import * as Tooltip from "@radix-ui/react-tooltip"
import { ModuleNameSOP } from "@routes/clients.$clientName/_sopPageLayout"
import { Link, useLocation } from "@tanstack/react-router"
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from "react"
import { usePageLayoutContext } from "../layoutContext"

const NavItem = ({
	selectedModule,
	name,
	icon,
}: {
	selectedModule: ModuleNameSOP
	name: string
	icon?: ReactNode
}) => {
	const { isMainNavExpanded, translationFunc, submitAction } = usePageLayoutContext()

	return (
		<Tooltip.Provider>
			<Tooltip.Root delayDuration={750}>
				<Tooltip.Trigger
					className={`${
						selectedModule === name
							? `rounded-none bg-black/20 shadow-[inset_0px_-1.5px_1px_hsla(0,0%,100%,0.2),inset_2px_3px_2px_hsla(0,0%,0%,0.2)]`
							: `hover:bg-white/10 hover:shadow-[inset_0px_-2px_2px_hsla(0,0%,0%,0.2),inset_0px_1.5px_1px_hsla(0,0%,100%,0.1),0px_4px_4px_hsla(0,0%,0%,0.2),0px_1px_2px_hsla(0,0%,0%,0.2)]`
					} ${
						isMainNavExpanded === false ? `w-[60px]` : `w-[180px]`
					} focus-style transition-all duration-300`}
				>
					<div
						tabIndex={-1}
						className={`group flex flex-row`}
						onClick={() => submitAction?.(name)}
					>
						<div
							tabIndex={-1}
							className={`flex h-[52px] items-center pl-[14px] ${
								selectedModule === name ? `cursor-default` : ``
							}`}
						>
							{icon === undefined ? (
								<div
									className={`h-[26px] w-[26px] pl-3 text-title5 leading-[26px] ${
										selectedModule === name
											? `text-dipai-primary-501`
											: `text-white/60 group-hover:text-white/100`
									} `}
								>
									{(translationFunc !== undefined
										? translationFunc(name.replaceAll("-", " ").toUpperCase())
										: name
									)
										.split(" ")
										.map((word) => word[0]?.toUpperCase())}
								</div>
							) : (
								<div
									className={`pr-3 duration-300 ${
										selectedModule === name
											? `text-dipai-primary-501`
											: `text-white/60 group-hover:text-white/100`
									} `}
								>
									{icon}
								</div>
							)}
						</div>
						<div
							tabIndex={-1}
							className={`h-[52px] w-[130px] origin-left pr-3 ${
								isMainNavExpanded === false ? `scale-x-0` : `scale-x-100`
							} ${selectedModule === name ? `cursor-default` : ``}`}
						>
							<div className={`flex h-full items-center overflow-hidden`}>
								<p
									className={`break-words text-left text-button font-semibold leading-[16px] duration-300 ${
										selectedModule !== name
											? `text-white group-hover:text-white/100`
											: `text-dipai-primary-501`
									} ${
										isMainNavExpanded === false
											? `w-full text-white/0`
											: `w-full ${
													selectedModule !== name
														? `text-white/60`
														: `text-dipai-primary-501/100`
											  }`
									}`}
								>
									{translationFunc !== undefined
										? translationFunc(name.replaceAll("-", " ").toUpperCase())
										: name}
								</p>
							</div>
						</div>
					</div>
				</Tooltip.Trigger>
				<Tooltip.Portal>
					<Tooltip.Content
						className={`${
							isMainNavExpanded === true ? `hidden` : `block`
						} z-[10001] h-auto w-auto rounded-sm bg-dipai-secondary-800/85 px-2 py-1 text-white/100`}
						sideOffset={5}
						side={`right`}
					>
						{translationFunc !== undefined
							? translationFunc(name.replaceAll("-", " ").toUpperCase())
							: name}
						<Tooltip.Arrow className={`fill-dipai-secondary-800/85`} />
					</Tooltip.Content>
				</Tooltip.Portal>
			</Tooltip.Root>
		</Tooltip.Provider>
	)
}
interface IMainNavItemProps
	extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	name: ModuleNameSOP
	icon?: ReactNode
}

export const MainNavItem: FC<IMainNavItemProps> = (props: IMainNavItemProps) => {
	const { name, icon } = props
	const selectedModule = useLocation().pathname.split("/").at(-1) as ModuleNameSOP

	return (
		<Link
			to={
				useLocation().pathname.includes("clients")
					? `/clients/$clientName/${name}`
					: `/${name}`
			}
			params={(prev) => ({ ...prev, clientName: prev.clientName ?? "unknown client" })}
			search={(prev) => ({ ...prev })}
			tabIndex={-1}
		>
			<div className={`hidden lg:flex`}>
				<NavItem selectedModule={selectedModule} name={name} icon={icon} />
			</div>
			<div className={`flex sm:flex lg:hidden`}>
				<NavItem selectedModule={selectedModule} name={name} icon={icon} />
			</div>
		</Link>
	)
}
