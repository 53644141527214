import UserDropdownV2 from "@components/userDropdown/UserDropdownV2"
import { ReactElement, ReactNode } from "react"

type HeaderLayoutProps = {
	children: ReactNode
	height: number
	bodyWidth: number | string
}

export default function HeaderLayout(props: HeaderLayoutProps): ReactElement {
	return (
		<div
			className="sticky top-0 left-0 flex flex-row justify-between text-white bg-dipai-secondary-901"
			style={{
				height: props.height,
			}}
		>
			<div className={`flex h-full flex-row items-center pl-4`}>
				<img
					src="/assets/img/logos/DipaiWhiteYellow.png"
					style={{ height: 32.5 }}
					alt="Dipai AS"
				/>
			</div>
			{props.children}
			<div className={`flex h-full flex-row items-center justify-end pr-4`}>
				<UserDropdownV2
					companyColors={{
						text: "text-white",
						border: "border-dipai-primary-501",
						backgroundHover: "hover:bg-dipai-tertiary-801",
					}}
				/>
			</div>
		</div>
	)
}
