import { handleMicrosoftLoginResponse } from "@components/login/microsoft/LoginMicrosoft"
import { RequestStatus } from "@redux/app/RequestStatus"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import { updateIsConnected } from "@redux/features/appState/appStateSlice"
import {
	fetchUserData,
	getUserData,
	getUserDataStatus,
} from "@redux/features/userData/userDataSlice"
import { Navigate, Outlet } from "@tanstack/react-router"
import "@ui-lib/core/chartJS/chartSetup"
import { LoadingCircle } from "@ui-lib/src"
import { common, scanClient } from "@utils/common"
import { requestApi2 } from "@utils/http"
import { getUserWebSocket } from "@utils/websocket/getUserWebSocket"
import { socketIoSocket } from "@utils/websocket/socketIoSocket"
import { markUserIsAuthenticated } from "@utils/websocket/userTrackingSocket"
import { endpoints, messageTypes } from "api"
import { useEffect, useState } from "react"
import "regenerator-runtime/runtime"
import { getAppState } from "./app/store"
import {
	fetchAppStructure,
	getAppStructure,
	getAppStructureStatus,
} from "./features/appStructure/appStructureSlice"
import { setCurrentCompany } from "./features/currentCompany/currentCompanySlice"
import { fetchStateAliases, getStateAliases } from "./features/stateAliases/stateAliasesSlice"
import { pca } from "./index"

export default function App() {
	const [companyName, setCompanyName] = useState<string>()
	const [isSignedIn, setIsSignedIn] = useState(false)

	const dispatch = useAppDispatch()

	const userData = useAppSelector(getUserData)
	const userDataStatus = useAppSelector(getUserDataStatus)

	const appStruct = useAppSelector(getAppStructure)
	const appStructureStatus = useAppSelector(getAppStructureStatus)

	const stateAliases = useAppSelector(getStateAliases)

	const appState = useAppSelector(getAppState)

	const wsClient = getUserWebSocket()

	useEffect(() => {
		pca.handleRedirectPromise()
			.then((response) => {
				if (response !== null) {
					handleMicrosoftLoginResponse(response, dispatch)
				}
			})
			.catch((error) => {
				console.error("Redirect error: ", error)
			})
	}, [])

	const signIn = async () => {
		const key = await requestApi2(endpoints.getSignInKey)
		if (key !== null) {
			wsClient.send(messageTypes.signIn, key)
			setIsSignedIn(true)
		}
	}

	useEffect(() => {
		const onConnected = () => dispatch(updateIsConnected(true))
		const onDisconnected = () => dispatch(updateIsConnected(false))
		socketIoSocket.onConnected.addListener(onConnected)
		socketIoSocket.onDisconnected.addListener(onDisconnected)
		return () => {
			socketIoSocket.onConnected.removeListener(onConnected)
			socketIoSocket.onDisconnected.removeListener(onDisconnected)
		}
	}, [])

	useEffect(() => {
		if (
			isSignedIn &&
			(!appState.isConnected ||
				userData.isAuthenticated === undefined ||
				userData.isAuthenticated === false)
		) {
			setIsSignedIn(false)
		}
		if (appState.isConnected && userData.isAuthenticated === true && !isSignedIn) {
			signIn()
		}
	}, [appState.isConnected, userData.isAuthenticated])

	useEffect(() => {
		if (userData.isAuthenticated === true) {
			markUserIsAuthenticated()
		}
	}, [userData.isAuthenticated])

	useEffect(() => {
		if (userDataStatus === RequestStatus.idle) {
			dispatch(fetchUserData())
		}
	}, [userDataStatus])

	useEffect(() => {
		if (userData.isAuthenticated === true) {
			if (appStruct === null) {
				dispatch(fetchAppStructure())
				setCompanyName(undefined)
				dispatch(setCurrentCompany(undefined))
			}
			if (stateAliases === null) {
				dispatch(fetchStateAliases())
			}
		}
	}, [userData])

	useEffect(() => {
		if (appStructureStatus === RequestStatus.succeeded) {
			console.log("😎 ~ appStruct", appStruct)
			common.sensorsById.clear()
			if (appStruct === null) {
				return
			}
			setCompanyName(Object.keys(appStruct)[0])
			for (const client of Object.keys(appStruct)) {
				const clientRef = appStruct[client]
				if (clientRef === undefined) {
					continue
				}
				scanClient(clientRef, client, client)
			}
		}
	}, [appStruct, dispatch])

	useEffect(() => {
		if (appStruct === null) {
			return
		}
		if (companyName === undefined || Object.keys(appStruct)[0] !== companyName) {
			setCompanyName(Object.keys(appStruct)[0])
			return
		}
	}, [appStruct, companyName])

	if (userData.isAuthenticated === false) {
		return (
			<>
				<Outlet />
				<Navigate to={"/login"} />
			</>
		)
	}

	return appStruct !== null &&
		companyName !== undefined &&
		Object.keys(appStruct)[0] === companyName ? (
		<Outlet />
	) : (
		<LoadingCircle containerHeightWithUnit="100vh" />
	)
}
