import { useState } from "react"
import { useTranslation } from "react-i18next"
import { MdError } from "react-icons/md"

export default function ErrorMessageMap({ error }: { error: Error | null }) {
	const { t } = useTranslation()
	const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false)
	return (
		<div
			className={`flex h-full items-center justify-center bg-dipai-secondary-700 text-white`}
		>
			<div className={`flex flex-col items-center`}>
				<div className={`mb-5 rounded-full bg-dipai-primary-501`}>
					<MdError className={`h-16 w-16 fill-dipai-secondary-700`} />
				</div>
				<p className="h-8 mb-2 font-bold text-center text-body">
					{t !== undefined
						? t("AN ERROR HAPPENED WHEN RENDERING THE MAP")
						: "An error happened when rendering the map"}
				</p>
				<p className="mb-8 text-center text-menu">
					{t !== undefined
						? t("IF THE ISSUE PERSISTS, PLEASE CONTACT DIPAI SUPPORT")
						: "If the issue persists, please contact Dipai support."}
				</p>
			</div>
		</div>
	)
}
