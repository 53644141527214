import * as AccordionPrimitive from "@radix-ui/react-accordion"
import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react"
import { MdChevronRight } from "react-icons/md"

const MapFilterAccordionTriggerMain = forwardRef<
	HTMLButtonElement,
	DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
>((props, ref) => {
	const { className, children, ...rest } = props
	return (
		// this height class fixes a bug with overflow-y in panel
		<AccordionPrimitive.Header className={`h-[33px] min-h-8`}>
			<AccordionPrimitive.Trigger
				className={`group flex h-8 w-full flex-row items-center justify-between border-t-1 bg-dipai-secondary-800 px-2 text-body font-semibold text-white`}
				{...rest}
				ref={ref}
			>
				{children}
				<MdChevronRight
					className={`h-6 w-6 transition-transform duration-300 group-data-[state=open]:rotate-90`}
					aria-hidden
				/>
			</AccordionPrimitive.Trigger>
		</AccordionPrimitive.Header>
	)
})

export default MapFilterAccordionTriggerMain
