import { getFileName } from "@helpers/getFileName"
import { useAppSelector } from "@redux/app/hooks"
import { getCurrentCompany } from "@redux/features/currentCompany/currentCompanySlice"
import { requestApi2 } from "@utils/http"
import { PlantsPerCompany } from "@utils/listPlantsPerCompany"
import { endpoints, GetVesselInfoArgs, VesselInfoOperation } from "api"
import { useQuery, useQueryClient } from "react-query"

interface VesselInfoOperationFE extends VesselInfoOperation {
	name: string
	owner: string
}

type VesselInfoOperationQueryArgs = {
	postData: GetVesselInfoArgs
	plantsPerCompany: PlantsPerCompany
	filter?: { [clientName: string]: string[] }
}
export default function useVesselInfoOperationQuery(args: VesselInfoOperationQueryArgs) {
	const queryClient = useQueryClient()
	const queryKeys = [
		`source=${getFileName()}`,
		`queryId=VesselInfoOperation`,
		`endpoints=[${endpoints.getVesselInfoOperation.name}]`,
	]
	const listOfCompanies = Object.keys(args.plantsPerCompany)
	if (listOfCompanies.length > 0) {
		const company0 = listOfCompanies[0]
		if (company0 !== undefined) {
			queryKeys.push(listOfCompanies.length > 1 ? "company=Dipai" : `company=${company0}`)
			queryKeys.push(`plants=${args.postData.vessels.map((v) => v.plantId)}`)
		}
	}

	if (args.filter !== undefined) {
		const companyNameFilter = Object.keys(args.filter)[0]
		if (companyNameFilter !== undefined) {
			queryKeys.push(`companyFilter=${companyNameFilter}`)
			const filterForCompany = args.filter[companyNameFilter]?.[0]
			if (filterForCompany !== undefined) {
				queryKeys.push(`filter=${filterForCompany}`)
			}
		}
	}
	const currentCompany = useAppSelector(getCurrentCompany)

	const response = useQuery(
		queryKeys,
		async (): Promise<VesselInfoOperationFE[]> => {
			let data = await requestApi2(endpoints.getVesselInfoOperation, {
				vessels: Array.from(
					args.postData.vessels
						.reduce((map, obj) => map.set(obj.plantId, obj), new Map())
						.values()
				),
			})
			if (currentCompany === undefined) {
				return []
			}
			if (data === null) {
				data = []
			}
			// for (const vesselData of data) {
			// 	if (vesselData.position !== undefined) {
			// 		continue
			// 	}
			// 	const vesselMetadata = common.plantDataFromOwnerAndId.get(
			// 		`${currentCompany.toLowerCase()}/${vesselData.plantId}`
			// 	)
			// 	const assetsMetadata = vesselMetadata?.assets
			// 	if (vesselMetadata === undefined || assetsMetadata === undefined) {
			// 		continue
			// 	}
			// 	const sensorsList: {
			// 		display_name: string
			// 		id: number
			// 		republish_list: string[]
			// 		unit: string
			// 		unitId?: number
			// 	}[] = []

			// 	for (const asset of assetsMetadata) {
			// 		sensorsList.push(...asset.sensors)
			// 	}

			// 	const headingId = sensorsList.find((s) => s.display_name === "Vessel Heading")?.id
			// 	const courseId = sensorsList.find((s) => s.display_name === "Vessel Course")?.id
			// 	const sogId = sensorsList.find((s) => s.display_name === "Vessel Speed (SOG)")?.id
			// 	const latitudeId = sensorsList.find((s) => s.display_name === "Latitude")?.id
			// 	const longitudeId = sensorsList.find((s) => s.display_name === "Longitude")?.id

			// 	const positionData = await requestApi2(endpoints.getTimeseriesData4, {
			// 		sensorIds: [headingId, courseId, sogId, latitudeId, longitudeId].filter(
			// 			(sId): sId is number => sId !== undefined
			// 		),
			// 		interval: {
			// 			lt: new Date(),
			// 			gte: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
			// 		},
			// 	})
			// 	if (positionData !== null && positionData.sensors.length > 0) {
			// 		const idx = data.findIndex((d) => d.plantId === vesselMetadata.id)
			// 		let data_idx = data[idx]
			// 		if (data_idx !== undefined) {
			// 			data_idx = {
			// 				...data_idx,
			// 				position: {
			// 					cog:
			// 						courseId !== undefined
			// 							? positionData.sensors.find((pd) => pd.id === courseId)
			// 									?.data[
			// 									positionData.sensors.find(
			// 										(pd) => pd.id === courseId
			// 									)?.data.length !== undefined
			// 										? positionData.sensors.find(
			// 												(pd) => pd.id === courseId
			// 										  )?.data.length! - 1
			// 										: 0
			// 							  ]?.[1] ?? null
			// 							: positionData.sensors.find((pd) => pd.id === headingId)
			// 									?.data[
			// 									positionData.sensors.find(
			// 										(pd) => pd.id === headingId
			// 									)?.data.length !== undefined
			// 										? positionData.sensors.find(
			// 												(pd) => pd.id === headingId
			// 										  )?.data.length! - 1
			// 										: 0
			// 							  ]?.[1] ?? null,
			// 					heading:
			// 						headingId !== undefined
			// 							? positionData.sensors.find((pd) => pd.id === headingId)
			// 									?.data[
			// 									positionData.sensors.find(
			// 										(pd) => pd.id === headingId
			// 									)?.data.length !== undefined
			// 										? positionData.sensors.find(
			// 												(pd) => pd.id === headingId
			// 										  )?.data.length! - 1
			// 										: 0
			// 							  ]?.[1] ?? null
			// 							: positionData.sensors.find((pd) => pd.id === courseId)
			// 									?.data[
			// 									positionData.sensors.find(
			// 										(pd) => pd.id === courseId
			// 									)?.data.length !== undefined
			// 										? positionData.sensors.find(
			// 												(pd) => pd.id === courseId
			// 										  )?.data.length! - 1
			// 										: 0
			// 							  ]?.[1] ?? null,
			// 					lat:
			// 						positionData.sensors.find((pd) => pd.id === latitudeId)?.data[
			// 							positionData.sensors.find((pd) => pd.id === latitudeId)
			// 								?.data.length !== undefined
			// 								? positionData.sensors.find(
			// 										(pd) => pd.id === latitudeId
			// 								  )?.data.length! - 1
			// 								: 0
			// 						]?.[1] ?? 0,
			// 					lon:
			// 						positionData.sensors.find((pd) => pd.id === longitudeId)?.data[
			// 							positionData.sensors.find((pd) => pd.id === longitudeId)
			// 								?.data.length !== undefined
			// 								? positionData.sensors.find(
			// 										(pd) => pd.id === longitudeId
			// 								  )?.data.length! - 1
			// 								: 0
			// 						]?.[1] ?? 0,
			// 					rot: null,
			// 					sog:
			// 						positionData.sensors.find((pd) => pd.id === sogId)?.data[
			// 							positionData.sensors.find((pd) => pd.id === sogId)?.data
			// 								.length !== undefined
			// 								? positionData.sensors.find((pd) => pd.id === sogId)
			// 										?.data.length! - 1
			// 								: 0
			// 						]?.[1] ?? null,
			// 					timeStamp:
			// 						positionData.sensors
			// 							.find((pd) => pd.id === longitudeId)
			// 							?.data[
			// 								positionData.sensors.find((pd) => pd.id === longitudeId)
			// 									?.data.length !== undefined
			// 									? positionData.sensors.find(
			// 											(pd) => pd.id === longitudeId
			// 									  )?.data.length! - 1
			// 									: 0
			// 							]?.[0].toISOString() ?? "",
			// 					navstat: 0,
			// 					srid: 0,
			// 				},
			// 			}
			// 		}
			// 	}
			// }

			const res: VesselInfoOperationFE[] = []
			for (const vesselData of data) {
				const id = vesselData.plantId

				for (const companyName in args.plantsPerCompany) {
					if (
						currentCompany.toLowerCase() === "dipai" &&
						(companyName.toLowerCase().includes("dipai") ||
							companyName.toLowerCase().includes("sølvtrans2"))
					) {
						continue
					}
					const plants = args.plantsPerCompany[companyName]
					for (const plantName in plants) {
						if (res.map((r) => r.plantId).includes(id) === true) {
							continue
						}
						const plantRef = plants[plantName]
						if (plantRef === undefined) {
							continue
						}
						if (plantRef.id === id) {
							res.push({ ...vesselData, owner: companyName, name: plantName })
						}
					}
				}
			}
			return res
		},
		{
			staleTime: 60000,
			refetchInterval: 60000,
			// initialData: () => {
			// 	const companyName = Object.keys(args.plantsPerCompany)[0]
			// 	console.log("🚀 ~ companyName:", companyName)
			// 	if (companyName === undefined) {
			// 		return undefined
			// 	}
			// 	const plantsList = args.plantsPerCompany[companyName]
			// 	console.log("🚀 ~ plantsList:", plantsList)
			// 	if (plantsList === undefined) {
			// 		return undefined
			// 	}
			// 	const data = queryClient.getQueriesData<VesselInfoOperationFE[]>({
			// 		queryKey: ["vesselOperationInfo", "Dipai"],
			// 		exact: true,
			// 	})[0]
			// 	if (data !== undefined) {
			// 		const dataFiltered = data[1].filter((vessel) =>
			// 			Object.keys(plantsList).includes(vessel.name)
			// 		)
			// 		return dataFiltered
			// 	} else {
			// 		const data = queryClient.getQueriesData<VesselInfoOperationFE[]>({
			// 			queryKey: ["vesselOperationInfo", Object.keys(args.plantsPerCompany)[0]],
			// 			exact: true,
			// 		})[0]
			// 		if (data !== undefined) {
			// 			const dataFiltered = data[1].filter((vessel) =>
			// 				Object.keys(plantsList).includes(vessel.name)
			// 			)
			// 			return dataFiltered
			// 		} else {
			// 			if (args.filter !== undefined) {
			// 				const data = queryClient.getQueriesData<VesselInfoOperationFE[]>({
			// 					queryKey: [
			// 						"vesselOperationInfo",
			// 						Object.keys(args.plantsPerCompany)[0],
			// 						Object.keys(args.filter)[0],
			// 					],
			// 					exact: true,
			// 				})[0]
			// 				if (data !== undefined) {
			// 					const dataFiltered = data[1].filter((vessel) =>
			// 						Object.keys(plantsList).includes(vessel.name)
			// 					)
			// 					return dataFiltered
			// 				} else {
			// 					return undefined
			// 				}
			// 			} else {
			// 				return undefined
			// 			}
			// 		}
			// 	}
			// },
		}
	)
	return response
}
