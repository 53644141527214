import { useSensorsGroupContext } from "@sop/HistoricalDataPage/sensorsGroupContext"
import { Tooltip, debounce } from "@ui-lib/src"
import { COLORS_PLOTS_COLORBLIND } from "@utils/colorsPlots"
import { OpModeEvent, SignalBaseLine } from "api"
import { ChartData, ChartOptions } from "chart.js"
import { AnnotationOptions, AnnotationTypeRegistry } from "chartjs-plugin-annotation"
import { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from "react"
import { Line } from "react-chartjs-2"
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types"
import { useTranslation } from "react-i18next"
import { MdInfoOutline } from "react-icons/md"
import { PlotData, SeriesDataFE } from "./utils"

type LinePlotProps = {
	newRangeLoaded: boolean
	sensorsIdWithNoDataInInterval?: {
		sensorLabel: string
		sensorId: number
		timestampLastDataPoint: Date
	}[]
	individualYAxisPerSensor?: boolean
	plotData: PlotData
	opModeEvents?: OpModeEvent[]
	setCurrentOpMode: Dispatch<SetStateAction<{ modeName: string; modeColor: string } | undefined>>
	setCurrentTimestamp: Dispatch<SetStateAction<number | undefined>>
	showOpModeBg?: boolean
	listOfModeColors?: {
		[modeName: string]: string
	}
	showRawData?: boolean
	isWarningPanelVisible?: boolean
	from: Date
	to: Date
}

type YAxisProps = {
	unit: string
	min: number
	max: number
	timeseriesRef: number[]
	tickLabels?: string[]
}

const generateThresholdAnnotation = (
	thresholdName: string,
	value: number,
	color: string,
	yScaleId: string,
	minTime: number,
	maxTime: number
):
	| AnnotationOptions<keyof AnnotationTypeRegistry>
	| Record<string, AnnotationOptions<keyof AnnotationTypeRegistry>> => {
	return {
		type: "line",
		yScaleID: yScaleId,
		xMin: minTime,
		xMax: maxTime,
		yMin: value,
		yMax: value,
		borderDash: [5, 5],
		borderWidth: 1,
		borderColor: color,
		label: {
			content: thresholdName,
			position: "end",
			color: color,
			font: { size: 14 },
			backgroundColor: "#00000000",
			display: true,
			yAdjust: -10,
		},
	}
}

const generateBaselineAnnotation = (
	baselineType: SignalBaseLine,
	seriesData: { x: Date; y: number }[],
	color: string,
	yScaleId: string,
	minTime: number,
	maxTime: number
):
	| AnnotationOptions<keyof AnnotationTypeRegistry>
	| Record<string, AnnotationOptions<keyof AnnotationTypeRegistry>> => {
	let baselineAbbreviation: string = ""
	let baselineValue: string = ""
	if (baselineType === SignalBaseLine.AVERAGE) {
		baselineAbbreviation = "AVG"
		const sum = seriesData.reduce((a, b) => a + b.y, 0)
		baselineValue = (sum / seriesData.length).toFixed(2)
	} else if (baselineType === SignalBaseLine.MIN) {
		baselineAbbreviation = "MIN"
		baselineValue = Math.min(...seriesData.map((dataPoint) => dataPoint.y)).toFixed(2)
	} else if (baselineType === SignalBaseLine.MAX) {
		baselineAbbreviation = "MAX"
		baselineValue = Math.max(...seriesData.map((dataPoint) => dataPoint.y)).toFixed(2)
	} else {
		return {}
	}

	return {
		type: "line",
		yScaleID: yScaleId,
		xMin: minTime,
		xMax: maxTime,
		yMin: baselineValue,
		yMax: baselineValue,
		borderWidth: 1,
		borderColor: color,
		label: {
			content: `${baselineAbbreviation} = ${baselineValue}`,
			position: "end",
			color: color,
			font: { size: 14 },
			backgroundColor: "#00000000",
			display: true,
			yAdjust: -10,
		},
	}
}

const getPrecision = (a: number) => {
	if (!isFinite(a)) return 0
	var e = 1,
		p = 0
	while (Math.round(a * e) / e !== a) {
		e *= 10
		p++
	}
	return p
}

const generateChartOptions = (
	listOfListeners: AbortController[],
	setListOfListeners: Dispatch<SetStateAction<AbortController[]>>,
	chartRef: ChartJSOrUndefined<"line", { x: Date; y: number | null }[]> | null,
	setCurrentOpMode: Dispatch<SetStateAction<{ modeName: string; modeColor: string } | undefined>>,
	setCurrentTimestamp: Dispatch<SetStateAction<number | undefined>>,
	yAxisData: YAxisProps[],
	oneYAxisPerSensor: boolean,
	seriesData: SeriesDataFE[],
	from: Date,
	to: Date,
	opModeEvents: OpModeEvent[],
	plotId: number,
	showOpModeBg?: boolean,
	listOfModeColors?: {
		[modeName: string]: string
	},
	units?: { label: string; unit: string }[],
	setPlotZoom?: Dispatch<
		SetStateAction<
			| {
					plotId: number
					zoomFrom?: number | undefined
					zoomTo?: number | undefined
			  }[]
			| undefined
		>
	>,
	zoomFrom?: number,
	zoomTo?: number,
	hideLegend?: boolean
) => {
	const maxTime = to.getTime() //Math.max(...seriesData.map((serie) => serie.data[0]?.x.getTime() ?? Infinity))
	const minTime = from.getTime()
	//Math.min(
	//...seriesData.map((serie) => serie.data[serie.data.length - 1]?.x.getTime() ?? 0)
	//)
	if ((zoomFrom === undefined || zoomTo === undefined) && setPlotZoom !== undefined) {
		setPlotZoom((prev) => {
			if (prev === undefined) {
				return [{ plotId, zoomFrom: minTime, zoomTo: maxTime }]
			}
			if (prev.map((item) => item.plotId).includes(plotId) === false) {
				prev.push({ plotId, zoomFrom: minTime, zoomTo: maxTime })
				return prev
			}
			return prev?.map((plotData) => {
				if (plotData.plotId === plotId) {
					return { plotId, zoomFrom: minTime, zoomTo: maxTime }
				} else {
					return plotData
				}
			})
		})
	}
	const listOfUniqueModes = new Set(
		opModeEvents.map((e) => {
			return e.opModeName
		})
	)
	const listOfColors: {
		[modeName: string]: string
	} = listOfModeColors ?? {}
	if (listOfModeColors === undefined) {
		Array.from(listOfUniqueModes).forEach((m, index) => {
			const color = `${
				COLORS_PLOTS_COLORBLIND[
					COLORS_PLOTS_COLORBLIND.length - 1 - (index % COLORS_PLOTS_COLORBLIND.length)
				]
			}40`
			listOfColors[m] = color
		})
	}
	if (chartRef !== null && opModeEvents.length !== 0) {
		function getModeData(e: MouseEvent) {
			const l = chartRef?.chartArea.left
			const r = chartRef?.chartArea.right
			const b = chartRef?.chartArea.bottom
			const t = chartRef?.chartArea.top
			const x = e.offsetX
			const y = e.offsetY
			if (
				l !== undefined &&
				l <= x &&
				r !== undefined &&
				r >= x &&
				t !== undefined &&
				t <= y &&
				b !== undefined &&
				b >= y
			) {
				const mouseX = chartRef?.scales.x?.getValueForPixel(e.offsetX)
				const idxRef = opModeEvents
					.sort(
						(a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
					)
					.findIndex(
						(e) => new Date(e.timestamp).getTime() > (mouseX ?? 0) //tooltipItems?.[0]?.parsed.x
					)
				const currentOpModeName =
					idxRef === 0
						? undefined
						: opModeEvents[idxRef === -1 ? opModeEvents.length - 1 : idxRef - 1]
								?.opModeName
				const modeColor =
					currentOpModeName !== undefined
						? listOfColors[currentOpModeName.toLowerCase()]
						: undefined

				setCurrentOpMode(
					currentOpModeName === undefined
						? undefined
						: {
								modeName: currentOpModeName,
								modeColor: modeColor ?? "#ccc",
						  }
				)

				setCurrentTimestamp(mouseX)
			} else {
				setCurrentOpMode(undefined)
				setCurrentTimestamp(undefined)
			}
		}

		listOfListeners?.[0]?.abort()
		const areaListener = new AbortController()

		chartRef?.canvas?.addEventListener("mousemove", debounce(getModeData, 5), {
			signal: areaListener.signal,
		})
		setListOfListeners([areaListener])
	}

	const options: ChartOptions<"line"> = {
		maintainAspectRatio: false,
		interaction: {
			mode: "index",
			intersect: false,
		},
		plugins: {
			tooltip: {
				callbacks: {
					title(tooltipItems) {
						const title = [`${tooltipItems?.[0]?.label}`]
						const tooltipItems_0 = tooltipItems?.[0]
						if (tooltipItems_0 === undefined) {
							return title
						}
						const idxRef = opModeEvents
							.sort(
								(a, b) =>
									new Date(a.timestamp).getTime() -
									new Date(b.timestamp).getTime()
							)
							.findIndex(
								(e) => new Date(e.timestamp).getTime() > tooltipItems_0.parsed.x
							)
						const currentOpModeName =
							opModeEvents[idxRef < 1 ? opModeEvents.length - 1 : idxRef - 1]
								?.opModeName
						if (listOfUniqueModes.size > 0) {
							title.push(`Operation mode: ${currentOpModeName}`)
						}
						return title
					},
					label(tooltipItem) {
						const refUnit = units?.find(
							(u) => u.label === tooltipItem.dataset.label
						)?.unit
						const yAxisRef = yAxisData.find((y) => y.unit === refUnit)
						if (yAxisRef?.tickLabels !== undefined) {
							return `${tooltipItem.dataset.label}: ${
								yAxisRef?.tickLabels[tooltipItem.parsed.y]
							}`
						}

						return `${tooltipItem.dataset.label}: ${
							typeof tooltipItem.parsed.y === "number"
								? tooltipItem.parsed.y.toFixed(2)
								: tooltipItem.parsed.y
						} ${refUnit ?? ""}`
					},
				},
				yAlign: "center",
				titleFont: { size: 14 },
				bodyFont: { size: 14 },
				intersect: false,
				mode: "index",
			},
			legend: {
				position: "bottom",
				align: "start",
				display: hideLegend === true ? false : true,
			},
			title: {
				display: false,
				text: ``,
			},
			zoom: {
				limits: {
					x: {
						min: minTime,
						max: maxTime,
					},
				},
				pan: {
					enabled: true,
					mode: "x",
				},
				zoom: {
					onZoom(context) {
						const xMin = context.chart.scales?.x?.min
						const xMax = context.chart.scales?.x?.max
						if (setPlotZoom !== undefined) {
							debounce(
								() =>
									setPlotZoom((prev) => {
										return prev?.map((plotData) => {
											if (plotData.plotId === plotId) {
												return { plotId, zoomFrom: xMin, zoomTo: xMax }
											} else {
												return plotData
											}
										})
									}),
								100
							)
						}
					},
					wheel: {
						enabled: true,
					},
					pinch: {
						enabled: true,
					},
					mode: "x",
				},
			},
			bgColor:
				showOpModeBg !== false
					? {
							colorMap: listOfColors,
							ranges: opModeEvents.map((e, index) => {
								const opModeEvents_next = opModeEvents[index + 1]
								return [
									new Date(e.timestamp).getTime(),
									opModeEvents_next !== undefined
										? new Date(opModeEvents_next.timestamp).getTime()
										: maxTime,
									e.opModeName.toLowerCase(),
								]
							}),
					  }
					: {},
			// customCanvasBackgroundColor: {},
			customLineTooltip: {
				enabled: false,
			},
			crosshairV2: { crosshair: { enabled: true } },
			annotation: {},
		},
		scales: {
			x: {
				min: zoomFrom ?? minTime,
				max: zoomTo ?? maxTime,
				grid: { display: false },
				type: "time",
				ticks: {
					major: { enabled: true },
					font: function (ctx, options) {
						if (ctx.tick !== undefined && ctx.tick.major === true) {
							return { weight: "bold", size: 15 }
						}
					},
				},
				time: {
					displayFormats: {
						day: "dd MMM",
						hour: "ha",
						minute: "HH:mm",
					},
					tooltipFormat: "dd MMM - HH:mm:ss",
				},
				title: {
					display: false,
					text: "time",
					font: { size: 14 },
				},
			},
		},
	}
	yAxisData.forEach((yAxis, index) => {
		const currentSeries = seriesData.find((serie) => yAxis.timeseriesRef.includes(serie.id))
		options.scales = {
			...options.scales,
			[`y${index === 0 ? `` : index}`]: {
				grid: { display: false },
				title: {
					display: true,
					align: "end",
					text:
						oneYAxisPerSensor === true
							? `${currentSeries?.displayName ?? "(Unknonwn sensor name"} (${
									yAxis.unit
							  })`
							: yAxis.unit,
					font: { size: 14 },
				},
				min: currentSeries?.stateAliases !== undefined ? yAxis.min : undefined,
				max: currentSeries?.stateAliases !== undefined ? yAxis.max : undefined,
				ticks: {
					callback(tickValue, index, ticks) {
						return yAxis.tickLabels !== undefined
							? Number(tickValue) % 1 !== 0
								? ""
								: yAxis.tickLabels?.[tickValue]
							: typeof tickValue === "number"
							? getPrecision(tickValue) > 3
								? tickValue.toFixed(3)
								: tickValue
							: tickValue
					},
				},
			},
		}
	})
	const annotationRef = options.plugins?.annotation
	let annotations = {}
	if (annotationRef !== undefined) {
		seriesData.forEach((serieData, index) => {
			if (serieData.baselines?.[0] !== undefined) {
				for (const baselineType of serieData.baselines) {
					annotations = {
						...annotations,
						[`baseline_${index}_${baselineType}`]: generateBaselineAnnotation(
							baselineType,
							serieData.data.filter(
								(d): d is { x: Date; y: number } => d.y !== null
							) ?? [],
							serieData.color ?? //COLORS_PLOTS_COLORBLIND[index % COLORS_PLOTS_COLORBLIND.length] ??
								"#000",
							`y${
								yAxisData.findIndex((axis) =>
									axis.timeseriesRef.includes(serieData.id)
								) < 1
									? ``
									: yAxisData.findIndex((axis) =>
											axis.timeseriesRef.includes(serieData.id)
									  )
							}`,
							minTime,
							maxTime
						),
					}
				}
			}
			if (serieData.thresholds?.[0] !== undefined) {
				for (const threshold of serieData.thresholds) {
					annotations = {
						...annotations,
						[`threshold_${index}_${threshold.id}`]: generateThresholdAnnotation(
							threshold.name,
							threshold.value,
							serieData.color ?? //COLORS_PLOTS_COLORBLIND[index % COLORS_PLOTS_COLORBLIND.length] ??
								"#000",
							`y${
								yAxisData.findIndex((axis) =>
									axis.timeseriesRef.includes(serieData.id)
								) < 1
									? ``
									: yAxisData.findIndex((axis) =>
											axis.timeseriesRef.includes(serieData.id)
									  )
							}`,
							minTime,
							maxTime
						),
					}
				}
			}
		})
		annotationRef.annotations = { ...annotationRef.annotations, ...annotations }
	}
	return options
}

const CustomLegendItem = ({
	index,
	color,
	label,
	timestampLastDataPoint,
	bounderingContainerRef,
	isHidden = false,
}: {
	index: number
	color: string
	label: string
	timestampLastDataPoint?: Date
	bounderingContainerRef: HTMLDivElement | null
	isHidden?: boolean
}) => {
	const { t } = useTranslation()
	return (
		<div data-label={index} className={`mb-1 flex items-center gap-0`}>
			<div
				data-label-index={index}
				style={{ backgroundColor: color }}
				className={`mr-[5px] h-[14px] w-[42px] border-0 border-gray-444`}
			/>
			<span
				data-label-index={index}
				className={`relative mr-1 inline-block text-[13px] text-gray-444 ${
					isHidden === true ? "before:visible" : "before:hidden"
				} before:absolute before:left-0 before:right-0 before:top-[50%] before:z-1 before:h-[2px] before:translate-y-[-50%] before:bg-gray-666 before:content-[''] hover:cursor-default`}
			>
				{label}
			</span>
			{timestampLastDataPoint !== undefined ? (
				<Tooltip
					className={"bg-dipai-secondary-800/95"}
					arrowClassName={"fill-dipai-secondary-800/95"}
					collisionBoundary={bounderingContainerRef}
					customContent={
						<div className={`flex max-w-[550px] flex-col`}>
							{`${t("SENSOR")} ${label} ${t(
								"HAS NO SAMPLES IN THE SELECTED INTERVAL"
							)}. ${t(
								"THE PLOT SHOWS THE LATEST SENSOR VALUE WHICH WAS REGISTERED ON"
							)} ${timestampLastDataPoint}.
							`}
						</div>
					}
				>
					<div className={`flex w-fit flex-row gap-2 text-red-700`}>
						<MdInfoOutline className={"hover:cursor-pointer"} />
					</div>
				</Tooltip>
			) : null}
		</div>
	)
}
const CustomLegend = ({
	bounderingContainerRef,
	chart,
	sensorsIdWithNoDataInInterval,
	visibilityStates,
}: {
	bounderingContainerRef: HTMLDivElement | null
	chart: ChartJSOrUndefined<
		"line",
		{
			x: Date
			y: number | null
		}[]
	>
	sensorsIdWithNoDataInInterval?: {
		sensorLabel: string
		sensorId: number
		timestampLastDataPoint: Date
	}[]
	visibilityStates: { index: number; isHidden: boolean }[]
}): ReactNode => {
	return (
		sensorsIdWithNoDataInInterval !== undefined &&
		chart?.data.datasets.map((value, index: number) => {
			const amountUniqueValues = new Set(value.data.map((d) => d.y)).size
			const color = typeof value.backgroundColor === "string" ? value.backgroundColor : "#666"
			const label = value.label ?? "unknown sensor"
			return (
				<CustomLegendItem
					key={`${label}_idx_${index}`}
					index={index}
					color={color}
					label={label}
					isHidden={visibilityStates.find((vs) => vs.index === index)?.isHidden}
					bounderingContainerRef={bounderingContainerRef}
					timestampLastDataPoint={
						amountUniqueValues === 1
							? sensorsIdWithNoDataInInterval.find((s) => s.sensorLabel === label)
									?.timestampLastDataPoint
							: undefined
					}
				/>
			)
		})
	)
}
export default function LinePlot({
	newRangeLoaded,
	individualYAxisPerSensor = false,
	plotData,
	opModeEvents,
	setCurrentOpMode,
	setCurrentTimestamp,
	showOpModeBg,
	listOfModeColors,
	showRawData,
	isWarningPanelVisible,
	from,
	to,
	sensorsIdWithNoDataInInterval,
}: LinePlotProps) {
	const c = useSensorsGroupContext()
	const currentPlotZoomData = c?.plotZoom?.find((pr) => pr.plotId === plotData.plotId)
	const zoomFrom = currentPlotZoomData?.zoomFrom
	const zoomTo = currentPlotZoomData?.zoomTo

	const setPlotZoom = c?.setPlotZoom
	// const stateAliases = useAppSelector(getStateAliases)

	const [listOfListeners, setListOfListeners] = useState<AbortController[]>([])

	const [yAxis, setYAxis] = useState<YAxisProps[]>([])
	const [chartOptions, setChartOptions] = useState<ChartOptions<"line">>()
	const [chartRef, setChartRef] = useState<ChartJSOrUndefined<
		"line",
		{ x: Date; y: number | null }[]
	> | null>(null)

	useEffect(() => {
		const timeseries = plotData.series
		if (timeseries === undefined || timeseries === null) {
			return
		}
		const listOfUnits: string[] = []
		const yAxisTemp: YAxisProps[] = []
		for (const timeserie of timeseries) {
			const listOfYValues = timeserie.data.map((dataPoint) => dataPoint.y)
			let uniqueDataValues: number[] = []
			if (timeserie.stateAliases !== undefined) {
				uniqueDataValues = Array.from(
					new Set(
						timeserie.data
							.filter((s): s is { x: Date; y: number } => s.y !== null)
							.map((s) => s.y)
					)
				).sort((a, b) => a - b)
			}
			if (individualYAxisPerSensor === true) {
				const minYValue =
					timeserie.stateAliases !== undefined
						? 0
						: Math.min(...listOfYValues.filter((v): v is number => v !== null))
				const maxYValue =
					timeserie.stateAliases !== undefined
						? uniqueDataValues.length + 1
						: Math.max(...listOfYValues.filter((v): v is number => v !== null))
				yAxisTemp.push({
					unit: timeserie.unit,
					min: minYValue,
					max: maxYValue,
					timeseriesRef: [timeserie.id],
					tickLabels:
						timeserie.stateAliases !== undefined
							? [
									"",
									...uniqueDataValues.map(
										(u) =>
											timeserie.stateAliases?.find((s) => s.value === u)
												?.label ?? `${u}`
									),
									"",
							  ]
							: undefined,
				})
			} else {
				if (listOfUnits.includes(timeserie.unit) === false) {
					listOfUnits.push(timeserie.unit)
					const minYValue =
						timeserie.stateAliases !== undefined
							? 0
							: Math.min(...listOfYValues.filter((v): v is number => v !== null))
					const maxYValue =
						timeserie.stateAliases !== undefined
							? uniqueDataValues.length + 1
							: Math.max(...listOfYValues.filter((v): v is number => v !== null))
					yAxisTemp.push({
						unit: timeserie.unit,
						min: minYValue,
						max: maxYValue,
						timeseriesRef: [timeserie.id],
						tickLabels:
							timeserie.stateAliases !== undefined
								? [
										"",
										...uniqueDataValues.map(
											(u) =>
												timeserie.stateAliases?.find((s) => s.value === u)
													?.label ?? `${u}`
										),
										"",
								  ]
								: undefined,
					})
				} else {
					const unitIndex = listOfUnits.findIndex((unit) => unit === timeserie.unit)
					const axisRef = yAxisTemp[unitIndex]
					if (axisRef !== undefined) {
						const minYValue = Math.min(
							...listOfYValues.filter((v): v is number => v !== null)
						)
						const maxYValue = Math.max(
							...listOfYValues.filter((v): v is number => v !== null)
						)
						yAxisTemp[unitIndex] = {
							unit: axisRef.unit,
							min:
								timeserie.stateAliases !== undefined
									? 0
									: Math.min(axisRef.min, minYValue),
							max:
								timeserie.stateAliases !== undefined
									? uniqueDataValues.length + 1
									: Math.max(axisRef.max, maxYValue),
							timeseriesRef: [...axisRef.timeseriesRef, timeserie.id],
							tickLabels:
								timeserie.stateAliases !== undefined
									? [
											"",
											...uniqueDataValues.map(
												(u) =>
													timeserie.stateAliases?.find(
														(s) => s.value === u
													)?.label ?? `${u}`
											),
											"",
									  ]
									: undefined,
						}
					}
				}
			}
		}

		setYAxis(yAxisTemp)
	}, [plotData.series])

	useEffect(() => {
		if (yAxis.length === 0 || plotData.series === null) {
			return
		}
		const options = generateChartOptions(
			listOfListeners,
			setListOfListeners,
			chartRef,
			setCurrentOpMode,
			setCurrentTimestamp,
			yAxis,
			individualYAxisPerSensor,
			plotData.series,
			from,
			to,
			opModeEvents ?? [],
			plotData.plotId,
			showOpModeBg,
			listOfModeColors,
			plotData.series.map((serieData) => {
				return { label: serieData.displayName, unit: serieData.unit }
			}),
			setPlotZoom,
			zoomFrom,
			zoomTo,
			true
		)
		setChartOptions(options)
	}, [yAxis, opModeEvents, chartRef, showOpModeBg, zoomFrom, zoomTo])

	const [chartData, setChartData] = useState<ChartData<"line", { x: Date; y: number | null }[]>>({
		datasets: [],
	})

	const mainSeries = plotData.series !== null ? plotData.series[0] : undefined

	useEffect(() => {
		if (mainSeries === undefined || yAxis === undefined || plotData.series === null) {
			return
		}

		// setChartData(undefined)
		const tempChartData: ChartData<"line", { x: Date; y: number | null }[]> = {
			datasets: [],
		}

		plotData.series.map((serie) => {
			const seriesWithSameUnit = plotData.series?.filter((s) => s.unit === serie.unit)
			let dataScaled = serie.data
			if (serie.stateAliases !== undefined && seriesWithSameUnit !== undefined) {
				const uniqueDataValues = Array.from(
					new Set(
						seriesWithSameUnit.flatMap((s) =>
							s.data
								.filter((s): s is { x: Date; y: number } => s.y !== null)
								.map((s) => s.y)
						)
					)
				).sort((a, b) => a - b)
				dataScaled = serie.data.map((d) => {
					return {
						x: d.x,
						y: d.y === null ? null : uniqueDataValues.findIndex((u) => u === d.y) + 1,
					}
				})
			}
			tempChartData.datasets.push({
				label: serie.displayName,
				data: dataScaled,
				borderColor: serie.color, //COLORS_PLOTS_COLORBLIND[index % COLORS_PLOTS_COLORBLIND.length],
				backgroundColor: serie.color, //COLORS_PLOTS_COLORBLIND[index % COLORS_PLOTS_COLORBLIND.length],
				borderWidth: 1,
				pointRadius: showRawData === true ? 1.5 : 0,
				pointBorderWidth: 0,
				pointHoverRadius: 6,
				pointHoverBackgroundColor: serie.color, // COLORS_PLOTS_COLORBLIND[index % COLORS_PLOTS_COLORBLIND.length],
				showLine: showRawData === true ? false : true,
				parsing: {
					xAxisKey: "x",
					yAxisKey: "y",
				},
				yAxisID: `y${
					yAxis.findIndex((axis) => axis.timeseriesRef.includes(serie.id)) < 1
						? ``
						: yAxis.findIndex((axis) => axis.timeseriesRef.includes(serie.id))
				}`,
				stepped: "after",
			})
		})

		setChartData(tempChartData)
	}, [plotData.series, yAxis, showRawData])

	const [containerRef2, setContainerRef2] = useState<HTMLDivElement | null>(null)

	const legendRef = useRef<HTMLDivElement>(null)
	const [visibilityStates, setVisibilityStates] = useState<
		{ index: number; isHidden: boolean }[]
	>([])

	useEffect(() => {
		const chart = chartRef
		if (chart === null || chart === undefined) {
			return
		}
		const visibilityStatesTemp: { index: number; isHidden: boolean }[] = []
		chart?.data.datasets.forEach((dataset, index) =>
			visibilityStatesTemp.push({
				index: index,
				isHidden: chart.getDatasetMeta(index).hidden,
			})
		)
		setVisibilityStates((prev) => visibilityStatesTemp)
		if (legendRef.current !== null) {
			const legendItems = legendRef.current.querySelectorAll("[data-label]")
			legendItems.forEach((item) => {
				item.addEventListener("click", (event) => {
					const target = event.target as Element
					if (target === null) {
						return
					}
					let index: string | null = target.getAttribute("data-label-index")
					if (index === null || index === undefined) {
						index = target.getAttribute("data-label")
						if (index === null || index === undefined) {
							return
						}
					}
					if (isNaN(Number(index)) === true) {
						return
					}
					const meta = chart.getDatasetMeta(Number(index))
					if (meta !== undefined && meta.hidden !== undefined) {
						const newVisibility = !meta.hidden
						setVisibilityStates((prev) => [
							...prev.filter((p) => p.index !== Number(index)),
							{ index: Number(index), isHidden: newVisibility },
						])
						meta.hidden = newVisibility
						chart.update()
					}
				})
			})
		}
	}, [chartRef, legendRef])

	return chartData?.datasets !== undefined && chartOptions !== undefined ? (
		<div
			ref={(newRef) => setContainerRef2(newRef)}
			className={`${
				isWarningPanelVisible === true ? "h-[368px]" : "h-[400px]"
			} w-full overflow-hidden border-1 p-1`}
		>
			<Line
				className={`${isWarningPanelVisible === true ? "max-h-[328px]" : "max-h-[360px]"}`}
				ref={(newRef) => setChartRef(newRef)}
				options={chartOptions}
				data={chartData}
			/>
			{chartRef !== null && chartRef !== undefined ? (
				<div ref={legendRef} className={`flex h-8 flex-row gap-[11px] px-[9px]`}>
					<CustomLegend
						bounderingContainerRef={containerRef2}
						chart={chartRef}
						sensorsIdWithNoDataInInterval={sensorsIdWithNoDataInInterval}
						visibilityStates={visibilityStates}
					/>
				</div>
			) : null}
		</div>
	) : null
}
