import { removeIncompatibleSearchParameters } from "@helpers/removeIncompatibleSearchParameters"
import { operationPageSearchSchema } from "@routes/clients.$clientName/_sopPageLayout/operations"
import { createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute("/_sopPageLayout/operations")({
	component: RouteComponent,
	validateSearch: operationPageSearchSchema,
	search: {
		middlewares: [removeIncompatibleSearchParameters(operationPageSearchSchema)],
	},
})

function RouteComponent() {
	return null
}
