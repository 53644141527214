import { HomePageTableItemST } from "@pages/HomePage/operationPortalTable/useOpModeTableColumns"
import { PlantInfo } from "api"
import { MapTableItemST } from "./usePlantsTableColumns"

export const getListOfEvents = (liveOpModeDataTemp: PlantInfo[]): string[] => {
	const listOfEvents: string[] = ["Fish Count", "Operation Mode", "Open State"]
	for (const vesselData of liveOpModeDataTemp) {
		const latestOpMode = vesselData.latestOperationMode
		if (latestOpMode === undefined) {
			continue
		}
		const deviationEvents = latestOpMode.deviationEvents
		if (deviationEvents === undefined) {
			continue
		}
		for (const deviationData of deviationEvents) {
			const categoryName = deviationData.categoryName
			if (listOfEvents.includes(categoryName)) {
				continue
			}
			listOfEvents.push(categoryName)
		}
	}
	return listOfEvents
}

export const prepareTableData = (listOfVessels: { loaded: boolean; vessels: PlantInfo[] }) => {
	const vessels = listOfVessels?.vessels
	if (vessels === undefined) {
		return []
	}
	return vessels.map((vessel) => {
		const listOfEvents: string[] = getListOfEvents(vessels ?? [])
		const opModeData = vessel.latestOperationMode

		const opModeEvents = opModeData?.opModeEvents
		const opModes =
			opModeEvents !== undefined && opModeEvents.length > 0
				? opModeEvents.map((event) => `${event.subsystemName}: ${event.opModeName}`)
				: ["--"]

		// const rowData: {
		// 	[colName: string]: { cellType: CellContentType; cellContent: any }
		// } = {}

		const statusLabel = "green"
		// for (const key of Object.keys(rowData)) {
		// 	if (rowData[key]?.cellType !== CellContentType.TRAFFIC_LIGHT) {
		// 		continue
		// 	}
		// 	if (statusLabel === "red") {
		// 		continue
		// 	}
		// 	if (rowData[key]?.cellContent === "red") {
		// 		statusLabel = "red"
		// 	} else {
		// 		if (statusLabel === "yellow") {
		// 			continue
		// 		}
		// 		if (rowData[key]?.cellContent === "yellow") {
		// 			statusLabel = "yellow"
		// 		} else {
		// 			if (statusLabel === "") {
		// 				continue
		// 			}
		// 			if (rowData[key]?.cellContent === "") {
		// 				statusLabel = ""
		// 			}
		// 		}
		// 	}
		// }

		const rowData: HomePageTableItemST = {
			itemName: vessel.name,
			fishCount: opModeData?.fishCount !== undefined ? `${opModeData.fishCount}` : "--",
			openState:
				opModeData?.openState !== undefined && typeof opModeData.openState === "string"
					? `${opModeData.openState.toUpperCase()}`
					: "--",
			operationMode: opModes,

			// const devEvents = opModeData !== undefined ? opModeData.deviationEvents : undefined

			// if (devEvents !== undefined) {
			// 	for (const event of devEvents) {
			// 		if (rowData[event.categoryName] === undefined) {
			// 			rowData[event.categoryName] = {
			// 				cellType: CellContentType.TRAFFIC_LIGHT,
			// 				cellContent: event.severity ?? "green",
			// 			}
			// 		} else {
			// 			if (event.severity === "red") {
			// 				rowData[event.categoryName] = {
			// 					cellType: CellContentType.TRAFFIC_LIGHT,
			// 					cellContent: event.severity,
			// 				}
			// 			} else {
			// 				const rowRef = rowData[event.categoryName]
			// 				if (rowRef === undefined) {
			// 					continue
			// 				}
			// 				if (event.severity === "yellow" && rowRef.cellContent !== "red") {
			// 					rowRef.cellContent = event.severity
			// 				}
			// 			}
			// 		}
			// 	}
			// }

			// for (const event of listOfEvents) {
			// 	if (rowData[event] === undefined) {
			// 		rowData[event] = {
			// 			cellType: CellContentType.TRAFFIC_LIGHT,
			// 			cellContent: "",
			// 		}
			// 	}
			// }

			conditionLabel: statusLabel,
			details: { ...vessel, latestOperationMode: opModeData },
		}
		return rowData
	})
}

export const prepareTableData2 = (listOfVessels: { loaded: boolean; vessels: PlantInfo[] }) => {
	const vessels = listOfVessels?.vessels
	if (vessels === undefined) {
		return []
	}
	return vessels.map((vessel) => {
		const listOfEvents: string[] = getListOfEvents(vessels ?? [])
		const opModeData = vessel.latestOperationMode

		const opModeEvents = opModeData?.opModeEvents
		const opModes =
			opModeEvents !== undefined && opModeEvents.length > 0
				? opModeEvents.map((event) => `${event.subsystemName}: ${event.opModeName}`)
				: ["--"]

		const statusLabel = "green"
		// for (const key of Object.keys(rowData)) {
		// 	if (rowData[key]?.cellType !== CellContentType.TRAFFIC_LIGHT) {
		// 		continue
		// 	}
		// 	if (statusLabel === "red") {
		// 		continue
		// 	}
		// 	if (rowData[key]?.cellContent === "red") {
		// 		statusLabel = "red"
		// 	} else {
		// 		if (statusLabel === "yellow") {
		// 			continue
		// 		}
		// 		if (rowData[key]?.cellContent === "yellow") {
		// 			statusLabel = "yellow"
		// 		} else {
		// 			if (statusLabel === "") {
		// 				continue
		// 			}
		// 			if (rowData[key]?.cellContent === "") {
		// 				statusLabel = ""
		// 			}
		// 		}
		// 	}
		// }
		const rowData: MapTableItemST = {
			itemName: vessel.name,
			fishCount: opModeData?.fishCount !== undefined ? `${opModeData.fishCount}` : "--",
			// rowData["Open State"] = {
			// 	cellType: CellContentType.TEXT,
			// 	cellContent:
			// 		opModeData?.openState !== undefined && typeof opModeData.openState === "string"
			// 			? `${opModeData.openState.toUpperCase()}`
			// 			: "--",
			// }
			operationMode: opModes,
			// const devEvents = opModeData !== undefined ? opModeData.deviationEvents : undefined

			// if (devEvents !== undefined) {
			// 	for (const event of devEvents) {
			// 		if (rowData[event.categoryName] === undefined) {
			// 			rowData[event.categoryName] = {
			// 				cellType: CellContentType.TRAFFIC_LIGHT,
			// 				cellContent: event.severity ?? "green",
			// 			}
			// 		} else {
			// 			if (event.severity === "red") {
			// 				rowData[event.categoryName] = {
			// 					cellType: CellContentType.TRAFFIC_LIGHT,
			// 					cellContent: event.severity,
			// 				}
			// 			} else {
			// 				const rowRef = rowData[event.categoryName]
			// 				if (rowRef === undefined) {
			// 					continue
			// 				}
			// 				if (event.severity === "yellow" && rowRef.cellContent !== "red") {
			// 					rowRef.cellContent = event.severity
			// 				}
			// 			}
			// 		}
			// 	}
			// }

			// for (const event of listOfEvents) {
			// 	if (rowData[event] === undefined) {
			// 		rowData[event] = {
			// 			cellType: CellContentType.TRAFFIC_LIGHT,
			// 			cellContent: "",
			// 		}
			// 	}
			// }
			conditionLabel: statusLabel,
			details: { ...vessel, latestOperationMode: opModeData },
		}

		return rowData
	})
}
