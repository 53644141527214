import { LiceThresholdColors } from "@components/map/dataGettterHooks/useGetFishFarmDataForMap"

export type FishFarmBarentsWatchProperties = {
	avgAdultFemaleLice: number | null | ""
	color: LiceThresholdColors
	hasSalmonoids: boolean
	isOnLand: boolean
	isSlaughterHoldingCage: boolean
	municipalityName: string
	noNeedToReport: boolean
	probablyNoFish: boolean
	siteName: string
}

export const createFishFarmBarentsWatchTooltip = (properties: FishFarmBarentsWatchProperties) => {
	const mainDiv = L.DomUtil.create("div", "grid w-auto grid-rows-[48px_auto] gap-2 p-1")

	const div2 = L.DomUtil.create("div", "grid grid-cols-[48px_auto]")

	const div3 = L.DomUtil.create("div", "w-full h-full rounded-full")
	div3.style.backgroundColor = properties.color

	const div4 = L.DomUtil.create("div", "ml-4")

	const p = L.DomUtil.create("p", "font-bold text-gray-800 text-menu")
	p.textContent = properties.siteName

	const p2 = L.DomUtil.create("p", "text-gray-700 text-small")
	p2.textContent = properties.municipalityName

	div4.appendChild(p)
	div4.appendChild(p2)

	div2.appendChild(div3)
	div2.appendChild(div4)

	const p3 = L.DomUtil.create("p", "text-menu")
	p3.textContent =
		String(properties.avgAdultFemaleLice) == "null"
			? properties.noNeedToReport === true
				? "Not required to report lice status"
				: properties.probablyNoFish === true
					? "Probably no fish"
					: "Lice status not reported"
			: `Average lice count per fish: ${
					properties.avgAdultFemaleLice === "" ? "0" : properties.avgAdultFemaleLice
				}`

	mainDiv.appendChild(div2)
	mainDiv.appendChild(p3)

	return mainDiv
}
