import { getWarningCondition, WARNING_DIALOG_HEIGHT } from "@sop/DashboardPage/DashboardPage"
import { useLocation } from "@tanstack/react-router"
import { ReactNode, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { BodyLayout } from "./BodyLayout/BodyLayout"
import { HeaderLayout } from "./BodyLayout/HeaderLayout"
import { MainNav } from "./MainNav/MainNav"
import { SecondaryNav } from "./SecondaryNav/SecondaryNav"
import { IPageLayoutContext, ModuleMetadata, PageLayoutContext } from "./layoutContext"

interface IPageLayoutProps<TAppFeatures, TAppModulesList extends string> {
	t: ReturnType<typeof useTranslation>["t"] | undefined
	company?: string
	contents: ModuleMetadata<TAppFeatures, TAppModulesList>[]
	defaultModule: TAppModulesList
	children: ReactNode | ReactNode[]
	submitAction?: (name: string) => void
}

function ComponentBody<TAppFeatures, TAppModulesList extends string>({
	contents,
	defaultModule,
	children,
	t,
	submitAction,
}: Omit<IPageLayoutProps<TAppFeatures, TAppModulesList>, "company">) {
	const moduleName = useLocation().pathname.split("/").at(-1) as TAppModulesList
	const [selectedModule, setSelectedModule] = useState<TAppModulesList>(
		moduleName !== undefined && moduleName !== null
			? (moduleName.toLowerCase() as TAppModulesList)
			: defaultModule
	)
	const isMainNavExpandedLocalStorage = localStorage.getItem("isMainNavExpanded")
	const [isMainNavExpanded, setIsMainNavExpanded] = useState<boolean>(
		isMainNavExpandedLocalStorage === "false" ? false : true
	)

	const [isMainNavPopupExpanded, setIsMainNavPopupExpanded] = useState<boolean>(false)

	// const isSecondaryNavExpandedLocalStorage = localStorage.getItem("isSecondaryNavExpanded")
	const [isSecondaryNavExpanded, setIsSecondaryNavExpanded] = useState<boolean>(
		true
		// isSecondaryNavExpandedLocalStorage === "false" ? false : true
	)
	useEffect(() => {
		if (moduleName !== selectedModule) {
			setSelectedModule(
				moduleName !== undefined
					? (moduleName.toLowerCase() as TAppModulesList)
					: defaultModule
			)
		}
	}, [moduleName])

	const selectedContent = contents.find((c) => c.name === selectedModule)
	const memoizedContextValue: IPageLayoutContext<TAppModulesList> = useMemo(
		() => ({
			selectedModuleMetadata: selectedContent,
			mainItems: contents.map((c) => {
				return { name: c.name, icon: c.icon }
			}),
			isMainNavExpanded: isMainNavExpanded,
			setIsMainNavExpanded: setIsMainNavExpanded,
			isMainNavPopupExpanded: isMainNavPopupExpanded,
			isSecondaryNavExpanded: isSecondaryNavExpanded,
			setIsSecondaryNavExpanded: setIsSecondaryNavExpanded,
			setIsMainNavPopupExpanded: setIsMainNavPopupExpanded,
			translationFunc: t,
			submitAction,
		}),
		[selectedContent, isMainNavExpanded, isMainNavPopupExpanded, isSecondaryNavExpanded]
	)

	// const handleResize1 = () => {
	// 	setIsMainNavPopupExpanded(false)
	// }
	// useEffect(() => {
	// 	handleResize1()
	// 	window.addEventListener("resize", debounce(handleResize1, 100))
	// 	return () => {
	// 		window.removeEventListener("resize", handleResize1)
	// 	}
	// }, [])

	const warningCondition = getWarningCondition()
	return (
		<PageLayoutContext.Provider value={memoizedContextValue}>
			<div
				style={{
					height:
						warningCondition === true
							? `calc(100vh - ${WARNING_DIALOG_HEIGHT}px)`
							: `100vh`,
				}}
				className={`flex w-screen flex-row`}
			>
				{children}
			</div>
		</PageLayoutContext.Provider>
	)
}

function PageLayout<TAppFeatures, TAppModulesList extends string>(
	props: IPageLayoutProps<TAppFeatures, TAppModulesList>
) {
	// const PageLayout: FC<IPageLayoutProps<any>> = (props) => {
	const { company, contents, defaultModule, children, t, submitAction } = props
	if (company === undefined) {
		return <div> {`Server error: unkown company`}</div>
	}
	return (
		<ComponentBody
			contents={contents}
			defaultModule={defaultModule}
			children={children}
			t={t}
			submitAction={submitAction}
		/>
	)
}

const PageLayoutNamespace = Object.assign(PageLayout, {
	MainNav: MainNav,
	SecondaryNav: SecondaryNav,
	BodyLayout: BodyLayout,
})
export { HeaderLayout, PageLayoutNamespace as PageLayout }
