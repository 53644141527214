import { Column, ColumnType, TableDbEditingWidget, TableObject, getErrorAsString } from "api"
import { Fragment, KeyboardEvent, ReactElement, ReactNode, useEffect, useState } from "react"
import ReactSelect from "react-select"
import Button from "./Button"
import EditableTable from "./EditableTable"
import MultiSelectWrapper from "./MultiSelectWrapper"

class EditableTableCell {
	constructor(readonly key: string, public dbValue: string, readonly read: () => string) {}
}

class EditableTableRow {
	readonly cells: EditableTableCell[] = []

	constructor(readonly rowId: string) {}
}

function findInputElement(id: string): HTMLInputElement {
	const element = document.getElementById(id)
	if (element == undefined) {
		throw new Error(`Element not found by id: ${id}`)
	}
	return element as HTMLInputElement
}

function alertError(err: unknown) {
	const message = getErrorAsString(err).replaceAll(/\\n/g, "\n")
	console.error(message)
	alert(message)
}

export function DbConfigEditingWidget(args: {
	widget: TableDbEditingWidget
	doSubmit: (objects: TableObject[]) => Promise<void>
	doCreate?: () => Promise<void>
}) {
	const values = new Map<string, string | undefined>()
	function readElementValue(elementId: string) {
		const fromMap = values.get(elementId)
		if (fromMap != undefined) {
			return fromMap
		}
		const element = document.getElementById(elementId)
		if (element instanceof HTMLInputElement) {
			return element.value
		}
		return ""
	}

	async function commit() {
		const objects = new Array<TableObject>()
		const onSubmitted: (() => void)[] = []
		const rowChangeDescriptions: string[] = []
		editableRows.forEach((rc) => {
			const cellChangeDescriptions: string[] = []
			const oc: TableObject = {
				object: rc.rowId,
				properties: [],
			}
			rc.cells.forEach((cell) => {
				const newValue = cell.read()
				// If not changed, skip this cell
				if (!args.widget.pushAllProperties && newValue == cell.dbValue) {
					return
				}
				onSubmitted.push(() => (cell.dbValue = newValue))
				let readableValue = newValue
				if (readableValue.length > 0 && readableValue[0] == "[") {
					readableValue = readableValue.replaceAll(/\\"/g, "").replaceAll(/"/g, "")
				}
				cellChangeDescriptions.push(`${cell.key}="${readableValue}"`)
				oc.properties.push({
					key: cell.key,
					value: newValue,
				})
			})
			// If there are no changes, skip this row
			if (oc.properties.length == 0) {
				return
			}
			objects.push(oc)
			rowChangeDescriptions.push(
				`object ${rc.rowId}:\n ${cellChangeDescriptions.join("\n ")}`
			)
		})
		// If no rows are changed, return
		if (objects.length == 0) {
			return
		}
		// Show confirmation box
		setSubmitIsAllowed(false)
		let message = "Are you sure you want to submit these changes?\n\n"
		message += rowChangeDescriptions.join("\n")
		if (confirm(message) == true) {
			try {
				await args.doSubmit(objects)
				onSubmitted.forEach((action) => action())
			} catch (err: unknown) {
				alertError(err)
			}
		}
		setSubmitIsAllowed(true)
	}

	function makeCell(col: Column, row: EditableTableRow, value: string): ReactElement {
		let editableCell: EditableTableCell | undefined
		let cell: ReactElement | undefined
		const cellId = `${args.widget.label} cell ${cellCounter++}`
		const type = col.type
		switch (type) {
			case ColumnType.VarText: {
				cell = (
					<input
						id={cellId}
						placeholder="(No value)"
						defaultValue={value}
						autoComplete="nope"
						type="text"
						className="min-w-64"
					/>
				)
				editableCell = new EditableTableCell(col.label, value, () =>
					readElementValue(cellId)
				)
				break
			}
			case ColumnType.Select: {
				const options: ReactElement[] = []
				col.alternatives?.forEach((alt, index) => {
					options.push(
						<option key={index} value={alt.value}>
							{alt.label}
						</option>
					)
				})
				const keyDown = (evt: KeyboardEvent) => {
					if (!evt.ctrlKey) {
						return
					}
					if (evt.key == "c") {
						const element = findInputElement(cellId)
						console.log(element)
						navigator.clipboard.writeText(element.value)
					}
					if (evt.key == "v") {
						const element = findInputElement(cellId)
						navigator.clipboard.readText().then((text) => {
							element.value = text
						})
					}
				}
				const defaultValue = col.alternatives?.find((a) => a.value == value)
				values.set(cellId, defaultValue?.value ?? "")
				cell = (
					<div style={{ minWidth: "200px" }}>
						<ReactSelect
							inputId={cellId}
							options={col.alternatives ?? []}
							onKeyDown={keyDown}
							defaultValue={defaultValue}
							onChange={(v) => values.set(cellId, v?.value)}
						/>
					</div>
				)
				editableCell = new EditableTableCell(col.label, value, () =>
					readElementValue(cellId)
				)
				break
			}
			case ColumnType.VarBool: {
				cell = (
					<div className="alignmid">
						<input id={cellId} defaultChecked={value == "true"} type={"checkbox"} />
					</div>
				)
				editableCell = new EditableTableCell(col.label, value, () =>
					JSON.stringify(findInputElement(cellId).checked)
				)
				break
			}
			case ColumnType.MultiSelect: {
				let values = JSON.parse(value) as string[]
				const valuesToString = () => {
					values.sort()
					return JSON.stringify(values)
				}
				cell = (
					<MultiSelectWrapper
						alternatives={col.alternatives ?? []}
						initialValues={values}
						onChange={(newValues) => (values = newValues)}
					/>
				)
				editableCell = new EditableTableCell(col.label, valuesToString(), () =>
					valuesToString()
				)
				break
			}
			case ColumnType.Table: {
				const lines = value.split("\n")
				const header = lines[0]?.split("\t") ?? []
				let rows = lines.slice(1).map((line) => line.split("\t"))
				cell = (
					<EditableTable
						header={header}
						rows={rows}
						onChanged={(newData) => (rows = newData)}
					/>
				)
				editableCell = new EditableTableCell(col.label, value, () => {
					const newData = [header]
						.concat(rows)
						.map((row) => row.join("\t"))
						.join("\n")
					return newData
				})
				break
			}
			case ColumnType.Fixed: {
				break
			}
		}
		if (editableCell != undefined) {
			row.cells.push(editableCell)
		}
		if (cell == undefined) {
			cell = <>{value}</>
		}
		return <td>{cell!}</td>
	}

	function makeRow(data: string[], y: number): ReactNode {
		const rowIdentifierY = args.widget.rowIdentifiers[y]
		if (rowIdentifierY === undefined) {
			return null
		}
		const editableRow = new EditableTableRow(rowIdentifierY)
		editableRows.push(editableRow)

		return (
			<tr key={y.toString()}>
				{data.map((value, x) => {
					const columnX = args.widget.columns[x]
					if (columnX === undefined) {
						return null
					}
					return (
						<Fragment key={x.toString()}>
							{makeCell(columnX, editableRow, value)}
						</Fragment>
					)
				})}
			</tr>
		)
	}

	async function createNewObject() {
		try {
			if (args.doCreate == undefined) {
				throw new Error(`doCreate definition is missing`)
			}
			await args.doCreate()
		} catch (error) {
			alert(error)
		}
	}

	const [submitIsAllowed, setSubmitIsAllowed] = useState<boolean>(true)
	const headers: string[] = []
	const dataRows: string[][] = []

	args.widget.columns.forEach((col, colIndex) => {
		headers.push(col.label)
		col.cells.forEach((cell, rowIndex) => {
			if (rowIndex >= dataRows.length) {
				dataRows.push([])
			}
			const dataRowsRef = dataRows[rowIndex]
			if (dataRowsRef !== undefined) {
				dataRowsRef.push(cell.value)
			}
		})
	})

	const [rowElements, setRowElements] = useState<ReactNode[] | undefined>()
	let cellCounter = 0
	const [editableRows, setEditableRows] = useState(new Array<EditableTableRow>())
	// const editableRows = new Array<EditableTableRow>()

	useEffect(() => {
		const _rowElements = new Array<ReactNode>()
		dataRows.forEach((row, y) => {
			_rowElements.push(makeRow(row, y))
		})
		setRowElements(_rowElements)
	}, [])

	return (
		<div className="mt-6 mb-6">
			<h1 className="text-title3">{args.widget.label}</h1>
			{args.widget.description != undefined ? <p>{args.widget.description}</p> : undefined}
			{rowElements != undefined ? (
				<>
					<table className="w-full">
						<thead>
							<tr>
								{headers.map((header, index) => (
									<th key={index.toString()}>{header}</th>
								))}
							</tr>
						</thead>
						<tbody>{rowElements}</tbody>
					</table>
					<div style={{ display: "flex" }}>
						<Button label="Submit" onClick={commit} disabled={!submitIsAllowed} />{" "}
						{args.widget.includeCreationButton == true ? (
							<Button onClick={createNewObject} label="Add" />
						) : undefined}
					</div>
				</>
			) : (
				<>
					<p>Loading "{args.widget.label}"...</p>
				</>
			)}
		</div>
	)
}
