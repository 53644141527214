import { useAppSelector } from "@redux/app/hooks"
import { getAppStructure } from "@redux/features/appStructure/appStructureSlice"
import { createFileRoute, Navigate } from "@tanstack/react-router"
import { LoadingCircle } from "@ui-lib/index"

export const Route = createFileRoute("/")({
	component: RouteComponent,
})

function RouteComponent() {
	const appStruct = useAppSelector(getAppStructure)
	if (appStruct === null) {
		return <LoadingCircle containerHeightWithUnit="100vh" />
	}
	if (Object.keys(appStruct)?.[0]?.toLowerCase() === "dipai") {
		return <Navigate to="/clients" replace={true} />
	}
	return <Navigate to="/fleet-overview" replace={true} />
}
