import { PlantObject } from "api"

export function getSensorId(
	plantData: PlantObject | undefined,
	componentName: string,
	sensorName: string
): number {
	if (plantData === undefined) {
		return -1
	}
	const componentData = plantData.assets.find(
		(component) => component.display_name === componentName
	)
	if (componentData === undefined) {
		return -1
	}
	const sensorData = componentData.sensors.find((sensor) => sensor.display_name === sensorName)
	if (sensorData === undefined) {
		return -1
	}
	return sensorData.id
}
