import { zArr, zNullable, zNum, zObj, zOptional, zStr } from "api"
import { z } from "zod"

export const OpModePlantMetaData = zObj({
	opModeName: zStr(),
	opModeId: zNullable(zNum()),
	sensorIds: zOptional(zArr(zNum())),
	panelChannelIds: zOptional(zArr(zStr())),
	camerasChannelIds: zOptional(zArr(zStr())),
})
export type OpModePlantMetaData = z.infer<typeof OpModePlantMetaData>

export const OpModeLatestEventWithMetaData = OpModePlantMetaData.extend({
	subsystemName: zStr(),
	subsystemId: zNum(),
})
export type OpModeLatestEventWithMetaData = z.infer<typeof OpModeLatestEventWithMetaData>

export function prepOpModeName(opMode: OpModeLatestEventWithMetaData) {
	return `${opMode.subsystemName}: ${opMode.opModeName}`
}
