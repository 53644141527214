import { NotFoundPageNL } from "@pages/NotFoundPage/NotFoundPage"
import { useAppSelector } from "@redux/app/hooks"
import { getAppStructure } from "@redux/features/appStructure/appStructureSlice"
import DashboardPageLayout from "@sop/DashboardPage/DashboardPageLayout"
import { createFileRoute } from "@tanstack/react-router"
import { LoadingCircle } from "@ui-lib/index"

export type ModuleNameSOP =
	| "operations"
	| "vessel-status"
	| "fleet-overview"
	| "consumption"
	| "machinery"
	| "cbm"
	| "voyage"
	| "emission"
	| "historical-data"
	| "download-data"

export const Route = createFileRoute("/clients/$clientName/_sopPageLayout")({
	component: RouteComponent,
})

function RouteComponent() {
	const { clientName } = Route.useParams()
	const appStruct = useAppSelector(getAppStructure)
	const clientData = appStruct?.["Dipai"]?.clients?.[clientName as string]

	if (clientData === undefined) {
		return <NotFoundPageNL linkTo={"/clients"} labelTo="Go back" />
	}
	return appStruct === null ? (
		<LoadingCircle containerHeightWithUnit="100vh" />
	) : (
		<DashboardPageLayout props={{ [clientName]: clientData }} />
	)
}
