import { requestApi2 } from "@utils/http"
import { ConfigurationModule, endpoints } from "api"
import { useEffect, useState } from "react"
import DataEditPage from "./DataEditPage"

export default function OpModeDetectionPage() {
	const [isResetting, setIsResetting] = useState(false)
	const [schema, setSchema] = useState<string>()
	const [schemas, setSchemas] = useState<string[]>()

	useEffect(() => {
		async function getSchemas() {
			const res = await requestApi2(endpoints.opModeConfigGetSchemas)
			if (res === null) {
				return
			}
			setSchemas(res.map((s) => s.name))
		}
		getSchemas()
	}, [])

	return (
		<div>
			{schemas == undefined ? (
				<div>Loading ...</div>
			) : (
				<select
					onChange={(evt) => {
						setSchema(evt.target.value)
					}}
				>
					{[""].concat(schemas).map((s) => (
						<option key={s} value={s}>
							{s}
						</option>
					))}
				</select>
			)}
			{schema != undefined && schema != "" ? (
				<div>
					<DataEditPage module={ConfigurationModule.OpMode} _key={schema} />
				</div>
			) : (
				<div>Select a schema</div>
			)}
		</div>
	)
}
