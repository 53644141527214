import { getWindyDiv } from "@components/map/windyMap/windyMap"
import { useEffect, useState } from "react"

export default function useAssignWindyMapDiv() {
	const [windyDiv, setWindyDiv] = useState<HTMLDivElement>()
	useEffect(() => {
		setWindyDiv(getWindyDiv())
		if (windyDiv !== undefined) {
			const target = document.getElementById("windyHolder")
			target?.appendChild(windyDiv)
		}
	}, [windyDiv])
}
