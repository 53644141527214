import { removeIncompatibleSearchParameters } from "@helpers/removeIncompatibleSearchParameters"
import {
	cbmPageSearchSchema,
	validateCbmSearchParams,
} from "@routes/clients.$clientName/_sopPageLayout/cbm"
import { createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute("/_sopPageLayout/cbm")({
	component: RouteComponent,
	validateSearch: validateCbmSearchParams,
	search: {
		middlewares: [removeIncompatibleSearchParameters(cbmPageSearchSchema)],
	},
})

function RouteComponent() {
	return null
}
