import { useAppSelector } from "@redux/app/hooks"
import { getUserData } from "@redux/features/userData/userDataSlice"

export interface IReportPageHeaderProps {
	customerLogo: JSX.Element
	dipaiLogo: JSX.Element
	reportName: string
	dateGenerated: string
	dateFrom: string
	dateTo: string
	listOfVessels: string[]
}

export default function ReportPageHeaderDipai(props: IReportPageHeaderProps) {
	const userData = useAppSelector(getUserData)

	return (
		<div className={`flex h-[200px] w-full flex-row gap-[2px]`}>
			<div
				className={`relative h-full w-[400px] border-1 border-dipai-secondary-800 bg-white`}
			>
				{props.customerLogo}
			</div>
			<div className="relative flex h-full w-[calc(100%-400px-4px)] justify-between overflow-hidden bg-dipai-secondary-800">
				<div className="absolute bottom-0 right-[398px] z-1 flex h-[102%] w-5 origin-bottom-left -translate-y-[0px] rotate-[5deg] flex-row ">
					<div className={`w-1`} />
					<div className={`w-2 bg-dipai-primary-501`} />
					<div className={`w-10 bg-dipai-secondary-901`} />
				</div>
				<div className={`grid h-full w-[1000px] grid-rows-5 text-white`}>
					<p
						className={`row-span-2 content-center border-b-1 border-white px-4 text-title2 font-bold`}
					>
						{props.reportName.toUpperCase()}
					</p>
					<div className={`flex flex-row items-center gap-2 px-4 text-menu `}>
						<p className={`font-bold`}>{`Generated: `}</p>
						<p>{`${props.dateGenerated} by ${userData.name}`}</p>
					</div>
					<div className={`row-span-1 flex flex-row items-center gap-2 px-4 text-menu`}>
						{props.listOfVessels !== undefined ? (
							<>
								<p className={`font-bold`}>{`${
									props.listOfVessels.length > 1 ? "Vessels" : "Vessel"
								}: `}</p>
								<p>{`${props.listOfVessels}`.replaceAll(",", ", ")}</p>
							</>
						) : null}
					</div>
					<div className={`row-span-1 flex flex-row items-center gap-2 px-4 text-menu`}>
						<>
							<p className={`font-bold`}>{`Report range: `}</p>
							<p>{`${props.dateFrom} - ${props.dateTo}`}</p>
						</>
					</div>
				</div>
				<div className="relative h-full w-[400px] bg-dipai-secondary-901">
					{props.dipaiLogo}
				</div>
			</div>
		</div>
	)
}
