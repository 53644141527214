import { createFileRoute } from "@tanstack/react-router"

// TODO: add correct types
export type MachineryPageSearch = {
	vessel?: string
	opMode?: string
	rangeLoadAndInterval?: string
	toLoadAndInterval?: string
	fromLoadAndInterval?: string
}

export const validateMachinerySearch = (search: Record<string, unknown>): MachineryPageSearch => {
	// TODO: validate search params here
	return {
		vessel: (search.vessel as string) ?? undefined,
		opMode: (search.opMode as string) ?? undefined,
		rangeLoadAndInterval: (search.rangeLoadAndInterval as string) ?? undefined,
		toLoadAndInterval: (search.toLoadAndInterval as string) ?? undefined,
		fromLoadAndInterval: (search.fromLoadAndInterval as string) ?? undefined,
	}
}

export const Route = createFileRoute("/clients/$clientName/_sopPageLayout/machinery")({
	component: RouteComponent,
	validateSearch: validateMachinerySearch,
})

function RouteComponent() {
	return null
}
