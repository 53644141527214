import { flexRender, Row } from "@tanstack/react-table"
import { Tooltip } from "@ui-lib/src"
import { OpModeState } from "@utilityTypes/opmodeDefs"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { MdOutlineTrendingDown, MdOutlineTrendingUp } from "react-icons/md"

type Text = {
	type: "text"
	text: string
}

type NumberWithUnit = {
	type: "number-with-unit"
	value: number | string
	unit?: string
}

type TrafficLight = {
	type: "traffic-light"
	state: OpModeState | string
}

type FuelDeviationFromBaseline = {
	type: "fuel-deviation-from-baseline"
	baseline: number
	fuelDeviation: number
}

type TypeName = "text" | "number-with-unit" | "traffic-light" | "fuel-deviation-from-baseline"

type CellType<T> = T extends "text"
	? Text
	: T extends "number-with-unit"
	? NumberWithUnit
	: T extends "traffic-light"
	? TrafficLight
	: T extends "fuel-deviation-from-baseline"
	? FuelDeviationFromBaseline
	: never

export function CellSetterV2<T extends TypeName>({
	content,
	index,
}: {
	content: CellType<T>
	index: number | string
}) {
	const { t } = useTranslation()
	if (content.type === "text") {
		const { text } = content
		return (
			<div
				key={`${text}_${index}`}
				style={{ userSelect: "text" }}
				className="flex items-center pr-1"
			>
				{text}
			</div>
		)
	}

	if (content.type === "number-with-unit") {
		const { value } = content
		return (
			<div
				key={`${value}_${index}`}
				style={{ userSelect: "text" }}
				className={`flex items-center justify-end pr-1`}
			>
				{typeof value === "number" && isFinite(value)
					? Math.round(value * 100) / 100
					: value}
			</div>
		)
	}

	if (content.type === "traffic-light") {
		const { state } = content
		return (
			<div key={`${state}_${index}`} className="flex items-center justify-center font-bold">
				<div
					className={`flex h-7 w-7 items-center justify-center rounded-full ${
						state === OpModeState.GREEN
							? "bg-alert-sucess"
							: state === OpModeState.YELLOW
							? "bg-alert-warning"
							: state === OpModeState.RED
							? "bg-alert-error"
							: "bg-gray-666"
					}`}
				>
					<div
						className={`h-5 w-5 rounded-full border-2 ${
							state === OpModeState.GREEN
								? "bg-alert-sucess"
								: state === OpModeState.YELLOW
								? "bg-alert-warning"
								: state === OpModeState.RED
								? "bg-alert-error"
								: "bg-gray-666"
						}`}
					/>
				</div>
			</div>
		)
	}

	if (content.type === "fuel-deviation-from-baseline") {
		const { baseline, fuelDeviation } = content

		return (
			<div key={`${baseline}_${index}`} className="flex flex-row items-center justify-end">
				<Tooltip
					side={"left"}
					avoidCollisions={false}
					customContent={`${fuelDeviation.toFixed(3)} m³`}
				>
					<div
						style={{ userSelect: "text" }}
						className={`m-2 ${
							baseline === -Infinity ||
							baseline === Infinity ||
							baseline === null ||
							baseline === 0
								? `text-slate-700`
								: baseline > 0
								? baseline >= 5
									? `text-red-600`
									: `text-amber-600`
								: `text-green-600`
						}`}
					>
						{baseline === -Infinity ||
						baseline === Infinity ||
						baseline === null ||
						baseline === 0
							? `--%`
							: `${baseline.toFixed(2)}%`}
					</div>
				</Tooltip>
				<div
					className={`flex h-7 w-7 items-center justify-center rounded-full border-2  ${
						baseline === -Infinity ||
						baseline === Infinity ||
						baseline === null ||
						baseline === 0
							? `border-slate-200  text-tiny text-slate-400`
							: baseline > 0
							? baseline >= 5
								? `border-red-600  text-red-600`
								: `border-amber-600 font-bold text-amber-600`
							: `border-green-600 text-green-600`
					}`}
				>
					{/* {t("COLLECTING baseline V2")} */}
					{baseline === -Infinity ||
					baseline === Infinity ||
					baseline === null ||
					baseline === 0 ? (
						"---"
					) : baseline > 0 ? (
						<MdOutlineTrendingUp className="w-5 h-5" />
					) : (
						<MdOutlineTrendingDown className="w-5 h-5 " />
					)}
				</div>
			</div>
		)
	}

	return null
}

export default function BodyContentLayoutNL<T>({ row }: { row: Row<T> }) {
	return (
		<>
			{row.getVisibleCells().map((cell) => (
				<Fragment key={cell.id}>
					{flexRender(cell.column.columnDef.cell, cell.getContext())}
				</Fragment>
			))}
		</>
	)
}
