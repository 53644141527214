import { ReactElement, useEffect, useState } from "react"
import Button from "./Button"

export default function EditableTable(props: {
	header: string[]
	rows: string[][]
	onChanged?: (columns: string[][]) => void
}): ReactElement {
	const [rows, setRows] = useState(props.rows)

	const addRow = () => {
		let newRow: string[]
		const lastRow = rows.at(-1)
		if (lastRow != undefined) {
			newRow = Array.from(lastRow)
		} else {
			newRow = Array.from(Array(props.header.length)).map(() => "")
		}
		const _rows = Array.from(rows)
		_rows.push(newRow)
		setRows(_rows)
	}

	const deleteRow = (rowIndex: number) => {
		const confirmation = confirm(`Really delete row ${rowIndex}?`)
		if (confirmation == true) {
			const _rows = Array.from(rows)
			_rows.splice(rowIndex, 1)
			setRows(_rows)
		}
	}

	const edit = (rowIndex: number, colIndex: number, newValue: string) => {
		const _rows = Array.from(rows)
		const _rows_rowIndex = _rows[rowIndex]
		if (_rows_rowIndex !== undefined) {
			_rows_rowIndex[colIndex] = newValue
			setRows(_rows)
		}
	}

	const runAsyncAndAlertError = (fn: () => Promise<void>) => {
		fn().catch(alert)
	}

	const copyToClipboard = async () => {
		const text = [props.header]
			.concat(rows)
			.map((row) => row.join("\t"))
			.join("\n")
		await navigator.clipboard.writeText(text)
	}

	const pasteFromClipboard = async () => {
		const text = await navigator.clipboard.readText()
		if (text == "") {
			return
		}
		const lines = text.split("\n")
		const header = lines[0]?.split("\t") ?? []
		for (const [index, label] of header.entries()) {
			const expectedHeader = props.header[index]
			if (expectedHeader != label.trim()) {
				throw new Error(`Expected header "${expectedHeader}" at position ${index}`)
			}
		}
		const newRows: string[][] = []
		for (const line of lines.slice(1)) {
			const fixedLine = line.replaceAll("\r", "\n")
			if (fixedLine.length == 0) {
				continue
			}
			newRows.push(fixedLine.split("\t").map((cell) => cell.trim()))
		}
		setRows(newRows)
	}

	useEffect(() => {
		props.onChanged?.(rows)
	}, [rows])

	return (
		<>
			<table>
				<thead>
					<tr>
						<td>#</td>
						{props.header.map((label, i) => (
							<td key={i}>{label}</td>
						))}
						<td></td>
					</tr>
				</thead>
				<tbody>
					{rows.map((row, rowIndex) => (
						<tr key={rowIndex}>
							<td>{rowIndex}</td>
							{row.map((cell, cellIndex) => (
								<td key={cellIndex}>
									<input
										onChange={(evt) =>
											edit(rowIndex, cellIndex, evt.target.value)
										}
										value={cell}
									/>
								</td>
							))}
							<td>
								<Button label="X" onClick={() => deleteRow(rowIndex)} />
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<div style={{ display: "flex" }}>
				<Button label="add row" onClick={addRow} />
				<Button label="copy" onClick={() => runAsyncAndAlertError(copyToClipboard)} />
				<Button label="paste" onClick={() => runAsyncAndAlertError(pasteFromClipboard)} />
			</div>
		</>
	)
}
