import { Link, useLocation } from "@tanstack/react-router"
import { memo } from "react"
import { useTranslation } from "react-i18next"
import { LinkData } from "./Page"

const TopBar = memo(({ links }: { links: LinkData[] }) => {
	const { t } = useTranslation()
	const location = useLocation().pathname
	return (
		<div className="flex w-auto gap-8">
			{links.map((link) => (
				<Link
					key={`divTopBarButton${link.linkLabel}`}
					style={{
						borderRadius: "0.125rem",
						cursor: "pointer",
						height: "2.75rem",
					}}
					className="mb-1 mt-1 flex h-[2.75rem] cursor-pointer items-center rounded-sm px-2 py-2 text-menu font-bold uppercase leading-10 hover:bg-dipai-tertiary-801"
					activeProps={{
						className:
							"border-b-4 border-dipai-primary-501 text-dipai-primary-501 hover:bg-dipai-tertiary-801/0",
						style: {
							borderRadius: "0rem",
							cursor: "default",
							height: "3rem",
						},
					}}
					onClick={(e) =>
						location.toLowerCase().includes(link.linkUrl.toLowerCase()) === true &&
						e.preventDefault()
					}
					to={`/${link.linkUrl}`}
					replace={false}
				>
					{t(link.linkLabel)}
				</Link>
			))}
		</div>
	)
})

export default TopBar
