import { EventSource } from "@utils/EventSource"
import { ConnectionStatus } from "./ConnectionStatus"

export class WebSocketWrapper {
	readonly onMessage = new EventSource<string>()
	readonly onStatusChanged = new EventSource<ConnectionStatus>()

	#connectBusy = false
	#pingInterval?: NodeJS.Timeout
	#pingTimeout?: NodeJS.Timeout
	#socket?: WebSocket

	constructor(private readonly url: string) {
		setInterval(() => {
			this.#step()
		}, 2000)
	}

	get status(): ConnectionStatus {
		return this.#socket != undefined ? "connected" : "connecting"
	}

	sendMessage(data: string) {
		if (this.#socket == undefined) {
			throw new Error("Socket is disconnected")
		}
		this.#socket.send(data)
	}

	#initPingInterval() {
		this.#pingInterval = setInterval(() => {
			this.#socket?.send("ping")
			this.#pingTimeout = setTimeout(() => {
				console.error(new Error(`Ping timeout`))
				this.#socket?.close()
			}, 10000)
		}, 20000)
	}

	#pushStatus() {
		this.onStatusChanged.tryEmit(this.status)
	}

	#onClosed() {
		clearInterval(this.#pingInterval)
		clearTimeout(this.#pingTimeout)
		this.#socket = undefined
		this.#pushStatus()
		this.#connectBusy = false
	}

	#step() {
		if (this.#connectBusy) {
			return
		}
		this.#connectBusy = true
		const socket = new WebSocket(this.url)
		socket.onopen = () => {
			this.#socket = socket
			this.#pushStatus()
			this.#initPingInterval()
		}
		socket.onclose = () => {
			this.#onClosed()
		}
		socket.onmessage = (event) => {
			if (event.data == "pong") {
				clearTimeout(this.#pingTimeout)
				return
			}
			this.onMessage.tryEmit(event.data)
		}
	}
}
