import { ReactElement } from "react"
import { PaginationButton, PaginationControlsProps } from "../PaginationControls"

export function PaginationControlsMapPage(props: PaginationControlsProps): ReactElement {
	return (
		<div className={`flex h-8 w-full flex-row items-center justify-end gap-12 bg-white px-4`}>
			{/* LEFT AREA */}
			<div className="" />
			{/* CENTRAL AREA */}
			<div className="grid grid-cols-7 gap-2">
				{/* TO FIRST PAGE */}
				<PaginationButton
					disabledCondition={props.getCanPreviousPage()}
					onClickHandler={() => props.setPageIndex(0)}
					iconId={"first"}
					positionRef={"right"}
				/>
				{/* TO PREVIOUS PAGE */}
				<PaginationButton
					disabledCondition={props.getCanPreviousPage()}
					onClickHandler={() => props.previousPage()}
					iconId={"previous"}
					positionRef={"right"}
				/>
				{/* CURRENT PAGE DISPLAY */}
				<div className="col-span-3 h-8 text-center">
					<h1 className="text-menu inline-block h-8 py-[4px]">
						{`${props.translationFunc("PAGE")} ${
							props.tableState.pagination.pageIndex + 1
						} ${props.translationFunc("OF")} ${props.getPageCount()}`}
					</h1>
				</div>
				{/* TO NEXT PAGE */}
				<PaginationButton
					disabledCondition={props.getCanNextPage()}
					onClickHandler={() => props.nextPage()}
					iconId={"next"}
					positionRef={"left"}
				/>
				{/* TO LAST PAGE */}
				<PaginationButton
					disabledCondition={props.getCanNextPage()}
					onClickHandler={() => props.setPageIndex(props.getPageCount() - 1)}
					iconId={"last"}
					positionRef={"left"}
				/>
			</div>
			{/* RIGHT AREA */}
			<div className="">
				{/* AMOUNT TO DISPLAY DROPDOWN */}
				<select
					value={props.tableState.pagination.pageSize}
					className={`border-gray-ccc text-small hover:border-dipai-secondary-500 focus:border-dipai-secondary-500 mr-[1px] h-8 w-[112.5px] rounded-sm border-1 bg-transparent py-[2px] pr-10 pl-3 hover:cursor-pointer focus:mx-0 focus:mr-0 focus:w-[113.5px] focus:border-2 focus:pr-[39px] focus:pl-[11px] focus:ring-0 focus:outline-hidden`}
					onChange={(e) => props.setPageSize(Number(e.target.value))}
				>
					{[10, 25, 50].map((pageSize) => (
						<option
							key={pageSize}
							value={pageSize}
						>
							{`${props.translationFunc("SHOW")} ${pageSize}`}
						</option>
					))}
				</select>
			</div>
		</div>
	)
}
