import { InformationContainer } from "@components/containers/Containers"
import FeatureGuard from "@components/featureGuard/FeatureGuard"
import { findValeOfKeyLike } from "@components/kpi/widgets/MultiValueWidget"
import PositionDataKpi from "@components/positionDataKpi/PositionDataKpi"
import SectionTitle from "@components/sectionTitle/SectionTitle"
import { SectionTitleSize, SectionTitleType } from "@components/sectionTitle/types"
import ShipImgContainer from "@components/shipImgContainer/ShipImgContainer"
import { findDeviationSeverity } from "@helpers/findDeviationSeverity"
import { RequestStatus } from "@redux/app/RequestStatus"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import { getCurrentCompany } from "@redux/features/currentCompany/currentCompanySlice"
import { getListOfVessels } from "@redux/features/listOfVessels/listOfVesselsSlice"
import {
	getPanelState,
	updateSidePanelState,
} from "@redux/features/mapSidePanelControl/mapSidePanelControlSlice"
import { getOfflineVessels } from "@redux/features/offlineVessels/offlineVesselsSlice"
import {
	clearSelectedVessel,
	getSelectedVessel,
	setSelectedVessel,
} from "@redux/features/selectedVessel/selectedVesselSlice"
import { getUniSeaVessels } from "@redux/features/uniSeaVessels/uniSeaVesselsSlice"
import { getWarningCondition, WARNING_DIALOG_HEIGHT } from "@sop/DashboardPage/DashboardPage"
import { useNavigate, useSearch } from "@tanstack/react-router"
import { IconButton, LoadingCircle, Tooltip } from "@ui-lib/src"
import { OpModeState } from "@utilityTypes/opmodeDefs"
import { common } from "@utils/common"
import { PortalFeature } from "api"
import { DateTime } from "luxon"
import { ReactElement, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdClose, MdFeed, MdSignalWifiStatusbarConnectedNoInternet } from "react-icons/md"
import { CoordLine } from "./CoordLine"
import LocalityTimeline from "./LocalityTimeline/LocalityTimeline"
import { PositionDataInfoLine } from "./PositionDataInfoLine"
import VesselSpecSheetContent from "./VesselSpecSheetContent"
import { TableListOfPlants } from "./table/TableListOfPlants"
import FishKpis from "./vesselKpis/FishKpis"
import FuelAndPowerKpis from "./vesselKpis/FuelAndPowerKpis"
import WaterQualityKpis from "./vesselKpis/WaterQualityKpis"
import { VESSELS_SPEC_SHEET_DATA } from "./vesselsSpecSheetData"

export default function VesselsContentNL({
	showHeader = true,
}: {
	showHeader?: boolean
}): ReactElement | null {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { vessel } = useSearch({ strict: false }) //Route.useSearch()

	const dispatch = useAppDispatch()
	const listOfVessels = useAppSelector(getListOfVessels)
	const offlineVessels = useAppSelector(getOfflineVessels)
	const uniSeaVessels = useAppSelector(getUniSeaVessels)

	const panelState = useAppSelector(getPanelState)

	useEffect(() => {
		if (vessel === undefined) {
			dispatch(clearSelectedVessel())
		} else {
			const vesselData = listOfVessels.vessels.find(
				(v) => v.name.toLowerCase() === vessel.toLowerCase()
			)
			if (vesselData !== undefined) {
				const plantObject = findValeOfKeyLike(
					common.plantDataFromOwnerAndName,
					vessel.toLowerCase()
				)
				dispatch(
					updateSidePanelState({
						...panelState,
						isTableExpanded: false,
					})
				)
				dispatch(setSelectedVessel(vesselData))
			}
		}
	}, [vessel, listOfVessels])

	const selectedVessel = useAppSelector(getSelectedVessel)
	const companyName = useAppSelector(getCurrentCompany)

	const fetchSelectedVesselDataStatus = useAppSelector((state) => state.selectedVessel.status)
	const plantData =
		companyName?.toLowerCase() !== "dipai"
			? common.plantDataFromOwnerAndName.get(
					`${companyName?.toLowerCase()}/${selectedVessel.name?.toLowerCase()}`
				)
			: common.plantDataFromName.get(selectedVessel.name)
	const latestOpMode = selectedVessel.latestOperationMode
	const latestOpMode2 = selectedVessel.latestOperationMode2

	const selectedVesselSpecSheetData = VESSELS_SPEC_SHEET_DATA[selectedVessel.name]
	const vesselOfflineData = offlineVessels.find((vesselData) => vesselData.name === vessel)

	const vesselUniSeaData = uniSeaVessels?.find((vesselData) => vesselData.name === vessel)

	const [painelContainerRef, setPainelContainerRef] = useState<HTMLDivElement | null>(null)
	const warningCondition = getWarningCondition()

	return companyName !== undefined ? (
		<div
			ref={(newRef) => setPainelContainerRef(newRef)}
			className="grid w-full overflow-x-auto"
		>
			{selectedVessel.name === "" ? (
				<div className="h-screen w-full overflow-auto">
					{showHeader !== false ? (
						<div className="bg-dipai-secondary-901 flex h-[52px] w-[calc(100%)] items-center px-4">
							<p className={"text-title5 font-bold text-white"}>{t("MAP")}</p>
						</div>
					) : null}
					<div
						style={{
							height:
								warningCondition === true
									? `calc(100vh - 52px - ${WARNING_DIALOG_HEIGHT}px)`
									: `calc(100vh - 52px)`,
						}}
						className={`overflow-auto pr-4`}
					>
						<InformationContainer>
							<div className={`relative`}>
								<TableListOfPlants customerName={companyName} />
							</div>
						</InformationContainer>
					</div>
				</div>
			) : plantData !== undefined ? (
				<>
					<div className="bg-dipai-secondary-901 flex h-[52px] w-full items-center justify-between px-4">
						<p className="text-headline text-white">
							{selectedVessel.name}
							{companyName.toLowerCase().includes(" demo") === false &&
								selectedVessel.vessel?.mmsi !== undefined && (
									<span className="text-tiny pl-2">{`(MMSI: ${selectedVessel.vessel.mmsi})`}</span>
								)}
						</p>
						<div className={`flex flex-row gap-2`}>
							<Tooltip
								collisionBoundary={painelContainerRef}
								customContent={t("CLOSE")}
							>
								<IconButton
									size={"sm"}
									variant={"text-light"}
									onClick={() => {
										dispatch(clearSelectedVessel())
										navigate({
											to: ".",
											search: (prev) => ({
												...prev,
												vessel: undefined,
											}),
										})
									}}
								>
									<IconButton.Icon>{MdClose}</IconButton.Icon>
								</IconButton>
							</Tooltip>
						</div>
					</div>
					{vesselUniSeaData !== undefined ? (
						<div className="text-tiny mx-1 mt-1 mb-1 flex h-auto w-[calc(100%-8px)] flex-row items-center gap-3 rounded-sm border-1 border-amber-300 bg-amber-50 px-3 py-1 font-medium text-amber-700">
							<MdFeed className={`h-5 w-5 cursor-help text-blue-400`} />
							<div
								className={`border-gray-ccc flex flex-row items-center gap-1 rounded-full border-0`}
							>
								<div className={``}>
									<p className={``}>{`${t(
										"ONLY UNISEA DATA IS AVAILABLE FOR THIS VESSEL"
									)}`}</p>
									<p>
										{`Data ${t(`AVAILABLE`)} ${t(`FROM`).toLowerCase()}: ${
											new Date(vesselUniSeaData.firstTimestamp)
												.toString()
												.split(" (")[0]
										}`}
									</p>
								</div>
							</div>
						</div>
					) : null}
					{vesselOfflineData !== undefined && vesselUniSeaData === undefined ? (
						<div className={`border-b-1 p-1`}>
							<div className="text-tiny flex h-auto w-full flex-row items-center gap-3 rounded-sm border-1 border-amber-300 bg-amber-50 px-3 py-1 font-medium text-amber-700">
								<MdSignalWifiStatusbarConnectedNoInternet
									className={`h-5 w-5 text-amber-700`}
								/>
								<div className={``}>
									{vesselOfflineData.lastUpdateTimestamp !== null ? (
										<>
											<p>{t(`Vessel is currently offline.`)}</p>
											<p>
												{t(
													`Last update: ${
														new Date(
															vesselOfflineData.lastUpdateTimestamp
														)
															.toString()
															.split(" (")[0]
													}`
												)}
											</p>
										</>
									) : (
										<p>{t(`No data found for this vessel.`)}</p>
									)}
								</div>
							</div>
						</div>
					) : null}
					<div
						style={{
							height:
								vesselOfflineData !== undefined
									? companyName?.toLowerCase() !== "dipai"
										? `calc(100vh - 52px - 59px - ${warningCondition === true ? WARNING_DIALOG_HEIGHT : 0}px)`
										: `calc(100vh - 52px - 52px - 59px - ${warningCondition === true ? WARNING_DIALOG_HEIGHT : 0}px)`
									: companyName?.toLowerCase() !== "dipai"
										? `calc(100vh - 52px - ${warningCondition === true ? WARNING_DIALOG_HEIGHT : 0}px)`
										: `calc(100vh - 52px - 52px - ${warningCondition === true ? WARNING_DIALOG_HEIGHT : 0}px)`,
						}}
						className={`w-full overflow-auto`}
					>
						<ShipImgContainer shipName={selectedVessel.name} />
						<SectionTitle
							title={"OPERATION MODE"}
							type={SectionTitleType.vessel}
							size={SectionTitleSize.medium}
						/>
						<InformationContainer>
							<>
								{latestOpMode2 !== undefined ? (
									<p className="text-menu font-medium text-black uppercase">
										{latestOpMode2.opModeName}
									</p>
								) : latestOpMode !== undefined &&
								  ((latestOpMode?.opModeEvents !== undefined &&
										latestOpMode?.opModeEvents.length > 0) ||
										latestOpMode?.openState !== undefined) ? (
									<>
										{latestOpMode.opModeEvents !== undefined &&
											latestOpMode?.opModeEvents.length > 0 && (
												<div className="flex gap-4 pb-2">
													<p className="text-menu text-gray-666 w-[120px]">
														{"Operation"}
													</p>
													<div className="flex flex-col gap-2">
														{latestOpMode?.opModeEvents.map(
															(opModeEvent, index) => (
																<div
																	key={`OpMode_${index}`}
																	className="flex items-center gap-2"
																>
																	<div
																		className={`h-3 w-3 rounded-full ${
																			findDeviationSeverity(
																				selectedVessel
																					?.latestOperationMode
																					?.deviationEvents ??
																					[],
																				opModeEvent.subsystemId
																			) === OpModeState.RED
																				? "bg-alert-error"
																				: findDeviationSeverity(
																							selectedVessel
																								?.latestOperationMode
																								?.deviationEvents ??
																								[],
																							opModeEvent.subsystemId
																					  ) ===
																					  OpModeState.YELLOW
																					? "bg-alert-warning"
																					: findDeviationSeverity(
																								selectedVessel
																									?.latestOperationMode
																									?.deviationEvents ??
																									[],
																								opModeEvent.subsystemId
																						  ) ===
																						  OpModeState.GREEN
																						? "bg-alert-sucess"
																						: "bg-gray-666"
																		}`}
																	/>
																	{/* <OpModePopover
															opModeData={OP_MODE_FAKE_DATA}
															vesselName={selectedVessel.name}
														> */}
																	<p className="text-menu text-gray-666 cursor-pointer underline">
																		{`${opModeEvent.subsystemName}: ${opModeEvent.opModeName}`}
																	</p>
																	{/* </OpModePopover> */}
																</div>
															)
														)}
													</div>
												</div>
											)}
										{latestOpMode?.openState !== undefined && (
											<div className="flex gap-4 border-t-1 pt-2 pb-2">
												<p className="text-menu text-gray-666 w-[120px]">
													{"Open to sea"}
												</p>

												<p className="text-menu text-gray-666 pl-5 uppercase">
													{latestOpMode.openState}
												</p>
											</div>
										)}
										{/* {latestOpMode.wellControl
												?.length > 0 && (
												<div className="flex gap-4 pt-2 border-t-1">
													<p className="w-[120px] text-menu text-gray-666">
														{"Well Control"}
													</p>
													<div
														className={`grid-rows-[${latestOpMode.wellControl.length} grid]`}
													>
														{latestOpMode.wellControl.map(
															(opMode, index) => (
																<div
																	key={index}
																	className="flex items-center gap-2"
																>
																	<p
																		key={index}
																		className="pl-5 text-menu text-gray-666"
																	>
																		{`${
																			opMode.zone.split(
																				"ActiveQuick"
																			)[1] ??
																			"Unidentified Zone"
																		}: ${opMode.mode}`}
																	</p>
																</div>
															)
														)}
													</div>
												</div>
											)} */}
									</>
								) : (
									"Unknown Operation Mode"
								)}
							</>
						</InformationContainer>
						{companyName?.toLowerCase() !== "dipai" ? (
							<FeatureGuard
								featureName={PortalFeature.VESSEL_KPIS}
								plant={plantData}
							>
								<>
									<SectionTitle
										title={"POWER AND FUEL"}
										type={SectionTitleType.vessel}
										size={SectionTitleSize.medium}
									/>
									<FuelAndPowerKpis
										plantId={plantData.id}
										customerName={companyName}
									/>
								</>
							</FeatureGuard>
						) : null}
						{companyName?.toLowerCase() !== "dipai" ? (
							<FeatureGuard
								featureName={PortalFeature.FISH_KPIS}
								plant={plantData}
							>
								<>
									<SectionTitle
										title={"FISH DATA"}
										type={SectionTitleType.vessel}
										size={SectionTitleSize.medium}
									/>
									<FishKpis customerName={companyName} />
								</>
							</FeatureGuard>
						) : null}
						{companyName?.toLowerCase() !== "dipai" ? (
							<FeatureGuard
								featureName={PortalFeature.WATER_QUALITY_KPIS}
								plant={plantData}
							>
								<>
									<SectionTitle
										title={"WATER QUALITY"}
										type={SectionTitleType.vessel}
										size={SectionTitleSize.medium}
									/>
									<WaterQualityKpis customerName={companyName} />
								</>
							</FeatureGuard>
						) : null}
						<SectionTitle
							title={"POSITION DATA"}
							type={SectionTitleType.vessel}
							size={SectionTitleSize.medium}
						/>
						<InformationContainer>
							{selectedVessel.position !== undefined ? (
								<>
									<div className="mb-4 grid grid-cols-3">
										<PositionDataKpi
											label={"SPEED"}
											unit={"knots"}
											value={selectedVessel.position!.sog}
										/>
										<PositionDataKpi
											label={"HEADING"}
											unit={"°"}
											value={selectedVessel.position!.heading}
										/>
										<PositionDataKpi
											label={"COURSE"}
											unit={"°"}
											value={selectedVessel.position!.cog}
										/>
									</div>
									<div className="grid grid-rows-4">
										{CoordLine(
											selectedVessel.position?.lat !== undefined
												? selectedVessel.position?.lat.toString()
												: undefined,
											selectedVessel.position?.lon !== undefined
												? selectedVessel.position?.lon.toString()
												: undefined
										)}
										<PositionDataInfoLine
											text={`Last update: ${DateTime.fromISO(
												selectedVessel.position!.timeStamp
											).toFormat("cccc, LLL dd, tt")}`}
										/>
										<PositionDataInfoLine
											text={
												selectedVessel.vessel !== undefined
													? `Destination: ${
															selectedVessel.vessel!.destination
														}`
													: `No destination information available`
											}
										/>
									</div>
								</>
							) : (
								<p> {"No AIS data available"} </p>
							)}
						</InformationContainer>
						{selectedVesselSpecSheetData !== undefined ? (
							<VesselSpecSheetContent
								vesselSpecSheetData={selectedVesselSpecSheetData}
							/>
						) : null}
						{companyName?.toLowerCase() !== "dipai" ? (
							<FeatureGuard
								featureName={PortalFeature.TIMELINE}
								plant={plantData}
							>
								<>
									<SectionTitle
										title={"VESSEL TIMELINE"}
										type={SectionTitleType.vessel}
										size={SectionTitleSize.medium}
									/>
									{fetchSelectedVesselDataStatus === RequestStatus.loading ? (
										<InformationContainer>
											<LoadingCircle containerHeightWithUnit={"300px"} />
										</InformationContainer>
									) : (
										<InformationContainer>
											<LocalityTimeline />
										</InformationContainer>
									)}
								</>
							</FeatureGuard>
						) : null}
					</div>
				</>
			) : (
				<p>Vessel data not found</p>
			)}
		</div>
	) : null
}
