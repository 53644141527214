import { CustomCircleOptions } from "@components/map/layersDataSetters/addFacilitiesWithRadius"
import { parseCoordFromDDToDDM } from "@helpers/parseCoordFromDDToDDM"
import { AppDispatch } from "@redux/app/store"
import { updateMapParamsState } from "@redux/features/mapParams/mapParamsSlice"
import { useNavigate } from "@tanstack/react-router"
import { debounce } from "@ui-lib/index"
import { requestApi2 } from "@utils/http"
import { endpoints, FrontEndKey } from "api"
import {
	GeoJSON,
	LatLng,
	LatLngBounds,
	LayerGroup,
	Map as LeafletMap,
	Marker,
	Polyline,
} from "leaflet"
import { QueryClient } from "react-query"
import {
	defaultLayerConstructor,
	LayerConstructor,
} from "../layersConstructors/defaultLayerConstructor"
import { createCombinedTooltip } from "../mapComponents/createCombinedTooltip"
import { addLayersToMap, DataSetter, LayerData, LayerObject, MapLayers } from "../utils/mapLayers"
import { getLayersAtPoint, MapElementMetadata } from "../utils/utils"
import { LayerOptionsWithMetadata } from "../windyMap/windyMap"

export class RegularMap {
	static async create(
		dispatch: AppDispatch,
		divId: string = "regularMap",
		queryClient: QueryClient,
		navigate: ReturnType<typeof useNavigate> | undefined
	): Promise<RegularMap> {
		const result = new RegularMap(dispatch, divId, queryClient, navigate)
		await result.init()
		return result
	}

	addLayer = (
		layerName: string,
		zIndex: number,
		dataSetter: DataSetter,
		constructor?: LayerConstructor
	) => {
		if (this.map === undefined) {
			return
		}
		let layerGroup: LayerGroup | undefined
		if (constructor !== undefined) {
			layerGroup = constructor(layerName, zIndex, this.map)
		} else {
			layerGroup = defaultLayerConstructor(layerName, zIndex, this.map)
		}
		if (layerGroup !== undefined) {
			this.layersMap.set(layerName, { layerGroup, dataSetter })
		}
	}

	areLayersGenerated = () => {
		return this.areLayersReady
	}

	getMapKyes = async () => {
		const data = await requestApi2(endpoints.getEnvVariables, {
			keys: [FrontEndKey.MAPBOX_KEY],
		})
		return data
	}

	isMapInitialized = () => {
		return this.map !== undefined
	}

	setAreLayersGenerated = () => {
		this.areLayersReady = true
	}

	setLayerData = (layerData: LayerData) => {
		const layerGroupObj = this.layersMap.get(layerData.mapLayerName)
		if (layerGroupObj !== undefined) {
			layerGroupObj.dataSetter(
				this.dispatch,
				this.queryClient,
				layerGroupObj.layerGroup,
				layerData,
				this.navigate,
				this.map
			)
		}
	}
	clearLayerData = (mapLayerName: MapLayers) => {
		const layerGroupObj = this.layersMap.get(mapLayerName)
		if (layerGroupObj !== undefined) {
			layerGroupObj.layerGroup.clearLayers()
		}
	}

	removeMap = () => {
		if (this.map !== undefined) {
			this.map.off()
			this.map.remove()
			this.map = undefined
			console.log("map removed: ", this.map)
		}
	}
	private areLayersReady: boolean = false
	private currentZoom: number = 0
	private dispatch: AppDispatch
	private queryClient: QueryClient
	private navigate: ReturnType<typeof useNavigate> | undefined
	private divId: string
	private layersMap: Map<string, LayerObject> = new Map()
	private map?: LeafletMap
	private mapBoxTilesOpacity: boolean = false
	private zoomThreshold: number = 1

	private constructor(
		dispatch: AppDispatch,
		divId: string,
		queryClient: QueryClient,
		navigate: ReturnType<typeof useNavigate> | undefined = undefined
	) {
		this.dispatch = dispatch
		this.queryClient = queryClient
		this.navigate = navigate
		this.divId = divId
	}

	setNavigateFunc(navigate: ReturnType<typeof useNavigate> | undefined = undefined) {
		if (this.map === undefined) {
			return
		}
		this.navigate = navigate
	}

	clearLayers() {
		if (this.map !== undefined) {
			this.layersMap.forEach(
				(value) => value.layerGroup !== undefined && value.layerGroup.clearLayers()
			)
		}
	}

	fitBounds(bounds: LatLngBounds, animate: boolean = true) {
		this.map?.invalidateSize()
		if (bounds.isValid() !== true || this.map === undefined) {
			return
		}
		this.map.flyToBounds(bounds, {
			// this.map.fitBounds(bounds, {
			duration: 0.5,
			easeLinearity: 1,
			maxZoom: 11,
			noMoveStart: true,
			animate,
		})
	}

	getBoundsZoom(bounds: LatLngBounds) {
		if (bounds.isValid() !== true || this.map === undefined) {
			return undefined
		}
		return this.map.getBoundsZoom(bounds)
	}

	setView(center: LatLng, zoom: number) {
		if (this.map === undefined) {
			return
		}
		this.map.setView(center, zoom)
	}

	getShowMapBoxTiles() {
		return this.mapBoxTilesOpacity
	}

	resizemap() {
		this.map !== undefined && this.map?.invalidateSize()
	}

	setCssStyle(querySelectors: string[], propertyString: string) {
		for (const querySelector of querySelectors) {
			const element = document.querySelector(querySelector)
			if (element !== null) {
				element.setAttribute("style", propertyString)
			}
		}
	}

	private async init() {
		const vars = await this.getMapKyes()
		const mapboxKey = vars?.find((v) => v.key == "MAPBOX_KEY")?.value
		if (mapboxKey === undefined) {
			return
		}
		await new Promise<void>((resolve) => {
			const map = L.map(this.divId).setView([62.45, 6.26], 10)

			const mapBoxTiles = L.tileLayer(
				`https://api.maptiler.com/maps/topo/{z}/{x}/{y}.png?key=${mapboxKey}`,
				{
					attribution:
						'\u003ca href="https://www.maptiler.com/copyright/" target="_blank"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href="https://www.openstreetmap.org/copyright" target="_blank"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e',
					maxZoom: 18,
					id: "mapbox/streets-v11",
					tileSize: 512,
					accessToken: mapboxKey,
					zoomOffset: -1,
				}
			).addTo(map)

			const coordsDiv = L.DomUtil.create("div", "leaflet-mouse-coordinates")
			coordsDiv.style.position = "absolute"
			coordsDiv.style.bottom = "46px"
			coordsDiv.style.right = "10px"
			coordsDiv.style.background = "rgba(255, 255, 255, 0.5)"
			coordsDiv.style.padding = "0px 4px"
			coordsDiv.style.borderRadius = "0px"
			coordsDiv.style.fontSize = "11px"
			coordsDiv.style.lineHeight = "16px"
			coordsDiv.style.minWidth = "140px"
			coordsDiv.style.minHeight = "16px"
			coordsDiv.style.borderRightWidth = "1.6px"
			coordsDiv.style.borderRightColor = "rgb(119, 119, 119)"
			coordsDiv.style.zIndex = "1000"
			coordsDiv.style.color = "rgb(51, 51, 51)"
			coordsDiv.style.visibility = "hidden"

			coordsDiv.innerHTML = ""

			const mapContainer = map.getContainer()

			mapContainer.appendChild(coordsDiv)

			mapContainer.addEventListener("mouseleave", () => {
				coordsDiv.style.visibility = "hidden"
			})

			mapContainer.addEventListener("mouseenter", () => {
				coordsDiv.style.visibility = "visible"
			})

			L.control.scale({ maxWidth: 200, imperial: false, position: "bottomright" }).addTo(map)

			const getCurrentZoom = (): number => {
				return this.currentZoom
			}

			const setCurrentZoom = (v: number) => {
				this.currentZoom = v
			}

			const getZoomThreshold = (): number => {
				return this.zoomThreshold
			}

			const layersMapHide = () =>
				map !== undefined &&
				this.layersMap.forEach((_, key) => (map.getPane(`${key}Pane`)!.style.opacity = "0"))

			const layersMapUnhide = () =>
				map !== undefined &&
				this.layersMap.forEach((_, key) => {
					if (map.getPane(`${key}Pane`)!.style.opacity === "0") {
						map.getPane(`${key}Pane`)!.style.opacity = "1"
					}
				})

			map.on("zoom", function () {
				const currentZoom = getCurrentZoom()
				if (currentZoom !== -1) {
					if (map.getZoom() - getCurrentZoom() > getZoomThreshold()) {
						setCurrentZoom(-1)
						layersMapHide()
					}
				}
			})

			map.on("zoomstart", function () {
				const currentZoom = getCurrentZoom()
				if (currentZoom !== -1) {
					setCurrentZoom(map.getZoom())
				}
			})

			const updateMapParamsZoom = (zoom: number) => {
				this.dispatch(
					updateMapParamsState({ zoom: { mapId: this.divId, zoomLevel: zoom } })
				)
			}

			map.on("zoomend", function () {
				updateMapParamsZoom(map.getZoom())
				layersMapUnhide()
				setCurrentZoom(1000)
			})

			const handleMouseMove = debounce((event: L.LeafletMouseEvent) => {
				const popupPaneChildren = map.getPane("popupPane")?.children
				if (popupPaneChildren !== undefined && popupPaneChildren.length > 0) {
					if (coordsDiv.style.visibility !== "hidden") {
						const event = new CustomEvent("mouseleave")
						mapContainer.dispatchEvent(event)
					}
					return
				}

				// check if the mouse is over a ship marker. If so, stop mouse move event propagation
				const target = event.originalEvent.target as HTMLElement
				if (target.closest(".leaflet-marker-icon") !== null) {
					map.eachLayer((layer) => {
						if ((layer.options as LayerOptionsWithMetadata).metadata !== undefined) {
							layer.fireEvent("mouseout")
						}

						const layerOptionsTyped = layer.options as LayerOptionsWithMetadata
						if (
							layerOptionsTyped?.oldStyle !== undefined &&
							layerOptionsTyped?.fillOpacity !== undefined &&
							layerOptionsTyped.fillOpacity === 0.85
						) {
							if ((layer as unknown as GeoJSON).setStyle !== undefined) {
								;(layer as unknown as GeoJSON).setStyle({
									fillOpacity: layerOptionsTyped?.oldStyle.fillOpacity,
									weight: layerOptionsTyped?.oldStyle.weight,
									color: layerOptionsTyped?.oldStyle.color,
									dashArray: layerOptionsTyped?.oldStyle.dashArray,
									stroke: layerOptionsTyped?.oldStyle.stroke,
								})
							} else if ((layer as unknown as Marker)?.setOpacity !== undefined) {
								;(layer as unknown as Marker).setOpacity(0.5)
							} else if ((layer as unknown as Polyline).setStyle !== undefined) {
								;(layer as unknown as Polyline).setStyle({
									fillOpacity: layerOptionsTyped?.oldStyle.fillOpacity,
									weight: layerOptionsTyped?.oldStyle.weight,
									color: layerOptionsTyped?.oldStyle.color,
									dashArray: layerOptionsTyped?.oldStyle.dashArray,
									stroke: layerOptionsTyped?.oldStyle.stroke,
								})
							}
						}

						if (
							(layer.options as LayerOptionsWithMetadata).metadata !== undefined &&
							(layer.options as LayerOptionsWithMetadata).metadata?.diseaseZone ===
								undefined &&
							(layer.options as LayerOptionsWithMetadata).metadata?.facility ===
								undefined &&
							(layer.options as LayerOptionsWithMetadata).metadata?.oilAndGasField ===
								undefined
						) {
							layer.remove()
						}
					})
					return
				}

				const latlng = event.latlng
				const coordParsed = parseCoordFromDDToDDM(latlng.lat, latlng.lng)
				const coordSDDM = `${coordParsed.Latitude_Degree < 10 ? "  " : coordParsed.Latitude_Degree < 100 ? " " : ""}${coordParsed.Latitude_Degree}°&nbsp${coordParsed.Latitude_Minutes < 10 ? "0" : ""}${coordParsed.Latitude_Minutes}${`${coordParsed.Latitude_Minutes}`.split(".").length === 1 ? ".00" : `${coordParsed.Latitude_Minutes}`.split(".")[1]?.length === 1 ? "0" : ""}&nbsp${coordParsed.Latitude_North_South} 
														${coordParsed.Longitude_Degree < 10 ? `  ` : coordParsed.Longitude_Degree < 100 ? ` ` : ""}${coordParsed.Longitude_Degree}°&nbsp${coordParsed.Longitude_Minutes < 10 ? "0" : ""}${coordParsed.Longitude_Minutes}${`${coordParsed.Longitude_Minutes}`.split(".").length === 1 ? ".00" : `${coordParsed.Longitude_Minutes}`.split(".")[1]?.length === 1 ? "0" : ""}&nbsp${coordParsed.Longitude_East_West}`
				coordsDiv.innerHTML = coordSDDM
				const layersUnderMouse = getLayersAtPoint(map, latlng)
				// console.log("Layers under mouse:", layersUnderMouse)

				map.eachLayer((layer) => {
					if ((layer.options as LayerOptionsWithMetadata).metadata === undefined) {
						return
					}
					layer.fireEvent("mouseout")

					const layerOptionsTyped = layer.options as LayerOptionsWithMetadata
					if (
						layerOptionsTyped?.oldStyle !== undefined &&
						layerOptionsTyped?.fillOpacity !== undefined &&
						layerOptionsTyped.fillOpacity === 0.85
					) {
						if ((layer as unknown as GeoJSON).setStyle !== undefined) {
							;(layer as unknown as GeoJSON).setStyle({
								fillOpacity: layerOptionsTyped?.oldStyle.fillOpacity,
								weight: layerOptionsTyped?.oldStyle.weight,
								color: layerOptionsTyped?.oldStyle.color,
								dashArray: layerOptionsTyped?.oldStyle.dashArray,
								stroke: layerOptionsTyped?.oldStyle.stroke,
							})
						} else if ((layer as unknown as Marker)?.setOpacity !== undefined) {
							;(layer as unknown as Marker).setOpacity(0.5)
						} else if ((layer as unknown as Polyline).setStyle !== undefined) {
							;(layer as unknown as Polyline).setStyle({
								fillOpacity: layerOptionsTyped?.oldStyle.fillOpacity,
								weight: layerOptionsTyped?.oldStyle.weight,
								color: layerOptionsTyped?.oldStyle.color,
								dashArray: layerOptionsTyped?.oldStyle.dashArray,
								stroke: layerOptionsTyped?.oldStyle.stroke,
							})
						}
					}

					if (
						(layer.options as LayerOptionsWithMetadata).metadata?.diseaseZone !==
							undefined ||
						(layer.options as LayerOptionsWithMetadata).metadata?.facility !==
							undefined ||
						(layer.options as LayerOptionsWithMetadata).metadata?.oilAndGasField !==
							undefined
					) {
						return
					}
					layer.remove()
				})

				const layer_0 = layersUnderMouse[0]
				if (
					layer_0 === undefined ||
					(layer_0.options as LayerOptionsWithMetadata).metadata === undefined
				) {
					return
				}

				const metadataArray: MapElementMetadata[] = []
				for (const layer of layersUnderMouse) {
					const metadata = (layer.options as LayerOptionsWithMetadata)?.metadata
					if (metadata === undefined) {
						continue
					}
					metadataArray.push(metadata)
					const layerOptionsTyped = layer.options as LayerOptionsWithMetadata
					if (layerOptionsTyped.oldStyle === undefined) {
						layerOptionsTyped.oldStyle = {}
					}
					if ((layer as unknown as GeoJSON).setStyle !== undefined) {
						layerOptionsTyped.oldStyle.fillOpacity = layerOptionsTyped.fillOpacity
						layerOptionsTyped.oldStyle.stroke = layerOptionsTyped.stroke
						layerOptionsTyped.oldStyle.color = layerOptionsTyped.color
						layerOptionsTyped.oldStyle.dashArray = layerOptionsTyped.dashArray
						layerOptionsTyped.oldStyle.weight = layerOptionsTyped.weight
						;(layer as unknown as GeoJSON).setStyle({
							fillOpacity: 0.85,
							stroke: true,
							color: "white",
							dashArray: undefined,
							weight: 2,
						})
					} else if ((layer as unknown as Marker)?.setOpacity !== undefined) {
						;(layer as unknown as Marker).setOpacity(0.85)
					} else if ((layer as unknown as Polyline).setStyle !== undefined) {
						layerOptionsTyped.oldStyle.fillOpacity = layerOptionsTyped.fillOpacity
						layerOptionsTyped.oldStyle.stroke = layerOptionsTyped.stroke
						layerOptionsTyped.oldStyle.color = layerOptionsTyped.color
						layerOptionsTyped.oldStyle.dashArray = layerOptionsTyped.dashArray
						layerOptionsTyped.oldStyle.weight = layerOptionsTyped.weight
						;(layer as unknown as Polyline).setStyle({
							fillOpacity: 0.85,
							stroke: true,
							color: "white",
							dashArray: undefined,
							weight: 2,
						})
					}
				}
				if (layersUnderMouse.length === 1) {
					layer_0.bindTooltip(createCombinedTooltip(metadataArray), {
						className: "z-[1000000]", // this z-index ensures that the combined tooltip is always on top of the vessel name labels
					})
					layer_0.fireEvent("mouseover")
				} else {
					function getCenter(latlngs: L.LatLng[]): L.LatLng {
						const total = latlngs.reduce(
							(acc, latlng) => {
								acc.lat += latlng.lat
								acc.lng += latlng.lng
								return acc
							},
							{ lat: 0, lng: 0 }
						)
						return L.latLng(total.lat / latlngs.length, total.lng / latlngs.length)
					}

					const latlngs: L.LatLng[] = layersUnderMouse
						.map((layer) => {
							if ((layer as unknown as GeoJSON).getBounds !== undefined) {
								return (layer as unknown as GeoJSON)?.getBounds()?.getCenter()
							}
							if ((layer as unknown as Marker)?.getLatLng !== undefined) {
								return (layer as unknown as Marker).getLatLng()
							}
							if ((layer as unknown as Polyline).getBounds !== undefined) {
								return (layer as unknown as Polyline).getBounds().getCenter()
							}
						})
						.filter((latlng) => latlng !== undefined) as L.LatLng[]
					const center = getCenter(latlngs)

					const placeHolderMarker = L.circle(center, {
						fillOpacity: 0,
						weight: 0,
						radius: 1,
						interactive: false,
						metadata: {}, // important to ensure that the placeholder is removed by the clean up logic
					} as CustomCircleOptions).addTo(map)
					placeHolderMarker.bindTooltip(createCombinedTooltip(metadataArray), {
						className: "z-[1000000]", // this z-index ensures that the combined tooltip is always on top of the vessel name labels
					})
					placeHolderMarker.fireEvent("mouseover")
				}
			}, 0)

			map.on("mousemove", handleMouseMove)

			this.map = map
			resolve()
		})
	}
}

let regularMap: RegularMap | undefined = undefined

export const destroyInstance = (map: RegularMap, divId: string = "regularMap") => {
	var div = document.getElementById(divId)
	if (div !== null) {
		div.remove()
	}
	map?.removeMap()
}
export function getRegularMap(): RegularMap | undefined {
	if (regularMap === undefined) {
		return
	}
	if (regularMap.areLayersGenerated() === false) {
		return
	}
	console.log("Map get: Returning regularMap")
	return regularMap
}

export async function tryInitRegularMap(
	dispatch: AppDispatch,
	queryClient: QueryClient,
	divId?: string,
	navigate?: ReturnType<typeof useNavigate> | undefined
) {
	if (regularMap !== undefined) {
		regularMap.clearLayers()
		regularMap.setNavigateFunc(navigate)
		return
	}
	try {
		const regularMapTemp = await RegularMap.create(dispatch, divId, queryClient, navigate)
		addLayersToMap(regularMapTemp)
		if (regularMapTemp.areLayersGenerated() === true) {
			if (divId === undefined) {
				regularMap = regularMapTemp
			} else {
				return regularMapTemp
			}
		}
	} catch (error) {
		console.log("map container instance not found")
	}
}

const regularMapDiv: HTMLDivElement = document.createElement("div")
regularMapDiv.id = "regularMap"
regularMapDiv.style.height = "100%"
regularMapDiv.style.width = "100%"

export function getRegularMapDiv() {
	return regularMapDiv
}

export const createRegularMapDiv = (divId: string) => {
	const regularMapDiv: HTMLDivElement = document.createElement("div")
	regularMapDiv.id = divId
	regularMapDiv.style.height = "100%"
	regularMapDiv.style.width = "100%"
	return regularMapDiv
}

export type RegularMapType = typeof RegularMap.prototype
