export enum SectionTitleSize {
	medium = "MEDIUM",
	large = "LARGE",
}

export enum SectionTitleType {
	first = "FIRST",
	vessel = "VESSEL",
	body = "BODY",
}
