import { CustomCircleOptions } from "@components/map/layersDataSetters/addFacilitiesWithRadius"
import { debounce } from "@helpers/debounce"
import { parseCoordFromDDToDDM } from "@helpers/parseCoordFromDDToDDM"
import { AppDispatch } from "@redux/app/store"
import { updateMapParamsState } from "@redux/features/mapParams/mapParamsSlice"
import { setRouteTracerData } from "@redux/features/routeTracerData/routeTracerDataSlice"
import { useNavigate } from "@tanstack/react-router"
import { requestApi2 } from "@utils/http"
import RouteTracer, { PathDataEntry } from "@utils/map/routeTracer/routeTracer"
import { endpoints, FrontEndKey } from "api"
import {
	GeoJSON,
	LatLng,
	LatLngBounds,
	LayerGroup,
	Map as LeafletMap,
	Marker,
	Polyline,
	TileLayer,
} from "leaflet"
import { QueryClient } from "react-query"
import {
	defaultLayerConstructor,
	LayerConstructor,
} from "../layersConstructors/defaultLayerConstructor"
import { createCombinedTooltip } from "../mapComponents/createCombinedTooltip"
import { detectCollisions } from "../utils/detectCollisions"
import { addLayersToMap, DataSetter, LayerData, LayerObject, MapLayers } from "../utils/mapLayers"
import { getLayersAtPoint, MapElementMetadata } from "../utils/utils"

export interface LayerOptionsWithMetadata extends L.LayerOptions {
	fillOpacity?: number
	stroke?: boolean
	color?: string
	dashArray?: string | number[] | undefined
	weight?: number
	metadata?: MapElementMetadata
	oldStyle?: {
		fillOpacity?: number
		stroke?: boolean
		color?: string
		dashArray?: string | number[] | undefined
		weight?: number
	}
}
interface WindyStore {
	get: (key: string) => unknown
	set: (key: string, value: unknown) => void
	on: (event: string, callback: (data: number) => void) => void
}

export type MapOptions = { routeTracer: { display: boolean } } | undefined
export class WindyMap {
	static async create(
		dispatch: AppDispatch,
		queryClient: QueryClient,
		navigate: ReturnType<typeof useNavigate> | undefined,
		options: MapOptions
	): Promise<WindyMap> {
		const result = new WindyMap(dispatch, queryClient, navigate, options)
		await result.init()
		return result
	}

	addLayer = (
		layerName: string,
		zIndex: number,
		dataSetter: DataSetter,
		constructor?: LayerConstructor
	) => {
		if (this.map === undefined) {
			return
		}
		let layerGroup: LayerGroup | undefined
		if (constructor !== undefined) {
			layerGroup = constructor(layerName, zIndex, this.map)
		} else {
			layerGroup = defaultLayerConstructor(layerName, zIndex, this.map)
		}
		if (layerGroup !== undefined) {
			this.layersMap.set(layerName, { layerGroup, dataSetter })
		}
	}

	areLayersGenerated = () => {
		return this.areLayersReady
	}

	getMapKyes = async () => {
		const data = await requestApi2(endpoints.getEnvVariables, {
			keys: [FrontEndKey.WINDY_KEY, FrontEndKey.MAPBOX_KEY],
		})
		return data
	}

	isMapInitialized = () => {
		return this.map !== undefined
	}

	setAreLayersGenerated = () => {
		this.areLayersReady = true
	}

	setLayerData = (layerData: LayerData) => {
		const layerGroupObj = this.layersMap.get(layerData.mapLayerName)
		if (layerGroupObj !== undefined) {
			layerGroupObj.dataSetter(
				this.dispatch,
				this.queryClient,
				layerGroupObj.layerGroup,
				layerData,
				this.navigate,
				this.map
			)
		}
	}
	clearLayerData = (mapLayerName: MapLayers) => {
		const layerGroupObj = this.layersMap.get(mapLayerName)
		if (layerGroupObj !== undefined) {
			layerGroupObj.layerGroup.clearLayers()
		}
	}

	getRouteTracerData = (routeData: PathDataEntry[]) => {
		this.dispatch(setRouteTracerData(routeData))
	}

	private areLayersReady: boolean = false
	private currentZoom: number = 0
	private dispatch: AppDispatch
	private queryClient: QueryClient
	private navigate: ReturnType<typeof useNavigate> | undefined
	private options: MapOptions
	private isParticlesAnimation: boolean =
		localStorage.getItem("settings_particlesAnim") !== null
			? JSON.parse(localStorage.getItem("settings_particlesAnim")!) === "off"
				? false
				: true
			: true
	private layersMap: Map<string, LayerObject> = new Map()
	private map?: LeafletMap
	private mapBoxTiles?: TileLayer
	private mapBoxTilesOpacity: boolean = false
	private store: WindyStore | undefined
	private routeTracerControl: L.Control | undefined = undefined
	private windySupressed: boolean = false
	private windySupressedByTimestamp: boolean = false

	private routeTracerLayerGroup: LayerGroup = L.layerGroup()
	//TileLayer
	private zoomThreshold: number = 1

	private constructor(
		dispatch: AppDispatch,
		queryClient: QueryClient,
		navigate: ReturnType<typeof useNavigate> | undefined = undefined,
		options: MapOptions
	) {
		this.dispatch = dispatch
		this.queryClient = queryClient
		this.navigate = navigate
		this.options = options
	}

	clearRouteTracerLayerGroup() {
		this.routeTracerLayerGroup.clearLayers()
	}

	setNavigateFunc(navigate: ReturnType<typeof useNavigate> | undefined = undefined) {
		if (this.map === undefined) {
			return
		}
		this.navigate = navigate
	}

	clearOptions() {
		if (this.map === undefined) {
			return
		}
		if (this.routeTracerControl !== undefined) {
			this.map.removeControl(this.routeTracerControl)
			this.clearRouteTracerLayerGroup()
			this.map.getContainer().style.cursor = ""
			this.routeTracerControl = undefined
		}
	}

	applyOptions(options: MapOptions) {
		if (this.map === undefined) {
			return
		}
		if (options?.routeTracer?.display === true) {
			this.routeTracerControl = new RouteTracer({
				routeTracerLayerGroup: this.routeTracerLayerGroup,
				getRouteData: this.getRouteTracerData,
				circleMarker: {
					color: "blue",
					radius: 2,
				},
			})
			this.map.addControl(this.routeTracerControl)
		}
	}
	clearLayers() {
		if (this.map !== undefined) {
			this.layersMap.forEach(
				(value) => value.layerGroup !== undefined && value.layerGroup.clearLayers()
			)
		}
	}

	fitBounds(bounds: LatLngBounds, animate: boolean = true) {
		this.map?.invalidateSize()
		if (bounds.isValid() !== true || this.map === undefined) {
			return
		}
		this.map.flyToBounds(bounds, {
			// this.map.fitBounds(bounds, {
			duration: 0.5,
			easeLinearity: 1,
			maxZoom: 11,
			noMoveStart: true,
			animate,
		})
	}

	getBoundsZoom(bounds: LatLngBounds) {
		if (bounds.isValid() !== true || this.map === undefined) {
			return undefined
		}
		return this.map.getBoundsZoom(bounds)
	}

	setView(center: LatLng, zoom: number) {
		if (this.map === undefined) {
			return
		}
		this.map.setView(center, zoom, { animate: true, duration: 0.5 })
	}

	getShowMapBoxTiles() {
		return this.mapBoxTilesOpacity
	}

	getZoom() {
		if (this.map === undefined) {
			return undefined
		}
		return this.map.getZoom()
	}

	resizemap() {
		if (this.map !== undefined) {
			this.map.invalidateSize({ animate: false })
			setTimeout(() => {
				window.dispatchEvent(new Event("resize"))
			}, 250)
			this.map.invalidateSize()
		}
	}

	setCssStyle(querySelectors: string[], propertyString: string) {
		for (const querySelector of querySelectors) {
			const element = document.querySelector(querySelector)
			if (element !== null) {
				element.setAttribute("style", propertyString)
			}
		}
	}

	setMapTilesVisibility() {
		if (this.windySupressed === false) {
			this.mapBoxTilesOpacity = !this.mapBoxTilesOpacity

			// MAPBOX ELEMENTS
			const mBoxDisplay = `display: ${this.mapBoxTilesOpacity === true ? "block" : "none"}`
			const mBoxSelectors = [
				"#maptilerLink",
				"#windy .leaflet-container .leaflet-control-attribution",
			]
			this.setCssStyle(mBoxSelectors, mBoxDisplay)
			this.mapBoxTiles?.setOpacity(this.mapBoxTilesOpacity === true ? 1 : 0)

			// WINDY ELEMENTS
			const windyDisplay = `display: ${this.mapBoxTilesOpacity === true ? "none" : "block"}`
			const windySelectors = [
				"#particleAnimationButton",
				"#windy #embed-zoom",
				"#windy #bottom",
				"#windy #logo-wrapper",
				"#windy #mobile-ovr-select",
				"#windy #map-container .leaflet-tile-pane .overlay-layer",
				"#windy #map-container .leaflet-tile-pane .particles-layer",
			]
			this.setCssStyle(windySelectors, windyDisplay)
		}
	}

	setWeatherTimestamp(timestamp: number) {
		if (this.store === undefined) {
			return
		}
		this.store.set("timestamp", timestamp)
	}

	setParticlesAnimation() {
		if (this.store === undefined) {
			return
		}
		this.isParticlesAnimation = !this.isParticlesAnimation
		this.store.set("particlesAnim", this.isParticlesAnimation === true ? "on" : "off")
	}

	private async init() {
		const vars = await this.getMapKyes()
		const windyKey = vars?.find((v) => v.key == "WINDY_KEY")?.value
		const mapboxKey = vars?.find((v) => v.key == "MAPBOX_KEY")?.value
		if ([windyKey, mapboxKey].includes(undefined)) {
			return
		}
		await new Promise<void>((resolve) => {
			windyInit(
				{
					key: windyKey,
					lat: 62.43,
					lng: 6.12,
					zoom: 5,
					verbose: false,
				},
				(windyAPI) => {
					// const { map, picker, utils, overlays, store, broadcast, particles } = windyAPI
					const { map, store }: { map: LeafletMap; store: WindyStore } = windyAPI

					// map.options.zoomSnap = 0.5
					// map.options.zoomDelta = 0.5
					// map.options.wheelPxPerZoomLevel = 120
					// map.options.preferCanvas = true
					const mapTyped = map as LeafletMap
					mapTyped.addControl(L.control.zoom())

					mapTyped.setMaxZoom(18)
					mapTyped.getPane("overlayPane")!.style.zIndex = "598"

					const mapBoxTiles = L.tileLayer(
						`https://api.maptiler.com/maps/topo/{z}/{x}/{y}.png?key=${mapboxKey}`,
						{
							attribution:
								'\u003ca href="https://www.maptiler.com/copyright/" target="_blank"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href="https://www.openstreetmap.org/copyright" target="_blank"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e',
							maxZoom: 18,
							id: "mapbox/streets-v11",
							tileSize: 512,
							zoomOffset: -1,
							accessToken: mapboxKey,
							opacity: 0.0,
							zIndex: 20,
						}
					).addTo(mapTyped)
					this.mapBoxTiles = mapBoxTiles

					const coordsDiv = L.DomUtil.create("div", "leaflet-mouse-coordinates")
					coordsDiv.style.position = "absolute"
					coordsDiv.style.bottom = "30px"
					coordsDiv.style.right = "10px"
					coordsDiv.style.background = "rgba(255, 255, 255, 0.5)"
					coordsDiv.style.padding = "0px 4px"
					coordsDiv.style.borderRadius = "0px"
					coordsDiv.style.fontSize = "11px"
					coordsDiv.style.lineHeight = "16px"
					coordsDiv.style.minWidth = "140px"
					coordsDiv.style.minHeight = "16px"
					coordsDiv.style.borderRightWidth = "1.6px"
					coordsDiv.style.borderRightColor = "rgb(119, 119, 119)"
					coordsDiv.style.zIndex = "1000"
					coordsDiv.style.color = "rgb(51, 51, 51)"
					coordsDiv.style.visibility = "hidden"

					coordsDiv.innerHTML = ""

					const mapContainer = mapTyped.getContainer()

					mapContainer.appendChild(coordsDiv)

					mapContainer.addEventListener("mouseleave", () => {
						coordsDiv.style.visibility = "hidden"
					})

					mapContainer.addEventListener("mouseenter", () => {
						coordsDiv.style.visibility = "visible"
						coordsDiv.style.bottom = getShowMapBoxTiles() === true ? "46px" : "30px"
					})

					L.control
						.scale({ maxWidth: 200, imperial: false, position: "bottomright" })
						.addTo(mapTyped)

					const getCurrentZoom = (): number => {
						return this.currentZoom
					}

					const setCurrentZoom = (v: number) => {
						this.currentZoom = v
					}

					const getWindySupressed = (): boolean => {
						return this.windySupressed
					}

					const setWindySupressed = (v: boolean) => {
						this.windySupressed = v
					}

					const getWindySupressedByTimestamp = (): boolean => {
						return this.windySupressedByTimestamp
					}

					const setWindySupressedByTimestamp = (v: boolean) => {
						this.windySupressedByTimestamp = v
					}

					const getZoomThreshold = (): number => {
						return this.zoomThreshold
					}

					const layersMapHide = () =>
						mapTyped !== undefined &&
						this.layersMap.forEach(
							(_, key) => (mapTyped.getPane(`${key}Pane`)!.style.opacity = "0")
						)

					const layersMapUnhide = () =>
						mapTyped !== undefined &&
						this.layersMap.forEach((_, key) => {
							if (mapTyped.getPane(`${key}Pane`)!.style.opacity === "0") {
								mapTyped.getPane(`${key}Pane`)!.style.opacity = "1"
							}
						})

					mapTyped.on("zoom", function () {
						const currentZoom = getCurrentZoom()
						if (currentZoom !== -1) {
							if (mapTyped.getZoom() - getCurrentZoom() > getZoomThreshold()) {
								setCurrentZoom(-1)
								layersMapHide()
							}
						}
					})

					mapTyped.on("zoomstart", function () {
						const currentZoom = getCurrentZoom()
						if (currentZoom !== -1) {
							setCurrentZoom(mapTyped.getZoom())
						}
					})

					// Re-run collision detection on map events
					mapTyped.on(
						"zoomend moveend update load baselayerchange layeradd",
						debounce(() => {
							const tooltips = document.querySelectorAll<HTMLElement>(".vessel-label")
							tooltips.forEach((tooltip) => {
								tooltip.style.display = "block"
								tooltip.style.borderRadius = ""
							})

							// Run collision detection after a slight delay
							requestAnimationFrame(() => {
								detectCollisions()
							})
						}, 200)
					)
					const getShowMapBoxTiles = () => {
						return this.getShowMapBoxTiles()
					}

					const setCssStyle = this.setCssStyle

					const windySupressZoom = 11

					store.on("timestamp", (timestamp: number) => {
						const firstTimestamp = (store.get("calendar") as { start: number }).start

						if (timestamp <= firstTimestamp) {
							setWindySupressedByTimestamp(true)
						} else if (getWindySupressedByTimestamp() === true) {
							setWindySupressedByTimestamp(false)
						}
						if (getShowMapBoxTiles() === false) {
							const windySelectors = [
								"#particleAnimationButton",
								"#windy #embed-zoom",
								// "#windy #bottom",
								// "#windy #logo-wrapper",
								"#windy #mobile-ovr-select",
								"#windy #map-container .leaflet-tile-pane .overlay-layer",
								"#windy #map-container .leaflet-tile-pane .particles-layer",
								"#windy #map-container .leaflet-marker-pane .labels-layer",
							]
							if (timestamp <= firstTimestamp) {
								mapBoxTiles.setOpacity(1)
								const windyDisplay = `display: none`
								setCssStyle(windySelectors, windyDisplay)
							} else {
								if (mapTyped.getZoom() <= windySupressZoom) {
									mapBoxTiles.setOpacity(0)
									const windyDisplay = `display: block`
									setCssStyle(windySelectors, windyDisplay)
								}
							}
						}
					})
					const updateMapParamsZoom = (zoom: number) => {
						this.dispatch(
							updateMapParamsState({
								zoom: { mapId: "windyHolder", zoomLevel: zoom },
							})
						)
					}

					mapTyped.on("zoomend", function () {
						updateMapParamsZoom(mapTyped.getZoom())
						const mapZoom = mapTyped.getZoom()
						if (mapZoom > windySupressZoom) {
							setWindySupressed(true)
						} else if (getWindySupressed() === true) {
							setWindySupressed(false)
						}
						if (getShowMapBoxTiles() === false) {
							const windySelectors =
								getWindySupressedByTimestamp() === true
									? ["#windy #bottom", "#windy #logo-wrapper"]
									: [
											"#particleAnimationButton",
											"#windy #embed-zoom",
											"#windy #bottom",
											"#windy #logo-wrapper",
											"#windy #mobile-ovr-select",
											"#windy #map-container .leaflet-tile-pane .overlay-layer",
											"#windy #map-container .leaflet-tile-pane .particles-layer",
										]
							if (mapZoom <= windySupressZoom) {
								mapBoxTiles.setOpacity(
									getWindySupressedByTimestamp() === false ? 0 : 1
								)
								const windyDisplay = `display: block`
								setCssStyle(windySelectors, windyDisplay)
							} else {
								mapBoxTiles.setOpacity(1)
								const windyDisplay = `display: none`
								setCssStyle(windySelectors, windyDisplay)
							}
						}

						layersMapUnhide()

						setCurrentZoom(1000)
					})

					const handleMouseMove = debounce((event: L.LeafletMouseEvent) => {
						const popupPaneChildren = map.getPane("popupPane")?.children
						if (popupPaneChildren !== undefined && popupPaneChildren.length > 0) {
							if (coordsDiv.style.visibility !== "hidden") {
								const event = new CustomEvent("mouseleave")
								mapContainer.dispatchEvent(event)
							}
							return
						}

						// check if the mouse is over a ship marker. If so, stop mouse move event propagation
						const target = event.originalEvent.target as HTMLElement
						if (target.closest(".leaflet-marker-icon") !== null) {
							map.eachLayer((layer) => {
								if (
									(layer.options as LayerOptionsWithMetadata).metadata !==
									undefined
								) {
									layer.fireEvent("mouseout")
								}

								const layerOptionsTyped = layer.options as LayerOptionsWithMetadata
								if (
									layerOptionsTyped?.oldStyle !== undefined &&
									layerOptionsTyped?.fillOpacity !== undefined &&
									layerOptionsTyped.fillOpacity === 0.85
								) {
									if ((layer as unknown as GeoJSON).setStyle !== undefined) {
										;(layer as unknown as GeoJSON).setStyle({
											fillOpacity: layerOptionsTyped?.oldStyle.fillOpacity,
											weight: layerOptionsTyped?.oldStyle.weight,
											color: layerOptionsTyped?.oldStyle.color,
											dashArray: layerOptionsTyped?.oldStyle.dashArray,
											stroke: layerOptionsTyped?.oldStyle.stroke,
										})
									} else if (
										(layer as unknown as Marker)?.setOpacity !== undefined
									) {
										;(layer as unknown as Marker).setOpacity(0.5)
									} else if (
										(layer as unknown as Polyline).setStyle !== undefined
									) {
										;(layer as unknown as Polyline).setStyle({
											fillOpacity: layerOptionsTyped?.oldStyle.fillOpacity,
											weight: layerOptionsTyped?.oldStyle.weight,
											color: layerOptionsTyped?.oldStyle.color,
											dashArray: layerOptionsTyped?.oldStyle.dashArray,
											stroke: layerOptionsTyped?.oldStyle.stroke,
										})
									}
								}

								if (
									(layer.options as LayerOptionsWithMetadata).metadata !==
										undefined &&
									(layer.options as LayerOptionsWithMetadata).metadata
										?.diseaseZone === undefined &&
									(layer.options as LayerOptionsWithMetadata).metadata
										?.facility === undefined &&
									(layer.options as LayerOptionsWithMetadata).metadata
										?.oilAndGasField === undefined
								) {
									layer.remove()
								}
							})
							return
						}

						const latlng = event.latlng
						const coordParsed = parseCoordFromDDToDDM(latlng.lat, latlng.lng)
						const coordSDDM = `${coordParsed.Latitude_Degree < 10 ? "  " : coordParsed.Latitude_Degree < 100 ? " " : ""}${coordParsed.Latitude_Degree}°&nbsp${coordParsed.Latitude_Minutes < 10 ? "0" : ""}${coordParsed.Latitude_Minutes}${`${coordParsed.Latitude_Minutes}`.split(".").length === 1 ? ".00" : `${coordParsed.Latitude_Minutes}`.split(".")[1]?.length === 1 ? "0" : ""}&nbsp${coordParsed.Latitude_North_South} 
											${coordParsed.Longitude_Degree < 10 ? `  ` : coordParsed.Longitude_Degree < 100 ? ` ` : ""}${coordParsed.Longitude_Degree}°&nbsp${coordParsed.Longitude_Minutes < 10 ? "0" : ""}${coordParsed.Longitude_Minutes}${`${coordParsed.Longitude_Minutes}`.split(".").length === 1 ? ".00" : `${coordParsed.Longitude_Minutes}`.split(".")[1]?.length === 1 ? "0" : ""}&nbsp${coordParsed.Longitude_East_West}`
						coordsDiv.innerHTML = coordSDDM
						const layersUnderMouse = getLayersAtPoint(map, latlng)
						// console.log("Layers under mouse:", layersUnderMouse)

						map.eachLayer((layer) => {
							if (
								(layer.options as LayerOptionsWithMetadata).metadata === undefined
							) {
								return
							}
							layer.fireEvent("mouseout")

							const layerOptionsTyped = layer.options as LayerOptionsWithMetadata
							if (
								layerOptionsTyped?.oldStyle !== undefined &&
								layerOptionsTyped?.fillOpacity !== undefined &&
								layerOptionsTyped.fillOpacity === 0.85
							) {
								if ((layer as unknown as GeoJSON).setStyle !== undefined) {
									;(layer as unknown as GeoJSON).setStyle({
										fillOpacity: layerOptionsTyped?.oldStyle.fillOpacity,
										weight: layerOptionsTyped?.oldStyle.weight,
										color: layerOptionsTyped?.oldStyle.color,
										dashArray: layerOptionsTyped?.oldStyle.dashArray,
										stroke: layerOptionsTyped?.oldStyle.stroke,
									})
								} else if ((layer as unknown as Marker)?.setOpacity !== undefined) {
									;(layer as unknown as Marker).setOpacity(0.5)
								} else if ((layer as unknown as Polyline).setStyle !== undefined) {
									;(layer as unknown as Polyline).setStyle({
										fillOpacity: layerOptionsTyped?.oldStyle.fillOpacity,
										weight: layerOptionsTyped?.oldStyle.weight,
										color: layerOptionsTyped?.oldStyle.color,
										dashArray: layerOptionsTyped?.oldStyle.dashArray,
										stroke: layerOptionsTyped?.oldStyle.stroke,
									})
								}
							}

							if (
								(layer.options as LayerOptionsWithMetadata).metadata
									?.diseaseZone !== undefined ||
								(layer.options as LayerOptionsWithMetadata).metadata?.facility !==
									undefined ||
								(layer.options as LayerOptionsWithMetadata).metadata
									?.oilAndGasField !== undefined
							) {
								return
							}
							layer.remove()
						})

						const layer_0 = layersUnderMouse[0]
						if (
							layer_0 === undefined ||
							(layer_0.options as LayerOptionsWithMetadata).metadata === undefined
						) {
							return
						}

						const metadataArray: MapElementMetadata[] = []
						for (const layer of layersUnderMouse) {
							const metadata = (layer.options as LayerOptionsWithMetadata)?.metadata
							if (metadata === undefined) {
								continue
							}
							metadataArray.push(metadata)
							const layerOptionsTyped = layer.options as LayerOptionsWithMetadata
							if (layerOptionsTyped.oldStyle === undefined) {
								layerOptionsTyped.oldStyle = {}
							}
							if ((layer as unknown as GeoJSON).setStyle !== undefined) {
								layerOptionsTyped.oldStyle.fillOpacity =
									layerOptionsTyped.fillOpacity
								layerOptionsTyped.oldStyle.stroke = layerOptionsTyped.stroke
								layerOptionsTyped.oldStyle.color = layerOptionsTyped.color
								layerOptionsTyped.oldStyle.dashArray = layerOptionsTyped.dashArray
								layerOptionsTyped.oldStyle.weight = layerOptionsTyped.weight
								;(layer as unknown as GeoJSON).setStyle({
									fillOpacity: 0.85,
									stroke: true,
									color: "white",
									dashArray: undefined,
									weight: 2,
								})
							} else if ((layer as unknown as Marker)?.setOpacity !== undefined) {
								;(layer as unknown as Marker).setOpacity(0.85)
							} else if ((layer as unknown as Polyline).setStyle !== undefined) {
								layerOptionsTyped.oldStyle.fillOpacity =
									layerOptionsTyped.fillOpacity
								layerOptionsTyped.oldStyle.stroke = layerOptionsTyped.stroke
								layerOptionsTyped.oldStyle.color = layerOptionsTyped.color
								layerOptionsTyped.oldStyle.dashArray = layerOptionsTyped.dashArray
								layerOptionsTyped.oldStyle.weight = layerOptionsTyped.weight
								;(layer as unknown as Polyline).setStyle({
									fillOpacity: 0.85,
									stroke: true,
									color: "white",
									dashArray: undefined,
									weight: 2,
								})
							}
						}
						if (layersUnderMouse.length === 1) {
							layer_0.bindTooltip(createCombinedTooltip(metadataArray), {
								className: "z-[1000000]", // this z-index ensures that the combined tooltip is always on top of the vessel name labels
							})
							layer_0.fireEvent("mouseover")
						} else {
							function getCenter(latlngs: L.LatLng[]): L.LatLng {
								const total = latlngs.reduce(
									(acc, latlng) => {
										acc.lat += latlng.lat
										acc.lng += latlng.lng
										return acc
									},
									{ lat: 0, lng: 0 }
								)
								return L.latLng(
									total.lat / latlngs.length,
									total.lng / latlngs.length
								)
							}

							const latlngs: L.LatLng[] = layersUnderMouse
								.map((layer) => {
									if ((layer as unknown as GeoJSON).getBounds !== undefined) {
										return (layer as unknown as GeoJSON)
											?.getBounds()
											?.getCenter()
									}
									if ((layer as unknown as Marker)?.getLatLng !== undefined) {
										return (layer as unknown as Marker).getLatLng()
									}
									if ((layer as unknown as Polyline).getBounds !== undefined) {
										return (layer as unknown as Polyline)
											.getBounds()
											.getCenter()
									}
								})
								.filter((latlng) => latlng !== undefined) as L.LatLng[]
							const center = getCenter(latlngs)

							const placeHolderMarker = L.circle(center, {
								fillOpacity: 0,
								weight: 0,
								radius: 1,
								interactive: false,
								metadata: {}, // important to ensure that the placeholder is removed by the clean up logic
							} as CustomCircleOptions).addTo(map)
							placeHolderMarker.bindTooltip(createCombinedTooltip(metadataArray), {
								className: "z-[1000000]", // this z-index ensures that the combined tooltip is always on top of the vessel name labels
							})
							placeHolderMarker.fireEvent("mouseover")
						}
					}, 0)

					mapTyped.on("mousemove", handleMouseMove)

					// store.set("favOverlays", ["wind", "temp", "pressure", "current"], {
					// 	forceChange: true,
					// })
					// store.set("graticule", true)
					// store.set("overlay", "wind")
					// store.set("particlesAnim", "on")
					// console.log(store.getAll())
					// console.log(store.getAllowed("overlay"))
					store.set("overlay", "wind")
					this.store = store
					this.map = map
					resolve()
				}
			)
		})
	}
}

let windyMap: WindyMap | undefined = undefined

export function setWindyTimestamp(timestamp: number) {
	if (windyMap === undefined) {
		return
	}
	windyMap.setWeatherTimestamp(timestamp)
}

export function getWindyMap(): WindyMap | undefined {
	if (windyMap === undefined) {
		return
	}
	if (windyMap.areLayersGenerated() === false) {
		return
	}
	console.log("Map get: Returning windyMap")
	return windyMap
}

export async function tryInitWindyMap(
	dispatch: AppDispatch,
	queryClient: QueryClient,
	navigate?: ReturnType<typeof useNavigate> | undefined,
	options?: MapOptions
) {
	if (windyMap !== undefined) {
		windyMap.resizemap()
		windyMap.clearLayers()
		windyMap.setNavigateFunc(navigate)
		return
	}
	windyMap = await WindyMap.create(dispatch, queryClient, navigate, options)
	addLayersToMap(windyMap)
}

const windyDiv: HTMLDivElement = document.createElement("div")
windyDiv.id = "windy"
windyDiv.style.height = "100%"
windyDiv.style.width = "100%"

export function getWindyDiv() {
	return windyDiv
}

export type WindyMapType = typeof WindyMap.prototype
