import { createShipIconTimeline } from "@components/map/mapComponents/shipIcon"
import { DataSetter } from "../utils/mapLayers"

export const addTimelineEventMarker: DataSetter = (
	dispatch,
	queryClient,
	layerGroup,
	layerData,
	navigate,
	map
) => {
	if (layerGroup === undefined) return

	const { timelineEventMarkerData } = layerData
	if (timelineEventMarkerData === undefined) return

	const { coords, heading } = timelineEventMarkerData
	if (coords === undefined) {
		return
	}

	layerGroup.clearLayers()

	if (isNaN(coords.lat) === false && isNaN(coords.lon) === false) {
		layerGroup.addLayer(
			L.marker([coords.lat, coords.lon], {
				icon: createShipIconTimeline({ heading: heading ?? 0 }),
				pane: "vesselsPane",
			})
		)
	}
}
