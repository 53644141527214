import "./routeTracer.css"

interface RouteTracerOptions {
	routeTracerLayerGroup: L.LayerGroup
	startOpened?: boolean
	position?: "topleft" | "topright" | "bottomleft" | "bottomright"
	circleMarker?: {
		color: string
		radius: number
	}
	lineStyle?: {
		color: string
		dashArray: string
	}
	lengthUnit?: {
		display: string
		decimal: number
		factor: number
		label: string
	}
	angleUnit?: {
		display: string
		decimal: number
		factor: number | null
		label: string
	}
	getRouteData: (routeData: PathDataEntry[]) => void
}

interface RouteTracerOptionsIntern {
	routeTracerLayerGroup: L.LayerGroup
	startOpened: boolean
	position: "topleft" | "topright" | "bottomleft" | "bottomright"
	circleMarker: {
		color: string
		radius: number
	}
	lineStyle: {
		color: string
		dashArray: string
	}
	lengthUnit: {
		display: string
		decimal: number
		factor: number
		label: string
	}
	angleUnit: {
		display: string
		decimal: number
		factor: number | null
		label: string
	}
	getRouteData: (routeData: PathDataEntry[]) => void
}

export type PathDataEntry = {
	lat: number
	lng: number
	bearing: number | string
	distance: number | string
}

class RouteTracer extends L.Control {
	private _mainContainer: HTMLElement | undefined
	private _container1: HTMLElement | undefined
	private _container2: HTMLElement | undefined
	private _container2_1: HTMLElement | undefined
	private _buttonsContainer: HTMLElement | undefined
	private _buttonMinimize: HTMLElement | undefined
	private _container2_2: HTMLElement | undefined
	private _buttonOk: HTMLButtonElement | undefined

	private _options: RouteTracerOptionsIntern
	private _map?: L.Map
	private _isMinimized: boolean = false
	private _isInterpPoint = false
	private _segmentToInterp: { lat: number; lng: number }[] = []
	private _pathData: PathDataEntry[] = []
	private _refLatlng: number[] = []
	private _choice: boolean = false
	private _defaultCursor: string = ""

	private _allLayers: L.LayerGroup
	private _tempLine: L.FeatureGroup | undefined
	private _tempPoint: L.FeatureGroup | undefined
	private _pointLayer: L.FeatureGroup | undefined
	private _polylineLayer: L.FeatureGroup<L.Polyline> | undefined

	private _result: { distance: number; bearing: number } = { distance: 0, bearing: 0 }
	private _clickedLatLong: { lat: number; lng: number } | null = null
	private _movingLatLong: { lat: number; lng: number } | null = null

	constructor(options: RouteTracerOptions) {
		super({ position: "topleft", ...options })
		this._options = {
			startOpened: true,
			position: "topleft",
			circleMarker: {
				color: "red",
				radius: 10,
			},
			lineStyle: {
				color: "red",
				dashArray: "1,6",
			},
			lengthUnit: {
				display: "NM",
				decimal: 2,
				factor: 0.539956803,
				label: "Distance:",
			},
			angleUnit: {
				display: "&deg;",
				decimal: 2,
				factor: null,
				label: "Bearing:",
			},
			...options,
		}
		this._allLayers = options.routeTracerLayerGroup
	}

	onAdd(map: L.Map): HTMLElement {
		this._map = map
		this._mainContainer = L.DomUtil.create("div", "main-container")
		this._container1 = L.DomUtil.create("div", "leaflet-bar", this._mainContainer)
		this._container1.classList.add("leaflet-ruler")
		L.DomEvent.disableClickPropagation(this._container1)
		// this._container1.addEventListener("click", (e) => this.toggleMeasure())
		L.DomEvent.on(this._container1, "click", this.toggleMeasure, this)

		this._container2 = L.DomUtil.create("div", "leaflet-ruler2", this._mainContainer)
		this._container2_1 = L.DomUtil.create("div", "popup-title", this._container2)
		const title = L.DomUtil.create("p", "popup-title-text", this._container2_1)
		title.innerHTML = "Route tracer"
		this._buttonsContainer = L.DomUtil.create("div", "buttons-container", this._container2_1)
		const buttonClear = L.DomUtil.create("button", "popup-button", this._buttonsContainer)
		buttonClear.innerHTML = "clear all"
		L.DomEvent.on(buttonClear, "click", this.clearAllMarkers, this)
		this._buttonMinimize = L.DomUtil.create(
			"button",
			"popup-button-control",
			this._buttonsContainer
		)
		this._buttonMinimize.innerHTML = "_"
		L.DomEvent.on(this._buttonMinimize, "click", this.minimizePopup, this)
		this._container2_2 = L.DomUtil.create("div", "leaflet-ruler2-2", this._container2)
		L.DomEvent.on(this._container2_1, "click", function (e) {
			// Stop the click event from propagating to the map
			const target = e.target as HTMLElement
			if (target.tagName.toLowerCase() !== "button") {
				// Allow click events on buttons
				L.DomEvent.stopPropagation(e)
			}
		})
		L.DomEvent.on(this._container2_2, "click", function (e) {
			// Stop the click event from propagating to the map
			const target = e.target as HTMLElement
			if (target.tagName.toLowerCase() !== "button") {
				// Allow click events on buttons
				L.DomEvent.stopPropagation(e)
			}
		})
		this._container2_2.appendChild(this.getPopUpBody([]))
		this._defaultCursor = this._map.getContainer().style.cursor
		this._map.getContainer().tabIndex = 0
		// this._allLayers = L.layerGroup()
		this._tempLine = L.featureGroup().addTo(this._allLayers)
		this._tempPoint = L.featureGroup().addTo(this._allLayers)
		this._pointLayer = L.featureGroup().addTo(this._allLayers)
		this._polylineLayer = L.featureGroup().addTo(this._allLayers)
		this._allLayers.addTo(this._map)

		L.DomEvent.on(
			this._map.getContainer(),
			"keydown",
			(e) => this.escape(e as KeyboardEvent),
			this
		)

		if (this._options.startOpened === true) {
			this.toggleMeasure()
		}
		return this._mainContainer
	}

	onRemove(map: L.Map): void {
		if (this._container1 !== undefined) {
			L.DomEvent.off(this._container1, "click", this.toggleMeasure, this)
		}
	}
	minimizePopup(e: Event) {
		if (this._container2_2 === undefined || this._buttonMinimize === undefined) {
			return
		}
		if (this._isMinimized === false) {
			this._container2_2.classList.add("minimized")
			this._isMinimized = true
			this._buttonMinimize.innerHTML = "🔲"
		} else {
			this._container2_2.classList.remove("minimized")
			this._isMinimized = false
			this._buttonMinimize.innerHTML = "_"
		}
		L.DomEvent.stopPropagation(e)
	}

	clearAllMarkers(e: Event) {
		if (
			this._map !== undefined &&
			this._allLayers !== undefined &&
			this._container2_2 !== undefined
		) {
			// clean layers
			if (this._map.hasLayer(this._allLayers) === true) {
				// this._map.removeLayer(this._allLayers)
				// this._allLayers = L.layerGroup()
				this._allLayers.clearLayers()
				this._pathData = []
				this._container2_2.replaceChildren(this.getPopUpBody([]))
				//re-add layers
				this._tempLine = L.featureGroup().addTo(this._allLayers)
				this._tempPoint = L.featureGroup().addTo(this._allLayers)
				this._pointLayer = L.featureGroup().addTo(this._allLayers)
				this._polylineLayer = L.featureGroup().addTo(this._allLayers)
				// this._allLayers.addTo(this._map)
			}
		}
		L.DomEvent.stopPropagation(e)
	}

	interpPoint(e: L.LeafletMouseEvent) {
		const firstSegment = this._segmentToInterp[0]
		const secondSegment = this._segmentToInterp[1]

		if (
			this._allLayers === undefined ||
			this._polylineLayer === undefined ||
			this._pointLayer === undefined ||
			firstSegment === undefined ||
			secondSegment === undefined
		) {
			return
		}
		const latlng = e.latlng
		// Remove from map polyline that is being interpolated
		for (const layerData of this._allLayers.getLayers()) {
			if (layerData instanceof L.LayerGroup === false) {
				continue
			}
			for (const subLayerData of (layerData as L.LayerGroup).getLayers()) {
				if (subLayerData instanceof L.Polyline === false) {
					continue
				}
				const latLng = (subLayerData as L.Polyline).getLatLngs()
				const latLng_0 = latLng[0]
				const latLng_1 = latLng[1]
				if (
					latLng_0 === undefined ||
					latLng_1 === undefined ||
					latLng_0 instanceof L.LatLng === false ||
					latLng_1 instanceof L.LatLng === false
				) {
					continue
				}
				if (
					(latLng_0 as L.LatLng).lat === firstSegment.lat &&
					(latLng_0 as L.LatLng).lng === firstSegment.lng &&
					(latLng_1 as L.LatLng).lat === secondSegment.lat &&
					(latLng_1 as L.LatLng).lng === secondSegment.lng
				) {
					layerData.removeLayer(subLayerData)
					break
				}
			}
		}
		// Add new point to the list of points
		const indexToInsert = this._pathData.findIndex(
			(data) => data.lat === firstSegment.lat && data.lng === firstSegment.lng
		)
		this._pathData.splice(indexToInsert + 1, 0, {
			lat: latlng.lat,
			lng: latlng.lng,
			bearing: "--",
			distance: "--",
		})
		// Clear old markers and add new set of marker on map
		if (this._pointLayer !== undefined && this._allLayers.hasLayer(this._pointLayer) === true) {
			this._pointLayer.clearLayers()
			for (const [index, entry] of this._pathData.entries()) {
				L.marker(
					{ lat: entry.lat, lng: entry.lng },
					{
						...this._options.circleMarker,
						draggable: true,
						icon: this.getMarkerIcon(`${index}`),
					}
				)
					.on("dragstart", (e) => {
						this.onDragStartHandler(e)
					})
					.on("drag", (e) => {
						this.onDragHandler(e)
					})
					.addTo(this._pointLayer)
			}
		}
		L.polyline(
			[
				[firstSegment.lat, firstSegment.lng],
				[latlng.lat, latlng.lng],
			],
			this._options.lineStyle
		).addTo(this._polylineLayer)
		L.polyline(
			[
				[latlng.lat, latlng.lng],
				[secondSegment.lat, secondSegment.lng],
			],
			this._options.lineStyle
		).addTo(this._polylineLayer)
		// Re-calculate bearing and distance for the whole path with the newly added point
		for (const [index, marker] of this._pathData.entries()) {
			if (index > 0) {
				const previousPathDataEntry = this._pathData[index - 1]
				if (previousPathDataEntry !== undefined) {
					marker.bearing = this.bearingCalc(
						previousPathDataEntry.lat,
						previousPathDataEntry.lng,
						marker.lat,
						marker.lng
					).toFixed(this._options.angleUnit.decimal)
					marker.distance = this.distanceCalc(
						previousPathDataEntry.lat,
						previousPathDataEntry.lng,
						marker.lat,
						marker.lng
					).toFixed(this._options.lengthUnit.decimal)
				}
			} else {
				marker.bearing = "--"
				marker.distance = "--"
			}
		}
		if (this._container2_2 !== undefined) {
			this._container2_2.replaceChildren(this.getPopUpBody(this._pathData))
		}
		L.DomEvent.stopPropagation(e)
	}

	clearMarker(e: Event, idx: number) {
		const prevRefPoint = this._pathData[idx - 1]
		const refPoint = this._pathData[idx]
		const nextRefPoint = this._pathData[idx + 1]
		if (refPoint === undefined) {
			return
		}
		if (this._allLayers === undefined) {
			return
		}
		this._pathData = this._pathData.filter((data, index) => index !== idx)
		// Remove from map polylines that have one of the vertices at the point removed from the path.
		for (const layerData of this._allLayers.getLayers()) {
			if (layerData instanceof L.LayerGroup === false) {
				continue
			}
			for (const subLayerData of layerData.getLayers()) {
				if (subLayerData instanceof L.Polyline === false) {
					continue
				}
				const latLng = subLayerData.getLatLngs()
				const latLng_0 = latLng[0]
				const latLng_1 = latLng[1]
				if (
					latLng_0 instanceof L.LatLng === true &&
					latLng_0.lat === refPoint.lat &&
					latLng_0.lng === refPoint.lng
				) {
					layerData.removeLayer(subLayerData)
				}
				if (
					latLng_1 instanceof L.LatLng === true &&
					latLng_1.lat === refPoint.lat &&
					latLng_1.lng === refPoint.lng
				) {
					layerData.removeLayer(subLayerData)
				}
			}
		}
		// Remove from map marker that correspond to the point removed from the path
		// and add new polyline to fill the gap left.
		for (const layerData of this._allLayers.getLayers()) {
			if (layerData instanceof L.LayerGroup === false) {
				continue
			}
			for (const subLayerData of layerData.getLayers()) {
				if (subLayerData instanceof L.Marker === false) {
					continue
				}
				const latLng = subLayerData.getLatLng()
				if (
					latLng instanceof L.LatLng === false ||
					latLng.lat !== refPoint.lat ||
					latLng.lng !== refPoint.lng
				) {
					continue
				}
				if (layerData.hasLayer(subLayerData) === true) {
					layerData.removeLayer(subLayerData)
				}
				if (
					prevRefPoint !== undefined &&
					nextRefPoint !== undefined &&
					this._polylineLayer !== undefined
				) {
					L.polyline(
						[
							[prevRefPoint.lat, prevRefPoint.lng],
							[nextRefPoint.lat, nextRefPoint.lng],
						],
						this._options.lineStyle
					).addTo(this._polylineLayer)
				}
			}
		}
		// Clear old markers and add new set of marker on map
		if (this._pointLayer !== undefined && this._allLayers.hasLayer(this._pointLayer) === true) {
			this._pointLayer.clearLayers()
			for (const [index, entry] of this._pathData.entries()) {
				L.marker(
					{ lat: entry.lat, lng: entry.lng },
					{
						...this._options.circleMarker,
						draggable: true,
						icon: this.getMarkerIcon(`${index}`),
					}
				)
					.on("dragstart", (e) => {
						this.onDragStartHandler(e)
					})
					.on("drag", (e) => {
						this.onDragHandler(e)
					})
					.addTo(this._pointLayer)
			}
		}
		// Re-calculate bearing and distance for the whole path without the removed point
		for (const [index, marker] of this._pathData.entries()) {
			if (index > 0) {
				const previousPathDataEntry = this._pathData[index - 1]
				if (previousPathDataEntry !== undefined) {
					marker.bearing = this.bearingCalc(
						previousPathDataEntry.lat,
						previousPathDataEntry.lng,
						marker.lat,
						marker.lng
					).toFixed(this._options.angleUnit.decimal)
					marker.distance = this.distanceCalc(
						previousPathDataEntry.lat,
						previousPathDataEntry.lng,
						marker.lat,
						marker.lng
					).toFixed(this._options.lengthUnit.decimal)
				}
			} else {
				marker.bearing = "--"
				marker.distance = "--"
			}
		}
		if (this._container2_2 !== undefined) {
			this._container2_2.replaceChildren(this.getPopUpBody(this._pathData))
		}
		L.DomEvent.stopPropagation(e)
	}

	getPopUpBody(pathData: PathDataEntry[]) {
		const bodyContainer = L.DomUtil.create("div", "popup-body")
		const table = L.DomUtil.create("table", "popup-table", bodyContainer)
		table.setAttribute("id", "markers")
		const header = L.DomUtil.create("tr", "", table)
		const hc1 = L.DomUtil.create("th", "", header)
		const hc2 = L.DomUtil.create("th", "", header)
		hc2.innerHTML = "Latitude"
		const hc3 = L.DomUtil.create("th", "", header)
		hc3.innerHTML = "Longitude"
		const hc4 = L.DomUtil.create("th", "", header)
		hc4.innerHTML = "Bearing"
		const hc5 = L.DomUtil.create("th", "", header)
		hc5.innerHTML = "Distance"
		const hc6 = L.DomUtil.create("th", "", header)
		for (const [index, marker] of pathData.entries()) {
			const row = L.DomUtil.create("tr", "", table)
			const rc1 = L.DomUtil.create("td", "", row)
			rc1.innerHTML = `<div class="waypoint-icon-table-container"><div class="waypoint-icon-table">${index}</div></div>`
			const rc2 = L.DomUtil.create("td", "", row)
			rc2.innerHTML = this.convertDecimalToDegrees(marker.lat)
			const rc3 = L.DomUtil.create("td", "", row)
			rc3.innerHTML = this.convertDecimalToDegrees(marker.lng)
			const rc4 = L.DomUtil.create("td", "", row)
			rc4.innerHTML = `${marker.bearing}${this._options.angleUnit.display}`
			const rc5 = L.DomUtil.create("td", "", row)
			rc5.innerHTML = `${marker.distance} ${this._options.lengthUnit.display}`
			const rc6 = L.DomUtil.create("td", "", row)
			const button = L.DomUtil.create("button", "popup-button-control", rc6)
			button.innerHTML = "X"
			L.DomEvent.on(button, "click", (e) => this.clearMarker(e, index), this)
		}
		const footer = L.DomUtil.create("tr", "", table)
		const fc1 = L.DomUtil.create("td", "", footer)
		const fc2 = L.DomUtil.create("td", "", footer)
		const fc3 = L.DomUtil.create("td", "", footer)
		const fc4 = L.DomUtil.create("td", "", footer)
		fc4.innerHTML = "Total distance"
		const fc5 = L.DomUtil.create("td", "", footer)
		fc5.innerHTML = `${pathData
			.reduce(
				(prev, current) =>
					prev +
					(isNaN(Number(current.distance)) === true ? 0 : Number(current.distance)),
				0
			)
			.toFixed(this._options.lengthUnit.decimal)} ${this._options.lengthUnit.display}`
		const fc6 = L.DomUtil.create("td", "", footer)
		this._buttonOk = L.DomUtil.create(
			"button",
			"popup-button-control popup-button-control-ok",
			fc6
		)
		this._buttonOk.disabled = this._clickedLatLong !== null || this._pathData.length < 2
		this._buttonOk.innerHTML = "OK"
		L.DomEvent.on(
			this._buttonOk,
			"click",
			(e) => {
				this._options.getRouteData(JSON.parse(JSON.stringify(this._pathData)))
				L.DomEvent.stopPropagation(e)
			},
			this
		)

		return bodyContainer
	}
	toggleMeasure() {
		this._choice = !this._choice
		this._clickedLatLong = null
		if (
			this._map === undefined ||
			this._map === null ||
			this._container1 === undefined ||
			this._container2 === undefined ||
			this._polylineLayer === undefined
		) {
			return
		}
		if (this._choice === true) {
			this._map.doubleClickZoom.disable()
			L.DomEvent.on(this._map.getContainer(), "dblclick", this.closePath, this)
			this._container1.classList.add("leaflet-ruler-clicked")
			this._container2.classList.add("leaflet-ruler2-clicked")
			this._map.getContainer().style.cursor = "crosshair"
			this._map.on("click", this.clicked, this)
			this._map.on("mousemove", this.moving, this)
			if (this._allLayers !== undefined && this._map.hasLayer(this._allLayers) === false) {
				this._map.addLayer(this._allLayers)
			}
		} else {
			this._map.doubleClickZoom.enable()
			L.DomEvent.off(this._map.getContainer(), "dblclick", this.closePath, this)
			this._container1.classList.remove("leaflet-ruler-clicked")
			this._container2.classList.remove("leaflet-ruler2-clicked")
			if (this._allLayers !== undefined && this._map.hasLayer(this._allLayers) === true) {
				this._map.removeLayer(this._allLayers)
			}
			this._map.getContainer().style.cursor = this._defaultCursor
			this._map.off("click", this.clicked, this)
			this._map.off("mousemove", this.moving, this)
		}
	}
	onDragHandler(e: L.LeafletEvent) {
		if (this._allLayers === undefined) {
			return
		}
		const targetMarker = e.target
		if (targetMarker instanceof L.Marker === false) {
			return
		}
		const markerLatLng = targetMarker.getLatLng()
		for (const layerData of this._allLayers.getLayers()) {
			if (layerData instanceof L.LayerGroup === false) {
				continue
			}
			for (const subLayerData of layerData.getLayers()) {
				if (subLayerData instanceof L.Polyline === false) {
					continue
				}
				const latLng = subLayerData.getLatLngs()
				const latLng_0 = latLng[0]
				const latLng_1 = latLng[1]
				if (
					latLng_0 instanceof L.LatLng === false ||
					latLng_1 instanceof L.LatLng === false
				) {
					continue
				}
				if (latLng_0.lat === this._refLatlng[0] && latLng_0.lng === this._refLatlng[1]) {
					subLayerData.setLatLngs([
						[markerLatLng.lat, markerLatLng.lng],
						[latLng_1.lat, latLng_1.lng],
					])
				}
				if (latLng_1.lat === this._refLatlng[0] && latLng_1.lng === this._refLatlng[1]) {
					subLayerData.setLatLngs([
						[latLng_0.lat, latLng_0.lng],
						[markerLatLng.lat, markerLatLng.lng],
					])
				}
			}
		}
		for (const [index, marker] of this._pathData.entries()) {
			if (marker.lat === this._refLatlng[0] && marker.lng === this._refLatlng[1]) {
				marker.lat = markerLatLng.lat
				marker.lng = markerLatLng.lng
				if (index > 0) {
					const previousPathDataEntry = this._pathData[index - 1]
					if (previousPathDataEntry !== undefined) {
						marker.bearing = this.bearingCalc(
							previousPathDataEntry.lat,
							previousPathDataEntry.lng,
							markerLatLng.lat,
							markerLatLng.lng
						).toFixed(this._options.angleUnit.decimal)
						marker.distance = this.distanceCalc(
							previousPathDataEntry.lat,
							previousPathDataEntry.lng,
							markerLatLng.lat,
							markerLatLng.lng
						).toFixed(this._options.lengthUnit.decimal)
					}
				}
				if (index < this._pathData.length - 1) {
					const nextPathDataEntry = this._pathData[index + 1]
					if (nextPathDataEntry !== undefined) {
						nextPathDataEntry.bearing = this.bearingCalc(
							markerLatLng.lat,
							markerLatLng.lng,
							nextPathDataEntry.lat,
							nextPathDataEntry.lng
						).toFixed(this._options.angleUnit.decimal)
						nextPathDataEntry.distance = this.distanceCalc(
							markerLatLng.lat,
							markerLatLng.lng,
							nextPathDataEntry.lat,
							nextPathDataEntry.lng
						).toFixed(this._options.lengthUnit.decimal)
					}
				}
				if (this._container2_2 !== undefined) {
					this._container2_2.replaceChildren(this.getPopUpBody(this._pathData))
				}
				break
			}
		}

		this._refLatlng = [markerLatLng.lat, markerLatLng.lng]
	}

	onDragStartHandler(e: L.LeafletEvent) {
		const targetMarker = e.target
		if (targetMarker instanceof L.Marker === false) {
			return
		}
		const markerLatLng = targetMarker.getLatLng()
		this._refLatlng = [markerLatLng.lat, markerLatLng.lng]
	}
	getMarkerIcon = (label: string) =>
		L.divIcon({
			className: "",
			html: `<div class="waypoint-icon-container">
			<div class="waypoint-icon-arrow"> </div>
			<div class="waypoint-icon">${label}</div>
			</div>`,
			iconSize: [32, 32],
			iconAnchor: [16, 22],
		})
	clicked(e: L.LeafletMouseEvent) {
		if (this._isInterpPoint === true) {
			this.interpPoint(e)
		} else {
			this._clickedLatLong = e.latlng
			if (this._clickedLatLong === null || this._pointLayer === undefined) {
				return
			}

			L.marker(this._clickedLatLong, {
				...this._options.circleMarker,
				draggable: true,
				icon: this.getMarkerIcon(`${this._pathData.length}`),
			})
				.on("dragstart", (e) => {
					this.onDragStartHandler(e)
				})
				.on("drag", (e) => {
					this.onDragHandler(e)
				})
				.addTo(this._pointLayer)

			if (this._pathData.length > 1 && this._movingLatLong === undefined) {
				const lastPathDataEntry = this._pathData[this._pathData.length - 1]
				if (lastPathDataEntry !== undefined) {
					this.calculateBearingAndDistance(
						lastPathDataEntry.lat,
						lastPathDataEntry.lng,
						this._clickedLatLong.lat,
						this._clickedLatLong.lng
					)
				}
			}
			this._pathData.push({
				lat: e.latlng.lat,
				lng: e.latlng.lng,
				bearing:
					this._pathData.length > 0
						? this._result.bearing.toFixed(this._options.angleUnit.decimal)
						: "--",
				distance:
					this._pathData.length > 0
						? this._result.distance.toFixed(this._options.lengthUnit.decimal)
						: "--",
			})
			if (this._container2_2 !== undefined) {
				this._container2_2.replaceChildren(this.getPopUpBody(this._pathData))
			}
			if (this._pathData.length > 1 && this._polylineLayer !== undefined) {
				const penultimatePathDataEntry = this._pathData[this._pathData.length - 2]
				if (
					penultimatePathDataEntry !== undefined &&
					e.latlng.equals({
						lat: penultimatePathDataEntry.lat,
						lng: penultimatePathDataEntry.lng,
					}) === false
				) {
					L.polyline(
						[
							{
								lat: penultimatePathDataEntry.lat,
								lng: penultimatePathDataEntry.lng,
							},
							this._clickedLatLong,
						],
						this._options.lineStyle
					).addTo(this._polylineLayer)
				}
			}
		}
	}

	moving(e: L.LeafletMouseEvent) {
		if (this._map === undefined || this._map === null) {
			return
		}
		if (this._tempPoint === undefined) {
			return
		}
		if (this._allLayers === undefined) {
			return
		}
		if (this._clickedLatLong !== null && this._clickedLatLong !== undefined) {
			if (this._container1 !== undefined) {
				L.DomEvent.off(this._container1, "click", this.toggleMeasure, this)
			}
			this._movingLatLong = e.latlng
			if (this._movingLatLong === null) {
				return
			}

			if (this._tempLine !== undefined && this._map.hasLayer(this._tempLine) === true) {
				this._map.removeLayer(this._tempLine)
			}

			if (this._map.hasLayer(this._tempPoint) === true) {
				this._map.removeLayer(this._tempPoint)
			}

			let text: string
			let addedLength = 0
			this._tempLine = L.featureGroup()
			this._tempPoint = L.featureGroup()
			this._tempLine.addTo(this._map)
			this._tempPoint.addTo(this._map)

			this.calculateBearingAndDistance(
				this._clickedLatLong.lat,
				this._clickedLatLong.lng,
				this._movingLatLong.lat,
				this._movingLatLong.lng
			)
			addedLength =
				this._result.distance +
				this._pathData.reduce(
					(prev, current) =>
						prev +
						(typeof current.distance === "string" ? 0 : Number(current.distance)),
					0
				)
			L.polyline([this._clickedLatLong, this._movingLatLong], this._options.lineStyle).addTo(
				this._tempLine
			)
			if (this._pathData.length > 1) {
				text =
					"<b>" +
					this._options.angleUnit.label +
					"</b>&nbsp;" +
					this._result.bearing.toFixed(this._options.angleUnit.decimal) +
					"&nbsp;" +
					this._options.angleUnit.display +
					"<br><b>" +
					this._options.lengthUnit.label +
					"</b>&nbsp;" +
					addedLength.toFixed(this._options.lengthUnit.decimal) +
					"&nbsp;" +
					this._options.lengthUnit.display +
					'<br><div class="plus-length">(+' +
					this._result.distance.toFixed(this._options.lengthUnit.decimal) +
					")</div>"
			} else {
				text =
					"<b>" +
					this._options.angleUnit.label +
					"</b>&nbsp;" +
					this._result.bearing.toFixed(this._options.angleUnit.decimal) +
					"&nbsp;" +
					this._options.angleUnit.display +
					"<br><b>" +
					this._options.lengthUnit.label +
					"</b>&nbsp;" +
					this._result.distance.toFixed(this._options.lengthUnit.decimal) +
					"&nbsp;" +
					this._options.lengthUnit.display
			}
			L.circleMarker(this._movingLatLong, this._options.circleMarker)
				.bindTooltip(text, {
					sticky: true,
					offset: L.point(0, -40),
					className: "moving-tooltip",
				})
				.addTo(this._tempPoint)
				.openTooltip()
		} else {
			if (this._polylineLayer === undefined) {
				return
			}
			this._tempPoint.clearLayers()
			this._isInterpPoint = false
			this._segmentToInterp = []
			const threshold = 20 // adjust as needed
			let closestDistance = Infinity
			let closestPoint: L.Point | null = null
			let closestSegmentStart: L.Point | null = null
			let closestSegmentEnd: L.Point | null = null

			for (const pointLayer of this._polylineLayer.getLayers()) {
				const latLngs = (pointLayer as L.Polyline).getLatLngs()
				for (let i = 0; i < latLngs.length - 1; i++) {
					const latLngs_i = latLngs[i]
					const latLngs_i_plus_1 = latLngs[i + 1]
					if (
						latLngs_i instanceof L.LatLng === false ||
						latLngs_i_plus_1 instanceof L.LatLng === false
					) {
						continue
					}
					const segmentStart = this._map.latLngToLayerPoint(latLngs_i)
					const segmentEnd = this._map.latLngToLayerPoint(latLngs_i_plus_1)
					if (segmentStart === undefined || segmentEnd === undefined) {
						continue
					}
					const distance = L.LineUtil.pointToSegmentDistance(
						L.point(e.layerPoint.x, e.layerPoint.y),
						segmentStart,
						segmentEnd
					)
					if (distance < closestDistance) {
						closestDistance = distance
						closestSegmentStart = segmentStart
						closestSegmentEnd = segmentEnd
						closestPoint = L.LineUtil.closestPointOnSegment(
							e.layerPoint,
							segmentStart,
							segmentEnd
						)
					}
				}
			}
			if (
				closestDistance < threshold &&
				closestPoint !== null &&
				closestSegmentEnd !== null &&
				closestSegmentStart !== null
			) {
				this._isInterpPoint = true
				this._segmentToInterp = [
					this._map.layerPointToLatLng(closestSegmentStart),
					this._map.layerPointToLatLng(closestSegmentEnd),
				]
				L.circleMarker(this._map.layerPointToLatLng(closestPoint), {
					...this._options.circleMarker,
					radius: 6,
					opacity: 1.0,
					fill: true,
					fillColor: "blue",
					fillOpacity: 1,
				}).addTo(this._tempPoint)
				this._tempPoint.addTo(this._allLayers)
			}
		}
	}

	escape(e: KeyboardEvent) {
		if (e.code === "Escape") {
			this._movingLatLong = null
			if (this._buttonOk !== undefined) {
				this._buttonOk.disabled = this._clickedLatLong === null || this._pathData.length < 2
			}

			if (this._clickedLatLong !== null) {
				// this._options.getRouteData(this._pathData)
				// this._options.getRouteData(JSON.parse(JSON.stringify(this._pathData)))
				this.closePath()
			} else {
				this._choice = true
				this.toggleMeasure()
			}
		}
	}

	closePath() {
		if (this._map !== undefined && this._map !== null && this._container1 !== undefined) {
			if (this._tempLine !== undefined && this._map.hasLayer(this._tempLine) === true) {
				this._map.removeLayer(this._tempLine)
			}
			if (this._tempPoint !== undefined && this._map.hasLayer(this._tempPoint) === true) {
				this._map.removeLayer(this._tempPoint)
			}
			// if (
			// 	this._pathData.length <= 1 &&
			// 	this._pointLayer !== undefined &&
			// 	this._map.hasLayer(this._pointLayer) === true
			// ) {
			// 	this._map.removeLayer(this._pointLayer)
			// }
			this._choice = true
			this._clickedLatLong = null
			L.DomEvent.on(this._container1, "click", this.toggleMeasure, this)
		}
	}

	bearingCalc(f1: number, l1: number, f2: number, l2: number): number {
		const toRadian = Math.PI / 180
		// haversine formula
		// bearing
		const y = Math.sin((l2 - l1) * toRadian) * Math.cos(f2 * toRadian)
		const x =
			Math.cos(f1 * toRadian) * Math.sin(f2 * toRadian) -
			Math.sin(f1 * toRadian) * Math.cos(f2 * toRadian) * Math.cos((l2 - l1) * toRadian)
		const angleUnitFactor = this._options.angleUnit.factor
		let brng =
			Math.atan2(y, x) *
			((angleUnitFactor !== null && angleUnitFactor !== undefined
				? angleUnitFactor / 2
				: 180) /
				Math.PI)
		brng +=
			brng < 0
				? angleUnitFactor !== null && angleUnitFactor !== undefined
					? angleUnitFactor
					: 360
				: 0
		return brng
	}

	distanceCalc(f1: number, l1: number, f2: number, l2: number): number {
		const toRadian = Math.PI / 180
		// distance
		const R =
			this._options.lengthUnit.factor !== undefined
				? 6371 * this._options.lengthUnit.factor
				: 6371 // kilometres
		const deltaF = (f2 - f1) * toRadian
		const deltaL = (l2 - l1) * toRadian
		const a =
			Math.sin(deltaF / 2) * Math.sin(deltaF / 2) +
			Math.cos(f1 * toRadian) *
				Math.cos(f2 * toRadian) *
				Math.sin(deltaL / 2) *
				Math.sin(deltaL / 2)
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
		const distance = R * c
		return distance
	}

	calculateBearingAndDistance(f1: number, l1: number, f2: number, l2: number) {
		this._result = {
			bearing: this.bearingCalc(f1, l1, f2, l2),
			distance: this.distanceCalc(f1, l1, f2, l2),
		}
	}

	convertDecimalToDegrees(v: number) {
		const degrees = Math.trunc(v)
		const minutes = Math.trunc((v % 1) * 60)
		const seconds = Math.round((((v % 1) * 60) % 1) * 60)
		const degreesValue = `${degrees}° ${minutes}' ${seconds}"`
		return degreesValue
	}
}

L.Control.RouteTracer = RouteTracer

export type RouteTracerType = typeof RouteTracer
declare module "leaflet" {
	namespace Control {
		let RouteTracer: RouteTracerType
	}
}
export default RouteTracer
