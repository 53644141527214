const AHTSVessels: [vessel: string, text: string][] = [
	//DOF-AHTS
	[
		"Skandi Urca",
		"Due to insufficient sensor data on Skandi Urca, it is not possible to calculate AHTS modes at this time.",
	], //NO WINCH DATA //SENSOR ISSUES
	[
		"Skandi Ipanema",
		"Due to insufficient sensor data on Skandi Ipanema, it is not possible to calculate AHTS modes at this time.",
	], //NO WINCH DATA //SENSOR ISSUES

	//DOF-AHTS dev
	[
		"Skandi Amazonas",
		"Winch data is not yet available on Skandi Amazonas, AHTS modes are currently based on power production.",
	], //NO WINCH DATA
	[
		"Skandi Iceman",
		"Winch data is not yet available on Skandi Iceman, AHTS modes are currently based on power production.",
	], //SENSOR ISSUES //NO WINCH DATA

	//DOF-AHTS dev winch
	[
		"Skandi Paraty",
		"Due to insufficient sensor data on Skandi Paraty, it is not possible to calculate AHTS modes at this time.",
	], //SENSOR ISSUES
	//"Skandi Angra", //OK
	//"Skandi Hercules", //OK
	//"Skandi Skansen", //OK
	//"Skandi Vega", //OK
	//"Skandi Iguacu", //OK
]

export default function AHTSWaningPanel({ plantName }: { plantName: string | undefined }) {
	const vesselEntry = AHTSVessels.find(
		([vessel]) => vessel.toLowerCase() === (plantName?.toLowerCase() ?? "")
	)

	const vesselText = vesselEntry?.[1] ?? undefined

	return vesselText !== undefined ? (
		<div className="p-1 -mt-5 -mb-2 -ml-5 -mr-5 bg-amber-50">
			<div
				className={` min-h-5 rounded-sm border-1 border-amber-300 bg-amber-50 pl-4 text-[14px] text-menu font-medium text-amber-700`}
			>
				<p>{vesselText !== undefined ? `${vesselText} ` : ""}</p>
			</div>
		</div>
	) : null
}
