import FeatureGuard from "@components/featureGuard/FeatureGuard"
import { changeOpacity } from "@components/map/mapComponents/createCombinedTooltip"
import { mapLayersColors } from "@components/map/utils/mapLayers"
import ToggleSwitch from "@components/toggleSwitch/ToggleSwitch"
import { ToggleStyle } from "@components/toggleSwitch/types"
import * as AccordionPrimitive from "@radix-ui/react-accordion"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import {
	MapFiltersFacilities,
	getMapFiltersFacilities,
	updateMapFiltersFacilities,
} from "@redux/features/mapFiltersFacilities/mapFiltersFacilitiesSlice"
import { PortalFeature } from "api"
import { memo, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { MapFilterAccordionContent } from "./mapFilterAccordion/MapFilterAccordionContent"
import MapFilterAccordionTriggerMain from "./mapFilterAccordion/MapFilterAccordionTriggerMain"

type MapFiltersFacilitiesSub<O extends keyof MapFiltersFacilities> = MapFiltersFacilities[O]
type SubProp<O extends keyof MapFiltersFacilities> = keyof MapFiltersFacilitiesSub<O>

const MapFiltersFacilitiesContent = memo(() => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const mapFiltersFacilities = useAppSelector(getMapFiltersFacilities)

	const onFilterVisibilityChanged = useCallback(
		<O extends keyof MapFiltersFacilities>(mainProp: O, subProp: SubProp<O>) =>
			(value: boolean) => {
				dispatch(
					updateMapFiltersFacilities({
						...mapFiltersFacilities,
						[mainProp]: { ...mapFiltersFacilities[mainProp], [subProp]: value },
					})
				)
			},
		[mapFiltersFacilities]
	)

	return (
		<AccordionPrimitive.Root
			type="single"
			defaultValue="mainItem-1"
			collapsible
		>
			<AccordionPrimitive.Item value="mainItem-1">
				<MapFilterAccordionTriggerMain>{t("FACILITIES")}</MapFilterAccordionTriggerMain>
				<MapFilterAccordionContent>
					<div className="grid">
						<ListEntry
							initialState={mapFiltersFacilities.facilities.showPorts}
							label={`${t("SHOW")} ${t("PORTS").toLowerCase()}`}
							labelColor={mapLayersColors.facilities["PORT"]}
							mainProp="facilities"
							subProp="showPorts"
							onChange={onFilterVisibilityChanged}
						/>

						<FeatureGuard featureName={PortalFeature.OIL_AND_GAS}>
							<>
								<ListEntry
									initialState={mapFiltersFacilities.facilities.showPlatforms}
									label={`${t("SHOW")} ${t(
										"OIL AND GAS PLATFORMS"
									).toLowerCase()}`}
									labelColor={mapLayersColors.facilities["PLATFORM"]}
									mainProp="facilities"
									subProp="showPlatforms"
									onChange={onFilterVisibilityChanged}
								/>
								<ListEntry
									initialState={
										mapFiltersFacilities.facilities.showDischargeTerminals
									}
									label={`${t("SHOW")} ${t("DISCHARGE TERMINALS").toLowerCase()}`}
									labelColor={mapLayersColors.facilities["DISCHARGE_TERMINAL"]}
									mainProp="facilities"
									subProp="showDischargeTerminals"
									onChange={onFilterVisibilityChanged}
								/>
								<ListEntry
									initialState={mapFiltersFacilities.facilities.showDischargeSTS}
									label={`${t("SHOW")} ${t("DISCHARGE STS").toLowerCase()}`}
									labelColor={mapLayersColors.facilities["DISCHARGE_STS"]}
									mainProp="facilities"
									subProp="showDischargeSTS"
									onChange={onFilterVisibilityChanged}
								/>
								<ListEntry
									initialState={mapFiltersFacilities.facilities.showAnchorage}
									label={`${t("SHOW")} ${t("ANCHORAGES").toLowerCase()}`}
									labelColor={mapLayersColors.facilities["ANCHORAGE"]}
									mainProp="facilities"
									subProp="showAnchorage"
									onChange={onFilterVisibilityChanged}
								/>
							</>
						</FeatureGuard>
						<FeatureGuard featureName={PortalFeature.FISH_FARM}>
							<ListEntry
								initialState={mapFiltersFacilities.facilities.showFishFarms}
								label={`${t("SHOW")} ${t("FISH FARMS").toLowerCase()}`}
								labelColor={mapLayersColors.facilities["FISH_FARM"]}
								mainProp="facilities"
								subProp="showFishFarms"
								onChange={onFilterVisibilityChanged}
							/>
						</FeatureGuard>
					</div>
				</MapFilterAccordionContent>
			</AccordionPrimitive.Item>
		</AccordionPrimitive.Root>
	)
})

export default MapFiltersFacilitiesContent

const ListEntry = memo(
	<O extends keyof MapFiltersFacilities>({
		label,
		initialState,
		onChange,
		mainProp,
		subProp,
		labelColor,
	}: {
		label: string
		initialState: boolean
		onChange: (
			mainProp: O,
			subProp: keyof MapFiltersFacilitiesSub<O>
		) => (value: boolean) => void
		mainProp: O
		subProp: keyof MapFiltersFacilitiesSub<O>
		labelColor: string
	}) => {
		return (
			<div className="hover:bg-gray-f4 flex flex-row items-center justify-between px-2 py-1">
				<ToggleSwitch
					onChange={onChange(mainProp, subProp)}
					initialState={initialState}
					style={ToggleStyle.Rounded}
					label={label}
				/>
				<div
					style={{
						backgroundColor: changeOpacity(labelColor),
						borderColor: labelColor,
					}}
					className={`h-5 w-5 rounded-full border-2`}
				/>
			</div>
		)
	}
)
