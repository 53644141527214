import * as Tooltip from "@radix-ui/react-tooltip"
import { useAppDispatch } from "@redux/app/hooks"
import {
	setUniSeaVessels,
	UniSeaVesselsList,
} from "@redux/features/uniSeaVessels/uniSeaVesselsSlice"
import { PlantObject } from "api"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdFeed } from "react-icons/md"

export type UniSeaVesselIconProps = {
	firstTimestamp: number | null | undefined
	colorTwString?: string
}

export const UniSeaVesselIcon: FC<UniSeaVesselIconProps> = ({ firstTimestamp, colorTwString }) => {
	// couldnt replace this component for the Tooltip from the ui-lib. There is an error with reference to the positioning element... or something along this line
	// it has something to do with the asChild property
	const { t } = useTranslation()
	const [openTooltip, setOpenTooltip] = useState<boolean>(false)
	// let hovering = false
	const [clickOutsidePending, setClickOutsidePending] = useState(false)

	return firstTimestamp !== undefined && firstTimestamp !== null ? (
		<Tooltip.Provider>
			<Tooltip.Root delayDuration={50} open={openTooltip}>
				<Tooltip.Trigger
					// asChild
					onClick={(e) => {
						e.stopPropagation()
						if (!clickOutsidePending) {
							setOpenTooltip(!openTooltip)
						}
						setClickOutsidePending(false)
					}}
					onMouseEnter={() => {
						// hovering = true
						setTimeout(() => {
							// if (hovering) {
							setOpenTooltip(true)
							// }
						}, 200)
					}}
					onMouseLeave={() => {
						// hovering = false
						setTimeout(() => {
							setOpenTooltip(false)
						}, 200)
					}}
				>
					<MdFeed
						className={`h-5 w-5 cursor-help ${
							colorTwString !== undefined ? colorTwString : "text-blue-400"
						}`}
					/>
				</Tooltip.Trigger>
				<Tooltip.Portal>
					<Tooltip.Content
						className={`z-10001 h-auto w-auto rounded-sm bg-dipai-secondary-800/85 px-2 py-1 text-white/100`}
						sideOffset={5}
						side={`right`}
						onPointerDownOutside={(e) => {
							setOpenTooltip(false)
							setClickOutsidePending(true)
						}}
						onEscapeKeyDown={() => setOpenTooltip(false)}
					>
						<p>{t(`ONLY UNISEA DATA IS AVAILABLE FOR THIS VESSEL`)}</p>
						<p>
							{`Data ${t(`AVAILABLE`)} ${t(`FROM`).toLowerCase()}: ${new Date(
								firstTimestamp
							).toString()}`}
						</p>
						<Tooltip.Arrow className={`fill-dipai-secondary-800/85`} />
					</Tooltip.Content>
				</Tooltip.Portal>
			</Tooltip.Root>
		</Tooltip.Provider>
	) : null
}

export function useGetUniSeaVessels(customerPlants: { [plantName: string]: PlantObject }) {
	const dispatch = useAppDispatch()

	useEffect(() => {
		const uniSeaVesselsTemp: UniSeaVesselsList = []
		Object.entries(customerPlants).forEach(([plantName, plantData]) => {
			const useUniSeaMark = plantData.marks.find((markData) => markData.mark === "use unisea")
			if (useUniSeaMark === undefined) {
				return
			}
			uniSeaVesselsTemp.push({
				id: plantData.id,
				name: plantName,
				firstTimestamp: new Date(useUniSeaMark.t1).getTime(),
			})
		})

		dispatch(setUniSeaVessels(uniSeaVesselsTemp))
	}, [customerPlants])
}
