import { removeIncompatibleSearchParameters } from "@helpers/removeIncompatibleSearchParameters"
import { voyagePageSearchSchema } from "@routes/clients.$clientName/_sopPageLayout/voyage"
import { createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute("/_sopPageLayout/voyage")({
	component: RouteComponent,
	validateSearch: voyagePageSearchSchema,
	search: {
		middlewares: [removeIncompatibleSearchParameters(voyagePageSearchSchema)],
	},
})

function RouteComponent() {
	return null
}
