import CompassCurrent from "@components/compass/CompassCurrent"
import CompassWind from "@components/compass/CompassWind"
import { CompassTimeseriesData } from "@components/compass/compassDrawClass"
import { isFeatureAvailable, useGetDevFeatures } from "@components/featureGuard/FeatureGuard"
import { useGetFacilitiesRadiusQuery } from "@components/map/dataGettterHooks/useGetFacilitiesRadiusQuery"
import useGetFishFarmDataForMap from "@components/map/dataGettterHooks/useGetFishFarmDataForMap"
import useGetOilGasDataForMap from "@components/map/dataGettterHooks/useGetOilGasDataForMap"
import RegularMapComponent3 from "@components/map/regularMap/RegularMapComponent"
import { LayerData, MapLayers } from "@components/map/utils/mapLayers"
import Modal from "@components/modal/Modal"
import PlotCard from "@components/plot/PlotCard"
import PlotConfig from "@components/plot/plotConfig/PlotConfig"
import { ImportantIcon } from "@helpers/ImportantIcon"
import { debounce } from "@helpers/debounce"
import { getFileName } from "@helpers/getFileName"
import { getSensorIdWithoutComponent } from "@helpers/getSensorIdWithoutComponent"
import { useGetOpModesListQuery } from "@queries/useGetOpModesListQuery"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import { getAppStructure } from "@redux/features/appStructure/appStructureSlice"
import { getCurrentCompany } from "@redux/features/currentCompany/currentCompanySlice"
import {
	getDiseaseZones,
	getSelectedDiseaseZones,
} from "@redux/features/diseaseZones/diseaseZonesSlice"
import { getFacilities, setFacilities } from "@redux/features/facilities/facilitiesSlice"
import { getMapFiltersFacilities } from "@redux/features/mapFiltersFacilities/mapFiltersFacilitiesSlice"
import { getMapParamsState } from "@redux/features/mapParams/mapParamsSlice"
import { TimelineEventMarker } from "@redux/features/timelineEventMarker/timelineEventMarkerSlice"
import { getUserData } from "@redux/features/userData/userDataSlice"
import { opModeNameColors2 } from "@sop/FuelPage/Total/utils/utilityFunctions"
import { useSearch } from "@tanstack/react-router"
import { Row } from "@tanstack/react-table"
import { VerticalLinePluginProps } from "@ui-lib/core/chartJS/plugins/verticalLine"
import { Button, IconButton, LoadingCircleSimples, Tooltip as TooltipButton } from "@ui-lib/src"
import { COLORS_PLOTS_COLORBLIND } from "@utils/colorsPlots"
import { common } from "@utils/common"
import { requestApi2 } from "@utils/http"
import {
	AggregationMethod,
	FacilityType,
	FuelPageGetBaselineDataResponse,
	GetAllFacilitiesArgs,
	GetPlantMaxKwArgs,
	GetPlantMaxKwResponse,
	PlantInfo,
	PlantPlot,
	PortalFeature,
	Segment,
	VesselTrack,
	endpoints,
} from "api"
import { ChartData, Chart as ChartJS, ChartOptions } from "chart.js"
import { RefObject, memo, useCallback, useEffect, useRef, useState } from "react"
import { Line } from "react-chartjs-2"
import { useTranslation } from "react-i18next"
import { MdVisibility, MdVisibilityOff } from "react-icons/md"
import { useQuery } from "react-query"
import {
	COMBINED_MODES,
	EventTableEntry,
	FuelConsumptionTimelineTableItem,
} from "./OperationsTimelineContent"
import { useGetTimeseries4Query } from "./queries/useGetTimeseries4Query"

export interface SensorData {
	id: number
	data: [number, number | null][]
}

let leftEnd: number
let rightEnd: number

const uniqueObjects = <T extends Object>(arr: T[]) => {
	const seen = new Map()
	return arr.filter((obj) => {
		const key = Object.entries(obj).sort().toString()
		if (seen.has(key)) return false
		seen.set(key, obj)
		return true
	})
}

function updateChartTimeRange(charts: (ChartJS | null)[]) {
	charts.forEach((chart) => {
		if (chart === null) {
			return
		}
		if (chart.options.scales !== undefined && chart.options.scales.x !== undefined) {
			chart.options.scales.x.min = leftEnd
			chart.options.scales.x.max = rightEnd
			chart.update("zoom")
		}
	})
}

type GetVesselTrackQueryArgs = {
	plantId: number
	t1: Date | undefined
	t2: Date | undefined
}
export function useGetVesselTrackQuery(args: GetVesselTrackQueryArgs) {
	const res = useQuery(
		[
			`source=${getFileName()}`,
			`queryId=GetVesselTrack`,
			`endpoints=[${endpoints.getPlantLocationTrack.name}]`,
			`plantId=${args.plantId}`,
			`t1=${args.t1?.toISOString !== undefined ? args.t1.toISOString() : args.t1}`,
			`t2=${args.t2?.toISOString !== undefined ? args.t2.toISOString() : args.t2}`,
		],
		async (): Promise<VesselTrack | undefined> => {
			if (args.plantId === -1 || args.t1 === undefined || args.t2 === undefined) {
				return undefined
			}
			const response = await requestApi2(endpoints.getPlantLocationTrack, {
				plantId: args.plantId,
				interval: { t1: args.t1, t2: args.t2 },
			})
			return response !== null ? response : undefined
		},
		{
			staleTime: 600000,
			refetchInterval: 600000,
		}
	)
	return res
}

type GetVesselTrackQueryV2Args = {
	plantId: number
	t1: Date | undefined
	t2: Date | undefined
	refreshQuery?: boolean
}
export function useGetVesselTrackQueryV2(args: GetVesselTrackQueryV2Args) {
	const res = useQuery(
		[
			`source=${getFileName()}`,
			`queryId=GetVesselTrackV2`,
			`endpoints=[${endpoints.getPlantLocationTrack.name}]`,
			`plantId=${args.plantId}`,
			`t1=${
				args.t1 !== undefined
					? `${args.t1.toISOString().split(":")[0]}:${
							args.t1.toISOString().split(":")[1]
						}`
					: "no date from"
			}`,
			`t2=${
				args.t2 !== undefined
					? `${args.t2.toISOString().split(":")[0]}:${
							args.t2.toISOString().split(":")[1]
						}`
					: "no date to"
			}`,
			`refreshQuery=${args.refreshQuery === true ? "true" : "false"}`,
		],
		async (): Promise<VesselTrack | undefined> => {
			if (args.plantId === -1) {
				return undefined
			}
			if (args.t1 === undefined || args.t2 === undefined) {
				return undefined
			}
			const toTimestamp = new Date()
			if (args.refreshQuery !== true) {
				toTimestamp.setTime(args.t2.getTime())
			}

			const response = await requestApi2(endpoints.getPlantLocationTrack, {
				plantId: args.plantId,
				interval: { t1: args.t1, t2: toTimestamp },
			})
			return response !== null ? response : undefined
		},
		{
			keepPreviousData: true,
			staleTime: args.refreshQuery === true ? 60 * 1000 : 60 * 60 * 1000,
			refetchInterval: args.refreshQuery === true ? 60 * 1000 : 60 * 60 * 1000,
		}
	)
	return res
}

type GetBaselineDataQueryArgs = { plantId: number; t1: Date; t2: Date }
export function useGetBaselineDataQuery(args: GetBaselineDataQueryArgs) {
	const res = useQuery(
		[
			`source=${getFileName()}`,
			`queryId=GetBaselineData`,
			`endpoints=[${endpoints.fuelPageGetBaselineData.name}]`,
			`plantId=${args.plantId}`,
			`t1=${args.t1?.toISOString !== undefined ? args.t1.toISOString() : args.t1}`,
			`t2=${args.t2?.toISOString !== undefined ? args.t2.toISOString() : args.t2}`,
		],
		async (): Promise<FuelPageGetBaselineDataResponse | undefined> => {
			if (args.plantId === -1) {
				return undefined
			}
			if (args.t1 === undefined || args.t2 === undefined) {
				return undefined
			}

			const response = await requestApi2(endpoints.fuelPageGetBaselineData, {
				plantId: args.plantId,
				t1: args.t1,
				t2: args.t2,
			})
			return response !== null ? response : undefined
		},
		{
			staleTime: 600000,
			refetchInterval: 600000,
		}
	)
	return res
}

function useFuelPageGetPlantMaxKwArgs(args: GetPlantMaxKwArgs) {
	const res = useQuery(
		[
			`source=${getFileName()}`,
			`queryId=GetPlantMaxKwArgs`,
			`endpoints=[${endpoints.hydrodynamicPerformanceGetPlantMaxKw.name}]`,
			`plantIds=${args.plantIds}`,
		],

		async (): Promise<GetPlantMaxKwResponse | undefined | null> => {
			if (args.plantIds === undefined) return undefined

			const response = await requestApi2(endpoints.hydrodynamicPerformanceGetPlantMaxKw, {
				plantIds: args.plantIds,
			})
			return response
		},
		{
			staleTime: 600000,
			refetchInterval: 600000,
		}
	)
	return res
}

const findWhichCombineMode = (
	opModesList: {
		name: string
		id: number
	}[],
	refOpModeId: number
) => {
	const refOpModeName = opModesList
		.find((opModeData) => opModeData.id === refOpModeId)
		?.name.toLowerCase()

	if (refOpModeName === undefined) {
		return undefined
	}
	for (const combinedMode of COMBINED_MODES) {
		if (refOpModeName.includes(combinedMode.refString) === true) {
			return combinedMode
		}
	}
	return undefined
}

export const ChartContainer = memo(
	(props: {
		plotData: ChartData<"line"> | undefined
		options: ChartOptions<"line"> | undefined
		minY?: number
		heightExpression: string
		minHeightExpression?: string
		chartRef: RefObject<ChartJS<"line"> | null>
		chartKey?: string
	}) => {
		return props.plotData !== undefined &&
			props.options !== undefined &&
			props.plotData.datasets.length > 0 ? (
			<div
				className={`w-full rounded-sm border-1`}
				style={{
					height: props.heightExpression,
					minHeight: props.minHeightExpression,
				}}
			>
				<Line
					key={props.chartKey}
					ref={props.chartRef}
					options={{
						...props.options,
						scales: {
							...props.options.scales,
							y: {
								...props.options.scales?.y,
								min: props.minY,
							},
						},
					}}
					data={props.plotData}
				/>
			</div>
		) : null
	}
)

export const generateDefaultOptions = (
	chart: RefObject<ChartJS<"line"> | null>,
	otherCharts: RefObject<ChartJS<"line"> | null>[],
	yAxis: { label: string; position?: "left" | "right" }[],
	minFrom: number,
	maxTo: number,
	timestampGetter: (tRef: number) => void,
	units?: { label: string; unit: string }[],
	bgColors?: {
		colorMap: {
			[modeName: string]: string
		}
		ranges: [number, number, string][]
	},
	syncGroupId?: number | string,
	verticalLine?: VerticalLinePluginProps,
	animate?: boolean
): ChartOptions<"line"> => {
	return {
		// responsive: true,
		animation: animate === false ? false : undefined,
		maintainAspectRatio: false,
		interaction: {
			mode: "index",
			intersect: false,
		},

		plugins: {
			tooltip: {
				position: "average",
				animation: { duration: 350, delay: 10 },
				callbacks: {
					label(tooltipItem) {
						const label = tooltipItem.dataset.label
						const value = tooltipItem.parsed.y
						return `${label}: ${typeof value === "number" ? value.toFixed(1) : value} ${
							units?.find((u) => u.label === label)?.unit ?? ""
						}`
					},
				},
				intersect: false,
				mode: "index",
			},
			legend: {
				position: "bottom",
			},
			title: {
				display: false,
			},
			zoom: {
				limits: {
					x: {
						min: minFrom,
						max: maxTo,
					},
				},
				pan: {
					enabled: true,
					mode: "x",
					onPan: function () {
						const chartObj = chart.current
						if (chartObj === null) {
							return
						}
						const xScale = chartObj.scales.x
						if (xScale === undefined) {
							return
						}
						leftEnd = xScale.min
						rightEnd = xScale.max
						updateChartTimeRange([...otherCharts.map((chart) => chart.current)])
					},
				},
				zoom: {
					wheel: {
						enabled: true,
					},
					pinch: {
						enabled: true,
					},
					onZoom: function () {
						const chartObj = chart.current
						if (chartObj === null) {
							return
						}
						const xScale = chartObj.scales.x
						if (xScale === undefined) {
							return
						}
						leftEnd = xScale.min
						rightEnd = xScale.max
						updateChartTimeRange([...otherCharts.map((chart) => chart.current)])
					},
					mode: "x",
				},
			},
			bgColor: bgColors ?? {
				colorMap: {},
				ranges: [],
			},
			verticalLine: verticalLine,
			crosshairV2: {
				timestampGetter: timestampGetter,
				sync: {
					enabled: true,
					group: syncGroupId ?? minFrom + maxTo,
					suppressTooltips: false,
				},
			},
		},
		scales: yAxis.reduce(
			(acc, curr, idx) => {
				acc[`y${idx === 0 ? "" : idx}`] = {
					position: curr.position,
					grid: { display: idx > 0 ? false : true },
					title: {
						display: true,
						text: curr.label,
					},
				}
				return acc
			},
			{
				x: {
					type: "time",
					time: {
						displayFormats: {
							hour: "HH:mm",
							minute: "HH:mm",
							second: "HH:mm:ss",
						},
						tooltipFormat: "MMM dd, yyyy, HH:mm:ss",
					},
					title: {
						display: false,
						text: "time",
					},
				},
			}
		),
	}
}

function ComponentBody({
	eventData,
	eventData_last,
	eventData_0,
}: {
	eventData: EventTableEntry[]
	eventData_last: EventTableEntry
	eventData_0: EventTableEntry
}) {
	const THRESHOLD_POWER = 1 // %

	const { t } = useTranslation()
	const appStructure = useAppSelector(getAppStructure)
	const userData = useAppSelector(getUserData)
	const devFeatures = useGetDevFeatures()

	const dispatch = useAppDispatch()

	const facilitiesData = useAppSelector(getFacilities)

	const paramSelectedVessel = useSearch({ strict: false })?.vessel ?? ""

	const currentCompany = useAppSelector(getCurrentCompany)
	const plantData = common.plantDataFromOwnerAndName.get(
		`${currentCompany?.toLowerCase()}/${paramSelectedVessel?.toLowerCase()}`
	)

	const listOfTimestampsSorted = eventData
		.map((e) => [e.event.t1, e.event.t2])
		.flat()
		.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

	const [plotZoom, setPlotZoom] = useState<
		{
			plotId: number
			zoomFrom?: number
			zoomTo?: number
		}[]
	>()

	const hybridPropulsionPowerID = getSensorIdWithoutComponent(
		plantData,
		"Hybrid Propulsion Power"
	)

	const {
		isLoading: isOpModesListLoading,
		isError: isOpModesListError,
		data: opModesList,
	} = useGetOpModesListQuery({ plantIdsList: [plantData?.id ?? -1] })

	const combinedModeMetaData = findWhichCombineMode(
		opModesList ?? [],
		eventData_0.metadata.opModeId
	)
	const findOpModeName = (opModeId: number) => {
		return opModesList?.find((opModeData) => opModeData.id === opModeId)?.name
	}

	const {
		isLoading: isVesselTrackLoading,
		isError: isVesselTrackError,
		data: vesselTrack,
	} = useGetVesselTrackQuery({
		plantId: plantData?.id ?? -1,
		t1: listOfTimestampsSorted?.[0],
		t2: listOfTimestampsSorted?.[listOfTimestampsSorted.length - 1],
	})

	const {
		isLoading: isPlantMaxKwLoading,
		isError: isPlantMaxKwError,
		data: plantsMaxKw,
	} = useFuelPageGetPlantMaxKwArgs({
		plantIds: [plantData?.id ?? -1],
	})

	const [time, setTime] = useState<number>()

	const timestampGetter = useCallback(
		debounce((tRef: number) => {
			if (time === tRef) {
				return
			}
			setTime(tRef)
		}, 0),
		[]
	)

	useEffect(() => {
		if (time === undefined) {
			return
		}
		const tRef = time

		const trackPoints = vesselTrack?.locations
		if (trackPoints === undefined) {
			return
		}
		for (let i = 0; i < trackPoints.length; i++) {
			const trackPoint = trackPoints[i]
			if (trackPoint === undefined) {
				continue
			}
			if (i === 0) {
				if (trackPoint.timestamp.getTime() >= tRef) {
					setTimelineEventMarker({
						coords: {
							lat: trackPoint.latitude,
							lon: trackPoint.longitude,
						},
						heading: trackPoint.heading ?? trackPoint.course,
					})
				}
			} else if (i === trackPoints.length - 1) {
				if (trackPoint.timestamp.getTime() <= tRef) {
					setTimelineEventMarker({
						coords: {
							lat: trackPoint.latitude,
							lon: trackPoint.longitude,
						},
						heading: trackPoint.heading ?? trackPoint.course,
					})
				}
			} else {
				const trackPointPrev = trackPoints[i - 1]
				const trackPointNext = trackPoints[i + 1]
				if (trackPointPrev === undefined || trackPointNext === undefined) {
					continue
				}
				if (
					trackPointPrev.timestamp.getTime() <= tRef &&
					trackPointNext.timestamp.getTime() >= tRef
				) {
					setTimelineEventMarker({
						coords: {
							lat: trackPoint.latitude,
							lon: trackPoint.longitude,
						},
						heading: trackPoint.heading ?? trackPoint.course,
					})
				}
			}
		}
	}, [time])

	const conditionChartRef = useRef<ChartJS<"line">>(null)
	const fuelChartRef = useRef<ChartJS<"line">>(null)
	const baselineChartRef = useRef<ChartJS<"line">>(null)
	const loadChartRef = useRef<ChartJS<"line">>(null)

	const [showOpModeBg, setShowOpModeBg] = useState<boolean | undefined>(true)
	const [lastZoomScale, setLastZoomScale] = useState<[number, number] | undefined>()
	const [updateChart, setUpdateChart] = useState<boolean>(false)

	useEffect(() => {
		const xScale = conditionChartRef?.current?.scales["x"]
		if (conditionChartRef.current !== null && xScale !== undefined) {
			setLastZoomScale([xScale.min, xScale.max])
			setUpdateChart(true)
		}
	}, [showOpModeBg])

	const updateChartZoom = (
		chartRef: React.RefObject<ChartJS<"line"> | null>,
		zoomScale: [number, number] | undefined
	) => {
		if (
			chartRef.current !== null &&
			zoomScale !== undefined &&
			chartRef.current.options.scales !== undefined
		) {
			const [min, max] = zoomScale
			const chart = chartRef.current
			if (chart.options.scales?.x !== undefined) {
				chart.options.scales.x.min = min
				chart.options.scales.x.max = max
				chart.update("zoom")
			}
		}
	}

	const bgColorRange = eventData.map((e) => {
		return [
			new Date(e.event.t1).setUTCSeconds(0, 0),
			new Date(e.event.t2).setUTCSeconds(0, 0),
			findOpModeName(e.metadata.opModeId)?.toLowerCase() ?? "(na)",
		]
	})

	const modeColors = Object.fromEntries(
		Object.entries(opModeNameColors2).map(([key, value]) => [key, `${value}40`])
	)

	// CONDITION DATA PLOT
	const [conditionLinePlotOptions, setConditionLinePlotOptions] = useState<ChartOptions<"line">>()
	const [conditionLinePlotData, setConditionLinePlotData] = useState<ChartData<"line">>()

	// LOAD DATA PLOT
	const [loadLinePlotOptions, setLoadLinePlotOptions] = useState<ChartOptions<"line">>(
		generateDefaultOptions(
			loadChartRef,
			[fuelChartRef, conditionChartRef, baselineChartRef],
			[{ label: "Power (kW)" }],
			Math.min(...eventData.map((e) => new Date(e.event.t1).getTime())),
			Math.max(...eventData.map((e) => new Date(e.event.t2).getTime())),
			timestampGetter,
			eventData?.[0]?.metadata.loadSensorsId.map((sensorId) => {
				const sensorData = common.sensorDataFromIds.get(sensorId)
				if (sensorData === undefined) {
					return { label: "", unit: "" }
				}
				return { label: sensorData.display_name, unit: sensorData.unit }
			})
		)
	)
	const [loadLinePlotData, setLoadLinePlotData] = useState<ChartData<"line">>()
	const [minLoad, setMinLoad] = useState<number>(0)

	// FUEL CONSUMPTION PLOT
	const [fuelLinePlotOptions, setFuelLinePlotOptions] = useState<ChartOptions<"line">>(
		generateDefaultOptions(
			fuelChartRef,
			[conditionChartRef, loadChartRef, baselineChartRef],
			[{ label: "Load (%)" }],
			Math.min(...eventData.map((e) => new Date(e.event.t1).getTime())),
			Math.max(...eventData.map((e) => new Date(e.event.t2).getTime())),
			timestampGetter,
			[
				...eventData_0?.metadata.fuelConsumersSensorsId.map((sensorId) => {
					const sensorData = common.sensorDataFromIds.get(sensorId)
					if (sensorData === undefined) {
						return { label: "", unit: "" }
					}
					return { label: sensorData.display_name, unit: sensorData.unit }
				}),
				...uniqueObjects(eventData_0?.metadata.powerProducers).map((producer) => {
					const sensorData = common.sensorDataFromIds.get(producer.kwSensorId)
					if (sensorData === undefined) {
						return { label: "", unit: "" }
					}
					const tempLabel = sensorData.display_name.split(" ")[0] + "Load %"
					return { label: tempLabel, unit: "%" }
				}),
			]
		)
	)

	// BASELINE CONSUMPTION PLOT
	const [baselinePlotOptions, setBaselinePlotOptions] = useState<ChartOptions<"line">>(
		generateDefaultOptions(
			baselineChartRef,
			[fuelChartRef, conditionChartRef, loadChartRef],
			[{ label: "Fuel rate (L/h)" }],
			Math.min(...eventData.map((e) => new Date(e.event.t1).getTime())),
			Math.max(...eventData.map((e) => new Date(e.event.t2).getTime())),
			timestampGetter,
			[
				{ label: "Baseline Fuel Rate", unit: "L/h" },
				{ label: "Total Fuel Rate", unit: "L/h" },
				{ label: "Deviation", unit: "%" },
			]
		)
	)

	const [fuelLinePlotData, setFuelLinePlotData] = useState<ChartData<"line">>()
	const [baselinePlotData, setBaselinePlotData] = useState<ChartData<"line">>()
	const [minFuel, setMinFuel] = useState<number>(0)
	const [minBaseline, setMinBaseline] = useState<number>(0)

	const {
		isLoading: isTimeseriesDataLoading,
		isError: isTimeseriesDataError,
		data: timeseriesData,
	} = useGetTimeseries4Query({
		gte: eventData_last.event.t1,
		lt: eventData_0.event.t2,
		sensorIds: [
			...(eventData?.[0]?.metadata.conditionSensorsId ?? []),
			...(eventData?.[0]?.metadata.fuelConsumersSensorsId ?? []),
			...(eventData?.[0]?.metadata.loadSensorsId ?? []),
			...new Set(eventData?.[0]?.metadata.powerProducers.map((a) => a.kwSensorId) ?? []),
		],
	})

	// 1729990800000, 3.644
	// 1729987200000, 3.6449016100178895

	const {
		isLoading: isBaselineDataLoading,
		isError: isBaselineDataError,
		data: baselineData,
	} = useGetBaselineDataQuery({
		plantId: plantData?.id ?? -1,
		t1: eventData_last.event.t1,
		t2: eventData_0.event.t2,
	})

	const [timeseriesDataV2, setTimeseriesDataV2] = useState<{
		sensors: {
			data: [number, number][]
			id: number
		}[]
	}>()
	const speedId = -5

	let plantMaxPowerProducedKw: number | undefined = undefined
	const maxKwProduced = eventData?.[0]?.metadata.powerProducers
		.flatMap((a) => a.maxKw)
		.filter((f) => f !== null)
	if (maxKwProduced !== undefined) {
		plantMaxPowerProducedKw = maxKwProduced.reduce((acc, cur) => acc + cur, 0)
	}

	useEffect(() => {
		if (timeseriesData !== null && timeseriesData !== undefined) {
			// Creating a deep copy of timeseriesData to modify
			const timeseriesDataWithPowerPercent: {
				sensors: {
					data: [number, number][]
					id: number
				}[]
			} = {
				// ...timeseriesData,
				sensors: timeseriesData.sensors.map((sensor) => {
					// Check if this sensor's ID matches any ID from powerProducers
					const producer = eventData?.[0]?.metadata.powerProducers.find(
						(p) => p.kwSensorId === sensor.id
					)
					if (producer !== undefined) {
						return {
							...sensor,
							data: sensor.data.map(([date, value]) => [
								date,
								producer.maxKw !== null ? (100 / producer.maxKw) * value : 0,
							]),
						}
					} else {
						// If no match, return the sensor data unchanged
						return sensor
					}
				}),
			}
			if (vesselTrack !== undefined) {
				timeseriesDataWithPowerPercent.sensors.push({
					id: speedId,
					data: vesselTrack.locations
						.filter(
							(
								l
							): l is {
								timestamp: Date
								heading: number | null
								latitude: number
								longitude: number
								course: number | null
								speed: number
							} => l.speed !== null
						)
						.map((l) => [l.timestamp.getTime(), l.speed]),
				})
			}
			setTimeseriesDataV2(timeseriesDataWithPowerPercent)
		}
	}, [isTimeseriesDataLoading, timeseriesData, eventData, vesselTrack])

	//find pairs of powerproducers and fuel consumers
	const [sensorPair, setSensorPair] = useState<Array<[number, number]>>([])
	useEffect(() => {
		if (eventData === null || eventData === undefined) {
			return
		}
		const newPairs = uniqueObjects(eventData_0.metadata.powerProducers).flatMap(
			(producer): [number, number][] => {
				const tempLabel = common.sensorDataFromIds
					.get(producer.kwSensorId)
					?.display_name.split(" ")[0]

				const tempSensorID = producer.kwSensorId

				const matchedSensorId = eventData_0.metadata.fuelConsumersSensorsId.find(
					(id) =>
						common.sensorDataFromIds.get(id)?.display_name.split(" ")[0] === tempLabel
				)

				if (matchedSensorId != null) {
					return [[matchedSensorId, tempSensorID] as [number, number]]
				} else {
					return []
				}
			}
		)

		setSensorPair((prevPairs) => [...prevPairs, ...newPairs])
	}, [eventData])

	const baselineFuelRateIds = [-1, -2, -4] as const
	const baselinePowerProductionId = [-3] as const

	const [timeseriesDataWithBaseline, setTimeseriesDataWithBaseline] = useState<{
		sensors: {
			data: [number, number][]
			id: number
		}[]
	}>()

	useEffect(() => {
		if (
			isTimeseriesDataLoading === true ||
			timeseriesData === undefined ||
			timeseriesData === null
		) {
			return
		}
		if (isBaselineDataLoading === true || baselineData === undefined || baselineData === null) {
			return
		}
		if (
			isBaselineDataLoading === false &&
			baselineData !== undefined &&
			baselineData !== null &&
			timeseriesDataV2 !== undefined &&
			timeseriesDataV2 !== null
		) {
			const res = timeseriesDataV2.sensors.slice()

			if (baselineData.samples.length > 0) {
				const actualValueSensorData: SensorData = {
					id: baselineFuelRateIds[0],
					data: new Array<[number, number]>(),
				}

				const baselineValueSensorData: SensorData = {
					id: baselineFuelRateIds[1],
					data: new Array<[number, number]>(),
				}

				const powerProductionPercentageData: SensorData = {
					id: baselinePowerProductionId[0],
					data: new Array<[number, number]>(),
				}
				const baselineDiffPercentage: SensorData = {
					id: baselineFuelRateIds[2],
					data: new Array<[number, number]>(),
				}

				baselineData.samples.forEach((element) => {
					const timestamp = element.timestamp

					if (
						element.fuelRateLitresPerHour.baselineValue !== undefined ||
						element.fuelRateLitresPerHour.baselineValue != null ||
						element.fuelRateLitresPerHour.actualValue !== undefined ||
						element.fuelRateLitresPerHour.actualValue != null ||
						element.powerProductionPercentage !== undefined ||
						element.powerProductionPercentage != null
					) {
						actualValueSensorData.data.push([
							timestamp.getTime(),
							element.fuelRateLitresPerHour.actualValue ?? null,
						])
						baselineValueSensorData.data.push([
							timestamp.getTime(),
							element.fuelRateLitresPerHour.baselineValue ?? null,
						])
						powerProductionPercentageData.data.push([
							timestamp.getTime(),
							element.powerProductionPercentage ?? null,
						])

						if (
							element.fuelRateLitresPerHour.baselineValue !== undefined &&
							element.fuelRateLitresPerHour.actualValue >= 0
						) {
							baselineDiffPercentage.data.push([
								timestamp.getTime(),
								element.fuelRateLitresPerHour.baselineValue === undefined
									? null
									: (100 * element.fuelRateLitresPerHour.actualValue) /
											element.fuelRateLitresPerHour.baselineValue -
										100,
							])
						}
					}
				})

				const fixedActual = new Array<[number, number]>()
				for (const sample of actualValueSensorData.data) {
					const [x1, x2] = sample
					if (x2 == null) {
						continue
					}
					fixedActual.push([x1, x2])
				}
				let exists = res.some((element) => element.id === actualValueSensorData.id)
				if (fixedActual.some((element) => typeof element[1] === "number")) {
					if (!exists) {
						res.push({ id: actualValueSensorData.id, data: fixedActual })
					}
				}

				const fixedBaseline = new Array<[number, number]>()
				for (const sample of baselineValueSensorData.data) {
					const [x1, x2] = sample
					if (x2 == null) {
						continue
					}
					fixedBaseline.push([x1, x2])
				}
				if (fixedBaseline.some((element) => typeof element[1] === "number")) {
					exists = res.some((element) => element.id === baselineValueSensorData.id)
					if (!exists) {
						res.push({ id: baselineValueSensorData.id, data: fixedBaseline })
					}
				}

				const fixedPowerProductionPercentage = new Array<[number, number]>()
				for (const sample of powerProductionPercentageData.data) {
					const [x1, x2] = sample
					if (x2 == null) {
						continue
					}
					fixedPowerProductionPercentage.push([x1, x2])
				}
				if (
					fixedPowerProductionPercentage.some((element) => typeof element[1] === "number")
				) {
					exists = res.some((element) => element.id === powerProductionPercentageData.id)
					if (!exists) {
						res.push({
							id: powerProductionPercentageData.id,
							data: fixedPowerProductionPercentage,
						})
					}
				}

				const fixedDiffPercentage = new Array<[number, number]>()
				for (const sample of baselineDiffPercentage.data) {
					const [x1, x2] = sample
					if (x2 == null) {
						continue
					}
					fixedDiffPercentage.push([x1, x2])
				}

				if (fixedDiffPercentage.some((element) => typeof element[1] === "number")) {
					exists = res.some((element) => element.id === baselineDiffPercentage.id)
					if (!exists) {
						res.push({
							id: baselineDiffPercentage.id,
							data: fixedDiffPercentage,
						})
					}
				}
			}

			setTimeseriesDataWithBaseline({ sensors: res })
		}
	}, [timeseriesDataV2, baselineData])

	// baseline values added to timeseriesData and results in timeseriesDataWithBaselin	// timeseriesDataWithBaseline replaces all timeseriesData  further down in the code

	const {
		isLoading: isTimeseriesWeatherDataLoading,
		isError: isTimeseriesWeatherDataError,
		data: timeseriesWeatherData,
	} = useGetTimeseries4Query({
		gte: eventData_last.event.t1,
		lt: eventData_0.event.t2,
		sensorIds: [...(eventData?.[0]?.metadata.weatherSensorsId ?? [])],
	})

	const opModeColorsMap: number[] = []
	eventData.forEach((evt) => {
		if (opModeColorsMap.includes(evt.metadata.opModeId) === false) {
			opModeColorsMap.push(evt.metadata.opModeId)
		}
	})

	const [compassWeatherData, setCompasWeatherData] = useState<CompassTimeseriesData>()

	useEffect(() => {
		if (
			timeseriesWeatherData === undefined ||
			timeseriesWeatherData === null ||
			timeseriesDataWithBaseline === undefined ||
			timeseriesDataWithBaseline === null ||
			opModesList === undefined
		) {
			return
		}

		let minProducerLoadTemp = 1000000000
		let minFuelTemp = 1000000000
		let maxFuelTemp = 0
		let minLoadTemp = 1000000000
		let minBaselineTemp = 1000000000

		const tempConditionPlotData: ChartData<"line"> = {
			labels: [],
			datasets: [],
		}
		const tempLoadPlotData: ChartData<"line"> = {
			labels: [],
			datasets: [],
		}
		const tempFuelPlotData: ChartData<"line"> = {
			labels: [],
			datasets: [],
		}

		const tempBaselinePlotData: ChartData<"line"> = {
			labels: [],
			datasets: [],
		}
		const initTs = new Date(eventData_last.event.t1).getTime()
		const endTs = new Date(eventData_0.event.t2).getTime()

		// fixes first and last timestamps for timeseriesV3 response
		for (const sensorData of timeseriesDataWithBaseline.sensors) {
			const tsData = sensorData.data
			const tsData0 = tsData?.[0]

			if (tsData.length === 1 && tsData0 !== undefined) {
				tsData0[0] = initTs
				tsData.push([endTs, tsData0[1]])
				continue
			}
			const tsDataLast = tsData?.[tsData.length - 1]
			if (tsData0 !== undefined) {
				const ts0 = tsData0[0]
				if (ts0 < initTs) {
					tsData0[0] = initTs
				}
			}
			if (tsDataLast !== undefined) {
				const tsLast = tsDataLast[0]
				if (tsLast < endTs) {
					sensorData.data.push([endTs, tsDataLast[1]])
				}
			}
		}

		// make signals uniform
		const uniformTable: {
			timestamp: Date
			sensorValues: Map<number, number | null>
		}[] = []

		// Group data by timestamp
		const dataGroupedByTimestamp = new Map<number, { sensorId: number; value: number }[]>()

		const sensorValueBySensorId = new Map<number, number | null>()

		for (const signal of timeseriesDataWithBaseline.sensors) {
			const sensorId = signal.id
			const data = signal.data
			for (const d of data) {
				const key = new Date(d[0]).setUTCSeconds(0, 0)
				let timestampDatapoints = dataGroupedByTimestamp.get(key)
				if (timestampDatapoints == undefined) {
					timestampDatapoints = []
					dataGroupedByTimestamp.set(key, timestampDatapoints)
				}
				timestampDatapoints.push({ sensorId, value: d[1] })
			}
		}

		// Run through the timestamps chronologically
		const t1Filter = new Date(eventData_last.event.t1).getTime()

		const timestampsSorted = Array.from(dataGroupedByTimestamp.keys())
			.sort((a, b) => a - b)
			.filter((f) => f >= t1Filter)

		for (const timestampMs of timestampsSorted) {
			const datapointsForTimestamp = dataGroupedByTimestamp.get(timestampMs) ?? []

			// Set the state of each sensor
			for (const datapoint of datapointsForTimestamp) {
				sensorValueBySensorId.set(datapoint.sensorId, datapoint.value)
			}
			// Create and save the row
			const row: {
				timestamp: Date
				sensorValues: Map<number, number | null>
			} = {
				sensorValues: new Map(),
				timestamp: new Date(timestampMs),
			}
			// For each sensor, there will be either a new value or a value that was previously set
			for (const [plantId, sensorValue] of sensorValueBySensorId) {
				row.sensorValues.set(plantId, sensorValue)
			}
			uniformTable.push(row)
		}

		const timeseriesDataUniform: {
			sensors: SensorData[]
		} = {
			sensors: timeseriesDataWithBaseline.sensors.map((signal) => {
				return {
					...signal,
					data: uniformTable
						.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
						.map((entry) => {
							return [
								entry.timestamp.getTime(),
								entry.sensorValues.get(signal.id) ?? null,
							]
						}),
				}
			}),
		}

		// to be removed when hybrid propulsion power and propulsion power is merged
		const conditionSensorsId = [
			...(eventData_0?.metadata.conditionSensorsId ?? []).filter(
				(id) => id !== hybridPropulsionPowerID
			),
			speedId,
		]

		const conditionUnitsObj: { label: string; unit: string }[] = []
		conditionSensorsId.forEach((sensorId) => {
			const sensorData = common.sensorDataFromIds.get(sensorId)
			if (sensorData !== undefined) {
				conditionUnitsObj.push({ label: sensorData.display_name, unit: sensorData.unit })
			}
		})

		for (const tsd of timeseriesDataUniform.sensors) {
			for (const index of baselinePowerProductionId) {
				if (tsd.id === index) {
					conditionUnitsObj.push({ label: "Power Production", unit: "%" })
				}
			}
		}

		let conditionLinePlotOptionsTemp: ChartOptions<"line"> =
			conditionLinePlotOptions === undefined
				? {
						// responsive: true,
						maintainAspectRatio: false,
						interaction: {
							mode: "index",
							intersect: false,
						},
						plugins: {
							tooltip: {
								// animation: {
								// 	duration: 400, // ✅ Tooltip animation enabled
								// },
								callbacks: {
									label(tooltipItem) {
										const label = tooltipItem.dataset.label
										const value = tooltipItem.parsed.y
										let tempActualProductionKW: number | undefined = undefined
										let tempActualPropulsionKW: number | undefined = undefined
										const maxPropulsionKW = plantsMaxKw?.find(
											(f) => f.plantId === plantData?.id
										)?.maxKw

										if (
											label?.includes("Production") == true &&
											plantMaxPowerProducedKw !== undefined
										) {
											tempActualProductionKW =
												(plantMaxPowerProducedKw / 100) * value
										}

										if (
											label?.includes("Propulsion") == true &&
											label?.includes("Hybrid") == false &&
											maxPropulsionKW !== undefined
										) {
											tempActualPropulsionKW = (maxPropulsionKW / 100) * value
										}

										return `${label}: ${
											typeof value === "number" ? value.toFixed(1) : value
										} ${
											conditionUnitsObj?.find((u) => u.label === label)
												?.unit ??
											`${label?.includes("Speed") == true ? `kn` : ""}`
										} ${
											tempActualPropulsionKW !== undefined
												? `/ ${tempActualPropulsionKW.toFixed(0)} KW`
												: ""
										}${
											tempActualProductionKW !== undefined
												? `/ ${tempActualProductionKW.toFixed(0)} KW`
												: ""
										}
										`
									},
								},
								intersect: false,
								mode: "index",
							},
							legend: {
								position: "bottom",
							},
							title: {
								display: false,
								text: ``,
							},
							zoom: {
								limits: {
									x: {
										min: Math.min(
											...eventData.map((e) => new Date(e.event.t1).getTime())
										),
										max: Math.max(
											...eventData.map((e) => new Date(e.event.t2).getTime())
										),
									},
								},
								pan: {
									enabled: true,
									mode: "x",
									onPan: function () {
										const chartData = conditionChartRef.current
										if (chartData === null) {
											return
										}
										const xScale = chartData.scales.x
										if (xScale === undefined) {
											return
										}
										leftEnd = xScale.min
										rightEnd = xScale.max
										updateChartTimeRange([
											conditionChartRef.current,
											fuelChartRef.current,
											baselineChartRef.current,
											loadChartRef.current,
										])
									},
								},
								zoom: {
									wheel: {
										enabled: true,
									},
									pinch: {
										enabled: true,
									},
									onZoom: function () {
										const chartData = conditionChartRef.current
										if (chartData === null) {
											return
										}
										const xScale = chartData.scales.x
										if (xScale === undefined) {
											return
										}
										leftEnd = xScale.min
										rightEnd = xScale.max
										updateChartTimeRange([
											conditionChartRef.current,
											fuelChartRef.current,
											baselineChartRef.current,
											loadChartRef.current,
										])
									},
									mode: "x",
								},
							},
							bgColor: {
								colorMap: {},
								ranges: [],
							},
							crosshairV2: {
								timestampGetter: timestampGetter,
								sync: {
									enabled: true,
									group:
										Math.min(
											...eventData.map((e) => new Date(e.event.t1).getTime())
										) +
										Math.max(
											...eventData.map((e) => new Date(e.event.t2).getTime())
										),
									suppressTooltips: false,
								},
							},
						},
						scales: {
							x: {
								type: "time",
								time: {
									displayFormats: {
										hour: "HH:mm",
										minute: "HH:mm",
										second: "HH:mm:ss",
									},
									tooltipFormat: "MMM dd, yyyy, HH:mm:ss",
								},
								title: {
									display: false,
									text: "time",
								},
							},
						},
					}
				: conditionLinePlotOptions

		const conditionUnits: string[] = []
		let conditionColorIndex = 0
		if (conditionSensorsId !== undefined) {
			for (const [index, sensorId] of conditionSensorsId.entries()) {
				const sensorData: SensorData[] = []
				for (const tsd of timeseriesDataUniform.sensors) {
					if (tsd.id === sensorId) {
						sensorData.push(tsd)
					}
				}

				if (sensorData.length > 0) {
					if (eventData.length > 1) {
						conditionLinePlotOptionsTemp = {
							...conditionLinePlotOptionsTemp,
							plugins: {
								...conditionLinePlotOptionsTemp.plugins,
								bgColor:
									showOpModeBg === true
										? {
												colorMap: modeColors,
												ranges: bgColorRange,
											}
										: {},
							},
						}
					}

					const conditionUnit =
						sensorId === speedId
							? "knots"
							: common.sensorDataFromIds.get(sensorId)?.unit

					if (
						conditionUnit !== undefined &&
						((conditionLinePlotOptionsTemp.scales !== undefined &&
							Object.keys(conditionLinePlotOptionsTemp.scales).length === 1) ||
							conditionUnits.includes(conditionUnit) === false)
					) {
						conditionUnits.push(conditionUnit)
						conditionLinePlotOptionsTemp = {
							...conditionLinePlotOptionsTemp,
							scales: {
								...conditionLinePlotOptionsTemp.scales,
								[conditionUnits.length - 1 === 0
									? "y"
									: `y${conditionUnits.length - 1}`]: {
									title: {
										display: true,
										text: `${conditionUnit}`,
									},
									ticks: {
										precision: 1,
									},
									grid: { display: index === 0 ? true : false },
								},
							},
						}
					}

					tempConditionPlotData.labels = sensorData
						.map((sd) => sd.data.map((dp) => dp[0]))
						.flat()
					const unitIndex = conditionUnits.findIndex((u) => u === conditionUnit)

					tempConditionPlotData.datasets.push({
						label:
							sensorId === speedId
								? "Vessel Speed"
								: (common.sensorDataFromIds.get(sensorId)?.display_name ??
									"unknown sensor"),
						data: sensorData.map((sd) => sd.data.map((dp) => dp[1])).flat(),
						borderColor: COLORS_PLOTS_COLORBLIND[conditionColorIndex],
						backgroundColor: COLORS_PLOTS_COLORBLIND[conditionColorIndex],
						borderWidth: 1,
						showLine: true,
						animation: false, //helps with rendering performance
						pointRadius: 0, //helps with rendering performance
						spanGaps: true, //helps with rendering performance
						yAxisID: unitIndex === 0 ? "y" : `y${unitIndex}`,
					})

					conditionColorIndex = conditionColorIndex + 1
				}
			}
		}

		if (baselineData?.samples[0]?.powerProductionPercentage !== undefined) {
			for (const index of baselinePowerProductionId) {
				const sensorData: SensorData[] = []
				for (const tsd of timeseriesDataUniform.sensors) {
					if (tsd.id === index) {
						sensorData.push(tsd)
					}
				}

				if (sensorData.length > 0) {
					if (eventData.length > 1) {
						conditionLinePlotOptionsTemp = {
							...conditionLinePlotOptionsTemp,
							plugins: {
								...conditionLinePlotOptionsTemp.plugins,
								bgColor:
									showOpModeBg === true
										? {
												colorMap: modeColors,
												ranges: bgColorRange,
											}
										: {},
							},
						}
					}

					const conditionUnit = "%"
					if (
						conditionUnit !== undefined &&
						conditionUnits.includes(conditionUnit) === false
					) {
						conditionUnits.push(conditionUnit)
						conditionLinePlotOptionsTemp = {
							...conditionLinePlotOptionsTemp,
							scales: {
								...conditionLinePlotOptionsTemp.scales,
								[conditionUnits.length - 1 === 0
									? "y"
									: `y${conditionUnits.length - 1}`]: {
									title: {
										display: true,
										text: `${conditionUnit}`,
									},
									ticks: {
										precision: 1,
									},
									grid: { display: false },
								},
							},
						}
					}

					const unitIndex = conditionUnits.findIndex((u) => u === conditionUnit)

					tempConditionPlotData.datasets.push({
						label: "Power Production",
						data: sensorData.map((sd) => sd.data.map((dp) => dp[1])).flat(),
						borderColor: COLORS_PLOTS_COLORBLIND[conditionColorIndex],
						backgroundColor: COLORS_PLOTS_COLORBLIND[conditionColorIndex],
						borderWidth: 1,
						showLine: true,
						animation: false, //helps with rendering performance
						pointRadius: 0, //helps with rendering performance
						spanGaps: true, //helps with rendering performance
						yAxisID: unitIndex === 0 ? "y" : `y${unitIndex}`,
					})
				}
			}
		}

		const loadSensorsId = eventData?.[0]?.metadata.loadSensorsId
		const loadLinePlotOptionsTemp = loadLinePlotOptions

		//combine others

		if (loadSensorsId !== undefined) {
			const powerConsumed = timeseriesDataUniform.sensors.filter((f) =>
				loadSensorsId?.includes(f.id)
			)
			const totalPowerProducedSensor = timeseriesDataUniform.sensors.find((f) => f.id === -3)

			const totalPowerProduced: SensorData | undefined =
				totalPowerProducedSensor !== undefined
					? {
							id: totalPowerProducedSensor.id,
							data: totalPowerProducedSensor.data.map(([date, powerPercent]) => {
								if (
									powerPercent !== null &&
									plantMaxPowerProducedKw !== undefined
								) {
									return [
										date,
										(plantMaxPowerProducedKw / 100) * powerPercent,
									] as [number, number]
								} else {
									return [date, null] as [number, number | null]
								}
							}),
						}
					: undefined

			const dateMap = new Map<string, number | null>()

			powerConsumed.forEach((sensor) => {
				sensor.data.forEach(([date, value]) => {
					const dateString = new Date(date).toISOString()
					const currentSum = dateMap.get(dateString)

					if (value === null) {
						// If value is null, keep the current sum as null if it's already null
						if (currentSum === undefined || currentSum === null) {
							dateMap.set(dateString, null)
						}
					} else {
						// If the current sum is null, set to current value, otherwise add the value
						if (currentSum === undefined) {
							dateMap.set(dateString, value)
						} else if (currentSum !== null) {
							dateMap.set(dateString, currentSum + value)
						}
					}
				})
			})

			totalPowerProduced?.data.forEach(([date, totalValue]) => {
				const dateString = new Date(date).toISOString()
				const sumValue = dateMap.get(dateString)

				if (totalValue === null || sumValue === null) {
					// If either total or sum is null, result is null
					dateMap.set(dateString, null)
				} else {
					// Subtract the sum from the total
					const resultValue = totalValue - (sumValue ?? 0)
					dateMap.set(dateString, resultValue)
				}
			})

			const combinedData: [number, number | null][] = Array.from(dateMap.entries()).map(
				([dateString, result]) => [new Date(dateString).getTime(), result]
			)
			const otherConsumers: SensorData = {
				id: 100, // Using a new ID or any other ID you prefer
				data: combinedData,
			}

			for (const [index, sensorId] of loadSensorsId.entries()) {
				const sensorData: SensorData[] = []

				for (const tsd of timeseriesDataUniform.sensors) {
					if (tsd.id === sensorId) {
						sensorData.push(tsd)
					}
				}

				if (sensorData.length > 0) {
					const isComponentStopped =
						sensorData.reduce(
							(acc, sd) =>
								acc +
								sd.data.reduce(
									(acc2, current2) =>
										acc2 + (current2[1] === null ? 0 : current2[1]),
									0
								),
							0
						) === 0

					if (isComponentStopped === false) {
						sensorData.forEach((sd) =>
							sd.data.forEach((dp) => {
								if (dp[1] !== null && dp[1] < minLoadTemp) {
									minLoadTemp = dp[1]
								}
							})
						)
						const pluginsRef = loadLinePlotOptionsTemp.plugins
						if (eventData.length > 1 && pluginsRef !== undefined) {
							pluginsRef.bgColor =
								showOpModeBg === true
									? {
											colorMap: modeColors,
											ranges: bgColorRange,
										}
									: {}
						}

						tempLoadPlotData.labels = sensorData
							.map((sd) => sd.data.map((dp) => dp[0]))
							.flat()
						tempLoadPlotData.datasets.push({
							label:
								common.sensorDataFromIds.get(sensorId)?.display_name ??
								"unknown sensor",
							data: sensorData.map((sd) => sd.data.map((dp) => dp[1])).flat(),
							borderColor: COLORS_PLOTS_COLORBLIND[index],
							backgroundColor: COLORS_PLOTS_COLORBLIND[index],
							borderWidth: 1,
							showLine: true,
							animation: false, //helps with rendering performance
							pointRadius: 0, //helps with rendering performance
							spanGaps: true, //helps with rendering performance
						})
					} else {
						tempLoadPlotData.datasets.push({
							label: `${
								common.sensorDataFromIds
									.get(sensorId)
									?.display_name.split(" Power")[0]
							}: not running`,
							data: sensorData.map((sd) => sd.data.map((dp) => null)).flat(),
							borderColor: "#aaa",
							backgroundColor: "#aaa",
							borderWidth: 1,
							showLine: true,
							animation: false, //helps with rendering performance
							pointRadius: 0, //helps with rendering performance
							spanGaps: true, //helps with rendering performance
						})
					}
				}
			}
			// if (hybridPropulsionPowerID === -1) {
			// 	tempLoadPlotData.datasets.push({
			// 		label: "Others (hotel, deck machinery, etc)",
			// 		data: otherConsumers.data.map((a) => a[1]),
			// 		borderColor: "#aaa",
			// 		backgroundColor: "#aaa",
			// 		borderWidth: 1,
			// 		showLine: true,
			// 		animation: false,
			// 		pointRadius: 0,
			// 		spanGaps: true,
			// 	})
			// }
		}

		setLoadLinePlotOptions({
			...loadLinePlotOptionsTemp,
			plugins: {
				...loadLinePlotOptionsTemp.plugins,
				tooltip: {
					...loadLinePlotOptions.plugins?.tooltip,
					// animation: {
					// 	duration: 400, // ✅ Tooltip animation enabled
					// },
					callbacks: {
						label(tooltipItem) {
							const label =
								tooltipItem.dataset.label?.includes("Other") === true
									? "Other"
									: tooltipItem.dataset.label
							const value = tooltipItem.parsed.y

							return `${label}: ${
								typeof value === "number" ? value.toFixed(1) : value
							} kW`
						},
					},
				},
				legend: {
					...loadLinePlotOptionsTemp.plugins?.legend,
					labels: {
						generateLabels(chart) {
							const labelsOriginal =
								ChartJS.defaults.plugins.legend.labels.generateLabels(chart)

							if (labelsOriginal !== undefined) {
								labelsOriginal.forEach((label) => {
									if (label.text.includes("running") === true) {
										label.fontColor = "#bbb"
									}
								})
							}
							return labelsOriginal
						},
					},
				},
			},
		})
		const fuelConsumersSensorsId = [
			...new Set(eventData_0?.metadata.powerProducers.map((a) => a.kwSensorId) ?? []),
			...eventData_0?.metadata.fuelConsumersSensorsId,
		]
		let fuelConsumerColorIndex = 0
		if (fuelConsumersSensorsId !== undefined) {
			for (const [index, sensorId] of fuelConsumersSensorsId.entries()) {
				const sensorData: SensorData[] = []
				for (const tsd of timeseriesDataUniform.sensors) {
					if (tsd.id === sensorId) {
						sensorData.push(tsd)
					}
				}

				if (sensorData.length > 0) {
					const isComponentRunning =
						sensorData.reduce(
							(acc, sd) =>
								acc +
								sd.data.reduce(
									(acc2, current2) =>
										acc2 + (current2[1] === null ? 0 : current2[1]),
									0
								),
							0
						) > 0

					if (isComponentRunning === true) {
						if (sensorPair.find((a) => a[0] === sensorId) !== undefined) {
							sensorData.forEach((sd) =>
								sd.data.forEach((dp) => {
									if (dp[1] !== null && dp[1] > maxFuelTemp) {
										maxFuelTemp = dp[1]
									}
								})
							)
							sensorData.forEach((sd) =>
								sd.data.forEach((dp) => {
									if (dp[1] !== null && dp[1] < minFuelTemp) {
										minFuelTemp = dp[1]
									}
								})
							)
						} else {
							sensorData.forEach((sd) =>
								sd.data.forEach((dp) => {
									if (dp[1] !== null && dp[1] < minProducerLoadTemp) {
										minProducerLoadTemp = dp[1] < 0 ? 0 : dp[1]
									}
								})
							)
						}

						setFuelLinePlotOptions({
							...fuelLinePlotOptions,
							plugins: {
								...fuelLinePlotOptions.plugins,
								legend: {
									display: true,
									labels: {
										filter: function (item, chart) {
											const itemTextSplit_1 = item.text.split(" ")?.[1]
											if (
												itemTextSplit_1 !== undefined &&
												itemTextSplit_1.includes("Fuel")
											) {
												return false
											}

											return true // Return true to show label
										},
									},
									position: "bottom",
								},
								bgColor:
									showOpModeBg === true && eventData.length > 1
										? {
												colorMap: modeColors,
												ranges: bgColorRange,
											}
										: {},
							},
						})

						const flattnedValues = sensorData
							.map((sd) => sd.data.map((dp) => dp[1]))
							.flat()

						if (
							common.sensorDataFromIds
								.get(sensorId)
								?.display_name.includes("Fuel") === false
								? (flattnedValues as number[]).reduce(
										(acc, val) => (val > acc ? val : acc),
										-Infinity
									) > THRESHOLD_POWER
									? true
									: false
								: true
						) {
							tempFuelPlotData.labels = sensorData
								.map((sd) => sd.data.map((dp) => dp[0]))
								.flat()

							tempFuelPlotData.datasets.push({
								label:
									common.sensorDataFromIds.get(sensorId)?.display_name ??
									"unknown sensor",
								data: sensorData
									.map((sd) => sd.data.map((dp) => dp[1]))
									.flat()
									.map((d) => (d !== null && d < THRESHOLD_POWER ? 0 : d)),
								borderColor: COLORS_PLOTS_COLORBLIND[fuelConsumerColorIndex],
								backgroundColor: COLORS_PLOTS_COLORBLIND[fuelConsumerColorIndex],
								borderWidth: 1,
								showLine: true,
								animation: false, //helps with rendering performance
								pointRadius: 0, //helps with rendering performance
								spanGaps: true, //helps with rendering performance
								hidden:
									(common.sensorDataFromIds
										.get(sensorId)
										?.display_name.includes("Fuel") ?? false)
										? true
										: false,
							})

							fuelConsumerColorIndex += 1
						} else {
							if (
								common.sensorDataFromIds
									.get(sensorId)
									?.display_name.includes("Power") === true
							) {
								tempFuelPlotData.datasets.push({
									label: `${
										common.sensorDataFromIds
											.get(sensorId)
											?.display_name.split(" Power")[0]
									}: not running`,
									data: sensorData
										.map((sd) => sd.data.map((dp) => dp[1]))
										.flat()
										.map((d) => null),
									borderColor: "#aaa",
									backgroundColor: "#aaa",
									borderWidth: 1,
									showLine: true,
									animation: false, //helps with rendering performance
									pointRadius: 0, //helps with rendering performance
									spanGaps: true, //helps with rendering performance
								})
							}
						}
					} else {
						if (
							common.sensorDataFromIds
								.get(sensorId)
								?.display_name.includes("Power") === true
						) {
							tempFuelPlotData.datasets.push({
								label: `${
									common.sensorDataFromIds
										.get(sensorId)
										?.display_name.split(" Power")[0]
								}: not running`,
								data: sensorData
									.map((sd) => sd.data.map((dp) => dp[1]))
									.flat()
									.map((d) => null),
								borderColor: "#aaa",
								backgroundColor: "#aaa",
								borderWidth: 1,
								showLine: true,
								animation: false, //helps with rendering performance
								pointRadius: 0, //helps with rendering performance
								spanGaps: true, //helps with rendering performance
							})
						}
					}
				}
			}
		}

		setFuelLinePlotOptions({
			...fuelLinePlotOptions,
			plugins: {
				...fuelLinePlotOptions.plugins,
				legend: {
					display: true,
					labels: {
						generateLabels(chart) {
							const labelsOriginal =
								ChartJS.defaults.plugins.legend.labels.generateLabels(chart)

							if (labelsOriginal !== undefined) {
								labelsOriginal.forEach((label) => {
									if (label.text.includes("running") === true) {
										label.fontColor = "#bbb"
									}
								})
							}
							return labelsOriginal
						},
						filter: function (item, chart) {
							const itemTextSplit_1 = item.text.split(" ")?.[1]
							if (itemTextSplit_1 !== undefined && itemTextSplit_1.includes("Fuel")) {
								return false
							}
							return true // Return true to show label
						},
					},
					position: "bottom",
				},

				tooltip: {
					// animation: {
					// 	duration: 400, // ✅ Tooltip animation enabled
					// },
					mode: "index",
					intersect: false,
					callbacks: {
						label: function (context) {
							if (context === undefined) {
								return
							}

							const label = context.dataset.label
							if (label === undefined) {
								return
							}
							const shortLabel = label.split(" ")[0]
							// Get all matched sensor indexes
							const matchedSensorIndexes = context.chart.data.datasets.flatMap(
								(dataset, index) =>
									dataset.label?.split(" ")[0] === shortLabel ? index : []
							)
							const labelSplit_1 = label.split(" ")?.[1]
							if (labelSplit_1 !== undefined && labelSplit_1.includes("Fuel")) {
								return ""
							}

							// Prepare for accumulating all fuel rates
							let fuelRatesCombined = "/ Fuel rate"

							// Iterate over all matched indexes to combine fuel rates
							matchedSensorIndexes.forEach((matchedIndex) => {
								const dataSet = context.chart.data.datasets[matchedIndex]
								if (dataSet !== undefined) {
									const otherValue = dataSet.data[context.dataIndex]
									const label = dataSet.label ?? ""
									if (label.includes("Fuel")) {
										const tempLabel = dataSet.label?.split(" ")
										const otherValue = dataSet.data[context.dataIndex]
										const tempLabelParts = dataSet.label?.split(" ")
										const tempLabel2 = tempLabelParts?.slice(3)

										const fuelRate = `${tempLabel2?.reduce(
											(prev, curr) => `${prev} ${curr}`,
											""
										)}: ${
											Number.isNaN(otherValue) || otherValue == null
												? otherValue
												: (otherValue as number).toFixed(1)
										} L/h  `

										// Append this fuel rate to the combined string
										fuelRatesCombined += `${fuelRate}`
									}
								}
							})

							// Return the combined tooltip with load and all fuel rates
							return `${label.split(" ")[0]} Load: ${context.parsed.y.toFixed(
								1
							)} % ${fuelRatesCombined}`
						},
					},
				},
				bgColor:
					showOpModeBg === true && eventData.length > 1
						? {
								colorMap: modeColors,
								ranges: bgColorRange,
							}
						: {},
			},
		})

		let baselineColorIndex = 0

		if (baselineData?.samples[0] !== undefined) {
			for (const id of [-1, -2]) {
				const sensorData: SensorData[] = []
				for (const tsd of timeseriesDataUniform.sensors) {
					if (tsd.id === id) {
						sensorData.push(tsd)
					}
				}
				if (sensorData.length > 0) {
					const isComponentRunning =
						sensorData.reduce(
							(acc, sd) =>
								acc +
								sd.data.reduce(
									(acc2, current2) =>
										acc2 + (current2[1] === null ? 0 : current2[1]),
									0
								),
							0
						) >= 0

					if (isComponentRunning === true) {
						sensorData.forEach((sd) =>
							sd.data.forEach((dp) => {
								if (dp[1] !== null && dp[1] < minBaselineTemp) {
									minBaselineTemp = dp[1]
								}
							})
						)

						if (eventData.length > 1) {
							setBaselinePlotOptions({
								...baselinePlotOptions,
								plugins: {
									...baselinePlotOptions.plugins,
									bgColor:
										showOpModeBg === true
											? {
													colorMap: modeColors,
													ranges: bgColorRange,
												}
											: {},
								},
							})
						}
						let tempName = "unknown sensor"

						if (id === baselineFuelRateIds[0]) {
							tempName = "Total Engine Fuel Rate"
						}
						if (id === baselineFuelRateIds[1]) {
							tempName = "Baseline Fuel Rate"
						}

						tempBaselinePlotData.labels = sensorData
							.map((sd) => sd.data.map((dp) => dp[0]))
							.flat()

						tempBaselinePlotData.datasets.push({
							label: tempName,
							data: sensorData
								.map((sd) => sd.data.map((dp) => dp[1]))
								.flat()
								.map((d) => d),
							borderColor: COLORS_PLOTS_COLORBLIND[baselineColorIndex],
							backgroundColor: `${COLORS_PLOTS_COLORBLIND[baselineColorIndex]}`,
							borderWidth: 1,
							showLine: true,
							animation: false, //helps with rendering performance
							pointRadius: 0, //helps with rendering performance
							spanGaps: true, //helps with rendering performance
							yAxisID: "y",
						})
						baselineColorIndex += 1
					}
				}
			}
		}

		if (baselineData?.samples[0] !== undefined) {
			for (const id of [-4]) {
				const sensorData: SensorData[] = []
				for (const tsd of timeseriesDataUniform.sensors) {
					if (tsd.id === id) {
						sensorData.push(tsd)
					}
				}

				if (sensorData.length > 0) {
					const values = sensorData.map((a) => a.data.map((b) => b[1]))

					const values2: number[] = values.reduce<number[]>(
						(acc, cur) => [
							...acc,
							...cur.filter((number): number is number => number !== null),
						],
						[]
					)

					function roundToClosest10(num: number, isMin?: boolean): number {
						if (isMin == true) return Math.floor(num / 10) * 10
						return Math.ceil(num / 10) * 10
					}

					const min =
						roundToClosest10(Math.min(...values2), true) < -100
							? -100
							: roundToClosest10(Math.min(...values2), true)

					const max =
						roundToClosest10(Math.max(...values2)) > 100
							? 100
							: roundToClosest10(Math.max(...values2) + 0.1)

					setBaselinePlotOptions({
						...baselinePlotOptions,
						scales: {
							...baselinePlotOptions.scales,
							y1: {
								min: min,
								max: max,
								title: {
									display: true,
									text: "Deviation (%)",
								},
								grid: { display: false },
							},
						},
						plugins: {
							...baselinePlotOptions.plugins,
							bgColor:
								showOpModeBg === true && eventData.length > 1
									? {
											colorMap: modeColors,
											ranges: bgColorRange,
										}
									: {},
						},
					})

					let tempName = "unknown sensor"

					if (id === baselineFuelRateIds[2]) {
						tempName = "Deviation"
					}

					const events = eventData.map((a) => a.event)
					let baselineSelectorColor = `#166534`

					const baselines = events.map((a) => {
						const baseline =
							(100 * a.mainEngineFuelM3) /
								(a.mainEngineFuelM3Baseline === null
									? 0
									: a.mainEngineFuelM3Baseline) -
							100
						const duration = new Date(a.t2).getTime() - new Date(a.t1).getTime()
						return { baseline, duration }
					})

					const totalWeightedBaseline = baselines.reduce(
						(acc, cur) => acc + cur.baseline * cur.duration,
						0
					)
					const totalDuration = baselines.reduce((acc, cur) => acc + cur.duration, 0)
					const totalEventBaseline =
						totalDuration !== undefined ? totalWeightedBaseline / totalDuration : 0

					if (totalEventBaseline > 0) {
						baselineSelectorColor = `#f7b443`
					}
					if (totalEventBaseline > 5) {
						baselineSelectorColor = `#ef4444`
					}

					if (sensorData.length > 0) {
						tempBaselinePlotData.labels = sensorData
							.map((sd) => sd.data.map((dp) => dp[0]))
							.flat()

						tempBaselinePlotData.datasets.push({
							label: tempName,
							data: sensorData
								.map((sd) => sd.data.map((dp) => dp[1]))
								.flat()
								.map((d) => d),
							borderColor: baselineSelectorColor,
							backgroundColor: baselineSelectorColor,
							borderWidth: 1,
							showLine: false,
							animation: false, //helps with rendering performance
							pointRadius: 0, //helps with rendering performance
							spanGaps: true, //helps with rendering performance
							fill: {
								target: { value: 0 },
								below: `#81bf44BB`,
								above: `#f7844388`,
							},
							yAxisID: "y1",
						})
						baselineColorIndex += 1
					}
				}
			}
		}

		const compassWeatherDataTemp: CompassTimeseriesData = []
		const weatherSensorsId = eventData?.[0]?.metadata.weatherSensorsId

		if (weatherSensorsId !== undefined) {
			for (const [index, sensorId] of weatherSensorsId.entries()) {
				const sensorData: SensorData[] = []
				for (const tsd of timeseriesWeatherData.sensors) {
					if (tsd.id === sensorId) {
						sensorData.push(tsd)
					}
				}
				if (sensorData.length > 0) {
					compassWeatherDataTemp.push({
						sensorId: sensorId,
						data: sensorData
							.map((sd) =>
								sd.data.map((dp) => {
									return {
										timestamp: dp[0],
										value: dp[1] === null ? 0 : dp[1],
									}
								})
							)
							.flat(),
					})
				}
			}
		}

		setConditionLinePlotOptions(conditionLinePlotOptionsTemp)
		setConditionLinePlotData(tempConditionPlotData)
		setMinLoad(Math.floor(minLoadTemp > 100000000 ? 950 : minLoadTemp * 0.95))
		setMinBaseline(Math.floor(minBaselineTemp * 0.95))
		setLoadLinePlotData(tempLoadPlotData)
		setMinFuel(Math.floor(minProducerLoadTemp * 0.95))
		setFuelLinePlotData(tempFuelPlotData)
		setBaselinePlotData(tempBaselinePlotData)

		setCompasWeatherData(compassWeatherDataTemp)
	}, [timeseriesDataWithBaseline, opModesList, timeseriesWeatherData, showOpModeBg])

	const [isPlotConfigOpen, setIsPlotConfigOpen] = useState<boolean>(false)
	const [plotsData, setPlotsData] = useState<PlantPlot[]>([])
	// const [plotsDataNoReRender, setPlotsDataNoReRender] = useState<PlantPlot[]>([])

	const createPlotsData = async () => {
		const opModeId = eventData?.[0]?.metadata.opModeId
		if (opModeId === undefined) {
			return
		}
		const abortController = new AbortController()
		try {
			const response = await requestApi2(
				endpoints.fuelPageGetOpModePlots,
				{
					opModeId: opModeId,
				},
				abortController.signal
			)
			if (response === null) {
				throw new Error()
			}
			setPlotsData(response)
			// setPlotsDataNoReRender(response)
		} catch (err) {
			if (!abortController.signal.aborted) {
				throw err
			}
		}
	}

	useEffect(() => {
		createPlotsData()
	}, [])

	const defaultPlots = async () => {
		for (const plot of plotsData) {
			try {
				await requestApi2(endpoints.plantPlotRemove, { id: plot.id })
			} catch (error) {}
		}
		setPlotsData([])
	}

	const handleAddPlotOpenToggle = () => {
		togglePlotConfig()
	}
	const togglePlotConfig = () => {
		setIsPlotConfigOpen((prev) => !prev)
	}

	const addPlot = async (newPlotData: PlantPlot) => {
		const plotsDataCopy: PlantPlot[] = JSON.parse(JSON.stringify(plotsData))
		const idx = plotsDataCopy.findIndex((plotData) => plotData === newPlotData)
		const opModeId = eventData?.[0]?.metadata.opModeId
		if (opModeId === undefined) {
			return
		}
		if (idx === -1) {
			// check if plot is new
			const plotsDataTemp: PlantPlot[] = JSON.parse(JSON.stringify(plotsData))

			try {
				const response = await requestApi2(endpoints.fuelPageAddOpModePlot, {
					opModeId: opModeId,
					plotName:
						newPlotData.name !== null
							? newPlotData.name
							: newPlotData.signals?.[0]?.sensorId !== undefined
								? (common.sensorsById.get(newPlotData.signals?.[0]?.sensorId)?.[0]
										?.display_name ?? "Unnamed plot")
								: "Unnamed plot",
					signals: newPlotData.signals,
				})
				if (response === null) {
					throw new Error()
				}
				newPlotData.id = response.id
				// fetchPlantPlots()
				// handleConfigOpen()
			} catch (err) {
				console.log(err)
			}
			plotsDataTemp.unshift(newPlotData)
			// setPlotsDataNoReRender(plotsDataTemp)
			setPlotsData(plotsDataTemp)
		}
	}

	const handleDeletePlot = async (inPlotData: PlantPlot) => {
		if (plotsData !== undefined) {
			const plotsDataCopy: PlantPlot[] = JSON.parse(JSON.stringify(plotsData))
			const newPlotsData = plotsDataCopy.filter((plotData) => plotData.id !== inPlotData.id)
			// setPlotsDataCurrent(newPlotsData)

			await requestApi2(endpoints.plantPlotRemove, {
				id: inPlotData.id,
			})
			// fetchPlantPlots()
			// handleConfigOpen()

			// setPlotsDataNoReRender(newPlotsData)
			setPlotsData(newPlotsData)
		}
	}

	const [plotOrder, setPlotOrder] = useState([1, 3, 5, 6])

	useEffect(() => {
		const newOrder: number[] = []
		const plotOrder_0 = plotOrder[0]
		const plotOrder_1 = plotOrder[1]
		const plotOrder_2 = plotOrder[2]
		const plotOrder_3 = plotOrder[3]

		// Check each plot's availability and adjust the order accordingly
		if (
			conditionLinePlotData !== undefined &&
			conditionLinePlotData.datasets.length > 0 &&
			plotOrder_0 !== undefined
		) {
			newOrder.push(1) // conPlot is 1
		}
		if (
			fuelLinePlotData !== undefined &&
			fuelLinePlotData.datasets.length > 0 &&
			plotOrder_1 !== undefined
		) {
			newOrder.push(3) // fuelPlot is 3
		}
		if (
			baselinePlotData !== undefined &&
			baselinePlotData.datasets.length > 0 &&
			plotOrder_2 !== undefined
		) {
			newOrder.push(5) // basePlot is 5
		}
		if (
			loadLinePlotData !== undefined &&
			loadLinePlotData.datasets.length > 0 &&
			plotOrder_3 !== undefined
		) {
			newOrder.push(6) // loadPlot is 6
		}

		// For each unavailable plot, add it to the end of the newOrder array
		if (
			!(conditionLinePlotData !== undefined && conditionLinePlotData.datasets.length > 0) &&
			plotOrder_0 !== undefined
		)
			newOrder.push(1)
		if (
			!(fuelLinePlotData !== undefined && fuelLinePlotData.datasets.length > 0) &&
			plotOrder_1 !== undefined
		)
			newOrder.push(3)
		if (
			!(baselinePlotData !== undefined && baselinePlotData.datasets.length > 0) &&
			plotOrder_2 !== undefined
		)
			newOrder.push(5)
		if (
			!(loadLinePlotData !== undefined && loadLinePlotData.datasets.length > 0) &&
			plotOrder_3 !== undefined
		)
			newOrder.push(6)

		setPlotOrder(newOrder)
	}, [conditionLinePlotData, fuelLinePlotData, baselinePlotData, loadLinePlotData])

	const [layersData, setLayersData] = useState<LayerData[]>()
	const [vesselData, setVesselData] = useState<PlantInfo[]>()

	const [timelineEventMarker, setTimelineEventMarker] = useState<TimelineEventMarker>({
		heading: undefined,
		latestOperationMode: undefined,
		coords: undefined,
	})

	const {
		isLoading: isFacilitiesWithRadiusDataLoading,
		isError: isFacilitiesWithRadiusDataError,
		data: facilitiesWithRadiusData,
	} = useGetFacilitiesRadiusQuery({
		isFacilitiesFeatureAvailable: isFeatureAvailable(
			undefined,
			PortalFeature.FACILITIES,
			currentCompany ?? "unkown company",
			devFeatures,
			userData.portalMode
		),
		types: (
			[
				"FISH_FARM",
				"PORT",
				"PLATFORM",
				"DISCHARGE_TERMINAL",
				"DISCHARGE_STS",
				"ANCHORAGE",
			] as GetAllFacilitiesArgs["types"]
		)
			.filter((f) =>
				isFeatureAvailable(
					undefined,
					PortalFeature.FISH_FARM,
					currentCompany ?? "unknown",
					devFeatures,
					userData.portalMode
				) === false
					? f !== "FISH_FARM"
					: true
			)
			.filter((f) =>
				isFeatureAvailable(
					undefined,
					PortalFeature.OIL_AND_GAS,
					currentCompany ?? "unknown",
					devFeatures,
					userData.portalMode
				) === false
					? f !== "PLATFORM" &&
						f !== "DISCHARGE_TERMINAL" &&
						f !== "DISCHARGE_STS" &&
						f !== "ANCHORAGE"
					: true
			),
	})
	const mapFilterFacilities = useAppSelector(getMapFiltersFacilities).facilities

	useEffect(() => {
		if (facilitiesWithRadiusData === null || facilitiesWithRadiusData === undefined) {
			return
		}
		const facilitiesTypeToInclude: FacilityType[] = []
		Object.entries(mapFilterFacilities).forEach(
			([key, value]) =>
				value === true &&
				(key.toLowerCase().includes("port") === true
					? facilitiesTypeToInclude.push("PORT")
					: key.toLowerCase().includes("fish") === true
						? facilitiesTypeToInclude.push("FISH_FARM")
						: key.toLowerCase().includes("platform") === true
							? facilitiesTypeToInclude.push("PLATFORM")
							: key.toLowerCase().includes("dischargeterminals") === true
								? facilitiesTypeToInclude.push("DISCHARGE_TERMINAL")
								: key.toLowerCase().includes("dischargests") === true
									? facilitiesTypeToInclude.push("DISCHARGE_STS")
									: key.toLowerCase().includes("anchorage") === true
										? facilitiesTypeToInclude.push("ANCHORAGE")
										: null)
		)
		const filteredFacilitiesWithRadiusData = facilitiesWithRadiusData.filter((f) =>
			facilitiesTypeToInclude.includes(f.type)
		)
		dispatch(setFacilities(filteredFacilitiesWithRadiusData))
	}, [facilitiesWithRadiusData, mapFilterFacilities])

	const [
		textFilteredFishGeoJson,
		mapParametersFilteredFishGeoJson,
		isFishFarmDataLoading,
		handleSetTextFilteredFishGeoJson,
	] = useGetFishFarmDataForMap(null)
	const diseaseZonesData = useAppSelector(getDiseaseZones)
	const selectedDiseaseZones = useAppSelector(getSelectedDiseaseZones)
	const [oilAndGasfacilitiesData, fieldsData] = useGetOilGasDataForMap()

	const { zoom } = useAppSelector(getMapParamsState)

	useEffect(() => {
		if (vesselTrack === undefined || opModesList === undefined) {
			return
		}
		const points = vesselTrack?.locations
		if (points === undefined) {
			return
		}
		const lastPoint = points[points.length - 1]
		if (lastPoint === undefined) {
			return
		}
		const vesselDataTemp: PlantInfo[] = [
			{
				data: "",
				position: {
					cog: lastPoint.course,
					heading: lastPoint.heading ?? lastPoint.course,
					sog: lastPoint.speed,
					lat: lastPoint.latitude,
					lon: lastPoint.longitude,
					rot: 0,
					navstat: 0,
					srid: 0,
					timeStamp: lastPoint?.timestamp.toISOString() ?? "",
				},
				plantId: plantData?.id ?? -1,
				condition: "green",
				name: paramSelectedVessel ?? "unkown",
				owner: currentCompany ?? "unknown",
			},
		]

		const segments: Segment[] = eventData.map((e) => {
			const color = combinedModeMetaData?.modeColors[
				findOpModeName(e.metadata.opModeId)
					?.split(combinedModeMetaData.splitString)?.[1]
					?.toLowerCase() ?? "unknown"
			] as string
			return {
				timestamp: e.event.t2.toString(),
				color: `${color?.split("0.")?.[0]}1)`,
			}
		})

		const layersDataTemp: LayerData[] = [
			{
				mapLayerName: MapLayers.FACILITIES_RADIUS,
				facilitiesRadius: {
					company: appStructure !== null ? (Object.keys(appStructure)[0] ?? "") : "",
					facilities:
						facilitiesData !== null && facilitiesData !== undefined
							? facilitiesData
							: [],
				},
				portalFeature: PortalFeature.FACILITIES,
			},
			{
				mapLayerName: MapLayers.VESSELS_NOT_CLICKABLE,
				vesselsNotClickableData: { vessels: vesselDataTemp, zoom: zoom?.zoomLevel },
			},
			{
				mapLayerName: MapLayers.VESSELS_TRACK_SEGMENTS,
				vesselsTrackSegmentsData: {
					intervalPoints: vesselTrack?.locations ?? [],
					operationModeEvents: {
						segments: segments.length > 1 ? segments : undefined,
					},
					zoom: zoom?.zoomLevel,
				},
				portalFeature: PortalFeature.VESSELS_TRACK,
			},
			{
				mapLayerName: MapLayers.TIMELINE_EVENT_MARKER,
				timelineEventMarkerData: timelineEventMarker,
			},
			// {
			// 	mapLayerName: MapLayers.OIL_AND_GAS,
			// 	oilAndGasData: getFacilityFixed(),
			// 	portalFeature: PortalFeature.OIL_AND_GAS,
			// },
			{
				mapLayerName: MapLayers.OIL_AND_GAS_FIELDS,
				oilAndGasFieldsData: fieldsData,
				portalFeature: PortalFeature.OIL_AND_GAS,
			},
			{
				mapLayerName: MapLayers.FISH_FARMS,
				fishFarmsData: textFilteredFishGeoJson,
				portalFeature: PortalFeature.FISH_FARM,
			},
			{
				mapLayerName: MapLayers.DESEASE_ZONES,
				deseaseZonesData: { diseaseZonesData, selectedDiseaseZones },
				portalFeature: PortalFeature.FISH_FARM,
			},
		]
		setLayersData(layersDataTemp)
		setVesselData(vesselDataTemp)
	}, [
		zoom,
		vesselTrack,
		fieldsData,
		timelineEventMarker,
		facilitiesData,
		textFilteredFishGeoJson,
		selectedDiseaseZones,
	])

	const opModesOrderRef = [28, 29, 30, 31]

	// const contextValue: SensorsGroupContextProps = useMemo(() => {
	// 	return {
	// 		plotRanges:
	// 			plotsData?.map((plotData) => {
	// 				return {
	// 					plotId: plotData.id,
	// 					range: TimeseriesDataRange.Custom,
	// 					from:
	// 						listOfTimestampsSorted?.[0] !== undefined
	// 							? new Date(listOfTimestampsSorted?.[0])
	// 							: undefined,

	// 					to:
	// 						listOfTimestampsSorted?.[listOfTimestampsSorted.length - 1] !==
	// 						undefined
	// 							? new Date(
	// 									listOfTimestampsSorted?.[listOfTimestampsSorted.length - 1]!
	// 							  )
	// 							: undefined,
	// 				}
	// 			}) ?? [],
	// 		plotZoom: [],
	// 		setPlotZoom,
	// 		triggerIndividualRangeRerender: Math.random(),
	// 	}
	// }, [plotsData])

	useEffect(() => {
		if (updateChart === true && lastZoomScale !== undefined) {
			updateChartZoom(conditionChartRef, lastZoomScale)
			updateChartZoom(fuelChartRef, lastZoomScale)
			updateChartZoom(baselineChartRef, lastZoomScale)
			updateChartZoom(loadChartRef, lastZoomScale)
			setUpdateChart(false)
		}
	}, [updateChart, lastZoomScale])

	return (
		// <SensorsGroupContext.Provider value={contextValue}>
		<div
			className={`relative h-auto w-full border-b-1 border-b-gray-300 ${
				isVesselTrackLoading === false &&
				isTimeseriesDataLoading === false &&
				isTimeseriesWeatherDataLoading === false
					? `py-4 pr-4 pl-10`
					: ``
			}`}
		>
			{isVesselTrackLoading === false &&
			isTimeseriesDataLoading === false &&
			isTimeseriesWeatherDataLoading === false ? (
				<>
					<div className={`flex flex-col`}>
						{eventData.length > 1 && opModesList !== undefined ? (
							<div className={`mb-2 flex h-8 w-full flex-row items-center gap-4`}>
								<p>{`${t(
									`${
										combinedModeMetaData?.combinedModeName.toUpperCase() ?? ""
									} MODES`
								)}:`}</p>
								<div className={`flex flex-row items-center gap-4`}>
									{opModeColorsMap
										.sort(
											(a, b) =>
												opModesOrderRef.indexOf(a) -
												opModesOrderRef.indexOf(b)
										)
										.map((opModeId, index) => (
											<div
												key={opModeId}
												className={`flex flex-row items-center gap-2`}
											>
												<div
													className={`h-4 w-10`}
													style={{
														backgroundColor:
															modeColors[
																findOpModeName(
																	opModeId
																)?.toLowerCase() ?? "(na)"
															],
													}}
												/>
												<p>
													{findOpModeName(opModeId)?.split(
														combinedModeMetaData?.splitString ??
															"don't split"
													)?.[1] ?? "unrecognized mode"}
												</p>
											</div>
										))}
								</div>
								<div className={`flex items-center gap-2`}>
									<TooltipButton
										customContent={t(
											showOpModeBg === true
												? "HIDE OPERATION MODES COLORS"
												: "SHOW OPERATION MODES COLORS"
										)}
									>
										<IconButton
											variant={"text-dark"}
											className={"h-7 w-7"}
											onClick={() =>
												setShowOpModeBg !== undefined &&
												setShowOpModeBg((prev) =>
													prev !== undefined ? !prev : undefined
												)
											}
										>
											<IconButton.Icon>
												{showOpModeBg === true
													? MdVisibility
													: MdVisibilityOff}
											</IconButton.Icon>
										</IconButton>
									</TooltipButton>
								</div>
							</div>
						) : null}
						<div className={`w-full min-w-[550px] gap-5`}>
							{baselineData !== null && timeseriesDataWithBaseline !== undefined ? (
								<div className={`mb-5 grid grid-cols-1 gap-5 2xl:grid-cols-2`}>
									{/* Condition Line Plot */}
									<div className={`order-1 2xl:order-${plotOrder[0]}`}>
										<p className="mb-5">{t("VESSEL OPERATION TIMELINE")}</p>
										<div className="h-[284px]">
											<ChartContainer
												chartRef={conditionChartRef}
												options={conditionLinePlotOptions}
												plotData={conditionLinePlotData}
												heightExpression={`calc(250px + 34px)`}
												// heightExpression={`calc(${
												// 	nVesselPlots <= 2 ? `588px` : `250px + 34px`
												// })`}
											/>
										</div>
									</div>
									{/* Map */}
									<div className="z-9 order-5 2xl:order-2">
										<p className="mb-5">{t("ROUTE TIMELINE")}</p>
										{currentCompany !== undefined &&
										timeseriesDataWithBaseline !== undefined &&
										layersData !== undefined &&
										vesselData !== undefined ? (
											<div
												className={`relative overflow-hidden rounded-sm`}
												style={{
													height: `284px`,
												}}
											>
												<RegularMapComponent3
													customerName={currentCompany}
													layersData={layersData}
													mapDivId={`mapDiv_${eventData?.[0]?.event.t1}_${eventData?.[0]?.event.t2}`}
													inputsForBoundCalc={{
														shipData: vesselData,
														trackData: layersData
															.find(
																(ld) =>
																	ld.mapLayerName ===
																	MapLayers.VESSELS_TRACK_SEGMENTS
															)
															?.vesselsTrackSegmentsData?.intervalPoints?.map(
																(p) => {
																	return {
																		type: "Feature" as const,
																		properties: {},
																		geometry: {
																			type: "Point" as const,
																			coordinates: [
																				p.longitude,
																				p.latitude,
																			],
																		},
																	}
																}
															),
													}}
												/>
											</div>
										) : null}
									</div>
									{/* Consumers */}
									<div
										className={`order-2 max-h-[284px] 2xl:order-${plotOrder[1]}`}
									>
										<ChartContainer
											chartRef={fuelChartRef}
											options={fuelLinePlotOptions}
											plotData={fuelLinePlotData}
											minY={minFuel}
											heightExpression={`calc(250px + 34px)`}
											// heightExpression={`calc(${
											// 	nVesselPlots <= 1 ? `584px` : `250px + 34px`
											// })`}
										/>
									</div>
									{/* Weather */}
									<div className={`order-6 max-h-[284px] 2xl:order-4`}>
										{listOfTimestampsSorted?.[0] !== undefined ? (
											<div className="item-center flex flex-row justify-evenly rounded-sm border-1">
												<CompassWind
													width={250}
													t1={listOfTimestampsSorted?.[0]}
													time={time}
													data={compassWeatherData}
													heading={
														timelineEventMarker.heading ??
														layersData?.find(
															(ld) =>
																ld.mapLayerName ===
																MapLayers.VESSELS_NOT_CLICKABLE
														)?.vesselsNlData?.vesselInfo?.[0]?.position
															?.heading
													}
												/>
												<CompassCurrent
													width={350}
													t1={listOfTimestampsSorted?.[0]}
													time={time}
													data={compassWeatherData}
													heading={
														timelineEventMarker.heading ??
														layersData?.find(
															(ld) =>
																ld.mapLayerName ===
																MapLayers.VESSELS_NOT_CLICKABLE
														)?.vesselsNlData?.vesselInfo?.[0]?.position
															?.heading
													}
												/>
											</div>
										) : null}
									</div>
									{/* Fuel */}
									<div
										className={`order-3 max-h-[284px] 2xl:order-${plotOrder[2]}`}
									>
										<ChartContainer
											chartRef={baselineChartRef}
											options={baselinePlotOptions}
											plotData={baselinePlotData}
											minY={minBaseline}
											heightExpression={`calc(250px + 34px)`}
										/>
									</div>
									{/* Thrusters */}
									<div
										className={`order-4 max-h-[284px] 2xl:order-${plotOrder[3]}`}
									>
										<ChartContainer
											chartRef={loadChartRef}
											options={loadLinePlotOptions}
											plotData={loadLinePlotData}
											minY={minLoad}
											heightExpression={`calc(250px + 34px)`}
										/>
									</div>
								</div>
							) : (
								<div className="text-menu mb-4 flex h-8 w-full flex-row items-center gap-2 rounded-sm border-1 border-red-300 bg-red-50 px-2 font-medium text-red-700">
									<ImportantIcon
										bgColor="text-red-300"
										iconColor="text-red-700"
									/>
									{`There was an error getting data for this vessel and/or operation.`}
								</div>
							)}
						</div>
					</div>
					<div className="flex w-auto flex-row items-center justify-start gap-4 py-3">
						<p>{t("ANALYSIS")}</p>
						<Button
							size={"medium"}
							variant={"outline-dark"}
							className={`w-auto min-w-[120px] px-2`}
							onClick={defaultPlots}
						>
							<Button.Label>{t("RESET PLOTS")}</Button.Label>
						</Button>
						<Button
							size={"medium"}
							variant={"filled-secondary"}
							className={`w-auto min-w-[120px] px-2`}
							onClick={handleAddPlotOpenToggle}
						>
							<Button.Label>{t("ADD PLOT")}</Button.Label>
						</Button>
					</div>
					{plotsData !== undefined && currentCompany !== undefined ? (
						plotsData.map((plotData, index) => (
							<PlotCard
								key={`plotCard_${index}`}
								fetchPlantPlots={createPlotsData}
								companyName={currentCompany}
								plantName={paramSelectedVessel}
								componentName={"All Assets"}
								plotData={plotData}
								width={"calc(100% - 0px)"}
								margin={"0px"}
								hideRangeSelector={true}
								listOfModeColors={modeColors}
								from={new Date(eventData_last.event.t1)}
								to={new Date(eventData_0.event.t2)}
							/>
						))
					) : (
						<div className="text-menu grid h-auto w-full grid-cols-[24px_auto] items-center gap-3 rounded-sm border-1 border-red-300 bg-red-50 px-3 py-1 font-medium text-red-700">
							<ImportantIcon
								bgColor="text-red-300"
								iconColor="text-red-700"
							/>
							{`An error occured server-side when retrieving the data for this plot.
								Please contact Dipai support.`}
						</div>
					)}
				</>
			) : (
				<div className={`absolute -top-[42px] left-[21px]`}>
					<LoadingCircleSimples
						size={32}
						textColorTailwind="text-dipai-secondary-500"
					/>
				</div>
			)}
			<Modal
				isOpen={isPlotConfigOpen}
				setIsOpen={setIsPlotConfigOpen}
			>
				{currentCompany !== undefined ? (
					<PlotConfig
						setPlotData={addPlot}
						plotData={{ name: null, id: -1, signals: [] }}
						handleConfigOpen={togglePlotConfig}
						companyName={currentCompany}
						plantName={paramSelectedVessel}
						componentName={"All Assets"}
						addNewPlot={true}
						configType={"Plot"}
						emptyLineTemplate={{
							sensorId: -1,
							aggregationMethod: AggregationMethod.SNAP_FIRST,
							baselines: [],
							id: Math.random(),
							thresholds: [],
							color: "",
						}}
						columnsLayout={`24px auto 250px 136px 48px`}
						numberSignals={6}
					/>
				) : (
					<div className="text-menu grid h-auto w-full grid-cols-[24px_auto] items-center gap-3 rounded-sm border-1 border-red-300 bg-red-50 px-3 py-1 font-medium text-red-700">
						<ImportantIcon
							bgColor="text-red-300"
							iconColor="text-red-700"
						/>
						{`An error occured server-side when retrieving the data for this plot.
								Please contact Dipai support.`}
					</div>
				)}
			</Modal>
		</div>
		// </SensorsGroupContext.Provider>
	)
}

export default function OperationsTimelineExpandedTableArea({
	row,
}: {
	row: Row<FuelConsumptionTimelineTableItem>
}) {
	const eventData = row
		.getAllCells()
		.find((c) => c.id.split("_")[1] === "eventData")
		?.getValue() as EventTableEntry[]

	const eventData_0 = eventData?.[0]
	const eventData_last = eventData?.[eventData.length > 0 ? eventData.length - 1 : 0]
	if (eventData === undefined || eventData_0 === undefined || eventData_last === undefined) {
		return (
			<div className={`p-4`}>
				<p className={`text-body`}> No data available to display.</p>
			</div>
		)
	}
	return (
		<ComponentBody
			eventData={eventData}
			eventData_last={eventData_last}
			eventData_0={eventData_0}
		/>
	)
}
