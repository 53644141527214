export const getFileName = (): string => {
	// This error stack manipulation is used to extract
	// the file name from where this function was called
	const prepareStackTraceOrg = Error.prepareStackTrace
	const err = new Error()
	Error.prepareStackTrace = (_, stack) => stack

	const stack = err.stack as unknown as NodeJS.CallSite[]

	Error.prepareStackTrace = prepareStackTraceOrg

	let __fileUrl: string | null | undefined
	if (typeof stack === "string") {
		__fileUrl = (stack as string).split("\n")?.[2]?.split("@")?.[0]
	} else {
		if (stack?.[2]?.getFileName !== undefined) {
			__fileUrl = stack?.[2]?.getFileName()
		} else {
			__fileUrl = undefined
		}
	}

	if (__fileUrl === null || __fileUrl === undefined) {
		return "unknown_file"
	}
	const splitBySlash = __fileUrl.split("?")?.[0]?.split("/")
	return splitBySlash !== undefined
		? splitBySlash[splitBySlash.length - 1]?.split(".")?.[0] ?? "unknown_file"
		: "unknown_file"
}
