import Card from "@components/clientCard/Card"
import { getCompanyLogo } from "@helpers/getCompanyLogo"
import { useAppDispatch } from "@redux/app/hooks"
import { setCurrentCompany } from "@redux/features/currentCompany/currentCompanySlice"
import { Link } from "@tanstack/react-router"
import { ReactElement } from "react"

type ClientCardProps = {
	client: string
	isAllAssets?: boolean
}
export function ClientCard({ client, isAllAssets = false }: ClientCardProps): ReactElement {
	const dispatch = useAppDispatch()

	return (
		<div
			className="inline-block h-[300px]"
			style={{ height: "250px" }}
			onClick={() => {
				dispatch(setCurrentCompany(client))
			}}
		>
			<Link to={`${client}${isAllAssets ? ":All" : ""}`}>
				{/* <Link to={`${replaceNorwegianVowels(client)}${isAllAssets ? ":All" : ""}`}> */}
				<Card width={"100%"} height={"100%"} padding={"0px"} isButton={true}>
					<div className="relative w-full h-full">
						{getCompanyLogo(client)}
						{isAllAssets && (
							<h4 className="absolute w-full text-center top-3/4">{"All Assets"}</h4>
						)}
					</div>
				</Card>
			</Link>
		</div>
	)
}
