import {
	getWindyMap,
	MapOptions,
	tryInitWindyMap,
	WindyMapType,
} from "@components/map/windyMap/windyMap"
import { AppDispatch } from "@redux/app/store"
import { useNavigate } from "@tanstack/react-router"
import { Dispatch, SetStateAction, useEffect } from "react"
import { QueryClient } from "react-query"

export default function useInitWindyMap(
	dispatch: AppDispatch,
	queryClient: QueryClient,
	setMap: Dispatch<SetStateAction<WindyMapType | undefined>>,
	map?: WindyMapType,
	navigate?: ReturnType<typeof useNavigate>,
	options?: MapOptions
) {
	const target = document.getElementById("windyHolder")
	useEffect(() => {
		const init = async () => {
			if (map === undefined) {
				await tryInitWindyMap(dispatch, queryClient, navigate, options)
				const temp = getWindyMap()
				if (temp !== undefined) {
					setMap(temp)
				}
			}
			getWindyMap()?.applyOptions(options)
		}
		if (target?.firstChild !== undefined) {
			init().catch(console.error)
		}
		return () => {
			getWindyMap()?.clearLayers()
			getWindyMap()?.clearOptions()
		}
	}, [target])
}
