export type monthslistType =
	| "january"
	| "february"
	| "march"
	| "april"
	| "may"
	| "june"
	| "july"
	| "august"
	| "september"
	| "october"
	| "november"
	| "december"

export const monthsList = [
	"january",
	"february",
	"march",
	"april",
	"may",
	"june",
	"july",
	"august",
	"september",
	"october",
	"november",
	"december",
] as const

export const LABELS_MONTHS: [string, monthslistType, number][] = [
	["JAN", "january", 0],
	["FEB", "february", 1],
	["MAR", "march", 2],
	["APR", "april", 3],
	["MAY", "may", 4],
	["JUN", "june", 5],
	["JUL", "july", 6],
	["AUG", "august", 7],
	["SEP", "september", 8],
	["OCT", "october", 9],
	["NOV", "november", 10],
	["DEC", "december", 11],
]

export function getLabelsMonths() {
	const monthRef = new Date().getMonth()
	const currentLabels = LABELS_MONTHS.slice(0, monthRef + 1)
	return currentLabels
		.reverse()
		.concat(LABELS_MONTHS.slice(monthRef + 1, 12 - monthRef + 1).reverse())
}
