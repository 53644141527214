import { removeIncompatibleSearchParameters } from "@helpers/removeIncompatibleSearchParameters"
import { historicalDataPageSearchSchema } from "@routes/clients.$clientName/_sopPageLayout/historical-data"
import { createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute("/_sopPageLayout/historical-data")({
	component: RouteComponent,
	validateSearch: historicalDataPageSearchSchema,
	search: {
		middlewares: [removeIncompatibleSearchParameters(historicalDataPageSearchSchema)],
	},
})

function RouteComponent() {
	return null
}
