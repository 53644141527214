import { toZodError } from "api"
import { Message, MessageDefinition } from "edge-admin-api"

export class MessageProcessor {
	readonly messageActions = new Map<string, (data: unknown) => void>()
	constructor() {}

	process(string: string) {
		const message = JSON.parse(string) as Message
		const action = this.messageActions.get(message.key)
		if (action == undefined) {
			throw new Error(`Unrecognized message type: ${message.key}`)
		}
		action(message.data)
	}

	addCallback<T>(definition: MessageDefinition<T>, fn: (data: T) => void) {
		this.messageActions.set(definition.name, (data) => {
			try {
				const parsed = definition.parser.parse(data)
				fn(parsed)
			} catch (err) {
				throw toZodError(err) ?? err
			}
		})
	}
}
