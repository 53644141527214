import { createShipIconNL } from "@components/map/mapComponents/shipIcon"
import { LatLng } from "leaflet"
import { DataSetter } from "../utils/mapLayers"

export const addVesselsNotClickable: DataSetter = (
	dispatch,
	queryClient,
	layerGroup,
	layerData,
	navigate,
	map
) => {
	if (layerGroup === undefined) return

	const { vesselsNotClickableData } = layerData
	if (vesselsNotClickableData === undefined) return

	const { vessels } = vesselsNotClickableData
	if (vessels.length === 0) return

	layerGroup.clearLayers()

	for (const ship of vessels) {
		if (
			ship?.position?.hasOwnProperty("lat") !== true ||
			ship?.position?.hasOwnProperty("lon") !== true
		) {
			continue
		}
		if (isNaN(ship.position.lat) === true || isNaN(ship.position.lon) == true) {
			continue
		}
		const shipCoord: LatLng = L.latLng(ship.position.lat, ship.position.lon)

		layerGroup.addLayer(
			L.marker(shipCoord, {
				icon: createShipIconNL(
					ship.position.heading,
					ship.condition,
					false,
					ship.position.lat,
					undefined, //main_dimensions_vessel_data[ship.name]?.LOA,
					undefined, //main_dimensions_vessel_data[ship.name]?.Breadth,
					map?.getZoom()
				),
				pane: "vesselsPane",
			})
		)
	}
}
