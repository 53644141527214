import ClientsPage from "@pages/ClientsPage/ClientsPage"
import { useAppSelector } from "@redux/app/hooks"
import { getAppStructure } from "@redux/features/appStructure/appStructureSlice"
import { createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute("/_adminPageLayout/clients/")({
	component: RouteComponent,
})

function RouteComponent() {
	const appStruct = useAppSelector(getAppStructure)

	return appStruct === null ? <>{"Loading..."}</> : <ClientsPage props={appStruct} />
}
