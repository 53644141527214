import { getDataAge } from "@components/kpi/utils"
import * as HoverCardPrimitive from "@radix-ui/react-hover-card"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import {
	getPanelState,
	updateSidePanelState,
} from "@redux/features/mapSidePanelControl/mapSidePanelControlSlice"
import {
	setSelectedFreightLetter,
	setShowFreightLetterForm,
} from "@redux/features/selectedFreightLetter/selectedFreightLetterSlice"
import { setSelectedVessel } from "@redux/features/selectedVessel/selectedVesselSlice"
import { Link, useNavigate } from "@tanstack/react-router"
import { TableBodyProps } from "@ui-lib/src/components/Table/Table"
import { OpModeState } from "@utilityTypes/opmodeDefs"
import { FreightLetter, PlantInfo } from "api"
import { Fragment, ReactElement, ReactNode } from "react"
import { MdKeyboardArrowRight } from "react-icons/md"

export type AssetsCondition = {
	overallCondition: string
	assets: { assetName: string; condition: string }[]
}
export type ItemProps = {
	plantName: string
	ownerName: string
	props: unknown
}
export type URLLink = {
	displayText: string
	url?: string
}

const TrafficLightHoverCard = ({ assetsCondition }: { assetsCondition: AssetsCondition }) => {
	const { overallCondition, assets } = assetsCondition
	return (
		<HoverCardPrimitive.Root>
			<HoverCardPrimitive.Trigger
				asChild
				className={`${assets.length > 0 && "cursor-pointer"}`}
			>
				<div
					className={`grid h-6 w-6 place-content-center rounded-full ${
						overallCondition === "c_green"
							? "bg-alert-sucess"
							: overallCondition === "a_yellow"
								? "bg-alert-warning"
								: overallCondition === "b_red"
									? "bg-alert-error"
									: "bg-gray-666"
					}`}
				>
					<div
						className={`border-gray-f4 h-5 w-5 rounded-full border-2 ${
							overallCondition === "c_green"
								? "bg-alert-sucess"
								: overallCondition === "a_yellow"
									? "bg-alert-warning"
									: overallCondition === "b_red"
										? "bg-alert-error"
										: "bg-gray-666"
						}`}
					/>
				</div>
			</HoverCardPrimitive.Trigger>
			{assets.length > 0 && (
				<HoverCardPrimitive.Content
					className="bg-gray-f4 shadow-card grid w-[200px] gap-2 rounded-lg p-4"
					style={{
						gridTemplateRows: `repeat(${assets.length}, minmax(0, 1fr))`,
						height: `calc(${assets.length}*32px + 32px)`,
					}}
				>
					{assets.map((assetData, index) => {
						const { assetName, condition } = assetData
						return (
							<div
								key={index}
								className="grid h-[24px] grid-cols-[24px_auto] gap-4"
							>
								<div
									className={`grid h-6 w-6 place-content-center rounded-full ${
										condition === "b_red"
											? "bg-alert-error"
											: condition === "a_yellow"
												? "bg-alert-warning"
												: condition === "c_green"
													? "bg-alert-sucess"
													: "bg-gray-666"
									}`}
								>
									<div
										className={`border-gray-f4 h-5 w-5 rounded-full border-2 ${
											condition === "b_red"
												? "bg-alert-error"
												: condition === "a_yellow"
													? "bg-alert-warning"
													: condition === "c_green"
														? "bg-alert-sucess"
														: "bg-gray-666"
										}`}
									/>
								</div>
								<p className="text-menu">{assetName}</p>
							</div>
						)
					})}
					<HoverCardPrimitive.Arrow style={{ fill: "#f4f4f4" }} />
				</HoverCardPrimitive.Content>
			)}
		</HoverCardPrimitive.Root>
	)
}

type Any = {
	type: "any"
	content: ReactNode
}
type ConditionLabel = {
	type: "condition-label"
	condition: OpModeState | string
}
type ConditionLabelPlantPage = {
	type: "condition-label-plant-page"
	condition: string
}
type DataAge = {
	type: "data-age"
	timestamp: string
}
type Details = {
	type: "details"
	plantInfo: PlantInfo
}
type DetailsFreightLetter = {
	type: "details-freight-letter"
	freightLetterData: FreightLetter
}
type DetailsOnClick = {
	type: "details-on-click"
	onClickFunc: () => void
}
type DetailsWithLink = {
	type: "details-with-link"
	url: string
}
type Icons = {
	type: "icons"
	icons: ReactNode[]
}
type Number = {
	type: "number"
	value: number | string
}
type OnlineOffline = {
	type: "online-offline"
	isOnline?: boolean
}
type PlantNameWithMap = ItemProps & { type: "plant-name-with-map" }
type Text = {
	type: "text"
	text: string
}
type TextRows = {
	type: "text-rows"
	rows: (number | string)[]
}
type TrafficLightHover = {
	type: "traffic-light-hover"
	assetsCondition: AssetsCondition
}
type URLParam = {
	type: "url-param"
	paramLabel: "vessel"
	paramValue: string
}
type URLText = URLLink & {
	type: "url-text"
}

type TypeName =
	| "any"
	| "condition-label"
	| "condition-label-plant-page"
	| "data-age"
	| "details"
	| "details-freight-letter"
	| "details-on-click"
	| "details-with-link"
	| "icons"
	| "number"
	| "online-offline"
	| "plant-name-with-map"
	| "text"
	| "text-rows"
	| "traffic-light-hover"
	| "url-param"
	| "url-text"

type CellType<T> = T extends "any"
	? Any
	: T extends "condition-label"
		? ConditionLabel
		: T extends "condition-label-plant-page"
			? ConditionLabelPlantPage
			: T extends "data-age"
				? DataAge
				: T extends "details"
					? Details
					: T extends "details-freight-letter"
						? DetailsFreightLetter
						: T extends "details-on-click"
							? DetailsOnClick
							: T extends "details-with-link"
								? DetailsWithLink
								: T extends "icons"
									? Icons
									: T extends "number"
										? Number
										: T extends "online-offline"
											? OnlineOffline
											: T extends "plant-name-with-map"
												? PlantNameWithMap
												: T extends "text"
													? Text
													: T extends "text-rows"
														? TextRows
														: T extends "traffic-light-hover"
															? TrafficLightHover
															: T extends "url-param"
																? URLParam
																: T extends "url-text"
																	? URLText
																	: never

export function CellSetterV1<T extends TypeName>({
	content,
	index,
}: {
	content: CellType<T>
	index: number | string
}) {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const panelState = useAppSelector(getPanelState)

	if (content.type === "condition-label") {
		const { condition } = content
		return (
			<div
				key={`${condition}_${index}`}
				className="group-hover:bg-gray-eee flex items-center"
			>
				<div
					className={`h-full w-2 ${
						condition === OpModeState.GREEN
							? "group-hover:bg-gray-eee"
							: condition === OpModeState.YELLOW
								? "bg-alert-warning"
								: condition === OpModeState.RED
									? "bg-alert-error"
									: condition === "UniSea"
										? "bg-blue-400"
										: "bg-gray-666"
					}`}
				/>
			</div>
		)
	}
	if (content.type === "condition-label-plant-page") {
		const { condition } = content
		return (
			<div
				key={`${condition}_${index}`}
				className="group-hover:bg-gray-eee flex items-center"
			>
				<div
					className={`h-full w-2 ${
						condition === "c_green"
							? "group-hover:bg-gray-eee"
							: condition === "a_yellow"
								? "bg-alert-warning"
								: condition === "b_red"
									? "bg-alert-error"
									: "bg-gray-666"
					}`}
				/>
			</div>
		)
	}
	if (content.type === "data-age") {
		const { timestamp } = content
		return (
			<div
				key={`${timestamp}_${index}`}
				style={{ userSelect: "text" }}
				className="group-hover:bg-gray-eee flex items-center py-4"
			>
				{getDataAge(timestamp)}
			</div>
		)
	}
	if (content.type === "details") {
		const { plantInfo } = content
		return (
			<div
				key={`details_${index}`}
				className="text-small group-hover:bg-gray-eee"
			>
				<div
					onClick={() => {
						dispatch(
							updateSidePanelState({
								...panelState,
								isTableExpanded: false,
							})
						)
						dispatch(setSelectedVessel(plantInfo))
					}}
					className="text-gray-333/60 hover:text-alert-focus/100 grid h-full grid-cols-[60px_27px] font-bold hover:scale-110 hover:cursor-pointer hover:font-bold"
				>
					<div className="hover:text-alert-focus flex items-center justify-center">
						<MdKeyboardArrowRight className={`h-6 w-6`} />
					</div>
				</div>
			</div>
		)
	}
	if (content.type === "details-freight-letter") {
		const { freightLetterData } = content
		return (
			<div
				key={`details_${index}`}
				className="text-small group-hover:bg-gray-eee"
			>
				<div
					onClick={() => {
						dispatch(setSelectedFreightLetter(freightLetterData))
						dispatch(setShowFreightLetterForm(true))
					}}
					className="text-gray-333/60 hover:text-alert-focus/100 grid h-full grid-cols-[60px_27px] font-bold hover:scale-110 hover:cursor-pointer hover:font-bold"
				>
					<div className="hover:text-alert-focus flex items-center justify-center">
						<Link
							className="no-underline"
							to={"/"}
							// to={"#"}
						>
							<MdKeyboardArrowRight className={`h-6 w-6`} />
						</Link>
					</div>
				</div>
			</div>
		)
	}
	if (content.type === "details-on-click") {
		const { onClickFunc } = content
		return (
			<div
				key={`details_${index}`}
				className="text-small group-hover:bg-gray-eee"
			>
				<div
					onClick={onClickFunc}
					className="text-gray-333/60 hover:text-alert-focus/100 grid h-full grid-cols-[60px_27px] font-bold hover:scale-110 hover:cursor-pointer hover:font-bold"
				>
					<div className="hover:text-alert-focus flex items-center justify-center">
						<MdKeyboardArrowRight className={`h-6 w-6`} />
					</div>
				</div>
			</div>
		)
	}
	if (content.type === "details-with-link") {
		const { url } = content
		return (
			<div
				key={`details_${url}_${index}`}
				className="text-small group-hover:bg-gray-eee"
			>
				<div className="text-gray-333/60 hover:text-alert-focus/100 grid h-full grid-cols-[60px_27px] font-bold hover:scale-110 hover:cursor-pointer hover:font-bold">
					<div className="hover:text-alert-focus flex items-center justify-center">
						<Link
							className="no-underline"
							to={url}
						>
							<MdKeyboardArrowRight className={`h-6 w-6`} />
						</Link>
					</div>
				</div>
			</div>
		)
	}
	if (content.type === "icons") {
		const { icons } = content
		return (
			<div
				className="group-hover:bg-gray-eee flex items-center justify-end"
				key={`icons_${index}`}
			>
				{icons.map((icon, index) => (
					<Fragment key={`icon_${index}`}>{icon}</Fragment>
				))}
			</div>
		)
	}
	if (content.type === "number") {
		const { value } = content
		return (
			<div
				key={`${value}_${index}`}
				style={{ userSelect: "text" }}
				className={`group-hover:bg-gray-eee flex items-center ${
					typeof value !== "number" && "text-gray-666"
				}`}
			>
				{typeof value === "number" && isFinite(value)
					? Math.round(value * 100) / 100
					: value}
			</div>
		)
	}
	if (content.type === "online-offline") {
		const { isOnline } = content
		return (
			<div
				key={`isOnline_${index}`}
				style={{ userSelect: "text" }}
				className="group-hover:bg-gray-eee flex items-center py-4 text-[16px]"
			>
				<p
					className={
						isOnline === true
							? "text-green-500"
							: isOnline === false
								? "text-red-600"
								: "text-slate-600"
					}
				>
					{isOnline === true ? "Online" : isOnline === false ? "Offline" : "unknown"}
				</p>
			</div>
		)
	}
	if (content.type === "plant-name-with-map") {
		const { plantName, ownerName, props } = content
		return (
			<div
				key={`${plantName}_${ownerName}_${index}`}
				style={{ userSelect: "text" }}
				className="group-hover:bg-gray-eee flex items-center"
			>
				<h1 className="mr-[5px] inline-block text-[16px] leading-[23px] font-bold">
					{`${plantName}\u00A0\u00A0`}
				</h1>

				<p className="[14px] text-gray-999 hover:text-alert-focus inline-block leading-[20px] font-normal underline hover:cursor-pointer">
					Map
				</p>
			</div>
		)
	}
	if (content.type === "text") {
		const { text } = content
		return (
			<div
				key={`${text}_${index}`}
				style={{ userSelect: "text" }}
				className="group-hover:bg-gray-eee flex items-center py-4"
			>
				{text}
			</div>
		)
	}
	if (content.type === "text-rows") {
		const { rows } = content
		return (
			<div
				key={`${rows?.[0]}_${index}`}
				className="group-hover:bg-gray-eee grid items-center overflow-hidden py-4"
				style={{ userSelect: "text", gridTemplateRows: `repeat(${rows.length}, 1fr)` }}
			>
				{rows.map((rowContent, idx) => (
					<div
						key={`${rows?.[0]}_${index}_line_${rowContent}_${idx}`}
						className="group-hover:bg-gray-eee flex items-center"
					>
						{rowContent}
					</div>
				))}
			</div>
		)
	}
	if (content.type === "traffic-light-hover") {
		const { assetsCondition } = content
		return (
			<div
				className="group-hover:bg-gray-eee flex items-center"
				key={`hoverTrafficLight_${assetsCondition.assets
					?.map((ac) => ac.assetName)
					.join()}_${index}`}
			>
				<TrafficLightHoverCard assetsCondition={assetsCondition} />
			</div>
		)
	}
	if (content.type === "url-param") {
		const { paramValue, paramLabel } = content
		return (
			<div
				key={`url_param_${paramValue}_${index}`}
				className="text-small group-hover:bg-gray-eee"
			>
				<div className="text-gray-333/60 hover:text-alert-focus/100 grid h-full grid-cols-[30px] font-bold hover:scale-110 hover:cursor-pointer hover:font-bold">
					<div className="hover:text-alert-focus flex items-center justify-center">
						<div
							onClick={(e) => {
								if (paramLabel === "vessel") {
									navigate({
										to: ".",
										search: (prev) => ({ ...prev, vessel: paramValue }),
									})
								}
							}}
						>
							<MdKeyboardArrowRight className={`h-6 w-6`} />
						</div>
					</div>
				</div>
			</div>
		)
	}
	if (content.type === "url-text") {
		const { displayText, url } = content
		return (
			<div
				key={`url_text_${displayText}_${index}`}
				style={{ userSelect: "text" }}
				className="text-small group-hover:bg-gray-eee flex items-center"
			>
				{url !== undefined ? (
					<Link
						className="hover:text-alert-focus underline"
						to={`${url}`}
					>
						<p className="text-[16px] leading-[23px]"> {displayText}</p>
					</Link>
				) : (
					<p className="text-[16px] leading-[23px]"> {displayText} </p>
				)}
			</div>
		)
	}

	return (
		<div
			key={`any_type_cell_${index}`}
			style={{ userSelect: "text" }}
			className="group-hover:bg-gray-eee flex items-center"
		>
			{content.content}
		</div>
	)
}

export function TableBodyMapPage<T>({
	columnsLayout,
	BodyContentLayout,
	rows,
}: TableBodyProps<T>): ReactElement {
	return (
		<div className="pt-3">
			{rows.map((row) => {
				const rowUniqueId = JSON.stringify(row.getAllCells().map((cell) => `${cell.id}`))
				return (
					<div
						className={`${columnsLayout} group bg-gray-f4 text-small mb-3 grid overflow-hidden rounded-sm select-none`}
						key={rowUniqueId}
					>
						<BodyContentLayout row={row} />
					</div>
				)
			})}
		</div>
	)
}

export function TableBodyFitContent<T>({
	columnsLayout,
	BodyContentLayout,
	rows,
}: TableBodyProps<T>): ReactElement {
	return (
		<div className="">
			{rows.map((row) => {
				const rowUniqueId = JSON.stringify(
					row.getAllCells().map((cell) => `${cell.getValue()}`)
				)
				return (
					<div
						className={`${columnsLayout} group bg-gray-f4 text-small mb-3 grid w-fit rounded-lg select-none`}
						key={rowUniqueId}
					>
						<BodyContentLayout row={row} />
					</div>
				)
			})}
		</div>
	)
}
