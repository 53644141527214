export const VESSELS_SPEC_SHEET_DATA: { [vesselName: string]: { [vesselProp: string]: string[] } } =
	{
		// ALTERA
		"Altera Thule": {
			"Class definition": [
				"✠ 1A Tanker for oil BIS BMON Bow",
				" loading BWM(T) CCO Clean(Design)",
				"COAT-PSPC(B, C) COMF(C-3, V-3)",
				"CSA(FLS2) CSR DYNPOS(AUTR) E0 ECA",
				"(SOx-A) ESP ESV(DP[HIL-IS]) F(A, M, C)",
				"HELDK(S, H) LCS NAUT(AW, ICS) Plus",
				"Recyclable TMON(oil lubricated) VCS(2, B)",
				"ER(SCR, TIER III)",
			],
			Loa: ["278.996 m"],
			Lbp: ["264.485 m"],
			Lload: ["266.782 m"],
			Bext: ["49.04 m"],
			Breadth: ["49 m"],
			Depth: ["24.5 m"],
			Draught: ["17.2 m"],
			"Gross Tonnage": ["86047"],
			"Net Tonnage": ["43629"],
			Deadweight: ["147098"],
		},
		"Lambada Spirit": {
			// "Vessel built": ["2013"],
			// Length: ["282 m"],
			// Breadth: ["49 m"],
			// Deadweight: ["154233 tons"],
			// "Gross tonnage": ["83882 tons"],
			// "Principal Dimensions": [""],
			"Length, overall": ["282 m"],
			"Breadth, moulded": ["49 m"],
			"Draft, design": ["16.20 m"],
			"Draft, scantling": ["17.2 m"],
			"Deadweight, scantling": ["154797 mt"],
			"Service speed": ["14.5 knots"],
			"Cruising range": ["18.000 nm"],
			// Capacities: [""],
			"Cargo tanks incl. slop tanks": ["165539 m3"],
			"Slop tanks": ["5888 m3"],
			"Fuel tanks": ["3135 m3"],
			"Gas oil/diesel oil tanks": ["544 m3"],
			"Fresh water tanks ": ["500 m3"],
			"Ballast tanks incl. peak tanks": ["45475 m3"],
			"Accommodation ": ["34+6 persons"],
			"Class definition": [
				"ABS, + A1E, Oil Carrier, CSR, AB-CM,",
				"DPS-2, SH, SHCM, SH-DLA, SFA(25),",
				"HIMP, RES, PMA, CPS, IGS-BALLAST,",
				"+AMS, +ACCU, +APS, NIBS, ESP, VEC-L,",
				"TCM, R1(single shaft), UWILD, POT,",
				"ENVIRO+, GP, BLU, BWE, Statement",
				"of Compliance for DNV’s F-AMC, HM1+R",
				"Slam Warning, HM2+R Hull Girder Stress",
			],
			// Machinery: [""],
			"Main eng.": ["MAN 6S70ME-C", "14270 kW"],
			"Aux. eng.": ["2 HHI x 9H32/40", "4500 kW"],
			"Aux. eng. II": ["2 HHI x 6H32/40", "3000 kW"],
			"El. Power": ["6.6 kV / 440 V"],
			"Aux. Boiler": ["2 x 30 tons/hr"],
			Economiser: ["1 x 1,6 tons/hr"],
			// "Rudders and Thrusters": [""],
			"Main propeller": ["4 blades", "1 CPP"],
			Rudder: ["High Lift", "1"],
			"Steering gear": ["1"],
			"Forw. tunnel thruster": ["1 x 2200 kW"],
			"Forw. azimuth thruster": ["2 x 2200 kW"],
			"Aft azimuth thruster": ["1 x 2200 kW"],
			"Aft tunnel thruster": ["1 x 2200 kW"],
			// "Bow Loading System": [""],
			"Brazil type – capacity": ["8000 m³/h"],
			"Traction winch": ["700 kN"],
			"Hose handling winch": ["400 kN"],
			"Service crane": ["1 x 5 tons"],
			// "Cargo & Ballast Equipment": [""],
			"Cargo tanks / slop tanks": ["12 / 2"],
			Segregation: ["3"],
			"Cargo pumps": ["3 x 3,800 m³/h"],
			"Cargo stripping": ["1 x 240 m³/h"],
			"Cargo strupping eductors": ["2 x 600 m³/h"],
			"Inert gas generator": ["14,250 m³/h"],
			"Inert gas topping-up generator": ["500 m³/h"],
			"Ballast pumps": ["2 x 2,500 m³/h"],
			// "Navigation & DP": [""],
			Navigation: ["2 radars, 2 ECDIS, 3 gyros,", "adaptive autopilot, 2 DGPS, AIS, VDR"],
			DP: ["K-POS, Artemis,  2 DARPS, Cyscan,", "Blom, RPMS, BLS telemetry"],
		},
		"Norse Spirit": {
			"Year built": ["2017"],
			"Draft (summer)": ["17.2 meters"],
			"Length overall": ["280 meters"],
			Breadth: ["49 meters"],
			Deadweight: ["148,167 tonnes"],
		},
		"Scott Spirit": {
			"Year built": ["2011"],
			"Class definition": [
				"✠1A1, CSR, Tanker for Oil ESP, BOW",
				"LOADING, SBM, COMF-V(3)C(3),",
				"HELDK-SH, OPP-F, RP, E0, ESV-DP[HIL]",
				"F-AMC CCO, DYNPOS-AUTR(A), NAUT-",
				"AW, VCS-2, CLEAN DESIGN, BWM-E(s),",
				"COAT-PSPC, CSA-2, PLUS-2, BIS, TMON",
			],
			"Length o.a.": ["250.00 m"],
			Breadth: ["43.80 m"],
			"Draft (summer)": ["15.00 m"],
			Deadweigth: ["109289 t"],
			"Cargo capcity": ["121700 m3"],
			"Gross Tonnage": ["66563"],
			"Net Tonnage": ["29517"],
		},
		"Sertanejo Spirit": {
			// "Principal Dimensions": [""],
			"Length, overall": ["282 m"],
			"Breadth, moulded": ["49 m"],
			"Draft, design": ["16.20 m"],
			"Draft, scantling": ["17.2 m"],
			"Deadweight, scantling": ["154797 mt"],
			"Service speed": ["14.5 knots"],
			"Cruising range": ["18.000 nm"],
			// Capacities: [""],
			"Cargo tanks incl. slop tanks": ["165539 m3"],
			"Slop tanks": ["5888 m3"],
			"Fuel tanks": ["3135 m3"],
			"Gas oil/diesel oil tanks": ["544 m3"],
			"Fresh water tanks ": ["500 m3"],
			"Ballast tanks incl. peak tanks": ["45475 m3"],
			"Accommodation ": ["34+6 persons"],
			"Class definition": [
				"ABS, + A1E, Oil Carrier, CSR, AB-CM,",
				"DPS-2, SH, SHCM, SH-DLA, SFA(25),",
				"HIMP, RES, PMA, CPS, IGS-BALLAST,",
				"+AMS, +ACCU, +APS, NIBS, ESP, VEC-L,",
				"TCM, R1(single shaft), UWILD, POT,",
				"ENVIRO+, GP, BLU, BWE, Statement",
				"of Compliance for DNV’s F-AMC, HM1+R",
				"Slam Warning, HM2+R Hull Girder Stress",
			],
			// Machinery: [""],
			"Main eng.": ["MAN 6S70ME-C", "14270 kW"],
			"Aux. eng.": ["2 HHI x 9H32/40", "4500 kW"],
			"Aux. eng. II": ["2 HHI x 6H32/40", "3000 kW"],
			"El. Power": ["6.6 kV / 440 V"],
			"Aux. Boiler": ["2 x 30 tons/hr"],
			Economiser: ["1 x 1,6 tons/hr"],
			// "Rudders and Thrusters": [""],
			"Main propeller": ["4 blades", "1 CPP"],
			Rudder: ["High Lift", "1"],
			"Steering gear": ["1"],
			"Forw. tunnel thruster": ["1 x 2200 kW"],
			"Forw. azimuth thruster": ["2 x 2200 kW"],
			"Aft azimuth thruster": ["1 x 2200 kW"],
			"Aft tunnel thruster": ["1 x 2200 kW"],
			// "Bow Loading System": [""],
			"Brazil type – capacity": ["8000 m³/h"],
			"Traction winch": ["700 kN"],
			"Hose handling winch": ["400 kN"],
			"Service crane": ["1 x 5 tons"],
			// "Cargo & Ballast Equipment": [""],
			"Cargo tanks / slop tanks": ["12 / 2"],
			Segregation: ["3"],
			"Cargo pumps": ["3 x 3,800 m³/h"],
			"Cargo stripping": ["1 x 240 m³/h"],
			"Cargo strupping eductors": ["2 x 600 m³/h"],
			"Inert gas generator": ["14,250 m³/h"],
			"Inert gas topping-up generator": ["500 m³/h"],
			"Ballast pumps": ["2 x 2,500 m³/h"],
			// "Navigation & DP": [""],
			Navigation: ["2 radars, 2 ECDIS, 3 gyros,", "adaptive autopilot, 2 DGPS, AIS, VDR"],
			DP: ["K-POS, Artemis,  2 DARPS, Cyscan,", "Blom, RPMS, BLS telemetry"],
		},
		// DOF
		Geoholm: {
			"Vessel built": ["2006"],
			"Vessel design": ["MT 6000", "Mk II"],
			"Class definition": ["✠ 1A1 Clean COMF(V-3) DK(+)", "DYNPOS(AUTR) E0 HELDK SF"],
			LOA: ["85.65 m"],
			"Breadth mld": ["19.7 m"],
			"Summer draught": ["6.1 m"],
			Deadweight: ["3691 t"],
			Accommodation: ["70"],
			"Gross tonnage": ["4454"],
			"Main engines": ["4 x 1530 kW"],
			"Bow thruster": ["1 x 883 kW"],
			"Azimuth thruster": ["2 x 883 kW"],
			"Stern thruster": ["2 x 2200 kW"],
			"Deck dimension": ["890 m²"],
			"Deck strength": ["5 t/m²"],
			"Deck capacity": ["1100 t"],
			"Fuel oil": ["1208 m³"],
			"Pot water": ["705 m³"],
			"Helideck dim": ["20.9 m diam", "12 t"],
			"ROV hangar": ["133 m²"],
			"Deck crane": ["SWL 5 t", "SWL 3 t"],
			"Offshore crane": ["SWL 90/100 t", "2140 m/1939 m"],
		},
		"Skandi Acergy": {
			"Vessel built": ["2008"],
			"Vessel design": ["AKER OSCV", "06L"],
			"Class definition": [
				"✠ 1A1 ICE-C SF COMF-V(3)C(3)",
				"HELDK-SH CRANE E0",
				"DYNPOS-AUTRO NAUT-AW",
				"Clean(Design) DK(+) TMON",
			],
			LOA: ["156.9 m"],
			"Breadth mld": ["27.0 m"],
			"Summer draught": ["8.5 m"],
			Deadweight: ["11500 t"],
			Accommodation: ["140"],
			"Gross tonnage": ["16500"],
			"Main engines": ["2 x 3840 kW", "4 x 2895 kW"],
			"Bow thruster": ["2 x 1900 kW"],
			"Azimuth thruster": ["2 x 1500 kW"],
			"Stern thruster": ["2 x 3000 kW"],
			"Deck dimensions": ["2100 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["7000 t"],
			"Fuel oil": ["2306 m³"],
			"Pot water": ["1500 m³"],
			"Helideck dim": [" 21 m diam", "12 t"],
			Moonpool: ["(7.2 x 7.2 m)", "+ 2 x (5.6 x", "3.5 m)"],
			"ROV hangar": ["292 m²"],
			"Deck crane": ["2 x SWL 3 t"],
			"Offshore crane": ["SWL 400 t", "3000 m"],
			"Offshore crane II": ["SWL 50/100 t", "2000/1000 m"],
			Tower: ["FLS 125 t"],
		},
		"Skandi Achiever": {
			Vessel: ["built 2007"],
			"Vessel design": ["AKER DSV 06"],
			"Class definition": [
				"✠ 1A1 ICE-C COMF-V(3) HELDK",
				"DSV-SAT E0 DYNPOS-AUTR (A)",
				"NAUT-AW Clean(Design) DK(+)",
			],
			LOA: ["105.9 m"],
			"Breadth mld": ["21.0 m"],
			"Summer draught": ["6.6 m"],
			Deadweight: ["4000 t"],
			Accommodation: ["100"],
			"Gross tonnage": ["7617"],
			"Main engines": ["4 x 1665 kW", "2 x 1980 kW"],
			"Bow thruster": ["1 x 1500 kW"],
			"Azimuth thruster": ["2 x 1500 kW"],
			"Stern thruster": ["2 x 2200 kW"],
			"Deck dimensions": ["735 m² +", "(Mezz deck = 153 m²)"],
			"Deck strength": ["5 - 10 t/m²"],
			"Deck capacity": ["1300 t"],
			"Fuel oil": ["1201 m³"],
			"Pot water": ["1088 m³"],
			"Helideck dim": ["20.9 m diam", "12 t"],
			Moonpool: ["4.2 x 4.2 m"],
			"ROV hangar": ["137 m²"],
			"Deck crane": ["SWL 3 t"],
			"Seabed crane": ["SWL 5 t"],
			"Offshore crane": ["SWL 140 t", "2500 m"],
			"Diving system": ["18 men", "saturated", "diving system", "installed"],
		},
		"Skandi Acu": {
			"Vessel delivery": ["2016"],
			"Vessel desig": ["VARD 3 05"],
			"Class definition": [
				"✠ 1A1 E0 DYNPOS-AUTR",
				"DK (+) CRANE HELDK-SH BIS",
				"Clean(Design) NAUT-AW",
				"Comf-V(3) C(3) SPS BWM (T)",
				"Recyclable SF",
			],
			LOA: ["145.9 m"],
			"Breadth mld": ["30.0 m"],
			"Summer draught": ["8.5 m"],
			Deadweight: ["10857 t"],
			Accommodation: ["120"],
			"Gross tonnage": ["18075"],
			"Main engines": ["6 x 3840 kW"],
			"Bow thruster": ["3 x 2400 kW"],
			"Azimuth thruster": ["1 x 2000 kW"],
			"Stern thruster": ["3 x 3700 kW"],
			"Deck dimensions": ["3000 m²"],
			"Deck strength": ["10 t/m²"],
			"Fuel oil": ["2450 m³"],
			"Pot water": ["1750 m³"],
			Moonpool: ["9.1 m x 7.2 m"],
			"Helideck dim": ["20.8 m dia", "12 t"],
			"Deck crane": ["1 x SWL 6 t", "1 x SWL 10 t", "2 x SWL 50 t"],
			"Offshore crane": ["1 x SWL 50 t"],
			Laytower: ["650 t"],
		},
		"Skandi Africa": {
			"Vessel built": ["2015"],
			"Vessel design": ["VARD OSCV 12"],
			"Class definition": [
				"✠  1A1 Offshore service vessel",
				"SPS BIS BWM(T) Clean(Design)",
				"COMF(C-3, V-3) Crane DEICE",
				"DK(+) DYNPOS(AUTRO)",
				"E0 HELDK(S, H) LFL(*)",
				"NAUT(AW) Recyclable SF VIBR",
				"Winterized(Basic)",
			],
			LOA: ["160.9 m"],
			"Breadth mld": ["32.0 m"],
			"Summer draught": ["9.25 m"],
			Deadweight: ["12655 t"],
			Accommodation: ["140"],
			"Gross tonnage": ["22696"],
			"Main engines": ["4 x 3840 kW", "2 x 4320 kW"],
			"Bow thruster": ["2 x 2800 kW"],
			"Azimuth thruster": ["2 x 2200 kW"],
			"Stern thruster": ["3 x 3700 kW"],
			"Deck dimensions": ["2700 m³"],
			"Deck strength": ["10 t/m2 - 15 t/m2"],
			"Deck capacity": ["5000 t"],
			"Fuel oil": ["2650 m³"],
			"Pot water": ["800 m³"],
			"Methanol/special product": ["200 m³"],
			"Brine/Glycol": ["500 m³"],
			Moonpool: ["9.4 m x 7.2 m"],
			"ROV moonpool": ["2 x 5 m x 5.6 m"],
			"ROV hangar": ["321 m2"],
			"Deck crane": ["3 x SWL 6 t"],
			"Offshore crane": ["SWL 450/900 t", "4400/2200 m"],
			"Offshore crane II": ["SWL 150 t", "3000 m"],
			Laytower: ["650 t"],
		},
		"Skandi Amazonas": {
			"Vessel built": ["2011"],
			"Vessel design": ["STX AH12"],
			"Class definition": [
				"✠ 1A1 Tug Supply Vessel SF",
				"COMF-V(3) E0 DYNPOS-AUTR",
				"NAUT-OSV(A) Clean(Design)",
				"DK(+) TMON",
			],
			LOA: ["95.0 m"],
			"Breadth mld": ["24.1 m"],
			"Summer draught": ["7.8 m"],
			Deadweight: ["4700 t"],
			Accommodation: ["60"],
			"Gross tonnage": ["7099"],
			"Main engines": ["2 x 7680 kW"],
			"Bow thruster": ["2 x 1200 kW"],
			"Azimuth thruster": ["2 x 1500 kW"],
			"Stern thruster": ["1 x 1200 kW"],
			"Deck dimensions": ["750 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["3000 t"],
			"Fuel oil": ["1367 m³"],
			"Pot water": ["1077 m³"],
			"Bollard pull": ["324 t"],
			"AH drum": ["2 x 460 t"],
			"Towing drum": ["1 x 500 t"],
			"Chain locker": ["1078 m³"],
			"Shark jaws": ["2 x 800 t"],
			"Towing pins": ["4 x 400 t"],
			"Deck crane": ["2 x SWL 5 t", "1 x SWL 15 t"],
			"Torpedo AH frame": ["Yes"],
		},
		"Skandi Angra": {
			"Vessel built": ["2015"],
			"Vessel design": ["VARD AH11"],
			"Class definition": [
				"✠ 1A1 Supply vessel Tug",
				"Clean(Design) COMF(V-3)",
				"DK(+) DYNPOS(AUTR) E0",
				"NAUT(OSV(A)) SF TMON",
			],
			LOA: ["93.5 m"],
			"Breadth mld": ["22.0 m"],
			"Summer draught": ["7.9 m"],
			Deadweight: ["4299 t"],
			Accommodation: ["30"],
			"Gross tonnage": ["7334"],
			"Main engines": ["2 x 4500 kW", "2 x 4000 kW"],
			"Bow thruster": ["1 x 880 kW"],
			"Azimuth thruster": ["2 x 880 kW"],
			"Stern thruster": ["1 x 880 kW"],
			"Deck dimensions": ["760 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["1750 t"],
			"Fuel oil": ["1305 m³"],
			"Pot water": ["885 m³"],
			"Drill water": ["3240 m³"],
			"Bollard pull": ["280 t"],
			"AH drum": ["1 x 500 t"],
			"Towing drum": ["2 x 460 t"],
			"Chain locker": ["1100 m³"],
			"Shark jaws": ["2 x 800 t"],
			"Towing pins": ["2 x 400 t"],
			"Deck crane": ["3 x SWL 5 t"],
			"Torpedo AH frame": ["Yes"],
		},
		"Skandi Atlantic": {
			"Vessel built": ["2012"],
			"Vessel design": ["STX AH08"],
			"Class definition": [
				"✠ 1A1 Fire fighter(I+, II)",
				"Tug Clean(Design) DK(+)",
				"DYNPOS(AUTR) E0 HL(2.8)",
				"NAUT(OSV(A)) OILREC SF TMON",
			],
			LOA: ["75.0 m"],
			"Breadth mld": ["17.4 m"],
			"Summer draught": ["7.0 m"],
			Deadweight: ["3000 t"],
			Accommodation: ["27"],
			"Gross tonnage": ["3181"],
			"Main engines": ["2 x 6000 kW"],
			"Bow thruster": ["2 x 880 kW"],
			"Stern thruster": ["2 x 880 kW"],
			"Deck dimensions": ["525 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["1000 t"],
			"Fuel oil": ["1685 m³"],
			"Pot water": ["641 m³"],
			"Dry bulk": ["264 m³"],
			Brine: ["430 m³"],
			"Drill water": ["2019 m³"],
			"Bollard pull": ["193 t"],
			"AH drum": ["350 t"],
			"Towing drum": ["350 t"],
			"Chain locker": ["2 x 150 m³"],
			"Shark jaws": ["2 x 600 t"],
			"Towing pins": ["4 x 300 t"],
			"Deck crane": ["1 x SWL 5 t", "1 x SWL 3 t"],
		},
		"Skandi Aukra": {
			"Vessel built": ["2012"],
			"Vessel design": [" STX PSV 09 CD"],
			"Class definition": [
				"✠ 1A1 Clean(Design) COMF(V-3)",
				"DK(+) DYNPOS(AUTR) E0",
				"HL(2.8) LFL(*) NAUT(OSV(A))",
				"OILREC SF",
			],
			LOA: [" 87.9 m"],
			"Breadth mld": [" 19.0 m"],
			"Summer draught": [" 6.6 m"],
			Deadweight: [" 4574 t"],
			Accommodation: [" 26"],
			"Gross tonnage": [" 3966"],
			"Main engines": [" 4 x 1665 kW"],
			"Bow thruster": [" 1 x 880 kW"],
			"Azimuth thruster": [" 1 x 880 kW"],
			"Stern thruster": [" 2 x 2200 kW"],
			"Deck dimension": [" 1000 m2"],
			"Deck strength": [" 5 t/m2 - 10 t/m2"],
			"Deck capacity": [" 2600 t"],
			"*": [
				"Tank capacities are dependent on configuration of tank modes and maximum for each product (combined tanks).",
			],
			"Fuel oil": [" 1052 m3"],
			Mud: [" 962 m3"],
			"Pot water": [" 1293 m³"],
			"Base oil": [" 316 m3"],
			Methanol: [" 127 m3"],
			"Dry bulk": [" 320 m3"],
			"Drill water": [" 2924 m3"],
			Brine: [" 962 m3"],
			ORO: ["1356 m3"],
			Crane: [" SWL 1 t", "SWL 5 t"],
		},
		"Skandi Barra": {
			"Vessel built": ["2005"],
			"Vessel design": ["MT 6000", "MK II"],
			"Class definition": [
				"✠ 1A1 Clean COMF(V-3) DK(+)",
				"DYNPOS(AUTR) E0 HL(2.5,2.8)",
				"LFL(*) SF",
			],
			LOA: ["86.65 m"],
			"Breadth mld": ["19.7 m"],
			"Summer draught": ["6.18 m"],
			Deadweight: ["4150 t"],
			Accommodation: ["24"],
			"Gross tonnage": ["3350"],
			"Main engines": ["4 x 1530 kW"],
			"Bow thruster": ["1 x 880 kW"],
			"Azimuth thruster": ["1 x 883 kW"],
			"Deck dimension": ["941 m2"],
			"Deck strength": ["5 t/m2"],
			"Deck capacity": ["3164 t"],
			"Fuel oil": ["1230 m3"],
			Mud: ["654 m3"],
			"Pot water": ["892 m³"],
			"Base oil": ["204 m³"],
			Methanol: ["162 m³"],
			"Dry bulk": ["396 m³"],
			"Drill water": ["1188 m³"],
			Brine: ["646 m³"],
			"Crane SWL": ["1 t", "SWL 3 t"],
		},
		"Skandi Buzios": {
			"Vessel built": ["2016"],
			"Vessel design": ["VARD 3 05"],
			"Class definition": [
				"✠ 1A1 E0 DYNPOS-AUTR",
				"DK (+) CRANE HELDK-SH BIS",
				"Clean(Design) NAUT-AW",
				"Comf-V(3) C(3) SPS ICS SF",
			],
			LOA: ["146.0 m"],
			"Breadth mld": ["30.0 m"],
			"Summer draught": ["8.5 m"],
			Deadweight: ["13200 t"],
			Accommodation: ["120"],
			"Gross tonnage": ["18075"],
			"Main engines": ["6 x 3840 kW"],
			"Bow thruster": ["3 x 2400 kW"],
			"Azimuth thruster": ["1 x 2000 kW"],
			"Stern thruster": ["3 x 3700 kW"],
			"Deck dimensions": ["3000 m²"],
			"Deck strength": ["10 t/m²"],
			"Fuel oil": ["2500 m³"],
			"Pot water": ["1800 m³"],
			"Chain locker": ["TBC"],
			Moonpool: ["9.1 m x 7.2m"],
			"Helideck dim": ["22 m diam"],
			"Deck crane": ["1 x SWL 5 t", "1 x SWL 10 t", "2 x SWL 15 t"],
			"Offshore crane": ["50 t SWL"],
			"Laytower (tiltable)": ["650 t"],
			Basket: ["1 x 2500 t", "1 x 1500 t"],
		},
		"Skandi Caledonia": {
			"Vessel built": ["2003"],
			"Vessel design": ["MT 6000"],
			"Class definition": [
				"✠ 1A1 SF LFL* COMF-V(3) E0",
				"DYNPOS-AUTR CLEAN DK(+)",
				"HL(2.0, 2.8)",
			],
			LOA: ["83.9 m"],
			"Breadth mld": ["19.7 m"],
			"Summer draught": ["6.1 m"],
			Deadweight: ["4135 t"],
			Accommodation: ["24"],
			"Gross tonnage": ["3285"],
			"Main engines": ["4 x 1530 kW"],
			"Bow thruster": ["1 x 883 kW"],
			"Azimuth thruster": ["1 x 883 kW"],
			"Stern thruster": ["2 x 2200 kW"],
			"Deck dimensions": ["907 m²"],
			"Deck strength": ["5 t/m²"],
			"Deck capacity": ["3400 t"],
			"Fuel oil": ["1270 m³"],
			Mud: ["653 m³"],
			"Pot water": ["983 m³"],
			"Base oil": ["196 m³"],
			Methanol: ["163 m³"],
			"Dry bulk": ["397 m³"],
			"Drill water": ["1009 m³"],
			Brine: ["720 m³"],
			Crane: ["2 x SWL 3 t"],
		},
		"Skandi Carla": {
			"Vessel built": ["2001"],
			"Vessel design": ["MT 6002"],
			"Class definition": ["✠ 1A1 SF HELDK E0", "DYNPOS-AUTR CLEAN DK(+)"],
			LOA: ["83.85 m"],
			"Breadth mld": ["19.7 m"],
			"Summer draught": ["6.1 m"],
			Deadweight: ["3244 t"],
			Accommodation: ["80"],
			"Gross tonnage": ["4456"],
			"Main engines": ["4 x 2530 kW"],
			"Bow thruster": ["2 x 1000 kW"],
			"Azimuth thruster": ["1 x 1000 kW"],
			"Stern thruster": ["2 x 2200 kW"],
			"Deck dimensions": ["634 m² +", "total Mezz", "209 m²"],
			"Deck strength": ["5 t/m²"],
			"Deck capacity": ["820 t"],
			"Fuel oil": ["1448 m³"],
			"Pot water": ["851 m³"],
			"Drill water": ["1598 m³"],
			MEG: ["270 m³"],
			"Helideck dim": ["19.5 m diam", "9.3 t"],
			Moonpool: ["30 m²"],
			"ROV hangar": ["130 m²"],
			"Deck crane": ["SWL 3 t", "SWL 10 t"],
			"Offshore crane": ["SWL 50 t", "3105 m"],
		},
		"Skandi Chieftain": {
			"Vessel built": ["2005"],
			"Vessel design": ["MT 6009"],
			"Class definition": ["✠ 1A1 SF COMF-V(3) E0", "DYNPOS-AUTR CLEAN DK(+)", "HL(2.5)"],
			LOA: ["74.3 m"],
			"Breadth mld": ["16.4 m"],
			"Summer draught": ["6.3 m"],
			Deadweight: ["3300 t"],
			Accommodation: ["50"],
			"Gross tonnage": ["3187"],
			"Main engines": ["4 x 1150 kW"],
			"Bow thruster": ["2 x 800 kW"],
			"Azimuth thruster": ["2 x 1470 kW"],
			"Deck dimensions": ["260 m²"],
			"Deck strength": ["5 t/m²"],
			"Deck capacity": ["2030 t"],
			"Fuel oil": ["1200 m³"],
			"Pot water": ["651 m³"],
			"Drill water": ["912 m³"],
			"Base oil": ["347 m³"],
			Brine: ["424 m³"],
			Methanol: ["206 m³"],
			Mud: ["1083 m³"],
			"Deck crane": ["SWL 3 t", "SWL 1 t", "SWL 16 t"],
			"Offshore crane": ["SWL 16 t"],
			"A-frame": ["SWL 30 t"],
			"AHC winch": ["SWL 10 t"],
		},
		"Skandi Commander": {
			"Vessel built": ["2007"],
			"Vessel design": ["MT 6009"],
			"Class definition": [
				"✠ 1A1 SF COMF-V(3) E0",
				"DYNPOS-AUTR NAUT-OSV(A)",
				"CLEAN DK(+) HL(2.5)",
			],
			LOA: ["74.3 m"],
			"Breadth mld": ["16.4 m"],
			"Summer draught": ["6.2 m"],
			Deadweight: ["3268 t"],
			Accommodation: ["48"],
			"Gross tonnage": ["3203"],
			"Main engines": ["2 x 1140 kW", "2 x 1330 kW"],
			"Bow thruster": ["2 x 1920 kW"],
			"Azimuth thruster": ["2 x 1470 kW"],
			"Deck dimensions": ["218 m³"],
			"Deck strength": ["5 t/m²"],
			"Deck capacity": ["1600 t"],
			"Fuel oil": ["1232 m³"],
			"Pot water": ["652 m³"],
			"Drill water": ["892 m³"],
			"Base oil": ["346 m³"],
			Brine: ["424 m³"],
			Methanol: ["206 m³"],
			Mud: ["1079 m³"],
			"Deck crane": ["SWL 1 t", "SWL 3 t"],
			"Offshore crane": ["SWL 10 t"],
			"A-frame": ["SWL 30 t"],
			"AHC winch": ["SWL 10 t"],
		},
		"Skandi Darwin": {
			"Vessel built": ["2012"],
			"Vessel design": ["MT 6015", "MPSV"],
			"Class definition": [
				"✠ 1A1 E0 SF Dynpos AUTR",
				"CLEAN DESIGN COMF-V (rat. 3)",
				"DK(+) hl(2,5 LFL* NAUT OSV (A)",
				"Ice C SPS Oil Recovery",
			],
			LOA: ["93.8 m"],
			"Breadth mld": ["20.0 m"],
			"Summer draught": ["6.5 m"],
			Deadweight: ["TBA t"],
			Accommodation: ["60"],
			"Gross tonnage": ["6055"],
			"Main engines": ["4 x 2230 kW"],
			"Bow thrusters": ["2 x 925 kW"],
			"Azimuth thruster": ["1 x 880 kW"],
			"Stern thruster": ["2 x 2200 kW"],
			"Deck dimension": ["760 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["1850 t"],
			"Fuel oil": ["1030 m³"],
			"Fresh water": ["1000 m³"],
			"Drill water": ["2100 m³"],
			Brine: ["790/1120 m³"],
			Methanol: ["194 m³"],
			"Dry bulk": ["330 m³"],
			Mud: ["1185/", "1515 m³"],
			"Deck crane": ["SWL 2 t", "SWL 3 t"],
			"Offshore crane": ["70 t"],
		},
		"Skandi Emerald": {
			"Vessel built": ["2011"],
			"Vessel design": ["STX AH08"],
			"Class definition": [
				"✠ 1A1 Fire fighter(I+, II)",
				"Tug Clean(Design) DK(+)",
				"DYNPOS(AUTR) E0 HL(2.8)",
				"NAUT(OSV(A)) SF TMON",
			],
			LOA: ["75.0 m"],
			"Breadth mld": ["17.4 m"],
			"Summer draught": ["7.0 m"],
			Deadweight: ["3195 t"],
			Accommodation: ["27"],
			"Gross tonnage": ["3195"],
			"Main engines": ["2 x 6000 kW"],
			"Bow thruster": ["2 x 880 kW"],
			"Stern thruster": ["2 x 880 kW"],
			"Deck dimensions": ["525 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["1000 t"],
			"Fuel oil": ["1685 m³"],
			"Pot water": ["641 m³"],
			"Dry bulk": ["264 m³"],
			Brine: ["430 m³"],
			"Drill water": ["2019 m³"],
			"Bollard pull": ["201 t"],
			"AH drum": ["350 t"],
			"Towing drum": ["350 t"],
			"Chain locker": ["2 x 150 m³"],
			"Shark jaws": ["2 x 600 t"],
			"Towing pins": ["4 x 300 t"],
			"Deck crane": ["SWL 5 t", "SWL 3 t"],
		},
		"Skandi Feistein": {
			"Vessel built": ["2011"],
			"Vessel design": ["STX", "PSV 09 CD"],
			"Class definition": [
				"✠ 1A1 Clean(Design) COMF(V-3)",
				"DK(+) DYNPOS(AUTR) E0",
				"HL(2.8) LFL(*) NAUT(OSV(A))",
				"OILREC SF",
			],
			LOA: ["87.9 m"],
			"Breadth mld": ["19.0 m"],
			"Summer draught": ["6.6 m"],
			Deadweight: ["4623 t"],
			Accommodation: ["26"],
			"Gross tonnage": ["3959"],
			"Main engines": ["4 x 1665 kW"],
			"Bow thruster": ["1 x 880 kW"],
			"Azimuth thruster": ["1 x 880 kW"],
			"Stern thruster": ["2 x 2200 kW"],
			"Deck dimension": ["1000 m²"],
			"Deck strength": ["5 t/m² - 10", "t/m²"],
			"Deck capacity": ["2600 t"],
			"Fuel oil": ["858 m³"],
			Mud: ["795 m³"],
			"Pot water": ["720 m³"],
			"Base oil": ["189 m³"],
			Methanol: ["167 m³"],
			"Dry bulk": ["325 m³"],
			"Drill water": ["1924 m³"],
			Brine: ["187 m³"],
			Crane: ["SWL 1 t", "SWL 5 t"],
		},
		"Skandi Flora": {
			"Vessel built": ["2009"],
			"Vessel design": ["STX", "PSV 06 CD"],
			"Class definition": [
				"✠ 1A1 Supply vessel",
				"Clean(Design) COMF(V-3) DK(+)",
				"DYNPOS(AUTR) E0 HL(2.8)",
				"LFL(*) OILREC SF",
			],
			LOA: ["94.9 m"],
			"Breadth mld": ["20.0 m"],
			"Summer draught": ["6.53 m"],
			Deadweight: ["5150 t"],
			Accommodation: ["25"],
			"Gross tonnage": ["4469"],
			"Main engines": ["4 x 1 980 kW"],
			"Bow thruster": ["2 x 880 kW"],
			"Azimuth thruster": ["1 x 880 kW"],
			"Stern thruster": ["2 x 2200 kW"],
			"Deck dimension": ["1025 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["3200 t"],
			"Fuel oil": ["900 m³"],
			Mud: ["1506 m³"],
			"Pot water": ["851 m³"],
			"Base oil": ["266 m³"],
			Methanol: ["230 m³"],
			"Dry bulk": ["404 m³"],
			"Drill water": ["1980 m³"],
			Brine: ["1506 m³"],
			Crane: ["2 x SWL 10 t"],
			"NOFO compliant": ["Yes"],
		},
		"Skandi Fluminense": {
			"Vessel built": ["2007"],
			"Vessel design": ["UT 722 L"],
			"Class definition": [
				"✠ 1A1 Tug Supply Vessel",
				"Fire Fighter I+ and II OILREC SF",
				"COMF-V(3) E0 DYNPOS-AUTR",
				"CLEAN TMON",
			],
			LOA: ["80.5 m"],
			"Breadth mld": ["18.0 m"],
			"Summer draught": ["6.0 m"],
			Deadweight: ["2659 t"],
			Accommodation: ["40"],
			"Gross tonnage": ["3519"],
			"Main engines": ["2 x 3560 kW", "2 x 2650 kW"],
			"Bow thruster": ["1 x 883 kW"],
			"Azimuth thruster": ["1 x 1120 kW"],
			"Stern thruster": ["2 x 736 kW"],
			"Deck dimensions": ["590 m²"],
			"Deck strength": ["5 t/m2 - 10 t/m2"],
			"Deck capacity": ["800 t"],
			"Fuel oil": ["1126 m³"],
			"Pot water": ["641 m³"],
			"Base oil": ["193 m³"],
			Mud: ["468 m³"],
			"Dry bulk": ["334 m³"],
			Brine: ["850 m³"],
			"Drill water": ["1467 m³"],
			"Bollard pull": ["211 t"],
			"AH drum": ["500 t"],
			"Towing drum": ["400 t"],
			"Chain locker": ["570 m³"],
			"Shark jaws": ["2 x 700 t"],
			"Towing pins": ["4 x 300 t"],
			"Deck crane": ["SWL 10 t", "SWL 2 t"],
		},
		"Skandi Gamma": {
			"Vessel built": ["2011"],
			"Vessel design": ["STX", "PSV 06 LNG"],
			"Class definition": [
				"✠ 1A1 Standby Vessel OILREC",
				"SF LFL* COMF-V(3)C(3) E0",
				"ESV-DP[HIL] DYNPOS-AUTR",
				"NAUT-OSV(A) Clean(Design)",
				"DK(+) HL(2.8) GAS FUELLED",
			],
			LOA: ["94.9 m"],
			"Breadth mid": ["20.0 m"],
			"Summer draught": ["6.6 m"],
			Deadweight: ["4753 t"],
			Accommodation: ["25"],
			"Gross tonnage": ["5054"],
			"Main engines": ["3 x 2610 kW"],
			"Bow thruster": ["2 x 883 kW"],
			"Azimuth thruster": ["1 x 880 kW"],
			"Stern thruster": ["2 x 2200 kW"],
			"Deck dimension": ["1000 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["3000 t"],
			"*": [
				"The vessel has a Multi- Application",
				"Cargo System (PG MACS) Tank",
				"capacities are dependent on",
				"configuration of tank modes",
				"and maximum for each product",
				"(combined tanks).",
			],
			"Multi-purp. tank (Mud/Brine/Cement/ORO)": ["1172 m³"],
			"Fuel Oil": ["1290 m³"],
			LNG: ["205 m³"],
			"Drill cuttings": ["525 m3"],
			"Pot water": ["881 m³"],
			"Base oil": ["200 m³"],
			Methanol: ["231 m³"],
			"Special product": ["200 m³"],
			"Drill water": ["1908 m³"],
			Brine: ["220 m³"],
			ORO: ["1700 m³"],
			Crane: ["SWL 3 t", "SWL 10 t"],
			"NOFO compliant": ["Yes"],
		},
		"Skandi Hawk": {
			"Vessel built": ["2012"],
			"Vessel design": ["STX", "PSV 09 CD"],
			"Class definition": [
				"✠ 1A1 Fire fighter(I)",
				"Supply vessel Clean(Design)",
				"COMF(V-3) DK(+)",
				"DYNPOS(AUTR) E0 HL(2.8)",
				"LFL(*) NAUT(OSV(A)) SF",
			],
			LOA: ["88.1 m"],
			"Breadth mld": ["19.0 m"],
			"Summer draught": ["6.6 m"],
			Deadweight: ["4400 t"],
			Accommodation: ["62"],
			"Gross tonnage": ["4508"],
			"Main engines": ["4 x 1665 kW"],
			"Bow thruster": ["1 x 880 kW"],
			"Azimuth thruster": ["1 x 880 kW"],
			"Stern thruster": ["2 x 2200 kW"],
			"Deck dimension": ["730 m²"],
			"Deck strength": ["5 - 10 t/m²"],
			"Deck capacity": ["2450 t"],
			"Fuel oil": ["1054 m³"],
			"Pot water": ["533 m³"],
			"Drill water": ["2166 m³"],
			"Base oil": ["192 m³"],
			Brine: ["960 m³"],
			Methanol: ["208 m³"],
			"Dry bulk": ["320 m³"],
			Mud: ["970 m³"],
			"Deck crane": ["SWL 1 t", "SWL 5 t"],
			"Offshore crane": ["SWL 60 t", "2000 m"],
		},
		"Skandi Hera": {
			"Vessel built": ["2009"],
			"Vessel design": ["Ulstein A122"],
			"Class definition": [
				"✠ 1A1 ICE-C Tug Supply Vessel",
				"SF COMF-V(3)C(3) OPP-F E0",
				"DYNPOS-AUTR NAUT-OSV(A)",
				"CLEAN DESIGN DK(+) HL(2.5)",
			],
			LOA: ["93.8 m"],
			"Breadth mld": ["23.0 m"],
			"Summer draught": ["8.0 m"],
			Deadweight: ["4925 t"],
			Accommodation: ["68"],
			"Gross tonnage": ["6839"],
			"Main engines": ["2 x 4500 kW"],
			"Bow thruster": ["2 x 1200 kW"],
			"Azimuth thruster": ["1 x 1400 kW"],
			"Stern thruster": ["2 x 1050 kW"],
			"Deck dimensions": ["721 m²"],
			"Deck strength": ["10 t/m2"],
			"Deck capacity": ["2500 t"],
			"Fuel oil": ["1800 m³"],
			"Ballast water": ["4771 m³"],
			"Base oil": ["190 m³"],
			Mud: ["639 m³"],
			Cement: ["175 m³"],
			Brine: ["964 m³"],
			"Fresh water": ["706 m³"],
			"Bollard pull": ["277 t"],
			"Special-handling drum": ["500 t"],
			"Towing & AH drum": ["2 x 450 t"],
			"Chain locker": ["4 x 200 m³"],
			"Shark jaws": ["2 x 800 t"],
			"Towing pins": ["4 x 300 t"],
			"Offshore crane prepared for": ["250 t"],
			"Deck crane": ["1 x SWL 25 t", "2 x SWL 5 t"],
		},
		"Skandi Hercules": {
			"Vessel built": ["2010"],
			"Vessel design": ["STX", "AH04 CD"],
			"Class definition": [
				"✠ 1A1 Tug Supply Vessel SF",
				"COMF-V(3) HELDK-SH E0",
				"DYNPOS-AUTR NAUT-OSV(A)",
				"Clean(Design) DK(+) HL(2.8)",
				"TMON",
			],
			LOA: ["109.6 m"],
			"Breadth mld": ["24.0 m"],
			"Summer draught": ["7.8 m"],
			Deadweight: ["4985 t"],
			Accommodation: ["90"],
			"Gross tonnage": ["8246"],
			"Main engines": ["2 x 7680 kW", "4 x 2600 kW"],
			"Bow thruster": ["2 x 1500 kW"],
			"Azimuth thruster": ["1 x 1500 kW"],
			"Stern thruster": ["2 x 1200 kW"],
			"Deck dimension": ["970 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["2800 t"],
			"Fuel oil": ["2416 m³"],
			"Pot water": ["965 m³"],
			"Helideck dim": ["20.88 m", "diam, 12.8 t"],
			Moonpool: ["7.2 x 7.2 m"],
			"ROV hangar": ["68 m²"],
			"Deck crane": ["2 x SWL 3 t", "2 x SWL 5 t"],
			"Offshore crane": ["SWL 250 t", "3000 m"],
			"Bollard pull": ["354 t"],
			"AH drum": ["1 x 500 t"],
			"Towing drum": ["2 x 500 t"],
			"Chain locker": ["4 x 299 m³"],
			"Shark jaws": ["3 x 800 t"],
			"Towing pins": ["4 x 300 t"],
		},
		"Skandi Hugen": {
			"Vessel built": ["2013"],
			"Vessel design": ["STX", "MRV 05 ROV"],
			"Class definition": [
				"✠ 1A1 Offshore service",
				"vessel SPS Standby vessel",
				"Clean(Design) COMF(V-3) DK(+)",
				"DYNPOS(AUTR) E0 HL(2.8)",
				"NAUT(OSV(A)) OILREC SF",
			],
			LOA: ["82.2 m"],
			"Breadth mld": ["17.0 m"],
			"Summer draught": ["6.1 m"],
			Deadweight: ["2900 t"],
			Accommodation: ["46"],
			"Gross tonnage": ["4365"],
			"Main engines": ["2 x 2600 kW", "1 x 1665 kW"],
			"Bow thruster": ["2 x 880 kW"],
			"Azimuth thruster": ["1 x 1200 kW"],
			"Stern thruster": ["2 x 2200 kW"],
			"Deck dimension": ["570 m²"],
			"Deck strength": ["5 - 10 t/m²"],
			"Deck capacity": ["2600 t"],
			"Fuel oil": ["857 m³"],
			Mud: ["594 m³"],
			"Pot water": ["1123 m³"],
			"Base oil": ["415 m³"],
			"Dry bulk": ["305 m³"],
			"Drill water": ["1383 m³"],
			Brine: ["270 m³"],
			ORO: ["1604 m³"],
			"Offshore crane": ["SWL 15 t", "220 m"],
			"Offshore crane II": ["SWL 15 t", "220 m"],
			"NOFO compliant": ["Yes"],
		},
		"Skandi Iceman": {
			"Vessel built": ["2013"],
			"Vessel design": ["VARD AH12"],
			"Class definition": [
				"✠ 1A1 Fire fighter(I, II)",
				"Offshore service vessel(AHTS)",
				"SPS Clean(Design)",
				"COMF(C-3, V-3) DK(+)",
				"DYNPOS(AUTR) E0 HL(2.8)",
				"Ice(1B) NAUT(OSV(A))",
				"OILREC Recyclable SF TMON",
				"Winterized(Basic)",
			],
			LOA: ["93.5 m"],
			"Breadth mld": ["24.0 m"],
			"Summer draught": ["6.5 m"],
			Deadweight: ["4000 t"],
			Accommodation: ["45"],
			"Gross tonnage": ["8269"],
			"Main engines": ["2 x 6000 kW", "3 x 2850 kW"],
			"Bow thruster": ["2 x 1200 kW"],
			"Azimuth thruster": ["1 x 1200 kW"],
			"Stern thruster": ["2 x 1200 kW"],
			"Deck dimensions": ["780 m2"],
			"Deck strength": ["10 t/m2"],
			"Deck capacity": ["appr. 2300 t"],
			"Fuel oil": ["1429 m³", "(combined tanks 2047 m³)"],
			"Pot water": ["845 m³"],
			Mud: ["618 m³"],
			"Dry bulk": ["250 m³"],
			Brine: ["618 m³"],
			"Drill water": ["3462 m³"],
			"Bollard pull": ["319 t"],
			"AH drum": ["500 t"],
			"Towing drum": ["1 x 500 t", "1 x 450 t"],
			"Chain lockers": ["4 x 250 m³"],
			"Shark jaws": ["2 x 600 t"],
			"Towing pins": ["2 x 400 t"],
			"Deck crane": ["2 x SWL 5 t", "1 x SWL 12 t"],
			"A-frame": ["SWL 300 t"],
			"NOFO compliant": ["Yes (2009)"],
			"GMDSS zone": ["A4"],
		},
		"Skandi Iguacu": {
			"Vessel built": ["2012"],
			"Vessel design": ["STX AH12"],
			"Class definition": [
				"✠ 1A1 Supply vessel Tug",
				"Clean(Design) COMF(V-3)",
				"DK(+) DYNPOS(AUTR) E0",
				"NAUT(OSV(A)) SF TMON",
			],
			LOA: ["95.0 m"],
			"Breadth mld": ["24.0 m"],
			"Summer draught": ["7.8 m"],
			Deadweight: ["4700 t"],
			Accommodation: ["60"],
			"Gross tonnage": ["7099"],
			"Main engines": ["2 x 7680 kW"],
			"Bow thruster": ["2 x 1200 kW"],
			"Azimuth thruster": ["2 x 1500 kW"],
			"Stern thruster": ["1 x 1200 kW"],
			"Deck dimensions": ["750 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["3000 t"],
			"Fuel oil": ["1367 m³"],
			"Pot water": ["1077 m³"],
			"Bollard pull": ["357 t"],
			"AH drum": ["2 x 460 t"],
			"Towing drum": ["1 x 500 t"],
			"Chain locker": ["1078 m³"],
			"Shark jaws": ["2 x 800 t"],
			"Towing pins": ["2 x 400 t"],
			"Deck crane": ["2 x SWL 5 t", "1 x SWL 15 t"],
			"Torpedo AH frame": ["Yes"],
		},
		"Skandi Ipanema": {
			"Vessel built": ["2010"],
			"Vessel design": ["STX AH05"],
			"Class definition": ["✠ 1A1 Fire fighter(I) Tug DK(+)", "DYNPOS(AUT) E0 SF TMON"],
			LOA: ["74.3 m"],
			"Breadth mld": ["17.0 m"],
			"Summer draught": ["6.0 m"],
			Deadweight: ["2250 t"],
			Accommodation: ["38"],
			"Gross tonnage": ["2771"],
			"Main engines": ["2 x 4500 kW"],
			"Bow thruster": ["1 x 880 kW"],
			"Azimuth thruster": ["1 x 905 kW"],
			"Stern thruster": ["1 x 880 kW"],
			"Deck dimensions": ["654 m²"],
			"Deck strength": ["7.5 t/m²-10 t/m²"],
			"Deck capacity": ["1000 t"],
			"Fuel oil": ["850 m³"],
			"Pot water": ["741 m³"],
			"Dry bulk": ["216 m³"],
			Brine: ["549 m³"],
			"Base oil": ["266 m³"],
			"Drill water": ["660 m³"],
			"Bollard pull": ["174 t"],
			"AH drum": ["310 t"],
			"Towing drum": ["310 t"],
			"Chain locker": ["2 x 139 m³"],
			"Shark jaws": ["2 x Karmoy"],
			"Towing pins": ["4 x Karmoy", "350 mm", "270 t SWL"],
			"Deck crane": ["1 x SWL 5 t", "1 x SWL", "1.5 t"],
		},
		"Skandi Kvitsøy": {
			"Vessel built": ["2012"],
			"Vessel design": ["STX", "PSV 09 CD"],
			"Class definition": [
				"✠ 1A1 Clean(Design) COMF(V-3)",
				"DK(+) DYNPOS(AUTR) E0",
				"HL(2.8) LFL(*) NAUT(OSV(A))",
				"OILREC SF",
			],
			LOA: ["87.9 m"],
			"Breadth mld": ["19.0 m"],
			"Summer draught": ["6.6 m"],
			Deadweight: ["4700 t"],
			Accommodation: ["26"],
			"Gross tonnage": ["3958"],
			"Main engines": ["4 x 2095 kW"],
			"Bow thruster": ["2 x 880 kW"],
			"Azimuth thruster": ["1 x 880 kW"],
			"Stern thruster": ["2 x 2200 kW"],
			"Deck dimension": ["950 m²"],
			"Deck strength": ["5 t/m² - 10 t/m²"],
			"Deck capacity": ["2600 t"],
			"Fuel oil": ["1054 m³"],
			Mud: ["980 m³"],
			"Pot water": ["1002 m³"],
			"Base oil": ["189 m³"],
			Methanol: ["167 m³"],
			"Dry bulk": ["325 m³"],
			"Drill water": ["2836 m³"],
			Brine: ["980 m³"],
			Crane: ["SWL 1 t", "SWL 5 t"],
			"NOFO compliant": ["Yes"],
		},
		"Skandi Marøy": {
			"Vessel built": [" 2012"],
			"Vessel design": [" STX", "MRV 05 CD"],
			"Class definition": [
				"✠ 1A1 Fire fighter(I, II) Offshore",
				"service vessel Standby vessel",
				"Clean(Design) COMF(V-3) DK(+)",
				"DYNPOS(AUTR) E0 HL(2.8)",
				"NAUT(OSV(A)) OILREC SF",
			],
			LOA: [" 82.2 m"],
			"Breadth mld": [" 17.0 m"],
			"Summer draught": [" 6.3 m"],
			Deadweigh: [" 3588 t"],
			Accommodation: [" 28"],
			"Gross tonnage": [" 3588"],
			"Main engines": [" 2 x 2600 kW", "1 x 1665 kW"],
			"Bow thruster": [" 2 x 880 kW"],
			"Azimuth thruster": [" 1 x 1200 kW"],
			"Stern thruster": [" 2 x 2200 kW"],
			"Deck dimension": [" 600 m²"],
			"Deck strength": [" 5 - 10 t/m²"],
			"Deck capacity": [" 1650 t"],
			"Fuel oil": [" 1145 m³"],
			Mud: [" 537 m³"],
			"Pot water": [" 897 m³"],
			"Base oil": [" 276 m³"],
			"Dry bulk": [" 304 m³"],
			"Drill water": [" 1533 m³"],
			Brine: ["275 m³"],
			Crane: ["SWL 2 t", "SWL 3 t"],
			ORO: ["1547 m³"],
			"NOFO compliant": ["Yes"],
		},
		"Skandi Mongstad": {
			"Vessel built": ["2008"],
			"Vessel design": ["Vik-Sandvik", "PSV 495", "DEM"],
			"Class definition": [
				"✠ 1A1 Tug Supply Vessel",
				"Standby Vessel(S) Fire Fighter II",
				"OILREC SF LFL* COMF-V(3)C(3)",
				"E0 DYNPOS-AUTR Clean(Design)",
				"DK(+) HL(2.8) TMON",
			],
			LOA: ["96.9 m"],
			"Breadth mld": ["21.0 m"],
			"Summer draught": ["7.0 m"],
			Deadweight: ["4423 t"],
			Accommodation: ["24"],
			"Gross tonnage": ["4859"],
			"Main engines": ["2 x 1600 kW"],
			"Bow thruster": ["2 x 883 kW"],
			"Azimuth thruster": ["3 x 1200 kW"],
			"Stern thruster": ["1 x 590 kW"],
			"Deck dimension": ["1176 m²"],
			"Deck strength": ["10 m²"],
			"Deck capacity": ["3400 t"],
			"Fuel oil": ["1167 m³"],
			Mud: ["876.5 m³"],
			"Pot water": ["892 m³"],
			"Base oil": ["220 m³"],
			Methanol: ["203 m³"],
			"Dry bulk": ["366 m³"],
			"Drill water": ["2587 m³"],
			Brine: ["477 m³"],
			Crane: ["2 x SWL 10 t"],
			"NOFO compliant Standard": ["2005"],
		},
		"Skandi Niterói": {
			"Vessel built": ["2011"],
			"Vessel design": ["STX", "OSCV 06"],
			"Class definition": [
				"✠ 1A1 Clean(Design)",
				"COMF(C-3, V-3) Crane DK(+)",
				"DYNPOS(AUTRO) E0",
				"HELDK(S, H) SF",
			],
			LOA: ["142.2 m"],
			"Breadth mld": ["27.0 m"],
			"Summer draft": ["8.5 m"],
			Deadweight: ["9000 t"],
			Accommodation: ["120"],
			"Gross tonnage": ["15183"],
			"Main engines": ["2 x 3840 kW", "4 x 2 880 kW"],
			"Bow thruster": ["2 x 1900 kW"],
			"Azimuth thruster": ["2 x 1500 kW"],
			"Stern thruster": ["2 x 3700 kW"],
			"Deck dimension": ["2100 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["1725 t"],
			"Fuel oil": ["2219 m³"],
			"Pot water": ["1849 m³"],
			"Helideck dim": ["21 m diam,", "Sikorsky-92 compatible"],
			Moonpool: ["7.2 m x 7.2 m"],
			"ROV hangar": ["2 x TRITON", "XLX Vehicles", "Total Area = 243 m²"],
			"Deck crane": ["3 x SWL 3 t"],
			"Offshore crane": ["SWL 250 t", "2500 m"],
			"Offshore crane II": ["SWL 50/100 t", "2000 m"],
			Tower: ["OSLT 270 t", "VLS"],
		},
		"Skandi Nova": {
			"Vessel built": ["2012"],
			"Vessel design": ["STX", "MRV 05 SP"],
			"Class definition": [
				"✠ 1A1 Fire fighter(I, II)",
				"Offshore service vessel",
				"SPS Standby vessel",
				"Clean(Design) COMF(V-3) DK(+)",
				"DYNPOS(AUTR) E0 HL(2.8)",
				"NAUT(OSV(A)) SF",
			],
			LOA: ["82.2 m"],
			"Breadth mld": ["17.0 m"],
			"Summer draught": ["6.1 m"],
			Deadweight: ["3100 t"],
			Accommodation: ["46"],
			"Gross tonnage": ["3788"],
			"Main engines": ["2 x 2600 kW", "1 x 1665 kW"],
			"Bow thruster": ["2 x 880 kW"],
			"Azimuth thruster": ["1 x 1200 kW"],
			"Stern thruster": ["2 x 2200 kW"],
			"Deck dimension": ["600 m²"],
			"Deck strength": ["5 t/m²"],
			"Deck capacity": ["1150 t"],
			"Fuel oil": ["857 m³"],
			Mud: ["594 m³"],
			"Pot water": ["1116 m³"],
			"Base oil": ["415 m³"],
			"Dry bulk": ["302 m³"],
			"Drill water": ["1490 m³"],
			Brine: ["270 m³"],
			Crane: ["SWL 2 t", "SWL 3 t"],
		},
		"Skandi Olinda": {
			"Vessel delivery": ["2019"],
			"Vessel design": ["VARD 3 16"],
			"Class definition": [
				"✠ 1A1 E0 DYNPOS-AUTR",
				"DK (+) CRANE HELDK-SH BIS",
				"Clean(Design) NAUT-AW",
				"Comf-V(3) C(3) SPS ICS SF",
			],
			LOA: ["139.9 m"],
			"Breadth mld": ["28.0 m"],
			"Summer draught": ["8.5 m"],
			Deadweight: ["12513 t"],
			Accommodation: ["120"],
			"Gross tonnage": ["14957"],
			"Main engines": ["6 x 2880 kW"],
			"Bow thruster": ["2 x 2000 kW"],
			"Azimuth thruster": ["2 x 1800 kW"],
			"Stern thruster": ["2 x 3700 kW"],
			"Deck dimensions": ["1800 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["TBA"],
			"Fuel oil": ["1700 m³"],
			"Pot water": ["1200 m³"],
			Moonpool: ["7.2 m x 7.2 m"],
			Helideck: ["dim 22 m diam"],
			"Deck crane": ["3 x SWL 3 t", "1 x SWL 50 t"],
			"Offshore crane": ["50 t", "@ 3000 m water depth"],
			Laytower: [
				"340t tension",
				"fold-able tower, 48m min hight",
				"ab sea level, approx 60m max",
				"height. Tower: 2x fully open type",
				"tensioners, remotely operated",
				"alligner wheel and end handling",
				"system. 380t A&R winch,",
				"3000m water depth rated.",
				"Product capacity; 3x200t reels",
				"on deck + 2500t basket below",
				"deck.",
			],
		},
		"Skandi Olympia": {
			"Vessel built": ["2009"],
			"Vessel design": ["MT 6009 L"],
			"Class definition": [
				"✠ 1A1 Clean COMF(V-3)",
				"DK(+) DYNPOS(AUTR) E0",
				"HL(2.5) NAUT(OSV(A)) SF",
			],
			LOA: ["79.6 m"],
			"Breadth mld": ["16.4 m"],
			"Summer draught": ["6.2 m"],
			Deadweight: ["3700 t"],
			Accommodation: ["44"],
			"Gross tonnage": ["3131"],
			"Main engines": ["4 x 1424 kW"],
			"Bow thruster": ["2 x 800 kW"],
			"Azimuth thruster": ["2 x 1470 kW"],
			"Deck dimension": ["718 m²"],
			"Deck strength": ["5 t/m²"],
			"Deck capacity": ["1600 t"],
			"Fuel oil": ["1212 m³"],
			"Pot water": ["648 m³"],
			"Drill water": ["665 m³"],
			"Base oil": ["143 m³"],
			Brine: ["424 m³"],
			Methanol: ["205 m³"],
			"Dry bulk": ["320 m3", "5 x 64 m³"],
			"Deck crane": ["SWL 1.5 t", "SWL 3 t"],
		},
		"Skandi Paraty": {
			"Vessel delivery": ["2016"],
			"Vessel design": ["VARD AH11"],
			"Class definition": [
				"✠ 1A1 SF SUPPLY VESSEL TUG",
				"E0 DYNPOS-AUTR",
				"COMF-V(3) Clean(Design)",
				"NAUT-OSV (T) DK(+) TMON",
			],
			LOA: ["93.5 m"],
			"Breadth mld": ["22.0 m"],
			"Summer draught": ["7.9 m"],
			Deadweight: ["4981 t"],
			Accommodation: ["46"],
			"Gross tonnage": ["TBA"],
			"Main engines": ["2 x 4500 kW", "2 x 4000 kW"],
			"Bow thruster": ["1 x 880 kW"],
			"Azimuth thruster": ["2 x 880 kW"],
			"Stern thruster": ["1 x 880 kW"],
			"Deck dimensions": ["760 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["TBA"],
			"Fuel oil": ["1305 m³"],
			"Pot water": ["870 m³"],
			"Drill water": ["3240 m³"],
			"Bollard pull": ["288 t"],
			"AH drum": ["500 t"],
			"Towing drum": ["460 t"],
			"Chain locker": ["1100 m³"],
			"Shark jaws": ["2 x 800 t"],
			"Towing pins": ["2 x TBA"],
			"ROV hangar": ["Yes"],
			"Deck crane": ["3 x SWL 5 t"],
			"Torpedo AH frame": ["Yes"],
		},
		"Skandi Patagonia": {
			"Vessel built": ["2000"],
			"Vessel design": ["MT 6016"],
			"Class definition": [
				"✠ 1A1 DSV(III) Fire fighter(I)",
				"Tug DK(+) DYNPOS(AUTR) E0",
				"HELDK HL(2.8) SF",
			],
			LOA: ["93.3 m"],
			"Breadth mld": ["19.7 m"],
			"Summer draught": ["6.09 m"],
			Deadweight: ["3722 t"],
			Accommodation: ["59"],
			"Gross tonnage": ["4641"],
			"Main engines": ["2 x 1537 kW", "2 x 3655 kW"],
			"Stern thruster": ["2 x 3700 kW"],
			"Bow thruster": ["2 x 883 kW"],
			"Azimuth thruster": ["1 x 880 kW"],
			"Deck dimension": ["560 m²"],
			"Deck strength": ["5 t/m²"],
			"Deck capacity": ["2815 t"],
			"Fuel oil": ["1657 m³"],
			"Pot water": ["1012 m³"],
			"Drill water": ["1296 m³"],
			Brine: ["500 m³"],
			Mud: ["161 m³"],
			ORO: ["1100 m³"],
			"Helideck dim": ["14.3 m diam"],
			Moonpool: ["3.5 x 3.6 m"],
			"ROV hangar": ["108 m³"],
			"Deck crane": ["SWL 4 t", "SWL 5 t"],
			"Offshore crane": ["SWL 50 t", "1540 m"],
			"Diving bell": ["200 m, 3 pers"],
			"Bollard pull": ["128 t"],
			"AH drum": ["200 t"],
			Towing: ["drum 200 t"],
			"Chain locker": ["2 x 44 m³"],
			"Shark jaws": ["1 x 350 t"],
			"Towing pins": ["1 x 240 t"],
		},
		"Skandi Recife": {
			"Vessel built": ["2018"],
			"Vessel design": ["VARD 3 16"],
			"Class definition": [
				"✠ 1A1 E0 DYNPOS-AUTR",
				"DK (+) CRANE HELDK-SH BIS",
				"Clean(Design) NAUT-AW",
				"Comf-V(3) C(3) SPS ICS SF",
			],
			LOA: ["139.9 m"],
			"Breadth mld": ["28.0 m"],
			"Summer draught": ["8.5 m"],
			Deadweight: ["12513 t"],
			Accommodation: ["120"],
			"Gross tonnage": ["14957"],
			"Main engines": ["6 x 2880 kW"],
			"Bow thruster": ["2 x 2000 kW"],
			"Azimuth thruster": ["2 x 1800 kW"],
			"Stern thruster": ["2 x 3700 kW"],
			"Deck dimensions": ["1800 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["N/A"],
			"Fuel oil": ["1700 m³"],
			"Pot water": ["1200 m³"],
			Moonpool: ["7.2 m x 7.2 m"],
			"Helideck dim": ["22 m diam"],
			"Deck crane": ["3 x SWL 3 t", "1 x SWL 50 t"],
			"Offshore crane": ["50 t", "@ 3000 m water depth"],
			Laytower: [
				"340t tension,",
				"fold-able tower, 48m min hight",
				"ab sea level, approx 60m max",
				"height. Tower: 2x fully open type",
				"tensioners, remotely operated",
				"alligner wheel and end handling",
				"system. 380t A&R winch,",
				"3000m water depth rated.",
				"Product capacity; 3x200t reels",
				"on deck + 2500t basket below",
				"deck.",
			],
		},
		"Skandi Salvador": {
			"Vessel built": ["2009"],
			"Vessel design": ["STX ROV06"],
			"Class definition": [
				"✠ 1A1 ICE-C Fire Fighter II SF",
				"LFL* COMF-V(3) HELDK E0",
				"DYNPOS-AUTR DK(+) HL(2.8)",
			],
			LOA: ["105.9 m"],
			"Breadth mld": ["21.0 m"],
			"Summer draught": ["6.6 m max"],
			Deadweight: ["3600 t"],
			Accommodation: ["100"],
			"Gross tonnage": ["6802"],
			"Main engines": ["4 x 2780 kW"],
			"Bow thruster": ["2 x 1500 kW"],
			"Azimuth thruster": ["1 x 1500 kW"],
			"Main propulsion": ["2 x 3000 kW"],
			"Deck dimension": ["1000 m²"],
			"Deck strength": ["5 t/m² - 10 t/m²"],
			"Deck capacity": ["1200 t"],
			"Fuel oil": ["1292 m³"],
			"Pot water": ["628 m³"],
			"Drill water": ["3682 m³"],
			"Base oil": ["235 m³"],
			Brine: ["467 m³"],
			Mud: ["600 m³"],
			Ethanol: ["150 m³"],
			"Dry bulk": ["256 m³"],
			"Helideck dim": ["21 m diam,", "Sikorsky-92 compatible"],
			Moonpool: ["7.2 x 7.2 m"],
			"ROV hangar": ["168 m²"],
			"Deck crane": ["1 x SWL 3 t", "1 x SWL 25 t"],
			"Offshore crane": ["SWL 140 t", "2545 m"],
			"Offshore AHC Winch": ["SWL 250 t"],
		},
		"Skandi Seven": {
			"Vessel built": ["2008"],
			"Vessel design": ["STX", "OSCV 03"],
			"Class definition": [
				"✠ 1A1 Clean(Design)",
				"COMF(C-3, V-3) Crane",
				"DK(+) DYNPOS(AUTRO) E0",
				"HELDK(S, H) Ice(C) NAUT(AW) SF",
			],
			LOA: ["120.7 m"],
			"Breadth mld": ["23.0 m"],
			"Summer draught": ["7.0 m"],
			Deadweight: ["6000 t"],
			Accommodation: ["120"],
			"Gross tonnage": ["9074"],
			"Main engines": ["4 x 2880 kW"],
			"Bow thruster": ["2 x 1500 kW"],
			"Azimuth thruster": ["2 x 1900 kW"],
			"Stern thruster": ["2 x 3000 kW"],
			"Deck dimensions": ["1300 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["3150 t"],
			"Fuel oil": ["1864 m³"],
			"Pot water": ["796 m³"],
			"Helideck dim": ["20.9 m diam", "12.8 t"],
			Moonpool: ["(7.2 x 7.2 m)", "+ (5.5 x 5.5 m)"],
			"ROV hangar": ["208 m²"],
			"Deck crane": ["2 x SWL 3 t"],
			"Offshore crane": ["SWL 250 t", "2500 m"],
		},
		"Skandi Singapore": {
			"Vessel built": ["2011"],
			"Vessel design": ["AKER DSV 06"],
			"Class definition": [
				"✠ 1A1 DSV(SAT, Surface)",
				"Clean(Design) COMF(V-3) DK(+)",
				"DYNPOS(AUTR) E0 HELDK",
				"Ice(C) NAUT(AW)",
			],
			LOA: ["107.1 m"],
			"Breadth mld": ["21.0 m"],
			"Summer draught": ["6.6 m"],
			Deadweight: ["3729 t"],
			Accommodation: ["100"],
			"Gross tonnage": ["7386"],
			"Main engines": ["4 x 3150 kW"],
			"Bow thruster": ["3 x 1500 kW"],
			"Azimuth thruster": ["2 x 3000 kW"],
			"Deck dimension": ["900 m²"],
			"Deck strength": ["5 - 10 t/m²"],
			"Deck capacity": ["2000 t"],
			"Fuel oil": ["1153 m³"],
			"Pot water": ["807 m³"],
			"Helideck dim": ["21 m diam", "12 t"],
			Moonpool: ["(4.2 x 4.2 m)"],
			"ROV hangar": ["292 m²"],
			"Deck crane": ["1 x SWL 5.5 t", "1 x SWL 3 t"],
			"Offshore crane": ["SWL 140 t", "2300 m"],
			"Diving system": ["18 men", "saturated diving", "system installed"],
		},
		"Skandi Skansen": {
			"Vessel built": ["2011"],
			"Vessel design": ["STX AH04"],
			"Class definition": [
				"✠ 1A1 Supply vessel",
				"Tug Clean(Design) COMF(V-3)",
				"DK(+) DYNPOS(AUTR)",
				"E0 HELDK(S, H) HL(2.8)",
				"NAUT(OSV(A)) SF TMON",
			],
			LOA: ["107.2 m"],
			"Breadth mld": ["24.0 m"],
			"Summer draught": ["7.8 m"],
			Deadweight: ["4982 t"],
			Accommodation: ["90"],
			"Gross tonnage": ["8222"],
			"Main engines": ["2 x 7680 kW", "4 x 2600 kW"],
			"Bow thruster": ["2 x 1500 kW"],
			"Azimuth thruster": ["1 x 1500 kW"],
			"Stern thruster": ["2 x 1200 kW"],
			"Deck dimension": ["1070 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["3050 t"],
			"Fuel oil": ["2400 m³"],
			"Pot water": ["965 m³"],
			"Drill water": ["1200 m³"],
			"Helideck dim": ["20.88 m", "diam, 12.8 t"],
			Moonpool: ["7.2 x 7.2 m"],
			"ROV hangar": ["66 m2"],
			"Deck crane": ["4 x SWL 3 t", "2 x SWL 5 t"],
			"Offshore crane": ["SWL 250 t", "2600 m"],
			"Bollard pull": ["349 t"],
			"AH drum": ["1 x 500 t"],
			"Towing drum": ["2 x 500 t"],
			"Chain locker": ["4 x 300 m³"],
			"Shark jaws": ["3 x 800 t"],
			"Towing pins": ["4 x 300 t"],
			"A-Frame SWL": ["260 t", "(if mounted)"],
		},
		"Skandi Urca": {
			"Vessel built": ["2014"],
			"Vessel design": ["VARD AH11"],
			"Class definition": [
				"✠ 1A1 SF SUPPLY VESSEL",
				"TUG E0 DYNPOS-AUTR",
				"COMF-V(3) Clean(Design)",
				"NAUT-OSV (T) DK(+) TMON",
			],
			LOA: ["93.5 m"],
			"Breadth mld": ["22.0 m"],
			"Summer draught": ["7.9 m"],
			Deadweight: ["4600 t"],
			Accommodation: ["30"],
			"Gross tonnage": ["4300 t"],
			"Main engines": ["2 x 4500 kW", "2 x 4000 kW"],
			"Bow thruster": ["1 x 880 kW"],
			"Azimuth thruster": ["2 x 880 kW"],
			"Stern thruster": ["1 x 880 kW"],
			"Deck dimensions": ["760 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["1700 t"],
			"Fuel oil": ["1305 m³"],
			"Pot water": ["870 m³"],
			"Drill water": ["3240 m³"],
			"Bollard pull": ["260 t"],
			"AH drum": ["2 x 460 t"],
			"Towing drum": ["1 x 400 t"],
			"Chain locker": ["1100 m³"],
			"Shark jaws": ["2 x 800 t"],
			"Towing pins": ["2 x 400 t"],
			"Deck crane": ["3 x SWL 5 t"],
			"Torpedo AH frame": ["Yes"],
		},
		"Skandi Vega": {
			"Vessel built": ["2010"],
			"Vessel design": ["STX AH04"],
			"Class definition": [
				"✠ 1A1 Supply vessel Tug",
				"Clean(Design) COMF(V-3) DK(+)",
				"DYNPOS(AUTR) E0 HL(2.8)",
				"NAUT(OSV(A)) SF TMON",
			],
			LOA: ["109.5 m"],
			Breadth: ["mld 24.0 m"],
			"Summer draught": ["7.8 m"],
			Deadweight: ["5750 t"],
			Accommodation: ["88"],
			"Gross tonnage": ["8164"],
			"Main engines": ["2 x 7680 kW", "4 x 2600 kW"],
			"Bow thruster": ["2 x 1500 kW"],
			"Azimuth thruster": ["1 x 1.500 kW"],
			"Stern thruster": ["2 x 1200 kW"],
			"Deck dimensions": ["1.070 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["3050 t"],
			"Fuel oil": ["1.219 m³"],
			"Pot water": ["987 m³"],
			Mud: ["602 m³"],
			Brine: ["602 m³"],
			"Drill water": ["3500 m³"],
			"Moon pool": [" 7.2 x 7.2 m"],
			"ROV hangar": [" 68 m2"],
			"Bollard pull": ["353 t"],
			"AH drum": ["1 x 500 t"],
			"Towing drum": ["2 x 500 t"],
			"Chain lockers": ["4 x 299 m³"],
			"Shark jaws": ["3 x 600 t"],
			"Towing pins": ["6 x 300 t"],
			"AH frame": ["200 t /3.5 x", "4 m lift"],
			"Deck crane": ["1 x knucklelboom", "2 x Cargo roll", "1 x SWL 3 t"],
		},
		"Skandi Vinland": {
			"Vessel built": ["2017"],
			"Vessel design": ["VARD 3 08", "Multi Purpose Vessel"],
			"Class definition": [
				"✠ 1A1 Fire fighter(I) SPS BIS",
				"BWM(T) Clean(Design) COMF(C-3,V-3) ",
				"DK(+) DYNPOS(AUTR) E0",
				"Ice(1C) NAUT(OSV(A)) OILREC",
				"Recyclable SF",
			],
			LOA: ["93.2 m"],
			"Breadth mld": ["20.0 m"],
			"Summer draught": ["6.0 m"],
			Deadweight: ["2970 t"],
			Accommodation: ["55"],
			"Gross tonnage": ["5883"],
			"Main engines": ["2 x 1840 kW", "4 x 2420 kW"],
			"Bow thruster": ["2 x 880 kW"],
			"Azimuth thruster": ["2 x 880 kW"],
			"Stern thruster": ["2 x 3000 kW"],
			"Deck dimension": ["715 m²"],
			"Deck strength": ["10 t/m²"],
			"Deck capacity": ["1400 t"],
			"Fuel oil": ["1845 m³"],
			"Pot water": ["710 m³"],
			"Drill water": ["1680 m³"],
			"Brine (combined)": ["457 m³"],
			"Mud (combined)": ["457 m³"],
			"ORO (combined)": ["457 m³"],
			"MEG (combined)": ["457 m³"],
			"Dry bulk": ["243 m³"],
			"ROV hangar": ["1400 m³"],
			"Deck crane": ["2 x SWL", "3 t @ 12 m"],
			"Offshore crane": ["SWL 100 t", "500 m"],
			"Bollard pull": ["115 t"],
		},
		// NCT OFFSHORE
		Freja: {
			Built: ["2024"],
			"Length over all": ["95 m"],
			Beam: ["15 m"],
			Deadweight: ["5252 t"],
			"Gross Tonnage": ["8660"],
		},
		// OCEAN INFINITY
		"Armada 78 01": {
			Type: ["Armada A78"],
			"Owner/Operator": ["Ocean Infinity Group"],
			Flag: ["Singapore"],
			"Class definition": [
				"✠ 1A Clean COMF(C-3, V-3) DK(+)",
				"DYNPOS(AUTR) E0 NAUT(OSV)",
				"Recyclable SF ER(SCR)",
			],
			Built: ["2023 - Vard"],
			"Remote Control System": ["Vard"],
			"Regulatory Regime": ["Classed IMO (SOLAS, MARPOL)", "DNV Lean Manned TBC"],
			"Dynamic Positioning": ["MT DP2 (DP cap 9, 8, 7, 6)"],
			Length: ["78 m"],
			Beam: ["15 m"],
			"Min. Draft": ["5 m"],
			"Displacement (light/full)": ["2500 t/3500 t"],
			"Deck Area": ["690 m²"],
			"Deck Load": ["1050 t"],
			"Moonpool Size": ["Two 9 x 4 m"],
			"Gross Tonnage": ["2051 t"],
			"Economic Speed": ["8-10 Knots"],
			"Operational Range": ["Global"],
			Endurance: ["21-35 days depending on payload and operation"],
			"Communications Systems": [
				"VHF, point-to-point /",
				"point-to-multipoint radio, satellite",
				"communications, 4G/LTE",
			],
			"Survey Positioning": ["Veripos LD900 DGNSS"],
			"Survey Heading & Attitude": ["Sonardyne Sprint 500 INS"],
			"Single Beam Echosounders": ["N/A"],
			"Multi Beam Echosounders": ["Kongsberg EM124 2° x 2°", "(A7801/02 Only)"],
			"ADCP/DVL": ["Nortek Signature VM 100 ADCP/", "DVL (A7801/02 Only)"],
			Magnetometer: ["Subvision 12-Pin Gradiometer (Attached to Schilling HD)"],
			"ROV/AUV": [
				"Saab Seaeye Leopard ROV",
				"Schilling HD WROV, Saab eWROV",
				"Hugin 3000/6000",
			],
			"VSAT Antennas": [
				"2 x 1 m Ka-Band Antennas",
				"2 x 1.2 m Ku-Band Antennas with",
				"L-Band Back up",
			],
			"Installed Power (kW)": [
				"Available Power with Batteries: 6.5 mW",
				"Total Power with Batteries: 7 mW",
			],
			Batteries: ["2 x 565 kWh Corvus Orca Li-ion"],
		},
		"Armada 78 02": {
			Type: ["Armada A78"],
			"Owner/Operator": ["Ocean Infinity Group"],
			Flag: ["Singapore"],
			"Class definition": [
				"✠ 1A Clean COMF(C-3, V-3) DK(+)",
				"DYNPOS(AUTR) E0 NAUT(OSV)",
				"Recyclable SF ER(SCR)",
			],
			Built: ["2023 - Vard"],
			"Remote Control System": ["Vard"],
			"Regulatory Regime": ["Classed IMO (SOLAS, MARPOL)", "DNV Lean Manned TBC"],
			"Dynamic Positioning": ["MT DP2 (DP cap 9, 8, 7, 6)"],
			Length: ["78 m"],
			Beam: ["15 m"],
			"Min. Draft": ["5 m"],
			"Displacement (light/full)": ["2500 t/3500 t"],
			"Deck Area": ["690 m²"],
			"Deck Load": ["1050 t"],
			"Moonpool Size": ["Two 9 x 4 m"],
			"Gross Tonnage": ["2051 t"],
			"Economic Speed": ["8-10 Knots"],
			"Operational Range": ["Global"],
			Endurance: ["21-35 days depending on payload and operation"],
			"Communications Systems": [
				"VHF, point-to-point /",
				"point-to-multipoint radio, satellite",
				"communications, 4G/LTE",
			],
			"Survey Positioning": ["Veripos LD900 DGNSS"],
			"Survey Heading & Attitude": ["Sonardyne Sprint 500 INS"],
			"Single Beam Echosounders": ["N/A"],
			"Multi Beam Echosounders": ["Kongsberg EM124 2° x 2°", "(A7801/02 Only)"],
			"ADCP/DVL": ["Nortek Signature VM 100 ADCP/", "DVL (A7801/02 Only)"],
			Magnetometer: ["Subvision 12-Pin Gradiometer (Attached to Schilling HD)"],
			"ROV/AUV": [
				"Saab Seaeye Leopard ROV",
				"Schilling HD WROV, Saab eWROV",
				"Hugin 3000/6000",
			],
			"VSAT Antennas": [
				"2 x 1 m Ka-Band Antennas",
				"2 x 1.2 m Ku-Band Antennas with",
				"L-Band Back up",
			],
			"Installed Power (kW)": [
				"Available Power with Batteries: 6.5 mW",
				"Total Power with Batteries: 7 mW",
			],
			Batteries: ["2 x 565 kWh Corvus Orca Li-ion"],
		},
		"Armada 78 03": {
			Type: ["Armada A78"],
			"Owner/Operator": ["Ocean Infinity Group"],
			Flag: ["Singapore"],
			"Class definition": [
				"✠ 1A Clean COMF(C-3, V-3) DK(+)",
				"DYNPOS(AUTR) E0 NAUT(OSV)",
				"Recyclable SF ER(SCR)",
			],
			Built: ["2023 - Vard"],
			"Remote Control System": ["Vard"],
			"Regulatory Regime": ["Classed IMO (SOLAS, MARPOL)", "DNV Lean Manned TBC"],
			"Dynamic Positioning": ["MT DP2 (DP cap 9, 8, 7, 6)"],
			Length: ["78 m"],
			Beam: ["15 m"],
			"Min. Draft": ["5 m"],
			"Displacement (light/full)": ["2500 t/3500 t"],
			"Deck Area": ["690 m²"],
			"Deck Load": ["1050 t"],
			"Moonpool Size": ["Two 9 x 4 m"],
			"Gross Tonnage": ["2051 t"],
			"Economic Speed": ["8-10 Knots"],
			"Operational Range": ["Global"],
			Endurance: ["21-35 days depending on payload and operation"],
			"Communications Systems": [
				"VHF, point-to-point /",
				"point-to-multipoint radio, satellite",
				"communications, 4G/LTE",
			],
			"Survey Positioning": ["Veripos LD900 DGNSS"],
			"Survey Heading & Attitude": ["Sonardyne Sprint 500 INS"],
			"Single Beam Echosounders": ["N/A"],
			"Multi Beam Echosounders": ["Kongsberg EM124 2° x 2°", "(A7801/02 Only)"],
			"ADCP/DVL": ["Nortek Signature VM 100 ADCP/", "DVL (A7801/02 Only)"],
			Magnetometer: ["Subvision 12-Pin Gradiometer (Attached to Schilling HD)"],
			"ROV/AUV": [
				"Saab Seaeye Leopard ROV",
				"Schilling HD WROV, Saab eWROV",
				"Hugin 3000/6000",
			],
			"VSAT Antennas": [
				"2 x 1 m Ka-Band Antennas",
				"2 x 1.2 m Ku-Band Antennas with",
				"L-Band Back up",
			],
			"Installed Power (kW)": [
				"Available Power with Batteries: 6.5 mW",
				"Total Power with Batteries: 7 mW",
			],
			Batteries: ["2 x 565 kWh Corvus Orca Li-ion"],
		},
		// REMØY
		"Rem Eir": {
			Type: ["VS 4412 DF PSV"],
			Design: ["Wartsila Ship Design Norway AS"],
			Class: [
				"DnV, 1A1, OFFSHORE SERVICE VESSEL",
				"+, SUPPLY, SF, DYNPOS-AUTR, E0, GAS",
				"FUELLED, CLEAN DESIGN, COMF",
				"V(3)C(3), LFL*, NAUT OSV(A),",
				"DK(10t/m2) and HL(2,8), ESV-DP (HIL),",
				"ESV-PMS(HIL)",
			],
			Authorities: ["NOR"],
			Yard: ["Kleven Verft"],
			"Hull Yard": ["Kleven Verft"],
			"Keel Laying": ["13.08.2013"],
			Delivery: ["20.11.2014"],
			"DNV Id No.": ["32920"],
			"IMO No.": ["9668647"],
			"Signal Letters": ["LEEW"],
			"Cargo Deck": ["1090 m2"],
			Accommodation: ["26 persons"],
			"Length over all": ["92,50 m"],
			"Length between perpendiculares": ["84,90 m"],
			"Breadth Moulded": ["20,00 m"],
			"Depth Main Deck": ["9,00 m"],
			"Main Engines": ["Wartsila - 6L34DF", "2 x 2610 kW"],
			"Main Engines ": ["Wartsila - 6L20DF", "2 x 1056 kW"],
			"Emergency generator": ["Volvo Penta D9 MG RC/HCM", "216 ekW"],
			"Main Propulsion": ["Rolls-Royce - AZP 120 FP", "2 x 2450 kW"],
			"Retractable Thruster": ["Rolls-Royce - TCNS 4,5TM FP", "880 kW"],
			"Tunnel Thruster (Fwd)": ["Rolls-Royce - TT2000 DPN CP", "2 x 1200 kW"],
			"Deck Cranes": ["TTS", "2 x 3 T @ 13 m"],
			"Tugger Winches": ["Norwegian Deck Machinery", "2 x 15 T"],
			"Windl./Mooring Winches": ["Norwegian Deck Machinery", "2 x 17 T"],
			"Mooring Winches (aft)": ["Norwegian Deck Machinery", "2 x 12 T"],
			"LNG system/tank": ["Wartsila LNGPac", "230 m3"],
			Bolier: ["Ulmatec Pyro", "600 kW"],
			BWTS: ["Optimarin", "170 m3/h"],
			"Speed (approx)": ["15,5 kn"],
			"Gross Tonnage": ["5380 T"],
			"Net Tonnage": ["1886 T"],
			"Lightweight (approx)": ["4041 T"],
			"Deadweight at 7,35 m draft": ["approx. 5900 T"],
			"Deck Load": ["approx. 3200 T"],
			"Fuel Oil": ["1000 m3"],
			"Drill Water/Water Ballast": ["1800 m3"],
			"Fresh Water Cargo": ["1000 m3"],
			MUD: ["980 m3"],
			"Base Oil": ["150 m3"],
			Brine: ["830 m3"],
			Slop: ["50 m3"],
			"Dry Bulk (4 tanks)": ["330 m3"],
			Methanol: ["230 m3"],
			"Special Product": ["400 m3"],
			"DP System": ["Rolls-Royce Icon™"],
			"DE System": ["Wartsila"],
			IAS: ["Wartsila"],
			"Cargo Equipment": ["Allweiler"],
			HVAC: ["Novenco"],
			Separators: ["Alfa Laval"],
			"Navigation & Comm.": ["Hareid Marine Technology"],
			"Internal FiFi System": ["Autronica"],
		},
		// SOLSTAD
		"Normand Drott": {
			Built: ["2010"],
			Design: ["AH 12"],
			Flag: ["NOR"],
			LOA: ["95 m"],
			"Breadth Moulded": ["24 m"],
			Yard: ["Aker Yard Brattvåg"],
			"Main Class": [
				"✠ 1A1, SF, E0, DK(+), HL(2,8),",
				"TUG, SUPPLY VESSELl, COMF-V(3),",
				"CLEAN DESIGN, DYNPOS-AUTR,",
				"NAUT OSV(A), TMON",
			],
			"Classification Society": ["DNV GL"],
			"Dynamic Positioning": ["DP 2 from Kongsberg"],
			Accommodation: ["70 Persons"],
			"Bollard Pull": ["339 mt"],
			"Deck Dimensions": ["760,2 m² (36,2 m x 21 m)"],
			Deadweight: ["5000 mt"],
			"Total BHP": ["32792 BHP"],
			"ROV system": ["WROV"],
		},
		"Normand Frontier": {
			Built: ["2014"],
			Design: ["Vard 3 03"],
			Yard: ["VARD Aukra", "Yard No. 825"],
			Flag: ["NIS"],
			Owner: ["Solstad Maritime"],
			"Class definition": [
				"✠ 1A1, SF, E0, DK(+), COMF-V(3)C(3),",
				"SPS, CLEAN DESIGN, DYNPOS-AUTR,",
				"NAUT-AW, ICE-C, TMON, BIS,",
				"CRANE, HELDK-SH",
			],
			"Classification Society": ["DNV GL"],
			LOA: ["121 m"],
			"Breadth Moulded": ["23 m"],
			"Cargo Deck Area": ["1400 m²"],
			Accommodation: ["100 Persons", "(40 x 1 Person, 30 x 2 Persons)"],
			ROV: ["Yes"],
			DP: ["DP 2", "Kongsberg"],
			"Main Crane": ["1 x SWL 250 mt @ TBCm - Single fall - AHC - CT"],
		},
		"Normand Ocean": {
			Built: ["2014"],
			Design: ["MT 6022"],
			Flag: ["NIS"],
			"Class definition": [
				"✠ 1A1, SF, E0, DK(+), COMF-V(3)C(3),",
				"SPS, CLEAN DESIGN, DYNPOS-AUTRO,",
				"NAUT OSV(A), NAUT-AW, ICE-C,",
				"DEICE, HELDK-SH, Battery (Power),",
			],
			LOA: ["107.6 m"],
			"Breadth Moulded": ["22 m"],
			"Cargo Deck Area": ["1170 m²"],
			"Total Accommodation": ["90 Persons"],
			ROV: ["Yes"],
			Yard: ["Kleven Yard (no. 352)"],
			Classification: ["DNV GL"],
			DP: ["DP 2 (Kongsberg)"],
			Helideck: ["Diameter 26 m", "16mt Sikorsky 92"],
			"Draft (max)": ["7.15 m + 0m", "Skeg/Nozzle"],
			"Deck Cargo Capacity": ["1800 mt (CoG 1.0 m", "above main deck)"],
			"Deck Strength": ["10 mt/m²"],
			Deadweight: ["6000 mt (d=0 m)"],
			Moonpool: ["51.84 m² (7.2 m x 7.2 m)"],
			"ROV system": ["1 obs ROV, 2 WROV"],
			"Main Crane": [
				"1 x SWL 150 mt@ 13 m - Single fall - AHC - CT:N/A- Sealift:N/A",
				"Personnel lift: N/A - placed on Port Side",
				"Wire lenght: TBCm - Wire diameter: TBCmm",
			],
		},
		"Normand Vision": {
			Built: ["2014"],
			Design: ["Vard 3 06"],
			Flag: ["NIS"],
			Accommodation: ["140 Persons (70 x 1 Person, 35 x 2 Persons)"],
			LOA: ["15 7m"],
			"Breadth Moulded": ["27 m"],
			"Cargo Deck Area": ["2100 m²"],
			ROV: ["Yes"],
			Yard: ["VARD Søviknes / Yard No. 811"],
			"Main Class": [
				"✠ 1A1, SF, E0, DK(+), COMF-V(3)C(3),",
				"SPS, CLEAN DESIGN, DYNPOS-AUTRO,",
				"NAUT-AW, ICE-C, TMON,",
				"VIBR, BIS, CRANE, HELDK-SH",
			],
			"Classification Society": ["DNV GL"],
			"Dynamic Positioning": ["DP 3 from Kongsberg"],
			Helideck: ["Diameter21m, 15 mt Sikorsky 92"],
			Moonpool: ["41,04 m² (7,2 m x 5,7 m)"],
			"ROV system": ["2 WROV"],
			"Main Crane": ["SWL 400 mt - Single fall - AHC"],
			"Crane 2": ["SWL 50/100 mt @ 12 m - Single & double fall - AHC"],
		},
		// SØLVTRANS
		"Ronja Aurora": {
			"Length overall": ["76.96 m"],
			"Breadth Moulded": ["17.80 m"],
			"Depth in draft": ["5.90 m"],
			"Service speed": ["13 knots"],
			"Fish hold capacity": ["3000 m3"],
			Accommodation: ["12"],
			"Propulsion system/Gear": ["Brunvoll Volda"],
			Generators: ["2xCAT 3512 C", "Pon Power"],
			"Emergency generator": ["Scania DI 13 75 M", "Nogva Motorfabrikk"],
			"Side thrusters": ["1x600 kW, 2x500 kW", "Brunvoll"],
			"Steering gear": ["Kongsberg Maritime"],
			"Deck cranes": ["4xPalfinger", "Bergen Hydraulic"],
			"Anchor winch": ["Scan Hvide Sande"],
			"Anchor equipment": ["Fossen Shipping"],
			"MOB boat/cranes": ["Mare Safety"],
			"Interior works": ["R&M Ship Interior"],
			"Ventilation system": ["Copra Drift"],
			"Provisions cooling system": ["Multi Kulde Vest"],
			"Interior doors": ["Baggerød"],
			"Toilet facilities": ["Jets Vacuum"],
			"Galley equipment": ["Beha-Hedo Industrier"],
			"Helm seats": ["NorSap"],
			Furniture: ["Shipnor"],
			"Loading/Unloading system/sorting counter": ["MMC First Process"],
			"Fish counters": ["AquaScan", "MSD Animal Health"],
			"Water treatment equipment": ["Sterner"],
			"RSW system": ["PTG Frionordica"],
			"Lice filter": ["MossHydro"],
			Pumps: ["PG Flow Solutions", "Allweiler"],
			"Fire/Rescue equipment": ["Certex Norge"],
			"Electrical installations": ["Vik Elektro"],
			Electronics: ["Furuno", "O. Øverland"],
			"Exterior doors": ["Libra-Plast"],
			Windows: ["Husna"],
			"Valves/Pipes equipment": ["Robinet", "Brødrene Dahl"],
			"CO2 fire extinguishing": ["John. Con. Nor. avd. Tyco Mar."],
			Paint: ["Carboline/International", "Wisting"],
			"Tank valves": ["John Gjerde"],
			Actuators: ["Eltorque"],
			"Pipe/cable penetrations": ["Roxtec"],
			Typhon: ["R. Stahl Tranberg"],
			Filtration: ["Øwre-Johnsen"],
		},
		"Ronja Azul": {
			"Length overall": ["76.96 m"],
			"Breadth Moulded": ["17.80 m"],
			"Depth in draft": ["5.90 m"],
			"Service speed": ["13 knots"],
			"Fish hold capacity": ["3000 m3"],
			Accommodation: ["12"],
			"Main engines": ["Yanmar 6EY26W", "Maritime Motor"],
			"Gear/Propulsion system": ["Brunvoll Volda"],
			"Steering gear": ["Kongsberg"],
			"Auxiliary engines": ["CAT 3512 C", "Pon Power"],
			"Shore power unit": ["Scania DI 13 75 M", "Nogva Motorfabrikk"],
			"Side thrusters": ["2x500 kW, 1x600 kW", "Brunvoll"],
			Pumps: ["PG Flow Solutions", "Allweiler"],
			"CO2 fire extinguishing": ["Tyco"],
			"Deck cranes": ["4xPalfinger", "Bergen Hydraulic"],
			"Anchor winches": ["Scan"],
			"Anchor equipment": ["Fossen Shipping"],
			"MOB boat/cranes": ["Mare Safety"],
			"Loading/Unloading system": ["MMC First Process"],
			"Interior work": ["Norwegian Marine Interior"],
			"Ventilation system": ["Copra"],
			"Provision cooling system": ["Multi Kulde Vest"],
			"Internal doors": ["Baggerød", "Norwegian Marine Interior"],
			"Toilet system": ["Jets Vacuum"],
			Galley: ["Beha-Hedo Industrier"],
			Electronics: ["Vik Elektro", "O. Øverland"],
			"External doors": ["Libra-Plast"],
			Windows: ["Husna"],
			"Fire/Rescue equipment": ["Certex Norge"],
			"Fish counters": ["AquaScan/Vaki"],
			"Ozone/oxygen system/UV system": ["Sterner"],
			"RSW system": ["PTG FrioNordica"],
			"Lice filter": ["MossHydro"],
			"Painting systems": ["Carboline Norge"],
			"Valve/pipe equipment": ["Robinet", "Brødrene Dahl"],
			"Cable/pipe penetrations": ["Roxtec"],
			"Navigation/Communication systems": ["Furuno"],
			"SCR system": ["H+H Engineering & Service"],
			Furniture: ["ShipNor"],
			Typhoon: ["R. Stahl Tranberg"],
			"Windshield wipers": ["Exalto Decca Wiper Technologies"],
		},
		"Ronja Borøy": {
			"Length overall": ["92.66 m"],
			"Length p.p.": ["84.99 m"],
			"Breadth Moulded": ["22.4 m"],
			"Depth in draft": ["9.60 m"],
			"Service speed": ["12 knots"],
			"Fish hold capacity": ["6000 m3"],
			Accommodation: ["16"],
			"Gross tonnage": ["7651 t"],
			"Net tonnage": ["2296 t"],
			"Main engines": ["4xScania DI 13", "Scania CV"],
			"Propulsion thrusters": ["2xUSE 255 FP", "Kongsberg Maritime"],
			"Tunnel thrusters": ["2xTT1650 DP FP", "Kongsberg Maritime"],
			"Emergency generator": ["Scania DI 13", "Scania CV"],
			"Fish handling system": ["MMC First Process"],
			Cranes: ["4 stk.", "Red Rock"],
			"MOB boat/cranes": ["Red Rock"],
			Delousing: ["FLS Caligus R670", "Flatsetsund Engineering"],
			"Rudder house door": ["Libra-Plast"],
			Design: ["Salt Ship Design"],
			"Classification society": ["DNV"],
			"Pipe/cable penetrations": ["CSD Sealing Systems", "Roxtec"],
		},
		"Ronja Challenger": {
			"Length overall": ["69.86 m"],
			"Length p.p.": ["66.98 m"],
			"Breadth Moulded": ["12 m"],
			"Depth in draft": ["5.90 m"],
			"Service speed": ["12.6 knots"],
			"Fish hold capacity": ["1800 m3"],
			"Gross tonnage": ["1753 t"],
			"Main engines": ["8L20, 1600 kW", "Wärtsila"],
			"Auxiliary engines": ["Pon Power"],
			"Emergency/harbor generator": ["Nogva Motorfabrikk"],
			"Gear/propulsion equipment": ["Scana Volda"],
			Pumps: ["PG Flow Solutions", "Allweiler"],
			"Side thrusters": ["1x450 kW, 1x300 kW", "Brunvoll"],
			"Steering gear": ["Rolls Royce Marine"],
			"Maneuvering system": ["Scana Volda"],
			"Valve/pipe equipment": ["Robinet", "Brødrene Dahl"],
			"Bunkering solutions": ["Øwre-Johnsen"],
			"Deck cranes": ["Bergen Hydraulic"],
			"Anchor winch": ["Rapp Hydema"],
			"MOB boat/cranes": ["Mare Safety"],
			"Anchor equipment": ["Fossen Shipping"],
			"Exterior doors": ["Libra-Plast"],
			"Fire/Rescue equipment": ["Erling Haug"],
			"Vacuum pump system": ["Havyard MMC"],
			"Fish counters": ["AquaScan"],
			"Smolt counters": ["VAKI"],
			"Ozone system": ["Redox"],
			"UV system": ["Sterner"],
			"Louse filter": ["Salsnes Filter"],
			Interior: ["R&M Ship Interior"],
			"Helm seats": ["Alu-Design"],
			"Wardrobe/tool cabinets": ["SSG"],
			"Ventilation system": ["Wangsmo"],
			"Interior doors": ["Norac Baggerød"],
			Window: ["Bohamet Spolka Akcyjna"],
			"Provisions cooling system": ["Trondheim Kulde"],
			"Electrical installations": ["Vik Elektro"],
			Electronics: ["O. Øverland"],
			Paint: ["International/Westing"],
			"CO2 fire extinguishing": ["Wilhelmsen Technical Solutions"],
		},
		"Ronja Christopher": {
			"Length overall": ["69,96 m"],
			"Breadth Moulded": ["17.80 m"],
			"Depth in draft": ["5.90 m"],
			"Service speed": ["13 knots"],
			"Fish hold capacity": ["2500 m3"],
			"Main engines": ["Yanmar 6EY26W, 1900 kW", "Maritim Motor"],
			"Thrusters/Gear/Propulsion system": ["Brunvoll Volda"],
			"Auxiliary engines": ["Cat", "Pon Power"],
			"Shore/Emergency generator": ["DI 13 75 M", "Nogva Motorfabrikk"],
			"Steering gear": ["Kongsberg Maritime"],
			"Filtration lub oil/fuel": ["Øwre-Johnsen"],
			"Deck cranes": ["Bergen Hydraulic"],
			"Anchor winch": ["AS Scan"],
			"Sorting counter/Loading/Unload system": ["MMC First Process"],
			"Fish counters": ["AquaScan/Vaki"],
			"Smolt counter": ["Vaki"],
			"Ozone system/UV-system/Lice filter": ["Sterner"],
			"RSW system": ["PTG FrioNordica"],
			"Anchor equipment": ["Fossen Shipping"],
			"MOB boat/cranes": ["Maritime Partner"],
			Lights: ["Luminell"],
			Typhon: ["R. Stahl Tranberg"],
			"Navigation lights/search lights": ["Glamox"],
			Furnishing: ["R&M Ship Interior/Shipnor"],
			"Ventilation system": ["Wangsmo"],
			"Provision fridge system": ["Trondheim Kulde"],
			"Exterior doors": ["Libra-Plast"],
			"Interior doors": ["Baggerød/R&M Ship Interior"],
			"Interior lights": ["Glamox"],
			Windows: ["Bohamet"],
			"Toilet facilities": ["Jets"],
			"Windshield wipers": ["Decca Technology"],
			"Electrical installations": ["Vik Elektro"],
			Electronics: ["HG Teknikk"],
			Pumps: ["PG Flow Solutions"],
			"Div. pumps": ["Allweiler"],
			"Fire/Rescue equipment": ["Certex Norge"],
			Paint: ["Carboline/Multi Marine"],
			"Valves/Pipes equipment": ["Robinet"],
			"Valve equipment": ["Brødrene Dahl"],
			"Fire fighting system": ["Tyco Fire & Integrated Solutions"],
			Filter: ["Moss Hydro"],
			"Pipe/cable penetrations": ["Roxtec"],
			"Anti fouling": ["MPE Cathodic"],
			"Safety equipment": ["Viking Norsafe Life-Saving Euipment"],
		},
		"Ronja Evolution": {
			"Length overall": ["76,96 m"],
			"Breadth Moulded": ["5,90 m"],
			"Service speed": ["13 knots"],
			"Fish hold capacity": ["3000 m3"],
			Accommodation: ["12"],
			"Main engines": ["Yanmar 6EY26W", "Maritim Motor"],
			Gear: ["Brunvoll Volda"],
			"Propulsion system": ["Brunvoll Volda"],
			"Auxiliary engines": ["2xCAT 3512 C", "Pon Power"],
			"Emergency generator": ["Scania DI 13 75M", "Nogva Motorfabrikk"],
			"Steering gear": ["Kongsberg Maritime"],
			"Valves/Pipes equipment": ["Robinet", "Brødrene Dahl"],
			Hydraulics: ["Bergen Hydraulic"],
			"CO2 fire extinguishing": ["Tyco Fire & Integrated"],
			Pumps: ["PG Flow Solutions", "Allweiler"],
			"Side thrusters": ["Brunvoll"],
			"Deck cranes": ["Storm Cranes"],
			"Anchor winch": ["Scan"],
			"Anchor equipment": ["Fossen Shipping"],
			"MOB boat": ["Mare Safety"],
			"MOB boat cranes": ["Storm Cranes"],
			"Navigation lights": ["R. Stahl Tranberg"],
			"Search lights": ["R. Stahl Tranberg"],
			Typhon: ["R. Stahl Tranberg"],
			"Fish counters": ["Aquan Scan", "MSD Animal Health"],
			"Loading system": ["MMC First Process"],
			"Unloading system": ["MMC First Process"],
			"Ozone system": ["Sterner"],
			"Oxygen system": ["Sterner"],
			"RSW system": ["PTG Frionordica"],
			"UV-anlegg": ["Sterner"],
			"Lice filter": ["MossHydro"],
			Furnishing: ["R&M Ship Interior"],
			"Interior doors": ["R&M Ship Interior", "Baggerød"],
			"Ventilation system": ["Copra Drift"],
			"Provision fridge system": ["Multi Kulde Vest"],
			"Toilet facilities": ["Jets Vacuum"],
			"Helm seats": ["NorSap"],
			"Tank air valves": ["John Gjerde"],
			Actuators: ["Eltorque"],
			"Electrical installations": ["Vik Elektro"],
			Electronics: ["Furuno", "O. Øverland"],
			"Fire equipment": ["Certex Norge"],
			"Rescue equipment": ["Certex Norge"],
			"Exterior doors": ["Libra-Plast"],
			Windows: ["Karaca Gemi"],
			Paint: ["Carboline Norge"],
			"Pipe/cable penetrations": ["Roxtec"],
			Furniture: ["Shipnor"],
		},
		"Ronja Explorer": {
			"Length overall": ["69.96 m"],
			"Breadth Moulded": ["17.80 m"],
			"Depth in draft": ["5.90 m"],
			"Service speed": ["13 knots"],
			"Fish hold capacity": ["2500 m3"],
			"Main engines": ["Yanmar 6EY26W, 1900 kW", "Maritim Motor"],
			Gear: ["Brunvoll Volda"],
			"Propulsion system": ["Brunvoll Volda"],
			"Auxiliary engines": ["Cat,Pon Power"],
			Thrusters: ["Brunvoll"],
			"Steering gear": ["Kongsberg Maritime"],
			"Filtration lub oil/fuel": ["Øwre-Johnsen"],
			"Deck cranes": ["Bergen Hydraulic"],
			"Anchor winch": ["Adria Winch"],
			"Loading/Unloading system": ["MMC First Process"],
			"Fish counters": ["AquaScan/Vaki"],
			"Smolt counter": ["Vaki"],
			Sorteringsteller: ["MMC First Process"],
			"Ozone system": ["Sterner"],
			"RSW system": ["FrioNordica"],
			"UV-system/Lice filter": ["Sterner"],
			"Anchor equipment": ["Fossen Shipping"],
			"MOB boat/cranes": ["Maritime Partner"],
			Furnishing: ["R&M Ship Interior"],
			"Ventilation system": ["Wangsmo"],
			"Provision fridge system": ["Trondheim Kulde"],
			"Exterior doors": ["Libra-Plast"],
			"Interior doors": ["Baggerød/R&M Ship Interior"],
			Windows: ["Bohamet"],
			"Toilet facilities": ["Jets"],
			"Electrical installations": ["Vik Elektro"],
			Electronics: ["Furuno Norge", "HG Marine Electronics"],
			Pumps: ["PG Flow Solutions/Allweiler"],
			"Fire/Rescue equipment": ["Certex Norge"],
			Paint: ["Multi Maritime/Carboline"],
			"Valves/Pipes equipment": ["Robinet/Brødrene Dahl"],
			Financing: ["Nordea"],
			"Fire fighting system": ["Tyco Fire & Integrated Solutions"],
		},
		"Ronja Harvester": {
			"Length overall": ["68.00 m"],
			"Length p.p.": ["62.72 m"],
			"Breadth Moulded": ["14.00 m"],
			"Depth in draft": ["6.30 m"],
			"Service speed": ["14 knots"],
			"Fish hold capacity": ["1900 m3"],
			"Gross tonnage": ["2057 t"],
			"RSW system": ["2 x 600.000 kcal/t", "Aquaterm"],
			"Main engines": ["Caterpillar 3516TAHD 2610 HP, 1600 o/min.", "Pon Power"],
			"Generator set": ["Caterpillar 3512TA B, 1375 kW", "Pon Power"],
			"Shore generator": ["Caterpillar C9TA, 170 kW", "Pon Power"],
			"Shaft generator": ["1500 kW", "Newage Norge"],
			"Gear/Propulsion equipment": ["Finnøy Gear & Propeller"],
			"Steering gear": ["Type Tenfjord", "Rolls-Royce avd. Tennfjord"],
			"Maneuvering system": ["Finnøy Gear & Propeller/ Scana Mar-El"],
			Pumps: ["Ing. Per Gjerdrum"],
			"Hoses, pipes, couplings, flanges": ["GS-Hydro"],
			Electronics: ["O. Øverland"],
			"Ventilation system": ["Wangsmo"],
			"Ozone system": ["Redox"],
			"CO2 system": ["Heien-Larssen"],
			"Fire/Rescue equipment": ["Erling Haug"],
			"Valve equipment": ["Ahlsell/Ålesund Armatur"],
			"Anchor winch": ["Rapp Hydema"],
			"Deck cranes": ["Bergen Hydraulic"],
			"Vacuum pump system": ["Stranda Prolog"],
			"Fish counters": ["AquaScan"],
			"Anchor equipment": ["Fossen Shipping"],
			"Mob boat": ["Erling Haug"],
			Lifeboats: ["Viking", "Mare Safety"],
			"Windshield wipers": ["Servi Motion Control"],
			Windows: ["Zaklad Produkcyjny Bohamet"],
			"Electrical installations": ["Vik Elektro"],
			Furnishing: ["R&M Ship Interior"],
			Furniture: ["Troldmyr Møbelfabrikk/Vad"],
			"Exterior doors": ["Libra-Plast"],
			"Interior doors": ["Norac Baggerød"],
			Paint: ["International, Roja"],
		},
		"Ronja Havsøy": {
			"Length overall": ["84.46 m"],
			"Breadth Moulded": ["16.00 m"],
			"Depth in draft": ["8.30 m"],
			"Service speed": ["11 knots"],
			"Fish hold capacity": ["3900 m3"],
			Accommodation: ["12"],
			"Fuel oil": ["326 m3"],
			"Fresh water": ["770 m3"],
			"Ballast water": ["975 m3"],
			"Main engines": ["Yanmar"],
			"Electric engines": ["Elkon"],
			"Propulsion system": ["Schottel"],
			"Shore/Emergency generator": ["Scania", "Margen"],
			Thrusters: ["Schottel"],
			"Starters side thrusters/Batteries": ["Elkon"],
			Cranes: ["Red Rock"],
			"Deck machinery": ["Adria Winch"],
			"RSW system": ["PTG FrioNordica"],
			"Freezing facility": ["PTG Kuldeteknisk"],
			"Fish handling system": ["Cflow/Redox"],
			"Fish counters": ["Aquascan"],
			"MOB boat/cranes": ["Red Rock"],
			"Smolt counter": ["Vaki"],
			Lifeboats: ["RFD"],
			"Navigation lights": ["R. Stahl Tranberg"],
			"Search lights": ["Glamox/Norselight"],
			"Flood light/bow light": ["Luminell"],
			Furnishing: ["Domgusel"],
			"Panels/doors": ["HBM"],
			Insulation: ["Paroc"],
			"Helm seats": ["Alu Design"],
			"Ventilation system": ["Heinen Hopman"],
			Osmose: ["Norwater"],
			"Vacuum toilet": ["Jets"],
			"Exterior doors": ["Libra Plast"],
			"Ballast water treatment": ["Optimarin"],
			CCTV: ["Elektronix"],
			Intercom: ["Zenitel"],
			"Navigation system": ["Furuno Norge", "Ozsay Deniz"],
			"Bridge consoles": ["Elkon"],
			Scrubber: ["Gesab"],
			"HP washers": ["HL. Skjong"],
			"Air receiver": ["Maskinspecialisten"],
			Paint: ["Jotun"],
			"Drum filter": ["CM Aqua Technologyies"],
			"Hospital equipment": ["Shipmed"],
		},
		"Ronja Herøy": {
			"Length overall": ["92.66 m"],
			"Breadth Moulded": ["22.40 m"],
			"Service speed": ["12 knots"],
			"Fish hold capacity": ["6000 m3"],
			Accommodation: ["16"],
			"Gross tonnage": ["7651 t"],
			"Net tonnage": ["2296 t"],
			"Main generators": ["4xDI 13", "Scania CV"],
			"Propulsion thrusters": ["2xUSE 255 FP", "Kongsberg Maritime"],
			"Side thrusters": ["2xTT1650 DP FP", "Kongsberg Maritime"],
			"Emergency generator": ["DI 13", "Scania CV"],
			"Fish handling system": ["MMC First Process"],
			"MOB boat/cranes": ["Red Rock"],
			"Cable/pipe penetrations": ["Roxtec", "CSD Sealing Systems"],
			"Ship design": ["Salt Ship Design"],
			"Classification society": ["DNV"],
			"Navigation lights": ["R. Stahl Tranberg"],
			"Rudder house door": ["Libra-Plast"],
			Delousing: ["FLS Caligus R670", "Flatsetsund Engineering"],
		},
		"Ronja Mistral": {
			"Length overall": ["76.96 m"],
			"Breadth Moulded": ["17.80 m"],
			"Depth in draft": ["5.90 m"],
			"Service speed": ["13 knots"],
			"Fish hold capacity": ["3000 m3"],
			Accommodation: ["12"],
			"Main engines": ["6EY26W", "Yanmar Europe", "Maritim Motor"],
			"Propulsion system/gear": ["Brunvoll Volda"],
			Generators: ["2xCAT 3512 C", "Pon Power"],
			"Emergency generator": ["Scania DI 13 75 M", "Nogva Motorfabrikk"],
			"Side thrusters": ["1x600 kW, 2x500 kW", "Brunvoll"],
			"Steering gear": ["Kongsberg Maritime"],
			"Deck cranes": ["4xPalfinger", "Bergen Hydraulic"],
			"Anchor winch": ["Scan"],
			"Anchor equipment": ["Fossen Shipping"],
			"MOB boat/cranes": ["Mare Safety"],
			"Interior works": ["R&M Ship Interior"],
			"Ventilation system": ["Copra Drift"],
			"Provisions cooling system": ["Multi Kulde Vest"],
			"Interior doors": ["Baggerød"],
			"Toilet facilities": ["Jets Vacuum"],
			"Galley equipment": ["Beha-Hedo Industrier"],
			"Helm seats": ["NorSap"],
			"Loading/Unloading system/Sorting counter": ["MMC First Process"],
			"Fish counters": ["AquaScan", "MSD Animal Health"],
			"Ozone/oxygen/UV system": ["Sterner"],
			"RSW system": ["PTG Frionordica"],
			"Lice filter": ["MossHydro"],
			Pumps: ["PG Flow Solutions", "Allweiler"],
			"Fire/Rescue equipment": ["Certex Norge"],
			"Electrical installations": ["Vik Elektro"],
			Electronics: ["O. Øverland"],
			"Exterior doors": ["Libra-Plast"],
			Windows: ["Husna"],
			"Valves/Pipes equipment": ["Robinet", "Brødrene Dahl"],
			"CO2 fire extinguishing": ["Tyco Fire & Integrated Solutions"],
			Paint: ["Carboline/International", "Wisting"],
			Actuators: ["Eltorque"],
			"Tank valves": ["John Gjerde"],
			"Pipe/cable penetrations": ["Roxtec"],
			Furniture: ["Shipnor"],
		},
		"Ronja Princess": {
			"Length overall": ["76.96 m"],
			"Breadth Moulded": ["17.80 m"],
			"Depth in draft": ["5.90 m"],
			"Service speed": ["13 knots"],
			"Fish hold capacity": ["3000 m3"],
			Accommodation: ["12"],
			"Main engines": ["Yanmar 6EY26W 1920kW", "Maritime Motor"],
			"Auxiliary engines": ["2xCAT 3512 C 1550ekW", "Pon Power"],
			"Gear/Propulsion system": ["Brunvoll Volda"],
			"Shore power unit": ["Scania DI 1375M 349ekW", "Nogva Motorfabrikk"],
			"Side thrusters": ["1x600kW, 2x250kW", "Brunvoll"],
			Pumps: ["PG Flow Solutions/Allweiler"],
			"Steering gear": ["Kongsberg Maritime"],
			"MOB boat/cranes": ["Mare Safety"],
			"Deck cranes": ["Palfinger", "Bergen Hydraulic"],
			"Anchor winch": ["Scan"],
			Anchor: ["Fossen Shipping"],
			"Loading/Unloading system": ["MMC First Process"],
			"Fish counters": ["AquaScan", "Vaki"],
			"Sorting counters": ["MMC First Process"],
			"Interior work": ["Norwegian Marine Interior", "ShipNor"],
			"Ventilation system": ["Copra"],
			"Internal doors": ["Baggerød"],
			"Toilet system": ["Jets Vacuum"],
			Galley: ["Beha-Hedo Industrier"],
			"Windshield wipers": ["Exalto Decca Wiper Technologies"],
			"Electrical installations": ["Vik Elektro"],
			Electronics: ["Furuno", "O. Øverland"],
			"Fire/Rescue equipment": ["Certex Norge"],
			"Provision cooling system": ["GK Inneklima"],
			"External doors": ["Libra-Plast"],
			"Ozone/UV system": ["Sterner"],
			"RSW system": ["PTG FrioNordica"],
			"Lice filter": ["MossHydro"],
			Painting: ["Carboline"],
			"Valve/pipe equipment": ["Robinet", "Brødrene Dahl"],
			"CO2 fire extinguishing": ["Tyco"],
			"SCR system": ["H+H Engineering & Service"],
			"Pipe/cable penetrations": ["Roxtec"],
			"Helm seats": ["NorSap"],
		},
		"Ronja Queen": {
			"Length overall": ["76.96 m"],
			"Breadth Moulded": ["17.80 m"],
			"Depth in draft": ["5.90 m"],
			"Service speed": ["13 knots"],
			"Fish hold capacity": ["3000 m3"],
			Accommodation: ["12"],
			"Gear/Propulsion system": ["Brunvoll Volda"],
			"Auxiliary engines": ["2xCAT 3512 C", "Pon Power"],
			"Emergency generator": ["Scania DI 13 75 M", "Nogva Motorfabrikk"],
			"Side thrusters": ["3 stk.", "Brunvoll"],
			"Steering gear": ["Kongsberg Maritime"],
			Pumps: ["PG Flow Solutions/Allweiler"],
			Cranes: ["4xPalfinger", "Bergen Hydraulic"],
			"MOB boat/kran": ["Mare Safety"],
			"Anchor winch": ["Scan"],
			"Anchor equipment": ["Fossen Shipping"],
			"Fish counters": ["Aqua Scan/MSD"],
			Furnishing: ["R&M Ship Interior"],
			"Ventilation system": ["Copra"],
			"Provision fridge system": ["Multi Kulde Vest"],
			"Interior doors": ["Baggerød"],
			"Toilet facilities": ["Jets Vacuum"],
			"Galley equipment": ["Beha-Hedo Industrier"],
			"Helm seats": ["NorSap"],
			"Loading/Unloading system": ["MMC First Process"],
			"Oxygen/UV system": ["Sterner"],
			"RSW system": ["PTG Frionordica"],
			"Lice filter": ["Mosshydro"],
			Actuators: ["Eltorque"],
			"Electrical installations": ["Vik Elektro"],
			Electronics: ["Furuno", "O. Øverland"],
			"Fire/Rescue equipment": ["Certex Norge"],
			"Exterior doors": ["Libra-Plast"],
			Windows: ["Husna"],
			Paint: ["Carboline Norge"],
			"Valves/Pipes equipment": ["Robinet/Brødrene Dahl"],
			"CO2 fire extinguishing": ["Tyco Fire & Integrated Soltutions"],
			"Pipe/cable penetrations": ["Roxtec"],
			"Air vents": ["John Gjerde"],
			"Lice treatment facility": ["Flatsetsund Engineering"],
			Filtration: ["HDU 27/108, PTU1 27/108", "Øwre Johnsen"],
		},
		"Ronja Silver": {
			"Length overall": ["92.50 m"],
			"Breadth Moulded": ["20.00 m"],
			"Service speed": ["11 knots"],
			"Fish hold capacity": ["5000 m3"],
			Accommodation: ["14"],
			"Gross tonnage": ["5000 t"],
			"Deadweight tonnage": ["5350 t"],
			"Main engines": ["2xC25:33 L9A", "Bergen Engines"],
			"Propulsion system": ["Promas", "Konsgberg Maritime"],
			"Propulsion motor": ["Marelli B5J", "Kongsberg Maritime"],
			Gear: ["RG-0650GDC", "Kongsberg Maritime"],
			"Steering gear": ["SR 662 FCP", "Kongsberg Maritime"],
			Thrusters: ["2xTT 1650 DPN FP", "Kongsberg Maritime"],
			"Shore/Emergency generator": ["Scania DI139 1M", "Nogva Motorfabrikk"],
			"Rack coolers": ["Hydroniq"],
			"Deck cranes": ["4 stk.", "Storm Cranes"],
			Winches: ["Adria Winch"],
			Walkway: ["Gurskøy"],
			"Free-fall lifeboat": ["Viking Norsafe Life-Saving Equipment"],
			"MOB boat": ["Mare Safety"],
			"HVAC system": ["Aeron"],
			"Toilet system": ["Jets Vacuum"],
			"Galley equipment": ["Mare Safety"],
			Furniture: ["ShipNor"],
			"RSW system": ["PTG Frionordica"],
			"Fish handling system": ["MMC First Process"],
			"IAS/PM": ["K-Chief", "Kongsberg Maritime"],
			"Paint systems": ["Carboline"],
			"Electrical installations": ["Hareid Group"],
			"Fire fighting system": ["John. Con. Nor. avd. Tyco Mar."],
			Actuators: ["Eltorque"],
			"Hull yard": ["Hat-San"],
			"Classification society": ["DNV"],
			"Helm seats": ["NorSap"],
			"Pipe/cable penetrations": ["Roxtec"],
			Typhon: ["R. Stahl Tranberg"],
			"Fire detection": ["John. Con. Nor. avd. Tyco Mar."],
			"Pipe system": ["Pipe Systems Engineering", "Tomrefjord Rør og Montering"],
			"Safety equipment": ["Viking Norsafe Life-Saving Equipment"],
		},
		"Ronja Star": {
			"Length overall": ["87.10 m"],
			"Breadth Moulded": ["18.00 m"],
			"Service speed": ["12 knots"],
			"Fish hold capacity": ["4175 m3"],
			Accommodation: ["12"],
			"Gross tonnage": ["5000 t"],
			"Deadweight tonnage": ["5350 t"],
			"Main engines": ["3xPon Cat 3512C", "Pon Power"],
			"Propulsion engines": ["2x1499 kW", "Kongsberg Maritime"],
			Gear: ["650GHC-V-SC500", "Kongsberg Maritime"],
			"Steering gear": ["SR662 FCP", "Kongsberg Maritime"],
			Thrusters: ["2xTT1650FP", "Kongsberg Maritime"],
			"Shore/Emergency generator": ["Scania DI1375M", "Nogva Motorfabrikk"],
			"Rack coolers": ["Hydroniq"],
			"Deck cranes": ["4 stk.", "Storm Cranes"],
			Winches: ["Kongsberg Maritime"],
			"Fish handling system": ["MMC First Process"],
			Walkway: ["Gurskøy"],
			"MOB boat": ["Mare Safety"],
			"IAS-/PMS-system": ["Kongsberg Maritime"],
			"Electrical installations": ["HG Teknikk"],
			"Navigation/Communication systems": ["Furuno", "HG Teknikk"],
			"HVAC system": ["AF Gruppen"],
			"Toilet system": ["Jets Vacuum"],
			"RSW system": ["PTG Frionordica"],
			"Galley equipment": ["Mare Safety"],
			"Pipe/cable penetrations": ["Roxtec"],
			"Fire detection/Sprinkler extinguishing system": ["Tyco"],
			"Exterior/Interior paint": ["Carboline"],
			"Classification society": ["DNV"],
			Design: ["Kongsberg Maritime"],
			Actuators: ["Eltorque"],
			Furnishing: ["ShipNor"],
			Typhon: ["R. Stahl Tranberg"],
			"Pipe installations": ["Pipe Systems Engineering"],
		},
		"Ronja Storm": {
			"Length overall": ["116.00 m"],
			"Breadth Moulded": ["3.00 m"],
			"Fish hold capacity": ["7450 m3"],
			"Fuel oil": ["410 m3"],
			"Fresh water": ["4733 m3"],
			"Grey water": ["12.7 m3"],
			"Generator set": ["Caterpillar", "Pon Power"],
			"Main engines": ["4xCaterpillar 3516C", "Pon Power"],
			Generators: ["4xNegr 500 MC4", "NES"],
			"Emergency generator": ["Caterpillar 7.1", "Pon Power"],
			"Propulsion system/Thrusters": ["Kongsberg Maritime"],
			"FO pumps": ["Allweiler"],
			"FO separators": ["Øwre-Johnsen"],
			Coolers: ["Sperre"],
			Cranes: ["Triplex MacGregor"],
			"Lifeboats Cranes/Winches": ["Palfinger Marine"],
			"Fish handling system": ["MMC First Process"],
			"MOB boat/cranes": ["Maritime Partner"],
			Lifeboats: ["Palfinger Ned-Deck"],
			"Rescue equipment": ["Viking Norsafe Life-Saving Equipment"],
			"Navigation lights": ["R. Stahl Tranberg"],
			"HP washers": ["HL Skjong"],
			"Anchor equipment": ["4050 kg", "Fossen Trading"],
			"Furnishing/Galley equipment": ["Beha Hedo Ind.", "Maritime Montering"],
			"Ventilation system": ["Aeron"],
			"Exterior doors": ["Libra Plast"],
			"Interior doors": ["IMS"],
			Windows: ["Bohamet"],
			"Windshield wipers": ["Servi"],
			Septikpumper: ["Ahlsell"],
			"Fire alarm": ["Autronica"],
			"Navigation/Communication systems": ["Furuno Norge", "Norwegian Control Systems"],
			"Internal communication": ["Jotron"],
			"IAS/EMS/Transformers": ["Norwegian Electric Systems"],
			"Fire equipment": ["Survitec"],
			Paint: ["Carboline/Multi Marine"],
			"ICCP/ICAF": ["MPE Cathodic"],
			"Fire detection/water mist extinguishing system": ["Tyco Fire & Integrated Sys."],
			"Pipe system installation": ["Fjordpipe"],
			"Pipe/cable penetrations": ["Roxtec"],
			Pumps: ["Allweiler"],
		},
		"Ronja Supporter": {
			"Length overall": ["69.86 m"],
			"Length p.p.": ["66.98 m"],
			"Breadth Moulded": ["12.00 m"],
			"Depth in draft": ["5.90 m"],
			"Service speed": ["12.6 knots"],
			"Fish hold capacity": ["1800 m3"],
			"Gross tonnage": ["1753 t"],
			"Main engines": ["Wärtsila Finland Oy"],
			"Auxiliary engines": ["Pon Power"],
			"Emergency/harbor generator": ["Nogva Motorfabrikk"],
			"Gear/propulsion system/maneuvering": ["Scana Volda"],
			"Side thrusters": ["Brunvoll"],
			"Steering gear": ["Rolls-Royce Marine"],
			Pumps: ["PG Flow Solutions"],
			"Deck cranes": ["Bergen Hydraulic"],
			"Anchor winch": ["Rapp Hydema"],
			"Vacuum pump system": ["Havyard MMC"],
			"Fish counters": ["AquaScan"],
			"Ozone system": ["Redox"],
			"UV system/louse filter": ["Sterner"],
			"MOB boat/cranes": ["Mare Safety"],
			"Anchor equipment": ["Fossen Shipping"],
			"LED floodlights": ["Luminell"],
			"Smolt counters": ["Vaki"],
			Interior: ["R&M Ship Interior"],
			"Helm seats": ["Alu Design"],
			"Ventilation system": ["Wangsmo"],
			"Interior doors": ["Baggerød"],
			"Exterior doors": ["Libra Plast"],
			Window: ["Bohamet Spolka Akcyjna"],
			"Provisions cooling system": ["Trondheim Kulde"],
			"Cable/pipe penetrations": ["Roxtec"],
			"Electrical installations": ["Vik Elektro"],
			Electronics: ["Furuno Norge", "O. Øverland"],
			"Fire/Rescue equipment": ["Erling Haug"],
			Paint: ["International/Westing"],
			"Valve/pipe equipment": ["Robinet", "Brødrene Dahl"],
			"CO2 fire extinguishing": ["Wilhelmsen Technical Solutions"],
			"Windscreen wipers": ["Decca Straight Line", "Decca"],
			"Oil filters": ["Øwre-Johnsen"],
		},
		"Ronja Vita": {
			"Length overall": ["76.96 m"],
			"Breadth Moulded": ["17.80 m"],
			"Service speed": ["13 knots"],
			"Fish hold capacity": ["3000 m3"],
			Accommodation: ["12"],
			"Main engines": ["Yanmar 6EY26W", "Maritim Motor"],
			Gear: ["Brunvoll Volda"],
			"Propulsion system": ["Brunvoll Volda"],
			"Auxiliary engines": ["2xCAT 3512 C", "Pon Power"],
			"Emergency generator": ["Scania DI 13 75 M", "Nogva Motorfabrikk"],
			"Side thrusters": ["Brunvoll"],
			Pumps: ["PG Flow Solutions", "Allweiler"],
			"Steering gear": ["Kongsberg Maritime"],
			"Valves/Pipes equipment": ["Robinet", "Brødrene Dahl"],
			Dieselseparator: ["Øwre-Johnsen"],
			"Fire fighting engine room": ["Johnson Controls Norway"],
			"MOB boat": ["Mare Safety"],
			"MOB boat cranes": ["Storm Cranes"],
			"Anchor winch": ["Scan"],
			"Anchor equipment": ["Fossen Shipping"],
			"Deck cranes": ["4 stk.", "Storm Cranes"],
			"Loading system": ["MMC First Process"],
			"Unloading system": ["MMC First Process"],
			"Sorting counter": ["MMC First Process"],
			"Fish counters": ["AquaScan", "MSD Animal Health Norway"],
			"Ozone system": ["Sterner"],
			"Oxygen system": ["Sterner"],
			"RSW system": ["PTG Frionordica"],
			"Lice filter": ["MossHydro"],
			Furnishing: ["R&M Ship Interior"],
			Furniture: ["Shipnor"],
			"Ventilation system": ["Copra Drift"],
			"Provision fridge system": ["Multi Kulde Vest"],
			"Interior doors": ["Baggerød", "R&M Ship Interior"],
			"Toilet facilities": ["Jets"],
			"Electrical installations": ["Vik Elektro"],
			Electronics: ["O. Øverland"],
			"Fire equipment": ["Certex Norge"],
			"Rescue equipment": ["Certex Norge"],
			"Exterior doors": ["Libra-Plast"],
			Windows: ["Karaca Gemi"],
			Paint: ["Carboline Norge"],
			Actuators: ["Eltorque"],
			"Windshield wipers": ["Exalto Decca Wiper"],
			"Pipe/cable penetrations": ["Roxtec"],
			"Navigation lights": ["Tranberg"],
			Design: ["AAS 3002 ST", "Aas Mek Verksted"],
		},
		Ronjafisk: {
			"Length overall": ["69.96 m"],
			"Breadth Moulded": ["17.80 m"],
			"Depth in draft": ["5.90 m"],
			"Service speed": ["13 kn"],
			"Fish hold capacity": ["2500 m3"],
			"Main engines": ["Yanmar 6EY26W, 1900 kW", "Maritim Motor"],
			Gear: ["Brunvoll Volda"],
			"Propulsion system": ["Brunvoll Volda"],
			"Auxiliary engines": ["Cat", "Pon Power"],
			Thrusters: ["Brunvoll"],
			"Steering gear": ["Kongsberg Maritime"],
			"Filtration lub oil/fuel": ["Øwre-Johnsen"],
			"Deck cranes": ["Bergen Hydraulic"],
			"Anchor winch": ["Adria Winch"],
			"Loading/Unloading system": ["MMC First Process"],
			"Fish counters": ["AquaScan", "Vaki"],
			"Smolt counter": ["Vaki"],
			Sorteringsteller: ["MMC First Process"],
			"Ozone system": ["Sterner"],
			"RSW system": ["PTG Frionordica"],
			"UV-system/Lice filter": ["Sterner"],
			"Anchor equipment": ["Fossen Shipping"],
			"MOB boat/cranes": ["Maritime Partner"],
			Lights: ["Luminell"],
			Typhon: ["R. Stahl Tranberg"],
			"Navigation lights/search lights": ["Glamox"],
			Furnishing: ["R&M Ship Interior", "Shipnor"],
			"Ventilation system": ["Wangsmo"],
			"Provision fridge system": ["Trondheim Kulde"],
			"Exterior doors": ["Libra-Plast"],
			"Interior doors": ["Baggerød", "R&M Ship Interior"],
			"Interior lights": ["Glamox"],
			Windows: ["Bohamet"],
			"Toilet facilities": ["Jets"],
			"Electrical installations": ["Vik Elektro"],
			Electronics: ["HG Marine Electronics"],
			Pumps: ["PG Flow Solutions", "Allweiler"],
			"Fire/Rescue equipment": ["Certex Norge"],
			Paint: ["Multi Marine", "Carboline"],
			"Valves/Pipes equipment": ["Robinet"],
			"Valve equipment": ["Brødrene Dahl"],
			Financing: ["Nordea"],
			"Fire fighting system": ["Tyco Fire & Integrated Solutions"],
		},
		// VARD
		"REV Ocean": {
			Length: ["183 m"],
			Beam: ["22 m"],
			Draft: ["5.25 m"],
			"Gross Tonnage": ["17,440"],
			"Ice class": ["Polar Class 6"],
			"Installed power": ["4 × Wärtsilä 8L26 (4 × 2.7 MW)"],
			Speed: ["11 knots"],
			"Maximum speed": ["17 knots"],
			Range: ["21,120 nmi"],
			"Boats & landing craft carried": ["2 trawlers, 1 submersible"],
			Crew: ["30"],
			Complement: ["90", "(crew, scientists and guests)"],
			"Aviation facilities": ["2 helipads", "ACH145 Airbus Corporate helicopter"],
		},
	}
