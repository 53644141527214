import { AddDeviceEntry, Plant } from "edge-admin-api"
import { ReactElement, useState } from "react"
import Button from "../Button"

export default function DevicePlantSelectionCell(args: {
	plants: Plant[]
	device: AddDeviceEntry
	onPermit: (plantId: number) => void
}): ReactElement {
	const [plantId, setPlantId] = useState<number>()

	const permitDevice = (ip: string) => {
		if (plantId == undefined || isNaN(plantId)) {
			return
		}
		const confirmed = confirm(
			`You are about to send access credentials (device name/token) to a device (ip=${ip}).\nThis will grant the device permission to send data to the cloud.\nAre you sure?`
		)
		if (confirmed == true) {
			args.onPermit(plantId)
		}
	}

	return (
		<div className={`flex flex-row gap-2 px-1`}>
			<select
				className={`h-8 py-0 pl-2`}
				onChange={(evt) => setPlantId(parseFloat(evt.target.value))}
			>
				{args.plants.map((p, index) => (
					<option key={index} value={p.id} label={p.name} />
				))}
			</select>
			<Button label="permit" onClick={() => permitDevice(args.device.ip)} />
		</div>
	)
}
