import { mapLayersColors } from "@components/map/utils/mapLayers"
import SingleSelect from "@components/plot/plotConfig/SingleSelect"
import RadioGroup from "@components/radioGroup/RadioGroup"
import ToggleSwitch from "@components/toggleSwitch/ToggleSwitch"
import { ToggleStyle } from "@components/toggleSwitch/types"
import * as AccordionPrimitive from "@radix-ui/react-accordion"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import {
	getSelectedDiseaseZones,
	SelectedDiseaseZones,
	updateSelectedDiseaseZones,
} from "@redux/features/diseaseZones/diseaseZonesSlice"
import { getFilteredListOfFishFarms } from "@redux/features/filteredListOfFishFarms/filteredListOfFishFarmsSlice"
import {
	getFishFarmOwners,
	updateSelectedFishFarmOwner as updateSelectedFishFarmOwnerOwnersSlice,
} from "@redux/features/fishFarmOwners/fishFarmOwnersSlice"
import {
	defaultState as defaultMapFilters,
	getMapFilters,
	resetMapFilters,
	updateMapFilters,
	updateSelectedFishFarmOwner,
} from "@redux/features/mapFilters/mapFiltersSlice"
import { Button } from "@ui-lib/index"
import { ReactElement, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MapFilterAccordionContent } from "./mapFilterAccordion/MapFilterAccordionContent"
import MapFilterAccordionTriggerMain from "./mapFilterAccordion/MapFilterAccordionTriggerMain"
import MapFilterAccordionTriggerSecondary from "./mapFilterAccordion/MapFilterAccordionTriggerSecondary"

type FishFarmOwnerOption = {
	value: string
	label: string
}

export default function MapFiltersFishContent(): ReactElement {
	const { t } = useTranslation()
	const input = document.getElementById("liceThreshold") as HTMLInputElement
	if (input !== null) {
		input.addEventListener(
			"focusout",
			() => (input.value = input.value === "" ? "0" : String(Number(input.value)))
		)
	}

	const dispatch = useAppDispatch()
	const mapFilters = useAppSelector(getMapFilters)
	const fishFarmOwners = useAppSelector(getFishFarmOwners)
	const selectedDiseaseZones = useAppSelector(getSelectedDiseaseZones)

	const [selectedFishFarmOwner, setSelectedFishFarmOwner] = useState<FishFarmOwnerOption>(
		mapFilters.selectedFishFarmOwner.orgNo === null
			? {
					value: "",
					label: "",
				}
			: {
					value: mapFilters.selectedFishFarmOwner.name,
					label: `${mapFilters.selectedFishFarmOwner.name} ${
						mapFilters.selectedFishFarmOwner.orgNo !== null
							? `(${mapFilters.selectedFishFarmOwner.orgNo})`
							: ""
					}`,
				}
	)

	const [fishFarmOwnersList, setFishFarmOwnersList] = useState<FishFarmOwnerOption[]>([])

	useEffect(() => {
		const fishFarmOwnersListTemp: FishFarmOwnerOption[] = []
		fishFarmOwners.map((owner) =>
			fishFarmOwnersListTemp.push({
				value: owner.name,
				label: `${owner.name} ${owner.orgNo !== null ? `(${owner.orgNo})` : ""}`,
			})
		)
		setFishFarmOwnersList(fishFarmOwnersListTemp)
	}, [fishFarmOwners])

	if (input !== null) {
		input.value = String(
			input.value === "" && mapFilters.liceThreshold.threshold === 0
				? ""
				: Number(input.value) === mapFilters.liceThreshold.threshold
					? input.value
					: mapFilters.liceThreshold.threshold
		)
	}

	const onShowProductionSitesChanged = (value: boolean) => {
		dispatch(updateMapFilters({ ...mapFilters, showProductionSites: value }))
	}

	const onReportingStatusChanged = (value: string) => {
		dispatch(updateMapFilters({ ...mapFilters, reportingStatus: value }))
	}

	const onLiceThresholdValueChanged = (value: number) => {
		const newLiceThreshold = { ...mapFilters.liceThreshold, threshold: value }
		dispatch(updateMapFilters({ ...mapFilters, liceThreshold: newLiceThreshold }))
	}

	const onShowSitesBelowThresholdChanged = (value: boolean) => {
		const newLiceThreshold = {
			...mapFilters.liceThreshold,
			showSitesBelowThreshold: value,
		}
		dispatch(updateMapFilters({ ...mapFilters, liceThreshold: newLiceThreshold }))
	}

	const onShowSitesAboveThresholdChanged = (value: boolean) => {
		const newLiceThreshold = {
			...mapFilters.liceThreshold,
			showSitesAboveThreshold: value,
		}
		dispatch(updateMapFilters({ ...mapFilters, liceThreshold: newLiceThreshold }))
	}

	const correctInputValue = () => {
		const input = document.getElementById("liceThreshold") as HTMLInputElement
		if (input.value !== "") {
			input.value = String(mapFilters.liceThreshold.threshold)
		}
	}

	const onResetMapFilter = () => {
		onClearSelectedOwner()
		dispatch(resetMapFilters())
	}
	const filteredListFishFarms = useAppSelector(getFilteredListOfFishFarms)

	useEffect(() => {
		if (selectedFishFarmOwner === null) {
			onClearSelectedOwner()
			return
		}
		if (selectedFishFarmOwner.value === "") {
			return
		}
		const orgNo = fishFarmOwners.find(
			(owner) => owner.name === selectedFishFarmOwner.value
		)?.orgNo
		if (orgNo === undefined) {
			return
		}
		dispatch(
			updateSelectedFishFarmOwner({
				orgNo: orgNo,
				name: selectedFishFarmOwner.value,
			})
		)
		dispatch(
			updateSelectedFishFarmOwnerOwnersSlice({
				orgNo: orgNo,
				name: selectedFishFarmOwner.value,
			})
		)
	}, [selectedFishFarmOwner])

	const onClearSelectedOwner = () => {
		setSelectedFishFarmOwner({ value: "", label: "" })
		dispatch(
			updateSelectedFishFarmOwner({
				orgNo: null,
				name: "",
			})
		)
		dispatch(
			updateSelectedFishFarmOwnerOwnersSlice({
				orgNo: null,
				name: "",
			})
		)
	}
	const changeOpacity = (hslColor: string) => {
		const hslWithOpacity = hslColor.slice(0, 3) + "a" + hslColor.slice(3, -1) + ",0.3)"
		return hslWithOpacity
	}
	const copySelectedDiseaseZones = () => {
		return JSON.parse(JSON.stringify(selectedDiseaseZones)) as SelectedDiseaseZones
	}
	const onSelectedIsaProtZoneChanged = (value: boolean) => {
		const selectedDiseaseZonesTemp = copySelectedDiseaseZones()
		selectedDiseaseZonesTemp["ISA protection zone"] = value
		dispatch(updateSelectedDiseaseZones(selectedDiseaseZonesTemp))
	}
	const onSelectedIsaSurvZoneChanged = (value: boolean) => {
		const selectedDiseaseZonesTemp = copySelectedDiseaseZones()
		selectedDiseaseZonesTemp["ISA surveillance zone"] = value
		dispatch(updateSelectedDiseaseZones(selectedDiseaseZonesTemp))
	}
	const onSelectedPdProtZoneChanged = (value: boolean) => {
		const selectedDiseaseZonesTemp = copySelectedDiseaseZones()
		selectedDiseaseZonesTemp["PD protection zone"] = value
		dispatch(updateSelectedDiseaseZones(selectedDiseaseZonesTemp))
	}
	const onSelectedPdSurvZoneChanged = (value: boolean) => {
		const selectedDiseaseZonesTemp = copySelectedDiseaseZones()
		selectedDiseaseZonesTemp["PD surveillance zone"] = value
		dispatch(updateSelectedDiseaseZones(selectedDiseaseZonesTemp))
	}

	const defaultOpenItems = ["item-1", "item-2", "item-3", "item-4", "item-5"]

	return (
		<AccordionPrimitive.Root
			type="single"
			defaultValue="mainItem-1"
			collapsible
		>
			<AccordionPrimitive.Item value="mainItem-1">
				<MapFilterAccordionTriggerMain>{t("FISH HEALTH")}</MapFilterAccordionTriggerMain>
				<MapFilterAccordionContent>
					<div className="flex h-auto flex-col gap-0 overflow-auto rounded-sm border-r-1 border-b-1 bg-white">
						<div
							className={`${
								JSON.stringify(mapFilters) === JSON.stringify(defaultMapFilters)
									? "h-0"
									: "p-2"
							} flex w-full justify-end overflow-hidden`}
						>
							<Button
								className={`px-2`}
								size={"sm"}
								disabled={
									JSON.stringify(mapFilters) === JSON.stringify(defaultMapFilters)
								}
								variant={"outline-dark"}
								onClick={() => onResetMapFilter()}
							>
								<Button.Label>{t("CLEAR FILTERS")}</Button.Label>
							</Button>
						</div>
						<AccordionPrimitive.Root
							type="multiple"
							defaultValue={defaultOpenItems}
						>
							{/* PRODUCTION SITES */}
							<AccordionPrimitive.Item
								className="border-l-dipai-secondary-300 hover:border-l-dipai-secondary-600 hover:bg-dipai-secondary-300/25 border-l-4"
								value="item-1"
							>
								<MapFilterAccordionTriggerSecondary>
									{t("PRODUCTION SITES")}
								</MapFilterAccordionTriggerSecondary>
								<MapFilterAccordionContent>
									<div className="grid">
										<div className="grid px-2 pt-1 pb-1">
											<p className="text-title5 text-gray-666">
												{`${filteredListFishFarms.features.length} ${t(
													"AQUACULTURE SITES"
												)}`}
											</p>
										</div>
										<div className={`hover:bg-gray-f4 px-2 pt-1 pb-2`}>
											<ToggleSwitch
												onChange={onShowProductionSitesChanged}
												initialState={mapFilters.showProductionSites}
												style={ToggleStyle.Rounded}
												label={`${t("SHOW")} ${t(
													"PRODUCTION SITES"
												).toLowerCase()} ${t("ON").toLowerCase()} ${t(
													"MAP"
												).toLowerCase()}`}
											/>
										</div>
									</div>
								</MapFilterAccordionContent>
							</AccordionPrimitive.Item>
							{/* LICE THRESHOLD */}
							<AccordionPrimitive.Item
								className="border-l-dipai-secondary-300 hover:border-l-dipai-secondary-600 hover:bg-dipai-secondary-300/25 border-l-4"
								value="item-2"
							>
								<MapFilterAccordionTriggerSecondary>
									{t("LICE THRESHOLD")}
								</MapFilterAccordionTriggerSecondary>
								<MapFilterAccordionContent>
									<div className="grid">
										<div className="hover:bg-gray-f4 flex flex-row items-center justify-between px-2 py-1">
											<label
												htmlFor="liceThreshold"
												className="text-button text-gray-666"
											>
												{t("FEMALE LICE PER FISH THRESHOLD")}
											</label>
											<input
												id="liceThreshold"
												name="liceThreshold"
												onChange={(e) =>
													/^([0-9]+\.?[0-9]*|\.[0-9]+|)$/.test(
														e.target.value
													)
														? onLiceThresholdValueChanged(
																Number(
																	e.target.value === ""
																		? "0"
																		: e.target.value
																)
															)
														: correctInputValue()
												}
												defaultValue={mapFilters.liceThreshold.threshold}
												type="number"
												max={1}
												step={0.05}
												min={0}
												className={`border-gray-ccc/90 text-tiny hover:border-dipai-secondary-500 focus:border-dipai-secondary-500 h-6 w-14 rounded-sm pr-1 pl-2 focus:border-2 focus:pr-[3px] focus:pl-[7px] focus:ring-0 focus:outline-hidden disabled:bg-slate-100`}
											/>
										</div>
										<div className="hover:bg-gray-f4 flex flex-row items-center justify-between px-2 py-1">
											<ToggleSwitch
												onChange={onShowSitesBelowThresholdChanged}
												initialState={
													mapFilters.liceThreshold.showSitesBelowThreshold
												}
												style={ToggleStyle.Rounded}
												label={`${t("SHOW")} ${t("SITES BELOW")} ${t(
													"LICE THRESHOLD"
												).toLowerCase()}`}
											/>
											<div className="h-5 w-5 rounded-full bg-blue-600" />
										</div>
										<div className="hover:bg-gray-f4 flex flex-row items-center justify-between px-2 pt-1 pb-2">
											<ToggleSwitch
												onChange={onShowSitesAboveThresholdChanged}
												initialState={
													mapFilters.liceThreshold.showSitesAboveThreshold
												}
												style={ToggleStyle.Rounded}
												label={`${t("SHOW")} ${t("SITES ABOVE")} ${t(
													"LICE THRESHOLD"
												).toLowerCase()}`}
											/>
											<div className="h-5 w-5 rounded-full bg-red-600" />
										</div>
									</div>
								</MapFilterAccordionContent>
							</AccordionPrimitive.Item>
							{/* REPORTING STATUS */}
							<AccordionPrimitive.Item
								className="border-l-dipai-secondary-300 hover:border-l-dipai-secondary-600 hover:bg-dipai-secondary-300/25 border-l-4"
								value="item-3"
							>
								<MapFilterAccordionTriggerSecondary>
									{t("REPORTING STATUS")}
								</MapFilterAccordionTriggerSecondary>
								<MapFilterAccordionContent>
									<div className={`pb-1`}>
										<RadioGroup
											onChange={onReportingStatusChanged}
											initialState={mapFilters.reportingStatus}
											style={ToggleStyle.Rounded}
											labels={[
												t("SHOW ALL SITES"),
												t("DID REPORT LICE STATUS"),
												t("DID NOT REPORT LICE STATUS"),
												`${t("DID NOT REPORT LICE STATUS")} (${t(
													"PROBABLY NO FISH"
												)})`,
												`${t("DID NOT REPORT LICE STATUS")} (${t(
													"NOT REQUIRED TO REPORT"
												)})`,
												`${t("DID NOT REPORT LICE STATUS")} (${t(
													"REQUIRED TO REPORT"
												)})`,
											]}
										/>
									</div>
								</MapFilterAccordionContent>
							</AccordionPrimitive.Item>
							{/* FILTER BY OWNER */}
							<AccordionPrimitive.Item
								className="border-l-dipai-secondary-300 hover:border-l-dipai-secondary-600 hover:bg-dipai-secondary-300/25 border-l-4"
								value="item-4"
							>
								<MapFilterAccordionTriggerSecondary>
									{t("FILTER BY OWNER")}
								</MapFilterAccordionTriggerSecondary>
								<MapFilterAccordionContent>
									<div className="hover:bg-gray-f4 flex flex-row justify-between px-2 pt-1 pb-2">
										<div className="w-[400px]">
											<SingleSelect
												options={fishFarmOwnersList}
												value={selectedFishFarmOwner}
												onChange={setSelectedFishFarmOwner}
												required={false}
												isClearable={true}
											/>
										</div>
									</div>
								</MapFilterAccordionContent>
							</AccordionPrimitive.Item>
							{/* DISEASE ZONES */}
							<AccordionPrimitive.Item
								className="border-l-dipai-secondary-300 hover:border-l-dipai-secondary-600 hover:bg-dipai-secondary-300/25 border-l-4"
								value="item-5"
							>
								<MapFilterAccordionTriggerSecondary>
									{t("DISEASE ZONES")}
								</MapFilterAccordionTriggerSecondary>
								<MapFilterAccordionContent>
									<div className="grid">
										<div className="hover:bg-gray-f4 flex flex-row items-center justify-between px-2 py-1">
											<ToggleSwitch
												onChange={onSelectedIsaSurvZoneChanged}
												initialState={
													selectedDiseaseZones["ISA surveillance zone"]
												}
												style={ToggleStyle.Rounded}
												label={`${t("SHOW")} ISA ${t("SURVEILLANCE ZONE")}`}
											/>
											<div
												style={{
													backgroundColor: changeOpacity(
														mapLayersColors.diseaseZones
															.IlaSurveillanceZone
													),
													borderColor:
														mapLayersColors.diseaseZones
															.IlaSurveillanceZone,
												}}
												className={`h-5 w-5 rounded-sm border-2`}
											/>
										</div>
										<div className="hover:bg-gray-f4 flex flex-row items-center justify-between px-2 py-1">
											<ToggleSwitch
												onChange={onSelectedIsaProtZoneChanged}
												initialState={
													selectedDiseaseZones["ISA protection zone"]
												}
												style={ToggleStyle.Rounded}
												label={`${t("SHOW")} ISA ${t("PROTECTION ZONE")}`}
											/>
											<div
												style={{
													backgroundColor: changeOpacity(
														mapLayersColors.diseaseZones
															.IlaProtectionZone
													),
													borderColor:
														mapLayersColors.diseaseZones
															.IlaProtectionZone,
												}}
												className={`h-5 w-5 rounded-sm border-2`}
											/>
										</div>
										<div className="hover:bg-gray-f4 flex flex-row items-center justify-between px-2 py-1">
											<ToggleSwitch
												onChange={onSelectedPdSurvZoneChanged}
												initialState={
													selectedDiseaseZones["PD surveillance zone"]
												}
												style={ToggleStyle.Rounded}
												label={`${t("SHOW")} PD ${t("SURVEILLANCE ZONE")}`}
											/>
											<div
												style={{
													backgroundColor: changeOpacity(
														mapLayersColors.diseaseZones
															.PdSurveillanceZone
													),
													borderColor:
														mapLayersColors.diseaseZones
															.PdSurveillanceZone,
												}}
												className={`h-5 w-5 rounded-sm border-2`}
											/>
										</div>
										<div className="hover:bg-gray-f4 flex flex-row items-center justify-between px-2 pt-1 pb-2">
											<ToggleSwitch
												onChange={onSelectedPdProtZoneChanged}
												initialState={
													selectedDiseaseZones["PD protection zone"]
												}
												style={ToggleStyle.Rounded}
												label={`${t("SHOW")} PD ${t("PROTECTION ZONE")}`}
											/>
											<div
												style={{
													backgroundColor: changeOpacity(
														mapLayersColors.diseaseZones
															.PdProtectionZone
													),
													borderColor:
														mapLayersColors.diseaseZones
															.PdProtectionZone,
												}}
												className={`h-5 w-5 rounded-sm border-2`}
											/>
										</div>
									</div>
								</MapFilterAccordionContent>
							</AccordionPrimitive.Item>
						</AccordionPrimitive.Root>
					</div>
				</MapFilterAccordionContent>
			</AccordionPrimitive.Item>
		</AccordionPrimitive.Root>
	)
}
