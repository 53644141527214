import { PlantPlot } from "api"
import { Dispatch, useReducer } from "react"

type PlotConfigReducerState = {
	plotInfo: PlantPlot
	thresholdOpenId: number | undefined
	updated: boolean
}

export type PlotConfigReducerAction = {
	payload?: PlantPlot
	thresholdOpenId?: number
	type: PlotConfigReducerActionTypes
}

export enum PlotConfigReducerActionTypes {
	ADD_SENSOR = "add_sensor",
	REMOVE_SENSOR = "remove_sensor",
	RESET_SENSOR = "reset_sensor",
	RESET_UPDATED = "reset_updated",
	UPDATE_SENSOR = "update_sensor",
	UPDATE_PLOTNAME = "update_plotname",
	UPDATE_THRESHOLD_OPEN = "update_threshold_open",
}

export const createPlotConfigReducer =
	() =>
	(state: PlotConfigReducerState, action: PlotConfigReducerAction): PlotConfigReducerState => {
		const signals_0 = action.payload?.signals?.[0]
		switch (action.type) {
			case PlotConfigReducerActionTypes.REMOVE_SENSOR:
				return {
					...state,
					plotInfo: {
						...state.plotInfo,
						signals:
							signals_0 !== undefined
								? state.plotInfo.signals.filter(
										(sensor) => sensor.id !== signals_0.id
								  )
								: state.plotInfo.signals,
					},
					updated: true,
				}

			case PlotConfigReducerActionTypes.UPDATE_SENSOR:
				return {
					...state,
					plotInfo: {
						...state.plotInfo,
						signals: state.plotInfo.signals.map((sensor) => {
							if (signals_0 !== undefined && sensor.id === signals_0.id) {
								return signals_0
							}
							return sensor
						}),
					},
					updated: true,
				}

			case PlotConfigReducerActionTypes.ADD_SENSOR:
				return {
					...state,
					plotInfo: {
						...state.plotInfo,
						signals:
							signals_0 !== undefined
								? [...state.plotInfo.signals, signals_0]
								: state.plotInfo.signals,
					},
					updated: true,
				}

			case PlotConfigReducerActionTypes.UPDATE_PLOTNAME:
				return {
					...state,
					plotInfo: {
						...state.plotInfo,
						name: action.payload!.name,
					},
					updated: true,
				}

			case PlotConfigReducerActionTypes.RESET_UPDATED:
				console.log("state: ", state)
				return { ...state, updated: false }

			case PlotConfigReducerActionTypes.UPDATE_THRESHOLD_OPEN:
				return { ...state, thresholdOpenId: action.thresholdOpenId }

			default:
				return state
		}
	}

export function usePlotConfigReducer(
	plotInfo: PlantPlot
): [PlotConfigReducerState, Dispatch<PlotConfigReducerAction>] {
	const plotConfigReducer = createPlotConfigReducer()
	const [plotConfigState, plotConfigDispatch] = useReducer(plotConfigReducer, {
		plotInfo: plotInfo,
		updated: false,
		thresholdOpenId: undefined,
	})

	return [plotConfigState, plotConfigDispatch]
}
