import { isFeatureAvailable, useGetDevFeatures } from "@components/featureGuard/FeatureGuard"
import { UniSeaVesselIcon } from "@hooks/useGetUniSeaVessels"
import { CellSetterV1, TableBodyMapPage } from "@pages/HomePage/BodyContentLayout"
import { useAppSelector } from "@redux/app/hooks"
import { getCurrentCompany } from "@redux/features/currentCompany/currentCompanySlice"
import { getListOfVessels, PlantInfoFE } from "@redux/features/listOfVessels/listOfVesselsSlice"
import { getOfflineVessels } from "@redux/features/offlineVessels/offlineVesselsSlice"
import { getUniSeaVessels } from "@redux/features/uniSeaVessels/uniSeaVesselsSlice"
import { getUserData } from "@redux/features/userData/userDataSlice"
import { ColumnDef } from "@tanstack/react-table"
import { TableMapPage } from "@ui-lib/src"
import {
	VesselConnectionStatusIcon,
	VesselConnectionStatusIconProps,
} from "@utils/hooks/useGetVesselsConnectionStatusData"
import { PortalFeature } from "api"
import { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import { hard_coded_vessel_data } from "./hard_coded_vessel_data"

const fake_data_demo_vard = [
	{ vessel: "Vessel A", type: "Subsea", operation: "Transit", customer: "Client A" },
	{
		vessel: "Vessel B",
		type: "Subsea",
		operation: "Stand by",
		customer: "Client B",
	},
	{ vessel: "Vessel C", type: "PSV", operation: "DP", customer: "Client C" },
	{ vessel: "Vessel D", type: "PSV", operation: "Port", customer: "Client D" },
	{ vessel: "Vessel E", type: "CSV", operation: "DP", customer: "Client E" },
	{ vessel: "Vessel F", type: "Wellboat", operation: "DP", customer: "Client F" },
	{ vessel: "Vessel G", type: "CSV", operation: "DP", customer: "Client G" },
	{ vessel: "Vessel H", type: "CLV", operation: "DP", customer: "Client H" },
	{ vessel: "Vessel I", type: "REV", operation: "DP", customer: "Client I" },
]

const fake_data_demo = [
	{ vessel: "Vessel A", type: "Subsea", operation: "Transit", customer: "Client A" },
	{
		vessel: "Vessel B",
		type: "Subsea",
		operation: "Stand by",
		customer: "Client B",
	},
	{ vessel: "Vessel C", type: "PSV", operation: "DP", customer: "Client C" },
	{ vessel: "Vessel D", type: "PSV", operation: "Port", customer: "Client D" },
	{ vessel: "Vessel E", type: "CSV", operation: "DP", customer: "Client E" },
	{ vessel: "Vessel F", type: "Wellboat", operation: "DP", customer: "Client F" },
	{ vessel: "Vessel G", type: "CSV", operation: "DP", customer: "Client G" },
	{ vessel: "Vessel H", type: "154233 tons", operation: "DP", customer: "Client H" },
]

type MapTableDofItem = {
	conditionLabel: string
	itemName: string
	type: string
	status: string
	customer: string
	icons: (ReactElement<VesselConnectionStatusIconProps> | null)[]
	details: { paramLabel: "vessel"; paramValue: string }
}

type InferAccessorType<T extends keyof MapTableDofItem> = MapTableDofItem[T]

function useListOfPlantsTableColumns(customerName?: string) {
	const { t } = useTranslation()
	const columns: ColumnDef<MapTableDofItem>[] =
		//  useMemo<ColumnDef<MapTableDofItem>[]>(
		// 	() =>
		[
			{
				accessorKey: "conditionLabel",
				header: () => <div className={`flex w-full justify-start text-left`}></div>,
				cell: (info) => {
					return CellSetterV1({
						content: {
							type: "condition-label",
							condition: info.getValue() as InferAccessorType<"conditionLabel">,
						},
						index: info.cell.id,
					})
				},
			},
			{
				accessorKey: "itemName",
				header: () => (
					<div className={`flex w-full justify-start text-left`}>{t("VESSEL")}</div>
				),
				cell: (info) => {
					return CellSetterV1({
						content: {
							type: "text",
							text: info.getValue() as InferAccessorType<"itemName">,
						},
						index: info.cell.id,
					})
				},
			},
			{
				accessorKey: "type",
				header: () => (
					<div className={`flex w-full justify-start text-left`}>
						{t(customerName === "Sølvtrans" ? "CARGO HOLD" : "VESSEL TYPE")}
					</div>
				),
				cell: (info) => {
					return CellSetterV1({
						content: {
							type: "text",
							text: info.getValue() as InferAccessorType<"type">,
						},
						index: info.cell.id,
					})
				},
			},
			{
				accessorKey: "status",
				header: () => (
					<div className={`flex w-full justify-start text-left`}>{t("OPERATION")}</div>
				),
				cell: (info) => {
					return CellSetterV1({
						content: {
							type: "text",
							text: info.getValue() as InferAccessorType<"status">,
						},
						index: info.cell.id,
					})
				},
			},
			{
				accessorKey: "customer",
				header: () => (
					<div className={`flex w-full justify-start text-left`}>{t("CHARTER")}</div>
				),
				cell: (info) => {
					return CellSetterV1({
						content: {
							type: "text",
							text: info.getValue() as InferAccessorType<"customer">,
						},
						index: info.cell.id,
					})
				},
			},
			{
				accessorKey: "icons",
				header: () => <div className={`flex w-full justify-start text-left`} />,
				cell: (info) => {
					return CellSetterV1({
						content: {
							type: "icons",
							icons: info.getValue() as InferAccessorType<"icons">,
						},
						index: info.cell.id,
					})
				},
			},
			{
				accessorKey: "details",
				header: () => <div className={`flex w-full justify-start text-left`} />,
				cell: (info) => {
					return CellSetterV1({
						content: {
							type: "url-param",
							...(info.getValue() as InferAccessorType<"details">),
						},
						index: info.cell.id,
					})
				},
			},
		]
	// 	[t]
	// )
	return columns
}

export function TableListOfPlants({ customerName }: { customerName: string }) {
	const { t } = useTranslation()
	const offlineVessels = useAppSelector(getOfflineVessels)
	const listOfVessels = useAppSelector(getListOfVessels)
	const uniSeaVessels = useAppSelector(getUniSeaVessels)
	const userData = useAppSelector(getUserData)
	const devFeatures = useGetDevFeatures()
	const currentCompany = useAppSelector(getCurrentCompany) ?? ""

	const showDatalessPlants = isFeatureAvailable(
		undefined,
		PortalFeature.SHOW_DATALESS_PLANTS,
		currentCompany,
		devFeatures,
		userData.portalMode
	)

	const fake_data =
		customerName === "Dipai Portal Demo"
			? fake_data_demo
			: customerName === "Vard Demo"
			? fake_data_demo_vard
			: hard_coded_vessel_data
	const columns = useListOfPlantsTableColumns(customerName)
	const data: MapTableDofItem[] =
		// useMemo(() => {
		// 	return
		listOfVessels.vessels
			.map((vesselData, index) => {
				const vessel: PlantInfoFE = JSON.parse(JSON.stringify(vesselData))

				let statusLabel = ""
				// if (vessel.latestOperationMode?.deviationEvents !== undefined) {
				// 	const deviationEvents = vessel.latestOperationMode.deviationEvents

				// 	for (const event of deviationEvents) {
				// 		if (event.severity !== undefined) {
				// 			if (event.severity === "yellow" && statusLabel === "") {
				// 				statusLabel = "yelow"
				// 			}
				// 			if (event.severity === "red") {
				// 				statusLabel = "red"
				// 			}
				// 		}
				// 	}
				// }
				const offlineVesselData = offlineVessels.find(
					(plantData) => plantData.id === vessel.plantId
				)
				const vesselUniSeaData = uniSeaVessels.find(
					(plantData) => plantData.id === vessel.plantId
				)

				const icons: (ReactElement<VesselConnectionStatusIconProps> | null)[] = []
				if (offlineVesselData !== undefined || vesselUniSeaData !== undefined) {
					statusLabel = vesselUniSeaData !== undefined ? "UniSea" : "yellow"
					icons.push(
						vesselUniSeaData !== undefined ? (
							<UniSeaVesselIcon firstTimestamp={vesselUniSeaData.firstTimestamp} />
						) : offlineVesselData !== undefined ? (
							<VesselConnectionStatusIcon
								isAvailable={false}
								lastTimestamp={offlineVesselData.lastUpdateTimestamp}
								colorTwString="text-amber-700"
							/>
						) : null
					)
				}

				return {
					conditionLabel: statusLabel === "" ? "green" : statusLabel,
					itemName: `${vessel.name}${statusLabel === "yelow" ? " (offline)" : ""}`,
					status: `${
						vessel.latestOperationMode2 !== undefined
							? vessel.latestOperationMode2.opModeName
							: "--"
					}`,
					details: { paramLabel: "vessel" as const, paramValue: vessel.name },
					icons,
					type: fake_data.find((data) => data.vessel === vessel.name)?.type ?? "--",
					customer:
						fake_data.find((data) => data.vessel === vessel.name)?.customer ?? "--",
				}
			})
			.filter((vesselData) => {
				const iconComponent = vesselData.icons?.[0]
				if (
					showDatalessPlants === true ||
					iconComponent === undefined ||
					iconComponent === null
				) {
					return true
				}
				if (
					iconComponent.props.lastTimestamp === null ||
					iconComponent.props.lastTimestamp === undefined
				) {
					return false
				}
				return true
			})
			.sort((a, b) => (a.itemName > b.itemName ? 1 : a.itemName < b.itemName ? -1 : 0))
	// }, [listOfVessels, offlineVessels])

	return (
		<TableMapPage
			translationFunc={t}
			TableBody={TableBodyMapPage}
			columns={columns}
			columnsLayout={"grid-cols-[16px_120px_120px_120px_auto_30px_30px]"}
			data={data}
			itemsPerPage={10}
			loading={!listOfVessels.loaded}
			showGlobalFilter={true}
		/>
	)
}
