export function debounce<T extends (...args: Parameters<T>) => ReturnType<T>>(fn: T, ms: number) {
	let timeout: ReturnType<typeof setTimeout> | undefined

	return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
		if (timeout !== undefined) {
			clearTimeout(timeout)
		}
		timeout = setTimeout(() => fn.apply(this, args), ms)
	}
	// return (...args: Parameters<T>): ReturnType<T> => {
	// 	let result: any
	// 	if (timeout !== undefined) {
	// 		clearTimeout(timeout)
	// 	}
	// 	timeout = setTimeout(() => {
	// 		result = fn(...args)
	// 	}, ms)
	// 	return result
	// }
}
