import { RootState } from "@redux/app/store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type State = {
	zoom?: { mapId: string; zoomLevel: number }
}

export const initialState: State = {
	zoom: undefined,
}

const mapParamsSlice = createSlice({
	name: "mapParams",
	initialState,
	reducers: {
		updateMapParamsState(state, action: PayloadAction<State>) {
			return { ...state, ...action.payload }
		},
	},
})

export const getMapParamsState = (state: RootState) => state.mapParams
export const { updateMapParamsState } = mapParamsSlice.actions
export default mapParamsSlice.reducer
