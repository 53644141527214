import { Button, IconButton } from "@ui-lib/index"
import { ValueLabelPair } from "@utilityTypes/ValueLabelPair"
import { AllSensorsForAnAsset } from "@utilityTypes/timeseriesDefs"
import { getSensorByIdAndContext } from "@utils/common"
import {
	AggregationMethod,
	PlantPlot,
	PlantPlotSignal,
	SensorThreshold,
	SensorThresholdType,
	SignalBaseLine,
} from "api"
import { Dispatch, Fragment, ReactElement, useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdClose } from "react-icons/md"
import { MultiValue } from "react-select"
import MultiSelect from "./MultiSelect"
import { SensorsListValueLabelPair } from "./PlotConfig"
import SingleSelect from "./SingleSelect"
import ThresholdListLine from "./ThresholdListLine"
import { PlotConfigReducerAction, PlotConfigReducerActionTypes } from "./usePlotConfigReducer"
import { useThresholdReducer } from "./useThresholdReducer"

const NUMBER_THRESHOLDS = 3

const AGGREGATION_TYPES = [
	{ value: AggregationMethod.PREDICTION, label: "prediction" },
	{ value: AggregationMethod.AVG_LAST_REPUB, label: AggregationMethod.AVG_LAST_REPUB },
	{ value: AggregationMethod.SNAP_FIRST, label: AggregationMethod.SNAP_FIRST },
	{ value: AggregationMethod.MAX_LAST_REPUB, label: AggregationMethod.MAX_LAST_REPUB },
	{ value: AggregationMethod.MIN_LAST_REPUB, label: AggregationMethod.MIN_LAST_REPUB },
]

type ValueLabelPairBaseline = {
	label: SignalBaseLine | string
	value: SignalBaseLine | string
}

const BASELINES_TYPES: ValueLabelPairBaseline[] = [
	{ label: SignalBaseLine.AVERAGE, value: SignalBaseLine.AVERAGE },
	{ label: SignalBaseLine.MAX, value: SignalBaseLine.MAX },
	{ label: SignalBaseLine.MIN, value: SignalBaseLine.MIN },
]

export type SensorThresholdFE = {
	id: number
	name: string
	value: number
	type: SensorThresholdType | ""
	useAlarm: boolean
}

type SensorsListLineArgs = {
	columnsLayout: string
	componentData: AllSensorsForAnAsset
	dispatch: Dispatch<PlotConfigReducerAction>
	isNewLine?: boolean
	signal: PlantPlotSignal
	plotData: PlantPlot
	thresholdOpenId?: number
}

type MyObject = {
	a: number
	b: string
	c: boolean
}

type PropValuesTypes =
	| Extract<PlantPlotSignal[keyof PlantPlotSignal], unknown>
	| SensorThresholdFE[]

export default function SensorListLine({
	columnsLayout,
	componentData,
	dispatch,
	isNewLine = false,
	signal,
	plotData,
	thresholdOpenId = undefined,
}: SensorsListLineArgs): ReactElement {
	const [plotInfoTemp, setPlotInfoTemp] = useState<PlantPlotSignal>(signal)
	const didMount = useRef(false)
	const updateSensor = useCallback(() => {
		// TODO: implement proper comparisson for initial and new plot info
		// TODO: Deep comparisson of objects. Need to be double checked and made recursive
		if (isNewLine === false) {
			const plotInfoTempNew: PlantPlotSignal = JSON.parse(JSON.stringify(plotInfoTemp))
			const tmpPlantPlot: PlantPlot = {
				name: null,
				id: -1,
				signals: [plotInfoTempNew],
			}

			const action: PlotConfigReducerAction = {
				type: PlotConfigReducerActionTypes.UPDATE_SENSOR,
				payload: tmpPlantPlot,
			}

			const keys = Object.keys(signal) as [keyof PlantPlotSignal]

			for (const key of keys) {
				const plotInfoTempNewRef = plotInfoTempNew[key]
				if (typeof signal[key] !== "object") {
					if (signal[key] !== plotInfoTempNewRef) {
						dispatch(action)
						break
					}
				} else {
					const signalProp = signal[key]
					if (Array.isArray(signalProp) && Array.isArray(plotInfoTempNewRef)) {
						if (signalProp.length !== plotInfoTempNewRef.length) {
							dispatch(action)
							break
						} else {
							for (const [f, signalPropEntry] of signalProp.entries()) {
								const plotInfoTempNewRefF = plotInfoTempNewRef[f]
								if (plotInfoTempNewRefF === undefined) {
									continue
								}
								if (typeof signalPropEntry !== "object") {
									if (signalPropEntry !== plotInfoTempNewRefF) {
										dispatch(action)
										break
									}
								} else {
									for (const [g, signalPropEntryEntry] of Object.entries(
										signalPropEntry
									)) {
										if (signalPropEntryEntry !== plotInfoTempNewRefF[g]) {
											dispatch(action)
											break
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}, [componentData.sensors, dispatch, isNewLine, plotInfoTemp])

	useEffect(() => {
		if (didMount.current === true) {
			updateSensor()
		} else {
			didMount.current = true
		}
	}, [plotInfoTemp])

	const toggleThresholds = () => {
		const action: PlotConfigReducerAction = {
			type: PlotConfigReducerActionTypes.UPDATE_THRESHOLD_OPEN,
			thresholdOpenId: thresholdOpenId !== signal.id ? signal.id : undefined,
		}
		dispatch(action)
	}

	const addSensor = () => {
		const action: PlotConfigReducerAction = {
			type: PlotConfigReducerActionTypes.ADD_SENSOR,
			payload: { ...plotData, signals: [{ ...plotInfoTemp, id: Math.random() }] },
		}
		dispatch(action)
	}

	const deleteSensor = () => {
		const action: PlotConfigReducerAction = {
			type: PlotConfigReducerActionTypes.REMOVE_SENSOR,
			payload: { ...plotData, signals: [plotInfoTemp] },
		}
		dispatch(action)
	}

	const updateProp = (propName: string, propValue: PropValuesTypes) => {
		if (plotInfoTemp.hasOwnProperty(propName) === true) {
			const newPlotInfo: PlantPlotSignal = JSON.parse(JSON.stringify(plotInfoTemp))
			newPlotInfo[propName] = propValue
			setPlotInfoTemp(newPlotInfo)
		}
	}

	const elementExtractorHeader = (signal: PlantPlotSignal, elementKey: string, index: number) => {
		switch (elementKey) {
			case "sensorId":
				if (signal.hasOwnProperty(elementKey)) {
					return (
						<SensorNameColumn
							key={index}
							componentData={componentData}
							signal={plotInfoTemp}
							updateProp={updateProp}
						/>
					)
				}
				break
			case "aggregationMethod":
				if (signal.hasOwnProperty(elementKey)) {
					return (
						<AggregationColumn
							key={index}
							signal={plotInfoTemp}
							updateProp={updateProp}
						/>
					)
				}
				break
			case "baselines":
				if (signal.hasOwnProperty(elementKey)) {
					return (
						<BaselinesColumn
							key={index}
							signal={plotInfoTemp as unknown as PlantPlotSignal}
							updateProp={updateProp}
						/>
					)
				}
				break
			case "thresholds":
				if (signal.hasOwnProperty(elementKey)) {
					return (
						<ThresholdColumn
							key={index}
							isNewLine={isNewLine}
							updateProp={toggleThresholds}
						/>
					)
				}
				break
			default:
				break
		}
		return <Fragment key={index} />
	}

	const elementExtractorBody = (signal: PlantPlotSignal, elementKey: string, index: number) => {
		switch (elementKey) {
			case "thresholds":
				if (signal.hasOwnProperty(elementKey)) {
					return (
						<ThresholdsList
							key={index}
							signal={plotInfoTemp}
							thresholdOpenId={thresholdOpenId}
							updateProp={updateProp}
						/>
					)
				}
				break
			default:
				break
		}
		return <Fragment key={index} />
	}

	// const elementsListHeader = ["sensorId", "aggregationMethod", "baselines", "thresholds"]
	const elementsListHeader = ["sensorId", "baselines", "thresholds"]

	const elementsListBody = ["thresholds"]

	const styleConditional = plotInfoTemp.sensorId !== -1

	return (
		<div className="mb-3 mt-[12px]">
			{/* HEADER */}
			<div
				className="grid items-center h-auto gap-6 pb-3"
				style={{ gridTemplateColumns: columnsLayout }}
			>
				<div className="h-8 rounded" style={{ backgroundColor: signal.color }} />
				{elementsListHeader.map((element, index) =>
					elementExtractorHeader(plotInfoTemp, element, index)
				)}
				{isNewLine === true ? (
					<Button
						variant={"filled-secondary"}
						className={`w-12`}
						disabled={!styleConditional}
						onClick={() => addSensor()}
					>
						<Button.Label>{"OK"}</Button.Label>
					</Button>
				) : (
					<IconButton
						variant={"destructive"}
						className={`w-12`}
						disabled={!styleConditional}
						onClick={() => deleteSensor()}
					>
						<IconButton.Icon>{MdClose}</IconButton.Icon>
					</IconButton>
				)}
			</div>
			{/* BODY */}
			{elementsListBody.map((element, index) =>
				elementExtractorBody(plotInfoTemp, element, index)
			)}
		</div>
	)
}

function ThresholdColumn({
	isNewLine,
	updateProp,
}: {
	isNewLine: boolean
	updateProp: () => void
}) {
	const { t } = useTranslation()

	return (
		<Button
			className={`w-[136px]`}
			variant={"outline-dark"}
			disabled={isNewLine}
			onClick={() => updateProp()}
		>
			<Button.Label>{t("THRESHOLD")}</Button.Label>
		</Button>
	)
}

function ThresholdsList<T extends PlantPlotSignal>({
	signal,
	thresholdOpenId,
	updateProp,
}: {
	signal: T
	thresholdOpenId: number | undefined
	updateProp: <F extends keyof SensorThreshold>(
		propName: string,
		propValue: SensorThresholdFE[]
	) => void
}) {
	const { t } = useTranslation()

	const thresholdsList: SensorThresholdFE[] = []
	let count = 0

	for (const threshold of signal.thresholds) {
		thresholdsList.push({ ...threshold, id: count })
		count++
	}

	const [thresholdState, thresholdDispatch] = useThresholdReducer(thresholdsList)
	const [thresholdListTable, setThresholdListTable] = useState<ReactElement[]>([])

	useEffect(() => {
		const prepData = () => {
			updateProp("thresholds", thresholdState)
			const thresholdListTableCopy: ReactElement[] = []

			for (const state of thresholdState) {
				const id = Math.random()

				thresholdListTableCopy.push(
					<ThresholdListLine
						key={`thresholdLine_${state.name}_${state.value}_${state.type}`}
						dispatch={thresholdDispatch}
						id={id}
						threshold={state}
					/>
				)
			}

			if (thresholdListTableCopy.length < NUMBER_THRESHOLDS) {
				const newThresholdLine: SensorThresholdFE = {
					id: -1,
					useAlarm: false,
					name: "",
					type: "",
					value: -10070,
				}
				thresholdListTableCopy.push(
					<ThresholdListLine
						key={`thresholdLine_${Math.random()}`}
						dispatch={thresholdDispatch}
						id={Math.random()}
						isNewLine={true}
						threshold={newThresholdLine}
					/>
				)
			}
			setThresholdListTable(thresholdListTableCopy)
		}

		prepData()
	}, [thresholdState]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div
			className="w-full overflow-auto rounded-sm bg-gray-f4 pl-[45px]"
			style={{ height: thresholdOpenId !== signal.id ? 0 : undefined }}
		>
			<div className="mb-2 mt-[14px] grid w-full grid-cols-[320px_100px_170px_48px] gap-6">
				<p className="text-button uppercase tracking-[1px] text-gray-666">
					{t("THRESHOLD NAME")}
				</p>
				<p className="text-button uppercase tracking-[1px] text-gray-666">{t("VALUE")}</p>
				<p className="text-button uppercase tracking-[1px] text-gray-666">{t("TYPE")}</p>
				{/* <p className="text-button uppercase tracking-[1px] text-gray-666">{t("ALARM")}</p> */}
			</div>

			{thresholdListTable}
		</div>
	)
}

function SensorNameColumn<T extends PlantPlotSignal>({
	componentData,
	signal,
	updateProp,
}: {
	componentData: AllSensorsForAnAsset
	signal: T
	updateProp: (propName: string, propValue: number) => void
}) {
	const sensorsList: SensorsListValueLabelPair[] = []
	for (const componentSensor of componentData.sensors) {
		sensorsList.push({
			value: componentSensor.id,
			label: componentSensor.display_name,
		})
	}
	sensorsList.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
	const [selectedSensor, setSelectedSensor] = useState<SensorsListValueLabelPair>(
		signal.sensorId !== -1 && getSensorByIdAndContext(signal.sensorId) !== undefined
			? {
					label: getSensorByIdAndContext(signal.sensorId)?.display_name!,
					value: signal.sensorId,
			  }
			: { label: "", value: -1 }
	)
	const [init, setInit] = useState<boolean>(false)

	useEffect(() => {
		if (init === false) {
			setInit(true)
		} else {
			const sensorData_0 = componentData.sensors.filter(
				(sensor) => selectedSensor.label === sensor.display_name
			)?.[0]
			let propValue = -1
			if (sensorData_0 !== undefined) {
				propValue = sensorData_0.id
			}
			updateProp("sensorId", propValue)
		}
	}, [selectedSensor])

	return (
		<SingleSelect
			onChange={setSelectedSensor}
			options={sensorsList}
			required={true}
			value={selectedSensor}
		/>
	)
}

function AggregationColumn<T extends PlantPlotSignal>({
	signal,
	updateProp,
}: {
	signal: T
	updateProp: (propName: string, propValue: string) => void
}) {
	const [selectedAggregation, setSelectedAggregation] = useState<ValueLabelPair>({
		value: signal.aggregationMethod,
		label: AGGREGATION_TYPES.filter(
			(aggregationType) => aggregationType.value === signal.aggregationMethod
		)?.[0]?.label as string,
	})
	const [init, setInit] = useState<boolean>(false)

	useEffect(() => {
		if (init === false) {
			setInit(true)
		} else {
			updateProp("aggregationMethod", selectedAggregation.value)
		}
	}, [selectedAggregation])

	return (
		<SingleSelect
			onChange={setSelectedAggregation}
			options={AGGREGATION_TYPES}
			required={true}
			value={selectedAggregation}
		/>
	)
}

function BaselinesColumn<T extends PlantPlotSignal>({
	signal,
	updateProp,
}: {
	signal: T
	updateProp: (propName: string, propValue: SignalBaseLine[]) => void
}) {
	const [selectedBaselines, setSelectedBaselines] = useState<MultiValue<ValueLabelPairBaseline>>(
		signal.baselines !== undefined
			? signal.baselines.map((baseline) => ({ label: baseline, value: baseline }))
			: []
	)
	const [init, setInit] = useState<boolean>(false)

	useEffect(() => {
		if (init === false) {
			setInit(true)
		} else {
			const baselines: SignalBaseLine[] = []
			for (const selectedBaseline of selectedBaselines) {
				baselines.push(selectedBaseline.label as SignalBaseLine)
			}
			updateProp("baselines", baselines)
		}
	}, [selectedBaselines])

	return (
		<MultiSelect
			onChange={setSelectedBaselines}
			selectAllOption={{
				value: "<SELECT_ALL>",
				label: "All items",
			}}
			options={BASELINES_TYPES}
			value={selectedBaselines}
		/>
	)
}
