import { z } from "zod"

export function removeIncompatibleSearchParameters<T extends z.ZodTypeAny>(schema: T) {
	return ({ search, next }) => {
		const result = next(search)
		const clean = schema.safeParse(result)

		if (!clean.success) {
			return {}
		}
		return clean.data
	}
}
