import { Button, IconButton } from "@ui-lib/index"
import { SensorThresholdType } from "api"
import { Dispatch, ReactElement, useEffect, useRef, useState } from "react"
import { MdClose } from "react-icons/md"
import { SensorThresholdFE } from "./SensorListLine"
import SingleSelect from "./SingleSelect"
import { ThresholdReducerAction, ThresholdReducerActionTypes } from "./useThresholdReducer"

const THRESHOLD_TYPES_LIST = [
	{ value: "MIN", label: "Minimum" },
	{ value: "MAX", label: "Maximum" },
]

type ThresholdListLineArgs = {
	dispatch: Dispatch<ThresholdReducerAction>
	id: number
	isNewLine?: boolean
	threshold: SensorThresholdFE
}

export default function ThresholdListLine({
	dispatch,
	id,
	isNewLine = false,
	threshold,
}: ThresholdListLineArgs): ReactElement {
	const thresholdTypesDict: { [key: string]: string } = {}
	for (const thresholdType of THRESHOLD_TYPES_LIST) {
		thresholdTypesDict[thresholdType.value] = thresholdType.label
	}

	const [name, setName] = useState<string>(threshold.name)
	const [value, setValue] = useState<string>(
		threshold.value === -10070 ? "" : threshold.value.toString()
	)
	const [useAlarm, setUseAlarm] = useState<boolean>(threshold.useAlarm)
	const [selectedType, setSelectedType] = useState<{
		value: SensorThresholdType | ""
		label: string
	}>(
		threshold.type !== "MAX" && threshold.type !== "MIN"
			? { value: "", label: "" }
			: { value: threshold.type, label: thresholdTypesDict[threshold.type] ?? "unkown" }
	)

	const didMount = useRef(false)

	const addThreshold = () => {
		const newThreshold: SensorThresholdFE = {
			useAlarm: useAlarm,
			name: name,
			type: selectedType.value,
			value: Number(value),
			id: Math.random(),
		}

		const action: ThresholdReducerAction = {
			type: ThresholdReducerActionTypes.ADD_THRESHOLD,
			payload: newThreshold,
		}
		dispatch(action)
	}

	const updateThreshold = () => {
		if (isNewLine !== true) {
			const newThreshold: SensorThresholdFE = {
				useAlarm: useAlarm,
				name: name,
				type: selectedType.value,
				value: Number(value),
				id: threshold.id,
			}

			for (const thresholdName of Object.keys(threshold)) {
				if (threshold[thresholdName] !== newThreshold[thresholdName]) {
					const action: ThresholdReducerAction = {
						type: ThresholdReducerActionTypes.UPDATE_THRESHOLD,
						payload: newThreshold,
					}
					dispatch(action)
					break
				}
			}
		}
	}

	const removeThreshold = () => {
		const action: ThresholdReducerAction = {
			type: ThresholdReducerActionTypes.REMOVE_THRESHOLD,
			payload: threshold,
		}
		dispatch(action)
	}

	useEffect(() => {
		if (didMount.current === true) {
			updateThreshold()
		} else {
			didMount.current = true
		}
	}, [selectedType])

	const styleConditional = name !== "" && value !== "" && selectedType.value !== ""

	return (
		<div
			className={`h-auto w-full ${
				isNewLine === true ? "mb-7" : "mb-3"
			} grid grid-cols-[320px_100px_170px_48px] gap-6`}
		>
			{/* THRESHOLD NAME COLUMN */}
			<input
				key={`name_${id}`}
				className={`h-[32px] w-[321px] rounded-sm border-1 pl-[5px] ${
					name === ""
						? "border-red-600 hover:border-red-600 focus:border-red-600"
						: "border-gray-ccc hover:border-dipai-secondary-500 focus:border-dipai-secondary-500"
				} focus:border-2 focus:pl-[4px] focus:outline-hidden focus:ring-0`}
				defaultValue={name}
				onChange={(e) => setName(e.target.value)}
				onBlur={updateThreshold}
			/>
			{/* VALUE COLUMN */}
			<input
				key={`value_${id}`}
				className={`h-[32px] w-[100px] rounded-sm border-1 pl-[5px] ${
					value === ""
						? "border-red-600 hover:border-red-600 focus:border-red-600"
						: "border-gray-ccc hover:border-dipai-secondary-500 focus:border-dipai-secondary-500"
				} focus:border-2 focus:pl-[4px] focus:outline-hidden focus:ring-0`}
				defaultValue={value}
				onChange={(e) => setValue(e.target.value)}
				onBlur={updateThreshold}
			/>
			{/* TYPE COLUMN */}
			<SingleSelect
				options={THRESHOLD_TYPES_LIST}
				value={selectedType}
				onChange={setSelectedType}
				required={true}
			/>
			{/* ALARM COLUMN */}
			{/* <input
				key={`checkbox_${id}`}
				style={{ margin: "7.5px 12.5px", marginBottom: 0, width: 15, height: 15 }}
				type="checkbox"
				name="alarm"
				defaultChecked={useAlarm}
				onChange={() => setUseAlarm(!useAlarm)}
				onBlur={updateThreshold}
			/> */}
			{/* BUTTON COLUMN */}
			{isNewLine === true ? (
				<Button
					variant={"filled-secondary"}
					className={`w-full`}
					disabled={styleConditional ? false : true}
					onClick={addThreshold}
				>
					<Button.Label>{"OK"}</Button.Label>
				</Button>
			) : (
				<IconButton variant={"destructive"} className={`w-full`} onClick={removeThreshold}>
					<IconButton.Icon>{MdClose}</IconButton.Icon>
				</IconButton>
			)}
		</div>
	)
}
