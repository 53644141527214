import Icon from "@components/icon/Icon"
import { IconId } from "@components/icon/IconId"
import { IconSize } from "@components/icon/IconSvgContainer"
import { fetchRealTimeValue } from "@components/kpi/utils"
import { ModalNL } from "@components/modal/Modal"
import { DataDbType } from "@components/plot/PlotCard"
import { RangeType } from "@components/plot/utils"
import { COLORS_PLOTS_COLORBLIND } from "@utils/colorsPlots"
import { ContextType, getSensorByIdAndContext } from "@utils/common"
import {
	AggregationMethod,
	GetLiveDataArgs,
	Timeseries,
	TimeseriesData,
	TimeseriesInterval,
	Trend,
	toInterval,
} from "api"
import { TimeSeries } from "pondjs"
import { Fragment, ReactElement, useEffect, useState } from "react"
import { SeriesConfigObj } from "./KpiVesselsContent"
import { TopicObj } from "./types"
import { getDataAge, useGetLastTimeDataChangedQuery, useGetLiveDataQuery } from "./utils"

export type KpiHullVesselsContentProps = {
	iconId: IconId
	label: string
	topics?: TopicObj[]
	unit?: string
	width?: number
}

export default function KpiHullVesselsContent({
	iconId,
	label,
	topics,
	unit,
	width,
}: KpiHullVesselsContentProps): ReactElement {
	const [range, setRange] = useState<RangeType>("24h")
	const [newRangeLoaded, setNewRangeLoaded] = useState<boolean>(false)
	const [seriesConfig, setSeriesConfig] = useState<SeriesConfigObj>({
		name: "",
		unit: "",
		series: {},
	})
	const [seriesCollection, setSeriesCollection] = useState<Timeseries[]>([])

	const [isExpanded, setIsExpanded] = useState<boolean>(false)
	const [customFrom, setCustomFrom] = useState<Date>(new Date())
	const [customTo, setCustomTo] = useState<Date>(new Date())
	const [selectedCustomFrom, setSelectedCustomFrom] = useState<boolean>(false)
	const [previousSelectedCustomFrom, setPreviousSelectedCustomFrom] = useState<boolean>(false)

	const roundedValues: { value: number; trend: Trend }[] = []
	let kpiTimestamp: string | undefined

	let minutes = 1440 * 0.5
	if (range === "24h") {
		minutes = 1440 * 1
	} else if (range === "7days") {
		minutes = 1440 * 7
	}

	const listOfTopics: GetLiveDataArgs = {
		sensors: [],
	}

	if (topics !== undefined) {
		for (const topic of topics) {
			listOfTopics.sensors.push({
				sensorId: topic.sensorId,
				aggregationMethod: AggregationMethod.SNAP_FIRST,
			})
		}
	}

	const { data: valuesTemp } = useGetLiveDataQuery({ topics, listOfTopics })
	const { data: lastTimeDataChangedTemp } = useGetLastTimeDataChangedQuery({ topics })

	if (valuesTemp !== undefined) {
		for (const value of valuesTemp) {
			if (typeof value.value.value === "number") {
				roundedValues.push({
					value: Math.round(10 * value.value.value) / 10,
					trend: value.trend !== undefined ? value.trend : Trend.NONE,
				})
			}
		}
		const lastTimeDataChangedTemp0 = lastTimeDataChangedTemp?.[0]
		if (lastTimeDataChangedTemp0 !== undefined && roundedValues !== undefined) {
			kpiTimestamp = getDataAge(lastTimeDataChangedTemp0.timestamp)
		}
	}

	const togglePlotConfig = () => {}

	const toggleExpanded = async () => {
		setIsExpanded(!isExpanded)
	}

	const fetchTimeSeries = async () => {
		let customInterval: TimeseriesInterval | undefined
		if (selectedCustomFrom !== previousSelectedCustomFrom) {
			setPreviousSelectedCustomFrom(selectedCustomFrom)
			customInterval = toInterval(customFrom, customTo)
		}

		let values: TimeseriesData = { sensors: [] }
		if (topics !== undefined) {
			if (customInterval !== undefined) {
				values = await fetchRealTimeValue(topics, undefined, customInterval)
			} else {
				values = await fetchRealTimeValue(topics, minutes, undefined)
			}
		}
		const seriesConfigTemp: SeriesConfigObj = {
			name: label,
			series: {},
			unit: unit !== undefined ? unit : "",
		}

		var data_db: DataDbType = []

		data_db.push(
			values.sensors.map((signal) =>
				signal.data.map((row) => ({
					x: row[0].getTime(),
					y: row[1],
				}))
			)
		)

		const data_db0 = data_db[0]
		if (data_db0 === undefined) {
			return
		}
		// Using this approach to ensure the reference to the state (and consequently the state) is updated.
		// Otherwise the component was not rerendering with new data.
		const seriesCopy = data_db0

		const seriesCollectionArray: TimeSeries[] = []

		for (const [k, seriesK] of seriesCopy.entries()) {
			const valueK = values.sensors[k]
			if (valueK === undefined) {
				continue
			}
			const ss = seriesK.map(({ x, y }) => [x, y])

			if (ss.length > 0) {
				const s = new TimeSeries({
					columns: ["time", `${label}_${k}`],
					name: label,
					points: ss.reverse(),
				})
				seriesCollectionArray.push(s)

				let sensorName: string
				const sensorData = getSensorByIdAndContext(
					valueK.id,
					ContextType.OWNER,
					"Sølvtrans"
				)
				if (topics !== undefined && sensorData !== undefined) {
					sensorName = sensorData.display_name
				} else {
					sensorName = ""
				}

				const plotData = {
					id: 0,
					aggregationMethod: AggregationMethod.SNAP_FIRST,
					baselines: [],
					sensorId: valueK.id,
					color: COLORS_PLOTS_COLORBLIND[k] ?? "#1111FF",
					display_name: sensorName,
					sensor_name: "",
					thresholds: [],
					unit: unit !== undefined ? unit : "",
				}
				seriesConfigTemp.series[`${label}_${k}`] = plotData
			}
		}

		const seriesCollectionArrayCopy: TimeSeries[] = seriesCollectionArray.slice()
		setSeriesConfig(seriesConfigTemp)
		setSeriesCollection(seriesCollectionArrayCopy)
		setNewRangeLoaded(true)
	}

	const handleOpenTimeSeries = async () => {
		await fetchTimeSeries()
	}

	useEffect(() => {
		seriesCollection.length > 0 && setIsExpanded(true)
	}, [seriesCollection])

	useEffect(() => {
		const interval = setInterval(() => {
			fetchTimeSeries()
		}, 60000)
		if (isExpanded === true) {
			setNewRangeLoaded(false)
			fetchTimeSeries()
		} else {
			setRange("24h")
			clearInterval(interval)
		}
		return () => {
			clearInterval(interval)
		}
	}, [range, selectedCustomFrom, isExpanded])
	return (
		<>
			<div
				onClick={() => handleOpenTimeSeries()}
				className={`m-2 grid h-[190px] w-[120px] cursor-pointer grid-rows-[148px_40px] gap-2 rounded-lg p-2 hover:bg-gray-eee`}
				style={{ width: width !== undefined ? width - 16 : "calc(100% - 16px)" }}
			>
				<div className="grid h-[148px] w-full grid-cols-3">
					<div className="grid h-auto grid-rows-[40px_40px_40px] pr-1">
						{roundedValues !== undefined &&
							roundedValues.map((value, index) =>
								index < roundedValues.length / 2 ? (
									roundedValues.length / 2 !== 1 ? (
										index === 0 ? (
											<div
												key={index}
												className={`flex items-center justify-end text-small text-black`}
											>
												<p>{`${value.value}  ${value.trend}`}</p>
											</div>
										) : roundedValues.length / 2 === 2 ? (
											<Fragment key={index}>
												<div />
												<div
													className={`flex items-center justify-end text-small text-black`}
												>
													<p>{`${value.value}  ${value.trend}`}</p>
												</div>
											</Fragment>
										) : (
											<div
												key={index}
												className={`flex items-center justify-end text-small text-black`}
											>
												<p>{`${value.value}  ${value.trend}`}</p>
											</div>
										)
									) : (
										<Fragment key={index}>
											<div />
											<div
												className={`flex items-center justify-end text-small text-black`}
											>
												<p>{`${value.value}  ${value.trend}`}</p>
											</div>
											<div />
										</Fragment>
									)
								) : (
									<Fragment key={index} />
								)
							)}
					</div>
					<div className="flex justify-center">
						<div className="relative ">
							<svg className={`h-[120px] w-[60px] text-dipai-tertiary-801`}>
								<g>
									<path
										d="M1 119 l58 0 l0 -85 l-29 -33 l -29 33 l0 85"
										stroke="currentColor"
										fill="none"
										strokeWidth="2"
									/>
								</g>
							</svg>
							<div
								className={`absolute top-[36px] ml-[6px]  text-dipai-tertiary-801`}
							>
								<Icon iconId={iconId} size={IconSize.large} />
							</div>
						</div>
					</div>
					<div className="grid grid-rows-[40px_40px_40px] pl-1">
						{roundedValues !== undefined &&
							roundedValues.map((value, index) =>
								index >= roundedValues.length / 2 ? (
									roundedValues.length / 2 !== 1 ? (
										index === 0 ? (
											<div
												key={index}
												className={`flex items-center justify-start text-small text-black`}
											>
												<p>{`${value.value}  ${value.trend}`}</p>
											</div>
										) : roundedValues.length / 2 === 2 ? (
											<Fragment key={index}>
												<div />
												<div
													className={`flex items-center justify-start text-small text-black`}
												>
													<p>{`${value.value}  ${value.trend}`}</p>
												</div>
											</Fragment>
										) : (
											<div
												key={index}
												className={`flex items-center justify-start text-small text-black`}
											>
												<p>{`${value.value}  ${value.trend}`}</p>
											</div>
										)
									) : (
										<Fragment key={index}>
											<div />
											<div
												className={`flex items-center justify-start text-small text-black`}
											>
												<p>{`${value.value}  ${value.trend}`}</p>
											</div>
											<div />
										</Fragment>
									)
								) : (
									<Fragment key={index} />
								)
							)}
					</div>
					<span
						className={`col-span-3 pr-2 text-right text-tiny text-dipai-tertiary-801`}
					>
						{kpiTimestamp}
					</span>
				</div>

				<p className={`text-center text-small uppercase text-black`}>
					{`${label} `}
					<span className="normal-case">{unit !== undefined ? `(${unit})` : ""}</span>
				</p>
			</div>
			<ModalNL isOpen={isExpanded} setIsOpen={setIsExpanded}>
				<div className="fixed left-[5vw] top-[10vh] z-5 h-[80vh] w-[90vw] rounded-lg bg-white p-[20px]"></div>
			</ModalNL>
		</>
	)
}
