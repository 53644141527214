import { ReactElement } from "react"

export type ContainerProps = {
	children: ReactElement
}

export const TabContainer = ({ children }: ContainerProps): ReactElement => {
	return <div className="mt-[2px] grid w-full overflow-hidden">{children}</div>
}

export const InformationContainer = ({ children }: ContainerProps): ReactElement => {
	return <div className="pl-4 mt-4 mb-4">{children}</div>
}
