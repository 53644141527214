import { OilAndGasFieldArea, OilAndGasFieldType } from "@assets/oilAndGasData/fldArea"
import { addDiseaseZonesConstructor } from "@components/map/layersConstructors/addDiseaseZonesConstructor"
import { AppDispatch } from "@redux/app/store"
import {
	DiseaseZonesObj,
	SelectedDiseaseZones,
} from "@redux/features/diseaseZones/diseaseZonesSlice"
import { VesselInfo } from "@redux/features/selectedVessel/selectedVesselSlice"
import { TimelineEventMarker } from "@redux/features/timelineEventMarker/timelineEventMarkerSlice"
import { useNavigate } from "@tanstack/react-router"
import { DiseaseZoneType, Facility, FacilityType, PortalFeature } from "api"
import { GeoJSON, LayerGroup, Map as LeafletMap } from "leaflet"
import { QueryClient } from "react-query"
import { ShipTrackData } from "../dataGettterHooks/useGetSelectedShipTrack"
import { addDiseaseZones } from "../layersDataSetters/addDiseaseZones"
import { addFacilitiesWithRadius } from "../layersDataSetters/addFacilitiesWithRadius"
import { addFishFarms } from "../layersDataSetters/addFishFarms"
import { addOilAndGasFacilities } from "../layersDataSetters/addOilAndGasFacilities"
import { addOilAndGasFields } from "../layersDataSetters/addOilAndGasFields"
import { addTimelineEventMarker } from "../layersDataSetters/addTimelineEventMarker"
import { addVessels } from "../layersDataSetters/addVessels"
import { addVesselsNL } from "../layersDataSetters/addVesselsNL"
import { addVesselsNotClickable } from "../layersDataSetters/addVesselsNotClickable"
import { addVesselTrack } from "../layersDataSetters/addVesselTrack"
import { addVesselTrackWithSegments } from "../layersDataSetters/addVesselTrackWithSegements"
import { OGFacility } from "../mapComponents/oilAndGasFacilityPopup"
import { RegularMapType } from "../regularMap/regularMap"
import { WindyMapType } from "../windyMap/windyMap"
import { PointCollection } from "./utils"

export const mapLayersColors: {
	facilities: { [facility in FacilityType]: string }
	oilAndGasFields: { [oilAndGasField in OilAndGasFieldType]: string }
	oilAndGasFacilities: { [key: string]: string }
	diseaseZones: { [diseaseZone in DiseaseZoneType]: string }
} = {
	facilities: {
		PORT: "hsl(342, 85%, 70%)",
		DISCHARGE_TERMINAL: "hsl(22, 54%, 42%)",
		DISCHARGE_STS: "hsl(1, 79%, 46%)",
		ANCHORAGE: "hsl(36, 78%, 45%)",
		PLATFORM: "hsl(127, 86%, 55%)",
		FISH_FARM: "hsl(0, 0%, 80%)",
	},
	oilAndGasFields: {
		Oil: "hsl(316, 87%, 45%)",
		Gas: "hsl(0, 100%, 50%)",
		"Oil/Gas": "hsl(240, 100%, 50%)",
		"Gas/Condensate": "hsl(287, 98%, 20%)",
	},
	oilAndGasFacilities: {
		SURFACE: "hsl(278, 98%, 50%)",
		UNDERWATER: "hsl(178, 94%, 40%)",
	},
	diseaseZones: {
		IlaProtectionZone: "hsl(11,82%,43%)",
		IlaSurveillanceZone: "hsl(34,87%,55%)",
		PdProtectionZone: "hsl(203,88%,27%)",
		PdSurveillanceZone: "hsl(191,100%,29%)",
		PdZone: "hsl(207,92%,20%)",
		IlaFreeArea: "hsl(55,49%,44%)",
	},
}

export type LayerData = {
	mapLayerName: MapLayers
	facilitiesRadius?: { company: string; facilities: Facility[] }
	oilAndGasData?: OGFacility[]
	oilAndGasFieldsData?: OilAndGasFieldArea[]
	fishFarmsData?: PointCollection
	vesselsData?: { company: string; vesselInfo: VesselInfo[] }
	vesselsNlData?: {
		company: string
		vesselInfo: VesselInfo[]
		selectedVessel?: string
		zoom?: number
	}
	vesselsNotClickableData?: { vessels: VesselInfo[]; zoom?: number }
	vesselsTrackData?: ShipTrackData & { zoom?: number }
	vesselsTrackSegmentsData?: ShipTrackData & { zoom?: number }
	timelineEventMarkerData?: TimelineEventMarker
	deseaseZonesData?: {
		diseaseZonesData: DiseaseZonesObj
		selectedDiseaseZones: SelectedDiseaseZones
	}
	portalFeature?: PortalFeature
}

export enum MapLayers {
	FACILITIES_RADIUS = "facilitiesRadius",
	OIL_AND_GAS = "oilAndGas",
	OIL_AND_GAS_FIELDS = "oilAndGasFields",
	FISH_FARMS = "fishFarms",
	VESSELS = "vessels",
	VESSELS_NL = "vesselsNl",
	VESSELS_NOT_CLICKABLE = "vesselsNotClickable",
	VESSELS_TRACK = "vesselsTrack",
	VESSELS_TRACK_SEGMENTS = "vesselsTrackSegments",
	TIMELINE_EVENT_MARKER = "timelineEventMarker",
	DESEASE_ZONES = "deseaseZones",
}

export type DataSetter = (
	dispatch: AppDispatch,
	queryClient: QueryClient,
	layerGroup: LayerGroup | GeoJSON,
	layerData: LayerData,
	navigate?: ReturnType<typeof useNavigate>,
	map?: LeafletMap
) => void

export type LayerObject = {
	layerGroup: LayerGroup
	dataSetter: DataSetter
}

export const addLayersToMap = (map: WindyMapType | RegularMapType | undefined) => {
	if (map !== undefined) {
		if (map.isMapInitialized() === true) {
			// DISEASE ZONES
			map.addLayer(
				MapLayers.DESEASE_ZONES,
				599,
				addDiseaseZones as DataSetter,
				addDiseaseZonesConstructor
			)
			// OIL AND GAS FIELDS LAYER ELEMENTS
			map.addLayer(MapLayers.OIL_AND_GAS_FIELDS, 599, addOilAndGasFields)
			// OIL AND GAS FACILITIES LAYER ELEMENTS
			map.addLayer(MapLayers.OIL_AND_GAS, 600, addOilAndGasFacilities)
			// FISH FARMS LAYER ELEMENTS
			map.addLayer(MapLayers.FISH_FARMS, 600, addFishFarms)
			// FACILITIES RADIUS LAYER ELEMENTS
			map.addLayer(MapLayers.FACILITIES_RADIUS, 600, addFacilitiesWithRadius)
			// VESSELS TRACK LAYER ELEMENTS
			map.addLayer(MapLayers.VESSELS_TRACK, 601, addVesselTrack)
			// VESSELS TRACK SEGMENTS LAYER ELEMENTS
			map.addLayer(MapLayers.VESSELS_TRACK_SEGMENTS, 601, addVesselTrackWithSegments)
			// TIMELINE EVENT LAYER ELEMENTS
			map.addLayer(MapLayers.TIMELINE_EVENT_MARKER, 602, addTimelineEventMarker)
			// VESSELS LAYER ELEMENTS
			map.addLayer(MapLayers.VESSELS, 603, addVessels)
			// VESSELS LAYER NL ELEMENTS
			map.addLayer(MapLayers.VESSELS_NL, 603, addVesselsNL)
			// VESSELS LAYER NOT CLICKABLE ELEMENTS
			map.addLayer(MapLayers.VESSELS_NOT_CLICKABLE, 603, addVesselsNotClickable)
			console.log("Map init: Layers added")
			map.setAreLayersGenerated()
		} else {
			// console.log("Map init: Map not initialized yet")
		}
	} else {
		console.log("Map init: map not defined yet")
	}
}
