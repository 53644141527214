import { FacilityFixed, getFacilityFixed } from "@assets/oilAndGasData/facility_fixed"
import { OilAndGasFieldArea, getFldArea } from "@assets/oilAndGasData/fldArea"
import { useAppSelector } from "@redux/app/hooks"
import { getMapFiltersOil } from "@redux/features/mapFiltersOil/mapFiltersOilSlice"
import { useEffect, useMemo, useState } from "react"

export default function useGetOilGasDataForMap(): [FacilityFixed[], OilAndGasFieldArea[]] {
	const mapFiltersOil = useAppSelector(getMapFiltersOil)
	const [facilitiesData, setFacilitiesData] = useState<FacilityFixed[]>([])
	const [fieldsData, setFieldsData] = useState<OilAndGasFieldArea[]>([])

	const facilityFixed: FacilityFixed[] = useMemo(() => {
		return getFacilityFixed()
	}, [])
	const fldArea: OilAndGasFieldArea[] = useMemo(() => {
		return getFldArea()
	}, [])

	useEffect(() => {
		const fieldFilters = mapFiltersOil.fields
		const facilitiesFilters = mapFiltersOil.facilities

		const fieldTypesToKeep: string[] = []
		if (fieldFilters.showOil === true) {
			fieldTypesToKeep.push("OIL")
		}
		if (fieldFilters.showGas === true) {
			fieldTypesToKeep.push("GAS")
		}
		if (fieldFilters.showOilGas === true) {
			fieldTypesToKeep.push("OIL/GAS")
		}
		if (fieldFilters.showGasCondensate === true) {
			fieldTypesToKeep.push("GAS/CONDENSATE")
		}

		const filteredFieldsData = fldArea.filter(
			(f: OilAndGasFieldArea) => fieldTypesToKeep.includes(f.fldHcType) === true
		)
		setFieldsData(filteredFieldsData)

		const facilityTypesToKeep: string[] = []
		if (facilitiesFilters.showSurface === true) {
			facilityTypesToKeep.push("Y")
		}
		if (facilitiesFilters.showUnderwater === true) {
			facilityTypesToKeep.push("N")
		}
		const filteredFacilitiesData = facilityFixed.filter(
			(f: FacilityFixed) => facilityTypesToKeep.includes(f.fclSurface) === true
		)
		setFacilitiesData(filteredFacilitiesData)
	}, [mapFiltersOil])

	return [facilitiesData, fieldsData]
}
