import Icon from "@components/icon/Icon"
import { IconId } from "@components/icon/IconId"
import { IconSize } from "@components/icon/IconSvgContainer"
import { fetchRealTimeValue } from "@components/kpi/utils"
import { ModalNL } from "@components/modal/Modal"
import { DataDbType } from "@components/plot/PlotCard"
import { RangeType } from "@components/plot/utils"
import { getSensorIdWithoutComponent } from "@helpers/getSensorIdWithoutComponent"
import { getSensorLabelFromSensorId } from "@helpers/getSensorLabelFromSensorId"
import { useAppSelector } from "@redux/app/hooks"
import { getSelectedVessel } from "@redux/features/selectedVessel/selectedVesselSlice"
import { TopicTimeseries } from "@utilityTypes/timeseriesDefs"
import { COLORS_PLOTS_COLORBLIND } from "@utils/colorsPlots"
import { ContextType, common, getSensorByIdAndContext } from "@utils/common"
import {
	AggregationMethod,
	GetLiveDataArgs,
	PlantPlotSignal,
	TimeseriesData,
	TimeseriesInterval,
	toInterval,
} from "api"
import { TimeSeries } from "pondjs"
import { ReactElement, useEffect, useState } from "react"
import { TopicObj } from "./types"
import { getDataAge, useGetLastTimeDataChangedQuery, useGetLiveDataQuery } from "./utils"

export type SeriesConfigObj = {
	name: string
	series: {
		[serieKey: string]: PlantPlotSignal & { unit: string | undefined; display_name: string }
	}
	unit: string
}

export type KpiVesselsContentProps = {
	iconId: IconId
	label: string
	customerName: string
	sensors?: string[]
	topics: TopicObj[]
	unit?: string
	value?: number
	width?: number
}

export default function KpiVesselsContent({
	iconId,
	label,
	sensors,
	topics,
	unit,
	value,
	width,
	customerName,
}: KpiVesselsContentProps): ReactElement {
	const [range, setRange] = useState<RangeType>("24h")
	const [newRangeLoaded, setNewRangeLoaded] = useState<boolean>(false)
	const [seriesConfig, setSeriesConfig] = useState<SeriesConfigObj>({
		name: "",
		unit: "",
		series: {},
	})
	const [seriesCollection, setSeriesCollection] = useState<TopicTimeseries[]>([])
	const [isExpanded, setIsExpanded] = useState<boolean>(false)
	const [customFrom, setCustomFrom] = useState<Date>(new Date())
	const [customTo, setCustomTo] = useState<Date>(new Date())
	const [selectedCustomFrom, setSelectedCustomFrom] = useState<boolean>(false)
	const [previousSelectedCustomFrom, setPreviousSelectedCustomFrom] = useState<boolean>(false)

	const selectedVessel = useAppSelector(getSelectedVessel)

	const plantData = common.plantDataFromOwnerAndName.get(
		`${customerName?.toLowerCase()}/${selectedVessel.name?.toLowerCase()}`
	)

	const roundedValues: { value: string; label: string }[] = []
	let kpiTimestamp: string | undefined

	let minutes = 1440 * 0.5
	if (range === "24h") {
		minutes = 1440 * 1
	} else if (range === "7days") {
		minutes = 1440 * 7
	}

	let listOfTopics: GetLiveDataArgs = {
		sensors: [],
	}

	if (topics !== undefined) {
		for (const topic of topics) {
			listOfTopics.sensors.push({
				sensorId: topic.sensorId,
				aggregationMethod: AggregationMethod.SNAP_FIRST,
			})
		}
	}
	if (sensors !== undefined) {
		listOfTopics = {
			sensors: [],
		}
		topics = []
		for (const sensor of sensors) {
			topics.push({
				sensorId: getSensorIdWithoutComponent(plantData, sensor),
				aggregationMethod: AggregationMethod.SNAP_FIRST,
			})
			listOfTopics.sensors.push({
				sensorId: getSensorIdWithoutComponent(plantData, sensor),
				aggregationMethod: AggregationMethod.SNAP_FIRST,
			})
		}
	}
	const { data: valuesTemp } = useGetLiveDataQuery({ topics, listOfTopics })

	if (valuesTemp !== undefined && valuesTemp.length > 0) {
		for (const topic of topics) {
			const lol = valuesTemp.find((value) => value.sensorId === topic.sensorId)
			roundedValues.push({
				value: lol !== undefined ? lol.value.value.toString() : "N/A",
				label: getSensorLabelFromSensorId(plantData, topic.sensorId) ?? "",
			})
		}
	}
	// if (sensors !== undefined) {
	// 	for (const sensor of sensors) {
	// 		const tmpTopics = [
	// 			{
	// 				sensorId: getSensorIdWithoutComponent(plantData, sensor),
	// 				aggregationMethod: AggregationMethod.SNAP_FIRST,
	// 			},
	// 		]
	// 		const listOfTopics: GetLiveDataArgs = {
	// 			sensors: [],
	// 		}
	// 		listOfTopics.sensors.push({
	// 			sensorId: getSensorIdWithoutComponent(plantData, sensor),
	// 			aggregationMethod: AggregationMethod.SNAP_FIRST,
	// 		})

	// 		const { data: tmptmp } = useGetLiveDataQuery(tmpTopics, listOfTopics)

	// 		const dataTemp0 = tmptmp?.[0]
	// 		if (dataTemp0 !== undefined) {
	// 			roundedValues.push({
	// 				value: dataTemp0.value.value.toFixed(1),
	// 				label: sensor,
	// 			})
	// 		} else {
	// 			roundedValues.push({
	// 				value: "N/A",
	// 				label: sensor,
	// 			})
	// 		}
	// 	}
	// } else {
	// 	const { data: valuesTemp } = useGetLiveDataQuery(topics, listOfTopics)

	// 	if (valuesTemp !== undefined && valuesTemp.length > 0) {
	// 		for (const topic of topics) {
	// 			const lol = valuesTemp.find((value) => value.sensorId === topic.sensorId)
	// 			roundedValues.push({
	// 				value: lol !== undefined ? lol.value.value.toString() : "N/A",
	// 				label: getSensorLabelFromSensorId(plantData, topic.sensorId) ?? "",
	// 			})
	// 		}
	// 	}
	// }
	const { data: lastTimeDataChangedTemp } = useGetLastTimeDataChangedQuery({ topics })
	const lastTimeDataChangedTemp0 = lastTimeDataChangedTemp?.[0]
	if (lastTimeDataChangedTemp0 !== undefined) {
		kpiTimestamp = getDataAge(lastTimeDataChangedTemp0.timestamp)
	}

	const togglePlotConfig = () => {}

	const toggleExpanded = async () => {
		setIsExpanded(!isExpanded)
	}

	const fetchTimeSeries = async () => {
		let customInterval: TimeseriesInterval | undefined
		if (selectedCustomFrom !== previousSelectedCustomFrom) {
			setPreviousSelectedCustomFrom(selectedCustomFrom)
			customInterval = toInterval(customFrom, customTo)
		}

		let values: TimeseriesData = { sensors: [] }
		if (topics !== undefined) {
			if (customInterval !== undefined) {
				values = await fetchRealTimeValue(topics, undefined, customInterval)
			} else {
				values = await fetchRealTimeValue(topics, minutes, undefined)
			}
		}

		const seriesConfigTemp: SeriesConfigObj = {
			name: label,
			series: {},
			unit: unit !== undefined ? unit : "",
		}

		var data_db: DataDbType = []

		data_db.push(
			values.sensors.map((signal) =>
				signal.data.map((row) => ({
					x: row[0].getTime(),
					y: row[1],
				}))
			)
		)

		const data_db0 = data_db[0]
		if (data_db0 === undefined) {
			return
		}
		// Using this approach to ensure the reference to the state (and consequently the state) is updated.
		// Otherwise the component was not rerendering with new data.
		const seriesCopy = data_db0

		const seriesCollectionArray: TimeSeries[] = []

		for (const [k, seriesK] of seriesCopy.entries()) {
			const valueK = values.sensors[k]
			if (valueK === undefined) {
				continue
			}
			const ss = seriesK.map(({ x, y }) => [x, y])

			if (ss.length > 0) {
				const s = new TimeSeries({
					name: label,
					columns: ["time", `${label}_${k}`],
					points: ss.reverse(),
				})
				seriesCollectionArray.push(s)

				let sensorName: string
				const sensorData = getSensorByIdAndContext(
					valueK.id,
					ContextType.OWNER,
					customerName
				)
				if (topics !== undefined && sensorData !== undefined) {
					sensorName = sensorData.display_name
				} else {
					sensorName = ""
				}

				const plotData = {
					id: 0,
					aggregationMethod: AggregationMethod.SNAP_FIRST,
					baselines: [],
					sensorId: valueK.id,
					color: COLORS_PLOTS_COLORBLIND[k] ?? "#1111FF",
					display_name: sensorName,
					sensor_name: "",
					thresholds: [],
					unit: unit !== undefined ? unit : "",
				}
				seriesConfigTemp.series[`${label}_${k}`] = plotData
			}
		}

		const seriesCollectionArrayCopy: TimeSeries[] = seriesCollectionArray.slice()
		setSeriesConfig(seriesConfigTemp)
		setSeriesCollection(seriesCollectionArrayCopy)
		setNewRangeLoaded(true)
	}

	const handleOpenTimeSeries = async () => {
		await fetchTimeSeries()
	}

	useEffect(() => {
		seriesCollection.length > 0 && setIsExpanded(true)
	}, [seriesCollection])

	useEffect(() => {
		const interval = setInterval(() => {
			fetchTimeSeries()
		}, 60000)
		if (isExpanded === true) {
			setNewRangeLoaded(false)
			fetchTimeSeries()
		} else {
			setRange("24h")
			clearInterval(interval)
		}
		return () => {
			clearInterval(interval)
		}
	}, [range, selectedCustomFrom, isExpanded])

	return (
		<>
			<div
				onClick={() => handleOpenTimeSeries()}
				className={`m-2 grid cursor-pointer grid-rows-[] gap-4 rounded-lg p-2 hover:bg-gray-eee`}
				style={{ width: width !== undefined ? width - 16 : "calc(100% - 16px)" }}
			>
				<>
					<div className={`flex justify-center text-dipai-tertiary-801`}>
						<Icon iconId={iconId} size={IconSize.large} />
					</div>
					<div className="flex flex-col items-baseline">
						<div
							className={`flex w-full flex-col gap-2 text-center text-title4 text-black`}
						>
							{roundedValues.length > 1 ? (
								roundedValues.map((value, index) => (
									<div
										key={`div_${value}_${index}`}
										className={`grid w-full grid-cols-6 rounded-lg bg-white px-3 py-2 text-menu 
                                    ${
										value.value !== "0.0" && value.value !== "N/A"
											? ` bg-sky-100/50 ring-2 ring-blue-400`
											: `border-1 text-black/70`
									}
                                    `}
									>
										<p className={`col-span-4`}>{value.label} </p>
										<p className={`col-span-2 flex justify-end`}>{`${
											value.value
										} ${
											value.value !== "N/A" && unit !== undefined ? unit : ""
										}`}</p>
									</div>
								))
							) : (
								<div>
									<p className={`w-full text-center text-title4 text-black`}>
										{`${roundedValues?.[0]?.value ?? "N/A"}`}
										{unit !== undefined && roundedValues.length > 0 ? (
											<span className="text-headline">{` ${unit}`}</span>
										) : (
											""
										)}
									</p>
								</div>
							)}
						</div>
						<span
							className={`w-full pr-2 text-right text-tiny text-dipai-tertiary-801`}
						>
							{kpiTimestamp}
						</span>
					</div>
				</>

				<p className={`text-center text-small uppercase text-dipai-tertiary-801`}>
					{label}
				</p>
			</div>
			<ModalNL isOpen={isExpanded} setIsOpen={setIsExpanded}>
				<div className="fixed left-[5vw] top-[10vh] z-5 h-[80vh] w-[90vw] rounded-lg bg-white p-[20px]"></div>
			</ModalNL>
		</>
	)
}
