import { changeOpacity } from "@components/map/mapComponents/createCombinedTooltip"
import { mapLayersColors } from "@components/map/utils/mapLayers"
import ToggleSwitch from "@components/toggleSwitch/ToggleSwitch"
import { ToggleStyle } from "@components/toggleSwitch/types"
import * as AccordionPrimitive from "@radix-ui/react-accordion"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import {
	MapFiltersOil,
	getMapFiltersOil,
	updateMapFiltersOil,
} from "@redux/features/mapFiltersOil/mapFiltersOilSlice"
import { useTranslation } from "react-i18next"
import { MapFilterAccordionContent } from "./mapFilterAccordion/MapFilterAccordionContent"
import MapFilterAccordionTriggerMain from "./mapFilterAccordion/MapFilterAccordionTriggerMain"

export default function MapFiltersOilContent() {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const mapFiltersOil = useAppSelector(getMapFiltersOil)

	type MapFiltersOilSub<O extends keyof MapFiltersOil> = MapFiltersOil[O]
	type SubProp<O extends keyof MapFiltersOil> = keyof MapFiltersOilSub<O>

	const onFilterVisibilityChanged =
		<O extends keyof MapFiltersOil>(mainProp: O, subProp: SubProp<O>) =>
		(value: boolean) => {
			dispatch(
				updateMapFiltersOil({
					...mapFiltersOil,
					[mainProp]: { ...mapFiltersOil[mainProp], [subProp]: value },
				})
			)
		}

	return (
		<AccordionPrimitive.Root
			type="single"
			defaultValue="mainItem-1"
			collapsible
		>
			{/* OIL AND GAS FACILITIES */}
			{/* <SectionTitle title={t("OIL AND GAS FACILITIES")} type={SectionTitleType.vessel} />
			<InformationContainer>
				<div className="grid gap-4">
					<div className="grid grid-cols-[2fr_1fr]">
						<ToggleSwitch
							onChange={onFilterVisibilityChanged("facilities", "showSurface")}
							initialState={mapFiltersOil.facilities.showSurface}
							style={ToggleStyle.Rounded}
							label={t("SHOW SURFACE FACILITIES")}
						/>
						<div
							style={{
								backgroundColor: changeOpacity(OIL_GAS_FACILITIES_COLORS.SURFACE),
								borderColor: OIL_GAS_FACILITIES_COLORS.SURFACE,
							}}
							className={`h-6 w-6 rounded-full border-2`}
						/>
					</div>
					<div className="grid grid-cols-[2fr_1fr]">
						<ToggleSwitch
							onChange={onFilterVisibilityChanged("facilities", "showUnderwater")}
							initialState={mapFiltersOil.facilities.showUnderwater}
							style={ToggleStyle.Rounded}
							label={t("SHOW UNDERWATER FACILITIES")}
						/>
						<div
							style={{
								backgroundColor: changeOpacity(
									OIL_GAS_FACILITIES_COLORS.UNDERWATER
								),
								borderColor: OIL_GAS_FACILITIES_COLORS.UNDERWATER,
							}}
							className={`h-6 w-6 rounded-full border-2`}
						/>
					</div>
				</div>
			</InformationContainer> */}
			{/* OIL AND GAS FIELDS */}
			<AccordionPrimitive.Item value="mainItem-1">
				<MapFilterAccordionTriggerMain>
					{t("OIL AND GAS FIELDS")}
				</MapFilterAccordionTriggerMain>
				<MapFilterAccordionContent>
					<div className="flex h-auto flex-col gap-2 border-x-1 border-b-1 bg-white">
						<div className="grid">
							<div className="hover:bg-gray-f4 flex flex-row items-center justify-between gap-2 px-2 py-1">
								<ToggleSwitch
									onChange={onFilterVisibilityChanged("fields", "showOil")}
									initialState={mapFiltersOil.fields.showOil}
									style={ToggleStyle.Rounded}
									label={`${t("SHOW")} ${t(
										"OIL PRODUCTION FIELDS"
									).toLowerCase()}`}
								/>
								<div
									style={{
										backgroundColor: changeOpacity(
											mapLayersColors.oilAndGasFields.Oil,
											0.75
										),
										borderColor: mapLayersColors.oilAndGasFields.Oil,
									}}
									className={`h-5 w-5 rounded-sm border-2`}
								/>
							</div>
							<div className="hover:bg-gray-f4 flex flex-row items-center justify-between gap-2 px-2 py-1">
								<ToggleSwitch
									onChange={onFilterVisibilityChanged("fields", "showGas")}
									initialState={mapFiltersOil.fields.showGas}
									style={ToggleStyle.Rounded}
									label={`${t("SHOW")} ${t(
										"GAS PRODUCTION FIELDS"
									).toLowerCase()}`}
								/>
								<div
									style={{
										backgroundColor: changeOpacity(
											mapLayersColors.oilAndGasFields.Gas,
											0.75
										),
										borderColor: mapLayersColors.oilAndGasFields.Gas,
									}}
									className={`h-5 w-5 min-w-5 rounded-sm border-2`}
								/>
							</div>
							<div className="hover:bg-gray-f4 flex flex-row content-center items-center justify-between gap-2 px-2 py-1">
								<ToggleSwitch
									onChange={onFilterVisibilityChanged("fields", "showOilGas")}
									initialState={mapFiltersOil.fields.showOilGas}
									style={ToggleStyle.Rounded}
									label={`${t("SHOW")} ${t(
										"OIL AND GAS PRODUCTION FIELDS"
									).toLowerCase()}`}
								/>
								<div
									style={{
										backgroundColor: changeOpacity(
											mapLayersColors.oilAndGasFields["Oil/Gas"],
											0.75
										),
										borderColor: mapLayersColors.oilAndGasFields["Oil/Gas"],
									}}
									className={`h-5 w-5 rounded-sm border-2`}
								/>
							</div>
							<div className="hover:bg-gray-f4 flex flex-row content-center items-center justify-between gap-2 px-2 py-1">
								<ToggleSwitch
									onChange={onFilterVisibilityChanged(
										"fields",
										"showGasCondensate"
									)}
									initialState={mapFiltersOil.fields.showGasCondensate}
									style={ToggleStyle.Rounded}
									label={`${t("SHOW")} ${t(
										"GAS AND CONDENSATE PRODUCTION FIELDS"
									).toLowerCase()}`}
								/>
								<div
									style={{
										backgroundColor: changeOpacity(
											mapLayersColors.oilAndGasFields["Gas/Condensate"],
											0.75
										),
										borderColor:
											mapLayersColors.oilAndGasFields["Gas/Condensate"],
									}}
									className={`h-5 w-5 min-w-5 rounded-sm border-2`}
								/>
							</div>
						</div>
					</div>
				</MapFilterAccordionContent>
			</AccordionPrimitive.Item>
		</AccordionPrimitive.Root>
	)
}
