import * as AccordionPrimitive from "@radix-ui/react-accordion"
import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react"
import { MdChevronRight } from "react-icons/md"

const MapFilterAccordionTriggerSecondary = forwardRef<
	HTMLButtonElement,
	DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
>((props, ref) => {
	const { className, children, ...rest } = props
	return (
		<AccordionPrimitive.Header>
			<AccordionPrimitive.Trigger
				className={`group flex h-8 w-full flex-row items-center justify-between border-t-1 px-2 text-tiny font-bold text-dipai-secondary-800`}
				{...rest}
				ref={ref}
			>
				{children}
				<MdChevronRight
					className={`h-6 w-6 transition-transform duration-300 group-data-[state=open]:rotate-90`}
					aria-hidden
				/>
			</AccordionPrimitive.Trigger>
		</AccordionPrimitive.Header>
	)
})

export default MapFilterAccordionTriggerSecondary
