import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import { PlantInfoFE, setListOfVessels } from "@redux/features/listOfVessels/listOfVesselsSlice"
import { getOfflineVessels } from "@redux/features/offlineVessels/offlineVesselsSlice"
import { getUniSeaVessels } from "@redux/features/uniSeaVessels/uniSeaVesselsSlice"
import { useSearch } from "@tanstack/react-router"
import {
	filterPlantsPerCompanyByVessel,
	getGetVesselInfoArgs,
	getOpModeGetLiveArgs,
	getPlantsPerCompanyFilter,
	listPlantsPerCompany,
} from "@utils/listPlantsPerCompany"
import useGetLiveOpModeQuery from "@utils/queries/useGetLiveOpModeQuery"
import useVesselInfoOperationQuery from "@utils/queries/useVesselInfoOperationQuery"
import { AppStructure, PlantInfo } from "api"
import { useEffect, useState } from "react"

export default function useGetShipDataForMap(
	props: AppStructure,
	clientId: string,
	specificPlant: string | undefined,
	useUrlPlantId: boolean = true
): [PlantInfo[], boolean, boolean] {
	const dispatch = useAppDispatch()

	const paramSelectedVessel = useSearch({ strict: false })?.vessel ?? ""
	const [shipData, setShipData] = useState<PlantInfo[]>([])
	const offlineVessels = useAppSelector(getOfflineVessels)
	const uniSeaVessels = useAppSelector(getUniSeaVessels)

	const mainClientName = Object.keys(props)[0]
	const inputProps =
		mainClientName === "Dipai" && props["Dipai"] !== undefined ? props["Dipai"].clients : props
	let subCompanyFilter: { [referenceCompanyName: string]: string[] } | undefined
	const clientIdSplited = clientId.split(":")
	const clientIdSplited_0 = clientIdSplited?.[0]

	if (clientIdSplited.length === 1 && clientIdSplited_0 !== undefined) {
		subCompanyFilter = {}
		let referenceCompany: string
		for (const [subClientName, subClientData] of Object.entries(inputProps)) {
			if (
				Object.keys(subClientData.clients).includes(clientId) ||
				clientId === subClientName
			) {
				referenceCompany = subClientName
				subCompanyFilter[referenceCompany] = [clientIdSplited_0]
				break
			}
		}
	}

	const plantsPerCompany = listPlantsPerCompany(inputProps, subCompanyFilter)
	const plantsPerCompanyFiltered = filterPlantsPerCompanyByVessel(
		plantsPerCompany,
		specificPlant,
		useUrlPlantId === true ? paramSelectedVessel : undefined
	)

	const filter = getPlantsPerCompanyFilter(
		clientId,
		{ plantId: paramSelectedVessel },
		specificPlant
	)
	const postDataVesselInfo = getGetVesselInfoArgs(plantsPerCompanyFiltered)
	const postDataOpModeLive = getOpModeGetLiveArgs(plantsPerCompanyFiltered)

	const {
		isLoading: isPositionDataLoading,
		isError: isPositionDataError,
		data: positionDataTemp,
	} = useVesselInfoOperationQuery({
		postData: postDataVesselInfo,
		plantsPerCompany: plantsPerCompanyFiltered,
		filter,
	})

	const {
		isLoading: isLiveOpModeDataLoading,
		isError: isLiveOpModeDataError,
		data: liveOpModeDataTemp,
	} = useGetLiveOpModeQuery({
		postData: postDataOpModeLive,
		plantsPerCompany: plantsPerCompanyFiltered,
		filter,
	})

	useEffect(() => {
		const processVesselsData = () => {
			if (
				positionDataTemp === undefined ||
				positionDataTemp.length === 0 ||
				liveOpModeDataTemp === undefined ||
				liveOpModeDataTemp === null ||
				liveOpModeDataTemp.plants.length === 0
			) {
				return
			}

			const positionDataTempCopy: PlantInfoFE[] = JSON.parse(JSON.stringify(positionDataTemp))
			const listOfOfflineVesselsId = offlineVessels.map((ov) => ov.id)
			const listOfUniSeaVesselsId = uniSeaVessels.map((ov) => ov.id)

			for (const positionData of positionDataTempCopy) {
				for (const liveOpModeData of liveOpModeDataTemp.plants) {
					if (positionData.plantId !== liveOpModeData.plantId) {
						continue
					}

					positionData.condition = listOfUniSeaVesselsId.includes(positionData.plantId)
						? "UniSea"
						: listOfOfflineVesselsId.includes(positionData.plantId)
							? "offline"
							: "online"
					positionData["latestOperationMode2"] = liveOpModeData
				}
			}

			dispatch(
				setListOfVessels({
					loaded: true,
					vessels: positionDataTempCopy,
				})
			)
			setShipData(positionDataTempCopy)
		}
		processVesselsData()
	}, [positionDataTemp, liveOpModeDataTemp, offlineVessels])

	return [shipData, isPositionDataLoading, isLiveOpModeDataLoading]
}
