import { ModuleNameSOP } from "@routes/clients.$clientName/_sopPageLayout"
import { getWarningCondition, WARNING_DIALOG_HEIGHT } from "@sop/DashboardPage/DashboardPage"
import { useLocation, useNavigate, useSearch } from "@tanstack/react-router"
import { FC, ReactNode, useEffect, useState } from "react"
import { MdClose, MdKeyboardDoubleArrowRight } from "react-icons/md"
import { IconButton } from "../../IconButton"
import { Tooltip } from "../../Tooltip"
import { ModulesContraint } from "../BodyLayout/BodyLayout"
import { usePageLayoutContext } from "../layoutContext"
import { SecondaryNavItem } from "./SecondaryNavItem"

const transitionTypes = "[transition:transform_0.3s,opacity_0.3s]"
const transitionDuration = `duration-[300ms]`

export interface ISecondaryNavProps {
	label: string
	items: { plantName: string; icons?: ReactNode[] }[]
	moduleConstraints: ModulesContraint[]
}

export const SecondaryNav: FC<ISecondaryNavProps> = (props: ISecondaryNavProps) => {
	const { label, items, moduleConstraints } = props
	const navigate = useNavigate()

	const {
		selectedModuleMetadata,
		translationFunc,
		isSecondaryNavExpanded,
		setIsSecondaryNavExpanded,
	} = usePageLayoutContext()

	const selectedModule = useLocation().pathname.split("/").at(-1) as ModuleNameSOP

	const paramSelectedVessel =
		useSearch({
			strict: false,
		}).vessel ?? ""

	const itemsWithConstraints = items.map((item) => {
		const currentVesselConstraints: ModulesContraint[] = moduleConstraints.filter(
			(constraint) => constraint.vessels.includes(item.plantName)
		)
		const availableModules = currentVesselConstraints.flatMap(
			(constraint) => constraint.availableModules
		)
		return {
			...item,
			isDisabled:
				availableModules.length === 0
					? false
					: availableModules.includes(selectedModule) === true
						? false
						: true,
		}
	})
	const [searchTerm, setSearchTerm] = useState("")
	const [filteredItems, setFilteredItems] =
		useState<{ plantName: string; icons?: ReactNode[]; isDisabled: boolean }[]>(
			itemsWithConstraints
		)

	useEffect(() => {
		const filtered = itemsWithConstraints.filter((item) =>
			item.plantName.toLowerCase().includes(searchTerm.toLowerCase())
		)
		setFilteredItems(filtered)
	}, [items, selectedModule])

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value
		setSearchTerm(value)
		const filtered = itemsWithConstraints.filter((item) =>
			item.plantName.toLowerCase().includes(value.toLowerCase())
		)
		setFilteredItems(filtered)
	}
	const handleClear = () => {
		setSearchTerm("")
		setFilteredItems(itemsWithConstraints)
	}

	const warningCondition = getWarningCondition()

	return (
		<>
			{selectedModuleMetadata === undefined ||
			selectedModuleMetadata.config?.hideVesselSelection === true ? null : (
				<div
					id="secondaryNav"
					style={{
						height:
							warningCondition === true
								? `calc(100vh - ${WARNING_DIALOG_HEIGHT}px)`
								: `100vh`,
					}}
					className={`border-dipai-secondary-800 bg-dipai-secondary-901 shadow-side-menu-l absolute top-[52px] z-99 flex w-auto flex-col justify-between border-r-0 sm:relative sm:top-0 ${
						warningCondition === true ? "sm:h-[calc(100vh-56px)]" : "sm:h-[100vh]"
					}`}
				>
					<div
						className={`transition-[width] ${transitionDuration} ${
							isSecondaryNavExpanded === false ? "w-[52px] sm:w-9" : "w-[212px]"
						}`}
					>
						<div
							className={`text-title5 flex h-[52px] w-full items-center border-b-gray-900/20 font-bold text-white/90 ${
								isSecondaryNavExpanded === true
									? "border-b-1 shadow-[0px_1.5px_1px_hsla(0,0%,100%,0.15)]"
									: ""
							}`}
						>
							<p
								className={`ml-3 origin-left ${transitionTypes} ${
									isSecondaryNavExpanded === false
										? "scale-x-0 opacity-0"
										: "scale-x-100 opacity-100"
								}`}
							>
								{translationFunc !== undefined
									? translationFunc(label.toUpperCase())
									: label}
							</p>
						</div>
						<div
							className={`relative m-3 w-[calc(100%-24px)] origin-left ${transitionTypes} ${
								isSecondaryNavExpanded === false
									? "scale-x-0 opacity-0"
									: "scale-x-100 opacity-100"
							}`}
						>
							<input
								className={`border-gray-ccc/90 text-small placeholder:text-gray-ccc hover:border-dipai-primary-501 focus:border-dipai-primary-501 h-8 w-full rounded-sm border-1 bg-white/10 py-[2px] pr-8 pl-2 text-white hover:cursor-text focus:border-2 focus:py-[1px] focus:pl-[7px] focus:ring-0 focus:outline-hidden`}
								type="text"
								placeholder={
									translationFunc !== undefined
										? translationFunc("SEARCH VESSEL")
										: "Search vessel..."
								}
								value={searchTerm}
								onChange={handleSearch}
							/>
							{searchTerm !== "" ? (
								<IconButton
									className={`absolute top-1/2 right-4 -translate-y-1/2 cursor-pointer border-none text-white/60 hover:text-white/100`}
									variant={"text-light"}
									size={"sm"}
									onClick={handleClear}
								>
									<IconButton.Icon>{MdClose}</IconButton.Icon>
								</IconButton>
							) : null}
						</div>
						<div
							style={{
								height:
									warningCondition === true
										? `calc(100vh - 52px - 52px - 56px - ${WARNING_DIALOG_HEIGHT}px)`
										: `calc(100vh - 52px - 52px - 56px)`,
							}}
							className={`ml-3 w-[calc(100%-12px)] origin-left overflow-auto overflow-x-hidden pr-3 pb-3 ${
								warningCondition === true
									? "sm:h-[calc(100vh-52px-52px-56px-56px)]"
									: "sm:h-[calc(100vh-52px-52px-56px)]"
							} ${transitionTypes} ${
								isSecondaryNavExpanded === false
									? "scale-x-0 opacity-0"
									: "scale-x-100 opacity-100"
							}`}
						>
							{filteredItems
								.sort((a, b) =>
									a.plantName > b.plantName
										? 1
										: a.plantName < b.plantName
											? -1
											: 0
								)
								.map((item, index) => {
									return (
										<div
											tabIndex={-1}
											key={`${item}_${index}`}
											onClick={() => {
												if (item.isDisabled === true) {
													return
												}
												navigate({
													to: ".",
													search: (prev) => ({
														...prev,
														vessel: item.plantName,
													}),
												})
											}}
										>
											<SecondaryNavItem
												isSelected={item.plantName === paramSelectedVessel}
												label={item.plantName}
												icons={item.icons}
												isDisabled={item.isDisabled}
											/>
										</div>
									)
								})}
						</div>
						<div
							style={{
								width:
									warningCondition === true
										? `calc(100vh - ${WARNING_DIALOG_HEIGHT}px)`
										: `100vh`,
							}}
							className={`gap-4 overflow-clip pr-[84px] transition-[height] duration-0 sm:pr-[28px] ${
								isSecondaryNavExpanded === true
									? "h-0 delay-[0ms]"
									: "h-[52px] delay-[200ms] sm:h-[36px]"
							} absolute -right-[0px] bottom-[-10px] left-[26px] flex origin-left -rotate-90 flex-row items-center justify-end sm:left-[18px]`}
						>
							{paramSelectedVessel === "" ? (
								<p
									className={`text-title5 font-bold whitespace-nowrap text-white/90`}
								>
									{translationFunc !== undefined
										? translationFunc(label.toUpperCase())
										: label}
								</p>
							) : (
								<>
									<p
										className={`text-title5 font-bold whitespace-nowrap text-white/90`}
									>
										{paramSelectedVessel}
									</p>
									{
										itemsWithConstraints.find(
											(item) => item.plantName === paramSelectedVessel
										)?.icons
									}
								</>
							)}
						</div>
						<div
							className={`flex h-[52px] w-full items-center justify-end pr-1 ${
								isSecondaryNavExpanded === true
									? "border-t-1 border-t-gray-900/20 shadow-[inset_0px_1.5px_1px_hsla(0,0%,100%,0.15)]"
									: ""
							}`}
						>
							<Tooltip
								side={"right"}
								sideOffset={20}
								customContent={
									translationFunc !== undefined
										? translationFunc(
												isSecondaryNavExpanded === true
													? "SHOW LESS"
													: "SHOW MORE"
											)
										: isSecondaryNavExpanded === true
											? "SHOW LESS"
											: "SHOW MORE"
								}
							>
								<button
									onMouseDown={(e) => {
										const elem = document.activeElement
										if (elem instanceof HTMLElement) {
											elem.blur()
										}
										e.preventDefault()
									}}
									onClick={() => {
										// localStorage.setItem(
										// 	"isSecondaryNavExpanded",
										// 	`${!isSecondaryNavExpanded}`
										// )
										setIsSecondaryNavExpanded((prev) => !prev)

										// Ensures that map components are properly resized and updated
										var event = new Event("resize")
										setTimeout(() => {
											window.dispatchEvent(event)
										}, 500)
									}}
									className={`focus-style h-6 w-6 cursor-pointer text-white/60 hover:text-white/100`}
								>
									<MdKeyboardDoubleArrowRight
										tabIndex={-1}
										className={`h-5 w-5 scale-y-125 transition-transform ${transitionDuration} ease-in-out ${
											isSecondaryNavExpanded === false
												? `rotate-0`
												: `rotate-180`
										}`}
									/>
								</button>
							</Tooltip>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
