import * as SwitchPrimitive from "@radix-ui/react-switch"
import { ReactElement } from "react"
import { LabelPosition, ToggleStyle } from "./types"

type LabelProps = {
	id: string
	label: string
	labelPosition: LabelPosition
	textColor: string
}

export const Label = ({ id, label, labelPosition, textColor }: LabelProps): ReactElement => {
	return (
		<label
			htmlFor={id}
			className={`${
				labelPosition === LabelPosition.left ? "pr-[10px]" : "pl-[10px]"
			} text-button ${textColor} cursor-pointer`}
		>
			{label}
		</label>
	)
}

export type ToggleSwitchProps = {
	disabled?: boolean
	initialState?: boolean
	label?: string
	labelPosition?: LabelPosition
	onChange?: (value: boolean) => void
	style?: ToggleStyle
	textColor?: string
}

export default function ToggleSwitch({
	disabled = false,
	initialState = false,
	label = "",
	labelPosition = LabelPosition.right,
	onChange,
	style = ToggleStyle.Rounded,
	textColor,
}: ToggleSwitchProps): ReactElement {
	const onCheckedChange = () => {
		if (onChange !== undefined) {
			onChange(!initialState)
		}
	}

	return (
		<div className={`${disabled === true && "opacity-30"} group flex items-center`}>
			{labelPosition === LabelPosition.left && (
				<Label
					id={`${label}`}
					label={label}
					labelPosition={labelPosition}
					textColor={textColor ?? "text-gray-666"}
				/>
			)}
			<SwitchPrimitive.Root
				className={`relative h-[20px] w-9 min-w-9 ${
					style === ToggleStyle.Rounded ? "rounded-full" : "rounded-md"
				} ${initialState === true ? "bg-dipai-secondary-800" : "bg-dipai-tertiary-400"}`}
				defaultChecked={false}
				disabled={disabled}
				onCheckedChange={onCheckedChange}
				id={`${label}`}
			>
				<SwitchPrimitive.Thumb
					className={`${
						initialState === true
							? `translate-x-[20px] bg-white`
							: `translate-x-[4px] bg-white`
					} block h-[12px] w-[12px] ${
						style === ToggleStyle.Rounded ? "rounded-full" : "rounded-md"
					}   transition-transform group-hover:scale-110`}
				/>
			</SwitchPrimitive.Root>
			{labelPosition === LabelPosition.right && (
				<Label
					id={`${label}`}
					label={label}
					labelPosition={labelPosition}
					textColor={textColor ?? "text-gray-666"}
				/>
			)}
		</div>
	)
}
