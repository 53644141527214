import { Row, flexRender } from "@tanstack/react-table";
import { Fragment } from "react";

export function BodyContentLayout<T>({ row }: { row: Row<T> }) {
    return (
        <>
            {row.getVisibleCells().map((cell) => (
                <Fragment key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Fragment>
            ))}
        </>
    );
}
