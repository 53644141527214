import ReactSelect from "react-select"
import "./multiSelect.css" //do i need this?

export default function SingleSelect({
	options,
	value,
	onChange,
	required = false,
	disabled = false,
	isClearable = false,
}: {
	options
	value
	onChange
	required?: boolean
	disabled?: boolean
	isClearable?: boolean
}) {
	const styles = (stateValue) => ({
		menu: (baseStyles) => ({ ...baseStyles, padding: "4px 4px 0px 4px" }),
		// menuList: (provided) => ({ ...provided, position: 'absolute', zIndex: 100, }),
		menuPortal: (baseStyles) => ({ ...baseStyles, zIndex: 9999 }),
		valueContainer: (baseStyles) => ({ ...baseStyles, padding: "0px 0px 0px 4px", height: 30 }),
		container: (baseStyles) => ({ ...baseStyles, padding: "0px 0px 0px 0px", height: 32 }),
		control: (baseStyles) => ({
			...baseStyles,
			minHeight: 32,
			height: 32,
			borderRadius: 2,
			":hover": { borderColor: "#4d82aa", cursor: "pointer" },
			borderColor: stateValue === "" && required ? "red" : "#ccc",
		}),
		input: (baseStyles) => ({ ...baseStyles, height: 20, padding: 0 }),
		placeholder: (baseStyles) => ({ ...baseStyles, padding: 0, margin: "0px 0px 3px 4px" }),
		singleValue: (baseStyles) => ({ ...baseStyles, padding: 0, margin: "0px 0px 2px 4px" }),
		multiValue: (baseStyles) => ({ ...baseStyles, padding: 0, margin: "0px 0px 2px 4px" }),
		multiValueLabel: (baseStyles) => ({ ...baseStyles, padding: 0, margin: 0 }),
		dropdownIndicator: (baseStyles) => ({ ...baseStyles, padding: 5, margin: 0 }),
		indicatorsContainer: (baseStyles) => ({ ...baseStyles, height: 32 }),
		clearIndicator: (baseStyles) => ({ ...baseStyles, padding: 5, margin: 0 }),
		option: (baseStyles) => ({
			...baseStyles,
			borderRadius: 2,
			padding: "4px 8px 8px 8px",
			margin: 1,
			":hover": { cursor: "pointer" },
		}),
	})

	return (
		<ReactSelect
			classNamePrefix="mySelect"
			closeMenuOnSelect={true}
			closeMenuOnScroll
			isClearable={isClearable}
			hideSelectedOptions={false}
			isDisabled={disabled}
			isMulti={false}
			isSearchable={true}
			menuPlacement={"auto"}
			menuPortalTarget={document.body}
			onChange={onChange}
			options={options}
			placeholder={"Select..."}
			styles={styles(value)}
			value={value}
		/>
	)
}
