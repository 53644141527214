export type FacilityFixed = {
	fclName: string
	fclPhase: string
	fclSurface: string
	fclCurrentOperatorName: string
	fclKind: string
	fclBelongsToName: string
	fclBelongsToKind: string
	fclBelongsToS: string
	fclStartupDate: string
	fclGeodeticDatum: string
	fclNsDeg: string
	fclNsMin: string
	fclNsSec: string
	fclNsCode: string
	fclEwDeg: string
	fclEwMin: string
	fclEwSec: string
	fclEwCode: string
	fclWaterDepth: string
	fclFunctions: string
	fclDesignLifetime: string
	fclFactPageUrl: string
	fclFactMapUrl: string
	fclNpdidFacility: string
	fclDateUpdated: string
	datesyncNPD: string
}

export function getFacilityFixed(): FacilityFixed[] {
	return [
		{
			fclName: "1/2-1 IM Blane",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Repsol Norge AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BLANE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "3437650",
			fclStartupDate: "12.09.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "54",
			fclNsSec: "30.57",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "15.12",
			fclEwCode: "E",
			fclWaterDepth: "74",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=373104",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=373104",
			fclNpdidFacility: "373104",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "1/2-1 PE Blane",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Repsol Norge AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BLANE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "3437650",
			fclStartupDate: "12.09.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "54",
			fclNsSec: "29.87",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "15.87",
			fclEwCode: "E",
			fclWaterDepth: "74",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=373068",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=373068",
			fclNpdidFacility: "373068",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "1/2-1 PW Blane",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Repsol Norge AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BLANE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "3437650",
			fclStartupDate: "12.09.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "54",
			fclNsSec: "29.86",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "14.39",
			fclEwCode: "E",
			fclWaterDepth: "74",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=373030",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=373030",
			fclNpdidFacility: "373030",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "10/1-CDP1",
			fclPhase: "PARTLY REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "CONCRETE STRUCTURE",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "01.09.1979",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "52",
			fclNsSec: "31.04",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "3",
			fclEwSec: "42.44",
			fclEwCode: "E",
			fclWaterDepth: "100",
			fclFunctions: "DRILLING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271861",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271861",
			fclNpdidFacility: "271861",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "15/12-C Rev",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Repsol Norge AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "REV",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4467554",
			fclStartupDate: "26.01.2009",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "1",
			fclNsSec: "40.68",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "55",
			fclEwSec: "28.34",
			fclEwCode: "E",
			fclWaterDepth: "113",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "10",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=378147",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=378147",
			fclNpdidFacility: "378147",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "15/12-D Rev",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Repsol Norge AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "REV",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4467554",
			fclStartupDate: "01.09.2009",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "1",
			fclNsSec: "42.62",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "55",
			fclEwSec: "23.63",
			fclEwCode: "E",
			fclWaterDepth: "113",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "10",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=402804",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=402804",
			fclNpdidFacility: "402804",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "15/12-E-1 H Gaupe",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "A/S Norske Shell",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GAUPE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "18161341",
			fclStartupDate: "31.03.2012",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "0",
			fclNsSec: "42.99",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "55",
			fclEwSec: "31.50",
			fclEwCode: "E",
			fclWaterDepth: "87",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=410712",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=410712",
			fclNpdidFacility: "410712",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "15/5-A-5 H Glitne",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GLITNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1272071",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "42",
			fclNsSec: "45.73",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "39",
			fclEwSec: "30.35",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=442552",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=442552",
			fclNpdidFacility: "442552",
			fclDateUpdated: "19.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "15/5-A-7 H Glitne",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GLITNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1272071",
			fclStartupDate: "30.11.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "42",
			fclNsSec: "45.20",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "37",
			fclEwSec: "51.70",
			fclEwCode: "E",
			fclWaterDepth: "109",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=377347",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=377347",
			fclNpdidFacility: "377347",
			fclDateUpdated: "19.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "15/5-B-1 H Glitne",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GLITNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1272071",
			fclStartupDate: "29.08.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "43",
			fclNsSec: "58.42",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "38",
			fclEwSec: "33.42",
			fclEwCode: "E",
			fclWaterDepth: "110",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283789",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283789",
			fclNpdidFacility: "283789",
			fclDateUpdated: "19.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "15/6-A-1 H Glitne",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GLITNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1272071",
			fclStartupDate: "29.08.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "42",
			fclNsSec: "46.90",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "40",
			fclEwSec: "5.20",
			fclEwCode: "E",
			fclWaterDepth: "112",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280233",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280233",
			fclNpdidFacility: "280233",
			fclDateUpdated: "19.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "15/6-A-2 H Glitne",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GLITNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1272071",
			fclStartupDate: "29.08.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "42",
			fclNsSec: "46.30",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "40",
			fclEwSec: "5.80",
			fclEwCode: "E",
			fclWaterDepth: "112",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=364886",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=364886",
			fclNpdidFacility: "364886",
			fclDateUpdated: "19.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "15/6-A-3 H Glitne",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GLITNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1272071",
			fclStartupDate: "29.08.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "42",
			fclNsSec: "46.61",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "40",
			fclEwSec: "5.41",
			fclEwCode: "E",
			fclWaterDepth: "112",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=364927",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=364927",
			fclNpdidFacility: "364927",
			fclDateUpdated: "19.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "15/6-A-4 H Glitne",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GLITNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1272071",
			fclStartupDate: "29.08.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "42",
			fclNsSec: "44.30",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "40",
			fclEwSec: "5.70",
			fclEwCode: "E",
			fclWaterDepth: "112",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=365003",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=365003",
			fclNpdidFacility: "365003",
			fclDateUpdated: "19.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "15/6-A-5 H Glitne",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GLITNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1272071",
			fclStartupDate: "30.09.2003",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "42",
			fclNsSec: "46.18",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "40",
			fclEwSec: "0.66",
			fclEwCode: "E",
			fclWaterDepth: "112",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=365044",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=365044",
			fclNpdidFacility: "365044",
			fclDateUpdated: "19.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "15/6-A-6 H Glitne",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GLITNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1272071",
			fclStartupDate: "01.08.2004",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "41",
			fclNsSec: "38.59",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "40",
			fclEwSec: "25.17",
			fclEwCode: "E",
			fclWaterDepth: "112",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=409489",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=409489",
			fclNpdidFacility: "409489",
			fclDateUpdated: "19.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "15/9-E",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SLEIPNER VEST",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43457",
			fclStartupDate: "11.10.2004",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "29",
			fclNsSec: "49.14",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "43",
			fclEwSec: "21.73",
			fclEwCode: "E",
			fclWaterDepth: "110",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280821",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280821",
			fclNpdidFacility: "280821",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "16/1-CA ROLVSNES",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "ABP Norway AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "",
			fclStartupDate: "30.06.2021",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "48",
			fclNsSec: "51.49",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "16",
			fclEwSec: "14.20",
			fclEwCode: "E",
			fclWaterDepth: "106",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=717185",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=717185",
			fclNpdidFacility: "717185",
			fclDateUpdated: "10.07.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "16/4 SOLVEIG BA",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "ABP Norway AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "SOLVEIG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "34833011",
			fclStartupDate: "30.09.2021",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "41",
			fclNsSec: "11.80",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "12",
			fclEwSec: "15.30",
			fclEwCode: "E",
			fclWaterDepth: "100",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=715043",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=715043",
			fclNpdidFacility: "715043",
			fclDateUpdated: "10.07.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "16/4 SOLVEIG BB",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "ABP Norway AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "SOLVEIG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "34833011",
			fclStartupDate: "30.09.2021",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "41",
			fclNsSec: "3.29",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "11",
			fclEwSec: "58.17",
			fclEwCode: "E",
			fclWaterDepth: "100",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=715341",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=715341",
			fclNpdidFacility: "715341",
			fclDateUpdated: "10.07.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "16/4 SOLVEIG BC",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "ABP Norway AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "SOLVEIG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "34833011",
			fclStartupDate: "30.09.2021",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "40",
			fclNsSec: "39.36",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "12",
			fclEwSec: "2.76",
			fclEwCode: "E",
			fclWaterDepth: "100",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=715639",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=715639",
			fclNpdidFacility: "715639",
			fclDateUpdated: "10.07.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "16/4 SOLVEIG BD",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "ABP Norway AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "SOLVEIG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "34833011",
			fclStartupDate: "30.09.2021",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "40",
			fclNsSec: "52.40",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "11",
			fclEwSec: "48.80",
			fclEwCode: "E",
			fclWaterDepth: "100",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=715937",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=715937",
			fclNpdidFacility: "715937",
			fclDateUpdated: "10.07.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "16/4 SOLVEIG BE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "ABP Norway AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "SOLVEIG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "34833011",
			fclStartupDate: "30.09.2021",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "40",
			fclNsSec: "39.41",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "12",
			fclEwSec: "10.08",
			fclEwCode: "E",
			fclWaterDepth: "100",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=716235",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=716235",
			fclNpdidFacility: "716235",
			fclDateUpdated: "10.07.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "24/6-A-5 Alvheim",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "ALVHEIM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2845712",
			fclStartupDate: "01.04.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "34",
			fclNsSec: "36.03",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "55",
			fclEwSec: "25.65",
			fclEwCode: "E",
			fclWaterDepth: "125",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=445917",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=445917",
			fclNpdidFacility: "445917",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "24/6-A-6 H Alvheim",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "ALVHEIM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2845712",
			fclStartupDate: "01.02.2018",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "34",
			fclNsSec: "35.41",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "55",
			fclEwSec: "24.45",
			fclEwCode: "E",
			fclWaterDepth: "121",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=727047",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=727047",
			fclNpdidFacility: "727047",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "24/6-A-7 Alvheim",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "ALVHEIM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2845712",
			fclStartupDate: "01.02.2018",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "34",
			fclNsSec: "35.92",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "55",
			fclEwSec: "23.29",
			fclEwCode: "E",
			fclWaterDepth: "121",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=727345",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=727345",
			fclNpdidFacility: "727345",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "24/6-B-5 Alvheim",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "ALVHEIM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2845712",
			fclStartupDate: "01.12.2012",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "32",
			fclNsSec: "46.09",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "59",
			fclEwSec: "22.93",
			fclEwCode: "E",
			fclWaterDepth: "124",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=428267",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=428267",
			fclNpdidFacility: "428267",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "24/6-B-6 KIS",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "ALVHEIM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2845712",
			fclStartupDate: "01.10.2018",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "32",
			fclNsSec: "45.08",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "59",
			fclEwSec: "17.01",
			fclEwCode: "E",
			fclWaterDepth: "124",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=727915",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=727915",
			fclNpdidFacility: "727915",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "24/9-P-10 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VOLUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4380167",
			fclStartupDate: "01.08.2017",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "29",
			fclNsSec: "42.49",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "58",
			fclEwSec: "2.75",
			fclEwCode: "E",
			fclWaterDepth: "123",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=729378",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=729378",
			fclNpdidFacility: "729378",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "24/9-P-6 AH Volund",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VOLUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4380167",
			fclStartupDate: "24.01.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "29",
			fclNsSec: "45.25",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "57",
			fclEwSec: "56.75",
			fclEwCode: "E",
			fclWaterDepth: "124",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=429693",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=429693",
			fclNpdidFacility: "429693",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "24/9-P-7 VIPER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VOLUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4380167",
			fclStartupDate: "10.11.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "29",
			fclNsSec: "42.00",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "57",
			fclEwSec: "60.00",
			fclEwCode: "E",
			fclWaterDepth: "124",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=448895",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=448895",
			fclNpdidFacility: "448895",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "24/9-P-8 KOBRA",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VOLUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4380167",
			fclStartupDate: "10.11.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "29",
			fclNsSec: "43.18",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "58",
			fclEwSec: "0.94",
			fclEwCode: "E",
			fclWaterDepth: "124",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=449134",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=449134",
			fclNpdidFacility: "449134",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "24/9-P-9 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VOLUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4380167",
			fclStartupDate: "01.07.2017",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "29",
			fclNsSec: "41.90",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "58",
			fclEwSec: "0.99",
			fclEwCode: "E",
			fclWaterDepth: "123",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=728943",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=728943",
			fclNpdidFacility: "728943",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/10 WI BALDER",
			fclPhase: "FABRICATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "14",
			fclNsSec: "7.80",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "21",
			fclEwSec: "7.58",
			fclEwCode: "E",
			fclWaterDepth: "125",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=720234",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=720234",
			fclNpdidFacility: "720234",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11 BALDER B",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "10",
			fclNsSec: "36.59",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "22",
			fclEwSec: "39.56",
			fclEwCode: "E",
			fclWaterDepth: "125",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=718441",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=718441",
			fclNpdidFacility: "718441",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11 BALDER D",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "59.52",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "24",
			fclEwSec: "47.00",
			fclEwCode: "E",
			fclWaterDepth: "125",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=718740",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=718740",
			fclNpdidFacility: "718740",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11 BALDER FT",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "13",
			fclNsSec: "7.39",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "25",
			fclEwSec: "31.68",
			fclEwCode: "E",
			fclWaterDepth: "125",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=719637",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=719637",
			fclNpdidFacility: "719637",
			fclDateUpdated: "25.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11 BALDER GT",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "14",
			fclNsSec: "20.85",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "26",
			fclEwSec: "55.57",
			fclEwCode: "E",
			fclWaterDepth: "125",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=719039",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=719039",
			fclNpdidFacility: "719039",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11 M4W BALDER",
			fclPhase: "FABRICATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "9",
			fclNsSec: "56.29",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "19",
			fclEwSec: "3.20",
			fclEwCode: "E",
			fclWaterDepth: "125",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=719936",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=719936",
			fclNpdidFacility: "719936",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-A-19 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "25.06.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "30.09",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "21",
			fclEwSec: "31.68",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=341394",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=341394",
			fclNpdidFacility: "341394",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-A-20 H BALDER",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "10.10.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "27.40",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "21",
			fclEwSec: "36.17",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282753",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282753",
			fclNpdidFacility: "282753",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-A-21 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "01.09.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "29.61",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "21",
			fclEwSec: "29.42",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282949",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282949",
			fclNpdidFacility: "282949",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-A-22 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "31.10.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "27.42",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "21",
			fclEwSec: "31.55",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=429055",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=429055",
			fclNpdidFacility: "429055",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-A-23 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "28.02.2014",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "30.09",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "21",
			fclEwSec: "27.02",
			fclEwCode: "E",
			fclWaterDepth: "126",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=429114",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=429114",
			fclNpdidFacility: "429114",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-A-27 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "30.06.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "27.32",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "21",
			fclEwSec: "27.63",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=442355",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=442355",
			fclNpdidFacility: "442355",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-A-4 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "13.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "29.29",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "21",
			fclEwSec: "35.47",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283173",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283173",
			fclNpdidFacility: "283173",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-A-5 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "15.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "30.22",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "21",
			fclEwSec: "34.19",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283229",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283229",
			fclNpdidFacility: "283229",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-A-6 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "13.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "28.33",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "21",
			fclEwSec: "37.41",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283257",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283257",
			fclNpdidFacility: "283257",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-A-8 H BALDER",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "29.83",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "21",
			fclEwSec: "32.63",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "WATER PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284629",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284629",
			fclNpdidFacility: "284629",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-B-11 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "15.10.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "10",
			fclNsSec: "42.54",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "22",
			fclEwSec: "44.00",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283817",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283817",
			fclNpdidFacility: "283817",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-B-14 H BALDER",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "04.10.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "10",
			fclNsSec: "42.75",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "22",
			fclEwSec: "45.85",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283845",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283845",
			fclNpdidFacility: "283845",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-B-18 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "01.04.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "10",
			fclNsSec: "39.19",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "22",
			fclEwSec: "46.05",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283873",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283873",
			fclNpdidFacility: "283873",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-B-24 H",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "18.11.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "10",
			fclNsSec: "41.22",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "22",
			fclEwSec: "42.71",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=430439",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=430439",
			fclNpdidFacility: "430439",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-B-28 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "01.04.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "10",
			fclNsSec: "41.77",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "22",
			fclEwSec: "40.73",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=448015",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=448015",
			fclNpdidFacility: "448015",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-B-7 H BALDER ",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "14.10.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "10",
			fclNsSec: "40.57",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "22",
			fclEwSec: "46.36",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283901",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283901",
			fclNpdidFacility: "283901",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-C-12 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "04.10.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "10",
			fclNsSec: "59.93",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "24",
			fclEwSec: "36.26",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283929",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283929",
			fclNpdidFacility: "283929",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-C-13 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "19.10.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "10",
			fclNsSec: "59.87",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "24",
			fclEwSec: "34.62",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283957",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283957",
			fclNpdidFacility: "283957",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-C-17 H BALDER",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "18.10.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "10",
			fclNsSec: "59.03",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "24",
			fclEwSec: "35.48",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283985",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283985",
			fclNpdidFacility: "283985",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-C-3 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "14.10.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "0.52",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "24",
			fclEwSec: "32.53",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284013",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284013",
			fclNpdidFacility: "284013",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-D-1 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "04.10.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "12",
			fclNsSec: "1.03",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "24",
			fclEwSec: "37.02",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284041",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284041",
			fclNpdidFacility: "284041",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-D-10 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "02.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "12",
			fclNsSec: "0.37",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "24",
			fclEwSec: "35.21",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284181",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284181",
			fclNpdidFacility: "284181",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-D-2 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "30.09.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "59.60",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "24",
			fclEwSec: "33.16",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284209",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284209",
			fclNpdidFacility: "284209",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-D-25 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "15.04.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "59.90",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "24",
			fclEwSec: "37.42",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=430498",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=430498",
			fclNpdidFacility: "430498",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-D-26 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "15.04.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "57.96",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "24",
			fclEwSec: "37.33",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=442604",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=442604",
			fclNpdidFacility: "442604",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/11-E-29 H BALDER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "31.08.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "13",
			fclNsSec: "12.13",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "21",
			fclEwSec: "44.80",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=447481",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=447481",
			fclNpdidFacility: "447481",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/1-S SKOGUL",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "SKOGUL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "31164600",
			fclStartupDate: "01.03.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "47",
			fclNsSec: "38.66",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "10",
			fclEwSec: "57.21",
			fclEwCode: "E",
			fclWaterDepth: "110",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=506928",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=506928",
			fclNpdidFacility: "506928",
			fclDateUpdated: "09.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/4-D1 H VILJE D1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VILJE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "3392471",
			fclStartupDate: "01.08.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "39",
			fclNsSec: "22.04",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "16",
			fclEwSec: "40.90",
			fclEwCode: "E",
			fclWaterDepth: "122",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=374865",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=374865",
			fclNpdidFacility: "374865",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/4-E1 H VILJE E1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VILJE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "3392471",
			fclStartupDate: "01.08.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "39",
			fclNsSec: "22.93",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "16",
			fclEwSec: "42.24",
			fclEwCode: "E",
			fclWaterDepth: "122",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=376298",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=376298",
			fclNpdidFacility: "376298",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/4-F-1 H VILJE F1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VILJE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "3392471",
			fclStartupDate: "10.04.2014",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "39",
			fclNsSec: "23.85",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "16",
			fclEwSec: "43.68",
			fclEwCode: "E",
			fclWaterDepth: "122",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=429545",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=429545",
			fclNpdidFacility: "429545",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/4-J-1 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "ALVHEIM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2845712",
			fclStartupDate: "08.06.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "34",
			fclNsSec: "54.47",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "3",
			fclEwSec: "28.18",
			fclEwCode: "E",
			fclWaterDepth: "123",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=375168",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=375168",
			fclNpdidFacility: "375168",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/4-J-2 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "ALVHEIM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2845712",
			fclStartupDate: "08.06.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "34",
			fclNsSec: "53.62",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "3",
			fclEwSec: "30.81",
			fclEwCode: "E",
			fclWaterDepth: "123",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=375130",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=375130",
			fclNpdidFacility: "375130",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/4-K-7",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "ALVHEIM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2845712",
			fclStartupDate: "01.05.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "32",
			fclNsSec: "51.75",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "1",
			fclEwSec: "12.57",
			fclEwCode: "E",
			fclWaterDepth: "119",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=728349",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=728349",
			fclNpdidFacility: "728349",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/5-D-1 H ATLA",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "TotalEnergies EP Norge AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "ATLA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "21106284",
			fclStartupDate: "07.10.2012",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "39",
			fclNsSec: "36.28",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "34",
			fclEwSec: "9.64",
			fclEwCode: "E",
			fclWaterDepth: "119",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=415159",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=415159",
			fclNpdidFacility: "415159",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/8 BALDER H",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "15",
			fclNsSec: "49.37",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "52.92",
			fclEwCode: "E",
			fclWaterDepth: "125",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=719338",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=719338",
			fclNpdidFacility: "719338",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/8-D-1 AH JETTE",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "JETTE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "21613906",
			fclStartupDate: "19.05.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "24",
			fclNsSec: "13.56",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "22",
			fclEwSec: "10.92",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "10",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=424812",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=424812",
			fclNpdidFacility: "424812",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "25/8-E-1 H JETTE",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "JETTE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "21613906",
			fclStartupDate: "22.05.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "24",
			fclNsSec: "12.41",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "22",
			fclEwSec: "10.95",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "10",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=424753",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=424753",
			fclNpdidFacility: "424753",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "30/4-D-1 AH",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "MARTIN LINGE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "21675447",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "30",
			fclNsSec: "15.40",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "2",
			fclEwSec: "49.60",
			fclEwCode: "E",
			fclWaterDepth: "119",
			fclFunctions: "DRILLING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=408991",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=408991",
			fclNpdidFacility: "408991",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "30/6-B-51 H OSEBERG B-51",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "OSEBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43625",
			fclStartupDate: "20.08.1996",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "31",
			fclNsSec: "59.85",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "44",
			fclEwSec: "24.91",
			fclEwCode: "E",
			fclWaterDepth: "110",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "21",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280065",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280065",
			fclNpdidFacility: "280065",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "30/6-C-27 H",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "OSEBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43625",
			fclStartupDate: "01.10.1991",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "32",
			fclNsSec: "27.64",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "43",
			fclEwSec: "9.32",
			fclEwCode: "E",
			fclWaterDepth: "106",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278217",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278217",
			fclNpdidFacility: "278217",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "30/8-A-21 H TUNE SOUTH",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TUNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "853376",
			fclStartupDate: "18.07.2009",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "27",
			fclNsSec: "26.46",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "36",
			fclEwSec: "29.02",
			fclEwCode: "E",
			fclWaterDepth: "93",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=365211",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=365211",
			fclNpdidFacility: "365211",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "30/9-B-49 H",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "OSEBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43625",
			fclStartupDate: "01.12.1988",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "32",
			fclNsSec: "32.05",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "50",
			fclEwSec: "14.77",
			fclEwCode: "E",
			fclWaterDepth: "102",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272925",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272925",
			fclNpdidFacility: "272925",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "30/9-B-50 H OSEBERG B-50",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "OSEBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43625",
			fclStartupDate: "01.12.1988",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "32",
			fclNsSec: "28.37",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "48",
			fclEwSec: "23.60",
			fclEwCode: "E",
			fclWaterDepth: "103",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272953",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272953",
			fclNpdidFacility: "272953",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-B-3 H TROLL B-3",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "19.09.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "46",
			fclNsSec: "39.92",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "12.02",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284825",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284825",
			fclNpdidFacility: "284825",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-D-1 H TROLL D-1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.10.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "52",
			fclNsSec: "3.17",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "12.15",
			fclEwCode: "E",
			fclWaterDepth: "338",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284853",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284853",
			fclNpdidFacility: "284853",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-D-2 H TROLL D-2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "14.02.1996",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "51",
			fclNsSec: "30.48",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "43.57",
			fclEwCode: "E",
			fclWaterDepth: "338",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284881",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284881",
			fclNpdidFacility: "284881",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-D-3 H TROLL D-3",
			fclPhase: "FUTURE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "51",
			fclNsSec: "30.48",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "43.47",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284937",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284937",
			fclNpdidFacility: "284937",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-D-4 H TROLL D-4",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.07.2003",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "51",
			fclNsSec: "24.65",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "56.32",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284965",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284965",
			fclNpdidFacility: "284965",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-D-5 H TROLL D-5",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "30.11.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "51",
			fclNsSec: "25.73",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "34.48",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285273",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285273",
			fclNpdidFacility: "285273",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-D-6 H TROLL D-6",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "31.10.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "51",
			fclNsSec: "23.74",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "28.95",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285301",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285301",
			fclNpdidFacility: "285301",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-D-7 H TROLL D-7",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "19.04.1996",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "51",
			fclNsSec: "21.02",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "34.19",
			fclEwCode: "E",
			fclWaterDepth: "338",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285441",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285441",
			fclNpdidFacility: "285441",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-D-8 H TROLL D-8",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "25.09.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "51",
			fclNsSec: "18.82",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "43.68",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285469",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285469",
			fclNpdidFacility: "285469",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-E-1 H TROLL E-1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "19.09.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "48",
			fclNsSec: "1.25",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "36.66",
			fclEwCode: "E",
			fclWaterDepth: "317",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285497",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285497",
			fclNpdidFacility: "285497",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-E-3 H TROLL E-3",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.04.2003",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "47",
			fclNsSec: "59.32",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "57.75",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285525",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285525",
			fclNpdidFacility: "285525",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-E-4 H TROLL E-4",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "19.09.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "47",
			fclNsSec: "56.20",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "31.84",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=341363",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=341363",
			fclNpdidFacility: "341363",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-E-5 H TROLL E-5",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "12.04.1996",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "47",
			fclNsSec: "52.42",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "34.65",
			fclEwCode: "E",
			fclWaterDepth: "318",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285553",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285553",
			fclNpdidFacility: "285553",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-E-6 H TROLL E-6",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "18.04.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "47",
			fclNsSec: "51.34",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "43.06",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285581",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285581",
			fclNpdidFacility: "285581",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-F-1 H TROLL F-1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "19.10.1996",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "46",
			fclNsSec: "36.44",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "9.19",
			fclEwCode: "E",
			fclWaterDepth: "317",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285609",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285609",
			fclNpdidFacility: "285609",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-F-4 H TROLL F-4",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "13.04.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "46",
			fclNsSec: "31.61",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "4.75",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285637",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285637",
			fclNpdidFacility: "285637",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-F-5 H TROLL F-5",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "23.09.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "46",
			fclNsSec: "0.20",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "25",
			fclEwSec: "26.90",
			fclEwCode: "E",
			fclWaterDepth: "317",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285665",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285665",
			fclNpdidFacility: "285665",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-F-6 H TROLL F-6",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "23.09.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "46",
			fclNsSec: "28.14",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "16.63",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285693",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285693",
			fclNpdidFacility: "285693",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-G-1 H TROLL G-1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "03.12.1996",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "45",
			fclNsSec: "4.72",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "22.18",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285721",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285721",
			fclNpdidFacility: "285721",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-G-3 H TROLL G-3",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "21.09.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "45",
			fclNsSec: "19.16",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "27.74",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285777",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285777",
			fclNpdidFacility: "285777",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-G-4 H TROLL G-4",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "22.09.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "45",
			fclNsSec: "9.37",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "21.77",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285805",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285805",
			fclNpdidFacility: "285805",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/2-G-6 H TROLL G-6",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "10.12.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "45",
			fclNsSec: "8.67",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "36.00",
			fclEwCode: "E",
			fclWaterDepth: "317",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285833",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285833",
			fclNpdidFacility: "285833",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/3-Q-21 H TROLL Q-21",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.04.2004",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "49",
			fclNsSec: "55.18",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "42",
			fclEwSec: "59.48",
			fclEwCode: "E",
			fclWaterDepth: "333",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286057",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286057",
			fclNpdidFacility: "286057",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/5-7 EOS",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "34",
			fclNsSec: "35.14",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "36.13",
			fclEwCode: "E",
			fclWaterDepth: "307",
			fclFunctions: "CO2 INJECTOR",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=723056",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=723056",
			fclNpdidFacility: "723056",
			fclDateUpdated: "03.05.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/5-C-1 H",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "30",
			fclNsSec: "48.53",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "28",
			fclEwSec: "12.18",
			fclEwCode: "E",
			fclWaterDepth: "307",
			fclFunctions: "CO2 INJECTOR",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=743920",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=743920",
			fclNpdidFacility: "743920",
			fclDateUpdated: "25.06.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/5-H-1 H TROLL H-1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.09.1996",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "42",
			fclNsSec: "57.99",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "30",
			fclEwSec: "39.37",
			fclEwCode: "E",
			fclWaterDepth: "317",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285861",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285861",
			fclNpdidFacility: "285861",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/5-H-2 H TROLL H-2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "19.01.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "43",
			fclNsSec: "49.05",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "30",
			fclEwSec: "47.63",
			fclEwCode: "E",
			fclWaterDepth: "317",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285889",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285889",
			fclNpdidFacility: "285889",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/5-H-3 H TROLL H-3",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "15.07.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "42",
			fclNsSec: "51.35",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "30",
			fclEwSec: "49.36",
			fclEwCode: "E",
			fclWaterDepth: "317",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285917",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285917",
			fclNpdidFacility: "285917",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/5-H-4 H TROLL H-4",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "04.09.1996",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "42",
			fclNsSec: "55.15",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "30",
			fclEwSec: "26.47",
			fclEwCode: "E",
			fclWaterDepth: "317",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285945",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285945",
			fclNpdidFacility: "285945",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/5-H-5 H TROLL H-5",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.01.1996",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "42",
			fclNsSec: "52.25",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "30",
			fclEwSec: "24.53",
			fclEwCode: "E",
			fclWaterDepth: "317",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285973",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285973",
			fclNpdidFacility: "285973",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/5-H-6 H TROLL H-6",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "23.12.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "42",
			fclNsSec: "46.85",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "30",
			fclEwSec: "33.96",
			fclEwCode: "E",
			fclWaterDepth: "317",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286001",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286001",
			fclNpdidFacility: "286001",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/5-I-32 H TROLL I-32",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.03.2003",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "43",
			fclNsSec: "0.04",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "35",
			fclEwSec: "55.32",
			fclEwCode: "E",
			fclWaterDepth: "315",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286085",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286085",
			fclNpdidFacility: "286085",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/5-Z-1H TROLL Z-1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.03.2011",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "44",
			fclNsSec: "25.87",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "30",
			fclEwSec: "39.62",
			fclEwCode: "E",
			fclWaterDepth: "320",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=409848",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=409848",
			fclNpdidFacility: "409848",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "31/5-Z-2H TROLL Z-2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.03.2011",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "42",
			fclNsSec: "50.91",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "30",
			fclEwSec: "15.98",
			fclEwCode: "E",
			fclWaterDepth: "318",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=409905",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=409905",
			fclNpdidFacility: "409905",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "33/12-N-5",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43699",
			fclStartupDate: "27.01.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "2",
			fclNsSec: "37.96",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "58",
			fclEwSec: "55.88",
			fclEwCode: "E",
			fclWaterDepth: "140",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=372575",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=372575",
			fclNpdidFacility: "372575",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/10-A-1 H",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "22.12.1986",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "11",
			fclNsSec: "4.73",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "13",
			fclEwSec: "15.51",
			fclEwCode: "E",
			fclWaterDepth: "135",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272673",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272673",
			fclNpdidFacility: "272673",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/10-A-2 A",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "23.12.1986",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "10",
			fclNsSec: "48.49",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "13",
			fclEwSec: "6.43",
			fclEwCode: "E",
			fclWaterDepth: "138",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272701",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272701",
			fclNpdidFacility: "272701",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/10-A-3 H",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "12.02.1987",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "11",
			fclNsSec: "14.26",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "12",
			fclEwSec: "41.26",
			fclEwCode: "E",
			fclWaterDepth: "138",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272729",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272729",
			fclNpdidFacility: "272729",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/10-A-4 H",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "23.12.1986",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "11",
			fclNsSec: "17.53",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "13",
			fclEwSec: "27.47",
			fclEwCode: "E",
			fclWaterDepth: "144",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272757",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272757",
			fclNpdidFacility: "272757",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/10-A-5 H",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "04.04.1987",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "10",
			fclNsSec: "42.13",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "12",
			fclEwSec: "38.43",
			fclEwCode: "E",
			fclWaterDepth: "136",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272785",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272785",
			fclNpdidFacility: "272785",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/10-A-9 H",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "15.05.1988",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "10",
			fclNsSec: "36.22",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "8",
			fclEwSec: "57.44",
			fclEwCode: "E",
			fclWaterDepth: "136",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277769",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277769",
			fclNpdidFacility: "277769",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/4-C H SNORRE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "27.06.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "31",
			fclNsSec: "30.22",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "12",
			fclEwSec: "40.41",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282781",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282781",
			fclNpdidFacility: "282781",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/4-D H SNORRE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "27.06.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "31",
			fclNsSec: "32.70",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "12",
			fclEwSec: "40.00",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282809",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282809",
			fclNpdidFacility: "282809",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/4-E H SNORRE",
			fclPhase: "FUTURE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282837",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282837",
			fclNpdidFacility: "282837",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/4-F H SNORRE",
			fclPhase: "FUTURE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284237",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284237",
			fclNpdidFacility: "284237",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/4-I-1 H SNORRE",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "10",
			fclNsSec: "17.09",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "37",
			fclEwSec: "15.10",
			fclEwCode: "E",
			fclWaterDepth: "350",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=441304",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=441304",
			fclNpdidFacility: "441304",
			fclDateUpdated: "01.01.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/4-K H SNORRE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "27.06.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "31",
			fclNsSec: "31.47",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "12",
			fclEwSec: "44.10",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "WATER/GAS INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284265",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284265",
			fclNpdidFacility: "284265",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/4-L H SNORRE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "27.06.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "31",
			fclNsSec: "32.00",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "12",
			fclEwSec: "45.00",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "WATER/GAS INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284293",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284293",
			fclNpdidFacility: "284293",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/4-M H SNORRE",
			fclPhase: "FUTURE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "WATER/GAS INJECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284321",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284321",
			fclNpdidFacility: "284321",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7 HY01",
			fclPhase: "FABRICATION",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SPAR",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "0.85",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "15",
			fclEwSec: "12.53",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "WIND TURBINE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=723351",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=723351",
			fclNpdidFacility: "723351",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7 HY02",
			fclPhase: "FABRICATION",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SPAR",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "21",
			fclNsSec: "13.83",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "15",
			fclEwSec: "38.08",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "WIND TURBINE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=723647",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=723647",
			fclNpdidFacility: "723647",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7 HY03",
			fclPhase: "FABRICATION",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SPAR",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "20",
			fclNsSec: "26.82",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "16",
			fclEwSec: "3.55",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "WIND TURBINE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=723943",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=723943",
			fclNpdidFacility: "723943",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7 HY04",
			fclPhase: "FABRICATION",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SPAR",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "19",
			fclNsSec: "39.80",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "16",
			fclEwSec: "29.06",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "WIND TURBINE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=724239",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=724239",
			fclNpdidFacility: "724239",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7 HY05",
			fclPhase: "FABRICATION",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SPAR",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "18",
			fclNsSec: "52.78",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "16",
			fclEwSec: "54.55",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "WIND TURBINE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=724535",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=724535",
			fclNpdidFacility: "724535",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7 HY06",
			fclPhase: "FABRICATION",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SPAR",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "18",
			fclNsSec: "5.76",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "17",
			fclEwSec: "20.02",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "WIND TURBINE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=724831",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=724831",
			fclNpdidFacility: "724831",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7 HY07",
			fclPhase: "FABRICATION",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SPAR",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "18",
			fclNsSec: "18.66",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "15",
			fclEwSec: "42.61",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "WIND TURBINE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=725127",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=725127",
			fclNpdidFacility: "725127",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7 HY08",
			fclPhase: "FABRICATION",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SPAR",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "19",
			fclNsSec: "5.67",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "15",
			fclEwSec: "17.10",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "WIND TURBINE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=725423",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=725423",
			fclNpdidFacility: "725423",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7 HY09",
			fclPhase: "FABRICATION",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SPAR",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "19",
			fclNsSec: "52.68",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "14",
			fclEwSec: "51.57",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "WIND TURBINE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=725719",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=725719",
			fclNpdidFacility: "725719",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7 HY10",
			fclPhase: "FABRICATION",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SPAR",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "20",
			fclNsSec: "39.70",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "14",
			fclEwSec: "26.01",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "WIND TURBINE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=726015",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=726015",
			fclNpdidFacility: "726015",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7 HY11",
			fclPhase: "FABRICATION",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SPAR",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "21",
			fclNsSec: "26.71",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "14",
			fclEwSec: "0.50",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "WIND TURBINE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=726311",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=726311",
			fclNpdidFacility: "726311",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7-C-5 H VIGDIS C5",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VIGDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43732",
			fclStartupDate: "18.10.2003",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "47.22",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "6",
			fclEwSec: "22.17",
			fclEwCode: "E",
			fclWaterDepth: "280",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=360009",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=360009",
			fclNpdidFacility: "360009",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7-E-5 H VIGDIS E5",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VIGDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43732",
			fclStartupDate: "18.10.2003",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "21",
			fclNsSec: "57.78",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "7",
			fclEwSec: "15.51",
			fclEwCode: "E",
			fclWaterDepth: "280",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=360052",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=360052",
			fclNpdidFacility: "360052",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7-I-1 H TORDIS I-1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TORDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43725",
			fclStartupDate: "03.06.1994",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "16",
			fclNsSec: "33.35",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "7",
			fclEwSec: "10.60",
			fclEwCode: "E",
			fclWaterDepth: "200",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "12",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281577",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281577",
			fclNpdidFacility: "281577",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7-I-10 H TORDIS I-10",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TORDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43725",
			fclStartupDate: "01.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "16",
			fclNsSec: "34.67",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "7",
			fclEwSec: "9.58",
			fclEwCode: "E",
			fclWaterDepth: "200",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "12",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281549",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281549",
			fclNpdidFacility: "281549",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7-I-11 H TORDIS I-11",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TORDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43725",
			fclStartupDate: "01.01.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "16",
			fclNsSec: "34.22",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "7",
			fclEwSec: "9.64",
			fclEwCode: "E",
			fclWaterDepth: "200",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "12",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281661",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281661",
			fclNpdidFacility: "281661",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7-I-2 H TORDIS I-2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TORDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43725",
			fclStartupDate: "03.06.1994",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "16",
			fclNsSec: "33.35",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "7",
			fclEwSec: "10.89",
			fclEwCode: "E",
			fclWaterDepth: "200",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "12",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281605",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281605",
			fclNpdidFacility: "281605",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7-I-3 H TORDIS I-3",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TORDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43725",
			fclStartupDate: "03.06.1994",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "16",
			fclNsSec: "34.23",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "7",
			fclEwSec: "1.60",
			fclEwCode: "E",
			fclWaterDepth: "200",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "12",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281633",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281633",
			fclNpdidFacility: "281633",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7-I-4 H TORDIS I-4",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TORDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43725",
			fclStartupDate: "03.06.1994",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "16",
			fclNsSec: "32.83",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "7",
			fclEwSec: "0.23",
			fclEwCode: "E",
			fclWaterDepth: "200",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "12",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281689",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281689",
			fclNpdidFacility: "281689",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7-I-5 H TORDIS I-5",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TORDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43725",
			fclStartupDate: "03.06.1994",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "16",
			fclNsSec: "35.24",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "7",
			fclEwSec: "3.52",
			fclEwCode: "E",
			fclWaterDepth: "200",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "12",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281717",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281717",
			fclNpdidFacility: "281717",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/7-R-1 H TORDIS R-1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TORDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43725",
			fclStartupDate: "14.02.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "16",
			fclNsSec: "32.22",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "6",
			fclEwSec: "54.69",
			fclEwCode: "E",
			fclWaterDepth: "229",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=394821",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=394821",
			fclNpdidFacility: "394821",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-1 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "26.05.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "12.57",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "35.13",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "GAS INJECTOR",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284377",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284377",
			fclNpdidFacility: "284377",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-10 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "01.01.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "13.47",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "31.43",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "WATER/GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284405",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284405",
			fclNpdidFacility: "284405",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-11 H",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "01.01.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "13.51",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "30.93",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "WATER/GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284433",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284433",
			fclNpdidFacility: "284433",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-13 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "01.01.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "13.55",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "29.92",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "WATER PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284461",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284461",
			fclNpdidFacility: "284461",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-14 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "01.01.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "11.65",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "31.44",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "WATER PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284489",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284489",
			fclNpdidFacility: "284489",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-16 A",
			fclPhase: "FUTURE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=410260",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=410260",
			fclNpdidFacility: "410260",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-17 A",
			fclPhase: "FUTURE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=410315",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=410315",
			fclNpdidFacility: "410315",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-18 AH/H",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "11.64",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "31.44",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284517",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284517",
			fclNpdidFacility: "284517",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-19 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "25.05.2004",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "11.71",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "31.91",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284545",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284545",
			fclNpdidFacility: "284545",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-2 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "26.05.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "12.79",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "35.05",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282697",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282697",
			fclNpdidFacility: "282697",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-20 H",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "11.78",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "32.38",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282725",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282725",
			fclNpdidFacility: "282725",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-21 H/AH",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "01.06.2003",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "11.86",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "32.83",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282865",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282865",
			fclNpdidFacility: "282865",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-22 H/AH",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "16.01.2004",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "11.90",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "33.30",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282977",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282977",
			fclNpdidFacility: "282977",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-23 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "01.01.2002",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "12.01",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "33.78",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283005",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283005",
			fclNpdidFacility: "283005",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-24 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "12.11",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "34.26",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283033",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283033",
			fclNpdidFacility: "283033",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-25 H/AH",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "01.01.2003",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "12.20",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "34.67",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283061",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283061",
			fclNpdidFacility: "283061",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-26  H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "01.01.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "12.34",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "35.06",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283089",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283089",
			fclNpdidFacility: "283089",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-3 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "26.05.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "12.92",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "34.65",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283117",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283117",
			fclNpdidFacility: "283117",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-30 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "01.01.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "13.83",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "31.41",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=424456",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=424456",
			fclNpdidFacility: "424456",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-33 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "18.10.2005",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "13.19",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "33.25",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=370415",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=370415",
			fclNpdidFacility: "370415",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-4 H/AH",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "01.01.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "13.02",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "34.24",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283145",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283145",
			fclNpdidFacility: "283145",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-5 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "05.08.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "13.11",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "33.77",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283201",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283201",
			fclNpdidFacility: "283201",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-6 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "26.05.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "13.26",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "32.83",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284573",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284573",
			fclNpdidFacility: "284573",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-8 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "01.01.2010",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "13.33",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "32.35",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284601",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284601",
			fclNpdidFacility: "284601",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "34/8-A-9 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "01.06.2012",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "13.40",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "31.91",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284657",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284657",
			fclNpdidFacility: "284657",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "35/11-B-31 H",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "FRAM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1578840",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "2",
			fclNsSec: "25.06",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "34",
			fclEwSec: "33.92",
			fclEwCode: "E",
			fclWaterDepth: "356",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=378110",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=378110",
			fclNpdidFacility: "378110",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "36/22",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "NORPIPE OLJELEDNING",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124133",
			fclStartupDate: "14.10.1975",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "55",
			fclNsMin: "17",
			fclNsSec: "27.98",
			fclNsCode: "N",
			fclEwDeg: "0",
			fclEwMin: "12",
			fclEwSec: "12.26",
			fclEwCode: "E",
			fclWaterDepth: "73",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=274185",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=274185",
			fclNpdidFacility: "274185",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "36/22-BP",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "NORPIPE OLJELEDNING",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124133",
			fclStartupDate: "01.01.1983",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "55",
			fclNsMin: "17",
			fclNsSec: "27.20",
			fclNsCode: "N",
			fclEwDeg: "0",
			fclEwMin: "12",
			fclEwSec: "14.00",
			fclEwCode: "E",
			fclWaterDepth: "73",
			fclFunctions: "BYPASS",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286673",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286673",
			fclNpdidFacility: "286673",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "37/4",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "NORPIPE OLJELEDNING",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124133",
			fclStartupDate: "14.10.1975",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "55",
			fclNsMin: "54",
			fclNsSec: "0.60",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "36",
			fclEwSec: "44.00",
			fclEwCode: "E",
			fclWaterDepth: "85",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=274213",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=274213",
			fclNpdidFacility: "274213",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "37/4-BP",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "NORPIPE OLJELEDNING",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124133",
			fclStartupDate: "01.01.1987",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "55",
			fclNsMin: "54",
			fclNsSec: "0.58",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "36",
			fclEwSec: "44.00",
			fclEwCode: "E",
			fclWaterDepth: "85",
			fclFunctions: "BYPASS",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286645",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286645",
			fclNpdidFacility: "286645",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6/3-A-1 H GAUPE ",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "A/S Norske Shell",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GAUPE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "18161341",
			fclStartupDate: "31.03.2012",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "57",
			fclNsSec: "31.94",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "55",
			fclEwSec: "47.34",
			fclEwCode: "E",
			fclWaterDepth: "85",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=410771",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=410771",
			fclNpdidFacility: "410771",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/7-A-1 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "15.55",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "5.48",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284685",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284685",
			fclNpdidFacility: "284685",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/7-A-10 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "15.78",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "9.44",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284741",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284741",
			fclNpdidFacility: "284741",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/7-A-11 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "15.92",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "8.70",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284769",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284769",
			fclNpdidFacility: "284769",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/7-A-12 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "16.08",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "7.88",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284797",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284797",
			fclNpdidFacility: "284797",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/7-A-13 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.01.2002",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "16.22",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "7.16",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284909",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284909",
			fclNpdidFacility: "284909",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/7-A-14 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "16.36",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "6.41",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284993",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284993",
			fclNpdidFacility: "284993",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/7-A-16 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "16.69",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "4.90",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285049",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285049",
			fclNpdidFacility: "285049",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/7-A-17 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "16.85",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "4.11",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285077",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285077",
			fclNpdidFacility: "285077",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/7-A-18 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "16.93",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "3.36",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285105",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285105",
			fclNpdidFacility: "285105",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/7-A-19 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "16.53",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "5.58",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285021",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285021",
			fclNpdidFacility: "285021",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/7-A-4 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "31.01.2004",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "14.59",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "5.77",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=395215",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=395215",
			fclNpdidFacility: "395215",
			fclDateUpdated: "06.08.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/7-A-5 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "14.75",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "6.47",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285161",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285161",
			fclNpdidFacility: "285161",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/7-A-6 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "14.93",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "7.23",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285189",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285189",
			fclNpdidFacility: "285189",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/7-A-7 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "15.11",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "7.93",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285217",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285217",
			fclNpdidFacility: "285217",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/7-A-8 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "15.25",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "8.68",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285245",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285245",
			fclNpdidFacility: "285245",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/7-A-9 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "15.40",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "9.50",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "GAS INJECTION - OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283761",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283761",
			fclNpdidFacility: "283761",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/9-A-53 H",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "19.10.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "19",
			fclNsSec: "48.94",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "46",
			fclEwSec: "30.91",
			fclEwCode: "E",
			fclWaterDepth: "277",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284349",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284349",
			fclNpdidFacility: "284349",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/9-A-55 H",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "19.10.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "42.35",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "44",
			fclEwSec: "14.66",
			fclEwCode: "E",
			fclWaterDepth: "286",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281801",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281801",
			fclNpdidFacility: "281801",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/9-A-58 H",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "19.10.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "23",
			fclNsSec: "7.69",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "57",
			fclEwSec: "37.47",
			fclEwCode: "E",
			fclWaterDepth: "243",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285749",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285749",
			fclNpdidFacility: "285749",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/9-D-1 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "13.12.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "20",
			fclNsSec: "32.73",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "43",
			fclEwSec: "25.83",
			fclEwCode: "E",
			fclWaterDepth: "265",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284069",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284069",
			fclNpdidFacility: "284069",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/9-D-2 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "22.04.2002",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "20",
			fclNsSec: "32.65",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "43",
			fclEwSec: "23.86",
			fclEwCode: "E",
			fclWaterDepth: "265",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284097",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284097",
			fclNpdidFacility: "284097",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/9-D-3 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "27.09.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "21",
			fclNsSec: "39.20",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "42",
			fclEwSec: "45.80",
			fclEwCode: "E",
			fclWaterDepth: "254",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=391161",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=391161",
			fclNpdidFacility: "391161",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/9-E-1 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "30.11.2002",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "15",
			fclNsSec: "44.90",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "43",
			fclEwSec: "51.03",
			fclEwCode: "E",
			fclWaterDepth: "293",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284125",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284125",
			fclNpdidFacility: "284125",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/9-E-2 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "30.11.2002",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "15",
			fclNsSec: "43.27",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "43",
			fclEwSec: "51.10",
			fclEwCode: "E",
			fclWaterDepth: "293",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "12",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284153",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284153",
			fclNpdidFacility: "284153",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/9-E-3 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "15.06.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "15",
			fclNsSec: "44.10",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "43",
			fclEwSec: "55.38",
			fclEwCode: "E",
			fclWaterDepth: "293",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=391213",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=391213",
			fclNpdidFacility: "391213",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/9-E-4 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "01.12.2014",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "15",
			fclNsSec: "23.37",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "42",
			fclEwSec: "13.16",
			fclEwCode: "E",
			fclWaterDepth: "296",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=432093",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=432093",
			fclNpdidFacility: "432093",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/9-G-1 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "01.11.2014",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "20",
			fclNsSec: "47.07",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "43",
			fclEwSec: "3.90",
			fclEwCode: "E",
			fclWaterDepth: "259",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=435244",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=435244",
			fclNpdidFacility: "435244",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/9-G-2 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "12.06.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "22",
			fclNsSec: "13.93",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "47",
			fclEwSec: "28.85",
			fclEwCode: "E",
			fclWaterDepth: "245",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=438207",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=438207",
			fclNpdidFacility: "438207",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/9-G-3 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "23.06.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "20",
			fclNsSec: "25.28",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "45",
			fclEwSec: "9.58",
			fclEwCode: "E",
			fclWaterDepth: "268",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=435301",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=435301",
			fclNpdidFacility: "435301",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/9-G-5 H",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "15",
			fclNsSec: "49.91",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "43",
			fclEwSec: "43.24",
			fclEwCode: "E",
			fclWaterDepth: "289",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=442817",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=442817",
			fclNpdidFacility: "442817",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6407/9-H-1",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "23",
			fclNsSec: "59.87",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "41",
			fclEwSec: "40.18",
			fclEwCode: "E",
			fclWaterDepth: "244",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=744219",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=744219",
			fclNpdidFacility: "744219",
			fclDateUpdated: "10.05.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6507/11-V H YTTERGRYTA",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "YTTERGRYTA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4973114",
			fclStartupDate: "06.01.2009",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "6",
			fclNsSec: "41.80",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "30",
			fclEwSec: "33.20",
			fclEwCode: "E",
			fclWaterDepth: "297",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=394760",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=394760",
			fclNpdidFacility: "394760",
			fclDateUpdated: "09.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6507/3-G-1H ÆRFUGL",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "ÆRFUGL NORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "38542241",
			fclStartupDate: "03.11.2021",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "55",
			fclNsSec: "4.14",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "43",
			fclEwSec: "15.50",
			fclEwCode: "E",
			fclWaterDepth: "368",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=713532",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=713532",
			fclNpdidFacility: "713532",
			fclDateUpdated: "04.11.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6507/3-H-1H ÆRFUGL",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "SKARV",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4704482",
			fclStartupDate: "03.11.2021",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "50",
			fclNsSec: "29.00",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "41",
			fclEwSec: "33.62",
			fclEwCode: "E",
			fclWaterDepth: "356",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=713830",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=713830",
			fclNpdidFacility: "713830",
			fclDateUpdated: "04.11.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6507/5-K-1H ÆRFUGL",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "SKARV",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4704482",
			fclStartupDate: "12.11.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "41",
			fclNsSec: "50.66",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "32",
			fclEwSec: "52.80",
			fclEwCode: "E",
			fclWaterDepth: "409",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=451366",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=451366",
			fclNpdidFacility: "451366",
			fclDateUpdated: "15.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6507/5-L-1H ÆRFUGL",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "SKARV",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4704482",
			fclStartupDate: "12.11.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "37",
			fclNsSec: "37.42",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "29",
			fclEwSec: "25.09",
			fclEwCode: "E",
			fclWaterDepth: "406",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=451420",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=451420",
			fclNpdidFacility: "451420",
			fclDateUpdated: "15.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6507/5-M-1H ÆRFUGL",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "SKARV",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4704482",
			fclStartupDate: "12.11.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "35",
			fclNsSec: "40.05",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "25",
			fclEwSec: "29.94",
			fclEwCode: "E",
			fclWaterDepth: "420",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=451474",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=451474",
			fclNpdidFacility: "451474",
			fclDateUpdated: "15.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "6507/8-D-5 H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "HEIDRUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43771",
			fclStartupDate: "01.02.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "21",
			fclNsSec: "46.34",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "20",
			fclEwSec: "7.01",
			fclEwCode: "E",
			fclWaterDepth: "357",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=417412",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=417412",
			fclNpdidFacility: "417412",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ALBUSKJELL A",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 12 LEGS",
			fclBelongsToName: "ALBUSKJELL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43437",
			fclStartupDate: "01.05.1979",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "38",
			fclNsSec: "34.39",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "56",
			fclEwSec: "24.03",
			fclEwCode: "E",
			fclWaterDepth: "71",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271357",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271357",
			fclNpdidFacility: "271357",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ALBUSKJELL A-BS",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "ALBUSKJELL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43437",
			fclStartupDate: "01.05.1979",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "38",
			fclNsSec: "30.55",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "56",
			fclEwSec: "26.30",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281073",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281073",
			fclNpdidFacility: "281073",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ALBUSKJELL A-FL",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "ALBUSKJELL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43437",
			fclStartupDate: "01.05.1979",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "38",
			fclNsSec: "27.67",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "56",
			fclEwSec: "28.40",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281101",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281101",
			fclNpdidFacility: "281101",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ALBUSKJELL F",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 12 LEGS",
			fclBelongsToName: "ALBUSKJELL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43437",
			fclStartupDate: "01.05.1979",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "37",
			fclNsSec: "13.93",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "3",
			fclEwSec: "14.21",
			fclEwCode: "E",
			fclWaterDepth: "71",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271553",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271553",
			fclNpdidFacility: "271553",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ALBUSKJELL F-BS",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "ALBUSKJELL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43437",
			fclStartupDate: "01.05.1979",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "37",
			fclNsSec: "9.91",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "3",
			fclEwSec: "16.49",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281241",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281241",
			fclNpdidFacility: "281241",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ALBUSKJELL F-FL",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "ALBUSKJELL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43437",
			fclStartupDate: "01.05.1979",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "37",
			fclNsSec: "6.96",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "3",
			fclEwSec: "18.58",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281269",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281269",
			fclNpdidFacility: "281269",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ALVE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ALVE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4444332",
			fclStartupDate: "19.03.2009",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "58",
			fclNsSec: "6.40",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "48",
			fclEwSec: "36.37",
			fclEwCode: "E",
			fclWaterDepth: "390",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=378362",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=378362",
			fclNpdidFacility: "378362",
			fclDateUpdated: "04.12.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ALVHEIM EAST RISER BASE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "ALVHEIM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2845712",
			fclStartupDate: "08.06.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "34",
			fclNsSec: "9.12",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "0",
			fclEwSec: "4.89",
			fclEwCode: "E",
			fclWaterDepth: "125",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=425523",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=425523",
			fclNpdidFacility: "425523",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ALVHEIM FPSO",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "FPSO",
			fclBelongsToName: "ALVHEIM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2845712",
			fclStartupDate: "08.06.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "34",
			fclNsSec: "3.06",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "59",
			fclEwSec: "54.42",
			fclEwCode: "E",
			fclWaterDepth: "125",
			fclFunctions: "OFFLOADING - OIL PRODUCER - PROCESSING - QUARTER - STORAGE",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=375206",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=375206",
			fclNpdidFacility: "375206",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ALVHEIM SOUTH RISER BASE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "ALVHEIM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2845712",
			fclStartupDate: "08.06.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "33",
			fclNsSec: "55.40",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "59",
			fclEwSec: "59.49",
			fclEwCode: "E",
			fclWaterDepth: "125",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=425582",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=425582",
			fclNpdidFacility: "425582",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ALVHEIM WEST RISER BASE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "ALVHEIM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2845712",
			fclStartupDate: "08.06.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "34",
			fclNsSec: "4.62",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "59",
			fclEwSec: "38.77",
			fclEwCode: "E",
			fclWaterDepth: "125",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=425640",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=425640",
			fclNpdidFacility: "425640",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ALWYN NORTH B",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "01.11.1987",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "48",
			fclNsSec: "36.42",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "44",
			fclEwSec: "6.87",
			fclEwCode: "E",
			fclWaterDepth: "126",
			fclFunctions: "PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=372850",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=372850",
			fclNpdidFacility: "372850",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ARMADA",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "57",
			fclNsSec: "26.77",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "50",
			fclEwSec: "45.28",
			fclEwCode: "E",
			fclWaterDepth: "110",
			fclFunctions: "PROCESSING - QUARTER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=378185",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=378185",
			fclNpdidFacility: "378185",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ASKELADD NORD (J)",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNØHVIT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2053062",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "24",
			fclNsSec: "0.39",
			fclNsCode: "N",
			fclEwDeg: "20",
			fclEwMin: "24",
			fclEwSec: "40.68",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=708522",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=708522",
			fclNpdidFacility: "708522",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ASKELADD SØR (L)",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNØHVIT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2053062",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "20",
			fclNsSec: "27.42",
			fclNsCode: "N",
			fclEwDeg: "20",
			fclEwMin: "23",
			fclEwSec: "23.79",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=708225",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=708225",
			fclNpdidFacility: "708225",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ASKELADD VEST (K)",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNØHVIT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2053062",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "17",
			fclNsSec: "39.20",
			fclNsCode: "N",
			fclEwDeg: "8",
			fclEwMin: "7",
			fclEwSec: "50.10",
			fclEwCode: "E",
			fclWaterDepth: "270",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=748429",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=748429",
			fclNpdidFacility: "748429",
			fclDateUpdated: "03.08.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "B-11",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "NORPIPE GASSLEDNING",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124165",
			fclStartupDate: "17.09.1977",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "55",
			fclNsMin: "27",
			fclNsSec: "44.33",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "33",
			fclEwSec: "5.93",
			fclEwCode: "E",
			fclWaterDepth: "33",
			fclFunctions: "BOOSTER - QUARTER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277265",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277265",
			fclNpdidFacility: "277265",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BALDER FPU",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "FPSO",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "30.09.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "29.78",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "23",
			fclEwSec: "14.70",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OFFLOADING - OIL PRODUCER - PROCESSING - QUARTER - STORAGE",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279841",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279841",
			fclNpdidFacility: "279841",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BALDER GASLIFT MANIFOLD",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "30.06.2016",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "MANIFOLD",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=448485",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=448485",
			fclNpdidFacility: "448485",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BALDER SDU A",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "01.06.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "28.48",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "21",
			fclEwSec: "30.66",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "DISTRIBUTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=430557",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=430557",
			fclNpdidFacility: "430557",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BALDER SDU B",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "18.11.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "10",
			fclNsSec: "40.28",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "22",
			fclEwSec: "42.15",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "DISTRIBUTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=430668",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=430668",
			fclNpdidFacility: "430668",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BALDER SDU D",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "15.04.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "58.37",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "24",
			fclEwSec: "35.79",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "DISTRIBUTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=430724",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=430724",
			fclNpdidFacility: "430724",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BAUGE",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "BAUGE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "29446221",
			fclStartupDate: "01.12.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "21",
			fclNsSec: "18.17",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "27",
			fclEwSec: "41.87",
			fclEwCode: "E",
			fclWaterDepth: "282",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=451051",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=451051",
			fclNpdidFacility: "451051",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BLAREGNIES",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "50",
			fclNsMin: "21",
			fclNsSec: "23.00",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "53",
			fclEwSec: "40.00",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=416127",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=416127",
			fclNpdidFacility: "416127",
			fclDateUpdated: "02.10.2019",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BOA",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ALVHEIM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2845712",
			fclStartupDate: "08.06.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "34",
			fclNsSec: "35.58",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "55",
			fclEwSec: "25.34",
			fclEwCode: "E",
			fclWaterDepth: "125",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=375245",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=375245",
			fclNpdidFacility: "375245",
			fclDateUpdated: "30.12.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BRAE A",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Repsol Sinopec North Sea Limited",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "ENOCH",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "3437659",
			fclStartupDate: "12.07.1983",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "41",
			fclNsSec: "32.94",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "16",
			fclEwSec: "55.02",
			fclEwCode: "E",
			fclWaterDepth: "112",
			fclFunctions: "OIL PRODUCER - PROCESSING - QUARTER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=273877",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=273877",
			fclNpdidFacility: "273877",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BRAEFOOT BAY",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "29",
			fclNsSec: "19.19",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "45",
			fclEwSec: "37.38",
			fclEwCode: "W",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=403588",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=403588",
			fclNpdidFacility: "403588",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BRAGE",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Wintershall Dea Norge AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "BRAGE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43651",
			fclStartupDate: "23.09.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "32",
			fclNsSec: "33.21",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "2",
			fclEwSec: "48.47",
			fclEwCode: "E",
			fclWaterDepth: "136",
			fclFunctions: "DRILLING - PROCESSING - QUARTER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277405",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277405",
			fclNpdidFacility: "277405",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BRAGE-T",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "STATPIPE 5",
			fclBelongsToKind: "TUF PART",
			fclBelongsToS: "124054",
			fclStartupDate: "23.09.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "33",
			fclNsSec: "38.28",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "53",
			fclEwSec: "53.00",
			fclEwCode: "E",
			fclWaterDepth: "136",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287037",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287037",
			fclNpdidFacility: "287037",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BRASSE",
			fclPhase: "FUTURE",
			fclSurface: "N",
			fclCurrentOperatorName: "",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "",
			fclBelongsToKind: "",
			fclBelongsToS: "",
			fclStartupDate: "01.01.2021",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "24",
			fclNsSec: "16.92",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "1",
			fclEwSec: "58.46",
			fclEwCode: "E",
			fclWaterDepth: "120",
			fclFunctions: "OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=451998",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=451998",
			fclNpdidFacility: "451998",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BRASSE WI Satellite ",
			fclPhase: "FUTURE",
			fclSurface: "N",
			fclCurrentOperatorName: "",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "",
			fclBelongsToKind: "",
			fclBelongsToS: "",
			fclStartupDate: "01.01.2021",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "120",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=456096",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=456096",
			fclNpdidFacility: "456096",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BREIDABLIKK J",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "BREIDABLIKK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "38702206",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "13",
			fclNsSec: "52.11",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "33",
			fclEwSec: "22.84",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=743545",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=743545",
			fclNpdidFacility: "743545",
			fclDateUpdated: "29.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BREIDABLIKK K",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "BREIDABLIKK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "38702206",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "14",
			fclNsSec: "30.04",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "35",
			fclEwSec: "3.06",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=743245",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=743245",
			fclNpdidFacility: "743245",
			fclDateUpdated: "29.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BREIDABLIKK L",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "BREIDABLIKK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "38702206",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "15",
			fclNsSec: "14.60",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "36",
			fclEwSec: "13.94",
			fclEwCode: "E",
			fclWaterDepth: "130",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=742945",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=742945",
			fclNpdidFacility: "742945",
			fclDateUpdated: "29.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BREIDABLIKK M",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "BREIDABLIKK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "38702206",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "15",
			fclNsSec: "50.93",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "37",
			fclEwSec: "26.24",
			fclEwCode: "E",
			fclWaterDepth: "129",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=742645",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=742645",
			fclNpdidFacility: "742645",
			fclDateUpdated: "29.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BRYNHILD",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "BRYNHILD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "21123063",
			fclStartupDate: "25.12.2014",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "29",
			fclNsSec: "34.00",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "18",
			fclEwSec: "9.40",
			fclEwCode: "E",
			fclWaterDepth: "82",
			fclFunctions: "OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=430375",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=430375",
			fclNpdidFacility: "430375",
			fclDateUpdated: "13.01.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BYGGVE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "TotalEnergies EP Norge AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "SKIRNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2138816",
			fclStartupDate: "01.03.2004",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "37",
			fclNsSec: "3.64",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "29",
			fclEwSec: "49.93",
			fclEwCode: "E",
			fclWaterDepth: "120",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=359760",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=359760",
			fclNpdidFacility: "359760",
			fclDateUpdated: "09.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BYGNES",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "17",
			fclNsSec: "56.99",
			fclNsCode: "N",
			fclEwDeg: "5",
			fclEwMin: "18",
			fclEwSec: "17.15",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=365267",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=365267",
			fclNpdidFacility: "365267",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "BØYLA",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "BØYLA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "22492497",
			fclStartupDate: "19.01.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "20",
			fclNsSec: "35.37",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "49",
			fclEwSec: "51.18",
			fclEwCode: "E",
			fclWaterDepth: "120",
			fclFunctions: "OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=435781",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=435781",
			fclNpdidFacility: "435781",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "CATS",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "54",
			fclNsMin: "37",
			fclNsSec: "32.99",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "21",
			fclEwSec: "5.56",
			fclEwCode: "W",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=416189",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=416189",
			fclNpdidFacility: "416189",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "COD",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "COD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43785",
			fclStartupDate: "01.11.1977",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "4",
			fclNsSec: "10.39",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "26",
			fclEwSec: "5.00",
			fclEwCode: "E",
			fclWaterDepth: "73",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271693",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271693",
			fclNpdidFacility: "271693",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "COD FL",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "COD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43785",
			fclStartupDate: "01.11.1977",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "4",
			fclNsSec: "6.53",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "26",
			fclEwSec: "7.52",
			fclEwCode: "E",
			fclWaterDepth: "78",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281465",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281465",
			fclNpdidFacility: "281465",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "COL DE LARRAU",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "43",
			fclNsMin: "1",
			fclNsSec: "0.00",
			fclNsCode: "N",
			fclEwDeg: "0",
			fclEwMin: "56",
			fclEwSec: "0.00",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=416247",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=416247",
			fclNpdidFacility: "416247",
			fclDateUpdated: "02.10.2019",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "CRUDEN BAY",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "23",
			fclNsSec: "49.00",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "50",
			fclEwSec: "42.00",
			fclEwCode: "W",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=416305",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=416305",
			fclNpdidFacility: "416305",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DALMENY",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "23",
			fclNsSec: "47.19",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "50",
			fclEwSec: "43.99",
			fclEwCode: "W",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=416363",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=416363",
			fclNpdidFacility: "416363",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DEN HELDER",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "52",
			fclNsMin: "57",
			fclNsSec: "11.98",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "43",
			fclEwSec: "59.98",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=416421",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=416421",
			fclNpdidFacility: "416421",
			fclDateUpdated: "02.10.2019",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DOMPAP S",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SKULD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "21350124",
			fclStartupDate: "01.04.2014",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "66",
			fclNsMin: "14",
			fclNsSec: "4.67",
			fclNsCode: "N",
			fclEwDeg: "8",
			fclEwMin: "19",
			fclEwSec: "58.90",
			fclEwCode: "E",
			fclWaterDepth: "343",
			fclFunctions: "OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=417781",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=417781",
			fclNpdidFacility: "417781",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DORNUM",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "53",
			fclNsMin: "38",
			fclNsSec: "52.39",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "19",
			fclEwSec: "38.17",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=416479",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=416479",
			fclNpdidFacility: "416479",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DRAUGEN",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "CONDEEP MONOSHAFT",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "19.10.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "21",
			fclNsSec: "11.42",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "46",
			fclEwSec: "57.38",
			fclEwCode: "E",
			fclWaterDepth: "252",
			fclFunctions: "DRILLING - PROCESSING - QUARTER - STORAGE",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272393",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272393",
			fclNpdidFacility: "272393",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DRAUGEN B",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "19.10.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "24",
			fclNsSec: "0.95",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "47",
			fclEwSec: "44.94",
			fclEwCode: "E",
			fclWaterDepth: "263",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277853",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277853",
			fclNpdidFacility: "277853",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DRAUGEN C",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "19.10.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "18",
			fclNsSec: "38.56",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "44",
			fclEwSec: "18.27",
			fclEwCode: "E",
			fclWaterDepth: "276",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=356244",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=356244",
			fclNpdidFacility: "356244",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DRAUGEN D",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "01.11.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "20",
			fclNsSec: "32.40",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "43",
			fclEwSec: "28.08",
			fclEwCode: "E",
			fclWaterDepth: "265",
			fclFunctions: "MANIFOLD STATION ",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280989",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280989",
			fclNpdidFacility: "280989",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DRAUGEN FLP",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "LOADING SYSTEM",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "19.10.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "20",
			fclNsSec: "49.20",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "50",
			fclEwSec: "26.50",
			fclEwCode: "E",
			fclWaterDepth: "258",
			fclFunctions: "OFFLOADING",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280177",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280177",
			fclNpdidFacility: "280177",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DRAUGEN FSL",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "LOADING SYSTEM",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "15.10.2012",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "20",
			fclNsSec: "50.32",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "50",
			fclEwSec: "45.11",
			fclEwCode: "E",
			fclWaterDepth: "258",
			fclFunctions: "OFFLOADING",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=425460",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=425460",
			fclNpdidFacility: "425460",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DRAUGEN PLEM 105",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "19.10.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "20",
			fclNsSec: "49.91",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "50",
			fclEwSec: "13.82",
			fclEwCode: "E",
			fclWaterDepth: "257",
			fclFunctions: "PIPELINE END MANIFOLD",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281829",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281829",
			fclNpdidFacility: "281829",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DRAUGEN PLEM-106",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "19.10.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "20",
			fclNsSec: "48.49",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "50",
			fclEwSec: "39.22",
			fclEwCode: "E",
			fclWaterDepth: "256",
			fclFunctions: "PIPELINE END MANIFOLD",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281857",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281857",
			fclNpdidFacility: "281857",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DRAUGEN SSBP",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "24.11.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "20",
			fclNsSec: "29.89",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "43",
			fclEwSec: "37.93",
			fclEwCode: "E",
			fclWaterDepth: "259",
			fclFunctions: "BOOSTER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=438886",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=438886",
			fclNpdidFacility: "438886",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DRAUGEN T",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "ÅSGARD TRANSPORT",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124211",
			fclStartupDate: "10.10.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "13",
			fclNsSec: "59.72",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "26",
			fclEwSec: "28.53",
			fclEwCode: "E",
			fclWaterDepth: "345",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287373",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287373",
			fclNpdidFacility: "287373",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DRAUPNER E",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "GASSLED",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "2252643",
			fclStartupDate: "13.08.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "11",
			fclNsSec: "23.97",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "28",
			fclEwSec: "21.62",
			fclEwCode: "E",
			fclWaterDepth: "69",
			fclFunctions: "RISER",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278357",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278357",
			fclNpdidFacility: "278357",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DRAUPNER S",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "GASSLED",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "2252643",
			fclStartupDate: "01.07.1985",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "11",
			fclNsSec: "19.93",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "28",
			fclEwSec: "22.05",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "QUARTER - RISER",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271945",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271945",
			fclNpdidFacility: "271945",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DUNKERQUE",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "GASSLED",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "2252643",
			fclStartupDate: "01.07.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "51",
			fclNsMin: "2",
			fclNsSec: "39.98",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "42.99",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286757",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286757",
			fclNpdidFacility: "286757",
			fclDateUpdated: "12.01.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DUVA K",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Neptune Energy Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "DUVA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "34833026",
			fclStartupDate: "23.08.2021",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "24",
			fclNsSec: "0.82",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "4",
			fclEwSec: "2.70",
			fclEwCode: "E",
			fclWaterDepth: "359",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=710870",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=710870",
			fclNpdidFacility: "710870",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DVALIN",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Wintershall Dea Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "DVALIN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "29393934",
			fclStartupDate: "01.12.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "25",
			fclNsSec: "45.96",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "8",
			fclEwSec: "24.61",
			fclEwCode: "E",
			fclWaterDepth: "380",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=451934",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=451934",
			fclNpdidFacility: "451934",
			fclDateUpdated: "02.07.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DVALIN GAS EXPORT RISER BASE",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "",
			fclBelongsToKind: "",
			fclBelongsToS: "",
			fclStartupDate: "01.10.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "19",
			fclNsSec: "32.03",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "19",
			fclEwSec: "36.02",
			fclEwCode: "E",
			fclWaterDepth: "345",
			fclFunctions: "",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=458692",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=458692",
			fclNpdidFacility: "458692",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DVALIN PLEM",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "",
			fclBelongsToKind: "",
			fclBelongsToS: "",
			fclStartupDate: "01.10.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "19",
			fclNsSec: "59.05",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "11",
			fclEwSec: "2.85",
			fclEwCode: "E",
			fclWaterDepth: "345",
			fclFunctions: "PIPELINE END MANIFOLD",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=458633",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=458633",
			fclNpdidFacility: "458633",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "DVALIN PRODUCTION RISER BASE SSIV",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "",
			fclBelongsToKind: "",
			fclBelongsToS: "",
			fclStartupDate: "01.10.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "19",
			fclNsSec: "31.07",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "19",
			fclEwSec: "38.83",
			fclEwCode: "E",
			fclWaterDepth: "345",
			fclFunctions: "",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=458752",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=458752",
			fclNpdidFacility: "458752",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EASINGTON",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "LANGELED",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "2450169",
			fclStartupDate: "01.10.2006",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "53",
			fclNsMin: "38",
			fclNsSec: "30.00",
			fclNsCode: "N",
			fclEwDeg: "0",
			fclEwMin: "9",
			fclEwSec: "20.00",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "TERMINAL",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=369424",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=369424",
			fclNpdidFacility: "369424",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EDDA",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 12 LEGS",
			fclBelongsToName: "EDDA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43541",
			fclStartupDate: "01.12.1979",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "27",
			fclNsSec: "53.42",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "6",
			fclEwSec: "16.07",
			fclEwCode: "E",
			fclWaterDepth: "71",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271637",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271637",
			fclNpdidFacility: "271637",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EDDA FL",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "EDDA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43541",
			fclStartupDate: "01.12.1976",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "27",
			fclNsSec: "49.59",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "6",
			fclEwSec: "20.08",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281381",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281381",
			fclNpdidFacility: "281381",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EDVARD GRIEG",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ABP Norway AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "EDVARD GRIEG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "21675433",
			fclStartupDate: "28.11.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "50",
			fclNsSec: "33.84",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "14",
			fclEwSec: "54.02",
			fclEwCode: "E",
			fclWaterDepth: "109",
			fclFunctions: "PROCESSING - QUARTER - WELLHEAD",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=436163",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=436163",
			fclNpdidFacility: "436163",
			fclDateUpdated: "10.07.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK A",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.01.1974",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "31",
			fclNsSec: "15.65",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "13",
			fclEwSec: "22.29",
			fclEwCode: "E",
			fclWaterDepth: "74",
			fclFunctions: "DRILLING - OIL PRODUCER - PROCESSING - QUARTER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271385",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271385",
			fclNpdidFacility: "271385",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK B",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 12 LEGS",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.10.1974",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "33",
			fclNsSec: "55.27",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "13.28",
			fclEwCode: "E",
			fclWaterDepth: "74",
			fclFunctions: "DRILLING - OIL PRODUCER - PROCESSING - WELLHEAD",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271413",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271413",
			fclNpdidFacility: "271413",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK BS1",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.01.1972",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "42.30",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "13",
			fclEwSec: "2.20",
			fclEwCode: "E",
			fclWaterDepth: "72",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281129",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281129",
			fclNpdidFacility: "281129",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK BS3",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.01.1974",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "54.07",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "52.26",
			fclEwCode: "E",
			fclWaterDepth: "72",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281157",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281157",
			fclNpdidFacility: "281157",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK BS4",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.01.1975",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "33",
			fclNsSec: "6.17",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "38.93",
			fclEwCode: "E",
			fclWaterDepth: "72",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281185",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281185",
			fclNpdidFacility: "281185",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK C",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 12 LEGS",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.01.1974",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "52.23",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "55.86",
			fclEwCode: "E",
			fclWaterDepth: "77",
			fclFunctions: "DRILLING - OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271441",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271441",
			fclNpdidFacility: "271441",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK FTP",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.04.1974",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "46.25",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "13",
			fclEwSec: "0.26",
			fclEwCode: "E",
			fclWaterDepth: "77",
			fclFunctions: "PROCESSING - RISER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272281",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272281",
			fclNpdidFacility: "272281",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK G",
			fclPhase: "PARTLY REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "VALHALL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43548",
			fclStartupDate: "01.10.1982",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "57.11",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "35.95",
			fclEwCode: "E",
			fclWaterDepth: "77",
			fclFunctions: "RISER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272113",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272113",
			fclNpdidFacility: "272113",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK H",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.01.1979",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "49.96",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "47.28",
			fclEwCode: "E",
			fclWaterDepth: "77",
			fclFunctions: "ACCOMMODATION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272197",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272197",
			fclNpdidFacility: "272197",
			fclDateUpdated: "23.12.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK J",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "18.08.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "49.92",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "13",
			fclEwSec: "16.62",
			fclEwCode: "E",
			fclWaterDepth: "77",
			fclFunctions: "OIL PRODUCER - PROCESSING",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277937",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277937",
			fclNpdidFacility: "277937",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK K",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.01.1987",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "33",
			fclNsSec: "56.89",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "21.98",
			fclEwCode: "E",
			fclWaterDepth: "74",
			fclFunctions: "DRILLING - QUARTER - WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271469",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271469",
			fclNpdidFacility: "271469",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK L",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.04.2014",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "36.21",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "13",
			fclEwSec: "27.85",
			fclEwCode: "E",
			fclWaterDepth: "79",
			fclFunctions: "ACCOMMODATION",
			fclDesignLifetime: "40",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=425117",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=425117",
			fclNpdidFacility: "425117",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK L-BS",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "25.10.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "39.60",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "13",
			fclEwSec: "25.35",
			fclEwCode: "E",
			fclWaterDepth: "79",
			fclFunctions: "",
			fclDesignLifetime: "40",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=425176",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=425176",
			fclNpdidFacility: "425176",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK M",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "23.10.2005",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "43.36",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "13",
			fclEwSec: "22.96",
			fclEwCode: "E",
			fclWaterDepth: "78",
			fclFunctions: "OIL PRODUCER - PROCESSING - WELLHEAD",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=364198",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=364198",
			fclNpdidFacility: "364198",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK M-BS",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "23.10.2005",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "43.36",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "13",
			fclEwSec: "22.96",
			fclEwCode: "E",
			fclWaterDepth: "78",
			fclFunctions: "",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=364234",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=364234",
			fclNpdidFacility: "364234",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK NORD FL",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.01.1975",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "33",
			fclNsSec: "9.41",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "36.67",
			fclEwCode: "E",
			fclWaterDepth: "72",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282641",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282641",
			fclNpdidFacility: "282641",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK P",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "14.10.1975",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "55.29",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "49.15",
			fclEwCode: "E",
			fclWaterDepth: "77",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272169",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272169",
			fclNpdidFacility: "272169",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK Q",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.01.1973",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "49.13",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "57.71",
			fclEwCode: "E",
			fclWaterDepth: "77",
			fclFunctions: "ACCOMMODATION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272253",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272253",
			fclNpdidFacility: "272253",
			fclDateUpdated: "23.12.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK R",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.09.1977",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "33",
			fclNsSec: "2.37",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "42.19",
			fclEwCode: "E",
			fclWaterDepth: "77",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272001",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272001",
			fclNpdidFacility: "272001",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK S",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "STATPIPE",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "123940",
			fclStartupDate: "01.01.1986",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "33",
			fclNsSec: "6.83",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "49.74",
			fclEwCode: "E",
			fclWaterDepth: "77",
			fclFunctions: "RISER",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271973",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271973",
			fclNpdidFacility: "271973",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK SBM-1",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "LOADING SYSTEM",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "33",
			fclNsSec: "6.10",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "44.22",
			fclEwCode: "E",
			fclWaterDepth: "74",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286393",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286393",
			fclNpdidFacility: "286393",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK SBM-2",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "LOADING SYSTEM",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "33",
			fclNsSec: "6.15",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "44.30",
			fclEwCode: "E",
			fclWaterDepth: "74",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286421",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286421",
			fclNpdidFacility: "286421",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK S-BS",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "STATPIPE",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "123940",
			fclStartupDate: "01.10.1986",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "33",
			fclNsSec: "5.38",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "44.18",
			fclEwCode: "E",
			fclWaterDepth: "74",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281325",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281325",
			fclNpdidFacility: "281325",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK SØR FL",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.01.1972",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "35.81",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "13",
			fclEwSec: "7.04",
			fclEwCode: "E",
			fclWaterDepth: "72",
			fclFunctions: "FLARE STACK",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281297",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281297",
			fclNpdidFacility: "281297",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK T",
			fclPhase: "PARTLY REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "DORIS",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.12.1974",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "58.35",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "45.29",
			fclEwCode: "E",
			fclWaterDepth: "77",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272141",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272141",
			fclNpdidFacility: "272141",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK T WALL",
			fclPhase: "SHUT DOWN",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "CONCRETE STRUCTURE",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "24.06.1989",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "58.35",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "45.29",
			fclEwCode: "E",
			fclWaterDepth: "77",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282669",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282669",
			fclNpdidFacility: "282669",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK VA",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "30.04.2010",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "17.28",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "43.22",
			fclEwCode: "E",
			fclWaterDepth: "73",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=409166",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=409166",
			fclNpdidFacility: "409166",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK VB",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "16.05.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "31",
			fclNsSec: "13.56",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "14",
			fclEwSec: "21.30",
			fclEwCode: "E",
			fclWaterDepth: "73",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=424664",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=424664",
			fclNpdidFacility: "424664",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK VB-T",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "16.05.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "29",
			fclNsSec: "41.89",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "10",
			fclEwSec: "39.06",
			fclEwCode: "E",
			fclWaterDepth: "73",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=426442",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=426442",
			fclNpdidFacility: "426442",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK VC",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.12.2018",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "31",
			fclNsSec: "20.22",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "14",
			fclEwSec: "41.17",
			fclEwCode: "E",
			fclWaterDepth: "72",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=460727",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=460727",
			fclNpdidFacility: "460727",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK W",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.01.1989",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "39.11",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "13",
			fclEwSec: "4.56",
			fclEwCode: "E",
			fclWaterDepth: "72",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277293",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277293",
			fclNpdidFacility: "277293",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK X",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.01.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "51.50",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "13",
			fclEwSec: "8.20",
			fclEwCode: "E",
			fclWaterDepth: "77",
			fclFunctions: "DRILLING",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278525",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278525",
			fclNpdidFacility: "278525",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK X-BS",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "01.01.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "51.55",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "12",
			fclEwSec: "59.65",
			fclEwCode: "E",
			fclWaterDepth: "76",
			fclFunctions: "",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281353",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281353",
			fclNpdidFacility: "281353",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EKOFISK Z",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43506",
			fclStartupDate: "25.10.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "38.58",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "13",
			fclEwSec: "32.37",
			fclEwCode: "E",
			fclWaterDepth: "79",
			fclFunctions: "PROCESSING - WELLHEAD",
			fclDesignLifetime: "40",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=425058",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=425058",
			fclNpdidFacility: "425058",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ELDFISK A",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 12 LEGS",
			fclBelongsToName: "ELDFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43527",
			fclStartupDate: "01.08.1979",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "22",
			fclNsSec: "36.77",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "15",
			fclEwSec: "56.89",
			fclEwCode: "E",
			fclWaterDepth: "71",
			fclFunctions: "DRILLING - OIL PRODUCER - PROCESSING - QUARTER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271581",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271581",
			fclNpdidFacility: "271581",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ELDFISK B",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 12 LEGS",
			fclBelongsToName: "ELDFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43527",
			fclStartupDate: "01.08.1979",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "25",
			fclNsSec: "9.59",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "13",
			fclEwSec: "6.22",
			fclEwCode: "E",
			fclWaterDepth: "71",
			fclFunctions: "DRILLING - OIL PRODUCER - PROCESSING - QUARTER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271609",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271609",
			fclNpdidFacility: "271609",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ELDFISK B-FL",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "ELDFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43527",
			fclStartupDate: "01.08.1979",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "25",
			fclNsSec: "5.66",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "13",
			fclEwSec: "10.01",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "FLARE STACK",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286029",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286029",
			fclNpdidFacility: "286029",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ELDFISK E",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "ELDFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43527",
			fclStartupDate: "01.01.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "22",
			fclNsSec: "30.31",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "15",
			fclEwSec: "54.74",
			fclEwCode: "E",
			fclWaterDepth: "72",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280093",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280093",
			fclNpdidFacility: "280093",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ELDFISK FTP",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "ELDFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43527",
			fclStartupDate: "01.08.1979",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "22",
			fclNsSec: "32.59",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "15",
			fclEwSec: "57.36",
			fclEwCode: "E",
			fclWaterDepth: "77",
			fclFunctions: "PROCESSING",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272057",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272057",
			fclNpdidFacility: "272057",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ELDFISK FTP-BS",
			fclPhase: "SHUT DOWN",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "ELDFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43527",
			fclStartupDate: "01.08.1979",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "22",
			fclNsSec: "28.76",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "15",
			fclEwSec: "59.51",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281409",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281409",
			fclNpdidFacility: "281409",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ELDFISK FTP-FL",
			fclPhase: "SHUT DOWN",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "ELDFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43527",
			fclStartupDate: "01.08.1979",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "22",
			fclNsSec: "25.53",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "16",
			fclEwSec: "2.14",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "FLARE STACK",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281437",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281437",
			fclNpdidFacility: "281437",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ELDFISK NA",
			fclPhase: "FABRICATION",
			fclSurface: "N",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ELDFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43527",
			fclStartupDate: "",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=748817",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=748817",
			fclNpdidFacility: "748817",
			fclDateUpdated: "05.08.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ELDFISK NB",
			fclPhase: "FABRICATION",
			fclSurface: "N",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ELDFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43527",
			fclStartupDate: "",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=749115",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=749115",
			fclNpdidFacility: "749115",
			fclDateUpdated: "05.08.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ELDFISK NC",
			fclPhase: "FABRICATION",
			fclSurface: "N",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ELDFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43527",
			fclStartupDate: "",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=749413",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=749413",
			fclNpdidFacility: "749413",
			fclDateUpdated: "05.08.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ELDFISK S",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "ELDFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43527",
			fclStartupDate: "03.01.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "22",
			fclNsSec: "25.46",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "15",
			fclEwSec: "45.71",
			fclEwCode: "E",
			fclWaterDepth: "72",
			fclFunctions: "PROCESSING - QUARTER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=432160",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=432160",
			fclNpdidFacility: "432160",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ELDFISK S-BS",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "ELDFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43527",
			fclStartupDate: "03.01.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "22",
			fclNsSec: "28.37",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "15",
			fclEwSec: "51.97",
			fclEwCode: "E",
			fclWaterDepth: "72",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=432219",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=432219",
			fclNpdidFacility: "432219",
			fclDateUpdated: "26.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EMBLA",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "EMBLA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43534",
			fclStartupDate: "13.05.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "19",
			fclNsSec: "59.63",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "14",
			fclEwSec: "53.75",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "ACCOMMODATION - OIL PRODUCER - PROCESSING - WELLHEAD",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277377",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277377",
			fclNpdidFacility: "277377",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EMDEN",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "GASSLED",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "2252643",
			fclStartupDate: "17.09.1977",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "53",
			fclNsMin: "22",
			fclNsSec: "36.99",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "13",
			fclEwSec: "7.99",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286141",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286141",
			fclNpdidFacility: "286141",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EMDEN SSIV",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "NORPIPE GASSLEDNING",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124165",
			fclStartupDate: "18.08.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "49.06",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "13",
			fclEwSec: "34.12",
			fclEwCode: "E",
			fclWaterDepth: "77",
			fclFunctions: "ISOLATION VALVE",
			fclDesignLifetime: "33",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287121",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287121",
			fclNpdidFacility: "287121",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ENERGIVERK MONGSTAD",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "28.09.2009",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "48",
			fclNsSec: "32.87",
			fclNsCode: "N",
			fclEwDeg: "5",
			fclEwMin: "2",
			fclEwSec: "19.30",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "GAS POWER PLANT",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=409313",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=409313",
			fclNpdidFacility: "409313",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EUROPIPE",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "53",
			fclNsMin: "39",
			fclNsSec: "68.61",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "26",
			fclEwSec: "16.00",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=416537",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=416537",
			fclNpdidFacility: "416537",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "EUROPIPE-SCP",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "GASSLED",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "2252643",
			fclStartupDate: "01.10.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "55",
			fclNsMin: "42",
			fclNsSec: "25.09",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "13",
			fclEwSec: "21.44",
			fclEwCode: "E",
			fclWaterDepth: "36",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286841",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286841",
			fclNpdidFacility: "286841",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FENJA G",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Neptune Energy Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "FENJA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "31164879",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "1",
			fclNsSec: "50.34",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "45",
			fclEwSec: "3.24",
			fclEwCode: "E",
			fclWaterDepth: "325",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=451120",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=451120",
			fclNpdidFacility: "451120",
			fclDateUpdated: "01.01.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FENJA GAS INJ/LIFT PLEM",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Neptune Energy Norge AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "FENJA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "31164879",
			fclStartupDate: "01.10.2021",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "325",
			fclFunctions: "PIPELINE END MANIFOLD",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=459350",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=459350",
			fclNpdidFacility: "459350",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FENJA H",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Neptune Energy Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "FENJA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "31164879",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "1",
			fclNsSec: "47.77",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "44",
			fclEwSec: "53.87",
			fclEwCode: "E",
			fclWaterDepth: "325",
			fclFunctions: "GAS INJECTOR - WATER INJECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=451185",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=451185",
			fclNpdidFacility: "451185",
			fclDateUpdated: "01.01.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FENJA PRODUCTION PLET",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "",
			fclBelongsToKind: "",
			fclBelongsToS: "",
			fclStartupDate: "01.10.2021",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "325",
			fclFunctions: "TERMINAL",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=459507",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=459507",
			fclNpdidFacility: "459507",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FENJA WATER INJ RISER BASE",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Neptune Energy Norge AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "FENJA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "31164879",
			fclStartupDate: "01.10.2021",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "325",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=459650",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=459650",
			fclNpdidFacility: "459650",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FLAGS TCS",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "A/S Norske Shell",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "KNARR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "20460988",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "1",
			fclNsSec: "15.05",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "41",
			fclEwSec: "44.81",
			fclEwCode: "E",
			fclWaterDepth: "139",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=433938",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=433938",
			fclNpdidFacility: "433938",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FLAGS-T",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TAMPEN LINK",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "3113815",
			fclStartupDate: "12.10.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "1",
			fclNsSec: "16.99",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "41",
			fclEwSec: "55.85",
			fclEwCode: "E",
			fclWaterDepth: "140",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=377070",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=377070",
			fclNpdidFacility: "377070",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FLORØ T",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "ÅSGARD TRANSPORT",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124211",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "24",
			fclNsSec: "5.47",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "7",
			fclEwSec: "57.64",
			fclEwCode: "E",
			fclWaterDepth: "345",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287513",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287513",
			fclNpdidFacility: "287513",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FOSSEKALL P",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SKULD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "21350124",
			fclStartupDate: "13.03.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "66",
			fclNsMin: "8",
			fclNsSec: "37.31",
			fclNsCode: "N",
			fclEwDeg: "8",
			fclEwMin: "13",
			fclEwSec: "58.85",
			fclEwCode: "E",
			fclWaterDepth: "358",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=417663",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=417663",
			fclNpdidFacility: "417663",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FOSSEKALL R",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SKULD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "21350124",
			fclStartupDate: "13.03.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "66",
			fclNsMin: "8",
			fclNsSec: "41.22",
			fclNsCode: "N",
			fclEwDeg: "8",
			fclEwMin: "14",
			fclEwSec: "13.09",
			fclEwCode: "E",
			fclWaterDepth: "358",
			fclFunctions: "OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=417722",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=417722",
			fclNpdidFacility: "417722",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FRAM A1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "FRAM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1578840",
			fclStartupDate: "02.10.2003",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "5",
			fclNsSec: "5.18",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "30",
			fclEwSec: "59.27",
			fclEwCode: "E",
			fclWaterDepth: "360",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=284713",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=284713",
			fclNpdidFacility: "284713",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FRAM A2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "FRAM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1578840",
			fclStartupDate: "02.10.2003",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "5",
			fclNsSec: "7.69",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "31",
			fclEwSec: "1.17",
			fclEwCode: "E",
			fclWaterDepth: "360",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285133",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285133",
			fclNpdidFacility: "285133",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FRAM B1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "FRAM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1578840",
			fclStartupDate: "30.10.2006",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "2",
			fclNsSec: "21.76",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "34",
			fclEwSec: "32.65",
			fclEwCode: "E",
			fclWaterDepth: "355",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=371482",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=371482",
			fclNpdidFacility: "371482",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FRAM B2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "FRAM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1578840",
			fclStartupDate: "30.10.2006",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "2",
			fclNsSec: "34.02",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "35",
			fclEwSec: "59.19",
			fclEwCode: "E",
			fclWaterDepth: "355",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=371520",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=371520",
			fclNpdidFacility: "371520",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FRAM H NORD",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "FRAM H-NORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "23410947",
			fclStartupDate: "06.09.2014",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "7",
			fclNsSec: "41.51",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "32",
			fclEwSec: "46.14",
			fclEwCode: "E",
			fclWaterDepth: "360",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=433590",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=433590",
			fclNpdidFacility: "433590",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FRAM T-1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "FRAM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1578840",
			fclStartupDate: "30.10.2006",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "1",
			fclNsSec: "27.25",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "34",
			fclEwSec: "24.81",
			fclEwCode: "E",
			fclWaterDepth: "360",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=371558",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=371558",
			fclNpdidFacility: "371558",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FRAM T-2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "FRAM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1578840",
			fclStartupDate: "30.10.2006",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "1",
			fclNsSec: "27.95",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "34",
			fclEwSec: "26.29",
			fclEwCode: "E",
			fclWaterDepth: "360",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=371595",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=371595",
			fclNpdidFacility: "371595",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FRANPIPE T",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "FRANPIPE",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124202",
			fclStartupDate: "01.07.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "53",
			fclNsMin: "5",
			fclNsSec: "41.09",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "7",
			fclEwSec: "48.39",
			fclEwCode: "E",
			fclWaterDepth: "30",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287233",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287233",
			fclNpdidFacility: "287233",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FREDERICIA",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "55",
			fclNsMin: "34",
			fclNsSec: "33.56",
			fclNsCode: "N",
			fclEwDeg: "9",
			fclEwMin: "46",
			fclEwSec: "49.94",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=416595",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=416595",
			fclNpdidFacility: "416595",
			fclDateUpdated: "02.10.2019",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FRIGG DP1",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "FRIGG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43555",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "52",
			fclNsSec: "26.50",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "3",
			fclEwSec: "39.00",
			fclEwCode: "E",
			fclWaterDepth: "98",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280933",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280933",
			fclNpdidFacility: "280933",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FRIGG DP2",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "FRIGG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43555",
			fclStartupDate: "01.08.1978",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "53",
			fclNsSec: "10.07",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "4",
			fclEwSec: "20.60",
			fclEwCode: "E",
			fclWaterDepth: "97",
			fclFunctions: "DRILLING - PROCESSING - QUARTER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277965",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277965",
			fclNpdidFacility: "277965",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FRIGG QP",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "52",
			fclNsSec: "48.48",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "3",
			fclEwSec: "59.40",
			fclEwCode: "E",
			fclWaterDepth: "102",
			fclFunctions: "QUARTER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=365776",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=365776",
			fclNpdidFacility: "365776",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FRIGG TCP2",
			fclPhase: "PARTLY REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "CONDEEP 3 SHAFTS",
			fclBelongsToName: "FRIGG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43555",
			fclStartupDate: "01.08.1978",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "52",
			fclNsSec: "48.44",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "3",
			fclEwSec: "59.53",
			fclEwCode: "E",
			fclWaterDepth: "102",
			fclFunctions: "PROCESSING",
			fclDesignLifetime: "60",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272561",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272561",
			fclNpdidFacility: "272561",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FRIGG TP1-Y",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "MARTIN LINGE GASSRØR",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "22223002",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "53",
			fclNsSec: "38.85",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "1",
			fclEwSec: "5.74",
			fclEwCode: "E",
			fclWaterDepth: "105",
			fclFunctions: "Y-CONNECTION",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=438553",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=438553",
			fclNpdidFacility: "438553",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FROSK TEST PRODUCER",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "BØYLA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "22492497",
			fclStartupDate: "26.08.2019",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "20",
			fclNsSec: "29.01",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "49",
			fclEwSec: "55.32",
			fclEwCode: "E",
			fclWaterDepth: "120",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=710400",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=710400",
			fclNpdidFacility: "710400",
			fclDateUpdated: "14.07.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "FRØY",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "FRØY",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43597",
			fclStartupDate: "11.05.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "44",
			fclNsSec: "3.00",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "33",
			fclEwSec: "28.00",
			fclEwCode: "E",
			fclWaterDepth: "119",
			fclFunctions: "WELLHEAD",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277433",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277433",
			fclNpdidFacility: "277433",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GDANSK",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "LANDFALL",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "54",
			fclNsMin: "38",
			fclNsSec: "13.85",
			fclNsCode: "N",
			fclEwDeg: "18",
			fclEwMin: "71",
			fclEwSec: "24.54",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=449005",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=449005",
			fclNpdidFacility: "449005",
			fclDateUpdated: "02.10.2019",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GINA KROG",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "GINA KROG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "23384544",
			fclStartupDate: "30.06.2017",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "34",
			fclNsSec: "19.85",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "41",
			fclEwSec: "48.44",
			fclEwCode: "E",
			fclWaterDepth: "116",
			fclFunctions: "PROCESSING - QUARTER - WELLHEAD",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=443856",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=443856",
			fclNpdidFacility: "443856",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GINA KROG FSO",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "FSU",
			fclBelongsToName: "GINA KROG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "23384544",
			fclStartupDate: "03.10.2017",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "35",
			fclNsSec: "2.42",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "44",
			fclEwSec: "3.57",
			fclEwCode: "E",
			fclWaterDepth: "116",
			fclFunctions: "OFFLOADING - STORAGE",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=444592",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=444592",
			fclNpdidFacility: "444592",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GINA KROG T",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "GINA KROG GASSIMPORT",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "23412532",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "26",
			fclNsSec: "26.29",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "2",
			fclEwSec: "39.83",
			fclEwCode: "E",
			fclWaterDepth: "116",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=444803",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=444803",
			fclNpdidFacility: "444803",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GJØA",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Neptune Energy Norge AS",
			fclKind: "SEMISUB STEEL",
			fclBelongsToName: "GJØA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4467574",
			fclStartupDate: "07.11.2010",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "19",
			fclNsSec: "56.51",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "53",
			fclEwSec: "48.55",
			fclEwCode: "E",
			fclWaterDepth: "360",
			fclFunctions: "PROCESSING - QUARTER",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=406672",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=406672",
			fclNpdidFacility: "406672",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GJØA B",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Neptune Energy Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GJØA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4467574",
			fclStartupDate: "07.11.2010",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "20",
			fclNsSec: "25.11",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "57",
			fclEwSec: "31.27",
			fclEwCode: "E",
			fclWaterDepth: "350",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=403298",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=403298",
			fclNpdidFacility: "403298",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GJØA C",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Neptune Energy Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GJØA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4467574",
			fclStartupDate: "07.11.2010",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "20",
			fclNsSec: "27.03",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "57",
			fclEwSec: "30.05",
			fclEwCode: "E",
			fclWaterDepth: "350",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=403356",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=403356",
			fclNpdidFacility: "403356",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GJØA D",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Neptune Energy Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GJØA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4467574",
			fclStartupDate: "07.11.2010",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "20",
			fclNsSec: "28.76",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "57",
			fclEwSec: "32.12",
			fclEwCode: "E",
			fclWaterDepth: "350",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=403414",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=403414",
			fclNpdidFacility: "403414",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GJØA E",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Neptune Energy Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GJØA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4467574",
			fclStartupDate: "07.11.2010",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "21",
			fclNsSec: "49.10",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "0",
			fclEwSec: "53.08",
			fclEwCode: "E",
			fclWaterDepth: "370",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=403472",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=403472",
			fclNpdidFacility: "403472",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GJØA F",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Neptune Energy Norge AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "GJØA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4467574",
			fclStartupDate: "07.11.2010",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "23",
			fclNsSec: "17.54",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "58",
			fclEwSec: "52.16",
			fclEwCode: "E",
			fclWaterDepth: "370",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=403530",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=403530",
			fclNpdidFacility: "403530",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GJØA G",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Neptune Energy Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GJØA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4467574",
			fclStartupDate: "22.02.2021",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "24",
			fclNsSec: "18.41",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "59",
			fclEwSec: "57.78",
			fclEwCode: "E",
			fclWaterDepth: "363",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=717659",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=717659",
			fclNpdidFacility: "717659",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GJØA-T",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Neptune Energy Norge AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "GJØA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4467574",
			fclStartupDate: "10.11.2010",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "1",
			fclNsSec: "14.80",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "42",
			fclEwSec: "0.81",
			fclEwCode: "E",
			fclWaterDepth: "150",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=406731",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=406731",
			fclNpdidFacility: "406731",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GOLIAT B",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GOLIAT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "5774394",
			fclStartupDate: "12.03.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "17",
			fclNsSec: "11.03",
			fclNsCode: "N",
			fclEwDeg: "22",
			fclEwMin: "17",
			fclEwSec: "6.51",
			fclEwCode: "E",
			fclWaterDepth: "387",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=410948",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=410948",
			fclNpdidFacility: "410948",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GOLIAT C",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GOLIAT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "5774394",
			fclStartupDate: "12.03.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "16",
			fclNsSec: "39.17",
			fclNsCode: "N",
			fclEwDeg: "22",
			fclEwMin: "16",
			fclEwSec: "43.54",
			fclEwCode: "E",
			fclWaterDepth: "376",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=411006",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=411006",
			fclNpdidFacility: "411006",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GOLIAT D",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GOLIAT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "5774394",
			fclStartupDate: "12.03.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "15",
			fclNsSec: "35.72",
			fclNsCode: "N",
			fclEwDeg: "22",
			fclEwMin: "15",
			fclEwSec: "42.55",
			fclEwCode: "E",
			fclWaterDepth: "350",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=411064",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=411064",
			fclNpdidFacility: "411064",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GOLIAT E",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GOLIAT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "5774394",
			fclStartupDate: "12.03.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "14",
			fclNsSec: "36.08",
			fclNsCode: "N",
			fclEwDeg: "22",
			fclEwMin: "15",
			fclEwSec: "0.54",
			fclEwCode: "E",
			fclWaterDepth: "323",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=411122",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=411122",
			fclNpdidFacility: "411122",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GOLIAT F",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GOLIAT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "5774394",
			fclStartupDate: "12.03.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "15",
			fclNsSec: "33.84",
			fclNsCode: "N",
			fclEwDeg: "22",
			fclEwMin: "15",
			fclEwSec: "35.76",
			fclEwCode: "E",
			fclWaterDepth: "350",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=411180",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=411180",
			fclNpdidFacility: "411180",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GOLIAT FPSO",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "FPSO",
			fclBelongsToName: "GOLIAT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "5774394",
			fclStartupDate: "12.03.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "18",
			fclNsSec: "39.74",
			fclNsCode: "N",
			fclEwDeg: "22",
			fclEwMin: "15",
			fclEwSec: "9.03",
			fclEwCode: "E",
			fclWaterDepth: "371",
			fclFunctions: "OFFLOADING - OIL PRODUCER - PROCESSING - QUARTER",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=410887",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=410887",
			fclNpdidFacility: "410887",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GOLIAT G",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GOLIAT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "5774394",
			fclStartupDate: "12.03.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "18",
			fclNsSec: "16.30",
			fclNsCode: "N",
			fclEwDeg: "22",
			fclEwMin: "18",
			fclEwSec: "38.02",
			fclEwCode: "E",
			fclWaterDepth: "394",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=411238",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=411238",
			fclNpdidFacility: "411238",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GOLIAT H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GOLIAT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "5774394",
			fclStartupDate: "12.03.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "17",
			fclNsSec: "11.43",
			fclNsCode: "N",
			fclEwDeg: "22",
			fclEwMin: "17",
			fclEwSec: "17.23",
			fclEwCode: "E",
			fclWaterDepth: "387",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=411296",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=411296",
			fclNpdidFacility: "411296",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GOLIAT I",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GOLIAT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "5774394",
			fclStartupDate: "12.03.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "14",
			fclNsSec: "32.81",
			fclNsCode: "N",
			fclEwDeg: "22",
			fclEwMin: "15",
			fclEwSec: "4.22",
			fclEwCode: "E",
			fclWaterDepth: "327",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=411354",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=411354",
			fclNpdidFacility: "411354",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GRANE",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "GRANE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1035937",
			fclStartupDate: "23.09.2003",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "9",
			fclNsSec: "54.86",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "29",
			fclEwSec: "14.60",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "DRILLING - OIL PRODUCER - PROCESSING - QUARTER",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283285",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283285",
			fclNpdidFacility: "283285",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GRANE SSIV",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "GRANE GASSRØR",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "1036213",
			fclStartupDate: "23.09.2003",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "10",
			fclNsSec: "2.69",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "29",
			fclEwSec: "14.21",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "ISOLATION VALVE",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=363581",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=363581",
			fclNpdidFacility: "363581",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GRANE-Y",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "EDVARD GRIEG OLJERØR",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "22508023",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "11",
			fclNsSec: "50.97",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "31",
			fclEwSec: "52.16",
			fclEwCode: "E",
			fclWaterDepth: "125",
			fclFunctions: "Y-CONNECTION",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=441741",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=441741",
			fclNpdidFacility: "441741",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GUDRUN",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "GUDRUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "18116481",
			fclStartupDate: "07.04.2014",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "50",
			fclNsSec: "42.80",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "44",
			fclEwSec: "37.40",
			fclEwCode: "E",
			fclWaterDepth: "109",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER - QUARTER - SEPARATION - WELLHEAD",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=410651",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=410651",
			fclNpdidFacility: "410651",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS A",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "CONDEEP 4 SHAFTS",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "21.12.1986",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "10",
			fclNsSec: "33.98",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "11",
			fclEwSec: "20.94",
			fclEwCode: "E",
			fclWaterDepth: "133",
			fclFunctions: "DRILLING - OIL PRODUCER - PROCESSING - QUARTER - STORAGE",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271833",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271833",
			fclNpdidFacility: "271833",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS A SSIV",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "01.02.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "10",
			fclNsSec: "33.98",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "11",
			fclEwSec: "21.10",
			fclEwCode: "E",
			fclWaterDepth: "135",
			fclFunctions: "ISOLATION VALVE",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287317",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287317",
			fclNpdidFacility: "287317",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS A-SPM 1",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "LOADING SYSTEM",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "21.12.1986",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "11",
			fclNsSec: "29.22",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "9",
			fclEwSec: "28.15",
			fclEwCode: "E",
			fclWaterDepth: "136",
			fclFunctions: "OFFLOADING",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272813",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272813",
			fclNpdidFacility: "272813",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS A-SPM 2",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "LOADING SYSTEM",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "29.02.1988",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "10",
			fclNsSec: "2.11",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "13",
			fclEwSec: "47.02",
			fclEwCode: "E",
			fclWaterDepth: "136",
			fclFunctions: "OFFLOADING",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272841",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272841",
			fclNpdidFacility: "272841",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS B",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "CONDEEP 4 SHAFTS",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "29.02.1988",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "12",
			fclNsSec: "10.50",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "12",
			fclEwSec: "4.68",
			fclEwCode: "E",
			fclWaterDepth: "141",
			fclFunctions: "DRILLING - OIL PRODUCER - PROCESSING - QUARTER - STORAGE",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271917",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271917",
			fclNpdidFacility: "271917",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS C",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "CONDEEP 4 SHAFTS",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "01.01.1990",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "12",
			fclNsSec: "53.80",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "16",
			fclEwSec: "25.93",
			fclEwCode: "E",
			fclWaterDepth: "216",
			fclFunctions: "DRILLING - OIL PRODUCER - PROCESSING - QUARTER - STORAGE",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272645",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272645",
			fclNpdidFacility: "272645",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS C SSIV",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "01.02.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "12",
			fclNsSec: "53.80",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "16",
			fclEwSec: "26.03",
			fclEwCode: "E",
			fclWaterDepth: "215",
			fclFunctions: "ISOLATION VALVE",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287345",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287345",
			fclNpdidFacility: "287345",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS OLS-1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "LOADING SYSTEM",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "01.06.2014",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "11",
			fclNsSec: "29.22",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "9",
			fclEwSec: "28.15",
			fclEwCode: "E",
			fclWaterDepth: "136",
			fclFunctions: "OFFLOADING",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=438710",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=438710",
			fclNpdidFacility: "438710",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS OLS-2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "LOADING SYSTEM",
			fclBelongsToName: "GULLFAKS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43686",
			fclStartupDate: "15.09.2014",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "10",
			fclNsSec: "2.11",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "13",
			fclEwSec: "47.02",
			fclEwCode: "E",
			fclWaterDepth: "136",
			fclFunctions: "LOADING BOUY",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=439911",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=439911",
			fclNpdidFacility: "439911",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS SC",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "GULLFAKS SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43699",
			fclStartupDate: "11.10.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "5",
			fclNsSec: "76.00",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "11",
			fclEwSec: "4.00",
			fclEwCode: "E",
			fclWaterDepth: "135",
			fclFunctions: "SUBSEA COMPRESSION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=443567",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=443567",
			fclNpdidFacility: "443567",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS SØR D",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43699",
			fclStartupDate: "08.10.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "7",
			fclNsSec: "26.55",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "14",
			fclEwSec: "39.45",
			fclEwCode: "E",
			fclWaterDepth: "135",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277517",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277517",
			fclNpdidFacility: "277517",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS SØR E",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43699",
			fclStartupDate: "08.10.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "6",
			fclNsSec: "38.40",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "15",
			fclEwSec: "38.37",
			fclEwCode: "E",
			fclWaterDepth: "135",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279701",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279701",
			fclNpdidFacility: "279701",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS SØR F",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43699",
			fclStartupDate: "08.10.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "5",
			fclNsSec: "51.01",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "16",
			fclEwSec: "36.50",
			fclEwCode: "E",
			fclWaterDepth: "135",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279645",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279645",
			fclNpdidFacility: "279645",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS SØR G",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43699",
			fclStartupDate: "08.10.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "5",
			fclNsSec: "50.23",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "16",
			fclEwSec: "36.25",
			fclEwCode: "E",
			fclWaterDepth: "135",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279673",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279673",
			fclNpdidFacility: "279673",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS SØR H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43699",
			fclStartupDate: "08.10.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "3",
			fclNsSec: "20.33",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "4",
			fclEwSec: "26.21",
			fclEwCode: "E",
			fclWaterDepth: "135",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280709",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280709",
			fclNpdidFacility: "280709",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS SØR I",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43699",
			fclStartupDate: "08.10.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "3",
			fclNsSec: "19.40",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "2",
			fclEwSec: "12.88",
			fclEwCode: "E",
			fclWaterDepth: "135",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280653",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280653",
			fclNpdidFacility: "280653",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS SØR J",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43699",
			fclStartupDate: "08.10.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "3",
			fclNsSec: "50.84",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "0",
			fclEwSec: "11.87",
			fclEwCode: "E",
			fclWaterDepth: "135",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280681",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280681",
			fclNpdidFacility: "280681",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS SØR K",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43699",
			fclStartupDate: "08.10.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "8",
			fclNsSec: "9.86",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "0",
			fclEwSec: "55.78",
			fclEwCode: "E",
			fclWaterDepth: "135",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280765",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280765",
			fclNpdidFacility: "280765",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS SØR L",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43699",
			fclStartupDate: "01.10.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "5",
			fclNsSec: "48.29",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "11",
			fclEwSec: "33.99",
			fclEwCode: "E",
			fclWaterDepth: "135",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279617",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279617",
			fclNpdidFacility: "279617",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS SØR M",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43699",
			fclStartupDate: "01.10.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "5",
			fclNsSec: "49.39",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "11",
			fclEwSec: "33.63",
			fclEwCode: "E",
			fclWaterDepth: "135",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281017",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281017",
			fclNpdidFacility: "281017",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS SØR O",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43699",
			fclStartupDate: "27.07.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "4",
			fclNsSec: "51.62",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "15",
			fclEwSec: "10.77",
			fclEwCode: "E",
			fclWaterDepth: "135",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=432415",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=432415",
			fclNpdidFacility: "432415",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS SØR P",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43699",
			fclStartupDate: "27.07.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "4",
			fclNsSec: "50.00",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "15",
			fclEwSec: "7.00",
			fclEwCode: "E",
			fclWaterDepth: "135",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=436279",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=436279",
			fclNpdidFacility: "436279",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS SØR Q",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43699",
			fclStartupDate: "29.08.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "4",
			fclNsSec: "12.82",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "6",
			fclEwSec: "21.42",
			fclEwCode: "E",
			fclWaterDepth: "140",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=441635",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=441635",
			fclNpdidFacility: "441635",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GULLFAKS T",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "STATPIPE 1",
			fclBelongsToKind: "TUF PART",
			fclBelongsToS: "124002",
			fclStartupDate: "01.02.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "5",
			fclNsSec: "46.79",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "0",
			fclEwSec: "58.56",
			fclEwCode: "E",
			fclWaterDepth: "136",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287289",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287289",
			fclNpdidFacility: "287289",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GYDA",
			fclPhase: "PARTLY REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "JACKET 6 LEGS",
			fclBelongsToName: "GYDA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43492",
			fclStartupDate: "22.06.1990",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "54",
			fclNsSec: "17.73",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "5",
			fclEwSec: "6.71",
			fclEwCode: "E",
			fclWaterDepth: "66",
			fclFunctions: "DRILLING - PROCESSING - QUARTER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272337",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272337",
			fclNpdidFacility: "272337",
			fclDateUpdated: "02.07.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "GYDA-Y",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "GYDA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43492",
			fclStartupDate: "22.04.1990",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "4",
			fclNsSec: "46.14",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "50",
			fclEwSec: "49.91",
			fclEwCode: "E",
			fclWaterDepth: "71",
			fclFunctions: "Y-CONNECTION",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286925",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286925",
			fclNpdidFacility: "286925",
			fclDateUpdated: "23.12.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "H-7",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "NORPIPE GASSLEDNING",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124165",
			fclStartupDate: "17.09.1977",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "54",
			fclNsMin: "30",
			fclNsSec: "33.61",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "2",
			fclEwSec: "9.15",
			fclEwCode: "E",
			fclWaterDepth: "41",
			fclFunctions: "BOOSTER - QUARTER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277237",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277237",
			fclNpdidFacility: "277237",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "H-7 BP",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "NORPIPE GASSLEDNING",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124165",
			fclStartupDate: "25.06.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "54",
			fclNsMin: "30",
			fclNsSec: "33.11",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "2",
			fclEwSec: "9.15",
			fclEwCode: "E",
			fclWaterDepth: "40",
			fclFunctions: "BYPASS",
			fclDesignLifetime: "28",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=377218",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=377218",
			fclNpdidFacility: "377218",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HARALD A",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=412931",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=412931",
			fclNpdidFacility: "412931",
			fclDateUpdated: "27.11.2019",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HARALD-Y",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "20",
			fclNsSec: "8.76",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "18",
			fclEwSec: "0.66",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "Y-CONNECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=441523",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=441523",
			fclNpdidFacility: "441523",
			fclDateUpdated: "02.10.2019",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HAUGSNESET",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "LANDFALL",
			fclBelongsToName: "JOHAN SVERDRUP",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26376286",
			fclStartupDate: "01.10.2018",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=730319",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=730319",
			fclNpdidFacility: "730319",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HEIDRUN",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "TLP CONCRETE",
			fclBelongsToName: "HEIDRUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43771",
			fclStartupDate: "19.10.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "19",
			fclNsSec: "33.00",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "19",
			fclEwSec: "3.00",
			fclEwCode: "E",
			fclWaterDepth: "345",
			fclFunctions: "DRILLING - OIL PRODUCER - PROCESSING - QUARTER",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272421",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272421",
			fclNpdidFacility: "272421",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HEIDRUN B",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "HEIDRUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43771",
			fclStartupDate: "15.08.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "18",
			fclNsSec: "46.00",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "15",
			fclEwSec: "45.00",
			fclEwCode: "E",
			fclWaterDepth: "339",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278049",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278049",
			fclNpdidFacility: "278049",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HEIDRUN C",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "HEIDRUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43771",
			fclStartupDate: "15.08.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "17",
			fclNsSec: "10.86",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "18",
			fclEwSec: "11.09",
			fclEwCode: "E",
			fclWaterDepth: "327",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=356124",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=356124",
			fclNpdidFacility: "356124",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HEIDRUN D",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "HEIDRUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43771",
			fclStartupDate: "15.08.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "21",
			fclNsSec: "42.80",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "20",
			fclEwSec: "9.56",
			fclEwCode: "E",
			fclWaterDepth: "359",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283593",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283593",
			fclNpdidFacility: "283593",
			fclDateUpdated: "08.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HEIDRUN E",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "HEIDRUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43771",
			fclStartupDate: "15.08.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "22",
			fclNsSec: "59.86",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "22",
			fclEwSec: "9.78",
			fclEwCode: "E",
			fclWaterDepth: "363",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283621",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283621",
			fclNpdidFacility: "283621",
			fclDateUpdated: "08.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HEIDRUN F",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "HEIDRUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43771",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "22",
			fclNsSec: "9.98",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "21",
			fclEwSec: "25.31",
			fclEwCode: "E",
			fclWaterDepth: "358",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283649",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283649",
			fclNpdidFacility: "283649",
			fclDateUpdated: "08.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HEIDRUN FSU",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "FSU",
			fclBelongsToName: "HEIDRUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43771",
			fclStartupDate: "01.06.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "20",
			fclNsSec: "37.03",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "21",
			fclEwSec: "57.13",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "OFFLOADING - STORAGE",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=443503",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=443503",
			fclNpdidFacility: "443503",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HEIDRUN PLEM-1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "HEIDRUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43771",
			fclStartupDate: "19.10.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "19",
			fclNsSec: "18.23",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "22",
			fclEwSec: "42.28",
			fclEwCode: "E",
			fclWaterDepth: "343",
			fclFunctions: "PIPELINE END MANIFOLD",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286785",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286785",
			fclNpdidFacility: "286785",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HEIDRUN PLEM-2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "HEIDRUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43771",
			fclStartupDate: "19.10.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "20",
			fclNsSec: "30.65",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "21",
			fclEwSec: "55.83",
			fclEwCode: "E",
			fclWaterDepth: "345",
			fclFunctions: "PIPELINE END MANIFOLD",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286813",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286813",
			fclNpdidFacility: "286813",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HEIDRUN RISER-BASE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "HEIDRUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43771",
			fclStartupDate: "15.08.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "19",
			fclNsSec: "24.49",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "19",
			fclEwSec: "3.00",
			fclEwCode: "E",
			fclWaterDepth: "350",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281997",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281997",
			fclNpdidFacility: "281997",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HEIDRUN SDU",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "HEIDRUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43771",
			fclStartupDate: "15.08.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "19",
			fclNsSec: "32.00",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "19",
			fclEwSec: "2.00",
			fclEwCode: "E",
			fclWaterDepth: "345",
			fclFunctions: "DISTRIBUTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282025",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282025",
			fclNpdidFacility: "282025",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HEIDRUN STL-I",
			fclPhase: "DECOMMISSIONED",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "LOADING SYSTEM",
			fclBelongsToName: "HEIDRUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43771",
			fclStartupDate: "19.10.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "19",
			fclNsSec: "13.99",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "22",
			fclEwSec: "53.60",
			fclEwCode: "E",
			fclWaterDepth: "343",
			fclFunctions: "OFFLOADING",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287681",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287681",
			fclNpdidFacility: "287681",
			fclDateUpdated: "14.06.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HEIDRUN STL-II",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "LOADING SYSTEM",
			fclBelongsToName: "HEIDRUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43771",
			fclStartupDate: "20.08.2014",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "20",
			fclNsSec: "37.03",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "21",
			fclEwSec: "57.13",
			fclEwCode: "E",
			fclWaterDepth: "353",
			fclFunctions: "OFFLOADING",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287709",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287709",
			fclNpdidFacility: "287709",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HEIDRUN TEMPLATE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "HEIDRUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43771",
			fclStartupDate: "15.08.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "19",
			fclNsSec: "32.75",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "19",
			fclEwSec: "3.47",
			fclEwCode: "E",
			fclWaterDepth: "345",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "40",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279533",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279533",
			fclNpdidFacility: "279533",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HEIDRUN UMBILCAL BASE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "HEIDRUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43771",
			fclStartupDate: "15.08.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "19",
			fclNsSec: "26.48",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "18",
			fclEwSec: "35.98",
			fclEwCode: "E",
			fclWaterDepth: "350",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282473",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282473",
			fclNpdidFacility: "282473",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HEIMDAL",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "HEIMDAL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43590",
			fclStartupDate: "15.10.1985",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "34",
			fclNsSec: "26.98",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "13",
			fclEwSec: "43.70",
			fclEwCode: "E",
			fclWaterDepth: "120",
			fclFunctions:
				"DISTRIBUTION - DRILLING - OIL PRODUCER - PROCESSING - QUARTER - WELLHEAD",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271777",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271777",
			fclNpdidFacility: "271777",
			fclDateUpdated: "08.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HEIMDAL HRP",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "OSEBERG GASSTRANSPORT",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124170",
			fclStartupDate: "30.09.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "34",
			fclNsSec: "31.08",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "13",
			fclEwSec: "41.90",
			fclEwCode: "E",
			fclWaterDepth: "120",
			fclFunctions: "RISER",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280569",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280569",
			fclNpdidFacility: "280569",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HOD",
			fclPhase: "SHUT DOWN",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "HOD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43485",
			fclStartupDate: "01.08.1990",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "10",
			fclNsSec: "35.52",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "27",
			fclEwSec: "36.22",
			fclEwCode: "E",
			fclWaterDepth: "72",
			fclFunctions: "OIL PRODUCER - WELLHEAD",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272309",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272309",
			fclNpdidFacility: "272309",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HOD B",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "HOD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43485",
			fclStartupDate: "11.04.2022",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "10",
			fclNsSec: "50.92",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "27",
			fclEwSec: "55.23",
			fclEwCode: "E",
			fclWaterDepth: "72",
			fclFunctions: "WELLHEAD",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=726653",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=726653",
			fclNpdidFacility: "726653",
			fclDateUpdated: "12.04.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HOUND POINT",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "14",
			fclNsSec: "10.79",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "23",
			fclEwSec: "10.21",
			fclEwCode: "W",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=416651",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=416651",
			fclNpdidFacility: "416651",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HULDRA",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "HULDRA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "97002",
			fclStartupDate: "21.11.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "51",
			fclNsSec: "20.97",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "39",
			fclEwSec: "1.17",
			fclEwCode: "E",
			fclWaterDepth: "125",
			fclFunctions: "OIL PRODUCER - WELLHEAD",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277573",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277573",
			fclNpdidFacility: "277573",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HYGGEVANNET",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "LANDFALL",
			fclBelongsToName: "GOLIAT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "5774394",
			fclStartupDate: "12.03.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "70",
			fclNsMin: "40",
			fclNsSec: "49.75",
			fclNsCode: "N",
			fclEwDeg: "23",
			fclEwMin: "43",
			fclEwSec: "36.37",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=437919",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=437919",
			fclNpdidFacility: "437919",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HYME",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "HYME",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "20474183",
			fclStartupDate: "25.02.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "21",
			fclNsSec: "29.38",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "33",
			fclEwSec: "4.52",
			fclEwCode: "E",
			fclWaterDepth: "256",
			fclFunctions: "OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=424593",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=424593",
			fclNpdidFacility: "424593",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "HÆWENE BRIM",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "FPSO",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "01.01.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "9",
			fclNsSec: "41.20",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "17",
			fclEwSec: "40.55",
			fclEwCode: "E",
			fclWaterDepth: "82",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=433262",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=433262",
			fclNpdidFacility: "433262",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "IDUN",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SKARV",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4704482",
			fclStartupDate: "31.12.2012",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "47",
			fclNsSec: "53.97",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "43",
			fclEwSec: "14.50",
			fclEwCode: "E",
			fclWaterDepth: "393",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=408694",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=408694",
			fclNpdidFacility: "408694",
			fclDateUpdated: "08.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "IVAR AASEN",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "IVAR AASEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "23384520",
			fclStartupDate: "24.12.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "55",
			fclNsSec: "20.17",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "11",
			fclEwSec: "53.25",
			fclEwCode: "E",
			fclWaterDepth: "113",
			fclFunctions: "PROCESSING - QUARTER - WELLHEAD",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=442024",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=442024",
			fclNpdidFacility: "442024",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN CASTBERG CA",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN CASTBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "32017325",
			fclStartupDate: "01.01.2022",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "72",
			fclNsMin: "27",
			fclNsSec: "13.03",
			fclNsCode: "N",
			fclEwDeg: "20",
			fclEwMin: "9",
			fclEwSec: "2.64",
			fclEwCode: "E",
			fclWaterDepth: "365",
			fclFunctions: "GAS INJECTION - GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=453134",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=453134",
			fclNpdidFacility: "453134",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN CASTBERG CB",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN CASTBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "32017325",
			fclStartupDate: "01.01.2022",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "72",
			fclNsMin: "26",
			fclNsSec: "30.15",
			fclNsCode: "N",
			fclEwDeg: "20",
			fclEwMin: "8",
			fclEwSec: "13.22",
			fclEwCode: "E",
			fclWaterDepth: "361",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=453075",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=453075",
			fclNpdidFacility: "453075",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN CASTBERG CC",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN CASTBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "32017325",
			fclStartupDate: "01.01.2052",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "72",
			fclNsMin: "23",
			fclNsSec: "25.54",
			fclNsCode: "N",
			fclEwDeg: "20",
			fclEwMin: "7",
			fclEwSec: "46.15",
			fclEwCode: "E",
			fclWaterDepth: "344",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=452957",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=452957",
			fclNpdidFacility: "452957",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN CASTBERG CD",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "JOHAN CASTBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "32017325",
			fclStartupDate: "01.01.2022",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "72",
			fclNsMin: "23",
			fclNsSec: "49.76",
			fclNsCode: "N",
			fclEwDeg: "20",
			fclEwMin: "8",
			fclEwSec: "4.93",
			fclEwCode: "E",
			fclWaterDepth: "345",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=453017",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=453017",
			fclNpdidFacility: "453017",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN CASTBERG DA",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN CASTBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "32017325",
			fclStartupDate: "01.01.2022",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "72",
			fclNsMin: "29",
			fclNsSec: "8.19",
			fclNsCode: "N",
			fclEwDeg: "20",
			fclEwMin: "20",
			fclEwSec: "42.72",
			fclEwCode: "E",
			fclWaterDepth: "373",
			fclFunctions: "GAS INJECTION - GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=453482",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=453482",
			fclNpdidFacility: "453482",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN CASTBERG EA",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN CASTBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "32017325",
			fclStartupDate: "01.01.2022",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "72",
			fclNsMin: "29",
			fclNsSec: "56.15",
			fclNsCode: "N",
			fclEwDeg: "20",
			fclEwMin: "18",
			fclEwSec: "43.81",
			fclEwCode: "E",
			fclWaterDepth: "378",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=453307",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=453307",
			fclNpdidFacility: "453307",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN CASTBERG EB",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN CASTBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "32017325",
			fclStartupDate: "01.01.2022",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "72",
			fclNsMin: "31",
			fclNsSec: "25.94",
			fclNsCode: "N",
			fclEwDeg: "20",
			fclEwMin: "20",
			fclEwSec: "47.88",
			fclEwCode: "E",
			fclWaterDepth: "389",
			fclFunctions: "GAS INJECTION - GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=453365",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=453365",
			fclNpdidFacility: "453365",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN CASTBERG EC",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN CASTBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "32017325",
			fclStartupDate: "01.01.2022",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "72",
			fclNsMin: "32",
			fclNsSec: "26.07",
			fclNsCode: "N",
			fclEwDeg: "20",
			fclEwMin: "21",
			fclEwSec: "31.49",
			fclEwCode: "E",
			fclWaterDepth: "392",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=453424",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=453424",
			fclNpdidFacility: "453424",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN CASTBERG FPSO",
			fclPhase: "FABRICATION",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "FPSO",
			fclBelongsToName: "",
			fclBelongsToKind: "",
			fclBelongsToS: "",
			fclStartupDate: "01.01.2022",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "72",
			fclNsMin: "29",
			fclNsSec: "2.40",
			fclNsCode: "N",
			fclEwDeg: "20",
			fclEwMin: "14",
			fclEwSec: "13.55",
			fclEwCode: "E",
			fclWaterDepth: "372",
			fclFunctions: "ACCOMMODATION - PROCESSING - QUARTER - STORAGE",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=452894",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=452894",
			fclNpdidFacility: "452894",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN CASTBERG UMBILICAL BASE",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "JOHAN CASTBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "32017325",
			fclStartupDate: "01.01.2022",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "72",
			fclNsMin: "29",
			fclNsSec: "2.40",
			fclNsCode: "N",
			fclEwDeg: "20",
			fclEwMin: "14",
			fclEwSec: "13.55",
			fclEwCode: "E",
			fclWaterDepth: "360",
			fclFunctions: "RISER BASE - UMBILICAL SUPPORT",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=459964",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=459964",
			fclNpdidFacility: "459964",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN CASTBERG YA",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN CASTBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "32017325",
			fclStartupDate: "01.01.2022",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "72",
			fclNsMin: "29",
			fclNsSec: "32.75",
			fclNsCode: "N",
			fclEwDeg: "20",
			fclEwMin: "22",
			fclEwSec: "7.52",
			fclEwCode: "E",
			fclWaterDepth: "371",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=453541",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=453541",
			fclNpdidFacility: "453541",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN CASTBERG YB",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN CASTBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "32017325",
			fclStartupDate: "01.01.2022",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "72",
			fclNsMin: "32",
			fclNsSec: "2.47",
			fclNsCode: "N",
			fclEwDeg: "20",
			fclEwMin: "25",
			fclEwSec: "9.94",
			fclEwCode: "E",
			fclWaterDepth: "390",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=453598",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=453598",
			fclNpdidFacility: "453598",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN CASTBERG ZA",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN CASTBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "32017325",
			fclStartupDate: "01.01.2022",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "72",
			fclNsMin: "28",
			fclNsSec: "16.02",
			fclNsCode: "N",
			fclEwDeg: "20",
			fclEwMin: "12",
			fclEwSec: "42.22",
			fclEwCode: "E",
			fclWaterDepth: "367",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=453193",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=453193",
			fclNpdidFacility: "453193",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN CASTBERG ZB",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "JOHAN CASTBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "32017325",
			fclStartupDate: "01.01.2022",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "72",
			fclNsMin: "26",
			fclNsSec: "55.40",
			fclNsCode: "N",
			fclEwDeg: "20",
			fclEwMin: "12",
			fclEwSec: "58.56",
			fclEwCode: "E",
			fclWaterDepth: "358",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=453250",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=453250",
			fclNpdidFacility: "453250",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN SVERDRUP D",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN SVERDRUP",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26376286",
			fclStartupDate: "01.06.2016",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "50",
			fclNsSec: "9.57",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "33",
			fclEwSec: "12.85",
			fclEwCode: "E",
			fclWaterDepth: "120",
			fclFunctions: "DRILLING TEMPLATE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=449393",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=449393",
			fclNpdidFacility: "449393",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN SVERDRUP DP",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "JOHAN SVERDRUP",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26376286",
			fclStartupDate: "08.11.2018",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "50",
			fclNsSec: "9.55",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "33",
			fclEwSec: "13.38",
			fclEwCode: "E",
			fclWaterDepth: "113",
			fclFunctions: "DRILLING",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=451874",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=451874",
			fclNpdidFacility: "451874",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN SVERDRUP E",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN SVERDRUP",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26376286",
			fclStartupDate: "05.10.2019",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "52",
			fclNsSec: "11.46",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "31",
			fclEwSec: "9.90",
			fclEwCode: "E",
			fclWaterDepth: "120",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=449559",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=449559",
			fclNpdidFacility: "449559",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN SVERDRUP F",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN SVERDRUP",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26376286",
			fclStartupDate: "05.10.2019",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "47",
			fclNsSec: "5.25",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "35",
			fclEwSec: "52.22",
			fclEwCode: "E",
			fclWaterDepth: "120",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=449339",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=449339",
			fclNpdidFacility: "449339",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN SVERDRUP G",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN SVERDRUP",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26376286",
			fclStartupDate: "05.10.2019",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "46",
			fclNsSec: "0.54",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "39",
			fclEwSec: "10.20",
			fclEwCode: "E",
			fclWaterDepth: "120",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=449669",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=449669",
			fclNpdidFacility: "449669",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN SVERDRUP H",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN SVERDRUP",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26376286",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "52",
			fclNsSec: "51.07",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "29",
			fclEwSec: "18.45",
			fclEwCode: "E",
			fclWaterDepth: "115",
			fclFunctions: "GAS INJECTION - OIL PRODUCER",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=721264",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=721264",
			fclNpdidFacility: "721264",
			fclDateUpdated: "05.01.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN SVERDRUP K",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN SVERDRUP",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26376286",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "44",
			fclNsSec: "52.91",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "34",
			fclEwSec: "13.80",
			fclEwCode: "E",
			fclWaterDepth: "109",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=721563",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=721563",
			fclNpdidFacility: "721563",
			fclDateUpdated: "05.01.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN SVERDRUP LQ",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "JOHAN SVERDRUP",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26376286",
			fclStartupDate: "01.05.2019",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "50",
			fclNsSec: "9.18",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "32",
			fclEwSec: "50.12",
			fclEwCode: "E",
			fclWaterDepth: "113",
			fclFunctions: "ACCOMMODATION - QUARTER",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=451651",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=451651",
			fclNpdidFacility: "451651",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN SVERDRUP O",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN SVERDRUP",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26376286",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "48",
			fclNsSec: "26.60",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "40",
			fclEwSec: "47.14",
			fclEwCode: "E",
			fclWaterDepth: "116",
			fclFunctions: "",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=721862",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=721862",
			fclNpdidFacility: "721862",
			fclDateUpdated: "05.01.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN SVERDRUP P",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN SVERDRUP",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26376286",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "48",
			fclNsSec: "23.75",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "42",
			fclEwSec: "36.83",
			fclEwCode: "E",
			fclWaterDepth: "116",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=722158",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=722158",
			fclNpdidFacility: "722158",
			fclDateUpdated: "05.01.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN SVERDRUP P1",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "JOHAN SVERDRUP",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26376286",
			fclStartupDate: "05.10.2019",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "50",
			fclNsSec: "11.86",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "33",
			fclEwSec: "1.00",
			fclEwCode: "E",
			fclWaterDepth: "113",
			fclFunctions: "DISTRIBUTION - PROCESSING - SEPARATION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=451762",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=451762",
			fclNpdidFacility: "451762",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN SVERDRUP P2",
			fclPhase: "INSTALLATION",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "JOHAN SVERDRUP",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26376286",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "50",
			fclNsSec: "15.55",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "33",
			fclEwSec: "37.38",
			fclEwCode: "E",
			fclWaterDepth: "114",
			fclFunctions: "PROCESSING",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=720966",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=720966",
			fclNpdidFacility: "720966",
			fclDateUpdated: "05.01.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN SVERDRUP Q",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "JOHAN SVERDRUP",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26376286",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "46",
			fclNsSec: "9.51",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "45",
			fclEwSec: "44.67",
			fclEwCode: "E",
			fclWaterDepth: "117",
			fclFunctions: "OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=722456",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=722456",
			fclNpdidFacility: "722456",
			fclDateUpdated: "05.01.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN SVERDRUP RP",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "JOHAN SVERDRUP",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26376286",
			fclStartupDate: "05.10.2019",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "50",
			fclNsSec: "13.00",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "33",
			fclEwSec: "25.00",
			fclEwCode: "E",
			fclWaterDepth: "115",
			fclFunctions: "EXPORT POINT - GAS EXPORT - RISER - UMBILICAL SUPPORT",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=449981",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=449981",
			fclNpdidFacility: "449981",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN SVERDRUP STATPIPE HOT TAP ",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "JOHAN SVERDRUP",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26376286",
			fclStartupDate: "05.10.2019",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "19",
			fclNsSec: "37.75",
			fclNsCode: "N",
			fclEwDeg: "5",
			fclEwMin: "3",
			fclEwSec: "12.28",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "GAS EXPORT - MANIFOLD",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=456869",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=456869",
			fclNpdidFacility: "456869",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOHAN SVERDRUP STATPIPE PLEM",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "JOHAN SVERDRUP",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26376286",
			fclStartupDate: "05.10.2019",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "19",
			fclNsSec: "35.80",
			fclNsCode: "N",
			fclEwDeg: "5",
			fclEwMin: "3",
			fclEwSec: "10.42",
			fclEwCode: "E",
			fclWaterDepth: "128",
			fclFunctions: "PIPELINE END MANIFOLD",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=456808",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=456808",
			fclNpdidFacility: "456808",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOTUN A",
			fclPhase: "LAID UP",
			fclSurface: "Y",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "FPSO",
			fclBelongsToName: "JOTUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43604",
			fclStartupDate: "21.10.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "27",
			fclNsSec: "18.92",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "23",
			fclEwSec: "10.89",
			fclEwCode: "E",
			fclWaterDepth: "126",
			fclFunctions: "OFFLOADING - OIL PRODUCER - PROCESSING - QUARTER - STORAGE",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279393",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279393",
			fclNpdidFacility: "279393",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOTUN B",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "JOTUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43604",
			fclStartupDate: "21.10.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "27",
			fclNsSec: "3.67",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "22",
			fclEwSec: "0.87",
			fclEwCode: "E",
			fclWaterDepth: "127",
			fclFunctions: "DRILLING - WELLHEAD",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279365",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279365",
			fclNpdidFacility: "279365",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOTUN SSIV",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "JOTUN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43604",
			fclStartupDate: "22.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "27",
			fclNsSec: "27.10",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "21",
			fclEwSec: "52.15",
			fclEwCode: "E",
			fclWaterDepth: "126",
			fclFunctions: "ISOLATION VALVE",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287177",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287177",
			fclNpdidFacility: "287177",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "JOTUN T",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "STATPIPE 4A",
			fclBelongsToKind: "TUF PART",
			fclBelongsToS: "1341660",
			fclStartupDate: "22.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "28",
			fclNsSec: "38.00",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "13",
			fclEwSec: "59.00",
			fclEwCode: "E",
			fclWaterDepth: "120",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287093",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287093",
			fclNpdidFacility: "287093",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KALSTØ",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "STATPIPE",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "123940",
			fclStartupDate: "01.10.1985",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "20",
			fclNsSec: "0.23",
			fclNsCode: "N",
			fclEwDeg: "5",
			fclEwMin: "11",
			fclEwSec: "42.53",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286197",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286197",
			fclNpdidFacility: "286197",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KALUNDBORG",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "LANDFALL",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "55",
			fclNsMin: "39",
			fclNsSec: "50.43",
			fclNsCode: "N",
			fclEwDeg: "11",
			fclEwMin: "5",
			fclEwSec: "43.22",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=712365",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=712365",
			fclNpdidFacility: "712365",
			fclDateUpdated: "23.02.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KINNEIL",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "1",
			fclNsSec: "45.00",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "39",
			fclEwSec: "30.00",
			fclEwCode: "W",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=416708",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=416708",
			fclNpdidFacility: "416708",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KLAB",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "16",
			fclNsSec: "24.00",
			fclNsCode: "N",
			fclEwDeg: "5",
			fclEwMin: "31",
			fclEwSec: "16.89",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "EXPORT POINT",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=416765",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=416765",
			fclNpdidFacility: "416765",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KNARR A",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "A/S Norske Shell",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "KNARR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "20460988",
			fclStartupDate: "16.03.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "48",
			fclNsSec: "43.15",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "46",
			fclEwSec: "24.64",
			fclEwCode: "E",
			fclWaterDepth: "412",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=433878",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=433878",
			fclNpdidFacility: "433878",
			fclDateUpdated: "30.04.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KNARR B",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "A/S Norske Shell",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "KNARR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "20460988",
			fclStartupDate: "16.03.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "47",
			fclNsSec: "45.71",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "44",
			fclEwSec: "48.85",
			fclEwCode: "E",
			fclWaterDepth: "403",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=434584",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=434584",
			fclNpdidFacility: "434584",
			fclDateUpdated: "30.04.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KNELER A",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ALVHEIM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2845712",
			fclStartupDate: "08.06.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "32",
			fclNsSec: "52.14",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "1",
			fclEwSec: "8.65",
			fclEwCode: "E",
			fclWaterDepth: "119",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=375056",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=375056",
			fclNpdidFacility: "375056",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KNELER B",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ALVHEIM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2845712",
			fclStartupDate: "08.06.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "32",
			fclNsSec: "47.53",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "59",
			fclEwSec: "19.14",
			fclEwCode: "E",
			fclWaterDepth: "124",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=375092",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=375092",
			fclNpdidFacility: "375092",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KOLLSNES",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "GASSLED",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "2252643",
			fclStartupDate: "01.10.1996",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "32",
			fclNsSec: "53.99",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "50",
			fclEwSec: "43.45",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286729",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286729",
			fclNpdidFacility: "286729",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KOLLSNES NÆRINGSPARK",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "32",
			fclNsSec: "53.99",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "50",
			fclEwSec: "43.45",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=440883",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=440883",
			fclNpdidFacility: "440883",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KOLLSNES T",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "ÅSGARD TRANSPORT",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124211",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "36",
			fclNsSec: "17.92",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "15",
			fclEwSec: "47.28",
			fclEwCode: "E",
			fclWaterDepth: "308",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287541",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287541",
			fclNpdidFacility: "287541",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KRISTIN",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SEMISUB STEEL",
			fclBelongsToName: "KRISTIN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1854729",
			fclStartupDate: "03.11.2005",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "59",
			fclNsSec: "38.24",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "33",
			fclEwSec: "4.83",
			fclEwCode: "E",
			fclWaterDepth: "360",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER - PROCESSING - QUARTER",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=368635",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=368635",
			fclNpdidFacility: "368635",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KRISTIN N",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "KRISTIN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1854729",
			fclStartupDate: "03.11.2005",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "1",
			fclNsSec: "41.20",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "28",
			fclEwSec: "46.58",
			fclEwCode: "E",
			fclWaterDepth: "368",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=360488",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=360488",
			fclNpdidFacility: "360488",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KRISTIN P",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "KRISTIN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1854729",
			fclStartupDate: "27.12.2005",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "0",
			fclNsSec: "52.67",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "26",
			fclEwSec: "17.57",
			fclEwCode: "E",
			fclWaterDepth: "372",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=360577",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=360577",
			fclNpdidFacility: "360577",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KRISTIN Q",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "KRISTIN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1854729",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "52",
			fclNsSec: "52.18",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "26",
			fclEwSec: "5.77",
			fclEwCode: "E",
			fclWaterDepth: "320",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=395591",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=395591",
			fclNpdidFacility: "395591",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KRISTIN R",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "KRISTIN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1854729",
			fclStartupDate: "22.01.2006",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "57",
			fclNsSec: "36.76",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "26",
			fclEwSec: "18.79",
			fclEwCode: "E",
			fclWaterDepth: "356",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=360533",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=360533",
			fclNpdidFacility: "360533",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KRISTIN S",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "KRISTIN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1854729",
			fclStartupDate: "03.11.2005",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "58",
			fclNsSec: "39.08",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "26",
			fclEwSec: "5.69",
			fclEwCode: "E",
			fclWaterDepth: "364",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=360444",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=360444",
			fclNpdidFacility: "360444",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KRISTIN T",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "ÅSGARD TRANSPORT",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124211",
			fclStartupDate: "05.11.2005",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "57",
			fclNsSec: "59.81",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "46",
			fclEwSec: "11.21",
			fclEwCode: "E",
			fclWaterDepth: "319",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287429",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287429",
			fclNpdidFacility: "287429",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KVITEBJØRN",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "KVITEBJØRN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1036101",
			fclStartupDate: "26.09.2004",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "4",
			fclNsSec: "49.10",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "29",
			fclEwSec: "58.99",
			fclEwCode: "E",
			fclWaterDepth: "190",
			fclFunctions: "DRILLING - PROCESSING - QUARTER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280877",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280877",
			fclNpdidFacility: "280877",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "KÅRSTØ",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "GASSLED",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "2252643",
			fclStartupDate: "01.10.1985",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "16",
			fclNsSec: "24.10",
			fclNsCode: "N",
			fclEwDeg: "5",
			fclEwMin: "31",
			fclEwSec: "16.89",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286169",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286169",
			fclNpdidFacility: "286169",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "LANGELED SSVS",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "LANGELED",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "2450169",
			fclStartupDate: "13.09.2007",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "83",
			fclFunctions: "VALVE",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=405699",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=405699",
			fclNpdidFacility: "405699",
			fclDateUpdated: "26.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "LAVRANS",
			fclPhase: "ABANDONED IN PLACE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "KRISTIN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1854729",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "50",
			fclNsSec: "54.58",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "33",
			fclEwSec: "33.65",
			fclEwCode: "E",
			fclWaterDepth: "270",
			fclFunctions: "DRILLING TEMPLATE",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=461086",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=461086",
			fclNpdidFacility: "461086",
			fclDateUpdated: "02.07.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "LAVRANS L",
			fclPhase: "FABRICATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "KRISTIN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1854729",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "50",
			fclNsSec: "42.50",
			fclNsCode: "N",
			fclEwDeg: "0",
			fclEwMin: "33",
			fclEwSec: "10.52",
			fclEwCode: "E",
			fclWaterDepth: "270",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=748116",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=748116",
			fclNpdidFacility: "748116",
			fclDateUpdated: "02.07.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "LILLE-FRIGG",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "LILLE-FRIGG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43583",
			fclStartupDate: "01.05.1994",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "57",
			fclNsSec: "41.90",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "23",
			fclEwSec: "27.00",
			fclEwCode: "E",
			fclWaterDepth: "113",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277825",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277825",
			fclNpdidFacility: "277825",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "LILLE-FRIGG A",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "LILLE-FRIGG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43583",
			fclStartupDate: "01.05.1994",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "58",
			fclNsSec: "20.47",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "23",
			fclEwSec: "6.28",
			fclEwCode: "E",
			fclWaterDepth: "110",
			fclFunctions: "MANIFOLD STATION ",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282165",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282165",
			fclNpdidFacility: "282165",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "LILLE-FRIGG B",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "LILLE-FRIGG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43583",
			fclStartupDate: "01.05.1994",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "57",
			fclNsSec: "58.15",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "23",
			fclEwSec: "18.17",
			fclEwCode: "E",
			fclWaterDepth: "113",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282193",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282193",
			fclNpdidFacility: "282193",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "LISTA",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "LANDFALL",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "01.06.2011",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "10",
			fclNsSec: "39.34",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "37",
			fclEwSec: "33.85",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=410491",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=410491",
			fclNpdidFacility: "410491",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "MAINLINE I.V",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "20",
			fclNsSec: "7.72",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "18",
			fclEwSec: "2.51",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=441580",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=441580",
			fclNpdidFacility: "441580",
			fclDateUpdated: "02.10.2019",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "MARIA G",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Wintershall Dea Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "MARIA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26465170",
			fclStartupDate: "17.12.2017",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "58",
			fclNsSec: "28.66",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "58",
			fclEwSec: "36.02",
			fclEwCode: "E",
			fclWaterDepth: "304",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=447355",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=447355",
			fclNpdidFacility: "447355",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "MARIA H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Wintershall Dea Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "MARIA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "26465170",
			fclStartupDate: "17.12.2017",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "56",
			fclNsSec: "50.02",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "57",
			fclEwSec: "16.43",
			fclEwCode: "E",
			fclWaterDepth: "303",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=447414",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=447414",
			fclNpdidFacility: "447414",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "MARTIN LINGE A",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "MARTIN LINGE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "21675447",
			fclStartupDate: "30.06.2021",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "30",
			fclNsSec: "22.28",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "0",
			fclEwSec: "53.38",
			fclEwCode: "E",
			fclWaterDepth: "114",
			fclFunctions: "PROCESSING - QUARTER - WELLHEAD",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=436400",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=436400",
			fclNpdidFacility: "436400",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "MARTIN LINGE B",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "FSU",
			fclBelongsToName: "MARTIN LINGE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "21675447",
			fclStartupDate: "30.06.2021",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "29",
			fclNsSec: "52.20",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "4",
			fclEwSec: "25.07",
			fclEwCode: "E",
			fclWaterDepth: "114",
			fclFunctions: "OFFLOADING - STORAGE",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=444051",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=444051",
			fclNpdidFacility: "444051",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "MARULK",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "MARULK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "18212090",
			fclStartupDate: "02.04.2012",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "56",
			fclNsSec: "0.51",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "32",
			fclEwSec: "37.10",
			fclEwCode: "E",
			fclWaterDepth: "368",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=410829",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=410829",
			fclNpdidFacility: "410829",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "MELKØYA",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "MELKØYA LANDANLEGG",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124383",
			fclStartupDate: "13.09.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "70",
			fclNsMin: "41",
			fclNsSec: "14.82",
			fclNsCode: "N",
			fclEwDeg: "23",
			fclEwMin: "35",
			fclEwSec: "58.02",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=363441",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=363441",
			fclNpdidFacility: "363441",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "MIKKEL A",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "MIKKEL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1630514",
			fclStartupDate: "01.10.2003",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "42",
			fclNsSec: "45.43",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "40",
			fclEwSec: "50.60",
			fclEwCode: "E",
			fclWaterDepth: "220",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283509",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283509",
			fclNpdidFacility: "283509",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "MIKKEL B",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "MIKKEL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1630514",
			fclStartupDate: "01.10.2003",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "38",
			fclNsSec: "49.50",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "39",
			fclEwSec: "49.11",
			fclEwCode: "E",
			fclWaterDepth: "220",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283537",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283537",
			fclNpdidFacility: "283537",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "MIME",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "MIME",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43792",
			fclStartupDate: "01.10.1990",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "7",
			fclNsSec: "12.51",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "29",
			fclEwSec: "10.56",
			fclEwCode: "E",
			fclWaterDepth: "85",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277461",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277461",
			fclNpdidFacility: "277461",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "MONGSTAD",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "MONGSTAD TERMINAL",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124360",
			fclStartupDate: "01.01.1986",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "48",
			fclNsSec: "53.25",
			fclNsCode: "N",
			fclEwDeg: "5",
			fclEwMin: "2",
			fclEwSec: "12.32",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286701",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286701",
			fclNpdidFacility: "286701",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "MOR-A",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "MORVIN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4966234",
			fclStartupDate: "01.08.2010",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "8",
			fclNsSec: "13.10",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "28",
			fclEwSec: "40.35",
			fclEwCode: "E",
			fclWaterDepth: "360",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=405160",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=405160",
			fclNpdidFacility: "405160",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "MOR-B",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "MORVIN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4966234",
			fclStartupDate: "01.08.2010",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "9",
			fclNsSec: "47.35",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "29",
			fclEwSec: "50.09",
			fclEwCode: "E",
			fclWaterDepth: "360",
			fclFunctions: "GAS INJECTION - OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=405219",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=405219",
			fclNpdidFacility: "405219",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "MTS SSIV",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "OSEBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43625",
			fclStartupDate: "01.03.1992",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "108",
			fclFunctions: "ISOLATION VALVE",
			fclDesignLifetime: "10",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=395321",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=395321",
			fclNpdidFacility: "395321",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "MURCHISON A",
			fclPhase: "SHUT DOWN",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "JACKET 12 LEGS",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "28.09.1980",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "23",
			fclNsSec: "48.26",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "44",
			fclEwSec: "27.25",
			fclEwCode: "E",
			fclWaterDepth: "156",
			fclFunctions: "DRILLING - OIL PRODUCER - QUARTER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=372759",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=372759",
			fclNpdidFacility: "372759",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "MØRE T",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "ÅSGARD TRANSPORT",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124211",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "63",
			fclNsMin: "35",
			fclNsSec: "59.93",
			fclNsCode: "N",
			fclEwDeg: "5",
			fclEwMin: "59",
			fclEwSec: "36.97",
			fclEwCode: "E",
			fclWaterDepth: "254",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287457",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287457",
			fclNpdidFacility: "287457",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NATURGASS VEST",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "32",
			fclNsSec: "54.00",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "50",
			fclEwSec: "43.45",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=416822",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=416822",
			fclNpdidFacility: "416822",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NATURKRAFT",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "01.11.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "16",
			fclNsSec: "24.10",
			fclNsCode: "N",
			fclEwDeg: "5",
			fclEwMin: "31",
			fclEwSec: "16.89",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "GAS POWER PLANT",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=377181",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=377181",
			fclNpdidFacility: "377181",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NAVION SAGA",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ABP Norway AS",
			fclKind: "FSU",
			fclBelongsToName: "VOLVE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "3420717",
			fclStartupDate: "12.02.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "27",
			fclNsSec: "40.89",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "54",
			fclEwSec: "30.43",
			fclEwCode: "E",
			fclWaterDepth: "90",
			fclFunctions: "OFFLOADING - STORAGE",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=376822",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=376822",
			fclNpdidFacility: "376822",
			fclDateUpdated: "10.07.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NBP",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "VIRTUAL SALEPOINT",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=446925",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=446925",
			fclNpdidFacility: "446925",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NCG",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "48",
			fclNsMin: "8",
			fclNsSec: "30.76",
			fclNsCode: "N",
			fclEwDeg: "11",
			fclEwMin: "34",
			fclEwSec: "50.62",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "VIRTUAL SALEPOINT",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=416880",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=416880",
			fclNpdidFacility: "416880",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NJORD A",
			fclPhase: "INSTALLATION",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SEMISUB STEEL",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "15.56",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "5.48",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "DRILLING - OIL PRODUCER - PROCESSING - QUARTER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277713",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277713",
			fclNpdidFacility: "277713",
			fclDateUpdated: "23.04.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NJORD BRAVO",
			fclPhase: "INSTALLATION",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "FSU",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "19.42",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "15",
			fclEwSec: "11.09",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "OFFLOADING - STORAGE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279561",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279561",
			fclNpdidFacility: "279561",
			fclDateUpdated: "06.08.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NJORD SDU-1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "15.55",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "4.48",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "DISTRIBUTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285329",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285329",
			fclNpdidFacility: "285329",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NJORD SDU-2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "15.55",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "12",
			fclEwSec: "5.48",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "DISTRIBUTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285385",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285385",
			fclNpdidFacility: "285385",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NJORD STL",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "LOADING SYSTEM",
			fclBelongsToName: "NJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43751",
			fclStartupDate: "01.10.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "16",
			fclNsSec: "19.42",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "15",
			fclEwSec: "11.09",
			fclEwCode: "E",
			fclWaterDepth: "101",
			fclFunctions: "",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287737",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287737",
			fclNpdidFacility: "287737",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NJORD T",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "DRAUGEN GASSEKSPORT",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "1035952",
			fclStartupDate: "10.12.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "12",
			fclNsSec: "19.36",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "15",
			fclEwSec: "35.86",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287653",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287653",
			fclNpdidFacility: "287653",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NORDØST FRIGG",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "NORDØST FRIGG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43568",
			fclStartupDate: "01.01.1984",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "59",
			fclNsSec: "11.44",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "14",
			fclEwSec: "57.54",
			fclEwCode: "E",
			fclWaterDepth: "102",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271721",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271721",
			fclNpdidFacility: "271721",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NORDØST FRIGG A",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MONOTOWER",
			fclBelongsToName: "NORDØST FRIGG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43568",
			fclStartupDate: "01.01.1984",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "59",
			fclNsSec: "11.44",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "14",
			fclEwSec: "57.54",
			fclEwCode: "E",
			fclWaterDepth: "102",
			fclFunctions: "FIELD CONTROL CENTER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282137",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282137",
			fclNpdidFacility: "282137",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NORNE B",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "NORNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43778",
			fclStartupDate: "05.11.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "66",
			fclNsMin: "0",
			fclNsSec: "54.61",
			fclNsCode: "N",
			fclEwDeg: "8",
			fclEwMin: "3",
			fclEwSec: "15.12",
			fclEwCode: "E",
			fclWaterDepth: "374",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279225",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279225",
			fclNpdidFacility: "279225",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NORNE C",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "NORNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43778",
			fclStartupDate: "14.11.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "66",
			fclNsMin: "0",
			fclNsSec: "51.89",
			fclNsCode: "N",
			fclEwDeg: "8",
			fclEwMin: "3",
			fclEwSec: "21.72",
			fclEwCode: "E",
			fclWaterDepth: "375",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279253",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279253",
			fclNpdidFacility: "279253",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NORNE D",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "NORNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43778",
			fclStartupDate: "05.11.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "66",
			fclNsMin: "0",
			fclNsSec: "49.39",
			fclNsCode: "N",
			fclEwDeg: "8",
			fclEwMin: "3",
			fclEwSec: "28.32",
			fclEwCode: "E",
			fclWaterDepth: "378",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279281",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279281",
			fclNpdidFacility: "279281",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NORNE E",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "NORNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43778",
			fclStartupDate: "05.11.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "66",
			fclNsMin: "2",
			fclNsSec: "43.70",
			fclNsCode: "N",
			fclEwDeg: "8",
			fclEwMin: "5",
			fclEwSec: "53.65",
			fclEwCode: "E",
			fclWaterDepth: "379",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279309",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279309",
			fclNpdidFacility: "279309",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NORNE ERB",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "NORNE GASSTRANSPORT",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "1035984",
			fclStartupDate: "01.10.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "66",
			fclNsMin: "1",
			fclNsSec: "38.30",
			fclNsCode: "N",
			fclEwDeg: "8",
			fclEwMin: "5",
			fclEwSec: "18.20",
			fclEwCode: "E",
			fclWaterDepth: "378",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282221",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282221",
			fclNpdidFacility: "282221",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NORNE F",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "NORNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43778",
			fclStartupDate: "02.07.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "66",
			fclNsMin: "2",
			fclNsSec: "41.09",
			fclNsCode: "N",
			fclEwDeg: "8",
			fclEwMin: "6",
			fclEwSec: "0.26",
			fclEwCode: "E",
			fclWaterDepth: "380",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279337",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279337",
			fclNpdidFacility: "279337",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NORNE FPSO",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "FPSO",
			fclBelongsToName: "NORNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43778",
			fclStartupDate: "05.11.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "66",
			fclNsMin: "1",
			fclNsSec: "38.25",
			fclNsCode: "N",
			fclEwDeg: "8",
			fclEwMin: "5",
			fclEwSec: "18.09",
			fclEwCode: "E",
			fclWaterDepth: "378",
			fclFunctions: "OFFLOADING - PROCESSING - QUARTER - STORAGE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279589",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279589",
			fclNpdidFacility: "279589",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NORNE G",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "URD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2834734",
			fclStartupDate: "08.11.2005",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "66",
			fclNsMin: "4",
			fclNsSec: "41.97",
			fclNsCode: "N",
			fclEwDeg: "8",
			fclEwMin: "14",
			fclEwSec: "55.75",
			fclEwCode: "E",
			fclWaterDepth: "390",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=364789",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=364789",
			fclNpdidFacility: "364789",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NORNE H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "URD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2834734",
			fclStartupDate: "08.11.2005",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "66",
			fclNsMin: "4",
			fclNsSec: "39.46",
			fclNsCode: "N",
			fclEwDeg: "8",
			fclEwMin: "15",
			fclEwSec: "2.98",
			fclEwCode: "E",
			fclWaterDepth: "390",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=364827",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=364827",
			fclNpdidFacility: "364827",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NORNE J",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "URD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2834734",
			fclStartupDate: "08.11.2005",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "66",
			fclNsMin: "2",
			fclNsSec: "56.84",
			fclNsCode: "N",
			fclEwDeg: "8",
			fclEwMin: "10",
			fclEwSec: "16.88",
			fclEwCode: "E",
			fclWaterDepth: "390",
			fclFunctions: "OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=364750",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=364750",
			fclNpdidFacility: "364750",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NORNE K",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "NORNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43778",
			fclStartupDate: "18.10.2006",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "66",
			fclNsMin: "0",
			fclNsSec: "49.00",
			fclNsCode: "N",
			fclEwDeg: "8",
			fclEwMin: "3",
			fclEwSec: "14.00",
			fclEwCode: "E",
			fclWaterDepth: "376",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=372501",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=372501",
			fclNpdidFacility: "372501",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NORNE M",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "NORNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43778",
			fclStartupDate: "01.05.2010",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "66",
			fclNsMin: "0",
			fclNsSec: "17.74",
			fclNsCode: "N",
			fclEwDeg: "8",
			fclEwMin: "1",
			fclEwSec: "55.52",
			fclEwCode: "E",
			fclWaterDepth: "375",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=409423",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=409423",
			fclNpdidFacility: "409423",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NORNE/HEIDRUN T",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "ÅSGARD TRANSPORT",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124211",
			fclStartupDate: "05.02.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "5",
			fclNsSec: "4.26",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "48",
			fclEwSec: "5.26",
			fclEwCode: "E",
			fclWaterDepth: "311",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287261",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287261",
			fclNpdidFacility: "287261",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NORPIPE Y",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "NORPIPE GASSLEDNING",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124165",
			fclStartupDate: "18.08.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "29",
			fclNsSec: "25.33",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "19",
			fclEwSec: "49.81",
			fclEwCode: "E",
			fclWaterDepth: "69",
			fclFunctions: "Y-CONNECTION",
			fclDesignLifetime: "33",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287065",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287065",
			fclNpdidFacility: "287065",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NOVA W",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Wintershall Dea Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "NOVA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "33197696",
			fclStartupDate: "01.08.2022",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "17",
			fclNsSec: "7.10",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "39",
			fclEwSec: "57.69",
			fclEwCode: "E",
			fclWaterDepth: "369",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=506250",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=506250",
			fclNpdidFacility: "506250",
			fclDateUpdated: "02.08.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NOVA X",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Wintershall Dea Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "NOVA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "33197696",
			fclStartupDate: "01.08.2022",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "16",
			fclNsSec: "34.68",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "39",
			fclEwSec: "56.24",
			fclEwCode: "E",
			fclWaterDepth: "368",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=506143",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=506143",
			fclNpdidFacility: "506143",
			fclDateUpdated: "02.08.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NYBRO",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "55",
			fclNsMin: "36",
			fclNsSec: "21.32",
			fclNsCode: "N",
			fclEwDeg: "8",
			fclEwMin: "12",
			fclEwSec: "48.37",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=416937",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=416937",
			fclNpdidFacility: "416937",
			fclDateUpdated: "02.10.2019",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NYBRO TEE",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "GASSLED",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "2252643",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "55",
			fclNsMin: "48",
			fclNsSec: "44.28",
			fclNsCode: "N",
			fclEwDeg: "48",
			fclEwMin: "30",
			fclEwSec: "37.68",
			fclEwCode: "E",
			fclWaterDepth: "40",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=739655",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=739655",
			fclNpdidFacility: "739655",
			fclDateUpdated: "07.01.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NYHAMNA",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "NYHAMNA LANDANLEGG",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "2455217",
			fclStartupDate: "13.09.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "62",
			fclNsMin: "50",
			fclNsSec: "52.06",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "57",
			fclEwSec: "13.32",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=364693",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=364693",
			fclNpdidFacility: "364693",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "NYHAMNA RESERVEKRAFTVERK",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "62",
			fclNsMin: "50",
			fclNsSec: "52.06",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "57",
			fclEwSec: "13.32",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=441041",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=441041",
			fclNpdidFacility: "441041",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "OBERKAPPEL",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "48",
			fclNsMin: "34",
			fclNsSec: "59.19",
			fclNsCode: "N",
			fclEwDeg: "13",
			fclEwMin: "46",
			fclEwSec: "10.96",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=416994",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=416994",
			fclNpdidFacility: "416994",
			fclDateUpdated: "02.10.2019",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ODA",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Sval Energi AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ODA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "29412516",
			fclStartupDate: "16.03.2019",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "4",
			fclNsSec: "23.14",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "3",
			fclEwSec: "55.07",
			fclEwCode: "E",
			fclWaterDepth: "66",
			fclFunctions: "OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=450877",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=450877",
			fclNpdidFacility: "450877",
			fclDateUpdated: "03.06.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ODIN",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "ODIN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43610",
			fclStartupDate: "01.04.1984",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "4",
			fclNsSec: "36.96",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "9",
			fclEwSec: "56.70",
			fclEwCode: "E",
			fclWaterDepth: "103",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271805",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271805",
			fclNpdidFacility: "271805",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ORMEN LANGE A",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "A/S Norske Shell",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ORMEN LANGE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2762452",
			fclStartupDate: "13.09.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "63",
			fclNsMin: "29",
			fclNsSec: "48.33",
			fclNsCode: "N",
			fclEwDeg: "5",
			fclEwMin: "23",
			fclEwSec: "11.47",
			fclEwCode: "E",
			fclWaterDepth: "850",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=371178",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=371178",
			fclNpdidFacility: "371178",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ORMEN LANGE B",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "A/S Norske Shell",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ORMEN LANGE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2762452",
			fclStartupDate: "13.09.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "63",
			fclNsMin: "31",
			fclNsSec: "42.13",
			fclNsCode: "N",
			fclEwDeg: "5",
			fclEwMin: "23",
			fclEwSec: "16.42",
			fclEwCode: "E",
			fclWaterDepth: "850",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=371216",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=371216",
			fclNpdidFacility: "371216",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ORMEN LANGE C",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "A/S Norske Shell",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ORMEN LANGE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2762452",
			fclStartupDate: "15.11.2012",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "63",
			fclNsMin: "34",
			fclNsSec: "21.99",
			fclNsCode: "N",
			fclEwDeg: "5",
			fclEwMin: "20",
			fclEwSec: "54.31",
			fclEwCode: "E",
			fclWaterDepth: "925",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=415218",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=415218",
			fclNpdidFacility: "415218",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ORMEN LANGE D",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "A/S Norske Shell",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ORMEN LANGE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2762452",
			fclStartupDate: "12.07.2010",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "63",
			fclNsMin: "23",
			fclNsSec: "10.82",
			fclNsCode: "N",
			fclEwDeg: "5",
			fclEwMin: "18",
			fclEwSec: "49.51",
			fclEwCode: "E",
			fclWaterDepth: "854",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=406437",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=406437",
			fclNpdidFacility: "406437",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "OSEBERG A",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "CONDEEP 4 SHAFTS",
			fclBelongsToName: "OSEBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43625",
			fclStartupDate: "01.12.1988",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "29",
			fclNsSec: "30.71",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "49",
			fclEwSec: "38.33",
			fclEwCode: "E",
			fclWaterDepth: "109",
			fclFunctions: "DRILLING - PROCESSING - QUARTER",
			fclDesignLifetime: "40",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271245",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271245",
			fclNpdidFacility: "271245",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "OSEBERG B",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "OSEBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43625",
			fclStartupDate: "01.12.1988",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "29",
			fclNsSec: "36.02",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "49",
			fclEwSec: "41.71",
			fclEwCode: "E",
			fclWaterDepth: "108",
			fclFunctions: "DRILLING",
			fclDesignLifetime: "40",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272897",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272897",
			fclNpdidFacility: "272897",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "OSEBERG C",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "OSEBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43625",
			fclStartupDate: "02.09.1991",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "36",
			fclNsSec: "30.02",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "46",
			fclEwSec: "32.15",
			fclEwCode: "E",
			fclWaterDepth: "108",
			fclFunctions: "DRILLING - PROCESSING - QUARTER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272225",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272225",
			fclNpdidFacility: "272225",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "OSEBERG D",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "OSEBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43625",
			fclStartupDate: "01.10.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "29",
			fclNsSec: "26.40",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "49",
			fclEwSec: "44.35",
			fclEwCode: "E",
			fclWaterDepth: "109",
			fclFunctions: "PROCESSING",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280541",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280541",
			fclNpdidFacility: "280541",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "OSEBERG DELTA",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "OSEBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43625",
			fclStartupDate: "27.06.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "28",
			fclNsSec: "5.23",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "40",
			fclEwSec: "53.74",
			fclEwCode: "E",
			fclWaterDepth: "101",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=374139",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=374139",
			fclNpdidFacility: "374139",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "OSEBERG DELTA O",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "OSEBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43625",
			fclStartupDate: "21.02.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "24",
			fclNsSec: "0.57",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "40",
			fclEwSec: "16.21",
			fclEwCode: "E",
			fclWaterDepth: "103",
			fclFunctions: "GAS INJECTOR - OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=435436",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=435436",
			fclNpdidFacility: "435436",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "OSEBERG DELTA P",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "OSEBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43625",
			fclStartupDate: "21.02.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "27",
			fclNsSec: "25.00",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "41",
			fclEwSec: "42.36",
			fclEwCode: "E",
			fclWaterDepth: "104",
			fclFunctions: "GAS INJECTOR - OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=435377",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=435377",
			fclNpdidFacility: "435377",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "OSEBERG H",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "OSEBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43625",
			fclStartupDate: "14.10.2018",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "32",
			fclNsSec: "54.58",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "44",
			fclEwSec: "0.12",
			fclEwCode: "E",
			fclWaterDepth: "107",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER - WELLHEAD",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=454141",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=454141",
			fclNpdidFacility: "454141",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "OSEBERG SØR",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 6 LEGS",
			fclBelongsToName: "OSEBERG SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43645",
			fclStartupDate: "30.08.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "23",
			fclNsSec: "24.62",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "47",
			fclEwSec: "49.06",
			fclEwCode: "E",
			fclWaterDepth: "101",
			fclFunctions: "DRILLING - PROCESSING - QUARTER",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280205",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280205",
			fclNpdidFacility: "280205",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "OSEBERG SØR J",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "OSEBERG SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43645",
			fclStartupDate: "15.06.2005",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "21",
			fclNsSec: "16.16",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "54",
			fclEwSec: "0.28",
			fclEwCode: "E",
			fclWaterDepth: "101",
			fclFunctions: "OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281745",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281745",
			fclNpdidFacility: "281745",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "OSEBERG SØR K",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "OSEBERG SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43645",
			fclStartupDate: "30.08.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "15",
			fclNsSec: "52.22",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "46",
			fclEwSec: "34.21",
			fclEwCode: "E",
			fclWaterDepth: "106",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279925",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279925",
			fclNpdidFacility: "279925",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "OSEBERG VESTFLANKE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "OSEBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43625",
			fclStartupDate: "09.02.2006",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "32",
			fclNsSec: "15.46",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "41",
			fclEwSec: "8.18",
			fclEwCode: "E",
			fclWaterDepth: "108",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=363907",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=363907",
			fclNpdidFacility: "363907",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "OSEBERG ØST",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "OSEBERG ØST",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43639",
			fclStartupDate: "03.05.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "42",
			fclNsSec: "1.79",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "56",
			fclEwSec: "6.79",
			fclEwCode: "E",
			fclWaterDepth: "157",
			fclFunctions: "DRILLING - PROCESSING - QUARTER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277489",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277489",
			fclNpdidFacility: "277489",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "OSEBERG-Y",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "OSEBERG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43625",
			fclStartupDate: "01.09.1991",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "34",
			fclNsSec: "51.74",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "47",
			fclEwSec: "57.36",
			fclEwCode: "E",
			fclWaterDepth: "110",
			fclFunctions: "Y-CONNECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286869",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286869",
			fclNpdidFacility: "286869",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "OSELVAR",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "OSELVAR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "5506919",
			fclStartupDate: "14.04.2012",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "55",
			fclNsSec: "59.00",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "40",
			fclEwSec: "16.00",
			fclEwCode: "E",
			fclWaterDepth: "72",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=410592",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=410592",
			fclNpdidFacility: "410592",
			fclDateUpdated: "15.01.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "PETROJARL 1",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "FPSO",
			fclBelongsToName: "GLITNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1272071",
			fclStartupDate: "29.08.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "42",
			fclNsSec: "47.90",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "40",
			fclEwSec: "5.29",
			fclEwCode: "E",
			fclWaterDepth: "110",
			fclFunctions: "OFFLOADING - PROCESSING - QUARTER - STORAGE",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280513",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280513",
			fclNpdidFacility: "280513",
			fclDateUpdated: "19.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "PETROJARL KNARR ",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "A/S Norske Shell",
			fclKind: "FPSO",
			fclBelongsToName: "KNARR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "20460988",
			fclStartupDate: "16.03.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "46",
			fclNsSec: "53.45",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "50",
			fclEwSec: "7.74",
			fclEwCode: "E",
			fclWaterDepth: "410",
			fclFunctions:
				"GAS EXPORT - GAS PRODUCER - OFFLOADING - OIL PRODUCER - PROCESSING - QUARTER - STORAGE",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=427058",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=427058",
			fclNpdidFacility: "427058",
			fclDateUpdated: "02.07.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "PETROJARL VARG",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Chrysaor Norge AS",
			fclKind: "FPSO",
			fclBelongsToName: "VARG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43451",
			fclStartupDate: "22.12.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "4",
			fclNsSec: "40.37",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "53",
			fclEwSec: "25.65",
			fclEwCode: "E",
			fclWaterDepth: "84",
			fclFunctions: "OFFLOADING - PROCESSING - QUARTER - STORAGE",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279869",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279869",
			fclNpdidFacility: "279869",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "RINGHORNE",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Vår Energi ASA",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "BALDER",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43562",
			fclStartupDate: "10.02.2003",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "15",
			fclNsSec: "57.81",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "26",
			fclEwSec: "59.49",
			fclEwCode: "E",
			fclWaterDepth: "129",
			fclFunctions: "DRILLING - PROCESSING - QUARTER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280849",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280849",
			fclNpdidFacility: "280849",
			fclDateUpdated: "17.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ROGASS",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "16",
			fclNsSec: "24.40",
			fclNsCode: "N",
			fclEwDeg: "5",
			fclEwMin: "31",
			fclEwSec: "16.89",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "EXPORT POINT",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=417051",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=417051",
			fclNpdidFacility: "417051",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ROGN SØR MANIFOLD",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "OKEA ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "DRAUGEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43758",
			fclStartupDate: "01.12.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "15",
			fclNsSec: "47.39",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "43",
			fclEwSec: "46.56",
			fclEwCode: "E",
			fclWaterDepth: "293",
			fclFunctions: "MANIFOLD STATION ",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=434044",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=434044",
			fclNpdidFacility: "434044",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SAGE-T",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "08.06.2009",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "28",
			fclNsSec: "42.56",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "23",
			fclEwSec: "24.14",
			fclEwCode: "E",
			fclWaterDepth: "120",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=375390",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=375390",
			fclNpdidFacility: "375390",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SIGYN",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SIGYN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1630100",
			fclStartupDate: "22.12.2002",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "17",
			fclNsSec: "5.97",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "2",
			fclEwSec: "37.54",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282277",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282277",
			fclNpdidFacility: "282277",
			fclDateUpdated: "19.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SKARV A",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SKARV",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4704482",
			fclStartupDate: "31.12.2012",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "44",
			fclNsSec: "19.23",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "39",
			fclEwSec: "21.17",
			fclEwCode: "E",
			fclWaterDepth: "324",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=408458",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=408458",
			fclNpdidFacility: "408458",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SKARV B/C VEST",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SKARV",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4704482",
			fclStartupDate: "31.12.2012",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "42",
			fclNsSec: "14.08",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "35",
			fclEwSec: "20.69",
			fclEwCode: "E",
			fclWaterDepth: "375",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=408576",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=408576",
			fclNpdidFacility: "408576",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SKARV B/C ØST",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SKARV",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4704482",
			fclStartupDate: "31.12.2012",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "42",
			fclNsSec: "15.73",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "35",
			fclEwSec: "28.64",
			fclEwCode: "E",
			fclWaterDepth: "375",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=408635",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=408635",
			fclNpdidFacility: "408635",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SKARV ERB",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "SKARV",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4704482",
			fclStartupDate: "10.11.2012",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "41",
			fclNsSec: "39.10",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "39",
			fclEwSec: "16.03",
			fclEwCode: "E",
			fclWaterDepth: "369",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=406335",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=406335",
			fclNpdidFacility: "406335",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SKARV FPSO",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "FPSO",
			fclBelongsToName: "SKARV",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4704482",
			fclStartupDate: "31.12.2012",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "41",
			fclNsSec: "52.00",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "39",
			fclEwSec: "3.91",
			fclEwCode: "E",
			fclWaterDepth: "368",
			fclFunctions: "OFFLOADING - PROCESSING - QUARTER - STORAGE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=414071",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=414071",
			fclNpdidFacility: "414071",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SKINFAKS N",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "GULLFAKS SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43699",
			fclStartupDate: "27.01.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "4",
			fclNsSec: "44.38",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "59",
			fclEwSec: "23.47",
			fclEwCode: "E",
			fclWaterDepth: "140",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=372538",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=372538",
			fclNpdidFacility: "372538",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SKIRNE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "TotalEnergies EP Norge AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "SKIRNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2138816",
			fclStartupDate: "03.03.2004",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "35",
			fclNsSec: "3.56",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "38",
			fclEwSec: "27.97",
			fclEwCode: "E",
			fclWaterDepth: "118",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "10",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=359717",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=359717",
			fclNpdidFacility: "359717",
			fclDateUpdated: "09.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SKOGUL PLEM",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "SKOGUL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "31164600",
			fclStartupDate: "01.03.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "39",
			fclNsSec: "26.21",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "16",
			fclEwSec: "43.17",
			fclEwCode: "E",
			fclWaterDepth: "120",
			fclFunctions: "PIPELINE END MANIFOLD",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=507332",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=507332",
			fclNpdidFacility: "507332",
			fclDateUpdated: "29.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SLAGENTANGEN",
			fclPhase: "SHUT DOWN",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "19",
			fclNsSec: "6.44",
			fclNsCode: "N",
			fclEwDeg: "10",
			fclEwMin: "31",
			fclEwSec: "51.55",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=363404",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=363404",
			fclNpdidFacility: "363404",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SLEIPNER A",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "CONDEEP 4 SHAFTS",
			fclBelongsToName: "SLEIPNER ØST",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43478",
			fclStartupDate: "01.10.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "22",
			fclNsSec: "2.33",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "54",
			fclEwSec: "31.01",
			fclEwCode: "E",
			fclWaterDepth: "83",
			fclFunctions: "DRILLING - PROCESSING - QUARTER",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272365",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272365",
			fclNpdidFacility: "272365",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SLEIPNER B",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "SLEIPNER VEST",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43457",
			fclStartupDate: "01.04.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "25",
			fclNsSec: "4.45",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "43",
			fclEwSec: "4.43",
			fclEwCode: "E",
			fclWaterDepth: "108",
			fclFunctions: "WELLHEAD",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277601",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277601",
			fclNpdidFacility: "277601",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SLEIPNER C",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SLEIPNER ØST",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43478",
			fclStartupDate: "11.09.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "26",
			fclNsSec: "8.86",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "55",
			fclEwSec: "46.83",
			fclEwCode: "E",
			fclWaterDepth: "85",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277545",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277545",
			fclNpdidFacility: "277545",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SLEIPNER D",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SLEIPNER ØST",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43478",
			fclStartupDate: "24.08.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "23",
			fclNsSec: "49.93",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "55",
			fclEwSec: "1.95",
			fclEwCode: "E",
			fclWaterDepth: "84",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277881",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277881",
			fclNpdidFacility: "277881",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SLEIPNER FL",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "SLEIPNER ØST",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43478",
			fclStartupDate: "01.09.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "22",
			fclNsSec: "9.85",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "54",
			fclEwSec: "43.83",
			fclEwCode: "E",
			fclWaterDepth: "83",
			fclFunctions: "FLARE STACK",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282305",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282305",
			fclNpdidFacility: "282305",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SLEIPNER R",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "SLEIPNER ØST",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43478",
			fclStartupDate: "01.10.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "22",
			fclNsSec: "6.83",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "54",
			fclEwSec: "37.91",
			fclEwCode: "E",
			fclWaterDepth: "83",
			fclFunctions: "RISER",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277797",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277797",
			fclNpdidFacility: "277797",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SLEIPNER T",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 6 LEGS",
			fclBelongsToName: "SLEIPNER VEST",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43457",
			fclStartupDate: "01.04.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "22",
			fclNsSec: "6.98",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "54",
			fclEwSec: "23.33",
			fclEwCode: "E",
			fclWaterDepth: "83",
			fclFunctions: "PROCESSING",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277629",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277629",
			fclNpdidFacility: "277629",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SMØRBUKK NORD",
			fclPhase: "FUTURE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "01.11.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "15",
			fclNsSec: "5.03",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "54",
			fclEwSec: "15.61",
			fclEwCode: "E",
			fclWaterDepth: "300",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=453826",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=453826",
			fclNpdidFacility: "453826",
			fclDateUpdated: "19.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNORRE A",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "TLP STEEL",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "03.08.1992",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "26",
			fclNsSec: "57.61",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "8",
			fclEwSec: "39.64",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "DRILLING - PROCESSING - QUARTER",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272085",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272085",
			fclNpdidFacility: "272085",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNORRE B",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SEMISUB STEEL",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "27.06.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "31",
			fclNsSec: "31.58",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "12",
			fclEwSec: "41.42",
			fclEwCode: "E",
			fclWaterDepth: "350",
			fclFunctions: "DRILLING - PROCESSING - QUARTER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280485",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280485",
			fclNpdidFacility: "280485",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNORRE B SBV",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "27.06.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "31",
			fclNsSec: "22.26",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "12",
			fclEwSec: "45.27",
			fclEwCode: "E",
			fclWaterDepth: "350",
			fclFunctions: "ISOLATION VALVE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287569",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287569",
			fclNpdidFacility: "287569",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNORRE MDU",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "27.06.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "31",
			fclNsSec: "32.00",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "12",
			fclEwSec: "43.00",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "DISTRIBUTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282249",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282249",
			fclNpdidFacility: "282249",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNORRE SDU-1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "27.06.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "31",
			fclNsSec: "33.00",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "12",
			fclEwSec: "44.00",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "DISTRIBUTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285357",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285357",
			fclNpdidFacility: "285357",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNORRE SDU-2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "27.06.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "31",
			fclNsSec: "33.40",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "12",
			fclEwSec: "41.83",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "DISTRIBUTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=285413",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=285413",
			fclNpdidFacility: "285413",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNORRE SEP M",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "12.12.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "32",
			fclNsSec: "11.86",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "14",
			fclEwSec: "54.63",
			fclEwCode: "E",
			fclWaterDepth: "362",
			fclFunctions: "OIL PRODUCER - WATER/GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=452213",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=452213",
			fclNpdidFacility: "452213",
			fclDateUpdated: "19.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNORRE SEP N",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "12.12.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "33",
			fclNsSec: "16.99",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "15",
			fclEwSec: "25.64",
			fclEwCode: "E",
			fclWaterDepth: "377",
			fclFunctions: "OIL PRODUCER - WATER/GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=452269",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=452269",
			fclNpdidFacility: "452269",
			fclDateUpdated: "19.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNORRE SEP V",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "12.12.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "29",
			fclNsSec: "45.99",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "8",
			fclEwSec: "54.43",
			fclEwCode: "E",
			fclWaterDepth: "327",
			fclFunctions: "OIL PRODUCER - WATER/GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=452436",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=452436",
			fclNpdidFacility: "452436",
			fclDateUpdated: "19.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNORRE SEP W",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "12.12.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "30",
			fclNsSec: "50.82",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "7",
			fclEwSec: "22.81",
			fclEwCode: "E",
			fclWaterDepth: "329",
			fclFunctions: "OIL PRODUCER - WATER/GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=452492",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=452492",
			fclNpdidFacility: "452492",
			fclDateUpdated: "19.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNORRE SEP X",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "12.12.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "28",
			fclNsSec: "54.70",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "12",
			fclEwSec: "22.85",
			fclEwCode: "E",
			fclWaterDepth: "329",
			fclFunctions: "OIL PRODUCER - WATER/GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=452548",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=452548",
			fclNpdidFacility: "452548",
			fclDateUpdated: "19.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNORRE SEP Z",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "12.12.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "29",
			fclNsSec: "50.65",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "14",
			fclEwSec: "0.89",
			fclEwCode: "E",
			fclWaterDepth: "338",
			fclFunctions: "OIL PRODUCER - WATER/GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=452380",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=452380",
			fclNpdidFacility: "452380",
			fclDateUpdated: "19.02.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNORRE UPA",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNORRE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43718",
			fclStartupDate: "03.08.1992",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "29",
			fclNsSec: "21.58",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "13",
			fclEwSec: "34.84",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=273961",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=273961",
			fclNpdidFacility: "273961",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNURREVARDEN",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "17",
			fclNsSec: "34.41",
			fclNsCode: "N",
			fclEwDeg: "5",
			fclEwMin: "29",
			fclEwSec: "45.17",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=417108",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=417108",
			fclNpdidFacility: "417108",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNØHVIT CDU-1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "SNØHVIT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2053062",
			fclStartupDate: "21.08.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "34",
			fclNsSec: "9.48",
			fclNsCode: "N",
			fclEwDeg: "21",
			fclEwMin: "13",
			fclEwSec: "39.12",
			fclEwCode: "E",
			fclWaterDepth: "325",
			fclFunctions: "DISTRIBUTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=370616",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=370616",
			fclNpdidFacility: "370616",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNØHVIT D",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNØHVIT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2053062",
			fclStartupDate: "21.08.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "35",
			fclNsSec: "31.80",
			fclNsCode: "N",
			fclEwDeg: "21",
			fclEwMin: "16",
			fclEwSec: "22.80",
			fclEwCode: "E",
			fclWaterDepth: "337",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=364278",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=364278",
			fclNpdidFacility: "364278",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNØHVIT E",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNØHVIT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2053062",
			fclStartupDate: "21.08.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "35",
			fclNsSec: "40.30",
			fclNsCode: "N",
			fclEwDeg: "21",
			fclEwMin: "11",
			fclEwSec: "0.71",
			fclEwCode: "E",
			fclWaterDepth: "336",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=364315",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=364315",
			fclNpdidFacility: "364315",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNØHVIT F",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNØHVIT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2053062",
			fclStartupDate: "22.04.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "36",
			fclNsSec: "39.70",
			fclNsCode: "N",
			fclEwDeg: "21",
			fclEwMin: "3",
			fclEwSec: "28.94",
			fclEwCode: "E",
			fclWaterDepth: "318",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=364352",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=364352",
			fclNpdidFacility: "364352",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNØHVIT G",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNØHVIT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2053062",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "39",
			fclNsSec: "25.35",
			fclNsCode: "N",
			fclEwDeg: "21",
			fclEwMin: "4",
			fclEwSec: "9.04",
			fclEwCode: "E",
			fclWaterDepth: "316",
			fclFunctions: "GAS INJECTOR - GAS PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=442087",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=442087",
			fclNpdidFacility: "442087",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNØHVIT N",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SNØHVIT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2053062",
			fclStartupDate: "21.08.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "29",
			fclNsSec: "18.70",
			fclNsCode: "N",
			fclEwDeg: "21",
			fclEwMin: "5",
			fclEwSec: "5.50",
			fclEwCode: "E",
			fclWaterDepth: "325",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=364390",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=364390",
			fclNpdidFacility: "364390",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SNØHVIT PLEM-1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "SNØHVIT",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2053062",
			fclStartupDate: "21.08.2007",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "71",
			fclNsMin: "34",
			fclNsSec: "16.81",
			fclNsCode: "N",
			fclEwDeg: "21",
			fclEwMin: "13",
			fclEwSec: "35.53",
			fclEwCode: "E",
			fclWaterDepth: "325",
			fclFunctions: "PIPELINE END MANIFOLD",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=370654",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=370654",
			fclNpdidFacility: "370654",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ST.FERGUS",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "",
			fclStartupDate: "20.08.1978",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "33",
			fclNsSec: "11.99",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "51",
			fclEwSec: "0.00",
			fclEwCode: "W",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286225",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286225",
			fclNpdidFacility: "286225",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STATFJ-NORD SSIV",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "STATFJORD NORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43679",
			fclStartupDate: "26.08.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "17",
			fclNsSec: "48.51",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "54",
			fclEwSec: "32.35",
			fclEwCode: "E",
			fclWaterDepth: "145",
			fclFunctions: "ISOLATION VALVE",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287205",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287205",
			fclNpdidFacility: "287205",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STATFJORD A",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "CONDEEP 3 SHAFTS",
			fclBelongsToName: "STATFJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43658",
			fclStartupDate: "24.11.1979",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "15",
			fclNsSec: "20.46",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "51",
			fclEwSec: "13.95",
			fclEwCode: "E",
			fclWaterDepth: "145",
			fclFunctions: "DRILLING - PROCESSING - QUARTER - STORAGE",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271273",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271273",
			fclNpdidFacility: "271273",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STATFJORD A-OLS",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "LOADING SYSTEM",
			fclBelongsToName: "STATFJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43658",
			fclStartupDate: "07.05.1987",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "15",
			fclNsSec: "48.64",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "53",
			fclEwSec: "14.03",
			fclEwCode: "E",
			fclWaterDepth: "145",
			fclFunctions: "OFFLOADING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277741",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277741",
			fclNpdidFacility: "277741",
			fclDateUpdated: "03.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STATFJORD B",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "CONDEEP 4 SHAFTS",
			fclBelongsToName: "STATFJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43658",
			fclStartupDate: "05.11.1982",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "12",
			fclNsSec: "24.88",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "49",
			fclEwSec: "50.29",
			fclEwCode: "E",
			fclWaterDepth: "145",
			fclFunctions: "DRILLING - PROCESSING - QUARTER - STORAGE",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271301",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271301",
			fclNpdidFacility: "271301",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STATFJORD B-OLS",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "LOADING SYSTEM",
			fclBelongsToName: "STATFJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43658",
			fclStartupDate: "01.08.1990",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "13",
			fclNsSec: "27.90",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "50",
			fclEwSec: "19.12",
			fclEwCode: "E",
			fclWaterDepth: "146",
			fclFunctions: "OFFLOADING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272589",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272589",
			fclNpdidFacility: "272589",
			fclDateUpdated: "03.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STATFJORD C",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "CONDEEP 4 SHAFTS",
			fclBelongsToName: "STATFJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43658",
			fclStartupDate: "26.06.1985",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "17",
			fclNsSec: "47.70",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "54",
			fclEwSec: "9.17",
			fclEwCode: "E",
			fclWaterDepth: "145",
			fclFunctions: "DRILLING - PROCESSING - QUARTER - STORAGE",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271329",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271329",
			fclNpdidFacility: "271329",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STATFJORD C-SPM",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "LOADING SYSTEM",
			fclBelongsToName: "STATFJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43658",
			fclStartupDate: "01.07.1984",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "16",
			fclNsSec: "51.80",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "53",
			fclEwSec: "1.80",
			fclEwCode: "E",
			fclWaterDepth: "145",
			fclFunctions: "OFFLOADING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272617",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272617",
			fclNpdidFacility: "272617",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STATFJORD G",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "STATFJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43658",
			fclStartupDate: "26.08.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "21",
			fclNsSec: "56.57",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "56",
			fclEwSec: "5.94",
			fclEwCode: "E",
			fclWaterDepth: "157",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279001",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279001",
			fclNpdidFacility: "279001",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STATFJORD H",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "STATFJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43658",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "21",
			fclNsSec: "53.75",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "55",
			fclEwSec: "59.97",
			fclEwCode: "E",
			fclWaterDepth: "157",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282333",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282333",
			fclNpdidFacility: "282333",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STATFJORD NORD D",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "STATFJORD NORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43679",
			fclStartupDate: "16.06.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "26",
			fclNsSec: "50.50",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "54",
			fclEwSec: "51.00",
			fclEwCode: "E",
			fclWaterDepth: "272",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278441",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278441",
			fclNpdidFacility: "278441",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STATFJORD NORD E",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "STATFJORD NORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43679",
			fclStartupDate: "23.01.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "26",
			fclNsSec: "3.30",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "55",
			fclEwSec: "30.40",
			fclEwCode: "E",
			fclWaterDepth: "265",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278469",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278469",
			fclNpdidFacility: "278469",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STATFJORD NORD F",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "STATFJORD NORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43679",
			fclStartupDate: "15.04.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "26",
			fclNsSec: "41.00",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "57",
			fclEwSec: "22.80",
			fclEwCode: "E",
			fclWaterDepth: "285",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278497",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278497",
			fclNpdidFacility: "278497",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STATFJORD ØST K",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "STATFJORD ØST",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43672",
			fclStartupDate: "29.01.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "20",
			fclNsSec: "38.20",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "58",
			fclEwSec: "22.00",
			fclEwCode: "E",
			fclWaterDepth: "156",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277909",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277909",
			fclNpdidFacility: "277909",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STATFJORD ØST L",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "STATFJORD ØST",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43672",
			fclStartupDate: "04.10.1994",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "19",
			fclNsSec: "45.85",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "59",
			fclEwSec: "11.48",
			fclEwCode: "E",
			fclWaterDepth: "153",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277993",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277993",
			fclNpdidFacility: "277993",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STATFJORD ØST M",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "STATFJORD ØST",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43672",
			fclStartupDate: "23.09.1994",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "20",
			fclNsSec: "28.52",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "59",
			fclEwSec: "59.63",
			fclEwCode: "E",
			fclWaterDepth: "195",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278021",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278021",
			fclNpdidFacility: "278021",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STATFJORD-A-ALP",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "LOADING SYSTEM",
			fclBelongsToName: "STATFJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43658",
			fclStartupDate: "24.11.1979",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "15",
			fclNsSec: "48.61",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "53",
			fclEwSec: "14.02",
			fclEwCode: "E",
			fclWaterDepth: "145",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286561",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286561",
			fclNpdidFacility: "286561",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STATFJORD-B-SPM",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "LOADING SYSTEM",
			fclBelongsToName: "STATFJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43658",
			fclStartupDate: "05.11.1982",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "12",
			fclNsSec: "25.00",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "49",
			fclEwSec: "51.00",
			fclEwCode: "E",
			fclWaterDepth: "145",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286589",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286589",
			fclNpdidFacility: "286589",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STAT-SSTC",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "STATPIPE 1",
			fclBelongsToKind: "TUF PART",
			fclBelongsToS: "124002",
			fclStartupDate: "01.10.1985",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "11",
			fclNsSec: "8.99",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "50",
			fclEwSec: "45.37",
			fclEwCode: "E",
			fclWaterDepth: "145",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "40",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286365",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286365",
			fclNpdidFacility: "286365",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STJERNE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "OSEBERG SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43645",
			fclStartupDate: "25.03.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "18",
			fclNsSec: "0.43",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "44",
			fclEwSec: "22.73",
			fclEwCode: "E",
			fclWaterDepth: "103",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=424530",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=424530",
			fclNpdidFacility: "424530",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STT-IN",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "STATPIPE 5",
			fclBelongsToKind: "TUF PART",
			fclBelongsToS: "124054",
			fclStartupDate: "01.03.1990",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "33",
			fclNsSec: "39.15",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "53",
			fclEwSec: "48.73",
			fclEwCode: "E",
			fclWaterDepth: "110",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "40",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286505",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286505",
			fclNpdidFacility: "286505",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "STURE",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "OSEBERG TRANSPORTSYSTEM",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124301",
			fclStartupDate: "22.12.1988",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "37",
			fclNsSec: "16.00",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "51",
			fclEwSec: "4.00",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286253",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286253",
			fclNpdidFacility: "286253",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SULLOM VOE",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "25",
			fclNsSec: "58.26",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "16",
			fclEwSec: "35.99",
			fclEwCode: "W",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=417165",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=417165",
			fclNpdidFacility: "417165",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SVALIN C",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SVALIN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "22507971",
			fclStartupDate: "06.09.2014",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "8",
			fclNsSec: "35.53",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "24",
			fclEwSec: "30.17",
			fclEwCode: "E",
			fclWaterDepth: "125",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=426277",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=426277",
			fclNpdidFacility: "426277",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "SYGNA N",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SYGNA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "104718",
			fclStartupDate: "02.08.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "28",
			fclNsSec: "0.99",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "59",
			fclEwSec: "30.66",
			fclEwCode: "E",
			fclWaterDepth: "299",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279897",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279897",
			fclNpdidFacility: "279897",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TAMBAR",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "TAMBAR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1028599",
			fclStartupDate: "16.07.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "58",
			fclNsSec: "57.92",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "57",
			fclEwSec: "31.61",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "WELLHEAD",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280793",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280793",
			fclNpdidFacility: "280793",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TEESSIDE",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips (U.K.) Limited.",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "NORPIPE OLJE-ANLEGG",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124159",
			fclStartupDate: "14.10.1975",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "54",
			fclNsMin: "37",
			fclNsSec: "33.00",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "17",
			fclEwSec: "44.00",
			fclEwCode: "W",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286113",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286113",
			fclNpdidFacility: "286113",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TEESSIDE SSIV",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "NORPIPE OLJELEDNING",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124133",
			fclStartupDate: "18.08.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "32",
			fclNsSec: "46.60",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "13",
			fclEwSec: "12.79",
			fclEwCode: "E",
			fclWaterDepth: "76",
			fclFunctions: "ISOLATION VALVE",
			fclDesignLifetime: "33",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287149",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287149",
			fclNpdidFacility: "287149",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TILJE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "SKARV",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4704482",
			fclStartupDate: "31.12.2012",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "44",
			fclNsSec: "17.53",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "39",
			fclEwSec: "5.26",
			fclEwCode: "E",
			fclWaterDepth: "324",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=408517",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=408517",
			fclNpdidFacility: "408517",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TJELDBERGODDEN",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "TJELDBERGODDEN",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "3471792",
			fclStartupDate: "11.11.1996",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "63",
			fclNsMin: "23",
			fclNsSec: "40.75",
			fclNsCode: "N",
			fclEwDeg: "8",
			fclEwMin: "39",
			fclEwSec: "17.81",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286309",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286309",
			fclNpdidFacility: "286309",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TOGI",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "22.01.1991",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "34",
			fclNsSec: "27.30",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "39",
			fclEwSec: "27.10",
			fclEwCode: "E",
			fclWaterDepth: "302",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "18",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272981",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272981",
			fclNpdidFacility: "272981",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TOMMELITEN",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TOMMELITEN GAMMA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43444",
			fclStartupDate: "03.10.1988",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "29",
			fclNsSec: "31.66",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "55",
			fclEwSec: "27.09",
			fclEwCode: "E",
			fclWaterDepth: "72",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272869",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272869",
			fclNpdidFacility: "272869",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TOMMELITEN 1/9 AA",
			fclPhase: "FUTURE",
			fclSurface: "N",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TOMMELITEN A",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "40867462",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "25",
			fclNsSec: "14.13",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "54",
			fclEwSec: "8.71",
			fclEwCode: "E",
			fclWaterDepth: "74",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=747480",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=747480",
			fclNpdidFacility: "747480",
			fclDateUpdated: "14.07.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TOMMELITEN 1/9-AB",
			fclPhase: "FUTURE",
			fclSurface: "N",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TOMMELITEN A",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "40867462",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "23",
			fclNsSec: "35.49",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "53",
			fclEwSec: "39.92",
			fclEwCode: "E",
			fclWaterDepth: "74",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=747781",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=747781",
			fclNpdidFacility: "747781",
			fclDateUpdated: "14.07.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TOR",
			fclPhase: "SHUT DOWN",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "TOR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43520",
			fclStartupDate: "01.08.1978",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "38",
			fclNsSec: "31.46",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "19",
			fclEwSec: "37.05",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "DRILLING - PROCESSING - QUARTER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271525",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271525",
			fclNpdidFacility: "271525",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TOR 2/4 YA",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TOR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43520",
			fclStartupDate: "03.12.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "38",
			fclNsSec: "20.95",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "17",
			fclEwSec: "57.93",
			fclEwCode: "E",
			fclWaterDepth: "67",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=714131",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=714131",
			fclNpdidFacility: "714131",
			fclDateUpdated: "11.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TOR 2/4 YB",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TOR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43520",
			fclStartupDate: "03.12.2020",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "38",
			fclNsSec: "26.81",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "18",
			fclEwSec: "27.19",
			fclEwCode: "E",
			fclWaterDepth: "68",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=714429",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=714429",
			fclNpdidFacility: "714429",
			fclDateUpdated: "11.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TOR FL",
			fclPhase: "SHUT DOWN",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET TRIPOD",
			fclBelongsToName: "TOR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43520",
			fclStartupDate: "01.08.1977",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "38",
			fclNsSec: "27.37",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "19",
			fclEwSec: "39.42",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "FLARE STACK",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281213",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281213",
			fclNpdidFacility: "281213",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TOR II Y",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL OLJERØR II",
			fclBelongsToKind: "TUF PART",
			fclBelongsToS: "124194",
			fclStartupDate: "01.10.2004",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "50",
			fclNsSec: "56.25",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "3",
			fclEwSec: "37.00",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "Y-CONNECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287597",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287597",
			fclNpdidFacility: "287597",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TORDIS EXTENSION",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TORDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43725",
			fclStartupDate: "01.07.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "16",
			fclNsSec: "29.54",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "7",
			fclEwSec: "11.66",
			fclEwCode: "E",
			fclWaterDepth: "200",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "17",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282445",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282445",
			fclNpdidFacility: "282445",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TORDIS SSIB",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TORDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43725",
			fclStartupDate: "14.02.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "16",
			fclNsSec: "29.20",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "6",
			fclEwSec: "54.83",
			fclEwCode: "E",
			fclWaterDepth: "210",
			fclFunctions: "BOOSTER - SEPARATION - WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=377386",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=377386",
			fclNpdidFacility: "377386",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TORDIS ØST K",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TORDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43725",
			fclStartupDate: "25.07.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "16",
			fclNsSec: "34.15",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "7",
			fclEwSec: "12.88",
			fclEwCode: "E",
			fclWaterDepth: "200",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280597",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280597",
			fclNpdidFacility: "280597",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TORDIS-MS",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TORDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43725",
			fclStartupDate: "03.06.1994",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "16",
			fclNsSec: "32.28",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "7",
			fclEwSec: "5.21",
			fclEwCode: "E",
			fclWaterDepth: "200",
			fclFunctions: "MANIFOLD STATION ",
			fclDesignLifetime: "12",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282893",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282893",
			fclNpdidFacility: "282893",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TRESTAKK A",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TRESTAKK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "29396445",
			fclStartupDate: "16.07.2019",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "51",
			fclNsSec: "58.08",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "50",
			fclEwSec: "15.43",
			fclEwCode: "E",
			fclWaterDepth: "300",
			fclFunctions: "GAS INJECTION - OIL PRODUCER",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=450531",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=450531",
			fclNpdidFacility: "450531",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TRESTAKK B",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "TRESTAKK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "29396445",
			fclStartupDate: "16.07.2019",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "50",
			fclNsSec: "9.53",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "47",
			fclEwSec: "12.96",
			fclEwCode: "E",
			fclWaterDepth: "297",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=450653",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=450653",
			fclNpdidFacility: "450653",
			fclDateUpdated: "04.12.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL A",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "CONDEEP 4 SHAFTS",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "15.05.1996",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "38",
			fclNsSec: "44.30",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "43",
			fclEwSec: "35.38",
			fclEwCode: "E",
			fclWaterDepth: "302",
			fclFunctions: "DRILLING - PROCESSING - QUARTER",
			fclDesignLifetime: "70",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278329",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278329",
			fclNpdidFacility: "278329",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL B",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SEMISUB CONCRETE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "21.09.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "46",
			fclNsSec: "27.75",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "30",
			fclEwSec: "11.45",
			fclEwCode: "E",
			fclWaterDepth: "320",
			fclFunctions: "PROCESSING - QUARTER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278273",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278273",
			fclNpdidFacility: "278273",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL C",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SEMISUB STEEL",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "53",
			fclNsSec: "10.76",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "36",
			fclEwSec: "41.20",
			fclEwCode: "E",
			fclWaterDepth: "339",
			fclFunctions: "PROCESSING - QUARTER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279421",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279421",
			fclNpdidFacility: "279421",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL D",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "25.09.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "51",
			fclNsSec: "24.22",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "41.29",
			fclEwCode: "E",
			fclWaterDepth: "338",
			fclFunctions: "MANIFOLD STATION ",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278105",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278105",
			fclNpdidFacility: "278105",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL E",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "19.09.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "47",
			fclNsSec: "57.18",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "44.66",
			fclEwCode: "E",
			fclWaterDepth: "331",
			fclFunctions: "MANIFOLD STATION ",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278133",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278133",
			fclNpdidFacility: "278133",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL ERB",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "21.09.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "46",
			fclNsSec: "21.00",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "30",
			fclEwSec: "39.00",
			fclEwCode: "E",
			fclWaterDepth: "324",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281493",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281493",
			fclNpdidFacility: "281493",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL ERS",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "11.04.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "46",
			fclNsSec: "24.60",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "30",
			fclEwSec: "22.84",
			fclEwCode: "E",
			fclWaterDepth: "324",
			fclFunctions: "RISER SUPPORT",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281521",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281521",
			fclNpdidFacility: "281521",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL F",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "23.09.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "46",
			fclNsSec: "34.21",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "17.68",
			fclEwCode: "E",
			fclWaterDepth: "330",
			fclFunctions: "MANIFOLD STATION ",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278161",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278161",
			fclNpdidFacility: "278161",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL G",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "21.09.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "45",
			fclNsSec: "12.68",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "31.71",
			fclEwCode: "E",
			fclWaterDepth: "327",
			fclFunctions: "MANIFOLD STATION ",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278189",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278189",
			fclNpdidFacility: "278189",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "31.12.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "42",
			fclNsSec: "52.19",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "30",
			fclEwSec: "37.36",
			fclEwCode: "E",
			fclWaterDepth: "317",
			fclFunctions: "MANIFOLD STATION ",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278301",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278301",
			fclNpdidFacility: "278301",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL I1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "14.04.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "44",
			fclNsSec: "27.64",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "34",
			fclEwSec: "54.21",
			fclEwCode: "E",
			fclWaterDepth: "315",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279113",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279113",
			fclNpdidFacility: "279113",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL I2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "14.04.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "43",
			fclNsSec: "42.75",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "34",
			fclEwSec: "39.35",
			fclEwCode: "E",
			fclWaterDepth: "315",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279953",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279953",
			fclNpdidFacility: "279953",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL J1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "17.03.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "44",
			fclNsSec: "35.65",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "36",
			fclEwSec: "28.80",
			fclEwCode: "E",
			fclWaterDepth: "315",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279141",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279141",
			fclNpdidFacility: "279141",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL J2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "17.03.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "43",
			fclNsSec: "45.47",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "39",
			fclEwSec: "23.57",
			fclEwCode: "E",
			fclWaterDepth: "315",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279981",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279981",
			fclNpdidFacility: "279981",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL K1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "24.07.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "46",
			fclNsSec: "5.93",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "36",
			fclEwSec: "40.86",
			fclEwCode: "E",
			fclWaterDepth: "320",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279449",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279449",
			fclNpdidFacility: "279449",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL K2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "24.07.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "45",
			fclNsSec: "21.02",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "38",
			fclEwSec: "23.26",
			fclEwCode: "E",
			fclWaterDepth: "320",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280009",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280009",
			fclNpdidFacility: "280009",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL L1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "14.11.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "47",
			fclNsSec: "6.44",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "33",
			fclEwSec: "21.75",
			fclEwCode: "E",
			fclWaterDepth: "327",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279029",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279029",
			fclNpdidFacility: "279029",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL L2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "14.11.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "48",
			fclNsSec: "31.58",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "33",
			fclEwSec: "21.43",
			fclEwCode: "E",
			fclWaterDepth: "333",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280289",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280289",
			fclNpdidFacility: "280289",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL M1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "15.07.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "54",
			fclNsSec: "56.17",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "37",
			fclEwSec: "35.59",
			fclEwCode: "E",
			fclWaterDepth: "344",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279057",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279057",
			fclNpdidFacility: "279057",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL M2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "15.07.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "55",
			fclNsSec: "53.11",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "39",
			fclEwSec: "51.26",
			fclEwCode: "E",
			fclWaterDepth: "346",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280037",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280037",
			fclNpdidFacility: "280037",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL N1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "06.01.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "54",
			fclNsSec: "56.48",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "35",
			fclEwSec: "11.79",
			fclEwCode: "E",
			fclWaterDepth: "345",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279729",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279729",
			fclNpdidFacility: "279729",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL N2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.12.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "56",
			fclNsSec: "46.82",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "34",
			fclEwSec: "49.33",
			fclEwCode: "E",
			fclWaterDepth: "345",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280317",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280317",
			fclNpdidFacility: "280317",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL O1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "23.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "53",
			fclNsSec: "22.46",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "33",
			fclEwSec: "59.00",
			fclEwCode: "E",
			fclWaterDepth: "355",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279757",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279757",
			fclNpdidFacility: "279757",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL O2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "20.07.2010",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "54",
			fclNsSec: "52.22",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "31",
			fclEwSec: "22.55",
			fclEwCode: "E",
			fclWaterDepth: "338",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283677",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283677",
			fclNpdidFacility: "283677",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL P1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "07.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "52",
			fclNsSec: "1.18",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "33",
			fclEwSec: "28.59",
			fclEwCode: "E",
			fclWaterDepth: "336",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279085",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279085",
			fclNpdidFacility: "279085",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL P2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.06.2002",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "52",
			fclNsSec: "35.69",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "29",
			fclEwSec: "39.51",
			fclEwCode: "E",
			fclWaterDepth: "336",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280261",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280261",
			fclNpdidFacility: "280261",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL PILOT",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "03.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "53",
			fclNsSec: "46.82",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "39",
			fclEwSec: "13.53",
			fclEwCode: "E",
			fclWaterDepth: "342",
			fclFunctions: "SEPARATION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282921",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282921",
			fclNpdidFacility: "282921",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL PRB",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "28.05.1994",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "46",
			fclNsSec: "33.00",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "29",
			fclEwSec: "51.00",
			fclEwCode: "E",
			fclWaterDepth: "324",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281885",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281885",
			fclNpdidFacility: "281885",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL PRBN",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.04.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "46",
			fclNsSec: "23.55",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "30",
			fclEwSec: "38.25",
			fclEwCode: "E",
			fclWaterDepth: "324",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281913",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281913",
			fclNpdidFacility: "281913",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL PRBS",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "11.06.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "46",
			fclNsSec: "19.81",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "30",
			fclEwSec: "34.48",
			fclEwCode: "E",
			fclWaterDepth: "324",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281941",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281941",
			fclNpdidFacility: "281941",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL PRS",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "10.04.1995",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "46",
			fclNsSec: "29.81",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "30",
			fclEwSec: "4.22",
			fclEwCode: "E",
			fclWaterDepth: "325",
			fclFunctions: "RISER SUPPORT",
			fclDesignLifetime: "27",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281969",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281969",
			fclNpdidFacility: "281969",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL PTG",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "52",
			fclNsSec: "55.83",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "37",
			fclEwSec: "5.78",
			fclEwCode: "E",
			fclWaterDepth: "338",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282053",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282053",
			fclNpdidFacility: "282053",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL PTO",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "52",
			fclNsSec: "56.70",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "37",
			fclEwSec: "6.37",
			fclEwCode: "E",
			fclWaterDepth: "338",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282081",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282081",
			fclNpdidFacility: "282081",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL Q1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "31.10.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "50",
			fclNsSec: "40.82",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "37",
			fclEwSec: "38.02",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279477",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279477",
			fclNpdidFacility: "279477",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL R",
			fclPhase: "FUTURE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "51",
			fclNsSec: "46.59",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "42",
			fclEwSec: "37.47",
			fclEwCode: "E",
			fclWaterDepth: "341",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283733",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283733",
			fclNpdidFacility: "283733",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL RB1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "53",
			fclNsSec: "20.32",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "36",
			fclEwSec: "30.57",
			fclEwCode: "E",
			fclWaterDepth: "338",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282109",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282109",
			fclNpdidFacility: "282109",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL RB2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "53",
			fclNsSec: "2.89",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "37",
			fclEwSec: "4.18",
			fclEwCode: "E",
			fclWaterDepth: "338",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283313",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283313",
			fclNpdidFacility: "283313",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL RB3",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "53",
			fclNsSec: "16.52",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "36",
			fclEwSec: "22.51",
			fclEwCode: "E",
			fclWaterDepth: "338",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283341",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283341",
			fclNpdidFacility: "283341",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL RB4",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "52",
			fclNsSec: "58.82",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "36",
			fclEwSec: "55.10",
			fclEwCode: "E",
			fclWaterDepth: "338",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283369",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283369",
			fclNpdidFacility: "283369",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL RSS1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "53",
			fclNsSec: "13.33",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "36",
			fclEwSec: "36.46",
			fclEwCode: "E",
			fclWaterDepth: "338",
			fclFunctions: "RISER SUPPORT",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283397",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283397",
			fclNpdidFacility: "283397",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL RSS2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "53",
			fclNsSec: "6.05",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "36",
			fclEwSec: "49.99",
			fclEwCode: "E",
			fclWaterDepth: "338",
			fclFunctions: "RISER SUPPORT",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283425",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283425",
			fclNpdidFacility: "283425",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL S1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "03.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "53",
			fclNsSec: "42.18",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "39",
			fclEwSec: "16.71",
			fclEwCode: "E",
			fclWaterDepth: "340",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280737",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280737",
			fclNpdidFacility: "280737",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL S2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "03.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "53",
			fclNsSec: "34.73",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "40",
			fclEwSec: "18.73",
			fclEwCode: "E",
			fclWaterDepth: "342",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280345",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280345",
			fclNpdidFacility: "280345",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL T1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.02.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "49",
			fclNsSec: "57.27",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "36",
			fclEwSec: "14.32",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280373",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280373",
			fclNpdidFacility: "280373",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL T2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.01.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "48",
			fclNsSec: "29.11",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "35",
			fclEwSec: "35.51",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280401",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280401",
			fclNpdidFacility: "280401",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL T3",
			fclPhase: "FUTURE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282361",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282361",
			fclNpdidFacility: "282361",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL U1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "50",
			fclNsSec: "57.58",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "34",
			fclEwSec: "54.00",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280429",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280429",
			fclNpdidFacility: "280429",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL U2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "01.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "49",
			fclNsSec: "51.78",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "34",
			fclEwSec: "21.34",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280457",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280457",
			fclNpdidFacility: "280457",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL V1",
			fclPhase: "FUTURE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282389",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282389",
			fclNpdidFacility: "282389",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL V2",
			fclPhase: "FUTURE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282417",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282417",
			fclNpdidFacility: "282417",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL W1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "27.08.2021",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "47",
			fclNsSec: "12.47",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "32",
			fclEwSec: "12.75",
			fclEwCode: "E",
			fclWaterDepth: "327",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "40",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=709792",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=709792",
			fclNpdidFacility: "709792",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TROLL W2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TROLL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "46437",
			fclStartupDate: "27.08.2021",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "50",
			fclNsSec: "19.94",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "30",
			fclEwSec: "54.57",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "40",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=710086",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=710086",
			fclNpdidFacility: "710086",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TRYM",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "DNO Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TRYM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "18081500",
			fclStartupDate: "12.02.2011",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "22",
			fclNsSec: "54.79",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "14",
			fclEwSec: "15.07",
			fclEwCode: "E",
			fclWaterDepth: "65",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=410130",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=410130",
			fclNpdidFacility: "410130",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TUNE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TUNE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "853376",
			fclStartupDate: "28.11.2002",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "28",
			fclNsSec: "3.08",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "39",
			fclEwSec: "11.91",
			fclEwCode: "E",
			fclWaterDepth: "100",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281045",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281045",
			fclNpdidFacility: "281045",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TYRIHANS A",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TYRIHANS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "3960848",
			fclStartupDate: "08.07.2009",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "53",
			fclNsSec: "20.25",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "4",
			fclEwSec: "16.84",
			fclEwCode: "E",
			fclWaterDepth: "286",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=377615",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=377615",
			fclNpdidFacility: "377615",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TYRIHANS B",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TYRIHANS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "3960848",
			fclStartupDate: "08.07.2009",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "48",
			fclNsSec: "41.78",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "3",
			fclEwSec: "24.41",
			fclEwCode: "E",
			fclWaterDepth: "273",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=377652",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=377652",
			fclNpdidFacility: "377652",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TYRIHANS C",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TYRIHANS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "3960848",
			fclStartupDate: "08.07.2009",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "47",
			fclNsSec: "23.50",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "0",
			fclEwSec: "42.22",
			fclEwCode: "E",
			fclWaterDepth: "268",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=377689",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=377689",
			fclNpdidFacility: "377689",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TYRIHANS D",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TYRIHANS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "3960848",
			fclStartupDate: "08.07.2009",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "46",
			fclNsSec: "47.06",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "59",
			fclEwSec: "26.58",
			fclEwCode: "E",
			fclWaterDepth: "266",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=377726",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=377726",
			fclNpdidFacility: "377726",
			fclDateUpdated: "04.12.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "TYRIHANS W",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "TYRIHANS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "3960848",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "50",
			fclNsSec: "5.69",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "0",
			fclEwSec: "55.14",
			fclEwCode: "E",
			fclWaterDepth: "282",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=377763",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=377763",
			fclNpdidFacility: "377763",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "UK-NL",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "STATFJORD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43658",
			fclStartupDate: "01.10.1985",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "10",
			fclNsSec: "2.94",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "39",
			fclEwSec: "33.94",
			fclEwCode: "E",
			fclWaterDepth: "44",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286449",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286449",
			fclNpdidFacility: "286449",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ULA DP",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "ULA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43800",
			fclStartupDate: "01.07.1986",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "6",
			fclNsSec: "41.15",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "50",
			fclEwSec: "50.39",
			fclEwCode: "E",
			fclWaterDepth: "71",
			fclFunctions: "DRILLING",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271889",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271889",
			fclNpdidFacility: "271889",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ULA PP",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "ULA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43800",
			fclStartupDate: "01.10.1986",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "6",
			fclNsSec: "42.32",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "50",
			fclEwSec: "54.85",
			fclEwCode: "E",
			fclWaterDepth: "71",
			fclFunctions: "PROCESSING",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272029",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272029",
			fclNpdidFacility: "272029",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ULA QP",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "ULA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43800",
			fclStartupDate: "01.10.1986",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "6",
			fclNsSec: "39.89",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "50",
			fclEwSec: "45.54",
			fclEwCode: "E",
			fclWaterDepth: "71",
			fclFunctions: "QUARTER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271665",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271665",
			fclNpdidFacility: "271665",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ULA-Y",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "ULA OLJERØR",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124285",
			fclStartupDate: "22.06.1990",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "4",
			fclNsSec: "45.34",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "50",
			fclEwSec: "53.66",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "Y-CONNECTION",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286477",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286477",
			fclNpdidFacility: "286477",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "URD T",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "URD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2834734",
			fclStartupDate: "08.11.2005",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "390",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=372144",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=372144",
			fclNpdidFacility: "372144",
			fclDateUpdated: "26.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "UTGARD",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "UTGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "28975098",
			fclStartupDate: "16.09.2019",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "22",
			fclNsSec: "0.28",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "33",
			fclEwSec: "1.67",
			fclEwCode: "E",
			fclWaterDepth: "115",
			fclFunctions: "GAS PRODUCER - WATER PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=450712",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=450712",
			fclNpdidFacility: "450712",
			fclDateUpdated: "28.12.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VALE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Sval Energi AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "VALE",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "1578893",
			fclStartupDate: "31.05.2002",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "42",
			fclNsSec: "22.43",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "18",
			fclEwSec: "37.83",
			fclEwCode: "E",
			fclWaterDepth: "114",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "12",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282501",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282501",
			fclNpdidFacility: "282501",
			fclDateUpdated: "03.06.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VALEMON",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "VALEMON",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "20460969",
			fclStartupDate: "03.01.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "2",
			fclNsSec: "26.38",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "20",
			fclEwSec: "20.43",
			fclEwCode: "E",
			fclWaterDepth: "133",
			fclFunctions: "PROCESSING - QUARTER - WELLHEAD",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=424932",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=424932",
			fclNpdidFacility: "424932",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VALHALL BVS",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "VALHALL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43548",
			fclStartupDate: "18.08.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "16",
			fclNsSec: "38.07",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "23",
			fclEwSec: "51.48",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "VALVE",
			fclDesignLifetime: "33",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287009",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287009",
			fclNpdidFacility: "287009",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VALHALL DP",
			fclPhase: "SHUT DOWN",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "VALHALL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43548",
			fclStartupDate: "17.12.1981",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "16",
			fclNsSec: "41.39",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "23",
			fclEwSec: "43.19",
			fclEwCode: "E",
			fclWaterDepth: "74",
			fclFunctions: "DRILLING",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271749",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271749",
			fclNpdidFacility: "271749",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VALHALL FLANKE NORD",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "VALHALL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43548",
			fclStartupDate: "07.01.2004",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "19",
			fclNsSec: "27.70",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "21",
			fclEwSec: "9.51",
			fclEwCode: "E",
			fclWaterDepth: "69",
			fclFunctions: "WELLHEAD",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=361248",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=361248",
			fclNpdidFacility: "361248",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VALHALL FLANKE SØR",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "VALHALL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43548",
			fclStartupDate: "08.05.2003",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "13",
			fclNsSec: "39.76",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "14.62",
			fclEwCode: "E",
			fclWaterDepth: "67",
			fclFunctions: "WELLHEAD",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280961",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280961",
			fclNpdidFacility: "280961",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VALHALL FLANKE VEST",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "VALHALL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43548",
			fclStartupDate: "16.12.2019",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "15",
			fclNsSec: "4.74",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "20",
			fclEwSec: "56.58",
			fclEwCode: "E",
			fclWaterDepth: "69",
			fclFunctions: "GAS INJECTION - GAS PRODUCER - OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=453766",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=453766",
			fclNpdidFacility: "453766",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VALHALL IP",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "VALHALL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43548",
			fclStartupDate: "24.01.2004",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "16",
			fclNsSec: "35.46",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "23",
			fclEwSec: "46.82",
			fclEwCode: "E",
			fclWaterDepth: "74",
			fclFunctions: "DRILLING - WATER INJECTION",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282529",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282529",
			fclNpdidFacility: "282529",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VALHALL PCP",
			fclPhase: "SHUT DOWN",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "VALHALL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43548",
			fclStartupDate: "01.06.1982",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "16",
			fclNsSec: "39.20",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "23",
			fclEwSec: "47.90",
			fclEwCode: "E",
			fclWaterDepth: "74",
			fclFunctions: "PROCESSING",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272449",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272449",
			fclNpdidFacility: "272449",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VALHALL PH",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "VALHALL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43548",
			fclStartupDate: "26.01.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "16",
			fclNsSec: "31.88",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "23",
			fclEwSec: "44.09",
			fclEwCode: "E",
			fclWaterDepth: "74",
			fclFunctions: "PROCESSING - QUARTER",
			fclDesignLifetime: "40",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=406276",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=406276",
			fclNpdidFacility: "406276",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VALHALL Q",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "VALHALL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43548",
			fclStartupDate: "01.07.1981",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "16",
			fclNsSec: "43.80",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "23",
			fclEwSec: "39.40",
			fclEwCode: "E",
			fclWaterDepth: "74",
			fclFunctions: "QUARTER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272477",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272477",
			fclNpdidFacility: "272477",
			fclDateUpdated: "23.12.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VALHALL SSPR",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "VALHALL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43548",
			fclStartupDate: "18.08.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "29",
			fclNsSec: "32.98",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "19",
			fclEwSec: "32.92",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "PIG RECIVER",
			fclDesignLifetime: "33",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286981",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286981",
			fclNpdidFacility: "286981",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VALHALL VTS",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "VALHALL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43548",
			fclStartupDate: "18.08.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "29",
			fclNsSec: "33.67",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "19",
			fclEwSec: "33.90",
			fclEwCode: "E",
			fclWaterDepth: "70",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "33",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286953",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286953",
			fclNpdidFacility: "286953",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VALHALL WP",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "VALHALL",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43548",
			fclStartupDate: "01.06.1996",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "16",
			fclNsSec: "35.65",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "23",
			fclEwSec: "46.96",
			fclEwCode: "E",
			fclWaterDepth: "74",
			fclFunctions: "WELLHEAD",
			fclDesignLifetime: "33",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278385",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278385",
			fclNpdidFacility: "278385",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VARG A",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Chrysaor Norge AS",
			fclKind: "MONOTOWER",
			fclBelongsToName: "VARG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43451",
			fclStartupDate: "22.12.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "58",
			fclNsMin: "4",
			fclNsSec: "40.50",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "53",
			fclEwSec: "25.69",
			fclEwCode: "E",
			fclWaterDepth: "84",
			fclFunctions: "WELLHEAD",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279505",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279505",
			fclNpdidFacility: "279505",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VEGA NORD",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Wintershall Dea Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "VEGA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4467595",
			fclStartupDate: "02.12.2010",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "16.22",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "22",
			fclEwSec: "36.81",
			fclEwCode: "E",
			fclWaterDepth: "377",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=407790",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=407790",
			fclNpdidFacility: "407790",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VEGA SENTRAL",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Wintershall Dea Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "VEGA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4467595",
			fclStartupDate: "02.12.2010",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "15",
			fclNsSec: "42.09",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "21",
			fclEwSec: "31.79",
			fclEwCode: "E",
			fclWaterDepth: "378",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=407849",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=407849",
			fclNpdidFacility: "407849",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VEGA SØR",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Wintershall Dea Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "VEGA",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4467595",
			fclStartupDate: "02.12.2010",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "10",
			fclNsSec: "26.11",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "26",
			fclEwSec: "29.45",
			fclEwCode: "E",
			fclWaterDepth: "368",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=407908",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=407908",
			fclNpdidFacility: "407908",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VESLEFRIKK A",
			fclPhase: "SHUT DOWN",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "JACKET 4 LEGS",
			fclBelongsToName: "VESLEFRIKK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43618",
			fclStartupDate: "26.12.1989",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "46",
			fclNsSec: "57.74",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "53",
			fclEwSec: "52.29",
			fclEwCode: "E",
			fclWaterDepth: "174",
			fclFunctions: "DRILLING",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277321",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277321",
			fclNpdidFacility: "277321",
			fclDateUpdated: "03.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VESLEFRIKK B",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SEMISUB STEEL",
			fclBelongsToName: "VESLEFRIKK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43618",
			fclStartupDate: "26.12.1989",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "46",
			fclNsSec: "53.00",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "53",
			fclEwSec: "55.00",
			fclEwCode: "E",
			fclWaterDepth: "176",
			fclFunctions: "PROCESSING - QUARTER",
			fclDesignLifetime: "26",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277349",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277349",
			fclNpdidFacility: "277349",
			fclDateUpdated: "02.07.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VESLEFRIKK T",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "STATPIPE 5",
			fclBelongsToKind: "TUF PART",
			fclBelongsToS: "124054",
			fclStartupDate: "01.09.1991",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "33",
			fclNsSec: "37.93",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "53",
			fclEwSec: "53.00",
			fclEwCode: "E",
			fclWaterDepth: "110",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "40",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286617",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286617",
			fclNpdidFacility: "286617",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VEST EKOFISK",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "ConocoPhillips Skandinavia AS",
			fclKind: "JACKET 8 LEGS",
			fclBelongsToName: "VEST EKOFISK",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43513",
			fclStartupDate: "01.01.1974",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "56",
			fclNsMin: "33",
			fclNsSec: "47.55",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "5",
			fclEwSec: "8.16",
			fclEwCode: "E",
			fclWaterDepth: "75",
			fclFunctions: "",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=271497",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=271497",
			fclNpdidFacility: "271497",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VESTERLED T",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "VESTERLED",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124280",
			fclStartupDate: "01.10.2001",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "38",
			fclNsSec: "35.93",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "19",
			fclEwSec: "8.31",
			fclEwCode: "E",
			fclWaterDepth: "100",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287625",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287625",
			fclNpdidFacility: "287625",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VESTPROSESS ",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "60",
			fclNsMin: "48",
			fclNsSec: "35.25",
			fclNsCode: "N",
			fclEwDeg: "5",
			fclEwMin: "2",
			fclEwSec: "12.32",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=460838",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=460838",
			fclNpdidFacility: "460838",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VIGDIS B",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "VIGDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43732",
			fclStartupDate: "05.03.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "23",
			fclNsSec: "49.49",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "7",
			fclEwSec: "3.54",
			fclEwCode: "E",
			fclWaterDepth: "292",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279169",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279169",
			fclNpdidFacility: "279169",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VIGDIS BOOSTER STATION",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "VIGDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43732",
			fclStartupDate: "01.05.2021",
			fclGeodeticDatum: "",
			fclNsDeg: "",
			fclNsMin: "",
			fclNsSec: "",
			fclNsCode: "N",
			fclEwDeg: "",
			fclEwMin: "",
			fclEwSec: "",
			fclEwCode: "E",
			fclWaterDepth: "292",
			fclFunctions: "BOOSTER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=720532",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=720532",
			fclNpdidFacility: "720532",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VIGDIS C",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "VIGDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43732",
			fclStartupDate: "05.03.1997",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "49.55",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "6",
			fclEwSec: "12.21",
			fclEwCode: "E",
			fclWaterDepth: "283",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277657",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277657",
			fclNpdidFacility: "277657",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VIGDIS D",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "VIGDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43732",
			fclStartupDate: "18.10.2003",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "20",
			fclNsSec: "44.21",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "4",
			fclEwSec: "22.33",
			fclEwCode: "E",
			fclWaterDepth: "250",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=359965",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=359965",
			fclNpdidFacility: "359965",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VIGDIS E",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "VIGDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43732",
			fclStartupDate: "01.01.1998",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "23",
			fclNsSec: "54.82",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "4",
			fclEwSec: "4.53",
			fclEwCode: "E",
			fclWaterDepth: "290",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279197",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279197",
			fclNpdidFacility: "279197",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VIGDIS F",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "VIGDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43732",
			fclStartupDate: "18.10.2003",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "19",
			fclNsSec: "14.45",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "4",
			fclEwSec: "0.12",
			fclEwCode: "E",
			fclWaterDepth: "220",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=356166",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=356166",
			fclNpdidFacility: "356166",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VIGDIS G",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "VIGDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43732",
			fclStartupDate: "19.03.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "45.08",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "6",
			fclEwSec: "22.44",
			fclEwCode: "E",
			fclWaterDepth: "280",
			fclFunctions: "OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=377425",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=377425",
			fclNpdidFacility: "377425",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VIGDIS H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "VIGDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43732",
			fclStartupDate: "11.03.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "23",
			fclNsSec: "21.33",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "8",
			fclEwSec: "28.37",
			fclEwCode: "E",
			fclWaterDepth: "291",
			fclFunctions: "OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=417223",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=417223",
			fclNpdidFacility: "417223",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VIGDIS NORDØST T",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "VIGDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43732",
			fclStartupDate: "11.03.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "23",
			fclNsSec: "49.53",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "7",
			fclEwSec: "3.17",
			fclEwCode: "E",
			fclWaterDepth: "290",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=430070",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=430070",
			fclNpdidFacility: "430070",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VIGDIS-Y",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "VIGDIS",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43732",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "11",
			fclNsSec: "4.84",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "10",
			fclEwSec: "20.15",
			fclEwCode: "E",
			fclWaterDepth: "140",
			fclFunctions: "Y-CONNECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286897",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286897",
			fclNpdidFacility: "286897",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VISUND",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SEMISUB STEEL",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "26.05.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "12.56",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "32.10",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "DRILLING - PROCESSING - QUARTER",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=277685",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=277685",
			fclNpdidFacility: "277685",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VISUND NORD",
			fclPhase: "SHUT DOWN",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "01.02.2002",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "25",
			fclNsSec: "51.00",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "34",
			fclEwSec: "3.30",
			fclEwCode: "E",
			fclWaterDepth: "381",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280625",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280625",
			fclNpdidFacility: "280625",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VISUND NORD IOR",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "02.09.2019",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "26",
			fclNsSec: "6.80",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "35",
			fclEwSec: "24.01",
			fclEwCode: "E",
			fclWaterDepth: "380",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=452152",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=452152",
			fclNpdidFacility: "452152",
			fclDateUpdated: "28.12.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VISUND NORD ITS",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "05.11.2013",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "26",
			fclNsSec: "6.10",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "35",
			fclEwSec: "20.84",
			fclEwCode: "E",
			fclWaterDepth: "378",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=424989",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=424989",
			fclNpdidFacility: "424989",
			fclDateUpdated: "28.12.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VISUND SDU-1",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "26.05.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "11.00",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "33.00",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "DISTRIBUTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283453",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283453",
			fclNpdidFacility: "283453",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VISUND SDU-2",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "VISUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43745",
			fclStartupDate: "26.05.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "22",
			fclNsSec: "12.00",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "27",
			fclEwSec: "34.00",
			fclEwCode: "E",
			fclWaterDepth: "335",
			fclFunctions: "DISTRIBUTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=283481",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=283481",
			fclNpdidFacility: "283481",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VISUND SØR",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "VISUND SØR",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "20461008",
			fclStartupDate: "22.11.2012",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "18",
			fclNsSec: "2.03",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "21",
			fclEwSec: "49.35",
			fclEwCode: "E",
			fclWaterDepth: "292",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=414939",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=414939",
			fclNpdidFacility: "414939",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VISUND T",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "KVITEBJØRN GASSRØR",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "2417249",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "61",
			fclNsMin: "4",
			fclNsSec: "5.09",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "31",
			fclEwSec: "40.20",
			fclEwCode: "E",
			fclWaterDepth: "185",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=364607",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=364607",
			fclNpdidFacility: "364607",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VOLUND",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "VOLUND",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "4380167",
			fclStartupDate: "16.09.2009",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "29",
			fclNsSec: "45.82",
			fclNsCode: "N",
			fclEwDeg: "1",
			fclEwMin: "57",
			fclEwSec: "54.44",
			fclEwCode: "E",
			fclWaterDepth: "124",
			fclFunctions: "OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=408227",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=408227",
			fclNpdidFacility: "408227",
			fclDateUpdated: "13.01.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "VØRING T",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "ÅSGARD TRANSPORT",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124211",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "62",
			fclNsMin: "13",
			fclNsSec: "9.79",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "35",
			fclEwSec: "56.91",
			fclEwCode: "E",
			fclWaterDepth: "171",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287485",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287485",
			fclNpdidFacility: "287485",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "YME B",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Repsol Norge AS",
			fclKind: "FSU",
			fclBelongsToName: "YME",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43807",
			fclStartupDate: "27.02.1996",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "45",
			fclNsSec: "15.30",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "21",
			fclEwSec: "21.80",
			fclEwCode: "E",
			fclWaterDepth: "95",
			fclFunctions: "OFFLOADING - STORAGE",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278413",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278413",
			fclNpdidFacility: "278413",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "YME B VEST",
			fclPhase: "DISPOSAL COMPLETED",
			fclSurface: "N",
			fclCurrentOperatorName: "Repsol Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "YME",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43807",
			fclStartupDate: "01.11.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "45",
			fclNsSec: "15.19",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "21",
			fclEwSec: "21.52",
			fclEwCode: "E",
			fclWaterDepth: "85",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "5",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282585",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282585",
			fclNpdidFacility: "282585",
			fclDateUpdated: "28.12.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "YME B ØST",
			fclPhase: "DISPOSAL COMPLETED",
			fclSurface: "N",
			fclCurrentOperatorName: "Repsol Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "YME",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43807",
			fclStartupDate: "01.06.1996",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "45",
			fclNsSec: "15.28",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "21",
			fclEwSec: "21.82",
			fclEwCode: "E",
			fclWaterDepth: "82",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "5",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282557",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282557",
			fclNpdidFacility: "282557",
			fclDateUpdated: "28.12.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "YME BETA",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Repsol Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "YME",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43807",
			fclStartupDate: "25.10.2021",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "45",
			fclNsSec: "22.68",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "22",
			fclEwSec: "7.39",
			fclEwCode: "E",
			fclWaterDepth: "77",
			fclFunctions: "OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=403775",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=403775",
			fclNpdidFacility: "403775",
			fclDateUpdated: "28.12.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "YME BETA NORD",
			fclPhase: "INSTALLATION",
			fclSurface: "N",
			fclCurrentOperatorName: "Repsol Norge AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "YME",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43807",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "45",
			fclNsSec: "23.86",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "22",
			fclEwSec: "9.66",
			fclEwCode: "E",
			fclWaterDepth: "79",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER - WATER INJECTION",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=718140",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=718140",
			fclNpdidFacility: "718140",
			fclDateUpdated: "29.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "YME SLS",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Repsol Norge AS",
			fclKind: "LOADING SYSTEM",
			fclBelongsToName: "YME",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43807",
			fclStartupDate: "25.10.2021",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "49",
			fclNsSec: "27.06",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "33",
			fclEwSec: "26.60",
			fclEwCode: "E",
			fclWaterDepth: "93",
			fclFunctions: "OFFLOADING",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=404044",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=404044",
			fclNpdidFacility: "404044",
			fclDateUpdated: "26.10.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "YME WHM",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Repsol Norge AS",
			fclKind: "MOPUStor",
			fclBelongsToName: "YME",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43807",
			fclStartupDate: "25.10.2021",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "48",
			fclNsSec: "57.92",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "32",
			fclEwSec: "7.57",
			fclEwCode: "E",
			fclWaterDepth: "93",
			fclFunctions: "OFFLOADING - STORAGE - WELLHEAD",
			fclDesignLifetime: "15",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=403167",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=403167",
			fclNpdidFacility: "403167",
			fclDateUpdated: "28.12.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "YME-STL",
			fclPhase: "REMOVED",
			fclSurface: "Y",
			fclCurrentOperatorName: "Repsol Norge AS",
			fclKind: "LOADING SYSTEM",
			fclBelongsToName: "YME",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43807",
			fclStartupDate: "27.02.1996",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "57",
			fclNsMin: "50",
			fclNsSec: "3.48",
			fclNsCode: "N",
			fclEwDeg: "4",
			fclEwMin: "33",
			fclEwSec: "0.19",
			fclEwCode: "E",
			fclWaterDepth: "94",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287765",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287765",
			fclNpdidFacility: "287765",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ZEEBRUGGE",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "ZEEPIPE TERMINAL",
			fclBelongsToKind: "TUF PART",
			fclBelongsToS: "124271",
			fclStartupDate: "01.10.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "51",
			fclNsMin: "20",
			fclNsSec: "25.00",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "10",
			fclEwSec: "39.00",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286281",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286281",
			fclNpdidFacility: "286281",
			fclDateUpdated: "15.01.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ZEEPIPE TERMINAL",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "ONSHORE FACILITY",
			fclBelongsToName: "GASSLED",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "2252643",
			fclStartupDate: "01.10.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "51",
			fclNsMin: "20",
			fclNsSec: "25.00",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "10",
			fclEwSec: "40.00",
			fclEwCode: "E",
			fclWaterDepth: "0",
			fclFunctions: "LANDFALL - PROCESSING",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286337",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286337",
			fclNpdidFacility: "286337",
			fclDateUpdated: "12.01.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ZEEPIPE-SCP",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "GASSLED",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "2252643",
			fclStartupDate: "01.10.1993",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "54",
			fclNsMin: "47",
			fclNsSec: "22.18",
			fclNsCode: "N",
			fclEwDeg: "3",
			fclEwMin: "17",
			fclEwSec: "59.34",
			fclEwCode: "E",
			fclWaterDepth: "41",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=286533",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=286533",
			fclNpdidFacility: "286533",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ØST FRIGG A",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ØST FRIGG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43576",
			fclStartupDate: "01.10.1988",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "55",
			fclNsSec: "8.65",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "21",
			fclEwSec: "51.99",
			fclEwCode: "E",
			fclWaterDepth: "112",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272505",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272505",
			fclNpdidFacility: "272505",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ØST FRIGG B",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ØST FRIGG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43576",
			fclStartupDate: "01.10.1988",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "53",
			fclNsSec: "26.40",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "21",
			fclEwSec: "42.94",
			fclEwCode: "E",
			fclWaterDepth: "112",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=272533",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=272533",
			fclNpdidFacility: "272533",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ØST FRIGG CMS",
			fclPhase: "REMOVED",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "ØST FRIGG",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43576",
			fclStartupDate: "01.10.1988",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "54",
			fclNsSec: "16.71",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "21",
			fclEwSec: "46.48",
			fclEwCode: "E",
			fclWaterDepth: "112",
			fclFunctions: "MANIFOLD STATION ",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=360641",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=360641",
			fclNpdidFacility: "360641",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ØST KAMELEON",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Aker BP ASA",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ALVHEIM",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "2845712",
			fclStartupDate: "08.06.2008",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "59",
			fclNsMin: "35",
			fclNsSec: "9.12",
			fclNsCode: "N",
			fclEwDeg: "2",
			fclEwMin: "5",
			fclEwSec: "10.76",
			fclEwCode: "E",
			fclWaterDepth: "123",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=375282",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=375282",
			fclNpdidFacility: "375282",
			fclDateUpdated: "13.01.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD A",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "FPSO",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "20.05.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "3",
			fclNsSec: "51.90",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "43",
			fclEwSec: "38.80",
			fclEwCode: "E",
			fclWaterDepth: "315",
			fclFunctions: "OFFLOADING - PROCESSING - QUARTER - STORAGE",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=279785",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=279785",
			fclNpdidFacility: "279785",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD B",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SEMISUB STEEL",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "04.10.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "6",
			fclNsSec: "37.34",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "47",
			fclEwSec: "28.27",
			fclEwCode: "E",
			fclWaterDepth: "300",
			fclFunctions: "GAS EXPORT - PROCESSING - QUARTER",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280121",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280121",
			fclNpdidFacility: "280121",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD C",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "FSU",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "04.10.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "7",
			fclNsSec: "52.93",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "51",
			fclEwSec: "56.83",
			fclEwCode: "E",
			fclWaterDepth: "290",
			fclFunctions: "OFFLOADING - STORAGE",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=280149",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=280149",
			fclNpdidFacility: "280149",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD D",
			fclPhase: "FUTURE",
			fclSurface: "N",
			fclCurrentOperatorName: "",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "",
			fclBelongsToKind: "INGEN REGISTERING",
			fclBelongsToS: "",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "4",
			fclNsSec: "5.07",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "36",
			fclEwSec: "40.44",
			fclEwCode: "E",
			fclWaterDepth: "300",
			fclFunctions: "",
			fclDesignLifetime: "",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=391270",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=391270",
			fclNpdidFacility: "391270",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD E",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "20.05.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "4",
			fclNsSec: "5.95",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "36",
			fclEwSec: "40.92",
			fclEwCode: "E",
			fclWaterDepth: "305",
			fclFunctions: "GAS INJECTION - OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278721",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278721",
			fclNpdidFacility: "278721",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD ERB",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "ÅSGARD TRANSPORT",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124211",
			fclStartupDate: "01.10.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "6",
			fclNsSec: "24.07",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "47",
			fclEwSec: "56.59",
			fclEwCode: "E",
			fclWaterDepth: "305",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=282613",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=282613",
			fclNpdidFacility: "282613",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD F",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "20.05.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "1",
			fclNsSec: "46.30",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "36",
			fclEwSec: "4.50",
			fclEwCode: "E",
			fclWaterDepth: "300",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278553",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278553",
			fclNpdidFacility: "278553",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD G",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "20.05.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "5",
			fclNsSec: "17.75",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "39",
			fclEwSec: "43.20",
			fclEwCode: "E",
			fclWaterDepth: "288",
			fclFunctions: "GAS INJECTION - OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278749",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278749",
			fclNpdidFacility: "278749",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD H",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "04.10.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "7",
			fclNsSec: "6.86",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "40",
			fclEwSec: "27.40",
			fclEwCode: "E",
			fclWaterDepth: "290",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278665",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278665",
			fclNpdidFacility: "278665",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD I",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "20.05.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "5",
			fclNsSec: "7.30",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "40",
			fclEwSec: "54.30",
			fclEwCode: "E",
			fclWaterDepth: "285",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278581",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278581",
			fclNpdidFacility: "278581",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD J",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "04.10.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "9",
			fclNsSec: "58.80",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "43",
			fclEwSec: "59.10",
			fclEwCode: "E",
			fclWaterDepth: "256",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278693",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278693",
			fclNpdidFacility: "278693",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD K",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "04.10.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "8",
			fclNsSec: "1.80",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "42",
			fclEwSec: "25.20",
			fclEwCode: "E",
			fclWaterDepth: "296",
			fclFunctions: "GAS INJECTION - OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278777",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278777",
			fclNpdidFacility: "278777",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD L",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "20.05.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "8",
			fclNsSec: "17.70",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "46",
			fclEwSec: "57.70",
			fclEwCode: "E",
			fclWaterDepth: "288",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278609",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278609",
			fclNpdidFacility: "278609",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD M",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "04.10.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "10",
			fclNsSec: "24.40",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "48",
			fclEwSec: "3.20",
			fclEwCode: "E",
			fclWaterDepth: "273",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278805",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278805",
			fclNpdidFacility: "278805",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD N",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "04.10.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "10",
			fclNsSec: "26.10",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "51",
			fclEwSec: "5.20",
			fclEwCode: "E",
			fclWaterDepth: "272",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278637",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278637",
			fclNpdidFacility: "278637",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD NB",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "10.02.2012",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "13",
			fclNsSec: "1.20",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "54",
			fclEwSec: "29.77",
			fclEwCode: "E",
			fclWaterDepth: "301",
			fclFunctions: "GAS PRODUCER - OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=413766",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=413766",
			fclNpdidFacility: "413766",
			fclDateUpdated: "28.12.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD O",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "04.10.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "8",
			fclNsSec: "20.04",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "47",
			fclEwSec: "5.06",
			fclEwCode: "E",
			fclWaterDepth: "286",
			fclFunctions: "WATER INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=281773",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=281773",
			fclNpdidFacility: "281773",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD P",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "20.05.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "1",
			fclNsSec: "22.78",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "52",
			fclEwSec: "45.80",
			fclEwCode: "E",
			fclWaterDepth: "310",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278889",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278889",
			fclNpdidFacility: "278889",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD PB",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "04.09.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "1",
			fclNsSec: "54.61",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "52",
			fclEwSec: "33.61",
			fclEwCode: "E",
			fclWaterDepth: "310",
			fclFunctions: "GAS INJECTION - OIL PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=437497",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=437497",
			fclNpdidFacility: "437497",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD Q",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "26.08.2005",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "2",
			fclNsSec: "24.62",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "56",
			fclEwSec: "30.75",
			fclEwCode: "E",
			fclWaterDepth: "300",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=363659",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=363659",
			fclNpdidFacility: "363659",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD R",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "20.05.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "2",
			fclNsSec: "0.25",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "54",
			fclEwSec: "7.04",
			fclEwCode: "E",
			fclWaterDepth: "305",
			fclFunctions: "GAS INJECTION",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278861",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278861",
			fclNpdidFacility: "278861",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD RB",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "26.08.2005",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "3",
			fclNsSec: "59.90",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "43",
			fclEwSec: "38.30",
			fclEwCode: "E",
			fclWaterDepth: "315",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=365658",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=365658",
			fclNpdidFacility: "365658",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD S",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "20.05.1999",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "0",
			fclNsSec: "58.00",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "56",
			fclEwSec: "45.20",
			fclEwCode: "E",
			fclWaterDepth: "300",
			fclFunctions: "OIL PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278833",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278833",
			fclNpdidFacility: "278833",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD SCMS-A",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "16.09.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "59",
			fclNsSec: "50.99",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "31",
			fclEwSec: "15.30",
			fclEwCode: "E",
			fclWaterDepth: "267",
			fclFunctions: "MANIFOLD STATION ",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=432583",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=432583",
			fclNpdidFacility: "432583",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD SCSt-A",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "16.09.2015",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "59",
			fclNsSec: "50.29",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "31",
			fclEwSec: "9.67",
			fclEwCode: "E",
			fclWaterDepth: "267",
			fclFunctions: "SUBSEA COMPRESSION",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=432476",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=432476",
			fclNpdidFacility: "432476",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD T",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "ÅSGARD TRANSPORT",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "124211",
			fclStartupDate: "",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "2",
			fclNsSec: "36.39",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "39",
			fclEwSec: "18.11",
			fclEwCode: "E",
			fclWaterDepth: "311",
			fclFunctions: "T-CONNECTION",
			fclDesignLifetime: "50",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=287401",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=287401",
			fclNpdidFacility: "287401",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD X",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "04.10.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "4",
			fclNsSec: "3.50",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "29",
			fclEwSec: "34.70",
			fclEwCode: "E",
			fclWaterDepth: "298",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278917",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278917",
			fclNpdidFacility: "278917",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD Y",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "04.10.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "65",
			fclNsMin: "0",
			fclNsSec: "18.60",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "33",
			fclEwSec: "25.20",
			fclEwCode: "E",
			fclWaterDepth: "260",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278945",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278945",
			fclNpdidFacility: "278945",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "ÅSGARD Z",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "ÅSGARD",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "43765",
			fclStartupDate: "04.10.2000",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "64",
			fclNsMin: "58",
			fclNsSec: "14.90",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "37",
			fclEwSec: "19.70",
			fclEwCode: "E",
			fclWaterDepth: "245",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "20",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=278973",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=278973",
			fclNpdidFacility: "278973",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "AASTA HANSTEEN B",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "AASTA HANSTEEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "23395946",
			fclStartupDate: "17.12.2018",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "67",
			fclNsMin: "2",
			fclNsSec: "3.07",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "2",
			fclEwSec: "34.38",
			fclEwCode: "E",
			fclWaterDepth: "1232",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=441847",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=441847",
			fclNpdidFacility: "441847",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "AASTA HANSTEEN C",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "AASTA HANSTEEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "23395946",
			fclStartupDate: "17.12.2018",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "67",
			fclNsMin: "2",
			fclNsSec: "44.98",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "52",
			fclEwSec: "54.22",
			fclEwCode: "E",
			fclWaterDepth: "1260",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=441904",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=441904",
			fclNpdidFacility: "441904",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "AASTA HANSTEEN D",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "MULTI WELL TEMPLATE",
			fclBelongsToName: "AASTA HANSTEEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "23395946",
			fclStartupDate: "17.12.2018",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "67",
			fclNsMin: "4",
			fclNsSec: "21.95",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "0",
			fclEwSec: "44.76",
			fclEwCode: "E",
			fclWaterDepth: "1278",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=441965",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=441965",
			fclNpdidFacility: "441965",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "AASTA HANSTEEN E",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SINGLE WELL TEMPLATE",
			fclBelongsToName: "AASTA HANSTEEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "23395946",
			fclStartupDate: "01.11.2018",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "67",
			fclNsMin: "5",
			fclNsSec: "8.54",
			fclNsCode: "N",
			fclEwDeg: "6",
			fclEwMin: "51",
			fclEwSec: "52.36",
			fclEwCode: "E",
			fclWaterDepth: "1311",
			fclFunctions: "GAS PRODUCER",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=455081",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=455081",
			fclNpdidFacility: "455081",
			fclDateUpdated: "10.12.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "AASTA HANSTEEN PLEM",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Gassco AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "POLARLED",
			fclBelongsToKind: "TUF",
			fclBelongsToS: "17343422",
			fclStartupDate: "17.12.2018",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "67",
			fclNsMin: "3",
			fclNsSec: "20.26",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "6",
			fclEwSec: "25.99",
			fclEwCode: "E",
			fclWaterDepth: "1300",
			fclFunctions: "PIPELINE END MANIFOLD",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=459032",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=459032",
			fclNpdidFacility: "459032",
			fclDateUpdated: "26.03.2022",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "AASTA HANSTEEN SPAR",
			fclPhase: "IN SERVICE",
			fclSurface: "Y",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SPAR",
			fclBelongsToName: "AASTA HANSTEEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "23395946",
			fclStartupDate: "17.12.2018",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "67",
			fclNsMin: "4",
			fclNsSec: "1.17",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "5",
			fclEwSec: "50.92",
			fclEwCode: "E",
			fclWaterDepth: "1315",
			fclFunctions: "ACCOMMODATION - OFFLOADING - PROCESSING - STORAGE",
			fclDesignLifetime: "30",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=444187",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=444187",
			fclNpdidFacility: "444187",
			fclDateUpdated: "10.09.2021",
			datesyncNPD: "02.09.2022",
		},
		{
			fclName: "AASTA HANSTEEN UMBILICAL RISER BASE",
			fclPhase: "IN SERVICE",
			fclSurface: "N",
			fclCurrentOperatorName: "Equinor Energy AS",
			fclKind: "SUBSEA STRUCTURE",
			fclBelongsToName: "AASTA HANSTEEN",
			fclBelongsToKind: "FIELD",
			fclBelongsToS: "23395946",
			fclStartupDate: "17.12.2018",
			fclGeodeticDatum: "ED50",
			fclNsDeg: "67",
			fclNsMin: "3",
			fclNsSec: "29.90",
			fclNsCode: "N",
			fclEwDeg: "7",
			fclEwMin: "5",
			fclEwSec: "51.90",
			fclEwCode: "E",
			fclWaterDepth: "1300",
			fclFunctions: "RISER BASE",
			fclDesignLifetime: "25",
			fclFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=facility&nav2=PageView|Fixed|All&nav3=459089",
			fclFactMapUrl:
				"https://factmaps.npd.no/factmaps/3_0/?run=FacilityByNPDID&scale=100000&NPDID=459089",
			fclNpdidFacility: "459089",
			fclDateUpdated: "26.03.2022",
			datesyncNPD: "02.09.2022",
		},
	]
}
