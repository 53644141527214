import MapHeader from "@components/mapHeader/MapHeader"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import {
	getPanelState,
	updateSidePanelState,
} from "@redux/features/mapSidePanelControl/mapSidePanelControlSlice"
import { ReactElement } from "react"

export type SidePanelMapNLProps = {
	contents: ReactElement[]
	contentsLabels: string[]
	ExpandedTable?: React.FC<{}>
}

export default function SidePanelMapNL({
	contents,
	contentsLabels,
	ExpandedTable,
}: SidePanelMapNLProps): ReactElement {
	const dispatch = useAppDispatch()
	const { isOpen, isTableExpanded, selectedTab } = useAppSelector(getPanelState)

	const handlePanelOpenClose = () => {
		dispatch(
			updateSidePanelState({
				isTableExpanded,
				selectedTab,
				isOpen: !isOpen,
			})
		)
	}

	return !isTableExpanded ? (
		<>
			{/* PANEL HANDLE */}
			<div
				onClick={() => handlePanelOpenClose()}
				className={`${
					isOpen === true ? "left-[590px]" : "left-0"
				} group text-dipai-secondary-901 shadow-side-menu-l absolute top-[calc(50vh-40px)] z-400 grid h-[80px] w-8 cursor-pointer place-items-center rounded-r-sm bg-white transition-[left] duration-500`}
			>
				<svg
					className={`h-6 w-6 transition-all duration-500 ease-in-out group-hover:scale-125 ${
						isOpen ? "rotate-180" : "rotate-0"
					}`}
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
					style={{}}
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M9 5l7 7-7 7"
					/>
				</svg>
			</div>
			{/* PANEL HANDLE END */}

			{/* PANEL BODY */}
			<div
				className={`h-[calc(100%)] w-[590px] transition-all duration-500 ${
					isOpen === true ? "left-0" : "-left-[590px]"
				} shadow-side-menu-l absolute z-400 bg-white`}
			>
				{contents[0]}
				{/* <TabHeaderGroup
							contents={contents}
							labels={contentsLabels}
							location={Location.map}
							width={574}
							widthTab={140}
						/> */}
			</div>
			{/* PANEL BODY END */}
		</>
	) : (
		<div className="absolute z-400 h-full w-full overflow-y-auto bg-white px-[192px]">
			{ExpandedTable !== undefined ? (
				<>
					<MapHeader />
					<div
						className={`bg-dipai-secondary-901 flex h-[60px] items-center justify-between rounded-lg px-4`}
					>
						<p className="text-headline font-bold text-white"> ALL VESSELS</p>
						{/* <Button
									iconId={IconId.Expand}
									size={ButtonSize.small}
									onClick={() =>
										dispatch(
											updateSidePanelState({
												...panelState,
												isTableExpanded: !panelState.isTableExpanded,
											})
										)
									}
								/> */}
					</div>
					<ExpandedTable />
				</>
			) : (
				<></>
			)}
		</div>
	)
}
