import { Column, ColumnType } from "api"
import { Fragment, ReactElement, ReactNode, useEffect, useState } from "react"
import MultiSelectWrapper from "../DatabasePage/MultiSelectWrapper"
import { ConfigData } from "./configTypeDefinition"

class EditableTableCell {
	constructor(readonly key: string, public dbValue: string, readonly read: () => string) {}
}

class EditableTableRow {
	readonly cells: EditableTableCell[] = []

	constructor(readonly rowId: string) {}
}

function readElementValue(elementId: string): string {
	const tmp = document.getElementById(elementId) as HTMLInputElement
	return tmp !== null ? tmp.value : ""
}

export default function DeviceConfigParser({ props }: { props: ConfigData | undefined }) {
	const [vncCounter, setVncCounter] = useState<number>(0)

	const [vncColumns, setVncColumns] = useState<Column[]>([])
	const [camColumns, setCamColumns] = useState<Column[]>([])

	const [dsRowElements, setDSRowElements] = useState<ReactElement[]>([])

	const [vncRowElemState, setVncRowElemState] = useState<ReactElement[]>()
	const [camRowElemState, setCamRowElemState] = useState<ReactElement[]>()

	const [showDsList, setShowDsList] = useState<boolean[]>([])
	const [configProps, setConfigProps] = useState<ConfigData | undefined>()

	const camDataRows: string[][] = []
	const vncDataRows: string[][] = []

	const vncRowElements: ReactElement[] = []

	function initStuff(in_configProps: ConfigData | undefined): ReactElement[] {
		const nodeColumns: Column[] = [
			{
				cells: [],
				label: "Name",
				type: ColumnType.VarText,
			},
			{
				cells: [],
				label: "Slave id",
				type: ColumnType.VarText,
			},
		]

		const compColumns: Column[] = [
			{
				cells: [],
				label: "Name",
				type: ColumnType.VarText,
			},
			{
				cells: [],
				label: "Repub interval",
				type: ColumnType.VarText,
			},
			{
				cells: [],
				label: "Ping interval",
				type: ColumnType.VarText,
			},
		]

		const inOpColumns: Column[] = [
			{
				cells: [],
				label: "Sensor",
				type: ColumnType.VarText,
			},
			{
				cells: [],
				label: "Threshold",
				type: ColumnType.VarText,
			},
			{
				cells: [],
				label: "GreaterThan",
				type: ColumnType.VarBool,
			},
			{
				cells: [],
				label: "Always",
				type: ColumnType.VarBool,
			},
		]

		const tmp_multiselectColumn: Column = {
			cells: [],
			label: "agg list",
			type: ColumnType.MultiSelect,
			alternatives: [
				{ value: "snap", label: "snap" },
				{ value: "accumulated", label: "accumulated" },
			],
		}

		const sensorColumns: Column[] = [
			{
				cells: [],
				label: "Name",
				type: ColumnType.VarText,
			},
			{
				cells: [],
				label: "registry index",
				type: ColumnType.VarText,
			},
			{
				cells: [],
				label: "node id",
				type: ColumnType.VarText,
			},
			tmp_multiselectColumn,
		]

		const dsColumns: Column[] = [
			{
				cells: [],
				label: "Name",
				type: ColumnType.VarText,
			},
			{
				cells: [],
				label: "IP",
				type: ColumnType.VarText,
			},
			{
				cells: [],
				label: "port",
				type: ColumnType.VarText,
			},
			{
				cells: [],
				label: "url",
				type: ColumnType.VarText,
			},
			{
				cells: [],
				label: "communication type",
				type: ColumnType.VarText,
			},
			{
				cells: [],
				label: "com port",
				type: ColumnType.VarText,
			},
			{
				cells: [],
				label: "baud rate",
				type: ColumnType.VarText,
			},
		]

		let compCounter = 0
		let nodeCounter = 0
		let sensorCounter = 0

		const handleShowDs = (index: number) => {
			setShowDsList((prevState) =>
				prevState.map((item, idx) => (idx == index ? !item : item))
			)
		}

		const local_dsRowElements: ReactElement[] = []

		if (in_configProps !== undefined && in_configProps.data_sources !== undefined) {
			in_configProps.data_sources.forEach((dataSource, dSindex) => {
				dsColumns[0]?.cells.push({
					value:
						(dataSource.name !== undefined ? dataSource.name : "") +
						";;data_sources;;" +
						dSindex.toString() +
						";;name",
					disabled: false,
				})
				dsColumns[1]?.cells.push({
					value:
						(dataSource.ip !== undefined ? dataSource.ip : "") +
						";;data_sources;;" +
						dSindex.toString() +
						";;ip",
					disabled: false,
				})
				dsColumns[2]?.cells.push({
					value:
						dataSource.port?.toString() +
						";;data_sources;;" +
						dSindex.toString() +
						";;port",
					disabled: false,
				})
				dsColumns[3]?.cells.push({
					value:
						(dataSource.url !== undefined ? dataSource.url : "") +
						";;data_sources;;" +
						dSindex.toString() +
						";;url",
					disabled: false,
				})
				dsColumns[4]?.cells.push({
					value:
						(dataSource.communication_type !== undefined
							? dataSource.communication_type
							: "") +
						";;data_sources;;" +
						dSindex.toString() +
						";;communication_type",
					disabled: false,
				})
				dsColumns[5]?.cells.push({
					value:
						dataSource.com_port +
						";;data_sources;;" +
						dSindex.toString() +
						";;com_port",
					disabled: false,
				})
				dsColumns[6]?.cells.push({
					value:
						dataSource.baud_rate?.toString() +
						";;data_sources;;" +
						dSindex.toString() +
						";;baud_rate",
					disabled: false,
				})
				const temp_stringlist: string[] = []
				const dsHeaders: string[] = []
				dsColumns.forEach((col, colIndex) => {
					dsHeaders.push(col.label)
					const colCellsDsIndex = col.cells[dSindex]
					if (colCellsDsIndex !== undefined) {
						temp_stringlist.push(colCellsDsIndex.value)
					}
				})
				showDsList.push(false)
				const tmp_counter = dSindex
				let dsHeader = "View datasource " + dsColumns[4]?.cells[dSindex]?.value
				const dataSourceName = dataSource.nodes[0]?.components[0]?.name
				if (dataSourceName !== undefined) {
					dsHeader = dataSourceName.toUpperCase()
				}
				local_dsRowElements.push(
					<div>
						<button
							onClick={() => handleShowDs(tmp_counter)}
							style={{
								lineHeight: "32px",
								fontSize: "24px",
								borderColor: "black",
								borderWidth: "1px",
							}}
						>
							{dsHeader}
							{showDsList[dSindex] !== undefined ? <>&#8593;</> : <>&#8595;</>}
						</button>
						{showDsList[dSindex] !== undefined ? (
							<table className="w-full">
								<thead>
									<tr>
										{dsHeaders.map((header, index) => (
											<th key={"ds" + dSindex.toString() + index.toString()}>
												{header}
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{makeRow(
										temp_stringlist,
										dsColumns,
										"datasourcetable" + dSindex.toString()
									)}
								</tbody>
							</table>
						) : null}
					</div>
				)
				dataSource.nodes.forEach((node, nodeIndex) => {
					nodeColumns[0]?.cells.push({
						value:
							node.name +
							";;data_sources;;" +
							dSindex.toString() +
							";;nodes;;" +
							nodeIndex.toString() +
							";;name",
						disabled: false,
					})
					nodeColumns[1]?.cells.push({
						value:
							node.slave_id?.toString() +
							";;data_sources;;" +
							dSindex.toString() +
							";;nodes;;" +
							nodeIndex.toString() +
							";;slave_id",
						disabled: false,
					})
					const temp_stringlist: string[] = []
					const nodeHeaders: string[] = []
					nodeColumns.forEach((col, colIndex) => {
						nodeHeaders.push(col.label)
						const colCellsNodeCounter = col.cells[nodeCounter]
						if (colCellsNodeCounter !== undefined) {
							temp_stringlist.push(colCellsNodeCounter.value)
						}
					})
					local_dsRowElements.push(
						<>
							{showDsList[dSindex] !== undefined ? (
								<div className="ml-6">
									<h1 className="text-title3">
										{"Node: " + nodeColumns[0]?.cells[nodeCounter]?.value}
									</h1>
									<table className="w-full">
										<thead>
											<tr>
												{nodeHeaders.map((header, index) => (
													<th
														key={
															"node" +
															nodeCounter.toString() +
															index.toString()
														}
													>
														{header}
													</th>
												))}
											</tr>
										</thead>
										<tbody>
											{makeRow(
												temp_stringlist,
												nodeColumns,
												"nodetable" + nodeCounter.toString()
											)}
										</tbody>
									</table>
								</div>
							) : null}
						</>
					)
					nodeCounter = nodeCounter + 1
					node.components.map((comp, compIndex) => {
						compColumns[0]?.cells.push({
							value:
								comp.name +
								";;data_sources;;" +
								dSindex.toString() +
								";;nodes;;" +
								nodeIndex.toString() +
								";;components;;" +
								compIndex.toString() +
								";;name",
							disabled: false,
						})
						compColumns[1]?.cells.push({
							value:
								comp.republish_interval?.toString() +
								";;data_sources;;" +
								dSindex.toString() +
								";;nodes;;" +
								nodeIndex.toString() +
								";;components;;" +
								compIndex.toString() +
								";;republish_interval",
							disabled: false,
						})
						compColumns[2]?.cells.push({
							value:
								comp.ping_interval?.toString() +
								";;data_sources;;" +
								dSindex.toString() +
								";;nodes;;" +
								nodeIndex.toString() +
								";;components;;" +
								compIndex.toString() +
								";;ping_interval",
							disabled: false,
						})
						const temp_stringlist: string[] = []
						const compHeaders: string[] = []
						compColumns.forEach((col, colIndex) => {
							compHeaders.push(col.label)
							const colCellsCompCounter = col.cells[compCounter]
							if (colCellsCompCounter !== undefined) {
								temp_stringlist.push(colCellsCompCounter.value)
							}
						})
						local_dsRowElements.push(
							<>
								{showDsList[dSindex] !== undefined ? (
									<div className="ml-12">
										<h1 className="text-title3">
											{"Component: " +
												compColumns[0]?.cells[compCounter]?.value}
										</h1>
										<table className="w-full">
											<thead>
												<tr>
													{compHeaders.map((header, index) => (
														<th
															key={
																"comp" +
																compCounter.toString() +
																index.toString()
															}
														>
															{header}
														</th>
													))}
												</tr>
											</thead>
											<tbody>
												{makeRow(
													temp_stringlist,
													compColumns,
													"comptable" + compCounter.toString()
												)}
											</tbody>
										</table>
									</div>
								) : null}
							</>
						)
						inOpColumns[0]?.cells.push({
							value:
								comp.in_operation?.sensor +
								";;data_sources;;" +
								dSindex.toString() +
								";;nodes;;" +
								nodeIndex.toString() +
								";;components;;" +
								compIndex.toString() +
								";;in_operation;;" +
								"sensor",
							disabled: false,
						})
						inOpColumns[1]?.cells.push({
							value:
								comp.in_operation?.threshold?.toString() +
								";;data_sources;;" +
								dSindex.toString() +
								";;nodes;;" +
								nodeIndex.toString() +
								";;components;;" +
								compIndex.toString() +
								";;in_operation;;" +
								"threshold",
							disabled: false,
						})
						inOpColumns[2]?.cells.push({
							value:
								comp.in_operation?.greaterThan?.toString() +
								";;data_sources;;" +
								dSindex.toString() +
								";;nodes;;" +
								nodeIndex.toString() +
								";;components;;" +
								compIndex.toString() +
								";;in_operation;;" +
								"greaterThan",
							disabled: false,
						})
						inOpColumns[3]?.cells.push({
							value:
								comp.in_operation?.always?.toString() +
								";;data_sources;;" +
								dSindex.toString() +
								";;nodes;;" +
								nodeIndex.toString() +
								";;components;;" +
								compIndex.toString() +
								";;in_operation;;" +
								"always",
							disabled: false,
						})
						const inop_stringlist: string[] = []
						const inOpHeaders: string[] = []
						inOpColumns.forEach((col, colIndex) => {
							inOpHeaders.push(col.label)
							const colCellsCompCounter = col.cells[compCounter]
							if (colCellsCompCounter !== undefined) {
								inop_stringlist.push(colCellsCompCounter.value)
							}
						})
						compCounter = compCounter + 1
						local_dsRowElements.push(
							<>
								{showDsList[dSindex] !== undefined ? (
									<div className="ml-12">
										<h1 className="text-title5">{"In operation: "}</h1>
										<table className="w-full">
											<thead>
												<tr>
													{inOpHeaders.map((header, index) => (
														<th
															key={
																"inop" +
																compCounter.toString() +
																index.toString()
															}
														>
															{header}
														</th>
													))}
												</tr>
											</thead>
											<tbody>
												{makeRow(
													inop_stringlist,
													inOpColumns,
													"inoptable" + compCounter.toString()
												)}
											</tbody>
										</table>
									</div>
								) : null}
							</>
						)

						sensorColumns.forEach((column) => (column.cells = []))
						comp.sensors.map((sensor, sensorIndex) => {
							sensorColumns[0]?.cells.push({
								value:
									sensor.name +
									";;data_sources;;" +
									dSindex.toString() +
									";;nodes;;" +
									nodeIndex.toString() +
									";;components;;" +
									compIndex.toString() +
									";;sensors;;" +
									sensorIndex.toString() +
									";;name",
								disabled: false,
							})
							sensorColumns[1]?.cells.push({
								value:
									sensor.ias_registringy_index?.toString() +
									";;data_sources;;" +
									dSindex.toString() +
									";;nodes;;" +
									nodeIndex.toString() +
									";;components;;" +
									compIndex.toString() +
									";;sensors;;" +
									sensorIndex.toString() +
									";;ias_registringy_index",
								disabled: false,
							})
							sensorColumns[2]?.cells.push({
								value:
									sensor.node_identifier +
									";;data_sources;;" +
									dSindex.toString() +
									";;nodes;;" +
									nodeIndex.toString() +
									";;components;;" +
									compIndex.toString() +
									";;sensors;;" +
									sensorIndex.toString() +
									";;node_identifier",
								disabled: false,
							})
							const tmp2: string[] = []
							if (sensor.aggregation_list !== undefined) {
								sensor.aggregation_list.map((streng, idx) => {
									tmp2.push(streng)
								})
							}
							sensorColumns[3]?.cells.push({
								value:
									JSON.stringify(tmp2) +
									";;data_sources;;" +
									dSindex.toString() +
									";;nodes;;" +
									nodeIndex.toString() +
									";;components;;" +
									compIndex.toString() +
									";;sensors;;" +
									sensorIndex.toString() +
									";;aggregation_list",
								disabled: false,
							})
						})
						const temp_stringlist2: string[][] = []
						const sensorHeaders: string[] = []
						// TODO: add aggregation_list
						sensorColumns.forEach((col, colIndex) => {
							sensorHeaders.push(col.label)
							col.cells.forEach((cell, rowIndex) => {
								if (rowIndex >= temp_stringlist2.length) {
									temp_stringlist2.push([])
								}
								const temp_stringlist2_rowIndex = temp_stringlist2[rowIndex]
								if (temp_stringlist2_rowIndex !== undefined) {
									temp_stringlist2_rowIndex.push(cell.value)
								}
							})
						})
						const tmp: ReactElement[] = []
						temp_stringlist2.forEach((row, y) => {
							tmp.push(
								makeRow(
									row,
									sensorColumns,
									"sensortable" + sensorCounter.toString()
								)
							)
							sensorCounter += 1
						})

						local_dsRowElements.push(
							<>
								{showDsList[dSindex] !== undefined ? (
									<div className="ml-12">
										<h1 className="text-title3">{"Sensors: "}</h1>
										<table className="w-full">
											<thead>
												<tr>
													{sensorHeaders.map((header, index) => (
														<th
															key={
																"sensor" +
																sensorCounter.toString() +
																index.toString()
															}
														>
															{header}
														</th>
													))}
												</tr>
											</thead>
											<tbody>{tmp}</tbody>
										</table>
									</div>
								) : null}
							</>
						)
					})
				})
			})
		}
		const local_camColumns: Column[] = []
		local_camColumns.push(
			{
				cells: [],
				label: "Name",
				type: ColumnType.VarText,
			},
			{
				cells: [],
				label: "URL",
				type: ColumnType.VarText,
			}
		)

		if (
			in_configProps != undefined &&
			in_configProps.stream != undefined &&
			in_configProps.stream.cam_sources != undefined
		) {
			in_configProps.stream.cam_sources.map((camSource, index) => {
				local_camColumns[0]?.cells.push({
					value: camSource.name + ";;stream;;cam_sources;;" + index.toString() + ";;name",
					disabled: false,
				})
				local_camColumns[1]?.cells.push({
					value: camSource.url + ";;stream;;cam_sources;;" + index.toString() + ";;url",
					disabled: false,
				})
			})
		}

		local_camColumns.forEach((col, colIndex) => {
			col.cells.forEach((cell, rowIndex) => {
				if (rowIndex >= camDataRows.length) {
					camDataRows.push([])
				}
				const camDataRows_rowIndex = camDataRows[rowIndex]
				if (camDataRows_rowIndex !== undefined) {
					camDataRows_rowIndex.push(cell.value)
				}
			})
		})

		const local_vnccolumns: Column[] = [
			{
				cells: [],
				label: "Name",
				type: ColumnType.VarText,
			},
			{
				cells: [],
				label: "IP",
				type: ColumnType.VarText,
			},
			{
				cells: [],
				label: "PORT",
				type: ColumnType.VarText,
			},
		]

		if (
			in_configProps != undefined &&
			in_configProps.stream != undefined &&
			in_configProps.stream.vnc_sources != undefined
		) {
			let local_counter = 0
			in_configProps.stream.vnc_sources.map((vncSource, index) => {
				local_vnccolumns[0]?.cells.push({
					value: vncSource.name + ";;stream;;vnc_sources;;" + index.toString() + ";;name",
					disabled: false,
				})
				local_vnccolumns[1]?.cells.push({
					value: vncSource.ip + ";;stream;;vnc_sources;;" + index.toString() + ";;ip",
					disabled: false,
				})
				local_vnccolumns[2]?.cells.push({
					value:
						vncSource.port?.toString() +
						";;stream;;vnc_sources;;" +
						index.toString() +
						";;port",
					disabled: false,
				})
				local_counter += 1
			})
			setVncCounter(local_counter)
		}

		local_vnccolumns.forEach((col, colIndex) => {
			col.cells.forEach((cell, rowIndex) => {
				if (rowIndex >= vncDataRows.length) {
					vncDataRows.push([])
				}
				const vncDataRows_rowIndex = vncDataRows[rowIndex]
				if (vncDataRows_rowIndex !== undefined) {
					vncDataRows_rowIndex.push(cell.value)
				}
			})
		})
		setCamColumns(local_camColumns)
		setVncColumns(local_vnccolumns)
		return local_dsRowElements
	}

	function makeCell(col: Column, row: EditableTableRow, value_in: string): ReactNode {
		let cell: ReactElement | undefined
		const split_str = value_in.split(";;")
		const value = split_str[0]
		if (value === undefined) {
			return null
		}
		const [, ...rest] = split_str
		const cellId = rest.join(";;")
		const type = col.type
		if (type == ColumnType.VarText) {
			cell = (
				<input
					className="w-full"
					id={cellId}
					placeholder="(No value)"
					defaultValue={value}
					autoComplete="nope"
					type="text"
				/>
			)
			const editableCell = new EditableTableCell(cellId, value, () =>
				readElementValue(cellId)
			)
			row.cells.push(editableCell)
		} else if (type == ColumnType.Select) {
			const options: ReactElement[] = []
			col.alternatives?.forEach((alt) => {
				options.push(
					<option key={options.length.toString()} value={alt.value}>
						{alt.label}
					</option>
				)
			})
			cell = (
				<select className="w-full" id={cellId} defaultValue={value}>
					{options}
				</select>
			)
			const editableCell = new EditableTableCell(col.label, value, () =>
				readElementValue(cellId)
			)
			row.cells.push(editableCell)
		} else if (type == ColumnType.VarBool) {
			cell = <input id={cellId} defaultChecked={value == "true"} type={"checkbox"} />
			const editableCell = new EditableTableCell(cellId, value, () => {
				return (document.getElementById(cellId) as HTMLInputElement).checked.toString()
			})
			row.cells.push(editableCell)
		} else if (type == ColumnType.MultiSelect) {
			let values = JSON.parse(value) as string[]
			const valuesToString = () => {
				values.sort()
				return JSON.stringify(values)
			}
			cell = (
				<MultiSelectWrapper
					alternatives={col.alternatives ?? []}
					initialValues={values}
					onChange={(newValues) => (values = newValues)}
				/>
			)
			const editableCell = new EditableTableCell(cellId, valuesToString(), () =>
				valuesToString()
			)
			row.cells.push(editableCell)
		}
		if (cell == undefined) {
			cell = <>{value}</>
		}
		return <td>{cell!}</td>
	}

	function makeRow(data: string[], columns: Column[], name: string): ReactElement {
		const editableRow = new EditableTableRow(name)
		return (
			<tr key={name}>
				{data.map((value, x) => {
					const columnX = columns[x]
					if (columnX === undefined) {
						return null
					}
					return (
						<Fragment key={name + x.toString()}>
							{makeCell(columnX, editableRow, value)}
						</Fragment>
					)
				})}
			</tr>
		)
	}

	const camRowElements: ReactElement[] = []
	function generateCamRows(counter: number): ReactElement[] {
		camDataRows.forEach((row, y) => {
			camRowElements.push(
				makeRow(row, camColumns, "camtable" + counter.toString() + y.toString())
			)
		})
		return camRowElements
	}
	function generateVNCROWS(counter: number): ReactElement[] {
		vncDataRows.forEach((row, y) => {
			vncRowElements.push(
				makeRow(
					row,
					vncColumns,
					"vnctable" + counter.toString() + y.toString() + vncCounter.toString()
				)
			)
		})
		return vncRowElements
	}

	useEffect(() => {
		setConfigProps(props)
		const dsssssrows = initStuff(configProps)
		setDSRowElements(dsssssrows)
		setVncRowElemState([])
		const vncrows = generateVNCROWS(vncCounter)
		setVncRowElemState(vncrows)
		const camrows = generateCamRows(vncCounter)
		setCamRowElemState(camrows)
		setVncCounter(vncCounter + 1)
	}, [props, showDsList, configProps])

	return (
		<>
			{props === undefined ? (
				<p>No device chosen or no config available</p>
			) : props === null ? (
				<p>No config available for that device</p>
			) : (
				<>
					Version: {JSON.stringify(props["version"])}
					<br></br>
					<h2 className="text-title2">Datasources:</h2>
					{dsRowElements}
					<br></br>
					{props.stream != undefined ? (
						<>
							<br></br>
							<h2 className="text-title2">Streams:</h2>
							General settings: <br></br>
							Server adr: {props.stream.dipai_server_adr}:
							{props.stream.dipai_server_port}
							<br></br>
							<>
								<div className="mt-6 mb-6">
									<h1 className="text-title3">{"VNC streams"}</h1>
									<table className="w-full">
										<thead>
											<tr>
												{vncColumns.map((col, index) => (
													<th key={"vncc" + index.toString()}>
														{col.label}
													</th>
												))}
											</tr>
										</thead>
										<tbody>{vncRowElemState}</tbody>
									</table>
								</div>
								<div className="mt-6 mb-6">
									<h1 className="text-title3">{"Cam streams"}</h1>
									<table className="w-full">
										<thead>
											<tr>
												{camColumns.map((col, index) => (
													<th key={"camm" + index.toString()}>
														{col.label}
													</th>
												))}
											</tr>
										</thead>
										<tbody>{camRowElemState}</tbody>
									</table>
								</div>
							</>
						</>
					) : (
						"no stream data"
					)}
				</>
			)}
		</>
	)
}
