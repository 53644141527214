import { TableState } from "@tanstack/react-table"
import { ReactElement } from "react"
import { useTranslation } from "react-i18next"

type PaginationIconId = "first" | "previous" | "next" | "last" | "down"
export const PaginationIcon = (iconId: PaginationIconId): ReactElement => {
	let path: string
	switch (iconId) {
		case "first":
			path = "M11 19l-7-7 7-7m8 14l-7-7 7-7"
			break
		case "previous":
			path = "M15 19l-7-7 7-7"
			break
		case "next":
			path = "M9 5l7 7-7 7"
			break
		case "last":
			path = "M13 5l7 7-7 7M5 5l7 7-7 7"
			break
		case "down":
			path = "M9 5l7 7-7 7"
			break
	}

	return (
		<svg
			className="w-4 h-4 m-auto"
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
			transform={iconId === "down" ? "rotate(90)" : ""}
		>
			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={path} />
		</svg>
	)
}

type PaginationButtonProps = {
	disabledCondition: boolean
	iconId: PaginationIconId
	onClickHandler: () => void
	positionRef: string
}

export const PaginationButton = (props: PaginationButtonProps): ReactElement => {
	return (
		<button
			onClick={props.onClickHandler}
			disabled={!props.disabledCondition}
			className={`relative ${
				props.positionRef === "right" ? "right-0" : "left-0"
			} absolute h-8 w-8 text-dipai-secondary-800/50 hover:cursor-pointer hover:text-dipai-secondary-800/100 disabled:text-dipai-secondary-800/30 disabled:hover:cursor-default`}
		>
			{PaginationIcon(props.iconId)}
		</button>
	)
}

export type PaginationControlsProps = {
	getCanNextPage: () => boolean
	getCanPreviousPage: () => boolean
	getPageCount: () => number
	nRecords: number
	nextPage: () => void
	previousPage: () => void
	setPageIndex: (updater: number | ((pageIndex: number) => number)) => void
	setPageSize: (pageSize: number) => void
	tableState: TableState
	translationFunc: ReturnType<typeof useTranslation>["t"]
}

export function PaginationControls(props: PaginationControlsProps): ReactElement {
	return (
		<div
			className={`flex h-[50px] w-full flex-row items-center justify-end gap-12 bg-white px-4`}
		>
			{/* LEFT AREA */}
			<div className="flex flex-row items-center gap-4">
				{/* AMOUNT TO DISPLAY DROPDOWN */}
				<span>{props.translationFunc("ROWS PER PAGE")}</span>
				<select
					value={props.tableState.pagination.pageSize}
					className="h-8 w-[90px] rounded-sm border-1 border-gray-ccc py-[2px] text-small hover:border-alert-focus focus:border-2 focus:border-alert-focus focus:outline-hidden focus:ring-0"
					onChange={(e) => props.setPageSize(Number(e.target.value))}
				>
					{[10, 25, 50].map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							{pageSize}
						</option>
					))}
				</select>
			</div>
			<span>{`${
				1 + props.tableState.pagination.pageSize * props.tableState.pagination.pageIndex
			} - ${
				props.tableState.pagination.pageSize +
					props.tableState.pagination.pageSize * props.tableState.pagination.pageIndex <=
				props.nRecords
					? props.tableState.pagination.pageSize +
					  props.tableState.pagination.pageSize * props.tableState.pagination.pageIndex
					: props.nRecords
			} of ${props.nRecords}`}</span>
			{/* CENTRAL AREA */}
			<div className="grid grid-cols-7 gap-2">
				{/* TO FIRST PAGE */}
				<PaginationButton
					disabledCondition={props.getCanPreviousPage()}
					onClickHandler={() => props.setPageIndex(0)}
					iconId={"first"}
					positionRef={"right"}
				/>
				{/* TO PREVIOUS PAGE */}
				<PaginationButton
					disabledCondition={props.getCanPreviousPage()}
					onClickHandler={() => props.previousPage()}
					iconId={"previous"}
					positionRef={"right"}
				/>
				{/* CURRENT PAGE DISPLAY */}
				<div className="h-8 col-span-3 text-center">
					<h1 className=" inline-block h-8 py-[4px] text-menu">
						{`${props.translationFunc("PAGE")} ${
							props.tableState.pagination.pageIndex + 1
						} ${props.translationFunc("OF")} ${props.getPageCount()}`}
					</h1>
				</div>
				{/* TO NEXT PAGE */}
				<PaginationButton
					disabledCondition={props.getCanNextPage()}
					onClickHandler={() => props.nextPage()}
					iconId={"next"}
					positionRef={"left"}
				/>
				{/* TO LAST PAGE */}
				<PaginationButton
					disabledCondition={props.getCanNextPage()}
					onClickHandler={() => props.setPageIndex(props.getPageCount() - 1)}
					iconId={"last"}
					positionRef={"left"}
				/>
			</div>
		</div>
	)
}
