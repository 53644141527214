import { decimalDegreesToDMS } from "@helpers/decimalDegreesToDMS"
import { PositionDataInfoLine } from "./PositionDataInfoLine"

export function CoordLine(lat: string | undefined, lon: string | undefined) {
	if (lat === undefined || lon === undefined) {
		return null
	}
	const latDirection = parseFloat(lat) >= 0 ? "N" : "S"
	const lonDirection = parseFloat(lon) >= 0 ? "E" : "W"
	const dmsLat = decimalDegreesToDMS(Math.abs(parseFloat(lat)), false)
	const dmsLon = decimalDegreesToDMS(Math.abs(parseFloat(lon)), true)

	return <PositionDataInfoLine text={`${latDirection} ${dmsLat}  ${lonDirection} ${dmsLon}`} />
}
