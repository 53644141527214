import { ConfigurationModule } from "api"
import { ReactElement, useState } from "react"
import DataEditPage from "./DataEditPage"
import DbConfigModuleKeySelector from "./DbConfigModuleKeySelector"

export default function DbConfigModulePage(props: {
	module: ConfigurationModule
	keySelectLabel: string
}): ReactElement {
	const [selectedSchema, setSelectedSchema] = useState<string>("")

	return (
		<>
			<DbConfigModuleKeySelector
				label={props.keySelectLabel}
				onSetKey={setSelectedSchema}
				module={props.module}
			/>
			{selectedSchema == undefined || selectedSchema == "" ? undefined : (
				<DataEditPage module={props.module} _key={selectedSchema} />
			)}
		</>
	)
}
