import { VariantProps, cva } from "class-variance-authority"
import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react"
import { cn } from "../../lib/utils"
import { ButtonIcon } from "./ButtonIcon"
import { ButtonLabel } from "./ButtonLabel"

const buttonVariants = cva(
	"inline-flex w-fit space-x-2 items-center justify-center rounded-sm text-small font-medium transition-colors focus:outline-hidden focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none data-[state=open]:bg-slate-100",
	{
		variants: {
			company: {
				dipai: "",
				soelvtrans: "",
			},
			variant: {
				"filled-primary": "",
				"filled-secondary": "",
				"outline-light": "",
				"outline-dark": "",
				"text-light": "",
				"text-dark": "",
				destructive: "bg-red-500 text-white hover:bg-red-600",
			},
			size: {
				sm: "h-6 px-4",
				medium: "h-8 px-6 font-semibold",
				lg: "h-10 px-8 font-bold",
			},
		},
		compoundVariants: [
			// DIPAI
			{
				variant: "filled-primary",
				company: "dipai",
				className:
					"bg-dipai-primary-501 hover:bg-dipai-primary-400 text-dipai-secondary-700 hover:text-dipai-secondary-901",
			},
			{
				variant: "filled-secondary",
				company: "dipai",
				className: "bg-dipai-secondary-800 text-white hover:bg-dipai-secondary-901",
			},
			{
				variant: "outline-light",
				company: "dipai",
				className:
					"bg-transparent text-white hover:text-dipai-primary-501 border-1 border-white hover:border-dipai-primary-501 hover:bg-white/20",
			},
			{
				variant: "outline-dark",
				company: "dipai",
				className:
					"bg-transparent text-dipai-secondary-800 hover:text-dipai-secondary-901 hover:bg-dipai-secondary-100 border-1 border-dipai-secondary-800 hover:border-dipai-secondary-901",
			},
			{
				variant: "text-light",
				company: "dipai",
				className:
					"bg-transparent text-white hover:text-dipai-primary-501 hover:bg-white/20",
			},
			{
				variant: "text-dark",
				company: "dipai",
				className:
					"bg-transparent text-dipai-secondary-800 hover:text-dipai-secondary-901 hover:bg-dipai-secondary-100",
			},
		],
		defaultVariants: {
			company: "dipai",
			variant: "filled-primary",
			size: "medium",
		},
	}
)

export interface IButtonProps
	extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	blockSubmit?: boolean
}

const Button = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
	const { className, children, variant, size, company, blockSubmit, ...rest } = props

	return (
		<button
			type={blockSubmit === false ? "submit" : "button"}
			className={cn(buttonVariants({ variant, size, company, className }))}
			ref={ref}
			{...rest}
		>
			{children}
		</button>
	)
})

const ButtonNamespace = Object.assign(Button, {
	Icon: ButtonIcon,
	Label: ButtonLabel,
})
export { ButtonNamespace as Button, buttonVariants }
