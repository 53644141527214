import BarLoader from "react-spinners/BarLoader";

export type TableLoadingBarProps = {
    loading: boolean;
};

export interface GenericTableLoadingBarProps extends TableLoadingBarProps {
    companyColors: { loadingBar: string };
}

export function TableLoadingBar(props: GenericTableLoadingBarProps) {
    return (
        <>
            {props.loading ? (
                <BarLoader
                    color={props.companyColors.loadingBar}
                    loading={props.loading}
                    cssOverride={{ display: "block", margin: "0 auto" }}
                    width={"100%"}
                />
            ) : (
                <div className={`h-[4px] w-full `} />
            )}
        </>
    );
}
