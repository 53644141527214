import useGetLiveOpModeQuery from "@queries/useGetLiveOpModeQuery"
import useVesselInfoOperationQuery from "@queries/useVesselInfoOperationQuery"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import {
	getListOfVessels,
	PlantInfoFE,
	setListOfVessels,
} from "@redux/features/listOfVessels/listOfVesselsSlice"
import {
	getPanelState,
	updateSidePanelState,
} from "@redux/features/mapSidePanelControl/mapSidePanelControlSlice"
import { TableMapPage } from "@ui-lib/src"
import { common } from "@utils/common"
import {
	getGetVesselInfoArgs,
	getOpModeGetLiveArgs,
	listPlantsPerCompany,
} from "@utils/listPlantsPerCompany"
import { AppStructure, CustomerData } from "api"
import { ReactElement, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { TableBodyMapPage } from "../BodyContentLayout"
import { useOpModeTableColumns } from "./useOpModeTableColumns"
import { getListOfEvents, prepareTableData } from "./utils"

export const OPERATION_PORTAL_COMPANIES = ["Sølvtrans"]

type ListOfPlantsArgs = {
	props: AppStructure
}

function ComponentBody(
	props: ListOfPlantsArgs & { companyName: string; companyData: CustomerData }
) {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const panelState = useAppSelector(getPanelState)

	const listOfVessels = useAppSelector(getListOfVessels)
	const plantsPerCompany = listPlantsPerCompany({
		...props.companyData.clients,
		[props.companyName]: props.companyData,
	})

	for (const plant in plantsPerCompany) {
		!OPERATION_PORTAL_COMPANIES.includes(plant) && delete plantsPerCompany[plant]
	}

	const plantsPerCompanyFiltered = plantsPerCompany
	const filter = undefined
	const postDataVesselInfo = getGetVesselInfoArgs(plantsPerCompanyFiltered)
	const postDataOpModeLive = getOpModeGetLiveArgs(plantsPerCompanyFiltered)

	const {
		isLoading: isPositionDataLoading,
		isError: isPositionDataError,
		data: positionDataTemp,
	} = useVesselInfoOperationQuery({
		postData: postDataVesselInfo,
		plantsPerCompany: plantsPerCompanyFiltered,
		filter,
	})

	const {
		isLoading: isLiveOpModeDataLoading,
		isError: isLiveOpModeDataError,
		data: liveOpModeDataTemp,
	} = useGetLiveOpModeQuery({
		postData: postDataOpModeLive,
		plantsPerCompany: plantsPerCompanyFiltered,
		filter,
	})

	useEffect(() => {
		const processVesselsData = () => {
			if (
				positionDataTemp === undefined ||
				liveOpModeDataTemp === undefined ||
				liveOpModeDataTemp === null ||
				isPositionDataError === true ||
				isLiveOpModeDataError === true
			) {
				return
			}

			const positionDataTempCopy: PlantInfoFE[] = JSON.parse(JSON.stringify(positionDataTemp))

			for (const positionData of positionDataTempCopy) {
				for (const data of liveOpModeDataTemp.plants) {
					if (
						common.plantDataFromOwnerAndName.get(
							`sølvtrans/${positionData.name?.toLowerCase()}`
						)?.id === data.plantId
					) {
						positionData["latestOperationMode2"] = data
					}
				}
			}

			dispatch(
				setListOfVessels({
					loaded: true,
					vessels: positionDataTempCopy,
				})
			)
		}

		processVesselsData()
	}, [positionDataTemp, liveOpModeDataTemp])

	const data = useMemo(() => prepareTableData(listOfVessels), [listOfVessels])

	const listOfEvents: string[] = getListOfEvents(listOfVessels.vessels ?? [])
	const columns = useOpModeTableColumns(listOfEvents)

	return (
		<TableMapPage
			translationFunc={t}
			company="soelvtrans"
			buttonFunction={() =>
				dispatch(
					updateSidePanelState({
						...panelState,
						isTableExpanded: !panelState.isTableExpanded,
					})
				)
			}
			TableBody={TableBodyMapPage}
			columnsLayout={
				"grid-cols-[16px_200px_min(calc(100%/7),calc((100%-311px)/3))_auto_min(calc(100%/7),calc((100%-311px)/3))_87px]"
			}
			columns={columns}
			data={data}
			showGlobalFilter={false}
			itemsPerPage={10}
			loading={!listOfVessels.loaded}
		/>
	)
}
export default function HomePageListOfOperationPortalPlants({
	props,
}: ListOfPlantsArgs): ReactElement {
	const companyName = Object.keys(props)[0]
	if (companyName === undefined) {
		return <div> {`Server error: unkown company`}</div>
	}
	const companyData = props[companyName]
	if (companyData === undefined) {
		return <div> {`Server error: unkown company`}</div>
	}
	return <ComponentBody props={props} companyData={companyData} companyName={companyName} />
}
