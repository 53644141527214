import { common } from "@utils/common"
import { memo, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdOutlineNavigation, MdOutlineThermostat } from "react-icons/md"
import { PiWaveSine } from "react-icons/pi"

import { TbRulerMeasure } from "react-icons/tb"
import { CompassDraw, CompassTimeseriesData, CompassTimeseriesDataUniSea } from "./compassDrawClass"
import { weatherSensorsUniSeaDict } from "./utils"

interface CanvasProps {
	width?: number
	time: number | undefined
	data: CompassTimeseriesData | CompassTimeseriesDataUniSea | undefined
	heading: number | null | undefined
	t1: Date
}

type CompassDisplayData = {
	sensorId: number | string
	value: number
	name: string | undefined
	unit: string | undefined
	max: number
	available: boolean
}[]

const CompassCurrent = ({ width = window.innerWidth, time, data, heading, t1 }: CanvasProps) => {
	const { t } = useTranslation()
	const canvasWidth = 200
	const canvasHeight = 200

	const canvasRef = useRef<HTMLCanvasElement | null>(null)
	const bgRef = useRef<HTMLCanvasElement | null>(null)
	const currentColor = "#00a8ff" //"#00a8ff"
	const waveColor = "#332288"
	const windWaveColor = "#f47300" //"#E4329D"
	const center: [number, number] = [canvasHeight / 2, width / 2]
	const size: number = 68

	//const [relSpeed, setRelSpeed] = useState<number>(0)
	//const [trueSpeed, setTrueSpeed] = useState<number>(0)
	//const [stormSpeed, setStormSpeed] = useState<number>(0)
	//const [displayData, setDisplayData] = useState<CompassDisplayData>()

	//const [displayStorm, setDisplayStorm] = useState<boolean>(false)
	const [dataNotAvailable, setDataNotAvailable] = useState<boolean>(true)

	const [airTemperatureAvailable, setAirTemperatureAvailable] = useState<boolean>(false)
	const [airTemperature, setAirTemperature] = useState<number>(0)
	const [waterTempAvailable, setWaterTempAvailable] = useState<boolean>(false)
	const [waterTempemperature, setWaterTemperature] = useState<number>(0)
	const [waveDirectionAvailable, setWaveDirectionAvailable] = useState<boolean>(false)
	const [waveDirection, setWaveDirection] = useState<number>(0)
	const [windWaveDirectionAvailable, setWindWaveDirectionAvailable] = useState<boolean>(false)
	const [windWaveDirection, setWindWaveDirection] = useState<number>(0)
	const [windWaveHeightAvailable, setWindWaveHeightAvailable] = useState<boolean>(false)
	const [windWaveHeight, setWindWaveHeight] = useState<number>(0)
	const [windWavePeriodAvailable, setWindWavePeriodAvailable] = useState<boolean>(false)
	const [windWavePeriod, setWindWavePeriod] = useState<number>(0)
	const [wavePeriodAvailable, setWavePeriodAvailable] = useState<boolean>(false)
	const [wavePeriod, setWavePeriod] = useState<number>(0)
	const [waveHeightAvailable, setWaveHeightAvailable] = useState<boolean>(false)
	const [waveHeight, setWaveHeight] = useState<number>(0)
	const [currentSpeedAvailable, setCurrentSpeedAvailable] = useState<boolean>(false)
	const [currentSpeed, setCurrentSpeed] = useState<number>(0)
	const [currentDirectionAvailable, setCurrentDirectionAvailable] = useState<boolean>(false)
	const [currentDirection, setCurrentDirection] = useState<number>(0)
	const [windWaveLength, setWindWaveLength] = useState<number>(0)
	const [windWaveSpeed, setWindWaveSpeed] = useState<number>(0)

	const [vesselSensorAvailable, setVesselSensorAvailable] = useState<boolean>(false)
	const [stormGlassAvailable, setStormGlassAvailable] = useState<boolean>(false)

	const avail = useRef<boolean>(false)
	const valueRef = useRef<number>(0)
	const maxRef = useRef<number>(0)
	const maxWaveHeightRef = useRef<number>(3)
	const maxCurrentSpeedRef = useRef<number>(2)

	//const [timeSelected, setTimeSelected] = useState<number>(new Date(t1).getTime())
	const timeSelected = time ?? new Date(t1).getTime()
	//requestAnimationFrame(() => {})

	const render = () => {
		const ctx = canvasRef.current?.getContext("2d") ?? null

		if (ctx == null) {
			return
		}
		const displayDataTemp: CompassDisplayData = []

		if (data != undefined) {
			for (const dataItem of data) {
				const valueData = data.find((item) => item.sensorId == dataItem.sensorId)
				const valueDataSorted = valueData?.data.sort((a, b) => a.timestamp - b.timestamp)
				if (valueDataSorted != undefined && valueData != undefined) {
					const values = valueDataSorted.map((item) => item.value)
					maxRef.current = Math.ceil(Math.max(...values))

					const last = valueDataSorted[valueDataSorted.length - 1]
					const first = valueDataSorted[0]
					if (last !== undefined) {
						if (timeSelected === undefined && valueDataSorted != undefined) {
							valueRef.current = first?.value ?? 0
						}

						//CHECK IF WEATHERDATA IS UP TO DATE
						if (timeSelected != undefined && last.timestamp + 3600000 < timeSelected) {
							avail.current = false
							setDataNotAvailable(true)
						} else {
							avail.current = true
							setDataNotAvailable(false)
						}

						if (timeSelected != undefined) {
							const datapointIndex = valueDataSorted.findIndex(
								(dataPoint) => dataPoint.timestamp > timeSelected
							)

							const valueDataAtSearch = valueDataSorted[datapointIndex]
							const valueDataPrev = valueDataSorted[datapointIndex - 1]

							if (valueDataAtSearch != undefined && valueDataPrev != undefined) {
								const t1diff = valueDataAtSearch.timestamp - timeSelected
								const t2diff = timeSelected - valueDataPrev.timestamp

								if (t1diff < t2diff) {
									valueRef.current = valueDataAtSearch.value
								} else {
									valueRef.current = valueDataPrev.value
								}
							} else {
								if (last.timestamp < timeSelected) {
									valueRef.current = last?.value ?? 0
								} else {
									valueRef.current = first?.value ?? 0
								}
							}
						}
					}
				}
				if (avail.current != false) {
					displayDataTemp.push({
						sensorId: dataItem.sensorId,
						name:
							typeof dataItem.sensorId === "string"
								? weatherSensorsUniSeaDict.find(
										(weatherSignal) => weatherSignal.id === dataItem.sensorId
								  )?.label ?? "unknown sensor"
								: common.sensorDataFromIds.get(dataItem.sensorId)?.display_name,
						unit:
							typeof dataItem.sensorId === "string"
								? weatherSensorsUniSeaDict.find(
										(weatherSignal) => weatherSignal.id === dataItem.sensorId
								  )?.unit ?? "unkown unit"
								: common.sensorDataFromIds.get(dataItem.sensorId)?.unit,
						value: valueRef.current,
						max: maxRef.current,
						available: avail.current,
					})
				}
			}
		}

		//Sensors available :
		// const windSpeed = displayDataTemp?.find(
		// 	(avail) => avail.name == "Wind Speed (10m)"
		// )

		//FEIL SENSOR HER MÅ VI FÅ WIND WAVE DIRECTION INN!!

		// const windDirectionTemp = displayDataTemp?.find((avail) => avail.name == "Wind Direction")

		// if (windDirectionTemp?.available != undefined) {
		// 	setWindWaveDirectionAvailable(true)
		// 	setWindWaveDirection(windDirectionTemp.value)
		// } else {
		// 	setWindWaveDirectionAvailable(false)
		// }

		// const relativeSpeed = displayDataTemp?.find(
		// 	(avail) => avail.name == "Relative Wind Speed"
		// )
		// const relativeWind = displayDataTemp?.find(
		// 	(avail) => avail.name == "Relative Wind Direction"
		// )
		// const trueSpeed = displayDataTemp?.find(
		// 	(avail) => avail.name == "True Wind Speed"
		// )
		// const trueWind = displayDataTemp?.find(
		// 	(avail) => avail.name == "True Wind Direction"
		// )

		const airTemperatureTemp = displayDataTemp?.find((value) => value.name == "Air Temperature")

		if (airTemperatureTemp?.available != undefined) {
			setAirTemperatureAvailable(true)
			setAirTemperature(airTemperatureTemp.value)
		} else {
			setAirTemperatureAvailable(false)
		}

		const waterTemperatureTemp = displayDataTemp?.find(
			(avail) => avail.name == "Water Temperature"
		)

		if (waterTemperatureTemp?.available != undefined) {
			setWaterTempAvailable(true)
			setWaterTemperature(waterTemperatureTemp.value)
		} else {
			setWaterTempAvailable(false)
		}

		//WIND WAVES
		const windWaveHeightTemp = displayDataTemp?.find(
			(avail) => avail.name == "Wind Wave Height"
		)
		if (windWaveHeightTemp != undefined) {
			setWindWaveHeightAvailable(true)
			setWindWaveHeight(windWaveHeightTemp.value)
		} else {
			setWindWaveHeightAvailable(false)
		}

		const windWavePeriodTemp = displayDataTemp?.find(
			(avail) => avail.name === "Wind Wave Period"
		)
		if (windWavePeriodTemp !== undefined) {
			setWindWavePeriodAvailable(true)
			setWindWavePeriod(windWavePeriodTemp.value)
		} else {
			setWindWavePeriodAvailable(false)
		}

		const windWaveDirectionTemp = displayDataTemp?.find(
			(avail) => avail.name == "Wind Wave Direction"
		)
		if (windWaveDirectionTemp != undefined) {
			setWindWaveDirectionAvailable(true)
			setWindWaveDirection(windWaveDirectionTemp.value)
		} else {
			setWindWaveDirectionAvailable(false)
		}

		//WAVES

		const waveHeightTemp = displayDataTemp?.find((avail) => avail.name == "Wave Height")

		if (waveHeightTemp?.available != undefined) {
			setWaveHeightAvailable(true)
			setWaveHeight(waveHeightTemp.value)
		} else {
			setWaveHeightAvailable(false)
		}

		const wavePeriodTemp = displayDataTemp?.find((avail) => avail.name === "Wave Period")
		if (wavePeriodTemp !== undefined) {
			setWavePeriodAvailable(true)
			setWavePeriod(wavePeriodTemp.value)
		} else {
			setWavePeriodAvailable(false)
		}

		const waveDirectionTemp = displayDataTemp?.find((avail) => avail.name == "Wave Direction")
		if (waveDirectionTemp != undefined) {
			setWaveDirectionAvailable(true)
			setWaveDirection(waveDirectionTemp.value)
		} else {
			setWaveDirectionAvailable(false)
		}

		// OCEAN CURRENT SPEED
		const currentSpeedTemp = displayDataTemp?.find(
			(avail) => avail.name === "Ocean Current Speed"
		)
		if (currentSpeedTemp !== undefined) {
			setCurrentSpeedAvailable(true)
			setCurrentSpeed(currentSpeedTemp.value)
		} else {
			setCurrentSpeedAvailable(false)
		}

		// OCEAN CURRENT DIRECTION
		const currentDirectionTemp = displayDataTemp?.find(
			(avail) => avail.name === "Ocean Current Direction"
		)
		if (currentDirectionTemp !== undefined) {
			setCurrentDirectionAvailable(true)
			setCurrentDirection(currentDirectionTemp.value)
		} else {
			setCurrentDirectionAvailable(false)
		}

		//calculate speed
		if (windWavePeriod != undefined) {
			setWindWaveLength((9.81 * windWavePeriod ** 2) / (2 * Math.PI))
			setWindWaveSpeed(windWaveLength / windWavePeriod)
		}

		// if (
		// 	relativeSpeed != undefined &&
		// 	relativeWind != undefined &&
		// 	trueSpeed != undefined &&
		// 	trueWind != undefined
		// ) {
		// 	setVesselSensorAvailable(true)
		// } else {
		// 	setVesselSensorAvailable(false)
		// }
		if (currentDirectionTemp != undefined && currentSpeedTemp != undefined) {
			setStormGlassAvailable(true)
		} else {
			setStormGlassAvailable(false)
		}

		// Max Wave Height
		if (windWaveHeightAvailable && waveHeightAvailable) {
			if (windWaveHeightTemp?.max != undefined && waveHeightTemp?.max != undefined) {
				const valuesToCompare: number[] = [
					windWaveHeightTemp?.max,
					waveHeightTemp?.max,
					maxWaveHeightRef.current,
				]
				const highestValue: number = Math.max(...valuesToCompare.filter((value) => value))
				maxWaveHeightRef.current = highestValue
			}
		}
		// Max current
		if (currentSpeedAvailable) {
			if (currentSpeedTemp?.max != undefined) {
				const valuesToCompare: number[] = [
					currentSpeedTemp?.max,
					maxCurrentSpeedRef.current,
				]
				const highestValue: number = Math.max(...valuesToCompare.filter((value) => value))
				maxCurrentSpeedRef.current = highestValue
			}
		}

		const enable = true

		const compassDraw = new CompassDraw(ctx)
		ctx.clearRect(0, 0, width, canvasHeight)
		//compassDraw.compass(size, center[0])

		if (!enable) {
			compassDraw.gaugeDouble(size, center[0], [10, 15], ["m/s", "m"])
			//compassDraw.waveHeight(10.2, waveColor, center[0], size, 6, 18, 1, false)
			compassDraw.gaugeDoubleLine(
				currentSpeed,
				currentColor,
				center[0],
				size,
				10,
				3.2,
				2,
				false
			)
			//compassDraw.waveHeight(17, windWaveColor, center[0], size, 10, 18, 2, true)
			compassDraw.gaugeDoubleLine(10.2, waveColor, center[0], size, 6, 18, 1, true)
			compassDraw.gaugeDoubleLine(17, windWaveColor, center[0], size, 14, 18, 1, true)
			compassDraw.gaugeDoubleVal([0.4, 17.8], "m", center[0], size, "bottom", "Wave height")
			compassDraw.gaugeDoubleVal([0.2], "m/s", center[0], size, "top", "Current speed")
		}

		compassDraw.vessel(
			heading !== null && heading !== undefined ? heading : 0,
			center[0],
			"#dddddd"
		)

		if (stormGlassAvailable != true && vesselSensorAvailable != true && enable) {
			compassDraw.noData(
				size,
				center[0],
				`${t("THIS WEATHER DATA")}`,
				`${t("HAS 2 HOURS DELAY")}`
			)
		}

		//Draw speedometer and speed value

		if (waveHeightAvailable && enable) {
			compassDraw.gaugeDouble(
				size,
				center[0],
				[maxCurrentSpeedRef.current, maxWaveHeightRef.current],
				["m/s", "m"]
			)
			if (waveHeight != undefined) {
				compassDraw.gaugeDoubleLine(
					waveHeight,
					waveColor,
					center[0],
					size,
					6,
					maxWaveHeightRef.current,
					1,
					true
				)
			}

			if (windWaveHeight != undefined) {
				compassDraw.gaugeDoubleLine(
					windWaveHeight,
					windWaveColor,
					center[0],
					size,
					14,
					maxWaveHeightRef.current,
					1,
					true
				)
			}
			if (currentSpeed != undefined) {
				compassDraw.gaugeDoubleLine(
					currentSpeed,
					currentColor,
					center[0],
					size,
					10,
					maxCurrentSpeedRef.current,
					2,
					false
				)
			}

			compassDraw.gaugeDoubleVal(
				[windWaveHeight, waveHeight],
				"m",
				center[0],
				size,
				"bottom",
				`${t("WAVE HEIGHT")}`
			)
			compassDraw.gaugeDoubleVal(
				[currentSpeed],
				"m/s",
				center[0],
				size,
				"top",
				`${t("CURRENT")}`
			)
		}

		//Wind direction ---  Draw Arrow
		if (displayDataTemp !== undefined) {
			//WaveDirection
			if (waveDirectionAvailable) {
				compassDraw.arrow(waveDirection, waveColor, center[0], size)
			}
			if (windWaveDirectionAvailable) {
				compassDraw.arrow(windWaveDirection, windWaveColor, center[0], size)
			}
			//Current Direction
			if (currentDirectionAvailable) {
				compassDraw.arrow(currentDirection, currentColor, center[0], size)
			}
		}

		//setDisplayData(displayDataTemp)

		ctx.setTransform(1, 0, 0, 1, 0, 0)
	}

	useEffect(() => {
		const canvas = canvasRef.current

		if (canvas === null) return

		const frameId = requestAnimationFrame(render)
		//if (time != undefined) setTimeSelected(time)

		return () => {
			cancelAnimationFrame(frameId)
		}
	}, [time, data, heading, stormGlassAvailable, vesselSensorAvailable]) //time, data, heading, displayTrue

	const renderBackground = () => {
		const ctx = bgRef.current?.getContext("2d") ?? null

		if (ctx == null) {
			return
		}
		const bgDraw = new CompassDraw(ctx)
		ctx.clearRect(0, 0, width, canvasHeight)
		bgDraw.compass(size, center[0])
	}

	useEffect(() => {
		const canvas = bgRef.current

		if (canvas === null) return

		const frameId = requestAnimationFrame(renderBackground)
		//if (time != undefined) setTimeSelected(time)

		return () => {
			cancelAnimationFrame(frameId)
		}
	}, [data])

	return (
		<div style={{ width: width }} className="relative flex h-[282px] flex-col px-2 text-tiny">
			<p className="mb-2 text-center border-b-1 text-menu">{`${t("CURRENT AND WAVES")}`} </p>
			<div
				className="flex flex-row gap-3"
				style={{ paddingLeft: (width - 200 - 120 - 28) / 2 }}
			>
				<div className="flex flex-col items-start">
					<div>
						<canvas
							id={"canvasCompassCurrent"}
							className="z-1 absolute h-[200px] w-[200px]"
							ref={canvasRef}
							height={canvasHeight}
							width={canvasWidth}
						/>
						<canvas
							id={"bgCanvasCompassCurrent"}
							className="z-0 h-[200px] w-[200px]"
							ref={bgRef}
							height={canvasHeight}
							width={canvasWidth}
						/>
					</div>
				</div>
				<ul className="h-auto w-[120px] space-y-1">
					<p className="font-medium">{`${t("WATER TEMPERATURE")}`} </p>
					<li className="flex items-center pl-2">
						<AirTemperatureIconMemo />
						<p className="ml-2">
							{`${
								waterTempAvailable != false ? waterTempemperature.toFixed(1) : "--"
							} °C`}{" "}
						</p>
					</li>
					<p className="font-semibold">{`${t("WAVES")}`} </p>
					<li className="flex items-center pl-2">
						<PiWaveSineIconMemo />
						<p className="ml-2">
							{`${t("HEIGHT")}: ${
								waveHeightAvailable != false ? waveHeight.toFixed(1) : "--"
							} m`}{" "}
						</p>
					</li>
					<li className="flex items-center pl-2">
						<RulerMeasureIconMemo />
						<p className="ml-2">
							{`${t("PERIOD")}: ${
								wavePeriodAvailable != false ? wavePeriod.toFixed(1) : "--"
							} s`}{" "}
						</p>
					</li>
					<p className="font-semibold">{`${t("WIND WAVES")}`} </p>
					<li className="flex items-center pl-2">
						<PiWaveSineIconMemo />
						<p className="ml-2">
							{`${t("HEIGHT")}:  ${
								windWaveHeightAvailable != false ? windWaveHeight.toFixed(1) : "--"
							} m`}{" "}
						</p>
					</li>
					<li className="flex items-center pl-2">
						<RulerMeasureIconMemo />
						<p className="ml-2">
							{`${t("PERIOD")}: ${
								windWavePeriodAvailable != false ? windWavePeriod.toFixed(1) : "--"
							} s`}{" "}
						</p>
					</li>
					<p className="font-medium">{`${t("CURRENT")}`} </p>
					<li className="flex items-center pl-2">
						<NavigationIconMemo />
						<p className="ml-2">
							{`${t("SPEED")}: ${
								currentSpeedAvailable != false ? currentSpeed.toFixed(1) : "--"
							} m/s`}{" "}
						</p>
					</li>
				</ul>
			</div>
			<ul className="absolute bottom-1">
				<li className="flex items-center gap-2">
					<div style={{ backgroundColor: currentColor }} className="h-3 w-7"></div>
					<p>
						{`${t("OCEAN CURRENT DIRECTION")} ${
							currentDirectionAvailable != false ? currentDirection.toFixed(1) : "--"
						} °`}{" "}
					</p>
				</li>
				<li className="flex items-center gap-2">
					<div style={{ backgroundColor: waveColor }} className="h-3 w-7"></div>
					<p>
						{`${t("WAVE DIRECTION")} ${
							waveDirectionAvailable != false ? waveDirection.toFixed(1) : "--"
						} °`}{" "}
					</p>
				</li>
				<li className="flex items-center gap-2">
					<div style={{ backgroundColor: windWaveColor }} className="h-3 w-7"></div>
					<p>
						{`${t("WIND WAVE DIRECTION")} ${
							windWaveDirectionAvailable != false
								? windWaveDirection.toFixed(1)
								: "--"
						} °`}{" "}
					</p>
				</li>
			</ul>
		</div>
	)
}

export default CompassCurrent

const AirTemperatureIconMemo = memo(function AirTemperatureIconMemo() {
	return <MdOutlineThermostat className=" fill-slate-700" />
})

const NavigationIconMemo = memo(function NavigationIconMemo() {
	return <MdOutlineNavigation className=" fill-slate-700" />
})
const RulerMeasureIconMemo = memo(function RulerMeasureIconMemo() {
	return <TbRulerMeasure className=" fill-slate-700" />
})
const PiWaveSineIconMemo = memo(function PiWaveSineIconMemo() {
	return <PiWaveSine className=" fill-slate-700" />
})
