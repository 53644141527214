import { TabContainer } from "@components/containers/Containers"
import SectionTitle from "@components/sectionTitle/SectionTitle"
import { SectionTitleSize, SectionTitleType } from "@components/sectionTitle/types"
import { ReactElement } from "react"

export default function EventLogContent(): ReactElement {
	return (
		<TabContainer>
			<>
				<SectionTitle
					title="ALL VESSELS"
					type={SectionTitleType.first}
					size={SectionTitleSize.large}
				/>
			</>
		</TabContainer>
	)
}
