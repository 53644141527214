import { CustomDatePickerInput } from "@components/datePicker/CustomDatePickerInput"
import { isFeatureAvailable, useGetDevFeatures } from "@components/featureGuard/FeatureGuard"
import { ModalNL } from "@components/modal/Modal"
import { CustomCalendarContainer } from "@components/plot/utils"
import { CellSetterV2 } from "@components/table/BodyContentLayoutNL"
import { PaginationIcon } from "@components/table/tableUtils"
import { LABELS_MONTHS } from "@helpers/getLabelsMonths"
import { useGetOpModesListQuery } from "@queries/useGetOpModesListQuery"
import { useAppSelector } from "@redux/app/hooks"
import { getCurrentCompany } from "@redux/features/currentCompany/currentCompanySlice"
import { getUserData } from "@redux/features/userData/userDataSlice"
import AHTSWaningPanel from "@sop/FuelPage/Total/AHTSWaningPanel"
import { formatString } from "@sop/FuelPage/Total/utils/makeLabels"
import TableBodyOperationsTimelineNL from "@sop/OperationsTimelinePage/Timeline/TableBodyOperationsTimelineNL"
import { useLocation, useMatch, useNavigate } from "@tanstack/react-router"
import { ColumnDef, Row } from "@tanstack/react-table"
import { IconButton, LoadingCircle, PageCard, TableGeneral, Tooltip } from "@ui-lib/src"
import { common } from "@utils/common"
import { FuelEvent, FuelEventsMetaData, PortalFeature, TimeseriesCollection } from "api"
import { nb } from "date-fns/locale"
import { useEffect, useMemo, useState } from "react"
import DatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"
import {
	MdClose,
	MdInfoOutline,
	MdOutlineAssessment,
	MdOutlineTrendingDown,
	MdOutlineTrendingUp,
} from "react-icons/md"
import { PiTrendDownFill, PiTrendUpFill } from "react-icons/pi"
import { useFuelEventsMetaDataQuery } from "./queries/useFuelEventsMetaDataQuery"
import { useListOfWeatherSensorsQuery } from "./queries/useListOfWeatherSensorsQuery"

const sortDate = (t1: string | Date, t2: string | Date): number => {
	const timeDiff =
		(typeof t1 === "string" ? new Date(t1).getTime() : t1.getTime()) -
		(typeof t2 === "string" ? new Date(t2).getTime() : t2.getTime())

	if (timeDiff > 0) return 1
	if (timeDiff < 0) return -1

	return 0
}

type ArrayProp = {
	[key: string]: number[]
}

export type FuelConsumptionTimeline = {
	id: number
	date: string
	mode: string
	speed: number[]
	duration: number
	baseline: number
	consumers: {
		[key: string]: { consumption: number[]; load: number[] }
	}
	weather: ArrayProp
}

export interface EventTableEntry {
	metadata: FuelEventsMetaData
	event: FuelEvent
}

export type FuelConsumptionTimelineTableItem = {
	id: number
	eventData: EventTableEntry[]
	date: Date
	dateEnd: Date
	duration: string
	opMode: string
	totalFuel: number
	deviation: { percent: number; fuel: number }
}

type InferAccessorType<T extends keyof FuelConsumptionTimelineTableItem> =
	FuelConsumptionTimelineTableItem[T]

export function formatDate(date: Date): string {
	return `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}/${
		date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
	}/${date.getFullYear()}, ${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${
		date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
	}:${date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()}`
}

function dateDiffInHoursAndMinutesV2(a: Date, b: Date): string {
	const MS_PER_HOUR = 1000 * 60 * 60
	const aTimestamp = new Date(a).getTime()
	const bTimestamp = new Date(b).getTime()

	const hours = Math.floor((bTimestamp - aTimestamp) / MS_PER_HOUR)
	const x = Math.floor(((bTimestamp - aTimestamp) % MS_PER_HOUR) / (1000 * 60))
	return `${hours}h${x < 10 ? `0` : ``}${x}`
}

const calcFuelConsumptionFromFuelRateData = (fuelConsumersData: TimeseriesCollection) => {
	const millisecondsInHours = 1000 * 60 * 60
	let acc = 0
	for (const consumer of fuelConsumersData) {
		for (let i = 0; i < consumer.dataPoints.length - 1; i++) {
			const consumerDataPointsI = consumer.dataPoints[i]
			const consumerDataPointsIplus1 = consumer.dataPoints[i + 1]
			if (consumerDataPointsI === undefined || consumerDataPointsIplus1 === undefined) {
				continue
			}
			const timeDif =
				new Date(consumerDataPointsI.timestamp).getTime() -
				new Date(consumerDataPointsIplus1.timestamp).getTime()
			const area =
				((consumerDataPointsIplus1.value + consumerDataPointsI.value) * timeDif) /
				(2 * millisecondsInHours)
			acc = acc + area
		}
	}
	return acc
}

export const COMBINED_MODES = [
	{
		name: "Port - All",
		id: "port-all",
		refString: "port",
		splitString: "Port - ",
		combinedModeName: "Port",
		modeColors: {
			moored: "rgba(23, 242, 7, 0.3)",
			maneuvering: "rgba(232, 218, 9, 0.3)",
			unknown: "rgba(77, 83, 170, 0.3)",
		},
	},
	{
		name: "Transit - All",
		id: "transit-all",
		refString: "transit",
		splitString: "Transit ",
		combinedModeName: "Transit",
		modeColors: {
			eco: "rgba(23, 242, 7, 0.3)",
			max: "rgba(196, 33, 44, 0.3)",
			other: "rgba(0, 0, 0, 0.2)",
			service: "rgba(232, 218, 9, 0.3)",
			unknown: "rgba(77, 83, 170, 0.3)",
		},
	},
	{
		name: "DP - All",
		id: "dp-all",
		refString: "dp",
		splitString: "DP ",
		combinedModeName: "DP",
		modeColors: {
			"1000m": "rgba(77, 83, 170, 0.3)",
			maneuvering: "rgba(232, 218, 9, 0.3)",
		},
	},
]

function useFuelConsumptionTimelineTableColumns() {
	const { t, i18n } = useTranslation()

	const columns = useMemo<ColumnDef<FuelConsumptionTimelineTableItem>[]>(
		() => [
			{
				id: "expander",
				header: t("DETAILS"),
				cell: (info) => (
					<span
						className={`flex items-center justify-center text-blue-600/80 hover:cursor-pointer hover:text-blue-600/100`}
						onClick={() => info.row.toggleExpanded()}
					>
						{info.row.getIsExpanded() === true
							? PaginationIcon("down")
							: PaginationIcon("next")}
					</span>
				),
			},
			{
				accessorKey: "id",
				header: "Id",
				enableHiding: true,
			},
			{
				accessorKey: "eventData",
				header: "Eventdata",
				enableHiding: true,
			},
			{
				accessorKey: "date",
				header: t("START"),
				cell: (info) => (
					<div className="flex items-center capitalize">
						{CellSetterV2({
							content: {
								type: "text",
								text: formatString(
									formatDate(
										new Date(info.getValue() as InferAccessorType<"date">)
									),
									i18n.language
								),
							},
							index: info.cell.id,
						})}
					</div>
				),
				sortingFn: (
					rowA: Row<FuelConsumptionTimelineTableItem>,
					rowB: Row<FuelConsumptionTimelineTableItem>,
					id: string
				) => {
					const aContent = rowA.getValue(id) as string | Date
					const bContent = rowB.getValue(id) as string | Date
					return sortDate(aContent, bContent)
				},
			},
			{
				accessorKey: "dateEnd",
				header: t("END"),
				cell: (info) => (
					<div className="flex items-center capitalize">
						{CellSetterV2({
							content: {
								type: "text",
								text: formatString(
									formatDate(
										new Date(info.getValue() as InferAccessorType<"dateEnd">)
									),
									i18n.language
								),
							},
							index: info.cell.id,
						})}
					</div>
				),
				sortingFn: (
					rowA: Row<FuelConsumptionTimelineTableItem>,
					rowB: Row<FuelConsumptionTimelineTableItem>,
					id: string
				) => {
					const aContent = rowA.getValue(id) as string | Date
					const bContent = rowB.getValue(id) as string | Date
					return sortDate(aContent, bContent)
				},
			},
			{
				accessorKey: "opMode",
				header: t("MODE"),
				cell: (info) => {
					return CellSetterV2({
						content: {
							type: "text",
							text: info.getValue() as InferAccessorType<"opMode">,
						},
						index: info.cell.id,
					})
				},
			},
			{
				accessorKey: "duration",
				header: () => <div className={`flex w-full justify-end`}>{t("DURATION")}</div>,
				cell: (info) => {
					return CellSetterV2({
						content: {
							type: "number-with-unit",
							value: info.getValue() as InferAccessorType<"duration">,
						},
						index: info.cell.id,
					})
				},
				sortingFn: (
					rowA: Row<FuelConsumptionTimelineTableItem>,
					rowB: Row<FuelConsumptionTimelineTableItem>,
					id: string
				) => {
					const aContent = rowA.getValue(id)
					const bContent = rowB.getValue(id)
					if (typeof aContent === "string" && typeof bContent === "string") {
						const aSplit = aContent.split("h")
						const bSplit = bContent.split("h")
						const aMinutes =
							(isNaN(Number(aSplit[1])) !== true ? Number(aSplit[1]) : 0) +
							60 * (isNaN(Number(aSplit[0])) !== true ? Number(aSplit[0]) : 0)
						const bMinutes =
							(isNaN(Number(bSplit[1])) !== true ? Number(bSplit[1]) : 0) +
							60 * (isNaN(Number(bSplit[0])) !== true ? Number(bSplit[0]) : 0)

						if (aMinutes > bMinutes) return 1
						if (aMinutes < bMinutes) return -1
					}
					return 0
				},
			},
			{
				accessorKey: "totalFuel",
				header: () => (
					<div className={`flex w-full justify-end`}>
						{`${t("TOTAL FUEL")}`}
						<p className="pl-1 lowercase">{`(m³)`}</p>
					</div>
				),
				cell: (info) =>
					CellSetterV2({
						content: {
							type: "number-with-unit",
							value: info.getValue() as InferAccessorType<"totalFuel">,
						},
						index: info.cell.id,
					}),
			},
			{
				accessorKey: "deviation",
				header: () => (
					<div className={`flex w-full justify-end`}>
						{`${t("FUEL")} ${t("BASELINE")} ${t("DEVIATION")}`}
					</div>
				),
				cell: (info) =>
					CellSetterV2({
						content: {
							type: "fuel-deviation-from-baseline",
							baseline: (info.getValue() as InferAccessorType<"deviation">).percent,
							fuelDeviation: (info.getValue() as InferAccessorType<"deviation">).fuel,
						},
						index: info.cell.id,
					}),
				sortingFn: (
					rowA: Row<FuelConsumptionTimelineTableItem>,
					rowB: Row<FuelConsumptionTimelineTableItem>,
					id: string
				) => {
					const aContent = (rowA.getValue(id) as InferAccessorType<"deviation">).percent
					const bContent = (rowB.getValue(id) as InferAccessorType<"deviation">).percent
					if (typeof aContent === "number" && typeof bContent === "number") {
						if (aContent > bContent) return 1
						if (aContent < bContent) return -1
					}
					return 0
				},
			},
		],
		[]
	)
	return columns
}

type FuelConsumptionDetailContentProps = {}

export const MGO_DENSITY = 0.84 //tonne/L

export default function OperationsTimelineContent(props: FuelConsumptionDetailContentProps) {
	const { t, i18n } = useTranslation()
	const userData = useAppSelector(getUserData)
	const devFeatures = useGetDevFeatures()

	const currentLocation = useLocation().pathname

	const search = useMatch({
		from: currentLocation.includes("clients")
			? "/clients/$clientName/_sopPageLayout/operations"
			: "/_sopPageLayout/operations",
		shouldThrow: false,
	})?.search

	const navigate = useNavigate({
		from: currentLocation.includes("clients")
			? "/clients/$clientName/operations"
			: "/operations",
	})

	const paramSelectedVessel = search?.vessel ?? ""

	const currentCompany = useAppSelector(getCurrentCompany)
	const plantData = common.plantDataFromOwnerAndName.get(
		`${currentCompany?.toLowerCase()}/${paramSelectedVessel?.toLowerCase()}`
	)

	const {
		isLoading: isOpModesListLoading,
		isError: isOpModesListError,
		data: opModesList,
	} = useGetOpModesListQuery({ plantIdsList: [plantData?.id ?? -1] })

	let defaultOpMode = "all"
	const paramOpMode = search?.opMode ?? defaultOpMode
	const [opMode, setOpMode] = useState<number[]>()

	const defaultMonth = LABELS_MONTHS?.[new Date().getMonth()]?.[1]?.toLowerCase() ?? "january"
	const paramMonth = search?.month ?? defaultMonth

	const defaultYear = new Date().getFullYear()
	const paramYear = search?.year?.toString() ?? defaultYear.toString()

	const now = new Date()
	const [range, setRange] = useState<string>(defaultMonth)
	const [year, setYear] = useState<number>(defaultYear)
	const [selectedDate, setSelectedDate] = useState<Date>(
		new Date(now.getFullYear(), now.getMonth())
	)

	const newDate = new Date(selectedDate)
	const newSelectedDate = new Date(selectedDate)
	newSelectedDate.getMonth()
	newDate.setMonth(newDate.getMonth() + 1)

	const gteDate = newSelectedDate
	const ltDate = newDate

	const {
		isLoading: isFuelEventsMetaDataLoading,
		isError: isFuelEventsMetaDataError,
		data: fuelEventsMetaData,
	} = useFuelEventsMetaDataQuery({
		opModesId: opMode ?? [-1],
		plantId: plantData?.id ?? -1,
		gte: gteDate,
		lt: ltDate,
	})

	const {
		isLoading: isFuelEventsMetaDataLoading2,
		isError: isFuelEventsMetaDataError2,
		data: fuelBaselineAvgData,
	} = useFuelEventsMetaDataQuery({
		opModesId: opModesList?.map((a) => a.id) ?? [-1],
		plantId: plantData?.id ?? -1,
		gte: gteDate,
		lt: ltDate,
	})

	const [averageBaselines, setAverageBaselines] = useState<CardData[] | undefined>([])

	useEffect(() => {
		if (fuelBaselineAvgData !== undefined && fuelBaselineAvgData !== null) {
			const res: CardData[] = []
			for (const index of fuelBaselineAvgData) {
				if (index.events.map((a) => a.mainEngineFuelM3Baseline).length == 0) {
					continue
				}
				const tempBaseline = index.events
					.map((b) =>
						b.mainEngineFuelM3Baseline !== null ? b.mainEngineFuelM3Baseline : 0
					)
					.reduce((a, b) => a + b, 0)

				if (tempBaseline === 0) {
					continue
				}

				const tempCubics = index.events
					.map((b) => b.mainEngineFuelM3)
					.reduce((a, b) => a + b, 0)

				res.push({
					opMode:
						opModesList?.find((mode) => mode.id === index.metaData.opModeId)?.name ??
						"",
					deviation: (100 * tempCubics) / tempBaseline - 100,
					deviationFuel: tempCubics - tempBaseline,
				})
			}

			res.sort((a, b) => a.opMode?.localeCompare(b.opMode))

			setAverageBaselines(res)
		}
	}, [fuelBaselineAvgData])

	const {
		isLoading: isListOfWeatherSensorsLoading,
		isError: isListOfWeatherSensorsError,
		data: listOfWeatherSensors,
	} = useListOfWeatherSensorsQuery({
		plantId: plantData?.id ?? -1,
	})

	useEffect(() => {
		if (opModesList === undefined || opModesList === null) {
			return
		}
		if (opModesList?.length === 0) {
			return
		}
		if (
			[
				...opModesList?.map((o) => o.name.toLowerCase()),
				"all",
				...COMBINED_MODES.map((cm) => cm.id.toLowerCase()),
			].includes(paramOpMode.toLowerCase())
		) {
			defaultOpMode = paramOpMode.toLowerCase()
		}

		const element = document.getElementById("opModeSelect") as HTMLInputElement
		if (element !== null) {
			element.value = (() => {
				if (defaultOpMode === "all") {
					return "all"
				}
				for (const mode of COMBINED_MODES) {
					if (defaultOpMode === mode.id) {
						return mode.id
					}
				}
				return `${opModesList.find((o) => o.name === defaultOpMode)?.id!}`
			})()
		}

		handleOpModeUpdate(defaultOpMode, opModesList)
	}, [opModesList])

	const canUserSeeBaseline =
		currentCompany === undefined
			? false
			: isFeatureAvailable(
					undefined,
					PortalFeature.CONSUMPTION_TIMELINE_BASELINE,
					currentCompany,
					devFeatures,
					userData.portalMode
			  )

	const data: FuelConsumptionTimelineTableItem[] = useMemo(() => {
		if (
			fuelEventsMetaData === undefined ||
			fuelEventsMetaData.length === 0 ||
			currentCompany === undefined
		) {
			return []
		}

		const res: FuelConsumptionTimelineTableItem[] = []
		for (const evMetadata of fuelEventsMetaData) {
			for (const [index, eventData] of evMetadata.events.entries()) {
				const duration = dateDiffInHoursAndMinutesV2(eventData.t1, eventData.t2)
				const curentModeRef =
					opModesList?.filter((o) => {
						return o.id === evMetadata.metaData.opModeId
					}) ?? undefined

				const eventDataComplete: EventTableEntry = {
					metadata: {
						...evMetadata.metaData,
						weatherSensorsId:
							listOfWeatherSensors !== undefined
								? Object.values(listOfWeatherSensors)
										.map((sensorData) => sensorData)
										.filter((sensorId): sensorId is number => sensorId !== null)
								: [],
					},
					event: eventData,
				}

				res.push({
					id: index,
					eventData: [eventDataComplete],
					date: eventData.t1,
					dateEnd: eventData.t2,
					totalFuel: eventData.plantTotalFuelM3,
					duration: duration,
					opMode:
						curentModeRef?.[0] !== undefined
							? curentModeRef[0].name
							: `(Unrecognized mode)`,
					deviation: {
						percent:
							canUserSeeBaseline === true
								? eventData.mainEngineFuelM3 === 0 ||
								  eventData.mainEngineFuelM3 === null ||
								  eventData.mainEngineFuelM3Baseline === null
									? 0
									: Number(
											100 *
												(eventData.mainEngineFuelM3 /
													eventData.mainEngineFuelM3Baseline -
													1)
									  )
								: 0,
						fuel:
							eventData.mainEngineFuelM3 === null ||
							eventData.mainEngineFuelM3Baseline === null
								? 0
								: eventData.mainEngineFuelM3 - eventData.mainEngineFuelM3Baseline,
					},
				})
			}
		}

		res.sort((a, b) => {
			// const aSplit = a.date.split("/")
			// const aNewFormat = `${aSplit[1]}/${aSplit[0]}/${aSplit[2]}`
			// const bSplit = b.date.split("/")
			// const bNewFormat = `${bSplit[1]}/${bSplit[0]}/${bSplit[2]}`
			return new Date(b.date).getTime() - new Date(a.date).getTime()
		})

		let combinedEvents: FuelConsumptionTimelineTableItem[] = []
		let tempState: FuelConsumptionTimelineTableItem | undefined

		const calcFuelDeviation = (tempState: FuelConsumptionTimelineTableItem) => {
			const deviationDenominator = tempState.eventData.reduce(
				(prev, curr) =>
					curr.event.mainEngineFuelM3Baseline === 0 ||
					curr.event.mainEngineFuelM3Baseline === null
						? 0 + prev
						: curr.event.mainEngineFuelM3Baseline + prev,
				0
			)
			const deviationNumerator = tempState.eventData.reduce(
				(prev, curr) =>
					curr.event.mainEngineFuelM3Baseline === 0 ||
					curr.event.mainEngineFuelM3Baseline === null
						? 0 + prev
						: curr.event.mainEngineFuelM3 - curr.event.mainEngineFuelM3Baseline + prev,
				0
			)

			return (
				(100 * deviationNumerator) / (deviationDenominator === 0 ? 1 : deviationDenominator)
			)
		}

		const checkForCombinedEvents = (
			event: FuelConsumptionTimelineTableItem,
			eventCopy: FuelConsumptionTimelineTableItem,
			tempState: FuelConsumptionTimelineTableItem | undefined,
			combinedModeName: string,
			refString: string
		): [FuelConsumptionTimelineTableItem | undefined, string] => {
			const eventData0 = eventCopy.eventData?.[0]

			if (
				event.opMode.toLowerCase().includes(refString) === true &&
				eventData0 !== undefined
			) {
				if (tempState === undefined) {
					//adds event to tempstate
					tempState = eventCopy
					tempState.opMode = combinedModeName
					//
					return [tempState, "break loop"]
				}

				if (tempState.opMode.toLowerCase().includes(refString) === false) {
					//adds tempstate to list
					if (tempState.eventData.length === 1) {
						tempState.opMode =
							opModesList?.find(
								(om) => om.id === tempState?.eventData?.[0]?.metadata.opModeId
							)?.name ?? "unknown"
					}

					tempState.deviation.percent = calcFuelDeviation(tempState)

					combinedEvents.push(tempState)
					tempState = undefined
					//
					//adds event to tempstate
					tempState = eventCopy
					tempState.opMode = combinedModeName
					//
					return [tempState, "break loop"]
				}
				if (eventCopy.dateEnd !== tempState.date) {
					//adds tempstate to list
					if (tempState.eventData.length === 1) {
						tempState.opMode =
							opModesList?.find(
								(om) => om.id === tempState?.eventData?.[0]?.metadata.opModeId
							)?.name ?? "unknown"
					}

					combinedEvents.push(tempState)
					tempState = undefined
					//
					//adds event to tempstate
					tempState = eventCopy
					tempState.opMode = combinedModeName
					//
					return [tempState, "break loop"]
				}
				tempState.eventData.push(eventData0)
				tempState.date = eventCopy.date
				// const t1Split = tempState.date.split("/")
				// const t1NewFormat = `${t1Split[1]}/${t1Split[0]}/${t1Split[2]}`
				// const t2Split = tempState.dateEnd.split("/")
				// const t2NewFormat = `${t2Split[1]}/${t2Split[0]}/${t2Split[2]}`
				tempState.duration = dateDiffInHoursAndMinutesV2(
					new Date(tempState.date),
					new Date(tempState.dateEnd)
				)
				tempState.totalFuel = tempState.totalFuel + eventCopy.totalFuel
				tempState.deviation.percent = calcFuelDeviation(tempState)
				tempState.deviation.fuel = (tempState.deviation.percent * tempState.totalFuel) / 100

				return [tempState, "dont break loop"]
			}

			return [tempState, "dont break loop"]
		}

		const refStrings = COMBINED_MODES.map((a) => a.refString)
		const opModeSorted = opMode?.sort().join(",")
		const matchesOpModesList = (refString: string) => {
			return (
				opModeSorted ===
				opModesList
					?.filter((om) => om.name.toLowerCase().includes(refString))
					?.map((om) => om.id)
					.sort()
					.join(",")
			)
		}
		if (
			opModeSorted ===
				opModesList
					?.map((om) => om.id)
					.sort()
					.join(",") ||
			refStrings.some(matchesOpModesList)
		) {
			try {
				for (const event of res) {
					const eventCopy: FuelConsumptionTimelineTableItem = JSON.parse(
						JSON.stringify(event)
					)
					const eventData0 = eventCopy.eventData?.[0]
					if (eventData0 === undefined) {
						continue
					}
					let shouldBreak = "don't break loop"
					let shouldSkip = false
					COMBINED_MODES.forEach((mode) => {
						if (shouldSkip === true) {
							return
						}
						const [modeTempState, modeShouldBreak] = checkForCombinedEvents(
							event,
							eventCopy,
							tempState,
							mode.combinedModeName,
							mode.refString
						)
						tempState = modeTempState
						if (modeShouldBreak === "break loop") {
							shouldSkip = true
							shouldBreak = "break loop"
						}
					})
					if (shouldBreak === "break loop") {
						continue
					}

					if (
						COMBINED_MODES.map(
							(mode) => event.opMode.toLowerCase().includes(mode.refString) === false
						).includes(false) === false
					) {
						if (tempState !== undefined) {
							//adds tempstate to list
							if (tempState.eventData.length === 1) {
								tempState.opMode =
									opModesList?.find(
										(om) =>
											om.id === tempState?.eventData?.[0]?.metadata.opModeId
									)?.name ?? "unknown"
							}

							tempState.deviation.percent = calcFuelDeviation(tempState)
							combinedEvents.push(tempState)
							tempState = undefined
							//
						}

						combinedEvents.push(eventCopy)
						continue
					}
				}
			} catch (error) {
				console.log("🚀 ~ error:", error)
				if ((error as Error).message !== "continue") {
					throw error // Rethrow any other error
				}
			}
			if (tempState !== undefined) {
				//adds tempstate to list
				if (tempState.eventData.length === 1) {
					tempState.opMode =
						opModesList?.find(
							(om) => om.id === tempState?.eventData?.[0]?.metadata.opModeId
						)?.name ?? "unknown"
				}

				tempState.deviation.percent = calcFuelDeviation(tempState)

				combinedEvents.push(tempState)
				tempState = undefined
			}
		} else {
			combinedEvents = res
		}
		return combinedEvents
	}, [range, year, opMode, fuelEventsMetaData?.length, currentCompany])

	const columns = useFuelConsumptionTimelineTableColumns()
	const handleOpModeUpdate = (
		opModeRef: string,
		opModesList: {
			id: number
			name: string
		}[]
	) => {
		if (opModeRef === "all") {
			setOpMode(opModesList.map((opMode) => opMode.id))
			return
		}
		for (const mode of COMBINED_MODES) {
			if (opModeRef === mode.id) {
				setOpMode(
					opModesList
						.filter((opMode) => opMode.name.toLowerCase().includes(mode.refString))
						.map((opMode) => opMode.id)
				)
				return
			}
		}
		setOpMode([
			opModesList.find((o) => o.name.toLowerCase() === opModeRef.toLowerCase())?.id ?? -1,
		])
		return
	}

	useEffect(() => {
		if (opModesList === undefined || opModesList === null) {
			return
		}
		handleOpModeUpdate(
			(() => {
				if (paramOpMode === "all") {
					return "all"
				}
				for (const mode of COMBINED_MODES) {
					if (paramOpMode === mode.id.toLowerCase()) {
						return mode.id.toLowerCase()
					}
				}
				const opModeTemp = opModesList.find(
					(o) => o.name.toLowerCase() === paramOpMode.toLowerCase()
				)
				return opModeTemp !== undefined ? opModeTemp.name.toLowerCase() : "all"
			})(),
			opModesList
		)
	}, [paramOpMode])

	useEffect(() => {
		const monthIndex = LABELS_MONTHS.find((month) => month[1].toLowerCase() === paramMonth)?.[2]
		if (
			monthIndex !== undefined &&
			(selectedDate.getFullYear().toString() !== paramYear ||
				selectedDate.getMonth() !== monthIndex)
		) {
			setSelectedDate(
				new Date(
					isNaN(Number(paramYear)) === true
						? new Date().getFullYear()
						: Number(paramYear),
					monthIndex
				)
			)
		}
		setRange(paramMonth)
		setYear(isNaN(Number(paramYear)) === true ? new Date().getFullYear() : Number(paramYear))
	}, [paramMonth, paramYear])

	const [columnLayout, setColumnsLayout] = useState<string>(
		"grid-cols-[50px_minmax(100px,1fr)__minmax(100px,1fr)__minmax(100px,1fr)__minmax(100px,1fr)_minmax(100px,1fr)_minmax(100px,1fr)]"
	)

	useEffect(() => {
		if (canUserSeeBaseline === false && columns.length > 8) {
			columns.pop()
			setColumnsLayout(
				"grid-cols-[50px_minmax(100px,1fr)__minmax(100px,1fr)__minmax(100px,1fr)__minmax(50px,1fr)__minmax(100px,1fr)]"
			)
		}
	}, [columns])

	return (
		<>
			<div
				className={`sticky top-[52px] z-10 -ml-5 -mt-5 mb-5 flex h-[49px] w-[calc(100%+40px)] flex-row items-center justify-end gap-4 border-b-1 bg-white px-5 shadow-card`}
			>
				{opModesList !== undefined ? (
					<div className={`relative`}>
						<MdOutlineAssessment
							className={`absolute top-2 ml-2 text-dipai-secondary-800`}
						/>
						<select
							className={`mx-[1px] h-8 w-auto rounded-sm border-1 border-gray-ccc bg-transparent py-[2px] pl-8 text-small hover:cursor-pointer hover:border-dipai-secondary-500 focus:mx-0 focus:border-2 focus:border-dipai-secondary-500 focus:outline-hidden focus:ring-0`}
							value={(() => {
								if (
									opMode === undefined ||
									opModesList === null ||
									opMode.length === opModesList.length
								) {
									return "all"
								}
								if (opMode.length === 1) {
									return opModesList.find((o) => o.id === opMode[0])?.id
								}
								for (const mode of COMBINED_MODES) {
									const refName =
										opModesList
											.find((o) => o.id === opMode[0])
											?.name.split(" ")[0]
											?.toLowerCase() ?? "unknown mode"
									if (mode.name.toLowerCase().includes(refName) === true) {
										return mode.id
									}
								}
							})()}
							id="opModeSelect"
							name="opMode"
							onChange={(e) => {
								navigate({
									to: ".",
									search: (prev) => ({
										...prev,
										opMode: (() => {
											if (e.target.value === "all" || opModesList === null) {
												return "all"
											}
											for (const mode of COMBINED_MODES) {
												if (e.target.value === mode.id) {
													return mode.id
												}
											}
											return opModesList.find(
												(o) => o.id === Number(e.target.value)
											)?.name!
										})().toLowerCase(),
									}),
								})
							}}
						>
							{[
								...(opModesList !== null ? opModesList : []),
								{ name: `All modes`, id: "all" },
								...COMBINED_MODES.filter((mode) => {
									if (opModesList !== undefined && opModesList !== null) {
										return opModesList?.filter((a) =>
											a.name.toLowerCase().includes(mode.refString)
										).length > 1
											? true
											: false
									}
									return false
								}),
							]
								.sort((a, b) => {
									if (a.name === `All modes`) return -1
									if (b.name === `All modes`) return 1
									return a.name.localeCompare(b.name)
								})
								.map((opMode) => (
									<option
										key={`opModeOption_${opMode.name}_${opMode.id}`}
										value={opMode.id}
									>
										{opMode.name}
									</option>
								))}
						</select>
					</div>
				) : null}
				<div className={`flex flex-row border-l-1 pl-4`}>
					<DatePicker
						id={"rangesCustom"}
						calendarContainer={CustomCalendarContainer}
						customInput={<CustomDatePickerInput width={112} />}
						locale={i18n.language === "no" ? nb : undefined}
						showMonthYearPicker
						dateFormat="MMM yyyy"
						maxDate={new Date()}
						minDate={
							plantData?.id !== undefined &&
							[1089, 1091, 1092, 1090].includes(plantData?.id)
								? new Date(2022, 0)
								: new Date(2023, 0)
						}
						onChange={(date) => {
							if (date !== null) {
								const selectedMonthLabel = LABELS_MONTHS.find(
									(month) => month[2] === date.getMonth()
								)?.[1]
								if (selectedMonthLabel === undefined) {
									return
								}
								navigate({
									to: ".",
									search: (prev) => ({
										...prev,
										month: selectedMonthLabel ?? defaultMonth,
										year: date.getFullYear(),
									}),
								})
								setSelectedDate(date)
							}
						}}
						popperPlacement="top-end"
						selected={selectedDate}
						selectsStart
						startDate={selectedDate}
					/>
				</div>
				{/* </div> */}
			</div>
			{isFuelEventsMetaDataLoading === false && isOpModesListLoading === false ? (
				data !== undefined && data.length > 0 ? (
					<div className={`flex flex-col gap-5`}>
						{/* AHTS WARNING PANEL */}
						<AHTSWaningPanel plantName={plantData?.display_name} />

						{canUserSeeBaseline === false ? null : (
							<DeviationCards cardData={averageBaselines} />
						)}
						<div className={`h-fit w-full rounded-sm bg-white p-3 px-4 shadow-card`}>
							<div className={`-mx-4 -mt-3`}>
								<TableGeneral
									translationFunc={t}
									columnVisibility={{ id: false, eventData: false }}
									TableBody={TableBodyOperationsTimelineNL}
									data={data}
									columns={columns}
									columnsLayout={columnLayout}
									itemsPerPage={10}
									loading={false}
									showGlobalFilter={true}
								/>
							</div>
						</div>
					</div>
				) : (
					<div
						className={`flex h-[calc(100vh-52px-49px-40px)] w-full flex-col items-center pt-[calc((100vh-52px-460px-49px-40px)/3)]`}
					>
						<img
							className={`h-[400px] opacity-25`}
							alt="placeholder image vessel"
							src={"/assets/img/vessels/placeHolderImage.png"}
						/>
						<p className="pb-2 font-semibold opacity-75 text-title4">
							{t(
								"NO FUEL CONSUMPTION DATA AVAILABLE FOR THIS COMBINATION OF TIME PERIOD AND OPERATION MODE"
							)}
						</p>
						<p className="opacity-50 text-headline">
							{t("PLEASE SELECT A DIFFERENT TIME PERIOD OR/AND OPERATION MODE")}
						</p>
					</div>
				)
			) : (
				<div
					className={`flex h-[calc(100vh-20px-20px-49px-52px)] flex-row items-center justify-center`}
				>
					<LoadingCircle containerHeightWithUnit="50%-20px" />
				</div>
			)}
		</>
	)
}

interface CardData {
	opMode: string
	deviation: number
	deviationFuel: number
}

function DeviationCards({ cardData }: { cardData: CardData[] | undefined }) {
	const { t } = useTranslation()
	const [isModalOpen, setModalOpen] = useState<boolean>(false)
	const potentialCard = document.getElementById("potentialCard")

	return (
		<PageCard id={"potentialCard"} className={`min-h-0`}>
			<PageCard.Section
				variant={"header"}
				label={t("AVERAGE FUEL BASELINE DEVIATION PER MODE")}
			>
				<div className={`-ml-4 flex h-full items-center`}>
					<MdInfoOutline
						className={`h-5 w-5 hover:cursor-pointer`}
						onClick={() => setModalOpen(true)}
					/>
				</div>
			</PageCard.Section>
			<ModalNL isOpen={isModalOpen} setIsOpen={setModalOpen}>
				<div
					className={`fixed top-[160px] z-5 flex h-[200px] w-[800px] flex-col gap-2 rounded-lg bg-white p-4`}
					style={{
						left: `${
							potentialCard !== null
								? ` ${
										-400 +
										potentialCard?.offsetLeft +
										potentialCard?.offsetWidth / 2
								  }px`
								: ``
						}`,
					}}
				>
					<div className={`flew-row mb-2 flex items-center justify-between font-bold`}>
						<p className={`text-body`}>{t(`${"FUEL BASELINE PREDICTION MODEL"}`)}</p>
						<IconButton
							variant={"text-dark"}
							size={"sm"}
							onClick={() => setModalOpen((prev) => !prev)}
						>
							<IconButton.Icon>{MdClose}</IconButton.Icon>
						</IconButton>
					</div>
					<div className={`mb-5 flex h-auto w-full flex-col gap-1 p-2`}>
						<p>{`${t(
							"BASELINE DATA IS ALL PLANT DATA LOGGED BEFORE THE BEGINNING OF THE CURRENT YEAR."
						)} `}</p>
						<div>
							<div className="grid grid-cols-3">
								<div className="flex flex-col items-center justify-center col-span-1 ">
									<div className="flex flex-row items-center justify-center">
										<div className={`m-2 text-green-600`}>{`-2.5%`}</div>
										<div
											className={`flex h-7 w-7 items-center justify-center rounded-full border-2 border-green-600 text-green-600`}
										>
											<PiTrendDownFill className="w-5 h-5" />
										</div>
									</div>
									<div
										className={`max-h-5 overflow-hidden text-clip pb-2 text-center font-bold text-gray-666`}
									>
										{t(`BELOW BASELINE`)}
									</div>
								</div>
								<div className="flex flex-col items-center justify-center col-span-1 ">
									<div className="flex flex-row items-center justify-center">
										<div className={`m-2 text-amber-600`}>{`2.5%`}</div>
										<div
											className={`flex h-7 w-7 items-center justify-center rounded-full border-2 border-amber-600 text-amber-600`}
										>
											<PiTrendUpFill className="w-5 h-5" />
										</div>
									</div>
									<div
										className={`max-h-5 overflow-hidden text-clip pb-2  text-center font-bold text-gray-666`}
									>
										{t("ABOVE BASELINE")}
									</div>
								</div>
								<div className="flex flex-col items-center justify-center col-span-1 ">
									<div className="flex flex-row items-center justify-center">
										<div className={`m-2 text-red-600`}>{`5.2%`}</div>
										<div
											className={`flex h-7 w-7 items-center justify-center rounded-full border-2 border-red-600 text-red-600`}
										>
											<PiTrendUpFill className="w-5 h-5" />
										</div>
									</div>
									<div
										className={`max-h-5 overflow-hidden text-clip pb-2  text-center font-bold text-gray-666`}
									>
										{t("ABOVE BASELINE > 5 %")}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ModalNL>
			{cardData === undefined || cardData.length === 0 ? (
				<>
					<div className="grid items-center w-auto grid-cols-1 p-2 max-h-16 border-1">
						<p className={`max-h-5 text-center text-small text-gray-666`}>
							{t("COLLECTING HISTORICAL SENSOR DATA...")}
						</p>
					</div>
				</>
			) : (
				<>
					<div
						className="grid w-auto h-auto gap-3 text-sm"
						style={{
							display: "grid",
							gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
							justifyContent: "center",
						}}
					>
						{cardData.map((value, index) => (
							<div key={index} className="p-2 rounded-sm border-1">
								<div className="overflow-hidden">
									<Tooltip
										className={"bg-dipai-secondary-800/95"}
										arrowClassName={"fill-dipai-secondary-800/95"}
										align={"center"}
										customContent={`${value.deviationFuel.toFixed(3)}m³`}
									>
										<div className="flex flex-row items-center justify-center ">
											<div
												className={`m-2 ${
													value.deviation === -Infinity ||
													value.deviation === Infinity ||
													value.deviation === null ||
													value.deviation === 0
														? `text-slate-700`
														: value.deviation > 0
														? value.deviation >= 5
															? `text-red-600`
															: `text-amber-600`
														: `text-green-600`
												}`}
											>
												{value.deviation === -Infinity ||
												value.deviation === Infinity ||
												value.deviation === null ||
												value.deviation === 0
													? `--%`
													: `${value.deviation.toFixed(2)}%`}
											</div>

											<div
												className={`flex h-7 w-7 items-center justify-center rounded-full border-2  ${
													value.deviation === -Infinity ||
													value.deviation === Infinity ||
													value.deviation === null ||
													value.deviation === 0
														? `border-slate-700 text-slate-700`
														: value.deviation > 0
														? value.deviation >= 5
															? `border-red-600 text-red-600`
															: `border-amber-600 text-amber-600`
														: `border-green-600 text-green-600`
												}`}
											>
												{value.deviation === -Infinity ||
												value.deviation === Infinity ||
												value.deviation === null ||
												value.deviation === 0 ? (
													"---"
												) : value.deviation > 0 ? (
													<MdOutlineTrendingUp className="w-5 h-5" />
												) : (
													<MdOutlineTrendingDown className="w-5 h-5 " />
												)}
											</div>
										</div>
									</Tooltip>
								</div>
								<p
									className={`max-h-5 overflow-hidden text-clip pb-2 text-center font-semibold text-black/70`}
								>
									{cardData[index]?.opMode ?? "---"}
								</p>
							</div>
						))}
					</div>
				</>
			)}
		</PageCard>
	)
}
