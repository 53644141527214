import { DateTime } from "luxon"
import { DataSetter } from "../utils/mapLayers"
import { createArrowIcon, getBearing } from "../utils/utils"

export const addVesselTrack: DataSetter = (
	dispatch,
	queryClient,
	layerGroup,
	layerData,
	navigate,
	map
) => {
	if (layerGroup === undefined) return

	layerGroup.clearLayers()

	const { vesselsTrackData } = layerData
	if (vesselsTrackData === undefined) return

	const { intervalPoints, operationModeEvents } = vesselsTrackData
	if (intervalPoints === undefined) return

	const openEvents = operationModeEvents?.openStateEvents
	const openEvents_0 = openEvents?.[0]
	const openEvents_last = openEvents?.[openEvents.length - 1]
	if (openEvents !== undefined && openEvents_0 !== undefined && openEvents_last !== undefined) {
		// THERE ARE EVENT DATA
		const openEventsRefIndex: { index: number; state: string }[] = []
		openEventsRefIndex.push({
			index: 0,
			state: openEvents_0.state === "open" ? "close" : "open",
		})
		const trackData = intervalPoints

		for (const event of openEvents) {
			const tsEvent = DateTime.fromISO(event.timestamp).toMillis()
			const index = trackData.findIndex((td) => tsEvent <= td.timestamp.getTime())
			if (index === undefined) {
				continue
			}
			openEventsRefIndex.push({ index: index, state: event.state })
		}
		openEventsRefIndex.push({
			index: trackData.length,
			state: openEvents_last.state,
		})
		for (const [idx, openEventData] of openEventsRefIndex.entries()) {
			const nextOpenEventData = openEventsRefIndex[idx + 1]
			if (nextOpenEventData === undefined) {
				continue
			}
			layerGroup.addLayer(
				L.polyline(
					intervalPoints
						.slice(
							openEventData.index !== 0
								? openEventData.index - 1
								: openEventData.index,
							nextOpenEventData.index
						)
						.filter(
							(pointData) =>
								isNaN(pointData.latitude) === false &&
								isNaN(pointData.longitude) === false
						)
						.map((pointData) => [pointData.latitude, pointData.longitude]),
					{
						color:
							openEventData.state === "open"
								? `hsl(143, 72%, 50%)`
								: `hsl(0, 72%, 50%)`,
						pane: "vesselsTrackPane",
						weight: 2,
					}
				)
			)
		}
	} else {
		// THERE ARE NO EVENT DATA
		const trackPoints = intervalPoints
			.filter((pointData) => !isNaN(pointData.latitude) && !isNaN(pointData.longitude))
			.map((pointData) => L.latLng(pointData.latitude, pointData.longitude))

		if (trackPoints.length < 2) return
		layerGroup.addLayer(
			L.polyline(trackPoints, {
				color: `#262626`,
				pane: "vesselsTrackPane",
				weight: 2,
				lineCap: "round",
				className: "z-1",
			})
		)
		if (map !== undefined && map?.getZoom() >= 10) {
			const arrowDistance =
				map?.getZoom() >= 13
					? 500
					: map?.getZoom() === 12
						? 1500
						: map?.getZoom() === 11
							? 5000
							: 10000

			let accumulatedDistance = 0
			let lastArrowIndex = 0
			for (let i = 1; i < trackPoints.length; i++) {
				const start = trackPoints[lastArrowIndex]
				const end = trackPoints[i]
				if (start === undefined || end === undefined) break

				accumulatedDistance += map.distance(start, end)

				if (accumulatedDistance >= arrowDistance) {
					const angle = getBearing(start, end)
					const arrowIcon = createArrowIcon(angle)

					const arrowMarker = L.marker(end, {
						icon: arrowIcon,
						pane: "vesselsTrackPane",
						zIndexOffset: 10000,
					})
					layerGroup.addLayer(arrowMarker)

					lastArrowIndex = i
					accumulatedDistance = 0
				}
			}
		}
	}
}
