const LIST_OF_COMPANY_LOGOS = {
	Altera: "png",
	Boreal: "png",
	"Dipai Portal Demo": "png",
	DipaiWhiteYellow: "png",
	DOF: "png",
	Equinor: "png",
	FSV: "png",
	"Geiranger Fjordservice": "png",
	Mainstay: "png",
	"NCT Offshore": "png",
	Nogva: "png",
	Norled: "png",
	"Ocean Infinity": "png",
	Remøy: "svg",
	"REV Ocean": "svg",
	Sølvtrans: "png",
	Solstad: "png",
	Vard: "svg",
}

export const getCompanyLogo = (companyName: string) => {
	return Object.keys(LIST_OF_COMPANY_LOGOS).includes(companyName) === true ? (
		<img
			src={`/assets/img/logos/${companyName}.${LIST_OF_COMPANY_LOGOS[companyName]}`}
			alt={companyName}
			className="text-title2 absolute top-[50%] left-[50%] m-0 h-[80%] w-[80%] translate-x-[-50%] translate-y-[-50%] object-contain text-center"
		/>
	) : (
		<h4 className="text-title2 absolute top-[50%] left-[50%] m-0 w-[80%] translate-x-[-50%] translate-y-[-50%] text-center">
			{companyName}
		</h4>
	)
}
