import { ReactElement } from "react"
import { BodyContentLayout } from "./BodyContentLayout"
import { Table, TableProps } from "./Table"
import { FilterSectionMapPage } from "./TableMapPage/FilterSectionMapPage"
import { PaginationControlsMapPage } from "./TableMapPage/PaginationControlsMapPage"
import { TableHeaderMapPage } from "./TableMapPage/TableHeaderMapPage"
import { TableMapPageLoadingBar } from "./TableMapPage/TableMapPageLoadingBar"

export function TableMapPage<T>(props: TableProps<T>): ReactElement {
	return (
		<Table
			{...props}
			BodyContentLayout={BodyContentLayout<T>}
			FilterSection={FilterSectionMapPage}
			PaginationControls={PaginationControlsMapPage}
			TableHeader={TableHeaderMapPage}
			TableLoadingBar={TableMapPageLoadingBar}
		/>
	)
}
