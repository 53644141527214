import { removeIncompatibleSearchParameters } from "@helpers/removeIncompatibleSearchParameters"
import { DisplayValue, SHOW_TOTAL } from "@sop/FuelPage/Total/types"
import {
	getListOfRanges,
	MIN_YEAR_FUEL_CONSUMPTION,
} from "@sop/FuelPage/Total/utils/useGetRangesDateswithQuarters"
import { createFileRoute } from "@tanstack/react-router"
import { z } from "zod"

const dateSchema = z.preprocess((val) => {
	if (typeof val === "string") {
		const parsedDate = new Date(val)
		if (!isNaN(parsedDate.getTime())) {
			return parsedDate.getTime()
		}
	} else if (typeof val === "number" && !isNaN(val)) {
		return val
	}
	return undefined
}, z.number().int().min(0).optional())

const minDateFrom = new Date(`${MIN_YEAR_FUEL_CONSUMPTION}-01-01`).getTime()

export const consumptionPageSearchSchema = z
	.object({
		vessel: z.optional(z.string()).catch(""), //TODO: find a way to get list of vessels
		rangeFuelTotal: z
			.optional(
				z.union(
					getListOfRanges().map((role) => z.literal(role)) as [
						z.ZodLiteral<string>,
						Zod.ZodLiteral<string>,
						...Zod.ZodLiteral<string>[]
					]
				)
			)
			.catch("custom"),
		tab: z
			.optional(z.enum(["dashboard", "total", "seemp", "timeline", "equipment usage"]))
			.catch("dashboard"),
		toFuelTotal: dateSchema,
		fromFuelTotal: dateSchema,
		showTotal: z.optional(z.nativeEnum(SHOW_TOTAL).catch(SHOW_TOTAL.HIDE)),
		displayVal: z.optional(z.nativeEnum(DisplayValue)).catch(DisplayValue.FUEL),
	})
	.superRefine((data, ctx) => {
		let hasErrors = false
		if (
			(data.fromFuelTotal !== undefined && data.toFuelTotal === undefined) ||
			(data.fromFuelTotal === undefined && data.toFuelTotal !== undefined)
		) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "Both 'fromFuelTotal' and 'toFuelTotal' must be provided together",
				path: ["fromFuelTotal"],
			})
			hasErrors = true
		}

		if (
			data.fromFuelTotal !== undefined &&
			data.toFuelTotal !== undefined &&
			data.fromFuelTotal >= data.toFuelTotal
		) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "'fromFuelTotal' must be before 'toFuelTotal'",
				path: ["fromFuelTotal"],
			})
			hasErrors = true
		}

		if (data.fromFuelTotal !== undefined && data.fromFuelTotal < minDateFrom) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "'fromFuelTotal' cannot be earlier than 2022-01-01",
				path: ["fromFuelTotal"],
			})
			hasErrors = true
		}

		if (hasErrors === true) {
			data.fromFuelTotal = undefined
			data.toFuelTotal = undefined
			data.rangeFuelTotal = "last 4 quarters"
		} else if (data.fromFuelTotal !== undefined && data.toFuelTotal !== undefined) {
			data.rangeFuelTotal = "custom"
		}
	})

export const validateConsumptionSearchParams = (
	inputParams: Record<string, unknown>
): z.infer<typeof consumptionPageSearchSchema> => {
	const result = consumptionPageSearchSchema.safeParse(inputParams)

	if (result.success === false) {
		return {
			...inputParams,
			fromFuelTotal: undefined,
			toFuelTotal: undefined,
			rangeFuelTotal: "last 4 quarters",
		}
	}

	return { ...inputParams, ...result.data }
}

export const Route = createFileRoute("/clients/$clientName/_sopPageLayout/consumption")({
	component: RouteComponent,
	validateSearch: validateConsumptionSearchParams,
	search: {
		middlewares: [removeIncompatibleSearchParameters(consumptionPageSearchSchema)],
	},
})

function RouteComponent() {
	return null
}
