/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SopPageLayoutImport } from './routes/_sopPageLayout'
import { Route as AdminPageLayoutImport } from './routes/_adminPageLayout'
import { Route as IndexImport } from './routes/index'
import { Route as SopPageLayoutVoyageImport } from './routes/_sopPageLayout/voyage'
import { Route as SopPageLayoutVesselStatusImport } from './routes/_sopPageLayout/vessel-status'
import { Route as SopPageLayoutOperationsImport } from './routes/_sopPageLayout/operations'
import { Route as SopPageLayoutMachineryImport } from './routes/_sopPageLayout/machinery'
import { Route as SopPageLayoutHistoricalDataImport } from './routes/_sopPageLayout/historical-data'
import { Route as SopPageLayoutFleetOverviewImport } from './routes/_sopPageLayout/fleet-overview'
import { Route as SopPageLayoutEmissionImport } from './routes/_sopPageLayout/emission'
import { Route as SopPageLayoutDownloadDataImport } from './routes/_sopPageLayout/download-data'
import { Route as SopPageLayoutConsumptionImport } from './routes/_sopPageLayout/consumption'
import { Route as SopPageLayoutCbmImport } from './routes/_sopPageLayout/cbm'
import { Route as AdminPageLayoutMapImport } from './routes/_adminPageLayout/map'
import { Route as AdminPageLayoutDevicesImport } from './routes/_adminPageLayout/devices'
import { Route as AdminPageLayoutDatabaseImport } from './routes/_adminPageLayout/database'
import { Route as ClientsClientNameIndexImport } from './routes/clients.$clientName/index'
import { Route as AdminPageLayoutClientsIndexImport } from './routes/_adminPageLayout/clients/index'
import { Route as ClientsClientNameSopPageLayoutImport } from './routes/clients.$clientName/_sopPageLayout'
import { Route as ClientsClientNameSopPageLayoutVoyageImport } from './routes/clients.$clientName/_sopPageLayout/voyage'
import { Route as ClientsClientNameSopPageLayoutVesselStatusImport } from './routes/clients.$clientName/_sopPageLayout/vessel-status'
import { Route as ClientsClientNameSopPageLayoutOperationsImport } from './routes/clients.$clientName/_sopPageLayout/operations'
import { Route as ClientsClientNameSopPageLayoutMachineryImport } from './routes/clients.$clientName/_sopPageLayout/machinery'
import { Route as ClientsClientNameSopPageLayoutHistoricalDataImport } from './routes/clients.$clientName/_sopPageLayout/historical-data'
import { Route as ClientsClientNameSopPageLayoutFleetOverviewImport } from './routes/clients.$clientName/_sopPageLayout/fleet-overview'
import { Route as ClientsClientNameSopPageLayoutEmissionImport } from './routes/clients.$clientName/_sopPageLayout/emission'
import { Route as ClientsClientNameSopPageLayoutDownloadDataImport } from './routes/clients.$clientName/_sopPageLayout/download-data'
import { Route as ClientsClientNameSopPageLayoutConsumptionImport } from './routes/clients.$clientName/_sopPageLayout/consumption'
import { Route as ClientsClientNameSopPageLayoutCbmImport } from './routes/clients.$clientName/_sopPageLayout/cbm'

// Create Virtual Routes

const LoginLazyImport = createFileRoute('/login')()
const ClientsClientNameImport = createFileRoute('/clients/$clientName')()
const AdminPageLayoutMaintenanceLazyImport = createFileRoute(
  '/_adminPageLayout/maintenance',
)()

// Create/Update Routes

const LoginLazyRoute = LoginLazyImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/login.lazy').then((d) => d.Route))

const SopPageLayoutRoute = SopPageLayoutImport.update({
  id: '/_sopPageLayout',
  getParentRoute: () => rootRoute,
} as any)

const AdminPageLayoutRoute = AdminPageLayoutImport.update({
  id: '/_adminPageLayout',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const ClientsClientNameRoute = ClientsClientNameImport.update({
  id: '/clients/$clientName',
  path: '/clients/$clientName',
  getParentRoute: () => rootRoute,
} as any)

const AdminPageLayoutMaintenanceLazyRoute =
  AdminPageLayoutMaintenanceLazyImport.update({
    id: '/maintenance',
    path: '/maintenance',
    getParentRoute: () => AdminPageLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_adminPageLayout/maintenance.lazy').then((d) => d.Route),
  )

const SopPageLayoutVoyageRoute = SopPageLayoutVoyageImport.update({
  id: '/voyage',
  path: '/voyage',
  getParentRoute: () => SopPageLayoutRoute,
} as any)

const SopPageLayoutVesselStatusRoute = SopPageLayoutVesselStatusImport.update({
  id: '/vessel-status',
  path: '/vessel-status',
  getParentRoute: () => SopPageLayoutRoute,
} as any)

const SopPageLayoutOperationsRoute = SopPageLayoutOperationsImport.update({
  id: '/operations',
  path: '/operations',
  getParentRoute: () => SopPageLayoutRoute,
} as any)

const SopPageLayoutMachineryRoute = SopPageLayoutMachineryImport.update({
  id: '/machinery',
  path: '/machinery',
  getParentRoute: () => SopPageLayoutRoute,
} as any)

const SopPageLayoutHistoricalDataRoute =
  SopPageLayoutHistoricalDataImport.update({
    id: '/historical-data',
    path: '/historical-data',
    getParentRoute: () => SopPageLayoutRoute,
  } as any)

const SopPageLayoutFleetOverviewRoute = SopPageLayoutFleetOverviewImport.update(
  {
    id: '/fleet-overview',
    path: '/fleet-overview',
    getParentRoute: () => SopPageLayoutRoute,
  } as any,
)

const SopPageLayoutEmissionRoute = SopPageLayoutEmissionImport.update({
  id: '/emission',
  path: '/emission',
  getParentRoute: () => SopPageLayoutRoute,
} as any)

const SopPageLayoutDownloadDataRoute = SopPageLayoutDownloadDataImport.update({
  id: '/download-data',
  path: '/download-data',
  getParentRoute: () => SopPageLayoutRoute,
} as any)

const SopPageLayoutConsumptionRoute = SopPageLayoutConsumptionImport.update({
  id: '/consumption',
  path: '/consumption',
  getParentRoute: () => SopPageLayoutRoute,
} as any)

const SopPageLayoutCbmRoute = SopPageLayoutCbmImport.update({
  id: '/cbm',
  path: '/cbm',
  getParentRoute: () => SopPageLayoutRoute,
} as any)

const AdminPageLayoutMapRoute = AdminPageLayoutMapImport.update({
  id: '/map',
  path: '/map',
  getParentRoute: () => AdminPageLayoutRoute,
} as any)

const AdminPageLayoutDevicesRoute = AdminPageLayoutDevicesImport.update({
  id: '/devices',
  path: '/devices',
  getParentRoute: () => AdminPageLayoutRoute,
} as any)

const AdminPageLayoutDatabaseRoute = AdminPageLayoutDatabaseImport.update({
  id: '/database',
  path: '/database',
  getParentRoute: () => AdminPageLayoutRoute,
} as any)

const ClientsClientNameIndexRoute = ClientsClientNameIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ClientsClientNameRoute,
} as any)

const AdminPageLayoutClientsIndexRoute =
  AdminPageLayoutClientsIndexImport.update({
    id: '/clients/',
    path: '/clients/',
    getParentRoute: () => AdminPageLayoutRoute,
  } as any)

const ClientsClientNameSopPageLayoutRoute =
  ClientsClientNameSopPageLayoutImport.update({
    id: '/_sopPageLayout',
    getParentRoute: () => ClientsClientNameRoute,
  } as any)

const ClientsClientNameSopPageLayoutVoyageRoute =
  ClientsClientNameSopPageLayoutVoyageImport.update({
    id: '/voyage',
    path: '/voyage',
    getParentRoute: () => ClientsClientNameSopPageLayoutRoute,
  } as any)

const ClientsClientNameSopPageLayoutVesselStatusRoute =
  ClientsClientNameSopPageLayoutVesselStatusImport.update({
    id: '/vessel-status',
    path: '/vessel-status',
    getParentRoute: () => ClientsClientNameSopPageLayoutRoute,
  } as any)

const ClientsClientNameSopPageLayoutOperationsRoute =
  ClientsClientNameSopPageLayoutOperationsImport.update({
    id: '/operations',
    path: '/operations',
    getParentRoute: () => ClientsClientNameSopPageLayoutRoute,
  } as any)

const ClientsClientNameSopPageLayoutMachineryRoute =
  ClientsClientNameSopPageLayoutMachineryImport.update({
    id: '/machinery',
    path: '/machinery',
    getParentRoute: () => ClientsClientNameSopPageLayoutRoute,
  } as any)

const ClientsClientNameSopPageLayoutHistoricalDataRoute =
  ClientsClientNameSopPageLayoutHistoricalDataImport.update({
    id: '/historical-data',
    path: '/historical-data',
    getParentRoute: () => ClientsClientNameSopPageLayoutRoute,
  } as any)

const ClientsClientNameSopPageLayoutFleetOverviewRoute =
  ClientsClientNameSopPageLayoutFleetOverviewImport.update({
    id: '/fleet-overview',
    path: '/fleet-overview',
    getParentRoute: () => ClientsClientNameSopPageLayoutRoute,
  } as any)

const ClientsClientNameSopPageLayoutEmissionRoute =
  ClientsClientNameSopPageLayoutEmissionImport.update({
    id: '/emission',
    path: '/emission',
    getParentRoute: () => ClientsClientNameSopPageLayoutRoute,
  } as any)

const ClientsClientNameSopPageLayoutDownloadDataRoute =
  ClientsClientNameSopPageLayoutDownloadDataImport.update({
    id: '/download-data',
    path: '/download-data',
    getParentRoute: () => ClientsClientNameSopPageLayoutRoute,
  } as any)

const ClientsClientNameSopPageLayoutConsumptionRoute =
  ClientsClientNameSopPageLayoutConsumptionImport.update({
    id: '/consumption',
    path: '/consumption',
    getParentRoute: () => ClientsClientNameSopPageLayoutRoute,
  } as any)

const ClientsClientNameSopPageLayoutCbmRoute =
  ClientsClientNameSopPageLayoutCbmImport.update({
    id: '/cbm',
    path: '/cbm',
    getParentRoute: () => ClientsClientNameSopPageLayoutRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_adminPageLayout': {
      id: '/_adminPageLayout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AdminPageLayoutImport
      parentRoute: typeof rootRoute
    }
    '/_sopPageLayout': {
      id: '/_sopPageLayout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof SopPageLayoutImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginLazyImport
      parentRoute: typeof rootRoute
    }
    '/_adminPageLayout/database': {
      id: '/_adminPageLayout/database'
      path: '/database'
      fullPath: '/database'
      preLoaderRoute: typeof AdminPageLayoutDatabaseImport
      parentRoute: typeof AdminPageLayoutImport
    }
    '/_adminPageLayout/devices': {
      id: '/_adminPageLayout/devices'
      path: '/devices'
      fullPath: '/devices'
      preLoaderRoute: typeof AdminPageLayoutDevicesImport
      parentRoute: typeof AdminPageLayoutImport
    }
    '/_adminPageLayout/map': {
      id: '/_adminPageLayout/map'
      path: '/map'
      fullPath: '/map'
      preLoaderRoute: typeof AdminPageLayoutMapImport
      parentRoute: typeof AdminPageLayoutImport
    }
    '/_sopPageLayout/cbm': {
      id: '/_sopPageLayout/cbm'
      path: '/cbm'
      fullPath: '/cbm'
      preLoaderRoute: typeof SopPageLayoutCbmImport
      parentRoute: typeof SopPageLayoutImport
    }
    '/_sopPageLayout/consumption': {
      id: '/_sopPageLayout/consumption'
      path: '/consumption'
      fullPath: '/consumption'
      preLoaderRoute: typeof SopPageLayoutConsumptionImport
      parentRoute: typeof SopPageLayoutImport
    }
    '/_sopPageLayout/download-data': {
      id: '/_sopPageLayout/download-data'
      path: '/download-data'
      fullPath: '/download-data'
      preLoaderRoute: typeof SopPageLayoutDownloadDataImport
      parentRoute: typeof SopPageLayoutImport
    }
    '/_sopPageLayout/emission': {
      id: '/_sopPageLayout/emission'
      path: '/emission'
      fullPath: '/emission'
      preLoaderRoute: typeof SopPageLayoutEmissionImport
      parentRoute: typeof SopPageLayoutImport
    }
    '/_sopPageLayout/fleet-overview': {
      id: '/_sopPageLayout/fleet-overview'
      path: '/fleet-overview'
      fullPath: '/fleet-overview'
      preLoaderRoute: typeof SopPageLayoutFleetOverviewImport
      parentRoute: typeof SopPageLayoutImport
    }
    '/_sopPageLayout/historical-data': {
      id: '/_sopPageLayout/historical-data'
      path: '/historical-data'
      fullPath: '/historical-data'
      preLoaderRoute: typeof SopPageLayoutHistoricalDataImport
      parentRoute: typeof SopPageLayoutImport
    }
    '/_sopPageLayout/machinery': {
      id: '/_sopPageLayout/machinery'
      path: '/machinery'
      fullPath: '/machinery'
      preLoaderRoute: typeof SopPageLayoutMachineryImport
      parentRoute: typeof SopPageLayoutImport
    }
    '/_sopPageLayout/operations': {
      id: '/_sopPageLayout/operations'
      path: '/operations'
      fullPath: '/operations'
      preLoaderRoute: typeof SopPageLayoutOperationsImport
      parentRoute: typeof SopPageLayoutImport
    }
    '/_sopPageLayout/vessel-status': {
      id: '/_sopPageLayout/vessel-status'
      path: '/vessel-status'
      fullPath: '/vessel-status'
      preLoaderRoute: typeof SopPageLayoutVesselStatusImport
      parentRoute: typeof SopPageLayoutImport
    }
    '/_sopPageLayout/voyage': {
      id: '/_sopPageLayout/voyage'
      path: '/voyage'
      fullPath: '/voyage'
      preLoaderRoute: typeof SopPageLayoutVoyageImport
      parentRoute: typeof SopPageLayoutImport
    }
    '/_adminPageLayout/maintenance': {
      id: '/_adminPageLayout/maintenance'
      path: '/maintenance'
      fullPath: '/maintenance'
      preLoaderRoute: typeof AdminPageLayoutMaintenanceLazyImport
      parentRoute: typeof AdminPageLayoutImport
    }
    '/clients/$clientName': {
      id: '/clients/$clientName'
      path: '/clients/$clientName'
      fullPath: '/clients/$clientName'
      preLoaderRoute: typeof ClientsClientNameImport
      parentRoute: typeof rootRoute
    }
    '/clients/$clientName/_sopPageLayout': {
      id: '/clients/$clientName/_sopPageLayout'
      path: '/clients/$clientName'
      fullPath: '/clients/$clientName'
      preLoaderRoute: typeof ClientsClientNameSopPageLayoutImport
      parentRoute: typeof ClientsClientNameRoute
    }
    '/_adminPageLayout/clients/': {
      id: '/_adminPageLayout/clients/'
      path: '/clients'
      fullPath: '/clients'
      preLoaderRoute: typeof AdminPageLayoutClientsIndexImport
      parentRoute: typeof AdminPageLayoutImport
    }
    '/clients/$clientName/': {
      id: '/clients/$clientName/'
      path: '/'
      fullPath: '/clients/$clientName/'
      preLoaderRoute: typeof ClientsClientNameIndexImport
      parentRoute: typeof ClientsClientNameImport
    }
    '/clients/$clientName/_sopPageLayout/cbm': {
      id: '/clients/$clientName/_sopPageLayout/cbm'
      path: '/cbm'
      fullPath: '/clients/$clientName/cbm'
      preLoaderRoute: typeof ClientsClientNameSopPageLayoutCbmImport
      parentRoute: typeof ClientsClientNameSopPageLayoutImport
    }
    '/clients/$clientName/_sopPageLayout/consumption': {
      id: '/clients/$clientName/_sopPageLayout/consumption'
      path: '/consumption'
      fullPath: '/clients/$clientName/consumption'
      preLoaderRoute: typeof ClientsClientNameSopPageLayoutConsumptionImport
      parentRoute: typeof ClientsClientNameSopPageLayoutImport
    }
    '/clients/$clientName/_sopPageLayout/download-data': {
      id: '/clients/$clientName/_sopPageLayout/download-data'
      path: '/download-data'
      fullPath: '/clients/$clientName/download-data'
      preLoaderRoute: typeof ClientsClientNameSopPageLayoutDownloadDataImport
      parentRoute: typeof ClientsClientNameSopPageLayoutImport
    }
    '/clients/$clientName/_sopPageLayout/emission': {
      id: '/clients/$clientName/_sopPageLayout/emission'
      path: '/emission'
      fullPath: '/clients/$clientName/emission'
      preLoaderRoute: typeof ClientsClientNameSopPageLayoutEmissionImport
      parentRoute: typeof ClientsClientNameSopPageLayoutImport
    }
    '/clients/$clientName/_sopPageLayout/fleet-overview': {
      id: '/clients/$clientName/_sopPageLayout/fleet-overview'
      path: '/fleet-overview'
      fullPath: '/clients/$clientName/fleet-overview'
      preLoaderRoute: typeof ClientsClientNameSopPageLayoutFleetOverviewImport
      parentRoute: typeof ClientsClientNameSopPageLayoutImport
    }
    '/clients/$clientName/_sopPageLayout/historical-data': {
      id: '/clients/$clientName/_sopPageLayout/historical-data'
      path: '/historical-data'
      fullPath: '/clients/$clientName/historical-data'
      preLoaderRoute: typeof ClientsClientNameSopPageLayoutHistoricalDataImport
      parentRoute: typeof ClientsClientNameSopPageLayoutImport
    }
    '/clients/$clientName/_sopPageLayout/machinery': {
      id: '/clients/$clientName/_sopPageLayout/machinery'
      path: '/machinery'
      fullPath: '/clients/$clientName/machinery'
      preLoaderRoute: typeof ClientsClientNameSopPageLayoutMachineryImport
      parentRoute: typeof ClientsClientNameSopPageLayoutImport
    }
    '/clients/$clientName/_sopPageLayout/operations': {
      id: '/clients/$clientName/_sopPageLayout/operations'
      path: '/operations'
      fullPath: '/clients/$clientName/operations'
      preLoaderRoute: typeof ClientsClientNameSopPageLayoutOperationsImport
      parentRoute: typeof ClientsClientNameSopPageLayoutImport
    }
    '/clients/$clientName/_sopPageLayout/vessel-status': {
      id: '/clients/$clientName/_sopPageLayout/vessel-status'
      path: '/vessel-status'
      fullPath: '/clients/$clientName/vessel-status'
      preLoaderRoute: typeof ClientsClientNameSopPageLayoutVesselStatusImport
      parentRoute: typeof ClientsClientNameSopPageLayoutImport
    }
    '/clients/$clientName/_sopPageLayout/voyage': {
      id: '/clients/$clientName/_sopPageLayout/voyage'
      path: '/voyage'
      fullPath: '/clients/$clientName/voyage'
      preLoaderRoute: typeof ClientsClientNameSopPageLayoutVoyageImport
      parentRoute: typeof ClientsClientNameSopPageLayoutImport
    }
  }
}

// Create and export the route tree

interface AdminPageLayoutRouteChildren {
  AdminPageLayoutDatabaseRoute: typeof AdminPageLayoutDatabaseRoute
  AdminPageLayoutDevicesRoute: typeof AdminPageLayoutDevicesRoute
  AdminPageLayoutMapRoute: typeof AdminPageLayoutMapRoute
  AdminPageLayoutMaintenanceLazyRoute: typeof AdminPageLayoutMaintenanceLazyRoute
  AdminPageLayoutClientsIndexRoute: typeof AdminPageLayoutClientsIndexRoute
}

const AdminPageLayoutRouteChildren: AdminPageLayoutRouteChildren = {
  AdminPageLayoutDatabaseRoute: AdminPageLayoutDatabaseRoute,
  AdminPageLayoutDevicesRoute: AdminPageLayoutDevicesRoute,
  AdminPageLayoutMapRoute: AdminPageLayoutMapRoute,
  AdminPageLayoutMaintenanceLazyRoute: AdminPageLayoutMaintenanceLazyRoute,
  AdminPageLayoutClientsIndexRoute: AdminPageLayoutClientsIndexRoute,
}

const AdminPageLayoutRouteWithChildren = AdminPageLayoutRoute._addFileChildren(
  AdminPageLayoutRouteChildren,
)

interface SopPageLayoutRouteChildren {
  SopPageLayoutCbmRoute: typeof SopPageLayoutCbmRoute
  SopPageLayoutConsumptionRoute: typeof SopPageLayoutConsumptionRoute
  SopPageLayoutDownloadDataRoute: typeof SopPageLayoutDownloadDataRoute
  SopPageLayoutEmissionRoute: typeof SopPageLayoutEmissionRoute
  SopPageLayoutFleetOverviewRoute: typeof SopPageLayoutFleetOverviewRoute
  SopPageLayoutHistoricalDataRoute: typeof SopPageLayoutHistoricalDataRoute
  SopPageLayoutMachineryRoute: typeof SopPageLayoutMachineryRoute
  SopPageLayoutOperationsRoute: typeof SopPageLayoutOperationsRoute
  SopPageLayoutVesselStatusRoute: typeof SopPageLayoutVesselStatusRoute
  SopPageLayoutVoyageRoute: typeof SopPageLayoutVoyageRoute
}

const SopPageLayoutRouteChildren: SopPageLayoutRouteChildren = {
  SopPageLayoutCbmRoute: SopPageLayoutCbmRoute,
  SopPageLayoutConsumptionRoute: SopPageLayoutConsumptionRoute,
  SopPageLayoutDownloadDataRoute: SopPageLayoutDownloadDataRoute,
  SopPageLayoutEmissionRoute: SopPageLayoutEmissionRoute,
  SopPageLayoutFleetOverviewRoute: SopPageLayoutFleetOverviewRoute,
  SopPageLayoutHistoricalDataRoute: SopPageLayoutHistoricalDataRoute,
  SopPageLayoutMachineryRoute: SopPageLayoutMachineryRoute,
  SopPageLayoutOperationsRoute: SopPageLayoutOperationsRoute,
  SopPageLayoutVesselStatusRoute: SopPageLayoutVesselStatusRoute,
  SopPageLayoutVoyageRoute: SopPageLayoutVoyageRoute,
}

const SopPageLayoutRouteWithChildren = SopPageLayoutRoute._addFileChildren(
  SopPageLayoutRouteChildren,
)

interface ClientsClientNameSopPageLayoutRouteChildren {
  ClientsClientNameSopPageLayoutCbmRoute: typeof ClientsClientNameSopPageLayoutCbmRoute
  ClientsClientNameSopPageLayoutConsumptionRoute: typeof ClientsClientNameSopPageLayoutConsumptionRoute
  ClientsClientNameSopPageLayoutDownloadDataRoute: typeof ClientsClientNameSopPageLayoutDownloadDataRoute
  ClientsClientNameSopPageLayoutEmissionRoute: typeof ClientsClientNameSopPageLayoutEmissionRoute
  ClientsClientNameSopPageLayoutFleetOverviewRoute: typeof ClientsClientNameSopPageLayoutFleetOverviewRoute
  ClientsClientNameSopPageLayoutHistoricalDataRoute: typeof ClientsClientNameSopPageLayoutHistoricalDataRoute
  ClientsClientNameSopPageLayoutMachineryRoute: typeof ClientsClientNameSopPageLayoutMachineryRoute
  ClientsClientNameSopPageLayoutOperationsRoute: typeof ClientsClientNameSopPageLayoutOperationsRoute
  ClientsClientNameSopPageLayoutVesselStatusRoute: typeof ClientsClientNameSopPageLayoutVesselStatusRoute
  ClientsClientNameSopPageLayoutVoyageRoute: typeof ClientsClientNameSopPageLayoutVoyageRoute
}

const ClientsClientNameSopPageLayoutRouteChildren: ClientsClientNameSopPageLayoutRouteChildren =
  {
    ClientsClientNameSopPageLayoutCbmRoute:
      ClientsClientNameSopPageLayoutCbmRoute,
    ClientsClientNameSopPageLayoutConsumptionRoute:
      ClientsClientNameSopPageLayoutConsumptionRoute,
    ClientsClientNameSopPageLayoutDownloadDataRoute:
      ClientsClientNameSopPageLayoutDownloadDataRoute,
    ClientsClientNameSopPageLayoutEmissionRoute:
      ClientsClientNameSopPageLayoutEmissionRoute,
    ClientsClientNameSopPageLayoutFleetOverviewRoute:
      ClientsClientNameSopPageLayoutFleetOverviewRoute,
    ClientsClientNameSopPageLayoutHistoricalDataRoute:
      ClientsClientNameSopPageLayoutHistoricalDataRoute,
    ClientsClientNameSopPageLayoutMachineryRoute:
      ClientsClientNameSopPageLayoutMachineryRoute,
    ClientsClientNameSopPageLayoutOperationsRoute:
      ClientsClientNameSopPageLayoutOperationsRoute,
    ClientsClientNameSopPageLayoutVesselStatusRoute:
      ClientsClientNameSopPageLayoutVesselStatusRoute,
    ClientsClientNameSopPageLayoutVoyageRoute:
      ClientsClientNameSopPageLayoutVoyageRoute,
  }

const ClientsClientNameSopPageLayoutRouteWithChildren =
  ClientsClientNameSopPageLayoutRoute._addFileChildren(
    ClientsClientNameSopPageLayoutRouteChildren,
  )

interface ClientsClientNameRouteChildren {
  ClientsClientNameSopPageLayoutRoute: typeof ClientsClientNameSopPageLayoutRouteWithChildren
  ClientsClientNameIndexRoute: typeof ClientsClientNameIndexRoute
}

const ClientsClientNameRouteChildren: ClientsClientNameRouteChildren = {
  ClientsClientNameSopPageLayoutRoute:
    ClientsClientNameSopPageLayoutRouteWithChildren,
  ClientsClientNameIndexRoute: ClientsClientNameIndexRoute,
}

const ClientsClientNameRouteWithChildren =
  ClientsClientNameRoute._addFileChildren(ClientsClientNameRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof SopPageLayoutRouteWithChildren
  '/login': typeof LoginLazyRoute
  '/database': typeof AdminPageLayoutDatabaseRoute
  '/devices': typeof AdminPageLayoutDevicesRoute
  '/map': typeof AdminPageLayoutMapRoute
  '/cbm': typeof SopPageLayoutCbmRoute
  '/consumption': typeof SopPageLayoutConsumptionRoute
  '/download-data': typeof SopPageLayoutDownloadDataRoute
  '/emission': typeof SopPageLayoutEmissionRoute
  '/fleet-overview': typeof SopPageLayoutFleetOverviewRoute
  '/historical-data': typeof SopPageLayoutHistoricalDataRoute
  '/machinery': typeof SopPageLayoutMachineryRoute
  '/operations': typeof SopPageLayoutOperationsRoute
  '/vessel-status': typeof SopPageLayoutVesselStatusRoute
  '/voyage': typeof SopPageLayoutVoyageRoute
  '/maintenance': typeof AdminPageLayoutMaintenanceLazyRoute
  '/clients/$clientName': typeof ClientsClientNameSopPageLayoutRouteWithChildren
  '/clients': typeof AdminPageLayoutClientsIndexRoute
  '/clients/$clientName/': typeof ClientsClientNameIndexRoute
  '/clients/$clientName/cbm': typeof ClientsClientNameSopPageLayoutCbmRoute
  '/clients/$clientName/consumption': typeof ClientsClientNameSopPageLayoutConsumptionRoute
  '/clients/$clientName/download-data': typeof ClientsClientNameSopPageLayoutDownloadDataRoute
  '/clients/$clientName/emission': typeof ClientsClientNameSopPageLayoutEmissionRoute
  '/clients/$clientName/fleet-overview': typeof ClientsClientNameSopPageLayoutFleetOverviewRoute
  '/clients/$clientName/historical-data': typeof ClientsClientNameSopPageLayoutHistoricalDataRoute
  '/clients/$clientName/machinery': typeof ClientsClientNameSopPageLayoutMachineryRoute
  '/clients/$clientName/operations': typeof ClientsClientNameSopPageLayoutOperationsRoute
  '/clients/$clientName/vessel-status': typeof ClientsClientNameSopPageLayoutVesselStatusRoute
  '/clients/$clientName/voyage': typeof ClientsClientNameSopPageLayoutVoyageRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof SopPageLayoutRouteWithChildren
  '/login': typeof LoginLazyRoute
  '/database': typeof AdminPageLayoutDatabaseRoute
  '/devices': typeof AdminPageLayoutDevicesRoute
  '/map': typeof AdminPageLayoutMapRoute
  '/cbm': typeof SopPageLayoutCbmRoute
  '/consumption': typeof SopPageLayoutConsumptionRoute
  '/download-data': typeof SopPageLayoutDownloadDataRoute
  '/emission': typeof SopPageLayoutEmissionRoute
  '/fleet-overview': typeof SopPageLayoutFleetOverviewRoute
  '/historical-data': typeof SopPageLayoutHistoricalDataRoute
  '/machinery': typeof SopPageLayoutMachineryRoute
  '/operations': typeof SopPageLayoutOperationsRoute
  '/vessel-status': typeof SopPageLayoutVesselStatusRoute
  '/voyage': typeof SopPageLayoutVoyageRoute
  '/maintenance': typeof AdminPageLayoutMaintenanceLazyRoute
  '/clients/$clientName': typeof ClientsClientNameIndexRoute
  '/clients': typeof AdminPageLayoutClientsIndexRoute
  '/clients/$clientName/cbm': typeof ClientsClientNameSopPageLayoutCbmRoute
  '/clients/$clientName/consumption': typeof ClientsClientNameSopPageLayoutConsumptionRoute
  '/clients/$clientName/download-data': typeof ClientsClientNameSopPageLayoutDownloadDataRoute
  '/clients/$clientName/emission': typeof ClientsClientNameSopPageLayoutEmissionRoute
  '/clients/$clientName/fleet-overview': typeof ClientsClientNameSopPageLayoutFleetOverviewRoute
  '/clients/$clientName/historical-data': typeof ClientsClientNameSopPageLayoutHistoricalDataRoute
  '/clients/$clientName/machinery': typeof ClientsClientNameSopPageLayoutMachineryRoute
  '/clients/$clientName/operations': typeof ClientsClientNameSopPageLayoutOperationsRoute
  '/clients/$clientName/vessel-status': typeof ClientsClientNameSopPageLayoutVesselStatusRoute
  '/clients/$clientName/voyage': typeof ClientsClientNameSopPageLayoutVoyageRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_adminPageLayout': typeof AdminPageLayoutRouteWithChildren
  '/_sopPageLayout': typeof SopPageLayoutRouteWithChildren
  '/login': typeof LoginLazyRoute
  '/_adminPageLayout/database': typeof AdminPageLayoutDatabaseRoute
  '/_adminPageLayout/devices': typeof AdminPageLayoutDevicesRoute
  '/_adminPageLayout/map': typeof AdminPageLayoutMapRoute
  '/_sopPageLayout/cbm': typeof SopPageLayoutCbmRoute
  '/_sopPageLayout/consumption': typeof SopPageLayoutConsumptionRoute
  '/_sopPageLayout/download-data': typeof SopPageLayoutDownloadDataRoute
  '/_sopPageLayout/emission': typeof SopPageLayoutEmissionRoute
  '/_sopPageLayout/fleet-overview': typeof SopPageLayoutFleetOverviewRoute
  '/_sopPageLayout/historical-data': typeof SopPageLayoutHistoricalDataRoute
  '/_sopPageLayout/machinery': typeof SopPageLayoutMachineryRoute
  '/_sopPageLayout/operations': typeof SopPageLayoutOperationsRoute
  '/_sopPageLayout/vessel-status': typeof SopPageLayoutVesselStatusRoute
  '/_sopPageLayout/voyage': typeof SopPageLayoutVoyageRoute
  '/_adminPageLayout/maintenance': typeof AdminPageLayoutMaintenanceLazyRoute
  '/clients/$clientName': typeof ClientsClientNameRouteWithChildren
  '/clients/$clientName/_sopPageLayout': typeof ClientsClientNameSopPageLayoutRouteWithChildren
  '/_adminPageLayout/clients/': typeof AdminPageLayoutClientsIndexRoute
  '/clients/$clientName/': typeof ClientsClientNameIndexRoute
  '/clients/$clientName/_sopPageLayout/cbm': typeof ClientsClientNameSopPageLayoutCbmRoute
  '/clients/$clientName/_sopPageLayout/consumption': typeof ClientsClientNameSopPageLayoutConsumptionRoute
  '/clients/$clientName/_sopPageLayout/download-data': typeof ClientsClientNameSopPageLayoutDownloadDataRoute
  '/clients/$clientName/_sopPageLayout/emission': typeof ClientsClientNameSopPageLayoutEmissionRoute
  '/clients/$clientName/_sopPageLayout/fleet-overview': typeof ClientsClientNameSopPageLayoutFleetOverviewRoute
  '/clients/$clientName/_sopPageLayout/historical-data': typeof ClientsClientNameSopPageLayoutHistoricalDataRoute
  '/clients/$clientName/_sopPageLayout/machinery': typeof ClientsClientNameSopPageLayoutMachineryRoute
  '/clients/$clientName/_sopPageLayout/operations': typeof ClientsClientNameSopPageLayoutOperationsRoute
  '/clients/$clientName/_sopPageLayout/vessel-status': typeof ClientsClientNameSopPageLayoutVesselStatusRoute
  '/clients/$clientName/_sopPageLayout/voyage': typeof ClientsClientNameSopPageLayoutVoyageRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/login'
    | '/database'
    | '/devices'
    | '/map'
    | '/cbm'
    | '/consumption'
    | '/download-data'
    | '/emission'
    | '/fleet-overview'
    | '/historical-data'
    | '/machinery'
    | '/operations'
    | '/vessel-status'
    | '/voyage'
    | '/maintenance'
    | '/clients/$clientName'
    | '/clients'
    | '/clients/$clientName/'
    | '/clients/$clientName/cbm'
    | '/clients/$clientName/consumption'
    | '/clients/$clientName/download-data'
    | '/clients/$clientName/emission'
    | '/clients/$clientName/fleet-overview'
    | '/clients/$clientName/historical-data'
    | '/clients/$clientName/machinery'
    | '/clients/$clientName/operations'
    | '/clients/$clientName/vessel-status'
    | '/clients/$clientName/voyage'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/login'
    | '/database'
    | '/devices'
    | '/map'
    | '/cbm'
    | '/consumption'
    | '/download-data'
    | '/emission'
    | '/fleet-overview'
    | '/historical-data'
    | '/machinery'
    | '/operations'
    | '/vessel-status'
    | '/voyage'
    | '/maintenance'
    | '/clients/$clientName'
    | '/clients'
    | '/clients/$clientName/cbm'
    | '/clients/$clientName/consumption'
    | '/clients/$clientName/download-data'
    | '/clients/$clientName/emission'
    | '/clients/$clientName/fleet-overview'
    | '/clients/$clientName/historical-data'
    | '/clients/$clientName/machinery'
    | '/clients/$clientName/operations'
    | '/clients/$clientName/vessel-status'
    | '/clients/$clientName/voyage'
  id:
    | '__root__'
    | '/'
    | '/_adminPageLayout'
    | '/_sopPageLayout'
    | '/login'
    | '/_adminPageLayout/database'
    | '/_adminPageLayout/devices'
    | '/_adminPageLayout/map'
    | '/_sopPageLayout/cbm'
    | '/_sopPageLayout/consumption'
    | '/_sopPageLayout/download-data'
    | '/_sopPageLayout/emission'
    | '/_sopPageLayout/fleet-overview'
    | '/_sopPageLayout/historical-data'
    | '/_sopPageLayout/machinery'
    | '/_sopPageLayout/operations'
    | '/_sopPageLayout/vessel-status'
    | '/_sopPageLayout/voyage'
    | '/_adminPageLayout/maintenance'
    | '/clients/$clientName'
    | '/clients/$clientName/_sopPageLayout'
    | '/_adminPageLayout/clients/'
    | '/clients/$clientName/'
    | '/clients/$clientName/_sopPageLayout/cbm'
    | '/clients/$clientName/_sopPageLayout/consumption'
    | '/clients/$clientName/_sopPageLayout/download-data'
    | '/clients/$clientName/_sopPageLayout/emission'
    | '/clients/$clientName/_sopPageLayout/fleet-overview'
    | '/clients/$clientName/_sopPageLayout/historical-data'
    | '/clients/$clientName/_sopPageLayout/machinery'
    | '/clients/$clientName/_sopPageLayout/operations'
    | '/clients/$clientName/_sopPageLayout/vessel-status'
    | '/clients/$clientName/_sopPageLayout/voyage'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AdminPageLayoutRoute: typeof AdminPageLayoutRouteWithChildren
  SopPageLayoutRoute: typeof SopPageLayoutRouteWithChildren
  LoginLazyRoute: typeof LoginLazyRoute
  ClientsClientNameRoute: typeof ClientsClientNameRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AdminPageLayoutRoute: AdminPageLayoutRouteWithChildren,
  SopPageLayoutRoute: SopPageLayoutRouteWithChildren,
  LoginLazyRoute: LoginLazyRoute,
  ClientsClientNameRoute: ClientsClientNameRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_adminPageLayout",
        "/_sopPageLayout",
        "/login",
        "/clients/$clientName"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_adminPageLayout": {
      "filePath": "_adminPageLayout.tsx",
      "children": [
        "/_adminPageLayout/database",
        "/_adminPageLayout/devices",
        "/_adminPageLayout/map",
        "/_adminPageLayout/maintenance",
        "/_adminPageLayout/clients/"
      ]
    },
    "/_sopPageLayout": {
      "filePath": "_sopPageLayout.tsx",
      "children": [
        "/_sopPageLayout/cbm",
        "/_sopPageLayout/consumption",
        "/_sopPageLayout/download-data",
        "/_sopPageLayout/emission",
        "/_sopPageLayout/fleet-overview",
        "/_sopPageLayout/historical-data",
        "/_sopPageLayout/machinery",
        "/_sopPageLayout/operations",
        "/_sopPageLayout/vessel-status",
        "/_sopPageLayout/voyage"
      ]
    },
    "/login": {
      "filePath": "login.lazy.tsx"
    },
    "/_adminPageLayout/database": {
      "filePath": "_adminPageLayout/database.tsx",
      "parent": "/_adminPageLayout"
    },
    "/_adminPageLayout/devices": {
      "filePath": "_adminPageLayout/devices.tsx",
      "parent": "/_adminPageLayout"
    },
    "/_adminPageLayout/map": {
      "filePath": "_adminPageLayout/map.tsx",
      "parent": "/_adminPageLayout"
    },
    "/_sopPageLayout/cbm": {
      "filePath": "_sopPageLayout/cbm.tsx",
      "parent": "/_sopPageLayout"
    },
    "/_sopPageLayout/consumption": {
      "filePath": "_sopPageLayout/consumption.tsx",
      "parent": "/_sopPageLayout"
    },
    "/_sopPageLayout/download-data": {
      "filePath": "_sopPageLayout/download-data.tsx",
      "parent": "/_sopPageLayout"
    },
    "/_sopPageLayout/emission": {
      "filePath": "_sopPageLayout/emission.tsx",
      "parent": "/_sopPageLayout"
    },
    "/_sopPageLayout/fleet-overview": {
      "filePath": "_sopPageLayout/fleet-overview.tsx",
      "parent": "/_sopPageLayout"
    },
    "/_sopPageLayout/historical-data": {
      "filePath": "_sopPageLayout/historical-data.tsx",
      "parent": "/_sopPageLayout"
    },
    "/_sopPageLayout/machinery": {
      "filePath": "_sopPageLayout/machinery.tsx",
      "parent": "/_sopPageLayout"
    },
    "/_sopPageLayout/operations": {
      "filePath": "_sopPageLayout/operations.tsx",
      "parent": "/_sopPageLayout"
    },
    "/_sopPageLayout/vessel-status": {
      "filePath": "_sopPageLayout/vessel-status.tsx",
      "parent": "/_sopPageLayout"
    },
    "/_sopPageLayout/voyage": {
      "filePath": "_sopPageLayout/voyage.tsx",
      "parent": "/_sopPageLayout"
    },
    "/_adminPageLayout/maintenance": {
      "filePath": "_adminPageLayout/maintenance.lazy.tsx",
      "parent": "/_adminPageLayout"
    },
    "/clients/$clientName": {
      "filePath": "clients.$clientName",
      "children": [
        "/clients/$clientName/_sopPageLayout",
        "/clients/$clientName/"
      ]
    },
    "/clients/$clientName/_sopPageLayout": {
      "filePath": "clients.$clientName/_sopPageLayout.tsx",
      "parent": "/clients/$clientName",
      "children": [
        "/clients/$clientName/_sopPageLayout/cbm",
        "/clients/$clientName/_sopPageLayout/consumption",
        "/clients/$clientName/_sopPageLayout/download-data",
        "/clients/$clientName/_sopPageLayout/emission",
        "/clients/$clientName/_sopPageLayout/fleet-overview",
        "/clients/$clientName/_sopPageLayout/historical-data",
        "/clients/$clientName/_sopPageLayout/machinery",
        "/clients/$clientName/_sopPageLayout/operations",
        "/clients/$clientName/_sopPageLayout/vessel-status",
        "/clients/$clientName/_sopPageLayout/voyage"
      ]
    },
    "/_adminPageLayout/clients/": {
      "filePath": "_adminPageLayout/clients/index.tsx",
      "parent": "/_adminPageLayout"
    },
    "/clients/$clientName/": {
      "filePath": "clients.$clientName/index.tsx",
      "parent": "/clients/$clientName"
    },
    "/clients/$clientName/_sopPageLayout/cbm": {
      "filePath": "clients.$clientName/_sopPageLayout/cbm.tsx",
      "parent": "/clients/$clientName/_sopPageLayout"
    },
    "/clients/$clientName/_sopPageLayout/consumption": {
      "filePath": "clients.$clientName/_sopPageLayout/consumption.tsx",
      "parent": "/clients/$clientName/_sopPageLayout"
    },
    "/clients/$clientName/_sopPageLayout/download-data": {
      "filePath": "clients.$clientName/_sopPageLayout/download-data.tsx",
      "parent": "/clients/$clientName/_sopPageLayout"
    },
    "/clients/$clientName/_sopPageLayout/emission": {
      "filePath": "clients.$clientName/_sopPageLayout/emission.tsx",
      "parent": "/clients/$clientName/_sopPageLayout"
    },
    "/clients/$clientName/_sopPageLayout/fleet-overview": {
      "filePath": "clients.$clientName/_sopPageLayout/fleet-overview.tsx",
      "parent": "/clients/$clientName/_sopPageLayout"
    },
    "/clients/$clientName/_sopPageLayout/historical-data": {
      "filePath": "clients.$clientName/_sopPageLayout/historical-data.tsx",
      "parent": "/clients/$clientName/_sopPageLayout"
    },
    "/clients/$clientName/_sopPageLayout/machinery": {
      "filePath": "clients.$clientName/_sopPageLayout/machinery.tsx",
      "parent": "/clients/$clientName/_sopPageLayout"
    },
    "/clients/$clientName/_sopPageLayout/operations": {
      "filePath": "clients.$clientName/_sopPageLayout/operations.tsx",
      "parent": "/clients/$clientName/_sopPageLayout"
    },
    "/clients/$clientName/_sopPageLayout/vessel-status": {
      "filePath": "clients.$clientName/_sopPageLayout/vessel-status.tsx",
      "parent": "/clients/$clientName/_sopPageLayout"
    },
    "/clients/$clientName/_sopPageLayout/voyage": {
      "filePath": "clients.$clientName/_sopPageLayout/voyage.tsx",
      "parent": "/clients/$clientName/_sopPageLayout"
    }
  }
}
ROUTE_MANIFEST_END */
