import { AggregationMethod, Sensor, TimeseriesDataPoint } from "api"

export type TopicTimeseries = {
	topic: string
	aggregationMethod?: AggregationMethod
	dataPoints: TimeseriesDataPoint[]
}

export type AllSensorsForAnAsset = {
	sensors: Sensor[]
}

export type AllSensorsForACompany = {
	companyName: string
	sensors: Sensor[]
}

export enum TimeseriesDataRange {
	Last12h = "12h",
	Last24h = "24h",
	LastWeek = "7days",
	Custom = "custom",
}
