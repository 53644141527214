import { WebSocketWrapper } from "./WebSocketWrapper"

const socketsByUrl = new Map<string, WebSocketWrapper>()

export function getWebSocket(url: string): WebSocketWrapper {
	let result = socketsByUrl.get(url)
	if (result != undefined) {
		return result
	}
	result = new WebSocketWrapper(url)
	socketsByUrl.set(url, result)
	return result
}
