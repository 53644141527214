import { removeIncompatibleSearchParameters } from "@helpers/removeIncompatibleSearchParameters"
import { fleetOverviewPageSearchSchema } from "@routes/clients.$clientName/_sopPageLayout/fleet-overview"
import { createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute("/_sopPageLayout/fleet-overview")({
	component: RouteComponent,
	validateSearch: fleetOverviewPageSearchSchema,
	search: {
		middlewares: [removeIncompatibleSearchParameters(fleetOverviewPageSearchSchema)],
	},
})

function RouteComponent() {
	return null
}
