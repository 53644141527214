import { KeyboardEventHandler, ReactElement, ReactNode } from "react"
import ReactSelect, { ActionMeta, GroupBase, MultiValue, StylesConfig } from "react-select"
import "./multiSelect.css" //do i need this?

export type GenericValueLabelPair<L, T> = {
	label: L
	value: T
}
type MultiSelectArgs<L, T> = {
	placeholder?: ReactNode
	options: GenericValueLabelPair<L, T>[]
	selectAllOption?: GenericValueLabelPair<L, T>
	value: MultiValue<GenericValueLabelPair<L, T>>
	onChange: (
		args: MultiValue<GenericValueLabelPair<L, T>>,
		am: ActionMeta<GenericValueLabelPair<L, T>>
	) => void
	required?: boolean
	onKeyDown?: KeyboardEventHandler
	isClearable?: boolean
	isDisabled?: boolean
	maxAmountItems?: number
	minAmountItems?: number
}

export default function MultiSelect<L, T>({
	options,
	selectAllOption,
	value,
	onChange,
	required = false,
	onKeyDown,
	isClearable,
	placeholder,
	isDisabled,
	maxAmountItems,
	minAmountItems,
}: MultiSelectArgs<L, T>): ReactElement {
	const styles = (
		stateValue: MultiValue<GenericValueLabelPair<L, T>>
	): StylesConfig<GenericValueLabelPair<L, T>, true, GroupBase<GenericValueLabelPair<L, T>>> => ({
		menu: (baseStyles) => ({ ...baseStyles, padding: "4px 4px 0px 4px" }),
		menuPortal: (baseStyles) => ({ ...baseStyles, zIndex: 9999 }),
		valueContainer: (baseStyles) => ({
			...baseStyles,
			padding: "2px 0px 0px 0px",
			// height: 30,
		}),
		container: (baseStyles) => ({
			...baseStyles,
			padding: "0px 0px 0px 0px",
			//, height: 32
		}),
		control: (baseStyles) => ({
			...baseStyles,
			minHeight: 32,
			// height: 32,
			borderRadius: 2,
			":hover": { borderColor: "#4d82aa", cursor: "pointer" },
			borderColor: stateValue.length === 0 && required ? "red" : "#ccc",
		}),
		input: (baseStyles) => ({ ...baseStyles, height: 20, padding: 0 }),
		placeholder: (baseStyles) => ({ ...baseStyles, padding: 0, margin: "0px 0px 3px 4px" }),
		multiValue: (baseStyles) => ({ ...baseStyles, padding: 0, margin: "0px 0px 2px 4px" }),
		multiValueLabel: (baseStyles) => ({ ...baseStyles, padding: 0, margin: 0 }),
		dropdownIndicator: (baseStyles) => ({ ...baseStyles, padding: 5, margin: 0 }),
		indicatorsContainer: (baseStyles) => ({ ...baseStyles, height: 32 }),
		clearIndicator: (baseStyles) => ({ ...baseStyles, padding: 5, margin: 0 }),
		option: (baseStyles) => ({
			...baseStyles,
			borderRadius: 2,
			padding: "4px 8px 8px 8px",
			margin: 1,
			":hover": { cursor: "pointer" },
		}),
	})

	// isOptionSelected sees previous value after onChange
	// const valueRef = useRef(value)
	// valueRef.current = value

	// const selectAllOption: GenericValueLabelPair<string, string> = {
	// 	value: "<SELECT_ALL>",
	// 	label: "All items",
	// }

	const selectAllOptionRef: GenericValueLabelPair<L, T> | undefined =
		(maxAmountItems !== undefined && options.length > maxAmountItems) ||
		minAmountItems !== undefined
			? undefined
			: selectAllOption

	const isSelectAllSelected = () => value.length === options.length

	const isOptionSelected = (option: GenericValueLabelPair<L, T>) =>
		value.some(({ value }) => value === option.value) === true || isSelectAllSelected()

	const getOptions = () =>
		selectAllOptionRef !== undefined ? [selectAllOptionRef, ...options] : options

	const getValue = () => {
		return isSelectAllSelected() && selectAllOptionRef !== undefined
			? [selectAllOptionRef]
			: value
	}

	const onSelectionChange = (newValue, actionMeta: ActionMeta<GenericValueLabelPair<L, T>>) => {
		const { action, option, removedValue } = actionMeta
		if (
			maxAmountItems !== undefined &&
			value.length >= maxAmountItems &&
			action === "select-option"
		) {
			return
		}

		if (
			action === "select-option" &&
			selectAllOptionRef !== undefined &&
			option?.value === selectAllOptionRef.value
		) {
			onChange(options, actionMeta)
		} else if (
			(action === "deselect-option" &&
				selectAllOptionRef !== undefined &&
				option?.value === selectAllOptionRef.value) ||
			(action === "remove-value" &&
				selectAllOptionRef !== undefined &&
				removedValue.value === selectAllOptionRef.value)
		) {
			onChange([], actionMeta)
		} else if (actionMeta.action === "deselect-option" && isSelectAllSelected()) {
			onChange(
				options.filter(({ value }) => value !== option?.value),
				actionMeta
			)
		} else {
			onChange(newValue ?? [], actionMeta)
		}
	}

	return (
		<ReactSelect
			isDisabled={isDisabled}
			placeholder={placeholder}
			classNamePrefix="mySelect"
			isOptionSelected={isOptionSelected}
			options={getOptions()}
			value={getValue()}
			onChange={onSelectionChange}
			hideSelectedOptions={false}
			closeMenuOnSelect={false}
			isMulti
			styles={styles(getValue())}
			isSearchable={true}
			menuPortalTarget={document.body}
			onKeyDown={onKeyDown}
			isClearable={isClearable}
		/>
	)
}
