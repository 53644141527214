import { requestApi2 } from "@utils/http"
import { authEndpoints, LoginMethod } from "api"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import LogoutGoogle from "./google/LogoutGoogle"
import useLogoutMicrosoft from "./microsoft/useLogoutMicrosoft"

export default function Logout() {
	const { t } = useTranslation()
	const [loggedWith, setLoggedWith] = useState<LoginMethod | null>()

	const logoutMicrosoft = useLogoutMicrosoft()

	useEffect(() => {
		async function fetchLoggedWith() {
			const loginInfo = await requestApi2(authEndpoints.getLoginInfo)
			setLoggedWith(loginInfo?.provider ?? null)
		}
		fetchLoggedWith()
	}, [])

	return (
		<>
			{loggedWith === LoginMethod.MICROSOFT ? (
				<button className="text-menu" onClick={logoutMicrosoft}>
					{t("LOG OUT")}
				</button>
			) : loggedWith === LoginMethod.GOOGLE ? (
				<LogoutGoogle />
			) : (
				<button className="text-menu" disabled={true}>
					{t("LOG OUT")}
				</button>
			)}
		</>
	)
}
