import { ReactElement } from "react"

export default function EzTable(args: {
	header: string[]
	data: (string | number | ReactElement | undefined)[][]
}): ReactElement {
	return (
		<table>
			<thead>
				<tr>
					{args.header.map((label, index) => (
						<td key={index}>{label}</td>
					))}
				</tr>
			</thead>
			<tbody>
				{args.data.map((row, rowIndex) => (
					<tr key={`row${rowIndex}`}>
						{row.map((cell, cellIndex) => (
							<td key={cellIndex}>{cell ?? ""}</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	)
}
