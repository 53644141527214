import { isFeatureAvailable, useGetDevFeatures } from "@components/featureGuard/FeatureGuard"
import { MapOptions, WindyMapType } from "@components/map/windyMap/windyMap"
import { useAppSelector } from "@redux/app/hooks"
import { getUserData } from "@redux/features/userData/userDataSlice"
import { PlantObject } from "api"
import { useEffect, useState } from "react"
import { RegularMapType } from "../regularMap/regularMap"
import { LayerData } from "./mapLayers"

export function objectsAreEqual(obj1: unknown, obj2: unknown): boolean {
	if (obj1 === obj2) {
		return true
	}
	if (obj1 === null || obj2 === null) {
		return false
	}
	if (Array.isArray(obj1)) {
		if (!Array.isArray(obj2)) {
			return false
		}
		if (obj1.length != obj2.length) {
			return false
		}
		for (const [index, obj1Item] of obj1.entries()) {
			const obj2Item = obj2[index]
			if (!objectsAreEqual(obj1Item, obj2Item)) {
				return false
			}
		}
	} else if (typeof obj1 == "object") {
		if (typeof obj2 != "object") {
			return false
		}
		const obj1Entries = Object.entries(obj1)
		if (obj1Entries.length != Object.entries(obj2).length) {
			return false
		}
		for (const [key, obj1Value] of obj1Entries) {
			if (key in obj2 == false) {
				// obj2 doesn't have property
				return false
			}
			const obj1ValueType = typeof obj1Value
			const obj2Value = obj2[key as keyof typeof obj2]
			if (obj1Value == null || obj1ValueType != "object") {
				if (obj1Value == obj2Value) {
					// the property is equal in both objects
					continue
				} else {
					// the properties are not equal
					return false
				}
			}
			if (typeof obj2Value != "object") {
				return false
			}
			if (!objectsAreEqual(obj1Value, obj2Value)) {
				return false
			}
		}
	} else {
		return obj1 === obj2
	}
	return true
}

export default function useAddLayersDataToMap(
	layersData: LayerData[],
	windyMapData?: { map?: WindyMapType; options?: MapOptions },
	regularMapData?: { map?: RegularMapType },
	customerName?: string,
	plantData?: PlantObject
) {
	const map = windyMapData?.map ?? regularMapData?.map
	const [layersState, setLayersState] = useState<LayerData[]>(layersData)
	const userData = useAppSelector(getUserData)
	const devFeatures = useGetDevFeatures()

	useEffect(() => {
		if (windyMapData?.map !== undefined && windyMapData?.options !== undefined) {
			windyMapData.map.clearOptions()
			windyMapData.map.applyOptions(windyMapData.options)
		}
	}, [layersData])

	useEffect(() => {
		if (map === undefined) {
			return
		}
		for (const layerData of layersData) {
			if (layerData.portalFeature !== undefined) {
				if (customerName === undefined) {
					return
				}
				if (
					isFeatureAvailable(
						plantData,
						layerData.portalFeature,
						customerName,
						devFeatures,
						userData.portalMode
					) === false
				) {
					map.clearLayerData(layerData.mapLayerName)
					setLayersState((prev) =>
						prev.filter((obj) => layerData.mapLayerName !== obj.mapLayerName)
					)
					continue
				}
			}
			map.setLayerData(layerData)
		}
	}, [map])

	useEffect(() => {
		if (map === undefined) {
			return
		}
		for (const layerData of layersData) {
			if (layerData.portalFeature !== undefined) {
				if (customerName === undefined) {
					return
				}
				if (
					isFeatureAvailable(
						plantData,
						layerData.portalFeature,
						customerName,
						devFeatures,
						userData.portalMode
					) === false
				) {
					map.clearLayerData(layerData.mapLayerName)
					setLayersState((prev) =>
						prev.filter((obj) => layerData.mapLayerName !== obj.mapLayerName)
					)
					continue
				}
			}

			const layerState = layersState.find((s) => s.mapLayerName === layerData.mapLayerName)
			if (
				layerState === undefined ||
				(layerState !== undefined &&
					layerData !== undefined &&
					objectsAreEqual(layerData, layerState) === false)
			) {
				if (layerState === undefined) {
					setLayersState((prev) => {
						prev.push(layerData)
						return prev
					})
				} else {
					setLayersState((prev) =>
						prev.map((obj) =>
							layerData.mapLayerName === obj.mapLayerName ? layerData : obj
						)
					)
				}
				map.setLayerData(layerData)
			}
		}
	}, [layersData])
}
