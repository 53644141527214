import UserDropdown from "@components/userDropdown/UserDropdown"
import { ReactElement } from "react"

export type MapHeaderCompanyProps = {
	width?: number
}

export type MapHeaderProps = {
	width?: number
}

export default function MapHeader({ width }: MapHeaderProps): ReactElement {
	return (
		<div
			className="grid h-24 grid-cols-[225px_auto] pl-4"
			style={{ width: width !== undefined ? width : "100%" }}
		>
			<div className="flex h-[96px] items-center">
				<img
					className="max-h-[50%]"
					alt={"DIPAI AS"}
					src={"/assets/img/logos/DipaiLogo.svg"}
				/>
			</div>
			<div className="flex justify-end my-auto">
				<UserDropdown />
			</div>
		</div>
	)
}
