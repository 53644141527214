import { getFileName } from "@helpers/getFileName"
import { requestApi2 } from "@utils/http"
import { PlantsPerCompany } from "@utils/listPlantsPerCompany"
import { OpModeGetLiveArgs, endpoints } from "api"
import { useQuery } from "react-query"

type GetLiveOpModeArgs = {
	postData: OpModeGetLiveArgs
	plantsPerCompany: PlantsPerCompany
	filter?: { [clientName: string]: string[] }
}

export default function useGetLiveOpModeQuery(args: GetLiveOpModeArgs) {
	const queryKeys = [
		`source=${getFileName()}`,
		`queryId=GetLiveOpMode`,
		`endpoints=[${endpoints.getVesselInfoOperation.name}]`,
	]
	const listOfCompanies = Object.keys(args.plantsPerCompany)
	if (listOfCompanies.length > 0) {
		const company0 = listOfCompanies[0]
		if (company0 !== undefined) {
			queryKeys.push(listOfCompanies.length > 1 ? "company=Dipai" : `company=${company0}`)
			queryKeys.push(`plants=${args.postData.plantIds}`)
		}
	}

	if (args.filter !== undefined) {
		const companyNameFilter = Object.keys(args.filter)[0]
		if (companyNameFilter !== undefined) {
			queryKeys.push(`companyFilter=${companyNameFilter}`)
			const filterForCompany = args.filter[companyNameFilter]?.[0]
			if (filterForCompany !== undefined) {
				queryKeys.push(`filter=${filterForCompany}`)
			}
		}
	}

	const response = useQuery(
		queryKeys,
		async () => {
			const data = await requestApi2(endpoints.opModeGetLive2, {
				plantIds: Array.from(new Set(args.postData.plantIds)),
			})
			return data
		},
		{
			staleTime: 60000,
			refetchInterval: 60000,
		}
	)
	return response
}
