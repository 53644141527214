type PaginationIconId = "first" | "previous" | "next" | "last" | "down"
export const PaginationIcon = (iconId: PaginationIconId) => {
	let path: string
	switch (iconId) {
		case "first":
			path = "M11 19l-7-7 7-7m8 14l-7-7 7-7"
			break
		case "previous":
			path = "M15 19l-7-7 7-7"
			break
		case "next":
			path = "M9 5l7 7-7 7"
			break
		case "last":
			path = "M13 5l7 7-7 7M5 5l7 7-7 7"
			break
		case "down":
			path = "M9 5l7 7-7 7"
			break
	}

	return (
		<svg
			className="m-auto h-5 w-5"
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
			transform={iconId === "down" ? "rotate(90)" : ""}
		>
			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d={path} />
		</svg>
	)
}
