import { ReactElement, ReactNode } from "react"

type BodyLayoutProps = {
	children: ReactNode
	headerHeight: number
	width: number | string
}

export default function BodyLayout(props: BodyLayoutProps): ReactElement {
	return (
		<div
			className="flex flex-col m-auto"
			style={{
				height: `calc(100vh - ${props.headerHeight}px)`,
				width: props.width,
			}}
		>
			{props.children}
		</div>
	)
}
