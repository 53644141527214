import { useAppSelector } from "@redux/app/hooks"
import { getSelectedVessel } from "@redux/features/selectedVessel/selectedVesselSlice"
import { useGetVesselTrackQueryV2 } from "@sop/OperationsTimelinePage/Timeline/OperationsTimelineExpandedTableArea"
import { OpModeEventData, VesselTrack } from "api"
import { useEffect, useState } from "react"

type Location = VesselTrack["locations"][number]
export type ShipTrackData = {
	operationModeEvents?: OpModeEventData
	intervalPoints?: Location[]
}

export default function useGetSelectedShipTrack() {
	const selectedVessel = useAppSelector(getSelectedVessel)
	const [shipTrackData, setShipTrackData] = useState<ShipTrackData>({})

	const lastTimestamp = selectedVessel.position?.timeStamp
	const {
		isLoading: isVesselTrackLoading,
		isError: isVesselTrackError,
		data: vesselTrackData,
	} = useGetVesselTrackQueryV2({
		plantId: selectedVessel.plantId,
		t1:
			lastTimestamp !== undefined
				? new Date(new Date(lastTimestamp).getTime() - 24 * 60 * 60 * 1000)
				: undefined,
		t2: lastTimestamp !== undefined ? new Date(lastTimestamp) : undefined,
	})

	useEffect(() => {
		const shipTrackDataTemp: ShipTrackData = {}
		if (selectedVessel.operationModeEvents !== undefined) {
			shipTrackDataTemp.operationModeEvents = selectedVessel.operationModeEvents
		}
		shipTrackDataTemp.intervalPoints = vesselTrackData?.locations ?? []
		setShipTrackData(selectedVessel.plantId === -1 ? {} : shipTrackDataTemp)
	}, [vesselTrackData])

	return { shipTrackData }
}
