import { TabHeader } from "@pages/HomePage/TabHeader"
import * as Tabs from "@radix-ui/react-tabs"
import { Route } from "@routes/_adminPageLayout/database"
import { useNavigate } from "@tanstack/react-router"
import { requestApi2 } from "@utils/http"
import { ConfigurationModule, DbDataReportType, endpoints } from "api"
import { ReactElement, useEffect, useState } from "react"
import Button from "./Button"
import DataEditPage from "./DataEditPage"
import DbConfigModulePage from "./DbConfigModulePage"
import DbDataPage from "./DbDataPage"
import DeviceAdminPage from "./Edge2DeviceAdminPage/Edge2DeviceAdminPage"
import KPIConfiguratorPage from "./KPIConfiguratorPage"
import NotificationSubmitPage from "./NotificationSubmitPage"
import OpModeDetectionPage from "./OpModeDetectionPage"
import { PlantRawDataPage } from "./PlantRawDataPage"
import "./dbconfig.css"
import { UserTracking } from "./usertracking/UserTracking"

export function normaliseTabLabel(label: string): string {
	return label.replaceAll(/ |\t/g, "").toLowerCase()
}

export const DEFAULT_DATABASE_TAB_LABEL = "User activity"

type Tab = {
	label: string
	content: ReactElement
}

export default function DatabasePage() {
	const { page } = Route.useSearch()
	const navigate = useNavigate()
	const [activeTabIndex, setActiveTabIndex] = useState<string>()
	const [width, setWidth] = useState(1300)
	const [tabs, setTabs] = useState<Tab[]>([])

	useEffect(() => {
		const _tabs: Tab[] = [
			{
				label: "Users",
				content: <DataEditPage module={ConfigurationModule.User} />,
			},
			{
				label: "Plant identities",
				content: <DataEditPage module={ConfigurationModule.Plants} />,
			},
			{
				label: "Plants / assets",
				content: <DataEditPage module={ConfigurationModule.Asset} />,
			},
			{
				label: "Sensors",
				content: <DataEditPage module={ConfigurationModule.Sensor} />,
			},
			{
				label: "Sensor groups",
				content: <DataEditPage module={ConfigurationModule.SensorGroup} />,
			},
			{
				label: "Units",
				content: <DataEditPage module={ConfigurationModule.Unit} />,
			},
			{
				label: "Sensor states",
				content: <DataEditPage module={ConfigurationModule.SensorState} />,
			},
			{
				label: "Op mode schemas",
				content: <DataEditPage module={ConfigurationModule.OpModeSchemas} />,
			},
			{
				label: "Op mode detection",
				content: <OpModeDetectionPage />,
			},
			{
				label: "Op mode pages",
				content: <DataEditPage module={ConfigurationModule.OpModePages} />,
			},
			{
				label: "Notifications",
				content: <NotificationSubmitPage />,
			},
			{
				label: "KPI edit",
				content: <KPIConfiguratorPage />,
			},
			{
				label: "AIS data sources",
				content: <DataEditPage module={ConfigurationModule.AisDataSources} />,
			},
			{
				label: "Høglund",
				content: <DataEditPage module={ConfigurationModule.Hoglund} />,
			},
			{
				label: "SeaQ",
				content: <DataEditPage module={ConfigurationModule.Seaq} />,
			},
			{
				label: "BlueCtrl",
				content: <DataEditPage module={ConfigurationModule.BlueCtrl} />,
			},
			{
				label: "K-IMS",
				content: <DataEditPage module={ConfigurationModule.KIMS} />,
			},
			{
				label: "Inmarsat",
				content: <DataEditPage module={ConfigurationModule.Inmarsat} />,
			},
			{
				label: "Fuel",
				content: <DataEditPage module={ConfigurationModule.FuelConsumption} />,
			},
			{
				label: "Virtual sensors",
				content: <DataEditPage module={ConfigurationModule.VirtualSensors} />,
			},
			{
				label: "Data editor",
				content: <DataEditPage module={ConfigurationModule.DataEditor} />,
			},
			// {
			// 	label: "Op mode db report",
			// 	content: <OpModeReportPage />,
			// },
			{
				label: "Devices",
				content: <DataEditPage module={ConfigurationModule.Devices} />,
			},
			{
				label: "Weather",
				content: <DataEditPage module={ConfigurationModule.Weather} />,
			},
			{
				label: "OP mode comparison",
				content: <DbDataPage argKey={DbDataReportType.OPMODE_COMPARISON} />,
			},
			{
				label: "Db data",
				content: <PlantRawDataPage />,
			},
			{
				label: DEFAULT_DATABASE_TAB_LABEL,
				content: <DbDataPage argKey={DbDataReportType.USER_ACTIVITY} />,
			},
			{
				label: "Edge2 admin",
				content: <DeviceAdminPage />,
			},
			{
				label: "Dof op mode notifier",
				content: <DataEditPage module={ConfigurationModule.DofOpModeNotifier} />,
			},
			{
				label: "Clarify",
				content: (
					<>
						<DataEditPage module={ConfigurationModule.ClarifyPlant} />
						<DbConfigModulePage
							module={ConfigurationModule.Clarify}
							keySelectLabel="Plant"
						/>
					</>
				),
			},
			{
				label: "Fuel consumer datasheets",
				content: (
					<DbConfigModulePage
						module={ConfigurationModule.FuelConsumerDataSheet}
						keySelectLabel="Fuel consumption schema"
					/>
				),
			},
			{
				label: "Hybrid propulsion",
				content: (
					<>
						<p>
							(Note: these configurations are deprecated in favor of "propeller line
							power computer")
						</p>
						<DbConfigModulePage
							module={ConfigurationModule.HybridPropulsion}
							keySelectLabel="Fuel consumption schema"
						/>
					</>
				),
			},
			{
				label: "Test",
				content: (
					<Button
						label="Test"
						onClick={() => {
							requestApi2(endpoints.executeTest)
						}}
					/>
				),
			},
			{
				label: "Configuration schemas",
				content: <DataEditPage module={ConfigurationModule.ConfigSchemas} />,
			},
			{
				label: "Dof data sender",
				content: <DataEditPage module={ConfigurationModule.DofDataSender} />,
			},
			{
				label: "Misc",
				content: <DataEditPage module={ConfigurationModule.Misc} />,
			},
			{
				label: "User tracking",
				content: <UserTracking />,
			},
			{
				label: "Propeller line power",
				content: (
					<DbConfigModulePage
						module={ConfigurationModule.PropellerLinePower}
						keySelectLabel="Config. schema"
					/>
				),
			},
			{
				label: "Moving avg",
				content: <DataEditPage module={ConfigurationModule.MovingAvgSensors} />,
			},
			{
				label: "Sum sensors",
				content: <DataEditPage module={ConfigurationModule.SumSensors} />,
			},
			{
				label: "Datasheet interpolation",
				content: (
					<>
						<DataEditPage module={ConfigurationModule.DataSheetInterpolation} />
						<h1 className="text-title3">Sheet editor</h1>
						<DbConfigModulePage
							module={ConfigurationModule.DataSheetInterpolationSheets}
							keySelectLabel="Sheet"
						/>
					</>
				),
			},
			{
				label: "EIAPP2",
				content: (
					<>
						<DataEditPage module={ConfigurationModule.EIAPPTableFuelRateComputer} />
						<h1 className="text-title3">Sheet editor</h1>
						<DbConfigModulePage
							module={ConfigurationModule.EIAPPTableFuelRateComputerSheets}
							keySelectLabel="Sheet"
						/>
					</>
				),
			},
			{
				label: "Plant marks",
				content: <DataEditPage module={ConfigurationModule.PlantIntervalMarks} />,
			},
			{
				label: "Sensor expressions",
				content: (
					<>
						<DataEditPage module={ConfigurationModule.SensorExpression} />
						<h1 className="text-title3">Expression editor</h1>
						<DbConfigModulePage
							module={ConfigurationModule.SensorExpressionTerms}
							keySelectLabel="Expression"
						/>
					</>
				),
			},
		]
		_tabs.sort((a, b) => a.label.localeCompare(b.label))
		setTabs(_tabs)

		if (page === "") {
			navigate({
				to: ".",
				search: (prev) => ({
					...prev,
					page: normaliseTabLabel(DEFAULT_DATABASE_TAB_LABEL),
				}),
			})
		}
		const updateSize = () => {
			setWidth(document.body.clientWidth - 48)
		}
		updateSize()
		window.addEventListener("resize", updateSize)
		return () => {
			window.removeEventListener("resize", updateSize)
		}
	}, [])

	useEffect(() => {
		if (page === "") {
			return
		}
		const tabIndex = tabs.findIndex((t) => normaliseTabLabel(t.label) === page)
		if (tabIndex !== -1) {
			setActiveTabIndex(tabIndex.toString())
		}
	}, [page, tabs])

	useEffect(() => {
		const index = parseFloat(activeTabIndex ?? "")
		const tab = tabs[index]
		if (tab != undefined) {
			navigate({
				to: ".",
				search: (prev) => ({ ...prev, page: normaliseTabLabel(tab.label) }),
			})
		}
	}, [activeTabIndex, tabs])

	return (
		<div className="db-config">
			{activeTabIndex != undefined ? (
				<>
					<Tabs.Root
						orientation="vertical"
						className=""
						onValueChange={setActiveTabIndex}
						value={activeTabIndex}
					>
						<Tabs.List
							className="flex flex-wrap gap-1 overflow-auto"
							style={{ width: width }}
						>
							{tabs.map((t, index) => (
								<TabHeader
									key={`tabHeader_${t.label}`}
									activeTab={activeTabIndex}
									tabName={t.label}
									tabIndex={index}
								/>
							))}
						</Tabs.List>
						{tabs.map((t, index) => (
							<Tabs.Content
								key={index}
								value={index.toString()}
							>
								{t.content}
							</Tabs.Content>
						))}
					</Tabs.Root>
				</>
			) : (
				<>Loading...</>
			)}
		</div>
	)
}
