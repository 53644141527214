import { useSensorsGroupContext } from "@sop/HistoricalDataPage/sensorsGroupContext"
import { IconButton, Tooltip } from "@ui-lib/index"
import { TimeseriesDataRange } from "@utilityTypes/timeseriesDefs"
import { getDate, setHours, setMinutes } from "date-fns"
import isAfter from "date-fns/isAfter"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import DatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdSearch, MdUpdate } from "react-icons/md"
import { CustomCalendarInput } from "./utils"

export type RangeType = "12h" | "24h" | "7days" | "custom"

type PlotRangeSelectionProps = {
	isDataLoading?: boolean
	cardRef?: HTMLDivElement | null
	plotId: number
	exposePlotFetchRange: (range: TimeseriesDataRange, from?: Date, to?: Date) => void
	defaultRange?: RangeType
	endDate: Date
	selectedStartDate: boolean
	setEndDate: Dispatch<SetStateAction<Date>>
	setSelectedStartDate: (value: boolean) => void
	setStartDate: Dispatch<SetStateAction<Date>>
	startDate: Date
	setRange: Dispatch<SetStateAction<TimeseriesDataRange>>
	range?: TimeseriesDataRange
	refetchData: () => void
}

export default function PlotRangeSelection({
	isDataLoading,
	cardRef,
	plotId,
	exposePlotFetchRange,
	setRange,
	defaultRange = "24h",
	endDate,
	selectedStartDate,
	setEndDate,
	setSelectedStartDate,
	setStartDate,
	startDate,
	range = TimeseriesDataRange.Last24h,
	refetchData,
}: PlotRangeSelectionProps) {
	const { t } = useTranslation()
	const c = useSensorsGroupContext()
	const setPlotZoom = c?.setPlotZoom

	const [isCalendarT1Open, setIsCalendarT1Open] = useState(false)
	const [isCalendarT2Open, setIsCalendarT2Open] = useState(false)

	const [disableUpdate, setDisableUpdate] = useState<boolean>(true)
	const selectElement = useRef<null | HTMLSelectElement>(null)

	const changeStartDate = (date: Date) => {
		setDisableUpdate(false)
		setStartDate(date)
	}

	const changeEndDate = (date: Date) => {
		if (date.getTime() > new Date().getTime()) {
			return
		}
		setDisableUpdate(false)
		setEndDate(date)
	}

	const updateRange = (t1: Date, t2: Date) => {
		if (isAfter(t2, t1)) {
			setDisableUpdate(true)
			if (selectElement.current != null) {
				selectElement.current.value = "custom"
			}
			setRange(TimeseriesDataRange.Custom)
			setSelectedStartDate(!selectedStartDate)
			exposePlotFetchRange(TimeseriesDataRange.Custom, t1, t2)
			if (setPlotZoom !== undefined) {
				const zoomData = {
					plotId,
					zoomFrom: t1.getTime(),
					zoomTo: t2.getTime(),
				}
				setPlotZoom((prev) => {
					if (prev === undefined) {
						return [zoomData]
					}
					if (prev.map((item) => item.plotId).includes(plotId) === false) {
						prev.push(zoomData)
						return prev
					}
					return prev.map((plotData) => {
						if (plotData.plotId === plotId) {
							return zoomData
						} else {
							return plotData
						}
					})
				})
			}
		} else {
			alert(t("START DATE SHOULD BE OLDER THAN END DATE"))
		}
	}

	const [tMinStart, setTMinStart] = useState<Date>()
	const [tMaxStart, setTMaxStart] = useState<Date>()
	const [tMinEnd, setTMinEnd] = useState<Date>()
	const [tMaxEnd, setTMaxEnd] = useState<Date>()

	useEffect(() => {
		if (getDate(startDate) !== getDate(endDate)) {
			setTMinStart(setHours(setMinutes(startDate, 0), 0))
			setTMinEnd(setHours(setMinutes(startDate, 59), 23))
			setTMaxStart(setHours(setMinutes(endDate, 0), 0))
			setTMaxEnd(
				endDate.getDate() === new Date().getDate()
					? setHours(setMinutes(endDate, endDate.getMinutes()), endDate.getHours())
					: setHours(setMinutes(endDate, 59), 23)
			)
		} else {
			setTMinStart(setHours(setMinutes(startDate, 0), 0))
			setTMinEnd(setHours(setMinutes(startDate, endDate.getMinutes()), endDate.getHours()))
			setTMaxStart(
				setHours(setMinutes(endDate, startDate.getMinutes()), startDate.getHours())
			)
			setTMaxEnd(
				endDate.getDate() === new Date().getDate()
					? setHours(setMinutes(endDate, endDate.getMinutes()), endDate.getHours())
					: setHours(setMinutes(endDate, 59), 23)
			)
		}
	}, [startDate, endDate])

	return (
		<div className={`flex h-10 flex-row items-end gap-4`}>
			<div className={`flex flex-row gap-2 py-1`}>
				<label
					className={`text-small hidden items-center 2xl:flex`}
					htmlFor={`ranges_${plotId}`}
				>
					{t("RANGE")}
				</label>
				<select
					disabled={isDataLoading}
					className={`border-gray-ccc text-small hover:border-dipai-secondary-500 focus:border-dipai-secondary-500 mr-[1px] h-8 w-[120px] rounded-sm border-1 py-[2px] pl-[13px] focus:mr-[0px] focus:w-[121px] focus:border-2 focus:pl-3 focus:ring-0 focus:outline-hidden`}
					value={range}
					id={`ranges_${plotId}`}
					name={`ranges_${plotId}`}
					onChange={(e) => {
						exposePlotFetchRange(e.target.value as TimeseriesDataRange)
						setRange(e.target.value as TimeseriesDataRange)
						if (setPlotZoom !== undefined) {
							const oneDayInMinutes = 24 * 60
							let minutes = oneDayInMinutes * 0.5
							if (e.target.value === TimeseriesDataRange.Last24h) {
								minutes = oneDayInMinutes * 1
							} else if (e.target.value === TimeseriesDataRange.LastWeek) {
								minutes = oneDayInMinutes * 7
							}
							const zoomData = {
								plotId,
								zoomFrom: new Date(
									new Date().getTime() - minutes * 60 * 1000
								).getTime(),
								zoomTo: new Date().getTime(),
							}
							setPlotZoom((prev) => {
								if (prev === undefined) {
									return [zoomData]
								}
								if (prev.map((item) => item.plotId).includes(plotId) === false) {
									prev.push(zoomData)
									return prev
								}
								return prev.map((plotData) => {
									if (plotData.plotId === plotId) {
										return zoomData
									} else {
										return plotData
									}
								})
							})
						}
					}}
					ref={selectElement}
				>
					<option value="12h">{t("LAST 12H")}</option>
					<option value="24h">{t("LAST 24H")}</option>
					<option value="7days">{t("LAST WEEK")}</option>
					<option
						disabled
						value="custom"
					>
						{t("CUSTOM")}
					</option>
				</select>
			</div>
			<div
				className={`text-menu text-dipai-secondary-901 flex h-full items-center font-bold`}
			>
				{"OR"}
			</div>
			<div className={`flex h-full w-full flex-row justify-between gap-4 py-1`}>
				<div className={`flex flex-row gap-4`}>
					<div className={`flex flex-row gap-2`}>
						<label
							className={`text-small flex items-center`}
							htmlFor={`from_${plotId}`}
						>
							{t("FROM")}
						</label>
						<DatePicker
							id={`from_${plotId}`}
							name={`from_${plotId}`}
							disabled={isDataLoading}
							portalId="calendar-portal"
							customInput={<CustomCalendarInput isCalendarOpen={isCalendarT1Open} />}
							onCalendarOpen={() => setIsCalendarT1Open(true)}
							onCalendarClose={() => setIsCalendarT1Open(false)}
							dateFormat="dd/MM/yyyy - hh:mm aa"
							endDate={endDate}
							maxDate={endDate}
							onChange={(date) => {
								date !== null && changeStartDate(date)
							}}
							fixedHeight
							popperPlacement="top-end"
							selected={startDate}
							minTime={tMinStart}
							maxTime={tMinEnd}
							selectsStart
							showTimeSelect
							startDate={startDate}
						/>
					</div>
					<div className={`flex flex-row gap-2`}>
						<label
							className={`text-small flex items-center`}
							htmlFor={`to_${plotId}`}
						>
							{t("TO")}
						</label>
						<DatePicker
							id={`to_${plotId}`}
							name={`to_${plotId}`}
							disabled={isDataLoading}
							portalId="calendar-portal"
							customInput={<CustomCalendarInput isCalendarOpen={isCalendarT2Open} />}
							onCalendarOpen={() => setIsCalendarT2Open(true)}
							onCalendarClose={() => setIsCalendarT2Open(false)}
							dateFormat="dd/MM/yyyy - hh:mm aa"
							endDate={endDate}
							minDate={startDate}
							minTime={tMaxStart}
							maxTime={tMaxEnd}
							maxDate={new Date()}
							onChange={(date) => date !== null && changeEndDate(date)}
							fixedHeight
							popperPlacement="top-end"
							selected={endDate}
							selectsEnd
							showTimeSelect
							startDate={startDate}
						/>
					</div>
					<Tooltip
						collisionBoundary={cardRef}
						customContent={t("SEARCH")}
					>
						<IconButton
							variant={"filled-secondary"}
							disabled={(isDataLoading === true ? true : false) || disableUpdate}
							onClick={() => updateRange(startDate, endDate)}
							size={"medium"}
						>
							<IconButton.Icon>{MdSearch}</IconButton.Icon>
						</IconButton>
					</Tooltip>
					<div className={`flew-col flex gap-1`}>
						<Tooltip
							collisionBoundary={cardRef}
							customContent={t("SHIFT INTERVAL BACKWARD")}
						>
							<IconButton
								disabled={isDataLoading}
								className={`w-6 px-0`}
								variant={"text-dark"}
								onClick={() => {
									const timeShift = Math.max(
										endDate.getTime() - startDate.getTime(),
										0
									)
									const newT1 = new Date(startDate.getTime() - timeShift)
									const newT2 = new Date(endDate.getTime() - timeShift)
									setStartDate(newT1)
									setEndDate(newT2)
									updateRange(newT1, newT2)
								}}
								size={"medium"}
							>
								<IconButton.Icon className={`scale-x-[1.1] scale-y-[1.4] p-0`}>
									{MdKeyboardArrowLeft}
								</IconButton.Icon>
							</IconButton>
						</Tooltip>
						<Tooltip
							collisionBoundary={cardRef}
							customContent={t("SHIFT INTERVAL FORWARD")}
						>
							<IconButton
								className={`w-6 px-0`}
								variant={"text-dark"}
								disabled={
									(isDataLoading === true ? true : false) ||
									(new Date().getTime() - endDate.getTime() > 60000
										? false
										: true)
								}
								onClick={() => {
									const liveTimestamp = new Date().getTime()
									const timeShift = Math.min(
										endDate.getTime() - startDate.getTime(),
										liveTimestamp - endDate.getTime()
									)
									const newT1 = new Date(startDate.getTime() + timeShift)
									const newT2 = new Date(endDate.getTime() + timeShift)
									setStartDate(newT1)
									setEndDate(newT2)
									updateRange(newT1, newT2)
								}}
								size={"medium"}
							>
								<IconButton.Icon className={`scale-x-[1.1] scale-y-[1.4] p-0`}>
									{MdKeyboardArrowRight}
								</IconButton.Icon>
							</IconButton>
						</Tooltip>
					</div>
					{/* <Button
					variant={"filled-secondary"}
					disabled={disableUpdate}
					onClick={() => updateRange()}
					size={"medium"}
				>
					<Button.Label>{t("UPDATE")}</Button.Label>
				</Button> */}
					<Tooltip
						collisionBoundary={cardRef}
						customContent={t("REFETCH DATA")}
					>
						<IconButton
							disabled={isDataLoading}
							variant={"text-dark"}
							onClick={() => refetchData()}
							size={"medium"}
						>
							<IconButton.Icon>{MdUpdate}</IconButton.Icon>
						</IconButton>
					</Tooltip>
				</div>
			</div>
		</div>
	)
}
