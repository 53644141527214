import { requestApi2 } from "@utils/http"
import { ConnectionStatus } from "@utils/websocket/ConnectionStatus"
import { getWebSocket } from "@utils/websocket/getWebSocket"
import { EdgeDeviceAdminAccess, endpoints } from "api"
import {
	AddDeviceEntry,
	ConfigJsonFetchResult,
	ConfigJsonPushResult,
	CreateDevicePlantArgs,
	Logger,
	Message,
	MessageDefinition,
	PageInfo,
	edgeAdminApi,
} from "edge-admin-api"
import { ReactElement, useEffect, useState } from "react"
import Button from "../Button"
import ConfigJsonController from "./ConfigJsonController"
import DeviceDataSendComponent from "./DeviceDataSendComponent"
import DevicePlantSelectionCell from "./DevicePlantSelectionCell"
import EzTable from "./EzTable"
import LoggerController from "./LoggerController"
import { MessageProcessor } from "./MessageProcessor"
import NewPlantCell from "./NewPlantCell"
import "./adminpage.css"

const messagesFromServer = edgeAdminApi.toPortal
const messagesFromClient = edgeAdminApi.fromPortal

let nextRequestId = 0

export default function Edge2DeviceAdminPage(): ReactElement {
	const [accessInfo, setAccessInfo] = useState<EdgeDeviceAdminAccess>()
	const [pageStatus, setPageStatus] = useState("")
	const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>()
	const [deviceEntries, setDeviceEntries] = useState<(AddDeviceEntry & { _removed: boolean })[]>(
		[]
	)
	const [pageInfo, setPageInfo] = useState<PageInfo>()
	const [infoMessages, setInfoMessages] = useState<string[]>([])
	const [selectedDeviceId, setSelectedDeviceId] = useState<number>()
	const [selectableDevices, setSelectableDevices] = useState<{ id: number; name: string }[]>([])
	const [loggerLines, setLoggerLines] = useState<string[]>([])

	const [logger, setLogger] = useState<Logger>()

	const [configJsonFetchResult, setConfigJsonFetchResult] = useState<ConfigJsonFetchResult>()
	const [configJsonPushResult, setConfigJsonPushResult] = useState<ConfigJsonPushResult>()

	const [pendingRequestId, setPendingRequestId] = useState<number>()

	const paramLocal = new URL(String(document.location)).searchParams.get("localserver")
	let wsServerUrl = "wss://edge.dipai.no/ws/admin"
	if (paramLocal == JSON.stringify(true)) {
		wsServerUrl = "ws://localhost:8086/ws/admin"
	}
	const socket = getWebSocket(wsServerUrl)

	useEffect(() => {
		const msgHandler = new MessageProcessor()
		const msgDef = messagesFromServer
		msgHandler.addCallback(msgDef.addClient, (entry) => {
			setDeviceEntries((_entries) => {
				const entries = Array.from(_entries)
				const index = entries.findIndex((candidate) => candidate.entryId == entry.entryId)
				if (index != -1) {
					entries[index] = { ...entry, _removed: false }
				} else {
					entries.push({ ...entry, _removed: false })
				}
				return entries
			})
		})
		msgHandler.addCallback(msgDef.removeClient, (entry) => {
			setDeviceEntries((_entries) => {
				const entries = Array.from(_entries)
				entries.forEach((e) => {
					if (e.entryId == entry.entryId) {
						e._removed = true
					}
				})
				return entries
			})
		})
		msgHandler.addCallback(msgDef.infoMessage, (data) => addMessage(data.text))
		msgHandler.addCallback(msgDef.dataStreamItem, (data) =>
			addMessage(`data stream item: ${data}`)
		)
		msgHandler.addCallback(msgDef.pageInfo, (data) => setPageInfo(data))
		msgHandler.addCallback(msgDef.logger, (data) => setLogger(data))
		msgHandler.addCallback(msgDef.configJsonFetchResult, (data) =>
			setConfigJsonFetchResult(data)
		)
		const onMessage = socket.onMessage.addListener((data) => {
			msgHandler.process(data)
		})
		const onStatus = socket.onStatusChanged.addListener((status) => {
			if (status == "connected") {
				requestAdminAccess()
			}
			setConnectionStatus(status)
		})
		setConnectionStatus(socket.status)
		if (socket.status == "connected") {
			clear()
			send(messagesFromClient.refresh, {})
		}
		return () => {
			socket.onMessage.removeListener(onMessage)
			socket.onStatusChanged.removeListener(onStatus)
		}
	}, [])

	useEffect(() => {
		if (accessInfo == undefined) {
			setPageStatus("Access denied")
			return
		}
		send(messagesFromClient.login, { userId: accessInfo.userId, token: accessInfo.token })
	}, [accessInfo])

	useEffect(() => {
		if (connectionStatus == "connected") {
			setPageStatus("Connected")
		} else {
			setPageStatus("Connecting...")
			clear()
		}
	}, [connectionStatus])

	useEffect(() => {
		const newDevices: typeof selectableDevices = []
		for (const entry of deviceEntries) {
			if (entry._removed || entry.credentials == null) {
				continue
			}
			newDevices.push({
				id: entry.entryId,
				name: entry.credentials.name,
			})
		}
		setSelectableDevices(newDevices)
	}, [deviceEntries])

	useEffect(() => {
		if (logger == undefined) {
			setLoggerLines([])
			return
		}
		const timestampedItems = new Array<{ t: Date; text: string }>()
		for (const ec of logger.entryCollections) {
			for (const entry of ec.entries) {
				timestampedItems.push({
					t: new Date(entry.timestamp),
					text: `(${ec.severity}) ${entry.lines.join("\n")}`,
				})
			}
		}
		timestampedItems.sort((a, b) => a.t.getTime() - b.t.getTime())
		setLoggerLines(timestampedItems.map((item) => `${item.t.toISOString()}: ${item.text}`))
	}, [logger])

	function addMessage(message: string) {
		setInfoMessages((messages) => [...messages, message])
	}

	async function requestAdminAccess() {
		const access = await requestApi2(endpoints.requestEdgeDeviceAdminAccess)
		if (access != null) {
			setAccessInfo(access)
		}
	}

	function send<T>(definition: MessageDefinition<T>, data: T) {
		const message: Message = {
			key: definition.name,
			data: data,
		}
		try {
			socket.sendMessage(JSON.stringify(message))
		} catch (err) {
			console.error(err)
			setPageStatus(`Socket error: ${err}`)
		}
	}

	function clear() {
		setDeviceEntries([])
	}

	function initSsh(entryId: number) {
		send(messagesFromClient.initSshTunnel, { entryId: entryId })
		const entry = deviceEntries.find((entry) => entry.entryId == entryId)
		addMessage(
			`Use this command to connect: ssh ${entry?.credentials?.sshInfo?.userName}@localhost -p ${entry?.credentials?.sshInfo?.port}`
		)
	}

	function revokeDeviceAccess() {
		if (isNaN(selectedDeviceId ?? NaN)) {
			return
		}
		send(messagesFromClient.revokeDeviceAccess, { deviceId: selectedDeviceId })
	}

	function promptDockerComposeDown(device: AddDeviceEntry) {
		const confirmation = confirm(
			`Warning! You are about to stop the docker service(s) running on device "${device.credentials?.name}".\nData logger(s) for this plant will stop until you start the services again.\nAre you sure? Click OK to confirm.`
		)
		if (confirmation == true) {
			send(messagesFromClient.doDockerComposeDown, {
				entryId: device.entryId,
			})
		}
	}

	function promptEraseCredentials(device: AddDeviceEntry) {
		const confirmation = confirm(
			`Warning! You are about to issue device to erase it's locally stored credentials.\nThe device will remain connected (and re-connect), but won't be able to send data to the cloud until it has received new credentials.\nIf you need to deny authorization to for a client, you probably need to revoke plant access\nDevice ip: ${device.ip}\nDevice name: ${device.credentials?.name}\nAre you sure? Click OK to confirm.`
		)
		if (confirmation == true) {
			send(messagesFromClient.doEraseCredentials, {
				entryId: device.entryId,
			})
		}
	}

	function createDevicePlant(
		entryId: number,
		companyId: number,
		plantName: string,
		mmsi: number | null,
		imo: number | null
	) {
		const args: CreateDevicePlantArgs = {
			entryId: entryId,
			companyId: companyId,
			plantName: plantName,
			plantMmsi: mmsi,
			plantImo: imo,
		}
		send(messagesFromClient.createDevicePlant, args)
	}

	function fetchLogger(entryId: number, key: string, severity: string[]) {
		setLoggerLines([])
		const entry = deviceEntries.find((de) => de.entryId == entryId)
		if (entry == undefined) {
			throw new Error(`Device not found by entry id: ${entryId}`)
		}
		setLoggerLines([`(awaiting data from ${entry.credentials?.name})`])
		send(messagesFromClient.fetchLogEntries, {
			entryId: entry.entryId,
			key: key,
			severity: severity,
		})
	}

	function configJsonFetch(entryId: number) {
		const pendingRequestId = nextRequestId++
		setPendingRequestId(pendingRequestId)
		send(edgeAdminApi.fromPortal.fetchConfigJson, {
			entryId: entryId,
			requestId: pendingRequestId,
		})
	}

	function configJsonPush(entryId: number, data: string) {
		send(edgeAdminApi.fromPortal.pushConfigJson, {
			data: data,
			entryId: entryId,
			requestId: -1,
		})
	}

	function issueDeviceSend(entryId: number, timestampGt: Date, timestampLt: Date) {
		send(edgeAdminApi.fromPortal.issueDeviceSend, {
			entryId: entryId,
			timestampGt: timestampGt.toISOString(),
			timestampLt: timestampLt.toISOString(),
		})
	}
	return (
		<>
			{pageInfo == undefined ? (
				<>Loading...</>
			) : (
				<div className={``}>
					<p className={`w-fit rounded-sm bg-amber-100 p-1 text-amber-700`}>
						Note: This page is a temporary solutions and will be modified
					</p>
					<div>
						<p className={`text-body font-semibold`}>Devices</p>
						<EzTable
							data={deviceEntries
								.filter((d) => d.credentials != null)
								.map((device) => [
									device.entryId,
									device.credentials?.dbId,
									device.credentials?.name,
									device.ip,
									device.credentials?.sshInfo?.port,
									device.credentials?.sshInfo?.userName,
									<div className={`flex flex-row gap-2 px-1 py-1`}>
										<Button
											disabled={device._removed}
											label="stream nmea"
											onClick={() => {
												send(messagesFromClient.openDataStream, {
													entryId: device.entryId,
												})
											}}
										/>
										<Button
											disabled={device._removed}
											label="init ssh info"
											onClick={() =>
												send(messagesFromClient.generateSshInfo, {
													entryId: device.entryId,
												})
											}
										/>
										<Button
											disabled={
												device._removed ||
												device.credentials?.sshInfo == undefined
											}
											label="init ssh"
											onClick={() => {
												initSsh(device.entryId)
											}}
										/>
										<Button
											disabled={
												device._removed ||
												device.credentials?.sshInfo == undefined
											}
											label="stop ssh"
											onClick={() =>
												send(messagesFromClient.stopSshTunnel, {
													entryId: device.entryId,
												})
											}
										/>
										<Button
											disabled={
												device._removed ||
												device.credentials?.sshInfo == undefined
											}
											label="compose pull"
											onClick={() =>
												send(messagesFromClient.doDockerComposePull, {
													entryId: device.entryId,
												})
											}
										/>
										<Button
											disabled={
												device._removed ||
												device.credentials?.sshInfo == undefined
											}
											label="compose up"
											onClick={() =>
												send(messagesFromClient.doDockerComposeUp, {
													entryId: device.entryId,
												})
											}
										/>
										<Button
											disabled={
												device._removed ||
												device.credentials?.sshInfo == undefined
											}
											label="compose down"
											onClick={() => promptDockerComposeDown(device)}
										/>
										<Button
											disabled={device._removed}
											label="erase credentials"
											onClick={() => promptEraseCredentials(device)}
										/>
									</div>,
									device._removed
										? "(offline)"
										: new Date(device.createdAt).toLocaleString(),
									device.generatedTimestamp != null
										? new Date(device.generatedTimestamp).toLocaleString()
										: "(null)",
								])}
							header={[
								"entry id",
								"device id",
								"name",
								"ip",
								"ssh port",
								"ssh user",
								"controls",
								"online since",
								"t0",
							]}
						/>
					</div>
					{/* <Button label="request connect" onClick={demo} /> */}
					<div className={`mt-2 border-1 border-slate-300 p-2`}>
						<p className={`pb-1 text-body font-semibold`}>Un-authorized devices</p>
						<EzTable
							data={deviceEntries
								.filter((d) => d.credentials == null)
								.map((device) => [
									device.entryId,
									device.ip,
									<NewPlantCell
										deviceIp={device.ip}
										pageInfo={pageInfo}
										onSubmit={(companyId, plantName, mmsi, imo) =>
											createDevicePlant(
												device.entryId,
												companyId,
												plantName,
												mmsi,
												imo
											)
										}
									/>,
									<DevicePlantSelectionCell
										device={device}
										onPermit={(plantId) =>
											send(messagesFromClient.permitDevice, {
												entryId: device.entryId,
												plantId: plantId,
											})
										}
										plants={pageInfo?.plants ?? []}
									/>,
									<Button
										disabled={device._removed}
										label="erase credentials"
										onClick={() => promptEraseCredentials(device)}
									/>,
									device._removed ? "(offline)" : device.createdAt,
									device.generatedTimestamp ?? "(null)",
								])}
							header={[
								"id",
								"ip",
								"assign to new plant",
								"assign to existing plant",
								"",
								"online since",
								"t0",
							]}
						/>

						<div className={`pt-1`}>
							<h3>Revoke access</h3>
							<div className={`flex h-8 flex-row gap-2`}>
								<select
									className={`h-8 py-0`}
									onChange={(evt) =>
										setSelectedDeviceId(parseFloat(evt.target.value))
									}
								>
									<option></option>
									{pageInfo.devices.map((device, index) => (
										<option key={index} value={device.id}>
											{device.name}
										</option>
									))}
								</select>
								<Button label="revoke" onClick={revokeDeviceAccess} />
							</div>
						</div>
					</div>
					<div className={`mt-2 border-1 border-slate-300 p-2`}>
						<p className={`text-body font-semibold`}>Messages</p>
						<div className="textview p-2">
							{infoMessages.map((message, index) => (
								<p key={index}>{message}</p>
							))}
						</div>
					</div>
					<div className={`p-2`}>
						<p className={`text-body font-semibold`}>How to use SSH</p>
						<ol>
							<li>
								Edge device ssh public key must be added to
								"~/.ssh/authorized_keys". If generated it resides in the db.
							</li>
							<li>Run "init ssh" command on a device</li>
							<li>
								Connect to ssh vm: <code>ssh sshuser@ssh.dipai.no</code>
							</li>
							<li>Enter "sshuser" password</li>
							<li>
								Connect to the live tunnel (USERNAME is "ssh user"):{" "}
								<code>ssh USERNAME@ssh.dipai.no -p PORT</code>
							</li>
							<li>
								Enter password that is set for the local user on the edge device
							</li>
						</ol>
					</div>
					<ConfigJsonController
						devices={selectableDevices}
						doFetch={(deviceId) => configJsonFetch(deviceId)}
						doPush={(deviceId, data) => configJsonPush(deviceId, data)}
						receivedData={configJsonFetchResult ?? null}
						receivedPushResult={configJsonPushResult ?? null}
					/>
					<div className={`mb-2 mt-2 border-1 border-slate-300 p-2`}>
						<p className={`text-body font-semibold`}>Logger</p>
						<h3>Logger controller</h3>
						<LoggerController
							pageInfo={pageInfo}
							onOpen={fetchLogger}
							selectAbleDevices={selectableDevices}
						/>
						<h3 className={`pt-1`}>Logger output</h3>
						<div className="textview">
							{loggerLines.map((message, index) =>
								message.split("\n").map((line) => (
									<p
										style={{ whiteSpace: "pre", fontFamily: "courier" }}
										key={index}
									>
										{line}
									</p>
								))
							)}
						</div>
						<h3 className={`pt-1`}>Issue data send</h3>
						<DeviceDataSendComponent
							devices={selectableDevices}
							onSubmit={issueDeviceSend}
						/>
						<p
							className={`${
								pageStatus === "Connected" ? "text-green-700" : "text-amber-700"
							} pt-1 text-body font-semibold`}
						>
							{pageStatus}
						</p>
					</div>
				</div>
			)}
		</>
	)
}
