import { InformationContainer } from "@components/containers/Containers"
import SectionTitle from "@components/sectionTitle/SectionTitle"
import { SectionTitleSize, SectionTitleType } from "@components/sectionTitle/types"
import { useEffect, useRef, useState } from "react"

const VesselInfoFullTextLine = ({ data }: { data: [string, string[]] }) => {
	return (
		<>
			<p className={`col-span-8 text-justify font-normal`}>{data[1]}</p>
		</>
	)
}

const VesselInfoClassDefinitionLine = ({ data }: { data: [string, string[]] }) => {
	return (
		<>
			<div className={`col-span-8 font-semibold`}>{data[0]}</div>
			<div className={`col-span-8 flex justify-end`}>
				{Array.isArray(data[1]) === true ? (
					<div className={`flex flex-col`}>
						{data[1].map((entry, index) => (
							<div className={`flex justify-end pt-[7px]`} key={index}>
								{entry}
							</div>
						))}
					</div>
				) : null}
			</div>
		</>
	)
}

const VesselInfoNormalLine = ({ data }: { data: [string, string[]] }) => {
	const refLabel = useRef<HTMLParagraphElement>(null)
	const [ref, setRef] = useState<(HTMLParagraphElement | null)[]>([])
	const [refLabelSpan, setRefLabelSpan] = useState<string>("col-span-4")
	const [refSpan, setRefSpan] = useState<string[]>([])
	const [refPadding, setRefPadding] = useState<string[]>([])

	useEffect(() => {
		if (refLabel.current === null) {
			return
		}
		if (
			(refLabel.current?.clientHeight !== undefined && refLabel.current?.clientHeight > 30) ||
			(refLabel.current?.parentNode as HTMLElement)?.className.includes("col-span-8") === true
		) {
			setRefLabelSpan("col-span-8")
		}
	}, [refLabel.current])

	useEffect(() => {
		if (ref.length === 0) {
			return
		}
		let tempRefSpan = refSpan
		let tempRefPadding = refPadding

		for (const [idx, r] of ref.entries()) {
			if (tempRefSpan[idx] === undefined) {
				tempRefSpan.push("col-span-4")
			}
			if (
				(r?.clientHeight !== undefined && r?.clientHeight! > 30) ||
				(r?.parentNode as HTMLElement)?.className.includes("col-span-8") === true
			) {
				tempRefSpan = tempRefSpan.map((span, index) =>
					index === idx ? "col-span-8" : span
				)
			}

			if (refPadding[idx] === undefined) {
				tempRefPadding.push("")
			}
			if (
				idx > 0 ||
				(refLabel.current?.parentNode as HTMLElement)?.className.includes("col-span-8") ===
					true ||
				(r?.parentNode as HTMLElement)?.className.includes("col-span-8") === true
			) {
				tempRefPadding = tempRefPadding.map((padding, index) =>
					index === idx ? "pt-[7px]" : padding
				)
			}
		}
		setRefSpan(tempRefSpan)
		setRefPadding(tempRefPadding)
	}, [ref.length])

	return (
		<>
			<div className={`${refLabelSpan} font-semibold`}>
				<p ref={refLabel}>{data[0]}</p>
			</div>
			{Array.isArray(data[1]) === true ? (
				<>
					{data[1].map((entry, index) => (
						<div
							className={`${refSpan[index] ?? "col-span-4"} flex justify-end ${
								refPadding[index] ?? ""
							}`}
							key={index}
							style={{ gridColumnEnd: index === 0 ? "auto" : -1 }}
						>
							<p
								ref={(newRef) => {
									setRef((prevRef) => {
										if (prevRef.length < index + 1) {
											prevRef.push(newRef)
										}
										return prevRef
									})
								}}
								id={`${entry}`}
							>
								{entry}
							</p>
						</div>
					))}
				</>
			) : null}
		</>
	)
}

export default function VesselSpecSheetContent({
	vesselSpecSheetData,
}: {
	vesselSpecSheetData: { [vesselProp: string]: string[] }
}) {
	const [vesselInfoContainerRef, setVesselInfoContainerRef] = useState<HTMLDivElement | null>(
		null
	)
	const [vesselInfoContainerRefHeight, setVesselInfoContainerRefHeight] = useState<number>(0)

	useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => {
			const entries_0 = entries[0]
			if (entries_0 === undefined) {
				return
			}
			const newHeight = entries_0.target.clientHeight
			setVesselInfoContainerRefHeight(newHeight)
		})

		if (vesselInfoContainerRef !== null) {
			resizeObserver.observe(vesselInfoContainerRef)
		}

		return () => {
			if (vesselInfoContainerRef !== null) {
				resizeObserver.unobserve(vesselInfoContainerRef)
			}
		}
	}, [vesselInfoContainerRef])

	return (
		<>
			<SectionTitle
				title={"VESSEL INFORMATION"}
				type={SectionTitleType.vessel}
				size={SectionTitleSize.medium}
			/>
			<InformationContainer>
				<>
					<div
						className={`flex w-full max-w-[557px] flex-col flex-wrap text-button`}
						style={{
							height: Math.ceil(vesselInfoContainerRefHeight / 2 / 100) * 110,
						}}
					>
						{Object.entries(vesselSpecSheetData).map((data) => (
							<div
								key={data[0]}
								className={`grid h-auto w-[calc(50%-2px-8px)] grid-cols-8 border-b-1 pb-[2px] pr-2 pt-1`}
							>
								{data[0].includes("*") === true ? (
									<VesselInfoFullTextLine data={data} />
								) : data[0] === "Class definition" ? (
									<VesselInfoClassDefinitionLine data={data} />
								) : (
									<VesselInfoNormalLine data={data} />
								)}
							</div>
						))}
					</div>
					{/* USE THIS DIV TO FIND THE RENDERED LIST SIZE TO USE AS REFERENCE HEIGHT
                        AND POSITION IT OUTSIDE OF VIEW */}
					<div
						ref={(newRef) => setVesselInfoContainerRef(newRef)}
						className={`absolute flex w-full flex-col flex-wrap text-button opacity-0`}
					>
						{Object.entries(vesselSpecSheetData).map((data) => (
							<div
								key={data[0]}
								className={`grid h-auto w-[calc(260px)] grid-cols-8 border-b-1 pb-[2px] pr-2 pt-1`}
							>
								{data[0].includes("*") === true ? (
									<VesselInfoFullTextLine data={data} />
								) : data[0] === "Class definition" ? (
									<VesselInfoClassDefinitionLine data={data} />
								) : (
									<VesselInfoNormalLine data={data} />
								)}
							</div>
						))}
					</div>
				</>
			</InformationContainer>
		</>
	)
}
