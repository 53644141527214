import IconSvgContainer, { IconProps, IconSize } from "../IconSvgContainer"

export default function BiomassIcon({ size = IconSize.medium }: IconProps) {
	return (
		<IconSvgContainer size={size} fill={true} viewBox={"0 0 1200 1100"}>
			<g>
				<path
					d="m205 220 l795 0 l170 880 l-1140 0 l200 -880"
					fill="none"
					strokeWidth={60}
				/>
				<path
					d="M 600, 100
						m -100, 0
						a 100,100 0 1,0 200,0
						a 100,100 0 1,0 -200,0
						"
					fill="none"
					strokeWidth={60}
				/>
				<g
					transform="
                translate(255, 280)
				scale(0.75)"
				>
					<g>
						<path
							d="M808.7808,385.6338c10.2539,95.3657,1.0683,163.936-72.9473,231.3144
					c-6.9658,6.4522-44.0146,52.2139-51.0635,57.7413c1.7266-1.5821,3.4942-2.9795,5.2608-4.5616
					c74.0361-67.3789,113.1396-175.8955,102.9277-271.2607c-8.2197-97.2769-61.4404-176.9434-118.8935-219.7046
					c-32.2618-23.3022-62.5909-37.4395-89.879-45.8643c-38.6933,2.6299-62.6523,14.0962-66.5156,15.1031
					c7.1094,2.8354,92.7149,35.0556,131.3252,116.1401c45.2481,85.5024,12.001,179.8403-31.0283,225.6636
					c-35.918,49.9946-92.9609,95.3047-100.79,177.0459c0.1025,39.7197,21.8632,75.4541,78.372,86.5302
					c53.9395,10.5206,148.1338-9.8847,222.252-99.6406c64.3584-76.666,88.6054-189.4565,65.6933-282.5825
					c-21.164-95.2837-84.5566-167.1006-147.2089-201.8071c-35.2207-18.9248-67.461-28.8911-95.7149-33.5762
					c15.8633,7.7266,32.3633,17.4663,49.3779,29.7749C747.3813,208.7524,800.561,288.3779,808.7808,385.6338z"
						/>
						<path
							d="M447.0244,101.9619c18.5142,32.3638,49.9536,52.645,84.064,58.2139
					c12.4316-32.2818,10.7881-69.6187-7.7266-101.9619C504.8267,25.8706,473.3882,5.5894,439.2573,0
					C426.8252,32.2612,428.4692,69.6597,447.0244,101.9619z"
						/>
						<path
							d="M436.7704,130.75c34.8096-13.377,71.9605-9.2671,101.9214,7.9526
					c-10.6855,32.857-35.5293,60.8233-70.3589,74.1797c-34.7886,13.398-71.9809,9.2881-101.9409-7.9316
					C377.1187,172.0732,401.9819,144.1479,436.7704,130.75z"
						/>
						<path
							d="M646.0366,482.6021c14.795,26.4873,3.5547,44.6313-15.8427,47.4467
				c-20.4864,3.0205-33.5967-4.1919-39.9463-14.8979c-9.2471-22.48-34.6241-70.666-36.4532-70.8101
				C550.897,443.3955,616.7349,433.9434,646.0366,482.6021z"
						/>
						<path
							d="M853.145,605.0713c-23.0761,15.3496-39.6386,15.8428-54.6386,1.376
				c-14.6094-13.8496-5.6914-39.3702,19.4179-55.501c50.8574-38.5283,133.9151-15.4522,128.6543-9.9658
				C941.5444,537.0762,890.8306,574.9268,853.145,605.0713z"
						/>
					</g>
					<g>
						<path
							d="M138.0161,624.3457c-10.2539-95.3452,4.7261-167.3267,78.7622-234.6846
					c6.9868-6.4521,38.1997-48.8437,45.2481-54.33c-1.7056,1.541-3.4932,2.9384-5.2608,4.5615
					c-74.0156,67.3379-113.1191,175.875-102.9067,271.2617c8.2402,97.2148,61.4809,176.8809,118.8936,219.6426
					c32.2407,23.3427,62.5908,37.4804,89.8588,45.9043c38.7134-2.6094,62.6524-14.0752,66.5362-15.123
					c-7.0894-2.7949-92.7149-35.0351-131.3462-116.1406c-45.1861-85.502-11.98-179.8193,31.0283-225.6221
					c35.939-50.0151,92.9614-95.3452,100.811-177.0664c-0.0825-39.7202-21.8842-75.4951-78.3925-86.5298
					c-53.94-10.5-148.1138,9.8838-222.253,99.5987c-64.3374,76.687-88.5844,189.4775-65.6728,282.645
					c21.165,95.2012,84.5366,167.0586,147.1889,201.7656c35.2408,18.9248,67.4605,28.8906,95.7149,33.5762
					c-15.8018-7.7471-32.3023-17.4863-49.3164-29.7754C199.4561,801.2686,146.2559,721.6016,138.0161,624.3457z"
						/>
						<path
							d="M499.8345,907.9971c-18.5557-32.3232-49.9741-52.6241-84.1055-58.1934
					c-12.4316,32.2822-10.8086,69.6397,7.7261,101.9619c18.5351,32.3438,49.9741,52.666,84.0845,58.2344
					C519.9711,977.7188,518.3481,940.3613,499.8345,907.9971z"
						/>
						<path
							d="M510.0474,879.2295c-34.7686,13.3564-71.9615,9.2471-101.9419-7.9736
					c10.6855-32.8155,35.5903-60.8028,70.3794-74.1583c34.7676-13.4189,71.9599-9.2891,101.9404,7.9102
					C569.6996,837.9063,544.8354,865.873,510.0474,879.2295z"
						/>
						<path
							d="M300.7603,527.4185c-14.7745-26.5284-3.5137-44.6519,15.8838-47.4878
				c20.4663-3.0205,33.5556,4.2334,39.9257,14.9389c9.2471,22.439,34.604,70.6245,36.4737,70.8101
				C395.8999,566.625,330.083,576.0566,300.7603,527.4185z"
						/>
						<path
							d="M93.6724,404.8877c23.0556-15.3291,39.6382-15.7813,54.6386-1.3355
				c14.6509,13.8291,5.6919,39.3501-19.4389,55.5425C78.0352,497.6436-5.022,474.4854,0.2383,468.999
				C5.273,472.9033,55.9863,435.0527,93.6724,404.8877z"
						/>
					</g>
				</g>
			</g>
		</IconSvgContainer>
	)
}
