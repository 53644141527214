export enum IconId {
	NoIcon = "NO_ICON",
	Clear = "CLEAR",
	Energy = "ENERGY",
	Fuel = "FUEL",
	Fish = "FISH",
	Biomass = "BIOMASS",
	CO2Water = "CO2_WATER",
	O2Water = "O2_WATER",
	PhWater = "PH_WATER",
	TemperatureWater = "TEMPERATURE_WATER",
}
