export class CompassDraw {
	compass(size: number, center: number) {
		//ring
		this.ctx.beginPath()
		this.ctx.arc(center, center, size + 16, 0, 2 * Math.PI)
		this.ctx.fillStyle = "#5c7c97"
		this.ctx.fill()
		//center
		this.ctx.beginPath()
		this.ctx.arc(center, center, size, 0, 2 * Math.PI)
		this.ctx.fillStyle = "#a3b8c9"
		this.ctx.fill()

		//Lines
		this.ctx.lineWidth = 2
		this.ctx.strokeStyle = "#DDDDDD"
		this.ctx.beginPath()
		this.ctx.moveTo(center - size - 16, center)
		this.ctx.lineTo(center + size + 16, center)
		//this.ctx.strokeStyle = "#222222"
		this.ctx.stroke()
		this.ctx.beginPath()
		this.ctx.moveTo(center, center - size - 16)
		this.ctx.lineTo(center, center + size + 16)
		this.ctx.stroke()

		//this.ctx.strokeStyle = "#555555"
		this.ctx.lineWidth = 2

		this.ctx.translate(center, center)

		for (let i = 22.5; i < 360; i = i + 22.5) {
			if (i != 90 && i != 180 && i != 270) {
				const ang = (i * Math.PI) / 180

				this.ctx.rotate(ang)
				this.ctx.translate(0, -72)
				this.ctx.beginPath()
				this.ctx.moveTo(0, 0)
				this.ctx.lineTo(0, -11)
				this.ctx.stroke()
				this.ctx.translate(0, 72)
				this.ctx.rotate(-ang)
			}
		}

		this.ctx.translate(-center, -center)

		this.ctx.fillStyle = "#000000"
		this.ctx.font = "12px Arial"
		this.ctx.textAlign = "center"
		this.ctx.fillText("N", center, center - size - 20)
		this.ctx.fillText("S", center, center + size + 30)
		this.ctx.fillText("W", center - size - 25, center + 5)
		this.ctx.fillText("E", center + size + 22, center + 5)

		this.ctx.restore()
		this.ctx.save()
	}
	gaugeDouble(size: number, center: number, maxValue: [number, number], unit: [string, string]) {
		this.ctx.resetTransform()

		this.ctx.translate(center, center)
		this.ctx.strokeStyle = "#aaaaaa"
		this.ctx.fillStyle = "#dddddd"
		this.ctx.lineWidth = 1

		this.ctx.beginPath()
		this.ctx.rotate(-(90 * Math.PI) / 180)
		this.ctx.arc(0, 0, size - 1, (45 * Math.PI) / 180, (135 * Math.PI) / 180)
		this.ctx.arc(0, 0, size - 20, (135 * Math.PI) / 180, (45 * Math.PI) / 180, true)
		//this.ctx.rotate((90 * Math.PI) / 180)
		this.ctx.fill()
		this.ctx.stroke()
		this.ctx.beginPath()
		this.ctx.arc(0, 0, size - 1, (225 * Math.PI) / 180, (315 * Math.PI) / 180)
		this.ctx.arc(0, 0, size - 20, (315 * Math.PI) / 180, (225 * Math.PI) / 180, true)
		this.ctx.fill()
		this.ctx.stroke()

		this.ctx.strokeStyle = "#dddddd"
		this.ctx.lineWidth = 2

		this.ctx.rotate((45 * Math.PI) / 180)

		this.ctx.beginPath()
		this.ctx.moveTo(size + 20, 0)
		this.ctx.lineTo(size - 25, 0)
		this.ctx.stroke()

		this.ctx.rotate((90 * Math.PI) / 180)

		this.ctx.beginPath()
		this.ctx.moveTo(size + 20, 0)
		this.ctx.lineTo(size - 25, 0)
		this.ctx.stroke()

		this.ctx.rotate((90 * Math.PI) / 180)

		this.ctx.beginPath()
		this.ctx.moveTo(size + 20, 0)
		this.ctx.lineTo(size - 25, 0)
		this.ctx.stroke()

		this.ctx.rotate((90 * Math.PI) / 180)

		this.ctx.beginPath()
		this.ctx.moveTo(size + 20, 0)
		this.ctx.lineTo(size - 25, 0)
		this.ctx.stroke()

		this.ctx.rotate((135 * Math.PI) / 180)

		this.ctx.fillStyle = "#000000"
		this.ctx.font = "12px Segoe UI"
		this.ctx.textAlign = "center"
		this.ctx.fillText(`0${unit[0]}`, -size - 15, size + 10)
		this.ctx.fillText(`0${unit[1]}`, size + 10, size + 10)
		this.ctx.fillText(maxValue[0] + unit[0], -size - 15, -size + 9)
		this.ctx.fillText(maxValue[1] + unit[1], size + 10, -size + 9)

		this.ctx.translate(-center, -center)
		this.ctx.restore()
		this.ctx.save()
	}
	gaugeDoubleVal(
		speed: number[],
		unit: string,
		center: number,
		size: number,
		position: "top" | "bottom",
		title: string
	) {
		this.ctx.resetTransform()

		if (position === "top") {
			this.ctx.transform(1, 0, 0, -1, 0, 200)
		}
		this.ctx.translate(center, center)

		this.ctx.beginPath()
		this.ctx.moveTo(0, 35)
		this.ctx.lineTo(20, 35)
		this.ctx.quadraticCurveTo(25, 35, 29, 37)
		this.ctx.arc(0, 0, size, (50 * Math.PI) / 180, (130 * Math.PI) / 180)
		this.ctx.lineTo(-29, 37)
		this.ctx.quadraticCurveTo(-25, 35, -22, 35)
		this.ctx.lineTo(-22, 35)
		this.ctx.lineTo(0, 35)
		this.ctx.fillStyle = "#7794ac"
		this.ctx.strokeStyle = "#aaaaaa"
		this.ctx.fillStyle = "#DDDDDD"
		this.ctx.fill()

		let flip = 1

		let add = 0
		this.ctx.fillStyle = "#eeeeee"
		this.ctx.fillStyle = "#666666"
		this.ctx.font = "12px Arial"
		this.ctx.textAlign = "center"

		//this.ctx.fillStyle = "#332288"
		if (position === "top") {
			this.ctx.resetTransform()
			this.ctx.translate(center, center + 7)
			flip = -1
			add = 2
		}

		if (speed.length > 1) {
			this.ctx.fillText(
				`${speed[0]?.toFixed(1)}${unit} / ${speed[1]?.toFixed(1)}${unit}`,
				0,
				(size - 18) * flip + add
			)
		} else {
			this.ctx.fillText(`${speed[0]?.toFixed(1)}${unit}`, 0, (size - 18) * flip + add)
		}

		//this.ctx.fillStyle = "#f47300"

		this.ctx.font = "10px Arial"
		this.ctx.fillText(title, 0, (size - 7) * flip + add)
		this.ctx.restore()
		this.ctx.save()
	}
	gaugeDoubleLine(
		waveHeight: number,
		color: string,
		center: number,
		size: number,
		offset: number,
		maxHeight: number,
		multiplier: number,
		mirror: boolean
	) {
		this.ctx.resetTransform()

		if (mirror) {
			this.ctx.setTransform(-1, 0, 0, 1, 200, 0)
		}

		this.ctx.translate(center, center)
		this.ctx.beginPath()
		this.ctx.rotate(-(90 * Math.PI) / 180)
		this.ctx.arc(0, 0, size - 1, (225 * Math.PI) / 180, (315 * Math.PI) / 180)
		this.ctx.arc(0, 0, size - 16, (315 * Math.PI) / 180, (225 * Math.PI) / 180, true)
		this.ctx.clip()
		this.ctx.rotate((90 * Math.PI) / 180)

		this.ctx.rotate((135 * Math.PI) / 180)
		this.ctx.strokeStyle = color
		this.ctx.beginPath()
		this.ctx.moveTo(size - offset, 0)
		if (waveHeight > maxHeight) waveHeight = maxHeight
		const heightDeg = (90 / maxHeight) * waveHeight
		this.ctx.lineCap = "round"
		this.ctx.arc(0, 0, size - offset, 0, (heightDeg * Math.PI) / 180)
		this.ctx.lineWidth = 6 * multiplier
		if (heightDeg > 0) this.ctx.stroke()

		this.ctx.restore()
		this.ctx.save()
	}
	gaugeSingle(size: number, center: number, maxSpeed: number, unit: string) {
		this.ctx.resetTransform()

		//Speedometer
		this.ctx.translate(center, center)

		this.ctx.strokeStyle = "#aaaaaa"
		this.ctx.fillStyle = "#dddddd"
		this.ctx.lineWidth = 1

		this.ctx.beginPath()
		this.ctx.arc(0, 0, size - 1, (135 * Math.PI) / 180, (45 * Math.PI) / 180)
		this.ctx.arc(0, 0, size - 20, (45 * Math.PI) / 180, (135 * Math.PI) / 180, true)
		this.ctx.fill()
		this.ctx.stroke()

		this.ctx.strokeStyle = "#dddddd"
		this.ctx.lineWidth = 2

		this.ctx.rotate((45 * Math.PI) / 180)

		this.ctx.beginPath()
		this.ctx.moveTo(size + 20, 0)
		this.ctx.lineTo(size - 25, 0)
		this.ctx.stroke()

		this.ctx.rotate((90 * Math.PI) / 180)

		this.ctx.beginPath()
		this.ctx.moveTo(size + 20, 0)
		this.ctx.lineTo(size - 25, 0)
		this.ctx.stroke()

		this.ctx.rotate((-135 * Math.PI) / 180)

		// Speed value box

		this.ctx.strokeStyle = "#aaaaaa"
		this.ctx.fillStyle = "#DDDDDD"

		this.ctx.beginPath()
		this.ctx.moveTo(0, 35)
		this.ctx.lineTo(20, 35)
		this.ctx.quadraticCurveTo(25, 35, 29, 37)
		this.ctx.arc(0, 0, size, (50 * Math.PI) / 180, (130 * Math.PI) / 180)
		this.ctx.lineTo(-29, 37)
		this.ctx.quadraticCurveTo(-25, 35, -22, 35)
		this.ctx.lineTo(-22, 35)
		this.ctx.lineTo(0, 35)
		this.ctx.fillStyle = "#7794ac"
		this.ctx.strokeStyle = "#aaaaaa"
		this.ctx.fillStyle = "#DDDDDD"
		this.ctx.fill()

		this.ctx.fillStyle = "#000000"
		this.ctx.font = "12px Segoe UI"
		this.ctx.textAlign = "center"
		this.ctx.fillText(`0${unit}`, -size - 10, size + 10)
		this.ctx.fillText(maxSpeed + unit, size + 15, size + 10)
		this.ctx.translate(-center, -center)
		this.ctx.restore()
		this.ctx.save()
	}
	gaugeSingleVal(
		speed: number,
		speed2: number,
		speedText: string,
		speed2Text: string,
		center: number,
		size: number,
		mouseX: number,
		mouseY: number,
		disable: boolean,
		noData: boolean
	) {
		this.ctx.translate(center, center)

		const button = new Path2D()
		button.moveTo(0, 40)
		button.lineTo(25, 40)
		button.quadraticCurveTo(35, 40, 42, 50)
		button.arc(0, 0, size - 1, (50 * Math.PI) / 180, (130 * Math.PI) / 180)
		button.lineTo(-42, 50)
		button.quadraticCurveTo(-35, 40, -25, 40)
		button.lineTo(-25, 40)
		button.closePath()
		//this.ctx.fill(button)
		//this.ctx.isPointInPath(button, 150, 150 + 70)

		let displaySpeed = speed.toFixed(1)
		let displayText = speedText
		if (!disable) {
			displayText = speedText
			if (this.ctx.isPointInPath(button, mouseX, mouseY)) {
				displaySpeed = speed2.toFixed(1)
				displayText = speed2Text
			}
		}
		if (noData) {
			displaySpeed = "-- "
			displayText = " "
		}

		this.ctx.fillStyle = "#555555"
		this.ctx.font = "18px Arial"
		this.ctx.textAlign = "center"
		this.ctx.fillText(displaySpeed + "kn", 0, size - 16)
		this.ctx.font = "12px Arial"
		this.ctx.textAlign = "center"
		this.ctx.fillText(displayText, 0, size - 4)
		this.ctx.restore()
		this.ctx.save()
	}
	gaugeSingleLine(
		speed: number,
		color: string,
		center: number,
		size: number,
		offset: number,
		maxSpeed: number,
		multiplier: number
	) {
		this.ctx.resetTransform()

		const gradient = this.ctx.createConicGradient(0, 0, 0)
		//this.ctx.lineCap = "round"
		// Add gradient
		gradient.addColorStop(0, "#6e90d0")
		gradient.addColorStop(0.1, "#0f94a7")
		gradient.addColorStop(0.2, "#39a239")
		gradient.addColorStop(0.3, "#c2863e")
		gradient.addColorStop(0.4, "#c8420d")
		gradient.addColorStop(0.5, "#d20032")
		gradient.addColorStop(0.6, "#af5088")
		gradient.addColorStop(0.7, "#754a92")
		gradient.addColorStop(0.8, "#45698d")

		this.ctx.translate(center, center)
		this.ctx.rotate((135 * Math.PI) / 180)
		this.ctx.strokeStyle = color
		this.ctx.beginPath()
		this.ctx.moveTo(size - offset, 0)
		if (speed > maxSpeed) speed = maxSpeed
		const speedDeg = (100 / maxSpeed) * speed * 2.7
		this.ctx.lineCap = "butt"
		this.ctx.arc(0, 0, size - offset, 0, ((speedDeg / 2) * Math.PI) / 180)
		this.ctx.lineWidth = 6 * multiplier
		this.ctx.stroke()

		if (speedDeg > 3) {
			this.ctx.beginPath()
			this.ctx.arc(
				0,
				0,
				size - offset,
				((speedDeg / 2) * Math.PI) / 180,
				(speedDeg * Math.PI) / 180
			)
			this.ctx.lineCap = "round"

			this.ctx.lineWidth = 6 * multiplier
			this.ctx.stroke()
		}
		this.ctx.rotate((-135 * Math.PI) / 180)
		this.ctx.translate(-center, -center)

		this.ctx.restore()
		this.ctx.save()
	}
	//Arrow rotating around the circle pointing inwards
	arrow(rot: number, color: string, center: number, size: number) {
		this.ctx.resetTransform()

		this.ctx.fillStyle = color
		this.ctx.strokeStyle = "#FFFFFFbb"
		this.ctx.shadowColor = "#00000066"
		this.ctx.shadowOffsetX = 1
		this.ctx.shadowOffsetY = 1
		this.ctx.shadowBlur = 1
		this.ctx.lineWidth = 1
		this.ctx.beginPath()
		this.ctx.translate(center, center)
		this.ctx.rotate((-90 * Math.PI) / 180)
		this.ctx.rotate((rot * Math.PI) / 180)
		this.ctx.moveTo(69, 0)
		this.ctx.arc(0, 0, 84, (-5 * Math.PI) / 180, (5 * Math.PI) / 180)
		this.ctx.lineTo(69, 0)

		this.ctx.filter = "blur(0.5px)"
		this.ctx.stroke()

		this.ctx.filter = "blur(0px)"
		this.ctx.fill()

		this.ctx.restore()
		this.ctx.save()
	}

	vessel(heading: number, center: number, color: string) {
		this.ctx.fillStyle = color
		this.ctx.strokeStyle = "#88888888"

		this.ctx.lineWidth = 2

		this.ctx.beginPath()
		this.ctx.translate(center, center)
		this.ctx.rotate((heading * Math.PI) / 180)
		this.ctx.moveTo(-12, 30)
		this.ctx.lineTo(-12, -20)
		this.ctx.lineTo(0, -30)
		this.ctx.lineTo(12, -20)
		this.ctx.lineTo(12, 30)
		this.ctx.lineTo(0, 25)
		this.ctx.lineTo(-12, 30)

		this.ctx.fill()

		//this.ctx.stroke()
		this.ctx.translate(-center, -center)
		this.ctx.restore()
		this.ctx.save()
	}
	noData(size: number, center: number, text1: string, text2: string) {
		this.ctx.resetTransform()
		//ring
		this.ctx.beginPath()
		this.ctx.arc(center, center, size, 0, 2 * Math.PI)
		this.ctx.fillStyle = "#ddddddAA"
		this.ctx.fill()
		//center

		this.ctx.translate(center, center)
		this.ctx.beginPath()
		this.ctx.roundRect(-65, -18, 130, 36, 10)

		this.ctx.fillStyle = "#eeeeee"
		this.ctx.fill()

		this.ctx.font = "12px Arial"
		this.ctx.textAlign = "center"
		this.ctx.fillStyle = "#000000"
		this.ctx.fillText(text1, 0, -3)
		this.ctx.fillText(text2, 0, 13)

		this.ctx.restore()
		this.ctx.save()
	}

	constructor(private readonly ctx: CanvasRenderingContext2D) {}
}
export const paramNames = [
	"airTemperature",
	"windSpeed",
	"windDirection",
	"waveHeight",
	"waveDirection",
	"windWaveHeight",
	"windWavePeriod",
	"waterTemperature",
	"currentSpeed",
	"currentDirection",
	"Wind Direction",
] as const

export type CompassTimeseriesData = {
	sensorId: number
	data: { timestamp: number; value: number }[]
}[]

export type CompassTimeseriesDataUniSea = {
	sensorId: string
	data: { timestamp: number; value: number }[]
}[]
