import { useMsal } from "@azure/msal-react"
import { isMobileDevice } from "@helpers/isMobileDevice"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import { clearAppStructure } from "@redux/features/appStructure/appStructureSlice"
import { getUserData, setUserData } from "@redux/features/userData/userDataSlice"
import { useNavigate } from "@tanstack/react-router"
import { requestApi2 } from "@utils/http"
import { authEndpoints } from "api"

export default function useLogoutMicrosoft() {
	const { instance } = useMsal()
	const dispatch = useAppDispatch()
	const userData = useAppSelector(getUserData)
	const navigate = useNavigate()

	const logoutMicrosoft = async () => {
		try {
			const isAuthenticated = userData.isAuthenticated
			if (isAuthenticated === true) {
				const logoutRequest = {
					postLogoutRedirectUri: window.location.origin,
					account: instance.getAccountByUsername(userData.email),
				}
				if (isMobileDevice() === true) {
					// await requestApi2(authEndpoints.logOut)
					// dispatch(clearAppStructure())
					// await instance.logoutRedirect(logoutRequest)
					instance.logoutPopup(logoutRequest)
				} else {
					instance.logoutPopup(logoutRequest)
				}
				try {
					await requestApi2(authEndpoints.logOut)
					dispatch(
						setUserData({
							name: "unknown",
							email: "unknown",
							isAuthenticated: false,
							profilePicture: "",
						})
					)
					dispatch(clearAppStructure())
					navigate({ to: "/" })
					console.log("Logout made successfully")
				} catch (error) {
					console.log("error: ", error)
				}
			}
		} catch (error) {
			console.log("Logout failed: ", error)
		}
	}

	return logoutMicrosoft
}
