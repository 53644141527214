import { fuel } from "api"
import { ScatterDataPoint } from "chart.js"
import { Coefficients, Statistics } from "../types"

export function findFirstRefWithTonnes(
	events?: fuel.TonnesGroupedByOpModeAndDateRefResponse["data"]
): Date | undefined {
	if (events === undefined) return undefined // Check if events is undefined

	// Find the first event with tonnes > 0
	const event = events.find((e) =>
		e.opModeEvents.some((opEvent) => opEvent.tonnesGas > 0 || opEvent.tonnesMdo > 0)
	)

	return event?.ref
}

export const UNKNOWN_MODE_NAME = "Unknown mode"

export const FUELS_DISPLAY_MULTIPLIER = {
	mdo: {
		density: 0.85, // tonnes/m3
		FUEL: 1,
		CO2: 3.206,
		NOX: 0.046,
		SOX: 0.004,
	},
	gas: {
		density: 0.45, // tonnes/m3
		FUEL: 1,
		CO2: 2.75,
		NOX: 0.003,
		SOX: 0,
	},
}

export function monthDiff(date1: Date, date2: Date) {
	// Calculate the difference in total months
	let months = (date2.getFullYear() - date1.getFullYear()) * 12
	// Subtract the month difference to get the total
	months -= date1.getMonth()
	months += date2.getMonth()
	// Return the absolute value in case date2 is before date1
	return Math.abs(months)
}

// getFirstDayOfQuarter will return a Date with first day of the quarter for the input Date
// if the input month is in Q1, the function will return the first day of Q1
// with option "last = true" function will return the last day of the quarter.
export function getFirstDayOfQuarter(date: Date, last?: boolean): Date {
	const year = date.getFullYear()
	const month = date.getMonth() // getMonth() returns a zero-based index (0 = January, 11 = December)
	let hours = date.getHours()
	let startMonth: number

	let day: number
	day = 1

	if (last === true) {
		day = 0
		hours = 0
	}

	if (month < 3) {
		startMonth = 0 // Q1
	} else if (month < 6) {
		startMonth = 3 // Q2
	} else if (month < 9) {
		startMonth = 6 // Q3
	} else {
		startMonth = 9 // Q4
	}

	return new Date(year, startMonth, 1, hours)
}

export function getLastDayOfQuarter(date: Date, offset?: number): Date {
	const year = date.getUTCFullYear()
	const month = date.getUTCMonth() // getMonth() returns a zero-based index (0 = January, 11 = December)

	let startMonth: number
	let day = 31

	if (month < 3) {
		startMonth = 2 // Q1
		day = 31
	} else if (month < 6) {
		startMonth = 5 // Q2
		day = 30
	} else if (month < 9) {
		startMonth = 8 // Q3
		day = 30
	} else {
		startMonth = 11 // Q4
		day = 31
	}

	const returnDate = new Date(
		Date.UTC(year, offset !== undefined ? startMonth + offset * 3 : startMonth, day, 0)
	)

	return returnDate
}

// getFirstDayOfNextQuarter will return a Date with first day of the upcomming quarter for the input Date
// IF the input month is in Q1, output will be first day of Q2
// with option "last = true" function will return the last day of the quarter.
export function getFirstDayOfNextQuarter(date: Date, last?: boolean): Date {
	const year = date.getFullYear()
	const month = date.getMonth() // getMonth() returns a zero-based index (0 = January, 11 = December)
	let hours = 0
	let day: number
	day = 1
	if (last === true) {
		day = 0
		hours = 2
	}
	let startMonth: number
	let startYear: number

	if (month < 3) {
		startMonth = 3 // Q1
		startYear = year
	} else if (month < 6) {
		startMonth = 6 // Q2
		startYear = year
	} else if (month < 9) {
		startMonth = 9 // Q3
		startYear = year
	} else {
		startMonth = 0 // Q4
		startYear = year + 1
	}

	return new Date(startYear, startMonth, day, hours)
}

export const getColorForValue = (value: number, maxValue: number): string => {
	if (maxValue < 1) {
		return "#0060ae"
	}
	const colors = [
		"#003ab0",
		"#1874af",
		"#72b3e9",
		"#92c6e9",
		"#BCBCBC",
		"#ffc300",
		"#ffa100",
		"#ff8a00",
		"#ff5e00",
		"#ff0000",
	]

	if (value >= maxValue) return colors[colors.length - 1] ?? "#ff0000"

	const zeroBasedValue = value - 1
	const zeroBasedMax = maxValue - 1

	// Scale value to range 0-1 and ensure it does not exceed 1
	const scaledValue = Math.min(zeroBasedValue / zeroBasedMax, 1)

	// Compute index ensuring it does not exceed colors.length - 2
	const index = Math.min(Math.floor(scaledValue * (colors.length - 1)), colors.length - 2)
	const fraction = scaledValue * (colors.length - 1) - index

	// Get color codes, ensure they are not undefined

	const color1 = colors[index] ?? "#ff0000"
	const color2 = colors[Math.min(index + 1, colors.length - 1)] ?? "#ff0000" // Ensure we do not go out of bounds

	const hex = (color: string) => parseInt(color.slice(1), 16)
	const lerp = (start: number, end: number, fraction: number): number =>
		Math.round(start + (end - start) * fraction)

	// Decompose the hex colors to RGB
	const r1 = (hex(color1) >> 16) & 255
	const g1 = (hex(color1) >> 8) & 255
	const b1 = hex(color1) & 255
	const r2 = (hex(color2) >> 16) & 255
	const g2 = (hex(color2) >> 8) & 255
	const b2 = hex(color2) & 255

	// Interpolate, recompose to hex, and return
	const r = lerp(r1, r2, fraction)
	const g = lerp(g1, g2, fraction)
	const b = lerp(b1, b2, fraction)
	return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, "0")}`
}

export function calculateMean(data: number[]): number {
	const sum = data.reduce((acc, val) => acc + val, 0)
	return sum / data.length
}

// Function to calculate standard deviation
export function calculateStandardDeviation(data: number[], mean: number): number {
	const squareDiffs = data.map((value) => (value - mean) ** 2)
	const averageSquareDiff = calculateMean(squareDiffs)
	return Math.sqrt(averageSquareDiff)
}

// Function to filter data based on Z-score threshold
export function filterDataByZScore(
	data: [number, number][],
	threshold: number = 3
): [number, number][] {
	const yValues = data.map((point) => point[1])
	const mean = calculateMean(yValues)
	const stdDev = calculateStandardDeviation(yValues, mean)
	return data.filter((point) => {
		const zScore = (point[1] - mean) / stdDev
		return Math.abs(zScore) < threshold
	})
}

export const findIntersections = (coeffA: Coefficients, coeffB: Coefficients) => {
	const a = coeffA.a - coeffB.a
	const b = coeffA.b - coeffB.b
	const c = coeffA.c - coeffB.c

	const discriminant = b * b - 4 * a * c
	if (discriminant < 0) {
		return [] // No real roots
	} else if (discriminant === 0) {
		const root = -b / (2 * a)
		return [root]
	} else {
		const root1 = (-b + Math.sqrt(discriminant)) / (2 * a)
		const root2 = (-b - Math.sqrt(discriminant)) / (2 * a)
		return [root1, root2]
	}
}

export const calculateMedian = (numbers: number[]): number => {
	const sortedNumbers = [...numbers].sort((a, b) => a - b)
	const middleIndex = Math.floor(sortedNumbers.length / 2)

	if (sortedNumbers.length % 2 === 0) {
		const middleLeft = sortedNumbers[middleIndex - 1]
		const middleRight = sortedNumbers[middleIndex]

		if (middleLeft === undefined || middleRight === undefined) {
			throw new Error("Unexpected undefined value in sorted numbers")
		}

		return (middleLeft + middleRight) / 2
	} else {
		const middle = sortedNumbers[middleIndex]

		if (middle === undefined) {
			throw new Error("Unexpected undefined value in sorted numbers")
		}

		return middle
	}
}

export const calculateStatistics = (
	dataA: ScatterDataPoint[],
	dataB: ScatterDataPoint[]
): Statistics => {
	if (dataA.length !== dataB.length) {
		throw new Error("Datasets must be of the same length for comparison.")
	}

	const differences = dataA.map((point, index) => {
		const pointB = dataB[index]
		if (pointB === undefined) {
			throw new Error(`No corresponding point found in dataset B for index ${index}`)
		}

		const percentDiff = ((pointB.y - point.y) / point.y) * 100
		return {
			percentDiff,
			x: point.x,
		}
	})

	const maxDifferencePoint = differences.reduce(
		(max, current) => (current.percentDiff > (max?.percentDiff ?? -Infinity) ? current : max),
		differences[0]
	)

	const maxDifference = maxDifferencePoint?.percentDiff
	const maxDifferenceX = maxDifferencePoint?.x
	const averageDifference =
		differences.reduce((sum, point) => sum + point.percentDiff, 0) / differences.length
	const medianDifference = calculateMedian(differences.map((point) => point.percentDiff))
	return {
		lossAverage: averageDifference,
		lossMedian: medianDifference,
	}
}

export const calculateLossByCoeff = (x: number, coeffs: Coefficients): number => {
	const aCoeff = coeffs.a
	const bCoeff = coeffs.b
	const cCoeff = coeffs.c
	const calculated = aCoeff * x * x + bCoeff * x + cCoeff

	return calculated
}

export const calculateStatisticsByCoeff = (
	dataA: Coefficients,
	dataB: Coefficients,
	min: number,
	max: number
): Statistics => {
	const xValues: number[] = []
	for (let i = min * 10; i <= max * 10; i++) {
		xValues.push(i / 10)
	}

	const data1: number[] = []
	const data2: number[] = []

	for (const index of xValues) {
		data1.push(calculateLossByCoeff(index, dataA))
		data2.push(calculateLossByCoeff(index, dataB))
	}

	if (data1.length !== data2.length) {
		throw new Error("Datasets must be of the same length for comparison.")
	}

	const differences = data1.map((point, index) => {
		const pointB = data2[index] ?? 0
		if (pointB === undefined) {
			throw new Error(`No corresponding point found in dataset B for index ${index}`)
		}

		const percentDiff = ((pointB - point) / point) * 100
		return {
			percentDiff,
			x: index,
		}
	})

	const maxDifferencePoint = differences.reduce(
		(max, current) => (current.percentDiff > (max?.percentDiff ?? -Infinity) ? current : max),
		differences[0]
	)

	const averageDifference =
		differences.reduce((sum, point) => sum + point.percentDiff, 0) / differences.length

	const medianDifference = calculateMedian(differences.map((point) => point.percentDiff))
	return {
		lossAverage: averageDifference,
		lossMedian: medianDifference,
	}
}

export const generateYValues = (equation: number[], xValues: number[]): ScatterDataPoint[] => {
	const aCoeff = equation[0] ?? 0
	const bCoeff = equation[1] ?? 0
	const cCoeff = equation[2] ?? 0
	const interpolatedPoints = xValues.map((a) => ({
		x: a,
		y: aCoeff * a ** 2 + bCoeff * a + cCoeff,
	}))

	return interpolatedPoints
}

const testColor = [
	// more pastel colors
	"#1f77b4",
	"#ff7f0e",
	"#2ca02c",
	"#d62728",
	"#9467bd",
	"#8c564b",
	"#e377c2",
	"#7f7f7f",
	"#bcbd22",
	"#17becf",
	"#aec7e8",
	"#ffbb78",
	"#98df8a",
	"#ff9896",
	"#c5b0d5",
	"#c49c94",
	"#f7b6d2",
	"#c7c7c7",
	"#dbdb8d",
	"#9edae5",
	// more vivid colors
	"#1f77b4",
	"#ff7f0e",
	"#2ca02c",
	"#d62728",
	"#9467bd",
	"#8c564b",
	"#e377c2",
	"#7f7f7f",
	"#bcbd22",
	"#17becf",
	"#ff5733",
	"#0057d9",
	"#e41a1c",
	"#f28e2b",
	"#76b041",
	"#d8345f",
	"#8d99ae",
	"#2b83ba",
	"#ffcc33",
	"#4e79a7",
]

export const opModeNameColors2: { [name: string]: string } = {
	dp: "#193cbc",
	"dp/standby": "#193cbc",
	operation: "#193cbc",
	standby: "#4382c8",
	port: "#84afdc",
	"port - maneuvering": "#84afdc",
	"port - moored": "#c5dff2",
	"transit other": "#ab6f63", //"#b6b6b6",
	"transit eco": "#2ca02c",
	"transit manuvering": "#ffd700",
	"transit service": "#ffd700",
	"transit max": "#f47e68",
	loading: "#745c9b",
	unloading: "#5422a7",
	ah: "#745c9b",
	"anchor handling": "#745c9b",
	tow: "#5422a7",
	//unisea modes
	"dp operations": "#193cbc",
	"in port (incl. mob/demob and at anchor)": "#84afdc",
	"standby on location and/or waiting on weather": "#4382c8",
	"transit service speed": "#ffd700",
	//altera modes
	"dp 1000m": "#0050ac",
	"dp maneuvering": "#3794b6",
	"drift/waiting": "#ffd600",
	"port/loading": "#b9dbdc",
	discharge: "#999999",
	anchorage: "#e3365c",
	manuvering: "#3794b6",
	"sailing ballast": "#b0d757",
	"sailing laden": "#f47e68",
	"(na)": "#ffffff",
	"unknown mode": "#94919166", //"#f20acbaa",
}

const colors = [
	"#782837",
	"#c29ea5",
	"#d6bec3",
	"#4d8181",
	"#8caeae",
	"#b3c9c9",
	"#f5af50",
	"#f9cb8d",
	"#fadbb0",

	"#332288",
	"#CC6677",
	"#117733",
	"#AA4499",
	"#44AA99",
	"#88CCEE",
	"#882255",
	"#DDCC77",

	"#1c2b39",
	"#223a4e",
	"#2b4861",
	"#aab6c0",
	"#ffd600",
	"#88CCEE",
	"#882255",
	"#DDCC77",
]

export function getColorOfOpModeName(opModeName: string) {
	const lowerCaseOpModeName = opModeName.toLowerCase()
	if (lowerCaseOpModeName in opModeNameColors2) {
		return opModeNameColors2[lowerCaseOpModeName]
	}
	return undefined
}

export const sortedModesOrder: { [key: string]: number } = {
	DP: 10,
	"DP/Standby": 11,
	Operation: 12,

	Standby: 20,

	Port: 30,
	"Port - maneuvering": 31,
	"Port - moored": 32,

	"Transit eco": 41,
	"Transit manuvering": 42,
	"Transit service": 43,
	"Transit max": 44,
	"Transit other": 45,

	Loading: 50,
	Unloading: 60,
	AH: 70,
	Tow: 80,

	//ALTERA MODES

	"DP 1000m": 10,
	"DP Maneuvering": 15,
	"Drift/waiting": 20,
	"Port/loading": 30,
	Discharge: 40,
	Anchorage: 50,
	Manuvering: 14,
	"Sailing ballast": 70,
	"Sailing laden": 80,
}
