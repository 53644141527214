import { requestApi2 } from "@utils/http"
import { endpoints } from "api"
import { useEffect, useMemo, useState } from "react"
import { Bar, Pie } from "react-chartjs-2"
import {
	ChartData,
	User,
	UserAction,
	columnWidths,
	extractEmailDomain,
	formatTimeSpent,
	generateCSV,
	getSortIndicator,
	prepareChartData,
	prepareEntriesForUserChartData,
	prepareEntriesPerActionChartData,
	prepareTimeSpentPerCompanyChartData,
	prepareTotalTimeSpentChartData,
	prepareUsersPerCompanyChartData,
	tableStyles,
	tdStyles,
	thStyles,
	thStylesSortable,
	timeAgo,
} from "./UserTrackingUtils"

import {
	ActiveElement,
	ArcElement,
	BarElement,
	CategoryScale,
	ChartEvent,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from "chart.js"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement)

export function UserTracking() {
	const [users, setUsers] = useState<User[]>([])
	const [companies, setCompanies] = useState<string[]>([])
	const [selectedCompanies, setSelectedCompanies] = useState<string[]>([])
	const [showCompanyFilter, setShowCompanyFilter] = useState(false)

	const [selectedUser, setSelectedUser] = useState<User | null>(null)

	const [selectedUserActions, setSelectedUserActions] = useState<Record<
		string,
		UserAction
	> | null>(null)

	const [userActions, setUserActions] = useState<Record<number, Record<string, UserAction>>>({})
	const [filterActive, setFilterActive] = useState(true)
	const [sortConfig, setSortConfig] = useState<{
		key: string
		direction: "asc" | "desc"
	}>({
		key: "totalTimeSpent",
		direction: "desc",
	})

	const [chartData, setChartData] = useState<ChartData | null>(null)
	const [totalTimeSpentChartData, setTotalTimeSpentChartData] = useState<ChartData | null>(null)
	const [entriesPerActionChartData, setEntriesPerActionChartData] = useState<ChartData | null>(
		null
	)
	const [timeSpentPerCompanyChartData, setTimeSpentPerCompanyChartData] =
		useState<ChartData | null>(null)
	const [usersPerCompanyChartData, setUsersPerCompanyChartData] = useState<ChartData | null>(null)

	const [showTotalTimeSpentChart, setShowTotalTimeSpentChart] = useState(true)
	const [showEntriesPerActionChart, setShowEntriesPerActionChart] = useState(true)
	const [showTimeSpentPerCompanyChart, setShowTimeSpentPerCompanyChart] = useState(true)
	const [showUsersPerCompanyChart, setShowUsersPerCompanyChart] = useState(true)
	const [showTotalTimeSpentByUsersChart, setShowTotalTimeSpentByUsersChart] = useState(true)

	const [showChartFilter, setShowChartFilter] = useState(false)

	const [showAsciiVessel, setShowAsciiVessel] = useState(false)

	const [metrics, setMetrics] = useState<{ dau: number; mau: number; ratio: number }>()

	const today = new Date().toISOString().split("T")[0]
	const [timeRange, setTimeRange] = useState({
		from: today,
		to: today,
	})
	const handleDateRangeSelect = (range: "today" | "thisMonth" | "last30Days") => {
		const today = new Date()
		const from = new Date()

		switch (range) {
			case "today":
				setTimeRange({
					from: today.toISOString().split("T")[0],
					to: today.toISOString().split("T")[0],
				})
				break
			case "thisMonth":
				from.setDate(1)
				setTimeRange({
					from: from.toISOString().split("T")[0],
					to: today.toISOString().split("T")[0],
				})
				break
			case "last30Days":
				from.setDate(today.getDate() - 29)
				setTimeRange({
					from: from.toISOString().split("T")[0],
					to: today.toISOString().split("T")[0],
				})
				break
		}
	}

	const handleDateChange = (field: "from" | "to", value: string) => {
		setTimeRange((prev) => {
			const newRange = { ...prev, [field]: value }

			if (field === "to" && value < prev.from!) {
				newRange.to = prev.from
			}
			if (field === "from" && value > prev.to!) {
				newRange.from = prev.to
			}

			return newRange
		})
	}

	const fetchMetricsWithParams = async () => {
		const params: { timeRange: { from: string; to: string } } = {
			timeRange: {
				from: timeRange.from!,
				to: timeRange.to!,
			},
		}
		const response = await requestApi2(endpoints.userTrackingGetActivityMetrics, params)
		if (response != null) {
			setMetrics(response)
		}
	}

	useEffect(() => {
		fetchMetricsWithParams()
	}, [timeRange.from, timeRange.to])

	const AsciiVessel = () => {
		return (
			<pre
				style={{
					position: "relative",
					justifyContent: "center",
					fontSize: "20px", // Large font size for the boat
					color: "#b85c0c",
					whiteSpace: "pre",
				}}
			>
				{`
                                                        _____     
                                                    )___(
                                            _______/__/_
                                    ___     /===========|   ___
                    ____       __   [\\\]___/____________|__[///]   __
                    \\   \_____[\\]__/___________________________\__[//]___
                    \\                          DIPAI                     |
                    \\                     usertracking vessel             /
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      `}
			</pre>
		)
	}

	const handleDownloadCSV = () => {
		const csvData = generateCSV(filteredUsers, userActions, extractEmailDomain)

		const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" })
		const link = document.createElement("a")
		const url = URL.createObjectURL(blob)
		link.setAttribute("href", url)
		link.setAttribute("download", "user_data.csv")
		link.style.visibility = "hidden"
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
		URL.revokeObjectURL(url) // Free up memory
	}

	const fetchData = async () => {
		const usersResponse = await requestApi2(endpoints.userTrackingGetUsers)

		if (usersResponse == null) {
			return
		}

		const users = usersResponse.users.map((user: User) => ({
			id: user.id,
			name: user.name,
			email: user.email,
		}))
		setUsers(users)

		const uniqueCompanies = Array.from(
			new Set(users.map((user) => extractEmailDomain(user.email)))
		)
		setCompanies(uniqueCompanies)

		const allUserActions = await requestApi2(endpoints.userTrackingGetAllUserActionCounts)

		if (allUserActions != null) {
			const userActionsMap = (
				allUserActions as Array<{
					userId: number
					actionCounts: Record<
						string,
						{
							count: number
							lastTimestamp: string
							totalTimeSpent?: number
							component?: string
						}
					>
				}>
			).reduce((acc, { userId, actionCounts }) => {
				acc[userId] = actionCounts
				return acc
			}, {} as Record<number, Record<string, UserAction>>)

			setUserActions(userActionsMap)
		}

		const totalTimeSpentData = prepareTotalTimeSpentChartData(
			userActions,
			selectedCompanies,
			users,
			filterActive,
			extractEmailDomain
		)
		setTotalTimeSpentChartData(totalTimeSpentData)

		const entriesPerActionData = prepareEntriesPerActionChartData(
			userActions,
			selectedCompanies
		)
		setEntriesPerActionChartData(entriesPerActionData)

		const timeSpentPerCompanyData = prepareTimeSpentPerCompanyChartData(
			userActions,
			users,
			selectedCompanies,
			filterActive,
			extractEmailDomain
		)
		setTimeSpentPerCompanyChartData(timeSpentPerCompanyData)

		const filteredUsersData = filteredUsers
		const usersPerCompanyData = prepareUsersPerCompanyChartData(
			filteredUsersData,
			extractEmailDomain
		)
		setUsersPerCompanyChartData(usersPerCompanyData)
	}

	useEffect(() => {
		fetchData()
	}, [])

	useEffect(() => {
		if (selectedUserActions != null) {
			setChartData(prepareChartData(Object.entries(selectedUserActions), sortConfig))
		}
	}, [selectedUserActions, sortConfig])

	useEffect(() => {
		const totalTimeSpentData = prepareTotalTimeSpentChartData(
			userActions,
			selectedCompanies,
			users,
			filterActive,
			extractEmailDomain
		)
		setTotalTimeSpentChartData(totalTimeSpentData)

		const entriesPerActionData = prepareEntriesPerActionChartData(
			userActions,
			selectedCompanies
		)
		setEntriesPerActionChartData(entriesPerActionData)

		const timeSpentPerCompanyData = prepareTimeSpentPerCompanyChartData(
			userActions,
			users,
			selectedCompanies,
			filterActive,
			extractEmailDomain
		)
		setTimeSpentPerCompanyChartData(timeSpentPerCompanyData)

		const filteredUsersData = filteredUsers
		const usersPerCompanyData = prepareUsersPerCompanyChartData(
			filteredUsersData,
			extractEmailDomain
		)
		setUsersPerCompanyChartData(usersPerCompanyData)
	}, [userActions, selectedCompanies, filterActive, users])

	const handleCompanySelect = (company: string) => {
		setSelectedCompanies((prevSelected) => {
			if (prevSelected.includes(company)) {
				return prevSelected.filter((c) => c !== company)
			} else {
				return [...prevSelected, company]
			}
		})
	}

	const handleSelectAllCompanies = () => {
		if (selectedCompanies.length === companies.length) {
			setSelectedCompanies([])
		} else {
			setSelectedCompanies(companies)
		}
	}

	const handleUserSelect = async (userId: number, userName: string, userEmail: string) => {
		const userActionsResponse = await requestApi2(endpoints.userTrackingGetUserActionCounts, {
			userId,
		})
		if (userActionsResponse?.actionCounts != null) {
			setSelectedUser({ id: userId, name: userName, email: userEmail })
			setSelectedUserActions(userActionsResponse.actionCounts)
		} else {
			setSelectedUserActions(null)
		}
	}

	const handleSort = (key: string) => {
		let direction: "asc" | "desc" = "asc"
		if (sortConfig != null && sortConfig.key === key && sortConfig.direction === "asc") {
			direction = "desc"
		}
		setSortConfig({ key, direction })
	}

	const filteredUsers = useMemo(() => {
		return users.filter((user) => {
			const company = extractEmailDomain(user.email)
			const companyMatch =
				selectedCompanies.length === 0 || selectedCompanies.includes(company)
			if (!companyMatch) return false

			if (filterActive) {
				const userAction = userActions[user.id]
				return userAction != null && Object.keys(userAction).length > 0
			}
			return true
		})
	}, [users, filterActive, selectedCompanies, userActions])

	const sortedUsers = useMemo(() => {
		return [...filteredUsers].sort((a, b) => {
			if (sortConfig?.key === "totalTimeSpent") {
				const totalTimeA = Object.values(userActions[a.id] ?? {}).reduce(
					(total, action) => total + (action.totalTimeSpent ?? 0),
					0
				)
				const totalTimeB = Object.values(userActions[b.id] ?? {}).reduce(
					(total, action) => total + (action.totalTimeSpent ?? 0),
					0
				)
				return sortConfig.direction === "asc"
					? totalTimeA - totalTimeB
					: totalTimeB - totalTimeA
			}

			return 0 // Default case, no sorting
		})
	}, [filteredUsers, sortConfig, userActions])

	const sortedUserActions = useMemo(() => {
		if (selectedUserActions == null) return []

		return Object.entries(selectedUserActions).sort((a, b) => {
			const [keyA, dataA] = a
			const [keyB, dataB] = b

			if (sortConfig != null) {
				const { key, direction } = sortConfig
				const order = direction === "asc" ? 1 : -1

				if (key === "action") {
					return keyA.localeCompare(keyB) * order
				} else if (key === "component") {
					const componentA = keyA.split("_")[1] ?? ""
					const componentB = keyB.split("_")[1] ?? ""
					return componentA.localeCompare(componentB) * order
				} else if (key === "count") {
					return ((dataA?.count ?? 0) - (dataB?.count ?? 0)) * order
				} else if (key === "lastTimestamp") {
					const timeA = new Date(dataA?.lastTimestamp ?? 0).getTime()
					const timeB = new Date(dataB?.lastTimestamp ?? 0).getTime()
					return (timeA - timeB) * order
				} else if (key === "totalTimeSpent") {
					return ((dataA?.totalTimeSpent ?? 0) - (dataB?.totalTimeSpent ?? 0)) * order
				}
			}

			return 0 // Default return if no sort config matches
		})
	}, [selectedUserActions, sortConfig])
	const formatTimeForChart = (minutes: number) => {
		if (minutes < 1) {
			const seconds = Math.floor(minutes * 60)
			return `${seconds}s`
		} else if (minutes < 60) {
			const mins = Math.floor(minutes)
			return `${mins}m`
		} else {
			const hours = (minutes / 60).toFixed(1)
			return `${hours}h`
		}
	}

	const renderUserTimeSpentChart = () => {
		const chartLabels = sortedUsers.map((user) => user.name)
		const totalTimeData = sortedUsers.map((user) =>
			Object.values(userActions[user.id] ?? {}).reduce(
				(total, action) => total + (action.totalTimeSpent ?? 0),
				0
			)
		)

		// Dynamically calculate the height of the chart based on the number of users
		const chartHeight = Math.min(Math.max(sortedUsers.length * 30, 400), 3600)

		const chartData = {
			labels: chartLabels,
			datasets: [
				{
					label: "Total Time Spent (hours)",
					data: totalTimeData,
					backgroundColor: "rgba(75, 192, 192, 0.6)",
					borderColor: "rgba(75, 192, 192, 1)",
					borderWidth: 1,
				},
			],
		}
		const handleBarClick = (event: ChartEvent, elements: ActiveElement[], chart: ChartJS) => {
			if (elements.length > 0 && elements[0]?.index != null) {
				const index = elements[0].index
				const selectedUser = sortedUsers[index]
				if (selectedUser != null) {
					handleUserSelect(selectedUser.id, selectedUser.name, selectedUser.email)
				}
			}
		}
		return (
			<div
				style={{
					height: `${chartHeight}px`,
					marginTop: "30px",
					marginBottom: "30px",
					overflowY: "auto",
				}}
			>
				<h3 style={{ textAlign: "center", color: "#333", marginBottom: "20px" }}>
					Total Time Spent by Users
				</h3>
				<Bar
					data={chartData}
					options={{
						indexAxis: "y", // Horizontal bar chart
						responsive: true,
						maintainAspectRatio: false,
						onClick: handleBarClick, // Add click event handler for the bars

						scales: {
							x: {
								beginAtZero: true,
								title: {
									display: true,
									text: "Time Spent (hours)",
								},
							},
							y: {
								ticks: {
									autoSkip: false, // Don't skip users
									maxRotation: 0,
									callback: function (value) {
										return chartLabels[value] ?? ""
									},
								},
								title: {
									display: true,
									text: "Users",
								},
							},
						},
						plugins: {
							tooltip: {
								callbacks: {
									label: function (tooltipItem) {
										return `Total Time: ${formatTimeSpent(
											Number(tooltipItem.raw)
										)}`
									},
								},
							},
						},
					}}
				/>
			</div>
		)
	}

	const renderMainContent = () => {
		return (
			<div>
				<div
					style={{
						backgroundColor: "#ffffff",
						padding: "30px",
						borderRadius: "16px",
						marginBottom: "30px",
						boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
					}}
				>
					<div
						style={{
							marginBottom: "30px",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<div
							style={{
								marginBottom: "20px",
								display: "flex",
								gap: "15px",
							}}
						>
							<input
								type="date"
								value={timeRange.from}
								onChange={(e) => handleDateChange("from", e.target.value)}
								min="2024-09-23"
								max={timeRange.to}
								style={{
									padding: "10px 15px",
									borderRadius: "8px",
									border: "1px solid #e0e0e0",
									fontSize: "14px",
									backgroundColor: "#f8f9fa",
									transition: "all 0.2s ease",
									cursor: "pointer",
								}}
							/>
							<input
								type="date"
								value={timeRange.to}
								onChange={(e) => handleDateChange("to", e.target.value)}
								min={timeRange.from}
								max={new Date().toISOString().split("T")[0]}
								style={{
									padding: "10px 15px",
									borderRadius: "8px",
									border: "1px solid #e0e0e0",
									fontSize: "14px",
									backgroundColor: "#f8f9fa",
									transition: "all 0.2s ease",
									cursor: "pointer",
								}}
							/>
						</div>

						<div style={{ display: "flex", gap: "12px" }}>
							{["today", "thisMonth", "last30Days"].map((range) => (
								<button
									key={range}
									onClick={() =>
										handleDateRangeSelect(
											range as "today" | "thisMonth" | "last30Days"
										)
									}
									style={{
										padding: "10px 20px",
										borderRadius: "8px",
										backgroundColor: "#f8f9fa",
										border: "1px solid #e0e0e0",
										color: "#444",
										fontSize: "14px",
										fontWeight: "500",
										cursor: "pointer",
										transition: "all 0.2s ease",
									}}
									onMouseEnter={(e) => {
										e.currentTarget.style.backgroundColor = "#e9ecef"
										e.currentTarget.style.transform = "translateY(-1px)"
									}}
									onMouseLeave={(e) => {
										e.currentTarget.style.backgroundColor = "#f8f9fa"
										e.currentTarget.style.transform = "none"
									}}
								>
									{range === "today"
										? "Today"
										: range === "thisMonth"
										? "This Month"
										: "Last 30 Days"}
								</button>
							))}
						</div>
					</div>

					{metrics != null && (
						<div
							style={{
								display: "grid",
								gridTemplateColumns: "repeat(3, 1fr)",
								gap: "25px",
								textAlign: "center",
							}}
						>
							{[
								{ label: "Daily Active Users", value: metrics.dau },
								{ label: "Monthly Active Users", value: metrics.mau },
								{ label: "DAU/MAU Ratio", value: `${metrics.ratio.toFixed(1)}%` },
							].map(({ label, value }) => (
								<div
									key={label}
									style={{
										padding: "25px",
										backgroundColor: "white",
										borderRadius: "12px",
										boxShadow: "0 4px 15px rgba(0,0,0,0.05)",
										transition: "transform 0.2s ease, box-shadow 0.2s ease",
									}}
								>
									<div
										style={{
											fontSize: "15px",
											color: "#666",
											marginBottom: "10px",
											fontWeight: "500",
										}}
									>
										{label}
									</div>
									<div
										style={{
											fontSize: "28px",
											fontWeight: "600",
											color: "#2563eb",
										}}
									>
										{value}
									</div>
								</div>
							))}
						</div>
					)}
				</div>

				{/* Buttons */}
				<div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
					<button
						onClick={handleDownloadCSV}
						style={{
							padding: "8px 16px",
							backgroundColor: "#2c9763",
							color: "#ffffff",
							border: "none",
							borderRadius: "4px",
							cursor: "pointer",
							fontWeight: "bold",
							transition: "background-color 0.3s ease",
							marginRight: "10px",
						}}
					>
						Download Data
					</button>
					<button
						onClick={() => setFilterActive((prev) => !prev)}
						style={{
							padding: "8px 16px",
							backgroundColor: filterActive ? "#272121" : "#aa9f9f",
							color: filterActive ? "#fff" : "#fafafa",
							border: "none",
							borderRadius: "4px",
							cursor: "pointer",
							fontWeight: "bold",
							transition: "background-color 0.3s ease",
							marginRight: "10px",
						}}
					>
						{filterActive ? "Active users" : "Active users"}
					</button>
					<button
						onClick={() => setShowCompanyFilter((prev) => !prev)}
						style={{
							padding: "8px 16px",
							backgroundColor: showCompanyFilter ? "#272121" : "#aa9f9f",
							color: "#ffffff",
							border: "none",
							borderRadius: "4px",
							cursor: "pointer",
							fontWeight: "bold",
							transition: "background-color 0.3s ease",
							marginRight: "10px",
						}}
					>
						{showCompanyFilter ? "Companies" : "Companies"}
					</button>
					{/* Button to toggle chart filter */}
					<button
						onClick={() => setShowChartFilter((prev) => !prev)}
						style={{
							padding: "8px 16px",
							backgroundColor: showChartFilter ? "#272121" : "#aa9f9f",
							color: "#fff",
							border: "none",
							borderRadius: "4px",
							cursor: "pointer",
							fontWeight: "bold",
							transition: "background-color 0.3s ease",
						}}
					>
						{showChartFilter ? "Charts" : "Charts"}
					</button>
				</div>

				{/* Company Filter */}
				{showCompanyFilter && (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginBottom: "20px",
						}}
					>
						<span
							style={{ marginBottom: "10px", fontWeight: "bold", fontSize: "16px" }}
						>
							Select Companies:
						</span>
						<div
							style={{
								display: "flex",
								flexWrap: "wrap",
								justifyContent: "center",
								maxWidth: "600px",
								margin: "0 auto",
							}}
						>
							{/* Select All Checkbox */}
							<div
								style={{
									width: "150px",
									display: "flex",
									alignItems: "center",
									margin: "5px",
								}}
							>
								<input
									type="checkbox"
									id="select-all-checkbox"
									checked={selectedCompanies.length === companies.length}
									onChange={handleSelectAllCompanies}
									style={{ marginRight: "8px" }}
								/>
								<label
									htmlFor="select-all-checkbox"
									style={{ cursor: "pointer", fontWeight: "bold" }}
								>
									{selectedCompanies.length === companies.length
										? "Select All"
										: "Select All"}
								</label>
							</div>

							{/* Individual Company Checkboxes */}
							{companies.map((company) => (
								<div
									key={company}
									style={{
										width: "150px",
										display: "flex",
										alignItems: "center",
										margin: "5px",
									}}
								>
									<input
										type="checkbox"
										id={`company-${company}`}
										checked={selectedCompanies.includes(company)}
										onChange={() => handleCompanySelect(company)}
										style={{ marginRight: "8px" }}
									/>
									<label
										htmlFor={`company-${company}`}
										style={{ cursor: "pointer" }}
									>
										{company}
									</label>
								</div>
							))}
						</div>
					</div>
				)}

				{/* Chart Selection Control Panel */}
				{showChartFilter && (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginBottom: "20px",
						}}
					>
						<span
							style={{ marginBottom: "10px", fontWeight: "bold", fontSize: "16px" }}
						>
							Select Charts to Display:
						</span>
						<div
							style={{
								display: "flex",
								flexWrap: "wrap",
								justifyContent: "center",
								maxWidth: "600px",
								margin: "0 auto",
							}}
						>
							{/* Individual Chart Checkboxes */}
							<div
								style={{
									width: "250px",
									display: "flex",
									alignItems: "center",
									margin: "5px",
								}}
							>
								<input
									type="checkbox"
									id="chart-total-time-spent"
									checked={showTotalTimeSpentChart}
									onChange={() => setShowTotalTimeSpentChart((prev) => !prev)}
									style={{ marginRight: "8px" }}
								/>
								<label
									htmlFor="chart-total-time-spent"
									style={{ cursor: "pointer" }}
								>
									Total Time Spent in Modules
								</label>
							</div>
							<div
								style={{
									width: "250px",
									display: "flex",
									alignItems: "center",
									margin: "5px",
								}}
							>
								<input
									type="checkbox"
									id="chart-entries-per-action"
									checked={showEntriesPerActionChart}
									onChange={() => setShowEntriesPerActionChart((prev) => !prev)}
									style={{ marginRight: "8px" }}
								/>
								<label
									htmlFor="chart-entries-per-action"
									style={{ cursor: "pointer" }}
								>
									Number of Entries per Module
								</label>
							</div>
							<div
								style={{
									width: "250px",
									display: "flex",
									alignItems: "center",
									margin: "5px",
								}}
							>
								<input
									type="checkbox"
									id="chart-time-spent-per-company"
									checked={showTimeSpentPerCompanyChart}
									onChange={() =>
										setShowTimeSpentPerCompanyChart((prev) => !prev)
									}
									style={{ marginRight: "8px" }}
								/>
								<label
									htmlFor="chart-time-spent-per-company"
									style={{ cursor: "pointer" }}
								>
									Total Time Spent per Company
								</label>
							</div>
							<div
								style={{
									width: "250px",
									display: "flex",
									alignItems: "center",
									margin: "5px",
								}}
							>
								<input
									type="checkbox"
									id="chart-users-per-company"
									checked={showUsersPerCompanyChart}
									onChange={() => setShowUsersPerCompanyChart((prev) => !prev)}
									style={{ marginRight: "8px" }}
								/>
								<label
									htmlFor="chart-users-per-company"
									style={{ cursor: "pointer" }}
								>
									Number of Users per Company
								</label>
							</div>
							{/* New Toggle for Total Time Spent by Users */}
							<div
								style={{
									width: "250px",
									display: "flex",
									alignItems: "center",
									margin: "5px",
								}}
							>
								<input
									type="checkbox"
									id="chart-total-time-spent-by-users"
									checked={showTotalTimeSpentByUsersChart}
									onChange={() =>
										setShowTotalTimeSpentByUsersChart((prev) => !prev)
									}
									style={{ marginRight: "8px" }}
								/>
								<label
									htmlFor="chart-total-time-spent-by-users"
									style={{ cursor: "pointer" }}
								>
									Total Time Spent by Users
								</label>
							</div>
							{/* dipai usertracking vessel */}
							<div
								style={{
									width: "250px",
									display: "flex",
									alignItems: "center",
									margin: "5px",
								}}
							>
								<input
									type="checkbox"
									id="toggle-ascii-vessel"
									checked={showAsciiVessel}
									onChange={() => setShowAsciiVessel((prev) => !prev)}
									style={{ marginRight: "8px" }}
								/>
								<label htmlFor="toggle-ascii-vessel" style={{ cursor: "pointer" }}>
									Show Dipai Vessel
								</label>
							</div>
						</div>
					</div>
				)}

				{/* Conditionally render the AsciiVessel */}
				{showAsciiVessel && (
					<div>
						{(() => {
							try {
								// @ts-ignore: Suppress TypeScript errors for this component
								// (ignore errors to enable very swift and painless removal of this boat)
								return <AsciiVessel />
							} catch (error) {
								return null
							}
						})()}
					</div>
				)}
				{showTotalTimeSpentChart && (
					<>
						<h3
							style={{
								marginTop: "30px",
								marginBottom: "20px",
								color: "#333",
								textAlign: "center",
							}}
						>
							Total Time Spent in Modules{" "}
						</h3>
						<div style={{ height: "400px", marginBottom: "30px" }}>
							{totalTimeSpentChartData != null && (
								<Bar
									data={totalTimeSpentChartData}
									options={{
										responsive: true,
										maintainAspectRatio: false,
										scales: {
											y: {
												beginAtZero: true,
												ticks: {
													callback: function (value) {
														// Check if the value is greater than 24 hours
														if (
															typeof value === "number" &&
															value >= 24
														) {
															const days = Math.floor(value / 24) // Convert hours to days
															const hours = value % 24 // Remaining hours after conversion to days
															return `${days} day${
																days !== 1 ? "s" : ""
															} ${hours} hour${
																hours !== 1 ? "s" : ""
															}`
														} else {
															return `${value} hr${
																value !== 1 ? "s" : ""
															}`
														}
													},
												},
												title: {
													display: true,
													text: "Time (hours/days)",
												},
											},
											x: {
												title: {
													display: true,
													text: "Modules",
												},
											},
										},
									}}
								/>
							)}
						</div>
					</>
				)}

				{/* Number of Entries per Action */}
				{showEntriesPerActionChart && (
					<>
						<h3
							style={{
								marginTop: "30px",
								marginBottom: "20px",
								color: "#333",
								textAlign: "center",
							}}
						>
							Number of Entries per Module
						</h3>
						<div style={{ height: "400px", marginBottom: "30px" }}>
							{entriesPerActionChartData != null && (
								<Bar
									data={entriesPerActionChartData}
									options={{
										responsive: true,
										maintainAspectRatio: false,
										scales: {
											y: {
												beginAtZero: true,
												title: {
													display: true,
													text: "Number of Entries",
												},
											},
											x: {
												title: {
													display: true,
													text: "Actions",
												},
											},
										},
									}}
								/>
							)}
						</div>
					</>
				)}

				{/* Total Time Spent per Company */}
				{showTimeSpentPerCompanyChart && (
					<>
						<h3
							style={{
								marginTop: "30px",
								marginBottom: "20px",
								color: "#333",
								textAlign: "center",
							}}
						>
							Total Time Spent per Company
						</h3>
						<div style={{ height: "400px", marginBottom: "30px" }}>
							{timeSpentPerCompanyChartData != null && (
								<Bar
									data={timeSpentPerCompanyChartData}
									options={{
										responsive: true,
										maintainAspectRatio: false,
										scales: {
											y: {
												beginAtZero: true,
												ticks: {
													callback: function (value) {
														// Correctly convert minutes to hours and then to days if needed
														const hours = Number(value) / 60 // Convert minutes to hours
														if (hours >= 24) {
															const days = Math.floor(hours / 24) // Convert hours to days
															const remainingHours = Math.floor(
																hours % 24
															) // Remaining hours after days
															return `${days} day${
																days !== 1 ? "s" : ""
															} ${remainingHours} hour${
																remainingHours !== 1 ? "s" : ""
															}`
														} else {
															return `${hours.toFixed(1)} hr${
																hours !== 1 ? "s" : ""
															}`
														}
													},
												},
												title: {
													display: true,
													text: "Time (hours/days)",
												},
											},
											x: {
												title: {
													display: true,
													text: "Companies",
												},
											},
										},
										plugins: {
											tooltip: {
												callbacks: {
													label: function (tooltipItem) {
														// Convert tooltip value from minutes to hours and days
														const minutes = Number(tooltipItem.raw)
														const hours = minutes / 60
														if (hours >= 24) {
															const days = Math.floor(hours / 24)
															const remainingHours = Math.floor(
																hours % 24
															)
															return `${days} day${
																days !== 1 ? "s" : ""
															} ${remainingHours} hour${
																remainingHours !== 1 ? "s" : ""
															}`
														} else {
															return `${hours.toFixed(1)} hr${
																hours !== 1 ? "s" : ""
															}`
														}
													},
												},
											},
										},
									}}
								/>
							)}
						</div>
					</>
				)}

				{/* Number of Users per Company */}
				{showUsersPerCompanyChart && (
					<>
						<h3
							style={{
								marginTop: "30px",
								marginBottom: "20px",
								color: "#333",
								textAlign: "center",
							}}
						>
							Number of Users per Company
						</h3>
						<div style={{ height: "400px", marginBottom: "30px" }}>
							{usersPerCompanyChartData != null && (
								<Pie
									data={usersPerCompanyChartData}
									options={{
										responsive: true,
										maintainAspectRatio: false,
										plugins: {
											legend: {
												position: "right" as const,
											},
										},
									}}
								/>
							)}
						</div>
					</>
				)}

				{/* Total Time Spent by Users */}
				{showTotalTimeSpentByUsersChart && renderUserTimeSpentChart()}

				{/* Users Table */}
				<table style={tableStyles}>
					<colgroup>
						<col style={{ width: columnWidths.id }} />
						<col style={{ width: columnWidths.name }} />
						<col style={{ width: columnWidths.company }} />
						<col style={{ width: "20%" }} />
					</colgroup>
					<thead>
						<tr>
							<th style={thStyles}>User ID</th>
							<th style={thStyles}>User Name</th>
							<th style={thStyles}>Company</th>
							<th
								style={thStylesSortable}
								onClick={() => handleSort("totalTimeSpent")}
							>
								Total Time Spent {getSortIndicator("totalTimeSpent", sortConfig)}
							</th>
						</tr>
					</thead>
					<tbody>
						{sortedUsers.map((user) => {
							const totalTimeSpent = Object.values(userActions[user.id] ?? {}).reduce(
								(total, action) => total + (action.totalTimeSpent ?? 0),
								0
							)
							return (
								<tr
									key={user.id}
									onClick={() => handleUserSelect(user.id, user.name, user.email)}
									style={{ cursor: "pointer" }}
									onMouseEnter={(e) =>
										(e.currentTarget.style.backgroundColor = "#f5f5f5")
									}
									onMouseLeave={(e) =>
										(e.currentTarget.style.backgroundColor = "")
									}
								>
									<td style={tdStyles}>{user.id}</td>
									<td style={tdStyles} title={user.name}>
										{user.name}
									</td>
									<td style={tdStyles}>{extractEmailDomain(user.email)}</td>
									<td style={tdStyles}>{formatTimeSpent(totalTimeSpent)}</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		)
	}

	const renderUserDetails = () => {
		const entriesChartData = prepareEntriesForUserChartData(sortedUserActions)

		return (
			<div>
				{/* Back and Refresh Buttons */}
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						marginBottom: "20px",
					}}
				>
					<button
						onClick={() => setSelectedUser(null)}
						style={{
							padding: "8px 16px",
							backgroundColor: "#f0f0f0",
							border: "none",
							borderRadius: "4px",
							cursor: "pointer",
						}}
					>
						Back to Users
					</button>
					<button
						onClick={() =>
							handleUserSelect(
								selectedUser!.id,
								selectedUser!.name,
								selectedUser!.email
							)
						}
						style={{
							padding: "8px 16px",
							backgroundColor: "#007bff",
							color: "#fff",
							border: "none",
							borderRadius: "4px",
							cursor: "pointer",
							fontWeight: "bold",
							transition: "background-color 0.3s ease",
						}}
						onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#0056b3")}
						onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#007bff")}
					>
						Refresh
					</button>
				</div>

				{/* User Name and Company */}
				<h2
					style={{
						marginBottom: "20px",
						color: "#333",
						fontWeight: "bold",
						textAlign: "center",
					}}
				>
					{selectedUser?.name} ({extractEmailDomain(selectedUser?.email ?? "")})
				</h2>

				{/* User Time Spent Chart */}
				<h3
					style={{
						marginTop: "30px",
						marginBottom: "20px",
						color: "#333",
						textAlign: "center",
					}}
				>
					Time Spent in Modules
				</h3>
				<div style={{ height: "400px", marginBottom: "30px" }}>
					{chartData != null && (
						<Bar
							data={chartData}
							options={{
								responsive: true,
								maintainAspectRatio: false,
								scales: {
									y: {
										beginAtZero: true,
										ticks: {
											callback: function (tickValue: string | number) {
												return formatTimeForChart(Number(tickValue))
											},
										},
										title: {
											display: true,
											text: "Time",
										},
									},
									x: {
										title: {
											display: true,
											text: "Actions",
										},
									},
								},
							}}
						/>
					)}
				</div>

				{/* User Entries Chart */}
				<h3
					style={{
						marginTop: "30px",
						marginBottom: "20px",
						color: "#333",
						textAlign: "center",
					}}
				>
					Number of Entries per Module
				</h3>
				<div style={{ height: "400px", marginBottom: "30px" }}>
					{entriesChartData != null && (
						<Bar
							data={entriesChartData}
							options={{
								responsive: true,
								maintainAspectRatio: false,
								scales: {
									y: {
										beginAtZero: true,
										title: {
											display: true,
											text: "Number of Entries",
										},
									},
									x: {
										title: {
											display: true,
											text: "Actions",
										},
									},
								},
							}}
						/>
					)}
				</div>

				{/* User Actions Table */}
				<table style={tableStyles}>
					<thead>
						<tr>
							<th style={thStylesSortable} onClick={() => handleSort("action")}>
								Module{getSortIndicator("action", sortConfig)}
							</th>
							<th style={thStylesSortable} onClick={() => handleSort("count")}>
								Visits{getSortIndicator("count", sortConfig)}
							</th>
							<th
								style={thStylesSortable}
								onClick={() => handleSort("lastTimestamp")}
							>
								Last Visit{getSortIndicator("lastTimestamp", sortConfig)}
							</th>
							<th
								style={thStylesSortable}
								onClick={() => handleSort("totalTimeSpent")}
							>
								Total Time Spent{getSortIndicator("totalTimeSpent", sortConfig)}
							</th>
						</tr>
					</thead>
					<tbody>
						{sortedUserActions.map(([action, data]) => {
							const date = new Date(data.lastTimestamp)
							return (
								<tr key={action}>
									<td style={tdStyles}>{action}</td>
									<td style={tdStyles}>{data.count}</td>
									<td style={tdStyles}>{timeAgo(date)}</td>
									<td style={tdStyles}>
										{data.totalTimeSpent != null && !isNaN(data.totalTimeSpent)
											? formatTimeSpent(data.totalTimeSpent)
											: "N/A"}
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		)
	}

	return (
		<div style={{ padding: "20px", maxWidth: "1200px", margin: "0 auto" }}>
			{selectedUser != null ? renderUserDetails() : renderMainContent()}
		</div>
	)
}
