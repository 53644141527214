type TooltipData = {
	id: number
	element: HTMLElement
	rect: DOMRect
	isVisible: boolean
}

type Cluster = {
	centerId: number
	points: TooltipData[]
}

function clusterPoints(
	distanceMatrix: number[][],
	points: TooltipData[],
	threshold: number
): Cluster[] {
	const clusters: Cluster[] = []
	const unassignedPoints: Set<number> = new Set(points.map((p) => p.id))

	while (unassignedPoints.size > 0) {
		// Select the point with the most neighbors within the threshold as the cluster center
		const centerId = selectClusterCenter(distanceMatrix, unassignedPoints, threshold)
		if (centerId === null) {
			// All remaining points are isolated; assign each to its own cluster
			unassignedPoints.forEach((pointId) => {
				const pointData = points.find((p) => p.id === pointId)
				if (pointData !== undefined) {
					clusters.push({
						centerId: pointId,
						points: [pointData],
					})
				}
			})
			break
		}

		// Create a new cluster with the selected center
		const cluster: Cluster = {
			centerId: centerId,
			points: [],
		}

		// Assign points within the threshold to the cluster
		unassignedPoints.forEach((pointId) => {
			const currentDistance = distanceMatrix[centerId]?.[pointId]
			if (currentDistance === undefined) {
				return
			}
			if (currentDistance <= threshold) {
				const pointData = points.find((p) => p.id === pointId)
				if (pointData !== undefined) {
					cluster.points.push(pointData)
				}
			}
		})

		// Remove assigned points from unassignedPoints
		cluster.points.forEach((p) => unassignedPoints.delete(p.id))
		clusters.push(cluster)
	}

	return clusters
}

function selectClusterCenter(
	distanceMatrix: number[][],
	unassignedPoints: Set<number>,
	threshold: number
): number | null {
	let maxNeighbors = -1
	let centerId: number | null = null

	unassignedPoints.forEach((candidateId) => {
		let neighborCount = 0
		unassignedPoints.forEach((otherId) => {
			const currentDistance = distanceMatrix[candidateId]?.[otherId]
			if (currentDistance === undefined) {
				return
			}
			if (currentDistance <= threshold) {
				neighborCount++
			}
		})

		if (neighborCount > maxNeighbors) {
			maxNeighbors = neighborCount
			centerId = candidateId
		}
	})

	return centerId
}

function isOverlapping(rect1: DOMRect, rect2: DOMRect): boolean {
	const tolerance = 5
	return !(
		rect1.right - rect2.left <= tolerance ||
		rect2.right - rect1.left <= tolerance ||
		rect1.bottom - rect2.top <= tolerance ||
		rect2.bottom - rect1.top <= tolerance
	)
}

export function detectCollisions(): void {
	const tooltipNodes = document.querySelectorAll<HTMLElement>(".vessel-label")
	const tooltips = Array.from(tooltipNodes)
	if (tooltips.length < 2) {
		return
	}
	const tooltipData: TooltipData[] = []
	tooltips.sort((a, b) => a.getBoundingClientRect().x - b.getBoundingClientRect().x)

	const distanceMatrix: number[][] = []
	for (const [idx, tooltip] of tooltips.entries()) {
		const rect_idx = tooltip.getBoundingClientRect()
		const distanceRow: number[] = new Array(idx + 1)
		for (let i = 0; i < idx + 1; ++i) distanceRow[i] = distanceMatrix[i]?.[idx] ?? -1
		for (let f = idx + 1; f < tooltips.length; f++) {
			const rect_f = tooltips[f]?.getBoundingClientRect()
			if (rect_f === undefined) {
				distanceRow.push(-1)
			} else {
				const distance_idx_j = Math.sqrt(
					(rect_idx.x - rect_f.x) ** 2 + (rect_idx.y - rect_f.y) ** 2
				)
				distanceRow.push(distance_idx_j)
			}
		}
		distanceMatrix.push(distanceRow)
	}
	const distanceMatrixNormalized = distanceMatrix.map((distanceRow) => {
		const max = Math.max(...distanceRow)
		return distanceRow.map((distance) => distance / max)
	})

	tooltips.forEach((tooltip, idx) => {
		const rect = tooltip.getBoundingClientRect()
		tooltipData.push({
			id: idx,
			element: tooltip,
			rect: rect,
			isVisible: true,
		})
	})
	const threshold = 0.05 // need to think about how to update the threshold based on the zoom level
	const clusters = clusterPoints(distanceMatrixNormalized, tooltipData, threshold)

	for (const cluster of clusters) {
		const clusterTooltips = cluster.points.sort((a, b) =>
			a.rect.left < b.rect.left ? 1 : a.rect.left > b.rect.left ? -1 : 0
		)
		for (let i = 0; i < clusterTooltips.length; i++) {
			const tooltip_i = clusterTooltips[i]
			if (tooltip_i === undefined || tooltip_i.element.style.display === "none") {
				continue
			}
			for (let j = i + 1; j < clusterTooltips.length; j++) {
				const tooltip_j = clusterTooltips[j]
				if (tooltip_j === undefined) {
					continue
				}
				if (isOverlapping(tooltip_i.rect, tooltip_j.rect)) {
					tooltip_j.element.style.display = "none"
					tooltip_j.element.style.borderRadius = "2px"
					tooltip_j.isVisible = false
				}
			}
		}
	}
}
