import { requestApi2 } from "@utils/http"
import { ConfigData, DbDeviceConfig, DeviceConfigAddArgs, endpoints } from "api"
import { useEffect, useState } from "react"
import Button from "./Button"

export default function DeviceConfigEditor({ props }: { props: DbDeviceConfig }) {
	const [editableText, setEditableText] = useState<string>(
		JSON.stringify(props.config, null, "\t")
	)

	useEffect(() => {
		setEditableText(JSON.stringify(props.config, null, "\t"))
	}, [props])

	async function saveNewConfig() {
		let obj = undefined
		try {
			obj = JSON.parse(editableText)
		} catch (e) {
			alert(e)
		}
		if (obj !== undefined) {
			if (confirm("Are you sure you want to save the new config?") == true) {
				const argss: DeviceConfigAddArgs = {
					config: obj as ConfigData,
					deviceId: props.deviceId,
				}
				console.log(argss)
				try {
					await requestApi2(endpoints.deviceConfigAdd, argss)
				} catch (e) {
					alert(e)
				}
			}
		}
	}

	return (
		<>
			<textarea
				id={"textarea"}
				rows={30}
				cols={170}
				value={editableText}
				onChange={(e) => setEditableText(e.target.value)}
			/>
			<Button label="Save as new config" onClick={() => saveNewConfig()} />
		</>
	)
}
