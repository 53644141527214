import { requestApi2 } from "@utils/http"
import { DbDevice, DeviceSignalUpdateArgs, endpoints } from "api"
import { useEffect, useState } from "react"
import Button from "./Button"

export default function () {
	const [devices, setDevices] = useState<DbDevice[] | undefined>()

	const [deviceId, setDeviceId] = useState<number | undefined>()
	const [selectedDevice, setSelectedDevice] = useState<DbDevice | undefined>()
	const [tokenText, setTokenText] = useState<string>("")

	async function loadListOfDevices() {
		const res = await requestApi2(endpoints.getAllDevices)
		if (res === null) {
			return
		}
		setDevices(res)
	}

	useEffect(() => {
		if (devices == undefined) {
			loadListOfDevices()
		}
	}, [])

	useEffect(() => {
		setSelectedDevice(undefined)
		if (devices !== undefined) {
			for (const devicee of devices) {
				if (devicee.id == deviceId) {
					setSelectedDevice(devicee)
					break
				}
			}
		}
	}, [deviceId])

	async function updateDevice() {
		if (selectedDevice !== undefined) {
			if (confirm("Are you sure you want to force an update?") == true) {
				const argss: DeviceSignalUpdateArgs = {
					deviceId: selectedDevice.id,
					token: tokenText,
				}
				console.log(argss)
				try {
					await requestApi2(endpoints.deviceSignalUpdate, argss)
				} catch (e) {
					alert(e)
				}
			}
		}
	}

	return (
		<>
			Update the source code running on the device.
			{devices == undefined ? (
				<p>Loading...</p>
			) : (
				<>
					<br></br>
					<label htmlFor="device-selector">Plant</label>
					<br></br>
					<select
						id="device-selector"
						onChange={(evt) => setDeviceId(parseFloat(evt.target.value))}
						defaultValue={""}
					>
						<option disabled value="">
							{" "}
							-- select an option --{" "}
						</option>
						{devices.map((device, index) => {
							return (
								<option key={index} value={device.id}>
									{device.name + " @ " + device.plantName}
								</option>
							)
						})}
					</select>
				</>
			)}
			{selectedDevice !== undefined ? (
				<>
					<br></br> Enter pass token used for git pull: <br></br>
					<input
						type="text"
						id="lname"
						name="lname"
						size={80}
						value={tokenText}
						onChange={(e) => setTokenText(e.target.value)}
					/>
				</>
			) : (
				<></>
			)}
			{tokenText.length > 5 ? (
				<>
					<br></br> <Button label="Update device" onClick={() => updateDevice()} />
				</>
			) : (
				<></>
			)}
		</>
	)
}
