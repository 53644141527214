import { AppDispatch } from "@redux/app/store"
import { updateMapParamsState } from "@redux/features/mapParams/mapParamsSlice"
import { Dispatch, SetStateAction, useEffect } from "react"
import { QueryClient } from "react-query"
import { RegularMapType, destroyInstance, getRegularMap, tryInitRegularMap } from "./regularMap"

export default function useInitRegularMap(
	dispatch: AppDispatch,
	queryClient: QueryClient,
	setMap: Dispatch<SetStateAction<RegularMapType | undefined>>,
	map?: RegularMapType,
	divId?: string
) {
	useEffect(() => {
		let temp: RegularMapType | undefined
		const init = async () => {
			if (map === undefined) {
				const newMap = await tryInitRegularMap(dispatch, queryClient, divId)
				if (newMap !== undefined) {
					temp = newMap
				} else {
					temp = getRegularMap()
				}
				if (temp !== undefined) {
					setMap(temp)
				}
			}
		}
		init().catch(console.error)
		return () => {
			if (divId !== undefined && temp !== undefined) {
				dispatch(updateMapParamsState({ zoom: undefined }))
				destroyInstance(temp, divId)
			} else if (divId === undefined) {
				getRegularMap()?.clearLayers()
			}
		}
	}, [])
}
