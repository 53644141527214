import { createShipIconNL } from "@components/map/mapComponents/shipIcon"
import { updateSidePanelState } from "@redux/features/mapSidePanelControl/mapSidePanelControlSlice"
import { setSelectedVessel } from "@redux/features/selectedVessel/selectedVesselSlice"
import { main_dimensions_vessel_data } from "@sop/MapPage/table/hard_coded_vessel_data"
import { common } from "@utils/common"
import { LatLng } from "leaflet"
import { DataSetter } from "../utils/mapLayers"

export const addVesselsNL: DataSetter = (
	dispatch,
	queryClient,
	layerGroup,
	layerData,
	navigate,
	map
) => {
	if (layerGroup === undefined) return

	const { vesselsNlData } = layerData
	if (vesselsNlData === undefined) return

	const { company, vesselInfo, selectedVessel } = vesselsNlData
	if (vesselInfo.length === 0) return

	layerGroup.clearLayers()

	for (const ship of vesselInfo) {
		if (
			ship?.position?.hasOwnProperty("lat") !== true ||
			ship?.position?.hasOwnProperty("lon") !== true
		) {
			continue
		}
		if (isNaN(ship.position.lat) === true || isNaN(ship.position.lon) == true) {
			continue
		}
		const shipCoord: LatLng = L.latLng(ship.position.lat, ship.position.lon)

		const plantData =
			company.toLowerCase() !== "dipai"
				? common.plantDataFromOwnerAndId.get(`${company.toLowerCase()}/${ship.plantId}`)
				: common.plantDataFromName.get(ship.name)

		const isSelected = selectedVessel === ship.name

		const marker = L.marker(shipCoord, {
			icon: createShipIconNL(
				ship.position.heading,
				ship.condition,
				isSelected,
				ship.position.lat,
				main_dimensions_vessel_data[ship.name]?.LOA,
				main_dimensions_vessel_data[ship.name]?.Breadth,
				map?.getZoom()
			),
			pane: "vesselsPane",
		}).on("click", () => {
			if (ship === null) {
				return
			}
			dispatch(setSelectedVessel(ship))
			dispatch(
				updateSidePanelState({
					isTableExpanded: false,
					isOpen: true,
					selectedTab: "1",
				})
			)
			if (navigate !== undefined) {
				navigate({
					to: `.`,
					search: (prev) => ({
						...prev,
						vessel: plantData?.display_name ?? "unknown vessel",
					}),
				})
			}
		})

		const labelIcon = L.divIcon({
			className: `div-vessel-${plantData?.display_name.replace(" ", "-")} text-white overflow-visible`,
			html: `<span class="vessel-label vessel-${plantData?.display_name.replace(" ", "-")} drop-shadow-[1px_1px_0.5px_rgba(0,0,0,0.35),1px_-0.5px_0.5px_rgba(0,0,0,0.35),-0.5px_1px_0.5px_rgba(0,0,0,0.35),-0.5px_-0.5px_0.5px_rgba(0,0,0,0.35)] pl-1 w-max pr-1 py-0 font-bold block rounded-sm">${
				plantData?.display_name ?? "unknown vessel"
			}</span>`,
			iconSize: [1, 20],
			iconAnchor: [-15, 10],
		})
		const labelOffset = L.latLng(shipCoord.lat, shipCoord.lng)

		const labelMarker = L.marker(labelOffset, {
			icon: labelIcon,
			interactive: false,
			pane: "tooltipPane",
		})

		layerGroup.addLayer(marker)
		layerGroup.addLayer(labelMarker)

		marker.on("mouseover", () => {
			if (isSelected === true) {
				return
			}
			const markerContainer = marker.getElement()
			if (markerContainer !== undefined) {
				markerContainer.style.setProperty("border-radius", "99999px", "important")
				markerContainer.style.setProperty("border", "2px solid #ffffffff", "important")
				markerContainer.style.setProperty("background-color", "#ffffff44", "important")
				markerContainer.style.setProperty("z-index", "10000000", "important")
			}
			const vesselLabelDiv = document.querySelector<HTMLElement>(
				`.div-vessel-${plantData?.display_name.replace(" ", "-")}`
			)
			if (vesselLabelDiv !== null) {
				vesselLabelDiv.style.setProperty("z-index", "10000001", "important")
			}
			const vesselLabel = document.querySelector<HTMLElement>(
				`.vessel-${plantData?.display_name.replace(" ", "-")}`
			)

			if (vesselLabel !== null) {
				if (vesselLabel.style.borderRadius !== "") {
					vesselLabel.style.setProperty("display", "block")
				}
				vesselLabel.style.setProperty("background-color", "rgba(0,0,0,0.35)", "important")
				vesselLabel.style.setProperty("position", "relative", "important")
				vesselLabel.style.setProperty("z-index", "10000001", "important")
			}
		})
		marker.on("mouseout", () => {
			if (isSelected === true) {
				return
			}
			const markerContainer = marker.getElement()
			if (markerContainer !== undefined) {
				markerContainer.style.setProperty("border", "2px solid #ffffff00", "important")
				markerContainer.style.setProperty("background-color", "#ffffff00", "important")
				markerContainer.style.setProperty("z-index", "1", "important")
			}

			const vesselLabelDiv = document.querySelector<HTMLElement>(
				`.div-vessel-${plantData?.display_name.replace(" ", "-")}`
			)
			if (vesselLabelDiv !== null) {
				vesselLabelDiv.style.setProperty("z-index", "1", "important")
			}

			const vesselLabel = document.querySelector<HTMLElement>(
				`.vessel-${plantData?.display_name.replace(" ", "-")}`
			)
			if (vesselLabel !== null) {
				if (vesselLabel.style.borderRadius !== "") {
					vesselLabel.style.setProperty("display", "none", "")
				}
				vesselLabel.style.setProperty("background-color", "#00000000")
				vesselLabel.style.setProperty("position", "relative", "important")
				vesselLabel.style.setProperty("z-index", "1", "important")
			}
		})
		if (isSelected === true) {
			const vesselLabel = document.querySelector<HTMLElement>(
				`.vessel-${plantData?.display_name.replace(" ", "-")}`
			)
			if (vesselLabel !== null) {
				vesselLabel.style.setProperty("display", "block")
				vesselLabel.style.setProperty("background-color", "rgba(0,0,0,0.35)", "important")
				vesselLabel.style.setProperty("position", "relative", "important")
				vesselLabel.style.setProperty("z-index", "10000000", "important")
			}
		}
	}
}
