import { createContext, Dispatch, ReactNode, SetStateAction, useContext } from "react"
import { useTranslation } from "react-i18next"

export type ModuleMetadata<TAppFeatures, TAppModulesList extends string> = {
	feature?: TAppFeatures
	icon?: ReactNode
	config?: { hideVesselSelection?: boolean; hideHeader?: boolean }
	name: TAppModulesList
	content: ReactNode
	alternativeContent?: { constraintName: string; content: ReactNode }[]
}

export interface IPageLayoutContext<TAppModulesList extends string> {
	selectedModuleMetadata?: ModuleMetadata<unknown, string>
	mainItems: { name: TAppModulesList; icon: ReactNode }[]
	subItems?: string[]
	isMainNavExpanded: boolean
	setIsMainNavExpanded: Dispatch<SetStateAction<boolean>>
	isMainNavPopupExpanded: boolean
	setIsMainNavPopupExpanded: Dispatch<SetStateAction<boolean>>
	isSecondaryNavExpanded: boolean
	setIsSecondaryNavExpanded: Dispatch<SetStateAction<boolean>>
	translationFunc: ReturnType<typeof useTranslation>["t"] | undefined
	submitAction?: (name: string) => void
}

export const PageLayoutContext = createContext<IPageLayoutContext<string> | undefined>(undefined)

export const usePageLayoutContext = <
	TAppModulesList extends string
>(): IPageLayoutContext<TAppModulesList> => {
	const context = useContext(PageLayoutContext) as IPageLayoutContext<TAppModulesList> | undefined
	if (context === undefined) {
		throw new Error("This component must be used within a <PageLayout> component.")
	}
	return context
}
