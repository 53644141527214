import { OilAndGasFieldType } from "@assets/oilAndGasData/fldArea"
import { mapLayersColors } from "../utils/mapLayers"
import { MapElementMetadata } from "../utils/utils"

export const changeOpacity = (hslColor: string, opacity: number = 0.3) => {
	const hslWithOpacity = hslColor.slice(0, 3) + "a" + hslColor.slice(3, -1) + `,${opacity})`
	return hslWithOpacity
}

export const createCombinedTooltip = (metadata: MapElementMetadata[]) => {
	const mainDiv = L.DomUtil.create(
		"div",
		`grid gap-2 grid-rows-[repeat(${metadata.length},48px)] divide-solid divide-gray-400 divide-y`
	)
	mainDiv.style.backgroundColor = "rgba(255, 255, 255)"
	const containsNonDiseaseZone = metadata.some(
		(props) => props.facility !== undefined || props.oilAndGasField !== undefined
	)
	for (const props of metadata) {
		if (props.facility !== undefined) {
			const { name, radius, type, location } = props.facility
			const color = mapLayersColors.facilities[type]
			const popupDiv = L.DomUtil.create("div", "grid grid-cols-[60px_auto] not-last:pb-2")

			const iconDiv = L.DomUtil.create(
				"div",
				"w-[60px] h-[60px] rounded-full border-2 border-dashed"
			)
			iconDiv.style.backgroundColor = changeOpacity(color)
			iconDiv.style.borderColor = color

			const textDiv = L.DomUtil.create("div", "ml-2")

			const p = L.DomUtil.create("p", "font-bold text-gray-700 text-small")
			p.textContent = name

			const p2 = L.DomUtil.create("p", "text-gray-600 text-small")
			p2.textContent = `${type.replaceAll("_", " ").toLowerCase()}${location !== undefined ? ` - ${location}` : ""}`

			const pExtra = L.DomUtil.create("p", "text-gray-600 text-small")
			pExtra.textContent = `radius: ${radius} m`

			textDiv.appendChild(p)
			textDiv.appendChild(p2)
			textDiv.appendChild(pExtra)

			popupDiv.appendChild(iconDiv)
			popupDiv.appendChild(textDiv)

			mainDiv.appendChild(popupDiv)
		}
		if (props.diseaseZone !== undefined) {
			const { color, zoneType, regulationNumber } = props.diseaseZone
			const popupDiv = L.DomUtil.create(
				"div",
				`grid ${containsNonDiseaseZone === true ? "grid-cols-[60px_auto]" : "grid-cols-[40px_auto]"} not-last:pb-2`
			)

			const iconDiv = L.DomUtil.create(
				"div",
				`w-10 h-10 rounded-md border-2 ${containsNonDiseaseZone === true ? "ml-[10px]" : "ml-0"}`
			)
			iconDiv.style.backgroundColor = changeOpacity(color)
			iconDiv.style.borderColor = color

			const textDiv = L.DomUtil.create("div", "ml-2")

			const p = L.DomUtil.create("p", "font-bold text-gray-700 text-small")
			p.textContent = zoneType

			const p2 = L.DomUtil.create("p", "text-gray-600 text-small")
			p2.textContent = regulationNumber

			textDiv.appendChild(p)
			textDiv.appendChild(p2)

			popupDiv.appendChild(iconDiv)
			popupDiv.appendChild(textDiv)
			mainDiv.appendChild(popupDiv)
		}
		if (props.oilAndGasField !== undefined) {
			const { id, location, name, operator, type } = props.oilAndGasField
			const popupDiv = L.DomUtil.create("div", "grid grid-cols-[60px_auto] not-last:pb-2")

			const iconDiv = L.DomUtil.create("div", "w-[60px] h-[60px] rounded-md")
			const color =
				type === "--"
					? "hsl(285,0%,50%)"
					: mapLayersColors.oilAndGasFields[
							OilAndGasFieldType[type] as OilAndGasFieldType[number]
						]
			iconDiv.style.backgroundColor = changeOpacity(color, 0.75)

			const textDiv = L.DomUtil.create("div", "ml-2")

			const p = L.DomUtil.create("p", "font-bold text-gray-700 text-small")
			p.textContent = name + " "

			const span = L.DomUtil.create("span", "text-tiny", p)
			span.textContent = `(id: ${id})`

			const p2 = L.DomUtil.create("p", "text-gray-600 text-small")
			p2.textContent = `${type} - ${location}`

			const pExtra = L.DomUtil.create("p", "text-gray-600 text-small")
			pExtra.textContent = `operator: ${operator}`

			textDiv.appendChild(p)
			textDiv.appendChild(p2)
			textDiv.appendChild(pExtra)

			popupDiv.appendChild(iconDiv)
			popupDiv.appendChild(textDiv)

			mainDiv.appendChild(popupDiv)
		}
	}

	return mainDiv
}
