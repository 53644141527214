import { InformationContainer } from "@components/containers/Containers"
import { IconId } from "@components/icon/IconId"
import KpiVesselsContent from "@components/kpi/KpiVesselsContent"
import { getSensorId } from "@helpers/getSensorId"
import { getSensorIdWithoutComponent } from "@helpers/getSensorIdWithoutComponent"
import { useAppSelector } from "@redux/app/hooks"
import { getSelectedVessel } from "@redux/features/selectedVessel/selectedVesselSlice"
import { common } from "@utils/common"
import { AggregationMethod } from "api"

export default function FishKpis({ customerName }: { customerName: string }) {
	const selectedVessel = useAppSelector(getSelectedVessel)

	const plantData = common.plantDataFromOwnerAndName.get(
		`${customerName?.toLowerCase()}/${selectedVessel.name?.toLowerCase()}`
	)

	return (
		<InformationContainer>
			<>
				<div className="grid grid-cols-[266.5px_2px_266.5px] border-b-2 border-gray-eee">
					<KpiVesselsContent
						customerName={customerName}
						iconId={IconId.Fish}
						label={"Fish count (PS)"}
						topics={[
							{
								sensorId:
									getSensorIdWithoutComponent(plantData, "count1") !== -1
										? getSensorIdWithoutComponent(plantData, "count1")
										: getSensorIdWithoutComponent(
												plantData,
												"Fish Loading PS Count"
										  ),
								aggregationMethod: AggregationMethod.MIN_LAST_REPUB,
							},
						]}
					/>
					<div className="my-5 bg-gray-eee" />
					<KpiVesselsContent
						customerName={customerName}
						iconId={IconId.Fish}
						label={"Fish count (SB)"}
						topics={[
							{
								sensorId:
									getSensorIdWithoutComponent(plantData, "count2") !== -1
										? getSensorIdWithoutComponent(plantData, "count2")
										: getSensorIdWithoutComponent(
												plantData,
												"Fish Loading SB Count"
										  ),
								aggregationMethod: AggregationMethod.MIN_LAST_REPUB,
							},
						]}
					/>
				</div>
				<div className="grid grid-cols-[266.5px_2px_266.5px]">
					<KpiVesselsContent
						customerName={customerName}
						iconId={IconId.Biomass}
						label={"Bio mass (PS)"}
						unit={"kg"}
						topics={[
							{
								sensorId:
									getSensorIdWithoutComponent(plantData, "biomass1") !== -1
										? getSensorIdWithoutComponent(plantData, "biomass1")
										: getSensorIdWithoutComponent(
												plantData,
												"Fish Loading PS Biomass"
										  ),
								aggregationMethod: AggregationMethod.SNAP_FIRST,
							},
						]}
					/>
					<div className="my-5 bg-gray-eee" />
					<KpiVesselsContent
						customerName={customerName}
						iconId={IconId.Biomass}
						label={"Bio mass (SB)"}
						unit={"kg"}
						topics={[
							{
								sensorId:
									getSensorIdWithoutComponent(plantData, "biomass2") !== -1
										? getSensorIdWithoutComponent(plantData, "biomass2")
										: getSensorIdWithoutComponent(
												plantData,
												"Fish Loading SB Biomass"
										  ),
								aggregationMethod: AggregationMethod.SNAP_FIRST,
							},
						]}
					/>
				</div>
				{getSensorId(plantData, "VAKI", "VakiCount1") !== -1 ? (
					<div className="grid grid-cols-[266.5px_2px_266.5px] border-t-2 border-gray-eee">
						<KpiVesselsContent
							customerName={customerName}
							iconId={IconId.Fish}
							label={"Smolt count (PS)"}
							topics={[
								{
									sensorId: getSensorId(plantData, "VAKI", "VakiCount1"),
									aggregationMethod: AggregationMethod.SNAP_FIRST,
								},
							]}
						/>
						<div className="my-5 bg-gray-eee" />
						<KpiVesselsContent
							customerName={customerName}
							iconId={IconId.Fish}
							label={"Smolt count (SB)"}
							topics={[
								{
									sensorId: getSensorId(plantData, "VAKI", "VakiCount2"),
									aggregationMethod: AggregationMethod.SNAP_FIRST,
								},
							]}
						/>
					</div>
				) : (
					""
				)}
			</>
		</InformationContainer>
	)
}
