import Page from "@components/basicPage/Page"
import { useAppSelector } from "@redux/app/hooks"
import { getAppStructure } from "@redux/features/appStructure/appStructureSlice"
import { createFileRoute, Navigate, Outlet } from "@tanstack/react-router"
import { LoadingCircle } from "@ui-lib/index"

export const Route = createFileRoute("/_adminPageLayout")({
	component: RouteComponent,
})

function RouteComponent() {
	const appStruct = useAppSelector(getAppStructure)
	if (appStruct === null) {
		return <LoadingCircle containerHeightWithUnit="100vh" />
	}
	if (Object.keys(appStruct)?.[0]?.toLowerCase() !== "dipai") {
		return <Navigate to="/fleet-overview" replace={true} />
	}
	return (
		<Page bodyWidth={"100%"}>
			<Outlet />
		</Page>
	)
}
