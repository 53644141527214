import HomePage from "@pages/HomePage/HomePage"
import { useAppSelector } from "@redux/app/hooks"
import { getAppStructure } from "@redux/features/appStructure/appStructureSlice"
import { createFileRoute } from "@tanstack/react-router"

type MapPageSearch = {
	vessel?: string
}

export const Route = createFileRoute("/_adminPageLayout/map")({
	component: RouteComponent,
	validateSearch: (search: Record<string, unknown>): MapPageSearch => {
		//TODO: validate search params here
		return {
			vessel: (search.vessel as string) ?? undefined,
		}
	},
})

function RouteComponent() {
	const appStruct = useAppSelector(getAppStructure)

	return appStruct === null ? <>{"Loading..."}</> : <HomePage props={appStruct} />
}
