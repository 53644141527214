import { OilAndGasFieldType } from "@assets/oilAndGasData/fldArea"
import { FacilityType } from "api"
import { Point } from "geojson"
import { CircleMarker, GeoJSON, LatLng, Layer, Map, Marker, Polygon, Polyline } from "leaflet"
import { DiseaseZoneProperties } from "../layersConstructors/addDiseaseZonesConstructor"

export type PointCollection = GeoJSON.FeatureCollection<Point>
export type PointFeature = GeoJSON.Feature<Point>

function normalizeMultiPolygonCoordinates(
	multiPolygon: (number[][] | number[][][])[],
	reverse: boolean = true
) {
	return multiPolygon.map((polygon) =>
		Array.isArray(polygon[0]?.[0])
			? (polygon as number[][][]).map((v) =>
					v.map((g) => {
						const t = reverse === true ? ([...g] as number[]).reverse() : g
						return t
					})
				)
			: [
					(polygon as number[][]).map((v) => {
						const t = reverse === true ? ([...v] as number[]).reverse() : v
						return t
					}),
				]
	)
}

type ExtendedLayer = Layer & { _leaflet_id?: number }
export function getLayersAtPoint(map: Map, latlng: LatLng): Layer[] {
	const foundLayers: Layer[] = []
	map.eachLayer((layer) => {
		if (
			foundLayers.some(
				(l: ExtendedLayer) => l?._leaflet_id === (layer as ExtendedLayer)?._leaflet_id
			)
		) {
			return
		}
		if ((layer as unknown as GeoJSON).addData !== undefined) {
			if ((layer as unknown as GeoJSON).getLayers().length > 0) {
				const geojsonLayers = leafletPip.pointInLayer(latlng, layer as unknown as GeoJSON)
				if (geojsonLayers.length > 0) {
					foundLayers.push(...geojsonLayers)
				}
			}
		} else if ((layer as unknown as Marker)?.getLatLng !== undefined) {
			const distance = latlng.distanceTo((layer as unknown as Marker).getLatLng())
			const markerRadius =
				(layer as unknown as CircleMarker)?.getRadius !== undefined
					? (layer as unknown as CircleMarker).getRadius()
					: 100 // Default marker size ~10px
			if (distance <= markerRadius) {
				foundLayers.push(layer)
			}
		} else if (
			(layer as unknown as Polyline).getBounds !== undefined &&
			(layer as unknown as Polyline).getLatLngs !== undefined &&
			(layer as unknown as Polyline).toGeoJSON !== undefined
		) {
			const bounds = (layer as unknown as Polyline).getBounds()
			if (bounds.contains(latlng) === false) {
				return
			}
			const tolerance = 5 // pixels
			const mousePoint = map.latLngToLayerPoint(latlng)
			const polylinePoints = (layer as unknown as Polygon).getLatLngs()
			const ref = polylinePoints[polylinePoints.length - 1]
			if (
				Array.isArray(polylinePoints[0]) === false &&
				Array.isArray(ref) === false &&
				(polylinePoints[0]?.lat !== ref?.lat || polylinePoints[0]?.lng === ref?.lng)
			) {
				const flatPolylinePoints = polylinePoints.flat(3)
				for (let i = 0; i < polylinePoints.length - 1; i++) {
					const polylinePoints_i = flatPolylinePoints[i]
					const polylinePoints_i_plus_1 = flatPolylinePoints[i + 1]
					if (polylinePoints_i === undefined || polylinePoints_i_plus_1 === undefined) {
						continue
					}
					const p1 = map.latLngToLayerPoint(polylinePoints_i)
					const p2 = map.latLngToLayerPoint(polylinePoints_i_plus_1)

					if (p1.x === p2.x && p1.y === p2.y) {
						continue
					}
					if (isPointNearPoint(mousePoint, p1, tolerance)) {
						foundLayers.push(layer)
						break
					}
				}
			} else {
				const foundLayer = leafletPip.pointInLayer(
					latlng,
					L.geoJSON({
						type: "Feature",
						geometry: latLngToGeoJSON(polylinePoints) as GeoJSON.Geometry,
						properties: {},
					} as GeoJSON.GeoJsonObject)
				)
				if (foundLayer.length > 0) {
					foundLayers.push(layer)
				}
			}
		}
	})
	return foundLayers
}

function isPointNearLineSegment(
	point: L.Point,
	p1: L.Point,
	p2: L.Point,
	tolerance: number
): boolean {
	const distance =
		Math.abs(point.x * (p2.y - p1.y) - (p2.x - p1.x) * point.y + p2.x * p1.y - p2.y * p1.x) /
		Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))

	return distance <= tolerance
}

function isPointNearPoint(pointMouse: L.Point, pointSegment: L.Point, tolerance: number): boolean {
	const distance = Math.sqrt(
		Math.pow(pointMouse.x - pointSegment.x, 2) + Math.pow(pointMouse.y - pointSegment.y, 2)
	)

	return distance <= tolerance
}

function extractCoords(latlngArr: LatLng[] | LatLng[][] | LatLng[][][]) {
	return Array.isArray(latlngArr[0])
		? (latlngArr as LatLng[][] | LatLng[][][]).map((latlng) => extractCoords(latlng))
		: (latlngArr as LatLng[]).map((latlng) => [
				(latlng as unknown as LatLng).lng,
				(latlng as unknown as LatLng).lat,
			])
}

function latLngToGeoJSON(latlngs: LatLng[] | LatLng[][] | LatLng[][][]) {
	const coordinates: [number, number][][][] = extractCoords(latlngs)

	if (!Array.isArray(coordinates[0])) return { type: "Point", coordinates }
	if (!Array.isArray(coordinates[0][0])) return { type: "LineString", coordinates }
	if (
		coordinates.map((c) => !Array.isArray(c[0]?.[0])).includes(false) === false &&
		coordinates.length === 1
	)
		return { type: "Polygon", coordinates }

	return {
		type: "MultiPolygon",
		coordinates: normalizeMultiPolygonCoordinates(coordinates, false),
	}
}

export function getBearing(start: { lat: number; lng: number }, end: { lat: number; lng: number }) {
	const lat1 = (start.lat * Math.PI) / 180
	const lat2 = (end.lat * Math.PI) / 180
	const deltaLng = ((end.lng - start.lng) * Math.PI) / 180

	const y = Math.sin(deltaLng) * Math.cos(lat2)
	const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(deltaLng)
	const angle = (Math.atan2(y, x) * 180) / Math.PI

	return ((angle + 360) % 360) - 0
}

export function createArrowIcon(angle: number, color1?: string, color2?: string) {
	return L.divIcon({
		className: ``,
		html: `
		<svg viewBox="0 0 30 30" class="" >
			<defs>
				<linearGradient id="vesselGradient_${`${color1}_${color2}`.replaceAll(", ", "_").replaceAll("(", "_").replaceAll(")", "_")}" x1="0%" y1="0%" x2="100%" y2="0%">
					<stop offset="0%" stop-color="${color1 ?? "#262626ff"}" />
					<stop offset="50%" stop-color="${color1 ?? "#262626ff"}" />
					<stop offset="50%" stop-color="${color2 ?? "#262626ff"}" />
					<stop offset="100%" stop-color="${color2 ?? "#262626ff"}" />
				</linearGradient>
			</defs>

			<path
				d="M 2.5 9 L 22.5 9 L 27.5 15 L 22.5 21 L 2.5 21 L 2.5 9"
				fill="url(#vesselGradient_${`${color1}_${color2}`.replaceAll(", ", "_").replaceAll("(", "_").replaceAll(")", "_")})"
				stroke="#fff"
				transform="rotate(${-90 + (angle ?? 0)},15,15)"
			/>
		</svg>
	`,
		iconSize: [20, 20],
		iconAnchor: [10, 10],
	})
}

export type MapElementMetadata = {
	facility?: { name: string; type: FacilityType; radius: number; location?: string }
	diseaseZone?: DiseaseZoneProperties
	oilAndGasField?: {
		id: number
		name: string
		type: OilAndGasFieldType | "--"
		location: string
		operator: string
	}
}
