import { PointCollection } from "@components/map/utils/utils"
import { WindyMapType } from "@components/map/windyMap/windyMap"
import { useAppSelector } from "@redux/app/hooks"
import { getSelectedVessel, VesselInfo } from "@redux/features/selectedVessel/selectedVesselSlice"
import { useSearch } from "@tanstack/react-router"
import { PlantInfo, VesselInfoOperation } from "api"
import { LatLngBounds } from "leaflet"
import { useEffect, useState } from "react"
import { RegularMapType } from "../regularMap/regularMap"

type MinOrMax = "min" | "max"
type LatOrLon = "lat" | "lon"

const setVesselBoundingBox = (
	map: RegularMapType | WindyMapType | undefined,
	selectedVessel: VesselInfo
) => {
	if (selectedVessel.position?.hasOwnProperty("lat") === false) {
		return
	}
	const boundShip = getBounds((arg1, arg2) => getMinOrMaxShips([selectedVessel], arg1, arg2))
	if (boundShip === undefined || map === undefined) {
		return
	}
	map.fitBounds(boundShip)
}

const setBoundingBox = (
	map: RegularMapType | WindyMapType | undefined,
	filteredShipData: PlantInfo[],
	textFilteredFishGeoJson: PointCollection
) => {
	if (map === undefined) {
		return
	}
	const boundShips =
		filteredShipData.length > 0
			? getBounds((arg1, arg2) => getMinOrMaxShips(filteredShipData, arg1, arg2))
			: undefined
	const boundFishFarms =
		textFilteredFishGeoJson.features.length > 0
			? getBounds((arg1, arg2) => getMinOrMaxFishFarms(textFilteredFishGeoJson, arg1, arg2))
			: undefined
	if (boundShips === undefined && boundFishFarms === undefined) {
		return
	}
	const bound = L.latLngBounds(
		[
			Math.max(boundShips?.getNorth() ?? -90, boundFishFarms?.getNorth() ?? -90, -90),
			Math.max(boundShips?.getEast() ?? -179, boundFishFarms?.getEast() ?? -179, -179),
		],
		[
			Math.min(boundShips?.getSouth() ?? 90, boundFishFarms?.getSouth() ?? 90, 90),
			Math.min(boundShips?.getWest() ?? 179, boundFishFarms?.getWest() ?? 179, 179),
		]
	)
	map.fitBounds(bound, false)
}

const setBoundingBoxVesselCenter = (
	map: RegularMapType | WindyMapType | undefined,
	filteredShipData: PlantInfo[],
	textFilteredFishGeoJson: PointCollection
) => {
	if (map === undefined) {
		return
	}
	const boundShips =
		filteredShipData.length > 0
			? getBounds((arg1, arg2) => getMinOrMaxShips(filteredShipData, arg1, arg2))
			: undefined
	const boundFishFarms =
		textFilteredFishGeoJson.features.length > 0
			? getBounds((arg1, arg2) => getMinOrMaxFishFarms(textFilteredFishGeoJson, arg1, arg2))
			: undefined
	if (boundShips === undefined && boundFishFarms === undefined) {
		return
	}
	const bound = L.latLngBounds(
		[
			Math.max(boundShips?.getNorth() ?? -90, boundFishFarms?.getNorth() ?? -90, -90),
			Math.max(boundShips?.getEast() ?? -179, boundFishFarms?.getEast() ?? -179, -179),
		],
		[
			Math.min(boundShips?.getSouth() ?? 90, boundFishFarms?.getSouth() ?? 90, 90),
			Math.min(boundShips?.getWest() ?? 179, boundFishFarms?.getWest() ?? 179, 179),
		]
	)

	const center = (boundShips ?? bound).getCenter()

	const trackNorth = bound.getNorth()
	const trackSouth = bound.getSouth()
	const trackEast = bound.getEast()
	const trackWest = bound.getWest()

	const newNorth = center.lat + (trackNorth - trackSouth)
	const newSouth = center.lat - (trackNorth - trackSouth)
	const newEast = center.lng + (trackEast - trackWest)
	const newWest = center.lng - (trackEast - trackWest)

	const adjustedBounds = L.latLngBounds([
		L.latLng(newNorth, newEast),
		L.latLng(newSouth, newWest),
	])
	const zoom = map.getBoundsZoom(adjustedBounds)
	map.setView(center, zoom !== undefined ? (zoom > 11 ? 11 : zoom) : 11)
}

const getBounds = (
	getMinOrMax: (minOrMax: MinOrMax, latOrLon: LatOrLon) => number | undefined
): LatLngBounds | undefined => {
	const maxLat = getMinOrMax("max", "lat")
	const minLat = getMinOrMax("min", "lat")
	const maxLon = getMinOrMax("max", "lon")
	const minLon = getMinOrMax("min", "lon")

	if (
		maxLat === undefined ||
		minLat === undefined ||
		maxLon === undefined ||
		minLon === undefined
	) {
		console.log("The latLng object is ill-defined: ", [
			[maxLat, minLat],
			[maxLon, minLon],
		])
		return undefined
	}
	if (
		isNaN(parseFloat(maxLat.toString())) === true ||
		isNaN(parseFloat(minLat.toString())) === true ||
		isNaN(parseFloat(maxLon.toString())) === true ||
		isNaN(parseFloat(minLon.toString())) === true
	) {
		console.log("The latLng object is ill-defined: ", [
			[maxLat, minLat],
			[maxLon, minLon],
		])
		return undefined
	}

	const bound = L.latLngBounds([
		[maxLat, maxLon],
		[minLat, minLon],
	])

	return bound
}

const assignDefaultCoord = (minOrMax: MinOrMax, latOrLon: LatOrLon) => {
	return latOrLon === "lat" ? (minOrMax === "min" ? -45 : 45) : minOrMax === "min" ? -45 : 45
}

const isCoordRangeCorrect = (coord: number, latOrLon: LatOrLon) => {
	return (
		(latOrLon === "lat" && coord <= 90 && coord >= -90) ||
		(latOrLon === "lon" && coord <= 180 && coord >= -180)
	)
}

const getMinOrMaxShips = (
	data: VesselInfoOperation[],
	minOrMax: MinOrMax,
	latOrLon: LatOrLon
): number | undefined => {
	const idx = latOrLon === "lat" ? 1 : 0
	let res: number | undefined
	let f = 0
	let data_f = data[0]

	while (
		data_f?.position === undefined
			? true
			: data_f.position.hasOwnProperty("lat") === false
				? true
				: data_f.position.hasOwnProperty("lon") === false
	) {
		f++
		data_f = data[f]
		if (f === data.length) {
			break
		}
	}

	if (data_f?.position !== undefined) {
		res =
			isCoordRangeCorrect(
				idx === 1 ? Number(data_f.position.lat) : Number(data_f.position.lon),
				latOrLon
			) === true
				? idx === 1
					? Number(data_f.position.lat)
					: Number(data_f.position.lon)
				: assignDefaultCoord(minOrMax, latOrLon)
	} else {
		return undefined
	}

	for (let i = f; i < data.length; i++) {
		const data_i = data[i]
		if (data_i === undefined || data_i.position === undefined) {
			continue
		}
		if (
			data_i.position.hasOwnProperty("lat") !== true ||
			data_i.position.lat > 90 ||
			data_i.position.lat < -90 ||
			data_i.position.hasOwnProperty("lon") !== true ||
			data_i.position.lon > 180 ||
			data_i.position.lon < -180
		) {
			continue
		}
		if (idx === 1) {
			if (
				minOrMax === "min"
					? Number(data_i.position.lat) < res
					: Number(data_i.position.lat) > res
			) {
				res = Number(data_i.position.lat)
			}
		} else {
			if (
				minOrMax === "min"
					? Number(data_i.position.lon) < res
					: Number(data_i.position.lon) > res
			) {
				res = Number(data_i.position.lon)
			}
		}
	}
	return res
}

const getMinOrMaxFishFarms = (
	data: PointCollection,
	minOrMax: MinOrMax,
	latOrLon: LatOrLon
): number => {
	// Note: longitude={fishFarms[i].geometry.coordinates[0]}
	//       latitude ={fishFarms[i].geometry.coordinates[1]}
	const idx = latOrLon === "lat" ? 1 : 0
	const fishFarms = data.features
	let refCoord = fishFarms[0]?.geometry.coordinates[idx]
	if (refCoord === undefined) {
		refCoord = assignDefaultCoord(minOrMax, latOrLon)
	}
	let res: number = isCoordRangeCorrect(refCoord, latOrLon)
		? refCoord
		: assignDefaultCoord(minOrMax, latOrLon)
	for (const fishFarmData of fishFarms) {
		const coord = fishFarmData.geometry.coordinates[idx]
		if (
			coord !== undefined &&
			isCoordRangeCorrect(coord, latOrLon) &&
			(minOrMax === "min" ? coord < res : coord > res)
		) {
			res = coord
		}
	}
	return res
}

export function useSetMapBounds(
	map: WindyMapType | undefined,
	data:
		| {
				shipData: PlantInfo[]
				fishGeoJson: PointCollection
		  }
		| undefined
) {
	const [firstRender, setFirstRender] = useState<boolean>(false)
	const selectedVessel = useAppSelector(getSelectedVessel)
	const shipData = data?.shipData
	const fishGeoJson = data?.fishGeoJson

	useEffect(() => {
		if (shipData === undefined) {
			return
		}
		if (shipData.length > 0 && firstRender === false) {
			setFirstRender(true)
			if (fishGeoJson === undefined) {
				return
			}
			if (shipData.length > 0 || fishGeoJson.features.length > 0) {
				setBoundingBox(map, shipData, fishGeoJson)
			}
		}
	}, [shipData, fishGeoJson])

	useEffect(() => {
		if (selectedVessel.position !== undefined) {
			setVesselBoundingBox(map, selectedVessel)
		} else {
			if (shipData === undefined || fishGeoJson === undefined) {
				return
			}
			setBoundingBox(map, shipData, fishGeoJson)
		}
	}, [selectedVessel])

	useEffect(() => {
		try {
			if (shipData === undefined || fishGeoJson === undefined) {
				return
			}
			if (shipData.length > 0 || fishGeoJson.features.length > 0) {
				setBoundingBox(map, shipData, fishGeoJson)
			}
		} catch (err) {
			console.error(`useSetMapBounds crashed!`)
			console.log(`crash map`, map)
			console.log(`crash data.shipData`, shipData)
			console.log(`crash data.fishGeoJson`, fishGeoJson)
			throw err
		}
	}, [shipData?.length, fishGeoJson?.features.length])
}

export function useSetMapBoundsV2(
	map: RegularMapType | WindyMapType | undefined,
	data:
		| {
				shipData: PlantInfo[]
				trackData: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[] | undefined
		  }
		| undefined
) {
	const paramSelectedVessel = useSearch({ strict: false })?.vessel ?? ""
	const [firstRender, setFirstRender] = useState<boolean>(false)
	const [isVesselViewSet, setIsVesselViewSet] = useState<boolean>(false)

	const selectedVessel = useAppSelector(getSelectedVessel)
	// map?.resizemap()

	const shipData = data?.shipData
	useEffect(() => {
		if (shipData === undefined) {
			return
		}
		if (
			shipData.length > 0 &&
			firstRender === false &&
			paramSelectedVessel === "" &&
			map?.areLayersGenerated() === true
		) {
			setFirstRender(true)
			setBoundingBox(map, shipData, { type: "FeatureCollection", features: [] })
		}
	}, [shipData, map?.areLayersGenerated()])

	useEffect(() => {
		if (map?.areLayersGenerated() === false) {
			return
		}
		if (selectedVessel.position !== undefined && selectedVessel.name === paramSelectedVessel) {
			if (data?.trackData === undefined) {
				return
			}
			if (isVesselViewSet === false) {
				setBoundingBoxVesselCenter(map, [{ ...selectedVessel, owner: "", condition: "" }], {
					type: "FeatureCollection",
					features: data?.trackData ?? [],
				})
			}
			if (data?.trackData !== undefined && data?.trackData.length > 0) {
				setIsVesselViewSet(true)
			}
			// } else if (paramSelectedVessel !== "") {
		} else {
			if (shipData === undefined) {
				return
			}
			setIsVesselViewSet(false)
			setBoundingBox(map, shipData, {
				type: "FeatureCollection",
				features: [],
			})
		}
	}, [paramSelectedVessel, map?.areLayersGenerated(), data?.trackData])

	useEffect(() => {
		if (
			shipData === undefined ||
			shipData.length === 0 ||
			paramSelectedVessel !== undefined ||
			map?.areLayersGenerated() !== true
		) {
			return
		}
		setBoundingBox(map, shipData, { type: "FeatureCollection", features: [] })
	}, [shipData?.length, map?.areLayersGenerated()])
}

export function useSetMapBoundsV3(
	map: RegularMapType | WindyMapType | undefined,
	data:
		| {
				shipData: PlantInfo[]
				trackData: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[] | undefined
		  }
		| undefined
) {
	const paramSelectedVessel = useSearch({ strict: false })?.vessel
	const [firstRender, setFirstRender] = useState<boolean>(false)

	const shipData = data?.shipData
	const trackData = data?.trackData
	useEffect(() => {
		if (shipData === undefined || trackData === undefined) {
			return
		}
		if (shipData.length > 0 && firstRender === false && paramSelectedVessel !== undefined) {
			setFirstRender(true)
			setBoundingBox(map, shipData, { type: "FeatureCollection", features: trackData })
		}
	}, [shipData, trackData])

	useEffect(() => {
		if (shipData === undefined || trackData === undefined) {
			return
		}
		if (shipData.length > 0 && paramSelectedVessel !== undefined) {
			setBoundingBoxVesselCenter(map, shipData, {
				type: "FeatureCollection",
				features: trackData,
			})
		}
	}, [map, trackData?.[0]?.geometry.coordinates[0]])
}
