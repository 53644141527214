import { requestApi2 } from "@utils/http"
import { DbDeviceConfig, DeviceConfigPushArgs, endpoints } from "api"
import Button from "./Button"

export default function DeviceConfigPusher({ props }: { props: DbDeviceConfig }) {
	async function pushConfigToDevice() {
		if (confirm("Are you sure you want to push?") == true) {
			const argss: DeviceConfigPushArgs = {
				configId: props.id,
				deviceId: props.deviceId,
				timestamp: props.timestamp,
			}
			console.log(argss)
			try {
				await requestApi2(endpoints.deviceConfigPush, argss)
			} catch (e) {
				alert(e)
			}
		}
	}

	return <Button label="Push selected config to device" onClick={() => pushConfigToDevice()} />
}
