import { googleLogout } from "@react-oauth/google"
import { useAppDispatch } from "@redux/app/hooks"
import { clearAppStructure } from "@redux/features/appStructure/appStructureSlice"
import { setUserData } from "@redux/features/userData/userDataSlice"
import { useNavigate } from "@tanstack/react-router"
import { requestApi2 } from "@utils/http"
import { authEndpoints } from "api"
import { useTranslation } from "react-i18next"

export default function LogoutGoogle() {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const logOut = async () => {
		console.log("trying to logout from google...")
		try {
			googleLogout()
			await requestApi2(authEndpoints.logOut)
			dispatch(
				setUserData({
					name: "unknown",
					email: "unknown",
					isAuthenticated: false,
					profilePicture: "",
				})
			)
			dispatch(clearAppStructure())
			navigate({ to: "/" })
			console.log("Logout made successfully")
		} catch (error) {
			console.log("Logout failed")
			console.log("error: ", error)
		}
	}

	return (
		<button className="text-menu" onClick={() => logOut()}>
			{t("LOG OUT")}
		</button>
	)
}
