import * as AccordionPrimitive from "@radix-ui/react-accordion"
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from "react"

export const MapFilterAccordionContent = forwardRef<
	HTMLDivElement,
	DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>((props, ref) => {
	const { className, children, ...rest } = props
	return (
		<AccordionPrimitive.Content
			className={`data-[state=open]:animate-slide-accordion-down data-[state=closed]:animate-slide-accordion-up overflow-hidden bg-white`}
			{...rest}
			ref={ref}
		>
			{children}
		</AccordionPrimitive.Content>
	)
})
