import * as Tooltip from "@radix-ui/react-tooltip"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import { getAppStructure } from "@redux/features/appStructure/appStructureSlice"
import {
	OfflineVesselsList,
	setOfflineVessels,
} from "@redux/features/offlineVessels/offlineVesselsSlice"
import { useGetVesselsConnectionStatusQuery } from "@utils/queries/useGetVesselsConnectionStatusQuery"
import { PlantObject } from "api"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
	MdSignalWifiConnectedNoInternet0,
	MdSignalWifiStatusbarConnectedNoInternet,
} from "react-icons/md"

export type VesselConnectionStatusIconProps = {
	isAvailable: boolean
	lastTimestamp: number | null | undefined
	colorTwString?: string
}

export const VesselConnectionStatusIcon: FC<VesselConnectionStatusIconProps> = ({
	isAvailable,
	lastTimestamp,
	colorTwString,
}) => {
	// couldnt replace this component for the Tooltip from the ui-lib. There is an error with reference to the positioning element... or something along this line
	// it has something to do with the asChild property
	const { t } = useTranslation()
	const [openTooltip, setOpenTooltip] = useState<boolean>(false)
	// let hovering = false
	const [clickOutsidePending, setClickOutsidePending] = useState(false)

	return isAvailable === false && lastTimestamp !== undefined ? (
		<Tooltip.Provider>
			<Tooltip.Root delayDuration={50} open={openTooltip}>
				<Tooltip.Trigger
					// asChild
					onClick={(e) => {
						e.stopPropagation()
						if (!clickOutsidePending) {
							setOpenTooltip(!openTooltip)
						}
						setClickOutsidePending(false)
					}}
					onMouseEnter={() => {
						// hovering = true
						setTimeout(() => {
							// if (hovering) {
							setOpenTooltip(true)
							// }
						}, 200)
					}}
					onMouseLeave={() => {
						// hovering = false
						setTimeout(() => {
							setOpenTooltip(false)
						}, 200)
					}}
				>
					{lastTimestamp === null ? (
						<MdSignalWifiConnectedNoInternet0
							className={`h-5 w-5 cursor-help ${
								colorTwString !== undefined ? colorTwString : "text-amber-400"
							}`}
						/>
					) : (
						<MdSignalWifiStatusbarConnectedNoInternet
							className={`h-5 w-5 cursor-help ${
								colorTwString !== undefined ? colorTwString : "text-amber-400"
							}`}
						/>
					)}
				</Tooltip.Trigger>
				<Tooltip.Portal>
					<Tooltip.Content
						className={`z-10001 h-auto w-auto max-w-[400px] rounded-sm bg-dipai-secondary-800/85 px-2 py-1 text-white/100`}
						sideOffset={5}
						side={`right`}
						onPointerDownOutside={(e) => {
							setOpenTooltip(false)
							setClickOutsidePending(true)
						}}
						onEscapeKeyDown={() => setOpenTooltip(false)}
					>
						{lastTimestamp === null ? (
							<p>{t(`No data found for this vessel.`)}</p>
						) : (
							<>
								<p>{t(`Vessel is currently offline.`)}</p>{" "}
								<p>{t(`Last update: ${new Date(lastTimestamp).toString()}`)}</p>
							</>
						)}
						<Tooltip.Arrow className={`fill-dipai-secondary-800/85`} />
					</Tooltip.Content>
				</Tooltip.Portal>
			</Tooltip.Root>
		</Tooltip.Provider>
	) : null
}

export function useGetVesselsConnectionStatusData(
	customerPlants:
		| {
				[plantName: string]: PlantObject
		  }
		| undefined
) {
	const appStructure = useAppSelector(getAppStructure)
	const dispatch = useAppDispatch()

	const refSensorsForPlantAvailability: {
		plantName: string
		plantId: number
		sensorId: number | undefined
	}[] = []
	if (customerPlants !== undefined) {
		refSensorsForPlantAvailability.push(
			...Object.values(customerPlants).map((plantData) => {
				return {
					plantName: plantData.display_name,
					plantId: plantData.id,
					sensorId: plantData.assets
						.map(
							(asset) =>
								asset.sensors.find((sensor) =>
									sensor.display_name.toLowerCase().includes("vessel speed")
								)?.id
						)
						.filter((sensorId) => sensorId !== undefined)?.[0],
				}
			})
		)
	}

	const {
		isLoading: isVesselsConnectionStatusDataLoading,
		isError: isVesselsConnectionStatusDataError,
		data: vesselsConnectionStatusData,
	} = useGetVesselsConnectionStatusQuery({
		isDipaiUser: true, //appStructure !== null && Object.keys(appStructure)[0] === "Dipai" ? true : false,
		plantIds:
			customerPlants === undefined ? [] : Object.values(customerPlants).map((p) => p.id),
	})

	useEffect(() => {
		if (vesselsConnectionStatusData === undefined || vesselsConnectionStatusData === null) {
			return
		}
		const offlineVesselsTemp: OfflineVesselsList = []
		for (const vesselConnectionData of vesselsConnectionStatusData) {
			const lastTimestamp = vesselConnectionData.latestTimestamp
			if (
				lastTimestamp === null ||
				lastTimestamp.getTime() <
					new Date(new Date().getTime() - 6 * 60 * 60 * 1000).getTime()
			) {
				const plantId = vesselConnectionData.plantId
				const plantName = refSensorsForPlantAvailability.find(
					(plantData) => plantData.plantId === plantId
				)?.plantName
				if (plantId !== undefined && plantName !== undefined) {
					offlineVesselsTemp.push({
						name: plantName,
						id: plantId,
						lastUpdateTimestamp:
							lastTimestamp === null ? lastTimestamp : lastTimestamp.getTime(),
					})
				}
			}
		}

		refSensorsForPlantAvailability.forEach((refData) => {
			const isVesselInList = vesselsConnectionStatusData.find(
				(o) => o.plantId === refData.plantId
			)
			if (isVesselInList === undefined) {
				offlineVesselsTemp.push({
					name: refData.plantName,
					id: refData.plantId,
					lastUpdateTimestamp: null,
				})
			}
		})
		dispatch(setOfflineVessels(offlineVesselsTemp))
	}, [vesselsConnectionStatusData])
}
