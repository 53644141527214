import { FishFarmBarentsWatchProperties } from "@components/map/mapComponents/createFishFarmBarentsWatchTooltip"
import { PointCollection, PointFeature } from "@components/map/utils/utils"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import { getCurrentCompany } from "@redux/features/currentCompany/currentCompanySlice"
import { setFilteredListOfFishFarms } from "@redux/features/filteredListOfFishFarms/filteredListOfFishFarmsSlice"
import { getSelectedFishFarmOwner } from "@redux/features/fishFarmOwners/fishFarmOwnersSlice"
import { getMapFilters } from "@redux/features/mapFilters/mapFiltersSlice"
import useFishFarmDataQuery from "@utils/queries/useFishFarmDataQuery"
import { DateTime } from "luxon"
import { useEffect, useState } from "react"

export enum LiceThresholdColors {
	null = "#666666",
	ok = "#035efc",
	notOk = "#fc2003",
}

const INITIAL_LICE_THRESHOLD: number = 0.45

export default function useGetFishFarmDataForMap(
	searchString: string | null
): [PointCollection, PointCollection, boolean, (fishGeoJson: PointCollection) => void] {
	const dispatch = useAppDispatch()

	const selectedFishFarmOwner = useAppSelector(getSelectedFishFarmOwner)
	const companyName = useAppSelector(getCurrentCompany)
	const dt = DateTime.now()
	const week = dt.weekNumber
	const year = dt.year

	const postDataFishFarms = { year, week, orgNo: selectedFishFarmOwner.orgNo }

	const {
		isLoading: isFishFarmDataLoading,
		isError: isFishFarmDataError,
		data: fishFarmDataTemp,
	} = useFishFarmDataQuery({ postData: postDataFishFarms, companyName })

	useEffect(() => {
		const processFishFarmData = () => {
			if (fishFarmDataTemp === undefined || isFishFarmDataError === true) {
				return
			}

			const allFeatures: PointFeature[] = []
			for (const locality of fishFarmDataTemp.localities) {
				const avgAdultFemaleLice = locality.avgAdultFemaleLice
				const color =
					avgAdultFemaleLice === null
						? LiceThresholdColors.null
						: avgAdultFemaleLice > INITIAL_LICE_THRESHOLD
							? LiceThresholdColors.notOk
							: LiceThresholdColors.ok

				const feature: PointFeature = {
					type: "Feature",
					properties: {
						color: color,
						siteName: locality.name,
						municipalityName: locality.municipality,
						avgAdultFemaleLice: avgAdultFemaleLice,
						probablyNoFish: locality.isFallow,
						isOnLand: locality.isOnLand,
						isSlaughterHoldingCage: locality.isSlaughterHoldingCage,
						hasSalmonoids: locality.hasSalmonoids,
						noNeedToReport:
							locality.isOnLand === true ||
							locality.isSlaughterHoldingCage === true ||
							locality.hasSalmonoids === false,
					},
					geometry: {
						type: "Point",
						coordinates: [locality.lon, locality.lat],
					},
				}
				allFeatures.push(feature)
			}

			const fGeoJson: PointCollection = {
				type: "FeatureCollection",
				features: allFeatures,
			}

			setFishGeoJson(fGeoJson)
		}

		processFishFarmData()
	}, [fishFarmDataTemp])

	const [fishGeoJson, setFishGeoJson] = useState<PointCollection>({
		type: "FeatureCollection",
		features: [],
	})

	const [mapParametersFilteredFishGeoJson, setMapParametersFilteredFishGeoJson] =
		useState<PointCollection>(fishGeoJson)

	const [textFilteredFishGeoJson, setTextFilteredFishGeoJson] =
		useState<PointCollection>(fishGeoJson)

	const mapFilters = useAppSelector(getMapFilters)

	const filterByThresholdStatus = (
		pointColletion: PointCollection,
		statusColor: LiceThresholdColors,
		keepData: boolean
	) => {
		pointColletion.features = pointColletion.features.filter((feature: PointFeature) =>
			feature.properties !== undefined && feature.properties!.color === statusColor
				? keepData
				: !keepData
		)
	}

	useEffect(() => {
		const fishGeoJsonCopy: PointCollection = JSON.parse(JSON.stringify(fishGeoJson)) //making deep copy to ensure state update works properly
		if (mapFilters.showProductionSites !== true) {
			fishGeoJsonCopy.features = []
		} else {
			for (const feature of fishGeoJsonCopy.features) {
				const properties = feature.properties as FishFarmBarentsWatchProperties
				if (properties?.color !== undefined) {
					properties.color =
						typeof properties.avgAdultFemaleLice != "number"
							? LiceThresholdColors.null
							: properties.avgAdultFemaleLice > mapFilters.liceThreshold.threshold
								? LiceThresholdColors.notOk
								: LiceThresholdColors.ok
				}
			}

			if (mapFilters.reportingStatus === "2") {
				filterByThresholdStatus(fishGeoJsonCopy, LiceThresholdColors.null, true)
			} else if (mapFilters.reportingStatus === "3") {
				filterByThresholdStatus(fishGeoJsonCopy, LiceThresholdColors.null, true)
				fishGeoJsonCopy.features = fishGeoJsonCopy.features.filter(
					(feature: PointFeature) =>
						feature.properties !== undefined &&
						feature.properties!.noNeedToReport === false
				)
				fishGeoJsonCopy.features = fishGeoJsonCopy.features.filter(
					(feature: PointFeature) =>
						feature.properties !== undefined &&
						feature.properties!.probablyNoFish === true
				)
			} else if (mapFilters.reportingStatus === "4") {
				fishGeoJsonCopy.features = fishGeoJsonCopy.features.filter(
					(feature: PointFeature) =>
						feature.properties !== undefined &&
						feature.properties!.noNeedToReport === true
				)
			} else if (mapFilters.reportingStatus === "5") {
				filterByThresholdStatus(fishGeoJsonCopy, LiceThresholdColors.null, true)
				fishGeoJsonCopy.features = fishGeoJsonCopy.features.filter(
					(feature: PointFeature) =>
						feature.properties !== undefined &&
						feature.properties!.noNeedToReport === false
				)
				fishGeoJsonCopy.features = fishGeoJsonCopy.features.filter(
					(feature: PointFeature) =>
						feature.properties !== undefined &&
						feature.properties!.probablyNoFish === false
				)
			} else {
				if (mapFilters.reportingStatus === "1") {
					filterByThresholdStatus(fishGeoJsonCopy, LiceThresholdColors.null, false)
				}

				if (mapFilters.liceThreshold.showSitesBelowThreshold === false) {
					filterByThresholdStatus(fishGeoJsonCopy, LiceThresholdColors.ok, false)
				}

				if (mapFilters.liceThreshold.showSitesAboveThreshold === false) {
					filterByThresholdStatus(fishGeoJsonCopy, LiceThresholdColors.notOk, false)
				}
			}
		}

		setMapParametersFilteredFishGeoJson(fishGeoJsonCopy)
		searchString !== null
			? handleSetTextFilteredFishGeoJson({
					type: fishGeoJson.type,
					features: fishGeoJsonCopy.features.filter(
						(feature: PointFeature) =>
							feature.properties !== undefined &&
							((feature.properties as FishFarmBarentsWatchProperties).siteName
								.toLowerCase()
								.includes(searchString.toLowerCase()) === true ||
								(
									feature.properties as FishFarmBarentsWatchProperties
								).municipalityName
									.toLowerCase()
									.includes(searchString.toLowerCase()) === true)
					),
				})
			: handleSetTextFilteredFishGeoJson(fishGeoJsonCopy)
	}, [mapFilters, fishGeoJson])

	const handleSetTextFilteredFishGeoJson = (fishGeoJson: PointCollection) => {
		setTextFilteredFishGeoJson(fishGeoJson)
		dispatch(setFilteredListOfFishFarms(fishGeoJson))
	}

	return [
		textFilteredFishGeoJson,
		mapParametersFilteredFishGeoJson,
		isFishFarmDataLoading,
		handleSetTextFilteredFishGeoJson,
	]
}
