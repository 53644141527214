export type OGFacility = {
	datesyncNPD: string
	fclBelongsToKind: string
	fclBelongsToName: string
	fclBelongsToS: string
	fclCurrentOperatorName: string
	fclDateUpdated: string
	fclDesignLifetime: string
	fclEwCode: string
	fclEwDeg: string
	fclEwMin: string
	fclEwSec: string
	fclFactMapUrl: string
	fclFactPageUrl: string
	fclFunctions: string
	fclGeodeticDatum: string
	fclKind: string
	fclName: string
	fclNpdidFacility: string
	fclNsCode: string
	fclNsDeg: string
	fclNsMin: string
	fclNsSec: string
	fclPhase: string
	fclStartupDate: string
	fclSurface: string
	fclWaterDepth: string
}

export const createOilAndGasFacilityPopup = (properties: OGFacility) => {
	const textRowDark = "grid grid-cols-2 p-1 bg-slate-200"
	const textRowLight = "grid grid-cols-2 p-1 bg-slate-100"

	// Title line
	const pTitle = L.DomUtil.create("p", "mb-2 border-b-2 border-gray-300 text-body font-semibold")
	pTitle.textContent = `${properties.fclName} (id: ${
		properties.fclNpdidFacility
	}) - Phase: ${properties.fclPhase.toLocaleLowerCase()}`

	// Type line
	const typeDiv = L.DomUtil.create("div", textRowDark)
	const pType = L.DomUtil.create("p", "whitespace-normal")
	pType.textContent = `Type: ${
		properties.fclKind !== "" ? properties.fclKind.toLocaleLowerCase() : "--"
	}`
	const pFunc = L.DomUtil.create("p", "whitespace-normal capitalize")
	pFunc.textContent = `Function: ${
		properties.fclFunctions !== "" ? properties.fclFunctions : "--"
	}`
	typeDiv.appendChild(pType)
	typeDiv.appendChild(pFunc)

	// Surface line
	const surfaceDiv = L.DomUtil.create("div", textRowLight)
	const pSurface = L.DomUtil.create("p", "")
	const pWaterDepth = L.DomUtil.create("p", "")
	pSurface.textContent = `Is surface facility: ${
		properties.fclSurface !== "" ? (properties.fclSurface === "N" ? "No" : "Yes") : "--"
	}`
	pWaterDepth.textContent = `Water Depth: ${
		properties.fclWaterDepth !== "" ? properties.fclWaterDepth : "--"
	} m`
	surfaceDiv.appendChild(pSurface)
	surfaceDiv.appendChild(pWaterDepth)

	// Lifetime line
	const lifetimeDiv = L.DomUtil.create("div", textRowDark)
	const pStartup = L.DomUtil.create("p", "")
	const pLifetime = L.DomUtil.create("p", "")
	pStartup.textContent = `Start up date: ${
		properties.fclStartupDate !== "" ? properties.fclStartupDate : "--"
	}`
	pLifetime.textContent = `Design lifetime: ${
		properties.fclDesignLifetime !== "" ? properties.fclDesignLifetime : "--"
	} years`
	lifetimeDiv.appendChild(pStartup)
	lifetimeDiv.appendChild(pLifetime)

	// Owner line
	const ownerDiv = L.DomUtil.create("div", textRowLight)
	const pBelongs = L.DomUtil.create("p", "capitalize whitespace-normal")
	const pOperator = L.DomUtil.create("p", "capitalize whitespace-normal")
	pBelongs.textContent = `Belongs to: ${
		properties.fclBelongsToName !== "" ? properties.fclBelongsToName.toLowerCase() : "--"
	}`
	pOperator.textContent = `Current operator: ${
		properties.fclCurrentOperatorName !== ""
			? properties.fclCurrentOperatorName.toLowerCase()
			: "--"
	}`
	ownerDiv.appendChild(pBelongs)
	ownerDiv.appendChild(pOperator)

	// Compose final div
	const mainDiv = L.DomUtil.create("div", "grid w-[500px] grid-rows-[auto] text-menu")
	mainDiv.appendChild(pTitle)
	mainDiv.appendChild(typeDiv)
	mainDiv.appendChild(surfaceDiv)
	mainDiv.appendChild(lifetimeDiv)
	mainDiv.appendChild(ownerDiv)

	return mainDiv
}
