import { CellSetterV1 } from "@pages/HomePage/BodyContentLayout"
import { ColumnDef } from "@tanstack/react-table"
import { PlantInfo } from "api"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

export type HomePageTableItemST = {
	conditionLabel: string
	itemName: string
	fishCount: number | string
	operationMode: string[]
	openState: string
	details: PlantInfo
}
type InferAccessorType<T extends keyof HomePageTableItemST> = HomePageTableItemST[T]

export function useOpModeTableColumns(listOfEventNames: string[]) {
	const { t } = useTranslation()
	const columns = useMemo<ColumnDef<HomePageTableItemST>[]>(
		() => [
			{
				accessorKey: "conditionLabel",
				header: () => <div className={`flex w-full justify-start text-left`} />,
				cell: (info) => {
					return CellSetterV1({
						content: {
							type: "condition-label",
							condition: info.getValue() as InferAccessorType<"conditionLabel">,
						},
						index: info.cell.id,
					})
				},
			},
			{
				accessorKey: "itemName",
				header: () => (
					<div className={`flex w-full justify-start text-left`}>{t("VESSEL")}</div>
				),
				cell: (info) => {
					return CellSetterV1({
						content: {
							type: "text",
							text: info.getValue() as InferAccessorType<"itemName">,
						},
						index: info.cell.id,
					})
				},
			},
			{
				accessorKey: "fishCount",
				header: () => (
					<div className={`flex w-full justify-start text-left`}>{t("FISH")}</div>
				),
				cell: (info) => {
					return CellSetterV1({
						content: {
							type: "number",
							value: info.getValue() as InferAccessorType<"fishCount">,
						},
						index: info.cell.id,
					})
				},
			},
			{
				accessorKey: "operationMode",
				header: () => (
					<div className={`flex w-full justify-start text-left`}>
						{t("OPERATION MODE")}
					</div>
				),
				cell: (info) => {
					return CellSetterV1({
						content: {
							type: "text-rows",
							rows: info.getValue() as InferAccessorType<"operationMode">,
						},
						index: info.cell.id,
					})
				},
			},
			{
				accessorKey: "openState",
				header: () => (
					<div className={`flex w-full justify-start text-left`}>{t("OPEN STATE")}</div>
				),
				cell: (info) => {
					return CellSetterV1({
						content: {
							type: "text",
							text: info.getValue() as InferAccessorType<"openState">,
						},
						index: info.cell.id,
					})
				},
			},
			{
				accessorKey: "details",
				header: () => <div className={`flex w-full justify-start text-left`} />,
				cell: (info) => {
					return CellSetterV1({
						content: {
							type: "details",
							plantInfo: info.getValue() as InferAccessorType<"details">,
						},
						index: info.cell.id,
					})
				},
			},
		],
		[t]
	)
	return columns
}
