import { OilAndGasFieldType } from "@assets/oilAndGasData/fldArea"
import { DataSetter, mapLayersColors } from "../utils/mapLayers"
import { LayerOptionsWithMetadata } from "../windyMap/windyMap"

function extractLatLngs(coords: (number[] | number[][] | number[][][])[]) {
	return coords.map((subArray) => {
		if (
			Array.isArray(subArray) &&
			subArray.length > 0 &&
			Array.isArray(subArray[0]) === false
		) {
			return L.latLng([Number(subArray[0]), Number(subArray[1])])
		}
		return extractLatLngs(subArray as number[][] | number[][][])
	})
}

export const addOilAndGasFields: DataSetter = (
	dispatch,
	queryClient,
	layerGroup,
	layerData,
	navigate,
	map
) => {
	if (layerGroup === undefined) return

	const { oilAndGasFieldsData } = layerData
	if (oilAndGasFieldsData === undefined) return
	layerGroup.clearLayers()
	// const canvasRenderer = L.canvas({ padding: 0.5, pane: "oilAndGasFieldsPane" })
	for (const field of oilAndGasFieldsData) {
		const polyline = L.polyline(extractLatLngs(field.fldAreaGeometryWKT), {
			// renderer: canvasRenderer,
			stroke: false,
			fill: true,
			fillColor:
				field.fldHcType === "" ||
				(OilAndGasFieldType[field.fldHcType] as OilAndGasFieldType[number]) === undefined
					? "gray"
					: mapLayersColors.oilAndGasFields[
							OilAndGasFieldType[field.fldHcType] as OilAndGasFieldType[number]
						],
			fillOpacity: 0.75,
			pane: "oilAndGasFieldsPane",
			smoothFactor: 1,
			noClip: true,
			metadata: {
				oilAndGasField: {
					id: field.fldNpdidField,
					name: field.fldName,
					type: field.fldHcType !== "" ? field.fldHcType : "--",
					location: field.fldMainArea !== "" ? field.fldMainArea : "--",
					operator: field.cmpLongName !== "" ? field.cmpLongName : "--",
				},
			},
		} as unknown as LayerOptionsWithMetadata)

		layerGroup.addLayer(polyline)
	}
}
