import { getFileName } from "@helpers/getFileName"
import { requestApi2 } from "@utils/http"
import { Facility, GetAllFacilitiesArgs, endpoints } from "api"
import { useQuery } from "react-query"

export function useGetFacilitiesRadiusQuery(args: {
	isFacilitiesFeatureAvailable: boolean
	types: GetAllFacilitiesArgs["types"]
}) {
	const res = useQuery(
		[
			`source=${getFileName()}`,
			`queryId=GetFacilitiesRadius`,
			`endpoints=[${endpoints.getFacilities.name}]`,
			`facilityTypes=${args.types}`,
			`isFacilitiesFeatureAvailable=${args.isFacilitiesFeatureAvailable}`,
		],
		async (): Promise<Facility[] | undefined | null> => {
			if (args.isFacilitiesFeatureAvailable === false) {
				return []
			}
			const facilities = await requestApi2(endpoints.getFacilities, { types: args.types })
			return facilities === null
				? null
				: facilities.sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
		},
		{
			staleTime: 60 * 60 * 1000,
			refetchInterval: 60 * 60 * 1000,
		}
	)
	return res
}
