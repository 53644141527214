import IconSvgContainer, { IconProps, IconSize } from "../IconSvgContainer"

export default function ClearIcon({ size = IconSize.medium }: IconProps) {
	return (
		<IconSvgContainer size={size}>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
				d="M4 20L20 4M4 4L20 20"
			/>
		</IconSvgContainer>
	)
}
