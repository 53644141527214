import {
	createFishFarmBarentsWatchTooltip,
	FishFarmBarentsWatchProperties,
} from "@components/map/mapComponents/createFishFarmBarentsWatchTooltip"
import { DataSetter } from "../utils/mapLayers"

export const addFishFarms: DataSetter = (
	dispatch,
	queryClient,
	layerGroup,
	layerData,
	navigate,
	map
) => {
	if (layerGroup === undefined) return

	const { fishFarmsData } = layerData
	if (fishFarmsData === undefined) return

	layerGroup.clearLayers()

	for (const fishFarm of fishFarmsData.features) {
		const coord1 = fishFarm.geometry.coordinates[1]
		const coord2 = fishFarm.geometry.coordinates[0]
		if (
			coord1 === undefined ||
			coord2 === undefined ||
			isNaN(coord1) === true ||
			isNaN(coord2) === true
		) {
			continue
		}
		layerGroup.addLayer(
			L.circleMarker([coord1, coord2], {
				color: "#ffffff",
				fillColor: fishFarm.properties!.color,
				fillOpacity: 0.5,
				weight: 1,
				radius: 5,
				pane: "fishFarmsPane",
			}).bindTooltip(
				createFishFarmBarentsWatchTooltip(
					fishFarm.properties as FishFarmBarentsWatchProperties
				)
			)
		)
	}
}
