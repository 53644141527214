import { useAppSelector } from "@redux/app/hooks"
import { getAppStructure } from "@redux/features/appStructure/appStructureSlice"
import { Button as ButtonLib, IconButton } from "@ui-lib/src"
import { AllSensorsForAnAsset } from "@utilityTypes/timeseriesDefs"
import { COLORS_PLOTS_COLORBLIND } from "@utils/colorsPlots"
import { common } from "@utils/common"
import { getSensorsByAsset } from "@utils/getSensorsByAsset"
import { requestApi2 } from "@utils/http"
import { PlantPlot, PlantPlotSignal, PlotType, endpoints } from "api"
import { ReactElement, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdClose } from "react-icons/md"
import SensorListLineGeneric from "./SensorListLine"
import {
	PlotConfigReducerAction,
	PlotConfigReducerActionTypes,
	usePlotConfigReducer,
} from "./usePlotConfigReducer"

export type SensorsListValueLabelPair = { value: number; label: string }

export type PlotConfigProps = {
	addNewPlot?: boolean
	columnsLayout: string
	companyName: string
	componentName: string
	configType: "Plot" | "CurrentData"
	emptyLineTemplate: PlantPlotSignal
	fetchPlantPlots?: () => Promise<void>
	deletePlotTemp?: () => void
	handleConfigOpen: () => void
	numberSignals: number
	plantName: string
	plotData: PlantPlot
	sensorsGroupId?: number | null
	setPlotData?: (newPlotData: PlantPlot) => void
	// setPlotName?: (newPlotName: string | undefined, plotId: number) => void
}

export default function PlotConfig({
	addNewPlot = false,
	columnsLayout,
	companyName,
	componentName,
	configType,
	emptyLineTemplate,
	fetchPlantPlots,
	deletePlotTemp,
	handleConfigOpen,
	numberSignals,
	plantName,
	plotData,
	sensorsGroupId = null,
	setPlotData,
}: // setPlotName,
PlotConfigProps): ReactElement {
	const { t } = useTranslation()
	const appStruct = useAppSelector(getAppStructure)
	const [plotConfigState, plotConfigDispatch] = usePlotConfigReducer(
		plotData !== undefined ? plotData : { name: null, id: -1, signals: [] }
	)
	const [sensorListTable, setSensorListTable] = useState<ReactElement[]>([])
	const [listOfSensors, setListOfSensors] = useState<AllSensorsForAnAsset>()
	// const [plotNameState, setPlotNameState] = useState<string>()
	const didMount = useRef(false)

	useEffect(() => {
		const fetchData = async (componentSensorsList: AllSensorsForAnAsset) => {
			const sensorListTableCopy: ReactElement[] = []
			const sensorsListValueLabelPairs: SensorsListValueLabelPair[] = []
			for (const componentSensor of componentSensorsList.sensors) {
				sensorsListValueLabelPairs.push({
					value: componentSensor.id,
					label: componentSensor.display_name,
				})
			}
			for (const signal of plotConfigState.plotInfo.signals) {
				sensorListTableCopy.push(
					<SensorListLineGeneric
						key={`sensorLine_${signal.color}_${signal.sensorId}_${
							signal.id
						}_${Math.random()}`}
						columnsLayout={columnsLayout}
						componentData={componentSensorsList}
						dispatch={plotConfigDispatch}
						signal={signal}
						plotData={plotConfigState.plotInfo}
						thresholdOpenId={plotConfigState.thresholdOpenId}
					/>
				)
			}

			if (sensorListTableCopy.length < numberSignals) {
				const colorsUsed: string[] = []
				if (plotConfigState.plotInfo.signals.length > 0) {
					for (const signal of plotConfigState.plotInfo.signals) {
						colorsUsed.push(signal.color)
					}
				}
				let color
				for (let i = 0; i < COLORS_PLOTS_COLORBLIND.length; i++) {
					if (colorsUsed.length > 0) {
						for (let f = 0; f < colorsUsed.length; f++) {
							if (COLORS_PLOTS_COLORBLIND[i] === colorsUsed[f]) {
								break
							}
							if (f === colorsUsed.length - 1) {
								color = COLORS_PLOTS_COLORBLIND[i]
								i = COLORS_PLOTS_COLORBLIND.length
								break
							}
						}
					} else {
						color = COLORS_PLOTS_COLORBLIND[i]
						i = COLORS_PLOTS_COLORBLIND.length
						break
					}
				}
				// ADD DYNAMIC PROPS TO EMPTY LINE TEMPLATE
				emptyLineTemplate.id = -1
				emptyLineTemplate.color = color ?? "#1111FF"

				sensorListTableCopy.push(
					<SensorListLineGeneric
						key={`sensorLine_${Math.random()}`}
						columnsLayout={columnsLayout}
						componentData={componentSensorsList}
						dispatch={plotConfigDispatch}
						plotData={plotConfigState.plotInfo}
						isNewLine={true}
						signal={emptyLineTemplate}
					/>
				)
			}
			setSensorListTable(sensorListTableCopy)
		}

		const fetchListOfSensors = (plantName: string, assetName: string) => {
			if (appStruct !== null) {
				const listOfSensorsTemp = getSensorsByAsset(
					appStruct,
					companyName,
					plantName,
					assetName !== "All Assets" ? assetName : undefined,
					sensorsGroupId !== null ? sensorsGroupId : undefined
				)
				setListOfSensors(listOfSensorsTemp)
			}
		}

		if (didMount.current === true && listOfSensors !== undefined) {
			fetchData(listOfSensors)
		} else {
			didMount.current = true
			fetchListOfSensors(plantName, componentName)
		}
	}, [listOfSensors, plotConfigState])

	const handleDelete = async () => {
		try {
			if (window.confirm(t("ARE YOU SURE YOU WANT TO DELETE THIS PLOT"))) {
				if (fetchPlantPlots !== undefined) {
					try {
						await requestApi2(endpoints.plantPlotRemove, {
							id: plotConfigState.plotInfo.id,
						})

						fetchPlantPlots()
						handleConfigOpen()
					} catch (err) {
						throw err
					}
				} else if (setPlotData !== undefined) {
					if (deletePlotTemp !== undefined) {
						deletePlotTemp()
					}
					handleConfigOpen()
				}
			}
		} catch (err) {
			throw err
		}
	}

	const handleUpdate = async () => {
		if (fetchPlantPlots !== undefined) {
			const signals: PlantPlotSignal[] = []
			for (const signal of plotConfigState.plotInfo.signals) {
				signals.push({
					sensorId: signal.sensorId,
					aggregationMethod: signal.aggregationMethod,
					color: signal.color,
					id: signal.id,
					thresholds: signal.thresholds,
					baselines: signal.baselines,
				})
			}

			try {
				await requestApi2(endpoints.plantPlotUpdate, {
					id: plotConfigState.plotInfo.id,
					signals: signals,
					name: plotConfigState.plotInfo.name,
				})

				fetchPlantPlots()
				const action: PlotConfigReducerAction = {
					type: PlotConfigReducerActionTypes.RESET_UPDATED,
				}
				plotConfigDispatch(action)
				handleConfigOpen()
			} catch (err) {
				// console.log(err)
			}
		} else if (setPlotData !== undefined) {
			// if (setPlotName !== undefined) {
			// 	let tmp = plotConfigState.plotInfo.id
			// 	setPlotName(plotNameState, tmp)
			// }
			setPlotData(plotConfigState.plotInfo)
			const action: PlotConfigReducerAction = {
				type: PlotConfigReducerActionTypes.RESET_UPDATED,
			}
			plotConfigDispatch(action)
			handleConfigOpen()
		}
	}

	const handleAdd = async () => {
		if (fetchPlantPlots !== undefined) {
			const plantData = common.plantDataFromOwnerAndName.get(
				`${companyName?.toLowerCase()}/${plantName?.toLowerCase()}`
			)
			if (plantData === undefined) {
				return
			}
			const plantId = plantData.id
			const assetData =
				componentName !== "All Assets"
					? plantData.assets.find((asset) => asset.display_name === componentName)
					: { id: null }
			if (assetData === undefined) {
				return
			}
			const componentId = assetData.id

			if (configType === "Plot") {
				const signals: PlantPlotSignal[] = []

				for (const signal of plotConfigState.plotInfo.signals) {
					if (signal.sensorId !== undefined) {
						const temp: PlantPlotSignal = {
							id: signal.id,
							aggregationMethod: signal.aggregationMethod,
							baselines: signal.baselines,
							color: signal.color,
							thresholds: signal.thresholds,
							sensorId: signal.sensorId,
						}
						signals.push(temp)
					}
				}

				try {
					if (plantId !== undefined && componentId !== undefined) {
						await requestApi2(endpoints.plantPlotAdd, {
							type: PlotType.PLOT,
							name:
								plotConfigState.plotInfo.name !== null
									? plotConfigState.plotInfo.name
									: "",
							plantId: plantId,
							assetId: componentId,
							signals: signals,
							groupId: sensorsGroupId,
						})
					}
					fetchPlantPlots()
					handleConfigOpen()
				} catch (err) {
					console.log(err)
				}
			} else {
				const signals: PlantPlotSignal[] = []

				for (const signal of plotConfigState.plotInfo.signals) {
					if (signal.sensorId !== undefined) {
						const temp: PlantPlotSignal = {
							id: signal.id,
							baselines: signal.baselines,
							aggregationMethod: signal.aggregationMethod,
							color: signal.color,
							thresholds: signal.thresholds,
							sensorId: signal.sensorId,
						}
						signals.push(temp)
					}
				}

				try {
					if (plantId !== undefined && componentId !== undefined) {
						await requestApi2(endpoints.plantPlotAdd, {
							type: PlotType.CARD,
							plantId: plantId,
							name: plotConfigState.plotInfo.name,
							assetId: componentId,
							signals: signals,
							groupId: sensorsGroupId,
						})
					}
					fetchPlantPlots()
					handleConfigOpen()
				} catch (err) {
					console.log(err)
				}
			}
		} else if (setPlotData !== undefined) {
			setPlotData({
				...plotConfigState.plotInfo,
				name:
					plotConfigState.plotInfo.name !== null
						? plotConfigState.plotInfo.name
						: plotConfigState.plotInfo.signals?.[0]?.sensorId !== undefined
						? common.sensorsById.get(
								plotConfigState.plotInfo.signals?.[0]?.sensorId
						  )?.[0]?.display_name ?? "Unnamed plot"
						: "Unnamed plot",
			})
			handleConfigOpen()
		}
	}

	const mainNav = document.getElementById("mainNav")
	const secondaryNav = document.getElementById("secondaryNav")

	return (
		<div
			id="EditPlot"
			className="fixed flex h-[540px] w-[897px] flex-col gap-4 rounded-sm bg-white px-4 py-4"
			style={{
				top: `calc(50% - 290px)`,
				left: `calc(50vw - 447.5px${
					mainNav !== null ? ` + ${mainNav.offsetWidth / 2}px` : ``
				}${secondaryNav !== null ? ` + ${secondaryNav.offsetWidth / 2}px` : ``})`,
			}}
		>
			{/* HEADER */}
			<div className="flex flex-row justify-between border-b-0">
				<div className="flex flex-row items-center align-middle">
					<p className={`pr-4 text-title4 font-semibold`}>
						{addNewPlot === true ? t("CREATE PLOT") : t("EDIT PLOT")}
					</p>
					<label htmlFor="nameInput" className={`pr-2 text-menu`}>{`${t(
						"NAME"
					)}:`}</label>
					<input
						id={"nameInput"}
						key={`plotNameInput`}
						className={`h-8 w-[321px] rounded-sm border-1 border-gray-ccc pl-[5px] hover:border-dipai-secondary-500 focus:border-2 focus:border-dipai-secondary-500 focus:pl-[4px] focus:outline-hidden focus:ring-0`}
						defaultValue={
							plotConfigState.plotInfo.name !== null
								? plotConfigState.plotInfo.name
								: ""
						}
						onChange={(e) =>
							plotConfigDispatch({
								type: PlotConfigReducerActionTypes.UPDATE_PLOTNAME,
								payload: { ...plotConfigState, name: e.target.value },
							} as unknown as PlotConfigReducerAction)
						}
					/>
				</div>
				<IconButton size={"medium"} variant={"text-dark"} onClick={handleConfigOpen}>
					<MdClose className={`h-6 w-6`} />
				</IconButton>
			</div>
			{/* BODY */}
			<div className="h-[436px] border-1 p-4">
				<div className="grid gap-6 mb-0" style={{ gridTemplateColumns: columnsLayout }}>
					<div />
					<p className="text-button uppercase tracking-[1px] text-gray-666">
						{t("SENSOR NAME")}
					</p>
					{/* <p className="text-button uppercase tracking-[1px] text-gray-666">
						{t("AGGREGATION")}
					</p> */}
					{emptyLineTemplate.hasOwnProperty("baselines") && (
						<p className="text-button uppercase tracking-[1px] text-gray-666">
							{t("BASELINES")}
						</p>
					)}
					<div />
					<div />
				</div>
				<div className="h-[340px] overflow-auto">{sensorListTable}</div>
			</div>
			{/* FOOTER */}
			<div className="flex flex-row justify-end gap-4 border-t-0">
				{addNewPlot !== true && (
					<ButtonLib
						variant={"outline-dark"}
						className={"w-[140px]"}
						onClick={() => handleDelete()}
					>
						<ButtonLib.Label>{t("DELETE")}</ButtonLib.Label>
					</ButtonLib>
				)}
				<ButtonLib
					variant={"outline-dark"}
					className={"w-[140px]"}
					onClick={() => handleConfigOpen()}
				>
					<ButtonLib.Label>{t("CANCEL")}</ButtonLib.Label>
				</ButtonLib>
				<ButtonLib
					disabled={
						plotConfigState.updated !== true ||
						plotConfigState.plotInfo.signals.length < 1
					}
					variant={"filled-secondary"}
					className={"w-[140px]"}
					onClick={addNewPlot === true ? () => handleAdd() : () => handleUpdate()}
				>
					<ButtonLib.Label>{t("UPDATE")}</ButtonLib.Label>
				</ButtonLib>
			</div>
		</div>
	)
}
