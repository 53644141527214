import { useAppSelector } from "@redux/app/hooks"
import { getAppStructure } from "@redux/features/appStructure/appStructureSlice"
import { getCurrentCompany } from "@redux/features/currentCompany/currentCompanySlice"
import { PortalMode, getUserData } from "@redux/features/userData/userDataSlice"
import { ModuleMetadata } from "@ui-lib/src"
import { common } from "@utils/common"
import { PlantObject, PortalFeature } from "api"
import { ReactElement } from "react"

type FeatureGuardProps = {
	plant?: PlantObject
	featureName: PortalFeature
	children: ReactElement
}

export const useGetDevFeatures = (): string[] => {
	const appStructure = useAppSelector(getAppStructure)
	if (appStructure === null) {
		return []
	}
	const companyName = Object.keys(appStructure)?.[0]
	if (
		companyName === undefined ||
		companyName !== "Dipai" ||
		appStructure[companyName] === undefined
	) {
		return []
	}
	return []
	// return appStructure[companyName]?.features ?? []
}

export default function FeatureGuard(props: FeatureGuardProps): ReactElement | null {
	const companyName = useAppSelector(getCurrentCompany)
	const userData = useAppSelector(getUserData)
	const devFeatures = useGetDevFeatures()

	if (companyName === undefined) {
		return null
	}
	const featuresList: string[] = []
	const plantFeatures = props.plant?.features
	if (plantFeatures !== undefined) {
		featuresList.push(...plantFeatures)
	}
	if (companyName.toLowerCase() === "dipai") {
		if (
			userData.portalMode === PortalMode.PROD &&
			devFeatures.includes(props.featureName) === true
		) {
			return null
		}
		return props.children
	}
	const companyFeatures = common.companyDataFromCompanyName.get(companyName)?.features
	if (companyFeatures !== undefined) {
		featuresList.push(...companyFeatures)
	}
	let filteredFeaturesList = featuresList
	if (userData.portalMode === PortalMode.PROD) {
		filteredFeaturesList = featuresList.filter((f) => devFeatures.includes(f) === false)
	}
	return filteredFeaturesList.includes(props.featureName) === true ? props.children : null
}

export function isFeatureAvailable(
	plant: PlantObject | undefined,
	featureName: PortalFeature,
	customerName: string,
	devFeatures: string[],
	portalMode?: PortalMode
): boolean {
	const featuresList: string[] | undefined = []
	const plantFeatures = plant?.features

	if (plantFeatures !== undefined) {
		featuresList.push(...plantFeatures)
	}

	if (customerName.toLowerCase() === "dipai") {
		if (portalMode === PortalMode.PROD && devFeatures.includes(featureName) === true) {
			return false
		}
		return true
	}
	const companyFeatures = common.companyDataFromCompanyName.get(customerName)?.features
	if (companyFeatures !== undefined) {
		featuresList.push(...companyFeatures)
	}
	let filteredFeaturesList = featuresList
	if (portalMode === PortalMode.PROD) {
		filteredFeaturesList = featuresList.filter((f) => devFeatures.includes(f) === false)
	}
	return filteredFeaturesList.includes(featureName) === true ? true : false
}

export const useIsContentAvailable = <TModuleList extends string>(
	contentList: ModuleMetadata<PortalFeature, TModuleList>[]
) => {
	const appStructure = useAppSelector(getAppStructure)
	const companyName = useAppSelector(getCurrentCompany)
	const userData = useAppSelector(getUserData)
	const devFeatures = useGetDevFeatures()

	if (appStructure === null) {
		return []
	}
	const mainCompanyName = Object.keys(appStructure)?.[0]
	if (companyName === undefined) {
		return []
	}
	const featuresList: string[] = []

	const companyFeatures = common.companyDataFromCompanyName.get(companyName)?.features
	if (companyFeatures !== undefined) {
		featuresList.push(...companyFeatures)
	}
	let filteredFeaturesList = featuresList
	if (userData.portalMode === PortalMode.PROD) {
		filteredFeaturesList = featuresList.filter((f) => devFeatures.includes(f) === false)
	}
	return mainCompanyName?.toLowerCase() === "dipai" && userData.portalMode === PortalMode.DEV
		? contentList
		: contentList.filter((content) =>
				content?.feature !== undefined
					? filteredFeaturesList.includes(content.feature)
					: true
		  )
}
