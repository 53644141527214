import { removeIncompatibleSearchParameters } from "@helpers/removeIncompatibleSearchParameters"
import { vesselStatusPageSearchSchema } from "@routes/clients.$clientName/_sopPageLayout/vessel-status"
import { createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute("/_sopPageLayout/vessel-status")({
	component: RouteComponent,
	validateSearch: vesselStatusPageSearchSchema,
	search: {
		middlewares: [removeIncompatibleSearchParameters(vesselStatusPageSearchSchema)],
	},
})

function RouteComponent() {
	return null
}
