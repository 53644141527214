import { common } from "@utils/common"
import { memo, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdOutlineThermostat } from "react-icons/md"
import { WiHumidity } from "react-icons/wi"
import { CompassDraw, CompassTimeseriesData, CompassTimeseriesDataUniSea } from "./compassDrawClass"
import { weatherSensorsUniSeaDict } from "./utils"

interface CanvasProps {
	width?: number
	time: number | undefined
	data: CompassTimeseriesData | CompassTimeseriesDataUniSea | undefined
	heading: number | null | undefined
	t1: Date
}

type CompassDisplayData = {
	sensorId: number | string
	value: number
	name: string | undefined
	unit: string | undefined
	max: number
	available: boolean
}[]

const CompassWind = ({ width = window.innerWidth, time, data, heading, t1 }: CanvasProps) => {
	const { t } = useTranslation()
	const canvasWidth = 200
	const canvasHeight = 200
	const canvasRef = useRef<HTMLCanvasElement | null>(null)
	const bgRef = useRef<HTMLCanvasElement | null>(null)
	const trueColor = "#cc6677"
	const relColor = "#332288"
	const stormColor = "#117733"
	const center: [number, number] = [canvasHeight / 2, width / 2]
	const size: number = 68

	const [relRot, setRelRot] = useState<number>(0)
	const [trueRot, setTrueRot] = useState<number>(0)
	const [stormRot, setStormRot] = useState<number>(0)
	const [airTemp, setAirTemp] = useState<number>(0)
	const [relSpeed, setRelSpeed] = useState<number>(0)
	const [trueSpeed, setTrueSpeed] = useState<number>(0)
	const [stormSpeed, setStormSpeed] = useState<number>(0)
	//const [displayData, setDisplayData] = useState<CompassDisplayData>()

	const [displayStorm, setDisplayStorm] = useState<boolean>(false)
	const [dataNotAvailable, setDataNotAvailable] = useState<boolean>(true)

	const [airTemperatureAvailable, setAirTemperatureAvailable] = useState<boolean>(false)
	const [airTemperature, setAirTemperature] = useState<number>(0)
	const [humidityAvailable, setHumidityAvailable] = useState<boolean>(false)
	const [humidity, setHumidity] = useState<number>(0)
	const [vesselSensorAvailable, setVesselSensorAvailable] = useState<boolean>(false)
	const [stormGlassAvailable, setStormGlassAvailable] = useState<boolean>(false)

	const avail = useRef<boolean>(false)
	const valueRef = useRef<number>(0)
	const maxRef = useRef<number>(30)
	const maxSpeedRef = useRef<number>(30)
	const maxSpeedStormRef = useRef<number>(30)
	const [mouseState, setMouseState] = useState<{ xPos: number; yPos: number }>({
		xPos: 0,
		yPos: 0,
	})

	const handleMouseMove = (event: MouseEvent) => {
		setMouseState({ xPos: event.offsetX, yPos: event.offsetY })
	}

	//const [timeSelected, setTimeSelected] = useState<number>(new Date(t1).getTime())
	const timeSelected = time ?? new Date(t1).getTime()
	const render = () => {
		const ctx = canvasRef.current?.getContext("2d") ?? null

		if (ctx == null) {
			return
		}
		const displayDataTemp: CompassDisplayData = []

		if (data != undefined) {
			for (const dataItem of data) {
				const valueDataSorted = dataItem.data.sort((a, b) => a.timestamp - b.timestamp)

				const values = valueDataSorted.map((item) => item.value)
				maxRef.current = Math.ceil(Math.max(...values))

				const last = valueDataSorted[valueDataSorted.length - 1]
				const first = valueDataSorted[0]
				if (last !== undefined) {
					if (timeSelected === undefined && valueDataSorted != undefined) {
						valueRef.current = first?.value ?? 0
					}

					//CHECK IF WEATHERDATA IS UP TO DATE
					if (timeSelected != undefined && last.timestamp + 3600000 < timeSelected) {
						avail.current = false
						setDataNotAvailable(true)
					} else {
						avail.current = true
						setDataNotAvailable(false)
					}

					if (timeSelected != undefined) {
						const datapointIndex = valueDataSorted.findIndex(
							(dataPoint) => dataPoint.timestamp > timeSelected
						)

						const valueDataAtSearch = valueDataSorted[datapointIndex]
						const valueDataPrev = valueDataSorted[datapointIndex - 1]

						if (valueDataAtSearch != undefined && valueDataPrev != undefined) {
							const t1diff = valueDataAtSearch.timestamp - timeSelected
							const t2diff = timeSelected - valueDataPrev.timestamp

							if (t1diff < t2diff) {
								valueRef.current = valueDataAtSearch.value
							} else {
								valueRef.current = valueDataPrev.value
							}
						} else {
							if (last.timestamp < timeSelected) {
								valueRef.current = last?.value ?? 0
							} else {
								valueRef.current = first?.value ?? 0
							}
						}
					}
				}

				if (avail.current === false) {
					continue
				}
				displayDataTemp.push({
					sensorId: dataItem.sensorId,
					name:
						typeof dataItem.sensorId === "string"
							? weatherSensorsUniSeaDict.find(
									(weatherSignal) => weatherSignal.id === dataItem.sensorId
							  )?.label ?? "unknown sensor"
							: common.sensorDataFromIds.get(dataItem.sensorId)?.display_name,
					unit:
						typeof dataItem.sensorId === "string"
							? weatherSensorsUniSeaDict.find(
									(weatherSignal) => weatherSignal.id === dataItem.sensorId
							  )?.unit ?? "unknown unit"
							: common.sensorDataFromIds.get(dataItem.sensorId)?.unit,
					value: valueRef.current,
					max: maxRef.current,
					available: avail.current,
				})
			}
		}

		//Sensors available :
		const windSpeed = displayDataTemp?.find((avail) => avail.name == "Wind Speed (10m)")
		const windDirection = displayDataTemp?.find((avail) => avail.name == "Wind Direction")
		const relativeSpeed = displayDataTemp?.find((avail) => avail.name == "Relative Wind Speed")
		const relativeWind = displayDataTemp?.find(
			(avail) => avail.name == "Relative Wind Direction"
		)
		const trueSpeed = displayDataTemp?.find((avail) => avail.name == "True Wind Speed")
		const trueWind = displayDataTemp?.find((avail) => avail.name == "True Wind Direction")
		// const waveHeight = displayDataTemp?.find((avail) => avail.name == "Wave Height")
		// const waveDirection = displayDataTemp?.find((avail) => avail.name == "Wave Direction")
		// const windWaveHeight = displayDataTemp?.find((avail) => avail.name == "Wind Wave Height")
		// const windWavePeriod = displayDataTemp?.find((avail) => avail.name == "Wind Wave Period")
		// const waterTemperature = displayDataTemp?.find((avail) => avail.name == "waterTemperature")
		// const currentSpeed = displayDataTemp?.find((avail) => avail.name == "Ocean Current Speed")
		// const currentDirection = displayDataTemp?.find(
		// 	(avail) => avail.name == "Ocean Current Direction"
		// )

		const humidityTemp = displayDataTemp?.find((avail) => avail.name == "Air Humidity")
		if (humidityTemp != undefined) {
			setHumidityAvailable(true)
			setHumidity(humidityTemp.value)
		} else {
			setHumidityAvailable(false)
		}

		const airTemperatureTemp = displayDataTemp?.find((value) => value.name == "Air Temperature")
		if (airTemperatureTemp?.available != undefined) {
			setAirTemperatureAvailable(true)
			setAirTemperature(airTemperatureTemp.value)
		} else {
			setAirTemperatureAvailable(false)
		}

		if (
			relativeSpeed != undefined &&
			relativeWind != undefined &&
			trueSpeed != undefined &&
			trueWind != undefined
		) {
			setVesselSensorAvailable(true)
		} else {
			setVesselSensorAvailable(false)
		}
		if (windDirection !== undefined && windSpeed !== undefined) {
			setStormGlassAvailable(true)
		} else {
			setStormGlassAvailable(false)
		}

		if (displayDataTemp !== undefined) {
			// FIND MAX WINDSPEED
			const relMaxTemp = displayDataTemp?.find((max) => max.name == "Relative Wind Speed")
			const trueMaxTemp = displayDataTemp?.find((max) => max.name == "True Wind Speed")

			if (relMaxTemp?.max != undefined && trueMaxTemp?.max != undefined)
				if (relMaxTemp.max < 30 && trueMaxTemp.max < 30) {
					maxSpeedRef.current = 30
				} else {
					if (relMaxTemp.max > trueMaxTemp.max) {
						maxSpeedRef.current = relMaxTemp.max
					} else {
						maxSpeedRef.current = trueMaxTemp.max
					}
				}
		}

		// Max STorm
		if (windSpeed !== undefined) {
			const stormMaxTemp = displayDataTemp?.find((max) => max.name == "Wind Speed (10m)")
			if (stormMaxTemp?.max != undefined)
				if (stormMaxTemp.max * 1.94384449 < 30) {
					maxSpeedStormRef.current = 30
				} else {
					maxSpeedStormRef.current = Math.ceil(stormMaxTemp.max * 1.94384449)
				}
		}

		const compassDraw = new CompassDraw(ctx)

		ctx.clearRect(0, 0, width, canvasHeight)

		//Check for stormglass or not
		if (displayDataTemp !== undefined) {
			//Storm glass Wind direction
			const stormCheck = displayDataTemp?.find((value) => value.name == "Wind Direction")
			if (stormCheck?.value != undefined) {
				setDisplayStorm(true)
				maxSpeedRef.current = maxSpeedStormRef.current
			}
		}
		//compassDraw.compass(size, center[0])

		compassDraw.gaugeSingle(size, center[0], maxSpeedRef.current, "kn")

		const speed: [number, number] = [0, 0]

		compassDraw.vessel(heading ?? 0, center[0], "#dddddd")

		if (stormGlassAvailable === false && vesselSensorAvailable === false) {
			compassDraw.noData(
				size,
				center[0],
				`${t("THIS WEATHER DATA")}`,
				`${t("HAS 2 HOURS DELAY")}`
			)
		}
		//Wind speed  --- Draw speedometer and speed value
		if (displayDataTemp !== undefined) {
			//Relative Wind
			const relSpeedTemp = displayDataTemp?.find(
				(value) => value.name == "Relative Wind Speed"
			)
			if (relSpeedTemp?.value != undefined) {
				setRelSpeed(relSpeedTemp.value)
				compassDraw.gaugeSingleLine(
					relSpeedTemp.value,
					relColor,
					center[0],
					size,
					6,
					maxSpeedRef.current,
					1
				)
				speed[0] = relSpeedTemp.value
				//compassDraw.speedVal(relSpeedTemp.value, center[0], size)
			}

			//True Wind
			const trueSpeedTemp = displayDataTemp?.find((value) => value.name == "True Wind Speed")
			if (trueSpeedTemp?.value != undefined) {
				setTrueSpeed(trueSpeedTemp.value)
				compassDraw.gaugeSingleLine(
					trueSpeedTemp.value,
					trueColor,
					center[0],
					size,
					14,
					maxSpeedRef.current,
					1
				)
				speed[1] = trueSpeedTemp.value
			}
			//Storm Wind
			const stormSpeedTemp = displayDataTemp?.find(
				(value) => value.name == "Wind Speed (10m)"
			)
			if (stormSpeedTemp?.value != undefined && vesselSensorAvailable === false) {
				setStormSpeed(stormSpeedTemp.value)
				compassDraw.gaugeSingleLine(
					stormSpeedTemp.value * 1.94384449,
					stormColor,
					center[0],
					size,
					10,
					maxSpeedStormRef.current,
					2
				)
				speed[0] = stormSpeedTemp.value * 1.94384449
			}
			compassDraw.gaugeSingleVal(
				speed[0],
				speed[1],
				`${t("REL")}`,
				`${t("TRUE")}`,
				center[0],
				size,
				mouseState.xPos,
				mouseState.yPos,
				displayStorm,
				stormGlassAvailable != true && vesselSensorAvailable != true
			)
		}

		//Wind direction ---  Draw Arrow
		if (displayDataTemp !== undefined) {
			//Relative Wind
			const relRotTemp = displayDataTemp?.find(
				(value) => value.name == "Relative Wind Direction"
			)
			if (relRotTemp?.value != undefined) {
				setRelRot(relRotTemp.value)
				//if (!displayTrue)
				compassDraw.arrow(relRotTemp.value, relColor, center[0], size)
			}
			//True Wind
			const trueRotTemp = displayDataTemp?.find(
				(value) => value.name == "True Wind Direction"
			)
			if (trueRotTemp?.value != undefined) {
				setTrueRot(trueRotTemp.value)
				//if (displayTrue)
				compassDraw.arrow(trueRotTemp.value, trueColor, center[0], size)
			}
			//Storm glass Wind direction
			const stormRotTemp = displayDataTemp?.find((value) => value.name == "Wind Direction")
			if (stormRotTemp?.value != undefined && vesselSensorAvailable === false) {
				setStormRot(stormRotTemp.value)
				//if (displayTrue)
				compassDraw.arrow(stormRotTemp.value, stormColor, center[0], size)
			}
		}

		//Air Temperature
		if (airTemperature !== undefined) {
			const airTempTemp = displayDataTemp?.find((value) => value.name == "Air Temperature")
			if (airTempTemp !== undefined) {
				setAirTemp(airTempTemp.value)
			}
		}

		//setDisplayData(displayDataTemp)

		ctx.setTransform(1, 0, 0, 1, 0, 0)
	}

	const renderBackground = () => {
		const ctx = bgRef.current?.getContext("2d") ?? null

		if (ctx == null) {
			return
		}
		const bgDraw = new CompassDraw(ctx)
		ctx.clearRect(0, 0, width, canvasHeight)
		bgDraw.compass(size, center[0])
		//bgDraw.speedometer(size, center[0], maxSpeedRef.current)
	}

	useEffect(() => {
		const canvas = canvasRef.current
		if (canvas === undefined || canvas === null) {
			return
		}
		canvas.addEventListener("mousemove", handleMouseMove)

		const frameId = requestAnimationFrame(render)

		return () => {
			cancelAnimationFrame(frameId)
			canvas?.removeEventListener("mousemove", handleMouseMove)
		}
	}, [time, data, heading, mouseState, stormGlassAvailable, vesselSensorAvailable])

	useEffect(() => {
		const canvas = bgRef.current

		if (canvas === null) return

		const frameId = requestAnimationFrame(renderBackground)
		//if (time != undefined) setTimeSelected(time)

		return () => {
			cancelAnimationFrame(frameId)
		}
	}, [data])

	return data?.length != 0 ? (
		<div style={{ width: width }} className="relative flex h-[282px] flex-col px-2 text-tiny">
			<p className="mb-2 text-center border-b-1 text-menu">{`${t("WIND")}`}</p>
			<div className={"flex justify-center"}>
				<div>
					<canvas
						className="z-1 absolute h-[200px] w-[200px]"
						ref={canvasRef}
						height={canvasHeight}
						width={canvasWidth}
					/>
					<canvas
						className="z-0 h-[200px] w-[200px]"
						ref={bgRef}
						height={canvasHeight}
						width={canvasWidth}
					/>
				</div>
			</div>
			<div>
				<ul className="absolute space-y-1 right-2 top-7">
					<p className="font-medium">{`${t("AIR TEMPERATURE")}`} </p>
					<li className="flex flex-row-reverse items-center">
						<p className="text-end">
							{`${
								airTemperatureAvailable != false ? airTemperature.toFixed(1) : "--"
							} °C`}{" "}
						</p>
						<AirTemperatureIconMemo />
						{/* <PiThermometerSimpleLight className="w-5 h-5 fill-slate-700 " /> */}
					</li>
				</ul>
			</div>
			<div>
				<ul className="absolute space-y-1 left-2 top-7">
					<p className="font-medium">{`${t("HUMIDITY")}`} </p>
					<li className="flex flex-row items-center">
						<HumidityIconMemo />
						<p className="text-end">
							{`${humidityAvailable != false ? humidity.toFixed(1) : "--"} %`}{" "}
						</p>
						{/* <PiThermometerSimpleLight className="w-5 h-5 fill-slate-700 " /> */}
					</li>
				</ul>
			</div>
			<div>
				{vesselSensorAvailable === true ? (
					<ul className="absolute bottom-1">
						<li className="flex items-center gap-2">
							<div style={{ backgroundColor: relColor }} className="h-3 w-7"></div>
							<p>{`${t("RELATIVE WIND DIRECTION")}:  ${relRot.toFixed(1)} °`}</p>
						</li>
						<li className="flex items-center gap-2">
							<div style={{ backgroundColor: trueColor }} className="h-3 w-7"></div>
							<p>{`${t("TRUE WIND DIRECTION")} ${trueRot.toFixed(1)} °`}</p>
						</li>
					</ul>
				) : (
					<ul className="space-y-0 ">
						<li className="flex items-center gap-2">
							<div style={{ backgroundColor: stormColor }} className="h-3 w-7"></div>
							<p>
								{`${t("WIND DIRECTION")} ${
									dataNotAvailable != true ? stormRot.toFixed(1) : "-,-"
								} °`}{" "}
							</p>
						</li>
					</ul>
				)}
			</div>
		</div>
	) : null
}

export default CompassWind

const HumidityIconMemo = memo(function HumidityIconMemo() {
	return <WiHumidity />
})

const AirTemperatureIconMemo = memo(function AirTemperatureIconMemo() {
	return <MdOutlineThermostat />
})
