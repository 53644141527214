import { InformationContainer } from "@components/containers/Containers"
import { IconId } from "@components/icon/IconId"
import KpiHullVesselsContent from "@components/kpi/KpiHullVesselsContent"
import { getSensorIdWithoutComponent } from "@helpers/getSensorIdWithoutComponent"
import { useAppSelector } from "@redux/app/hooks"
import { getSelectedVessel } from "@redux/features/selectedVessel/selectedVesselSlice"
import { common } from "@utils/common"
import { AggregationMethod } from "api"

export default function WaterQualityKpis({ customerName }: { customerName: string }) {
	const selectedVessel = useAppSelector(getSelectedVessel)

	const plantData = common.plantDataFromOwnerAndName.get(
		`${customerName?.toLowerCase()}/${selectedVessel.name?.toLowerCase()}`
	)

	return (
		<InformationContainer>
			<>
				<div className="grid grid-cols-[266.5px_2px_266.5px] border-b-2 border-gray-eee">
					<KpiHullVesselsContent
						iconId={IconId.CO2Water}
						label={"CO2 level"}
						unit={"mg/L"}
						topics={[
							{
								sensorId: getSensorIdWithoutComponent(plantData, "CO2 PS"),
								aggregationMethod: AggregationMethod.SNAP_FIRST,
							},
							{
								sensorId: getSensorIdWithoutComponent(plantData, "CO2 SB"),
								aggregationMethod: AggregationMethod.SNAP_FIRST,
							},
						]}
					/>
					<div className="my-5 bg-gray-eee" />

					<KpiHullVesselsContent
						iconId={IconId.O2Water}
						label={"O2 level"}
						unit={"%"}
						topics={[
							{
								sensorId: getSensorIdWithoutComponent(plantData, "O2 PS FWD"),
								aggregationMethod: AggregationMethod.SNAP_FIRST,
							},
							{
								sensorId: getSensorIdWithoutComponent(plantData, "O2 PS Cent"),
								aggregationMethod: AggregationMethod.SNAP_FIRST,
							},
							{
								sensorId: getSensorIdWithoutComponent(plantData, "O2 PS AFT"),
								aggregationMethod: AggregationMethod.SNAP_FIRST,
							},
							{
								sensorId: getSensorIdWithoutComponent(plantData, "O2 SB FWD"),
								aggregationMethod: AggregationMethod.SNAP_FIRST,
							},
							{
								sensorId: getSensorIdWithoutComponent(plantData, "O2 SB Cent"),
								aggregationMethod: AggregationMethod.SNAP_FIRST,
							},
							{
								sensorId: getSensorIdWithoutComponent(plantData, "O2 SB AFT"),
								aggregationMethod: AggregationMethod.SNAP_FIRST,
							},
						]}
					/>
				</div>
				<div className="grid grid-cols-[266.5px_2px_266.5px]">
					<KpiHullVesselsContent
						iconId={IconId.PhWater}
						label={"pH"}
						topics={[
							{
								sensorId: getSensorIdWithoutComponent(plantData, "PH PS"),
								aggregationMethod: AggregationMethod.SNAP_FIRST,
							},
							{
								sensorId: getSensorIdWithoutComponent(plantData, "PH SB"),
								aggregationMethod: AggregationMethod.SNAP_FIRST,
							},
						]}
					/>
					<div className="my-5 bg-gray-eee" />

					<KpiHullVesselsContent
						iconId={IconId.TemperatureWater}
						label={"Temperature"}
						unit={"°C"}
						topics={[
							{
								sensorId: getSensorIdWithoutComponent(plantData, "Temp PS FWD"),
								aggregationMethod: AggregationMethod.SNAP_FIRST,
							},
							{
								sensorId: getSensorIdWithoutComponent(plantData, "Temp PS Cent"),
								aggregationMethod: AggregationMethod.SNAP_FIRST,
							},
							{
								sensorId: getSensorIdWithoutComponent(plantData, "Temp PS AFT"),
								aggregationMethod: AggregationMethod.SNAP_FIRST,
							},
							{
								sensorId: getSensorIdWithoutComponent(plantData, "Temp SB FWD"),
								aggregationMethod: AggregationMethod.SNAP_FIRST,
							},
							{
								sensorId: getSensorIdWithoutComponent(plantData, "Temp SB Cent"),
								aggregationMethod: AggregationMethod.SNAP_FIRST,
							},
							{
								sensorId: getSensorIdWithoutComponent(plantData, "Temp SB AFT"),
								aggregationMethod: AggregationMethod.SNAP_FIRST,
							},
						]}
					/>
				</div>
			</>
		</InformationContainer>
	)
}
