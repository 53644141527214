import { validateMachinerySearch } from "@routes/clients.$clientName/_sopPageLayout/machinery"
import { createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute("/_sopPageLayout/machinery")({
	component: RouteComponent,
	validateSearch: validateMachinerySearch,
})

function RouteComponent() {
	return null
}
