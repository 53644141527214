import { IconButton, debounce } from "@ui-lib/index"
import { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { MdNavigateBefore } from "react-icons/md"

type HTMLDivElementExtended = HTMLDivElement & { initialTouchX?: number; initialTouchY?: number }

export default function CarouselV2({
	children,
	step = 1,
	showPaginationControls = true,
	paginationControlsOverlap = true,
	itemsSpacing = 16,
	direction = "horizontal",
	paginationControlsSize = "medium",
}: {
	children: ReactNode[]
	step?: number
	showPaginationControls?: boolean
	paginationControlsOverlap?: boolean
	itemsSpacing?: number
	direction?: "horizontal" | "vertical"
	paginationControlsSize?: "sm" | "medium" | "lg"
}): ReactElement {
	const dimensionRef = direction === "horizontal" ? "width" : "height"
	const offsetRef = direction === "horizontal" ? "offsetWidth" : "offsetHeight"

	const carouselRef = useRef<HTMLDivElementExtended | null>(null)
	const nodeRefs = useRef<(HTMLDivElement | null)[]>([])
	const [maxIndex, setMaxIndex] = useState<number>(0)
	const [nodeSizesAccumulated, setNodeSizesAccumulated] = useState<number[]>([])
	const [currentIndex, setCurrentIndex] = useState(0)
	const [carouselSize, setCarouselSize] = useState<number | null>(null)
	const [swipeOffset, setSwipeOffset] = useState<number>(0)

	// Function to assign refs to each item
	const assignRef = (el: HTMLDivElement | null, index: number) => {
		if (el !== null && nodeRefs.current[index] !== el) {
			nodeRefs.current[index] = el
		}
	}

	// Effect to measure carousel size once it's mounted
	useEffect(() => {
		if (carouselRef.current !== null) {
			setCarouselSize(carouselRef.current[offsetRef])
		}
	}, [])

	// Effect to calculate sizes of children and maxIndex
	useEffect(() => {
		const sizes = nodeRefs.current.map((ref) =>
			ref !== null ? ref.getBoundingClientRect()[dimensionRef] : 0
		)

		const accumulatedSizes = sizes.map((s, index) =>
			sizes
				.slice(0, index + 1)
				.reduce(
					(prev, curr, currIndex) => curr + (currIndex > 0 ? itemsSpacing : 0) + prev,
					0
				)
		)

		setNodeSizesAccumulated(accumulatedSizes)

		if (carouselSize !== null) {
			const newMaxIndex =
				children.length - accumulatedSizes.findIndex((s) => s > carouselSize)
			setMaxIndex(newMaxIndex)
		}
	}, [children, carouselSize])

	const next = () => {
		setCurrentIndex((prev) => Math.min(maxIndex, prev + step))
	}

	const prev = () => {
		setCurrentIndex((prev) => Math.max(0, prev - step))
	}

	// Effect to reset index if carousel size changes
	useEffect(() => {
		if (carouselSize !== null && nodeSizesAccumulated.length > 0) {
			const lastNode = nodeSizesAccumulated[nodeSizesAccumulated.length - 1]
			if (lastNode !== undefined && carouselSize >= lastNode) {
				setCurrentIndex(0)
			}
		}
	}, [carouselSize, nodeSizesAccumulated])

	// Debounced wheel handler
	const handleWheel = debounce((event: WheelEvent) => {
		event.preventDefault()
		event.deltaY > 0 ? next() : prev()
	}, 25)

	// Add event listeners for wheel interactions
	useEffect(() => {
		const carousel = carouselRef.current
		if (carousel === null) return

		carousel.addEventListener("wheel", handleWheel, { passive: false })

		return () => {
			carousel.removeEventListener("wheel", handleWheel)
		}
	}, [])

	// Touch handling functions
	const handleTouchStart = (event: TouchEvent) => {
		const touch = event.touches[0]
		if (touch === undefined || carouselRef.current === null) return

		if (direction === "vertical") {
			carouselRef.current.initialTouchY = touch.clientY
		} else {
			carouselRef.current.initialTouchX = touch.clientX
		}
	}

	const handleTouchMove = useCallback(() => {
		debounce((event: TouchEvent) => {
			const touch = event.touches[0]
			if (touch === undefined || carouselRef.current === null) return

			const swipeAmount =
				direction === "vertical"
					? touch.clientY * 0.9 - (carouselRef.current.initialTouchY ?? 0)
					: touch.clientX * 0.9 - (carouselRef.current.initialTouchX ?? 0)

			const lastNodeSize = nodeSizesAccumulated[nodeSizesAccumulated.length - 1]
			setSwipeOffset((prev) =>
				swipeAmount < 0 && lastNodeSize !== undefined
					? Math.min(prev + Math.abs(swipeAmount), lastNodeSize - (carouselSize ?? 0))
					: Math.max(prev - swipeAmount, 0)
			)

			setCurrentIndex(0)
		}, 10)
	}, [carouselSize, nodeSizesAccumulated])

	useEffect(() => {
		const carousel = carouselRef.current
		if (carousel === null || nodeSizesAccumulated.length === 0) return

		carousel.addEventListener("touchstart", handleTouchStart)
		carousel.addEventListener("touchmove", handleTouchMove)

		return () => {
			carousel.removeEventListener("touchstart", handleTouchStart)
			carousel.removeEventListener("touchmove", handleTouchMove)
		}
	}, [nodeSizesAccumulated])

	// Calculate offset value without accessing ref in JSX
	const offsetComponentValue =
		currentIndex === 0
			? swipeOffset
			: (nodeSizesAccumulated[
					nodeSizesAccumulated.findIndex((s) => s > (carouselSize ?? 0)) +
						currentIndex -
						1
			  ] ?? 0) - (carouselSize ?? 0)

	return (
		<div
			ref={carouselRef}
			className={`flex h-full w-full ${
				direction === "horizontal" ? "flex-col justify-center" : "flex-row justify-center"
			}`}
		>
			{showPaginationControls &&
				currentIndex > 0 &&
				carouselSize !== null &&
				nodeSizesAccumulated.length > 0 && (
					<IconButton
						onClick={prev}
						size={paginationControlsSize}
						variant="text-light"
						className={`absolute z-1 ${
							paginationControlsOverlap
								? direction === "horizontal"
									? "-left-4"
									: "-top-4 rotate-90"
								: direction === "horizontal"
								? "-left-8"
								: "-top-8 rotate-90"
						}`}
					>
						<IconButton.Icon>{MdNavigateBefore}</IconButton.Icon>
					</IconButton>
				)}
			<div className="w-full h-full overflow-hidden">
				<div
					className={`flex shrink-0 grow ${
						direction === "horizontal" ? "flex-row" : "flex-col"
					} transition-all duration-[400ms] ease-in-out`}
					style={{
						gap: itemsSpacing,
						transform: `translate${
							direction === "horizontal" ? "X" : "Y"
						}(-${offsetComponentValue}px)`,
					}}
				>
					{children.map((node, index) => (
						<div key={index} ref={(el) => assignRef(el, index)}>
							{node}
						</div>
					))}
				</div>
			</div>
		</div>
	)
}
