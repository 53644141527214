export enum OilAndGasFieldType {
	OIL = "Oil",
	GAS = "Gas",
	"OIL/GAS" = "Oil/Gas",
	"GAS/CONDENSATE" = "Gas/Condensate",
}

export type OilAndGasFieldArea = {
	fldNpdidField: string
	wlbNpdidWellbore: string
	fldName: string
	fldCurrentActivitySatus: string
	wlbName: string
	fldDiscoveryYear: string
	fldFactPageUrl: string
	fldFactMapUrl: string
	fldOwnerKind: string
	fldOwnerName: string
	cmpLongName: string
	wlbFactPageUrl: string
	fldHcType: keyof typeof OilAndGasFieldType | ""
	fldMainArea: string
	cmpNpdidCompany: string
	fldNpdidOwner: string
	fldDateUpdated: string
	fldDateUpdatedMax: string
	fldMainSupplyBase: string
	fldAreaGeometryWKT: (number[][] | number[][][])[]
}

export function getFldArea(): OilAndGasFieldArea[] {
	return [
		{
			fldNpdidField: "46437",
			wlbNpdidWellbore: "398",
			fldName: "TROLL",
			fldCurrentActivitySatus: "Producing",
			wlbName: "31/2-1",
			fldDiscoveryYear: "1979",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=46437",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=46437",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "TROLL UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=398",
			fldHcType: "OIL/GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "41105",
			fldDateUpdated: "2022-09-13 00:00:00",
			fldDateUpdatedMax: "2021-12-22 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[
						[60.502760915769727, 3.9094638207928272],
						[60.502760921988767, 3.9094639128468547],
						[60.502761004651525, 3.9094640512559482],
						[60.502761081164593, 3.9094640994242766],
						[60.509319362541142, 3.9100890965265132],
						[60.51812774561575, 3.9125834917803024],
						[60.518127774579206, 3.912583496351961],
						[60.518127833878808, 3.9125834943293891],
						[60.518127864284274, 3.9125834895492755],
						[60.523116729611644, 3.9109085004285595],
						[60.532616677865171, 3.9111612986683193],
						[60.545722161033787, 3.9137723963809115],
						[60.545722169151581, 3.9137723969301241],
						[60.545722186350766, 3.9137723997034923],
						[60.545722195362828, 3.9137724001120069],
						[60.559294343689892, 3.9141334986273879],
						[60.572597101231686, 3.9217279734996322],
						[60.572597103089485, 3.9217279750355201],
						[60.572597105841545, 3.9217279764307613],
						[60.57259710769933, 3.9217279779666505],
						[60.584549893696078, 3.9279334697808959],
						[60.590855480852404, 3.932627959756827],
						[60.590855515664643, 3.9326279762385745],
						[60.590855590171351, 3.9326279956315866],
						[60.590855628971497, 3.9326279986833059],
						[60.603763912217126, 3.9307141008048205],
						[60.612583254967731, 3.9309529986172644],
						[60.620255483805671, 3.9343307666430372],
						[60.625244256862111, 3.939450139537799],
						[60.625244324558651, 3.9394501691881034],
						[60.625244463316243, 3.9394501748599513],
						[60.625244533413962, 3.9394501492017393],
						[60.636166803835614, 3.9297779754214099],
						[60.651588898107079, 3.9243030135135193],
						[60.661969310756824, 3.9282084806822413],
						[60.671885939315558, 3.9343723345702717],
						[60.678344213921889, 3.950422275684875],
						[60.6783442148162, 3.9504222755444132],
						[60.678344214885101, 3.9504222773676436],
						[60.679724806637907, 3.9537250579410452],
						[60.681688700436062, 3.9646639168937337],
						[60.681613700042142, 3.9764555990653525],
						[60.681533100286309, 3.9886999997845463],
						[60.681533100696583, 3.9887000107256791],
						[60.681533104336971, 3.9887000358367457],
						[60.681533105709981, 3.9887000484619808],
						[60.685060905989843, 4.0028639481748067],
						[60.685060931084848, 4.0028639938011787],
						[60.685061001231873, 4.0028640654420053],
						[60.685061046352082, 4.0028640932802357],
						[60.692291642830284, 4.0048862923466393],
						[60.695449939858548, 4.0058834913188903],
						[60.695450014296796, 4.0058834852532188],
						[60.695450138011047, 4.0058834256543543],
						[60.69545018721886, 4.0058833702968437],
						[60.699136286668235, 3.9960083697464439],
						[60.699136292314193, 3.9960083523503833],
						[60.699136298239907, 3.9960083183941264],
						[60.699136300308389, 3.9960083015553316],
						[60.699191900355054, 3.9873861448707473],
						[60.706530774840203, 3.9717083984001866],
						[60.714527967048426, 3.9596751109576624],
						[60.714527968768266, 3.9596751088515294],
						[60.714527972070336, 3.9596751009886479],
						[60.714527973790176, 3.9596750988824971],
						[60.722080774146356, 3.9462639971029105],
						[60.722080777585568, 3.9462639928881811],
						[60.722080784188087, 3.9462639771559416],
						[60.722080786664023, 3.946263971256343],
						[60.72622234977505, 3.9354807672022685],
						[60.730983349999697, 3.9342473947046792],
						[60.730983401794084, 3.9342473608065691],
						[60.730983476553682, 3.9342472681692779],
						[60.730983499588064, 3.9342472112562641],
						[60.731494597985062, 3.9251778333889371],
						[60.73431688465088, 3.9156250772078938],
						[60.742558474756535, 3.9008556978418389],
						[60.742558476475374, 3.9008556957306704],
						[60.742558478124607, 3.9008556917927857],
						[60.742558478879616, 3.9008556879971104],
						[60.748519535694193, 3.8887473774327184],
						[60.750561138943574, 3.8883445958255454],
						[60.750561186602965, 3.8883445716804625],
						[60.750561263130216, 3.8883445024894194],
						[60.750561291928271, 3.8883444556162732],
						[60.754300144948189, 3.8756308154910242],
						[60.760138878743895, 3.876233499653809],
						[60.760138935902432, 3.8762334886742553],
						[60.760139034653037, 3.8762334361051507],
						[60.76013907624511, 3.8762333945155989],
						[60.764033476027222, 3.8690639953383283],
						[60.764033475957177, 3.8690639935104643],
						[60.764033476781307, 3.8690639915393619],
						[60.76403347767549, 3.8690639913961431],
						[60.766327920546466, 3.8645780022807026],
						[60.768813895230892, 3.864639099775093],
						[60.768813952177752, 3.8646390832867885],
						[60.768814046171734, 3.8646390240710082],
						[60.768814084042916, 3.8646389793720162],
						[60.77478907660614, 3.8507000951745396],
						[60.77798903935043, 3.8457807515534532],
						[60.782075093582804, 3.8436084777113817],
						[60.782075120667542, 3.8436084567868956],
						[60.782075166367179, 3.8436084052613428],
						[60.782075184158352, 3.8436083766331106],
						[60.787361284118013, 3.8310111770095907],
						[60.787361286659198, 3.8310111729181742],
						[60.787361289812125, 3.8310111613659972],
						[60.787361292282739, 3.8310111554454807],
						[60.790600191814242, 3.8197222558250927],
						[60.790600176723913, 3.8197221680742643],
						[60.790600082758885, 3.8197220415534403],
						[60.790600004707564, 3.8197220008094388],
						[60.787433556063512, 3.8196442064591576],
						[60.789058488405082, 3.8133194703126501],
						[60.793186278385512, 3.8052333913481533],
						[60.793186284147474, 3.8052333775265721],
						[60.793186293060089, 3.8052333521482544],
						[60.793186296139794, 3.8052333387621831],
						[60.795989085089943, 3.7912028942356617],
						[60.802600146699852, 3.7840807365708811],
						[60.802600166194523, 3.7840807057698402],
						[60.802600193204853, 3.7840806369142808],
						[60.802600199897689, 3.7840806008354106],
						[60.802627999969793, 3.779533301691302],
						[60.80262799303668, 3.779533262311233],
						[60.802627965150862, 3.779533193205415],
						[60.802627944126847, 3.7795331616498622],
						[60.801280776151451, 3.778138793747591],
						[60.801236614166903, 3.7780037271878091],
						[60.801474959888573, 3.7780529962116449],
						[60.801474995041872, 3.778052997837448],
						[60.801475062989731, 3.778052986743154],
						[60.801475095855636, 3.7780529758527872],
						[60.809422295980447, 3.7736890757654478],
						[60.809422320442536, 3.7736890570319139],
						[60.809422361106122, 3.7736890153896714],
						[60.809422378130265, 3.7736889905046223],
						[60.811714068646943, 3.7691918087773795],
						[60.816277948080931, 3.7633834357518388],
						[60.82151403556027, 3.7585001452853648],
						[60.82151403913636, 3.7585001446987918],
						[60.821514044285649, 3.7585001383264776],
						[60.821514046002079, 3.7585001362023553],
						[60.8290333784106, 3.7504808084712473],
						[60.834461079366719, 3.7510584995737095],
						[60.834461121456798, 3.7510584944996115],
						[60.83446119873345, 3.7510584688960598],
						[60.834461234742299, 3.7510584463881211],
						[60.83833073463785, 3.7475057470142099],
						[60.838330745007042, 3.7475057360908375],
						[60.838330762241128, 3.7475057166640697],
						[60.838330769856547, 3.7475057043501017],
						[60.843589069924633, 3.738975105767969],
						[60.84358907903961, 3.7389750858271649],
						[60.843589093621752, 3.7389750447028098],
						[60.843589098266783, 3.7389750254986103],
						[60.84454739322338, 3.7312583711994085],
						[60.848202964961288, 3.7258751127871568],
						[60.848202972574597, 3.7258751004642106],
						[60.848202985047216, 3.7258750744292124],
						[60.848202990800509, 3.725875060569444],
						[60.853747386442265, 3.7082389746635873],
						[60.858108456548806, 3.699225134706424],
						[60.865833413046147, 3.6939251644301634],
						[60.865833435562919, 3.6939251422319272],
						[60.865833474121438, 3.6939250933769134],
						[60.865833489196767, 3.6939250650357134],
						[60.867222353374139, 3.6898584694609973],
						[60.869125265736393, 3.6894192267278458],
						[60.867219204014553, 3.6990055601748972],
						[60.867219203337974, 3.6990055658232555],
						[60.867219201912384, 3.6990055752867286],
						[60.867219201163351, 3.6990055791018301],
						[60.86576090105995, 3.7136721791655254],
						[60.865760972730136, 3.7136722853796256],
						[60.865761162961377, 3.7136723405752523],
						[60.865761279590018, 3.7136722861866494],
						[60.874555774414034, 3.6955778990478847],
						[60.882680724184546, 3.6832945755710438],
						[60.88511646956745, 3.6826780640970038],
						[60.884919200747888, 3.6844971785690177],
						[60.884919264250563, 3.6844972824513422],
						[60.884919444273493, 3.6844973521284134],
						[60.884919559972531, 3.6844973199065163],
						[60.89098345102056, 3.676372332391447],
						[60.89451954028381, 3.6728834425635712],
						[60.894519548858547, 3.6728834318950359],
						[60.894519566008022, 3.6728834105579646],
						[60.894519572794991, 3.672883400188383],
						[60.896097352041132, 3.6701529361913514],
						[60.902125090112065, 3.6656807821943103],
						[60.91364999307357, 3.6623141098509273],
						[60.916716588234522, 3.6634307714420418],
						[60.921638675276085, 3.6685417553440733],
						[60.919408107789828, 3.6761221433361086],
						[60.919408150065031, 3.6761222564078651],
						[60.919408311591376, 3.6761223587717624],
						[60.919408431809181, 3.6761223497505551],
						[60.924261231623248, 3.671839050431859],
						[60.924261244594312, 3.6718390371677687],
						[60.924261266815172, 3.6718390075654388],
						[60.924261277031725, 3.671838992913945],
						[60.92843907701269, 3.6638583926602215],
						[60.928439078654492, 3.6638583886869815],
						[60.92843908283195, 3.6638583805905851],
						[60.928439085294627, 3.663858374630756],
						[60.932083484756234, 3.6548750750762955],
						[60.932083485650082, 3.654875074926081],
						[60.934091867302534, 3.6498418206753054],
						[60.937139019842292, 3.6472973602014975],
						[60.941255711384727, 3.6445584676832032],
						[60.941255728748779, 3.644558451810533],
						[60.941255762363362, 3.6445584147037082],
						[60.941255776005583, 3.6445583957585854],
						[60.942350175771274, 3.6425333958677393],
						[60.942350186218768, 3.6425333645110651],
						[60.942350197208341, 3.6425333016171435],
						[60.942350197750365, 3.6425332700799058],
						[60.942072397802825, 3.6406860701296631],
						[60.942072382709462, 3.6406860301297894],
						[60.942072338587934, 3.6406859617277436],
						[60.942072308665907, 3.6406859334762496],
						[60.941069584345527, 3.6400304812431328],
						[60.940678011181213, 3.6374500550657447],
						[60.94224183192123, 3.6354473568819179],
						[60.947658395111517, 3.6325223755612526],
						[60.947658402188672, 3.632522372516064],
						[60.947658415375749, 3.6325223647389726],
						[60.947658421412264, 3.6325223581693482],
						[60.953397320927188, 3.6281445596601878],
						[60.953397324428977, 3.628144557217809],
						[60.953397327930745, 3.6281445547754307],
						[60.953397329644893, 3.6281445526352396],
						[60.957455608632785, 3.6246641562228814],
						[60.958688688764717, 3.6255750928246298],
						[60.958227603321149, 3.6300749624572992],
						[60.955269214773644, 3.6408082162483373],
						[60.947860932172851, 3.6523109926779211],
						[60.947860929564442, 3.652310994966824],
						[60.947860927029168, 3.6523109990934808],
						[60.947860925387765, 3.6523110030697761],
						[60.941158124716942, 3.6644499038038068],
						[60.9411581169824, 3.6644499809375612],
						[60.941158158429012, 3.6644501182554037],
						[60.941158207610158, 3.6644501784394881],
						[60.942455508378956, 3.6651223775000803],
						[60.942455541214912, 3.6651223886378217],
						[60.942455609951573, 3.6651223974509288],
						[60.942455644958414, 3.6651223952762879],
						[60.949044445281977, 3.6635973956661236],
						[60.949044455041523, 3.6635973921774432],
						[60.94904447366671, 3.6635973853501898],
						[60.949044483426256, 3.6635973818615262],
						[60.956063978170079, 3.6603834839429066],
						[60.961361173185729, 3.6583084861656681],
						[60.961361196060658, 3.6583084730651518],
						[60.961361238909703, 3.6583084417993632],
						[60.961361257989992, 3.6583084237843813],
						[60.966891857566374, 3.6512334227256673],
						[60.966891876661393, 3.6512333824864021],
						[60.966891894820989, 3.6512332961252865],
						[60.966891893958724, 3.6512332518422697],
						[60.964839119773593, 3.642958355258453],
						[60.977046995567783, 3.6294887372665765],
						[60.977119235251259, 3.6295945138610692],
						[60.977119294439028, 3.629594553847435],
						[60.977119424384924, 3.629594585567693],
						[60.977119542392451, 3.6295945211616618],
						[60.977119586849845, 3.6295943951253875],
						[60.977119584878196, 3.6295943232384045],
						[60.97706688529194, 3.6294666230570973],
						[60.977066810027594, 3.6294665635690109],
						[60.977066643288417, 3.6294665343699499],
						[60.977066551887013, 3.6294665664983694],
						[60.97704704008332, 3.6294880945673671],
						[60.97479186471768, 3.6261859878863456],
						[60.974791849608046, 3.6261859700752876],
						[60.974791815960614, 3.6261859387367616],
						[60.974791797422789, 3.626185925209314],
						[60.971908397035257, 3.6245887247447914],
						[60.963841885304404, 3.6200692733148032],
						[60.961975197679827, 3.6062999739097026],
						[60.961975191136673, 3.6062999454053219],
						[60.961975167546314, 3.6062998957329975],
						[60.961975153106671, 3.6062998722713249],
						[60.955830753075006, 3.5989442726889793],
						[60.955830752033492, 3.5989442691647806],
						[60.955830750172062, 3.5989442676305861],
						[60.955830748310646, 3.5989442660963875],
						[60.949861262285637, 3.5923304807142271],
						[60.947019594237169, 3.5875610358623784],
						[60.945280799199324, 3.5719777775028696],
						[60.945280798008639, 3.5719777703060331],
						[60.945280794659325, 3.5719777542275826],
						[60.945280792574913, 3.5719777471833463],
						[60.94334459338409, 3.5649388475641146],
						[60.943344591373666, 3.5649388423580293],
						[60.943344588246518, 3.5649388317930377],
						[60.94334458623608, 3.5649388265869382],
						[60.933747387639713, 3.5408443311932327],
						[60.924155790063161, 3.5117638376873144],
						[60.924155692778491, 3.5117637694210289],
						[60.924155502125117, 3.5117637727301831],
						[60.924155407862735, 3.5117638444598183],
						[60.918505410857492, 3.5315305359146363],
						[60.914688716278754, 3.5408805226203373],
						[60.908188731129471, 3.5559692851599851],
						[60.898388757591398, 3.5659081603746086],
						[60.898388753271327, 3.5659081648063777],
						[60.89838874463117, 3.5659081736699361],
						[60.89838874127912, 3.5659081797838725],
						[60.89255549290948, 3.573577610699906],
						[60.886988877774861, 3.5741859017140283],
						[60.886988852824224, 3.5741859078094285],
						[60.886988804113344, 3.5741859271841254],
						[60.886988781172704, 3.5741859384772088],
						[60.880008247209943, 3.5793608890874533],
						[60.873969428834428, 3.5784887022572049],
						[60.8739694071621, 3.5784887004051105],
						[60.873969365007632, 3.5784887038821025],
						[60.873969342663848, 3.5784887076818714],
						[60.866786497861604, 3.5806079741156362],
						[60.871800165099565, 3.5710862165023776],
						[60.878150129763327, 3.5641751533568025],
						[60.888286208382631, 3.5576668671916329],
						[60.888286217171029, 3.5576668619969092],
						[60.888286233779731, 3.5576668499260959],
						[60.888286242419341, 3.5576668410633054],
						[60.89551684234376, 3.5504279411155388],
						[60.895516846663291, 3.550427936682103],
						[60.895516856940823, 3.5504279238405507],
						[60.895516862079567, 3.5504279174197855],
						[60.904047362298265, 3.5387223178900999],
						[60.90404737473299, 3.5387222917371388],
						[60.904047394165659, 3.5387222385160539],
						[60.904047400195253, 3.538722209766334],
						[60.904489099303525, 3.5298389217278978],
						[60.905747397083552, 3.5219694315573626],
						[60.905747396329623, 3.5219693910349434],
						[60.905747377991972, 3.5219693165816115],
						[60.905747361301906, 3.521969282496948],
						[60.902416860844745, 3.5174359811736191],
						[60.902416798946128, 3.5174359419455401],
						[60.902416663842665, 3.5174359171700846],
						[60.902416590637799, 3.5174359316226904],
						[60.901175009529034, 3.5182386553987004],
						[60.893236221295908, 3.5121776416251711],
						[60.893236211984338, 3.5121776339938746],
						[60.893236190830294, 3.5121776228615724],
						[60.893236180700086, 3.5121776172184305],
						[60.891405743868013, 3.5113692453091216],
						[60.889544587791285, 3.5078777272077937],
						[60.888119594072634, 3.5020943528391615],
						[60.886139109057723, 3.4937222155580767],
						[60.888802985559522, 3.4870083737969999],
						[60.888802991136608, 3.487008356209782],
						[60.888802997898104, 3.4870083236424239],
						[60.888802999900747, 3.4870083066737867],
						[60.888964098877686, 3.4826306399059885],
						[60.890786252898288, 3.4778307621358775],
						[60.894227859733917, 3.476747390755452],
						[60.894227886239818, 3.4767473787700069],
						[60.894227932618925, 3.4767473467131933],
						[60.894227954279351, 3.4767473263318744],
						[60.896561254020511, 3.4739168263263376],
						[60.896561265108424, 3.4739168114707626],
						[60.89656128184717, 3.4739167808556783],
						[60.896561290103229, 3.473916762796756],
						[60.898205778852223, 3.4689889953780297],
						[60.900730759092262, 3.4656751210600816],
						[60.900730775677722, 3.4656750867676998],
						[60.900730795960257, 3.4656750111849455],
						[60.900730797870196, 3.4656749702052019],
						[60.899897401610765, 3.4599138965993759],
						[60.900350199240492, 3.4560306234209506],
						[60.90035019922464, 3.4560306012522712],
						[60.900350193755742, 3.4560305560139706],
						[60.900350189272089, 3.4560305346231988],
						[60.898616904214919, 3.4510860785411688],
						[60.898875199260345, 3.4489000230139149],
						[60.898875198198013, 3.4488999973334709],
						[60.898875189666697, 3.4488999433943239],
						[60.898875183243049, 3.4488999186485096],
						[60.896611282846138, 3.4438138182481297],
						[60.896611236783926, 3.4438137708620387],
						[60.896611126368562, 3.4438137125347121],
						[60.896611060304245, 3.443813703739468],
						[60.892641660165104, 3.4446304046678087],
						[60.892641614308083, 3.444630427443649],
						[60.892641539589171, 3.4446304921973456],
						[60.892641510727223, 3.4446305341752081],
						[60.890030421986182, 3.4520832032403965],
						[60.887622086815618, 3.4551554196703158],
						[60.886202756634823, 3.4554665046409885],
						[60.886202725663168, 3.4554665174181642],
						[60.88620267035374, 3.4554665510520537],
						[60.886202645122388, 3.4554665720642457],
						[60.883688808977311, 3.458530396660227],
						[60.882683583199665, 3.458488712522465],
						[60.885664086568411, 3.4504472700766513],
						[60.889966886679943, 3.4389194697808017],
						[60.889966893049589, 3.438919428033794],
						[60.88996688687245, 3.4389193441481107],
						[60.889966875295272, 3.4389193036872099],
						[60.887700174615382, 3.4348082029411695],
						[60.887700132428698, 3.4348081622909508],
						[60.887700028568929, 3.4348081102515438],
						[60.887699968835072, 3.4348081022174259],
						[60.884555662131156, 3.4352941880023868],
						[60.881789045245874, 3.4328692609978435],
						[60.878980765015122, 3.4293470860714046],
						[60.876291884970236, 3.4248582198206075],
						[60.875555791595374, 3.4222721451306888],
						[60.875555752369479, 3.422272089223235],
						[60.875555646773549, 3.4222720172370842],
						[60.875555581296972, 3.4222720010020327],
						[60.871111139692708, 3.4227053957836198],
						[60.864808403665052, 3.41941372860301],
						[60.856505714675791, 3.4136137363663166],
						[60.856505708192266, 3.4136137319663784],
						[60.856505695225195, 3.4136137231665193],
						[60.856505688817997, 3.4136137205983745],
						[60.85143898889477, 3.411105421753847],
						[60.851438968703185, 3.4111054123770144],
						[60.851438927808339, 3.4111054029372583],
						[60.851438907922201, 3.4111054008863371],
						[60.844044417624978, 3.4108220019243465],
						[60.8358861277733, 3.4096554028940922],
						[60.835886090910606, 3.4096554038173772],
						[60.835886021370186, 3.4096554196828084],
						[60.835885986905424, 3.4096554349378705],
						[60.826286059611739, 3.4162164858529849],
						[60.819611147067505, 3.4146192056066824],
						[60.819611118169981, 3.4146192032871063],
						[60.819611060680053, 3.4146192059666243],
						[60.819611032163962, 3.4146192127954009],
						[60.81716663235855, 3.4154998122124431],
						[60.817166599015245, 3.4154998327836346],
						[60.81716654172142, 3.415499883341425],
						[60.817166518816975, 3.415499916830909],
						[60.815666518789769, 3.4187249153236356],
						[60.815666517155194, 3.4187249192947142],
						[60.815666513886057, 3.4187249272368874],
						[60.815666512175248, 3.4187249293785098],
						[60.814199895091079, 3.4226803071713223],
						[60.811080794179773, 3.4208554816964547],
						[60.810886300087915, 3.4174443897384008],
						[60.810886281717238, 3.4174443376724666],
						[60.810886224217683, 3.4174442537493732],
						[60.810886183149265, 3.4174442185460099],
						[60.807111223171745, 3.415719235905514],
						[60.804177951242089, 3.4123220684484874],
						[60.804177911371241, 3.4123220404176435],
						[60.804177820853312, 3.4123220065030089],
						[60.804177772069572, 3.412322002135638],
						[60.798122296549487, 3.4131830836578883],
						[60.795961265690821, 3.4099387897775988],
						[60.795961239298542, 3.4099387612416812],
						[60.795961175432737, 3.409938718998617],
						[60.795961138776356, 3.4099387033069481],
						[60.788513939309148, 3.4084387038659618],
						[60.78851392575384, 3.4084387025531409],
						[60.788513897749731, 3.4084387000836731],
						[60.788513884270785, 3.4084387005987273],
						[60.783422183953313, 3.4088498003505383],
						[60.783422167870178, 3.4088498031612615],
						[60.783422137567236, 3.4088498102980007],
						[60.783422123423797, 3.408849816451597],
						[60.774152839375077, 3.4127441666924767],
						[60.768091834279794, 3.4072915503500631],
						[60.768091772070569, 3.4072915262570809],
						[60.768091645834232, 3.4072915207099341],
						[60.768091580913584, 3.4072915394118635],
						[60.763394307956339, 3.4107526184010455],
						[60.759647246631495, 3.4119414748636117],
						[60.754830730619112, 3.4077526498010196],
						[60.754830669303537, 3.4077526255597257],
						[60.754830543960587, 3.4077526198542216],
						[60.754830480009545, 3.4077526402159863],
						[60.752985980037856, 3.4091359397554841],
						[60.752985961067417, 3.4091359596191033],
						[60.752985927670423, 3.4091360003924867],
						[60.752985915183537, 3.4091360246420477],
						[60.750199814604755, 3.4159471235787171],
						[60.750199809036104, 3.4159471411004119],
						[60.750199801625293, 3.4159471791723108],
						[60.750199799706948, 3.4159471978967741],
						[60.750169200625244, 3.4193416438629596],
						[60.744213728844336, 3.4291804968209805],
						[60.744213724680776, 3.4291805048993633],
						[60.744213717171128, 3.4291805190753597],
						[60.744213713977118, 3.429180528823832],
						[60.742819231838929, 3.4327665809857568],
						[60.737930470359494, 3.4369415478002221],
						[60.737930461727629, 3.4369415566513828],
						[60.737930446175078, 3.4369415722184935],
						[60.737930437619156, 3.4369415828947592],
						[60.733485937599788, 3.4430748838462137],
						[60.73348592411066, 3.4430749707324799],
						[60.733485970995076, 3.4430751243504054],
						[60.733486033079934, 3.4430751889474083],
						[60.74198602710198, 3.4460779864944824],
						[60.749999920102276, 3.4495501840656524],
						[60.749999931052457, 3.449550187685416],
						[60.749999951983497, 3.4495501932539496],
						[60.749999961964328, 3.4495501952027072],
						[60.755616620143236, 3.4506223872275985],
						[60.760033148057381, 3.4537112379100789],
						[60.7644581253562, 3.4616583963970076],
						[60.764458212151283, 3.4616584494552693],
						[60.764458385899694, 3.4616584506784385],
						[60.764458472928602, 3.4616584006700388],
						[60.774086199298388, 3.4450419286104301],
						[60.777163690959185, 3.4457695554617929],
						[60.777085902721936, 3.4472249376560202],
						[60.776183130877207, 3.4486470924776982],
						[60.77618311853481, 3.4486471203849067],
						[60.776183102937281, 3.4486471783039327],
						[60.776183099682164, 3.4486472083157649],
						[60.77638309543039, 3.4533915915008069],
						[60.77357769562478, 3.455099828849673],
						[60.773577667862995, 3.4550998539022406],
						[60.773577624410201, 3.4550999129579947],
						[60.773577606932022, 3.45509994727079],
						[60.772477624797517, 3.4590637844411227],
						[60.770027675090688, 3.4610276435643428],
						[60.770027657005897, 3.4610276632541637],
						[60.770027626349155, 3.4610277053600873],
						[60.770027612883574, 3.4610277279308108],
						[60.769058112720259, 3.4635388285493054],
						[60.769058106932093, 3.4635388626706023],
						[60.769058105260875, 3.4635389310401874],
						[60.769058111165002, 3.4635389649794339],
						[60.770035910827538, 3.4663722650007616],
						[60.770035926409442, 3.4663722935884458],
						[60.770035965388949, 3.4663723438926026],
						[60.770035987892989, 3.4663723657635495],
						[60.773091514995258, 3.4684390161302887],
						[60.773755396846035, 3.4730777923312286],
						[60.772588704943814, 3.4782499560625575],
						[60.772588703016858, 3.4782499747969107],
						[60.772588700874806, 3.4782500101301106],
						[60.77258870162872, 3.4782500284020093],
						[60.773141502435706, 3.4821583287476812],
						[60.773141591473212, 3.4821584146111788],
						[60.77314178481744, 3.4821584328079696],
						[60.773141889124211, 3.4821583651412382],
						[60.775700174124154, 3.4748557085150908],
						[60.778447253715115, 3.4720502302926173],
						[60.780041633639243, 3.4726029884724792],
						[60.780041707367722, 3.4726029867809363],
						[60.780041831113955, 3.4726029322744689],
						[60.780041882100804, 3.4726028811328002],
						[60.781925162419391, 3.4684279282057933],
						[60.783113889396589, 3.4675058619510235],
						[60.784633165466317, 3.4689029476961371],
						[60.784633256412242, 3.4689029706322065],
						[60.78463341770054, 3.4689029317029556],
						[60.784633488042857, 3.4689028698376361],
						[60.787733481048107, 3.4604000875889929],
						[60.793150170250065, 3.4517501058205875],
						[60.793150171067886, 3.4517501038372922],
						[60.793150173597077, 3.4517500997156834],
						[60.793150175232682, 3.4517500957490785],
						[60.796944529179697, 3.444852979477794],
						[60.798605636137985, 3.4445529967311299],
						[60.798605640605786, 3.4445529959548606],
						[60.798605653115636, 3.4445529937813069],
						[60.798605659294701, 3.4445529908659629],
						[60.800691759201094, 3.4439084907992834],
						[60.800691801333159, 3.4439084650587506],
						[60.80069186696732, 3.4439083983965628],
						[60.800691892180659, 3.4439083553356378],
						[60.802216892311492, 3.4386222540857325],
						[60.802216893794785, 3.4386222464598237],
						[60.80221689839658, 3.4386222272396214],
						[60.802216898834423, 3.4386222161116509],
						[60.802683494845667, 3.4331694698446462],
						[60.806283430770208, 3.427841863865277],
						[60.808538813977108, 3.4269585483631624],
						[60.810002616092184, 3.4294444816261498],
						[60.810519209940082, 3.4310444614116768],
						[60.810519220833839, 3.4310444853077851],
						[60.810519247830484, 3.4310445285073232],
						[60.810519264826858, 3.4310445476550728],
						[60.812452569664472, 3.4328222604942997],
						[60.811663739002562, 3.435224849675822],
						[60.807374924066153, 3.4369998151271286],
						[60.807374908210235, 3.4369998234136112],
						[60.807374880148558, 3.4369998411935576],
						[60.807374866155676, 3.4369998509980633],
						[60.804405466191476, 3.4396776505217947],
						[60.80440544483988, 3.4396776781829468],
						[60.80440541226951, 3.4396777391110613],
						[60.804405401944258, 3.4396777722226171],
						[60.804016506411479, 3.4425805399589122],
						[60.801902636203273, 3.4467498803114331],
						[60.798191557618949, 3.4504915584371765],
						[60.798191542368329, 3.450491581342904],
						[60.798191516334995, 3.4504916263789309],
						[60.798191505703798, 3.4504916521663507],
						[60.796780427878339, 3.456269262831293],
						[60.793802712413793, 3.4577276195762483],
						[60.793802696632063, 3.4577276296769908],
						[60.793802668642954, 3.45772764925908],
						[60.793802655693348, 3.4577276625519318],
						[60.791110955775281, 3.4605359625456811],
						[60.791110938735684, 3.4605359857509859],
						[60.791110912849987, 3.4605360344251461],
						[60.791110904079559, 3.4605360617221677],
						[60.790369204134656, 3.4643943621280116],
						[60.790369203241063, 3.4643943622826603],
						[60.790369203392252, 3.4643943659389711],
						[60.790369203467833, 3.4643943677671296],
						[60.790047003302071, 3.4663249669527461],
						[60.79004700817422, 3.4663250195052262],
						[60.790047047256053, 3.4663251158523218],
						[60.790047078709343, 3.4663251582825758],
						[60.79123047937469, 3.46722515878916],
						[60.7912305341993, 3.4672251787660833],
						[60.791230646956485, 3.4672251850391858],
						[60.791230703995502, 3.4672251714899205],
						[60.796827701220991, 3.4638058943178343],
						[60.79745809726456, 3.4654111293174856],
						[60.797122001435945, 3.4695277839993106],
						[60.797122000555085, 3.4695278062511257],
						[60.797122007880347, 3.4695278528664031],
						[60.797122014223788, 3.4695278757103845],
						[60.798116478409355, 3.4719971847181048],
						[60.79275263029416, 3.4812581941230523],
						[60.78295813259318, 3.4960415897114947],
						[60.782958131774684, 3.4960415916930661],
						[60.782958130137715, 3.4960415956561701],
						[60.782958129244079, 3.4960415958098912],
						[60.772485928723633, 3.5133387957359297],
						[60.772485915329177, 3.5133388421948819],
						[60.772485910287919, 3.5133389387457821],
						[60.772485919384977, 3.5133389850299839],
						[60.776074818816674, 3.5208972867377604],
						[60.7760749102085, 3.5208973428654153],
						[60.776075091109661, 3.5208973431831008],
						[60.776075181437854, 3.5208972853926275],
						[60.785697380955966, 3.5005306856328913],
						[60.785697380880855, 3.5005306838048922],
						[60.785697381699414, 3.500530681823276],
						[60.785697382593064, 3.5005306816696624],
						[60.78866686212978, 3.4938473278542044],
						[60.793491821429754, 3.4901584594548392],
						[60.79349184383647, 3.4901584353395245],
						[60.79349188030622, 3.4901583811807866],
						[60.793491892582033, 3.4901583514452623],
						[60.795083477830765, 3.484269508218421],
						[60.798616744733948, 3.4810113302105576],
						[60.800086031393732, 3.4815557877908887],
						[60.800086049642879, 3.4815557938500752],
						[60.800086090383175, 3.4815557997109754],
						[60.800086110193455, 3.4815557999754092],
						[60.801388909607049, 3.4814890997251755],
						[60.801388963740791, 3.4814890811721813],
						[60.801389051961081, 3.4814890162150052],
						[60.801389087016638, 3.4814889714854163],
						[60.802547387385161, 3.47849727216476],
						[60.802547391992746, 3.4784972529497855],
						[60.802547397633496, 3.478497215137168],
						[60.802547400378444, 3.4784971944019674],
						[60.802375199685045, 3.4715638953554842],
						[60.802375199307534, 3.4715638862111526],
						[60.802375197809916, 3.4715638717347574],
						[60.802375195645205, 3.4715638628994765],
						[60.801653003351142, 3.4677666963507821],
						[60.802091890950152, 3.4648667752218776],
						[60.810014064549115, 3.4532723126613267],
						[60.819677964159887, 3.4393084144515731],
						[60.828730388686211, 3.4266534396101767],
						[60.82862200108611, 3.4328499656355618],
						[60.827588711387556, 3.4358499357425769],
						[60.827588708193424, 3.435849945516273],
						[60.827588702850704, 3.4358499685683705],
						[60.827588700626123, 3.4358499800165743],
						[60.826994201280932, 3.4416638792107728],
						[60.826994272339071, 3.4416639866642638],
						[60.826994460055317, 3.4416640424767397],
						[60.82699457678936, 3.4416639926659003],
						[60.830680773040541, 3.4346334003374492],
						[60.835175143671989, 3.427755745785388],
						[60.839463989792364, 3.425002980914746],
						[60.841847059840269, 3.424122465136398],
						[60.842466494148546, 3.4265806019427392],
						[60.841058107276893, 3.4326082476485298],
						[60.839533108657371, 3.4374166392137742],
						[60.839533105767124, 3.4374166563145572],
						[60.839533100880224, 3.4374166903604357],
						[60.839533099777086, 3.4374167071497879],
						[60.83996649816801, 3.4476527515630453],
						[60.828224827246132, 3.4681054990838307],
						[60.828224815971396, 3.4681055747736211],
						[60.828224849340366, 3.4681057072500914],
						[60.828224893317199, 3.4681057696825359],
						[60.835166592967695, 3.4725251698521862],
						[60.835166655683132, 3.4725251848078349],
						[60.835166779377175, 3.4725251726121118],
						[60.835166837675018, 3.4725251459248545],
						[60.837744446055346, 3.4700752317225589],
						[60.841994262320718, 3.4717057606768922],
						[60.847230340592517, 3.4807999514706824],
						[60.842333174671424, 3.4847415437816056],
						[60.842333168566846, 3.4847415485248474],
						[60.842333159113835, 3.4847415593793074],
						[60.842333153009214, 3.4847415641225545],
						[60.837438753169117, 3.490016563706464],
						[60.83743874885154, 3.4900165681394877],
						[60.837438740366949, 3.490016580667362],
						[60.837438735231053, 3.4900165870855209],
						[60.827763736860618, 3.5040942846338292],
						[60.815516567770665, 3.5208526414043551],
						[60.808849945136615, 3.5240942047297104],
						[60.801683281047637, 3.5247665002030981],
						[60.801683254386781, 3.5247665084522231],
						[60.80168320121458, 3.5247665286084668],
						[60.801683176565426, 3.5247665420384924],
						[60.798586030960017, 3.5271886994443391],
						[60.790344400148733, 3.5272053992941133],
						[60.790344391211882, 3.5272054008231062],
						[60.790344374157129, 3.5272054018998289],
						[60.790344366113949, 3.5272054032759237],
						[60.782986101034588, 3.5284719967517182],
						[60.777791826950555, 3.5275248195979052],
						[60.773716873443831, 3.5204360004184956],
						[60.77371686577068, 3.520435988850672],
						[60.773716849680355, 3.5204359695227967],
						[60.773716842082038, 3.5204359597823651],
						[60.76967514237969, 3.5163776581894273],
						[60.769675136793062, 3.5163776536264222],
						[60.769675126588325, 3.5163776461745195],
						[60.769675121895339, 3.5163776414584476],
						[60.762325121839716, 3.5107276416396243],
						[60.762325104335481, 3.5107276317631624],
						[60.762325067539706, 3.510727612316598],
						[60.762325048398033, 3.5107276063999069],
						[60.754763956749997, 3.5088387088813597],
						[60.743258384300958, 3.5048442164721472],
						[60.729186208917675, 3.49661923111824],
						[60.71930843594378, 3.4895470511247648],
						[60.70882236898958, 3.4773637881576747],
						[60.702377983892532, 3.4640193206347258],
						[60.695847387320917, 3.4465555302520627],
						[60.695847320846319, 3.4465554683154553],
						[60.69584716516556, 3.4465554254790534],
						[60.695847075883613, 3.4465554427565261],
						[60.68534991430802, 3.4548609128057897],
						[60.679772241844418, 3.4559691878418017],
						[60.677236249009574, 3.4542942582153602],
						[60.672986273414438, 3.4469887995760367],
						[60.672986159883649, 3.4469887531485104],
						[60.672985973882689, 3.4469888036307283],
						[60.672985899625296, 3.4469889008492607],
						[60.672980399627882, 3.4544916992294437],
						[60.672980401202246, 3.4544917154684653],
						[60.672980406138137, 3.4544917476381625],
						[60.672980410317642, 3.4544917615931889],
						[60.674872010166226, 3.4602583616964799],
						[60.674872019115391, 3.4602583821692798],
						[60.674872041406175, 3.4602584205231275],
						[60.674872053778621, 3.4602584367365878],
						[60.682438749774875, 3.4683084324675466],
						[60.68695540964309, 3.4736944849051925],
						[60.688722002026587, 3.4862389288668481],
						[60.688722074672945, 3.4862390100001899],
						[60.688722252166855, 3.4862390584524561],
						[60.688722356939159, 3.4862390239489298],
						[60.68992212189336, 3.4847171219149291],
						[60.694802609124636, 3.5002972599033666],
						[60.694802615161905, 3.5002972753906443],
						[60.694802629842336, 3.5002973040832335],
						[60.694802639379191, 3.5002973171354896],
						[60.699652608623019, 3.506919478598705],
						[60.701822001676462, 3.5217889292261204],
						[60.701822065216234, 3.5217890083507988],
						[60.70182222616819, 3.5217890708532842],
						[60.701822326336185, 3.5217890555968987],
						[60.703247143211321, 3.5206392036344858],
						[60.706216497408917, 3.5258500471400036],
						[60.705727600441833, 3.5338277879119588],
						[60.70572759977189, 3.5338277935348277],
						[60.705727600293983, 3.5338278062997746],
						[60.705727599698612, 3.5338278137462233],
						[60.70618309973343, 3.5406306134968109],
						[60.706183105170403, 3.5406306364437587],
						[60.706183119693762, 3.5406306835532306],
						[60.706183129599346, 3.5406307057401523],
						[60.706647029960571, 3.5413779044494538],
						[60.706647114715089, 3.541377952470711],
						[60.706647284819589, 3.5413779529240124],
						[60.70664737016957, 3.541377905356093],
						[60.713152341269812, 3.5308705212938274],
						[60.710977605213905, 3.5404721562115955],
						[60.710977605213905, 3.5404721562115955],
						[60.709560905030997, 3.5469527561981269],
						[60.709560902870692, 3.5469527694204128],
						[60.709560900263106, 3.5469527937374892],
						[60.709560899890228, 3.5469528066560865],
						[60.70990809989086, 3.5588472060143355],
						[60.709908166610809, 3.5588472975451331],
						[60.709908342815773, 3.5588473594958443],
						[60.709908452300787, 3.5588473299157588],
						[60.711108114708452, 3.5574449223709697],
						[60.711508099564313, 3.5709139059845558],
						[60.711508099712475, 3.5709139096325759],
						[60.71150810090257, 3.5709139167773882],
						[60.711508100976666, 3.5709139186014061],
						[60.712624800862685, 3.582600019202101],
						[60.712624802052176, 3.5826000263476225],
						[60.712624805398853, 3.582600042311928],
						[60.712624806662262, 3.582600051281569],
						[60.716616506837489, 3.5975611520054467],
						[60.716616509961234, 3.5975611625002379],
						[60.716616518963882, 3.5975611848627267],
						[60.716616522907707, 3.5975611933826639],
						[60.720383122876918, 3.6046361937876719],
						[60.720383126746682, 3.6046362004848658],
						[60.72038313433896, 3.6046362102300238],
						[60.720383137241278, 3.6046362152529077],
						[60.72307763702856, 3.608397315103101],
						[60.723077655861445, 3.6083973358206696],
						[60.723077697701974, 3.6083973692056035],
						[60.723077720783209, 3.6083973836977372],
						[60.730327702675503, 3.6115057753296473],
						[60.731847018461238, 3.6125445184365961],
						[60.732583099368455, 3.6167000101231639],
						[60.732224802935662, 3.6225055503017032],
						[60.723969243211478, 3.6405637622964191],
						[60.71861101708528, 3.6439581167818744],
						[60.713144344874465, 3.6455248076360123],
						[60.713144322746906, 3.6455248168372663],
						[60.713144283180334, 3.6455248399671079],
						[60.713144263880508, 3.6455248523696184],
						[60.705669264030035, 3.6524526532875461],
						[60.705669263136159, 3.652452653436439],
						[60.705669259706589, 3.6524526576798451],
						[60.705669258812691, 3.6524526578287397],
						[60.701355459471863, 3.6567359585545027],
						[60.701355449930723, 3.6567359674854756],
						[60.701355433675921, 3.6567359885485811],
						[60.70135542696228, 3.6567360006806933],
						[60.694655427293284, 3.6683027004929416],
						[60.694655413094388, 3.6683027505708132],
						[60.69465541255326, 3.6683028497732204],
						[60.694655427177651, 3.6683029005727135],
						[60.696799826630823, 3.6720362001972817],
						[60.696799837118142, 3.6720362149772954],
						[60.69679986070183, 3.6720362422689434],
						[60.696799873798177, 3.67203625478058],
						[60.699974848557943, 3.6746057343838845],
						[60.701427600874396, 3.6768556617785824],
						[60.701469200266153, 3.679158281127433],
						[60.700960904208458, 3.6816777598243342],
						[60.700960931224181, 3.681677850809332],
						[60.700961050195509, 3.6816779687868522],
						[60.70096114125721, 3.6816779959274095],
						[60.707136038981069, 3.6803919169059696],
						[60.710122062713886, 3.6832001466149467],
						[60.710122087648031, 3.6832001626840087],
						[60.710122142662193, 3.6832001884609409],
						[60.710122171848298, 3.6832001983168903],
						[60.715324972188178, 3.6839279978967787],
						[60.715325020605349, 3.683927993549641],
						[60.715325109272548, 3.6839279604961734],
						[60.715325148701197, 3.6839279337624844],
						[60.724797348676844, 3.6733390333202207],
						[60.724797356431239, 3.6733390246840849],
						[60.724797368364378, 3.6733390080055313],
						[60.724797374330933, 3.6733389996662744],
						[60.729389058369925, 3.6653057274302325],
						[60.73268070806941, 3.6623057716047778],
						[60.739761174436069, 3.6594807868422974],
						[60.73976121796408, 3.6594807501830089],
						[60.739761281050015, 3.6594806624772622],
						[60.739761299641145, 3.6594806097538384],
						[60.739908499536369, 3.6565750096033898],
						[60.739908499992602, 3.6565749984991678],
						[60.739908498077575, 3.656574973085946],
						[60.739908495779218, 3.6565749606028017],
						[60.739286296268276, 3.6535166603390179],
						[60.739286292108908, 3.6535166463286743],
						[60.739286283042269, 3.6535166221086959],
						[60.739286277989031, 3.6535166082474202],
						[60.737953003293782, 3.6509027574520805],
						[60.73811129661383, 3.6478806536883246],
						[60.740883445166325, 3.6425501551754182],
						[60.744158236576006, 3.6411224465536751],
						[60.74737204193741, 3.645272322416226],
						[60.747372075432352, 3.6452723499124366],
						[60.747372148988418, 3.6452723890999765],
						[60.74737219083724, 3.6452724004926127],
						[60.748852791050666, 3.6453419001487157],
						[60.748852857652025, 3.6453418779890177],
						[60.748852960569792, 3.6453417964429851],
						[60.748852996813078, 3.6453417352302928],
						[60.749044597369917, 3.6442583344609236],
						[60.749044597678854, 3.6442583197006897],
						[60.749044599044417, 3.6442582863781423],
						[60.749044598386412, 3.6442582699409551],
						[60.748705819957813, 3.6420557170744834],
						[60.753411183444022, 3.6398445826596082],
						[60.761913981296374, 3.6361057834412227],
						[60.761914022208877, 3.6361057489997703],
						[60.761914081775863, 3.6361056636118079],
						[60.761914100430374, 3.6361056126652724],
						[60.762136294110427, 3.6327167951320991],
						[60.767635895012539, 3.6278671461487666],
						[60.767605399810172, 3.6307805972562486],
						[60.767605476683322, 3.6307806965859646],
						[60.76760566568155, 3.6307807421615457],
						[60.767605778700485, 3.6307806882575493],
						[60.770386269806274, 3.6251973064938943],
						[60.771941804119479, 3.6232057918503995],
						[60.773791468262964, 3.6230446214388148],
						[60.772627607874014, 3.6316138301742762],
						[60.765335929991316, 3.6434248940983096],
						[60.765335915128887, 3.6434249499300795],
						[60.765335916762353, 3.6434250581880443],
						[60.765335934225206, 3.6434251122920474],
						[60.767199834237829, 3.6461807128040946],
						[60.767199866018139, 3.6461807424434864],
						[60.767199939720342, 3.6461807853097796],
						[60.767199981642229, 3.6461807985366961],
						[60.769980582425674, 3.6464279988711263],
						[60.769980603130755, 3.646427999088806],
						[60.769980644321663, 3.6464279940414772],
						[60.769980663986701, 3.6464279907534936],
						[60.770933363570464, 3.6461084893582281],
						[60.77093339791945, 3.6461084707357245],
						[60.770933457020845, 3.6461084185369419],
						[60.770933480879386, 3.6461083851101384],
						[60.7726862502359, 3.6424251499084499],
						[60.777572286005885, 3.6400945803618181],
						[60.777572306198593, 3.6400945677794301],
						[60.777572342789028, 3.6400945377294684],
						[60.777572359186749, 3.6400945202618828],
						[60.78273901915091, 3.6332862741438978],
						[60.786425055315085, 3.6322418921357698],
						[60.786425091449502, 3.6322418731887178],
						[60.786425153299604, 3.632241822316491],
						[60.786425178121462, 3.6322417905412605],
						[60.788355778401069, 3.6284389906237373],
						[60.788355780862517, 3.6284389846878895],
						[60.788355785785392, 3.6284389728162232],
						[60.78835578914066, 3.6284389667303105],
						[60.78993908528269, 3.6238944765270666],
						[60.792602968994473, 3.6183057091113699],
						[60.796747306431911, 3.6135084828905004],
						[60.801249692907412, 3.6126196633301353],
						[60.797102611910695, 3.6242166322945679],
						[60.797102611090288, 3.6242166342738349],
						[60.79710261026986, 3.6242166362531214],
						[60.797102610343288, 3.6242166380821477],
						[60.793794209673756, 3.6345193387176997],
						[60.79379425391248, 3.634519454655202],
						[60.793794420827709, 3.6345195555387475],
						[60.793794544324797, 3.6345195385059683],
						[60.803864043758971, 3.6240362396451284],
						[60.803864058293172, 3.6240362206255332],
						[60.803864081925234, 3.6240361816586559],
						[60.803864090949624, 3.6240361598819475],
						[60.806186253679009, 3.6166084777514187],
						[60.809205630571171, 3.6161334979068829],
						[60.809205673926883, 3.616133479552317],
						[60.809205747389505, 3.6161334266537595],
						[60.809205778390243, 3.6161333919592242],
						[60.814839065280317, 3.6052029176065021],
						[60.817708359552782, 3.6021696278170334],
						[60.820002668352245, 3.6028779680781002],
						[60.821822004170087, 3.6058778624172656],
						[60.822274799421976, 3.6125888900770975],
						[60.819874802539651, 3.626952766265501],
						[60.819874801866007, 3.6269527719068089],
						[60.819874801339182, 3.626952781208804],
						[60.819874799771725, 3.6269527870004308],
						[60.818460901162929, 3.6485332817011003],
						[60.816744202539361, 3.6638971654050501],
						[60.812772007909814, 3.680977743304267],
						[60.8086192123817, 3.6924499325888633],
						[60.808619221152711, 3.6924500177033206],
						[60.808619302936414, 3.6924501514800072],
						[60.808619375876582, 3.6924501983123665],
						[60.810330575986569, 3.6926557984634534],
						[60.810330617252198, 3.6926557952965022],
						[60.810330694588856, 3.6926557714047341],
						[60.810330730659871, 3.692655750679922],
						[60.812722331136015, 3.6905807517118285],
						[60.81272234239438, 3.6905807406309319],
						[60.812722362978214, 3.6905807151058765],
						[60.812722372376207, 3.6905807024918307],
						[60.817133471952339, 3.6831251015901265],
						[60.817133475310101, 3.6831250955041912],
						[60.817133481204316, 3.6831250853114263],
						[60.817133483668179, 3.6831250793742112],
						[60.820902983733667, 3.6743056798174374],
						[60.820902989408921, 3.6743056641302023],
						[60.820902996362683, 3.6743056353312245],
						[60.820902999356242, 3.6743056200909683],
						[60.822419594371119, 3.6599028674258673],
						[60.82447515117471, 3.6565918352559748],
						[60.826861189687655, 3.6547557837623268],
						[60.829649711762009, 3.654033581134998],
						[60.82668870793389, 3.6644805455422791],
						[60.826688754757342, 3.6644806593607404],
						[60.826688925009599, 3.6644807544070019],
						[60.826689048438453, 3.6644807356347937],
						[60.832214047819193, 3.6584112348178457],
						[60.832214054678246, 3.6584112262972894],
						[60.832214068323438, 3.6584112074250474],
						[60.832214074288629, 3.6584111990539889],
						[60.833841874093778, 3.6555639995320997],
						[60.833841878271059, 3.6555639914592537],
						[60.833841884837845, 3.655563975612766],
						[60.83384188812127, 3.6555639676895098],
						[60.836883470785118, 3.6470695159409758],
						[60.842327932735088, 3.6422057498894143],
						[60.842327943989083, 3.6422057387808793],
						[60.842327963669135, 3.6422057133505277],
						[60.842327972168377, 3.6422057008603734],
						[60.846439066616334, 3.6352140111086571],
						[60.851236233664416, 3.6288584547074603],
						[60.855647252191453, 3.6263808001760118],
						[60.858788899419729, 3.6264029999703573],
						[60.858788958938746, 3.626402980720179],
						[60.85878905587672, 3.6264029071988393],
						[60.858789093369126, 3.6264028547602329],
						[60.860505787397116, 3.6202722732048702],
						[60.863294549450799, 3.6147945486098401],
						[60.865013820882695, 3.613900267787141],
						[60.865716525825661, 3.6151500978948699],
						[60.865716604374903, 3.6151501492195028],
						[60.865716767772845, 3.6151501622282716],
						[60.865716853662541, 3.615150127427349],
						[60.880883378996039, 3.5968252195266062],
						[60.882541567249319, 3.5971445782715898],
						[60.884688695028224, 3.6012583395452094],
						[60.884163702406404, 3.6062305673663162],
						[60.883227604651957, 3.6103305545978097],
						[60.883227603306395, 3.6103305659036118],
						[60.883227601435387, 3.6103305865301492],
						[60.88322760008981, 3.610330597835953],
						[60.883094199787458, 3.6181221974758393],
						[60.883094252845801, 3.6181222863724942],
						[60.883094410100831, 3.618122370597717],
						[60.883094514223963, 3.6181223640923577],
						[60.888875113612464, 3.614119564402503],
						[60.888875119721824, 3.6141195596763973],
						[60.888875131940544, 3.6141195502241743],
						[60.888875137976314, 3.6141195436638194],
						[60.893250138003786, 3.6099279442867296],
						[60.893250140611499, 3.6099279419985506],
						[60.893250145826912, 3.6099279374221713],
						[60.893250148434596, 3.6099279351339928],
						[60.89708340510559, 3.6057140823096625],
						[60.899869411338408, 3.6051058012972961],
						[60.901763621245365, 3.6053112705925097],
						[60.899447012861913, 3.6114277291350305],
						[60.89944701699703, 3.6114278097171741],
						[60.899447080692433, 3.6114279393359134],
						[60.89944714218764, 3.6114279917397676],
						[60.902288842375853, 3.6122834926659064],
						[60.902288909705256, 3.6122834886643536],
						[60.902289027401757, 3.6122834391916334],
						[60.902289076875057, 3.6122833938716941],
						[60.906327934275616, 3.6046640742242237],
						[60.913211010844634, 3.6030196276443935],
						[60.915316517029979, 3.6061639859244838],
						[60.916749781620545, 3.6109832684692367],
						[60.90819424123913, 3.6243776772104108],
						[60.899269277419343, 3.6342137386413071],
						[60.895094309274931, 3.6363387217419705],
						[60.895094303985246, 3.636338724479669],
						[60.895094293479225, 3.6363387317897469],
						[60.895094287295706, 3.6363387346779454],
						[60.892588786947741, 3.6380526349294877],
						[60.892588773012868, 3.638052646509216],
						[60.892588747971189, 3.6380526728864182],
						[60.892588735823992, 3.6380526841652645],
						[60.889941570851768, 3.6418331366491863],
						[60.882924927873013, 3.6445581141649557],
						[60.882924916399418, 3.6445581197855392],
						[60.882924894346033, 3.6445581308765229],
						[60.88292488294563, 3.644558138331103],
						[60.878627683235202, 3.6476470372673351],
						[60.878627655656139, 3.6476470677427688],
						[60.878627615165463, 3.6476471354610966],
						[60.878627602326986, 3.6476471745377501],
						[60.87668590226022, 3.6629082670635951],
						[60.875158347866609, 3.6708531742420165],
						[60.872783371949858, 3.6699304142757638],
						[60.872783315052274, 3.6699304090217346],
						[60.872783206216788, 3.6699304327523472],
						[60.872783155172804, 3.6699304615875739],
						[60.869366594273949, 3.6735026206813122],
						[60.864599948895112, 3.6747692067466953],
						[60.864599940922851, 3.6747692099229101],
						[60.864599924905541, 3.6747692144423052],
						[60.864599916933273, 3.6747692176185183],
						[60.855980517128266, 3.6786859180622273],
						[60.855980496932169, 3.6786859306538555],
						[60.855980461154871, 3.6786859587568266],
						[60.855980443785896, 3.6786859745663438],
						[60.850960944769412, 3.6849554732640875],
						[60.843433166216236, 3.6938998471803246],
						[60.831844308171242, 3.7013887212653591],
						[60.826400011544507, 3.7035469798227316],
						[60.818280693201181, 3.6992748221367151],
						[60.818280612022868, 3.6992748171768763],
						[60.818280472761224, 3.6992748679130871],
						[60.818280414677872, 3.6992749236091327],
						[60.817975084822812, 3.7000164609234187],
						[60.814664069118415, 3.6948304919347073],
						[60.81466404010213, 3.6948304635900158],
						[60.814663971704746, 3.6948304178324181],
						[60.814663933290056, 3.6948304021013558],
						[60.812830634094375, 3.6945192021804609],
						[60.810438933988934, 3.6941109027891579],
						[60.810438928552898, 3.6941109018493936],
						[60.810438920435068, 3.6941109013547235],
						[60.810438915892931, 3.6941109002665891],
						[60.808944416000209, 3.6939942000949371],
						[60.808944404306672, 3.6939942001937647],
						[60.808944380919556, 3.6939942003914616],
						[60.808944369298459, 3.6939942023202241],
						[60.805916668775936, 3.6944637022289259],
						[60.805916665200229, 3.6944637028222624],
						[60.805916655367, 3.6944637044539435],
						[60.805916650003411, 3.694463705343952],
						[60.803897151125668, 3.6949804052265653],
						[60.801955551220864, 3.6954748060776539],
						[60.798866651220443, 3.6962637062015955],
						[60.796252750876761, 3.6969304055544288],
						[60.795413850987224, 3.6971442057557367],
						[60.795413827068259, 3.6971442152444771],
						[60.795413783023314, 3.6971442391166622],
						[60.795413762075853, 3.6971442554774261],
						[60.79158876221846, 3.7007804542151477],
						[60.789588761581129, 3.7026804553298742],
						[60.786991561683536, 3.7051470546838812],
						[60.786991561755848, 3.7051470565125735],
						[60.785855462285461, 3.7062359556447633],
						[60.784035962221019, 3.7079804551713678],
						[60.782324862201648, 3.7096192554491978],
						[60.780635962421208, 3.7112387558112414],
						[60.779669261867852, 3.7121637542196866],
						[60.779669261940064, 3.712163756047981],
						[60.778855461827682, 3.7129442557412],
						[60.777935962177537, 3.713824855365639],
						[60.777080462228184, 3.7146415545525571],
						[60.777080459618475, 3.7146415568235058],
						[60.777080456186958, 3.7146415610702102],
						[60.777080453577256, 3.7146415633411749],
						[60.775683162308042, 3.7161331531206288],
						[60.774466608884879, 3.7171470146258727],
						[60.770880556043736, 3.7179498056875588],
						[60.768305556116154, 3.7185248042010035],
						[60.766527756190712, 3.7189220043380375],
						[60.765216655640636, 3.7192165051917274],
						[60.76521665385269, 3.719216505486524],
						[60.762974954290968, 3.7197470055591633],
						[60.762974950715083, 3.7197470061486451],
						[60.76297494535126, 3.719747007032868],
						[60.762974941847467, 3.7197470094497156],
						[60.759280541847772, 3.7208637098400752],
						[60.75663054229468, 3.7216637085911137],
						[60.753516642149187, 3.722605407143893],
						[60.752016642941669, 3.7230554076577982],
						[60.752016642047693, 3.723055407805012],
						[60.752016642119735, 3.7230554096317658],
						[60.751155542093102, 3.7233165084522404],
						[60.749877741555075, 3.7237026084298033],
						[60.747944342294602, 3.724285908351924],
						[60.747944318445214, 3.7242859196311984],
						[60.747944272750452, 3.7242859473750243],
						[60.74794425269306, 3.7242859635452654],
						[60.745563752636919, 3.7268637631723163],
						[60.743813753204307, 3.7287581631618125],
						[60.741822053399623, 3.7309165638531687],
						[60.739874852860162, 3.7330192646840263],
						[60.737749861310519, 3.7353220545650436],
						[60.734902671235183, 3.7377304461793317],
						[60.731847071132634, 3.7403109463714221],
						[60.729422071142423, 3.7423581463431548],
						[60.72728318481424, 3.7441665350909963],
						[60.725452701932113, 3.7451970256714175],
						[60.722574902338131, 3.7468137260040058],
						[60.719291602350211, 3.7486609265684407],
						[60.717972177244739, 3.749402584694824],
						[60.715727858575981, 3.7487081081664964],
						[60.71326945944822, 3.7479442096038609],
						[60.711044458767745, 3.7472554086040013],
						[60.706905719786093, 3.7459748284934848],
						[60.697133458881446, 3.7331387786016732],
						[60.697133451298257, 3.7331387688324509],
						[60.697133432627687, 3.7331387517040984],
						[60.697133424150479, 3.7331387420814655],
						[60.692405759815372, 3.7294026707397814],
						[60.681014080426678, 3.7060166115922306],
						[60.681014074701778, 3.7060166033623698],
						[60.6810140640737, 3.7060165849327165],
						[60.681014058348779, 3.7060165767028583],
						[60.670555766869718, 3.6926387895460318],
						[60.665133475038253, 3.682822102102699],
						[60.665133469311996, 3.6828220938809566],
						[60.665133455999133, 3.6828220759113672],
						[60.66513344930641, 3.6828220660157203],
						[60.659744549183934, 3.6768109654028303],
						[60.659744547323484, 3.6768109638773185],
						[60.659744541742107, 3.6768109593007741],
						[60.65974453988165, 3.6768109577752637],
						[60.649850150031369, 3.6670859669062499],
						[60.639300159435351, 3.6532720796943141],
						[60.639300148125336, 3.6532720669145831],
						[60.639300125651104, 3.6532720449954406],
						[60.639300113520115, 3.6532720341843459],
						[60.627136216736879, 3.6448665378370291],
						[60.617230759639334, 3.6374165700773213],
						[60.606961280584208, 3.615477714598522],
						[60.606961256843491, 3.6154776837849711],
						[60.606961199823409, 3.6154776310733281],
						[60.606961164829769, 3.6154776112921101],
						[60.597252868881618, 3.6121165118179928],
						[60.58527507313655, 3.6074442142216712],
						[60.585275002903124, 3.6074442131565432],
						[60.585274878722032, 3.6074442576945684],
						[60.585274825594716, 3.6074443013313253],
						[60.582541545186018, 3.6122776674199017],
						[60.570941586691113, 3.6215498332633294],
						[60.55635821790495, 3.6298526167217746],
						[60.534588871708195, 3.637099797946485],
						[60.519208309871757, 3.6363359001165767],
						[60.519208251988466, 3.6363359151904091],
						[60.519208153699921, 3.6363359771244554],
						[60.519208115082428, 3.6363360236882594],
						[60.515299814789216, 3.645741623400788],
						[60.515299809407068, 3.645741646194538],
						[60.515299802218358, 3.6457416911903442],
						[60.515299801305716, 3.6457417132444503],
						[60.515922000890932, 3.6543417146752382],
						[60.515922002148905, 3.6543417235937881],
						[60.515922004664837, 3.6543417414308843],
						[60.515922005922796, 3.654341750349436],
						[60.519238705667696, 3.6670611493359129],
						[60.519238710791114, 3.6670611649203391],
						[60.519238721641322, 3.6670611886879203],
						[60.519238727586007, 3.6670612023115194],
						[60.527072025917043, 3.680333398450601],
						[60.536019223618716, 3.6967972948900769],
						[60.5360192293438, 3.6967973030826919],
						[60.536019240866175, 3.6967973212824279],
						[60.536019246591238, 3.6967973294750469],
						[60.54298043134267, 3.7050862107472344],
						[60.54811921533522, 3.7155972777664168],
						[60.552119209211803, 3.7269917593176585],
						[60.55521090737377, 3.7388194510574513],
						[60.555210908411176, 3.7388194545432532],
						[60.55521091055769, 3.7388194633305321],
						[60.555210911666819, 3.7388194686320069],
						[60.559880411933705, 3.7515889694257822],
						[60.559880414830417, 3.7515889744379449],
						[60.559880420766873, 3.7515889880942419],
						[60.559880423735102, 3.7515889949223964],
						[60.562338705404642, 3.7561694591184245],
						[60.563613700013583, 3.7697667067609619],
						[60.563283100588137, 3.7861360673823614],
						[60.559585913182971, 3.7978082284990209],
						[60.552699822061811, 3.8130193077089931],
						[60.544466532866927, 3.8268331907345954],
						[60.536708137601451, 3.8374915819162836],
						[60.536708131693942, 3.8374915919903914],
						[60.536708120773113, 3.8374916119958362],
						[60.536708115759758, 3.8374916219271777],
						[60.531191529813022, 3.8504637872971506],
						[60.52321925513526, 3.8588470613133268],
						[60.523219243176747, 3.8588470778167694],
						[60.523219221942291, 3.8588471103973339],
						[60.523219212736421, 3.8588471282887009],
						[60.517238721115959, 3.8745110085517007],
						[60.511069233296361, 3.8838387907353527],
						[60.511069220788755, 3.8838388164328479],
						[60.511069203961078, 3.8838388701830442],
						[60.511069199641007, 3.8838388982357452],
						[60.510996999772935, 3.8952249317418981],
						[60.506205441779144, 3.9014220771328727],
						[60.506205434972287, 3.9014220873266212],
						[60.506205422322239, 3.9014221093867132],
						[60.506205415584816, 3.9014221213938813],
						[60.502760915769727, 3.9094638207928272],
					],
					[
						[60.804614741105141, 3.7441269255694003],
						[60.805109258943084, 3.7439785610309189],
						[60.805108709711163, 3.7439810970131417],
						[60.804614741105141, 3.7441269255694003],
					],
					[
						[60.810515570819312, 3.7220311699040818],
						[60.811292100629494, 3.7194140505326123],
						[60.810897008392764, 3.7209527417066939],
						[60.810515570819312, 3.7220311699040818],
					],
					[
						[60.81384918227662, 3.7100368047291137],
						[60.81387798476996, 3.7099667760349275],
						[60.815302983903415, 3.7065028770996036],
						[60.815342495263288, 3.7064100807083067],
						[60.81384918227662, 3.7100368047291137],
					],
				],
			],
		},
		{
			fldNpdidField: "2053062",
			wlbNpdidWellbore: "135",
			fldName: "SNØHVIT",
			fldCurrentActivitySatus: "Producing",
			wlbName: "7121/4-1",
			fldDiscoveryYear: "1984",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=2053062",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=2053062",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "SNØHVIT UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=135",
			fldHcType: "GAS",
			fldMainArea: "Barents sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "1333943",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2021-12-22 00:00:00",
			fldMainSupplyBase: "Hammerfest",
			fldAreaGeometryWKT: [
				[
					[
						[71.646652599665686, 20.983175002196983],
						[71.646749799870733, 20.992100000754363],
						[71.646805399714864, 20.999369400914873],
						[71.646805399537982, 20.999369403698029],
						[71.646805400414905, 20.999369404259475],
						[71.646805400237994, 20.999369407042625],
						[71.647063699912778, 21.007791704015233],
						[71.647163700158899, 21.014988902538853],
						[71.647163699981746, 21.014988905321868],
						[71.647305400366278, 21.02190000042895],
						[71.64726089996033, 21.027516698161818],
						[71.64726089996033, 21.027516698161818],
						[71.647260899783006, 21.027516700944719],
						[71.647241499817184, 21.035361099908503],
						[71.647241499817184, 21.035361099908503],
						[71.647241499639748, 21.035361102691322],
						[71.647310899931995, 21.042416699147875],
						[71.647271999668476, 21.048394398578449],
						[71.647072000107613, 21.062216698256773],
						[71.647072000107613, 21.062216698256773],
						[71.647072000107613, 21.062216698256773],
						[71.647071999929793, 21.062216701039297],
						[71.647077600144158, 21.071861099589729],
						[71.647010900379414, 21.079999998047064],
						[71.646927599802623, 21.089644397799006],
						[71.646927599802623, 21.089644397799006],
						[71.646927599624391, 21.089644400581221],
						[71.646927599624391, 21.089644400581221],
						[71.646949800355074, 21.098499997498891],
						[71.646774800391015, 21.108144396059501],
						[71.646774800212526, 21.108144398841507],
						[71.646774800212526, 21.108144398841507],
						[71.646774800034038, 21.108144401623498],
						[71.646821999734271, 21.116205598587857],
						[71.646755399639176, 21.125063898521457],
						[71.646755399639176, 21.125063898521457],
						[71.646755400336971, 21.125063901870558],
						[71.646802600408805, 21.13211670098897],
						[71.646802600408805, 21.13211670098897],
						[71.646802600229961, 21.132116703770723],
						[71.646802600229961, 21.132116703770723],
						[71.646933100083785, 21.13888330272782],
						[71.646933126841859, 21.138883369325054],
						[71.646933219174358, 21.138883466819262],
						[71.646933352667446, 21.13888347797452],
						[71.646933459259969, 21.138883396361422],
						[71.646933497057262, 21.138883333922063],
						[71.648464097063055, 21.130388936829078],
						[71.649805796410391, 21.123041736570407],
						[71.651125196197341, 21.116416739044123],
						[71.651125196197341, 21.116416739044123],
						[71.651125197431128, 21.116416734046027],
						[71.651125197431128, 21.116416734046027],
						[71.652583496981578, 21.10791393546269],
						[71.653627996159713, 21.102658342480595],
						[71.654677893247793, 21.098114385665031],
						[71.65572483598099, 21.099625115110605],
						[71.655724842815388, 21.09962512242496],
						[71.655724858935599, 21.099625141536212],
						[71.655724868399787, 21.099625150549684],
						[71.656702667658152, 21.100486250108403],
						[71.656702702023694, 21.100486269416091],
						[71.656702777084647, 21.100486294728956],
						[71.656702816026893, 21.100486299601233],
						[71.658486115657453, 21.100339098660253],
						[71.658486157601587, 21.100339085179307],
						[71.658486233259026, 21.100339044201053],
						[71.658486265397542, 21.100339012786979],
						[71.660461265360482, 21.097452912113667],
						[71.660461270456892, 21.097452903809316],
						[71.660461279773074, 21.097452886634219],
						[71.660461284869442, 21.09745287832985],
						[71.662030784780725, 21.093700076101193],
						[71.662030785835611, 21.093700073883106],
						[71.662030788123715, 21.093700066662542],
						[71.66203079005524, 21.093700065010761],
						[71.663558490033552, 21.089155664218019],
						[71.663558490033552, 21.089155664218019],
						[71.66355849021177, 21.089155661433335],
						[71.663558491266613, 21.089155659214814],
						[71.664922390634445, 21.084827859237581],
						[71.664922391689231, 21.084827857018656],
						[71.664922392922236, 21.08482785201484],
						[71.664922393977051, 21.084827849795932],
						[71.666286294004877, 21.079488949008788],
						[71.666286295237711, 21.079488944004218],
						[71.666286296826726, 21.079488933429388],
						[71.666286298059561, 21.079488928424837],
						[71.666952996991384, 21.074658339249815],
						[71.66803909370843, 21.070475050241527],
						[71.668039093886392, 21.070475047455989],
						[71.668039096173658, 21.070475040230335],
						[71.668039096351606, 21.07047503744483],
						[71.668658496361758, 21.067300038845445],
						[71.668658496895503, 21.067300030488536],
						[71.668658499360575, 21.067300020476598],
						[71.668658499894306, 21.067300012119706],
						[71.668811300194733, 21.064994412031574],
						[71.66881130037261, 21.064994409245873],
						[71.668811300029574, 21.064994400323666],
						[71.668811300385329, 21.064994394752294],
						[71.668625200123444, 21.058291693345467],
						[71.668625200123444, 21.058291693345467],
						[71.668625200301221, 21.058291690559745],
						[71.668625199602246, 21.058291687209095],
						[71.668072399631413, 21.048783289017639],
						[71.668072399809049, 21.048783286231899],
						[71.668072399109931, 21.048783282881736],
						[71.668072399287595, 21.048783280095979],
						[71.667616899762194, 21.044097188680993],
						[71.667430800360165, 21.037322201298622],
						[71.667744599709422, 21.028025011794625],
						[71.66805849942341, 21.024205620359741],
						[71.66905579789865, 21.01583613035675],
						[71.670597396958101, 21.007394434666821],
						[71.670597397135126, 21.007394431880275],
						[71.670597397666256, 21.007394423520644],
						[71.670597398720233, 21.007394421296731],
						[71.671414097622701, 20.999675025337694],
						[71.672436297742692, 20.992886128461329],
						[71.672436297919546, 20.992886125674374],
						[71.672436299150149, 20.992886120662423],
						[71.672436299326989, 20.992886117875468],
						[71.672911299277132, 20.987188917253558],
						[71.672911299453887, 20.987188914466472],
						[71.672911299630641, 20.987188911679407],
						[71.67291129980741, 20.98718890889231],
						[71.673108500077063, 20.983150009214125],
						[71.673108420821933, 20.983149906257868],
						[71.673108228025001, 20.983149866909859],
						[71.673108113782931, 20.983149927169443],
						[71.671288741747517, 20.987780455510293],
						[71.670544433905562, 20.988146960929996],
						[71.669764081393268, 20.98743319506821],
						[71.669144596024069, 20.984699962633453],
						[71.668186297689232, 20.978872169899091],
						[71.667225197992181, 20.972177775987465],
						[71.666461299663453, 20.965338886417104],
						[71.666272399843066, 20.95863889402472],
						[71.666272399843066, 20.95863889402472],
						[71.666272400019395, 20.958638891238291],
						[71.666272400019395, 20.958638891238291],
						[71.665902999834188, 20.951508291235491],
						[71.665903000010417, 20.95150828844907],
						[71.665903000010417, 20.95150828844907],
						[71.665508500081998, 20.945602788636403],
						[71.665300199849113, 20.940919391017676],
						[71.665300200025172, 20.940919388231229],
						[71.665300199324136, 20.940919384885419],
						[71.665300199324136, 20.940919384885419],
						[71.664561298856341, 20.931708283884703],
						[71.664561299032272, 20.931708281098263],
						[71.664561299208216, 20.931708278311859],
						[71.664561299384161, 20.931708275525409],
						[71.66350019865331, 20.922786076042698],
						[71.663500197776159, 20.922786075484247],
						[71.663500197951976, 20.922786072697853],
						[71.663500198127792, 20.922786069911488],
						[71.66252799803533, 20.916386071054824],
						[71.661594598247177, 20.909841673318262],
						[71.661594596142379, 20.909841663286166],
						[71.661594592634401, 20.909841646566015],
						[71.661594590354042, 20.909841639320131],
						[71.661108489683158, 20.908333238922665],
						[71.661108446700993, 20.908333182617199],
						[71.661108334943009, 20.908333117358858],
						[71.661108205986466, 20.908333136793722],
						[71.661108122817822, 20.908333240398232],
						[71.661108100356699, 20.90833330725652],
						[71.661327600014758, 20.915752806922089],
						[71.661327600014758, 20.915752806922089],
						[71.661327599839055, 20.915752809708213],
						[71.661624800152737, 20.922086105122695],
						[71.661708100184626, 20.929577802850925],
						[71.661708100184626, 20.929577802850925],
						[71.661827600034584, 20.937141699957703],
						[71.661697000283255, 20.942763893672875],
						[71.661277600257108, 20.951269388542258],
						[71.660874799776934, 20.957469385488377],
						[71.660874799600634, 20.957469388274003],
						[71.6608748003014, 20.957469391619622],
						[71.6608748003014, 20.957469391619622],
						[71.660535900027796, 20.964677790344926],
						[71.660044199716197, 20.972966688742108],
						[71.660044199716197, 20.972966688742108],
						[71.660044199539669, 20.972966691527475],
						[71.660044200416692, 20.972966692088153],
						[71.659730400004463, 20.981111089981692],
						[71.659463699920309, 20.985722188416297],
						[71.659248191336701, 20.989383160451148],
						[71.658189070101969, 20.987749894743963],
						[71.656550172736331, 20.984958198102603],
						[71.655016873326488, 20.982302699632235],
						[71.655016872449522, 20.982302699071305],
						[71.655016870872245, 20.982302695164904],
						[71.655016869995251, 20.982302694603955],
						[71.653375176922879, 20.979655505262581],
						[71.652800182935295, 20.9783638187027],
						[71.652800131375372, 20.978363768356402],
						[71.652800007006931, 20.978363714919073],
						[71.652799934198399, 20.978363711827967],
						[71.651733234153099, 20.978735912344494],
						[71.651733220992156, 20.978735918415556],
						[71.651733196600887, 20.978735928894952],
						[71.651733185193905, 20.978735936087329],
						[71.650183184680699, 20.979830437769923],
						[71.650183180996109, 20.979830438311097],
						[71.650183175204333, 20.97983044329861],
						[71.650183172220096, 20.979830447184291],
						[71.648613772150313, 20.981138747455109],
						[71.648613768289096, 20.981138750779603],
						[71.648613759689653, 20.981138756867896],
						[71.64861375582845, 20.981138760192383],
						[71.646652656045447, 20.983174861573286],
						[71.64665263480218, 20.983174894331047],
						[71.646652606700655, 20.983174963251244],
						[71.646652599665686, 20.983175002196983],
					],
					[
						[71.659239106019896, 20.989544299555622],
						[71.659248518827809, 20.989384398971971],
						[71.659443683894253, 20.989685362114319],
						[71.659239106019896, 20.989544299555622],
					],
				],
				[
					[71.573160905150161, 20.83009174177036],
					[71.573908104993862, 20.833497242325368],
					[71.573908143264319, 20.833497300982891],
					[71.573908249617702, 20.83349737935751],
					[71.573908381728998, 20.833497367218442],
					[71.573908472577926, 20.833497271470904],
					[71.573908499572894, 20.833497204805312],
					[71.573922688266421, 20.832975826482969],
					[71.576380404619968, 20.842280643719924],
					[71.579549803786449, 20.858997238081226],
					[71.582402602963157, 20.873969436323744],
					[71.584344200866951, 20.885013921875288],
					[71.585513700153129, 20.904897208454454],
					[71.585752600282248, 20.920241700897634],
					[71.585402600075795, 20.944938894515907],
					[71.584269199844641, 20.970652791752947],
					[71.584269199844641, 20.970652791752947],
					[71.584269199668228, 20.970652794527268],
					[71.584269199668228, 20.970652794527268],
					[71.583555400131914, 20.997705594940502],
					[71.583555399955102, 20.997705597714429],
					[71.583555399601465, 20.997705603262311],
					[71.583555400301606, 20.997705606595574],
					[71.584369200312736, 21.026033302700259],
					[71.584022000123767, 21.060197197372659],
					[71.584021999946046, 21.060197200146018],
					[71.584021999768311, 21.060197202919397],
					[71.584021999768311, 21.060197202919397],
					[71.584752599834061, 21.093055600487403],
					[71.584785899688129, 21.125483298498303],
					[71.58466650009278, 21.155224997992821],
					[71.584396999647225, 21.186305596023836],
					[71.583810899860325, 21.215830595727425],
					[71.583810899860325, 21.215830595727425],
					[71.583810899680358, 21.215830598499135],
					[71.583810899680358, 21.215830598499135],
					[71.583716500340046, 21.241558298627993],
					[71.583491500400896, 21.267899996017281],
					[71.583210900184923, 21.282302795657461],
					[71.583210899823015, 21.282302801199283],
					[71.583210900337107, 21.282302807313503],
					[71.583210899975214, 21.282302812855331],
					[71.584313700205968, 21.301286110401392],
					[71.584883100110432, 21.31427780949705],
					[71.584883100110432, 21.31427780949705],
					[71.584883099929016, 21.3142778122679],
					[71.585613700306368, 21.327897209891624],
					[71.586613700006922, 21.346055609598753],
					[71.586613699825051, 21.34605561236949],
					[71.586613700518967, 21.346055615715642],
					[71.586613701394739, 21.346055616291039],
					[71.587760900328789, 21.359797211620251],
					[71.588105399930569, 21.372372203829759],
					[71.588105399748301, 21.372372206600428],
					[71.588105399566032, 21.372372209371097],
					[71.588105400441762, 21.37237220994777],
					[71.58881920033707, 21.387024997195621],
					[71.587813701299481, 21.400569384634156],
					[71.587813700241213, 21.400569386826543],
					[71.587813700058533, 21.400569389596871],
					[71.587813700058533, 21.400569389596871],
					[71.587166500335215, 21.414238889744492],
					[71.586346999619565, 21.428819389013025],
					[71.586347001330708, 21.428819404599725],
					[71.586347003185054, 21.428819431844904],
					[71.586347005771714, 21.428819448010763],
					[71.589010905746932, 21.439855647791941],
					[71.592055401706347, 21.4526639328367],
					[71.594177601265244, 21.472436120588732],
					[71.594177601081526, 21.472436123359241],
					[71.594177601081526, 21.472436123359241],
					[71.594177601956901, 21.472436123940714],
					[71.596288702360752, 21.489016725096306],
					[71.598044200839809, 21.503011123237414],
					[71.599588701465251, 21.515863922887842],
					[71.59958870197174, 21.515863929013076],
					[71.599588704044336, 21.515863939076446],
					[71.59958870455084, 21.515863945201694],
					[71.60102480532781, 21.52211944439631],
					[71.601024806709304, 21.522119451106146],
					[71.601024811222658, 21.522119465693791],
					[71.601024812604152, 21.522119472403634],
					[71.602438712805039, 21.525797271170244],
					[71.60243882469085, 21.52579733427757],
					[71.602439019179499, 21.525797296231918],
					[71.602439099847501, 21.525797196681651],
					[71.602358499903644, 21.515794400498439],
					[71.602866900349042, 21.499955605820126],
					[71.602866900349042, 21.499955605820126],
					[71.602866899657911, 21.499955602465668],
					[71.602866899842041, 21.499955599694207],
					[71.602983499894449, 21.482441699682969],
					[71.602983499894449, 21.482441699682969],
					[71.602983499894449, 21.482441699682969],
					[71.602983500078309, 21.482441696911277],
					[71.602800199730254, 21.465636097861886],
					[71.602766899756887, 21.438327799512738],
					[71.603083500079265, 21.412994404019461],
					[71.603553000103901, 21.394983305632071],
					[71.604564100212173, 21.363802808516354],
					[71.605125198810015, 21.347027828681437],
					[71.608741893012436, 21.333986155030519],
					[71.613352992095287, 21.317783356076134],
					[71.613352993334146, 21.317783351101287],
					[71.613352994391519, 21.317783348901376],
					[71.61335299457302, 21.317783346126429],
					[71.618200194858971, 21.296938945412524],
					[71.61820019504016, 21.296938942636672],
					[71.622683495371504, 21.277205643010728],
					[71.622683495733327, 21.2772056374573],
					[71.622683498389918, 21.277205624720622],
					[71.622683498751755, 21.277205619167177],
					[71.624625199093572, 21.255719417592637],
					[71.624625199274192, 21.255719414815424],
					[71.624625199635389, 21.255719409260973],
					[71.624625199816009, 21.255719406483763],
					[71.625772400475498, 21.225844407260745],
					[71.625772399599299, 21.225844406689543],
					[71.625772399959644, 21.225844401134147],
					[71.625772399959644, 21.225844401134147],
					[71.625864100290741, 21.200572201265437],
					[71.626608499781781, 21.163561102824797],
					[71.626608499781781, 21.163561102824797],
					[71.626608499961065, 21.163561100046316],
					[71.626608499961065, 21.163561100046316],
					[71.626602999842419, 21.13405560071806],
					[71.626733500045631, 21.099602801852363],
					[71.626733500223963, 21.099602799073182],
					[71.626683500265273, 21.072261103377699],
					[71.627828000110625, 21.046911110201364],
					[71.62782800028819, 21.046911107421487],
					[71.627828000465783, 21.046911104641623],
					[71.627827999588987, 21.046911104078589],
					[71.628255799862714, 21.019686102533704],
					[71.628255800039881, 21.019686099753486],
					[71.628255800217076, 21.019686096973249],
					[71.628255800217076, 21.019686096973249],
					[71.627716899678731, 20.995738895737666],
					[71.627311299804532, 20.97251669618743],
					[71.627311299804532, 20.97251669618743],
					[71.626814100048747, 20.948466697093806],
					[71.62669189987669, 20.928036098058687],
					[71.62669189987669, 20.928036098058687],
					[71.626691900052535, 20.928036095277768],
					[71.626691900052535, 20.928036095277768],
					[71.625980799810918, 20.905022194898134],
					[71.625980799986436, 20.905022192117073],
					[71.625044599516187, 20.879213896638692],
					[71.625041899611247, 20.863944399104053],
					[71.625127999610541, 20.846827800236362],
					[71.625127999785221, 20.846827797454839],
					[71.625128000309232, 20.846827789110236],
					[71.625127998729042, 20.846827785221262],
					[71.623877998711336, 20.831230585157019],
					[71.623877998885774, 20.831230582375515],
					[71.623877999060213, 20.83123057959401],
					[71.623877999234651, 20.831230576812484],
					[71.621880799118401, 20.813358278645094],
					[71.621880797711711, 20.813358271978142],
					[71.621880797356695, 20.813358263081877],
					[71.621880795775823, 20.813358259196338],
					[71.618897395572702, 20.799297157669194],
					[71.618897394695111, 20.799297157117856],
					[71.618897394695111, 20.799297157117856],
					[71.616455799167397, 20.788130571462492],
					[71.615653000804727, 20.776158304017198],
					[71.617011298683039, 20.764111123080983],
					[71.617011298672665, 20.764111108625414],
					[71.617011297774155, 20.764111079164586],
					[71.617011296886091, 20.764111064159373],
					[71.614547396561605, 20.750888863019291],
					[71.614547394448209, 20.750888853029508],
					[71.61454738917044, 20.750888835282002],
					[71.614547386006038, 20.750888827524243],
					[71.609591885618968, 20.738266625988338],
					[71.609591884036519, 20.738266622111563],
					[71.609591879289141, 20.738266610481137],
					[71.609591875951139, 20.738266605507896],
					[71.60388348013592, 20.727699912969047],
					[71.600005783911811, 20.718599921818104],
					[71.600005783911811, 20.718599921818104],
					[71.60000578320674, 20.718599918491925],
					[71.600005782328921, 20.718599917944939],
					[71.594950186067905, 20.707433225169204],
					[71.58999738876426, 20.693288833124903],
					[71.589997388058762, 20.693288829801428],
					[71.589997385597414, 20.693288825386887],
					[71.589997384891916, 20.693288822063412],
					[71.585927985115774, 20.683477722084071],
					[71.58592798089829, 20.683477716581883],
					[71.585927973685827, 20.683477700567508],
					[71.585927970346276, 20.683477695610254],
					[71.583411270361566, 20.679449893699854],
					[71.583411160995169, 20.679449848921422],
					[71.58341097681091, 20.67944989327302],
					[71.583410901115045, 20.679449981858387],
					[71.582460900877194, 20.690455580583233],
					[71.580980401007849, 20.706291681838167],
					[71.579063700829749, 20.7266555804507],
					[71.579063700656874, 20.72665558322673],
					[71.579063700656874, 20.72665558322673],
					[71.579063700483999, 20.726655586002806],
					[71.577074800860899, 20.753902785590387],
					[71.577074799983166, 20.753902785042477],
					[71.577074799809893, 20.753902787817946],
					[71.577074799809893, 20.753902787817946],
					[71.575749799977942, 20.776008289205237],
					[71.575749799977942, 20.776008289205237],
					[71.575749799804356, 20.77600829198029],
					[71.575749799804356, 20.77600829198029],
					[71.574841499762442, 20.798533293004958],
					[71.574841499762442, 20.798533293004958],
					[71.574841499762442, 20.798533293004958],
					[71.574841499588544, 20.798533295779656],
					[71.574288699651675, 20.819511095816946],
					[71.573922364038225, 20.832973033051644],
					[71.573161293215392, 20.830091648181586],
					[71.573161247749624, 20.830091587897158],
					[71.573161128063774, 20.830091518471804],
					[71.573160991925107, 20.830091551173656],
					[71.573160917929911, 20.830091669056088],
					[71.573160905150161, 20.83009174177036],
				],
				[
					[71.457458100255721, 21.153594401825707],
					[71.457513700338808, 21.173675000124515],
					[71.457513700159566, 21.17367500287844],
					[71.457513699980325, 21.173675005632369],
					[71.457513699980325, 21.173675005632369],
					[71.457958100081612, 21.187186107863745],
					[71.457958099902157, 21.187186110617596],
					[71.457958100937049, 21.187186122760799],
					[71.457958102330821, 21.187186129396277],
					[71.459310901971151, 21.196683328971002],
					[71.459310903364582, 21.196683335607627],
					[71.459310906151487, 21.196683348880921],
					[71.459310907544918, 21.196683355517546],
					[71.461955404758839, 21.20593894609674],
					[71.463899800199172, 21.215625020317923],
					[71.464021999957509, 21.22568060260895],
					[71.464021999957509, 21.22568060260895],
					[71.464021999777515, 21.225680605363269],
					[71.464371999863033, 21.238769403923786],
					[71.464505399718064, 21.2549416983938],
					[71.464213699779151, 21.271463897173177],
					[71.464213699598488, 21.271463899927038],
					[71.464213700293882, 21.271463903248854],
					[71.464213700113206, 21.271463906002758],
					[71.464913699707495, 21.293608306582211],
					[71.464913700916455, 21.293608315981384],
					[71.464913703515407, 21.293608332025961],
					[71.464913703848424, 21.293608340856157],
					[71.466835904331859, 21.302677841721785],
					[71.466835942451766, 21.302677900963232],
					[71.466836047716356, 21.302677980881469],
					[71.466836179134347, 21.302677968678324],
					[71.466836271072481, 21.302677876247991],
					[71.466836300156643, 21.302677809005232],
					[71.467291900174089, 21.293941710401068],
					[71.467291900355093, 21.293941707646994],
					[71.467291899660125, 21.293941704323874],
					[71.467291899841115, 21.293941701569789],
					[71.467391900040269, 21.279461105753587],
					[71.468461300064803, 21.260352812249451],
					[71.468461300245309, 21.260352809494847],
					[71.468461300245309, 21.260352809494847],
					[71.468461300425815, 21.260352806740226],
					[71.469400197980889, 21.232836159735566],
					[71.470416540686244, 21.231214528610533],
					[71.471146999643508, 21.242186111977002],
					[71.471980399642675, 21.255869412732942],
					[71.472819199624908, 21.271105611144616],
					[71.472819199624908, 21.271105611144616],
					[71.472819200320302, 21.271105614467942],
					[71.474538700373643, 21.296927813562387],
					[71.474538701249585, 21.296927814131813],
					[71.474538701068539, 21.296927816886885],
					[71.474538701068539, 21.296927816886885],
					[71.475716501107939, 21.310975016695561],
					[71.475716500926694, 21.310975019450677],
					[71.475716500745449, 21.310975022205778],
					[71.475716502316018, 21.310975026101129],
					[71.477910901707702, 21.328375023968299],
					[71.477910902402073, 21.328375027294527],
					[71.477910902220557, 21.328375030049784],
					[71.4779109029149, 21.328375033375991],
					[71.480030402925863, 21.341316731695919],
					[71.480030407966382, 21.341316752229854],
					[71.480030423665667, 21.341316791216745],
					[71.480030434142748, 21.341316812425109],
					[71.481935933896978, 21.344177911249076],
					[71.481936047304799, 21.344177950804074],
					[71.481936230230033, 21.344177892032924],
					[71.481936299747417, 21.344177793706784],
					[71.481758500464437, 21.338661093459461],
					[71.481758499588622, 21.338661092887893],
					[71.481758499770294, 21.338661090132181],
					[71.481758499951951, 21.338661087376504],
					[71.481008500267009, 21.32622779566714],
					[71.481280800291742, 21.302847202488529],
					[71.481280800291742, 21.302847202488529],
					[71.481280800472888, 21.302847199732511],
					[71.481294599881736, 21.27580560540083],
					[71.482875199925402, 21.240694408908421],
					[71.482875199925402, 21.240694408908421],
					[71.482875200105639, 21.240694406151558],
					[71.482875200285875, 21.240694403394684],
					[71.483233500392984, 21.22001940777854],
					[71.484283499729614, 21.201200012088769],
					[71.485902999360121, 21.179038914683396],
					[71.485903000415831, 21.179038912489883],
					[71.485902999718817, 21.179038909167502],
					[71.485902999718817, 21.179038909167502],
					[71.486947399772077, 21.15326941040728],
					[71.488372399695137, 21.127755610136997],
					[71.488372399695137, 21.127755610136997],
					[71.488372399873754, 21.127755607378145],
					[71.488372399873754, 21.127755607378145],
					[71.489058499871135, 21.108569407499601],
					[71.489058500049467, 21.108569404740468],
					[71.489769600082681, 21.084722205285761],
					[71.489769600082681, 21.084722205285761],
					[71.489769600260672, 21.08472220252629],
					[71.489769600260672, 21.08472220252629],
					[71.489911300386268, 21.063908302901279],
					[71.490753000221716, 21.039555605817224],
					[71.491830800422647, 21.006938907242876],
					[71.491830799545738, 21.006938906686131],
					[71.491830799722592, 21.006938903925544],
					[71.492441899639658, 20.982244408112095],
					[71.493969599263707, 20.960044415167911],
					[71.495061298565247, 20.945977825834351],
					[71.496797139720357, 20.936448646108662],
					[71.497380401040814, 20.942425014704611],
					[71.497958100401007, 20.952138907048596],
					[71.498194200137362, 20.962119403958567],
					[71.498230399642793, 20.97249170189259],
					[71.498230400167117, 20.972491707971695],
					[71.498230401215707, 20.972491720129888],
					[71.498230401740017, 20.972491726208986],
					[71.500088701583067, 20.987030626165751],
					[71.500088708239829, 20.987030650504618],
					[71.500088727168375, 20.987030696995088],
					[71.500088739440201, 20.987030719146745],
					[71.502877639368677, 20.990800118005932],
					[71.502877717071613, 20.990800158680482],
					[71.502877874615791, 20.990800163777507],
					[71.502877955510584, 20.990800125993985],
					[71.50542235473371, 20.987694526050213],
					[71.50542236386265, 20.987694511717976],
					[71.505422381243548, 20.987694482497226],
					[71.505422387741575, 20.987694466496052],
					[71.508533480341768, 20.97905838928968],
					[71.51068333864545, 20.975797504629803],
					[71.513491506095761, 20.978322295052646],
					[71.514047000470725, 20.986794408944654],
					[71.514288699908548, 20.9933639075667],
					[71.514288755661028, 20.993363991863941],
					[71.514288912996165, 20.99336407163862],
					[71.514289014755491, 20.993364064352072],
					[71.518047314880491, 20.990719563712336],
					[71.518047329448166, 20.990719549949198],
					[71.51804735472237, 20.990719525724465],
					[71.518047366659161, 20.990719510290724],
					[71.52121126657984, 20.985925109554056],
					[71.521211279046042, 20.98592508581979],
					[71.52121129626218, 20.985925030575441],
					[71.521211299781982, 20.985925004038716],
					[71.521575199991901, 20.964688905937127],
					[71.522583500320948, 20.941975009069733],
					[71.523472399996308, 20.92329721216619],
					[71.524800198887903, 20.90362501680773],
					[71.526669598990196, 20.882669418662577],
					[71.526669599165302, 20.882669415895723],
					[71.526669600392765, 20.882669410914183],
					[71.526669600392765, 20.882669410914183],
					[71.528244600020329, 20.852436111807503],
					[71.530066899714882, 20.824094412986597],
					[71.530066899714882, 20.824094412986597],
					[71.532300198979641, 20.788000015117401],
					[71.534266899051161, 20.768597220599659],
					[71.53426689922459, 20.768597217830532],
					[71.53426689922459, 20.768597217830532],
					[71.536186298645774, 20.747833318939477],
					[71.536186298818905, 20.747833316169878],
					[71.536186298818905, 20.747833316169878],
					[71.536186299869811, 20.747833313946575],
					[71.537908500236227, 20.723300014655013],
					[71.537908495287084, 20.723299977051749],
					[71.537908473113134, 20.723299908607526],
					[71.537908456061118, 20.723299874996407],
					[71.532611256182292, 20.716694275747294],
					[71.526566856040773, 20.709172073640094],
					[71.526566851651438, 20.709172070918878],
					[71.526566844973615, 20.709172061027704],
					[71.526566840584238, 20.709172058306486],
					[71.520369540612222, 20.7029915589948],
					[71.520369529372161, 20.702991549158074],
					[71.520369503207959, 20.702991530077316],
					[71.520369489161723, 20.702991521377044],
					[71.513641822291063, 20.699660935871034],
					[71.508008447250262, 20.693547063912725],
					[71.508008326334775, 20.693547043713661],
					[71.508008154662903, 20.693547135757182],
					[71.508008105662356, 20.693547249085697],
					[71.508224799257448, 20.69440002174106],
					[71.507777599809614, 20.711255588364242],
					[71.506172001248643, 20.728602781775137],
					[71.506172001248643, 20.728602781775137],
					[71.506172001075811, 20.728602784540602],
					[71.506172000025146, 20.728602786761556],
					[71.504860899488335, 20.747647187025638],
					[71.504860900366097, 20.747647187570969],
					[71.504860900366097, 20.747647187570969],
					[71.503166499917086, 20.775452786380573],
					[71.501274800997663, 20.799186082994506],
					[71.499502600895852, 20.82096668293342],
					[71.497149808363758, 20.847022098887791],
					[71.493752671806163, 20.849838745919563],
					[71.493752666895304, 20.84983875145868],
					[71.493752655318772, 20.849838761437681],
					[71.493752650407899, 20.849838766976823],
					[71.491183149771672, 20.852752667146792],
					[71.491183144860727, 20.852752672684478],
					[71.491183134864258, 20.85275268652191],
					[71.491183130830748, 20.852752692609272],
					[71.487555430600949, 20.858541593253559],
					[71.487555425515197, 20.858541601550257],
					[71.487555419202579, 20.858541614820005],
					[71.487555414994219, 20.858541623666525],
					[71.48544421495437, 20.863680524348634],
					[71.485444212850084, 20.863680528770864],
					[71.485444209693597, 20.863680535404153],
					[71.485444209344053, 20.86368054092641],
					[71.483288709129326, 20.870783242967651],
					[71.483288705622883, 20.870783255120518],
					[71.483288701242046, 20.87078328107707],
					[71.48328870036768, 20.870783294880749],
					[71.482788700070657, 20.887816693002978],
					[71.482052599895738, 20.904613890699434],
					[71.482052599895738, 20.904613890699434],
					[71.482052599720362, 20.90461389345964],
					[71.482052599720362, 20.90461389345964],
					[71.481380400011773, 20.926802791086608],
					[71.480366503023276, 20.942524954304723],
					[71.477347015677552, 20.949611022724348],
					[71.477347014800429, 20.949611022170661],
					[71.47734701357129, 20.94961102713512],
					[71.47734701269421, 20.949611026581461],
					[71.473285912839003, 20.960197127064689],
					[71.473285911433436, 20.960197134785798],
					[71.473285906868185, 20.960197149120042],
					[71.473285904761724, 20.96019715352875],
					[71.471477604746156, 20.967844355055856],
					[71.471477604393613, 20.967844360571977],
					[71.471477601758181, 20.967844373253573],
					[71.471477601229353, 20.967844381527762],
					[71.470644200840113, 20.976436080998855],
					[71.469291501243049, 20.989519379377121],
					[71.469291501066465, 20.989519382134613],
					[71.469291500889881, 20.989519384892127],
					[71.469291499836316, 20.989519387094386],
					[71.467372000948856, 21.018577781821463],
					[71.465505401436687, 21.035730578066953],
					[71.465505401259463, 21.035730580823458],
					[71.465505401082197, 21.035730583579952],
					[71.465505400904959, 21.035730586336449],
					[71.46319420136777, 21.066797177674953],
					[71.460163701800724, 21.086380569759353],
					[71.460163702321452, 21.086380575829168],
					[71.460163700732863, 21.086380586290641],
					[71.460163700376896, 21.086380591801085],
					[71.459147000046869, 21.110877792104169],
					[71.459147000046869, 21.110877792104169],
					[71.458171999741012, 21.137136091175325],
					[71.457458100095096, 21.153594390246834],
					[71.457458099916153, 21.153594393000965],
					[71.457458099558252, 21.153594398509252],
					[71.457458100255721, 21.153594401825707],
				],
				[
					[71.420513700349986, 20.837261098228879],
					[71.420513700355855, 20.837261112536023],
					[71.420513700884683, 20.837261118586941],
					[71.422485901072875, 20.859691715130005],
					[71.423619199730808, 20.877586110592357],
					[71.424513699609435, 20.902375006692925],
					[71.424513700311422, 20.902375009994735],
					[71.424513700311422, 20.902375009994735],
					[71.424513700136131, 20.902375012746734],
					[71.425946999818294, 20.92496110809418],
					[71.426385900134463, 20.945361104717247],
					[71.426385900134463, 20.945361104717247],
					[71.426385900134463, 20.945361104717247],
					[71.427099799694659, 20.967655604494869],
					[71.427099800395496, 20.96765560779949],
					[71.427099800219267, 20.967655610551187],
					[71.427099800219267, 20.967655610551187],
					[71.428338700133551, 20.989811110548072],
					[71.42833870065742, 20.989811116605296],
					[71.428338703282606, 20.989811132579334],
					[71.428338703806489, 20.989811138636558],
					[71.430410904322358, 21.000297239364084],
					[71.430411004110482, 21.000297319640882],
					[71.430411201658217, 21.000297312824998],
					[71.430411298364177, 21.000297227929664],
					[71.432327868332251, 20.987103721708575],
					[71.434005406094016, 20.992686149010712],
					[71.435716501359224, 21.000958328361349],
					[71.43654150037834, 21.013669410337965],
					[71.437388700250551, 21.039925007529231],
					[71.437885900077205, 21.05766390529352],
					[71.438460900391945, 21.082130601908439],
					[71.438577600410468, 21.103025000334949],
					[71.438577600232279, 21.103025003086895],
					[71.438577600232279, 21.103025003086895],
					[71.438577600232279, 21.103025003086895],
					[71.439038700363326, 21.125722200584576],
					[71.438805399810022, 21.150291697365653],
					[71.438485900381522, 21.176508298667979],
					[71.438485900202267, 21.176508301419158],
					[71.438485900202267, 21.176508301419158],
					[71.438580400182005, 21.198022202347001],
					[71.438580400182005, 21.198022202347001],
					[71.438580400002422, 21.198022205097992],
					[71.438580400002422, 21.198022205097992],
					[71.43909979994865, 21.215561103283513],
					[71.439163700012315, 21.237177800619126],
					[71.439163699652042, 21.237177806120421],
					[71.43916370104408, 21.237177812752833],
					[71.439163700683778, 21.237177818254118],
					[71.440366501437651, 21.250263917606929],
					[71.440366532720944, 21.250263983699124],
					[71.440366631851688, 21.250264073566697],
					[71.440366766927454, 21.250264074841702],
					[71.440366866905904, 21.250263984621927],
					[71.440366898599564, 21.250263919087001],
					[71.441211299307568, 21.241594420343667],
					[71.44121129861162, 21.241594417026953],
					[71.441211299848206, 21.241594412090972],
					[71.441211299848206, 21.241594412090972],
					[71.442083499969627, 21.226205610039422],
					[71.442083499969627, 21.226205610039422],
					[71.442083500149607, 21.226205607288236],
					[71.442083500329602, 21.226205604537039],
					[71.442525200350275, 21.208997207043783],
					[71.443244599813937, 21.187897208746865],
					[71.444194600163073, 21.168586111195314],
					[71.444194600342229, 21.168586108443243],
					[71.444964100242302, 21.15059440843239],
					[71.444964100242302, 21.15059440843239],
					[71.445658499736084, 21.133416707796101],
					[71.446875200305115, 21.109275010300504],
					[71.446875200305115, 21.109275010300504],
					[71.446875199606851, 21.109275006987438],
					[71.446875199785154, 21.109275004234366],
					[71.447122400305418, 21.097519405601172],
					[71.447425199767167, 21.085144405252596],
					[71.448016899880855, 21.065161107123465],
					[71.448577999554203, 21.046825006872762],
					[71.448577999731611, 21.046825004118805],
					[71.448578000086385, 21.046824998610859],
					[71.448578000263794, 21.046824995856912],
					[71.448294599781434, 21.028380595517667],
					[71.448294599781434, 21.028380595517667],
					[71.448294599781434, 21.028380595517667],
					[71.447864100231016, 21.012711097730904],
					[71.447775200092494, 20.996155599067428],
					[71.447775200269135, 20.996155596313063],
					[71.44777520044579, 20.996155593558704],
					[71.447775199568824, 20.996155593003838],
					[71.447336299801037, 20.984311097215134],
					[71.447405800162826, 20.97036111175947],
					[71.448291897199582, 20.96281943420194],
					[71.449761295272467, 20.956483344979283],
					[71.449761295272467, 20.956483344979283],
					[71.451086294619088, 20.950544443728553],
					[71.451086297253298, 20.950544431057786],
					[71.451086299890406, 20.950544404057624],
					[71.451086299717275, 20.95054439248354],
					[71.450753000493137, 20.942641699121204],
					[71.451180799667043, 20.93224721335131],
					[71.452241898243315, 20.921211128625323],
					[71.454111294047365, 20.911400051171203],
					[71.45663628145455, 20.904050086163142],
					[71.460300169387992, 20.898280706025044],
					[71.46030017991319, 20.898280683961385],
					[71.460300194822707, 20.898280635978558],
					[71.460300199909057, 20.898280613367309],
					[71.460469599785171, 20.895400011078301],
					[71.460469599785171, 20.895400011078301],
					[71.460469599960405, 20.895400008321101],
					[71.460469600135596, 20.895400005563893],
					[71.460933499820456, 20.878591706130958],
					[71.460933499820456, 20.878591706130958],
					[71.46093349999542, 20.878591703373498],
					[71.461308499830267, 20.861252805085311],
					[71.461308500004975, 20.861252802327648],
					[71.461308500354406, 20.861252796812288],
					[71.461308500354406, 20.861252796812288],
					[71.460916899960395, 20.841444395767429],
					[71.460916899960395, 20.841444395767429],
					[71.460916900309243, 20.841444390251798],
					[71.460916900309243, 20.841444390251798],
					[71.460166899789343, 20.826666693203006],
					[71.459822400277943, 20.81100279559638],
					[71.459822400277943, 20.81100279559638],
					[71.459822400451912, 20.811002792838405],
					[71.459822399574364, 20.81100279229161],
					[71.459139099952466, 20.793438893104565],
					[71.459139100299922, 20.793438887588479],
					[71.459139098892152, 20.793438880980421],
					[71.459139099239607, 20.793438875464332],
					[71.459050198741949, 20.792705582957328],
					[71.458211299962485, 20.773886096173818],
					[71.458361299554639, 20.757452803077172],
					[71.458361299727841, 20.757452800318877],
					[71.458361299901043, 20.757452797560585],
					[71.458361299901043, 20.757452797560585],
					[71.458200199637076, 20.743366697150801],
					[71.45820017273401, 20.743366631773483],
					[71.458200081407909, 20.743366532226165],
					[71.458199947173512, 20.743366520725701],
					[71.458199839867717, 20.743366603273994],
					[71.458199803078145, 20.743366666454907],
					[71.457913705300427, 20.74498885364941],
					[71.456138710155088, 20.750688835371058],
					[71.453716512649549, 20.757347128621543],
					[71.449777618969563, 20.767555510888702],
					[71.446377628359755, 20.773272098848594],
					[71.446377626430987, 20.773272100515722],
					[71.446377623277684, 20.773272107151023],
					[71.44637762134893, 20.77327210881818],
					[71.442199830202426, 20.78158599203006],
					[71.437008190256051, 20.788230417971462],
					[71.434166664902904, 20.788735902875704],
					[71.434166639817974, 20.788735910197733],
					[71.434166592811351, 20.788735932530688],
					[71.434166570012039, 20.788735946996663],
					[71.429777669974797, 20.792472048712678],
					[71.429777662259156, 20.792472055368105],
					[71.429777645602925, 20.792472073641662],
					[71.429777637713599, 20.7924720830509],
					[71.426785938049989, 20.796572083229893],
					[71.426785929986735, 20.796572095389447],
					[71.426785916493074, 20.796572121343864],
					[71.42678591001129, 20.796572137347031],
					[71.423952609759183, 20.805024936428669],
					[71.423952608533924, 20.805024941389107],
					[71.423952607134765, 20.805024949102378],
					[71.423952605031886, 20.805024953517382],
					[71.4220526054267, 20.812969352417959],
					[71.422052604027215, 20.812969360129898],
					[71.422052602279834, 20.812969373346942],
					[71.422052600880335, 20.81296938105886],
					[71.420916501092677, 20.825224981094188],
					[71.420916500918537, 20.825224983846439],
					[71.420916499692737, 20.82522498880477],
					[71.420916500396075, 20.825224992103223],
					[71.420513699821171, 20.837261092177961],
					[71.420513700349986, 20.837261098228879],
				],
				[
					[71.299138701752383, 20.283319424585766],
					[71.302102602046688, 20.306508326199722],
					[71.302102602046688, 20.306508326199722],
					[71.302102601713671, 20.306508331680657],
					[71.30210260347198, 20.306508332718675],
					[71.306127602529173, 20.33054443383325],
					[71.306127603408214, 20.330544434353452],
					[71.306127603408214, 20.330544434353452],
					[71.306127603241393, 20.330544437094268],
					[71.308035902686569, 20.340905636226793],
					[71.308035907292776, 20.340905650315182],
					[71.3080359149142, 20.340905674709504],
					[71.308035919687413, 20.340905686056907],
					[71.311497019664401, 20.348130686484577],
					[71.311497029544157, 20.34813070370425],
					[71.311497053153985, 20.348130734745553],
					[71.311497066884058, 20.348130748567137],
					[71.317710967017064, 20.353691850292687],
					[71.317710975807046, 20.353691855508806],
					[71.317710994977858, 20.353691869726536],
					[71.317711004646867, 20.353691875464261],
					[71.323844304787769, 20.357008476855292],
					[71.323844324292807, 20.35700848559507],
					[71.323844363804625, 20.357008494845424],
					[71.323844383644158, 20.357008498099045],
					[71.331449971693843, 20.357614097219713],
					[71.342063853490203, 20.359775194318235],
					[71.347852746061065, 20.361383493097051],
					[71.347852786074654, 20.361383494131825],
					[71.347852863966935, 20.361383486393478],
					[71.347852902180279, 20.361383472127525],
					[71.35158340182096, 20.359164071280055],
					[71.351583425803184, 20.359164051388312],
					[71.351583466903179, 20.359164004674849],
					[71.351583484020907, 20.359163977853143],
					[71.357214084086138, 20.346050079595834],
					[71.357214084253258, 20.346050076847916],
					[71.357214086178431, 20.346050075144923],
					[71.357214086345564, 20.346050072397023],
					[71.361052973267093, 20.336191807203836],
					[71.364922346736023, 20.332025135909866],
					[71.364922365942164, 20.332025104591775],
					[71.364922392592788, 20.332025040667293],
					[71.364922400204165, 20.332025005311753],
					[71.365058719948408, 20.327023946991776],
					[71.365216637687723, 20.327075189192406],
					[71.365216710683725, 20.327075186951319],
					[71.365216834397486, 20.327075129368797],
					[71.365216885603587, 20.327075005958761],
					[71.365216840623958, 20.327074876720669],
					[71.3652167928973, 20.327074822755201],
					[71.365059583019175, 20.32699225990191],
					[71.36508080000246, 20.326213905227011],
					[71.365080776633249, 20.32621384008764],
					[71.365080693653383, 20.326213736717506],
					[71.365080633709042, 20.326213703985193],
					[71.362847234244057, 20.325830404568247],
					[71.362847121958737, 20.325830468934814],
					[71.362847058670866, 20.325830656360512],
					[71.362847106956082, 20.325830776149022],
					[71.365059176816658, 20.32699250013308],
					[71.365058334093561, 20.327023401792093],
					[71.353386171002313, 20.323235911637759],
					[71.337088993976693, 20.316166522361492],
					[71.322916821840522, 20.307238739513153],
					[71.311600141941199, 20.296869259196228],
					[71.302983449390936, 20.28730546537648],
					[71.302983446753316, 20.287305463821998],
					[71.302983444994879, 20.287305462785657],
					[71.302983444115696, 20.287305462267511],
					[71.299139044101608, 20.28331926011219],
					[71.299138928736937, 20.283319240409696],
					[71.29913875791884, 20.283319324275563],
					[71.299138701752383, 20.283319424585766],
				],
				[
					[
						[71.294955399788179, 20.422488897488869],
						[71.294955399994265, 20.422488908966294],
						[71.294955399657923, 20.422488914443026],
						[71.2962554003024, 20.44100561424429],
						[71.296255401181114, 20.441005614769196],
						[71.296255401012658, 20.441005617507539],
						[71.296255400844217, 20.441005620245928],
						[71.29698870125732, 20.448819417820481],
						[71.296988731072545, 20.448819481070664],
						[71.296988825707729, 20.448819571714164],
						[71.296988957334491, 20.448819579422992],
						[71.296989059784337, 20.448819498710883],
						[71.296989096306106, 20.448819438208567],
						[71.302047032733057, 20.423024090950911],
						[71.302383099982293, 20.44241390261427],
						[71.302383099813824, 20.442413905353465],
						[71.302383099645354, 20.442413908092693],
						[71.302383100355598, 20.442413911357058],
						[71.303519200386432, 20.466197209882104],
						[71.303519200217622, 20.466197212621235],
						[71.303519200927454, 20.466197215886648],
						[71.303519200758643, 20.466197218625787],
						[71.305872001117592, 20.490072219452429],
						[71.307777600701272, 20.508594420834299],
						[71.307777602119444, 20.508594427369594],
						[71.307777604077259, 20.508594439911903],
						[71.307777604616874, 20.508594445918863],
						[71.310597005159835, 20.52046674635173],
						[71.310597008334526, 20.520466753946746],
						[71.310597011878869, 20.520466770289563],
						[71.310597015223195, 20.520466775144993],
						[71.31323871390957, 20.52687227495738],
						[71.316027612799857, 20.534136170464773],
						[71.316027615265327, 20.534136174794217],
						[71.316027618269644, 20.534136185133914],
						[71.316027619856726, 20.534136188933644],
						[71.318838720000329, 20.539902887570126],
						[71.318838727396425, 20.539902900562677],
						[71.31883874323691, 20.539902924337206],
						[71.318838750802897, 20.539902934589168],
						[71.322472051434204, 20.543944533366354],
						[71.322472054777862, 20.543944538228917],
						[71.322472062513583, 20.543944545743425],
						[71.322472066905632, 20.543944548395334],
						[71.32686376680509, 20.547875149575624],
						[71.326863774540641, 20.5478751570935],
						[71.326863792986927, 20.547875168238036],
						[71.326863801770912, 20.54787517354498],
						[71.334155499134539, 20.551994573111134],
						[71.339702653736325, 20.555380743527817],
						[71.34553592935498, 20.56499450369915],
						[71.345535930063036, 20.564994506975314],
						[71.345535932698056, 20.564994508571431],
						[71.345535934284456, 20.564994512379624],
						[71.352427633947002, 20.575255711807415],
						[71.352427748245447, 20.575255749800277],
						[71.352427931499307, 20.57525569004596],
						[71.35242799957642, 20.57525559176608],
						[71.351305800094906, 20.547969395279701],
						[71.351091900297092, 20.512511100753219],
						[71.351500199920267, 20.474036102643939],
						[71.351500200089248, 20.474036099898104],
						[71.351500200258215, 20.474036097152247],
						[71.351500200258215, 20.474036097152247],
						[71.351200239629591, 20.456849468595962],
						[71.357613704245225, 20.489650038243695],
						[71.362222002910769, 20.514325036584587],
						[71.362222003789242, 20.514325037114823],
						[71.362222003619664, 20.514325039861827],
						[71.365055403703224, 20.52870003969182],
						[71.365055403703224, 20.52870003969182],
						[71.365055403703224, 20.52870003969182],
						[71.365055405290349, 20.52870004350104],
						[71.366191504964647, 20.533958341459066],
						[71.366191506920501, 20.533958354042269],
						[71.366191513977085, 20.533958372560392],
						[71.366191517859676, 20.533958383458785],
						[71.367552618381794, 20.536955684001224],
						[71.36755262331252, 20.536955692684909],
						[71.36755263493086, 20.536955711115152],
						[71.367552640570096, 20.536955723077771],
						[71.369683140797036, 20.53973072123113],
						[71.369683252638751, 20.53973075471168],
						[71.369683432200659, 20.539730695197029],
						[71.369683500090815, 20.539730599454021],
						[71.3697307999565, 20.519402800123125],
						[71.3697307999565, 20.519402800123125],
						[71.369666904765026, 20.494560253845208],
						[71.374388704425115, 20.517094439873681],
						[71.378891503937226, 20.540072236665008],
						[71.378891503767264, 20.540072239414101],
						[71.378891503767264, 20.540072239414101],
						[71.381613702708478, 20.553283334921606],
						[71.382880401644385, 20.561991728731595],
						[71.3828804032308, 20.561991732547138],
						[71.38288040393887, 20.56199173582965],
						[71.382880403768581, 20.561991738579092],
						[71.384049804263412, 20.567686140630624],
						[71.384049805508951, 20.567686149946006],
						[71.384049810975725, 20.567686164677657],
						[71.384049813977953, 20.567686175059723],
						[71.38648311353225, 20.573802873446994],
						[71.388169213461637, 20.578202870948644],
						[71.388169214169409, 20.578202874232627],
						[71.38816921575544, 20.578202878050561],
						[71.388169217341513, 20.578202881868499],
						[71.389783116765415, 20.581850080283608],
						[71.389783155801794, 20.581850126857091],
						[71.389783254340188, 20.581850183933337],
						[71.389783311744466, 20.581850198868239],
						[71.396086098916427, 20.581480799781151],
						[71.406730585386853, 20.582261298749266],
						[71.406730620126396, 20.58226129704688],
						[71.406730686067135, 20.582261277205358],
						[71.406730717975961, 20.582261262353551],
						[71.411830717922626, 20.578555762317759],
						[71.411830731412664, 20.578555750529596],
						[71.411830754879489, 20.578555724814436],
						[71.411830765734621, 20.57855571142218],
						[71.420250166222147, 20.56598341202767],
						[71.420250167270865, 20.565983409807323],
						[71.428111250005074, 20.554158433941346],
						[71.437516812211484, 20.54640846620714],
						[71.44483892747111, 20.542453011858633],
						[71.452302715825496, 20.544327984060182],
						[71.458499869357937, 20.548589052984116],
						[71.465591199617023, 20.55608252220139],
						[71.465699803707537, 20.556627839217228],
						[71.465699846289866, 20.556627902418089],
						[71.465699963649001, 20.556627979679075],
						[71.465700101873537, 20.556627955051983],
						[71.465700181140633, 20.55662783718731],
						[71.465700195911324, 20.556627760235838],
						[71.465593283075791, 20.55608472317002],
						[71.465699855088275, 20.55619733751584],
						[71.465699974096623, 20.556197358476513],
						[71.46570014361015, 20.556197269827315],
						[71.465700195872031, 20.556197161288114],
						[71.46348512905972, 20.545376905140497],
						[71.462577995959123, 20.540769362216782],
						[71.462577996129184, 20.540769359455737],
						[71.46257799542083, 20.540769356160137],
						[71.46257799542083, 20.540769356160137],
						[71.455416894729936, 20.508861056662056],
						[71.455416894899528, 20.508861053901715],
						[71.455416894021013, 20.508861053368847],
						[71.455416894190577, 20.508861050608505],
						[71.449508494029772, 20.485274950892439],
						[71.449508494029772, 20.485274950892439],
						[71.449508493320451, 20.485274947601127],
						[71.449508491563265, 20.485274946537917],
						[71.442833493202897, 20.461608247009881],
						[71.43629189358127, 20.437194352878326],
						[71.430808496145374, 20.412463858608582],
						[71.425902995737431, 20.389024959222482],
						[71.422794595638919, 20.373227761487261],
						[71.422794503980583, 20.373227680953214],
						[71.422794309688797, 20.373227670468005],
						[71.422794208980847, 20.373227738811266],
						[71.418027610677214, 20.388299934072844],
						[71.414213721093304, 20.398394306704184],
						[71.408949852304644, 20.406135963413274],
						[71.398691641683683, 20.414277590403856],
						[71.388663951386022, 20.412935904045014],
						[71.376355681637619, 20.408077618270873],
						[71.361483391030262, 20.400474822536772],
						[71.361483388393722, 20.40047482096162],
						[71.361483383120586, 20.400474817811276],
						[71.361483380484046, 20.40047481623613],
						[71.340994497235926, 20.391477624553541],
						[71.323166816806449, 20.379338739260454],
						[71.310000138022389, 20.36917485271103],
						[71.301455751866484, 20.359258169830944],
						[71.301455649989549, 20.359258137792651],
						[71.301455474829112, 20.35925818720559],
						[71.301455402759146, 20.359258263698468],
						[71.296472002531445, 20.386569364280625],
						[71.296472002196126, 20.386569369758451],
						[71.296472001190196, 20.386569386191933],
						[71.29647199997595, 20.386569391147255],
						[71.295591499810016, 20.4086582916777],
						[71.294955400124522, 20.422488892012137],
						[71.294955399788179, 20.422488897488869],
					],
					[
						[71.462556524811546, 20.540842813986327],
						[71.463484736150946, 20.545376981050545],
						[71.46559262283472, 20.556083444046333],
						[71.465591569673208, 20.556082329431735],
						[71.462556524811546, 20.540842813986327],
					],
				],
				[
					[71.266049808152914, 20.098472259408172],
					[71.269710908124196, 20.110636157192143],
					[71.269710915223811, 20.110636175463572],
					[71.269710929913913, 20.110636203793213],
					[71.26971093893664, 20.110636220345238],
					[71.273888708764417, 20.116313977212588],
					[71.277177600886432, 20.138877822222657],
					[71.278999799860969, 20.165502807335066],
					[71.278999800101502, 20.186252800923913],
					[71.27899986189118, 20.186252893643978],
					[71.279000034329357, 20.186252963020248],
					[71.279000143054134, 20.186252941389053],
					[71.282858321929012, 20.182300264220938],
					[71.289369230870676, 20.187030722918468],
					[71.298177610444228, 20.213275063509052],
					[71.298177619292304, 20.213275082874425],
					[71.298177639957004, 20.213275117667568],
					[71.298177652653081, 20.213275133609983],
					[71.30643875264002, 20.222275134954071],
					[71.306438776053412, 20.222275154351468],
					[71.306438826179431, 20.222275183724634],
					[71.306438853771539, 20.222275194215765],
					[71.316947143355748, 20.224775192896598],
					[71.331052732537231, 20.229816889058981],
					[71.331052736220158, 20.229816888379254],
					[71.331052742375832, 20.229816891993988],
					[71.331052745179377, 20.229816890797885],
					[71.34588319132358, 20.234047377997211],
					[71.354158153338929, 20.243008435028589],
					[71.354158158449323, 20.243008440883095],
					[71.354158167956399, 20.243008449326023],
					[71.354158173066793, 20.243008455180533],
					[71.357602672828165, 20.245847354983344],
					[71.357602694811362, 20.245847367931091],
					[71.357602740867719, 20.245847389364517],
					[71.357602765985902, 20.245847395619247],
					[71.365399965758328, 20.247205797479211],
					[71.365400014185099, 20.247205794693897],
					[71.365400103405307, 20.247205764691149],
					[71.365400144033032, 20.247205740223723],
					[71.372116844161852, 20.240258439333505],
					[71.372116859008017, 20.24025841959449],
					[71.372116883589911, 20.240258374256904],
					[71.372116893160026, 20.240258351409395],
					[71.377016893050524, 20.222036151179982],
					[71.377016895305331, 20.222036143959084],
					[71.377016897011458, 20.222036130716976],
					[71.377016898221527, 20.222036125730551],
					[71.379547397815017, 20.202700024804511],
					[71.379547386714819, 20.20269996699481],
					[71.379547331917792, 20.20269986357134],
					[71.379547288055875, 20.202699820710027],
					[71.378262587041078, 20.202068171159315],
					[71.379519326856894, 20.202561284874015],
					[71.379519402327759, 20.202561286464572],
					[71.379519531285695, 20.202561231141893],
					[71.379519585381431, 20.202561103351126],
					[71.379519534873282, 20.202560973953506],
					[71.379519482711942, 20.202560917669295],
					[71.375711183025487, 20.200813719390027],
					[71.37571110771961, 20.200813715055666],
					[71.375710975574435, 20.200813762811322],
					[71.375710915158777, 20.200813889719619],
					[71.375710960940907, 20.200814022008291],
					[71.375711012223405, 20.20081407775962],
					[71.378253769735295, 20.202064280329065],
					[71.37156953611786, 20.199441539179183],
					[71.361708477578517, 20.183074907391891],
					[71.356058483489278, 20.170366618342882],
					[71.356058470954181, 20.170366599628732],
					[71.35605844396035, 20.170366563921021],
					[71.356058427742497, 20.170366545898588],
					[71.347869527642786, 20.16357204635397],
					[71.347869500375836, 20.163572030423676],
					[71.347869439906617, 20.163572006473064],
					[71.34786940934309, 20.163571999994389],
					[71.3370722318068, 20.163110899799108],
					[71.324450080253712, 20.159538715061093],
					[71.311622348862713, 20.151783156262198],
					[71.299733475251657, 20.13054160202746],
					[71.299733474371976, 20.130541601516505],
					[71.299733474371976, 20.130541601516505],
					[71.288750175072153, 20.11120270271353],
					[71.277233476004881, 20.090061003112844],
					[71.277233408913034, 20.090060953004521],
					[71.277233260641566, 20.090060921148073],
					[71.27723318034181, 20.090060939908422],
					[71.26604988037522, 20.098472040625243],
					[71.266049844780511, 20.098472088014965],
					[71.266049809109219, 20.098472197660669],
					[71.266049808152914, 20.098472259408172],
				],
			],
		},
		{
			fldNpdidField: "2762452",
			wlbNpdidWellbore: "3144",
			fldName: "ORMEN LANGE",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6305/5-1",
			fldDiscoveryYear: "1997",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=2762452",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=2762452",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "ORMEN LANGE UNIT",
			cmpLongName: "A/S Norske Shell",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=3144",
			fldHcType: "GAS",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "562",
			fldNpdidOwner: "2588637",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-02-17 00:00:00",
			fldMainSupplyBase: "Kristiansund",
			fldAreaGeometryWKT: [
				[
					[63.300502599876957, 5.3817499939307822],
					[63.300502602224853, 5.3817500156034352],
					[63.300502608610749, 5.3817500547424366],
					[63.300502613595043, 5.3817500740870265],
					[63.300713713738304, 5.3822806728946153],
					[63.301113699930973, 5.3833389375516143],
					[63.301127599773999, 5.3849667014753884],
					[63.301127602121412, 5.3849667231487857],
					[63.301127612139638, 5.3849667638306613],
					[63.301127618965374, 5.3849667849422529],
					[63.301374799044552, 5.3855000415379886],
					[63.301349800135164, 5.3868666951378428],
					[63.301349802684392, 5.3868667247746087],
					[63.301349818379272, 5.3868667767282101],
					[63.301349828043286, 5.3868668034757734],
					[63.302185928020741, 5.3882612037870494],
					[63.302185969386407, 5.3882612405423407],
					[63.302186064050986, 5.3882612885913908],
					[63.302186118245537, 5.3882612997729042],
					[63.302680617970573, 5.3882168983467036],
					[63.302680678421325, 5.3882168727948079],
					[63.302680767859975, 5.3882167856910588],
					[63.302680797794082, 5.3882167260178031],
					[63.302725198322499, 5.3878778260972284],
					[63.302725199016102, 5.3878778180215257],
					[63.302725199608687, 5.3878778059641199],
					[63.302725200352803, 5.3878777998792877],
					[63.302705799971982, 5.3843277994680152],
					[63.302705797725579, 5.3843277817751742],
					[63.30270579328338, 5.384327748380354],
					[63.302705788400552, 5.3843277330155228],
					[63.302500198089277, 5.3837527598973507],
					[63.302466900284152, 5.3832805865934032],
					[63.30246684516414, 5.3832805036389964],
					[63.302466693160916, 5.3832804268534984],
					[63.302466595280919, 5.3832804291531797],
					[63.302283494712242, 5.3833940423696713],
					[63.302266905688676, 5.3821087458258692],
					[63.302483131816764, 5.3822973182260476],
					[63.30269421268099, 5.3828278714109148],
					[63.302883103153611, 5.3833583434113663],
					[63.302916501161079, 5.3837028196614867],
					[63.302916502208461, 5.3837028255216612],
					[63.302916504353796, 5.383702839232849],
					[63.30291650540115, 5.383702845093028],
					[63.30317759870686, 5.3848361152638962],
					[63.303146999838638, 5.3853027861622529],
					[63.303147006274145, 5.3853028272965382],
					[63.303147032428484, 5.3853029019070444],
					[63.303147053042984, 5.385302935270909],
					[63.303577604706803, 5.3857667844835735],
					[63.303610900043587, 5.386241714596534],
					[63.303610902138132, 5.3862417263173397],
					[63.303610907273402, 5.3862417516375443],
					[63.303610910263622, 5.386241763246014],
					[63.304008101419633, 5.3874306363600546],
					[63.304085900238029, 5.3897444061779245],
					[63.304085919666022, 5.3897444636619669],
					[63.304085989365504, 5.3897445547925376],
					[63.304086037896148, 5.3897445906544883],
					[63.30426369913404, 5.3898029441371937],
					[63.304258100308495, 5.3913416986557809],
					[63.304258179165529, 5.3913417966328696],
					[63.304258369817177, 5.3913418386650802],
					[63.304258481611726, 5.3913417827202119],
					[63.304672333889151, 5.3904362888303279],
					[63.304910997198768, 5.3904141106090373],
					[63.305141473119711, 5.3907750150127258],
					[63.304935918275262, 5.391227717331077],
					[63.304935911564087, 5.3912277361477061],
					[63.304935902620151, 5.391227773220006],
					[63.304935899542151, 5.3912277935789019],
					[63.304899800145932, 5.3924221941409725],
					[63.304899804636726, 5.3924222295306432],
					[63.304899826952486, 5.392422294645729],
					[63.304899843932219, 5.3924223264743274],
					[63.305344237328569, 5.3929723164837204],
					[63.30559702953903, 5.3933807044894611],
					[63.305597049899482, 5.3933807279066341],
					[63.30559709489701, 5.3933807662160858],
					[63.30559711958449, 5.3933807830994462],
					[63.305833156464729, 5.3934834567978216],
					[63.306024824045991, 5.393841793611025],
					[63.306024850032557, 5.3938418263116663],
					[63.306024913246006, 5.3938418743267595],
					[63.30602494967804, 5.3938418937355124],
					[63.306519349836542, 5.3939695944757826],
					[63.306519435619308, 5.393969575748863],
					[63.306519560737051, 5.3939694781931475],
					[63.306519600072093, 5.393969399364364],
					[63.306505800208505, 5.3914443993215144],
					[63.306505797962991, 5.3914443816257656],
					[63.306505791731077, 5.3914443484497871],
					[63.306505787694199, 5.391444330978433],
					[63.306294601433002, 5.3908693665216481],
					[63.3063279996571, 5.3889056036995839],
					[63.306327996212573, 5.3889055741697893],
					[63.306327980417393, 5.3889055182239618],
					[63.306327968066711, 5.388905491807912],
					[63.306094599634434, 5.3885443407291547],
					[63.30609739986032, 5.3874306013994495],
					[63.306097384115098, 5.387430547445974],
					[63.306097327003975, 5.3874304567335942],
					[63.306097284691909, 5.3874304180959145],
					[63.305877995256118, 5.3873276701188617],
					[63.305861300157801, 5.3869416916624981],
					[63.305861262360622, 5.386941618503732],
					[63.305861144936479, 5.3869415233723066],
					[63.305861065360055, 5.3869415033907302],
					[63.305705710794797, 5.3869691772473081],
					[63.305183500914517, 5.385944353551567],
					[63.305200196923352, 5.3849195959354752],
					[63.306475003845087, 5.3848946000182734],
					[63.306475054695653, 5.3848945856476202],
					[63.306475143149704, 5.3848945306042788],
					[63.306475178961861, 5.3848944901563556],
					[63.306722220605877, 5.3844032038427923],
					[63.306952630603476, 5.3847640081842263],
					[63.306952642651581, 5.3847640226522007],
					[63.306952666646616, 5.3847640476058469],
					[63.306952681381667, 5.3847640617366386],
					[63.307146998019441, 5.3849083995049414],
					[63.307138700241033, 5.3854221961642237],
					[63.30713870368627, 5.3854222256943896],
					[63.307138720429016, 5.3854222835186185],
					[63.307138732780331, 5.3854223099338698],
					[63.307433078804216, 5.3858722273409887],
					[63.307263711106849, 5.3863721368815956],
					[63.307263706982269, 5.3863721513814857],
					[63.307263701420126, 5.3863721800442361],
					[63.307263700033097, 5.3863721961982387],
					[63.307194199680936, 5.3904305980156328],
					[63.307194201977055, 5.3904306177028829],
					[63.307194210252938, 5.3904306606108294],
					[63.307194217027416, 5.3904306797369701],
					[63.307388700159834, 5.3908750430327634],
					[63.30740260038889, 5.392288900580505],
					[63.307397000306892, 5.3939138983842749],
					[63.307397004746903, 5.3939139317860541],
					[63.307397024173277, 5.3939139892794934],
					[63.307397039260493, 5.3939140173535076],
					[63.307630398962999, 5.3942333637539193],
					[63.307619199593994, 5.3948305950824933],
					[63.307619204929495, 5.3948306283726062],
					[63.307619224456332, 5.3948306898495035],
					[63.307619238647668, 5.3948307180363253],
					[63.308085939230004, 5.395466818737134],
					[63.308085971385118, 5.3954668466745686],
					[63.30808604340352, 5.3954668876021117],
					[63.308086083216409, 5.3954668986010006],
					[63.308285964402643, 5.395483489886451],
					[63.308477616402719, 5.3959278790966181],
					[63.308477618345272, 5.3959278848463024],
					[63.308477623025254, 5.3959278922510991],
					[63.308477624917408, 5.3959278960095194],
					[63.308724824885886, 5.3963778950549903],
					[63.308724863663301, 5.3963779361487472],
					[63.308724958375699, 5.3963779862260468],
					[63.308725012620108, 5.3963779994162699],
					[63.309025013087108, 5.3963585008979313],
					[63.309025053190865, 5.3963584878906063],
					[63.309025125034857, 5.3963584509365328],
					[63.309025155829069, 5.3963584251105212],
					[63.309472354176869, 5.3957973285583858],
					[63.309677886348901, 5.395558506060552],
					[63.309833285927944, 5.3955695993868904],
					[63.309833358969861, 5.3955695742658705],
					[63.309833466225584, 5.3955694809382662],
					[63.309833500389018, 5.3955694107403183],
					[63.309861288964314, 5.395016917307367],
					[63.310022109715376, 5.3950307932836949],
					[63.31053043471988, 5.395544516424521],
					[63.310683120451216, 5.395855688669422],
					[63.310683141858156, 5.3958557179545643],
					[63.310683196114205, 5.3958557671022396],
					[63.310683227121572, 5.3958557851974955],
					[63.310958145814922, 5.3959640531094459],
					[63.311152604970992, 5.3964500498618637],
					[63.311274801160621, 5.3974444243905193],
					[63.311274802207457, 5.3974444302528823],
					[63.31127480519676, 5.3974444418656269],
					[63.31127480624361, 5.3974444477279873],
					[63.311405400805299, 5.3979694274095067],
					[63.311416500408477, 5.3984000045529923],
					[63.311416501807834, 5.3984000243557819],
					[63.311416510825943, 5.3984000611859013],
					[63.311416516703694, 5.3984000804287167],
					[63.311627579127638, 5.3988860944049959],
					[63.311380421247158, 5.3993777109550827],
					[63.311380410660846, 5.3993777542316437],
					[63.311380409243462, 5.3993778403130355],
					[63.311380419358414, 5.3993778849973744],
					[63.3116108853242, 5.3998694132076883],
					[63.311422057922883, 5.400452588936421],
					[63.311202814572091, 5.400435900520252],
					[63.311202755658734, 5.4004359158735262],
					[63.311202657396365, 5.4004359741015397],
					[63.311202616356724, 5.4004360211830793],
					[63.310936011930799, 5.4010524998078493],
					[63.310797312614802, 5.4009581343314617],
					[63.310797211845859, 5.4009581289426611],
					[63.310797055615858, 5.4009582043995277],
					[63.310797000205149, 5.4009582872366471],
					[63.310741506811944, 5.4018942770606113],
					[63.310539011882206, 5.4020053110271133],
					[63.310372389331583, 5.4015221339899826],
					[63.310372350354271, 5.4015220849213286],
					[63.310372248920885, 5.4015220176849015],
					[63.310372188256181, 5.4015219992933243],
					[63.309791857409166, 5.4015580900399609],
					[63.309661295439398, 5.4009916551905848],
					[63.309661266314372, 5.4009916048928748],
					[63.309661186226045, 5.4009915290000903],
					[63.309661135262722, 5.4009915034050042],
					[63.309205635054333, 5.4009109022051165],
					[63.309205630575974, 5.4009109027646405],
					[63.309205624255881, 5.4009109015566548],
					[63.309205619727152, 5.4009109001248383],
					[63.309066720450701, 5.4008970014672846],
					[63.309066681886179, 5.4008970042879083],
					[63.309066611429614, 5.4008970250763744],
					[63.309066577695837, 5.4008970412767114],
					[63.30846657794023, 5.401360941608865],
					[63.308466548342707, 5.4013609792648349],
					[63.308466509154584, 5.4013610640629333],
					[63.308466499563984, 5.4013611112050421],
					[63.308524800183811, 5.4023528015146889],
					[63.308499811194324, 5.4028191843283437],
					[63.308319414979103, 5.402805400588397],
					[63.308319356910829, 5.4028054138319996],
					[63.308319258647359, 5.4028054720458307],
					[63.30831921840192, 5.4028055150248031],
					[63.307616544937936, 5.4043304580469096],
					[63.307358200723577, 5.4044776256000819],
					[63.307358165650541, 5.4044776599396958],
					[63.307358114665796, 5.4044777402098854],
					[63.307358099700025, 5.404477788019884],
					[63.307302599833989, 5.4054138874496491],
					[63.307302601232387, 5.4054139072499856],
					[63.307302606766449, 5.4054139485065997],
					[63.307302611747581, 5.4054139678599107],
					[63.307510902237446, 5.4059889392201281],
					[63.307569199146336, 5.4071916791051855],
					[63.307355405792563, 5.4080305517867249],
					[63.307355402611705, 5.4080305681631646],
					[63.307355400778611, 5.4080306023489078],
					[63.307355401230701, 5.4080306202698818],
					[63.307391500769512, 5.4083750218382765],
					[63.307391503858426, 5.4083750374330428],
					[63.307391513669181, 5.4083750701671587],
					[63.307391520340786, 5.4083750853152885],
					[63.307583082969643, 5.4087750099118068],
					[63.307333113109472, 5.4094388296120695],
					[63.307333108187301, 5.4094388482028295],
					[63.307333101130389, 5.4094388890319243],
					[63.307333099841166, 5.40943890916739],
					[63.30738589989231, 5.4104667102771558],
					[63.307385903081389, 5.4104667298546145],
					[63.307385913042246, 5.4104667685631389],
					[63.307385919814052, 5.4104667876941992],
					[63.308641500028855, 5.4130278451544713],
					[63.308641499662698, 5.4169305996944104],
					[63.308641499863121, 5.4169306076597241],
					[63.308641502105502, 5.4169306253588401],
					[63.308641503201649, 5.4169306332127451],
					[63.309230402592817, 5.4204444333865744],
					[63.309230406626142, 5.4204444508637604],
					[63.309230419121242, 5.4204444832702432],
					[63.309230425791633, 5.4204444984221549],
					[63.309922007951926, 5.4216583663419913],
					[63.310155401744701, 5.4232667292563033],
					[63.310155402740428, 5.4232667331279725],
					[63.310155404831988, 5.4232667448542049],
					[63.310155405827715, 5.4232667487258928],
					[63.310947006093038, 5.4263694483270966],
					[63.310947012862719, 5.4263694674643022],
					[63.310947029934979, 5.4263695033026877],
					[63.310947040237536, 5.4263695200038917],
					[63.312216524998512, 5.4280639990030011],
					[63.312777603769774, 5.4294111493815702],
					[63.312916500927088, 5.4309083182982034],
					[63.31291653402392, 5.4309083841212979],
					[63.312916635097466, 5.430908473486431],
					[63.312916703124088, 5.4309084990201004],
					[63.314169255032432, 5.4308891014220766],
					[63.314352609730562, 5.4314528617835824],
					[63.314352648797659, 5.431452914883149],
					[63.314352751113276, 5.4314529821232371],
					[63.314352814411727, 5.4314529982555282],
					[63.31517501434579, 5.4313945990073709],
					[63.315175056294301, 5.4313945878154994],
					[63.315175130685247, 5.4313945446317486],
					[63.31517516407326, 5.4313945145206937],
					[63.315480588115534, 5.4309586660149609],
					[63.315747029415341, 5.4314001032697554],
					[63.315747038721774, 5.4314001161027878],
					[63.315747060967411, 5.4314001433167505],
					[63.315747072911108, 5.4314001538249599],
					[63.315919213755656, 5.4315418055911691],
					[63.315991501938733, 5.4320111295512277],
					[63.315991506916312, 5.4320111489150174],
					[63.31599152130007, 5.4320111850959103],
					[63.315991528964787, 5.4320112041267992],
					[63.316180410301335, 5.4323222723745621],
					[63.316269202983896, 5.4327944371908767],
					[63.31626920592047, 5.4327944468174705],
					[63.316269210848048, 5.4327944641897172],
					[63.316269213784643, 5.4327944738163074],
					[63.316494212749362, 5.4333611709619483],
					[63.317277602835716, 5.4354917431118155],
					[63.317360885898978, 5.4364248479206498],
					[63.317108422388621, 5.4364886627630158],
					[63.316752985987357, 5.4355749273364582],
					[63.31675295991036, 5.4355748906021688],
					[63.316752894619867, 5.4355748307481102],
					[63.316752855406335, 5.4355748076283481],
					[63.316322255096608, 5.4354526075475542],
					[63.316322172741039, 5.4354526197392552],
					[63.316322046299554, 5.4354527013259366],
					[63.316322002263462, 5.4354527727127921],
					[63.31625810644298, 5.4359110429420214],
					[63.316052663727852, 5.436358116428873],
					[63.315072344270007, 5.4364858808658996],
					[63.314883499129422, 5.435836067979011],
					[63.314830802063568, 5.4345278366279075],
					[63.31526123765007, 5.4335084885426328],
					[63.315727819192254, 5.4334640998019337],
					[63.31572786283261, 5.4334640844072783],
					[63.315727939761665, 5.4334640349196395],
					[63.315727972204556, 5.4334640029294441],
					[63.316011272080928, 5.4329834010520637],
					[63.316011286160531, 5.4329833533530216],
					[63.316011288194517, 5.4329832551966506],
					[63.31601127614892, 5.4329832047393252],
					[63.315464076340803, 5.4319666043103609],
					[63.315464042201526, 5.4319665685796981],
					[63.315463959145134, 5.4319665169313636],
					[63.315463911123679, 5.4319665009027256],
					[63.314036230089044, 5.431888706115604],
					[63.313789080727823, 5.4313610141779316],
					[63.313788992147224, 5.4313609572249675],
					[63.313788812406791, 5.4313609555208853],
					[63.313788721246951, 5.4313610107697645],
					[63.313566550102017, 5.4318054525783541],
					[63.313288805844358, 5.4319526227349035],
					[63.313288782059466, 5.4319526416643171],
					[63.313288739117901, 5.4319526849433499],
					[63.313288720906833, 5.4319527111737242],
					[63.312844220842138, 5.4328416101081594],
					[63.312844211637916, 5.4328416372198705],
					[63.312844202236441, 5.4328416923256393],
					[63.312844201143484, 5.4328417204306314],
					[63.312908093955215, 5.433480546293799],
					[63.312688736343965, 5.4337970862628824],
					[63.312688722611107, 5.4337971119370163],
					[63.312688704998237, 5.4337971620653809],
					[63.312688700322227, 5.4337971906137801],
					[63.312660901548099, 5.4343860524567145],
					[63.312497059675188, 5.4347498232594633],
					[63.311888860969439, 5.4348692047373524],
					[63.311888816532893, 5.4348692242216918],
					[63.311888742589538, 5.4348692853156297],
					[63.311888713978412, 5.4348693268143684],
					[63.311663781263213, 5.4354386578610745],
					[63.311450054812703, 5.4353776079835541],
					[63.311449963599649, 5.4353776252645751],
					[63.311449836860582, 5.4353777308466737],
					[63.311449801234836, 5.4353778151646397],
					[63.311496996001324, 5.436013858293574],
					[63.311272022263914, 5.4364582100665935],
					[63.311272013755364, 5.4364582290986734],
					[63.31127200315769, 5.4364582723617971],
					[63.311272000123033, 5.4364582947121063],
					[63.31123039985723, 5.4378471948189295],
					[63.311230403241836, 5.4378472223683243],
					[63.311230418122292, 5.4378472784617662],
					[63.311230428622807, 5.4378473031334824],
					[63.311474804728178, 5.4382472648916487],
					[63.31152760142318, 5.438883316727174],
					[63.311527642777023, 5.4388833895260769],
					[63.311527768079848, 5.4388834779168267],
					[63.3115278511331, 5.4388834936193975],
					[63.311897001910268, 5.4387863584317859],
					[63.311910900109709, 5.4403917014958747],
					[63.311910926932192, 5.440391768102586],
					[63.311911019291706, 5.4403918685630881],
					[63.311911083833493, 5.440391898544366],
					[63.312399983962351, 5.4404307991100893],
					[63.312400036710066, 5.4404307885958758],
					[63.312400129018279, 5.4404307432258516],
					[63.312400169424706, 5.4404307062677475],
					[63.312702959370789, 5.4399501221944879],
					[63.312883447010385, 5.4397557352316861],
					[63.312883466019038, 5.4397557049125291],
					[63.31288349308857, 5.4397556376361775],
					[63.312883500253754, 5.4397556007896632],
					[63.312900199355347, 5.4374807732026369],
					[63.313272000131668, 5.4374280309110281],
					[63.313272000250912, 5.4407998511730034],
					[63.3128388412973, 5.440933108138915],
					[63.312838790092385, 5.4409331444334157],
					[63.312838719729456, 5.4409332410319564],
					[63.312838699626006, 5.4409332994550041],
					[63.312835900406412, 5.4424499513300129],
					[63.312147020814614, 5.4438221092762769],
					[63.312147013300226, 5.443822132179462],
					[63.312147002750066, 5.4438221774328914],
					[63.312146999764011, 5.4438222017747826],
					[63.312149799981626, 5.4447943970563619],
					[63.312113699648584, 5.4463416952352643],
					[63.312113701986767, 5.4463417169221753],
					[63.312113710196243, 5.4463417578623421],
					[63.312113716117295, 5.4463417791072199],
					[63.312341492842151, 5.4468750245795539],
					[63.312272002872085, 5.4472971673894826],
					[63.312272019987489, 5.4472972411937652],
					[63.312272100996061, 5.4472973550638626],
					[63.312272164938854, 5.4472973971213445],
					[63.312730400371315, 5.4473779677972596],
					[63.31273310030884, 5.4484056002954162],
					[63.312733137278641, 5.448405677646714],
					[63.312733257449857, 5.4484057767036411],
					[63.312733339705822, 5.4484057965280526],
					[63.313030640227339, 5.4483445959941204],
					[63.313030681082964, 5.4483445769714631],
					[63.31303074985663, 5.448344524538915],
					[63.313030778670424, 5.4483444910185712],
					[63.313464078885502, 5.4474833898009623],
					[63.313464087296175, 5.4474833667871785],
					[63.31346409695179, 5.4474833216434462],
					[63.31346409993845, 5.4474832973008196],
					[63.313455801244984, 5.4469278346327945],
					[63.313827820007525, 5.445931117894891],
					[63.314077618777304, 5.4464667851102551],
					[63.314077673016499, 5.4464668343593452],
					[63.314077801003123, 5.4464668864968315],
					[63.314077876442596, 5.4464668851806746],
					[63.314335994683269, 5.4463613589715516],
					[63.314547002109755, 5.4468472452405603],
					[63.314588699845146, 5.4478778071673197],
					[63.314588730296784, 5.447877875337146],
					[63.314588828821933, 5.4478779710739573],
					[63.314588896945097, 5.4478780006327456],
					[63.315185915807319, 5.4478862970439383],
					[63.315266484268683, 5.4488359189098832],
					[63.315030581256671, 5.4488581015533839],
					[63.315030525223371, 5.4488581244460956],
					[63.315030437289927, 5.4488582012212081],
					[63.315030405340138, 5.4488582531117702],
					[63.314922030056742, 5.4493192494153044],
					[63.314544330774766, 5.4494581118514702],
					[63.314544283200171, 5.4494581496832382],
					[63.314544217310008, 5.449458245714867],
					[63.314544199939888, 5.4494583057960995],
					[63.314591499767559, 5.4513028044196341],
					[63.314591501956059, 5.4513028201334093],
					[63.314591507228833, 5.451302851450567],
					[63.314591511208789, 5.4513028669435881],
					[63.314991511149799, 5.4524500653023171],
					[63.314991551951103, 5.4524501162192367],
					[63.314991656048733, 5.4524501833149595],
					[63.314991720240812, 5.4524501993834109],
					[63.315924796129877, 5.4523585205783078],
					[63.31590810016187, 5.4533832961486537],
					[63.315908126132307, 5.4533833648789773],
					[63.315908216741121, 5.4533834676074813],
					[63.315908283121395, 5.4533834993930963],
					[63.316602597374519, 5.4534418832306342],
					[63.316585900017458, 5.4544249962000686],
					[63.316585901359517, 5.4544250140174029],
					[63.316585906681183, 5.4544250473292664],
					[63.316585910710423, 5.4544250648157426],
					[63.31681371093547, 5.45508616535116],
					[63.31681375173585, 5.4550862162749825],
					[63.316813856728047, 5.4550862832740279],
					[63.316813920919884, 5.4550862993492668],
					[63.317927674964466, 5.4549696148760356],
					[63.318369181019179, 5.4559916981506236],
					[63.318222018087916, 5.4563193186952512],
					[63.318222012164725, 5.4563193334113613],
					[63.318222003950893, 5.4563193643947523],
					[63.318222000814053, 5.4563193827643159],
					[63.318174804217946, 5.4568305497595766],
					[63.317927615680695, 5.4574082216691702],
					[63.317927607565714, 5.4574082566360431],
					[63.317927604870775, 5.4574083289010229],
					[63.317927611137044, 5.457408364096862],
					[63.318135899772685, 5.458025032396101],
					[63.318138700439611, 5.4588805616809628],
					[63.317866514884287, 5.4595416232230027],
					[63.317866509158854, 5.459541645906504],
					[63.317866502186661, 5.4595416907228769],
					[63.317866500044147, 5.4595417129658745],
					[63.317916498406326, 5.4603193730423758],
					[63.317702610937914, 5.4609416342537882],
					[63.317702606855256, 5.4609416507405699],
					[63.317702601377079, 5.4609416833839104],
					[63.317702599981615, 5.4609416995404665],
					[63.317694200392673, 5.4628249634768613],
					[63.317463711632072, 5.4634471309671149],
					[63.317463706752264, 5.4634471515475065],
					[63.317463700575651, 5.4634471922682515],
					[63.317463700174571, 5.4634472122986235],
					[63.317494196747958, 5.4639193596461038],
					[63.317249821159884, 5.4644110104754553],
					[63.317249812696978, 5.4644110314954242],
					[63.317249802986595, 5.4644110746475754],
					[63.317249799947582, 5.4644110969997293],
					[63.317238699648925, 5.4649693965232871],
					[63.317238701984259, 5.4649694182156461],
					[63.317238712128301, 5.4649694649246889],
					[63.317238719887506, 5.4649694879493387],
					[63.317452598871796, 5.4654139437013409],
					[63.317444200399969, 5.4658416962397949],
					[63.317444204823254, 5.4658417296647661],
					[63.31744422427041, 5.4658417892194455],
					[63.317444239393147, 5.4658418193332308],
					[63.317885898716668, 5.4664361655079707],
					[63.31786650021234, 5.4689582982048055],
					[63.317866502645856, 5.4689583238821209],
					[63.317866513684336, 5.4689583704837856],
					[63.3178665232344, 5.46895839329036],
					[63.31835809899983, 5.4698889485596647],
					[63.31831369960004, 5.4733832979142214],
					[63.318313722775045, 5.4733833630134141],
					[63.318313807000969, 5.4733834625972468],
					[63.318313868051931, 5.4733834970818833],
					[63.319424968452992, 5.4735640972198523],
					[63.319425047878575, 5.4735640755035364],
					[63.319425164045718, 5.4735639773511959],
					[63.319425199891448, 5.4735639010248791],
					[63.319441900264167, 5.4715417443791416],
					[63.319933483414928, 5.4704444827669239],
					[63.319933483365567, 5.4704444807747041],
					[63.319933484211916, 5.4704444786726913],
					[63.319933485058321, 5.4704444765706715],
					[63.320114029217528, 5.4700085127191924],
					[63.320533283269576, 5.4700445984367914],
					[63.320533357270726, 5.470044575376475],
					[63.3205334663187, 5.4700444800768704],
					[63.320533499574033, 5.4700444080572295],
					[63.32056129817547, 5.4694472478608098],
					[63.320994582137409, 5.4685000823545282],
					[63.320994588061815, 5.4685000676394537],
					[63.32099459707527, 5.4685000325619884],
					[63.320994599317942, 5.4685000143017879],
					[63.321047394787897, 5.4678194721733053],
					[63.321308461767252, 5.4674584175459593],
					[63.321308476352563, 5.4674583897776703],
					[63.32130849462601, 5.4674583295829251],
					[63.321308500155055, 5.4674582989289782],
					[63.321303000414872, 5.466813956869462],
					[63.321527970456593, 5.4664473056630296],
					[63.321527979018583, 5.4664472886251358],
					[63.321527991614524, 5.4664472531066837],
					[63.321527996593552, 5.4664472365085253],
					[63.32172519070825, 5.4653944652889068],
					[63.322158479390147, 5.4645333894085031],
					[63.322158486061525, 5.4645333686049327],
					[63.322158496667662, 5.4645333253354034],
					[63.322158499706653, 5.4645333029794401],
					[63.322183499342373, 5.4630806319552301],
					[63.322464063725263, 5.4621473484205891],
					[63.322799885305237, 5.4620085642914606],
					[63.323060908181596, 5.4627028590850673],
					[63.323277604798676, 5.4636083462332694],
					[63.323277619971336, 5.4636083783443103],
					[63.323277658229998, 5.4636084355988395],
					[63.323277683156846, 5.4636084625147063],
					[63.323935982664445, 5.4640862629964531],
					[63.323936006299832, 5.4640862740834688],
					[63.323936057104078, 5.4640862938249839],
					[63.323936083278326, 5.4640862986044914],
					[63.324133283150175, 5.4641029991417627],
					[63.32413335804597, 5.4641029759530264],
					[63.324133466144509, 5.4641028787362398],
					[63.324133500341951, 5.4641028085831813],
					[63.324233500432292, 5.4616694084773005],
					[63.324233500184832, 5.4616693985148137],
					[63.32423349789844, 5.4616693788099866],
					[63.324233496705773, 5.4616693669650731],
					[63.324033496777815, 5.4604555677957034],
					[63.324033493744061, 5.4604555541786812],
					[63.324033485885053, 5.4604555271649389],
					[63.324033481109346, 5.4604555157606711],
					[63.323825181282508, 5.4600082168344857],
					[63.323825086393157, 5.4600081585527116],
					[63.323824900629589, 5.4600081674045446],
					[63.323824810651125, 5.4600082344280132],
					[63.323636034784805, 5.4605495808234421],
					[63.323325199329915, 5.4601360310109639],
					[63.323305803310653, 5.4579086044415304],
					[63.323558220739059, 5.4580168831101181],
					[63.323558283787143, 5.4580168893431464],
					[63.323558399885492, 5.4580168610698747],
					[63.323558452985388, 5.4580168285560076],
					[63.324027952886155, 5.4574557283648675],
					[63.324027960699986, 5.4574557174104905],
					[63.32402797543191, 5.4574556956119586],
					[63.324027981454293, 5.4574556848780231],
					[63.324275122942232, 5.4569224097517983],
					[63.324494385359685, 5.4569390982115999],
					[63.324494446966902, 5.4569390826350741],
					[63.324494548637908, 5.4569390161598363],
					[63.324494588701704, 5.4569389652611244],
					[63.324725178737779, 5.4562750933711293],
					[63.324927961661693, 5.4559945174715017],
					[63.32492797624468, 5.4559944896939152],
					[63.324927994612302, 5.4559944334694981],
					[63.324928000138861, 5.4559944028095506],
					[63.324939099310754, 5.4553083442747559],
					[63.325083483106603, 5.4549806812417589],
					[63.325083483057007, 5.4549806792492177],
					[63.325083483952767, 5.4549806791389113],
					[63.325333436377647, 5.4544001890411558],
					[63.325594253753351, 5.4543808108505294],
					[63.325763707688218, 5.4549500574136003],
					[63.325763707688218, 5.4549500574136003],
					[63.325763708583942, 5.4549500573032939],
					[63.325949808964026, 5.4555667587341272],
					[63.32594989245851, 5.4555668284020848],
					[63.325950073590853, 5.4555668500713645],
					[63.325950172124415, 5.4555668019624042],
					[63.326380760287989, 5.4548334224692558],
					[63.326763947018506, 5.4544363399349205],
					[63.326883120458618, 5.4544890399043062],
					[63.326952602369552, 5.4549250307322978],
					[63.326952605304697, 5.4549250403647269],
					[63.326952610328789, 5.454925061732574],
					[63.326952613263906, 5.4549250713650066],
					[63.327069213159561, 5.4552333719132449],
					[63.327069215099762, 5.4552333776706927],
					[63.327069220771669, 5.455233388964956],
					[63.32706922360758, 5.4552333946121028],
					[63.327260921891529, 5.4555917907441422],
					[63.327547021237997, 5.4561722887425432],
					[63.327547074578305, 5.4561723381425349],
					[63.327547201566709, 5.4561723864706391],
					[63.327547275214847, 5.4561723853987276],
					[63.327808375111843, 5.4560668844065203],
					[63.327808406065841, 5.4560668646047628],
					[63.327808459664347, 5.4560668160300994],
					[63.327808481314001, 5.4560667833820062],
					[63.328055579228035, 5.4555338219141678],
					[63.32826651180573, 5.456105669856143],
					[63.328266517427956, 5.4561056791582843],
					[63.32826652787584, 5.4561057018583732],
					[63.328266534393798, 5.4561057110502302],
					[63.328691534255803, 5.4567418108495946],
					[63.328691630590974, 5.4567418549598035],
					[63.328691809637895, 5.4567418289211282],
					[63.328691891453914, 5.4567417588824885],
					[63.329200133425857, 5.4550724518343996],
					[63.329397141183932, 5.4551334909841191],
					[63.329397223590959, 5.4551334808347898],
					[63.329397351835283, 5.4551333990747271],
					[63.329397397722175, 5.4551333294568343],
					[63.329527976706693, 5.454244567104122],
					[63.329830639684218, 5.4541834969249523],
					[63.329830681436249, 5.4541834777883871],
					[63.329830751904097, 5.4541834211314439],
					[63.329830780669546, 5.4541833856039359],
					[63.330241881033864, 5.4533194850653546],
					[63.330241887753168, 5.4533194662462527],
					[63.330241896613941, 5.4533194251741151],
					[63.330241899651114, 5.4533194028107204],
					[63.330261299257934, 5.4521639451928854],
					[63.330944527132701, 5.4506835026373164],
					[63.331619396094112, 5.4506973994841461],
					[63.331619462876233, 5.4506973752552028],
					[63.331619564097181, 5.4506972908038902],
					[63.33161959843666, 5.4506972265955804],
					[63.331772394312992, 5.4495778559163899],
					[63.331936282560591, 5.4492111813658335],
					[63.331936289229681, 5.4492111605516476],
					[63.331936297989905, 5.4492111154898435],
					[63.331936300130728, 5.4492110932352267],
					[63.331922401193523, 5.4487889337464237],
					[63.332169586748883, 5.4481361718804893],
					[63.33216959292097, 5.4481361311357031],
					[63.332169590037886, 5.4481360515254318],
					[63.332169580086983, 5.4481360127705383],
					[63.331055797752782, 5.4458471486662052],
					[63.331008499703884, 5.4448749901529041],
					[63.33100849841032, 5.444874974320097],
					[63.331008491344654, 5.4448749432076848],
					[63.331008486468214, 5.444874927817442],
					[63.330786298821209, 5.4443082609314937],
					[63.330778002886063, 5.4437585186253816],
					[63.331166682977319, 5.4437918996458512],
					[63.331166733087777, 5.4437918914548415],
					[63.331166821913378, 5.4437918504927287],
					[63.331166860678259, 5.443791819714586],
					[63.331489015096288, 5.4433557802600685],
					[63.33237223863653, 5.443177995911249],
					[63.332372276853803, 5.4431779791928081],
					[63.332372343982975, 5.4431779329117935],
					[63.332372371999092, 5.4431779034599446],
					[63.332636271696295, 5.4427362023248902],
					[63.332636282743479, 5.4427361769690501],
					[63.332636296577377, 5.4427361192816761],
					[63.332636300359326, 5.4427360908254965],
					[63.332619599534546, 5.4423555900922116],
					[63.33261958679541, 5.4423555476848664],
					[63.332619542556856, 5.4423554671885768],
					[63.332619512102447, 5.4423554349680243],
					[63.332330752281948, 5.4421609614979261],
					[63.332161274830078, 5.4418527040366538],
					[63.332161259951135, 5.4418526838855072],
					[63.332161225764459, 5.4418526461299388],
					[63.332161205610774, 5.4418526306292847],
					[63.331925196234479, 5.4417054877533273],
					[63.331903001963006, 5.4410306445773555],
					[63.332144578706043, 5.4405444892577925],
					[63.332144587115508, 5.440544466226914],
					[63.332144596818473, 5.440544423044444],
					[63.332144599853628, 5.4405444006782808],
					[63.332139100330103, 5.4373390488440867],
					[63.332355659079084, 5.4372723919552373],
					[63.332355695404544, 5.4372723714647515],
					[63.332355756958101, 5.4372723178633606],
					[63.33235578129046, 5.4372722848633428],
					[63.332600143863949, 5.436744564637797],
					[63.332816759319499, 5.4366779910113872],
					[63.332816793007588, 5.4366779728455716],
					[63.332816851127568, 5.4366779256647764],
					[63.332816876405346, 5.4366778945458476],
					[63.333055775739524, 5.4362361941565744],
					[63.333055784247954, 5.4362361751098307],
					[63.333055795790884, 5.4362361336957745],
					[63.333055799770904, 5.4362361132106169],
					[63.333086298388359, 5.4357750340532203],
					[63.333252991803228, 5.4351972547843737],
					[63.333252995086902, 5.4351972423821406],
					[63.333252998967048, 5.4351972179105568],
					[63.333253000409407, 5.4351972037371601],
					[63.333269600041007, 5.4342306032022982],
					[63.333269596705442, 5.4342305776253887],
					[63.333269584660101, 5.4342305271375286],
					[63.33326957505458, 5.434230502337531],
					[63.333097396068609, 5.4339193401465051],
					[63.33304739911042, 5.43328328447015],
					[63.333047365964561, 5.4332832166059317],
					[63.333047260363863, 5.4332831257341985],
					[63.333047188754854, 5.4332831006225986],
					[63.332441688754443, 5.4333165004794628],
					[63.332441625608283, 5.433316526298352],
					[63.332441531713386, 5.4333166159038582],
					[63.33244150091479, 5.4333166776974675],
					[63.332394204290786, 5.4337332484270089],
					[63.33198594726445, 5.4347109471435351],
					[63.331733394853977, 5.4348164426735366],
					[63.331447378758405, 5.4342443118883592],
					[63.331447340887181, 5.4342442706008169],
					[63.331447249620517, 5.4342442139386504],
					[63.331447196274958, 5.4342442005569467],
					[63.331039034859302, 5.4342525979839129],
					[63.33083348598737, 5.4337277266240909],
					[63.330833459909876, 5.4337276898739004],
					[63.330833392777237, 5.4337276282248572],
					[63.330833351821894, 5.4337276073118046],
					[63.330616751994569, 5.4336692068693804],
					[63.330616682079501, 5.433669213533844],
					[63.330616563997985, 5.4336692701462708],
					[63.33061651588141, 5.4336693220870904],
					[63.329944216235056, 5.4352555216806007],
					[63.329944208275215, 5.4352555626467494],
					[63.329944210369469, 5.4352556463459685],
					[63.329944220373712, 5.4352556870861815],
					[63.330113704891673, 5.4356083551178118],
					[63.330163700738524, 5.4360750216249176],
					[63.330163702878892, 5.4360750353530722],
					[63.330163709796942, 5.4360750604837973],
					[63.330163713728744, 5.4360750739901205],
					[63.330369200544382, 5.4365972382370575],
					[63.330377598607825, 5.4371888625015341],
					[63.330194262667959, 5.4376387062138116],
					[63.32895845393881, 5.437702591739205],
					[63.328816894078848, 5.4371416506523254],
					[63.328816855859621, 5.4371415954152793],
					[63.328816754242887, 5.4371415200407895],
					[63.328816689053944, 5.4371415001250964],
					[63.327913889266874, 5.4371914997270672],
					[63.327913840251924, 5.4371915157883919],
					[63.327913756255711, 5.437191570160425],
					[63.32791372217018, 5.4371916083602612],
					[63.327647081603658, 5.4377164896826509],
					[63.327472403659584, 5.4377026159739774],
					[63.327480800276199, 5.4372806030888121],
					[63.327480796941209, 5.4372805775166553],
					[63.327480784897006, 5.4372805270375562],
					[63.327480775341868, 5.4372805042341374],
					[63.327214100088803, 5.4367943488528425],
					[63.32721689961722, 5.4356558002307729],
					[63.328799864059981, 5.4356640983371722],
					[63.329024813653724, 5.4362306748568443],
					[63.329024847045794, 5.4362307166981489],
					[63.329024931295585, 5.4362307802276808],
					[63.329024982103391, 5.4362307999231225],
					[63.329397182123969, 5.4362640997039273],
					[63.329397256118973, 5.4362640765491754],
					[63.329397365193103, 5.4362639830848147],
					[63.329397400272192, 5.4362639127752095],
					[63.329475197370748, 5.4350889560099978],
					[63.330347376848927, 5.4334333928761653],
					[63.330347383615454, 5.4334333760452953],
					[63.330347395257377, 5.4334333386198077],
					[63.330347399286886, 5.433433320129053],
					[63.33041959641448, 5.4327222529190191],
					[63.330641881558357, 5.4322361832166397],
					[63.330641889120656, 5.4322361622885076],
					[63.33064189792541, 5.4322361192166202],
					[63.33064190011347, 5.4322360989547072],
					[63.330611300194441, 5.4293666981375974],
					[63.330611297953489, 5.4293666804240006],
					[63.3306112917302, 5.4293666472119355],
					[63.330611287747821, 5.4293666317134717],
					[63.330386297557531, 5.4287555595980344],
					[63.330358500346463, 5.4281221908442641],
					[63.330358496214053, 5.4281221693675503],
					[63.330358485262146, 5.4281221267476081],
					[63.3303584767012, 5.4281221078195561],
					[63.329939101321749, 5.4273277518073968],
					[63.329947399841117, 5.426863903405633],
					[63.32994739939128, 5.4268638854702429],
					[63.329947392171754, 5.4268638483850653],
					[63.329947388139125, 5.426863830894475],
					[63.329725200378618, 5.426258264433149],
					[63.329722400052468, 5.4253278365721149],
					[63.329925187122996, 5.4247972702084413],
					[63.329925191301449, 5.4247972576952161],
					[63.329925196971232, 5.4247972330025984],
					[63.329925199308249, 5.4247972187191102],
					[63.329972396675949, 5.4242944449070913],
					[63.330139019948405, 5.4238446513334377],
					[63.330311036007018, 5.4239029896526638],
					[63.330311106021561, 5.4239029869502442],
					[63.330311226986126, 5.4239029379360142],
					[63.330311277886146, 5.4239028896313641],
					[63.330639025067043, 5.4232557950180063],
					[63.331377694380507, 5.4232335036596142],
					[63.331610932732595, 5.423589009508726],
					[63.331610953984004, 5.4235890328566576],
					[63.331611001710883, 5.4235890729059077],
					[63.331611029927757, 5.4235890873916421],
					[63.331899818160245, 5.4236973445719894],
					[63.331952595971508, 5.4240832846508615],
					[63.331747032096558, 5.4247804627098226],
					[63.33160841230324, 5.4248525159991248],
					[63.331364089111638, 5.4241582339148904],
					[63.331364024662072, 5.4241581719533389],
					[63.33136387517122, 5.4241581225573432],
					[63.331363787442832, 5.4241581354567723],
					[63.33114428689742, 5.4243081357807288],
					[63.331144272816353, 5.4243081475257613],
					[63.331144247391279, 5.4243081726749116],
					[63.331144235151584, 5.4243081861903102],
					[63.33084426558608, 5.424747042567553],
					[63.330611016289275, 5.4248554194546843],
					[63.330610973200152, 5.4248554567924989],
					[63.330610915388711, 5.4248555519332253],
					[63.330610899720647, 5.4248556078545489],
					[63.330621999043665, 5.4251916648766505],
					[63.330397014434446, 5.425763827765179],
					[63.330397008564518, 5.4257638444866192],
					[63.330397002248866, 5.4257638792549603],
					[63.3303970000117, 5.4257638975243019],
					[63.330380400382552, 5.4267749966534184],
					[63.330380400832382, 5.4267750145890767],
					[63.330380407106247, 5.4267750497931448],
					[63.33038041108891, 5.4267750652911166],
					[63.330547003610796, 5.427252845332367],
					[63.330619202127018, 5.4278083264504424],
					[63.330619205163892, 5.4278083400670001],
					[63.330619213079046, 5.4278083690706431],
					[63.330619218852974, 5.4278083843465454],
					[63.330844219118518, 5.4282889847387468],
					[63.330844267043972, 5.4282890327663313],
					[63.330844384288234, 5.4282890861847761],
					[63.330844452761319, 5.4282890936796786],
					[63.331077686415327, 5.4282252378915148],
					[63.33130811664725, 5.4287500793204559],
					[63.33130815004148, 5.428750121156253],
					[63.331308234194566, 5.4287501806827896],
					[63.331308284057677, 5.4287501984846687],
					[63.331680584255842, 5.4287807980454961],
					[63.331680640585205, 5.4287807870572324],
					[63.331680738909213, 5.4287807308749274],
					[63.331680779212356, 5.4287806898890656],
					[63.331980751787846, 5.428175144958705],
					[63.332238684237026, 5.4280281525934431],
					[63.332202602898832, 5.4288249360597911],
					[63.331963733245296, 5.4291859909224662],
					[63.331963721254873, 5.4291860144010089],
					[63.331963704639477, 5.4291860684408544],
					[63.331963699964561, 5.4291860970091772],
					[63.331944199648781, 5.4303221964386967],
					[63.331944201989451, 5.4303222181391186],
					[63.331944210203702, 5.430322259102585],
					[63.331944217022865, 5.4303222802474993],
					[63.332191517084432, 5.4308917798585359],
					[63.332191520866935, 5.4308917873861189],
					[63.332191530273299, 5.430891804212127],
					[63.332191535001449, 5.4308918136216207],
					[63.332435935351363, 5.4312501137766507],
					[63.332435968445402, 5.4312501436589526],
					[63.332436044982551, 5.4312501861466247],
					[63.332436089371313, 5.4312502006339329],
					[63.333885911264233, 5.4313307909248607],
					[63.333974796805016, 5.4328555488839818],
					[63.333755493258117, 5.4332136910308959],
					[63.333538915000013, 5.4331970012274802],
					[63.333538839313938, 5.4331970286019828],
					[63.333538731337327, 5.4331971299531476],
					[63.333538699942451, 5.4331972038187883],
					[63.33356089967959, 5.4342111037631584],
					[63.333560900924489, 5.4342111176037644],
					[63.333560905155736, 5.4342111430699234],
					[63.333560908192027, 5.4342111566885709],
					[63.333727608181142, 5.4347750573191211],
					[63.333727617686833, 5.434775078133482],
					[63.333727642122291, 5.4347751210894746],
					[63.333727656952355, 5.4347751392449046],
					[63.334191556806282, 5.4352473391429044],
					[63.334191647374446, 5.4352473679095343],
					[63.334191810144134, 5.435247337749149],
					[63.334191884137041, 5.4352472786002144],
					[63.334669478567001, 5.4341309245063121],
					[63.334841503134541, 5.4342722964888601],
					[63.334855400438386, 5.4347444058665113],
					[63.334855420744447, 5.4347444633312518],
					[63.334855493153171, 5.4347445563269403],
					[63.334855544360167, 5.4347445919688253],
					[63.335074857165814, 5.434808466105765],
					[63.335302602103162, 5.4352972497972276],
					[63.335333100216275, 5.4358583107590457],
					[63.335333176157292, 5.4358584013220472],
					[63.335333360626962, 5.4358584484513859],
					[63.335333470051324, 5.4358584049067744],
					[63.336008470082362, 5.4347584050735502],
					[63.336008483615721, 5.4347583714056977],
					[63.336008497097218, 5.4347582997549715],
					[63.336008497095193, 5.4347582637653744],
					[63.335936308187236, 5.4343667222737624],
					[63.336183418069268, 5.4337891328306958],
					[63.336736053913036, 5.4339195940917984],
					[63.336736134426893, 5.4339195801139581],
					[63.336736256288496, 5.433919495028408],
					[63.336736297736032, 5.4339194279072833],
					[63.336850198319596, 5.4330722268514311],
					[63.336850198716327, 5.4330722068072763],
					[63.336850196872597, 5.4330721690454071],
					[63.33685019368648, 5.4330721494454153],
					[63.336644593616448, 5.4322832499144944],
					[63.336644546238603, 5.4322831878067204],
					[63.336644422276628, 5.4322831171997201],
					[63.336644344796774, 5.4322831088115784],
					[63.335950137160587, 5.4324830533532227],
					[63.335797392290161, 5.431955544542677],
					[63.335797355910223, 5.4319554910705685],
					[63.335797259814754, 5.4319554210102465],
					[63.335797199103752, 5.4319554005466459],
					[63.334803004110228, 5.4319580996825039],
					[63.334822396764409, 5.4308890874230444],
					[63.335699882326331, 5.4308335074543361],
					[63.335908121659784, 5.4312389908649923],
					[63.3359081531122, 5.4312390269526611],
					[63.335908228107556, 5.4312390796335572],
					[63.335908271700426, 5.4312390982200007],
					[63.336624971832975, 5.4313418989765259],
					[63.336625047668335, 5.4313418775738578],
					[63.336625162212933, 5.4313417873866481],
					[63.336625199080842, 5.4313417168310085],
					[63.336669597144002, 5.4308306398854027],
					[63.336839091387233, 5.4302889608398726],
					[63.336839093724777, 5.4302889465533486],
					[63.336839099295602, 5.4302889178692073],
					[63.336839099841768, 5.4302889038049527],
					[63.336875195623982, 5.4281502411829265],
					[63.337269248971005, 5.4282251751478778],
					[63.337516511183217, 5.4289333669116209],
					[63.337516551992834, 5.4289334178338091],
					[63.337516656101094, 5.4289334848940909],
					[63.337516720245425, 5.4289334989276883],
					[63.337994419891956, 5.4288862983786323],
					[63.337994483783014, 5.4288862664533797],
					[63.337994573792351, 5.428886165300054],
					[63.33799459996051, 5.4288860980654077],
					[63.337972400739496, 5.4268278585123992],
					[63.338258410282748, 5.4263807992538933],
					[63.338716700964596, 5.4263779998393105],
					[63.338716751819504, 5.426377985523918],
					[63.338716838598216, 5.4263779347521668],
					[63.338716875367744, 5.4263778961910889],
					[63.338941841181537, 5.4259695585533274],
					[63.339119279910129, 5.4258974638513191],
					[63.339288702928258, 5.4263833437005768],
					[63.339344200682675, 5.4269444207261754],
					[63.339344203769656, 5.4269444363402002],
					[63.339344212630685, 5.4269444672345433],
					[63.339344218454727, 5.426944484508331],
					[63.339549818385869, 5.4273889831513165],
					[63.33954985999177, 5.4273890299765943],
					[63.339549960218044, 5.4273890855197848],
					[63.339550019834036, 5.427389098113454],
					[63.339772220286228, 5.4273668997026325],
					[63.339772284176917, 5.427366867771922],
					[63.339772374185038, 5.4273667666074843],
					[63.339772400352466, 5.4273666993672514],
					[63.339772399858667, 5.4270168385484681],
					[63.340500071124055, 5.4267418879561529],
					[63.340500103815053, 5.426741865897764],
					[63.340500159990142, 5.4267418129257985],
					[63.34050018342424, 5.4267417800186575],
					[63.340950102151062, 5.4257252629903263],
					[63.341186019840066, 5.4258279837504606],
					[63.341186093587361, 5.4258279865865262],
					[63.341186224455065, 5.4258279383298547],
					[63.341186278938395, 5.4258278895645677],
					[63.341325149331468, 5.4255473494211124],
					[63.341622286916518, 5.4254029804733062],
					[63.341622311646056, 5.42540296340146],
					[63.341622353789447, 5.4254029241673445],
					[63.341622372994756, 5.4254029017824479],
					[63.341905717118458, 5.4249195978800895],
					[63.342647059859829, 5.4248974031028689],
					[63.342891502362285, 5.4255944395732936],
					[63.342966468668585, 5.426949449019463],
					[63.342502947984038, 5.4264387656554156],
					[63.342502856418633, 5.4264387330329527],
					[63.342502689222854, 5.4264387658033266],
					[63.342502614388231, 5.4264388270975141],
					[63.342194261773798, 5.4272276034566405],
					[63.34179439604609, 5.427235900071711],
					[63.3417943302141, 5.4272359262466781],
					[63.341794231844951, 5.4272360164581972],
					[63.341794201099141, 5.4272360802722623],
					[63.341699812109908, 5.4282137645919644],
					[63.340958409021596, 5.4285358341332142],
					[63.340691886410553, 5.4278721245813166],
					[63.340691830223676, 5.4278720695652538],
					[63.340691695911026, 5.4278720162510989],
					[63.34069161688948, 5.4278720180642717],
					[63.340452716993774, 5.4279804187203959],
					[63.340452673853967, 5.4279804540732375],
					[63.340452615990678, 5.4279805472473583],
					[63.340452600271576, 5.4279806011927576],
					[63.340466500304295, 5.4292222024707284],
					[63.340466505631952, 5.4292222358052475],
					[63.340466530618606, 5.4292223006954297],
					[63.340466548436162, 5.4292223304799094],
					[63.340705432384404, 5.4295001117233577],
					[63.341372019699833, 5.4308639883464807],
					[63.34137205204803, 5.4308640243292929],
					[63.3413721289348, 5.4308640807837349],
					[63.341372175164921, 5.4308640970458679],
					[63.342424974751786, 5.4309973987548625],
					[63.342425054965673, 5.4309973728034411],
					[63.34242516826469, 5.4309972687512573],
					[63.342425199558377, 5.4309971908727457],
					[63.342397410866155, 5.4303613391804708],
					[63.342905474285992, 5.4304473803442495],
					[63.34321092727577, 5.4309779006340415],
					[63.343211018343993, 5.4309779493328278],
					[63.343211196042766, 5.4309779412840653],
					[63.343211281727712, 5.430977882653921],
					[63.344327981654835, 5.4285417831327614],
					[63.34432798160487, 5.4285417811389456],
					[63.344327984242, 5.4285417788114598],
					[63.344327984242, 5.4285417788114598],
					[63.344564013102648, 5.4279835468018058],
					[63.344938838766339, 5.4281029904377309],
					[63.344938914105128, 5.4281029850817974],
					[63.344939039199616, 5.4281029215451335],
					[63.34493908900528, 5.4281028653582961],
					[63.34512794721185, 5.4275612869300707],
					[63.34536671874789, 5.4275391000118098],
					[63.345366765024437, 5.4275390822632845],
					[63.345366842796494, 5.4275390306012925],
					[63.345366876033459, 5.4275389944714307],
					[63.345697221022569, 5.4269225857565591],
					[63.346008113128455, 5.4273778815096412],
					[63.346283105366226, 5.4286000444605929],
					[63.3462831899691, 5.4286001219618774],
					[63.346283377423994, 5.4286001446880068],
					[63.346283477588948, 5.4286000902465208],
					[63.346491877851747, 5.4281917904020203],
					[63.346491884617627, 5.4281917735603704],
					[63.346491896257966, 5.428191736111609],
					[63.346491899341068, 5.4281917157269719],
					[63.346544598422774, 5.4275500364130735],
					[63.346686292171121, 5.4270500544071041],
					[63.346686293812567, 5.4270500482026085],
					[63.346686297145425, 5.4270500377876125],
					[63.346686297891189, 5.4270500316944084],
					[63.346758493203801, 5.4265833602060116],
					[63.347233480402721, 5.4255944860874807],
					[63.347233483785487, 5.4255944776661291],
					[63.347233492242417, 5.4255944566127541],
					[63.347233494679472, 5.4255944463087342],
					[63.347489088409503, 5.4245028740803356],
					[63.347675127583877, 5.4241751806333429],
					[63.347830634896496, 5.4241473978274373],
					[63.347830671369898, 5.4241473832909612],
					[63.347830735905291, 5.4241473412619881],
					[63.347830764017246, 5.4241473157635456],
					[63.348200096895724, 5.4236196102979788],
					[63.348377780620616, 5.4236362993076161],
					[63.348377845806276, 5.4236362831998113],
					[63.348377950943089, 5.4236362121158725],
					[63.348377990944265, 5.4236361591338271],
					[63.348544543687602, 5.4230919138007971],
					[63.349083197571062, 5.4231390927044787],
					[63.349491543721385, 5.423644527008209],
					[63.349491594830937, 5.4236445586592668],
					[63.349491705256575, 5.423644590934912],
					[63.349491766313974, 5.4236445893425733],
					[63.349691593856697, 5.4235752482844317],
					[63.349958117819831, 5.4241556832972542],
					[63.34995816574677, 5.4241557313488133],
					[63.34995828214678, 5.4241557868889982],
					[63.34995834972419, 5.4241557944890175],
					[63.350216487352164, 5.4240891627933179],
					[63.350160899902846, 5.4251999907134802],
					[63.350160902294384, 5.4252000144213666],
					[63.350160910660286, 5.4252000613917346],
					[63.350160919321659, 5.4252000843201769],
					[63.350369199315985, 5.4256472432866474],
					[63.350363701121331, 5.4259888627857391],
					[63.350174812649968, 5.4264860299591575],
					[63.350174806980007, 5.4264860546689562],
					[63.350174801959973, 5.4264861053036002],
					[63.350174801714218, 5.4264861313397716],
					[63.350299801189756, 5.427269421296379],
					[63.350316499812941, 5.4276111103450537],
					[63.350316521913356, 5.4276111676126648],
					[63.35031659606824, 5.427611258422214],
					[63.350316648172651, 5.4276112939583863],
					[63.350791504921695, 5.4277390542132178],
					[63.350805399660516, 5.4281694064874912],
					[63.350805401801701, 5.4281694202248536],
					[63.350805407079747, 5.42816945157682],
					[63.350805411012345, 5.4281694650916394],
					[63.351033111163623, 5.4288306654781415],
					[63.351033160184571, 5.4288307214051823],
					[63.351033282156919, 5.4288307842759718],
					[63.351033356949735, 5.428830792991536],
					[63.351372243630308, 5.4287307957842001],
					[63.351733167246692, 5.4286752236685265],
					[63.351938711901184, 5.4292500676904618],
					[63.351938769083205, 5.4292501266075313],
					[63.351938907923802, 5.4292501813855223],
					[63.35193898958245, 5.4292501772464199],
					[63.352158389823337, 5.429139077117239],
					[63.35215842943046, 5.4291390441905163],
					[63.35215848306548, 5.4291389615080581],
					[63.352158499780501, 5.4291389114186064],
					[63.352186300368651, 5.4286694122881283],
					[63.352186300168789, 5.4286694043106705],
					[63.352186299868997, 5.4286693923444904],
					[63.352186298773468, 5.4286693844782805],
					[63.352086299317634, 5.4273305845003206],
					[63.35208629812206, 5.4273305726454906],
					[63.352086293043754, 5.427330549269695],
					[63.352086290056732, 5.427330537637455],
					[63.351903001299327, 5.4267666697222623],
					[63.35193908794723, 5.4247450865699207],
					[63.352869226956152, 5.4262584010424302],
					[63.353191524823878, 5.4268361971720021],
					[63.353191609673516, 5.4268362486471453],
					[63.353191783193154, 5.4268362530912446],
					[63.353191870071711, 5.4268362062828137],
					[63.353616869615429, 5.4261529066048766],
					[63.353616882350877, 5.4261528770124192],
					[63.353616897123423, 5.4261528211580128],
					[63.353616900006216, 5.4261527927902762],
					[63.353536299869745, 5.4241999921953443],
					[63.353536285183623, 5.4241999440051272],
					[63.353536236406207, 5.4241998620421006],
					[63.353536199577825, 5.4241998266090334],
					[63.353358447586039, 5.424097054359402],
					[63.353033474698456, 5.4235138035144788],
					[63.353033429608523, 5.423513761107988],
					[63.353033323211029, 5.4235137103225082],
					[63.35303326195352, 5.4235137039379255],
					[63.352411301673534, 5.423633058472964],
					[63.352425200098885, 5.4217056000821238],
					[63.352425197756787, 5.4217055783670531],
					[63.352425187698501, 5.4217055356057191],
					[63.352425180877965, 5.4217055144479884],
					[63.352175219606934, 5.4211693980057989],
					[63.352341883320719, 5.4207972819478103],
					[63.352341889139886, 5.420797263218109],
					[63.35234189719548, 5.420797226204713],
					[63.352341900377652, 5.4207972098038564],
					[63.352369591171154, 5.4201585165501251],
					[63.352827663572086, 5.4201973896576927],
					[63.353038716029531, 5.4206861789732494],
					[63.353038743006124, 5.4206862156277245],
					[63.353038808202705, 5.4206862715319106],
					[63.353038847368488, 5.4206862926645156],
					[63.353494346850866, 5.4208112938070041],
					[63.353494375663225, 5.4208112962222303],
					[63.353494432242037, 5.4208112951809069],
					[63.353494460904209, 5.4208112916128313],
					[63.353816760953819, 5.4207084911146124],
					[63.353816787574182, 5.4207084777971035],
					[63.353816836981792, 5.4207084416358136],
					[63.353816858923388, 5.4207084208979994],
					[63.354244558990402, 5.4201445218244242],
					[63.354244569338007, 5.420144504530195],
					[63.354244585554802, 5.4201444704993831],
					[63.354244593215341, 5.4201444535397512],
					[63.354430792719135, 5.4194750546850425],
					[63.354430795851052, 5.4194750362883521],
					[63.354430798632293, 5.4194750039301747],
					[63.354430799077164, 5.4194749858681446],
					[63.354383513104402, 5.418827971021809],
					[63.35531942862027, 5.4186918980665082],
					[63.355319475741098, 5.4186918781910132],
					[63.355319553309165, 5.4186918185082478],
					[63.355319583756405, 5.4186917787009952],
					[63.355547378680185, 5.4181528893660884],
					[63.355833472591804, 5.4176640004962779],
					[63.35583348273861, 5.417663975221819],
					[63.355833496712314, 5.417663923459628],
					[63.355833499643524, 5.417663897083469],
					[63.355769603110481, 5.4132446011252844],
					[63.356810985566099, 5.413255799508657],
					[63.357077602191026, 5.4137000576047694],
					[63.357113700023987, 5.4149250063829371],
					[63.357113738753505, 5.4149250815901189],
					[63.357113860638464, 5.4149251764407396],
					[63.357113943743826, 5.414925194089494],
					[63.35805834406689, 5.4147112955419496],
					[63.35805837869767, 5.4147112792166512],
					[63.358058441438558, 5.4147112373743651],
					[63.358058466761342, 5.414711208203018],
					[63.358922307208864, 5.4133891023700214],
					[63.359547003013425, 5.4134001966290493],
					[63.359574800402221, 5.415175003921596],
					[63.3595748007533, 5.415175017885514],
					[63.359574806929913, 5.4151750491328103],
					[63.359574809968095, 5.4151750627616027],
					[63.359835899435517, 5.4159556288834549],
					[63.359802599661151, 5.4168388911987781],
					[63.359802624803947, 5.4168389621057358],
					[63.359802717280452, 5.4168390666422841],
					[63.359802782772618, 5.4168390985003123],
					[63.360624982581975, 5.4169084983417397],
					[63.360625038014298, 5.4169084874290858],
					[63.360625132054793, 5.4169084396846756],
					[63.360625173299852, 5.4169084005230461],
					[63.360905772776491, 5.4164279005723941],
					[63.360905782077509, 5.4164278773996193],
					[63.360905796201095, 5.4164278316124506],
					[63.360905800027737, 5.4164278051198478],
					[63.360958493948409, 5.414658518570306],
					[63.362105581761874, 5.4147612985728744],
					[63.362105646946212, 5.4147612824363245],
					[63.362105752078008, 5.4147612112845769],
					[63.362105792025446, 5.4147611562693641],
					[63.36223079194, 5.4143417573273345],
					[63.362230794326052, 5.4143417450219271],
					[63.362230798252625, 5.4143417225178849],
					[63.362230799742974, 5.4143417103242246],
					[63.362261296735532, 5.413788957660155],
					[63.363019573613514, 5.4124389980079393],
					[63.363019583759367, 5.4124389727252851],
					[63.363019596785136, 5.4124389190642885],
					[63.363019599665158, 5.4124388906859355],
					[63.362991902390711, 5.4117917454389985],
					[63.363255775713071, 5.4113000952663759],
					[63.363255785013244, 5.4113000720900537],
					[63.363255797343797, 5.411300026520327],
					[63.363255800323962, 5.4113000021318358],
					[63.363289098652913, 5.4092889718052728],
					[63.363677956215611, 5.4088057251902155],
					[63.363677962284378, 5.4088057164262668],
					[63.363677974421918, 5.4088056988983668],
					[63.363677978649051, 5.4088056883631577],
					[63.363883478938774, 5.4083944888966453],
					[63.363883484011353, 5.4083944762542311],
					[63.363883493210537, 5.408394449086197],
					[63.363883497337127, 5.4083944345605994],
					[63.36397236489119, 5.4078863162506243],
					[63.364147056740954, 5.4079029879621991],
					[63.364263699988449, 5.4082111377803086],
					[63.364285900082841, 5.4104000029387489],
					[63.364285901380342, 5.4104000187881578],
					[63.36428590666236, 5.4104000501513587],
					[63.364285910646871, 5.4104000656651525],
					[63.36445541056505, 5.4108861662543122],
					[63.364455416240432, 5.4108861775541754],
					[63.364455425749682, 5.4108861983824523],
					[63.364455432270546, 5.4108862075752793],
					[63.364980398964668, 5.4117028571835553],
					[63.364972000307766, 5.412299997136083],
					[63.364972003697581, 5.4123000247336206],
					[63.364972017642707, 5.4123000790340878],
					[63.364972029043479, 5.4123001036299874],
					[63.365219200947116, 5.4127083574179631],
					[63.365230399797369, 5.4133528028530833],
					[63.365230399998119, 5.4133528108340254],
					[63.365230402190953, 5.4133528265723072],
					[63.365230403287384, 5.4133528344414552],
					[63.365427602888147, 5.4144833333980475],
					[63.365427610755972, 5.4144833604374849],
					[63.365427632610952, 5.4144834077483326],
					[63.365427648439592, 5.4144834297914626],
					[63.365899848227109, 5.4150362303861455],
					[63.365899884857086, 5.4150362578410034],
					[63.365899966572265, 5.4150362916804529],
					[63.36590001260334, 5.4150362999485795],
					[63.366772213302525, 5.4149808004444617],
					[63.366772266692486, 5.4149807797721561],
					[63.366772355701933, 5.4149807106205747],
					[63.366772388684552, 5.4149806644719005],
					[63.366983438967132, 5.4143558131368659],
					[63.367163881916447, 5.4143723981092409],
					[63.367163919585316, 5.4143723954103082],
					[63.367163992680226, 5.4143723722776542],
					[63.367164027310871, 5.4143723559464387],
					[63.367686164154073, 5.4139446069706629],
					[63.368102688609909, 5.4139779925581042],
					[63.368352593024724, 5.4143417511221408],
					[63.368305400803457, 5.4147666784698281],
					[63.368305402301104, 5.4147667023034716],
					[63.368305408033692, 5.4147667516309275],
					[63.368305414009789, 5.4147667749057566],
					[63.368572014141918, 5.415433374800581],
					[63.368572040224223, 5.4154334115811782],
					[63.368572106418867, 5.4154334713816814],
					[63.368572145585375, 5.4154334925178613],
					[63.368791646031752, 5.4154945931336025],
					[63.368791718582266, 5.4154945840824906],
					[63.36879184013749, 5.4154945228776414],
					[63.368791888146276, 5.4154944668446916],
					[63.36895573628869, 5.415036313956179],
					[63.369177782985034, 5.4150557980489982],
					[63.369177850806253, 5.4150557795778589],
					[63.369177958424409, 5.4150557000915462],
					[63.369177996429976, 5.4150556392998874],
					[63.369308454375876, 5.4144224429332999],
					[63.369585942004484, 5.414489068186719],
					[63.369866508831429, 5.4154139584564422],
					[63.369866547009089, 5.4154140117459573],
					[63.369866647590889, 5.415414081271436],
					[63.369866710940883, 5.4154140993912252],
					[63.370480477021424, 5.4153808074698642],
					[63.37073311894278, 5.4159139850507172],
					[63.370733123671975, 5.4159139944701318],
					[63.370733133975875, 5.415914011201588],
					[63.370733138654906, 5.4159140186253865],
					[63.371238738652664, 5.4166001185761932],
					[63.371238776278325, 5.4166001499175334],
					[63.371238859039295, 5.4166001896424909],
					[63.371238905120407, 5.4166001999100652],
					[63.37239170487144, 5.4165696005048156],
					[63.372391775924562, 5.4165695676178789],
					[63.372391873089256, 5.4165694654080756],
					[63.372391899996202, 5.4165693919820033],
					[63.372353000782574, 5.4155361219914511],
					[63.372861290804465, 5.4134778605902509],
					[63.373050187435737, 5.4129778708195166],
					[63.373050190717308, 5.4129778583975003],
					[63.37305019723027, 5.4129778315576722],
					[63.373050198720485, 5.4129778193593383],
					[63.373100196064705, 5.4124667481570317],
					[63.373283437764812, 5.4120112946428769],
					[63.373919410008412, 5.4119807998916292],
					[63.373919452800251, 5.411980786538221],
					[63.373919530816856, 5.4119807447597514],
					[63.373919565095719, 5.4119807144507375],
					[63.374227882071217, 5.4115335330231602],
					[63.374424942901271, 5.411591891961181],
					[63.374425020825498, 5.4115918822266593],
					[63.374425146606157, 5.4115918104538343],
					[63.37442519356695, 5.4115917485274121],
					[63.374661261870912, 5.4106279789194085],
					[63.375038928781635, 5.4105723986279761],
					[63.375038972368564, 5.4105723811681656],
					[63.375039044759518, 5.410572330076171],
					[63.375039075354877, 5.4105722962201392],
					[63.37528621279673, 5.4101224102383441],
					[63.375483283087959, 5.4101390981632216],
					[63.375483341206639, 5.4101390868951578],
					[63.375483438676405, 5.4101390326668959],
					[63.375483478923194, 5.4101389895947518],
					[63.37581127876782, 5.4094806882387063],
					[63.375811287967117, 5.4094806610596198],
					[63.375811298153884, 5.4094806017212669],
					[63.375811298346129, 5.4094805736658635],
					[63.375775222683174, 5.4092195504748597],
					[63.376155497886906, 5.4091224323159679],
					[63.376638709736149, 5.4100194675323312],
					[63.376819200926455, 5.4109361250113865],
					[63.376849799602432, 5.4114972112715476],
					[63.376849800698999, 5.4114972191437891],
					[63.376849802892082, 5.4114972348882775],
					[63.376849803988598, 5.4114972427605057],
					[63.376980403934624, 5.4121083421718472],
					[63.376980426635924, 5.4121083873916618],
					[63.376980492237543, 5.4121084592895157],
					[63.376980534292365, 5.4121084880754875],
					[63.37729713441238, 5.4122195883603981],
					[63.377297207113166, 5.4122195852858681],
					[63.377297331554558, 5.4122195316966106],
					[63.377297383244972, 5.4122194791857741],
					[63.377586283324597, 5.4115583794105682],
					[63.377586285860986, 5.4115583730865762],
					[63.377586290883563, 5.4115583584425249],
					[63.377586294265427, 5.4115583500105382],
					[63.377902994375624, 5.4103417490920576],
					[63.377902995865625, 5.4103417368915201],
					[63.377902998694843, 5.4103417065021286],
					[63.377903000134566, 5.4103416923054937],
					[63.37783350100036, 5.4085778226546726],
					[63.377983492725313, 5.4080306529944036],
					[63.377983495110755, 5.4080306406817042],
					[63.377983498985969, 5.4080306161683236],
					[63.37798349952979, 5.4080306020835414],
					[63.377997398688173, 5.407175042444079],
					[63.378661233398951, 5.4056445968937021],
					[63.380905605959526, 5.4055723998199472],
					[63.380905632779459, 5.4055723944603224],
					[63.380905683581354, 5.4055723780885421],
					[63.380905709405006, 5.4055723688484267],
					[63.381427908587355, 5.405233468421331],
					[63.381427941818089, 5.4052334322192035],
					[63.381427987377151, 5.4052333504157657],
					[63.381427999655266, 5.4052333028180746],
					[63.381436297133845, 5.4047169171312843],
					[63.382194382976834, 5.4047807984919887],
					[63.382194439303596, 5.4047807874343281],
					[63.382194535925713, 5.4047807352830981],
					[63.382194577116707, 5.404780694077413],
					[63.382500177391442, 5.4042056943090229],
					[63.382500185744341, 5.4042056692304703],
					[63.382500197126376, 5.4042056217427437],
					[63.382500200105262, 5.4042055973371337],
					[63.382491900659872, 5.4036917389888464],
					[63.382716885300411, 5.4031528769292994],
					[63.382716890221431, 5.4031528582883004],
					[63.382716898272129, 5.4031528212307087],
					[63.382716899610465, 5.4031528030384859],
					[63.382725200390972, 5.4025944271964255],
					[63.382961255043064, 5.401714095831144],
					[63.383513906019701, 5.4016974003777785],
					[63.38351391139372, 5.4016973997043181],
					[63.383513920350403, 5.4016973985818844],
					[63.383513923933066, 5.4016973981329111],
					[63.383950023656723, 5.4016445986648005],
					[63.383950054904439, 5.4016445907431372],
					[63.383950110828039, 5.4016445637064816],
					[63.383950138190947, 5.4016445442547631],
					[63.384341598555842, 5.4012725719862376],
					[63.384505408456505, 5.4018444554605232],
					[63.384505445743542, 5.4018445088706173],
					[63.38450554727774, 5.4018445802665171],
					[63.384505610679568, 5.4018446003610956],
					[63.387035888193459, 5.4017113112452027],
					[63.3870025995856, 5.4023082885063216],
					[63.387002624735899, 5.4023083594667058],
					[63.387002716327387, 5.402308464164622],
					[63.3870027819233, 5.4023085000110918],
					[63.387444381777485, 5.4023473996654099],
					[63.387444444323322, 5.4023473858181745],
					[63.387444546066654, 5.4023473229914876],
					[63.387444586159788, 5.4023472738997986],
					[63.387608444752679, 5.4019307760809037],
					[63.387927835964014, 5.4018723972057998],
					[63.387927883031651, 5.4018723752817719],
					[63.387927958649264, 5.4018723097174863],
					[63.387927988145272, 5.4018722679617479],
					[63.388302935750765, 5.4008308126597422],
					[63.388519292982004, 5.4008501916143414],
					[63.38879154013032, 5.4012140194583651],
					[63.388791564968123, 5.4012140423856545],
					[63.388791620711899, 5.4012140794668513],
					[63.388791651617922, 5.4012140936207551],
					[63.389049870940468, 5.4012779744946142],
					[63.389322028911039, 5.4017307037150788],
					[63.389322059374557, 5.4017307359540094],
					[63.389322130596391, 5.4017307811126649],
					[63.389322172351093, 5.4017307979138938],
					[63.390097172329632, 5.4018390988073186],
					[63.390097234925733, 5.4018390869544692],
					[63.390097340352149, 5.4018390276646873],
					[63.390097383232806, 5.4018389822246888],
					[63.390319542358043, 5.401341872209966],
					[63.390702844639414, 5.4012529947329035],
					[63.390702880163957, 5.4012529782593512],
					[63.390702941954181, 5.4012529344533187],
					[63.390702969115523, 5.4012529070085273],
					[63.390983413991016, 5.4008112948816258],
					[63.391594290584031, 5.4007780058219224],
					[63.39186092930219, 5.4012223024178585],
					[63.391860948816316, 5.401222328018374],
					[63.391860995653815, 5.4012223682234044],
					[63.391861023027502, 5.4012223848249983],
					[63.392249923374742, 5.4013834856054093],
					[63.392249987269373, 5.4013834896148918],
					[63.392250105094675, 5.4013834588151601],
					[63.392250158079285, 5.401383422121147],
					[63.392589058163601, 5.4009473230592011],
					[63.392589071044554, 5.4009472994063641],
					[63.39258909227776, 5.400947250665145],
					[63.392589098788292, 5.4009472238042644],
					[63.392658477144501, 5.4003613066719414],
					[63.393616692440681, 5.4003974004352786],
					[63.393616746029593, 5.4003973877037517],
					[63.393616838121005, 5.4003973340803757],
					[63.393616876623547, 5.4003972931885365],
					[63.393961213822621, 5.3997502115418436],
					[63.394174861238781, 5.3997695881633181],
					[63.394869214890328, 5.4014750741711612],
					[63.394869254763883, 5.4014751232681784],
					[63.394869356046435, 5.4014751847010594],
					[63.394869416610213, 5.4014751991464829],
					[63.39512765657215, 5.4014530129579148],
					[63.395410908891414, 5.4023694584987405],
					[63.395410939757618, 5.402369506723419],
					[63.395411023277525, 5.4023695763980841],
					[63.39541107503554, 5.4023695979603676],
					[63.396344317945406, 5.4024890913153287],
					[63.396874873675017, 5.4029195556163945],
					[63.396874892836458, 5.4029195672405645],
					[63.396874931954393, 5.4029195863818344],
					[63.396874952806478, 5.4029195937866552],
					[63.397152753437254, 5.4029862949375138],
					[63.397152822403797, 5.4029862862927205],
					[63.397152937733225, 5.4029862277542104],
					[63.397152984941464, 5.4029861757507955],
					[63.397339034754694, 5.4025307997675229],
					[63.398291700596374, 5.4025251996801709],
					[63.39829176468173, 5.4025251756152537],
					[63.398291863234356, 5.4025250931260604],
					[63.398291896805979, 5.4025250348141087],
					[63.398380774347416, 5.4020168634719825],
					[63.398641598799387, 5.4019530311169826],
					[63.398891524961179, 5.402402897204384],
					[63.398891557266261, 5.4024029312273925],
					[63.398891633117287, 5.4024029818331991],
					[63.398891676663226, 5.4024029984159823],
					[63.400238877240362, 5.4025584989834758],
					[63.400238937149432, 5.4025584874642076],
					[63.400239039043576, 5.4025584306017773],
					[63.400239081028644, 5.4025583852586188],
					[63.400511222352996, 5.4019808083209728],
					[63.400624901769461, 5.4019890935172885],
					[63.401097047935409, 5.402544529920192],
					[63.401097079988709, 5.4025445539573891],
					[63.401097150958549, 5.4025445891471744],
					[63.401097189875095, 5.4025446002997617],
					[63.402424841886784, 5.4026112922538907],
					[63.40268590585913, 5.4036639489722145],
					[63.402685953249687, 5.4036640111718137],
					[63.402686078171122, 5.4036640836938306],
					[63.402686155651686, 5.4036640920184338],
					[63.403147255800526, 5.4035307915427628],
					[63.403147292170601, 5.4035307729542517],
					[63.403147352864288, 5.4035307212541657],
					[63.403147378133973, 5.4035306900281466],
					[63.403355777802048, 5.403119489112],
					[63.403355786255311, 5.4031194680104386],
					[63.403355796892129, 5.4031194265934124],
					[63.403355799921002, 5.4031194041677582],
					[63.4033640993121, 5.4026472388379796],
					[63.40358908230823, 5.4020639831637522],
					[63.403833414413199, 5.4016141100502866],
					[63.404072184231552, 5.4016334983194989],
					[63.404072245881487, 5.4016334845738951],
					[63.40407234757371, 5.401633419708542],
					[63.404072387615997, 5.401633368588791],
					[63.40430293124875, 5.4010085239204075],
					[63.404913868608041, 5.4011029980502085],
					[63.404913876719426, 5.4011029990368691],
					[63.404913891996117, 5.4011029991245971],
					[63.404913900107481, 5.4011030001112559],
					[63.405435950647252, 5.4011029995616342],
					[63.405566508347647, 5.401544456532771],
					[63.405566541055883, 5.4015445065465695],
					[63.405566629104932, 5.4015445776787709],
					[63.405566683550063, 5.4015445989095499],
					[63.405805460381472, 5.4015640886564107],
					[63.406033114053521, 5.4021417732257904],
					[63.406033141085409, 5.4021418119268345],
					[63.406033209972648, 5.4021418714348446],
					[63.406033250982681, 5.4021418943521562],
					[63.406288850889837, 5.4022057947892206],
					[63.406288918960556, 5.4022057862514536],
					[63.406289034440505, 5.4022057336842044],
					[63.406289080853348, 5.4022056857709426],
					[63.406577922764725, 5.401586309797838],
					[63.406836084670353, 5.4016057998323452],
					[63.406836148061217, 5.401605783862359],
					[63.406836251443949, 5.4016057147694436],
					[63.406836289644481, 5.4016056618712183],
					[63.406983438099182, 5.4011474248095688],
					[63.407580569958782, 5.4012390988980821],
					[63.407580618324765, 5.4012390928298046],
					[63.407580708283319, 5.4012390614983872],
					[63.407580748929867, 5.4012390343494738],
					[63.408083102818559, 5.4006811187231873],
					[63.408088700052602, 5.4016278013995613],
					[63.408088733365204, 5.4016278753959845],
					[63.408088843665723, 5.401627973810478],
					[63.408088921549265, 5.4016279981161643],
					[63.408546995970902, 5.4015780223048626],
					[63.40853590036798, 5.4022221965643693],
					[63.408535901817608, 5.4022222184324109],
					[63.408535910936159, 5.4022222593838221],
					[63.408535916864089, 5.4022222806901237],
					[63.40920811666544, 5.403738980407252],
					[63.409208157333282, 5.4037390254224977],
					[63.409208254981948, 5.4037390853434646],
					[63.409208312858439, 5.4037391001368595],
					[63.409505468896057, 5.4037196088713699],
					[63.409905406938314, 5.4046972566842815],
					[63.410085903192368, 5.4056583363892781],
					[63.410085906181266, 5.4056583480425235],
					[63.41008591395034, 5.4056583711245194],
					[63.41008591783492, 5.4056583826655142],
					[63.410510917898939, 5.4065944821885417],
					[63.410510954932946, 5.4065945256603749],
					[63.410511045364835, 5.4065945844930958],
					[63.410511098762697, 5.4065945998539648],
					[63.411044224174645, 5.406597398002158],
					[63.411116502169293, 5.4071611251372769],
					[63.411116526915173, 5.4071611801737181],
					[63.411116605410911, 5.4071612645612053],
					[63.411116657369405, 5.4071612941366869],
					[63.411433257039164, 5.4072307942133797],
					[63.411433332225286, 5.4072307827895258],
					[63.41143345073656, 5.4072307078008075],
					[63.411433494957429, 5.4072306441237474],
					[63.412000195148259, 5.4047722451646409],
					[63.412000195842587, 5.4047722370584976],
					[63.412000199022707, 5.4047722206216386],
					[63.412000199666728, 5.4047722105170299],
					[63.412039099371974, 5.4040722316269632],
					[63.41226128633172, 5.4032361808213434],
					[63.412500154003425, 5.4028557303310123],
					[63.412761168237957, 5.4026252069126048],
					[63.413016634695062, 5.4026473945360589],
					[63.413291554669676, 5.4028195460246931],
					[63.413560912234637, 5.4032444786202829],
					[63.413677604932808, 5.4037694427161771],
					[63.413677610810204, 5.403769462028011],
					[63.413677626993021, 5.4037694980914699],
					[63.413677636402753, 5.4037695149554059],
					[63.413933100854962, 5.4041333641024956],
					[63.413941500000611, 5.4046917027029977],
					[63.413941501248544, 5.4046917165808912],
					[63.413941504589808, 5.4046917422257774],
					[63.413941507679446, 5.4046917578776839],
					[63.414110908299861, 5.4052639567950997],
					[63.41411091019183, 5.4052639605677726],
					[63.414110912184448, 5.4052639683376444],
					[63.414110914126738, 5.4052639741089266],
					[63.414322014371074, 5.4057972739380258],
					[63.414322046926976, 5.4057973179757779],
					[63.414322131139777, 5.4057973795979057],
					[63.414322181951356, 5.4057973992931512],
					[63.414538769767645, 5.4058168883403406],
					[63.415019220268334, 5.4068000886627585],
					[63.415019252673069, 5.4068001267066554],
					[63.41501933404755, 5.4068001826738632],
					[63.415019381175711, 5.4068001988229328],
					[63.415258162985168, 5.4068223881961996],
					[63.415447015574323, 5.4072667782930415],
					[63.415447058082755, 5.407266825097107],
					[63.415447161154376, 5.4072668863678386],
					[63.415447222562896, 5.4072668987235764],
					[63.41566658141749, 5.4072419154934632],
					[63.415880419722491, 5.4076889867090561],
					[63.415880435602602, 5.4076890107850293],
					[63.415880475323306, 5.407689053929583],
					[63.415880498217952, 5.407689071111621],
					[63.416133146071537, 5.4078390418282458],
					[63.41632761979384, 5.4082417879629414],
					[63.416327633781982, 5.4082418082664487],
					[63.416327667082008, 5.408241846201495],
					[63.416327687339852, 5.4082418657196039],
					[63.416541547984153, 5.4083890387034552],
					[63.416794225818016, 5.4088389985808387],
					[63.416794259017344, 5.4088390325195768],
					[63.416794336707859, 5.4088390849469858],
					[63.416794382889833, 5.4088390992137034],
					[63.417049866132714, 5.4088612898735304],
					[63.417530417325445, 5.4099333815964457],
					[63.417530459833024, 5.4099334284077951],
					[63.417530563698705, 5.4099334855824965],
					[63.417530625107048, 5.4099334979446869],
					[63.418147224807811, 5.4098557984446396],
					[63.418147286901856, 5.4098557666067144],
					[63.418147374257906, 5.4098556674391256],
					[63.418147399570159, 5.409855602108359],
					[63.418155799373892, 5.4093000349182789],
					[63.418808488691852, 5.4074194657314667],
					[63.418808491227971, 5.4074194593979161],
					[63.418808493562956, 5.4074194450685971],
					[63.418808496149396, 5.4074194407340022],
					[63.419022365835389, 5.4063640893094025],
					[63.419299868223185, 5.4063446105060571],
					[63.41951090127305, 5.4068778391937506],
					[63.41954419974644, 5.4085111032545967],
					[63.419544208863286, 5.4085111442236871],
					[63.419544243017953, 5.4085112161461444],
					[63.41954426721037, 5.408511249210715],
					[63.419972017370604, 5.4088918040347949],
					[63.42017480422156, 5.4098972400757059],
					[63.420174831553005, 5.4098972907970717],
					[63.420174912633755, 5.4098973708833862],
					[63.420174966282573, 5.4098973962502495],
					[63.420672166276916, 5.409983497183072],
					[63.420672179762299, 5.4099834974996428],
					[63.420672208574587, 5.4099834999075567],
					[63.420672222905353, 5.4099834981128891],
					[63.421108322599068, 5.4099334980649214],
					[63.421108368872531, 5.4099334802374006],
					[63.421108446586111, 5.4099334263859857],
					[63.42110847892188, 5.4099333902499236],
					[63.421377967322954, 5.4093973138924589],
					[63.421844548582378, 5.4088779345543525],
					[63.421844566737946, 5.4088779062089483],
					[63.421844592150237, 5.4088778448671198],
					[63.421844600352863, 5.4088778137576847],
					[63.421977999629789, 5.4068694120654905],
					[63.421977999529197, 5.406869408067152],
					[63.421978000223653, 5.406869399958242],
					[63.421978000123048, 5.4068693959599035],
					[63.421953007083715, 5.4058808591773699],
					[63.422191649725363, 5.4059418940942683],
					[63.42219173819533, 5.4059418749798303],
					[63.422191862371513, 5.4059417751800867],
					[63.42219189986907, 5.4059416942701626],
					[63.422166904757951, 5.4049530041797773],
					[63.423558182395674, 5.4049834967766426],
					[63.423788726450063, 5.4053889995627982],
					[63.423788768757852, 5.4053890383801368],
					[63.423788868891641, 5.4053890900015205],
					[63.423788926616965, 5.4053890988070012],
					[63.42396927191438, 5.4053641190129591],
					[63.424180414434659, 5.4058944746577033],
					[63.42418044236134, 5.4058945132739717],
					[63.424180511247165, 5.4058945728273144],
					[63.424180553102005, 5.4058945936520741],
					[63.424458192347792, 5.4059612805737434],
					[63.424866549841141, 5.4064251329957953],
					[63.424866587318178, 5.4064251583820102],
					[63.424866669035161, 5.4064251922611684],
					[63.424866714170754, 5.4064252006418156],
					[63.425144263659504, 5.4064058110440856],
					[63.425372012372044, 5.4070250684115084],
					[63.425372051348205, 5.4070251176794812],
					[63.425372149146519, 5.4070251836419168],
					[63.425372208864296, 5.4070252002241217],
					[63.426725009278833, 5.4069669006480279],
					[63.42672506092584, 5.4069668821382155],
					[63.426725149235274, 5.4069668209217934],
					[63.426725184106346, 5.4069667784397852],
					[63.427552984033376, 5.4050167777079832],
					[63.427552989004738, 5.4050167610374595],
					[63.427552997206512, 5.4050167299206775],
					[63.427553000386546, 5.4050167134748826],
					[63.427630784603146, 5.4039058189348772],
					[63.427830581365043, 5.4039251980859895],
					[63.427830643014971, 5.4039251843323202],
					[63.427830745653644, 5.403925121305873],
					[63.427830786642424, 5.4039250720330712],
					[63.428039033399088, 5.4033835110879256],
					[63.428316683654828, 5.4034057992894873],
					[63.428316724855449, 5.4034057941186528],
					[63.428316801429531, 5.4034057664550765],
					[63.428316836853369, 5.403405745961904],
					[63.428780737014449, 5.402972346886413],
					[63.42878074920128, 5.4029723313150599],
					[63.428780771783558, 5.4029723003972077],
					[63.428780782128712, 5.40297228305106],
					[63.429027795841883, 5.4024337894714538],
					[63.429455416768427, 5.403411180913448],
					[63.429455465648537, 5.403411230946416],
					[63.429455585638671, 5.4034112860963495],
					[63.429455655853026, 5.4034112913257486],
					[63.430200056284036, 5.4031945913144943],
					[63.430200089121506, 5.4031945751563919],
					[63.430200148174357, 5.4031945296290482],
					[63.430200172598369, 5.4031945004846795],
					[63.430861259981533, 5.4020668230041569],
					[63.431419544105466, 5.4014890398480651],
					[63.431419547537018, 5.4014890333987031],
					[63.431419556292113, 5.4014890242746176],
					[63.431419559774007, 5.4014890198250676],
					[63.432011259982914, 5.4007029195223746],
					[63.432011266050992, 5.400702910735407],
					[63.432011277291494, 5.4007028932739471],
					[63.432011282413548, 5.4007028825996244],
					[63.43238078221524, 5.3998972835445489],
					[63.432380791261096, 5.3998972503086469],
					[63.432380795867459, 5.399897183525062],
					[63.432380794114984, 5.399897149639755],
					[63.432139094343832, 5.3989721489987934],
					[63.432139081048355, 5.3989721205769063],
					[63.43213904739298, 5.3989720686333795],
					[63.432139026086965, 5.398972043224461],
					[63.431661309248042, 5.3985860124515144],
					[63.431689093217422, 5.398291787605106],
					[63.4327943367797, 5.3971225575341135],
					[63.432985901205669, 5.3975667437130603],
					[63.432999800414805, 5.3980389049989101],
					[63.432999801814702, 5.3980389248854213],
					[63.432999809887718, 5.3980389599830527],
					[63.432999815715512, 5.3980389773066548],
					[63.43318871627784, 5.3984861776818063],
					[63.433188744206483, 5.3984862163026435],
					[63.433188814886655, 5.3984862756309679],
					[63.433188856692119, 5.3984862944510805],
					[63.43350809700263, 5.3985557600168876],
					[63.433471999805398, 5.4006138966585109],
					[63.433472033169267, 5.4006139727182951],
					[63.433472146258481, 5.4006140748752474],
					[63.433472224987348, 5.4006140970850636],
					[63.433669213972991, 5.4005891245074054],
					[63.433702599981643, 5.4011083129797939],
					[63.433702618359632, 5.4011083648412894],
					[63.433702677999747, 5.4011084496393496],
					[63.433702719261909, 5.4011084825758919],
					[63.433822007381771, 5.4011612331579881],
					[63.433880400205616, 5.4022833096633311],
					[63.433880403396259, 5.4022833293257344],
					[63.433880413360299, 5.402283368200683],
					[63.433880420133612, 5.4022833874132168],
					[63.434074801369341, 5.4026861479749675],
					[63.434085899849734, 5.4031167054508451],
					[63.434085934007229, 5.4031167774027358],
					[63.43408604520264, 5.4031168757966359],
					[63.434086121244192, 5.4031168983510991],
					[63.434763763592699, 5.4030446157129965],
					[63.434952611954671, 5.4035750679328975],
					[63.434952649986052, 5.4035751153245775],
					[63.434952747836277, 5.4035751832962715],
					[63.434952807554382, 5.4035751998761778],
					[63.435291569711794, 5.403561304450621],
					[63.435480416374986, 5.4040056784016333],
					[63.435480442410196, 5.404005713255569],
					[63.435480505872476, 5.4040057715014376],
					[63.435480545040598, 5.4040057926683982],
					[63.43591934472478, 5.4041307914148495],
					[63.435919415583037, 5.4041307865336021],
					[63.435919536285965, 5.4041307272410899],
					[63.435919585335661, 5.4041306769424997],
					[63.436125133743765, 5.4036308011902259],
					[63.436680599406394, 5.4036334999260651],
					[63.43668065911384, 5.4036334803912771],
					[63.436680756126428, 5.403633408017086],
					[63.436680791640235, 5.4036333554025511],
					[63.436827945607448, 5.4031308120629147],
					[63.437324982836039, 5.4031723981694979],
					[63.437325047122414, 5.4031723820714124],
					[63.437325149659834, 5.4031723150198685],
					[63.437325189702165, 5.4031722638414941],
					[63.437491859705446, 5.4026695532571116],
					[63.437672272360267, 5.402600187880167],
					[63.437672305942151, 5.4026001656036948],
					[63.437672362055849, 5.4026001103989811],
					[63.437672385432911, 5.4026000753580359],
					[63.437877984949289, 5.4021028756492333],
					[63.437877989175739, 5.4021028650854399],
					[63.437877994891295, 5.4021028422950845],
					[63.437877998222071, 5.4021028318437887],
					[63.437950194207147, 5.4016361541567832],
					[63.43817518469185, 5.4010944776416379],
					[63.438175189612323, 5.4010944589639367],
					[63.438175197661963, 5.401094421833605],
					[63.438175199895461, 5.4010944034934889],
					[63.43819189887158, 5.3999778449728835],
					[63.438566817000009, 5.3991502266568565],
					[63.43898056311302, 5.3992279975542603],
					[63.438980606155106, 5.3992279941501868],
					[63.438980688303772, 5.3992279737900484],
					[63.438980727359926, 5.3992279548336564],
					[63.439789026653607, 5.3985668540646525],
					[63.439789051126972, 5.3985668269067464],
					[63.43978908633634, 5.3985667622778406],
					[63.439789098018352, 5.3985667266946349],
					[63.439991898390161, 5.3970778265078483],
					[63.439991899084056, 5.3970778183935852],
					[63.439991899626598, 5.3970778042781431],
					[63.439991900320493, 5.3970777961638809],
					[63.439983501352124, 5.3966028452387107],
					[63.440205779818911, 5.3961500882777367],
					[63.440205787324807, 5.3961500652589711],
					[63.440205797908725, 5.3961500217853189],
					[63.440205799990231, 5.3961499974423095],
					[63.440197401021379, 5.3955472685646448],
					[63.440422296118975, 5.3952668995386039],
					[63.440997100573227, 5.3952696006902867],
					[63.441347041453611, 5.3957279214305229],
					[63.441347079033427, 5.3957279508176006],
					[63.441347163591047, 5.395727990347531],
					[63.441347210568921, 5.3957280004903536],
					[63.441963762402224, 5.3956946087975943],
					[63.442152612218045, 5.3962250665357274],
					[63.442152643931813, 5.3962251127175289],
					[63.442152729145235, 5.3962251782570663],
					[63.442152780904117, 5.3962251998407362],
					[63.442388760834163, 5.3962473886415454],
					[63.442774814746492, 5.3971806767517894],
					[63.442774834362389, 5.3971807063951003],
					[63.442774879662046, 5.3971807568906138],
					[63.442774907137107, 5.3971807775174749],
					[63.443066501907069, 5.3973334225836096],
					[63.443085899845791, 5.3986250031536267],
					[63.443085903087415, 5.3986250248225227],
					[63.443085912207252, 5.3986250658218013],
					[63.443085918981119, 5.398625085039555],
					[63.443338699235916, 5.3991639423331819],
					[63.443330399912462, 5.3995499957247093],
					[63.443330400164513, 5.3995500057278907],
					[63.443330401614702, 5.3995500276222819],
					[63.443330403658081, 5.3995500374002576],
					[63.443566499951736, 5.4007722185803511],
					[63.44355260007395, 5.4016749662789634],
					[63.443360911125815, 5.4022610359124315],
					[63.443069210889384, 5.4030943340711399],
					[63.443069206813988, 5.4030943506386082],
					[63.443069201350113, 5.4030943834360619],
					[63.44306920001204, 5.4030944016666629],
					[63.443072000440132, 5.4042138298553928],
					[63.442647045123884, 5.4047359731285098],
					[63.442647028710311, 5.4047359992720274],
					[63.44264700678216, 5.4047360562116111],
					[63.442647001217239, 5.404736085007066],
					[63.442616501381849, 5.4051638849975996],
					[63.442616501039822, 5.4051639071166102],
					[63.442616507571486, 5.4051639524558404],
					[63.442616514394807, 5.4051639736754522],
					[63.442827614368696, 5.405697273256151],
					[63.442827650608102, 5.4056973208881667],
					[63.442827743828083, 5.405697383445192],
					[63.442827799963283, 5.4056974004832039],
					[63.443344399627421, 5.405697399305061],
					[63.443344452270409, 5.4056973846701739],
					[63.443344541625514, 5.4056973293023027],
					[63.443344579283625, 5.4056972904575504],
					[63.443572306565876, 5.4052446315429004],
					[63.443830551735275, 5.4053084933255047],
					[63.443830627816496, 5.4053084817685964],
					[63.443830749958764, 5.4053084082339824],
					[63.443830795073765, 5.4053083443680103],
					[63.443958461887412, 5.4047612843170851],
					[63.444655619637643, 5.4046918990072603],
					[63.444655670388507, 5.4046918805932114],
					[63.444655752376498, 5.4046918181161816],
					[63.444655784559629, 5.4046917759415098],
					[63.444861285036303, 5.4041917759734108],
					[63.444861290007509, 5.4041917592925994],
					[63.444861298158614, 5.4041917261558874],
					[63.444861300392496, 5.4041917078117052],
					[63.444897398341979, 5.4033333419920524],
					[63.445127986157573, 5.4027528748987796],
					[63.445127987747426, 5.4027528666706761],
					[63.445127992768825, 5.402752851990229],
					[63.445127995304716, 5.4027528456503777],
					[63.445341895412675, 5.4018250455126351],
					[63.445341896901745, 5.4018250332828428],
					[63.445341899034538, 5.4018250109366228],
					[63.445341899627927, 5.4018249988193912],
					[63.445336301405803, 5.4007474173816883],
					[63.445955507756672, 5.4008001929113201],
					[63.446405432634016, 5.4012695130972617],
					[63.44661371881552, 5.4017167845543863],
					[63.446613767645921, 5.401716832612605],
					[63.44661388763663, 5.4017168877862201],
					[63.446613957850936, 5.4017168930132566],
					[63.446955463164031, 5.4016141503708441],
					[63.447141506837241, 5.4022750537466395],
					[63.44714153586213, 5.4022751002806997],
					[63.447141613163119, 5.4022751728648073],
					[63.447141661388841, 5.40227519691397],
					[63.447535966406534, 5.4023529778450152],
					[63.447766522369086, 5.4028028901133656],
					[63.447766555670633, 5.4028029280825312],
					[63.44776663699637, 5.4028029820984216],
					[63.447766685020547, 5.4028029981451491],
					[63.448024803009112, 5.402822386425691],
					[63.448044200421826, 5.4042000037168147],
					[63.448044205655378, 5.4042000331690385],
					[63.448044226075453, 5.4042000948381235],
					[63.448044241110949, 5.4042001210520834],
					[63.448469212137624, 5.4047528831255107],
					[63.44867200265476, 5.4058444379496535],
					[63.448672003600755, 5.405844439838237],
					[63.44889150407527, 5.4069389387274169],
					[63.448891592323221, 5.4069390179838379],
					[63.448891780624336, 5.4069390385151479],
					[63.448891884260192, 5.4069389793404339],
					[63.449161065189934, 5.4063144869088982],
					[63.449330400128531, 5.4068889260192456],
					[63.449313699843302, 5.4078749968226516],
					[63.449313702238207, 5.407875020610784],
					[63.449313713247378, 5.4078750653993355],
					[63.449313721016303, 5.4078750885132099],
					[63.449602620844274, 5.408461188865286],
					[63.449602659618989, 5.4084612301714818],
					[63.449602755373967, 5.4084612864112893],
					[63.449602811408241, 5.4084612994561674],
					[63.451472210661436, 5.408358500256937],
					[63.451472264099742, 5.4083584815075039],
					[63.45147235056757, 5.408358418463604],
					[63.451472386334423, 5.4083583758332647],
					[63.452075185949468, 5.4068611754354565],
					[63.452075189280571, 5.4068611649794907],
					[63.452075195047115, 5.4068611441799348],
					[63.452075196586875, 5.4068611339487873],
					[63.452230793886507, 5.4059694494894934],
					[63.452414070986151, 5.4054279201408812],
					[63.453000125326085, 5.4049612556544471],
					[63.453000144577636, 5.4049612351679333],
					[63.453000178401091, 5.4049611867520362],
					[63.453000191231958, 5.4049611610489405],
					[63.453147391199927, 5.404500061125499],
					[63.453147393584821, 5.4045000487800188],
					[63.453147398304331, 5.4045000220877046],
					[63.453147399793572, 5.4045000098547211],
					[63.453214100123574, 5.4029583088747932],
					[63.453214097727802, 5.4029582850838898],
					[63.453214089353693, 5.4029582379522392],
					[63.453214080688312, 5.4029582149491517],
					[63.452733500229613, 5.4019277543109236],
					[63.452716900170962, 5.3993472468505725],
					[63.453150179148381, 5.3984778897819785],
					[63.453150185014543, 5.3984778729826379],
					[63.453150194955541, 5.3984778396093098],
					[63.453150198979962, 5.398477821034005],
					[63.453197396597474, 5.3980528365862153],
					[63.453366894042567, 5.397380649167089],
					[63.453366895581652, 5.3973806389349877],
					[63.453366898558933, 5.3973806144681271],
					[63.453366900047548, 5.3973806022346817],
					[63.453389099274908, 5.3960500413361396],
					[63.453822216190147, 5.3950087817106596],
					[63.454783108676224, 5.3969389643838266],
					[63.454969199913755, 5.3979000209455616],
					[63.45497760003569, 5.3984167032584764],
					[63.45497762687922, 5.3984167701536094],
					[63.454977718272609, 5.3984168670748174],
					[63.45497778287293, 5.398416899102334],
					[63.455830413699779, 5.3984890851136589],
					[63.455897000345793, 5.399355601822899],
					[63.455869199993323, 5.3998249872123791],
					[63.455869201292266, 5.3998250031118298],
					[63.455869202944129, 5.3998250330218562],
					[63.455869206034386, 5.3998250486959769],
					[63.456055405706536, 5.4005722489858066],
					[63.456055416214035, 5.4005722737666897],
					[63.45605544255254, 5.4005723206510918],
					[63.456055459279241, 5.4005723426420129],
					[63.456483104182254, 5.4009944883383669],
					[63.456513699640567, 5.4015583115585084],
					[63.456513703777475, 5.4015583331252568],
					[63.456513715633939, 5.401558375808329],
					[63.456513725144838, 5.4015583966994409],
					[63.456710925344154, 5.401916795268173],
					[63.456710957699954, 5.4019168313588359],
					[63.456711037184306, 5.4019168836118965],
					[63.456711083417211, 5.4019168998868698],
					[63.456908108616908, 5.4019334844576683],
					[63.456938699568475, 5.4025389094030105],
					[63.456938703806024, 5.4025389349733963],
					[63.45693871839957, 5.4025389793213687],
					[63.456938727960662, 5.4025390022147084],
					[63.457208101213354, 5.4029917568843393],
					[63.457219200289188, 5.4035083048651833],
					[63.457219242709186, 5.4035083838683322],
					[63.457219376245753, 5.4035084775251159],
					[63.457219466365935, 5.4035084882880806],
					[63.457619306808994, 5.4033696442663626],
					[63.457947024616722, 5.4039556977056211],
					[63.457947057867706, 5.4039557336875967],
					[63.457947137301005, 5.4039557839468078],
					[63.45794718169202, 5.4039557984491324],
					[63.458069245143164, 5.4039668867398767],
					[63.458233103886428, 5.404538941066356],
					[63.458305401595638, 5.4050639267137592],
					[63.458305405681692, 5.4050639462806611],
					[63.458305418281782, 5.4050639828502902],
					[63.458305425900136, 5.405063999965483],
					[63.458535926379035, 5.4054694983908398],
					[63.458535954104654, 5.4054695290451207],
					[63.458536019106134, 5.4054695771060075],
					[63.458536057277705, 5.4054695944001123],
					[63.458836057396198, 5.4055362953306503],
					[63.458836068194486, 5.4055362959824711],
					[63.458836090737073, 5.4055362991753428],
					[63.458836103326689, 5.4055362996021783],
					[63.45927220291442, 5.405530800325768],
					[63.459272242222958, 5.4055307913724802],
					[63.459272313322401, 5.4055307603535097],
					[63.45927234595861, 5.4055307361735787],
					[63.459719510996926, 5.4050529736421495],
					[63.460035903951407, 5.4049502731779651],
					[63.460047000055688, 5.40568060283102],
					[63.460047007231324, 5.4056806380762552],
					[63.460047032179574, 5.4056807012113595],
					[63.460047050002522, 5.4056807311030397],
					[63.460335928997949, 5.4060084085860822],
					[63.460549817058649, 5.4064972794018686],
					[63.460549849564302, 5.4064973215080157],
					[63.460549932831043, 5.406497381338049],
					[63.460549982696456, 5.4064973991743894],
					[63.461047051976813, 5.4065390871871148],
					[63.461197010736655, 5.4069833632059838],
					[63.461197031397276, 5.4069833987679949],
					[63.461197085006212, 5.4069834583080754],
					[63.461197117954569, 5.4069834822861429],
					[63.461433158974124, 5.4070890552890303],
					[63.46166372568203, 5.407494498272345],
					[63.461663750669913, 5.4074945272672617],
					[63.461663809350831, 5.4074945741229756],
					[63.461663842148191, 5.4074945920962181],
					[63.462061041848251, 5.4076140914424533],
					[63.46206109758073, 5.4076140924788163],
					[63.462061201075471, 5.4076140634196852],
					[63.462061247992366, 5.40761403543861],
					[63.46256946260948, 5.4070558275007476],
					[63.462763703450918, 5.4071140501827886],
					[63.462774800058313, 5.4075889056251141],
					[63.462774814801264, 5.4075889559907013],
					[63.462774869959652, 5.4075890414488024],
					[63.462774908634053, 5.4075890787682424],
					[63.462974802595795, 5.4076918235434004],
					[63.462996998708633, 5.4085971630522787],
					[63.462583114427062, 5.40963602631901],
					[63.462583108559599, 5.4096360431217194],
					[63.462583102248971, 5.4096360780548238],
					[63.462583100014491, 5.4096360964099803],
					[63.462577600688903, 5.4100221570988953],
					[63.462305416843407, 5.4106443204040522],
					[63.462305410230968, 5.4106443433246865],
					[63.462305402430395, 5.4106443904938581],
					[63.462305400346544, 5.4106444148547288],
					[63.462338699803674, 5.4111222139512813],
					[63.462338702892716, 5.4111222296301973],
					[63.462338709020614, 5.41112225898602],
					[63.462338712955088, 5.4111222725505934],
					[63.462527599689885, 5.4116139372747787],
					[63.462533099643089, 5.4125583013382723],
					[63.462533104925249, 5.4125583328089029],
					[63.46253312618736, 5.4125583924006699],
					[63.462533141271663, 5.4125584206340962],
					[63.463021994567143, 5.4132000616183538],
					[63.462991500593283, 5.4136277852901991],
					[63.462991501145716, 5.4136278073126372],
					[63.462991507674872, 5.4136278526859867],
					[63.462991513601331, 5.4136278740348667],
					[63.46324151359709, 5.414252875033327],
					[63.463241588799676, 5.4142529359015743],
					[63.463241755754332, 5.4142529651883278],
					[63.463241846660907, 5.4142529357211702],
					[63.463461038207591, 5.4140142635068935],
					[63.463710907174033, 5.4145944571059585],
					[63.463874803394155, 5.4155556330688999],
					[63.463874804591008, 5.4155556449693263],
					[63.463874811362686, 5.4155556642049572],
					[63.463874814300681, 5.4155556738788766],
					[63.464119213629075, 5.4161750729450038],
					[63.46411925713096, 5.4161751237317466],
					[63.464119362886073, 5.4161751847967841],
					[63.464119427030965, 5.4161751988549556],
					[63.464299885245296, 5.4161502177856518],
					[63.464591520900768, 5.4167333896738281],
					[63.46459161570678, 5.4167334440793047],
					[63.464591799621047, 5.4167334330972645],
					[63.464591887883806, 5.4167333698240716],
					[63.464775143620336, 5.4162307881105063],
					[63.465019417759926, 5.4162084983870011],
					[63.465019463238484, 5.4162084846566048],
					[63.465019541003834, 5.4162084327380215],
					[63.465019574286622, 5.41620839844202],
					[63.465322308288997, 5.4156752157550079],
					[63.466374975091092, 5.4158084984932247],
					[63.466375027935776, 5.4158084918729346],
					[63.466375122124212, 5.4158084499446311],
					[63.466375164363612, 5.415808414524431],
					[63.46680289782492, 5.4151974093954571],
					[63.467199982610957, 5.415230798244286],
					[63.467199991617868, 5.415230799124318],
					[63.467200009631732, 5.4152308008843768],
					[63.467200018588436, 5.4152307997620746],
					[63.467435924394437, 5.4152085171005861],
					[63.467508100903316, 5.4158167248355031],
					[63.46750814396146, 5.4158167937610058],
					[63.46750826828265, 5.4158168786179637],
					[63.467508350441413, 5.4158168944371994],
					[63.468008349996396, 5.4156890934927793],
					[63.468008396069408, 5.4156890676333855],
					[63.468008467908298, 5.4156889943547002],
					[63.468008493724341, 5.4156889489378042],
					[63.468133454922551, 5.4151808089530746],
					[63.469327790389791, 5.4152391005736167],
					[63.469327849253126, 5.4152390831533053],
					[63.469327945676532, 5.4152390228594189],
					[63.469327984927489, 5.4152389757563837],
					[63.469533436350353, 5.4147390963488133],
					[63.469891613755223, 5.4147280026105138],
					[63.470358151703905, 5.4152390347926112],
					[63.470358184650919, 5.4152390587875754],
					[63.470358258254613, 5.4152390917494042],
					[63.47035829801559, 5.415239100828499],
					[63.472149997601221, 5.4152612995500231],
					[63.472150047608245, 5.4152612872557722],
					[63.472150137067004, 5.4152612358644712],
					[63.47215017388195, 5.4152611991068804],
					[63.472477869673654, 5.4146863819442581],
					[63.472713792769511, 5.4148362698271058],
					[63.472713880151716, 5.4148362789641613],
					[63.472714028825024, 5.4148362241661214],
					[63.472714091011802, 5.4148361601187345],
					[63.472861261541006, 5.4143751482213975],
					[63.473119408729026, 5.4142669139367001],
					[63.473494339352428, 5.4143862917094481],
					[63.473494408419512, 5.4143862870688597],
					[63.4734945275857, 5.4143862379751306],
					[63.473494579375711, 5.4143861892918688],
					[63.473763870008064, 5.4138505057166766],
					[63.473972011602527, 5.4144278679780244],
					[63.473972050576542, 5.4144279173377443],
					[63.473972151058739, 5.41442798309554],
					[63.473972212566927, 5.4144279994936149],
					[63.474849877720942, 5.414372408698509],
					[63.475152619936551, 5.4150000876228237],
					[63.475152665079683, 5.4150001321935788],
					[63.475152774317486, 5.4150001888216561],
					[63.475152838311807, 5.4150001968731525],
					[63.475472105282797, 5.414939122255789],
					[63.475766502577407, 5.4153944638192693],
					[63.475796999799059, 5.4159139112802999],
					[63.475797005926012, 5.4159139406509],
					[63.475797027086486, 5.4159139962666281],
					[63.475797041174118, 5.4159140206210736],
					[63.476183111840051, 5.4164194849931286],
					[63.476274804023227, 5.4169000374557381],
					[63.476274806065476, 5.4169000472461732],
					[63.476274810995484, 5.4169000647118466],
					[63.476274813933401, 5.4169000743900408],
					[63.476469199121169, 5.4173917352217318],
					[63.476460899947952, 5.4177777962835707],
					[63.476460901245005, 5.417777812195288],
					[63.476460904734736, 5.4177778439065518],
					[63.476460908718828, 5.4177778594816974],
					[63.476666499285486, 5.4184417282515716],
					[63.476658099947507, 5.4189138958926657],
					[63.476658104282514, 5.4189139254891083],
					[63.476658121013614, 5.418913983672577],
					[63.476658134255231, 5.4189140101443991],
					[63.476930420134522, 5.4193222915020494],
					[63.47712201230614, 5.4198556685558055],
					[63.477122054911206, 5.4198557194827934],
					[63.477122164347797, 5.4198557841452812],
					[63.47712223117933, 5.4198557978807962],
					[63.477299805863389, 5.4198280325152242],
					[63.477335899630809, 5.4213389042951352],
					[63.477335899781174, 5.4213389103043204],
					[63.477335901923382, 5.4213389241015557],
					[63.477335902123855, 5.421338932113799],
					[63.477530401588943, 5.42255833159353],
					[63.477530406418083, 5.4225583450547949],
					[63.477530414284956, 5.4225583722014505],
					[63.477530419114082, 5.4225583856627155],
					[63.477744219056042, 5.4230083869522829],
					[63.477744272408152, 5.4230084365405968],
					[63.477744397614927, 5.4230084851781832],
					[63.477744471311048, 5.4230084860064673],
					[63.478022150871759, 5.4229030331553485],
					[63.478452659952531, 5.4233279419831071],
					[63.478452698272363, 5.4233279653217297],
					[63.478452780730926, 5.4233279931865015],
					[63.47845282486962, 5.4233279977126179],
					[63.478672062042072, 5.4233002194415318],
					[63.478880409716226, 5.4239222643442488],
					[63.47888044505423, 5.4239223121701627],
					[63.478880535679842, 5.4239223792043569],
					[63.478880592808849, 5.4239224001917776],
					[63.479797090359526, 5.4239557966172711],
					[63.4800081326769, 5.4242751092371098],
					[63.480008149350041, 5.4242751292567055],
					[63.480008186128771, 5.4242751628381116],
					[63.480008207130034, 5.4242751762878925],
					[63.480402647650173, 5.4244834456630997],
					[63.48059421938909, 5.4248861864176536],
					[63.480594238949479, 5.4248862141152108],
					[63.480594286876801, 5.4248862623804985],
					[63.480594315193684, 5.424886280944933],
					[63.480927651755536, 5.4250418529843438],
					[63.481352611739766, 5.4258972717168321],
					[63.481630401261235, 5.4269528287795357],
					[63.481647000461749, 5.4274278076998828],
					[63.481647025350682, 5.4274278688979409],
					[63.48164710771843, 5.4274279651159461],
					[63.481647166938529, 5.4274279979086719],
					[63.482160993834306, 5.4275140842514125],
					[63.482433117866201, 5.4278389939097567],
					[63.482880407348453, 5.4295111514244301],
					[63.482880443730423, 5.4295112051623571],
					[63.482880545351769, 5.4295112808983257],
					[63.48288060780412, 5.4295112992249956],
					[63.483560896359201, 5.4294835085199171],
					[63.483552599916742, 5.4299138952901194],
					[63.483552616643479, 5.4299139534940402],
					[63.483552681941617, 5.4299140498471585],
					[63.483552728671597, 5.4299140862165354],
					[63.48384703724701, 5.4300251523782119],
					[63.48399420932509, 5.4305111585682742],
					[63.483994258446238, 5.4305112187580455],
					[63.483994384050625, 5.4305112834666831],
					[63.483994460583816, 5.4305112899891199],
					[63.484416583046055, 5.4303752481368228],
					[63.484683103757057, 5.4310444482332105],
					[63.484716500756697, 5.4313917190256262],
					[63.484716501902305, 5.4313917289318052],
					[63.484716504989215, 5.4313917446251523],
					[63.484716506980511, 5.4313917524159283],
					[63.484885901268626, 5.4320083314829137],
					[63.484927599730362, 5.4330444086341796],
					[63.484927628299673, 5.4330444734043324],
					[63.48492771872494, 5.4330445686435382],
					[63.484927783267999, 5.4330445987772888],
					[63.485761082826329, 5.4331140992341602],
					[63.485761086459057, 5.4331141007907826],
					[63.485761091833183, 5.4331141001202115],
					[63.485761095415917, 5.4331140996731664],
					[63.486069395415285, 5.4331223993348532],
					[63.48606940621351, 5.433122399997389],
					[63.486069426814304, 5.4331223974268417],
					[63.486069437562556, 5.4331223960856843],
					[63.486355638201729, 5.4330668958855641],
					[63.486355654274149, 5.4330668918700606],
					[63.486355685423348, 5.4330668799433957],
					[63.486355701445795, 5.4330668739241599],
					[63.486594438772173, 5.4329280084925191],
					[63.486969369001343, 5.4329862971952325],
					[63.486969396867572, 5.4329862977378403],
					[63.486969452400182, 5.4329862908079978],
					[63.486969480066556, 5.4329862833355476],
					[63.487219407997692, 5.4328780149234603],
					[63.487419334026029, 5.432947389654835],
					[63.487419346665554, 5.4329473920975611],
					[63.487419371994598, 5.4329473989868129],
					[63.487419384584193, 5.4329473994257498],
					[63.487644301634383, 5.4329640928202716],
					[63.487958126328984, 5.4333334041471124],
					[63.488288712807154, 5.4341917722456898],
					[63.4882887147484, 5.4341917780337763],
					[63.488288719426592, 5.4341917854904986],
					[63.488288722213554, 5.4341917891629787],
					[63.488538721828689, 5.4346861899909591],
					[63.488538802247341, 5.4346862442829931],
					[63.488538972431392, 5.4346862592364635],
					[63.488539061251167, 5.4346862180057558],
					[63.489055726130786, 5.4339834656155599],
					[63.489849934744917, 5.43367803847452],
					[63.49005544427888, 5.4339362243195151],
					[63.490055507180251, 5.4339362606957833],
					[63.490055641536806, 5.4339362801155833],
					[63.490055713937664, 5.4339362650513463],
					[63.490261202906893, 5.4337945712565467],
					[63.49054160094154, 5.4336502762411563],
					[63.490774800155691, 5.4341889400765142],
					[63.490758100413345, 5.4361666986611485],
					[63.490758099717361, 5.4361667067889465],
					[63.490758101958015, 5.4361667246018541],
					[63.490758103053359, 5.4361667325062974],
					[63.49093870007497, 5.4372583169762798],
					[63.49094980041243, 5.4390250012681491],
					[63.490949801806913, 5.4390250211970592],
					[63.490949811861178, 5.4390250641701616],
					[63.490949817734148, 5.4390250835410603],
					[63.491238717811299, 5.4396528849716326],
					[63.491238747421015, 5.4396529194783207],
					[63.491238817985263, 5.439652975016692],
					[63.491238859835441, 5.4396529959367754],
					[63.491991561330885, 5.4398057769055725],
					[63.492258118385799, 5.4403861827918751],
					[63.492258159988381, 5.4403862298791852],
					[63.492258261105668, 5.4403862856363432],
					[63.492258320720062, 5.4403862983144391],
					[63.492933179170251, 5.4403141145237415],
					[63.493185919113074, 5.4408528858202567],
					[63.493185976987604, 5.4408529369143741],
					[63.493186112937082, 5.4408529843157867],
					[63.493186190066432, 5.4408529787304163],
					[63.493405487371376, 5.440741981327343],
					[63.493577610774004, 5.4412306659967866],
					[63.49357764063258, 5.4412307105290738],
					[63.493577717715361, 5.4412307753161038],
					[63.493577764989396, 5.441230797575086],
					[63.49405260943248, 5.4413140698667393],
					[63.494083100021847, 5.4418778119512146],
					[63.494083104202723, 5.4418778355564372],
					[63.494083117888792, 5.4418778800934051],
					[63.494083128339533, 5.4418779029179118],
					[63.49435259693275, 5.4423306495668591],
					[63.494297000203808, 5.4432305877036207],
					[63.494297001697348, 5.4432306116436671],
					[63.494297011004157, 5.4432306607476608],
					[63.494297018767625, 5.4432306839072941],
					[63.494505399418557, 5.4436806405084939],
					[63.49446919984306, 5.444752793126729],
					[63.494469202182309, 5.4447528149512543],
					[63.494469207606969, 5.444752852475939],
					[63.494469214424683, 5.4447528737432682],
					[63.49469700206869, 5.4453333429550081],
					[63.494727599661545, 5.4458944112280836],
					[63.494727608618966, 5.4458944463033978],
					[63.494727639969291, 5.445894514782907],
					[63.494727662262619, 5.4458945441784659],
					[63.495122009289808, 5.4462722948418394],
					[63.495172001404924, 5.4467500216219467],
					[63.495172029868662, 5.4467500824201567],
					[63.49517212103158, 5.4467501716116322],
					[63.495172182785282, 5.4467501981119186],
					[63.496105463031995, 5.4468307884215772],
					[63.49633309722936, 5.4473667334999636],
					[63.49630810037808, 5.4477527864089641],
					[63.496308100825914, 5.4477528044489798],
					[63.496308104309151, 5.4477528361860434],
					[63.496308107444086, 5.4477528538919735],
					[63.496477603390481, 5.4483694350605827],
					[63.496527601223193, 5.4488917185183823],
					[63.496527637050598, 5.4488917864488933],
					[63.496527746225745, 5.4488918774338515],
					[63.496527818628032, 5.4488918985951766],
					[63.496766592049887, 5.448869610049103],
					[63.496980428438782, 5.4492307017885464],
					[63.496980451577379, 5.4492307290731912],
					[63.496980508404107, 5.4492307742887247],
					[63.496980540251052, 5.4492307904377677],
					[63.497549874235119, 5.4494084698941387],
					[63.49786093473687, 5.4498668120616482],
					[63.497860966931881, 5.4498668422437735],
					[63.497861040776755, 5.4498668853473058],
					[63.497861083322348, 5.4498668981573966],
					[63.498260975210208, 5.449900190761622],
					[63.498524823261896, 5.4503972934186988],
					[63.498524829830146, 5.4503973046673559],
					[63.498524844708363, 5.450397324937498],
					[63.498524853068005, 5.4503973359636104],
					[63.498997053258599, 5.4509112343656758],
					[63.49899708709566, 5.450911258313675],
					[63.498997161587354, 5.4509112912870643],
					[63.498997203137712, 5.4509113002011649],
					[63.499433153558037, 5.4509058016295642],
					[63.499585909331508, 5.4513917594058325],
					[63.499585941973628, 5.4513918076335957],
					[63.499586028158149, 5.4513918773627568],
					[63.499586081700585, 5.4513918988641681],
					[63.499863762879578, 5.4514168889604822],
					[63.500322016201025, 5.4524889797456524],
					[63.500322079545192, 5.4524890342178791],
					[63.500322228876968, 5.4524890780124187],
					[63.500322313023482, 5.4524890655524283],
					[63.500544309375726, 5.4523364048867915],
					[63.501019214993015, 5.4534972763513192],
					[63.501019256591441, 5.4534973234719049],
					[63.501019362381143, 5.4534973867548873],
					[63.501019423736267, 5.4534973972365215],
					[63.501222069831755, 5.4534724183769896],
					[63.5014276125859, 5.4540056725524542],
					[63.501427646023501, 5.4540057166654439],
					[63.50142773021669, 5.4540057786099618],
					[63.501427781022009, 5.4540057984462775],
					[63.501605460146813, 5.4540223882882515],
					[63.501874814969568, 5.4546889749709262],
					[63.501874852039428, 5.4546890206456071],
					[63.501874946134805, 5.4546890833751043],
					[63.501875003160265, 5.4546891004299276],
					[63.502708303149902, 5.4546752003388583],
					[63.502708356643957, 5.4546751836441754],
					[63.502708445918323, 5.4546751242982561],
					[63.502708482594329, 5.45467508153589],
					[63.503041813904602, 5.4539308336789389],
					[63.503555480771823, 5.4540584766864315],
					[63.503922037835295, 5.4545640172145102],
					[63.503922075453296, 5.4545640487460636],
					[63.5039221582539, 5.454564090758649],
					[63.503922205178263, 5.4545640990123143],
					[63.506627785255787, 5.4544946007241011],
					[63.50718600693132, 5.454594585406273],
					[63.507663764183263, 5.455039045840941],
					[63.507663795233199, 5.4550390661238408],
					[63.50766386235972, 5.4550390919857445],
					[63.507663897590213, 5.4550390996811924],
					[63.508122197839356, 5.4550446001679234],
					[63.508122198735073, 5.4550446000567376],
					[63.508122201422168, 5.4550445997231884],
					[63.508122202317892, 5.4550445996120027],
					[63.508755602207351, 5.4550390997449307],
					[63.50875561031836, 5.4550391007495751],
					[63.508755624649659, 5.4550390989705786],
					[63.508755632711015, 5.4550390979698937],
					[63.50948883313513, 5.4549169135509104],
					[63.509938760393119, 5.4553557439060274],
					[63.509938787810569, 5.4553557626301235],
					[63.509938849612439, 5.4553557911672028],
					[63.509938883051504, 5.4553557990859334],
					[63.510477681444932, 5.4554029900921099],
					[63.510691518721906, 5.4557611873323086],
					[63.510913704135127, 5.4563389471428954],
					[63.51106920061423, 5.4578056200951037],
					[63.511069200663847, 5.4578056221006026],
					[63.511069200713479, 5.4578056241061024],
					[63.511069201658792, 5.4578056260004901],
					[63.511227602047754, 5.4590583244189173],
					[63.511227608066889, 5.459058349824196],
					[63.511227626275947, 5.459058395846176],
					[63.511227637619776, 5.4590584185794775],
					[63.511538738258501, 5.4594862176964405],
					[63.511538754877307, 5.4594862357527214],
					[63.511538794285677, 5.459486267076767],
					[63.511538815283799, 5.4594862805666553],
					[63.511947107919354, 5.4596779779135582],
					[63.512263745796382, 5.4598584422922567],
					[63.512597013368911, 5.4605694729289054],
					[63.512780409979626, 5.4611417613959343],
					[63.512780438936574, 5.461141806089544],
					[63.512780517005659, 5.461141874872772],
					[63.51278056517242, 5.4611418970677494],
					[63.513272076430312, 5.4612279802383101],
					[63.513522029230508, 5.4616390047689212],
					[63.513522054158322, 5.4616390318570627],
					[63.513522111877066, 5.4616390770115597],
					[63.513522144568803, 5.4616390910665826],
					[63.513958157615498, 5.4617640669567677],
					[63.514172020158981, 5.4622111862678313],
					[63.51417205254976, 5.4622112245035375],
					[63.514172133953338, 5.4622112828203244],
					[63.51417218197129, 5.4622112990009377],
					[63.514449862274937, 5.462236287952412],
					[63.514655415056893, 5.4627278767728029],
					[63.51465545759774, 5.4627279258235877],
					[63.514655564180174, 5.4627279850470183],
					[63.51465562737566, 5.4627279973364127],
					[63.514858172323919, 5.4627002187843958],
					[63.515677614138959, 5.4647556745228085],
					[63.515677660311546, 5.4647557251405123],
					[63.515677773212715, 5.4647557856029794],
					[63.515677839941375, 5.4647557954477346],
					[63.515974866778556, 5.4646946328150552],
					[63.516199810842529, 5.4653611637997113],
					[63.516199848008213, 5.465361213522427],
					[63.516199944934868, 5.465361282002176],
					[63.51620000469584, 5.4653613007591915],
					[63.51737780534652, 5.4653334999335508],
					[63.517377862375142, 5.4653334807997727],
					[63.51737795409332, 5.4653334110926188],
					[63.517377989728111, 5.4653333624143148],
					[63.517544547048232, 5.4648307919372519],
					[63.518699860828882, 5.4647558085865278],
					[63.518869210239181, 5.4652444625629917],
					[63.519094203963597, 5.465955644999223],
					[63.519166501784298, 5.4664778269271403],
					[63.519166503872761, 5.4664778387419144],
					[63.519166509841135, 5.466477862149703],
					[63.519166513721046, 5.4664778737427149],
					[63.519719213701975, 5.4678639749164457],
					[63.519719247981769, 5.4678640169564163],
					[63.519719332169295, 5.4678640789799831],
					[63.519719382972767, 5.4678640988527176],
					[63.520413773713344, 5.4679223904020251],
					[63.520699823284957, 5.4684639923910998],
					[63.520699830797049, 5.4684640055479239],
					[63.520699849354642, 5.4684640294120381],
					[63.520699858608722, 5.4684640403409928],
					[63.521172055926108, 5.4689362396853847],
					[63.521560928200451, 5.4693584081043864],
					[63.521788713569379, 5.4699389730911134],
					[63.521788744216209, 5.4699390135734252],
					[63.521788822132841, 5.4699390763829108],
					[63.521788869402677, 5.469939098710098],
					[63.522366531607474, 5.4700307753808319],
					[63.522455403801828, 5.4704694400594391],
					[63.522455443949674, 5.4704695015051614],
					[63.522455554606516, 5.4704695803930727],
					[63.522455626011194, 5.4704695977245157],
					[63.522858190317073, 5.4704169153991344],
					[63.523105424243546, 5.470869496135391],
					[63.523105450114841, 5.4708695251360924],
					[63.523105508825395, 5.4708695742258993],
					[63.523105544302396, 5.470869591976351],
					[63.52332489470659, 5.4709334773235581],
					[63.523888757873216, 5.4715001401210674],
					[63.523888789866099, 5.4715001623286037],
					[63.523888858880134, 5.471500192034207],
					[63.523888895901301, 5.4715001995322545],
					[63.525836096006429, 5.4715390997546685],
					[63.525836149551843, 5.4715390850832168],
					[63.525836240723287, 5.4715390295311677],
					[63.525836279245041, 5.4715389885398178],
					[63.526125129816194, 5.4709584886083542],
					[63.526344264007918, 5.4709363154320823],
					[63.526533110839857, 5.4714694663599373],
					[63.526533136062525, 5.4714695055083977],
					[63.526533199548162, 5.471469566091046],
					[63.526533237860555, 5.4714695895318162],
					[63.526713735817403, 5.4715279570817499],
					[63.526899801885207, 5.4721917359242314],
					[63.526933100813736, 5.4725833172112139],
					[63.526933130162689, 5.472583377991441],
					[63.526933221359698, 5.4725834693686233],
					[63.526933284103507, 5.4725834998548715],
					[63.527169256248023, 5.4726029884218157],
					[63.527363712375852, 5.4731361681750021],
					[63.527363744961526, 5.4731362144671021],
					[63.527363830987746, 5.4731362783074582],
					[63.527363883631416, 5.473136299979771],
					[63.527599867313661, 5.4731557898573602],
					[63.52780811905744, 5.4736056846869063],
					[63.527808145027038, 5.4736057177081641],
					[63.527808209259263, 5.4736057721673754],
					[63.527808246626179, 5.4736057937160068],
					[63.528049864711242, 5.4736723699824763],
					[63.528472003630924, 5.4744417599363917],
					[63.528538701179968, 5.475222216642198],
					[63.528538704361033, 5.4752222363779079],
					[63.528538715152372, 5.4752222732892921],
					[63.528538721866944, 5.475222290575636],
					[63.528747018279802, 5.4756278833114607],
					[63.528941514351125, 5.4761194741722239],
					[63.528941546837103, 5.4761195164566763],
					[63.528941631966354, 5.4761195804200664],
					[63.528941682719385, 5.4761195983066537],
					[63.529394382710727, 5.4761584984327438],
					[63.529394386293603, 5.4761584979902427],
					[63.529394390821622, 5.4761584994439394],
					[63.529394394404505, 5.4761584990014391],
					[63.530169301436459, 5.4761807970192633],
					[63.530499843346021, 5.4766001246970273],
					[63.530499845186824, 5.4766001264826967],
					[63.530499847972749, 5.4766001301646519],
					[63.530499849813566, 5.476600131950323],
					[63.53098873227691, 5.4771584113668679],
					[63.53127762105062, 5.4777444885934177],
					[63.531277654333138, 5.4777445267586122],
					[63.5312777347363, 5.4777445812499943],
					[63.531277782801972, 5.4777445994725689],
					[63.532566569091777, 5.4778557893698139],
					[63.532738700937855, 5.4782167480294524],
					[63.532752599943599, 5.478736104460002],
					[63.532752603222875, 5.4787361282131473],
					[63.532752615057085, 5.4787361710419482],
					[63.532752622765642, 5.4787361922352265],
					[63.533038723348369, 5.4792778935427613],
					[63.53303875568546, 5.4792779298155017],
					[63.533038836038727, 5.4792779823073499],
					[63.533038883159143, 5.479277998637003],
					[63.533197066708532, 5.4792918893755092],
					[63.533463718080846, 5.4798750827250267],
					[63.533463764198991, 5.4798751313908927],
					[63.533463875154716, 5.4798751861485631],
					[63.533463940986699, 5.4798751961440884],
					[63.533905480391617, 5.479783528788233],
					[63.534116515953073, 5.4802778786120392],
					[63.534116552965848, 5.4802779223633378],
					[63.534116646105502, 5.4802779833484649],
					[63.534116701336742, 5.4802780006928229],
					[63.534572046840886, 5.4802752013277445],
					[63.534702606728231, 5.4807583527586052],
					[63.534702609612687, 5.480758360455912],
					[63.534702617271627, 5.4807583796439721],
					[63.534702620156089, 5.4807583873412966],
					[63.53516650940275, 5.4817056650291391],
					[63.535272003925918, 5.48223333997736],
					[63.535272021034928, 5.482233378134107],
					[63.535272070332404, 5.4822334465479416],
					[63.53527210157587, 5.4822334749082593],
					[63.53544984330312, 5.4823334411800095],
					[63.535624816862814, 5.4827361804701766],
					[63.535624845616177, 5.4827362171918352],
					[63.535624919845972, 5.4827362764929672],
					[63.53562496437744, 5.4827362971756086],
					[63.536080463204215, 5.4828195775852731],
					[63.536272019137428, 5.4832250839815133],
					[63.536272024856757, 5.4832250973701742],
					[63.536272038834781, 5.4832251177942091],
					[63.53627204629629, 5.4832251289546718],
					[63.536777646155556, 5.4838307287286634],
					[63.536777718002213, 5.483830764167446],
					[63.536777866388931, 5.4838307700364535],
					[63.536777941186799, 5.4838307426948649],
					[63.537261240701312, 5.4833529429767047],
					[63.537261258920317, 5.4833529165687587],
					[63.537261287247404, 5.4833528627394204],
					[63.537261297306245, 5.4833528333106134],
					[63.537416897161684, 5.4824194325342237],
					[63.537416897958821, 5.4824194284089538],
					[63.537416898608093, 5.4824194182614576],
					[63.537416900251664, 5.4824194120182996],
					[63.537447397398232, 5.4819472474402229],
					[63.538002984196098, 5.4806583792688972],
					[63.538002989973016, 5.4806583584207891],
					[63.538002997894623, 5.4806583151592356],
					[63.538003000088644, 5.4806582947532263],
					[63.537941900157051, 5.4784971930933901],
					[63.537941897970803, 5.4784971772551012],
					[63.537941890812498, 5.4784971418954536],
					[63.537941885939098, 5.478497126388941],
					[63.537314133115395, 5.4769306450696496],
					[63.537880571862793, 5.476333619036251],
					[63.538094201692111, 5.476655663219689],
					[63.538122000222543, 5.477433307900978],
					[63.538122003403352, 5.4774333276435376],
					[63.538122012452121, 5.4774333667967756],
					[63.538122019166437, 5.4774333840893652],
					[63.53835261915755, 5.4779278842993069],
					[63.538352651593712, 5.4779279245919934],
					[63.538352734782684, 5.4779279827798772],
					[63.538352783694705, 5.4779279988888394],
					[63.538588754202785, 5.4779473897081772],
					[63.538760911127547, 5.4784389656161041],
					[63.538760951025438, 5.4784390170700927],
					[63.538761052375484, 5.4784390830857417],
					[63.538761113876923, 5.478439099654894],
					[63.539038778440684, 5.4784196087539181],
					[63.539230406646382, 5.4788222599463259],
					[63.539302602151423, 5.4793028296684625],
					[63.539302604140211, 5.4793028374774257],
					[63.539302607222048, 5.4793028532059616],
					[63.539302609210893, 5.4793028610149381],
					[63.539472004366701, 5.4798361436565752],
					[63.539538702278826, 5.4803583253226043],
					[63.539538727893778, 5.4803583805568916],
					[63.539538807244377, 5.4803584654050761],
					[63.539538860084271, 5.480358495129499],
					[63.539919279342371, 5.480436278802423],
					[63.540191533068175, 5.4808473106360296],
					[63.540191567097743, 5.4808473426831386],
					[63.540191646357492, 5.4808473872721022],
					[63.540191689796202, 5.4808474000350023],
					[63.541241570132456, 5.4808973946008326],
					[63.541452618672764, 5.4813472853104583],
					[63.541452652899487, 5.4813473253902867],
					[63.541452736037826, 5.4813473815865486],
					[63.541452784998803, 5.4813473997106543],
					[63.541672063355108, 5.481364090919838],
					[63.541938673169128, 5.4819916778759463],
					[63.541655436143863, 5.4823998864250685],
					[63.541655417620909, 5.4823999370406895],
					[63.541655412122672, 5.4824000424356552],
					[63.541655424202339, 5.4824000953177761],
					[63.541924803080633, 5.4828972560561011],
					[63.541952599709091, 5.4833722128462004],
					[63.541952602790538, 5.4833722285766378],
					[63.541952610794127, 5.4833722618243037],
					[63.541952615716305, 5.4833722793415456],
					[63.542163702321098, 5.483866745701496],
					[63.542194200179893, 5.4844306104376903],
					[63.542194203359756, 5.4844306301838612],
					[63.542194212357344, 5.4844306673371879],
					[63.542194219070858, 5.4844306846338888],
					[63.54242479694453, 5.4849250376584129],
					[63.542399800174479, 5.48531108744438],
					[63.54239980066702, 5.4853111075220014],
					[63.542399807026477, 5.4853111470148361],
					[63.542399811997669, 5.4853111665404306],
					[63.542605411954817, 5.4858889666073276],
					[63.542605412004065, 5.485888968615102],
					[63.542605412004065, 5.485888968615102],
					[63.542835911791101, 5.4865139686846431],
					[63.542835954373423, 5.4865140198260871],
					[63.542836059302815, 5.4865140854380634],
					[63.542836123441397, 5.4865140996878612],
					[63.543052684830265, 5.4864891140658179],
					[63.543283099006473, 5.4869417457597791],
					[63.543271999926162, 5.4874999964485234],
					[63.543272001264924, 5.4875000144086412],
					[63.543272006678833, 5.487500052005676],
					[63.543272011551252, 5.4875000675166277],
					[63.543483111635318, 5.4880806684251846],
					[63.54348311541515, 5.4880806760152829],
					[63.54348312302394, 5.4880806932033117],
					[63.543483126803721, 5.4880807007934154],
					[63.543774827061426, 5.4885807000114903],
					[63.543774862083495, 5.4885807359771901],
					[63.543774944175773, 5.4885807862857021],
					[63.543774991246003, 5.4885808006284931],
					[63.544747190594556, 5.4886223994734573],
					[63.544747260117347, 5.488622376813856],
					[63.54474736309745, 5.4886222896142041],
					[63.544747398395508, 5.4886222268614704],
					[63.544816880106133, 5.4881112600410082],
					[63.545316750534042, 5.4879807930903528],
					[63.545316792239753, 5.4879807718409861],
					[63.545316859931539, 5.487980711138194],
					[63.545316886813346, 5.4879806715743991],
					[63.545541825692531, 5.4873974288546545],
					[63.545958261711149, 5.4874779966447624],
					[63.54595831102565, 5.4874779925835098],
					[63.545958401947352, 5.4874779632556239],
					[63.545958443554532, 5.4874779379889906],
					[63.546341790567972, 5.4870779939254373],
					[63.546602814603823, 5.4870584992189535],
					[63.546602867156636, 5.4870584806589608],
					[63.546602953609124, 5.4870584156253885],
					[63.546602986613074, 5.4870583692621731],
					[63.546791850816476, 5.4865557671604579],
					[63.547030617918573, 5.4864919029263035],
					[63.547505582753423, 5.4865334985291705],
					[63.547505646153411, 5.4865334826591647],
					[63.547505749624513, 5.4865334155245087],
					[63.54750578969567, 5.4865333642598531],
					[63.547675190339859, 5.4860278641856963],
					[63.547675192682348, 5.486027849797547],
					[63.547675199109499, 5.4860278187922962],
					[63.54767519966051, 5.486027804624956],
					[63.547683497187883, 5.4855557963160964],
					[63.548119402693054, 5.4855502004765189],
					[63.548119454399128, 5.4855501840312684],
					[63.548119541146413, 5.4855501310385302],
					[63.548119577929782, 5.4855500922620442],
					[63.548652977632742, 5.4845167912956567],
					[63.548652986097331, 5.4845167701091153],
					[63.548652996657943, 5.4845167244927877],
					[63.548652999698909, 5.4845167019607652],
					[63.548689099834476, 5.4801639280931527],
					[63.548861291797181, 5.4795722554583914],
					[63.548861295084045, 5.4795722429667224],
					[63.548861298921302, 5.4795722163069973],
					[63.548861300416725, 5.4795722040365531],
					[63.548955799053758, 5.4750944433755251],
					[63.54961953580937, 5.4735557885125896],
					[63.550374904871823, 5.4734919083291915],
					[63.550744239087862, 5.4739973184886201],
					[63.550744259337243, 5.4739973381426648],
					[63.550744305160904, 5.4739973747776585],
					[63.550744330636348, 5.4739973877419459],
					[63.550902656065034, 5.4740557605896747],
					[63.551149821252785, 5.4745528885587644],
					[63.551149848167398, 5.4745529235048327],
					[63.551149914141419, 5.4745529757792353],
					[63.551149953200841, 5.4745529931075474],
					[63.551427648033531, 5.4746195695676203],
					[63.551616511144772, 5.4751528672580045],
					[63.551616557363388, 5.4751529199630511],
					[63.551616671254955, 5.4751529844446392],
					[63.551616740719375, 5.4751529959996663],
					[63.552058340899876, 5.4750612954361308],
					[63.55205838175717, 5.4750612762818109],
					[63.552058452278537, 5.4750612212266212],
					[63.55205848104687, 5.4750611854365241],
					[63.552739080715412, 5.4736111855498919],
					[63.552739089128934, 5.4736111623486092],
					[63.552739097894552, 5.4736111169434043],
					[63.552739099988656, 5.4736110925093868],
					[63.55269740260308, 5.4725694614872227],
					[63.553025160965589, 5.4721251185064794],
					[63.553025176396304, 5.472125088392148],
					[63.553025194668223, 5.4721250277071549],
					[63.553025200147133, 5.4721249947954007],
					[63.553011302461499, 5.4715611764966301],
					[63.553377947178824, 5.4711612355039287],
					[63.55337795673497, 5.4711612222330279],
					[63.55337797410526, 5.4711611979215249],
					[63.55337798182051, 5.4711611828639235],
					[63.553811238946402, 5.4702084772851665],
					[63.554452666240536, 5.470091928580409],
					[63.554619210806678, 5.4705806657984297],
					[63.554619257076411, 5.4705807205100019],
					[63.554619376344192, 5.470580784314155],
					[63.55461944755087, 5.4705807936285762],
					[63.554880452441154, 5.4705169427291507],
					[63.55502760295721, 5.4711333375303708],
					[63.555149801705056, 5.4720500253493372],
					[63.555149820855512, 5.4720500733475204],
					[63.555149879362311, 5.4720501507246038],
					[63.555149917872413, 5.4720501822230441],
					[63.555385958925349, 5.4721557568978847],
					[63.555622027180327, 5.4725667993006786],
					[63.555622062206325, 5.4725668352611052],
					[63.555622146095402, 5.4725668853214549],
					[63.555622194958502, 5.4725668994213921],
					[63.556352794998958, 5.472586299419941],
					[63.556352853869079, 5.4725862820590789],
					[63.556352951909396, 5.4725862135086949],
					[63.556352989337569, 5.4725861645496279],
					[63.556566799632705, 5.4719586255333601],
					[63.556955479953167, 5.4722501607128669],
					[63.556955517171993, 5.4722501762543079],
					[63.556955594049498, 5.4722501969607453],
					[63.556955633559902, 5.4722501960994929],
					[63.557455633978044, 5.4721640963237812],
					[63.55745567931347, 5.4721640766070063],
					[63.557455754262691, 5.4721640189706369],
					[63.55745578377762, 5.4721639770334409],
					[63.557686284061703, 5.4716222771722576],
					[63.557686290837403, 5.4716222239451096],
					[63.557686273835543, 5.471622117243963],
					[63.55768625015692, 5.471622067787572],
					[63.557197438945067, 5.4710638693492966],
					[63.557383463892386, 5.4706890196011875],
					[63.557825140674382, 5.4702529417320296],
					[63.557825151175621, 5.4702529303565743],
					[63.557825169441429, 5.4702529059297014],
					[63.557825177206034, 5.470252892878281],
					[63.558341823608366, 5.4692640957403782],
					[63.558699909714477, 5.4692502040208453],
					[63.558933112559636, 5.4695278881118501],
					[63.559024804022478, 5.4700111364786279],
					[63.559024805116081, 5.4700111444032657],
					[63.559024809094716, 5.4700111600306007],
					[63.559024811979768, 5.4700111677333139],
					[63.559249802745128, 5.4706361432052555],
					[63.559299800959906, 5.4712000189497756],
					[63.559299803097531, 5.4712000327903834],
					[63.559299809114769, 5.4712000582407905],
					[63.559299813989021, 5.471200073757533],
					[63.55949151415885, 5.4716917732612291],
					[63.559491516049192, 5.4716917770572877],
					[63.55949152082448, 5.4716917885563401],
					[63.559491522714808, 5.4716917923523809],
					[63.55968309958849, 5.4720556492084036],
					[63.55969700023752, 5.4736083017727974],
					[63.559697008342326, 5.4736083390560921],
					[63.559697037938278, 5.4736084099509688],
					[63.55969706027571, 5.47360844144273],
					[63.559891529852386, 5.4738001130475507],
					[63.560141513696287, 5.4744250739884945],
					[63.560141584346646, 5.4744251337602412],
					[63.560141743426009, 5.4744251725150299],
					[63.560141831805609, 5.474425149489087],
					[63.56031953159799, 5.4742695501794962],
					[63.560319556832589, 5.4742695168303115],
					[63.560319590981173, 5.4742694440909831],
					[63.560319599845762, 5.4742694026918199],
					[63.560327998801874, 5.4737500497943845],
					[63.561336278325648, 5.4717667915266652],
					[63.561336286639914, 5.4717667642996508],
					[63.561336297993122, 5.4717667145292435],
					[63.561336299987921, 5.4717666860695733],
					[63.561308501778704, 5.4713333204540922],
					[63.561494592771837, 5.4706583540313884],
					[63.561494596008423, 5.4706583395240633],
					[63.561494598898761, 5.4706583109532065],
					[63.561494600294459, 5.4706582946587172],
					[63.561461304650898, 5.4691862991696896],
					[63.56190266788154, 5.4691835014365084],
					[63.562377616139152, 5.4703028789427774],
					[63.562377668629523, 5.4703029308824727],
					[63.562377795760966, 5.4703029856665699],
					[63.562377870352563, 5.470302986501836],
					[63.562658213818274, 5.4701974457070897],
					[63.563063698773753, 5.4708806525691678],
					[63.563055399597204, 5.471266695738997],
					[63.56305543018896, 5.4712667705470759],
					[63.563055535119567, 5.47126687242325],
					[63.563055610354176, 5.4712668993804208],
					[63.563991709838554, 5.4712195990421817],
					[63.563991759800707, 5.4712195847920961],
					[63.563991843756014, 5.4712195280392812],
					[63.563991878743771, 5.4712194894441035],
					[63.56415843936778, 5.4708890687195568],
					[63.564397098187563, 5.4708224365699607],
					[63.564610901299844, 5.471230652326847],
					[63.564658099844578, 5.4728750063456806],
					[63.564658102031494, 5.472875022198286],
					[63.564658109141952, 5.4728750555801149],
					[63.564658114016041, 5.4728750711000389],
					[63.564830414332427, 5.4733194729496981],
					[63.564830445923668, 5.4733195153938095],
					[63.564830530158524, 5.4733195795369216],
					[63.564830580961257, 5.4733195994484074],
					[63.565088761702128, 5.4733445880479934],
					[63.565508101971673, 5.4743306451807063],
					[63.565524799694309, 5.4747194086720521],
					[63.565524801831614, 5.4747194225159221],
					[63.565524807996496, 5.4747194540007147],
					[63.565524811925243, 5.4747194676228954],
					[63.565730399357669, 5.475297233430549],
					[63.56571920031373, 5.4759471969313385],
					[63.565719200708898, 5.4759472130064095],
					[63.565719205032686, 5.4759472427039277],
					[63.565719208065609, 5.4759472564371769],
					[63.565980407877269, 5.4768333576067798],
					[63.565980409767469, 5.476833361404017],
					[63.565980413696018, 5.4768333750267111],
					[63.565980417427035, 5.4768333806118044],
					[63.566233095925533, 5.4774139309043246],
					[63.566183101096485, 5.4778860781691003],
					[63.5661831038259, 5.4778861161265686],
					[63.566183124413286, 5.4778861861399522],
					[63.566183141424872, 5.4778862203160159],
					[63.566358105078606, 5.4781167730892575],
					[63.566405399650485, 5.4788111138337383],
					[63.566405429046569, 5.4788111767128145],
					[63.566405520289443, 5.4788112702421712],
					[63.566405582086887, 5.4788112988830013],
					[63.566844381921101, 5.4788501988642642],
					[63.566844382816797, 5.4788501987535456],
					[63.567002783334857, 5.4788640992080371],
					[63.567002836083709, 5.4788640886564464],
					[63.567002929295427, 5.4788640428694455],
					[63.567002968862596, 5.4788640077447379],
					[63.567289044716354, 5.4784140457511832],
					[63.567527907804866, 5.4782612678569294],
					[63.567527928060912, 5.4782612512431967],
					[63.567527963896922, 5.478261210531433],
					[63.567527980421964, 5.4782611883321453],
					[63.568725172204744, 5.4758168032070689],
					[63.568927962708997, 5.4755334154338513],
					[63.568927977293967, 5.4755333874247176],
					[63.568927994720809, 5.4755333288281314],
					[63.568928000249826, 5.4755332979081697],
					[63.568919599886442, 5.4749722294468341],
					[63.569083449769821, 5.4744668872785418],
					[63.569780619091581, 5.4744001998073015],
					[63.569780662734594, 5.4744001843260488],
					[63.569780739673611, 5.4744001344865101],
					[63.569780772969587, 5.4744001001282481],
					[63.570069508882511, 5.4739058096641928],
					[63.570883283381207, 5.4739751988727186],
					[63.570883343245924, 5.4739751854135337],
					[63.570883443325123, 5.4739751266583516],
					[63.570883482594425, 5.4739750794634956],
					[63.571319541591038, 5.4729779725309067],
					[63.57159724315396, 5.4729168940592121],
					[63.571597291973333, 5.4729168698698611],
					[63.571597367373336, 5.4729167940066699],
					[63.57159739494854, 5.4729167462414754],
					[63.5718307537838, 5.4719419141550247],
					[63.572091683244849, 5.4719640985203215],
					[63.572091743109347, 5.4719640850562872],
					[63.572091843138189, 5.4719640242816077],
					[63.572091884247669, 5.4719639788698595],
					[63.573436196333759, 5.4688419822427008],
					[63.573713802053362, 5.4689973755701731],
					[63.573713884854726, 5.4689973814316684],
					[63.573714027376568, 5.4689973315023934],
					[63.573714086151853, 5.4689972738127572],
					[63.574108441884079, 5.4679945851010574],
					[63.574344420690721, 5.4679695997367519],
					[63.574344471399627, 5.4679695793342127],
					[63.574344553462915, 5.4679695187525681],
					[63.574344584767765, 5.4679694765634599],
					[63.574719517921956, 5.46705303987022],
					[63.574958247947507, 5.4671168934708714],
					[63.574958319604256, 5.467116884580471],
					[63.574958440281677, 5.4671168232364593],
					[63.574958486565734, 5.4671167691062204],
					[63.575208486703445, 5.4664472704896871],
					[63.575208489291519, 5.4664472661361554],
					[63.575208491780529, 5.4664472577625682],
					[63.575208492577154, 5.4664472536313626],
					[63.575486249117205, 5.4654363143913507],
					[63.576319272243026, 5.4655084905077143],
					[63.576549821825068, 5.4659583915517018],
					[63.576549856006579, 5.4659584296508763],
					[63.576549939101241, 5.46595848385802],
					[63.576549987118646, 5.465958500077166],
					[63.578355459697306, 5.4660723923930821],
					[63.578563714197671, 5.466608372479131],
					[63.578563761315181, 5.466608425107558],
					[63.578563877798466, 5.4666084852585515],
					[63.578563948109505, 5.4666084946801954],
					[63.578824875813218, 5.4665446369294424],
					[63.579013713168273, 5.4670361723179548],
					[63.579013731874845, 5.4670362022597203],
					[63.579013778096488, 5.4670362550008225],
					[63.579013807402973, 5.4670362775778552],
					[63.579208173120428, 5.4671390596181828],
					[63.579447003136146, 5.4674194759983212],
					[63.579472000156088, 5.4680694086411652],
					[63.579472003338296, 5.468069428411158],
					[63.579472013285631, 5.4680694675066439],
					[63.579472020050687, 5.4680694868321478],
					[63.579666500824011, 5.4684750473207959],
					[63.57967479972919, 5.4690833026105032],
					[63.579674802015575, 5.4690833224918398],
					[63.579674810121716, 5.4690833597997806],
					[63.579674815941431, 5.4690833772264069],
					[63.579941515734276, 5.4697083788990968],
					[63.579941518619357, 5.4697083866073708],
					[63.579941527027181, 5.4697083996803135],
					[63.579941530758482, 5.4697084052671476],
					[63.580172030951331, 5.4700751049998164],
					[63.580172054982079, 5.4700751322680317],
					[63.580172112648945, 5.4700751755308348],
					[63.580172145389383, 5.4700751916364627],
					[63.580611045047846, 5.4702001910013083],
					[63.580611122128516, 5.4702001834605136],
					[63.580611247038348, 5.4702001115092109],
					[63.580611294021274, 5.4702000492201801],
					[63.580797343805528, 5.469480842672624],
					[63.58129434982505, 5.4696084944737908],
					[63.581294419690479, 5.4696084858093581],
					[63.581294536835522, 5.4696084269161949],
					[63.581294585010824, 5.4696083765763834],
					[63.581961229132432, 5.4679835128957315],
					[63.582158282940497, 5.4680001988738889],
					[63.58215834280449, 5.46800018539611],
					[63.582158442880825, 5.468000126595542],
					[63.582158483043685, 5.4680000792622119],
					[63.582302934979396, 5.4676668906274823],
					[63.582563915318865, 5.4676473980130522],
					[63.582563964335293, 5.4676473818470166],
					[63.582564047392971, 5.467647325156193],
					[63.582564081434285, 5.4676472846313953],
					[63.583014044753128, 5.4666918623291174],
					[63.583316763725612, 5.4665890883909212],
					[63.583316801795235, 5.4665890655144551],
					[63.583316864102635, 5.4665890053444741],
					[63.583316888340356, 5.4665889680509832],
					[63.583483435722108, 5.4661280120627298],
					[63.583702782289009, 5.4661473997154557],
					[63.583702842998925, 5.4661473841111494],
					[63.583702943920585, 5.4661473231788928],
					[63.583702984978494, 5.4661472757291314],
					[63.583908308830758, 5.465647702470517],
					[63.584194217911936, 5.4662750834877842],
					[63.584194296577472, 5.4662751402763963],
					[63.584194466010722, 5.4662751615944964],
					[63.584194555833143, 5.4662751242245289],
					[63.58464448972628, 5.4657113073084993],
					[63.58486388496118, 5.4657279997643178],
					[63.584863929696965, 5.4657279921925213],
					[63.584864011556974, 5.4657279598422983],
					[63.584864048631658, 5.4657279330531656],
					[63.585366826743048, 5.465166858875822],
					[63.585566796796158, 5.4650557742052346],
					[63.585566817995755, 5.4650557594707738],
					[63.585566855718248, 5.4650557225150385],
					[63.585566872241088, 5.4650557002937701],
					[63.585833408025572, 5.4646030097777913],
					[63.585991683101241, 5.4646168990376207],
					[63.585991737640157, 5.4646168882290551],
					[63.585991834330358, 5.464616837896501],
					[63.585991874690244, 5.4646167985950518],
					[63.586175174909812, 5.4642889988477874],
					[63.586175175706359, 5.4642889947149218],
					[63.586175178294354, 5.4642889903595089],
					[63.586175179140483, 5.4642889882374206],
					[63.586422342180455, 5.4637890638186475],
					[63.586625062156223, 5.4637223904220766],
					[63.586625101071476, 5.4637223654168574],
					[63.586625165119372, 5.4637223030000177],
					[63.586625189356319, 5.4637222656996887],
					[63.587000137645703, 5.462630813339004],
					[63.587716682602242, 5.4626918999790259],
					[63.587716736245255, 5.4626918892773357],
					[63.587716827659655, 5.4626918436248451],
					[63.587716868118164, 5.4626918083400309],
					[63.587980730339339, 5.4622834678987777],
					[63.588183365168682, 5.4622140893981559],
					[63.588183397912914, 5.4622140691907779],
					[63.58818345509205, 5.4622140197234517],
					[63.588183478581691, 5.4622139885639154],
					[63.588430723256714, 5.4617169000837071],
					[63.590697057958224, 5.461739099191659],
					[63.590902610810836, 5.4623194666373687],
					[63.590902663452951, 5.4623195246460794],
					[63.590902790686329, 5.4623195834676981],
					[63.590902868063907, 5.4623195879687811],
					[63.591385972377758, 5.4621446580488699],
					[63.591610909206658, 5.4628556602112779],
					[63.591610949159971, 5.4628557137476665],
					[63.591611054198964, 5.4628557834014018],
					[63.591611119284629, 5.462855799518727],
					[63.591849861638266, 5.462833514775264],
					[63.592019211070749, 5.4633222647984443],
					[63.592019248237577, 5.4633223146478347],
					[63.592019343324445, 5.4633223815064733],
					[63.592019402189749, 5.4633224004155991],
					[63.592913786711172, 5.4633141012604121],
					[63.593183127760355, 5.4637695013877865],
					[63.593183148110327, 5.4637695250854845],
					[63.59318319587674, 5.4637695675676774],
					[63.593183224139317, 5.463769584229575],
					[63.593580524411749, 5.4639334846355254],
					[63.593580550635025, 5.4639334914638358],
					[63.593580607411376, 5.4639334985298822],
					[63.593580635227767, 5.4639334970902596],
					[63.594058335248342, 5.4638473979543392],
					[63.594058382324022, 5.4638473759632236],
					[63.594058457120262, 5.4638473121939466],
					[63.594058486632207, 5.4638472701931349],
					[63.594391886634014, 5.4629667707205387],
					[63.594391893055793, 5.4629667396590396],
					[63.59439189599707, 5.4629666767495735],
					[63.594391891620859, 5.4629666450129601],
					[63.594316899888639, 5.4627027734205154],
					[63.594322399975468, 5.462313903270573],
					[63.594322394703433, 5.4623138716455131],
					[63.594322377043262, 5.4623138112977729],
					[63.594322362913367, 5.462313784809222],
					[63.594091901139187, 5.4619888381511492],
					[63.594100200268258, 5.4614722039633756],
					[63.594100197980765, 5.461472184072643],
					[63.594100191663955, 5.4614721465253302],
					[63.594100185793685, 5.4614721270801967],
					[63.593472386153934, 5.4598610269594738],
					[63.593472304649943, 5.4598609644713605],
					[63.593472128946203, 5.4598609439663628],
					[63.593472034796136, 5.4598609879607558],
					[63.59316391716159, 5.46031072138683],
					[63.593011297857636, 5.4600388448907555],
					[63.592941901040248, 5.4583056198900914],
					[63.593155794188569, 5.457413947840962],
					[63.593155795681966, 5.4574139355502496],
					[63.593155798817754, 5.4574139170026719],
					[63.593155800360833, 5.4574139067232332],
					[63.593200194861737, 5.455864075753424],
					[63.593600025703665, 5.4558112981979789],
					[63.593600072827726, 5.455811278205311],
					[63.593600151401809, 5.4558112220149937],
					[63.593600181856772, 5.4558111819063368],
					[63.594052794436486, 5.4548115989446755],
					[63.594452616140494, 5.4557556782998295],
					[63.594452644005159, 5.4557557151796674],
					[63.594452715558859, 5.4557557748626939],
					[63.594452759198205, 5.4557557956545191],
					[63.594810959168122, 5.4558307741654195],
					[63.595213700345127, 5.4567278430097055],
					[63.595219200387156, 5.4573333020260213],
					[63.595219201729847, 5.4573333200172822],
					[63.595219208893759, 5.4573333554421586],
					[63.595219213868923, 5.4573333749987105],
					[63.595413713945703, 5.4578250747247319],
					[63.595413772859516, 5.4578251319533599],
					[63.595413915172465, 5.4578251828333491],
					[63.595413996730628, 5.4578251746963273],
					[63.595613718967805, 5.4577142290713256],
					[63.595644200262193, 5.458236110971332],
					[63.595644203396184, 5.4582361287398866],
					[63.595644211405897, 5.4582361620425424],
					[63.595644216331294, 5.4582361795880949],
					[63.59583590230023, 5.4586833460059694],
					[63.595866499952564, 5.4592056116431271],
					[63.595866501046849, 5.4592056195775402],
					[63.595866504180691, 5.4592056373463667],
					[63.595866505274977, 5.4592056452807807],
					[63.59612480506479, 5.4603083464872624],
					[63.596124805960471, 5.4603083463758173],
					[63.596124806010103, 5.4603083483873069],
					[63.596255405605262, 5.4608389475390648],
					[63.596255417993639, 5.4608389762630285],
					[63.596255451677813, 5.4608390305851193],
					[63.596255473819674, 5.4608390540603367],
					[63.596724805289512, 5.4612222993912765],
					[63.596755399817063, 5.4617889106697985],
					[63.596755400911164, 5.4617889186045492],
					[63.596755403149025, 5.46178893648562],
					[63.596755405138872, 5.4617889443089798],
					[63.596885905192288, 5.4623611448763949],
					[63.596885959873809, 5.4623612127320271],
					[63.596886104373503, 5.4623612795067995],
					[63.596886192400255, 5.4623612786487117],
					[63.597247291866324, 5.4621751772263565],
					[63.597247318340592, 5.46217515779193],
					[63.597247363029354, 5.4621751118793034],
					[63.597247382089989, 5.4621750832781464],
					[63.597513998772207, 5.4615946649962419],
					[63.597869316619786, 5.4617557817171081],
					[63.597869380611428, 5.4617557898999625],
					[63.59786950028812, 5.461755760889683],
					[63.597869554280983, 5.4617557279426006],
					[63.598136251841936, 5.461433429090504],
					[63.598314050231124, 5.4612307325979437],
					[63.598314066654083, 5.4612307063409977],
					[63.598314091289822, 5.4612306487950084],
					[63.598314098656516, 5.4612306196290579],
					[63.59836409384048, 5.4607167673916699],
					[63.598572368597409, 5.4603890062269471],
					[63.598572379695653, 5.4603889826496763],
					[63.598572396368958, 5.4603889301288788],
					[63.598572400202329, 5.4603889034199362],
					[63.598580798760111, 5.4598722470587866],
					[63.5987862786682, 5.4594556879788092],
					[63.598786287128782, 5.459455666747143],
					[63.598786297680817, 5.459455621040858],
					[63.598786299772222, 5.4594555965662499],
					[63.598775200171595, 5.4587638964042133],
					[63.598775197983016, 5.4587638805338008],
					[63.598775191814411, 5.458763849016016],
					[63.598775187784788, 5.4587638313569657],
					[63.598452988020377, 5.4578721321587595],
					[63.598452940849981, 5.4578720774974547],
					[63.598452823417922, 5.4578720154426703],
					[63.598452751315179, 5.4578720062606116],
					[63.598197311594291, 5.457935865122483],
					[63.597716901974202, 5.4568999605084478],
					[63.597744600174622, 5.4562972093807627],
					[63.597744598931143, 5.4562971954111559],
					[63.597744597339897, 5.4562971673603364],
					[63.59774459520073, 5.4562971535023088],
					[63.597505794654772, 5.4552832534734934],
					[63.597505789828446, 5.455283239950476],
					[63.59750577833465, 5.4552832111161296],
					[63.597505770821215, 5.4552831979279492],
					[63.597314096714115, 5.4549638410404331],
					[63.597266900033389, 5.4539666916120106],
					[63.597266897844278, 5.453966675742822],
					[63.59726689172436, 5.4539666462393441],
					[63.597266887743871, 5.4539666305934587],
					[63.597022400949307, 5.453297166488511],
					[63.597041899108959, 5.4522611404805446],
					[63.597264085382967, 5.4517194769218378],
					[63.597264092200511, 5.4517194256274424],
					[63.597264079910296, 5.4517193282901815],
					[63.597264061698212, 5.4517192821355955],
					[63.596994629563241, 5.4513499762654476],
					[63.597716836121108, 5.4497251861082239],
					[63.597935966172443, 5.4497030147762482],
					[63.598163712380014, 5.4503250685900957],
					[63.598163753134081, 5.4503251179858019],
					[63.59816385718365, 5.45032518369693],
					[63.59816391953369, 5.450325198112508],
					[63.598385952226508, 5.4503030158065151],
					[63.598533106769985, 5.4508333527227588],
					[63.598533152251157, 5.4508334116192527],
					[63.598533269934883, 5.450833483703283],
					[63.598533342983423, 5.4508334947674157],
					[63.598810977931358, 5.4507724314078265],
					[63.599424815078009, 5.4522944757333134],
					[63.599424838365643, 5.4522945091511916],
					[63.599424898177269, 5.4522945662644027],
					[63.599424933755884, 5.4522945880597398],
					[63.599741577299035, 5.4524057693886521],
					[63.600091540185375, 5.4528695202803634],
					[63.600091572330307, 5.4528695485589731],
					[63.600091644233835, 5.4528695860050629],
					[63.600091684091872, 5.4528695991960747],
					[63.600330471741465, 5.452889090997985],
					[63.600560922273353, 5.4533417900046688],
					[63.600560948247796, 5.4533418230896578],
					[63.600561010596941, 5.4533418738368606],
					[63.600561047917033, 5.4533418933991591],
					[63.600797097208066, 5.4534057799188282],
					[63.601224858740494, 5.4538307414616343],
					[63.601224878893213, 5.4538307571113158],
					[63.601224922681986, 5.4538307839403197],
					[63.601224947164013, 5.4538307929961123],
					[63.601466556594822, 5.4538973678445553],
					[63.60167481927855, 5.4543472846623597],
					[63.601674844357071, 5.4543473178612185],
					[63.601674907700996, 5.4543473725246026],
					[63.60167494591667, 5.4543473919773016],
					[63.601891571612924, 5.4544084714904972],
					[63.602124830959681, 5.454775107582682],
					[63.602124842055964, 5.4547751203262269],
					[63.602124866089596, 5.4547751476018842],
					[63.602124878927562, 5.4547751581102251],
					[63.602380442550349, 5.4549695303339547],
					[63.602610922097817, 5.4554222902365987],
					[63.602610958123229, 5.4554223301456384],
					[63.602611046645599, 5.4554223857113504],
					[63.602611098197151, 5.4554223994677029],
					[63.606508297654479, 5.4554557999240023],
					[63.606508355626119, 5.4554557826056422],
					[63.606508450173173, 5.4554557183414172],
					[63.606508486698942, 5.4554556693833369],
					[63.60669731458578, 5.454953062138272],
					[63.606833168689008, 5.4550084616439234],
					[63.607083102256709, 5.455375064490247],
					[63.607124800123934, 5.4564167077875112],
					[63.607124802263129, 5.4564167216500943],
					[63.607124806591187, 5.4564167513875406],
					[63.607124811417464, 5.4564167649152555],
					[63.607277603451458, 5.4568611428695188],
					[63.607327600599127, 5.4574278185359137],
					[63.607327602738216, 5.4574278323986762],
					[63.607327608807772, 5.4574278599010162],
					[63.60732761363397, 5.4574278734290163],
					[63.607555414126857, 5.4580111731912089],
					[63.607555441144996, 5.4580112122137505],
					[63.607555510906444, 5.4580112721521079],
					[63.607555552754079, 5.4580112931795153],
					[63.607833253029654, 5.4580779947248796],
					[63.607833323789656, 5.4580779859105064],
					[63.607833441675709, 5.4580779207632091],
					[63.607833488851398, 5.4580778664426211],
					[63.608041837064576, 5.4574891138883679],
					[63.608261081866324, 5.4575084989282123],
					[63.608261138195829, 5.4575084878730609],
					[63.608261234833229, 5.4575084354623895],
					[63.60826127519082, 5.457508396119171],
					[63.608464075162338, 5.4571389949714986],
					[63.608464083622529, 5.4571389737318432],
					[63.608464096860786, 5.4571389276742472],
					[63.608464099847438, 5.4571389030795219],
					[63.608480800026136, 5.4561861032672283],
					[63.608480788931267, 5.4561860541862899],
					[63.608480743751613, 5.4561859710011378],
					[63.608480711408525, 5.4561859346612955],
					[63.608186261246374, 5.4559887668786722],
					[63.60801410013574, 5.4555832603602425],
					[63.608036300245551, 5.4519556487789389],
					[63.608264078905556, 5.451500089921681],
					[63.60826408736488, 5.4515000686805264],
					[63.608264097018591, 5.4515000230678163],
					[63.608264100054022, 5.4515000004850345],
					[63.608266899919663, 5.4489472473670117],
					[63.608494432256769, 5.4484920841389997],
					[63.608858137664747, 5.4489918173984169],
					[63.608858181653538, 5.4489918522762633],
					[63.608858278736854, 5.4489918906158854],
					[63.608858333722296, 5.4489918978786811],
					[63.609358256814176, 5.448905809474363],
					[63.609941590924691, 5.4492834672896482],
					[63.609941612769695, 5.4492834786920907],
					[63.609941658947619, 5.4492834931116221],
					[63.609941681538956, 5.449283498364875],
					[63.610730565874086, 5.4493529985421931],
					[63.611488828594155, 5.4495501881305328],
					[63.612419308845162, 5.4500251789316003],
					[63.612419314269054, 5.4500251802731201],
					[63.612419323375285, 5.4500251851924908],
					[63.612419327903503, 5.4500251866458598],
					[63.613294328378593, 5.4503640857318594],
					[63.613294344700066, 5.4503640917694174],
					[63.613294378985081, 5.4503640975827787],
					[63.613294396998398, 5.4503640993712672],
					[63.614199914148102, 5.4503779988853625],
					[63.614891556127404, 5.4510973381536703],
					[63.614891660972759, 5.4510973634247692],
					[63.614891831999479, 5.4510973037104833],
					[63.61489189907649, 5.4510972186132935],
					[63.615141899021985, 5.4485444197697097],
					[63.615141899718445, 5.4485444116065676],
					[63.615141900265563, 5.4485443974049588],
					[63.615141900066376, 5.4485443893537253],
					[63.614964100125611, 5.4453638899296921],
					[63.614964099080382, 5.4453638840033385],
					[63.614964098930855, 5.4453638779649527],
					[63.614964097935498, 5.445363874051381],
					[63.614547398292075, 5.4421610739765134],
					[63.61454739724671, 5.4421610680503658],
					[63.614547395156031, 5.4421610561980485],
					[63.614547394110701, 5.4421610502718778],
					[63.613775197887598, 5.439194365057384],
					[63.613483499231066, 5.4357388830555768],
					[63.613483498135466, 5.4357388751172122],
					[63.613483495994274, 5.4357388612531601],
					[63.61348349400302, 5.4357388534270985],
					[63.613036300202246, 5.4338777784586068],
					[63.613072400185501, 5.4316306246645558],
					[63.613225194998222, 5.4309528442348354],
					[63.613225197434986, 5.4309528338342821],
					[63.613225198725878, 5.4309528134829934],
					[63.613225200266974, 5.430952803194895],
					[63.613275199089017, 5.4279278405996969],
					[63.613444584666446, 5.4275111740735857],
					[63.613444590535359, 5.4275111571844636],
					[63.613444597744774, 5.4275111219564147],
					[63.613444599980944, 5.4275111035049024],
					[63.613508499031248, 5.423752880393061],
					[63.613808446140837, 5.4234334367194403],
					[63.613808466038989, 5.4234334059502149],
					[63.613808493045177, 5.423433335926152],
					[63.613808500203447, 5.4234332986839844],
					[63.613802999719425, 5.4227833384740114],
					[63.614194395414032, 5.4218227437023128],
					[63.614383113149607, 5.4223139717590332],
					[63.614383154808806, 5.4223140210290248],
					[63.614383260611767, 5.4223140844347606],
					[63.614383324755487, 5.4223140985705109],
					[63.614583185880242, 5.4222891165965015],
					[63.614794205781514, 5.4226972582194772],
					[63.614880400660894, 5.4234389220451336],
					[63.614880404694894, 5.4234389397089897],
					[63.614880413608411, 5.4234389729112511],
					[63.614880420229092, 5.4234389862115204],
					[63.615055404077985, 5.4238000527832044],
					[63.615102600933618, 5.4244083158419167],
					[63.615102620200574, 5.4244083679329469],
					[63.615102684307608, 5.4244084527455323],
					[63.615102729197829, 5.4244084874798588],
					[63.615541562919084, 5.4245751619043086],
					[63.615755428318451, 5.4249390013560363],
					[63.615755462410469, 5.4249390354310387],
					[63.615755543628367, 5.4249390857887576],
					[63.615755591599807, 5.4249390999460267],
					[63.616705592241622, 5.4249779991920262],
					[63.616705651154852, 5.4249779837050305],
					[63.6167057483744, 5.4249779189778424],
					[63.616705785835165, 5.4249778718631951],
					[63.6171612269234, 5.4238058267910771],
					[63.617616663577529, 5.4238890970957607],
					[63.617616729806336, 5.4238890867431637],
					[63.617616840560594, 5.4238890243454803],
					[63.61761688513613, 5.423888974313348],
					[63.617719554591829, 5.4236362495572843],
					[63.618041782406578, 5.423491882181481],
					[63.61804181414886, 5.423491857994196],
					[63.618041868426083, 5.4234918006819379],
					[63.618041889119517, 5.4234917657694357],
					[63.618189088816088, 5.423072266293766],
					[63.618189092097779, 5.4230722537650138],
					[63.61818909771538, 5.4230722268073004],
					[63.618189100101432, 5.4230722143912917],
					[63.618294580712821, 5.4215252837192365],
					[63.618433229284392, 5.4215779865472022],
					[63.618433297605428, 5.4215779880412285],
					[63.618433421349373, 5.4215779421707371],
					[63.618433474185743, 5.4215778991704795],
					[63.618761220883144, 5.4210001927577585],
					[63.619616712839573, 5.4209445993843861],
					[63.619616765382297, 5.4209445806507155],
					[63.619616850956305, 5.4209445173692865],
					[63.619616884933421, 5.4209444747217566],
					[63.619802929887975, 5.4204835139881373],
					[63.620038881385895, 5.4205057995970494],
					[63.620038941244388, 5.420505785999052],
					[63.620039042194954, 5.420505726836728],
					[63.620039083287033, 5.4205056812723962],
					[63.620702982821292, 5.4190028818863576],
					[63.620702988638946, 5.4190028629780151],
					[63.620702998432741, 5.4190028233739476],
					[63.620702999772099, 5.4190028050299448],
					[63.620716899341751, 5.4182694499885429],
					[63.621125130228286, 5.4174807844451651],
					[63.62150562706384, 5.4174279970467492],
					[63.621505680401235, 5.4174279741659399],
					[63.621505763035429, 5.4174279011423856],
					[63.621505793278132, 5.417427852899892],
					[63.621630793142891, 5.4169639520260766],
					[63.621630796323551, 5.4169639354687114],
					[63.621630799152499, 5.416963904818938],
					[63.621630799646212, 5.416963888600395],
					[63.621600202951562, 5.4164417384695094],
					[63.621847381890753, 5.4159000834745914],
					[63.621847388553377, 5.4159000624386779],
					[63.621847398346318, 5.4159000228319591],
					[63.62184739963503, 5.415900002473867],
					[63.621866900132339, 5.4146028033294975],
					[63.621866897838267, 5.4146027834233852],
					[63.621866890613433, 5.4146027459634238],
					[63.621866885632407, 5.4146027263963443],
					[63.621600200306347, 5.4139305612606012],
					[63.621625199663086, 5.4104306461515046],
					[63.621869579384025, 5.4099333870473565],
					[63.62186958778674, 5.409933363770536],
					[63.621869598221927, 5.4099333139826076],
					[63.62186960030477, 5.4099332894846981],
					[63.621780801840217, 5.4081056318703569],
					[63.622052986961975, 5.407394470440023],
					[63.622052991087529, 5.4073944557813443],
					[63.622052998342284, 5.4073944225508193],
					[63.622052999680164, 5.4073944042054594],
					[63.622100196567366, 5.4055390245813761],
					[63.622322288735951, 5.4054279787874071],
					[63.622322316097637, 5.4054279591692334],
					[63.622322359922073, 5.4054279152528153],
					[63.622322379021355, 5.4054278886014586],
					[63.622611236624287, 5.4048473732259037],
					[63.622888791196253, 5.4047835295095066],
					[63.623333100857202, 5.4056861481506173],
					[63.623427599406291, 5.409372160903227],
					[63.623241516679812, 5.4097888174713509],
					[63.623241510913736, 5.4097888383965413],
					[63.623241502118852, 5.4097888819207123],
					[63.623241499935389, 5.4097889023932009],
					[63.623252598893302, 5.4104805508884182],
					[63.622758123664092, 5.4113916052363038],
					[63.622758115261114, 5.4113916285134396],
					[63.622758103084465, 5.4113916805419313],
					[63.622758100105749, 5.4113917051536262],
					[63.622771998871087, 5.4118666581329782],
					[63.622524818282628, 5.4124082162913005],
					[63.622524810724855, 5.4124082374416087],
					[63.622524801979488, 5.4124082829771405],
					[63.622524799845962, 5.4124083054621908],
					[63.622535898715668, 5.4128416670090376],
					[63.622327613088466, 5.4133888284207403],
					[63.622327608116926, 5.4133888452049872],
					[63.62232760180671, 5.4133888803345371],
					[63.622327599572429, 5.413388898792884],
					[63.622322000152167, 5.4148999983942225],
					[63.622322001399716, 5.414900012373935],
					[63.622322004790497, 5.4149000402203553],
					[63.622322007879625, 5.4149000559873235],
					[63.622510908261155, 5.4155667544821329],
					[63.622510940070455, 5.4155668049987113],
					[63.622511027218323, 5.4155668768089011],
					[63.622511081661216, 5.415566898215479],
					[63.622727781972742, 5.4155862997577202],
					[63.622727842726349, 5.4155862860349337],
					[63.622727943624476, 5.4155862248360167],
					[63.622727983768975, 5.4155861773599083],
					[63.622955728208048, 5.415041911894221],
					[63.62315278331662, 5.4150584987915646],
					[63.623152838746535, 5.4150584877587384],
					[63.623152936262862, 5.4150584350622379],
					[63.623152975662329, 5.4150583937375911],
					[63.623483430119222, 5.4144390800022046],
					[63.62366393267849, 5.4144084976756526],
					[63.623663970940079, 5.4144084827488133],
					[63.623664038154857, 5.4144084399320151],
					[63.623664067057717, 5.4144084100287273],
					[63.62390571082706, 5.4140418956268572],
					[63.624563908610547, 5.4140113000919392],
					[63.624563962047908, 5.4140112812289134],
					[63.624564050355872, 5.4140112195894838],
					[63.624564085226453, 5.4140111768131032],
					[63.624772333296725, 5.413508501000412],
					[63.625863898011971, 5.4135169001467593],
					[63.625863949758497, 5.4135168855349836],
					[63.625864040108972, 5.41351683373244],
					[63.625864076871302, 5.4135167947542779],
					[63.626149985044364, 5.4129782566754985],
					[63.626591496215859, 5.4139667338047799],
					[63.626544200926908, 5.4144388802296124],
					[63.626544202425926, 5.4144389042790975],
					[63.626544209056874, 5.4144389539394622],
					[63.626544215929812, 5.4144389773107067],
					[63.627463715620543, 5.4165944786563438],
					[63.627463755389975, 5.4165945241386995],
					[63.62746385303393, 5.4165945845402934],
					[63.627463911804178, 5.4165945993465687],
					[63.629294280444896, 5.4164891070459333],
					[63.629944200705829, 5.4177972508460606],
					[63.629969200177499, 5.4186667067600034],
					[63.629969202370596, 5.4186667226445131],
					[63.629969207602237, 5.4186667522868319],
					[63.629969211586641, 5.4186667679454938],
					[63.630163711763231, 5.4192028688399825],
					[63.630163753423588, 5.4192029181320178],
					[63.630163859278071, 5.4192029835733297],
					[63.630163922576557, 5.4192029998355293],
					[63.630380395587849, 5.4191780257716209],
					[63.630347000087369, 5.4210805954610946],
					[63.630347026076983, 5.4210806649119423],
					[63.630347117557712, 5.4210807665142031],
					[63.630347183944515, 5.421080798552727],
					[63.630666558750313, 5.4211057894209222],
					[63.630855412702672, 5.4215972712212706],
					[63.630855446150747, 5.4215973154919306],
					[63.630855528616742, 5.4215973798497137],
					[63.630855580321608, 5.4215973995983102],
					[63.630994207921255, 5.4216112824676221],
					[63.631038700197678, 5.4226528091705735],
					[63.631038702340177, 5.4226528230421218],
					[63.631038707571008, 5.4226528526863111],
					[63.631038710609175, 5.4226528664450546],
					[63.631244210991461, 5.4232333675966151],
					[63.63124427270612, 5.423233428512396],
					[63.631244420404016, 5.4232334786007312],
					[63.631244506437511, 5.4232334697871467],
					[63.631488803034806, 5.4230808966048993],
					[63.631774814230667, 5.4237972738351354],
					[63.631774854048025, 5.4237973213482684],
					[63.631774955372066, 5.4237973853602082],
					[63.631775014987149, 5.4237973980566707],
					[63.632591714950657, 5.4237362988988016],
					[63.632591763961159, 5.4237362826261037],
					[63.632591846999624, 5.4237362257032453],
					[63.632591881027601, 5.4237361850530803],
					[63.633061221616295, 5.4227391109658605],
					[63.6333055839862, 5.4227584980747361],
					[63.633305647377263, 5.4227584820089874],
					[63.633305749073656, 5.4227584166706144],
					[63.63330578912008, 5.4227583651583595],
					[63.633450144708256, 5.422339092997599],
					[63.63376671141225, 5.4223224009132238],
					[63.633766769228629, 5.4223223774668785],
					[63.633766860771509, 5.4223223012825077],
					[63.633766893602349, 5.4223222486573333],
					[63.633913960969345, 5.4217283859648511],
					[63.63408870289377, 5.4220889515588144],
					[63.634133097200909, 5.4227860644638985],
					[63.633952615912733, 5.4232027217007159],
					[63.63395261004446, 5.4232027386030266],
					[63.633952602040921, 5.423202777998692],
					[63.633952599805426, 5.4232027964638867],
					[63.63394420140979, 5.4237221524985761],
					[63.633716520728484, 5.4241805103660736],
					[63.633716514014552, 5.424180529394885],
					[63.633716503323789, 5.4241805691282554],
					[63.633716500242599, 5.4241805897200681],
					[63.633683103093702, 5.4247832445390776],
					[63.63348042456515, 5.4251527039618983],
					[63.633480415264344, 5.4251527273566476],
					[63.633480403032642, 5.4251527773850983],
					[63.633480400051624, 5.4251528020047433],
					[63.63350809870245, 5.4272332560219869],
					[63.632516520241339, 5.429311014653325],
					[63.632516511785468, 5.42931103591933],
					[63.632516502038989, 5.4293110775505395],
					[63.632516499902849, 5.429311100042332],
					[63.632519200022934, 5.4301361013770562],
					[63.632519221279594, 5.4301361613344321],
					[63.632519298273301, 5.4301362587327766],
					[63.632519353064581, 5.4301362942723088],
					[63.633055553190673, 5.4302668931104767],
					[63.633055622207017, 5.4302668864563444],
					[63.633055739283506, 5.4302668252737201],
					[63.633055785602352, 5.4302667729844103],
					[63.633202959989873, 5.42989184005532],
					[63.633541797207933, 5.4297029747162098],
					[63.633541821885991, 5.4297029554509919],
					[63.633541864722126, 5.4297029076383625],
					[63.633541882930217, 5.4297028811050252],
					[63.633747331669468, 5.4292445952449402],
					[63.634088784579028, 5.4292308051600768],
					[63.634416524730142, 5.4298222956505136],
					[63.634685923608195, 5.4303194958097079],
					[63.634685959590506, 5.4303195337163466],
					[63.634686047126358, 5.4303195853474895],
					[63.634686096888615, 5.4303195992971451],
					[63.635880596769603, 5.4303363000506879],
					[63.635880651204971, 5.4303362851260149],
					[63.635880742357166, 5.4303362292164437],
					[63.635880779969639, 5.4303361881189636],
					[63.636144524802305, 5.4297945994584964],
					[63.636625000156151, 5.4297946000294077],
					[63.636625052800106, 5.4297945853284775],
					[63.636625143056399, 5.4297945295282437],
					[63.636625178877381, 5.4297944886541538],
					[63.637014079167997, 5.4290056873247305],
					[63.63701408598277, 5.4290056723234494],
					[63.637014095879316, 5.4290056367284656],
					[63.637014099061346, 5.429005620163383],
					[63.637086295086547, 5.4282778629648503],
					[63.637391756822041, 5.4277392030783069],
					[63.63763039408159, 5.4279333906407512],
					[63.63754419958498, 5.429266686486991],
					[63.637544212630814, 5.4292667414247857],
					[63.637544264396297, 5.4292668359480531],
					[63.63754430401162, 5.4292668754208986],
					[63.637919304261871, 5.4294723751374203],
					[63.637919386167972, 5.4294723810038281],
					[63.637919525986128, 5.4294723310919535],
					[63.637919584793806, 5.4294722752010491],
					[63.63874735141281, 5.4274223581675933],
					[63.639150064815261, 5.427283489884176],
					[63.639150101086472, 5.4272834671333792],
					[63.639150159943362, 5.4272834132499517],
					[63.639150184270292, 5.4272833798774416],
					[63.639711284174787, 5.4259889805630754],
					[63.63971128914779, 5.4259889637706014],
					[63.639711297302362, 5.4259889304111626],
					[63.639711299588356, 5.4259889139569308],
					[63.639786297989531, 5.4249139329031362],
					[63.639955793260192, 5.4242778503608271],
					[63.639955795646287, 5.4242778379353442],
					[63.639955799422509, 5.4242778091681396],
					[63.63995579996709, 5.4242777949537437],
					[63.639930799804624, 5.4233666957348401],
					[63.63993079761201, 5.4233666798444045],
					[63.639930793226775, 5.4233666480635199],
					[63.63993078929299, 5.4233666344132452],
					[63.639741889228212, 5.4228305340012035],
					[63.639741797705788, 5.4228304667199136],
					[63.639741613343361, 5.4228304596348327],
					[63.639741516870558, 5.4228305182679124],
					[63.639555447823668, 5.4232470511536679],
					[63.639397114192654, 5.4233220185858615],
					[63.639397085932856, 5.4233220383120031],
					[63.639397037725061, 5.4233220868212548],
					[63.639397018672696, 5.4233221154915245],
					[63.639124869807468, 5.4239054045449455],
					[63.638788891705289, 5.4239191999881307],
					[63.638788838266684, 5.4239192188428289],
					[63.638788748213457, 5.423919282723852],
					[63.63878871339017, 5.4239193275245503],
					[63.638660913051936, 5.424252727508442],
					[63.638660908079181, 5.4242527443005653],
					[63.638660901766428, 5.4242527794480901],
					[63.638660900426444, 5.42425279780349],
					[63.638652600674405, 5.4247305653797859],
					[63.638469210174044, 5.4252777353951673],
					[63.638469207787878, 5.4252777478199103],
					[63.638469202865124, 5.4252777666261718],
					[63.638469201374633, 5.425277778938157],
					[63.63839700523117, 5.4259193426143462],
					[63.638149931571853, 5.426416386587074],
					[63.63797239861011, 5.4262721033046155],
					[63.637961300461413, 5.4244972308685062],
					[63.638133491166677, 5.4239500593900498],
					[63.638133493602872, 5.4239500489797301],
					[63.638133498425162, 5.4239500261447011],
					[63.638133499070037, 5.4239500159599769],
					[63.638186295728893, 5.4232611542886691],
					[63.638616879275048, 5.4223889879635152],
					[63.638616889915085, 5.4223889462081623],
					[63.638616892336188, 5.422388863052837],
					[63.638616884117255, 5.422388821652885],
					[63.638366884118213, 5.421805520074015],
					[63.63836684071758, 5.4218054730044152],
					[63.638366735014721, 5.421805413578606],
					[63.638366671816911, 5.4218054013352734],
					[63.637791893621035, 5.4218858707314661],
					[63.637741900881551, 5.4199778346221104],
					[63.637972386363536, 5.4193917728012746],
					[63.637972391335722, 5.4193917560087108],
					[63.637972397647253, 5.419391720861003],
					[63.637972399882266, 5.4193917023929163],
					[63.638022400019672, 5.4163222032551532],
					[63.638022370344586, 5.4163221302135991],
					[63.638022268112643, 5.416322029956552],
					[63.638022194609924, 5.4163220008397328],
					[63.636850203741943, 5.4163525940679822],
					[63.636861299407308, 5.4158333306983257],
					[63.63704459168752, 5.4152000568372909],
					[63.637044594918201, 5.4152000422850008],
					[63.637044598843438, 5.4152000195624783],
					[63.637044600282792, 5.4152000052363274],
					[63.637094598875237, 5.4136472486763152],
					[63.637791879764542, 5.4121917859986572],
					[63.637791888217834, 5.4121917647236373],
					[63.637791897908947, 5.4121917210646124],
					[63.637791900092715, 5.4121917005817552],
					[63.637794600360472, 5.4062194006277853],
					[63.637794599061621, 5.4062193846265494],
					[63.6377945938778, 5.4062193569928176],
					[63.637794590787642, 5.4062193412182964],
					[63.637589090942789, 5.405552740838643],
					[63.637589058284661, 5.4055526924347808],
					[63.637588971182296, 5.4055526226331088],
					[63.637588916738096, 5.4055526012352777],
					[63.637011117049205, 5.4055026019697499],
					[63.637011059828993, 5.4055026132541579],
					[63.637010962367079, 5.4055026680247833],
					[63.637010921229766, 5.4055027116243721],
					[63.636763765162101, 5.4059998227442687],
					[63.63580574314463, 5.4061803689409293],
					[63.635619592853601, 5.4055138451108613],
					[63.635619551933054, 5.4055137896739502],
					[63.635619445025746, 5.4055137183427728],
					[63.635619378092969, 5.4055137005476679],
					[63.635397310982718, 5.4055386867502238],
					[63.63515017558759, 5.4050860034199593],
					[63.63515013233291, 5.4050859624236329],
					[63.635150028666878, 5.4050859129105069],
					[63.63514996910083, 5.4050859022662117],
					[63.634255807796478, 5.4052247659096304],
					[63.634289096632401, 5.4042778853021698],
					[63.634489040801654, 5.4040779407791133],
					[63.634489054778896, 5.404077924866046],
					[63.634489078103876, 5.4040778875646769],
					[63.634489088397679, 5.4040778680770529],
					[63.634719535938416, 5.4034474122837581],
					[63.635097183766632, 5.4034779991822166],
					[63.635097260388108, 5.4034779733144642],
					[63.635097368382922, 5.4034778727554933],
					[63.635097399756219, 5.4034777980643023],
					[63.635080800873105, 5.402050045919383],
					[63.635325180227284, 5.4015500884428072],
					[63.635325186887762, 5.4015500673935151],
					[63.635325196575607, 5.4015500237347611],
					[63.635325199603024, 5.4015500011252975],
					[63.635333499696976, 5.3990417009106775],
					[63.635333498346832, 5.3990416828970682],
					[63.635333492063985, 5.3990416473240872],
					[63.635333487131305, 5.399041629764751],
					[63.635100187062186, 5.3984166307346912],
					[63.635100139869508, 5.3984165761042062],
					[63.635100020622026, 5.3984165144477423],
					[63.635099950307925, 5.398416505180486],
					[63.634225127943104, 5.3986441604891606],
					[63.63405299843398, 5.3981555608849892],
					[63.634008499993698, 5.3974166872797884],
					[63.634008493912553, 5.3974166597649731],
					[63.63400847463577, 5.3974166076582897],
					[63.6340084623357, 5.3974165829528173],
					[63.633772402913216, 5.397091639263941],
					[63.633833500432559, 5.3957111093649814],
					[63.633833498980891, 5.3957110873244458],
					[63.633833491700521, 5.3957110478397281],
					[63.633833485770708, 5.3957110263675192],
					[63.633550202755337, 5.3949943695625455],
					[63.633658499924991, 5.3934056140553448],
					[63.633658498371823, 5.3934055879870764],
					[63.633658489046667, 5.3934055386606801],
					[63.633658481224103, 5.3934055133885721],
					[63.6334223813313, 5.3929082138599354],
					[63.633422334338945, 5.3929081672977386],
					[63.633422220665359, 5.3929081130412948],
					[63.633422154879725, 5.3929081052333352],
					[63.633144520993888, 5.3929719671628416],
					[63.632741913604598, 5.3919860967325057],
					[63.632972388067444, 5.3913556684985311],
					[63.632972392241953, 5.3913556558458318],
					[63.632972396957797, 5.3913556289817253],
					[63.63297239934105, 5.3913556165566403],
					[63.633000184761045, 5.3910112944310402],
					[63.634888779349822, 5.390953003020039],
					[63.63511920133346, 5.3914056505816736],
					[63.635152598877916, 5.393052755317953],
					[63.634722021420252, 5.3939249110241327],
					[63.634722012919077, 5.3939249302881036],
					[63.634722003182986, 5.393924971934358],
					[63.634722000106159, 5.3939249925300468],
					[63.634683099860936, 5.3948749926848354],
					[63.634683110976859, 5.3948750417860865],
					[63.634683158134692, 5.3948751307628928],
					[63.634683193230401, 5.3948751687380492],
					[63.634919243167737, 5.3950251376428069],
					[63.63516921875857, 5.3955639843056602],
					[63.635169262117024, 5.3955640293156639],
					[63.63516936777954, 5.3955640866230699],
					[63.635169430979282, 5.3955640988068021],
					[63.635885956711888, 5.3954530249881749],
					[63.636055408272533, 5.3960306558263538],
					[63.636055408323081, 5.3960306578405284],
					[63.636055409269233, 5.3960306597410383],
					[63.63605541021542, 5.3960306616415421],
					[63.6362276096526, 5.3965639619125287],
					[63.636227634946501, 5.3965640031561346],
					[63.636227703042593, 5.3965640672568211],
					[63.636227745895376, 5.3965640921280684],
					[63.636444346327544, 5.3966251924120012],
					[63.636444418028695, 5.3966251853342015],
					[63.636444538672421, 5.396625123551078],
					[63.63644458676989, 5.3966250709736059],
					[63.636694397581451, 5.3959533791503604],
					[63.63690540168605, 5.3965333395892205],
					[63.636930398107388, 5.3970999693271935],
					[63.63674701999534, 5.3976027093924914],
					[63.636480432173528, 5.3980137902656606],
					[63.636480420291356, 5.398013818040889],
					[63.63648040374261, 5.398013874696721],
					[63.636480399971646, 5.3980139034637178],
					[63.636488700306032, 5.3985778035708964],
					[63.636488701656241, 5.3985778215852305],
					[63.636488708834847, 5.3985778570459368],
					[63.636488713767569, 5.398577874605901],
					[63.636722000157796, 5.3991583377034926],
					[63.636708099677932, 5.4045250000090483],
					[63.636708122837305, 5.404525063757367],
					[63.636708205272257, 5.4045251624335995],
					[63.636708263652139, 5.4045251974749231],
					[63.637580564359602, 5.4046862965617466],
					[63.637580628795284, 5.404686286382522],
					[63.637580738745406, 5.4046862280060202],
					[63.637580784209391, 5.4046861777944475],
					[63.637811284050649, 5.404141777627304],
					[63.637811289916648, 5.4041417607188054],
					[63.637811297170515, 5.4041417274689199],
					[63.637811300349632, 5.4041417109006957],
					[63.637839089894598, 5.4035808179281437],
					[63.63873058270007, 5.4036557986728297],
					[63.638730639024452, 5.4036557874973585],
					[63.638730734795189, 5.4036557369725564],
					[63.638730775137127, 5.4036556975098122],
					[63.638980696719344, 5.4032030373878079],
					[63.639174943238274, 5.403261291971126],
					[63.639175017626769, 5.4032612845688064],
					[63.639175140909913, 5.4032612204527029],
					[63.639175189754134, 5.4032611617244886],
					[63.639366848281526, 5.402672390121591],
					[63.639963708976509, 5.402636312486357],
					[63.640046998384079, 5.4046332567427582],
					[63.639799821063335, 5.4051332110725401],
					[63.639799811866709, 5.4051332385079442],
					[63.639799801584665, 5.4051332943726047],
					[63.639799801445278, 5.4051333247029207],
					[63.639852594783719, 5.4055693680288961],
					[63.63954421823825, 5.4062360150267086],
					[63.639544210731884, 5.4062360382058836],
					[63.639544201938293, 5.40623608175623],
					[63.639544199805769, 5.40623610425523],
					[63.639602599697248, 5.40862220590693],
					[63.639602602887884, 5.4086222257116274],
					[63.639602611956064, 5.4086222649811591],
					[63.639602618729221, 5.408622284332691],
					[63.63983311872822, 5.4091167836544916],
					[63.639833152079355, 5.4091168238950669],
					[63.639833232558011, 5.4091168804046283],
					[63.639833280631521, 5.4091168985748146],
					[63.640069199177979, 5.409139082324919],
					[63.640058099931942, 5.4111721992892132],
					[63.640058102176233, 5.4111722171932266],
					[63.640058109402098, 5.4111722546761465],
					[63.640058114333343, 5.4111722722405284],
					[63.640285914099216, 5.4117528739606877],
					[63.640285979350892, 5.4117529324028837],
					[63.640286132325635, 5.4117529777377023],
					[63.640286219052427, 5.411752960714499],
					[63.640444518827955, 5.4116362613710924],
					[63.640444545043103, 5.4116362317864031],
					[63.640444583837422, 5.4116361662568622],
					[63.640444598207871, 5.4116361300856379],
					[63.640516894155574, 5.4111667537864934],
					[63.640886229801907, 5.410247412530504],
					[63.641244382589917, 5.4102779982159257],
					[63.641244451302903, 5.4102779794232729],
					[63.641244557169294, 5.4102779013675892],
					[63.641244596113978, 5.4102778418780719],
					[63.641352959079747, 5.4097669122370897],
					[63.641530476405428, 5.4097807906029987],
					[63.641802600781205, 5.4102806528842828],
					[63.641824799011069, 5.411322154253897],
					[63.641163720854173, 5.4126499110548858],
					[63.641163713296471, 5.4126499322191579],
					[63.641163702709619, 5.412649975996418],
					[63.641163699630162, 5.4126499965948458],
					[63.641155399941582, 5.4132110979771833],
					[63.641155401239693, 5.413211113980628],
					[63.641155406673732, 5.4132111516918275],
					[63.641155411554386, 5.4132111672426753],
					[63.64136649883114, 5.4137944330847443],
					[63.641355400093829, 5.4143555961644969],
					[63.641355403384267, 5.4143556200002552],
					[63.641355413547693, 5.4143556672193345],
					[63.641355422212001, 5.4143556903763992],
					[63.641585922392117, 5.4148056901556849],
					[63.641585945638184, 5.4148057215765002],
					[63.64158600257641, 5.4148057709732011],
					[63.641586037214481, 5.4148057908506129],
					[63.641944213941727, 5.4149251486217089],
					[63.641994194080759, 5.4154055518338335],
					[63.641810932069184, 5.4156915910761958],
					[63.64181092018363, 5.4156916188494124],
					[63.641810903627913, 5.4156916755058875],
					[63.641810899853439, 5.4156917042760497],
					[63.641822000173327, 5.4162556039113232],
					[63.641822002567778, 5.4162556278607639],
					[63.641822016313206, 5.4162556746290562],
					[63.641822024926974, 5.4162556957724597],
					[63.642072003595878, 5.4167111592578596],
					[63.642119200884196, 5.4172778167206239],
					[63.642119221099861, 5.4172778707573297],
					[63.642119288793921, 5.4172779551803742],
					[63.642119334581508, 5.4172779898220504],
					[63.642280535279092, 5.4173334891225844],
					[63.642280606186347, 5.4173334862374851],
					[63.642280728881936, 5.417333434375827],
					[63.642280780670191, 5.4173333853993135],
					[63.642733444037191, 5.4163751643464471],
					[63.643152602680843, 5.4162391745279015],
					[63.643191500182915, 5.4197917026216809],
					[63.643191523285459, 5.4197917643823086],
					[63.64319160476439, 5.4197918612233327],
					[63.643191662245101, 5.4197918964166867],
					[63.64400556195038, 5.4199473959833027],
					[63.644005627333172, 5.4199473877377464],
					[63.644005738185321, 5.4199473292932066],
					[63.644005782809259, 5.4199472812219627],
					[63.644769517842519, 5.4182391250730992],
					[63.645186063303093, 5.4183168976784835],
					[63.645186125948548, 5.4183168877529129],
					[63.645186234213497, 5.4183168336682233],
					[63.645186278937338, 5.4183167896221329],
					[63.645411278850275, 5.4178611890069934],
					[63.64541128725417, 5.4178611657129698],
					[63.645411297792329, 5.4178611199161075],
					[63.645411299876351, 5.4178610953983641],
					[63.645391901321936, 5.4167778592760687],
					[63.645636201807406, 5.4164085083460414],
					[63.645891557205694, 5.4164279905163832],
					[63.64602481300269, 5.4167861701265254],
					[63.646024846452327, 5.4167862144145644],
					[63.646024930711732, 5.4167862785652003],
					[63.64602498331287, 5.4167862982016484],
					[63.647691682880101, 5.4169279990038754],
					[63.647691746320568, 5.4169279849310241],
					[63.647691848859182, 5.4169279174090628],
					[63.647691888903246, 5.4169278658619282],
					[63.64783908870519, 5.4165083664933],
					[63.647839092781332, 5.4165083498074358],
					[63.647839099041825, 5.4165083126317741],
					[63.647839100380764, 5.4165082942700957],
					[63.647828000710071, 5.4161167350596999],
					[63.648094586157619, 5.4154472734785424],
					[63.648094591179579, 5.4154472586943507],
					[63.648094596694975, 5.4154472276764487],
					[63.64809459987535, 5.4154472111034169],
					[63.648125200169126, 5.4149306120434275],
					[63.648125171338577, 5.4149305368494884],
					[63.64812506716347, 5.4149304307439694],
					[63.648124991818925, 5.4149303998323841],
					[63.647430804408309, 5.4149580912892645],
					[63.647441899757943, 5.4143083028742103],
					[63.6474418963166, 5.414308272988289],
					[63.647441877790513, 5.4143082146873605],
					[63.647441865442978, 5.4143081879479542],
					[63.646683504434279, 5.4132055439482212],
					[63.646736298379082, 5.412430642219511],
					[63.646925187180884, 5.4119278691583634],
					[63.646925192202474, 5.4119278543741762],
					[63.646925197616461, 5.4119278193269418],
					[63.646925199900778, 5.4119278028674183],
					[63.646986299799984, 5.4058278406096782],
					[63.647294584732606, 5.4050750762053204],
					[63.647294591393532, 5.4050750551478801],
					[63.647294598395433, 5.4050750118120812],
					[63.647294599682503, 5.4050749914352698],
					[63.647191900723136, 5.4028583146487303],
					[63.647475197360428, 5.4013194363009323],
					[63.647475182058358, 5.4013193634493666],
					[63.647475107278062, 5.4013192476040839],
					[63.647475046008594, 5.4013192048374563],
					[63.646822279133353, 5.4011637139641087],
					[63.646372374404123, 5.4008804738781713],
					[63.646116895619095, 5.3998666582915487],
					[63.645928003654141, 5.3987639085266066],
					[63.646197376038018, 5.3976973200431191],
					[63.646747316093368, 5.3973001638021278],
					[63.647436108951148, 5.3968141389908091],
					[63.648110910924068, 5.3972168193664993],
					[63.648405399858163, 5.4001111102990267],
					[63.648396999746119, 5.4019888982195878],
					[63.648397008917108, 5.4019889415291402],
					[63.648397045915992, 5.4019890197186982],
					[63.648397072848297, 5.4019890547122591],
					[63.648933105422003, 5.4024306999372387],
					[63.649074799600037, 5.4048667123048704],
					[63.649074800596537, 5.4048667162216457],
					[63.649074801744284, 5.4048667261837915],
					[63.649074802791212, 5.4048667321157025],
					[63.649485902844475, 5.4073750320463265],
					[63.649485938541723, 5.4073750942381027],
					[63.649486044854903, 5.4073751778138233],
					[63.649486113679501, 5.4073751994245445],
					[63.650438855398221, 5.4073085046406355],
					[63.65149990413461, 5.4078918752582732],
					[63.651499997634062, 5.4078918775748805],
					[63.651500144849443, 5.4078918003118543],
					[63.651500198666184, 5.4078917247628047],
					[63.651739099327784, 5.4058861241453595],
					[63.651739096679485, 5.4058860901119594],
					[63.651739084318606, 5.4058860269832465],
					[63.651739071868704, 5.4058859962129278],
					[63.650519620723642, 5.4038527801654492],
					[63.651258480286707, 5.4018861882927078],
					[63.651983470335679, 5.4007084049377196],
					[63.651983482268768, 5.4007083791635484],
					[63.651983496181934, 5.4007083248341692],
					[63.651983499953339, 5.4007082960517421],
					[63.65193079958442, 5.3970444178808341],
					[63.652177986459392, 5.3957778924649604],
					[63.652666649889767, 5.3952281571737268],
					[63.653060896932409, 5.3960500390217403],
					[63.652866500103478, 5.3993499878760147],
					[63.652866503598446, 5.399350019781088],
					[63.652866520389843, 5.3993500803257577],
					[63.652866532790632, 5.399350109079001],
					[63.653313733413, 5.4000307092704682],
					[63.653313837824776, 5.4000307526348399],
					[63.653314020130111, 5.4000307133317289],
					[63.653314098023685, 5.4000306306642267],
					[63.653722365415064, 5.3973946439788882],
					[63.654374805903643, 5.3975473608773585],
					[63.654471999602933, 5.3999306077486784],
					[63.654472025400949, 5.3999306691762037],
					[63.654472105946901, 5.3999307640954362],
					[63.654472164277387, 5.399930797132579],
					[63.654969198732282, 5.4000223668678684],
					[63.654963699632198, 5.4017055986790457],
					[63.654963703971838, 5.4017056284576626],
					[63.654963720661343, 5.4017056849771192],
					[63.654963733906811, 5.401705711604377],
					[63.656094234222508, 5.4033834127620652],
					[63.656094261848359, 5.4033834395902041],
					[63.656094325804148, 5.4033834820331119],
					[63.656094362083678, 5.4033834956322444],
					[63.656977665303415, 5.403555777062806],
					[63.657463721037445, 5.4045389898232008],
					[63.657463760709817, 5.4045390313038979],
					[63.657463860049084, 5.4045390874660715],
					[63.657463917823797, 5.4045390983431432],
					[63.658869418243263, 5.4044112983213148],
					[63.658869461927743, 5.4044112846957626],
					[63.658869538889945, 5.4044112365194099],
					[63.658869571272, 5.4044112020821116],
					[63.659664071102782, 5.4030918027304011],
					[63.659664078810309, 5.403091787597269],
					[63.659664090642821, 5.4030917577852913],
					[63.659664095613039, 5.4030917409769765],
					[63.65988906303442, 5.4020223969020371],
					[63.660341519894203, 5.4020113039050166],
					[63.660497000603023, 5.4035583195377912],
					[63.66049704098198, 5.4035583892443668],
					[63.660497159091946, 5.4035584774084917],
					[63.66049723682297, 5.4035584958660587],
					[63.660861136622749, 5.4034890959019162],
					[63.660861195078894, 5.4034890621998999],
					[63.660861276254202, 5.4034889669692667],
					[63.660861299868969, 5.4034889053271185],
					[63.660916897674362, 5.4015639834060831],
					[63.661358407076087, 5.4011084762392194],
					[63.661883356697999, 5.400952992807154],
					[63.661883397392295, 5.4009529674195038],
					[63.661883463252188, 5.4009529064799819],
					[63.661883489212435, 5.4009528667824389],
					[63.662239088880021, 5.3999444675809727],
					[63.662239093004693, 5.3999444529003142],
					[63.662239097620976, 5.3999444219776374],
					[63.662239099954355, 5.3999444075243295],
					[63.662364098703648, 5.3964917231812635],
					[63.662561268198033, 5.3955168750225404],
					[63.663177830992836, 5.3954195984657192],
					[63.663177878952268, 5.3954195761905677],
					[63.663177957297918, 5.3954195116258141],
					[63.6631779876842, 5.3954194693362023],
					[63.664055631218353, 5.3930198984073696],
					[63.664585921781345, 5.3940556912884654],
					[63.664585931142263, 5.3940557062793095],
					[63.664585951604806, 5.3940557340170772],
					[63.664585962706433, 5.394055746763982],
					[63.664988758940254, 5.3944334419218878],
					[63.66549973818173, 5.3949722144735768],
					[63.66475814103039, 5.3959498781373254],
					[63.664758125616878, 5.3959499084129252],
					[63.664758104641784, 5.3959499677121672],
					[63.664758100076909, 5.3959500006544374],
					[63.664760899683706, 5.3970389011067539],
					[63.664760925634667, 5.3970389686022484],
					[63.664761017926885, 5.3970390661000254],
					[63.664761083423038, 5.3970390982322991],
					[63.665533283312847, 5.3971057979677939],
					[63.665533347596032, 5.3971057817105583],
					[63.665533451865137, 5.397105711828992],
					[63.665533491800559, 5.3971056561883906],
					[63.66632798614588, 5.3944500765004353],
					[63.667125164424881, 5.3929307172537246],
					[63.667683112058441, 5.3923365917974451],
					[63.667722000380124, 5.3939222046830997],
					[63.667722018864033, 5.3939222609952795],
					[63.667722084982813, 5.3939223537298995],
					[63.667722131671489, 5.3939223882498126],
					[63.668263748840886, 5.3941168584786885],
					[63.669310898880006, 5.3966306382578608],
					[63.669302600127217, 5.3971249975749425],
					[63.669302624236408, 5.3971250632922523],
					[63.66930270941409, 5.3971251637323814],
					[63.669302771378256, 5.3971251983413993],
					[63.670097000555856, 5.3972418724394844],
					[63.67011089970029, 5.400363901869957],
					[63.6701109008484, 5.4003639118392712],
					[63.670110903144646, 5.4003639317778918],
					[63.67011090429277, 5.4003639417472016],
					[63.670491503548895, 5.4021278418043561],
					[63.670491518787557, 5.4021278762854017],
					[63.670491560807136, 5.4021279397368369],
					[63.670491588382795, 5.4021279645603162],
					[63.671324888006431, 5.4026945640879331],
					[63.671324909905401, 5.4026945774943078],
					[63.671324957929095, 5.4026945936554167],
					[63.671324983208734, 5.4026945985404637],
					[63.671980583092868, 5.4027501994997182],
					[63.671980643844648, 5.4027501857223932],
					[63.6719807446853, 5.4027501223496168],
					[63.671980784824719, 5.4027500747709114],
					[63.673047385296293, 5.400116774237568],
					[63.673047391855334, 5.4001167491260711],
					[63.673047397757834, 5.400116697796058],
					[63.673047397996939, 5.4001166714637936],
					[63.672841897824995, 5.3986610717150532],
					[63.672841891844953, 5.3986610481961552],
					[63.672841874612125, 5.3986610058745406],
					[63.672841863308832, 5.3986609850550336],
					[63.671689062701503, 5.3970304848505775],
					[63.671689013225539, 5.3970304466289365],
					[63.671688900008355, 5.3970304104379023],
					[63.671688837112185, 5.397030410337976],
					[63.671097387211027, 5.3972275274844064],
					[63.671011302866297, 5.3953889580231067],
					[63.672013979359761, 5.3939391374550514],
					[63.672602736348715, 5.3941362906543109],
					[63.672602814369327, 5.3941362847777645],
					[63.67260294117915, 5.3941362180696872],
					[63.672602990914591, 5.394136159140988],
					[63.673225178788471, 5.3921583983102108],
					[63.674014051869136, 5.3912362310267783],
					[63.6740140716078, 5.3912361941180107],
					[63.674014094862663, 5.3912361183188757],
					[63.674014099223868, 5.3912360772976671],
					[63.673814099023659, 5.3894360788964066],
					[63.673814097925451, 5.3894360709431197],
					[63.67381409388706, 5.3894360532478025],
					[63.673814091893234, 5.3894360454085595],
					[63.672808491915696, 5.38588604403754],
					[63.672808470251759, 5.3858860043114625],
					[63.672808409315735, 5.3858859392432779],
					[63.672808370939279, 5.3858859137870168],
					[63.671791896979464, 5.3855026604832616],
					[63.671705801443366, 5.3811389481138834],
					[63.67205842437518, 5.3804751948863023],
					[63.672783310816442, 5.380436300614063],
					[63.6727833774288, 5.3804362698541945],
					[63.672783472022232, 5.3804361728018693],
					[63.672783500003327, 5.3804361065094071],
					[63.672836295795825, 5.3785584481282394],
					[63.673452859693377, 5.3783640908321892],
					[63.673452883671615, 5.3783640796759347],
					[63.673452927893223, 5.378364051770709],
					[63.673452948136585, 5.3783640350217388],
					[63.674377591127197, 5.3773533235316666],
					[63.674344200113183, 5.3793805969543129],
					[63.674344221442006, 5.3793806589772784],
					[63.674344295833706, 5.379380758736839],
					[63.674344350637043, 5.3793807942278304],
					[63.675497151425837, 5.3796751942157366],
					[63.675497231929242, 5.3796751798877072],
					[63.675497355537132, 5.3796750932817092],
					[63.67549739874319, 5.3796750250376508],
					[63.676002996557564, 5.3754583335341239],
					[63.676466856673308, 5.3733669144965752],
					[63.677713788872062, 5.3734723900097707],
					[63.67800263485055, 5.3738918128844126],
					[63.67800266158617, 5.37389183981829],
					[63.678002722812607, 5.3738918805522937],
					[63.678002758249946, 5.3738918962549],
					[63.678749957773441, 5.3740529960715193],
					[63.678750037329685, 5.3740529798230137],
					[63.678750157402398, 5.3740528956577078],
					[63.67875019786802, 5.3740528257237266],
					[63.678930796721851, 5.3726833360224404],
					[63.679175194341191, 5.371663947321494],
					[63.679175196722589, 5.3716639348744515],
					[63.679175198849435, 5.3716639123415],
					[63.67917520033523, 5.3716639000091071],
					[63.679177999909534, 5.3701278186520307],
					[63.679477996003016, 5.3684194415201461],
					[63.679794580401406, 5.3671084059270804],
					[63.680372087597235, 5.3665642811764691],
					[63.680583105181078, 5.3674222461716843],
					[63.680985905168377, 5.3691889435262166],
					[63.680985908160821, 5.3691889552858312],
					[63.680985915936873, 5.3691889785756057],
					[63.680985920720516, 5.3691889901057444],
					[63.682308112041603, 5.3718250716055973],
					[63.682713694637641, 5.3732972078465773],
					[63.682488704420969, 5.374466661051188],
					[63.682488701988518, 5.3744666714820424],
					[63.682488700604225, 5.3744666878505827],
					[63.682488699912071, 5.3744666960348635],
					[63.682474799618859, 5.3752082955976155],
					[63.682474800921305, 5.3752083116224645],
					[63.682474805317405, 5.3752083434430178],
					[63.682474809255808, 5.375208357106783],
					[63.68295260901742, 5.3767833578799102],
					[63.682952630683893, 5.3767833976129236],
					[63.682952691623967, 5.3767834626840019],
					[63.68295272995114, 5.3767834861191313],
					[63.684327605795012, 5.3772945415394897],
					[63.684410899693006, 5.3792333088081259],
					[63.684410903885031, 5.3792333325612249],
					[63.684410917642772, 5.379233379380806],
					[63.684410927157657, 5.3792334004297064],
					[63.684897000172256, 5.3800667545837593],
					[63.684899799982695, 5.3824943992309731],
					[63.684899823152286, 5.3824944630680998],
					[63.684899905653253, 5.3824945638607655],
					[63.684899964883172, 5.3824945967810631],
					[63.685944365464891, 5.3826807976733857],
					[63.685944435170434, 5.3826807827020353],
					[63.685944549334145, 5.3826807134749757],
					[63.685944591899634, 5.3826806554125648],
					[63.686541892290386, 5.3806500565992499],
					[63.686541896159071, 5.3806500318145218],
					[63.686541896680772, 5.3806499811425601],
					[63.686541895124982, 5.3806499550265388],
					[63.686333497551352, 5.3797416689606994],
					[63.686216898805448, 5.3784443826028463],
					[63.686216894714875, 5.3784443628837568],
					[63.686216882900545, 5.3784443218857634],
					[63.686216875227572, 5.3784443026245583],
					[63.6849391219911, 5.3761582865853663],
					[63.685672385423551, 5.3743861753114688],
					[63.6856723923649, 5.374386129891791],
					[63.685672384702215, 5.3743860397851089],
					[63.685672371044646, 5.3743859970011743],
					[63.685161301094809, 5.3735499447714483],
					[63.685222399607952, 5.3698889038992199],
					[63.685222347009642, 5.3698888134793226],
					[63.68522218857774, 5.3698887305455898],
					[63.68522208538004, 5.3698887356699556],
					[63.68421408501063, 5.3705968257686472],
					[63.684130801992922, 5.3685111341299843],
					[63.68451117057144, 5.3675531644955834],
					[63.685383155361265, 5.3684668373329956],
					[63.685383181049431, 5.3684668583304633],
					[63.685383238389285, 5.368466887416222],
					[63.685383269196265, 5.3684668976369307],
					[63.686036069115524, 5.3685695978521428],
					[63.686036137028189, 5.3685695830756535],
					[63.686036246757709, 5.3685695163833209],
					[63.686036290314853, 5.3685694622202673],
					[63.686255761875671, 5.3678945483705904],
					[63.686825079701975, 5.3676473839874888],
					[63.686825119341329, 5.3676473525904536],
					[63.686825177613635, 5.3676472762953997],
					[63.686825197142149, 5.3676472312825725],
					[63.687336291790949, 5.36446946394911],
					[63.687802916263259, 5.3635669094662513],
					[63.688366682779908, 5.3636140991208254],
					[63.688366748849752, 5.3636140825431609],
					[63.688366853050184, 5.3636140104622179],
					[63.688366892076402, 5.363613954843963],
					[63.688752948320015, 5.3622585125564637],
					[63.689072032029856, 5.3622834864346069],
					[63.689216504098923, 5.363038937314573],
					[63.689216578887184, 5.363039016788286],
					[63.689216756951872, 5.3630390587105374],
					[63.68921685933271, 5.3630390212740515],
					[63.690027958962276, 5.3619695209890823],
					[63.690027970196589, 5.3619695033504691],
					[63.690027988085227, 5.3619694646124563],
					[63.690027994790533, 5.361969445530991],
					[63.690325098692831, 5.3607060540933258],
					[63.691144241675062, 5.3617640220422969],
					[63.691144272890853, 5.3617640484016649],
					[63.69114434397212, 5.3617640878620971],
					[63.691144383786515, 5.3617640989451392],
					[63.69155278434112, 5.3617973997253978],
					[63.691552859162407, 5.3617973739189972],
					[63.691552967176143, 5.3617972750132958],
					[63.6915530003686, 5.361797201913955],
					[63.69170579984938, 5.3483833017654225],
					[63.691705769243974, 5.3483832287749911],
					[63.691705664278942, 5.348383128926999],
					[63.691705588972489, 5.3483831001668571],
					[63.690977788762233, 5.348421999590073],
					[63.690977735386191, 5.3484220206424951],
					[63.690977645420539, 5.3484220869029562],
					[63.690977610673372, 5.3484221338980689],
					[63.690408160349271, 5.3500608946725894],
					[63.689161358031122, 5.350005411551682],
					[63.689936293153536, 5.3471472522675336],
					[63.689936297016409, 5.3471472274754328],
					[63.689936296579432, 5.3471471748947534],
					[63.689936294173251, 5.3471471509110069],
					[63.688972429063163, 5.343450083839997],
					[63.689730676233765, 5.3429195908909373],
					[63.690161128964284, 5.3428557982562621],
					[63.690161183081081, 5.3428557710247224],
					[63.690161265622947, 5.3428556955840927],
					[63.690161294840912, 5.3428556432236061],
					[63.690577986976201, 5.3410083773666939],
					[63.691114062257803, 5.3401612160463925],
					[63.691647355020301, 5.3395112264482805],
					[63.691647376712957, 5.339511160881445],
					[63.691647374270175, 5.3395110295959212],
					[63.691647346552458, 5.3395109643401675],
					[63.691202973838337, 5.3390276945423327],
					[63.690941888181726, 5.3383110323714371],
					[63.690941876818378, 5.3383110095452722],
					[63.690941850663613, 5.3383109704098102],
					[63.690941835820752, 5.338310952082578],
					[63.690650201986664, 5.3380416135931181],
					[63.690719599787222, 5.3350250060313984],
					[63.690719598274583, 5.3350249819322002],
					[63.690719589083251, 5.3350249385807347],
					[63.690719582248647, 5.3350249171946684],
					[63.689186282117788, 5.3316777164638331],
					[63.689186237792676, 5.3316776695671733],
					[63.689186132106165, 5.3316776123958096],
					[63.6891860698492, 5.3316776022370531],
					[63.688136263599603, 5.3318386737470504],
					[63.687869600087915, 5.3302832837750911],
					[63.687866899824826, 5.3287944005013799],
					[63.687866888737503, 5.3287943533530235],
					[63.687866846222519, 5.3287942718139147],
					[63.687866813847819, 5.328794235521527],
					[63.687464061804278, 5.3285137686204358],
					[63.687139084405402, 5.3277443228396395],
					[63.687139033655576, 5.3277442707120395],
					[63.687138912691601, 5.3277442135149462],
					[63.687138840686316, 5.3277442086775713],
					[63.686886040718079, 5.3278220097069733],
					[63.686886002621229, 5.3278220308392044],
					[63.686885940273122, 5.3278220875035798],
					[63.686885915126311, 5.3278221231518197],
					[63.686269247006344, 5.3293081482444133],
					[63.685975139039925, 5.3294330239121743],
					[63.685802994420875, 5.3287249521323794],
					[63.685802954315058, 5.3287248945778165],
					[63.685802846372773, 5.3287248194979835],
					[63.685802776693627, 5.3287248001872252],
					[63.685302776955965, 5.3287831014676508],
					[63.685302728060762, 5.3287831219722284],
					[63.685302646905505, 5.3287831810679842],
					[63.685302614748451, 5.3287832236941819],
					[63.684924814817194, 5.329691622741243],
					[63.684924809804272, 5.3296916375597432],
					[63.684924803412208, 5.3296916687502325],
					[63.684924801137477, 5.3296916852382283],
					[63.684866501003526, 5.3304805857901911],
					[63.684866504668356, 5.3304806237746538],
					[63.684866528066713, 5.3304806956384141],
					[63.684866546904594, 5.3304807296337398],
					[63.68531374730221, 5.3310140282056953],
					[63.685313770308916, 5.3310140495169032],
					[63.685313823229521, 5.3310140811239508],
					[63.685313851403727, 5.3310140936692694],
					[63.686488762923631, 5.3313084721822648],
					[63.686752593966652, 5.3318250373476168],
					[63.686702606318789, 5.3322666384619923],
					[63.68618314903992, 5.3332137595626108],
					[63.685288837296874, 5.3337831035164909],
					[63.684924988760002, 5.3338026000726249],
					[63.68492492916868, 5.3338026239762746],
					[63.684924836039151, 5.3338027068716993],
					[63.684924804189208, 5.3338027615967176],
					[63.684577604244744, 5.3356082626939552],
					[63.684577602556431, 5.3356082669605707],
					[63.684577601866529, 5.3356082751463108],
					[63.684577601073784, 5.3356082792971096],
					[63.68447200065264, 5.3366388765243533],
					[63.684344202312317, 5.33757217308594],
					[63.684344200880879, 5.3375721874397506],
					[63.684344201703013, 5.3375722197193216],
					[63.684344203009658, 5.3375722357433597],
					[63.684549803322533, 5.3387278354127359],
					[63.684549804372217, 5.3387278413494768],
					[63.684549807367105, 5.3387278531072671],
					[63.684549809312344, 5.3387278589283111],
					[63.68489420907536, 5.3398444598331052],
					[63.684894298895294, 5.3398445291967649],
					[63.684894486942447, 5.3398445393166138],
					[63.684894584222725, 5.3398444781709733],
					[63.685158413958845, 5.3392224434606455],
					[63.685608089389227, 5.3393584444354145],
					[63.685491502760129, 5.3410332475998885],
					[63.684999821223727, 5.3420332111563917],
					[63.684999812039592, 5.3420332386560601],
					[63.684999801834145, 5.3420332966498885],
					[63.684999801657071, 5.3420333250109096],
					[63.685177596243975, 5.3434360839349591],
					[63.684794208008846, 5.3447416432732764],
					[63.684794207267096, 5.344741649441362],
					[63.684794203941216, 5.344741659991084],
					[63.684794203148229, 5.3447416641416448],
					[63.684644214071376, 5.3455693065365315],
					[63.684297115678376, 5.3459358968052548],
					[63.683319405787863, 5.3459053991968633],
					[63.683319353102647, 5.3459054120627227],
					[63.683319261934848, 5.3459054663234458],
					[63.683319224296611, 5.3459055055854012],
					[63.683088779312882, 5.3463331036888464],
					[63.682658500233501, 5.3463469934339098],
					[63.682661300146876, 5.3446639211357629],
					[63.682808494580911, 5.343983342915732],
					[63.682808496320767, 5.3439833406672719],
					[63.682808497062446, 5.3439833344995877],
					[63.682808497011152, 5.3439833324822139],
					[63.683086293746513, 5.3422722558394833],
					[63.683700184673711, 5.3407861753992538],
					[63.68370018884351, 5.3407861627164124],
					[63.68370019628761, 5.3407861374663668],
					[63.68370019861495, 5.3407861229973692],
					[63.683966899065432, 5.3384806233776798],
					[63.683966898757213, 5.3384806112729919],
					[63.683966899036314, 5.338480586947898],
					[63.683966897832534, 5.3384805749589308],
					[63.683783498336069, 5.3370777745077467],
					[63.683783498284683, 5.3370777724902991],
					[63.683783498233296, 5.3370777704728702],
					[63.683783498181896, 5.3370777684554422],
					[63.683577998286438, 5.3357749693194174],
					[63.683577929752573, 5.335774889122801],
					[63.683577761223717, 5.3357748340009135],
					[63.683577660281792, 5.3357748571740293],
					[63.683113833330708, 5.3362303853958615],
					[63.682550252665976, 5.3361359409016789],
					[63.683511292575901, 5.3326972545762557],
					[63.683511294902438, 5.3326972401067314],
					[63.683511298762816, 5.3326972153183734],
					[63.683511300193786, 5.3326972009647555],
					[63.683533500071789, 5.3300250011350236],
					[63.683533488089353, 5.3300249541093105],
					[63.683533442785468, 5.3300248688937435],
					[63.683533410411073, 5.330024832605333],
					[63.682319509670343, 5.3292304324131443],
					[63.6823194625347, 5.3292304162550392],
					[63.682319367090351, 5.3292304083785558],
					[63.682319317886062, 5.3292304167762019],
					[63.681474984584078, 5.329608088418107],
					[63.680750054566978, 5.3293998083104466],
					[63.680749989932139, 5.3293998106103242],
					[63.680749876124402, 5.3293998577325281],
					[63.680749826899934, 5.329399900537708],
					[63.679488777249468, 5.3315748148263724],
					[63.678783485907999, 5.3316636754598683],
					[63.678636300273226, 5.3294249865216319],
					[63.678636256122076, 5.3294249112907908],
					[63.67863612627476, 5.329424822873535],
					[63.678636038787431, 5.3294248099191073],
					[63.67802213926786, 5.3296220108881709],
					[63.678022090629902, 5.3296220414706763],
					[63.678022021837307, 5.3296221272786504],
					[63.678022000787074, 5.329622182620092],
					[63.6779415148509, 5.330505431119045],
					[63.676639097709085, 5.3307469605153228],
					[63.676622399850274, 5.3290611461950483],
					[63.678336278425498, 5.3256861919509788],
					[63.678336285969998, 5.3256861707359127],
					[63.678336296632871, 5.3256861309032884],
					[63.678336299699616, 5.3256861102687054],
					[63.678502997289996, 5.3223306609331047],
					[63.679833471312847, 5.3201140033882117],
					[63.679833479803705, 5.3201139840713845],
					[63.679833493254883, 5.3201139479199719],
					[63.679833498111918, 5.320113927051155],
					[63.680241871180868, 5.3172752136664627],
					[63.681219383628459, 5.317352999842023],
					[63.681219452327333, 5.3173529807951159],
					[63.681219557244276, 5.3173529023959318],
					[63.681219595253424, 5.3173528428108892],
					[63.681486275354388, 5.3161362337784279],
					[63.682700093171128, 5.3154945764722967],
					[63.682700136966282, 5.3154945323214244],
					[63.682700188166137, 5.3154944264869419],
					[63.682700197361925, 5.3154943645704522],
					[63.68188910735168, 5.3108722222539475],
					[63.682222385347394, 5.3100556750495826],
					[63.682222390306492, 5.3100556582118319],
					[63.682222398381832, 5.310055622752313],
					[63.682222399707001, 5.3100556043637654],
					[63.682358500399737, 5.3043222052038752],
					[63.682358498038305, 5.304322183247641],
					[63.682358490732625, 5.3043221437200465],
					[63.682358484789056, 5.3043221222309809],
					[63.680936295969552, 5.3008888491281478],
					[63.680758499277012, 5.2992888788617147],
					[63.680758493280827, 5.2992888553577986],
					[63.680758477758218, 5.2992888108348675],
					[63.68075846733629, 5.2992887899327927],
					[63.680447369449013, 5.298816593017313],
					[63.679805770305016, 5.297774895502334],
					[63.679805760090638, 5.2977748826697395],
					[63.679805738766341, 5.2977748571215768],
					[63.679805726760904, 5.2977748445229542],
					[63.679269533742129, 5.2973359506932498],
					[63.678772369716391, 5.2968498851599515],
					[63.678230812563164, 5.2955166915802767],
					[63.678416889705524, 5.2949389619298657],
					[63.678416893819275, 5.2949389472258312],
					[63.678416899360286, 5.2949389181689934],
					[63.678416899891985, 5.2949389039332422],
					[63.678541899412124, 5.2871750373579998],
					[63.678827986985674, 5.286405669517559],
					[63.678827991941979, 5.2864056526777965],
					[63.678827998220449, 5.2864056174506837],
					[63.678828000438131, 5.2864055989459748],
					[63.678811300123016, 5.2832832991166381],
					[63.678811294811162, 5.2832832674325259],
					[63.678811276232167, 5.2832832091551598],
					[63.678811261173948, 5.2832831827967688],
					[63.678541900804511, 5.2829166351985899],
					[63.678552999617679, 5.2822722034305665],
					[63.678552994201326, 5.2822721677130504],
					[63.678552969040375, 5.2822720981575548],
					[63.678552950295639, 5.2822720682359474],
					[63.677616901593865, 5.281208226105762],
					[63.677680799074267, 5.2786361272774611],
					[63.677872393716143, 5.2778611471990722],
					[63.677872396089349, 5.2778611347450033],
					[63.677872398992584, 5.2778611080551849],
					[63.677872399574767, 5.2778610958363217],
					[63.677844600161656, 5.2767193943791195],
					[63.677844596952816, 5.2767193745633056],
					[63.677844587848597, 5.2767193352846045],
					[63.677844581057705, 5.2767193159393555],
					[63.677558481264619, 5.2761027156442823],
					[63.677558445138281, 5.2761026738453749],
					[63.677558353759174, 5.2761026150213679],
					[63.67755830034961, 5.2761025997778237],
					[63.675041816204249, 5.2760969997072049],
					[63.674180773736495, 5.2745916006529088],
					[63.674180743191847, 5.2745915662210834],
					[63.674180669140306, 5.2745915172722118],
					[63.674180627424455, 5.274591502519784],
					[63.672455626994278, 5.2743554028148418],
					[63.67245557415945, 5.274355409758356],
					[63.672455479072013, 5.2743554505816244],
					[63.672455436819433, 5.2743554844613554],
					[63.670922037445919, 5.2765081844988737],
					[63.670922027117655, 5.2765082020412422],
					[63.670922010147699, 5.2765082406883179],
					[63.670922004401497, 5.2765082616753798],
					[63.670702604257237, 5.2776305619384081],
					[63.670702603413964, 5.2776305640723438],
					[63.670702603413964, 5.2776305640723438],
					[63.670474804926926, 5.2788999549671143],
					[63.669610948253975, 5.281899804083082],
					[63.668772395425997, 5.2819303933818338],
					[63.668683499708983, 5.278608295162039],
					[63.66868349934358, 5.2786082810484407],
					[63.668683494135216, 5.2786082534089465],
					[63.668683491083243, 5.2786082396479692],
					[63.66721409130227, 5.273986037965309],
					[63.667214061391689, 5.273985993344124],
					[63.667213983287567, 5.2739859267339488],
					[63.667213935041744, 5.2739859027288523],
					[63.66565003534442, 5.2737109038395023],
					[63.665649979770777, 5.2737109091191625],
					[63.665649881049148, 5.2737109483887279],
					[63.665649837005624, 5.2737109824963255],
					[63.663469291972717, 5.2767526065176842],
					[63.662149985689616, 5.2768442010106584],
					[63.662149948182659, 5.2768442099794788],
					[63.662149879011849, 5.276844245353641],
					[63.662149847347976, 5.2768442717589643],
					[63.661363746811659, 5.2777720719927288],
					[63.661363740751924, 5.2777720808782043],
					[63.661363727736955, 5.2777720987666781],
					[63.661363721729437, 5.2777721096678549],
					[63.659116563167089, 5.2821442292455405],
					[63.658330721920414, 5.2823497601028864],
					[63.657905786721138, 5.2812443288510158],
					[63.657905749544355, 5.2812442811446587],
					[63.657905657167461, 5.2812442184292019],
					[63.657905600967453, 5.2812441995065917],
					[63.656608395020015, 5.2812386993694691],
					[63.655886281120488, 5.2803527051377932],
					[63.654886291968666, 5.2768610455910308],
					[63.654886248208634, 5.2768609866265495],
					[63.654886132285505, 5.2768609169110059],
					[63.654886060070261, 5.2768609041446961],
					[63.65437216020517, 5.2769665037363431],
					[63.654372120220494, 5.2769665211155834],
					[63.654372053206096, 5.2769665703505497],
					[63.654372025333025, 5.2769666043390151],
					[63.653885925258635, 5.2778527029656779],
					[63.653885921833179, 5.2778527094811363],
					[63.653885915877872, 5.27785272239454],
					[63.653885913347942, 5.2778527287924977],
					[63.653377659420578, 5.2791748090779143],
					[63.652366797423859, 5.2792414929281932],
					[63.6498695787987, 5.2757777056603032],
					[63.648397389401055, 5.2716138362131897],
					[63.647450190426056, 5.2687249380287104],
					[63.647450189425903, 5.2687249341170403],
					[63.647450185634575, 5.2687249265292042],
					[63.647450183738876, 5.268724922735271],
					[63.646347385811119, 5.2661332242686703],
					[63.645175188422613, 5.2630499324629207],
					[63.644108489324665, 5.259980535377478],
					[63.642877989683491, 5.2563277363276795],
					[63.6428779877875, 5.256327732535202],
					[63.642877983943038, 5.2563277229358834],
					[63.642877981994552, 5.2563277171290448],
					[63.641525182374693, 5.2533138180919661],
					[63.641525140764131, 5.2533137730544812],
					[63.641525041264309, 5.2533137134244816],
					[63.641524980741167, 5.2533137012007911],
					[63.639499981122405, 5.2535109001641374],
					[63.639499923388506, 5.253510925972301],
					[63.639499834730572, 5.2535110063871588],
					[63.639499803806487, 5.253511060993878],
					[63.638813708240924, 5.2569110385926292],
					[63.637785917590655, 5.2591666178738796],
					[63.637785910160751, 5.2591666431036357],
					[63.637785901726744, 5.2591666987792287],
					[63.637785902408744, 5.2591667249610952],
					[63.639616479012929, 5.2734692504882608],
					[63.636511090063735, 5.2742803965906759],
					[63.63313063190278, 5.2737304032602879],
					[63.633130592395823, 5.273730404403068],
					[63.633130514747748, 5.2737304246943566],
					[63.633130479240876, 5.2737304414766655],
					[63.63255267920259, 5.2741720402292316],
					[63.632552666926671, 5.2741720519423216],
					[63.632552645904639, 5.2741720728848991],
					[63.632552636315282, 5.2741720842455049],
					[63.631580436107249, 5.275558184960504],
					[63.63158042841323, 5.2755582001125632],
					[63.631580413025254, 5.2755582304166913],
					[63.631580407174496, 5.2755582473474574],
					[63.63086650725613, 5.278169346765953],
					[63.630866506465011, 5.2781693509103835],
					[63.630866504778368, 5.2781693551721691],
					[63.630866503987221, 5.2781693593166246],
					[63.628910969388784, 5.2876107465895537],
					[63.628225121744613, 5.2870804428014955],
					[63.628225110738001, 5.2870804341393045],
					[63.628225086090254, 5.2870804191794702],
					[63.628225074292239, 5.2870804146611041],
					[63.627852874242883, 5.2869331147767857],
					[63.627852865183534, 5.2869331119206979],
					[63.627852847064766, 5.2869331062085081],
					[63.627852838005374, 5.2869331033524238],
					[63.627325112011384, 5.2868304173142562],
					[63.626752957576997, 5.286091577357614],
					[63.626752855976392, 5.2860915401390018],
					[63.626752679183568, 5.2860915834538877],
					[63.626752603939245, 5.2860916619741154],
					[63.626524837756712, 5.2872636865545308],
					[63.62520009387368, 5.2871581062450419],
					[63.624836247155187, 5.2867665633000334],
					[63.624836237991694, 5.2867665564179847],
					[63.624836219612583, 5.2867665406407607],
					[63.624836211344586, 5.2867665336416625],
					[63.624305711012006, 5.2864137339755413],
					[63.624305604871488, 5.2864137296706124],
					[63.624305449925295, 5.2864138186012752],
					[63.624305400224067, 5.2864139119539324],
					[63.624416495419979, 5.2882332235389677],
					[63.62334705634364, 5.2893415613410415],
					[63.623347029831059, 5.2893416132797482],
					[63.623347010148578, 5.2893417208817395],
					[63.623347016135135, 5.2893417786750057],
					[63.623552597034255, 5.289822235060309],
					[63.623491512230402, 5.2907498058072386],
					[63.623025179820218, 5.2907636952154409],
					[63.622925199135558, 5.2897721795465564],
					[63.622925195085223, 5.2897721618972575],
					[63.622925185193495, 5.2897721268325739],
					[63.622925178456569, 5.2897721095341135],
					[63.622675177904441, 5.2892860091550897],
					[63.622675092903378, 5.2892859536037298],
					[63.622674920233699, 5.2892859478782333],
					[63.622674830670022, 5.2892859939120367],
					[63.622458130678496, 5.2896304947186277],
					[63.622458118713389, 5.2896305184986296],
					[63.622458103894587, 5.2896305709280833],
					[63.62245810009329, 5.2896305976814944],
					[63.622446999909528, 5.2904582980067225],
					[63.62244700227275, 5.290458319915615],
					[63.622447012424438, 5.2904583650448371],
					[63.622447020160813, 5.2904583862522001],
					[63.622627600786686, 5.2908361474493484],
					[63.622658098068342, 5.2918221517041149],
					[63.622444224104456, 5.2922193045982686],
					[63.622444215669105, 5.2922193258967338],
					[63.622444204223484, 5.2922193698054505],
					[63.622444200317766, 5.2922193925325596],
					[63.622363702790757, 5.2941305197615227],
					[63.622013752194448, 5.2945165653933772],
					[63.622013733168217, 5.2945165941312284],
					[63.622013706965468, 5.2945166581404193],
					[63.62201369973706, 5.2945166913988198],
					[63.621930403630401, 5.2966831812848802],
					[63.620897101219292, 5.2972748255638749],
					[63.620897074820519, 5.2972748471807893],
					[63.620897032925107, 5.2972748950529027],
					[63.620897015637475, 5.2972749215414714],
					[63.620388716220425, 5.2984777223403814],
					[63.620388710366896, 5.2984777392597966],
					[63.620388702397698, 5.2984777786705033],
					[63.620388700178204, 5.2984777971361279],
					[63.620360900154857, 5.3000805978440582],
					[63.620360901465411, 5.3000806138302652],
					[63.620360905877639, 5.3000806455694391],
					[63.620360909874876, 5.3000806612057962],
					[63.620805410429895, 5.3014639617576487],
					[63.620805424640942, 5.3014639902024117],
					[63.620805462810168, 5.3014640417839312],
					[63.620805485872872, 5.3014640650372424],
					[63.622166497813076, 5.3024029049989156],
					[63.622138699622035, 5.3040055977398053],
					[63.622138699881177, 5.304005607804732],
					[63.622138702190526, 5.3040056277015974],
					[63.622138703345207, 5.3040056376500297],
					[63.622524803100724, 5.3060583363857399],
					[63.62282480191412, 5.3077389316577825],
					[63.622997000047, 5.3090361153990218],
					[63.623041486243949, 5.310938287594329],
					[63.621261259113133, 5.3085998788986721],
					[63.621261252637247, 5.3085998716613503],
					[63.621261239685488, 5.3085998571867243],
					[63.62126123320963, 5.3085998499493989],
					[63.620172332535276, 5.3076359493411234],
					[63.620172317000367, 5.3076359392422843],
					[63.620172285034968, 5.307635919160985],
					[63.620172267760701, 5.3076359113077416],
					[63.61914176834663, 5.3072609118998839],
					[63.619141715250528, 5.3072609087021911],
					[63.619141613313673, 5.3072609280074516],
					[63.619141565420222, 5.3072609524068097],
					[63.617822065384466, 5.3084665509767746],
					[63.617822045408012, 5.3084665778047002],
					[63.617822013566823, 5.3084666324262111],
					[63.617822003596672, 5.3084666640125482],
					[63.617363732926428, 5.3108692057002127],
					[63.616974992747757, 5.3108830999733581],
					[63.616974925254965, 5.3108831309474578],
					[63.616974828917058, 5.3108832282658076],
					[63.616974800123671, 5.3108832966226691],
					[63.616963702983256, 5.3116220095038411],
					[63.616297189681525, 5.311655400339081],
					[63.616297136258794, 5.3116554193880354],
					[63.616297047204533, 5.3116554854665345],
					[63.616297012416858, 5.3116555303672692],
					[63.61561662988661, 5.3135218083803037],
					[63.615022352823928, 5.312819271492395],
					[63.615022318912992, 5.3128192456035457],
					[63.615022243393085, 5.3128192089589961],
					[63.615022201835806, 5.3128192002157935],
					[63.614452979724227, 5.3128137029226883],
					[63.614386307396451, 5.3122000558930234],
					[63.614625161626172, 5.3118695169098471],
					[63.614625171629974, 5.3118694166718248],
					[63.614625099275955, 5.3118692584685299],
					[63.614625016022572, 5.3118692006194701],
					[63.613786115944457, 5.3117998001296201],
					[63.613786050827791, 5.3117998186747455],
					[63.613785945808623, 5.31179989287567],
					[63.613785906801674, 5.3117999484152412],
					[63.613469233976197, 5.3129970460808771],
					[63.612563832930334, 5.3133165109615232],
					[63.612563786241402, 5.3133165473025414],
					[63.612563719454911, 5.3133166407640822],
					[63.612563700252842, 5.3133166977684745],
					[63.612530400279155, 5.3152332963047595],
					[63.612530400692464, 5.3152333124034197],
					[63.612530405996878, 5.3152333440202559],
					[63.612530409096877, 5.3152333597706187],
					[63.612908109185959, 5.3164417593872244],
					[63.612908124341239, 5.3164417897280991],
					[63.612908164347921, 5.3164418430961664],
					[63.61290818830377, 5.316441866239396],
					[63.613719146837227, 5.3169889711600264],
					[63.612947185904375, 5.3186689522994524],
					[63.612072375853678, 5.3170499054857574],
					[63.612072374906468, 5.3170499035894911],
					[63.611150174932931, 5.31537490317546],
					[63.611150171144075, 5.3153748955908391],
					[63.61115016087966, 5.3153748807698644],
					[63.611150155299697, 5.3153748734174062],
					[63.610389055227515, 5.3144359739002649],
					[63.61038904969918, 5.3144359685603781],
					[63.610389040433574, 5.3144359576483753],
					[63.610389034009707, 5.3144359524245717],
					[63.608789034177548, 5.3129915510322423],
					[63.608789023172434, 5.3129915423656566],
					[63.608789000318382, 5.3129915271606976],
					[63.608788987573845, 5.3129915207384562],
					[63.60737228795368, 5.3122942206191386],
					[63.607372266201907, 5.3122942133471351],
					[63.607372219167864, 5.3122942012797125],
					[63.607372194884533, 5.3122942003920715],
					[63.605886423150295, 5.3123330917113263],
					[63.606822380270707, 5.3103944879316378],
					[63.606822386917827, 5.3103944668827445],
					[63.606822396629816, 5.3103944252498465],
					[63.606822399746427, 5.3103944066777302],
					[63.606886299297464, 5.3080861374222881],
					[63.607839088854547, 5.3053750654500575],
					[63.607839092125914, 5.3053750529126846],
					[63.60783909777313, 5.305375027954315],
					[63.607839100097145, 5.3053750135213491],
					[63.608230797939122, 5.299833340728318],
					[63.609283486395753, 5.2967306732260475],
					[63.611091881935813, 5.2927333827355625],
					[63.611091888580262, 5.2927333616794385],
					[63.611091898235117, 5.2927333180221812],
					[63.611091900349919, 5.2927332955378432],
					[63.611055800238091, 5.2910305968703124],
					[63.611055791138241, 5.2910305576785035],
					[63.611055758714116, 5.2910304851887284],
					[63.611055735441781, 5.2910304539029562],
					[63.610844592359442, 5.2908360040866587],
					[63.610639099780734, 5.2885166919985753],
					[63.610622400020524, 5.2828193984316476],
					[63.610622364853583, 5.2828193242916317],
					[63.610622249047104, 5.2828192243554994],
					[63.6106221694073, 5.2828192024665075],
					[63.610377993583363, 5.2828580665274112],
					[63.610244599735289, 5.2780999942059914],
					[63.610244599369992, 5.2780999801214028],
					[63.610244594214024, 5.2780999545505258],
					[63.610244591214375, 5.2780999428297628],
					[63.609914100772556, 5.2770082730283843],
					[63.610014100037546, 5.2724472040092891],
					[63.610014093723763, 5.2724471684968393],
					[63.610014068558996, 5.2724470991158761],
					[63.61001405070806, 5.2724470691540795],
					[63.609125154358345, 5.2714276724320852],
					[63.607897367847606, 5.2698470903588142],
					[63.60633627980355, 5.2669471121635336],
					[63.606128000977925, 5.2664610618935974],
					[63.606169599625133, 5.2651222056057678],
					[63.606169599310952, 5.2651221935351646],
					[63.606169596839216, 5.2651221676174664],
					[63.606169594681667, 5.2651221537703705],
					[63.605777995368221, 5.2634693549622584],
					[63.605355795678996, 5.2615555593419554],
					[63.60490019702074, 5.2591277634987357],
					[63.604900186331115, 5.259127732608107],
					[63.604900155258683, 5.2591276781578449],
					[63.604900135718971, 5.2591276524687132],
					[63.603886272057224, 5.2581915857781665],
					[63.60316129110867, 5.256169338216913],
					[63.602041897027263, 5.2521499608563813],
					[63.601572397899879, 5.2483527754313402],
					[63.601572341908948, 5.2483526960293281],
					[63.601572189100139, 5.2483526253635633],
					[63.601572093125107, 5.2483526319702589],
					[63.599649892534579, 5.2495665302427934],
					[63.599649877574166, 5.2495665423067015],
					[63.599649850444941, 5.2495665701027807],
					[63.599649837328066, 5.249566583941772],
					[63.598447057261055, 5.2512665550146496],
					[63.597022084687715, 5.2522637364205789],
					[63.597022074151951, 5.2522637458808665],
					[63.597022052237485, 5.252263766930521],
					[63.597022041701734, 5.2522637763907882],
					[63.595394330850247, 5.2543553636205651],
					[63.593883500373458, 5.2538109584233617],
					[63.593866899874619, 5.2476582997051375],
					[63.593866898558318, 5.247658283736401],
					[63.593866894187322, 5.2476582540460068],
					[63.593866891132663, 5.2476582403243635],
					[63.593283491222884, 5.245819339127328],
					[63.593283421218658, 5.245819273708654],
					[63.593283259189846, 5.2458192285053613],
					[63.593283164531449, 5.2458192510860924],
					[63.59224706534647, 5.2467609512510576],
					[63.592247043643255, 5.2467609803442281],
					[63.592247011983417, 5.2467610410162244],
					[63.59224700207939, 5.246761074605792],
					[63.591938712820387, 5.2491276865819074],
					[63.590710987393173, 5.2499720348068752],
					[63.59071097332815, 5.2499720467486028],
					[63.590710948780092, 5.2499720701599193],
					[63.590710938349645, 5.2499720836401478],
					[63.589777637688172, 5.2512637838760492],
					[63.58977763594973, 5.2512637861225979],
					[63.589777630734432, 5.2512637928622441],
					[63.589777628996004, 5.251263795108791],
					[63.58868592990067, 5.2530554947807184],
					[63.58806387473107, 5.2540329674013533],
					[63.587505783259481, 5.2533916065594548],
					[63.587147393807875, 5.2519110527473272],
					[63.587147367368026, 5.2519110037797621],
					[63.587147289044374, 5.2519109293692186],
					[63.587147239847098, 5.2519109035723917],
					[63.586466740125232, 5.2517720034520599],
					[63.586466733804279, 5.2517720022673871],
					[63.586466722110281, 5.2517720017904308],
					[63.586466715789307, 5.2517720006057633],
					[63.585780615732226, 5.251716501779919],
					[63.585780583389393, 5.2517165020055838],
					[63.58578052007207, 5.2517165204316649],
					[63.585780489835436, 5.2517165324832327],
					[63.58462787345767, 5.2524803117638852],
					[63.58396687699156, 5.2512332050033468],
					[63.5839668191567, 5.2512331581601508],
					[63.583966685942755, 5.2512331151949079],
					[63.583966610616244, 5.2512331210830379],
					[63.58308321107652, 5.2516748202194643],
					[63.583083177678283, 5.2516748488224545],
					[63.583083125419805, 5.2516749121818087],
					[63.583083105716639, 5.2516749490662287],
					[63.582519219771363, 5.2538498969000997],
					[63.58163045974014, 5.2547192560656342],
					[63.581630451890661, 5.2547192651662122],
					[63.581630437139708, 5.2547192852595348],
					[63.581630430185704, 5.2547192942422605],
					[63.580785994410533, 5.2560941894632176],
					[63.580589040718216, 5.2560776106062708],
					[63.580400185970767, 5.2555943268469019],
					[63.580400149734167, 5.2555942811930478],
					[63.580400059137901, 5.2555942184882047],
					[63.580400002934688, 5.255594199662923],
					[63.57973623277541, 5.255583101075433],
					[63.57952798440968, 5.2550971204081245],
					[63.579527938217517, 5.2550970720324761],
					[63.579527826129066, 5.2550970121585809],
					[63.579527760337818, 5.2550970046800476],
					[63.579258426950133, 5.2551525695786694],
					[63.579033486582965, 5.2545499298140435],
					[63.579033455824074, 5.2545498874763386],
					[63.57903337597331, 5.2545498233637646],
					[63.579033326881486, 5.2545498015889081],
					[63.578352827208548, 5.2544581026761712],
					[63.578352761046773, 5.2544581154149625],
					[63.578352654056616, 5.2544581819263749],
					[63.578352612227768, 5.2544582317972477],
					[63.578147058693844, 5.2550276034394381],
					[63.575022196406913, 5.2550915007956451],
					[63.575022149102978, 5.2550915130671898],
					[63.575022067609467, 5.2550915580662014],
					[63.575022031628869, 5.2550915910292524],
					[63.574708198968814, 5.2555747872225025],
					[63.573400134261782, 5.2554304144359989],
					[63.573155780713755, 5.2549055161572937],
					[63.573155739999542, 5.2549054711045446],
					[63.573155638761989, 5.2549054138493858],
					[63.573155579134173, 5.2549054015291672],
					[63.572941817950287, 5.254927587795633],
					[63.572680778628943, 5.2543999106628734],
					[63.572680735228076, 5.2543998659650004],
					[63.572680628669865, 5.2543998114287014],
					[63.572680567356009, 5.2543998033640866],
					[63.572050112917928, 5.2545053781612294],
					[63.57152797941194, 5.253449911116669],
					[63.571527944227775, 5.2534498713894706],
					[63.571527858318156, 5.2534498161581631],
					[63.571527807592716, 5.2534498006540495],
					[63.570486230282924, 5.25340810535541],
					[63.57027798110601, 5.252961015203832],
					[63.570277895143718, 5.2529609579670984],
					[63.570277716995079, 5.252960949150717],
					[63.570277625809247, 5.2529610014716654],
					[63.569999885811832, 5.2534469957984617],
					[63.569158307466786, 5.2534192006851157],
					[63.56915825299901, 5.2534192138990923],
					[63.569158161813, 5.2534192662164036],
					[63.569158124199276, 5.2534193054375526],
					[63.56889707861086, 5.2539054020965459],
					[63.568522195578204, 5.2539136991434816],
					[63.568522148326828, 5.2539137134229152],
					[63.568522064252086, 5.2539137627859827],
					[63.568522029219764, 5.253913797634004],
					[63.568208191558575, 5.2544358900821173],
					[63.567383522709513, 5.2543692168333793],
					[63.567536298664187, 5.2531028243659215],
					[63.567536298929497, 5.253102800139942],
					[63.567536294192188, 5.2531027564128854],
					[63.567536289137038, 5.2531027349028037],
					[63.567016889231574, 5.2516193340306616],
					[63.567016879751257, 5.2516193151195196],
					[63.567016859104712, 5.2516192815508553],
					[63.567016846990462, 5.2516192650022546],
					[63.566227946945396, 5.2507637638378313],
					[63.566227942362815, 5.2507637604094102],
					[63.566227933145143, 5.2507637515436345],
					[63.566227927614506, 5.250763746224167],
					[63.564800132813509, 5.2495692492051607],
					[63.563786237114726, 5.2486220534942616],
					[63.563786220627826, 5.2486220415559304],
					[63.563786187759142, 5.2486220216967281],
					[63.563786169586407, 5.2486220140117421],
					[63.563050070390382, 5.2483470119989422],
					[63.563050065860331, 5.2483470105799626],
					[63.563050056800257, 5.2483470077419812],
					[63.563050053165718, 5.2483470062050577],
					[63.561963953175578, 5.2480470076289478],
					[63.561963932421492, 5.2480470043158318],
					[63.561963890122989, 5.2480470018247756],
					[63.561963868578573, 5.2480470026468344],
					[63.560447168721566, 5.2482887027278435],
					[63.560447142857413, 5.2482887101647853],
					[63.5604470949214, 5.2482887326009955],
					[63.560447072796983, 5.2482887455917187],
					[63.558855473002794, 5.2496054458021053],
					[63.55885546599653, 5.2496054527703144],
					[63.558855451983959, 5.2496054667067718],
					[63.558855445873171, 5.2496054735571045],
					[63.557905482453165, 5.2507554299176409],
					[63.556872161006055, 5.2511054024413699],
					[63.555872336220176, 5.251171989312005],
					[63.555058489862901, 5.2489555371832806],
					[63.554122391656819, 5.2458221422593132],
					[63.554122375539087, 5.2458221101279667],
					[63.554122333663685, 5.2458220551952017],
					[63.554122307905956, 5.245822032393769],
					[63.5523223083811, 5.2446609326127129],
					[63.552322273826192, 5.2446609170175069],
					[63.552322203346421, 5.2446609021265029],
					[63.552322165630564, 5.2446609030667011],
					[63.550272166218292, 5.2450109039500958],
					[63.550272108590178, 5.245010933704342],
					[63.55027202562335, 5.2450110252236053],
					[63.550272001127453, 5.2450110848628544],
					[63.549983100648525, 5.248672183550414],
					[63.54998309985816, 5.2486721876838089],
					[63.549983100068431, 5.248672195714887],
					[63.549983100173577, 5.2486721997304162],
					[63.549960899966216, 5.2536916967337302],
					[63.549549805743546, 5.2655776129715024],
					[63.547613887024404, 5.265708100380583],
					[63.547613886128914, 5.2657081004978972],
					[63.547613886128914, 5.2657081004978972],
					[63.547344570389747, 5.2657275862391151],
					[63.546269599259269, 5.258630574997567],
					[63.545619599079338, 5.2517805808895694],
					[63.545619535769369, 5.2517804965525254],
					[63.545619369634622, 5.2517804317783465],
					[63.545619266809844, 5.251780451341209],
					[63.544388766696791, 5.2528748503431011],
					[63.54438876406283, 5.2528748527035862],
					[63.544388758794817, 5.2528748574245858],
					[63.544388757056325, 5.2528748596673696],
					[63.542852687199151, 5.2544526285876128],
					[63.541402728116893, 5.2550165145627377],
					[63.541402710364267, 5.2550165229366623],
					[63.541402676754963, 5.2550165434636664],
					[63.541402660898335, 5.2550165556167006],
					[63.540099860885306, 5.2562720559520546],
					[63.540099841813706, 5.2562720826237657],
					[63.540099811834978, 5.2562721389232152],
					[63.540099801875797, 5.2562721704404503],
					[63.539249801904269, 5.2619832712003225],
					[63.539249801165873, 5.2619832773388371],
					[63.539249799689124, 5.2619832896158991],
					[63.539249799898649, 5.2619832976440604],
					[63.539169200216428, 5.2670749960317611],
					[63.539169200425654, 5.2670750040599676],
					[63.539169200791811, 5.2670750181093124],
					[63.53916920189652, 5.267075026020291],
					[63.539488679648265, 5.2696609498189879],
					[63.539252750186918, 5.2697220059805288],
					[63.539252711202288, 5.2697220271915137],
					[63.539252644345119, 5.2697220822571609],
					[63.539252618315906, 5.2697221178845854],
					[63.53904999637394, 5.2701717208942718],
					[63.538822380627842, 5.2696860144540416],
					[63.538822368411147, 5.269685993899599],
					[63.53882233691813, 5.2696859577419408],
					[63.538822320276118, 5.2696859397802571],
					[63.538591869788164, 5.2695137760491368],
					[63.538455795629751, 5.2691110562050492],
					[63.53840849915936, 5.2686805788740916],
					[63.538408496054565, 5.2686805631696236],
					[63.538408489053978, 5.2686805358918427],
					[63.538408484158111, 5.2686805204217277],
					[63.538166896998277, 5.2681166511880946],
					[63.538136298634086, 5.26772498346683],
					[63.538136298372606, 5.2677249734319496],
					[63.538136294267581, 5.2677249538309745],
					[63.538136292215064, 5.2677249440304861],
					[63.537950200025655, 5.2670860729906437],
					[63.53795299996009, 5.2656056005049203],
					[63.537952997645803, 5.2656055806698436],
					[63.53795299033068, 5.2656055413515013],
					[63.537952984434355, 5.2656055219855018],
					[63.537711283686484, 5.2650388216456951],
					[63.537711281738446, 5.2650388158594499],
					[63.537711276999197, 5.265038806411166],
					[63.537711274155647, 5.2650388007421896],
					[63.537502996937221, 5.2646721426612535],
					[63.537419600176953, 5.2631082895002184],
					[63.537419597967045, 5.2631082736795731],
					[63.537419590913053, 5.2631082443972135],
					[63.537419586912122, 5.2631082288112605],
					[63.536625197173443, 5.2610193561253196],
					[63.53658079916503, 5.2604305848428377],
					[63.536580763196959, 5.2604305150514481],
					[63.53658065211571, 5.2604304249035403],
					[63.536580576897741, 5.2604304005333375],
					[63.535772176642247, 5.2605220004665751],
					[63.535772133919814, 5.2605220161366164],
					[63.53577205795397, 5.2605220663705259],
					[63.535772025606079, 5.2605221008169591],
					[63.535474883596123, 5.2610441992840773],
					[63.532113901980082, 5.2610526003365612],
					[63.531413905020429, 5.2610358994082835],
					[63.531413847969766, 5.2610359169530447],
					[63.531413753407499, 5.2610359776684934],
					[63.531413715948325, 5.2610360228456416],
					[63.530780416334792, 5.2625416215423799],
					[63.530780409694344, 5.2625416425455249],
					[63.530780401786572, 5.2625416838478136],
					[63.53078039962368, 5.2625417042643123],
					[63.530791499468066, 5.2629721597285011],
					[63.529433117719101, 5.2659805171194591],
					[63.529433111869054, 5.2659805339908781],
					[63.529433103698651, 5.2659805652584675],
					[63.529433101430662, 5.2659805816610294],
					[63.529366542698263, 5.2666745447091632],
					[63.529208444934845, 5.2665081620959082],
					[63.529208340904333, 5.2665081354465721],
					[63.529208169011383, 5.2665081941798606],
					[63.529208101096593, 5.2665082775561478],
					[63.52914423934574, 5.2670857337959989],
					[63.528966818270575, 5.2669554384259047],
					[63.528966706755824, 5.2669554348994518],
					[63.528966549289159, 5.2669555299921065],
					[63.528966501598532, 5.2669556308519443],
					[63.529210896626488, 5.2685332932888365],
					[63.528980438921025, 5.2695665014188657],
					[63.528047406071778, 5.2695719996303874],
					[63.528080800375591, 5.2684833066320973],
					[63.528080798009448, 5.2684832847973384],
					[63.528080789695117, 5.2684832415963339],
					[63.528080783799211, 5.2684832222363598],
					[63.527891899227853, 5.2680388568955214],
					[63.52788079972575, 5.2675332956253289],
					[63.527880798568773, 5.2675332857111909],
					[63.527880797202592, 5.2675332677720403],
					[63.527880796097918, 5.267533259864158],
					[63.527647399998401, 5.2664221761846628],
					[63.527605800019202, 5.2645499952834651],
					[63.527605797704815, 5.2645499754556422],
					[63.527605788650796, 5.2645499383924728],
					[63.527605782754328, 5.264549919033672],
					[63.526928001234417, 5.2630166598847712],
					[63.526939100167539, 5.2624333047177272],
					[63.52693909580001, 5.2624332750942662],
					[63.526939081796591, 5.2624332205636009],
					[63.526939070369707, 5.2624331958910187],
					[63.526711270037936, 5.262063794561028],
					[63.526711241386771, 5.2620637640932744],
					[63.526711172809385, 5.262063718726119],
					[63.526711134674201, 5.262063703592065],
					[63.526300034608298, 5.2619915022528696],
					[63.526299973765902, 5.261991512237004],
					[63.526299868142708, 5.2619915623089346],
					[63.526299824309831, 5.2619916042855506],
					[63.526038773976389, 5.2624776115902794],
					[63.525588915795581, 5.2625219979298032],
					[63.525161150990975, 5.2624081077833287],
					[63.525161081931834, 5.2624081128036675],
					[63.52516096477153, 5.2624081684090109],
					[63.525160916670416, 5.2624082189939934],
					[63.523149874188441, 5.2669608907338494],
					[63.522952813830393, 5.2669470006733867],
					[63.52295275936072, 5.2669470138373367],
					[63.522952662795404, 5.2669470667250389],
					[63.522952623386246, 5.2669471060973256],
					[63.522674874621003, 5.2674692097362481],
					[63.522441683262102, 5.2674887012504126],
					[63.522441634322384, 5.2674887197279068],
					[63.522441551346482, 5.2674887768734422],
					[63.522441518258077, 5.2674888174302117],
					[63.522235959774981, 5.2679415249335673],
					[63.52183885869718, 5.2680248045963065],
					[63.521838824033381, 5.268024819192842],
					[63.521838763079195, 5.2680248593668937],
					[63.521838735893262, 5.2680248850615445],
					[63.5215276543898, 5.2684692597645322],
					[63.521344278177367, 5.2686109425474603],
					[63.521344249409417, 5.2686109764984872],
					[63.521344210993, 5.2686110539764499],
					[63.521344199553575, 5.2686110977375398],
					[63.521338700620184, 5.2690777521162762],
					[63.520652623447759, 5.2705027069738355],
					[63.520355425853786, 5.2710249003015761],
					[63.520355420741886, 5.271024911032324],
					[63.520355410622571, 5.2710249365052935],
					[63.520355407301651, 5.2710249470020303],
					[63.520222145338096, 5.2715188373627715],
					[63.519961279294563, 5.2709916111028541],
					[63.519961176324372, 5.2709915561307277],
					[63.519960988317123, 5.2709915827083691],
					[63.519960904123302, 5.2709916621353914],
					[63.519785903968533, 5.2719110623747429],
					[63.519785903229689, 5.271911068508814],
					[63.519785900908722, 5.271911082899666],
					[63.519785900169893, 5.2719110890337353],
					[63.5197581024117, 5.2724166527903629],
					[63.519330455987109, 5.2733942303794645],
					[63.519097149373373, 5.2734554078279174],
					[63.519097117395532, 5.2734554220655863],
					[63.519097059178478, 5.273455463878939],
					[63.5190970337826, 5.2734554893320382],
					[63.518802837418086, 5.2738967843451494],
					[63.518627983065109, 5.2734943190660113],
					[63.518627948729943, 5.2734942772628859],
					[63.518627861827888, 5.2734942181738669],
					[63.518627810156516, 5.2734942007710659],
					[63.517355758106333, 5.2734331075709893],
					[63.517236293580176, 5.2729555503966212],
					[63.517236265409124, 5.2729555037665552],
					[63.517236186148864, 5.272955427585341],
					[63.517236135164111, 5.2729554020452509],
					[63.516841734977469, 5.2728859039524849],
					[63.516841671395269, 5.2728859122547904],
					[63.516841564133415, 5.2728859685191756],
					[63.516841518610477, 5.2728860147096537],
					[63.516099872268711, 5.274460902373149],
					[63.515669539409281, 5.2744664975722255],
					[63.515477988270547, 5.273944331736943],
					[63.515477948301353, 5.2739442806121133],
					[63.515477845071253, 5.2739442156104142],
					[63.51547778270583, 5.2739442016166578],
					[63.51524453921963, 5.2739636883901504],
					[63.5150557890071, 5.2734055359762921],
					[63.515055767365908, 5.2734054985577679],
					[63.515055709172799, 5.2734054377403776],
					[63.515055672620946, 5.2734054143415028],
					[63.514805796374148, 5.2733081612679138],
					[63.514794599629269, 5.2729166946928521],
					[63.5147945773614, 5.2729166332107971],
					[63.51479450031777, 5.2729165386370651],
					[63.514794444646533, 5.2729165056622849],
					[63.514544444685825, 5.2728581043082121],
					[63.514544433887345, 5.2728581037059543],
					[63.514544411342598, 5.2728581006130186],
					[63.514544398753031, 5.272858100244612],
					[63.514077934848743, 5.2728608984514436],
					[63.513869586127193, 5.272338826118764],
					[63.513869544418633, 5.2723387772381773],
					[63.51386944124004, 5.272338714251064],
					[63.513869378874489, 5.2723387002614865],
					[63.513455617306597, 5.2723830978026394],
					[63.513261286421468, 5.272327645673478],
					[63.513214099387653, 5.2718193819882435],
					[63.513214064373045, 5.271819314126752],
					[63.513213955089924, 5.2718192237712493],
					[63.513213881716879, 5.2718192011602776],
					[63.512997182081577, 5.2718387016276118],
					[63.512997147260634, 5.2718387101990043],
					[63.512997083409701, 5.2718387426818669],
					[63.512997053431953, 5.2718387647050848],
					[63.512524903145092, 5.2723470118280895],
					[63.512324975385944, 5.2723720015756097],
					[63.512324930027582, 5.2723720195699588],
					[63.512324852370334, 5.272372073973429],
					[63.512324821019199, 5.2723721122708156],
					[63.51212202124502, 5.2727832116424214],
					[63.512122012864161, 5.2727832348683537],
					[63.512122002371065, 5.2727832805018364],
					[63.512122000258834, 5.2727833029093834],
					[63.512130399087994, 5.2733277611592531],
					[63.51169981518327, 5.2743832247721025],
					[63.511699810227661, 5.2743832415143634],
					[63.511699802107451, 5.2743832747651869],
					[63.511699799838375, 5.2743832911568704],
					[63.511641501892626, 5.2758582556153097],
					[63.511008116361197, 5.2773277204642852],
					[63.511008112196478, 5.2773277330786854],
					[63.511008103971271, 5.277327762317678],
					[63.511008100754218, 5.2773277768204059],
					[63.510947022112902, 5.2778276058665048],
					[63.509149993883874, 5.2778775998188072],
					[63.509149941360469, 5.2778776187361647],
					[63.509149851267814, 5.2778776787626791],
					[63.509149815593936, 5.2778777236483005],
					[63.508738729169984, 5.2788581912597978],
					[63.508283204355187, 5.2793665075764524],
					[63.507797184257008, 5.2794054007677129],
					[63.50779713441996, 5.279405419331245],
					[63.507797049699818, 5.2794054786498954],
					[63.507797015764368, 5.2794055212931879],
					[63.507424858244832, 5.2802720242902108],
					[63.507152760024432, 5.2803276044708909],
					[63.507152720038128, 5.280327621749195],
					[63.507152652176352, 5.2803276728328434],
					[63.507152623405375, 5.2803277067548375],
					[63.506894282587453, 5.280813692484136],
					[63.503808310659529, 5.2806442006914072],
					[63.50380824912822, 5.2806442187614913],
					[63.503808149339662, 5.280644286064228],
					[63.503808110239007, 5.280644337418102],
					[63.503583115854681, 5.2813277207174822],
					[63.503322085875332, 5.2818108918908875],
					[63.502461236381755, 5.2817442108066057],
					[63.5022362841408, 5.2812193215024381],
					[63.502236247017137, 5.2812192760541077],
					[63.502236154744971, 5.2812192176733843],
					[63.502236100387805, 5.2812192006153884],
					[63.500747199920696, 5.2812165004064173],
					[63.500747149031511, 5.2812165130652637],
					[63.500747062363089, 5.2812165665832786],
					[63.500747024688557, 5.2812166036668469],
					[63.500172092025124, 5.2822608826601698],
					[63.499616730114241, 5.282177602666521],
					[63.499616674591046, 5.2821776098929334],
					[63.4996165786023, 5.2821776505412119],
					[63.499616536345691, 5.2821776841961876],
					[63.499230504173326, 5.2827303871562217],
					[63.497941808232945, 5.2825887108011482],
					[63.4976612601412, 5.2822165804612986],
					[63.497661227964251, 5.2822165524735771],
					[63.497661155967066, 5.2822165135804244],
					[63.497661116094818, 5.2822165006707982],
					[63.497013915641006, 5.282163701383614],
					[63.497013854900814, 5.2821637153208734],
					[63.497013754111656, 5.282163778709843],
					[63.49701371401062, 5.2821638261574453],
					[63.496583155717637, 5.2832581204999922],
					[63.496422169279697, 5.2832831018677666],
					[63.496422113433546, 5.2832831312524924],
					[63.496422029388214, 5.2832832165881465],
					[63.49642200213669, 5.2832832744266476],
					[63.496360926673219, 5.2837469844740506],
					[63.496163917279681, 5.2837304012029334],
					[63.496163859225881, 5.2837304147869331],
					[63.496163760018831, 5.2837304699191714],
					[63.496163718917614, 5.2837305134714772],
					[63.494769279440618, 5.2866553898433759],
					[63.494591716365179, 5.2866415017165451],
					[63.494591659154594, 5.286641513173417],
					[63.494591562632927, 5.2866415679432155],
					[63.494591522322274, 5.2866416073645839],
					[63.494127621863392, 5.2875360076467031],
					[63.494127615219057, 5.287536028616949],
					[63.494127602825934, 5.287536070441079],
					[63.494127599763786, 5.2875360909458839],
					[63.494097001874721, 5.2882332501174201],
					[63.493669219386007, 5.2891332136536988],
					[63.493669211054332, 5.2891332388636618],
					[63.49366920155525, 5.289133288353157],
					[63.493669201335443, 5.2891333145202601],
					[63.493713696639354, 5.2897221627320343],
					[63.492302619899228, 5.2927193135917223],
					[63.492061135291372, 5.2932077779241888],
					[63.491872389825211, 5.2926471363285179],
					[63.49187235075825, 5.2926470851022893],
					[63.491872250170985, 5.2926470177313583],
					[63.491872187703173, 5.2926469996990466],
					[63.490775122406006, 5.2927136913012287],
					[63.490289080167209, 5.2917027120324027],
					[63.490289067058221, 5.2917026916188838],
					[63.490289033727805, 5.291702653725201],
					[63.490289016244965, 5.2917026379000989],
					[63.490041891776478, 5.2915276904992483],
					[63.490011298926554, 5.2911360844803346],
					[63.490011288984064, 5.2911360475731293],
					[63.490011255821798, 5.291135981513059],
					[63.490011233445586, 5.291135950240319],
					[63.489816884699351, 5.2909637974723944],
					[63.489669599949643, 5.2900971818869369],
					[63.489658499955148, 5.2895527958626696],
					[63.489658463845714, 5.2895527201359158],
					[63.489658347253233, 5.2895526247031111],
					[63.489658266718301, 5.2895526029934041],
					[63.48919169177676, 5.2896303985425659],
					[63.488958317039405, 5.2896109015903177],
					[63.488958257141846, 5.2896109133874427],
					[63.488958157192613, 5.2896109746116471],
					[63.488958116984954, 5.2896110180280056],
					[63.488791516868375, 5.289988818956803],
					[63.48879151106722, 5.2899888378027162],
					[63.48879150220354, 5.2899888771493213],
					[63.488791499984593, 5.2899888955302092],
					[63.488780403269551, 5.2906109337550937],
					[63.488491663589535, 5.2906637021717362],
					[63.48849161833234, 5.2906637241280183],
					[63.488491542666232, 5.2906637862163288],
					[63.488491514048391, 5.2906638261158934],
					[63.488285914109433, 5.2911916266342658],
					[63.488285908360098, 5.2911916474831324],
					[63.488285901391059, 5.2911916906033261],
					[63.488285900119422, 5.291191710871102],
					[63.488313688051903, 5.2916997810319613],
					[63.487633431996713, 5.2916442097305252],
					[63.487389080563034, 5.2911193162162666],
					[63.487389040755858, 5.2911192711265986],
					[63.487388942218736, 5.2911192135560627],
					[63.48738888438433, 5.2911192009589643],
					[63.486905610818361, 5.2911580985177942],
					[63.486563938447027, 5.2910915041392723],
					[63.486563888192521, 5.2910915066401802],
					[63.486563796418807, 5.2910915366405229],
					[63.486563753952055, 5.2910915622527446],
					[63.486052694060966, 5.2916359207675745],
					[63.485819352342155, 5.2916942055152774],
					[63.485819296701798, 5.2916942428855336],
					[63.485819221022545, 5.291694339138485],
					[63.485819200140106, 5.2916944001407211],
					[63.485821999681853, 5.292588878220494],
					[63.485608185285649, 5.2935439986498327],
					[63.485150142194932, 5.2930804597310672],
					[63.485150043593727, 5.2930804343272779],
					[63.48514987501003, 5.2930804823209643],
					[63.485149805027547, 5.2930805557184577],
					[63.484935905149712, 5.2940360565781477],
					[63.484935902722562, 5.2940360669430184],
					[63.484935900606899, 5.2940360893277312],
					[63.484935899970864, 5.2940360994603823],
					[63.484935900288349, 5.2951638485808505],
					[63.484733168670253, 5.2955359228628822],
					[63.484463860420355, 5.2955915031453209],
					[63.484463817848969, 5.2955915247423571],
					[63.484463747398195, 5.2955915801024211],
					[63.484463717779612, 5.2955916161008556],
					[63.484238718003446, 5.296080516598594],
					[63.484238712201467, 5.2960805354402485],
					[63.484238703387923, 5.2960805767819918],
					[63.484238700324489, 5.2960805972787997],
					[63.48423040389676, 5.2965858809697837],
					[63.484052818979407, 5.2965692014781629],
					[63.484052758185626, 5.2965692133744025],
					[63.484052657337564, 5.296569274679376],
					[63.484052616283996, 5.2965693201976061],
					[63.482952680759681, 5.299124771584899],
					[63.482647339662762, 5.2990609254825056],
					[63.482191887376388, 5.2980555282453619],
					[63.482005799582616, 5.2974555684217197],
					[63.482002999640926, 5.2964832993250415],
					[63.482002998226662, 5.2964832794099763],
					[63.482002990024881, 5.2964832402759647],
					[63.482002984132862, 5.2964832209409884],
					[63.481794598365745, 5.2959999533964881],
					[63.481766900263693, 5.2954916891462931],
					[63.481766865208598, 5.2954916193257509],
					[63.481766756731872, 5.2954915248525722],
					[63.481766682414637, 5.2954915003159559],
					[63.48108342766217, 5.295552588442006],
					[63.480425184420746, 5.2939860225753224],
					[63.480425146405636, 5.2939859772601174],
					[63.480425049556892, 5.2939859154547424],
					[63.480424991670759, 5.2939859008514656],
					[63.479761238349184, 5.2940108955864309],
					[63.479555787917477, 5.293447132740396],
					[63.47955575985705, 5.2934470901560235],
					[63.479555687239831, 5.2934470272224754],
					[63.479555641735622, 5.2934470049864704],
					[63.479269541852979, 5.2933859261418972],
					[63.479077983070418, 5.2929443196769803],
					[63.479077945106873, 5.2929442763681456],
					[63.479077849153278, 5.2929442144529775],
					[63.47907779216262, 5.2929441997362945],
					[63.477783291876584, 5.2929942005294857],
					[63.477783224385938, 5.2929942313843927],
					[63.477783128159849, 5.2929943322794433],
					[63.477783100319968, 5.2929944022034956],
					[63.477805397572752, 5.294980411583091],
					[63.47753608840555, 5.2949969990473233],
					[63.477536038565994, 5.2949970175624204],
					[63.47753595463017, 5.2949970726478242],
					[63.477535920585773, 5.2949971112209218],
					[63.477294280760759, 5.2954830907208841],
					[63.476597216083434, 5.2954276005611991],
					[63.476597158923752, 5.2954276139949013],
					[63.476597060607048, 5.2954276689307997],
					[63.476597021241069, 5.2954277102009559],
					[63.476372020578758, 5.2958777116328966],
					[63.476372013037555, 5.295877732704084],
					[63.4763720033284, 5.2958777741504512],
					[63.476372000316836, 5.2958777966443247],
					[63.476360900741348, 5.296652757055889],
					[63.475988716416161, 5.2975166217049763],
					[63.475988709666552, 5.2975166386541259],
					[63.475988701696195, 5.2975166778647962],
					[63.475988700371765, 5.2975166961209013],
					[63.475971999866729, 5.2984888750557317],
					[63.475741504622889, 5.2994805551029147],
					[63.475741503401821, 5.2994805773641493],
					[63.47574150280267, 5.2994806236575016],
					[63.475741505163846, 5.2994806454551631],
					[63.475974799325606, 5.3004722183829518],
					[63.475949799899396, 5.3010527904410365],
					[63.475949805309646, 5.301052825910336],
					[63.475949825929433, 5.3010528935719279],
					[63.475949842878322, 5.3010529235298511],
					[63.476177598997637, 5.3013417688990243],
					[63.476163700370797, 5.3021193958464812],
					[63.476163702835159, 5.3021194216501115],
					[63.476163713929225, 5.3021194684412869],
					[63.4761637226107, 5.3021194914315242],
					[63.476374797738082, 5.302525045386119],
					[63.476338708115136, 5.3035330618945773],
					[63.475963935963271, 5.303463703146984],
					[63.475963874169622, 5.3034637111350511],
					[63.475963765946673, 5.3034637653128947],
					[63.475963721308482, 5.303463811271155],
					[63.475666569341776, 5.3040609155806209],
					[63.475469520570968, 5.3040858830933155],
					[63.47519188172847, 5.3034777168433278],
					[63.475191840134052, 5.3034776719865642],
					[63.475191737123865, 5.3034776149753018],
					[63.475191675656326, 5.3034776008181801],
					[63.474797324628526, 5.303524784305468],
					[63.474572384884375, 5.3029999232200264],
					[63.474364100723356, 5.3024805639845747],
					[63.474389099783181, 5.3008472024348494],
					[63.474389098576786, 5.3008471905351824],
					[63.474389097059543, 5.3008471666200059],
					[63.474389094957608, 5.3008471548361618],
					[63.474172395090896, 5.2999388545686337],
					[63.47417235308086, 5.2999387936980424],
					[63.4741722423867, 5.2999387176027764],
					[63.474172171015908, 5.2999387027257114],
					[63.473830704239077, 5.299988682994468],
					[63.473444599373288, 5.2993332448474213],
					[63.473436300349007, 5.2988277978899099],
					[63.473436296988496, 5.2988277722049384],
					[63.473436284102306, 5.2988277256512974],
					[63.473436275420291, 5.2988277026642443],
					[63.472980774986127, 5.2980138029110515],
					[63.472980750872203, 5.2980137738853958],
					[63.472980695790952, 5.2980137287763611],
					[63.472980664771789, 5.2980137106905074],
					[63.472533446209617, 5.2978609370897356],
					[63.472291881552174, 5.2973360154000595],
					[63.472291848171452, 5.2973359755204967],
					[63.472291764017449, 5.2973359181045279],
					[63.472291714139736, 5.2973359004521772],
					[63.472058444332106, 5.2973192092117918],
					[63.471886288166395, 5.2968388322690343],
					[63.47188625462995, 5.2968387863833941],
					[63.471886169372752, 5.2968387210760142],
					[63.471886115860912, 5.296838701886184],
					[63.471258316377963, 5.2967887000420513],
					[63.471258310109114, 5.2967887008537256],
					[63.471258298415016, 5.2967887003587775],
					[63.471258292094326, 5.2967886991680766],
					[63.46996390108805, 5.2968386993806051],
					[63.468852808915607, 5.2967886992680242],
					[63.468852797273392, 5.2967887007752701],
					[63.468852773041561, 5.2967887019035143],
					[63.468852761399354, 5.2967887034107575],
					[63.468438860973571, 5.296872004930564],
					[63.468438806915209, 5.2968720340264932],
					[63.46843872739035, 5.2968721206588718],
					[63.468438700976478, 5.2968721763091002],
					[63.46816920099478, 5.2991443752651595],
					[63.468169201098426, 5.2991443792694648],
					[63.468169200410152, 5.2991443873939055],
					[63.468169199618274, 5.2991443915140728],
					[63.468077600918846, 5.3013527624369461],
					[63.46785269106217, 5.3019941069927938],
					[63.467622300228705, 5.3018609279747198],
					[63.467622230700954, 5.3018609148662916],
					[63.467622102843116, 5.3018609394308536],
					[63.467622042670115, 5.3018609753333044],
					[63.467219308622418, 5.3023719918033771],
					[63.466716715843994, 5.3023304011666514],
					[63.466716657787941, 5.30233041469707],
					[63.46671656036375, 5.3023304694735138],
					[63.466716519308029, 5.3023305149551874],
					[63.466252684808687, 5.3033053760639772],
					[63.465875036092115, 5.3032359039687345],
					[63.465874976985091, 5.3032359116065981],
					[63.465874872137178, 5.3032359572941079],
					[63.465874828290957, 5.3032359991162856],
					[63.46552489620948, 5.3038303827120687],
					[63.464933329763483, 5.3037415029325485],
					[63.464933263595448, 5.3037415154985483],
					[63.464933154735334, 5.3037415797785119],
					[63.464933112043333, 5.3037416314924446],
					[63.464688754776063, 5.3044220117827621],
					[63.46445558274263, 5.3044415015311834],
					[63.464455518831379, 5.3044415318810607],
					[63.464455427866397, 5.3044416279915945],
					[63.464455399917107, 5.3044416938679095],
					[63.464422001189973, 5.3053721646161955],
					[63.464233110168337, 5.3059416361720153],
					[63.4642331069482, 5.3059416506477479],
					[63.464233102247306, 5.3059416773660848],
					[63.464233099870974, 5.305941689724305],
					[63.464208101843646, 5.3064416473290485],
					[63.464002621099198, 5.3068527112079131],
					[63.464002612660714, 5.306852732382672],
					[63.464002602999983, 5.3068527758092214],
					[63.464002599934894, 5.3068527962903653],
					[63.463985901270206, 5.3078638649873957],
					[63.463835954656041, 5.3083192019280778],
					[63.46221953117346, 5.3083414987842099],
					[63.461977983593457, 5.3077805212457401],
					[63.461977888541576, 5.3077804592001989],
					[63.461977702694107, 5.3077804651062346],
					[63.461977611846727, 5.3077805310560899],
					[63.461772075718031, 5.3083441549944697],
					[63.461055741902349, 5.3081331327839312],
					[63.460883480870862, 5.3077693132419501],
					[63.460883476186225, 5.3077693058131095],
					[63.460883464922418, 5.3077692871832518],
					[63.460883459342234, 5.3077692798699658],
					[63.460600158875273, 5.3073970797012633],
					[63.460600067078737, 5.307397039331132],
					[63.460599895181822, 5.3073970635219272],
					[63.460599814977975, 5.3073971240795892],
					[63.460413793192096, 5.3078469340136607],
					[63.459986180923835, 5.3076581170450812],
					[63.459986084099278, 5.3076581255220878],
					[63.45998594372066, 5.3076582219578725],
					[63.459985900114916, 5.3076583079150579],
					[63.460010898394408, 5.308280559065695],
					[63.459733126345078, 5.3088805007489261],
					[63.459072241288638, 5.3098384882895049],
					[63.458880799660967, 5.3093943598260074],
					[63.45888079989291, 5.3082667108961052],
					[63.458986293131538, 5.3073056712886419],
					[63.45939186472085, 5.3067168123767452],
					[63.459391878377595, 5.3067167845072527],
					[63.459391895736424, 5.3067167260365613],
					[63.459391900334055, 5.3067166953198166],
					[63.459350199618818, 5.30480829647514],
					[63.459350192366749, 5.3048082592553092],
					[63.459350164584414, 5.3048081925549502],
					[63.459350144949703, 5.3048081629587616],
					[63.458872351663935, 5.3043026691165824],
					[63.458450156775839, 5.303763776035038],
					[63.458450120073458, 5.3037637466367622],
					[63.458450034648429, 5.3037637094743726],
					[63.458449987665098, 5.3037636994774653],
					[63.458180588330713, 5.3037803994643449],
					[63.458180534907477, 5.3037804184132646],
					[63.458180447644217, 5.3037804839039362],
					[63.458180413700674, 5.3037805264427771],
					[63.457974864183484, 5.3043080989213456],
					[63.456880720108444, 5.3042998013433174],
					[63.456619575671631, 5.3038110067063196],
					[63.456619547821397, 5.3038109721504876],
					[63.456619480048815, 5.3038109226702153],
					[63.456619440918566, 5.3038109036273964],
					[63.45631405087876, 5.3037470275161072],
					[63.455747387570788, 5.3021499325217833],
					[63.455747386623464, 5.302149930636209],
					[63.455747384677004, 5.3021499248637758],
					[63.455747383677881, 5.3021499209769036],
					[63.455575183957464, 5.3017471215449339],
					[63.455575155107603, 5.3017470831055169],
					[63.455575081702108, 5.301747024319825],
					[63.455575038041999, 5.3017470038578169],
					[63.455233445718441, 5.3016804242560154],
					[63.45504458576557, 5.3011971272762803],
					[63.455044496084348, 5.3011970645725608],
					[63.455044311717593, 5.3011970582795884],
					[63.455044217979435, 5.3011971165758087],
					[63.454822224579743, 5.3016855623377541],
					[63.454614088936445, 5.3010860341677501],
					[63.454614087937337, 5.3010860302810618],
					[63.454614085095251, 5.30108602462467],
					[63.45461408414792, 5.3010860227392023],
					[63.454408492049019, 5.3006027399275544],
					[63.454191895820507, 5.2995749585949925],
					[63.454191889033481, 5.2995749393937386],
					[63.454191871877136, 5.2995749014543776],
					[63.454191862403412, 5.2995748826004681],
					[63.453505767561737, 5.2986276909152465],
					[63.452911273067407, 5.2976081992087005],
					[63.452911239842365, 5.2976081653583043],
					[63.452911161269242, 5.297608115287856],
					[63.452911115077434, 5.2976081011846956],
					[63.452444415042578, 5.2975720009473015],
					[63.452444406930731, 5.2975719999887687],
					[63.452444390758892, 5.2975720000727451],
					[63.452444382698872, 5.2975720011152552],
					[63.451977782728555, 5.2976109011997368],
					[63.451977747957358, 5.2976109117201826],
					[63.451977682256398, 5.2976109423027999],
					[63.451977653169578, 5.2976109641343356],
					[63.451410952776548, 5.2982276651120248],
					[63.45141094492385, 5.298227674158186],
					[63.451410931061403, 5.2982276940198991],
					[63.451410924156114, 5.2982277049512412],
					[63.451208124252709, 5.2985999041379213],
					[63.451208115867189, 5.2985999273064532],
					[63.451208102626644, 5.2985999711793648],
					[63.451208099666296, 5.2985999956540812],
					[63.451197009629709, 5.2991800976676524],
					[63.451002946977269, 5.2989692653101947],
					[63.451002837578265, 5.2989692393010444],
					[63.451002664084399, 5.2989693058996652],
					[63.451002599937738, 5.2989693965064717],
					[63.450994199637229, 5.2996305604164586],
					[63.450785930703269, 5.3001581827423578],
					[63.450313830019773, 5.3005858931150973],
					[63.450116717432792, 5.3005692016998669],
					[63.45011665579456, 5.3005692156878412],
					[63.450116555839372, 5.3005692767865575],
					[63.450116514835798, 5.3005693242444938],
					[63.449480603774809, 5.3021105658141598],
					[63.449291886099942, 5.3016305275994284],
					[63.4492918580939, 5.3016304870518329],
					[63.44929178463655, 5.3016304262779945],
					[63.449291740924643, 5.3016304038195123],
					[63.448986239257891, 5.3015665249084218],
					[63.448083480645131, 5.299630515238329],
					[63.448083448160723, 5.29963047527312],
					[63.448083364903233, 5.2996304177808717],
					[63.448083315921288, 5.2996304000223295],
					[63.447905728389358, 5.2996165081088247],
					[63.447400179255062, 5.2986027101486037],
					[63.44740013592002, 5.2986026675734372],
					[63.447400032959877, 5.2986026126325809],
					[63.447399973386574, 5.2986026022675849],
					[63.44721937288876, 5.2986276019935863],
					[63.447219312612667, 5.2986276338744949],
					[63.447219226920836, 5.2986277252477496],
					[63.447219200661415, 5.2986277868565619],
					[63.447174830726119, 5.2992467589484766],
					[63.446997362836854, 5.2990748805336461],
					[63.446772378024683, 5.2986305101354336],
					[63.446772374183332, 5.2986305005952667],
					[63.446772363022014, 5.2986304859793396],
					[63.446772357389555, 5.2986304766707155],
					[63.446350156917539, 5.298091576768404],
					[63.446350122005214, 5.2980915471577754],
					[63.446350042847662, 5.2980915092163778],
					[63.446349998602479, 5.298091500885624],
					[63.445900190108247, 5.2980941985241348],
					[63.445872400287982, 5.2975499902621328],
					[63.445872367972846, 5.2975499221782885],
					[63.445872266662207, 5.2975498268848495],
					[63.44587219677117, 5.2975497997910539],
					[63.445477797428758, 5.2975553993774716],
					[63.445477728989175, 5.2975554282996251],
					[63.445477630812071, 5.2975555232921065],
					[63.445477600179004, 5.2975555894782165],
					[63.445449802543834, 5.2980999334268395],
					[63.444752699806983, 5.299130391471075],
					[63.443855800539708, 5.2990581154636365],
					[63.443869600403588, 5.2971167003828903],
					[63.443869598094047, 5.2971166806100438],
					[63.443869589840851, 5.2971166395271343],
					[63.443869583949066, 5.2971166202175093],
					[63.44362520010921, 5.2965527593710675],
					[63.443625200082735, 5.2955055996085072],
					[63.443625199927126, 5.2955055936072437],
					[63.443625198720397, 5.2955055817205485],
					[63.443625197669228, 5.295505575835123],
					[63.4434334977961, 5.2940138740291012],
					[63.443433496744881, 5.2940138681437903],
					[63.443433494642441, 5.2940138563731454],
					[63.443433493591243, 5.2940138504878105],
					[63.44318079429425, 5.2930221509958661],
					[63.443180793242995, 5.2930221451106423],
					[63.443180790348734, 5.293022137456874],
					[63.443180788401925, 5.293022131687561],
					[63.443008488353506, 5.2925416324918997],
					[63.443008466769527, 5.2925415971514775],
					[63.443008414061971, 5.2925415397482949],
					[63.443008381147287, 5.2925415179173863],
					[63.44279459026211, 5.2924470655505598],
					[63.442764099174859, 5.2920582841233816],
					[63.442764077071629, 5.2920582287800517],
					[63.442764003779196, 5.2920581399232294],
					[63.4427639543811, 5.2920581061778194],
					[63.442566893614433, 5.2920026467224313],
					[63.442541900037085, 5.2913805923145949],
					[63.442541895831809, 5.2913805687744286],
					[63.442541883890961, 5.2913805241583498],
					[63.442541875259842, 5.2913805031984129],
					[63.44231407493934, 5.2909721022191114],
					[63.442314040765375, 5.2909720665042501],
					[63.442313961294531, 5.2909720165856449],
					[63.442313914154603, 5.2909720006135439],
					[63.442116823990631, 5.2909581088279012],
					[63.441875176042132, 5.2905110050753157],
					[63.441875141868039, 5.2905109693615415],
					[63.441875062293164, 5.2905109154443695],
					[63.441875015205135, 5.290510901473537],
					[63.441694513246254, 5.2904970077780389],
					[63.441397367233179, 5.2900442891145261],
					[63.441397341378639, 5.2900442623590527],
					[63.441397280078192, 5.2900442201255595],
					[63.441397244632299, 5.290044204647522],
					[63.441144550547442, 5.2899859294869476],
					[63.440766887824495, 5.2889471326059683],
					[63.440766885929499, 5.2889471288376253],
					[63.440766882983056, 5.2889471191847068],
					[63.440766881088045, 5.2889471154163807],
					[63.440541881428544, 5.2884638155914026],
					[63.440541848045569, 5.2884637757652584],
					[63.440541764783838, 5.2884637183200232],
					[63.44054171580062, 5.2884637005848738],
					[63.439897216370092, 5.2884109008779712],
					[63.439897157419729, 5.2884109145381961],
					[63.439897057417802, 5.2884109736560676],
					[63.439897016366281, 5.2884110191137292],
					[63.439652673296337, 5.2889747902695703],
					[63.439402815359138, 5.2889553999510808],
					[63.439402756460659, 5.2889554156100314],
					[63.439402655562951, 5.2889554748409688],
					[63.439402616302338, 5.2889555200649534],
					[63.439160964833881, 5.2895165075588579],
					[63.438891688247757, 5.2895330999860821],
					[63.43889163482644, 5.2895331189475865],
					[63.438891548413096, 5.2895331823193645],
					[63.438891513630018, 5.2895332269616535],
					[63.43851656926244, 5.2904747882928991],
					[63.437422312625024, 5.2903887075203642],
					[63.437125166647647, 5.2899359898167013],
					[63.43712513715893, 5.2899359615292179],
					[63.437125066799084, 5.2899359164619844],
					[63.437125026875407, 5.2899359015661958],
					[63.436480801729438, 5.2898470258542281],
					[63.436516900005721, 5.2864333011890858],
					[63.436516900005721, 5.2864333011890858],
					[63.436516899953737, 5.2864332991891816],
					[63.436516899953737, 5.2864332991891816],
					[63.43650300021649, 5.2838693994017829],
					[63.43650297142517, 5.2838693288908729],
					[63.436502871792854, 5.2838692294440168],
					[63.43650280274295, 5.2838692002756869],
					[63.435911303413604, 5.2838609033844675],
					[63.435964100326814, 5.2805250025162733],
					[63.435964100326814, 5.2805250025162733],
					[63.435964100274752, 5.2805250005164179],
					[63.435964100274752, 5.2805250005164179],
					[63.43595580018598, 5.2753611205466617],
					[63.436072395093731, 5.2748278432747249],
					[63.436072396624034, 5.2748278330425435],
					[63.43607239878915, 5.2748278126946415],
					[63.436072400319446, 5.274827802462462],
					[63.43610299973173, 5.2728500028699008],
					[63.436119600076935, 5.2718806023039999],
					[63.436119598868267, 5.2718805904214676],
					[63.43611959639869, 5.2718805646565627],
					[63.436119594242271, 5.2718805508907227],
					[63.435866896097423, 5.2708888593801078],
					[63.435622398062804, 5.2692777702604081],
					[63.435622396010608, 5.2692777604946333],
					[63.43562239006291, 5.2692777391965526],
					[63.435622387115174, 5.2692777295474134],
					[63.43524188742775, 5.268277729187262],
					[63.435241832024197, 5.2682776721940838],
					[63.43524169755284, 5.2682776154682776],
					[63.435241619432816, 5.2682776176187804],
					[63.434050029455825, 5.2688053695622292],
					[63.433552923281958, 5.2684165410877029],
					[63.433552864341166, 5.2684165206746387],
					[63.433552744765535, 5.2684165181920273],
					[63.433552685026221, 5.268416536005831],
					[63.432205484886069, 5.2693581357024053],
					[63.432205472557527, 5.2693581453332694],
					[63.432205450691548, 5.2693581682442838],
					[63.432205440206289, 5.2693581796414888],
					[63.431819259349858, 5.2698692546494241],
					[63.431038782741574, 5.2704304389499699],
					[63.431038753076663, 5.2704304729058258],
					[63.431038711127393, 5.2704305525990724],
					[63.431038699738558, 5.270430598219888],
					[63.431027599893653, 5.2721360529233356],
					[63.43086092806616, 5.2724720975727859],
					[63.430677688145487, 5.2727304123236944],
					[63.430283276242356, 5.2727776017101391],
					[63.43028321502441, 5.2727776317422705],
					[63.430283126766881, 5.2727777274849998],
					[63.430283099622933, 5.2727777891967422],
					[63.430255408345907, 5.2733165387617227],
					[63.430002755185072, 5.2733748048876254],
					[63.430002697705639, 5.2733748404505461],
					[63.430002621246146, 5.2733749406745787],
					[63.430002599579488, 5.2733750056851365],
					[63.430013693158728, 5.2738025407784521],
					[63.42935284974115, 5.2736331056066383],
					[63.42935275865733, 5.2736331274829764],
					[63.429352634786419, 5.2736332358760176],
					[63.429352601103773, 5.2736333225092089],
					[63.429416496662803, 5.2742221849108244],
					[63.429130450300264, 5.2752858865445509],
					[63.428447327759152, 5.2752304097489837],
					[63.428222378159504, 5.2747888108701133],
					[63.427977985922269, 5.2743027244173435],
					[63.42778911412578, 5.2737056130890041],
					[63.42799458208713, 5.2732556835660453],
					[63.427994590894613, 5.2732556422991781],
					[63.427994591442229, 5.2732555599791162],
					[63.427994583182375, 5.2732555189258958],
					[63.427319583036393, 5.2717277195845744],
					[63.42731958208865, 5.27172771770186],
					[63.427319581140935, 5.271727715819142],
					[63.427319580193192, 5.2717277139364089],
					[63.427144580185633, 5.2713638133837621],
					[63.427144540424955, 5.2713637704130081],
					[63.427144441880458, 5.2713637130249431],
					[63.427144384939886, 5.2713637003737812],
					[63.426891838686835, 5.2713830884866919],
					[63.42668628913453, 5.2707832352139423],
					[63.426686282395949, 5.27078321803703],
					[63.426686265441091, 5.2707831881477523],
					[63.426686255172591, 5.2707831734362047],
					[63.426247401216663, 5.2702443308494438],
					[63.426266900381741, 5.2678778018893881],
					[63.426266900120517, 5.2678777918936266],
					[63.426266897754765, 5.2678777701362138],
					[63.426266895702483, 5.2678777603737093],
					[63.426033499941212, 5.2666943777934749],
					[63.426014100252011, 5.2657249967949342],
					[63.426014096885851, 5.2657249711563452],
					[63.42601408404169, 5.2657249266934034],
					[63.42601407535463, 5.2657249037540925],
					[63.425769575133593, 5.2652777030148856],
					[63.425769540953468, 5.2652776673508308],
					[63.425769457839749, 5.2652776159987837],
					[63.425769409801731, 5.2652776001940556],
					[63.424458419123908, 5.2652137066387192],
					[63.424177993168264, 5.2647249340543825],
					[63.424050197862393, 5.2637443734975742],
					[63.424050190861479, 5.2637443463289788],
					[63.424050168904571, 5.2637442970404562],
					[63.424050154791736, 5.2637442728048009],
					[63.423539068283937, 5.2631220902048401],
					[63.423330804353768, 5.2627166588992207],
					[63.423377999202138, 5.2621000152233233],
					[63.423377962817646, 5.2620999297093283],
					[63.42337783520027, 5.2620998260075229],
					[63.423377743967428, 5.2620998078197054],
					[63.422852743983803, 5.2622526077447089],
					[63.42285270942461, 5.2622526262917093],
					[63.422852648679807, 5.262252674328038],
					[63.422852624337622, 5.2622527055827018],
					[63.422613723810763, 5.262699906702589],
					[63.422613715378638, 5.2626999278591713],
					[63.422613703096701, 5.2626999735862361],
					[63.422613700090082, 5.2626999960410625],
					[63.422605400104374, 5.2632055977115426],
					[63.422605402575442, 5.2632056234635431],
					[63.422605418368491, 5.2632056775642564],
					[63.422605428899281, 5.2632057022654548],
					[63.422849797937005, 5.2636111515797186],
					[63.422822001676124, 5.2642693560411633],
					[63.422652615342116, 5.2646832248190769],
					[63.422652610282697, 5.2646832375126928],
					[63.422652602954976, 5.2646832665475944],
					[63.422652600686682, 5.2646832828888614],
					[63.422608101971996, 5.2651832655726176],
					[63.422438706877202, 5.2658305490090163],
					[63.42243870445197, 5.265830559353442],
					[63.422438701340305, 5.2658305778100676],
					[63.422438699810606, 5.2658305880378187],
					[63.422413710935977, 5.2662526014146556],
					[63.42192793570716, 5.2662553993778447],
					[63.421739098960849, 5.2657749577269684],
					[63.421675200340665, 5.2641388916405933],
					[63.42167519818355, 5.2641388778822602],
					[63.421675192921512, 5.2641388484835296],
					[63.42167518897331, 5.2641388349586444],
					[63.421536294033707, 5.2637388506682159],
					[63.421422397208559, 5.2630721665968574],
					[63.421422395208282, 5.2630721588351648],
					[63.421422391155389, 5.2630721413129438],
					[63.421422389155111, 5.2630721335512307],
					[63.421283488863232, 5.2626721342580565],
					[63.421283465376469, 5.2626720952025536],
					[63.421283401754202, 5.2626720333016426],
					[63.421283363409714, 5.2626720102227278],
					[63.420980751719817, 5.2625720399560496],
					[63.420825186097936, 5.2621721264226737],
					[63.42082514712552, 5.2621720793596456],
					[63.420825046629531, 5.2621720162525101],
					[63.420824986897024, 5.2621719999748136],
					[63.420575202758336, 5.2621886862227019],
					[63.420589099934098, 5.2610250028615297],
					[63.420689099015298, 5.2565833497734049],
					[63.420922340149808, 5.2561140679183982],
					[63.421088953639092, 5.2560668923571674],
					[63.42108898740679, 5.256066877918439],
					[63.421089043776647, 5.2560668344545327],
					[63.42108906822228, 5.2560668071941734],
					[63.421350167982247, 5.2556612079286733],
					[63.421350173145726, 5.2556611992316293],
					[63.421350181629165, 5.2556611800727389],
					[63.421350186792623, 5.2556611713757162],
					[63.421555787063127, 5.2551278717896297],
					[63.421555791964437, 5.2551278530984549],
					[63.42155579902812, 5.2551278140683371],
					[63.421555800347363, 5.2551277958451736],
					[63.421519602937479, 5.2537862078509789],
					[63.422030708359749, 5.2534584679048404],
					[63.422030743288857, 5.2534584292425208],
					[63.422030788600985, 5.2534583410867084],
					[63.422030799879565, 5.253458291476135],
					[63.421972400001884, 5.2521138909460134],
					[63.421972395790284, 5.2521138674286094],
					[63.421972382994412, 5.2521138249769255],
					[63.421972374305213, 5.2521138020450175],
					[63.421722403383363, 5.2516693519066742],
					[63.421847399773498, 5.2491778092741548],
					[63.421847395298862, 5.2491777757632958],
					[63.421847373759967, 5.2491777083834146],
					[63.421847357643713, 5.2491776763960178],
					[63.421544607229521, 5.2487888396990785],
					[63.421608499013004, 5.248125019213826],
					[63.421608476368014, 5.2481249439574134],
					[63.42160838456136, 5.2481248356354069],
					[63.421608316242661, 5.2481248004538124],
					[63.421300016081076, 5.2480998005593449],
					[63.421299953605384, 5.2480998167598498],
					[63.421299851091653, 5.2480998823252465],
					[63.421299811106095, 5.2480999336888976],
					[63.421152649732484, 5.248516525064705],
					[63.420700126649947, 5.2486025723093972],
					[63.420483486695396, 5.2480360287613115],
					[63.420483450404987, 5.2480359813665505],
					[63.420483356171665, 5.2480359174893181],
					[63.420483300019775, 5.248035900772412],
					[63.418933299963243, 5.2480386992435584],
					[63.418933251763221, 5.248038711568725],
					[63.418933167635032, 5.2480387586784527],
					[63.418933131654356, 5.2480387914644187],
					[63.418672078902233, 5.2484415193252838],
					[63.418222161920824, 5.2485276050126783],
					[63.418222116617343, 5.2485276249792792],
					[63.418222041916664, 5.2485276889013619],
					[63.418222013309986, 5.2485277287425562],
					[63.417838713216057, 5.2495360289666309],
					[63.417838708262977, 5.2495360456577753],
					[63.417838701938813, 5.2495360785713965],
					[63.417838699724754, 5.2495360969095843],
					[63.417805401238319, 5.2515443568177327],
					[63.417627615713265, 5.2519555209190942],
					[63.417627614922694, 5.2519555250332131],
					[63.417627610654975, 5.2519555336126853],
					[63.417627609916856, 5.2519555397252979],
					[63.416977651953815, 5.2539776055653631],
					[63.416469392336083, 5.2539969994482263],
					[63.416469326592122, 5.2539970280915131],
					[63.416469231078132, 5.2539971207833505],
					[63.416469200412529, 5.2539971849489193],
					[63.416435903026596, 5.2544805505662913],
					[63.416197018115049, 5.255011017807643],
					[63.416197011422653, 5.2550110367292771],
					[63.416197003411042, 5.255011073870647],
					[63.416197000300762, 5.255011092324362],
					[63.416149801627029, 5.2564082395897627],
					[63.415891531199257, 5.2568165936669775],
					[63.415891527721968, 5.256816598131465],
					[63.415891522558425, 5.2568166068266136],
					[63.415891520872172, 5.2568166110572569],
					[63.41460262106979, 5.2594305112189774],
					[63.414602614376797, 5.2594305301385376],
					[63.414602603677437, 5.2594305676271311],
					[63.414602599671056, 5.259430586196185],
					[63.414555402667986, 5.2601638502258519],
					[63.414349845361102, 5.2606137573562046],
					[63.414169444974441, 5.2607164432477544],
					[63.413922372788512, 5.2604248953050199],
					[63.413594588711291, 5.2596221338863147],
					[63.413422394048858, 5.2590277513570394],
					[63.413222395417208, 5.2581193568257421],
					[63.413222393416667, 5.2581193490665843],
					[63.413222387624494, 5.2581193337820098],
					[63.413222385623961, 5.2581193260228716],
					[63.412980785807825, 5.2575193254520114],
					[63.412980783912026, 5.2575193216893803],
					[63.412980780068033, 5.2575193121658987],
					[63.412980778172219, 5.2575193084032499],
					[63.412772400287224, 5.2571138513671656],
					[63.412772400423634, 5.2559889007973162],
					[63.412772358767363, 5.2559888200203213],
					[63.412772230459922, 5.2559887244923216],
					[63.412772141069794, 5.2559887080939101],
					[63.412266640749216, 5.2561442086074459],
					[63.412266595706519, 5.2561442385483597],
					[63.41226652668972, 5.2561443137243034],
					[63.412266503715934, 5.256144362838719],
					[63.41208873790287, 5.257060885195596],
					[63.411891716859273, 5.2570442015845442],
					[63.411891657859691, 5.2570442132957798],
					[63.411891558766307, 5.2570442723447899],
					[63.411891517672238, 5.2570443158032063],
					[63.411649878835249, 5.2575691843872105],
					[63.410558323422329, 5.2574442014857876],
					[63.410558260840602, 5.257444213663085],
					[63.410558158164889, 5.2574442731753255],
					[63.410558116279915, 5.2574443207440309],
					[63.410313768307262, 5.2580081019550242],
					[63.40763889832759, 5.2580303997189048],
					[63.407638829896101, 5.2580304286940232],
					[63.407638730849378, 5.2580305238082543],
					[63.407638700234138, 5.2580305899473503],
					[63.407610907624473, 5.2586081252977968],
					[63.407430733156808, 5.2586330807134285],
					[63.407241893835966, 5.2581138484671071],
					[63.407027998826401, 5.2569332750177384],
					[63.406983499151728, 5.2563860834802831],
					[63.406983495941184, 5.2563860638531175],
					[63.406983485094919, 5.2563860271810245],
					[63.406983478302301, 5.2563860080214146],
					[63.406755825756782, 5.2559444013068122],
					[63.406997375639442, 5.2554972942073519],
					[63.406997384966076, 5.2554972729434377],
					[63.406997397141062, 5.2554972232429114],
					[63.406997400094234, 5.2554971988018586],
					[63.406983499685133, 5.2528583544060581],
					[63.407225092733633, 5.2524502355295066],
					[63.407402743217901, 5.2525029910425634],
					[63.407402814068625, 5.2525029857941909],
					[63.407402933914391, 5.2525029300414152],
					[63.407402983804928, 5.2525028794200184],
					[63.407627927358917, 5.2519780098862245],
					[63.407913884848725, 5.2520001987140725],
					[63.407913944690947, 5.2520001848795035],
					[63.407914044625279, 5.2520001237068952],
					[63.40791408471739, 5.2520000763688719],
					[63.408100185155014, 5.2515500761141691],
					[63.408100190951409, 5.2515500573141969],
					[63.408100197961758, 5.2515500163037752],
					[63.40810020017615, 5.2515499979719973],
					[63.408091899919356, 5.2509278254023686],
					[63.408358493170596, 5.2499389514758139],
					[63.40835849622777, 5.2499389310287787],
					[63.408358498760073, 5.2499388906031168],
					[63.408358498235209, 5.2499388706244696],
					[63.408294597714246, 5.2495082720010897],
					[63.408294576648132, 5.2495082226331844],
					[63.408294514391535, 5.2495081445716467],
					[63.408294472253033, 5.2495081139972735],
					[63.408044562346454, 5.2494109487254024],
					[63.407872393554207, 5.2488555488422479],
					[63.407672395383763, 5.247947156881164],
					[63.40767236894402, 5.2479471082184173],
					[63.407672295992946, 5.2479470335656435],
					[63.407672247638054, 5.2479470058121072],
					[63.407439023634666, 5.2478887245463186],
					[63.407194566362172, 5.2475220892172834],
					[63.407194528648283, 5.2475220560633531],
					[63.407194442104071, 5.2475220112564038],
					[63.407194394169267, 5.2475219994862554],
					[63.406511251598992, 5.2475441957354718],
					[63.406341892609014, 5.2469138471901253],
					[63.406341858004318, 5.2469137955897382],
					[63.406341764403429, 5.2469137216404222],
					[63.406341705407279, 5.2469136992914507],
					[63.404922328820547, 5.2468804035612413],
					[63.404733481075112, 5.2464749163976832],
					[63.404733452211062, 5.2464748780807833],
					[63.404733379784616, 5.2464748234152054],
					[63.404733337012644, 5.2464748029541424],
					[63.404016849635248, 5.246341524457149],
					[63.403861289432065, 5.2459027329688235],
					[63.403861273420276, 5.2459027049980866],
					[63.403861234337654, 5.245902653989317],
					[63.403861210423884, 5.2459026330660183],
					[63.403594536575525, 5.2457276509908635],
					[63.403366902233763, 5.2454388325374612],
					[63.403400199409553, 5.2443944415497636],
					[63.40364458510043, 5.2437917744882103],
					[63.403644590948389, 5.2437917576871662],
					[63.403644598009116, 5.2437917186788665],
					[63.403644600169947, 5.243791698351842],
					[63.403641900214367, 5.2427471986298624],
					[63.403641896949388, 5.242747177009039],
					[63.403641886837441, 5.2427471342365743],
					[63.403641879990445, 5.242747113084941],
					[63.40204187956946, 5.2394416133178616],
					[63.402041849860716, 5.2394415771263905],
					[63.402041778327188, 5.2394415223661648],
					[63.402041734711432, 5.2394415040321878],
					[63.401630730930847, 5.2393692190978243],
					[63.401419576761292, 5.2389666072806085],
					[63.401419554427001, 5.2389665781405954],
					[63.40141949743191, 5.2389665294936636],
					[63.401419463719265, 5.2389665118666882],
					[63.401116848217903, 5.2388637383588899],
					[63.400925182841952, 5.2384221190228217],
					[63.400925155872109, 5.2384220844788478],
					[63.400925087814926, 5.2384220252589051],
					[63.400925046885554, 5.2384220065748854],
					[63.400694524636457, 5.2383665244578266],
					[63.400430767870418, 5.2379581901846679],
					[63.400430743850237, 5.2379581652758676],
					[63.40043068796092, 5.2379581245042584],
					[63.400430656091814, 5.2379581086414815],
					[63.400072326975057, 5.2378526280688904],
					[63.399775166941822, 5.2374026906788336],
					[63.399775110104201, 5.237402648029426],
					[63.39977498341743, 5.237402614540656],
					[63.399774912567445, 5.2374026198242865],
					[63.399244429950272, 5.2376580636643784],
					[63.398808442626361, 5.2372887591785213],
					[63.398414084014235, 5.2368110094874032],
					[63.39815019472821, 5.2356638551943497],
					[63.398150186036531, 5.2356638322863027],
					[63.398150164175668, 5.2356637870576401],
					[63.398150150216338, 5.2356637688487906],
					[63.397697350953969, 5.2351498683140791],
					[63.397264058599525, 5.2346387782816732],
					[63.396750170623832, 5.2339054956951045],
					[63.396458475712521, 5.2333638053464631],
					[63.396458457064575, 5.2333637797395518],
					[63.396458411024504, 5.2333637376892295],
					[63.3964583853707, 5.233363719013731],
					[63.396119484783156, 5.2332054181655732],
					[63.396119480252977, 5.2332054167563786],
					[63.396119470244344, 5.2332054120585676],
					[63.396119464818611, 5.2332054107669386],
					[63.395636165185337, 5.2330387111636263],
					[63.395636104923362, 5.2330387090553803],
					[63.395635994356027, 5.2330387416051964],
					[63.395635943997959, 5.2330387742662943],
					[63.39514711027666, 5.2336497925304117],
					[63.394344415118823, 5.2335887007656439],
					[63.394344396260642, 5.2335887012370375],
					[63.394344357701449, 5.2335887042942151],
					[63.394344338948713, 5.2335887087593038],
					[63.393577858762228, 5.2338330719419988],
					[63.393180746409094, 5.2334109623220764],
					[63.393180682722644, 5.2334109326108802],
					[63.393180551189097, 5.233410919819419],
					[63.393180483342014, 5.233410936739169],
					[63.392611055298481, 5.2338191864627719],
					[63.392325146668981, 5.2337581262087962],
					[63.392169592161345, 5.2333582409658534],
					[63.391936294837784, 5.2323332556938214],
					[63.391936259174336, 5.232333198264028],
					[63.391936160087887, 5.2323331211100461],
					[63.391936095716623, 5.2323330995067234],
					[63.391522323196597, 5.2323414973036506],
					[63.391261279304338, 5.2318166116367184],
					[63.391261186170688, 5.2318165557437917],
					[63.391261004805536, 5.2318165615242007],
					[63.391260915678608, 5.231816623315126],
					[63.391055460256212, 5.2323054171747101],
					[63.390874973414725, 5.232330401104667],
					[63.390874927217915, 5.2323304211938773],
					[63.390874848731244, 5.2323304775784347],
					[63.390874818337863, 5.2323305176318762],
					[63.390652716969271, 5.2328164009482938],
					[63.390458413152622, 5.2326831351158978],
					[63.390458329185876, 5.2326831200919273],
					[63.390458181165776, 5.2326831635631494],
					[63.390458116164169, 5.2326832201793296],
					[63.390213772789338, 5.2332469889121995],
					[63.38905012093953, 5.233155408330207],
					[63.387914074368055, 5.2311693016584755],
					[63.387914037440609, 5.2311692644371846],
					[63.387913948942398, 5.2311692139538444],
					[63.387913898267129, 5.2311692005742012],
					[63.385722198265007, 5.2311914994637947],
					[63.385722142165072, 5.2311915188479414],
					[63.385722050351823, 5.231191580980993],
					[63.385722012952918, 5.2311916279575472],
					[63.38506656280159, 5.2328858998640815],
					[63.384547321184947, 5.2328831014143447],
					[63.383914076912028, 5.2316721067726029],
					[63.383914039036533, 5.2316720676770911],
					[63.383913946851045, 5.2316720136767296],
					[63.38391389259381, 5.2316720007679969],
					[63.382550094318745, 5.2317219952788774],
					[63.382125178873856, 5.2311832030808416],
					[63.381955789635199, 5.2306666371825621],
					[63.381955755130427, 5.2306665896367681],
					[63.381955661627138, 5.2306665197928064],
					[63.381955605367843, 5.2306664991379161],
					[63.379027804623448, 5.2305942007457134],
					[63.379027750209069, 5.2305942158994139],
					[63.379027655551134, 5.2305942723845087],
					[63.379027618046869, 5.2305943153590206],
					[63.378527618193424, 5.2316805167464349],
					[63.378527612399488, 5.2316805355307876],
					[63.37852760270809, 5.2316805768559593],
					[63.37852759960068, 5.231680595287747],
					[63.37851920101577, 5.2321443618696915],
					[63.378313760868622, 5.2326692053321056],
					[63.377991840067509, 5.2326830948620255],
					[63.377425189257671, 5.2310527332049226],
					[63.377425184569226, 5.2310527258098869],
					[63.37742517782609, 5.2310527086715313],
					[63.377425173084944, 5.2310526992808049],
					[63.377214099910283, 5.2306860450937229],
					[63.377203000343805, 5.2291361398069913],
					[63.377425184375213, 5.228611178116445],
					[63.377425190168744, 5.228611159332214],
					[63.377425198068266, 5.2286111182428661],
					[63.377425200279816, 5.2286110999290489],
					[63.377394600042912, 5.2225249997129302],
					[63.377394562003339, 5.2225249206073476],
					[63.377394438152244, 5.2225248247530338],
					[63.377394353183369, 5.2225248058908411],
					[63.37662775295599, 5.2227109064266042],
					[63.376627729778953, 5.2227109134801637],
					[63.376627688166572, 5.2227109369763731],
					[63.376627667834597, 5.2227109496633801],
					[63.376063768150942, 5.223208149675326],
					[63.376063754193041, 5.223208165528475],
					[63.376063729016508, 5.223208198877062],
					[63.376063718693359, 5.2232082162547124],
					[63.375660919944657, 5.2240777124985875],
					[63.375427621998497, 5.2245388108512802],
					[63.375427616836838, 5.224538819539668],
					[63.375427609305703, 5.2245388405543345],
					[63.375427605987852, 5.2245388510028103],
					[63.375280412104786, 5.2251277282936144],
					[63.374938722585966, 5.2257832087064546],
					[63.374938718372533, 5.2257832192722891],
					[63.37493870910302, 5.2257832425171209],
					[63.374938704942402, 5.2257832550784391],
					[63.374744218195957, 5.2266220989292069],
					[63.37430821768514, 5.2270526005955231],
					[63.373822403692841, 5.2270525993910271],
					[63.37383079996556, 5.2266278035292615],
					[63.373830799912767, 5.2266278015338541],
					[63.373830799701572, 5.2266277935522183],
					[63.373830799595979, 5.2266277895613822],
					[63.373802999859763, 5.2260443896640281],
					[63.373802995696948, 5.2260443681851871],
					[63.373802984737587, 5.2260443275759147],
					[63.373802977941082, 5.226044308445478],
					[63.373594601937214, 5.2256416551101994],
					[63.373622400174121, 5.2249472082445747],
					[63.373622400068484, 5.2249472042537723],
					[63.373622399910019, 5.2249471982676017],
					[63.373622399804368, 5.2249471942768224],
					[63.373611300278768, 5.224519394351181],
					[63.373611292111157, 5.2245193573804096],
					[63.373611263344628, 5.2245192890776515],
					[63.373611241007595, 5.2245192599764554],
					[63.373150140885365, 5.2240581597034597],
					[63.373150108856343, 5.2240581378841098],
					[63.373150038846802, 5.2240581070416665],
					[63.373150000919132, 5.2240581000139121],
					[63.371588901224428, 5.2240526006659014],
					[63.371588846810745, 5.2240526158275626],
					[63.371588755790192, 5.2240526738430884],
					[63.371588719130507, 5.2240527147016671],
					[63.370677618914563, 5.2259971141004415],
					[63.370677612278662, 5.2259971349935705],
					[63.370677601746102, 5.2259971784220731],
					[63.370677599640501, 5.2259972007221345],
					[63.370685899746462, 5.230102749868168],
					[63.370463724335352, 5.2305110046526417],
					[63.370463721754327, 5.2305110089955384],
					[63.370463718383313, 5.2305110174463287],
					[63.370463715802302, 5.2305110217892228],
					[63.370258157437547, 5.2309998243346802],
					[63.369972334369457, 5.2310553696506528],
					[63.369800190422147, 5.2305388375884236],
					[63.369800188578417, 5.2305388358282929],
					[63.369800187630155, 5.2305388339506615],
					[63.369800187577425, 5.2305388319555242],
					[63.369594588059151, 5.2299777302723554],
					[63.369594584266174, 5.2299777227619311],
					[63.369594576680207, 5.2299777077410994],
					[63.369594572887223, 5.2299777002306724],
					[63.369314073080226, 5.2294915993170505],
					[63.369314039839956, 5.2294915656412915],
					[63.369313960348961, 5.2294915160136766],
					[63.369313914098242, 5.2294915000618003],
					[63.369044531319027, 5.2294720093439411],
					[63.368836280816559, 5.2290305144107325],
					[63.368836241939377, 5.229030471465995],
					[63.368836145169986, 5.2290304140984558],
					[63.368836088173246, 5.2290303995581242],
					[63.367972329176617, 5.229080391128516],
					[63.367714084838006, 5.2284777230572903],
					[63.367541885945556, 5.2280388268510647],
					[63.367541846962553, 5.2280387799196015],
					[63.367541748243461, 5.228038716808066],
					[63.36754168761189, 5.2280387007455813],
					[63.367272323313273, 5.2280553919960981],
					[63.367027980926167, 5.2275332147451969],
					[63.367027944787843, 5.2275331734473047],
					[63.367027857921101, 5.2275331167897656],
					[63.367027806244444, 5.22753309955276],
					[63.363505606358977, 5.2274358993609491],
					[63.3635055510493, 5.227435914629023],
					[63.363505459026044, 5.2274359687448912],
					[63.363505420627042, 5.2274360118172121],
					[63.363258120563785, 5.2279305110671279],
					[63.363258119721038, 5.2279305131793627],
					[63.363258117192878, 5.2279305195160717],
					[63.363258114611924, 5.2279305238580891],
					[63.363049815183388, 5.2284305238997888],
					[63.363049808547082, 5.2284305447867681],
					[63.363049801701258, 5.2284305917219704],
					[63.363049799595196, 5.2284306140159371],
					[63.363083094903175, 5.2289055282536063],
					[63.362616550855499, 5.2294248662092482],
					[63.362616541321529, 5.229424879469625],
					[63.362616524097376, 5.2294249077500234],
					[63.362616516407165, 5.22942492277003],
					[63.362369216082044, 5.2300055211907477],
					[63.362205464961733, 5.2303776106966966],
					[63.361927784011158, 5.230399801531969],
					[63.361927734126844, 5.2303998180832139],
					[63.361927650372827, 5.2303998791076438],
					[63.361927616450373, 5.2303999215862591],
					[63.361288773314996, 5.2318941876214886],
					[63.361127817409056, 5.2318804008288895],
					[63.361127761097926, 5.2318804122153697],
					[63.361127663647117, 5.2318804650222965],
					[63.361127624298462, 5.2318805062079408],
					[63.360863778706062, 5.2323748015822265],
					[63.36042499630917, 5.2323830992256486],
					[63.360424942842585, 5.2323831162421062],
					[63.360424853661918, 5.2323831759673594],
					[63.360424817895158, 5.2323832166816597],
					[63.360158176389177, 5.2329636909336958],
					[63.359483434835084, 5.2329109093770674],
					[63.359269583341934, 5.232422119514081],
					[63.359269548047813, 5.2324220761089961],
					[63.359269461130225, 5.2324220174567291],
					[63.359269409454086, 5.2324220002151094],
					[63.357941709255272, 5.2323636997395315],
					[63.357941682390226, 5.2323637032611545],
					[63.357941629661006, 5.2323637141756754],
					[63.357941604745072, 5.2323637234454834],
					[63.356136004580584, 5.2333359236865791],
					[63.356135974713105, 5.2333359496126262],
					[63.356135925934957, 5.2333360080333469],
					[63.35613590802523, 5.2333360443990689],
					[63.355841508118665, 5.2343416448799083],
					[63.355841504167984, 5.2343416654082944],
					[63.355841501797606, 5.2343417117437809],
					[63.355841503272622, 5.2343417335624753],
					[63.355916490793213, 5.234777766537511],
					[63.355627624344422, 5.2353110051997742],
					[63.355627620867743, 5.2353110096572424],
					[63.355627615810725, 5.2353110223259849],
					[63.355627613282216, 5.2353110286603375],
					[63.355483146278523, 5.2356859435259508],
					[63.355202730406901, 5.2357915128739148],
					[63.355202694165094, 5.2357915356291391],
					[63.355202634639852, 5.2357915954509817],
					[63.355202612199236, 5.2357916304061964],
					[63.354955478344827, 5.2364580536384269],
					[63.354580657581167, 5.2363442096725796],
					[63.3545806376694, 5.2363442042755954],
					[63.354580598056486, 5.2363442014580368],
					[63.354580577354497, 5.2363442001665144],
					[63.354361077099718, 5.236369200713936],
					[63.354361046810418, 5.2363692106825184],
					[63.354360989076355, 5.2363692362501677],
					[63.354360962579676, 5.2363692537261191],
					[63.353855677893847, 5.2368440515866208],
					[63.353664098731798, 5.2363582588864608],
					[63.353650199686683, 5.2359305930951594],
					[63.353650187782129, 5.2359305506334497],
					[63.353650148011866, 5.2359304738027088],
					[63.353650118355127, 5.23593043966821],
					[63.353452918403804, 5.2357859381952894],
					[63.353452837123605, 5.2357859228262438],
					[63.353452688838161, 5.2357859562492459],
					[63.353452622781028, 5.2357860069180244],
					[63.353147022656835, 5.2363610070195277],
					[63.353147021761359, 5.2363610071367646],
					[63.35314702007561, 5.2363610113592589],
					[63.353147019285437, 5.2363610154644897],
					[63.351955419331887, 5.2388832159321836],
					[63.351955411851065, 5.2388832389197368],
					[63.351955402262533, 5.2388832841919148],
					[63.351955400207387, 5.238883308470462],
					[63.352013698041794, 5.2402166630726548],
					[63.351785915735938, 5.2407555215784249],
					[63.351785909940688, 5.2407555403433435],
					[63.351785901932168, 5.2407555774047916],
					[63.351785899771549, 5.2407555976952445],
					[63.351777600732895, 5.2413110623767869],
					[63.351572013778593, 5.2418527241980737],
					[63.351360964253658, 5.2423498087345353],
					[63.35042792593827, 5.2424053929353045],
					[63.349966899044425, 5.2413832547719101],
					[63.349944605327273, 5.2403113599123134],
					[63.35018054974968, 5.2403723942325842],
					[63.350180636456535, 5.2403723768925801],
					[63.350180760619892, 5.2403722806294706],
					[63.350180799920025, 5.2403722034659506],
					[63.350202998752309, 5.2389167436900905],
					[63.350472383176609, 5.2382972805965036],
					[63.350472388971568, 5.2382972618319616],
					[63.350472397822315, 5.2382972226605764],
					[63.350472399982621, 5.2382972023708749],
					[63.350497400052504, 5.2368000028453796],
					[63.350497399789283, 5.2367999928762856],
					[63.350497397366503, 5.2367999691848963],
					[63.350497396155141, 5.2367999573391959],
					[63.350291896422426, 5.2358388572735759],
					[63.350291862657073, 5.2358388036739614],
					[63.350291773423272, 5.2358387253251921],
					[63.35029171432015, 5.2358386990511807],
					[63.350094500244118, 5.235824806704418],
					[63.349644554563923, 5.2352748723576612],
					[63.349644463801951, 5.2352748382267222],
					[63.349644294551211, 5.2352748603878414],
					[63.349644217063286, 5.2352749205501485],
					[63.34919428233772, 5.2363108690337201],
					[63.34889444190383, 5.2362470042324958],
					[63.34889438627706, 5.2362470075127634],
					[63.348894285716533, 5.2362470446831511],
					[63.348894241678309, 5.2362470784560493],
					[63.3484277571579, 5.2368525272297299],
					[63.348214013352667, 5.2367054345049775],
					[63.348213925856655, 5.2367054219497318],
					[63.348213775305439, 5.236705471661895],
					[63.348213712197598, 5.2367055319356668],
					[63.348002663889851, 5.2372886899789055],
					[63.347150095414868, 5.2372220064507529],
					[63.346214048628028, 5.2361804667426632],
					[63.34621403572234, 5.2361804544289345],
					[63.346214008172659, 5.2361804320294425],
					[63.346213993581252, 5.2361804239371832],
					[63.345658403110711, 5.235885928546355],
					[63.344822312429599, 5.2353192343137804],
					[63.344822306055704, 5.2353192311475469],
					[63.344822295098986, 5.2353192245805937],
					[63.344822288725105, 5.2353192214143593],
					[63.343313988986289, 5.2345748219297468],
					[63.343313927617793, 5.2345748119624931],
					[63.343313812202766, 5.2345748310736075],
					[63.343313757260745, 5.2345748602692179],
					[63.342510957228569, 5.2353915608205606],
					[63.342510946745804, 5.2353915721934454],
					[63.342510927676564, 5.2353915986913107],
					[63.342510919985621, 5.2353916136990737],
					[63.341869220365282, 5.2367221130736228],
					[63.341869212779478, 5.2367221320671309],
					[63.341869203033582, 5.2367221713443755],
					[63.341869199977964, 5.2367221917452671],
					[63.341783099879066, 5.2387221919313669],
					[63.341783100142138, 5.2387222018974668],
					[63.341783101616421, 5.2387222237057713],
					[63.341783102774983, 5.2387222335547641],
					[63.341935897765651, 5.2396111021766272],
					[63.34181134922072, 5.24047746428539],
					[63.341830799727958, 5.2392278024768641],
					[63.34183077460343, 5.2392277357584183],
					[63.341830685635749, 5.2392276333854566],
					[63.341830552310931, 5.239227620816707],
					[63.341830442950901, 5.2392276991192519],
					[63.341830404125886, 5.2392277601986716],
					[63.34140263922469, 5.2413247860722416],
					[63.34039441508849, 5.2412470002064611],
					[63.340394358828853, 5.2412470135582279],
					[63.340394263164917, 5.2412470660591532],
					[63.340394222917695, 5.2412471073184923],
					[63.339577624602768, 5.2428138029528384],
					[63.338710929268316, 5.2442526978115964],
					[63.338710919837908, 5.2442527150418812],
					[63.338710905664833, 5.244252756889896],
					[63.338710900817084, 5.2442527775216146],
					[63.338513701007663, 5.2459916772529578],
					[63.33851370274337, 5.2459917090242518],
					[63.338513716065464, 5.2459917712813313],
					[63.338513726756339, 5.2459918018839815],
					[63.339493983830693, 5.2476774822125467],
					[63.339275050680612, 5.2476192067407501],
					[63.339274982568575, 5.2476192136258533],
					[63.339274866305139, 5.247619268790344],
					[63.339274818153768, 5.2476193170697298],
					[63.339027631103534, 5.2481581891503462],
					[63.338561011924178, 5.2486775981704659],
					[63.337925003316812, 5.2486691997976989],
					[63.337924971973564, 5.2486692038849458],
					[63.337924914027028, 5.248669221440406],
					[63.337924887423711, 5.2486692349086415],
					[63.337058186662212, 5.2492665344243985],
					[63.337058164746331, 5.2492665552795428],
					[63.337058128236144, 5.2492666020345302],
					[63.337058113641831, 5.2492666279343725],
					[63.336585914340453, 5.250483228820074],
					[63.336585910125095, 5.2504832393680827],
					[63.336585903537809, 5.2504832622235629],
					[63.336585902061437, 5.2504832744143144],
					[63.336394202002069, 5.2519721731069593],
					[63.33639420126385, 5.2519721792022445],
					[63.336394199682495, 5.2519721874070955],
					[63.336394199787335, 5.251972191392845],
					[63.336302600692797, 5.254222180012575],
					[63.335927604017236, 5.2565777609942312],
					[63.334677605625586, 5.2617332530840581],
					[63.334677603932448, 5.2617332912959665],
					[63.334677613926971, 5.2617333639816266],
					[63.334677626562438, 5.2617334003318375],
					[63.334910902725973, 5.2621389594306214],
					[63.33494149704925, 5.2626555544552698],
					[63.334697024128417, 5.263108205109158],
					[63.334697023285159, 5.2631082072182513],
					[63.334697019859846, 5.263108213661849],
					[63.33469701812102, 5.2631082158872573],
					[63.334488943122274, 5.263560528883394],
					[63.334300191734492, 5.2629055450094882],
					[63.334300100880895, 5.2629054728291527],
					[63.334299911929563, 5.26290546337896],
					[63.334299813831777, 5.2629055261091517],
					[63.333616898996119, 5.2646489431066268],
					[63.333611300082111, 5.2600249998387696],
					[63.333611296767742, 5.2600249762755524],
					[63.333611285713687, 5.2600249317238132],
					[63.333611277921683, 5.2600249087426079],
					[63.333380799072202, 5.2595777491278497],
					[63.333350201079021, 5.2577333376797464],
					[63.333597386428188, 5.2571111742631498],
					[63.333597391382526, 5.2571111576226137],
					[63.333597397761494, 5.2571111268002433],
					[63.333597400029241, 5.2571111105091912],
					[63.333625200390095, 5.2566000110387723],
					[63.333625197970726, 5.2565999873594436],
					[63.333625189602202, 5.2565999424595198],
					[63.333625183653062, 5.2565999212389238],
					[63.33295298441962, 5.255047120278685],
					[63.332952972253921, 5.2550471018674374],
					[63.332952945183571, 5.2550470634019657],
					[63.332952928592633, 5.2550470475661317],
					[63.332430728785283, 5.2546081467386001],
					[63.332430634864522, 5.2546081289723263],
					[63.332430475298345, 5.2546081777341769],
					[63.332430407861885, 5.2546082444954134],
					[63.331308108174341, 5.2585249442784683],
					[63.331308107331125, 5.2585249463874453],
					[63.33130810569704, 5.2585249525979521],
					[63.331308104853868, 5.2585249547069246],
					[63.331077610336322, 5.2595166343625515],
					[63.330644219449205, 5.2604332152859374],
					[63.33064421191245, 5.2604332362583106],
					[63.330644202316726, 5.2604332814897941],
					[63.330644200205519, 5.2604333037564031],
					[63.330655398802804, 5.2609916574040358],
					[63.330222018771359, 5.2618999131607751],
					[63.330222012182212, 5.2618999360085992],
					[63.330222001795086, 5.2618999853402224],
					[63.330221999735876, 5.2619000095988797],
					[63.330252597138141, 5.2625443390760882],
					[63.329985939102095, 5.2629081810629801],
					[63.329985919706985, 5.2629082295643252],
					[63.329985908835177, 5.2629083289392407],
					[63.329985917410802, 5.2629083818052589],
					[63.330199800804891, 5.2633944444934313],
					[63.330221999255166, 5.2645527452455863],
					[63.330019230379499, 5.2648804959223856],
					[63.330019219364388, 5.2648805213430387],
					[63.330019203707458, 5.2648805753555843],
					[63.330019199961171, 5.2648806038312825],
					[63.330027599350338, 5.2654360458879435],
					[63.32969982964714, 5.2659692944365313],
					[63.329699822005104, 5.2659693114218991],
					[63.32969980940765, 5.2659693450440228],
					[63.329699803556693, 5.2659693617969738],
					[63.329505405855777, 5.2669805488915342],
					[63.329316510927292, 5.267522133126592],
					[63.329316506867208, 5.2675221496467328],
					[63.329316502224849, 5.2675221782375345],
					[63.329316499955866, 5.2675221945253758],
					[63.329269200306562, 5.2693166959344566],
					[63.329269204723651, 5.269316727348861],
					[63.329269225252034, 5.2693167906608922],
					[63.329269240415634, 5.2693168206821843],
					[63.329541497803305, 5.269680664127379],
					[63.329499799809895, 5.2711332939200686],
					[63.329499805174279, 5.2711333272114205],
					[63.329499823858619, 5.2711333887649108],
					[63.329499838126317, 5.2711334189034442],
					[63.3297330840306, 5.2714528436624164],
					[63.329252620911213, 5.2734831876755628],
					[63.328638902450777, 5.273983040174917],
					[63.328153004594775, 5.2735721113976508],
					[63.328183499934937, 5.2729750097516437],
					[63.328183491571593, 5.2729749648552264],
					[63.328183456194779, 5.2729748834749044],
					[63.32818342918128, 5.2729748469910023],
					[63.326397329141578, 5.2714637466549279],
					[63.326397298119375, 5.2714637286847932],
					[63.326397231014518, 5.2714637053939848],
					[63.326397195827461, 5.2714636999572901],
					[63.325980810028511, 5.2714719967532542],
					[63.326005799905481, 5.2709611099786331],
					[63.326005769109564, 5.2709610320111633],
					[63.326005661253461, 5.2709609280462235],
					[63.326005583245518, 5.2709609001725832],
					[63.325750137854584, 5.2709830866835059],
					[63.325539089077679, 5.2703693352147436],
					[63.325539001070901, 5.2703692686601622],
					[63.325538817388477, 5.2703692544814285],
					[63.325538720921656, 5.2703693109576433],
					[63.325272080347894, 5.2709025925693718],
					[63.325133311575122, 5.2708941993527958],
					[63.325133240348684, 5.2708942245717667],
					[63.325133134073901, 5.2708943182962065],
					[63.325133099921068, 5.270894386685657],
					[63.325055401764608, 5.2719999638798845],
					[63.324891573945884, 5.2725441251518461],
					[63.324655701701012, 5.2724387268879607],
					[63.324083419867442, 5.272008140226613],
					[63.324083331373842, 5.2720081237039551],
					[63.324083177174103, 5.2720081716580562],
					[63.32408311136372, 5.2720082321506521],
					[63.323935948686426, 5.2724248272621992],
					[63.323499957832617, 5.2725192041002593],
					[63.323499902090838, 5.2725192373024239],
					[63.323499823681146, 5.2725193314045722],
					[63.323499800117638, 5.2725193924205049],
					[63.323463706804219, 5.2733720304890594],
					[63.322766666879097, 5.2734887039684724],
					[63.322766605763761, 5.2734887378634809],
					[63.322766523084098, 5.2734888405081266],
					[63.322766499676568, 5.2734889074976605],
					[63.32282199819435, 5.2749916403464638],
					[63.322597036099801, 5.2753165862021278],
					[63.322597029143736, 5.2753165950970233],
					[63.32259701802252, 5.2753166165231358],
					[63.322597013805229, 5.2753166270623826],
					[63.322372013971851, 5.2758971283945559],
					[63.32237200901524, 5.2758971450253833],
					[63.32237200189283, 5.2758971819233746],
					[63.322371999622845, 5.2758971982066249],
					[63.322358099720823, 5.2778666463156441],
					[63.322010926249661, 5.278480502132747],
					[63.322010916127581, 5.2784805274253204],
					[63.322010903152027, 5.2784805810681794],
					[63.32201090029848, 5.2784806094184766],
					[63.32205538169697, 5.2794246289583953],
					[63.321900106376802, 5.2793276291354028],
					[63.321900004762803, 5.2793276262790734],
					[63.32189985244456, 5.2793277119211002],
					[63.321899799845184, 5.2793277966670722],
					[63.321891502617397, 5.2798414837686041],
					[63.321689027090578, 5.2798248095953939],
					[63.321458478207049, 5.2793777085670763],
					[63.321458386934317, 5.2793776544082585],
					[63.321458208198479, 5.2793776575216507],
					[63.321458119839797, 5.2793777149096028],
					[63.320985919814078, 5.2803666138804166],
					[63.320985914752903, 5.2803666265259173],
					[63.320985907317159, 5.2803666514698016],
					[63.320985903099491, 5.280366662007725],
					[63.320902633387121, 5.2808137051612132],
					[63.319391693296126, 5.2808637002548764],
					[63.319391646039691, 5.2808637143532264],
					[63.31939156284561, 5.2808637630711344],
					[63.319391526960011, 5.280863799682475],
					[63.319133182643874, 5.2813054042915537],
					[63.317974993915421, 5.2813414997695851],
					[63.317974936068239, 5.2813415212293346],
					[63.317974844438474, 5.2813415910166901],
					[63.317974808916787, 5.2813416415672689],
					[63.317810926281247, 5.2818748848363191],
					[63.317397070884162, 5.2822220468146259],
					[63.317397056867186, 5.2822220606145356],
					[63.317397031675938, 5.2822220938423614],
					[63.317397021293182, 5.2822221091713715],
					[63.317130455244921, 5.2827498441622591],
					[63.316874923972442, 5.282855414877119],
					[63.316874875652424, 5.2828554570844233],
					[63.316874813667461, 5.2828555610050447],
					[63.316874800898134, 5.2828556226027459],
					[63.316944194206293, 5.2834527588437989],
					[63.316722024594291, 5.2838555037217496],
					[63.316722016158408, 5.2838555247934309],
					[63.316722003816416, 5.2838555683504875],
					[63.3167219999103, 5.2838555908358638],
					[63.316696999666839, 5.2843999914872777],
					[63.316697006027532, 5.2844000286341144],
					[63.316697032182326, 5.2844001011943504],
					[63.316697051924486, 5.2844001346161624],
					[63.316944204379539, 5.2846722814207254],
					[63.316974800300109, 5.2852666984785506],
					[63.316896999997681, 5.286397185779518],
					[63.316897000101562, 5.2863971897627442],
					[63.316897000361237, 5.2863971997207839],
					[63.316897000465111, 5.2863972037039915],
					[63.316916497328229, 5.2878330678728176],
					[63.316288929960791, 5.2877387019415591],
					[63.316288849671515, 5.2877387242819491],
					[63.316288734585065, 5.287738825044042],
					[63.316288699735985, 5.2877389014741594],
					[63.316291500117678, 5.2887082546102233],
					[63.316030451955186, 5.2892359468917407],
					[63.315791617292589, 5.2893442173156995],
					[63.315791574292085, 5.289344256826582],
					[63.315791514729526, 5.2893443504255133],
					[63.315791499958607, 5.2893444042827324],
					[63.315805398320528, 5.2898499428113874],
					[63.315544232608623, 5.2902498910781253],
					[63.315544219849599, 5.2902499186985334],
					[63.315544204234669, 5.2902499746617444],
					[63.315544199587585, 5.290250003235295],
					[63.315555398976727, 5.2908416422373099],
					[63.315294233441783, 5.2912415916559397],
					[63.315294218995142, 5.2912416234893485],
					[63.315294202795435, 5.2912416915159319],
					[63.315294201886061, 5.2912417256022417],
					[63.315360894141492, 5.2917527660396315],
					[63.315119220717854, 5.2922416108830426],
					[63.315119213176288, 5.2922416318358101],
					[63.31511920341471, 5.2922416710579725],
					[63.315119200350956, 5.292241691434195],
					[63.315091503594346, 5.2929110044771184],
					[63.314635970384558, 5.293302647195504],
					[63.314635941763832, 5.2933026868459532],
					[63.314635907962767, 5.2933027691241268],
					[63.314635901094931, 5.2933028159653288],
					[63.314669196814819, 5.2937332698787332],
					[63.314441514352595, 5.2943166266814972],
					[63.314441511821371, 5.2943166330015776],
					[63.314441507602631, 5.2943166435350673],
					[63.314441506862515, 5.2943166496246876],
					[63.314185906948524, 5.2952777499888413],
					[63.314185903625294, 5.2952777604068855],
					[63.314185900664718, 5.2952777847650099],
					[63.314185900080027, 5.2952777968288576],
					[63.314177599877389, 5.295919384955158],
					[63.313972107865482, 5.2974020106824096],
					[63.313783497609158, 5.2969166590207557],
					[63.313752999809012, 5.2963138895202926],
					[63.313752973023675, 5.2963138270286141],
					[63.313752886094548, 5.2963137323103142],
					[63.313752826002592, 5.2963137020751203],
					[63.312880626196659, 5.2961998013623512],
					[63.312880548592545, 5.296199823330733],
					[63.31288043603108, 5.2961999177272778],
					[63.312880400126332, 5.2961999882792679],
					[63.312852602274589, 5.2966721568280564],
					[63.312708113858719, 5.2970443284895721],
					[63.312708108795988, 5.2970443411285313],
					[63.31270810320018, 5.2970443678220107],
					[63.312708100875987, 5.2970443821068427],
					[63.312658101888886, 5.2975971668926976],
					[63.312380406100218, 5.2986860510379472],
					[63.312380403724106, 5.298686063331167],
					[63.312380400814725, 5.2986860896787435],
					[63.312380400229706, 5.2986861017417795],
					[63.312402599059141, 5.3012555142355806],
					[63.311938828386531, 5.3016886946349135],
					[63.311758314911806, 5.3016748004575271],
					[63.311758239253102, 5.3016748281541055],
					[63.311758131424334, 5.301674931906998],
					[63.311758100098146, 5.3016750058570459],
					[63.311771999023343, 5.3021471538469847],
					[63.311485924391945, 5.3026805052389223],
					[63.311485915109358, 5.302680528407536],
					[63.311485902968329, 5.3026805799138126],
					[63.311485900006453, 5.3026806042689278],
					[63.311505398598186, 5.3036666744540009],
					[63.31125540827594, 5.3045443443413989],
					[63.311255405055384, 5.3045443587398546],
					[63.311255401249319, 5.304544385200753],
					[63.311255399819913, 5.3045443993693766],
					[63.31124149975426, 5.3067693996548257],
					[63.311241501011409, 5.3067694134788175],
					[63.311241506367345, 5.3067694467560882],
					[63.311241509415666, 5.3067694603504219],
					[63.311521996217856, 5.307647219304795],
					[63.311455401182876, 5.3081971753288828],
					[63.311455401957311, 5.3081972051980975],
					[63.31145541241046, 5.3081972617972708],
					[63.311455421245135, 5.308197290633327],
					[63.311724799982265, 5.3087333484896959],
					[63.31173590017454, 5.3106166459634254],
					[63.311424826666034, 5.3111470996003103],
					[63.311424817382033, 5.3111471227661911],
					[63.31142480434233, 5.3111471743835956],
					[63.311424799639461, 5.3111472009585192],
					[63.311421999590593, 5.3126889007012563],
					[63.311422001897462, 5.3126889203848098],
					[63.31142201198783, 5.3126889630465932],
					[63.311422018824203, 5.3126889841481733],
					[63.311669204916228, 5.3132222545219809],
					[63.311705379922742, 5.3135248443587759],
					[63.311427754910298, 5.3135887048114174],
					[63.311427709699139, 5.3135887285790657],
					[63.311427637704227, 5.3135887937347235],
					[63.311427610024829, 5.3135888352373657],
					[63.3112609502517, 5.3140859187398721],
					[63.310861089254033, 5.3141415008109067],
					[63.310066705548408, 5.3141191992836712],
					[63.310066647592684, 5.3141192166889892],
					[63.310066553053012, 5.31411927873169],
					[63.310066514677914, 5.3141193235982591],
					[63.309674866244357, 5.3150719992846334],
					[63.3076057522836, 5.3150776006957248],
					[63.307472392790281, 5.3145971461866681],
					[63.30747236558117, 5.314597101724936],
					[63.307472292767812, 5.3145970311357242],
					[63.307472247163574, 5.3145970050082676],
					[63.307194447367756, 5.3145304058304488],
					[63.307194379251179, 5.3145304125473789],
					[63.307194263906467, 5.3145304692535342],
					[63.307194216678347, 5.3145305192427568],
					[63.306352664720947, 5.3164304100390867],
					[63.305277784139058, 5.3165165006843003],
					[63.305277723702211, 5.3165165263886047],
					[63.305277634205915, 5.3165166097397289],
					[63.305277603355286, 5.3165166676155424],
					[63.305188732395024, 5.3170636850048565],
					[63.30473061571729, 5.3170276020086593],
					[63.304730557606611, 5.3170276134320558],
					[63.30473046111976, 5.3170276697096686],
					[63.30473042090096, 5.3170277128020214],
					[63.304466581392745, 5.3175636909078445],
					[63.304225015965592, 5.3175442001563251],
					[63.30422494114751, 5.3175442256982057],
					[63.304224833152027, 5.3175443233916644],
					[63.304224800026169, 5.3175443975340775],
					[63.304219202340533, 5.3178860005005548],
					[63.304019277169367, 5.3180415419475526],
					[63.30401925186645, 5.3180415711469475],
					[63.304019214056339, 5.3180416378973705],
					[63.30401920244482, 5.318041675333915],
					[63.303883107076395, 5.3191416323729346],
					[63.303658183129357, 5.3195081082691846],
					[63.303377783763274, 5.3195304008963049],
					[63.303377733918566, 5.3195304192474078],
					[63.30337765012348, 5.3195304798844631],
					[63.303377616173016, 5.3195305221704468],
					[63.303149815928315, 5.3200666214972481],
					[63.303149808052986, 5.3200666644465278],
					[63.303149811109932, 5.3200667479429731],
					[63.303149821198026, 5.3200667905952637],
					[63.303688703749422, 5.3211361550076663],
					[63.303755399546134, 5.3219972096374288],
					[63.303766500358805, 5.3224278045938949],
					[63.303766522603297, 5.3224278656696571],
					[63.303766599663867, 5.3224279616928882],
					[63.303766655324068, 5.3224279945352535],
					[63.304044196197431, 5.3224918574770586],
					[63.303996999672641, 5.3242832945306287],
					[63.303997013650338, 5.3242833486734487],
					[63.303997069956615, 5.3242834413579807],
					[63.303997112285181, 5.3242834798996928],
					[63.304313811759982, 5.324439080525857],
					[63.304313822609984, 5.3244390831364283],
					[63.304313845308471, 5.3244390922249698],
					[63.304313856158529, 5.3244390948355349],
					[63.304908170175587, 5.3245723759665227],
					[63.305138726430272, 5.324975098680377],
					[63.305138744114814, 5.3249751223912343],
					[63.305138783756384, 5.3249751612781733],
					[63.305138808400223, 5.3249751761115265],
					[63.305335967584995, 5.325077956993991],
					[63.30564703181436, 5.3254890095330216],
					[63.305974798626622, 5.3260694514718523],
					[63.305958100186892, 5.3271388971514533],
					[63.305958104591106, 5.3271389285498341],
					[63.305958125093809, 5.3271389918531797],
					[63.305958140193944, 5.3271390198904518],
					[63.306230424230129, 5.3275028973627805],
					[63.306458111433663, 5.3280778677092338],
					[63.30691650320572, 5.3295250401507515],
					[63.306966501253505, 5.3300000214016601],
					[63.306966516763694, 5.3300000653695587],
					[63.306966567282096, 5.3300001428311736],
					[63.306966602239029, 5.3300001743338523],
					[63.307144225187884, 5.3301001306799503],
					[63.307344204631328, 5.3309722446099972],
					[63.307344233780562, 5.3309722948321552],
					[63.307344313904878, 5.3309723705232761],
					[63.307344364931261, 5.3309723979832651],
					[63.307802596566937, 5.3310529668737701],
					[63.307794200981334, 5.3315638471274402],
					[63.307530423960813, 5.3320582068364075],
					[63.307530413777741, 5.3320582301065258],
					[63.307530402572866, 5.3320582834706665],
					[63.307530399708561, 5.3320583118016813],
					[63.307544189715649, 5.3323165229809089],
					[63.30642777796664, 5.332438701099889],
					[63.306427729067046, 5.3324387213078035],
					[63.306427647057511, 5.3324387816861929],
					[63.30642761489441, 5.3324388237336251],
					[63.306241514909324, 5.3328943239729174],
					[63.306241509152862, 5.332894344680704],
					[63.306241502169293, 5.3328943875172294],
					[63.306241499995274, 5.3328944077690039],
					[63.306285897576444, 5.3339666606006642],
					[63.305727617657553, 5.3352055186735727],
					[63.305727611005167, 5.3352055394944617],
					[63.305727602229659, 5.335205582557542],
					[63.305727600157809, 5.3352056067906739],
					[63.30573869257671, 5.335591442399978],
					[63.305225047629435, 5.335463706180323],
					[63.305224991052, 5.3354637073844549],
					[63.305224887775758, 5.3354637444899113],
					[63.30522484187005, 5.3354637762955059],
					[63.304819242000917, 5.3359859777918413],
					[63.304819224064047, 5.3359860140287836],
					[63.304819204567629, 5.3359860944068851],
					[63.304819202956821, 5.335986136557163],
					[63.304891490793665, 5.3363777707026685],
					[63.304602674627283, 5.3369526022881084],
					[63.303730596228569, 5.336969199309296],
					[63.303730530465273, 5.3369692276433671],
					[63.303730433049701, 5.3369693179239484],
					[63.303730401448682, 5.3369693818612838],
					[63.303635905031186, 5.3379443400930198],
					[63.302816524131401, 5.3394610056798815],
					[63.30281651489387, 5.3394610308205319],
					[63.302816502688152, 5.3394610803053801],
					[63.302816499822335, 5.3394611086310571],
					[63.302844199603804, 5.340191708040182],
					[63.302844207689994, 5.3401917429637358],
					[63.302844233560599, 5.3401918055872306],
					[63.302844253187487, 5.3401918350503976],
					[63.303055403805395, 5.3404222816192854],
					[63.303085900291904, 5.3409806030504452],
					[63.303060901277973, 5.3425193458778875],
					[63.30287762485866, 5.3428471023811754],
					[63.302877615569379, 5.3428471255300529],
					[63.302877603362489, 5.342847175013592],
					[63.3028776003937, 5.3428471993574904],
					[63.302880399663294, 5.346355595827994],
					[63.302822011374253, 5.3475469702217158],
					[63.302208330157541, 5.3474554028343633],
					[63.30220824896201, 5.3474554251100779],
					[63.302208134726932, 5.3474555254705241],
					[63.302208099845046, 5.3474556017915882],
					[63.302213700027416, 5.3493361001174247],
					[63.302213724153873, 5.3493361649655737],
					[63.302213807568215, 5.3493362642434219],
					[63.302213867700686, 5.3493362965689153],
					[63.302749892292276, 5.3494223859474808],
					[63.303041502373311, 5.3497889719317433],
					[63.303055399982753, 5.3502611051872115],
					[63.303055411955249, 5.3502611516048972],
					[63.303055456244472, 5.3502612318770026],
					[63.303055487665567, 5.350261265844857],
					[63.303269233315611, 5.3504057286257636],
					[63.303422011295361, 5.3508472643067231],
					[63.303422032915442, 5.3508473015142215],
					[63.303422089232853, 5.3508473602919828],
					[63.303422124032245, 5.3508473858438759],
					[63.303677724046672, 5.3509529852947395],
					[63.303677792420672, 5.3509529886191078],
					[63.303677915405189, 5.3509529490757251],
					[63.303677969120088, 5.3509529063214032],
					[63.303961078246623, 5.3505060078170494],
					[63.304135920677844, 5.350861187565723],
					[63.304135933777978, 5.3508612078772124],
					[63.304135964405383, 5.3508612459421441],
					[63.304135980985997, 5.3508612618181379],
					[63.30433590221088, 5.3510084037966354],
					[63.30436919976988, 5.3524222044882039],
					[63.304369202020226, 5.3524222221794293],
					[63.304369209207806, 5.3524222572217317],
					[63.304369214094045, 5.3524222725819239],
					[63.304560909158333, 5.3529083613136059],
					[63.304822002189582, 5.353913931116983],
					[63.304855397391989, 5.3543860718865908],
					[63.304688712023747, 5.3548443310568441],
					[63.304688706977707, 5.3548443796320582],
					[63.304688721067755, 5.3548444737230234],
					[63.304688739308226, 5.354844519352068],
					[63.305058106365237, 5.3553444732212521],
					[63.305091496377209, 5.3557332664879658],
					[63.30482204576218, 5.3563498531411611],
					[63.304383210026053, 5.3565720225966968],
					[63.304383168746448, 5.3565720597742654],
					[63.304383113616709, 5.3565721526315482],
					[63.30438309971565, 5.3565722063203411],
					[63.304394197876377, 5.3569138566915386],
					[63.30416652063159, 5.3573666097469035],
					[63.304166513975872, 5.3573666305616445],
					[63.304166503453295, 5.3573666758331902],
					[63.304166500380155, 5.357366696194978],
					[63.304158100719931, 5.3579249582699049],
					[63.303910915815628, 5.3585027216782626],
					[63.303910907928639, 5.3585027646185281],
					[63.30391091096309, 5.3585028481219581],
					[63.30391092103978, 5.358502890789179],
					[63.304180420850379, 5.3590361897851233],
					[63.304180459638246, 5.359036230822011],
					[63.304180553620064, 5.3590362868546464],
					[63.304180608763119, 5.359036299859504],
					[63.305852611185607, 5.3589613091402173],
					[63.30592759831061, 5.3603360664948774],
					[63.305722015223772, 5.3608332243805616],
					[63.305722010257057, 5.3608332409873709],
					[63.305722002216754, 5.3608332779567327],
					[63.305721999987846, 5.3608332962151604],
					[63.305702600923247, 5.3619860596837121],
					[63.305516515222273, 5.3624416242692874],
					[63.305516509359748, 5.3624416409888047],
					[63.30551650221463, 5.3624416778443864],
					[63.305516499985522, 5.3624416961025458],
					[63.305499803426834, 5.3633387188652613],
					[63.304566679823672, 5.3634331011729746],
					[63.304566631764132, 5.3634331192230711],
					[63.304566550585626, 5.363433177405641],
					[63.304566518362279, 5.3634332174250705],
					[63.304380468875927, 5.363844204916342],
					[63.303663891892832, 5.3638720000135729],
					[63.303663841095471, 5.3638720164105447],
					[63.30366375364725, 5.3638720753811819],
					[63.303663717841175, 5.3638721158509588],
					[63.303435964593241, 5.364366514681242],
					[63.303216676961128, 5.3643915018544099],
					[63.303216613980574, 5.3643915337682087],
					[63.30321652486203, 5.3643916328920982],
					[63.303216499619658, 5.3643916999891816],
					[63.303213699674892, 5.3659305995778013],
					[63.303213701974371, 5.3659306192602818],
					[63.303213709209487, 5.3659306562954967],
					[63.303213715091537, 5.3659306755261307],
					[63.303630399695507, 5.3669500377986736],
					[63.30362479974837, 5.3674193989002346],
					[63.303624801996861, 5.3674194165922327],
					[63.303624809282375, 5.3674194556192489],
					[63.303624814268609, 5.367419474963377],
					[63.303835902336424, 5.3679500440533001],
					[63.303866499765775, 5.368466711308681],
					[63.303866503957771, 5.3684667347478205],
					[63.303866516718436, 5.3684667770798917],
					[63.303866525287113, 5.3684667959728181],
					[63.304041525025831, 5.3687806973815126],
					[63.304041529707192, 5.3687807047807414],
					[63.304041538123442, 5.3687807177011626],
					[63.304041542754042, 5.368780723109472],
					[63.304547043425849, 5.3694195235138551],
					[63.304547135155566, 5.3694195618884004],
					[63.304547306173518, 5.3694195383410435],
					[63.304547383670446, 5.369419476644846],
					[63.304772318166961, 5.3688835368373455],
					[63.305013851074392, 5.368944593672369],
					[63.30501393600855, 5.3689445769760482],
					[63.305014061159305, 5.3689444813085503],
					[63.305014099584625, 5.3689444025631072],
					[63.30502239932747, 5.3683028493613776],
					[63.305514018782731, 5.3673613045421549],
					[63.306527606873978, 5.3674001924712682],
					[63.306569195069173, 5.3684748760904055],
					[63.306347112376692, 5.3685831204223646],
					[63.306347067663594, 5.3685831640102384],
					[63.306347012779852, 5.3685832668065379],
					[63.306347001764308, 5.3685833281189579],
					[63.306402564249431, 5.368971960838528],
					[63.305850031223599, 5.3688859028421367],
					[63.305849965892172, 5.3688859130736759],
					[63.305849857841935, 5.3688859746301807],
					[63.305849815072371, 5.3688860239641389],
					[63.30559701637717, 5.3695055216575387],
					[63.305185975679201, 5.3704136885634064],
					[63.30498891696363, 5.3703970003939538],
					[63.304988857158186, 5.3703970159176402],
					[63.304988756221682, 5.3703970745729297],
					[63.30498871608777, 5.3703971215736583],
					[63.304760958392762, 5.3709359213790684],
					[63.304261316367878, 5.3710219593525315],
					[63.304339099638966, 5.3697889123984712],
					[63.304339094145412, 5.3697888731441932],
					[63.304339067983008, 5.3697887985449526],
					[63.304339047364948, 5.3697887651909459],
					[63.303402947288461, 5.3687692652831709],
					[63.30340285486605, 5.3687692349884095],
					[63.303402689425198, 5.3687692658246622],
					[63.30340261371984, 5.3687693272942996],
					[63.303174868270382, 5.3693497873972413],
					[63.302977817347831, 5.3693331002432307],
					[63.30297773809211, 5.3693331282048913],
					[63.302977630364481, 5.3693332376460727],
					[63.302977600895396, 5.3693333152567781],
					[63.303024797700459, 5.3699360785791637],
					[63.302772008123007, 5.3708138454240189],
					[63.302772004456784, 5.3708138778411936],
					[63.302772006976497, 5.3708139414346441],
					[63.302772014058078, 5.3708139724981123],
					[63.302944192612706, 5.3712556202267718],
					[63.30283593376231, 5.3718469947720138],
					[63.302041706332709, 5.3718219996370902],
					[63.302041648369119, 5.3718220169212598],
					[63.30204155206237, 5.3718220809737121],
					[63.302041513719232, 5.3718221277419902],
					[63.301830413924478, 5.3723610275493821],
					[63.301830408905673, 5.3723610421612262],
					[63.301830401656488, 5.3723610750281772],
					[63.301830400271029, 5.3723610911797728],
					[63.301802601804894, 5.372958243615404],
					[63.301599824586653, 5.3733277048798831],
					[63.301599821950397, 5.3733277072088015],
					[63.301599817725688, 5.3733277177261574],
					[63.301599816035832, 5.3733277219331086],
					[63.301369216181996, 5.3738638212259202],
					[63.301369210368783, 5.373863839931456],
					[63.30136920242645, 5.3738638808731229],
					[63.301369200195843, 5.3738638991278291],
					[63.301369200388542, 5.3763888990458941],
					[63.301369199543586, 5.3763889011492543],
					[63.301369199644931, 5.3763889051307361],
					[63.3013691996956, 5.3763889071214708],
					[63.301410898373007, 5.377416652883876],
					[63.300877623620984, 5.3783999047515625],
					[63.300877615272135, 5.3783999297656839],
					[63.300877603052761, 5.3783999792311121],
					[63.300877600128523, 5.3784000055605476],
					[63.300902597566832, 5.3793860059931706],
					[63.300560974712987, 5.3796581432889132],
					[63.300560947706984, 5.3796581766374576],
					[63.30056091101622, 5.3796582531407289],
					[63.3005609003852, 5.3796582944172719],
					[63.300502599876957, 5.3817499939307822],
				],
			],
		},
		{
			fldNpdidField: "43765",
			wlbNpdidWellbore: "68",
			fldName: "ÅSGARD",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6507/11-1",
			fldDiscoveryYear: "1981",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43765",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43765",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "ÅSGARD UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=68",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "40564",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-02-17 00:00:00",
			fldMainSupplyBase: "Kristiansund",
			fldAreaGeometryWKT: [
				[
					[65.225241500622062, 6.8974027805181857],
					[65.225241506629388, 6.8974028249691539],
					[65.225241539236023, 6.8974029100955265],
					[65.22524156490887, 6.8974029487051256],
					[65.227002664794455, 6.8990084463197618],
					[65.229599863999724, 6.9014195478032603],
					[65.229599865822763, 6.9014195497984678],
					[65.229599871262053, 6.9014195536466101],
					[65.229599873981741, 6.9014195555706772],
					[65.233024867883358, 6.9042029513131142],
					[65.238402663296355, 6.9092307453568722],
					[65.238402664222704, 6.9092307474241519],
					[65.238402666015929, 6.9092307472823906],
					[65.23840266694225, 6.9092307493496543],
					[65.243774852359678, 6.9140445350685029],
					[65.246758141262561, 6.9179584218491899],
					[65.246758145834448, 6.9179584279126169],
					[65.246758157668069, 6.9179584398276592],
					[65.246758164033182, 6.9179584457498828],
					[65.250002664182972, 6.9209779458064951],
					[65.250002664212545, 6.9209779479455902],
					[65.250002665109136, 6.9209779478750724],
					[65.25000266600577, 6.920977947804551],
					[65.255099843371084, 6.9255973266537803],
					[65.257160924586728, 6.9291472977719692],
					[65.26166921990297, 6.9375306876663094],
					[65.262991517343522, 6.9404861827864854],
					[65.262991521017895, 6.9404861889274221],
					[65.262991528366612, 6.9404862012092821],
					[65.262991531144365, 6.9404862074201201],
					[65.264580430672623, 6.9429834069131617],
					[65.264580506691345, 6.9429834524156409],
					[65.264580665745456, 6.942983465741098],
					[65.264580748780844, 6.9429834335640752],
					[65.265841849123845, 6.9415751321492216],
					[65.265841865837785, 6.9415751072771439],
					[65.265841891108224, 6.9415750517406201],
					[65.265841898768088, 6.9415750211460283],
					[65.266327998656081, 6.9369500201342857],
					[65.266327999406002, 6.9369500093620431],
					[65.266327999083302, 6.9369499858171766],
					[65.266327998010681, 6.9369499730445527],
					[65.265208497909555, 6.9287360751750802],
					[65.264480798717244, 6.922238876999697],
					[65.264480797761522, 6.9222388727896647],
					[65.264480795820575, 6.9222388622293662],
					[65.264480795761472, 6.9222388579488072],
					[65.262914095728945, 6.9149360588530451],
					[65.262914094802696, 6.9149360567836773],
					[65.26291409381713, 6.9149360504340267],
					[65.262914092890881, 6.9149360483646598],
					[65.259825192744032, 6.9033860475890636],
					[65.259825190861392, 6.9033860413117605],
					[65.259825186169707, 6.9033860266883931],
					[65.259825183360633, 6.9033860183423732],
					[65.25736958342101, 6.8979027189174245],
					[65.25472518325752, 6.8919999180104625],
					[65.254725182330944, 6.8919999159425442],
					[65.254725179581214, 6.8919999118782194],
					[65.25472517865461, 6.8919999098103011],
					[65.252936285002178, 6.8884305237432004],
					[65.25159738982012, 6.8843888368055959],
					[65.25159736593713, 6.8843887980271266],
					[65.251597303109463, 6.884388734525257],
					[65.251597265091476, 6.8843887118692857],
					[65.247461192404259, 6.8829748190466509],
					[65.245241849116638, 6.8814054608843831],
					[65.24383627694786, 6.87911660609468],
					[65.241380782660571, 6.8736388188119228],
					[65.241380778983526, 6.8736388126853081],
					[65.241380773422577, 6.8736388002878464],
					[65.24138076884897, 6.8736387942333259],
					[65.238383475223202, 6.8688610022880647],
					[65.236122382138404, 6.8642416164740636],
					[65.233944597796082, 6.8591805544410915],
					[65.233544599823901, 6.8502693909808876],
					[65.233544599823901, 6.8502693909808876],
					[65.233544599793348, 6.8502693888431994],
					[65.23354459976278, 6.8502693867055298],
					[65.233311299728669, 6.8467971862913899],
					[65.233311268265567, 6.8467971203679463],
					[65.233311169996711, 6.8467970277802577],
					[65.233311102263755, 6.8467969990513282],
					[65.230930601619733, 6.8467719994094534],
					[65.230930537341706, 6.846772023900531],
					[65.230930437996463, 6.846772106884365],
					[65.230930402929218, 6.8467721653770823],
					[65.229999804962077, 6.8520832539173417],
					[65.228949809301994, 6.8554416392480704],
					[65.228949807600273, 6.8554416458053469],
					[65.228949805032471, 6.8554416545725996],
					[65.228949804196887, 6.8554416589198786],
					[65.227835903893975, 6.8607666600556012],
					[65.227835903924387, 6.8607666621928232],
					[65.227835902252863, 6.8607666708867043],
					[65.22783590228326, 6.8607666730239281],
					[65.227094201940048, 6.866497174464735],
					[65.227094201104109, 6.8664971788113665],
					[65.227094200328835, 6.8664971874323548],
					[65.227094200419828, 6.8664971938438839],
					[65.226830399739583, 6.8737166916688679],
					[65.226474799930514, 6.8809443869336047],
					[65.225774801116884, 6.8897249837363246],
					[65.225774800280305, 6.8897249880820128],
					[65.225774800430287, 6.8897249987675107],
					[65.225774799593665, 6.889725003113222],
					[65.225802599864579, 6.8916860915183351],
					[65.225241500622062, 6.8974027805181857],
				],
				[
					[
						[64.984588701417167, 6.5998582857902335],
						[64.98458870075909, 6.5998583006924703],
						[64.984588703028692, 6.5998583301752625],
						[64.984588705990447, 6.5998583468731979],
						[64.985849806029535, 6.6050861469441857],
						[64.985849809819243, 6.6050861593282244],
						[64.985849819191515, 6.6050861839357973],
						[64.985849824774107, 6.6050861961593279],
						[64.988216525120166, 6.6093750964387858],
						[64.988216535218612, 6.6093751103813903],
						[64.988216556311997, 6.6093751381864987],
						[64.988216568169463, 6.6093751498511635],
						[64.991447064326536, 6.6122140462830963],
						[64.994722056007902, 6.6154334393263081],
						[64.998605440719857, 6.6197001221271217],
						[65.002130429091437, 6.6251945039038818],
						[65.005835926167052, 6.6317111995188123],
						[65.005835932610367, 6.6317112095584356],
						[65.005835947256358, 6.631711227359677],
						[65.005835954596122, 6.6317112373198652],
						[65.011394255314258, 6.6375945360750803],
						[65.011394258070709, 6.637594540076484],
						[65.011394265376609, 6.6375945479207354],
						[65.011394269029537, 6.6375945518428825],
						[65.016391569196941, 6.6419334514931174],
						[65.016391569196941, 6.6419334514931174],
						[65.016391571023348, 6.641933453454838],
						[65.020605466670986, 6.6454751500655496],
						[65.024760962983592, 6.649386245638766],
						[65.0247609648099, 6.6493862476016021],
						[65.024760968429192, 6.649386249406616],
						[65.0247609702555, 6.649386251369453],
						[65.028872070327637, 6.6529195527838505],
						[65.028872080288863, 6.6529195582799616],
						[65.028872101174386, 6.6529195734353372],
						[65.028872112065443, 6.6529195809736272],
						[65.032744299886375, 6.6548057731653349],
						[65.036491570949835, 6.6573112532541598],
						[65.040488744193723, 6.6614695266143951],
						[65.04488873528679, 6.6679195121180079],
						[65.044888738042488, 6.6679195161275127],
						[65.044888743586924, 6.6679195262687916],
						[65.044888748135577, 6.6679195301215559],
						[65.050230446254943, 6.6741807277423808],
						[65.054477643247324, 6.6794918231974858],
						[65.058352641147806, 6.6845307219408214],
						[65.058352644799527, 6.6845307258761792],
						[65.058352652135895, 6.6845307358702932],
						[65.05835265578763, 6.6845307398056528],
						[65.061838742824051, 6.6881584252405322],
						[65.065094228253173, 6.6931557038478084],
						[65.068077617270916, 6.6986417831326603],
						[65.070980411560214, 6.7065139681396921],
						[65.070980413385783, 6.7065139701098193],
						[65.070980414347432, 6.7065139742815001],
						[65.07098041527648, 6.7065139763287638],
						[65.075580414890538, 6.7177778766097198],
						[65.075580417677315, 6.7177778827537651],
						[65.075580424147304, 6.7177778949650451],
						[65.075580426901666, 6.7177778989842674],
						[65.08185812709857, 6.7286583999150738],
						[65.081858128027349, 6.7286584019639619],
						[65.081858128027349, 6.7286584019639619],
						[65.086749827649484, 6.7369251013537381],
						[65.086749830403321, 6.7369251053766153],
						[65.086749834085808, 6.7369251114490449],
						[65.086749835911007, 6.7369251134223891],
						[65.091347032283139, 6.7435418095711013],
						[65.095674827723869, 6.7507668025957326],
						[65.095674829580815, 6.7507668066971327],
						[65.095674835055846, 6.7507668126219906],
						[65.095674836880846, 6.7507668145969371],
						[65.100635937239716, 6.7577862155109516],
						[65.100635940921393, 6.7577862215894147],
						[65.100635949149449, 6.7577862315439239],
						[65.100635953727675, 6.7577862375468296],
						[65.105672045897961, 6.7631779290303102],
						[65.112085939430273, 6.7718168192814696],
						[65.112085941255003, 6.7718168212590824],
						[65.112085944007944, 6.771816825289366],
						[65.112085944904464, 6.7718168252142679],
						[65.119499845346454, 6.7809057255821799],
						[65.119499846274508, 6.7809057276357638],
						[65.11949984720259, 6.7809057296893434],
						[65.119499848099167, 6.7809057296145205],
						[65.126022047787885, 6.7885529297359799],
						[65.126022052364902, 6.7885529357498768],
						[65.126022060559535, 6.7885529435943486],
						[65.126022066001681, 6.7885529474047264],
						[65.13069706579941, 6.7927945471831856],
						[65.130697065830759, 6.7927945493125019],
						[65.130697067623856, 6.7927945491635864],
						[65.130697069448374, 6.7927945511439818],
						[65.136572050070328, 6.7978779348544984],
						[65.146335930595399, 6.8118223073534931],
						[65.150194227262148, 6.8185362007326873],
						[65.150194228189719, 6.8185362027899643],
						[65.150194231868952, 6.8185362088880836],
						[65.150194233724079, 6.8185362130026217],
						[65.153497029784774, 6.8234445045542715],
						[65.155652618325064, 6.827261184988811],
						[65.157635905996614, 6.8327111521181401],
						[65.159366501875269, 6.8419306301334384],
						[65.160266497993334, 6.8495777968177203],
						[65.159122006036654, 6.8568221452575315],
						[65.156413715739504, 6.8633249211901752],
						[65.154083117408064, 6.8685860183616914],
						[65.154083114778814, 6.8685860228402227],
						[65.154083112240414, 6.8685860337126634],
						[65.15408310958091, 6.8685860360598987],
						[65.152163709916181, 6.8743832364027702],
						[65.152163708213578, 6.8743832429397758],
						[65.152163704808373, 6.8743832560138092],
						[65.152163703135912, 6.8743832646819882],
						[65.150922002557138, 6.8812388642387301],
						[65.150922002587237, 6.8812388663698103],
						[65.150922002617335, 6.8812388685008701],
						[65.150922001720758, 6.881238868572412],
						[65.149319202091888, 6.8916055698858338],
						[65.14931920131508, 6.8916055784808261],
						[65.149319199791435, 6.8916055978017514],
						[65.149319199911247, 6.8916056063255366],
						[65.149552600148169, 6.8980639063410667],
						[65.149552601164245, 6.8980639147939886],
						[65.149552602240007, 6.8980639275089022],
						[65.149552603226226, 6.8980639338308514],
						[65.1507692026467, 6.9053528335864671],
						[65.150769202676472, 6.9053528357175473],
						[65.150769203632592, 6.9053528399089839],
						[65.15076920366235, 6.9053528420400658],
						[65.152894204096953, 6.9154917407603396],
						[65.152894205052831, 6.915491744952476],
						[65.152894207890753, 6.915491755397646],
						[65.152894208876234, 6.9154917617210696],
						[65.155822008756431, 6.9246806613664287],
						[65.155822010608659, 6.9246806654892916],
						[65.155822015239238, 6.9246806757963952],
						[65.155822017091467, 6.9246806799192582],
						[65.15909151660972, 6.9321278808032387],
						[65.15909152028442, 6.9321278869192096],
						[65.159091527663136, 6.9321279012829216],
						[65.159091532263844, 6.932127909460827],
						[65.162797032254275, 6.9378418097257066],
						[65.162797034076846, 6.9378418117184779],
						[65.16279703867724, 6.9378418198984919],
						[65.162797042293093, 6.9378418217519044],
						[65.166416533240749, 6.9425390100297921],
						[65.170213726652193, 6.949147299942501],
						[65.170213733971067, 6.9491473100515355],
						[65.170213751327793, 6.949147332194376],
						[65.170213758646639, 6.949147342303414],
						[65.174372059410956, 6.9532557413389124],
						[65.174372060336694, 6.9532557434027655],
						[65.17789155982598, 6.9566862424450626],
						[65.17789156801193, 6.9566862503566682],
						[65.177891585251444, 6.9566862639774527],
						[65.177891594305038, 6.9566862696866485],
						[65.181333193851202, 6.958847368883629],
						[65.181333201111372, 6.9588473747323052],
						[65.181333215544754, 6.9588473800288515],
						[65.181333223701586, 6.9588473858085074],
						[65.184736016877494, 6.9602445826913044],
						[65.188594299274698, 6.9621834730517635],
						[65.19239988815373, 6.9647529670392077],
						[65.192399899896927, 6.9647529725476263],
						[65.192399925205592, 6.9647529855613],
						[65.192399937845408, 6.9647529910008679],
						[65.195436037716931, 6.9657445897009849],
						[65.19543604491902, 6.9657445912850982],
						[65.195436059352147, 6.9657445965880749],
						[65.19543606742198, 6.9657445959686077],
						[65.198163867018991, 6.9662057963235444],
						[65.198163873324447, 6.9662057979767571],
						[65.198163886803073, 6.9662057990793862],
						[65.198163894005162, 6.9662058006637766],
						[65.200794394209893, 6.9662835009762114],
						[65.200794406762995, 6.9662835000126098],
						[65.200794432765804, 6.9662834980165815],
						[65.20079444618662, 6.9662834948489705],
						[65.204061133419373, 6.9655195979728219],
						[65.207636029388311, 6.965161308005591],
						[65.208935961684261, 6.9664001453165048],
						[65.208935965299744, 6.9664001471769454],
						[65.208935970766348, 6.9664001531713842],
						[65.208935974381831, 6.9664001550318257],
						[65.210652673953192, 6.9677973545032907],
						[65.210652679390833, 6.9677973583624224],
						[65.210652690266159, 6.9677973660806449],
						[65.210652694807152, 6.9677973700085847],
						[65.21371929539788, 6.9696807694007425],
						[65.213719299938845, 6.9696807733294515],
						[65.213719308991898, 6.9696807790506003],
						[65.213719314400677, 6.9696807807743228],
						[65.215924901706884, 6.9707362746493287],
						[65.217699890650053, 6.9718834686166025],
						[65.21769996264149, 6.9718834823502309],
						[65.217700097833756, 6.9718834570217965],
						[65.217700179838431, 6.9718833480786166],
						[65.217700163647038, 6.9718832124379846],
						[65.217700129577324, 6.9718831487462891],
						[65.216406423951582, 6.9707774278433181],
						[65.217852677720842, 6.9718973583007964],
						[65.217852678617461, 6.9718973582321064],
						[65.217852678646295, 6.9718973603686729],
						[65.218785940117456, 6.9726029296829513],
						[65.220466518756908, 6.9761528854312775],
						[65.220466530701103, 6.9761529059079139],
						[65.220466558060934, 6.9761529380398164],
						[65.220466574430802, 6.9761529539000877],
						[65.222708174400395, 6.9779723540650833],
						[65.222708185303901, 6.9779723639279156],
						[65.222708209714725, 6.977972377037152],
						[65.222708222382835, 6.9779723846260113],
						[65.224172114166848, 6.9785918803389855],
						[65.226149865871349, 6.9796307546523186],
						[65.227805429863864, 6.9819140078753392],
						[65.22924152412763, 6.9845556956101253],
						[65.229241525978239, 6.9845556997485518],
						[65.229241529650807, 6.9845557058878622],
						[65.229241533294726, 6.9845557098896709],
						[65.230635933437455, 6.9866918089554151],
						[65.230635947144904, 6.9866918271705476],
						[65.230635978957181, 6.9866918568467815],
						[65.230635996193968, 6.9866918705137344],
						[65.231358196224576, 6.9871307706076742],
						[65.23135820978888, 6.9871307781351213],
						[65.231358237785514, 6.9871307909841001],
						[65.231358253085915, 6.9871307940997349],
						[65.232880552620884, 6.9875001938989101],
						[65.232880575991203, 6.9875001964010428],
						[65.232880621777866, 6.9875001971978854],
						[65.232880645090958, 6.9875001954243983],
						[65.2345194447945, 6.9871195957727936],
						[65.234519489341167, 6.9871195709819771],
						[65.234519561197672, 6.9871195077311521],
						[65.23451958758227, 6.9871194672014392],
						[65.234958487738183, 6.9858861668148888],
						[65.23495849291767, 6.9858861514394741],
						[65.234958497868107, 6.9858861189602868],
						[65.234958500329014, 6.9858861016517002],
						[65.234977999810539, 6.9839861027056251],
						[65.234977998684613, 6.9839860856701979],
						[65.234977993800101, 6.9839860560803269],
						[65.234977990880836, 6.9839860391815751],
						[65.233722390598913, 6.9799610394240554],
						[65.233722389673531, 6.9799610373546681],
						[65.23372238779406, 6.9799610310780107],
						[65.233722385943338, 6.9799610269392316],
						[65.23189188558473, 6.9752582277438053],
						[65.231891882779664, 6.9752582193988548],
						[65.231891876301646, 6.9752582049152414],
						[65.231891871732032, 6.9752581988452409],
						[65.229216875137425, 6.9707443015643173],
						[65.227061276858876, 6.9666527079822851],
						[65.22706127500777, 6.966652703845476],
						[65.22706127223114, 6.9666526976402423],
						[65.227061270380005, 6.9666526935034367],
						[65.224905770245257, 6.9631915930322679],
						[65.224905766629746, 6.9631915911711788],
						[65.224905762030687, 6.9631915829677293],
						[65.224905760208458, 6.9631915809686022],
						[65.22483669988911, 6.9630997118186793],
						[65.224955791730594, 6.9624833607526675],
						[65.226436229207948, 6.9591890992194427],
						[65.229391700254553, 6.9591862992378699],
						[65.229391709249967, 6.9591863006837604],
						[65.229391727153782, 6.959186297163094],
						[65.229391735223558, 6.9591862965406621],
						[65.231580634912248, 6.9587918962672672],
						[65.231580691949048, 6.9587918661878474],
						[65.231580774204915, 6.9587917763853708],
						[65.231580799424037, 6.9587917166623097],
						[65.232319599314337, 6.950094419973138],
						[65.232830799127598, 6.9455083214423761],
						[65.232830798806347, 6.9455082979271872],
						[65.232830791858149, 6.9455082492465046],
						[65.232830786157095, 6.945508226149113],
						[65.229964085774242, 6.9382305263612354],
						[65.229964083922368, 6.9382305222259779],
						[65.229964080218608, 6.9382305139554425],
						[65.22996407836672, 6.9382305098201877],
						[65.228297377973064, 6.9349499091919613],
						[65.228297375195112, 6.9349499029897919],
						[65.228297368771962, 6.9349498927928028],
						[65.228297366023355, 6.9349498887280125],
						[65.227130768357597, 6.933222091368612],
						[65.225766893590119, 6.9310555323419045],
						[65.224991898718443, 6.9233888802113652],
						[65.224991896718535, 6.923388865392516],
						[65.224991890058462, 6.9233888381029622],
						[65.224991886294831, 6.923388825561906],
						[65.223314085609843, 6.9192027251537267],
						[65.223314081905087, 6.9192027168879324],
						[65.223314072672665, 6.9192026983604009],
						[65.223314068071275, 6.9192026901651191],
						[65.222047386722451, 6.9172582207718385],
						[65.220930795108472, 6.9123443568210474],
						[65.220930794152466, 6.9123443526182982],
						[65.220930793196516, 6.9123443484155258],
						[65.220930793166829, 6.9123443462787897],
						[65.219625192620853, 6.9076777467236576],
						[65.219625191694476, 6.9076777446579083],
						[65.219625189812021, 6.9076777383897614],
						[65.219625189782292, 6.9076777362531381],
						[65.21733628995004, 6.9008138360547804],
						[65.217336288097144, 6.9008138319241157],
						[65.21733628343506, 6.9008138194610025],
						[65.21733627975911, 6.9008138133360823],
						[65.214489084443798, 6.8949055207784928],
						[65.212114087104254, 6.8887332282534404],
						[65.21211401082931, 6.8887331659066575],
						[65.212113841667772, 6.8887331366266951],
						[65.212113748781121, 6.8887331696934968],
						[65.208922048648816, 6.8923942685271724],
						[65.208922040729121, 6.8923942798480828],
						[65.208922024919772, 6.8923943046256406],
						[65.208922017926639, 6.8923943180109388],
						[65.207627650403765, 6.8952248474523108],
						[65.204405610744828, 6.8966164764208751],
						[65.201905696961987, 6.8952248245187393],
						[65.201905642956135, 6.8952248138466299],
						[65.201905536199916, 6.8952248180533386],
						[65.201905484406041, 6.8952248371313134],
						[65.198316583662404, 6.8977804370605176],
						[65.198316574785949, 6.8977804441765942],
						[65.198316558826221, 6.8977804582664497],
						[65.198316550876243, 6.897780467446279],
						[65.196347050925155, 6.8999804678725516],
						[65.196347045605165, 6.8999804725683669],
						[65.196347036788296, 6.89998048395264],
						[65.196347033291403, 6.8999804906410986],
						[65.194291540137343, 6.9030942805466777],
						[65.194110739031217, 6.9033071514019211],
						[65.192764096276022, 6.8974721584454306],
						[65.189655798013717, 6.8811777719613891],
						[65.188591900383059, 6.8702193847277675],
						[65.187847399940637, 6.8549860944017347],
						[65.187616899989749, 6.8395388964288646],
						[65.187239099564735, 6.8147222015941944],
						[65.188100199859178, 6.7877139053373217],
						[65.188100199859178, 6.7877139053373217],
						[65.188100199796295, 6.7877139010694707],
						[65.188100199796295, 6.7877139010694707],
						[65.188150199663795, 6.7703917012260755],
						[65.188150199632105, 6.7703916990921815],
						[65.188150200433597, 6.7703916926150649],
						[65.188150200401935, 6.7703916904811701],
						[65.187136300210639, 6.750588890090282],
						[65.186627999975812, 6.735883293326415],
						[65.186111299740944, 6.7213056026227616],
						[65.186589100218242, 6.7119778102621881],
						[65.186589100185714, 6.7119778081285046],
						[65.186589100153213, 6.7119778059948212],
						[65.186589100120699, 6.7119778038611386],
						[65.186766900302956, 6.7018722030382163],
						[65.186766900270314, 6.7018722009045328],
						[65.186766900172344, 6.7018721945034674],
						[65.186766900107031, 6.7018721902361023],
						[65.186464099833643, 6.695652789282021],
						[65.186247400168767, 6.6914666899698263],
						[65.186247399141081, 6.6914666815133774],
						[65.18624739711845, 6.6914666667341312],
						[65.186247396123562, 6.6914666604113071],
						[65.185558495980771, 6.6881332606973851],
						[65.185558494023539, 6.6881332501859072],
						[65.185558489311177, 6.6881332356418479],
						[65.185558485560946, 6.6881332252867383],
						[65.184016885753394, 6.6842388259048029],
						[65.184016879280549, 6.6842388136523381],
						[65.184016863612598, 6.6842387872488773],
						[65.184016855313899, 6.6842387730195947],
						[65.180447354983272, 6.6798304730815463],
						[65.180447345853636, 6.6798304632002719],
						[65.180447324008441, 6.6798304437515039],
						[65.180447313085821, 6.679830434027143],
						[65.177580712852574, 6.6778692344891697],
						[65.177580696550947, 6.6778692252376324],
						[65.177580663117254, 6.6778692110788906],
						[65.17758064595219, 6.6778692040387613],
						[65.174600053585664, 6.6771665067244488],
						[65.17171116218978, 6.6762192098262334],
						[65.171711153158981, 6.6762192063467216],
						[65.171711135163349, 6.6762192036525487],
						[65.171711126165519, 6.6762192023054761],
						[65.167780626428012, 6.6757081019292306],
						[65.167780606672437, 6.6757081015249691],
						[65.167780568987268, 6.6757081026914866],
						[65.167780551090686, 6.6757081063943806],
						[65.164616650809648, 6.6765109053392502],
						[65.164616648153242, 6.676510907706632],
						[65.164616642774334, 6.6765109081776366],
						[65.164616640084915, 6.6765109084131362],
						[65.161291670052293, 6.677555400409414],
						[65.157036133001967, 6.6775304000632909],
						[65.150855693395116, 6.6754831197568665],
						[65.146897319100503, 6.6725692396968403],
						[65.146897317274465, 6.6725692377235459],
						[65.146897313655487, 6.6725692359073889],
						[65.146897311829477, 6.672569233934091],
						[65.13964731778546, 6.6676748370496783],
						[65.134177922655994, 6.6634054440500128],
						[65.127877924336502, 6.6584526438720122],
						[65.123077931818955, 6.6546081507250898],
						[65.118458439980273, 6.6501442561634017],
						[65.111855748242405, 6.6435415647745293],
						[65.106933457379469, 6.6376748758751072],
						[65.10005016081071, 6.6282831820563057],
						[65.10005016081071, 6.6282831820563057],
						[65.100050159880567, 6.6282831800095794],
						[65.100050159880567, 6.6282831800095794],
						[65.095852959979993, 6.6227248798162224],
						[65.087466860889037, 6.6113526801941251],
						[65.08746685999256, 6.6113526802745444],
						[65.087466859062204, 6.6113526782293501],
						[65.087466858131847, 6.6113526761841781],
						[65.082644557550111, 6.6051304761010199],
						[65.082644552069439, 6.6051304702090627],
						[65.082644541108095, 6.6051304584251724],
						[65.08264453473096, 6.605130452613845],
						[65.078325134844277, 6.6012054525982551],
						[65.078325132120881, 6.6012054507155646],
						[65.078325125743604, 6.6012054449061024],
						[65.07832512302025, 6.6012054430234288],
						[65.073952931765689, 6.5977831491620904],
						[65.067869560129097, 6.5918609773934786],
						[65.063802981727633, 6.584558215947041],
						[65.060783487233678, 6.5766943277801406],
						[65.060783483579144, 6.5766943238592583],
						[65.060783478890627, 6.5766943115262011],
						[65.060783477028977, 6.5766943074423283],
						[65.057486277402532, 6.5704499078670873],
						[65.057486276437118, 6.5704499037024968],
						[65.057486272782455, 6.5704498997829468],
						[65.057486271851573, 6.5704498977414696],
						[65.054441872148246, 6.5653526971833118],
						[65.054441865700497, 6.5653526871417469],
						[65.0544418519777, 6.5653526713862504],
						[65.054441844668119, 6.5653526635494099],
						[65.051536245205142, 6.562280462812935],
						[65.051536230620215, 6.5622804492652955],
						[65.051536198830306, 6.5622804266611166],
						[65.051536181625352, 6.5622804176045753],
						[65.048297282322523, 6.5608248182391931],
						[65.048297265152044, 6.5608248113070458],
						[65.048297230018591, 6.5608248038919044],
						[65.048297211986338, 6.5608247991641351],
						[65.044830615906022, 6.560613701024514],
						[65.041888921043039, 6.560308101084865],
						[65.041888919250212, 6.5603081012487916],
						[65.041888913871659, 6.5603081017405742],
						[65.041888912044186, 6.5603080997826204],
						[65.038736122969311, 6.5601137017548918],
						[65.035169457547113, 6.5595192059999334],
						[65.031869518985246, 6.558063733545155],
						[65.028961273641301, 6.5548665941428164],
						[65.027222390201416, 6.5501110378885636],
						[65.025752991259466, 6.545244341798214],
						[65.025752990293384, 6.545244337639474],
						[65.025752986568406, 6.5452443294867875],
						[65.025752985602338, 6.5452443253280439],
						[65.024011286192476, 6.5408666251362639],
						[65.024011271431135, 6.5408666010118539],
						[65.024011234772161, 6.5408665555438494],
						[65.024011212874484, 6.5408665342002577],
						[65.022602913431797, 6.5398998356187477],
						[65.022602861056114, 6.5398998170846392],
						[65.022602755966886, 6.5398998140241229],
						[65.022602702287116, 6.5398998253396385],
						[65.019961002037846, 6.5413748239722933],
						[65.01996097284011, 6.5413748500165845],
						[65.019960925375983, 6.5413749117155318],
						[65.019960907074719, 6.5413749452500571],
						[65.018924812253289, 6.5450805291863281],
						[65.015324831589623, 6.5526526862785364],
						[65.012080452321896, 6.5562248654856043],
						[65.012080442634641, 6.556224876985044],
						[65.012080426880516, 6.5562249017754723],
						[65.01208041898613, 6.5562249131109311],
						[65.00918042030942, 6.5623305114930712],
						[65.005197030975268, 6.5702526894993021],
						[64.999880450128387, 6.5767054660519957],
						[64.996472056300277, 6.5802609601754884],
						[64.993497056407691, 6.5833442611342354],
						[64.993497052890518, 6.5833442656944365],
						[64.993497045821812, 6.5833442726967837],
						[64.993497043166812, 6.5833442750578923],
						[64.991799843697166, 6.5854970743546053],
						[64.989722045703786, 6.5880026721429861],
						[64.989722044841585, 6.5880026743416131],
						[64.989722043082907, 6.5880026766211017],
						[64.989722042186472, 6.5880026767019437],
						[64.98795264179526, 6.5902804764259235],
						[64.987952640933017, 6.5902804786243312],
						[64.987952638312038, 6.5902804831019077],
						[64.987952635656882, 6.5902804854618351],
						[64.986347035781051, 6.5925637842859492],
						[64.98634703319415, 6.5925637908805594],
						[64.986347027055672, 6.592563799915415],
						[64.986347025331099, 6.5925638043118209],
						[64.984952624839963, 6.5951138042807846],
						[64.984952616182497, 6.5951138241434659],
						[64.984952604246331, 6.5951138633852882],
						[64.98495260100168, 6.5951138848818758],
						[64.984588701417167, 6.5998582857902335],
					],
					[
						[65.2057084528861, 6.9380866229568703],
						[65.2079665573808, 6.940397339709981],
						[65.207966559203328, 6.9403973417062561],
						[65.207966562848398, 6.9403973456988224],
						[65.207966564670926, 6.9403973476950966],
						[65.210477629863647, 6.9427001167225253],
						[65.212849806845412, 6.9488778568595952],
						[65.21298635636056, 6.9495551658895902],
						[65.210972318225188, 6.948324838061203],
						[65.208572366927811, 6.9462442805022571],
						[65.206994589393048, 6.9424527337507875],
						[65.205883492319586, 6.9386971440252365],
						[65.2057084528861, 6.9380866229568703],
					],
				],
				[
					[64.983008103283311, 6.8862556173306455],
					[64.983008104569819, 6.8862556447898395],
					[64.985083105394295, 6.8953806430613067],
					[64.985083108233468, 6.89538065343908],
					[64.985083113882041, 6.8953806720767323],
					[64.985083117617876, 6.8953806823839736],
					[65.015091515827038, 6.9622417778780443],
					[65.028938713982328, 6.9967917749968587],
					[65.028938785701882, 6.9967918354487724],
					[65.028938947730879, 6.9967918700134364],
					[65.028939038040306, 6.9967918441261876],
					[65.037977937970226, 6.9881501451154957],
					[65.03797794855906, 6.9881501315713317],
					[65.037977968868589, 6.9881501066728156],
					[65.037977976738816, 6.9881500912092385],
					[65.051780772092016, 6.9616084021597349],
					[65.056344564077534, 6.9550195140850297],
					[65.056344576340479, 6.9550194918864028],
					[65.05634459276736, 6.9550194459843562],
					[65.05634459872465, 6.9550194221434056],
					[65.0603168987424, 6.9189667222078164],
					[65.060316899402679, 6.9189667051468531],
					[65.060316898092452, 6.9189666754825803],
					[65.060316896092374, 6.9189666607554221],
					[65.05520019569073, 6.8938443602865984],
					[65.055200190968407, 6.8938443436531562],
					[65.05520017793728, 6.8938443106695173],
					[65.055200169628463, 6.8938442943193197],
					[65.046539086235768, 6.8798943219179893],
					[65.041336294246022, 6.8593082506064071],
					[65.041336287726665, 6.8593082341316043],
					[65.041336272894839, 6.8593082013258178],
					[65.04133626368565, 6.8593081850667614],
					[65.034819563633235, 6.8499887855773256],
					[65.034819559955267, 6.8499887795009133],
					[65.034819550836673, 6.8499887696142805],
					[65.034819547158733, 6.849988763537886],
					[65.026077946879724, 6.8405776648265428],
					[65.026077935936684, 6.8405776529702758],
					[65.026077914173172, 6.8405776377419087],
					[65.026077903260742, 6.8405776280066846],
					[65.013952903111729, 6.8332970285661512],
					[65.013952868825882, 6.8332970164884106],
					[65.013952800407964, 6.8332970029332722],
					[65.013952763616814, 6.8332970037940779],
					[65.003899963683139, 6.8351470028929668],
					[65.003899936011521, 6.8351470136209613],
					[65.003899885212661, 6.8351470389524565],
					[65.003899862116171, 6.8351470556752307],
					[64.997108161777433, 6.8416498564320447],
					[64.997108138028537, 6.841649890167222],
					[64.997108106853389, 6.8416499690474701],
					[64.997108100293048, 6.8416500120013906],
					[64.998841483902652, 6.8711025341250451],
					[64.988869451626258, 6.8684248066187239],
					[64.988869378133813, 6.8684248145952953],
					[64.988869257010208, 6.8684248815013618],
					[64.988869210245497, 6.8684249382412776],
					[64.983008110243389, 6.8862555383384816],
					[64.983008105223504, 6.8862555641757828],
					[64.983008103283311, 6.8862556173306455],
				],
				[
					[64.920610911173057, 7.6493333658200831],
					[64.922924810722066, 7.6559444665409702],
					[64.922924811638126, 7.6559444686098574],
					[64.922924815302395, 7.6559444768854217],
					[64.922924817134515, 7.6559444810231962],
					[64.926205416796194, 7.6635056801058266],
					[64.926205418628101, 7.6635056842446225],
					[64.926205425020854, 7.6635056966163537],
					[64.926205426852775, 7.6635057007551692],
					[64.930205426625946, 7.6703362010409153],
					[64.930205428419939, 7.6703362009520077],
					[64.930205429335828, 7.6703362030219449],
					[64.930205430251675, 7.6703362050918846],
					[64.933847030108126, 7.6762473052142637],
					[64.933847031023902, 7.6762473072847044],
					[64.938316516732996, 7.6832806854156006],
					[64.940107865157586, 7.6890992666536935],
					[64.936414051873953, 7.6853081699397148],
					[64.933708460314548, 7.6816915807017931],
					[64.933708459398815, 7.6816915786311775],
					[64.933708454876395, 7.681691574622227],
					[64.933708453960691, 7.6816915725516299],
					[64.93069735419968, 7.6780804720975198],
					[64.930697343323089, 7.6780804599412047],
					[64.930697318916401, 7.6780804399901008],
					[64.930697307161552, 7.6780804299924403],
					[64.92941400738772, 7.6772692307155239],
					[64.92941389257075, 7.6772692363757882],
					[64.929413743727281, 7.677269351592007],
					[64.929413708784992, 7.6772694590778476],
					[64.930780408968275, 7.6816444593485285],
					[64.930780410818386, 7.6816444656037186],
					[64.930780416312629, 7.6816444780259632],
					[64.930780419059744, 7.6816444842370952],
					[64.932630418729545, 7.6856333831701615],
					[64.932630423289154, 7.6856333914088317],
					[64.932630432408374, 7.6856334078861535],
					[64.932630436052349, 7.6856334140541742],
					[64.934638735853312, 7.6884945154814739],
					[64.93463874310379, 7.688494523589676],
					[64.934638757604773, 7.6884945398060829],
					[64.934638765752297, 7.688494547870409],
					[64.937133147193862, 7.6907529302023896],
					[64.938999821423238, 7.6935750924606356],
					[64.940322014043772, 7.6969778728769649],
					[64.940322017724228, 7.6969778832787181],
					[64.940322027757716, 7.6969779018362345],
					[64.940322034129238, 7.6969779121072337],
					[64.942385933676917, 7.70004731126072],
					[64.942385940945215, 7.7000473214897509],
					[64.942385957257429, 7.7000473397454465],
					[64.942385966301345, 7.7000473477721281],
					[64.944855465793495, 7.7022834484753027],
					[64.944855478443742, 7.7022834584453861],
					[64.944855504604448, 7.7022834741109865],
					[64.944855519048744, 7.7022834839942185],
					[64.945944319008504, 7.7027668822510682],
					[64.945944336125365, 7.7027668898891131],
					[64.94594437297657, 7.7027668965723333],
					[64.94594439095367, 7.7027668999356331],
					[64.949822191136732, 7.702941900814535],
					[64.949822214440701, 7.7029418975700912],
					[64.949822258339225, 7.7029418890954444],
					[64.949822279830755, 7.7029418838218193],
					[64.952594480212682, 7.7017279834609056],
					[64.952594499854897, 7.7017279719240817],
					[64.952594536411468, 7.7017279447484075],
					[64.952594553325795, 7.7017279291095395],
					[64.955686252996358, 7.6980779297450361],
					[64.955686259119673, 7.698077808759896],
					[64.955686151831671, 7.6980776488204778],
					[64.955686036626275, 7.6980776099533363],
					[64.953777737324145, 7.69870541022713],
					[64.953777734633121, 7.6987054103577872],
					[64.953777731063511, 7.6987054126482732],
					[64.953777728372486, 7.6987054127789305],
					[64.952322183853752, 7.6992636914013302],
					[64.949858375283469, 7.69872201194638],
					[64.947702922762502, 7.6974359407604034],
					[64.94551954214154, 7.6953054590381411],
					[64.943264143202711, 7.6929416645391582],
					[64.943952927080701, 7.6916084880409272],
					[64.945944358601295, 7.6914252046881133],
					[64.948811006718373, 7.6929362762668969],
					[64.948811021144678, 7.6929362840306643],
					[64.948811049941753, 7.6929362932105381],
					[64.948811065228014, 7.6929362966987913],
					[64.95202776482472, 7.6935112977081763],
					[64.952027849855398, 7.6935112723931303],
					[64.952027967352663, 7.6935111629309869],
					[64.952027998940764, 7.6935110809437441],
					[64.951644598688375, 7.6897555796612602],
					[64.951644595867222, 7.6897555649800813],
					[64.951644589346515, 7.6897555377776525],
					[64.951644584731369, 7.6897555231841688],
					[64.950308512198518, 7.6865138893615619],
					[64.951125187986193, 7.6837083681585474],
					[64.952922382339949, 7.679697282844212],
					[64.95426956718417, 7.6768140168199643],
					[64.956341799322388, 7.6747140830648588],
					[64.959116739354172, 7.6741640958059056],
					[64.959116806234391, 7.6741640480267739],
					[64.959116881351065, 7.6741639172532521],
					[64.959116888690531, 7.674163834303247],
					[64.957939088577817, 7.6707721334257899],
					[64.95620852557748, 7.6658167410637841],
					[64.959200140064397, 7.6622779435115742],
					[64.961686204332253, 7.6602945725013667],
					[64.96554727618495, 7.6586334850237217],
					[64.969850074088924, 7.6569279867627325],
					[64.969850074966885, 7.656927984600217],
					[64.969850075863874, 7.656927984555236],
					[64.972683376080397, 7.6557640856582614],
					[64.972683437267364, 7.6557640041950501],
					[64.972683458147372, 7.6557638315326724],
					[64.972683416924355, 7.6557637382607613],
					[64.96969453307014, 7.6536026493709848],
					[64.966786255300519, 7.6504776734276572],
					[64.963650162806758, 7.6460554836980386],
					[64.963650160077364, 7.6460554796000197],
					[64.963650152824599, 7.646055471494634],
					[64.963650149198216, 7.6460554674419505],
					[64.960166965420655, 7.6421666951487115],
					[64.96247511765975, 7.6394279710759765],
					[64.96608894161939, 7.638158497926204],
					[64.96962216913883, 7.6378780032264704],
					[64.974341613899583, 7.639791880270594],
					[64.978311003006496, 7.6419918752547602],
					[64.978311008407687, 7.6419918770999207],
					[64.978311019229366, 7.6419918829084068],
					[64.978311024630557, 7.6419918847535868],
					[64.981327724780911, 7.6432195853046245],
					[64.981327764344684, 7.6432195938961653],
					[64.981327842401953, 7.6432195920590207],
					[64.98132788001773, 7.6432195837942256],
					[64.98433068025119, 7.6419001832308693],
					[64.984330688285525, 7.6419001785839171],
					[64.984330704373491, 7.6419001714086923],
					[64.984330710613875, 7.6419001668527908],
					[64.986072310814379, 7.6407307656679757],
					[64.986072348102098, 7.6407307213788167],
					[64.986072391147971, 7.6407306195635964],
					[64.986072395989837, 7.640730559964318],
					[64.985214095854104, 7.6364027611111451],
					[64.985214093963393, 7.6364027506089922],
					[64.985214088407304, 7.636402731814802],
					[64.985214083845008, 7.6364027235684615],
					[64.983664083956342, 7.6327027231677391],
					[64.98366407118516, 7.6327027005048258],
					[64.983664037589321, 7.6327026577099932],
					[64.98366401768105, 7.6327026396508515],
					[64.981550118439557, 7.6311498388031813],
					[64.981550113018827, 7.6311498348415796],
					[64.98155010128032, 7.6311498269642293],
					[64.981550095859589, 7.6311498230026293],
					[64.979558409029323, 7.6300665328668797],
					[64.977341829553055, 7.6283637470513783],
					[64.974500137399943, 7.6256915547410893],
					[64.974500129268591, 7.6256915488018695],
					[64.974500112986433, 7.6256915348055969],
					[64.974500103958093, 7.6256915289124176],
					[64.972308403746396, 7.6243581293700196],
					[64.972308371318377, 7.6243581162051175],
					[64.97230830299155, 7.6243581027657026],
					[64.972308267989675, 7.6243581024451137],
					[64.969619367971163, 7.6247998022347945],
					[64.969619349173541, 7.6247998074369558],
					[64.9696193134113, 7.6247998219841246],
					[64.969619296427126, 7.624799829211633],
					[64.967210996285431, 7.6262748287117663],
					[64.967210987374088, 7.6262748355236338],
					[64.967210971325798, 7.626274846938105],
					[64.967210964208391, 7.6262748536579839],
					[64.965652746892815, 7.6277247754496873],
					[64.964069478038894, 7.6272637141895405],
					[64.96186950930354, 7.6260137313268013],
					[64.959872336980425, 7.6245609524671494],
					[64.958175258371753, 7.6225721947481917],
					[64.959372356672802, 7.6210251244314708],
					[64.960100154811158, 7.6201334255938429],
					[64.960100164560501, 7.6201334123854334],
					[64.960100181329139, 7.6201333818737824],
					[64.960100189264963, 7.620133366641034],
					[64.960808488621453, 7.6181333665313478],
					[64.960808490376209, 7.618133362205298],
					[64.960808492988761, 7.6181333535994389],
					[64.9608084938465, 7.618133349319633],
					[64.961369594065872, 7.6159028488035379],
					[64.961369595742028, 7.6159028360100143],
					[64.961369599113979, 7.6159028125397725],
					[64.961369599912757, 7.6159028019093933],
					[64.961383500284526, 7.6125361009106074],
					[64.961383500186088, 7.6125360903265786],
					[64.961383497298357, 7.6125360692978914],
					[64.961383496322625, 7.6125360608771153],
					[64.96072799594117, 7.6093388589898057],
					[64.960727994068293, 7.6093388506159112],
					[64.960727987631614, 7.609338834007815],
					[64.960727984842052, 7.6093388235637285],
					[64.960230830395545, 7.6081361332671493],
					[64.9626334509922, 7.6051223300324535],
					[64.965308435226845, 7.6022945484392412],
					[64.966886224379692, 7.6010557567107933],
					[64.966886244811661, 7.6010557344609841],
					[64.966886280214311, 7.6010556817735262],
					[64.966886293391056, 7.6010556514295944],
					[64.968186290863841, 7.5962083620259691],
					[64.970175184229433, 7.5910056795263117],
					[64.97246406567875, 7.5862751155328061],
					[64.975414020803754, 7.5831779632375831],
					[64.978727855163797, 7.581502996421988],
					[64.98273608388395, 7.5811724015311306],
					[64.986469341213294, 7.5820807929452547],
					[64.990655530339524, 7.5836390861304785],
					[64.990655535741411, 7.5836390879646762],
					[64.990655546565293, 7.5836390937521791],
					[64.990655551947086, 7.5836390934672835],
					[64.9937582520365, 7.5844057932118618],
					[64.993758268222066, 7.5844057965961946],
					[64.993758299656022, 7.584405799173668],
					[64.993758315801387, 7.5844057983193265],
					[64.996888916437101, 7.5841529985749245],
					[64.996888932582451, 7.5841529977203095],
					[64.996888965689791, 7.5841529874852558],
					[64.996888981794939, 7.5841529823914513],
					[65.000033381877685, 7.582733481836307],
					[65.000033403304229, 7.5827334700963274],
					[65.000033439818296, 7.582733440589581],
					[65.000033456699668, 7.5827334227277685],
					[65.002597357215421, 7.5794584233891067],
					[65.002597365187285, 7.5794584123600846],
					[65.002597378440115, 7.5794583904449846],
					[65.002597382824092, 7.5794583796065558],
					[65.004589083211144, 7.5748833799371651],
					[65.00723628296754, 7.5689667809730334],
					[65.007236283844207, 7.5689667788046311],
					[65.007236284700554, 7.568966774515836],
					[65.007236285577179, 7.5689667723474354],
					[65.009677985950844, 7.5627000729158915],
					[65.009677986827413, 7.5627000707470842],
					[65.009677987703952, 7.5627000685782813],
					[65.009677987683546, 7.562700066457702],
					[65.011575187082471, 7.5573889704929211],
					[65.013819580788251, 7.5518278851593701],
					[65.016330773631566, 7.5471694975807679],
					[65.019119570665282, 7.5424307033706368],
					[65.021436246151694, 7.5387751433404153],
					[65.024075106027595, 7.5370251692228942],
					[65.026761201866378, 7.5354334712611468],
					[65.026761207227239, 7.5354334688441655],
					[65.026761216134304, 7.5354334619866261],
					[65.026761220598232, 7.5354334596188224],
					[65.029655721209465, 7.5332307593066199],
					[65.029655727446396, 7.5332307547175104],
					[65.029655739002536, 7.5332307434664303],
					[65.029655745239467, 7.5332307388773412],
					[65.032427905316453, 7.5303279798448601],
					[65.035472250087977, 7.5295473949966931],
					[65.035472290262348, 7.5295473736704617],
					[65.035472356113971, 7.5295473169501479],
					[65.035472382667251, 7.5295472793840723],
					[65.037016874296086, 7.5260195023034901],
					[65.039275149028114, 7.5230279342729052],
					[65.041763941677033, 7.5206808350805412],
					[65.043422120921292, 7.5213918847275458],
					[65.043422134417327, 7.521391888228683],
					[65.043422159615432, 7.5213918953303196],
					[65.043422173111409, 7.5213918988314408],
					[65.044447172682496, 7.5215307986292306],
					[65.044447233590049, 7.521530786757376],
					[65.044447338027567, 7.5215307299848249],
					[65.044447380660671, 7.5215306851337891],
					[65.046311278755013, 7.5175917899773586],
					[65.048144567267641, 7.5141668113888374],
					[65.049961247781667, 7.5119640350881136],
					[65.052041839979054, 7.5099195428646262],
					[65.052041840854798, 7.5099195406906398],
					[65.052041844421382, 7.5099195383663506],
					[65.0520418453183, 7.5099195383162582],
					[65.05394184499751, 7.5079195382923238],
					[65.053941850336699, 7.5079195337432285],
					[65.053941858303133, 7.5079195226715028],
					[65.053941862724187, 7.5079195160485481],
					[65.055686262652884, 7.5054834151051759],
					[65.055686270619134, 7.5054834040319953],
					[65.05568628469409, 7.5054833756135757],
					[65.055686289927152, 7.5054833604427849],
					[65.057111286782373, 7.5010306720479862],
					[65.058130759817729, 7.4987973296723371],
					[65.060766791107866, 7.4968584807525369],
					[65.064211158728639, 7.4957945916092399],
					[65.064211173036668, 7.4957945865499838],
					[65.064211198919267, 7.4957945723335788],
					[65.064211211412058, 7.4957945652506854],
					[65.066914010621744, 7.4939807652319113],
					[65.06691401598188, 7.4939807628028481],
					[65.066914025783831, 7.4939807558703606],
					[65.066914030225689, 7.4939807513668963],
					[65.070347281619263, 7.4910251939581922],
					[65.075627791251932, 7.4905474011174693],
					[65.079152733373675, 7.4911779899728881],
					[65.081769304802336, 7.4925945758517285],
					[65.081769314732639, 7.4925945816722832],
					[65.081769335447376, 7.4925945890101744],
					[65.081769346253211, 7.4925945926537576],
					[65.086711045605199, 7.4939918924868154],
					[65.086711068070898, 7.4939918954723881],
					[65.086711112062645, 7.4939918972409707],
					[65.086711135382501, 7.493991895922548],
					[65.09195003541916, 7.4930640978503993],
					[65.091950038985431, 7.4930640955203316],
					[65.091950046160775, 7.493064095114315],
					[65.091950049748434, 7.4930640949113076],
					[65.094833347742863, 7.4923223941305901],
					[65.099102845936983, 7.4913057956155269],
					[65.099102855781624, 7.4913057929287916],
					[65.099102873655653, 7.491305785529347],
					[65.099102882603361, 7.4913057828934217],
					[65.102033383404105, 7.4899723824869433],
					[65.102033385176469, 7.4899723802573224],
					[65.102033391433437, 7.4899723777733023],
					[65.102033393227273, 7.4899723776715437],
					[65.104969492617101, 7.4884223780036816],
					[65.104969515765077, 7.4884223596544937],
					[65.104969556658091, 7.4884223211336334],
					[65.104969574381684, 7.4884222988338633],
					[65.107175160793844, 7.4845195218630778],
					[65.109983437418862, 7.4816779449773163],
					[65.11216953215461, 7.4797501500457546],
					[65.11481123062093, 7.4774584500616585],
					[65.114811261558529, 7.4774584035586997],
					[65.114811291890604, 7.4774582974472024],
					[65.114811290409818, 7.4774582400188843],
					[65.113636290385287, 7.4737860384290684],
					[65.113636285792396, 7.4737860280424337],
					[65.113636275687966, 7.4737860051918465],
					[65.113636271116718, 7.4737859969339899],
					[65.11103347071824, 7.4695165966138815],
					[65.111033458884577, 7.4695165802560126],
					[65.111033431673079, 7.4695165520037969],
					[65.111033417170461, 7.469516537929259],
					[65.107589016912286, 7.4670387387660888],
					[65.107589006959245, 7.4670387308212485],
					[65.107588987096705, 7.4670387191881584],
					[65.107588976290302, 7.4670387155515732],
					[65.103038975951279, 7.4651554139478709],
					[65.103038968754277, 7.4651554122336758],
					[65.103038955257119, 7.4651554087536054],
					[65.103038948060075, 7.4651554070394308],
					[65.099052848000568, 7.4641720059145582],
					[65.099052813874593, 7.4641720036255421],
					[65.09905274662863, 7.4641720096337574],
					[65.099052714427359, 7.4641720200068695],
					[65.096113813843601, 7.4655637197691052],
					[65.096113801352317, 7.4655637268748496],
					[65.096113777288437, 7.465563743161999],
					[65.096113764818924, 7.4655637523951102],
					[65.092905491900183, 7.4685026271906416],
					[65.090322150972824, 7.4695359045940117],
					[65.086349978219189, 7.4699665007458149],
					[65.086349934401085, 7.469966516029686],
					[65.086349856869859, 7.4699665694232831],
					[65.086349824010171, 7.4699666032283094],
					[65.084455442338168, 7.4734331725641647],
					[65.083708302968887, 7.4740691353135436],
					[65.081833432869772, 7.4723942499442888],
					[65.081833412985915, 7.4723942361919748],
					[65.081833369673973, 7.4723942131454875],
					[65.081833347164462, 7.4723942059261645],
					[65.07810839970854, 7.471491518112213],
					[65.075036239276685, 7.4685248557285009],
					[65.07503622300186, 7.4685248439020553],
					[65.075036188680102, 7.4685248224779262],
					[65.075036169736322, 7.4685248129317809],
					[65.070630669917747, 7.4668692120754594],
					[65.070630633954337, 7.4668692056374555],
					[65.070630561282996, 7.466869207690408],
					[65.070630523656376, 7.4668692141075939],
					[65.067083223999205, 7.4683192144756081],
					[65.067083208838753, 7.4683192238517897],
					[65.067083181186788, 7.4683192403245027],
					[65.067083166923226, 7.4683192496491593],
					[65.063347066869881, 7.4716276501847396],
					[65.063347052671219, 7.4716276658805691],
					[65.063347027883182, 7.4716276991913828],
					[65.063347018212454, 7.471627718879696],
					[65.061591517880231, 7.4755055188292978],
					[65.06159151612971, 7.4755055231810834],
					[65.061591510856445, 7.4755055341118473],
					[65.061591510002813, 7.4755055384123628],
					[65.060488713190026, 7.478944326998664],
					[65.057344221009046, 7.4858332108585639],
					[65.054260938837686, 7.4913609789537032],
					[65.049986203046274, 7.4956496134344315],
					[65.048261295089404, 7.4892221548088642],
					[65.046975195843586, 7.4826999599487145],
					[65.046152995632227, 7.4786055610572051],
					[65.046152960026163, 7.4786055014727655],
					[65.046152856892206, 7.4786054202424044],
					[65.04615279117975, 7.4786054006176359],
					[65.041783290904519, 7.4788081002101414],
					[65.041783241703655, 7.4788081157597732],
					[65.041783157890123, 7.4788081693947719],
					[65.041783123277483, 7.4788082074801396],
					[65.03966094513008, 7.4828248656495875],
					[65.037008183415679, 7.4847692374817081],
					[65.034636063704696, 7.4864691801334295],
					[65.032225083820961, 7.4857248165486459],
					[65.029994506101175, 7.4843304307759713],
					[65.02999449977969, 7.4843304268880306],
					[65.029994486239858, 7.4843304191630926],
					[65.029994479939873, 7.4843304173972713],
					[65.027955680179772, 7.4834442168004482],
					[65.027955601207722, 7.4834442170381008],
					[65.027955468040147, 7.4834442713093026],
					[65.027955412990764, 7.4834443296376909],
					[65.026199823409485, 7.4880610038182507],
					[65.02422486696112, 7.4907304446053216],
					[65.021502807983168, 7.4921469689848292],
					[65.01989450410403, 7.4911637287992772],
					[65.019894478861659, 7.4911637174895063],
					[65.019894427565603, 7.4911637034059018],
					[65.019894400615044, 7.4911637006827361],
					[65.018127801156865, 7.491155399907699],
					[65.018127740337306, 7.4911554203210633],
					[65.018127643257813, 7.491155493719349],
					[65.018127606997865, 7.491155546704249],
					[65.017522006624731, 7.4933416470993963],
					[65.017522005770545, 7.4933416513922388],
					[65.017522004062229, 7.4933416599779425],
					[65.017522003208086, 7.4933416642707833],
					[65.015997003978327, 7.5016805587495012],
					[65.014516507749008, 7.5079110448136994],
					[65.011710919893048, 7.5162693098498723],
					[65.009205488855329, 7.5198387099861375],
					[65.006777838202709, 7.520080394451556],
					[65.005089083753134, 7.5192387671791456],
					[65.004197398557665, 7.5125527811203128],
					[65.003744599619154, 7.5049471886094459],
					[65.00374459863734, 7.5049471801793795],
					[65.003744596694958, 7.504947165439261],
					[65.003744595734403, 7.5049471591292471],
					[65.002369597043554, 7.4983916654790841],
					[65.001655798282343, 7.4933638730376311],
					[65.00165576174247, 7.4933638093426502],
					[65.001655656779448, 7.4933637240496687],
					[65.001655588335012, 7.4933637003317459],
					[64.999072225871217, 7.4935164969613428],
					[64.996236181150778, 7.4925776154256081],
					[64.993716798149933, 7.491155425924827],
					[64.993716792725564, 7.4911554219898484],
					[64.993716781898172, 7.4911554162391809],
					[64.993716775598301, 7.4911554144740382],
					[64.991636198271337, 7.4903026232192031],
					[64.989744517857815, 7.4889248386745191],
					[64.989744447661636, 7.4889248193173321],
					[64.989744314083765, 7.4889248332216916],
					[64.989744249805213, 7.4889248665339103],
					[64.987783149691623, 7.4911387677169641],
					[64.987783139953876, 7.4911387809859802],
					[64.987783122315037, 7.4911388116604103],
					[64.987783114392542, 7.4911388269470498],
					[64.9867776165827, 7.4936832184549242],
					[64.984719238327315, 7.4977359762423594],
					[64.982841565869734, 7.4994387517977161],
					[64.982841547247062, 7.4994387740376878],
					[64.982841517262344, 7.4994388265889738],
					[64.982841505857735, 7.4994388526634594],
					[64.981499807217446, 7.5050388457660526],
					[64.979738710329215, 7.5104416382858545],
					[64.978294210063027, 7.5148582376194959],
					[64.978294210084101, 7.5148582397375439],
					[64.978294209208215, 7.514858241905352],
					[64.978294208311297, 7.5148582419551104],
					[64.976677614150091, 7.5202443220198614],
					[64.974449823982255, 7.5244221060444998],
					[64.974449819581267, 7.524422114762686],
					[64.974449812552265, 7.5244221299824279],
					[64.974449809945185, 7.5244221386017527],
					[64.973099810103108, 7.5286110382947609],
					[64.9730998066197, 7.5286110490801841],
					[64.973099802343739, 7.5286110705031488],
					[64.973099800654225, 7.5286110811899967],
					[64.972735902375177, 7.5326305718603246],
					[64.971633106999164, 7.5383055468799745],
					[64.969335916716176, 7.5446360169751641],
					[64.967052624270238, 7.5489166066314297],
					[64.967052623373306, 7.5489166066800228],
					[64.96705262159999, 7.5489166088944026],
					[64.967052620723621, 7.5489166110601884],
					[64.965688720623547, 7.5516249106255247],
					[64.965688717994311, 7.5516249171223047],
					[64.965688711859499, 7.5516249322814311],
					[64.965688709250813, 7.5516249408952989],
					[64.963685908587024, 7.5580527417456631],
					[64.963685907710541, 7.5580527439108769],
					[64.963685905977997, 7.5580527503582617],
					[64.963685905998446, 7.5580527524751959],
					[64.961785908388151, 7.5658471436806023],
					[64.959874810168643, 7.5715499368957158],
					[64.959874810168643, 7.5715499368957158],
					[64.959874810188907, 7.5715499390123613],
					[64.959874810188907, 7.5715499390123613],
					[64.958027610342612, 7.5772971391488984],
					[64.958027608568841, 7.5772971413606509],
					[64.958027607712268, 7.5772971456412845],
					[64.958027606835472, 7.5772971478054343],
					[64.956605406584117, 7.5824999472999348],
					[64.956605405747467, 7.5824999536965949],
					[64.956605403177207, 7.5824999665373438],
					[64.956605402340557, 7.5824999729339826],
					[64.955622002109124, 7.5893832715923697],
					[64.955622002129132, 7.5893832737086964],
					[64.955622002149141, 7.5893832758250426],
					[64.955622001252195, 7.5893832758722546],
					[64.954863702368115, 7.595605567308815],
					[64.953147004654596, 7.6040943538948023],
					[64.951141512047627, 7.6113388272502727],
					[64.948305432806251, 7.6170692865062177],
					[64.945233164976514, 7.6204359520458897],
					[64.941899878624653, 7.6229526389954732],
					[64.941899875972751, 7.6229526433642274],
					[64.941899869732936, 7.6229526479172103],
					[64.941899867061508, 7.6229526501706717],
					[64.938641580101176, 7.6258609382139921],
					[64.937035993971222, 7.6268581290182738],
					[64.937035970844335, 7.6268581513611924],
					[64.937035930888854, 7.6268581978405123],
					[64.937035914976775, 7.6268582240458658],
					[64.934333258598059, 7.6334717711958486],
					[64.929827968304167, 7.6265609907107335],
					[64.929827852164053, 7.6265609501187424],
					[64.929827668748857, 7.6265610102755304],
					[64.929827599679783, 7.6265611111161622],
					[64.93033870018742, 7.6353083044095387],
					[64.930197000182389, 7.6455193969525546],
					[64.930197000240014, 7.6455194032956681],
					[64.930197000336079, 7.6455194138675431],
					[64.93019700127148, 7.6455194180510198],
					[64.930863700772051, 7.6527667184033836],
					[64.930863707280352, 7.6527667434612798],
					[64.930863724781901, 7.6527667933518622],
					[64.930863737550084, 7.6527668159800282],
					[64.9330804381961, 7.6558445165449012],
					[64.933080442738273, 7.6558445226640206],
					[64.933080452738707, 7.6558445369719017],
					[64.933080459074915, 7.6558445430011313],
					[64.936297036709988, 7.6590390193145863],
					[64.938497012742801, 7.6632861746888414],
					[64.93994150622828, 7.668288950460429],
					[64.940707915377118, 7.6715548353906762],
					[64.937650160937793, 7.6679442789989309],
					[64.934191869757598, 7.6626276929760939],
					[64.930922372392004, 7.6571054983235127],
					[64.93092236512075, 7.6571054881104867],
					[64.930922350597299, 7.6571054697988705],
					[64.930922343326088, 7.6571054595858419],
					[64.927433442944718, 7.6535387591669064],
					[64.927433433001326, 7.6535387512053754],
					[64.927433411301493, 7.6535387332581317],
					[64.927433399583208, 7.6535387275007611],
					[64.923969504759626, 7.6515331310229779],
					[64.920611209694542, 7.649333132996186],
					[64.92061109308473, 7.6493331388639358],
					[64.920610944262918, 7.6493332541964953],
					[64.920610911173057, 7.6493333658200831],
				],
			],
		},
		{
			fldNpdidField: "26376286",
			wlbNpdidWellbore: "6374",
			fldName: "JOHAN SVERDRUP",
			fldCurrentActivitySatus: "Producing",
			wlbName: "16/2-6",
			fldDiscoveryYear: "2010",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=26376286",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=26376286",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "JOHAN SVERDRUP UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=6374",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "26312510",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-07-09 00:00:00",
			fldMainSupplyBase: "Dusavik",
			fldAreaGeometryWKT: [
				[
					[58.658791533784687, 2.626608273784063],
					[58.658791605031297, 2.6266084387654081],
					[58.658791695437472, 2.6266084999918808],
					[58.661399995023658, 2.6266779996346599],
					[58.661400045812215, 2.6266779886069531],
					[58.661400134339047, 2.6266779395559952],
					[58.661400172077315, 2.626677901532759],
					[58.663289072096347, 2.6235112026769194],
					[58.663289076940281, 2.6235111914177383],
					[58.663289086713348, 2.6235111706123959],
					[58.663289090664783, 2.623511159516704],
					[58.664647366363823, 2.619169540738238],
					[58.667166791882259, 2.6178723775337405],
					[58.667166816190203, 2.6178723592451925],
					[58.667166859195341, 2.6178723185106096],
					[58.667166876829441, 2.6178722928018487],
					[58.669022374399518, 2.6143417997015255],
					[58.670852940761584, 2.6113668523696312],
					[58.673102890149146, 2.6102390790349848],
					[58.673102913563383, 2.6102390609017072],
					[58.673102958265375, 2.6102390181091284],
					[58.67310297606852, 2.6102389958189489],
					[58.675033463967694, 2.6066612161640625],
					[58.677230741007399, 2.6042529436096475],
					[58.679608433368912, 2.6021473501384063],
					[58.6796084419517, 2.6021473416417984],
					[58.679608458946305, 2.602147321221091],
					[58.679608467443607, 2.6021473110107367],
					[58.681419567256064, 2.5994195116359187],
					[58.681419572183252, 2.5994195020813189],
					[58.681419581230685, 2.5994194848502037],
					[58.681419585265367, 2.5994194754598521],
					[58.683072350373962, 2.5953918606585757],
					[58.685633155940096, 2.5945224601747698],
					[58.686597002384389, 2.5985667311675784],
					[58.687052599248432, 2.6035722033638171],
					[58.686747000259366, 2.6086471876296988],
					[58.686746999708518, 2.6086471946502301],
					[58.686747000306404, 2.6086472066490756],
					[58.686746999670127, 2.60864721195549],
					[58.687058100273951, 2.6136611100224814],
					[58.687271999864805, 2.6187528082541447],
					[58.687271999950077, 2.6187528099683215],
					[58.687272001013135, 2.6187528132329105],
					[58.687272001183686, 2.6187528166612655],
					[58.687644200648087, 2.6233639156576123],
					[58.68764420196667, 2.6233639240650191],
					[58.687644203626128, 2.6233639393292871],
					[58.687644204859495, 2.6233639460224807],
					[58.688727600113666, 2.6279528207129221],
					[58.688627600084082, 2.6330027953157447],
					[58.688627600169184, 2.633002797030036],
					[58.688627600339366, 2.6330028004586365],
					[58.688627600339366, 2.6330028004586365],
					[58.688663699960983, 2.6381722017991787],
					[58.688663700046007, 2.6381722035134905],
					[58.688663700301078, 2.6381722086564379],
					[58.688663700386087, 2.6381722103707537],
					[58.688908099985696, 2.6432889100005088],
					[58.688908101048135, 2.6432889132660207],
					[58.688908100665302, 2.6432889237152479],
					[58.688908101727748, 2.6432889269807616],
					[58.689505402166056, 2.6476222265334437],
					[58.689505402250951, 2.6476222282478443],
					[58.690210901644569, 2.6525583288791923],
					[58.690210904916064, 2.6525583403914372],
					[58.690210911628718, 2.6525583668448069],
					[58.690210915792747, 2.6525583781941116],
					[58.691885915931287, 2.6564361784461377],
					[58.691885993659973, 2.6564362386543787],
					[58.691886162390666, 2.6564362632290117],
					[58.691886252584887, 2.6564362294727863],
					[58.693508405092672, 2.6545168858398207],
					[58.696030500610284, 2.6541030187680681],
					[58.698044230093473, 2.6573334055299802],
					[58.698044257673438, 2.6573334351035829],
					[58.698044322058209, 2.6573334804553621],
					[58.698044358863029, 2.6573334962335307],
					[58.700316659376909, 2.6578057962519184],
					[58.700316710592467, 2.6578057938281012],
					[58.70031680711967, 2.657805760640616],
					[58.700316849838345, 2.6578057320805311],
					[58.702544549898192, 2.6552612316430624],
					[58.702544565754465, 2.655261206251311],
					[58.702544590241722, 2.6552611550563703],
					[58.702544597895297, 2.6552611277010865],
					[58.703214096808424, 2.6503722351716617],
					[58.704252963131296, 2.6457084887780331],
					[58.706775016276573, 2.6455112990583958],
					[58.706775045645699, 2.6455112919566952],
					[58.706775103066718, 2.6455112693403611],
					[58.706775129248605, 2.6455112524369642],
					[58.709111101029961, 2.6435308602550802],
					[58.711497071917151, 2.6455223548602844],
					[58.711497121643639, 2.6455223769227212],
					[58.711497225900423, 2.6455223907419856],
					[58.711497279538186, 2.6455223826620506],
					[58.713852879922591, 2.6444973829087597],
					[58.713852880815132, 2.6444973827454628],
					[58.713852882600222, 2.6444973824188653],
					[58.713852884300358, 2.6444973803767002],
					[58.715888983794706, 2.6435529815161392],
					[58.715889026169755, 2.6435529460619929],
					[58.715889083038952, 2.6435528577500462],
					[58.715889100295676, 2.6435528061179081],
					[58.716041898161762, 2.6385861663277934],
					[58.717889062640573, 2.6359779163720609],
					[58.717889064340568, 2.6359779143292346],
					[58.717889065148007, 2.6359779124499552],
					[58.719944564761519, 2.6329640131422334],
					[58.719944565568923, 2.6329640112627724],
					[58.719944568161395, 2.6329640090560389],
					[58.719944568076272, 2.6329640073402083],
					[58.722030702767775, 2.6297169091071577],
					[58.723416470794021, 2.6298334803586849],
					[58.722702601647207, 2.6346082697636963],
					[58.72270260135037, 2.634608281939145],
					[58.722702599779048, 2.6346083047376463],
					[58.722702601182156, 2.6346083148698911],
					[58.723069200995724, 2.639458315795459],
					[58.723069280178073, 2.6394584051867223],
					[58.723069467696646, 2.6394584452970435],
					[58.723069576840381, 2.6394583941366054],
					[58.724736276696255, 2.6363139939437579],
					[58.724736280733502, 2.6363139845454677],
					[58.724736288722966, 2.6363139640327984],
					[58.724736291867707, 2.6363139547980801],
					[58.725972392245801, 2.6319389536327082],
					[58.725972393860566, 2.6319389498730654],
					[58.725972396197598, 2.6319389425174564],
					[58.725972395942186, 2.6319389373690676],
					[58.726883494588975, 2.627150042606277],
					[58.728047393717276, 2.6224889477562034],
					[58.728047397412432, 2.6224889314906661],
					[58.728047399277187, 2.6224888965108883],
					[58.728047399231805, 2.6224888774687525],
					[58.72753080341613, 2.617783314452832],
					[58.728641884947145, 2.6132917867084049],
					[58.730800162305023, 2.6103084170297368],
					[58.730800170546438, 2.6103084016565798],
					[58.730800186051347, 2.6103083693582434],
					[58.730800193314835, 2.610308352433067],
					[58.732055791750739, 2.6057806555107415],
					[58.733372391824325, 2.6012667559821168],
					[58.733372393352589, 2.6012667505038833],
					[58.733372396409145, 2.601266739547432],
					[58.733372396959417, 2.6012667325173391],
					[58.734194554998957, 2.5963419833098422],
					[58.736263722979849, 2.5972279427441971],
					[58.737172003114196, 2.602050031610545],
					[58.737860900867112, 2.6070472212261895],
					[58.73824420103665, 2.6120722152934666],
					[58.738244201292893, 2.612072220443455],
					[58.738244201719951, 2.612072229026781],
					[58.73824420286865, 2.6120722340125049],
					[58.73907760316709, 2.6169139336949478],
					[58.739077607761239, 2.6169139536389525],
					[58.739077622133784, 2.6169139891085247],
					[58.739077631105033, 2.6169140065149792],
					[58.740874831160006, 2.619769507749139],
					[58.740874911793213, 2.6197695535607681],
					[58.74087507861158, 2.6197695575378059],
					[58.740875163011729, 2.6197695160314498],
					[58.742930754357296, 2.6168862282503884],
					[58.745200117409794, 2.6145584665957005],
					[58.747741783226218, 2.6134057816867098],
					[58.747741812802118, 2.6134057606458727],
					[58.747741863409153, 2.6134057097401393],
					[58.747741884354866, 2.6134056781581316],
					[58.749458405950442, 2.6094363584304019],
					[58.751658102913765, 2.6103334364941979],
					[58.751760900099931, 2.615236103540072],
					[58.75176090078299, 2.6152361172788159],
					[58.751760904741168, 2.6152361425460824],
					[58.751760907123796, 2.6152361542388944],
					[58.752966506716994, 2.6195528544611073],
					[58.752966523681238, 2.6195528877356491],
					[58.752966567978369, 2.6195529454448052],
					[58.752966596289085, 2.6195529714326438],
					[58.754847095753135, 2.6206973712047059],
					[58.754847162099807, 2.6206973832654059],
					[58.754847287390753, 2.620697367151493],
					[58.75484734705686, 2.6206973353777103],
					[58.756805581342199, 2.6185753154921141],
					[58.758949825792207, 2.6215584002612817],
					[58.760677593616471, 2.6253778292540111],
					[58.759980407773462, 2.6302443298325704],
					[58.757972061657362, 2.6334581423979273],
					[58.755449917287152, 2.6346081171470015],
					[58.755449878315417, 2.6346081485650874],
					[58.755449820943859, 2.6346082266907094],
					[58.755449801566371, 2.6346082718444825],
					[58.754824801858, 2.6391027727708893],
					[58.754824802581702, 2.6391028055684758],
					[58.7548248137619, 2.6391028676453976],
					[58.754824825195982, 2.6391028984785878],
					[58.756622002399993, 2.6423139564479645],
					[58.756746997914604, 2.6452027601061516],
					[58.755199819100362, 2.6485305162328237],
					[58.755199812001784, 2.6485305365978222],
					[58.755199802267384, 2.6485305765105114],
					[58.755199799716472, 2.6485305977758289],
					[58.755160899743444, 2.653705598760888],
					[58.755160901399847, 2.6537056140563156],
					[58.755160905605216, 2.653705644483821],
					[58.755160909046715, 2.6537056594525925],
					[58.756305409224254, 2.6573639601543637],
					[58.756305430896376, 2.6573639977880368],
					[58.7563054898826, 2.6573640615285292],
					[58.756305527111877, 2.6573640859176275],
					[58.758786021804283, 2.6583390846779946],
					[58.760597117902641, 2.6591584822219696],
					[58.760597192833472, 2.6591584858537796],
					[58.760597324638198, 2.6591584374827169],
					[58.760597380619565, 2.6591583856430794],
					[58.76226127757328, 2.6556583919038141],
					[58.764144520897261, 2.6523001924631529],
					[58.76588328592694, 2.6521946010572144],
					[58.767819361392228, 2.6525807955773573],
					[58.767819446737555, 2.6525807730143427],
					[58.767819565749662, 2.6525806662652864],
					[58.767819598608774, 2.6525805839609409],
					[58.767464105226402, 2.6482472521748384],
					[58.769539066988351, 2.6451251114253824],
					[58.769539071918565, 2.6451251018519502],
					[58.769539083479145, 2.6451250806594779],
					[58.769539086539282, 2.6451250696950095],
					[58.77109458739271, 2.6409361706081262],
					[58.771094593257139, 2.6409361435223278],
					[58.771094597930741, 2.6409360923791971],
					[58.771094596569853, 2.6409360648850781],
					[58.770461315673572, 2.6373556717294724],
					[58.772186047822565, 2.6357086265073337],
					[58.773716524310416, 2.6385583949375189],
					[58.773716557590987, 2.6385584304474006],
					[58.773716637666716, 2.6385584833792595],
					[58.773716686161869, 2.6385584987550579],
					[58.776397185844182, 2.6387529987075253],
					[58.7763971976172, 2.6387530000147845],
					[58.776397220907967, 2.6387529974733908],
					[58.776397232595883, 2.6387529970620172],
					[58.77902503309668, 2.6383084971908635],
					[58.779025086689714, 2.6383084700068098],
					[58.779025169138421, 2.638308388961693],
					[58.779025197101511, 2.6383083352645076],
					[58.779836297083584, 2.6338778356107366],
					[58.779836297591267, 2.6338778095009037],
					[58.779836294229177, 2.6338777598199665],
					[58.779836289381741, 2.6338777346940967],
					[58.778436290630985, 2.6298360401862255],
					[58.777194622872038, 2.6254000550993313],
					[58.779330703000511, 2.6231362817370094],
					[58.781977810809465, 2.6225502022446321],
					[58.784611077679685, 2.622844599167804],
					[58.784611138284205, 2.6228445862758574],
					[58.784611240744283, 2.6228445275150332],
					[58.784611281792685, 2.6228444835294735],
					[58.78617785419987, 2.6193864654503241],
					[58.788433148895486, 2.6219807306947023],
					[58.788433159395524, 2.6219807426401922],
					[58.788433181839302, 2.6219807593257718],
					[58.788433193146503, 2.6219807693877506],
					[58.790858135004093, 2.6235112318323912],
					[58.791674812067015, 2.6258083671883403],
					[58.791674828859776, 2.6258083970632482],
					[58.79167487298475, 2.6258084514030098],
					[58.791674900146425, 2.6258084724291875],
					[58.793991600295627, 2.6271473737939135],
					[58.793991654954205, 2.6271473862915009],
					[58.793991762736702, 2.6271473803362286],
					[58.793991816753135, 2.627147361719127],
					[58.796150116989629, 2.6255973625385765],
					[58.796150117796842, 2.6255973606546825],
					[58.796150121281585, 2.6255973582778642],
					[58.796150123066575, 2.6255973579492413],
					[58.798380493618978, 2.6238670342131223],
					[58.799530402827507, 2.6275889406796828],
					[58.800063694728877, 2.6324555613676623],
					[58.798163727901205, 2.6360831981701347],
					[58.796047049657361, 2.6392887631896613],
					[58.793585974642468, 2.6412554435382125],
					[58.793585968649971, 2.6412554498439462],
					[58.793585955687419, 2.6412554608998247],
					[58.793585949694936, 2.6412554672055717],
					[58.791449863159322, 2.6436637523516158],
					[58.789678002108751, 2.6450412907154455],
					[58.789700200229127, 2.6400167346960797],
					[58.791180789323271, 2.6357833655442326],
					[58.791180793101795, 2.6357833145347382],
					[58.791180773798089, 2.635783215716943],
					[58.791180751693545, 2.6357831694639882],
					[58.789264051717467, 2.6335498697069184],
					[58.789263952855883, 2.6335498375673647],
					[58.789263780133759, 2.6335498779919653],
					[58.789263706273253, 2.6335499505561173],
					[58.788249809184066, 2.6376027406566647],
					[58.78680545614381, 2.641413716174287],
					[58.784155574788215, 2.6417498027240058],
					[58.784155548097353, 2.6417498093576395],
					[58.784155497818524, 2.6417498307285934],
					[58.7841554741455, 2.64174984374689],
					[58.781791574113534, 2.6436554442867055],
					[58.781791560428189, 2.643655458939254],
					[58.781791534035072, 2.6436554897994733],
					[58.781791523197391, 2.6436555073985866],
					[58.779902643341863, 2.6472637689659289],
					[58.777424916027663, 2.6491804117334952],
					[58.774749963346395, 2.6496831028233503],
					[58.774749953613338, 2.6496831063410582],
					[58.774749935847368, 2.6496831113307677],
					[58.774749927899421, 2.6496831145213604],
					[58.772244328108997, 2.6506554133106053],
					[58.772244295849106, 2.6506554348268963],
					[58.772244240679392, 2.6506554848167183],
					[58.772244218747076, 2.6506555148451678],
					[58.770552631733679, 2.6542442881803714],
					[58.768349851361279, 2.656697066424198],
					[58.768349829766798, 2.6566971033186046],
					[58.768349804938026, 2.6566971841505036],
					[58.768349801703764, 2.6566972280879901],
					[58.768969199868053, 2.6610556187891654],
					[58.769127598972261, 2.6655777843906789],
					[58.768208115408406, 2.6703999017057312],
					[58.766063888344469, 2.6725330281572002],
					[58.763919527681267, 2.6707387469839992],
					[58.763919474303052, 2.670738723790484],
					[58.763919361206391, 2.6707387132325047],
					[58.76391930327312, 2.6707387255421744],
					[58.761444491628531, 2.6721191194192055],
					[58.759716883411649, 2.6683055183754263],
					[58.758105782793663, 2.6646193193042835],
					[58.758105714246653, 2.66461926249531],
					[58.758105556696478, 2.6646192271464146],
					[58.758105468585875, 2.6646192484434446],
					[58.756324869085027, 2.6661554487561969],
					[58.756324857905064, 2.6661554594640879],
					[58.756324836691853, 2.6661554858700529],
					[58.75632482827433, 2.6661554978066309],
					[58.754608362415325, 2.6690606012950786],
					[58.753341891924109, 2.6647471432806697],
					[58.753341846753656, 2.6647470856749358],
					[58.753341728013815, 2.6647470155110415],
					[58.753341656314326, 2.6647470043444104],
					[58.750891656442896, 2.6653054051636031],
					[58.75089160301232, 2.6653054357163515],
					[58.750891525430589, 2.6653055244006461],
					[58.750891501194729, 2.665305580814727],
					[58.750422000726829, 2.6701527802282401],
					[58.750422000257494, 2.6701527889784145],
					[58.750422000126726, 2.6701528045984344],
					[58.750421999572751, 2.670152811631135],
					[58.750727599685476, 2.6752611122364041],
					[58.750727627084139, 2.6752611748244299],
					[58.750727715423849, 2.675261267891003],
					[58.750727776364904, 2.6752612983695654],
					[58.752419375917903, 2.6754640981666467],
					[58.752419417146342, 2.6754640941152092],
					[58.752419492593859, 2.6754640716934199],
					[58.752419528598104, 2.6754640529975702],
					[58.754713873430816, 2.673522485711441],
					[58.756894243990693, 2.6762362241484041],
					[58.756894250746299, 2.67623623331587],
					[58.756894264896353, 2.6762362463346574],
					[58.756894271567376, 2.6762362537843307],
					[58.759216571556983, 2.678166853916109],
					[58.759216573426748, 2.6781668553085702],
					[58.759216575296477, 2.6781668567010386],
					[58.759216576189083, 2.6781668565383101],
					[58.761599751200258, 2.6800528580985152],
					[58.759913760614012, 2.6836165276280841],
					[58.757316654716561, 2.6842137054368527],
					[58.757316638734096, 2.6842137100807535],
					[58.757316608723315, 2.6842137224791487],
					[58.757316593802379, 2.6842137303961824],
					[58.754805494295667, 2.685785929968096],
					[58.754805459218609, 2.6857859675498146],
					[58.754805412308677, 2.6857860575456578],
					[58.7548054003069, 2.6857861065243496],
					[58.754927600447694, 2.6892889074001718],
					[58.754927600869692, 2.6892889159889011],
					[58.754927602690685, 2.6892889347216848],
					[58.754927604982285, 2.6892889447033301],
					[58.755935876745383, 2.6937166191955346],
					[58.75392767734224, 2.6952748427406403],
					[58.753927666075363, 2.6952748517208054],
					[58.753927645579793, 2.6952748745097717],
					[58.753927636182475, 2.6952748848831445],
					[58.752152635548356, 2.6978054846167527],
					[58.752152624871229, 2.6978055056190655],
					[58.752152608148712, 2.6978055502480909],
					[58.752152602103322, 2.6978055738747848],
					[58.751505419167962, 2.7026581475131275],
					[58.748916640215946, 2.7034748081201379],
					[58.74891660178173, 2.7034748324236149],
					[58.748916538892367, 2.7034748906228563],
					[58.748916513628778, 2.7034749263981288],
					[58.747349935235661, 2.7074607172462573],
					[58.745219551947251, 2.7049748699058069],
					[58.74521955097044, 2.7049748683504387],
					[58.745219550077792, 2.7049748685123851],
					[58.745219549100966, 2.7049748669570217],
					[58.742911248836656, 2.702380467221102],
					[58.742911193257108, 2.7023804357284833],
					[58.742911072346367, 2.7023804126275661],
					[58.742911007992014, 2.7023804225744796],
					[58.740577739722696, 2.7035915066029439],
					[58.737949975582545, 2.7039054009365526],
					[58.737949915808819, 2.7039054308343751],
					[58.737949829355998, 2.703905522735496],
					[58.737949800807463, 2.7039055833456791],
					[58.737560900857908, 2.7084082833861758],
					[58.737560915659216, 2.708408346526809],
					[58.737560982804609, 2.7084084486805837],
					[58.737561034340153, 2.7084084895724927],
					[58.740138833904268, 2.7093168887927983],
					[58.740138847546447, 2.7093168915169592],
					[58.740138875807503, 2.7093168985205462],
					[58.740138891150835, 2.7093168992040022],
					[58.742736031707558, 2.7094390976190228],
					[58.745258184739477, 2.7112223625012968],
					[58.745258187585627, 2.7112223654506069],
					[58.745258194086517, 2.7112223694701076],
					[58.745258196932667, 2.7112223724194178],
					[58.747824832220765, 2.7127584320695899],
					[58.748916508005699, 2.71625005738382],
					[58.74978590775752, 2.7192722546905892],
					[58.749785939903383, 2.7192723043202975],
					[58.749786028097901, 2.719272376725884],
					[58.749786083253937, 2.7192723996633772],
					[58.75242218277932, 2.719502999771986],
					[58.752422245906828, 2.7195029831435824],
					[58.752422349782627, 2.7195029158142177],
					[58.752422390446931, 2.7195028633955309],
					[58.753691860015635, 2.7156529545267754],
					[58.756266501203321, 2.7146919874768591],
					[58.756274799765698, 2.7185666942061739],
					[58.755991511700174, 2.7232775955512514],
					[58.753716705026669, 2.7232164995137196],
					[58.753716643851817, 2.7232165192487066],
					[58.753716546474493, 2.7232165905924863],
					[58.753716509379338, 2.7232166423628299],
					[58.752380410777143, 2.7276055336167757],
					[58.750777613694083, 2.7316416265383658],
					[58.750777622684339, 2.7316417184866193],
					[58.750777713081483, 2.7316418546414836],
					[58.75077779278687, 2.7316419008883854],
					[58.752133293455628, 2.7316891004530262],
					[58.752133305953301, 2.731689098194698],
					[58.752133332901643, 2.731689096790936],
					[58.752133346291998, 2.7316890943713013],
					[58.754805645605558, 2.7310584943447185],
					[58.754805650068995, 2.7310584935380082],
					[58.754805658912041, 2.7310584902067112],
					[58.754805663375464, 2.7310584894000041],
					[58.75745813967918, 2.7301780646424643],
					[58.758094191831127, 2.734083265463132],
					[58.756163730428121, 2.7376831956089043],
					[58.754169236917917, 2.7404998836434551],
					[58.754169227851612, 2.7404999008764119],
					[58.754169213122331, 2.7404999312621645],
					[58.75416920673414, 2.7404999480118333],
					[58.753855406622257, 2.7416638479625139],
					[58.753855402605204, 2.7416638764147487],
					[58.753855405367261, 2.7416639331043129],
					[58.753855410360948, 2.7416639616637721],
					[58.755255410259331, 2.7460361613651387],
					[58.755255410342961, 2.7460361630830885],
					[58.755255412379334, 2.7460361679149998],
					[58.755255413355684, 2.7460361694720006],
					[58.756766512810188, 2.7500500703772128],
					[58.756766522740463, 2.7500500893849766],
					[58.756766548040929, 2.7500501281533443],
					[58.756766562351224, 2.7500501446387355],
					[58.759147041596776, 2.7523223265959267],
					[58.760924816257933, 2.7553361827535392],
					[58.762080408595494, 2.759061158544617],
					[58.762080490353689, 2.7590612287644127],
					[58.762080668167897, 2.7590612556962268],
					[58.762080766009397, 2.7590612120869022],
					[58.764064065653251, 2.7561307125123129],
					[58.76606117503075, 2.7531919458092156],
					[58.768596965523585, 2.7542001214373588],
					[58.767619216838661, 2.758324904415872],
					[58.765299851003071, 2.7609220665601151],
					[58.76529984087437, 2.7609220805172794],
					[58.765299823295209, 2.7609221079496957],
					[58.765299815035441, 2.7609221233040642],
					[58.763685914905473, 2.764802723622934],
					[58.763685913286771, 2.7648027273808475],
					[58.763685910216203, 2.7648027383335352],
					[58.763685907704762, 2.7648027422519998],
					[58.762447010593043, 2.7689388352986266],
					[58.760894235299872, 2.7729248741002506],
					[58.758727680980101, 2.7745331396606447],
					[58.758727647994228, 2.7745331837111871],
					[58.758727609471421, 2.7745332807504104],
					[58.758727603125024, 2.7745333356175674],
					[58.759419202963933, 2.7784778344597987],
					[58.759419206307939, 2.7784778477254095],
					[58.759419213805522, 2.7784778723782373],
					[58.759419217959085, 2.7784778837654382],
					[58.761124817920077, 2.7821833836396848],
					[58.761124879027541, 2.7821834368142757],
					[58.761125020817396, 2.7821834824558689],
					[58.76112510222621, 2.7821834713261127],
					[58.762774965355874, 2.7812085527079757],
					[58.764724759988461, 2.7832472374642143],
					[58.763210919708008, 2.7863805125584058],
					[58.76321091339689, 2.7863805310229584],
					[58.763210904345719, 2.7863805673121669],
					[58.763210899903257, 2.7863805871752381],
					[58.762885900101139, 2.791186086182273],
					[58.762885959793266, 2.7911861846951709],
					[58.762886132703585, 2.7911862594699821],
					[58.762886246004832, 2.79118623745038],
					[58.76489184558875, 2.7890390376737479],
					[58.764891849803433, 2.789039031718453],
					[58.76489185831592, 2.7890390215264458],
					[58.764891861720905, 2.7890390174496282],
					[58.76694176485357, 2.786200251092195],
					[58.76940551573405, 2.7873473807560947],
					[58.769405533195538, 2.7873473880289641],
					[58.769405569654758, 2.7873473970983818],
					[58.769405587842819, 2.7873474007736814],
					[58.772002787986921, 2.7874974001656767],
					[58.77200281217516, 2.7874973975644943],
					[58.772002856814254, 2.7874973895643267],
					[58.772002879050717, 2.787497383845329],
					[58.774594460292512, 2.7863779919164848],
					[58.777205579675744, 2.7858641089120884],
					[58.779774925339055, 2.7869057844851808],
					[58.779774978761154, 2.786905790514846],
					[58.779775082406765, 2.7869057736663119],
					[58.779775130844719, 2.7869057511082587],
					[58.78214733091783, 2.7848612508953852],
					[58.78214734291781, 2.7848612383370739],
					[58.782147365858705, 2.7848612099417638],
					[58.782147375906845, 2.7848611942649182],
					[58.783997366989013, 2.7813918112060727],
					[58.786233439282384, 2.7786695458313098],
					[58.788711217705242, 2.7768529610269574],
					[58.788711226383079, 2.7768529542640232],
					[58.788711239358186, 2.7768529432597857],
					[58.788711246250514, 2.7768529368176305],
					[58.791027946169528, 2.7743723365471973],
					[58.791027952978411, 2.7743723283842758],
					[58.79102796481066, 2.7743723123793531],
					[58.791027970726773, 2.7743723043769091],
					[58.792927971421335, 2.7712695040650877],
					[58.79292798032489, 2.7712694833801952],
					[58.792927995203705, 2.771269437332387],
					[58.792927998667402, 2.7712694158909179],
					[58.793311297612398, 2.7662639399455968],
					[58.794758489062701, 2.7619389654350548],
					[58.79626408820485, 2.757716766253731],
					[58.796264089823268, 2.7577167624918686],
					[58.796264092893068, 2.7577167515281049],
					[58.796264093618753, 2.7577167479271467],
					[58.797386293951739, 2.7531139464006564],
					[58.797386296379138, 2.7531139407573511],
					[58.797386297579493, 2.7531139283948844],
					[58.797386299114173, 2.753113922912624],
					[58.797955778065287, 2.748233499694233],
					[58.800474945151592, 2.7482362995515439],
					[58.802774897344825, 2.7496112718782983],
					[58.802774977074485, 2.7496112817796527],
					[58.80277511907228, 2.7496112387893268],
					[58.80277517955497, 2.7496111862200037],
					[58.804336276800164, 2.746383394785886],
					[58.806347372990913, 2.7429529010458547],
					[58.806347372907176, 2.7429528993253944],
					[58.806347373799881, 2.7429528991640293],
					[58.806347373716157, 2.7429528974435686],
					[58.808291873670193, 2.7395000983640112],
					[58.80829188346101, 2.7395000775003955],
					[58.808291895649774, 2.7395000319032321],
					[58.808291899916867, 2.7395000085673158],
					[58.80849739393998, 2.7348862497877913],
					[58.811108358966152, 2.7340807905801907],
					[58.81110838541133, 2.7340807788488393],
					[58.81110843163362, 2.7340807479141649],
					[58.811108454088803, 2.7340807282259525],
					[58.81310575375106, 2.7316862282639187],
					[58.813105771880146, 2.7316861937329056],
					[58.813105795389134, 2.7316861217723245],
					[58.813105798899805, 2.7316860829453966],
					[58.812697402372592, 2.7267556197327742],
					[58.813841806903227, 2.7227253569211314],
					[58.815933168779125, 2.7245390511607157],
					[58.815933251859583, 2.7245390742873128],
					[58.815933405398582, 2.7245390464408712],
					[58.815933475048467, 2.724538997350602],
					[58.817722374715117, 2.7212917962480025],
					[58.817722379650974, 2.7212917866712658],
					[58.817722387737341, 2.7212917678417967],
					[58.817722390887845, 2.7212917585890488],
					[58.818869591329907, 2.7175028588013026],
					[58.818869591970333, 2.7175028534762178],
					[58.818869594311984, 2.7175028461059205],
					[58.818869595120553, 2.7175028442228264],
					[58.819939092966941, 2.7127472519311726],
					[58.821319588359962, 2.7082917677905245],
					[58.822983484833088, 2.7042472766069121],
					[58.822983487173978, 2.7042472692348878],
					[58.822983491939937, 2.7042472562119748],
					[58.822983492579809, 2.7042472508860373],
					[58.824161289068662, 2.6997500687492093],
					[58.825966880858708, 2.6959472853519064],
					[58.825966880858708, 2.6959472853519064],
					[58.827533424623816, 2.6926169035799798],
					[58.829474708324426, 2.6926668936669174],
					[58.827897014282733, 2.6966360264085618],
					[58.827897008709307, 2.6966360413184209],
					[58.827897002278647, 2.6966360754888363],
					[58.827897000444437, 2.6966360931906936],
					[58.827716500328229, 2.7015416926196236],
					[58.827716509829585, 2.7015417395179044],
					[58.82771654902556, 2.7015418226899537],
					[58.827716578720164, 2.701541858963711],
					[58.828207990668382, 2.7019139171223929],
					[58.8260498615969, 2.7039609555139696],
					[58.826049838119964, 2.7039609910473339],
					[58.826049806930669, 2.7039610714008546],
					[58.826049800919336, 2.7039611141747493],
					[58.826408101358716, 2.7089306131101063],
					[58.826408149392194, 2.708930692942793],
					[58.826408288592752, 2.7089307770326458],
					[58.826408380736659, 2.7089307828488209],
					[58.828922280758377, 2.707819582913856],
					[58.828922319832294, 2.7078195532263303],
					[58.828922378224149, 2.7078194766042905],
					[58.828922396733638, 2.7078194315536703],
					[58.829636297092883, 2.703441734301995],
					[58.830544590341681, 2.6986583720936665],
					[58.831813863517716, 2.6965448473373885],
					[58.833016514217597, 2.6995361757659411],
					[58.833016520079326, 2.699536185119888],
					[58.833016531971417, 2.6995362072710818],
					[58.833016537833117, 2.6995362166250358],
					[58.835074837924452, 2.702372316839126],
					[58.835074911055145, 2.7023723573688567],
					[58.835075062955397, 2.7023723696540665],
					[58.835075140832309, 2.7023723415721332],
					[58.837402940986145, 2.7000445408858349],
					[58.837402943579683, 2.7000445386759822],
					[58.837402949575079, 2.7000445323717548],
					[58.837402952168617, 2.7000445301618869],
					[58.839675098597247, 2.6973779931615729],
					[58.842644416113622, 2.6971362993950416],
					[58.842644480346671, 2.697136268569384],
					[58.842644572060138, 2.6971361736576607],
					[58.842644600348819, 2.6971361076866969],
					[58.842830800165473, 2.6922556076135304],
					[58.842830799574422, 2.6922555955588061],
					[58.842830797584156, 2.6922555733343541],
					[58.842830797077546, 2.6922555630017309],
					[58.841902997702569, 2.6873416696569392],
					[58.841258500089758, 2.6822943894111138],
					[58.84135019912015, 2.6775111531891369],
					[58.843339075035317, 2.6739528985825078],
					[58.843339075758536, 2.673952894975002],
					[58.843339078182233, 2.673952889318667],
					[58.843339079882696, 2.673952887269857],
					[58.845194579645167, 2.6701556874756092],
					[58.845194590277053, 2.6701556472995862],
					[58.84519459165007, 2.670155565377724],
					[58.845194585068946, 2.6701555231414464],
					[58.843800189191725, 2.6667555323332874],
					[58.842450208379695, 2.6622360970970855],
					[58.843669582612627, 2.6576861888368524],
					[58.845897360575833, 2.6546834186007744],
					[58.845897365676272, 2.6546834124510741],
					[58.845897374814754, 2.6546833968712695],
					[58.845897378852776, 2.654683387441183],
					[58.847725152253368, 2.6509695440810437],
					[58.849730696636783, 2.6498501743737153],
					[58.849730699314399, 2.6498501738816063],
					[58.849730702799526, 2.6498501715031662],
					[58.849730705392126, 2.6498501692887584],
					[58.852211205069409, 2.6483140702729346],
					[58.852211241832741, 2.6483140304910036],
					[58.852211288625689, 2.6483139384615155],
					[58.852211299632849, 2.6483138877723036],
					[58.851886299659192, 2.643388886246592],
					[58.851886298681585, 2.6433888846884175],
					[58.851886299403922, 2.6433888810794262],
					[58.851886299318814, 2.6433888793570421],
					[58.851344598653569, 2.6383221785873072],
					[58.851344591935892, 2.6383221520158084],
					[58.851344573315771, 2.6383221033035702],
					[58.851344558565465, 2.6383220782111487],
					[58.84924742817055, 2.6355638704772733],
					[58.850914085127791, 2.6315056764378069],
					[58.850914088993555, 2.6315056635591185],
					[58.850914095917787, 2.631505639688545],
					[58.85091409799854, 2.6315056271388872],
					[58.851566898207089, 2.6266306268199209],
					[58.851566899053587, 2.6266306075451271],
					[58.851566897176589, 2.6266305696541163],
					[58.85156689356063, 2.626630551202545],
					[58.850566912309048, 2.6227028230010796],
					[58.852450179105233, 2.6189611899134433],
					[58.852450183140547, 2.6189611804775215],
					[58.852450190233249, 2.6189611600481939],
					[58.852450194268556, 2.6189611506122703],
					[58.85355849389741, 2.6146750504294589],
					[58.853558490991134, 2.614674991868617],
					[58.853558455176405, 2.614674887245279],
					[58.85355842226793, 2.6146748411827558],
					[58.851108421816186, 2.6127970408638488],
					[58.851108374932153, 2.6127970217227299],
					[58.851108277898781, 2.6127970083769245],
					[58.851108227663921, 2.6127970124500295],
					[58.84845827101968, 2.6138219966264322],
					[58.845736213540377, 2.6135804083664373],
					[58.843789076983811, 2.6106388062268064],
					[58.842322441226543, 2.6071056576243485],
					[58.844564012685865, 2.6052057661373063],
					[58.847175095252148, 2.6038057765637186],
					[58.847175106597206, 2.6038057692500023],
					[58.847175129116096, 2.6038057511785455],
					[58.847175140375562, 2.603805742142804],
					[58.849536239883669, 2.6014862437403332],
					[58.849536255983736, 2.6014862233787168],
					[58.849536281679747, 2.6014861786460419],
					[58.849536292253788, 2.601486155831835],
					[58.850861287317898, 2.5969222722541079],
					[58.852736132769579, 2.5931864794752872],
					[58.853752640344602, 2.594552918664228],
					[58.853752722601264, 2.5945529607727051],
					[58.853752887117935, 2.5945529546016601],
					[58.853752967678794, 2.594552908375404],
					[58.855208467654812, 2.5922918077253518],
					[58.855208478312882, 2.5922917866269635],
					[58.855208494188652, 2.5922917437011117],
					[58.855208499320568, 2.592291720151271],
					[58.855747398907653, 2.5872278214579234],
					[58.855747398821805, 2.5872278197355163],
					[58.855747398821805, 2.5872278197355163],
					[58.855747398735964, 2.5872278180131225],
					[58.856208491594721, 2.5821500932450179],
					[58.858527936949152, 2.5799668467708829],
					[58.85852795385199, 2.5799668245055032],
					[58.858527981324833, 2.5799667794134682],
					[58.858527991808899, 2.579966754864313],
					[58.859802979909595, 2.575525095765391],
					[58.862064053443227, 2.5728334292085866],
					[58.86206406176489, 2.572833415488998],
					[58.8620640776019, 2.5728333899386078],
					[58.862064085031186, 2.5728333763851312],
					[58.863677970418287, 2.5689307117016638],
					[58.866030744424705, 2.5664779375412325],
					[58.86603075712145, 2.5664779212642554],
					[58.866030778859283, 2.566477887652634],
					[58.866030787814225, 2.5664778685951402],
					[58.867541888093129, 2.562283367346641],
					[58.867541891317892, 2.5622833597894403],
					[58.867541895724081, 2.5622833398391727],
					[58.867541897970248, 2.5622833307254864],
					[58.868294593635845, 2.5573583565720335],
					[58.869930782632274, 2.5536389818268317],
					[58.869930791153678, 2.5536389541489992],
					[58.869930798380473, 2.5536389006264142],
					[58.869930797891925, 2.5536388728920234],
					[58.86933909913899, 2.5492638795349962],
					[58.869033501015039, 2.5442833074129796],
					[58.869714094863873, 2.5393167526803357],
					[58.871189085398242, 2.5357389753814736],
					[58.871189085398242, 2.5357389753814736],
					[58.871189086290578, 2.5357389752143606],
					[58.872708471567954, 2.53195570817544],
					[58.874941846260079, 2.5295223366011976],
					[58.877311244218539, 2.5270584386130182],
					[58.877311249312427, 2.5270584324389791],
					[58.877311258694554, 2.527058421981546],
					[58.877311262896157, 2.5270584159748934],
					[58.87947516290923, 2.5240140160343945],
					[58.87947516541275, 2.5240140120852801],
					[58.879475169527453, 2.5240140043545054],
					[58.879475172117672, 2.5240140021287463],
					[58.881439071720351, 2.5206918015463065],
					[58.88143907923056, 2.5206917896975476],
					[58.88143908872361, 2.5206917635585269],
					[58.881439094275692, 2.5206917485980158],
					[58.882408492452321, 2.5168556557135844],
					[58.883805752171853, 2.512475181748036],
					[58.886230626338495, 2.512155798418227],
					[58.88623064945115, 2.5121557923313067],
					[58.886230692738913, 2.5121557754899611],
					[58.886230713806334, 2.5121557645677144],
					[58.888580713926636, 2.5105251647771163],
					[58.888580733667119, 2.5105251454020925],
					[58.888580766815188, 2.5105251061034268],
					[58.888580781920417, 2.5105250841202591],
					[58.89027513729463, 2.50685298149005],
					[58.892788930871745, 2.5064557977776802],
					[58.892788940686799, 2.5064557959295901],
					[58.892788960229957, 2.5064557905094511],
					[58.892788969152726, 2.506455788829371],
					[58.89538616893315, 2.5055001882361445],
					[58.895386187410026, 2.5055001795348475],
					[58.895386222318315, 2.5055001572960629],
					[58.895386237944422, 2.5055001456507306],
					[58.897444507029888, 2.5035473735354179],
					[58.90005006569482, 2.502630789676755],
					[58.900050084171454, 2.5026307809725852],
					[58.900050115684067, 2.5026307628488409],
					[58.900050132202125, 2.5026307510323558],
					[58.902469532202133, 2.5005168505867807],
					[58.902469533007341, 2.5005168486941378],
					[58.902469537381634, 2.5005168461285909],
					[58.90246953818685, 2.5005168442359311],
					[58.904822338062587, 2.4982668456085961],
					[58.904822345744634, 2.4982668371957941],
					[58.904822359324193, 2.4982668207067547],
					[58.90482236611399, 2.4982668124622496],
					[58.906941865579398, 2.4951251121660802],
					[58.906941869779331, 2.4951251061502209],
					[58.906941877199792, 2.4951250925622963],
					[58.906941879615196, 2.4951250868832302],
					[58.908705744888124, 2.4914418597752439],
					[58.910422268724382, 2.4908084874402916],
					[58.910422295055909, 2.4908084737605116],
					[58.910422341037304, 2.4908084389567389],
					[58.910422360687178, 2.490808417832739],
					[58.912583461335736, 2.4878557182682774],
					[58.912583471344853, 2.4878557024457866],
					[58.912583486727456, 2.4878556681941526],
					[58.912583493885393, 2.4878556494277602],
					[58.913580765855208, 2.4840334541389693],
					[58.915961164989689, 2.4832168896218589],
					[58.915961185249188, 2.4832168805652461],
					[58.915961221850111, 2.4832168562269548],
					[58.915961239258337, 2.4832168442265643],
					[58.91779733898688, 2.4814473447299346],
					[58.917797349169753, 2.4814473323533903],
					[58.917797366946139, 2.481447309831875],
					[58.917797375257251, 2.4814472960678535],
					[58.91956406791271, 2.4782223101638023],
					[58.921739056600707, 2.475444523204744],
					[58.921739073221524, 2.4754444956706152],
					[58.92173909370991, 2.4754444377924427],
					[58.921739100166739, 2.4754444052161233],
					[58.921889099944238, 2.4704778057628056],
					[58.921889098982142, 2.4704777867846563],
					[58.921889091704692, 2.4704777498431332],
					[58.921889087086306, 2.4704777298162162],
					[58.920405795637237, 2.466491651217722],
					[58.920055800993119, 2.4636860961472982],
					[58.920533499415534, 2.4586111185510413],
					[58.920533499957251, 2.4586111114809004],
					[58.920533500060856, 2.4586110957848688],
					[58.920533499710388, 2.4586110888841679],
					[58.920283500134218, 2.4545610886834432],
					[58.920283495338047, 2.4545610652094361],
					[58.920283482264679, 2.4545610206646935],
					[58.920283472115429, 2.4545609982078718],
					[58.918311280087316, 2.4512138119658569],
					[58.916722397395766, 2.4472388549002781],
					[58.916472401708141, 2.4434444239569886],
					[58.917630770146253, 2.4396668290879413],
					[58.919047307229924, 2.4387640682703928],
					[58.919047320260695, 2.4387640588213308],
					[58.919047342665721, 2.4387640388778924],
					[58.919047353736417, 2.4387640263186245],
					[58.921325153824263, 2.4360057276245337],
					[58.921325158913398, 2.4360057214290367],
					[58.921325167307337, 2.4360057093780667],
					[58.921325170612164, 2.4360057035225862],
					[58.922272371027361, 2.4344445035494031],
					[58.922272371831561, 2.4344445016541667],
					[58.92227237263576, 2.4344444997589285],
					[58.922272374420047, 2.4344444994188055],
					[58.923397373990177, 2.4324611993816974],
					[58.923397378098983, 2.4324611916301784],
					[58.923397385424487, 2.4324611762972514],
					[58.923397387749034, 2.4324611688859847],
					[58.923941887882343, 2.4309750690080913],
					[58.923941893071429, 2.4309750471139377],
					[58.923941898900893, 2.4309750024511767],
					[58.923941898649147, 2.4309749798527438],
					[58.923714099180216, 2.4286499796830863],
					[58.923714097847984, 2.4286499712271397],
					[58.923714094203334, 2.4286499527602405],
					[58.923714091978958, 2.4286499444745155],
					[58.923191895576487, 2.4268499577207052],
					[58.922925198392527, 2.4248860736686351],
					[58.922925192875027, 2.4248860538180339],
					[58.922925179163649, 2.4248860146277496],
					[58.922925170077619, 2.4248859954583972],
					[58.921669570112932, 2.4228387941786003],
					[58.921669567172266, 2.4228387895143313],
					[58.921669559418575, 2.422838778801411],
					[58.921669554693665, 2.4228387744778486],
					[58.919491855437364, 2.420158174506589],
					[58.919491833684759, 2.4201581542761281],
					[58.919491786963597, 2.4201581213968355],
					[58.919491761102911, 2.4201581089184017],
					[58.917202860643087, 2.4194276091834452],
					[58.917202827105854, 2.4194276051395045],
					[58.9172027596681, 2.4194276075712788],
					[58.917202725767581, 2.4194276140470063],
					[58.912491625658213, 2.4213026135953868],
					[58.912491620393581, 2.421302616342051],
					[58.912491608972196, 2.4213026220056815],
					[58.912491602815443, 2.4213026249226641],
					[58.909511013255688, 2.4229581191027805],
					[58.90703325700121, 2.423974800585865],
					[58.904194392646545, 2.4240691995462749],
					[58.904194384617384, 2.4240692010781344],
					[58.904194370255233, 2.4240692020772077],
					[58.904194362226086, 2.4240692036090685],
					[58.901397161658458, 2.4246137033874313],
					[58.901397156305705, 2.4246137044084715],
					[58.901397143011785, 2.4246137086851922],
					[58.901397136766882, 2.4246137098764078],
					[58.898888866873719, 2.4254469997143877],
					[58.897297232318273, 2.4254636995750181],
					[58.894725065102662, 2.4245859106504795],
					[58.893202865788943, 2.4240581103855887],
					[58.893202784164778, 2.4240581172492557],
					[58.893202653702893, 2.4240581908626515],
					[58.893202604777095, 2.424058255888689],
					[58.891930404787843, 2.42974435563693],
					[58.891930403983686, 2.4297443575305735],
					[58.891930404159645, 2.4297443609778715],
					[58.891930404247617, 2.4297443627015238],
					[58.89095540380243, 2.4348638632358228],
					[58.890955402910286, 2.4348638634056612],
					[58.890955403174019, 2.4348638685765178],
					[58.890955402369755, 2.4348638704699983],
					[58.890372002736235, 2.4386555651720179],
					[58.889533105483231, 2.442816655689096],
					[58.887888712436968, 2.4490471277795853],
					[58.885319229193456, 2.453985995990279],
					[58.882416540147204, 2.4581331797771626],
					[58.879380444935798, 2.4619526735126827],
					[58.87628875529284, 2.4655970623151613],
					[58.873035963067124, 2.4686748554541342],
					[58.87303595610431, 2.4686748602506254],
					[58.873035944225421, 2.4686748746744569],
					[58.873035938329686, 2.4686748827478029],
					[58.870091550189137, 2.4727387659911519],
					[58.866855465328335, 2.4757081521677176],
					[58.866855463543885, 2.4757081525049771],
					[58.863627663914919, 2.4787054522243115],
					[58.863627656321327, 2.4787054623531346],
					[58.863627640067321, 2.4787054793345167],
					[58.863627633365972, 2.4787054892948293],
					[58.860783133254571, 2.4830276898858825],
					[58.860783131557326, 2.4830276919449674],
					[58.860783129142334, 2.4830276976170205],
					[58.860783127445089, 2.4830276996761214],
					[58.858066526671813, 2.487660999340493],
					[58.858066525061666, 2.4876610031213668],
					[58.858066520231219, 2.4876610144639555],
					[58.858066516836573, 2.4876610185813361],
					[58.855735916576315, 2.4928860185722481],
					[58.855735914247973, 2.4928860259648737],
					[58.855735908698982, 2.4928860409182274],
					[58.855735907175777, 2.4928860464206721],
					[58.854049847373894, 2.4990776003890551],
					[58.850636131280609, 2.4990970004612558],
					[58.847061160605136, 2.4979581097454848],
					[58.847061141432341, 2.4979581046636015],
					[58.847061101650361, 2.4979581017223231],
					[58.84706108175935, 2.4979581002516928],
					[58.843497181656666, 2.4982720012415371],
					[58.843497170862413, 2.4982720015347697],
					[58.843497147750405, 2.4982720076214608],
					[58.843497137043158, 2.4982720096361772],
					[58.839927739844022, 2.499460909046169],
					[58.836349943167839, 2.500516508149194],
					[58.836349931655249, 2.5005165120515698],
					[58.836349908717047, 2.5005165215774672],
					[58.836349898270704, 2.5005165287543214],
					[58.832885998225635, 2.5025692280537646],
					[58.832885997333364, 2.5025692282214758],
					[58.832885994743478, 2.5025692304455989],
					[58.832885993851193, 2.5025692306133118],
					[58.829436003718563, 2.5047165245654601],
					[58.825924914195589, 2.5063887190398795],
					[58.82592491330329, 2.5063887192074477],
					[58.825924909821055, 2.5063887215983534],
					[58.825924908036484, 2.5063887219334915],
					[58.822436007609838, 2.5081942214432593],
					[58.82243600064524, 2.5081942262242078],
					[58.822435987608351, 2.5081942356185696],
					[58.822435980556904, 2.5081942386790423],
					[58.819063818366452, 2.510680412509505],
					[58.815455569635596, 2.5113859025257872],
					[58.811838878832262, 2.5117720003146284],
					[58.811838871693894, 2.5117720016533243],
					[58.811838856524808, 2.5117720044980518],
					[58.811838850278747, 2.5117720056694082],
					[58.808261049859354, 2.5126970062113219],
					[58.808261034863826, 2.5126970124948147],
					[58.808261004872797, 2.5126970250618141],
					[58.808260990769583, 2.5126970311780146],
					[58.804885990851503, 2.5148887317648176],
					[58.804885976116147, 2.5148887432055425],
					[58.804885948690334, 2.5148887709114591],
					[58.804885936718669, 2.5148887835701728],
					[58.801955458146026, 2.5189942541191885],
					[58.798533189267438, 2.521288733631716],
					[58.798533171135517, 2.5212887491741567],
					[58.798533140398874, 2.5212887826956916],
					[58.798533126901859, 2.5212888008417722],
					[58.79590263663556, 2.5258526816164752],
					[58.792938784677055, 2.5291915280403039],
					[58.789374934369576, 2.5304442102079574],
					[58.789374927317446, 2.530444213260548],
					[58.78937491499785, 2.5304442190323106],
					[58.789374908838056, 2.5304442219181893],
					[58.78592770940417, 2.5322026226374477],
					[58.785927691097662, 2.5322026347306812],
					[58.785927657334661, 2.5322026618547118],
					[58.785927641878217, 2.5322026768855199],
					[58.78291376699309, 2.5360831445618532],
					[58.779438803690134, 2.5379915254012175],
					[58.779438802797785, 2.5379915255676675],
					[58.779438801013093, 2.5379915259005714],
					[58.77943880020716, 2.5379915277854699],
					[58.77596660019443, 2.539999827037974],
					[58.775966599302045, 2.5399998272043542],
					[58.77596659840971, 2.5399998273707305],
					[58.772502702154569, 2.5420415261741569],
					[58.769011005662051, 2.5439109234801349],
					[58.769011003877317, 2.5439109238125992],
					[58.769011002178964, 2.5439109258630084],
					[58.769011001286586, 2.5439109260292407],
					[58.765536000799202, 2.545899825348878],
					[58.765535998208406, 2.5458998275651363],
					[58.765535991242132, 2.5458998323299826],
					[58.765535987758973, 2.5458998347123996],
					[58.762119298227589, 2.5481970279331314],
					[58.758641638662077, 2.5500165056632667],
					[58.755088962235398, 2.5504858896132467],
					[58.751777929829856, 2.5476526483969146],
					[58.751777884550656, 2.5476526256239729],
					[58.751777789039892, 2.5476526069969627],
					[58.75177773782967, 2.5476526095918066],
					[58.748274938347933, 2.5487915099601479],
					[58.748274933079969, 2.5487915126729312],
					[58.748274923350131, 2.5487915162155557],
					[58.748274917995879, 2.5487915172113831],
					[58.744747130159254, 2.5503831120341793],
					[58.741177763867263, 2.5514331023523713],
					[58.737555586590474, 2.5517192006818425],
					[58.733933289005037, 2.5519191991749808],
					[58.733933266695544, 2.5519192033199118],
					[58.733933224119909, 2.5519192164269255],
					[58.733933202961396, 2.5519192255548231],
					[58.7304610031251, 2.5538331243991212],
					[58.730460991782643, 2.553833131701801],
					[58.730460970968686, 2.5538331476918148],
					[58.730460960604788, 2.5538331565448704],
					[58.72723876074091, 2.5569276554003091],
					[58.727238759934657, 2.5569276572818702],
					[58.72723875823602, 2.55692765932907],
					[58.727238757343635, 2.5569276594946961],
					[58.724041570397532, 2.560177647064275],
					[58.720669320555174, 2.5625581105371142],
					[58.717061065370572, 2.563205402663626],
					[58.717061057338967, 2.5632054041522081],
					[58.717061042254173, 2.5632054086794454],
					[58.717061035200985, 2.5632054117180885],
					[58.713494334550219, 2.5644387107435969],
					[58.713494328389416, 2.5644387136163025],
					[58.713494314282968, 2.5644387196923786],
					[58.713494308122172, 2.5644387225650651],
					[58.709999907571657, 2.5662554232372012],
					[58.709999902303203, 2.5662554259439676],
					[58.709999891766294, 2.5662554313575181],
					[58.709999885605434, 2.5662554342295589],
					[58.706591585676939, 2.5686109352394912],
					[58.706591581386775, 2.568610939495374],
					[58.706591571828113, 2.5686109464573832],
					[58.706591566645564, 2.5686109508784534],
					[58.703310967408285, 2.571533150422562],
					[58.703310963118035, 2.5715331546776743],
					[58.703310955344023, 2.5715331613079662],
					[58.703310951946172, 2.5715331653979852],
					[58.700174888082849, 2.5749998260685674],
					[58.696605539431879, 2.5761526105292698],
					[58.696605526131336, 2.5761526147177811],
					[58.696605503271606, 2.5761526258640783],
					[58.69660549094931, 2.5761526316021532],
					[58.693180492989619, 2.5783776306480668],
					[58.689730495694896, 2.5804831293099926],
					[58.68973049391002, 2.5804831296395525],
					[58.689730492210941, 2.5804831316832817],
					[58.689730491404283, 2.5804831335622311],
					[58.686313790963951, 2.5827165325227495],
					[58.686313783103493, 2.5827165374330758],
					[58.686313769253232, 2.5827165486383472],
					[58.686313762370972, 2.5827165550979991],
					[58.683063761748535, 2.5857970545364113],
					[58.683063760941806, 2.5857970564148762],
					[58.683063758350151, 2.5857970586225432],
					[58.683063756650981, 2.5857970606656187],
					[58.67986375705712, 2.5890665597127676],
					[58.679863751959402, 2.5890665658409082],
					[58.679863742570738, 2.5890665762189613],
					[58.679863738365469, 2.5890665821825882],
					[58.676922037684832, 2.5931081817215773],
					[58.676922036071133, 2.5931081854774951],
					[58.676922031865651, 2.5931081914401344],
					[58.676922030251937, 2.5931081951960553],
					[58.674147029861224, 2.597580494338374],
					[58.674147029054289, 2.5975804962160964],
					[58.674147025655508, 2.5975805002999901],
					[58.674147024934136, 2.5975805038911597],
					[58.671519225142895, 2.6023638036981116],
					[58.671519224335896, 2.6023638055755809],
					[58.671519221829406, 2.602363809494594],
					[58.671519221022407, 2.602363811372046],
					[58.669013720857556, 2.60739161028155],
					[58.669013720050486, 2.6073916121587528],
					[58.669013719243416, 2.6073916140359548],
					[58.669013719328845, 2.6073916157491963],
					[58.666599819291257, 2.6125805167834084],
					[58.666599816699105, 2.6125805189879845],
					[58.666599815170144, 2.6125805244550326],
					[58.666599814448325, 2.6125805280451457],
					[58.664435917201175, 2.6181555175984603],
					[58.661924833144738, 2.6231192862742239],
					[58.658791550903814, 2.6266081652175646],
					[58.658791533784687, 2.626608273784063],
				],
			],
		},
		{
			fldNpdidField: "43645",
			wlbNpdidWellbore: "55",
			fldName: "OSEBERG SØR",
			fldCurrentActivitySatus: "Producing",
			wlbName: "30/9-3",
			fldDiscoveryYear: "1984",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43645",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43645",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "OSEBERG AREA UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=55",
			fldHcType: "OIL/GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "3500071",
			fldDateUpdated: "2022-09-13 00:00:00",
			fldDateUpdatedMax: "2022-09-15 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[60.308933100223811, 2.9406666925865461],
					[60.308933157675902, 2.9406667871683894],
					[60.308933326003185, 2.94066686477233],
					[60.308933435903285, 2.9406668461608332],
					[60.315541827947541, 2.9345612544171602],
					[60.329452862711143, 2.9241641034398915],
					[60.337702752968433, 2.9244862988112534],
					[60.349072104770528, 2.9296168771179127],
					[60.362505476426357, 2.9381084597449694],
					[60.370874861946739, 2.9460695452322527],
					[60.370874875915298, 2.9460695553597653],
					[60.370874903852467, 2.9460695756148025],
					[60.37087491863155, 2.946069583773145],
					[60.376472101634846, 2.9485418754174417],
					[60.382549885979799, 2.9527334647593628],
					[60.382549916265702, 2.9527334773140201],
					[60.38254997740146, 2.9527334950427129],
					[60.382550009144126, 2.9527335000506438],
					[60.395702750339154, 2.9521724023658598],
					[60.399324853399492, 2.954286245464361],
					[60.404999827369132, 2.9641639990608608],
					[60.404999859614229, 2.9641640349314744],
					[60.404999939272777, 2.9641640838317045],
					[60.404999984089756, 2.9641640991640483],
					[60.41603888435948, 2.9650418989408576],
					[60.41603894320567, 2.9650418861794918],
					[60.416039043102884, 2.9650418257287856],
					[60.416039083343122, 2.9650417800109672],
					[60.419811282877973, 2.9564556791245313],
					[60.419811291783446, 2.9564556374040194],
					[60.419811288730344, 2.9564555505641295],
					[60.419811277828977, 2.9564555088899107],
					[60.414066878326885, 2.9453110088423302],
					[60.41406686768233, 2.945310992620652],
					[60.414066844689906, 2.9453109623157099],
					[60.414066832424389, 2.9453109500377694],
					[60.398952962764675, 2.9320304765985759],
					[60.398483648883854, 2.9310446680253968],
					[60.404963863800816, 2.9322473978088004],
					[60.404963943905258, 2.9322473773804929],
					[60.404964062025144, 2.9322472825009016],
					[60.404964100123102, 2.9322472098544159],
					[60.405739099278016, 2.9144083163102494],
					[60.406589090799308, 2.9076583883130458],
					[60.412400148427665, 2.9012640357386799],
					[60.412400165204275, 2.9012640089179094],
					[60.412400189664126, 2.9012639533433555],
					[60.412400198240121, 2.901263924421849],
					[60.416997397612832, 2.864855625319628],
					[60.416997399231306, 2.8648556213716629],
					[60.416997399706759, 2.8648556121767301],
					[60.416997399539866, 2.8648556085662622],
					[60.417494600187673, 2.8541444090983203],
					[60.417494599686123, 2.8541443982669557],
					[60.417494598850197, 2.8541443802146742],
					[60.417494598348654, 2.8541443693833095],
					[60.414269603232505, 2.8327778054997612],
					[60.415000144543633, 2.8293391776400552],
					[60.419777723016949, 2.8313362845427923],
					[60.419777726671391, 2.8313362856693129],
					[60.419777734956767, 2.8313362895579663],
					[60.419777740396427, 2.8313362903451194],
					[60.42404993962468, 2.8326890910447862],
					[60.42404999100799, 2.8326890922061594],
					[60.42405008911355, 2.8326890717358957],
					[60.424050134775385, 2.832689046662888],
					[60.432652899948856, 2.8247973799441763],
					[60.449905654412582, 2.8199279926887382],
					[60.449905686125994, 2.8199279775267057],
					[60.449905741014888, 2.8199279378930546],
					[60.449905765891494, 2.8199279112741156],
					[60.45699736580778, 2.809475112281036],
					[60.456997369125432, 2.8094751061770538],
					[60.456997375036629, 2.8094750977541878],
					[60.456997378354295, 2.8094750916502034],
					[60.466786273051149, 2.7904112011321001],
					[60.468705743269787, 2.7875445448594536],
					[60.471538986862456, 2.7857057822905849],
					[60.479258362825483, 2.7831612904279348],
					[60.479258376936905, 2.7831612840683766],
					[60.47925840418263, 2.7831612697122496],
					[60.47925841731692, 2.7831612617156827],
					[60.481286216646168, 2.7817057619256262],
					[60.481286227102736, 2.7817057544423673],
					[60.481286246869459, 2.7817057342217266],
					[60.481286256348866, 2.7817057251014066],
					[60.487019545864591, 2.7745390382736805],
					[60.492805734208403, 2.7693473493890499],
					[60.492805736716356, 2.7693473452551256],
					[60.492805743602055, 2.769347338452596],
					[60.492805747087317, 2.769347335955894],
					[60.49404454719231, 2.7680029357192635],
					[60.494044550592704, 2.768002931413136],
					[60.49404455909373, 2.7680029206477963],
					[60.494044563386652, 2.7680029161697264],
					[60.497336262887096, 2.7633807156972603],
					[60.497336264587204, 2.7633807135437296],
					[60.497336267902561, 2.7633807074272978],
					[60.49733626960267, 2.7633807052737684],
					[60.502189069401602, 2.755594506177208],
					[60.50802516801469, 2.7464807079624376],
					[60.508025168907153, 2.7464807077898103],
					[60.508025171414346, 2.7464807036521219],
					[60.508025171414346, 2.7464807036521219],
					[60.509800171154119, 2.7435362034450304],
					[60.509800184707927, 2.7435361661191213],
					[60.509800196388028, 2.74353608897365],
					[60.509800192729436, 2.7435360494995433],
					[60.508830792824959, 2.7398555496715806],
					[60.508830773908208, 2.7398555113271321],
					[60.508830720436443, 2.7398554467985301],
					[60.508830685796234, 2.7398554188044448],
					[60.498091800900205, 2.7347609272969486],
					[60.489114014900373, 2.7284637355549965],
					[60.489113994583739, 2.7284637248906414],
					[60.489113952336268, 2.7284637075254485],
					[60.489113929598354, 2.7284637028064025],
					[60.483177830341567, 2.7275720027940564],
					[60.483177788947664, 2.7275720035171642],
					[60.483177713431296, 2.7275720254538127],
					[60.483177677267868, 2.7275720415880023],
					[60.474399876702336, 2.7344387419848251],
					[60.474399875002682, 2.7344387441382483],
					[60.474399872410579, 2.734438746464396],
					[60.47439986981847, 2.7344387487905424],
					[60.466266570694238, 2.7413498471422968],
					[60.457566596218207, 2.7486331259619825],
					[60.453930655851465, 2.7500664632784031],
					[60.448519548606718, 2.7439998672935908],
					[60.448519506743033, 2.7439998389148093],
					[60.448519415197303, 2.7439998073655008],
					[60.448519364537674, 2.7439998025606842],
					[60.438491677442286, 2.7458054010278108],
					[60.437966691621135, 2.7458312278691994],
					[60.439316783161459, 2.7450918827281057],
					[60.440761162075354, 2.7445668895932536],
					[60.442336155942854, 2.7441112923455844],
					[60.442336214929334, 2.7441112445090607],
					[60.442336283813759, 2.7441111200336237],
					[60.442336291926757, 2.7441110437392116],
					[60.442128051801447, 2.7434001476355818],
					[60.443025045454341, 2.7431945954814547],
					[60.443025056078959, 2.7431945916080154],
					[60.443025078220636, 2.7431945836888785],
					[60.443025088845253, 2.7431945798154218],
					[60.4440583890486, 2.7426807798733068],
					[60.44405840218036, 2.7426807718700692],
					[60.444058424788864, 2.7426807547463863],
					[60.444058436943031, 2.7426807451091149],
					[60.445755737046504, 2.7410945457096494],
					[60.445755742145913, 2.7410945392563852],
					[60.445755753322331, 2.7410945279839036],
					[60.445755759314189, 2.7410945213582858],
					[60.447364058776728, 2.7390029220943228],
					[60.447364063790822, 2.7390029138339003],
					[60.447364073819045, 2.7390028973130662],
					[60.447364078833154, 2.7390028890526419],
					[60.449144578556655, 2.7354111891008182],
					[60.449144588624584, 2.7354111543396775],
					[60.449144596180744, 2.7354110854260982],
					[60.449144593583746, 2.7354110494671087],
					[60.448877993804835, 2.7343721499820171],
					[60.448877990701249, 2.7343721414668858],
					[60.448877986364245, 2.7343721258980485],
					[60.448877982368195, 2.7343721175554503],
					[60.448202982444052, 2.7328666186027588],
					[60.448202905852028, 2.7328665604940681],
					[60.448202738911306, 2.7328665344410941],
					[60.448202648647886, 2.7328665683033315],
					[60.447408148703929, 2.733780469174008],
					[60.44740814109803, 2.7337804797593601],
					[60.447408126778676, 2.733780500757518],
					[60.447408120065226, 2.7337805111703033],
					[60.446269219563028, 2.7361027113081797],
					[60.446269219648251, 2.7361027131145943],
					[60.446269218755802, 2.7361027132870741],
					[60.446269218841003, 2.73610271509351],
					[60.444288774601361, 2.7402969954592717],
					[60.442972294873996, 2.7402554025526924],
					[60.442000153402439, 2.7391081708833611],
					[60.442000130875947, 2.7391081515397384],
					[60.442000084378662, 2.7391081204219909],
					[60.442000057730517, 2.7391081091649698],
					[60.440208358000497, 2.7385665085040638],
					[60.440208350690391, 2.7385665062708462],
					[60.440208337047821, 2.7385665034381623],
					[60.440208329737708, 2.738566501204946],
					[60.438344430293931, 2.7382804011427013],
					[60.438344427616499, 2.738280401659805],
					[60.43834442217652, 2.7382804008880202],
					[60.438344418606654, 2.7382804015774855],
					[60.436405674602064, 2.7380942058679407],
					[60.436275120112704, 2.7379970392821789],
					[60.436273925321522, 2.7379961292554906],
					[60.436372334841749, 2.7379057467140737],
					[60.43637233913374, 2.737905742240494],
					[60.436372346910403, 2.7379057352716094],
					[60.43637235120238, 2.7379057307980279],
					[60.436886246099064, 2.7373140372663198],
					[60.43735569032431, 2.7368557916075233],
					[60.43782490320902, 2.7368085104710023],
					[60.438385936075036, 2.7376168147352975],
					[60.438385964297275, 2.7376168402655128],
					[60.4383860284332, 2.7376168825505278],
					[60.438386064261707, 2.7376168974993575],
					[60.43934716370066, 2.7377918974536111],
					[60.439347191622751, 2.7377918975277362],
					[60.439347246318853, 2.7377918924302334],
					[60.439347273007726, 2.7377918854525798],
					[60.441002872771747, 2.7371445861531907],
					[60.441002881611197, 2.7371445826229004],
					[60.441002900904678, 2.737144571605211],
					[60.441002909658955, 2.7371445662687921],
					[60.442319510066355, 2.7362723666562356],
					[60.442319517035678, 2.7362723616642404],
					[60.442319529274556, 2.736272353831338],
					[60.442319535266186, 2.7362723472055981],
					[60.443369526012276, 2.7353140557187956],
					[60.444644515418766, 2.7343973647740447],
					[60.445583413028807, 2.7337557658792981],
					[60.445583414728489, 2.7337557637278302],
					[60.445583419998002, 2.7337557608862091],
					[60.445583421697677, 2.7337557587347554],
					[60.446297321532633, 2.7332057573971986],
					[60.44629733109393, 2.7332057500797942],
					[60.446297349238804, 2.733205733811114],
					[60.446297356844667, 2.7332057232260047],
					[60.446766838391802, 2.7326140479831369],
					[60.447369473982619, 2.7322001924634889],
					[60.448061084228627, 2.7321058046787075],
					[60.448799899996381, 2.7323251782777311],
					[60.44969704608129, 2.733133429836728],
					[60.450235930924876, 2.7339890065100212],
					[60.450235938661294, 2.7339890177758988],
					[60.450235955833769, 2.7339890381558898],
					[60.450235965269869, 2.733989047270017],
					[60.450794265120379, 2.7345001479095474],
					[60.450794339285011, 2.7345001736790024],
					[60.450794482038738, 2.7345001643089821],
					[60.450794551605512, 2.7345001308036148],
					[60.45276125159829, 2.7322029307789051],
					[60.452761253297915, 2.732202928626875],
					[60.452761255804752, 2.7322029244954424],
					[60.452761258396855, 2.7322029221707584],
					[60.454433454578734, 2.7300584267004901],
					[60.456308451777716, 2.7278695306073928],
					[60.456308456876414, 2.7278695241498712],
					[60.456308466181355, 2.7278695114076221],
					[60.456308470387576, 2.7278695051228996],
					[60.456972361644858, 2.7268029184773255],
					[60.456989051657992, 2.7267834300605753],
					[60.456989076165897, 2.7267833578450364],
					[60.456989065938792, 2.7267832175916946],
					[60.45698903298868, 2.7267831492082455],
					[60.455864033166705, 2.7257859493007475],
					[60.455864027641319, 2.7257859467239154],
					[60.455864018290058, 2.7257859394176549],
					[60.455864012679292, 2.725785935033894],
					[60.454480751661592, 2.7248387614259899],
					[60.453797374728389, 2.7235999038318415],
					[60.453239075307124, 2.7225916022504579],
					[60.453239063743801, 2.7225915862576806],
					[60.453239038025238, 2.722591556597608],
					[60.453239022892156, 2.7225915412964365],
					[60.452269523431717, 2.7218331420779904],
					[60.452269515058106, 2.721833136407418],
					[60.452269497418456, 2.7218331252391956],
					[60.452269487259969, 2.7218331199144528],
					[60.451352887415318, 2.7213887204701512],
					[60.451352880019563, 2.7213887164335619],
					[60.451352866291323, 2.7213887118007718],
					[60.451352858980975, 2.7213887095708493],
					[60.448841765286851, 2.7206109115122366],
					[60.447627871146715, 2.7201470125095963],
					[60.447627803615916, 2.7201470128342131],
					[60.447627683905829, 2.7201470524375857],
					[60.447627630919541, 2.7201470936957235],
					[60.446794230755167, 2.7214720931500325],
					[60.444808134551849, 2.7246276862367016],
					[60.443083147828119, 2.7269665690745817],
					[60.441230470413387, 2.7288109468797654],
					[60.439560997368204, 2.7300026281156438],
					[60.437577711222808, 2.7309915209651177],
					[60.437577707652977, 2.7309915216553629],
					[60.437577703275963, 2.7309915243240876],
					[60.437577700683875, 2.7309915266476841],
					[60.435463800749112, 2.7321970262234934],
					[60.435463791995026, 2.732197031560232],
					[60.435463776357068, 2.732197043694502],
					[60.435463767688262, 2.7321970508370601],
					[60.43363596772577, 2.7338081500153062],
					[60.433635947329527, 2.7338081758227735],
					[60.433635914824855, 2.7338082313027496],
					[60.433635903608923, 2.7338082608027912],
					[60.433572768814891, 2.7341295657027085],
					[60.433572396331115, 2.734127760834451],
					[60.433572396245879, 2.7341277590287469],
					[60.433497397688853, 2.7337693685531441],
					[60.433377999390444, 2.7327443774118976],
					[60.433377997094034, 2.7327443669226938],
					[60.433377992586422, 2.7327443477500215],
					[60.433377990290012, 2.732744337260836],
					[60.433341895764066, 2.7326332568323233],
					[60.433158498215313, 2.731180575331432],
					[60.433158496811238, 2.7311805646699105],
					[60.433158492303441, 2.7311805454975895],
					[60.43315848911444, 2.7311805351811191],
					[60.432883489192129, 2.7303777368139102],
					[60.432433490052269, 2.7290249376184046],
					[60.432433483929827, 2.7290249224032337],
					[60.432433470070684, 2.7290248959293097],
					[60.43243346224866, 2.7290248828649348],
					[60.432169583169468, 2.7286610109712757],
					[60.43198909411668, 2.7280249507873617],
					[60.431564094942935, 2.7260888575166304],
					[60.431564093709135, 2.7260888504670051],
					[60.431564089456622, 2.7260888367130636],
					[60.431564087330379, 2.7260888298361081],
					[60.431233496276398, 2.7252193511779512],
					[60.431155798690845, 2.7245582773023695],
					[60.431155794096767, 2.7245582563266559],
					[60.431155781594867, 2.7245582204826149],
					[60.431155774408786, 2.7245582018305186],
					[60.430661299697888, 2.7236777485448118],
					[60.430661300313965, 2.7236693998768238],
					[60.430661270847487, 2.7236693290541076],
					[60.430661170613845, 2.7236692281980748],
					[60.430661099932045, 2.7236691999702654],
					[60.430658417899558, 2.7236691997405882],
					[60.430536274626078, 2.7234499036182385],
					[60.430536256389018, 2.723449879817351],
					[60.430536212053653, 2.7234498373810658],
					[60.430536186933161, 2.723449820378443],
					[60.430036186660665, 2.7232081207021874],
					[60.430036177394719, 2.7232081152075049],
					[60.430036157248679, 2.723208108174541],
					[60.430036147261042, 2.7232081064635389],
					[60.429438946797042, 2.7230637051820623],
					[60.429438916112439, 2.7230637038328256],
					[60.429438854192284, 2.7230637085288398],
					[60.429438823849154, 2.7230637144013734],
					[60.428952769293481, 2.7232636953567346],
					[60.428891717980093, 2.7232581018704201],
					[60.428891668810252, 2.7232581095641724],
					[60.42889157969784, 2.7232581486730529],
					[60.42889154064769, 2.7232581799154372],
					[60.428855477437622, 2.7233054310726925],
					[60.42881663278343, 2.7233192122799643],
					[60.428816609835984, 2.7233192221866367],
					[60.428816567130681, 2.723319252314321],
					[60.428816548179874, 2.7233192705571891],
					[60.427997048698515, 2.7242831690464389],
					[60.427155450086772, 2.7252442684887095],
					[60.427155448387225, 2.7252442706393167],
					[60.427155444095689, 2.7252442751131958],
					[60.427155442396142, 2.7252442772638035],
					[60.426335944950893, 2.7263081724585536],
					[60.425766549056824, 2.7269665687761075],
					[60.425766524206438, 2.7269666336996115],
					[60.425766523124651, 2.7269667632530794],
					[60.42576654600083, 2.7269668280556254],
					[60.42579409279125, 2.7269999434565833],
					[60.42456380102054, 2.7276998258782275],
					[60.424563787082562, 2.7276998358599323],
					[60.424563761076755, 2.7276998572834241],
					[60.424563749008968, 2.7276998687251988],
					[60.422697049108372, 2.7298609689293665],
					[60.422697032453158, 2.7298609976503925],
					[60.422697007345384, 2.7298610571502113],
					[60.422696999870574, 2.7298610895616178],
					[60.422669199929565, 2.7303471895057636],
					[60.422669200782295, 2.7303472075566653],
					[60.422669206142878, 2.7303472447736592],
					[60.422669209672989, 2.7303472623071423],
					[60.423030410075981, 2.7314361622284054],
					[60.42303041113896, 2.7314361656661967],
					[60.423030412201953, 2.731436169103969],
					[60.423030413179667, 2.7314361707366337],
					[60.423394208045444, 2.7324028561257969],
					[60.423666504299611, 2.7336722422441126],
					[60.423666506340233, 2.7336722473147934],
					[60.423666508721759, 2.733672259606124],
					[60.423666510762374, 2.7336722646768052],
					[60.423997008995009, 2.7346417572048649],
					[60.424238701085947, 2.735547232027745],
					[60.424269199751826, 2.7360944117938253],
					[60.424269205406929, 2.7360944362049713],
					[60.424269221179451, 2.7360944841656099],
					[60.424269232189353, 2.7360945075427727],
					[60.424810929709245, 2.7369390066923227],
					[60.425141518950795, 2.7374806873850068],
					[60.425444212030982, 2.7383278670887763],
					[60.425444213093797, 2.7383278705270371],
					[60.425444215219414, 2.7383278774035773],
					[60.425444217174679, 2.7383278806695648],
					[60.425863717034538, 2.7392944800991033],
					[60.42586372298544, 2.7392944917026956],
					[60.425863737649863, 2.7392945161983762],
					[60.425863747170673, 2.7392945271129605],
					[60.426316544591728, 2.7398362268345049],
					[60.426705425327654, 2.7403195012500299],
					[60.427158113974201, 2.7414667726526099],
					[60.427158134418725, 2.7414668051439359],
					[60.427158183721794, 2.7414668575723034],
					[60.427158213643061, 2.7414668809479061],
					[60.42790818892167, 2.7418251677557754],
					[60.428930467628795, 2.7427279505215072],
					[60.428930481611751, 2.7427279605771782],
					[60.428930512169963, 2.7427279783665233],
					[60.428930527852764, 2.7427279862723588],
					[60.429858195844147, 2.7430834746919648],
					[60.430491564235403, 2.743625146052171],
					[60.431249829946047, 2.7443918129103397],
					[60.431633090017748, 2.745313917000554],
					[60.431431859182197, 2.7461526967319863],
					[60.428047232885078, 2.7463191987008817],
					[60.42515571313011, 2.745199828259393],
					[60.418770259817094, 2.7386126659310581],
					[60.418186274199684, 2.7375777028679091],
					[60.418186266378534, 2.7375776898069453],
					[60.418186248144039, 2.7375776660065898],
					[60.418186237730644, 2.7375776552672244],
					[60.412907895997137, 2.7325651138354208],
					[60.410736243940612, 2.7303248609925515],
					[60.410736228893875, 2.7303248475109516],
					[60.410736197271298, 2.7303248263058228],
					[60.41073617971773, 2.7303248169502634],
					[60.398066848124387, 2.7248137457781829],
					[60.397766923036109, 2.7241861030661463],
					[60.398205778264362, 2.7233222903849912],
					[60.398205789222388, 2.7233222555032555],
					[60.398205795625394, 2.7233221814573434],
					[60.398205792133524, 2.723322145728122],
					[60.395153003786298, 2.712713885496556],
					[60.396964096261726, 2.7004222392049417],
					[60.397489094296709, 2.6983389490616645],
					[60.39748909573877, 2.6983389415009698],
					[60.397489097644836, 2.6983389247491885],
					[60.397489099001227, 2.6983389153849138],
					[60.397919595904369, 2.6928722556856806],
					[60.398372377779793, 2.6919806907279602],
					[60.398372385125157, 2.6919806747388688],
					[60.398372395096615, 2.6919806382166476],
					[60.398372398786627, 2.6919806211173638],
					[60.398802999253085, 2.6878167207424677],
					[60.398802948677371, 2.6878166231758764],
					[60.398802788543691, 2.6878165323438585],
					[60.398802679963943, 2.6878165407085732],
					[60.39006103578933, 2.694380398024006],
					[60.376008355811642, 2.6939331018587755],
					[60.370486213102161, 2.6907637331338625],
					[60.362605726401952, 2.684397044705666],
					[60.362605676753674, 2.6843970234370778],
					[60.36260557517528, 2.6843970086172813],
					[60.362605522352766, 2.684397015239369],
					[60.351600022071665, 2.6890580728750968],
					[60.349286212458416, 2.6874859357173539],
					[60.349286145855189, 2.687485917740974],
					[60.349286016835691, 2.687485931866743],
					[60.349285955226023, 2.687485961994859],
					[60.342041554678119, 2.695102661411461],
					[60.34204154796727, 2.6951026717970143],
					[60.342041534374218, 2.6951026889670833],
					[60.342041526770963, 2.6951026995255107],
					[60.336216540919047, 2.7050637769238759],
					[60.333077673381624, 2.7081692440481238],
					[60.331294289357309, 2.7093526335954192],
					[60.331294283281231, 2.7093526384022364],
					[60.331294271043603, 2.7093526462158675],
					[60.331294265945438, 2.7093526526502409],
					[60.326738765617272, 2.7134859523581509],
					[60.326738740296179, 2.7134859881215658],
					[60.326738707293778, 2.7134860707732358],
					[60.326738701311918, 2.7134861155172234],
					[60.327530398903058, 2.7234332892933573],
					[60.326305403305525, 2.7302277640564463],
					[60.326305402583436, 2.7302277678277971],
					[60.326305402031736, 2.7302277751986623],
					[60.326305401224474, 2.7302277771702279],
					[60.325752619134903, 2.735235917347997],
					[60.316741680213248, 2.7361470015177103],
					[60.316741621097805, 2.7361470274074406],
					[60.316741532406219, 2.736147113451795],
					[60.31674150176736, 2.7361471701794655],
					[60.316333102324315, 2.7388999712016768],
					[60.316333104110754, 2.7389000089859081],
					[60.31633312196346, 2.738900081810367],
					[60.316333138029691, 2.7389001168506169],
					[60.322035905316255, 2.7468417707557671],
					[60.32275809995037, 2.7561222037686761],
					[60.322619200112271, 2.7596999917116367],
					[60.322619211581234, 2.7597000439918924],
					[60.322619260786134, 2.7597001326251118],
					[60.322619296821756, 2.7597001711197349],
					[60.323313644290316, 2.7601167798501893],
					[60.323097034046938, 2.7605887817648691],
					[60.321135961236074, 2.7624748555548591],
					[60.321135933732052, 2.7624749025848545],
					[60.321135906900828, 2.7624750021448725],
					[60.321135907658395, 2.7624750564744693],
					[60.331469208161359, 2.7971944568367615],
					[60.333947003853986, 2.8057500437165603],
					[60.334163692185982, 2.8071249628957244],
					[60.33392767372591, 2.8075526136503384],
					[60.332668506844598, 2.8077110633464217],
					[60.333255688241444, 2.8074223784752199],
					[60.333255733866196, 2.8074223334703907],
					[60.333255788065138, 2.8074222214439359],
					[60.333255794938303, 2.8074221565621884],
					[60.329789095007484, 2.7920527559975596],
					[60.329789022145505, 2.7920526808900195],
					[60.329788849905611, 2.7920526374395087],
					[60.329788749550723, 2.7920526674665131],
					[60.324466561294585, 2.7980748548716217],
					[60.312277672625989, 2.8080526441743334],
					[60.312277643235696, 2.80805268969356],
					[60.312277608083427, 2.8080527853191461],
					[60.312277627160498, 2.8080528851637796],
					[60.312277695634542, 2.8080529629168605],
					[60.312277741054771, 2.8080529905925022],
					[60.322491641268897, 2.8112251905920886],
					[60.322491665789812, 2.8112251950129368],
					[60.322491718149784, 2.811225197776869],
					[60.32249174411956, 2.8112251946592326],
					[60.328097244440379, 2.8099584956779537],
					[60.32809725506754, 2.8099584918421265],
					[60.328097277214454, 2.809958484000814],
					[60.328097287757501, 2.809958478364849],
					[60.33145940977726, 2.8083055091766007],
					[60.332324799672747, 2.8227389116480199],
					[60.332324800901148, 2.822738918680368],
					[60.332324802297464, 2.8227389293135436],
					[60.332324802549302, 2.8227389347148004],
					[60.33474420259256, 2.8362250347212479],
					[60.334744206917058, 2.8362250502507154],
					[60.334744218327771, 2.8362250826033732],
					[60.334744225330233, 2.8362250976259307],
					[60.339327624763555, 2.8444472963393266],
					[60.339327636312099, 2.8444473123227563],
					[60.339327659241889, 2.844447340687899],
					[60.339327672408437, 2.8444473527320313],
					[60.342296960610923, 2.8469278618093861],
					[60.342097047627362, 2.8474331403085484],
					[60.329594334541106, 2.8517359104668549],
					[60.329594304691426, 2.8517359269983977],
					[60.329594251742137, 2.85173596968411],
					[60.329594230427873, 2.8517359955012394],
					[60.326797029548899, 2.8562720941985411],
					[60.326797019029364, 2.8562721197902623],
					[60.32679700423909, 2.8562721697950981],
					[60.326797000051826, 2.85627219600847],
					[60.326405400311465, 2.8771388736846095],
					[60.321494214369196, 2.8964082202942882],
					[60.320747029789523, 2.8976165956348745],
					[60.320747025657425, 2.8976166036709849],
					[60.320747018286028, 2.8976166195760302],
					[60.320747014153966, 2.8976166276121327],
					[60.320349836970721, 2.8986248682582212],
					[60.319633190410073, 2.8990970329186094],
					[60.319633174754962, 2.8990970449271898],
					[60.319633148074338, 2.8990970717085447],
					[60.319633135263331, 2.8990970868156314],
					[60.310316534599998, 2.9126387871071562],
					[60.310316523010925, 2.9126388092357915],
					[60.310316506413045, 2.9126388595240664],
					[60.310316500428769, 2.9126388860509347],
					[60.309472000325023, 2.9250055859217539],
					[60.309472000407581, 2.9250055877212531],
					[60.309471999679872, 2.9250055914866433],
					[60.309471999762415, 2.9250055932861461],
					[60.308933100223811, 2.9406666925865461],
				],
				[
					[60.272602600357672, 2.7058000005730225],
					[60.272608100153619, 2.7076083009450573],
					[60.272608129623507, 2.7076083714187513],
					[60.272608230754585, 2.7076084715944866],
					[60.272608303222746, 2.7076084993277436],
					[60.273030481197985, 2.7076030022154751],
					[60.273508123195562, 2.7085028941644107],
					[60.273508156396225, 2.7085029294695935],
					[60.273508233630217, 2.7085029816724679],
					[60.273508278556037, 2.7085029983981199],
					[60.273983170140546, 2.7085529861978586],
					[60.274405420846456, 2.7094000888688559],
					[60.274405457872952, 2.7094001288783627],
					[60.274405546964701, 2.7094001842390738],
					[60.274405599029947, 2.7094001995902435],
					[60.276216576453628, 2.7094112996119755],
					[60.276647021214934, 2.7102750893280096],
					[60.276647058241224, 2.7102751293411105],
					[60.276647147332682, 2.7102751847076489],
					[60.276647199397857, 2.7102752000610546],
					[60.277549875880062, 2.7102779994026642],
					[60.279344220637476, 2.7138556905947251],
					[60.279344252051963, 2.7138557262552268],
					[60.279344327584667, 2.7138557806203272],
					[60.279344373402381, 2.7138557971839545],
					[60.279827668008409, 2.7139223836624282],
					[60.280252621219866, 2.7147722885564649],
					[60.280252658330269, 2.7147723303761122],
					[60.280252749205033, 2.7147723854167021],
					[60.280252802162259, 2.7147724006067393],
					[60.281152676960318, 2.7147641002800729],
					[60.282016521115729, 2.7164917887828492],
					[60.282016551722457, 2.7164918264178608],
					[60.282016627254222, 2.7164918807931744],
					[60.282016671286776, 2.7164918977053798],
					[60.282502667915949, 2.7165640836300193],
					[60.283863720947444, 2.7192611906821993],
					[60.283863758863745, 2.7192612305421968],
					[60.28386384786667, 2.7192612841470796],
					[60.283863900823526, 2.719261299345741],
					[60.284302800959992, 2.7192585001931526],
					[60.284302853359058, 2.7192584846639982],
					[60.284302941705796, 2.7192584295651701],
					[60.28430297943838, 2.7192583896518765],
					[60.285211224600339, 2.7174418982119253],
					[60.286100002676008, 2.7174280007872653],
					[60.286100054989461, 2.7174279834566386],
					[60.286100143335311, 2.7174279283495917],
					[60.286100179282357, 2.7174278887756245],
					[60.287433426358639, 2.7147195952995919],
					[60.287866706000088, 2.7147058001228928],
					[60.287866756613575, 2.7147057849283334],
					[60.287866843087848, 2.7147057283570173],
					[60.287866879119385, 2.7147056905754572],
					[60.28876402774776, 2.7129223908510074],
					[60.289227816341224, 2.7128862987990052],
					[60.289227879743358, 2.7128862684368964],
					[60.289227973262427, 2.7128861705892993],
					[60.28922799989499, 2.712886105589555],
					[60.289250198914878, 2.7119361484942521],
					[60.289666827028825, 2.7110862949504497],
					[60.290122207325652, 2.7110696005105765],
					[60.290122257853191, 2.7110695835114469],
					[60.290122344411124, 2.7110695287240292],
					[60.290122379378218, 2.711069487512396],
					[60.290569524738956, 2.7101612974788654],
					[60.291016702403681, 2.7101558006662967],
					[60.291016753908963, 2.7101557852908411],
					[60.291016842251324, 2.7101557301551797],
					[60.291016878195983, 2.7101556905670465],
					[60.291483422947096, 2.709247398997344],
					[60.292363779164646, 2.7092391016931532],
					[60.292827621791211, 2.7101389920185825],
					[60.292827665235784, 2.710139034441104],
					[60.292827767714236, 2.7101390872501536],
					[60.292827827640593, 2.7101390974645576],
					[60.29329442776978, 2.7100723984821489],
					[60.293294471964607, 2.7100723808865053],
					[60.293294546834815, 2.7100723265303599],
					[60.293294578402644, 2.7100722895977367],
					[60.293722323638704, 2.7092279973020643],
					[60.29461110299696, 2.7092141002634929],
					[60.294611155394485, 2.70921408471236],
					[60.294611241951422, 2.7092140299117982],
					[60.294611277895712, 2.7092139903180557],
					[60.295069522977535, 2.7083112992333218],
					[60.295480601302017, 2.7083085006057219],
					[60.295480670228372, 2.7083084727911024],
					[60.295480770076082, 2.7083083755034245],
					[60.295480800190425, 2.708308308000571],
					[60.295516897597892, 2.7073694503312828],
					[60.29595572399495, 2.7064945993380798],
					[60.296386100952979, 2.7064919009055788],
					[60.296386153350127, 2.7064918853490694],
					[60.296386241690641, 2.7064918301936003],
					[60.29638627941889, 2.7064917902501504],
					[60.296839025461878, 2.7055918964043366],
					[60.297291705669075, 2.7055780007027908],
					[60.297291757088118, 2.7055779835184932],
					[60.297291842750901, 2.705577928875714],
					[60.297291878693983, 2.7055778892746023],
					[60.298205723686458, 2.7037612985176587],
					[60.299058183128317, 2.703753000538951],
					[60.299560924760016, 2.7046528971705568],
					[60.299560961702745, 2.7046529354054414],
					[60.299561050540241, 2.7046529853968311],
					[60.2995611017136, 2.7046530009220673],
					[60.301330476641745, 2.704636300917159],
					[60.301780421132882, 2.705536189298162],
					[60.301780458160941, 2.7055362293348351],
					[60.301780548147129, 2.705536284554376],
					[60.301780601105285, 2.7055362997372097],
					[60.302230601057396, 2.7055335004961858],
					[60.302230654346751, 2.7055334847624866],
					[60.302230742686653, 2.7055334295935838],
					[60.302230779436556, 2.7055333880154078],
					[60.303561225952826, 2.702827995967648],
					[60.304469405583163, 2.7028002000222191],
					[60.304469457087244, 2.7028001846275544],
					[60.30446954355542, 2.7028001279934961],
					[60.304469578605044, 2.7028000885525749],
					[60.304916825236006, 2.7018918987236566],
					[60.305805602122938, 2.7018835007430408],
					[60.305805653626877, 2.7018834853461873],
					[60.305805741964988, 2.7018834301608576],
					[60.305805778799161, 2.7018833903723904],
					[60.306255726296733, 2.7009862941927332],
					[60.306708306736191, 2.7009696005917645],
					[60.306708358154424, 2.7009695833943597],
					[60.3067084429224, 2.7009695288947611],
					[60.30670847886384, 2.7009694892765848],
					[60.307164024075711, 2.7000612995535813],
					[60.307575001239861, 2.7000585000321307],
					[60.307575051936709, 2.7000584866025283],
					[60.30757513857467, 2.7000584335526345],
					[60.307575174601375, 2.7000583957309945],
					[60.308075119905531, 2.699147396198974],
					[60.309861105214182, 2.6991029993291362],
					[60.309861155910902, 2.6991029858968751],
					[60.30986124237716, 2.6991029292428728],
					[60.309861278403552, 2.6991028914173798],
					[60.31031122312536, 2.6982223986499423],
					[60.310711100765403, 2.6982195991492994],
					[60.310711152354394, 2.698219585542672],
					[60.310711239077065, 2.6982195342811384],
					[60.310711275103174, 2.6982194964536408],
					[60.311214018899356, 2.6973084993476339],
					[60.31207500191838, 2.6973001999057602],
					[60.312075054314036, 2.6973001843255093],
					[60.312075141757447, 2.6973001292888252],
					[60.312075178590042, 2.6973000894871859],
					[60.313439027953066, 2.6945751902270851],
					[60.313891714614584, 2.6945418989987999],
					[60.313891763354512, 2.6945418823048999],
					[60.313891846248865, 2.6945418263220313],
					[60.313891878618492, 2.6945417873784403],
					[60.314758479002592, 2.692780687817506],
					[60.314758476331434, 2.692780593923882],
					[60.314758392935872, 2.6927804502950479],
					[60.314758312211488, 2.6927804005598381],
					[60.313858426790787, 2.6927248063530262],
					[60.313422378767697, 2.6918582095004755],
					[60.313422340843168, 2.6918581696388619],
					[60.313422250851616, 2.6918581144396976],
					[60.313422197892187, 2.6918580992749241],
					[60.311191820416752, 2.6918803983122572],
					[60.310716876925774, 2.6909805069940758],
					[60.310716839893431, 2.6909804669638469],
					[60.310716749987307, 2.6909804135700273],
					[60.31071669800594, 2.6909804000336748],
					[60.308075117427542, 2.6910053993569845],
					[60.307572375213148, 2.6901027024848947],
					[60.307572338266318, 2.690102664257926],
					[60.307572249423799, 2.6901026142955753],
					[60.307572198334775, 2.6901026005888498],
					[60.302197322986615, 2.6901553993651293],
					[60.30174457877596, 2.6892555093808967],
					[60.301744540850855, 2.6892554695357784],
					[60.301744450858578, 2.6892554143616247],
					[60.301744397898979, 2.6892553992053361],
					[60.300400121711412, 2.6892664994685251],
					[60.299944578430718, 2.6883666103870794],
					[60.299944541312058, 2.6883665685741476],
					[60.299944451405231, 2.6883665152033691],
					[60.299944398445561, 2.6883665000491686],
					[60.298600123197069, 2.6883803990096165],
					[60.298150179234241, 2.6874832093884731],
					[60.298150142201116, 2.6874831693767995],
					[60.298150052208236, 2.6874831142130367],
					[60.298149999248487, 2.6874830990609446],
					[60.297255722249005, 2.6874886994499136],
					[60.296819577651149, 2.6866277105838177],
					[60.296819546057961, 2.6866276713359296],
					[60.296819466052725, 2.6866276178718782],
					[60.29681942023209, 2.6866276013393611],
					[60.29634732914284, 2.6865804116391359],
					[60.2959001786746, 2.6856916104508781],
					[60.29590014066283, 2.6856915688187555],
					[60.295900049776925, 2.6856915138361828],
					[60.29589999690279, 2.6856915004857695],
					[60.293647197101514, 2.685730399308301],
					[60.293647144793077, 2.6857304166941955],
					[60.293647057354363, 2.6857304717274313],
					[60.293647019632679, 2.6857305116909322],
					[60.293213773365594, 2.6866137044281584],
					[60.292761093935688, 2.6866275992428026],
					[60.292761044304306, 2.6866276161084457],
					[60.29276095767181, 2.6866276691671094],
					[60.292760921734605, 2.6866277087829431],
					[60.292294276659497, 2.6875359021236056],
					[60.288716697197678, 2.6875831005183768],
					[60.288716644717418, 2.6875831143030982],
					[60.288716557363458, 2.6875831711198344],
					[60.288716521425883, 2.6875832107294317],
					[60.288272074729029, 2.6884776005512077],
					[60.287377797903908, 2.688485899602882],
					[60.287377747294059, 2.6884859148375972],
					[60.287377658875783, 2.6884859682270976],
					[60.287377622045483, 2.6884860080066733],
					[60.286908178209075, 2.6893970011925372],
					[60.286483298580599, 2.6893998004707518],
					[60.28648324610009, 2.6893998142514008],
					[60.286483157767087, 2.6893998694342711],
					[60.286483121022194, 2.6893999110091333],
					[60.285574875791262, 2.6912165009125215],
					[60.284255720662976, 2.6912275995754165],
					[60.283797378044788, 2.6903443083414382],
					[60.283797288053272, 2.6903442531914754],
					[60.28379710981396, 2.6903442550141703],
					[60.283797022372823, 2.6903443100169282],
					[60.283352797173109, 2.6912217629990969],
					[60.282900177205377, 2.6903555070215828],
					[60.282900142043843, 2.6903554684762656],
					[60.282900055707685, 2.6903554144345323],
					[60.282900007295993, 2.6903554002175945],
					[60.282475121913286, 2.6903415048310486],
					[60.281991875564302, 2.6894416047553227],
					[60.281991838617721, 2.6894415665572495],
					[60.281991749689738, 2.6894415148343209],
					[60.281991696730223, 2.6894414996848868],
					[60.281558421412129, 2.689446998790705],
					[60.28109187772661, 2.6885471077586898],
					[60.281091839801675, 2.6885470679381047],
					[60.281091750787674, 2.6885470144217729],
					[60.281091698806215, 2.6885470008986325],
					[60.280644522638518, 2.6885497987374452],
					[60.280191879426241, 2.6876499102302289],
					[60.280191789433793, 2.6876498550932464],
					[60.280191610216548, 2.6876498553047585],
					[60.28019152018507, 2.6876499126229958],
					[60.279752673639933, 2.688547001319102],
					[60.278400121717588, 2.688571997700774],
					[60.277516878727965, 2.686811009208705],
					[60.277516847306629, 2.68681097357655],
					[60.277516768108498, 2.686810918171096],
					[60.277516722288006, 2.6868109016463952],
					[60.277039029096095, 2.6867581135593124],
					[60.276591878415317, 2.6858777090852195],
					[60.2765918413821, 2.6858776691002926],
					[60.27659175138907, 2.6858776139736795],
					[60.276591697622592, 2.6858776008015735],
					[60.27615012240404, 2.6858830975729666],
					[60.275691878217465, 2.6849832092657184],
					[60.275691841183992, 2.6849831692828467],
					[60.275691751190642, 2.6849831141601013],
					[60.27569169920892, 2.6849831006443696],
					[60.275280719315639, 2.6849858980634882],
					[60.274808475734716, 2.684105504277666],
					[60.274808439764961, 2.6841054677176928],
					[60.274808351641866, 2.6841054140500984],
					[60.274808301444864, 2.6841054001906413],
					[60.274350000788083, 2.684102599437427],
					[60.274349931060605, 2.6841026292514822],
					[60.274349830338316, 2.6841027267177786],
					[60.274349800407499, 2.6841027977908136],
					[60.274335900892105, 2.6850277528910111],
					[60.273902621346956, 2.6859082118850583],
					[60.273902613366204, 2.6859082333756228],
					[60.273902602844814, 2.6859082771176066],
					[60.27390260030424, 2.6859082993690153],
					[60.273894199677585, 2.6886471544276014],
					[60.273466519962696, 2.6895277128355701],
					[60.273466510332533, 2.6895277564033178],
					[60.273466510962258, 2.6895278451329867],
					[60.273466521136449, 2.6895278884981715],
					[60.273902577954125, 2.6904000034614426],
					[60.273466518163694, 2.6913582176982196],
					[60.273466510988762, 2.6913582372179938],
					[60.273466502164716, 2.6913582788160153],
					[60.273466499537506, 2.6913582992700382],
					[60.273485900140059, 2.6994360556477117],
					[60.273038719929794, 2.7003721143635646],
					[60.273038711946626, 2.7003721358501629],
					[60.273038702227282, 2.7003721776176204],
					[60.273038699684228, 2.7003721998674663],
					[60.273049800089382, 2.7048916534725995],
					[60.272602621328303, 2.7057999113078486],
					[60.272602613344219, 2.7057999327928828],
					[60.272602602901657, 2.7057999783238427],
					[60.272602600357672, 2.7058000005730225],
				],
				[
					[60.235619199524159, 2.7345360993630492],
					[60.235619199889562, 2.7432194001182117],
					[60.235619200229351, 2.7432194072975666],
					[60.235619200908893, 2.7432194216562547],
					[60.235619202141166, 2.7432194286647857],
					[60.237219202019688, 2.7541889295583197],
					[60.23721928701061, 2.7541890129331335],
					[60.237219477408431, 2.7541890399445581],
					[60.237219581838026, 2.7541889819567262],
					[60.239277959169705, 2.7496195354845012],
					[60.241333100089086, 2.7482503742093973],
					[60.241333100362745, 2.7523638453100085],
					[60.239733136831894, 2.755105483806453],
					[60.23813594890472, 2.7569331681133309],
					[60.238135933048547, 2.7569331946874702],
					[60.238135907922981, 2.7569332538225879],
					[60.23813590116162, 2.7569332822821204],
					[60.237908101063312, 2.7596749838094357],
					[60.237908105774231, 2.7596750263787158],
					[60.237908134662412, 2.7596751041786445],
					[60.237908158031829, 2.7596751413747276],
					[60.240191558042142, 2.7619612409048777],
					[60.240191577632324, 2.7619612552791799],
					[60.240191619405671, 2.7619612817215997],
					[60.240191640526852, 2.7619612903697521],
					[60.243163740861249, 2.762877960897796],
					[60.244760904953651, 2.7679056460165046],
					[60.245651495127767, 2.773680735987972],
					[60.242011029663843, 2.7750359129093374],
					[60.24201097941679, 2.7750359550847068],
					[60.242010915169267, 2.7750360633254201],
					[60.242010902061352, 2.7750361292207946],
					[60.242699801782663, 2.7796222302053559],
					[60.242699812228558, 2.7796222608228303],
					[60.242699840007468, 2.7796223153127166],
					[60.242699858317557, 2.7796223408105556],
					[60.246360958043972, 2.7833001405227513],
					[60.246360978609054, 2.7833001565382101],
					[60.246361023948893, 2.7833001823334014],
					[60.246361047831044, 2.7833001922829612],
					[60.249788847567181, 2.7842307931404568],
					[60.249788865756251, 2.7842307969273943],
					[60.249788902773737, 2.7842307989442019],
					[60.24978892079401, 2.784230799139622],
					[60.253911032408922, 2.7837863084064201],
					[60.258941561578141, 2.7883890443985018],
					[60.263519258017851, 2.7929890418426879],
					[60.263519262649368, 2.792989044587455],
					[60.263519272973575, 2.7929890535004045],
					[60.263519277689369, 2.7929890580417198],
					[60.266491578277417, 2.7952918587989588],
					[60.266491635050457, 2.7952918788266676],
					[60.266491752351072, 2.7952918837269789],
					[60.266491811009189, 2.7952918671420952],
					[60.267864010873254, 2.7943807675755648],
					[60.267864035022939, 2.7943807448575351],
					[60.267864073975012, 2.794380692134121],
					[60.267864089754276, 2.7943806637558422],
					[60.268780789990991, 2.7916333621293368],
					[60.268780795142973, 2.7916333375825535],
					[60.268780798053307, 2.7916332844564193],
					[60.268780795895971, 2.7916332576738703],
					[60.268094595867964, 2.7884221586553561],
					[60.26809458860761, 2.7884221382828285],
					[60.268094572470467, 2.7884221014709381],
					[60.268094561724162, 2.7884220835744395],
					[60.265561863182533, 2.7849220814522004],
					[60.266699869021267, 2.7853029664248687],
					[60.271044231191247, 2.7921668064314784],
					[60.271044245591717, 2.7921668254488021],
					[60.271044281364752, 2.7921668585316923],
					[60.271044301036419, 2.7921668747336716],
					[60.272644276892372, 2.7930834604834809],
					[60.27401375840509, 2.7944557411020745],
					[60.274013860397616, 2.7944557652273332],
					[60.274014028977774, 2.7944557114204831],
					[60.27401409735058, 2.7944556331489894],
					[60.27470019419836, 2.7903417516206375],
					[60.275386286642458, 2.7885139693843235],
					[60.275386291252161, 2.7885139141103186],
					[60.275386268254437, 2.788513807880205],
					[60.275386240731365, 2.7885137587212636],
					[60.274927965159847, 2.7880581814797281],
					[60.266705783922902, 2.7711305194077722],
					[60.261219587323275, 2.7569527277707255],
					[60.261219582267188, 2.756952716049859],
					[60.261219569647004, 2.7569526967125628],
					[60.261219563698397, 2.756952685162303],
					[60.25390576628395, 2.7464331885490791],
					[60.246589068174089, 2.7349998928421981],
					[60.246589060438723, 2.7349998816433123],
					[60.246589042290516, 2.7349998597588874],
					[60.246589031962742, 2.7349998508687667],
					[60.238819532433411, 2.7281387498190623],
					[60.238819510077278, 2.728138734184598],
					[60.23881946132834, 2.7281387127467434],
					[60.238819435657739, 2.7281387031821818],
					[60.236305635671712, 2.7276831027263109],
					[60.236305558196392, 2.727683121214616],
					[60.236305439884873, 2.7276832091180636],
					[60.236305400918781, 2.7276832799855093],
					[60.235619201265955, 2.7345360791069968],
					[60.235619200628662, 2.7345360846624667],
					[60.235619200246525, 2.7345360956023685],
					[60.235619199524159, 2.7345360993630492],
				],
			],
		},
		{
			fldNpdidField: "43625",
			wlbNpdidWellbore: "380",
			fldName: "OSEBERG",
			fldCurrentActivitySatus: "Producing",
			wlbName: "30/6-1",
			fldDiscoveryYear: "1979",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43625",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43625",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "OSEBERG AREA UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=380",
			fldHcType: "OIL/GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "3500071",
			fldDateUpdated: "2022-09-13 00:00:00",
			fldDateUpdatedMax: "2022-01-04 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[60.53965541716984, 2.6905138201208856],
					[60.539655425292608, 2.6905139154307847],
					[60.539655515671662, 2.6905140549979167],
					[60.539655599798643, 2.690514100717817],
					[60.539836099723111, 2.6905141005193176],
					[60.53983611497938, 2.6905140993660779],
					[60.53983614272888, 2.6905140957713147],
					[60.539836156920934, 2.6905140911695331],
					[60.54041945738566, 2.6903390927828399],
					[60.540419469707039, 2.6903390867181911],
					[60.540419494435753, 2.6903390764003721],
					[60.540419504972405, 2.6903390706845283],
					[60.541252905243084, 2.6898223706145208],
					[60.541252918284961, 2.689822360751986],
					[60.541252940799282, 2.6898223417245721],
					[60.541252951970513, 2.6898223303993203],
					[60.541502944748459, 2.68953073714159],
					[60.543061205767707, 2.6880695742005751],
					[60.54433611161852, 2.6876252083906675],
					[60.545558199318343, 2.6879029827746601],
					[60.54640263802186, 2.6887612205896372],
					[60.547197023361221, 2.6901972937158649],
					[60.548188720648042, 2.6922111873607397],
					[60.548188723754627, 2.6922111958972432],
					[60.548188730602526, 2.6922112073600029],
					[60.548188735407976, 2.6922112137357508],
					[60.548983134978258, 2.6933612140516128],
					[60.548983235973772, 2.6933612546654619],
					[60.548983414535108, 2.6933612216012386],
					[60.548983493885636, 2.6933611475743975],
					[60.549197386146304, 2.6924917815368952],
					[60.549689055892017, 2.6917640267894698],
					[60.550394518734109, 2.6910362646045143],
					[60.551327874977439, 2.6905918854162247],
					[60.552675032881339, 2.6901446007868395],
					[60.553813901786228, 2.6901335003184488],
					[60.553813953371886, 2.6901334865727029],
					[60.553814039998045, 2.6901334330517295],
					[60.553814076016863, 2.6901333949142652],
					[60.554413879370358, 2.6890254591945708],
					[60.554422016263047, 2.6890444797279565],
					[60.554422017241343, 2.6890444813656971],
					[60.554422019283919, 2.6890444864534713],
					[60.554422020262223, 2.6890444880912145],
					[60.554619219576978, 2.6894472873294903],
					[60.554619245389695, 2.6894473188631078],
					[60.554619303724429, 2.6894473714726166],
					[60.55461933794524, 2.689447390387175],
					[60.554961014011297, 2.6895584816540685],
					[60.555760979387252, 2.6900695608170766],
					[60.55684156581826, 2.6910390495966441],
					[60.556841571344322, 2.6910390521745007],
					[60.556841580783562, 2.6910390613040347],
					[60.55684158728792, 2.6910390655197913],
					[60.557669268186729, 2.6916084523568591],
					[60.558291552041126, 2.692291835577588],
					[60.558291575467223, 2.692291854778289],
					[60.558291627244103, 2.6922918830703089],
					[60.558291653896056, 2.6922918943230676],
					[60.558766654290224, 2.6924029950421384],
					[60.558766662407486, 2.6924029952843958],
					[60.558766677921469, 2.69240299956842],
					[60.55876668603873, 2.69240299981068],
					[60.55953888608807, 2.6924558002528078],
					[60.55953888965756, 2.692455799554887],
					[60.559538897774829, 2.6924557997971337],
					[60.559538902322593, 2.6924558007373083],
					[60.560077802355742, 2.6924501990487002],
					[60.560077815827256, 2.6924501982440328],
					[60.560077841792008, 2.6924501949965736],
					[60.560077855177617, 2.6924501923793005],
					[60.560705654724252, 2.6922695914337234],
					[60.561127853970902, 2.6921501916737345],
					[60.56112785673394, 2.6921501929628966],
					[60.56112786200228, 2.6921501901032259],
					[60.561127864679399, 2.6921501895797246],
					[60.561466765190978, 2.692033488078212],
					[60.561466814767648, 2.6920334509410417],
					[60.561466882119809, 2.6920333517842647],
					[60.561466899981234, 2.6920332915773204],
					[60.561450199955843, 2.6916110920924381],
					[60.561450198462076, 2.691611079578168],
					[60.561450193517977, 2.691611051273286],
					[60.561450190239455, 2.6916110391080563],
					[60.561422401604339, 2.6915249737715778],
					[60.561430798911992, 2.6913750224558548],
					[60.561439097126204, 2.6913278343651399],
					[60.561439097674914, 2.6913278269398768],
					[60.561439100385229, 2.6913278081149441],
					[60.561439100041547, 2.6913278008642112],
					[60.561439099881405, 2.6912750110723112],
					[60.561455799431123, 2.6911278229222404],
					[60.561455799345197, 2.6911278211095633],
					[60.561455799345197, 2.6911278211095633],
					[60.56146689801475, 2.6910250267378277],
					[60.561491897049201, 2.6908750331510936],
					[60.561491897855653, 2.6908750311638774],
					[60.561491897683801, 2.6908750275384969],
					[60.561491897683801, 2.6908750275384969],
					[60.561511297782644, 2.6907306311935186],
					[60.561527997065824, 2.6906361354429289],
					[60.561527996979898, 2.6906361336302465],
					[60.561527997700402, 2.6906361298303203],
					[60.56152799752855, 2.6906361262049563],
					[60.561544597031485, 2.6905139348656335],
					[60.561564095293221, 2.6904250421526981],
					[60.561564095927807, 2.6904250365400797],
					[60.561564098175246, 2.6904250269529921],
					[60.561564098809818, 2.690425021340392],
					[60.561572397178907, 2.6903472362498979],
					[60.561616893440721, 2.6901833526052665],
					[60.561616894161219, 2.6901833488053359],
					[60.56161689479579, 2.6901833431927051],
					[60.561616896408665, 2.6901833392182177],
					[60.561652996082813, 2.6900028391930109],
					[60.561652998330231, 2.6900028296058665],
					[60.561653000062037, 2.6900028091425878],
					[60.561652999632358, 2.6900028000791343],
					[60.561653000134775, 2.6899639413851366],
					[60.561669584020848, 2.6899250786853774],
					[60.561669588053, 2.6899250687490941],
					[60.561669594246652, 2.6899250474129688],
					[60.561669595601707, 2.6899250380003821],
					[60.561705795361156, 2.6897389432610455],
					[60.561750193726986, 2.6895639482455937],
					[60.561750195339833, 2.6895639442710633],
					[60.561750196608934, 2.6895639330457239],
					[60.561750198135833, 2.6895639272585012],
					[60.561758495595257, 2.6895056423259946],
					[60.561775191680724, 2.6894500575364271],
					[60.561775193293563, 2.6894500535618659],
					[60.561775193842166, 2.6894500461365149],
					[60.561775194562649, 2.6894500423365373],
					[60.561811295031887, 2.689286143114813],
					[60.561811295838297, 2.6892861411275351],
					[60.561811295752349, 2.6892861393148264],
					[60.561811295666423, 2.6892861375021311],
					[60.56183909609129, 2.6891417379332871],
					[60.561839096983668, 2.6891417377586966],
					[60.561839096811767, 2.6891417341333081],
					[60.561839096811767, 2.6891417341333081],
					[60.561855795767357, 2.6890444428313929],
					[60.561883494076717, 2.6889361499952438],
					[60.561883494711239, 2.688936144382561],
					[60.561883496958593, 2.6889361347952776],
					[60.561883497593101, 2.6889361291825797],
					[60.561900197138478, 2.6888278366178024],
					[60.561919595700935, 2.6887361419576905],
					[60.561919595529034, 2.6887361383322959],
					[60.561919597141866, 2.6887361343576797],
					[60.561919597055919, 2.688736132544983],
					[60.561944596622631, 2.6885806368939629],
					[60.561980795958725, 2.6884083407819324],
					[60.561980795958725, 2.6884083407819324],
					[60.561980795786795, 2.6884083371565182],
					[60.561980796679158, 2.6884083369819067],
					[60.561989097297356, 2.6883639367229737],
					[60.561997395301226, 2.6883194436448772],
					[60.562041893704148, 2.6881444490212294],
					[60.562041895316973, 2.6881444450465857],
					[60.562041895951452, 2.6881444394338461],
					[60.5620418966719, 2.6881444356338258],
					[60.562069595603568, 2.6879889375149615],
					[60.562086294801937, 2.6879083428639374],
					[60.562105793671748, 2.6878222513755885],
					[60.562150191439137, 2.6876750583649849],
					[60.562150192159557, 2.6876750545649251],
					[60.562150193686392, 2.6876750487775407],
					[60.562150195299175, 2.6876750448028517],
					[60.562186293691099, 2.6875167474670985],
					[60.562202992951327, 2.6874528522355936],
					[60.562219591192793, 2.6873944616569885],
					[60.562275186841099, 2.6872361715585349],
					[60.562327984627927, 2.6871056758704075],
					[60.562327986326686, 2.687105673708396],
					[60.562327987133074, 2.687105671721016],
					[60.562327987047084, 2.6871056699083034],
					[60.562372383907011, 2.6869861779494335],
					[60.562433477972242, 2.6868583918508704],
					[60.562489074882237, 2.6867583963362094],
					[60.562489075688603, 2.6867583943488302],
					[60.562489079000123, 2.6867583882120263],
					[60.562489080698867, 2.6867583860499864],
					[60.562541872915538, 2.6866473013213437],
					[60.562614063322556, 2.686544516385263],
					[60.562666860753502, 2.6864723172183371],
					[60.562666861731856, 2.6864723188563975],
					[60.562719552412872, 2.6864001293598099],
					[60.562775140756798, 2.6863445411185558],
					[60.562819537219234, 2.686300146747068],
					[60.562883424912755, 2.6862445564202337],
					[60.562936216320239, 2.6862057636002001],
					[60.56299730216729, 2.6861640736296257],
					[60.563069482075271, 2.6861279820835522],
					[60.563125070117323, 2.6861057864797684],
					[60.563197255031525, 2.68608079381697],
					[60.563222243603548, 2.6860751941701548],
					[60.563222247172995, 2.6860751934714209],
					[60.563222251634812, 2.6860751925980058],
					[60.563222255204252, 2.6860751918992793],
					[60.563241749886963, 2.6860695937176775],
					[60.563313937656801, 2.6860529963522786],
					[60.563366730680521, 2.6860445975663065],
					[60.563438929750447, 2.6860334976567639],
					[60.563438934212272, 2.6860334967833359],
					[60.563438941351151, 2.6860334953858525],
					[60.563438944920627, 2.6860334946871078],
					[60.563475033893937, 2.6860251968711988],
					[60.563547222642136, 2.6860168981980972],
					[60.563547223620489, 2.6860168998361886],
					[60.563547225319226, 2.6860168976740364],
					[60.56359172468693, 2.6860112987417066],
					[60.563591725579286, 2.6860112985670219],
					[60.563591729148747, 2.6860112978682715],
					[60.563591730041097, 2.6860112976935868],
					[60.563663929997375, 2.6860001974266967],
					[60.563663931782095, 2.6860001970773184],
					[60.563663935351528, 2.6860001963785689],
					[60.563663937222273, 2.6860001978419747],
					[60.563708330048797, 2.6859918972308674],
					[60.563780623070087, 2.6859834993037119],
					[60.563780628424254, 2.6859834982555753],
					[60.563780637347875, 2.6859834965086793],
					[60.563780640917336, 2.6859834958099227],
					[60.563833331133033, 2.6859723980329466],
					[60.563886121235782, 2.685966899469288],
					[60.563886122128139, 2.6859668992945953],
					[60.563886124805244, 2.6859668987705234],
					[60.563886125697586, 2.6859668985958338],
					[60.563950022805194, 2.6859584993042325],
					[60.564002819152897, 2.6859529994633853],
					[60.564066713973276, 2.685947399904427],
					[60.564119399737471, 2.6859446002670415],
					[60.56417499000581, 2.685947399769518],
					[60.564227772896693, 2.6859501981369429],
					[60.564291656807384, 2.6859640956483934],
					[60.564352741457093, 2.6859779923071878],
					[60.564416619267107, 2.6860029825362552],
					[60.564461003988384, 2.6860251746682802],
					[60.564516596222965, 2.686058470869424],
					[60.564560990091749, 2.686086267464916],
					[60.564605474989506, 2.6861168566941593],
					[60.56467766386325, 2.6861834465438101],
					[60.564677665733988, 2.6861834480072684],
					[60.564677667604691, 2.6861834494707337],
					[60.564677670453754, 2.686183452572366],
					[60.564777664245263, 2.6862695455332841],
					[60.564794258032734, 2.6862862407135699],
					[60.564794258925097, 2.6862862405388803],
					[60.564813756096278, 2.6863057394854075],
					[60.564902654088392, 2.6864001365310863],
					[60.56490265782984, 2.6864001394580672],
					[60.564902664420309, 2.686400145486695],
					[60.564902667269401, 2.6864001485883597],
					[60.565002656686637, 2.6864890393960952],
					[60.565019247714815, 2.6865084304812425],
					[60.565035948359601, 2.6865279300362577],
					[60.565035955036066, 2.6865279378778109],
					[60.565035969195399, 2.6865279515733507],
					[60.565035975785889, 2.686527957602042],
					[60.565127674601989, 2.6866001566961031],
					[60.565199874254745, 2.6866584542562451],
					[60.56524427360398, 2.6866945543491236],
					[60.565244279216095, 2.686694558739672],
					[60.565244290440376, 2.6866945675207825],
					[60.565244295966522, 2.6866945700984712],
					[60.565308191061334, 2.6867334684196238],
					[60.565344285671529, 2.6867584632002202],
					[60.565344293154382, 2.6867584690543298],
					[60.56534430892642, 2.686758478774979],
					[60.565344316323284, 2.6867584828162059],
					[60.565380516398726, 2.6867751811885068],
					[60.565380517377079, 2.6867751828267097],
					[60.565380518269421, 2.6867751826520325],
					[60.565461018269183, 2.6868112828393214],
					[60.565522117304127, 2.6868390827362769],
					[60.565522118196498, 2.6868390825615993],
					[60.565577718328313, 2.6868640817558416],
					[60.565577723854474, 2.68686408433358],
					[60.565577734906768, 2.6868640894890263],
					[60.565577741239302, 2.6868640900791729],
					[60.565649939960885, 2.686886290337541],
					[60.565702739970206, 2.6869029910374547],
					[60.565702741754933, 2.6869029906880968],
					[60.565702746388716, 2.6869029934405315],
					[60.565702748173457, 2.6869029930911736],
					[60.565774948301581, 2.6869223937263134],
					[60.565774949193958, 2.6869223935516344],
					[60.565774950978664, 2.6869223932022814],
					[60.56581935075576, 2.6869334943817478],
					[60.565819351648123, 2.6869334942070733],
					[60.565819354411197, 2.6869334954959463],
					[60.565819355303546, 2.6869334953212696],
					[60.565891655148853, 2.6869501941495542],
					[60.565891656127192, 2.686950195787805],
					[60.565891658804304, 2.6869501952637695],
					[60.565891659782643, 2.6869501969020058],
					[60.565919359940949, 2.686955795239419],
					[60.565919367251809, 2.6869557974678453],
					[60.565919379024521, 2.6869557988228574],
					[60.565919385357027, 2.6869557994130293],
					[60.565955571690118, 2.6869584978693264],
					[60.566008259090673, 2.6869695961937721],
					[60.566008262660141, 2.686969595495055],
					[60.566008270863364, 2.6869695975487948],
					[60.566008275411164, 2.6869695984883228],
					[60.566052775425653, 2.6869751982218033],
					[60.566052778188727, 2.6869751995106896],
					[60.566052783542901, 2.6869751984626182],
					[60.566052787198338, 2.6869751995768301],
					[60.566141687375236, 2.6869807994274901],
					[60.566141691923036, 2.6869808003670244],
					[60.566141701825018, 2.6869808002585045],
					[60.566141707179199, 2.68698079921043],
					[60.56622500690797, 2.6869780007023913],
					[60.566225011369767, 2.6869779998289958],
					[60.566225020293409, 2.6869779980822019],
					[60.566225024755241, 2.6869779972088002],
					[60.566269425063162, 2.6869723981791176],
					[60.566269427740231, 2.6869723976550777],
					[60.566269434879153, 2.6869723962576395],
					[60.566269437556237, 2.6869723957335969],
					[60.566341738030061, 2.6869584954070804],
					[60.566341738922425, 2.6869584952323993],
					[60.566341741685498, 2.6869584965213154],
					[60.56634174338425, 2.686958494358997],
					[60.566366743051901, 2.6869529949958015],
					[60.566366751975544, 2.6869529932489908],
					[60.566366769736845, 2.6869529879424245],
					[60.566366778574512, 2.6869529843826725],
					[60.566386179164454, 2.6869445848079736],
					[60.566386179970856, 2.6869445828203489],
					[60.566386180863205, 2.6869445826456695],
					[60.566386181755568, 2.6869445824709897],
					[60.566466781749661, 2.6869084833166053],
					[60.566466786125496, 2.6869084806302466],
					[60.566466795855511, 2.6869084768957854],
					[60.566466800145363, 2.6869084723964591],
					[60.566519499773307, 2.6868779737439259],
					[60.566519505041498, 2.6868779708828723],
					[60.566519512900804, 2.6868779656848178],
					[60.566519517190684, 2.6868779611854934],
					[60.566600116919695, 2.6868195621211228],
					[60.566600122994267, 2.6868195572724134],
					[60.566600134337037, 2.6868195495626144],
					[60.566600138540927, 2.6868195432503028],
					[60.56668072343637, 2.6867418607693212],
					[60.566708403162465, 2.6867251706432547],
					[60.566708420579815, 2.6867251580846436],
					[60.566708451587083, 2.6867251282272964],
					[60.566708466069393, 2.6867251107538839],
					[60.566725162153013, 2.6867001171541633],
					[60.566805756585318, 2.686597323664301],
					[60.566805758284055, 2.6865973215019507],
					[60.566805759982785, 2.6865973193395982],
					[60.566805760875177, 2.6865973191649015],
					[60.566877960798323, 2.6865001190734743],
					[60.566877961690686, 2.6865001188987789],
					[60.566877964195818, 2.6865001147487306],
					[60.566877965002185, 2.6865001127610677],
					[60.566930762888767, 2.6864223152021061],
					[60.567011260954814, 2.6863140186230581],
					[60.567011261761188, 2.6863140166353823],
					[60.567011262653551, 2.6863140164606776],
					[60.567039063318759, 2.6862751156031703],
					[60.567039068328967, 2.6862751073030213],
					[60.567039076650659, 2.6862750928651282],
					[60.567039081660866, 2.6862750845649841],
					[60.567055774834778, 2.6862389986864326],
					[60.567136266158862, 2.6861195113332657],
					[60.56713626696525, 2.686119509345573],
					[60.56713626866398, 2.6861195071831578],
					[60.567136270362717, 2.686119505020756],
					[60.567191864527608, 2.6860307122263247],
					[60.56726405808697, 2.685933423003533],
					[60.567352955282203, 2.6858251263236665],
					[60.56735295698094, 2.6858251241612354],
					[60.567352962077102, 2.6858251176739327],
					[60.567352963689835, 2.6858251136985087],
					[60.567377958630715, 2.6857890211619329],
					[60.567405753220669, 2.6857557290029574],
					[60.567486252342761, 2.6856612294610631],
					[60.567486257438937, 2.6856612229737191],
					[60.567486266652914, 2.6856612083607252],
					[60.567486271749075, 2.6856612018733839],
					[60.567539072214466, 2.6855723028360323],
					[60.567539081804782, 2.6855722771702362],
					[60.567539095631275, 2.685572226887043],
					[60.567539099781413, 2.6855722004566172],
					[60.567539099863119, 2.6854778006463635],
					[60.567539100044208, 2.6853389008372517],
					[60.567539099614194, 2.6853388917722354],
					[60.567539097861797, 2.6853388738169315],
					[60.567539097431776, 2.6853388647519147],
					[60.567511296598113, 2.6851832658580057],
					[60.567511296340093, 2.6851832604190138],
					[60.56751129421135, 2.6851832535164801],
					[60.567511294039342, 2.6851832498904824],
					[60.567494594357122, 2.6851193482909466],
					[60.567494593464758, 2.6851193484656837],
					[60.567494592400394, 2.6851193450144306],
					[60.567494592314382, 2.6851193432014173],
					[60.567475193539615, 2.6850527496783712],
					[60.567439095335395, 2.6848943552254299],
					[60.567439095335395, 2.6848943552254299],
					[60.567439095249391, 2.6848943534124365],
					[60.567402995211388, 2.6847416577590342],
					[60.56738629573087, 2.6846610592754954],
					[60.567386295644866, 2.6846610574625052],
					[60.567386294666491, 2.6846610558242689],
					[60.567386294666491, 2.6846610558242689],
					[60.567366894744282, 2.6845777565941598],
					[60.567339095943538, 2.6844416592054361],
					[60.567339095943538, 2.6844416592054361],
					[60.567314095632575, 2.6843221595056126],
					[60.567314095546557, 2.6843221576926273],
					[60.567314094568196, 2.6843221560544017],
					[60.567314095374527, 2.6843221540666433],
					[60.567286295250753, 2.6842027582156174],
					[60.567258495641099, 2.6840638619540238],
					[60.567258496361433, 2.6840638581532943],
					[60.567258495297025, 2.6840638547020856],
					[60.567258494232618, 2.6840638512508925],
					[60.567239096939495, 2.6839860635873172],
					[60.567230798841187, 2.6839166767791047],
					[60.567230797776801, 2.6839166733279005],
					[60.56723079671238, 2.683916669876715],
					[60.5672307974327, 2.6839166660759894],
					[60.567202996782953, 2.6837555655037968],
					[60.567202996696921, 2.6837555636908226],
					[60.567202996438844, 2.6837555582518777],
					[60.567202995460462, 2.6837555566136779],
					[60.56716689762623, 2.6835971664863369],
					[60.567158499243682, 2.6835221783238223],
					[60.56715849800721, 2.6835221712466968],
					[60.567158495706337, 2.6835221607184057],
					[60.567158494555891, 2.6835221554542708],
					[60.567139096254891, 2.6834360592766662],
					[60.56711409665764, 2.6833082625808387],
					[60.567094596663274, 2.6831999651858176],
					[60.567094596577235, 2.683199963372835],
					[60.567094595598846, 2.683199961734656],
					[60.567066897101398, 2.6830582658818511],
					[60.567039097004148, 2.6828888683818102],
					[60.567039094703212, 2.6828888578536083],
					[60.567039090187372, 2.6828888386101686],
					[60.567039087080104, 2.6828888300697296],
					[60.567022392640588, 2.6828443456179687],
					[60.567014096771921, 2.6828027649754822],
					[60.566986298064464, 2.6826221691729639],
					[60.566986296913981, 2.6826221639088725],
					[60.566986293634635, 2.6826221517425526],
					[60.566986292398099, 2.6826221446655034],
					[60.56693909731937, 2.682455561024248],
					[60.56693079914735, 2.682386076349927],
					[60.566930795867954, 2.682386064183655],
					[60.566930790201567, 2.6823860396762789],
					[60.5669307869222, 2.6823860275100033],
					[60.566902994070418, 2.6823138477463848],
					[60.56687799734577, 2.6821610678870513],
					[60.566877996109206, 2.6821610608100461],
					[60.566877992829816, 2.6821610486438154],
					[60.566877990700931, 2.6821610417416246],
					[60.566830797086716, 2.6820082593874059],
					[60.566822398978267, 2.6819332777326594],
					[60.566822396591228, 2.6819332653916508],
					[60.566822389946346, 2.6819332392463155],
					[60.566822386752982, 2.6819332288930782],
					[60.566794590385811, 2.681861037604798],
					[60.56675019261786, 2.68169714846703],
					[60.56675019342417, 2.6816971464792578],
					[60.566750191381288, 2.6816971413900723],
					[60.566750191295249, 2.6816971395771283],
					[60.566694600360769, 2.6815193690435484],
					[60.566694599607004, 2.6814777997295227],
					[60.56669459776802, 2.6814777799619929],
					[60.566694589628305, 2.6814777413011002],
					[60.56669458421991, 2.6814777222329083],
					[60.566677987111923, 2.6814388281881958],
					[60.566622389861728, 2.6812693376944714],
					[60.566622388797263, 2.6812693342434217],
					[60.566622386754368, 2.6812693291543015],
					[60.566622385689897, 2.6812693257032549],
					[60.566569589196831, 2.6811360347943145],
					[60.566533491607906, 2.6810138435490334],
					[60.566533491435777, 2.6810138399231604],
					[60.566533488414464, 2.6810138331959843],
					[60.566533487349986, 2.6810138297449568],
					[60.566477986647371, 2.68086662873139],
					[60.566477983712126, 2.6808666238171486],
					[60.566477977583425, 2.6808666085499038],
					[60.566477974562119, 2.6808666018227347],
					[60.566425182473878, 2.6807721155743209],
					[60.566369586669879, 2.680624928570968],
					[60.566369582584073, 2.6806249183928608],
					[60.56636957271381, 2.6806249001992857],
					[60.566369566843292, 2.6806248903708898],
					[60.566316871250521, 2.6805442977360925],
					[60.566244575174657, 2.6804138036807745],
					[60.566200175008291, 2.6803332036529306],
					[60.566200172965381, 2.6803331985639316],
					[60.566200168073266, 2.6803331903736876],
					[60.56620016522406, 2.6803331872724607],
					[60.566127964841236, 2.6802276865358858],
					[60.566127961992031, 2.6802276834346741],
					[60.566127957099908, 2.6802276752444669],
					[60.566127954250717, 2.6802276721432543],
					[60.566047353915309, 2.6801304727995268],
					[60.566047351066118, 2.680130469698331],
					[60.566047345281639, 2.6801304616830199],
					[60.566047341540077, 2.6801304587566768],
					[60.566011244989767, 2.6800942619735091],
					[60.565983448390533, 2.6800637651590637],
					[60.565983442778204, 2.6800637607695732],
					[60.565983433338253, 2.6800637516408674],
					[60.565983427639829, 2.6800637454385012],
					[60.565902927890953, 2.6799970457698312],
					[60.565902924149398, 2.6799970428435262],
					[60.565902916752357, 2.6799970388037977],
					[60.565902913010817, 2.6799970358774936],
					[60.565822312640542, 2.6799415342243806],
					[60.565822308898987, 2.679941531298073],
					[60.565822298738816, 2.6799415259701078],
					[60.565822294104905, 2.6799415232186754],
					[60.565775094466339, 2.679916523398358],
					[60.565775089832449, 2.6799165206469437],
					[60.565775081629113, 2.6799165185950016],
					[60.565775077887544, 2.6799165156687228],
					[60.56572228560244, 2.6798942184103178],
					[60.565686195063165, 2.6798748236294276],
					[60.565686183118288, 2.6798748186512498],
					[60.56568615833617, 2.6798748088697257],
					[60.565686144606588, 2.6798748042412881],
					[60.565650044999948, 2.6798665050311152],
					[60.565577844534808, 2.6798498044386476],
					[60.565577841771685, 2.679849803150387],
					[60.565577834546772, 2.6798498027364719],
					[60.565577830891314, 2.6798498016230838],
					[60.565525031307779, 2.6798415016965778],
					[60.565525027738367, 2.6798415023960662],
					[60.565525021405833, 2.6798415018072936],
					[60.565525016858011, 2.679841500868779],
					[60.565461117030466, 2.6798359013735547],
					[60.56546111337498, 2.6798359002601568],
					[60.565461104365397, 2.679835900196001],
					[60.565461099817561, 2.6798358992574887],
					[60.565416703917791, 2.6798359007936345],
					[60.565344407748491, 2.6798330994384836],
					[60.565344404179086, 2.6798331001379663],
					[60.565344396954202, 2.6798330997240689],
					[60.565344393384777, 2.6798331004235507],
					[60.565263893360182, 2.6798358991981326],
					[60.565263891575476, 2.6798358995478742],
					[60.565263888898414, 2.6798359000724812],
					[60.565263887113716, 2.6798359004222214],
					[60.565219387189565, 2.6798386998447006],
					[60.565219386297215, 2.6798387000195691],
					[60.56521938362016, 2.6798387005441771],
					[60.565219382727811, 2.6798387007190461],
					[60.565155583013713, 2.6798442008253907],
					[60.565155582121363, 2.6798442010002561],
					[60.565155580336665, 2.6798442013499959],
					[60.565155579444315, 2.6798442015248631],
					[60.565102781476234, 2.6798498017051795],
					[60.565038885518504, 2.6798553992929728],
					[60.564986090410933, 2.6798581006034357],
					[60.564986087647782, 2.6798580993152039],
					[60.564986085949165, 2.6798581014777736],
					[60.564986085056816, 2.679858101652639],
					[60.564913885178811, 2.6798637016568705],
					[60.564877784793275, 2.6798665017332959],
					[60.564805584641462, 2.6798720011049801],
					[60.564769385149702, 2.6798748004492894],
					[60.564688886617873, 2.6798803999461587],
					[60.564644390072658, 2.6798830997424417],
					[60.564580590996492, 2.6798859006803495],
					[60.564580590104143, 2.6798859008552123],
					[60.564580588233362, 2.6798858993921222],
					[60.564580587341013, 2.679885899566985],
					[60.564536086605784, 2.6798887007909178],
					[60.564536085713435, 2.6798887009657797],
					[60.564455586013999, 2.679894199947586],
					[60.56445558512165, 2.6798942001224488],
					[60.564419384821576, 2.6798970013749943],
					[60.564347192075502, 2.6799025997169417],
					[60.564313900321487, 2.6799026006306201],
					[60.564313896666015, 2.6799025995172614],
					[60.56431389220424, 2.6799026003915665],
					[60.564313890419541, 2.6799026007412876],
					[60.564258289908096, 2.6799053996435354],
					[60.564258289015733, 2.6799053998183977],
					[60.564258286338699, 2.6799054003429759],
					[60.564258284553986, 2.6799054006927001],
					[60.564222184870673, 2.6799081016801862],
					[60.564222183978309, 2.679908101855045],
					[60.564222181215193, 2.6799081005668106],
					[60.56422218032283, 2.6799081007416712],
					[60.564194385723468, 2.6799108996802845],
					[60.564124991794394, 2.6799137008318659],
					[60.56412498992362, 2.6799136993687926],
					[60.564124986354194, 2.6799137000682323],
					[60.564124984569496, 2.6799137004179516],
					[60.564088888641095, 2.6799164994413815],
					[60.564008296623065, 2.679919200461772],
					[60.56397220285259, 2.6799191996718106],
					[60.563891712844303, 2.6799164996783205],
					[60.563836119784483, 2.6799109007812838],
					[60.563783333388102, 2.6799054014521841],
					[60.563747245372497, 2.6798970051538609],
					[60.56374724358777, 2.679897005503578],
					[60.563747241716996, 2.6798970040405052],
					[60.563747239932297, 2.6798970043902206],
					[60.563666755633029, 2.6798804072491409],
					[60.563622274014413, 2.6798637135152812],
					[60.563550084787423, 2.6798331191376654],
					[60.563486201183458, 2.679799827056653],
					[60.563422313160309, 2.6797581343492687],
					[60.563341816688521, 2.6796998376270773],
					[60.563341815796171, 2.6796998378019361],
					[60.563341812946966, 2.6796998347010228],
					[60.563341811076192, 2.6796998332379971],
					[60.563316826970812, 2.6796831431708288],
					[60.563297341291779, 2.6796637583097431],
					[60.563297339421005, 2.6796637568467041],
					[60.563297335679437, 2.6796637539206589],
					[60.563297333808663, 2.6796637524576346],
					[60.56320843421436, 2.679583151409386],
					[60.56320843421436, 2.679583151409386],
					[60.563208433235921, 2.6795831497715126],
					[60.563208432343572, 2.6795831499463731],
					[60.563116851626752, 2.6795026667073425],
					[60.563100166166329, 2.6794776891247944],
					[60.563100153704951, 2.6794776732706818],
					[60.563100128148101, 2.6794776471755291],
					[60.563100114074196, 2.679477635296605],
					[60.563064024143515, 2.6794526418463227],
					[60.562991833354111, 2.6793887504843332],
					[60.562991825870967, 2.6793887446323446],
					[60.562991811883123, 2.6793887345662406],
					[60.562991805378431, 2.6793887303521071],
					[60.562919506844771, 2.6793442302701664],
					[60.562855708639617, 2.6793026312862738],
					[60.562855704005692, 2.6793026285351615],
					[60.56285569295315, 2.6793026233826969],
					[60.562855687426897, 2.679302620806447],
					[60.562775092346087, 2.679263722585171],
					[60.562750096694707, 2.6792498254920427],
					[60.56275008466374, 2.6792498187017522],
					[60.562750058989273, 2.6792498090963419],
					[60.562750046152004, 2.6792498042936352],
					[60.56270284808933, 2.679238706588404],
					[60.56265005358226, 2.679224806777432],
					[60.562622257062387, 2.6792165082577006],
					[60.562622247966715, 2.6792165063809699],
					[60.56262222977535, 2.6792165026274959],
					[60.562622220679678, 2.6792165007507651],
					[60.562541721139233, 2.6792081015111484],
					[60.562541719268452, 2.6792081000481658],
					[60.562541715699048, 2.6792081007476356],
					[60.56254171480667, 2.6792081009225073],
					[60.562505615345955, 2.6792054014991011],
					[60.562505614453606, 2.6792054016739688],
					[60.562505612582825, 2.6792054002110035],
					[60.562416713141459, 2.6791997996975545],
					[60.562416711356761, 2.6791998000472872],
					[60.562416708679713, 2.6791998005718862],
					[60.562416707787364, 2.6791998007467539],
					[60.562344407961319, 2.6791969995489202],
					[60.562344406176621, 2.6791969998986516],
					[60.56234440171486, 2.6791970007729833],
					[60.562344399844086, 2.6791969993100264],
					[60.562291699859266, 2.6791970004314107],
					[60.562227800190335, 2.6791970007783994],
					[60.562191700095525, 2.6791970000842156],
					[60.562163899542696, 2.67919699947976],
					[60.562094405917598, 2.6791970001012007],
					[60.562047211937575, 2.6791942006462888],
					[60.56204720917443, 2.6791941993581916],
					[60.562047202927992, 2.67919420058224],
					[60.562047200164841, 2.6791941992941641],
					[60.561986105186989, 2.6791942007048202],
					[60.561930610066682, 2.6791915002673359],
					[60.561930609174333, 2.6791915004421978],
					[60.561869409033193, 2.6791886994825536],
					[60.561869407248508, 2.6791886998322778],
					[60.561869401894413, 2.6791887008814546],
					[60.56186940002361, 2.6791886994185061],
					[60.561813905247469, 2.679188699306311],
					[60.561752808759465, 2.6791858999911353],
					[60.561752804211615, 2.6791858990527935],
					[60.56175279341732, 2.6791858993384903],
					[60.56175278717086, 2.6791859005625258],
					[60.561708293592353, 2.6791887008654038],
					[60.561625000046767, 2.6791886997604499],
					[60.56162499826204, 2.6791887001101764],
					[60.561624993714204, 2.6791886991718181],
					[60.561624991929506, 2.6791886995215402],
					[60.561555591606123, 2.6791914992590842],
					[60.561555590713773, 2.6791914994339461],
					[60.56155558892906, 2.6791914997836672],
					[60.561508288686078, 2.6791942005470615],
					[60.561508286901379, 2.6791942008967813],
					[60.56150828333196, 2.6791942015962267],
					[60.561508282439611, 2.6791942017710855],
					[60.561447182188111, 2.679199800619481],
					[60.561447176834015, 2.6791998016686387],
					[60.561447167910494, 2.679199803417236],
					[60.561447163362658, 2.6791998024788986],
					[60.561402769761578, 2.6792081027395573],
					[60.561330576684078, 2.6792165008898787],
					[60.561330575791743, 2.6792165010647362],
					[60.561330574899372, 2.6792165012395968],
					[60.561286075328432, 2.6792220011135366],
					[60.56128607354372, 2.6792220014632537],
					[60.561286070866672, 2.6792220019878257],
					[60.561286069974322, 2.6792220021626854],
					[60.561213870098463, 2.6792331019027369],
					[60.56121386831375, 2.6792331022524527],
					[60.56121386474436, 2.6792331029518812],
					[60.561213862959633, 2.6792331033015966],
					[60.561169365973655, 2.6792415023625118],
					[60.561097170642228, 2.6792526029411641],
					[60.56107777470416, 2.6792554028353601],
					[60.56105278177391, 2.6792581011374299],
					[60.560980586375614, 2.6792637003822377],
					[60.560936093683132, 2.6792665002705123],
					[60.560844406116061, 2.6792665007571825],
					[60.560755622902533, 2.6792609010888513],
					[60.560738935955492, 2.679258102490671],
					[60.560711139077412, 2.6792526034874697],
					[60.560711138185063, 2.6792526036623223],
					[60.560638949089132, 2.679238706821192],
					[60.560594465098205, 2.6792248111540404],
					[60.560513979630016, 2.679194216222081],
					[60.560458399199995, 2.6791665256772745],
					[60.560397306913032, 2.6791276305038139],
					[60.560314020890132, 2.6790748392460149],
					[60.560289033092346, 2.6790526506644787],
					[60.560289032199996, 2.6790526508393344],
					[60.56028903024314, 2.6790526475638976],
					[60.560289029350791, 2.6790526477387542],
					[60.560252930653633, 2.6790220493914405],
					[60.560172342013367, 2.6789498575885173],
					[60.560100149315403, 2.6788692666497105],
					[60.560100148423054, 2.6788692668245675],
					[60.560100147358547, 2.6788692633743043],
					[60.560100145573834, 2.6788692637240228],
					[60.560064047303563, 2.6788304643202494],
					[60.56002795738393, 2.6787915755505085],
					[60.559955767176277, 2.6786859893721018],
					[60.559891868967462, 2.6785859921474131],
					[60.559891867096674, 2.6785859906845766],
					[60.559891865139804, 2.6785859874092095],
					[60.559891864161337, 2.6785859857715333],
					[60.559827973282289, 2.6784942985363518],
					[60.55976687964592, 2.6783693114700728],
					[60.559766877689015, 2.6783693081947213],
					[60.559766872796814, 2.6783693000063513],
					[60.55976687083993, 2.6783692967310131],
					[60.559711276700824, 2.6782777058192817],
					[60.559650184792737, 2.6781443222511001],
					[60.559611287219326, 2.6780416282234474],
					[60.559611286240873, 2.6780416265858005],
					[60.559611284197892, 2.6780416214979468],
					[60.559611282240994, 2.6780416182226419],
					[60.559550182634155, 2.6779055184882909],
					[60.559494585743352, 2.677780526442382],
					[60.559450189225664, 2.6776555331349079],
					[60.559394588767638, 2.6774943351721729],
					[60.559377990398076, 2.6774443364550224],
					[60.559377990398076, 2.6774443364550224],
					[60.559377989419623, 2.6774443348173986],
					[60.559377989419623, 2.6774443348173986],
					[60.559358488884094, 2.6773888331979809],
					[60.559305791200252, 2.6772360403243156],
					[60.559269591860307, 2.6771110461274263],
					[60.55923349288539, 2.6769805461889598],
					[60.559233491820819, 2.6769805427388533],
					[60.559233489691678, 2.6769805358386223],
					[60.559233487734772, 2.6769805325634199],
					[60.559177991387124, 2.676824938009601],
					[60.55916129229621, 2.6767666451277052],
					[60.559161291231653, 2.6767666416776095],
					[60.559161289102498, 2.6767666347774086],
					[60.559161288037927, 2.6767666313273155],
					[60.559141890728164, 2.6767138396508487],
					[60.55909739340062, 2.6765471479331779],
					[60.559097393314509, 2.6765471461206811],
					[60.559097392336042, 2.6765471444830986],
					[60.559097392249939, 2.6765471426706013],
					[60.559052991628953, 2.676397143793618],
					[60.559052991456717, 2.6763971401686302],
					[60.559052989413672, 2.6763971350809701],
					[60.559052988349073, 2.6763971316309072],
					[60.559016889855705, 2.6762971380153711],
					[60.558980792255532, 2.6761721442068045],
					[60.55898079012637, 2.6761721373066818],
					[60.558980785061792, 2.676172125493868],
					[60.558980782040265, 2.6761721187686751],
					[60.558944587949561, 2.6760916308174907],
					[60.558925192065885, 2.6760249436737893],
					[60.558925190022805, 2.6760249385861923],
					[60.55892518682905, 2.6760249282360498],
					[60.558925184785984, 2.6760249231484345],
					[60.55886128639348, 2.6758721256777473],
					[60.558816887022644, 2.6757555290255022],
					[60.558816886044148, 2.6757555273879619],
					[60.558816884001089, 2.675755522300383],
					[60.558816883914957, 2.6757555204879044],
					[60.558764084074078, 2.6756332206197699],
					[60.558764083095603, 2.6756332189822358],
					[60.558764081138655, 2.6756332157071454],
					[60.558764081052523, 2.675633213894673],
					[60.558708480750681, 2.6755166149083731],
					[60.558655781427625, 2.6754055137156381],
					[60.558655779470669, 2.6754055104405916],
					[60.558655773685942, 2.675405502427902],
					[60.558655771642869, 2.6754054973403694],
					[60.558591873071599, 2.675297098873751],
					[60.558527973996988, 2.6751860007950605],
					[60.558475174680844, 2.6750916021364595],
					[60.558475173702369, 2.6750916004989542],
					[60.558475171745407, 2.675091597223942],
					[60.558475170853058, 2.6750915973988945],
					[60.558402970793431, 2.6749720973056279],
					[60.55840296972881, 2.6749720938556778],
					[60.558402966793373, 2.6749720889431639],
					[60.55840296492255, 2.6749720874806218],
					[60.55836686460291, 2.6749192873520875],
					[60.558366860775116, 2.6749192826145416],
					[60.558366852141056, 2.6749192715019769],
					[60.558366848227138, 2.67491926495198],
					[60.558311262000949, 2.6748581806947289],
					[60.558258472410756, 2.6747692985258542],
					[60.558222371958315, 2.6747081973492799],
					[60.558222266151567, 2.6747081504093004],
					[60.558222079823985, 2.6747081906009504],
					[60.558222001001724, 2.6747082755702123],
					[60.558108101013374, 2.675636076793344],
					[60.558108101099499, 2.6756360786058004],
					[60.558108101357888, 2.6756360840431133],
					[60.558108099659322, 2.6756360862054227],
					[60.558063699642361, 2.6763082874538568],
					[60.55806370130977, 2.6763083035909192],
					[60.558063703752261, 2.6763083360399409],
					[60.558063707204383, 2.676308351827168],
					[60.558099803875244, 2.6764417427124294],
					[60.558119201721922, 2.6765583283361658],
					[60.558135901875048, 2.676691725676775],
					[60.558135903025736, 2.6766917309392193],
					[60.558135905413224, 2.6766917432765309],
					[60.55813590575768, 2.6766917505263192],
					[60.558155399723859, 2.6767667259976191],
					[60.558155400068443, 2.6768222002505504],
					[60.558155400326783, 2.6768222056878859],
					[60.558155400843454, 2.6768222165625888],
					[60.558155401101779, 2.6768222219999274],
					[60.558172000170451, 2.676975013804713],
					[60.558174799987761, 2.6770750066886317],
					[60.558174800159954, 2.6770750103135299],
					[60.558174800590514, 2.6770750193757786],
					[60.558174800762735, 2.6770750230006879],
					[60.558191499792471, 2.6772167115011856],
					[60.558191500142463, 2.6773305998736134],
					[60.558191500314656, 2.6773306034985307],
					[60.558191499852839, 2.6773306127356795],
					[60.558191500917395, 2.6773306161856891],
					[60.558202601300373, 2.677458315623598],
					[60.558210900068786, 2.6775833134734643],
					[60.558210900068786, 2.6775833134734643],
					[60.558210900961136, 2.6775833132985829],
					[60.558219200805127, 2.6777000131532267],
					[60.558219201063437, 2.6777000185905999],
					[60.558219202214076, 2.677700023853077],
					[60.558219202386283, 2.6777000274779952],
					[60.558238701411838, 2.6778389212737133],
					[60.558247000275465, 2.6779417075772738],
					[60.558246999614681, 2.6780943999909992],
					[60.558247000045164, 2.6780944090532901],
					[60.558247001798449, 2.678094427003006],
					[60.558247003121281, 2.6780944358904248],
					[60.558258102238668, 2.6781556288541166],
					[60.55826649955582, 2.6782361092486116],
					[60.558266500016856, 2.6783499995769411],
					[60.558266500275131, 2.6783500050143099],
					[60.558266500791682, 2.6783500158890678],
					[60.55826650104995, 2.6783500213264544],
					[60.558274800629228, 2.6784278208375181],
					[60.558274800715317, 2.6784278226499931],
					[60.558274801693749, 2.6784278242875947],
					[60.558274801779852, 2.6784278261000498],
					[60.558294200770234, 2.6785750214552237],
					[60.558302599606556, 2.6786806074587588],
					[60.558302599908629, 2.6788194005773835],
					[60.558302600252972, 2.678819407827242],
					[60.558302601920076, 2.6788194239645722],
					[60.558302603242858, 2.6788194328520429],
					[60.558321999673602, 2.6789389164651669],
					[60.558322000150611, 2.6790583003888897],
					[60.55832199960259, 2.6790583078135963],
					[60.558322001097508, 2.6790583203260105],
					[60.558322002334187, 2.6790583274010351],
					[60.558341501134748, 2.6791944216048198],
					[60.558349799745258, 2.6793000128605682],
					[60.558358099680639, 2.6794500109578103],
					[60.558358100745146, 2.6794500144079088],
					[60.558358101175514, 2.679450023470257],
					[60.558358102240021, 2.6794500269203749],
					[60.558369200698536, 2.679530620686668],
					[60.558377600246523, 2.6796528136343003],
					[60.55837760122494, 2.6796528152719441],
					[60.558377601483159, 2.6796528207093644],
					[60.558377600676891, 2.6796528226966547],
					[60.558385900171324, 2.6797278110383282],
					[60.558385899977566, 2.6797833002630749],
					[60.558385900149695, 2.6797833038880152],
					[60.558385899601646, 2.6797833113127258],
					[60.558385900666124, 2.6797833147628438],
					[60.558397000804497, 2.6799361140116713],
					[60.558397000890565, 2.6799361158241606],
					[60.55839700097664, 2.6799361176366276],
					[60.558405401131822, 2.6800333145992568],
					[60.558413699636745, 2.680175011791984],
					[60.558413699722813, 2.6801750136044564],
					[60.558413700873359, 2.6801750188670579],
					[60.558413700959441, 2.6801750206795432],
					[60.558424800918374, 2.6802861168162644],
					[60.558433099805228, 2.6804139056111742],
					[60.558433099981599, 2.6805360996872505],
					[60.558433100153728, 2.6805361033121962],
					[60.558433099605622, 2.6805361107369099],
					[60.558433100670086, 2.680536114187074],
					[60.558441501432362, 2.6806528137824781],
					[60.558441500626067, 2.680652815769752],
					[60.558441500626067, 2.680652815769752],
					[60.558441500712135, 2.6806528175822248],
					[60.558452601026971, 2.680783315792],
					[60.558460900174481, 2.6808889075456017],
					[60.558460899582094, 2.6810249992840407],
					[60.558460899840256, 2.6810250047214885],
					[60.558460900270532, 2.6810250137838914],
					[60.558460901334989, 2.6810250172340568],
					[60.558469199640456, 2.6811222107339701],
					[60.558472000010177, 2.681263902261068],
					[60.558472000220796, 2.6813332999103734],
					[60.558472000149941, 2.681402787247432],
					[60.558452601833103, 2.6815471728174098],
					[60.558452601112862, 2.6815471766171566],
					[60.558452601370995, 2.6815471820545924],
					[60.558452600650725, 2.6815471858543427],
					[60.55844420062332, 2.6816638851792938],
					[60.558444199730964, 2.6816638853540646],
					[60.558444199817018, 2.6816638871665401],
					[60.558435901621635, 2.6817943633028918],
					[60.558391518825182, 2.6819388065582359],
					[60.558327641073951, 2.6820220786130093],
					[60.558327639461318, 2.6820220825874901],
					[60.558327634279266, 2.6820220872609948],
					[60.558327631774262, 2.6820220914102419],
					[60.558266535676715, 2.6821165864006584],
					[60.558185939774788, 2.6822248804977891],
					[60.55818593646346, 2.6822248866342036],
					[60.558185927970079, 2.6822248974440797],
					[60.558185923766416, 2.6822249037552606],
					[60.558169252810586, 2.682255452747611],
					[60.558133205060905, 2.6822748233430049],
					[60.558133194610747, 2.6822748308774114],
					[60.558133177193781, 2.6822748434347754],
					[60.558133166657584, 2.6822748491567356],
					[60.558060974550003, 2.6823387438572763],
					[60.557980482739758, 2.6823970377977786],
					[60.5579804801487, 2.6823970401344788],
					[60.557980475772965, 2.6823970428206638],
					[60.557980474074284, 2.6823970449826136],
					[60.557935974295297, 2.6824331438731313],
					[60.557880474641479, 2.6824776437513669],
					[60.557880472050442, 2.6824776460880475],
					[60.557880466062045, 2.6824776527486249],
					[60.557880461686302, 2.6824776554347927],
					[60.557827667269592, 2.6825276511135421],
					[60.557774870943682, 2.6825720475252659],
					[60.557774869244994, 2.682572049687189],
					[60.557774863976888, 2.6825720525480965],
					[60.557774862278194, 2.6825720547100236],
					[60.557710968131737, 2.682633148680265],
					[60.557630476354284, 2.6826970424460579],
					[60.557630472870891, 2.6826970449574383],
					[60.557630468581152, 2.6826970494560118],
					[60.557630465990115, 2.6826970517926578],
					[60.557602666920886, 2.6827220502863334],
					[60.557577666652492, 2.6827442506777759],
					[60.557577666652492, 2.6827442506777759],
					[60.557577665760142, 2.6827442508525094],
					[60.557497065884924, 2.6828165506245423],
					[60.557497058197839, 2.6828165594468434],
					[60.557497041124968, 2.6828165792533811],
					[60.557497034416237, 2.6828165897134051],
					[60.557424834208355, 2.6829248890160975],
					[60.557424831617304, 2.6829248913527088],
					[60.557424828305969, 2.6829248974889164],
					[60.55742482580095, 2.6829249016379499],
					[60.557369226332156, 2.6830221005834365],
					[60.557369224719494, 2.6830221045577334],
					[60.557369221408159, 2.6830221106939018],
					[60.557369219709464, 2.6830221128557925],
					[60.557299820184255, 2.6831666128137517],
					[60.557299815346255, 2.6831666247366117],
					[60.557299806648629, 2.683166650220048],
					[60.557299803595349, 2.6831666617934644],
					[60.557272003766975, 2.6833110594797751],
					[60.557255403708098, 2.6833888575989113],
					[60.557255403880134, 2.6833888612237646],
					[60.557255401633164, 2.6833888708100146],
					[60.557255401805222, 2.6833888744348484],
					[60.557247001601631, 2.6834527707392195],
					[60.557219203367055, 2.6836221626019694],
					[60.557205606714646, 2.6836851188105584],
					[60.557191895726064, 2.6836166614358481],
					[60.557191895640038, 2.6836166596234188],
					[60.55719189538199, 2.6836166541861712],
					[60.557191894403616, 2.6836166525484639],
					[60.557155797252669, 2.6834666633545825],
					[60.557147398341691, 2.6833999740283425],
					[60.557147397191272, 2.6833999687658197],
					[60.557147394804389, 2.6833999564283486],
					[60.557147393567959, 2.6833999493533978],
					[60.557130794349206, 2.6833360527856218],
					[60.557091897245108, 2.6831666625143047],
					[60.557066898009069, 2.6829999696610121],
					[60.557066895880247, 2.682999962760817],
					[60.557066893321284, 2.6829999467985974],
					[60.557066891106423, 2.6829999380860006],
					[60.557047396343208, 2.6829388551310349],
					[60.557039098049081, 2.6828749744517109],
					[60.557039097877023, 2.6828749708268869],
					[60.557039095576137, 2.6828749603019011],
					[60.557039095404079, 2.6828749566770944],
					[60.557002994606137, 2.682716655676801],
					[60.557002994606137, 2.682716655676801],
					[60.557002995412475, 2.6827166536896643],
					[60.556966895241644, 2.6825638534430456],
					[60.55696689434928, 2.6825638536177827],
					[60.556966894263248, 2.6825638518053827],
					[60.556966894263248, 2.6825638518053827],
					[60.556947393769605, 2.6824860510762418],
					[60.556947392705176, 2.6824860476261669],
					[60.556947389597923, 2.6824860390883742],
					[60.556947389425865, 2.6824860354635796],
					[60.556919591100929, 2.6824055390044177],
					[60.556875192178232, 2.6822527438175849],
					[60.556875192178232, 2.6822527438175849],
					[60.556875191113811, 2.6822527403675394],
					[60.556822391225211, 2.6820832415909552],
					[60.556822391139171, 2.6820832397785663],
					[60.556822391139171, 2.6820832397785663],
					[60.556802991452749, 2.6820221391579788],
					[60.55680298940991, 2.6820221340702952],
					[60.556802985238193, 2.6820221220825644],
					[60.556802982303005, 2.6820221171696326],
					[60.556766888239281, 2.6819416307716004],
					[60.55673079064487, 2.6818221422643531],
					[60.556730790386737, 2.6818221368272028],
					[60.55673078630106, 2.681822126651904],
					[60.556730783279839, 2.6818221199266161],
					[60.556694583396137, 2.6817388193223288],
					[60.55669458241772, 2.6817388176847081],
					[60.556694579396485, 2.6817388109594371],
					[60.556694576547322, 2.6817388078589435],
					[60.556622378738886, 2.6815999115693989],
					[60.556577981020297, 2.681505514795739],
					[60.556577981020297, 2.681505514795739],
					[60.556577980041894, 2.6815055131581271],
					[60.556514080373439, 2.6813721138526674],
					[60.556514078330579, 2.6813721087650904],
					[60.556514073438542, 2.681372100577053],
					[60.556514069610984, 2.6813720958389862],
					[60.556461272724576, 2.6812859993454055],
					[60.556389075611044, 2.6811527059107769],
					[60.55636127740668, 2.6810999061742771],
					[60.556361276428277, 2.6810999045366861],
					[60.556361272600682, 2.681099899798677],
					[60.556361271622279, 2.6810998981610901],
					[60.556316872223611, 2.6810248982585057],
					[60.556316871245208, 2.6810248966209023],
					[60.556316871245208, 2.6810248966209023],
					[60.556261270899533, 2.6809331967879717],
					[60.556261269921116, 2.6809331951503781],
					[60.556225170314093, 2.6808748951061587],
					[60.55622516933569, 2.6808748934685807],
					[60.556225167378862, 2.6808748901934165],
					[60.556225166400473, 2.6808748885558389],
					[60.556144569032483, 2.6807554924194119],
					[60.556091872087954, 2.6806665978713773],
					[60.556091867281943, 2.6806665914958949],
					[60.55609185660547, 2.6806665752950383],
					[60.556091850907102, 2.6806665690943392],
					[60.55601955181146, 2.6805831696739286],
					[60.555947353248712, 2.6804970711502674],
					[60.555947349421118, 2.6804970664124026],
					[60.555947339981223, 2.680497057286213],
					[60.555947336153629, 2.6804970525483465],
					[60.555911239289706, 2.680463756203491],
					[60.555883440992702, 2.6804359591778346],
					[60.555883437165122, 2.680435954439969],
					[60.555883426832857, 2.6804359454886169],
					[60.555883422112913, 2.6804359409255536],
					[60.555811221869575, 2.6803804401973674],
					[60.555811215364898, 2.6803804359838996],
					[60.555811199592448, 2.6803804262689646],
					[60.555811193173859, 2.6803804238678208],
					[60.555747297219206, 2.6803470249493171],
					[60.555686202069062, 2.6803109282659987],
					[60.555686192801268, 2.6803109227645909],
					[60.555686175330187, 2.6803109152116291],
					[60.555686165256112, 2.6803109116973411],
					[60.555613965077903, 2.6802859112154209],
					[60.555613964185554, 2.6802859113902096],
					[60.555613961422424, 2.6802859101022585],
					[60.555613959551636, 2.6802859086395223],
					[60.555569460152938, 2.6802720089799794],
					[60.555569452841979, 2.6802720067536479],
					[60.555569440090842, 2.6802720037637449],
					[60.555569433758322, 2.6802720031749496],
					[60.555488934315491, 2.6802581035536255],
					[60.555488933423128, 2.680258103728415],
					[60.555488930659997, 2.6802581024404728],
					[60.555488929767648, 2.6802581026152592],
					[60.555452829717574, 2.6802526017807682],
					[60.555452825255792, 2.6802526026547095],
					[60.555452812590737, 2.6802526014771124],
					[60.555452808042872, 2.6802526005387537],
					[60.555380608352451, 2.6802498007828475],
					[60.555380605589342, 2.680249799494888],
					[60.555380602019916, 2.6802498001940425],
					[60.55538060023521, 2.6802498005436197],
					[60.555352799743467, 2.6802498004834403],
					[60.555325000144023, 2.680249800247001],
					[60.555324995596216, 2.6802497993086356],
					[60.555324988457386, 2.680249800706934],
					[60.555324983995597, 2.6802498015808749],
					[60.555255584339626, 2.6802554016043119],
					[60.555255583447263, 2.6802554017791018],
					[60.555255580684161, 2.6802554004911623],
					[60.555255579791812, 2.6802554006659483],
					[60.555199979708227, 2.6802609006050542],
					[60.555199974354117, 2.6802609016537682],
					[60.555199961861156, 2.6802609041007779],
					[60.555199955614682, 2.6802609053242779],
					[60.555138863152756, 2.680274803113563],
					[60.555083269899114, 2.6802831020704811],
					[60.555083267222059, 2.6802831025948346],
					[60.55508325919088, 2.6802831041678972],
					[60.555083255621469, 2.6802831048670357],
					[60.555022156102638, 2.6802970057611892],
					[60.55502215512422, 2.6802970041236711],
					[60.555022151640877, 2.6802970066351057],
					[60.55502215074852, 2.680297006809889],
					[60.554966654111723, 2.680310905875777],
					[60.554905556286677, 2.6803248044918648],
					[60.554905553609601, 2.6803248050162116],
					[60.554905550932546, 2.6803248055405571],
					[60.554905549147847, 2.6803248058901206],
					[60.554841649166434, 2.6803415067822134],
					[60.554841649166434, 2.6803415067822134],
					[60.554788849101563, 2.6803554061286743],
					[60.554724949734812, 2.6803720061624232],
					[60.554724948842463, 2.6803720063372021],
					[60.554672148858607, 2.6803859073842884],
					[60.554608254133072, 2.6804026051458507],
					[60.554555558828199, 2.6804137034934179],
					[60.554555557935835, 2.6804137036681959],
					[60.55455555623719, 2.6804137058300062],
					[60.554555555258787, 2.6804137041925262],
					[60.554483254904639, 2.6804304056072588],
					[60.55448325401229, 2.680430405782035],
					[60.55448325222757, 2.6804304061315931],
					[60.55448325133522, 2.6804304063063671],
					[60.554438853792313, 2.6804415054372877],
					[60.554374956775952, 2.6804554048562625],
					[60.554374954098904, 2.680455405380584],
					[60.554374946067711, 2.6804554069535587],
					[60.554374943390648, 2.6804554074778824],
					[60.554347160341699, 2.6804637024116458],
					[60.554322177613919, 2.6804665011458568],
					[60.554322170475096, 2.6804665025440486],
					[60.554322155305073, 2.6804665055152093],
					[60.554322148166214, 2.6804665069134086],
					[60.554249954267895, 2.6804859052443346],
					[60.554194360676753, 2.6804970039208094],
					[60.554194358892026, 2.6804970042703582],
					[60.554194355322608, 2.6804970049694488],
					[60.554194352645546, 2.6804970054937654],
					[60.554124954117569, 2.6805137051652577],
					[60.554077753738966, 2.6805248042456471],
					[60.554077752040321, 2.6805248064074187],
					[60.554077749363238, 2.6805248069317344],
					[60.554077746686175, 2.6805248074560466],
					[60.554016646811405, 2.6805415077339991],
					[60.554016645919056, 2.6805415079087687],
					[60.554016644134322, 2.6805415082583108],
					[60.554016643241972, 2.6805415084330817],
					[60.553961047850798, 2.6805581073071814],
					[60.553891652879841, 2.6805748059972387],
					[60.553891645740983, 2.6805748073953883],
					[60.553891633334096, 2.680574811654358],
					[60.553891626195238, 2.6805748130525067],
					[60.553836031952223, 2.6805970121904492],
					[60.553774939693099, 2.6806165091627756],
					[60.553702741068712, 2.6806387098042133],
					[60.553702735714594, 2.6806387108528038],
					[60.553702725092393, 2.6806387147621913],
					[60.553702719824308, 2.6806387176229949],
					[60.553658223711714, 2.6806581156050746],
					[60.553577728948994, 2.6806887138172777],
					[60.553577727164281, 2.6806887141668034],
					[60.553577722702528, 2.680688715040616],
					[60.55357772181015, 2.6806887152153838],
					[60.553558232044921, 2.6806970115398174],
					[60.553530542103715, 2.6807054084923214],
					[60.553530535943288, 2.6807054115278564],
					[60.553530525321086, 2.6807054154372159],
					[60.553530519966948, 2.6807054164857869],
					[60.553461027296898, 2.6807359137900422],
					[60.553405534411567, 2.6807554110673051],
					[60.553405531734512, 2.6807554115915848],
					[60.553405530035874, 2.6807554137532938],
					[60.553405528165086, 2.6807554122906252],
					[60.553333233953154, 2.6807831108506184],
					[60.553280544203012, 2.6807998074418173],
					[60.553216653945036, 2.6808165044369998],
					[60.553163865896956, 2.680827603183459],
					[60.553099982123186, 2.6808359016936878],
					[60.553047195307187, 2.6808386999824143],
					[60.552994409790571, 2.6808386995927549],
					[60.552938931185246, 2.6808331012013835],
					[60.552886150159694, 2.6808220050974234],
					[60.552822258087637, 2.6808026083488139],
					[60.552758375816545, 2.6807831146408927],
					[60.552688992224837, 2.6807470228150332],
					[60.552688990354056, 2.6807470213523912],
					[60.552688985720181, 2.6807470186018412],
					[60.552688983849428, 2.6807470171391974],
					[60.552652903983578, 2.6807304283107682],
					[60.55261682199508, 2.6807026427666032],
					[60.552616813533611, 2.6807026352786423],
					[60.552616797761203, 2.6807026255644262],
					[60.552616789471848, 2.6807026217007808],
					[60.552544506695497, 2.6806665309624833],
					[60.552508421846746, 2.6806387424762499],
					[60.552508419889911, 2.6806387392014623],
					[60.552508416148385, 2.6806387362762027],
					[60.552508414363672, 2.6806387366257156],
					[60.552436213999549, 2.6805887351242039],
					[60.552436212128789, 2.6805887336615806],
					[60.55243620838727, 2.6805887307363303],
					[60.552436205624154, 2.6805887294484618],
					[60.552355714153627, 2.6805387350571128],
					[60.552319521801323, 2.6805109404789156],
					[60.55231951618903, 2.6805109360910691],
					[60.552319503265778, 2.6805109294770206],
					[60.552319495868772, 2.6805109254386768],
					[60.55226400199512, 2.6804804271869984],
					[60.552211206609378, 2.6804470301981596],
					[60.552211203846241, 2.6804470289103142],
					[60.552211198320016, 2.6804470263346216],
					[60.552211194578476, 2.6804470234093944],
					[60.552175094676436, 2.6804276242486273],
					[60.552175092805669, 2.6804276227860213],
					[60.552175090934909, 2.6804276213234184],
					[60.552175089150197, 2.6804276216729375],
					[60.552102888668799, 2.6803915213453862],
					[60.552102887690381, 2.6803915197080275],
					[60.552102884034909, 2.6803915185949325],
					[60.55210288314256, 2.6803915187696923],
					[60.552041782842117, 2.6803637174005779],
					[60.552041781949754, 2.6803637175753368],
					[60.551986181715421, 2.6803387183994398],
					[60.551986176189196, 2.6803387158237797],
					[60.551986165136704, 2.6803387106724452],
					[60.551986158718101, 2.6803387082715511],
					[60.551913959957858, 2.6803165081252636],
					[60.551861160443302, 2.6802998091333188],
					[60.55186115776624, 2.6802998096576021],
					[60.551861154024685, 2.6802998067324388],
					[60.551861152239987, 2.6802998070819593],
					[60.551788955927321, 2.6802804085755958],
					[60.551752858992799, 2.6802692084532],
					[60.551752854444963, 2.6802692075149093],
					[60.551752841693826, 2.6802692045252541],
					[60.55175283714599, 2.6802692035869642],
					[60.551708343385314, 2.680260904438251],
					[60.551652848846409, 2.6802470068096014],
					[60.551652847867999, 2.6802470051722693],
					[60.551619450149602, 2.6802387057811532],
					[60.551547259085908, 2.6802192095604842],
					[60.551500066375617, 2.6802026107824291],
					[60.551500064590897, 2.6802026111319552],
					[60.551500061827795, 2.680202609844152],
					[60.551500060935446, 2.6802026100189109],
					[60.551430667747333, 2.6801804115002206],
					[60.551375074132572, 2.6801581130856929],
					[60.551375072347874, 2.6801581134352142],
					[60.551375072347874, 2.6801581134352142],
					[60.551302878111649, 2.6801304153733985],
					[60.551230695354313, 2.680097023556177],
					[60.551194504991294, 2.6800748301329445],
					[60.551194502228157, 2.6800748288451666],
					[60.551194494831158, 2.680074824807066],
					[60.551194492068028, 2.6800748235192873],
					[60.551125102628475, 2.6800387285664464],
					[60.551089014346708, 2.6800137353236768],
					[60.551052915900897, 2.6799887368086934],
					[60.550980718324034, 2.6799359381767851],
					[60.550980714582494, 2.6799359352517373],
					[60.550980709056255, 2.6799359326761998],
					[60.5509807053147, 2.6799359297511516],
					[60.550900114372496, 2.6798859346973352],
					[60.550852922414933, 2.6798498403918525],
					[60.550852920630234, 2.6798498407413827],
					[60.55085291875946, 2.6798498392788699],
					[60.55085291875946, 2.6798498392788699],
					[60.550800119319447, 2.6798109385890219],
					[60.550800116556317, 2.6798109373012791],
					[60.550800113793208, 2.6798109360135363],
					[60.550800112814777, 2.6798109343762415],
					[60.550747320632468, 2.6797748415762084],
					[60.550711229256351, 2.6797442475804378],
					[60.550711227299523, 2.679744244305879],
					[60.550711220880906, 2.6797442419051793],
					[60.550711218031715, 2.6797442388053927],
					[60.550639017795703, 2.6796915386732829],
					[60.550639014946526, 2.6796915355735167],
					[60.550639009334212, 2.6796915311860121],
					[60.550639004786369, 2.6796915302478248],
					[60.550558410026312, 2.6796415333604808],
					[60.550522314198929, 2.6796165351153101],
					[60.550522309565018, 2.6796165323651082],
					[60.550522301189588, 2.6796165266899128],
					[60.550522296641738, 2.6796165257517419],
					[60.550466801269287, 2.6795859265702386],
					[60.550422306269809, 2.6795581292831807],
					[60.550422301721994, 2.6795581283450116],
					[60.550422294238892, 2.6795581224950809],
					[60.550422288798693, 2.6795581217316875],
					[60.550377889370225, 2.6795359204590801],
					[60.550305688527189, 2.6794998202753111],
					[60.55030568674249, 2.6794998206248533],
					[60.550305685850113, 2.6794998207996295],
					[60.55024178636134, 2.6794692201806987],
					[60.550241783598231, 2.6794692188929874],
					[60.550241778071978, 2.679469216317599],
					[60.55024177620119, 2.6794692148551347],
					[60.550180676262208, 2.6794442146951583],
					[60.550180675283762, 2.6794442130579257],
					[60.550180672606714, 2.6794442135822414],
					[60.550180671714358, 2.6794442137570171],
					[60.550108376301303, 2.6794165141772477],
					[60.550063980115297, 2.6793970175380433],
					[60.550063974589044, 2.6793970149626825],
					[60.55006396540729, 2.6793970112743968],
					[60.550063958988694, 2.6793970088738037],
					[60.549983368979362, 2.6793720112345505],
					[60.549963979265883, 2.6793637164427282],
					[60.549963974718047, 2.6793637155045995],
					[60.549963967321005, 2.6793637114667859],
					[60.549963963665519, 2.6793637103538859],
					[60.549947264266024, 2.6793581092685459],
					[60.549947260696598, 2.6793581099676422],
					[60.549947255170345, 2.6793581073922939],
					[60.549947252407222, 2.6793581061046017],
					[60.549863951962628, 2.6793359072672742],
					[60.549863949199512, 2.679335905979602],
					[60.549863943759313, 2.6793359052162553],
					[60.549863940996175, 2.6793359039285884],
					[60.549811146072955, 2.6793248045857423],
					[60.549747251234692, 2.6793081066803683],
					[60.549747247579191, 2.6793081055674834],
					[60.549747243923711, 2.6793081044545906],
					[60.549747241160588, 2.679308103166913],
					[60.549694440795754, 2.6792970031346264],
					[60.549694439011041, 2.6792970034841757],
					[60.549694436247904, 2.6792970021965159],
					[60.549694433570856, 2.6792970027208347],
					[60.549630634400792, 2.6792859025393052],
					[60.549630632616079, 2.6792859028888523],
					[60.54963063172373, 2.6792859030636267],
					[60.549630630745298, 2.6792859014264225],
					[60.549577830978848, 2.6792776012872346],
					[60.549577830086484, 2.6792776014620117],
					[60.549577827409436, 2.6792776019863269],
					[60.549577825624709, 2.6792776023358762],
					[60.549513925890821, 2.6792692011519468],
					[60.549452826661209, 2.6792609025601095],
					[60.549452824876511, 2.6792609029096526],
					[60.549452821221031, 2.6792609017967748],
					[60.549452820242607, 2.6792609001595662],
					[60.54939722012238, 2.6792554016665133],
					[60.54939721735925, 2.6792554003788682],
					[60.549397212811414, 2.6792553994407675],
					[60.549397211026715, 2.6792553997903097],
					[60.549344415928047, 2.6792526017467413],
					[60.54929172094775, 2.679247000636614],
					[60.549291715593625, 2.6792470016852468],
					[60.549291704713241, 2.6792470001585968],
					[60.549291700165419, 2.6792469992204966],
					[60.549227799638579, 2.679246999867043],
					[60.549174999698984, 2.6792470008649176],
					[60.549174996935847, 2.679246999577281],
					[60.549174990689387, 2.6792470008006766],
					[60.549174987926236, 2.6792469995130452],
					[60.549127794232753, 2.6792497996923523],
					[60.549066700098066, 2.6792497993134989],
					[60.549066697420997, 2.6792497998378124],
					[60.549066692066887, 2.6792498008864341],
					[60.549066690196113, 2.6792497994240279],
					[60.549011089816403, 2.6792526001312633],
					[60.549011088031705, 2.679252600480801],
					[60.549011083569958, 2.6792526013546469],
					[60.549011081785231, 2.6792526017041918],
					[60.548949982329546, 2.6792581015471044],
					[60.548949979566416, 2.6792581002594713],
					[60.548894385224813, 2.6792637014623963],
					[60.548841689363762, 2.6792665001849314],
					[60.548841685794336, 2.6792665008840033],
					[60.548841677677089, 2.6792665006449878],
					[60.548841674107685, 2.6792665013440593],
					[60.548777774295694, 2.6792748026308599],
					[60.548777773403344, 2.6792748028056295],
					[60.548716672758147, 2.6792831026429678],
					[60.548716671865797, 2.6792831028177346],
					[60.548716670887387, 2.6792831011805727],
					[60.548716669995009, 2.6792831013553426],
					[60.548661069915752, 2.6792915016105123],
					[60.548661069023403, 2.6792915017852779],
					[60.548608268817418, 2.6792998017064145],
					[60.548608268011137, 2.6792998036930995],
					[60.548608267118773, 2.6792998038678677],
					[60.548608266140356, 2.6792998022307111],
					[60.548544366920915, 2.6793109031021913],
					[60.54849166930137, 2.679319201145971],
					[60.548491667602711, 2.6793192033074331],
					[60.548491665818013, 2.6793192036569624],
					[60.548491664925663, 2.6793192038317253],
					[60.548427764996617, 2.6793304035024894],
					[60.548427764104268, 2.6793304036772514],
					[60.548427761427206, 2.6793304042015436],
					[60.548427758750137, 2.6793304047258357],
					[60.54837496092037, 2.6793415038748125],
					[60.548302761848326, 2.6793554040961824],
					[60.548302760063613, 2.6793554044457086],
					[60.548302757386558, 2.6793554049699937],
					[60.548302754709496, 2.679355405494281],
					[60.548266655291357, 2.6793637042652119],
					[60.548194356006299, 2.6793804055384234],
					[60.548169359256192, 2.679385904444568],
					[60.548141659951881, 2.6793915046852148],
					[60.548141657274819, 2.6793915052094928],
					[60.548141651028352, 2.6793915064328129],
					[60.54814164835129, 2.6793915069570917],
					[60.548069350189294, 2.6794109052697297],
					[60.54802495100347, 2.6794220052099935],
					[60.548024949304825, 2.6794220073714143],
					[60.548024943950715, 2.6794220084199636],
					[60.548024941273638, 2.6794220089442389],
					[60.547952744674838, 2.6794442089603501],
					[60.547899949207014, 2.6794581070465622],
					[60.547899947422316, 2.6794581073960733],
					[60.547899942960548, 2.6794581082698552],
					[60.547899942068199, 2.6794581084446101],
					[60.547836041926537, 2.6794776082314167],
					[60.547836041034174, 2.6794776084061711],
					[60.547836040141824, 2.679477608580926],
					[60.547836039249475, 2.6794776087556818],
					[60.547774940849337, 2.6794970096450106],
					[60.547711041591562, 2.679516509089785],
					[60.547711039806849, 2.679516509439293],
					[60.547711036237423, 2.6795165101383076],
					[60.547711033560383, 2.679516510662566],
					[60.54764713381163, 2.6795387111440427],
					[60.54764713291928, 2.6795387113187932],
					[60.547647131134553, 2.6795387116683007],
					[60.547647130328286, 2.6795387136549196],
					[60.547602729630952, 2.6795554130788526],
					[60.547602728738596, 2.6795554132536039],
					[60.547522135035727, 2.6795859117322314],
					[60.54748604064644, 2.6795970087659691],
					[60.547486036270719, 2.6795970114515764],
					[60.547486024756211, 2.6795970155351756],
					[60.547486020294443, 2.6795970164089247],
					[60.547441621068813, 2.6796165151252671],
					[60.547388821887317, 2.6796387157236041],
					[60.547388820994968, 2.679638715898351],
					[60.547388818403945, 2.6796387182344508],
					[60.547388815726897, 2.6796387187586923],
					[60.547352716348229, 2.6796554179860177],
					[60.547352712864885, 2.6796554204968532],
					[60.547352708403096, 2.6796554213705921],
					[60.547352704919753, 2.6796554238814294],
					[60.547280508656968, 2.6796942218882127],
					[60.547216614197772, 2.6797248198458909],
					[60.547216609822073, 2.6797248225314552],
					[60.547216601876968, 2.6797248259159923],
					[60.547216598393597, 2.6797248284268145],
					[60.547155497920549, 2.6797609284566897],
					[60.547155497028186, 2.6797609286314348],
					[60.547155496135829, 2.6797609288061799],
					[60.547155495329541, 2.6797609307927583],
					[60.547083207438746, 2.6798054224968597],
					[60.547038820394924, 2.679824817680335],
					[60.547038810665086, 2.6798248214143099],
					[60.547038794860924, 2.6798248299951446],
					[60.547038787001846, 2.6798248351914751],
					[60.546994287294552, 2.6798554347993657],
					[60.54699428461749, 2.6798554353235793],
					[60.546994282112536, 2.6798554394714813],
					[60.546994280327837, 2.6798554398209591],
					[60.546938783591735, 2.6798970362534487],
					[60.54688598707579, 2.6799331352677163],
					[60.546885985377124, 2.6799331374290176],
					[60.546885980109067, 2.6799331402892719],
					[60.546885978410423, 2.6799331424505679],
					[60.546813779458972, 2.6799887416027999],
					[60.54672487993934, 2.6800554391699327],
					[60.546724873864967, 2.6800554440166877],
					[60.546724864393298, 2.6800554531860219],
					[60.546724859297349, 2.6800554596698607],
					[60.546705459264743, 2.6800748587622518],
					[60.54668876505999, 2.6800915518912438],
					[60.546608178781774, 2.6801581407308124],
					[60.54653598562161, 2.6802081365280035],
					[60.546535984729246, 2.6802081367027286],
					[60.546535983030601, 2.6802081388639878],
					[60.546535982052198, 2.6802081372269062],
					[60.54647488420995, 2.6802526376424436],
					[60.546402691899836, 2.6803026328313408],
					[60.546374904020965, 2.6803192247829579],
					[60.546358213686929, 2.6803276184280902],
					[60.546277729821014, 2.6803637115016312],
					[60.546213841892921, 2.6803831089839316],
					[60.546149955038068, 2.6804026049173881],
					[60.546097171844856, 2.6804109024326559],
					[60.546033287162587, 2.6804191992619781],
					[60.545980599847198, 2.6804191997354887],
					[60.545916710153215, 2.6804191991863009],
					[60.545863930172558, 2.6804137026679462],
					[60.545808338827648, 2.680402603300871],
					[60.545738944380389, 2.6803887041891237],
					[60.545683349785946, 2.6803748059265402],
					[60.545611161627555, 2.6803554103473628],
					[60.545575071640947, 2.6803415133070492],
					[60.545575066114722, 2.6803415107318314],
					[60.545575053363585, 2.6803415077425692],
					[60.545575046944982, 2.6803415053420689],
					[60.545505651921751, 2.6803248069543644],
					[60.545477857192054, 2.6803165088151824],
					[60.545477852644218, 2.6803165078769946],
					[60.545477844440931, 2.6803165058259357],
					[60.545477838914685, 2.6803165032507343],
					[60.545450041808742, 2.6803109046008271],
					[60.545377846634764, 2.6802942059933161],
					[60.545333347675914, 2.6802831056932366],
					[60.545333344020442, 2.6802831045803606],
					[60.545333334924777, 2.6802831027040335],
					[60.545333330376941, 2.680283101765871],
					[60.545261130014204, 2.6802720015875443],
					[60.54526112912184, 2.680272001762261],
					[60.545261126444785, 2.6802720022864071],
					[60.545261124660072, 2.6802720026358378],
					[60.545216725059461, 2.6802665021854986],
					[60.545216722296345, 2.6802665008978908],
					[60.545216717834563, 2.6802665017714666],
					[60.54521671507144, 2.6802665004838797],
					[60.545144414778534, 2.6802609001897304],
					[60.545144412101479, 2.6802609007138729],
					[60.545144403984203, 2.6802609004745626],
					[60.545144400328752, 2.6802608993616865],
					[60.545063899926213, 2.6802609000215289],
					[60.545063897249165, 2.6802609005456701],
					[60.545063890916616, 2.6802608999569446],
					[60.54506388734719, 2.6802609006557994],
					[60.54501938660465, 2.6802636998234548],
					[60.545019385712301, 2.6802636999981657],
					[60.545019383927588, 2.6802637003475933],
					[60.545019383035239, 2.6802637005223078],
					[60.544955582613646, 2.6802692013104039],
					[60.544955578958174, 2.6802692001975368],
					[60.54495557181933, 2.6802692015952427],
					[60.544955569142267, 2.6802692021193804],
					[60.544902769182592, 2.6802776014507419],
					[60.544902764720803, 2.6802776023243045],
					[60.544902759366686, 2.6802776033725779],
					[60.544902754904903, 2.6802776042461418],
					[60.54483055890227, 2.6802942042206923],
					[60.544786062956327, 2.6803026032445114],
					[60.544786056709832, 2.6803026044674887],
					[60.544786046893925, 2.6803026063893074],
					[60.544786040733499, 2.6803026094239946],
					[60.544713841406015, 2.6803248089570699],
					[60.544713834353239, 2.6803248121664676],
					[60.544713818376877, 2.6803248171229535],
					[60.544713811324101, 2.6803248203323342],
					[60.544697126150581, 2.6803331137361028],
					[60.544669341629813, 2.6803415084547577],
					[60.544669340737457, 2.6803415086294664],
					[60.544588840716592, 2.6803665088679183],
					[60.544588835448494, 2.6803665117278634],
					[60.544588824826299, 2.6803665156360541],
					[60.54458882036451, 2.6803665165095927],
					[60.544544323556522, 2.6803859142275535],
					[60.544472128109867, 2.680413713438615],
					[60.544472127217517, 2.6804137136133179],
					[60.544408227180966, 2.6804387147008621],
					[60.544408223611519, 2.6804387153996831],
					[60.5444082174511, 2.6804387184343006],
					[60.544408213881653, 2.6804387191331225],
					[60.544355522292747, 2.6804637163344167],
					[60.544274929350692, 2.6804942124442137],
					[60.544274922297888, 2.6804942156535136],
					[60.544274909084663, 2.6804942218974221],
					[60.544274902031859, 2.6804942251067208],
					[60.544230511683992, 2.6805192206568078],
					[60.544149941698116, 2.6805526083764923],
					[60.544122160869151, 2.6805581038011344],
					[60.544122142215727, 2.6805581092814932],
					[60.544122105973344, 2.680558123690866],
					[60.544122089362787, 2.6805581342568594],
					[60.54410550545353, 2.6805692234651333],
					[60.544033228196369, 2.6805998135264764],
					[60.543977734398602, 2.6806192112539331],
					[60.543977730829162, 2.6806192119527177],
					[60.543977724668736, 2.680619214987257],
					[60.543977721991673, 2.680619215511344],
					[60.543905521773894, 2.6806498158372056],
					[60.543852721626941, 2.6806720157696833],
					[60.543852720734591, 2.68067201594438],
					[60.543852719842249, 2.6806720161190727],
					[60.543788820388038, 2.6806998172843839],
					[60.543788818603332, 2.6806998176337711],
					[60.543719319404339, 2.6807304172440505],
					[60.543719316727277, 2.6807304177681264],
					[60.543719313243898, 2.6807304202785454],
					[60.543719310566821, 2.6807304208026204],
					[60.543674910849653, 2.6807526216054081],
					[60.543602719385483, 2.6807887160340522],
					[60.543566628255896, 2.6808026125230207],
					[60.543566621203105, 2.6808026157321687],
					[60.543566608075892, 2.6808026237874434],
					[60.543566600937048, 2.6808026251849495],
					[60.543547103899655, 2.6808137248130297],
					[60.54345820579136, 2.6808609226371156],
					[60.543458201415646, 2.6808609253221829],
					[60.543458191857859, 2.6808609326786548],
					[60.543458186589753, 2.6808609355384116],
					[60.543405488997394, 2.6808970330906758],
					[60.543333190947116, 2.6809442337729608],
					[60.543333187377705, 2.6809442344716898],
					[60.54333317871226, 2.6809442416534126],
					[60.543333176035198, 2.6809442421774605],
					[60.54325267628176, 2.6810081421793619],
					[60.543252673690738, 2.68100814451503],
					[60.543252671099715, 2.6810081468506954],
					[60.543252670293384, 2.6810081488370052],
					[60.543233179440058, 2.6810248403017232],
					[60.543208189261428, 2.6810415331009043],
					[60.543208184079361, 2.6810415377722303],
					[60.54320817273684, 2.6810415454779504],
					[60.543208168447151, 2.6810415499745952],
					[60.543116568478752, 2.6811220507163367],
					[60.543116562404322, 2.6811220555623132],
					[60.543116552126257, 2.6811220667165423],
					[60.543116546051856, 2.6811220715625286],
					[60.54304705815948, 2.6812054586178427],
					[60.543010971431976, 2.6812359467535551],
					[60.543010968840953, 2.6812359490891877],
					[60.543010966249923, 2.6812359514248163],
					[60.543010963658901, 2.6812359537604453],
					[60.542974863624806, 2.6812692529377862],
					[60.542974857636423, 2.6812692595953376],
					[60.54297484485334, 2.6812692748967044],
					[60.54297483886495, 2.6812692815542589],
					[60.54290264468883, 2.6813665745720487],
					[60.542813751676306, 2.6814665651926051],
					[60.542777652750921, 2.6815054641950726],
					[60.542777647654873, 2.6815054706778754],
					[60.542777638269122, 2.6815054816571995],
					[60.54277763415147, 2.6815054897769226],
					[60.542749834960858, 2.681547086610462],
					[60.542680437936859, 2.6816442824128561],
					[60.542624839348562, 2.6817192804851375],
					[60.542552638995026, 2.6818165816965411],
					[60.542552637296318, 2.6818165838574393],
					[60.54255263389895, 2.6818165881792337],
					[60.542552632286295, 2.681816592151737],
					[60.542483135305382, 2.6819248865577374],
					[60.542447038988669, 2.6819748808072856],
					[60.542402641190364, 2.6820331788777372],
					[60.542402640298015, 2.6820331790523797],
					[60.542402638599334, 2.6820331812132552],
					[60.542338738834886, 2.6821192810562158],
					[60.54233873463118, 2.6821192873641855],
					[60.542338728008467, 2.6821192996308278],
					[60.542338723804761, 2.6821193059387949],
					[60.542302631925295, 2.6821859904714032],
					[60.542222042785134, 2.6822887764415086],
					[60.542222041086447, 2.6822887786023548],
					[60.542222037689044, 2.6822887829240591],
					[60.542222035990349, 2.6822887850849075],
					[60.542149836133106, 2.6823915852728444],
					[60.542149836133106, 2.6823915852728444],
					[60.542105435783576, 2.682455484912893],
					[60.542052636127167, 2.6825304845654627],
					[60.542052636213178, 2.6825304863770283],
					[60.54205263362212, 2.6825304887124815],
					[60.54205263362212, 2.6825304887124815],
					[60.542008136129446, 2.68259708556573],
					[60.541972038730798, 2.682647080298711],
					[60.541891541393312, 2.6827526781769286],
					[60.541891539694589, 2.6827526803377326],
					[60.541891538888258, 2.682752682323911],
					[60.541891537995895, 2.6827526824985339],
					[60.541819238213932, 2.682852682507006],
					[60.541819237407587, 2.682852684493179],
					[60.541819234816529, 2.6828526868285905],
					[60.541819234010177, 2.6828526888147626],
					[60.541766533813849, 2.6829304888264409],
					[60.541766532921486, 2.6829304890010581],
					[60.541766531308809, 2.682930492973389],
					[60.541766529524082, 2.6829304933226257],
					[60.541702635391303, 2.6830331864695118],
					[60.541669239809899, 2.6830776801583069],
					[60.541669228983331, 2.6830776987321889],
					[60.541669211792012, 2.6830777350069273],
					[60.541669204620881, 2.6830777546939104],
					[60.54165810521738, 2.683124954032551],
					[60.541658103056385, 2.6831249654256659],
					[60.541658101411457, 2.6831249876880872],
					[60.541658100228808, 2.6831250007181389],
					[60.541658099947675, 2.6832805993911637],
					[60.541658099990329, 2.6833222004615749],
					[60.541658101398745, 2.6833222111562591],
					[60.541658103323222, 2.6833222327202302],
					[60.541658104731638, 2.6833222434149144],
					[60.541669205013754, 2.6833722424021325],
					[60.541705404121558, 2.6835417426043415],
					[60.541705405013907, 2.6835417424297434],
					[60.541705405099904, 2.6835417442412943],
					[60.541705405185937, 2.6835417460528372],
					[60.541741504161429, 2.6836944395987716],
					[60.541758103150123, 2.6837889335832119],
					[60.541777602796678, 2.6839083313503398],
					[60.541777602968679, 2.6839083349734465],
					[60.541777604119048, 2.6839083402335353],
					[60.54177760518342, 2.683908343682055],
					[60.54179700236304, 2.6839944338365158],
					[60.541805400581119, 2.6840694220114933],
					[60.541805401645483, 2.684069425460021],
					[60.541805402881842, 2.6840694325316772],
					[60.541805403053843, 2.6840694361547897],
					[60.54183309994113, 2.6842167187227042],
					[60.541833099882844, 2.6843389006202991],
					[60.541833099707368, 2.6844555991975705],
					[60.541833100258089, 2.6845666991031614],
					[60.541833099805956, 2.6846916876059783],
					[60.541816502390155, 2.6848332663624785],
					[60.541797003704765, 2.6849249582708068],
					[60.541797003876731, 2.6849249618939255],
					[60.541797002435978, 2.6849249694892983],
					[60.541797001629604, 2.6849249714754202],
					[60.54178040233387, 2.6850443720194725],
					[60.54178040241986, 2.6850443738310297],
					[60.541780400893096, 2.68504437961484],
					[60.541780400979086, 2.6850443814264002],
					[60.541772003285317, 2.68513056481037],
					[60.541752606963179, 2.6852027476619544],
					[60.541752606328764, 2.6852027532711782],
					[60.541752603275228, 2.6852027648387549],
					[60.541752601748456, 2.6852027706225514],
					[60.541727603329015, 2.685372165189289],
					[60.541691505614757, 2.6855471495240568],
					[60.541674809322487, 2.6855999404589737],
					[60.541674807709725, 2.685599944431166],
					[60.541674804570164, 2.6855999541871247],
					[60.541674803935742, 2.6855999597963311],
					[60.541663703729625, 2.6856527586206531],
					[60.54166370381563, 2.6856527604322031],
					[60.54166370381563, 2.6856527604322031],
					[60.54163040414776, 2.6858193585575481],
					[60.541594206168078, 2.6859805517159892],
					[60.541574812322359, 2.686049930216976],
					[60.541558128189152, 2.6860859960477277],
					[60.541513740715715, 2.6861442790247234],
					[60.541513738210547, 2.6861442831714002],
					[60.541513730609125, 2.6861442937998916],
					[60.541513728103965, 2.6861442979465684],
					[60.54146093122641, 2.6862331921547433],
					[60.541416534900016, 2.6862998869169306],
					[60.541335948937672, 2.6864137676661191],
					[60.541244265914848, 2.6864970521695604],
					[60.54124426421609, 2.6864970543301236],
					[60.541244259840241, 2.6864970570142455],
					[60.541244258141461, 2.6864970591748132],
					[60.541227658194423, 2.686513758513088],
					[60.541227652205784, 2.6865137651692823],
					[60.541227641120784, 2.6865137783072055],
					[60.541227636024495, 2.68651378478889],
					[60.541208135870555, 2.6865415843855396],
					[60.541127637152975, 2.6866554850676545],
					[60.541127632949049, 2.6866554913747818],
					[60.541127625433525, 2.6866555038145554],
					[60.54112762122957, 2.6866555101217053],
					[60.541074821284134, 2.6867610097391892],
					[60.54107481967133, 2.6867610137112528],
					[60.54107481725211, 2.6867610196693477],
					[60.541074815553337, 2.6867610218299038],
					[60.541013715929488, 2.6869055225687144],
					[60.541013715929488, 2.6869055225687144],
					[60.541013715037124, 2.6869055227432166],
					[60.540985914653128, 2.6869721223203102],
					[60.540985910793005, 2.6869721358734902],
					[60.540985904051077, 2.686972164616837],
					[60.540985901083339, 2.6869721779955142],
					[60.540977602358119, 2.6870471662814337],
					[60.540941505075615, 2.6872055557993262],
					[60.540941504269199, 2.6872055577853402],
					[60.540941502742335, 2.6872055635689005],
					[60.540941502828282, 2.6872055653804199],
					[60.540913702841273, 2.687366665243601],
					[60.540913703013175, 2.6873666688666535],
					[60.540913702292713, 2.6873666726641785],
					[60.540913702378681, 2.6873666744756943],
					[60.540905402746567, 2.687430562862601],
					[60.540888709858137, 2.687494335328243],
					[60.540824815708028, 2.6876443220706481],
					[60.540824814901619, 2.6876443240566386],
					[60.540824813288772, 2.6876443280286297],
					[60.540824813374734, 2.6876443298401402],
					[60.540780415045404, 2.6877638234601666],
					[60.540727621708633, 2.6878805079775394],
					[60.540663727647832, 2.6879887984386075],
					[60.540663726841402, 2.6879888004245864],
					[60.540663725228562, 2.6879888043965372],
					[60.540663724336191, 2.6879888045710056],
					[60.54060262524181, 2.6881027022290951],
					[60.54054982672745, 2.6881943003175119],
					[60.540549825835065, 2.6881943004919782],
					[60.540549825921026, 2.6881943023034758],
					[60.540477625560833, 2.6883221016610173],
					[60.540477623141541, 2.688322107618887],
					[60.540477617410616, 2.6883221197091003],
					[60.540477614991318, 2.6883221256669887],
					[60.540441515101399, 2.6884110252296773],
					[60.540441513402605, 2.688411027390087],
					[60.540441511069261, 2.6884110351594512],
					[60.540441508564037, 2.6884110393058114],
					[60.540388708879398, 2.688577739362239],
					[60.540388708965338, 2.6885777411737326],
					[60.5403887081589, 2.6885777431596765],
					[60.540388708244841, 2.6885777449711861],
					[60.54033590781043, 2.6887610454924751],
					[60.540324808199692, 2.6887999457026646],
					[60.540324805231762, 2.6887999590809089],
					[60.540324801080615, 2.6887999854884748],
					[60.540324799983352, 2.6888000003293198],
					[60.540324799886292, 2.6888388808050614],
					[60.540291504279558, 2.6890138628101528],
					[60.540263703728606, 2.689152760404089],
					[60.540263703814524, 2.6891527622155791],
					[60.540263703008087, 2.6891527642015189],
					[60.540263703094027, 2.6891527660130037],
					[60.540247004829325, 2.6892499528342881],
					[60.54021921258559, 2.6893388283252295],
					[60.540155417875319, 2.6894777164274113],
					[60.5401554171548, 2.6894777202248021],
					[60.540155413929014, 2.6894777281684505],
					[60.540155412316118, 2.689477732140285],
					[60.540110915308119, 2.6895999236755093],
					[60.540058120709176, 2.6897166118659377],
					[60.539997026214841, 2.6898305011118446],
					[60.539933128753368, 2.6899359956782205],
					[60.539933127946924, 2.689935997664104],
					[60.539933127140472, 2.6899359996500056],
					[60.539933127140472, 2.6899359996500056],
					[60.539880430315002, 2.6900276952147153],
					[60.539799833393673, 2.6901498904148342],
					[60.539799829189541, 2.6901498967212563],
					[60.539799821673689, 2.6901499091597012],
					[60.539799819254313, 2.6901499151173285],
					[60.539763718792827, 2.6902277167976862],
					[60.539763716287545, 2.6902277209438217],
					[60.539763712255251, 2.6902277308731666],
					[60.539763709835881, 2.6902277368307792],
					[60.539710912794142, 2.6903860286871706],
					[60.53965541716984, 2.6905138201208856],
				],
				[
					[60.52529980595402, 2.7455999717513189],
					[60.52529981140048, 2.7456000492723889],
					[60.525299821391194, 2.7456000893669907],
					[60.525569221148508, 2.746144488402817],
					[60.525569223188882, 2.7461444934899033],
					[60.525569228076961, 2.7461445016804329],
					[60.525569231009818, 2.7461445065947405],
					[60.526372031124623, 2.7474140071706028],
					[60.526372035927501, 2.7474140135506095],
					[60.526372045533243, 2.7474140263106301],
					[60.526372051228599, 2.7474140325178849],
					[60.527180446740765, 2.7483168276310681],
					[60.528172043301097, 2.749583422646686],
					[60.528172059409314, 2.7495834396322967],
					[60.528172095025404, 2.749583469290735],
					[60.528172115425789, 2.7495834817908573],
					[60.529699888721801, 2.7502973686442735],
					[60.530508165646147, 2.7510223487417242],
					[60.530508198074052, 2.7510223680547576],
					[60.530508265904203, 2.7510223933120672],
					[60.530508302198911, 2.7510223990836646],
					[60.53211670230391, 2.7510085006865661],
					[60.532116710336211, 2.751008499132491],
					[60.532116727378401, 2.7510084976629141],
					[60.532116734518212, 2.7510084962815178],
					[60.533191735255144, 2.7508168962048885],
					[60.533191756589517, 2.7508168902490757],
					[60.533191798110551, 2.7508168730761984],
					[60.53319181829719, 2.750816861859132],
					[60.53444731791808, 2.7499001623315387],
					[60.534447319617925, 2.7499001601747222],
					[60.534447324802585, 2.7499001555156419],
					[60.534447326587539, 2.7499001551701987],
					[60.535344526716649, 2.7491640544482445],
					[60.535344542185399, 2.7491640386588045],
					[60.535344569382822, 2.7491640041480552],
					[60.535344580304098, 2.7491639874109182],
					[60.535980779795565, 2.7478389874089486],
					[60.535980789780233, 2.747838950745018],
					[60.53598079529975, 2.747838876558891],
					[60.5359807908346, 2.7478388390366835],
					[60.535961293064943, 2.7477777477867922],
					[60.535925197323422, 2.7476249619467636],
					[60.535905798923778, 2.7474943773876408],
					[60.535897400402746, 2.7473749869635102],
					[60.535889099716002, 2.7472332878215706],
					[60.53588909865325, 2.7472332843714686],
					[60.535889099290316, 2.7472332787643245],
					[60.535889098227557, 2.7472332753142403],
					[60.53587799792102, 2.7471443752703451],
					[60.535877997835883, 2.7471443734589007],
					[60.535877997835883, 2.7471443734589007],
					[60.535877997750745, 2.7471443716474555],
					[60.535861297799464, 2.7470277714697726],
					[60.535861297714341, 2.7470277696583261],
					[60.535861295588838, 2.7470277627581581],
					[60.535861296396163, 2.7470277607739044],
					[60.535841895828646, 2.7469332605414647],
					[60.535841895743509, 2.7469332587300044],
					[60.535825195699836, 2.7468527585887124],
					[60.535825195699836, 2.7468527585887124],
					[60.535825194637091, 2.7468527551386437],
					[60.535825194551954, 2.7468527533271856],
					[60.535789096711092, 2.7466999657687108],
					[60.535769598244016, 2.7465416755566432],
					[60.535769597988562, 2.7465416701223337],
					[60.535769595777886, 2.7465416614107805],
					[60.535769594715113, 2.7465416579607158],
					[60.535752995322042, 2.7464666571909189],
					[60.535733496724916, 2.7463749659561163],
					[60.535716898003237, 2.7462555722111412],
					[60.535716897918086, 2.7462555703997071],
					[60.53571689668501, 2.7462555633267938],
					[60.535716895707395, 2.7462555616881912],
					[60.535697398446274, 2.7461582696430971],
					[60.535680798583279, 2.7460110772690034],
					[60.535680798327817, 2.7460110718347139],
					[60.535680796031954, 2.7460110613117923],
					[60.53568079496916, 2.7460110578617543],
					[60.535644596148899, 2.7458471593019911],
					[60.53563349839397, 2.7457888701662112],
					[60.535625198937275, 2.7457166770136689],
					[60.535625197874495, 2.7457166735636624],
					[60.535625196556232, 2.7457166646793612],
					[60.535625196385922, 2.7457166610565089],
					[60.535597396068667, 2.745577760128187],
					[60.535597395983501, 2.7455777583167649],
					[60.53559739500588, 2.7455777566781903],
					[60.53559739500588, 2.7455777566781903],
					[60.53556959665395, 2.7454555664215308],
					[60.53555299903185, 2.7453193766907411],
					[60.535552997969049, 2.7453193732407586],
					[60.535552997798725, 2.7453193696179006],
					[60.535552996821082, 2.7453193679793362],
					[60.535525196803476, 2.7451499687967789],
					[60.535525196633138, 2.7451499651739439],
					[60.535525195655509, 2.7451499635353844],
					[60.5355251964628, 2.7451499615510904],
					[60.535516895722267, 2.7451082623084408],
					[60.535508496883892, 2.7450638639748974],
					[60.535480798099108, 2.7449027735722886],
					[60.535469599335492, 2.7447916833267967],
					[60.535461299925231, 2.7446582884980404],
					[60.535461299840065, 2.7446582866866271],
					[60.535461299840065, 2.7446582866866271],
					[60.535453000341882, 2.7445388937456414],
					[60.535453000033648, 2.7444278005095679],
					[60.535452999632732, 2.7443167072791543],
					[60.535461300100543, 2.744191713291364],
					[60.535469599799924, 2.7440611131551895],
					[60.535477998877091, 2.7439528143687593],
					[60.535477999684396, 2.7439528123844501],
					[60.535478000150945, 2.7439528031544844],
					[60.535477999980564, 2.7439527995316477],
					[60.535477999533988, 2.7439417206174594],
					[60.535505796093304, 2.7438111416150828],
					[60.535505795922944, 2.7438111379922576],
					[60.535505798259592, 2.7438111302279027],
					[60.535505798089233, 2.7438111266050766],
					[60.535514096280892, 2.7437472418119908],
					[60.535530791929077, 2.7436889558790751],
					[60.535530792736353, 2.7436889538947629],
					[60.535530794350905, 2.7436889499261365],
					[60.535530794265718, 2.7436889481147091],
					[60.535575194137706, 2.7435111483746941],
					[60.53561959377528, 2.7433306483092004],
					[60.53563079377642, 2.7432861490749603],
					[60.535630795390965, 2.7432861451063029],
					[60.535630795942673, 2.743286137687702],
					[60.535630796749949, 2.7432861357033702],
					[60.535639094688108, 2.7432389486519582],
					[60.535691889803701, 2.7430750602245779],
					[60.535691890696157, 2.7430750600516616],
					[60.535691892310695, 2.7430750560829851],
					[60.535691892225508, 2.7430750542715465],
					[60.535727988519611, 2.7429472652225635],
					[60.535780783860183, 2.7428222778898737],
					[60.535780784667452, 2.7428222759055232],
					[60.535780787174438, 2.7428222717638802],
					[60.535780787089251, 2.7428222699524549],
					[60.535833481563358, 2.7426806839428801],
					[60.535889074575195, 2.7425806972746076],
					[60.53588907708221, 2.742580693132926],
					[60.535889082096176, 2.7425806848495951],
					[60.535889083625484, 2.7425806790694396],
					[60.53595018075449, 2.7424389847643376],
					[60.535994579406911, 2.7423500887322585],
					[60.535994579406911, 2.7423500887322585],
					[60.535994580299359, 2.7423500885593195],
					[60.53605848028775, 2.742219488732569],
					[60.536058480202549, 2.7422194869211327],
					[60.536102976178228, 2.7421278952733483],
					[60.536175172370605, 2.7420057021818058],
					[60.536175173177874, 2.7420057001974101],
					[60.536175174792369, 2.7420056962286221],
					[60.536175175599631, 2.742005694244225],
					[60.536227974916258, 2.7419083963356972],
					[60.536291873996923, 2.7417944987346914],
					[60.536291875696627, 2.7417944965773113],
					[60.536291878203606, 2.7417944924355369],
					[60.536291878925638, 2.7417944886396781],
					[60.536344573744479, 2.7416889990134519],
					[60.536408469446734, 2.741589008402614],
					[60.536408469361533, 2.7415890065911577],
					[60.536408471061236, 2.7415890044337576],
					[60.536408471868484, 2.7415890024493357],
					[60.536477970432514, 2.7414723054682],
					[60.536533467646095, 2.7413862074697684],
					[60.536614068034083, 2.7412612084870429],
					[60.536614069648557, 2.741261204518163],
					[60.536614073855219, 2.7412611982188726],
					[60.536614074662445, 2.7412611962344329],
					[60.536630770161644, 2.7412307050674545],
					[60.536650163873546, 2.7412029136422973],
					[60.536650164766009, 2.7412029134693179],
					[60.536730763742852, 2.7410862147227206],
					[60.53679185659248, 2.7410001257068846],
					[60.536855750197581, 2.7409279328497771],
					[60.536855751004815, 2.7409279308653138],
					[60.536855752704518, 2.7409279287078463],
					[60.536855754404208, 2.7409279265503956],
					[60.536908440157383, 2.7408640442776266],
					[60.536972322963187, 2.7408140577571207],
					[60.536972324662891, 2.7408140555996527],
					[60.536972327255043, 2.740814053269188],
					[60.536972329039955, 2.7408140529232097],
					[60.537025128698112, 2.7407695524597364],
					[60.537025129590575, 2.7407695522867437],
					[60.53702513129025, 2.7407695501292784],
					[60.537025132267949, 2.7407695517677721],
					[60.537069508453442, 2.7407307713773679],
					[60.537113979975366, 2.7407112835452239],
					[60.537113980867829, 2.7407112833722289],
					[60.537113982567519, 2.740711281214733],
					[60.537113984437674, 2.7407112826802544],
					[60.537186168061645, 2.7406779885938568],
					[60.53725004116793, 2.7406612950622624],
					[60.537302815924924, 2.7406529991525197],
					[60.537358283844895, 2.7406529995711768],
					[60.537411063007823, 2.7406612976287259],
					[60.537474944209585, 2.7406751926971982],
					[60.537536017653899, 2.7406973829618662],
					[60.537608204953564, 2.7407362752278779],
					[60.537608211456465, 2.7407362794514798],
					[60.537608221614427, 2.7407362847946151],
					[60.537608228032099, 2.7407362872066794],
					[60.537644304803941, 2.7407501775876772],
					[60.537672086268337, 2.7407695642285761],
					[60.537672088138478, 2.7407695656941247],
					[60.537672092771238, 2.7407695684521931],
					[60.53767209464138, 2.7407695699177248],
					[60.537744293597051, 2.7408140705157353],
					[60.537797092566521, 2.7408473691723931],
					[60.537797094436662, 2.7408473706379528],
					[60.537797095329111, 2.7408473704649561],
					[60.537797096221581, 2.7408473702919633],
					[60.537860995687026, 2.7408862719540616],
					[60.537860998364408, 2.7408862714350817],
					[60.537861002997154, 2.740886274193179],
					[60.53786100486731, 2.7408862756587413],
					[60.537933205043004, 2.7409251753323089],
					[60.537933212438347, 2.740925179382995],
					[60.537933224381256, 2.7409251843802451],
					[60.537933230884128, 2.740925188603911],
					[60.537986030681395, 2.7409445886841723],
					[60.537986037013845, 2.7409445892847617],
					[60.537986048871502, 2.7409445924704889],
					[60.537986055289174, 2.7409445948826185],
					[60.538058254839534, 2.7409612948812212],
					[60.538058261171962, 2.7409612954818154],
					[60.538058273922097, 2.7409612984945451],
					[60.538058280254539, 2.7409612990951513],
					[60.538086080144012, 2.7409640983219643],
					[60.538086084691543, 2.7409640992685436],
					[60.538086094593822, 2.7409640991771664],
					[60.538086100033802, 2.7409640999507521],
					[60.538111099771633, 2.7409641002452134],
					[60.538111108781457, 2.7409641003268272],
					[60.538111127608346, 2.7409640985055095],
					[60.538111138317866, 2.7409640964295852],
					[60.538183338324814, 2.7409501962807568],
					[60.538183342787121, 2.7409501954157842],
					[60.538183355196331, 2.7409501911822947],
					[60.538183359658639, 2.7409501903173217],
					[60.538236159686065, 2.7409334914592298],
					[60.538236172902515, 2.7409334852411913],
					[60.538236197635733, 2.7409334749626573],
					[60.538236208982042, 2.7409334672790377],
					[60.538308409383013, 2.7408862665654805],
					[60.538308411975173, 2.7408862642349261],
					[60.538308418137156, 2.7408862612123741],
					[60.538308420729336, 2.740886258881821],
					[60.538389020965582, 2.7408251595579731],
					[60.538389031334191, 2.7408251502357133],
					[60.53838905028649, 2.740825131937207],
					[60.538389057892495, 2.7408251213223784],
					[60.53841684937543, 2.7407890349705655],
					[60.538461237366214, 2.7407473456900653],
					[60.538461240765578, 2.7407473413749113],
					[60.538461247649586, 2.7407473345561923],
					[60.538461251048972, 2.7407473302410335],
					[60.538533448126365, 2.7406640336395065],
					[60.538622345824301, 2.7405695377714663],
					[60.538622345739064, 2.7405695359598932],
					[60.538622346631541, 2.7405695357868844],
					[60.538622348416467, 2.7405695354408648],
					[60.538650147809207, 2.7405390355616142],
					[60.538650152908303, 2.7405390290888261],
					[60.538650162213955, 2.7405390163162511],
					[60.53865016552809, 2.7405390101894698],
					[60.538666852824534, 2.7405140317855974],
					[60.538758434869365, 2.7404307474876206],
					[60.538758435676591, 2.7404307455030246],
					[60.538758440053641, 2.7404307428263812],
					[60.538758440860882, 2.7404307408417834],
					[60.538827925648476, 2.7403612552348582],
					[60.538875108331581, 2.7403307686300904],
					[60.538927906970258, 2.740297368725011],
					[60.538972288743992, 2.7402695802814319],
					[60.539016770320956, 2.7402529861190073],
					[60.539016772105867, 2.7402529857729667],
					[60.539016777460624, 2.7402529847348513],
					[60.539016780052755, 2.7402529824041988],
					[60.539080663303217, 2.7402251918087646],
					[60.539141739307986, 2.7402112961781309],
					[60.539205634305091, 2.7402001976231984],
					[60.539205636090045, 2.7402001972771477],
					[60.539205638767413, 2.7402001967580842],
					[60.539205641444788, 2.7402001962390168],
					[60.539258329421209, 2.7401890985348225],
					[60.539322216554254, 2.7401834986264322],
					[60.539322218339152, 2.7401834982803877],
					[60.539322220209307, 2.7401834997459709],
					[60.53932222110177, 2.7401834995729462],
					[60.539375014828416, 2.7401779991742847],
					[60.539438908576514, 2.7401752004774451],
					[60.539438912146359, 2.7401751997853463],
					[60.539438918393579, 2.7401751985741729],
					[60.539438921156183, 2.7401751998667199],
					[60.539491720095832, 2.7401695996919408],
					[60.539547220122209, 2.7401640992200353],
					[60.539547222799598, 2.7401640987009537],
					[60.539547228154341, 2.7401640976628023],
					[60.539547230831708, 2.7401640971437269],
					[60.539600024441505, 2.7401557995946462],
					[60.539663916924667, 2.7401501984818237],
					[60.539663919687271, 2.7401501997743756],
					[60.539663925042042, 2.7401501987362122],
					[60.539663926826954, 2.7401501983901593],
					[60.539725019365449, 2.7401418987581034],
					[60.539772212400116, 2.7401391001318292],
					[60.539772213292579, 2.7401390999588],
					[60.539772215077505, 2.7401390996127422],
					[60.539772215969968, 2.7401390994397126],
					[60.539841714722485, 2.7401334990689294],
					[60.539877801771738, 2.7401308005885121],
					[60.539924988733233, 2.7401335004199838],
					[60.539924991410601, 2.7401334999008991],
					[60.539924992303092, 2.7401334997278659],
					[60.53999437291364, 2.7401362989214779],
					[60.54004165138155, 2.7401473943127854],
					[60.540102730181282, 2.7401640884935454],
					[60.540166606818325, 2.7401945769449743],
					[60.540219288344517, 2.7402251664889388],
					[60.540291577913216, 2.7402807596089072],
					[60.540291578805665, 2.7402807594358802],
					[60.540291579698128, 2.7402807592628502],
					[60.540335952727155, 2.7403140385142177],
					[60.540363734382197, 2.7403557101563467],
					[60.540363737315289, 2.7403557150722806],
					[60.5403637450516, 2.7403557263697942],
					[60.540363748877155, 2.7403557311127202],
					[60.54043593911944, 2.7404390193550583],
					[60.540488729355381, 2.7405223047858276],
					[60.540552624276252, 2.7406306944034169],
					[60.540608118323327, 2.7407444828483172],
					[60.540660916241777, 2.7408694784378498],
					[60.540660916241777, 2.7408694784378498],
					[60.540660917304692, 2.7408694818882329],
					[60.54066091828237, 2.7408694835269123],
					[60.540716512998301, 2.7409917727107955],
					[60.540769207106983, 2.7411556517127957],
					[60.540777605319875, 2.7411944420152046],
					[60.540777604597864, 2.7411944458116055],
					[60.540777605660772, 2.7411944492619957],
					[60.540777606638457, 2.7411944509006867],
					[60.540788706617853, 2.7412361508812997],
					[60.54078870670309, 2.7412361526929976],
					[60.540788707765991, 2.74123615614339],
					[60.540788708828899, 2.7412361595937837],
					[60.540841509325865, 2.741408357227074],
					[60.5408859084312, 2.7415583566426158],
					[60.540913708002549, 2.7416556558610043],
					[60.540913708087764, 2.741655657672712],
					[60.540913710128358, 2.7416556627618403],
					[60.540913711106036, 2.7416556644005552],
					[60.540949809854332, 2.7417611635474688],
					[60.540985907790215, 2.7418722574659653],
					[60.541013706823712, 2.7419722503140163],
					[60.541049805782258, 2.7421139491283899],
					[60.541049805867466, 2.7421139509401056],
					[60.541049806845145, 2.7421139525788401],
					[60.54104980693036, 2.7421139543905526],
					[60.541094207386365, 2.7422722537345714],
					[60.541094208364036, 2.7422722553733117],
					[60.541094210404594, 2.7422722604625291],
					[60.541094209597333, 2.7422722624472264],
					[60.541113709071602, 2.7423306596008237],
					[60.541130408085003, 2.7423861551980795],
					[60.541177607106512, 2.7425556538332057],
					[60.54117760808419, 2.742555655471957],
					[60.541222007800087, 2.7427111552449301],
					[60.541222008862981, 2.7427111586954145],
					[60.541222011966376, 2.7427111672351696],
					[60.541222013114442, 2.74271117249739],
					[60.541249803722202, 2.7427833479230048],
					[60.541258101039404, 2.7428556229289831],
					[60.541258102102283, 2.7428556263794772],
					[60.541258103250343, 2.7428556316417234],
					[60.541258103420745, 2.7428556352651845],
					[60.54128590287575, 2.7430111333764562],
					[60.54131090329593, 2.7431667317346826],
					[60.541310902659049, 2.7431667373428601],
					[60.541310905847638, 2.7431667476944184],
					[60.541310906910489, 2.7431667511449307],
					[60.541330405693046, 2.7432389507620512],
					[60.541347006030541, 2.7433056478476909],
					[60.541347019166118, 2.7433056782106315],
					[60.541347055976409, 2.7433057332376207],
					[60.541347077866206, 2.7433057582475695],
					[60.541797078329431, 2.7436501589314832],
					[60.54179712967052, 2.7436501782347946],
					[60.541797237322044, 2.7436501884542999],
					[60.541797290869901, 2.7436501780775671],
					[60.542516790773654, 2.7432807773922629],
					[60.542516798635432, 2.7432807722120125],
					[60.542516813551742, 2.7432807638362506],
					[60.542516822305977, 2.7432807584830394],
					[60.54323342246483, 2.7427307594408292],
					[60.543233451360067, 2.7427307227581381],
					[60.543233489897617, 2.7427306421542932],
					[60.543233500432351, 2.7427305980601644],
					[60.543222400262657, 2.7416305984172857],
					[60.5432224003901, 2.7414750122767777],
					[60.543230799039286, 2.7414028221641624],
					[60.543230798527958, 2.7414028112931326],
					[60.543230799290193, 2.7414027892050368],
					[60.54323079877885, 2.7414027783340043],
					[60.543222400062739, 2.7413277872921364],
					[60.543219599963685, 2.7411749983611751],
					[60.543219599745193, 2.7410222114509288],
					[60.543230799299749, 2.7409250238194613],
					[60.543230799129283, 2.7409250201957773],
					[60.543230798873594, 2.7409250147602737],
					[60.543230799595591, 2.7409250109635601],
					[60.543239098934137, 2.7407750174945087],
					[60.54324739881465, 2.7407028224928021],
					[60.543247398558961, 2.7407028170572829],
					[60.543247399832467, 2.7407028058401868],
					[60.543247399576778, 2.740702800404669],
					[60.54324740024159, 2.7406000097090057],
					[60.543255798819622, 2.7405056210991292],
					[60.543264099417627, 2.7404361244975881],
					[60.543283497705836, 2.7402833276777616],
					[60.543300198479699, 2.7401667288091875],
					[60.543300198223982, 2.7401667233736786],
					[60.543300198775462, 2.7401667159532703],
					[60.543300200389915, 2.7401667119834943],
					[60.543308499591049, 2.7400306116736992],
					[60.543316898937874, 2.7398944223776094],
					[60.543336296559708, 2.7397833337944775],
					[60.543336298259376, 2.7397833316365174],
					[60.543336297918394, 2.7397833243891561],
					[60.543336298640369, 2.7397833205924198],
					[60.543352999395609, 2.7396250215646138],
					[60.543352999139849, 2.7396250161290889],
					[60.543353000413283, 2.7396250049119173],
					[60.543353000157538, 2.7396249994763924],
					[60.543353000362963, 2.7395750264034806],
					[60.543369593799753, 2.7395111504714995],
					[60.543369595880392, 2.7395111372694059],
					[60.543369599234424, 2.7395111128501273],
					[60.543369600422608, 2.7395110998211121],
					[60.543369599845526, 2.7393722222721046],
					[60.543389094660547, 2.7392833436495811],
					[60.543389096996918, 2.7392833358829836],
					[60.543389098907006, 2.7392833190572037],
					[60.543389100350907, 2.7392833114636859],
					[60.543397399974936, 2.739138913185422],
					[60.543405798710509, 2.7390222137543612],
					[60.543405799602965, 2.7390222135812761],
					[60.543405799602965, 2.7390222135812761],
					[60.543414100045339, 2.7388944129585879],
					[60.5434223997839, 2.7387667147792332],
					[60.543430798747991, 2.7386556153914556],
					[60.543430798662712, 2.738655613579621],
					[60.543430800447624, 2.7386556132334317],
					[60.543430800362373, 2.7386556114215921],
					[60.543439098675918, 2.7385139171287229],
					[60.543450199449993, 2.7384139220306625],
					[60.543450199279476, 2.7384139184069851],
					[60.543450200001395, 2.7384139146101907],
					[60.543450199830872, 2.7384139109865155],
					[60.54345849971628, 2.7382639117080183],
					[60.543458500438192, 2.738263907911223],
					[60.543458500182396, 2.7382639024757092],
					[60.543458500097131, 2.7382639006638554],
					[60.543458500324945, 2.73818890781122],
					[60.543466898981137, 2.7380750180355031],
					[60.543475199137532, 2.7379944201672219],
					[60.543475198881723, 2.7379944147317117],
					[60.54347520024028, 2.7379944053262815],
					[60.543475199984471, 2.7379943998907521],
					[60.543475199977237, 2.7379389048782428],
					[60.54348350030341, 2.7377861132654364],
					[60.543491898946158, 2.737688916382945],
					[60.543491898946158, 2.737688916382945],
					[60.543491899668069, 2.7376889125861483],
					[60.543491899668069, 2.7376889125861483],
					[60.54350019873052, 2.7375472174929474],
					[60.543511299316783, 2.7374444207271345],
					[60.543511299060953, 2.7374444152916055],
					[60.543511300248916, 2.7374444022624691],
					[60.543511299993085, 2.7374443968269384],
					[60.543508500066658, 2.7373111077831993],
					[60.543519598908858, 2.7371972184135318],
					[60.543519598908858, 2.7371972184135318],
					[60.543519598738293, 2.7371972147898607],
					[60.543519599545455, 2.737197212804888],
					[60.543528000363125, 2.7370722127521327],
					[60.543528000192545, 2.7370722091284607],
					[60.543527999936714, 2.7370722036929278],
					[60.543527999766148, 2.7370722000692549],
					[60.543527999671035, 2.7369528065125772],
					[60.543536298686817, 2.7368361141588808],
					[60.543536300301156, 2.7368361101889001],
					[60.543536300045282, 2.7368361047533916],
					[60.543536299789437, 2.7368360993178595],
					[60.543536300436287, 2.7367083077789824],
					[60.543544599267598, 2.7366000149504108],
					[60.543544600074746, 2.7366000129654258],
					[60.543544599989474, 2.7366000111535871],
					[60.543552999343319, 2.7364639145709773],
					[60.543561299405745, 2.7363639167569658],
					[60.543561300042327, 2.7363639111482962],
					[60.54356129970116, 2.7363639039009331],
					[60.543561300423015, 2.7363639001040849],
					[60.54356130016528, 2.7362167002337587],
					[60.543561299805184, 2.7361278113463805],
					[60.543577998617003, 2.7359722206148622],
					[60.543577999338851, 2.7359722168180052],
					[60.543577999719524, 2.735972205773801],
					[60.543578000356078, 2.7359722001651234],
					[60.543577999914405, 2.7359332994188272],
					[60.543577999802537, 2.7358944059205936],
					[60.543586299273002, 2.7357361170030012],
					[60.543597398866048, 2.7356444231684329],
					[60.543597398780733, 2.7356444213565982],
					[60.543597399417301, 2.735644415747907],
					[60.543597400053827, 2.7356444101392055],
					[60.543605799950598, 2.7354972126766319],
					[60.543614099403491, 2.7353889153933717],
					[60.54361410021064, 2.7353889134083502],
					[60.543614100125339, 2.7353889115965115],
					[60.543622400017291, 2.7352528134286165],
					[60.54363079942339, 2.7351278216422084],
					[60.543650198236548, 2.7350056318012217],
					[60.543650198065919, 2.7350056281775323],
					[60.543650198787738, 2.7350056243806651],
					[60.543650198702423, 2.7350056225688277],
					[60.543666896675248, 2.734852833745625],
					[60.543683494772182, 2.7347833458469504],
					[60.543683496937646, 2.734783334456298],
					[60.543683498591228, 2.734783312194597],
					[60.543683499778922, 2.7347832991653096],
					[60.543683500032287, 2.7346944243219835],
					[60.54371129519086, 2.7345778466725821],
					[60.543711295742035, 2.7345778392520015],
					[60.543711297736856, 2.7345778242376473],
					[60.543711299180472, 2.7345778166438608],
					[60.543719597849851, 2.7344833269049298],
					[60.543747396833474, 2.7343278379332303],
					[60.543780795964999, 2.7341556416374142],
					[60.543791894986271, 2.7341083458463631],
					[60.543791894815627, 2.7341083422226617],
					[60.543791897151692, 2.7341083344556241],
					[60.543791897873504, 2.7341083306587017],
					[60.543800195904218, 2.7340556398397524],
					[60.543844593715718, 2.7338806497987052],
					[60.543844593630396, 2.7338806479868607],
					[60.543844595244643, 2.7338806440167134],
					[60.543844595159321, 2.7338806422048707],
					[60.543880794279382, 2.7337139460626898],
					[60.543897392621233, 2.7336528512252896],
					[60.543897392621233, 2.7336528512252896],
					[60.543897394406123, 2.7336528508788187],
					[60.543914093905066, 2.7335889516640766],
					[60.543914093819737, 2.7335889498522303],
					[60.543914095348647, 2.7335889440701959],
					[60.543914095263304, 2.7335889422583501],
					[60.5439501940931, 2.7334222465599498],
					[60.543994593109382, 2.7332583511486215],
					[60.543994593831151, 2.7332583473516756],
					[60.543994596167167, 2.7332583395845136],
					[60.543994596888936, 2.7332583357875664],
					[60.54400579266408, 2.7331944568911775],
					[60.544030785075236, 2.7331333757207181],
					[60.544030789110785, 2.7331333657951857],
					[60.544030795311663, 2.7331333444787775],
					[60.544030796584529, 2.733133333261148],
					[60.544058495831685, 2.7329722401875425],
					[60.544094594436018, 2.7328222474764332],
					[60.544094595243131, 2.7328222454913171],
					[60.544094595879542, 2.7328222398824953],
					[60.544094595794206, 2.7328222380706233],
					[60.544111295770961, 2.7327361389574789],
					[60.544111297470508, 2.7327361367991019],
					[60.544127995771504, 2.7326444392689964],
					[60.544155795202833, 2.7325167457867865],
					[60.544183494102235, 2.7324028469854871],
					[60.544183494909333, 2.7324028450003395],
					[60.544183495631088, 2.7324028412033528],
					[60.544183496352836, 2.7324028374063656],
					[60.54420849599186, 2.7322750379720615],
					[60.544208496884316, 2.7322750377987823],
					[60.544208496798987, 2.7322750359869206],
					[60.544236296420095, 2.7321250421903613],
					[60.544252993923038, 2.7320556473419435],
					[60.544252996344319, 2.7320556413864994],
					[60.544252997617114, 2.7320556301687557],
					[60.544252998338841, 2.7320556263717513],
					[60.544261296622402, 2.7319917349337475],
					[60.544297394633283, 2.73182504283327],
					[60.54429739641818, 2.7318250424866886],
					[60.544333496313584, 2.7316556416954967],
					[60.544333496142876, 2.7316556380717532],
					[60.544333497586337, 2.7316556304777104],
					[60.544333498308085, 2.7316556266806713],
					[60.544341896083445, 2.7315944436687318],
					[60.544361291386828, 2.7315333612744763],
					[60.544361292023176, 2.7315333556655705],
					[60.544361295166176, 2.7315333459130384],
					[60.544361295802553, 2.731533340304146],
					[60.544394595244839, 2.7313750437105364],
					[60.544430794178624, 2.7312278473660441],
					[60.544430794985693, 2.7312278453808725],
					[60.544430794900343, 2.7312278435690018],
					[60.544430794900343, 2.7312278435690018],
					[60.544450195252715, 2.731138943591823],
					[60.544450196059785, 2.7311389416066487],
					[60.544450195889048, 2.7311389379829114],
					[60.54445019678149, 2.7311389378096056],
					[60.544466894609336, 2.7310500432642337],
					[60.544502993590065, 2.7309111502988701],
					[60.544502994397142, 2.7309111483136879],
					[60.544539094098553, 2.7307667493995789],
					[60.544539094905637, 2.7307667474143713],
					[60.544539094734901, 2.7307667437906264],
					[60.544539096434413, 2.7307667416321157],
					[60.544555792738684, 2.7306861535815292],
					[60.544583487696599, 2.73060836618592],
					[60.544583489396103, 2.7306083640273986],
					[60.544583490924893, 2.7306083582451106],
					[60.544583491646584, 2.7306083544480368],
					[60.544627992234972, 2.7304528572408571],
					[60.544680790850094, 2.7302861594389327],
					[60.544680791657164, 2.7302861574537194],
					[60.544680793185904, 2.7302861516714034],
					[60.544680793907588, 2.7302861478743141],
					[60.544697390395669, 2.7302194639219328],
					[60.544733483616064, 2.7301361791727032],
					[60.544733486037252, 2.7301361732170313],
					[60.544733489987166, 2.7301361614790416],
					[60.544733491601285, 2.7301361575086061],
					[60.544769588651349, 2.7300139656623745],
					[60.544805785015313, 2.7299250744612924],
					[60.544805785907776, 2.7299250742879435],
					[60.544805786629446, 2.729925070490816],
					[60.544805786629446, 2.729925070490816],
					[60.544858484130977, 2.7297833783679586],
					[60.544914080833621, 2.7296667852800462],
					[60.544914080833621, 2.7296667852800462],
					[60.544914081555305, 2.7296667814829156],
					[60.544914083340181, 2.7296667811362103],
					[60.544966880208612, 2.7295472884640235],
					[60.545027976078245, 2.7294333948096026],
					[60.545027976885301, 2.7294333928243306],
					[60.545027980284267, 2.729433388507104],
					[60.54502798109133, 2.7294333865218476],
					[60.545083479975496, 2.7293167873897275],
					[60.545136275233141, 2.7292111976157614],
					[60.545208470431191, 2.729094504646596],
					[60.545208472937709, 2.7290945005026801],
					[60.545208477950801, 2.7290944922148208],
					[60.54520848037194, 2.7290944862590014],
					[60.545252976722075, 2.7290028923166343],
					[60.545325171160911, 2.7288751032131118],
					[60.545369568025841, 2.728805707699598],
					[60.545369568832875, 2.7288057057143078],
					[60.545369570532358, 2.7288057035556186],
					[60.545369572231834, 2.728805701396948],
					[60.545430770850892, 2.728702903980794],
					[60.545466869891577, 2.728644505847801],
					[60.54546687401217, 2.7286444977331956],
					[60.545466882338765, 2.7286444833159185],
					[60.545466884674489, 2.7286444755480801],
					[60.545486278160702, 2.7285972915539731],
					[60.545566868900174, 2.7284695075817749],
					[60.545566869707208, 2.7284695055964638],
					[60.545566871406677, 2.7284695034377626],
					[60.545566872213698, 2.7284695014524534],
					[60.545619570375976, 2.7283807041037105],
					[60.545691868817777, 2.7282668068847853],
					[60.545691871409701, 2.728266804552665],
					[60.545691874637839, 2.7282667966113503],
					[60.545691877144336, 2.7282667924673065],
					[60.545744574301771, 2.7281667985338101],
					[60.545808471245749, 2.7280612032706615],
					[60.545808472052784, 2.7280612012853043],
					[60.54580847455928, 2.7280611971412307],
					[60.545808476258742, 2.7280611949824922],
					[60.545869573616777, 2.7279472986362538],
					[60.545925172210161, 2.7278529001451206],
					[60.54592517310261, 2.7278528999717095],
					[60.545925173909616, 2.7278528979863625],
					[60.545925174802044, 2.7278528978129541],
					[60.545994574564148, 2.7277278972103569],
					[60.54599457626361, 2.7277278950515895],
					[60.545994577070644, 2.7277278930662141],
					[60.545994577963093, 2.7277278928927986],
					[60.546039076779174, 2.727641794421459],
					[60.54611127494622, 2.7275083954686736],
					[60.546155774647438, 2.7274278970367409],
					[60.546155776346893, 2.7274278948779451],
					[60.546155777960927, 2.7274278909071961],
					[60.546155778767961, 2.7274278889218029],
					[60.546227976091181, 2.7272833952738464],
					[60.546272372251408, 2.7272084020105121],
					[60.546272373865442, 2.7272083980397404],
					[60.546272378071379, 2.7272083917367191],
					[60.546272379685398, 2.7272083877659319],
					[60.546344579423014, 2.7270583904387],
					[60.546372377254642, 2.7270056924093828],
					[60.546372378147076, 2.7270056922359456],
					[60.546416878099791, 2.7269194926999121],
					[60.546416878014384, 2.7269194908879459],
					[60.546469578216261, 2.7268167909024057],
					[60.546469579108688, 2.7268167907289649],
					[60.546469579830266, 2.7268167869315927],
					[60.5464695807227, 2.7268167867581501],
					[60.546497380720716, 2.7267583863857858],
					[60.546497380635287, 2.7267583845738184],
					[60.546497380635287, 2.7267583845738184],
					[60.546566876570175, 2.7266083931005975],
					[60.546611271602394, 2.7265334019155176],
					[60.546611274194262, 2.7265333995831909],
					[60.546611276615302, 2.7265333936269016],
					[60.546611278314728, 2.7265333914680294],
					[60.546683477124908, 2.726391793426366],
					[60.546727974426027, 2.7263111990929167],
					[60.546800171015946, 2.7261890036033698],
					[60.546852967435839, 2.7261029097920257],
					[60.546925161073318, 2.7260001189792793],
					[60.547005745341018, 2.7258973406296372],
					[60.547041829140078, 2.7258668528489287],
					[60.547041836915639, 2.7258668458516988],
					[60.547041850596528, 2.7258668303921429],
					[60.547041858372111, 2.725866823394909],
					[60.54706124575312, 2.725841837530663],
					[60.547150131276922, 2.7257640509648486],
					[60.547230730990712, 2.7256945508914323],
					[60.54723073269016, 2.7256945487324669],
					[60.54723073358258, 2.7256945485589883],
					[60.547258417377535, 2.725669562542393],
					[60.547302898358375, 2.725644573532009],
					[60.547302899250802, 2.7256445733585299],
					[60.547302901035664, 2.725644573011571],
					[60.547302901928099, 2.7256445728380903],
					[60.547364002110257, 2.7256084717545259],
					[60.547364003002691, 2.7256084715810447],
					[60.547364004702118, 2.7256084694220708],
					[60.547364005594538, 2.7256084692485922],
					[60.54740839512143, 2.7255807768431102],
					[60.547480682400419, 2.7255473814088904],
					[60.547544477909234, 2.7255195846031497],
					[60.547605675592749, 2.7254945858011461],
					[60.547605677377611, 2.7254945854541739],
					[60.54760567907703, 2.7254945832951765],
					[60.547605679969458, 2.7254945831216895],
					[60.547669470346243, 2.7254668876598322],
					[60.547722259586564, 2.7254501904832789],
					[60.547722263156281, 2.7254501897893264],
					[60.547722269403323, 2.7254501885749045],
					[60.547722271995184, 2.725450186242409],
					[60.547794471872123, 2.725422386340667],
					[60.547794478926143, 2.7254223831407134],
					[60.547794492141733, 2.7254223769142798],
					[60.54779449919576, 2.7254223737143244],
					[60.547813990371566, 2.7254112791507361],
					[60.547838978852916, 2.7254001838177206],
					[60.547919476144685, 2.7253668847447283],
					[60.547919477929547, 2.7253668843977463],
					[60.547919480606851, 2.7253668838772684],
					[60.54791948230627, 2.7253668817182239],
					[60.547975081752313, 2.7253418825605773],
					[60.547975084429609, 2.7253418820400941],
					[60.547975087913883, 2.7253418795340809],
					[60.547975088806311, 2.7253418793605872],
					[60.548047289022293, 2.7253057785913009],
					[60.548047294291443, 2.7253057757382844],
					[60.548047303044854, 2.7253057703792245],
					[60.548047306614592, 2.7253057696852436],
					[60.548108401865591, 2.7252668719529458],
					[60.548163995777259, 2.7252362741416589],
					[60.548164002831271, 2.7252362709416231],
					[60.54816401596139, 2.7252362629029969],
					[60.548164022037533, 2.7252362580643843],
					[60.548225114312004, 2.7251890657580522],
					[60.548261205333283, 2.7251668693937376],
					[60.548297305036883, 2.7251445707114565],
					[60.548297306736316, 2.7251445685523894],
					[60.548297311113025, 2.7251445658728204],
					[60.548297312897887, 2.7251445655258211],
					[60.548386213187115, 2.7250834649644964],
					[60.54838622015567, 2.725083459952319],
					[60.548386232307919, 2.7250834502750036],
					[60.548386238384055, 2.7250834454363266],
					[60.548450129921427, 2.7250223533545848],
					[60.548494520023198, 2.724989059541286],
					[60.548494522615044, 2.7249890572086759],
					[60.548494528691172, 2.7249890523699807],
					[60.548494532260904, 2.7249890516759541],
					[60.548539029959628, 2.7249501520217776],
					[60.548602927076224, 2.7248973532378677],
					[60.548602928054095, 2.724897354876441],
					[60.548602927968666, 2.7248973530643581],
					[60.548602928861079, 2.7248973528908498],
					[60.548655729287027, 2.7248529527199579],
					[60.548655730986432, 2.724852950560853],
					[60.54871952769539, 2.7247973546938344],
					[60.548800123847123, 2.724733457845129],
					[60.548800129030816, 2.7247334531798373],
					[60.548800136720878, 2.7247334443698201],
					[60.548800141097558, 2.7247334416901534],
					[60.548825134291363, 2.7247084481644714],
					[60.548852922780831, 2.7246862576415327],
					[60.548933416816872, 2.7246251615529196],
					[60.549014014337629, 2.7245695640972487],
					[60.5490140151446, 2.7245695621116268],
					[60.549014017821889, 2.7245695615910597],
					[60.549014020413736, 2.7245695592583932],
					[60.54905841225321, 2.7245362656664485],
					[60.549122303955869, 2.7244973718093539],
					[60.549122309139563, 2.724497367143988],
					[60.549122317892916, 2.7244973617845196],
					[60.549122322184175, 2.7244973572926794],
					[60.549158414025911, 2.7244695652198523],
					[60.549194504989202, 2.7244473691662168],
					[60.549194505967094, 2.7244473708047972],
					[60.549194507666492, 2.7244473686456372],
					[60.549194509365911, 2.7244473664864715],
					[60.549266804036712, 2.724400170793793],
					[60.549336198349181, 2.7243612744049126],
					[60.549400096610484, 2.7243251746957866],
					[60.549480697057049, 2.724280774270964],
					[60.549480702326157, 2.7242807714176438],
					[60.549480711971967, 2.7242807658845281],
					[60.549480716348647, 2.7242807632047192],
					[60.549500099979454, 2.7242668737638116],
					[60.549525081458057, 2.7242557837172221],
					[60.549525086727165, 2.7242557808638894],
					[60.54952509905025, 2.7242557748101577],
					[60.549525105126357, 2.7242557699711432],
					[60.549597299216693, 2.7242112738201238],
					[60.549650092712142, 2.7241834774793379],
					[60.549650093604562, 2.7241834773058038],
					[60.549650094411525, 2.7241834753201357],
					[60.549650095303974, 2.7241834751465936],
					[60.549722292247729, 2.7241445777503963],
					[60.549794488749235, 2.7241084782644087],
					[60.549794494910792, 2.7241084752374842],
					[60.549794507233877, 2.7241084691836388],
					[60.549794513309969, 2.7241084643445537],
					[60.549838996893222, 2.7240779768341636],
					[60.549927879714978, 2.7240390839166349],
					[60.549927884984072, 2.7240390810632311],
					[60.549927894629882, 2.72403907552994],
					[60.549927899092033, 2.7240390746622252],
					[60.549947295481523, 2.724027976748074],
					[60.549963984204382, 2.724019581600059],
					[60.550055677812871, 2.723980783651851],
					[60.550108378363014, 2.7239584835931523],
					[60.550108380147876, 2.7239584832460606],
					[60.550108383632121, 2.7239584807397161],
					[60.550108386309404, 2.7239584802190784],
					[60.550172276874399, 2.7239279854642189],
					[60.550233366953677, 2.7239057874254491],
					[60.550288962805226, 2.7238862908636681],
					[60.550361154703261, 2.7238640916601602],
					[60.550413943594386, 2.723850195611603],
					[60.550486137892463, 2.7238362966998242],
					[60.550486139677318, 2.7238362963527205],
					[60.550486143247042, 2.7238362956585092],
					[60.55048614503189, 2.7238362953114046],
					[60.550522235731137, 2.7238279965090406],
					[60.550602827023297, 2.7238168975303747],
					[60.550602827915711, 2.7238168973568206],
					[60.550602829786044, 2.7238168988219003],
					[60.550602830678478, 2.7238168986483453],
					[60.550638921694123, 2.7238112990526995],
					[60.550719414183796, 2.7238058007403607],
					[60.550719414990766, 2.7238057987546105],
					[60.550755614679908, 2.7238029998528703],
					[60.550827807725447, 2.7237974000867782],
					[60.550852799811636, 2.7237974007422348],
					[60.550852803381368, 2.7237974000480101],
					[60.550852808821396, 2.7237974008188757],
					[60.550852813283555, 2.7237973999510916],
					[60.550897211198155, 2.7237945995658706],
					[60.550952810286212, 2.7237918995215891],
					[60.550952813048966, 2.7237919008131244],
					[60.550952819295972, 2.7237918995982247],
					[60.550952821973247, 2.7237918990775558],
					[60.55097781528594, 2.7237890995417544],
					[60.551050007994775, 2.7237862996894266],
					[60.551050013349354, 2.7237862986480788],
					[60.551050025036403, 2.7237862982040393],
					[60.551050030390982, 2.7237862971626923],
					[60.551086125211036, 2.7237807972702655],
					[60.55116672135955, 2.7237723981488724],
					[60.551166722337442, 2.7237723997875305],
					[60.551166724122311, 2.7237723994404122],
					[60.551166724929267, 2.7237723974546344],
					[60.551211125198648, 2.7237668971466831],
					[60.551211130638691, 2.723766897917546],
					[60.551211144025125, 2.7237668953141534],
					[60.551211151164551, 2.7237668939256796],
					[60.551275045765131, 2.7237501954905685],
					[60.551327841353967, 2.7237390956090253],
					[60.55132784403127, 2.7237390950883391],
					[60.551327849385835, 2.7237390940469752],
					[60.55132785206311, 2.7237390935262931],
					[60.551400052355277, 2.7237195927190605],
					[60.551400053247704, 2.7237195925454984],
					[60.551400055924994, 2.7237195920248118],
					[60.551400056817414, 2.7237195918512498],
					[60.55145565690605, 2.7237029920531248],
					[60.55145565958334, 2.7237029915324356],
					[60.551455665744889, 2.7237029885052593],
					[60.551455668422165, 2.72370298798457],
					[60.551516768341848, 2.7236807883950118],
					[60.551516769234262, 2.7236807882214489],
					[60.551516771911565, 2.7236807877007529],
					[60.551516772718507, 2.7236807857149548],
					[60.55158067299552, 2.7236557864097666],
					[60.551644473266315, 2.7236307863293625],
					[60.551644476750567, 2.7236307838228515],
					[60.551644484696936, 2.7236307804485138],
					[60.551644489159116, 2.7236307795806765],
					[60.551705674157489, 2.7236001869100437],
					[60.551761158093441, 2.7235834922562749],
					[60.551761163362514, 2.7235834894026167],
					[60.551761173093766, 2.7235834856810959],
					[60.551761178448345, 2.7235834846396854],
					[60.551813978245484, 2.7235612839732295],
					[60.551813981729723, 2.7235612814666985],
					[60.551813989676106, 2.7235612780923217],
					[60.551813994945213, 2.723561275238648],
					[60.551850083852635, 2.7235418820399024],
					[60.551886171776985, 2.7235279866128925],
					[60.551958372436829, 2.7235001871740461],
					[60.551958373243785, 2.723500185188211],
					[60.55195837592106, 2.7235001846674964],
					[60.551958377705922, 2.7235001843203523],
					[60.552011167838735, 2.723477988897157],
					[60.552083358908384, 2.7234557911640911],
					[60.552083359800811, 2.7234557909905179],
					[60.552136158764995, 2.7234390913471973],
					[60.552191748833124, 2.7234223937910045],
					[60.552261134182942, 2.7234084960559364],
					[60.552316727734301, 2.7234001978708453],
					[60.552377824831559, 2.7233918972917133],
					[60.552425016024522, 2.7233862995644276],
					[60.552486105093713, 2.7233835000387225],
					[60.552530599983335, 2.7233835001361859],
					[60.552566692101848, 2.7233834995334893],
					[60.552638873906361, 2.7233890989697929],
					[60.552683263037977, 2.7233973973695504],
					[60.552683265715253, 2.7233973968488105],
					[60.55268327115531, 2.7233973976196304],
					[60.552683273918056, 2.7233973989111919],
					[60.552747161551409, 2.723405796776754],
					[60.552799948910966, 2.723419594585911],
					[60.552799953373096, 2.7234195937180132],
					[60.552799958898632, 2.7234195963011425],
					[60.552799961575914, 2.7234195957804062],
					[60.552872148414849, 2.7234334940593303],
					[60.552927734330162, 2.7234529898106725],
					[60.552927737007437, 2.7234529892899313],
					[60.552927744317813, 2.7234529915259378],
					[60.552927747973001, 2.723452992643935],
					[60.552988836797851, 2.7234695891755476],
					[60.553052727353879, 2.7234945862152253],
					[60.553052728246307, 2.7234945860416451],
					[60.553052730116626, 2.7234945875068086],
					[60.553052731009075, 2.7234945873332252],
					[60.553105530614999, 2.7235140875115968],
					[60.553105532399869, 2.7235140871644354],
					[60.553105535162636, 2.7235140884560214],
					[60.55310553703297, 2.7235140899212018],
					[60.553205535782844, 2.7235473906587693],
					[60.553222132384853, 2.7235529873083504],
					[60.553294328136907, 2.7235807862328927],
					[60.553294331792088, 2.7235807873509117],
					[60.553294339102465, 2.7235807895869657],
					[60.553294342843117, 2.7235807925173243],
					[60.553349940689323, 2.723597391154549],
					[60.553419338928883, 2.7236195908765337],
					[60.553419341606158, 2.7236195903557974],
					[60.553419348024121, 2.7236195927654276],
					[60.553419350786882, 2.7236195940570496],
					[60.553474950174369, 2.7236334947745187],
					[60.553538849156389, 2.7236501927405103],
					[60.553591649402833, 2.7236640929514579],
					[60.553591653058028, 2.7236640940695103],
					[60.553591662153238, 2.7236640959584291],
					[60.553591665808433, 2.7236640970764623],
					[60.553655565530455, 2.723675196482811],
					[60.553655568293202, 2.7236751977744293],
					[60.553655570970491, 2.723675197253693],
					[60.55365557284081, 2.723675198718893],
					[60.553716672859458, 2.7236834974908009],
					[60.553716674729777, 2.7236834989560044],
					[60.553716678299487, 2.7236834982616869],
					[60.553716680169835, 2.7236834997268851],
					[60.553772179800362, 2.7236890981430895],
					[60.553772181670681, 2.7236890996082956],
					[60.553833282017493, 2.7236945981680827],
					[60.553833282995399, 2.7236945998068682],
					[60.553833285672688, 2.7236945992861252],
					[60.553833286650594, 2.7236946009249143],
					[60.553877787461296, 2.7236974003807415],
					[60.553877789246144, 2.7236974000335832],
					[60.553877791030992, 2.7236973996864231],
					[60.553877791923433, 2.723697399512842],
					[60.553949992070912, 2.7237001995869963],
					[60.55394999385576, 2.7237001992398344],
					[60.553949998403382, 2.7237002001843043],
					[60.55395000018823, 2.7237001998371442],
					[60.553986100426165, 2.7237001997411392],
					[60.553986103103462, 2.7237001992203944],
					[60.55398610943594, 2.7237001998177015],
					[60.553986113005649, 2.7237001991233796],
					[60.55403060554881, 2.7236973997790552],
					[60.554094399683045, 2.7236973995078477],
					[60.554094403338226, 2.7236974006259032],
					[60.55409440780037, 2.7236973997579943],
					[60.55409441137008, 2.7236973990636675],
					[60.554147210254008, 2.7236945990862615],
					[60.554202810300204, 2.7236918998940167],
					[60.554202811192646, 2.7236918997204334],
					[60.554255610968248, 2.7236890995402008],
					[60.554255611860675, 2.7236890993666178],
					[60.554255613645552, 2.7236890990194493],
					[60.554255615430399, 2.7236890986722835],
					[60.554327813968285, 2.7236835001486885],
					[60.554372210318206, 2.7236807999099506],
					[60.554436109240037, 2.7236779990580784],
					[60.554436110217928, 2.7236780006968977],
					[60.55443611111037, 2.7236780005233117],
					[60.554488910884317, 2.7236752002767588],
					[60.554488912669157, 2.7236751999295903],
					[60.554488918023722, 2.7236751988880794],
					[60.554488919808577, 2.7236751985409104],
					[60.55454441913389, 2.7236695997011822],
					[60.554605614126565, 2.7236641001370998],
					[60.55466111036084, 2.723661300280785],
					[60.554661112145702, 2.7236612999336107],
					[60.554661115715405, 2.7236612992392661],
					[60.554661118392694, 2.7236612987185054],
					[60.554722217931918, 2.7236557993813477],
					[60.554722219716766, 2.7236557990341743],
					[60.554777819846095, 2.723650198778528],
					[60.554777820738515, 2.723650198604938],
					[60.554830620921422, 2.7236445997202363],
					[60.554883321257336, 2.7236390988827064],
					[60.554883322149763, 2.7236390987091186],
					[60.554883324827053, 2.7236390981883516],
					[60.55488332571948, 2.7236390980147633],
					[60.554947222043062, 2.7236307993298441],
					[60.555011116822911, 2.7236251987705269],
					[60.555011123155396, 2.7236251993678233],
					[60.555011134756953, 2.7236251971111516],
					[60.555011141003966, 2.7236251958960174],
					[60.555063935741138, 2.7236140961384838],
					[60.555116731129509, 2.7236057983443356],
					[60.555116733806798, 2.723605797823561],
					[60.555116740053805, 2.7236057966084206],
					[60.555116742645602, 2.7236057942752079],
					[60.555180642376655, 2.7235918959847689],
					[60.555233340764239, 2.7235807965477208],
					[60.555233343356058, 2.7235807942144938],
					[60.555233346118818, 2.7235807955061602],
					[60.555233346925775, 2.7235807935201222],
					[60.555313947400982, 2.7235612943120224],
					[60.555313953562511, 2.7235612912844118],
					[60.55531396694893, 2.7235612886805005],
					[60.555313974002878, 2.7235612854792985],
					[60.555341766030587, 2.7235501891287557],
					[60.555405657627574, 2.7235307902991996],
					[60.555405662089704, 2.7235307894312237],
					[60.555405668336718, 2.7235307882160522],
					[60.555405671906435, 2.7235307875216681],
					[60.555441765153383, 2.7235168892675965],
					[60.55547785896826, 2.7235057912037228],
					[60.555477864237332, 2.7235057883496872],
					[60.555477874860991, 2.7235057844540642],
					[60.555477881107997, 2.7235057832388865],
					[60.555547281270009, 2.7234751831644193],
					[60.555547283054864, 2.7234751828172241],
					[60.555547287431523, 2.7234751801367723],
					[60.555547289216385, 2.7234751797895731],
					[60.555602883624459, 2.7234473821977558],
					[60.555675077791769, 2.7234168840250779],
					[60.555675083953304, 2.723416880997382],
					[60.555675096276325, 2.7234168749420369],
					[60.555675102437853, 2.7234168719143619],
					[60.555736193713329, 2.7233807761871165],
					[60.555800085915479, 2.7233501806504079],
					[60.55580008939971, 2.7233501781435181],
					[60.555800093861862, 2.7233501772754987],
					[60.555800097346086, 2.7233501747686133],
					[60.555880696974761, 2.7233057750154108],
					[60.555880699566558, 2.7233057726820999],
					[60.555880708319876, 2.7233057673210874],
					[60.555880711889579, 2.7233057666266665],
					[60.555897306290213, 2.7232945690486492],
					[60.555916799174383, 2.7232834734069584],
					[60.555916799174383, 2.7232834734069584],
					[60.555916800959245, 2.7232834730597468],
					[60.555997298284943, 2.723236274187681],
					[60.556058396323877, 2.7232029767827388],
					[60.556058398023247, 2.7232029746230118],
					[60.556058402399906, 2.723202971942476],
					[60.55605840418476, 2.7232029715952586],
					[60.556122301894874, 2.723164071881893],
					[60.556194494812175, 2.7231223766411907],
					[60.556238989357915, 2.7231001796073362],
					[60.556238990250343, 2.7231001794337226],
					[60.556238991949712, 2.7231001772739978],
					[60.556238991949712, 2.7231001772739978],
					[60.556302891675749, 2.7230668775352527],
					[60.556302896137879, 2.7230668766671808],
					[60.556302902213908, 2.7230668718268718],
					[60.55630290480569, 2.7230668694935103],
					[60.556338997395876, 2.7230445746473317],
					[60.556372288590339, 2.723027979260408],
					[60.556444487802217, 2.7229918793079579],
					[60.556508385245408, 2.7229612812089239],
					[60.556569476985949, 2.7229334841691348],
					[60.556641771669916, 2.7229057866119213],
					[60.556641774347192, 2.7229057860910579],
					[60.556641777831416, 2.7229057835840491],
					[60.556641779616264, 2.7229057832368064],
					[60.556686169191792, 2.7228862875206703],
					[60.556769457273873, 2.7228612927419418],
					[60.556769458973235, 2.7228612905821508],
					[60.556769461650525, 2.7228612900612781],
					[60.556769463435373, 2.7228612897140287],
					[60.556802857230387, 2.7228501925148101],
					[60.556866750988107, 2.7228334943154251],
					[60.556866751795035, 2.722833492329269],
					[60.556866756257186, 2.722833491461139],
					[60.556866757149599, 2.7228334912875156],
					[60.556894444414056, 2.7228251962334666],
					[60.55693063007196, 2.7228195975301457],
					[60.557002821430864, 2.7228084991110073],
					[60.557047211573014, 2.7228058002494535],
					[60.557047214250296, 2.7228057997285746],
					[60.557047218712405, 2.7228057988604393],
					[60.557047221389709, 2.7228057983395542],
					[60.557127806849742, 2.7227974000352764],
					[60.557216689134812, 2.7228001990980037],
					[60.557252781905675, 2.7228029997113805],
					[60.557333278592914, 2.7228112990169442],
					[60.557333280377762, 2.7228112986696864],
					[60.557361067619084, 2.7228140981994104],
					[60.557422156175804, 2.7228279954494741],
					[60.557422158938557, 2.7228279967411506],
					[60.55742216616347, 2.7228279971646785],
					[60.557422169818651, 2.7228279982827464],
					[60.557458255625747, 2.7228334965297791],
					[60.557486041998544, 2.7228418917861115],
					[60.557486043783385, 2.7228418914388541],
					[60.557486046546167, 2.7228418927305333],
					[60.557486048331015, 2.7228418923832756],
					[60.557558247925371, 2.7228612923557436],
					[60.557558248817791, 2.7228612921821145],
					[60.557611041829631, 2.7228751901889998],
					[60.557683235281452, 2.722900188886054],
					[60.557683237151799, 2.7229001903513734],
					[60.557683239914581, 2.7229001916430646],
					[60.557683241699429, 2.7229001912958068],
					[60.557738840763534, 2.722916890935053],
					[60.557808237250491, 2.7229390901607071],
					[60.557863833893407, 2.7229584884959355],
					[60.557863835678255, 2.722958488148679],
					[60.557863837548588, 2.7229584896140109],
					[60.55786384031137, 2.7229584909057136],
					[60.557916640233074, 2.7229751902044987],
					[60.557916642103422, 2.7229751916698337],
					[60.557916645758603, 2.7229751927879131],
					[60.557916647543458, 2.7229751924406562],
					[60.557988848017203, 2.7229945927382517],
					[60.557988848909631, 2.7229945925646217],
					[60.557988850779964, 2.7229945940299625],
					[60.558044351122966, 2.7230084943121819],
					[60.558044353885712, 2.7230084956039007],
					[60.558044358433349, 2.7230084965483736],
					[60.558044361110625, 2.7230084960274876],
					[60.558113860623855, 2.7230223964379383],
					[60.558113863386637, 2.7230223977296517],
					[60.55811386695634, 2.723022397035137],
					[60.55811386882668, 2.7230223985004853],
					[60.558149969318535, 2.723027998444373],
					[60.55814997199581, 2.7230279979234853],
					[60.558149977435868, 2.7230279986943184],
					[60.55814998011315, 2.7230279981734316],
					[60.558230575008537, 2.7230362991471591],
					[60.558249971167989, 2.7230390979403807],
					[60.558249979285314, 2.7230390981903279],
					[60.558249992842683, 2.7230390992111131],
					[60.558250000067581, 2.7230390996347089],
					[60.558277800280763, 2.7230390999468104],
					[60.558277802065604, 2.7230390995995535],
					[60.558277805720785, 2.7230391007176511],
					[60.558277808398088, 2.7230391001967607],
					[60.558347208390401, 2.7230363007392495],
					[60.558347210175256, 2.7230363003919908],
					[60.558347213744952, 2.7230362996974682],
					[60.558347214637394, 2.7230362995238377],
					[60.558383315248889, 2.723033499548388],
					[60.558383317926165, 2.7230334990274976],
					[60.558383324258635, 2.7230334996247239],
					[60.558383325958019, 2.7230334974648218],
					[60.558466726371236, 2.7230223984606767],
					[60.558466731725801, 2.7230223974188874],
					[60.558466743327358, 2.7230223951616805],
					[60.558466748681909, 2.723022394119893],
					[60.558511148761667, 2.7230112934038195],
					[60.55851115233137, 2.7230112927092947],
					[60.558511157685921, 2.7230112916675049],
					[60.558511161255652, 2.7230112909729733],
					[60.558580661287436, 2.7229890908708403],
					[60.558580663879248, 2.7229890885373069],
					[60.558580671018653, 2.7229890871482452],
					[60.558580673695928, 2.7229890866273481],
					[60.558636173659544, 2.7229668865464278],
					[60.558636176251326, 2.7229668842128931],
					[60.558636180713464, 2.7229668833447223],
					[60.558636183390746, 2.7229668828238225],
					[60.558697283129213, 2.7229390821536823],
					[60.558697283936141, 2.7229390801674116],
					[60.558697288398285, 2.7229390792992336],
					[60.558697289290706, 2.7229390791255992],
					[60.558769488597527, 2.7229029796837012],
					[60.558769491189324, 2.7229029773501483],
					[60.558769494759026, 2.7229029766556025],
					[60.558769495565969, 2.7229029746693252],
					[60.558825095618204, 2.7228723748832988],
					[60.558825099187921, 2.7228723741887437],
					[60.558825102672131, 2.7228723716815528],
					[60.558825105263914, 2.7228723693479959],
					[60.558905704695931, 2.7228223698278211],
					[60.558905707373206, 2.7228223693069018],
					[60.558905709965011, 2.7228223669733311],
					[60.558905710857438, 2.7228223667996927],
					[60.558922310703871, 2.7228112655412722],
					[60.558922315972943, 2.72281126268678],
					[60.55892232464074, 2.7228112555124153],
					[60.558922329909812, 2.7228112526579227],
					[60.558941817431645, 2.7227945626485681],
					[60.559030701651018, 2.7227418714444402],
					[60.559030704328315, 2.7227418709235076],
					[60.559030707812525, 2.7227418684162825],
					[60.559030708619446, 2.7227418664299878],
					[60.559094509436953, 2.7227001672272584],
					[60.559094510329381, 2.722700167053612],
					[60.559094512114228, 2.7227001667063249],
					[60.55909451292117, 2.7227001647200209],
					[60.559155706639231, 2.7226584695686857],
					[60.559236201384088, 2.7226112735083281],
					[60.55923620219103, 2.7226112715220139],
					[60.559236203083458, 2.7226112713483652],
					[60.559236203083458, 2.7226112713483652],
					[60.559263994061865, 2.7225945769672677],
					[60.559300084387168, 2.7225779817185405],
					[60.559300087871378, 2.7225779792112768],
					[60.559300096710153, 2.7225779756621109],
					[60.559300101979211, 2.7225779728075463],
					[60.559361189702202, 2.722541878980858],
					[60.559408374923009, 2.7225223856854539],
					[60.559477870253502, 2.7224945869828252],
					[60.559541763142512, 2.7224723894171374],
					[60.559594450858683, 2.7224557929799502],
					[60.559658337174248, 2.7224418961815631],
					[60.559711128789843, 2.7224334985881784],
					[60.559775018803911, 2.7224251987246575],
					[60.55981940608757, 2.7224224006382238],
					[60.559863900130466, 2.7224224007782722],
					[60.559927795181913, 2.7224223995845938],
					[60.559980589016995, 2.7224252006873293],
					[60.559980589909415, 2.7224252005136704],
					[60.560036084580361, 2.7224279992534579],
					[60.56008887779565, 2.7224334987791616],
					[60.560161072857753, 2.7224418981851772],
					[60.56021386934821, 2.7224501978542706],
					[60.560213870240638, 2.722450197680613],
					[60.560269368630159, 2.7224584984579252],
					[60.560322168929829, 2.7224668980449565],
					[60.560322171607091, 2.7224668975239785],
					[60.560322178917495, 2.7224668997601507],
					[60.560322183379625, 2.7224668988918537],
					[60.560386077601642, 2.7224723988049844],
					[60.560447172547249, 2.7224807978559187],
					[60.560447175224525, 2.7224807973349368],
					[60.560447177987285, 2.7224807986266919],
					[60.560447179772133, 2.7224807982793706],
					[60.560502774799794, 2.7224862978588442],
					[60.560555568611463, 2.7224945981752442],
					[60.56055557396602, 2.7224945971332799],
					[60.560555584846114, 2.722494598674849],
					[60.560555590286185, 2.7224945994456196],
					[60.560611090396257, 2.7224973990647525],
					[60.560611091374177, 2.7224974007038325],
					[60.560611092266619, 2.7224974005301701],
					[60.560680592434608, 2.7225001997710332],
					[60.560680594219448, 2.7225001994237124],
					[60.560680597874679, 2.7225002005418246],
					[60.56068059965952, 2.7225002001945042],
					[60.560716699948017, 2.7225002001083913],
					[60.560763900351517, 2.722500200498768],
					[60.560763902136372, 2.7225002001514436],
					[60.56076390659851, 2.722500199283131],
					[60.56076390936127, 2.7225002005748991],
					[60.560825009224907, 2.7224973992254164],
					[60.560825011987667, 2.722497400517184],
					[60.560825017342204, 2.7224973994752144],
					[60.560825020019493, 2.7224973989542236],
					[60.560880620444891, 2.7224918992002416],
					[60.560880621337311, 2.7224918990265783],
					[60.560933320686686, 2.7224862997166137],
					[60.560933324256389, 2.7224862990219605],
					[60.560933328718519, 2.7224862981536422],
					[60.560933331395795, 2.7224862976326523],
					[60.560986131278113, 2.7224779986597829],
					[60.561058329783336, 2.7224668979524891],
					[60.561058336922741, 2.7224668965631693],
					[60.561058351201538, 2.7224668937845342],
					[60.561058358340958, 2.7224668923952122],
					[60.561113958236355, 2.7224501911461125],
					[60.561113958236355, 2.7224501911461125],
					[60.561113960021217, 2.7224501907987793],
					[60.561113960913659, 2.7224501906251124],
					[60.561175059696694, 2.7224307910459125],
					[60.561247258641806, 2.7224084903572643],
					[60.561247266673639, 2.7224084887942537],
					[60.561247281673872, 2.7224084822163461],
					[60.561247288727785, 2.7224084790142253],
					[60.561291787707574, 2.7223862798614409],
					[60.561372287424213, 2.7223473806135137],
					[60.561372290015996, 2.7223473782797178],
					[60.561372296177481, 2.7223473752512377],
					[60.561372298854742, 2.72234737473023],
					[60.561391789917636, 2.7223362785980476],
					[60.561416781036392, 2.7223251823227081],
					[60.561416787197892, 2.7223251792942174],
					[60.561416800413262, 2.7223251730635742],
					[60.56141680568232, 2.7223251702087614],
					[60.561497305965418, 2.7222751695279093],
					[60.561497305965418, 2.7222751695279093],
					[60.561497307664787, 2.7222751673677696],
					[60.561497309449628, 2.7222751670204253],
					[60.561561209243067, 2.7222334660701479],
					[60.561561211920342, 2.7222334655491238],
					[60.561561216296951, 2.7222334628679574],
					[60.561561218888762, 2.7222334605341341],
					[60.561625118759203, 2.7221862619263839],
					[60.561625119566145, 2.7221862599399076],
					[60.561625121350986, 2.7221862595925561],
					[60.561705716070549, 2.7221251625352787],
					[60.561730711392748, 2.7221084652176648],
					[60.561730715769386, 2.722108462536462],
					[60.561730725329561, 2.7221084551875743],
					[60.561730729706184, 2.7221084525063586],
					[60.561750124403382, 2.7220918572311352],
					[60.561830716848839, 2.7220334622186302],
					[60.561830720333035, 2.7220334597110942],
					[60.561830723817216, 2.7220334572035387],
					[60.561830727301427, 2.7220334546959988],
					[60.561891821352248, 2.7219834607378974],
					[60.561955713697714, 2.721939063464005],
					[60.561955716374975, 2.7219390629429498],
					[60.561955719859199, 2.7219390604353872],
					[60.561955722450953, 2.7219390581015168],
					[60.562027913647341, 2.7218834645825432],
					[60.562064000283961, 2.7218612731220135],
					[60.562100095304821, 2.7218418754035945],
					[60.562100103165626, 2.7218418702147411],
					[60.562100120672085, 2.721841859489643],
					[60.562100129339818, 2.7218418523142764],
					[60.562169522608642, 2.7217834585859442],
					[60.56224181360691, 2.721730764729906],
					[60.562297309009473, 2.7216945685708955],
					[60.562297315085424, 2.7216945637293462],
					[60.562297327237332, 2.721694554046282],
					[60.562297333313296, 2.7216945492047304],
					[60.562350118190359, 2.7216473620548283],
					[60.562402899930582, 2.7216168730657491],
					[60.562402912974918, 2.721616863208923],
					[60.56240293736424, 2.7216168456554728],
					[60.562402947645772, 2.7216168345068863],
					[60.562430735394329, 2.7215862486047251],
					[60.562519520305003, 2.7215195604375229],
					[60.562519522004337, 2.7215195582772735],
					[60.562519524596105, 2.7215195559433227],
					[60.562519527273366, 2.7215195554222191],
					[60.56261122715938, 2.721444555225931],
					[60.562611234849143, 2.7214445464111789],
					[60.562611252013518, 2.7214445284342785],
					[60.562611259703289, 2.7214445196195238],
					[60.562627948275107, 2.7214223359934917],
					[60.562655734371717, 2.7213973482836398],
					[60.562655738662805, 2.7213973437894068],
					[60.562655748137423, 2.7213973346272162],
					[60.562655752428526, 2.7213973301329819],
					[60.562736251251799, 2.7213029304926395],
					[60.562825148996403, 2.7212029327720186],
					[60.562825150781265, 2.7212029324245917],
					[60.562825153287505, 2.7212029282777128],
					[60.562825154179933, 2.7212029281040016],
					[60.562852954066308, 2.7211695270863574],
					[60.562852958357389, 2.7211695225920658],
					[60.562852966683025, 2.7211695081648566],
					[60.562852970974099, 2.7211695036705659],
					[60.562877967169349, 2.7211279105897086],
					[60.562950162188606, 2.7210279176730885],
					[60.562950162995499, 2.7210279156865043],
					[60.56295016639416, 2.7210279113658964],
					[60.562950167201059, 2.7210279093792948],
					[60.563002967177631, 2.7209473100970922],
					[60.563002967984538, 2.7209473081104822],
					[60.563002968876937, 2.7209473079367656],
					[60.563002969683858, 2.720947305950173],
					[60.563066869024304, 2.7208445069556007],
					[60.563139067748708, 2.7207334086312622],
					[60.563139069448027, 2.7207334064709303],
					[60.56313907276116, 2.7207334003373886],
					[60.563139075352908, 2.7207333980033281],
					[60.563191872676541, 2.7206389999092244],
					[60.563264071820797, 2.7205168026040765],
					[60.563264074327009, 2.7205167984571053],
					[60.563264075940808, 2.7205167944838697],
					[60.563264077640142, 2.7205167923235152],
					[60.563308474715569, 2.7204306975094346],
					[60.563389070591093, 2.7202973033417206],
					[60.56338907229042, 2.7202973011813447],
					[60.563389073097312, 2.7202972991947112],
					[60.563389073904197, 2.7202972972080861],
					[60.563425174055567, 2.7202333976025139],
					[60.563425174862459, 2.720233395615864],
					[60.563425178261085, 2.7202333912951082],
					[60.56342517906797, 2.7202333893084747],
					[60.563469577081726, 2.7201444924131652],
					[60.563514070107239, 2.7200640055056406],
					[60.563550164651545, 2.7200112136775454],
					[60.563550167157757, 2.7200112095304809],
					[60.563550172170146, 2.7200112012363884],
					[60.563550173783916, 2.7200111972630765],
					[60.563622372145957, 2.7198834005992527],
					[60.563675169029217, 2.7197973076873256],
					[60.563747365944472, 2.7196890116208179],
					[60.563747366751358, 2.7196890096341511],
					[60.563747370149969, 2.7196890053132945],
					[60.563747370956847, 2.7196890033266268],
					[60.56380846343491, 2.7195890162589418],
					[60.563872354908455, 2.7195112272083755],
					[60.563872356522218, 2.7195112232350169],
					[60.563872360813257, 2.7195112187403532],
					[60.563872364211846, 2.7195112144194464],
					[60.563944563579589, 2.7194084143911637],
					[60.563944570376776, 2.7194084057493195],
					[60.56394458112284, 2.719408385361104],
					[60.563944586049672, 2.7194083752538942],
					[60.563961269561943, 2.7193668116615983],
					[60.563997341402995, 2.7193307413747805],
					[60.56399734569402, 2.7193307368800754],
					[60.563997355168482, 2.719330727716883],
					[60.56399735937395, 2.7193307214092441],
					[60.56407795896871, 2.7192251211992571],
					[60.564150159136972, 2.7191307211326881],
					[60.564150159943843, 2.7191307191459653],
					[60.564150160750728, 2.7191307171592554],
					[60.564150161643148, 2.719130716985477],
					[60.564194559274085, 2.7190695201619346],
					[60.564247351959303, 2.7190029303864192],
					[60.564302948163551, 2.7189418357728257],
					[60.564302950669727, 2.718941831625596],
					[60.564302955682052, 2.718941823331126],
					[60.564302959166184, 2.7189418208230474],
					[60.564347357294743, 2.7188834226163836],
					[60.564416853787876, 2.7187973287488525],
					[60.564497341883111, 2.7187029428021097],
					[60.564533429047799, 2.7186723533018791],
					[60.564533439414632, 2.7186723439645406],
					[60.564533455600653, 2.7186723243458979],
					[60.564533464182638, 2.7186723153561467],
					[60.564552956260187, 2.7186445258246725],
					[60.56464184799438, 2.7185473351589597],
					[60.564730748231199, 2.7184501349576169],
					[60.564730749930483, 2.7184501327970567],
					[60.564730755028336, 2.71845012631537],
					[60.564730757620033, 2.7184501239809902],
					[60.56475015025012, 2.7184251326986817],
					[60.56477514032877, 2.7184001435379881],
					[60.564866838643212, 2.71831124380709],
					[60.564866840428053, 2.7183112434594774],
					[60.564866841234924, 2.7183112414727044],
					[60.564866841234924, 2.7183112414727044],
					[60.564955741244447, 2.7182223409844481],
					[60.564955743836144, 2.7182223386500493],
					[60.564955748933961, 2.7182223321682861],
					[60.564955752418108, 2.7182223296600738],
					[60.564972341903342, 2.7182029411200452],
					[60.56499182998742, 2.7181862520919986],
					[60.565080729397302, 2.7181112526322946],
					[60.565080730289715, 2.7181112524584798],
					[60.565080731988992, 2.7181112502978788],
					[60.565080732881405, 2.7181112501240654],
					[60.565152923052615, 2.718047357535184],
					[60.565205712681816, 2.7180112648173291],
					[60.565205714466643, 2.7180112644696957],
					[60.565205715359042, 2.7180112642958805],
					[60.565261214838273, 2.71797236389907],
					[60.565261218322384, 2.7179723613908093],
					[60.565261222613344, 2.7179723568957446],
					[60.565261226097462, 2.7179723543874656],
					[60.565305716528179, 2.7179362622941428],
					[60.565350106042551, 2.7179084707912726],
					[60.565350106849422, 2.7179084688044397],
					[60.56535010863427, 2.7179084684567965],
					[60.565350108548699, 2.7179084666438063],
					[60.565422306606465, 2.717861268990962],
					[60.565494505260901, 2.7178168703899721],
					[60.565494506960192, 2.7178168682293204],
					[60.565494511336709, 2.7178168655471815],
					[60.565494513121521, 2.7178168651995365],
					[60.56553900360133, 2.7177862707547389],
					[60.565611191873749, 2.7177473774889433],
					[60.565644488911964, 2.7177307791774536],
					[60.565644493288481, 2.7177307764953054],
					[60.565644500342252, 2.7177307732916591],
					[60.565644502933935, 2.7177307709571692],
					[60.565672299681729, 2.7177140729837101],
					[60.565752896397612, 2.7176695747750537],
					[60.565808394221264, 2.7176390752978339],
					[60.565877891878209, 2.7176029779117195],
					[60.565877893577465, 2.7176029757510181],
					[60.565877898039545, 2.7176029748818569],
					[60.565877899738808, 2.7176029727211679],
					[60.565950099644034, 2.7175612732234029],
					[60.56595010134329, 2.7175612710627122],
					[60.565950104020537, 2.7175612705412076],
					[60.565950104912964, 2.7175612703673706],
					[60.56598620069682, 2.7175390728697506],
					[60.566041795646889, 2.7175084753211114],
					[60.56604179743173, 2.7175084749734371],
					[60.566041799216542, 2.7175084746257667],
					[60.566041800023399, 2.7175084726388996],
					[60.566094499564585, 2.7174779724141436],
					[60.566094508317569, 2.7174779670497062],
					[60.566094522339554, 2.7174779588291829],
					[60.56609452930774, 2.717477953812415],
					[60.566130717929113, 2.7174473616372037],
					[60.566211205751074, 2.7173973703424816],
					[60.566211210934441, 2.7173973656733597],
					[60.566211221386723, 2.7173973581481388],
					[60.566211225763254, 2.717397355465891],
					[60.566283423925363, 2.717339056198107],
					[60.566319521977135, 2.7173112588215718],
					[60.566319522869549, 2.717311258647729],
					[60.566319525461218, 2.717311256313153],
					[60.566319526268074, 2.7173112543262641],
					[60.566364026216711, 2.7172751563351429],
					[60.566433424681911, 2.7172195554684193],
					[60.566433429058421, 2.717219552786132],
					[60.566433436833449, 2.7172195457823389],
					[60.566433441124389, 2.7172195412869993],
					[60.566489040594035, 2.7171640429172674],
					[60.566489041400892, 2.7171640409303621],
					[60.566541840405442, 2.7171112433387483],
					[60.566605737725659, 2.7170501447286375],
					[60.566605741209727, 2.7170501422201667],
					[60.56660574639308, 2.7170501375509111],
					[60.566605748006765, 2.7170501335770871],
					[60.566658447633351, 2.7169918346374131],
					[60.5666584493326, 2.7169918324766411],
					[60.566658451117448, 2.7169918321289264],
					[60.566658451031863, 2.7169918303158678],
					[60.566711248293124, 2.7169307346123444],
					[60.566766845044967, 2.7168723374375352],
					[60.56676684593738, 2.7168723372636769],
					[60.566766846744237, 2.7168723352767481],
					[60.566766847551065, 2.7168723332898068],
					[60.566819547983123, 2.7168140335838022],
					[60.566819548875536, 2.7168140334099427],
					[60.566819549682378, 2.716814031423012],
					[60.566819550574778, 2.7168140312491516],
					[60.566891851022142, 2.7167307316178952],
					[60.566891852635827, 2.7167307276440162],
					[60.566891857733573, 2.7167307211616127],
					[60.566891861217663, 2.716730718653062],
					[60.566964060175373, 2.716633420661196],
					[60.566989054342017, 2.7166001276749947],
					[60.567016848027251, 2.71656953336158],
					[60.567016849726507, 2.7165695312007623],
					[60.567016854909831, 2.7165695265313667],
					[60.567016857415922, 2.7165695223835984],
					[60.567097355585553, 2.7164668263618359],
					[60.567177953583908, 2.7163695282910223],
					[60.567177955283142, 2.7163695261301837],
					[60.567177956175563, 2.7163695259563068],
					[60.567177956089985, 2.7163695241432166],
					[60.567222356061919, 2.7163140257527911],
					[60.567222357675583, 2.7163140217788535],
					[60.567222361966479, 2.7163140172832665],
					[60.567222363580136, 2.7163140133093249],
					[60.567266859870948, 2.7162501192978628],
					[60.567322356037877, 2.7161807241949472],
					[60.567322356930291, 2.7161807240210649],
					[60.567322357737105, 2.7161807220340894],
					[60.567322358629532, 2.7161807218602045],
					[60.567366855915054, 2.7161223257364631],
					[60.567447352032474, 2.716027929501887],
					[60.567447353731716, 2.7160279273410088],
					[60.567447358022591, 2.7160279228453552],
					[60.567447359636262, 2.7160279188713696],
					[60.567527955145849, 2.7159195268634213],
					[60.567555748181775, 2.7158890358252443],
					[60.567555750687838, 2.7158890316773356],
					[60.56755575659237, 2.7158890232076609],
					[60.567555760076424, 2.7158890206989859],
					[60.567580760028676, 2.7158557198530575],
					[60.567580760028676, 2.7158557198530575],
					[60.567580761727903, 2.7158557176921452],
					[60.567580761727903, 2.7158557176921452],
					[60.567652957231495, 2.7157557245209119],
					[60.56772515096818, 2.7156723311589896],
					[60.567725155259069, 2.7156723266632405],
					[60.567725161970408, 2.7156723162064442],
					[60.567725165368863, 2.7156723118846156],
					[60.567777961056066, 2.715594518829243],
					[60.567850153028999, 2.7155057289968849],
					[60.56788624860777, 2.7154640348010108],
					[60.567922347348301, 2.7154251358414219],
					[60.567922350746748, 2.7154251315195403],
					[60.567922360135299, 2.7154251205409081],
					[60.567922364340582, 2.7154251142319703],
					[60.567991856964639, 2.7153251241761427],
					[60.568083447232858, 2.7152251346536027],
					[60.56808345241614, 2.7152251299838457],
					[60.568083460019857, 2.7152251193529229],
					[60.568083464225126, 2.71522511304393],
					[60.568108454273421, 2.7151890298610413],
					[60.568144540642656, 2.7151529414884403],
					[60.568144544041132, 2.7151529371664926],
					[60.568144550116806, 2.7151529323228014],
					[60.56814455262284, 2.7151529281747586],
					[60.568216849884955, 2.7150668311959012],
					[60.568289047266099, 2.7149890350981982],
					[60.568289047266099, 2.7149890350981982],
					[60.568289049050911, 2.7149890347503556],
					[60.568289049857711, 2.7149890327632882],
					[60.56833344869731, 2.7149390329154626],
					[60.568377946708495, 2.7148890355535218],
					[60.568441843731506, 2.7148223385811234],
					[60.568441844623919, 2.7148223384071963],
					[60.568441847215539, 2.7148223360722596],
					[60.56844184712994, 2.7148223342591065],
					[60.568502942645075, 2.7147557401985556],
					[60.568558438435744, 2.7147029455618492],
					[60.568558440134957, 2.7147029434008254],
					[60.568558442640985, 2.7147029392527076],
					[60.568558445318224, 2.7147029387309125],
					[60.568611245391843, 2.7146473371489597],
					[60.56861124709107, 2.7146473349879279],
					[60.568611252274309, 2.7146473303179839],
					[60.568611253887916, 2.7146473263437927],
					[60.568664047189273, 2.7145834366522932],
					[60.568727937646045, 2.7145223445592732],
					[60.568727942829284, 2.7145223398892928],
					[60.568727952217756, 2.7145223289101295],
					[60.568727957401016, 2.7145223242401433],
					[60.56878074581401, 2.7144557378300855],
					[60.568852933142495, 2.7143918496376731],
					[60.568852939218139, 2.7143918447937079],
					[60.568852951198231, 2.7143918314794417],
					[60.568852956295849, 2.7143918249962611],
					[60.568897352860567, 2.7143362299088185],
					[60.568941848919515, 2.7142862329699691],
					[60.568941849811928, 2.7142862327960215],
					[60.568941852403533, 2.7142862304610058],
					[60.568941852403533, 2.7142862304610058],
					[60.569005750812941, 2.7142112322651433],
					[60.569077941277918, 2.7141307417633032],
					[60.569122332115022, 2.7140918501636033],
					[60.569122335599026, 2.7140918476546059],
					[60.569122343373877, 2.7140918406494436],
					[60.569122346772289, 2.7140918363272539],
					[60.569158446935006, 2.7140529357489878],
					[60.569227941025211, 2.7139779418824928],
					[60.569300136142743, 2.7139112467267403],
					[60.569300139541141, 2.7139112424044933],
					[60.569300147315971, 2.713911235399264],
					[60.569300150714376, 2.7139112310770113],
					[60.569336234820348, 2.7138695499239591],
					[60.569391813644749, 2.7138307640599004],
					[60.569391819720373, 2.7138307592157456],
					[60.569391831871613, 2.7138307495274669],
					[60.569391837947265, 2.7138307446833054],
					[60.569444526324794, 2.7137807558760314],
					[60.569497313262339, 2.7137445652811434],
					[60.569497314069118, 2.7137445632939543],
					[60.569497316746343, 2.7137445627720598],
					[60.569497317638742, 2.7137445625980949],
					[60.569569511704557, 2.7136918653025561],
					[60.569650104642015, 2.7136418691766502],
					[60.569686205111509, 2.7136195701509895],
					[60.569722302254029, 2.7135973723662747],
					[60.569786198194997, 2.7135612735817971],
					[60.56983069474559, 2.7135362762088637],
					[60.56990009167577, 2.7135001770880929],
					[60.569963991867532, 2.7134668768502026],
					[60.56996399365233, 2.7134668765022529],
					[60.569963998028776, 2.7134668738191352],
					[60.569963999813588, 2.7134668734711833],
					[60.570016787184962, 2.7134362812241006],
					[60.570108372946322, 2.7134001854785676],
					[60.570108387946036, 2.7134001788944393],
					[60.570108415096954, 2.7134001626216078],
					[60.570108428226199, 2.7134001545722],
					[60.570125100049829, 2.7133862778703528],
					[60.570180666183965, 2.7133668881529838],
					[60.570180675022421, 2.7133668845999344],
					[60.570180691806947, 2.7133668776677986],
					[60.570180699667368, 2.7133668724754854],
					[60.570233397633622, 2.7133362753043309],
					[60.570269491865318, 2.7133168771677911],
					[60.570341787035112, 2.7132807792604656],
					[60.570413983642823, 2.7132473812809454],
					[60.570413988104868, 2.7132473804110173],
					[60.570413995965303, 2.713247375218613],
					[60.5704140003417, 2.7132473725354322],
					[60.57046679575749, 2.7132168748679795],
					[60.57054728796966, 2.7131751798803312],
					[60.570583383623443, 2.7131584821013379],
					[60.570583387999861, 2.7131584794181234],
					[60.570583394161076, 2.7131584763869192],
					[60.570583397645095, 2.7131584738776944],
					[60.570627894992946, 2.7131334750954266],
					[60.570680685023099, 2.7131057815795732],
					[60.570727875712102, 2.7130862854554918],
					[60.570727879281755, 2.713086284759521],
					[60.570727883572523, 2.7130862802629951],
					[60.570727887142155, 2.7130862795670287],
					[60.570797280435926, 2.7130529843998521],
					[60.570861172765824, 2.713027986381507],
					[60.57086117365823, 2.7130279862075137],
					[60.570861176335463, 2.7130279856855273],
					[60.570861178034647, 2.713027983524257],
					[60.570913968346332, 2.7130057886576613],
					[60.57098615868064, 2.7129834913155939],
					[60.570986162250271, 2.7129834906196075],
					[60.570986166626682, 2.7129834879363353],
					[60.570986170196321, 2.712983487240348],
					[60.57103065378768, 2.7129668938679261],
					[60.571102838174134, 2.712952995350542],
					[60.571102840044581, 2.7129529968158415],
					[60.57110284629141, 2.7129529955978584],
					[60.571102848883022, 2.712952993262562],
					[60.571147236352125, 2.7129418975336312],
					[60.571219422917778, 2.7129334994554606],
					[60.57121942737983, 2.7129334985854596],
					[60.571219432734267, 2.7129334975414632],
					[60.571219437196305, 2.7129334966714658],
					[60.571263926195542, 2.7129251992766146],
					[60.571336115185048, 2.7129195987254033],
					[60.57137221443211, 2.7129168990756249],
					[60.571452814429463, 2.7129113000157385],
					[60.571452814343814, 2.7129112982024073],
					[60.571452815321862, 2.7129112998417391],
					[60.571488915212804, 2.7129084993882602],
					[60.571561115241813, 2.7129029990579885],
					[60.571561119703865, 2.712902998187972],
					[60.571561128713562, 2.7129029982612662],
					[60.571561133175614, 2.7129029973912493],
					[60.571577827351938, 2.7129001980117766],
					[60.571605619973091, 2.7128973995520189],
					[60.571605624435129, 2.712897398682002],
					[60.571605633359205, 2.7128973969419699],
					[60.571605637821229, 2.7128973960719547],
					[60.571677831201583, 2.7128834971463895],
					[60.571722225385095, 2.7128779978294149],
					[60.571722231631959, 2.7128779966113794],
					[60.571722245103707, 2.7128779958146416],
					[60.571722252157329, 2.7128779926092723],
					[60.571794450408177, 2.7128584945463183],
					[60.571838948398899, 2.7128473933090747],
					[60.571838951076138, 2.7128473927870518],
					[60.571838957322989, 2.7128473915690074],
					[60.571838959107794, 2.7128473912209992],
					[60.571919458860407, 2.7128223909709619],
					[60.571919460645233, 2.7128223906229456],
					[60.571919464129209, 2.7128223881135756],
					[60.57191946599967, 2.7128223895789008],
					[60.571975065841052, 2.7128029892027752],
					[60.571975070217469, 2.7128029865193866],
					[60.571975079141545, 2.7128029847792994],
					[60.571975082625528, 2.7128029822699067],
					[60.572036180384544, 2.7127751822713631],
					[60.572108377718372, 2.7127445846484508],
					[60.57210838209479, 2.7127445819650391],
					[60.572108389148383, 2.7127445787596036],
					[60.572108392632387, 2.7127445762502034],
					[60.572161189687868, 2.7127168792923957],
					[60.572241786878891, 2.712677980231097],
					[60.572241790448516, 2.7126779795350404],
					[60.572241795631683, 2.7126779748642211],
					[60.572241799201308, 2.7126779741681668],
					[60.572261198656435, 2.7126668748036957],
					[60.57226120214041, 2.7126668722942786],
					[60.572261208216013, 2.712666867449455],
					[60.57226121089321, 2.7126668669274157],
					[60.572277904171209, 2.7126557718411592],
					[60.572358396386328, 2.7126112746276525],
					[60.572413996169033, 2.7125807742030936],
					[60.572475087297768, 2.7125473801015607],
					[60.572547277672477, 2.712516883494144],
					[60.572547278564876, 2.712516883320125],
					[60.572547281242116, 2.712516882798059],
					[60.572547282134515, 2.7125168826240404],
					[60.57260287784873, 2.7124918838536565],
					[60.572672273145237, 2.7124640860437785],
					[60.572708355308727, 2.7124501925314299],
					[60.572780637991585, 2.7124362965145967],
					[60.572780643346036, 2.7124362954704484],
					[60.57278065405491, 2.7124362933821566],
					[60.572780659323719, 2.7124362905246207],
					[60.572816749276051, 2.7124251936214177],
					[60.572844439439415, 2.7124195962091808],
					[60.572916724956421, 2.7124057983183323],
					[60.572961113434673, 2.7124030003664319],
					[60.573050001819524, 2.7123973991696109],
					[60.573122191364817, 2.7124002006375627],
					[60.573174982170315, 2.7124029985471063],
					[60.573238873627652, 2.712411298084084],
					[60.573238875412478, 2.7124112977360264],
					[60.573283275034868, 2.7124168978167336],
					[60.573347164706917, 2.7124251977440177],
					[60.57337215568726, 2.712430794383943],
					[60.57337215845012, 2.712430795675278],
					[60.573372160234932, 2.7124307953272231],
					[60.573372161127345, 2.7124307951531934],
					[60.573399961139444, 2.7124362960510022],
					[60.573399962031843, 2.7124362958769734],
					[60.57339996479471, 2.7124362971683103],
					[60.573399965687116, 2.7124362969942815],
					[60.573480553436276, 2.7124501948448914],
					[60.573516641325703, 2.7124612902726439],
					[60.57351664595901, 2.7124612930293601],
					[60.573516656839175, 2.712461294567889],
					[60.573516662279275, 2.7124612953371412],
					[60.573588860866458, 2.7124751967774969],
					[60.573644358288888, 2.7124862955827567],
					[60.57370555566942, 2.7125001957138459],
					[60.573705559324665, 2.7125001968311748],
					[60.573705567441991, 2.7125001970783651],
					[60.573705570204844, 2.7125001983697197],
					[60.573761066887073, 2.7125084972641824],
					[60.573822164420626, 2.7125195966824247],
					[60.573822168075914, 2.7125195977997505],
					[60.573822176278838, 2.712519599860399],
					[60.573822179848477, 2.712519599164279],
					[60.573877776931099, 2.7125251991846997],
					[60.573938872832834, 2.7125334988380798],
					[60.573938877380492, 2.7125334997813857],
					[60.57393888630456, 2.712533498041088],
					[60.573938889959841, 2.7125334991584173],
					[60.57399438995953, 2.7125362996953668],
					[60.573994390851944, 2.7125362995213367],
					[60.574055590877798, 2.7125390993947436],
					[60.574055592748273, 2.7125391008601398],
					[60.57405559810271, 2.7125390998159582],
					[60.574055599887522, 2.7125390994678957],
					[60.574111100040653, 2.7125390998261256],
					[60.574163900093609, 2.7125390996453147],
					[60.574163903748868, 2.7125391007626583],
					[60.574163912758593, 2.7125391008358091],
					[60.574163917134982, 2.7125390981521851],
					[60.57422781423675, 2.7125335004797084],
					[60.574280610142978, 2.7125307997451862],
					[60.574280612820232, 2.7125307992230829],
					[60.57428061817464, 2.7125307981788951],
					[60.5742806209375, 2.7125307994702701],
					[60.57433331594229, 2.7125251992615782],
					[60.57438890991375, 2.7125223993917049],
					[60.574388914375788, 2.7125223985215379],
					[60.574388923385499, 2.7125223985946811],
					[60.574388926955109, 2.7125223978985504],
					[60.574450023463633, 2.7125140995366372],
					[60.574505619371358, 2.7125084990567747],
					[60.574566713855049, 2.7125029993852587],
					[60.574622209695654, 2.7125002002671827],
					[60.574622212372866, 2.7125001997450773],
					[60.574622215942505, 2.7125001990489319],
					[60.574622217727317, 2.7125001987008637],
					[60.574683318293921, 2.7124945989628895],
					[60.574683328196031, 2.712494598861988],
					[60.574683346850939, 2.7124945933937301],
					[60.574683355775008, 2.7124945916533663],
					[60.574730656074649, 2.7124807916725349],
					[60.574730684289101, 2.712480778849343],
					[60.574730734042937, 2.7124807453537279],
					[60.574730757367142, 2.7124807243332141],
					[60.574783457009914, 2.7124140234215739],
					[60.574783471153111, 2.7124139987008675],
					[60.57478349293573, 2.71241394503721],
					[60.574783498790325, 2.712413916442336],
					[60.574791899152743, 2.712316717612171],
					[60.57479189918871, 2.7123166993030887],
					[60.574791895691014, 2.7123166633810882],
					[60.574791893135384, 2.7123166474076368],
					[60.574755794226149, 2.7121860525555186],
					[60.574727995032688, 2.7120610575797337],
					[60.574727994861398, 2.7120610539527394],
					[60.574727992819646, 2.7120610488603312],
					[60.574727992648356, 2.7120610452333387],
					[60.574700194256621, 2.7119610508598129],
					[60.57467519535281, 2.7118555544551519],
					[60.574647395080376, 2.7117360545013098],
					[60.574647394016672, 2.7117360510483834],
					[60.574647390911174, 2.7117360425030883],
					[60.574647389761815, 2.7117360372366579],
					[60.574611291709481, 2.711624942294157],
					[60.574575193331313, 2.711491647375166],
					[60.574575192181932, 2.7114916421087556],
					[60.574575189076441, 2.7114916335635009],
					[60.574575187034675, 2.7114916284711694],
					[60.574511287290811, 2.7113221288501141],
					[60.574511285249024, 2.7113221237578053],
					[60.574511280187416, 2.7113221119337356],
					[60.574511277253208, 2.7113221070154969],
					[60.574483488366987, 2.7112693272812844],
					[60.574466893750532, 2.7112027515541914],
					[60.574466891623096, 2.7112027446484008],
					[60.574466886304485, 2.711202727383931],
					[60.574466883284622, 2.7112027206522158],
					[60.574411283324139, 2.7110749200078494],
					[60.574411282260392, 2.7110749165549599],
					[60.574411279326192, 2.7110749116367554],
					[60.574411277370075, 2.7110749083579342],
					[60.574375176781558, 2.7110055088285807],
					[60.574375175717826, 2.7110055053757107],
					[60.574375172697984, 2.7110054986440262],
					[60.574375170827523, 2.7110054971787001],
					[60.574302984170124, 2.7108860169580287],
					[60.574286290962561, 2.7108332404320579],
					[60.574286279861148, 2.7108332151448686],
					[60.574286251754174, 2.7108331730429382],
					[60.57428623636207, 2.7108331522531013],
					[60.574250151118761, 2.7107998669898721],
					[60.574169562792704, 2.7106859834166293],
					[60.574169559944153, 2.7106859803119581],
					[60.574169554161422, 2.7106859722891321],
					[60.574169551312878, 2.7106859691844583],
					[60.574097352389607, 2.7106026697453327],
					[60.574052952874823, 2.7105498702073296],
					[60.574052945307265, 2.7105498625327291],
					[60.574052929279709, 2.7105498473576199],
					[60.57405291992734, 2.7105498400311889],
					[60.573997325096492, 2.7105081447928985],
					[60.573925129244621, 2.7104470472405162],
					[60.573925127374125, 2.7104470457752474],
					[60.573925122655112, 2.7104470412053283],
					[60.573925120870307, 2.7104470415535014],
					[60.573844521049516, 2.7103859402129631],
					[60.573844514631375, 2.7103859378046806],
					[60.573844503408516, 2.710385929013079],
					[60.573844496904677, 2.7103859247913773],
					[60.573819498148367, 2.7103720259425343],
					[60.573800099304208, 2.7103609255968335],
					[60.573800096541319, 2.710360924305661],
					[60.573719496771851, 2.7103165253258528],
					[60.573663997289209, 2.7102859249242797],
					[60.573663992655852, 2.7102859221678521],
					[60.573663986237698, 2.7102859197596212],
					[60.57366398258241, 2.7102859186425485],
					[60.573602882880799, 2.7102581183559664],
					[60.573602880117932, 2.7102581170648099],
					[60.573602873699777, 2.7102581146565869],
					[60.573602871829287, 2.7102581131913412],
					[60.573530672072664, 2.7102304121816276],
					[60.573530666632578, 2.7102304114127462],
					[60.573530655666744, 2.7102304080615451],
					[60.573530651119057, 2.7102304071185741],
					[60.573477855383011, 2.7102165084700043],
					[60.573413957771557, 2.710197008541082],
					[60.573413956879158, 2.7101970087151721],
					[60.573413953223884, 2.710197007598099],
					[60.573413951353416, 2.7101970061328631],
					[60.573361151067381, 2.7101831066347057],
					[60.573361145627295, 2.7101831058658346],
					[60.57336113555386, 2.7101831023405905],
					[60.573361130113746, 2.7101831015717073],
					[60.573288938312885, 2.710172002245359],
					[60.573252845447797, 2.7101637049902947],
					[60.573252837330514, 2.7101637047436977],
					[60.573252821816965, 2.7101637004495926],
					[60.573252813699682, 2.7101637002029952],
					[60.573172221320966, 2.7101581012786577],
					[60.573152827589468, 2.7101554023300434],
					[60.573152821256997, 2.7101554017352676],
					[60.573152806807187, 2.7101554008938908],
					[60.573152799582303, 2.7101554004732051],
					[60.57312499985278, 2.7101553991746323],
					[60.573124998067954, 2.7101553995228156],
					[60.573124993605937, 2.710155400393262],
					[60.573124991821139, 2.710155400741443],
					[60.573055596328501, 2.7101581004396258],
					[60.573019400050782, 2.7101580991739946],
					[60.573019393803968, 2.7101581003926141],
					[60.573019383009466, 2.7101581006682838],
					[60.57301937676263, 2.7101581018869103],
					[60.572947176664769, 2.7101665004209017],
					[60.572947175772349, 2.7101665005949949],
					[60.572947174965627, 2.7101665025824713],
					[60.572902776236909, 2.710172001364187],
					[60.572830577114807, 2.7101804014600681],
					[60.572830576222408, 2.7101804016341542],
					[60.572830575330009, 2.7101804018082394],
					[60.572786075793978, 2.7101859019050023],
					[60.572713881774078, 2.7101942002726007],
					[60.572669387054795, 2.7101969999982916],
					[60.572669385269997, 2.7101970003464597],
					[60.572597197936972, 2.710202599792185],
					[60.572505625144906, 2.7101970015770873],
					[60.572461139303485, 2.7101887037686971],
					[60.57238065214468, 2.7101720068376633],
					[60.572363974215961, 2.7101665143376454],
					[60.57232788443244, 2.7101498184688753],
					[60.572327880777166, 2.7101498173518532],
					[60.572327876143788, 2.7101498145955696],
					[60.572327873380914, 2.7101498133044482],
					[60.572263990042529, 2.710124821077792],
					[60.572219506332402, 2.710097029149023],
					[60.572139017767746, 2.710047038529062],
					[60.572066829074522, 2.7099859475170089],
					[60.572066825333586, 2.7099859445866565],
					[60.572066816959257, 2.7099859389000809],
					[60.572066813132622, 2.7099859341563932],
					[60.572022339188564, 2.7099554523155369],
					[60.57199455871578, 2.7099192782277308],
					[60.571994555781544, 2.7099192733099748],
					[60.571994549192041, 2.7099192672752266],
					[60.571994546257791, 2.7099192623574879],
					[60.571914049356799, 2.709833165447769],
					[60.571822356226555, 2.7097276732724271],
					[60.571805761989516, 2.7097054820920685],
					[60.571786264020602, 2.7096776846780379],
					[60.57178626215012, 2.7096776832129028],
					[60.571786258323478, 2.7096776784692889],
					[60.571786256367311, 2.7096776751908314],
					[60.57169736617341, 2.7095665861247298],
					[60.571644572975345, 2.7094748994646838],
					[60.571644570041101, 2.7094748945470295],
					[60.571644563194532, 2.7094748830725011],
					[60.571644559367876, 2.7094748783289311],
					[60.571572363825155, 2.7093804842461666],
					[60.571500167859917, 2.7092692910210681],
					[60.57150016590375, 2.7092692877426652],
					[60.571500161099038, 2.7092692813599322],
					[60.571500159142843, 2.7092692780815284],
					[60.571455763177859, 2.7092109844415102],
					[60.571408466882559, 2.7091387904938768],
					[60.571408465904476, 2.7091387888546818],
					[60.571408462970197, 2.709138783937084],
					[60.571408461992121, 2.7091387822978863],
					[60.571355766360895, 2.7090665878716873],
					[60.571319570351378, 2.7090081955400431],
					[60.571319569287617, 2.7090081920875422],
					[60.571319566439023, 2.7090081889832818],
					[60.571319565460925, 2.7090081873440957],
					[60.571247365214724, 2.7089026860912484],
					[60.571247362366172, 2.7089026829869973],
					[60.571247355605216, 2.7089026733260204],
					[60.571247351864244, 2.7089026703958821],
					[60.571166857199145, 2.7088081758264],
					[60.571122362021121, 2.7087470826910502],
					[60.571122360064955, 2.7087470794127113],
					[60.571122356238277, 2.7087470746693025],
					[60.571122354282096, 2.7087470713909667],
					[60.571066854382529, 2.7086804724742009],
					[60.571066853404446, 2.7086804708350343],
					[60.571066851533963, 2.7086804693699684],
					[60.571066850641564, 2.7086804695440847],
					[60.571014051939919, 2.7086192693483055],
					[60.570969552634018, 2.7085665717371401],
					[60.570969551655935, 2.7085665700979824],
					[60.570969549785453, 2.7085665686329277],
					[60.570969548807355, 2.7085665669937731],
					[60.570897348707717, 2.7084859666286354],
					[60.570897348707717, 2.7084859666286354],
					[60.570825154427027, 2.7084054716125374],
					[60.570797358985615, 2.7083692773888153],
					[60.570797354266531, 2.7083692728196369],
					[60.570797345720777, 2.7083692635071395],
					[60.570797341001686, 2.7083692589379478],
					[60.570761240799456, 2.7083331589738933],
					[60.570761239821373, 2.7083331573347547],
					[60.570761237950876, 2.7083331558697252],
					[60.570761236972793, 2.708333154230584],
					[60.570672344152676, 2.7082498608468657],
					[60.570589050035075, 2.7081554673383743],
					[60.570589048164578, 2.7081554658733764],
					[60.5705890443379, 2.708155461130112],
					[60.570589040596893, 2.7081554582001215],
					[60.570564057041999, 2.7081304733546339],
					[60.570544568533457, 2.7080998926421449],
					[60.570544564706758, 2.708099887898916],
					[60.570544555989578, 2.7080998749600491],
					[60.570544551184781, 2.7080998685776985],
					[60.570455756079312, 2.707997075437369],
					[60.57038346410959, 2.7078998866922235],
					[60.57032797100009, 2.7078137965602784],
					[60.570255778024027, 2.7076888086810462],
					[60.570219581116483, 2.7076110150673895],
					[60.570219581116483, 2.7076110150673895],
					[60.570219581030777, 2.7076110132541626],
					[60.570219580138364, 2.7076110134283042],
					[60.570150179952023, 2.7074666127706251],
					[60.570150178973911, 2.707466611131546],
					[60.570102985057673, 2.7073721228228669],
					[60.570050189280281, 2.7072221350361394],
					[60.570005788888245, 2.7070916360477342],
					[60.570005788802526, 2.7070916342345033],
					[60.569966890738286, 2.7069777408518543],
					[60.56993079295232, 2.7068499463306579],
					[60.569905794140119, 2.7067610498171115],
					[60.569886294456289, 2.7066805537962946],
					[60.569886294284856, 2.7066805501698821],
					[60.56988629224292, 2.7066805450785876],
					[60.569886292157193, 2.7066805432653651],
					[60.569841892717726, 2.7065305492825238],
					[60.569805795035585, 2.7063777532853184],
					[60.569805794057487, 2.7063777516462699],
					[60.569805792993641, 2.7063777481940394],
					[60.569805792993641, 2.7063777481940394],
					[60.569786293640568, 2.7063055513024139],
					[60.569769595066816, 2.7062332561819749],
					[60.569733495249423, 2.7060721551733664],
					[60.569733494271311, 2.7060721535343499],
					[60.569733493036011, 2.7060721464557291],
					[60.569733491972151, 2.7060721430035177],
					[60.569686296005308, 2.7059138577541941],
					[60.569677997871821, 2.7058499733352357],
					[60.569677995401193, 2.7058499591780563],
					[60.569677986890383, 2.7058499315604085],
					[60.569677981742579, 2.7058499179257787],
					[60.569650189339704, 2.7057888321359367],
					[60.569605793196182, 2.705619349165354],
					[60.569561292671438, 2.7054499492220643],
					[60.569561291350375, 2.7054499403303223],
					[60.569561285224403, 2.7054499250568007],
					[60.569561282118563, 2.7054499165134462],
					[60.56953348971205, 2.7053888349202584],
					[60.56951689481707, 2.7053193532456139],
					[60.569516892775077, 2.70531934815445],
					[60.569516889669224, 2.7053193396111208],
					[60.569516889412021, 2.7053193341715769],
					[60.56946958876059, 2.7051832353426599],
					[60.569433488694131, 2.7050777351860544],
					[60.569433487715983, 2.7050777335470846],
					[60.569433486566389, 2.7050777282817444],
					[60.569433485588242, 2.7050777266427728],
					[60.569380786048669, 2.7049443275046756],
					[60.569380784984787, 2.7049443240525437],
					[60.569380782942787, 2.7049443189614251],
					[60.569380781964647, 2.7049443173224592],
					[60.569325182218662, 2.7048221165404343],
					[60.569325181326256, 2.7048221167146456],
					[60.569325180262375, 2.704822113262519],
					[60.569325180176641, 2.7048221114493494],
					[60.569272380269872, 2.7047138115987353],
					[60.5692723774212, 2.7047138084950411],
					[60.56927237155238, 2.7047137986613099],
					[60.569272370402764, 2.7047137933960097],
					[60.569208469897816, 2.7046109932184992],
					[60.569208466070982, 2.7046109884758778],
					[60.569208457439238, 2.70461097735165],
					[60.5692084526343, 2.7046109709700779],
					[60.56911955914525, 2.7045054783019231],
					[60.569100163738312, 2.7044776862181847],
					[60.569100149409202, 2.7044776688867191],
					[60.569100116460511, 2.7044776387211766],
					[60.56910009686279, 2.704477624248169],
					[60.569075100087431, 2.7044637276802903],
					[60.568991802946108, 2.7044137289840977],
					[60.568991794571566, 2.7044137232994556],
					[60.568991777994007, 2.7044137155564836],
					[60.568991768812843, 2.7044137118592273],
					[60.568938969327569, 2.7043942126349947],
					[60.56893895568443, 2.7043942098088229],
					[60.568938930182945, 2.7043942038080595],
					[60.568938917432178, 2.704394200807672],
					[60.568875016833772, 2.7043887009084493],
					[60.568875004168767, 2.7043886997212092],
					[60.568874977225477, 2.7043887013214807],
					[60.568874963839576, 2.7043887039347587],
					[60.568813863864797, 2.7043998032826426],
					[60.568813854940871, 2.704399805024817],
					[60.568813836286346, 2.7043998104965463],
					[60.568813827448153, 2.7043998140518615],
					[60.568749927349749, 2.7044248144491241],
					[60.568749919403956, 2.7044248178302084],
					[60.568749902705726, 2.7044248265797424],
					[60.568749894759925, 2.704424829960828],
					[60.568669294838628, 2.7044748293225123],
					[60.568669285279533, 2.7044748366782634],
					[60.568669267053735, 2.7044748512155712],
					[60.56866925838704, 2.704474858397127],
					[60.568652665589589, 2.7044915508887075],
					[60.568624875041181, 2.7045137432865967],
					[60.568624868159262, 2.7045137501197045],
					[60.568624852696395, 2.7045137659474556],
					[60.56862484581449, 2.7045137727805617],
					[60.568544246178817, 2.7046109711975568],
					[60.568463745656899, 2.7047081717159376],
					[60.568463740559757, 2.7047081782005535],
					[60.568463732150256, 2.7047081908213597],
					[60.56846372794552, 2.704708197131751],
					[60.568419231178609, 2.7047831938572102],
					[60.568347039198898, 2.7048915806262546],
					[60.568310945366456, 2.7049359736287673],
					[60.568310936871207, 2.7049359844363359],
					[60.56831092272936, 2.7049360091551042],
					[60.568310916104636, 2.7049360214273905],
					[60.568294221742569, 2.7049749074321841],
					[60.568213730736439, 2.7051026920569781],
					[60.568158131643685, 2.7051887916811292],
					[60.568158129944628, 2.7051887938426158],
					[60.568158126632262, 2.7051887999787092],
					[60.568158125825612, 2.705188801966004],
					[60.568097031093856, 2.7052970921552464],
					[60.568024838941874, 2.7053998828160277],
					[60.567972040700099, 2.7054692791116199],
					[60.567972039807714, 2.7054692792857993],
					[60.567972038194398, 2.7054692832603706],
					[60.567972037301999, 2.7054692834345522],
					[60.567916544873917, 2.7055470717814063],
					[60.567863754823556, 2.705602661782458],
					[60.567863753124506, 2.7056026639439192],
					[60.567863749640651, 2.7056026664537187],
					[60.567863748833986, 2.7056026684409851],
					[60.567827655651186, 2.7056442601588673],
					[60.567755469306007, 2.7057109493130267],
					[60.56769428688888, 2.7057609343023565],
					[60.567630510872718, 2.7057970191842897],
					[60.567558224671266, 2.7058276157941452],
					[60.567522156221386, 2.705841502417623],
					[60.567441686242041, 2.7058469992325294],
					[60.567441683650564, 2.705847001568102],
					[60.567441679995255, 2.7058470004517083],
					[60.567441678210479, 2.7058470008000435],
					[60.567416702691176, 2.7058497984228929],
					[60.567397233989439, 2.7058470035112747],
					[60.567316749684558, 2.7058304064817835],
					[60.567272268892147, 2.7058165117612591],
					[60.567200078546897, 2.7057859157936419],
					[60.56713619121517, 2.7057581220436764],
					[60.567075102326136, 2.7057220276164951],
					[60.567075099563212, 2.7057220263259567],
					[60.567075097778442, 2.7057220266742918],
					[60.567075096800295, 2.7057220250354193],
					[60.56699450578563, 2.7056776293536937],
					[60.566966814601741, 2.7056581361258027],
					[60.566966809968321, 2.7056581333705552],
					[60.566966799723339, 2.7056581262212096],
					[60.56696679508989, 2.7056581234659842],
					[60.566930697378119, 2.7056387248274554],
					[60.566866798297724, 2.7056026267098883],
					[60.566822297575776, 2.7055776260082816],
					[60.566822295705265, 2.7055776245435941],
					[60.566822291964208, 2.7055776216142191],
					[60.566822289201298, 2.7055776203237012],
					[60.56675008893977, 2.7055415214319751],
					[60.566750085198699, 2.7055415185026144],
					[60.566750076017556, 2.7055415148052346],
					[60.566750072362261, 2.7055415136888903],
					[60.566677879103892, 2.7055137151919859],
					[60.566625085575595, 2.7054887194362593],
					[60.566625080942153, 2.7054887166810642],
					[60.566625070868611, 2.7054887131578993],
					[60.566625065342762, 2.7054887105768799],
					[60.566552864836595, 2.7054637113910354],
					[60.566552863944196, 2.7054637115652085],
					[60.566552861181286, 2.705463710274691],
					[60.566552860203153, 2.70546370863585],
					[60.566508360213902, 2.7054498092794419],
					[60.566508354773781, 2.7054498085114669],
					[60.566508344700239, 2.7054498049883082],
					[60.56650834104493, 2.7054498038719852],
					[60.566427849143935, 2.7054331053372427],
					[60.566400056898495, 2.7054248084175647],
					[60.566400046910672, 2.7054248067074291],
					[60.566400025956924, 2.705424801648344],
					[60.566400015076717, 2.7054248001123984],
					[60.566363925929849, 2.7054220015935071],
					[60.566302836339617, 2.7054109029069235],
					[60.566302831877643, 2.7054109037777834],
					[60.566302823674626, 2.7054109017193153],
					[60.566302820019317, 2.7054109006029989],
					[60.566275020267192, 2.7054081017170737],
					[60.566275019289058, 2.7054081000782468],
					[60.566275015719484, 2.7054081007749344],
					[60.56627501482707, 2.705408100949108],
					[60.566202815025733, 2.7054026014708636],
					[60.566202813155193, 2.7054026000062135],
					[60.566158313831878, 2.705399799857465],
					[60.56608611475513, 2.7053942014550421],
					[60.566086112884605, 2.7053941999903963],
					[60.566086111992206, 2.7053942001645646],
					[60.566041711942766, 2.7053914995840307],
					[60.566041711050381, 2.7053914997582003],
					[60.566041709265583, 2.7053915001065438],
					[60.566041708373191, 2.7053915002807143],
					[60.565969410175732, 2.7053887005877821],
					[60.565925018968308, 2.705385901314072],
					[60.565861129271539, 2.7053776016707327],
					[60.565808337903043, 2.7053692035516299],
					[60.565736155534999, 2.7053526072745848],
					[60.565680675259586, 2.7053331135785976],
					[60.565619489012626, 2.7053054204222744],
					[60.565547308945888, 2.7052665327480576],
					[60.56551122227922, 2.705238742175136],
					[60.565511221301094, 2.7052387405363332],
					[60.56551121853817, 2.705238739245889],
					[60.565511219430569, 2.7052387390717185],
					[60.565458426895091, 2.7051998442065814],
					[60.565402936334877, 2.7051498520522625],
					[60.565339043009601, 2.7050887599984121],
					[60.565286255629204, 2.7050304736140762],
					[60.56524186166174, 2.7049692830586975],
					[60.565241861575998, 2.7049692812457575],
					[60.565241860683599, 2.7049692814199342],
					[60.565241859791215, 2.7049692815941127],
					[60.565169563661165, 2.7048720859075641],
					[60.565097372362359, 2.7047609976240428],
					[60.565052975939885, 2.7046777058694054],
					[60.565052973983626, 2.704677702591896],
					[60.565052969985324, 2.7046776942239914],
					[60.565052967136666, 2.7046776911206654],
					[60.564969570589732, 2.7045498958593219],
					[60.564936274997692, 2.7044916028740933],
					[60.564889075216563, 2.7044055034764236],
					[60.564889075216563, 2.7044055034764236],
					[60.564844575234829, 2.7043249023855198],
					[60.564844573278549, 2.7043248991080726],
					[60.564844570344142, 2.7043248941918971],
					[60.564844568387883, 2.7043248909144317],
					[60.564808468626886, 2.7042692937473447],
					[60.564736271045071, 2.704149897071157],
					[60.564736268110657, 2.7041498921550189],
					[60.564736262241809, 2.7041498823227212],
					[60.564736259307402, 2.7041498774065618],
					[60.564672358877793, 2.704066577938641],
					[60.564672357007275, 2.7040665764741143],
					[60.564672355050995, 2.7040665731966964],
					[60.564672354072854, 2.7040665715579881],
					[60.564602954053328, 2.7039831713211417],
					[60.564602951204662, 2.7039831682179374],
					[60.564602947377828, 2.703983163476015],
					[60.564602943636757, 2.7039831605470122],
					[60.564530754114699, 2.7039081726295455],
					[60.564502962939457, 2.7038692838298246],
					[60.564502945847359, 2.7038692652106859],
					[60.564502909243281, 2.7038692339336925],
					[60.564502888838881, 2.7038692214500584],
					[60.564447302261748, 2.7038415274426808],
					[60.564394512955118, 2.7038054346970299],
					[60.564394500032869, 2.7038054280714041],
					[60.564394474188354, 2.7038054148201636],
					[60.564394460373691, 2.7038054083687446],
					[60.564341759793436, 2.7037887089399799],
					[60.564341757030498, 2.7037887076496854],
					[60.564341748827466, 2.70378870559174],
					[60.564341745172143, 2.7037887044756652],
					[60.564269445027215, 2.7037720053565897],
					[60.564269434146993, 2.7037720038212614],
					[60.564269410601781, 2.7037720010989896],
					[60.564269399721546, 2.7037719995636618],
					[60.564197199598993, 2.703772000051536],
					[60.564197190589297, 2.7037719999806686],
					[60.564197171763304, 2.7037720018260365],
					[60.564197162839363, 2.7037720035680608],
					[60.56415276268951, 2.7037804036969399],
					[60.564152756442759, 2.7037804049163503],
					[60.564152742250222, 2.7037804095164542],
					[60.564152735111058, 2.7037804109100714],
					[60.564080534976576, 2.703805411201436],
					[60.564080529622196, 2.7038054122466439],
					[60.564080520784032, 2.7038054158015168],
					[60.564080517300205, 2.7038054183111915],
					[60.564044317044967, 2.7038220170862424],
					[60.564044310883951, 2.7038220201185146],
					[60.56404429856196, 2.7038220261830479],
					[60.564044294271504, 2.703822030679802],
					[60.563999894870008, 2.7038498298806237],
					[60.563935995787674, 2.703888728195547],
					[60.563935987120985, 2.7038887353761281],
					[60.563935970594258, 2.703888747750208],
					[60.563935962819968, 2.7038887547565755],
					[60.563891565088745, 2.7039304510939997],
					[60.563819268253262, 2.7039942498439986],
					[60.563819264769442, 2.7039942523536289],
					[60.563819258779922, 2.7039942590115564],
					[60.563819256188495, 2.7039942613470087],
					[60.563749859608464, 2.7040665574881628],
					[60.563713763668162, 2.7040998523914501],
					[60.563677666911666, 2.7041331492053757],
					[60.563585970520322, 2.7042109463717585],
					[60.563585968014628, 2.7042109505200242],
					[60.563585961046961, 2.7042109555392],
					[60.563585957648883, 2.7042109598616313],
					[60.563505458002957, 2.7042915585448042],
					[60.563505456303929, 2.7042915607060083],
					[60.563505451206787, 2.7042915671896481],
					[60.56350544772296, 2.7042915696992185],
					[60.563488759119551, 2.7043109566501204],
					[60.56347207691762, 2.7043248426198399],
					[60.563391592795618, 2.7043831296922374],
					[60.563319309319887, 2.7044220214673831],
					[60.563255524391835, 2.7044526148262049],
					[60.563183242835883, 2.7044776085390612],
					[60.563138863871224, 2.7044887036316467],
					[60.563066677026889, 2.7044970004526743],
					[60.563066671672523, 2.7044970014977192],
					[60.563066660963798, 2.7044970035878095],
					[60.563066655609425, 2.7044970046328571],
					[60.563041686197955, 2.704502597081988],
					[60.563005616041522, 2.7044998014360031],
					[60.562941718752079, 2.704494200343774],
					[60.562888931982386, 2.7044887012324064],
					[60.562825046715602, 2.70447480451362],
					[60.562772255479459, 2.7044609087356397],
					[60.562708362172465, 2.7044415104382442],
					[60.56264446584472, 2.7044192111637786],
					[60.562644463081782, 2.7044192098735085],
					[60.562644455771149, 2.7044192076412936],
					[60.562644452115819, 2.7044192065251971],
					[60.562583362553113, 2.7044026091833797],
					[60.562519472890003, 2.7043776138675573],
					[60.562519470127086, 2.7043776125772929],
					[60.562519463708838, 2.7043776101709351],
					[60.562519460053529, 2.7043776090548444],
					[60.56246676043186, 2.7043609092950702],
					[60.562466757754692, 2.7043609098175896],
					[60.56246675401362, 2.7043609068887218],
					[60.56246675133643, 2.7043609074112438],
					[60.562383351047828, 2.704338706706976],
					[60.562383349177303, 2.704338705242546],
					[60.562383348284904, 2.7043387054167178],
					[60.562350048415269, 2.7043304058014326],
					[60.562350043867546, 2.7043304048595296],
					[60.56235003477213, 2.7043304029757143],
					[60.562350030224422, 2.7043304020337895],
					[60.562294436770841, 2.7043220023995089],
					[60.562258345012978, 2.7043137043631265],
					[60.562258338680479, 2.7043137037695524],
					[60.562258326015474, 2.7043137025824429],
					[60.562258319597234, 2.7043137001761162],
					[60.562230619747695, 2.7043109011670676],
					[60.562230617070504, 2.7043109016895892],
					[60.562230609759858, 2.7043108994574196],
					[60.562230606190283, 2.7043109001541161],
					[60.562141705577751, 2.704308099355456],
					[60.562141702900576, 2.7043080998779758],
					[60.562141696568069, 2.7043080992844235],
					[60.562141693890894, 2.7043080998069393],
					[60.562052794349405, 2.7043109007197752],
					[60.562052789801669, 2.7043108997778802],
					[60.562052782662505, 2.7043109011712607],
					[60.562052779985329, 2.7043109016937747],
					[60.562024980144038, 2.7043137003418014],
					[60.562024975682064, 2.704313701212663],
					[60.562024966758116, 2.7043137029543831],
					[60.562024963188541, 2.7043137036510712],
					[60.561980564595814, 2.7043220031803417],
					[60.561916665540615, 2.7043331024163382],
					[60.561916654025239, 2.7043331064933058],
					[60.561916630016334, 2.7043331130086625],
					[60.561916619393344, 2.7043331169114633],
					[60.561891626621225, 2.7043442132338682],
					[60.561811035237071, 2.7043720114530005],
					[60.561811029882683, 2.7043720124980171],
					[60.561811020152092, 2.704372016226618],
					[60.561811014883467, 2.7043720190843779],
					[60.561763815313228, 2.7043942185038379],
					[60.561763814420843, 2.7043942186780034],
					[60.561763813614178, 2.7043942206649163],
					[60.561763812721793, 2.7043942208390837],
					[60.561694312765283, 2.7044276202404691],
					[60.561694310980492, 2.7044276205888029],
					[60.561694306604238, 2.7044276232723794],
					[60.561694304819468, 2.7044276236207105],
					[60.561622104677717, 2.7044665241872026],
					[60.561622098516686, 2.7044665272191084],
					[60.561622086280394, 2.7044665350956243],
					[60.561622080205105, 2.7044665399402508],
					[60.561577684996394, 2.7044998363344739],
					[60.56150549260073, 2.7045470316940134],
					[60.561469295059048, 2.7045692294045618],
					[60.561469288091352, 2.7045692344233054],
					[60.561469273349353, 2.7045692464476958],
					[60.561469266381664, 2.7045692514664403],
					[60.561441569911402, 2.7045942484497298],
					[60.561352673664331, 2.7046665451521901],
					[60.56135267018049, 2.7046665476615397],
					[60.561352662406144, 2.7046665546671376],
					[60.561352658922296, 2.7046665571764912],
					[60.561272067732837, 2.7047470495293502],
					[60.561235978082223, 2.7047748421893223],
					[60.561235965931644, 2.7047748518783492],
					[60.561235943672443, 2.7047748763462485],
					[60.561235934370501, 2.7047748891382475],
					[60.561219242257096, 2.704799876847686],
					[60.561127653221405, 2.7048998651621781],
					[60.561127652329006, 2.7048998653363276],
					[60.561038752141648, 2.7049970653268911],
					[60.561038741947293, 2.7049970782928905],
					[60.561038724321499, 2.7049971055151603],
					[60.561038716083416, 2.704997121758292],
					[60.561022026833811, 2.7050359958869308],
					[60.560985945445694, 2.705080474476711],
					[60.560985943660889, 2.7050804748249972],
					[60.560985941961839, 2.7050804769859877],
					[60.560985941069454, 2.7050804771601298],
					[60.560913741218961, 2.7051748783302267],
					[60.560913740326548, 2.705174878504371],
					[60.560913738627484, 2.7051748806653486],
					[60.560913738713218, 2.705174882478047],
					[60.56086094198993, 2.7052470783060225],
					[60.56078874463627, 2.7053359734807612],
					[60.560788743829605, 2.7053359754675874],
					[60.560788742937177, 2.7053359756417268],
					[60.560788743022911, 2.7053359774544181],
					[60.560708147093415, 2.7054387717786708],
					[60.560680452011695, 2.7054692663177153],
					[60.560680449420239, 2.7054692686527919],
					[60.560680442538249, 2.7054692754839134],
					[60.560680440032506, 2.7054692796316782],
					[60.560663747245734, 2.7054915700870912],
					[60.560574862238731, 2.7055859557994082],
					[60.56048317139868, 2.705663746908213],
					[60.560483167914811, 2.705663749417397],
					[60.560483161925205, 2.7056637560742942],
					[60.560483159333742, 2.705663758409341],
					[60.560458179080321, 2.7056887376848264],
					[60.560422105180713, 2.7057081248175137],
					[60.560422103395915, 2.7057081251657573],
					[60.560422098127233, 2.7057081280231676],
					[60.560422096342464, 2.7057081283714091],
					[60.560358196206636, 2.7057470293481507],
					[60.560358176281547, 2.7057470460416302],
					[60.560358140172411, 2.7057470823574619],
					[60.560358124966477, 2.7057471036183629],
					[60.559930451120778, 2.7065276564546727],
					[60.55930269937727, 2.7068970278106028],
					[60.559302690624676, 2.7068970331766478],
					[60.559302674904323, 2.7068970435605846],
					[60.559302668022241, 2.7068970503910821],
					[60.558047077338912, 2.7079998424740541],
					[60.557241587254111, 2.7085526353667189],
					[60.557241576802326, 2.7085526428925051],
					[60.557241558575967, 2.7085526574219969],
					[60.557241550887063, 2.7085526662382273],
					[60.556255451384928, 2.709655465145389],
					[60.555091559142255, 2.7109415588058892],
					[60.553658174732789, 2.7122276437425725],
					[60.551594284062915, 2.7137054375178145],
					[60.551594276288036, 2.7137054445190039],
					[60.551594260652656, 2.713705456709163],
					[60.551594253770176, 2.7137054635365017],
					[60.549713812041126, 2.7157248006291734],
					[60.548366697931186, 2.7157358994632776],
					[60.54836667821246, 2.715735901473975],
					[60.548366638139342, 2.7157359111052344],
					[60.548366617699358, 2.7157359169137596],
					[60.547119355988947, 2.7162941998523444],
					[60.54577503137056, 2.7163054008284995],
					[60.544697262876667, 2.7159498105584357],
					[60.544697255566206, 2.7159498083245892],
					[60.544697242815637, 2.7159498053213067],
					[60.544697236397596, 2.7159498029137459],
					[60.542819435769289, 2.7156026024007223],
					[60.542819422211906, 2.7156026013831509],
					[60.542819393397863, 2.7156026015071646],
					[60.54281937805564, 2.7156026008370455],
					[60.541022177782644, 2.7157998016906939],
					[60.541022175912246, 2.7157998002264812],
					[60.539408275959616, 2.7159970001245637],
					[60.53940823444384, 2.7159970173456616],
					[60.539408160128538, 2.7159970647169889],
					[60.539408129199408, 2.7159970963313342],
					[60.538960958014819, 2.7167304472481737],
					[60.537166607958319, 2.7176554226790919],
					[60.537166586161064, 2.7176554378883044],
					[60.537166545671482, 2.7176554768429391],
					[60.537166527700514, 2.7176554967918833],
					[60.536183139632783, 2.7193026767134802],
					[60.535285976811544, 2.7202192397682734],
					[60.534299901694403, 2.7207748256110773],
					[60.534299885165773, 2.7207748379643437],
					[60.534299853171966, 2.7207748661199012],
					[60.534299838599182, 2.7207748817486541],
					[60.533763739349467, 2.7215081819995004],
					[60.533763734251387, 2.7215081884742172],
					[60.533763725118561, 2.7215082048726198],
					[60.533763720020438, 2.7215082113473419],
					[60.533047020362595, 2.7229693126685515],
					[60.53304701866319, 2.7229693148266629],
					[60.533047016242307, 2.7229693207806456],
					[60.533047014628366, 2.7229693247499585],
					[60.532599826877501, 2.7240665957353301],
					[60.531974847749417, 2.7247998700151275],
					[60.531974839337714, 2.7247998826159914],
					[60.531974824213741, 2.7247999056597778],
					[60.531974817586914, 2.7247999179138667],
					[60.53090262388902, 2.7271749043714388],
					[60.52983593234633, 2.7288220907541927],
					[60.529835923212552, 2.7288221071485199],
					[60.529835908685527, 2.7288221428662207],
					[60.529835904184708, 2.7288221620162911],
					[60.529480404387151, 2.7306443625611956],
					[60.529480402602275, 2.7306443629076278],
					[60.529035903549506, 2.7330138609390415],
					[60.52841370374982, 2.7359305573193282],
					[60.527791510575646, 2.7388471288176484],
					[60.526985925045501, 2.7403110036351213],
					[60.52698592011685, 2.740311013727359],
					[60.526985911237261, 2.7403110355498264],
					[60.52698590809355, 2.7403110452961963],
					[60.526102609585209, 2.7434110372966112],
					[60.525299812112905, 2.7455999303576006],
					[60.52529980595402, 2.7455999717513189],
				],
				[
					[60.509183101195873, 2.7275582856495939],
					[60.509183102181872, 2.7275583256403246],
					[60.509183104820579, 2.7275583433929094],
					[60.509219204601131, 2.7277194444154045],
					[60.509219212850638, 2.7277194665587765],
					[60.509219232833971, 2.7277195083429877],
					[60.509219246352728, 2.7277195276375292],
					[60.509274846203041, 2.7277862275700153],
					[60.509274856617566, 2.7277862383345961],
					[60.509274881016388, 2.7277862591711437],
					[60.509274894108223, 2.7277862694162844],
					[60.509319294391666, 2.7278140708193224],
					[60.509319299024646, 2.727814073573362],
					[60.509319310967904, 2.7278140785619778],
					[60.509319315600877, 2.727814081316005],
					[60.509355516296992, 2.7278307815695171],
					[60.509355523692648, 2.7278307856140098],
					[60.509355540183506, 2.7278307915467677],
					[60.509355548386246, 2.7278307936081903],
					[60.509427747716487, 2.7278501933503949],
					[60.509427753156515, 2.7278501941213835],
					[60.509427764121952, 2.7278501974732765],
					[60.509427768669532, 2.7278501984174137],
					[60.509480569387883, 2.7278584981336342],
					[60.509480574827897, 2.7278584989046242],
					[60.509480585622576, 2.7278584986366998],
					[60.509480591955061, 2.7278584992345514],
					[60.509552791781431, 2.7278612999785135],
					[60.509552799898785, 2.7278613002300429],
					[60.509552815155658, 2.7278612990963733],
					[60.509552823273012, 2.7278612993479041],
					[60.509625022672132, 2.7278529988262394],
					[60.509625031596542, 2.7278529970947023],
					[60.509625051230259, 2.7278529932853242],
					[60.509625060154676, 2.7278529915537866],
					[60.50967786040421, 2.7278362904841895],
					[60.509677870135675, 2.7278362867695756],
					[60.50967789040569, 2.7278362773572615],
					[60.50967790013717, 2.7278362736426458],
					[60.509750100045231, 2.7277945737552782],
					[60.50975010093768, 2.7277945735821199],
					[60.509750101744743, 2.7277945715990275],
					[60.509750102637177, 2.7277945714258753],
					[60.509777903139288, 2.7277779719086603],
					[60.509777908323173, 2.7277779672498803],
					[60.509777918776315, 2.7277779597422365],
					[60.509777924938042, 2.7277779567202005],
					[60.509805725059877, 2.727755755397455],
					[60.5098057294367, 2.7277557527217473],
					[60.509805736405461, 2.7277557477166274],
					[60.509805738912029, 2.7277557435773083],
					[60.509894538920769, 2.7276695436049154],
					[60.509894543212205, 2.7276695391192605],
					[60.50989454920316, 2.7276695324773366],
					[60.509894551795092, 2.7276695301479301],
					[60.509975152157779, 2.7275751306383316],
					[60.509975152964827, 2.7275751286552397],
					[60.509975153771883, 2.7275751266721433],
					[60.509975154664332, 2.7275751264989783],
					[60.510000150576481, 2.7275445318869576],
					[60.510036245966575, 2.7275057359675068],
					[60.510036251150453, 2.7275057313086406],
					[60.510036257948435, 2.72750572268356],
					[60.510036262154486, 2.7275057163879111],
					[60.510108462119753, 2.7274057167071954],
					[60.510108463819243, 2.7274057145509216],
					[60.510108467218252, 2.7274057102383473],
					[60.510108468832357, 2.7274057062721342],
					[60.510161265821019, 2.7273223115305654],
					[60.510233462322446, 2.7272223171682883],
					[60.510233463129495, 2.727222315185152],
					[60.510233464829, 2.7272223130288551],
					[60.510233465636048, 2.7272223110457374],
					[60.510305765654124, 2.727114012856712],
					[60.510341860715485, 2.7270612194690025],
					[60.510394557407707, 2.7269945246341822],
					[60.510394558214756, 2.7269945226510477],
					[60.510394560721288, 2.7269945185115962],
					[60.510394563313213, 2.7269945161820974],
					[60.510450163050422, 2.7269168154930639],
					[60.510450165556954, 2.7269168113535822],
					[60.510450170570046, 2.7269168030746478],
					[60.510450173969012, 2.7269167987619811],
					[60.510483462920881, 2.7268584177849982],
					[60.510566848011308, 2.7267668347714236],
					[60.510566848011308, 2.7267668347714236],
					[60.510566849710791, 2.7267668326150822],
					[60.510566849710791, 2.7267668326150822],
					[60.510647337215971, 2.7266751483983889],
					[60.510680720068464, 2.7266501596886012],
					[60.510680729543708, 2.7266501505435987],
					[60.510680749386637, 2.7266501320803633],
					[60.510680757969439, 2.7266501231085387],
					[60.510700147277362, 2.726625137642162],
					[60.510780727086257, 2.7265529532460757],
					[60.510861221335354, 2.7264918594701806],
					[60.510861225626762, 2.726491854984209],
					[60.510861235994419, 2.7264918456658909],
					[60.510861241178276, 2.7264918410067378],
					[60.510897323017296, 2.7264557596657579],
					[60.510969499812326, 2.7264140721596593],
					[60.510969507758894, 2.7264140687908522],
					[60.510969521696325, 2.7264140587796613],
					[60.510969528665022, 2.7264140537740795],
					[60.511005721687646, 2.7263834595466441],
					[60.511041808087846, 2.7263584697461973],
					[60.511122301465171, 2.7263112734678288],
					[60.51112230316464, 2.726311271311427],
					[60.511122305841958, 2.7263112707918071],
					[60.511122306649007, 2.7263112688086086],
					[60.511183399824411, 2.7262723729327445],
					[60.511247292410332, 2.7262390763494655],
					[60.511247293302759, 2.7262390761762596],
					[60.51124729598007, 2.7262390756566304],
					[60.511247296872511, 2.7262390754834227],
					[60.511327886213223, 2.726194581548854],
					[60.511355674284857, 2.7261834856352141],
					[60.51135568839306, 2.7261834792438209],
					[60.51135571465381, 2.7261834631874309],
					[60.511355727698749, 2.7261834533492286],
					[60.511372318077981, 2.7261695624541193],
					[60.511452904667358, 2.7261195706566492],
					[60.511452907259255, 2.7261195683270003],
					[60.511452910743621, 2.7261195658241331],
					[60.511452913420932, 2.7261195653044892],
					[60.511505708902661, 2.7260834682520847],
					[60.511577905428439, 2.726039070011343],
					[60.511577906320881, 2.7260390698381256],
					[60.51157790802035, 2.7260390676816599],
					[60.511577909805226, 2.7260390673352255],
					[60.511658409811041, 2.7259862677380124],
					[60.511658414187814, 2.7259862650618993],
					[60.511658421071104, 2.7259862582460701],
					[60.511658424555456, 2.7259862557431713],
					[60.511686215028547, 2.7259640642757494],
					[60.51172230536573, 2.7259418691871113],
					[60.511722308043048, 2.7259418686674493],
					[60.511722315011731, 2.7259418636616362],
					[60.511722317603642, 2.7259418613319348],
					[60.511794518416544, 2.7258890610409696],
					[60.511794521900875, 2.7258890585380531],
					[60.511794529676592, 2.725889051548966],
					[60.511794533160959, 2.7258890490460455],
					[60.511847322141541, 2.7258418594485097],
					[60.511919509922727, 2.7257945666525654],
					[60.511919513407065, 2.7257945641496297],
					[60.511919521353605, 2.725794560780558],
					[60.511919523860087, 2.7257945566407971],
					[60.512000124346883, 2.7257307564968256],
					[60.512000128723656, 2.725730753820645],
					[60.512000137306387, 2.725730744848192],
					[60.512000140705332, 2.7257307405352003],
					[60.5120168296693, 2.7257140535043654],
					[60.512036211193241, 2.7257001664304057],
					[60.512116795063783, 2.7256501764873851],
					[60.512188979634253, 2.7256168827382097],
					[60.512250066781348, 2.7255918896159543],
					[60.512313947700591, 2.7255723947679011],
					[60.512358319380603, 2.7255640993899828],
					[60.512402795256691, 2.7255641004619418],
					[60.512458274444704, 2.725566899378657],
					[60.512511053326079, 2.7255779948622312],
					[60.512583236689039, 2.7255973905152899],
					[60.512647125757006, 2.7256223857175219],
					[60.512708216650758, 2.7256473816616733],
					[60.512780504333442, 2.7256834749345358],
					[60.512808197039547, 2.7257001709528721],
					[60.512808207198006, 2.725700176287611],
					[60.5128082292998, 2.7257001866106569],
					[60.512808240350687, 2.7257001917721646],
					[60.512852730621177, 2.7257140874861623],
					[60.51290552171762, 2.7257362834369907],
					[60.512905530005803, 2.7257362873081568],
					[60.512905546411332, 2.7257362914302758],
					[60.512905553807045, 2.7257362954746802],
					[60.512952753550174, 2.7257473947694608],
					[60.512952758990238, 2.7257473955401368],
					[60.512952768085412, 2.7257473974279893],
					[60.512952772633021, 2.7257473983719023],
					[60.513033273419353, 2.7257584980864729],
					[60.513033277074513, 2.7257584992036445],
					[60.513033286976729, 2.7257584991081538],
					[60.513033292416786, 2.7257584998788342],
					[60.513102792335893, 2.7257612992365767],
					[60.513102804108406, 2.7257613006047099],
					[60.513102827397205, 2.7257612979106791],
					[60.513102838998876, 2.7257612956586192],
					[60.513158338687212, 2.7257501953299048],
					[60.513158345912132, 2.7257501957541153],
					[60.513158359213264, 2.7257501913454711],
					[60.513158365374942, 2.7257501883226927],
					[60.513230663960137, 2.7257251883646298],
					[60.51325566336709, 2.72571689040501],
					[60.513255673098492, 2.7257168866892854],
					[60.513255693282908, 2.7257168754643653],
					[60.513255703014309, 2.725716871748642],
					[60.513283403133045, 2.7257001709951121],
					[60.513283405810334, 2.7257001704754003],
					[60.513283410994156, 2.7257001658157294],
					[60.513283413586052, 2.7257001634859068],
					[60.513355714169862, 2.7256501645759519],
					[60.513355715869324, 2.7256501624193601],
					[60.513355720246118, 2.72565015974304],
					[60.513355721945565, 2.7256501575864323],
					[60.513416822179018, 2.7256029574132197],
					[60.513416824856321, 2.725602956893495],
					[60.51341683173959, 2.7256029500771901],
					[60.513416835223907, 2.7256029475741088],
					[60.513480734774411, 2.7255445486355692],
					[60.513480739872797, 2.7255445421657436],
					[60.513480750240376, 2.7255445328463184],
					[60.513480753553893, 2.725544526722965],
					[60.513533448058404, 2.7254807347005374],
					[60.513589041189562, 2.7254251406507857],
					[60.513589043781458, 2.7254251383208992],
					[60.513589048158238, 2.7254251356445316],
					[60.513589049772264, 2.7254251316777882],
					[60.513650149836735, 2.7253557325116673],
					[60.513650153235631, 2.7253557281983949],
					[60.513650159226451, 2.7253557215552555],
					[60.513650161732926, 2.725355717415229],
					[60.513694557171291, 2.7252945241087527],
					[60.513741852200489, 2.7252390297013118],
					[60.513741854706957, 2.7252390255612826],
					[60.51374185989075, 2.7252390209014874],
					[60.51374186328966, 2.725239016588187],
					[60.513811261056091, 2.7251418197289468],
					[60.5138918585547, 2.7250362207605225],
					[60.513927958607063, 2.7249890222616311],
					[60.513927960221082, 2.7249890182948042],
					[60.51392796370542, 2.7249890157916092],
					[60.513927965404854, 2.7249890136349442],
					[60.513964059111743, 2.724936222804625],
					[60.514036250982222, 2.7248529315697736],
					[60.514036252681677, 2.7248529294130885],
					[60.514036256887579, 2.7248529231162908],
					[60.514036258587026, 2.7248529209596106],
					[60.514089059029175, 2.7247834212286679],
					[60.514089060728622, 2.7247834190719797],
					[60.514089064127511, 2.7247834147586021],
					[60.514089065826944, 2.7247834126019117],
					[60.51415295960679, 2.7246890194967128],
					[60.514241854957334, 2.7245807274516931],
					[60.514241858270815, 2.7245807213280924],
					[60.514241865068577, 2.7245807127012327],
					[60.514241869274478, 2.7245807064043763],
					[60.51426126483085, 2.7245501147261595],
					[60.514286257265489, 2.7245168232803527],
					[60.51436685276088, 2.7244195295305254],
					[60.514439051242206, 2.7243362310640111],
					[60.514439054641073, 2.7243362267505398],
					[60.51443906143885, 2.7243362181235722],
					[60.514439063945275, 2.7243362139833671],
					[60.514483456098922, 2.7242723256384096],
					[60.514547347124918, 2.7242029355823489],
					[60.514547347931924, 2.7242029335988844],
					[60.514600147871157, 2.7241445348083859],
					[60.51460015216248, 2.7241445303215595],
					[60.514600158960199, 2.7241445216945275],
					[60.514600162359081, 2.7241445173809931],
					[60.5146362564603, 2.7240945266060548],
					[60.514716847636926, 2.7240057333060492],
					[60.514716851121214, 2.7240057308026886],
					[60.514716856219515, 2.7240057243323492],
					[60.514716858725969, 2.724005720192094],
					[60.514797343849004, 2.7239001406376158],
					[60.514825125412877, 2.7238779558653028],
					[60.514825135780328, 2.7238779465449543],
					[60.514825157151378, 2.7238779223003529],
					[60.514825165648539, 2.7238779115163982],
					[60.514841861459644, 2.7238529197109131],
					[60.514930755098952, 2.723744526354885],
					[60.514930755991386, 2.7237445261815791],
					[60.514930757605384, 2.7237445222145649],
					[60.514930759390232, 2.7237445218679612],
					[60.515002958638512, 2.7236501223843317],
					[60.515002960337945, 2.7236501202275183],
					[60.515002961144944, 2.7236501182439965],
					[60.515002961144944, 2.7236501182439965],
					[60.515055760702019, 2.7235779199286529],
					[60.515127960916224, 2.7234807190008246],
					[60.515127962615658, 2.7234807168439943],
					[60.515127966736038, 2.7234807087365982],
					[60.515127970220334, 2.723480706233143],
					[60.515155768476781, 2.7234362086967523],
					[60.515183464816488, 2.7233945130951254],
					[60.515264063266756, 2.7232807151073222],
					[60.51526406407374, 2.7232807131237773],
					[60.515264065858602, 2.7232807127771372],
					[60.51526406577316, 2.7232807109669261],
					[60.515325160213216, 2.7231890214304633],
					[60.515389052066013, 2.7231140297164149],
					[60.51546125079183, 2.7230307308724853],
					[60.515461257589486, 2.7230307222449652],
					[60.515461271099376, 2.7230307031797003],
					[60.515461276919154, 2.723030692915287],
					[60.51548625796417, 2.7229834296266393],
					[60.515530725943755, 2.7229473541116009],
					[60.515530728621059, 2.7229473535916089],
					[60.515530736396599, 2.7229473466009471],
					[60.515530738988431, 2.7229473442707337],
					[60.51560293462709, 2.7228779474186031],
					[60.515666829673961, 2.7228223528960762],
					[60.515719516828334, 2.7227779632633564],
					[60.515791804535567, 2.7227334694490088],
					[60.515827905109084, 2.7227112692326063],
					[60.515827906001512, 2.7227112690592685],
					[60.515827910378221, 2.7227112663823347],
					[60.515827911270648, 2.7227112662089943],
					[60.515852900630989, 2.7226945727049778],
					[60.515936189310899, 2.7226529783467699],
					[60.515936191988189, 2.7226529778267494],
					[60.515936198149767, 2.7226529748031139],
					[60.515936199849158, 2.7226529726461863],
					[60.515988987155325, 2.7226223811777119],
					[60.516061171591495, 2.7225945876703292],
					[60.516061177753066, 2.7225945846466701],
					[60.516061190076194, 2.7225945785993382],
					[60.516061196237779, 2.7225945755756773],
					[60.516122284778405, 2.7225612808223358],
					[60.516177870399879, 2.7225390861209613],
					[60.516258364653076, 2.7225112894204715],
					[60.516258369115228, 2.7225112885537377],
					[60.516258376976182, 2.7225112833730849],
					[60.516258380545914, 2.7225112826796951],
					[60.516283369427427, 2.7225001882165123],
					[60.516311157099921, 2.7224918914341023],
					[60.516311157992341, 2.7224918912607565],
					[60.516311158884768, 2.7224918910874099],
					[60.516383358578153, 2.7224695910607717],
					[60.516419451891274, 2.7224584934277263],
					[60.516491743337987, 2.7224418953730436],
					[60.516544437586489, 2.7224307956143936],
					[60.516608328170307, 2.722419598127257],
					[60.516661119295193, 2.7224140989732475],
					[60.516725013895744, 2.7224085000444078],
					[60.516777804755385, 2.7224057996964399],
					[60.516833299782036, 2.722405800354863],
					[60.516902794818201, 2.7224057999380293],
					[60.516958290320517, 2.7224085006579415],
					[60.516958291212958, 2.7224085004845837],
					[60.517019384177289, 2.7224112995612537],
					[60.517066675890149, 2.7224168982975963],
					[60.517066677760454, 2.7224168997611953],
					[60.51706668222262, 2.7224168988944175],
					[60.517066684007482, 2.7224168985477064],
					[60.517136080233293, 2.7224223980661044],
					[60.5171832747087, 2.7224279974476748],
					[60.517244373410179, 2.7224362981296903],
					[60.517244375195041, 2.7224362977829784],
					[60.517244377957802, 2.7224362990732249],
					[60.517244379742635, 2.7224362987265169],
					[60.517299979680644, 2.7224418986310122],
					[60.517299980573071, 2.7224418984576579],
					[60.517299984228238, 2.7224418995745725],
					[60.517299985120687, 2.7224418994012138],
					[60.517372184951675, 2.722447398800099],
					[60.517372186822008, 2.7224474002637113],
					[60.517416685008975, 2.7224501984462099],
					[60.51747778234153, 2.7224557992583387],
					[60.517477783233964, 2.7224557990849809],
					[60.517477785996725, 2.7224558003752377],
					[60.517477787781587, 2.7224558000285235],
					[60.517522187686147, 2.7224585003904127],
					[60.517522188578575, 2.722458500217054],
					[60.517522192148313, 2.7224584995236216],
					[60.51752219304074, 2.7224584993502634],
					[60.517605593432123, 2.7224613005376188],
					[60.517605595216999, 2.7224613001909024],
					[60.517605597894288, 2.722461299670826],
					[60.51760559967915, 2.7224612993241104],
					[60.517638899941254, 2.7224612998246829],
					[60.51763890172613, 2.7224612994779638],
					[60.517638906273724, 2.7224613004215299],
					[60.517638908058586, 2.7224613000748139],
					[60.517711104285475, 2.7224585004007391],
					[60.517738899927544, 2.722458499598563],
					[60.517766700117178, 2.7224584997384689],
					[60.517766703772374, 2.7224585008553941],
					[60.517766710911829, 2.7224584994685173],
					[60.517766715373966, 2.7224584986017235],
					[60.517838914778899, 2.7224529998615261],
					[60.51787501516894, 2.7224501982765812],
					[60.517875017931679, 2.7224501995668544],
					[60.517875022393838, 2.7224501987000562],
					[60.517875024178686, 2.7224501983533362],
					[60.517944423723968, 2.7224418982863501],
					[60.517991723627688, 2.7224362999146448],
					[60.518061124063109, 2.7224279995991991],
					[60.518061124870094, 2.7224279976154695],
					[60.518061127632841, 2.7224279989057476],
					[60.51806113031013, 2.7224279983856641],
					[60.518116730411769, 2.7224195982156054],
					[60.518116733089045, 2.7224195976955188],
					[60.518116741120927, 2.7224195961352522],
					[60.518116743798217, 2.7224195956151656],
					[60.518177836768395, 2.722405796608133],
					[60.518233329813263, 2.7223973988839729],
					[60.518233336060284, 2.7223973976704201],
					[60.518233346683992, 2.7223973937796764],
					[60.518233352038585, 2.722397392739488],
					[60.518294444373623, 2.7223807948139278],
					[60.51835833493584, 2.7223695964102173],
					[60.518358339397977, 2.7223695955433902],
					[60.518358347429874, 2.7223695939830961],
					[60.518358350999584, 2.7223695932896308],
					[60.518411151078155, 2.7223557936578366],
					[60.518475051234233, 2.722339094039691],
					[60.518475053019095, 2.7223390936929559],
					[60.518475057395769, 2.7223390910157357],
					[60.518475060073094, 2.7223390904956268],
					[60.518519456316888, 2.7223251913343045],
					[60.51859175073934, 2.7223057938947788],
					[60.518644450569305, 2.7222918929271822],
					[60.518644453246594, 2.7222918924070738],
					[60.518644456816311, 2.7222918917135948],
					[60.518644458601173, 2.722291891366857],
					[60.51871675205836, 2.7222695926617932],
					[60.518752845161522, 2.7222612946829905],
					[60.518752848731246, 2.7222612939895008],
					[60.518752855870687, 2.7222612926025294],
					[60.518752860332846, 2.7222612917356708],
					[60.518797260134107, 2.7222473916317931],
					[60.518797261833527, 2.7222473894746373],
					[60.518797267188106, 2.7222473884344067],
					[60.518797269865395, 2.722247387914289],
					[60.5188417647203, 2.7222307890739215],
					[60.518877858731038, 2.7222195913613003],
					[60.518958358041061, 2.7221945909514478],
					[60.5190139565983, 2.7221779924964831],
					[60.51901396016801, 2.7221779918029774],
					[60.519013966329574, 2.7221779887789315],
					[60.519013968114422, 2.72217798843218],
					[60.519075062617866, 2.7221557893374655],
					[60.519138957635846, 2.722136290883165],
					[60.519138960313136, 2.7221362903630322],
					[60.51913896388286, 2.7221362896695203],
					[60.519138965667722, 2.7221362893227634],
					[60.519202866443656, 2.7221140893085645],
					[60.519202868143033, 2.7221140871513811],
					[60.519263962716842, 2.7220918895955419],
					[60.519319458425045, 2.7220751919959274],
					[60.519319461909305, 2.7220751894919677],
					[60.519319469855716, 2.7220751861211068],
					[60.519319474317868, 2.7220751852542051],
					[60.51938895959205, 2.7220473908657636],
					[60.519436145927827, 2.7220362959140707],
					[60.519436149412073, 2.7220362934100995],
					[60.519436156551528, 2.7220362920230374],
					[60.519436161013672, 2.722036291156126],
					[60.51949726067145, 2.7220168913171436],
					[60.51949726415571, 2.7220168888131653],
					[60.519497271295137, 2.7220168874260984],
					[60.519497273972441, 2.7220168869059456],
					[60.519525056573627, 2.7220057924742349],
					[60.519552839323993, 2.7220001955975612],
					[60.519552840216427, 2.722000195424175],
					[60.519552841108855, 2.722000195250792],
					[60.519633341407378, 2.7219834956219335],
					[60.519633343192247, 2.7219834952751616],
					[60.519633345869543, 2.7219834947550048],
					[60.519633347654413, 2.7219834944082306],
					[60.519677838322991, 2.7219723967917431],
					[60.519738924402276, 2.7219640987109761],
					[60.519794413576307, 2.7219584994706572],
					[60.519866700099747, 2.7219557994014236],
					[60.519947184136093, 2.7219585006774718],
					[60.519991668597214, 2.7219640981154458],
					[60.520063862406083, 2.7219779968042657],
					[60.520063863298525, 2.7219779966308768],
					[60.520108259128001, 2.7219862953142826],
					[60.520180546351931, 2.7220029933088306],
					[60.520197136726424, 2.7220084906892845],
					[60.520197137618851, 2.7220084905158961],
					[60.520197141274032, 2.72200849163284],
					[60.52019714216646, 2.722008491459452],
					[60.52022494167808, 2.7220168903535487],
					[60.520224945333275, 2.7220168914704757],
					[60.520224949966341, 2.7220168942245198],
					[60.520224952643623, 2.7220168937043527],
					[60.520305546790702, 2.7220362934727191],
					[60.520349940329652, 2.722050190953297],
					[60.520349942114514, 2.7220501906065162],
					[60.520349945855152, 2.7220501935339447],
					[60.520349947640042, 2.7220501931871626],
					[60.520422148011221, 2.7220695923041545],
					[60.520422154429177, 2.7220695947114377],
					[60.52042216530927, 2.7220695962517629],
					[60.520422169856879, 2.7220695971953135],
					[60.520477758751383, 2.722077996128653],
					[60.520530548652104, 2.7220918921901851],
					[60.520530554177604, 2.7220918947708737],
					[60.52053056416527, 2.7220918964845957],
					[60.520530568712921, 2.7220918974281463],
					[60.520583269191121, 2.7221001980156236],
					[60.520583272760838, 2.7221001973220664],
					[60.520583280963642, 2.7221001993825729],
					[60.520583285425793, 2.722100198515629],
					[60.520655584599226, 2.7221057985467305],
					[60.520655585577117, 2.7221058001838503],
					[60.520655589146834, 2.7221057994902957],
					[60.520655590039262, 2.7221057993169082],
					[60.520711090079466, 2.7221085003830652],
					[60.520711094541603, 2.7221084995161169],
					[60.520711103551378, 2.7221084995927285],
					[60.520711108991442, 2.7221085003629004],
					[60.520772208934822, 2.7221057995917435],
					[60.520772214289387, 2.7221057985514046],
					[60.520772225084023, 2.7221057982812331],
					[60.520772229546168, 2.7221057974142848],
					[60.520827829684862, 2.7220973975118588],
					[60.520827830577289, 2.7220973973384681],
					[60.520880630918334, 2.7220890973853802],
					[60.520880635380486, 2.7220890965184203],
					[60.520880641627485, 2.7220890953046819],
					[60.520880645197202, 2.722089094611118],
					[60.520952844581331, 2.7220723955879351],
					[60.52095285074288, 2.7220723925636476],
					[60.520952863236886, 2.7220723901361534],
					[60.52095286939845, 2.722072387111881],
					[60.521005669023715, 2.7220529873611796],
					[60.521005669916129, 2.7220529871877877],
					[60.521005670808563, 2.7220529870143917],
					[60.521005671701005, 2.7220529868409984],
					[60.521077872147323, 2.7220251875720982],
					[60.521077872061859, 2.7220251857615669],
					[60.521077873846721, 2.7220251854147763],
					[60.52110567420101, 2.7220140850862005],
					[60.521105678577719, 2.7220140824086858],
					[60.521105690093833, 2.7220140783440185],
					[60.521105695362934, 2.7220140754931013],
					[60.521141794699467, 2.7219945766717046],
					[60.521141795591909, 2.7219945764983069],
					[60.521202895640897, 2.7219612761691647],
					[60.521202895555433, 2.7219612743586254],
					[60.521202898318172, 2.7219612756489715],
					[60.521202898232708, 2.721961273838434],
					[60.521247298228303, 2.7219362739077928],
					[60.521247301712542, 2.7219362714036608],
					[60.521247309573468, 2.721936266221995],
					[60.52124731395017, 2.7219362635444444],
					[60.521319510316694, 2.7218862664681764],
					[60.521400104979755, 2.7218362708179895],
					[60.521400106679152, 2.7218362686606365],
					[60.521400111055826, 2.721836265983081],
					[60.521400112840674, 2.7218362656362758],
					[60.521444513451343, 2.7218057654859735],
					[60.521444516850117, 2.7218057611712605],
					[60.521444523818602, 2.7218057561629161],
					[60.521444527302862, 2.7218057536587508],
					[60.521491826717103, 2.7217668534583899],
					[60.521491829394407, 2.7217668529381727],
					[60.521491831986232, 2.7217668506074055],
					[60.521491833685609, 2.7217668484500432],
					[60.521544532993545, 2.7217195500217657],
					[60.521589029991318, 2.7216807519237793],
					[60.521661226230762, 2.7216195549620661],
					[60.521750122690726, 2.7215501573026999],
					[60.521750127959848, 2.721550154451652],
					[60.521750136542231, 2.7215501454752449],
					[60.521750140833419, 2.7215501409870329],
					[60.521777940854804, 2.7215223414374825],
					[60.521777942554202, 2.7215223392800678],
					[60.521777946930875, 2.721522336602431],
					[60.521777948630259, 2.7215223344450372],
					[60.5218029400286, 2.7214945434176769],
					[60.521894528642569, 2.7214168527353078],
					[60.521894532933764, 2.7214168482470562],
					[60.521894539816763, 2.7214168414279944],
					[60.521894544193422, 2.7214168387503372],
					[60.521975133741876, 2.7213334481540685],
					[60.522000122779573, 2.721314058690822],
					[60.522000127963189, 2.7213140540291194],
					[60.522000137438006, 2.7213140448791528],
					[60.522000141729187, 2.7213140403908898],
					[60.522019539210106, 2.7212945443182832],
					[60.522108434196035, 2.7212112493396807],
					[60.52218903047973, 2.721141852705371],
					[60.522189037362729, 2.7211418458861663],
					[60.522189047644474, 2.7211418347520788],
					[60.522189053635024, 2.7211418281063229],
					[60.522216843737098, 2.7211084402955019],
					[60.522261230987326, 2.7210695521599435],
					[60.522333428927737, 2.7210084525906471],
					[60.522333430627107, 2.721008450433188],
					[60.522333435896215, 2.7210084475819989],
					[60.522333437595577, 2.7210084454245393],
					[60.522394533615817, 2.7209501499390853],
					[60.522450129379465, 2.7209029527585926],
					[60.522450131971254, 2.7209029504276829],
					[60.522450137961805, 2.7209029437818071],
					[60.522450141446015, 2.7209029412774663],
					[60.522502936712421, 2.7208501450588525],
					[60.522555733380138, 2.7208029488347361],
					[60.522555734272565, 2.7208029486612988],
					[60.522555735971935, 2.7208029465038117],
					[60.522555736864355, 2.7208029463303736],
					[60.522602937362194, 2.7207584455024167],
					[60.522602939061564, 2.7207584433449234],
					[60.522602943438208, 2.7207584406671135],
					[60.522602945137592, 2.7207584385096184],
					[60.522664037989578, 2.7206945458204213],
					[60.522744530565603, 2.7206251502886083],
					[60.522744534942262, 2.7206251476107632],
					[60.522744542717632, 2.7206251406178863],
					[60.522744546116378, 2.7206251363028642],
					[60.522780746064967, 2.7205862355431507],
					[60.522780746957388, 2.7205862353697041],
					[60.522816841795297, 2.7205473403294937],
					[60.522897335692193, 2.7204723469566297],
					[60.52289733828399, 2.7204723446256329],
					[60.522897341682707, 2.7204723403105806],
					[60.522897344360011, 2.7204723397902271],
					[60.522969544101556, 2.720397339139569],
					[60.522969546693368, 2.7203973368085772],
					[60.522969551791455, 2.7203973303359534],
					[60.52296955519018, 2.720397326020878],
					[60.52300574884633, 2.7203529332121139],
					[60.523050142429071, 2.7203084412220249],
					[60.523050145020861, 2.7203084388910228],
					[60.523050151818303, 2.7203084302608174],
					[60.523050155217021, 2.7203084259457104],
					[60.523111252219344, 2.720233428207619],
					[60.523175146577906, 2.7201612358227689],
					[60.523239043637602, 2.7200945372028169],
					[60.523239048014233, 2.72009453452484],
					[60.523239055704089, 2.7200945257210916],
					[60.523239059102821, 2.720094521405934],
					[60.52330015492268, 2.7200140265880175],
					[60.523344549833816, 2.7199640333375488],
					[60.523344555738795, 2.7199640248806598],
					[60.523344566741862, 2.7199640099510152],
					[60.523344571754429, 2.7199640016675919],
					[60.523372364722071, 2.7199168132427123],
					[60.523452956132161, 2.719816826326936],
					[60.523452956046661, 2.7198168245162821],
					[60.523533455870776, 2.71971682564492],
					[60.52353345667769, 2.7197168236607658],
					[60.523533458377038, 2.7197168215031562],
					[60.523533459269487, 2.7197168213296754],
					[60.523569556201615, 2.71966952571153],
					[60.523614053019976, 2.719616829260378],
					[60.523614053826897, 2.7196168272762349],
					[60.52361405641868, 2.7196168249451342],
					[60.523614057311093, 2.7196168247716557],
					[60.523677956895149, 2.7195362233751603],
					[60.523677956895149, 2.7195362233751603],
					[60.523677958594497, 2.7195362212175307],
					[60.523677958594497, 2.7195362212175307],
					[60.523730755785124, 2.7194668246880278],
					[60.523791849581578, 2.719394532310079],
					[60.523864046740478, 2.7193168358469997],
					[60.523864047632905, 2.7193168356735136],
					[60.523864050139153, 2.7193168315316734],
					[60.52386405103158, 2.7193168313581859],
					[60.523900149214249, 2.7192751333852021],
					[60.523936237805266, 2.7192362457503019],
					[60.524008428333232, 2.7191751546198786],
					[60.524069524691932, 2.7191251555841984],
					[60.524133416853772, 2.7190751628367642],
					[60.524214005704884, 2.7190223696872864],
					[60.524241802956809, 2.7190057713281419],
					[60.52424180465615, 2.7190057691704426],
					[60.524241809925186, 2.7190057663187477],
					[60.52424181171002, 2.7190057659717479],
					[60.524258409054148, 2.7189945684142085],
					[60.524338998577726, 2.7189445741747251],
					[60.524402892013015, 2.7189112767144441],
					[60.52440289638961, 2.7189112740362238],
					[60.524402904335915, 2.7189112706639817],
					[60.524402906927676, 2.7189112683327665],
					[60.524464000767132, 2.7188723733471809],
					[60.524547294369945, 2.7188279761368674],
					[60.524547297854113, 2.7188279736321093],
					[60.524547306607332, 2.7188279682756114],
					[60.524547311069469, 2.7188279674080729],
					[60.524580711299969, 2.7188057676275363],
					[60.524580713891709, 2.7188057652963002],
					[60.524580719075239, 2.7188057606338192],
					[60.524580721666986, 2.7188057583025822],
					[60.5246168126329, 2.7187779644428702],
					[60.524680703863268, 2.7187390714085624],
					[60.52468070823987, 2.7187390687302884],
					[60.524680716100661, 2.7187390635472526],
					[60.52468072039175, 2.7187390590582599],
					[60.524725118992777, 2.7187057611913192],
					[60.524805716768611, 2.718647361626219],
					[60.524805720252807, 2.7186473591214337],
					[60.524805726328736, 2.7186473542853835],
					[60.524805729006012, 2.7186473537648399],
					[60.52489452908349, 2.7185723527966861],
					[60.524894531675258, 2.7185723504654025],
					[60.524894537751187, 2.7185723456293029],
					[60.524894541149841, 2.7185723413137759],
					[60.524914041093595, 2.7185529423653132],
					[60.524914043599836, 2.718552938223294],
					[60.524914048783337, 2.7185529335607082],
					[60.524914052182005, 2.7185529292451727],
					[60.524930745054085, 2.7185334383133566],
					[60.525030737118236, 2.7184390449099003],
					[60.52503073970999, 2.7184390425785967],
					[60.525030744893492, 2.7184390379159908],
					[60.525030748377667, 2.7184390354111674],
					[60.525119548190645, 2.7183418343828256],
					[60.525119553374161, 2.7183418297201705],
					[60.525119561785267, 2.7183418171204461],
					[60.525119565990813, 2.7183418108205943],
					[60.525136261910475, 2.7183168168205465],
					[60.52515575356334, 2.7182918273938608],
					[60.525244550107779, 2.7181918321145839],
					[60.525244550914685, 2.718191830130305],
					[60.525244555205767, 2.7181918256411524],
					[60.525244556905086, 2.7181918234833455],
					[60.525316855139039, 2.7181001268481508],
					[60.525361253056651, 2.7180473292509806],
					[60.525361254755971, 2.7180473270931569],
					[60.525361258154597, 2.7180473227774899],
					[60.525361258961503, 2.7180473207931977],
					[60.525405753126236, 2.7179890294599729],
					[60.525469547036252, 2.7179195346825655],
					[60.525469547928694, 2.7179195345090248],
					[60.525469547928694, 2.7179195345090248],
					[60.525522348108645, 2.7178612357097336],
					[60.525586247283648, 2.7177918350614401],
					[60.525586248982968, 2.7177918329035862],
					[60.525586252381579, 2.7177918285878819],
					[60.525586254080899, 2.7177918264300085],
					[60.525639045924507, 2.7177279369491312],
					[60.525691835921066, 2.7176779468850638],
					[60.525747333700188, 2.7176279490543513],
					[60.525747337184342, 2.7176279465493822],
					[60.525747342282287, 2.717627940075741],
					[60.525747344874006, 2.7176279377443211],
					[60.525800136828387, 2.7175723459227124],
					[60.525864026935842, 2.7175195534019063],
					[60.525864032204829, 2.717519550549794],
					[60.525864040786921, 2.7175195415711419],
					[60.525864045077959, 2.7175195370817971],
					[60.52591683811572, 2.7174640451612846],
					[60.52598073091243, 2.7174084502473712],
					[60.525980733589705, 2.7174084497266993],
					[60.525980738687622, 2.7174084432529959],
					[60.525980741279355, 2.7174084409215391],
					[60.526025138624036, 2.717364043821715],
					[60.526069535564623, 2.7173223457888032],
					[60.526141834619956, 2.7172557479894279],
					[60.526222333762625, 2.7171834488107294],
					[60.526222333762625, 2.7171834488107294],
					[60.526222334569503, 2.7171834468263456],
					[60.526222336354344, 2.7171834464792197],
					[60.526258436293389, 2.7171501468834469],
					[60.526258437992695, 2.7171501447255126],
					[60.526258444068574, 2.7171501398889473],
					[60.526258446574765, 2.7171501357466461],
					[60.526291842325627, 2.7171140415422923],
					[60.526375136658821, 2.7170362456089365],
					[60.526375140057425, 2.717036241293044],
					[60.526375147832582, 2.7170362342984733],
					[60.526375151316728, 2.7170362317933874],
					[60.526444546732371, 2.7169557367656378],
					[60.526480735240483, 2.7169195466760558],
					[60.526516828978998, 2.7168890528448348],
					[60.52651683416245, 2.7168890481817516],
					[60.52651684355137, 2.7168890372183325],
					[60.526516848734786, 2.7168890325552568],
					[60.526589042221566, 2.7168084403255612],
					[60.526677934274915, 2.7167279476888266],
					[60.526677936059762, 2.7167279473416643],
					[60.526677940350766, 2.7167279428521174],
					[60.526677941157629, 2.7167279408676963],
					[60.526705737874515, 2.7167001441244487],
					[60.526733429356788, 2.716675152864588],
					[60.52682232323685, 2.7166057573678848],
					[60.526822325828554, 2.7166057550363014],
					[60.526822332796847, 2.7166057500259577],
					[60.526822336280965, 2.7166057475207905],
					[60.526894528780076, 2.71653905383091],
					[60.52693071070334, 2.7165112667645084],
					[60.526983400118823, 2.7164807730678309],
					[60.526983405387789, 2.7164807702154552],
					[60.526983414140879, 2.7164807648578697],
					[60.526983419324317, 2.7164807601946519],
					[60.527036211698494, 2.7164418655055429],
					[60.527080700646252, 2.7164140723514416],
					[60.527152894968864, 2.7163751753157745],
					[60.527152896753691, 2.71637517496859],
					[60.527152902022642, 2.7163751721161824],
					[60.527152903807497, 2.7163751717689948],
					[60.527216798842794, 2.7163362734265339],
					[60.527269489714065, 2.7163084799029842],
					[60.527350081400002, 2.7162695826718561],
					[60.527377873553576, 2.7162584855576011],
					[60.527405673737654, 2.7162473868604797],
					[60.527405677221786, 2.7162473843552188],
					[60.527405683383158, 2.7162473813291683],
					[60.527405686952861, 2.7162473806347727],
					[60.527475083625205, 2.7162140819031153],
					[60.527530682145617, 2.7161890812826632],
					[60.527530684016007, 2.7161890827463324],
					[60.527530688392552, 2.7161890800674571],
					[60.527530690177393, 2.7161890797202557],
					[60.527591783712751, 2.7161584814700306],
					[60.527663977673029, 2.7161279843635397],
					[60.527663982941995, 2.7161279815110402],
					[60.527663991780635, 2.7161279779641294],
					[60.527663997049586, 2.7161279751116147],
					[60.527719492384939, 2.7160973782556712],
					[60.527800086668321, 2.716058479371215],
					[60.52780008934559, 2.7160584788503939],
					[60.527800092829693, 2.7160584763450686],
					[60.52780009461452, 2.7160584759978592],
					[60.527836195360294, 2.716039075260773],
					[60.527836197145135, 2.7160390749135583],
					[60.527836202414107, 2.7160390720610295],
					[60.527836205005812, 2.7160390697293177],
					[60.527872299701883, 2.7160168721651154],
					[60.527933396063986, 2.7159834747062814],
					[60.527933399548104, 2.7159834722009513],
					[60.527933409193594, 2.715983466669448],
					[60.527933413570153, 2.7159834639905061],
					[60.527969506669429, 2.7159584686335676],
					[60.528041800373025, 2.7159168726769045],
					[60.528041801265466, 2.7159168725032883],
					[60.528041803857143, 2.715916870171541],
					[60.528041804749556, 2.7159168699979288],
					[60.528114005359598, 2.7158723697012235],
					[60.528114006252011, 2.7158723695276104],
					[60.528114007144453, 2.7158723693539928],
					[60.528166807026224, 2.7158390685016256],
					[60.528166808811058, 2.715839068154398],
					[60.528166808725501, 2.7158390663434919],
					[60.528230699556474, 2.715797373881998],
					[60.528275089097143, 2.7157751793949902],
					[60.528275105710861, 2.7157751688526148],
					[60.528275136982337, 2.7157751444932421],
					[60.528275151640095, 2.715775130676263],
					[60.528291829000587, 2.7157557560948722],
					[60.528383399656626, 2.715702972522084],
					[60.528383402333873, 2.7157029720012242],
					[60.528383405817983, 2.7157029694958261],
					[60.528383406710397, 2.7157029693222072],
					[60.528444506024776, 2.7156640691115852],
					[60.52850840365145, 2.7156251707672725],
					[60.528508405436277, 2.7156251704200272],
					[60.52858900467244, 2.7155751694220402],
					[60.528589010833826, 2.7155751663957339],
					[60.528589022178572, 2.7155751587058079],
					[60.528589028254387, 2.7155751538685737],
					[60.528605710054713, 2.7155612681630963],
					[60.528633390084764, 2.7155473791002982],
					[60.528633392676461, 2.7155473767684897],
					[60.528633400622667, 2.7155473733949225],
					[60.528633404999191, 2.7155473707158624],
					[60.528705700433157, 2.7155029726763895],
					[60.528766793237317, 2.715469577613415],
					[60.528838989069044, 2.7154334793917152],
					[60.528838991746298, 2.7154334788708234],
					[60.528838996929657, 2.7154334742071393],
					[60.528838999606933, 2.715433473686244],
					[60.528911200248203, 2.7153918732916931],
					[60.528911202839879, 2.7153918709598512],
					[60.528911209893664, 2.7153918677598452],
					[60.528911214184618, 2.7153918632697942],
					[60.528947302051741, 2.7153668729110958],
					[60.529019487310507, 2.7153307799410582],
					[60.529055684182616, 2.7153140819846167],
					[60.529055691236401, 2.7153140787845755],
					[60.529055704365952, 2.7153140707471866],
					[60.529055711334173, 2.715314065736175],
					[60.529080703727644, 2.7152973710988255],
					[60.529161192336872, 2.7152529786469244],
					[60.529225085797371, 2.7152223803538922],
					[60.529225089367038, 2.7152223796593358],
					[60.529225094635969, 2.7152223768065387],
					[60.529225098120087, 2.7152223743010002],
					[60.529288998168965, 2.7151862734662826],
					[60.529289000846219, 2.7151862729453602],
					[60.529289005222722, 2.7151862702661926],
					[60.52928900781442, 2.7151862679343046],
					[60.529358402354127, 2.7151418714387177],
					[60.52939449491442, 2.7151223751685438],
					[60.529394497591682, 2.7151223746476103],
					[60.529394502860598, 2.7151223717947834],
					[60.529394506344723, 2.7151223692892161],
					[60.529438997801634, 2.7150945752112694],
					[60.529494488702085, 2.7150668796387403],
					[60.529494489594484, 2.7150668794650965],
					[60.529538990253187, 2.7150445791141262],
					[60.529538991060043, 2.7150445771294822],
					[60.529538992844877, 2.7150445767821871],
					[60.529538994629704, 2.7150445764348974],
					[60.529611195113851, 2.7150057759506967],
					[60.529611196898678, 2.7150057756034034],
					[60.529611200382796, 2.7150057730978228],
					[60.529611202167622, 2.7150057727505268],
					[60.529672291422507, 2.7149695782589536],
					[60.52973617977112, 2.7149418839685771],
					[60.529736180663534, 2.7149418837949244],
					[60.529736185040065, 2.7149418811156805],
					[60.529736186824891, 2.7149418807683792],
					[60.529816785400293, 2.7149029808543834],
					[60.529852884074522, 2.7148862827739499],
					[60.529852889257889, 2.7148862781100398],
					[60.529852899795749, 2.7148862724041929],
					[60.529852905064672, 2.7148862695512808],
					[60.529888995318856, 2.7148640754905538],
					[60.529941784994591, 2.7148390814487233],
					[60.529977878304017, 2.7148223842662671],
					[60.530058371391959, 2.7147918877335244],
					[60.530058372284387, 2.7147918875598678],
					[60.530058373983643, 2.714791885401544],
					[60.530113973958414, 2.7147695865045951],
					[60.530113975657677, 2.7147695843462678],
					[60.530113981012185, 2.7147695833043182],
					[60.530113982711441, 2.7147695811459926],
					[60.530175073658484, 2.7147418857339107],
					[60.530247265342659, 2.7147168898337046],
					[60.530247268826749, 2.7147168873280458],
					[60.530247274988092, 2.7147168843013874],
					[60.530247277665346, 2.714716883780405],
					[60.5303000775992, 2.7146945848336266],
					[60.53037227486606, 2.7146640844806633],
					[60.530408364579081, 2.7146501893834976],
					[60.530463958109323, 2.7146334912421239],
					[60.530463960786577, 2.7146334907211287],
					[60.53046396694792, 2.7146334876944489],
					[60.530463969625174, 2.7146334871734545],
					[60.530508370355783, 2.7146168871896901],
					[60.530508377409525, 2.7146168839893221],
					[60.530508390624604, 2.7146168777622832],
					[60.530508396785947, 2.7146168747355959],
					[60.530533382020337, 2.7146029837932955],
					[60.530605662066222, 2.714577990160469],
					[60.530661155669414, 2.7145612929394671],
					[60.530730651411893, 2.7145418927004981],
					[60.530786145820535, 2.7145279952244188],
					[60.530847236704211, 2.7145140978952926],
					[60.530902825423027, 2.7145057985094643],
					[60.530955611115935, 2.7145001994554585],
					[60.531019400399849, 2.7145001993635272],
					[60.531072179600926, 2.7145001998386302],
					[60.531127760769728, 2.7145112957885345],
					[60.531127762554554, 2.7145112954411914],
					[60.531127767102198, 2.714511296383896],
					[60.531127767994612, 2.7145112962102256],
					[60.531197145882707, 2.7145223939797147],
					[60.531252719897374, 2.7145445838851217],
					[60.531305509318841, 2.7145695785011057],
					[60.531386002966336, 2.7146140754993104],
					[60.531386004751162, 2.7146140751519701],
					[60.531386009384413, 2.714614077905789],
					[60.531386011254824, 2.7146140793695257],
					[60.531413788823237, 2.7146279672412525],
					[60.531433166629022, 2.7146445498823182],
					[60.531530460682745, 2.7147334435287007],
					[60.531622038354385, 2.7148279207917407],
					[60.531638724914693, 2.7148583955489025],
					[60.531638727848666, 2.7148584004612055],
					[60.531638733716612, 2.7148584102857964],
					[60.531638736650585, 2.714858415198103],
					[60.53166653494727, 2.7148973121408955],
					[60.531738731812951, 2.7150084071931677],
					[60.531791527132, 2.7150918004949909],
					[60.53185542210209, 2.7152111923439657],
					[60.531908120688158, 2.7153167886716476],
					[60.531908120773714, 2.7153167904827611],
					[60.531908121666142, 2.7153167903091044],
					[60.531972019014383, 2.715441784645388],
					[60.532016515960557, 2.7155472777756815],
					[60.53201651604612, 2.7155472795867981],
					[60.53201651702409, 2.715547281224286],
					[60.532016518002088, 2.7155472828617548],
					[60.532080416071302, 2.7156889782747116],
					[60.532116512895747, 2.7157778708049842],
					[60.532160912251605, 2.7159028661130917],
					[60.53216091233719, 2.7159028679242327],
					[60.532160913400716, 2.7159028713728497],
					[60.532160913400716, 2.7159028713728497],
					[60.532188711973909, 2.7159750682351333],
					[60.532208110164554, 2.7160333617038868],
					[60.532260908925885, 2.7161972600386721],
					[60.532305409165488, 2.7163444571949933],
					[60.532333109288437, 2.7164361580982308],
					[60.532360906074054, 2.7165278476540107],
					[60.532388703715434, 2.7166722378867485],
					[60.532388703800976, 2.7166722396978908],
					[60.532388704864495, 2.7166722431465757],
					[60.532388704864495, 2.7166722431465757],
					[60.532424802864078, 2.7168306372793829],
					[60.532433102415183, 2.7168861301904923],
					[60.532433101522763, 2.7168861303641116],
					[60.532441501593567, 2.716938927855614],
					[60.532460901380027, 2.7171028246266853],
					[60.532460902272469, 2.7171028244530735],
					[60.532460901551133, 2.7171028282489851],
					[60.532460901722217, 2.7171028318712995],
					[60.532480402144799, 2.7172278283795386],
					[60.532497002373887, 2.7173556264234602],
					[60.532497002373887, 2.7173556264234602],
					[60.532516501151832, 2.7174972217326108],
					[60.532524799794764, 2.7176000130123827],
					[60.532533099853794, 2.7177583106016119],
					[60.53253310125946, 2.7177583212949634],
					[60.532533104877608, 2.7177583406969363],
					[60.532533107175659, 2.7177583512166987],
					[60.532544200404836, 2.7178000257868877],
					[60.532544199905416, 2.7178388994829521],
					[60.53254420007648, 2.7178389031052643],
					[60.532544200333071, 2.7178389085387549],
					[60.532544200418613, 2.7178389103499079],
					[60.532552600130352, 2.7180000107938698],
					[60.532552600215887, 2.7180000126050436],
					[60.532552601193842, 2.7180000142426159],
					[60.53255260127937, 2.7180000160537716],
					[60.532560901249362, 2.7180972178614073],
					[60.532560901334904, 2.7180972196725661],
					[60.532560900613532, 2.7180972234684848],
					[60.53256090167703, 2.7180972269172146],
					[60.532580401462624, 2.7182417215412711],
					[60.532588701424892, 2.7183556149342736],
					[60.532588700617985, 2.7183556169190144],
					[60.532599801265725, 2.7184833156856794],
					[60.532608100298738, 2.7186083132459622],
					[60.532608100298738, 2.7186083132459622],
					[60.532608101191151, 2.7186083130723939],
					[60.532616500040547, 2.7187250131617833],
					[60.532624799633439, 2.7188639129584176],
					[60.532624800611394, 2.7188639145960352],
					[60.53262480086795, 2.7188639200295288],
					[60.532624800953478, 2.7188639218406916],
					[60.532635900622175, 2.7189639152477247],
					[60.532644200054484, 2.7191194116193107],
					[60.532644201117947, 2.719119415068088],
					[60.532644200567582, 2.7191194224863278],
					[60.53264420171655, 2.7191194277462718],
					[60.532652599790559, 2.7191806142124522],
					[60.532652600367165, 2.719269399541556],
					[60.532652599731286, 2.7192694051486295],
					[60.532652601136796, 2.7192694158420996],
					[60.532652601393352, 2.7192694212756194],
					[60.53266370065829, 2.7193750208131622],
					[60.532663700743811, 2.7193750226243298],
					[60.532663700743811, 2.7193750226243298],
					[60.532672000600733, 2.7194472170230228],
					[60.532680399740471, 2.7195972071663572],
					[60.532683099924085, 2.7196972057139721],
					[60.532683100009599, 2.7196972075251398],
					[60.532683100095106, 2.7196972093363132],
					[60.532683100180627, 2.7196972111474831],
					[60.532691499659613, 2.7198361120278385],
					[60.53269149974512, 2.719836113839011],
					[60.53269150063754, 2.7198361136654743],
					[60.532691500723033, 2.7198361154766482],
					[60.532699799973351, 2.7199444147243872],
					[60.532708099935022, 2.7200750130715612],
					[60.532708100020514, 2.7200750148827364],
					[60.532708100998455, 2.7200750165203784],
					[60.532708101083948, 2.7200750183315683],
					[60.53271920064752, 2.7201972164646575],
					[60.532727600423577, 2.7203167135422164],
					[60.532735900271028, 2.7204528119322293],
					[60.532735901334441, 2.7204528153810665],
					[60.532735900613027, 2.7204528191769528],
					[60.532735900698533, 2.7204528209881276],
					[60.532747001024376, 2.7205556206772461],
					[60.532747001109868, 2.7205556224884244],
					[60.532763701316796, 2.7207056222255774],
					[60.532763702465715, 2.7207056274856019],
					[60.532763702893192, 2.7207056365415117],
					[60.532763704042097, 2.7207056418015521],
					[60.532774804283036, 2.7207583415146712],
					[60.532774806409861, 2.7207583484123838],
					[60.532774808793164, 2.7207583607436363],
					[60.53277481092001, 2.720758367641344],
					[60.532791503851747, 2.7208056462959052],
					[60.532810901243813, 2.720969424529545],
					[60.532827601203564, 2.7211083244866474],
					[60.532827602352448, 2.7211083297466998],
					[60.532827603757823, 2.7211083404403151],
					[60.532827604906714, 2.7211083457003826],
					[60.532855404012544, 2.7212278425887946],
					[60.532883103408651, 2.721366733841653],
					[60.532891500106381, 2.721425013426245],
					[60.532891500474037, 2.7214778005260025],
					[60.532891499691509, 2.7216249997375446],
					[60.532891500454369, 2.7216694007565869],
					[60.53289150105276, 2.7216694134349253],
					[60.532891504119846, 2.7216694402558432],
					[60.532891507395526, 2.721669452413721],
					[60.532983107350283, 2.7220056529208736],
					[60.532983201759478, 2.7220057259594053],
					[60.532983392920322, 2.7220057308393923],
					[60.532983491371347, 2.7220056605226759],
					[60.533350174286653, 2.7208390123291073],
					[60.533708432604577, 2.7205195493829581],
					[60.533708437788128, 2.72051954471933],
					[60.533708447262811, 2.7205195355656291],
					[60.53370845236087, 2.7205195290907667],
					[60.534075073686942, 2.7200919218915707],
					[60.534549958212502, 2.7201945944066619],
					[60.534549995229462, 2.7201945963479153],
					[60.534550067601408, 2.7201945841024409],
					[60.534550103041894, 2.7201945717270051],
					[60.535266803266587, 2.7197640711305251],
					[60.53526680505145, 2.7197640707834094],
					[60.53526681023498, 2.7197640661194185],
					[60.535266812019813, 2.7197640657723072],
					[60.536055711612569, 2.719227965444222],
					[60.536055729840356, 2.7192279509309345],
					[60.536055762298616, 2.7192279135418667],
					[60.536055775807739, 2.7192278944623713],
					[60.536627975779197, 2.7181639951115315],
					[60.53662797658609, 2.7181639931265336],
					[60.536627979177823, 2.7181639907943165],
					[60.536627979006745, 2.7181639871715455],
					[60.537255759885106, 2.7168890273571158],
					[60.537927915435198, 2.7163529646720588],
					[60.539336168572639, 2.7154945916706845],
					[60.540116696846326, 2.7153808029669833],
					[60.540691654854285, 2.7154834956303313],
					[60.541419346288549, 2.7156890913526506],
					[60.541419388831144, 2.7156890958696827],
					[60.541419472803852, 2.7156890813523509],
					[60.541419512534745, 2.7156890644770351],
					[60.542044512845436, 2.7152584643347897],
					[60.542044536170479, 2.7152584433389664],
					[60.542044572576089, 2.7152583941999464],
					[60.542044588419472, 2.7152583673472424],
					[60.542350187521095, 2.714408367305198],
					[60.542350189220336, 2.7144083651459781],
					[60.542350190748387, 2.7144083593633925],
					[60.542350192447635, 2.7144083572041904],
					[60.543080792303016, 2.7119667568442769],
					[60.543080793024174, 2.7119667530469851],
					[60.543080795273276, 2.7119667434668648],
					[60.543080795994449, 2.7119667396695872],
					[60.543650196073379, 2.7091028390885277],
					[60.543650196708803, 2.7091028334793603],
					[60.543650198786388, 2.7091028202753873],
					[60.543650199421798, 2.7091028146662355],
					[60.543850198648215, 2.7064528239753609],
					[60.544000195838684, 2.7055000386192884],
					[60.544319593017221, 2.7041194516036136],
					[60.544739090616737, 2.7027583586521655],
					[60.544739092677737, 2.7027583070516266],
					[60.544739070027909, 2.702758209073298],
					[60.5447390462095, 2.7027581625214347],
					[60.544658454791161, 2.702672073048177],
					[60.544622365790381, 2.7026220895927411],
					[60.544586269783686, 2.7025637952112538],
					[60.544586264892956, 2.7025637870228527],
					[60.544586253412476, 2.7025637728059895],
					[60.544586246736962, 2.7025637649657663],
					[60.544522371990048, 2.7024942909151419],
					[60.544486286455907, 2.702405526452619],
					[60.544425186222867, 2.7022499268474949],
					[60.544425185244734, 2.7022499252098302],
					[60.544425184180838, 2.7022499217604019],
					[60.544425183202662, 2.7022499201227279],
					[60.544405785128255, 2.7022055236975966],
					[60.54438908609643, 2.7021638262095813],
					[60.544325186249559, 2.7020027250401477],
					[60.544325186249559, 2.7020027250401477],
					[60.544325185271411, 2.7020027234024977],
					[60.544325184293257, 2.7020027217648481],
					[60.544280784877223, 2.7018971236349913],
					[60.544225186854483, 2.7017582289099313],
					[60.544180787751145, 2.7016360323423356],
					[60.544180786687228, 2.7016360288929349],
					[60.544180783581226, 2.7016360203565446],
					[60.544180781624902, 2.701636017081265],
					[60.544125182860533, 2.7015138173972577],
					[60.544064085168841, 2.701374923724317],
					[60.544027987134122, 2.7012805287127741],
					[60.544027985092036, 2.701280523625806],
					[60.544027981986019, 2.7012805150894708],
					[60.544027980029718, 2.7012805118142436],
					[60.543964080117661, 2.7011499111764397],
					[60.543964078247114, 2.7011499097129672],
					[60.543964074248713, 2.7011499013507878],
					[60.543964072292376, 2.7011498980755642],
					[60.543908475348651, 2.7010555033726606],
					[60.543847376983138, 2.700938807337681],
					[60.543847374048646, 2.7009388024249024],
					[60.543847369072054, 2.7009387924251889],
					[60.543847366137584, 2.700938787512388],
					[60.543783465595226, 2.7008442877479513],
					[60.543783464702841, 2.700844287922088],
					[60.543783463724679, 2.7008442862845001],
					[60.543719564409209, 2.7007526865804925],
					[60.543719564323425, 2.7007526847687715],
					[60.543719563431033, 2.7007526849429131],
					[60.54363906327147, 2.7006387851903932],
					[60.543639062293323, 2.7006387835528178],
					[60.543639060336979, 2.7006387802776746],
					[60.543639058466418, 2.7006387788142425],
					[60.543611257836233, 2.700602677796534],
					[60.543611248311898, 2.7006026668559495],
					[60.543611225693653, 2.7006026456713363],
					[60.543611214384512, 2.70060263507904],
					[60.543575114055237, 2.7005776361216469],
					[60.543575101047111, 2.7005776276893658],
					[60.543575075202398, 2.7005776144482581],
					[60.54357506147344, 2.7005776098135481],
					[60.543513961348296, 2.7005581093061428],
					[60.543513955015797, 2.7005581087134409],
					[60.54351394494217, 2.7005581051938847],
					[60.543513938609657, 2.7005581046011833],
					[60.543458341858198, 2.7005470034021677],
					[60.543397245760652, 2.7005331045780654],
					[60.543341748947761, 2.7005192065444472],
					[60.5433417470772, 2.7005192050810347],
					[60.543341745292423, 2.7005192054293232],
					[60.543341744400038, 2.7005192056034666],
					[60.543280646685979, 2.7005054059194147],
					[60.543216754899177, 2.7004887085821991],
					[60.543163959726755, 2.7004720089070662],
					[60.543163957941964, 2.7004720092553542],
					[60.543163954200857, 2.7004720063285492],
					[60.543163952416094, 2.7004720066768364],
					[60.543091754942672, 2.7004526078479616],
					[60.543036157893475, 2.7004359083525928],
					[60.542955675283558, 2.7004109133366154],
					[60.542938989271931, 2.7004026212089376],
					[60.542938984638411, 2.7004026184563075],
					[60.542938976349568, 2.7004026145885591],
					[60.542938971801831, 2.7004026136476065],
					[60.542902871863262, 2.7003887137992795],
					[60.542838975161082, 2.7003637149748521],
					[60.542786183593329, 2.700341518754668],
					[60.542713996267004, 2.700305425059935],
					[60.542658402942742, 2.7002720282654149],
					[60.542658402942742, 2.7002720282654149],
					[60.542658402050328, 2.7002720284395645],
					[60.542597303927508, 2.7002359285192021],
					[60.542516806375225, 2.7001859302217723],
					[60.542516798086382, 2.7001859263541417],
					[60.542516782401066, 2.7001859184447143],
					[60.542516774112215, 2.700185914577065],
					[60.542488996592425, 2.7001748230502125],
					[60.542469516767952, 2.7001609384385095],
					[60.542389019712587, 2.7001026395230459],
					[60.542327922002002, 2.7000554418106715],
					[60.542327920131456, 2.7000554403473083],
					[60.542327916390306, 2.7000554374206289],
					[60.542327913627346, 2.7000554361314371],
					[60.54226401758261, 2.7000109385188247],
					[60.542191821871576, 2.6999554413034379],
					[60.54219182000103, 2.6999554398401053],
					[60.54219181625988, 2.6999554369134287],
					[60.542191814389334, 2.6999554354500961],
					[60.54215571387688, 2.6999304364117522],
					[60.542155713791111, 2.6999304346001205],
					[60.542119521650505, 2.69990544069754],
					[60.542047328986463, 2.6998442461476344],
					[60.542047326223518, 2.6998442448584696],
					[60.542047319719423, 2.6998442406426548],
					[60.542047316870686, 2.6998442375418636],
					[60.541966818719629, 2.6997859384830938],
					[60.541922320286119, 2.6997526397127367],
					[60.541877919717464, 2.6997192396542733],
					[60.541877916954505, 2.6997192383651258],
					[60.541877912235186, 2.6997192338010443],
					[60.541877909472234, 2.6997192325118955],
					[60.541822308639809, 2.69968313298329],
					[60.541822307661619, 2.6996831313458354],
					[60.541822306769248, 2.6996831315199907],
					[60.541822305791058, 2.699683129882537],
					[60.541777905872053, 2.6996554306952665],
					[60.541777904001478, 2.6996554292319743],
					[60.54177790123854, 2.6996554279428295],
					[60.541777900260357, 2.699655426305362],
					[60.541705700258348, 2.6996137263785553],
					[60.541705696602982, 2.699613725263565],
					[60.541705691969462, 2.6996137225111418],
					[60.541705689206516, 2.6996137212220073],
					[60.541633390584096, 2.6995776226587695],
					[60.541580692802718, 2.6995498226961083],
					[60.541580688169184, 2.6995498199436891],
					[60.541580677117366, 2.6995498147871966],
					[60.541580670699076, 2.6995498123830899],
					[60.541500072846766, 2.6995192134970587],
					[60.541472274158053, 2.6995081141662043],
					[60.541472272373291, 2.699508114514519],
					[60.541472272287493, 2.6995081127029272],
					[60.541436172190934, 2.6994942125777661],
					[60.541436164880238, 2.6994942103478552],
					[60.541436151237029, 2.6994942075254516],
					[60.541436143926347, 2.699494205295542],
					[60.541375045076158, 2.6994804046984284],
					[60.541338947898531, 2.6994720055143162],
					[60.54127504968686, 2.6994554054753142],
					[60.541275039699002, 2.6994554037678986],
					[60.541275020615657, 2.6994554001788935],
					[60.541275010713598, 2.6994554002830573],
					[60.541222217076609, 2.6994526017077525],
					[60.541150024105583, 2.6994442006764143],
					[60.541105625298187, 2.6994387015378538],
					[60.541105621642849, 2.6994387004229119],
					[60.541105618073303, 2.6994387011195435],
					[60.541105615396113, 2.6994387016420203],
					[60.541033314559044, 2.6994331007422669],
					[60.541033312688484, 2.6994330992790139],
					[60.541033311796099, 2.6994330994531701],
					[60.540988912260843, 2.6994303996247346],
					[60.540988911368466, 2.6994303997988882],
					[60.540988909583668, 2.6994304001472083],
					[60.540988908691276, 2.6994304003213636],
					[60.540925011045488, 2.6994276004490443],
					[60.540880613930696, 2.699424799598944],
					[60.540808314963648, 2.699419200201532],
					[60.540808314071278, 2.6994192003756887],
					[60.540808312286487, 2.6994192007240034],
					[60.540808309523527, 2.6994191994349173],
					[60.540755609722886, 2.6994164996102623],
					[60.540700015997054, 2.6994137008875518],
					[60.540647223000981, 2.6994081013055391],
					[60.540583326088047, 2.699399801834232],
					[60.540583325195662, 2.6993998020083865],
					[60.540583322432717, 2.6993998007193105],
					[60.540583320647904, 2.6993998010676274],
					[60.540530621317856, 2.6993942015201244],
					[60.540530620425464, 2.6993942016942767],
					[60.540530617662519, 2.6993942004051856],
					[60.54053061677012, 2.6993942005793437],
					[60.540466729117171, 2.699388702098295],
					[60.540413941401304, 2.6993776039126773],
					[60.54041393506882, 2.6993776033202219],
					[60.540413923210373, 2.6993776001496239],
					[60.540413916963651, 2.699377601368707],
					[60.540350021743095, 2.6993720001913135],
					[60.540286125807157, 2.6993637024690345],
					[60.540286124914758, 2.6993637026431898],
					[60.54024172487081, 2.699358101547233],
					[60.54024172397844, 2.6993581017213857],
					[60.540241723086055, 2.6993581018955379],
					[60.540169424036911, 2.6993498005118957],
					[60.540125025056625, 2.6993442028943151],
					[60.540125023186064, 2.6993442014311073],
					[60.54012501774595, 2.6993442006645103],
					[60.540125015068767, 2.6993442011869746],
					[60.540052815376924, 2.6993387014592178],
					[60.540052812613951, 2.699338700170169],
					[60.540008312821882, 2.6993358993708485],
					[60.540008311929498, 2.6993358995449976],
					[60.540008310144707, 2.699335899893307],
					[60.540008309252322, 2.6993359000674602],
					[60.539947208929853, 2.6993330992592863],
					[60.539947207145055, 2.6993330996075962],
					[60.539947201790731, 2.699333100652511],
					[60.539947199920171, 2.6993330991892903],
					[60.539900000289947, 2.6993330996206244],
					[60.53983059995312, 2.6993330996286957],
					[60.539802800048712, 2.6993331004431611],
					[60.539802796393367, 2.6993330993282694],
					[60.539802788276084, 2.6993330990841349],
					[60.539802784792329, 2.6993331015922375],
					[60.539766688357325, 2.6993359007040354],
					[60.539694391753308, 2.6993387000038291],
					[60.539694390860923, 2.6993387001779796],
					[60.539694389076146, 2.6993387005262814],
					[60.539694387205572, 2.6993386990630928],
					[60.539649989698049, 2.6993415001241252],
					[60.539577792835345, 2.6993441994783742],
					[60.539577788373393, 2.6993442003491257],
					[60.539577779363711, 2.6993442002791217],
					[60.539577774901787, 2.6993442011498638],
					[60.539533275774509, 2.6993498009204613],
					[60.539461076648003, 2.6993581005209029],
					[60.539461075841402, 2.6993581025065305],
					[60.53946107494901, 2.6993581026806779],
					[60.539416679302846, 2.6993637004898137],
					[60.539352782952982, 2.6993692005478813],
					[60.539352779383421, 2.6993692012444734],
					[60.539352772244321, 2.6993692026376479],
					[60.539352768588969, 2.6993692015227673],
					[60.539299973990481, 2.6993776028901082],
					[60.539244379603581, 2.6993831007574434],
					[60.53924437781879, 2.6993831011057381],
					[60.539244375141642, 2.6993831016281717],
					[60.539244373356851, 2.6993831019764665],
					[60.53918327305049, 2.6993915024819448],
					[60.539183271265721, 2.6993915028302373],
					[60.539183267696153, 2.6993915035268201],
					[60.539183265911369, 2.6993915038751122],
					[60.539119366314367, 2.6994026024042648],
					[60.539119361852435, 2.6994026032749869],
					[60.539119353014335, 2.6994026068278956],
					[60.539119349444796, 2.6994026075244686],
					[60.539066656608284, 2.6994165045769791],
					[60.539002766017099, 2.6994276030726105],
					[60.53900276333993, 2.6994276035950437],
					[60.5390027579856, 2.6994276046399008],
					[60.539002756200816, 2.6994276049881858],
					[60.538941656041729, 2.6994415044964666],
					[60.538941655149337, 2.6994415046706077],
					[60.538941653364539, 2.6994415050188989],
					[60.538941650687391, 2.6994415055413241],
					[60.538886050789422, 2.6994554066392631],
					[60.538886049004645, 2.6994554069875458],
					[60.538886043650322, 2.6994554080323927],
					[60.538886040973146, 2.699455408554821],
					[60.538813840938516, 2.6994776093703989],
					[60.538813838261341, 2.6994776098928224],
					[60.538813831122255, 2.6994776112859427],
					[60.538813827638471, 2.6994776137939511],
					[60.538777728183021, 2.6994915120874206],
					[60.538777728268805, 2.6994915138988604],
					[60.538777725591643, 2.6994915144212808],
					[60.538708232840477, 2.6995192106274875],
					[60.538672140614949, 2.6995304090185517],
					[60.538672136238795, 2.6995304117006782],
					[60.538672126422512, 2.6995304136161984],
					[60.538672121153958, 2.6995304164724798],
					[60.538652720926088, 2.6995387159415216],
					[60.538652719141311, 2.6995387162897981],
					[60.53865271824894, 2.6995387164639326],
					[60.538572117916623, 2.6995748176470253],
					[60.538572117024238, 2.6995748178211638],
					[60.538572115239447, 2.6995748181694368],
					[60.538572114347062, 2.6995748183435722],
					[60.538519314171971, 2.6995998181313938],
					[60.53851931336537, 2.6995998201169553],
					[60.538519308989216, 2.6995998227990565],
					[60.538519307204432, 2.6995998231473273],
					[60.538455514999292, 2.6996331195262449],
					[60.538383221726086, 2.6996637162434474],
					[60.538383219941316, 2.699663716591711],
					[60.538383216371741, 2.6996637172882445],
					[60.538383213780364, 2.6996637196220608],
					[60.538330514474886, 2.6996887195234853],
					[60.538330512690123, 2.6996887198717459],
					[60.538330511883508, 2.6996887218572967],
					[60.538330510905311, 2.6996887202200117],
					[60.538258218733048, 2.6997248173775246],
					[60.538222129951549, 2.6997387130298294],
					[60.538169331127214, 2.6997581123523111],
					[60.53811663068344, 2.699777611238535],
					[60.538116629876839, 2.6997776132240672],
					[60.538116628984447, 2.6997776133981919],
					[60.538116628092062, 2.6997776135723219],
					[60.538080534926785, 2.6997915117774509],
					[60.538008241060929, 2.6998137093653227],
					[60.53800824016853, 2.699813709539451],
					[60.537963849321287, 2.6998276051518428],
					[60.537899963373597, 2.6998415021086197],
					[60.537847169359765, 2.6998498015112378],
					[60.537774980396705, 2.6998609016836275],
					[60.537719389642632, 2.6998636992722052],
					[60.537719385180694, 2.6998637001428145],
					[60.537719373579591, 2.6998637024064083],
					[60.537719369031883, 2.6998637014656319],
					[60.537666683937644, 2.6998719996240363],
					[60.537602800073742, 2.6998720004017573],
					[60.537602797310797, 2.6998719991127422],
					[60.537602791956445, 2.6998720001574732],
					[60.537602789279283, 2.6998720006798327],
					[60.537549988796719, 2.6998748003919655],
					[60.537497194646292, 2.6998776006821532],
					[60.537441704630702, 2.6998775998080964],
					[60.537380608931564, 2.699874800650917],
					[60.537380607061003, 2.6998747991877901],
					[60.53738060170668, 2.6998748002325055],
					[60.537380599921882, 2.6998748005807465],
					[60.53732500544514, 2.6998747999719743],
					[60.537272215454479, 2.6998720017705473],
					[60.537216719867139, 2.6998665012303134],
					[60.537216717189963, 2.6998665017526693],
					[60.537216711664065, 2.6998664991746515],
					[60.53721670898689, 2.6998664996970083],
					[60.537155613287325, 2.6998637005541184],
					[60.537091719046757, 2.6998581002196236],
					[60.537038920957968, 2.6998526017120725],
					[60.537038920065569, 2.6998526018861928],
					[60.536983323330411, 2.6998470004761304],
					[60.536922226606627, 2.6998387027020754],
					[60.536922225714235, 2.6998387028761948],
					[60.536922224736067, 2.6998387012389742],
					[60.536877831120236, 2.6998331021914233],
					[60.536805638112156, 2.6998192038361539],
					[60.536805636241596, 2.699819202373035],
					[60.536805631779657, 2.6998192032436203],
					[60.536805629909097, 2.6998192017805223],
					[60.536750034256215, 2.6998109038305818],
					[60.53667784116103, 2.6997970037510659],
					[60.536641748809807, 2.6997887065437212],
					[60.536580653156214, 2.6997720069574149],
					[60.536580648608492, 2.6997720060166781],
					[60.536580642275972, 2.6997720054241752],
					[60.536580638620649, 2.6997720043093207],
					[60.536552842055428, 2.6997665032940836],
					[60.536516744899188, 2.6997581047105572],
					[60.536444447357923, 2.6997415050544995],
					[60.536400049057718, 2.6997304064962013],
					[60.536400048165305, 2.6997304066703189],
					[60.536400047272913, 2.699730406844437],
					[60.536319448074011, 2.6997109062963327],
					[60.536275049686374, 2.6996998060012394],
					[60.536211151173561, 2.6996831051904557],
					[60.536211146711622, 2.6996831060610349],
					[60.536211139400926, 2.6996831038313731],
					[60.536211135745596, 2.6996831027165409],
					[60.536150042346733, 2.6996720041613531],
					[60.536094448973053, 2.6996581068558845],
					[60.536094447102478, 2.6996581053928272],
					[60.53609444531768, 2.6996581057410647],
					[60.536094444339518, 2.6996581041038867],
					[60.536033344367347, 2.6996442045368814],
					[60.536033340711995, 2.6996442034220589],
					[60.536033332594727, 2.6996442031778138],
					[60.536033329831753, 2.6996442018888782],
					[60.535977830430582, 2.6996359020281329],
					[60.535905633994879, 2.6996248037448862],
					[60.535861136736365, 2.6996165033824511],
					[60.535861135843966, 2.699616503556566],
					[60.53580003602363, 2.6996054028032566],
					[60.535800032454055, 2.6996054034997217],
					[60.535800024250989, 2.6996054014441948],
					[60.535800019703252, 2.6996054005034975],
					[60.535744421264091, 2.6995998017620999],
					[60.535672224003065, 2.6995915006210751],
					[60.535627824941663, 2.6995859012577905],
					[60.535563926249502, 2.699577602459974],
					[60.535563925357117, 2.6995776026340885],
					[60.535519425318341, 2.6995720010652127],
					[60.535519421748795, 2.6995720017616698],
					[60.535519416308674, 2.6995720009950981],
					[60.535519413545721, 2.6995719997061647],
					[60.535438921781846, 2.6995665015365882],
					[60.535402831105515, 2.6995609026095613],
					[60.535402828342569, 2.6995609013206492],
					[60.535402822902441, 2.6995609005540806],
					[60.535402821117643, 2.6995609009023127],
					[60.535350032924782, 2.6995554028942212],
					[60.535313944770465, 2.6995470048470662],
					[60.535313939330344, 2.6995470040804999],
					[60.535313925687149, 2.6995470012584484],
					[60.535313920247027, 2.6995470004918838],
					[60.535286120983884, 2.6995442002805312],
					[60.535213923806793, 2.6995359011297473],
					[60.535169431333969, 2.6995304023678179],
					[60.535097238222093, 2.699516503378625],
					[60.535097237329708, 2.6995165035527395],
					[60.535097237329708, 2.6995165035527395],
					[60.535052837047289, 2.6995081022983758],
					[60.535052835262491, 2.6995081026466035],
					[60.535052829822376, 2.699508101880046],
					[60.535052827145215, 2.6995081024023864],
					[60.534972237575722, 2.6994970024782821],
					[60.534927849251574, 2.6994859063450805],
					[60.534927844703837, 2.6994859054044187],
					[60.534927838371345, 2.6994859048119793],
					[60.534927833823595, 2.6994859038713037],
					[60.534863939348448, 2.6994748041037351],
					[60.53480284569816, 2.6994609058625394],
					[60.534747248570845, 2.699447006431817],
					[60.534747246786061, 2.6994470067800416],
					[60.534747244915501, 2.6994470053170514],
					[60.534747244023123, 2.6994470054911615],
					[60.534686145737524, 2.6994331045873938],
					[60.534630648607248, 2.6994192058393116],
					[60.53463064584431, 2.6994192045504382],
					[60.534630640404188, 2.6994192037839029],
					[60.534630636834613, 2.6994192044803547],
					[60.534558342995169, 2.6994054041507702],
					[60.534513948884069, 2.6993942051862567],
					[60.534513947991684, 2.699394205360369],
					[60.534513946206921, 2.6993942057085922],
					[60.534513945228738, 2.6993942040714853],
					[60.534441744896789, 2.6993776052922782],
					[60.534441743112012, 2.6993776056405032],
					[60.534441739456668, 2.6993776045257531],
					[60.534441736693701, 2.6993776032368961],
					[60.534397237385441, 2.6993692033290757],
					[60.534325040864658, 2.6993554042981209],
					[60.534288945392099, 2.6993470044412682],
					[60.53428894084437, 2.6993470035006384],
					[60.534288931834695, 2.6993470034305673],
					[60.534288927286944, 2.6993470024899429],
					[60.534227828590097, 2.6993387027663611],
					[60.534191735145512, 2.6993331029318059],
					[60.534163938655652, 2.6993276043861756],
					[60.534163936785092, 2.699327602923197],
					[60.534163933215517, 2.6993276036196479],
					[60.534163930452571, 2.6993276023307988],
					[60.534091730261672, 2.6993165021847849],
					[60.53409172482155, 2.6993165014182745],
					[60.534091715811876, 2.6993165013482101],
					[60.534091711264118, 2.6993165004075785],
					[60.534038917613813, 2.6993137019583084],
					[60.533975026051792, 2.6993054025209871],
					[60.533975018741081, 2.6993054002915344],
					[60.533975006076076, 2.6993053991067253],
					[60.533974999829333, 2.6993054003255028],
					[60.533930608388843, 2.6993054007344894],
					[60.533858314762497, 2.6992998008058073],
					[60.533858311999552, 2.6992997995169716],
					[60.533858303882255, 2.6992997992728012],
					[60.533858300312694, 2.6992997999692427],
					[60.533813899862324, 2.6992998003059614],
					[60.533733300122904, 2.6992998002937658],
					[60.533661099672045, 2.6992997993262602],
					[60.533661096994869, 2.6992997998485868],
					[60.533661091640518, 2.6992998008932414],
					[60.533661088877579, 2.6992997996044115],
					[60.533608291166161, 2.6993026008011194],
					[60.533536091850564, 2.699305399912467],
					[60.533536087388612, 2.699305400783008],
					[60.533536074809383, 2.6993054014093705],
					[60.533536070347417, 2.6993054022799123],
					[60.533499973964446, 2.6993109020496155],
					[60.533427782102635, 2.6993192015870968],
					[60.533383287249144, 2.6993220004825629],
					[60.533383283679569, 2.6993220011789902],
					[60.533383275562258, 2.6993220009348131],
					[60.533383272885111, 2.6993220014571278],
					[60.5333027729639, 2.6993331026361744],
					[60.533302770200933, 2.6993331013473565],
					[60.533302763954211, 2.6993331025660963],
					[60.533302761277035, 2.6993331030884127],
					[60.533274968093345, 2.6993387028457807],
					[60.533230575008126, 2.6993442017040157],
					[60.533230572330979, 2.6993442022263281],
					[60.533230566084242, 2.6993442034450625],
					[60.533230563321297, 2.6993442021562446],
					[60.533186062703457, 2.6993526026946029],
					[60.533186061896842, 2.6993526046798375],
					[60.53318606100445, 2.6993526048539409],
					[60.53315826123044, 2.699358103493152],
					[60.533158260338048, 2.6993581036672563],
					[60.533158258553264, 2.6993581040154644],
					[60.533077760807437, 2.6993748033073213],
					[60.53303326260697, 2.699383102746451],
					[60.533033260907985, 2.6993831049057726],
					[60.533033257338431, 2.6993831056021795],
					[60.533033254575486, 2.6993831043133665],
					[60.532961055393983, 2.6993998057529875],
					[60.532961053523408, 2.6993998042900764],
					[60.532961051824422, 2.6993998064493958],
					[60.53296105093203, 2.6993998066234952],
					[60.532916651097558, 2.6994109053455411],
					[60.532916649398572, 2.6994109075048538],
					[60.532916647613774, 2.6994109078530593],
					[60.532844353043728, 2.6994304060924046],
					[60.532780558000994, 2.6994442046754212],
					[60.53278055264667, 2.6994442057200101],
					[60.532780543722758, 2.699444207461001],
					[60.532780540153183, 2.6994442081573964],
					[60.532727740608792, 2.6994609082164591],
					[60.532663848972682, 2.6994804064392199],
					[60.532602755796781, 2.6994942041331269],
					[60.532602748743443, 2.6994942073370072],
					[60.532602733572809, 2.6994942102966419],
					[60.532602727411856, 2.6994942133264122],
					[60.532538834202938, 2.6995192116411388],
					[60.5324943399456, 2.6995331085635326],
					[60.532494339138999, 2.6995331105487175],
					[60.53249433637604, 2.6995331092599111],
					[60.532494334677033, 2.6995331114191892],
					[60.53241383937025, 2.699560909721562],
					[60.53238604338771, 2.6995692074907343],
					[60.532386036334387, 2.6995692106945546],
					[60.532386023034306, 2.6995692151170276],
					[60.532386015980961, 2.6995692183208719],
					[60.532349926597114, 2.6995859133773252],
					[60.53228884029236, 2.6996054084898393],
					[60.532244340583162, 2.699619209352389],
					[60.532244338798392, 2.6996192097005682],
					[60.532244336121231, 2.6996192102228367],
					[60.532244335228839, 2.6996192103969254],
					[60.532172134663575, 2.6996442111398005],
					[60.532172131986414, 2.6996442116620649],
					[60.532172127610238, 2.6996442143436004],
					[60.532172124933069, 2.6996442148658657],
					[60.532116629213199, 2.6996665123519969],
					[60.532055533224273, 2.6996887123516755],
					[60.531991634377356, 2.6997109106959232],
					[60.531991632592565, 2.6997109110440953],
					[60.53199163170018, 2.6997109112181814],
					[60.531991630807788, 2.6997109113922662],
					[60.531938835909266, 2.699730409799185],
					[60.531866644898912, 2.6997526079694296],
					[60.531813848722102, 2.6997665079500792],
					[60.531813847743933, 2.6997665063131104],
					[60.531741647655373, 2.6997859077303361],
					[60.531741645784798, 2.6997859062674516],
					[60.531741643193421, 2.6997859086007439],
					[60.531741641408644, 2.6997859089489067],
					[60.531705551697705, 2.6997970067731694],
					[60.531633262021366, 2.6998109037974531],
					[60.531633256667028, 2.6998109048419354],
					[60.531633246850731, 2.6998109067568219],
					[60.531633243281156, 2.6998109074531462],
					[60.531605555391728, 2.699819205609586],
					[60.531588866681517, 2.6998220023295634],
					[60.531588864896726, 2.6998220026777244],
					[60.53158886132718, 2.6998220033740417],
					[60.531588858649975, 2.6998220038962848],
					[60.531508259196755, 2.6998387043569632],
					[60.53150825830437, 2.6998387045310408],
					[60.53150825562718, 2.6998387050532782],
					[60.531508254734796, 2.699838705227358],
					[60.53147216389506, 2.6998470029724859],
					[60.531391672901023, 2.6998581019679841],
					[60.531391670223854, 2.6998581024902175],
					[60.531391664869517, 2.6998581035346816],
					[60.531391663084712, 2.6998581038828386],
					[60.531347165981323, 2.6998665023858335],
					[60.531274969900977, 2.69987760259882],
					[60.531274968116186, 2.6998776029469709],
					[60.531274965439017, 2.6998776034692011],
					[60.531274962676065, 2.6998776021804054],
					[60.531230565399191, 2.6998859025496427],
					[60.531166666017697, 2.699897003649895],
					[60.531166662448122, 2.6998970043461958],
					[60.531166652631825, 2.6998970062610184],
					[60.531166649062264, 2.6998970069573192],
					[60.531113851967703, 2.6999109065058211],
					[60.531041655356248, 2.6999276049931447],
					[60.531041650087701, 2.6999276078485908],
					[60.531041639378998, 2.6999276099374687],
					[60.531041634024653, 2.6999276109819088],
					[60.530986037032427, 2.6999470097500335],
					[60.530916639001774, 2.6999692101612052],
					[60.530916630970268, 2.6999692117278431],
					[60.530916615078787, 2.6999692184831274],
					[60.530916607218828, 2.6999692236717721],
					[60.530852706611221, 2.7000026230568439],
					[60.530852705718836, 2.700002623230914],
					[60.530852704826437, 2.700002623404985],
					[60.530816604883711, 2.7000220241721258],
					[60.530816603991333, 2.7000220243461919],
					[60.530816601399934, 2.7000220266793953],
					[60.53081659961515, 2.7000220270275355],
					[60.530763800022136, 2.7000526266317837],
					[60.53076379564596, 2.7000526293131171],
					[60.530763787785979, 2.7000526345017324],
					[60.53076378519458, 2.7000526368349318],
					[60.530716589225648, 2.7000859340150432],
					[60.530663793065557, 2.7001192304576653],
					[60.530663786097982, 2.7001192354721826],
					[60.530663771356203, 2.7001192474862381],
					[60.530663764388621, 2.7001192525007571],
					[60.530591564546071, 2.7001859517662066],
					[60.530502666025477, 2.7002665523903233],
					[60.530502660756902, 2.7002665552456637],
					[60.530502653154215, 2.7002665658671288],
					[60.530502647971417, 2.700266570533449],
					[60.530485949340864, 2.7002859681137799],
					[60.530466550919471, 2.7003081677614373],
					[60.530377653186356, 2.7004054636511645],
					[60.530288759366229, 2.7004998576544033],
					[60.530252663547131, 2.700533152670157],
					[60.530252658450088, 2.7005331591473642],
					[60.530252647277827, 2.7005331704648832],
					[60.530252642266547, 2.7005331787530795],
					[60.53022484505486, 2.700569272697912],
					[60.530152653827024, 2.7006526642557751],
					[60.530072059296891, 2.7007331580026905],
					[60.530072056705478, 2.7007331603357816],
					[60.530072055006436, 2.7007331624948301],
					[60.530072053307421, 2.700733164653879],
					[60.53003595265583, 2.7007720637913213],
					[60.530002664026242, 2.7008081535690671],
					[60.529910975968534, 2.7008804434327862],
					[60.529910974269519, 2.7008804455918112],
					[60.529910969000916, 2.700880448446997],
					[60.529910966409503, 2.7008804507800823],
					[60.529830466267853, 2.7009526510078534],
					[60.529830464568839, 2.7009526531668895],
					[60.529830461085027, 2.7009526556739818],
					[60.529830459385984, 2.700952657833001],
					[60.529813782063563, 2.7009692358458293],
					[60.529786011097329, 2.7009831212066393],
					[60.529786002344913, 2.7009831265688815],
					[60.529785985646761, 2.7009831353083928],
					[60.529785977872507, 2.7009831423075581],
					[60.529713785015851, 2.701038736854338],
					[60.529652701108802, 2.7010776256441691],
					[60.529580510714609, 2.701113721767769],
					[60.529508214954546, 2.7011498181619857],
					[60.529463827026483, 2.701169213679476],
					[60.529383235176994, 2.7011970108041155],
					[60.529383230715034, 2.7011970116742492],
					[60.529383223661647, 2.7011970148773807],
					[60.529383220984457, 2.7011970153994627],
					[60.529363848424261, 2.701205404412542],
					[60.529338879026568, 2.7012081006314173],
					[60.529338869210243, 2.7012081025456993],
					[60.529338849577584, 2.7012081063742688],
					[60.52933884073942, 2.7012081099254472],
					[60.529266646251862, 2.7012304074577784],
					[60.529222155380481, 2.7012415051840453],
					[60.529158263249499, 2.7012554029968894],
					[60.529105569221869, 2.7012637011985352],
					[60.529105566630456, 2.7012637035315086],
					[60.529105563867496, 2.7012637022426729],
					[60.529105562168461, 2.7012637044016254],
					[60.529033266272364, 2.7012776033316492],
					[60.528977770364719, 2.7012859013723189],
					[60.528977766880907, 2.701285903879318],
					[60.52897775876361, 2.7012859036345929],
					[60.52897775608642, 2.7012859041566561],
					[60.528916656093621, 2.7012998055542039],
					[60.528916655201236, 2.7012998057282216],
					[60.528916653416452, 2.7012998060762627],
					[60.528916650739291, 2.701299806598322],
					[60.528861054413746, 2.7013137056883147],
					[60.528799956115101, 2.7013276048108072],
					[60.528799951653141, 2.7013276056808988],
					[60.52879994459974, 2.7013276088839397],
					[60.528799941030165, 2.7013276095800154],
					[60.528727740987527, 2.7013498087870556],
					[60.528727735718917, 2.7013498116420638],
					[60.528727725095955, 2.7013498155411613],
					[60.528727719741589, 2.7013498165852652],
					[60.528683222127349, 2.7013692162279277],
					[60.528602722654128, 2.7014026157506446],
					[60.52860271738551, 2.7014026186056168],
					[60.528602704171135, 2.701402624837602],
					[60.528602697117734, 2.7014026280406029],
					[60.528574899771804, 2.7014192279146174],
					[60.52853050244169, 2.7014442258424718],
					[60.528530497173065, 2.7014442286974334],
					[60.528530485829208, 2.7014442363922515],
					[60.528530481453004, 2.7014442390731905],
					[60.528477689002891, 2.7014831334561724],
					[60.528422097298481, 2.701516529687872],
					[60.52842209024508, 2.7015165328908459],
					[60.52842207720218, 2.7015165427444843],
					[60.528422071126919, 2.7015165475843168],
					[60.528349871316287, 2.7015776462497239],
					[60.528349869617244, 2.701577648408608],
					[60.52834986872486, 2.7015776485826168],
					[60.528349867832475, 2.7015776487566234],
					[60.528260967665638, 2.7016554490450244],
					[60.528260965966595, 2.7016554512039166],
					[60.52826096078374, 2.7016554558696688],
					[60.528260959084704, 2.701655458028541],
					[60.528244267085263, 2.7016720504639236],
					[60.528216575167903, 2.7016942436715268],
					[60.528216568285998, 2.7016942504961561],
					[60.528216553544048, 2.7016942625085432],
					[60.528216547640277, 2.7016942709700276],
					[60.528135951033768, 2.7017887656793018],
					[60.528047053989162, 2.7018831622148323],
					[60.528047054074911, 2.7018831640256984],
					[60.528047053182526, 2.701883164199693],
					[60.528047052375875, 2.7018831661845453],
					[60.528019254888768, 2.7019137627079957],
					[60.527983159090546, 2.7019498589060107],
					[60.527983154800054, 2.7019498633976831],
					[60.527983147111492, 2.7019498722070483],
					[60.527983142821014, 2.7019498766987184],
					[60.52791094530761, 2.7020415737552401],
					[60.52784154639567, 2.7021248717170643],
					[60.527841544696628, 2.7021248738758823],
					[60.5278415421052, 2.7021248762086918],
					[60.52784154129855, 2.7021248781935379],
					[60.527797043633591, 2.7021831757054229],
					[60.52774154697817, 2.7022498713592373],
					[60.5276887552182, 2.7023109624841917],
					[60.527633162179974, 2.7023637542085006],
					[60.527633161373323, 2.7023637561933112],
					[60.527633157082832, 2.7023637606848898],
					[60.52763315619044, 2.7023637608588724],
					[60.527563755822456, 2.70243596078633],
					[60.527563755015791, 2.702435962771133],
					[60.52756375420914, 2.7024359647559542],
					[60.527563753316748, 2.7024359649299319],
					[60.527499855685043, 2.7025054615808952],
					[60.527455459036624, 2.7025498598888782],
					[60.527455456359448, 2.7025498604108034],
					[60.527455451176557, 2.7025498650762936],
					[60.527455448670821, 2.7025498692198773],
					[60.527419259002478, 2.7025915562942329],
					[60.52734707103771, 2.7026526463108627],
					[60.527347069338653, 2.7026526484696185],
					[60.527347065048126, 2.7026526529611106],
					[60.527347062456684, 2.7026526552938366],
					[60.52729427357152, 2.7027026439842667],
					[60.527222086128653, 2.7027526366673595],
					[60.527222082559064, 2.702752637363234],
					[60.527222077376173, 2.7027526420286554],
					[60.527222073892297, 2.7027526445353378],
					[60.527149874422122, 2.7028109435741103],
					[60.527149872723044, 2.7028109457328489],
					[60.527149869239196, 2.7028109482395148],
					[60.52714986664774, 2.7028109505722155],
					[60.527124875586679, 2.7028331436077413],
					[60.527097088003245, 2.7028526342676926],
					[60.527016590808458, 2.7029054322228312],
					[60.526952700408565, 2.7029470265209712],
					[60.526880510537822, 2.702983120375984],
					[60.526880508838765, 2.7029831225346901],
					[60.526880505269169, 2.7029831232305317],
					[60.526880502677699, 2.7029831255631982],
					[60.526799902613341, 2.7030276259023744],
					[60.526799894667505, 2.7030276292787949],
					[60.526799879925406, 2.7030276412899981],
					[60.526799872065297, 2.7030276464772038],
					[60.526783189613475, 2.7030415326501314],
					[60.526755509626518, 2.7030554207536319],
					[60.526683211945127, 2.7030915205096511],
					[60.526630515102887, 2.7031165186089199],
					[60.52655821635426, 2.7031498185693854],
					[60.526558211892294, 2.7031498194391488],
					[60.526558204032163, 2.7031498246262653],
					[60.526558199655916, 2.703149827306814],
					[60.526505506674994, 2.7031804238520825],
					[60.526441614114908, 2.7032109208445831],
					[60.526441613136797, 2.7032109192077671],
					[60.526361012759928, 2.7032498208028493],
					[60.526361006598876, 2.7032498238312477],
					[60.526360995169156, 2.7032498297140997],
					[60.526360989093824, 2.7032498345532803],
					[60.526335997004161, 2.7032665279945967],
					[60.526299908167871, 2.7032859216766458],
					[60.526227711298148, 2.7033220207863242],
					[60.526227703438018, 2.7033220259733417],
					[60.526227686739659, 2.7033220347105655],
					[60.526227679771928, 2.7033220397236555],
					[60.526183181017728, 2.7033554389637637],
					[60.526110982892007, 2.703408138653312],
					[60.526022083138145, 2.7034720374100947],
					[60.526022071879858, 2.7034720469143041],
					[60.52602205212623, 2.7034720672116372],
					[60.526022041760349, 2.7034720765418898],
					[60.526002658422556, 2.7034970567160084],
					[60.52596657775468, 2.7035248418017654],
					[60.525966573464125, 2.7035248462929382],
					[60.525966563012531, 2.7035248538124304],
					[60.52596655872199, 2.7035248583036005],
					[60.525885959077286, 2.7036054588070435],
					[60.525885957292481, 2.703605459154915],
					[60.525885953001925, 2.7036054636460634],
					[60.52588595219526, 2.7036054656307509],
					[60.525805451606367, 2.7036942662373784],
					[60.525805449014875, 2.7036942685699095],
					[60.525805443025263, 2.7036942752196431],
					[60.525805439627099, 2.7036942795368368],
					[60.525780446363619, 2.7037276716872025],
					[60.525752651795763, 2.7037581650420495],
					[60.525752650096685, 2.7037581672006392],
					[60.52575264499945, 2.7037581736764054],
					[60.525752643386092, 2.7037581776457391],
					[60.525672043458471, 2.7038609766976687],
					[60.525591544113951, 2.703960974262964],
					[60.525591543307257, 2.7039609762476253],
					[60.525591539909087, 2.7039609805647622],
					[60.525591538210023, 2.7039609827233262],
					[60.525547041812565, 2.7040220781868847],
					[60.525483144613425, 2.7040998725418115],
					[60.525483139601874, 2.7040998808281871],
					[60.525483130299783, 2.7040998936055964],
					[60.52548312618066, 2.7040999017180645],
					[60.525447035695365, 2.7041637827477207],
					[60.525402650015899, 2.7042137672854851],
					[60.52540264831682, 2.7042137694440225],
					[60.52540264321955, 2.7042137759196661],
					[60.525402640628087, 2.7042137782521194],
					[60.525322041338214, 2.7043192793953685],
					[60.525322039553394, 2.7043192797431983],
					[60.525322038746715, 2.7043192817278294],
					[60.525322037940036, 2.7043192837124486],
					[60.52524983770175, 2.7044192825513722],
					[60.525249836895057, 2.7044192845359833],
					[60.525249836088342, 2.7044192865205976],
					[60.525249835195957, 2.7044192866945056],
					[60.525213741159803, 2.7044720788575969],
					[60.52516094858931, 2.7045331686315341],
					[60.525160946975916, 2.7045331726007431],
					[60.525160942685346, 2.7045331770916778],
					[60.525160940986261, 2.7045331792501828],
					[60.525108146242637, 2.7046026716248153],
					[60.525052651979728, 2.7046637666321582],
					[60.525052650280649, 2.7046637687906481],
					[60.525052646796759, 2.7046637712969419],
					[60.525052645097674, 2.7046637734554517],
					[60.524991549420506, 2.704738769794933],
					[60.524919252615859, 2.7048165643473849],
					[60.524919249217668, 2.7048165686643375],
					[60.524919241528877, 2.7048165774721538],
					[60.524919238216391, 2.7048165835997926],
					[60.524883137987963, 2.7048665836924126],
					[60.524883133697358, 2.7048665881832514],
					[60.524883127072393, 2.7048666004385171],
					[60.524883122867493, 2.7048666067400542],
					[60.524858126123021, 2.7049138016919168],
					[60.524785929416232, 2.7050331964283423],
					[60.52478592771714, 2.705033198586793],
					[60.52478592771714, 2.705033198586793],
					[60.524730429126748, 2.705127695666246],
					[60.524660929650899, 2.7052387938675535],
					[60.524660928929876, 2.705238797662799],
					[60.524660924724984, 2.7052388039642219],
					[60.524660923025891, 2.7052388061226584],
					[60.524605425259793, 2.7053443030584767],
					[60.524544226652793, 2.7054498998302932],
					[60.524544225846078, 2.7054499018148364],
					[60.524544225039385, 2.7054499037993933],
					[60.524544225039385, 2.7054499037993933],
					[60.524480424864173, 2.7055666039084119],
					[60.524480424057479, 2.7055666058929608],
					[60.524480423165073, 2.7055666060668346],
					[60.524480423165073, 2.7055666060668346],
					[60.524427622770546, 2.7056666076693605],
					[60.524427621878147, 2.7056666078432285],
					[60.524427621963838, 2.7056666096539073],
					[60.524363721792689, 2.7057916099315751],
					[60.524363720985995, 2.7057916119160947],
					[60.524363719286868, 2.7057916140744847],
					[60.524363717587768, 2.7057916162328861],
					[60.524319221337322, 2.7058888114439941],
					[60.524249824233543, 2.7060166049211718],
					[60.524249822620121, 2.7060166088902058],
					[60.524249820199984, 2.7060166148437319],
					[60.524249817608471, 2.7060166171759592],
					[60.524205421097243, 2.7061138105443945],
					[60.524133123841466, 2.7062471055040889],
					[60.524133121421336, 2.7062471114575759],
					[60.524133114796214, 2.7062471237122927],
					[60.524133113268462, 2.7062471294919321],
					[60.524097015900665, 2.7063443226135671],
					[60.524033119584907, 2.70647771417176],
					[60.524033118692508, 2.7064777143456027],
					[60.523980418694315, 2.7065888145226475],
					[60.523980417887586, 2.706588816507129],
					[60.523980416274114, 2.7065888204761053],
					[60.52398041466067, 2.7065888244450629],
					[60.523927614938387, 2.7067166235307862],
					[60.523874815906929, 2.7068416225779393],
					[60.523874815014501, 2.7068416227517749],
					[60.523874815100186, 2.7068416245624256],
					[60.523874815100186, 2.7068416245624256],
					[60.523827615679501, 2.7069582213292089],
					[60.523766518178306, 2.7070916171330257],
					[60.523766515672435, 2.7070916212757576],
					[60.523766513423588, 2.7070916308503921],
					[60.523766510917731, 2.7070916349931218],
					[60.523730414631828, 2.7071971235948884],
					[60.523658120085848, 2.7073471132387978],
					[60.523658119279105, 2.7073471152232571],
					[60.523658115966484, 2.7073471213503968],
					[60.523658115245439, 2.7073471251454753],
					[60.523622016383705, 2.7074360215915343],
					[60.523552616754387, 2.7075916189579683],
					[60.523552616840057, 2.7075916207685875],
					[60.523552616033292, 2.7075916227530183],
					[60.523552615226571, 2.7075916247374425],
					[60.523533115322856, 2.7076388247922352],
					[60.523533115322856, 2.7076388247922352],
					[60.523533113623699, 2.7076388269504705],
					[60.523533113623699, 2.7076388269504705],
					[60.523508114637437, 2.7077027250587471],
					[60.52345261468907, 2.7078360237126913],
					[60.523419215688065, 2.7079166216796837],
					[60.5233554213508, 2.7080638106499006],
					[60.523302625037203, 2.70815820286886],
					[60.523302621638891, 2.7081582071852419],
					[60.523302616884067, 2.7081582209022583],
					[60.523302613571417, 2.7081582270292626],
					[60.523247016750027, 2.7082999191208006],
					[60.523194221477439, 2.7084055116560983],
					[60.523194219778269, 2.7084055138142675],
					[60.523194217358025, 2.7084055197674437],
					[60.523194214852111, 2.7084055239099989],
					[60.523141518397615, 2.7085332160841666],
					[60.523088722002662, 2.7086360086847998],
					[60.523088721195897, 2.7086360106691916],
					[60.523088717883226, 2.7086360167960897],
					[60.523088717162118, 2.7086360205910771],
					[60.523033119007152, 2.7087638146616415],
					[60.52298042237382, 2.7088693097140704],
					[60.522916523146719, 2.7089916057094037],
					[60.522863725606314, 2.7090888019106498],
					[60.522791526860324, 2.7092138001932478],
					[60.522791525161146, 2.7092138023513499],
					[60.522791521848447, 2.7092138084781343],
					[60.522791520234925, 2.7092138124468312],
					[60.522747025291793, 2.7093055029124389],
					[60.522666531052437, 2.7094331934305744],
					[60.522666529353245, 2.709433195588673],
					[60.52266652684731, 2.7094331997310808],
					[60.522666526040545, 2.7094332017154081],
					[60.522641527341356, 2.7094777002285748],
					[60.522605428141652, 2.7095387970034155],
					[60.522541529165039, 2.7096442958596585],
					[60.522541528358282, 2.709644297843969],
					[60.522541524959927, 2.7096443021600924],
					[60.522541524153141, 2.7096443041444065],
					[60.522505426762571, 2.7097110006751479],
					[60.522433131012839, 2.709827693687195],
					[60.52237203615325, 2.7099220858495854],
					[60.52229983936283, 2.7100192803128733],
					[60.522299837663645, 2.7100192824708973],
					[60.522299834350882, 2.7100192885975081],
					[60.522299831759305, 2.7100192909292811],
					[60.522227631756543, 2.7101304912577713],
					[60.522227631756543, 2.7101304912577713],
					[60.522227630864144, 2.7101304914314999],
					[60.522227630949764, 2.7101304932420573],
					[60.522194230725859, 2.7101831931581479],
					[60.52219422991908, 2.7101831951424273],
					[60.522194227413095, 2.7101831992847201],
					[60.52219422571391, 2.7101832014427281],
					[60.522158132239383, 2.7102470910428522],
					[60.522094238908835, 2.7103331807384983],
					[60.52209423899447, 2.710333182549042],
					[60.522094238102071, 2.7103331827227661],
					[60.522049837612371, 2.710394281783544],
					[60.522049836891206, 2.710394285578372],
					[60.522049834385236, 2.7103942897206252],
					[60.52204983170801, 2.7103942902417879],
					[60.521977632343614, 2.7105054904955299],
					[60.521977632429248, 2.7105054923060687],
					[60.521977630644436, 2.710505492653505],
					[60.521977630730042, 2.7105054944640488],
					[60.521916532919789, 2.7106026892314015],
					[60.521852637626644, 2.7106942832671805],
					[60.521772039556936, 2.7108026804798238],
					[60.521772036244151, 2.7108026866062525],
					[60.521772027748135, 2.7108026973960069],
					[60.521772023542958, 2.7108027036961562],
					[60.521755437819706, 2.7108331793052405],
					[60.521727658601847, 2.7108609587144534],
					[60.521727650912624, 2.7108609675199404],
					[60.521727635619804, 2.7108609869414284],
					[60.521727628908621, 2.7108609973837381],
					[60.521655437403702, 2.7109804838300415],
					[60.521583147062984, 2.7110665715009743],
					[60.521583145363792, 2.711066573658893],
					[60.521583141879759, 2.7110665761642303],
					[60.521583141072966, 2.7110665781484511],
					[60.521522043192185, 2.7111470755870903],
					[60.521441545618252, 2.7112442722078249],
					[60.521441538907041, 2.7112442826499579],
					[60.521441527183796, 2.7112443013763334],
					[60.521441521279385, 2.7112443098342398],
					[60.521422040366417, 2.711283174188138],
					[60.521385971407902, 2.7113137466948758],
					[60.521385961933831, 2.711313755847526],
					[60.521385947362113, 2.7113137714738942],
					[60.521385938780476, 2.7113137804528487],
					[60.521305439274876, 2.7114220803123845],
					[60.521305439360496, 2.7114220821228887],
					[60.521235938613962, 2.7115165816391391],
					[60.521235937721535, 2.711516581812826],
					[60.521191544584028, 2.7115776742152344],
					[60.521135952051154, 2.7116387644304272],
					[60.521135951244347, 2.7116387664146231],
					[60.521135950437547, 2.7116387683988012],
					[60.521135948652734, 2.7116387687461607],
					[60.521083150291226, 2.7116998685767473],
					[60.52103045176132, 2.7117581660107573],
					[60.521030450868921, 2.7117581661844339],
					[60.521030450868921, 2.7117581661844339],
					[60.521030450062113, 2.7117581681685987],
					[60.520966553107264, 2.711830464606416],
					[60.520902655937782, 2.7118970619099336],
					[60.520902655045369, 2.7118970620836054],
					[60.520902654152955, 2.711897062257278],
					[60.520858154295382, 2.7119442623319219],
					[60.52085815089692, 2.7119442666475719],
					[60.52085814410006, 2.7119442752788827],
					[60.520858140701606, 2.7119442795945368],
					[60.520822048741003, 2.711991568129998],
					[60.520741558755311, 2.7120720588149445],
					[60.520741556970485, 2.7120720591622773],
					[60.520741555356864, 2.7120720631305639],
					[60.52074155267961, 2.7120720636515658],
					[60.520672052780249, 2.7121470642823469],
					[60.520635953182826, 2.7121859635870327],
					[60.520599857863509, 2.7122248583138764],
					[60.520519263767525, 2.7122998538015821],
					[60.520519261982713, 2.7122998541488963],
					[60.520519257691824, 2.7122998586381244],
					[60.52051925599261, 2.7122998607959206],
					[60.520447055925921, 2.712374861416567],
					[60.520447055033507, 2.7123748615902237],
					[60.520447054226686, 2.7123748635743596],
					[60.520447053334273, 2.7123748637480132],
					[60.52041095285513, 2.712413764500671],
					[60.520374855761688, 2.712452660849181],
					[60.520294258664769, 2.7125331572139513],
					[60.520294257857955, 2.7125331591980557],
					[60.520294257051134, 2.7125331611821588],
					[60.520294256158721, 2.7125331613558084],
					[60.520222056836445, 2.7126081607376142],
					[60.520185963131908, 2.7126442531791324],
					[60.52014156767811, 2.7126831503273765],
					[60.520141563387234, 2.7126831548165109],
					[60.520141552128223, 2.7126831643156972],
					[60.520141547030505, 2.7126831707889081],
					[60.52008045569729, 2.7127554599678612],
					[60.519999866589345, 2.7128276508938125],
					[60.519999865782523, 2.7128276528778916],
					[60.51999986399769, 2.7128276532251703],
					[60.519999863105291, 2.71282765339881],
					[60.519955462994695, 2.7128692532241829],
					[60.519955459596218, 2.7128692575396283],
					[60.519955451821275, 2.7128692645336829],
					[60.519955449315191, 2.7128692686754752],
					[60.519919252097786, 2.7129109642295175],
					[60.5198470569119, 2.7129859591816299],
					[60.519774859235881, 2.7130581588305209],
					[60.519774855751805, 2.7130581613354749],
					[60.519774850568481, 2.7130581659981234],
					[60.51977484886924, 2.7130581681558326],
					[60.519738749164674, 2.7130998698507947],
					[60.519702651866062, 2.7131415654988289],
					[60.519630460018178, 2.7132165569074669],
					[60.519549863760176, 2.7132915533413184],
					[60.519549858662437, 2.7132915598143108],
					[60.519549849188202, 2.7132915689658539],
					[60.51954984409047, 2.7132915754388605],
					[60.519516545696618, 2.7133331718742926],
					[60.519480450993498, 2.7133748664439676],
					[60.51939985516379, 2.7134609614086447],
					[60.519308155637809, 2.7135554609712278],
					[60.519308149647657, 2.7135554676177387],
					[60.519308138645314, 2.7135554825475898],
					[60.519308132655148, 2.7135554891941056],
					[60.519291541883206, 2.7135804770628944],
					[60.519263751590529, 2.7136109652774421],
					[60.51926375069813, 2.7136109654510543],
					[60.519263749891287, 2.7136109674350668],
					[60.519263749084452, 2.7136109694190806],
					[60.519174849155945, 2.7137137682231458],
					[60.519174848349103, 2.7137137702071525],
					[60.519094250346569, 2.71380816776486],
					[60.519066551658106, 2.71383876565204],
					[60.519066549152001, 2.7138387697936439],
					[60.519066543161827, 2.7138387764400673],
					[60.519066539763308, 2.7138387807552711],
					[60.519041549433403, 2.7138720691385281],
					[60.518949859138459, 2.7139637582606682],
					[60.518949854040663, 2.713963764733434],
					[60.518949844566379, 2.7139637738846023],
					[60.518949841167881, 2.7139637781997932],
					[60.518888746235803, 2.7140442715994761],
					[60.518833152385987, 2.7141054663384505],
					[60.518833149794318, 2.7141054686696156],
					[60.518833144610959, 2.714105473331955],
					[60.518833142911703, 2.7141054754895264],
					[60.518780443377892, 2.7141720758884227],
					[60.518780441593059, 2.7141720762356121],
					[60.518780439979373, 2.7141720802035523],
					[60.518780438280089, 2.7141720823611353],
					[60.518735949234085, 2.7142331664732309],
					[60.518683162473252, 2.7142831557159361],
					[60.518683160688411, 2.7142831560631193],
					[60.518683158182313, 2.7142831602046473],
					[60.518683156397472, 2.7142831605518274],
					[60.518610956395861, 2.7143581611840406],
					[60.518610954696605, 2.7143581633415992],
					[60.518610952104922, 2.7143581656727305],
					[60.518610951298065, 2.714358167656687],
					[60.518538751146352, 2.71443876775268],
					[60.518538749361518, 2.7144387680998516],
					[60.518538745962964, 2.714438772414923],
					[60.518538745156107, 2.7144387743988729],
					[60.518502649006841, 2.7144831687799664],
					[60.518466553191743, 2.7145220637046523],
					[60.51846654890079, 2.7145220681933027],
					[60.518466539597618, 2.7145220809648802],
					[60.518466536199057, 2.7145220852799339],
					[60.518397040400252, 2.7146220802313326],
					[60.518316544243945, 2.7147220730712194],
					[60.518280444921338, 2.7147665733844804],
					[60.518280443222068, 2.7147665755419954],
					[60.518280439909091, 2.7147665816673436],
					[60.518280438209821, 2.7147665838248423],
					[60.518244242713472, 2.7148165764097874],
					[60.518172048959357, 2.7148998698665552],
					[60.518172046367653, 2.7148998721976287],
					[60.518172041269843, 2.7148998786700784],
					[60.51817203876368, 2.7148998828114825],
					[60.518119238750508, 2.7149720814107119],
					[60.518119237051238, 2.7149720835681879],
					[60.518119233652698, 2.7149720878831558],
					[60.518119232845834, 2.7149720898670688],
					[60.51805543934374, 2.715069280041825],
					[60.517966547150309, 2.7151748716981592],
					[60.51796654285932, 2.7151748761866505],
					[60.51796653525534, 2.7151748868003875],
					[60.51796653104995, 2.715174893099189],
					[60.517947040589071, 2.7152054762696616],
					[60.517910953941644, 2.7152442637293159],
					[60.517910947951364, 2.7152442703752251],
					[60.517910937755687, 2.7152442833199473],
					[60.517910932657855, 2.7152442897922926],
					[60.517841534594169, 2.715349887549499],
					[60.517785939409812, 2.7154276815839529],
					[60.517716540629259, 2.7155192786346527],
					[60.517716539822395, 2.7155192806185222],
					[60.517716538123125, 2.7155192827759418],
					[60.517716537316232, 2.715519284759834],
					[60.517635939365718, 2.7156331813781378],
					[60.51760814176771, 2.7156692781087854],
					[60.517608139982876, 2.7156692784558754],
					[60.517608138369148, 2.7156692824235966],
					[60.517608136669857, 2.7156692845810184],
					[60.517580440638042, 2.7157081786579549],
					[60.517499845992951, 2.7158054721553357],
					[60.517499845186066, 2.7158054741391844],
					[60.517499841701948, 2.7158054766436566],
					[60.51749984089507, 2.7158054786275083],
					[60.51743874087488, 2.715885978381364],
					[60.517438739261145, 2.7158859823490675],
					[60.517438734970128, 2.715885986837371],
					[60.517438733356393, 2.715885990805059],
					[60.517374835899794, 2.7159831843762308],
					[60.51729423980423, 2.7160915809106849],
					[60.51729423810491, 2.7160915830680503],
					[60.517294234706334, 2.7160915873827878],
					[60.517294233007043, 2.7160915895401501],
					[60.5172776417999, 2.7161165763288695],
					[60.517249852011162, 2.716147065653661],
					[60.517249848612586, 2.7161470699683683],
					[60.517249841815385, 2.7161470785978103],
					[60.517249838416795, 2.716147082912522],
					[60.51716924009861, 2.7162581792139768],
					[60.517105441641405, 2.7163415768996955],
					[60.517044243274178, 2.7164192765736561],
					[60.517044242381758, 2.7164192767471769],
					[60.517044239875581, 2.7164192808883132],
					[60.517044238983139, 2.7164192810618331],
					[60.516972038923832, 2.7165165812180301],
					[60.516972038923832, 2.7165165812180301],
					[60.516972038116947, 2.7165165832018299],
					[60.516972037224527, 2.7165165833753471],
					[60.516944252010347, 2.716555462173694],
					[60.516908170862742, 2.7165859478840115],
					[60.51690816567929, 2.716585952545659],
					[60.516908157011706, 2.7165859597116611],
					[60.516908151828233, 2.7165859643733121],
					[60.516819252197571, 2.7166831655022778],
					[60.516819251390693, 2.7166831674860634],
					[60.516819247992075, 2.7166831718006774],
					[60.516819246207227, 2.7166831721477007],
					[60.516738745933708, 2.7167804734105792],
					[60.51673874414886, 2.7167804737575967],
					[60.516738741642662, 2.7167804778986646],
					[60.516738739943364, 2.7167804800559496],
					[60.516722054731524, 2.7168026599101358],
					[60.51669427501065, 2.716824844671577],
					[60.516694268042343, 2.716824849680183],
					[60.516694255169227, 2.7168248631444119],
					[60.516694249178869, 2.7168248697897659],
					[60.516605449140677, 2.7169276697259228],
					[60.516605447355829, 2.7169276700729328],
					[60.516605443957204, 2.7169276743874895],
					[60.516605443150318, 2.7169276763712569],
					[60.516533149538212, 2.7170192666653015],
					[60.516488758119351, 2.717063758846292],
					[60.516488756420053, 2.7170637610035442],
					[60.516488751236579, 2.7170637656650549],
					[60.516488748730389, 2.7170637698060789],
					[60.516452654773161, 2.7171054612593415],
					[60.516380464103918, 2.7171748523131463],
					[60.516299867244008, 2.7172470501127388],
					[60.516299864652275, 2.7172470524434771],
					[60.51629986125365, 2.7172470567579414],
					[60.516299858661888, 2.7172470590886717],
					[60.516263758963632, 2.7172831591205848],
					[60.516263753780159, 2.7172831637820209],
					[60.516263744391146, 2.7172831747416701],
					[60.516263740100094, 2.7172831792296166],
					[60.516238745836141, 2.7173165703542694],
					[60.516158154268034, 2.7174026634209465],
					[60.516158153375592, 2.7174026635944371],
					[60.516158151676287, 2.7174026657516506],
					[60.516158150783873, 2.7174026659251354],
					[60.516085950812538, 2.7174831665973325],
					[60.516085948306298, 2.7174831707382618],
					[60.516085940616598, 2.717483179540586],
					[60.516085938110379, 2.7174831836815119],
					[60.516049853644517, 2.7175331607342903],
					[60.515997076042034, 2.7175748424253756],
					[60.515997074342728, 2.7175748445825669],
					[60.515997069966133, 2.7175748472602228],
					[60.515997067374379, 2.7175748495909016],
					[60.515924868331822, 2.7176387486168445],
					[60.515844269652632, 2.7177081475781479],
					[60.515844267953298, 2.7177081497353273],
					[60.515844265361558, 2.7177081520659798],
					[60.515844263576703, 2.7177081524129316],
					[60.51580816414581, 2.7177415530624263],
					[60.515808152971907, 2.7177415643687328],
					[60.515808132579998, 2.717741590254839],
					[60.515808124254413, 2.7177416046611635],
					[60.515772029396622, 2.7178081963315095],
					[60.515708133744461, 2.7179026875657],
					[60.515708132130662, 2.7179026915330726],
					[60.515708127118195, 2.7179026998147768],
					[60.515708123805048, 2.7179027059393119],
					[60.51567202635443, 2.7179693013630573],
					[60.515602629234259, 2.7180887965266494],
					[60.515547037126019, 2.7181776828894266],
					[60.515485964546166, 2.7182526486710188],
					[60.515430490670362, 2.7182887325643175],
					[60.51543048897102, 2.7182887347214382],
					[60.515430484594418, 2.7182887373989257],
					[60.51543048280957, 2.7182887377458393],
					[60.515369303873243, 2.7183331225764875],
					[60.515297127975742, 2.7183609124250832],
					[60.515297125383981, 2.7183609147556447],
					[60.515297119222524, 2.7183609177800232],
					[60.515297115652835, 2.7183609184738398],
					[60.515261016132236, 2.7183776188477213],
					[60.515260993442261, 2.7183776342187063],
					[60.515260950996144, 2.7183776698709075],
					[60.515260932217913, 2.7183776917888651],
					[60.515224832715226, 2.7184331900193786],
					[60.515135956183599, 2.7185665541742821],
					[60.51506381192516, 2.7186137176934184],
					[60.515038836806276, 2.7186220097398133],
					[60.515038806976882, 2.7186220264980756],
					[60.515038753100171, 2.7186220680248558],
					[60.515038730030803, 2.7186220944301076],
					[60.515010929787593, 2.7186665947200108],
					[60.515010928088223, 2.7186665968770845],
					[60.515010923882642, 2.718666603174861],
					[60.515010923246749, 2.7186666087788471],
					[60.514938724277165, 2.7188055042199117],
					[60.514894228948933, 2.7188859959436935],
					[60.514813733299981, 2.719008189134255],
					[60.514813730793719, 2.7190081932749135],
					[60.514813728287415, 2.7190081974155622],
					[60.514813726588088, 2.7190081995725968],
					[60.514760928971967, 2.7190998965769499],
					[60.514697042301705, 2.7191998752664164],
					[60.514608156272551, 2.7192915606542347],
					[60.514608152873862, 2.7192915649682656],
					[60.514608145184013, 2.7192915737697438],
					[60.514608141699796, 2.7192915762736032],
					[60.514588762038763, 2.7193165511857083],
					[60.514563790310056, 2.7193331336151707],
					[60.514483189573163, 2.7193859329238994],
					[60.514483188680721, 2.7193859330973242],
					[60.51448318698138, 2.7193859352543157],
					[60.514483186088938, 2.7193859354277379],
					[60.514419286205907, 2.7194304362617663],
					[60.51441928531348, 2.7194304364351853],
					[60.514419282721697, 2.7194304387655936],
					[60.514419281829277, 2.7194304389390123],
					[60.514358181635579, 2.7194748376249915],
					[60.514358181721072, 2.71947483943514],
					[60.51427488465648, 2.7195359366492911],
					[60.514249888660189, 2.7195526348768375],
					[60.514249880799326, 2.719552640057854],
					[60.514249866055557, 2.7195526520566236],
					[60.514249859172637, 2.7195526588743797],
					[60.514230459307754, 2.7195720583433873],
					[60.514230455823544, 2.7195720608471978],
					[60.514230450639964, 2.7195720655079643],
					[60.514230449026115, 2.71957206947507],
					[60.514141549177928, 2.7196748690396904],
					[60.514141548285515, 2.7196748692130979],
					[60.514060953205203, 2.7197692644735496],
					[60.514024860924941, 2.7198054561354428],
					[60.513988764316075, 2.7198387531666519],
					[60.513988761724278, 2.719838755497014],
					[60.51398875832556, 2.7198387598108873],
					[60.513988755648271, 2.7198387603311009],
					[60.513916563712471, 2.7199137532530533],
					[60.513819272794777, 2.7199942454943593],
					[60.513819265911827, 2.7199942523119098],
					[60.51381925384527, 2.7199942637901144],
					[60.513819247854748, 2.7199942704342828],
					[60.513802653288614, 2.7200137636409707],
					[60.513783158531645, 2.7200331572282845],
					[60.513783156832247, 2.7200331593852014],
					[60.513783154325942, 2.7200331635256303],
					[60.513783151734145, 2.72003316585594],
					[60.513694252081685, 2.7201304641201212],
					[60.513694251274735, 2.7201304661036296],
					[60.513694247875996, 2.7201304704174327],
					[60.513694246176641, 2.7201304725743491],
					[60.513613759987024, 2.720227655442617],
					[60.513577677709549, 2.7202554411939617],
					[60.513577673418368, 2.7202554456811447],
					[60.513577662965709, 2.7202554531921477],
					[60.513577658674528, 2.7202554576793299],
					[60.513549862401248, 2.7202831551364564],
					[60.513460966385907, 2.7203637514391756],
					[60.513460963794103, 2.7203637537694352],
					[60.513460962009248, 2.720363754116208],
					[60.513460961202306, 2.7203637560997116],
					[60.513372061209608, 2.7204498565762307],
					[60.513372060317188, 2.7204498567496143],
					[60.513372058617811, 2.7204498589064778],
					[60.513372058617811, 2.7204498589064778],
					[60.513352658690735, 2.7204692590970772],
					[60.51335265439954, 2.7204692635842003],
					[60.513352644117816, 2.7204692747152679],
					[60.51335263982665, 2.7204692792023883],
					[60.513335945125675, 2.7204915733839812],
					[60.513247050959663, 2.7205915677114332],
					[60.51324705006725, 2.7205915678848092],
					[60.513247048282373, 2.72059156823157],
					[60.513247048367859, 2.7205915700416603],
					[60.513166556129569, 2.7206859602850813],
					[60.513130463647343, 2.7207192527285318],
					[60.513130462840387, 2.7207192547119883],
					[60.513130460248576, 2.7207192570422132],
					[60.513130459441641, 2.7207192590256675],
					[60.513102659728872, 2.720747056798916],
					[60.513022062035056, 2.7208248547990435],
					[60.51293317320242, 2.7209081447698247],
					[60.512905484679358, 2.7209276366040762],
					[60.512905470827874, 2.7209276484283227],
					[60.512905445080705, 2.7209276753502265],
					[60.512905433991975, 2.720927688464446],
					[60.512888741234612, 2.7209526760482259],
					[60.512797050952138, 2.7210554667107267],
					[60.512797048360312, 2.7210554690408815],
					[60.512797041562784, 2.7210554776680627],
					[60.512797039056437, 2.7210554818082837],
					[60.512727649989856, 2.7211498662498181],
					[60.512680463417631, 2.721194254283982],
					[60.512680461632755, 2.7211942546307015],
					[60.512680460018828, 2.7211942585975399],
					[60.512680459126415, 2.7211942587708955],
					[60.512644262288688, 2.7212304546674244],
					[60.512563766381845, 2.7213026500305539],
					[60.512563765574889, 2.7213026520139625],
					[60.512563763790027, 2.7213026523606731],
					[60.512563763875491, 2.7213026541707257],
					[60.512483164362692, 2.7213776531026741],
					[60.51248316007149, 2.7213776575895277],
					[60.512483152381492, 2.7213776663899103],
					[60.51248314809029, 2.7213776708767687],
					[60.51244984823775, 2.7214165696542096],
					[60.512449839740789, 2.7214165804379715],
					[60.51244982381025, 2.7214166054522333],
					[60.512449818076043, 2.7214166175259904],
					[60.512413723164485, 2.7214971058042576],
					[60.512358130212924, 2.7215859934716824],
					[60.512358130298395, 2.7215859952817403],
					[60.51235812770657, 2.72158599761182],
					[60.51235812770657, 2.72158599761182],
					[60.512322027800955, 2.7216470984130621],
					[60.51232202610155, 2.7216471005697938],
					[60.512322023595182, 2.7216471047099051],
					[60.512322022873683, 2.7216471085033476],
					[60.512252623045519, 2.7217805074748491],
					[60.512252622238556, 2.721780509458243],
					[60.512252621431585, 2.72178051144162],
					[60.512252619732159, 2.7217805135983402],
					[60.512208121383267, 2.7218721115568463],
					[60.512135921313671, 2.7220166113471289],
					[60.512091520616636, 2.7221055118101147],
					[60.512091519809665, 2.7221055137934895],
					[60.512091517388768, 2.722105519743562],
					[60.512091515774799, 2.722105523710284],
					[60.512027621874161, 2.722258207242338],
					[60.511983130185897, 2.7223304951263669],
					[60.511983129293469, 2.7223304952996874],
					[60.511983127679521, 2.722330499266397],
					[60.511983126787094, 2.7223304994397175],
					[60.511910927006717, 2.7224554996998944],
					[60.511858130832124, 2.7225442922171497],
					[60.511785934152392, 2.7226526887856837],
					[60.511785931560546, 2.7226526911156332],
					[60.511785929054142, 2.7226526952556158],
					[60.511785928247171, 2.7226526972389413],
					[60.511724829414426, 2.7227554967918395],
					[60.51166922867192, 2.7228470960577078],
					[60.511669227779493, 2.7228470962310101],
					[60.511669227057951, 2.7228471000243357],
					[60.511669226165523, 2.7228471001976389],
					[60.511599827882939, 2.7229692981016216],
					[60.511555429958669, 2.7230415949768458],
					[60.511555429066249, 2.7230415951501463],
					[60.511555428259264, 2.7230415971334656],
					[60.511555428344707, 2.7230415989434666],
					[60.511499833987351, 2.7231359888161379],
					[60.511455441267032, 2.7231942797182436],
					[60.511455436975751, 2.7231942842047299],
					[60.511455428564062, 2.7231942967976863],
					[60.511455425250674, 2.723194302920859],
					[60.511427628308525, 2.7232442986413301],
					[60.511347038458766, 2.7233720817188942],
					[60.511285953767924, 2.7234442639365883],
					[60.511222061807423, 2.7235054561089855],
					[60.511222054924261, 2.7235054629252211],
					[60.511222042942826, 2.7235054762111353],
					[60.511222037844554, 2.72350548268082],
					[60.511149837720495, 2.7236054823268896],
					[60.511149832707666, 2.7236054906065208],
					[60.511149824466806, 2.7236055068192129],
					[60.511149819368519, 2.7236055132888648],
					[60.511124828390258, 2.723658194532927],
					[60.511088741068377, 2.7237054791464734],
					[60.511088735970091, 2.7237054856160778],
					[60.511088726665939, 2.723705498382031],
					[60.51108872343795, 2.7237055063150759],
					[60.511016525711945, 2.7238416012143767],
					[60.510972036210148, 2.7239165836874495],
					[60.510891546306077, 2.7240137725934139],
					[60.510891540400742, 2.7240137810461715],
					[60.510891527697694, 2.7240137981249828],
					[60.510891522684823, 2.7240138064044674],
					[60.510830423410084, 2.7241305059429832],
					[60.510774824336728, 2.72423320519579],
					[60.510774822551852, 2.7242332055423133],
					[60.510774821830275, 2.7242332093355017],
					[60.510774821023276, 2.7242332113187437],
					[60.510713721895812, 2.7243555083244888],
					[60.510658123300061, 2.7244610067844035],
					[60.510597023607176, 2.7245777046339144],
					[60.510544229350401, 2.7246720968445159],
					[60.51046373301601, 2.7247942907637186],
					[60.51046372872468, 2.7247942952498305],
					[60.510463722990153, 2.7247943073222456],
					[60.510463719676679, 2.7247943134450803],
					[60.510427619778788, 2.7248693139553279],
					[60.510427618886347, 2.7248693141285671],
					[60.510427617357735, 2.7248693199049079],
					[60.510427615658259, 2.7248693220613438],
					[60.510363715764285, 2.7250193224032033],
					[60.510363714871865, 2.7250193225764368],
					[60.510363714150259, 2.7250193263695621],
					[60.510363713343224, 2.7250193283527464],
					[60.510327613155468, 2.7251138283998886],
					[60.510327612433876, 2.7251138321930219],
					[60.51032760920581, 2.7251138401257404],
					[60.510327607591762, 2.7251138440920939],
					[60.510283111311409, 2.7252666342508203],
					[60.510230416233966, 2.7253943207878835],
					[60.510177619279965, 2.7255110145927217],
					[60.510113721053251, 2.7256388107908482],
					[60.510113720246238, 2.7256388127739948],
					[60.510113716932686, 2.7256388188966851],
					[60.51011371621108, 2.725638822689763],
					[60.510069219206784, 2.7257443155073902],
					[60.510005424230712, 2.725869303483377],
					[60.509944227444208, 2.7259749006428544],
					[60.509944224045277, 2.7259749049555309],
					[60.509944219117656, 2.7259749150444099],
					[60.509944215804119, 2.7259749211670301],
					[60.509891520223206, 2.7260971128967477],
					[60.509835924231503, 2.7261999053310229],
					[60.509835922532005, 2.7261999074873469],
					[60.509835920110902, 2.7261999134367016],
					[60.509835919303875, 2.7261999154198202],
					[60.509783118728407, 2.726313816168473],
					[60.509783117835987, 2.7263138163416674],
					[60.509730417964505, 2.7264277154208121],
					[60.509730418049905, 2.7264277172307243],
					[60.509730417242878, 2.7264277192138309],
					[60.509730415543402, 2.7264277213701287],
					[60.509674816255973, 2.7265582211049706],
					[60.509674814556497, 2.72655822326126],
					[60.509630416188685, 2.7266666219538243],
					[60.509569217804959, 2.7268082164714675],
					[60.509524821066975, 2.7268999128075313],
					[60.509452621411747, 2.7270443114457912],
					[60.50945261962687, 2.7270443117921404],
					[60.50945261971227, 2.7270443136020388],
					[60.509408122801368, 2.7271360085971739],
					[60.509327649571233, 2.7272804571999627],
					[60.509238794343787, 2.7273359298851223],
					[60.509238772715854, 2.7273359486933777],
					[60.509238736770293, 2.7273359885443846],
					[60.50923872075316, 2.7273360117433363],
					[60.509210921181186, 2.7273916115278976],
					[60.509210914553961, 2.7273916237725504],
					[60.509210906017891, 2.727391652825697],
					[60.509210903045826, 2.7273916661875974],
					[60.509183103019367, 2.7275582670312351],
					[60.509183101195873, 2.7275582856495939],
				],
				[
					[
						[60.489810904975094, 2.6918471575427794],
						[60.48981090755062, 2.6918472118033492],
						[60.489810942870982, 2.6918473107910263],
						[60.48981097472344, 2.6918473556921225],
						[60.489838774818764, 2.6918695555596699],
						[60.489838788805457, 2.691869565610435],
						[60.48983881839191, 2.6918695817465879],
						[60.489838834077524, 2.6918695896406595],
						[60.489894329433064, 2.6918890873242676],
						[60.489955520201541, 2.6919140826097308],
						[60.490027709113555, 2.691947378650775],
						[60.490072102029252, 2.6919723746324302],
						[60.490072102921623, 2.6919723744584307],
						[60.490072105770473, 2.6919723775538205],
						[60.490072106662858, 2.691972377379817],
						[60.490136007333881, 2.6920057764435836],
						[60.490136010096883, 2.6920057777302819],
						[60.490136013838118, 2.6920057806516962],
						[60.490136015708728, 2.6920057821123944],
						[60.490172113150059, 2.6920223795153628],
						[60.490199909183211, 2.6920362784157108],
						[60.490280499865925, 2.6920779748915522],
						[60.490333193317852, 2.6921112686260447],
						[60.49033319884385, 2.6921112711994715],
						[60.490333209981692, 2.6921112781550556],
						[60.490333215593537, 2.6921112825372111],
						[60.49040551071252, 2.6921445787211109],
						[60.490477705059732, 2.6921834751317792],
						[60.490477708800952, 2.6921834780532277],
						[60.490477716111712, 2.6921834802786937],
						[60.490477719852933, 2.6921834832001443],
						[60.490522120264515, 2.6922029836981292],
						[60.490586018088685, 2.6922307836371457],
						[60.490622116400587, 2.692247381318249],
						[60.490622121034185, 2.6922473840657188],
						[60.490622131107926, 2.6922473875779471],
						[60.490622135741553, 2.6922473903254112],
						[60.490647136353388, 2.6922557905016196],
						[60.490647137245773, 2.6922557903276219],
						[60.490647140008768, 2.6922557916143575],
						[60.490647140901174, 2.6922557914403571],
						[60.49072774060496, 2.6922807913930442],
						[60.490727744260319, 2.6922807925057901],
						[60.490727750678701, 2.6922807949052912],
						[60.490727754248226, 2.6922807942093008],
						[60.490774954107557, 2.6922918940915319],
						[60.490774955085797, 2.6922918957262878],
						[60.490774955978182, 2.6922918955522874],
						[60.490836055888934, 2.692305794519148],
						[60.490836062221469, 2.6923057951099025],
						[60.490836074079994, 2.6923057982741523],
						[60.490836080412514, 2.692305798864925],
						[60.490891679604488, 2.6923112997926757],
						[60.490891681389265, 2.6923112994446763],
						[60.490891683174027, 2.6923112990966804],
						[60.490891684958797, 2.6923112987486832],
						[60.490963885021912, 2.692316899815236],
						[60.490963891354426, 2.6923169004059977],
						[60.490963903041255, 2.6923168999527647],
						[60.490963909373797, 2.6923169005435219],
						[60.491025004802786, 2.6923141008750719],
						[60.491080599584656, 2.6923141006418096],
						[60.491080604938951, 2.6923140995978136],
						[60.491080613948625, 2.692314099666576],
						[60.49108061841055, 2.6923140987965741],
						[60.491141717879344, 2.6923084998194948],
						[60.491141723233639, 2.6923084987754944],
						[60.491141732157473, 2.6923084970354916],
						[60.491141736619397, 2.6923084961654906],
						[60.491186129875082, 2.6923001979918451],
						[60.491258323197258, 2.6922918991238571],
						[60.491258329443944, 2.6922918979058492],
						[60.491258341937311, 2.6922918954698294],
						[60.491258348183997, 2.6922918942518166],
						[60.491302840035935, 2.692280796539678],
						[60.491375029910124, 2.6922695975134068],
						[60.491430629756117, 2.6922612973801483],
						[60.491430630648509, 2.6922612972061439],
						[60.491430632433271, 2.6922612968581388],
						[60.491500032292748, 2.6922501963192409],
						[60.491500035055736, 2.6922501976060249],
						[60.491500042194808, 2.6922501962139891],
						[60.491500044871941, 2.6922501956919782],
						[60.4915361377723, 2.6922418965533028],
						[60.49160831540599, 2.6922308001171],
						[60.491644399585503, 2.6922307996500168],
						[60.491644406810423, 2.692230800066755],
						[60.491644420282022, 2.6922307992654542],
						[60.491644425636316, 2.6922307982214178],
						[60.491708318426205, 2.6922223997180996],
						[60.491761105467404, 2.6922196005325123],
						[60.491833299961968, 2.6922195999300254],
						[60.491861091996036, 2.6922196007341896],
						[60.491897184716386, 2.6922223989547587],
						[60.491897185608757, 2.6922223987807534],
						[60.491897188371759, 2.6922224000675294],
						[60.491897189264144, 2.6922223998935229],
						[60.491949988033369, 2.6922251991608372],
						[60.491994382079724, 2.6922279997765015],
						[60.49206667597236, 2.6922362988218658],
						[60.492111068657927, 2.6922418982942853],
						[60.492183261700255, 2.6922557959585061],
						[60.49218326624802, 2.6922557968972796],
						[60.49218327168817, 2.6922557976620412],
						[60.49218327534355, 2.6922557987748381],
						[60.492227765004799, 2.6922612972243147],
						[60.492299953115328, 2.6922779945196398],
						[60.492344351020833, 2.6922890934889772],
						[60.492344351913218, 2.6922890933149675],
						[60.492344353783842, 2.6922890947757905],
						[60.49234435467622, 2.6922890946017817],
						[60.492416651878941, 2.6923057944630582],
						[60.492480540270719, 2.6923223906303644],
						[60.492533230865973, 2.6923418878082561],
						[60.492533231758358, 2.692341887634246],
						[60.492533233628976, 2.6923418890950637],
						[60.492533235413731, 2.6923418887470474],
						[60.492605532898523, 2.6923668878418403],
						[60.492658224121818, 2.6923862846246203],
						[60.492730516443977, 2.6924195822095949],
						[60.492730518314595, 2.692419583670441],
						[60.492730522862345, 2.6924195846092451],
						[60.492730524647136, 2.6924195842612275],
						[60.492758221302452, 2.6924307837094825],
						[60.492794308552739, 2.6924473774314217],
						[60.492858201021789, 2.6924834742558907],
						[60.492910994973464, 2.6925140696329111],
						[60.492983185793697, 2.6925612657578721],
						[60.493055482067597, 2.6926140625658435],
						[60.493055486701195, 2.692614065313566],
						[60.493055496860791, 2.6926140706349728],
						[60.49305550238676, 2.6926140732086936],
						[60.493099884972487, 2.69263906506225],
						[60.493135970456144, 2.6926695524195918],
						[60.493199868803437, 2.6927251519233684],
						[60.493199869695815, 2.6927251517493658],
						[60.493199871566425, 2.6927251532102541],
						[60.49319987254465, 2.6927251548451236],
						[60.4932609733535, 2.6927751531785429],
						[60.493324869292771, 2.6928279512240634],
						[60.493388763994467, 2.6928862466697079],
						[60.493433162991913, 2.6929279458991231],
						[60.493477658868706, 2.6929695423689419],
						[60.4935415558082, 2.6930362376519641],
						[60.493541558742848, 2.6930362425566576],
						[60.493541566225275, 2.6930362484002779],
						[60.493541569074104, 2.6930362514960766],
						[60.493622069049117, 2.6931057508336189],
						[60.493622070027328, 2.6931057524685258],
						[60.493622070919699, 2.6931057522945303],
						[60.493622070919699, 2.6931057522945303],
						[60.493658160944094, 2.6931362433820252],
						[60.493685951763247, 2.6931668337550203],
						[60.49368595656852, 2.6931668401206763],
						[60.493685966899776, 2.6931668490601735],
						[60.493685973403956, 2.6931668532689383],
						[60.493766572654046, 2.6932334548101151],
						[60.493766573546417, 2.6932334546361223],
						[60.493766576395231, 2.6932334577319597],
						[60.49376657728763, 2.6932334575579659],
						[60.493855461580047, 2.6933029461016473],
						[60.493874848676775, 2.693325132375771],
						[60.493874858029777, 2.6933251396804452],
						[60.493874875929279, 2.6933251562726945],
						[60.493874886174673, 2.6933251634033795],
						[60.493910984875455, 2.6933501635147805],
						[60.493980482982508, 2.6934001625679991],
						[60.493980484853118, 2.6934001640289389],
						[60.493980489486695, 2.6934001667768319],
						[60.493980491271458, 2.6934001664288529],
						[60.494044290833735, 2.6934418672742706],
						[60.49404429181196, 2.6934418689092019],
						[60.49404429368257, 2.6934418703701635],
						[60.494044294574948, 2.6934418701961746],
						[60.494116590876253, 2.6934862687958758],
						[60.494197078150577, 2.6935418594129343],
						[60.494216569939404, 2.6935584518596021],
						[60.494216574658822, 2.6935584564164641],
						[60.494216584011838, 2.6935584637212773],
						[60.494216588645415, 2.6935584664692289],
						[60.494241589329661, 2.6935751660825988],
						[60.49424159120025, 2.6935751675435613],
						[60.494241595833834, 2.6935751702915134],
						[60.494241598682656, 2.6935751733874223],
						[60.494322089194135, 2.6936223674945872],
						[60.494377679798866, 2.6936640593958359],
						[60.494377682647674, 2.693664062491782],
						[60.494377688173643, 2.693664065065756],
						[60.494377690936631, 2.6936640663527425],
						[60.494449882940479, 2.6937112612275285],
						[60.494530475952708, 2.6937751574425319],
						[60.494530478715681, 2.6937751587295362],
						[60.494530486198087, 2.6937751645734895],
						[60.494530488961061, 2.6937751658604916],
						[60.494547089374009, 2.6937862665048433],
						[60.494547091244613, 2.6937862679658293],
						[60.494547094985805, 2.6937862708878009],
						[60.494547096770567, 2.6937862705398365],
						[60.494574884596958, 2.6938029638407794],
						[60.494666570145739, 2.6938779524291192],
						[60.49474706222238, 2.6939501450263221],
						[60.494783155711559, 2.6939862385977973],
						[60.494819253409254, 2.6940251357085518],
						[60.494899849787224, 2.6941140321437196],
						[60.494988744742024, 2.6942168278969878],
						[60.495008131919114, 2.6942418105905928],
						[60.495024825125697, 2.6942722954141298],
						[60.495024830016732, 2.69427230358919],
						[60.495024839798802, 2.6942723199393037],
						[60.495024846474635, 2.6942723277664133],
						[60.49511653668732, 2.694383415868399],
						[60.495169229189024, 2.6944695033045294],
						[60.495169230253047, 2.6944695067485434],
						[60.495169232123644, 2.6944695082096106],
						[60.495169233101841, 2.6944695098446334],
						[60.495233132747252, 2.6945668099473505],
						[60.495305432438137, 2.6946779094431892],
						[60.495305433330522, 2.6946779092692248],
						[60.495305434308733, 2.6946779109042587],
						[60.495305434394531, 2.6946779127132579],
						[60.495358132355904, 2.6947557093662469],
						[60.495430429626744, 2.6948723056200659],
						[60.495430432475537, 2.6948723087162167],
						[60.495430435410121, 2.6948723136213579],
						[60.495430437280717, 2.694872315082443],
						[60.495458125756926, 2.6949112008574945],
						[60.495474817779723, 2.6949472843680002],
						[60.495474821692532, 2.6949472909082219],
						[60.495474827647548, 2.6949473025275537],
						[60.495474832366931, 2.6949473070848042],
						[60.495555432096339, 2.695072307241261],
						[60.495555432182151, 2.6950723090502757],
						[60.495555433160334, 2.6950723106853358],
						[60.495555433160334, 2.6950723106853358],
						[60.495610929949187, 2.6951557065922973],
						[60.495674828206738, 2.6952640023813941],
						[60.495674830163125, 2.6952640056515369],
						[60.495674835054118, 2.6952640138269039],
						[60.495674837902889, 2.6952640169231126],
						[60.495747032412858, 2.6953640082355146],
						[60.495799827005342, 2.6954557004135808],
						[60.495799827005342, 2.6954557004135808],
						[60.495799828069337, 2.6954557038576947],
						[60.495799828961722, 2.6954557036837485],
						[60.495872026917013, 2.6955751011251481],
						[60.495908126350862, 2.6956389973786719],
						[60.495908129371252, 2.6956390040929841],
						[60.495908136132797, 2.6956390137296196],
						[60.495908139959781, 2.6956390184609726],
						[60.495980431897259, 2.695736209804831],
						[60.496016524583716, 2.6958000986215458],
						[60.496044224641189, 2.6958500970413648],
						[60.496044225619372, 2.6958500986764711],
						[60.496044229446326, 2.6958501034078721],
						[60.496044230424516, 2.6958501050429788],
						[60.496124826158535, 2.695980700759439],
						[60.496169224218349, 2.696063993336264],
						[60.496233124154827, 2.696183394876043],
						[60.496233126111221, 2.6961833981462915],
						[60.49623313198034, 2.696183407957065],
						[60.496233133936698, 2.6961834112273366],
						[60.496297031564147, 2.6962779081423665],
						[60.496358128660205, 2.6963779045961362],
						[60.496358129638409, 2.6963779062312705],
						[60.496358133379552, 2.6963779091536986],
						[60.496358134357742, 2.6963779107888346],
						[60.496430433751435, 2.696486211670722],
						[60.496430433751435, 2.696486211670722],
						[60.49643043464382, 2.6964862114967998],
						[60.496430434729618, 2.6964862133058656],
						[60.49646653516178, 2.6965390123928352],
						[60.496466540052708, 2.6965390205685931],
						[60.496466552425908, 2.6965390345892759],
						[60.496466559015786, 2.696539040608124],
						[60.496522045276635, 2.696594528618558],
						[60.496574834927046, 2.6966723113643392],
						[60.496574838839763, 2.6966723179049903],
						[60.496574846579442, 2.6966723291771886],
						[60.496574852191131, 2.6966723335609095],
						[60.496627652081095, 2.6967307336627107],
						[60.496627653059306, 2.696730735297868],
						[60.496691553399067, 2.6968001344700974],
						[60.496691555355426, 2.6968001377404325],
						[60.496691560074744, 2.6968001422981205],
						[60.496691562031117, 2.6968001455684538],
						[60.496755462317125, 2.6968612448841802],
						[60.496755463295315, 2.6968612465193509],
						[60.49675546516589, 2.6968612479806251],
						[60.496755467036451, 2.6968612494418838],
						[60.496808167053153, 2.6969084481720724],
						[60.496808169009526, 2.6969084514424213],
						[60.496808172750647, 2.6969084543649662],
						[60.496808174621215, 2.6969084558262297],
						[60.496863770812872, 2.6969529522542079],
						[60.496916567059166, 2.6970001489559547],
						[60.496916569907917, 2.6970001520524289],
						[60.496916577304397, 2.6970001560884227],
						[60.49691658015314, 2.6970001591848947],
						[60.496960980070995, 2.6970334600729657],
						[60.496960981941569, 2.6970334615342422],
						[60.496960984704543, 2.6970334628216044],
						[60.496960985682712, 2.6970334644567919],
						[60.497033185730523, 2.6970834638952796],
						[60.497033189471658, 2.6970834668178467],
						[60.497033195975739, 2.6970834710278009],
						[60.497033198738706, 2.6970834723151724],
						[60.497113797020269, 2.6971307708522421],
						[60.49714989523634, 2.6971529693737404],
						[60.497149897106908, 2.6971529708350319],
						[60.497149899869868, 2.6971529721224106],
						[60.497149901740443, 2.6971529735837017],
						[60.497194301622855, 2.6971779746652662],
						[60.4971943052782, 2.6971779757787622],
						[60.497194311696497, 2.6971779781796248],
						[60.497194315437618, 2.6971779811022132],
						[60.497241614909761, 2.6972001805278909],
						[60.497241621413842, 2.6972001847378784],
						[60.497241635142849, 2.6972001893657236],
						[60.497241640668769, 2.6972001919404995],
						[60.497277741224934, 2.6972112908906771],
						[60.497277743095509, 2.6972112923519767],
						[60.497277744880279, 2.6972112920041593],
						[60.497277746750868, 2.6972112934654557],
						[60.497358247333352, 2.6972334935575373],
						[60.497358251881089, 2.6972334944971337],
						[60.497358262761345, 2.6972334960284745],
						[60.497358269179657, 2.6972334984293553],
						[60.497411069242489, 2.6972418981113466],
						[60.497411076467401, 2.6972418985292199],
						[60.497411091809603, 2.6972418991910176],
						[60.497411099926914, 2.6972418994349616],
						[60.497483300468424, 2.6972419007986237],
						[60.497483304037985, 2.6972419001029868],
						[60.497483312155268, 2.6972419003469348],
						[60.497483314832458, 2.6972418998252019],
						[60.49755561535558, 2.697236298233205],
						[60.497555627042431, 2.697236297781509],
						[60.497555649352201, 2.6972362934337584],
						[60.497555660060897, 2.6972362913468344],
						[60.497600059826503, 2.6972223911472351],
						[60.497600063396078, 2.6972223904515906],
						[60.497600067772225, 2.6972223877729036],
						[60.497600071341793, 2.6972223870772605],
						[60.497680671322911, 2.6971918864306175],
						[60.49768067221531, 2.6971918862567064],
						[60.497680673107681, 2.6971918860827961],
						[60.497680674000087, 2.6971918859088815],
						[60.497708373616817, 2.6971807855373244],
						[60.497708386831093, 2.6971807793103544],
						[60.497708411389119, 2.6971807653950743],
						[60.497708422732835, 2.6971807577067874],
						[60.497733415110432, 2.6971612637418811],
						[60.497814004909472, 2.6971112699783433],
						[60.497814010984655, 2.6971112651426381],
						[60.497814022328356, 2.6971112574542824],
						[60.497814026704518, 2.6971112547755607],
						[60.497877926939715, 2.6970584532912074],
						[60.497877931315891, 2.6970584506124529],
						[60.497877938283445, 2.6970584456028113],
						[60.497877940789031, 2.6970584414627607],
						[60.49793074097245, 2.6970057416483955],
						[60.497930742671436, 2.6970057394914022],
						[60.497930746069414, 2.6970057351774228],
						[60.497930747854213, 2.6970057348295806],
						[60.497986241669679, 2.6969445417288198],
						[60.498047333885204, 2.6968890477586926],
						[60.498047335584182, 2.6968890456016874],
						[60.498047339960344, 2.6968890429228964],
						[60.498047340766945, 2.6968890409398134],
						[60.498102941266914, 2.6968334412034229],
						[60.498102944750705, 2.6968334386985582],
						[60.49810295065425, 2.6968334302444368],
						[60.498102954138034, 2.6968334277395556],
						[60.498155753594972, 2.6967695272956962],
						[60.49815575788535, 2.6967695228077302],
						[60.498155764595509, 2.696769512370476],
						[60.498155767993488, 2.6967695080564162],
						[60.49820016761393, 2.6967001089621281],
						[60.498200170205308, 2.6967001066311744],
						[60.498200171818482, 2.6967001026649675],
						[60.498200172625111, 2.6967001006818649],
						[60.498264073097651, 2.6965890004586495],
						[60.49826407479663, 2.696588998301614],
						[60.498264077216405, 2.6965889923522899],
						[60.498264078022999, 2.6965889903691882],
						[60.498316873961869, 2.696486198227158],
						[60.498377969082156, 2.6963890058950519],
						[60.498377970781135, 2.6963890037379965],
						[60.4983779741791, 2.6963889994238706],
						[60.498377974899888, 2.6963889956315814],
						[60.498441866967717, 2.6962723103167394],
						[60.498494555210513, 2.6962057275468281],
						[60.498539042594494, 2.6961529401755393],
						[60.498602931048566, 2.6960973514186697],
						[60.498602937037894, 2.6960973447734635],
						[60.49860294910232, 2.6960973332922262],
						[60.498602954199235, 2.6960973268209592],
						[60.498655737256676, 2.6960334473674834],
						[60.49871121518369, 2.6959945632906548],
						[60.498711216968452, 2.6959945629427522],
						[60.498711220452201, 2.6959945604377498],
						[60.498711222151165, 2.6959945582806371],
						[60.498747309683999, 2.6959668670918617],
						[60.498816779117, 2.6959279851490772],
						[60.498880629807154, 2.6959084996191414],
						[60.498933282915168, 2.6959084993469049],
						[60.498997149152231, 2.6959195932958511],
						[60.499052717881334, 2.695939082637429],
						[60.499133190105745, 2.6959834676445622],
						[60.499158173977527, 2.6960029555971832],
						[60.499194259282525, 2.6960334428190667],
						[60.499266548976372, 2.6961168315509818],
						[60.499266552803327, 2.6961168362828598],
						[60.49926656313442, 2.6961168452247386],
						[60.499266567853752, 2.6961168497826509],
						[60.4993581557477, 2.6961973389693896],
						[60.499383145368157, 2.6962279260931794],
						[60.499383146346361, 2.6962279277284562],
						[60.499410945839507, 2.6962612283449618],
						[60.499410946731892, 2.6962612281710125],
						[60.49941094958065, 2.6962612312676484],
						[60.499410950558854, 2.6962612329029301],
						[60.499499844456544, 2.6963612252071059],
						[60.499572037738851, 2.6964612176392104],
						[60.499572037738851, 2.6964612176392104],
						[60.499572038631236, 2.6964612174652638],
						[60.499572038717034, 2.6964612192745081],
						[60.499619236972251, 2.6965251166240498],
						[60.499680433932269, 2.696614011158244],
						[60.49971653243032, 2.6966695091652997],
						[60.499716534386707, 2.6966695124359354],
						[60.499716539191823, 2.6966695188032359],
						[60.499716541062398, 2.6966695202646211],
						[60.499752639839613, 2.6967168196897204],
						[60.49983313880076, 2.6968251189709642],
						[60.499833139778929, 2.6968251206062872],
						[60.499833141735316, 2.6968251238769261],
						[60.499833142627693, 2.6968251237029914],
						[60.499905443042124, 2.6969168236826189],
						[60.499905444912685, 2.6969168251440134],
						[60.499905448825416, 2.6969168316853378],
						[60.499905450695991, 2.6969168331467297],
						[60.499952650896191, 2.6969695325877026],
						[60.499952654723124, 2.6969695373197786],
						[60.499952663183592, 2.6969695448007127],
						[60.499952667010511, 2.6969695495327755],
						[60.500005453955289, 2.6970168380988291],
						[60.500058142704049, 2.6970834248138629],
						[60.500058153120875, 2.6970834355655273],
						[60.500058173868702, 2.6970834552596101],
						[60.500058185092115, 2.6970834640280752],
						[60.500141584672903, 2.6971418649467309],
						[60.500141586457694, 2.6971418645988683],
						[60.500177685920015, 2.6971668640236222],
						[60.500177689661172, 2.6971668669464699],
						[60.500177698928219, 2.6971668724442814],
						[60.500177702669362, 2.6971668753671358],
						[60.500202703283996, 2.6971807757845379],
						[60.50020270506878, 2.6971807754366766],
						[60.500274905249185, 2.6972195753602075],
						[60.500274908097921, 2.6972195784570085],
						[60.500274915408625, 2.6972195806841364],
						[60.500274918171577, 2.6972195819716296],
						[60.500330518267262, 2.697244582600248],
						[60.50033052744854, 2.6972445862888303],
						[60.500330543940507, 2.6972445922045374],
						[60.500330553035987, 2.6972445940838332],
						[60.500399952687602, 2.6972612938786522],
						[60.500399955450547, 2.6972612951661574],
						[60.500399959105913, 2.6972612962797284],
						[60.500399960890682, 2.6972612959318703],
						[60.500455561119459, 2.6972723952151134],
						[60.500455564774789, 2.6972723963286915],
						[60.500455573870262, 2.6972723982079834],
						[60.500455579310412, 2.6972723989736989],
						[60.500508279045789, 2.6972779993891849],
						[60.500508284400148, 2.6972779983456037],
						[60.500508295280405, 2.6972779998770395],
						[60.500508299828127, 2.6972780008166919],
						[60.500563899717093, 2.6972779998208289],
						[60.500563903286654, 2.697277999125109],
						[60.50056390783439, 2.6972780000647583],
						[60.500563911403951, 2.6972779993690383],
						[60.500616710957203, 2.6972752007088912],
						[60.500616713548581, 2.6972751983777967],
						[60.500616721665892, 2.6972751986217238],
						[60.500616726127816, 2.6972751977520759],
						[60.500680626368023, 2.6972668975162262],
						[60.500680630023375, 2.6972668986298074],
						[60.500680637076712, 2.6972668954290482],
						[60.500680640732057, 2.6972668965426299],
						[60.500733341165557, 2.6972557959263193],
						[60.500733343842704, 2.6972557954045264],
						[60.500733348304678, 2.6972557945348634],
						[60.500733350981839, 2.697255794013067],
						[60.500797251035387, 2.6972390939594724],
						[60.500797252820163, 2.6972390936116057],
						[60.500797258088738, 2.6972390907586918],
						[60.500797259873501, 2.6972390904108283],
						[60.500850059640733, 2.6972223917830438],
						[60.500850062232125, 2.6972223894519254],
						[60.500850064016895, 2.6972223891040596],
						[60.500850064909287, 2.6972223889301259],
						[60.500922265302314, 2.6971973894294554],
						[60.500922268871875, 2.6971973887337146],
						[60.500922275925198, 2.6971973855329008],
						[60.500922280301374, 2.6971973828539011],
						[60.500966778730842, 2.6971779834923169],
						[60.50103897836145, 2.6971473834345958],
						[60.501038982823388, 2.6971473825649124],
						[60.501038991575726, 2.6971473772068837],
						[60.501038996844279, 2.6971473743539174],
						[60.501063977611103, 2.6971334846096626],
						[60.501091757263545, 2.6971251921497674],
						[60.501091765209274, 2.6971251887749954],
						[60.501091780208313, 2.6971251821993745],
						[60.501091787347448, 2.6971251808078649],
						[60.501172284622236, 2.6970862816164813],
						[60.501227878267017, 2.697061284475248],
						[60.501297273729541, 2.6970334856414042],
						[60.501297278191494, 2.6970334847716937],
						[60.501297286137202, 2.6970334813968737],
						[60.501297288728594, 2.6970334790656878],
						[60.501352878711323, 2.6970057835057442],
						[60.501413967331651, 2.6969834876515142],
						[60.5014861632866, 2.6969584897867467],
						[60.501530656012946, 2.696944592792645],
						[60.50161115337734, 2.6969223920667256],
						[60.501611154355508, 2.6969223937021374],
						[60.501611156054508, 2.6969223915448834],
						[60.501611156946886, 2.6969223913709381],
						[60.501638943633736, 2.6969140953606203],
						[60.501675030676786, 2.6969084982653855],
						[60.50167503424634, 2.6969084975695923],
						[60.501675039600677, 2.6969084965259054],
						[60.501675043170238, 2.6969084958301126],
						[60.501738942691105, 2.6968945958081476],
						[60.501763932299916, 2.6968890983540015],
						[60.501844421444645, 2.6968807991456938],
						[60.501844423229429, 2.6968807987977903],
						[60.501844424121828, 2.6968807986238401],
						[60.501891724020616, 2.6968751997702896],
						[60.501891726611973, 2.6968751974390641],
						[60.501891732858724, 2.6968751962214075],
						[60.501891735621676, 2.6968751975089313],
						[60.5019528228908, 2.6968640988981059],
						[60.502008309932016, 2.6968612995239676],
						[60.502008314393954, 2.6968612986542113],
						[60.502008323403651, 2.6968612987240705],
						[60.502008326973197, 2.6968612980282662],
						[60.502069422697133, 2.6968529989417487],
						[60.5021250201047, 2.6968473986682238],
						[60.502125023760037, 2.696847399781801],
						[60.502125031705745, 2.6968473964068358],
						[60.502125036253481, 2.6968473973464584],
						[60.502186129764169, 2.6968362973584727],
						[60.502230624469419, 2.6968307995951033],
						[60.502302822975068, 2.6968223996019582],
						[60.50230282743702, 2.6968223987321807],
						[60.50230283457612, 2.6968223973405445],
						[60.502302839038094, 2.6968223964707638],
						[60.50235833869462, 2.696811296580031],
						[60.502358342178375, 2.6968112940747933],
						[60.502358347618504, 2.6968112948404745],
						[60.502358351102274, 2.6968112923352359],
						[60.50241114327001, 2.6967973966246412],
						[60.502475034186503, 2.6967862971130865],
						[60.502475036863665, 2.6967862965912137],
						[60.502475042218002, 2.6967862955474708],
						[60.502475044002772, 2.6967862951995571],
						[60.502536144300329, 2.6967723941436326],
						[60.502536145278505, 2.6967723957790843],
						[60.502536146977491, 2.6967723936217585],
						[60.502536148762267, 2.6967723932738457],
						[60.502591748960008, 2.6967584942078644],
						[60.502591749852414, 2.6967584940339027],
						[60.502591750744784, 2.6967584938599476],
						[60.502644448173051, 2.6967445944926425],
						[60.502716743300468, 2.6967279941681954],
						[60.50276944115322, 2.6967168959152041],
						[60.502769443830395, 2.6967168953933189],
						[60.502769449184719, 2.6967168943495539],
						[60.502769451861894, 2.69671689382767],
						[60.502841748167057, 2.6966973931971236],
						[60.502877843135423, 2.6966890949630713],
						[60.502958334871082, 2.6966723976046629],
						[60.502977825261034, 2.6966695988497604],
						[60.503002821444809, 2.6966668991759981],
						[60.503002824121985, 2.6966668986541049],
						[60.503002827691532, 2.6966668979582478],
						[60.503002830368722, 2.6966668974363515],
						[60.503075023409174, 2.6966557987575621],
						[60.503111110775791, 2.6966530004779625],
						[60.503211100064192, 2.6966501993871792],
						[60.503299994312876, 2.696652999880913],
						[60.503299995205275, 2.6966529997069437],
						[60.503299997882422, 2.6966529991850465],
						[60.503299999752997, 2.6966530006465823],
						[60.503327787997854, 2.696652999916699],
						[60.503397167594713, 2.696661297579289],
						[60.503424961078807, 2.6966668967453935],
						[60.503424965626557, 2.6966668976850139],
						[60.503424976421016, 2.6966668974068653],
						[60.503424980968752, 2.6966668983464896],
						[60.503452771172988, 2.6966695981727549],
						[60.503524962431463, 2.6966834965546655],
						[60.503524963323848, 2.6966834963806985],
						[60.503524963323848, 2.6966834963806985],
						[60.503569352259504, 2.696691894113123],
						[60.503633241891784, 2.6967112916195202],
						[60.503633245547135, 2.6967112927331205],
						[60.503633254642601, 2.6967112946124048],
						[60.503633259190373, 2.6967112955520309],
						[60.503686053118962, 2.696722395703246],
						[60.503758244085269, 2.6967418916480068],
						[60.503811040228975, 2.69675849016159],
						[60.503811043884319, 2.6967584912752192],
						[60.503811051195029, 2.6967584935024389],
						[60.503811054850367, 2.6967584946160512],
						[60.50388325277985, 2.6967751934099953],
						[60.503938849902838, 2.6967890947079813],
						[60.503991648633026, 2.6968029937170406],
						[60.503991652288377, 2.6968029948306627],
						[60.503991658620912, 2.6968029954223778],
						[60.503991662276249, 2.6968029965359999],
						[60.504063857095922, 2.6968168946776117],
						[60.504108250746533, 2.6968279945810125],
						[60.504108257971446, 2.6968279949987854],
						[60.504108270722277, 2.6968279979917296],
						[60.504108277054783, 2.6968279985834518],
						[60.504180570293315, 2.6968362984975585],
						[60.504224962621819, 2.6968445972756543],
						[60.504224971631501, 2.6968445973454784],
						[60.50422499071486, 2.6968446009301816],
						[60.504224999638744, 2.696844599190499],
						[60.504297190320699, 2.6968446006437019],
						[60.504324979801865, 2.6968473994067264],
						[60.504324990596324, 2.6968473991286284],
						[60.504325012185241, 2.6968473985724017],
						[60.504325022087329, 2.6968473984682535],
						[60.504350018908625, 2.6968445981879423],
						[60.504422215406898, 2.6968390996721081],
						[60.504422218084066, 2.6968390991502047],
						[60.504422222546012, 2.6968390982803547],
						[60.504422225223188, 2.6968390977584447],
						[60.50446672501436, 2.6968334979921265],
						[60.504466728583914, 2.6968334972962467],
						[60.504466736701218, 2.6968334975400281],
						[60.504466740270786, 2.6968334968441492],
						[60.504547240212389, 2.6968168957986141],
						[60.504547244674335, 2.6968168949287574],
						[60.504547252705834, 2.6968168933630192],
						[60.504547258060171, 2.6968168923191929],
						[60.504602857899521, 2.6968001926733138],
						[60.504602864952844, 2.6968001894720137],
						[60.504602879145267, 2.6968001848789389],
						[60.50460288619859, 2.6968001816776224],
						[60.504655682403104, 2.6967751821554375],
						[60.504727877654474, 2.6967445835081745],
						[60.50472788390119, 2.6967445822903571],
						[60.504727895330653, 2.6967445764096274],
						[60.504727899706801, 2.696744573730224],
						[60.504780699837852, 2.6967140725347796],
						[60.50487230040833, 2.6966612724983618],
						[60.504872307461653, 2.6966612692969836],
						[60.504872321396668, 2.6966612592751851],
						[60.504872328364193, 2.6966612542642689],
						[60.504889020372602, 2.6966473601865895],
						[60.504905711028655, 2.6966362678568472],
						[60.504905715319019, 2.6966362633678527],
						[60.504905723093124, 2.6966362563734241],
						[60.504905728361649, 2.696636253520015],
						[60.504986227040291, 2.6965695553706723],
						[60.50505842297806, 2.6965112584071571],
						[60.505114020027676, 2.6964695602946795],
						[60.50511402261904, 2.696469557963173],
						[60.505114030478929, 2.6964695527781939],
						[60.505114032984494, 2.6964695486371366],
						[60.505166832963283, 2.6964223488765984],
						[60.505219532923938, 2.6963751483652891],
						[60.505219533816337, 2.6963751481912994],
						[60.505219535601121, 2.6963751478433218],
						[60.5052195373001, 2.6963751456857872],
						[60.50526403491687, 2.6963334488414032],
						[60.505336232612926, 2.6962695492035182],
						[60.505336234311905, 2.6962695470459721],
						[60.505336236989066, 2.6962695465239963],
						[60.505336238688038, 2.6962695443664511],
						[60.505408427309391, 2.696200154513694],
						[60.505444514339864, 2.6961751640280625],
						[60.505480714406247, 2.6961501642280266],
						[60.505480719588974, 2.6961501595649073],
						[60.505480732631561, 2.6961501497166673],
						[60.50548073870668, 2.6961501448795517],
						[60.505552929933643, 2.6960807539126654],
						[60.505641819892141, 2.6960140604239062],
						[60.505641826773832, 2.6960140536031574],
						[60.505641842407776, 2.6960140414232279],
						[60.505641848397048, 2.6960140347764661],
						[60.50566953205788, 2.6959834520264856],
						[60.505714012678148, 2.6959529654322116],
						[60.505714017946687, 2.6959529625786076],
						[60.505714025720749, 2.6959529555838118],
						[60.505714030903455, 2.6959529509206286],
						[60.505775123415034, 2.6959001583328064],
						[60.505830713725302, 2.6958612646881654],
						[60.505902912102364, 2.6958112664711091],
						[60.505991810060408, 2.6957529673623966],
						[60.505991817834435, 2.6957529603674928],
						[60.505991834360735, 2.6957529480132854],
						[60.50599184213479, 2.695752941018398],
						[60.506008419181384, 2.6957362631070914],
						[60.506036188576296, 2.6957223794831662],
						[60.506036192952436, 2.6957223768035061],
						[60.506036200898123, 2.6957223734277989],
						[60.506036205274235, 2.6957223707481401],
						[60.506108402544172, 2.6956779716427324],
						[60.506161189166157, 2.6956473785420942],
						[60.506233378446829, 2.6956168846200463],
						[60.506233379339228, 2.69561688444603],
						[60.506233380145787, 2.6956168824624034],
						[60.506297274532166, 2.6955890852885194],
						[60.506350060576509, 2.6955695907104436],
						[60.50642224384336, 2.6955501945350222],
						[60.506466733697231, 2.6955418968687828],
						[60.506538915143722, 2.6955307999032283],
						[60.506574983917737, 2.6955307996161828],
						[60.506591668246834, 2.6955334963769895],
						[60.506591671902193, 2.6955334974905378],
						[60.506591680105302, 2.6955334995436284],
						[60.506591684567255, 2.6955334986735204],
						[60.506663877006339, 2.695539099833582],
						[60.506716650660579, 2.6955473956303897],
						[60.506780530741317, 2.6955695886213058],
						[60.506844312085683, 2.695594580699979],
						[60.506905495630711, 2.6956307711177625],
						[60.506988785248723, 2.6956834644134924],
						[60.507013767092026, 2.6957029495967695],
						[60.507041559227581, 2.6957307411458897],
						[60.507041561183982, 2.6957307444171685],
						[60.507041565817538, 2.6957307471663867],
						[60.507041567773939, 2.6957307504376811],
						[60.507130461319321, 2.6958084433314937],
						[60.507213746761913, 2.6958973283933654],
						[60.507238740397284, 2.6959307196310416],
						[60.507238746180654, 2.6959307276353122],
						[60.507238757575792, 2.6959307400244974],
						[60.507238764251547, 2.6959307478547569],
						[60.507274848626714, 2.6959640311757229],
						[60.507347036071032, 2.6960668157485137],
						[60.507347036963431, 2.6960668155745013],
						[60.507347038919818, 2.6960668188458277],
						[60.507347038919818, 2.6960668188458277],
						[60.507419237941782, 2.6961640182530635],
						[60.507474836861732, 2.6962418164996711],
						[60.507474837754124, 2.6962418163256645],
						[60.507474838818119, 2.6962418197710369],
						[60.507474839710504, 2.6962418195970321],
						[60.507555435339455, 2.6963501144858455],
						[60.507580431198598, 2.696389007147578],
						[60.507599830604093, 2.696419506480237],
						[60.507599831668074, 2.6964195099256143],
						[60.507599833538634, 2.6964195113873002],
						[60.507599834602651, 2.6964195148326917],
						[60.507680434988565, 2.6965362148403798],
						[60.507680435880935, 2.6965362146663834],
						[60.507680436859118, 2.6965362163020754],
						[60.507680437837308, 2.6965362179377692],
						[60.5077442380679, 2.6966251174721649],
						[60.50774423904609, 2.6966251191078645],
						[60.507744242873031, 2.6966251238409673],
						[60.507744244743606, 2.6966251253026856],
						[60.507816541362061, 2.6967140214795671],
						[60.507897035925247, 2.6968251144842736],
						[60.507913733559413, 2.6968501102251765],
						[60.507913739342712, 2.6968501182297762],
						[60.507913749931177, 2.6968501326032577],
						[60.507913754650524, 2.6968501371624125],
						[60.50793941103997, 2.6968769970709268],
						[60.508005447080677, 2.6969557288798889],
						[60.50800544805886, 2.6969557305156133],
						[60.508022047830167, 2.6969751291587953],
						[60.508022082392912, 2.6969751552960619],
						[60.508022157971119, 2.6969751917008895],
						[60.50802219979316, 2.6969751999847467],
						[60.508024910679083, 2.6969751992795414],
						[60.508102651733964, 2.6970612345593716],
						[60.508102658323892, 2.6970612405803389],
						[60.508102670611308, 2.6970612527962579],
						[60.508102678093614, 2.6970612586432363],
						[60.508138777730153, 2.6970890580347264],
						[60.508138782449471, 2.6970890625939501],
						[60.508138790824162, 2.6970890682669535],
						[60.508138795371913, 2.6970890692067293],
						[60.508174878633135, 2.6971112609803036],
						[60.508247064027699, 2.6971779475307112],
						[60.508247069639395, 2.6971779519159949],
						[60.50824708086283, 2.6971779606865334],
						[60.508247087366954, 2.6971779648978238],
						[60.508327686608681, 2.6972334643895879],
						[60.508327687586856, 2.6972334660253354],
						[60.508327690349802, 2.6972334673131293],
						[60.508327691242187, 2.697233467139144],
						[60.508374890744967, 2.6972640689568714],
						[60.508374891637359, 2.6972640687828879],
						[60.508374893422129, 2.6972640684349156],
						[60.50842769309709, 2.697297368418015],
						[60.508427697730639, 2.6972973711675814],
						[60.508427706105302, 2.6972973768406714],
						[60.508427710738836, 2.6972973795902404],
						[60.508472110822254, 2.6973195794910994],
						[60.508472115370004, 2.6973195804309151],
						[60.508472123658898, 2.6973195842942919],
						[60.508472128292418, 2.6973195870438511],
						[60.508508228233921, 2.6973334874878998],
						[60.508508230996874, 2.6973334887756906],
						[60.508508238221779, 2.6973334891935723],
						[60.508508240984746, 2.6973334904813617],
						[60.508588835273152, 2.6973584895467089],
						[60.508633230296908, 2.69737518742844],
						[60.508633239392374, 2.6973751893081137],
						[60.508633256776726, 2.6973751950511784],
						[60.508633265872213, 2.6973751969308331],
						[60.508697166296599, 2.6973862965526747],
						[60.508697169059573, 2.6973862978404748],
						[60.508697176284471, 2.6973862982583512],
						[60.508697179047445, 2.6973862995461499],
						[60.508749978688932, 2.6973918995305306],
						[60.50874998582804, 2.6973918981386515],
						[60.508750001256047, 2.6973919006101923],
						[60.50875000928756, 2.6973918990443289],
						[60.508813909173263, 2.6973890992365495],
						[60.508813911936222, 2.6973891005243553],
						[60.508813919075344, 2.6973890991324696],
						[60.508813922644897, 2.6973890984365272],
						[60.508886123277051, 2.6973807989405292],
						[60.508886132200949, 2.6973807972006663],
						[60.508886150941123, 2.6973807935469538],
						[60.508886159865035, 2.6973807918070904],
						[60.508930659715531, 2.6973668901934666],
						[60.508930660607916, 2.6973668900194792],
						[60.508930664177484, 2.6973668893235319],
						[60.508930665069869, 2.6973668891495444],
						[60.509002864730633, 2.6973418882183475],
						[60.509002870084956, 2.6973418871744204],
						[60.50900287892307, 2.6973418836247669],
						[60.5090028842774, 2.6973418825808388],
						[60.509038983596596, 2.6973251821242563],
						[60.509038987972737, 2.6973251794445403],
						[60.509038995832661, 2.6973251742590763],
						[60.509039000294614, 2.6973251733891295],
						[60.509091799606416, 2.6972945738310621],
						[60.509091801391193, 2.6972945734830844],
						[60.50909180219778, 2.6972945714993162],
						[60.509091803090165, 2.6972945713253296],
						[60.509147303293396, 2.6972612715148174],
						[60.509147310260921, 2.6972612665033253],
						[60.509147325088364, 2.6972612563063407],
						[60.509147332055875, 2.6972612512948504],
						[60.509191831201719, 2.6972223518702871],
						[60.50927233110589, 2.6971529513493175],
						[60.509272337987596, 2.6971529445279825],
						[60.509272350858673, 2.6971529310593194],
						[60.509272356848001, 2.6971529244119976],
						[60.509333451815586, 2.6970751315079391],
						[60.509377945828547, 2.6970279365117067],
						[60.509377946635134, 2.6970279345279167],
						[60.50937794841991, 2.6970279341799173],
						[60.509433448265973, 2.6969668339137067],
						[60.509433453448715, 2.6969668292500901],
						[60.509433461051231, 2.6969668186351061],
						[60.509433465255789, 2.6969668123356985],
						[60.509486264928647, 2.696889012346217],
						[60.509530763270902, 2.6968251160199821],
						[60.509602961352208, 2.6967251196908899],
						[60.509683458826061, 2.6966195208541124],
						[60.509683463116389, 2.6966195163644344],
						[60.50968346901989, 2.6966195079071009],
						[60.509683472332043, 2.6966195017816101],
						[60.509711260958959, 2.6965723210060966],
						[60.509755746364924, 2.6965251373455463],
						[60.509755746279119, 2.6965251355357354],
						[60.50975574797809, 2.6965251333778935],
						[60.509755748870482, 2.6965251332038793],
						[60.509827947724446, 2.6964445332527558],
						[60.509889044569448, 2.6963779376064676],
						[60.509933430809568, 2.6963334504626784],
						[60.509977920093199, 2.6963001599919529],
						[60.50997792098557, 2.6963001598179321],
						[60.509977921792149, 2.6963001578340866],
						[60.510050111991475, 2.6962445659332688],
						[60.51013070076079, 2.6961973729208628],
						[60.510130701653182, 2.6961973727468349],
						[60.510130704244531, 2.6961973704149247],
						[60.510130705136916, 2.696197370240895],
						[60.510166798713279, 2.6961751746220495],
						[60.51021948503697, 2.696147381347167],
						[60.510266775669294, 2.6961279848860888],
						[60.510266781023631, 2.6961279838419032],
						[60.51026679156066, 2.6961279781338603],
						[60.510266796829171, 2.696127975279841],
						[60.510291782037832, 2.6961140831045851],
						[60.510372264688172, 2.6960862882646701],
						[60.510372265666369, 2.696086289900482],
						[60.510427866284651, 2.696066889156763],
						[60.510427867177007, 2.6960668889827315],
						[60.510427868069399, 2.6960668888087014],
						[60.510427868961784, 2.6960668886346637],
						[60.510488955914866, 2.6960445931055368],
						[60.510552842358109, 2.6960307949757314],
						[60.510552844142886, 2.6960307946276636],
						[60.510552847712432, 2.6960307939315236],
						[60.510552851281972, 2.6960307932353813],
						[60.510616746105924, 2.6960140946019528],
						[60.510669441310142, 2.6960029958286205],
						[60.510669448449242, 2.6960029944363253],
						[60.510669461749238, 2.6960029900159128],
						[60.510669468888338, 2.6960029886236185],
						[60.510722269228047, 2.6959834888582703],
						[60.510722272711767, 2.6959834863522429],
						[60.510722279765055, 2.6959834831500773],
						[60.510722284227008, 2.6959834822798885],
						[60.510794476796406, 2.695950185176796],
						[60.510838970090454, 2.6959334871536811],
						[60.510838970982853, 2.6959334869796394],
						[60.51083897276763, 2.6959334866315579],
						[60.510838973660007, 2.6959334864575211],
						[60.510894473961933, 2.6959112862207553],
						[60.510894478338059, 2.6959112835406756],
						[60.510894486283732, 2.6959112801644349],
						[60.510894488960879, 2.6959112796423135],
						[60.510938987116816, 2.6958890806227362],
						[60.510975083704594, 2.6958723815661414],
						[60.510975088973105, 2.6958723787120111],
						[60.510975100402518, 2.695872372829704],
						[60.51097510477863, 2.6958723701496154],
						[60.511047298839756, 2.6958279736649757],
						[60.511116791873242, 2.6957918778740746],
						[60.51111680062548, 2.6957918725138477],
						[60.511116817237571, 2.6957918619674266],
						[60.51111682509741, 2.6957918567812484],
						[60.511172312347519, 2.6957473664655107],
						[60.511261198275832, 2.6956973739331849],
						[60.511261199168217, 2.6956973737591343],
						[60.511280698538876, 2.6956862732348683],
						[60.511280704699772, 2.6956862702066267],
						[60.511280716935723, 2.6956862623402511],
						[60.511280723010785, 2.6956862575021021],
						[60.511305722663266, 2.6956668580257026],
						[60.511305725254601, 2.6956668556936587],
						[60.511305728738314, 2.6956668531875647],
						[60.511305731329664, 2.6956668508554986],
						[60.511386227951789, 2.6955973544335241],
						[60.511458425615466, 2.6955390552376151],
						[60.511458425615466, 2.6955390552376151],
						[60.511458428292634, 2.6955390547154501],
						[60.511458429099207, 2.6955390527314891],
						[60.511511226612278, 2.6954945545318378],
						[60.511566819271415, 2.6954501612781172],
						[60.511619512631079, 2.6954140655945231],
						[60.511619520405084, 2.6954140585982387],
						[60.511619534339971, 2.6954140485736233],
						[60.511619541221599, 2.6954140417514139],
						[60.511655741007303, 2.6953779426428763],
						[60.511655741813868, 2.695377940658902],
						[60.511655742620427, 2.6953779386749277],
						[60.511655744405218, 2.6953779383268031],
						[60.511727943560523, 2.6953029384918987],
						[60.511800142754048, 2.6952279401248949],
						[60.511844541669838, 2.6951834405974484],
						[60.511844544261187, 2.6951834382653037],
						[60.511844550250402, 2.6951834316170658],
						[60.511844552841737, 2.6951834292849401],
						[60.511889052788547, 2.6951307282724435],
						[60.51188905627226, 2.6951307257662447],
						[60.511889062175662, 2.6951307173080399],
						[60.511889064595337, 2.6951307113560596],
						[60.511941865353222, 2.6950529127970997],
						[60.511941867858738, 2.695052908655009],
						[60.51194187206319, 2.6950529023548695],
						[60.5119418736763, 2.6950528983868534],
						[60.511986272424423, 2.694975101811738],
						[60.51205846088159, 2.6948584193327951],
						[60.51213904987079, 2.6947668329167449],
						[60.512183398551002, 2.6947168893792259],
						[60.512255604046494, 2.6947085012244139],
						[60.512291684879429, 2.6947112994159514],
						[60.512291707360681, 2.6947112986837678],
						[60.512291752065742, 2.6947112917895226],
						[60.512291774289515, 2.6947112856274829],
						[60.512319473996676, 2.694700186411116],
						[60.512319488995558, 2.6947001798317847],
						[60.51231951605866, 2.6947001617655082],
						[60.512319529186918, 2.6947001537244075],
						[60.512408428604076, 2.6946251534456422],
						[60.512408432980173, 2.6946251507652379],
						[60.512408439775911, 2.6946251421327352],
						[60.512408444152001, 2.6946251394523331],
						[60.512489044096924, 2.6945418388294384],
						[60.512489050892661, 2.694541830196878],
						[60.512489066183086, 2.6945418107736203],
						[60.512489072086424, 2.6945418023151704],
						[60.512525172235378, 2.6944807020354418],
						[60.512525177160519, 2.6944806919410813],
						[60.51252518611841, 2.6944806719264589],
						[60.512525190151138, 2.694480662006197],
						[60.512577990190422, 2.6943194642913531],
						[60.512622388904397, 2.6941917666031268],
						[60.512622391324051, 2.6941917606509174],
						[60.512622392593791, 2.6941917494429251],
						[60.51262239509925, 2.6941917453007025],
						[60.512650193079956, 2.6940722510551613],
						[60.512686292031226, 2.6939500574468198],
						[60.51268629275193, 2.6939500536527632],
						[60.512686294999867, 2.6939500440806161],
						[60.512686295720556, 2.6939500402865639],
						[60.512711295653624, 2.6938278399274522],
						[60.512711297180857, 2.6938278341493347],
						[60.512711297729872, 2.6938278267353399],
						[60.512711299257084, 2.6938278209572237],
						[60.512727998127033, 2.6936694324271446],
						[60.512739095372474, 2.6936194436875391],
						[60.512739096179011, 2.6936194417034551],
						[60.512739096899701, 2.6936194379094043],
						[60.512739096727998, 2.6936194342894542],
						[60.5127473957333, 2.693572238361126],
						[60.512783496210979, 2.6934028427747378],
						[60.512783495953443, 2.693402837344812],
						[60.512783498201337, 2.6934028277725859],
						[60.512783498836171, 2.6934028221685553],
						[60.512800196746817, 2.6932583418567919],
						[60.512827989688873, 2.6931694611046568],
						[60.512852985111657, 2.6930944776253964],
						[60.51292517658225, 2.6929556925591887],
						[60.512925179173536, 2.6929556902268041],
						[60.512925180700734, 2.6929556844485889],
						[60.512925183206157, 2.6929556803062322],
						[60.512969583275016, 2.6928556813223841],
						[60.51303348184846, 2.6927139813532883],
						[60.513033484439745, 2.6927139790208763],
						[60.513033485966936, 2.6927139732426308],
						[60.513033486687583, 2.6927139694485289],
						[60.513077984146918, 2.6925944777008053],
						[60.513130776375803, 2.6924833956760326],
						[60.513191868612481, 2.6923862062041417],
						[60.513191872096144, 2.692386203697537],
						[60.513191876214584, 2.6923861955868014],
						[60.51319187782763, 2.6923861916185157],
						[60.513255778319298, 2.6922611912635439],
						[60.513308477366223, 2.6921611926915014],
						[60.51330847817276, 2.6921611907073424],
						[60.5133084805923, 2.6921611847548643],
						[60.513308483097696, 2.6921611806123948],
						[60.513364079081114, 2.6920361907271801],
						[60.513416873071293, 2.6919444992654604],
						[60.513416874684303, 2.6919444952971117],
						[60.513416878888584, 2.691944488996278],
						[60.513416881393987, 2.6919444848537566],
						[60.513477972635414, 2.6918140026456019],
						[60.513514039409365, 2.6917640488246675],
						[60.513550105324846, 2.6917418692518744],
						[60.513550110593251, 2.6917418663968231],
						[60.513550121936554, 2.691741858702525],
						[60.513550128011488, 2.6917418538632885],
						[60.513630727794116, 2.6916751535574073],
						[60.513711227625166, 2.6916084541425991],
						[60.513711229409914, 2.6916084537942431],
						[60.513711231915302, 2.6916084496516595],
						[60.51371123370005, 2.6916084493033021],
						[60.513739033878124, 2.6915834491660497],
						[60.513739033792248, 2.6915834473560376],
						[60.513739034684626, 2.691583447181856],
						[60.513739035576997, 2.6915834470076763],
						[60.51377512984844, 2.691550152861736],
						[60.513855723913181, 2.6914862567979667],
						[60.513855729095674, 2.6914862521328109],
						[60.513855736869473, 2.6914862451350929],
						[60.513855741159595, 2.6914862406441178],
						[60.513916841070618, 2.6914251409722145],
						[60.513916841962988, 2.6914251407980299],
						[60.51391684366186, 2.6914251386396182],
						[60.513916845360761, 2.6914251364812167],
						[60.513964045386096, 2.6913751365607936],
						[60.513964051460995, 2.6913751317214096],
						[60.513964061654278, 2.6913751187709778],
						[60.513964065772647, 2.6913751106599371],
						[60.514008465887201, 2.6913084103397664],
						[60.514008470091447, 2.6913084040387139],
						[60.514008477607597, 2.6913083916108138],
						[60.514008480919472, 2.6913083854839646],
						[60.514069570846573, 2.69117790526282],
						[60.514133456059071, 2.6910973242136365],
						[60.514194553899394, 2.6910223271071483],
						[60.514266849725018, 2.6909362325575894],
						[60.514302943916242, 2.6908973397445268],
						[60.514339040996262, 2.6908612413039639],
						[60.51433904278101, 2.6908612409555537],
						[60.514339045286363, 2.6908612368128528],
						[60.514339047071125, 2.6908612364644453],
						[60.514411244692425, 2.6907834376249951],
						[60.514483435952684, 2.6907084474912542],
						[60.514527926336847, 2.6906723542132447],
						[60.514527930712831, 2.6906723515321396],
						[60.514527941884296, 2.6906723402171],
						[60.514527947066782, 2.6906723355517226],
						[60.514564038579969, 2.6906334444498596],
						[60.514644530880467, 2.6905640508369117],
						[60.514644536062946, 2.6905640461715032],
						[60.514644544643147, 2.6905640371890907],
						[60.514644549825647, 2.6905640325236773],
						[60.514705746348952, 2.690494536380903],
						[60.514750140896616, 2.6904501418160036],
						[60.514750146885589, 2.690450135166254],
						[60.514750157078772, 2.6904501222152089],
						[60.514750162261258, 2.6904501175497613],
						[60.514786257348916, 2.6904001234936157],
						[60.514866852090975, 2.6903057303012075],
						[60.514866853789819, 2.6903057281426892],
						[60.514866857187542, 2.6903057238256483],
						[60.514866858886393, 2.6903057216671153],
						[60.51494735862213, 2.6902001223579717],
						[60.514975157837966, 2.6901640220059111],
						[60.514975161321566, 2.6901640194989027],
						[60.514975166332235, 2.6901640112132212],
						[60.514975167945209, 2.6901640072445874],
						[60.515000159141508, 2.690125121061131],
						[60.515083448084354, 2.690033434006915],
						[60.515083449783212, 2.6900334318483656],
						[60.515083453180928, 2.6900334275312692],
						[60.515083454879772, 2.690033425372703],
						[60.515144542925007, 2.6899584403786276],
						[60.515197329018335, 2.6899140537951558],
						[60.515197335007265, 2.6899140471452148],
						[60.515197346264564, 2.6899140376397774],
						[60.51519735136111, 2.6899140311640717],
						[60.515233443993175, 2.6898723393508961],
						[60.515305730924425, 2.6898057501112538],
						[60.515394526033049, 2.6897334544965923],
						[60.515394538182704, 2.6897334448167745],
						[60.515394558654819, 2.6897334207238011],
						[60.51539456884791, 2.6897334077722643],
						[60.515414055193283, 2.6897029286713887],
						[60.515450135972159, 2.6896695472849017],
						[60.51545013758512, 2.6896695433161999],
						[60.515450143659926, 2.6896695384762634],
						[60.515450146251155, 2.6896695361434251],
						[60.515530739178999, 2.6895834443855424],
						[60.515619532037455, 2.6895057514055027],
						[60.515619531951565, 2.689505749595388],
						[60.515619532843928, 2.689505749421139],
						[60.515619533736313, 2.6895057492468881],
						[60.515647333638753, 2.6894807493716844],
						[60.515647338735299, 2.6894807428958147],
						[60.515647349906651, 2.6894807315799554],
						[60.515647355089087, 2.6894807269142187],
						[60.515672353073604, 2.6894501284491685],
						[60.515752943283495, 2.6893584402384061],
						[60.515844535208444, 2.6892751480730062],
						[60.515844536014903, 2.6892751460886255],
						[60.515844540390866, 2.6892751434071922],
						[60.515844541197353, 2.6892751414228067],
						[60.515869540971501, 2.6892501417099344],
						[60.515869545261573, 2.6892501372183668],
						[60.515869551336372, 2.6892501323783011],
						[60.515869553841689, 2.6892501282352521],
						[60.515897349171048, 2.6892168346632692],
						[60.515986243728854, 2.6891251384841501],
						[60.515986246320089, 2.6891251361512252],
						[60.515986248911297, 2.6891251338183002],
						[60.515986250610133, 2.6891251316596416],
						[60.516058451115661, 2.6890418304983421],
						[60.51605845370689, 2.6890418281654052],
						[60.516058458803386, 2.6890418216893832],
						[60.516058462201066, 2.6890418173720452],
						[60.516094561971343, 2.6889918168098057],
						[60.516094562777816, 2.6889918148253988],
						[60.516094566175475, 2.6889918105080537],
						[60.516094567067853, 2.6889918103337846],
						[60.516147362814415, 2.6889112165643891],
						[60.516202958297086, 2.6888390222038696],
						[60.516202961608819, 2.6888390160763347],
						[60.516202970102988, 2.6888390052828721],
						[60.516202974307134, 2.6888389989810744],
						[60.516236272766562, 2.6887807008732665],
						[60.51630847128321, 2.6886584046075805],
						[60.516361260724942, 2.688575119866921],
						[60.516433450744692, 2.6884918312446664],
						[60.51643345584116, 2.6884918247684824],
						[60.516433466034123, 2.6884918118161263],
						[60.516433471044692, 2.6884918035297822],
						[60.516505760595322, 2.6883723216153865],
						[60.516541847325698, 2.6883334353976878],
						[60.516541848218054, 2.6883334352233996],
						[60.516541850809283, 2.6883334328903672],
						[60.516541851615713, 2.6883334309059208],
						[60.516558435597503, 2.6883140471683102],
						[60.516658416855194, 2.6882418614685055],
						[60.516658424714713, 2.6882418562795212],
						[60.516658439455441, 2.6882418442657126],
						[60.516658446336642, 2.6882418374408581],
						[60.516747345936452, 2.6881473363092137],
						[60.516747349420001, 2.6881473338018624],
						[60.516747355408853, 2.6881473271512566],
						[60.516747357914113, 2.6881473230080299],
						[60.516766844597086, 2.6881223406489103],
						[60.51678341785172, 2.6881084615515274],
						[60.516863999071028, 2.6880584742146101],
						[60.516927883993255, 2.6880251814763914],
						[60.516997274350167, 2.6879973866947573],
						[60.516997288370355, 2.6879973784753521],
						[60.516997312927124, 2.6879973645439672],
						[60.516997326054955, 2.6879973564988844],
						[60.517069525709175, 2.6879390559458809],
						[60.517069529999176, 2.6879390514539878],
						[60.517069537772741, 2.6879390444546716],
						[60.517069541170386, 2.6879390401370764],
						[60.517097335266882, 2.687911247184386],
						[60.517133419832795, 2.6878807605512827],
						[60.517205708774796, 2.6878334678689138],
						[60.517205715741902, 2.6878334628540625],
						[60.517205727891408, 2.687833453172952],
						[60.517205733073773, 2.6878334485067139],
						[60.517258427710459, 2.6877862541305499],
						[60.517330721732499, 2.6877307586785477],
						[60.517330721732499, 2.6877307586785477],
						[60.517330724323671, 2.6877307563454136],
						[60.517411219827174, 2.6876668603263236],
						[60.517439015083411, 2.6876473648118355],
						[60.517439030630513, 2.6876473508129108],
						[60.51743905887573, 2.6876473197175845],
						[60.517439070681505, 2.6876473027954799],
						[60.517455760281798, 2.6876195207076905],
						[60.517544546222233, 2.6875251361905979],
						[60.517544546222233, 2.6875251361905979],
						[60.517544548006995, 2.6875251358419563],
						[60.517544547921048, 2.6875251340317279],
						[60.517625145405802, 2.6874362382586656],
						[60.517661238458338, 2.6874001454471896],
						[60.517689021643456, 2.6873751598948319],
						[60.517777909549508, 2.6873168668512375],
						[60.517777921698958, 2.6873168571697525],
						[60.517777945019553, 2.6873168361709006],
						[60.517777956190692, 2.6873168248535166],
						[60.517858445886354, 2.6872168388023163],
						[60.517886234214473, 2.6871918482654498],
						[60.517886237612053, 2.6871918439476525],
						[60.517886247976769, 2.6871918346147452],
						[60.517886252266734, 2.6871918301226314],
						[60.517902937664481, 2.6871723465507937],
						[60.517991820001967, 2.6871057607160926],
						[60.51799182080839, 2.6871057587315228],
						[60.517991824291926, 2.6871057562239531],
						[60.517991826076674, 2.6871057558752862],
						[60.518064008746926, 2.6870473687864029],
						[60.518119474632414, 2.6870195852363667],
						[60.518191750853134, 2.6869973931972528],
						[60.518216711916729, 2.6869919027938272],
						[60.518244375416124, 2.6869945994158937],
						[60.518316658849443, 2.6870057969320191],
						[60.518369345016637, 2.6870195916879278],
						[60.518441604695411, 2.687041880494605],
						[60.518494275887633, 2.6870834568596047],
						[60.518494276780011, 2.6870834566852668],
						[60.518494276865951, 2.6870834584955485],
						[60.518558177302019, 2.6871334582547379],
						[60.518558178194375, 2.6871334580804032],
						[60.51855818006505, 2.6871334595420153],
						[60.518558181043353, 2.6871334611779494],
						[60.518622081163144, 2.6871807607851217],
						[60.518622085796871, 2.6871807635340086],
						[60.518622096935012, 2.687180770493339],
						[60.518622101568745, 2.6871807732422197],
						[60.518666599567183, 2.687205772933821],
						[60.518694297112788, 2.687222372575107],
						[60.518694298897529, 2.6872223722264357],
						[60.518694301660588, 2.6872223735137055],
						[60.518694302638892, 2.6872223751496476],
						[60.518774902883749, 2.6872668748091959],
						[60.518774903776141, 2.6872668746348567],
						[60.518774903862074, 2.6872668764451402],
						[60.518836003902109, 2.6873001764416742],
						[60.518836006665161, 2.6873001777289582],
						[60.518836011212947, 2.6873001786675741],
						[60.518836013976006, 2.6873001799548555],
						[60.51889991387376, 2.6873307807888382],
						[60.51889991387376, 2.6873307807888382],
						[60.518899915658508, 2.6873307804401692],
						[60.518899915744434, 2.6873307822504597],
						[60.518972116249294, 2.6873640826472314],
						[60.518972119904717, 2.6873640837601869],
						[60.518972126323192, 2.6873640861604562],
						[60.518972129978614, 2.6873640872734166],
						[60.519016630185845, 2.6873807865685566],
						[60.519016631164149, 2.6873807882045195],
						[60.51901663303483, 2.6873807896661481],
						[60.519016634819572, 2.6873807893174817],
						[60.519088834875369, 2.6874057895435643],
						[60.51908883666011, 2.6874057891949024],
						[60.519088841207896, 2.6874057901335373],
						[60.519088843078571, 2.6874057915951868],
						[60.519116642840856, 2.6874140912486979],
						[60.519116657462526, 2.687414095700595],
						[60.519116686448065, 2.6874140991734654],
						[60.519116700005497, 2.6874141001790752],
						[60.519133291713118, 2.6874141000983007],
						[60.519233282806404, 2.68742239986222],
						[60.519233289138931, 2.6874224004521952],
						[60.519233300825711, 2.6874223999961644],
						[60.519233306265861, 2.6874224007604721],
						[60.519322203105808, 2.6874195995048527],
						[60.519349999751157, 2.6874195992865744],
						[60.519350012330271, 2.6874195986562244],
						[60.519350035617862, 2.6874195959338434],
						[60.519350049003428, 2.687419593318829],
						[60.519394449105548, 2.6874084946905943],
						[60.519394454459771, 2.6874084936445843],
						[60.519394464103975, 2.687408488106251],
						[60.519394468565828, 2.6874084872345754],
						[60.519447268538421, 2.6873890877339899],
						[60.51944727032317, 2.6873890873853181],
						[60.519447271215533, 2.6873890872109807],
						[60.519447272107918, 2.6873890870366441],
						[60.51948337213517, 2.6873751864129374],
						[60.519483375704645, 2.6873751857155912],
						[60.519483385348856, 2.6873751801772325],
						[60.519483388918346, 2.6873751794798832],
						[60.519555688519858, 2.6873390781875988],
						[60.519555690390533, 2.6873390796492482],
						[60.519555692089355, 2.6873390774902406],
						[60.519555692981719, 2.6873390773159067],
						[60.519619492822514, 2.6873057765436625],
						[60.519619496392025, 2.6873057758463004],
						[60.519619501574361, 2.6873057711796093],
						[60.519619505143829, 2.6873057704822503],
						[60.519691804984049, 2.6872612695198184],
						[60.519691807575235, 2.6872612671864582],
						[60.519691813735882, 2.6872612641557252],
						[60.519691817219439, 2.6872612616480249],
						[60.519772317186138, 2.6872029623195486],
						[60.519772325852024, 2.687202955145104],
						[60.519772343990233, 2.6872029388115037],
						[60.519772351677815, 2.6872029300010665],
						[60.519789035445442, 2.6871834492080993],
						[60.519816814338611, 2.6871640645281589],
						[60.519897313666128, 2.6871084653395667],
						[60.519897317149656, 2.6871084628318207],
						[60.51989732313843, 2.6871084561803626],
						[60.519897326707913, 2.6871084554829752],
						[60.519958422701926, 2.6870584576660033],
						[60.520022319405868, 2.6870112616054147],
						[60.520022321104662, 2.6870112594463631],
						[60.520022323695834, 2.6870112571129603],
						[60.520022326372946, 2.6870112565899098],
						[60.520094511070369, 2.6869529672508179],
						[60.520130695152801, 2.6869334765768857],
						[60.520130707388162, 2.6869334687048525],
						[60.520130729988168, 2.6869334514991623],
						[60.520130741159257, 2.6869334401807761],
						[60.520147327333817, 2.6869168558469232],
						[60.520236208858542, 2.6868584665394595],
						[60.520300107434778, 2.6868168695133918],
						[60.520363999973384, 2.6867779722139518],
						[60.52044449668908, 2.6867334752269754],
						[60.520444505440878, 2.6867334698626246],
						[60.520444520267375, 2.6867334596569785],
						[60.520444528126816, 2.6867334544669688],
						[60.520461209948813, 2.6867195681682374],
						[60.520488986088964, 2.6867057798034657],
						[60.520561184252934, 2.686672381489204],
						[60.520561184252934, 2.686672381489204],
						[60.520561185145311, 2.6866723813148412],
						[60.520561186037682, 2.6866723811404758],
						[60.520613982293924, 2.6866473836788138],
						[60.520686173166425, 2.6866168862486552],
						[60.520747269143186, 2.6865945881330786],
						[60.520747271820284, 2.6865945876099793],
						[60.520747278873287, 2.6865945844046566],
						[60.520747282356822, 2.6865945818967973],
						[60.520802870809057, 2.6865695885344629],
						[60.520875059394989, 2.6865473902825099],
						[60.520875060287352, 2.6865473901081418],
						[60.520927856301135, 2.6865307918332482],
						[60.521000049742128, 2.6865112948712424],
						[60.521044448844485, 2.6865001944692288],
						[60.5210444505433, 2.6865001923100778],
						[60.521044456875806, 2.686500192899886],
						[60.52104446035937, 2.6865001903919867],
						[60.521097252529366, 2.6864834921894221],
						[60.521133344761267, 2.6864751952846881],
						[60.521133347438386, 2.686475194761563],
						[60.521133352792596, 2.6864751937153275],
						[60.521133355383746, 2.686475191381799],
						[60.52115285070829, 2.6864695930659828],
						[60.521233335787876, 2.6864501967970842],
						[60.521277824988708, 2.6864445986251866],
						[60.52127782766582, 2.6864445981020615],
						[60.521277833912379, 2.686444596881441],
						[60.521277838374239, 2.6864445960095673],
						[60.521350036626856, 2.6864307967535859],
						[60.521394434252521, 2.6864223970138656],
						[60.521466727378574, 2.6864112980167869],
						[60.521511123981746, 2.6864057982231362],
						[60.521583321888052, 2.6863973982913336],
						[60.521636120669307, 2.6863918992552338],
						[60.521636123346404, 2.6863918987320994],
						[60.521636127808264, 2.6863918978602022],
						[60.521636129593006, 2.6863918975114447],
						[60.521691725005475, 2.6863834984721091],
						[60.521744421109666, 2.6863779993483985],
						[60.521797218730839, 2.6863723999647782],
						[60.521861117001343, 2.6863668989549208],
						[60.521925016876189, 2.6863612989302106],
						[60.521925017768552, 2.6863612987558296],
						[60.521925019553294, 2.6863612984070673],
						[60.521925021338014, 2.6863612980583063],
						[60.521977819222577, 2.6863557990451969],
						[60.52204171731114, 2.6863501993128871],
						[60.522041720880608, 2.6863501986153548],
						[60.522041728019566, 2.6863501972202881],
						[60.522041730782618, 2.6863501985076033],
						[60.522094426295482, 2.6863418981556788],
						[60.522147221235102, 2.6863362991404895],
						[60.522147222127472, 2.6863362989661077],
						[60.52214722480457, 2.6863362984429564],
						[60.522147225696934, 2.6863362982685723],
						[60.522211122654554, 2.6863279997911715],
						[60.52226392107611, 2.6863223982133322],
						[60.522263924645593, 2.686322397515791],
						[60.522263933655239, 2.6863223975824271],
						[60.522263938117078, 2.6863223967105032],
						[60.522336130722501, 2.6863084972238349],
						[60.522380624636035, 2.6863029981493396],
						[60.522380629097896, 2.6863029972774033],
						[60.522380638021595, 2.6863029955335356],
						[60.52238064337579, 2.6863029944872174],
						[60.52244444200177, 2.6862890961761234],
						[60.52249724057129, 2.6862779961381227],
						[60.522497242356017, 2.6862779957893506],
						[60.522497244140752, 2.6862779954405749],
						[60.522497245033136, 2.6862779952661811],
						[60.522569445157728, 2.6862612946689119],
						[60.522605645306932, 2.6862529942161548],
						[60.522605646285257, 2.6862529958522758],
						[60.522605648876407, 2.6862529935185977],
						[60.522605650661141, 2.6862529931698176],
						[60.522658347830564, 2.6862390933002032],
						[60.522705646243466, 2.6862279953277923],
						[60.522705649812941, 2.6862279946302294],
						[60.522705658650686, 2.6862279910758211],
						[60.522705664004896, 2.6862279900294737],
						[60.522730664395809, 2.6862195904319011],
						[60.522730666094603, 2.6862195882726185],
						[60.522730669664078, 2.6862195875750539],
						[60.522730672341176, 2.6862195870518786],
						[60.522802869093589, 2.6861918882898697],
						[60.522858366207949, 2.6861723885255473],
						[60.52285836799269, 2.6861723881767618],
						[60.52285837236856, 2.6861723854942907],
						[60.522858374153294, 2.6861723851455039],
						[60.522927874120491, 2.68614458641217],
						[60.522991773375161, 2.6861195851562423],
						[60.522991775245849, 2.6861195866179628],
						[60.522991777836992, 2.6861195842842633],
						[60.522991779621741, 2.6861195839354708],
						[60.52305568041281, 2.6860918833232743],
						[60.523055683896324, 2.686091880815153],
						[60.523055692734054, 2.6860918772606648],
						[60.523055698002288, 2.6860918744037696],
						[60.523125096008087, 2.686052974144149],
						[60.523161192404366, 2.6860334774367765],
						[60.523216789294487, 2.6860057785259173],
						[60.523216791079207, 2.6860057781771176],
						[60.523216796347469, 2.68600577532017],
						[60.523216798046228, 2.6860057731608471],
						[60.523261198363038, 2.6859807740254391],
						[60.523261200147772, 2.6859807736766372],
						[60.523261202738915, 2.6859807713429036],
						[60.523261205416013, 2.6859807708197008],
						[60.52329729852508, 2.6859584732811861],
						[60.523377892411908, 2.6859168781127321],
						[60.523377894110695, 2.6859168759533714],
						[60.523377899378943, 2.6859168730964118],
						[60.523377902056041, 2.685916872573201],
						[60.523439000203048, 2.6858807722704725],
						[60.523502899209539, 2.6858445739402179],
						[60.523502900994281, 2.6858445735914027],
						[60.523583400775827, 2.6857973734782248],
						[60.523583407742841, 2.6857973684618659],
						[60.523583420784519, 2.6857973586035331],
						[60.523583427751547, 2.6857973535871702],
						[60.523600107918384, 2.6857834711444255],
						[60.523636183801848, 2.6857668814804176],
						[60.52363618907011, 2.6857668786234083],
						[60.52363619880019, 2.6857668748943477],
						[60.523636203982456, 2.6857668702267916],
						[60.523700099130956, 2.6857279731717965],
						[60.523752893096578, 2.6857001778058147],
						[60.523752893988934, 2.6857001776314018],
						[60.523752893902987, 2.6857001758208487],
						[60.523752894795351, 2.6857001756464376],
						[60.52382509209707, 2.6856612783315605],
						[60.523897288924822, 2.685625178621561],
						[60.523897289817192, 2.6856251784471419],
						[60.523897291515958, 2.685625176287751],
						[60.523897293386653, 2.6856251777494808],
						[60.523950089916248, 2.6855973796938581],
						[60.524030681237896, 2.6855584821976293],
						[60.524058374424861, 2.6855473862464612],
						[60.524058384961343, 2.6855473805322716],
						[60.524058406034271, 2.685547369103932],
						[60.524058415678383, 2.6855473635641629],
						[60.524077905358659, 2.6855334707047862],
						[60.524147287157078, 2.6854973797043775],
						[60.524202879871929, 2.6854723824189928],
						[60.524275076959825, 2.6854418845250865],
						[60.524336170786022, 2.6854168862717165],
						[60.52440006600051, 2.6853945894529132],
						[60.524400070376366, 2.6853945867701956],
						[60.524400078321712, 2.6853945833897543],
						[60.524400082783551, 2.6853945825176271],
						[60.524461179016342, 2.6853668845234151],
						[60.524508371287176, 2.6853473863860153],
						[60.524588953901031, 2.6853195925172537],
						[60.524613942599331, 2.685314095835492],
						[60.524613950544676, 2.685314092455021],
						[60.524613965628944, 2.6853140876791177],
						[60.524613973660223, 2.685314086109253],
						[60.524641763162627, 2.6853029891611042],
						[60.524713950344136, 2.6852834933713079],
						[60.524758346784552, 2.6852723938449148],
						[60.524830645373932, 2.6852557945119031],
						[60.524830648943407, 2.6852557938141688],
						[60.524830656082344, 2.685255792418709],
						[60.524830659651798, 2.6852557917209765],
						[60.524875051991003, 2.685241893296415],
						[60.524947243170374, 2.6852251942039254],
						[60.525000040883782, 2.6852140951187913],
						[60.525000041862114, 2.6852140967549749],
						[60.525000042668516, 2.6852140947699183],
						[60.525063942184204, 2.6852001968742329],
						[60.52511673980797, 2.6851890958859683],
						[60.525172236697507, 2.6851779953407751],
						[60.525233334793832, 2.6851668963038295],
						[60.52529723296044, 2.6851557962691897],
						[60.525350030121047, 2.6851473979922416],
						[60.525405630296298, 2.6851390986663595],
						[60.525405660378837, 2.685139087303448],
						[60.525405715824149, 2.6851390600179106],
						[60.525405741015007, 2.6851390404739659],
						[60.52575294085073, 2.6847918414843064],
						[60.526102941315166, 2.6844418411311568],
						[60.526102952400009, 2.6844418279990077],
						[60.526102971806644, 2.6844418004474511],
						[60.526102980128414, 2.6844417860280312],
						[60.526380780241141, 2.6838611853028551],
						[60.526380784358992, 2.6838611771876533],
						[60.526380790638022, 2.6838611576848388],
						[60.52638079377752, 2.683861147933424],
						[60.52683349357978, 2.6820056468150937],
						[60.526833495998808, 2.6820056408593476],
						[60.526833497901734, 2.6820056240392924],
						[60.526833499342423, 2.6820056164473485],
						[60.526939099292015, 2.6807306163665605],
						[60.526939100012292, 2.6807306125705619],
						[60.526939099582144, 2.6807306035169738],
						[60.52693910030245, 2.6807305997209716],
						[60.526933499635938, 2.678997199656024],
						[60.526933500184064, 2.6789971922385578],
						[60.526933498689317, 2.6789971797382068],
						[60.526933498431156, 2.678997174306065],
						[60.526705798085437, 2.6772610738957776],
						[60.526705798085437, 2.6772610738957776],
						[60.526705797999362, 2.6772610720850891],
						[60.526477998011892, 2.675641671192714],
						[60.526477995796697, 2.6756416624887596],
						[60.526477992258634, 2.6756416449061482],
						[60.526477990043446, 2.6756416362021938],
						[60.525933489727983, 2.6740193369614951],
						[60.525933459739903, 2.674019293491523],
						[60.525933382691925, 2.6740192263423186],
						[60.525933335632025, 2.6740192026630818],
						[60.525872235666327, 2.6740081030685636],
						[60.525872233881628, 2.6740081034180636],
						[60.525872231118477, 2.6740081021316628],
						[60.525872230226121, 2.6740081023064159],
						[60.525816730420104, 2.6739998022823275],
						[60.525816724087541, 2.6739998016949498],
						[60.525816714185609, 2.6739998018065649],
						[60.525816708659285, 2.6739997992337856],
						[60.52575561437007, 2.6739969990641383],
						[60.52570001996547, 2.673991500869072],
						[60.525700017288408, 2.673991501393322],
						[60.525700012740558, 2.6739915004564412],
						[60.525700010955859, 2.6739915008059403],
						[60.525647210766508, 2.6739886992210211],
						[60.525647205412426, 2.6739887002695135],
						[60.525647194618131, 2.6739887005558605],
						[60.525647189177931, 2.6739886997937341],
						[60.525594388820934, 2.6739914994837122],
						[60.525594387928599, 2.6739914996584573],
						[60.525594384359202, 2.6739915003574497],
						[60.525594382574518, 2.6739915007069439],
						[60.525530598277456, 2.6739969996023856],
						[60.525486113056381, 2.673994199805851],
						[60.525486104046784, 2.6739941997427126],
						[60.525486087898422, 2.6739942010775368],
						[60.525486079781174, 2.673994200839652],
						[60.525430580042638, 2.6739998006564543],
						[60.525430579150274, 2.6739998008312025],
						[60.525377784104592, 2.6740054009360459],
						[60.525324989803181, 2.6740081006786913],
						[60.525324984449099, 2.6740081017271646],
						[60.525324971870106, 2.6740081023630067],
						[60.525324965623689, 2.6740081035862251],
						[60.525261065814149, 2.6740192024132656],
						[60.5252610649218, 2.6740192025880094],
						[60.525197165471518, 2.674030403630558],
						[60.525197161009793, 2.6740304045042773],
						[60.525197152978663, 2.6740304060769757],
						[60.52519714940928, 2.6740304067759508],
						[60.525144348963025, 2.6740442058019078],
						[60.525144347178326, 2.6740442061513958],
						[60.525144343695061, 2.6740442086609608],
						[60.525144341910362, 2.6740442090104457],
						[60.525080552414181, 2.6740637051629159],
						[60.525036069970199, 2.6740720028481837],
						[60.524963877260831, 2.6740804016019939],
						[60.52496387458379, 2.6740804021262141],
						[60.524963871014386, 2.6740804028251826],
						[60.524963869143569, 2.6740804013640833],
						[60.524911069223364, 2.6740887012988668],
						[60.524911066632448, 2.674088703633664],
						[60.524911063869268, 2.6740887023473112],
						[60.524911062170702, 2.6740887045073682],
						[60.524838862677043, 2.6741026032719297],
						[60.52479436854162, 2.674110901282281],
						[60.524730578746222, 2.6741192004129175],
						[60.524694409038311, 2.6741219996127641],
						[60.524677832653651, 2.6741192022566618],
						[60.524677831761288, 2.6741192024314038],
						[60.524677828998122, 2.6741192011450541],
						[60.524677827213424, 2.6741192014945292],
						[60.524597227052453, 2.6741081011657468],
						[60.524597225267755, 2.6741081015152206],
						[60.524552825106667, 2.6741026022510899],
						[60.524552819666482, 2.6741026014889568],
						[60.524552812355452, 2.6741025992657419],
						[60.52455280789372, 2.6741026001394301],
						[60.524480650932517, 2.6740998023349611],
						[60.524436218146455, 2.6740776361177083],
						[60.524364041259176, 2.6740054590056235],
						[60.524364021658677, 2.6740054445696391],
						[60.524363980845202, 2.6740054196682608],
						[60.524363958739904, 2.6740054093776049],
						[60.524283359319071, 2.6739804081412148],
						[60.524283352986508, 2.6739804075538496],
						[60.524283341127635, 2.6739804043938102],
						[60.524283334795101, 2.6739804038064414],
						[60.52423614513522, 2.6739720044925739],
						[60.524166754318706, 2.6739526060813725],
						[60.524166748878493, 2.673952605319271],
						[60.524166739782778, 2.6739526034455738],
						[60.524166735234928, 2.6739526025087326],
						[60.524119451994821, 2.6739442051333238],
						[60.524066787886852, 2.6739248189065159],
						[60.524030704880104, 2.6739026298843993],
						[60.524030703009295, 2.6739026284233502],
						[60.524030700246129, 2.6739026271370383],
						[60.524030698375299, 2.6739026256759932],
						[60.523986205707196, 2.6738776301268272],
						[60.523905713556076, 2.6738220343979617],
						[60.523833414346434, 2.6737720362896695],
						[60.523833414260295, 2.673772034479156],
						[60.523833413367953, 2.6737720346538949],
						[60.523789012825212, 2.6737415355764034],
						[60.523789011846745, 2.6737415339406154],
						[60.523789009975921, 2.6737415324795863],
						[60.523789009083586, 2.6737415326543275],
						[60.523733408928038, 2.6737054312063009],
						[60.523733408035682, 2.6737054313810469],
						[60.523733406164865, 2.6737054299200209],
						[60.52373340527253, 2.6737054300947607],
						[60.523697305209197, 2.6736831297347989],
						[60.523697299682851, 2.673683127162243],
						[60.523697288544071, 2.6736831202065998],
						[60.523697283910074, 2.6736831174592992],
						[60.523661183959973, 2.6736665185340436],
						[60.523661179326005, 2.6736665157867456],
						[60.523661170144152, 2.6736665121026433],
						[60.523661164703938, 2.6736665113405973],
						[60.523580679978949, 2.6736387167972846],
						[60.523527893206598, 2.6736109220523221],
						[60.52352788865872, 2.6736109211155461],
						[60.523527882153928, 2.6736109169072546],
						[60.523527877606064, 2.6736109159704773],
						[60.523455684605338, 2.6735804197839395],
						[60.523391792768926, 2.6735470226426403],
						[60.523391789113425, 2.6735470215311268],
						[60.523391780823921, 2.6735470176723375],
						[60.523391778060741, 2.6735470163860837],
						[60.523338978196058, 2.673524816208479],
						[60.523338972669734, 2.6735248136359724],
						[60.523338964466355, 2.6735248115876855],
						[60.523338958940016, 2.6735248090151784],
						[60.523258359453884, 2.6734998096162768],
						[60.523258357669192, 2.6734998099657648],
						[60.523258356690732, 2.673499808330027],
						[60.523230659282753, 2.6734915088037883],
						[60.52318616027506, 2.6734776094367638],
						[60.523186156619538, 2.6734776083252663],
						[60.523186151179353, 2.6734776075632531],
						[60.523186149308522, 2.6734776061022503],
						[60.52313064874415, 2.6734637067262677],
						[60.523130647851801, 2.6734637069010123],
						[60.523097253727407, 2.6734554064929044],
						[60.523025058902284, 2.6734331087987586],
						[60.52302505435442, 2.6734331078620168],
						[60.523025044280232, 2.6734331043527861],
						[60.523025038840032, 2.6734331035907855],
						[60.522969450015395, 2.6734220064871148],
						[60.522908361359036, 2.6734026085559903],
						[60.522908351370972, 2.6734026068572598],
						[60.522908329610154, 2.6734026038092726],
						[60.522908319535951, 2.6734026003000806],
						[60.522852823863921, 2.6733970017428228],
						[60.52279172732014, 2.6733887028117715],
						[60.522791722772276, 2.6733887018750209],
						[60.522791713762686, 2.6733887018120126],
						[60.522791710107171, 2.6733887007005359],
						[60.522736109771266, 2.6733859002018772],
						[60.522683311363174, 2.6733830992141185],
						[60.522683304224408, 2.6733831006120714],
						[60.522683290666961, 2.673383099612332],
						[60.522683285312866, 2.6733831006607987],
						[60.522611084651551, 2.6733887003045944],
						[60.522611082866852, 2.6733887006540824],
						[60.522611081082182, 2.6733887010035664],
						[60.522611079297484, 2.6733887013530513],
						[60.522558278560624, 2.6733942004704812],
						[60.522558274098891, 2.6733942013441965],
						[60.522558263390742, 2.673394203441112],
						[60.522558257144311, 2.6733942046643131],
						[60.522494357346773, 2.6734081046848681],
						[60.522494348423301, 2.6734081064322903],
						[60.522494328963944, 2.6734081138974974],
						[60.522494320126611, 2.6734081174553577],
						[60.522449920287364, 2.6734276168239219],
						[60.522449907966774, 2.6734276228911726],
						[60.522449885196409, 2.6734276364866507],
						[60.522449873854285, 2.673427644189621],
						[60.522377673792043, 2.6734859447781458],
						[60.522377662536023, 2.6734859542914973],
						[60.52237764206712, 2.6734859784000435],
						[60.522377632681938, 2.6734859893743601],
						[60.522324832691979, 2.6735665901317387],
						[60.522324830993412, 2.6735665922916416],
						[60.522324827682418, 2.6735665984218966],
						[60.522324826876194, 2.6735666004070637],
						[60.522252634966293, 2.6736915841466629],
						[60.522224846193815, 2.6737248716529916],
						[60.522224838593317, 2.6737248822777211],
						[60.522224825263116, 2.6737249049881497],
						[60.522224817662625, 2.6737249156128828],
						[60.522208124063731, 2.6737610044038975],
						[60.522127630760352, 2.6738887931003452],
						[60.522127629061799, 2.6738887952602175],
						[60.522127625750748, 2.6738888013903908],
						[60.522127624944517, 2.673888803375557],
						[60.522083124936216, 2.6739693034174556],
						[60.522083124129971, 2.6739693054026019],
						[60.522083120732844, 2.6739693097223478],
						[60.52208311992662, 2.6739693117074887],
						[60.522019223918434, 2.6740999052373842],
						[60.521966528025168, 2.6741887977409373],
						[60.521894229316999, 2.6743081952328378],
						[60.521894226812179, 2.6743081993777977],
						[60.521894224307381, 2.6743082035227723],
						[60.521894222694918, 2.6743082074930187],
						[60.521841527686504, 2.6744081979482077],
						[60.521769239820884, 2.6745165793855574],
						[60.521697052313627, 2.6746026645168293],
						[60.521672062449248, 2.6746276550545378],
						[60.521644266360269, 2.6746526504243038],
						[60.521644263769325, 2.6746526527588057],
						[60.521644260372163, 2.6746526570784068],
						[60.521644258673582, 2.6746526592382045],
						[60.521563778699068, 2.6747331385359416],
						[60.521491620749295, 2.6747720152791334],
						[60.521430539428508, 2.6747915088661238],
						[60.52143053585911, 2.6747915095649097],
						[60.521430529698769, 2.6747915125981714],
						[60.521430527021735, 2.6747915131222602],
						[60.521366627389241, 2.6748165138523978],
						[60.521366622927495, 2.6748165147258751],
						[60.521366614982469, 2.6748165181085146],
						[60.521366610606826, 2.674816520792374],
						[60.521322113838288, 2.6748387187806006],
						[60.521266618199341, 2.674863718599636],
						[60.521266611952896, 2.6748637198224903],
						[60.521266601416919, 2.674863725539562],
						[60.521266595342681, 2.6748637303831715],
						[60.521230503375847, 2.6748859255297246],
						[60.521197111151302, 2.674902620204656],
						[60.521197104990961, 2.6749026232378696],
						[60.521197093648738, 2.6749026309399846],
						[60.521197089273109, 2.6749026336238302],
						[60.521105489343149, 2.6749637339887884],
						[60.521105472012806, 2.6749637483448021],
						[60.52110544117982, 2.6749637817891951],
						[60.521105426698746, 2.6749637992418767],
						[60.521035926815706, 2.6750831991756545],
						[60.521035925203215, 2.6750832031457557],
						[60.521035921892114, 2.6750832092756114],
						[60.521035920279637, 2.6750832132457112],
						[60.520988719623269, 2.6751805124488914],
						[60.520988719709372, 2.675180514259254],
						[60.520927621891417, 2.6753082085954869],
						[60.520874824251507, 2.675405504553023],
						[60.520874821746652, 2.6754055086977653],
						[60.520874816823067, 2.6754055187976165],
						[60.520874815296665, 2.6754055245780393],
						[60.520819217219348, 2.6755416191973769],
						[60.520766519073483, 2.6756527141059383],
						[60.520766516568621, 2.6756527182506478],
						[60.520766513429706, 2.6756527280010944],
						[60.520766511903282, 2.6756527337815079],
						[60.520722017179835, 2.6757777173031014],
						[60.520660925265943, 2.6758888044139848],
						[60.520660924459683, 2.6758888063989934],
						[60.520660921062458, 2.6758888107183507],
						[60.520660920256198, 2.6758888127033553],
						[60.520605424157871, 2.6760027051872726],
						[60.520544227670705, 2.6761054981668395],
						[60.520544226864452, 2.6761055001518326],
						[60.520544225251911, 2.6761055041218262],
						[60.520544224445651, 2.6761055061068215],
						[60.520480426437885, 2.6762249017373501],
						[60.520427631318803, 2.6763109935609966],
						[60.520347033107868, 2.6764331902805383],
						[60.520347032301608, 2.6764331922655114],
						[60.520347028904368, 2.676433196584747],
						[60.520347027291827, 2.6764332005547167],
						[60.520327626591239, 2.6764665996533923],
						[60.520327625784965, 2.6764666016383636],
						[60.520327623366185, 2.676466607593297],
						[60.520327620775191, 2.6764666099275605],
						[60.520310926736848, 2.6764998995167852],
						[60.520230432539783, 2.6766220895421537],
						[60.520230431733488, 2.6766220915271206],
						[60.520230429228612, 2.6766220956716968],
						[60.520230428422352, 2.6766220976566584],
						[60.52017762960967, 2.6767109953620634],
						[60.520105431406932, 2.6768248934712822],
						[60.52010542962222, 2.6768248938205437],
						[60.520041530054229, 2.6769276935641027],
						[60.520041530140297, 2.6769276953744185],
						[60.520041529334023, 2.6769276973593636],
						[60.520041527549317, 2.676927697708618],
						[60.519980428043972, 2.6770304972300467],
						[60.519980427237684, 2.6770304992149865],
						[60.519916527689169, 2.6771415988839919],
						[60.51986372959766, 2.6772276954554877],
						[60.519863727899029, 2.6772276976150327],
						[60.519863725394124, 2.6772277017594996],
						[60.519863723781569, 2.6772277057293654],
						[60.519791528561591, 2.6773609960730917],
						[60.51975543462904, 2.677413786864248],
						[60.51975543131784, 2.6774137929936046],
						[60.519755423803069, 2.6774138054269456],
						[60.519755421298164, 2.6774138095713993],
						[60.519722027967646, 2.67748049528142],
						[60.51965813820356, 2.6775692836080838],
						[60.519658134806278, 2.6775692879271085],
						[60.519658128904076, 2.6775692963905646],
						[60.519658125592862, 2.6775693025199021],
						[60.51962203062633, 2.6776331929100663],
						[60.519541540593309, 2.6777470775936174],
						[60.51946926184457, 2.677833154021922],
						[60.519424879681353, 2.6778665406231625],
						[60.51942486413536, 2.6778665546276463],
						[60.519424836871011, 2.6778665873690897],
						[60.519424825066558, 2.6778666042957693],
						[60.519408143308084, 2.6778970704942684],
						[60.519319282199071, 2.6779720385856352],
						[60.519247110008386, 2.6780165206282498],
						[60.519191625566798, 2.6780387135677319],
						[60.519191624760495, 2.6780387155526149],
						[60.519191622975789, 2.678038715901796],
						[60.519191622083454, 2.6780387160763879],
						[60.519119321955579, 2.6780692161822239],
						[60.519119308742376, 2.6780692224216267],
						[60.519119284272826, 2.6780692381717661],
						[60.519119272124101, 2.6780692478570982],
						[60.519102718610085, 2.6780831075448983],
						[60.519074982457788, 2.6780859012616172],
						[60.519002784695445, 2.6780915014460422],
						[60.519002781932315, 2.6780915001595447],
						[60.519002778362896, 2.6780915008579012],
						[60.519002774793478, 2.678091501556255],
						[60.518958285316785, 2.6780969993083188],
						[60.518869409440072, 2.6780998001642429],
						[60.518780632007321, 2.6780887013240964],
						[60.518752839150743, 2.6780831033037185],
						[60.51875283638762, 2.6780831020172311],
						[60.518752828270358, 2.6780831017782654],
						[60.518752824700933, 2.6780831024766152],
						[60.518708326666214, 2.6780776027914701],
						[60.518652829676299, 2.6780692013177059],
						[60.518652827891593, 2.6780692016668799],
						[60.518652824236106, 2.6780692005549867],
						[60.518652820666681, 2.6780692012533338],
						[60.518627820698683, 2.6780665008937761],
						[60.518547225291911, 2.6780581023408465],
						[60.518511130297597, 2.6780526012234076],
						[60.518511124051116, 2.6780526024455105],
						[60.518511112192314, 2.6780525992844231],
						[60.518511105945819, 2.678052600506529],
						[60.518419405924327, 2.6780498003295299],
						[60.518419390668228, 2.6780498014872549],
						[60.518419361048394, 2.6780498036281157],
						[60.518419345878357, 2.6780498065960696],
						[60.51833054643572, 2.6780748077711478],
						[60.518330542866295, 2.6780748084694848],
						[60.518330539296876, 2.6780748091678217],
						[60.518330535727451, 2.6780748098661573],
						[60.518313848744477, 2.6780804059104288],
						[60.518286061059506, 2.6780859045180145],
						[60.518286055705374, 2.6780859055655135],
						[60.518286045889468, 2.6780859074859351],
						[60.518286040535337, 2.6780859085334345],
						[60.518205540555982, 2.678110908184606],
						[60.518205534395555, 2.6781109112168999],
						[60.518205521988655, 2.6781109154712666],
						[60.518205515828214, 2.6781109185035636],
						[60.518169321529101, 2.6781276154186369],
						[60.518097128013224, 2.6781554130714871],
						[60.518097127120875, 2.678155413246067],
						[60.518097123637482, 2.6781554157546061],
						[60.518097121852783, 2.6781554161037628],
						[60.518044332338881, 2.6781776123259475],
						[60.51797214622615, 2.6781998065741832],
						[60.517916658304863, 2.6782137040948615],
						[60.517855564060106, 2.6782248031143898],
						[60.517855563167771, 2.678224803288964],
						[60.517855558705996, 2.6782248041618448],
						[60.517855556921276, 2.6782248045109989],
						[60.517791665953567, 2.6782387033508703],
						[60.517747199748705, 2.6782441978632492],
						[60.517702833464057, 2.6782387033267896],
						[60.517650040786243, 2.6782276039964779],
						[60.517650037216825, 2.6782276046947797],
						[60.517650029905859, 2.6782276024710092],
						[60.517650026250386, 2.6782276013591204],
						[60.517586126224607, 2.6782192012161024],
						[60.517522252778612, 2.6782109055196712],
						[60.517469477767037, 2.6781887164899345],
						[60.517469473133147, 2.6781887137424545],
						[60.517469463951421, 2.6781887100576571],
						[60.517469459403586, 2.6781887091203549],
						[60.517397262206622, 2.6781665104770744],
						[60.5173250752201, 2.6781415146032224],
						[60.517288983890317, 2.6781248187352418],
						[60.517288982997968, 2.678124818909815],
						[60.517227883017249, 2.6780970183974877],
						[60.517191787320819, 2.6780804203073956],
						[60.517163988903221, 2.6780665216902859],
						[60.517163987924803, 2.6780665200547031],
						[60.517163987032454, 2.6780665202292777],
						[60.517083386980318, 2.6780276192427137],
						[60.517083382432482, 2.678027618305443],
						[60.517083372272332, 2.6780276129851504],
						[60.517083365853715, 2.6780276105868803],
						[60.517027866283343, 2.6780081109817728],
						[60.517027862627863, 2.6780081098699298],
						[60.517027856209268, 2.6780081074716611],
						[60.517027851661418, 2.6780081065343802],
						[60.516966751924159, 2.6779915076486684],
						[60.516966751031823, 2.6779915078232417],
						[60.516966749161043, 2.6779915063622477],
						[60.516911149231063, 2.677977606121678],
						[60.516911147446365, 2.6779776064708298],
						[60.516911144683242, 2.6779776051844149],
						[60.516911143790878, 2.6779776053589917],
						[60.516850043557774, 2.6779637051251273],
						[60.516850042665425, 2.6779637052997058],
						[60.516786143173661, 2.6779498046531462],
						[60.516786140410538, 2.6779498033667393],
						[60.516786134077989, 2.6779498027786364],
						[60.516786131314866, 2.6779498014922094],
						[60.51673333117553, 2.6779415014117256],
						[60.516733327606111, 2.6779415021100315],
						[60.516733321273563, 2.6779415015219321],
						[60.516733316725727, 2.6779415005846783],
						[60.516669423693912, 2.6779359005103416],
						[60.51661674807611, 2.6779276053413636],
						[60.516533363355897, 2.6778998099005484],
						[60.516500062617467, 2.6778887109593263],
						[60.516500056198865, 2.6778887085610981],
						[60.516500041662994, 2.6778887059239533],
						[60.516500034352042, 2.6778887037003019],
						[60.516436145601524, 2.6778776055881242],
						[60.516408373829748, 2.6778692123156693],
						[60.51638069964217, 2.6778554268910755],
						[60.516300109924259, 2.6778026330946774],
						[60.516300108053478, 2.6778026316337207],
						[60.516300106182705, 2.6778026301727662],
						[60.516300105290341, 2.6778026303473443],
						[60.516227917035494, 2.6777581372263639],
						[60.516175129287312, 2.6777137475076094],
						[60.516175127416545, 2.6777137460466625],
						[60.516175123674991, 2.6777137431247717],
						[60.516175121804231, 2.6777137416638062],
						[60.516102922373165, 2.6776581414605527],
						[60.516102917739261, 2.6776581387132556],
						[60.51610290927777, 2.6776581312339611],
						[60.516102904729934, 2.6776581302967606],
						[60.516066804704344, 2.6776359308209781],
						[60.516066801855153, 2.6776359277245265],
						[60.516066797221235, 2.6776359249772175],
						[60.516066795350476, 2.6776359235162821],
						[60.51603071087883, 2.6776165326978729],
						[60.515958425617718, 2.677558143560808],
						[60.515958420983814, 2.6775581408135336],
						[60.515958409845233, 2.6775581338580441],
						[60.515958405125261, 2.6775581293006647],
						[60.51588621235441, 2.6775137330234879],
						[60.515833419205272, 2.6774748387480174],
						[60.515833414657436, 2.6774748378108471],
						[60.515833408066705, 2.6774748317925785],
						[60.515833405303567, 2.6774748305062444],
						[60.515761208768204, 2.67743043164911],
						[60.515725114449069, 2.6774054363327142],
						[60.515725104202836, 2.6774054292027385],
						[60.51572508477485, 2.6774054183883722],
						[60.51572507470074, 2.6774054148785513],
						[60.51569727476673, 2.6773942142133249],
						[60.515697272982003, 2.6773942145624954],
						[60.515697270132826, 2.6773942114660954],
						[60.515697268348113, 2.6773942118152636],
						[60.515627867588769, 2.6773692125601829],
						[60.51562786669642, 2.677369212734769],
						[60.515627865717974, 2.6773692110992862],
						[60.515572275360043, 2.6773498133115101],
						[60.515511182566328, 2.6773220177708064],
						[60.515511178910828, 2.6773220166590859],
						[60.515511169729102, 2.6773220129747202],
						[60.51551116607363, 2.6773220118629939],
						[60.515447266288113, 2.6772998115012565],
						[60.515447264417347, 2.6772998100403642],
						[60.515447259869511, 2.677299809103229],
						[60.515447258084777, 2.677299809452403],
						[60.51538335906578, 2.6772804096570915],
						[60.515322260814621, 2.6772609095619431],
						[60.515322258051484, 2.677260908275628],
						[60.515322251718949, 2.6772609076876686],
						[60.515322248955798, 2.6772609064013735],
						[60.51526674888553, 2.6772470066743259],
						[60.515266747993167, 2.677247006848912],
						[60.515266747014749, 2.6772470052134456],
						[60.515186147177879, 2.6772276065732523],
						[60.515186146199461, 2.6772276049377894],
						[60.515186145307098, 2.6772276051123765],
						[60.515150052100715, 2.6772192074678811],
						[60.515105659232113, 2.6772054092627235],
						[60.51506115975981, 2.6771915095470704],
						[60.515061158867461, 2.6771915097216574],
						[60.515025058742438, 2.6771804097133716],
						[60.515025054108513, 2.6771804069662295],
						[60.515025043228128, 2.6771804054411605],
						[60.515025037787929, 2.6771804046786358],
						[60.514952837706083, 2.677166504189977],
						[60.514952836813748, 2.6771665043645609],
						[60.514952836727659, 2.677166502554531],
						[60.514908336738038, 2.6771581037597403],
						[60.514836137547235, 2.6771442031804256],
						[60.514836134870166, 2.6771442037041822],
						[60.514836127559199, 2.6771442014807914],
						[60.514836124882137, 2.6771442020045444],
						[60.514791724868743, 2.6771387012060983],
						[60.514791722191681, 2.6771387017298514],
						[60.514791717643831, 2.6771387007927543],
						[60.514791714966776, 2.6771387013165073],
						[60.514719415141798, 2.6771331016687343],
						[60.514719412378653, 2.6771331003824672],
						[60.514719404261406, 2.6771331001437062],
						[60.514719399713549, 2.6771330992066122],
						[60.514675003733579, 2.677133100145169],
						[60.514611107991051, 2.6771303996954878],
						[60.514611106206353, 2.6771304000446574],
						[60.51461110174457, 2.6771304009175769],
						[60.514611099873811, 2.6771303994567259],
						[60.514538899718126, 2.6771303997303657],
						[60.514538895256365, 2.6771304006032803],
						[60.514538883569685, 2.6771304010628514],
						[60.514538879021835, 2.6771304001257605],
						[60.514486084755298, 2.6771359005967548],
						[60.514422191174241, 2.6771387003422209],
						[60.514422175918149, 2.6771387015001138],
						[60.514422145578131, 2.6771387074359052],
						[60.514422131386532, 2.677138712039222],
						[60.514369330711062, 2.6771581123341659],
						[60.514369321067299, 2.6771581178745598],
						[60.514369299909028, 2.6771581274945246],
						[60.514369290265257, 2.6771581330349212],
						[60.514297090432223, 2.6772054336408235],
						[60.514297086862811, 2.6772054343391392],
						[60.514297079089829, 2.6772054413403423],
						[60.51429707471415, 2.6772054440232331],
						[60.514269276058954, 2.6772276437477669],
						[60.514244276694733, 2.6772470414718188],
						[60.514244275888451, 2.6772470434563895],
						[60.514152676418099, 2.6773192424476471],
						[60.514152672934742, 2.6773192449559349],
						[60.51415266686039, 2.6773192497979399],
						[60.514152665247828, 2.6773192537670853],
						[60.514083171793473, 2.6773831452109982],
						[60.514038780354895, 2.6774165391876381],
						[60.514038770883268, 2.6774165483478751],
						[60.51403875355259, 2.6774165626992201],
						[60.514038745865669, 2.6774165715102964],
						[60.514010958360124, 2.677449859307961],
						[60.513938777939245, 2.6775109418635994],
						[60.51387488729241, 2.6775554353678279],
						[60.513805488148662, 2.6776026357883707],
						[60.513805487170252, 2.6776026341529628],
						[60.513724886733833, 2.677658134399389],
						[60.513724882358126, 2.677658137082171],
						[60.513724873692752, 2.6776581442577148],
						[60.513724870295476, 2.6776581485758948],
						[60.513705479336878, 2.6776748390757135],
						[60.513688789054413, 2.6776859336998644],
						[60.513688788248118, 2.6776859356843925],
						[60.513688783786343, 2.6776859365571988],
						[60.513688782980061, 2.6776859385417247],
						[60.513608186656555, 2.67774423437971],
						[60.513535990231013, 2.677791533584609],
						[60.513535988446293, 2.6777915339337275],
						[60.513535983264291, 2.6777915386009856],
						[60.513535979694858, 2.6777915392992222],
						[60.513480492822907, 2.6778331309108303],
						[60.513399914665662, 2.6778748187526187],
						[60.513366623402916, 2.6778887147473647],
						[60.51336661643618, 2.6778887197637107],
						[60.513366602330613, 2.677888726176469],
						[60.513366595277844, 2.6778887293828442],
						[60.513330496120844, 2.6779109292874921],
						[60.513274922304312, 2.6779442146048669],
						[60.513222156745705, 2.6779581036416218],
						[60.513158266078776, 2.6779692036006195],
						[60.513158264207995, 2.6779692021397885],
						[60.513158259832281, 2.6779692048224724],
						[60.5131582579615, 2.6779692033616396],
						[60.513105558179348, 2.6779804032121199],
						[60.513105555588353, 2.6779804055456977],
						[60.513105551126557, 2.6779804064184498],
						[60.513105547557146, 2.677980407116646],
						[60.513033247621358, 2.6779998067730242],
						[60.513033245836631, 2.6779998071221218],
						[60.513033242353288, 2.6779998096302569],
						[60.51303323959015, 2.6779998083439596],
						[60.512980541033279, 2.6780165082286911],
						[60.512924944816056, 2.6780331080755504],
						[60.51285276289893, 2.6780526031335281],
						[60.512799979356281, 2.6780581013606559],
						[60.512747184397654, 2.6780637004418],
						[60.512691691299558, 2.6780665007500897],
						[60.512630591295206, 2.6780691994048058],
						[60.51263059040285, 2.6780691995793489],
						[60.512630588618137, 2.6780691999284394],
						[60.512630587725788, 2.6780692001029811],
						[60.512583294191344, 2.6780720004345202],
						[60.512513899586445, 2.6780719994067135],
						[60.512477799574334, 2.6780720001593425],
						[60.512433300001419, 2.6780719997827456],
						[60.512433298216699, 2.6780720001318308],
						[60.51243329366887, 2.6780719991946285],
						[60.51243329188415, 2.6780719995437168],
						[60.512361091629579, 2.6780747996257808],
						[60.51236108538307, 2.6780748008475777],
						[60.512361070126978, 2.6780748020048946],
						[60.512361062095771, 2.6780748035757691],
						[60.51228886188494, 2.6780887046204702],
						[60.512288860014174, 2.6780887031596485],
						[60.512288853767672, 2.6780887043814388],
						[60.512288852069027, 2.6780887065404215],
						[60.512244356914131, 2.6780998044120849],
						[60.51217216294507, 2.6781137041131386],
						[60.512127762575872, 2.6781220031025361],
						[60.512127750168951, 2.6781220073559568],
						[60.512127723570387, 2.6781220162118928],
						[60.512127711163465, 2.6781220204653153],
						[60.512083217741107, 2.6781442169782577],
						[60.512027726739738, 2.6781665148879266],
						[60.511991628226362, 2.6781804127848075],
						[60.511991627420066, 2.6781804147692134],
						[60.511991623850619, 2.6781804154673634],
						[60.511991622065914, 2.678180415816434],
						[60.511919322197627, 2.6782109168746793],
						[60.511866624814779, 2.6782331156810932],
						[60.511802726672968, 2.6782581134158563],
						[60.511802703009643, 2.678258127177557],
						[60.511802660402893, 2.6782581592578842],
						[60.511802639760802, 2.6782581797354554],
						[60.511294239861002, 2.6789331798350964],
						[60.511294239054664, 2.6789331818194455],
						[60.511294237356005, 2.6789331839782986],
						[60.511294235657338, 2.6789331861371566],
						[60.510666535732938, 2.6798331857968312],
						[60.510666525712857, 2.679833202369069],
						[60.510666511198977, 2.6798332380862337],
						[60.510666504920408, 2.6798332575801518],
						[60.510472009794746, 2.6807277337551167],
						[60.509883136103248, 2.681927679772576],
						[60.509255473676774, 2.6825304445820168],
						[60.508366611633654, 2.6831359180568599],
						[60.507049968656155, 2.6835470003831881],
						[60.504894398338685, 2.6835664994139341],
						[60.504894381383735, 2.6835665027263511],
						[60.504894348280203, 2.683566507367356],
						[60.504894332303593, 2.683566512314929],
						[60.503763832015991, 2.6839748121779845],
						[60.503763805674815, 2.6839748264547216],
						[60.503763758690567, 2.6839748612000132],
						[60.503763738939845, 2.6839748814942674],
						[60.503469238620276, 2.6843776825657737],
						[60.503469231825179, 2.6843776911979025],
						[60.503469221084039, 2.6843777115580831],
						[60.503469216159644, 2.6843777216510207],
						[60.503174866893929, 2.6850748004866705],
						[60.502591698386695, 2.6850804006397717],
						[60.501361108913351, 2.6850915005611133],
						[60.500427863847669, 2.6849998055700666],
						[60.499600126764442, 2.6845109433140681],
						[60.498608453011649, 2.6834304706694403],
						[60.497664057824139, 2.6822165776865661],
						[60.497664035117573, 2.6822165547339822],
						[60.49766398218906, 2.6822165212522648],
						[60.49766395268751, 2.6822165069306356],
						[60.497583355400891, 2.682194207479828],
						[60.497555656780598, 2.6821859078619195],
						[60.497555628687344, 2.6821859042205336],
						[60.497555571060062, 2.6821859045227505],
						[60.49755554331076, 2.6821859081177046],
						[60.497527743808746, 2.6821942072086813],
						[60.497458246065968, 2.6822137066828677],
						[60.497458243388884, 2.6822137072058321],
						[60.497458237228301, 2.6822137102351831],
						[60.49745823365884, 2.6822137109324697],
						[60.497402733589958, 2.6822331106363011],
						[60.497402723053554, 2.6822331163463198],
						[60.497402701002379, 2.6822331261315715],
						[60.497402689659587, 2.6822331338249823],
						[60.497330490018328, 2.6822804321007321],
						[60.497330484836098, 2.682280436764787],
						[60.497330475278041, 2.6822804441095252],
						[60.497330470095818, 2.6822804487735787],
						[60.497249870407366, 2.682349848349185],
						[60.497249868622625, 2.682349848697819],
						[60.497249867816244, 2.6823498506812005],
						[60.49724986692388, 2.682349850855517],
						[60.497224875760267, 2.6823720418674855],
						[60.497188785828058, 2.6823970362853684],
						[60.4971165857663, 2.6824470361928463],
						[60.49711658487395, 2.6824470363671606],
						[60.497116583175178, 2.6824470385248476],
						[60.497116581390458, 2.6824470388734722],
						[60.497063780612308, 2.6824859384392106],
						[60.497063780698298, 2.6824859402482635],
						[60.497063778913557, 2.6824859405968855],
						[60.497063778107183, 2.6824859425802554],
						[60.496991577575933, 2.682541542043646],
						[60.496991576683541, 2.6825415422179582],
						[60.496991575791185, 2.6825415423922689],
						[60.496910981020001, 2.6826054382877667],
						[60.496883185703155, 2.6826248352933955],
						[60.496883177843834, 2.6826248404802442],
						[60.496883164888253, 2.6826248521400871],
						[60.496883158007279, 2.6826248589616761],
						[60.496866570389841, 2.6826415463622793],
						[60.496785987342491, 2.6826998341255397],
						[60.496713790977722, 2.682747032937125],
						[60.496649891234256, 2.6827887329347799],
						[60.496649885073673, 2.6827887359639195],
						[60.496649873730846, 2.6827887436569227],
						[60.496649868548602, 2.6827887483208102],
						[60.496588769580477, 2.6828415480305696],
						[60.496552672757105, 2.6828720457530024],
						[60.496524880611702, 2.6828942388290367],
						[60.49643598675857, 2.6829554357227967],
						[60.496435980598001, 2.6829554387518915],
						[60.496435970233513, 2.6829554480795124],
						[60.496435964158884, 2.682955452917636],
						[60.496363767400339, 2.683022049919717],
						[60.49632767508556, 2.6830526430939967],
						[60.496283180304012, 2.6830859399202054],
						[60.496283176820533, 2.6830859424263753],
						[60.496283170745905, 2.6830859472644475],
						[60.496283167262419, 2.6830859497706188],
						[60.496210966591384, 2.68314985018799],
						[60.496210964086238, 2.6831498543288714],
						[60.496210956312865, 2.6831498613245026],
						[60.496210952915341, 2.6831498656396717],
						[60.496149861073071, 2.6832165568239739],
						[60.496094271273954, 2.6832637472301464],
						[60.496094261801815, 2.6832637563832944],
						[60.496094244642279, 2.6832637743410226],
						[60.49609423784721, 2.6832637829713053],
						[60.496058151760863, 2.683313762490962],
						[60.495988770623569, 2.6833720465078694],
						[60.495988766333689, 2.6833720509972787],
						[60.495988756861522, 2.6833720601503481],
						[60.49598875167927, 2.6833720648140393],
						[60.495933159612541, 2.6834331566803238],
						[60.495872069101907, 2.6834859485767675],
						[60.495872065618407, 2.6834859510828761],
						[60.495872061328498, 2.6834859555722401],
						[60.495872057930967, 2.6834859598873271],
						[60.495816558771814, 2.6835415594828076],
						[60.495763758785898, 2.683594258240563],
						[60.495763758785898, 2.683594258240563],
						[60.495710959128665, 2.6836470589969745],
						[60.495710958236288, 2.6836470591712467],
						[60.495710955645158, 2.6836470615030401],
						[60.495710954752795, 2.6836470616773092],
						[60.495655457018124, 2.6837054593212271],
						[60.4955942620374, 2.6837665541103632],
						[60.495538765824136, 2.6838165506794662],
						[60.495538762426584, 2.6838165549944737],
						[60.495538753760805, 2.6838165621640595],
						[60.49553875125563, 2.6838165663047988],
						[60.495494252100073, 2.683866565920062],
						[60.495433156361599, 2.6839331617827211],
						[60.495369259196181, 2.6839970582425101],
						[60.495369258303811, 2.6839970584167685],
						[60.495316557571989, 2.6840498585735486],
						[60.495316557657944, 2.6840498603825118],
						[60.495316555873217, 2.6840498607310219],
						[60.495316555066822, 2.6840498627142395],
						[60.495260955255667, 2.6841081619714942],
						[60.495260952664523, 2.6841081643032187],
						[60.495260948460555, 2.6841081706013865],
						[60.495260945869418, 2.684108172933108],
						[60.495208149086004, 2.6841720682655361],
						[60.495155453931083, 2.6842304644833299],
						[60.495091556127576, 2.6842970601433356],
						[60.495019255634524, 2.6843720605989216],
						[60.495019252236958, 2.6843720649137963],
						[60.495019244549482, 2.6843720737178085],
						[60.495019241151908, 2.6843720780326867],
						[60.494983146581397, 2.6844192701091534],
						[60.494938753726458, 2.6844665633774731],
						[60.494938752834095, 2.6844665635517142],
						[60.494938752027672, 2.6844665655348989],
						[60.494938751135315, 2.6844665657091387],
						[60.49486655421012, 2.684547064340705],
						[60.494785955897456, 2.6846304609051574],
						[60.494785951607511, 2.6846304653942288],
						[60.494785943919972, 2.6846304741981037],
						[60.494785939716017, 2.6846304804961045],
						[60.494760941070659, 2.6846637788399117],
						[60.49473315481719, 2.6846998615638085],
						[60.494644271572035, 2.6847748463332284],
						[60.494580477150549, 2.6848276427827513],
						[60.494527683767316, 2.6848665372568643],
						[60.494463785690428, 2.6849109352432294],
						[60.494463782292847, 2.6849109395579731],
						[60.494463774433413, 2.6849109447438653],
						[60.494463770949871, 2.6849109472496937],
						[60.494427699931542, 2.6849415212101357],
						[60.494394339002952, 2.6849526097682199],
						[60.494322142283401, 2.6849748095851336],
						[60.494266648698684, 2.6849915062850873],
						[60.494194386336055, 2.6850080974368251],
						[60.494133327841155, 2.6850026011816204],
						[60.494088936669009, 2.68499420325226],
						[60.494088933991911, 2.6849942037749255],
						[60.494088925788688, 2.6849942017251269],
						[60.494088923025629, 2.6849942004388958],
						[60.494016723603295, 2.6849859006918884],
						[60.493972225245315, 2.6849804028194661],
						[60.493972215257344, 2.6849804011181231],
						[60.493972195367348, 2.6849803995243073],
						[60.493972184658901, 2.6849804016149639],
						[60.493936093265589, 2.6849830991434485],
						[60.493855604177561, 2.6849830998037132],
						[60.493791708188567, 2.6849803995013404],
						[60.493791700071299, 2.6849803992604397],
						[60.49379168392268, 2.6849804005875102],
						[60.493791674998988, 2.6849804023297099],
						[60.493747175028595, 2.6849859025800669],
						[60.493747151826966, 2.6849859071097786],
						[60.493747106659875, 2.6849859232305184],
						[60.493747085672737, 2.684985936456195],
						[60.493710990147235, 2.6850109333477907],
						[60.493630494384128, 2.685060930747766],
						[60.49363049170703, 2.6850609312704146],
						[60.493630488223481, 2.6850609337761542],
						[60.493630485546383, 2.685060934298805],
						[60.493549885817231, 2.685116534567717],
						[60.493549878936101, 2.6851165413880556],
						[60.49354986500196, 2.6851165514109598],
						[60.493549859099147, 2.6851165598659525],
						[60.49353045856973, 2.6851359584889485],
						[60.493530456870936, 2.6851359606462442],
						[60.493530455172149, 2.6851359628035381],
						[60.493530454365711, 2.6851359647866193],
						[60.493494253814724, 2.6851748640418709],
						[60.493494247825971, 2.6851748706879537],
						[60.493494236826763, 2.6851748856147899],
						[60.493494231730395, 2.6851748920866747],
						[60.493424835790385, 2.6852831852082453],
						[60.493352640583048, 2.6853776784449872],
						[60.493352638077845, 2.6853776825853179],
						[60.493352632981434, 2.6853776890571432],
						[60.493352631368573, 2.6853776930232724],
						[60.493299835341539, 2.6854609867525463],
						[60.493208140280352, 2.6855831796062768],
						[60.493208135183949, 2.6855831860780417],
						[60.493208125162994, 2.6855832026392541],
						[60.49320812104488, 2.6855832107456723],
						[60.493191527632852, 2.6856165965396208],
						[60.493163736633271, 2.6856554834860771],
						[60.493163736719211, 2.6856554852949261],
						[60.493163735020417, 2.6856554874521685],
						[60.493163734128053, 2.6856554876263625],
						[60.493083133700409, 2.6857748874750373],
						[60.493083132087548, 2.6857748914411088],
						[60.493083127883494, 2.6857748977386269],
						[60.493083126270619, 2.6857749017047006],
						[60.493038729440542, 2.6858526969740311],
						[60.492966530712344, 2.6859665928488665],
						[60.492966529905893, 2.6859665948318945],
						[60.492966527486587, 2.6859666007809864],
						[60.492966525787772, 2.6859666029381981],
						[60.492905426332975, 2.6860749014702163],
						[60.492905423827729, 2.6860749056104352],
						[60.492905418903149, 2.6860749156997317],
						[60.49290541729026, 2.6860749196657872],
						[60.492852616784504, 2.6861943187498838],
						[60.492852615257561, 2.6861943245247586],
						[60.492852610418893, 2.6861943364228544],
						[60.492852607999573, 2.6861943423718895],
						[60.492816508928335, 2.6863166407908126],
						[60.492763712569385, 2.686483226479635],
						[60.492727620548386, 2.6865610092082681],
						[60.492647024822269, 2.6867082042597605],
						[60.49264702312346, 2.6867082064169097],
						[60.492647019005275, 2.6867082145230272],
						[60.492647017392379, 2.6867082184890183],
						[60.492610919319461, 2.6867888150799262],
						[60.492538719982178, 2.6869388138051806],
						[60.492502623918227, 2.6870138048982213],
						[60.492422029021149, 2.6871498961661864],
						[60.492369230170333, 2.687235994471719],
						[60.492369226772666, 2.6872359987859271],
						[60.492369222654482, 2.6872360068919199],
						[60.49236922104155, 2.6872360108578395],
						[60.492305424259577, 2.6873638043550421],
						[60.492252628376313, 2.6874526981666227],
						[60.492252626677477, 2.6874527003237105],
						[60.492252624172181, 2.6874527044637557],
						[60.492252623365715, 2.687452706446702],
						[60.492191526859862, 2.6875692998103862],
						[60.492127630623592, 2.687669291512468],
						[60.492127630709504, 2.6876692933212678],
						[60.492127629903045, 2.6876692953041981],
						[60.492127629010668, 2.6876692954783317],
						[60.492066531333059, 2.6877692938220914],
						[60.491994231828926, 2.6878804903414304],
						[60.491994231022439, 2.6878804923243513],
						[60.491994227710663, 2.6878804984472331],
						[60.491994226011833, 2.6878805006042943],
						[60.491949828031494, 2.6879581990447274],
						[60.491885932674521, 2.6880637897761193],
						[60.491849837683269, 2.6881137823547441],
						[60.491849835177945, 2.6881137864946743],
						[60.491849831059675, 2.6881137946004277],
						[60.491849827576075, 2.6881137971056908],
						[60.491824829927502, 2.6881554940888122],
						[60.491744232266804, 2.6882804915892393],
						[60.491744231460338, 2.6882804935721301],
						[60.491744229675575, 2.6882804939203608],
						[60.491688729560579, 2.688369293316788],
						[60.491688728754106, 2.6883692952996752],
						[60.491688726334679, 2.6883693012483367],
						[60.491688724635836, 2.6883693034053531],
						[60.491627626959868, 2.6884805004242227],
						[60.491563729452224, 2.688585996291196],
						[60.491563726861017, 2.6885859986222793],
						[60.491563725333954, 2.6885860043968108],
						[60.491563722742718, 2.6885860067278982],
						[60.491510923043336, 2.6886860053958248],
						[60.491447023978147, 2.6888055061619269],
						[60.491447022279289, 2.6888055083188869],
						[60.49144701977395, 2.6888055124587069],
						[60.491447018967477, 2.6888055144415692],
						[60.491402621751021, 2.6888999098937236],
						[60.491333127153013, 2.6890248990867809],
						[60.491277629125065, 2.6891165962973305],
						[60.491277624114367, 2.6891166045768804],
						[60.491277615963597, 2.6891166225965963],
						[60.49127761273764, 2.6891166305279901],
						[60.491224815339486, 2.6892582229938236],
						[60.491172019299064, 2.6893721162846624],
						[60.491172015901341, 2.6893721205984806],
						[60.491172011954767, 2.6893721323214193],
						[60.491172010341771, 2.6893721362870853],
						[60.491127611099046, 2.6895055323795951],
						[60.491072012696691, 2.6896527283258251],
						[60.491072012782567, 2.6896527301345734],
						[60.49107201197608, 2.6896527321173891],
						[60.491072011083688, 2.6896527322914632],
						[60.491038711320314, 2.6897471330159934],
						[60.491038711406205, 2.689747134824735],
						[60.490991515209821, 2.6898832221414515],
						[60.490958120776241, 2.6899499097508239],
						[60.490958118356737, 2.6899499156992359],
						[60.49095811262535, 2.6899499277701557],
						[60.490958111098209, 2.6899499335445043],
						[60.490938711315302, 2.6900055341980624],
						[60.490938711315302, 2.6900055341980624],
						[60.490938709616429, 2.6900055363549265],
						[60.490938709702299, 2.6900055381636654],
						[60.490885913132644, 2.6901666290742896],
						[60.490833118381275, 2.6902888161311211],
						[60.490777622217735, 2.6904027070913652],
						[60.490724826050588, 2.6904999027685896],
						[60.49065262680088, 2.6906249001395821],
						[60.49065262510198, 2.6906249022964137],
						[60.490652622682468, 2.6906249082447142],
						[60.490652620983568, 2.6906249104015263],
						[60.490608126131399, 2.6907138014273189],
						[60.490527636967876, 2.6908415828617946],
						[60.490502645182723, 2.6908720736414233],
						[60.490502641784921, 2.6908720779550181],
						[60.490502635075181, 2.6908720883908903],
						[60.490502630785009, 2.6908720928785179],
						[60.49048313093045, 2.6909026936366125],
						[60.490483130123941, 2.6909026956193789],
						[60.49048312672614, 2.6909026999329484],
						[60.490483125027254, 2.6909027020897325],
						[60.490410927961186, 2.6910331972294639],
						[60.490358131035805, 2.6911165921838696],
						[60.490358130229282, 2.6911165941666257],
						[60.490358130229282, 2.6911165941666257],
						[60.490285930006273, 2.6912331953646658],
						[60.490285929113909, 2.6912331955386857],
						[60.490285928393234, 2.6912331993301239],
						[60.490285926608472, 2.6912331996781718],
						[60.49022482690647, 2.6913388004820389],
						[60.490224826014078, 2.6913388006560615],
						[60.490224825207555, 2.6913388026387857],
						[60.490224825293417, 2.6913388044474877],
						[60.490160945252349, 2.6914554670544115],
						[60.490088778371266, 2.6915109407623632],
						[60.490088770597403, 2.691510947754586],
						[60.490088757640954, 2.6915109594083062],
						[60.490088750759455, 2.6915109662265331],
						[60.490063750942163, 2.6915387668989137],
						[60.490063744146511, 2.6915387755258249],
						[60.490063730555264, 2.6915387927796246],
						[60.490063724737851, 2.6915388030412193],
						[60.490035928133793, 2.691588796917725],
						[60.489947059107401, 2.6917276488610948],
						[60.489866634390161, 2.6917720085021619],
						[60.489830568923999, 2.6917776024691773],
						[60.489830523927608, 2.6917776221955747],
						[60.48983044656601, 2.6917776810905347],
						[60.489830416071442, 2.6917777217197854],
						[60.489822016144096, 2.6917971210193028],
						[60.489822012917976, 2.6917971289500739],
						[60.48982200655157, 2.6917971466202952],
						[60.48982200528193, 2.6917971578204214],
						[60.489810904975094, 2.6918471575427794],
					],
					[
						[60.507947052090934, 2.6968849956258283],
						[60.508032831774415, 2.6969748000654055],
						[60.50802508270619, 2.6969748004496457],
						[60.508022347448858, 2.6969720644103545],
						[60.507947052090934, 2.6968849956258283],
					],
				],
				[
					[
						[60.41421649961832, 2.8336139026473335],
						[60.414274799640033, 2.8387611017672154],
						[60.414274799807636, 2.8387611053772188],
						[60.414274800142827, 2.8387611125971968],
						[60.414274801203071, 2.8387611160377531],
						[60.414905397817776, 2.8464110828282863],
						[60.414460906061869, 2.8481749511731644],
						[60.414460903718592, 2.848174958900874],
						[60.414460901709958, 2.8481749738487707],
						[60.414460901151983, 2.8481749812381016],
						[60.414241500763801, 2.8506277820001147],
						[60.414241500707519, 2.8506278002195931],
						[60.414241505225519, 2.8506278394230113],
						[60.414241508823515, 2.8506278587710425],
						[60.415144204424124, 2.8536167434518265],
						[60.415610901632711, 2.8566417293008821],
						[60.415610909134777, 2.8566417551951075],
						[60.415610929244252, 2.8566418005543519],
						[60.415610942018802, 2.8566418236296545],
						[60.416705421244345, 2.8580444962176088],
						[60.417047009692254, 2.8590639640751574],
						[60.417047021573708, 2.8590639873213357],
						[60.417047048740315, 2.8590640295276089],
						[60.417047065810799, 2.859064048149885],
						[60.418744240039338, 2.8605973244612564],
						[60.419816524244929, 2.8625972957468009],
						[60.419816530018402, 2.8625973037607446],
						[60.419816542625021, 2.8625973232305602],
						[60.419816550267349, 2.8625973327122263],
						[60.421002649918663, 2.8639418325180657],
						[60.421002663083947, 2.8639418445984717],
						[60.421002689247587, 2.8639418651483943],
						[60.421002704114755, 2.8639418750857462],
						[60.421674904386037, 2.864311274739697],
						[60.421674916408215, 2.864311281572816],
						[60.421674941178388, 2.8643112914592641],
						[60.421674953926335, 2.864311294512587],
						[60.423013853705278, 2.8646251943478807],
						[60.423013872702015, 2.864625196219857],
						[60.423013909719387, 2.8646251983270199],
						[60.42301392863267, 2.8646251983934126],
						[60.424266687880014, 2.8644419033701616],
						[60.427724928220726, 2.8653668886254495],
						[60.428827667262404, 2.8659334577237434],
						[60.43051652770631, 2.8682057032324719],
						[60.43154979987154, 2.8703750439167517],
						[60.431524799926109, 2.8730999978373672],
						[60.431524830290961, 2.8731000704396776],
						[60.431524931310534, 2.873100171597609],
						[60.431525003750657, 2.8731001998159837],
						[60.43290544286068, 2.8730752027024562],
						[60.433294205642682, 2.8746861477781014],
						[60.433294211915488, 2.8746861666344539],
						[60.433294228841241, 2.8746862016980401],
						[60.433294238601498, 2.8746862180738582],
						[60.435074839170468, 2.8771195181557809],
						[60.435074845919161, 2.8771195278137016],
						[60.435074862904095, 2.8771195446491156],
						[60.435074872330915, 2.8771195538014207],
						[60.436719251893592, 2.8784973363647381],
						[60.438885936565633, 2.8815473155151157],
						[60.438885953633303, 2.8815473341612377],
						[60.43888599198246, 2.881547365191766],
						[60.438886013347187, 2.8815473793826576],
						[60.440988812990049, 2.882569580611225],
						[60.440988834914499, 2.8825695874087085],
						[60.440988880382399, 2.8825695970535654],
						[60.440988903033116, 2.882569600069373],
						[60.441858243665521, 2.8825558004135008],
						[60.442308100213474, 2.8828362111505461],
						[60.442308099846969, 2.883197057896981],
						[60.442102733649207, 2.8832692102342419],
						[60.442102692690277, 2.8832692398301165],
						[60.442102629292407, 2.8832693101070745],
						[60.442102606043989, 2.8832693527632589],
						[60.441994205976997, 2.8837193536513013],
						[60.441994203404668, 2.8837193760051538],
						[60.441994202890015, 2.8837194234842247],
						[60.441994204864478, 2.8837194468027412],
						[60.442174804806321, 2.8844889457623668],
						[60.44217483310792, 2.8844889932735112],
						[60.442174910470634, 2.8844890698010137],
						[60.442174959365389, 2.8844890952039721],
						[60.445711009690427, 2.8852279855131915],
						[60.447885971338764, 2.8870612522707582],
						[60.447886000094286, 2.8870612705431582],
						[60.447886060594001, 2.8870612937653988],
						[60.447886094206801, 2.8870613001855374],
						[60.448844335104944, 2.887089098678981],
						[60.450091587362799, 2.8879445646877238],
						[60.450091625045438, 2.8879445812786071],
						[60.450091704935389, 2.8879445953788312],
						[60.450091746333179, 2.8879445948636553],
						[60.451316745690121, 2.8876557949318595],
						[60.45131676605633, 2.8876557874451501],
						[60.451316803944295, 2.8876557693623921],
						[60.451316823251467, 2.8876557584296263],
						[60.451988918820398, 2.8871335387972237],
						[60.453255503343321, 2.8878334756296571],
						[60.453255529004792, 2.8878334853739771],
						[60.453255580887792, 2.8878334974649209],
						[60.453255608002046, 2.8878334996431985],
						[60.453777808391791, 2.8878112991008926],
						[60.453777850266462, 2.8878112893800583],
						[60.453777924173764, 2.8878112517412062],
						[60.453777957099135, 2.8878112236548157],
						[60.454350157166594, 2.8870807235620344],
						[60.454350165594718, 2.8870807110337733],
						[60.454350179689662, 2.8870806846750505],
						[60.454350187225074, 2.8870806723151841],
						[60.455139070000655, 2.8850334146454957],
						[60.455705659069423, 2.8845058192316495],
						[60.457930554847444, 2.8850168958044895],
						[60.457930581878522, 2.8850168961729112],
						[60.457930634964718, 2.8850168952706436],
						[60.457930660853428, 2.8850168903848123],
						[60.459519388216265, 2.8845058132901085],
						[60.46018051927291, 2.8848001830870946],
						[60.460180540221387, 2.8848001882501153],
						[60.460180583903778, 2.8848001982391982],
						[60.460180605578529, 2.884800199618347],
						[60.462319275766355, 2.8847308032279346],
						[60.463769219352457, 2.8877778857648084],
						[60.463769310248985, 2.8877779433818738],
						[60.463769490289792, 2.8877779422360002],
						[60.463769581385847, 2.887777886751993],
						[60.464758467708407, 2.8856890122412033],
						[60.466449833181777, 2.8838560762763912],
						[60.466799800079627, 2.8888528142784957],
						[60.466799802280882, 2.8888528229820429],
						[60.466799804005198, 2.8888528408944381],
						[60.466799806206446, 2.8888528495979866],
						[60.46699150573977, 2.8896083483997215],
						[60.466991507857806, 2.8896083552952834],
						[60.466991512010758, 2.8896083672782917],
						[60.466991514128814, 2.8896083741738519],
						[60.467480414094759, 2.8908333739784329],
						[60.467480425888191, 2.8908333954634835],
						[60.467480455640995, 2.8908334354467939],
						[60.467480472790754, 2.8908334559215496],
						[60.467822027575821, 2.8911140180055623],
						[60.468022010128152, 2.8917250614070622],
						[60.468022015173631, 2.8917250732223669],
						[60.468022025264617, 2.8917250968529964],
						[60.468022031119702, 2.8917251066917813],
						[60.47000263112529, 2.8948473064073288],
						[60.470002700738632, 2.8948473516505775],
						[60.470002851258002, 2.894847374346329],
						[60.470002931188247, 2.8948473501588428],
						[60.472194531104961, 2.8929584513823543],
						[60.472194537104698, 2.8929584447786638],
						[60.472194550889654, 2.8929584312345793],
						[60.472194556889413, 2.8929584246309021],
						[60.473083388091403, 2.8918335111245286],
						[60.474897176823383, 2.8920418991940742],
						[60.474897212698593, 2.8920418960751428],
						[60.474897281272092, 2.8920418794912348],
						[60.474897313887254, 2.8920418642177039],
						[60.476233414395821, 2.8911140647199245],
						[60.47623342826369, 2.8911140529818895],
						[60.476233452345376, 2.8911140283709496],
						[60.476233464344681, 2.8911140151611701],
						[60.477114048002591, 2.8898640377846321],
						[60.477741781558528, 2.8893529909139555],
						[60.478477782527413, 2.8892585032337355],
						[60.480613841941206, 2.8899084916776228],
						[60.480613865567726, 2.8899084963409427],
						[60.48061391427354, 2.889908498095171],
						[60.480613939352828, 2.8899084951860954],
						[60.483650039313027, 2.8893112953551596],
						[60.483650083366733, 2.8893112742652529],
						[60.483650157085393, 2.8893112129051906],
						[60.483650185857591, 2.8893111728035907],
						[60.484180764110022, 2.8879584303468082],
						[60.48573071181179, 2.8869057659201496],
						[60.485730721382026, 2.8869057586379818],
						[60.485730736951645, 2.8869057447481308],
						[60.485730744736458, 2.8869057378032106],
						[60.486547323615753, 2.8860473599071805],
						[60.489061147982483, 2.8846585018191462],
						[60.489602792947032, 2.8846780001278725],
						[60.489602828655435, 2.8846779933797335],
						[60.4896028969779, 2.8846779713428812],
						[60.489602928532797, 2.8846779526042003],
						[60.491311209828602, 2.8832362697101739],
						[60.492205687408301, 2.8828057799312918],
						[60.492205702334516, 2.8828057716337185],
						[60.492205730235007, 2.8828057517571919],
						[60.492205743126021, 2.8828057383687455],
						[60.493502903835505, 2.8814723793355417],
						[60.495461121167878, 2.8809808006827669],
						[60.496636092356994, 2.8810251990695344],
						[60.496636120197351, 2.8810251974545467],
						[60.49663617359294, 2.8810251837039909],
						[60.496636200124136, 2.881025173209272],
						[60.497997225167516, 2.8802419174243625],
						[60.498852741652485, 2.880502991232269],
						[60.498852804641373, 2.8805029902695836],
						[60.498852917990853, 2.8805029487445011],
						[60.498852968351429, 2.8805029081821112],
						[60.500283331040492, 2.8782975198652139],
						[60.501594255621747, 2.87966123933586],
						[60.501594293638775, 2.8796612631797927],
						[60.50159437606461, 2.8796612932263854],
						[60.501594421366114, 2.8796612992600994],
						[60.502525021404026, 2.8795612993470057],
						[60.502525069193204, 2.8795612811744324],
						[60.502525147619885, 2.8795612243395414],
						[60.502525180935471, 2.8795611851704446],
						[60.502927938208693, 2.8787084760600949],
						[60.50453613972347, 2.8783834957767382],
						[60.504536163659616, 2.8783834875940153],
						[60.504536210305908, 2.8783834641569315],
						[60.504536231313956, 2.8783834510506945],
						[60.507825094981463, 2.8755307812207609],
						[60.509302848895672, 2.875161294084716],
						[60.50930286398804, 2.8751612893996086],
						[60.509302893113315, 2.8751612765776953],
						[60.509302908038926, 2.8751612682717713],
						[60.512350082845579, 2.8732112840636499],
						[60.513925054908178, 2.8727584913393733],
						[60.51392506829847, 2.8727584888013986],
						[60.513925092960008, 2.8727584768212329],
						[60.513925105290788, 2.8727584708311551],
						[60.518436182676567, 2.8699918842564043],
						[60.519905659187664, 2.869541891464539],
						[60.519905680361596, 2.8695418819677334],
						[60.519905719780994, 2.8695418580492054],
						[60.519905738919157, 2.8695418434581215],
						[60.520655710584549, 2.8688195706439448],
						[60.522255574927797, 2.8681724262084938],
						[60.522530486720029, 2.8683612656155124],
						[60.52253053073737, 2.8683612828410023],
						[60.522530623935474, 2.8683612925617878],
						[60.522530671414373, 2.8683612872069526],
						[60.523349983096324, 2.8680502204858902],
						[60.524938800609846, 2.8689529743714965],
						[60.52493881433395, 2.8689529790758352],
						[60.524938840080274, 2.8689529906345221],
						[60.524938853804343, 2.8689529953388653],
						[60.526502753920802, 2.869325194120337],
						[60.526502774786309, 2.8693251974699909],
						[60.52650281715961, 2.8693251985658739],
						[60.526502837774693, 2.869325196481491],
						[60.528961069827602, 2.8688474104017998],
						[60.530641608430123, 2.8697223774968341],
						[60.53064164399585, 2.8697223871945416],
						[60.530641718280698, 2.8697223968544643],
						[60.530641755856685, 2.8697223915513654],
						[60.531502856365506, 2.8694723911333928],
						[60.531502875028274, 2.8694723857641513],
						[60.531502910151097, 2.869472366306399],
						[60.531502926778089, 2.8694723558411135],
						[60.531689027260377, 2.8693195550855251],
						[60.531689050277443, 2.8693195269600915],
						[60.531689085265569, 2.8693194654956646],
						[60.531689097236594, 2.8693194321566802],
						[60.532541894805441, 2.8640194478785781],
						[60.533136249603118, 2.8622334820455588],
						[60.537044282202274, 2.8617335163378037],
						[60.537902618648033, 2.8635361856439028],
						[60.537902634100583, 2.8635362082974822],
						[60.537902670278172, 2.8635362507749509],
						[60.537902690919601, 2.8635362687868926],
						[60.538605464872994, 2.8639945512295193],
						[60.539724844712694, 2.8653668254020772],
						[60.539724869175203, 2.8653668481740242],
						[60.539724922955052, 2.8653668818280633],
						[60.539724952355904, 2.8653668945222051],
						[60.541780551556563, 2.8658695936524263],
						[60.541780566982588, 2.8658695962061276],
						[60.541780594989532, 2.8658695981978783],
						[60.541780608546645, 2.8658695992785619],
						[60.544044409277575, 2.8657641004067655],
						[60.544044445793539, 2.8657640916409188],
						[60.544044513693812, 2.865764060458718],
						[60.544044545078116, 2.865764038042383],
						[60.544886207130752, 2.8648807769695592],
						[60.546461155013276, 2.8644279925132179],
						[60.546461172699537, 2.8644279854952828],
						[60.546461207012243, 2.864427968004208],
						[60.546461223638701, 2.864427957531047],
						[60.547869523803904, 2.8633223569519157],
						[60.547869536776027, 2.8633223453439465],
						[60.547869559791536, 2.8633223171995463],
						[60.547869570894761, 2.8633223041184444],
						[60.549300171472154, 2.8609668046906709],
						[60.549300174708371, 2.8609667967612444],
						[60.549300182966142, 2.8609667805628711],
						[60.549300186202359, 2.8609667726334438],
						[60.550491886245446, 2.8579028726024016],
						[60.550491887863473, 2.8579028686373964],
						[60.550491891099512, 2.8579028607073718],
						[60.550491891824869, 2.8579028569122276],
						[60.551608489142374, 2.854061166519017],
						[60.553205732787092, 2.8501946015497159],
						[60.555916698392309, 2.850227999173395],
						[60.555916737668596, 2.8502279916886182],
						[60.555916811896793, 2.8502279610832058],
						[60.555916845063386, 2.8502279383027718],
						[60.556766757234747, 2.8493308317718853],
						[60.558152689542304, 2.8498029737174142],
						[60.559055455950748, 2.8507418385752894],
						[60.559055489425972, 2.8507418614606905],
						[60.559055561062237, 2.8507418917060425],
						[60.559055600199692, 2.8507419007089529],
						[60.562083300220166, 2.8507363007825308],
						[60.562083329677385, 2.8507362951680011],
						[60.562083387364147, 2.8507362768559972],
						[60.562083414701064, 2.8507362643286558],
						[60.562877860093728, 2.8501808025819502],
						[60.565049992704324, 2.8502584991775941],
						[60.565050044393807, 2.8502584874942416],
						[60.565050133126626, 2.8502584394785928],
						[60.565050171146389, 2.8502584047895536],
						[60.566572359202056, 2.8477501232849263],
						[60.568214042419996, 2.8460945404492506],
						[60.568214049309596, 2.8460945336459913],
						[60.568214062837328, 2.8460945145987053],
						[60.568214069643112, 2.8460945059818497],
						[60.57022512362304, 2.8428834785500703],
						[60.572475039681514, 2.8424251967614489],
						[60.572475061829458, 2.8424251888728449],
						[60.572475103279686, 2.8424251699793044],
						[60.572475121605478, 2.8424251573309753],
						[60.574264004948027, 2.8410390713448663],
						[60.576602884278458, 2.8399501816561648],
						[60.576602913063695, 2.8399501615161213],
						[60.576602961288387, 2.8399501138711858],
						[60.576602980643919, 2.8399500845522203],
						[60.576955762761898, 2.8392029239161469],
						[60.578555674362583, 2.8378169000177618],
						[60.581030520378377, 2.8378334981692865],
						[60.582463763363712, 2.8391751465437758],
						[60.582463859827946, 2.8391751683766273],
						[60.582464025992707, 2.8391751201455553],
						[60.582464093740086, 2.8391750467940606],
						[60.583036287180811, 2.8368278774159021],
						[60.584380710588704, 2.834586304192412],
						[60.585194253006939, 2.8346140957603319],
						[60.585872010056683, 2.8367028621879409],
						[60.585872058686839, 2.8367029206213061],
						[60.585872185005599, 2.8367029843354872],
						[60.585872260992957, 2.8367029917722277],
						[60.586858361448442, 2.8363890917819776],
						[60.586858395588678, 2.8363890706076238],
						[60.586858456224412, 2.8363890187368863],
						[60.586858479958082, 2.8363889867380121],
						[60.58822794668739, 2.8335473569673306],
						[60.590216774769722, 2.8327473858087067],
						[60.590216793262435, 2.832747376776958],
						[60.590216828210586, 2.8327473536107415],
						[60.590216843773419, 2.8327473396471134],
						[60.591539044118008, 2.8313807389547554],
						[60.591539054409076, 2.8313807278299734],
						[60.591539072061266, 2.8313807006484675],
						[60.591539079590483, 2.8313806882214605],
						[60.592005756344413, 2.8304279362928018],
						[60.594000094893438, 2.8290779780824109],
						[60.595127874590432, 2.8286223843060534],
						[60.595127906135488, 2.8286223654455731],
						[60.595127957841768, 2.8286223152559464],
						[60.595127979956331, 2.8286222872149542],
						[60.595794565035177, 2.827241818342169],
						[60.596261039218255, 2.8267920374810496],
						[60.596927618861677, 2.82819448576016],
						[60.59692765781282, 2.8281945295731918],
						[60.596927753198329, 2.8281945863810467],
						[60.596927808740126, 2.8281945995468805],
						[60.598555609022391, 2.8281195997766844],
						[60.598555632229981, 2.8281195953303087],
						[60.598555676691817, 2.8281195831491108],
						[60.598555697861933, 2.828119573599043],
						[60.600961198404839, 2.8267668744453163],
						[60.600961220130976, 2.8267668574610334],
						[60.600961258059471, 2.8267668208881354],
						[60.600961274177685, 2.8267667994841532],
						[60.602577961171242, 2.8239168215225119],
						[60.604800141640816, 2.8216779405389047],
						[60.604800152739351, 2.8216779274194477],
						[60.604800172174471, 2.8216778998786793],
						[60.604800181403654, 2.8216778852861135],
						[60.605214070705436, 2.82080010788005],
						[60.607494555161466, 2.8179945267158639],
						[60.60749456188092, 2.8179945162664919],
						[60.607494573618951, 2.8179944975261577],
						[60.60749458033839, 2.8179944870767875],
						[60.608277968058722, 2.8163918111612718],
						[60.611319532822506, 2.8129529518893586],
						[60.612611210648083, 2.8120918655090845],
						[60.612611217620014, 2.8120918605046898],
						[60.61261123326468, 2.8120918483368404],
						[60.61261124023661, 2.81209184333246],
						[60.618130739703531, 2.8066918428711736],
						[60.618130744890109, 2.8066918382081947],
						[60.618130754286291, 2.8066918272377057],
						[60.618130759388436, 2.806691820758457],
						[60.620716798155186, 2.8033030015223042],
						[60.623761097865255, 2.8033308004914237],
						[60.623761146786876, 2.8033307874051392],
						[60.623761232013507, 2.803330741668149],
						[60.623761268234055, 2.8033307072008848],
						[60.624950109390205, 2.8014862993979635],
						[60.626755601097408, 2.8014807991151369],
						[60.626755654397037, 2.8014807833480995],
						[60.626755743663274, 2.8014807276581508],
						[60.626755781330466, 2.8014806855745906],
						[60.627191865836373, 2.8005501165058386],
						[60.628116783210928, 2.7996308000943206],
						[60.63328610109469, 2.7996030004401424],
						[60.633286141174807, 2.7996029908810289],
						[60.633286215303706, 2.7996029582583271],
						[60.633286248375413, 2.799602933549707],
						[60.636600136965036, 2.7959473462492919],
						[60.639519523878299, 2.7936529567157584],
						[60.639519531657321, 2.7936529497132288],
						[60.639519548023358, 2.7936529337184548],
						[60.639519555717825, 2.793652924898502],
						[60.640677955691253, 2.7922140246782985],
						[60.640677961600517, 2.7922140162022777],
						[60.640677974227003, 2.7922139972604207],
						[60.640677980051663, 2.7922139869669036],
						[60.641786265425218, 2.789927918771284],
						[60.64363341708912, 2.7880862662067027],
						[60.646461169830225, 2.7867418875817163],
						[60.649883352807585, 2.7858001939240049],
						[60.64988339059304, 2.7858001737788016],
						[60.649883454691349, 2.7858001191970936],
						[60.649883481004167, 2.7858000847606137],
						[60.651169572714323, 2.7830529033025613],
						[60.652177959830389, 2.7816973188061236],
						[60.652177973357588, 2.7816972226299437],
						[60.652177912008206, 2.7816970657258042],
						[60.652177837046892, 2.7816970031797461],
						[60.649788936913936, 2.7812442029042996],
						[60.649788896411721, 2.7812442034025628],
						[60.649788816255018, 2.7812442225786671],
						[60.649788779278062, 2.7812442407384892],
						[60.647316642325578, 2.78313309116343],
						[60.644950078258894, 2.7828748065471403],
						[60.644439242280164, 2.7824778127057153],
						[60.645564011022145, 2.7817251651980954],
						[60.645564043581558, 2.7817251295547947],
						[60.645564088386962, 2.7817250475242088],
						[60.645564099655665, 2.7817249994918303],
						[60.645558501140684, 2.7798724005142521],
						[60.648008252013902, 2.7798669007341412],
						[60.648936007751708, 2.7803445775895388],
						[60.64893603342032, 2.7803445872975372],
						[60.648936084333613, 2.780344597623992],
						[60.64893610966309, 2.7803446000603476],
						[60.649844409590507, 2.7803001996979941],
						[60.649844461017096, 2.7803001824098379],
						[60.64984454572555, 2.7803001256633659],
						[60.649844580792454, 2.7803000858596545],
						[60.650275180813601, 2.7793833841734115],
						[60.650275187998247, 2.7793833644391217],
						[60.650275197650558, 2.7793833203802385],
						[60.650275200203041, 2.7793832978736028],
						[60.650264099699925, 2.7784416975703348],
						[60.650264096812414, 2.7784416742821811],
						[60.65026408576707, 2.778441630560335],
						[60.650264077609293, 2.7784416101266523],
						[60.649366878214522, 2.7766666106603393],
						[60.64936684770317, 2.7766665743776122],
						[60.649366774948895, 2.776666520592487],
						[60.649366731813465, 2.7766665032628985],
						[60.646669475981902, 2.7762359099370943],
						[60.645791837361493, 2.775630450084678],
						[60.644314089449772, 2.7738221167852917],
						[60.64397240438727, 2.7716306090433696],
						[60.644436288218714, 2.7697806702832906],
						[60.644894563914995, 2.7688807174122774],
						[60.646205714125323, 2.7675445697646621],
						[60.647330674841818, 2.7670890852913779],
						[60.64733069502924, 2.7670890740403142],
						[60.647330731664219, 2.7670890485948219],
						[60.647330748919302, 2.7670890324095874],
						[60.649794549037978, 2.7643279324093695],
						[60.649794556730342, 2.7643279235795428],
						[60.649794572752576, 2.7643279002930647],
						[60.649794579467454, 2.7643278898184804],
						[60.650411249961635, 2.7630807482291497],
						[60.651588990270497, 2.7624862786145243],
						[60.651589004295126, 2.7624862703893518],
						[60.651589031281837, 2.7624862504761953],
						[60.651589044243934, 2.7624862387882247],
						[60.65248344413591, 2.7615640394496292],
						[60.652483457650227, 2.76156402031557],
						[60.652483481108945, 2.7615639827403369],
						[60.6524834899908, 2.7615639608363436],
						[60.653147376383856, 2.7594890071841296],
						[60.653864012725222, 2.7587751695388274],
						[60.65606947469054, 2.7578807853268872],
						[60.656069486122497, 2.7578807794367464],
						[60.656069506309009, 2.7578807681765785],
						[60.656069515956027, 2.7578807626331581],
						[60.656964016044753, 2.7572473638998276],
						[60.656964027306486, 2.7572473543727312],
						[60.656964047959875, 2.7572473338470536],
						[60.656964058244029, 2.7572473226750649],
						[60.65897514794738, 2.7546473347352438],
						[60.66144453607987, 2.7523529466555665],
						[60.661444548063315, 2.7523529333147696],
						[60.661444570074927, 2.7523529033432914],
						[60.661444580188288, 2.7523528885310982],
						[60.661905723186841, 2.7514058042334444],
						[60.663769393121392, 2.7514696000745413],
						[60.663769441314301, 2.7514695907006215],
						[60.663769524905526, 2.7514695487484748],
						[60.663769563066388, 2.7514695174681378],
						[60.664852962785709, 2.7499584174290335],
						[60.664852966907176, 2.7499584092860543],
						[60.664852976127769, 2.7499583946451147],
						[60.664852980249236, 2.7499583865021355],
						[60.665505770200404, 2.7486057088526747],
						[60.666594536198232, 2.7472723492697031],
						[60.667269512287561, 2.7468168661847039],
						[60.667269531324756, 2.7468168496305503],
						[60.667269564595863, 2.7468168101157104],
						[60.667269579807503, 2.7468167888000798],
						[60.667736255660408, 2.7458612362561512],
						[60.669064011394802, 2.7449751666742754],
						[60.669064027839653, 2.7449751524583976],
						[60.669064057881506, 2.7449751209102682],
						[60.669064070585989, 2.7449751037517998],
						[60.669922371066001, 2.743550103039583],
						[60.669922380200532, 2.7435500865754956],
						[60.669922392858986, 2.74355004923359],
						[60.669922396553545, 2.7435500319936685],
						[60.670339096631096, 2.7409639316112115],
						[60.670339089438173, 2.7409638742736502],
						[60.670339043265756, 2.7409637731358552],
						[60.670339004286241, 2.7409637293356215],
						[60.670078004384756, 2.7408054902018466],
						[60.670150199898977, 2.7388500076299582],
						[60.670150200193881, 2.7388499947232594],
						[60.670150197213914, 2.7388499696058424],
						[60.670150194746149, 2.7388499554021446],
						[60.669580795307667, 2.7362971556870166],
						[60.669580793181211, 2.7362971487594523],
						[60.669580786887217, 2.7362971297956928],
						[60.669580783782926, 2.7362971212232656],
						[60.668852984206609, 2.7345860226108267],
						[60.668852967668549, 2.7345859964685277],
						[60.668852930386521, 2.7345859505110179],
						[60.668852907943126, 2.7345859328628568],
						[60.666561259627834, 2.7331137646237056],
						[60.666352984188265, 2.7326221216770388],
						[60.666352967649864, 2.7326220955377263],
						[60.666352926712094, 2.7326220484637322],
						[60.666352903290601, 2.7326220291736392],
						[60.665647470179202, 2.7321971288264013],
						[60.66610847418962, 2.7313806975855979],
						[60.666108486673778, 2.731380656606226],
						[60.666108493622616, 2.7313805744933259],
						[60.666108488001839, 2.7313805315410966],
						[60.666017059161703, 2.7311282034139208],
						[60.666116611056871, 2.7311779792674686],
						[60.666116621129952, 2.7311779828077216],
						[60.666116643146424, 2.7311779913585736],
						[60.666116654111939, 2.7311779947246584],
						[60.666669353574441, 2.7313084945097734],
						[60.66666936632479, 2.7313084975276141],
						[60.666669389784268, 2.7313084984553955],
						[60.666669401556732, 2.7313084998286694],
						[60.668027801640015, 2.7312974005342086],
						[60.669113902142421, 2.7312890999058004],
						[60.669113933462796, 2.7312890956281071],
						[60.669113993720437, 2.7312890746889913],
						[60.669114021765289, 2.7312890582017575],
						[60.670202918872363, 2.7304501613191952],
						[60.671500115138009, 2.7295334628554886],
						[60.671500117729778, 2.7295334605137178],
						[60.671500123891263, 2.7295334574749468],
						[60.671500125590605, 2.7295334553074442],
						[60.672780717314204, 2.7284890623236908],
						[60.674541807369181, 2.7273668693030513],
						[60.674541808176087, 2.7273668673095521],
						[60.674541809068501, 2.7273668671352196],
						[60.676716760396602, 2.7259612990403919],
						[60.677669398208891, 2.7259529994312364],
						[60.678136094407435, 2.7259669005773057],
						[60.67813612037304, 2.7259668973390925],
						[60.678136171155202, 2.7259668855789845],
						[60.678136195886218, 2.7259668752377304],
						[60.679275096394008, 2.7253473764999936],
						[60.679275117213081, 2.7253473595778397],
						[60.679275156002873, 2.7253473226179445],
						[60.679275172995624, 2.7253473009352072],
						[60.679730772931229, 2.7245640016245516],
						[60.679730784227097, 2.7245639737100031],
						[60.679730796745631, 2.7245639143414713],
						[60.679730799031802, 2.724563886351953],
						[60.679652998821688, 2.7234777852539502],
						[60.679652996009899, 2.7234777637696372],
						[60.67965298324706, 2.723477722196836],
						[60.67965297508082, 2.7234777017593901],
						[60.679016874734216, 2.7223388018018149],
						[60.679016865125384, 2.7223387889916841],
						[60.679016845015269, 2.7223387635459053],
						[60.679016833621596, 2.7223387510847674],
						[60.677794534261146, 2.7212415503931462],
						[60.677794501913979, 2.7212415328503603],
						[60.677794433992418, 2.7212415057401995],
						[60.677794397696857, 2.7212414999860117],
						[60.676841698018748, 2.7212497996436986],
						[60.676841673031326, 2.7212498045302698],
						[60.676841624120122, 2.7212498177674336],
						[60.676841600196298, 2.7212498261180476],
						[60.674397145817501, 2.722658099915007],
						[60.673719437900935, 2.72266369958892],
						[60.673039053949914, 2.7223915460249959],
						[60.672439086212947, 2.7208943257553195],
						[60.672439085149321, 2.7208943222917776],
						[60.672439083193289, 2.7208943190027335],
						[60.67243908221527, 2.7208943173582125],
						[60.671941882596109, 2.71981101905831],
						[60.671480783608594, 2.7187277222930444],
						[60.671480779610789, 2.7187277138964423],
						[60.6714807717008, 2.7187276989221973],
						[60.671480766810603, 2.7187276907001614],
						[60.670444567413263, 2.717149890259563],
						[60.670444563586685, 2.7171498855012128],
						[60.670444555847858, 2.7171498741656404],
						[60.670444552021301, 2.7171498694073066],
						[60.669875151891617, 2.7164831704146035],
						[60.669875122856524, 2.7164831467254742],
						[60.669875058161168, 2.7164831116572055],
						[60.669875021608462, 2.7164831004526797],
						[60.669227822452335, 2.7164109018170355],
						[60.669227781144905, 2.7164109043921534],
						[60.669227703127667, 2.7164109306699222],
						[60.669227667138898, 2.7164109505603125],
						[60.668349882017594, 2.7171970360846505],
						[60.66713053231102, 2.7179081087148038],
						[60.66610282893879, 2.7180719925150796],
						[60.665108430757989, 2.7176137395938778],
						[60.664383472241759, 2.7166109962856662],
						[60.663925194799852, 2.7156832434662319],
						[60.663805805951689, 2.7145167475080316],
						[60.66453076987078, 2.7133445059370946],
						[60.664530781250185, 2.7133444798508126],
						[60.664530795891665, 2.7133444274315548],
						[60.664530799960474, 2.7133443991053725],
						[60.66452800019173, 2.7124888982075435],
						[60.664527998356448, 2.7124888783781915],
						[60.664527988181995, 2.712488834486579],
						[60.664527980992361, 2.7124888157052731],
						[60.663761280617159, 2.7108610152872386],
						[60.663761248132403, 2.7108609757650539],
						[60.663761168935316, 2.7108609196943472],
						[60.663761121244896, 2.7108609015020204],
						[60.663111138944224, 2.7107915032337511],
						[60.662083359206527, 2.7104915091166584],
						[60.661625121374563, 2.7103415292061368],
						[60.661089058260892, 2.7096470778209487],
						[60.661089058260892, 2.7096470778209487],
						[60.661089057282744, 2.7096470761773195],
						[60.661089057282744, 2.7096470761773195],
						[60.660475158321866, 2.7088692775239971],
						[60.659822363693564, 2.7080248851804072],
						[60.659139072207367, 2.7069442972066469],
						[60.658486274198111, 2.7057805025835484],
						[60.658486249365893, 2.7057804725833208],
						[60.658486188615271, 2.7057804257645803],
						[60.658486153589251, 2.7057804087712518],
						[60.657680654138147, 2.7055526074065117],
						[60.657680586780344, 2.7055526114271893],
						[60.657680471250998, 2.7055526634185796],
						[60.657680422101222, 2.7055527097459806],
						[60.656960940830551, 2.7069581714764639],
						[60.656388773232493, 2.7074304464230496],
						[60.656388745962786, 2.7074304792863342],
						[60.656388710043117, 2.7074305578804294],
						[60.656388700329209, 2.7074306001498445],
						[60.656391500389731, 2.708986100800046],
						[60.656391501075788, 2.7089861153446559],
						[60.656391505039316, 2.7089861420916739],
						[60.656391508402528, 2.7089861561121413],
						[60.657199807836932, 2.7116972570196847],
						[60.657199811920798, 2.7116972672299009],
						[60.657199821959125, 2.7116972891191624],
						[60.657199827021159, 2.7116973009728551],
						[60.658922016956218, 2.7146361821794232],
						[60.659688710181953, 2.7169611635919431],
						[60.659997005126229, 2.717894446766119],
						[60.660269201588733, 2.7196778297365762],
						[60.660269202737979, 2.7196778350171376],
						[60.660269204950872, 2.7196778437598947],
						[60.660269206014497, 2.7196778472221288],
						[60.660932885968172, 2.7223630587044805],
						[60.660597355695998, 2.721941574458508],
						[60.660597258618459, 2.7219415402109663],
						[60.660597084598265, 2.7219415742161641],
						[60.660597007741188, 2.7219416442872615],
						[60.660283363917912, 2.7230268563257547],
						[60.660105810678402, 2.7218111592943326],
						[60.66037235519866, 2.7214862276965039],
						[60.660372376261563, 2.7214861777035573],
						[60.660372391187103, 2.7214860738578506],
						[60.660372383179322, 2.7214860185355434],
						[60.660252983145845, 2.721216618613898],
						[60.660252940419561, 2.7212165719119108],
						[60.660252835896671, 2.7212165134309636],
						[60.660252774100073, 2.7212165016520005],
						[60.659805780123179, 2.7212747743382661],
						[60.659589098615037, 2.7189638811336687],
						[60.659589096059605, 2.718963865117956],
						[60.659589089249565, 2.7189638352537546],
						[60.659589084102514, 2.718963821579734],
						[60.658241899881574, 2.7158471583053583],
						[60.658241900176613, 2.7154694004737285],
						[60.658241899319897, 2.715469382291686],
						[60.658241893315839, 2.7154693504367562],
						[60.658241888975205, 2.715469334771103],
						[60.657122389377825, 2.7122555338550454],
						[60.657122387335896, 2.7122555287499068],
						[60.657122382359617, 2.7122555187142772],
						[60.657122380317702, 2.7122555136091369],
						[60.656083480163161, 2.7101027123881392],
						[60.656083400073548, 2.7101026565062276],
						[60.656083230881308, 2.710102640083849],
						[60.65608314088626, 2.7101026797180729],
						[60.655930440702086, 2.7103026794937359],
						[60.655930422698511, 2.7103027178796388],
						[60.65593040488212, 2.7103027984301704],
						[60.655930404176942, 2.7103028407694709],
						[60.656213685926033, 2.7116693547511024],
						[60.65546926397505, 2.7126970477757744],
						[60.654663846713014, 2.7131498006581238],
						[60.653530597849503, 2.7131609005612307],
						[60.653530554207755, 2.713160910933488],
						[60.653530475898478, 2.7131609501040557],
						[60.653530441145271, 2.7131609770844189],
						[60.652169257071279, 2.7149387588836191],
						[60.651274941096261, 2.715627592489827],
						[60.649944421411689, 2.7154859018007649],
						[60.649944390813047, 2.715485902280169],
						[60.649944332806946, 2.7154859136219125],
						[60.649944305399465, 2.7154859244842533],
						[60.648366650750681, 2.7163386986641322],
						[60.647791703119104, 2.7163303995099923],
						[60.647791630627225, 2.7163304283558056],
						[60.647791529984644, 2.7163305287416244],
						[60.647791500049124, 2.716330600630525],
						[60.647791500101164, 2.7165637020719364],
						[60.647344397852137, 2.7165692007067332],
						[60.647344328123069, 2.7165692308458178],
						[60.647344229179424, 2.7165693290629496],
						[60.647344200136175, 2.716569400776172],
						[60.647346998852619, 2.7174831191572331],
						[60.645549978816035, 2.7176748016518233],
						[60.645549914612317, 2.7176748343752402],
						[60.645549825655472, 2.7176749342998261],
						[60.645549800009896, 2.7176750016754045],
						[60.64555259864872, 2.7184137027572119],
						[60.64458889819872, 2.7184220008233622],
						[60.64458884312635, 2.7184220170865663],
						[60.644588750586024, 2.7184220791883842],
						[60.644588714010496, 2.7184221248525922],
						[60.642652621797396, 2.7232443053107818],
						[60.642016553872224, 2.7241776602998771],
						[60.640941621094427, 2.7249776099769285],
						[60.637377799740463, 2.7255191976177526],
						[60.632672228603958, 2.7248276029669407],
						[60.63267219711269, 2.7248276036103163],
						[60.632672134557765, 2.7248276139810366],
						[60.632672105278964, 2.724827623360162],
						[60.629983246378146, 2.7262859016311358],
						[60.625405629808917, 2.7264664993711092],
						[60.62446121486289, 2.7261331291335305],
						[60.62328344991041, 2.724802667402201],
						[60.623283443321384, 2.724802661355413],
						[60.623283430057846, 2.7248026474455682],
						[60.623283422576435, 2.7248026415728179],
						[60.621214023272437, 2.7231859419893816],
						[60.621213973713246, 2.7231859223308859],
						[60.621213872222739, 2.7231859091362338],
						[60.621213818592075, 2.7231859177643689],
						[60.618316638841328, 2.7244720077300646],
						[60.616969475591517, 2.72473588168527],
						[60.616763937682272, 2.7244955095888335],
						[60.617080753179252, 2.724014032308161],
						[60.617589029610812, 2.7235584528907353],
						[60.618152926398253, 2.7231029551325676],
						[60.618152933281017, 2.7231029482922176],
						[60.618152947046518, 2.7231029346115418],
						[60.618152953929261, 2.7231029277711971],
						[60.618641823852023, 2.7225168638562436],
						[60.619369482188837, 2.7221890818863574],
						[60.619369489242636, 2.7221890786776344],
						[60.619369500672974, 2.7221890727824452],
						[60.619369506834346, 2.7221890697478091],
						[60.619905640410167, 2.7218474101729031],
						[60.620283247644345, 2.7219195936729372],
						[60.620802732566943, 2.7221029882003758],
						[60.620802741747752, 2.72210299190776],
						[60.620802759045873, 2.7221029958643594],
						[60.620802768141154, 2.7221029977556257],
						[60.621449968248434, 2.7222084965223692],
						[60.621449969226404, 2.7222084981644228],
						[60.621449971011245, 2.7222084978162235],
						[60.621449972796071, 2.7222084974680261],
						[60.621833266287531, 2.7222612971913369],
						[60.62209715890306, 2.7223168965276776],
						[60.622097165235509, 2.7223168971251632],
						[60.622097177008023, 2.7223168984942201],
						[60.622097183340507, 2.7223168990916999],
						[60.622494383271373, 2.7223501982507639],
						[60.622494401290837, 2.7223501984011165],
						[60.622494437158608, 2.7223501950694038],
						[60.622494455006944, 2.722350191587334],
						[60.623038955108306, 2.7221945916944006],
						[60.623038956893126, 2.7221945913461782],
						[60.623038961355206, 2.7221945904756208],
						[60.62303896224762, 2.7221945903015095],
						[60.623444462109823, 2.7220612896270158],
						[60.623444473625696, 2.7220612855472113],
						[60.623444495679443, 2.7220612757454461],
						[60.623444507109753, 2.7220612698493776],
						[60.623897298854367, 2.7217751748833754],
						[60.624311191417021, 2.7215640782147141],
						[60.624311192223878, 2.7215640762242468],
						[60.624311194901111, 2.7215640757018327],
						[60.624311196685937, 2.7215640753535588],
						[60.624691796659086, 2.7213529751825041],
						[60.624691814079583, 2.7213529626178898],
						[60.624691846072167, 2.7213529343784191],
						[60.624691859837455, 2.7213529206940565],
						[60.624975151260841, 2.7209751316988844],
						[60.625244540063584, 2.7207112428607592],
						[60.625244551151546, 2.7207112296983835],
						[60.625244569757776, 2.7207112040703056],
						[60.625244578975327, 2.7207111894398914],
						[60.625511278745918, 2.7201806895080978],
						[60.625758479126759, 2.7196889896636218],
						[60.625758481547237, 2.7196889836918623],
						[60.625758487280606, 2.7196889715741213],
						[60.625758488808664, 2.7196889657765442],
						[60.626080788715541, 2.7187472649620976],
						[60.626080790414761, 2.7187472627972231],
						[60.626080791050377, 2.7187472571737885],
						[60.626080791857177, 2.7187472551831586],
						[60.626308490085741, 2.7179722631098837],
						[60.626502987840084, 2.7174444694953275],
						[60.626502990260462, 2.7174444635232469],
						[60.626502993230773, 2.7174444501112101],
						[60.626502994758724, 2.7174444443133985],
						[60.626630793739224, 2.716897250632055],
						[60.626750191748329, 2.716483354260506],
						[60.626750194254306, 2.7164833501047529],
						[60.626750196417731, 2.7164833386832634],
						[60.626750197138875, 2.7164833348761026],
						[60.626886296546061, 2.7156917337981792],
						[60.626886298159569, 2.7156917298166734],
						[60.626886298709422, 2.7156917223765897],
						[60.626886299430545, 2.7156917185693965],
						[60.626969599001669, 2.7147500187021278],
						[60.626969600443857, 2.714750011087669],
						[60.626969599758638, 2.7147499965561472],
						[60.626969600308406, 2.7147499891160347],
						[60.626930800039631, 2.7139999994545865],
						[60.626936300351993, 2.7138722083975777],
						[60.626936293112912, 2.7138721694822032],
						[60.626936266141158, 2.713872094092618],
						[60.626936243902584, 2.7138720617743872],
						[60.6267362437477, 2.7136637602625351],
						[60.626736211228611, 2.7136637391192444],
						[60.626736138587177, 2.7136637074841659],
						[60.626736100420942, 2.7136637002764581],
						[60.626513899810661, 2.7136636993306404],
						[60.62651385965259, 2.7136637071773779],
						[60.62651378733235, 2.7136637396400021],
						[60.626513754192104, 2.7136637626138507],
						[60.626263754275932, 2.7139304629965961],
						[60.626263749985249, 2.7139304675012181],
						[60.626263742296288, 2.7139304763360665],
						[60.626263738091275, 2.7139304826570827],
						[60.625988737607379, 2.7143081825012847],
						[60.625988735101487, 2.7143081866570991],
						[60.62598872928298, 2.7143081969594722],
						[60.625988724992283, 2.7143082014639872],
						[60.62570263429626, 2.7148192874270043],
						[60.625424858373712, 2.7151609573493589],
						[60.625138775039773, 2.7153887439112454],
						[60.625138768964248, 2.7153887487641182],
						[60.625138756898778, 2.7153887602862294],
						[60.625138750823247, 2.7153887651391195],
						[60.624799865311907, 2.7157665501918662],
						[60.624430491581869, 2.7160331325298701],
						[60.62406102957933, 2.7162442093870336],
						[60.623533301190825, 2.7163414967257999],
						[60.622802840640183, 2.7161970034491238],
						[60.62240564692835, 2.7161081060067014],
						[60.621777850602022, 2.7159442067568635],
						[60.621777833389409, 2.7159442046196296],
						[60.62177779896416, 2.7159442003451755],
						[60.621777781922816, 2.7159442018402564],
						[60.621324981903015, 2.7159859015054071],
						[60.621324977355371, 2.7159859005606335],
						[60.621324969323695, 2.7159859021290762],
						[60.621324964861678, 2.715985903000429],
						[60.621152764774521, 2.7160165025329612],
						[60.621152747904425, 2.7160165076602039],
						[60.621152714249881, 2.7160165197307653],
						[60.621152698443453, 2.7160165283159712],
						[60.620880503915863, 2.7161776242652702],
						[60.620502710144471, 2.7163692214140749],
						[60.620502709252058, 2.7163692215883311],
						[60.620502708445279, 2.7163692235786643],
						[60.620502706660481, 2.7163692239271735],
						[60.619958207017987, 2.7166554237726159],
						[60.619958200856743, 2.7166554268083694],
						[60.619958188619911, 2.7166554346959342],
						[60.619958183351073, 2.7166554375574439],
						[60.619588783388181, 2.7169220387703121],
						[60.619588778119336, 2.7169220416317432],
						[60.61958876766731, 2.7169220491706425],
						[60.619588763462119, 2.7169220554898987],
						[60.619027694116561, 2.7174526241035286],
						[60.618408235287625, 2.7176665117005112],
						[60.618408231718, 2.7176665123973223],
						[60.618408228148375, 2.7176665130941395],
						[60.618408224578708, 2.7176665137909586],
						[60.617688852321059, 2.7179554036586406],
						[60.617049981067439, 2.7180165006107164],
						[60.617049976605387, 2.7180165014816375],
						[60.617049967681275, 2.7180165032234855],
						[60.617049963133617, 2.7180165022785188],
						[60.616100018011515, 2.7181941934617782],
						[60.615802906153043, 2.7180831258182017],
						[60.615350135762782, 2.717674852299218],
						[60.615052952070506, 2.7173942672797029],
						[60.614672367564751, 2.7168526919416878],
						[60.61433077203953, 2.7162720983847244],
						[60.61433077203953, 2.7162720983847244],
						[60.614330771061468, 2.716272096743209],
						[60.614033470754002, 2.7157859965243425],
						[60.614033468797935, 2.7157859932413642],
						[60.614033466841867, 2.7157859899583663],
						[60.61403346586382, 2.7157859883168771],
						[60.61371956586914, 2.7153192885882826],
						[60.613719540318385, 2.7153192624257683],
						[60.613719481613352, 2.7153192207473551],
						[60.613719449351507, 2.7153192050572166],
						[60.613430648990793, 2.7152470053821407],
						[60.613430645335519, 2.7152470042634076],
						[60.613430639003049, 2.7152470036673733],
						[60.61343063534779, 2.7152470025486348],
						[60.612933335452354, 2.7151581033339438],
						[60.61293331377771, 2.7151581020685942],
						[60.612933273276859, 2.7151581026465208],
						[60.612933252751532, 2.7151581066539054],
						[60.612294352618719, 2.715313705303334],
						[60.612294321727028, 2.7153137186636984],
						[60.612294265811897, 2.7153137552327897],
						[60.612294241680878, 2.7153137782672796],
						[60.611972043345887, 2.7157304755538543],
						[60.61151094474566, 2.7162970737014862],
						[60.611510938841292, 2.7162970821831469],
						[60.611510927032555, 2.7162970991464714],
						[60.611510922106199, 2.716297109269505],
						[60.611080422193432, 2.7171471098890994],
						[60.611080418073811, 2.7171471180221616],
						[60.611080410727013, 2.7171471341140969],
						[60.611080408477811, 2.7171471437143802],
						[60.610619209217901, 2.7186916394224978],
						[60.610288716591846, 2.7196721174673817],
						[60.609913728621521, 2.7203331952305114],
						[60.609616532336005, 2.7207887917908873],
						[60.60961652562446, 2.7207888022607647],
						[60.609616514964195, 2.7207888244938356],
						[60.609616511015446, 2.7207888362570327],
						[60.609149811595813, 2.7221638304621223],
						[60.608772014111231, 2.7231082256567727],
						[60.608772013389867, 2.7231082294616615],
						[60.608772010247797, 2.7231082392348438],
						[60.608772007741599, 2.7231082433876885],
						[60.608533108385345, 2.7239193421481924],
						[60.608533104778395, 2.7239193611723675],
						[60.60853310121967, 2.7239194003403133],
						[60.60853310118241, 2.7239194186686428],
						[60.608608100845998, 2.7247250197906503],
						[60.608608103229379, 2.7247250321508716],
						[60.60860810897406, 2.7247250585128389],
						[60.608608113142282, 2.7247250705251833],
						[60.608766512743507, 2.7251389712682146],
						[60.608766529368189, 2.725138999174137],
						[60.608766568608011, 2.7251390483221329],
						[60.608766593008006, 2.7251390692163242],
						[60.609119292620541, 2.7253612686445079],
						[60.609119326580704, 2.7253612821794873],
						[60.609119395687344, 2.7253612961932832],
						[60.609119431726292, 2.7253612964981686],
						[60.609497231632524, 2.725300197645697],
						[60.609497250287937, 2.7253001921776754],
						[60.609497283943561, 2.7253001801218635],
						[60.609497300728613, 2.7253001731861963],
						[60.609886175425849, 2.7250723879212932],
						[60.610458300234313, 2.7249363067875132],
						[60.610986031649574, 2.7250640886295683],
						[60.611660990416297, 2.7253945686751511],
						[60.611701576259861, 2.7254284323156268],
						[60.61156657319772, 2.725616533739486],
						[60.610105529895399, 2.72616651342023],
						[60.610105482094298, 2.726166550218287],
						[60.610105418031331, 2.7261666433189178],
						[60.610105399984654, 2.7261666999693022],
						[60.610110900157721, 2.7292249996571885],
						[60.610110900670385, 2.7292250105504507],
						[60.610110902588147, 2.7292250321631513],
						[60.610110904885694, 2.7292250427087565],
						[60.610174804871534, 2.7295167435707879],
						[60.6101748611989, 2.7295168113841037],
						[60.610175005753447, 2.729516878503166],
						[60.610175094787643, 2.729516875819562],
						[60.610677816157114, 2.7292446182278001],
						[60.612033196139357, 2.7297334757721288],
						[60.614047048893205, 2.7315140335274197],
						[60.616272002191515, 2.7347250657293172],
						[60.61628039790255, 2.7350471273759025],
						[60.616022048899111, 2.7353442691434102],
						[60.616022025237847, 2.7353443213939936],
						[60.616022011618583, 2.7353444339955031],
						[60.616022021575169, 2.7353444925305279],
						[60.616535909644817, 2.7363417688805454],
						[60.617260814994502, 2.7395802416737922],
						[60.616705686827011, 2.7393137191949135],
						[60.616705671228843, 2.7393137130662604],
						[60.616705639310723, 2.7393137046144562],
						[60.616705622012979, 2.7393137006489368],
						[60.615966782264621, 2.7392304070656222],
						[60.61471682696164, 2.7381970455122251],
						[60.6147167982719, 2.738197029104156],
						[60.614716735964443, 2.7381970064088246],
						[60.614716704131567, 2.738196999774368],
						[60.614088904341237, 2.7381859008127565],
						[60.614088872042537, 2.7381859034309883],
						[60.614088812590005, 2.7381859223258238],
						[60.614088783565947, 2.7381859371338346],
						[60.613533183829389, 2.7385804376971263],
						[60.613533170783988, 2.7385804475640652],
						[60.613533146734007, 2.7385804723980898],
						[60.61353313564414, 2.7385804855493929],
						[60.611830488932284, 2.7410137094842519],
						[60.610566680719238, 2.741133101677554],
						[60.610566662784947, 2.7411331033319342],
						[60.610566629042388, 2.7411331135561743],
						[60.610566612256378, 2.7411331204838967],
						[60.609416635256842, 2.74169980804067],
						[60.607891659876422, 2.7420081036775019],
						[60.607891643005111, 2.7420081087886645],
						[60.607891610325431, 2.7420081224684227],
						[60.607891593539364, 2.7420081293950482],
						[60.603947108961854, 2.7444720209563598],
						[60.601944324988821, 2.7452804155144528],
						[60.601944309094989, 2.7452804222645759],
						[60.601944279433148, 2.7452804426787556],
						[60.601944264602224, 2.7452804528858303],
						[60.600619332710522, 2.7464914907852851],
						[60.599263925351821, 2.7463220026475081],
						[60.599263907246986, 2.7463220006680631],
						[60.599263870400485, 2.7463220023274459],
						[60.59926385165879, 2.7463220059662676],
						[60.598041652318159, 2.7466220065035887],
						[60.598041629284559, 2.7466220146382008],
						[60.598041586235496, 2.7466220376473749],
						[60.598041565327598, 2.7466220526951828],
						[60.596591622255325, 2.7479442002873444],
						[60.595236098310892, 2.7479553996268975],
						[60.59523606707463, 2.747955405689023],
						[60.595236006812939, 2.747955426540722],
						[60.595235978679952, 2.7479554411571105],
						[60.59433057453959, 2.7486414671873387],
						[60.593197283559483, 2.7481137190342459],
						[60.593197231837237, 2.7481137107591773],
						[60.593197128777867, 2.7481137216021354],
						[60.593197080118166, 2.7481137402006022],
						[60.592311019937817, 2.7487804096308111],
						[60.590730568993685, 2.7490304020122904],
						[60.590730532743173, 2.7490304163692234],
						[60.590730467892833, 2.7490304545851036],
						[60.59073044027064, 2.7490304800854282],
						[60.590066540089254, 2.7499220813328238],
						[60.590066526661246, 2.749922102247536],
						[60.590066508985316, 2.7499221477893068],
						[60.590066501974839, 2.7499221711212294],
						[60.589969202236077, 2.7506027716581962],
						[60.589969210230159, 2.750602826868763],
						[60.589969252651862, 2.7506029248391184],
						[60.589969287886817, 2.7506029656113511],
						[60.590519288051617, 2.7509723652309384],
						[60.590519330297106, 2.7509723826722023],
						[60.590519414573841, 2.750972393792904],
						[60.590519458560287, 2.7509723907552055],
						[60.591191641101858, 2.7507641279459296],
						[60.591480366218491, 2.7510667444153336],
						[60.59140815065723, 2.7512442347131736],
						[60.589497144693752, 2.7517887080206958],
						[60.589497133176764, 2.7517887120848985],
						[60.58949710933539, 2.7517887222008524],
						[60.589497097011005, 2.7517887282525795],
						[60.588285997066208, 2.7525137287143977],
						[60.588285984019542, 2.7525137385674356],
						[60.588285958011333, 2.7525137600878877],
						[60.588285945942246, 2.752513771582294],
						[60.586260963862408, 2.7549498501210778],
						[60.584466607196383, 2.7561831215232822],
						[60.582672162783247, 2.7568441997250535],
						[60.579530622673381, 2.756877600607766],
						[60.577616746770751, 2.7564165054486134],
						[60.57761672866598, 2.7564165034640973],
						[60.577616695218921, 2.7564165007903236],
						[60.577616677199195, 2.7564165006196011],
						[60.575983276677242, 2.7566054011901411],
						[60.575983260612567, 2.7566054043010704],
						[60.575983231330767, 2.7566054136318936],
						[60.575983216243614, 2.7566054183837125],
						[60.574127777228846, 2.7574636900693918],
						[60.572563943015687, 2.7571192058739902],
						[60.572563915816019, 2.7571192019900477],
						[60.572563861926888, 2.7571192051033839],
						[60.572563836044871, 2.7571192101143263],
						[60.571675009620151, 2.7574219843110077],
						[60.570552880988764, 2.756924817064442],
						[60.570552858166479, 2.7569248105034712],
						[60.570552810014512, 2.7569248015267158],
						[60.57055278566235, 2.7569248007515812],
						[60.569663925354448, 2.7569858985553188],
						[60.567355662715613, 2.7562248102172302],
						[60.567355633645924, 2.7562248048667222],
						[60.567355575039272, 2.7562248034046855],
						[60.567355546394801, 2.7562248071203759],
						[60.566152785779884, 2.7565636969484717],
						[60.565492936079629, 2.7564728012287483],
						[60.56551118626998, 2.7564640797725737],
						[60.565511195917225, 2.7564640742459496],
						[60.565511214319216, 2.7564640633654487],
						[60.565511222988924, 2.7564640561984346],
						[60.569258382795994, 2.7535279893547253],
						[60.572716732909434, 2.7529501974710815],
						[60.572716770945554, 2.7529501827819582],
						[60.572716838219648, 2.7529501386381239],
						[60.572716866565173, 2.7529501093563247],
						[60.57502792856598, 2.749419566223184],
						[60.578483345623248, 2.7482529962345419],
						[60.582808325708413, 2.7476779976979864],
						[60.582808361236708, 2.7476779871448969],
						[60.582808422687251, 2.7476779532573361],
						[60.582808452179378, 2.747677929230373],
						[60.58483074021278, 2.7453279438328293],
						[60.5877195256299, 2.7429779544547022],
						[60.587719529199745, 2.7429779537615473],
						[60.587719534298756, 2.7429779472788267],
						[60.587719536976124, 2.7429779467589634],
						[60.591472331970515, 2.7394529505940288],
						[60.594361170942143, 2.7371030008415596],
						[60.596669398893916, 2.7371140999642098],
						[60.596669446108194, 2.737114088953216],
						[60.59666952871823, 2.7371140454207445],
						[60.596669565006394, 2.7371140127257587],
						[60.598694564762987, 2.7341723132898106],
						[60.598694566865817, 2.7341722048206507],
						[60.598694470318925, 2.7341720477755511],
						[60.598694369969699, 2.7341720013617281],
						[60.594944471993365, 2.7347469878615831],
						[60.592647343692128, 2.7323804613335825],
						[60.59264728014999, 2.7323804315777909],
						[60.592647149845767, 2.7323804184723026],
						[60.592647083254448, 2.7323804387517172],
						[60.58859989511113, 2.7353137285675295],
						[60.586291609008128, 2.7364859213627506],
						[60.586291590693392, 2.7364859340765073],
						[60.586291557719029, 2.7364859606244258],
						[60.586291543144732, 2.736485976272812],
						[60.583980477565703, 2.7394276319988125],
						[60.580805564542615, 2.7405941994994976],
						[60.579077800771394, 2.740588699205444],
						[60.579077773105325, 2.7405887045775152],
						[60.579077719728595, 2.7405887186027602],
						[60.579077694103212, 2.740588729069787],
						[60.575327816248155, 2.7429330541052916],
						[60.572450127214111, 2.7405637459528434],
						[60.572450096739985, 2.7405637299083359],
						[60.572450034347867, 2.740563705419683],
						[60.572450000815486, 2.740563700948984],
						[60.5689917007874, 2.7405526007899539],
						[60.5689916650892, 2.740552607718854],
						[60.568991596881659, 2.7405526319366542],
						[60.56899156632776, 2.7405526525056554],
						[60.566394323350593, 2.7429025997843213],
						[60.562938901288319, 2.7428887003230797],
						[60.562938884246336, 2.7428887017993344],
						[60.562938852032559, 2.7428887062185296],
						[60.562938835968261, 2.7428887093346099],
						[60.562708233678869, 2.7429664436969872],
						[60.562716855574628, 2.7429557245958209],
						[60.562716857359568, 2.742955724249593],
						[60.562716858974042, 2.7429557202774904],
						[60.562716860758968, 2.7429557199312664],
						[60.562725157562333, 2.742944522361435],
						[60.56277235541544, 2.7428862259540803],
						[60.562772357922377, 2.7428862218088708],
						[60.562772363913929, 2.7428862151582512],
						[60.562772366420894, 2.7428862110130376],
						[60.562794561084324, 2.7428529197120519],
						[60.562855754689444, 2.7427779273397457],
						[60.562869553966884, 2.7427612276651394],
						[60.562869555666595, 2.7427612255059612],
						[60.562869558173539, 2.7427612213607278],
						[60.562869559873228, 2.742761219201554],
						[60.562886257377976, 2.7427390232230162],
						[60.562911255235321, 2.7427084260985231],
						[60.562911256042547, 2.7427084241124464],
						[60.56291125774225, 2.7427084219532634],
						[60.562911258634699, 2.7427084217801467],
						[60.562972351382534, 2.7426279325217169],
						[60.56299181767892, 2.7426084649159717],
						[60.562997287745347, 2.742605779227441],
						[60.562997329305084, 2.7426057437232827],
						[60.56299738535003, 2.7426056523542561],
						[60.562997400005735, 2.7426056001152896],
						[60.562997399837123, 2.7426028760511652],
						[60.563019550116422, 2.7425779335219267],
						[60.5630195500312, 2.7425779317089787],
						[60.563019551730896, 2.7425779295497654],
						[60.563019552623359, 2.7425779293766408],
						[60.56306405297304, 2.7425251295035942],
						[60.563122352674498, 2.7424557283075859],
						[60.563122352674498, 2.7424557283075859],
						[60.56312235356696, 2.7424557281344581],
						[60.563136247701131, 2.7424390353826373],
						[60.563152940823805, 2.7424223411638469],
						[60.563164041256137, 2.7424112415609097],
						[60.563164044655508, 2.7424112372424787],
						[60.563164050647053, 2.7424112305916877],
						[60.563164054131668, 2.7424112280862136],
						[60.563177954251174, 2.7423945286421318],
						[60.5631779550584, 2.7423945266560481],
						[60.563177956758089, 2.7423945244968317],
						[60.563177957565316, 2.742394522510732],
						[60.563216856381771, 2.7423445248351648],
						[60.563244554232568, 2.7423112280047586],
						[60.563244555039795, 2.7423112260186699],
						[60.563244557546732, 2.7423112218733472],
						[60.56324456013887, 2.7423112195409725],
						[60.563277960078182, 2.7422668200875382],
						[60.563319560220997, 2.7422112193411272],
						[60.563319561920672, 2.7422112171818922],
						[60.563319565405301, 2.7422112146763786],
						[60.563319566127298, 2.7422112108773011],
						[60.563330761152343, 2.7421945182994909],
						[60.563355754890217, 2.7421640272023997],
						[60.563355757397133, 2.7421640230570579],
						[60.563355760796512, 2.742164018738559],
						[60.563355763388664, 2.7421640164061771],
						[60.5633695632852, 2.7421445150970367],
						[60.563411260094263, 2.7420862205301231],
						[60.563422356013703, 2.7420723242741665],
						[60.563422356906152, 2.7420723241010254],
						[60.563422356820936, 2.7420723222880383],
						[60.563422357798615, 2.7420723239278852],
						[60.563441854954718, 2.7420473264180805],
						[60.563458451626644, 2.7420279300552486],
						[60.563458454218789, 2.7420279277228317],
						[60.563458459317836, 2.7420279212450698],
						[60.563458460932303, 2.7420279172728428],
						[60.563511257968621, 2.7419557233768801],
						[60.563550153172777, 2.7419084293287637],
						[60.563600147179237, 2.7418501361387633],
						[60.56360294087537, 2.7418473401602359],
						[60.563602962800786, 2.7418473084636594],
						[60.563602991946077, 2.7418472387760096],
						[60.563603000058428, 2.7418472006117716],
						[60.563602999703086, 2.7418444728715547],
						[60.563664051278018, 2.7417723306950244],
						[60.563689023421645, 2.741744561784679],
						[60.563694488283133, 2.7417418805505416],
						[60.563694503113965, 2.741741870355026],
						[60.563694531797964, 2.7417418483241276],
						[60.563694544843898, 2.7417418384748977],
						[60.563747344701042, 2.7416862386105785],
						[60.563747346400739, 2.7416862364512662],
						[60.563747348100421, 2.7416862342919726],
						[60.563747349800096, 2.7416862321326798],
						[60.563769545228723, 2.7416612374984757],
						[60.563780741298835, 2.7416501426272606],
						[60.563780746397889, 2.7416501361493486],
						[60.563780755788727, 2.7416501251796936],
						[60.56378076008054, 2.7416501206879449],
						[60.56378905197522, 2.7416390312748273],
						[60.563841840723939, 2.7415862415482515],
						[60.563841841616387, 2.7415862413750931],
						[60.563869541048717, 2.7415584406253881],
						[60.563889037609229, 2.7415390445745249],
						[60.563958435260929, 2.7414751466152913],
						[60.563958437045834, 2.7414751462689702],
						[60.563958439637972, 2.7414751439364915],
						[60.563958441337647, 2.7414751417771681],
						[60.56396124110163, 2.7414723408956392],
						[60.563961263026947, 2.7414723091984481],
						[60.563961292086795, 2.7414722376966831],
						[60.563961300199033, 2.7414721995319242],
						[60.563961299965989, 2.7414694936910613],
						[60.563994527587894, 2.7414418532079452],
						[60.563994527587894, 2.7414418532079452],
						[60.563994529372806, 2.7414418528616191],
						[60.563994530265262, 2.7414418526884563],
						[60.564050125875241, 2.7413945551430561],
						[60.564114023256359, 2.7413445580280968],
						[60.564114024148822, 2.7413445578549309],
						[60.564114024956034, 2.7413445558687619],
						[60.564141824659416, 2.7413223555708459],
						[60.564155719771449, 2.7413112599855425],
						[60.564183415383432, 2.741291864400516],
						[60.564183416190644, 2.7412918624143381],
						[60.564183418868005, 2.7412918618948376],
						[60.564183419675246, 2.7412918599086393],
						[60.564227913408786, 2.7412584653666641],
						[60.564250105614143, 2.7412445705656081],
						[60.564250106421355, 2.7412445685794271],
						[60.564250107313818, 2.7412445684062576],
						[60.564280707173246, 2.7412251690100118],
						[60.564280708065695, 2.7412251688368414],
						[60.564319508380144, 2.7412001678005917],
						[60.564319517134223, 2.741200162442829],
						[60.564319533579457, 2.741200148274459],
						[60.56431954135585, 2.741200141276869],
						[60.564322317954506, 2.7411973649574493],
						[60.564338989304801, 2.7411890786429121],
						[60.564338992789381, 2.7411890761372089],
						[60.564338998951335, 2.7411890731119879],
						[60.564339003413615, 2.7411890722461281],
						[60.564380702794509, 2.7411640720835506],
						[60.564380702709258, 2.7411640702705342],
						[60.564408399172471, 2.7411473746793096],
						[60.564480694923418, 2.7411084751131645],
						[60.564480695815881, 2.7411084749399874],
						[60.564480697600779, 2.7411084745936383],
						[60.564480699385683, 2.7411084742472895],
						[60.564500098701053, 2.7410973733638833],
						[60.564500135712784, 2.7410973369088802],
						[60.564500186572374, 2.7410972501979853],
						[60.564500200420213, 2.7410971999421183],
						[60.564500199843138, 2.741094525224383],
						[60.564505687090545, 2.7410918797088519],
						[60.564505690575139, 2.7410918772031181],
						[60.564505699414468, 2.7410918736583332],
						[60.564505702899034, 2.7410918711526056],
						[60.564561199922366, 2.7410584722777305],
						[60.564586195638569, 2.741044576550788],
						[60.564627894307193, 2.7410223772053985],
						[60.564627896092105, 2.7410223768590409],
						[60.564627900383883, 2.7410223723670697],
						[60.56462790127636, 2.7410223721938878],
						[60.564675054952218, 2.7409946006806538],
						[60.564677799967292, 2.7409946004220043],
						[60.564677827718697, 2.7409945968664595],
						[60.56467788190281, 2.740994580863366],
						[60.564677908335533, 2.740994568415831],
						[60.564755708046604, 2.7409445684851175],
						[60.564755710638742, 2.7409445661525313],
						[60.564755716715425, 2.7409445613141838],
						[60.564755720285277, 2.7409445606214535],
						[60.564766818115878, 2.7409362615047272],
						[60.564786164207128, 2.7409224007422761],
						[60.564788900212285, 2.7409224003956965],
						[60.564788934125637, 2.7409223938147553],
						[60.564788998763483, 2.7409223702941596],
						[60.564789028595506, 2.7409223535276932],
						[60.564841820173086, 2.7408779599410527],
						[60.564858410829892, 2.740866865910351],
						[60.56485841779903, 2.7408668608987892],
						[60.564858430844907, 2.7408668510488203],
						[60.564858436836346, 2.7408668443973987],
						[60.564908436821057, 2.7408195453868704],
						[60.564908437628269, 2.7408195434006166],
						[60.564908440220385, 2.7408195410680145],
						[60.564908441112848, 2.7408195408948286],
						[60.56494454097647, 2.7407834412815095],
						[60.564964034007033, 2.7407640484030651],
						[60.564994526628496, 2.7407390556740454],
						[60.56499455373784, 2.7407390193100727],
						[60.564994589766386, 2.7407389427936963],
						[60.564994600385255, 2.7407389004818605],
						[60.564994599624555, 2.7407361836770017],
						[60.565011241003326, 2.7407195414761452],
						[60.56503624146, 2.7406945423325828],
						[60.565075141256003, 2.7406557419603135],
						[60.565075141170752, 2.7406557401472429],
						[60.565125136867522, 2.7406057462834923],
						[60.565147331392517, 2.7405862515520485],
						[60.565186230482006, 2.740552952673597],
						[60.565186231289218, 2.7405529506873383],
						[60.565186232988879, 2.7405529485278848],
						[60.565186233881349, 2.7405529483546878],
						[60.565216833202122, 2.7405251498608405],
						[60.565236229587384, 2.740508452124705],
						[60.565236233071978, 2.7405084496188485],
						[60.565236237363742, 2.7405084451267361],
						[60.565236239148632, 2.7405084447803452],
						[60.565239037416248, 2.7405057464427425],
						[60.565302926291395, 2.7404473565618779],
						[60.565389015666788, 2.7403862624956172],
						[60.565389021743478, 2.7403862576570592],
						[60.56538903478927, 2.7403862478067325],
						[60.565389040780715, 2.7403862411550852],
						[60.565394527777478, 2.7403807559115037],
						[60.565411211190174, 2.740369566565553],
						[60.565411213782298, 2.74036956423287],
						[60.565411218159319, 2.7403695615537687],
						[60.56541121985898, 2.7403695593942889],
						[60.565455719709107, 2.7403362597821217],
						[60.565466815795922, 2.7403279624234993],
						[60.565483409076329, 2.7403168673590135],
						[60.565505697679981, 2.7403029745478413],
						[60.565516790197577, 2.740297378387341],
						[60.565516791897231, 2.7402973762278542],
						[60.565516797251959, 2.7402973751886246],
						[60.565516799036885, 2.7402973748422097],
						[60.565575096099337, 2.7402640758481485],
						[60.565644491973465, 2.7402279768063593],
						[60.565644531747537, 2.7402279416424635],
						[60.565644585198591, 2.7402278525943462],
						[60.565644599853272, 2.74022780035],
						[60.565644600064552, 2.7402251235522752],
						[60.565661184351583, 2.7402168825154383],
						[60.56568067742085, 2.7402084849391555],
						[60.565736174131025, 2.7401862861203643],
						[60.565736177615577, 2.7401862836144213],
						[60.565736184669959, 2.7401862804156529],
						[60.565736189132238, 2.7401862795496048],
						[60.565752888622612, 2.7401779795143142],
						[60.565752890322258, 2.7401779773548043],
						[60.565752891214721, 2.7401779771815931],
						[60.56575838135074, 2.7401751826297636],
						[60.565830660761847, 2.7401473918090513],
						[60.565841748871804, 2.7401445939162259],
						[60.565841754141296, 2.740144591063856],
						[60.565841764850745, 2.7401445889853195],
						[60.565841770120223, 2.7401445861329523],
						[60.565863960782977, 2.7401362906362894],
						[60.565905650966279, 2.7401251930781565],
						[60.565905652751191, 2.7401251927317309],
						[60.565905656321021, 2.7401251920388789],
						[60.56590565721347, 2.7401251918656668],
						[60.565933355751078, 2.7401168921928187],
						[60.565972253998915, 2.7401057930498376],
						[60.566022253740385, 2.740091893348807],
						[60.566022262579679, 2.7400918898035558],
						[60.566022281150708, 2.7400918825398257],
						[60.566022289097553, 2.7400918791677906],
						[60.566027873876749, 2.7400890863320231],
						[60.566075055886735, 2.7400751918849626],
						[60.566075059456566, 2.7400751911920995],
						[60.566075064726029, 2.7400751883397017],
						[60.566075067403396, 2.7400751878200555],
						[60.566113964603147, 2.7400612893867091],
						[60.5661306634263, 2.7400557905203562],
						[60.566130665125954, 2.7400557883608121],
						[60.566130666018402, 2.7400557881875969],
						[60.566186166272111, 2.7400362886445255],
						[60.566186176003846, 2.7400362849260254],
						[60.566186193597176, 2.7400362760223205],
						[60.566186203328925, 2.7400362723038176],
						[60.566200091410337, 2.7400279779759682],
						[60.566219479278672, 2.7400195840747057],
						[60.566219481870775, 2.7400195817419335],
						[60.566219487225517, 2.7400195807026253],
						[60.566219488925164, 2.7400195785430723],
						[60.566263982248458, 2.7399973830718785],
						[60.566291769106087, 2.739986288591266],
						[60.56630006438575, 2.7399834892486061],
						[60.566300069655213, 2.7399834863961718],
						[60.566300082956765, 2.7399834819847624],
						[60.566300089118698, 2.7399834789591053],
						[60.566355689183631, 2.739955779313501],
						[60.56635569088327, 2.7399557771539351],
						[60.56635569534555, 2.7399557762878337],
						[60.566355697045182, 2.7399557741282696],
						[60.566380683865511, 2.7399418820559513],
						[60.566402859197147, 2.7399334904292862],
						[60.56641394663513, 2.739930793163174],
						[60.566413957344594, 2.7399307910845145],
						[60.566413976808072, 2.7399307836473863],
						[60.56641398573263, 2.7399307819151697],
						[60.566472277573716, 2.7399029840881433],
						[60.56652506899335, 2.7398834883541863],
						[60.566525070693004, 2.739883486194604],
						[60.566525075155269, 2.7398834853284888],
						[60.566525076940195, 2.739883484982037],
						[60.566558366236471, 2.7398695895681686],
						[60.566577851626455, 2.7398640930592779],
						[60.566588948652104, 2.7398612932568218],
						[60.566588953114397, 2.7398612923906955],
						[60.566588960254016, 2.7398612910049001],
						[60.566588962931391, 2.7398612904852238],
						[60.566630659172645, 2.7398473916554975],
						[60.566650055270351, 2.739841892323045],
						[60.566650057055263, 2.7398418919765941],
						[60.566650062324705, 2.7398418891240857],
						[60.566650064194896, 2.7398418905907889],
						[60.566658332867213, 2.7398390999975808],
						[60.566661100447654, 2.7398391007391627],
						[60.566661112049566, 2.7398390984872267],
						[60.566661137123546, 2.7398390954500482],
						[60.566661148725444, 2.7398390931981145],
						[60.566738924725662, 2.7398196000379769],
						[60.566741700338127, 2.7398195992184315],
						[60.566741707563047, 2.7398195996457586],
						[60.566741724604945, 2.7398195981675912],
						[60.566741732637027, 2.7398195966085526],
						[60.566791715776525, 2.7398112999921644],
						[60.566794399591245, 2.7398113005224589],
						[60.566802800377268, 2.7398113001744302],
						[60.566802812064438, 2.7398112997356368],
						[60.566802836160726, 2.739811295058491],
						[60.566802847847889, 2.739811294619682],
						[60.566825024358913, 2.739805800795271],
						[60.566830599935876, 2.7398057999151741],
						[60.56683060537587, 2.7398058006889583],
						[60.56683061519287, 2.7398057987834523],
						[60.566830619740415, 2.739805799730461],
						[60.566913910340972, 2.7397973998569038],
						[60.566916700403191, 2.739797399889174],
						[60.566925000381552, 2.7397974008056147],
						[60.56692500305892, 2.7397974002859282],
						[60.566925010283818, 2.7397974007132722],
						[60.566925013853634, 2.7397974000203575],
						[60.567005606657816, 2.7397919005383367],
						[60.567008268797785, 2.7397918997839432],
						[60.567016639380348, 2.7397945894808911],
						[60.567016665773124, 2.7397945953362144],
						[60.567016719917262, 2.7397945976346016],
						[60.567016746776126, 2.7397945942508812],
						[60.5670278280475, 2.7397918998988526],
						[60.567100003845319, 2.7397891008833652],
						[60.567108300168471, 2.73978910067457],
						[60.567108301953375, 2.739789100328109],
						[60.567108304630729, 2.7397890998084216],
						[60.567108307308096, 2.7397890992887293],
						[60.567188902803103, 2.7397862998768341],
						[60.567199999983089, 2.7397862994301323],
						[60.567200005423082, 2.7397863002039151],
						[60.567200015240083, 2.7397862982983718],
						[60.567200019787613, 2.7397862992453907],
						[60.567227820251247, 2.7397834993758208],
						[60.567227821143689, 2.7397834992025936],
						[60.567252820892328, 2.7397807983410378],
						[60.567280609753695, 2.7397780007113792],
						[60.567291699708669, 2.7397779998325746],
						[60.567291706041118, 2.7397780004331276],
						[60.567291716750567, 2.7397779983543455],
						[60.567291722190575, 2.739777999128127],
						[60.56736671110648, 2.7397696002476506],
						[60.567386100146919, 2.7397696004394412],
						[60.567386104609213, 2.7397695995732758],
						[60.567386113619008, 2.739769599654128],
						[60.567386118973729, 2.7397695986147346],
						[60.567444419197443, 2.7397640987715053],
						[60.567444423659708, 2.7397640979053377],
						[60.567444434454437, 2.739764097639739],
						[60.567444438916688, 2.739764096773575],
						[60.567458319838188, 2.7397612998956244],
						[60.567477799869003, 2.7397613007143589],
						[60.567477804331268, 2.7397612998481922],
						[60.567477812448615, 2.7397613001022929],
						[60.567477816018446, 2.7397612994093565],
						[60.567547208065903, 2.7397558001003146],
						[60.567572199711982, 2.7397557996231674],
						[60.567572202389357, 2.7397557991034627],
						[60.567572206044424, 2.7397558002237168],
						[60.567572207829343, 2.7397557998772477],
						[60.567638904305518, 2.7397529993399674],
						[60.567661099642223, 2.7397530000444701],
						[60.567661103212053, 2.7397529993515248],
						[60.567661110436944, 2.7397529997788426],
						[60.567661113114312, 2.7397529992591352],
						[60.567702807149345, 2.7397501994516333],
						[60.567724999808568, 2.7397502006712156],
						[60.567725005163311, 2.7397501996317981],
						[60.567725014173107, 2.7397501997126432],
						[60.567725017742923, 2.7397501990196997],
						[60.567755618168015, 2.739747398977046],
						[60.567816718352219, 2.7397418989203595],
						[60.56784721788393, 2.7397390983138026],
						[60.567877818308247, 2.7397362982429612],
						[60.567877819200696, 2.7397362980697242],
						[60.567877820178417, 2.7397362997097128],
						[60.567905619745872, 2.7397334998759053],
						[60.567905622423226, 2.7397334993561966],
						[60.567905625993042, 2.7397334986632464],
						[60.56790562777794, 2.7397334983167729],
						[60.567925027594654, 2.7397307974702452],
						[60.567925029379573, 2.7397307971237681],
						[60.567944429054037, 2.7397279974934325],
						[60.567944430838942, 2.7397279971469559],
						[60.567997230741, 2.7397195978220967],
						[60.567997233418353, 2.7397195973023862],
						[60.567997235203272, 2.7397195969559078],
						[60.567997236988155, 2.7397195966094334],
						[60.568041737139069, 2.7397112969685762],
						[60.568041739816429, 2.7397112964488586],
						[60.568041747848518, 2.7397112948897093],
						[60.568041752225533, 2.7397112922102922],
						[60.568083325562149, 2.7397001993871495],
						[60.568088900331212, 2.7397002004619546],
						[60.568088910148212, 2.7397001985563172],
						[60.568088930759906, 2.7397001963850447],
						[60.568088940662172, 2.7397001962926271],
						[60.568141741434651, 2.7396890959478233],
						[60.568141746789394, 2.7396890949083783],
						[60.568141758306012, 2.7396890908430325],
						[60.568141763660734, 2.7396890898035928],
						[60.56815005366024, 2.7396862930481514],
						[60.5681750425328, 2.7396807945369051],
						[60.568175044317705, 2.7396807941904227],
						[60.568175046187882, 2.7396807956571645],
						[60.568175046995066, 2.7396807936707015],
						[60.568244447311109, 2.7396640940915447],
						[60.568244455343205, 2.7396640925323688],
						[60.568244471322089, 2.7396640876007914],
						[60.568244478376464, 2.7396640844016238],
						[60.568263963673502, 2.7396557896928786],
						[60.568286148867912, 2.7396501931255122],
						[60.568286154222619, 2.7396501920860601],
						[60.568286163147178, 2.7396501903536326],
						[60.568286168416634, 2.7396501875009469],
						[60.568347234995016, 2.7396280004545925],
						[60.568355599740308, 2.7396279997109407],
						[60.568355620352008, 2.7396279975395776],
						[60.568355659619989, 2.7396279899168605],
						[60.568355679168697, 2.7396279842922682],
						[60.568375079321221, 2.7396195834608705],
						[60.568375095129589, 2.7396195749027532],
						[60.568375124790876, 2.7396195545065525],
						[60.568375138729046, 2.7396195444816902],
						[60.568377913729499, 2.7396168685991649],
						[60.568444484285507, 2.7395862828088902],
						[60.568444485092691, 2.7395862808224019],
						[60.568444487770073, 2.7395862803026656],
						[60.568444488577256, 2.7395862783161795],
						[60.56846678875457, 2.7395751790984622],
						[60.568466794916468, 2.7395751760725049],
						[60.56846680626257, 2.7395751683805649],
						[60.568466811532012, 2.7395751655278522],
						[60.568475104127046, 2.7395695704450733],
						[60.568536195967759, 2.7395362767845719],
						[60.568536201151936, 2.7395362721185994],
						[60.568536209905943, 2.7395362667596341],
						[60.568536215175399, 2.739536263906889],
						[60.568591812739079, 2.7394973652284884],
						[60.568600109849484, 2.7394918662046237],
						[60.568600113419329, 2.7394918655116243],
						[60.568600117796315, 2.7394918628321334],
						[60.568600120388389, 2.7394918604991387],
						[60.568622309684969, 2.7394751670047435],
						[60.568641799348079, 2.7394640734458746],
						[60.568641810694182, 2.7394640657538663],
						[60.568641833301072, 2.7394640485565942],
						[60.568641843669425, 2.739464039224575],
						[60.568644534076022, 2.7394612498103528],
						[60.568683421071945, 2.7394307604237143],
						[60.568714014292787, 2.7394084646951544],
						[60.568730710954618, 2.7393973661533333],
						[60.568730710954618, 2.7393973661533333],
						[60.56873071273953, 2.7393973658068278],
						[60.568775112690858, 2.7393668654753003],
						[60.568775116175424, 2.7393668629690242],
						[60.568775122252035, 2.7393668581297339],
						[60.568775124844123, 2.7393668557967157],
						[60.568802907720361, 2.7393445693810858],
						[60.568808388413132, 2.7393418805209437],
						[60.568808389220322, 2.739341878534427],
						[60.568808390112757, 2.7393418783611758],
						[60.568825090279176, 2.7393334780495699],
						[60.568825094656191, 2.7393334753700302],
						[60.568825106087523, 2.7393334694911973],
						[60.568825111356979, 2.7393334666384068],
						[60.568866810604959, 2.7393057667159555],
						[60.568866812389878, 2.7393057663694407],
						[60.56886681408951, 2.7393057642096643],
						[60.56886681498198, 2.7393057640364065],
						[60.568894510169294, 2.7392862667021176],
						[60.568958400652157, 2.7392473728839595],
						[60.568977899046153, 2.739236273701712],
						[60.568977910392213, 2.7392362660095277],
						[60.568977931214171, 2.7392362491584201],
						[60.568977940775326, 2.7392362418127694],
						[60.568980723400465, 2.739233460062501],
						[60.569047298911627, 2.7391945735739411],
						[60.569072294689654, 2.7391807766824434],
						[60.569119488586672, 2.7391557790471093],
						[60.569161185348591, 2.7391362804266],
						[60.569161200264467, 2.7391362720412809],
						[60.569161228226029, 2.7391362538039048],
						[60.56916124110117, 2.7391362403252755],
						[60.569164017647218, 2.7391334633891677],
						[60.569191788590295, 2.7391195795852727],
						[60.569191791182369, 2.7391195772522039],
						[60.569191793859751, 2.739119576732409],
						[60.569191795644635, 2.7391195763858871],
						[60.569252873237943, 2.7390862874575337],
						[60.569263948989708, 2.7390834948854592],
						[60.569263964883319, 2.7390834881401029],
						[60.569263995778044, 2.7390834748226767],
						[60.569264009801479, 2.7390834666105808],
						[60.569272302599479, 2.739077971849242],
						[60.569302887431583, 2.7390612817809123],
						[60.569336176691408, 2.7390473859670492],
						[60.569336194199359, 2.7390473752485502],
						[60.569336225645436, 2.7390473545046379],
						[60.569336241197931, 2.7390473405060938],
						[60.569339017827026, 2.739044565359769],
						[60.569344487887967, 2.7390418802913441],
						[60.569344489672865, 2.7390418799448115],
						[60.569344494049858, 2.7390418772651905],
						[60.56934449583477, 2.7390418769186553],
						[60.569405688984219, 2.7390084792265808],
						[60.569430681202469, 2.738997383571391],
						[60.569430683794543, 2.7389973812382769],
						[60.56943069084889, 2.7389973780388353],
						[60.569430694418692, 2.7389973773457599],
						[60.569477885680094, 2.7389723814680043],
						[60.569497278953477, 2.738964083709222],
						[60.569497280738382, 2.7389640833626823],
						[60.569497286900273, 2.7389640803364959],
						[60.569497288599912, 2.7389640781766591],
						[60.569519447448862, 2.7389530000627165],
						[60.56952219968543, 2.7389530000324069],
						[60.569522227436735, 2.7389529964743233],
						[60.569522278050705, 2.7389529811579982],
						[60.56952230278354, 2.7389529708665341],
						[60.569550092902375, 2.7389362778328445],
						[60.569611182977155, 2.7389084826245722],
						[60.569611189946222, 2.7389084776117869],
						[60.569611204054894, 2.7389084712128073],
						[60.569611210131498, 2.7389084663732994],
						[60.569619509889634, 2.7389029664901119],
						[60.56961951864362, 2.7389029611307611],
						[60.569619534196093, 2.7389029471320172],
						[60.569619541079859, 2.7389029403059317],
						[60.569622316812904, 2.7389001652948037],
						[60.569675081544403, 2.7388751812525918],
						[60.569694471410628, 2.7388668876975926],
						[60.569702863086881, 2.7388640891263285],
						[60.569702871926133, 2.7388640855802779],
						[60.569702891389518, 2.73886407814163],
						[60.569702899336328, 2.7388640747688537],
						[60.569722294205434, 2.7388529768670544],
						[60.569766789135436, 2.7388307785633268],
						[60.569766790027877, 2.7388307783900534],
						[60.569788989982804, 2.7388195786455758],
						[60.569838987411707, 2.7387945808250072],
						[60.569880684333775, 2.7387751818488573],
						[60.569880686925863, 2.7387751795156881],
						[60.56988069308774, 2.7387751764894261],
						[60.569880695679821, 2.7387751741562738],
						[60.569911190195327, 2.7387584790216093],
						[60.569941778559041, 2.7387445836505835],
						[60.569969474280867, 2.7387334860085781],
						[60.569969482227634, 2.7387334826357446],
						[60.569969496251034, 2.7387334744232863],
						[60.569969503305387, 2.7387334712237221],
						[60.569983390557823, 2.7387251790688008],
						[60.570061141368882, 2.7386918991961284],
						[60.57006389993748, 2.7386918997410756],
						[60.57006397065139, 2.738691871371925],
						[60.570064071315869, 2.7386917713114451],
						[60.570064100373948, 2.7386916997934034],
						[60.570064100338762, 2.7386890373110355],
						[60.570136171716214, 2.7386612865142208],
						[60.57013617617848, 2.7386612856478036],
						[60.570136184932451, 2.7386612802883112],
						[60.570136189394702, 2.7386612794218985],
						[60.570147273294282, 2.7386557877609405],
						[60.570166754897102, 2.7386501916855472],
						[60.57016675578955, 2.7386501915122645],
						[60.570213955599471, 2.7386362929812655],
						[60.570213962653803, 2.7386362897816419],
						[60.570213975062849, 2.7386362855423299],
						[60.570213981224732, 2.738636282516008],
						[60.570238973402361, 2.738625186258496],
						[60.570255656018624, 2.7386195919654641],
						[60.570288948267446, 2.7386112941380056],
						[60.570288952729712, 2.7386112932715774],
						[60.57028896335386, 2.7386112893788139],
						[60.570288966923648, 2.7386112886856746],
						[60.57032785872341, 2.7385973910396597],
						[60.570380648632856, 2.7385834938866971],
						[60.570391746738402, 2.7385807936632851],
						[60.570391750393497, 2.7385807947834997],
						[60.570391754770448, 2.7385807921037091],
						[60.570391758340278, 2.7385807914105564],
						[60.570419458464364, 2.7385723917092535],
						[60.570419459356813, 2.7385723915359663],
						[60.570419461948873, 2.7385723892027602],
						[60.570419462926615, 2.7385723908428194],
						[60.570452857575759, 2.7385612915644577],
						[60.570483353426425, 2.7385529931077772],
						[60.57048335521133, 2.7385529927612025],
						[60.570483361373199, 2.7385529897348371],
						[60.570483364050574, 2.7385529892149707],
						[60.570508360640254, 2.7385445910541253],
						[60.57059175682484, 2.7385195922780916],
						[60.570591764771606, 2.7385195889051279],
						[60.570591780665183, 2.7385195821591934],
						[60.570591787804787, 2.7385195807728779],
						[60.570597271875272, 2.7385168872862327],
						[60.570616755339515, 2.7385112924995534],
						[60.570616757124405, 2.7385112921529768],
						[60.570616760608942, 2.7385112896464547],
						[60.570616763286296, 2.7385112891265844],
						[60.570641762692659, 2.7385029891607302],
						[60.570641769832292, 2.7385029877744045],
						[60.570641783940943, 2.7385029813750354],
						[60.570641790995275, 2.7385029781753514],
						[60.570647273137205, 2.7385001862397531],
						[60.57068615180804, 2.7384890924415326],
						[60.570697248874986, 2.7384862935389176],
						[60.570697253337251, 2.7384862926724591],
						[60.570697261284046, 2.7384862892994715],
						[60.570697264853841, 2.7384862886063068],
						[60.570777864439819, 2.7384584892507564],
						[60.570836131957812, 2.7384390995623908],
						[60.570841700390282, 2.7384390997901251],
						[60.570841771104099, 2.738439071419621],
						[60.570841870875874, 2.738438971529102],
						[60.570841899933818, 2.7384389000090885],
						[60.570841900233752, 2.7384362447163637],
						[60.570866761263829, 2.7384279897918002],
						[60.570911160184771, 2.7384140909479338],
						[60.57091116107722, 2.7384140907746382],
						[60.570911164561743, 2.7384140882680823],
						[60.57091116732439, 2.7384140895615716],
						[60.570958365035935, 2.7383973890606943],
						[60.570991763319036, 2.7383862905030059],
						[60.571033363078875, 2.7383723897565835],
						[60.571033370133193, 2.738372386556827],
						[60.571033383434681, 2.7383723821439734],
						[60.57103338870408, 2.7383723792908117],
						[60.571044484018714, 2.7383668814802933],
						[60.571063940848774, 2.7383584991804817],
						[60.571066700309238, 2.7383584995184296],
						[60.571066717351123, 2.7383584980391547],
						[60.571066751349548, 2.7383584932672278],
						[60.571066768220845, 2.7383584881611882],
						[60.571136165437196, 2.7383334892600275],
						[60.571144461788187, 2.7383307894282565],
						[60.571144462680635, 2.7383307892549578],
						[60.571144466250452, 2.7383307885617612],
						[60.571144467142908, 2.7383307883884593],
						[60.571183366932942, 2.7383168884152727],
						[60.57118336871784, 2.7383168880686726],
						[60.571183373094819, 2.7383168853887856],
						[60.57118337398726, 2.7383168852154873],
						[60.5712250621119, 2.7383001910778186],
						[60.571236149355883, 2.7382973939587623],
						[60.571236155603039, 2.7382973927456589],
						[60.571236169711675, 2.7382973863460509],
						[60.571236177743742, 2.7382973847863439],
						[60.571288968228934, 2.7382751895037831],
						[60.571316756951923, 2.7382668928281748],
						[60.571316764006241, 2.738266889628346],
						[60.571316777307715, 2.7382668852154208],
						[60.571316782577142, 2.7382668823622121],
						[60.57134727890287, 2.7382529835714897],
						[60.571402869339771, 2.7382307879094991],
						[60.571411163759535, 2.7382279895811821],
						[60.571411168221779, 2.7382279887146646],
						[60.571411176168546, 2.7382279853415303],
						[60.571411179653062, 2.7382279828349128],
						[60.571455679044647, 2.7382084843439962],
						[60.571494478848969, 2.7381918841421165],
						[60.571513941018893, 2.7381835005642334],
						[60.571516700393772, 2.7381834990714533],
						[60.571516721090738, 2.7381834987122535],
						[60.57151676295063, 2.7381834887534806],
						[60.571516783306421, 2.7381834811406192],
						[60.571583367133456, 2.7381529888629084],
						[60.571594449103493, 2.7381501945081648],
						[60.571594473836228, 2.7381501842153164],
						[60.571594520368521, 2.7381501587093138],
						[60.571594541190315, 2.7381501418560283],
						[60.571597316011413, 2.7381473667742755],
						[60.571633379680321, 2.738130783528975],
						[60.571666776841532, 2.7381168847619346],
						[60.571666785595461, 2.7381168794019985],
						[60.571666803188585, 2.7381168704955865],
						[60.571666811942514, 2.7381168651356504],
						[60.571675100169244, 2.7381112735704884],
						[60.571738985713132, 2.7380807797601054],
						[60.571738986605567, 2.7380807795867979],
						[60.57173898928292, 2.7380807790668671],
						[60.571766789106505, 2.7380668783347795],
						[60.571766792676307, 2.7380668776415362],
						[60.571766799645324, 2.7380668726282118],
						[60.571766803129833, 2.738066870121548],
						[60.57178069991631, 2.7380584746620906],
						[60.571811196242329, 2.73804187620844],
						[60.571811197049506, 2.7380418742217052],
						[60.571811200619294, 2.7380418735284593],
						[60.571811203211347, 2.7380418711950862],
						[60.571852896186357, 2.7380168745336677],
						[60.571880689032504, 2.7380029787052824],
						[60.571880691624571, 2.7380029763719169],
						[60.571880697786433, 2.7380029733452798],
						[60.571880699571324, 2.7380029729986548],
						[60.571933394741244, 2.7379723754112018],
						[60.571944489227256, 2.7379668792787561],
						[60.571944493604214, 2.7379668765987373],
						[60.571944505927959, 2.7379668705454647],
						[60.571944511112072, 2.7379668658787173],
						[60.571952910591996, 2.7379612654259562],
						[60.571952919345911, 2.7379612600659424],
						[60.571952934091108, 2.7379612480524189],
						[60.571952940974846, 2.7379612412255869],
						[60.571955707207913, 2.7379584750820363],
						[60.571963961944434, 2.7379557904724598],
						[60.571963975160607, 2.7379557842458704],
						[60.571963998915585, 2.7379557723126191],
						[60.571964011239317, 2.737955766259343],
						[60.571980706798307, 2.7379445689480519],
						[60.572022303320367, 2.7379195706144688],
						[60.572022306890162, 2.737919569921206],
						[60.572022315558783, 2.7379195627477353],
						[60.572022319935748, 2.7379195600677164],
						[60.572033411623906, 2.737911266368684],
						[60.572089003269866, 2.7378779721714288],
						[60.572089003184587, 2.7378779703579879],
						[60.572089004077029, 2.7378779701846714],
						[60.572089004969463, 2.7378779700113549],
						[60.572125105243288, 2.7378557700886796],
						[60.572125113997188, 2.7378557647286104],
						[60.572125133034028, 2.7378557482214934],
						[60.572125140810208, 2.7378557412212827],
						[60.572127925992234, 2.7378529567325898],
						[60.572158407999858, 2.7378334692463273],
						[60.57215840880702, 2.737833467259565],
						[60.572158413183956, 2.7378334645795261],
						[60.572158414076419, 2.737833464406203],
						[60.572186212793611, 2.7378140642833495],
						[60.572211200262508, 2.7377973731686458],
						[60.572227889186181, 2.7377890793706592],
						[60.572227896155184, 2.7377890743571771],
						[60.572227910178519, 2.737789066143693],
						[60.57222791634036, 2.7377890631169999],
						[60.572266816144072, 2.7377612629561794],
						[60.572266817928977, 2.737761262609534],
						[60.572266821328171, 2.7377612582893347],
						[60.572266823113075, 2.7377612579426871],
						[60.57229180800406, 2.737741869794807],
						[60.572297290210763, 2.7377390793845482],
						[60.572302889033011, 2.7377362791353868],
						[60.572302895194852, 2.7377362761086763],
						[60.572302905733657, 2.7377362704018751],
						[60.572302910917756, 2.7377362657350353],
						[60.572361210894357, 2.7376973666202269],
						[60.572361215271307, 2.7376973639401494],
						[60.572361223132752, 2.7376973587533198],
						[60.572361226531974, 2.7376973544330929],
						[60.572391813593796, 2.7376723653177968],
						[60.572411198860379, 2.7376612748432581],
						[60.572411205829397, 2.7376612698297165],
						[60.572411218960241, 2.7376612617894596],
						[60.572411224951509, 2.737661255135786],
						[60.572425120834446, 2.7376501602003565],
						[60.572464016226753, 2.7376223623696703],
						[60.572483416193982, 2.7376084635473457],
						[60.572483417001131, 2.7376084615605576],
						[60.572483418786035, 2.7376084612139002],
						[60.572483419593183, 2.7376084592270966],
						[60.572494520012206, 2.737600159933987],
						[60.57252791916644, 2.7375751606536962],
						[60.572558417758501, 2.7375529627931687],
						[60.5725584176732, 2.7375529609797069],
						[60.572558420350553, 2.7375529604597157],
						[60.572558421157716, 2.7375529584729197],
						[60.572569517944189, 2.737544562852988],
						[60.572616814781824, 2.7375112643277504],
						[60.572616821665527, 2.7375112575006941],
						[60.572616834711077, 2.7375112476468297],
						[60.572616840702317, 2.7375112409930904],
						[60.572625141068343, 2.7375029401057605],
						[60.572627928026762, 2.7375001551946374],
						[60.572636211845349, 2.7374945659712684],
						[60.572636215329851, 2.7374945634644687],
						[60.572636219706801, 2.7374945607843388],
						[60.572636223191274, 2.7374945582775432],
						[60.572661222781697, 2.7374751576722067],
						[60.572661223674146, 2.7374751574988712],
						[60.572661225373757, 2.7374751553387346],
						[60.572689024793064, 2.7374529563007739],
						[60.572689025600226, 2.7374529543139503],
						[60.572689027470403, 2.737452955780773],
						[60.572750126594464, 2.7374029544422376],
						[60.57275013007898, 2.7374029519354144],
						[60.572750137855103, 2.7374029449349568],
						[60.572750141254303, 2.7374029406146647],
						[60.572752923833356, 2.7374001583637506],
						[60.572766803205248, 2.737391871852517],
						[60.57276681017423, 2.7373918668388812],
						[60.572766825811804, 2.7373918546514364],
						[60.572766832780808, 2.7373918496377794],
						[60.572791832801329, 2.7373695481577398],
						[60.572791833779078, 2.7373695497978945],
						[60.572791833693763, 2.7373695479844034],
						[60.572819532961653, 2.7373445499331766],
						[60.572841830781627, 2.7373251504545126],
						[60.572841834351436, 2.7373251497611535],
						[60.572841838557778, 2.7373251434540262],
						[60.572841841149803, 2.737325141120535],
						[60.572861240790978, 2.7373057408677233],
						[60.572880741092142, 2.7372862405170051],
						[60.572930730913072, 2.7372362514859545],
						[60.572941819959105, 2.7372279596357494],
						[60.572941837210969, 2.7372279434745019],
						[60.572941866996494, 2.7372279065782577],
						[60.572941879615499, 2.7372278876567373],
						[60.572944570259395, 2.7372223086033522],
						[60.572955751431671, 2.7372084318183285],
						[60.572997339994927, 2.7371640437509912],
						[60.573022333317894, 2.7371418493626489],
						[60.573022339394448, 2.7371418445222577],
						[60.573022351376892, 2.737141831214482],
						[60.573022355583213, 2.7371418249072752],
						[60.573055751924784, 2.7371001295828661],
						[60.573083448098984, 2.7370695335089739],
						[60.573083453283061, 2.7370695288418934],
						[60.573083461781032, 2.7370695180409434],
						[60.573083465987352, 2.7370695117337318],
						[60.57308905544312, 2.737061228405651],
						[60.573100140854891, 2.737050140816033],
						[60.573100146038975, 2.7370501361489601],
						[60.573100156321821, 2.7370501250013053],
						[60.573100159720994, 2.7370501206809115],
						[60.57310845983168, 2.737039019587812],
						[60.573108464845127, 2.7370390112937382],
						[60.573108474957351, 2.7370389965190922],
						[60.573108479078385, 2.737038988398361],
						[60.573111253229833, 2.7370334424897278],
						[60.573125102743496, 2.737025172495644],
						[60.57312511838105, 2.7370251603078768],
						[60.573125146001011, 2.7370251348122525],
						[60.573125158875911, 2.7370251213310381],
						[60.573169555999819, 2.7369668258978148],
						[60.573197351107858, 2.7369334323262278],
						[60.5732279471179, 2.7369001351602713],
						[60.573227955615842, 2.7369001243592459],
						[60.573227971633969, 2.7369001011170169],
						[60.573227979154147, 2.7369000886758119],
						[60.573230767191298, 2.7368945125849171],
						[60.573277949199486, 2.7368418321831727],
						[60.573277954383563, 2.736841827516022],
						[60.573277962881498, 2.7368418167149486],
						[60.573277967087805, 2.7368418104076713],
						[60.573283456383301, 2.7368334274869208],
						[60.573289039718055, 2.7368279434378673],
						[60.573289044902118, 2.7368279387707326],
						[60.573289054292502, 2.7368279277962961],
						[60.57328905769166, 2.7368279234758535],
						[60.573308455181262, 2.7368029266689615],
						[60.573341851634481, 2.7367640305896761],
						[60.573341854141205, 2.7367640264425934],
						[60.573341859325268, 2.7367640217754383],
						[60.57334186093955, 2.7367640178016965],
						[60.573372360675229, 2.7367223188091172],
						[60.573372365688684, 2.7367223105149194],
						[60.573372374101304, 2.7367222979002741],
						[60.573372378307603, 2.7367222915929439],
						[60.573375167288447, 2.7367168122708581],
						[60.573394551073648, 2.7366945301685117],
						[60.573394551880796, 2.7366945281816419],
						[60.573394556257711, 2.7366945255013193],
						[60.573394557064844, 2.7366945235144517],
						[60.57342515286129, 2.7366557283662454],
						[60.573452948115055, 2.7366251342400609],
						[60.573452954106259, 2.7366251275859748],
						[60.57345296421844, 2.7366251128110406],
						[60.57345297020963, 2.7366251061569584],
						[60.57346686537948, 2.7366029128685034],
						[60.573475158736116, 2.7365918219852716],
						[60.5735057568616, 2.7365529236961694],
						[60.573505762767482, 2.7365529152285477],
						[60.573505775386373, 2.7365528963064443],
						[60.573505780314477, 2.7365528861986759],
						[60.573508467613912, 2.7365473131244276],
						[60.573533449433342, 2.7365195346645663],
						[60.573533452747185, 2.7365195285305415],
						[60.573533462137533, 2.7365195175559305],
						[60.573533466343839, 2.7365195112485559],
						[60.573555765761029, 2.7364862108625458],
						[60.573555770052629, 2.7364862063686823],
						[60.573555775787881, 2.7364861942740037],
						[60.573555779994159, 2.7364861879666074],
						[60.573558464928666, 2.7364807178220767],
						[60.573566840942512, 2.7364723413142409],
						[60.573566846126589, 2.7364723366470036],
						[60.573566853817347, 2.7364723278326162],
						[60.573566858108947, 2.7364723233387496],
						[60.573616857895871, 2.7364084230391819],
						[60.573616857810556, 2.7364084212256627],
						[60.57361685959544, 2.7364084208789246],
						[60.573616861295015, 2.7364084187186677],
						[60.573647361053581, 2.7363668178228426],
						[60.57364736194603, 2.7363668176494707],
						[60.57364736194603, 2.7363668176494707],
						[60.573647362753157, 2.7363668156625804],
						[60.573702963306509, 2.7362890162765154],
						[60.573702963221194, 2.7362890144629741],
						[60.573702964113629, 2.7362890142896048],
						[60.57370296492077, 2.7362890123027057],
						[60.573733460939479, 2.7362445185068482],
						[60.57375295839433, 2.736219523513205],
						[60.573752958309015, 2.7362195216996779],
						[60.573780758383066, 2.7361834227912163],
						[60.573780760082641, 2.7361834206309368],
						[60.573780761696902, 2.7361834166571111],
						[60.573780764288941, 2.7361834143234498],
						[60.573800158319969, 2.7361557234670624],
						[60.573819550584723, 2.7361334317365893],
						[60.573819558190131, 2.7361334211085415],
						[60.573819571616092, 2.736133400199158],
						[60.573819579221521, 2.736133389571088],
						[60.573825172662261, 2.7361223013757683],
						[60.573836263255984, 2.7361057163601794],
						[60.573914059311825, 2.7360029218384727],
						[60.573975150889524, 2.7359223321819193],
						[60.573977941124738, 2.7359195408785908],
						[60.57397794630878, 2.7359195362111857],
						[60.573977953999503, 2.73591952739657],
						[60.573977958205774, 2.7359195210890115],
						[60.574005755683963, 2.7358834258108877],
						[60.574050146852422, 2.7358307357156559],
						[60.574055741171009, 2.7358251412371994],
						[60.574055746355057, 2.7358251365697663],
						[60.574055754938229, 2.7358251275817134],
						[60.574055759144471, 2.7358251212741314],
						[60.574091850683956, 2.7357779325284466],
						[60.574130740816798, 2.735739041277935],
						[60.574130741709247, 2.7357390411045417],
						[60.574183441125747, 2.7356862407446942],
						[60.574197340945119, 2.7356723415829802],
						[60.574200083074636, 2.7356695998461777],
						[60.574202800244869, 2.7356696008331736],
						[60.574202837642289, 2.7356695917370808],
						[60.574202909247845, 2.7356695631837575],
						[60.574202940607961, 2.7356695406196021],
						[60.574225132163988, 2.7356473504495242],
						[60.57427231379824, 2.7356112658283753],
						[60.574361205955157, 2.7355557703571725],
						[60.574361214623615, 2.7355557631825036],
						[60.574361232853029, 2.7355557486597775],
						[60.574361240629052, 2.7355557416585228],
						[60.574364041223589, 2.7355529409535175],
						[60.574364063147272, 2.7355529092417439],
						[60.574364092203503, 2.7355528377112113],
						[60.574364100313829, 2.7355527995326296],
						[60.574364099627381, 2.7355502008676318],
						[60.574366699670158, 2.7355502008092465],
						[60.574366724743982, 2.735550197767648],
						[60.574366772765408, 2.7355501847770207],
						[60.57436679579834, 2.7355501766415489],
						[60.574427888706666, 2.7355168792108184],
						[60.574452880731421, 2.7355057825328863],
						[60.574452881623863, 2.7355057823594842],
						[60.574452881623863, 2.7355057823594842],
						[60.57445288340876, 2.7355057820126829],
						[60.574483381337501, 2.7354918823795913],
						[60.574541771242593, 2.7354668862025351],
						[60.574550063973895, 2.7354640908200301],
						[60.57459176270492, 2.735450190828931],
						[60.574591764404481, 2.7354501886685569],
						[60.574591766189371, 2.7354501883217499],
						[60.57459176708182, 2.7354501881483486],
						[60.574630648174967, 2.7354362956926845],
						[60.574650029048208, 2.7354334981137902],
						[60.574650036187762, 2.7354334967265617],
						[60.574650052251791, 2.735433493605298],
						[60.574650060198486, 2.7354334902310731],
						[60.574694449235956, 2.7354195945016815],
						[60.574722238962778, 2.7354140960589364],
						[60.574750038275099, 2.7354084950837905],
						[60.574794434881738, 2.7354001962702825],
						[60.57481113275265, 2.7353973965399003],
						[60.574811134537541, 2.7353973961930893],
						[60.574811138107343, 2.7353973954994619],
						[60.574811138999785, 2.7353973953260584],
						[60.574825030666013, 2.7353945972901079],
						[60.574875022091, 2.7353890986106215],
						[60.574875025660781, 2.7353890979169981],
						[60.574875034585233, 2.7353890961829341],
						[60.574875039047456, 2.7353890953159024],
						[60.574902819701727, 2.7353834997398536],
						[60.574908299897288, 2.7353835000691102],
						[60.57490830533731, 2.735383500842254],
						[60.57490831604666, 2.7353834987613745],
						[60.574908322379088, 2.735383499361117],
						[60.574958320050044, 2.7353779994285494],
						[60.57498890886766, 2.7353752000811418],
						[60.574994399857843, 2.7353752001407972],
						[60.574994406190292, 2.7353752007405374],
						[60.574994416899628, 2.7353751986596495],
						[60.57499442233965, 2.7353751994328097],
						[60.575069422298547, 2.7353668984711348],
						[60.57506942854566, 2.7353668972572769],
						[60.575069441932349, 2.7353668946561518],
						[60.575069449071918, 2.7353668932688819],
						[60.575080624715497, 2.7353640995012434],
						[60.575100000320738, 2.7353640993644506],
						[60.575100005760746, 2.7353641001375975],
						[60.57510001923275, 2.7353640993500665],
						[60.575100026372333, 2.7353640979627936],
						[60.575163913028021, 2.7353558003786924],
						[60.575169400363009, 2.7353557993132758],
						[60.575172200405675, 2.7353558006376795],
						[60.575172206652788, 2.7353557994238153],
						[60.575172221017255, 2.7353557984628671],
						[60.575172228156816, 2.735355797075592],
						[60.575250027748133, 2.7353445983785711],
						[60.575250033102797, 2.7353445973381092],
						[60.575250042919706, 2.7353445954305959],
						[60.575250047381935, 2.7353445945635442],
						[60.575261141019062, 2.7353418954013882],
						[60.575325033657073, 2.735330796351493],
						[60.575325041689091, 2.735330794790793],
						[60.575325056860656, 2.7353307918428045],
						[60.575325064000225, 2.7353307904555129],
						[60.575333346824515, 2.7353279950519633],
						[60.575352827692896, 2.7353251980225362],
						[60.575352836617355, 2.7353251962884229],
						[60.575352854466274, 2.7353251928201909],
						[60.57535286330539, 2.7353251892724644],
						[60.575377860922515, 2.7353168911964718],
						[60.575413951051253, 2.7353057934733429],
						[60.575438944235678, 2.735300195771678],
						[60.575438951289925, 2.7353001925707567],
						[60.5754389646766, 2.7353001899695659],
						[60.57543897173084, 2.7353001867686273],
						[60.575488971880894, 2.7352807856826589],
						[60.575488972773343, 2.7352807855092434],
						[60.575488973665792, 2.7352807853358274],
						[60.575530673972786, 2.7352640859324331],
						[60.575530681027026, 2.7352640827314927],
						[60.575530693350593, 2.7352640766764464],
						[60.575530698619936, 2.7352640738223357],
						[60.575550098645842, 2.7352529741444735],
						[60.575550104722289, 2.7352529693033216],
						[60.57555011526096, 2.735252963595082],
						[60.575550121337422, 2.73525295875393],
						[60.575561204908382, 2.7352445713667497],
						[60.575566787197154, 2.7352418797226337],
						[60.575566788089574, 2.7352418795492213],
						[60.575566791659355, 2.735241878855561],
						[60.575566793358902, 2.7352418766951074],
						[60.575619492832359, 2.7352140773361566],
						[60.575619499801235, 2.7352140723215572],
						[60.575619512931922, 2.7352140642794263],
						[60.575619519093685, 2.735214061251888],
						[60.575630717552556, 2.7352057613195506],
						[60.575669511876875, 2.7351779661183016],
						[60.575700106633668, 2.7351584682587959],
						[60.575700111095898, 2.7351584673917038],
						[60.57570011717236, 2.7351584625504923],
						[60.575700119764363, 2.7351584602166046],
						[60.575711220003889, 2.7351501591954488],
						[60.5757112226812, 2.7351501586751956],
						[60.575711229564774, 2.7351501518469288],
						[60.575711233049198, 2.7351501493396251],
						[60.575736228744546, 2.7351279527109855],
						[60.575764025285004, 2.7351057553595464],
						[60.575764028769456, 2.7351057528522285],
						[60.575764038330341, 2.7351057455036831],
						[60.575764041729443, 2.7351057411827311],
						[60.57577233327919, 2.7350973508258689],
						[60.575797323070162, 2.7350779573273445],
						[60.575797324769717, 2.7350779551668509],
						[60.575797330846164, 2.7350779503256271],
						[60.575797334415945, 2.7350779496319406],
						[60.575825134163139, 2.7350529494952132],
						[60.575825134077796, 2.735052947681575],
						[60.575825134970252, 2.7350529475081506],
						[60.575889013837994, 2.7349945663694428],
						[60.575894488259117, 2.7349918791397316],
						[60.575894503174645, 2.7349918707505774],
						[60.575894531050189, 2.7349918506918409],
						[60.575894544010197, 2.7349918390222459],
						[60.575977944196772, 2.7349057392901908],
						[60.575977945003878, 2.7349057373031163],
						[60.575977949295442, 2.734905732808687],
						[60.575977950994989, 2.7349057306481854],
						[60.575997348283991, 2.7348834337410866],
						[60.576041846351849, 2.7348362361735186],
						[60.576041847244305, 2.7348362360000884],
						[60.576041848943852, 2.7348362338395793],
						[60.576041849836301, 2.7348362336661491],
						[60.576064023869613, 2.7348112611708957],
						[60.576069491085939, 2.7348084782905744],
						[60.576069530772934, 2.7348084412943123],
						[60.576069585112215, 2.7348083520341944],
						[60.576069599764502, 2.7348082997703536],
						[60.576069600355815, 2.7348028766600967],
						[60.576122347704121, 2.7347445346989732],
						[60.576122349403668, 2.734744532538437],
						[60.576122351103209, 2.7347445303779203],
						[60.576122351995657, 2.7347445302044844],
						[60.576155751647256, 2.7347057302012105],
						[60.576155753346804, 2.7347057280406855],
						[60.576155754239238, 2.7347057278672531],
						[60.576183452356034, 2.7346723295571893],
						[60.576222349812973, 2.7346279329291954],
						[60.576222353212053, 2.7346279286081328],
						[60.5762223591178, 2.7346279201394208],
						[60.576222361709775, 2.7346279178054549],
						[60.57624455507225, 2.7345973278855649],
						[60.576283445811718, 2.7345557363242698],
						[60.576283450188576, 2.7345557336434121],
						[60.576283456901422, 2.734555723187571],
						[60.576283460385831, 2.7345557206801536],
						[60.57632515072914, 2.7345001307378678],
						[60.576336241250822, 2.7344890400613155],
						[60.576336245542329, 2.7344890355667832],
						[60.576336255824948, 2.7344890244171407],
						[60.57633626011647, 2.7344890199225889],
						[60.57636955566236, 2.7344445255467558],
						[60.576405750684422, 2.7344029304158908],
						[60.576405753276404, 2.7344029280818893],
						[60.576405755868379, 2.7344029257478719],
						[60.576405758460368, 2.7344029234138705],
						[60.576425158111412, 2.73437792212862],
						[60.576425159810931, 2.7343779199680611],
						[60.576425164017117, 2.7343779136598294],
						[60.57642516571665, 2.7343779114992524],
						[60.576441856187323, 2.7343529260679991],
						[60.576461240783061, 2.7343334422249392],
						[60.576466840443771, 2.7343279429985987],
						[60.576466843842852, 2.7343279386774508],
						[60.576466852425895, 2.7343279296882979],
						[60.576466855824968, 2.7343279253671531],
						[60.576500155802904, 2.7342862245796065],
						[60.576500158394907, 2.7342862222455682],
						[60.576500160986889, 2.7342862199115494],
						[60.576500162601072, 2.7342862159373094],
						[60.576514062887675, 2.7342668168847055],
						[60.5765140759719, 2.7342667887180419],
						[60.57651409483011, 2.7342667301449728],
						[60.576514099711638, 2.7342666999120047],
						[60.57651409969705, 2.7342639819723291],
						[60.576519543332793, 2.7342584398372689],
						[60.576519545032326, 2.7342584376766954],
						[60.576519549323848, 2.734258433182104],
						[60.576519551023388, 2.7342584310215132],
						[60.576572351249482, 2.7341973315966048],
						[60.576572352949022, 2.7341973294360256],
						[60.576572356348088, 2.7341973251148524],
						[60.576572357155179, 2.7341973231277246],
						[60.576602897044161, 2.7341584994136112],
						[60.576605599762331, 2.7341584994089714],
						[60.576605640814719, 2.7341584914301547],
						[60.576605714926473, 2.7341584587233201],
						[60.576605747985838, 2.7341584339953022],
						[60.576630747526252, 2.7341307333722806],
						[60.576630750203584, 2.7341307328519195],
						[60.57663075360265, 2.7341307285307472],
						[60.576630755302176, 2.7341307263701622],
						[60.576666842259485, 2.7340862432167787],
						[60.576680724836756, 2.7340751568556492],
						[60.576680740303289, 2.734075141037656],
						[60.576680766603431, 2.7340751066415696],
						[60.576680779307253, 2.7340750895302657],
						[60.576686256243335, 2.7340640353938976],
						[60.576694512102222, 2.7340584664046874],
						[60.576694519878181, 2.7340584594025303],
						[60.576694537214927, 2.7340584450512972],
						[60.576694544990872, 2.7340584380491273],
						[60.57674734310568, 2.7340029408255235],
						[60.57675294073406, 2.7339973412175973],
						[60.576752943411385, 2.7339973406972216],
						[60.576752945918024, 2.7339973365494741],
						[60.576752946725108, 2.7339973345623307],
						[60.576783440711829, 2.7339640431296672],
						[60.576808432784404, 2.7339418491275183],
						[60.576808435461722, 2.7339418486071421],
						[60.576808438775451, 2.733941842472233],
						[60.576808441367426, 2.7339418401381455],
						[60.576858432051957, 2.7338918500835327],
						[60.576869505156488, 2.7338834712029985],
						[60.576875087412205, 2.73388077920219],
						[60.576875100628151, 2.7338807729728698],
						[60.576875129395937, 2.7338807527390476],
						[60.576875140570934, 2.7338807414155606],
						[60.576891834011427, 2.7338640496317081],
						[60.576905725235854, 2.7338529559172078],
						[60.576905727020751, 2.7338529555702809],
						[60.576905731312266, 2.7338529510755585],
						[60.576905733097142, 2.7338529507286338],
						[60.576930732924417, 2.7338307495494401],
						[60.576930734623957, 2.7338307473888168],
						[60.576930739807892, 2.7338307427206434],
						[60.576930743292316, 2.7338307402130919],
						[60.576936237598723, 2.7338251459807434],
						[60.576961223653747, 2.7338029584155028],
						[60.576997313604984, 2.7337779646055744],
						[60.5769973170894, 2.7337779620979972],
						[60.576997322273336, 2.7337779574298042],
						[60.576997324950675, 2.7337779569094067],
						[60.577052907890717, 2.733733468357137],
						[60.577069488816292, 2.7337251791960804],
						[60.577069502839308, 2.7337251709794881],
						[60.577069528929755, 2.7337251512657921],
						[60.577069541082523, 2.7337251415824344],
						[60.577086225832282, 2.7337084567883925],
						[60.577116806718209, 2.7336890690762976],
						[60.577116811095088, 2.7336890663952436],
						[60.577116818956348, 2.7336890612066109],
						[60.577116823333199, 2.73368905852556],
						[60.577141815419353, 2.733669563749439],
						[60.577172306837028, 2.7336501695595681],
						[60.577172310321416, 2.7336501670519806],
						[60.577172314698274, 2.7336501643709004],
						[60.57717231818269, 2.7336501618633107],
						[60.577233417914833, 2.7336057627190269],
						[60.577305711445355, 2.7335529663075784],
						[60.577319503601032, 2.7335445711348254],
						[60.577319507085434, 2.7335445686271966],
						[60.577319513247168, 2.7335445655991562],
						[60.577319515839143, 2.733544563265017],
						[60.577339015625896, 2.73353076287898],
						[60.577339017410772, 2.7335307625320326],
						[60.577339019110298, 2.733530760371349],
						[60.577339020809816, 2.7335307582106823],
						[60.577350119170987, 2.7335223613459529],
						[60.577400103818022, 2.7334862721503468],
						[60.577411189324934, 2.7334807788034245],
						[60.57741119807865, 2.733480773441209],
						[60.577411214778962, 2.7334807647039874],
						[60.577411222554865, 2.7334807577015137],
						[60.577461220015053, 2.7334418599946524],
						[60.577500112298267, 2.7334140656537556],
						[60.577530706923135, 2.7333945685532299],
						[60.577530711385357, 2.7333945676858256],
						[60.577530717376362, 2.7333945610302837],
						[60.577530720053701, 2.7333945605098418],
						[60.57756401960556, 2.7333695609063975],
						[60.577564049475257, 2.7333695258148385],
						[60.577564088898036, 2.7333694449384329],
						[60.577564100321354, 2.7333694006203766],
						[60.577564099627345, 2.7333667833895676],
						[60.57756682194384, 2.7333640614699273],
						[60.577591796993531, 2.7333501757452185],
						[60.577591810124098, 2.733350167701778],
						[60.577591832644671, 2.7333501486813905],
						[60.577591843904983, 2.7333501391711863],
						[60.577594531571144, 2.7333473507701735],
						[60.577605718720328, 2.7333390609713457],
						[60.577658416978501, 2.7333001612459653],
						[60.577677914081256, 2.7332862658949795],
						[60.577727900575013, 2.7332529723184744],
						[60.577733390614227, 2.7332501772498703],
						[60.577733398560831, 2.7332501738747434],
						[60.577733414283344, 2.7332501634970479],
						[60.577733422144604, 2.7332501583081821],
						[60.577769518802988, 2.7332223614911721],
						[60.577789002680198, 2.7332084722715293],
						[60.577794487681743, 2.7332057806675505],
						[60.577794494650554, 2.7332057756521682],
						[60.577794507781093, 2.7332057676086174],
						[60.577794513942827, 2.7332057645804637],
						[60.577858414198474, 2.7331612639986265],
						[60.577858421167271, 2.7331612589832259],
						[60.577858435019543, 2.7331612471386588],
						[60.577858441010505, 2.7331612404829886],
						[60.57786122705798, 2.7331584567105276],
						[60.577869509195168, 2.733152968179779],
						[60.577900107258571, 2.7331334680817903],
						[60.577930702959421, 2.7331140709032629],
						[60.577950099372991, 2.7331029733517198],
						[60.577950101157867, 2.7331029730047391],
						[60.577950102857393, 2.7331029708440062],
						[60.577950103749828, 2.733102970670513],
						[60.578000104461886, 2.7330723716689556],
						[60.578000109645792, 2.7330723670004793],
						[60.578000120098991, 2.7330723594773128],
						[60.578000125368284, 2.7330723566226034],
						[60.578014022591525, 2.7330612573192354],
						[60.578036208045049, 2.7330445693919212],
						[60.578066792527352, 2.7330279764914267],
						[60.578072291399806, 2.7330251778032526],
						[60.578072294077131, 2.7330251772827703],
						[60.578072300153465, 2.7330251724408026],
						[60.578072302830819, 2.7330251719203158],
						[60.578127903347344, 2.732991871489423],
						[60.57812790504687, 2.7329918693286728],
						[60.578127910316155, 2.732991866473939],
						[60.578127912101031, 2.7329918661269481],
						[60.578136207127471, 2.7329862688361595],
						[60.578164002930421, 2.7329695718420446],
						[60.578205696257626, 2.7329445757866107],
						[60.578216788791039, 2.7329390788991805],
						[60.578216790575929, 2.7329390785521861],
						[60.578216796737642, 2.7329390755239378],
						[60.578216799329617, 2.7329390731896792],
						[60.578236199294999, 2.7329279747309809],
						[60.578236209662833, 2.7329279653939227],
						[60.578236230569203, 2.7329279503473596],
						[60.578236240937024, 2.7329279410103058],
						[60.578239008028007, 2.7329251755105219],
						[60.578247264270338, 2.7329223898407804],
						[60.578247274808895, 2.7329223841312529],
						[60.578247296863815, 2.7329223743524991],
						[60.578247307402378, 2.7329223686429684],
						[60.578277902173348, 2.7329029712108626],
						[60.578308396217558, 2.7328862748124587],
						[60.57830839711, 2.7328862746389571],
						[60.57830839889489, 2.7328862742919569],
						[60.57830839970196, 2.7328862723046874],
						[60.578361197947814, 2.7328557748330873],
						[60.578397295143574, 2.7328362757886517],
						[60.578397296036023, 2.7328362756151492],
						[60.578397296928486, 2.7328362754416418],
						[60.57842229736449, 2.7328223748147447],
						[60.578422298256953, 2.7328223746412377],
						[60.578422299149388, 2.732822374467736],
						[60.57846119352957, 2.7328001753469344],
						[60.578483389057247, 2.7327890786642084],
						[60.578483403080199, 2.7327890704467968],
						[60.578483429255833, 2.7327890525452188],
						[60.578483441323151, 2.7327890410472593],
						[60.578486217800709, 2.7327862645358065],
						[60.5784917893426, 2.7327834790924346],
						[60.578491791127469, 2.7327834787454273],
						[60.578491792826973, 2.7327834765846402],
						[60.578491793719429, 2.7327834764111372],
						[60.578575094052958, 2.7327390753115948],
						[60.578575096730283, 2.7327390747910769],
						[60.578575102891996, 2.732739071762754],
						[60.578575106376384, 2.7327390692549494],
						[60.578597285854691, 2.7327251824371119],
						[60.578605663339289, 2.7327223895806592],
						[60.578605672263691, 2.7327223878455973],
						[60.578605688071505, 2.7327223792811099],
						[60.578605696018101, 2.7327223759057677],
						[60.578666795626162, 2.7326890759816425],
						[60.578666797411053, 2.732689075634624],
						[60.578666798218109, 2.7326890736473124],
						[60.578666799110565, 2.7326890734738045],
						[60.578686199050082, 2.7326779746947798],
						[60.578730697532194, 2.7326529742227486],
						[60.578755692675088, 2.7326390761504564],
						[60.578777889078268, 2.732627979070561],
						[60.578777892562641, 2.7326279765627284],
						[60.578777901401672, 2.7326279730138312],
						[60.578777903993647, 2.732627970679502],
						[60.578827901979601, 2.732597372419614],
						[60.578847293963079, 2.7325862769000175],
						[60.57885288925408, 2.73258347944736],
						[60.578852891931398, 2.7325834789268248],
						[60.578852897115304, 2.7325834742581523],
						[60.57885289890018, 2.7325834739111325],
						[60.578911199404317, 2.7325501740214277],
						[60.578911201189193, 2.7325501736744013],
						[60.578911203781132, 2.7325501713400633],
						[60.578911206458457, 2.7325501708195228],
						[60.578933403334489, 2.7325362711749941],
						[60.578952898796054, 2.7325251737627658],
						[60.578952899688474, 2.7325251735892522],
						[60.578952902280442, 2.732525171254887],
						[60.57895290317289, 2.7325251710813721],
						[60.57898070328136, 2.7325084705466676],
						[60.578980704173794, 2.7325084703731526],
						[60.57898070506625, 2.7325084701996367],
						[60.578980705958671, 2.7325084700261253],
						[60.579025104393942, 2.732480770354166],
						[60.579094496091749, 2.7324390755620276],
						[60.579105689257887, 2.7324334782225677],
						[60.579116788904251, 2.7324279796003945],
						[60.57911681499445, 2.732427959884316],
						[60.579116860586282, 2.7324279144115273],
						[60.579116880002537, 2.7324278868410099],
						[60.57911955079706, 2.7324223485553301],
						[60.579125088843526, 2.7324195786510175],
						[60.57913618929625, 2.7324140780330368],
						[60.579136192865988, 2.7324140773389645],
						[60.579136201619654, 2.7324140719761507],
						[60.579136206888911, 2.732414069121222],
						[60.579189007349825, 2.7323807690193354],
						[60.579189008242274, 2.7323807688458164],
						[60.579189009941771, 2.7323807666849595],
						[60.579189010834227, 2.7323807665114361],
						[60.579205708770893, 2.7323695685301645],
						[60.579258406626408, 2.7323362699207068],
						[60.579258435859472, 2.7323362404412541],
						[60.579258481404572, 2.7323361748425619],
						[60.579258495846339, 2.7323361372565125],
						[60.579272396331021, 2.7322639387758749],
						[60.579272397138098, 2.7322639367885269],
						[60.57927239696734, 2.7322639331608674],
						[60.57927239696734, 2.7322639331608674],
						[60.579277997422089, 2.732230632400007],
						[60.579277988186803, 2.7322305701308127],
						[60.579277933723823, 2.7322304654037297],
						[60.579277889303164, 2.7322304209584667],
						[60.579250088902263, 2.7322165209423117],
						[60.579250087031994, 2.7322165194755268],
						[60.579250083376863, 2.732216518355806],
						[60.579250079721717, 2.7322165172360853],
						[60.579205705332562, 2.7321970282208894],
						[60.579175127214484, 2.7321720451936624],
						[60.579175105919944, 2.7321720328603987],
						[60.579175061631418, 2.7321720103546903],
						[60.579175038808152, 2.7321720038099189],
						[60.579133347167023, 2.7321637042703633],
						[60.5791140196798, 2.7321581271712296],
						[60.579033459732962, 2.7320498814464735],
						[60.579033449233087, 2.7320498688447894],
						[60.579033424663592, 2.7320498443355516],
						[60.579033411571757, 2.7320498340682762],
						[60.579025154610804, 2.7320442625270047],
						[60.579022378030473, 2.7320388096458244],
						[60.579022364426365, 2.7320387884956849],
						[60.579022331141857, 2.7320387510377455],
						[60.579022312353885, 2.7320387345564061],
						[60.578944527616372, 2.7319859455898179],
						[60.578933440678654, 2.7319748583835115],
						[60.578933436852751, 2.7319748536362054],
						[60.578933426609019, 2.7319748464760032],
						[60.578933422783109, 2.7319748417287184],
						[60.578908422850247, 2.7319554426206389],
						[60.578883422681194, 2.7319359429148937],
						[60.578883421703352, 2.7319359412746094],
						[60.578883420725539, 2.731935939634341],
						[60.578883419833097, 2.7319359398078711],
						[60.57887231996682, 2.7319276391944234],
						[60.578872314441405, 2.7319276366080114],
						[60.578872303305239, 2.7319276296213664],
						[60.578872296802025, 2.7319276253946647],
						[60.57884731940694, 2.7319137369731332],
						[60.578800137352694, 2.7318692531329574],
						[60.578800133697555, 2.7318692520132917],
						[60.578800128979211, 2.7318692474395498],
						[60.578800126131114, 2.7318692443325605],
						[60.578755726246584, 2.7318331446442539],
						[60.578755724376329, 2.7318331431775285],
						[60.578755719743377, 2.7318331404176011],
						[60.578755716895294, 2.7318331373106002],
						[60.578725124096252, 2.7318109436583806],
						[60.578686230139141, 2.7317776484502962],
						[60.578686228183493, 2.7317776451697737],
						[60.578686222658121, 2.7317776425833937],
						[60.57868621981001, 2.7317776394764075],
						[60.578664023652124, 2.7317609425794571],
						[60.578577934997803, 2.7316887511677455],
						[60.578575141377513, 2.7316859594229892],
						[60.578572341083152, 2.7316831598245344],
						[60.578572338149691, 2.7316831549037772],
						[60.578572330668628, 2.7316831490369378],
						[60.578572327820545, 2.731683145929964],
						[60.578505729740961, 2.7316276487460347],
						[60.578483436963737, 2.7316081538671955],
						[60.578461241311039, 2.7315859586213231],
						[60.578461238462943, 2.7315859555143716],
						[60.578461230981901, 2.7315859496475681],
						[60.578461227241363, 2.7315859467141541],
						[60.578414035281838, 2.7315470520767731],
						[60.578400141426449, 2.7315331595829999],
						[60.578400141341056, 2.7315331577692334],
						[60.578400138578367, 2.7315331564760741],
						[60.578400138578367, 2.7315331564760741],
						[60.578397339322308, 2.7315304555432061],
						[60.578397334689356, 2.7315304527833355],
						[60.578397328993162, 2.7315304465694701],
						[60.578397325252638, 2.7315304436360792],
						[60.578369530257916, 2.731508147567737],
						[60.578305736352959, 2.7314498525871196],
						[60.578255737094899, 2.7314026543817618],
						[60.578255736117086, 2.73140265274154],
						[60.578255732376562, 2.7314026498081607],
						[60.578255729613858, 2.7314026485150191],
						[60.578236229626718, 2.731385947611781],
						[60.578216829637746, 2.7313692474164699],
						[60.57821682874529, 2.7313692475900142],
						[60.578216827767463, 2.7313692459497969],
						[60.578166834708533, 2.7313276513559481],
						[60.578125140622639, 2.7312859586154512],
						[60.578102941096816, 2.7312637590787037],
						[60.578102933530367, 2.73126375139827],
						[60.578102919460662, 2.7312637394913635],
						[60.578102911087178, 2.7312637337982384],
						[60.578086222959691, 2.7312526417550931],
						[60.5780334423916, 2.7312054596909023],
						[60.578011250068293, 2.731180467575129],
						[60.578011245264534, 2.7311804611878516],
						[60.578011234935367, 2.7311804522143444],
						[60.578011230217015, 2.7311804476408095],
						[60.577972335888553, 2.7311470532622319],
						[60.577944541343683, 2.7311192593252502],
						[60.577944539388028, 2.7311192560448401],
						[60.577944533777206, 2.7311192516448899],
						[60.577944531821565, 2.7311192483644753],
						[60.577922337343459, 2.7310998540012279],
						[60.577902941029372, 2.7310804591363489],
						[60.577902938095875, 2.7310804542157521],
						[60.577902930614798, 2.731080448349164],
						[60.577902927766701, 2.7310804452423074],
						[60.577852935372739, 2.7310387524928941],
						[60.577786241160716, 2.7309720582801771],
						[60.577777941279358, 2.730963759190645],
						[60.577766841004298, 2.7309526588230106],
						[60.577755740728399, 2.7309415584628183],
						[60.577755738772737, 2.7309415551824539],
						[60.577755735032206, 2.7309415522491878],
						[60.577755732184109, 2.7309415491423552],
						[60.577683438299552, 2.7308776556370153],
						[60.577675141919023, 2.7308692589072843],
						[60.577675140941182, 2.7308692572671092],
						[60.577675138093085, 2.7308692541603068],
						[60.577675137200643, 2.7308692543338577],
						[60.577586239233163, 2.7307859562436176],
						[60.577583440792445, 2.7307831582715574],
						[60.577561241192797, 2.7307609594355995],
						[60.57756123923712, 2.7307609561552488],
						[60.577561233626319, 2.7307609517554052],
						[60.577561231670643, 2.7307609484750546],
						[60.577494533702492, 2.7307026518255744],
						[60.577419538870366, 2.7306331566673085],
						[60.577416840576042, 2.7306304582168224],
						[60.577416837727945, 2.7306304551100653],
						[60.577416830161447, 2.7306304474299377],
						[60.577416824636032, 2.7306304448438312],
						[60.577402940898011, 2.7306192563848053],
						[60.577389052524865, 2.7306026712203932],
						[60.577389048698912, 2.7306026664734877],
						[60.577389038284302, 2.7306026556866247],
						[60.577389032673494, 2.7306026512868113],
						[60.577336232944994, 2.7305554511420898],
						[60.577336232859601, 2.7305554493283797],
						[60.57733623107471, 2.7305554496754976],
						[60.577336230096897, 2.730555448035346],
						[60.577316831887337, 2.7305387502448228],
						[60.577266836939074, 2.7304942536430876],
						[60.57725574134794, 2.7304831581776936],
						[60.577255737607395, 2.7304831552445044],
						[60.577255730040903, 2.730483147564446],
						[60.577255727192778, 2.7304831444577173],
						[60.577225130985958, 2.7304581496310014],
						[60.577186236196617, 2.7304220535810617],
						[60.577186235304183, 2.730422053754622],
						[60.577186234240948, 2.7304220503007857],
						[60.577186233348492, 2.7304220504743477],
						[60.57713623299027, 2.7303776504449244],
						[60.577111233209834, 2.7303554494867361],
						[60.577111230447123, 2.7303554481937251],
						[60.577111223858452, 2.7303554421538623],
						[60.577111221095748, 2.7303554408608481],
						[60.577100122780017, 2.7303470420609948],
						[60.577044527046624, 2.7303026450806325],
						[60.576972329268521, 2.730241546929975],
						[60.576972325613362, 2.7302415458105438],
						[60.576972319917132, 2.7302415395971655],
						[60.57697231617658, 2.7302415366640469],
						[60.576952946272513, 2.7302276597059585],
						[60.576947366429721, 2.7302192895772337],
						[60.576947355844283, 2.7302192751632592],
						[60.576947333059351, 2.730219250309796],
						[60.576947319967424, 2.7302192400438634],
						[60.576891831072366, 2.7301776486677118],
						[60.576886240757112, 2.7301720589839364],
						[60.576886229450032, 2.7301720483709162],
						[60.576886204243998, 2.7301720294792817],
						[60.57688619132287, 2.7301720228407653],
						[60.57688070932587, 2.7301692307433911],
						[60.576866824896968, 2.7301581434507862],
						[60.576797324995965, 2.7301026435440043],
						[60.576797324103509, 2.7301026437175722],
						[60.576797321255405, 2.7301026406109017],
						[60.57679732036295, 2.7301026407844691],
						[60.576775120112892, 2.7300859393895966],
						[60.57677511922045, 2.7300859395631627],
						[60.576775114587448, 2.730085936803627],
						[60.576775113609607, 2.730085935163522],
						[60.576739019052006, 2.7300609396861044],
						[60.576686224219166, 2.7300192421676384],
						[60.576686220564035, 2.730019241048236],
						[60.576686214060757, 2.7300192368221867],
						[60.576686211212632, 2.7300192337155504],
						[60.576661211238616, 2.7300026340127452],
						[60.57665291420723, 2.7299970363765125],
						[60.57659451586629, 2.7299554368697767],
						[60.576527915611599, 2.7299081372197924],
						[60.576527912848867, 2.7299081359268436],
						[60.576527906260196, 2.7299081298871721],
						[60.576527902605022, 2.7299081287677942],
						[60.576514023889402, 2.7298998409025832],
						[60.576508441130585, 2.7298942590088191],
						[60.576508435434341, 2.7298942527955865],
						[60.576508423234813, 2.7298942423563659],
						[60.576508415753693, 2.7298942364902725],
						[60.576489016129706, 2.7298804370800855],
						[60.576489012389146, 2.7298804341470362],
						[60.576489004015585, 2.7298804284545199],
						[60.576489000360439, 2.7298804273351394],
						[60.57643620862752, 2.7298498322066322],
						[60.576416816160148, 2.7298359362851041],
						[60.576416814375285, 2.7298359366322451],
						[60.576416808764456, 2.7298359322327084],
						[60.57641680689418, 2.7298359307661961],
						[60.576386207429408, 2.7298165310652367],
						[60.57638620466669, 2.7298165297722976],
						[60.576386201011545, 2.7298165286529277],
						[60.576386199055861, 2.7298165253727507],
						[60.5763278985726, 2.7297831253446536],
						[60.576327897680173, 2.7297831255182214],
						[60.576327897680173, 2.7297831255182214],
						[60.576258402220283, 2.7297442286654925],
						[60.576236206203248, 2.7297304297327196],
						[60.576236192389665, 2.7297304232680721],
						[60.576236163870064, 2.7297304105123485],
						[60.576236149249461, 2.7297304060349306],
						[60.576225077968964, 2.7297276121322551],
						[60.576211224129501, 2.7297192408741435],
						[60.576205740621639, 2.7297137587229718],
						[60.576205731184821, 2.729713749576836],
						[60.576205708826841, 2.7297137337924946],
						[60.576205696712719, 2.7297137251670751],
						[60.576180696852745, 2.7296998255234808],
						[60.576180691241902, 2.7296998211239973],
						[60.57618067840616, 2.7296998162994393],
						[60.576180671988297, 2.7296998138871666],
						[60.576144510664164, 2.7296859290555044],
						[60.576139041224948, 2.729680458826254],
						[60.57613902902542, 2.7296804483872066],
						[60.576139001056617, 2.7296804282034408],
						[60.57613898715762, 2.7296804199251934],
						[60.576058386985054, 2.7296415199399555],
						[60.576058385114777, 2.729641518473469],
						[60.576058381459617, 2.7296415173541111],
						[60.576058378696899, 2.7296415160612004],
						[60.576019479335763, 2.7296248163527106],
						[60.576019478443314, 2.7296248165262869],
						[60.576019476573045, 2.7296248150598026],
						[60.575986177577875, 2.729610915415249],
						[60.575966779352356, 2.729602615980907],
						[60.575966777567508, 2.7296026163280516],
						[60.575966774804783, 2.7296026150351467],
						[60.575966773826941, 2.7296026133950906],
						[60.575911183071682, 2.7295804171068641],
						[60.575905690991675, 2.7295776216533696],
						[60.575905687336515, 2.7295776205340418],
						[60.57590567815592, 2.7295776168288937],
						[60.575905674415374, 2.7295776138959416],
						[60.575877874000007, 2.7295665140166463],
						[60.575877873107579, 2.7295665141902203],
						[60.575877870344861, 2.7295665128973186],
						[60.575877869367019, 2.7295665112572687],
						[60.575794469157763, 2.7295359111154482],
						[60.575794468265322, 2.7295359112890214],
						[60.57579446461019, 2.7295359101696977],
						[60.575794463717756, 2.7295359103432735],
						[60.575786164206185, 2.7295331100380209],
						[60.575786147886099, 2.7295331077215099],
						[60.575786116052981, 2.7295331011012611],
						[60.575786099818302, 2.7295331005983714],
						[60.575783335822251, 2.7295331000938354],
						[60.575761169732772, 2.7295248138105248],
						[60.575761162337066, 2.7295248097582547],
						[60.575761146824014, 2.7295248054545587],
						[60.57576113862126, 2.7295248033894821],
						[60.575747250090167, 2.7295220066305359],
						[60.575702904966811, 2.7295081233956235],
						[60.5757001387895, 2.7295054566767125],
						[60.575700117323962, 2.7295054407191635],
						[60.57570007107936, 2.7295054149392],
						[60.575700046300305, 2.7295054051168033],
						[60.575641754546396, 2.7294915074409496],
						[60.575625063810186, 2.7294859100721194],
						[60.575625055607446, 2.7294859080070553],
						[60.575625040986829, 2.7294859035298193],
						[60.575625032869496, 2.7294859032783672],
						[60.575608343324959, 2.7294831040917673],
						[60.575547252362803, 2.7294665077713556],
						[60.575547238720041, 2.7294665049341469],
						[60.575547213304759, 2.7294665007262036],
						[60.575547199747433, 2.729466499702613],
						[60.575538927649205, 2.7294664998245386],
						[60.575519455153021, 2.7294609072686336],
						[60.57551944882055, 2.7294609066700573],
						[60.575519435177789, 2.729460903832849],
						[60.575519427867476, 2.7294609015942441],
						[60.57546113858065, 2.7294526021987422],
						[60.575427849288495, 2.7294442063242239],
						[60.575427844740915, 2.729444205378496],
						[60.575427837430617, 2.7294442031398809],
						[60.575427831990581, 2.7294442023677332],
						[60.575394432353704, 2.729438703228984],
						[60.575394424150979, 2.729438701163942],
						[60.575394407916306, 2.729438700661091],
						[60.575394399798974, 2.7294387004096654],
						[60.57538331687109, 2.7294386999956393],
						[60.575336134948785, 2.7294304036405315],
						[60.575336132186067, 2.7294304023476643],
						[60.575336126746045, 2.7294304015755131],
						[60.575336124961183, 2.7294304019226621],
						[60.575313925443517, 2.7294276019405705],
						[60.575313919111046, 2.729427601341984],
						[60.575313906446127, 2.7294276001448368],
						[60.57531390011367, 2.7294275995462658],
						[60.575297211846433, 2.7294276003705211],
						[60.575250023656018, 2.7294220011516495],
						[60.5752500209787, 2.7294220016723716],
						[60.575250017323548, 2.7294220005530785],
						[60.575250013753802, 2.7294220012473756],
						[60.575211114196321, 2.7294192007300517],
						[60.575211113218465, 2.7294191990900241],
						[60.575211110541147, 2.7294191996107471],
						[60.575211109648713, 2.7294191997843225],
						[60.575158310321996, 2.7294165001499233],
						[60.575158307644685, 2.7294165006706446],
						[60.575158303097105, 2.7294164997249282],
						[60.575158300419801, 2.7294165002456445],
						[60.575152809359274, 2.7294164993878138],
						[60.575122218024347, 2.7294137008746029],
						[60.575122214454623, 2.7294137015688915],
						[60.575122205359442, 2.7294136996774649],
						[60.575122200004827, 2.7294137007189025],
						[60.575088899925071, 2.729413700310467],
						[60.575069399935558, 2.7294137007681831],
						[60.575036099855716, 2.7294137003564263],
						[60.574977799715839, 2.7294136994600748],
						[60.574977796146086, 2.7294137001543621],
						[60.574977787136326, 2.7294137000765084],
						[60.574977782674146, 2.7294137009443666],
						[60.574944391989334, 2.7294165000282726],
						[60.574927800066547, 2.7294165004101205],
						[60.574927797303843, 2.7294164991172649],
						[60.574927790164345, 2.7294165005058377],
						[60.574927785616765, 2.7294164995601093],
						[60.574888885786855, 2.7294192006298856],
						[60.574888884001979, 2.7294192009770288],
						[60.574888880432255, 2.7294192016713121],
						[60.574888877669537, 2.7294192003784428],
						[60.57486388878911, 2.7294219997164832],
						[60.5748527996139, 2.7294220005120402],
						[60.574852795066292, 2.7294219995663331],
						[60.574852785249512, 2.7294220014756112],
						[60.57485277980949, 2.7294220007034729],
						[60.574797183236626, 2.7294276013808112],
						[60.57475558709038, 2.7294304003928933],
						[60.574755582628185, 2.7294304012607467],
						[60.574755577188164, 2.7294304004886039],
						[60.574755573618411, 2.7294304011828872],
						[60.574713874136826, 2.7294359014822551],
						[60.574713843072409, 2.7294359111842952],
						[60.574713785025757, 2.7294359407754731],
						[60.574713758850585, 2.7294359586774628],
						[60.574711008189759, 2.7294387081586695],
						[60.57466107766092, 2.7294442005658772],
						[60.574661071413864, 2.7294442017808609],
						[60.574661058027331, 2.7294442043843947],
						[60.574661050887848, 2.7294442057729431],
						[60.574638861447589, 2.7294498043157223],
						[60.574619371658159, 2.7294526029040207],
						[60.574619368088435, 2.729452603598292],
						[60.574619361841378, 2.7294526048132695],
						[60.57461935907866, 2.729452603520425],
						[60.574566663876823, 2.7294637031262621],
						[60.574530569731969, 2.7294692021148799],
						[60.574530565269804, 2.7294692029827132],
						[60.574530556345458, 2.7294692047183835],
						[60.574530550990829, 2.7294692057597887],
						[60.574508257086691, 2.7294748044420336],
						[60.574463863303706, 2.7294831035105895],
						[60.574463862411278, 2.7294831036841547],
						[60.574463857949091, 2.7294831045519881],
						[60.574463856164215, 2.729483104899121],
						[60.574438855976624, 2.7294887046362639],
						[60.574438850622009, 2.7294887056776584],
						[60.574438841783056, 2.7294887092268603],
						[60.574438837320876, 2.7294887100946905],
						[60.574413847097247, 2.7294970073605995],
						[60.574363857193568, 2.7295081040861757],
						[60.574363855408691, 2.729508104433306],
						[60.574363851838939, 2.7295081051275685],
						[60.574363850946511, 2.7295081053011314],
						[60.574352751358482, 2.7295109063810621],
						[60.574352748681164, 2.7295109069017562],
						[60.57435274511144, 2.7295109075960151],
						[60.574352744218984, 2.7295109077695794],
						[60.574305547591464, 2.7295248067094882],
						[60.574272152074514, 2.7295331061034473],
						[60.574272151182072, 2.7295331062770103],
						[60.574261050615362, 2.7295359056985018],
						[60.574260997372505, 2.7295359416753588],
						[60.574260920896755, 2.7295360389050933],
						[60.574260900341123, 2.7295360996373059],
						[60.57426090013729, 2.7295387533869406],
						[60.574191641945767, 2.7295609087252277],
						[60.574172145393618, 2.7295665086194134],
						[60.574152744950538, 2.7295720074584517],
						[60.574152743165634, 2.729572007805579],
						[60.574152738788889, 2.7295720104869097],
						[60.574152737004013, 2.7295720108340338],
						[60.574111036782838, 2.7295859094371036],
						[60.574111035083384, 2.729585911597749],
						[60.574111033298507, 2.7295859119448691],
						[60.574111032406066, 2.729585912118432],
						[60.574080531859046, 2.7295970121605504],
						[60.574080530966604, 2.7295970123341133],
						[60.574080529181714, 2.7295970126812383],
						[60.574080528289294, 2.7295970128547942],
						[60.57404433081286, 2.7296109118486234],
						[60.57398883401541, 2.7296304107005245],
						[60.573988827853753, 2.7296304137289522],
						[60.57398881633749, 2.7296304177987354],
						[60.573988811068276, 2.7296304206536073],
						[60.573966621161304, 2.7296415167165513],
						[60.573936032124216, 2.7296526118948656],
						[60.573936030339347, 2.7296526122419809],
						[60.573936027747429, 2.7296526145761719],
						[60.57393602587716, 2.7296526131097707],
						[60.573922125585575, 2.7296581146515932],
						[60.573922124693134, 2.7296581148251531],
						[60.57392212201583, 2.7296581153458233],
						[60.573922121123395, 2.7296581155193831],
						[60.573902722266624, 2.7296665165192411],
						[60.573822136629765, 2.729699810660017],
						[60.573811051143821, 2.7297026052119362],
						[60.573811041412441, 2.7297026089345522],
						[60.573811021057224, 2.729702616553364],
						[60.57381101132583, 2.7297026202759831],
						[60.573772121372244, 2.7297220150565731],
						[60.573741632411284, 2.7297331125783986],
						[60.573741612141468, 2.7297331220106749],
						[60.573741576320245, 2.7297331454479421],
						[60.573741558983954, 2.7297331598000798],
						[60.573738781589185, 2.7297359344971723],
						[60.573722130034838, 2.7297442110668362],
						[60.573711050880007, 2.7297470061914395],
						[60.573711026148004, 2.7297470164914666],
						[60.573710979617559, 2.7297470420113363],
						[60.573710958796937, 2.729747058871169],
						[60.573708184164524, 2.7297498348571505],
						[60.573630516418532, 2.7297859193620098],
						[60.573630512848808, 2.7297859200562153],
						[60.57363050757958, 2.7297859229110268],
						[60.57363050490229, 2.7297859234316784],
						[60.573594308150049, 2.7298054219698691],
						[60.573555511123061, 2.7298248203675595],
						[60.573544318061771, 2.7298304175246497],
						[60.573516626361844, 2.7298415144150794],
						[60.573516621007215, 2.7298415154563811],
						[60.573516611361242, 2.7298415209924229],
						[60.573516606092028, 2.7298415238472131],
						[60.573474907697005, 2.7298637227484188],
						[60.573452710710818, 2.7298748216557538],
						[60.57345270981839, 2.7298748218292981],
						[60.573391610754939, 2.7299054216913161],
						[60.57337491070092, 2.7299137203136699],
						[60.573363811238053, 2.7299192205085778],
						[60.57336380507639, 2.729919223536887],
						[60.573363793730934, 2.7299192312334544],
						[60.573363787569278, 2.7299192342617631],
						[60.573355497937811, 2.7299248285754349],
						[60.57329160779944, 2.7299581231798817],
						[60.573291606014578, 2.7299581235269716],
						[60.573291603337275, 2.7299581240476054],
						[60.573291600745364, 2.7299581263817205],
						[60.573272101108046, 2.7299692258568289],
						[60.573233202957319, 2.7299915258851679],
						[60.573197105039682, 2.730010924176987],
						[60.573197098878019, 2.7300109272052593],
						[60.57319708574768, 2.7300109352488375],
						[60.573197079671409, 2.7300109400905845],
						[60.573185991833967, 2.7300192306629918],
						[60.573108206333764, 2.7300609239725286],
						[60.573108202764018, 2.7300609246666903],
						[60.573108199279659, 2.7300609271743408],
						[60.573108196602355, 2.7300609276949634],
						[60.573094300142102, 2.7300692263382169],
						[60.573069302574467, 2.7300831241987344],
						[60.573069300874963, 2.7300831263592862],
						[60.573069298283059, 2.7300831286933711],
						[60.573069295605762, 2.7300831292139898],
						[60.573055498400798, 2.7300915274905528],
						[60.57302209969432, 2.7301109277349154],
						[60.573022097017017, 2.7301109282555269],
						[60.573022090855353, 2.730110931283765],
						[60.573022089155884, 2.730110933444323],
						[60.573005494722885, 2.7301220296242574],
						[60.572986001230312, 2.7301331258639805],
						[60.572986000337877, 2.7301331260375195],
						[60.572913799865418, 2.730174827137767],
						[60.572913798080542, 2.7301748274848396],
						[60.572913794596197, 2.730174829992444],
						[60.572913792811335, 2.7301748303395121],
						[60.572861000870454, 2.7302081253520933],
						[60.572855509195307, 2.7302109226220836],
						[60.572855496064946, 2.730210930665486],
						[60.572855470782066, 2.7302109483921977],
						[60.57285545871494, 2.7302109598889741],
						[60.572849916946616, 2.7302164991831619],
						[60.572844399831837, 2.73021649957087],
						[60.572844373865742, 2.730216502789907],
						[60.572844325037892, 2.730216517774696],
						[60.572844301112887, 2.7302165260870357],
						[60.572824900556405, 2.7302276260962657],
						[60.572824897964495, 2.7302276284303213],
						[60.572824894394756, 2.7302276291244554],
						[60.572824891802831, 2.730227631458515],
						[60.572747100918399, 2.7302776259216888],
						[60.572736011082654, 2.7302831203364395],
						[60.572736010275612, 2.7302831223234203],
						[60.572736009383199, 2.730283122496949],
						[60.57273050878171, 2.7302859214774622],
						[60.572730505297343, 2.7302859239850559],
						[60.572730495651335, 2.7302859295208042],
						[60.572730491274548, 2.7302859322019084],
						[60.572674890872982, 2.7303220311993597],
						[60.572674890065947, 2.7303220331863352],
						[60.572674887474022, 2.730322035520373],
						[60.572674884796733, 2.7303220360409615],
						[60.572647096724744, 2.7303415288725774],
						[60.572619310601596, 2.7303554213367924],
						[60.572619303547491, 2.7303554245384842],
						[60.572619291309557, 2.7303554324082207],
						[60.57261928434086, 2.7303554374233547],
						[60.572599889167016, 2.7303692337067678],
						[60.57255549402619, 2.730397030287846],
						[60.57255549402619, 2.730397030287846],
						[60.572555493133756, 2.7303970304613747],
						[60.572502692606385, 2.730430430677377],
						[60.572472092983745, 2.7304498312934595],
						[60.57247208431555, 2.7304498384690352],
						[60.57247206600136, 2.730449851180258],
						[60.572472057418565, 2.7304498601692506],
						[60.57246658130736, 2.7304554359957116],
						[60.572455510839902, 2.7304609210383104],
						[60.572455507355556, 2.7304609235458446],
						[60.572455496731699, 2.7304609274415781],
						[60.572455493332754, 2.7304609317625603],
						[60.572394293330049, 2.7304998318033364],
						[60.572394289760268, 2.7304998324974354],
						[60.572394286361316, 2.7304998368183888],
						[60.572394283684012, 2.7304998373389582],
						[60.572374886049488, 2.7305137345590351],
						[60.572341589410946, 2.730535934139966],
						[60.572333189209672, 2.7305415331259772],
						[60.572333188317231, 2.7305415332995002],
						[60.572333186617762, 2.7305415354599707],
						[60.572288790749425, 2.7305720328126166],
						[60.572208195380078, 2.7306220291093917],
						[60.57220819108867, 2.7306220336038289],
						[60.572208183227531, 2.7306220387923505],
						[60.572208178850723, 2.7306220414733677],
						[60.572197079856636, 2.7306304398652492],
						[60.572174879783653, 2.7306470393934581],
						[60.572174878976618, 2.7306470413803932],
						[60.572163787841845, 2.7306554345659144],
						[60.57213599742218, 2.7306720284682959],
						[60.572135993852442, 2.7306720291623634],
						[60.572135987776143, 2.7306720340038333],
						[60.572135985184232, 2.7306720363377992],
						[60.572108191781616, 2.7306915313719409],
						[60.572074899044459, 2.7307109262174207],
						[60.572074894753037, 2.730710930711846],
						[60.572074887784325, 2.7307109357267918],
						[60.572074884214572, 2.7307109364208557],
						[60.572016583622002, 2.7307526371459865],
						[60.572016582729539, 2.7307526373195041],
						[60.57201658103007, 2.7307526394799386],
						[60.57201658103007, 2.7307526394799386],
						[60.571994283616341, 2.7307692376165513],
						[60.571949909718541, 2.7307998196972809],
						[60.57194163575398, 2.7308026105488241],
						[60.571941614591609, 2.7308026201533528],
						[60.571941576985289, 2.7308026439350352],
						[60.571941558756436, 2.7308026584592411],
						[60.571930476310655, 2.7308137417793943],
						[60.57190269655446, 2.7308304284970282],
						[60.571902693962542, 2.7308304308309661],
						[60.571902688693292, 2.7308304336854383],
						[60.571902686101367, 2.7308304360193922],
						[60.571838785591105, 2.7308748354310799],
						[60.571838783891621, 2.730874837591498],
						[60.571838782192131, 2.7308748397519151],
						[60.571838780407255, 2.7308748400989353],
						[60.571827689913626, 2.7308831323344251],
						[60.571808200805648, 2.7308942268024592],
						[60.571808197321275, 2.7308942293098939],
						[60.571808187675238, 2.7308942348452847],
						[60.571808184190878, 2.7308942373527354],
						[60.571749886121893, 2.7309359350423801],
						[60.571724888877576, 2.7309526332372744],
						[60.571724887178092, 2.7309526353976823],
						[60.571724883693733, 2.7309526379051001],
						[60.571724882801291, 2.7309526380786076],
						[60.571674896280257, 2.730988728419975],
						[60.571658211228232, 2.7309970212273424],
						[60.571658169757377, 2.7309970585698702],
						[60.571658115420547, 2.730997147823595],
						[60.571658099877254, 2.7309972002553127],
						[60.57165809999097, 2.7309998994745945],
						[60.571635982744475, 2.7310165372380948],
						[60.571572090106812, 2.7310609325111197],
						[60.571535995105933, 2.7310831296105111],
						[60.571535990729132, 2.7310831322914182],
						[60.571535981975529, 2.7310831376531985],
						[60.5715359776841, 2.7310831421474742],
						[60.571499883369427, 2.731110938814691],
						[60.571491588322999, 2.7311165335140646],
						[60.571491585731081, 2.7311165358479612],
						[60.571491582246708, 2.7311165383553462],
						[60.571491579654797, 2.7311165406892259],
						[60.571480484851918, 2.7311248372403676],
						[60.571463788617862, 2.731135933797451],
						[60.571463787725428, 2.7311359339709496],
						[60.571463786832986, 2.7311359341444503],
						[60.571463786025944, 2.7311359361313463],
						[60.571399888305557, 2.7311804343705477],
						[60.571391589622692, 2.7311859327507557],
						[60.571391587923166, 2.7311859349111334],
						[60.571391585245863, 2.7311859354316232],
						[60.571391584438821, 2.7311859374184957],
						[60.57137208591201, 2.7311998350600168],
						[60.571322088601214, 2.7312331341356759],
						[60.57132208681633, 2.731233134482669],
						[60.571322081547088, 2.7312331373370125],
						[60.571322079847604, 2.7312331394973755],
						[60.571299880004872, 2.7312498405453596],
						[60.57129987732754, 2.7312498410658446],
						[60.571299871336628, 2.731249847720425],
						[60.571299867766875, 2.7312498484144063],
						[60.57127767419113, 2.731269245598579],
						[60.571266583009923, 2.7312776383205617],
						[60.571213786870928, 2.731313735775502],
						[60.571213785978493, 2.7313137359489952],
						[60.571213785086051, 2.7313137361224857],
						[60.571158184945475, 2.7313526367313323],
						[60.571158184945475, 2.7313526367313323],
						[60.571158183160584, 2.7313526370783165],
						[60.571158182268157, 2.731352637251804],
						[60.571127695953543, 2.731374828540889],
						[60.571122111302309, 2.7313776215548167],
						[60.571122097279407, 2.7313776297707508],
						[60.571122071103915, 2.7313776476690115],
						[60.571122059036675, 2.7313776591647034],
						[60.571113770158398, 2.7313859480579166],
						[60.571052682830178, 2.7314304387661505],
						[60.571033186716647, 2.7314442349804056],
						[60.570991588837281, 2.7314720337726008],
						[60.57099158106147, 2.7314720407740634],
						[60.570991566316906, 2.7314720527901146],
						[60.570991559433537, 2.731472059618091],
						[60.570988770821543, 2.7314748452390694],
						[60.570941584812751, 2.7315081367788392],
						[60.570941583920302, 2.7315081369523297],
						[60.570941583027853, 2.7315081371258154],
						[60.570941582220804, 2.7315081391126506],
						[60.570858181675412, 2.7315692384823302],
						[60.570858174706665, 2.7315692434968866],
						[60.570858161746948, 2.7315692551658959],
						[60.570858155756028, 2.7315692618203342],
						[60.570855480101379, 2.7315720364420208],
						[60.570849911252843, 2.7315748208328916],
						[60.570849903391633, 2.7315748260209358],
						[60.570849886691427, 2.7315748347571689],
						[60.570849879722658, 2.7315748397717319],
						[60.570827679829335, 2.7315915403231825],
						[60.570827649960023, 2.731591575408653],
						[60.570827611345152, 2.7315916542829344],
						[60.570827600007647, 2.7315917004055414],
						[60.57082759983598, 2.731594317261496],
						[60.570824872449798, 2.731597045206084],
						[60.570763787382276, 2.7316387348204008],
						[60.570763784790337, 2.7316387371541837],
						[60.570763780413515, 2.7316387398349287],
						[60.570763776929141, 2.7316387423422128],
						[60.57071378015231, 2.7316776395445008],
						[60.570674897083641, 2.731705427876987],
						[60.570658211084258, 2.7317137202016251],
						[60.570658202330605, 2.7317137255630777],
						[60.570658183930881, 2.7317137364594783],
						[60.57065817615505, 2.7317137434607912],
						[60.570605530039664, 2.7317554007240115],
						[60.570602800294154, 2.7317554006633871],
						[60.570602769865815, 2.7317554047482364],
						[60.57060271219872, 2.7317554232775532],
						[60.570602684067516, 2.731755437895484],
						[60.570583183537202, 2.7317692363873056],
						[60.570583182730147, 2.7317692383741141],
						[60.570583181030642, 2.7317692405343967],
						[60.570583179245766, 2.7317692408813476],
						[60.570572080251559, 2.7317776402396055],
						[60.570494281008756, 2.7318359383669533],
						[60.570433182327847, 2.7318804386291049],
						[60.570402681971672, 2.7319026389136916],
						[60.570402679379704, 2.7319026412474301],
						[60.57040267241095, 2.731902646261859],
						[60.570402668034106, 2.7319026489425369],
						[60.570380477746511, 2.7319220407863445],
						[60.570355489193766, 2.7319387339039651],
						[60.570355485709364, 2.7319387364111662],
						[60.570355479547651, 2.7319387394387737],
						[60.57035547695569, 2.7319387417725047],
						[60.570330477147479, 2.7319581416677563],
						[60.570330476340409, 2.7319581436545461],
						[60.570330474555533, 2.7319581440014833],
						[60.570316578363219, 2.7319692425696984],
						[60.570294281896011, 2.7319859393558219],
						[60.570224882646357, 2.732035937332018],
						[60.570194283237377, 2.7320581382640015],
						[60.570194280645431, 2.7320581405977271],
						[60.570194277161015, 2.7320581431049025],
						[60.570194275376153, 2.732058143451829],
						[60.570166591701735, 2.7320804308827586],
						[60.570155510613269, 2.7320859206413104],
						[60.570155496590331, 2.7320859288566837],
						[60.570155471392525, 2.7320859483936384],
						[60.570155459239842, 2.7320859580753916],
						[60.570152664707415, 2.7320887520350516],
						[60.570135981745459, 2.7321026374377348],
						[60.570105493346759, 2.7321220315477555],
						[60.570105488969922, 2.7321220342283761],
						[60.570105481108705, 2.7321220394161525],
						[60.57010547762431, 2.7321220419233305],
						[60.57004427900381, 2.7321692412758001],
						[60.570010979764348, 2.7321942394320948],
						[60.570010978871906, 2.7321942396055552],
						[60.570010978957264, 2.7321942414188594],
						[60.569999879039074, 2.7322026406451987],
						[60.569999878146611, 2.7322026408186599],
						[60.569999876447099, 2.7322026429788808],
						[60.569999874662223, 2.7322026433258007],
						[60.569958179763333, 2.7322359395359692],
						[60.569930485367095, 2.7322554363003233],
						[60.569930483582205, 2.7322554366472409],
						[60.569930482775135, 2.7322554386339957],
						[60.569869282888547, 2.7322998379637924],
						[60.569869276005136, 2.732299844791322],
						[60.569869262067542, 2.732299854819821],
						[60.569869256076558, 2.732299861473916],
						[60.569866571821457, 2.7323026449337213],
						[60.569858189783581, 2.7323081333148096],
						[60.569858188084069, 2.7323081354750154],
						[60.569858181922356, 2.7323081385024994],
						[60.56985818022283, 2.7323081406627052],
						[60.5698359802212, 2.7323248401607496],
						[60.569835979328772, 2.7323248403342006],
						[60.56977767936926, 2.7323692393662631],
						[60.569733183000601, 2.7324026385919136],
						[60.569705484772719, 2.7324220351627195],
						[60.569705483965642, 2.7324220371494552],
						[60.569685983671938, 2.7324359372375624],
						[60.569685981972412, 2.7324359393977518],
						[60.569685976703127, 2.732435942251743],
						[60.569685975003615, 2.7324359444119293],
						[60.569672076979749, 2.732447042885267],
						[60.569613778990174, 2.7324915417065863],
						[60.569613778097732, 2.7324915418800342],
						[60.569613776312856, 2.7324915422269309],
						[60.569613774613309, 2.7324915443871158],
						[60.569599881767978, 2.7325026381428028],
						[60.569591590074197, 2.7325081326507261],
						[60.569591589181748, 2.7325081328241745],
						[60.569574889411321, 2.7325192322841829],
						[60.56957488681936, 2.7325192346178251],
						[60.569574881550068, 2.7325192374717928],
						[60.569574879850563, 2.7325192396319666],
						[60.569508179682558, 2.7325692394842087],
						[60.569508178875488, 2.7325692414709288],
						[60.569508177983046, 2.7325692416443754],
						[60.569508177090611, 2.7325692418178198],
						[60.569483178299798, 2.732588741105122],
						[60.569449879945445, 2.7326137400034822],
						[60.569449878160569, 2.7326137403503727],
						[60.569449874761524, 2.7326137446706968],
						[60.569449872976634, 2.732613745017586],
						[60.569419277248862, 2.732638741954232],
						[60.569366581152018, 2.7326776389026461],
						[60.569366581237382, 2.7326776407159121],
						[60.569344283625746, 2.7326942378764008],
						[60.569327688361163, 2.732705433945481],
						[60.569327686576287, 2.7327054342923609],
						[60.569327684876761, 2.732705436452509],
						[60.569327683984319, 2.7327054366259493],
						[60.569308184400704, 2.7327192374109499],
						[60.569308182615821, 2.7327192377578347],
						[60.569308179131433, 2.7327192402648586],
						[60.569308177431907, 2.7327192424250053],
						[60.569258176970735, 2.7327581422150509],
						[60.569258176078293, 2.732758142388493],
						[60.569258172679248, 2.73275814670879],
						[60.569258171786799, 2.7327581468822295],
						[60.569241576590656, 2.7327720419056405],
						[60.569180482002821, 2.7328165376762876],
						[60.569180475926444, 2.7328165425168631],
						[60.569180464751518, 2.7328165538378513],
						[60.569180458675127, 2.7328165586784454],
						[60.569169277820507, 2.7328276392703175],
						[60.569149900840031, 2.7328387262023615],
						[60.569149893785855, 2.732838729403126],
						[60.569149880740682, 2.7328387392576921],
						[60.569149874664312, 2.7328387440982755],
						[60.569122075944904, 2.7328609426291024],
						[60.569060986663132, 2.7329081342054491],
						[60.569033197201058, 2.7329248284766305],
						[60.569033178886563, 2.7329248411849916],
						[60.569033147953682, 2.7329248728141318],
						[60.569033132572592, 2.7329248904419434],
						[60.569027651780004, 2.7329331614676899],
						[60.569016582274244, 2.7329415377768735],
						[60.568969286441252, 2.7329748352643053],
						[60.568960987902656, 2.7329804333314436],
						[60.568960986203145, 2.7329804354915672],
						[60.568960981826294, 2.7329804381719671],
						[60.568960980126761, 2.7329804403320765],
						[60.568883179712977, 2.7330387398725526],
						[60.568883150735665, 2.7330387747810541],
						[60.568883111312758, 2.7330388556354359],
						[60.568883099889334, 2.7330388999415183],
						[60.568883099741029, 2.7330415751857062],
						[60.568877711617489, 2.7330442197059215],
						[60.568877702863766, 2.7330442250666942],
						[60.568877685356355, 2.7330442357882516],
						[60.568877676688018, 2.7330442429622606],
						[60.568852682192507, 2.7330637387949319],
						[60.568808186871152, 2.7330942355178216],
						[60.568808179009878, 2.7330942407051566],
						[60.568808165157598, 2.7330942525461914],
						[60.568808158274123, 2.7330942593733174],
						[60.568791570220341, 2.7331109467687242],
						[60.568741583349087, 2.7331470374998941],
						[60.568741580757113, 2.7331470398334008],
						[60.568741578165145, 2.7331470421669111],
						[60.568741576380269, 2.7331470425137585],
						[60.568702682964876, 2.7331776379695456],
						[60.568685989289847, 2.7331887337257301],
						[60.568685985805431, 2.7331887362326537],
						[60.568685978836584, 2.7331887412465221],
						[60.568685976244623, 2.7331887435800217],
						[60.568633184717697, 2.7332304366849476],
						[60.568610993697149, 2.7332442291796775],
						[60.568610985028776, 2.7332442363535887],
						[60.568610966799604, 2.7332442508748196],
						[60.568610958216595, 2.7332442598619586],
						[60.568599867992496, 2.7332554487382179],
						[60.568588781786318, 2.7332637383978406],
						[60.568522083756974, 2.7333109368156645],
						[60.568522052994687, 2.7333109720703543],
						[60.568522010894242, 2.7333110534437406],
						[60.568521999556104, 2.7333110995624348],
						[60.568521999619051, 2.7333137985223956],
						[60.56845818345117, 2.7333609381000352],
						[60.568430485375643, 2.7333804354317031],
						[60.568430481891234, 2.7333804379385862],
						[60.568430477599712, 2.7333804424321206],
						[60.568430475007752, 2.7333804447655869],
						[60.568388774665387, 2.7334137442102122],
						[60.568388772880496, 2.7334137445570428],
						[60.568388768588989, 2.7334137490505479],
						[60.568388766804112, 2.7334137493973749],
						[60.568363824315703, 2.7334359005330144],
						[60.568358299966334, 2.7334358998976565],
						[60.568358246675771, 2.7334359157421564],
						[60.568358156546516, 2.7334359716813803],
						[60.568358119793139, 2.7334360135893219],
						[60.568355440855228, 2.7334415697594121],
						[60.568344280725832, 2.7334498388833319],
						[60.568344278940941, 2.7334498392301585],
						[60.568344276434296, 2.7334498433768299],
						[60.568344274649412, 2.7334498437236596],
						[60.568316577595809, 2.7334720414878642],
						[60.568252682112792, 2.733519237915631],
						[60.568194283668817, 2.7335609379259549],
						[60.568194280184379, 2.7335609404328012],
						[60.568194272408434, 2.7335609474331193],
						[60.568194268031569, 2.7335609501133722],
						[60.568172077780488, 2.7335804418194245],
						[60.568163790410722, 2.7335859331075572],
						[60.568163780764543, 2.7335859386414589],
						[60.568163766105087, 2.7335859524686494],
						[60.568163759221591, 2.7335859592955454],
						[60.568149881132349, 2.7335998359316211],
						[60.56813880993063, 2.7336054216598749],
						[60.568138802069328, 2.7336054268469527],
						[60.568138787153849, 2.7336054352345092],
						[60.568138780185002, 2.7336054402481773],
						[60.568072079793382, 2.7336554409152503],
						[60.568072078008505, 2.7336554412620635],
						[60.568072072824535, 2.7336554459289157],
						[60.568072071039644, 2.7336554462757299],
						[60.568035988557618, 2.733685931559481],
						[60.568030509116419, 2.7336887217199952],
						[60.568030500362667, 2.7336887270804398],
						[60.568030482855193, 2.7336887378013417],
						[60.568030474186791, 2.7336887449749794],
						[60.567985975868687, 2.7337248433363022],
						[60.567913794224694, 2.7337804280472806],
						[60.567908212934888, 2.733783120863055],
						[60.567908203203345, 2.7337831245836619],
						[60.567908183996316, 2.733783137464461],
						[60.567908175327922, 2.7337831446380618],
						[60.567894274580695, 2.7337942442137488],
						[60.567894271181615, 2.7337942485337328],
						[60.567894261620765, 2.7337942558807296],
						[60.567894258136327, 2.7337942583875234],
						[60.567885965917441, 2.7338026507025996],
						[60.56787207829894, 2.7338137422211997],
						[60.567805480407678, 2.7338637403804027],
						[60.567805478622788, 2.7338637407272],
						[60.567805477815689, 2.7338637427137806],
						[60.567805476923255, 2.7338637428871797],
						[60.567755477133389, 2.7339026424251927],
						[60.567755477133389, 2.7339026424251927],
						[60.567755476240954, 2.7339026425985877],
						[60.567755475433856, 2.7339026445851831],
						[60.567741579858982, 2.7339137393867476],
						[60.567713786003594, 2.733933135434881],
						[60.567713779034733, 2.733933140448408],
						[60.567713765182347, 2.7339331522886594],
						[60.567713759191278, 2.7339331589419689],
						[60.567708158947383, 2.7339387592305306],
						[60.567708158054934, 2.7339387594039284],
						[60.567691576848297, 2.7339554407552535],
						[60.567672100647002, 2.7339665255829058],
						[60.567672092871071, 2.7339665325829863],
						[60.567672076255974, 2.7339665431302169],
						[60.56767206758758, 2.7339665503037116],
						[60.567627674645244, 2.7340054433651719],
						[60.567597082795778, 2.7340276371402936],
						[60.567597081096231, 2.7340276393002521],
						[60.56759707939667, 2.7340276414602132],
						[60.567597076719352, 2.7340276419803886],
						[60.567547078658407, 2.7340665406498879],
						[60.567535980240081, 2.7340748394218237],
						[60.567535979432982, 2.7340748414083831],
						[60.56753597594853, 2.7340748439151197],
						[60.567535975056096, 2.734074844088509],
						[60.567466575139541, 2.7341304447817025],
						[60.567452675250969, 2.7341415438727936],
						[60.567452670874104, 2.7341415465528995],
						[60.567452663990572, 2.7341415533795046],
						[60.567452659699008, 2.7341415578727979],
						[60.567447072980578, 2.7341470438332576],
						[60.567438788406655, 2.7341526331005159],
						[60.567438784922224, 2.7341526356072343],
						[60.567438778845776, 2.7341526404472982],
						[60.567438776253802, 2.7341526427806246],
						[60.567385979129163, 2.7341942402945074],
						[60.567355482290338, 2.7342165396690588],
						[60.567355479613028, 2.7342165401892129],
						[60.567355477021039, 2.7342165425225442],
						[60.567355475321499, 2.7342165446824755],
						[60.567313774820342, 2.7342498436794576],
						[60.567313773120794, 2.7342498458393858],
						[60.567313769636371, 2.7342498483460793],
						[60.567313767851466, 2.7342498486928513],
						[60.567269272391847, 2.7342887461684886],
						[60.567241578659498, 2.7343109399193786],
						[60.567222084380312, 2.7343248365131485],
						[60.567222077411422, 2.734324841526512],
						[60.567222065343906, 2.7343248530196371],
						[60.567222059352794, 2.7343248596727676],
						[60.567210962801845, 2.7343359537355485],
						[60.567188788056704, 2.7343554320942869],
						[60.567177711400959, 2.7343609214551305],
						[60.567177670821451, 2.7343609586144773],
						[60.567177614611865, 2.73436104638344],
						[60.567177600044886, 2.7343611004459802],
						[60.567177600084925, 2.734363799294862],
						[60.567122079729693, 2.7344054402640441],
						[60.567122078030131, 2.7344054424239523],
						[60.567122073653252, 2.7344054451039916],
						[60.567122071868361, 2.7344054454507467],
						[60.567088773408024, 2.7344331448001187],
						[60.56706097478019, 2.7344554446650107],
						[60.567060971295753, 2.7344554471716789],
						[60.567060965304634, 2.7344554538247543],
						[60.567060960927741, 2.7344554565047834],
						[60.567058167977017, 2.7344581508546062],
						[60.567016575413021, 2.7344915435030641],
						[60.567016573713474, 2.734491545662955],
						[60.567016571036142, 2.7344915461830799],
						[60.567016570229022, 2.7344915481695997],
						[60.566997073372555, 2.7345081457175535],
						[60.566983175232231, 2.7345192441274624],
						[60.566927675030222, 2.7345637441447854],
						[60.566927670738664, 2.7345637486379255],
						[60.566927662962648, 2.7345637556377151],
						[60.566927658585762, 2.7345637583177189],
						[60.56691097308299, 2.7345804445311557],
						[60.566902690041275, 2.7345859326508366],
						[60.566902684857247, 2.7345859373173469],
						[60.566902675296348, 2.7345859446638685],
						[60.566902670919475, 2.7345859473438638],
						[60.566866571976945, 2.7346165451789339],
						[60.5668192730366, 2.7346554464045427],
						[60.566819272144166, 2.7346554465779143],
						[60.566802672280289, 2.7346692452289361],
						[60.566802668881181, 2.7346692495486922],
						[60.566802661912284, 2.7346692545619096],
						[60.566802659405624, 2.7346692587082768],
						[60.566794265423809, 2.734677651960483],
						[60.566760978389482, 2.734705441813432],
						[60.56674158374566, 2.7347192362454105],
						[60.566741576776771, 2.7347192412586048],
						[60.566741564709197, 2.734719252751403],
						[60.566741558718078, 2.7347192594043768],
						[60.566738759023444, 2.7347220593055677],
						[60.566738758130995, 2.7347220594789348],
						[60.566730462445975, 2.7347304555605367],
						[60.566677675281646, 2.7347776444167855],
						[60.566638783930102, 2.7348054377232356],
						[60.566638780445665, 2.7348054402298114],
						[60.566638774369189, 2.7348054450696258],
						[60.566638769992302, 2.7348054477495709],
						[60.566619269606129, 2.7348220474087772],
						[60.566619267099455, 2.7348220515551271],
						[60.566619261022993, 2.7348220563949157],
						[60.566619258430997, 2.7348220587281271],
						[60.566610961846301, 2.7348304549248188],
						[60.56658596983727, 2.7348526480077107],
						[60.566569271965506, 2.7348665459921948],
						[60.566569272050849, 2.7348665478053111],
						[60.566569270265944, 2.7348665481520396],
						[60.566549926088214, 2.7348830990936559],
						[60.566547199993209, 2.7348830998672886],
						[60.56654714670244, 2.7348831157083344],
						[60.566547058272008, 2.7348831694802711],
						[60.566547020625677, 2.7348832115574635],
						[60.566544239284561, 2.7348887743616674],
						[60.566485976618225, 2.7349387425397897],
						[60.566458186157028, 2.7349581362008322],
						[60.566458179995223, 2.7349581392274764],
						[60.566458169627197, 2.7349581485602315],
						[60.566458165335625, 2.7349581530532681],
						[60.5664248646384, 2.7349887535242337],
						[60.56642485265612, 2.7349887668299258],
						[60.566424831454249, 2.7349887947343632],
						[60.566424822234659, 2.7349888093330925],
						[60.566422039850643, 2.7349942735051882],
						[60.566372072184699, 2.7350359461529514],
						[60.56637206870024, 2.7350359486594877],
						[60.566372061816665, 2.7350359554856571],
						[60.56637205930997, 2.7350359596319618],
						[60.566360973059354, 2.7350470438113215],
						[60.566344288846281, 2.7350581321985059],
						[60.566344281070243, 2.7350581391980398],
						[60.566344266325281, 2.7350581512106338],
						[60.566344257656802, 2.7350581583835076],
						[60.566330464095827, 2.735072053706511],
						[60.566294271185946, 2.7351026466950272],
						[60.566294267701501, 2.7351026492015627],
						[60.566294260817898, 2.7351026560277081],
						[60.566294258225888, 2.7351026583608711],
						[60.566280468865862, 2.7351165473362511],
						[60.566269280909644, 2.7351248389906258],
						[60.566269276618058, 2.7351248434835949],
						[60.566269267864257, 2.7351248488433297],
						[60.566269263572671, 2.7351248533363188],
						[60.566233167357936, 2.735158151809931],
						[60.566191568706572, 2.7351942498824942],
						[60.566191567728808, 2.7351942482427449],
						[60.566169269595981, 2.7352137474710463],
						[60.566119271553696, 2.7352554454444573],
						[60.566119261270956, 2.7352554565901266],
						[60.566119241597946, 2.7352554787081198],
						[60.56611923310011, 2.7352554895070931],
						[60.566113744624907, 2.7352637718080572],
						[60.566108173405624, 2.7352692433366621],
						[60.566099887969145, 2.7352748340525253],
						[60.566099886269555, 2.7352748362123172],
						[60.566099881892654, 2.7352748388921468],
						[60.566099880107778, 2.7352748392388411],
						[60.566088779901939, 2.7352831393574215],
						[60.566088775610368, 2.7352831438503586],
						[60.566088766856552, 2.7352831492100163],
						[60.566088762564966, 2.7352831537029592],
						[60.566044262800915, 2.7353248538422541],
						[60.56604426199381, 2.73532485582869],
						[60.566044260294234, 2.735324857988473],
						[60.566044257616902, 2.7353248585085073],
						[60.566013766428384, 2.7353554522145838],
						[60.565983172864598, 2.7353804450182451],
						[60.565983172057479, 2.7353804470046765],
						[60.565983167680564, 2.7353804496844845],
						[60.565983165981002, 2.7353804518442746],
						[60.565955466379805, 2.7354054515187967],
						[60.565955464594929, 2.7354054518654802],
						[60.565922065419514, 2.7354359515501518],
						[60.565922062827511, 2.735435953883258],
						[60.565922059428367, 2.7354359582028138],
						[60.565922056836349, 2.7354359605359218],
						[60.565916558219975, 2.7354415587811713],
						[60.565899861271376, 2.7354581565387215],
						[60.565860968562063, 2.7354942478604576],
						[60.565833175083121, 2.7355165448699603],
						[60.565833172405782, 2.7355165453899768],
						[60.565833167221733, 2.7355165500561891],
						[60.565833164629716, 2.7355165523892837],
						[60.565799865425419, 2.7355470525406962],
						[60.565799862833394, 2.7355470548737872],
						[60.565799860241384, 2.7355470572068801],
						[60.565799859434257, 2.7355470591932947],
						[60.565797062226487, 2.7355498547909627],
						[60.565741565537785, 2.7355998504648467],
						[60.565741557847041, 2.7355998592771904],
						[60.565741541658426, 2.7355998788882836],
						[60.565741533967689, 2.7355998877006233],
						[60.565735948952934, 2.7356081666335523],
						[60.565716570310833, 2.7356248477681047],
						[60.565716567718802, 2.7356248501011886],
						[60.565716565126763, 2.7356248524342686],
						[60.565716564319651, 2.7356248544206769],
						[60.565674864150807, 2.7356637538611634],
						[60.565674862365917, 2.7356637542078288],
						[60.565674859773878, 2.7356637565409052],
						[60.565674858966744, 2.7356637585273127],
						[60.565655473444458, 2.735683145144292],
						[60.565647088879885, 2.7356887347923453],
						[60.565647080933182, 2.7356887381654129],
						[60.565647066273499, 2.7356887519905086],
						[60.565647059389875, 2.7356887588164023],
						[60.565613762888155, 2.7357220537643867],
						[60.565588767309535, 2.73574424975306],
						[60.56558876739485, 2.7357442515661456],
						[60.565563766772918, 2.7357665509579778],
						[60.565563760781728, 2.7357665576104879],
						[60.565563748714055, 2.7357665691024571],
						[60.565563743615307, 2.7357665755816538],
						[60.565552659234719, 2.7357804563598958],
						[60.565516577907147, 2.7358081407300427],
						[60.565516571023522, 2.7358081475558786],
						[60.565516557170945, 2.7358081593944545],
						[60.565516551265091, 2.7358081678600255],
						[60.565494250683791, 2.7358331665719717],
						[60.565494242185885, 2.7358331773705782],
						[60.565494227060327, 2.7358332004341959],
						[60.565494220432647, 2.7358332126992071],
						[60.565491543890772, 2.7358387640230402],
						[60.565474889346433, 2.735849833094528],
						[60.565474880592582, 2.7358498384539391],
						[60.56547486593287, 2.7358498522788857],
						[60.565474859049246, 2.7358498591046869],
						[60.565449862791276, 2.7358748543584261],
						[60.565424867183545, 2.7358970501099558],
						[60.565424866291096, 2.7358970502832811],
						[60.565424863699072, 2.7358970526163149],
						[60.565424863784386, 2.7358970544293748],
						[60.565383166116931, 2.7359359508156951],
						[60.56536377017602, 2.7359526477187046],
						[60.565363766691533, 2.7359526502250557],
						[60.565363760700343, 2.7359526568774863],
						[60.565363759000768, 2.7359526590372045],
						[60.565338759321733, 2.7359776584406741],
						[60.565338751545653, 2.7359776654397385],
						[60.565338739648574, 2.7359776805576721],
						[60.565338733742706, 2.7359776890231444],
						[60.565327655969838, 2.7359942560601764],
						[60.565302688869082, 2.7360109334335982],
						[60.565302669917749, 2.7360109517511049],
						[60.565302635670172, 2.7360109895059042],
						[60.565302621351698, 2.7360110105829247],
						[60.56529703773041, 2.7360220755694602],
						[60.565241566037805, 2.7360720500850859],
						[60.56524156433823, 2.7360720522447752],
						[60.565241560046616, 2.7360720567374854],
						[60.565241559239482, 2.7360720587238516],
						[60.565224858947204, 2.7360887588825555],
						[60.565205458695026, 2.7361081587861773],
						[60.565188772164902, 2.736124845255298],
						[60.565180488386041, 2.7361304334205045],
						[60.565180479717498, 2.7361304405928304],
						[60.565180465864884, 2.7361304524311372],
						[60.565180458895924, 2.7361304574437861],
						[60.565133159271582, 2.7361776571176706],
						[60.565133159356883, 2.7361776589307154],
						[60.565116568210293, 2.7361942481258588],
						[60.565105478621462, 2.7362026407235414],
						[60.56510546315458, 2.7362026565345343],
						[60.565105434091016, 2.7362026896228993],
						[60.565105422364553, 2.7362027083667142],
						[60.565102636611819, 2.7362081783394068],
						[60.565035970374467, 2.7362720484763297],
						[60.565024878995082, 2.7362804413715756],
						[60.565024872026129, 2.736280446384145],
						[60.565024857366367, 2.7362804602087127],
						[60.565024849590266, 2.7362804672076333],
						[60.565002655878395, 2.7363054608906361],
						[60.564944261976827, 2.7363609546307748],
						[60.564944255178467, 2.7363609632694006],
						[60.56494423979688, 2.7363609808932501],
						[60.564944232998521, 2.7363609895318759],
						[60.564938755451642, 2.7363692563912592],
						[60.564930488276097, 2.7363748339021794],
						[60.564930479522218, 2.7363748392613347],
						[60.564930465754905, 2.7363748529124892],
						[60.564930458785931, 2.736374857925044],
						[60.564883160848453, 2.7364220565117612],
						[60.564847063601086, 2.7364554527873928],
						[60.564847062793959, 2.7364554547737239],
						[60.564847060201927, 2.7364554571066879],
						[60.564847059394765, 2.7364554590930252],
						[60.564777658689728, 2.736524857614866],
						[60.564758161935202, 2.736544255575375],
						[60.564730465654513, 2.7365692525589744],
						[60.564730463869623, 2.7365692529055776],
						[60.564730459577987, 2.7365692573981328],
						[60.564730458770867, 2.736569259384471],
						[60.564666558686504, 2.7366331598217744],
						[60.564635961165905, 2.7366637568709971],
						[60.564585963151927, 2.736710955606497],
						[60.564585962259478, 2.7367109557797953],
						[60.56458595966744, 2.7367109581126994],
						[60.564585958774991, 2.7367109582859959],
						[60.564577659404279, 2.7367192588205054],
						[60.564577651628156, 2.736719265819219],
						[60.564577638838536, 2.7367192811097887],
						[60.56457763382506, 2.7367192894016394],
						[60.56457204887726, 2.7367276666038505],
						[60.564552669930585, 2.7367442476617918],
						[60.564552668230995, 2.7367442498213959],
						[60.564552663939331, 2.7367442543139213],
						[60.564552662239748, 2.7367442564735258],
						[60.564485962099369, 2.7368081565026978],
						[60.564485958529588, 2.7368081571958709],
						[60.564485954323239, 2.7368081635013852],
						[60.564485951731193, 2.7368081658342773],
						[60.564430460575828, 2.7368692542151352],
						[60.564413772193973, 2.7368831464544914],
						[60.564413768709464, 2.7368831489606653],
						[60.56441376182579, 2.7368831557860251],
						[60.564413759233737, 2.736883158118907],
						[60.564399858658305, 2.7368970597233262],
						[60.564383159141457, 2.736913758826427],
						[60.564383158249022, 2.7369137589997155],
						[60.564330463191766, 2.736966554019117],
						[60.564308168995197, 2.7369859493888917],
						[60.564308166403144, 2.7369859517217545],
						[60.564308161219046, 2.7369859563874819],
						[60.564308157734558, 2.7369859588936301],
						[60.564277659400673, 2.7370165597606113],
						[60.564244262693521, 2.7370498547790323],
						[60.564219266947745, 2.7370720505601849],
						[60.564219262656081, 2.7370720550526015],
						[60.564219254965245, 2.737072063864157],
						[60.564219249695832, 2.7370720667168618],
						[60.564197054202786, 2.7370970631800033],
						[60.56417205874299, 2.7371220598856567],
						[60.564133171976486, 2.7371609451383203],
						[60.56412488823225, 2.7371665346541305],
						[60.564124880370827, 2.7371665398396563],
						[60.564124865625644, 2.7371665518504118],
						[60.564124858741934, 2.7371665586756349],
						[60.564105459204562, 2.737185957066735],
						[60.564072061727636, 2.7372192555233532],
						[60.564041564740712, 2.7372470527922261],
						[60.564041563848257, 2.737247052965504],
						[60.564005463944227, 2.7372804533623811],
						[60.564005463051771, 2.7372804535356585],
						[60.564005459652563, 2.7372804578547343],
						[60.564005458760121, 2.7372804580280099],
						[60.563972058664326, 2.7373137586117187],
						[60.563972056072252, 2.7373137609445308],
						[60.563972051780603, 2.7373137654368769],
						[60.563972049188557, 2.7373137677696864],
						[60.563952655908324, 2.7373359603008693],
						[60.563902664592923, 2.737383151934472],
						[60.563880467964232, 2.7374026503678217],
						[60.563880466264628, 2.7374026525273427],
						[60.563880460995215, 2.7374026553799613],
						[60.563880459295618, 2.7374026575395001],
						[60.563860964096001, 2.7374220522461949],
						[60.563819268568174, 2.737458148637145],
						[60.56381925488602, 2.7374581641003362],
						[60.563819231176794, 2.7374581961465765],
						[60.563819220257301, 2.7374582129029239],
						[60.563816534677166, 2.7374637838355045],
						[60.563774860996915, 2.7375054559552519],
						[60.563735966297848, 2.7375415517247661],
						[60.563688768386704, 2.7375831490297546],
						[60.563688758910921, 2.7375831581875585],
						[60.563688740937081, 2.7375831781428985],
						[60.563688734223923, 2.7375831885938884],
						[60.563683150108332, 2.737591565236726],
						[60.563666571923349, 2.737605446328331],
						[60.563666567631678, 2.7376054508205931],
						[60.563666561555095, 2.7376054556594056],
						[60.563666559048322, 2.737605459805136],
						[60.563655462357637, 2.7376165550868929],
						[60.563622065368016, 2.7376470514907596],
						[60.563622062775934, 2.7376470538235194],
						[60.563622060183889, 2.7376470561562756],
						[60.563622059376726, 2.7376470581425019],
						[60.563594263142477, 2.7376748534893292],
						[60.563569267363086, 2.7376970501284612],
						[60.563569265663475, 2.737697052287964],
						[60.5635692630714, 2.7376970546207153],
						[60.563569262178952, 2.7376970547939772],
						[60.563513771269861, 2.7377498464954835],
						[60.563502679639619, 2.7377581396950497],
						[60.563502675347912, 2.7377581441872714],
						[60.563502665786828, 2.7377581515320268],
						[60.563502662387585, 2.7377581558509854],
						[60.563433169873029, 2.7378248475649083],
						[60.563422080022242, 2.7378331403700953],
						[60.563422066976635, 2.7378331502207938],
						[60.563422043011435, 2.7378331768274959],
						[60.563422032813762, 2.737833189784324],
						[60.563416557900936, 2.737841552427247],
						[60.563402697025545, 2.7378498288372453],
						[60.563402689164043, 2.7378498340224713],
						[60.563402670849008, 2.7378498467256556],
						[60.563402663072821, 2.7378498537238452],
						[60.563358162735554, 2.7378915539317741],
						[60.563358161928377, 2.7378915559179795],
						[60.563358160143494, 2.7378915562644899],
						[60.563358159336317, 2.7378915582506971],
						[60.563324864251662, 2.7379248535489236],
						[60.563244270138533, 2.7379942485159132],
						[60.563244262362325, 2.7379942555140295],
						[60.563244246002739, 2.7379942714964489],
						[60.563244239204273, 2.7379942801342589],
						[60.563235955457856, 2.7380054602233357],
						[60.563222075296643, 2.7380165430510637],
						[60.563208175290264, 2.7380276443573024],
						[60.563208159737847, 2.7380276583534915],
						[60.5632081316515, 2.7380276930779015],
						[60.563208119924759, 2.738027711819925],
						[60.563205445646176, 2.7380331596519625],
						[60.563191597223586, 2.7380415286346191],
						[60.56319158766248, 2.7380415359791992],
						[60.563191571047035, 2.7380415465227443],
						[60.56319156327082, 2.7380415535208247],
						[60.563144266431557, 2.7380859511899782],
						[60.563085970012686, 2.7381359472527107],
						[60.56308596661345, 2.7381359515715715],
						[60.563085961429323, 2.7381359562369219],
						[60.563085958837235, 2.7381359585696194],
						[60.563058159173053, 2.7381637591439678],
						[60.563049868197808, 2.7381720480967449],
						[60.563013778197217, 2.7381998425088825],
						[60.563013777304754, 2.738199842682127],
						[60.5630137746274, 2.7381998432018633],
						[60.563013772927782, 2.7381998453612866],
						[60.562983173015077, 2.738224845097291],
						[60.562983170422996, 2.7382248474299526],
						[60.562983161754332, 2.7382248546012002],
						[60.562983158355095, 2.7382248589200366],
						[60.562966563833818, 2.7382415526161252],
						[60.562930471179854, 2.7382720477054807],
						[60.562930470287405, 2.738272047878723],
						[60.562930469394956, 2.7382720480519644],
						[60.562888769396395, 2.7383081485509049],
						[60.562888765911858, 2.7383081510567973],
						[60.562888760727709, 2.7383081557220943],
						[60.562888759113356, 2.7383081596944447],
						[60.562877664055712, 2.7383192522952973],
						[60.562799872393796, 2.7383859460931537],
						[60.562785976643454, 2.738397042593967],
						[60.562774879117882, 2.7384054407869081],
						[60.562774878310712, 2.7384054427730651],
						[60.56277487384844, 2.738405443639254],
						[60.56277487304127, 2.7384054456254092],
						[60.562758172986321, 2.7384192452430454],
						[60.562758168694621, 2.7384192497350677],
						[60.562758161725569, 2.7384192547468023],
						[60.562758159218774, 2.7384192588923639],
						[60.562733167768755, 2.7384442489157985],
						[60.562697077702119, 2.7384720427329658],
						[60.562697075917221, 2.7384720430794349],
						[60.562697071540242, 2.7384720457585265],
						[60.562697070733059, 2.7384720477446782],
						[60.56266097119191, 2.7385026460820567],
						[60.562624876769291, 2.7385331427331718],
						[60.562605483847577, 2.7385470368273439],
						[60.562605478663407, 2.7385470414925774],
						[60.562605470801891, 2.7385470466774917],
						[60.562605467317354, 2.7385470491833321],
						[60.562580467164651, 2.7385692507346668],
						[60.562580465379746, 2.7385692510811315],
						[60.562580461173326, 2.7385692573860325],
						[60.562580459473701, 2.7385692595454034],
						[60.562572065298077, 2.7385776524471925],
						[60.562538771592891, 2.7386054465200118],
						[60.562538770700442, 2.7386054466932412],
						[60.562538769893244, 2.7386054486793814],
						[60.56251927440357, 2.7386220445872804],
						[60.56247207776763, 2.7386581408133734],
						[60.562472076067991, 2.7386581429727332],
						[60.562472070883828, 2.7386581476379117],
						[60.562472068291754, 2.7386581499705147],
						[60.562427677859077, 2.7386970401502118],
						[60.562410989169109, 2.7387081333129917],
						[60.562410984877403, 2.7387081378049207],
						[60.562410976123438, 2.738708143163004],
						[60.562410970939261, 2.7387081478281661],
						[60.562358170661064, 2.7387526474398092],
						[60.562358169768615, 2.7387526476130328],
						[60.562338782034281, 2.738769237049937],
						[60.562324896841154, 2.7387776290639234],
						[60.562324887279978, 2.7387776364080794],
						[60.562324868157667, 2.7387776510963997],
						[60.562324858596504, 2.738777658440569],
						[60.56231376625675, 2.738788751970183],
						[60.562252691368975, 2.7388387303134056],
						[60.562247110990036, 2.7388415203183945],
						[60.562247102236057, 2.7388415256763974],
						[60.562247083835622, 2.738841536565642],
						[60.562247076059364, 2.738841543563312],
						[60.562180476048979, 2.738894242414649],
						[60.562180474349354, 2.7388942445739746],
						[60.562180470864803, 2.7388942470797444],
						[60.562180469972354, 2.7388942472529645],
						[60.562160978153244, 2.7389109408605821],
						[60.562124886104492, 2.7389359367827328],
						[60.562124881642227, 2.7389359376488254],
						[60.56212487475841, 2.7389359444732286],
						[60.562124872166322, 2.7389359468057832],
						[60.562091572382947, 2.738963746751867],
						[60.562091545188785, 2.7389637813009986],
						[60.562091509161164, 2.7389638578122208],
						[60.562091500327725, 2.7389638997743053],
						[60.56209150012581, 2.7389664994419265],
						[60.562088900076972, 2.7389664991647562],
						[60.562088865271271, 2.7389665059202417],
						[60.562088800633802, 2.7389665294424321],
						[60.56208876999483, 2.7389665481952381],
						[60.56206927364579, 2.7389831456261362],
						[60.562033178178424, 2.7390109410833166],
						[60.562033177285983, 2.7390109412565287],
						[60.562033175586343, 2.7390109434158592],
						[60.561980475917316, 2.739052643527109],
						[60.561927680382105, 2.7390942395295852],
						[60.561888784115943, 2.7391220368105498],
						[60.561888782416297, 2.7391220389698461],
						[60.561888778931753, 2.7391220414755644],
						[60.561888777146855, 2.7391220418219855],
						[60.561863776861422, 2.7391415421838268],
						[60.561863775968966, 2.7391415423570376],
						[60.561863774269334, 2.7391415445163263],
						[60.561863773376878, 2.7391415446895384],
						[60.561802679468293, 2.7391915417516048],
						[60.561763783757868, 2.7392192362399737],
						[60.561744283953047, 2.7392331374460719],
						[60.561744281360959, 2.7392331397785581],
						[60.561744275284333, 2.7392331446167373],
						[60.561744271714502, 2.739233145309572],
						[60.561710977128399, 2.7392609417492499],
						[60.561649882011125, 2.7393054375219466],
						[60.561649875934485, 2.7393054423600987],
						[60.561649863866442, 2.7393054538492834],
						[60.56164985868228, 2.7393054585142274],
						[60.561641570302939, 2.7393137462750379],
						[60.56162208442472, 2.7393276373448354],
						[60.561602683629211, 2.7393415374961196],
						[60.561602678444991, 2.7393415421610583],
						[60.561602667184154, 2.7393415516641504],
						[60.561602661107507, 2.7393415565022874],
						[60.561599870550879, 2.7393442458059352],
						[60.56153048273579, 2.7393942381274843],
						[60.561530480950886, 2.739394238473885],
						[60.561530478444062, 2.7393942426191948],
						[60.561530475766709, 2.7393942431387996],
						[60.561491578777193, 2.7394248406300528],
						[60.56146098290214, 2.7394470381365417],
						[60.561460980310038, 2.7394470404689861],
						[60.561460975040568, 2.7394470433210367],
						[60.561460971556016, 2.739447045826684],
						[60.561444280356859, 2.7394609395243856],
						[60.561435987745121, 2.7394665333062171],
						[60.561435986937944, 2.7394665352922614],
						[60.561435985238312, 2.7394665374515057],
						[60.561435984345856, 2.7394665376247045],
						[60.56141658442565, 2.7394804374332966],
						[60.561416582640732, 2.7394804377796955],
						[60.561416580855806, 2.7394804381260953],
						[60.561416580048636, 2.7394804401121378],
						[60.561405481408585, 2.7394887389654157],
						[60.561352680601424, 2.7395276397615702],
						[60.561352679708975, 2.7395276399347659],
						[60.561352677116872, 2.7395276422672175],
						[60.56135267541724, 2.7395276444264538],
						[60.561324877616897, 2.73954984021471],
						[60.561272084650277, 2.7395887356224771],
						[60.561263790190594, 2.7395942327391207],
						[60.561263789383396, 2.739594234725153],
						[60.561263786706057, 2.7395942352447333],
						[60.561263785813594, 2.7395942354179303],
						[60.561191588361105, 2.7396442340303042],
						[60.561183188855978, 2.7396498338250037],
						[60.561183187071059, 2.7396498341713893],
						[60.561183181886847, 2.7396498388362311],
						[60.561183180101942, 2.7396498391826123],
						[60.561172079749788, 2.7396581400726689],
						[60.561172078050127, 2.739658142231888],
						[60.561172071888222, 2.7396581452570623],
						[60.561172070188547, 2.7396581474162858],
						[60.561152674773822, 2.7396748432327436],
						[60.561108179572336, 2.7397081398934713],
						[60.561085984270164, 2.7397248374932315],
						[60.561035989123454, 2.7397581347933038],
						[60.561035987338556, 2.7397581351396791],
						[60.561035983853991, 2.7397581376452762],
						[60.561035982154337, 2.7397581398044775],
						[60.560994289478145, 2.7397887337082927],
						[60.560966596825466, 2.7398054296302843],
						[60.560966590663547, 2.739805432655412],
						[60.56096658020985, 2.7398054401721352],
						[60.560966575025638, 2.7398054448369011],
						[60.560938778148596, 2.7398276417428375],
						[60.560905532860488, 2.7398526008666066],
						[60.560902800425872, 2.7398526005909085],
						[60.560902770889591, 2.7398526044931888],
						[60.560902716705584, 2.7398526204959359],
						[60.560902690272947, 2.7398526329427559],
						[60.560894289637936, 2.739858132231189],
						[60.560894288830731, 2.7398581342171919],
						[60.560894285346194, 2.7398581367227446],
						[60.560894283561282, 2.7398581370691124],
						[60.560855488976998, 2.7398859346582807],
						[60.560833194308316, 2.7398998303957942],
						[60.560833191716199, 2.7398998327281596],
						[60.560833185554266, 2.7398998357532558],
						[60.560833183854633, 2.7398998379124349],
						[60.560813783880782, 2.7399137371819169],
						[60.560813782988333, 2.7399137373550988],
						[60.56081378209587, 2.7399137375282807],
						[60.56081378218115, 2.7399137393410928],
						[60.560772082351782, 2.7399442390713582],
						[60.560772076189842, 2.7399442420964411],
						[60.560772064121764, 2.7399442535850538],
						[60.56077205893753, 2.7399442582497668],
						[60.56076927297071, 2.7399470453205219],
						[60.560744289013471, 2.7399637348916013],
						[60.560744288121029, 2.7399637350647787],
						[60.560744284551213, 2.7399637357574989],
						[60.560744283744015, 2.739963737743492],
						[60.560724886354379, 2.7399776346011153],
						[60.560699889119903, 2.7399942334021472],
						[60.560699889119903, 2.7399942334021472],
						[60.560633188767227, 2.7400387345945734],
						[60.560633180905647, 2.7400387397787789],
						[60.560633166160187, 2.7400387517868396],
						[60.560633159276321, 2.7400387586106723],
						[60.560627678357527, 2.7400442382154839],
						[60.560602703081699, 2.7400581252109761],
						[60.560602696027303, 2.7400581284091965],
						[60.560602683788694, 2.7400581362720953],
						[60.56060267779727, 2.7400581429227464],
						[60.560566581343942, 2.7400859396284614],
						[60.560547090100663, 2.7400998325870751],
						[60.560519296593242, 2.7401165292576177],
						[60.560519295700786, 2.7401165294307912],
						[60.560519294808323, 2.7401165296039669],
						[60.560519293915874, 2.7401165297771417],
						[60.560497094034346, 2.7401304305811749],
						[60.560497093141898, 2.7401304307543475],
						[60.560497089657332, 2.7401304332598428],
						[60.560497088764869, 2.7401304334330168],
						[60.56045548873589, 2.7401581344169585],
						[60.560455487843427, 2.7401581345901325],
						[60.560455485166074, 2.7401581351096467],
						[60.560455484358862, 2.7401581370956185],
						[60.560436007937305, 2.7401720197641026],
						[60.560427735675766, 2.7401748103300663],
						[60.560427722459437, 2.7401748165532585],
						[60.560427695134315, 2.7401748291727817],
						[60.560427682003244, 2.7401748372087655],
						[60.560366589230561, 2.740219233704098],
						[60.560352696958439, 2.7402276295468666],
						[60.560352696065969, 2.7402276297200365],
						[60.56035269517352, 2.7402276298932091],
						[60.560294295017137, 2.7402637308791933],
						[60.560294292339755, 2.7402637313987035],
						[60.560294286177843, 2.7402637344236709],
						[60.560294283585712, 2.7402637367559661],
						[60.560274896697685, 2.7402776275215452],
						[60.560263811076958, 2.7402831207158442],
						[60.560263803130113, 2.7402831240871599],
						[60.560263787406903, 2.7402831344553316],
						[60.560263779545309, 2.7402831396394314],
						[60.560241585857263, 2.7402998342172276],
						[60.560188794226882, 2.7403331307835415],
						[60.560166595125196, 2.740347029297995],
						[60.56011099668622, 2.7403804294433214],
						[60.560110991416728, 2.7403804322951046],
						[60.560110980963024, 2.7403804398114064],
						[60.560110974886307, 2.7403804446491153],
						[60.560097091987011, 2.7403915294587144],
						[60.560091612319674, 2.740394220778692],
						[60.560091607050197, 2.7403942236304681],
						[60.560091599188588, 2.7403942288144938],
						[60.560091593833846, 2.7403942298534831],
						[60.560069293916804, 2.7404081295469576],
						[60.560069293109592, 2.740408131532897],
						[60.560069289539761, 2.74040813222555],
						[60.560069287840115, 2.7404081343846505],
						[60.560060990888992, 2.7404137321573403],
						[60.56000819833158, 2.7404470285081373],
						[60.559983202733498, 2.7404609243200859],
						[60.559983200141346, 2.7404609266523443],
						[60.559983194871883, 2.7404609295040796],
						[60.559983193172229, 2.740460931663188],
						[60.559922100967569, 2.7404998260935898],
						[60.559916609114794, 2.7405026221840258],
						[60.559916605544977, 2.7405026228766634],
						[60.559916601167963, 2.7405026255552429],
						[60.559916597683355, 2.7405026280606508],
						[60.559855498362509, 2.740538728523267],
						[60.55985548782354, 2.7405387342267091],
						[60.559855468700988, 2.7405387489127877],
						[60.559855460032153, 2.7405387560826604],
						[60.559849878452233, 2.7405442403554492],
						[60.559830500377657, 2.7405554271473118],
						[60.559758211832161, 2.7405970203680825],
						[60.55974432466968, 2.7406026142209599],
						[60.559744307076272, 2.7406026231223488],
						[60.55974427393015, 2.7406026460171198],
						[60.559744259269863, 2.7406026598373274],
						[60.559738772332508, 2.7406081458381726],
						[60.55973049279411, 2.74061373097059],
						[60.559649901185686, 2.740660926389189],
						[60.559624902967855, 2.7406748241980541],
						[60.559624902160657, 2.7406748261839735],
						[60.559624901268208, 2.7406748263571261],
						[60.559605508123035, 2.7406859226125833],
						[60.559569316409998, 2.7407026177286045],
						[60.559569306763457, 2.7407026232587874],
						[60.5595692892553, 2.7407026339728167],
						[60.559569279608759, 2.7407026395030014],
						[60.559558191417068, 2.7407109317241112],
						[60.559483206555669, 2.7407526229316703],
						[60.559466610564996, 2.7407609208904442],
						[60.559466607080395, 2.7407609233957877],
						[60.559466599133536, 2.740760926766876],
						[60.559466594756501, 2.7407609294453668],
						[60.559430499659669, 2.7407831264207743],
						[60.559399907058065, 2.7407998234454576],
						[60.559394317789085, 2.7408026163817185],
						[60.559380526097883, 2.7408081147196426],
						[60.559380480076307, 2.7408081510875761],
						[60.5593804168928, 2.7408082438342141],
						[60.559380399730841, 2.7408083002129193],
						[60.559380399966393, 2.7408109672553311],
						[60.559361022399443, 2.7408192157852884],
						[60.559361017129909, 2.740819218636914],
						[60.55936100918305, 2.740819222007973],
						[60.559361004806014, 2.7408192246864442],
						[60.559288805721096, 2.7408581248793764],
						[60.559241605590664, 2.7408831236473015],
						[60.559241604698187, 2.7408831238204532],
						[60.559241603890989, 2.7408831258063242],
						[60.559241602913275, 2.7408831241667411],
						[60.559216605644217, 2.7408970232496186],
						[60.559199917814674, 2.7409054175316538],
						[60.559172125721467, 2.7409165148938568],
						[60.559172121259174, 2.7409165157595754],
						[60.559172111612632, 2.7409165212896016],
						[60.559172107150346, 2.7409165221553171],
						[60.559119308031121, 2.7409442222129043],
						[60.559108210277977, 2.7409498215743042],
						[60.55907491054348, 2.7409665202806415],
						[60.559036010937866, 2.7409859205837597],
						[60.559036007453287, 2.7409859230890397],
						[60.559036001291318, 2.7409859261137401],
						[60.55903599691429, 2.7409859287921758],
						[60.559022102656996, 2.7409942255208852],
						[60.559005514734856, 2.7410026186050667],
						[60.558947120947494, 2.7410276153517468],
						[60.55894711308585, 2.7410276205354203],
						[60.558947097277347, 2.7410276290900724],
						[60.558947089415717, 2.7410276342737458],
						[60.558930503977621, 2.7410387231549009],
						[60.558886020317352, 2.7410581163488525],
						[60.558886017725186, 2.7410581186809959],
						[60.558886013262921, 2.7410581195466808],
						[60.55888600969309, 2.7410581202392268],
						[60.558852710026457, 2.7410748205185524],
						[60.558841614943653, 2.7410804192641867],
						[60.558772119664788, 2.7411109169079273],
						[60.55877211698742, 2.7411109174273327],
						[60.558772112610363, 2.7411109201057133],
						[60.558772110825451, 2.7411109204519821],
						[60.558749910697919, 2.7411220210359653],
						[60.55872771673117, 2.7411331185791554],
						[60.558680524386979, 2.7411526144215723],
						[60.558680520902385, 2.7411526169268021],
						[60.558680514740416, 2.7411526199514351],
						[60.558680512063049, 2.7411526204708347],
						[60.558669323804097, 2.7411581146725461],
						[60.55866103642844, 2.7411609095628524],
						[60.558661015265173, 2.7411609191561515],
						[60.558660976849424, 2.7411609449010128],
						[60.558660958619249, 2.7411609594130093],
						[60.55865818630501, 2.7411637307280148],
						[60.558572123387229, 2.7411998163554245],
						[60.558572119817384, 2.7411998170479497],
						[60.55857211365543, 2.7411998200725618],
						[60.558572110978062, 2.7411998205919503],
						[60.558544318076287, 2.7412137163265049],
						[60.55850272464351, 2.7412304136197436],
						[60.55850272472874, 2.7412304154324341],
						[60.558502722943835, 2.7412304157786931],
						[60.558502722051372, 2.7412304159518226],
						[60.558483221677221, 2.7412387152307494],
						[60.558430524167676, 2.7412609158529238],
						[60.558402738197039, 2.741272009581615],
						[60.558391650935448, 2.7412748061150873],
						[60.55839164379578, 2.7412748075001123],
						[60.558391628709238, 2.741274812255968],
						[60.558391621654806, 2.741274815453691],
						[60.558319322197789, 2.7413054153230023],
						[60.558319318713188, 2.7413054178282072],
						[60.558319313443697, 2.7413054206796432],
						[60.558319310766315, 2.7413054211990211],
						[60.558302752595623, 2.741313700219751],
						[60.558297200386249, 2.7413136998733583],
						[60.55829717977447, 2.741313702042564],
						[60.558297141398754, 2.7413137094869917],
						[60.558297120957469, 2.7413137152815872],
						[60.558277720576839, 2.7413220151726145],
						[60.558238831640701, 2.7413387125124484],
						[60.558211042870759, 2.7413470079469522],
						[60.558211019922545, 2.74134701788623],
						[60.558210977851687, 2.741347042510287],
						[60.558210958814243, 2.7413470590077789],
						[60.558208188280169, 2.7413498299198271],
						[60.55812493122729, 2.7413831111017295],
						[60.558108236191458, 2.7413887093516967],
						[60.558108230029504, 2.7413887123762195],
						[60.558108216813089, 2.7413887185984156],
						[60.558108210651135, 2.7413887216229398],
						[60.558097119702481, 2.7413942168761074],
						[60.558052736895213, 2.7414109096549737],
						[60.558033244567042, 2.7414165072291006],
						[60.558033240104734, 2.7414165080947099],
						[60.558033228673253, 2.7414165139706337],
						[60.558033224210966, 2.7414165148362408],
						[60.557986029885569, 2.7414359113826414],
						[60.55794433728154, 2.7414498103273148],
						[60.557944331926784, 2.7414498113660377],
						[60.557944323087447, 2.7414498149099087],
						[60.557944318710383, 2.7414498175881681],
						[60.557919325632049, 2.741460914292194],
						[60.557880538177294, 2.7414748093630741],
						[60.557852743233163, 2.7414831075946289],
						[60.557852735286268, 2.7414831109653481],
						[60.557852717607553, 2.7414831180530261],
						[60.557852711445598, 2.7414831210775228],
						[60.557830523516024, 2.7414942131913271],
						[60.5577693394617, 2.7415137092690567],
						[60.557769316513493, 2.741513719208057],
						[60.557769275420291, 2.7415137454710794],
						[60.557769256297597, 2.7415137601555628],
						[60.55776659166596, 2.7415165232741061],
						[60.557738842955445, 2.7415248088072892],
						[60.557738834030857, 2.7415248105384524],
						[60.557738818137068, 2.7415248172798448],
						[60.557738810190173, 2.7415248206505578],
						[60.557727723836685, 2.7415304136698269],
						[60.557674940153149, 2.7415470088561413],
						[60.557674932206275, 2.7415470122268224],
						[60.557674917204935, 2.7415470187950706],
						[60.557674910065266, 2.7415470201799956],
						[60.55766382290328, 2.7415526151650722],
						[60.557605536709843, 2.7415720098519452],
						[60.557605533225242, 2.7415720123570591],
						[60.557605525278348, 2.7415720157277268],
						[60.557605520816054, 2.741572016593298],
						[60.557586028585497, 2.7415804125938377],
						[60.557552737457783, 2.741591510812087],
						[60.557536036829298, 2.7415970093155475],
						[60.557494337061648, 2.7416109092090459],
						[60.55744433668611, 2.7416276099322081],
						[60.557444334901191, 2.7416276102784307],
						[60.557444329631672, 2.741627613129729],
						[60.557444327846746, 2.7416276134759561],
						[60.557408235065971, 2.7416415103863354],
						[60.557380542784585, 2.7416498085332304],
						[60.557380540107211, 2.7416498090525625],
						[60.557380534837691, 2.7416498119038515],
						[60.557380533052779, 2.7416498122500719],
						[60.557341665118592, 2.7416637004792119],
						[60.557338900296863, 2.7416637008259555],
						[60.557338882362465, 2.7416637024755199],
						[60.557338846578865, 2.7416637075872852],
						[60.557338829707376, 2.7416637126889998],
						[60.557316633790741, 2.7416720110708308],
						[60.557230538725101, 2.7416998101047048],
						[60.557230516669314, 2.7416998198702647],
						[60.557230477275752, 2.7416998439737341],
						[60.557230459045499, 2.7416998584847359],
						[60.557227696193422, 2.7417026204692676],
						[60.557197147582592, 2.7417109073320467],
						[60.557197142227835, 2.741710908370691],
						[60.557197130711096, 2.7417109124337062],
						[60.557197125356353, 2.7417109134723483],
						[60.557155561118655, 2.7417275999888755],
						[60.557152799866635, 2.7417275996367474],
						[60.557152783717157, 2.741727600940048],
						[60.557152753373558, 2.7417276068256742],
						[60.557152737309274, 2.7417276099415999],
						[60.557136043897607, 2.7417331087220722],
						[60.557124951266758, 2.7417359060002324],
						[60.557124925641141, 2.7417359164581723],
						[60.557124880000337, 2.7417359417732396],
						[60.557124859177932, 2.7417359586161214],
						[60.557122091140634, 2.7417387252518899],
						[60.55704994096962, 2.7417637093505856],
						[60.557019347815398, 2.7417720065677487],
						[60.557019344245575, 2.7417720072601659],
						[60.557019336298666, 2.7417720106307333],
						[60.557019331836358, 2.7417720114962592],
						[60.556958237061608, 2.741794210897484],
						[60.556930542314213, 2.7418026098530022],
						[60.55693053695947, 2.7418026108916189],
						[60.556930527227642, 2.7418026146083729],
						[60.556930521872879, 2.7418026156469941],
						[60.556911031267155, 2.7418109122164873],
						[60.556866640338498, 2.7418248085286425],
						[60.55686663774636, 2.7418248108605479],
						[60.556866634176536, 2.7418248115529522],
						[60.556866632391618, 2.7418248118991579],
						[60.556805536626747, 2.74184700943895],
						[60.556777747228779, 2.7418554065386815],
						[60.556766652669054, 2.7418581053369024],
						[60.556766649184439, 2.7418581078419009],
						[60.556766641152301, 2.7418581093998049],
						[60.556766636690007, 2.741858110265305],
						[60.556708237441008, 2.7418776098212834],
						[60.556708230386555, 2.7418776130186711],
						[60.556708217084903, 2.7418776174277526],
						[60.556708210922906, 2.7418776204520401],
						[60.556697129678966, 2.7418831115543187],
						[60.556686051322664, 2.7418859059643488],
						[60.556686045967908, 2.7418859070029442],
						[60.556686037128557, 2.7418859105465194],
						[60.556686032666242, 2.7418859114120195],
						[60.556647132909525, 2.7418998124907317],
						[60.556599946645129, 2.7419165078468195],
						[60.556586061931554, 2.7419192045228553],
						[60.556586053006953, 2.7419192062538276],
						[60.556586034350538, 2.7419192117014592],
						[60.556586024533487, 2.7419192136055308],
						[60.556572130412199, 2.741924813083092],
						[60.556508234739901, 2.7419470118294496],
						[60.556483267357578, 2.7419553991677876],
						[60.556480599858475, 2.7419554007291205],
						[60.556480582816505, 2.7419554022053605],
						[60.55648054890306, 2.7419554087829949],
						[60.55648053194632, 2.7419554120718139],
						[60.556419331858287, 2.7419776128216844],
						[60.55638056476861, 2.7419915007262525],
						[60.556372200008042, 2.7419915006678939],
						[60.556372182073616, 2.7419915023171844],
						[60.556372147182458, 2.7419915072552752],
						[60.556372130310969, 2.7419915123566287],
						[60.556327731364981, 2.7420081112793691],
						[60.556266634974783, 2.7420304105841256],
						[60.556258242163281, 2.742033108388013],
						[60.556238845420026, 2.7420387086141753],
						[60.556238842657422, 2.7420387073208841],
						[60.556238840957725, 2.7420387094796355],
						[60.556238840065262, 2.7420387096527277],
						[60.556186039643507, 2.7420554087564191],
						[60.556186034373965, 2.7420554116075286],
						[60.556186023749682, 2.7420554154971795],
						[60.556186019287388, 2.7420554163626321],
						[60.556161040334111, 2.7420665073319106],
						[60.556147161948076, 2.7420692044964032],
						[60.556147153915937, 2.7420692060542149],
						[60.556147137851667, 2.7420692091698355],
						[60.556147129904751, 2.742069212540208],
						[60.556102732955182, 2.7420859114323219],
						[60.556061036684156, 2.7420998092754951],
						[60.556061035791693, 2.7420998094485856],
						[60.556052736064039, 2.7421026098802499],
						[60.556052731686947, 2.7421026125582526],
						[60.556052724632515, 2.742102615755512],
						[60.556052721955112, 2.7421026162747815],
						[60.55603323132361, 2.7421109124065643],
						[60.555997141055585, 2.7421220090769576],
						[60.55599713659327, 2.7421220099424004],
						[60.555997130431273, 2.7421220129665653],
						[60.555997125968979, 2.7421220138320024],
						[60.555969333822318, 2.7421331106973144],
						[60.555941641721148, 2.7421415085353424],
						[60.555941639043752, 2.742141509054604],
						[60.555941633774225, 2.7421415119056745],
						[60.555941631989313, 2.7421415122518447],
						[60.555880536235087, 2.7421637102657512],
						[60.555836040068229, 2.7421776095034298],
						[60.555836037390854, 2.7421776100226816],
						[60.555836029443945, 2.7421776133929932],
						[60.555836025874086, 2.7421776140853331],
						[60.555808225774065, 2.7421887142095733],
						[60.55580822228945, 2.7421887167144678],
						[60.555808213450078, 2.7421887202578534],
						[60.555808208987784, 2.7421887211232763],
						[60.555802733874927, 2.7421915096161591],
						[60.555788861646803, 2.7421942036636207],
						[60.555788853614644, 2.7421942052213817],
						[60.555788839420522, 2.7421942098032841],
						[60.555788831388377, 2.7421942113610434],
						[60.555758230649431, 2.7422054119728996],
						[60.555727734147027, 2.7422165118669768],
						[60.555702737319962, 2.7422248093527664],
						[60.555702735620265, 2.7422248115114685],
						[60.555702732942883, 2.7422248120307149],
						[60.555702731157957, 2.7422248123768824],
						[60.555619335349036, 2.7422554118376343],
						[60.555611037983923, 2.7422581092977212],
						[60.555611036284247, 2.7422581114564113],
						[60.555611030929491, 2.7422581124948966],
						[60.555611029144565, 2.7422581128410597],
						[60.555588836970642, 2.7422665101626311],
						[60.555569346234769, 2.742272007616493],
						[60.555569342664946, 2.7422720083088139],
						[60.555569336417712, 2.7422720095203794],
						[60.555569332847881, 2.7422720102127021],
						[60.555522142225513, 2.7422887088650483],
						[60.555511051361897, 2.742291505433335],
						[60.555511046899589, 2.7422915062987334],
						[60.555511038952666, 2.7422915096689739],
						[60.555511035382843, 2.7422915103612904],
						[60.555430534661035, 2.7423192104518903],
						[60.555430529391472, 2.7423192133029004],
						[60.555430516982248, 2.7423192175385158],
						[60.555430510820258, 2.7423192205625848],
						[60.55541931842977, 2.7423248187861504],
						[60.555405531169384, 2.742330411761301],
						[60.555363837399675, 2.7423442096747013],
						[60.555363833022611, 2.7423442123526187],
						[60.555363825075673, 2.7423442157228313],
						[60.555363820613394, 2.7423442165882133],
						[60.555344331970964, 2.7423526125805808],
						[60.555297143753272, 2.742366508415186],
						[60.555297140183448, 2.74236650910749],
						[60.555297133043752, 2.7423665104921002],
						[60.555297129559136, 2.7423665129969339],
						[60.555252735902421, 2.7423831097258704],
						[60.555224942078091, 2.7423915085590029],
						[60.555224940293172, 2.7423915089051505],
						[60.555224935023602, 2.7423915117561117],
						[60.555224933238691, 2.7423915121022624],
						[60.55518603340736, 2.7424054118389631],
						[60.555186031622419, 2.7424054121851156],
						[60.555186030729956, 2.74240541235819],
						[60.555186029837508, 2.7424054125312631],
						[60.555163833048717, 2.7424137117483647],
						[60.555138837181758, 2.7424220105502082],
						[60.55508053790043, 2.7424415104681872],
						[60.555072138829665, 2.7424442092593808],
						[60.555072137044746, 2.7424442096055262],
						[60.555072134367371, 2.7424442101247419],
						[60.555072132667654, 2.7424442122833894],
						[60.555033264701436, 2.7424581002118757],
						[60.555030599793632, 2.7424580993925902],
						[60.555030581944429, 2.7424581028540227],
						[60.555030547053235, 2.7424581077913155],
						[60.555030530181689, 2.7424581128921801],
						[60.554986044765286, 2.7424748076051366],
						[60.55497216115063, 2.7424776042444519],
						[60.554972152226043, 2.7424776059751537],
						[60.554972137139416, 2.7424776107298729],
						[60.554972129107277, 2.7424776122875061],
						[60.554913836899196, 2.7424998102488227],
						[60.554894344588853, 2.7425054083214153],
						[60.55489434369639, 2.7425054084944844],
						[60.554894342803934, 2.7425054086675518],
						[60.554866643389424, 2.7425137084966007],
						[60.554838843081114, 2.742522007756258],
						[60.554838837811559, 2.7425220106071633],
						[60.55483882807971, 2.7425220143234186],
						[60.554838822724953, 2.742522015361831],
						[60.554805531653855, 2.742535911687523],
						[60.55476104167797, 2.7425498086502915],
						[60.554713843698167, 2.7425637076112874],
						[60.554713841998449, 2.7425637097699211],
						[60.554713839321074, 2.7425637102891196],
						[60.554713836643685, 2.7425637108083207],
						[60.554688838978343, 2.7425720097038981],
						[60.554652740707468, 2.7425831086778989],
						[60.554652739815019, 2.7425831088509622],
						[60.5546527380301, 2.7425831091970934],
						[60.554652737137637, 2.7425831093701594],
						[60.554644336587657, 2.7425859107925632],
						[60.554644334802717, 2.7425859111386952],
						[60.554644332125342, 2.7425859116578906],
						[60.554644330425639, 2.7425859138164981],
						[60.554622137105589, 2.7425942102435732],
						[60.554602744789896, 2.7425998089252128],
						[60.554602742919755, 2.7425998074588676],
						[60.554602739435126, 2.7425998099636022],
						[60.554602736757744, 2.742599810482794],
						[60.554536048179976, 2.7426220064868758],
						[60.554522160990494, 2.7426248037045831],
						[60.554522153936013, 2.7426248069015813],
						[60.554522141441574, 2.7426248093244658],
						[60.554522134301877, 2.7426248107089748],
						[60.554466665789107, 2.7426441998417825],
						[60.554461100102863, 2.7426442000098814],
						[60.554461076813666, 2.7426442026970332],
						[60.554461031383362, 2.7426442133356899],
						[60.554461009242274, 2.7426442212871871],
						[60.554455522685011, 2.7426470154479561],
						[60.554447168200184, 2.7426498005844611],
						[60.554444399721653, 2.7426497997114025],
						[60.554444383572132, 2.7426498010140574],
						[60.554444352250783, 2.7426498052587669],
						[60.554444337164163, 2.7426498100132917],
						[60.554377737058033, 2.7426720105754341],
						[60.554377730003544, 2.7426720137723875],
						[60.554377716701858, 2.7426720181807851],
						[60.554377711432302, 2.7426720210316131],
						[60.554372137197724, 2.7426748084044701],
						[60.554355566993401, 2.7426776027640924],
						[60.554355558961241, 2.7426776043216425],
						[60.554355542089702, 2.7426776094222585],
						[60.554355534057549, 2.7426776109798068],
						[60.554291647216218, 2.7426998065353292],
						[60.554263861229856, 2.7427054030958971],
						[60.554263854982636, 2.7427054043073107],
						[60.554263844358324, 2.7427054081964788],
						[60.55426383811109, 2.7427054094078964],
						[60.55425553822586, 2.7427081107625755],
						[60.554255534656001, 2.7427081114548155],
						[60.554255528408781, 2.74270811266623],
						[60.554255524838929, 2.7427081133584688],
						[60.554241634241293, 2.742713711341263],
						[60.554213843731581, 2.7427220083324557],
						[60.554174947256726, 2.742733106335896],
						[60.554133249110222, 2.7427442062052441],
						[60.554133247325296, 2.7427442065513592],
						[60.55413324473313, 2.7427442088829803],
						[60.554133243755466, 2.7427442072435881],
						[60.554086044045604, 2.7427581077541698],
						[60.554086042260685, 2.7427581081002823],
						[60.5540860378836, 2.7427581107780128],
						[60.554086036013459, 2.7427581093116795],
						[60.554061035962683, 2.7427665107481785],
						[60.554061029800692, 2.7427665137720161],
						[60.554061018283903, 2.742766517834196],
						[60.554061012121885, 2.7427665208580518],
						[60.554055528014025, 2.7427692138903019],
						[60.554016645059328, 2.7427804079126723],
						[60.553997146344841, 2.7427859081021326],
						[60.553997145452392, 2.7427859082751858],
						[60.553977745899687, 2.7427915062829773],
						[60.55390554841815, 2.7428109068151625],
						[60.553905544848313, 2.7428109075073777],
						[60.55390554038599, 2.74281090837265],
						[60.553905536901375, 2.742810910877302],
						[60.553897144291298, 2.7428137087941598],
						[60.553863852046533, 2.7428220061245452],
						[60.553863850261621, 2.7428220064706523],
						[60.553863846691769, 2.7428220071628657],
						[60.553863844906836, 2.742822007508972],
						[60.553824944934583, 2.7428331077511552],
						[60.553824943149664, 2.7428331080972579],
						[60.553824937794886, 2.7428331091355775],
						[60.553824936009974, 2.7428331094816825],
						[60.553816646077138, 2.7428359075933324],
						[60.55379165558967, 2.7428415050685371],
						[60.553791653804758, 2.7428415054146402],
						[60.553791651127376, 2.7428415059337943],
						[60.553791649342436, 2.7428415062799041],
						[60.553738855030524, 2.742855404883445],
						[60.553724962339011, 2.7428581042129445],
						[60.553724957791488, 2.742858103265756],
						[60.553724950737006, 2.7428581064626121],
						[60.553724947167161, 2.7428581071548166],
						[60.553663846854086, 2.7428748068969524],
						[60.553663838014685, 2.742874810439897],
						[60.553663820335878, 2.7428748175257569],
						[60.553663810604007, 2.7428748212417373],
						[60.553658226631626, 2.7428776122267271],
						[60.5536388510303, 2.7428831066999022],
						[60.553577755907284, 2.7428970052181012],
						[60.553577754122372, 2.7428970055641964],
						[60.553577752337461, 2.7428970059102964],
						[60.55357775144499, 2.7428970060833446],
						[60.553566650829779, 2.7428998059503686],
						[60.553566649937316, 2.7428998061234187],
						[60.553566646367493, 2.742899806815613],
						[60.553566644667761, 2.7428998089741321],
						[60.553547146084753, 2.7429054077345838],
						[60.553497148831369, 2.742919206357052],
						[60.553463874649601, 2.7429276005383736],
						[60.553458299952482, 2.7429276005651317],
						[60.553458287372791, 2.742927601175388],
						[60.553458262298683, 2.7429276042082877],
						[60.553458248911745, 2.7429276068040029],
						[60.553405553608421, 2.7429415041796106],
						[60.553366657924443, 2.7429498031561863],
						[60.553366658009665, 2.7429498049685939],
						[60.553366657117174, 2.742949805141643],
						[60.553366656224725, 2.7429498053146899],
						[60.553341655912234, 2.7429554045063251],
						[60.55334165234239, 2.7429554051985079],
						[60.553341645287894, 2.742955408395285],
						[60.553341640825593, 2.7429554092605151],
						[60.553305548753784, 2.7429665060418191],
						[60.553280561687998, 2.7429720038762553],
						[60.553263867460821, 2.7429748037113191],
						[60.553263863805775, 2.7429748025910761],
						[60.553263857558541, 2.7429748038023911],
						[60.553263855773615, 2.7429748041484823],
						[60.553202756257562, 2.7429887049667654],
						[60.553202748225402, 2.7429887065241645],
						[60.553202734031224, 2.7429887111052684],
						[60.553202726084287, 2.74298871447508],
						[60.553188843106952, 2.7429942063983357],
						[60.553119360716508, 2.7430081047896473],
						[60.553119351791885, 2.7430081065200773],
						[60.553119334027848, 2.7430081117933343],
						[60.553119325188462, 2.7430081153361692],
						[60.553105560920763, 2.7430136994393188],
						[60.553099999780635, 2.7430137004766033],
						[60.553099989878362, 2.7430137005676762],
						[60.553099969266519, 2.7430137027352677],
						[60.55309995757932, 2.7430137031724096],
						[60.553061059295466, 2.7430220042928481],
						[60.553008258549752, 2.7430331033916131],
						[60.553008246140493, 2.7430331076265828],
						[60.553008221321988, 2.7430331160965378],
						[60.553008208997937, 2.7430331221438964],
						[60.553002732968288, 2.7430359103970816],
						[60.552922158789151, 2.7430526047969557],
						[60.552922156111762, 2.7430526053160769],
						[60.552922148972065, 2.7430526067004024],
						[60.552922145402228, 2.7430526073925612],
						[60.552902772381529, 2.7430580992775364],
						[60.552894399582897, 2.7430581004452828],
						[60.552894388788133, 2.7430581007093839],
						[60.552894368176304, 2.7430581028769243],
						[60.552894358359232, 2.7430581047803613],
						[60.552855558425165, 2.7430665055883057],
						[60.552855556640239, 2.7430665059343844],
						[60.552830558772584, 2.7430720038579386],
						[60.552802761242781, 2.7430776038312494],
						[60.552802760350318, 2.7430776040042884],
						[60.552802757672943, 2.7430776045234033],
						[60.552802756780487, 2.7430776046964422],
						[60.552738857274939, 2.7430915047686302],
						[60.552688857467942, 2.7431026055524215],
						[60.55268885381286, 2.7431026044322055],
						[60.552688847650856, 2.7431026074558331],
						[60.552688845865937, 2.7431026078019061],
						[60.552669353292345, 2.7431081044990249],
						[60.552655580365517, 2.7431108992581024],
						[60.552650000312845, 2.7431109002829257],
						[60.552649990410572, 2.7431109003739627],
						[60.552649970691192, 2.7431109023684037],
						[60.552649960874106, 2.7431109042718114],
						[60.552608260738289, 2.7431192033568244],
						[60.55260825984584, 2.7431192035298602],
						[60.552608257253674, 2.7431192058613321],
						[60.552608256275995, 2.7431192042220052],
						[60.55255826204386, 2.7431304044129594],
						[60.552524967020979, 2.7431359033282452],
						[60.552524960773745, 2.7431359045394919],
						[60.552524949171726, 2.7431359067889525],
						[60.552524942924499, 2.7431359080002],
						[60.552497160405764, 2.7431442025413011],
						[60.552472177945724, 2.7431470015665838],
						[60.552472171698504, 2.7431470027778224],
						[60.552472161881433, 2.7431470046812052],
						[60.552472155634185, 2.7431470058924474],
						[60.552447177618994, 2.7431526004087674],
						[60.552444400129673, 2.7431525994001773],
						[60.552444328607095, 2.7431526297272],
						[60.552444228830637, 2.7431527295430098],
						[60.552444199684238, 2.7431527992048408],
						[60.552444200115573, 2.7431554327089649],
						[60.552380565634522, 2.7431665029797876],
						[60.552380561172214, 2.7431665038449555],
						[60.552380551355128, 2.743166505748321],
						[60.552380546085558, 2.7431665085988768],
						[60.552361052615147, 2.7431720053667838],
						[60.55229166118238, 2.7431859035590973],
						[60.552291657612528, 2.7431859042512281],
						[60.552291653150235, 2.7431859051163903],
						[60.552291651365294, 2.7431859054624552],
						[60.552280558772196, 2.7431887035348188],
						[60.552247168291686, 2.7431942025432798],
						[60.552247165614283, 2.7431942030623788],
						[60.552247162044445, 2.7431942037545034],
						[60.552247161151982, 2.7431942039275343],
						[60.552219360935574, 2.7431998041832886],
						[60.552219355580803, 2.7431998052214772],
						[60.552219349333569, 2.7431998064326946],
						[60.552219344871254, 2.7431998072978523],
						[60.552199950730746, 2.7432054054589843],
						[60.552174960078005, 2.7432109035609313],
						[60.552116680866511, 2.743221999717615],
						[60.552113899807182, 2.7432219993934499],
						[60.552113889097647, 2.7432220014698094],
						[60.552113866615663, 2.7432220021708833],
						[60.552113856883793, 2.7432220058865537],
						[60.552038878143861, 2.7432387007281238],
						[60.552036099761871, 2.7432386998830274],
						[60.552036089859584, 2.7432386999740128],
						[60.552036070140211, 2.7432387019683158],
						[60.552036061215581, 2.7432387036986055],
						[60.552022160702137, 2.7432415047776191],
						[60.552022159809674, 2.743241504950646],
						[60.552022157939533, 2.7432415034843554],
						[60.552022157132271, 2.7432415054697366],
						[60.55199715665983, 2.7432470054084903],
						[60.551997155767353, 2.7432470055815221],
						[60.551997153089985, 2.743247006100606],
						[60.551997151305052, 2.7432470064466639],
						[60.551952761150389, 2.743258103588321],
						[60.551933272295159, 2.7432609017611642],
						[60.551933267832851, 2.7432609026263011],
						[60.551933259800691, 2.743260904183551],
						[60.551933256230839, 2.7432609048756609],
						[60.551872160233195, 2.7432748037361709],
						[60.551841663609629, 2.7432804035538711],
						[60.5518416361137, 2.743280412542342],
						[60.551841585117742, 2.7432804388888221],
						[60.551841560725229, 2.7432804564198454],
						[60.551838804661152, 2.743283114042677],
						[60.55179166466764, 2.7432915034800622],
						[60.551791660205346, 2.743291504345188],
						[60.55179165038826, 2.7432915062484726],
						[60.551791645925945, 2.7432915071136028],
						[60.551772157887044, 2.7432970044586611],
						[60.551752770730502, 2.7432998011571432],
						[60.551752766353395, 2.7432998038345864],
						[60.551752753773691, 2.7432998044446273],
						[60.551752749396613, 2.7432998071220691],
						[60.551711053043569, 2.7433109067057591],
						[60.551661056689447, 2.7433220041962776],
						[60.551661055796991, 2.7433220043693005],
						[60.551636077854319, 2.743327600384279],
						[60.551627800421855, 2.7433275994323925],
						[60.551627786949702, 2.7433276002154368],
						[60.551627761875558, 2.743327603247768],
						[60.55162774946627, 2.7433276074824318],
						[60.551574955070954, 2.743341504147558],
						[60.551547160952119, 2.7433470041840025],
						[60.551547158274737, 2.7433470047030681],
						[60.55154715202751, 2.743347005914226],
						[60.551547149350114, 2.7433470064332948],
						[60.551483257975157, 2.7433637036282503],
						[60.551466667303025, 2.7433665030867389],
						[60.551466662755516, 2.743366502139541],
						[60.551466654808543, 2.7433665055090399],
						[60.55146665213114, 2.7433665060281069],
						[60.551433254644266, 2.7433748059462966],
						[60.551394358020808, 2.7433831039240384],
						[60.551394345611541, 2.7433831081586235],
						[60.551394320793008, 2.7433831166277933],
						[60.551394309361385, 2.7433831225016783],
						[60.551388829751076, 2.7433859112320564],
						[60.55133055414462, 2.7433998041028209],
						[60.551330548875058, 2.743399806953235],
						[60.551330540842869, 2.743399808510413],
						[60.551330537273024, 2.74339980920249],
						[60.551322148213529, 2.7434026060111352],
						[60.551308279822699, 2.7434054007104498],
						[60.551302799684237, 2.7434054005879651],
						[60.551302787104547, 2.7434054011979407],
						[60.55130276113794, 2.7434054044032052],
						[60.551302747750967, 2.7434054069984981],
						[60.551230574220384, 2.7434247995208807],
						[60.551227800385547, 2.7434247996027508],
						[60.551227787805864, 2.7434248002127144],
						[60.551227762731706, 2.7434248032449329],
						[60.55122775112968, 2.7434248054941719],
						[60.551216651473752, 2.7434276065667542],
						[60.551216649688826, 2.7434276069127916],
						[60.551216647011422, 2.7434276074318493],
						[60.551216646118966, 2.7434276076048651],
						[60.55114714862848, 2.7434470079199889],
						[60.551124956284326, 2.7434526050217762],
						[60.551097161179911, 2.7434581032233756],
						[60.551097157695267, 2.7434581057277239],
						[60.551097149577906, 2.7434581054725875],
						[60.551097146985718, 2.7434581078039182],
						[60.55106664692331, 2.7434665078292593],
						[60.551036047528143, 2.7434748063930026],
						[60.551036023687253, 2.7434748165012564],
						[60.551035979023666, 2.743474843447872],
						[60.551035959008246, 2.743474858300917],
						[60.551033198700395, 2.7434776172426094],
						[60.550986054057844, 2.743488704488461],
						[60.550986053250583, 2.7434887064737707],
						[60.550986049595537, 2.7434887053535348],
						[60.550986048788275, 2.7434887073388423],
						[60.550944348840126, 2.7434998070478311],
						[60.550944347055207, 2.7434998073938583],
						[60.550944342592906, 2.7434998082589277],
						[60.550944340807966, 2.7434998086049585],
						[60.550908244372295, 2.7435109082833105],
						[60.550877752113152, 2.7435192061017566],
						[60.550852756307442, 2.7435248052523513],
						[60.550852752737597, 2.7435248059444],
						[60.550852748275268, 2.7435248068094658],
						[60.550852744705423, 2.7435248075015153],
						[60.550833245272997, 2.7435304073011784],
						[60.550833222324563, 2.7435304172363253],
						[60.550833179360701, 2.7435304420243649],
						[60.550833159430439, 2.7435304586895648],
						[60.550830493713583, 2.7435331224614408],
						[60.550766654036494, 2.7435526050148495],
						[60.550749966926546, 2.7435554028692484],
						[60.550749958894372, 2.7435554044263522],
						[60.550749943722515, 2.7435554073675408],
						[60.550749936668005, 2.7435554105638951],
						[60.550674943865801, 2.7435804072770797],
						[60.5506527763633, 2.7435859006145065],
						[60.55064719987859, 2.7435859008440335],
						[60.550647171234552, 2.7435859045680862],
						[60.550647115264979, 2.7435859209044664],
						[60.550647087939439, 2.7435859335168136],
						[60.550638811891993, 2.7435915172997456],
						[60.550594339680806, 2.7436054090245539],
						[60.550594338788336, 2.7436054091975608],
						[60.550594337895873, 2.7436054093705713],
						[60.550586045122209, 2.7436081087466309],
						[60.550563850982492, 2.7436137059634058],
						[60.550563847412647, 2.7436137066554362],
						[60.550563837680748, 2.7436137103707972],
						[60.550563833218433, 2.743613711235839],
						[60.55051664340867, 2.7436304083559953],
						[60.550494351805881, 2.7436359055658803],
						[60.550494348236008, 2.743635906257913],
						[60.550494341181512, 2.7436359094542189],
						[60.550494336719197, 2.7436359103192549],
						[60.550469337252402, 2.7436442092640991],
						[60.550469336359939, 2.743644209437107],
						[60.550444337201235, 2.743652610718994],
						[60.550427740994721, 2.743658108406001],
						[60.550408245123755, 2.743663707344199],
						[60.55040824163909, 2.7436637098484713],
						[60.550408236199132, 2.7436637090742573],
						[60.550408232714453, 2.7436637115785301],
						[60.550361036648461, 2.7436804097427623],
						[60.550316641167029, 2.7436942095183805],
						[60.55031663759717, 2.7436942102104007],
						[60.550316634027304, 2.7436942109024187],
						[60.550316631349929, 2.7436942114214293],
						[60.550255531402094, 2.7437165124641165],
						[60.550255526939758, 2.7437165133291366],
						[60.550255518185509, 2.7437165186836427],
						[60.550255512830752, 2.7437165197216595],
						[60.550249920832897, 2.7437192166873707],
						[60.550227732986272, 2.7437276105273369],
						[60.550202737166352, 2.7437359104407246],
						[60.550202736273889, 2.7437359106137253],
						[60.550202735381426, 2.7437359107867265],
						[60.5502027335965, 2.7437359111327329],
						[60.550147143177725, 2.7437554085148563],
						[60.550136053019365, 2.7437581051048401],
						[60.550136045072421, 2.7437581084740943],
						[60.550136029093274, 2.7437581134003484],
						[60.550136021146287, 2.7437581167695919],
						[60.550097124980468, 2.7437748150216152],
						[60.55006103918695, 2.7437887081252139],
						[60.550049951121231, 2.7437915066986105],
						[60.550049944873983, 2.7437915079096107],
						[60.550049933357144, 2.7437915119708332],
						[60.550049926217447, 2.743791513354831],
						[60.550036029167394, 2.7437970125210764],
						[60.549974931649423, 2.7438192120879612],
						[60.549974926294652, 2.7438192131259518],
						[60.549974914863014, 2.7438192189993926],
						[60.549974909593423, 2.7438192218496069],
						[60.549958220790188, 2.7438276157378585],
						[60.549927732206783, 2.7438387120368106],
						[60.549927729529401, 2.7438387125557995],
						[60.549927726937185, 2.7438387148870156],
						[60.549927725959535, 2.7438387132477944],
						[60.549872162034681, 2.7438609006719146],
						[60.549869399886255, 2.743860900272487],
						[60.549869380166854, 2.7438609022661971],
						[60.549869340898454, 2.7438609098780473],
						[60.549869322241918, 2.7438609153231912],
						[60.549816621949908, 2.7438831163091124],
						[60.549816621057445, 2.743883116482106],
						[60.549777724870495, 2.7438998143960434],
						[60.549755529815457, 2.7439081124419102],
						[60.549755527223283, 2.7439081147731019],
						[60.549755520976014, 2.7439081159840653],
						[60.549755519191095, 2.7439081163300543],
						[60.549705527923521, 2.7439304138016114],
						[60.549688837054958, 2.7439359094277056],
						[60.549688815976609, 2.7439359208283798],
						[60.549688777474948, 2.7439359447499672],
						[60.549688759244383, 2.7439359592560759],
						[60.549685986048665, 2.743938731505029],
						[60.549633222796224, 2.7439609151617259],
						[60.549599929087286, 2.743974813402609],
						[60.549591636355949, 2.7439776094973523],
						[60.549591631086372, 2.743977612347515],
						[60.549591622246922, 2.7439776158896305],
						[60.549591616977324, 2.7439776187397804],
						[60.549530518788465, 2.7440054172528008],
						[60.549511021911869, 2.7440137151639417],
						[60.549511019319667, 2.744013717495108],
						[60.549511014050076, 2.7440137203452437],
						[60.54951101137268, 2.7440137208642121],
						[60.549499910713301, 2.7440192209196432],
						[60.549499909820824, 2.7440192210926351],
						[60.549477716002329, 2.7440304190669647],
						[60.549419321211104, 2.744055416621979],
						[60.549419320318641, 2.7440554167949669],
						[60.549419315856333, 2.7440554176599061],
						[60.549419314156594, 2.7440554198180722],
						[60.549366613951058, 2.7440804198553086],
						[60.549366613058595, 2.744080420028296],
						[60.549361014888966, 2.7440831198709832],
						[60.549330521050997, 2.7440970164460565],
						[60.54931662501447, 2.744102613890425],
						[60.549316622422268, 2.7441026162215687],
						[60.549316615367744, 2.7441026194176406],
						[60.549316610905414, 2.7441026202825749],
						[60.549305513811163, 2.7441081195785633],
						[60.549244317456122, 2.7441359183007417],
						[60.549244314778747, 2.7441359188196932],
						[60.549244311294068, 2.7441359213238163],
						[60.549244308616672, 2.7441359218427706],
						[60.549238817638781, 2.7441387179239332],
						[60.549224925839042, 2.7441442139322314],
						[60.549224921461899, 2.7441442166093353],
						[60.54922490994506, 2.7441442206703264],
						[60.549224904675469, 2.7441442235204128],
						[60.549152713167473, 2.7441831193183543],
						[60.549133228908786, 2.7441915133127579],
						[60.549124937818199, 2.7441942102359791],
						[60.549124930678488, 2.7441942116198352],
						[60.549124916569419, 2.7441942180119026],
						[60.549124910407372, 2.7441942210349488],
						[60.549080510098811, 2.7442165201366668],
						[60.549080508399079, 2.7442165222947996],
						[60.549080503129474, 2.7442165251448785],
						[60.549080501344548, 2.7442165254908404],
						[60.549061003769559, 2.7442276248227575],
						[60.549008211679308, 2.7442554216680342],
						[60.548972116073621, 2.74427201837497],
						[60.548972111611292, 2.744272019239864],
						[60.548972104641962, 2.7442720242480472],
						[60.54897210115729, 2.7442720267521299],
						[60.548952704384405, 2.7442831247576986],
						[60.548888822281441, 2.7443165142679686],
						[60.548880538327118, 2.7443192097512177],
						[60.548880527702735, 2.7443192136391015],
						[60.548880510109008, 2.7443192225351445],
						[60.548880500377088, 2.7443192262500515],
						[60.548861005606604, 2.7443304236472343],
						[60.548855511723055, 2.7443331196435055],
						[60.548855508238375, 2.7443331221475726],
						[60.548855502076307, 2.7443331251705692],
						[60.548855497699201, 2.7443331278476064],
						[60.548794309376156, 2.744369220818959],
						[60.548774920640582, 2.7443776161939848],
						[60.548774912693595, 2.7443776195629113],
						[60.548774896884794, 2.7443776281129435],
						[60.548774889915443, 2.7443776331210521],
						[60.548766597964615, 2.7443831279911053],
						[60.548702707075783, 2.7444165224444297],
						[60.548702706268493, 2.7444165244295555],
						[60.548702702698641, 2.7444165251214478],
						[60.548702700913715, 2.7444165254673938],
						[60.548644304553562, 2.7444498240565154],
						[60.548633210256178, 2.7444554213077166],
						[60.548633207663961, 2.7444554236387786],
						[60.548633203201632, 2.7444554245036392],
						[60.548633200609444, 2.7444554268346986],
						[60.548613801140107, 2.7444665251211773],
						[60.548613800332816, 2.7444665271063164],
						[60.548613797655428, 2.744466527625228],
						[60.548613796762979, 2.7444665277981977],
						[60.548599898988989, 2.7444748280334981],
						[60.548527705694028, 2.7445165222554282],
						[60.548522112867907, 2.7445192209062834],
						[60.548522111082981, 2.7445192212522214],
						[60.548522106705846, 2.7445192239292084],
						[60.548522105813383, 2.7445192241021763],
						[60.548480506387392, 2.7445415243819191],
						[60.548480502817547, 2.7445415250737901],
						[60.548480496740652, 2.7445415299088647],
						[60.548480494063263, 2.7445415304277683],
						[60.548436002395832, 2.7445692243092359],
						[60.548424909877077, 2.7445748211333565],
						[60.548424904607486, 2.7445748239832923],
						[60.548424894068269, 2.7445748296831685],
						[60.54842488879865, 2.7445748325331252],
						[60.548408199022127, 2.7445859266732917],
						[60.548397109987178, 2.7445915209823233],
						[60.548397105610022, 2.7445915236592913],
						[60.548397096770557, 2.7445915272010879],
						[60.548397093371072, 2.7445915315172389],
						[60.548344294141934, 2.7446248290642705],
						[60.548272101744978, 2.7446692251350218],
						[60.548255510281791, 2.7446776211570034],
						[60.54825549625788, 2.7446776293607944],
						[60.54825547105785, 2.7446776488737714],
						[60.548255458904073, 2.7446776585437789],
						[60.548252678805746, 2.744680437419607],
						[60.548216605303871, 2.7446998230767146],
						[60.548216603604118, 2.744699825234783],
						[60.548216600926729, 2.7446998257536683],
						[60.548216600119439, 2.7446998277387564],
						[60.548183200239365, 2.7447192273307945],
						[60.548183194969745, 2.744719230180686],
						[60.548183187107938, 2.7447192353615981],
						[60.548183183623244, 2.7447192378655685],
						[60.548163788703476, 2.744733134576407],
						[60.548141594700347, 2.7447470306286106],
						[60.548077702893998, 2.7447859243198915],
						[60.548072111090967, 2.7447887214284816],
						[60.548072105821348, 2.74478872427835],
						[60.548072097067063, 2.7447887296321696],
						[60.548072092604734, 2.7447887304969627],
						[60.548010992981062, 2.7448276314834916],
						[60.548010992088585, 2.7448276316564502],
						[60.548010990388832, 2.7448276338144781],
						[60.548010989411203, 2.7448276321753169],
						[60.547985992803966, 2.7448442308276633],
						[60.547958197335063, 2.7448609293321953],
						[60.547958192872734, 2.7448609301969733],
						[60.547958184203623, 2.7448609373628829],
						[60.54795817982648, 2.7448609400397697],
						[60.547924899348445, 2.7448859263585152],
						[60.54790552097316, 2.7448942167491786],
						[60.547905511241197, 2.7448942204637872],
						[60.547905490247935, 2.7448942336752515],
						[60.54790547970871, 2.7448942393749389],
						[60.547894280313358, 2.7449026398519796],
						[60.547883185879925, 2.7449109358655459],
						[60.547805491937076, 2.7449609316965975],
						[60.547805491129772, 2.7449609336816549],
						[60.547805488452383, 2.7449609342005084],
						[60.547805486667457, 2.7449609345464112],
						[60.54775269805878, 2.74499702652126],
						[60.547741609894807, 2.7450026203900708],
						[60.547736011031539, 2.7450054206244956],
						[60.547735969556037, 2.7450054579197465],
						[60.547735915208428, 2.7450055470819366],
						[60.547735899658925, 2.7450055994677069],
						[60.54773590008481, 2.7450081758963076],
						[60.54771381069456, 2.7450192205939676],
						[60.547713803725188, 2.7450192256017791],
						[60.547713792293472, 2.7450192314743225],
						[60.547713786216576, 2.7450192363091657],
						[60.547641586096127, 2.7450692366921565],
						[60.547641582526246, 2.7450692373839511],
						[60.547641577341828, 2.7450692420458229],
						[60.547641574749584, 2.7450692443767828],
						[60.547627682357771, 2.7450804377072577],
						[60.547619290456289, 2.7450859332617905],
						[60.547619288671349, 2.7450859336076863],
						[60.547619288671349, 2.7450859336076863],
						[60.547569288544786, 2.7451192337760082],
						[60.54753598891817, 2.7451415333380833],
						[60.547535986325954, 2.7451415356690245],
						[60.547535980249037, 2.7451415405038189],
						[60.547535976679178, 2.7451415411955975],
						[60.547510982806841, 2.7451609383834805],
						[60.547485988964262, 2.7451776346987442],
						[60.547485988071791, 2.7451776348716868],
						[60.547485985394388, 2.7451776353905193],
						[60.547485983694628, 2.745177637548494],
						[60.547447085069166, 2.7452054361869238],
						[60.547402691723128, 2.7452359324485145],
						[60.547374897001937, 2.7452526283019774],
						[60.547374894409707, 2.7452526306328804],
						[60.547374887440334, 2.7452526356405835],
						[60.547374883870482, 2.7452526363323435],
						[60.547355483699732, 2.7452665370109477],
						[60.547355476730353, 2.7452665420186437],
						[60.547355464576519, 2.7452665516881254],
						[60.547355458584761, 2.7452665583349631],
						[60.547347071790114, 2.7452748450522733],
						[60.547285987012565, 2.7453165353374653],
						[60.547285986120102, 2.7453165355104034],
						[60.547285984420348, 2.7453165376683542],
						[60.547266584156517, 2.7453304364554354],
						[60.547266583349227, 2.7453304384404489],
						[60.547266580671838, 2.7453304389592597],
						[60.547266579779368, 2.7453304391321964],
						[60.547233180157974, 2.745355440146739],
						[60.547210987738872, 2.7453720339971071],
						[60.54717489500414, 2.745394230589663],
						[60.547174889734507, 2.7453942334393471],
						[60.547174880172896, 2.7453942407778622],
						[60.547174874903263, 2.7453942436275445],
						[60.547119278782354, 2.7454387409891807],
						[60.54708878232961, 2.7454609373899763],
						[60.547088776252664, 2.7454609422246401],
						[60.547088764183997, 2.7454609537060453],
						[60.547088758999543, 2.7454609583677745],
						[60.547085972890443, 2.74546374371748],
						[60.547069289101799, 2.7454748320664395],
						[60.547069289186972, 2.7454748338785047],
						[60.54706928829448, 2.7454748340514388],
						[60.547060987756815, 2.7454804342077033],
						[60.547060985971889, 2.7454804345535671],
						[60.547060983464839, 2.7454804386965073],
						[60.547060980787435, 2.7454804392153043],
						[60.546997081182226, 2.7455276386784853],
						[60.546997080374929, 2.7455276406634757],
						[60.546997077697533, 2.7455276411822678],
						[60.546997076805049, 2.7455276413552001],
						[60.546933182544805, 2.7455776372871319],
						[60.546908189310351, 2.7455942322566047],
						[60.546908180641189, 2.7455942394220703],
						[60.54690816419533, 2.7455942535800566],
						[60.546908156418638, 2.7455942605725761],
						[60.546905466822309, 2.7455970491358723],
						[60.546872082780496, 2.7456220374027152],
						[60.546835985712036, 2.7456470356427785],
						[60.546835982227336, 2.7456470381465357],
						[60.546835974450637, 2.7456470451390413],
						[60.546835970073467, 2.7456470478157247],
						[60.546816576024, 2.7456637426756356],
						[60.546785983096115, 2.7456859372113409],
						[60.546785979611421, 2.7456859397150888],
						[60.546785976126721, 2.7456859422188367],
						[60.546785973534476, 2.7456859445496615],
						[60.546741576841683, 2.7457220427848976],
						[60.546730479892354, 2.7457304392462194],
						[60.546641580209645, 2.7457970404828931],
						[60.546641562956474, 2.74579705662561],
						[60.546641533975247, 2.7457970914976242],
						[60.546641521354744, 2.7457971103998213],
						[60.546638751303156, 2.7458026518432481],
						[60.546633211277737, 2.7458054203146802],
						[60.546633197253747, 2.7458054285175288],
						[60.546633171161062, 2.7458054482015006],
						[60.546633159092359, 2.7458054596826065],
						[60.546616569873073, 2.7458220479054152],
						[60.54657768421994, 2.7458498369345263],
						[60.546577681627703, 2.7458498392653219],
						[60.546577677250532, 2.7458498419419701],
						[60.546577674658295, 2.7458498442727617],
						[60.546549875592788, 2.7458720438650395],
						[60.546497075688933, 2.7459137430957021],
						[60.546497072289405, 2.7459137474114317],
						[60.546497062727767, 2.7459137547496306],
						[60.546497059328225, 2.7459137590653633],
						[60.546485970814196, 2.7459248453625151],
						[60.546458185586282, 2.7459442353373333],
						[60.546458181294263, 2.7459442398259739],
						[60.546458172539914, 2.7459442451792069],
						[60.546458169140358, 2.7459442494949315],
						[60.546416571586015, 2.7459804464574611],
						[60.546402678160639, 2.7459915422149996],
						[60.546391580162449, 2.7459998399482863],
						[60.546391577570198, 2.7459998422790748],
						[60.546391572300564, 2.7459998451285808],
						[60.546391569708327, 2.7459998474593474],
						[60.546372079176692, 2.7460165404336387],
						[60.546347089362108, 2.7460331322486486],
						[60.546347084177626, 2.7460331369101714],
						[60.546347072831018, 2.7460331445941089],
						[60.546347067646536, 2.7460331492556476],
						[60.546280468339958, 2.7460915481018677],
						[60.546258170881188, 2.7461109479232579],
						[60.546199881420151, 2.7461581385745606],
						[60.546191587817894, 2.7461637333854481],
						[60.546191580041153, 2.7461637403776846],
						[60.546191566187474, 2.7461637522043056],
						[60.54619155930321, 2.7461637590236179],
						[60.546180465666026, 2.7461748515985311],
						[60.546166574814059, 2.7461859448554624],
						[60.546166573836416, 2.7461859432163531],
						[60.546166573029126, 2.7461859452012738],
						[60.546166572136663, 2.7461859453741799],
						[60.546099871864811, 2.7462415453653755],
						[60.546099869357732, 2.7462415495081118],
						[60.546099864173229, 2.7462415541695671],
						[60.546099860688521, 2.7462415566731959],
						[60.546097065755156, 2.7462442503178721],
						[60.54604707185419, 2.7462859459084283],
						[60.546047070154415, 2.7462859480662463],
						[60.546047065777231, 2.7462859507427861],
						[60.546047064969919, 2.7462859527276997],
						[60.546013767887814, 2.7463165490204648],
						[60.545963772039563, 2.7463581460180393],
						[60.5459637711471, 2.7463581461909383],
						[60.545963768554849, 2.7463581485216473],
						[60.54596376774753, 2.7463581505065542],
						[60.54594156770348, 2.7463776499147086],
						[60.545941543184433, 2.7463776839209202],
						[60.54594150885162, 2.7463777582269877],
						[60.545941500015473, 2.7463778001659471],
						[60.54594150042287, 2.7463804916469914],
						[60.545933189668851, 2.74638593242187],
						[60.545933187969091, 2.7463859345796888],
						[60.545933182699443, 2.7463859374290882],
						[60.545933180107177, 2.7463859397597923],
						[60.545922080380521, 2.7463942393865213],
						[60.545922076980979, 2.746394243702138],
						[60.545922070011535, 2.7463942487093398],
						[60.545922065634336, 2.7463942513858397],
						[60.545894268324311, 2.7464192491075403],
						[60.545833168710992, 2.7464720497903259],
						[60.545833167818529, 2.7464720499632205],
						[60.545833166926052, 2.7464720501361155],
						[60.545783169616996, 2.7465165487420875],
						[60.545752673111878, 2.7465415443646157],
						[60.545752668819844, 2.7465415488530733],
						[60.545752661935559, 2.7465415556722244],
						[60.545752659428466, 2.7465415598148946],
						[60.545741572554491, 2.7465526434710275],
						[60.545733190227715, 2.7465581327704411],
						[60.545733178966223, 2.7465581422660446],
						[60.545733157335754, 2.7465581610843386],
						[60.545733146159435, 2.7465581723919357],
						[60.545719255330447, 2.7465748623245294],
						[60.545655470337557, 2.7466331487889946],
						[60.545627674741226, 2.7466554432418135],
						[60.545627672234133, 2.746655447384474],
						[60.545627666157138, 2.7466554522186799],
						[60.54562766267243, 2.7466554547222253],
						[60.545577668626372, 2.7467026491142157],
						[60.545563775131235, 2.746713744254841],
						[60.545563770754043, 2.7467137469312632],
						[60.545563763062447, 2.7467137557352057],
						[60.54556375868524, 2.7467137584116315],
						[60.545549861773438, 2.7467276554393534],
						[60.545494267897034, 2.74677764948476],
						[60.545474870148468, 2.7467942485364372],
						[60.545474866663724, 2.7467942510399617],
						[60.545474860586765, 2.7467942558741112],
						[60.545474858972128, 2.7467942598438584],
						[60.545472060762279, 2.7467970564130071],
						[60.545422063259352, 2.7468442546625318],
						[60.545422060581949, 2.7468442551811809],
						[60.54542205888216, 2.7468442573389242],
						[60.545422058074855, 2.7468442593237827],
						[60.545413765312396, 2.7468526512103946],
						[60.545383173196136, 2.7468776446062915],
						[60.545383168011639, 2.7468776492675282],
						[60.545383157727748, 2.7468776604020047],
						[60.545383153435708, 2.746877664890381],
						[60.545352657719171, 2.7469109572774921],
						[60.545319265826805, 2.7469415504545029],
						[60.545294266613027, 2.7469637506756106],
						[60.545269267309848, 2.7469859490494279],
						[60.545269264717611, 2.7469859513800294],
						[60.54526926140317, 2.7469859575074689],
						[60.545269258810897, 2.7469859598380721],
						[60.54524706288057, 2.747008154540727],
						[60.545202667704835, 2.7470470499630348],
						[60.545202663412773, 2.7470470544513645],
						[60.545202653043738, 2.7470470637737372],
						[60.54520264875169, 2.7470470682620651],
						[60.545166567239477, 2.7470887488031246],
						[60.545158190071334, 2.7470942331968682],
						[60.545158182209384, 2.7470942383766657],
						[60.545158165763375, 2.7470942525330702],
						[60.545158157986585, 2.747094259524848],
						[60.545116561034845, 2.7471359551091972],
						[60.545077664012311, 2.74717205364187],
						[60.545077663204985, 2.7471720556267023],
						[60.545077659635119, 2.7471720563181852],
						[60.545077658827793, 2.7471720583030166],
						[60.54504706336008, 2.7472026541371579],
						[60.545024868223329, 2.7472220490098107],
						[60.545024862231486, 2.74722205565577],
						[60.545024850162626, 2.7472220671357457],
						[60.54502484425592, 2.7472220755936774],
						[60.545013751468701, 2.7472359665604649],
						[60.544972058843179, 2.7472776583479983],
						[60.544944272661297, 2.747305444381738],
						[60.544935990213844, 2.7473109322710183],
						[60.544935980652099, 2.7473109396084268],
						[60.544935965905815, 2.7473109516069392],
						[60.544935958129031, 2.7473109585985975],
						[60.544905459226413, 2.7473415592195831],
						[60.544860958727604, 2.7473859576270865],
						[60.544860955413164, 2.747385963754386],
						[60.544860946743889, 2.7473859709188879],
						[60.544860944236767, 2.7473859750613743],
						[60.544849853937571, 2.7473998617565774],
						[60.544822065973619, 2.7474248504017114],
						[60.544822063466476, 2.7474248545441915],
						[60.544822055689686, 2.7474248615358077],
						[60.544822053097406, 2.7474248638663461],
						[60.544788755857013, 2.7474609613359888],
						[60.544758158614513, 2.7474915587680897],
						[60.54473045928632, 2.7475192581128014],
						[60.544710959195491, 2.7475387595460306],
						[60.544710957410551, 2.7475387598917473],
						[60.544710954818285, 2.7475387622222649],
						[60.544710953925822, 2.7475387623951248],
						[60.544669256008518, 2.747583160465394],
						[60.544608158725467, 2.7476442591682297],
						[60.544608155325875, 2.7476442634835174],
						[60.544608146656586, 2.7476442706478923],
						[60.544608144149457, 2.7476442747903258],
						[60.544597043621415, 2.7476581742304784],
						[60.544597042814075, 2.7476581762152685],
						[60.544597041921612, 2.7476581763881227],
						[60.54457765706735, 2.7476831588910655],
						[60.544563774739927, 2.7476942437506895],
						[60.544563773847464, 2.7476942439235414],
						[60.544563773040103, 2.7476942459083484],
						[60.54456377214764, 2.7476942460812035],
						[60.544547071741931, 2.7477081458085912],
						[60.544547068342325, 2.7477081501238652],
						[60.544547058780559, 2.7477081574610613],
						[60.544547055380953, 2.747708161776337],
						[60.544494255334087, 2.7477637621254796],
						[60.544494245050132, 2.7477637732593516],
						[60.544494228222362, 2.7477637984595549],
						[60.544494219893622, 2.7477638128715993],
						[60.544491533852586, 2.7477692844594137],
						[60.544433154561887, 2.7478304612403943],
						[60.544433148655138, 2.7478304696980391],
						[60.544433135949191, 2.7478304867862038],
						[60.544433129957298, 2.747830493431926],
						[60.544419231296203, 2.7478526926226157],
						[60.544399861225791, 2.7478831462712039],
						[60.54437211115826, 2.7478970209911817],
						[60.544372103296283, 2.7478970261706479],
						[60.544372086594734, 2.747897034890519],
						[60.544372078817908, 2.7478970418819233],
						[60.544360979202494, 2.7479054410817776],
						[60.544360972233008, 2.7479054460883923],
						[60.544360958464289, 2.7479054597254793],
						[60.544360950687448, 2.7479054667168792],
						[60.544310952899174, 2.7479609643266456],
						[60.54426925593642, 2.74800546162666],
						[60.544260958664346, 2.74801375868253],
						[60.544260948465499, 2.7480137716281559],
						[60.544260928960263, 2.748013797346585],
						[60.544260921438827, 2.7480138097736866],
						[60.544219260751973, 2.7480970309992361],
						[60.544169346229161, 2.7481109066382401],
						[60.544169317203263, 2.7481109214015134],
						[60.544169264761685, 2.748110955326764],
						[60.544169242323633, 2.7481109761278231],
						[60.544149842076678, 2.748135977436275],
						[60.544149842076678, 2.748135977436275],
						[60.544149841269324, 2.7481359794210261],
						[60.544149840376861, 2.7481359795938638],
						[60.5441331628857, 2.7481581497933418],
						[60.544119296590246, 2.748166528800315],
						[60.544119282651216, 2.748166538813352],
						[60.544119256643256, 2.7481665603056915],
						[60.544119244659448, 2.7481665735968859],
						[60.544094245272994, 2.7481970729139618],
						[60.544094235159264, 2.7481970876713655],
						[60.544094217779453, 2.7481971202915068],
						[60.5440942095358, 2.7481971365151479],
						[60.544085912416556, 2.7482221321905613],
						[60.544041566284285, 2.748335893120553],
						[60.543980609039465, 2.7483330999436997],
						[60.543980607254511, 2.7483331002893681],
						[60.543980601814546, 2.7483330995144577],
						[60.543980600029606, 2.7483330998601199],
						[60.543972200176469, 2.7483330992687689],
						[60.543972155637995, 2.7483331097222661],
						[60.54397207731467, 2.7483331486590545],
						[60.543972041744873, 2.7483331774880284],
						[60.543952645658734, 2.7483581722059749],
						[60.543933148861626, 2.7483804675828551],
						[60.543933138747875, 2.7483804823401288],
						[60.54393312039042, 2.7483805133209347],
						[60.543933113039195, 2.7483805293716199],
						[60.543924813343978, 2.7484027297624092],
						[60.543924811729291, 2.7484027337318651],
						[60.543924809307285, 2.748402739686064],
						[60.543924807692584, 2.7484027436555252],
						[60.543899807822079, 2.7484860428073472],
						[60.54389982652831, 2.7484861342609377],
						[60.543899934358173, 2.7484862633073259],
						[60.543900021611769, 2.748486299433873],
						[60.543925021056161, 2.7484834987694273],
						[60.543952816152178, 2.7484807984845712],
						[60.543994412709644, 2.7484779999028279],
						[60.544041711734017, 2.7484751995415033],
						[60.544041732260887, 2.7484751955664484],
						[60.544041772337003, 2.7484751859772421],
						[60.544041790908686, 2.7484751787240311],
						[60.544047247616177, 2.7484723996776212],
						[60.544086100067986, 2.7484723996820954],
						[60.54408610274541, 2.7484723991636071],
						[60.544086109077831, 2.7484723997657161],
						[60.544086111755249, 2.74847239924723],
						[60.544133311755765, 2.7484695997536455],
						[60.544133317110592, 2.7484695987166696],
						[60.544133327012894, 2.7484695986274605],
						[60.544133332367757, 2.748469597590482],
						[60.544150016113413, 2.748466900635977],
						[60.544177800224546, 2.748466899325535],
						[60.54421940038133, 2.7484668992778172],
						[60.544219403143856, 2.7484669005712448],
						[60.544219409391168, 2.7484668993614338],
						[60.544219412153709, 2.7484669006548779],
						[60.544266705736682, 2.7484640994627445],
						[60.544269400360562, 2.7484641005636625],
						[60.54431109954028, 2.7484640996129972],
						[60.544358299570582, 2.7484640993601541],
						[60.544374989374283, 2.7484640999408638],
						[60.544399978169174, 2.7484668998617274],
						[60.544399982631546, 2.7484668989975689],
						[60.544399994403932, 2.7484669003746163],
						[60.544399999758767, 2.7484668993376284],
						[60.544444399894488, 2.7484669000826596],
						[60.544491653983549, 2.7484669003236974],
						[60.544497112314986, 2.7484695805790231],
						[60.5444971333526, 2.7484695874754967],
						[60.544497177042558, 2.7484695972989108],
						[60.544497199694902, 2.7484696002258349],
						[60.544533293645436, 2.7484696004933138],
						[60.544577787480435, 2.7484724006871337],
						[60.544577791050315, 2.7484723999958076],
						[60.54457779738275, 2.7484724005979162],
						[60.544577800060175, 2.7484724000794181],
						[60.544597199744693, 2.748472400631226],
						[60.544619389421229, 2.7484724002693603],
						[60.544669377805469, 2.7484779998843076],
						[60.544669383160304, 2.7484779988473114],
						[60.54466939404022, 2.7484780003972],
						[60.544669400287539, 2.7484779991873669],
						[60.544708286099926, 2.7484780000058002],
						[60.544727772173935, 2.7484807977842274],
						[60.544727774851339, 2.7484807972657292],
						[60.544727781268911, 2.7484807996797822],
						[60.544727783946321, 2.7484807991612858],
						[60.544761084146089, 2.7484834998358418],
						[60.54476108771599, 2.7484834991445068],
						[60.544761095833366, 2.7484834994009519],
						[60.54476110038086, 2.7484835003487333],
						[60.544797188891053, 2.7484834998387599],
						[60.544822177685667, 2.7484862997912707],
						[60.544822179470607, 2.7484862994456045],
						[60.54482218036307, 2.7484862992727703],
						[60.544849979931897, 2.7484890997763993],
						[60.544849985286731, 2.7484890987393977],
						[60.544849995274184, 2.7484891004621259],
						[60.544849999736542, 2.7484890995979567],
						[60.544880591051097, 2.7484890995089115],
						[60.54494168172446, 2.748494598811086],
						[60.544941687164417, 2.7484945995860377],
						[60.54494169617427, 2.7484945996696482],
						[60.544941699829295, 2.7484946007902811],
						[60.544961100320911, 2.7484945993572647],
						[60.544966691184854, 2.7484945990814365],
						[60.545027782077725, 2.7485001989209095],
						[60.545027785647633, 2.7485001982295669],
						[60.545027794742616, 2.7485002001251417],
						[60.545027800097436, 2.7485001990881335],
						[60.545047199781678, 2.7485001996401235],
						[60.545055595979854, 2.748500199137228],
						[60.545119391367471, 2.7485030006448938],
						[60.545119393152419, 2.7485030002992232],
						[60.545119397614791, 2.7485029994350456],
						[60.545119400377331, 2.7485030007285052],
						[60.545144399850081, 2.7485029992742098],
						[60.545191699879595, 2.7485029997507069],
						[60.54520829958421, 2.7485029994890668],
						[60.545230600055852, 2.7485029996074268],
						[60.545272190308253, 2.7485029996125667],
						[60.545299979889293, 2.7485057984231531],
						[60.545299987114198, 2.748505798852436],
						[60.545300001564009, 2.7485057997110203],
						[60.545300008788921, 2.7485058001403031],
						[60.545363903943532, 2.748503000014487],
						[60.545386099951756, 2.7485030002435815],
						[60.545413900295941, 2.7485029994120613],
						[60.545413903950958, 2.7485030005326951],
						[60.545413911090741, 2.7485029991499941],
						[60.545413913768144, 2.7485029986314848],
						[60.545452813912178, 2.7485001990845501],
						[60.545452815697132, 2.7485001987388737],
						[60.545452820244627, 2.7485001996866671],
						[60.545452822029567, 2.7485001993409917],
						[60.545477810760325, 2.7484974005533562],
						[60.545497199564046, 2.7484973995372299],
						[60.54549720321905, 2.748497400657866],
						[60.545497210358839, 2.7484973992751578],
						[60.545497213121379, 2.7484974005686507],
						[60.54553891315912, 2.7484945999964956],
						[60.545538914944061, 2.7484945996508179],
						[60.545538917621478, 2.7484945991323046],
						[60.545538919406432, 2.7484945987866261],
						[60.54556671904551, 2.7484918992393932],
						[60.545566720830443, 2.7484918988937186],
						[60.545566725292836, 2.7484918980295197],
						[60.545566727077777, 2.7484918976838419],
						[60.545627814154223, 2.7484834994200056],
						[60.545630599683733, 2.748483499366853],
						[60.545630605123705, 2.7484835001418095],
						[60.54563061494089, 2.7484834982405748],
						[60.545630618595908, 2.7484834993612117],
						[60.545658319125998, 2.7484807988723428],
						[60.545658321803415, 2.7484807983538233],
						[60.545658328050735, 2.7484807971439436],
						[60.545658330813275, 2.7484807984374218],
						[60.545711131423161, 2.7484723976724315],
						[60.545711135885533, 2.7484723968082263],
						[60.545711144810255, 2.7484723950798249],
						[60.545711149272613, 2.7484723942156228],
						[60.545722234706481, 2.7484695964156582],
						[60.54574722097783, 2.7484668986112748],
						[60.545747228117612, 2.7484668972285462],
						[60.545747242397162, 2.7484668944630952],
						[60.545747247752004, 2.7484668934260488],
						[60.545791743340104, 2.7484557949030144],
						[60.545836137039629, 2.7484473966525211],
						[60.545836138824569, 2.7484473963068363],
						[60.545836144179411, 2.7484473952697845],
						[60.545836145964351, 2.7484473949241006],
						[60.545894445702636, 2.7484334949291798],
						[60.545894448380039, 2.7484334944106537],
						[60.545894451057443, 2.7484334938921271],
						[60.54589445194992, 2.7484334937192849],
						[60.545925052219701, 2.7484251922173635],
						[60.545925053197301, 2.7484251938565323],
						[60.545925054897118, 2.7484251916988338],
						[60.545944452726673, 2.7484195936590057],
						[60.545997250937617, 2.7484057935795372],
						[60.54599725183008, 2.7484057934066923],
						[60.545997253615049, 2.7484057930610044],
						[60.545997255399989, 2.7484057927153152],
						[60.546016754843784, 2.7484001914254925],
						[60.546036155349157, 2.7483945928329385],
						[60.54603615624162, 2.7483945926600937],
						[60.546036157134104, 2.7483945924872448],
						[60.54603615794143, 2.7483945905023854],
						[60.546100057693515, 2.748375191719584],
						[60.546100065640644, 2.748375188351952],
						[60.546100081534867, 2.7483751816166913],
						[60.546100088589498, 2.7483751784218882],
						[60.546105670025995, 2.748372389631089],
						[60.546138948041467, 2.748364094184292],
						[60.546138953396309, 2.7483640931472126],
						[60.546138963128364, 2.748364089433863],
						[60.546138967590728, 2.7483640885696303],
						[60.546169462547574, 2.7483529895789069],
						[60.546197252963722, 2.7483445921236997],
						[60.546208346645443, 2.7483418931979995],
						[60.546208351107822, 2.748341892333757],
						[60.546208360032537, 2.7483418906052868],
						[60.546208363602418, 2.7483418899138963],
						[60.546233355823446, 2.7483334913021045],
						[60.546288949402474, 2.7483195938681702],
						[60.546288953864817, 2.7483195930039308],
						[60.546288965381819, 2.7483195889448684],
						[60.546288969759061, 2.7483195862685919],
						[60.546311164174128, 2.7483112906325387],
						[60.546375056768326, 2.7482918928702502],
						[60.546394455396744, 2.7482862926713931],
						[60.546394458881501, 2.7482862901679566],
						[60.546394466021262, 2.748286288785156],
						[60.546394470398489, 2.7482862861088697],
						[60.546416760263938, 2.7482779902167982],
						[60.546427849346358, 2.7482751933951421],
						[60.546427850238821, 2.7482751932222924],
						[60.546427852916239, 2.7482751927037397],
						[60.546427854701179, 2.748275192358038],
						[60.546466754853746, 2.7482640926648365],
						[60.546466759316118, 2.7482640918005803],
						[60.546466766370749, 2.7482640886057301],
						[60.546466769048166, 2.7482640880871756],
						[60.546519457289698, 2.7482445927733785],
						[60.546555645004574, 2.7482362948747445],
						[60.54655565035938, 2.7482362938376323],
						[60.546555661069043, 2.748236291763396],
						[60.546555666338755, 2.7482362889142156],
						[60.546619434142855, 2.7482141008702472],
						[60.546622199782064, 2.7482140991552653],
						[60.546622223071459, 2.7482140964731321],
						[60.546622267695049, 2.7482140878304366],
						[60.546622288858956, 2.7482140782457836],
						[60.546627872883086, 2.7482112870461579],
						[60.546647254854641, 2.7482057923695336],
						[60.546647262801734, 2.7482057890017995],
						[60.546647281458505, 2.7482057835597926],
						[60.546647289320475, 2.7482057783800022],
						[60.546652877721584, 2.7482029845002125],
						[60.546733362818102, 2.7481751905061591],
						[60.546811131012376, 2.7481501991260431],
						[60.546816700285184, 2.7481501992954973],
						[60.546816715627493, 2.7481501999810454],
						[60.546816747756452, 2.7481501937581965],
						[60.546816763820935, 2.7481501906467702],
						[60.546825063630216, 2.7481473908941552],
						[60.546825068007422, 2.7481473882178111],
						[60.546825075062074, 2.7481473850228939],
						[60.546825078631954, 2.7481473843314661],
						[60.546844468590727, 2.7481390885127985],
						[60.546888959632447, 2.7481251904239326],
						[60.546888962309858, 2.7481251899053611],
						[60.546888963202328, 2.7481251897325012],
						[60.546913961870921, 2.7481168900629158],
						[60.54696675807191, 2.7481001910915577],
						[60.547005654632819, 2.7480890916552552],
						[60.547005664450012, 2.7480890897537962],
						[60.547005682129161, 2.7480890826724576],
						[60.547005690883616, 2.7480890773196984],
						[60.547011168741207, 2.7480862883382415],
						[60.547047245007924, 2.7480779952290364],
						[60.547047245900387, 2.7480779950561778],
						[60.547047247685356, 2.7480779947104521],
						[60.547047248577819, 2.748077994537593],
						[60.5470694489563, 2.748072394486194],
						[60.547069454225976, 2.7480723916369572],
						[60.547069464935632, 2.7480723895626271],
						[60.547069470205329, 2.7480723867133885],
						[60.547091758351939, 2.7480640926306332],
						[60.547127841671063, 2.7480557962755787],
						[60.547183340382624, 2.7480445951123254],
						[60.547183342252701, 2.7480445965786804],
						[60.547183345822596, 2.7480445958872277],
						[60.547183348414869, 2.7480445935565609],
						[60.547205624022837, 2.7480390995672486],
						[60.547213899605346, 2.7480391003288362],
						[60.547213908615198, 2.7480391004122917],
						[60.547213928334685, 2.74803909842139],
						[60.547213937259414, 2.7480390966927604],
						[60.547272219210086, 2.7480280000328468],
						[60.547291699890891, 2.7480279991580172],
						[60.547291705330863, 2.7480279999329209],
						[60.547291715233172, 2.7480279998435053],
						[60.547291719695515, 2.7480279989791905],
						[60.54734722036121, 2.7480223982622642],
						[60.547347223123779, 2.7480223995557584],
						[60.547347230263526, 2.7480223981728478],
						[60.547347232940957, 2.748022397654251],
						[60.547363920908992, 2.7480195987059983],
						[60.547427803753841, 2.7480169001408545],
						[60.54743889992654, 2.7480168994849405],
						[60.547449999754264, 2.7480168999494108],
						[60.547516700335834, 2.7480168995065024],
						[60.547527800163493, 2.7480168999693424],
						[60.547541696314504, 2.7480169000662267],
						[60.547613880441354, 2.748019598906843],
						[60.547630566627831, 2.7480223976099212],
						[60.547630574745206, 2.7480223978662286],
						[60.547630591957564, 2.7480224000181028],
						[60.547630600074939, 2.7480224002744111],
						[60.547649999751769, 2.7480224004440803],
						[60.547658296031109, 2.7480224008427308],
						[60.547722181335963, 2.7480251985282798],
						[60.547741671963138, 2.7480279971306851],
						[60.547741676595756, 2.7480279998905948],
						[60.547741687390548, 2.7480279996283024],
						[60.54774169193805, 2.7480280005760767],
						[60.547808286315281, 2.748030800175068],
						[60.547836081118852, 2.7480334989093187],
						[60.547836082903792, 2.7480334985635837],
						[60.547836086558803, 2.7480334996842344],
						[60.547836089236228, 2.7480334991656274],
						[60.547888889248149, 2.748036299075824],
						[60.547888892010704, 2.7480363003693413],
						[60.547888897365532, 2.7480362993321354],
						[60.547888900128086, 2.7480363006256505],
						[60.547899989968116, 2.7480362993646503],
						[60.547927779636957, 2.7480390996748612],
						[60.547927781421897, 2.748039099329127],
						[60.547927784099329, 2.7480390988105206],
						[60.547927785884269, 2.7480390984647856],
						[60.547966686175343, 2.7480418989782986],
						[60.547966688937898, 2.7480419002718341],
						[60.54796669616281, 2.7480419007010117],
						[60.547966699732676, 2.7480419000095417],
						[60.547991682153622, 2.7480418995360889],
						[60.548022165206703, 2.7480473965276473],
						[60.548022170646661, 2.7480473973025679],
						[60.548022184204015, 2.7480473983337927],
						[60.54802218972911, 2.7480474009208535],
						[60.548080587827329, 2.7480502008948089],
						[60.548122187118132, 2.7480529997943526],
						[60.548122189795535, 2.7480529992757496],
						[60.54812219702044, 2.7480529997049294],
						[60.548122199697858, 2.7480529991863234],
						[60.548133278828168, 2.7480530000005774],
						[60.548172158120373, 2.748061295973649],
						[60.548172161690246, 2.7480612952821741],
						[60.548172168107833, 2.7480612976963599],
						[60.54817217176285, 2.7480612988170354],
						[60.548191664174553, 2.7480640971179127],
						[60.548216642723773, 2.748069592438469],
						[60.548261024695854, 2.7480862857739035],
						[60.548324920025912, 2.7481140827556159],
						[60.548324923766074, 2.748114085688429],
						[60.548324932861036, 2.7481140875840491],
						[60.548324936601205, 2.7481140905168626],
						[60.548349918762348, 2.7481223848388692],
						[60.548394302338195, 2.7481473745393519],
						[60.548394304123136, 2.748147374193616],
						[60.548394308840905, 2.7481473787657325],
						[60.548394310625845, 2.7481473784199975],
						[60.548416599183, 2.7481584730111037],
						[60.548424888067444, 2.7481640668556846],
						[60.548424889852399, 2.7481640665099465],
						[60.548424895377501, 2.7481640690970384],
						[60.548424897332715, 2.7481640723756193],
						[60.548438797046373, 2.7481723720367643],
						[60.5484388072041, 2.7481723773838351],
						[60.548438825649484, 2.7481723866115497],
						[60.548438835722067, 2.7481723901464705],
						[60.54844711368375, 2.7481751828019707],
						[60.548469293907644, 2.7481890706383147],
						[60.548469300325209, 2.7481890730525467],
						[60.548469311375413, 2.7481890782267619],
						[60.548469316985646, 2.7481890826260296],
						[60.548530516954052, 2.7482168827048046],
						[60.54853052060907, 2.7482168838254912],
						[60.548530528004235, 2.7482168878790376],
						[60.548530531574116, 2.7482168871875698],
						[60.548561032003242, 2.7482279876818265],
						[60.548561039313292, 2.7482279899232047],
						[60.548561054825839, 2.7482279942331136],
						[60.548561063028359, 2.7482279963016394],
						[60.548619362899593, 2.7482390957608147],
						[60.548619395369101, 2.748239096786254],
						[60.548619458012375, 2.7482390883098682],
						[60.548619489078611, 2.7482390786351933],
						[60.548636162355685, 2.7482307910435226],
						[60.548652833454874, 2.7482279971469592],
						[60.548652861843692, 2.7482279879908473],
						[60.548652917303166, 2.7482279607906985],
						[60.548652940718803, 2.7482279416259376],
						[60.548655706233895, 2.7482251768260282],
						[60.548711158126586, 2.7482084928687085],
						[60.548711173043195, 2.7482084844934418],
						[60.548711203046665, 2.7482084713672292],
						[60.548711216985659, 2.7482084613526494],
						[60.548741816422975, 2.7481862629113207],
						[60.548777913898682, 2.7481612640620483],
						[60.548777920868197, 2.7481612590547435],
						[60.548777934722033, 2.7481612472279568],
						[60.548777940713926, 2.7481612405813363],
						[60.548797326673693, 2.7481418559506752],
						[60.548814010609021, 2.7481307665055072],
						[60.548814019363462, 2.748130761152451],
						[60.548814034024637, 2.7481307473405923],
						[60.548814040909001, 2.7481307405210895],
						[60.548827938037626, 2.748116844410351],
						[60.548861230330836, 2.7480862517670546],
						[60.548889021904053, 2.7480640575278459],
						[60.548966820317638, 2.7480057597048462],
						[60.548966826394661, 2.7480057548703565],
						[60.548966836763753, 2.748005745547109],
						[60.548966841948285, 2.7480057408854979],
						[60.548977932111264, 2.7479945516962445],
						[60.548989010216253, 2.7479862687712551],
						[60.549008399298089, 2.7479751745119754],
						[60.54900840269768, 2.7479751701960891],
						[60.549008409752318, 2.7479751670008885],
						[60.549008413237061, 2.7479751644971979],
						[60.549052901749796, 2.7479445733697316],
						[60.549069483843006, 2.7479362812168859],
						[60.549088978238657, 2.74792798449388],
						[60.549088994940163, 2.7479279757726069],
						[60.549089027195343, 2.7479279530663709],
						[60.549089041049164, 2.7479279412393791],
						[60.549091816840949, 2.7479251652390668],
						[60.549155667870458, 2.7478945885049035],
						[60.549166747340664, 2.7478918936943413],
						[60.549166751803014, 2.7478918928299421],
						[60.549166761620185, 2.7478918909282544],
						[60.549166766889869, 2.7478918880787799],
						[60.549205665233686, 2.7478779892299783],
						[60.549247258197759, 2.7478640913706305],
						[60.549308326686756, 2.7478473991693106],
						[60.549313899612947, 2.7478474003809459],
						[60.549313915762575, 2.7478473990812495],
						[60.549313947084165, 2.7478473948425481],
						[60.54931396306349, 2.7478473899184612],
						[60.549338942234506, 2.747839096725242],
						[60.549366720200972, 2.747836299711087],
						[60.549366724663301, 2.7478362988466727],
						[60.549366733588016, 2.7478362971178369],
						[60.549366738942844, 2.7478362960805307],
						[60.549408333787206, 2.7478279971982893],
						[60.549427813690485, 2.7478252001905652],
						[60.54944169993631, 2.7478252002445891],
						[60.549441701721264, 2.7478251998988177],
						[60.549441705291144, 2.7478251992072797],
						[60.549441707161222, 2.7478252006737183],
						[60.549519384127514, 2.7478224003266614],
						[60.549533261244306, 2.7478251964092117],
						[60.549533270339282, 2.7478251983048017],
						[60.549533290229043, 2.7478251999379633],
						[60.549533300131387, 2.7478251998484504],
						[60.549538883852243, 2.7478252007914823],
						[60.54959166474454, 2.7478334974861092],
						[60.549605559966025, 2.7478362955567395],
						[60.549633246923378, 2.7478418934637792],
						[60.549697129373193, 2.7478640866368607],
						[60.549744310204147, 2.7478834786426476],
						[60.549758197152329, 2.7478918705701574],
						[60.549758203655045, 2.7478918747966321],
						[60.549758218360274, 2.7478918810916104],
						[60.549758225755461, 2.7478918851452176],
						[60.549786010636261, 2.7479029795710033],
						[60.549869293695465, 2.7479529694265712],
						[60.5498775996208, 2.7479584083634232],
						[60.549877600001118, 2.7479611001743276],
						[60.549877611139458, 2.7479611455635591],
						[60.549877650925012, 2.747961225635386],
						[60.549877679572205, 2.7479612603180064],
						[60.549888772820836, 2.7479695552612005],
						[60.54994156660473, 2.7480168492339536],
						[60.549941568474807, 2.748016850700421],
						[60.549941567582344, 2.7480168508733014],
						[60.549963757775529, 2.7480362417321249],
						[60.549983148927545, 2.7480584314777947],
						[60.549983151775251, 2.7480584345836374],
						[60.549983155600529, 2.748058439328823],
						[60.549983159255568, 2.7480584404495296],
						[60.550002651641655, 2.7480779351744729],
						[60.550055446115792, 2.7481418269470397],
						[60.550055461246721, 2.7481418423033976],
						[60.550055494015687, 2.748141868872982],
						[60.550055511653717, 2.7481418800862212],
						[60.550060899869315, 2.7481445248985987],
						[60.550060900417364, 2.748150000028017],
						[60.550060904712375, 2.7481500339420091],
						[60.550060927326562, 2.7481500935671872],
						[60.550060942246084, 2.7481501235943981],
						[60.550080435211285, 2.7481751136598884],
						[60.55011092795155, 2.7482251005200364],
						[60.550147020707634, 2.7482889892437221],
						[60.550155416415507, 2.7483083788408806],
						[60.55015541837075, 2.7483083821196184],
						[60.550155420411087, 2.7483083872106082],
						[60.550155421388688, 2.7483083888499946],
						[60.550166521413573, 2.7483306889953139],
						[60.550202612959303, 2.7484028733523371],
						[60.550208108331759, 2.7484222542417629],
						[60.550208109649894, 2.7484222631301418],
						[60.550208115770921, 2.7484222784031873],
						[60.550208118788888, 2.7484222851335831],
						[60.550235918593422, 2.7484806868938896],
						[60.550235920378363, 2.7484806865481439],
						[60.550235921355977, 2.7484806881875246],
						[60.55024421521378, 2.7484972776853782],
						[60.550249810034053, 2.7485139638952263],
						[60.550249811989268, 2.7485139671740066],
						[60.550249815092357, 2.7485139757166706],
						[60.550249816155095, 2.7485139791683069],
						[60.550285913311725, 2.7485972706266701],
						[60.550297010194406, 2.7486306629211321],
						[60.550297011257129, 2.7486306663727764],
						[60.550297013297481, 2.7486306714638244],
						[60.550297014275081, 2.74863067310321],
						[60.550322013642635, 2.7486944729474145],
						[60.550322013727758, 2.7486944747596911],
						[60.550322013727758, 2.7486944747596911],
						[60.550327612571508, 2.7487083714167393],
						[60.550388712167532, 2.7488778686507356],
						[60.550399811751625, 2.7489083675250807],
						[60.550413712286925, 2.7489472665204113],
						[60.550413714412379, 2.7489472734237452],
						[60.550413719385517, 2.7489472834330382],
						[60.550413721425834, 2.7489472885241257],
						[60.550438711003942, 2.7489972708073727],
						[60.550441506282311, 2.7490083493177537],
						[60.550441508322635, 2.7490083544088297],
						[60.550441510703457, 2.7490083667490048],
						[60.550441513636272, 2.74900837166722],
						[60.550460914073007, 2.7490583722271236],
						[60.550460914243253, 2.7490583758516549],
						[60.550460916198453, 2.7490583791304894],
						[60.550460917176053, 2.7490583807698976],
						[60.55047201289301, 2.7490833722988373],
						[60.550480410412035, 2.7491083634132272],
						[60.550480412367222, 2.7491083666920502],
						[60.550480414577784, 2.749108375407697],
						[60.550480415640521, 2.7491083788593733],
						[60.550505415987864, 2.749166778882238],
						[60.550522016429717, 2.7492056782117098],
						[60.55053591496678, 2.7492389763602869],
						[60.550535916921987, 2.7492389796391374],
						[60.550535919939897, 2.7492389863696634],
						[60.550535921002613, 2.7492389898213698],
						[60.550566519994035, 2.7493000875865663],
						[60.550597014982934, 2.7493639761828543],
						[60.55060811233556, 2.7493944685198475],
						[60.550608116331063, 2.7493944768898477],
						[60.550608124407177, 2.7493944954421212],
						[60.550608129295156, 2.7493945036392509],
						[60.550624824845542, 2.7494222951429745],
						[60.550655420910132, 2.7494833901438676],
						[60.550655428730892, 2.7494834032593372],
						[60.550655447942269, 2.7494834287989049],
						[60.550655458440424, 2.7494834413958338],
						[60.550663733780411, 2.7494918164487778],
						[60.550685918918568, 2.749538985021251],
						[60.55068592087374, 2.7495389883001238],
						[60.550685924869228, 2.7495389966701613],
						[60.550685926909544, 2.7495390017613088],
						[60.550705422139337, 2.7495722916138572],
						[60.55071921768976, 2.7496028815125912],
						[60.550719219730048, 2.749602886603753],
						[60.550719224703137, 2.7496028966132409],
						[60.55071922665833, 2.7496028998921367],
						[60.550749826561884, 2.7496556991722376],
						[60.550749826646999, 2.7496557009845226],
						[60.550749828431947, 2.7496557006388378],
						[60.550777627417084, 2.7497028996065196],
						[60.55079982580105, 2.7497417998480005],
						[60.550799827671128, 2.7497418013146198],
						[60.550799831581493, 2.7497418078724052],
						[60.550799834429149, 2.7497418109784766],
						[60.550827630429929, 2.7497834054649037],
						[60.550849825774151, 2.7498222990905914],
						[60.550849826751751, 2.7498223007300417],
						[60.550849829684516, 2.7498223056484141],
						[60.550849830662088, 2.7498223072878676],
						[60.550869231006878, 2.7498529074200446],
						[60.550869231006878, 2.7498529074200446],
						[60.550888729086793, 2.7498834027072294],
						[60.550899825921071, 2.7499028991211079],
						[60.55089982787625, 2.7499029024000228],
						[60.550899830809001, 2.7499029073184094],
						[60.550899832679079, 2.7499029087850286],
						[60.550941530165304, 2.7499668053378361],
						[60.55094982495244, 2.7499806960821065],
						[60.550955420814049, 2.7499917902615763],
						[60.55095545783346, 2.749991830640798],
						[60.550955547722872, 2.7499918845554814],
						[60.550955599785489, 2.7499919000760764],
						[60.550960991627043, 2.7499919001342095],
						[60.551002632714336, 2.7500557090806126],
						[60.551002633691922, 2.7500557107200798],
						[60.551002636624673, 2.7500557156384806],
						[60.551002637602252, 2.750055717277966],
						[60.551047033040419, 2.7501168112423344],
						[60.551055428706427, 2.7501307026479394],
						[60.551055436356883, 2.750130712139117],
						[60.551055450765354, 2.7501307312943073],
						[60.551055459308287, 2.7501307406126574],
						[60.551072044540639, 2.7501473272725963],
						[60.551108135320838, 2.7502001121694826],
						[60.551108151599351, 2.7502001327913761],
						[60.551108190063218, 2.7502001655761337],
						[60.551108211441232, 2.7502001797241293],
						[60.551113767034842, 2.7502029571289404],
						[60.551135938158886, 2.7502334179992189],
						[60.551135941006521, 2.7502334211053481],
						[60.551135944831763, 2.7502334258509675],
						[60.551135946786928, 2.7502334291299233],
						[60.551166546582117, 2.750269529100672],
						[60.551166548452166, 2.750269530567325],
						[60.551216547720266, 2.7503279305856032],
						[60.551216551460357, 2.7503279335189217],
						[60.551216556178076, 2.7503279380917345],
						[60.551216559025711, 2.7503279411978778],
						[60.551224835969109, 2.7503362175321424],
						[60.551227621031714, 2.7503417882193166],
						[60.551227626982318, 2.7503417998685635],
						[60.551227640583384, 2.7503418210091071],
						[60.551227649126304, 2.750341830327558],
						[60.551280449019728, 2.7504029306861733],
						[60.551280450889777, 2.75040293215284],
						[60.551280455692591, 2.7504029385379911],
						[60.551280457647756, 2.7504029418169722],
						[60.551319258148105, 2.7504418413480165],
						[60.551319259040582, 2.750441841175189],
						[60.551319260018154, 2.7504418428146837],
						[60.551319260995754, 2.7504418444541763],
						[60.551322059778869, 2.7504445431162687],
						[60.551338699718606, 2.7504611834756481],
						[60.551338700264068, 2.7504638991444281],
						[60.55133870838376, 2.750463937805768],
						[60.551338736947557, 2.7504640090842454],
						[60.551338759176616, 2.7504640413557468],
						[60.551352654940573, 2.7504779377434012],
						[60.551383152723041, 2.7505112342445406],
						[60.551383156633356, 2.7505112408025556],
						[60.55138316696123, 2.7505112497754416],
						[60.551383171678921, 2.750511254348309],
						[60.55139985041869, 2.7505251349016726],
						[60.551405433703572, 2.7505334117334046],
						[60.55140544224647, 2.7505334210519758],
						[60.551405458439817, 2.7505334398618979],
						[60.551405466090273, 2.7505334493532709],
						[60.551460965058581, 2.7505834480024864],
						[60.551497063890565, 2.7506168469985535],
						[60.551497064783042, 2.7506168468257308],
						[60.5514970657606, 2.7506168484652407],
						[60.551497066738193, 2.7506168501047483],
						[60.551522066724331, 2.7506390498658488],
						[60.551522068594402, 2.7506390513325329],
						[60.551522073226991, 2.7506390540931061],
						[60.55152207509704, 2.7506390555597955],
						[60.551563771705432, 2.7506723527838552],
						[60.551585968187183, 2.7506918493533226],
						[60.551585970142348, 2.7506918526323685],
						[60.551585974774945, 2.7506918553929349],
						[60.551585976730102, 2.7506918586719635],
						[60.551649877289847, 2.7507418586076002],
						[60.551649881922437, 2.7507418613681973],
						[60.551649892165173, 2.7507418685288934],
						[60.551649896797755, 2.7507418712894736],
						[60.551677693022533, 2.7507584694250422],
						[60.551719288861847, 2.7507862652374606],
						[60.551719291709482, 2.7507862683436874],
						[60.551719298212113, 2.7507862725710046],
						[60.551719300974632, 2.7507862738648901],
						[60.551738792939993, 2.7507973696265475],
						[60.551766584650828, 2.7508168637589288],
						[60.551766585543305, 2.7508168635861128],
						[60.551766588390947, 2.7508168666923467],
						[60.551766589283424, 2.7508168665195289],
						[60.55178318874475, 2.7508279664519879],
						[60.551783194269795, 2.7508279690397739],
						[60.55178320540503, 2.7508279760277032],
						[60.551783210930061, 2.7508279786155074],
						[60.551788806250897, 2.7508307777464291],
						[60.55185820205034, 2.7508695751026093],
						[60.551858225850417, 2.7508695832958252],
						[60.551858275150416, 2.7508695975242738],
						[60.551858299587657, 2.7508696001076194],
						[60.551861048187227, 2.7508696000634534],
						[60.551911002258066, 2.7508973742625868],
						[60.551930500718512, 2.7509084743779626],
						[60.551930503481039, 2.7509084756718827],
						[60.551930508028519, 2.7509084766201526],
						[60.551930509898568, 2.7509084780868758],
						[60.551947110475062, 2.750916878535957],
						[60.551947112260017, 2.7509168781903233],
						[60.551947116000136, 2.7509168811237821],
						[60.551947117870185, 2.750916882590503],
						[60.55200268491226, 2.7509418673962571],
						[60.552008159089844, 2.7509473418413211],
						[60.552008174050272, 2.7509473535751483],
						[60.552008208433477, 2.7509473761787446],
						[60.552008225986221, 2.7509473855817648],
						[60.552036026086398, 2.7509584858044831],
						[60.552036027956461, 2.7509584872712103],
						[60.552036033396419, 2.7509584880466886],
						[60.552036036158931, 2.7509584893406025],
						[60.552044325933018, 2.7509612861586383],
						[60.552074916736792, 2.7509751818339794],
						[60.552074921369368, 2.7509751845946244],
						[60.552074927701803, 2.7509751851972752],
						[60.552074931441922, 2.7509751881307349],
						[60.552127729798819, 2.7509945862562328],
						[60.552149929356347, 2.7510029871342283],
						[60.552149930248824, 2.7510029869614119],
						[60.552216620110983, 2.7510279836293643],
						[60.552227710441883, 2.7510334789171877],
						[60.55223320941343, 2.7510362784852891],
						[60.552233216808524, 2.7510362825399],
						[60.552233232406117, 2.7510362886638968],
						[60.552233239716131, 2.7510362909061308],
						[60.55227773934508, 2.75105018920571],
						[60.552302723065765, 2.7510584844792785],
						[60.552308209116781, 2.7510612774149199],
						[60.552308219274401, 2.7510612827634562],
						[60.552308240397053, 2.7510612914753714],
						[60.552308251362049, 2.7510612948387165],
						[60.552319344685927, 2.7510640920051004],
						[60.552380531927717, 2.751083488208808],
						[60.552394325218117, 2.7510890856212487],
						[60.552394327980629, 2.7510890869151865],
						[60.552394336183099, 2.7510890889846311],
						[60.552394339923232, 2.7510890919181281],
						[60.552455539912415, 2.7511084912235004],
						[60.552455553554843, 2.7511084940684301],
						[60.552455585302084, 2.7511084988942276],
						[60.552455599751902, 2.7511084997539683],
						[60.55245827002549, 2.7511085002628302],
						[60.552486041915543, 2.7511168903472107],
						[60.552486042893129, 2.7511168919867792],
						[60.552486044678069, 2.7511168916411526],
						[60.552486045655634, 2.7511168932807251],
						[60.552536046133326, 2.7511307928495761],
						[60.552574944999712, 2.751141891363293],
						[60.552574946869768, 2.7511418928300544],
						[60.552574951417256, 2.7511418937783687],
						[60.55257495320221, 2.7511418934327394],
						[60.552586043714541, 2.7511445924294189],
						[60.55261103585169, 2.751152990456315],
						[60.552611042184132, 2.7511529910590014],
						[60.55261105501917, 2.7511529958891892],
						[60.552611061351591, 2.7511529964918773],
						[60.552666652624787, 2.7511640944357447],
						[60.552686027382791, 2.7511695877751339],
						[60.552691610585356, 2.7511723784065247],
						[60.552691624398001, 2.7511723848763059],
						[60.55269165274553, 2.7511723940182615],
						[60.552691667280449, 2.7511723966904134],
						[60.552758251166992, 2.7511834949442093],
						[60.552774936149312, 2.7511890903295422],
						[60.552774943459312, 2.7511890925718454],
						[60.552774957101754, 2.751189095416827],
						[60.552774964411746, 2.7511890976591298],
						[60.552836050120256, 2.7512001943682116],
						[60.552844336235786, 2.751202990184153],
						[60.552844339890775, 2.7512029913053011],
						[60.552844348093259, 2.7512029933747773],
						[60.552844350855779, 2.7512029946687568],
						[60.552855551403518, 2.7512057931030558],
						[60.552855556036114, 2.7512057958637914],
						[60.552855566023538, 2.7512057975876578],
						[60.552855569678528, 2.7512057987088054],
						[60.552911063726455, 2.7512140973049983],
						[60.552936056551069, 2.7512195946961255],
						[60.552936068323447, 2.7512195960743671],
						[60.552936089190773, 2.7512195993492612],
						[60.552936100070674, 2.7512196009003174],
						[60.552941677381497, 2.7512195999790507],
						[60.552977754735792, 2.7512279951070502],
						[60.552977759283259, 2.7512279960553867],
						[60.552977769270697, 2.7512279977792624],
						[60.552977773818178, 2.7512279987275994],
						[60.553019373584647, 2.7512334974636228],
						[60.553019380002176, 2.7512334998787473],
						[60.553019392581945, 2.7512334992717435],
						[60.553019399806836, 2.7512334997016366],
						[60.553024981665132, 2.7512334997302448],
						[60.553086063801608, 2.7512445972717932],
						[60.553086065586548, 2.751244596926163],
						[60.553086070134029, 2.7512445978745048],
						[60.553086071918983, 2.7512445975288733],
						[60.553105572409962, 2.7512473982560612],
						[60.55310557874239, 2.7512473988587716],
						[60.5531055931922, 2.751247399718562],
						[60.553105600417112, 2.7512474001484564],
						[60.553116685807517, 2.7512474007441949],
						[60.55313606997769, 2.7512501983938971],
						[60.553188864935926, 2.7512584966844029],
						[60.553202761001245, 2.7512612962767684],
						[60.553202763763771, 2.7512612975707484],
						[60.553202771881161, 2.7512612978278268],
						[60.553202776428641, 2.7512612987761722],
						[60.553272175651273, 2.7512695989503264],
						[60.553294375145057, 2.751272398314859],
						[60.553294377015114, 2.7512723997816577],
						[60.553294379692524, 2.7512723992632098],
						[60.55329438236997, 2.7512723987447569],
						[60.553324972628317, 2.7512751974235252],
						[60.553355563847632, 2.7512807978210754],
						[60.553355572857498, 2.7512807979053591],
						[60.553355590962298, 2.7512807998863353],
						[60.553355599972171, 2.7512807999706008],
						[60.553361088161992, 2.7512807998523834],
						[60.553383275750356, 2.7512834991144599],
						[60.553383280212721, 2.7512834982503755],
						[60.553383286630257, 2.7512835006655321],
						[60.553383291092622, 2.7512834998014508],
						[60.553444386244735, 2.7512863008358788],
						[60.553474981857747, 2.7512890984952239],
						[60.553474983727781, 2.7512890999620505],
						[60.55347498729769, 2.7512890992707812],
						[60.553474989167739, 2.7512891007375875],
						[60.553527787138457, 2.7512919008066707],
						[60.553561084384064, 2.7512945996447451],
						[60.553561088039075, 2.7512946007659194],
						[60.553561096071348, 2.7512945992105626],
						[60.553561099726366, 2.751294600331736],
						[60.553580600148258, 2.7512945992052424],
						[60.553616692255041, 2.7512946005420527],
						[60.553652785067534, 2.7512973981800815],
						[60.553652790592572, 2.7512974007680757],
						[60.553652804064804, 2.7512973999882546],
						[60.553652810397239, 2.7512974005909938],
						[60.553708304726186, 2.7512945995904472],
						[60.553741700421973, 2.7512945999751435],
						[60.553741703099405, 2.7512945994566844],
						[60.553741710324303, 2.7512945998865912],
						[60.553741713001727, 2.7512945993681339],
						[60.553783306111931, 2.7512919004035385],
						[60.553800000304719, 2.7512918994712021],
						[60.553800022701729, 2.7512918969631861],
						[60.553800068132887, 2.751291886336912],
						[60.553800089382108, 2.7512918785643206],
						[60.55380564703303, 2.7512890996312462],
						[60.553833299670551, 2.751289100097325],
						[60.553833306895449, 2.7512891005272468],
						[60.55383332036768, 2.7512890997474106],
						[60.553833326615006, 2.7512890985376748],
						[60.553894427402874, 2.7512807985933252],
						[60.553894429187856, 2.7512807982476808],
						[60.553894433650221, 2.7512807973835796],
						[60.553894436327639, 2.7512807968651192],
						[60.553925034835494, 2.751275197263698],
						[60.553988933748229, 2.7512640966642068],
						[60.553988948920299, 2.7512640937262405],
						[60.553988977309331, 2.7512640845710026],
						[60.553988991418755, 2.7512640781809119],
						[60.553994463177233, 2.7512612920807435],
						[60.554011133373145, 2.7512584974721666],
						[60.554011137835516, 2.75125849660806],
						[60.554011149437692, 2.7512584943613749],
						[60.554011153814976, 2.7512584916847889],
						[60.554030649214674, 2.7512529936513954],
						[60.554091743780084, 2.7512390944963698],
						[60.554091745650133, 2.7512390959632196],
						[60.554091748242463, 2.7512390936322539],
						[60.55409174913494, 2.7512390934594313],
						[60.554102848989132, 2.7512362947511093],
						[60.554102851581455, 2.7512362924201468],
						[60.554102857828788, 2.7512362912103812],
						[60.55410286139869, 2.7512362905190901],
						[60.554163950045506, 2.7512168945749482],
						[60.554191738750013, 2.7512112957552435],
						[60.554191768031508, 2.7512112864270684],
						[60.554191819751473, 2.7512112562930766],
						[60.554191844145095, 2.7512112387665932],
						[60.55419451239441, 2.7512084724429893],
						[60.554255662928433, 2.7511862898500512],
						[60.554283357301301, 2.7511779914004104],
						[60.554283365333575, 2.7511779898449769],
						[60.55428338122794, 2.7511779831091299],
						[60.554283389175112, 2.7511779797412061],
						[60.554305682792098, 2.7511668826257516],
						[60.554372276692696, 2.7511390842924341],
						[60.554372280262598, 2.7511390836011196],
						[60.554372287317314, 2.7511390804060039],
						[60.554372289994724, 2.7511390798875222],
						[60.554383389772866, 2.7511334791377267],
						[60.554383390665329, 2.7511334789649018],
						[60.554383394150136, 2.7511334764610953],
						[60.554383395042599, 2.7511334762882704],
						[60.554419490427144, 2.7511140786138477],
						[60.554461181982269, 2.751094583003824],
						[60.554480678275617, 2.7510862844330082],
						[60.554480681760396, 2.7510862819291928],
						[60.554480688815119, 2.7510862787340495],
						[60.554480693192403, 2.7510862760574013],
						[60.554538991097125, 2.7510557785024501],
						[60.554550090063422, 2.7510501796771778],
						[60.5545500979255, 2.7510501744966911],
						[60.554550112757163, 2.751050164308531],
						[60.554550119726763, 2.751050159300874],
						[60.554561200604539, 2.7510418735356614],
						[60.554580679081191, 2.7510334832459855],
						[60.554580682651078, 2.7510334825546594],
						[60.554580692298096, 2.7510334770284803],
						[60.554580695868005, 2.7510334763371493],
						[60.554641796254742, 2.7510001752282198],
						[60.554641797147205, 2.751000175055387],
						[60.554641798039711, 2.7510001748825492],
						[60.554641798932174, 2.7510001747097168],
						[60.554661198795706, 2.7509890743964038],
						[60.554661199603068, 2.7509890724110635],
						[60.554661202280499, 2.7509890718925614],
						[60.554661203172962, 2.7509890717197298],
						[60.554702895831902, 2.7509640757800975],
						[60.554730689394972, 2.7509501793519724],
						[60.55473070422665, 2.7509501691636991],
						[60.554730730405169, 2.7509501512910357],
						[60.554730743366783, 2.7509501396359233],
						[60.554736221797313, 2.7509445610692067],
						[60.554786196041341, 2.7509168747688859],
						[60.554822294737825, 2.7508973753408723],
						[60.554822298307705, 2.7508973746495231],
						[60.554822301792498, 2.7508973721456575],
						[60.55482230527732, 2.7508973696417867],
						[60.554858400709925, 2.7508751730192826],
						[60.554908396689065, 2.7508473743721162],
						[60.554908434596264, 2.7508473377667784],
						[60.554908486355288, 2.7508472509246849],
						[60.554908500207119, 2.7508472006879461],
						[60.554908499671782, 2.7508445999446645],
						[60.554913899631494, 2.7508446003617895],
						[60.554913927383318, 2.7508445968162962],
						[60.554913977999071, 2.7508445815268465],
						[60.554914002733064, 2.7508445712497669],
						[60.554927900914961, 2.7508362719422221],
						[60.555000095012794, 2.75079457551089],
						[60.555033388651211, 2.7507779784725943],
						[60.555033391328656, 2.750777977954062],
						[60.555033394813435, 2.750777975450168],
						[60.555033395705912, 2.7507779752773254],
						[60.555088996001352, 2.7507473753159002],
						[60.555089008240671, 2.7507473674584957],
						[60.555089030849288, 2.7507473502768054],
						[60.555089041218544, 2.7507473409525574],
						[60.555091817956452, 2.7507445644709962],
						[60.555152889309817, 2.7507140790438545],
						[60.555180689002185, 2.7507001791625423],
						[60.555180689894662, 2.7507001789896965],
						[60.555180690787139, 2.7507001788168517],
						[60.555180691679602, 2.7507001786440077],
						[60.555255689995867, 2.7506612782437241],
						[60.555266746916772, 2.7506558006832971],
						[60.555272199957429, 2.7506557999299277],
						[60.555272224139344, 2.7506557970755892],
						[60.555272270462922, 2.7506557862749781],
						[60.555272291712086, 2.7506557785015571],
						[60.555358391632211, 2.7506112782251337],
						[60.555358394309621, 2.7506112777065881],
						[60.555358396901944, 2.7506112753754945],
						[60.555358398601797, 2.7506112732172472],
						[60.555377894941017, 2.7506001756555003],
						[60.555388990398306, 2.7505945790158051],
						[60.555388991290783, 2.7505945788429544],
						[60.555388992183246, 2.7505945786701043],
						[60.555388992990601, 2.7505945766847106],
						[60.555447293431087, 2.7505640767730442],
						[60.555447305670398, 2.7505640689154585],
						[60.555447330063927, 2.7505640513877485],
						[60.555447341240537, 2.7505640400779163],
						[60.555450120566213, 2.7505612612170456],
						[60.555480693868233, 2.7505445759834659],
						[60.555538993401868, 2.750514076058431],
						[60.555538999564078, 2.7505140730359039],
						[60.555539012695853, 2.7505140650054232],
						[60.555539019665439, 2.7505140599974855],
						[60.555550112742011, 2.7505057659184184],
						[60.555594486055796, 2.7504779813742353],
						[60.555602862873783, 2.7504751901888129],
						[60.555602877875621, 2.7504751836251482],
						[60.555602906901704, 2.7504751688581281],
						[60.555602920033508, 2.7504751608276283],
						[60.555625105876402, 2.7504584702338604],
						[60.555641789091837, 2.7504501798596208],
						[60.55564179436157, 2.7504501770099283],
						[60.555641803116089, 2.7504501716562495],
						[60.555641806600896, 2.7504501691522605],
						[60.555702905181207, 2.75041126914727],
						[60.555716803414768, 2.7504029712441103],
						[60.555716803414768, 2.7504029712441103],
						[60.555716804307245, 2.750402971071253],
						[60.555766800242949, 2.7503723724084876],
						[60.555797295790128, 2.7503557768613871],
						[60.555797299189827, 2.7503557725447907],
						[60.555797307136963, 2.7503557691764873],
						[60.555797310621756, 2.7503557666724809],
						[60.555805705194608, 2.7503501698781854],
						[60.555825098913765, 2.7503390735703221],
						[60.555825099806242, 2.7503390733974613],
						[60.555825100698705, 2.7503390732246036],
						[60.555891795629336, 2.7503001752785829],
						[60.555897291203642, 2.75029737872757],
						[60.555897295580898, 2.7502973760506872],
						[60.555897305227894, 2.7502973705240561],
						[60.555897309605136, 2.7502973678471747],
						[60.555905705201226, 2.7502918695896268],
						[60.555972301091607, 2.7502529723289197],
						[60.55597230636134, 2.7502529694791593],
						[60.555972315923214, 2.7502529621399079],
						[60.555972321192961, 2.7502529592901444],
						[60.555983402769407, 2.7502445739432604],
						[60.556019483976733, 2.75022798272225],
						[60.556019490138929, 2.7502279796996163],
						[60.556019502378213, 2.7502279718417699],
						[60.556019506755483, 2.7502279691648464],
						[60.556050101967671, 2.7502084714115473],
						[60.556075097321731, 2.7501945742559433],
						[60.556075098214208, 2.7501945740830762],
						[60.556075099999148, 2.7501945737373461],
						[60.556075100891633, 2.7501945735644782],
						[60.556079752348367, 2.7501918673140686],
						[60.555724852063051, 2.7511220262320384],
						[60.552841661406767, 2.7516998042748719],
						[60.55284165158951, 2.751699806175691],
						[60.552841633825068, 2.7516998114441327],
						[60.55284162498539, 2.7516998149845575],
						[60.54996102539188, 2.7528692145682432],
						[60.549961018337115, 2.7528692177624894],
						[60.549961006012566, 2.7528692238054804],
						[60.549960998957808, 2.7528692269997266],
						[60.547944312123541, 2.7540415190777461],
						[60.54362496134047, 2.7557970006918397],
						[60.541030601448135, 2.7557859005812309],
						[60.541030577266021, 2.7557859034298282],
						[60.541030531834288, 2.7557859140445],
						[60.541030508799743, 2.7557859221557788],
						[60.536422108571131, 2.7581276217649195],
						[60.536422104193683, 2.7581276244389743],
						[60.536422092761299, 2.7581276303046214],
						[60.536422087576327, 2.7581276349627202],
						[60.532969308134348, 2.7604748217988488],
						[60.529799993101285, 2.7616441895484307],
						[60.525772318011661, 2.7604498258956531],
						[60.525349666647941, 2.7599079973744947],
						[60.526347345797994, 2.7588362369113568],
						[60.526347368365748, 2.75883619965883],
						[60.526347394419084, 2.7588361215307704],
						[60.52634739871214, 2.7588360786718549],
						[60.526019599634807, 2.7556832931103377],
						[60.526069600174488, 2.7539500195608717],
						[60.526372397295695, 2.7521556320690173],
						[60.52637239929264, 2.7521556170637251],
						[60.52637239882408, 2.7521555879161772],
						[60.526372398143586, 2.7521555734287095],
						[60.526116898090564, 2.7502749735904444],
						[60.526116897920389, 2.7502749699686042],
						[60.526116896687562, 2.7502749628976182],
						[60.526116895624909, 2.7502749594484306],
						[60.525264096048758, 2.7461110591323861],
						[60.525264091882867, 2.7461110471474579],
						[60.525264084443535, 2.7461110230048811],
						[60.525264079300044, 2.7461110093818664],
						[60.524489079272286, 2.7445638101923766],
						[60.524489069581115, 2.7445637956231517],
						[60.524489049391484, 2.7445637684682733],
						[60.524489037915359, 2.7445637542446315],
						[60.520461238334462, 2.740738754873639],
						[60.520461229961462, 2.7407387491872521],
						[60.520461215000346, 2.7407387374687358],
						[60.520461206627331, 2.7407387317823688],
						[60.517333406910893, 2.7387498315702477],
						[60.517333376522103, 2.7387498173620055],
						[60.517333314641554, 2.7387498037744082],
						[60.51733328297928, 2.7387498007742792],
						[60.516425023219369, 2.7388275971028309],
						[60.515495330581942, 2.7385278894912259],
						[60.515866723948037, 2.738483499272764],
						[60.515866724755263, 2.738483497289562],
						[60.51640560998635, 2.7384168998975023],
						[60.516980579874968, 2.7384280004525974],
						[60.517547162574225, 2.7385334963286851],
						[60.517547171669293, 2.7385334982203129],
						[60.517547187904036, 2.7385334987286267],
						[60.517547196106648, 2.7385335007931708],
						[60.518102795719571, 2.7385445994832782],
						[60.518102811869063, 2.7385445981811563],
						[60.51810284416807, 2.7385445955769061],
						[60.518102859254633, 2.7385445908268533],
						[60.518650059402503, 2.7383751913707668],
						[60.518650061187415, 2.738375191024911],
						[60.518650063779539, 2.738375188695676],
						[60.518650065564479, 2.7383751883498189],
						[60.519225065567241, 2.7381751884194134],
						[60.519225067352153, 2.7381751880735394],
						[60.51979176735864, 2.7379751882900201],
						[60.519791777982888, 2.7379751844041427],
						[60.519791799953332, 2.7379751728383943],
						[60.519791811299527, 2.7379751651585265],
						[60.52034731093979, 2.7376029655134779],
						[60.52034731540207, 2.7376029646486737],
						[60.520347321478759, 2.7376029598168579],
						[60.520347324070876, 2.7376029574874123],
						[60.520902918667524, 2.7371612612797307],
						[60.521466814035485, 2.7367723649335569],
						[60.521466826103513, 2.7367723534586372],
						[60.521466849432379, 2.7367723324924111],
						[60.521466859630237, 2.7367723195528684],
						[60.522030760134122, 2.7360195199904922],
						[60.52203077963128, 2.7360194559140933],
						[60.522030773661754, 2.7360193291695771],
						[60.522030749087534, 2.7360192663284559],
						[60.521580748575659, 2.735519266249447],
						[60.521580743857534, 2.7355192616827466],
						[60.521580734421299, 2.7355192525493632],
						[60.521580728725432, 2.735519246345083],
						[60.521005728925822, 2.7350359477829826],
						[60.521005726077867, 2.7350359446809502],
						[60.521005719574838, 2.7350359404604681],
						[60.52100571761936, 2.7350359371853954],
						[60.520447317929374, 2.7346304380751159],
						[60.520447300375722, 2.7346304286891474],
						[60.520447265353674, 2.7346304117277365],
						[60.520447246100403, 2.7346304044983962],
						[60.519908359542399, 2.7345026086811939],
						[60.519344473427935, 2.734280414697646],
						[60.519344471557716, 2.7342804132332637],
						[60.519344470665274, 2.7342804134063083],
						[60.519344469772811, 2.7342804135793535],
						[60.518777873282367, 2.7340692135864733],
						[60.518211196759466, 2.7338331235001094],
						[60.51764451461073, 2.7334331377562715],
						[60.517644513632952, 2.7334331361189341],
						[60.517644511762732, 2.7334331346546672],
						[60.517644511762732, 2.7334331346546672],
						[60.517077912250592, 2.7330526336391046],
						[60.517077910380365, 2.7330526321748869],
						[60.517077907703026, 2.7330526326940752],
						[60.51707790672527, 2.7330526310567902],
						[60.516530707429105, 2.7327081311439509],
						[60.5165306927233, 2.732708124861468],
						[60.516530665096639, 2.7327081119503513],
						[60.516530651368633, 2.732708107305124],
						[60.515875083181456, 2.7325359158314795],
						[60.515075110663346, 2.7320054337494626],
						[60.515075093109523, 2.7320054243663137],
						[60.515075058172577, 2.7320054092204651],
						[60.515075039004536, 2.7320054038039299],
						[60.514247267669823, 2.7318387106599813],
						[60.513483392932656, 2.7314359234586987],
						[60.513483381881954, 2.7314359182951069],
						[60.513483358888095, 2.7314359081410262],
						[60.513483348008059, 2.7314359065977372],
						[60.512927848071534, 2.7312998068645284],
						[60.512927844416424, 2.7312998057467475],
						[60.512927836213727, 2.7312998036842546],
						[60.51292783166614, 2.7312998027395592],
						[60.512361132653822, 2.7312081034167019],
						[60.511802834377008, 2.7311109032581165],
						[60.511802828936986, 2.7311109024865305],
						[60.511802818949413, 2.7311109007703118],
						[60.511802814401825, 2.7311108998256666],
						[60.511247233229469, 2.7310720018959405],
						[60.510583351749311, 2.7308942076175433],
						[60.510583344439077, 2.7308942053822025],
						[60.510583329011467, 2.7308942028945786],
						[60.510583320808749, 2.7308942008323025],
						[60.509919447971441, 2.73082480450651],
						[60.509361180712851, 2.7306026176553111],
						[60.50875842814014, 2.7303081398409521],
						[60.508280755998392, 2.7297081752267869],
						[60.508280702188095, 2.729708141829871],
						[60.508280583694997, 2.729708111846413],
						[60.508280519989974, 2.7297081168966542],
						[60.508013820190115, 2.7298248175480566],
						[60.508013788744897, 2.7298248382578771],
						[60.508013737968348, 2.7298248882863883],
						[60.508013716937491, 2.7298249197610804],
						[60.507602617373635, 2.7307610207347479],
						[60.507602615674074, 2.7307610228906793],
						[60.507602614059842, 2.7307610268564355],
						[60.507602613338086, 2.7307610306491128],
						[60.507208113311719, 2.7318193301650298],
						[60.506744212719468, 2.733058230015283],
						[60.506297012531654, 2.7342388281845991],
						[60.506297012616947, 2.7342388299943465],
						[60.505860913159061, 2.7353971303004752],
						[60.50586091226662, 2.7353971304733902],
						[60.505860912351885, 2.7353971322831225],
						[60.50586091065226, 2.7353971344387045],
						[60.505422010948834, 2.7366471332041442],
						[60.505422008612463, 2.7366471409616446],
						[60.505422005639446, 2.7366471543211501],
						[60.505422004110308, 2.7366471600960556],
						[60.50519980504442, 2.7377471551202421],
						[60.504924805963604, 2.7388610524169308],
						[60.504585907037495, 2.7402305451428441],
						[60.504210909396022, 2.7414443406387545],
						[60.503863710975693, 2.7425277336354581],
						[60.503416513833933, 2.7436749263995615],
						[60.502755415962703, 2.7453082215167042],
						[60.502288720092317, 2.7463555122079657],
						[60.501744226689333, 2.7473831996673495],
						[60.501188731926014, 2.7482609914318967],
						[60.500616535943344, 2.749127686372463],
						[60.500060940224579, 2.7498859784191452],
						[60.499497042171058, 2.7506109770830518],
						[60.499497039578678, 2.7506109794098492],
						[60.499497037156409, 2.7506109853555012],
						[60.4994970354565, 2.7506109875098428],
						[60.498941534902748, 2.7514220868459418],
						[60.498941534095316, 2.7514220888277707],
						[60.498941530610452, 2.7514220913268885],
						[60.49894152980302, 2.7514220933087166],
						[60.498377629682281, 2.7523276937382968],
						[60.498377627259934, 2.752327699683633],
						[60.498377622245179, 2.7523277079555384],
						[60.498377619822826, 2.7523277139008577],
						[60.497822020257118, 2.7534805137212004],
						[60.497822020257118, 2.7534805137212004],
						[60.497822019449657, 2.7534805157029099],
						[60.497269219250754, 2.7546610158478848],
						[60.497269219250754, 2.7546610158478848],
						[60.497269217550787, 2.7546610180018574],
						[60.497269216658296, 2.754661018174188],
						[60.496794216963927, 2.7557221176619064],
						[60.496794216241419, 2.7557221214527918],
						[60.496794212203923, 2.7557221313607632],
						[60.496794210588909, 2.7557221353239472],
						[60.496572010596218, 2.7563693350035039],
						[60.496572006006119, 2.7563693523207373],
						[60.49657200056582, 2.756369389884703],
						[60.496571999630646, 2.7563694083221275],
						[60.496591499800104, 2.7568417078425109],
						[60.496591511741919, 2.7568417511798353],
						[60.496591554112975, 2.7568418288090233],
						[60.496591583649732, 2.7568418632731673],
						[60.496841583930006, 2.7570195635835386],
						[60.496841628851797, 2.7570195804729458],
						[60.496841721330661, 2.7570195936603801],
						[60.496841767910261, 2.7570195883213713],
						[60.497469467965971, 2.7567918889181797],
						[60.497469468858469, 2.7567918887459042],
						[60.497469470558478, 2.7567918865920062],
						[60.497469472343454, 2.7567918862474636],
						[60.498027867594146, 2.7565751874688327],
						[60.498575063851241, 2.7563890885072762],
						[60.498575079746125, 2.7563890817872192],
						[60.498575111450926, 2.7563890665377317],
						[60.498575125390865, 2.7563890565434872],
						[60.499119524670057, 2.7559529568188093],
						[60.499119529855022, 2.755952952166099],
						[60.499119539332476, 2.7559529430330216],
						[60.499119543624928, 2.7559529385526229],
						[60.499658443811789, 2.7553945386973031],
						[60.499658447296746, 2.7553945361985459],
						[60.499658453289193, 2.7553945295638864],
						[60.499658455796663, 2.7553945254280028],
						[60.500214041785945, 2.7547029437806105],
						[60.500625097754757, 2.7543779765114698],
						[60.500663491872615, 2.7543642585680463],
						[60.50027763692384, 2.7551192789494463],
						[60.49917486203725, 2.7561748557922541],
						[60.499174856852278, 2.7561748604449372],
						[60.4991748465673, 2.7561748715597139],
						[60.499174842274826, 2.7561748760400793],
						[60.496972042216825, 2.7590109773506977],
						[60.496972036309124, 2.759010985793434],
						[60.496972025386249, 2.7590110025066754],
						[60.496972019563515, 2.7590110127587244],
						[60.494913725949459, 2.7632416017523238],
						[60.493588741618183, 2.7652581762575954],
						[60.489538752855509, 2.7696387634631496],
						[60.489538750347549, 2.7696387675966343],
						[60.489538745246833, 2.7696387740546329],
						[60.489538741761564, 2.769638776551044],
						[60.487755453156439, 2.7719331621588505],
						[60.485066565677599, 2.7743442502293059],
						[60.485066562361681, 2.774344256342236],
						[60.485066552882842, 2.7743442654658099],
						[60.485066549482205, 2.7743442697700336],
						[60.482824871685374, 2.7769442436708895],
						[60.480438802753049, 2.7782720258593785],
						[60.48043878354072, 2.7782720386760942],
						[60.480438749024856, 2.7782720708571182],
						[60.48043873363666, 2.7782720884129799],
						[60.479463745426081, 2.7797276717922044],
						[60.476774885977292, 2.782416531351922],
						[60.476102790422338, 2.7827025865625243],
						[60.475247304326999, 2.782427623300102],
						[60.473844537174848, 2.7811081532151887],
						[60.473844518562458, 2.7811081403710132],
						[60.473844482399436, 2.7811081181274524],
						[60.473844462171222, 2.7811081092423824],
						[60.473055661646171, 2.7808526097443322],
						[60.473055618211951, 2.7808526053170795],
						[60.473055533167063, 2.7808526161797924],
						[60.473055492448864, 2.7808526312983397],
						[60.470024946617059, 2.7827886955469121],
						[60.468461111879471, 2.7826915008298041],
						[60.468461079578788, 2.7826915033830986],
						[60.468461018247275, 2.7826915206302747],
						[60.4684609882393, 2.7826915336877232],
						[60.466899887882121, 2.7837470343658084],
						[60.466899872239281, 2.7837470464881959],
						[60.466899843884988, 2.7837470756421938],
						[60.46689983206609, 2.7837470925025012],
						[60.463891531947795, 2.7884331919474965],
						[60.463891526122545, 2.7884332021835725],
						[60.463891514556558, 2.788433224463303],
						[60.463891509708361, 2.7884332363357873],
						[60.46327481290588, 2.7902888277608793],
						[60.461977617740459, 2.7931693189007634],
						[60.461977615316151, 2.7931693248363132],
						[60.461977609574951, 2.7931693368784227],
						[60.461977608043199, 2.7931693426429294],
						[60.461094214410139, 2.7961360245219775],
						[60.45852482626038, 2.8011666004109301],
						[60.457897045799847, 2.8021470695464026],
						[60.456372066840323, 2.8035054515481774],
						[60.456372057359204, 2.8035054606547694],
						[60.456372036864749, 2.8035054846309824],
						[60.456372029337395, 2.8035054970105855],
						[60.455252635080456, 2.8053637868121815],
						[60.453533155045179, 2.807519260857589],
						[60.452233168630535, 2.8086470489334574],
						[60.4522331524295, 2.8086470684365858],
						[60.452233122873714, 2.8086471105452615],
						[60.45223311219668, 2.8086471326391966],
						[60.451560915596204, 2.8105193221878673],
						[60.450666539178357, 2.8123415732076529],
						[60.448130471909266, 2.8144554456767366],
						[60.44813046761459, 2.8144554501420429],
						[60.448130459833656, 2.8144554570955429],
						[60.44813045724004, 2.8144554594133648],
						[60.445735980115188, 2.8169137369606294],
						[60.443941609009748, 2.8178304212147234],
						[60.443941581203383, 2.8178304429210446],
						[60.443941534128719, 2.817830495641072],
						[60.443941514776313, 2.8178305248482296],
						[60.443719255457196, 2.8183748264773394],
						[60.441697199251848, 2.8188025953774547],
						[60.438386138617233, 2.8181387045968638],
						[60.438386107932288, 2.8181387031578331],
						[60.43838604698287, 2.8181387093110937],
						[60.438386016802497, 2.8181387187096631],
						[60.436983216738462, 2.8187776188473377],
						[60.436983174262082, 2.8187776542774996],
						[60.436983116372076, 2.8187777454901313],
						[60.436983099981788, 2.8187777996365631],
						[60.436983100012753, 2.8192331004329447],
						[60.436758299601607, 2.819233099644991],
						[60.436758234137258, 2.8192331248782994],
						[60.436758134682506, 2.8192332130786824],
						[60.436758102477356, 2.8192332757054812],
						[60.436610906283377, 2.8204193362506667],
						[60.435147055453164, 2.8230054503099629],
						[60.434519307268005, 2.8233331227407485],
						[60.434519280353186, 2.8233331442655936],
						[60.434519234000518, 2.8233331931795114],
						[60.434519216431951, 2.8233332220346234],
						[60.434269255556003, 2.8239248258996175],
						[60.43272495808295, 2.8242526039899487],
						[60.432724916718058, 2.824252624620359],
						[60.432724846432407, 2.824252681731275],
						[60.432724818320231, 2.8242527162358351],
						[60.43242206541742, 2.8249192135799897],
						[60.427672180101972, 2.8254054004009279],
						[60.4276721623336, 2.8254054056047004],
						[60.427672126712842, 2.8254054142065748],
						[60.427672109921076, 2.8254054210461361],
						[60.426644309563443, 2.8259220206662845],
						[60.426644295617464, 2.8259220306069728],
						[60.426644268534126, 2.8259220485128105],
						[60.426644257349992, 2.8259220597494812],
						[60.42477487992155, 2.827838736669595],
						[60.420513808966348, 2.8300220216407572],
						[60.420513787154192, 2.8300220367158415],
						[60.420513748328702, 2.8300220732386245],
						[60.420513731231438, 2.830022092880994],
						[60.420388730903348, 2.8302192924993443],
						[60.420388721114911, 2.830219314394383],
						[60.420388706085127, 2.8302193591411511],
						[60.420388700843752, 2.8302193819928849],
						[60.420210914323782, 2.8322026400176119],
						[60.418808252623002, 2.8325442053123342],
						[60.418808184202234, 2.8325442638451626],
						[60.418808172879778, 2.8325442914933996],
						[60.418808106328171, 2.832544351492154],
						[60.41863873584289, 2.8332331270104327],
						[60.417699962728207, 2.8334109026677372],
						[60.417699920468905, 2.833410923445483],
						[60.417699849118172, 2.8334109770677052],
						[60.417699819301859, 2.8334110136921309],
						[60.417547075591443, 2.833733095746847],
						[60.414216707195095, 2.833613699329129],
						[60.4142166337774, 2.8336137278446083],
						[60.414216529542685, 2.8336138295885056],
						[60.41421649961832, 2.8336139026473335],
					],
					[
						[60.508845572650294, 2.7933332236738915],
						[60.512214084107939, 2.7831944825973971],
						[60.513789066873613, 2.7804112103138747],
						[60.51587515863028, 2.7776807202597165],
						[60.51587516211567, 2.7776807177619576],
						[60.515875168832338, 2.7776807073351715],
						[60.515875172148299, 2.7776807012165494],
						[60.5171473717986, 2.7755362026162032],
						[60.517147376814783, 2.7755361943428443],
						[60.517147384977356, 2.7755361763293873],
						[60.517147388123739, 2.7755361665893057],
						[60.517687754460539, 2.774019760546989],
						[60.518249810165145, 2.774093666563533],
						[60.51759702067146, 2.7759082070252044],
						[60.516505435602085, 2.7774637843289911],
						[60.516505433986445, 2.7774637882936029],
						[60.516505428970149, 2.7774637965665159],
						[60.516505425569463, 2.7774638008748043],
						[60.514480429614878, 2.7810165939123106],
						[60.512402634904639, 2.7840387870609189],
						[60.51240262988788, 2.784038795331734],
						[60.512402619939031, 2.7840388136836558],
						[60.51240261500692, 2.7840388237647482],
						[60.511472020204941, 2.7862943127634074],
						[60.511347250882153, 2.7865162025662871],
						[60.511283490633012, 2.7863166384592217],
						[60.511283397403254, 2.7863165705351212],
						[60.511283207216138, 2.7863165669091488],
						[60.511283111320495, 2.7863166346561572],
						[60.508845572650294, 2.7933332236738915],
					],
					[
						[60.536212552089268, 2.765487719654979],
						[60.536714056975867, 2.763439101721997],
						[60.540741698576674, 2.7634529994670798],
						[60.543044399349135, 2.7634612993651011],
						[60.54304441906902, 2.7634612973840067],
						[60.54304445663886, 2.7634612919547372],
						[60.543044475211474, 2.7634612847103481],
						[60.545925038935984, 2.7622919004242559],
						[60.546233713919811, 2.7622929827405271],
						[60.544080463177359, 2.7648248538322573],
						[60.543408241008507, 2.7653219954944674],
						[60.542600082073612, 2.7652637042273458],
						[60.541386232068888, 2.7641942495546195],
						[60.541386220849418, 2.7641942407522579],
						[60.541386197687793, 2.764194226943538],
						[60.541386185575817, 2.7641942183135528],
						[60.540291785625406, 2.7636748199326564],
						[60.540291755408674, 2.7636748093155452],
						[60.540291692977085, 2.7636748030924956],
						[60.540291662377363, 2.7636748035182945],
						[60.538916661886446, 2.7639442031539692],
						[60.538916645906383, 2.7639442080682377],
						[60.538916615816163, 2.7639442193637169],
						[60.538916600813486, 2.7639442259173146],
						[60.536212552089268, 2.765487719654979],
					],
				],
				[
					[60.379833112701718, 2.6702527663167248],
					[60.379833131046269, 2.6702528864540072],
					[60.379833160742812, 2.6702529425248414],
					[60.382008160633262, 2.6723668430826724],
					[60.382008163396378, 2.672366844363979],
					[60.382008167223908, 2.6723668490767998],
					[60.382008169094661, 2.6723668505318829],
					[60.382897068566692, 2.6731418501910142],
					[60.38289707515731, 2.6731418561854507],
					[60.38289708825252, 2.673141866371624],
					[60.382897094757119, 2.6731418705633758],
					[60.383794266757356, 2.6736945523058924],
					[60.384072031992048, 2.674030711722267],
					[60.385352622731411, 2.6764889925299689],
					[60.385352631450495, 2.676489005389485],
					[60.385352648802751, 2.6764890293056913],
					[60.385352659306598, 2.6764890418178306],
					[60.386694258862192, 2.6778223415264093],
					[60.386694260818828, 2.677822344784937],
					[60.386694266344996, 2.6778223473488025],
					[60.386694268301646, 2.6778223506073271],
					[60.388083159311691, 2.6790445420675435],
					[60.390702652224782, 2.6819390338355862],
					[60.390702666383604, 2.6819390474603138],
					[60.390702695335868, 2.6819390691267735],
					[60.390702711107622, 2.681939078798051],
					[60.392263805089776, 2.6827112762781518],
					[60.394155499158131, 2.6838168737774755],
					[60.394155525724237, 2.6838168831713687],
					[60.3941555822542, 2.683816897658283],
					[60.39415561204634, 2.6838168991445714],
					[60.396491712416797, 2.6836808007732911],
					[60.396491713309167, 2.683680800599725],
					[60.396491715008054, 2.6836807984491107],
					[60.396491715900446, 2.6836807982755424],
					[60.398047216240045, 2.6835529983340551],
					[60.398047232388791, 2.6835529970131238],
					[60.398047261751465, 2.6835529894813237],
					[60.398047275943661, 2.6835529849004387],
					[60.398883375974442, 2.6832084857347356],
					[60.398883379458077, 2.6832084832367333],
					[60.398883385618838, 2.6832084802179423],
					[60.398883389188363, 2.683208479523548],
					[60.401724900655765, 2.6817919732975781],
					[60.402527616983079, 2.683619479178724],
					[60.402527620089657, 2.6836194876769546],
					[60.402527626045156, 2.6836194992619595],
					[60.402527629958243, 2.683619505782763],
					[60.403741529779118, 2.6855918054738797],
					[60.403741550785369, 2.6855918305175845],
					[60.403741602270642, 2.6855918714802036],
					[60.403741630964895, 2.6855918877462717],
					[60.405138831083079, 2.6861084887922302],
					[60.405138852929596, 2.686108493646548],
					[60.405138898235656, 2.6861084994001221],
					[60.405138920716986, 2.6861084986689865],
					[60.406380572241623, 2.6859752046043432],
					[60.407266570254315, 2.68632516320947],
					[60.408572035420704, 2.6882279129595958],
					[60.408572034614153, 2.6882279149373014],
					[60.40857203639893, 2.688227914590243],
					[60.40857203639893, 2.688227914590243],
					[60.409819236020198, 2.6900112156160456],
					[60.409819247329551, 2.6900112261638389],
					[60.409819270119854, 2.6900112508679155],
					[60.40981928321397, 2.6900112610687348],
					[60.410472075873685, 2.6904834565603331],
					[60.41171927007003, 2.6915501519957092],
					[60.411719271048213, 2.691550153626594],
					[60.411719273811187, 2.6915501549105572],
					[60.411719274789377, 2.6915501565414406],
					[60.412608166889683, 2.6922584503659315],
					[60.413085939890209, 2.6927334232226605],
					[60.413499820818714, 2.6934472898710222],
					[60.413855414911396, 2.6942806754761159],
					[60.414038712823604, 2.6947583716532626],
					[60.414038750113818, 2.6947584172212768],
					[60.414038845082828, 2.6947584825420825],
					[60.414038901783449, 2.6947585006637986],
					[60.41462500203199, 2.694753000248042],
					[60.414625035050719, 2.6947529938325006],
					[60.414625097089818, 2.6947529726723807],
					[60.414625125938691, 2.6947529543187545],
					[60.414922176706327, 2.6945113775587095],
					[60.415338749126086, 2.6949862327017318],
					[60.415752621063675, 2.695461199820453],
					[60.416110903608327, 2.6966556405508126],
					[60.416169198452423, 2.6971332952301759],
					[60.415935932448761, 2.698449801986555],
					[60.415227797869598, 2.6984581004066999],
					[60.414633297791539, 2.6984636996154334],
					[60.414633275395751, 2.6984637021431848],
					[60.414633230054527, 2.6984637145901131],
					[60.414633207915827, 2.6984637225314612],
					[60.413688808417611, 2.6989498222963864],
					[60.41368879269708, 2.6989498326332146],
					[60.413688763126537, 2.6989498547647863],
					[60.413688750254593, 2.6989498681907604],
					[60.413158149710583, 2.6995554681632865],
					[60.413158124959196, 2.6995555348855813],
					[60.41315812496719, 2.6995556678236459],
					[60.413158150618948, 2.6995557338661644],
					[60.414108130966227, 2.7006223115266161],
					[60.415060917668768, 2.7026500833083369],
					[60.415655417691418, 2.7039583825136244],
					[60.415655422667243, 2.7039583924755406],
					[60.415655434318076, 2.7039584102484717],
					[60.415655440100664, 2.7039584182326353],
					[60.416010939883414, 2.704436219585332],
					[60.416010951277336, 2.7044362319447264],
					[60.416010975850007, 2.7044362563172504],
					[60.416010988943114, 2.7044362665257431],
					[60.416541565886114, 2.7047890512714758],
					[60.417549847170363, 2.7059779286876946],
					[60.417549850104415, 2.7059779335825316],
					[60.417549855801298, 2.7059779397627901],
					[60.41754985864975, 2.7059779428529063],
					[60.418266554761018, 2.7066890372741499],
					[60.418797051242294, 2.7072834328928943],
					[60.418797076535753, 2.707283453486903],
					[60.418797135790129, 2.7072834875297165],
					[60.41879716779502, 2.707283497715113],
					[60.419511067834023, 2.7073973972942627],
					[60.419511105572333, 2.7073973954393775],
					[60.419511178665111, 2.7073973794418955],
					[60.41951121312713, 2.707397365472386],
					[60.419866812867184, 2.7071529659302533],
					[60.419866837805884, 2.7071529410572381],
					[60.419866876631922, 2.7071528861688861],
					[60.419866892304078, 2.7071528558073505],
					[60.420039074034747, 2.7065529206693575],
					[60.421150084066539, 2.7057307878682924],
					[60.42246666978447, 2.7054530111825623],
					[60.423202675088973, 2.7058473597567141],
					[60.424124850975666, 2.7068751338913439],
					[60.424124854716531, 2.7068751368090203],
					[60.424124862283819, 2.7068751444494494],
					[60.424124865132278, 2.7068751475402353],
					[60.424777665281901, 2.7074695485903471],
					[60.424777682030118, 2.7074695599152694],
					[60.424777718118193, 2.7074695802406641],
					[60.424777736565652, 2.7074695894142295],
					[60.425486024392889, 2.707702985958079],
					[60.427144291970883, 2.7085251696965718],
					[60.428035946940447, 2.7092362344241319],
					[60.428391528232837, 2.7098307037469267],
					[60.4283915415823, 2.7098307193772184],
					[60.428391571044045, 2.7098307519238989],
					[60.428391587963219, 2.7098307668618777],
					[60.429069288088158, 2.7102890661394548],
					[60.429069324811657, 2.7102890808812123],
					[60.429069402121904, 2.7102890968617279],
					[60.42906944083829, 2.7102890966412496],
					[60.430169440822333, 2.7100557957614537],
					[60.430169445284463, 2.7100557948960375],
					[60.430169453316253, 2.7100557933382907],
					[60.430169456885942, 2.710055792645957],
					[60.431413956972015, 2.7096834925517292],
					[60.431413966703076, 2.7096834888420904],
					[60.431413985272812, 2.7096834815959436],
					[60.431413994025903, 2.709683476253923],
					[60.43277229371499, 2.7089640776086132],
					[60.432772320524293, 2.7089640541857958],
					[60.432772366746811, 2.7089640033084432],
					[60.432772385182027, 2.708963974221501],
					[60.433066885241971, 2.7082333751632954],
					[60.433066892246757, 2.708233351937821],
					[60.433066898945832, 2.7082333032609629],
					[60.433066898725677, 2.708233279615134],
					[60.432947398832823, 2.70703607997133],
					[60.432947397598014, 2.7070360729222118],
					[60.432947396020843, 2.7070360586507514],
					[60.432947393893599, 2.707036051774824],
					[60.432227994548946, 2.7041693555025064],
					[60.431991896260442, 2.702972161623153],
					[60.431991894133006, 2.7029721547476813],
					[60.431991889792492, 2.7029721391912296],
					[60.431991887579407, 2.7029721305102501],
					[60.431511298400046, 2.7016610605915288],
					[60.431450200180997, 2.7004638995740526],
					[60.431505800036298, 2.6993861115859987],
					[60.431619598981669, 2.6973472209136631],
					[60.43179459801317, 2.6961500285759947],
					[60.431794597755953, 2.6961500231595523],
					[60.431794599197765, 2.696150015590689],
					[60.431794599832934, 2.6961500100007902],
					[60.431850200079843, 2.6950694104982462],
					[60.431850199651059, 2.6950694014708629],
					[60.431850198879211, 2.6950693852215699],
					[60.431850199428567, 2.695069377826171],
					[60.431672399108599, 2.6935138766325557],
					[60.431672391660911, 2.693513852570923],
					[60.431672373110224, 2.6935138033363981],
					[60.431672360308212, 2.6935137803160418],
					[60.431316924862294, 2.6930388672268459],
					[60.431669587956627, 2.6920778684292048],
					[60.431669590290596, 2.6920778606866183],
					[60.431669594872744, 2.6920778433959867],
					[60.431669597120923, 2.6920778338479456],
					[60.43190019674261, 2.6907583348880051],
					[60.431900198269936, 2.6907583291243724],
					[60.431900198819058, 2.6907583217288895],
					[60.431900198561607, 2.6907583163125026],
					[60.432075198858968, 2.6887222174771375],
					[60.43218909867948, 2.6875222183606549],
					[60.43218910003494, 2.6875222089858739],
					[60.432189100068705, 2.6875221907574662],
					[60.432189098661169, 2.6875221800983673],
					[60.432069603888962, 2.6863278277157288],
					[60.432480773975882, 2.6852445049919664],
					[60.433011203761986, 2.6846418836104857],
					[60.433388936213476, 2.6845723972223179],
					[60.433388973349722, 2.6845723827004533],
					[60.433389038731796, 2.6845723371656676],
					[60.433389067149434, 2.6845723097638263],
					[60.433661248565251, 2.6841557381040948],
					[60.434133421991163, 2.683791857480915],
					[60.434133447645578, 2.6837918287936633],
					[60.434133485945637, 2.6837917630194008],
					[60.434133497698923, 2.6837917261062323],
					[60.434361126376729, 2.6820319568120041],
					[60.434580402409054, 2.6833306283323526],
					[60.435649801783399, 2.6916000254744139],
					[60.435649807275048, 2.6916000462743934],
					[60.435649820849726, 2.6916000855477593],
					[60.435649829910929, 2.6916001056532126],
					[60.437169208389214, 2.6940389697341369],
					[60.437619197319684, 2.697111094311627],
					[60.437180406036177, 2.6992610510654869],
					[60.436430409066567, 2.7017193419653029],
					[60.436430406010956, 2.7017193534932504],
					[60.436430401684603, 2.7017193762024236],
					[60.436430400413833, 2.7017193873836538],
					[60.436280407816525, 2.7041804557098588],
					[60.434461035256739, 2.7048109101607145],
					[60.43446100113767, 2.7048109313629758],
					[60.434460944036687, 2.7048109807167022],
					[60.434460920333564, 2.7048110126527352],
					[60.433710920322902, 2.7063499115166247],
					[60.433710909626868, 2.7063499518595591],
					[60.433710907146697, 2.7063500325192607],
					[60.433710913577762, 2.7063500731824446],
					[60.43493041378418, 2.7094111738828439],
					[60.434930421779214, 2.7094111905547438],
					[60.434930441167843, 2.7094112195946027],
					[60.43493045175456, 2.7094112339414083],
					[60.437960952412958, 2.7127612337481342],
					[60.437960981361002, 2.7127612554713196],
					[60.43796104507647, 2.7127612886768375],
					[60.43796107975836, 2.7127612983533167],
					[60.440688879878685, 2.7130390987462518],
					[60.44068890414524, 2.7130390976852872],
					[60.440688953399693, 2.7130390917781981],
					[60.44068897740965, 2.7130390852991426],
					[60.443711176759962, 2.7117807858086032],
					[60.443711198642838, 2.7117807724493579],
					[60.443711238582246, 2.7117807410048167],
					[60.443711258423626, 2.7117807225732702],
					[60.448530757655803, 2.7056001230019522],
					[60.448530766872935, 2.7056001084502053],
					[60.448530782629938, 2.7056000798666986],
					[60.448530789084188, 2.7056000640285238],
					[60.449880780724612, 2.7016000878746884],
					[60.451689057638298, 2.6988195232105761],
					[60.45292599566919, 2.6974800477696408],
					[60.450205436273684, 2.7013998860001669],
					[60.450205428670515, 2.701399896593903],
					[60.45020541711942, 2.701399918894051],
					[60.450205412279132, 2.7013999307739183],
					[60.448813712080295, 2.7051860302018818],
					[60.448813716740702, 2.7051861095124661],
					[60.448813780534628, 2.7051862393215322],
					[60.448813842431008, 2.7051862911064273],
					[60.454311042421033, 2.7068612910684884],
					[60.454311056064071, 2.7068612938886822],
					[60.45431108424259, 2.7068612993557069],
					[60.454311097800009, 2.706861300369122],
					[60.463938892948768, 2.7069695992488478],
					[60.47256938715379, 2.7075112990585706],
					[60.472569392593876, 2.7075112998256579],
					[60.472569404280861, 2.7075112993785679],
					[60.472569408828555, 2.7075113003190867],
					[60.483400008923923, 2.7070002006304419],
					[60.48340004364227, 2.7070001920539122],
					[60.483400108103034, 2.7070001649183135],
					[60.483400136867424, 2.7070001447243861],
					[60.487111236647607, 2.7035001449751435],
					[60.487111248627109, 2.7035001316923912],
					[60.487111269823188, 2.7035001038393029],
					[60.487111279846502, 2.7035000872867103],
					[60.490011279910348, 2.6975694876264096],
					[60.490011287084165, 2.6975694679746032],
					[60.490011296969783, 2.6975694295402399],
					[60.490011299595842, 2.6975694089489615],
					[60.490208500165451, 2.6927222073275718],
					[60.490208495943179, 2.692722175292726],
					[60.490208476790052, 2.6927221133107926],
					[60.490208462751553, 2.6927220831897496],
					[60.488880762787559, 2.6908498844652255],
					[60.488880757003933, 2.6908498764662196],
					[60.488880744630215, 2.6908498624508659],
					[60.488880737954247, 2.6908498546258786],
					[60.486969563230566, 2.689030478699697],
					[60.485761279007264, 2.6865999111671579],
					[60.485761275094099, 2.686599904629861],
					[60.485761267181829, 2.6865998897468448],
					[60.485761262376265, 2.6865998833836682],
					[60.482839062365215, 2.6825498824657839],
					[60.482839055774761, 2.682549876452184],
					[60.482839044206628, 2.6825498604600444],
					[60.48283903672381, 2.6825498546206661],
					[60.479419562962363, 2.6793192795152145],
					[60.478519585832316, 2.6774999256914445],
					[60.477811291047416, 2.6752610403614563],
					[60.477811289004507, 2.6752610352863151],
					[60.477811285897118, 2.6752610267696335],
					[60.47781128483264, 2.6752610233280936],
					[60.476800190992734, 2.6728305369929819],
					[60.475900193636001, 2.6691693516804453],
					[60.475900192399081, 2.6691693446236351],
					[60.475900188140521, 2.6691693308590936],
					[60.475900185118896, 2.6691693241513437],
					[60.474864085414204, 2.6666360244434597],
					[60.474864021373989, 2.6666359658126964],
					[60.474863870690506, 2.6666359223093856],
					[60.474863784853405, 2.6666359354544831],
					[60.473658185230711, 2.6674804359722484],
					[60.473658168793193, 2.6674804501350629],
					[60.473658138853729, 2.6674804833601278],
					[60.47365812705025, 2.6674805002655528],
					[60.472233128037701, 2.6699609972795444],
					[60.471280439086058, 2.6715109794405749],
					[60.469781952400346, 2.6731593146997148],
					[60.47138626959655, 2.6705945060915925],
					[60.472602968802327, 2.6686529056135666],
					[60.472602969694677, 2.668652905439048],
					[60.473850169835131, 2.6666612053653282],
					[60.473850174758283, 2.6666611952792856],
					[60.473850186303089, 2.6666611729503331],
					[60.47385019024771, 2.6666611612311661],
					[60.474450189833256, 2.6648111626573057],
					[60.474450194239033, 2.6648111417243792],
					[60.474450198675093, 2.6648111025394519],
					[60.474450198705384, 2.6648110842874475],
					[60.474300199234136, 2.6629693833268391],
					[60.4743001933928, 2.6629693552767186],
					[60.47430017385156, 2.6629693043641001],
					[60.474300160065432, 2.6629692796938973],
					[60.472930760379214, 2.6611387799911297],
					[60.472930729379712, 2.6611387532196029],
					[60.472930658082554, 2.6611387124444188],
					[60.472930617871178, 2.6611387002483742],
					[60.469900068708021, 2.66086920524871],
					[60.463550122941577, 2.6566304411990305],
					[60.456719532796072, 2.6505609495134741],
					[60.456719454242887, 2.650560926614332],
					[60.456719303815561, 2.6505609451576824],
					[60.456719233898788, 2.6505609898637745],
					[60.453410944276001, 2.6555109735231883],
					[60.449480456636685, 2.6595387607291761],
					[60.449480449756791, 2.6595387675450115],
					[60.449480437867926, 2.6595387826337826],
					[60.449480431966627, 2.6595387910813431],
					[60.447680439483086, 2.6623192817286552],
					[60.445860971446166, 2.664488742542932],
					[60.441788805441938, 2.6666804238536845],
					[60.441788800174024, 2.6666804267057338],
					[60.441788791422866, 2.6666804320611446],
					[60.441788786154937, 2.6666804349131952],
					[60.439980574238994, 2.6679303745116387],
					[60.438469473899808, 2.6673304131441147],
					[60.438469463019359, 2.667330411624381],
					[60.438469441978526, 2.6673304047990976],
					[60.438469431990413, 2.6673304031050331],
					[60.436650031880369, 2.6670387038193493],
					[60.436649961384951, 2.6670387175896737],
					[60.436649847877995, 2.6670387926133974],
					[60.436649805672644, 2.6670388518868466],
					[60.436188705791302, 2.6688888522935761],
					[60.436188703630954, 2.6688888636500909],
					[60.436188701267177, 2.6688888896258609],
					[60.436188699999192, 2.6688889008081178],
					[60.436199799940994, 2.6707305736326985],
					[60.435599817752347, 2.6728805096533907],
					[60.434086263828341, 2.6748688541105672],
					[60.434050199681231, 2.6743305862099862],
					[60.434050198702828, 2.6743305845785548],
					[60.434050199251018, 2.6743305771823538],
					[60.434050198272615, 2.6743305755509219],
					[60.433808497874423, 2.6724166746065303],
					[60.433808496637802, 2.6724166675586574],
					[60.433808494078484, 2.6724166516573771],
					[60.433808491949506, 2.6724166447836266],
					[60.433391891635573, 2.6709860436886048],
					[60.433391888528071, 2.6709860351837436],
					[60.433391883291527, 2.6709860198053188],
					[60.433391879291676, 2.6709860114746351],
					[60.432977979336243, 2.6701499109634188],
					[60.432977955305482, 2.6701498792092857],
					[60.432977897052275, 2.6701498286241132],
					[60.432977861937516, 2.6701498099672447],
					[60.43262226158037, 2.6700331097779739],
					[60.432622246065982, 2.6700331055175948],
					[60.432622214317064, 2.6700331007819078],
					[60.432622197190156, 2.6700331004808002],
					[60.431972197018318, 2.6700414998117594],
					[60.431972155163834, 2.6700415098042152],
					[60.431972080347023, 2.6700415462753266],
					[60.431972045599984, 2.6700415731023828],
					[60.43167484638537, 2.670402673477982],
					[60.431674840397172, 2.6704026801133791],
					[60.431674831183933, 2.670402694667025],
					[60.43167482608807, 2.6704027011282623],
					[60.431263725571341, 2.6711249002017259],
					[60.431263713907676, 2.6711249389213072],
					[60.431263705030183, 2.6711250171846621],
					[60.431263707816363, 2.6711250567284308],
					[60.431441507597462, 2.6717222573113339],
					[60.431441507597462, 2.6717222573113339],
					[60.43144150946825, 2.6717222587684182],
					[60.431622006046958, 2.6723194494724103],
					[60.431741501407416, 2.672916726318149],
					[60.431799798113474, 2.6737555824288859],
					[60.431627620358455, 2.6744748952824016],
					[60.430977697945714, 2.6750804218239068],
					[60.430147184303209, 2.6753275951117552],
					[60.429316771850104, 2.6752165080771597],
					[60.428430711189741, 2.674627633547622],
					[60.428430691847936, 2.6746276245659741],
					[60.428430654142701, 2.6746276082338927],
					[60.428430634972941, 2.6746276028626896],
					[60.427780635288606, 2.6745137028871064],
					[60.42778062627896, 2.674513702822332],
					[60.427780607195245, 2.6745136992564564],
					[60.427780598185599, 2.6745136991916834],
					[60.427424997904879, 2.6745165004977047],
					[60.427424963995144, 2.6745165071111394],
					[60.427424899368994, 2.6745165306468457],
					[60.427424867846227, 2.6745165495483336],
					[60.427008348558964, 2.6748802920878658],
					[60.426652972120038, 2.6742859973376389],
					[60.426652935339135, 2.6742859626074815],
					[60.426652848273882, 2.6742859121778317],
					[60.426652798161605, 2.6742859000885479],
					[60.426297197725802, 2.6742886994140838],
					[60.42629715417219, 2.6742887115520499],
					[60.426297074171856, 2.674288752660662],
					[60.426297038617498, 2.6742887814572853],
					[60.426119292225231, 2.674530410788019],
					[60.424758309204343, 2.6746636985038297],
					[60.424166792202001, 2.6745498132731442],
					[60.423636232976605, 2.6740776506673729],
					[60.42363620232419, 2.6740776311408521],
					[60.42363613359035, 2.6740776062886642],
					[60.42363609818603, 2.6740776004409255],
					[60.423338898083912, 2.6740804003140535],
					[60.423338874796485, 2.6740804030336678],
					[60.423338830264484, 2.6740804135396341],
					[60.423338808127546, 2.6740804215000247],
					[60.422863820598529, 2.6743248160370996],
					[60.422155597178623, 2.6745691890750756],
					[60.421033359234102, 2.6742220083708936],
					[60.421033354686251, 2.6742220074360996],
					[60.421033348267656, 2.6742220050445029],
					[60.42103334380586, 2.674222005914503],
					[60.4200222441807, 2.6739915058110131],
					[60.420022237762112, 2.6739915034195221],
					[60.420022225989342, 2.6739915020720164],
					[60.420022219656779, 2.6739915014852693],
					[60.418958320035934, 2.6738831013844377],
					[60.418958305586102, 2.6738831005589883],
					[60.418958277750825, 2.6738831023434555],
					[60.418958265171717, 2.6738831029747048],
					[60.418247400307408, 2.6740108612263391],
					[60.41824740005174, 2.6730527994660691],
					[60.418247379106887, 2.6730527379790732],
					[60.418247302469318, 2.6730526418167169],
					[60.418247246690534, 2.673052605336701],
					[60.41682226138461, 2.6727081086863138],
					[60.414808388736631, 2.6718915193587556],
					[60.413208404016835, 2.6709498289420788],
					[60.411905718934264, 2.6701248392435746],
					[60.411491830276496, 2.6697692479455428],
					[60.411491798817323, 2.6697692304080269],
					[60.411491732846081, 2.6697692068548],
					[60.411491697355544, 2.6697691992088624],
					[60.411072197757512, 2.6697747992353387],
					[60.410544398411858, 2.6697804002047962],
					[60.410544373339853, 2.6697804032739354],
					[60.410544323540115, 2.6697804166290431],
					[60.410544299704782, 2.6697804267409726],
					[60.409853267951185, 2.6701773007207854],
					[60.410739077794666, 2.6683722918658224],
					[60.413061276380802, 2.6640750943644531],
					[60.413061276380802, 2.6640750943644531],
					[60.413061278079319, 2.6640750922116858],
					[60.413061277993165, 2.6640750904073602],
					[60.41444737432294, 2.6613639983325479],
					[60.416439068581255, 2.6581945057295733],
					[60.416439070365939, 2.6581945053807532],
					[60.418764068333587, 2.6544584090456329],
					[60.421308465413951, 2.6507223132195143],
					[60.421308482022731, 2.6507222462114952],
					[60.421308469645574, 2.6507221192976762],
					[60.421308442444278, 2.6507220590425806],
					[60.42064454239884, 2.6500554584587568],
					[60.420644540614191, 2.6500554588080796],
					[60.420644538656838, 2.6500554555480087],
					[60.420644537764517, 2.6500554557226677],
					[60.4194723378886, 2.6489415565114078],
					[60.41774453880884, 2.6472748555747616],
					[60.417744537916505, 2.6472748557494823],
					[60.417744535066767, 2.6472748526645771],
					[60.417744534174467, 2.6472748528392915],
					[60.416633434311514, 2.6462720517119851],
					[60.416633366465753, 2.646272026748659],
					[60.416633232301301, 2.6462720275217175],
					[60.416633164197997, 2.6462720536075497],
					[60.415527663753217, 2.647294253199544],
					[60.41552766205497, 2.6472942553533438],
					[60.415527658572096, 2.6472942578565446],
					[60.415527656873856, 2.6472942600103613],
					[60.414635958809598, 2.6482026580037354],
					[60.413697061057917, 2.6491137557668605],
					[60.413697057575007, 2.6491137582697131],
					[60.413697051587882, 2.6491137649051062],
					[60.413697049083638, 2.6491137690376312],
					[60.411422059896694, 2.6517220555926144],
					[60.410588848297081, 2.6524053838233872],
					[60.410091756274454, 2.6522998072347859],
					[60.407475093601306, 2.651316522376582],
					[60.406691815077274, 2.6507637368913213],
					[60.4066917882502, 2.6507637221098017],
					[60.406691728867806, 2.6507637045921739],
					[60.406691698903131, 2.6507636995284627],
					[60.406305599429764, 2.6507665002368195],
					[60.406305580604659, 2.6507665020982127],
					[60.406305546696402, 2.6507665087306833],
					[60.406305530634576, 2.6507665118723849],
					[60.402861031327149, 2.6520387124880935],
					[60.402861026865523, 2.6520387133604917],
					[60.402861019813223, 2.652038716560047],
					[60.402861016243932, 2.6520387172579651],
					[60.401863838624863, 2.6524998077797632],
					[60.401194380827107, 2.6526192001358622],
					[60.398250004066995, 2.6526498005214321],
					[60.396413910064588, 2.6525581006093648],
					[60.396413902839669, 2.6525581002013618],
					[60.396413890260774, 2.6525581008398751],
					[60.396413883035841, 2.6525581004318535],
					[60.394913883228732, 2.6526859011111847],
					[60.394913844138898, 2.6526859123916071],
					[60.394913773874634, 2.6526859497867212],
					[60.394913742786443, 2.6526859777047185],
					[60.394469246297177, 2.653252672811103],
					[60.392585953392526, 2.6554081637376874],
					[60.39114425664215, 2.6568831593705573],
					[60.391144250740773, 2.6568831678027482],
					[60.391144238765513, 2.6568831810609836],
					[60.391144233670239, 2.6568831875158399],
					[60.389924834022629, 2.6586942886400879],
					[60.389924833216512, 2.6586942906173188],
					[60.389924830625723, 2.6586942929429753],
					[60.389924829819606, 2.6586942949202066],
					[60.388099838386452, 2.6616331816806427],
					[60.387711048608622, 2.6620858520057173],
					[60.387263987642761, 2.6618665198015892],
					[60.387263985771895, 2.6618665183469012],
					[60.38726398033166, 2.661866517588614],
					[60.387263977568452, 2.6618665163080082],
					[60.385705678110121, 2.6612081162988903],
					[60.385705674368374, 2.6612081133897187],
					[60.385705666164938, 2.6612081113510793],
					[60.385705661617045, 2.6612081104187961],
					[60.385372261813608, 2.6610998104268999],
					[60.385372246299028, 2.6610998061755917],
					[60.385372215442281, 2.6610998012785489],
					[60.385372199207723, 2.6610998008069116],
					[60.384983299064089, 2.6611025995302238],
					[60.384983259800954, 2.6611026071908488],
					[60.384983189275275, 2.661102639146872],
					[60.384983156055654, 2.6611026601849832],
					[60.384763756071763, 2.6613304603578438],
					[60.384763743376006, 2.661330477391334],
					[60.384763719941567, 2.6613305147155892],
					[60.384763710987542, 2.6613305346581857],
					[60.384210911304095, 2.6629082338497878],
					[60.384210908885521, 2.6629082397801089],
					[60.384210905919211, 2.662908253095412],
					[60.384210904479161, 2.6629082606544054],
					[60.383772003714789, 2.6650471607014312],
					[60.383772003166932, 2.6650471680862688],
					[60.383772001178876, 2.6650471830299116],
					[60.383771999738684, 2.6650471905887314],
					[60.383716501062452, 2.6662832711020776],
					[60.383552621326189, 2.6669581916943428],
					[60.383161014095052, 2.6672998103392183],
					[60.382274973110682, 2.6674220027678812],
					[60.382274951694178, 2.6674220069417922],
					[60.382274911796458, 2.6674220201757843],
					[60.382274893401394, 2.6674220310385048],
					[60.381552693388628, 2.667877630287812],
					[60.381552682132352, 2.6678776397588497],
					[60.381552660426046, 2.6678776567244165],
					[60.381552650148201, 2.6678776678241523],
					[60.380447049663161, 2.6691248664426266],
					[60.380447042954621, 2.6691248768461651],
					[60.380447030343859, 2.6691248956768243],
					[60.380447024441615, 2.6691249041039704],
					[60.379833124139807, 2.6702527040527642],
					[60.379833112701718, 2.6702527663167248],
				],
			],
		},
		{
			fldNpdidField: "43718",
			wlbNpdidWellbore: "422",
			fldName: "SNORRE",
			fldCurrentActivitySatus: "Producing",
			wlbName: "34/4-1",
			fldDiscoveryYear: "1979",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43718",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43718",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "SNORRE UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=422",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "40906",
			fldDateUpdated: "2022-09-13 00:00:00",
			fldDateUpdatedMax: "2022-03-02 00:00:00",
			fldMainSupplyBase: "Florø",
			fldAreaGeometryWKT: [
				[
					[61.397519215047943, 2.0600333482297559],
					[61.397519229679894, 2.0600334051210245],
					[61.398402624778548, 2.0614611961410008],
					[61.399288720624313, 2.0632361889441193],
					[61.399288724569558, 2.0632361955845968],
					[61.399288733446383, 2.0632362105256603],
					[61.399288738282777, 2.063236216967594],
					[61.400230433416951, 2.0645445106550198],
					[61.401172026983865, 2.0660862015092052],
					[61.401905419762009, 2.0674111881132693],
					[61.402580409618835, 2.0689750656405126],
					[61.403135904927154, 2.0711278448636956],
					[61.403585903833999, 2.0732917398210158],
					[61.404035903034789, 2.0756833372071894],
					[61.404035907264529, 2.0756833494272113],
					[61.404035913846712, 2.0756833724047365],
					[61.404035918967608, 2.0756833844265317],
					[61.404922019112021, 2.0775806848833231],
					[61.404922021179317, 2.0775806900641216],
					[61.404922027191205, 2.0775807018883965],
					[61.404922030149656, 2.0775807068710179],
					[61.406502629043253, 2.0801168048901455],
					[61.407866527251969, 2.0824418013783363],
					[61.407866528143146, 2.0824418011802699],
					[61.407866528143146, 2.0824418011802699],
					[61.409499820193908, 2.0852083889341881],
					[61.410910914545006, 2.0886917745978182],
					[61.410910914639864, 2.088691776457265],
					[61.41216651275878, 2.0917278728339266],
					[61.413208111601676, 2.0946611671360036],
					[61.413208111696449, 2.0946611689956094],
					[61.413208113763119, 2.0946611741789276],
					[61.413208114843869, 2.0946611777003756],
					[61.414519210796414, 2.0978111674865008],
					[61.415346949210885, 2.1006498554209263],
					[61.413941660388282, 2.1009887046639517],
					[61.412747167111156, 2.1011915034546593],
					[61.412747116881178, 2.1011915258737566],
					[61.412747038433615, 2.1011915959215677],
					[61.412747009230117, 2.1011916418882599],
					[61.412352616299778, 2.1024971185396386],
					[61.411688743728924, 2.103591571544865],
					[61.410594269823875, 2.1045998464506481],
					[61.409774877321119, 2.1052415424617879],
					[61.409774846751851, 2.1052415793203934],
					[61.409774808650319, 2.1052416629774409],
					[61.409774800132247, 2.1052417081138834],
					[61.40985810015416, 2.1070889089722868],
					[61.409858100627147, 2.1070889182695822],
					[61.409858104435934, 2.1070889399909571],
					[61.409858105894756, 2.107088950950327],
					[61.410269204185269, 2.1086806402210931],
					[61.410719202866666, 2.1114306328784158],
					[61.411072002872608, 2.1136083327032784],
					[61.411072004047369, 2.1136083380847523],
					[61.411072006585911, 2.1136083525667888],
					[61.411072008651871, 2.1136083577510667],
					[61.411488708024052, 2.1149722576754519],
					[61.411849807948713, 2.1162194566604655],
					[61.411849810109111, 2.1162194637046459],
					[61.411849816212239, 2.1162194773987628],
					[61.411849819358267, 2.116219486105416],
					[61.412541519312192, 2.1176806868144538],
					[61.41254152020344, 2.1176806866173532],
					[61.412541521283551, 2.1176806901395748],
					[61.412541522269244, 2.1176806918021311],
					[61.413174810720257, 2.1189111683852202],
					[61.413741504636363, 2.121422244619894],
					[61.413741505622021, 2.1214222462826502],
					[61.413741506890858, 2.1214222535246243],
					[61.413741507970933, 2.1214222570471195],
					[61.41462760304362, 2.1243778375636779],
					[61.414916500956224, 2.1276083182377032],
					[61.41491650123919, 2.1276083238172303],
					[61.414916502696357, 2.1276083347794388],
					[61.414916503776269, 2.1276083383022746],
					[61.415583102186091, 2.1309278300620544],
					[61.415977600899375, 2.1343861237829769],
					[61.41597760188484, 2.1343861254462584],
					[61.415977602167537, 2.1343861310260359],
					[61.415977603153038, 2.1343861326893276],
					[61.416385903247381, 2.1367944336715357],
					[61.416385903247381, 2.1367944336715357],
					[61.416385903341585, 2.1367944355314954],
					[61.416897000573115, 2.1395500262338114],
					[61.417133099575729, 2.1426694126565002],
					[61.417313699617928, 2.1462583040643914],
					[61.417277599759643, 2.1491638923064618],
					[61.417080400354685, 2.1519638851683291],
					[61.417080399745359, 2.1519638909446903],
					[61.417080400215319, 2.151963900244994],
					[61.417080399606022, 2.1519639060213498],
					[61.417197000114037, 2.1559056003537282],
					[61.417105399652648, 2.1593943959798056],
					[61.417105399746539, 2.1593943978398973],
					[61.41710540002822, 2.1593944034201855],
					[61.417105400122097, 2.1593944052802794],
					[61.417185899796323, 2.161947198841589],
					[61.417041500053116, 2.1649749894781163],
					[61.416905399833325, 2.1671888886877233],
					[61.416905400114693, 2.1671888942680662],
					[61.416905399879852, 2.1671889074845772],
					[61.41690540016122, 2.167188913064904],
					[61.417044200335745, 2.1691556141274675],
					[61.41704421032658, 2.1691556514291639],
					[61.417044243396745, 2.1691557175171661],
					[61.417044265678513, 2.1691557483592629],
					[61.417644265869455, 2.1697001476888089],
					[61.417644306681403, 2.1697001707027499],
					[61.417644396187285, 2.1697001943122349],
					[61.417644443098538, 2.169700195299098],
					[61.418361106762369, 2.1695419038989168],
					[61.418908173177996, 2.1696251823466319],
					[61.419285916247198, 2.1703000809518227],
					[61.419552610041691, 2.1710972622872546],
					[61.419552645035118, 2.1710973129133517],
					[61.419552736696694, 2.1710973793145252],
					[61.419552792754708, 2.1710974008660981],
					[61.4208138926411, 2.1711391001470517],
					[61.420813901648081, 2.1711391000509943],
					[61.420813919662045, 2.1711390998588853],
					[61.420813927683938, 2.1711390980981187],
					[61.422188885277826, 2.1709419031243806],
					[61.423502716974582, 2.1713307835453577],
					[61.425019268672543, 2.1722890548661202],
					[61.426485946238529, 2.1739473276682322],
					[61.427791542263492, 2.1756168236733426],
					[61.427791545124833, 2.1756168268082368],
					[61.427791549862548, 2.1756168314127518],
					[61.427791551832563, 2.175616834743213],
					[61.429205449209782, 2.1771668322330613],
					[61.430510942132926, 2.1787223231374555],
					[61.4318720271459, 2.1806251017527201],
					[61.43284701983243, 2.1826667853331592],
					[61.432847020910934, 2.1826667888600086],
					[61.432847022880814, 2.1826667921914185],
					[61.432847023959312, 2.1826667957182493],
					[61.433822023914992, 2.1844778941168324],
					[61.433822024993475, 2.1844778976438484],
					[61.433822028839543, 2.1844779024459378],
					[61.433822029918048, 2.1844779059729529],
					[61.434802616795849, 2.1860556839513063],
					[61.435663707592781, 2.188808355322398],
					[61.436255406882196, 2.1909917526948046],
					[61.436255413071635, 2.1909917682758278],
					[61.436255427233242, 2.1909917990474517],
					[61.436255436096772, 2.1909918140428695],
					[61.438060936328121, 2.1935779141197393],
					[61.438060959122502, 2.1935779373584809],
					[61.438061009685676, 2.1935779752192164],
					[61.438061039237176, 2.1935779894509326],
					[61.439933215065189, 2.1941779833010062],
					[61.441141581295248, 2.1949279626660361],
					[61.442791538412564, 2.1963584238805782],
					[61.443883119617489, 2.1986389856708066],
					[61.443883128573631, 2.1986390025338309],
					[61.443883151740785, 2.198639033227781],
					[61.443883164169016, 2.1986390474487796],
					[61.445919264300699, 2.2005168477018655],
					[61.445919266176794, 2.2005168491738214],
					[61.445919269928993, 2.200516852117731],
					[61.44591927091372, 2.2005168537846944],
					[61.447627664271501, 2.2019501468559657],
					[61.449058149208199, 2.2033945322160831],
					[61.45010262389831, 2.2047445001763966],
					[61.450869214000448, 2.2066944721667374],
					[61.450869225018288, 2.206694494232937],
					[61.450869253107072, 2.2066945332766421],
					[61.45086926928667, 2.2066945504490287],
					[61.452141529263422, 2.2078001158888041],
					[61.45274421115441, 2.2095278651832828],
					[61.452744213216896, 2.209527870380918],
					[61.452744217435125, 2.2095278826385849],
					[61.452744220389022, 2.2095278876414106],
					[61.453402617718233, 2.2108972845599721],
					[61.454005416651597, 2.2122722795583063],
					[61.454005418713997, 2.2122722847563354],
					[61.454005421760982, 2.2122722916221087],
					[61.454005423730187, 2.212272294957641],
					[61.454997022172428, 2.2140972926901434],
					[61.4562804116715, 2.2167306702895822],
					[61.456885906271616, 2.2189278499078742],
					[61.457435904815789, 2.2212444469370141],
					[61.457435912079589, 2.2212444660655692],
					[61.457435930172814, 2.221244503544654],
					[61.457435940017746, 2.2212445202269553],
					[61.458541537384697, 2.2227140156712895],
					[61.459594232235759, 2.2243057076488189],
					[61.460810931339616, 2.2262445053821485],
					[61.460810932324073, 2.2262445070507058],
					[61.460810934385961, 2.2262445122508034],
					[61.460810935277372, 2.2262445120564136],
					[61.462533135002275, 2.228744512651776],
					[61.462533135986661, 2.2287445143205318],
					[61.462533136971089, 2.2287445159892583],
					[61.462533137955489, 2.2287445176579954],
					[61.464255438372035, 2.2311307165906391],
					[61.4642554403408, 2.2311307199284371],
					[61.464255444185376, 2.2311307247408716],
					[61.464255446154141, 2.2311307280786705],
					[61.465755446375873, 2.232939028444926],
					[61.465755450220392, 2.2329390332577925],
					[61.465755458800864, 2.2329390426893152],
					[61.465755464428284, 2.232939047113689],
					[61.467205464261163, 2.2342834462254699],
					[61.467205490336305, 2.2342834631479196],
					[61.467205543897798, 2.2342834891509167],
					[61.467205574058475, 2.2342834976488035],
					[61.468538839645888, 2.234458494229858],
					[61.469599902939663, 2.2349973749390855],
					[61.470688796314803, 2.2356612712389907],
					[61.470688812918063, 2.2356612789248391],
					[61.470688849690326, 2.2356612935197258],
					[61.470688868875008, 2.2356612987593825],
					[61.472416669051235, 2.2359362987564855],
					[61.4724166708341, 2.2359362983680682],
					[61.472416675291299, 2.23593629739702],
					[61.472416678058522, 2.2359362986780775],
					[61.473919328443891, 2.2361057937282629],
					[61.475369257008651, 2.2371001440490841],
					[61.477269236441664, 2.2398390142977798],
					[61.477269238410237, 2.2398390176375083],
					[61.477269242254536, 2.2398390224529368],
					[61.477269245114577, 2.2398390255985201],
					[61.479113732956392, 2.2421140101795345],
					[61.480347023942478, 2.24441679476663],
					[61.480347024926694, 2.2444167964367958],
					[61.480347028863648, 2.2444168031174594],
					[61.480347029940688, 2.2444168066518109],
					[61.481635919911263, 2.2464833897777505],
					[61.482252613037019, 2.24810567059171],
					[61.482252621096059, 2.248105687683406],
					[61.482252637827372, 2.2481057160799378],
					[61.482252648468055, 2.2481057307254897],
					[61.483713726065282, 2.2498112039136302],
					[61.484388714080062, 2.2515528729137095],
					[61.484388723122819, 2.2515528916780956],
					[61.48438874458887, 2.2515529247024908],
					[61.484388757996307, 2.251552940633109],
					[61.485397058086122, 2.2525723411705778],
					[61.486408118424478, 2.253591800268067],
					[61.486808104726229, 2.2553444438737977],
					[61.486808107956513, 2.2553444544802241],
					[61.48680811432439, 2.2553444738284658],
					[61.486808118446113, 2.2553444842411303],
					[61.487766514430703, 2.2574278746326808],
					[61.488669209686407, 2.2601056637584613],
					[61.488669214699264, 2.2601056739787357],
					[61.488669223833526, 2.2601056946129328],
					[61.488669228846412, 2.2601057048332036],
					[61.490080410301367, 2.2624111714163417],
					[61.490885903205694, 2.2668639367195005],
					[61.490885904189689, 2.2668639383909288],
					[61.490885905358624, 2.2668639437922065],
					[61.490885906435111, 2.2668639473285475],
					[61.491510906413858, 2.2694389462816429],
					[61.491510911888277, 2.2694389658291803],
					[61.491510928985264, 2.2694390017054427],
					[61.491510938824739, 2.2694390184209934],
					[61.493110939188291, 2.2716223181618278],
					[61.493110960002824, 2.2716223381538292],
					[61.493111006888462, 2.2716223751126554],
					[61.493111033666239, 2.2716223881559845],
					[61.495247113150064, 2.2723695818009317],
					[61.496491593028921, 2.2731529687819845],
					[61.496491606863515, 2.2731529752082391],
					[61.496491633641142, 2.2731529882541412],
					[61.496491648274827, 2.2731529926218106],
					[61.498402747537519, 2.2736723926839884],
					[61.498402789992618, 2.2736723947893642],
					[61.498402873087265, 2.2736723805395962],
					[61.498402912835331, 2.2736723643777688],
					[61.499858407895985, 2.2726751681941995],
					[61.501536159349996, 2.271666898345774],
					[61.50333321983419, 2.2716002035972531],
					[61.504580458087695, 2.2728557400955198],
					[61.504580467557233, 2.2728557493559189],
					[61.504580489078329, 2.2728557654307457],
					[61.504580500238447, 2.2728557724385703],
					[61.506133199710483, 2.2737473732389382],
					[61.50613322639574, 2.2737473844230256],
					[61.506133280195691, 2.2737473972685249],
					[61.506133307402813, 2.2737474007958061],
					[61.507872206585837, 2.2736835000479685],
					[61.507872210151881, 2.2736834992742994],
					[61.507872214609378, 2.2736834983072294],
					[61.507872218267813, 2.2736834993995338],
					[61.510066718201898, 2.2734862995930332],
					[61.51006672265941, 2.2734862986258397],
					[61.510066731574405, 2.2734862966914617],
					[61.510066737015826, 2.2734862973969148],
					[61.51242503709539, 2.2730445966939823],
					[61.512425051359429, 2.2730445935984589],
					[61.512425077827217, 2.2730445821956646],
					[61.512425091998779, 2.2730445772339283],
					[61.514155651751729, 2.2721529982681479],
					[61.515786106570104, 2.2720918993480108],
					[61.517977807121952, 2.2720112996014246],
					[61.517977831284853, 2.2720112962422792],
					[61.517977876566349, 2.2720112826384224],
					[61.517977898668939, 2.2720112740667084],
					[61.519988998601811, 2.2708695746132612],
					[61.519989021133497, 2.2708695565131287],
					[61.519989059679048, 2.270869516068029],
					[61.519989076584359, 2.270869493529478],
					[61.520816831788657, 2.2692973792671087],
					[61.523286082600208, 2.2688474056494905],
					[61.525372132066408, 2.2696029886153548],
					[61.525372157583121, 2.2696029943944378],
					[61.525372210921034, 2.2696029979036418],
					[61.525372237850753, 2.269602995827452],
					[61.52766668662828, 2.2691613055423598],
					[61.529941590294442, 2.2699112747396848],
					[61.531819239324683, 2.2718640209695571],
					[61.533485923745815, 2.2747806955109318],
					[61.534927619826753, 2.2775861874907792],
					[61.535855410746066, 2.2795778662477955],
					[61.536688705819444, 2.2828861485904288],
					[61.536688712091596, 2.2828861661073243],
					[61.536688728201995, 2.2828862003675794],
					[61.536688738040219, 2.2828862171109203],
					[61.538524836314757, 2.2854445145062305],
					[61.539966535286965, 2.2875418123408515],
					[61.539966546815485, 2.2875418268329391],
					[61.539966573346334, 2.2875418531760756],
					[61.539966587457158, 2.287541865220394],
					[61.54130268676208, 2.2884529642206681],
					[61.541302716305466, 2.2884529785845151],
					[61.541302775730351, 2.2884529959108355],
					[61.541302806595645, 2.2884530005480634],
					[61.542430536678687, 2.2884141020573692],
					[61.543513746019059, 2.2892140351909589],
					[61.544094224716268, 2.2902694959548464],
					[61.544094262682727, 2.2902695349304021],
					[61.544094353556574, 2.2902695869850378],
					[61.544094407355502, 2.2902695998708817],
					[61.545672206768153, 2.2902169000298698],
					[61.545672226473975, 2.2902168976467525],
					[61.545672263825914, 2.2902168876621647],
					[61.545672283347294, 2.2902168815424817],
					[61.547355657705744, 2.2894473929566552],
					[61.549325029764155, 2.2891445973394924],
					[61.54932503698857, 2.2891445976615503],
					[61.549325049377707, 2.2891445930867684],
					[61.549325054726843, 2.2891445919269398],
					[61.5502805930875, 2.2888724101769373],
					[61.552436033026119, 2.2896390885459024],
					[61.552436063891349, 2.2896390931852877],
					[61.552436126236728, 2.2896390966646716],
					[61.552436156640816, 2.2896390919606033],
					[61.554233356650244, 2.289102992499263],
					[61.554233379553182, 2.2891029818658617],
					[61.554233423299266, 2.2891029553793865],
					[61.55423344235939, 2.289102939912989],
					[61.554844444559301, 2.2884835400641239],
					[61.556324917919767, 2.2891529814773035],
					[61.556324930770081, 2.2891529862459299],
					[61.556324958253718, 2.2891529953964587],
					[61.556324971903294, 2.2891529981028076],
					[61.557677772322691, 2.2893473980612766],
					[61.557677801128214, 2.2893473974805505],
					[61.557677858370234, 2.2893473888430722],
					[61.557677885915275, 2.2893473809796872],
					[61.559580686275261, 2.2884473814374147],
					[61.559580693222927, 2.288447376151908],
					[61.559580708993593, 2.2884473670632199],
					[61.559580716033523, 2.288447363646843],
					[61.560805716481788, 2.287569563817248],
					[61.560805725120225, 2.2875695562752441],
					[61.560805740613993, 2.2875695415781552],
					[61.560805749160153, 2.2875695321669727],
					[61.56224447083212, 2.285964120806494],
					[61.565047155798254, 2.2865918952477284],
					[61.565047165789522, 2.2865918968580363],
					[61.56504718666362, 2.2865918998851593],
					[61.565047196562595, 2.2865918996260346],
					[61.567530597007163, 2.2866279998757761],
					[61.56753060512311, 2.286628000003641],
					[61.567530622154223, 2.2866279981962667],
					[61.567530630270184, 2.2866279983241458],
					[61.569558329879833, 2.2863223980479468],
					[61.56955835296705, 2.2863223911459274],
					[61.569558397081472, 2.2863223721199146],
					[61.569558418201026, 2.2863223618656114],
					[61.571280718374183, 2.2850640610965707],
					[61.571280731377676, 2.2850640507133484],
					[61.571280755324715, 2.2850640247246861],
					[61.571280766360566, 2.2850640109890552],
					[61.571794362959103, 2.2842972354516049],
					[61.57302510134901, 2.2835807727737683],
					[61.573025114444704, 2.2835807642592041],
					[61.573025138668442, 2.2835807438775833],
					[61.57302515068794, 2.2835807318168704],
					[61.573830613642613, 2.2826574986602246],
					[61.573936033795455, 2.2826945899354385],
					[61.573936034686952, 2.2826945897417512],
					[61.573936038345352, 2.2826945908369467],
					[61.573936039236848, 2.2826945906432603],
					[61.574958239047177, 2.2830195916544449],
					[61.574958284176759, 2.2830195931901756],
					[61.574958371022667, 2.2830195818825372],
					[61.574958413445835, 2.2830195651054375],
					[61.576069513013572, 2.2822612641230355],
					[61.576069527707482, 2.2822612514793947],
					[61.576069556111435, 2.2822612245157541],
					[61.576069568837625, 2.2822612085193903],
					[61.577325150335795, 2.2802973363430157],
					[61.57909732344099, 2.2789140579463836],
					[61.579097333769433, 2.2789140481392929],
					[61.579097354333918, 2.2789140266548813],
					[61.579097362786932, 2.2789140153652241],
					[61.579536262820618, 2.2782973158636501],
					[61.579536275176686, 2.2782972923826805],
					[61.579536292756771, 2.2782972469716398],
					[61.579536298687486, 2.2782972211071506],
					[61.579814097301472, 2.2756444361533816],
					[61.579990817035984, 2.2749846964660185],
					[61.580355779039671, 2.2742556891477377],
					[61.580355787029966, 2.2742556685047921],
					[61.580355797384243, 2.2742556227718604],
					[61.580355799840653, 2.2742555995521938],
					[61.580355800125872, 2.2736221252689384],
					[61.580594593407213, 2.2727306518075014],
					[61.580594593407213, 2.2727306518075014],
					[61.580594594206204, 2.2727306497431576],
					[61.580594594113713, 2.2727306478728413],
					[61.581366894276755, 2.2695778477921298],
					[61.581366894890643, 2.2695778419869534],
					[61.58136689790139, 2.2695778299883291],
					[61.581366897623809, 2.2695778243772584],
					[61.581889095570936, 2.2653500413200676],
					[61.582730788999427, 2.2625528661593939],
					[61.583844583196054, 2.2597417826965991],
					[61.584647379319044, 2.2581472894733259],
					[61.5846473809166, 2.2581472853433557],
					[61.584647384111712, 2.2581472770834004],
					[61.584647385709268, 2.258147272953428],
					[61.585177985883412, 2.2568028736783066],
					[61.585177991382011, 2.2568028573523509],
					[61.585177997736452, 2.2568028219320202],
					[61.585177999576494, 2.2568028045136299],
					[61.58523629988013, 2.2543944052553062],
					[61.585236296798868, 2.254394379457072],
					[61.585236285194881, 2.2543943271576583],
					[61.585236275074685, 2.254394304786699],
					[61.584591901942595, 2.2532166521687516],
					[61.584702999643277, 2.250683308264013],
					[61.58470300016355, 2.2506833005873865],
					[61.584702998622582, 2.2506832876887057],
					[61.584702999142863, 2.2506832800120793],
					[61.584555798538617, 2.2492443797032911],
					[61.584555794843396, 2.2492443597123413],
					[61.584555783979916, 2.2492443223798588],
					[61.584555775920265, 2.2492443052330522],
					[61.583794575716375, 2.2478332051823866],
					[61.583794483935861, 2.2478331533887213],
					[61.583794304068775, 2.2478331605455266],
					[61.583794216873628, 2.2478332193012323],
					[61.582941528772686, 2.2497915928413543],
					[61.581852648853683, 2.251038768065857],
					[61.581852648946452, 2.2510387699361694],
					[61.581852647163572, 2.2510387703254868],
					[61.581852646364922, 2.2510387723904541],
					[61.580813746320672, 2.2522831715379765],
					[61.580813743831861, 2.2522831758623276],
					[61.5808137387614, 2.2522831826408125],
					[61.580813736179834, 2.2522831850949103],
					[61.579944239978801, 2.2535192789013978],
					[61.579235944697665, 2.2543887743169013],
					[61.579235929764238, 2.2543888002607066],
					[61.579235907307194, 2.254388856202485],
					[61.579235900675123, 2.2543888860058918],
					[61.579105400821028, 2.2561971853518208],
					[61.57910540039309, 2.2561971948971511],
					[61.57910540122748, 2.2561972117286393],
					[61.579105400799577, 2.2561972212739634],
					[61.57938869704023, 2.258830579293897],
					[61.578655409210903, 2.2611443391406985],
					[61.578655406200859, 2.2611443511390332],
					[61.578655401963736, 2.2611443747470084],
					[61.578655400736643, 2.2611443863566492],
					[61.578397002379738, 2.2647582686532832],
					[61.578083166281829, 2.2659719175692534],
					[61.577122326206968, 2.2655276377605582],
					[61.576369587567015, 2.264594313635905],
					[61.576050197355826, 2.2628055645363729],
					[61.57605019637171, 2.2628055628606316],
					[61.576050195109794, 2.2628055555748823],
					[61.576050195017174, 2.2628055537048786],
					[61.575680795335614, 2.2612582531298271],
					[61.57568077823521, 2.2612582171636593],
					[61.575680731924407, 2.2612581554316913],
					[61.575680701729894, 2.2612581279902368],
					[61.574541802063173, 2.2605804282282294],
					[61.574541770826755, 2.2605804161363068],
					[61.574541707126848, 2.2605804035605876],
					[61.574541673679207, 2.2605804014012065],
					[61.573258282584895, 2.260749799208162],
					[61.572583412239425, 2.2607747961306965],
					[61.571827981109934, 2.2594860141830733],
					[61.571116896239495, 2.2575916545383774],
					[61.570908502322801, 2.2555611052835616],
					[61.571205796905794, 2.2537500328486231],
					[61.571205797704515, 2.2537500307844445],
					[61.571205798410524, 2.2537500268505872],
					[61.571205798317791, 2.2537500249809086],
					[61.571494597617985, 2.2514583254773268],
					[61.572127997928625, 2.2468694295777238],
					[61.572772398205551, 2.2427611301666959],
					[61.572772398725462, 2.2427611224929294],
					[61.572772399672346, 2.2427611052756844],
					[61.57277240009936, 2.24276109573219],
					[61.572739100135976, 2.2410833211687877],
					[61.573044594849215, 2.2397472454398764],
					[61.573044597206859, 2.2397472203536148],
					[61.573044595589209, 2.2397471696757658],
					[61.573044591706839, 2.2397471459539142],
					[61.572502992220834, 2.2378499456195202],
					[61.572502992127873, 2.2378499437498265],
					[61.572502990066155, 2.2378499385306485],
					[61.572502989973209, 2.2378499366609539],
					[61.571908500621568, 2.2360749694082829],
					[61.571964099074755, 2.2334333505192379],
					[61.572608276397091, 2.2322032776307053],
					[61.573083114187, 2.2333833754009822],
					[61.573083119202181, 2.2333833856437946],
					[61.573083130030959, 2.2333834040646021],
					[61.573083135046176, 2.2333834143074061],
					[61.573885935415994, 2.2345473138782457],
					[61.57388593827627, 2.2345473170325625],
					[61.573885944089803, 2.2345473252109858],
					[61.573885946950071, 2.2345473283653035],
					[61.574572046746326, 2.2353584284326375],
					[61.574572116113821, 2.2353584642886437],
					[61.574572257221647, 2.2353584749963158],
					[61.574572329946399, 2.2353584515227345],
					[61.575011230039422, 2.2349807514766069],
					[61.575011257170026, 2.2349807171868754],
					[61.575011291168884, 2.2349806398100651],
					[61.575011299820069, 2.2349805963328144],
					[61.574989099647155, 2.2338999968417976],
					[61.574966900179128, 2.2301778243330044],
					[61.575427994096636, 2.2283556486445835],
					[61.575427994708804, 2.2283556428398135],
					[61.575427997715913, 2.2283556308397499],
					[61.575427998328045, 2.2283556250349705],
					[61.575714098273707, 2.2260583245477705],
					[61.575714098978885, 2.2260583206127542],
					[61.57571410038922, 2.2260583127427087],
					[61.575714100109849, 2.2260583071331634],
					[61.575930799606638, 2.2206417080855925],
					[61.57593080031166, 2.2206417041504323],
					[61.575930800032083, 2.2206416985409119],
					[61.575930799938888, 2.2206416966710756],
					[61.575869599996196, 2.217761095846126],
					[61.575869599809714, 2.2177610921064712],
					[61.575869599343555, 2.2177610827573551],
					[61.575869599157102, 2.2177610790177109],
					[61.575591899087208, 2.2151305793216132],
					[61.575591895293833, 2.2151305574709865],
					[61.575591881653914, 2.2151305188788135],
					[61.5755918726055, 2.2151305000718255],
					[61.574439072724651, 2.2131442999930373],
					[61.574439028310721, 2.2131442587080135],
					[61.574438924132686, 2.2131442116412692],
					[61.574438864181914, 2.2131442021201297],
					[61.572666663843663, 2.2134692030481147],
					[61.572666649581521, 2.2134692061787642],
					[61.57266662302662, 2.2134692157879594],
					[61.572666608951053, 2.2134692226578303],
					[61.571622108596962, 2.2139998220990642],
					[61.571622091142395, 2.2139998334900928],
					[61.571622057404468, 2.2139998616852061],
					[61.571622042012542, 2.2139998782936314],
					[61.570755481325612, 2.2151220274870815],
					[61.56931671743331, 2.2155497860048987],
					[61.568866844818409, 2.215333147496918],
					[61.568172378837801, 2.2140443102565537],
					[61.56759188539214, 2.2127527254247421],
					[61.566947388071931, 2.2109860306802198],
					[61.566947387180527, 2.2109860308759171],
					[61.566947386993888, 2.2109860271373858],
					[61.566947386102534, 2.2109860273330715],
					[61.566302986261313, 2.2093388272299466],
					[61.566302975242763, 2.2093388050832128],
					[61.566302948137441, 2.2093387675717735],
					[61.566302931957274, 2.2093387503378645],
					[61.565400148070928, 2.2085442631714538],
					[61.564200182922903, 2.2069249115702676],
					[61.563664093924508, 2.2049138534321746],
					[61.563175199298861, 2.2026610732902125],
					[61.563091900052605, 2.2009861041478636],
					[61.563275198937063, 2.1989389178247878],
					[61.563275197628357, 2.1989388916588917],
					[61.563275187693158, 2.1989388371571779],
					[61.563275179160122, 2.1989388106903358],
					[61.562814079122845, 2.198005510972977],
					[61.562814017114675, 2.1980054603662103],
					[61.562813875401851, 2.1980054197301522],
					[61.562813794805884, 2.198005429896901],
					[61.561333195223988, 2.1989192304113745],
					[61.561333183823294, 2.1989192366969958],
					[61.5613331639764, 2.1989192542869165],
					[61.561333153560568, 2.1989192622454112],
					[61.560027653980406, 2.2003054632732248],
					[61.560027652291161, 2.2003054655338987],
					[61.560027646331996, 2.2003054725118756],
					[61.560027642860028, 2.2003054751644529],
					[61.559433152665846, 2.2010526636512036],
					[61.558066564372901, 2.2023192520064554],
					[61.556974883691183, 2.2033331344195819],
					[61.556205686363271, 2.2037303294385637],
					[61.554827981362706, 2.2010499153411485],
					[61.554077983959438, 2.1992916211232658],
					[61.554077982974597, 2.1992916194507504],
					[61.554077981896327, 2.1992916159097744],
					[61.554077980911487, 2.1992916142372634],
					[61.552527982008804, 2.1960221170653611],
					[61.551661283041035, 2.1940360196558824],
					[61.551661278023076, 2.1940360094264935],
					[61.551661267189331, 2.1940359910320377],
					[61.551661261280024, 2.1940359809986814],
					[61.55098348277803, 2.1931138112379718],
					[61.550572392849887, 2.1915832474206676],
					[61.550572386659695, 2.19158323178354],
					[61.550572371418163, 2.1915831973612283],
					[61.55057236067767, 2.1915831808364712],
					[61.549552966040395, 2.1902026877723011],
					[61.547905775257163, 2.187541602986848],
					[61.546994579647311, 2.1856805114462725],
					[61.546994572752411, 2.1856804997443198],
					[61.546994557180021, 2.1856804767329092],
					[61.546994549393823, 2.1856804652271871],
					[61.545866861160242, 2.1844276802737963],
					[61.545302970634438, 2.1835026965326279],
					[61.545302860208771, 2.1835026509782898],
					[61.545302675186591, 2.1835026992804298],
					[61.545302599698786, 2.1835027933331785],
					[61.545227600845372, 2.1857749692948247],
					[61.544388707180552, 2.1888055466008041],
					[61.544388703566248, 2.1888055643918625],
					[61.544388700981592, 2.1888056027289231],
					[61.544388701026236, 2.1888056216032497],
					[61.54458310145553, 2.1905861207290376],
					[61.544777601162373, 2.1926056183547851],
					[61.544777615979953, 2.1926056623066628],
					[61.54477766306794, 2.1926057388182874],
					[61.544777695244761, 2.1926057695101533],
					[61.545672094994551, 2.193155769765748],
					[61.545672163248874, 2.1931557830823314],
					[61.545672291179471, 2.1931557643898896],
					[61.545672350762246, 2.1931557305129643],
					[61.54643343584916, 2.192280749057927],
					[61.547147081389127, 2.1917642330334708],
					[61.547552598884671, 2.1932944224084889],
					[61.547435899668592, 2.1962888921391723],
					[61.547435900136094, 2.1962889014793228],
					[61.547435900977604, 2.1962889182915797],
					[61.547435902242981, 2.1962889255677451],
					[61.547752601925446, 2.1986611264833056],
					[61.547752601925446, 2.1986611264833056],
					[61.547752602018917, 2.1986611283513593],
					[61.547999798063088, 2.2003222035969361],
					[61.547705402339275, 2.2029638745093352],
					[61.547358101771373, 2.2053721700987357],
					[61.547005403040082, 2.2075388636359987],
					[61.546538709210985, 2.2098332346853331],
					[61.546002931442672, 2.2109325753958751],
					[61.545669598991992, 2.207486080106916],
					[61.545669597820606, 2.2074860746986364],
					[61.54566959726052, 2.2074860634908391],
					[61.54566959519773, 2.2074860582781626],
					[61.545311294851359, 2.2058332582003182],
					[61.545311287304578, 2.2058332334187938],
					[61.545311263390687, 2.205833187680311],
					[61.545311247914924, 2.2058331665276949],
					[61.544691873662266, 2.2051470948755649],
					[61.544516887131365, 2.2046777301482576],
					[61.544516786567691, 2.204677665359791],
					[61.544516596196381, 2.2046776788273887],
					[61.544516505403998, 2.2046777554112684],
					[61.544102613022503, 2.2064888225668482],
					[61.543235931035341, 2.2078470917497408],
					[61.543235925262394, 2.2078471024579636],
					[61.543235914701235, 2.207847125546639],
					[61.543235909913022, 2.207847137927093],
					[61.542438721769173, 2.2102721007982864],
					[61.541808146933917, 2.2110192710010215],
					[61.541808139377935, 2.2110192820991266],
					[61.541808125157374, 2.2110193040998576],
					[61.541808119384193, 2.2110193148069897],
					[61.540838719256406, 2.2130943148470474],
					[61.540838713669544, 2.2130943292888579],
					[61.540838705076752, 2.2130943557185381],
					[61.540838702164066, 2.2130943695740934],
					[61.540638761932804, 2.214416276129203],
					[61.539733441674443, 2.2135054598423451],
					[61.539733421929448, 2.2135054434016976],
					[61.539733378262326, 2.2135054171002633],
					[61.539733354340193, 2.2135054072394995],
					[61.53900840509629, 2.2133026210379887],
					[61.538219570804003, 2.2125054889562308],
					[61.537586287319549, 2.2108693284957743],
					[61.537586270673366, 2.2108693019385743],
					[61.537586233110652, 2.2108692535364378],
					[61.537586209519951, 2.2108692322778838],
					[61.536914041272787, 2.2104248537645663],
					[61.536177964240864, 2.2093887853815568],
					[61.53521687294981, 2.2080054986560209],
					[61.534989101940702, 2.2075416576574738],
					[61.535027999828642, 2.2063472069540748],
					[61.535027998801752, 2.2063471864134985],
					[61.535027992291106, 2.2063471463101911],
					[61.535027986900701, 2.2063471286147873],
					[61.534397409287934, 2.2047110860514212],
					[61.534869591713552, 2.2027806555852485],
					[61.535511289960546, 2.2008417630427126],
					[61.535511290665042, 2.2008417591123366],
					[61.535511293058853, 2.2008417529231767],
					[61.535511293763342, 2.2008417489927816],
					[61.536141889908968, 2.1983083643692338],
					[61.537000183078909, 2.1963556802251709],
					[61.537000187772591, 2.1963556659781611],
					[61.537000196175143, 2.1963556358125884],
					[61.537000198992637, 2.1963556200899075],
					[61.537291899425043, 2.1935972215424271],
					[61.537291899050913, 2.1935972140727826],
					[61.537291900272429, 2.1935972024764325],
					[61.53729189999185, 2.1935971968741921],
					[61.537269600223752, 2.1919306026848866],
					[61.53735299974641, 2.1899000096008274],
					[61.537486300090549, 2.1876278124614359],
					[61.537486299341666, 2.1876277975222362],
					[61.537486296952558, 2.1876277678399769],
					[61.53748629432738, 2.1876277514256217],
					[61.536727994295383, 2.1845693519857892],
					[61.536727988995871, 2.1845693361607301],
					[61.536727975816426, 2.1845693069665484],
					[61.536727967842879, 2.184569291730083],
					[61.535769569116404, 2.1830720937338031],
					[61.534697376626653, 2.18134430539883],
					[61.534016882909462, 2.1798332204919437],
					[61.533333488161318, 2.1782027306239193],
					[61.532527989617051, 2.175747137456598],
					[61.53252798736559, 2.1757471285141592],
					[61.532527980282417, 2.1757471130856167],
					[61.532527976248318, 2.1757471045360188],
					[61.531905776411008, 2.174591604542071],
					[61.531905775425912, 2.1745916028715095],
					[61.531905771579289, 2.1745915980562707],
					[61.531905770594207, 2.1745915963856883],
					[61.531064071266798, 2.1732109956628363],
					[61.531064067420161, 2.17321099084789],
					[61.531064058741698, 2.173210979547505],
					[61.531064054801249, 2.1732109728656073],
					[61.530225154621291, 2.1721859724552464],
					[61.530225146036571, 2.1721859630223594],
					[61.530225126193237, 2.1721859447460852],
					[61.530225115028415, 2.1721859377696049],
					[61.528666814998992, 2.1710859369491633],
					[61.528666809369646, 2.1710859325278675],
					[61.528666796328316, 2.1710859240783416],
					[61.528666789901514, 2.1710859217203842],
					[61.527394497636365, 2.1704470256168777],
					[61.525036205622484, 2.1689748299045482],
					[61.525036204731151, 2.1689748301011065],
					[61.525036203746019, 2.1689748284310748],
					[61.523430722234416, 2.1679998406370791],
					[61.52237234820057, 2.16699156444295],
					[61.521480764693486, 2.1658554866739848],
					[61.520530772679194, 2.1642498996022286],
					[61.519691876039296, 2.1627555041481878],
					[61.518680777923443, 2.1607943081619365],
					[61.518680769056175, 2.1607942931370472],
					[61.518680748835656, 2.1607942674098153],
					[61.518680738279741, 2.1607942546445771],
					[61.51773908515483, 2.1599027007424509],
					[61.517333497188837, 2.1576721647557275],
					[61.517333485692625, 2.1576721333313462],
					[61.517333455945902, 2.1576720795214022],
					[61.517333435724851, 2.1576720537973024],
					[61.516614042175398, 2.1570026584140209],
					[61.515780826658741, 2.1561027504165016],
					[61.515941838980041, 2.1556196131411736],
					[61.516708268722027, 2.1556918971291354],
					[61.517536056358871, 2.1558779946212425],
					[61.517536070010195, 2.1558779972664999],
					[61.517536098016073, 2.1558779986279757],
					[61.517536111573378, 2.15587799940714],
					[61.518191712452108, 2.1558391002361894],
					[61.518191767241518, 2.1558390786995196],
					[61.518191855801653, 2.1558390119656359],
					[61.518191890275638, 2.1558389628392947],
					[61.518672346661369, 2.1543834903698591],
					[61.519497212366382, 2.1543335000341419],
					[61.519497229300697, 2.1543334962925491],
					[61.519497262278051, 2.1543334890062864],
					[61.519497278227057, 2.1543334835954289],
					[61.520477823148774, 2.1539169072851196],
					[61.521908262821526, 2.1541834971375127],
					[61.5219083041966, 2.1541834955431454],
					[61.521908380846639, 2.1541834786054377],
					[61.521908417904172, 2.1541834628681928],
					[61.522616818093489, 2.1536668613171472],
					[61.522616829397904, 2.1536668531574192],
					[61.522616849144782, 2.1536668336960942],
					[61.522616857587273, 2.1536668223945146],
					[61.523589014133158, 2.1524168795327374],
					[61.525722244592927, 2.1519279951949501],
					[61.525722281556028, 2.1519279775862494],
					[61.525722345910836, 2.1519279312734509],
					[61.525722373208453, 2.1519279007027818],
					[61.526366827870532, 2.150816879527798],
					[61.5272444385593, 2.1506445961103644],
					[61.527244481666997, 2.1506445752528642],
					[61.527244553572217, 2.1506445178278968],
					[61.527244583166933, 2.1506444791966888],
					[61.528258483000279, 2.1483194798979142],
					[61.528258487080073, 2.1483194714452911],
					[61.528258491674542, 2.1483194553287488],
					[61.528258494768913, 2.1483194452066119],
					[61.528572340055561, 2.1469919776080877],
					[61.529780521122078, 2.1475112840601116],
					[61.529780579104617, 2.1475112901067437],
					[61.529780688027394, 2.1475112697811976],
					[61.529780739858872, 2.1475112432118335],
					[61.530433439940595, 2.1468751432893969],
					[61.530433450164978, 2.1468751315885592],
					[61.530433467939908, 2.1468751087786022],
					[61.530433476287598, 2.1468750956053904],
					[61.53096687592064, 2.1458889941162371],
					[61.530966883282773, 2.1458889792729763],
					[61.530966894253638, 2.1458889466417994],
					[61.530966897956553, 2.145888930720723],
					[61.531219598248292, 2.1442056303594046],
					[61.531219598574388, 2.1442056189609744],
					[61.531219600211941, 2.1442055978336643],
					[61.531219599646739, 2.1442055866325473],
					[61.531016899998654, 2.1410027876974853],
					[61.531016898353442, 2.1410027729602557],
					[61.531016891592294, 2.1410027461422407],
					[61.531016888258819, 2.1410027336666588],
					[61.530391890874071, 2.1392555409465461],
					[61.529133493386809, 2.1345749483780918],
					[61.529133490241421, 2.1345749396373574],
					[61.529133483856356, 2.1345749202891926],
					[61.529133479819748, 2.1345749117460255],
					[61.528347379652779, 2.1329610120549556],
					[61.528347378667213, 2.1329610103859276],
					[61.528347377681598, 2.1329610087169084],
					[61.528347376790379, 2.1329610089145157],
					[61.526614077069617, 2.1296249073915101],
					[61.526614072141477, 2.1296248990473723],
					[61.526614061393992, 2.1296248825567847],
					[61.526614054589004, 2.1296248727415144],
					[61.524952954549789, 2.127594272818004],
					[61.524952945962205, 2.1275942633991627],
					[61.524952926113357, 2.1275942451546683],
					[61.524952914852051, 2.1275942363290437],
					[61.523414015130562, 2.1265081372166921],
					[61.523414005840557, 2.1265081317289884],
					[61.523413988151773, 2.1265081205557985],
					[61.523413977970527, 2.1265081152658376],
					[61.522208378338817, 2.125997015782668],
					[61.522208360042079, 2.1259970104066528],
					[61.522208323543033, 2.1259970015208651],
					[61.522208306326426, 2.1259969996795833],
					[61.520952850125866, 2.1259609014019527],
					[61.519089009697765, 2.1250192305715534],
					[61.518152972731322, 2.1242498821180629],
					[61.517536290557146, 2.1222749392310023],
					[61.517536283373765, 2.1222749219563286],
					[61.517536262673886, 2.1222748869258399],
					[61.517536251831096, 2.1222748685765369],
					[61.5163779607973, 2.120930479160994],
					[61.514552968069282, 2.1180887915129154],
					[61.514552964220812, 2.118088786707359],
					[61.514552954552371, 2.11808877376057],
					[61.51455294971813, 2.1180887672871735],
					[61.512297356582735, 2.1155165762387558],
					[61.510205762549766, 2.1125804837177791],
					[61.510205761658547, 2.1125804839158286],
					[61.510205759686912, 2.1125804805809709],
					[61.51020575870109, 2.1125804789135496],
					[61.508064077692673, 2.1097666038000553],
					[61.50711959074065, 2.1071110398944861],
					[61.506169592573464, 2.1036249463097576],
					[61.506169592384005, 2.1036249425793772],
					[61.506169589331535, 2.103624935713404],
					[61.506169588250856, 2.1036249321812819],
					[61.504952987758408, 2.1002777328898059],
					[61.504952984610973, 2.1002777241593393],
					[61.504952977614487, 2.1002777106269743],
					[61.504952975453008, 2.1002777035632567],
					[61.504127976158131, 2.098799906320191],
					[61.503191878330647, 2.0969749098059656],
					[61.503191878235825, 2.0969749079409814],
					[61.501602977765991, 2.0938971075236097],
					[61.501602967014527, 2.0938970910583987],
					[61.501602941055737, 2.0938970591205188],
					[61.501602926834451, 2.0938970453142356],
					[61.500675136021741, 2.0931387536361901],
					[61.499097354708617, 2.0914831713786475],
					[61.498222373702561, 2.0902499008724384],
					[61.497186283129096, 2.0879638181186224],
					[61.495980782906649, 2.0852193192790289],
					[61.495980779853291, 2.0852193124171192],
					[61.495980772855411, 2.0852192988920084],
					[61.495980768910897, 2.0852192922288233],
					[61.494836286927317, 2.08341662012048],
					[61.494172395281204, 2.0806305532032594],
					[61.494172377244851, 2.0806305176301052],
					[61.4941723297773, 2.0806304527972683],
					[61.494172298658867, 2.0806304257996553],
					[61.492900098881215, 2.0799054266364521],
					[61.49290007673342, 2.0799054164941047],
					[61.49290003103566, 2.0799054040642844],
					[61.492900007390716, 2.0799053999125134],
					[61.491822206621954, 2.0798692009269906],
					[61.491822138384059, 2.0798692236937564],
					[61.491822034773357, 2.0798693128016117],
					[61.491822001087755, 2.0798693768807746],
					[61.491405401070161, 2.0834443761829662],
					[61.491405401165167, 2.0834443780471892],
					[61.491405401165167, 2.0834443780471892],
					[61.491405401260153, 2.0834443799114166],
					[61.491044201263193, 2.087016679170087],
					[61.491044200941701, 2.0870166905540311],
					[61.491044201094965, 2.0870167112590767],
					[61.491044200678516, 2.0870167207788062],
					[61.491435901456882, 2.0907639213756313],
					[61.491435901836461, 2.0907639288326729],
					[61.491435904283087, 2.09076394148545],
					[61.491435906445012, 2.0907639485454617],
					[61.492041505888096, 2.0931972473129532],
					[61.492041509130758, 2.0931972579034555],
					[61.492041516412435, 2.0931972770216647],
					[61.492041519560267, 2.0931972857478232],
					[61.49258871970261, 2.0943389856558965],
					[61.492588759787488, 2.0943390295237889],
					[61.492588858235209, 2.0943390867935014],
					[61.492588914720841, 2.0943390987278168],
					[61.493941573822468, 2.0942363094342924],
					[61.494491500146538, 2.0954944418777313],
					[61.494499800189438, 2.0970305443242792],
					[61.493908127640921, 2.0980192973334169],
					[61.493908121971472, 2.0980193099083198],
					[61.493908112414886, 2.0980193346614779],
					[61.493908106745458, 2.0980193472363751],
					[61.493105407038321, 2.1009138473201152],
					[61.493105404933303, 2.1009138591012246],
					[61.493105400912818, 2.1009138863922461],
					[61.493105399888513, 2.1009139017039069],
					[61.493119199722088, 2.1031556004107954],
					[61.493119199816817, 2.1031556022751983],
					[61.493119199911547, 2.103155604139606],
					[61.493119200006277, 2.1031556060040271],
					[61.49318040039239, 2.1050417053745374],
					[61.493180399690594, 2.1050417093014966],
					[61.493180401055305, 2.1050417184254728],
					[61.493180401244715, 2.1050417221543056],
					[61.493349766827777, 2.1065636182549219],
					[61.492427889885164, 2.1062776181775762],
					[61.491066858451362, 2.1053137678038207],
					[61.490575185241099, 2.1042860221058257],
					[61.490077987833928, 2.1029055311854443],
					[61.490077985862101, 2.1029055278533102],
					[61.490077982714915, 2.1029055191266415],
					[61.49007798064833, 2.102905513930291],
					[61.48980848121105, 2.1023360137351985],
					[61.489808433809664, 2.1023359677174511],
					[61.489808321106025, 2.1023359135974689],
					[61.489808254912575, 2.1023359056933844],
					[61.489047154588683, 2.1025109052621356],
					[61.489047114162489, 2.1025109255624064],
					[61.489047044951668, 2.1025109824266708],
					[61.489047017058219, 2.1025110187925109],
					[61.488024816762653, 2.1048277192289762],
					[61.488024815966163, 2.1048277212911635],
					[61.488024812685474, 2.1048277276758456],
					[61.488024811983706, 2.10482773160214],
					[61.487274821163368, 2.1068860076525033],
					[61.486135946065723, 2.1085026704896128],
					[61.485383193770645, 2.1092637230502986],
					[61.483805584286813, 2.1097303966198906],
					[61.482072317199702, 2.109502613639247],
					[61.480000166941927, 2.1063526904708891],
					[61.480000160135525, 2.1063526806748922],
					[61.480000145726216, 2.106352663144496],
					[61.480000139014479, 2.1063526552121337],
					[61.478747357808317, 2.1051470748819252],
					[61.477936271830963, 2.1037942993500498],
					[61.476250173218197, 2.1008582001272882],
					[61.476250168288537, 2.1008581918008526],
					[61.476250155755707, 2.1008581757422391],
					[61.476250149043693, 2.1008581678119844],
					[61.474677958834221, 2.0990915767775835],
					[61.473105766196049, 2.0967387892293021],
					[61.473105765115271, 2.0967387857011262],
					[61.473105761360969, 2.0967387827675248],
					[61.473105759389014, 2.096738779437525],
					[61.47116955857971, 2.0941804795019978],
					[61.471169556607713, 2.0941804761723595],
					[61.471169552758532, 2.0941804713761791],
					[61.471169549895372, 2.0941804682447902],
					[61.469819551046051, 2.0926387690173938],
					[61.468680761956328, 2.0913137823435441],
					[61.46792517088052, 2.0900804953494112],
					[61.467925147974384, 2.0900804703039393],
					[61.467925095317455, 2.0900804273882816],
					[61.467925064770348, 2.0900804115792826],
					[61.466522336360036, 2.0895998341631001],
					[61.465497379641256, 2.0877999117265937],
					[61.464475184638772, 2.0852971241099412],
					[61.464475181585513, 2.0852971172547723],
					[61.464475175573973, 2.0852971054070717],
					[61.46447517162953, 2.0852970987503601],
					[61.463611272446016, 2.0838387979101776],
					[61.463611268596516, 2.0838387931163891],
					[61.463611262679805, 2.0838387831318661],
					[61.463611258830305, 2.0838387783380918],
					[61.462269558957658, 2.0820665797063178],
					[61.462269545626633, 2.0820665657232826],
					[61.46226951629113, 2.0820665383527897],
					[61.462269499395504, 2.0820665251638411],
					[61.461188999241109, 2.0814470265232665],
					[61.461188976202365, 2.0814470165872079],
					[61.461188927831422, 2.081447004760363],
					[61.461188903295351, 2.0814470008086232],
					[61.459950112195301, 2.081427601125573],
					[61.459464082804708, 2.0806443157893026],
					[61.458558491050965, 2.0777860398017252],
					[61.458558490064753, 2.0777860381380999],
					[61.458558489969718, 2.0777860362758589],
					[61.458558488983556, 2.0777860346122261],
					[61.458130789258085, 2.0765332343709786],
					[61.458130760052285, 2.0765331919128145],
					[61.458130682795989, 2.0765331262643887],
					[61.458130635636671, 2.0765331028754752],
					[61.456783426885039, 2.0762887191195505],
					[61.45587237729459, 2.074719306537061],
					[61.455283481178085, 2.0734805136832546],
					[61.455283469438363, 2.0734804955854176],
					[61.455283442394375, 2.0734804601845851],
					[61.455283427185186, 2.0734804447436219],
					[61.454372326899268, 2.0727331455641753],
					[61.454372311084228, 2.0727331359086523],
					[61.454372276685589, 2.0727331153318032],
					[61.454372259278493, 2.0727331097976709],
					[61.452866758525055, 2.0722692082326741],
					[61.452866740321902, 2.0722692047596234],
					[61.452866703214703, 2.0722692017360238],
					[61.452866684215529, 2.0722692003235967],
					[61.45222231679854, 2.0723219891871101],
					[61.451419584016513, 2.0707471199507577],
					[61.450836287695971, 2.0691582312471355],
					[61.450836285723376, 2.069158227921239],
					[61.450836282574237, 2.069158219208878],
					[61.450836281397635, 2.0691582138224391],
					[61.450569580841282, 2.0685943140752658],
					[61.450569557646098, 2.0685942834738351],
					[61.450569502820372, 2.068594233567671],
					[61.450569471094568, 2.0685942124012695],
					[61.449550088178484, 2.0682081204223746],
					[61.448036203892357, 2.0672804298849452],
					[61.448036191831847, 2.0672804231618156],
					[61.448036165132592, 2.0672804121736292],
					[61.448036152276146, 2.0672804075108968],
					[61.44637225165522, 2.0668331067825716],
					[61.446372235329441, 2.066833104777019],
					[61.446372200895588, 2.0668331011635415],
					[61.446372183773825, 2.0668331012182928],
					[61.445244472321519, 2.0669247933754273],
					[61.444441837433821, 2.0661748537155846],
					[61.444441801252154, 2.0661748335495482],
					[61.44444172500431, 2.066174805379779],
					[61.444441684237383, 2.066174801297584],
					[61.443097184317899, 2.0662831008975937],
					[61.443097158475311, 2.0662831066632084],
					[61.443097109749012, 2.0662831231818446],
					[61.443097085974195, 2.0662831341336592],
					[61.441749914349693, 2.0672137162440438],
					[61.440563873180338, 2.0675441990974268],
					[61.439219468582415, 2.0675358994132242],
					[61.437458423026307, 2.0661637416567813],
					[61.437458410965789, 2.0661637349361053],
					[61.437458384967236, 2.0661637200313669],
					[61.437458372015591, 2.0661637135094582],
					[61.435958372326027, 2.0655887134348694],
					[61.435958362238097, 2.0655887100388455],
					[61.435958343939667, 2.0655887047101094],
					[61.435958333946907, 2.0655887031749476],
					[61.433677862610125, 2.065194208145154],
					[61.432075112140986, 2.064394232238699],
					[61.43036953160118, 2.0629054495890058],
					[61.430369524091333, 2.0629054437374443],
					[61.430369509166859, 2.062905433894791],
					[61.430369500670679, 2.0629054263815121],
					[61.428980743822386, 2.0620915521183472],
					[61.428294571415137, 2.0609831964448411],
					[61.427769571822566, 2.0600970979463855],
					[61.42776955829914, 2.0600970802667535],
					[61.42776952590556, 2.0600970461006813],
					[61.427769508021811, 2.0600970312757081],
					[61.425850108009875, 2.0588637311159621],
					[61.425850053113997, 2.0588637151456846],
					[61.425849944146073, 2.0588637168879829],
					[61.425849889978743, 2.0588637327403214],
					[61.425041589715704, 2.0593970327939592],
					[61.42504157504311, 2.0593970454754786],
					[61.425041547575354, 2.05939707230098],
					[61.425041534780227, 2.0593970864449518],
					[61.422752643710808, 2.062727674330894],
					[61.421452671932443, 2.064113744252273],
					[61.420155640585058, 2.0649191387914012],
					[61.419972378137089, 2.0647027011851904],
					[61.419322390810514, 2.0626666388054038],
					[61.419322365260982, 2.0626665974759919],
					[61.419322298883493, 2.0626665332729455],
					[61.419322257069211, 2.0626665087381562],
					[61.418414046835686, 2.0623942354760678],
					[61.417839085751396, 2.0610471263104273],
					[61.417164087265583, 2.0592471305078721],
					[61.417164085102307, 2.0592471234666689],
					[61.41716407908882, 2.0592471116415281],
					[61.417164077020814, 2.0592471064600226],
					[61.416691876955348, 2.0583582050907188],
					[61.416691875077859, 2.0583582036286767],
					[61.416691871037038, 2.0583581951256202],
					[61.416691869159521, 2.0583581936635822],
					[61.416061269353563, 2.0573665925372127],
					[61.416061209463919, 2.0573665494743709],
					[61.416061075230886, 2.0573665154772058],
					[61.416061001873899, 2.057366526203666],
					[61.415333201783817, 2.0577776265606555],
					[61.415333159929574, 2.0577776716354319],
					[61.415333111226737, 2.0577777765446297],
					[61.415333104378135, 2.0577778363790125],
					[61.415960904371453, 2.0610889365325855],
					[61.415960905738743, 2.0610889456320476],
					[61.415960910255549, 2.0610889634335017],
					[61.415960913309853, 2.0610889702758319],
					[61.416691503203189, 2.0630028446631834],
					[61.416877601118884, 2.0651917164280591],
					[61.41687760385291, 2.0651917346279154],
					[61.416877612790294, 2.0651917683733885],
					[61.416877619088822, 2.0651917857787314],
					[61.417088637634201, 2.0656388123564495],
					[61.416061041680727, 2.0661887048022081],
					[61.415416684429935, 2.0662415017591917],
					[61.415416675423508, 2.0662415018854201],
					[61.415416657600936, 2.0662415058571697],
					[61.415416648689671, 2.0662415078430389],
					[61.414772243703915, 2.0664108822323208],
					[61.413814022405141, 2.0656776408050748],
					[61.413814014004316, 2.0656776351542594],
					[61.413813997297829, 2.0656776257121763],
					[61.413813988992175, 2.0656776219208877],
					[61.413013988950048, 2.0652804205222659],
					[61.413013983412945, 2.0652804179948778],
					[61.413013972433895, 2.0652804147995645],
					[61.41301396591053, 2.0652804106112863],
					[61.411677921682582, 2.064810930242996],
					[61.410069590325506, 2.0627443175892521],
					[61.409997398196829, 2.0622860689279312],
					[61.40999739269391, 2.0622860494690074],
					[61.409997379109875, 2.0622860130064264],
					[61.409997370042468, 2.0622859943421243],
					[61.409314069649966, 2.0611831953740429],
					[61.409314018767937, 2.061183152184717],
					[61.409313902167632, 2.0611831104843867],
					[61.409313837245236, 2.061183109915476],
					[61.407913938925788, 2.0616497751633709],
					[61.407383423818494, 2.0612304432943103],
					[61.40738340612544, 2.0612304321958841],
					[61.40738336727005, 2.0612304126528018],
					[61.40738334610775, 2.0612304042081564],
					[61.405941773032886, 2.0608887120750139],
					[61.404319520323618, 2.0599831371744335],
					[61.403316837683278, 2.0590276548252704],
					[61.403316769645194, 2.0590276286331908],
					[61.403316633372164, 2.0590276251760185],
					[61.403316565422969, 2.059027653487556],
					[61.402069479870342, 2.0601746544233297],
					[61.401500186334701, 2.0587193267059201],
					[61.401500184266666, 2.0587193215269775],
					[61.401500180225952, 2.0587193130278658],
					[61.401500178253222, 2.0587193097077012],
					[61.400875177564636, 2.0574999089652972],
					[61.400875178455756, 2.0574999087665784],
					[61.400875177374097, 2.0574999052478269],
					[61.400875175591871, 2.0574999056452619],
					[61.400458480993045, 2.0567249154917748],
					[61.400047385358313, 2.0557193243779541],
					[61.400047294727756, 2.0557192618760962],
					[61.400047110654235, 2.0557192578150847],
					[61.400047018102384, 2.0557193160571581],
					[61.399383119812235, 2.0571638140031023],
					[61.398663735414928, 2.058613783836972],
					[61.398174857177189, 2.0591165608745672],
					[61.398174852020944, 2.0591165657836084],
					[61.398174841803787, 2.0591165774602627],
					[61.398174836742825, 2.0591165842278873],
					[61.397519237251345, 2.0600331835008698],
					[61.397519219326838, 2.0600332382496491],
					[61.397519215047943, 2.0600333482297559],
				],
			],
		},
		{
			fldNpdidField: "43562",
			wlbNpdidWellbore: "143",
			fldName: "BALDER",
			fldCurrentActivitySatus: "Producing",
			wlbName: "25/11-1",
			fldDiscoveryYear: "1967",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43562",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43562",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "001",
			cmpLongName: "Vår Energi ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=143",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "50000032",
			fldNpdidOwner: "20756",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Dusavik",
			fldAreaGeometryWKT: [
				[
					[59.265310901402394, 2.3859721809085577],
					[59.265310909421551, 2.3859722321362762],
					[59.265310950080554, 2.385972322760189],
					[59.265310982720401, 2.3859723621563984],
					[59.269866582711067, 2.3892640627887074],
					[59.269866662807985, 2.3892640788784232],
					[59.26986680802883, 2.38926404709711],
					[59.26986687404473, 2.3892639990525217],
					[59.272044574119768, 2.3854194988861162],
					[59.272044579027238, 2.3854194891305296],
					[59.272044587058147, 2.3854194699667883],
					[59.272044591073616, 2.385419460384913],
					[59.272572386570573, 2.3837306703134762],
					[59.273383468304914, 2.3819251148477067],
					[59.275172343052333, 2.3801001402437469],
					[59.275172354114389, 2.3801001275265814],
					[59.275172373384471, 2.3801000991282653],
					[59.275172381592526, 2.3801000834471213],
					[59.275833465854468, 2.3786695176953607],
					[59.276955740263475, 2.3775668421031839],
					[59.276955745437384, 2.3775668375736969],
					[59.276955754715154, 2.377566825202921],
					[59.276955759889063, 2.377566820673453],
					[59.278036117980214, 2.3761281098651326],
					[59.279955454563208, 2.3781473375665398],
					[59.279955461163254, 2.378147343321011],
					[59.279955474363334, 2.3781473548299608],
					[59.279955480963395, 2.378147360584415],
					[59.280838781352713, 2.3788029605536303],
					[59.280838808199725, 2.3788029746831882],
					[59.280838864124654, 2.3788029937050243],
					[59.280838894183596, 2.3788030001664411],
					[59.281164593262645, 2.3788124764123508],
					[59.281899806001782, 2.3808417502640946],
					[59.282427600835703, 2.384013919910247],
					[59.282652600025344, 2.3901167078386751],
					[59.282652601628271, 2.3901167216107884],
					[59.282652605637267, 2.3901167472381228],
					[59.282652609024218, 2.3901167606628997],
					[59.283841508899407, 2.3939083603266971],
					[59.283841515050867, 2.3939083749749352],
					[59.283841530029854, 2.3939084037506766],
					[59.283841537965351, 2.3939084180517738],
					[59.289663737673038, 2.4019112174029327],
					[59.289663744360851, 2.4019112249072099],
					[59.2896637558637, 2.4019112385189851],
					[59.289663763354817, 2.4019112441062607],
					[59.290708163414124, 2.4028973448362811],
					[59.29070818874095, 2.4028973645664347],
					[59.290708246801998, 2.4028973902605393],
					[59.290708278821612, 2.4028973998852221],
					[59.294822178979501, 2.4033390992648749],
					[59.294822184331714, 2.4033390982244787],
					[59.294822194321519, 2.4033390998048718],
					[59.294822198870413, 2.4033391006817553],
					[59.298352730615541, 2.403353000512884],
					[59.299844241651947, 2.4045057300520014],
					[59.300958120731892, 2.4067278895562461],
					[59.302519220852993, 2.4098583897326824],
					[59.302519228698316, 2.4098584023008867],
					[59.302519248045769, 2.4098584284884601],
					[59.302519258655863, 2.4098584422811222],
					[59.30433875726348, 2.4116695393793051],
					[59.304645448239192, 2.4119938995764691],
					[59.304122183043312, 2.4120387015333287],
					[59.30412217135806, 2.4120387020410048],
					[59.304122148164701, 2.412038706545141],
					[59.304122136656616, 2.4120387105416072],
					[59.30315553733017, 2.4123609100040069],
					[59.303155513599094, 2.4123609216583684],
					[59.303155471754977, 2.4123609491608118],
					[59.303155451857862, 2.4123609653553295],
					[59.302030452135043, 2.4135942645895465],
					[59.302030448832497, 2.4135942705151505],
					[59.302030441069718, 2.4135942773066694],
					[59.302030437767208, 2.4135942832322685],
					[59.301333147664884, 2.4145609688185909],
					[59.299830501930167, 2.4160081165157989],
					[59.29784435774679, 2.4164387043189204],
					[59.297844339994093, 2.4164387095245643],
					[59.297844305646358, 2.4164387249950527],
					[59.297844288962771, 2.416438733515812],
					[59.296397117716133, 2.4174026143384304],
					[59.295111056739394, 2.4177248045421686],
					[59.293177761870211, 2.4180998032868821],
					[59.293177744117429, 2.4180998084907834],
					[59.293177710572969, 2.4180998220403049],
					[59.293177694781299, 2.4180998303859282],
					[59.292052694929062, 2.4187970292018801],
					[59.292052687077451, 2.4187970342462628],
					[59.292052672443262, 2.4187970476496758],
					[59.292052664591644, 2.4187970526940612],
					[59.290069278023957, 2.4206220413052799],
					[59.28888883561099, 2.4213720032042283],
					[59.287225019318072, 2.4214803974608672],
					[59.285991751324381, 2.4211581052231614],
					[59.2859917477561, 2.4211581059146519],
					[59.285991743207283, 2.4211581050355213],
					[59.285991741334691, 2.4211581036377678],
					[59.284705641033334, 2.4208887040926275],
					[59.284705621142422, 2.4208887026654993],
					[59.284705580556981, 2.420888701727526],
					[59.284705559950908, 2.4208887039601303],
					[59.283630559648905, 2.4211054037094537],
					[59.283630525300374, 2.4211054191678216],
					[59.283630460790761, 2.4211054615967798],
					[59.283630434286401, 2.4211054896193169],
					[59.282880434305177, 2.4222304894656541],
					[59.282880425111415, 2.422230503570145],
					[59.282880412253192, 2.4222305342288508],
					[59.282880405824088, 2.4222305495581962],
					[59.282397006038273, 2.4241082502154163],
					[59.282397005411418, 2.4241082556181763],
					[59.282397003354063, 2.424108268339777],
					[59.282397001835129, 2.4241082739153108],
					[59.282183101778905, 2.4257721738466533],
					[59.282183101867282, 2.4257721755899735],
					[59.282183100975203, 2.4257721757626967],
					[59.28218310106358, 2.4257721775060359],
					[59.282022010587426, 2.4271665963386369],
					[59.281208247386949, 2.4278386844450885],
					[59.28018072973601, 2.4276165245389203],
					[59.279502974020552, 2.4264138020834287],
					[59.279502967068993, 2.4264137893467694],
					[59.279502949862646, 2.4264137697937578],
					[59.279502941215291, 2.4264137591456678],
					[59.278805741093905, 2.4257165585048641],
					[59.278805725133068, 2.4257165457525067],
					[59.278805688839455, 2.4257165228544317],
					[59.278805670290772, 2.4257165123633122],
					[59.277947269854558, 2.4253942121943042],
					[59.277947265305762, 2.4253942113147366],
					[59.277947253355187, 2.4253942065875038],
					[59.277947247022233, 2.4253942060533524],
					[59.276580647361492, 2.4250637064832161],
					[59.275961225288405, 2.4249137244129666],
					[59.27547801368091, 2.4241610634730075],
					[59.27574739336233, 2.4230361520123798],
					[59.276122390765387, 2.421802858172589],
					[59.276122391569025, 2.421802856256805],
					[59.276122393176315, 2.4218028524252193],
					[59.276122393979961, 2.4218028505094344],
					[59.276605793434314, 2.4199250512680197],
					[59.277033493147812, 2.4183167505308183],
					[59.277033496546366, 2.4183167287499963],
					[59.277033497902615, 2.4183166844826198],
					[59.277033495771811, 2.418316660253049],
					[59.276819597631075, 2.4172416712501987],
					[59.276711304291297, 2.4160083352566732],
					[59.27730297021207, 2.4147223086421974],
					[59.278483452834458, 2.4133279288032958],
					[59.278483455422069, 2.4133279265411329],
					[59.278483459616737, 2.4133279204467208],
					[59.278483462115815, 2.4133279164414683],
					[59.279608460386136, 2.4117723194427505],
					[59.280305758810549, 2.4108612216516425],
					[59.280305773538743, 2.4108611923881682],
					[59.280305794789491, 2.4108611319328066],
					[59.280305800331384, 2.4108610991708552],
					[59.280305799970414, 2.4086610997418481],
					[59.280305800419384, 2.4086610908528567],
					[59.280305797749151, 2.408661073767512],
					[59.28030579721748, 2.4086610633085122],
					[59.279983497159115, 2.4068916643868126],
					[59.279983496001151, 2.4068916593305816],
					[59.279983493773884, 2.4068916509612683],
					[59.279983492704574, 2.4068916476481901],
					[59.279394593281239, 2.4047443466759706],
					[59.279394586061613, 2.4047443287146071],
					[59.279394568054222, 2.4047442934848982],
					[59.279394557266443, 2.4047442762165585],
					[59.278589056739435, 2.4037248756418568],
					[59.278589044344812, 2.4037248622069329],
					[59.278589017056788, 2.4037248393430128],
					[59.278589002966768, 2.4037248279976824],
					[59.277786202722638, 2.4032442286649758],
					[59.277786177839403, 2.4032442176565021],
					[59.277786126732281, 2.4032442047012323],
					[59.277786100331042, 2.4032441992683902],
					[59.276927799601054, 2.4032442008501658],
					[59.276927739385762, 2.4032442213480256],
					[59.276927643118498, 2.4032442946142401],
					[59.276927607066547, 2.4032443473825906],
					[59.276444207740482, 2.4050138428787902],
					[59.275960919697212, 2.4065138073890582],
					[59.275211022646019, 2.4075330672012334],
					[59.274514043769315, 2.407319234181565],
					[59.274191881865562, 2.4066221157107806],
					[59.274191877942819, 2.4066221094320794],
					[59.274191869028051, 2.4066220935621723],
					[59.274191865105323, 2.4066220872834703],
					[59.273602978294839, 2.4057638054055945],
					[59.273064087826469, 2.404369331815944],
					[59.272422392186769, 2.4025471437409402],
					[59.271939093823548, 2.4005610533940649],
					[59.271939088298808, 2.4005610333481191],
					[59.271939072163221, 2.400560999524243],
					[59.271939062267002, 2.4005609820875788],
					[59.271080762164303, 2.3993804825140064],
					[59.271080670032873, 2.3993804423401492],
					[59.271080497071779, 2.3993804601108093],
					[59.271080416419615, 2.3993805215406545],
					[59.269794216941371, 2.4023860184108754],
					[59.269255418361432, 2.4035638167367694],
					[59.269255415951321, 2.4035638224841676],
					[59.269255412023156, 2.4035638338057574],
					[59.269255409613038, 2.4035638395531715],
					[59.268666509905309, 2.4053888385540696],
					[59.268666506957636, 2.405388851444672],
					[59.268666501865738, 2.4053888753101109],
					[59.268666499721483, 2.4053888862849728],
					[59.268505399667411, 2.4078555870629352],
					[59.268505399844635, 2.4078555905480505],
					[59.268505400287701, 2.4078555992608548],
					[59.268505399572888, 2.407855602919069],
					[59.268558100102958, 2.4113972022446144],
					[59.268558100191534, 2.4113972039871885],
					[59.268558100280089, 2.411397205729763],
					[59.268613700062261, 2.4135417054954393],
					[59.268613700327847, 2.4135417107232082],
					[59.268613700770544, 2.4135417194361404],
					[59.268613701928217, 2.4135417244909365],
					[59.268880401889739, 2.4156861258522344],
					[59.268880401889739, 2.4156861258522344],
					[59.26914980158093, 2.4177778260951173],
					[59.2691498029154, 2.4177778346354004],
					[59.269149808437533, 2.4177778546827238],
					[59.269149809772003, 2.4177778632230211],
					[59.269738709641871, 2.4195500631526881],
					[59.269738710710854, 2.4195500664652081],
					[59.269738712760365, 2.4195500713475875],
					[59.269738713740892, 2.4195500729174624],
					[59.270435913587114, 2.4213194726971956],
					[59.270435919647035, 2.4213194856022859],
					[59.270435934354659, 2.4213195091514419],
					[59.270435941306665, 2.4213195218837691],
					[59.271133135579596, 2.4222307149035345],
					[59.272205429075164, 2.4238945043886635],
					[59.272849826099765, 2.4250194993490624],
					[59.2728498280607, 2.4250195024893775],
					[59.272849831982576, 2.425019508770025],
					[59.272849833943503, 2.4250195119103584],
					[59.273977634403337, 2.4266834119571703],
					[59.27397763529541, 2.4266834117845271],
					[59.273977635383773, 2.426683413527432],
					[59.274674832403278, 2.4277029072932432],
					[59.275158112741238, 2.4285056772806901],
					[59.275383105882995, 2.4294083472261061],
					[59.27538310695175, 2.4294083505394908],
					[59.275383108108826, 2.4294083555958781],
					[59.275383108197154, 2.4294083573388616],
					[59.276024798837113, 2.4315528277187548],
					[59.275972000000323, 2.4335916793689041],
					[59.275702604177418, 2.4350943608219833],
					[59.275383105111771, 2.4364888550527879],
					[59.275383103229608, 2.4364888712570894],
					[59.27538310124946, 2.4364889033209201],
					[59.275383101151498, 2.4364889191804213],
					[59.275544200786044, 2.4381500191644667],
					[59.275544206393356, 2.4381500409620358],
					[59.275544220284267, 2.4381500840400734],
					[59.275544229371775, 2.4381501034051758],
					[59.275830428555089, 2.4386195035526965],
					[59.275830430427497, 2.4386195049510615],
					[59.27639983020314, 2.4395445041427402],
					[59.276399830291339, 2.4395445058858121],
					[59.276399831271654, 2.4395445074565543],
					[59.276399832163776, 2.4395445072842272],
					[59.277097031872131, 2.4406251086597983],
					[59.277097031872131, 2.4406251086597983],
					[59.277474826790979, 2.4412084017240661],
					[59.277930423131664, 2.4420694934002554],
					[59.278494222690306, 2.4431389933814347],
					[59.278494224650821, 2.4431389965233423],
					[59.278494229640273, 2.4431390061212785],
					[59.278494233473168, 2.443139010661866],
					[59.279255433421099, 2.4443001103567399],
					[59.279255437253973, 2.444300114897572],
					[59.279255445095963, 2.4443001274656959],
					[59.27925544982093, 2.4443001318343094],
					[59.279713749432801, 2.4448223310408186],
					[59.280185949168434, 2.4453668304613667],
					[59.280185950148685, 2.4453668320324522],
					[59.280585950160955, 2.4458195318804812],
					[59.28058595595423, 2.445819539563876],
					[59.280585969060674, 2.445819549356393],
					[59.280585974765835, 2.4458195552964797],
					[59.28122767494839, 2.4463334568202741],
					[59.28122767494839, 2.4463334568202741],
					[59.281960974777391, 2.4469168571586164],
					[59.281960975669513, 2.4469168569864457],
					[59.282413736014206, 2.4472751264957577],
					[59.282760915916604, 2.4480806781982389],
					[59.283202615886609, 2.4491111794380407],
					[59.283202618827197, 2.449111184152049],
					[59.28320262390433, 2.4491111954956271],
					[59.283202626844904, 2.4491112002096376],
					[59.283519226728266, 2.4496529009691592],
					[59.283519236354039, 2.4496529131958411],
					[59.283519257389806, 2.449652937305002],
					[59.2835192679077, 2.44965294935959],
					[59.284183159291146, 2.4502390412504567],
					[59.284669249969056, 2.4507862337210118],
					[59.285274849630532, 2.4514695329462786],
					[59.285672049877142, 2.451919532898704],
					[59.285672064051369, 2.4519195460100378],
					[59.285672095880336, 2.4519195698008778],
					[59.285672111750785, 2.4519195808244914],
					[59.286388811769925, 2.4522695808631392],
					[59.286913812067013, 2.4525251802114254],
					[59.286913817595853, 2.4525251826663976],
					[59.286913831329876, 2.4525251870601408],
					[59.286913836858702, 2.4525251895151179],
					[59.287880514328769, 2.4528473824888906],
					[59.288035994296507, 2.4529445694382814],
					[59.288035995188643, 2.4529445692662253],
					[59.288485994531591, 2.4532223694238691],
					[59.289035990978093, 2.4535640678138995],
					[59.289405488448189, 2.4538140664083432],
					[59.289405489340339, 2.4538140662362982],
					[59.290180488789375, 2.4543334669059877],
					[59.290180493426035, 2.4543334695334766],
					[59.290180500827127, 2.4543334733887057],
					[59.290180506355895, 2.4543334758441655],
					[59.29062770601692, 2.4545723754290236],
					[59.290627707977194, 2.4545723785726681],
					[59.290627712525861, 2.4545723794563838],
					[59.290627716270393, 2.4545723822559666],
					[59.291547115888925, 2.4549973822324684],
					[59.29154712320198, 2.4549973843440673],
					[59.291547137023969, 2.4549973904831992],
					[59.291547145229138, 2.4549973924227748],
					[59.292174890350836, 2.4551779770800879],
					[59.292677651997451, 2.4557279348219123],
					[59.292677652889587, 2.4557279346498975],
					[59.292677652977581, 2.4557279363938571],
					[59.292938753009565, 2.4560112368666482],
					[59.292938755861961, 2.4560112398385718],
					[59.292938763350975, 2.4560112454384244],
					[59.292938766203363, 2.4560112484103467],
					[59.293458165905733, 2.4564807471428014],
					[59.293849855057509, 2.4568362375444144],
					[59.29422764464725, 2.4572973268869074],
					[59.294572045061727, 2.4577195265090466],
					[59.29457207885001, 2.4577195534552505],
					[59.294572154556207, 2.4577195899312714],
					[59.294572197366264, 2.4577195992891281],
					[59.295083296273361, 2.4577279999354058],
					[59.295599995645283, 2.4577390999604747],
					[59.295599997429555, 2.457739099616512],
					[59.295599999213813, 2.4577390992725503],
					[59.295600001086036, 2.4577391006727067],
					[59.296719401212584, 2.4577363005463408],
					[59.297505600934876, 2.4577335000822815],
					[59.297505609944203, 2.4577335001065475],
					[59.297505626982741, 2.4577334985828814],
					[59.297505635904081, 2.4577334968629363],
					[59.298488904794041, 2.4575530016591145],
					[59.299402773054744, 2.4576779974316949],
					[59.30096667300657, 2.4578890983761799],
					[59.30104717332933, 2.4579001981405106],
					[59.302522165416725, 2.4581029975915039],
					[59.303583256659714, 2.4583362951465695],
					[59.304044356110523, 2.4584390945759793],
					[59.304044357002653, 2.4584390944039591],
					[59.305205547363251, 2.4586945936245512],
					[59.306552737238761, 2.4591418896721113],
					[59.307136006754163, 2.4593362804568342],
					[59.30836925353924, 2.4602501403497561],
					[59.308844235190691, 2.4609362142521376],
					[59.309333132957882, 2.4616445109736977],
					[59.31002203175504, 2.4627195085573952],
					[59.310569231867177, 2.4635751078374253],
					[59.310569248176392, 2.4635751275984079],
					[59.310569284099657, 2.4635751611978973],
					[59.310569303801628, 2.4635751767813319],
					[59.312144304223047, 2.4644334760270583],
					[59.312144305115183, 2.4644334758551429],
					[59.312144306007326, 2.4644334756832218],
					[59.312144306095227, 2.4644334774282259],
					[59.315433157997759, 2.4661807517881495],
					[59.318730430136981, 2.4715473056899691],
					[59.318730515376394, 2.4715473527191438],
					[59.318730684682322, 2.471547351841286],
					[59.318730770533151, 2.4715473035907123],
					[59.319966857324701, 2.4695279266261085],
					[59.321950112608278, 2.4676501694033601],
					[59.324150043250931, 2.4666668993407206],
					[59.327205600079324, 2.4666669007628999],
					[59.328238900357768, 2.4666669005975015],
					[59.329619399872726, 2.4666668998110093],
					[59.329619474738479, 2.4666668659878699],
					[59.329619574374021, 2.4666667515879044],
					[59.329619598339541, 2.4666666729289672],
					[59.329514097629556, 2.4659027728270351],
					[59.329514098433798, 2.465902770909151],
					[59.329514098345904, 2.4659027691632533],
					[59.329514097365873, 2.4659027675893683],
					[59.327614096865702, 2.4541693673565486],
					[59.327614096689615, 2.4541693638650415],
					[59.327614095445306, 2.4541693570543552],
					[59.327614094377083, 2.4541693537351423],
					[59.326139131308103, 2.4480862022106167],
					[59.327261148039383, 2.4474835007572291],
					[59.329727796113701, 2.4475362993262593],
					[59.329727803338741, 2.447536299692231],
					[59.329727816808564, 2.4475362988508422],
					[59.329727824837569, 2.447536297298504],
					[59.331444424990011, 2.4473223988069974],
					[59.331444467458802, 2.4473223835433533],
					[59.331444538937241, 2.4473223362259011],
					[59.331444569819269, 2.4473223055730244],
					[59.33187237025426, 2.4466251042722433],
					[59.331872380882018, 2.4466250828241312],
					[59.331872394648045, 2.4466250343243208],
					[59.33187239974685, 2.4466250104194591],
					[59.331980796985455, 2.4445333542891983],
					[59.332408468247621, 2.4437279098222668],
					[59.333333456702142, 2.4425501239311518],
					[59.333333473221103, 2.4425500942883835],
					[59.333333495377303, 2.4425500335825134],
					[59.333333499946093, 2.4425499992000441],
					[59.333333499556829, 2.4410999998000511],
					[59.333333497341322, 2.4410999737827872],
					[59.333333483097221, 2.4410999236477062],
					[59.333333472852821, 2.4410998991845232],
					[59.332141875791741, 2.4390610049948802],
					[59.33139187866832, 2.4375610109260215],
					[59.331391870825392, 2.4375609983410018],
					[59.331391854247421, 2.4375609733436971],
					[59.331391844708584, 2.437560962850049],
					[59.330264045299046, 2.4363804611963418],
					[59.33026404066203, 2.4363804585685904],
					[59.330264034064221, 2.4363804527947521],
					[59.330264030230992, 2.4363804482484079],
					[59.329139029570626, 2.4354137484088456],
					[59.329139008081242, 2.4354137331797836],
					[59.329138960103165, 2.43541371074146],
					[59.32913893539861, 2.4354137031866312],
					[59.328225046208793, 2.4352526057714314],
					[59.327314080945655, 2.4349859443824693],
					[59.327100205839905, 2.4333778315091248],
					[59.327741881709017, 2.4319833845262959],
					[59.327741888677657, 2.4319833620222879],
					[59.327741898066165, 2.4319833161329738],
					[59.327741899682266, 2.4319832946662285],
					[59.32768910017289, 2.4301582940850386],
					[59.327689100084505, 2.430158292339375],
					[59.327689099907772, 2.4301582888480295],
					[59.32768909883896, 2.4301582855296209],
					[59.32752799853148, 2.4279055853216827],
					[59.327527998089487, 2.427905576593425],
					[59.32752799533295, 2.4279055577372186],
					[59.327527993106806, 2.4279055493549242],
					[59.327100193192308, 2.4262971491454182],
					[59.327100183837338, 2.4262971245182392],
					[59.327100160040239, 2.4262970815388853],
					[59.327100144617603, 2.4262970616141097],
					[59.326080744822939, 2.4252248622943728],
					[59.326080731626476, 2.4252248507524201],
					[59.326080703537784, 2.4252248297601509],
					[59.326080689626103, 2.4252248218823707],
					[59.325222289641282, 2.4247942217482277],
					[59.325222275818007, 2.4247942156163211],
					[59.325222247367826, 2.4247942052710947],
					[59.325222232829361, 2.4247942028032727],
					[59.324255632977739, 2.4246331024525523],
					[59.324255580080191, 2.4246331074257821],
					[59.324255483817204, 2.4246331454878516],
					[59.324255441343837, 2.4246331784036457],
					[59.323397076547501, 2.4257609318903985],
					[59.32264718519928, 2.4260275935416291],
					[59.321841907643424, 2.4258665376024133],
					[59.321950199548482, 2.4236694096260645],
					[59.321950200175202, 2.423669404216966],
					[59.321950199732953, 2.4236693954902133],
					[59.321950200359666, 2.4236693900810979],
					[59.321897399926435, 2.4224332915345501],
					[59.321897383891404, 2.4224332417705252],
					[59.321897328177855, 2.4224331556425356],
					[59.321897288587785, 2.4224331210239138],
					[59.321144488897367, 2.4220581213333112],
					[59.321144449653737, 2.4220581113231758],
					[59.321144370178608, 2.4220581073568264],
					[59.321144329947096, 2.4220581134006118],
					[59.32028603037736, 2.4223804137377285],
					[59.320285997098033, 2.4223804325314529],
					[59.320285942040364, 2.4223804837493415],
					[59.320285920085112, 2.4223805126829925],
					[59.319588785167831, 2.4238303775456078],
					[59.318625110841978, 2.423669216581037],
					[59.317766873421846, 2.4225415975450515],
					[59.317230781988371, 2.4213610177098999],
					[59.317230769152836, 2.4213609955285653],
					[59.317230739286842, 2.4213609572665886],
					[59.31723072225639, 2.4213609411859585],
					[59.315622355768646, 2.4201276674363745],
					[59.314869584021189, 2.4187332205509908],
					[59.314011300173405, 2.4161582656265259],
					[59.314010190156132, 2.4160606753059914],
					[59.314586250776152, 2.4153945303647451],
					[59.314586261753036, 2.4153945158982735],
					[59.314586279961411, 2.4153944841683188],
					[59.314586288173537, 2.4153944684765705],
					[59.31531128792686, 2.4133972684460763],
					[59.315311291140731, 2.4133972607730834],
					[59.315311294803763, 2.4133972442019194],
					[59.315311297037034, 2.4133972349572113],
					[59.31584189669212, 2.4104472347197459],
					[59.315841898210351, 2.4104472291380472],
					[59.315841898659308, 2.4104472202397291],
					[59.315841899196855, 2.4104472130864201],
					[59.316105798831856, 2.4067667133932948],
					[59.316105799635231, 2.4067667114748512],
					[59.316105800349924, 2.4067667078114168],
					[59.316105800172586, 2.4067667043214209],
					[59.316300200152469, 2.3995944054077216],
					[59.316300200063722, 2.3995944036627463],
					[59.316300199974947, 2.3995944019177737],
					[59.316364099846538, 2.393269402659052],
					[59.316364099579971, 2.3932693974241728],
					[59.316364100027734, 2.393269388525574],
					[59.316364098869158, 2.3932693834645229],
					[59.316083500152764, 2.3896721909614653],
					[59.316053000422357, 2.3867306077533286],
					[59.316211298574892, 2.384816716309643],
					[59.316211300269934, 2.3848167142166439],
					[59.316211300180981, 2.384816712471725],
					[59.316333500040663, 2.3829028129356198],
					[59.316333499951675, 2.3829028111907014],
					[59.316333499951675, 2.3829028111907014],
					[59.316505799780039, 2.3797000115908986],
					[59.316505800315895, 2.3797000044370589],
					[59.316505799692663, 2.3796999922226454],
					[59.316505799336525, 2.3796999852429739],
					[59.316405799439408, 2.3783416852926549],
					[59.316405799350356, 2.3783416835477325],
					[59.316405799261311, 2.3783416818028296],
					[59.316405799083199, 2.3783416783130229],
					[59.316150198797025, 2.3759221787683953],
					[59.316150198797025, 2.3759221787683953],
					[59.31615019861885, 2.375922175278613],
					[59.316150197726856, 2.3759221754528794],
					[59.315800199104892, 2.3731332821975073],
					[59.315591900027719, 2.3689832959550858],
					[59.315627999680011, 2.3648639064778108],
					[59.315797400310842, 2.3614417130890417],
					[59.316047398811968, 2.358608317226786],
					[59.316047398633337, 2.3586083137371547],
					[59.316047400149287, 2.3586083081532583],
					[59.316047399970657, 2.358608304663627],
					[59.316141900123988, 2.354819404289481],
					[59.316141899945258, 2.354819400799868],
					[59.31614190039037, 2.3548193919009979],
					[59.316141900211633, 2.3548193884113697],
					[59.315997399859626, 2.3523582882324412],
					[59.315997399323244, 2.352358277763678],
					[59.315997394772083, 2.3523582592704693],
					[59.315997393343736, 2.3523582489765915],
					[59.315594592627086, 2.3508221496448565],
					[59.315594591288047, 2.3508221410959345],
					[59.315594587094346, 2.3508221295822231],
					[59.315594583971425, 2.3508221213831386],
					[59.314605784294152, 2.3484888219455313],
					[59.314605783312757, 2.3484888203757981],
					[59.314605781260539, 2.3484888154916077],
					[59.314605780189709, 2.3484888121771679],
					[59.313622380328013, 2.3464666130009477],
					[59.313622378365196, 2.3464666098616709],
					[59.313622376223435, 2.346466603233115],
					[59.313622374350096, 2.3464666018384888],
					[59.312672375311713, 2.3447805034235158],
					[59.310450177717577, 2.340749908454852],
					[59.31014606687534, 2.3401124421530177],
					[59.310172390959259, 2.3400250574400161],
					[59.310172396393021, 2.3400250228941335],
					[59.310172392008283, 2.3400249550356875],
					[59.310172383973651, 2.3400249213727955],
					[59.309002985987938, 2.3373027268851909],
					[59.307316889927456, 2.3325166389497811],
					[59.305994592067087, 2.3279832437300683],
					[59.305994590995745, 2.3279832404171334],
					[59.305994588942802, 2.3279832355354402],
					[59.30599458787146, 2.3279832322225067],
					[59.304616887772916, 2.3241999311675183],
					[59.304616886791258, 2.3241999295989508],
					[59.304616885719845, 2.3241999262862736],
					[59.304616884738174, 2.3241999247176865],
					[59.304064085248754, 2.3228443237982841],
					[59.30406408426709, 2.3228443222297761],
					[59.304064082213955, 2.3228443173486908],
					[59.304064081232283, 2.322844315780173],
					[59.303522382428959, 2.3216749172309386],
					[59.302452982193515, 2.3193138166877083],
					[59.302452980230086, 2.3193138135510525],
					[59.302452975231688, 2.3193138039654166],
					[59.30245297317844, 2.3193137990847639],
					[59.301527972794055, 2.3177248993908677],
					[59.30152796503004, 2.3177248885888466],
					[59.301527946736549, 2.3177248657679614],
					[59.301527938080682, 2.3177248551416239],
					[59.30060576424939, 2.316847079607224],
					[59.299564083179703, 2.3147027182267879],
					[59.299344585905153, 2.3141582245963339],
					[59.298983486067492, 2.3132555258338185],
					[59.298091885818238, 2.310997127130995],
					[59.298091883764698, 2.3109971222514907],
					[59.298091880729324, 2.3109971158040876],
					[59.29809187867577, 2.3109971109245695],
					[59.297022378934571, 2.3088582103785442],
					[59.297022338957213, 2.3088581689431318],
					[59.297022243226564, 2.3088581120818441],
					[59.297022186761325, 2.3088581003192057],
					[59.292308305947699, 2.3091691996967749],
					[59.289069424588881, 2.3087554023990386],
					[59.28906942360706, 2.3087554008316138],
					[59.283641724350424, 2.3081081004654496],
					[59.283641712666196, 2.3081081010080515],
					[59.283641691883382, 2.3081080998228187],
					[59.283641682072876, 2.3081081017567651],
					[59.282000016950988, 2.3082580981397038],
					[59.279750061093651, 2.3076692081765322],
					[59.274725071444493, 2.3057692134869767],
					[59.27472499143876, 2.3057692169366764],
					[59.274724858837054, 2.3057692835622698],
					[59.274724807934824, 2.3057693446440273],
					[59.273616508132413, 2.3095971442288592],
					[59.27288041067326, 2.3121027335820186],
					[59.272552617384989, 2.3128943184790094],
					[59.271369224293117, 2.3153805021953993],
					[59.270441530572683, 2.3168526930765396],
					[59.27044152406642, 2.3168527066768418],
					[59.270441511945826, 2.3168527337019662],
					[59.270441507133519, 2.3168527452089482],
					[59.269972008404217, 2.3185416435627433],
					[59.269702610819145, 2.3193777327830447],
					[59.269444229653125, 2.3200470837460347],
					[59.2684387649673, 2.3209609516847722],
					[59.26843876416519, 2.3209609536024089],
					[59.268438761579297, 2.3209609558707678],
					[59.268438759795487, 2.320960956221517],
					[59.267810960240546, 2.3215720568040288],
					[59.267810937595719, 2.321572089414035],
					[59.267810908006844, 2.3215721621063907],
					[59.267810900081145, 2.321572200621874],
					[59.267833099810915, 2.326086101649095],
					[59.267833099900635, 2.3260861033913276],
					[59.267833099990334, 2.3260861051335637],
					[59.26783310016976, 2.3260861086180449],
					[59.268033100301068, 2.3311944083956737],
					[59.268033100301068, 2.3311944083956737],
					[59.26803310039071, 2.3311944101379365],
					[59.268033099588429, 2.3311944120552988],
					[59.268316500116995, 2.335833310568924],
					[59.268599800186387, 2.343319404594602],
					[59.268616500059501, 2.3524166999154912],
					[59.268616500238203, 2.3524167034002446],
					[59.268616499703676, 2.352416710544265],
					[59.268616499793026, 2.3524167122866331],
					[59.268922000159804, 2.357036108500369],
					[59.268958099872272, 2.3593527954887246],
					[59.268441500750242, 2.3669360745052823],
					[59.26746920517877, 2.3722832570068633],
					[59.267222007408449, 2.3732221496660846],
					[59.267222005802623, 2.3732221534988214],
					[59.267222004285898, 2.3732221590739395],
					[59.267222002769138, 2.3732221646490603],
					[59.266810902944748, 2.3754860639313167],
					[59.266810903122838, 2.3754860674160461],
					[59.266810901516926, 2.3754860712486359],
					[59.26681090169501, 2.3754860747333693],
					[59.26658040198965, 2.3772693716878508],
					[59.266416503270214, 2.3782749637467893],
					[59.266083104691198, 2.379799957391759],
					[59.266083104066169, 2.3797999627925952],
					[59.266083101924075, 2.3797999737680771],
					[59.266083101210057, 2.3797999774265661],
					[59.265894201658874, 2.3815416717406053],
					[59.26555540316928, 2.3834138646240453],
					[59.265555403347179, 2.3834138681086858],
					[59.265555401115812, 2.3834138773414728],
					[59.26555540129371, 2.3834138808261116],
					[59.265310901402394, 2.3859721809085577],
				],
				[
					[59.220641499801658, 2.4708833019118415],
					[59.220649799848132, 2.4730667003974158],
					[59.220649801354185, 2.4730667124087837],
					[59.220649803386422, 2.4730667348622415],
					[59.220649804892489, 2.4730667468736218],
					[59.220988704787167, 2.4745028455345981],
					[59.220988706922242, 2.4745028521540116],
					[59.220988711104681, 2.4745028636524657],
					[59.220988713239748, 2.4745028702718792],
					[59.221469212982207, 2.4757972699445792],
					[59.221469221083893, 2.4757972877209395],
					[59.221469241660515, 2.4757973206782342],
					[59.221469253243271, 2.4757973360301571],
					[59.222235952678425, 2.4766223358826012],
					[59.222235960166458, 2.4766223414764661],
					[59.222235971573824, 2.4766223533481266],
					[59.22223597906185, 2.4766223589419876],
					[59.22343317266732, 2.4775334558236657],
					[59.224413726566482, 2.4783918132057159],
					[59.224827583693163, 2.4796638840066474],
					[59.224260920679733, 2.4807916099649261],
					[59.224260914418238, 2.480791628737669],
					[59.224260903767174, 2.4807916676819159],
					[59.22426090018223, 2.4807916859419787],
					[59.224144199613349, 2.4824693868489311],
					[59.224144199700916, 2.4824693885894882],
					[59.224144200051221, 2.4824693955517279],
					[59.224144200138788, 2.4824693972922871],
					[59.224116499886961, 2.4860666984529232],
					[59.224116505118758, 2.4860667308407458],
					[59.22411652718089, 2.4860667933965632],
					[59.224116543119031, 2.4860668237353116],
					[59.225847042898664, 2.4882779227231309],
					[59.225847065623945, 2.4882779447360925],
					[59.22584711875426, 2.4882779802630028],
					[59.225847149159307, 2.4882779937769346],
					[59.227280456290373, 2.4886584687007596],
					[59.22802481680592, 2.4903250814487632],
					[59.228024855030753, 2.4903251251046878],
					[59.228024948099829, 2.4903251846321774],
					[59.228025003836265, 2.4903252003330398],
					[59.229244404373084, 2.4903001998633081],
					[59.229244467999315, 2.4903001753884859],
					[59.229244564601906, 2.4903000901202601],
					[59.22924459757828, 2.4903000293268529],
					[59.22940578858308, 2.4892167889743519],
					[59.230311248791345, 2.4882112346254157],
					[59.230311254686718, 2.4882112264666505],
					[59.230311265672761, 2.4882112120607687],
					[59.230311270675941, 2.4882112040727478],
					[59.230800145761663, 2.4874057458885486],
					[59.231208233894996, 2.4871585741218727],
					[59.231646990055403, 2.4878722394303785],
					[59.231460909756514, 2.4889443222456222],
					[59.231010935434483, 2.4896026869049828],
					[59.231010931323425, 2.4896026947222696],
					[59.231010922121584, 2.4896027087866077],
					[59.231010918902733, 2.4896027164331844],
					[59.230466519347914, 2.4907749164002571],
					[59.230466512105309, 2.4907749336045319],
					[59.230466503148257, 2.4907749704708233],
					[59.230466499649403, 2.4907749904742102],
					[59.230399800051522, 2.4920777894267978],
					[59.230399799684029, 2.4920778000429058],
					[59.230399800645998, 2.4920778191929309],
					[59.230399802062934, 2.4920778294677532],
					[59.230719202274663, 2.4941639291826072],
					[59.230719203341735, 2.4941639324938945],
					[59.230719202624357, 2.4941639361463497],
					[59.230719203604018, 2.49416393771669],
					[59.231277600835597, 2.4970389221689757],
					[59.231360900034957, 2.4998306051207373],
					[59.231447000068087, 2.5036417052926945],
					[59.231447000242689, 2.5036417087746989],
					[59.231447000679175, 2.5036417174797219],
					[59.231447000941074, 2.503641722702743],
					[59.231719200662653, 2.5060556220550514],
					[59.231719204037475, 2.5060556354724053],
					[59.231719209807622, 2.5060556607363869],
					[59.231719213095175, 2.5060556724127183],
					[59.232272012571812, 2.507488971287728],
					[59.232272021561649, 2.5074889889071255],
					[59.232272042043462, 2.5074890201530642],
					[59.232272052730501, 2.5074890356909068],
					[59.232869253300443, 2.5081334357430025],
					[59.232869286903949, 2.5081334592129969],
					[59.232869362422541, 2.5081334922629757],
					[59.232869403358201, 2.5081335002721028],
					[59.233705602581232, 2.5081196003753234],
					[59.233705619620892, 2.5081195988818217],
					[59.233705654417989, 2.5081195922422985],
					[59.233705670390968, 2.5081195874367714],
					[59.234988969870372, 2.5076362868241251],
					[59.234988978705445, 2.5076362833802772],
					[59.23498899548337, 2.5076362766628231],
					[59.234989001554503, 2.507636271988571],
					[59.236108401969744, 2.5069695716092544],
					[59.23610841589641, 2.5069695619195871],
					[59.236108441790769, 2.5069695393983347],
					[59.236108453758447, 2.5069695265667491],
					[59.237080753708355, 2.5057918261959262],
					[59.237080754687867, 2.505791827766894],
					[59.237080756385062, 2.5057918256849412],
					[59.237080757189993, 2.5057918237733188],
					[59.237863998277675, 2.5048057985810988],
					[59.238363830578656, 2.5047974006303484],
					[59.238983131411096, 2.5053001197607925],
					[59.239347002595302, 2.5063167445942813],
					[59.239469199200926, 2.5076193954579744],
					[59.239283102256671, 2.508877770105824],
					[59.239283102343904, 2.5088777718472435],
					[59.239283101626143, 2.5088777755003453],
					[59.239283100733886, 2.5088777756706113],
					[59.239074800804481, 2.5105999759488808],
					[59.239074801414965, 2.5105999881388139],
					[59.23907480076425, 2.5106000111176656],
					[59.239074801287515, 2.5106000215661686],
					[59.239224801035469, 2.5119472223929131],
					[59.23922480227651, 2.5119472291884506],
					[59.239224804758528, 2.5119472427795189],
					[59.239224805912357, 2.5119472478336342],
					[59.239733105672506, 2.5139889483699029],
					[59.239733105759683, 2.513988950111365],
					[59.239733106739102, 2.5139889516827059],
					[59.23973310682625, 2.5139889534241684],
					[59.240819207025837, 2.5178806537568792],
					[59.241666507131214, 2.5210778506893456],
					[59.241666508284688, 2.5210778557441498],
					[59.241666510417474, 2.5210778623705656],
					[59.241666511483892, 2.5210778656837869],
					[59.242147005641726, 2.5224667512964873],
					[59.242366501655042, 2.5236278266059071],
					[59.242563699982071, 2.5258556072107572],
					[59.242524799820522, 2.5283693963076428],
					[59.242524799907478, 2.5283693980492981],
					[59.24252479999447, 2.5283693997909475],
					[59.242524800081441, 2.5283694015326001],
					[59.242533099989423, 2.5303694008990476],
					[59.242533101403382, 2.5303694111792945],
					[59.24253310342602, 2.5303694336511713],
					[59.242533104753036, 2.5303694421897616],
					[59.242799805396864, 2.5315750433880893],
					[59.242799807789964, 2.5315750552404541],
					[59.242799816145244, 2.5315750782664352],
					[59.242799821215151, 2.531575089609726],
					[59.243327620969971, 2.532638988774913],
					[59.243327638421661, 2.5326390135882457],
					[59.243327678331021, 2.5326390552300531],
					[59.243327701767882, 2.5326390736305822],
					[59.243972102179079, 2.533002974592923],
					[59.243972124202124, 2.5330029827132083],
					[59.243972169945849, 2.5330029968727219],
					[59.243972194384952, 2.5330029992587768],
					[59.245044393805564, 2.5330334996093655],
					[59.245044418070833, 2.5330334985118936],
					[59.245044462597718, 2.5330334882865864],
					[59.245044484730784, 2.5330334805612473],
					[59.245855685345781, 2.5326501814722473],
					[59.245855705433492, 2.5326501688602852],
					[59.245855740886768, 2.5326501392592835],
					[59.245855757231524, 2.5326501238423753],
					[59.246447357348103, 2.5318973242042717],
					[59.246447360656418, 2.5318973182998263],
					[59.246447368252205, 2.5318973080631109],
					[59.246447371560492, 2.5318973021586655],
					[59.247230767576227, 2.5305834078936242],
					[59.248461265291645, 2.5288001131513931],
					[59.248461266096932, 2.5288001112396334],
					[59.248461268599776, 2.5288001072463109],
					[59.248461270297355, 2.5288001051647302],
					[59.249789062196783, 2.5266418186120365],
					[59.25097512179736, 2.5252751651286127],
					[59.251833359731918, 2.5248918920239456],
					[59.252905590613651, 2.5246891059008618],
					[59.254336037335321, 2.5250890897118019],
					[59.255958211065078, 2.5257168797903655],
					[59.257152650692674, 2.5264445423512591],
					[59.257944224974246, 2.5278778975976519],
					[59.257944230849837, 2.5278779070329369],
					[59.257944245277763, 2.5278779253938399],
					[59.257944253024881, 2.5278779362317798],
					[59.258877652574924, 2.5288890357792826],
					[59.258877656317985, 2.52888903858477],
					[59.258877662998763, 2.5288890461081284],
					[59.258877666741803, 2.5288890489136162],
					[59.260263767073901, 2.53012514969069],
					[59.260263774559924, 2.5301251553021538],
					[59.260263792295753, 2.5301251677581353],
					[59.260263800674053, 2.5301251731997678],
					[59.261386000656742, 2.5307612733886118],
					[59.26138602642294, 2.5307612843167453],
					[59.261386075736006, 2.5307612977995491],
					[59.26138610195968, 2.5307612998447153],
					[59.262530590338969, 2.5307474004782295],
					[59.263652721757694, 2.5308695933960426],
					[59.264774832792241, 2.5317390228903158],
					[59.265544207293495, 2.5337778558126569],
					[59.266174804286138, 2.5368417403151531],
					[59.266174806591991, 2.5368417504330738],
					[59.266174812988289, 2.5368417703294845],
					[59.266174817078721, 2.536841780107979],
					[59.267185911924592, 2.5391556698196336],
					[59.267810909329953, 2.541150060381081],
					[59.267810919381603, 2.541150081343841],
					[59.26781094207491, 2.5411501210175116],
					[59.267810955608894, 2.5411501395588223],
					[59.268433155900986, 2.5417918383727751],
					[59.268433192262989, 2.5417918631328709],
					[59.268433271170288, 2.5417918921229821],
					[59.268433313889211, 2.5417918998390783],
					[59.269266713869698, 2.5417335002282306],
					[59.269266734392318, 2.5417334963273048],
					[59.269266772587052, 2.541733485548145],
					[59.269266791956909, 2.5417334765876216],
					[59.269908313037988, 2.5414002198132564],
					[59.270505429617891, 2.5416251499999865],
					[59.270724798683283, 2.5425528195801568],
					[59.270658101236911, 2.5442305716424518],
					[59.270116506696588, 2.5463777447717],
					[59.269291509092305, 2.5489971391338471],
					[59.269291506762315, 2.5489971466144641],
					[59.269291503886905, 2.5489971612368731],
					[59.269291501556893, 2.5489971687174782],
					[59.26873310200061, 2.5525443685186917],
					[59.268733102087289, 2.5525443702617672],
					[59.268733102087289, 2.5525443702617672],
					[59.268499801812744, 2.5541277705066592],
					[59.268499800721344, 2.5541277847897601],
					[59.268499801215519, 2.5541278128481451],
					[59.268499801995418, 2.5541278285357705],
					[59.268597001896069, 2.5548250286260266],
					[59.268597011399862, 2.5548250567349959],
					[59.268597036653617, 2.5548251117681446],
					[59.268597054101555, 2.5548251366107122],
					[59.268813754045553, 2.5550557369842721],
					[59.268813826704239, 2.5550557671904031],
					[59.268813971839286, 2.5550557695730096],
					[59.268814045034702, 2.555055738094052],
					[59.269216845019521, 2.5546307381193927],
					[59.269216854315033, 2.5546307257988046],
					[59.269216871208101, 2.5546307032392654],
					[59.269216877740021, 2.5546306896833779],
					[59.270069571353261, 2.5529445041187051],
					[59.270947355031289, 2.5517223267461944],
					[59.272227946061513, 2.5503557365307272],
					[59.272227951960794, 2.5503557283719367],
					[59.272227963846056, 2.5503557137976176],
					[59.272227969745337, 2.5503557056388293],
					[59.272986262353001, 2.5491362183536981],
					[59.274222327095458, 2.5476751588812734],
					[59.274863889994748, 2.54734193067145],
					[59.275366539864642, 2.5476612349391119],
					[59.275774815653755, 2.5486333772464977],
					[59.275774818764333, 2.5486333854552004],
					[59.275774826683346, 2.5486333997902513],
					[59.275774830686224, 2.5486334078294881],
					[59.27618043103972, 2.5492779073330478],
					[59.276180438785211, 2.5492779181815335],
					[59.276180457845385, 2.5492779392006648],
					[59.276180468354525, 2.5492779512842265],
					[59.276708168121083, 2.5497390511508473],
					[59.276708191382546, 2.5497390660928994],
					[59.276708240235472, 2.5497390884947895],
					[59.276708265913669, 2.5497390976980792],
					[59.27720826572078, 2.549825196726232],
					[59.277208312380601, 2.5498251931454634],
					[59.277208397607865, 2.5498251681610342],
					[59.277208437873227, 2.5498251446749753],
					[59.277850138396161, 2.5492112452140381],
					[59.277850152784687, 2.5492112266413147],
					[59.277850177100255, 2.5492111903432177],
					[59.277850186940547, 2.5492111708743055],
					[59.278416886588964, 2.5477111709993472],
					[59.278416889005605, 2.5477111652601487],
					[59.278416892946566, 2.5477111539512753],
					[59.278416893578616, 2.547711148551095],
					[59.279122393858607, 2.5448611484943515],
					[59.279122395296028, 2.5448611411808488],
					[59.279122398257677, 2.544861128297434],
					[59.279122398889598, 2.544861122897097],
					[59.279491897265146, 2.5415972352902352],
					[59.279939092521076, 2.5397278543910793],
					[59.280339090142292, 2.538511161749796],
					[59.280339091666228, 2.5385111561793972],
					[59.280339094540352, 2.5385111415513837],
					[59.280339096956553, 2.5385111358112251],
					[59.280525197379212, 2.5374861366488122],
					[59.280525197923936, 2.5374861295045101],
					[59.28052519901339, 2.5374861152159047],
					[59.280525199645012, 2.5374861098152413],
					[59.280591899687494, 2.5360861097964862],
					[59.280591900058347, 2.5360860991649057],
					[59.280591899015441, 2.5360860782414107],
					[59.280591897688602, 2.5360860696931189],
					[59.280347398376676, 2.5345055694113277],
					[59.280347396244416, 2.5345055627766517],
					[59.280347392785245, 2.5345055475937883],
					[59.280347390566035, 2.5345055392154929],
					[59.279914091159853, 2.5331166403653671],
					[59.279914084044471, 2.5331166241189007],
					[59.279914068116149, 2.5331165937093125],
					[59.279914059303188, 2.5331165795462081],
					[59.279244562209477, 2.5322387825071999],
					[59.278427974894754, 2.531038801649641],
					[59.277780788051032, 2.5296055291901896],
					[59.277200191832797, 2.5276582428070857],
					[59.277200190853534, 2.5276582412336741],
					[59.2772001907665, 2.5276582394902571],
					[59.276672390898447, 2.5259888406943114],
					[59.276672387786483, 2.5259888324875783],
					[59.276672381649547, 2.525988817817471],
					[59.276672378624625, 2.5259888113541069],
					[59.276141878668696, 2.5249249111685952],
					[59.276141876623029, 2.5249249062787076],
					[59.276141872705765, 2.5249248999856366],
					[59.276141870747153, 2.524924896839106],
					[59.275472370746186, 2.5238165978227189],
					[59.275472365762504, 2.5238165882133785],
					[59.275472354184757, 2.5238165728215587],
					[59.275472348395908, 2.5238165651256455],
					[59.274633479798212, 2.5228970993847013],
					[59.274319599833838, 2.5217832732352976],
					[59.274339100301852, 2.5202000244900344],
					[59.274689094628044, 2.5186583436221195],
					[59.274689094628044, 2.5186583436221195],
					[59.274689096238248, 2.5186583397951123],
					[59.274689096151128, 2.5186583380518335],
					[59.274947396008059, 2.5173056380622625],
					[59.274947397987532, 2.5173056236052367],
					[59.274947399269792, 2.5173055952020449],
					[59.274947398572564, 2.5173055812558798],
					[59.274847399043082, 2.5162805803496022],
					[59.274847396648418, 2.5162805684873679],
					[59.274847391859105, 2.5162805447629308],
					[59.274847387592807, 2.5162805314980532],
					[59.274511288309945, 2.515355532600017],
					[59.27451127629535, 2.5153555084953467],
					[59.274511246282046, 2.5153554667080473],
					[59.274511229088368, 2.5153554471118347],
					[59.274127928587411, 2.5150331473301617],
					[59.274127897922909, 2.5150331285461696],
					[59.274127831937967, 2.5150331059459599],
					[59.274127795638066, 2.5150331005568733],
					[59.273674996172005, 2.5150415006627225],
					[59.273674949057643, 2.5150415131767767],
					[59.273674864971895, 2.5150415609063566],
					[59.273674829785001, 2.5150415957811818],
					[59.273297055792639, 2.5156526523580061],
					[59.272891681067904, 2.5158886804107015],
					[59.272414055254636, 2.5157109445307571],
					[59.272102988640711, 2.5148777333263106],
					[59.271572389698477, 2.5132555375260655],
					[59.271572387652377, 2.5132555326375363],
					[59.271572385431874, 2.5132555242628403],
					[59.271572383385788, 2.5132555193743253],
					[59.271019593099552, 2.5120055402498842],
					[59.270847400246971, 2.5110305837253466],
					[59.270866900193639, 2.5098194239391227],
					[59.271147394646682, 2.5085583438726125],
					[59.27114739616934, 2.5085583383025063],
					[59.271147398322398, 2.5085583273327741],
					[59.271147399040096, 2.5085583236761826],
					[59.271264099305824, 2.5075333222804947],
					[59.271264098694864, 2.5075333100791362],
					[59.271264099257365, 2.5075332853354269],
					[59.27126409775417, 2.5075332733045954],
					[59.271116898386005, 2.5064638721297574],
					[59.271116893856842, 2.506463853638484],
					[59.271116882121852, 2.5064638171675857],
					[59.271116874111094, 2.5064638011015368],
					[59.270589074360394, 2.5055388006737891],
					[59.27058906759099, 2.5055387914096681],
					[59.270589054052145, 2.5055387728814105],
					[59.270589047282741, 2.5055387636172894],
					[59.269869546780498, 2.5047581641330092],
					[59.269869541077902, 2.5047581581846039],
					[59.269869526996011, 2.5047581467994964],
					[59.269869520313883, 2.504758139278684],
					[59.268841837944905, 2.5039831522528422],
					[59.267691869469019, 2.5026442904545019],
					[59.266822380152547, 2.5008305136208895],
					[59.2668223732081, 2.5008305008730964],
					[59.266822357622132, 2.5008304774616539],
					[59.266822349872839, 2.500830466627312],
					[59.264455749914958, 2.4981609669482796],
					[59.264455732981141, 2.4981609525944219],
					[59.264455698396077, 2.4981609275427212],
					[59.264455678873055, 2.498160915443663],
					[59.263630726199487, 2.4978054375247751],
					[59.263177978228974, 2.4972277028102181],
					[59.262875188734277, 2.4963555341587225],
					[59.262875168966787, 2.4963554992368864],
					[59.262875116573206, 2.4963554424099441],
					[59.262875084751933, 2.4963554185915],
					[59.26242508457625, 2.4961442189720549],
					[59.262425068971574, 2.4961442131620322],
					[59.262425036957445, 2.4961442034553079],
					[59.262425019743212, 2.4961442014718989],
					[59.261789027671782, 2.4960804121442273],
					[59.260041878299397, 2.4926860076850206],
					[59.260041816352576, 2.4926859579746199],
					[59.260041673845151, 2.4926859184112464],
					[59.260041594264202, 2.4926859301298703],
					[59.259344293512086, 2.4931192292362203],
					[59.259344256896625, 2.4931192714288324],
					[59.25934421139393, 2.4931193698561001],
					[59.259344201527021, 2.4931194245191466],
					[59.259574802272496, 2.4949417249985735],
					[59.259574804843879, 2.4949417403387311],
					[59.259574813642878, 2.4949417720783833],
					[59.259574819870508, 2.4949417884778988],
					[59.26014151515281, 2.4961028751202434],
					[59.261352607296146, 2.4996667538666242],
					[59.261924801004213, 2.5022917284885131],
					[59.262083099568315, 2.5045556135916551],
					[59.26208310196396, 2.5045556254485839],
					[59.262083105775702, 2.5045556475904203],
					[59.262083109063553, 2.5045556592768246],
					[59.262610909222744, 2.5062472602313588],
					[59.262610924264663, 2.5062472907834863],
					[59.262610963813849, 2.5062473430419612],
					[59.262610988408376, 2.5062473664909155],
					[59.26382201825308, 2.5070612181476806],
					[59.264010900806142, 2.5081500222005597],
					[59.26404700009455, 2.5088028026090501],
					[59.264016500957382, 2.5098805761513985],
					[59.263869204028516, 2.5105693578074026],
					[59.263869204115757, 2.510569359550082],
					[59.263869204115757, 2.510569359550082],
					[59.263722014203658, 2.5112916084537833],
					[59.263308260962724, 2.5117747372947119],
					[59.262069527999664, 2.5109887458176585],
					[59.2589890628199, 2.5077081813006998],
					[59.257983481316998, 2.5059082127959034],
					[59.25755018523455, 2.5048443246875132],
					[59.257550178203218, 2.504844310199883],
					[59.257550161551222, 2.5048442834784508],
					[59.257550152822745, 2.5048442710741412],
					[59.257047360193418, 2.5042470791582749],
					[59.256614083279501, 2.5035999141683365],
					[59.256252999049075, 2.5023971685738484],
					[59.256225201254772, 2.5013278196977833],
					[59.256483494290563, 2.5002056493371327],
					[59.25685849222797, 2.498897254923639],
					[59.2568584953599, 2.4988972455296521],
					[59.256858497967507, 2.4988972256819655],
					[59.256858499227654, 2.4988972148869752],
					[59.257016899066471, 2.4968000146032763],
					[59.257016899609027, 2.4968000074634369],
					[59.257016899714543, 2.4967999916121677],
					[59.257016899277467, 2.4967999829007423],
					[59.25679459940222, 2.4941971822000695],
					[59.256794599227312, 2.4941971787155492],
					[59.256794596830751, 2.4941971668612175],
					[59.256794595676212, 2.4941971618051961],
					[59.256330796163475, 2.4918305614661209],
					[59.256330795008857, 2.491830556410239],
					[59.256330792787061, 2.4918305480407197],
					[59.256330791632422, 2.4918305429848542],
					[59.25594459182652, 2.4905332432771865],
					[59.255944590759327, 2.4905332399636291],
					[59.255944588712438, 2.4905332350787051],
					[59.255944587732749, 2.4905332335073553],
					[59.255464093058698, 2.489191644655079],
					[59.255147398770006, 2.487705576302683],
					[59.255091899700645, 2.4855667026401331],
					[59.255222398638615, 2.4826333163004755],
					[59.25566129776027, 2.4788083270637884],
					[59.256172396787697, 2.4755917363497302],
					[59.256639094102951, 2.4733028515613302],
					[59.257250189855952, 2.4714306626549916],
					[59.257250192269218, 2.4714306569142677],
					[59.257250193614787, 2.4714306478605015],
					[59.257250195135839, 2.4714306422911627],
					[59.257508494580144, 2.4702639424875339],
					[59.257508496188919, 2.4702639386603167],
					[59.257508498251049, 2.4702639259506127],
					[59.257508498879893, 2.4702639205525583],
					[59.25762239866355, 2.469144420536562],
					[59.257622398941223, 2.4691444081695879],
					[59.257622398779986, 2.4691443870915331],
					[59.257622399145426, 2.4691443764667937],
					[59.257500199370945, 2.4681221759006657],
					[59.257500192788825, 2.4681221525384434],
					[59.257500177123731, 2.4681221098127359],
					[59.257500166168619, 2.4681220890499538],
					[59.256852965648584, 2.4671554888220837],
					[59.256852949340406, 2.467155469088826],
					[59.256852908870215, 2.4671554346499276],
					[59.256852887384689, 2.4671554194298579],
					[59.256016787212587, 2.4667498205830016],
					[59.256016776155441, 2.4667498156721943],
					[59.256016754128993, 2.4667498075927186],
					[59.256016742267519, 2.4667498045955103],
					[59.25420284164678, 2.4663609037573577],
					[59.254202780716724, 2.4663609101914767],
					[59.254202673190925, 2.4663609572418035],
					[59.254202626683039, 2.4663609996000697],
					[59.253847027185955, 2.4669692981681908],
					[59.253847018338107, 2.466969319216417],
					[59.253847005103246, 2.4669693604555807],
					[59.253847000804015, 2.466969382388545],
					[59.253733103290998, 2.4682749565869004],
					[59.253427645896657, 2.4691165392232541],
					[59.25280824417149, 2.4693137073469735],
					[59.252072148335067, 2.4695109062755831],
					[59.252072113175217, 2.4695109235822907],
					[59.252072051587184, 2.469510970588817],
					[59.252072026051152, 2.4695110001172624],
					[59.251455425576111, 2.4705916003653083],
					[59.251455423250654, 2.4705916078469863],
					[59.251455415835494, 2.4705916215824524],
					[59.251455412530099, 2.4705916274935715],
					[59.2509137204271, 2.471994311928758],
					[59.249847032026885, 2.4737804907913805],
					[59.248994249231451, 2.4750026673728804],
					[59.247902683916138, 2.4759970346676581],
					[59.246902720617527, 2.4765248165026548],
					[59.245333235811067, 2.4770581104547804],
					[59.245333226976975, 2.4770581139077357],
					[59.245333209396435, 2.4770581225552712],
					[59.245333199757809, 2.477058127920996],
					[59.243835999540785, 2.477919226643468],
					[59.243835989097612, 2.4779192339213338],
					[59.243835969191082, 2.4779192500474871],
					[59.243835958747894, 2.4779192573253388],
					[59.243313758938285, 2.4784387572082585],
					[59.243313742146441, 2.4784387815282067],
					[59.243313714895692, 2.4784388307120433],
					[59.243313704524411, 2.4784388573174367],
					[59.243172005215641, 2.4790943584622402],
					[59.243172003064927, 2.479094369424542],
					[59.243171999830963, 2.4790943946611144],
					[59.243171999552231, 2.479094407022798],
					[59.243199799748545, 2.4798833068653607],
					[59.243199802409173, 2.4798833239384863],
					[59.24319980960243, 2.4798833594841563],
					[59.243199814939601, 2.479883376044127],
					[59.243463715201386, 2.4805306766185966],
					[59.24346373087819, 2.4805307017467015],
					[59.243463771153671, 2.4805307502925977],
					[59.243463794684885, 2.4805307703983557],
					[59.244205494562898, 2.4809862698099243],
					[59.244205512127678, 2.4809862787515011],
					[59.244205545210036, 2.4809862917519836],
					[59.244205563404158, 2.4809862952978188],
					[59.245111063298189, 2.4811584957461279],
					[59.245111064277964, 2.4811584973167271],
					[59.245111066062357, 2.4811584969746696],
					[59.245111066954543, 2.4811584968036473],
					[59.246136015010521, 2.4813279890613194],
					[59.247260961375815, 2.4822418445037018],
					[59.248769219819721, 2.4839389982313702],
					[59.249324801796575, 2.4858861353222621],
					[59.24957480108305, 2.488813916849677],
					[59.249574801345581, 2.4888139220753183],
					[59.249574802937886, 2.4888139358394747],
					[59.249574804092617, 2.488813940894258],
					[59.250010902964959, 2.4909000361010718],
					[59.250255400338524, 2.4924806143825933],
					[59.250238700080189, 2.4943888909221088],
					[59.250080400904579, 2.4964360729082942],
					[59.249427603842783, 2.499797162795788],
					[59.24942760303793, 2.4997971647082635],
					[59.249427602407806, 2.4997971701045629],
					[59.249427601690329, 2.4997971737589388],
					[59.2491331088221, 2.5019777187210197],
					[59.248447044930671, 2.502824873139609],
					[59.248447037424604, 2.5028248851251571],
					[59.24844702330472, 2.5028249089257719],
					[59.248447015798675, 2.5028249209113178],
					[59.247910924678585, 2.5040721018730268],
					[59.247347038412585, 2.5048526830293683],
					[59.247347033321105, 2.5048526892773681],
					[59.247347025009944, 2.5048527031743073],
					[59.247347020810693, 2.5048527092518831],
					[59.247016551547773, 2.5055081485303701],
					[59.246613936236187, 2.5056997626320632],
					[59.246064073059223, 2.505197087745989],
					[59.245677989740422, 2.5040388378504077],
					[59.245677988673584, 2.5040388345373814],
					[59.245677986627214, 2.504038829653064],
					[59.245677986452591, 2.5040388261695918],
					[59.244980785843616, 2.5022805258320617],
					[59.244980781838088, 2.5022805178055032],
					[59.244980773827031, 2.5022805017523995],
					[59.244980769821495, 2.5022804937258445],
					[59.243802976330329, 2.5003916047467349],
					[59.243227990242843, 2.4991443353229394],
					[59.242911300783469, 2.4977999807741824],
					[59.243000199230664, 2.4957500224495535],
					[59.243327993894248, 2.4940250534649024],
					[59.243916887858965, 2.4923889681227398],
					[59.243916890990477, 2.4923889587318362],
					[59.243916896186384, 2.4923889366375551],
					[59.243916898425702, 2.4923889274173758],
					[59.244125198417002, 2.4908500270884337],
					[59.244125199046763, 2.4908500216928404],
					[59.244125200131307, 2.4908500074184672],
					[59.244125199868868, 2.4908500021936395],
					[59.244141900059425, 2.4888944013422041],
					[59.24414189962188, 2.4888943926342022],
					[59.244141897854611, 2.4888943753890249],
					[59.24414189661239, 2.4888943685934337],
					[59.243822396690526, 2.4869443672039986],
					[59.243822395360674, 2.4869443586669724],
					[59.243822389849292, 2.4869443386223593],
					[59.243822386735047, 2.4869443304270828],
					[59.243150187061779, 2.4851416291322974],
					[59.243150180028429, 2.4851416146548324],
					[59.243150164177358, 2.4851415860416846],
					[59.243150153575264, 2.48514157224785],
					[59.242694554413816, 2.4845887730490812],
					[59.242694518846868, 2.4845887464553065],
					[59.242694440383794, 2.4845887087381007],
					[59.242694396683035, 2.4845886995271278],
					[59.242169396595926, 2.4845970000299715],
					[59.242169368850455, 2.4845970035868756],
					[59.242169317453673, 2.4845970204659285],
					[59.242169291930395, 2.4845970323884141],
					[59.24157499315551, 2.4849803661825529],
					[59.241002946873316, 2.4846609529277526],
					[59.240569599543136, 2.4838777476718397],
					[59.240564100185878, 2.4826222100113564],
					[59.240841898367712, 2.4800139334431965],
					[59.241614091011293, 2.4767917587130377],
					[59.242275176290057, 2.4750583994662869],
					[59.243364055347804, 2.4736473254434754],
					[59.244339051060038, 2.4725168309611125],
					[59.244339061239707, 2.4725168184568402],
					[59.244339077854889, 2.4725167906501677],
					[59.244339085182595, 2.472516775176532],
					[59.24497518462907, 2.4709694761029293],
					[59.244975187846762, 2.4709694684514361],
					[59.244975193389919, 2.4709694533197659],
					[59.244975194735538, 2.4709694442692767],
					[59.245350194671026, 2.4693361453071891],
					[59.245350196908653, 2.4693361360852131],
					[59.245350198707456, 2.4693361181552751],
					[59.245350200052918, 2.4693361091046322],
					[59.245436299779314, 2.4674750098749128],
					[59.245436300056895, 2.4674749975123884],
					[59.245436298111208, 2.4674749767846715],
					[59.245436296604467, 2.4674749647649055],
					[59.245169596759354, 2.4659888648791162],
					[59.245169594448171, 2.4659888547725202],
					[59.245169588933614, 2.465988834730739],
					[59.245169584750279, 2.4659888232254197],
					[59.244639084686483, 2.4646943237412784],
					[59.244639077650902, 2.4646943092675815],
					[59.244639060991155, 2.4646942825760583],
					[59.24463905225911, 2.4646942701867895],
					[59.24396955239343, 2.4639137703838925],
					[59.243969513166164, 2.4639137427554538],
					[59.243969426756365, 2.4639137083698222],
					[59.243969380465991, 2.4639137014411481],
					[59.242872180227316, 2.4640248013165515],
					[59.24287215176625, 2.4640248085446621],
					[59.242872097871889, 2.4640248294522848],
					[59.242872074310782, 2.464024844530337],
					[59.241827673789963, 2.4648776443669673],
					[59.241827666916187, 2.4648776509626011],
					[59.241827654060828, 2.4648776639824188],
					[59.241827648079223, 2.4648776704066209],
					[59.240902647836819, 2.465963770639326],
					[59.24090263935431, 2.4659637810600405],
					[59.240902624437027, 2.4659638067827654],
					[59.240902616934463, 2.4659638187734427],
					[59.240358116729581, 2.4671805173846426],
					[59.240358112883335, 2.4671805304307561],
					[59.240358105995234, 2.4671805546102901],
					[59.240358103041146, 2.4671805674850433],
					[59.239847003347926, 2.470258265156926],
					[59.239124804628695, 2.4742693537783755],
					[59.238277614032349, 2.4771221234962706],
					[59.237591546577427, 2.4783887624830894],
					[59.236902837836681, 2.4788636309732186],
					[59.236169593099618, 2.4779721198207034],
					[59.236022400220506, 2.4765027935237733],
					[59.236080799938392, 2.4746333053053364],
					[59.236080799850697, 2.4746333035641839],
					[59.236080800392109, 2.4746332964284989],
					[59.236080800216719, 2.4746332929462111],
					[59.236025199635925, 2.4729360936702371],
					[59.236025196799019, 2.4729360731190226],
					[59.236025186664371, 2.472936032872465],
					[59.236025181238666, 2.4729360145759172],
					[59.235591881382199, 2.4720277141387785],
					[59.235591865879343, 2.4720276925024898],
					[59.235591833264756, 2.4720276530545036],
					[59.235591813476503, 2.472027635756421],
					[59.234886213122721, 2.4715415346754583],
					[59.234886212230549, 2.4715415348466663],
					[59.233991825770808, 2.47093314456691],
					[59.23316127612938, 2.470135991584999],
					[59.232514092423749, 2.468030544523113],
					[59.231652992790309, 2.4649305469042644],
					[59.231652976759456, 2.4649305148283736],
					[59.231652935147508, 2.4649304577841882],
					[59.231652908586433, 2.464930431246342],
					[59.231097308725495, 2.464569232202773],
					[59.231097235947878, 2.4645692180582341],
					[59.231097100340129, 2.4645692441054918],
					[59.231097036617854, 2.4645692844686478],
					[59.230424836764534, 2.4655109840138518],
					[59.230424829349772, 2.4655109977416121],
					[59.230424813803666, 2.4655110288500937],
					[59.230424808261041, 2.4655110439759849],
					[59.229858126114429, 2.4674359836104234],
					[59.22945003840789, 2.4677636131363694],
					[59.228847384351575, 2.4669416133876241],
					[59.228458495832307, 2.4654138595417705],
					[59.228214100403434, 2.4639277822058747],
					[59.228205800131953, 2.4619750084865824],
					[59.228458498437639, 2.4592750322695558],
					[59.228855789419583, 2.4575944689388298],
					[59.229589073689695, 2.4561417989107652],
					[59.230158469277683, 2.4552473066009224],
					[59.230158475082909, 2.4552472966958665],
					[59.230158486429602, 2.4552472716634264],
					[59.230158491166819, 2.4552472584484328],
					[59.230602991382831, 2.4538000582713626],
					[59.230602994423592, 2.4538000471400676],
					[59.230602997652774, 2.4538000219108178],
					[59.230602999713426, 2.4538000092103798],
					[59.230691900095131, 2.4520306095401727],
					[59.230691899567212, 2.4520305990954538],
					[59.230691898423352, 2.4520305764652326],
					[59.230691897003311, 2.4520305661921982],
					[59.230497396745427, 2.4509193663380757],
					[59.230497393189012, 2.4509193494454902],
					[59.230497381703493, 2.4509193182596869],
					[59.230497374666527, 2.4509193037947368],
					[59.230016875206822, 2.4500416041632178],
					[59.230016859700534, 2.4500415825405559],
					[59.230016822619071, 2.4500415439788554],
					[59.230016802024068, 2.4500415286088328],
					[59.229250102336259, 2.4495887282084934],
					[59.229250082985395, 2.4495887196301478],
					[59.229250043567582, 2.449588706126582],
					[59.229250022608504, 2.449588701373143],
					[59.228561122995266, 2.449508100741931],
					[59.228561091594699, 2.4495081032714623],
					[59.228561031822011, 2.4495081147780042],
					[59.228561002645804, 2.4495081256674207],
					[59.228130503083236, 2.4497470244080373],
					[59.228130476307363, 2.4497470471381639],
					[59.22813043033274, 2.4497470999277988],
					[59.228130412918247, 2.4497471296438658],
					[59.227730413341959, 2.4508221304783762],
					[59.227730410929581, 2.4508221362153599],
					[59.227730406996926, 2.4508221475176453],
					[59.227730406368806, 2.4508221529112468],
					[59.227333113676679, 2.4524582175134086],
					[59.226574862000383, 2.4535859463606262],
					[59.225861033170716, 2.4539692083632421],
					[59.225005570569373, 2.4541220016405236],
					[59.224122176246716, 2.4542276017567608],
					[59.224122161080352, 2.4542276046737248],
					[59.22412213181569, 2.4542276138169958],
					[59.224122117629427, 2.4542276183028262],
					[59.223266618246406, 2.4546137186672286],
					[59.223266594598364, 2.4546137320017118],
					[59.223266553987038, 2.4546137661717768],
					[59.223266535151488, 2.4546137856100683],
					[59.222649834606514, 2.4555081859291938],
					[59.222649831389688, 2.455508193576927],
					[59.222649823083806, 2.4555082074750763],
					[59.222649818974865, 2.4555082152943459],
					[59.222083118881699, 2.4567249165901801],
					[59.222083113340055, 2.4567249317136537],
					[59.222083104041019, 2.4567249616175748],
					[59.22208310117577, 2.4567249762265169],
					[59.221691501814647, 2.4601721758431498],
					[59.221369202256277, 2.4624555676284174],
					[59.220994202725713, 2.4645527642322564],
					[59.220994202096975, 2.4645527696245719],
					[59.220994200927336, 2.4645527821495152],
					[59.220994200298612, 2.4645527875418107],
					[59.220860899732365, 2.4666471871364379],
					[59.220860899820138, 2.4666471888767765],
					[59.220860899820138, 2.4666471888767765],
					[59.220860899907933, 2.4666471906171097],
					[59.220641500079736, 2.470883289558333],
					[59.220641500167474, 2.4708832912986707],
					[59.220641499626204, 2.4708832984311679],
					[59.220641499801658, 2.4708833019118415],
				],
				[
					[59.151208100428519, 2.3759305987113559],
					[59.151208101051047, 2.3759306108667295],
					[59.151208104169072, 2.375930636567718],
					[59.151208105683615, 2.3759306485499616],
					[59.15134420592679, 2.3764833475372211],
					[59.1513442070856, 2.3764833525735676],
					[59.151344210295179, 2.3764833624731705],
					[59.151344212345961, 2.3764833673364252],
					[59.151758111946826, 2.3776389667167499],
					[59.151758115067409, 2.3776389748800724],
					[59.151758122111687, 2.3776389892970924],
					[59.151758126124271, 2.3776389972873306],
					[59.151977626195723, 2.3780306984835677],
					[59.151977633954168, 2.3780307092546336],
					[59.151977652324874, 2.3780307337505957],
					[59.151977661867335, 2.3780307441755038],
					[59.153233162179923, 2.3792251442819623],
					[59.153233190184814, 2.3792251634026234],
					[59.153233251994244, 2.3792251917495477],
					[59.153233284817865, 2.3792251994122759],
					[59.154063884876592, 2.3792890990353142],
					[59.154063920734927, 2.3792890955861545],
					[59.154063989956427, 2.3792890751408615],
					[59.154064023319599, 2.3792890581447237],
					[59.15524172725236, 2.3783696322896009],
					[59.1563137381223, 2.3788584458010611],
					[59.157058108042236, 2.3810944562129199],
					[59.157841507266319, 2.3840778513231502],
					[59.157841510653036, 2.3840778646990501],
					[59.157841520905706, 2.3840778890221306],
					[59.157841526968497, 2.3840779018791443],
					[59.15927762675976, 2.3865279005549667],
					[59.159277634606354, 2.3865279130671277],
					[59.159277652886864, 2.3865279358358107],
					[59.159277662339917, 2.3865279445282992],
					[59.160669262147138, 2.3878501455378811],
					[59.160669263039154, 2.387850145364999],
					[59.16066926393119, 2.387850145192115],
					[59.160669264019951, 2.3878501469291185],
					[59.163299853565675, 2.3902779364329105],
					[59.165474817202906, 2.39295289269044],
					[59.167388700640331, 2.4005556292005341],
					[59.167738700108465, 2.4076944084182075],
					[59.168105399655332, 2.4162028097832864],
					[59.168105399743752, 2.4162028115207606],
					[59.168105400901048, 2.4162028165610159],
					[59.168105400989461, 2.4162028182984918],
					[59.16865810120575, 2.422236118158072],
					[59.168658102804493, 2.4222361318862782],
					[59.168658109570316, 2.4222361586544947],
					[59.168658113933631, 2.4222361736040847],
					[59.170499813884447, 2.4269528735650359],
					[59.170499815933461, 2.4269528784340784],
					[59.170499821992181, 2.4269528913036167],
					[59.170499825021565, 2.4269528977383596],
					[59.172702624932192, 2.4309500962195436],
					[59.172702626000735, 2.4309500995232241],
					[59.172702628941693, 2.4309501042209827],
					[59.17270263090235, 2.4309501073528352],
					[59.174252630783855, 2.4334084066254844],
					[59.174252652801499, 2.4334084322178127],
					[59.174252706209089, 2.4334084728235394],
					[59.174252735814811, 2.433408488180496],
					[59.177216636176368, 2.4344140881066751],
					[59.177216651783098, 2.4344140938763887],
					[59.177216686300511, 2.4344140995146688],
					[59.177216703426936, 2.4344140997267876],
					[59.180552744730697, 2.4343585013429148],
					[59.183399884253063, 2.436105763208372],
					[59.184366568216518, 2.4368890508240879],
					[59.185333115189692, 2.4378083993833495],
					[59.185747001839729, 2.4400639263772996],
					[59.18611369984108, 2.4440667082333225],
					[59.186069200316432, 2.4487138856821344],
					[59.185699802168152, 2.4515221742756199],
					[59.185699800559782, 2.4515221780953986],
					[59.185699800911607, 2.4515221850493902],
					[59.18569980019538, 2.4515221886977714],
					[59.185577600381919, 2.4536499888691261],
					[59.185577599929417, 2.4536499977329562],
					[59.185577600720748, 2.4536500133794283],
					[59.185577601160382, 2.4536500220719177],
					[59.185880400820992, 2.4564250217068446],
					[59.185880407404333, 2.4564250450185416],
					[59.18588042413959, 2.4564250909568832],
					[59.185880435183655, 2.4564251134122537],
					[59.186805435198245, 2.457780712354289],
					[59.186805453379314, 2.4577807334407615],
					[59.186805492870654, 2.4577807662359055],
					[59.186805516141007, 2.457780781079228],
					[59.189522090044122, 2.4590390693526865],
					[59.191305433684519, 2.4605834192626412],
					[59.192608115043576, 2.4637083763318248],
					[59.192608117003509, 2.4637083794673451],
					[59.192608120031181, 2.463708385909535],
					[59.192608121098921, 2.4637083892161873],
					[59.194558120780776, 2.4676167882057096],
					[59.194558127727923, 2.4676168009204678],
					[59.194558144210959, 2.467616824097902],
					[59.194558151962525, 2.467616834902628],
					[59.195985951562761, 2.4691834346756849],
					[59.195985954326979, 2.4691834359017517],
					[59.195985957266679, 2.4691834406059852],
					[59.195985959138739, 2.4691834420030516],
					[59.196952659287604, 2.470147341091498],
					[59.196952675331659, 2.4701473555760654],
					[59.196952710725313, 2.470147378643849],
					[59.196952730074891, 2.470147387227065],
					[59.198055468801066, 2.4705612646900437],
					[59.19888312637012, 2.4718056994750524],
					[59.199897019473589, 2.4739222856210508],
					[59.200349807954574, 2.4749111604958922],
					[59.20163590427385, 2.4816556370284077],
					[59.201635913879748, 2.4816556668013456],
					[59.201635944602472, 2.4816557223884721],
					[59.20163596384733, 2.4816557468046967],
					[59.204866563724245, 2.4846473462323124],
					[59.20486656470397, 2.4846473478012352],
					[59.204866566575902, 2.48464734919949],
					[59.204866566663419, 2.4846473509390754],
					[59.209552649002134, 2.4888029330455206],
					[59.21340813507058, 2.4944362131329276],
					[59.213408170897544, 2.4944362449364803],
					[59.213408255060621, 2.4944362885860802],
					[59.213408303396683, 2.4944363004321342],
					[59.215408302732115, 2.494408500453992],
					[59.215408357699594, 2.4944084829237192],
					[59.215408449109198, 2.4944084197794276],
					[59.215408484746526, 2.4944083760760529],
					[59.217144584865821, 2.4901528761000913],
					[59.217144590953851, 2.4901528538525168],
					[59.217144597776702, 2.4901528103809993],
					[59.217144600295931, 2.4901527888158399],
					[59.216964099649132, 2.486902789304259],
					[59.216964100453836, 2.4869027873933645],
					[59.216964099386608, 2.4869027840836395],
					[59.216964099299091, 2.4869027823434346],
					[59.216439100071504, 2.4807332951665475],
					[59.216822398783677, 2.4722694160328516],
					[59.217214092588335, 2.4688917694103467],
					[59.217702969406837, 2.4681251080795583],
					[59.217702975125412, 2.4681250964402137],
					[59.217702985582655, 2.4681250715925054],
					[59.217702991213486, 2.4681250582129808],
					[59.218716890622211, 2.4648111584997996],
					[59.218716892318731, 2.4648111564170341],
					[59.218716892947462, 2.4648111510250912],
					[59.218716893664016, 2.4648111473733811],
					[59.219222390887097, 2.4627861595886174],
					[59.219819586646913, 2.4612222703394417],
					[59.21981958986408, 2.4612222626928824],
					[59.219819594426305, 2.461222246002241],
					[59.219819596663484, 2.4612222367867922],
					[59.220097395142275, 2.4597500449062135],
					[59.220741889828233, 2.4573556636689435],
					[59.221658486193441, 2.4550444747164661],
					[59.221658490842763, 2.4550444597644878],
					[59.221658497377042, 2.4550444286348245],
					[59.221658500154156, 2.4550444122855897],
					[59.221844599886005, 2.4522083136583879],
					[59.221844599006211, 2.4522082962550185],
					[59.221844595550373, 2.4522082635318729],
					[59.2218445929743, 2.4522082482120786],
					[59.220914092831556, 2.4487749475171254],
					[59.220914092831556, 2.4487749475171254],
					[59.220914091763369, 2.4487749442082674],
					[59.220914091763369, 2.4487749442082674],
					[59.219186291549853, 2.4428999436971188],
					[59.21918629128556, 2.442899938476605],
					[59.219186288168707, 2.4428999302913001],
					[59.219186286208263, 2.4428999271546634],
					[59.217369586212456, 2.4382471272619433],
					[59.217369582115047, 2.4382471175094067],
					[59.217369573204444, 2.4382471016564193],
					[59.217369568214934, 2.4382470920758421],
					[59.215269567656925, 2.4349748923199024],
					[59.215269565696332, 2.4349748891840624],
					[59.215269561775088, 2.4349748829124183],
					[59.21526955981448, 2.4349748797765796],
					[59.214441866581367, 2.4338692887885887],
					[59.213658474612224, 2.4325360027148202],
					[59.21310847716687, 2.431477708532185],
					[59.21310847511792, 2.4314777036569275],
					[59.213108470216184, 2.4314776958183488],
					[59.213108467275127, 2.4314776911152158],
					[59.212508466890576, 2.4305581910961607],
					[59.212508466802298, 2.430558189356363],
					[59.212508464841591, 2.4305581862210479],
					[59.212508463949504, 2.430558186393168],
					[59.211450185068692, 2.4290388152911291],
					[59.211083502879241, 2.4276138858192224],
					[59.211175191842237, 2.4267833845567299],
					[59.212508435396231, 2.4252668497585801],
					[59.213336217212856, 2.4246668627391954],
					[59.213336235239986, 2.4246668452027822],
					[59.213336265853357, 2.4246668094238348],
					[59.213336279155037, 2.4246667875294099],
					[59.214580770325647, 2.4221362060948817],
					[59.215591827627271, 2.4208473593775315],
					[59.216650028619306, 2.4203419075983215],
					[59.218030511026818, 2.4205723879250991],
					[59.219594261804865, 2.4219529441647798],
					[59.221113756594001, 2.4235168397611129],
					[59.22111375757445, 2.4235168413289427],
					[59.221113761319494, 2.4235168441198871],
					[59.221113763192037, 2.423516845515346],
					[59.221849859762159, 2.4242084422882964],
					[59.222510957980852, 2.4248751408672238],
					[59.222510966451338, 2.4248751480175312],
					[59.222510984284419, 2.4248751621458067],
					[59.222510992754913, 2.4248751692961164],
					[59.223102664271252, 2.4252501503471131],
					[59.22408872993244, 2.4265084074975909],
					[59.224722008417899, 2.4278028614905378],
					[59.225099803330203, 2.4299833339234045],
					[59.225099812948073, 2.4299833637010373],
					[59.225099842800645, 2.4299834194491408],
					[59.225099863035382, 2.4299834454196261],
					[59.226608162654699, 2.4314001466367992],
					[59.226608194221654, 2.4314001651491433],
					[59.226608262266858, 2.4314001924380819],
					[59.226608297764749, 2.431400199646391],
					[59.228363787695507, 2.4314168987304901],
					[59.229358123301068, 2.4330250940965863],
					[59.229947010790539, 2.4343278663298333],
					[59.230280407066587, 2.4356000512799505],
					[59.230280408223308, 2.4356000563299673],
					[59.230280411340672, 2.4356000645171685],
					[59.230280413389522, 2.4356000693950515],
					[59.230683089772931, 2.4366778114548304],
					[59.229763707371042, 2.4401749493099243],
					[59.229763704419618, 2.4401749621827711],
					[59.229763701193093, 2.4401749874125058],
					[59.229763700025899, 2.4401749999413709],
					[59.229763699748133, 2.4435805996936173],
					[59.22976370276578, 2.4435806237197588],
					[59.229763713085468, 2.4435806674311005],
					[59.229763721367725, 2.4435806886851048],
					[59.230308120975018, 2.444683388192511],
					[59.230308129796882, 2.4446834023122945],
					[59.230308150205055, 2.4446834317769555],
					[59.230308161703292, 2.4446834453811057],
					[59.231272062194684, 2.4456057447832098],
					[59.231272078153424, 2.4456057575287282],
					[59.231272110698818, 2.445605777625552],
					[59.231272129157794, 2.4456057863739056],
					[59.232238828985217, 2.4459723868059742],
					[59.232238847268036, 2.4459723920730609],
					[59.2322388817852, 2.4459723977284074],
					[59.232238899891875, 2.4459723995138094],
					[59.233252800262953, 2.4459724000035252],
					[59.233252812840746, 2.445972399338324],
					[59.23325283969249, 2.4459723959233077],
					[59.233252853074347, 2.4459723933453472],
					[59.233758353277828, 2.4458334930550953],
					[59.233758392718741, 2.4458334713935308],
					[59.233758459034156, 2.4458334111545996],
					[59.23375848510463, 2.4458333744900242],
					[59.234264084413773, 2.4445917779710324],
					[59.234725183423414, 2.4435333796741747],
					[59.234725188963395, 2.4435333645429202],
					[59.234725197190777, 2.4435333313142706],
					[59.234725199074184, 2.4435333151298062],
					[59.234816898218973, 2.4423833330173017],
					[59.235091893981718, 2.4413250501958381],
					[59.235091894609447, 2.4413250448009114],
					[59.235091896668827, 2.4413250320981077],
					[59.235091898188664, 2.4413250265311985],
					[59.235322384220808, 2.439575134555247],
					[59.236288954293329, 2.439116895306324],
					[59.23725556884277, 2.4390252040538747],
					[59.238360975391259, 2.4394834646909946],
					[59.238866520339485, 2.440127894721658],
					[59.239280390298532, 2.4414638987019019],
					[59.23881920979008, 2.4428888380861098],
					[59.238819206122557, 2.4428888546165326],
					[59.238819201287626, 2.4428888836791125],
					[59.23881920029644, 2.4428888996936053],
					[59.238819199604414, 2.4445472003408821],
					[59.238819199794875, 2.4455582998318404],
					[59.238819201743986, 2.4455583205540652],
					[59.238819211083012, 2.4455583627082298],
					[59.238819218384855, 2.445558382398977],
					[59.239233117796623, 2.4464778816138981],
					[59.239233139184719, 2.4464779126563494],
					[59.239233191333938, 2.446477964144413],
					[59.239233222095038, 2.4464779845900444],
					[59.240430521953421, 2.4469834836819424],
					[59.240430558430951, 2.4469834924778855],
					[59.240430629701294, 2.4469834945702527],
					[59.240430666366422, 2.4469834892641775],
					[59.24199446570838, 2.4464334887706118],
					[59.241994477217908, 2.4464334847944227],
					[59.241994498276533, 2.4464334737031757],
					[59.241994507913731, 2.4464334683294751],
					[59.243283407656641, 2.4456057687390089],
					[59.243283413725315, 2.4456057640526105],
					[59.243283425862657, 2.4456057546797996],
					[59.243283431931332, 2.4456057499933834],
					[59.24549183190372, 2.4436723503002598],
					[59.245491838776296, 2.443672343699677],
					[59.245491852433396, 2.4436723287569602],
					[59.245491859305979, 2.4436723221563796],
					[59.247830758806124, 2.4406279213247895],
					[59.247830771306226, 2.4406279013270558],
					[59.247830789885334, 2.4406278590530457],
					[59.247830796856462, 2.4406278366046599],
					[59.248861296779992, 2.4350222351785811],
					[59.248861298299481, 2.4350222296091197],
					[59.248861299377829, 2.4350222153313719],
					[59.248861300005224, 2.4350222099341656],
					[59.249136299937916, 2.4294278104557736],
					[59.24913629896654, 2.4294277912976452],
					[59.249136294435829, 2.4294277552402486],
					[59.249136289896065, 2.4294277367717418],
					[59.247305789675671, 2.4238721368362963],
					[59.247305768997599, 2.4238721021458014],
					[59.247305716397953, 2.4238720419739113],
					[59.247305682780571, 2.4238720185791425],
					[59.244802883461247, 2.4227359182902246],
					[59.244802866961848, 2.4227359126890944],
					[59.24480283405147, 2.4227359032282507],
					[59.244802816836781, 2.4227359012824743],
					[59.242041738161099, 2.4225054027606361],
					[59.240844503803473, 2.4221359234471302],
					[59.239694567435272, 2.4210331834570176],
					[59.239286301374584, 2.4200999594650345],
					[59.23931130005424, 2.4185806031915029],
					[59.239311299700461, 2.4185805962270632],
					[59.239311299081351, 2.4185805840393071],
					[59.239311298727586, 2.4185805770748647],
					[59.238647399326815, 2.4128305760893674],
					[59.238647397100088, 2.4128305677296398],
					[59.238647393450186, 2.4128305490963338],
					[59.238647391223438, 2.4128305407365924],
					[59.236944601782874, 2.4074360763511899],
					[59.237097398263792, 2.405113934614338],
					[59.23765579010616, 2.4031111633692279],
					[59.238141887204641, 2.4018472714416181],
					[59.238680786126913, 2.4004639736865352],
					[59.238680787556277, 2.4004639663763117],
					[59.238680792465068, 2.4004639566327119],
					[59.238680793091056, 2.4004639512365773],
					[59.239141892897983, 2.3987111512854109],
					[59.239141895845165, 2.3987111384056514],
					[59.239141899152138, 2.3987111149065528],
					[59.239141900315239, 2.3987111023730674],
					[59.23918909964943, 2.3950750098837794],
					[59.239555799124958, 2.3904750165868891],
					[59.239555799839337, 2.3904750129314802],
					[59.239555799572884, 2.39047500770842],
					[59.239555800376088, 2.3904750057940269],
					[59.239647400041513, 2.3871611057356921],
					[59.239647399152865, 2.387161088325533],
					[59.239647393807523, 2.3871610541989883],
					[59.23964739024283, 2.3871610373091743],
					[59.23914189006878, 2.3856416378400604],
					[59.239141887929222, 2.3856416312230926],
					[59.239141883827841, 2.3856416214711054],
					[59.239141881777165, 2.3856416165951053],
					[59.238636287470804, 2.384538827706888],
					[59.238083489865041, 2.3828360383284632],
					[59.238083488706287, 2.3828360332792515],
					[59.238083485674572, 2.3828360268362014],
					[59.238083484604722, 2.3828360235279145],
					[59.237622406899611, 2.3817305766733448],
					[59.23826689718031, 2.377958335933585],
					[59.238958496332074, 2.3744611384802115],
					[59.238958497045999, 2.3744611348245619],
					[59.238958498473849, 2.3744611275132748],
					[59.23895849909875, 2.3744611221167067],
					[59.239511297704212, 2.3697667283635218],
					[59.240155795942798, 2.366086138360421],
					[59.241075195075126, 2.3618528415103759],
					[59.241075197305015, 2.3618528322830388],
					[59.241075198999681, 2.3618528126097083],
					[59.241075200337598, 2.3618528035564861],
					[59.241119599551503, 2.3593694033317436],
					[59.241119598748377, 2.3593693876628197],
					[59.241119594376961, 2.3593693551064949],
					[59.241119589916728, 2.3593693383932797],
					[59.240705790330388, 2.3580805382701682],
					[59.240705776236332, 2.3580805093717041],
					[59.240705736631192, 2.3580804573214356],
					[59.240705713885262, 2.3580804353879605],
					[59.239647314283353, 2.3573442354217931],
					[59.239647289307754, 2.3573442227166868],
					[59.239647236145736, 2.3573442049669779],
					[59.239647208851309, 2.3573441997481632],
					[59.238588934361097, 2.3572970014290742],
					[59.237530754719415, 2.3569748375641377],
					[59.237303003871688, 2.3563305786659501],
					[59.237577997138018, 2.3540778358941119],
					[59.237936292776148, 2.3526000543641845],
					[59.238552990987905, 2.3506500601272573],
					[59.238552991701148, 2.3506500564711987],
					[59.238552994732828, 2.3506500453286607],
					[59.238552996338043, 2.3506500414982239],
					[59.238902996404654, 2.3490028416205031],
					[59.238902996404654, 2.3490028416205031],
					[59.23890299622591, 2.3490028381388064],
					[59.238902996136531, 2.349002836397966],
					[59.239225195964288, 2.3473083368334802],
					[59.239225198103739, 2.347308325864919],
					[59.239225199617415, 2.3473083027103909],
					[59.239225199972978, 2.3473082920907924],
					[59.239153000108594, 2.345552791997652],
					[59.239152999393241, 2.3455527780708967],
					[59.239152993681664, 2.3455527545717261],
					[59.239152991271823, 2.3455527427348857],
					[59.238939090854601, 2.3448499419487723],
					[59.238939086571385, 2.3448499287203126],
					[59.238939076310544, 2.3448499043532922],
					[59.238939068549037, 2.3448498935638264],
					[59.238572369326256, 2.344266594449584],
					[59.238572366382122, 2.3442665897508173],
					[59.238572358620559, 2.3442665789615988],
					[59.23857235478448, 2.3442665744373916],
					[59.237955754639401, 2.3435081743998967],
					[59.237955752676619, 2.343508171267481],
					[59.237955748840527, 2.3435081667434505],
					[59.237955746877766, 2.3435081636110344],
					[59.237208449830845, 2.3427026670240214],
					[59.236694577609555, 2.3420943015769993],
					[59.236291890144685, 2.3408360387941416],
					[59.23629188496917, 2.3408360257418503],
					[59.23629187185287, 2.3408359984204621],
					[59.236291864001579, 2.3408359858920407],
					[59.235636264375948, 2.3398942858418614],
					[59.235636232170926, 2.3398942552264188],
					[59.235636158844621, 2.33989421332311],
					[59.235636116741887, 2.3398942004692596],
					[59.234591717081678, 2.3398026012845792],
					[59.234591664457774, 2.3398026115886101],
					[59.234591570453041, 2.3398026581250329],
					[59.234591529964192, 2.3398026941827768],
					[59.233666529652105, 2.3413054945132696],
					[59.233666525550348, 2.3413055023484595],
					[59.233666518238721, 2.3413055178442321],
					[59.233666514136928, 2.3413055256794317],
					[59.232905429838013, 2.3431970860212616],
					[59.232352763748175, 2.3437386563092044],
					[59.231808409425923, 2.3434554317305802],
					[59.231194583757265, 2.3429665902238979],
					[59.23099189700136, 2.3418193647716423],
					[59.230991890576178, 2.341819344934077],
					[59.230991876031439, 2.3418193073484894],
					[59.230991866038529, 2.3418192882091744],
					[59.230547378013753, 2.3411582059689748],
					[59.230172386166551, 2.3402055270187967],
					[59.230172362702056, 2.3402054911808463],
					[59.230172303020446, 2.3402054343065615],
					[59.230172266713872, 2.3402054115298632],
					[59.229283366679489, 2.339888712036946],
					[59.229283319583146, 2.339888707193881],
					[59.22928322922958, 2.3398887196080782],
					[59.229283184188482, 2.3398887372145691],
					[59.228380483593348, 2.3405276372142012],
					[59.228380475744885, 2.3405276422660122],
					[59.228380460316458, 2.3405276575904277],
					[59.228380452557488, 2.3405276643825106],
					[59.22703316373525, 2.3419970538982984],
					[59.224827676611227, 2.343727643860551],
					[59.224827662787348, 2.3437276553526822],
					[59.224827638975654, 2.3437276828593596],
					[59.224827628898403, 2.3437276971338004],
					[59.223397029225282, 2.346102696467538],
					[59.223397018613383, 2.3461027178752465],
					[59.223397004525154, 2.3461027592954378],
					[59.223397001227617, 2.3461027827880003],
					[59.22307760132589, 2.3497693835034288],
					[59.223077599988748, 2.3497693925522363],
					[59.223077599990305, 2.3497694101269215],
					[59.223077601418346, 2.349769420392867],
					[59.223483096610863, 2.3538888783925405],
					[59.222633116767703, 2.3566277146085941],
					[59.220852704277938, 2.3594191787184799],
					[59.219630770176231, 2.359183129690229],
					[59.219172400985123, 2.3568832857636166],
					[59.219311299081426, 2.354030615139584],
					[59.21963079696917, 2.3511333418624338],
					[59.220275191097187, 2.3490611584030714],
					[59.220275192613016, 2.349061152834794],
					[59.220275197160483, 2.3490611361299174],
					[59.220275197605673, 2.3490611272561357],
					[59.220505798065382, 2.3474056271757187],
					[59.220505798329867, 2.3474055972487133],
					[59.220505787084711, 2.3474055361814377],
					[59.220505778429697, 2.3474055079979257],
					[59.220161277940178, 2.3467388087978831],
					[59.220161225933403, 2.3467387609718346],
					[59.220161102747824, 2.3467387130014905],
					[59.220161033263544, 2.3467387107685695],
					[59.219516632864874, 2.3469665113107236],
					[59.219516614402146, 2.3469665201916801],
					[59.219516578636835, 2.3469665429988065],
					[59.219516562136768, 2.346966555010765],
					[59.218505461986304, 2.3479331556898599],
					[59.218505461094345, 2.3479331558641836],
					[59.218505460291766, 2.3479331577783027],
					[59.218505459399807, 2.3479331579526241],
					[59.217674859280926, 2.3487609572462045],
					[59.217674853394698, 2.3487609654252961],
					[59.21767484224614, 2.3487609763899213],
					[59.217674837251877, 2.3487609843947292],
					[59.216985936850413, 2.3497276847437845],
					[59.216985922848707, 2.3497277103227461],
					[59.216985905192836, 2.3497277700021564],
					[59.216985900378688, 2.3497277990576757],
					[59.21698590007567, 2.3510166969270636],
					[59.216938700631857, 2.3525805472545338],
					[59.216524856100619, 2.3533637442054558],
					[59.21555828316572, 2.3537775898228444],
					[59.214639034994526, 2.3535470284634696],
					[59.213950176040854, 2.3522582068663533],
					[59.213950175862244, 2.3522582033871746],
					[59.213950173899704, 2.3522582002563697],
					[59.213950172918437, 2.3522581986909641],
					[59.213441899359836, 2.3513860446703467],
					[59.213375201202489, 2.3487972245088016],
					[59.213672380205502, 2.3477167994205241],
					[59.214108318990633, 2.3472419747548292],
					[59.214499871935757, 2.3475668531485323],
					[59.214499876663609, 2.3475668574957917],
					[59.214499887903195, 2.3475668658417264],
					[59.214499892541674, 2.3475668684494146],
					[59.215374892584897, 2.3481195691744823],
					[59.215374926659358, 2.3481195836012971],
					[59.21537499667987, 2.3481195962752222],
					[59.215375032715286, 2.3481195962620056],
					[59.216202832629627, 2.3479807963585979],
					[59.216202872409838, 2.3479807815561795],
					[59.216202941175787, 2.3479807347321886],
					[59.21620297025089, 2.3479807044502792],
					[59.21671127026687, 2.3471529044722388],
					[59.216711276776806, 2.3471528908996646],
					[59.216711288904705, 2.347152863928835],
					[59.216711293630745, 2.3471528507049197],
					[59.217355794142691, 2.3446667508502017],
					[59.217355794766398, 2.344666745456621],
					[59.217355796905743, 2.3446667344950485],
					[59.21735579833198, 2.3446667271873256],
					[59.217675197813044, 2.3424111277874435],
					[59.217675199149689, 2.3424111187398351],
					[59.217675199949703, 2.3424110992537921],
					[59.217675200394417, 2.3424110903806401],
					[59.217583499812925, 2.3406638891645839],
					[59.217583498294729, 2.340663877161],
					[59.21758349543726, 2.3406638566332805],
					[59.217583492045719, 2.3406638432389819],
					[59.217169591606357, 2.3392360438370052],
					[59.217169587322751, 2.3392360306175553],
					[59.217169576169226, 2.3392360064419524],
					[59.217169570101753, 2.3392359935715805],
					[59.216686281386174, 2.3384610116138025],
					[59.216022398285993, 2.3366138578517308],
					[59.215658500621913, 2.3314694024306459],
					[59.21595579907526, 2.328461119291874],
					[59.215955798985632, 2.328461117552274],
					[59.215955798896005, 2.328461115812658],
					[59.215955798806384, 2.3284611140730589],
					[59.216147398830387, 2.3257917140742954],
					[59.216147400255544, 2.3257917067661209],
					[59.216147400430138, 2.325791692674414],
					[59.21614739908992, 2.325791684151302],
					[59.215922398872486, 2.3227971857879481],
					[59.215922398244565, 2.3227971736108932],
					[59.215922393421103, 2.3227971499566071],
					[59.21592239011747, 2.3227971383044292],
					[59.215439091414574, 2.3212999394499532],
					[59.214583491339873, 2.3185860400234497],
					[59.214583483217368, 2.3185860222894616],
					[59.214583465278302, 2.3185859889111051],
					[59.214583453677982, 2.3185859736168655],
					[59.211647354388283, 2.3150331726661886],
					[59.211647318790234, 2.3150331462738474],
					[59.211647242069347, 2.3150331086297387],
					[59.211647199252553, 2.3150330994675952],
					[59.20933895895007, 2.3150498003221207],
					[59.207466849175212, 2.3138137598303947],
					[59.205852992253192, 2.3110777324703613],
					[59.205805797733476, 2.3107555714309385],
					[59.205805796572029, 2.3107555663890817],
					[59.205805795141004, 2.3107555561301525],
					[59.205805793889709, 2.3107555493492624],
					[59.205252994834986, 2.308591655626099],
					[59.204839095842956, 2.306522160766189],
					[59.204839095842956, 2.306522160766189],
					[59.204839095753059, 2.3065221590272307],
					[59.204839095663154, 2.3065221572882577],
					[59.204425195648533, 2.3045888583062593],
					[59.204425193325164, 2.3045888482233914],
					[59.204425186894717, 2.3045888284083502],
					[59.204425182877543, 2.3045888204151179],
					[59.203780783218605, 2.3031166193356447],
					[59.203780782236777, 2.3031166177721283],
					[59.203780781164994, 2.3031166144697379],
					[59.203780780183187, 2.3031166129062344],
					[59.20313628045556, 2.3017805134714493],
					[59.203136274564514, 2.3017805040908335],
					[59.203136264656173, 2.3017804867176115],
					[59.203136258855103, 2.3017804790758478],
					[59.202261258923066, 2.3006304785046701],
					[59.202261226889931, 2.300630451432391],
					[59.202261154534938, 2.3006304112138842],
					[59.202261115284919, 2.3006304013698284],
					[59.201088915183995, 2.3005442013972881],
					[59.201088900734199, 2.3005442007267813],
					[59.201088874690164, 2.3005442023369285],
					[59.201088862204081, 2.3005442047930367],
					[59.200641662396499, 2.3006304039615708],
					[59.200641640189879, 2.3006304100860619],
					[59.200641597118441, 2.3006304308532379],
					[59.200641576965502, 2.3006304418430474],
					[59.1997248771955, 2.3013442425569268],
					[59.199724864267402, 2.3013442538814126],
					[59.199724841266708, 2.3013442794815302],
					[59.199724830302294, 2.3013442939325586],
					[59.19863873024466, 2.3030831945592416],
					[59.198638718837906, 2.3030832178777514],
					[59.198638704321091, 2.3030832681524864],
					[59.198638700319137, 2.3030832952840554],
					[59.198516500150816, 2.3084416948059459],
					[59.19851650095972, 2.3084417104537454],
					[59.198516504271453, 2.3084417396602572],
					[59.198516506774219, 2.3084417532189954],
					[59.199335907155778, 2.3114417523312301],
					[59.199335908227347, 2.3114417556334943],
					[59.199335909209069, 2.3114417571970471],
					[59.199335909388743, 2.3114417606744344],
					[59.199824806243051, 2.3129944509240183],
					[59.200071998395664, 2.3141361147917059],
					[59.19999700102688, 2.3151277802567041],
					[59.199866502267071, 2.3161721699420919],
					[59.199708102699802, 2.3170638656346556],
					[59.199708101993195, 2.3170638868494802],
					[59.199708103255674, 2.3170639287541044],
					[59.199708106026868, 2.3170639475301984],
					[59.199891505121442, 2.3178000432796986],
					[59.200166502361462, 2.3191333282059357],
					[59.200305400129707, 2.32065280554637],
					[59.200260902214922, 2.3218027535338046],
					[59.199844243301683, 2.3227220622763367],
					[59.199108204483579, 2.3231831246729104],
					[59.198419325585981, 2.3235054132679296],
					[59.197727783410073, 2.3237358950519802],
					[59.197130695153895, 2.3236442112094808],
					[59.196864077398182, 2.3233776945055395],
					[59.196433497039045, 2.3219610608254579],
					[59.196350200162819, 2.3210666878895978],
					[59.196302999783171, 2.3194110932737],
					[59.196303000405855, 2.3194110878830898],
					[59.19630299897559, 2.3194110776265804],
					[59.196302997724764, 2.3194110708472064],
					[59.196072398239004, 2.3178443717290524],
					[59.196072394755568, 2.3178443566069169],
					[59.196072386986522, 2.3178443282761729],
					[59.19607238091713, 2.3178443154174437],
					[59.195475181337251, 2.3165555165271488],
					[59.195475151444327, 2.3165554784885689],
					[59.195475076412343, 2.3165554212077275],
					[59.19547503127324, 2.3165554019654562],
					[59.194600030774275, 2.3164192014504694],
					[59.194600020873665, 2.3164192016367497],
					[59.194600001874583, 2.316419200095849],
					[59.194599991973988, 2.3164192002821316],
					[59.1934499916281, 2.3164636997608166],
					[59.19344997459234, 2.3164637013468026],
					[59.193449940790146, 2.3164637097340055],
					[59.193449923933912, 2.3164637147968334],
					[59.192666644978551, 2.316785905269414],
					[59.192116720860533, 2.3168775936509642],
					[59.191286222438912, 2.3165554336980416],
					[59.190091874290232, 2.3150776978063563],
					[59.18922518474119, 2.3129527247964448],
					[59.189225177689657, 2.312952710377981],
					[59.189225161892686, 2.3129526836292778],
					[59.189225153147213, 2.31295267129904],
					[59.187272352768623, 2.310630471286923],
					[59.187272331979244, 2.3106304525435881],
					[59.187272287192329, 2.3106304227095342],
					[59.187272262213057, 2.3106304100558224],
					[59.184522325221501, 2.3097415304222855],
					[59.182325170836421, 2.3066387945546052],
					[59.180611285880765, 2.3033666223168905],
					[59.179922393855442, 2.3011416501037374],
					[59.179600199862335, 2.2996221771099692],
					[59.179552999987003, 2.2973693963198238],
					[59.179552999896998, 2.2973693945821849],
					[59.179552999806987, 2.2973693928445327],
					[59.179552999716975, 2.2973693911068942],
					[59.179461299914536, 2.2953443906082023],
					[59.179461298752571, 2.2953443855707434],
					[59.17946129821236, 2.2953443751449671],
					[59.17946129794224, 2.2953443699320717],
					[59.179230797686706, 2.2938249706653369],
					[59.179230793128667, 2.2938249522534395],
					[59.179230780445145, 2.2938249161314817],
					[59.179230772319698, 2.2938248984214202],
					[59.178772371746106, 2.2930442993217932],
					[59.178772370764193, 2.2930442977596877],
					[59.178772368710291, 2.2930442928978745],
					[59.178772367728364, 2.2930442913357658],
					[59.178430768078393, 2.292519291633361],
					[59.178430719180007, 2.2925192521068496],
					[59.178430608023213, 2.2925192107884524],
					[59.178430545674736, 2.2925192072590161],
					[59.177713845599222, 2.2927220086099225],
					[59.177713814744635, 2.292722021701771],
					[59.177713760890889, 2.2927220603818586],
					[59.177713736909787, 2.2927220844080747],
					[59.177022036690055, 2.2936887844884901],
					[59.177022033392809, 2.2936887904027858],
					[59.17702202590646, 2.2936888024068098],
					[59.177022021717363, 2.2936888084965341],
					[59.176010922159968, 2.2956666092307607],
					[59.176010921358149, 2.2956666111436013],
					[59.176010918952642, 2.2956666168821274],
					[59.176010918150816, 2.2956666187949701],
					[59.175183123420162, 2.297505505084882],
					[59.174169230983601, 2.299116592843546],
					[59.174169227686093, 2.2991165987568203],
					[59.174169221892932, 2.2991166086707007],
					[59.174169218595431, 2.2991166145839563],
					[59.173066519369698, 2.3014638157013634],
					[59.173066517675913, 2.3014638177891218],
					[59.173066516072062, 2.3014638216142238],
					[59.173066515270143, 2.3014638235267646],
					[59.172605433871958, 2.3025692779023919],
					[59.171455508298408, 2.3035359156823318],
					[59.170488854219705, 2.3037637053661144],
					[59.170488820778083, 2.3037637207074493],
					[59.17048876326313, 2.3037637583247443],
					[59.170488736694033, 2.3037637846005237],
					[59.169474837033718, 2.305191583900966],
					[59.169474827942238, 2.3051915997242882],
					[59.169474813326786, 2.3051916306705391],
					[59.169474807000832, 2.3051916477057297],
					[59.168694207108878, 2.3080443476731181],
					[59.168694207198733, 2.3080443494102449],
					[59.168694206396708, 2.3080443513223856],
					[59.16869420559469, 2.3080443532345414],
					[59.167910906742073, 2.3112638481078607],
					[59.167483108116144, 2.3127388447845267],
					[59.167174808428967, 2.3137971446470806],
					[59.167174806734963, 2.3137971467338585],
					[59.167174806022622, 2.3137971503828543],
					[59.167174806112399, 2.3137971521199212],
					[59.166394205666407, 2.3169249527465072],
					[59.166394204953974, 2.3169249563953564],
					[59.166394202637235, 2.31692496386784],
					[59.16639420201453, 2.3169249692537517],
					[59.165888702318568, 2.3201221698112353],
					[59.165888702318568, 2.3201221698112353],
					[59.16588870240826, 2.3201221715482618],
					[59.165888701606043, 2.3201221734599677],
					[59.165374801890273, 2.3238388721912204],
					[59.165374801979915, 2.3238388739282492],
					[59.165374801267291, 2.3238388775768586],
					[59.165374801356933, 2.3238388793138722],
					[59.165194201257691, 2.325547179196914],
					[59.165030401273803, 2.3270888763417936],
					[59.164508102327716, 2.33114997344394],
					[59.16450810090204, 2.3311499807407063],
					[59.164508099655464, 2.331149991511464],
					[59.164508099924106, 2.3311499967224663],
					[59.164452600389865, 2.3355138967218072],
					[59.164452599855863, 2.3355139038441295],
					[59.164452601284722, 2.3355139140919197],
					[59.164452600661242, 2.3355139194772327],
					[59.165141500148259, 2.3423806066365573],
					[59.164999800764832, 2.3463166793631496],
					[59.164138703222022, 2.3516805688317328],
					[59.164138701616629, 2.3516805726535313],
					[59.164138701171026, 2.3516805815126411],
					[59.16413869965492, 2.3516805870714985],
					[59.163913699765878, 2.3551471870730731],
					[59.163688700913831, 2.3586110817961647],
					[59.163385900641394, 2.3611888763470223],
					[59.163385900730553, 2.3611888780840631],
					[59.163172005854875, 2.3631499347012568],
					[59.162452677058504, 2.3643581152067297],
					[59.161713871742279, 2.3644637028696236],
					[59.161713857470524, 2.3644637056456066],
					[59.161713828124093, 2.3644637131080462],
					[59.161713814030492, 2.3644637193579934],
					[59.16073601420748, 2.3649304195074774],
					[59.160735986376608, 2.3649304389542465],
					[59.160735939277316, 2.3649304867080398],
					[59.160735919116895, 2.3649305151885418],
					[59.15851651919747, 2.3696443152665321],
					[59.158516515985568, 2.3696443229072295],
					[59.158516508758822, 2.3696443400987772],
					[59.158516507419961, 2.3696443491296164],
					[59.15791374417087, 2.3719387086632073],
					[59.154980589426543, 2.3721026005565471],
					[59.154980542417476, 2.3721026149489997],
					[59.154980460349194, 2.3721026659680682],
					[59.154980426181993, 2.3721027024214623],
					[59.154347151466446, 2.3732246796545686],
					[59.153722392384175, 2.3726360062881175],
					[59.153533499121622, 2.3706221802711633],
					[59.153533498943595, 2.3706221767980078],
					[59.15353349662557, 2.370622166725084],
					[59.153533496358556, 2.3706221615153305],
					[59.153016895830476, 2.3679582624329125],
					[59.153016871662345, 2.3679582127603549],
					[59.153016800132662, 2.3679581354610755],
					[59.153016752771116, 2.3679581078343745],
					[59.151491753250106, 2.3675387066858056],
					[59.151491665834826, 2.367538723673988],
					[59.151491540057712, 2.3675388182682555],
					[59.151491500000972, 2.3675388979575143],
					[59.151471999600687, 2.3691777976345518],
					[59.151471999689704, 2.3691777993710219],
					[59.151458100018836, 2.3719027914317481],
					[59.151213701199303, 2.374633281920342],
					[59.151213701377202, 2.3746332853933096],
					[59.151213700037957, 2.3746332944220234],
					[59.151213700304808, 2.374633299631451],
					[59.151208100428519, 2.3759305987113559],
				],
			],
		},
		{
			fldNpdidField: "4704482",
			wlbNpdidWellbore: "3683",
			fldName: "SKARV",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6507/5-1",
			fldDiscoveryYear: "1998",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=4704482",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=4704482",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "SKARV UNIT",
			cmpLongName: "Aker BP ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=3683",
			fldHcType: "OIL/GAS",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "28544099",
			fldNpdidOwner: "4468539",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2021-12-21 00:00:00",
			fldMainSupplyBase: "Sandnessjøen",
			fldAreaGeometryWKT: [
				[
					[65.77109150103972, 7.6858750190199174],
					[65.771291500645688, 7.6880694183868812],
					[65.771291503430092, 7.6880694291654663],
					[65.771291508120683, 7.6880694529512965],
					[65.771291510923774, 7.6880694659129514],
					[65.772127611272296, 7.6904528652899193],
					[65.772127614037828, 7.6904528738859694],
					[65.772127618634585, 7.6904528867572814],
					[65.772127623175209, 7.6904528930791507],
					[65.773188722920906, 7.6924944922457623],
					[65.773188725667623, 7.6924944986591326],
					[65.773188732020685, 7.6924945070739881],
					[65.77318873476743, 7.6924945134873797],
					[65.775022034600823, 7.6951584139739024],
					[65.775022036394688, 7.6951584138831945],
					[65.775022037310194, 7.6951584160212319],
					[65.775022038207126, 7.695158415975877],
					[65.77685543366529, 7.6977112124659435],
					[65.77855543065921, 7.7003834073065409],
					[65.77855543065921, 7.7003834073065409],
					[65.778555431556128, 7.7003834072613619],
					[65.779380432056968, 7.7016668069115743],
					[65.779380432075484, 7.7016668090953591],
					[65.779380432990919, 7.7016668112339879],
					[65.779380433887852, 7.7016668111888515],
					[65.780024802107462, 7.7026250624819799],
					[65.780088700285674, 7.7050499865140507],
					[65.779788725303248, 7.706938724962491],
					[65.77879436760459, 7.7070998028929001],
					[65.778794356860075, 7.7070998056159734],
					[65.778794333577196, 7.7070998111520153],
					[65.778794322851098, 7.707099816058828],
					[65.777891622908967, 7.7074748158398698],
					[65.777891594355296, 7.7074748347468569],
					[65.777891545375553, 7.7074748787073615],
					[65.777891524070981, 7.707474905989474],
					[65.77704422404031, 7.7090610069280014],
					[65.777044219611042, 7.7090610137030913],
					[65.777044211686245, 7.7090610315755841],
					[65.777044209069217, 7.7090610404445199],
					[65.776513709297248, 7.7107416410772576],
					[65.776513705801634, 7.7107416521741845],
					[65.776513702398049, 7.7107416741887782],
					[65.776513699817727, 7.7107416874244192],
					[65.776313700090512, 7.7139499882186744],
					[65.776313700200689, 7.7139500013197848],
					[65.776313703130143, 7.7139500295714356],
					[65.776313705034156, 7.713950042583142],
					[65.776738704814861, 7.7159139420543195],
					[65.776738705748457, 7.7159139463768094],
					[65.776738709427804, 7.7159139571160322],
					[65.776738710361386, 7.7159139614385239],
					[65.777202609884668, 7.7173250610290447],
					[65.777202656122071, 7.7173251177122015],
					[65.777202772429092, 7.7173251840191126],
					[65.777202844310892, 7.7173251957372839],
					[65.777836020279636, 7.7171835227318901],
					[65.778660941928649, 7.718244522309643],
					[65.778660942843857, 7.7182445244488225],
					[65.778660943740803, 7.7182445244042626],
					[65.778660944655996, 7.7182445265434225],
					[65.779438745442931, 7.7192001265054442],
					[65.779438745442931, 7.7192001265054442],
					[65.77943874635811, 7.7192001286447027],
					[65.779438747255057, 7.7192001286001748],
					[65.781130436941439, 7.7212112162985571],
					[65.782372024979637, 7.7232444982615673],
					[65.784030421774048, 7.7264722918360098],
					[65.784030421774048, 7.7264722918360098],
					[65.784030422689142, 7.7264722939759078],
					[65.784030422689142, 7.7264722939759078],
					[65.785455422905287, 7.7291611946743357],
					[65.785455423802219, 7.7291611946301417],
					[65.785455423820352, 7.7291611968144434],
					[65.78545542471727, 7.7291611967702512],
					[65.786927625129167, 7.7318444958773931],
					[65.78692762695924, 7.7318445001580445],
					[65.786927632413224, 7.7318445086311627],
					[65.786927635140188, 7.7318445128677125],
					[65.78853313492472, 7.7341945120499265],
					[65.788533136754737, 7.7341945163310326],
					[65.788533143087434, 7.7341945225765611],
					[65.788533144917423, 7.7341945268576673],
					[65.789402628272143, 7.7352557070382844],
					[65.79051091707926, 7.737738982972413],
					[65.790510935234067, 7.7377390083111957],
					[65.790510978701093, 7.7377390564528028],
					[65.790511004892153, 7.7377390770269461],
					[65.792010974624162, 7.7385473595380585],
					[65.792972052442721, 7.7396029355514822],
					[65.79297207231906, 7.7396029520664822],
					[65.792972115623485, 7.7396029805512248],
					[65.792972139930441, 7.7396029902921573],
					[65.794197140325295, 7.739986291373234],
					[65.794197164578264, 7.7399862945598219],
					[65.794197214860091, 7.7399862986602361],
					[65.794197239955992, 7.7399862952478165],
					[65.79559996862298, 7.7396974110618233],
					[65.797283176769739, 7.7406084609505967],
					[65.798699836032284, 7.7420834187890506],
					[65.800033116673944, 7.7446667826105093],
					[65.800822013333317, 7.7467250727621488],
					[65.80082202334269, 7.7467250897673692],
					[65.800822045173192, 7.7467251258762193],
					[65.800822058752416, 7.7467251405213844],
					[65.802191558972538, 7.7480945420379417],
					[65.802191593252459, 7.748094564425462],
					[65.802191668862562, 7.7480945935517793],
					[65.802191710192744, 7.748094600290556],
					[65.803686110228327, 7.7480223999932596],
					[65.80368612009454, 7.7480223995139417],
					[65.803686138912127, 7.7480223964129964],
					[65.803686148760434, 7.7480223937478172],
					[65.804769449126866, 7.7477473950675648],
					[65.804769465217888, 7.7477473877251306],
					[65.804769494727083, 7.7477473753569948],
					[65.804769509921172, 7.7477473680581692],
					[65.806033410046751, 7.7469140669590884],
					[65.806033426084014, 7.7469140530575853],
					[65.806033454553045, 7.7469140232429652],
					[65.806033467881676, 7.7469140072862528],
					[65.806836221175629, 7.7456612816476849],
					[65.807697128125852, 7.7455085164018422],
					[65.808974853603331, 7.7468807369826278],
					[65.808974859917612, 7.746880741049873],
					[65.80897487258197, 7.746880753557007],
					[65.808974878914114, 7.7468807598105869],
					[65.8097970791855, 7.747502959260796],
					[65.809797109824117, 7.747502975269315],
					[65.809797174581689, 7.7475029939936109],
					[65.80979720873647, 7.747503001082146],
					[65.810791708564579, 7.7474558010547296],
					[65.810791729176003, 7.7474557978652676],
					[65.810791767672271, 7.7474557872442205],
					[65.810791787350993, 7.7474557797254366],
					[65.812055686864412, 7.7468418795679579],
					[65.812055697591745, 7.7468418746712233],
					[65.812055718113655, 7.7468418605482254],
					[65.812055727926136, 7.746841853508533],
					[65.813047328344766, 7.746019553482733],
					[65.814169528282406, 7.7450834543012714],
					[65.814169562742691, 7.745083389181187],
					[65.814169579587869, 7.7450832549152739],
					[65.81416950062335, 7.7450831450058377],
					[65.814169366694742, 7.7450831165287894],
					[65.814169295030368, 7.7450831309583998],
					[65.813003543835762, 7.7458003859554809],
					[65.813627919891871, 7.7453307601317531],
					[65.81362792880735, 7.7453307531347395],
					[65.813627944826564, 7.7453307370414013],
					[65.813627952845124, 7.7453307300880949],
					[65.814211253420936, 7.7446390279267732],
					[65.814211256093785, 7.7446390256088806],
					[65.814211261421548, 7.7446390187863203],
					[65.814211263197464, 7.7446390165121395],
					[65.814616863272548, 7.7440668170156046],
					[65.814616873874158, 7.7440667968096939],
					[65.814616889713889, 7.7440667588470173],
					[65.814616895830909, 7.744066738859777],
					[65.814789095595998, 7.7431750380294542],
					[65.814789098196997, 7.7431750269641322],
					[65.814789099775439, 7.7431750006349542],
					[65.814789099667735, 7.7431749875141289],
					[65.814736300069626, 7.7422943881282],
					[65.814736298168, 7.7422943750949997],
					[65.814736294382683, 7.7422943512153948],
					[65.814736289790289, 7.7422943383135845],
					[65.814039092037049, 7.7401193422478105],
					[65.813291892897951, 7.7373943465913362],
					[65.813291889220082, 7.7373943358338284],
					[65.813291879155585, 7.7373943122640352],
					[65.813291873683895, 7.7373943015944544],
					[65.812189081809535, 7.7354610151772896],
					[65.810475187110399, 7.7309082305864605],
					[65.810475186177243, 7.7309082262577951],
					[65.81047518434714, 7.7309082219733156],
					[65.81047518162012, 7.7309082177329991],
					[65.809505781850845, 7.728747118936564],
					[65.809505779087445, 7.7287471103239564],
					[65.809505769991176, 7.7287470954621256],
					[65.809505766330858, 7.7287470868937733],
					[65.808130772108342, 7.7267165970256251],
					[65.807394579181917, 7.725316611800868],
					[65.806747393899556, 7.723913841814209],
					[65.806555798255388, 7.7225999748092002],
					[65.80636129888974, 7.7208416782162104],
					[65.806361298871465, 7.720841676030143],
					[65.806361298871465, 7.720841676030143],
					[65.806361297974576, 7.7208416760746674],
					[65.806069597910863, 7.7185388743747891],
					[65.806069595092012, 7.718538859206304],
					[65.80606958584832, 7.7185388268616988],
					[65.806069580338715, 7.7185388118270293],
					[65.805330779975463, 7.7170305115394573],
					[65.805330778163295, 7.717030509442794],
					[65.805330773623723, 7.7170305031081279],
					[65.805330771793265, 7.7170304988254879],
					[65.803172371564628, 7.7133831971700175],
					[65.803172366109649, 7.7133831886955084],
					[65.803172354321106, 7.7133831739770846],
					[65.803172347072291, 7.7133831655921341],
					[65.801525153911882, 7.7115887734317612],
					[65.800105761303541, 7.709672081345559],
					[65.800105755866781, 7.7096720750583865],
					[65.800105747702432, 7.7096720645348453],
					[65.800105743180993, 7.7096720603883009],
					[65.798872356439944, 7.708410972581242],
					[65.797864069833153, 7.7069165945380416],
					[65.796439074182061, 7.7044499010898111],
					[65.795058474701833, 7.7019805015753464],
					[65.795058473804929, 7.701980501620505],
					[65.795058472889465, 7.7019804994805705],
					[65.795058472889465, 7.7019804994805705],
					[65.794183472578084, 7.7004776988525885],
					[65.79418344901714, 7.7004776716227594],
					[65.794183393000338, 7.7004776263552603],
					[65.794183360563039, 7.7004776105026043],
					[65.793002860944512, 7.7000970103717821],
					[65.793002850144461, 7.7000970065445857],
					[65.793002826769069, 7.700097001165525],
					[65.793002815109205, 7.700097001753381],
					[65.792141761149125, 7.7000304036273368],
					[65.790914031948475, 7.699319246626219],
					[65.789630768226331, 7.697730490972333],
					[65.788894576641738, 7.6963332067868055],
					[65.788894576641738, 7.6963332067868055],
					[65.788894575744806, 7.6963332068321471],
					[65.787744576110441, 7.6941832055134043],
					[65.787744557967116, 7.6941831823925222],
					[65.787744516336318, 7.6941831407921963],
					[65.787744491951912, 7.6941831223581456],
					[65.786197291813551, 7.693380422010125],
					[65.786197271109998, 7.6933804143178799],
					[65.786197227049442, 7.6933804034383346],
					[65.786197203692467, 7.6933804002510753],
					[65.784655603620891, 7.6933469995907942],
					[65.784655600930151, 7.6933469997271056],
					[65.784655593754877, 7.6933470000905979],
					[65.784655590167219, 7.6933470002723459],
					[65.78311671273056, 7.6934247981507076],
					[65.780758335675557, 7.6929942037108523],
					[65.780758329378514, 7.6929942018450648],
					[65.780758314112362, 7.6929942004336826],
					[65.780758306937059, 7.6929942007972105],
					[65.779444471706881, 7.6929498013203332],
					[65.777983445931454, 7.6918109606593212],
					[65.776333463985566, 7.6894665852358104],
					[65.776333461257423, 7.6894665810054814],
					[65.7763334540073, 7.689466572635907],
					[65.776333451279143, 7.689466568405579],
					[65.774686251358915, 7.6875665699610654],
					[65.774686245921231, 7.6875665636846797],
					[65.774686235064607, 7.6875665533152828],
					[65.774686228730033, 7.6875665470845336],
					[65.773089029258799, 7.6862137466888916],
					[65.773089013917556, 7.686213736549],
					[65.773088980563102, 7.6862137185894355],
					[65.7730889643437, 7.6862137106784223],
					[65.771952863916383, 7.6858304105937751],
					[65.771952845921888, 7.6858304049578292],
					[65.77195280909234, 7.6858304002809508],
					[65.771952790238473, 7.6858303990569201],
					[65.771091690280258, 7.6858747997196302],
					[65.771091618790166, 7.6858748339357881],
					[65.77109152461837, 7.6858749435630971],
					[65.77109150103972, 7.6858750190199174],
				],
				[
					[65.502755421796962, 7.3437194125849654],
					[65.502755428168868, 7.3437195029757731],
					[65.515022027982681, 7.3642529021861298],
					[65.515022032559941, 7.3642529105505643],
					[65.515022042588001, 7.3642529250619235],
					[65.515022048015496, 7.3642529290475336],
					[65.540505447586881, 7.3939279307560248],
					[65.540505452116776, 7.3939279348068796],
					[65.540505459360006, 7.3939279408555239],
					[65.540505462096249, 7.3939279450167898],
					[65.559052661815969, 7.4115418452457735],
					[65.559052669058516, 7.4115418513035571],
					[65.559052684440388, 7.4115418633645076],
					[65.55905269166027, 7.4115418672572764],
					[65.585472057154675, 7.4286001446254293],
					[65.604119223712914, 7.4565944964841888],
					[65.628777617665946, 7.511202882737626],
					[65.628777652150021, 7.5112029220322372],
					[65.628777735298073, 7.5112029824317421],
					[65.628777784816435, 7.5112029991433689],
					[65.637719366339454, 7.5118557987886119],
					[65.64508875962963, 7.5139057685062411],
					[65.649369221159901, 7.5226056879219394],
					[65.649369252032528, 7.5226057252941443],
					[65.649369327041001, 7.5226057797188002],
					[65.64936937209481, 7.522605798892898],
					[65.661469306811085, 7.5243362887834584],
					[65.665177660784721, 7.5279445419469626],
					[65.665177772247603, 7.5279445617228316],
					[65.665177942000383, 7.5279444846575059],
					[65.665178000269279, 7.5279443856423054],
					[65.664771842055671, 7.5219811609150424],
					[65.66608324388109, 7.5223140909389761],
					[65.669241637410593, 7.523369589002991],
					[65.669241703799315, 7.52336958739805],
					[65.669241821826645, 7.523369543693109],
					[65.669241872547389, 7.5233694994698377],
					[65.670013848092708, 7.5220310897023346],
					[65.672455408184021, 7.5295611553164976],
					[65.674094205780975, 7.5358667491339526],
					[65.67409421406164, 7.5358667704255593],
					[65.674094233271788, 7.5358668085073166],
					[65.674094245098132, 7.5358668252468384],
					[65.677549841899022, 7.5401168223941815],
					[65.680747040403674, 7.5443862202451406],
					[65.680747051290638, 7.5443862326925579],
					[65.680747075734487, 7.5443862552609646],
					[65.680747090208996, 7.5443862675069129],
					[65.681232208305985, 7.5447061104394466],
					[65.681249802250818, 7.5448278281863317],
					[65.681249807860382, 7.54482785181309],
					[65.681249825316058, 7.5448278943634062],
					[65.681249837182989, 7.5448279154622897],
					[65.683616507054467, 7.5481833725576166],
					[65.684205397249499, 7.5533777844706762],
					[65.683305957445526, 7.5568153952705659],
					[65.682844210069888, 7.558319339819124],
					[65.682291509992865, 7.5599638364429884],
					[65.682291505652358, 7.559963851920358],
					[65.682291500579282, 7.5599638848512294],
					[65.682291499846784, 7.559963902304708],
					[65.68231919964316, 7.5629583023900295],
					[65.682335899908367, 7.5648000028154501],
					[65.682335900928081, 7.5648000158185713],
					[65.682335904761331, 7.564800041725471],
					[65.682335907595274, 7.5648000568047351],
					[65.682533108377228, 7.565477856045483],
					[65.682533120282187, 7.5654778815058918],
					[65.682533151267137, 7.565477932029502],
					[65.682533171203033, 7.5654779526921221],
					[65.682812093756809, 7.56571442189684],
					[65.682810900185288, 7.5676194007299271],
					[65.682810901225253, 7.5676194159089016],
					[65.682810906913048, 7.5676194482440851],
					[65.682810910664045, 7.5676194654498685],
					[65.686135911062109, 7.5772417641865815],
					[65.686135913833937, 7.5772417727420303],
					[65.686135921151077, 7.5772417875786182],
					[65.686135923922905, 7.577241796134067],
					[65.69052201684444, 7.5852806831406028],
					[65.692641511640872, 7.5910500680232236],
					[65.692641560615399, 7.5910501241504846],
					[65.692641684111678, 7.5910501849341161],
					[65.692641756859743, 7.5910501918644071],
					[65.692843036276145, 7.5909902789979551],
					[65.693130410257112, 7.5918556635512422],
					[65.693130472744812, 7.5918557254789256],
					[65.693130623086986, 7.5918557782756118],
					[65.693130710921338, 7.591855766968223],
					[65.694264011324663, 7.5911029675647024],
					[65.694264022883615, 7.5911029560480321],
					[65.694264046918533, 7.5911029351423887],
					[65.694264057580611, 7.5911029236745033],
					[65.694769578649527, 7.5904532271451766],
					[65.6960470616512, 7.5902113293149762],
					[65.698916509380695, 7.5994306595403387],
					[65.704566509125684, 7.6180944590596109],
					[65.70456651281188, 7.6180944697550261],
					[65.704566522894623, 7.6180944931796155],
					[65.704566529271432, 7.6180945037314114],
					[65.708941528672653, 7.6252779043290806],
					[65.708941545909809, 7.6252779252015879],
					[65.708941584809779, 7.625277960175147],
					[65.708941607389107, 7.6252779764063368],
					[65.710812102341677, 7.6262633022792095],
					[65.711024798747829, 7.6275472096587364],
					[65.710858099721491, 7.6300943872468689],
					[65.710858100794326, 7.6300944068007066],
					[65.710858105611152, 7.6300944435880238],
					[65.710858110252033, 7.630094460774032],
					[65.711149786945484, 7.6309999905583119],
					[65.710033116443611, 7.6335971215219489],
					[65.710033112056621, 7.6335971326479592],
					[65.710033105095931, 7.6335971569831704],
					[65.710033101625356, 7.6335971702397201],
					[65.709855402125442, 7.6347582694884055],
					[65.709855401403914, 7.6347582891363013],
					[65.709855402670968, 7.6347583304680082],
					[65.709855406433888, 7.6347583498793421],
					[65.710149806429143, 7.6358972493830173],
					[65.710149811966247, 7.6358972665223348],
					[65.710149826608642, 7.6358972984340454],
					[65.710149835713864, 7.635897313206419],
					[65.710919235589898, 7.6370084132319302],
					[65.710919240132753, 7.6370084195295558],
					[65.71091924919898, 7.6370084299468983],
					[65.710919252844917, 7.6370084362917776],
					[65.712549830031193, 7.6387695107774487],
					[65.713519211178777, 7.6410222664862761],
					[65.714402603284015, 7.6442056379553147],
					[65.715119200906983, 7.650163924697754],
					[65.715119211978063, 7.6501639589891068],
					[65.715119243928314, 7.6501640205222543],
					[65.715119264807512, 7.6501640477640525],
					[65.71559983681442, 7.6506029217523688],
					[65.71637200986649, 7.65217506660042],
					[65.716866502598364, 7.6544556362855811],
					[65.717272000121113, 7.6572028158637444],
					[65.717294199850627, 7.6597389015269028],
					[65.717294199869755, 7.6597389037053905],
					[65.717294199908025, 7.6597389080623639],
					[65.717294199927125, 7.6597389102408719],
					[65.717405400007863, 7.6618083104025807],
					[65.717405413881664, 7.6618083576338067],
					[65.717405462143432, 7.6618084379583369],
					[65.717405495634594, 7.6618084710980225],
					[65.718458188351988, 7.6624473650524356],
					[65.719605479650795, 7.6633112599296194],
					[65.719605497703412, 7.6633112720749406],
					[65.719605537339035, 7.6633112896441693],
					[65.719605557128219, 7.6633112951607565],
					[65.720461057065364, 7.6634973958531143],
					[65.720461094754555, 7.6634973960857895],
					[65.720461167346812, 7.6634973857963038],
					[65.720461202211794, 7.6634973709166463],
					[65.721311181306504, 7.6629918858878883],
					[65.722258333918987, 7.6627112981783627],
					[65.72377777766647, 7.662633501941519],
					[65.72520552206359, 7.6630223863769622],
					[65.727399870589863, 7.6642918563555336],
					[65.728935939944122, 7.6660584217865999],
					[65.730858131842538, 7.668961207600435],
					[65.732110930257704, 7.6709723054055035],
					[65.732110949301443, 7.6709723284146056],
					[65.732110990900736, 7.6709723655294013],
					[65.732111015268984, 7.670972381722641],
					[65.733447114881983, 7.6715973819500993],
					[65.733447138277455, 7.6715973894713994],
					[65.733447185908418, 7.6715973979283225],
					[65.733447210162865, 7.6715974010438046],
					[65.734774863295385, 7.6715280073680763],
					[65.735844212394454, 7.6744750686792393],
					[65.735844218805212, 7.6744750836177866],
					[65.735844234279739, 7.674475108996802],
					[65.735844241587458, 7.6744751238893576],
					[65.736710941708353, 7.6755834234395621],
					[65.736710979643206, 7.6755834520315762],
					[65.736711063433916, 7.675583491360884],
					[65.736711110167732, 7.6755834998721095],
					[65.738038730982154, 7.6755169086754647],
					[65.738441503397482, 7.6780333314338032],
					[65.738441514443451, 7.6780333635876969],
					[65.738441546363674, 7.6780334230303211],
					[65.738441567219056, 7.6780334481387662],
					[65.740163746283642, 7.6795640305800115],
					[65.74228042914477, 7.6829168047458136],
					[65.744402624074482, 7.6865028956959787],
					[65.746624821424561, 7.6910083876168365],
					[65.746624821443248, 7.6910083897978225],
					[65.748652621013633, 7.6950639900264282],
					[65.748652624657169, 7.6950639963887282],
					[65.748652632841186, 7.695064009068008],
					[65.748652638297173, 7.6950640175208491],
					[65.75057483793492, 7.6977390171330997],
					[65.750574845184588, 7.6977390254967064],
					[65.750574859683979, 7.6977390422239607],
					[65.750574867830593, 7.6977390505423759],
					[65.751819267809623, 7.6988307498928794],
					[65.751819311065915, 7.6988307717202042],
					[65.75181940098048, 7.6988307933797424],
					[65.751819449432517, 7.6988307931216244],
					[65.752955648920405, 7.6985418942843253],
					[65.752955676631899, 7.6985418819757996],
					[65.752955726617685, 7.6985418510852988],
					[65.752955749788853, 7.6985418324581367],
					[65.753802950403468, 7.6975751313173193],
					[65.75380295485084, 7.6975751267279993],
					[65.753802962830122, 7.697575115412965],
					[65.753802967277522, 7.697575110823645],
					[65.754272294303092, 7.6968585226150124],
					[65.755222143586735, 7.6970418938847498],
					[65.756272128131485, 7.6974501863661198],
					[65.756272166773215, 7.697450193148299],
					[65.756272243926531, 7.6974501914399696],
					[65.756272283335022, 7.6974501829042445],
					[65.756838982968262, 7.6971918821625929],
					[65.75683902039691, 7.6971918518981823],
					[65.756839077222907, 7.6971917813649338],
					[65.756839095704777, 7.6971917389594857],
					[65.75711129633298, 7.6957917382387881],
					[65.757111297174006, 7.6957917316478683],
					[65.757111298837501, 7.6957917162841269],
					[65.75711129967857, 7.6957917096931832],
					[65.757191895448315, 7.6939390226448383],
					[65.75766387041827, 7.6936835449729761],
					[65.758619259023945, 7.6945612416085458],
					[65.760058150685907, 7.696105733719329],
					[65.761974848894084, 7.6983195311047599],
					[65.761974937126254, 7.698319565954864],
					[65.761975102925433, 7.6983195423138602],
					[65.761975179614112, 7.6983194860502406],
					[65.763469579583557, 7.6952389867919742],
					[65.763469588983909, 7.6952389317329803],
					[65.763469574580199, 7.6952388211096263],
					[65.763469550813383, 7.6952387699100919],
					[65.761550164347199, 7.6930248831944334],
					[65.760394576387867, 7.6910055046618382],
					[65.759814097200348, 7.6898777464840817],
					[65.759702999676747, 7.6880360874222031],
					[65.759702998723625, 7.6880360809214867],
					[65.759702996817424, 7.6880360679200725],
					[65.759702995864345, 7.688036061419373],
					[65.75930579563196, 7.6859777616783456],
					[65.759305786418949, 7.6859777337679853],
					[65.759305758164501, 7.6859776828134372],
					[65.7593057408981, 7.6859776574958989],
					[65.758636240727441, 7.6853165574011086],
					[65.758636226264258, 7.6853165450396812],
					[65.758636194684684, 7.6853165248177984],
					[65.758636179362128, 7.6853165168660427],
					[65.758158474115788, 7.6851109572907284],
					[65.757466894678473, 7.6819110580499492],
					[65.757466893743953, 7.6819110537319375],
					[65.757466891874856, 7.6819110450958936],
					[65.757466890940307, 7.6819110407778615],
					[65.756977994992809, 7.6803193519874995],
					[65.756680800436186, 7.6787193800622529],
					[65.756666901252089, 7.6773333671555264],
					[65.757044320788836, 7.6768532239179876],
					[65.757533109348273, 7.678444458668972],
					[65.757533112114487, 7.6784444672588679],
					[65.757533120356428, 7.6784444864829098],
					[65.757533124019574, 7.6784444950269295],
					[65.758883124150913, 7.6809167959927382],
					[65.758883125047802, 7.6809167959469242],
					[65.75888312596355, 7.680916798083123],
					[65.75888312596355, 7.680916798083123],
					[65.761680426144395, 7.6858556990714701],
					[65.761680428872666, 7.6858557032990316],
					[65.761680435226097, 7.6858557117085278],
					[65.761680437954325, 7.6858557159360927],
					[65.76312203821071, 7.6878612175277414],
					[65.763122042751462, 7.6878612238469639],
					[65.763122053626773, 7.6878612363942889],
					[65.763122059064429, 7.6878612426679318],
					[65.763791559177122, 7.688522342909776],
					[65.763791575433871, 7.6885223551843191],
					[65.763791608825542, 7.6885223775054152],
					[65.763791626838653, 7.6885223853239708],
					[65.764838826827102, 7.6889307867659484],
					[65.76483884840917, 7.6889307922203596],
					[65.76483888884502, 7.6889307989008095],
					[65.764838910389599, 7.6889307999901764],
					[65.765505609939751, 7.6888974003471802],
					[65.765505610836641, 7.6888974003016317],
					[65.766075011170315, 7.6888669010042658],
					[65.766075072851251, 7.6888668738521657],
					[65.766075167212506, 7.6888667860835538],
					[65.766075198098974, 7.6888667255581522],
					[65.766252997853655, 7.6874722244478617],
					[65.766252998713114, 7.6874722200369554],
					[65.766253000375741, 7.687472204667241],
					[65.766253000319523, 7.687472198119317],
					[65.766241899956896, 7.6863166983649309],
					[65.766241898928726, 7.6863166831320742],
					[65.766241894200334, 7.686316654985931],
					[65.766241891397058, 7.6863166420269993],
					[65.765752991317413, 7.6847249418298285],
					[65.765752977600869, 7.6847249119588747],
					[65.765752942189451, 7.6847248635411978],
					[65.765752921354007, 7.6847248405836028],
					[65.764605754696248, 7.6838581650108058],
					[65.763739075401844, 7.6822860037027629],
					[65.763739071757769, 7.6822859973385746],
					[65.763739066301085, 7.6822859888835149],
					[65.763739061760077, 7.6822859825651255],
					[65.762777965005455, 7.6809470871650669],
					[65.76220017923383, 7.6800527100351115],
					[65.761422387223675, 7.6780138290887781],
					[65.761422382644781, 7.6780138184072317],
					[65.761422372627806, 7.6780138014545321],
					[65.761422368048912, 7.6780137907729875],
					[65.760555786319372, 7.6766721207194237],
					[65.759769594369274, 7.6734777529413067],
					[65.759769593434527, 7.6734777486231964],
					[65.759769590668043, 7.6734777400330936],
					[65.759769588836349, 7.6734777357610584],
					[65.758989090199393, 7.6712055374214732],
					[65.758208491140365, 7.6687055407453641],
					[65.758208489289544, 7.6687055342920019],
					[65.75820848287816, 7.668705519341998],
					[65.758208480130435, 7.6687055129348725],
					[65.757239083783361, 7.6666777192144195],
					[65.756269585789383, 7.6641860273302393],
					[65.756269583041487, 7.6641860209240731],
					[65.756269575732759, 7.6641860060227032],
					[65.756269572984863, 7.6641859996165369],
					[65.754822377128619, 7.6617193077214649],
					[65.753758483603846, 7.6594638212428841],
					[65.752786285730338, 7.6569749280601993],
					[65.752786284795121, 7.6569749237438769],
					[65.75278628204687, 7.6569749173393227],
					[65.752786281130824, 7.6569749152044757],
					[65.75162518086465, 7.6544943158102354],
					[65.751625178097157, 7.654494307224855],
					[65.751625169909786, 7.654494294557014],
					[65.751625166264546, 7.6544942881997482],
					[65.750566865716308, 7.6529331872839332],
					[65.750566864819433, 7.6529331873307003],
					[65.749797364867874, 7.6518165878991216],
					[65.749797360306417, 7.6518165794082424],
					[65.749797350325053, 7.6518165668357518],
					[65.749797343989016, 7.651816560619725],
					[65.748741871801784, 7.6507192915230018],
					[65.74796688699459, 7.648680527951524],
					[65.747966885162199, 7.6486805236832183],
					[65.747966880581259, 7.6486805130124313],
					[65.747966877851979, 7.6486805087910152],
					[65.746708479028797, 7.6462055097104038],
					[65.746033480683963, 7.6448555167327612],
					[65.745158488935687, 7.6428221336722402],
					[65.744572392296575, 7.6407749458300884],
					[65.744572392277178, 7.6407749436493191],
					[65.744572392277178, 7.6407749436493191],
					[65.744572391360876, 7.6407749415157058],
					[65.743397391968202, 7.6369138432065728],
					[65.742808492432658, 7.6348666455687946],
					[65.742808487831269, 7.6348666327219732],
					[65.742808477751083, 7.6348666092563207],
					[65.742808472272301, 7.6348665986374904],
					[65.741750171965492, 7.6330748979868766],
					[65.741750168319399, 7.6330748916350339],
					[65.741750159233376, 7.6330748790262435],
					[65.74175015379349, 7.6330748727692743],
					[65.739925153587507, 7.6308609738627924],
					[65.739925148127966, 7.6308609654263613],
					[65.739925133660449, 7.6308609531042908],
					[65.739925127323545, 7.6308609468957593],
					[65.73887234867108, 7.6299942629900483],
					[65.737044571025464, 7.6273220955324605],
					[65.735497376794527, 7.6244027065076079],
					[65.735497375897651, 7.6244027065553306],
					[65.735497374981094, 7.6244027044230469],
					[65.735497374961469, 7.6244027022430583],
					[65.733761276022619, 7.6212638044096357],
					[65.732116877962937, 7.6181193087400922],
					[65.730666878809203, 7.6151971104203966],
					[65.730666859737383, 7.6151970852741666],
					[65.730666816251926, 7.6151970396290496],
					[65.730666790064262, 7.6151970214058027],
					[65.729425111221914, 7.6145720325095851],
					[65.727608444726727, 7.6130526608937688],
					[65.726069558029437, 7.6110581780853872],
					[65.726069530884942, 7.611058153380144],
					[65.72606947131375, 7.6110581151545817],
					[65.726069437113082, 7.6110581039097784],
					[65.725119504729804, 7.6108804167053909],
					[65.723775166827167, 7.6093359861934227],
					[65.722616877857121, 7.6070916084521771],
					[65.722616875106837, 7.6070916020604002],
					[65.722616868749114, 7.6070915936829007],
					[65.722616866018669, 7.6070915894700137],
					[65.720977978604722, 7.6046416079721952],
					[65.720102986996991, 7.6023832285009059],
					[65.719325193727045, 7.6003499463009812],
					[65.718822400448843, 7.5973777829775351],
					[65.718802999944074, 7.5953027974059966],
					[65.718802999903971, 7.5953027930488863],
					[65.718802998886829, 7.5953027800262296],
					[65.718802997929799, 7.5953027735392382],
					[65.718497398004786, 7.5930110736083254],
					[65.718497396090555, 7.5930110606346881],
					[65.71849738777766, 7.5930110349312692],
					[65.71849738406965, 7.5930110220551823],
					[65.717527983655486, 7.5907582206191657],
					[65.717527975422655, 7.5907582036312284],
					[65.717527952698958, 7.590758172175792],
					[65.717527940898748, 7.5907581575616998],
					[65.715994541451195, 7.5892276586806187],
					[65.71599453782342, 7.5892276545195907],
					[65.715994530588006, 7.5892276483758296],
					[65.715994526960216, 7.5892276442148017],
					[65.713889056257216, 7.5874970700078403],
					[65.712359860948155, 7.5847880757619226],
					[65.71378318632685, 7.5857779642119256],
					[65.713783225074266, 7.5857779817074604],
					[65.713783307748585, 7.5857779946231618],
					[65.713783350818971, 7.5857779944485895],
					[65.7171249779623, 7.5849058116719617],
					[65.720549849063744, 7.5865973494728154],
					[65.723502618864558, 7.5928250862462168],
					[65.723502625223063, 7.5928250946204852],
					[65.723502636166359, 7.5928251136455582],
					[65.723502643441833, 7.5928251241499831],
					[65.725899841645941, 7.5958779226772775],
					[65.730147040885598, 7.6013751228754618],
					[65.733872040668274, 7.6062695213995921],
					[65.733872046109255, 7.6062695276490215],
					[65.733872057888107, 7.6062695400995057],
					[65.733872064225977, 7.6062695463005632],
					[65.738380445850296, 7.6104668297708153],
					[65.742377631565518, 7.616630708239259],
					[65.742377637922729, 7.6166307166254423],
					[65.742377648823634, 7.6166307313133039],
					[65.742377655161036, 7.6166307375189275],
					[65.745827654673633, 7.6202584379715415],
					[65.745827661907811, 7.6202584441310037],
					[65.745827676376209, 7.6202584564499043],
					[65.745827683610372, 7.6202584626093461],
					[65.750777677053421, 7.6237862574945066],
					[65.757133161839107, 7.6291529459120824],
					[65.761108150274467, 7.6333918326042225],
					[65.766158146186029, 7.6394973278687761],
					[65.766158148915565, 7.6394973320921533],
					[65.766158157045879, 7.6394973382145324],
					[65.766158159775429, 7.6394973424379096],
					[65.769866548307363, 7.6431140302266556],
					[65.773858138245515, 7.648647317371954],
					[65.773858161777028, 7.6486473401666029],
					[65.773858216854279, 7.6486473787834219],
					[65.773858248380662, 7.6486473924223448],
					[65.779647115024375, 7.6501945838212198],
					[65.783874885181774, 7.6531362642606098],
					[65.788894246569086, 7.6566695359675245],
					[65.791297024596929, 7.661025097904969],
					[65.791297029138789, 7.6610251042263071],
					[65.791297039138513, 7.6610251190071672],
					[65.791297042764342, 7.6610251231903632],
					[65.794219242809035, 7.6647001249495936],
					[65.794219250041422, 7.6647001311329532],
					[65.794219265441242, 7.6647001478231749],
					[65.794219271776754, 7.6647001540529862],
					[65.799247071695163, 7.6688834547502376],
					[65.79924708432776, 7.6688834628434703],
					[65.79924710869615, 7.6688834790762312],
					[65.799247122206665, 7.6688834849377248],
					[65.801888822115501, 7.670000184781089],
					[65.80188883381318, 7.670000188550663],
					[65.801888858105428, 7.6700001960435475],
					[65.80188887068104, 7.6700001975811913],
					[65.807930570773749, 7.6708918979011571],
					[65.80793062008425, 7.6708918931702836],
					[65.807930710405358, 7.6708918578910099],
					[65.807930751453924, 7.6708918317149415],
					[65.813897314912836, 7.6640418720455399],
					[65.818863892845002, 7.6624224120430764],
					[65.824394291988028, 7.6646390723115116],
					[65.828369264741454, 7.6682501481015981],
					[65.834983146390684, 7.674272330560882],
					[65.838980431743352, 7.6804723090568014],
					[65.843772031652065, 7.6879112083243104],
					[65.843772040733541, 7.6879112210021132],
					[65.843772060671569, 7.6879112440770001],
					[65.843772072424997, 7.6879112544283323],
					[65.848799871864585, 7.6921057530530854],
					[65.848799888120936, 7.6921057653699254],
					[65.848799923268274, 7.6921057832977517],
					[65.848799942177962, 7.6921057910984256],
					[65.854063792058426, 7.6937029759987494],
					[65.859894234998762, 7.6997890171596426],
					[65.864699817253879, 7.7091778827083104],
					[65.867127612622838, 7.7154889721324711],
					[65.867127619011313, 7.7154889849659964],
					[65.867127630854668, 7.7154890062954289],
					[65.867127639036966, 7.7154890190392775],
					[65.871644239090784, 7.7216668175822996],
					[65.871644248151185, 7.7216668280940945],
					[65.871644268962797, 7.7216668489837819],
					[65.871644279817062, 7.7216668594063087],
					[65.875610979713613, 7.7246473594220832],
					[65.875611024741374, 7.7246473791148844],
					[65.875611118165892, 7.7246473920187535],
					[65.875611165702168, 7.7246473896582861],
					[65.882408274170672, 7.7222724207269025],
					[65.887947087801507, 7.7258001653788213],
					[65.892966584439989, 7.7293640620364332],
					[65.892966681451583, 7.7293640747879193],
					[65.892966837023693, 7.729364007837658],
					[65.892966897414354, 7.7293639324339907],
					[65.894455796707391, 7.7202056368332874],
					[65.896714096157837, 7.709702842180473],
					[65.896714096139405, 7.7097028399867007],
					[65.896714096980972, 7.7097028333603461],
					[65.896714097859459, 7.7097028311214899],
					[65.897950198312643, 7.7018556300914769],
					[65.899191897723469, 7.6940056304667639],
					[65.899191872797971, 7.6940055483292973],
					[65.899191766834434, 7.6940054330037917],
					[65.899191686693314, 7.6940053997700941],
					[65.893944386792114, 7.6943469991501772],
					[65.893944343852922, 7.6943470145016457],
					[65.893944267055588, 7.6943470579092628],
					[65.893944234112936, 7.694347088113302],
					[65.892136148584541, 7.697052367585612],
					[65.889716887761537, 7.6907360322170648],
					[65.888633489252555, 7.6875721348233528],
					[65.888633487402501, 7.6875721283359271],
					[65.888633481011681, 7.6875721154986758],
					[65.888633478264751, 7.6875721090571094],
					[65.887030796784856, 7.6844388452420933],
					[65.886422400333601, 7.6754193872749923],
					[65.886422398407333, 7.6754193720176378],
					[65.886422391901931, 7.6754193460274518],
					[65.88642238818187, 7.6754193308626562],
					[65.88318629130589, 7.666572139951894],
					[65.882369594367077, 7.6633888509966566],
					[65.882369585207201, 7.6633888295386736],
					[65.882369564215878, 7.6633887889552668],
					[65.882369550590639, 7.6633887699232428],
					[65.878655751283432, 7.6591081694896443],
					[65.878655745844654, 7.6591081631941229],
					[65.878655736799118, 7.6591081548937687],
					[65.878655732276371, 7.6591081507436014],
					[65.875222331916007, 7.6560998502992019],
					[65.87522228325372, 7.6560998265309408],
					[65.875222179900561, 7.6560998078172551],
					[65.875222126125777, 7.6560998150167903],
					[65.871299926356144, 7.6576526138028811],
					[65.871299894240835, 7.6576526352139442],
					[65.87129983907495, 7.6576526885250766],
					[65.871299816902095, 7.6576527181867453],
					[65.869238765898231, 7.6623192078547095],
					[65.86714183724294, 7.6624553912576427],
					[65.865530787907161, 7.6580360318324923],
					[65.865530782410772, 7.6580360189672572],
					[65.865530769643442, 7.6580359955215087],
					[65.865530762353274, 7.6580359827499338],
					[65.862869561861501, 7.6543331829639305],
					[65.862869506786112, 7.6543331442032105],
					[65.862869385360312, 7.6543331111086736],
					[65.862869318112999, 7.6543331168218023],
					[65.85947492371794, 7.6558526154013249],
					[65.857383229576143, 7.6566359134899056],
					[65.857383225988599, 7.6566359136773929],
					[65.85738321795499, 7.6566359184799557],
					[65.85738321438663, 7.6566359208577905],
					[65.854247113834546, 7.6581387200446001],
					[65.854247111143849, 7.6581387201850388],
					[65.854247104903962, 7.6581387248928898],
					[65.854247102213307, 7.6581387250333277],
					[65.850333269567173, 7.6603358868857745],
					[65.848228009412665, 7.6598304446043093],
					[65.848677999699987, 7.6520444110243346],
					[65.848677979371502, 7.6520443441808208],
					[65.848677900398627, 7.6520442409802438],
					[65.84867783996053, 7.6520442047172015],
					[65.846313957442803, 7.6515554077427366],
					[65.842094491255011, 7.6498970206027277],
					[65.840247349145542, 7.6487276577841961],
					[65.839180774394407, 7.6468610015146519],
					[65.839180746281627, 7.6468609679563126],
					[65.8391806785705, 7.6468609211520233],
					[65.839180639830417, 7.6468609034813024],
					[65.83681393964919, 7.6463748041029955],
					[65.836813927073464, 7.6463748025749885],
					[65.836813900128149, 7.6463747996133469],
					[65.836813886674847, 7.6463748003211123],
					[65.835769485912394, 7.64644419367706],
					[65.83364185136584, 7.6440026697656025],
					[65.833641839590101, 7.6440026572500672],
					[65.833641816096716, 7.644002638783947],
					[65.833641803443456, 7.6440026285039888],
					[65.830741811800152, 7.6422581346015166],
					[65.829155720298829, 7.6410748405035012],
					[65.829155687855618, 7.6410748247046856],
					[65.829155622169409, 7.6410748040941048],
					[65.829155587132632, 7.6410747993770451],
					[65.828369641222821, 7.6411247836800316],
					[65.830108497173057, 7.6313222351350287],
					[65.830108497991702, 7.6313222263352989],
					[65.830108499609523, 7.6313222065478552],
					[65.830108500428196, 7.6313221977481236],
					[65.830064099965952, 7.6261582984235146],
					[65.830064035423987, 7.6261582055505963],
					[65.830063859865049, 7.6261581404940406],
					[65.830063749725369, 7.626158166074533],
					[65.828238977982167, 7.6282190098322165],
					[65.827164089274291, 7.6250610368920269],
					[65.82716401958605, 7.6250609705727026],
					[65.827163858592755, 7.6250609288295284],
					[65.827163767268075, 7.6250609512179111],
					[65.82325816742042, 7.628555451483539],
					[65.8232581496395, 7.6285554699384193],
					[65.823258121311454, 7.6285555130282754],
					[65.823258109867467, 7.6285555377110086],
					[65.821730472645527, 7.6331775449511063],
					[65.820152972120226, 7.6326387463086203],
					[65.819052994944883, 7.6275471570007287],
					[65.819052942298455, 7.6275470897870976],
					[65.819052800801828, 7.6275470207445801],
					[65.819052713765032, 7.6275470210071497],
					[65.815916773088105, 7.6290497422219499],
					[65.813522386221322, 7.6253388182073207],
					[65.81188349983853, 7.6189943745541377],
					[65.811836299689944, 7.6138360984032563],
					[65.811836256784389, 7.6138360175868609],
					[65.811836124032666, 7.6138359241051727],
					[65.811836033289609, 7.6138359114881196],
					[65.807388833445529, 7.6154276116764423],
					[65.807388803986939, 7.6154276285693765],
					[65.807388754097872, 7.6154276684314794],
					[65.807388731873615, 7.6154276914970422],
					[65.806091531693582, 7.6174526918760179],
					[65.806091520271082, 7.6174527187330874],
					[65.806091503704209, 7.6174527721103837],
					[65.806091500393109, 7.6174528029062474],
					[65.806152594974279, 7.6232550991817671],
					[65.803230744424098, 7.6202276612398743],
					[65.803230722741304, 7.6202276449066462],
					[65.803230679434833, 7.6202276187973075],
					[65.803230654203915, 7.6202276070275872],
					[65.801391845742614, 7.6197081319776192],
					[65.800316884049849, 7.6171999216543584],
					[65.800316870379433, 7.6171998983360218],
					[65.800316837716508, 7.6171998585444509],
					[65.800316818704275, 7.6171998398857754],
					[65.798727918580454, 7.616016539498129],
					[65.79872785109707, 7.6160165190712554],
					[65.798727718418064, 7.6160165327544593],
					[65.798727654079812, 7.6160165624457088],
					[65.797433363101092, 7.6173968408094241],
					[65.795822399855766, 7.6136360570394404],
					[65.79580019977972, 7.6110555970398996],
					[65.795800193204087, 7.6110555646019913],
					[65.795800169270336, 7.6110554981210008],
					[65.795800151055076, 7.6110554684963647],
					[65.794205750891322, 7.6092304674116713],
					[65.794205658175215, 7.6092304352510975],
					[65.794205488843957, 7.6092304640598947],
					[65.794205413145505, 7.6092305271658187],
					[65.791899830870719, 7.6151915820254592],
					[65.790335988370089, 7.6165887310482825],
					[65.788772379711446, 7.6173385912334917],
					[65.788466899949839, 7.6128471869689687],
					[65.788466897120529, 7.6128471718225024],
					[65.788466890584857, 7.6128471437622673],
					[65.78846688687851, 7.6128471308484977],
					[65.786575186975782, 7.6078166290608502],
					[65.786575163319, 7.6078165931866542],
					[65.786575101754764, 7.6078165331343381],
					[65.786575064764023, 7.6078165110921168],
					[65.784997355824316, 7.6072776417460215],
					[65.783644588132091, 7.6035027332910179],
					[65.783644549087768, 7.6035026829620396],
					[65.783644447979484, 7.6035026163271882],
					[65.783644385915466, 7.6035026000213159],
					[65.781288994109389, 7.6036636930423755],
					[65.779427958706577, 7.6012137790304966],
					[65.779427940589287, 7.6012137603499328],
					[65.779427899930255, 7.6012137297829589],
					[65.779427877368505, 7.6012137157128929],
					[65.778105676789025, 7.6006581166408713],
					[65.778105655184049, 7.6006581090740513],
					[65.778105609363408, 7.6006581028206295],
					[65.778105586024651, 7.6006581019018133],
					[65.777322186078322, 7.6007136997867688],
					[65.775749986301236, 7.6008219990584989],
					[65.775749946041614, 7.6008220121638281],
					[65.775749872817315, 7.6008220510856823],
					[65.775749840769464, 7.600822079036937],
					[65.774710995696793, 7.6021804075555339],
					[65.773400142623061, 7.6022719900972602],
					[65.77259190756223, 7.5997471949946318],
					[65.773597393759573, 7.595811150071242],
					[65.774611294075243, 7.5918750508940782],
					[65.774611295748343, 7.5918750376966031],
					[65.774611299094616, 7.5918750113016529],
					[65.774611299870841, 7.5918749981531217],
					[65.774553000448094, 7.5860778182340853],
					[65.775925196176161, 7.5788917383681769],
					[65.775925197706115, 7.5788917098856565],
					[65.77592519179737, 7.5788916534145505],
					[65.775925185255488, 7.5788916253765706],
					[65.773636284829976, 7.57324992534702],
					[65.773636190027489, 7.5732498628754712],
					[65.773636003418048, 7.5732498666399906],
					[65.773635910714276, 7.57324993292562],
					[65.771088710665083, 7.5805166346243595],
					[65.771088708952419, 7.5805166434548505],
					[65.771088703733341, 7.5805166612144639],
					[65.771088702020663, 7.580516670044954],
					[65.7705915617138, 7.5837717770973532],
					[65.768202979709358, 7.5813609984564794],
					[65.767386295663727, 7.5781971608721355],
					[65.766822398201668, 7.5743666704064161],
					[65.76682239263728, 7.5743666510602861],
					[65.766822377900681, 7.574366610383592],
					[65.76682236785193, 7.5743665912851244],
					[65.764427968113907, 7.5706692913634024],
					[65.762025168110171, 7.5669692899576493],
					[65.762025157225011, 7.5669692774619177],
					[65.762025132743673, 7.5669692504376158],
					[65.762025119188394, 7.5669692402733935],
					[65.760444518776239, 7.5657915394821957],
					[65.76044448811902, 7.565791523719553],
					[65.760444420628872, 7.5657915034532097],
					[65.760444385610143, 7.5657915010319199],
					[65.759650048730947, 7.5658469960227119],
					[65.756752929957116, 7.5641192445729324],
					[65.755158478238741, 7.5622970998104266],
					[65.753533489914972, 7.5572582400141481],
					[65.753533429186689, 7.5572581757455355],
					[65.753533279730746, 7.5572581229807199],
					[65.753533191879356, 7.557258132252973],
					[65.750135991929241, 7.5594304324162138],
					[65.750135977661657, 7.5594304419410783],
					[65.750135952755102, 7.559430465153083],
					[65.750135941219256, 7.559430478890234],
					[65.747033141052754, 7.5635109792516193],
					[65.74703314017637, 7.5635109814823878],
					[65.747033136609431, 7.5635109838627654],
					[65.747033135733048, 7.563510986093533],
					[65.744185979221626, 7.5675748255793556],
					[65.740783406986566, 7.5684553664542129],
					[65.738633487316349, 7.5640999258202433],
					[65.736733496753885, 7.5577971617696962],
					[65.736000198990737, 7.5501221805235907],
					[65.736000189711703, 7.5501221483271266],
					[65.73600016220567, 7.550122086617205],
					[65.736000142205668, 7.5501220593842886],
					[65.734811241805872, 7.5489192581945517],
					[65.734811169746223, 7.5489192295238619],
					[65.734811026186179, 7.5489192310388233],
					[65.734810954706475, 7.548919263404315],
					[65.732208160116983, 7.5516776573127222],
					[65.729874965439592, 7.5537719633459526],
					[65.72749448508003, 7.5526526195054231],
					[65.727494460761378, 7.552652609964416],
					[65.727494411330554, 7.5526526018288482],
					[65.727494385300858, 7.5526526011052333],
					[65.726975059433542, 7.5526914943558694],
					[65.725389066336547, 7.5515165742199901],
					[65.723494587250229, 7.5465027298604479],
					[65.723494558135755, 7.5465026878950248],
					[65.723494484867572, 7.5465026266100503],
					[65.723494439775536, 7.5465026029830486],
					[65.721130639565985, 7.5460248029491712],
					[65.721130626092261, 7.546024801526606],
					[65.721130599165647, 7.5460248008601747],
					[65.721130585712714, 7.5460248016163121],
					[65.71903335506147, 7.5461747970575397],
					[65.715591830440104, 7.5438442460463371],
					[65.712141891141329, 7.540230510868918],
					[65.711569602131306, 7.5351250037810944],
					[65.712058497965131, 7.5318750310255673],
					[65.712058498652439, 7.5318750091953781],
					[65.712058497378479, 7.5318749700435044],
					[65.712058493623445, 7.531874952823558],
					[65.710961294207507, 7.5274527526623727],
					[65.710961284068901, 7.5274527249118517],
					[65.710961255804051, 7.5274526785813887],
					[65.71096123942948, 7.5274526555437316],
					[65.707252939142748, 7.5238581555065069],
					[65.705397339204225, 7.5220637561797306],
					[65.705397312087698, 7.522063735942182],
					[65.70539725172425, 7.5220637110670481],
					[65.70539722112575, 7.522063701921117],
					[65.701714055146553, 7.5216831142113421],
					[65.700630792300913, 7.5179055458160331],
					[65.700630791361775, 7.5179055415134615],
					[65.700630788607754, 7.51790553513659],
					[65.700630787689761, 7.5179055330109712],
					[65.699280787911306, 7.5141443330143529],
					[65.699280774182881, 7.5141443054873607],
					[65.699280736924322, 7.5141442575300035],
					[65.69928071518784, 7.5141442369966951],
					[65.695969543989236, 7.5118109574809697],
					[65.693839091417914, 7.5087527236353138],
					[65.692994598155607, 7.5030277703871464],
					[65.692994592582281, 7.5030277511114312],
					[65.692994579663292, 7.5030277148399787],
					[65.692994571399424, 7.5030276957197772],
					[65.691125171194287, 7.4999498969792882],
					[65.691125131326331, 7.4999498579177262],
					[65.69112503933421, 7.4999498109875979],
					[65.691124985394936, 7.4999498010467844],
					[65.688500146736544, 7.5001414897103977],
					[65.687408495612203, 7.4963638562174761],
					[65.686844597961127, 7.492549971124471],
					[65.686844561442086, 7.4925499079384714],
					[65.686844454711036, 7.4925498227131788],
					[65.686844386292719, 7.4925498005695479],
					[65.683433286256687, 7.4927970011290448],
					[65.68343323974976, 7.4927970168944418],
					[65.683433160360593, 7.4927970650466422],
					[65.683433126603063, 7.4927970996611295],
					[65.681113746210428, 7.4968220649815072],
					[65.679291696653721, 7.4982386485777495],
					[65.675322318933908, 7.4953165380304965],
					[65.673736244029698, 7.4941470561064634],
					[65.672402967036675, 7.4923165880721712],
					[65.670533471357516, 7.4892415965152601],
					[65.670533379340455, 7.4892415474851646],
					[65.670533202683458, 7.4892415599536895],
					[65.670533118000392, 7.4892416171034739],
					[65.668738718223807, 7.4932249170193179],
					[65.668738713825547, 7.4932249259769037],
					[65.668738708594944, 7.4932249415093537],
					[65.668738705990378, 7.4932249503627153],
					[65.667724805754446, 7.4971527499529094],
					[65.667724805775876, 7.4971527521270787],
					[65.6677248040465, 7.4971527587535371],
					[65.667724804067944, 7.4971527609277056],
					[65.666977643982122, 7.5010608480869196],
					[65.665139035145131, 7.5005498300108107],
					[65.663794602997257, 7.498080553820488],
					[65.664016900316312, 7.494211110243679],
					[65.664016856386908, 7.4942110236146338],
					[65.664016716322891, 7.4942109251658389],
					[65.664016618437515, 7.4942109177979601],
					[65.662037895925835, 7.4951031354399849],
					[65.657408489420874, 7.4834360342362194],
					[65.651991890858838, 7.4658249409512134],
					[65.651991843610546, 7.465824882869093],
					[65.651991720896561, 7.465824816203579],
					[65.651991646327687, 7.4658248075671656],
					[65.645705725230229, 7.4675775564789602],
					[65.643014087723813, 7.4600693325697476],
					[65.643014048632693, 7.4600692827275328],
					[65.643013946565375, 7.4600692170603633],
					[65.6430138853828, 7.4600692011290191],
					[65.637239065595111, 7.4605025874657409],
					[65.635016897056559, 7.4478249651584418],
					[65.635016816353001, 7.4478248830732356],
					[65.635016631184925, 7.4478248528580986],
					[65.635016526676125, 7.4478249003854193],
					[65.631919379831189, 7.4531996322037308],
					[65.626602962437033, 7.4459026826103933],
					[65.62660287405987, 7.4459026401124753],
					[65.626602704580677, 7.4459026524217942],
					[65.626602624353339, 7.4459027050046922],
					[65.622986120157393, 7.4525967436130216],
					[65.620833504686047, 7.4476277718566308],
					[65.622305798349672, 7.4359694245735017],
					[65.622305798082024, 7.4359693985297426],
					[65.622305791268914, 7.4359693468200936],
					[65.622305783826661, 7.435969321208157],
					[65.619622384378431, 7.4297638207913383],
					[65.619622380723925, 7.4297638144978384],
					[65.61962237612785, 7.429763803918342],
					[65.619622373392573, 7.4297637997407167],
					[65.617486272746646, 7.4260831989951122],
					[65.617486260908379, 7.4260831823418858],
					[65.617486232770119, 7.4260831514768304],
					[65.617486218263807, 7.4260831371564135],
					[65.614311234756642, 7.423769251865366],
					[65.61113077087073, 7.4201748903675968],
					[65.609519596776181, 7.416458251477879],
					[65.608908499983258, 7.4075360860797357],
					[65.608908494329839, 7.4075360603793028],
					[65.608908475848494, 7.4075360094178162],
					[65.608908463917388, 7.4075359841018393],
					[65.604652964139646, 7.4014720848878923],
					[65.604652938755294, 7.4014720604056361],
					[65.604652882696854, 7.4014720204470459],
					[65.604652852045575, 7.4014720071395281],
					[65.600966752192591, 7.400488707271327],
					[65.600966681366927, 7.40048871379628],
					[65.600966561763727, 7.4004887753980979],
					[65.600966513860186, 7.4004888282513086],
					[65.59942512125086, 7.4044547355821555],
					[65.598258498749246, 7.3917471825513257],
					[65.598258483918713, 7.3917471379048987],
					[65.598258434642432, 7.3917470606730289],
					[65.598258401990279, 7.3917470279766908],
					[65.588752902392869, 7.3861304281329572],
					[65.588752809009449, 7.3861304230789289],
					[65.588752658989407, 7.3861304931100928],
					[65.588752603249588, 7.3861305681396754],
					[65.587263713901265, 7.3952054969986749],
					[65.585699969915879, 7.3966108589073158],
					[65.581480754986217, 7.394399856356241],
					[65.58040578276993, 7.3919305199904297],
					[65.579872391185901, 7.390697138832075],
					[65.579322395134568, 7.3881860580458536],
					[65.579322353191813, 7.388185995599077],
					[65.579322238400408, 7.3881859181487854],
					[65.579322164654911, 7.388185903200764],
					[65.56568887363666, 7.3905859015257107],
					[65.559913979229748, 7.39105809290729],
					[65.550894545157746, 7.3816026620837425],
					[65.550894544237877, 7.3816026599750826],
					[65.550894543341059, 7.3816026600307483],
					[65.538169555343757, 7.3686526720898069],
					[65.529122364741696, 7.3554220867430553],
					[65.529122357450063, 7.3554220763827498],
					[65.529122338382834, 7.355422055944687],
					[65.529122328424236, 7.3554220479163837],
					[65.522239028318793, 7.3496554468882778],
					[65.522239025604875, 7.3496554448964524],
					[65.522239022890972, 7.3496554429046235],
					[65.522239020177025, 7.3496554409127981],
					[65.511666820208958, 7.3416748402667222],
					[65.511666781410227, 7.3416748211048111],
					[65.511666698668307, 7.3416748047327802],
					[65.511666654701443, 7.3416748053616505],
					[65.502755555043194, 7.3437192052556801],
					[65.502755485682542, 7.3437192636964976],
					[65.502755421796962, 7.3437194125849654],
				],
			],
		},
		{
			fldNpdidField: "43555",
			wlbNpdidWellbore: "190",
			fldName: "FRIGG",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "25/1-1",
			fldDiscoveryYear: "1971",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43555",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43555",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "903",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=190",
			fldHcType: "GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "28974935",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[59.828580400357744, 1.9726943984609444],
					[59.82858040168734, 1.9726944232426313],
					[59.828580403338265, 1.9726944372149455],
					[59.829347002884617, 1.9767556378000348],
					[59.829347004951707, 1.9767556427334765],
					[59.829347008289638, 1.9767556545590945],
					[59.829347010451663, 1.9767556612627226],
					[59.830185906392344, 1.9793778503057564],
					[59.830866503620179, 1.9826083416180198],
					[59.830866505781898, 1.9826083483223349],
					[59.830866510200202, 1.9826083635012826],
					[59.830866512361922, 1.9826083702056119],
					[59.832891505739283, 1.9880722515412246],
					[59.833705402265778, 1.9932722316194291],
					[59.833705404426965, 1.9932722383250563],
					[59.833705406872262, 1.9932722503420832],
					[59.833705409128157, 1.993272258818177],
					[59.834783106342698, 1.9968333493897505],
					[59.836572003731092, 2.0055861401358435],
					[59.836572003825644, 2.0055861419065044],
					[59.836572004811387, 2.0055861434893507],
					[59.836572004905939, 2.005586145260013],
					[59.837297004709576, 2.0087194457243251],
					[59.837297006775529, 2.0087194506610007],
					[59.837297010110696, 2.0087194624927993],
					[59.837297012176627, 2.0087194674294637],
					[59.838433108437961, 2.011872258169944],
					[59.83902760371943, 2.0142750402273748],
					[59.839785902948591, 2.018647233397409],
					[59.839785904122927, 2.0186472385225867],
					[59.839785906377244, 2.0186472470020385],
					[59.839785906565965, 2.0186472505438151],
					[59.841060906769812, 2.0234583518168443],
					[59.841060907849666, 2.0234583551714085],
					[59.841060910103685, 2.0234583636515309],
					[59.841060911183547, 2.0234583670060946],
					[59.842694210912676, 2.0280833677266163],
					[59.842694211803945, 2.0280833675393493],
					[59.842694211803945, 2.0280833675393493],
					[59.846002611696306, 2.0372306672151246],
					[59.846002611790418, 2.0372306689864295],
					[59.846002612775813, 2.0372306705706693],
					[59.846002612775813, 2.0372306705706693],
					[59.848235912058492, 2.0431250681988504],
					[59.850363710855106, 2.04922226645657],
					[59.850363711840387, 2.049222268041373],
					[59.850363712919602, 2.0492222713977615],
					[59.850363713998831, 2.0492222747541282],
					[59.851699808344208, 2.052597259039564],
					[59.852913704869728, 2.0579222445693284],
					[59.852913709091965, 2.0579222562252988],
					[59.852913717630237, 2.0579222813089482],
					[59.852913722837634, 2.057922294550103],
					[59.853760921202358, 2.0595139901097648],
					[59.855255418841885, 2.0626917857960487],
					[59.858647018842191, 2.0698917843350975],
					[59.858647019920916, 2.069891787693007],
					[59.858647022876006, 2.0698917924504534],
					[59.858647023954738, 2.0698917958083638],
					[59.860602623992598, 2.0735056950885782],
					[59.860602624086241, 2.0735056968607761],
					[59.860602624977602, 2.0735056966745891],
					[59.860602624977602, 2.0735056966745891],
					[59.862949825177097, 2.0777611973127668],
					[59.862949826162051, 2.0777611988990161],
					[59.862949828225531, 2.0777612038438753],
					[59.86294983010184, 2.0777612052440331],
					[59.865110921129265, 2.0812833915036095],
					[59.867094214899126, 2.0861389747267971],
					[59.868483109158007, 2.0896222613539019],
					[59.869460904691735, 2.0934944433280838],
					[59.870605401269735, 2.0993361234760575],
					[59.870897000227053, 2.1050306098668394],
					[59.870897000320298, 2.1050306116397022],
					[59.870897000320298, 2.1050306116397022],
					[59.871272000308622, 2.1114528122098508],
					[59.871272000401774, 2.1114528139827597],
					[59.871272001386338, 2.1114528155704155],
					[59.871747001278735, 2.1178806143268361],
					[59.871747000666481, 2.1178806198308124],
					[59.871747003100751, 2.1178806318713743],
					[59.871747003379923, 2.1178806371902521],
					[59.872799802858069, 2.1235139371288532],
					[59.872799803842504, 2.1235139387169677],
					[59.872799803842504, 2.1235139387169677],
					[59.872799803935486, 2.1235139404900027],
					[59.874097003819777, 2.1298833382475832],
					[59.875102603252508, 2.1352056367357086],
					[59.875913703416728, 2.1396889332554343],
					[59.876710901577916, 2.1448972294469124],
					[59.876710902654786, 2.1448972328091673],
					[59.876710903824367, 2.1448972379447588],
					[59.876710903917058, 2.1448972397180825],
					[59.8781026028132, 2.1516889368142702],
					[59.879522003379016, 2.1601361337642704],
					[59.879522003379016, 2.1601361337642704],
					[59.879522003471507, 2.1601361355377984],
					[59.879522002579982, 2.1601361357218112],
					[59.880860902952612, 2.1675417356955866],
					[59.880860904213748, 2.167541742606296],
					[59.880860906551177, 2.1675417528804464],
					[59.880860908703816, 2.1675417596073241],
					[59.882327608608762, 2.1722722595346338],
					[59.882327616756733, 2.1722722775760004],
					[59.88232763474339, 2.1722723115175979],
					[59.882327646365141, 2.1722723270504267],
					[59.884752645695528, 2.1752001280114484],
					[59.884752648554745, 2.1752001310082445],
					[59.884752656148535, 2.1752001384084312],
					[59.884752659007759, 2.1752001414052429],
					[59.88662205885332, 2.1770612414379937],
					[59.886622130700474, 2.1770612714659245],
					[59.886622272948181, 2.1770612690654669],
					[59.886622344332594, 2.1770612382274344],
					[59.888130743645071, 2.175494539204363],
					[59.888130752990506, 2.1754945283138456],
					[59.888130769806011, 2.1754945051260814],
					[59.888130777276061, 2.1754944928288404],
					[59.889336262740926, 2.173186219808072],
					[59.890016674346711, 2.1725031097155525],
					[59.891166515761768, 2.1741194864826072],
					[59.892969203152028, 2.1809361384240731],
					[59.893752600989984, 2.1873861163612847],
					[59.894066500417821, 2.1944305990681734],
					[59.893716500552721, 2.2006110816424123],
					[59.893202602362173, 2.2046082735590966],
					[59.8932026006065, 2.2046082918521268],
					[59.893202603336292, 2.2046083271577346],
					[59.893202605147003, 2.2046083447190856],
					[59.893591505390781, 2.2062861444584927],
					[59.893591526595245, 2.2062861885283436],
					[59.893591586101032, 2.2062862588148651],
					[59.893591625293901, 2.2062862848486668],
					[59.895372108236202, 2.2070084781337367],
					[59.897147043426905, 2.2081445371897792],
					[59.89833870717932, 2.2106944589314299],
					[59.899280401375201, 2.216533324542532],
					[59.899899798658538, 2.2238027965665341],
					[59.899085901264385, 2.2307832767027302],
					[59.899085901355946, 2.2307832784775625],
					[59.899085900647435, 2.230783282209492],
					[59.899085900647435, 2.230783282209492],
					[59.898591501251389, 2.2367471732426343],
					[59.897305403037848, 2.2441110659592174],
					[59.897305402420351, 2.2441110714654684],
					[59.897305401185299, 2.2441110824780131],
					[59.897305399676128, 2.2441110881661555],
					[59.897099799782119, 2.2476110872236199],
					[59.897099800056118, 2.2476110925479902],
					[59.897099799621131, 2.2476111016037512],
					[59.897099799803811, 2.2476111051533456],
					[59.897269200054311, 2.2535139064136951],
					[59.897269200145551, 2.2535139081885145],
					[59.897269200419323, 2.2535139135129936],
					[59.897269201402253, 2.2535139151061778],
					[59.897744201022292, 2.2595333156666744],
					[59.89774421203623, 2.2595333546739096],
					[59.897744249932309, 2.2595334258720037],
					[59.897744275831506, 2.2595334564694562],
					[59.900430460607808, 2.2616584441978054],
					[59.902799815904693, 2.2643806935384112],
					[59.904005404348801, 2.2697250386710124],
					[59.904897002983205, 2.2751528326914046],
					[59.904897003074169, 2.2751528344667116],
					[59.904897004329776, 2.2751528413868281],
					[59.904897005403434, 2.2751528447563452],
					[59.9059081054316, 2.2796583442551155],
					[59.905908106686979, 2.2796583511756241],
					[59.905908110981194, 2.2796583646546731],
					[59.905908113128284, 2.2796583713942011],
					[59.907194213086733, 2.283041770313226],
					[59.907194227024917, 2.2830417961877401],
					[59.907194261762513, 2.2830418411630591],
					[59.907194282652817, 2.2830418620392967],
					[59.908916582740311, 2.2842834617004324],
					[59.908916654822853, 2.2842834793695328],
					[59.908916791712855, 2.284283460569938],
					[59.908916857412052, 2.2842834239203276],
					[59.909852957336156, 2.283100124203743],
					[59.90985296962176, 2.2831001001824558],
					[59.909852990626007, 2.2831000528636123],
					[59.90985299827117, 2.2831000261958119],
					[59.910889097850898, 2.2755139294418791],
					[59.911911298134648, 2.2688556300543383],
					[59.911911297952528, 2.2688556265030035],
					[59.911911298571034, 2.2688556209946968],
					[59.911911299280625, 2.268855617262048],
					[59.912394597590321, 2.2636083303266235],
					[59.91361129373761, 2.2578917495442301],
					[59.914739092120449, 2.2541361586565292],
					[59.914739094156658, 2.2541361456809601],
					[59.914739099120723, 2.25413611954811],
					[59.914739100356499, 2.2541361085300666],
					[59.914989100231686, 2.2475333076839936],
					[59.914989099866283, 2.247533300580999],
					[59.91498910020983, 2.2475332897445917],
					[59.914989098952752, 2.2475332828235408],
					[59.914458499464352, 2.241299983705086],
					[59.914458499098615, 2.2412999766022952],
					[59.914458496675259, 2.2412999645366054],
					[59.914458496309507, 2.2412999574338173],
					[59.912733496060405, 2.2332360572081504],
					[59.912733493910949, 2.2332360504704356],
					[59.912733489612052, 2.2332360369950242],
					[59.912733487371042, 2.2332360284817416],
					[59.911100192357594, 2.2289055412148828],
					[59.909700195987199, 2.2227249577219941],
					[59.908264099932957, 2.2156110778403715],
					[59.908155800216484, 2.2091944118665996],
					[59.909464095892702, 2.1999667418750182],
					[59.910694591039416, 2.1957000587933955],
					[59.912361279630737, 2.1904194937976222],
					[59.915494541122506, 2.1862557446562314],
					[59.919947316364514, 2.1831001629300215],
					[59.919947321529364, 2.1831001582763232],
					[59.919947332750588, 2.1831001487852237],
					[59.919947338806992, 2.1831001439478337],
					[59.924000139162779, 2.1791945444855005],
					[59.924000154379968, 2.1791945251928411],
					[59.924000180172101, 2.1791944839746678],
					[59.924000189855491, 2.1791944622330028],
					[59.925814090056868, 2.1736111617113698],
					[59.925814090856058, 2.1736111597513266],
					[59.925814092362046, 2.1736111540551799],
					[59.925814093161208, 2.1736111520951416],
					[59.926789091368839, 2.1700444596134396],
					[59.928066888982308, 2.1662917639330028],
					[59.92806689199363, 2.1662917525393151],
					[59.928066897217207, 2.1662917317122847],
					[59.928066899336997, 2.1662917205027821],
					[59.928600199320996, 2.1609472192140049],
					[59.928600199043395, 2.1609472138855343],
					[59.928600200086144, 2.1609471993076057],
					[59.92860019971603, 2.160947192202979],
					[59.92840849983758, 2.1562805922022248],
					[59.928408498575713, 2.1562805852821838],
					[59.928408497742353, 2.1562805692970515],
					[59.92840849737199, 2.1562805621925385],
					[59.927505799253261, 2.1514721756381796],
					[59.927219599907453, 2.146180596143167],
					[59.927308499949547, 2.1391556095184074],
					[59.927697398175802, 2.1337972323351808],
					[59.929044587935508, 2.1286000735894666],
					[59.931252956401686, 2.124480732234113],
					[59.934872296777442, 2.1216834782762413],
					[59.938894394760247, 2.1202502135260746],
					[59.941541593233012, 2.1213279725374536],
					[59.945541567771834, 2.1248612508427085],
					[59.945541574291006, 2.1248612548753631],
					[59.945541587422319, 2.1248612647176208],
					[59.945541593050045, 2.1248612689357333],
					[59.947877667327788, 2.126336251715137],
					[59.950702647142293, 2.1297001283462138],
					[59.950702661350697, 2.129700141560908],
					[59.950702690566004, 2.1297001660277037],
					[59.950702707355795, 2.1297001769091009],
					[59.952891607216415, 2.1308557771064081],
					[59.952891674391907, 2.1308557864967987],
					[59.952891799194404, 2.1308557605494025],
					[59.952891856728435, 2.13085572343428],
					[59.956800157160266, 2.1259001236496324],
					[59.956800162229804, 2.1259001172040373],
					[59.956800172275827, 2.1259001025352919],
					[59.956800176360893, 2.1259000944976685],
					[59.959008475823097, 2.1217778940799272],
					[59.959008480706238, 2.1217778840779955],
					[59.959008490286337, 2.12177786051882],
					[59.959008494278059, 2.1217778507025016],
					[59.96100578898843, 2.1140222666738291],
					[59.964439069156278, 2.1065501101252657],
					[59.968233440790229, 2.1023057417560982],
					[59.972794524592345, 2.0983112570088327],
					[59.977872308583834, 2.0946529675913235],
					[59.983030694635907, 2.0916195756570994],
					[59.985305562026063, 2.0905002412478484],
					[59.987222060228014, 2.0923668436285396],
					[59.990666551342265, 2.0957668336921356],
					[59.99369154418887, 2.0995584245068346],
					[59.993691549019445, 2.0995584306920665],
					[59.99369156046339, 2.0995584426895331],
					[59.993691565200542, 2.0995584470953523],
					[59.996472065414828, 2.1020945484566389],
					[59.996472084083038, 2.1020945607440296],
					[59.996472123108838, 2.1020945831663602],
					[59.996472144264438, 2.1020945913352893],
					[59.99999164356511, 2.1031195917372134],
					[59.999991693245079, 2.1031195939415572],
					[59.999991786747408, 2.103119572585995],
					[59.99999183066317, 2.1031195508058196],
					[60.002016831414196, 2.1013612508357453],
					[60.002016850090641, 2.1013612289341097],
					[60.002016880923627, 2.1013611810967423],
					[60.002016892986759, 2.1013611533811312],
					[60.003252993365024, 2.096972253655824],
					[60.003252993178108, 2.0969722500959991],
					[60.00325299468048, 2.096972244382989],
					[60.003252994587022, 2.0969722426030759],
					[60.004725194525498, 2.0902111423483571],
					[60.004725196214665, 2.0902111401947527],
					[60.004725196825369, 2.0902111346680332],
					[60.004725198421006, 2.0902111307344451],
					[60.00550849720495, 2.0852806346474582],
					[60.00693079461135, 2.0781000475406661],
					[60.00816689162135, 2.0738139549190207],
					[60.008166894717796, 2.0738139452694084],
					[60.008166897955313, 2.0738139211917845],
					[60.008166900160425, 2.0738139117294594],
					[60.008366900342168, 2.0703000113166583],
					[60.008366898231117, 2.0702999883628759],
					[60.008366889552377, 2.070299943392206],
					[60.008366883876015, 2.0702999211879534],
					[60.00732518373804, 2.0678582225628679],
					[60.007325176654078, 2.0678582078547203],
					[60.007325159906053, 2.0678581807807821],
					[60.007325150242004, 2.0678581684149906],
					[60.006366864626834, 2.066772084510665],
					[60.00346409675479, 2.0614305423830963],
					[60.003080799765939, 2.0553999877785216],
					[60.003080799389849, 2.0553999806595193],
					[60.003080797840397, 2.0553999683890201],
					[60.003080796667042, 2.0553999632375066],
					[60.002072397261145, 2.0499555641575933],
					[60.002072395102182, 2.0499555574146546],
					[60.002072390784292, 2.0499555439287902],
					[60.002072388531246, 2.0499555354061791],
					[60.000494589398947, 2.0453110364195415],
					[60.000494577197131, 2.0453110102023371],
					[60.000494546039867, 2.0453109663901334],
					[60.000494526896119, 2.0453109452360323],
					[59.997394527296279, 2.0427637448642053],
					[59.997394495768539, 2.0427637281254256],
					[59.997394429618886, 2.0427637042969873],
					[59.997394395185353, 2.0427637007661392],
					[59.993452794754582, 2.0428636996527003],
					[59.993452762668674, 2.0428637064209805],
					[59.99345270164163, 2.042863728289432],
					[59.993452673685937, 2.0428637449807843],
					[59.990702673589723, 2.0451026454252901],
					[59.990702665850662, 2.0451026524537617],
					[59.99070265135795, 2.0451026681018303],
					[59.990702642727619, 2.0451026753182391],
					[59.988255442579209, 2.048183175285355],
					[59.988255441782037, 2.0481831772521035],
					[59.98825544009356, 2.0481831794066796],
					[59.988255439296381, 2.0481831813734241],
					[59.985394238750665, 2.0520720801559218],
					[59.985394237156179, 2.0520720840888882],
					[59.985394232090549, 2.0520720905514556],
					[59.985394229604779, 2.052072094672122],
					[59.983138735871336, 2.0556804847704413],
					[59.981055455838671, 2.05836376053113],
					[59.979397115701495, 2.0597248106651915],
					[59.977350044385126, 2.0600275901265874],
					[59.974652934607931, 2.0583304485307563],
					[59.972986255281967, 2.0562748732660019],
					[59.972986253311397, 2.0562748700846991],
					[59.972986247681632, 2.0562748658752508],
					[59.972986244819758, 2.0562748628814407],
					[59.970616851911103, 2.0537665702575811],
					[59.969102958241528, 2.0518220768199593],
					[59.969102940178459, 2.0518220590490626],
					[59.969102900769265, 2.0518220295913854],
					[59.969102879329093, 2.0518220161266552],
					[59.967472278813062, 2.0511165169298833],
					[59.967472260422902, 2.0511165100147388],
					[59.967472221250844, 2.0511165020806739],
					[59.96747220126619, 2.0511164990963628],
					[59.965372201294436, 2.0511053991272012],
					[59.965372164751365, 2.0511054068177348],
					[59.965372095888739, 2.0511054338924675],
					[59.965372065351751, 2.0511054529015249],
					[59.963452665080567, 2.0528665535841277],
					[59.96345265476107, 2.0528665629447187],
					[59.963452637078014, 2.0528665864362323],
					[59.96345262774382, 2.0528665973869407],
					[59.961358128332236, 2.0563804974193216],
					[59.961358122562949, 2.0563805076187149],
					[59.961358112900946, 2.0563805294202111],
					[59.96135810891429, 2.0563805392447989],
					[59.959805411359433, 2.0612666348655808],
					[59.958747029260685, 2.064105485345741],
					[59.957185998548397, 2.0655748206552396],
					[59.954102752705616, 2.0664442042080675],
					[59.949269448953302, 2.0674247861425457],
					[59.943844562869344, 2.0633054726789997],
					[59.94260298260221, 2.0604471194765854],
					[59.942602976690949, 2.0604471099403332],
					[59.942602963695407, 2.060447085725392],
					[59.942602956892806, 2.0604470763762981],
					[59.938569559874303, 2.0553692795308773],
					[59.935750162695527, 2.0513887840638811],
					[59.93575016171021, 2.0513887824750991],
					[59.935750160724901, 2.0513887808863198],
					[59.93162236313465, 2.0457915820760615],
					[59.929000168251648, 2.0420331913854728],
					[59.926147372358649, 2.0372276974120527],
					[59.92614737146738, 2.0372276975997021],
					[59.92614737038771, 2.0372276942362402],
					[59.923430775189495, 2.0328443035007613],
					[59.92138908091453, 2.0287055157347891],
					[59.919097385398103, 2.0236221236909797],
					[59.916861293295476, 2.017713844800622],
					[59.91589459621968, 2.0131166589080038],
					[59.915894591898692, 2.0131166454615257],
					[59.915894581379746, 2.0131166171700747],
					[59.915894575276283, 2.0131166041000208],
					[59.914491875038308, 2.0105638043344829],
					[59.914491853729615, 2.0105637765295268],
					[59.914491801781487, 2.010563731864877],
					[59.914491772924507, 2.0105637146285966],
					[59.912041781992691, 2.0096081165734323],
					[59.909552928663047, 2.008341541466423],
					[59.907633455783859, 2.0059665741134207],
					[59.907633451935226, 2.0059665695434643],
					[59.907633444237895, 2.0059665604035426],
					[59.907633439592622, 2.0059665577963672],
					[59.905858439652498, 2.0042220567593998],
					[59.905858385975129, 2.0042220304241978],
					[59.905858271411411, 2.0042220115758891],
					[59.905858209728457, 2.0042220210255435],
					[59.904363810395552, 2.0049720201448462],
					[59.904363793044304, 2.0049720327832126],
					[59.904363761015404, 2.0049720574949328],
					[59.904363747323636, 2.0049720711541799],
					[59.900885947061965, 2.0091331719171972],
					[59.900885945279526, 2.0091331722936139],
					[59.900885942794957, 2.0091331764064586],
					[59.90088594199829, 2.0091331783687876],
					[59.898280442032259, 2.0125081775666764],
					[59.898280441141019, 2.0125081777547824],
					[59.898280441235521, 2.0125081795287589],
					[59.89624424117315, 2.0151915794275097],
					[59.896244233624344, 2.0151915899890498],
					[59.896244221294886, 2.0151916123219626],
					[59.896244214731801, 2.0151916244693622],
					[59.894919222665159, 2.0184277040362693],
					[59.893613746585999, 2.0203248692565317],
					[59.891955517639659, 2.0218915027065476],
					[59.890724999542385, 2.0219275995896759],
					[59.8855083056946, 2.0217580998984284],
					[59.885508297578994, 2.0217580998149054],
					[59.885508281442128, 2.0217581014212351],
					[59.885508273326536, 2.0217581013377264],
					[59.8817028159744, 2.0222803957313999],
					[59.880075083618173, 2.0217831155924433],
					[59.877377950086057, 2.0200942571569347],
					[59.875419590027455, 2.0165832300388358],
					[59.87475019616307, 2.0132443617271312],
					[59.874750196068597, 2.0132443599544061],
					[59.87475019597413, 2.0132443581816819],
					[59.87475019597413, 2.0132443581816819],
					[59.874172395759544, 2.0105305577130235],
					[59.874172392424313, 2.0105305458681415],
					[59.874172383971406, 2.0105305225543124],
					[59.874172378759241, 2.01053050931269],
					[59.873369578598002, 2.0089416102035895],
					[59.87336953012062, 2.0089415630676855],
					[59.873369413856018, 2.0089415123061682],
					[59.873369346865459, 2.008941506719895],
					[59.870538875409324, 2.0097220005280128],
					[59.867466704202663, 2.0096664992000548],
					[59.867466691630952, 2.0096665000589726],
					[59.867466669255734, 2.0096665029852998],
					[59.867466657575221, 2.0096665036562618],
					[59.864383262926346, 2.010330402854835],
					[59.861513913679694, 2.0108025944974255],
					[59.85905569370486, 2.0100554193518434],
					[59.856219540175069, 2.007844256686611],
					[59.854352968416457, 2.0055804884461628],
					[59.851800182649328, 2.0005999178874672],
					[59.849866887009192, 1.9955443283108654],
					[59.849866885037414, 1.9955443251445613],
					[59.849866881890392, 1.9955443168524465],
					[59.849866879918601, 1.9955443136861628],
					[59.846764080069313, 1.9890943133858525],
					[59.84676407701668, 1.9890943068662912],
					[59.846764069129009, 1.9890942942038183],
					[59.846764066076361, 1.9890942876842426],
					[59.843650167371791, 1.9844998899745927],
					[59.840686268191845, 1.9799137901479511],
					[59.84068626631462, 1.9799137887542415],
					[59.840686262370362, 1.9799137824252366],
					[59.84068626039825, 1.9799137792607397],
					[59.838516874786315, 1.9770166007828014],
					[59.837566885054677, 1.9746999247647563],
					[59.837566879934194, 1.9746999133138021],
					[59.837566868005815, 1.9746998925598138],
					[59.837566861102992, 1.9746998814861545],
					[59.835352961418025, 1.9716970806905558],
					[59.835352959540728, 1.9716970792975181],
					[59.832927960310755, 1.968483179248715],
					[59.83292795458371, 1.9684831733000829],
					[59.832927946884283, 1.9684831641883656],
					[59.832927942143428, 1.9684831598213002],
					[59.832069542221454, 1.9676165596136057],
					[59.83206952612143, 1.9676165451204033],
					[59.832069489750737, 1.9676165223888336],
					[59.832069471262386, 1.9676165137728883],
					[59.83049177119063, 1.9670165129021011],
					[59.830491654128842, 1.9670165484457729],
					[59.830491541843124, 1.9670167065108239],
					[59.830491546619186, 1.9670168290322276],
					[59.830944001064303, 1.967555435208671],
					[59.829511081391395, 1.9676887017615996],
					[59.829511030179667, 1.9676887215616672],
					[59.82951094611375, 1.9676887841272106],
					[59.829510913449596, 1.9676888304329867],
					[59.828824813101015, 1.9695110307155614],
					[59.828824809215334, 1.9695110422799207],
					[59.828824802620112, 1.9695110705302656],
					[59.828824800706762, 1.9695110852574234],
					[59.828580401475271, 1.972694385692827],
					[59.828580400357744, 1.9726943984609444],
				],
			],
		},
		{
			fldNpdidField: "43457",
			wlbNpdidWellbore: "318",
			fldName: "SLEIPNER VEST",
			fldCurrentActivitySatus: "Producing",
			wlbName: "15/6-3",
			fldDiscoveryYear: "1974",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43457",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43457",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "SLEIPNER VEST UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=318",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "40848",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Dusavik",
			fldAreaGeometryWKT: [
				[
					[58.389555400607605, 1.6639111177202661],
					[58.389624800832507, 1.6646806172124784],
					[58.389624803590557, 1.6646806338036622],
					[58.389624811681315, 1.6646806647318291],
					[58.389624817111638, 1.6646806807651213],
					[58.390091517171307, 1.6657417808995247],
					[58.390091521222566, 1.6657417886374404],
					[58.390091531204227, 1.665741805437722],
					[58.390091536243872, 1.6657418146860339],
					[58.390616515845501, 1.66649448574708],
					[58.390949806700256, 1.6677167522484417],
					[58.390949807786242, 1.6677167554553298],
					[58.39094980887225, 1.6677167586621988],
					[58.39094980896985, 1.6677167603585827],
					[58.391483108632407, 1.6693944592293175],
					[58.391949808678255, 1.6709222576514393],
					[58.392410906089374, 1.6724472485650759],
					[58.392680400201023, 1.6738278204396217],
					[58.392685899793889, 1.6752167009984653],
					[58.392685900671573, 1.6752167162669487],
					[58.392685907075823, 1.6752167492683347],
					[58.392685910625843, 1.6752167639796627],
					[58.393616501873808, 1.677961137303869],
					[58.393688699988608, 1.6795028042164173],
					[58.393694200429579, 1.6812028007943118],
					[58.393694199928568, 1.6812028077661862],
					[58.393694200708083, 1.6812028213387453],
					[58.393694202086074, 1.6812028296360237],
					[58.393897002050373, 1.6825861288633679],
					[58.393897015968825, 1.6825861671693212],
					[58.393897058656982, 1.6825862355371235],
					[58.393897088317459, 1.6825862654134445],
					[58.394752687796583, 1.6831668650699303],
					[58.394752768928257, 1.6831668807928624],
					[58.394752913738159, 1.6831668437633642],
					[58.394752978404668, 1.6831667925220362],
					[58.395077978285244, 1.6825361911324417],
					[58.395077983741423, 1.6825361762603954],
					[58.395077993665517, 1.6825361450052183],
					[58.395077998230867, 1.6825361303187287],
					[58.395333498344939, 1.6808250301015226],
					[58.395333498748506, 1.6808250214326728],
					[58.395333499750492, 1.6808250074882718],
					[58.395333500154052, 1.6808249988194457],
					[58.395322399881756, 1.6785055986135677],
					[58.395314100092222, 1.6768056421572715],
					[58.396027980784197, 1.6752306843941724],
					[58.396805753824367, 1.6736501413930165],
					[58.398177903948742, 1.6728195716738776],
					[58.398177913454589, 1.6728195645395245],
					[58.398177932466282, 1.6728195502708374],
					[58.398177940983793, 1.6728195416255793],
					[58.399483395982465, 1.671527986924555],
					[58.40072223271401, 1.6713195970946317],
					[58.400722238058627, 1.671319595979337],
					[58.400722247857097, 1.6713195939346361],
					[58.400722252310956, 1.671319593005232],
					[58.40275281879839, 1.6707696019745115],
					[58.404061084630065, 1.6708668998046881],
					[58.404061096307657, 1.6708668990847837],
					[58.404061116099768, 1.6708668983886716],
					[58.404061126886582, 1.6708668978546899],
					[58.405761126764212, 1.6706390976812644],
					[58.405761149924182, 1.6706390928469439],
					[58.405761193974634, 1.6706390750646216],
					[58.405761214962723, 1.6706390638137163],
					[58.406936215074715, 1.6698168636972619],
					[58.406936229632102, 1.6698168503528741],
					[58.406936257660931, 1.669816820455746],
					[58.406936270339209, 1.6698168057861087],
					[58.407719560982102, 1.6685473205875936],
					[58.40960573971811, 1.6664584431656206],
					[58.411105731330011, 1.6651612503753528],
					[58.411105734697706, 1.665161246236214],
					[58.411105744203013, 1.6651612390969326],
					[58.411105747570701, 1.6651612349577904],
					[58.412277947713044, 1.6638751351727816],
					[58.412277952666813, 1.6638751272662613],
					[58.412277962769743, 1.6638751148480273],
					[58.412277967821176, 1.6638751086389119],
					[58.412994556102781, 1.6627640266233965],
					[58.413647301752782, 1.6621168807765001],
					[58.415086118379875, 1.6617474013392399],
					[58.416458285977008, 1.6618446005536978],
					[58.416458317348706, 1.6618445974306537],
					[58.416458377724204, 1.661844581371464],
					[58.416458406728069, 1.6618445684353385],
					[58.418288961597163, 1.6606862986756308],
					[58.419205587183782, 1.6606474006167913],
					[58.419924966165986, 1.6607723975453546],
					[58.419924977050414, 1.660772398705419],
					[58.419924997830798, 1.6607723995140655],
					[58.419925008715225, 1.6607724006741202],
					[58.420647209178163, 1.6607391002962371],
					[58.420647249957014, 1.6607390883311557],
					[58.420647322716128, 1.6607390524953181],
					[58.420647355489358, 1.660739026740476],
					[58.421555735697765, 1.6596223505410974],
					[58.423316764264769, 1.6584668972763443],
					[58.425086107777588, 1.6583919008443981],
					[58.425086151228363, 1.6583918883155395],
					[58.425086228146967, 1.6583918464437279],
					[58.425086260724065, 1.658391817287181],
					[58.426255761445177, 1.6567973184445552],
					[58.426255775316363, 1.6567972932006121],
					[58.426255793857941, 1.6567972394829105],
					[58.426255800309839, 1.6567972106363085],
					[58.426375199396944, 1.6546333145818393],
					[58.426497398660388, 1.6533917207030149],
					[58.426497398768383, 1.6533917069321369],
					[58.426497399180036, 1.6533916827865172],
					[58.426497398397288, 1.6533916692021646],
					[58.425822397822593, 1.6489443699736324],
					[58.425822389032504, 1.6489443426007688],
					[58.425822366205892, 1.6489442906728529],
					[58.425822349595094, 1.6489442683756634],
					[58.424769549896993, 1.647752667615253],
					[58.424769507660464, 1.647752638659941],
					[58.424769416639641, 1.6477526078982634],
					[58.424769365878092, 1.6477526030693053],
					[58.42235278779475, 1.6481636992067419],
					[58.420711110732071, 1.6480747995318954],
					[58.42071108470536, 1.6480748015482063],
					[58.420711035715698, 1.6480748118119724],
					[58.420711011862032, 1.6480748202460274],
					[58.419341612103416, 1.648749820900788],
					[58.419341586957721, 1.648749838195563],
					[58.419341543390907, 1.648749879967413],
					[58.419341524079044, 1.648749904631083],
					[58.418305424641687, 1.6506471026472676],
					[58.417133170096754, 1.6527026241149838],
					[58.415436073949053, 1.6530831004929456],
					[58.414322191795371, 1.6531303995388773],
					[58.414322181997328, 1.6531304015897132],
					[58.414322162303371, 1.6531304039939247],
					[58.414322151614584, 1.6531304062311969],
					[58.412819351721609, 1.6535026054339643],
					[58.412819327074544, 1.6535026157458537],
					[58.412819280843962, 1.6535026425999226],
					[58.412819259162596, 1.6535026574447194],
					[58.411841596322688, 1.6544720200933252],
					[58.410533303413224, 1.6548358917116031],
					[58.409222260004285, 1.654424809553978],
					[58.409222232695342, 1.6544248049617851],
					[58.409222179359475, 1.6544248023798791],
					[58.409222151648869, 1.6544248064601268],
					[58.407719371349266, 1.6547970012612641],
					[58.406474992030518, 1.6548498007943084],
					[58.406474980353153, 1.6548498015196198],
					[58.406474957986909, 1.6548498044810198],
					[58.406474946407343, 1.654849806903421],
					[58.405166646483906, 1.6552137076449471],
					[58.405166607183077, 1.6552137296055514],
					[58.40516654104119, 1.6552137863778147],
					[58.405166515286446, 1.655213824397181],
					[58.404583139396252, 1.6566304653642958],
					[58.403013793172143, 1.6576220309385665],
					[58.403013774260131, 1.6576220469144403],
					[58.403013739401651, 1.6576220833982507],
					[58.403013724345882, 1.6576221037199197],
					[58.402235973089482, 1.6590470144879268],
					[58.400605573577863, 1.6592692022065112],
					[58.398769374804246, 1.659502602740631],
					[58.398769367580542, 1.6595026025332207],
					[58.398769355110076, 1.6595026051395438],
					[58.398769347984107, 1.6595026066288687],
					[58.397397170348277, 1.659869200104118],
					[58.395236092221886, 1.6599608991194816],
					[58.393666731939902, 1.6600247988138868],
					[58.392552870460321, 1.6596081133570002],
					[58.392552825043374, 1.6596081073949853],
					[58.392552736272947, 1.6596081156420524],
					[58.392552693017187, 1.6596081315475641],
					[58.391769315037735, 1.6601054167323379],
					[58.390655538566591, 1.6604609083808151],
					[58.390655500350718, 1.6604609335319116],
					[58.390655435389633, 1.6604609951718803],
					[58.390655410621349, 1.6604610346812696],
					[58.390010911381211, 1.6623443344745554],
					[58.390010909697388, 1.6623443365429402],
					[58.390010909099679, 1.6623443418179251],
					[58.390010908306614, 1.6623443437002949],
					[58.389555408085535, 1.6639110440575622],
					[58.389555403717644, 1.6639110621365587],
					[58.389555401314823, 1.6639110986889163],
					[58.389555400607605, 1.6639111177202661],
				],
				[
					[58.354141500748533, 1.7044278100682602],
					[58.354141501816549, 1.7044278287106409],
					[58.354547001856893, 1.7071778288736739],
					[58.354547004220912, 1.7071778386731329],
					[58.354547008948998, 1.707177858272056],
					[58.354547012203888, 1.7071778678868743],
					[58.355066512264742, 1.7086167682422269],
					[58.355066534095343, 1.7086168031616831],
					[58.355066590634131, 1.7086168617573172],
					[58.355066626233153, 1.7086168852488732],
					[58.356183205135402, 1.7090612773821443],
					[58.35730266012564, 1.709836247173786],
					[58.358519236167702, 1.7114195154889251],
					[58.359747031008553, 1.7133445086480932],
					[58.359747034862913, 1.7133445129951959],
					[58.359747042668651, 1.7133445233844267],
					[58.359747046620008, 1.7133445294265885],
					[58.361227606177742, 1.7150944802143797],
					[58.361419201044512, 1.7172194174068429],
					[58.36141920123837, 1.7172194207971552],
					[58.361419202420024, 1.7172194256982007],
					[58.361419201723024, 1.7172194292729501],
					[58.36206920059076, 1.7216250230832812],
					[58.362310901281525, 1.7246667154849831],
					[58.362310906297552, 1.7246667401763986],
					[58.362310921674869, 1.7246667884536788],
					[58.362310933817945, 1.7246668116710044],
					[58.36444153361559, 1.727855711747095],
					[58.364441634303731, 1.7278557543976367],
					[58.364441815444437, 1.7278557220921751],
					[58.364441894115203, 1.7278556475045754],
					[58.364702880577987, 1.7267783142960131],
					[58.366283124784182, 1.7293444968168634],
					[58.367249811818752, 1.7312778712843895],
					[58.367963580208155, 1.7338773903270244],
					[58.36696399849108, 1.7333165267152604],
					[58.36696387179002, 1.733316549462931],
					[58.366963746997762, 1.7333167004765198],
					[58.366963748112411, 1.7333168306219675],
					[58.369627648093342, 1.7364418305269991],
					[58.370849848405591, 1.7378751296454318],
					[58.370849849393153, 1.7378751311571399],
					[58.370849851368312, 1.7378751341805418],
					[58.370849852355903, 1.7378751356922388],
					[58.373027652030608, 1.7402584349068915],
					[58.3730276784257, 1.7402584551935092],
					[58.373027736078143, 1.7402584861840897],
					[58.3730277673355, 1.7402584968880366],
					[58.375444366973667, 1.7406640963527347],
					[58.375444376966954, 1.7406640977211079],
					[58.375444396953576, 1.7406641004578767],
					[58.375444406850228, 1.7406641001303367],
					[58.376994407020625, 1.7406112996773313],
					[58.376994414147887, 1.7406112982056281],
					[58.376994428595751, 1.7406112986541993],
					[58.376994435723056, 1.7406112971824883],
					[58.378113936320744, 1.7404057972371001],
					[58.378113959387797, 1.7404057907576693],
					[58.37811400236594, 1.7404057698704927],
					[58.378114022373715, 1.7404057571587936],
					[58.379225081785961, 1.7395473902253542],
					[58.38060002759201, 1.7393334978900736],
					[58.382408226852625, 1.7391030107037315],
					[58.383191558400434, 1.7398973401002835],
					[58.383191582026306, 1.7398973592476983],
					[58.383191635031167, 1.7398973877728749],
					[58.38319166262832, 1.7398973975187133],
					[58.384661013156546, 1.7401723874635704],
					[58.385619254557625, 1.7409584392758881],
					[58.386227640989304, 1.7417584212975008],
					[58.38622765442841, 1.7417584356858959],
					[58.386227681907584, 1.7417584591894111],
					[58.386227697922799, 1.7417584713293592],
					[58.387266598381593, 1.742377971864663],
					[58.387266630626435, 1.7423779840863642],
					[58.38726669899097, 1.7423779974308362],
					[58.387266734123109, 1.7423779970410911],
					[58.388475033937603, 1.742169597241999],
					[58.38847506482972, 1.7421695857121036],
					[58.388475121676173, 1.7421695550896212],
					[58.388475148424817, 1.742169534116468],
					[58.389241848089071, 1.7413223347136582],
					[58.389241857405899, 1.7413223242063793],
					[58.389241872379259, 1.7413223022313307],
					[58.389241878926711, 1.7413222905795562],
					[58.390000173935434, 1.7398167985076896],
					[58.390425134863897, 1.7391473596256661],
					[58.391283343640339, 1.738786298412659],
					[58.391888907223645, 1.7387640996181895],
					[58.391888928702059, 1.7387640968960878],
					[58.391888971368857, 1.7387640863617821],
					[58.391888990678765, 1.7387640772211097],
					[58.392575076437694, 1.7384140858245432],
					[58.393783351833022, 1.7380390937733261],
					[58.39465273501321, 1.7378419194468429],
					[58.39543595689014, 1.7386418404160051],
					[58.395435985958407, 1.7386418601605913],
					[58.395436048066117, 1.7386418902446643],
					[58.395436081899739, 1.7386418987031285],
					[58.396738882146835, 1.7387612983463536],
					[58.39673889392197, 1.7387612993462438],
					[58.396738918266458, 1.7387612994649611],
					[58.396738928957333, 1.7387612972551372],
					[58.398211086615362, 1.7385419032797897],
					[58.399255527286115, 1.7388362871819014],
					[58.400477709708092, 1.7394529789341715],
					[58.400477739978086, 1.7394529881309029],
					[58.400477800925586, 1.739452997854644],
					[58.400477832397335, 1.7394529965003385],
					[58.401775032330391, 1.7392418972253931],
					[58.401775041239453, 1.7392418953836342],
					[58.40177505806998, 1.7392418901871103],
					[58.40177506697902, 1.7392418883453578],
					[58.402813878394888, 1.7388724191722149],
					[58.40438597627098, 1.7398084585209501],
					[58.405960912515042, 1.7414056942872957],
					[58.406147001437752, 1.7425556242033651],
					[58.406241501041265, 1.7437083163841716],
					[58.406241516219573, 1.7437083613343449],
					[58.406241563911344, 1.7437084390657209],
					[58.406241598206627, 1.7437084714787379],
					[58.406505400784766, 1.7438640154590404],
					[58.406522000358862, 1.7455167018142046],
					[58.406522006938665, 1.745516738237435],
					[58.406522033172287, 1.7455168032308199],
					[58.40652205301928, 1.7455168351958399],
					[58.406960836103167, 1.7459944073578106],
					[58.406272043882488, 1.7468470737971111],
					[58.406272029294712, 1.7468471025707528],
					[58.406272007343034, 1.7468471603433786],
					[58.406272000075759, 1.7468471910397545],
					[58.406202599642299, 1.7483277907489259],
					[58.406202601402292, 1.7483278058417742],
					[58.406202604031378, 1.7483278362114834],
					[58.406202606778855, 1.7483278528177828],
					[58.406913707322509, 1.7509278522939351],
					[58.406913719847736, 1.7509278823388701],
					[58.40691375619064, 1.7509279349453637],
					[58.406913778226468, 1.7509279578747872],
					[58.407524815943617, 1.7514001095296496],
					[58.407797000620697, 1.7530333231540527],
					[58.408199795914591, 1.758930541547546],
					[58.407097070759534, 1.7604470371692176],
					[58.404949929694666, 1.7613442125254601],
					[58.402758251215914, 1.7620720064763995],
					[58.398277792886532, 1.7628691975289408],
					[58.394997254803748, 1.76250260470179],
					[58.3902667851602, 1.7602665190605677],
					[58.390266768641204, 1.7602665138811615],
					[58.390266734712178, 1.7602665037059373],
					[58.390266718289553, 1.7602665002232554],
					[58.383830617725557, 1.7596970002316665],
					[58.383830603373994, 1.7596970014717728],
					[58.383830573683525, 1.7596970024391241],
					[58.383830559331948, 1.7596970036792208],
					[58.378394393704937, 1.7608330962149426],
					[58.373105628330428, 1.7600748018119641],
					[58.373105612100552, 1.7600748017222105],
					[58.373105577858922, 1.7600748019095849],
					[58.373105561725431, 1.7600748035157157],
					[58.372124962314764, 1.7602665037807759],
					[58.372124902188311, 1.7602665401793298],
					[58.372124820680966, 1.7602666444599233],
					[58.372124800094646, 1.7602667104626653],
					[58.372221999837485, 1.7621361093916257],
					[58.372222001210162, 1.7621361176874744],
					[58.372222001884595, 1.7621361295583953],
					[58.372222003160935, 1.7621361361584009],
					[58.372483098100943, 1.7635306093340564],
					[58.372335900707071, 1.7650971793040726],
					[58.372033102101049, 1.767602770677378],
					[58.371641503444131, 1.7698027653326482],
					[58.371641502335912, 1.7698027775699707],
					[58.371641500215688, 1.7698028037404288],
					[58.371641500889417, 1.7698028156113721],
					[58.371738701319998, 1.7710472159031683],
					[58.371738733872711, 1.771047281268096],
					[58.371738837339805, 1.7710473732303742],
					[58.371738907363202, 1.7710474000108911],
					[58.372227731675096, 1.7710308025300436],
					[58.372969275354528, 1.7716279559962682],
					[58.372969277232698, 1.7716279573258784],
					[58.373549876841352, 1.7720779576581571],
					[58.373549908385307, 1.772077973478543],
					[58.373549972677836, 1.7720779945773044],
					[58.373550008099357, 1.7720779993062772],
					[58.374205506679708, 1.7720530045749356],
					[58.376033144426515, 1.7743334253224945],
					[58.376033149266163, 1.7743334311912684],
					[58.376033159836432, 1.7743334427456972],
					[58.376033166458036, 1.7743334482482571],
					[58.377024837906859, 1.7752334228507118],
					[58.378116514372813, 1.7773944748534869],
					[58.379224808954547, 1.7809694595124212],
					[58.379224816152508, 1.7809694751942082],
					[58.379224832426566, 1.7809695078881411],
					[58.379224842297532, 1.7809695230210614],
					[58.380058133841892, 1.7820390124278156],
					[58.381316515140007, 1.7841972796875156],
					[58.382510904375899, 1.7882389413706927],
					[58.383219200594681, 1.7934000193665622],
					[58.383413700326642, 1.7963778134259016],
					[58.383413700326642, 1.7963778134259016],
					[58.383413701313572, 1.7963778149398106],
					[58.383413701409481, 1.7963778166363169],
					[58.383691501390736, 1.7998250166971224],
					[58.383691504227237, 1.7998250349940155],
					[58.383691515342278, 1.7998250721892126],
					[58.38369152183877, 1.7998250914525615],
					[58.38486652236233, 1.8021389909426611],
					[58.384866537165252, 1.802139013654692],
					[58.384866572720853, 1.8021390527116585],
					[58.384866592582497, 1.8021390692390611],
					[58.386283193183623, 1.8030362694169122],
					[58.386283200599344, 1.803036273047288],
					[58.38628321730868, 1.8030362816398189],
					[58.386283224724409, 1.8030362852701867],
					[58.38735552460394, 1.8034695854408704],
					[58.38735555200568, 1.8034695918444037],
					[58.387355606425885, 1.8034695978645179],
					[58.387355633444365, 1.8034695974811321],
					[58.389663907044458, 1.8030780012414971],
					[58.390819380135916, 1.803194599466869],
					[58.390819414186815, 1.8031945959262548],
					[58.390819480027467, 1.8031945807255301],
					[58.390819511625551, 1.8031945656716324],
					[58.39205011181199, 1.8023668658170207],
					[58.392050123012034, 1.8023668566565429],
					[58.392050142739102, 1.8023668388831342],
					[58.392050152952308, 1.802366828208221],
					[58.393272352991602, 1.8009112294627343],
					[58.393272362121934, 1.80091121557555],
					[58.393272377805388, 1.8009111900458927],
					[58.393272385153622, 1.8009111765238504],
					[58.393597372707262, 1.8001168043783884],
					[58.394411243300134, 1.7991445406412414],
					[58.395719532169061, 1.798000150730465],
					[58.395719549126468, 1.7980001318034691],
					[58.395719578202538, 1.7980000880743123],
					[58.395719588635075, 1.7980000653345516],
					[58.396152819658688, 1.7967422548925067],
					[58.398213715588199, 1.8015528789664039],
					[58.39821372841763, 1.8015528986570366],
					[58.398213755858592, 1.8015529376731505],
					[58.398213773047317, 1.8015529547536484],
					[58.401305473400186, 1.8040973540575203],
					[58.401305472604967, 1.8040973559374507],
					[58.403885973342007, 1.8062112550741074],
					[58.403886004978411, 1.8062112726345319],
					[58.403886071144825, 1.8062112951426161],
					[58.403886106565849, 1.806211299907809],
					[58.405863907467015, 1.8061446005461024],
					[58.405863965700668, 1.8061445783142192],
					[58.405864059662385, 1.8061445023981588],
					[58.405864093608351, 1.8061444490790191],
					[58.406502993966747, 1.8036083497010671],
					[58.406502993870923, 1.8036083480034188],
					[58.406502994666113, 1.803608346123188],
					[58.406502994570289, 1.8036083444255302],
					[58.407527994298604, 1.7991722492727573],
					[58.408011293252244, 1.7974250527148889],
					[58.408011294554697, 1.7974250438608941],
					[58.408011298050624, 1.797425025970147],
					[58.408011299353099, 1.797425017116149],
					[58.408233498807611, 1.794744422345222],
					[58.40861959683712, 1.7919028355627284],
					[58.409011294966234, 1.7901583434962016],
					[58.409011296705991, 1.7901583105077825],
					[58.409011290384491, 1.7901582465432895],
					[58.409011281432207, 1.7901582157501594],
					[58.408508489738026, 1.7890749334129648],
					[58.407566894761835, 1.7851916561328127],
					[58.407050200375309, 1.7827110809863032],
					[58.407100198879576, 1.7789611979326703],
					[58.40937791770768, 1.7771612617028369],
					[58.41035571160198, 1.7765029658792966],
					[58.410355728748172, 1.7765029503292322],
					[58.410355761066221, 1.7765029162002188],
					[58.410355773565129, 1.7765028981711666],
					[58.411166873976249, 1.7750611987482585],
					[58.411166880430535, 1.7750611853971885],
					[58.411166889775231, 1.7750611594283874],
					[58.411166895338546, 1.7750611462606507],
					[58.411880783405749, 1.7720472935595315],
					[58.413430751257074, 1.7702640308501609],
					[58.413430765440651, 1.7702640107524934],
					[58.413430787980658, 1.7702639631687174],
					[58.413430795638668, 1.7702639392618142],
					[58.414139095578079, 1.766780639603037],
					[58.414139097673001, 1.7667806288650645],
					[58.414139100080895, 1.7667806077562542],
					[58.414139100393896, 1.7667805973854163],
					[58.414114099636919, 1.7644222232411386],
					[58.414750164847291, 1.7618862625540723],
					[58.418127852283213, 1.760980793075158],
					[58.418127901019311, 1.7609807624088778],
					[58.418127973784351, 1.7609806786849234],
					[58.418127997909707, 1.760980627325285],
					[58.418508495804232, 1.7581361414560928],
					[58.419627968991378, 1.7541668364359253],
					[58.421188953856038, 1.7533251975291766],
					[58.423166682794488, 1.753255801276792],
					[58.425066659296441, 1.7536584956815047],
					[58.425066676513673, 1.7536584972809595],
					[58.425066713620886, 1.7536584999279301],
					[58.425066733317898, 1.7536584975787326],
					[58.427458333366701, 1.753261297614898],
					[58.427458338712306, 1.7532612965108512],
					[58.427458351088788, 1.7532612922363002],
					[58.427458357421834, 1.7532612926467142],
					[58.429280657223032, 1.7527223926127802],
					[58.429280666035787, 1.7527223890738803],
					[58.429280682770376, 1.752722382180141],
					[58.429280692474073, 1.7527223784572206],
					[58.430266791813338, 1.7522112785501243],
					[58.430266801420458, 1.7522112731283457],
					[58.430266816974459, 1.7522112613223906],
					[58.43026682479973, 1.7522112562687135],
					[58.431097258762009, 1.7515446088454221],
					[58.432102776455068, 1.7516668993081412],
					[58.432102867952807, 1.7516668597795622],
					[58.43210297186247, 1.7516667214429662],
					[58.432102984177831, 1.7516666209362886],
					[58.42962238363404, 1.7458638202672763],
					[58.429622376624664, 1.7458638079683895],
					[58.429622360824119, 1.7458637837390272],
					[58.429622351936274, 1.7458637701100084],
					[58.428439071913346, 1.7444720944564054],
					[58.427241883815661, 1.7416471228291128],
					[58.42724187275892, 1.7416471027770275],
					[58.427241848166055, 1.7416470666225699],
					[58.427241832848154, 1.7416470508887969],
					[58.426466894141846, 1.740952704469491],
					[58.426203003323721, 1.7373833370673086],
					[58.428133466676933, 1.7331945163580444],
					[58.429097304713011, 1.7322307774303405],
					[58.43409431329038, 1.7307891331778731],
					[58.436213732215251, 1.7340334086721376],
					[58.437777623257745, 1.7364472952440033],
					[58.439594216571273, 1.7405750812673444],
					[58.439594216571273, 1.7405750812673444],
					[58.439594217655575, 1.7405750844809307],
					[58.439594218546475, 1.7405750842965158],
					[58.440630418720879, 1.7427972843826738],
					[58.440630430668527, 1.7427973042581095],
					[58.44063046070346, 1.7427973410193294],
					[58.440630477008966, 1.7427973582738783],
					[58.442422042562832, 1.744186231276069],
					[58.443933117016712, 1.7470333806872556],
					[58.4449331092517, 1.7497861601396567],
					[58.445722000136108, 1.7527806277829776],
					[58.445758100376828, 1.756000000693098],
					[58.445716499893834, 1.7587888970608645],
					[58.445716507554657, 1.758788936741708],
					[58.445716537829284, 1.7587890095767109],
					[58.445716559552167, 1.7587890429148525],
					[58.446633159650609, 1.7596862435531031],
					[58.446633170029678, 1.759686251725793],
					[58.446633191678742, 1.7596862678871867],
					[58.446633203839696, 1.759686275691926],
					[58.447738803922739, 1.7602918755302714],
					[58.449463802961375, 1.7612473757649736],
					[58.449463804743274, 1.7612473753970455],
					[58.449463809390863, 1.7612473778763387],
					[58.449463812160126, 1.7612473790240069],
					[58.45068593328898, 1.7618501405353943],
					[58.452027606998975, 1.7664361533158814],
					[58.452527607419682, 1.7683111519924271],
					[58.45252763624417, 1.7683111993537504],
					[58.452527717081807, 1.7683112738160125],
					[58.452527768011322, 1.7683112977013069],
					[58.453572168314601, 1.7684807975989176],
					[58.453572189986573, 1.7684807982868271],
					[58.45357223491979, 1.7684807958954682],
					[58.453572257193692, 1.7684807913002252],
					[58.455447239821638, 1.7679251968998368],
					[58.457416722948949, 1.7676918997191453],
					[58.459472176588697, 1.7674558038504673],
					[58.462824847914924, 1.7686473583223474],
					[58.464052612973269, 1.7717083709668904],
					[58.465022010621496, 1.7744556675271426],
					[58.465022015750286, 1.7744556785109402],
					[58.465022027886043, 1.7744557018114389],
					[58.465022034796746, 1.7744557124277551],
					[58.466758134808238, 1.7770112119479808],
					[58.466758171866033, 1.7770112455949623],
					[58.466758258191192, 1.777011289728188],
					[58.466758307458591, 1.7770113002144101],
					[58.46769994784669, 1.7769780021827042],
					[58.470130497666204, 1.77842797175145],
					[58.470130503300751, 1.778427975751236],
					[58.470130514377352, 1.778427980349593],
					[58.470130519024671, 1.7784279828324454],
					[58.471730519282936, 1.7791362828689807],
					[58.471730554896943, 1.7791362910121185],
					[58.471730627233391, 1.779136295026158],
					[58.471730662968589, 1.7791362893800231],
					[58.473030493271864, 1.778703045365736],
					[58.474160909811317, 1.7811667652941223],
					[58.474522006109645, 1.782627847720615],
					[58.47452204263459, 1.7826279038587138],
					[58.474522143355621, 1.7826279794607436],
					[58.474522206756902, 1.7826280008091482],
					[58.475722068741817, 1.7825863045311297],
					[58.476333116193722, 1.7840361774624298],
					[58.476333116289887, 1.7840361791633683],
					[58.476333117277029, 1.7840361806807219],
					[58.477383116881498, 1.7864528800697557],
					[58.477383124970721, 1.786452895611552],
					[58.477383145604108, 1.7864529257775119],
					[58.477383158148271, 1.7864529404016831],
					[58.478163757896567, 1.7872418415237321],
					[58.478163791606242, 1.7872418638350132],
					[58.47816386627558, 1.7872418931976219],
					[58.478163907235206, 1.787241900248969],
					[58.479100006749469, 1.7872113002121215],
					[58.479100041401942, 1.787211291353779],
					[58.479100105771309, 1.7872112660492572],
					[58.479100136186965, 1.7872112460173908],
					[58.480375107883482, 1.7860223726367017],
					[58.48131397118118, 1.7856640867868654],
					[58.481314000801682, 1.7856640686370286],
					[58.481314052434072, 1.785664025299988],
					[58.481314074349832, 1.7856639984116169],
					[58.482158458116558, 1.7841612278803438],
					[58.483516788635541, 1.7829695887972392],
					[58.48471381738554, 1.7827641166722217],
					[58.486363744078687, 1.7848334250345412],
					[58.486363745956822, 1.7848334263687076],
					[58.4863637507964, 1.7848334322562593],
					[58.486363752770671, 1.7848334352918538],
					[58.488263752806112, 1.7868945368688587],
					[58.488263756562318, 1.7868945395375413],
					[58.488263765158038, 1.7868945480943672],
					[58.488263769010388, 1.7868945524645878],
					[58.489908169166682, 1.7883112525763305],
					[58.489908201697006, 1.7883112699740076],
					[58.489908269744909, 1.7883112938253847],
					[58.489908307044459, 1.7883112999119486],
					[58.491277806762639, 1.7882641000723263],
					[58.491277828242517, 1.7882640973680819],
					[58.491277869131935, 1.7882640872217197],
					[58.491277887554354, 1.7882640782615056],
					[58.493063987927343, 1.7873834791341083],
					[58.493064001894751, 1.7873834710909866],
					[58.493064028746289, 1.7873834517848033],
					[58.493064040835577, 1.7873834424071542],
					[58.494422330671981, 1.7860279507647323],
					[58.495105678130713, 1.7855140923464718],
					[58.496900025062381, 1.7852890981927489],
					[58.496900029517306, 1.7852890972742701],
					[58.496900041196241, 1.7852890965881638],
					[58.496900045651152, 1.7852890956696834],
					[58.498525046071585, 1.7849057943994029],
					[58.498525071717737, 1.7849057856675732],
					[58.49852511886904, 1.7849057587266626],
					[58.498525141361377, 1.7849057420359036],
					[58.499544540797096, 1.7838890421036913],
					[58.499544544168636, 1.7838890379645729],
					[58.499544551007922, 1.7838890313883706],
					[58.499544554379476, 1.7838890272492494],
					[58.500389054345142, 1.7828779281543281],
					[58.500389055934697, 1.7828779243825688],
					[58.500389061786734, 1.7828779162876929],
					[58.500389064363461, 1.7828779140342508],
					[58.501316848172792, 1.7815362374981174],
					[58.502591823691162, 1.7805084577999977],
					[58.50548622183733, 1.7782834585399996],
					[58.505486226990598, 1.7782834540317192],
					[58.50548623739342, 1.7782834467175153],
					[58.505486241559439, 1.7782834406908492],
					[58.50684184158029, 1.7769251409336966],
					[58.506841845047845, 1.776925138495391],
					[58.506841851790433, 1.7769251302139932],
					[58.506841855257996, 1.7769251277756957],
					[58.507944518007356, 1.7755779708366761],
					[58.509563905353133, 1.7750308095493059],
					[58.513086043907123, 1.7760529925587769],
					[58.513086110416211, 1.7760529891554817],
					[58.513086224893087, 1.7760529413918293],
					[58.513086273848174, 1.7760528985500981],
					[58.516119572756864, 1.7707084017627472],
					[58.517636263117915, 1.7682001166097026],
					[58.519755726708908, 1.7656695605865937],
					[58.523933390012324, 1.7635584782401394],
					[58.523933411706849, 1.76355846341011],
					[58.523933452037163, 1.7635584274906952],
					[58.523933468891059, 1.7635584067701975],
					[58.525955769067124, 1.7603751065405773],
					[58.525955772437797, 1.7603751023959329],
					[58.525955778095138, 1.7603750908846625],
					[58.525955781369284, 1.7603750850367501],
					[58.527130698375558, 1.7578780624144721],
					[58.528163819021081, 1.7583334834140334],
					[58.528163857308961, 1.758333490990835],
					[58.528163933112289, 1.7583334925176062],
					[58.528163971518644, 1.7583334862829763],
					[58.529102871579568, 1.7579723870416177],
					[58.529102901195088, 1.75797236884076],
					[58.529102952815911, 1.7579723253990076],
					[58.52910297383373, 1.7579722986393536],
					[58.529947318942149, 1.7564695957268639],
					[58.531150007166175, 1.756425199781664],
					[58.531150025972117, 1.7564251976069885],
					[58.531150063294227, 1.7564251881471871],
					[58.531150080125158, 1.7564251829348891],
					[58.532780680116055, 1.7557084825496083],
					[58.532780713101644, 1.75570846019875],
					[58.532780765827198, 1.7557084044543578],
					[58.532780785663753, 1.7557083727643923],
					[58.533622253551023, 1.7535365146858113],
					[58.534669216999944, 1.7549833884011781],
					[58.535649804961487, 1.7584111468090502],
					[58.536199803382765, 1.7613583377307827],
					[58.536199805743777, 1.7613583475841268],
					[58.536199813331613, 1.7613583701445725],
					[58.53619981757101, 1.7613583813325107],
					[58.537074817991801, 1.763302881442117],
					[58.537074864037628, 1.7633029305133956],
					[58.537074977170562, 1.7633029863741436],
					[58.537075044354182, 1.7633029948674332],
					[58.5379361440461, 1.7631084951492852],
					[58.537936164344572, 1.7631084874968674],
					[58.537936202966655, 1.7631084691534349],
					[58.537936221290252, 1.7631084584624297],
					[58.538622320832495, 1.762589059875997],
					[58.538622350374474, 1.7625890244500964],
					[58.538622389976744, 1.7625889438432922],
					[58.538622400037042, 1.7625888986623823],
					[58.538594599795829, 1.7596250252909023],
					[58.539091893257897, 1.7577944522140145],
					[58.53909189465346, 1.7577944450290905],
					[58.539091898335471, 1.7577944304745623],
					[58.539091898840098, 1.7577944234743539],
					[58.539411299341879, 1.7549806234070147],
					[58.539411299170084, 1.7549806044795035],
					[58.539411296057096, 1.7549805654749175],
					[58.539411293212517, 1.7549805471017215],
					[58.539052992817076, 1.7536749468402137],
					[58.539052988577041, 1.7536749356523804],
					[58.539052981084446, 1.7536749147957802],
					[58.539052975953481, 1.7536749037927275],
					[58.538700176301141, 1.7530277046597469],
					[58.538700128759714, 1.7530276610813498],
					[58.538700018682228, 1.7530276115129204],
					[58.538699956146154, 1.7530276055228815],
					[58.537841716434201, 1.7532219913865277],
					[58.537064064039782, 1.7529192492168948],
					[58.536527993025608, 1.7511249461698399],
					[58.535725194236399, 1.7480221525859985],
					[58.535186302056893, 1.745736086004779],
					[58.535808499415239, 1.7392833194507793],
					[58.535808499822032, 1.7392833107473791],
					[58.535808499744753, 1.7392832935257236],
					[58.535808500248386, 1.7392832865259715],
					[58.535511302122295, 1.7350111159830954],
					[58.536089094657889, 1.7325167452315802],
					[58.536089096845984, 1.7325167361574338],
					[58.536089099343506, 1.7325167166761419],
					[58.536089099652941, 1.7325167062689935],
					[58.536222400371287, 1.728886107178129],
					[58.536222399886519, 1.7288860986599706],
					[58.536222399013909, 1.7288860833272814],
					[58.536222397638269, 1.728886074994527],
					[58.535852997755519, 1.7259332755610715],
					[58.535852995100889, 1.7259332605996183],
					[58.535852986131097, 1.7259332297150185],
					[58.535852980706871, 1.7259332136063963],
					[58.534619581372944, 1.7233388141000006],
					[58.534619575445653, 1.7233388049920195],
					[58.534619564481922, 1.7233387865905467],
					[58.534619557663781, 1.7233387776681239],
					[58.532872358104342, 1.7210970774582273],
					[58.532872311135428, 1.7210970441513569],
					[58.532872206375572, 1.7210970090942637],
					[58.532872148584666, 1.721097007344041],
					[58.530722148923559, 1.7216692073228228],
					[58.53072214090588, 1.7216692089927985],
					[58.530722125858432, 1.7216692138505185],
					[58.530722118828656, 1.7216692170382739],
					[58.527891618851633, 1.7229248173012983],
					[58.52789161617909, 1.7229248178577936],
					[58.527891610930972, 1.7229248206740126],
					[58.527891608258393, 1.7229248212305175],
					[58.526174908310153, 1.7238109217289384],
					[58.526174901377324, 1.7238109266188995],
					[58.526174887414626, 1.7238109346957304],
					[58.52617487959094, 1.7238109397711578],
					[58.521449880442113, 1.7273665406405099],
					[58.521449878660391, 1.7273665410111891],
					[58.521449877769534, 1.7273665411965298],
					[58.518647077649966, 1.7295220413291117],
					[58.518647060607989, 1.7295220586566893],
					[58.518647031172264, 1.7295220957910631],
					[58.518647017887623, 1.729522115783144],
					[58.517210985066967, 1.7326275711825476],
					[58.516177852231202, 1.7324109071043641],
					[58.513066763038651, 1.7313720105242927],
					[58.513066745627533, 1.7313720055300839],
					[58.51306671109603, 1.7313720006492364],
					[58.513066692987842, 1.731371999245241],
					[58.511772352585666, 1.731416495558677],
					[58.510789095043194, 1.727663856168753],
					[58.510055796660552, 1.7235721642992157],
					[58.510055795381703, 1.7235721576751903],
					[58.510055790945273, 1.7235721430955167],
					[58.510055788678557, 1.7235721349545086],
					[58.509166905312583, 1.7209693828934289],
					[58.509869597251658, 1.7166167315730425],
					[58.51019739454329, 1.7146611484422518],
					[58.511397366996064, 1.7111723287792953],
					[58.512938990082425, 1.710125181278104],
					[58.515088964752245, 1.7093890898963806],
					[58.515088989209517, 1.7093890761827113],
					[58.515089034366419, 1.7093890460933077],
					[58.515089054077983, 1.7093890282007966],
					[58.515939054477386, 1.7083695286358491],
					[58.515939077298036, 1.7083694704684704],
					[58.515939084147654, 1.7083693536095084],
					[58.515939068970262, 1.7083692930295629],
					[58.51471128407676, 1.7064388158376387],
					[58.514177999771718, 1.7046443693835032],
					[58.514152999637247, 1.7021750012548851],
					[58.514272400152116, 1.6970639047165461],
					[58.514272399762703, 1.6970638979067527],
					[58.514272398983884, 1.6970638842871804],
					[58.514272398594457, 1.6970638774773901],
					[58.513797399000076, 1.692808277327692],
					[58.513797364719153, 1.6928082138584606],
					[58.513797261290541, 1.6928081234934984],
					[58.513797192337698, 1.6928081000025941],
					[58.512341757190768, 1.6928636984071446],
					[58.509755767710644, 1.6911581706661025],
					[58.508783490221447, 1.6882471363582865],
					[58.508783489330632, 1.6882471365445366],
					[58.508783489233167, 1.6882471348423689],
					[58.508783489135695, 1.6882471331402316],
					[58.507197389107198, 1.6837249334143387],
					[58.507197388021382, 1.6837249301965604],
					[58.507197386044773, 1.6837249271651498],
					[58.507197384958928, 1.6837249239473937],
					[58.505086289641532, 1.6785666346997048],
					[58.503752993798621, 1.6737082485780674],
					[58.502947394157424, 1.6704638519098696],
					[58.502947393071288, 1.6704638486929086],
					[58.502947391887439, 1.6704638437741777],
					[58.502947390801303, 1.6704638405572216],
					[58.502239090881169, 1.6681999395268532],
					[58.502239055589271, 1.6681998900901638],
					[58.502238963214083, 1.668199819895527],
					[58.502238905240063, 1.6681997993243038],
					[58.499672204736171, 1.6681358998283542],
					[58.494527834164842, 1.668002600933246],
					[58.492291818256064, 1.6672692280664148],
					[58.491514098977916, 1.6663166286116049],
					[58.491500201362506, 1.6648444527337867],
					[58.492169557104688, 1.6636751285129254],
					[58.492936196104139, 1.6629918837638131],
					[58.494555544370861, 1.6626030197050679],
					[58.496116566114992, 1.664014047196994],
					[58.496116583126089, 1.6640140608497143],
					[58.496116618636471, 1.664014082677298],
					[58.496116638026464, 1.6640140906654075],
					[58.497916637787206, 1.6646001893472053],
					[58.49791669211173, 1.6646001934554164],
					[58.497916791679465, 1.6646001691352765],
					[58.497916838899698, 1.6646001437364928],
					[58.500208439150995, 1.6623862436980921],
					[58.500208449350872, 1.6623862329479333],
					[58.500208466882789, 1.6623862086048504],
					[58.500208476094123, 1.6623861963398954],
					[58.501300145124027, 1.6603834514170774],
					[58.502750087913313, 1.6596723794885808],
					[58.502750096722785, 1.6596723759176479],
					[58.502750114938998, 1.6596723634835986],
					[58.502750122759927, 1.6596723583978297],
					[58.503852922798593, 1.658811258470255],
					[58.503852945674133, 1.6588112330010603],
					[58.503852982028626, 1.6588111754231734],
					[58.503852994714698, 1.6588111452032381],
					[58.504347395077914, 1.6566667456095772],
					[58.504347397064933, 1.6566667331358726],
					[58.504347399355417, 1.6566667102642798],
					[58.504347399560984, 1.656666698164591],
					[58.504328000835621, 1.6548667460495443],
					[58.504997360600591, 1.6535307251603819],
					[58.505594530048143, 1.6530168527925586],
					[58.505594550250244, 1.6530168278796458],
					[58.505594582442136, 1.6530167763340722],
					[58.505594594333985, 1.6530167479995554],
					[58.506086293738953, 1.6510333482656676],
					[58.50608629364099, 1.6510333465637974],
					[58.506086295324479, 1.651033344487598],
					[58.506086295324479, 1.651033344487598],
					[58.506497392769965, 1.6492194513294838],
					[58.506911290826366, 1.6478917594524409],
					[58.506911295092635, 1.6478917396080219],
					[58.506911299073636, 1.6478916991535244],
					[58.506911298886351, 1.6478916802453543],
					[58.506716898786692, 1.6459360796122271],
					[58.506716893949552, 1.6459360582369023],
					[58.506716879821752, 1.6459360164227304],
					[58.506716871323796, 1.6459359940947116],
					[58.505933471242351, 1.644658194511041],
					[58.505933469362922, 1.6446581931838418],
					[58.505933466396691, 1.6446581886402747],
					[58.505933464419186, 1.6446581856112479],
					[58.505152963993567, 1.6435415851701278],
					[58.505152938179691, 1.643541559596035],
					[58.505152879123955, 1.6435415203448096],
					[58.505152844893324, 1.6435415051531972],
					[58.503950091166892, 1.6432609151159936],
					[58.503000127125119, 1.6424804441895144],
					[58.503000094675826, 1.6424804286250025],
					[58.503000028388094, 1.6424804046774582],
					[58.502999992964391, 1.6424804000725384],
					[58.50179999282625, 1.6425247998936878],
					[58.501799950170778, 1.6425248105879737],
					[58.501799874052885, 1.6425248507100492],
					[58.501799839699778, 1.6425248803251751],
					[58.50078594844792, 1.6438748694958818],
					[58.499769389190995, 1.644894128332077],
					[58.498300160841232, 1.6436415736514605],
					[58.496633478966288, 1.6402721103111892],
					[58.496633473033583, 1.640272101227126],
					[58.496633460081313, 1.6402720798436177],
					[58.496633452269116, 1.6402720694329147],
					[58.495419551863314, 1.6388470707293961],
					[58.495419533762892, 1.638847053874134],
					[58.495419495184301, 1.6388470258296997],
					[58.49541947381541, 1.6388470148279337],
					[58.493530674273309, 1.6380998135564662],
					[58.493530656859605, 1.6380998086109455],
					[58.493530624009814, 1.638099801747531],
					[58.493530606792355, 1.6380998002044531],
					[58.491302806605212, 1.6380219992556322],
					[58.49130280304243, 1.6380220000051744],
					[58.491302795818811, 1.6380219998031218],
					[58.491302792256029, 1.6380220005526593],
					[58.489161198276037, 1.6381053954259723],
					[58.488002961031164, 1.6365359812269717],
					[58.488002853386078, 1.6365359436194236],
					[58.488002673361336, 1.636535995268642],
					[58.488002600883554, 1.6365360828244921],
					[58.487597001209657, 1.6410860819882385],
					[58.487596999722442, 1.6410860874656419],
					[58.487597000408933, 1.6410860993722263],
					[58.487596999910515, 1.6410861063632796],
					[58.487908099068591, 1.6512082764936222],
					[58.486497014094361, 1.6564749197408029],
					[58.484210928830556, 1.6602276962158968],
					[58.484210922389337, 1.6602277096160414],
					[58.484210909604727, 1.6602277381171253],
					[58.484210905933537, 1.6602277526577975],
					[58.482177610254155, 1.668602734565658],
					[58.480497022464682, 1.6722471084090624],
					[58.47983068694419, 1.6734022485898881],
					[58.479386295900703, 1.6713193585543491],
					[58.479386292740124, 1.6713193506104047],
					[58.479386288102823, 1.6713193326489442],
					[58.479386284942244, 1.6713193247050175],
					[58.478344584952865, 1.668774924480604],
					[58.478344576849913, 1.6687749089666726],
					[58.47834455797171, 1.6687748784983565],
					[58.47834454818495, 1.6687748650580656],
					[58.477064068210908, 1.6673692880036635],
					[58.475419581038516, 1.663877715740723],
					[58.475419524867654, 1.6638776638345938],
					[58.475419393189917, 1.6638776157009092],
					[58.475419316694534, 1.6638776179595389],
					[58.473480516984758, 1.6647609182041481],
					[58.473480509956509, 1.66476092139711],
					[58.473480495107047, 1.6647609296699666],
					[58.473480488176541, 1.6647609345632961],
					[58.470205488844229, 1.6669831346304995],
					[58.46759993205221, 1.6686970054776926],
					[58.464811080894052, 1.6689637005500226],
					[58.464811062188133, 1.6689637044645873],
					[58.464811024080937, 1.6689637158800179],
					[58.464811005668082, 1.668963724894416],
					[58.462541636099367, 1.6701776077124644],
					[58.459583311086142, 1.6706136957198008],
					[58.457380709891368, 1.6700498224992559],
					[58.455494550685025, 1.667863768245152],
					[58.455494538226041, 1.6678637553724915],
					[58.455494508061278, 1.6678637324448284],
					[58.455494492136992, 1.6678637220171193],
					[58.454825092239673, 1.6675165224047555],
					[58.45482498466523, 1.6675165328730726],
					[58.45482484139103, 1.6675166436854028],
					[58.454824804800488, 1.6675167442157677],
					[58.455447005092601, 1.6702194442658163],
					[58.455447005190258, 1.6702194459653106],
					[58.455447006178659, 1.6702194474785026],
					[58.456213706008782, 1.6732917481052723],
					[58.456213710255113, 1.6732917592571779],
					[58.456213718845383, 1.6732917832605554],
					[58.456213723982486, 1.6732917942262222],
					[58.457427622278573, 1.675558391194651],
					[58.458927603021408, 1.6786639531819925],
					[58.459133065085453, 1.6813717497470377],
					[58.45739734673846, 1.6799554616208059],
					[58.456258465072231, 1.6783526866515719],
					[58.455208466562702, 1.676749890048278],
					[58.45520843037179, 1.6767498563332695],
					[58.455208342138462, 1.6767498111345818],
					[58.455208292865933, 1.6767498007920032],
					[58.453916727697667, 1.6767969998903305],
					[58.452533378374071, 1.6763554143234898],
					[58.451664033042569, 1.675894243626612],
					[58.449391867382957, 1.6728581890918424],
					[58.448250171926198, 1.6709276980435042],
					[58.448250145032176, 1.6709276692750117],
					[58.448250081933907, 1.6709276222823959],
					[58.448250045827294, 1.6709276057574551],
					[58.447044473307699, 1.6706415120284741],
					[58.445658396667518, 1.669874824338162],
					[58.445658387369321, 1.6698748194044934],
					[58.445658367979817, 1.6698748114224478],
					[58.445658358779276, 1.6698748081878045],
					[58.443750058687527, 1.6692887086287509],
					[58.443750028706539, 1.6692887045812628],
					[58.443749969232798, 1.6692887049809568],
					[58.443749938849301, 1.6692887096143905],
					[58.441433239281345, 1.6700331100413426],
					[58.441433211167499, 1.6700331227953746],
					[58.441433161858612, 1.6700331588917459],
					[58.441433139675183, 1.6700331807214621],
					[58.441180506755892, 1.6703719918089546],
					[58.439886117826468, 1.6702554018255051],
					[58.439886112286629, 1.6702553995451477],
					[58.43988609882755, 1.6702554006393062],
					[58.439886091603825, 1.670255400430102],
					[58.439108291822357, 1.6702859005235338],
					[58.439108281035551, 1.6702859010590534],
					[58.439108257778095, 1.6702859042011979],
					[58.439108247088946, 1.6702859064354407],
					[58.437733247435219, 1.6706665070407267],
					[58.437733212390398, 1.6706665246793324],
					[58.437733151098669, 1.6706665718701827],
					[58.437733125742497, 1.670666601236303],
					[58.436885925718293, 1.6721777012837085],
					[58.436885923338799, 1.6721777069379125],
					[58.436885917689054, 1.6721777184324578],
					[58.436885915309581, 1.6721777240866713],
					[58.436127708725294, 1.674013597738677],
					[58.435436212059614, 1.6735470339849643],
					[58.435436183372317, 1.6735470210693266],
					[58.435436123813318, 1.6735470042890221],
					[58.435436092941629, 1.6735470004243747],
					[58.434230612134783, 1.6735914994572518],
					[58.432502830093298, 1.6733276027603712],
					[58.432502815548226, 1.6733276006421327],
					[58.432502786750824, 1.6733276015009049],
					[58.432502773291681, 1.6733276025934365],
					[58.430869372861366, 1.6735526021652996],
					[58.430869350689683, 1.673552608514741],
					[58.430869308322997, 1.673552624238198],
					[58.430869288128029, 1.6735526336122006],
					[58.428555498336969, 1.6751192284881371],
					[58.427183256252249, 1.6758275977410788],
					[58.425202873761791, 1.6757387024059018],
					[58.423027923924927, 1.6740137422141266],
					[58.423027906219708, 1.6740137321640478],
					[58.42302786813697, 1.674013712621788],
					[58.423027847063807, 1.6740137067115111],
					[58.420697247338317, 1.6734442056301178],
					[58.420697233684002, 1.6734442033261923],
					[58.420697205582222, 1.6734442006021664],
					[58.420697192916265, 1.6734441998101224],
					[58.418886092751805, 1.6735108998045241],
					[58.418886079390219, 1.6735109025939023],
					[58.418886051678712, 1.6735109066608618],
					[58.418886038317112, 1.6735109094502432],
					[58.417166638424021, 1.6740692100354371],
					[58.417166579900147, 1.6740692583299241],
					[58.417166515870356, 1.67406938336725],
					[58.417166509376102, 1.6740694585983396],
					[58.417485908990294, 1.6751167579667166],
					[58.417485909087837, 1.6751167596644077],
					[58.417810894152943, 1.6761778103107032],
					[58.417388710988092, 1.6783721252160786],
					[58.41653043105164, 1.6797331937246347],
					[58.416530421337058, 1.6797332129306719],
					[58.416530405763474, 1.6797332556926237],
					[58.416530400600287, 1.679733275667407],
					[58.416291505701366, 1.6817388374164488],
					[58.41534707320033, 1.6834359175795566],
					[58.413952768918975, 1.6836554026991992],
					[58.413952752884704, 1.6836554060411892],
					[58.413952722792587, 1.683655415748972],
					[58.413952707843968, 1.6836554223004059],
					[58.412911039681859, 1.684191506079064],
					[58.410991753351787, 1.6844303924588959],
					[58.409680753820616, 1.683488765519686],
					[58.408875176189177, 1.6819888050063414],
					[58.408875136047499, 1.6819887652800543],
					[58.408875040203355, 1.6819887131174864],
					[58.408874983610062, 1.6819887008668835],
					[58.408555623191589, 1.6820136983676788],
					[58.406166760600378, 1.6812526097541045],
					[58.40616666528588, 1.6812526296208765],
					[58.406166535551833, 1.6812527408185181],
					[58.406166503011285, 1.6812528334752226],
					[58.406491472909906, 1.6831776577686743],
					[58.405433353029657, 1.6834830764837132],
					[58.40413073638814, 1.6822776523733505],
					[58.404130652096811, 1.6822776287214736],
					[58.404130496611835, 1.6822776525357257],
					[58.404130426406404, 1.6822777015133068],
					[58.402841553650887, 1.6845692525049922],
					[58.400269304321334, 1.6859776256726535],
					[58.400269284319847, 1.6859776384238838],
					[58.40026924836701, 1.6859776716688351],
					[58.400269232318259, 1.6859776904656461],
					[58.397980437609853, 1.6895109836570983],
					[58.396616747048398, 1.6912024222717101],
					[58.395650187105907, 1.689105528784018],
					[58.394314091237661, 1.6848860393384339],
					[58.394314082942557, 1.6848860204630107],
					[58.394314062983952, 1.6848859868473451],
					[58.394314052113863, 1.6848859702249999],
					[58.393089052357851, 1.6834554700638495],
					[58.393089046623437, 1.6834554643907846],
					[58.393089036045396, 1.6834554528590968],
					[58.393089030310982, 1.683455447186029],
					[58.391614045366595, 1.6821970607219807],
					[58.390127957967437, 1.6802831777733556],
					[58.390127927610614, 1.6802831514803525],
					[58.390127858671256, 1.6802831126248978],
					[58.390127821967702, 1.6802831013876991],
					[58.385583322180274, 1.6797915005701938],
					[58.38558330060642, 1.6797915016312444],
					[58.385583261119336, 1.6797915047072796],
					[58.385583240631171, 1.6797915089751227],
					[58.384113841405537, 1.6802415082715298],
					[58.384113816853166, 1.6802415202509824],
					[58.384113773385508, 1.6802415481849864],
					[58.38411375179782, 1.6802415646961522],
					[58.379833151688409, 1.6849331652311508],
					[58.379833150004188, 1.6849331672978418],
					[58.379833145842305, 1.6849331733125315],
					[58.379833144158091, 1.6849331753792194],
					[58.376852644333894, 1.688655475576134],
					[58.37685264086798, 1.688655478013021],
					[58.376852635815105, 1.6886554842120192],
					[58.376852634228136, 1.6886554879741558],
					[58.373797034084333, 1.6931970885554362],
					[58.373797033290785, 1.6931970904362286],
					[58.373797031606415, 1.6931970925021627],
					[58.373797030715608, 1.6931970926872917],
					[58.371675094835339, 1.6965577749817635],
					[58.37140300121203, 1.6952555867648684],
					[58.371480800029772, 1.694102812747261],
					[58.371480797777494, 1.6941027891946079],
					[58.371480790697717, 1.6941027443401311],
					[58.371480783991366, 1.6941027217129352],
					[58.370858484184744, 1.6926499218067339],
					[58.370858473218576, 1.6926499034969167],
					[58.370858448613767, 1.6926498674326675],
					[58.370858434181606, 1.6926498515588817],
					[58.37016409863228, 1.6920193117196933],
					[58.370147399552195, 1.6902167163547486],
					[58.370636296439685, 1.6875722408551319],
					[58.371294592359021, 1.6847583577807954],
					[58.371966888376043, 1.6829306689807941],
					[58.37196688986544, 1.6829306635234267],
					[58.371966893637683, 1.682930650727777],
					[58.371966895029686, 1.6829306435748701],
					[58.372297395060784, 1.6814417437880713],
					[58.372297397246079, 1.6814417347540795],
					[58.372297398944319, 1.6814417172423621],
					[58.372297400238843, 1.681441708393798],
					[58.3723668999625, 1.6799611088912787],
					[58.372366890998784, 1.6799610627142842],
					[58.372366851887222, 1.679960978202387],
					[58.372366822727592, 1.6799609413775656],
					[58.371761223397975, 1.6794915415306921],
					[58.371761180862556, 1.6794915229339238],
					[58.371761095081943, 1.6794915047619694],
					[58.371761049261856, 1.6794915074386891],
					[58.370305585225829, 1.6798719972378076],
					[58.369188922131656, 1.6797498009107747],
					[58.369188896006399, 1.6797498012023775],
					[58.369188844243027, 1.6797498102625565],
					[58.369188819398303, 1.6797498171502783],
					[58.367572153832093, 1.6804637015332435],
					[58.366033354089545, 1.6805219979272916],
					[58.364305705686967, 1.6794442305824822],
					[58.364305671558469, 1.6794442171009205],
					[58.364305601311109, 1.6794442025600906],
					[58.364305564398926, 1.6794442033814245],
					[58.362099964008983, 1.6798498021803083],
					[58.359888864208557, 1.6802581024905419],
					[58.359888818569203, 1.6802581239939127],
					[58.359888745287165, 1.6802581821232105],
					[58.359888716057725, 1.6802582225097413],
					[58.359058115899842, 1.6822332225720287],
					[58.359058114410416, 1.6822332280273733],
					[58.359058109649979, 1.6822332393086799],
					[58.359058108160532, 1.6822332447640405],
					[58.358155407588768, 1.6853416440354774],
					[58.358155406795312, 1.6853416459155748],
					[58.358155406099215, 1.685341649490566],
					[58.358155406196566, 1.6853416511854471],
					[58.357827605988554, 1.6866499514992614],
					[58.357827602799809, 1.6866499744585088],
					[58.35782760285268, 1.6866500224704544],
					[58.357827605106117, 1.6866500460134783],
					[58.358094204681684, 1.6877750460746772],
					[58.358094209023434, 1.6877750588931633],
					[58.358094218597799, 1.6877750843449384],
					[58.358094223733055, 1.6877750952833732],
					[58.358960923844819, 1.6893611962246871],
					[58.358960929773474, 1.689361205283554],
					[58.358960943412406, 1.6893612230310426],
					[58.358960950231882, 1.6893612319047775],
					[58.359649808653515, 1.6901472851466399],
					[58.359744191992938, 1.6909527431009599],
					[58.359238741358986, 1.6916192797062868],
					[58.359238734621506, 1.6916192879666818],
					[58.359238723916278, 1.6916193056272031],
					[58.359238719851284, 1.6916193133323816],
					[58.357822026676097, 1.6945859986804441],
					[58.35631375980627, 1.6967470518741636],
					[58.354874897387035, 1.697610929362946],
					[58.354874864005239, 1.697610960300362],
					[58.354874816030097, 1.6976110354251415],
					[58.354874801436736, 1.6976110796125112],
					[58.35471920095587, 1.6990749785255335],
					[58.354485901090705, 1.701188878179571],
					[58.35448590138212, 1.7011888832638578],
					[58.354485900280388, 1.7011888954968071],
					[58.354485899778098, 1.7011889024606697],
					[58.35450260036361, 1.7029693769904379],
					[58.354141506452933, 1.7044277522940012],
					[58.354141502969632, 1.7044277701651691],
					[58.354141500748533, 1.7044278100682602],
				],
			],
		},
		{
			fldNpdidField: "43651",
			wlbNpdidWellbore: "402",
			fldName: "BRAGE",
			fldCurrentActivitySatus: "Producing",
			wlbName: "31/4-3",
			fldDiscoveryYear: "1980",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43651",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43651",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "BRAGE UNIT",
			cmpLongName: "Wintershall Dea Norge AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=402",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "35318843",
			fldNpdidOwner: "40432",
			fldDateUpdated: "2022-09-13 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[
						[60.47664150211039, 3.0644805695875434],
						[60.476641510053661, 3.064480626500361],
						[60.476641555005472, 3.0644807258786861],
						[60.47664159201404, 3.0644807683441697],
						[60.476788761269844, 3.0645751488463273],
						[60.476938738012137, 3.0647807179044828],
						[60.476938748400705, 3.0647807287687518],
						[60.476938770070866, 3.0647807503336186],
						[60.476938781352452, 3.0647807610342346],
						[60.477044280777541, 3.0648584607554543],
						[60.477044330211704, 3.0648584790560083],
						[60.477044428842092, 3.0648584901641009],
						[60.477044479824322, 3.0648584826443042],
						[60.477438979967786, 3.0646862822151801],
						[60.477438993201311, 3.0646862761415314],
						[60.477439017720833, 3.0646862607030818],
						[60.477439029899777, 3.0646862511745949],
						[60.477494499629877, 3.0646390776672163],
						[60.477519457313818, 3.0646307912692246],
						[60.477730644142063, 3.0645751958069543],
						[60.477925032843757, 3.0645390971984345],
						[60.477944405254092, 3.0645363015464167],
						[60.478377781363925, 3.0645779997977236],
						[60.478377785828968, 3.0645779989793032],
						[60.478377794839822, 3.0645779991517759],
						[60.478377800278672, 3.0645779999789968],
						[60.478388851697687, 3.0645780009056827],
						[60.47839430918448, 3.0645807783293009],
						[60.478394311051275, 3.0645807798112439],
						[60.478394313730298, 3.0645807793201918],
						[60.478394314704104, 3.0645807809658145],
						[60.478755515371702, 3.0647501817097589],
						[60.478755521784315, 3.0647501841826688],
						[60.478755536395575, 3.0647501888010837],
						[60.478755542808194, 3.0647501912739914],
						[60.478838842636819, 3.0647751928343294],
						[60.478838859846334, 3.0647751951523845],
						[60.478838894265337, 3.064775199788516],
						[60.478838912287053, 3.064775200133576],
						[60.478936075581039, 3.0647696016480683],
						[60.479288841138342, 3.0648779912660853],
						[60.479288862000644, 3.0648779947388678],
						[60.479288902670731, 3.0648779982293699],
						[60.479288924345255, 3.0648779997290925],
						[60.47942782391538, 3.0648612982276271],
						[60.479611123804879, 3.0648390995741277],
						[60.479611131761175, 3.0648390962915828],
						[60.479611147835293, 3.0648390933452587],
						[60.479611154979352, 3.0648390920357786],
						[60.479736143897277, 3.0648029948265676],
						[60.479838931989562, 3.0647862973187694],
						[60.479838932882565, 3.064786297155083],
						[60.480105629999052, 3.0647418988070121],
						[60.480186054331746, 3.0647308088433771],
						[60.480294291747249, 3.0648001677062582],
						[60.480472091846025, 3.0649140680675933],
						[60.48047209371282, 3.064914069549646],
						[60.480472098339426, 3.0649140723500703],
						[60.480472100206221, 3.0649140738321212],
						[60.480635970555539, 3.0650084552858368],
						[60.481052639707443, 3.0654918200356258],
						[60.481083132341226, 3.0655390091260788],
						[60.481083135181791, 3.0655390122539776],
						[60.481083140050735, 3.0655390204829298],
						[60.481083142891315, 3.0655390236108464],
						[60.481144243346918, 3.0656168238208052],
						[60.481144244320703, 3.0656168254665985],
						[60.481347037611279, 3.0658695172973629],
						[60.481533133209588, 3.0661529104379599],
						[60.481533139864503, 3.0661529183397951],
						[60.48153315512188, 3.0661529374351226],
						[60.481533163643583, 3.0661529468191273],
						[60.481616536290758, 3.0662307209956881],
						[60.481699819340136, 3.0664028850732232],
						[60.481833109893287, 3.0666972653077873],
						[60.481980400359781, 3.0673056236556997],
						[60.481980399712427, 3.0673139007084016],
						[60.48198040035318, 3.0673166990959149],
						[60.481980400238896, 3.0673471999011181],
						[60.481980400319642, 3.0673472017106382],
						[60.481980399668906, 3.0673472073028236],
						[60.481980399749652, 3.0673472091123615],
						[60.48201650016648, 3.0681472045414639],
						[60.482016500062237, 3.0682222005014572],
						[60.4820165003852, 3.0682222077395505],
						[60.482016502085628, 3.0682222256712133],
						[60.482016503382368, 3.0682222345552366],
						[60.482035903133117, 3.0683361319044926],
						[60.482116502404132, 3.068861130260327],
						[60.482116503458599, 3.0688611337158185],
						[60.482116503700802, 3.0688611391444103],
						[60.482116503862265, 3.0688611427634731],
						[60.482174804167386, 3.0691333428369081],
						[60.482174805060396, 3.0691333426733234],
						[60.482174805141135, 3.0691333444828777],
						[60.482174805221852, 3.0691333462924129],
						[60.482258104783504, 3.0694833457157764],
						[60.482258106892424, 3.0694833526268086],
						[60.482258111029552, 3.0694833646393174],
						[60.482258113138499, 3.0694833715503438],
						[60.482430413043907, 3.0699389717415082],
						[60.482452613339291, 3.0699972706542233],
						[60.482452618369386, 3.0699972825033073],
						[60.482452630134894, 3.0699973040648141],
						[60.482452636951024, 3.0699973155867717],
						[60.482485937204949, 3.0700445145757445],
						[60.482485942966619, 3.0700445226421746],
						[60.482485957169018, 3.070044538284352],
						[60.482485963823734, 3.0700445461872019],
						[60.482752662221287, 3.0702918444972704],
						[60.482874856118634, 3.0704112395578629],
						[60.48304985197084, 3.0706029341799224],
						[60.483049852944561, 3.0706029358259643],
						[60.483049853918281, 3.0706029374720032],
						[60.483049854811306, 3.0706029373084549],
						[60.483263754447435, 3.070827937523108],
						[60.483324853164412, 3.0708945345053231],
						[60.483324863552532, 3.0708945453736027],
						[60.483324888793895, 3.0708945662924569],
						[60.483324902673367, 3.0708945746969598],
						[60.483349878122461, 3.0709084618008373],
						[60.483397058044865, 3.0709557409298145],
						[60.483397058937889, 3.0709557407662706],
						[60.483774856327123, 3.0713334386384674],
						[60.483947053314338, 3.071516836411595],
						[60.484172052200371, 3.0717640334823209],
						[60.484222050797342, 3.0718195342235082],
						[60.484222053637808, 3.0718195373522668],
						[60.48422206013101, 3.0718195416365774],
						[60.484222062971448, 3.0718195447653409],
						[60.484272063180384, 3.0718668445167157],
						[60.484272068861259, 3.0718668507742231],
						[60.484272081035364, 3.0718668613160882],
						[60.484272088421584, 3.071866865436891],
						[60.484572074978423, 3.0720695578709756],
						[60.484672045143967, 3.0721640297550876],
						[60.485072025762179, 3.0727778997587802],
						[60.485097015550423, 3.0728278783064149],
						[60.485119211874739, 3.0728889686412306],
						[60.485119216904621, 3.0728889804917401],
						[60.485119228750392, 3.0728890038657344],
						[60.485119234592574, 3.0728890137430227],
						[60.485322033334064, 3.0731834094761989],
						[60.485569229921104, 3.0735807058705613],
						[60.485569238603659, 3.0735807188771354],
						[60.485569259379524, 3.0735807406168787],
						[60.48556926976746, 3.0735807514867397],
						[60.485608170095631, 3.0736140521209232],
						[60.485608174802756, 3.0736140567327466],
						[60.485608185922352, 3.0736140638196705],
						[60.485608192415505, 3.0736140681045074],
						[60.48572484062732, 3.0736890353008097],
						[60.486016517389395, 3.0743556803046657],
						[60.486016602854548, 3.074355739466347],
						[60.486016780715758, 3.0743557506980439],
						[60.486016872950479, 3.074355699148497],
						[60.486194503048125, 3.0740474224016721],
						[60.486466641764231, 3.0740973933512694],
						[60.486716575755032, 3.0742029648986331],
						[60.486916544561566, 3.0744501255630459],
						[60.486916545535259, 3.074450127209408],
						[60.486916547401968, 3.0744501286922858],
						[60.487044242334989, 3.0746029236517751],
						[60.487366537947231, 3.0750445182641544],
						[60.487366545575348, 3.075044527815586],
						[60.487366564403622, 3.0750445462646496],
						[60.487366572844103, 3.0750445538427815],
						[60.487727658835666, 3.0753418433152127],
						[60.487810948195737, 3.0754390291084932],
						[60.487810950143071, 3.0754390324013534],
						[60.48781095395713, 3.0754390371771692],
						[60.487810955823811, 3.0754390386601451],
						[60.487883104081021, 3.075513985404656],
						[60.48789150001425, 3.0756777992699558],
						[60.487849799888338, 3.0764221896986488],
						[60.487849800775322, 3.0764222096073111],
						[60.487849807988084, 3.0764222502539837],
						[60.487849812527799, 3.0764222713188389],
						[60.487988713418041, 3.0767833720773434],
						[60.48798871439169, 3.076783373723817],
						[60.488030406111363, 3.0768889546940099],
						[60.488108103282194, 3.0773333333411439],
						[60.488108105713245, 3.0773333474935218],
						[60.488108114066776, 3.0773333733347661],
						[60.488108119096282, 3.0773333851870595],
						[60.488183119235629, 3.0774917853331556],
						[60.488183127998468, 3.0774918001517189],
						[60.488183148122573, 3.0774918274887524],
						[60.488183158590836, 3.0774918401706124],
						[60.488258159217054, 3.0775668414022221],
						[60.488258164897701, 3.0775668476614904],
						[60.488258178938054, 3.0775668596898353],
						[60.488258185431107, 3.07756686397581],
						[60.488433184819137, 3.0776890639295682],
						[60.488433194071874, 3.0776890695353298],
						[60.488433213470387, 3.0776890805834469],
						[60.488433221749467, 3.0776890845426896],
						[60.488708161678161, 3.0778057587455869],
						[60.488972028725364, 3.0782445030405645],
						[60.488972033593562, 3.0782445112734074],
						[60.48897204600906, 3.0782445272489527],
						[60.488972052663293, 3.0782445351550431],
						[60.489158128402131, 3.0784473082364068],
						[60.489444215445268, 3.0791556756204845],
						[60.489444218446749, 3.079155682370323],
						[60.489444224369102, 3.0791556940600144],
						[60.489444228344226, 3.0791557024564691],
						[60.489605427809508, 3.0794279028598566],
						[60.489605432597045, 3.079427909283035],
						[60.489605443145734, 3.0794279237760636],
						[60.489605448906879, 3.0794279318458964],
						[60.489916549180741, 3.0797862301521111],
						[60.489916550154362, 3.0797862317987694],
						[60.489916552101626, 3.0797862350921017],
						[60.48991655307524, 3.0797862367387632],
						[60.490055452980009, 3.0799362369031833],
						[60.490055484551874, 3.0799362584998455],
						[60.490055552650844, 3.079936291663067],
						[60.490055590802832, 3.0799362992829109],
						[60.490116665062956, 3.0799390988347346],
						[60.490497139701574, 3.0800584903257304],
						[60.490497155205425, 3.0800584947891254],
						[60.490497185078382, 3.0800584984491337],
						[60.49049719952805, 3.0800584994557814],
						[60.490502800361845, 3.0800585005657681],
						[60.49050282268761, 3.0800584964822968],
						[60.490502867258549, 3.0800584865053136],
						[60.490502887717675, 3.0800584809384839],
						[60.490508344847584, 3.0800558003614165],
						[60.490947196604033, 3.0800613006677544],
						[60.490947198390096, 3.0800613003410717],
						[60.49094719928312, 3.0800613001777326],
						[60.490947200176151, 3.0800613000143899],
						[60.490952800036155, 3.0800612994736429],
						[60.49095551720292, 3.080061300655232],
						[60.490963726986514, 3.080069508807215],
						[60.490994211002935, 3.0801556678621829],
						[60.49099421189598, 3.0801556676988397],
						[60.49099421189598, 3.0801556676988397],
						[60.490994211976577, 3.0801556695089007],
						[60.491149806170888, 3.0805806530058488],
						[60.491224789748976, 3.0809805649455684],
						[60.49107762551639, 3.0812388001788715],
						[60.491077625596965, 3.0812388019889609],
						[60.490952625823027, 3.081461002079795],
						[60.490952624929974, 3.0814610022431013],
						[60.490952624117512, 3.0814610042164694],
						[60.490952624198094, 3.0814610060265326],
						[60.490841523638871, 3.081669305248441],
						[60.490841522745832, 3.081669305411737],
						[60.490841522826408, 3.0816693072218131],
						[60.490841521933369, 3.0816693073851109],
						[60.49072762186406, 3.0818888084301737],
						[60.490727621051597, 3.0818888104035338],
						[60.490727619426664, 3.0818888143502212],
						[60.490727617721134, 3.0818888164868565],
						[60.490619217764483, 3.0821249157567405],
						[60.490502618050385, 3.0823777165680126],
						[60.490502617237908, 3.0823777185413324],
						[60.490502614800462, 3.0823777244613026],
						[60.490502614068539, 3.0823777282446656],
						[60.490410913917728, 3.0826138274426462],
						[60.490410910667784, 3.0826138353358918],
						[60.490410906034569, 3.0826138526058751],
						[60.490410903677663, 3.0826138603358357],
						[60.490374804083743, 3.0827971614342862],
						[60.490374802451804, 3.0827971854545888],
						[60.490374803572514, 3.0827972308688616],
						[60.490374808191824, 3.0827972537463229],
						[60.490422007179205, 3.0829639523318519],
						[60.490502606945419, 3.0832722516504907],
						[60.490502609053671, 3.0832722585641874],
						[60.490502614163219, 3.0832722722283328],
						[60.490502616271456, 3.0832722791420166],
						[60.490691516171644, 3.0837083788022275],
						[60.490691527854551, 3.0837083985640916],
						[60.490691554711944, 3.0837084356247706],
						[60.490691569886458, 3.0837084529235974],
						[60.490952669642738, 3.0839307517196239],
						[60.490952677109192, 3.0839307576540285],
						[60.490952691961517, 3.0839307677128067],
						[60.490952699427957, 3.083930773647213],
						[60.491099899346821, 3.0840168738112634],
						[60.491099907625753, 3.0840168777724011],
						[60.491099927836309, 3.0840168868518556],
						[60.491099937901311, 3.0840168904865237],
						[60.491397088653663, 3.0841140736255137],
						[60.491822054017234, 3.0845668377084734],
						[60.491822078202546, 3.084566855186174],
						[60.491822129010707, 3.0845668842215237],
						[60.491822157419612, 3.0845668954527339],
						[60.491847069393778, 3.0845723752014447],
						[60.491874825470127, 3.0846222975534912],
						[60.49187482936437, 3.0846223041411265],
						[60.491874838045938, 3.0846223171531686],
						[60.491874842833219, 3.0846223235775656],
						[60.492294243233516, 3.0851557238443652],
						[60.492294254594142, 3.0851557363670663],
						[60.492294279913963, 3.0851557591127081],
						[60.492294293873201, 3.0851557693356231],
						[60.492674893683208, 3.0853945696816805],
						[60.49267489644285, 3.0853945710022321],
						[60.49267490009553, 3.0853945721595939],
						[60.492674901962104, 3.0853945736433568],
						[60.492744302310193, 3.0854334747245034],
						[60.492744319761044, 3.0854334824846714],
						[60.492744356287773, 3.0854334940582415],
						[60.492744375363642, 3.0854334978716254],
						[60.49278883701983, 3.0854390937414382],
						[60.492966603838042, 3.0855362748222506],
						[60.492966610330875, 3.0855362791104022],
						[60.492966623155468, 3.0855362840663179],
						[60.492966628674736, 3.0855362867074705],
						[60.493194309909299, 3.0856223796370781],
						[60.493255492607666, 3.0856612688503553],
						[60.493255499019966, 3.0856612713283624],
						[60.493255510139008, 3.0856612784209596],
						[60.493255515738781, 3.085661282872378],
						[60.493641616414841, 3.085839082392019],
						[60.493641628265877, 3.0858390857011133],
						[60.49364165115545, 3.085839094292711],
						[60.493641663006507, 3.0858390976018062],
						[60.493805561992289, 3.0858695969013135],
						[60.493916661081819, 3.0858918955841474],
						[60.493916675612006, 3.0858918984036587],
						[60.493916707109918, 3.0858918981223669],
						[60.493916721559557, 3.0858918991316386],
						[60.494091722098311, 3.0858723989051029],
						[60.494091739066057, 3.0858723958042473],
						[60.494091771054464, 3.0858723863084334],
						[60.494091786075089, 3.0858723799134782],
						[60.49422508623816, 3.08580847922084],
						[60.494225087211703, 3.0858084808678981],
						[60.494225088104756, 3.0858084807046913],
						[60.494463988282732, 3.0856918793462138],
						[60.494463990961847, 3.085691878856581],
						[60.494463996159055, 3.0856918742567561],
						[60.494463998838164, 3.0856918737671233],
						[60.494541798711204, 3.0856473748108937],
						[60.494541816981936, 3.0856473605217491],
						[60.494541849870743, 3.0856473307860344],
						[60.494541865301315, 3.0856473133659565],
						[60.494572364338701, 3.0856029143325876],
						[60.494616864384355, 3.0855390130282117],
						[60.494616866170425, 3.0855390127017834],
						[60.494616867795472, 3.0855390087547869],
						[60.494616870394061, 3.0855390064548418],
						[60.494822369810151, 3.0852084059147042],
						[60.494986209293536, 3.0849446032271719],
						[60.495147193090126, 3.0849501999214066],
						[60.495147193983158, 3.0849501997581732],
						[60.495147194876196, 3.0849501995949384],
						[60.495147195769242, 3.0849501994317019],
						[60.495280587043069, 3.0849529993292979],
						[60.495438815266532, 3.0849695919209341],
						[60.495735962628288, 3.0852362468778924],
						[60.495888759770843, 3.0853862427246175],
						[60.495888764397073, 3.0853862455291834],
						[60.495888771050943, 3.0853862534383643],
						[60.495888774784127, 3.0853862564061774],
						[60.496083149110483, 3.0855418350857176],
						[60.496333131392298, 3.0859390077366737],
						[60.496333146647046, 3.085939026849807],
						[60.496333183246861, 3.0859390603127972],
						[60.496333202805808, 3.0859390749890898],
						[60.496599878749464, 3.0860862614061579],
						[60.49678316031973, 3.0862668420029324],
						[60.496783168840146, 3.0862668513964935],
						[60.496783190185141, 3.0862668657467554],
						[60.496783200411116, 3.0862668730034897],
						[60.496855500463745, 3.0863084735905355],
						[60.496855526112938, 3.08630848350397],
						[60.496855579848891, 3.0863084974100006],
						[60.496855606962114, 3.0863084997553609],
						[60.497155606654751, 3.0862974007338324],
						[60.497233307442251, 3.0862946000043725],
						[60.497233325383554, 3.0862945985505599],
						[60.497233361024634, 3.0862945902116459],
						[60.49723337872441, 3.0862945833265227],
						[60.497538957382147, 3.0861640931451517],
						[60.497680600018157, 3.0861391034608694],
						[60.497824964158482, 3.086164096972027],
						[60.497999962932944, 3.0861973959059279],
						[60.49813047826175, 3.0862223802999509],
						[60.498283131811277, 3.0864584083329922],
						[60.498283132865325, 3.0864584117907743],
						[60.498283135705456, 3.0864584149221113],
						[60.498283137572052, 3.0864584164061708],
						[60.4985776380489, 3.0868668169737545],
						[60.498938725072804, 3.0873723008825609],
						[60.498980414662682, 3.0874667775799884],
						[60.499027613582619, 3.0875861735708301],
						[60.499027616583739, 3.0875861803234161],
						[60.499027625345519, 3.0875861951495729],
						[60.499027629320153, 3.0875862035495074],
						[60.499216527642069, 3.0878973013648916],
						[60.499435921577522, 3.0882833924757573],
						[60.499455418918998, 3.0883250857562334],
						[60.499455435227453, 3.0883251083302379],
						[60.499455472229165, 3.0883251508518024],
						[60.499455493815425, 3.0883251706361996],
						[60.499477688916357, 3.088339066824918],
						[60.499516583876975, 3.0883668630575158],
						[60.499516585743557, 3.0883668645417384],
						[60.499516589396194, 3.088366865699625],
						[60.499516591262768, 3.0883668671838467],
						[60.499794291016521, 3.088547367720778],
						[60.499794313986449, 3.0885473781262776],
						[60.499794361551388, 3.0885473949897517],
						[60.499794386958996, 3.0885473994739936],
						[60.499924961088261, 3.0885557988933576],
						[60.500083237070925, 3.0886084895077563],
						[60.500083237963963, 3.0886084893445913],
						[60.500083238857037, 3.0886084891814187],
						[60.500083239830552, 3.0886084908288671],
						[60.500197140144202, 3.0886445914507532],
						[60.500197152888248, 3.0886445945982222],
						[60.500197180081919, 3.0886445987562459],
						[60.500197193638549, 3.0886445999299426],
						[60.50037495918918, 3.088650198345861],
						[60.500599927206096, 3.0887390851680245],
						[60.500599940030611, 3.0887390901262033],
						[60.500599969171347, 3.0887390975792512],
						[60.500599982727955, 3.0887390987530119],
						[60.500824982622383, 3.0887584990889234],
						[60.500825014932886, 3.0887584968361459],
						[60.500825077284951, 3.0887584817930951],
						[60.500825107326541, 3.0887584690027974],
						[60.501000105928249, 3.0886473692088625],
						[60.501136177854697, 3.088564087741497],
						[60.501269433780756, 3.0885307986058121],
						[60.501386119399228, 3.0885195993589685],
						[60.501386120292267, 3.0885195991957914],
						[60.501386121185313, 3.0885195990326131],
						[60.501386122078365, 3.0885195988694338],
						[60.501616700336328, 3.0884946017627373],
						[60.501719377985573, 3.0885057983969402],
						[60.501719396007395, 3.0885057987546931],
						[60.50171943197055, 3.0885057976595305],
						[60.501719449831398, 3.0885057943959073],
						[60.501816749945959, 3.088480793891021],
						[60.501816751732044, 3.0884807935646572],
						[60.502113951745812, 3.088400193362542],
						[60.502113956211012, 3.0884001925466076],
						[60.502113963274873, 3.0884001894304047],
						[60.502113965953995, 3.0884001889408466],
						[60.502169385964052, 3.0883808161953317],
						[60.502230510648246, 3.0884112792258902],
						[60.502230517953549, 3.0884112815418252],
						[60.502230530858562, 3.0884112883108057],
						[60.502230538163865, 3.0884112906267593],
						[60.502616599075125, 3.0885362769794411],
						[60.502833162874381, 3.0887279458044095],
						[60.502844237142703, 3.0887390199223548],
						[60.503066522812503, 3.0891639926684151],
						[60.503066556000412, 3.089164030415851],
						[60.503066635779689, 3.0891640833814842],
						[60.503066682371085, 3.0891640985997166],
						[60.503502781997184, 3.0892029981159079],
						[60.503502786623336, 3.0892030009216382],
						[60.503502795553793, 3.0892029992898999],
						[60.50350280009949, 3.0892030002848196],
						[60.503516699739329, 3.0892029998577928],
						[60.503527800373817, 3.0892029997157704],
						[60.503527804919514, 3.0892030007106879],
						[60.503527814742988, 3.0892029989157774],
						[60.503527820181709, 3.089202999747545],
						[60.503944409810707, 3.089161299138274],
						[60.503963816619063, 3.0891613000945117],
						[60.503977621177086, 3.0891751033664065],
						[60.503985906597833, 3.0892056529525043],
						[60.503985913734347, 3.0892056717292866],
						[60.503985933365662, 3.0892057083037852],
						[60.503985944074344, 3.0892057264278563],
						[60.504413743965472, 3.0897362263429806],
						[60.504413747698578, 3.0897362293120048],
						[60.504413755245274, 3.0897362370609143],
						[60.50441375905887, 3.0897362418407939],
						[60.504538756414611, 3.089861239107444],
						[60.50477485325122, 3.0901168351749999],
						[60.504774857064795, 3.0901168399549781],
						[60.504774864611463, 3.0901168477040595],
						[60.504774869237615, 3.0901168505100003],
						[60.504835968621428, 3.0901695517160586],
						[60.504835973247566, 3.090169554522014],
						[60.504835980713779, 3.090169560460251],
						[60.504835985339938, 3.0901695632662221],
						[60.504863785009803, 3.0901890638663612],
						[60.504863795155138, 3.0901890693151426],
						[60.504863814552785, 3.0901890803758585],
						[60.504863825591151, 3.0901890856614682],
						[60.504905525608869, 3.0902057857040006],
						[60.504905528288013, 3.0902057852145326],
						[60.50490553202112, 3.0902057881836518],
						[60.504905533887673, 3.0902057896682296],
						[60.505213834129897, 3.0903140884778115],
						[60.505213845980848, 3.0903140917894727],
						[60.505213869602329, 3.0903140966019143],
						[60.505213883239392, 3.0903140995872791],
						[60.505311083122422, 3.0903223983766956],
						[60.505311103823381, 3.0903223982459371],
						[60.505311145876966, 3.0903223923885474],
						[60.505311167310033, 3.090322388472829],
						[60.505397266795569, 3.0902918893504143],
						[60.505397267608139, 3.0902918873763618],
						[60.505397270287283, 3.0902918868868903],
						[60.505397271180321, 3.0902918867237341],
						[60.50574177122855, 3.0901612875627462],
						[60.505741772934179, 3.0901612854255029],
						[60.505741777399415, 3.0901612846096915],
						[60.505741779185499, 3.0901612842833668],
						[60.505761179060073, 3.0901529841119397],
						[60.505761189615669, 3.0901529785321356],
						[60.50576121064644, 3.0901529655616202],
						[60.505761221121595, 3.0901529581709131],
						[60.505772321270591, 3.0901445587056302],
						[60.5057723308527, 3.0901445514780757],
						[60.505772348962928, 3.090144533564267],
						[60.505772358384114, 3.090144522714859],
						[60.50579185205396, 3.0901195301323408],
						[60.50606684592865, 3.0898279372124686],
						[60.506066845848181, 3.0898279354015323],
						[60.506066846741227, 3.0898279352383589],
						[60.506211247150404, 3.0896723368600512],
						[60.506211257303626, 3.0896723222252933],
						[60.506211275011452, 3.0896722952562339],
						[60.506211283378612, 3.0896722809478327],
						[60.506316882227097, 3.0894333812134853],
						[60.506419582094445, 3.0892111831353875],
						[60.506419582094445, 3.0892111831353875],
						[60.506419582013962, 3.0892111813244352],
						[60.506533481716261, 3.0889583834160925],
						[60.506661282030855, 3.0886778835840705],
						[60.50666128365598, 3.0886778796357053],
						[60.506661286825732, 3.0886778699280306],
						[60.506661289343896, 3.0886778658164724],
						[60.506736288227621, 3.0884611672986217],
						[60.506794588150612, 3.0883028690795946],
						[60.506794588963182, 3.0883028671054014],
						[60.506794589614749, 3.088302861509252],
						[60.506794591320336, 3.0883028593718427],
						[60.506916890600841, 3.0879111601951932],
						[60.506916891413383, 3.0879111582209879],
						[60.506916891413383, 3.0879111582209879],
						[60.507019591161196, 3.0875750582310491],
						[60.507019591973737, 3.0875750562568252],
						[60.507019594411389, 3.0875750503341362],
						[60.507019594250394, 3.0875750467121756],
						[60.507064093988987, 3.087391747378796],
						[60.5070640961046, 3.0873917342121451],
						[60.507064098710735, 3.087391711827332],
						[60.507064100013835, 3.0873917006349219],
						[60.507064099867705, 3.0873056005889978],
						[60.507064100178845, 3.086575000240328],
						[60.507064098722211, 3.0865749877267228],
						[60.50706409572846, 3.0865749608885129],
						[60.507064093298268, 3.0865749467271972],
						[60.507039092742275, 3.0864832482180669],
						[60.506955792602874, 3.0861721490157188],
						[60.506905792816305, 3.0859832485783296],
						[60.506905785679308, 3.0859832298006302],
						[60.50690576880676, 3.085983194546071],
						[60.506905759071167, 3.0859831780691955],
						[60.506786259212866, 3.0858276784191796],
						[60.506661279129069, 3.0856610046357176],
						[60.506630796731002, 3.0855721578400921],
						[60.506622403283615, 3.0854917177735435],
						[60.506661292468635, 3.0853556587639162],
						[60.506794563327539, 3.0849334490377394],
						[60.507111175677593, 3.084802985451307],
						[60.507111213192239, 3.0848029585078236],
						[60.507111269797079, 3.0848028952103737],
						[60.507111292378838, 3.0848028563921446],
						[60.507136291178455, 3.0847167611780351],
						[60.507155778363867, 3.0846611955684882],
						[60.507400158716393, 3.0843418212868752],
						[60.507561258313658, 3.0841334217140224],
						[60.507561260019216, 3.0841334195763381],
						[60.507561264242739, 3.0841334133266232],
						[60.507561265948262, 3.0841334111889429],
						[60.507666866367686, 3.0839751117786101],
						[60.507819561686951, 3.0837529186266868],
						[60.50793345588886, 3.0836057245757655],
						[60.508011249593608, 3.083511233747164],
						[60.508225142797649, 3.0832862409428294],
						[60.508255741396376, 3.0832557415995021],
						[60.50845843210741, 3.0830557503243479],
						[60.508533423406419, 3.0829973575422343],
						[60.508533424299458, 3.0829973573788565],
						[60.508533426004945, 3.0829973552410515],
						[60.508533426898005, 3.0829973550776701],
						[60.508719519440973, 3.0828445613661928],
						[60.508861211351622, 3.0827501666671862],
						[60.508861212244646, 3.0827501665037995],
						[60.508861213950141, 3.0827501643659612],
						[60.508861214762604, 3.0827501623914886],
						[60.508908410147811, 3.0827168663704372],
						[60.508994505252844, 3.0826640704376027],
						[60.508994506065328, 3.0826640684631368],
						[60.508994509637446, 3.0826640678095778],
						[60.508994511342948, 3.0826640656717226],
						[60.509197311332734, 3.0825279662208676],
						[60.509358395461931, 3.0824223774094626],
						[60.509555679992829, 3.0823362824768519],
						[60.50961946337403, 3.0823084911886554],
						[60.509802791826054, 3.0822669068320665],
						[60.509997136602024, 3.0823279909137766],
						[60.510052690386878, 3.0823473739145228],
						[60.510252650720474, 3.0825501337615822],
						[60.510344220987449, 3.0826611968483468],
						[60.510402608728256, 3.0828500586305663],
						[60.51040260880881, 3.0828500604417237],
						[60.510402609863, 3.0828500639006315],
						[60.510402609863, 3.0828500639006315],
						[60.510524806667263, 3.0832139559545944],
						[60.510610904801709, 3.0835778467099995],
						[60.510610905694733, 3.0835778465466226],
						[60.510610905775295, 3.0835778483577916],
						[60.510610905855849, 3.0835778501689584],
						[60.51065810290973, 3.0837611371900437],
						[60.51066650060362, 3.0838306233837947],
						[60.510666501657774, 3.0838306268427695],
						[60.510666501818882, 3.0838306304651275],
						[60.510666502792468, 3.0838306321129316],
						[60.510699802328077, 3.0840333298486189],
						[60.510769201463305, 3.0845389226469972],
						[60.510780399782767, 3.0846722075459336],
						[60.51077759979546, 3.0848277965437743],
						[60.51077759979546, 3.0848277965437743],
						[60.510777599876, 3.0848277983549539],
						[60.510771999814395, 3.0854388972761693],
						[60.510772000217031, 3.0854389063320862],
						[60.510772000941813, 3.0854389226327679],
						[60.510772002156962, 3.0854389297141926],
						[60.510794200408235, 3.0855833131849373],
						[60.510791499812385, 3.0857666961946069],
						[60.510777600249718, 3.0863388957308708],
						[60.51077759975928, 3.0863389049501198],
						[60.510777601296454, 3.0863389192763448],
						[60.510777601699047, 3.0863389283322933],
						[60.510799802231837, 3.0864917293709815],
						[60.510799802231837, 3.0864917293709815],
						[60.510844201981051, 3.0867889293857576],
						[60.510844202061563, 3.0867889311969505],
						[60.510844203035113, 3.0867889328448506],
						[60.510883102876001, 3.0870306325041565],
						[60.510883104091086, 3.087030639585651],
						[60.51088310838783, 3.0870306552333027],
						[60.510883109602901, 3.087030662314818],
						[60.511005409651872, 3.0874028613250415],
						[60.511005416708166, 3.0874028782941947],
						[60.51100543333893, 3.0874029081202452],
						[60.51100544299387, 3.0874029227883701],
						[60.511147042718939, 3.0875834229292805],
						[60.511147084676779, 3.0875834554288808],
						[60.511147179968006, 3.0875834927853107],
						[60.511147233301365, 3.0875834976421599],
						[60.511230631202864, 3.0875695980055435],
						[60.511538894848719, 3.0875252027349336],
						[60.511597162683174, 3.0875362968335054],
						[60.511597206683632, 3.0875362942668176],
						[60.511597292093448, 3.0875362713476324],
						[60.511597331716729, 3.0875362513216404],
						[60.511641832104665, 3.087497351586304],
						[60.511641845749274, 3.0874973344839867],
						[60.511641871252436, 3.087497300605877],
						[60.511641882137411, 3.0874972821821265],
						[60.511683478719789, 3.0874056900921252],
						[60.511875175216169, 3.0870583961845277],
						[60.512047371048602, 3.0867501025225801],
						[60.512119568413681, 3.0866390094915457],
						[60.512211260932467, 3.0864973189548803],
						[60.512389054100609, 3.086283427665355],
						[60.512497350320139, 3.0861529321303172],
						[60.512680746284794, 3.085958436259892],
						[60.512680753106991, 3.0859584277081358],
						[60.51268076667089, 3.085958408793291],
						[60.512680772600028, 3.0859584004048446],
						[60.512894572734155, 3.085586199462274],
						[60.51289457354666, 3.085586197487638],
						[60.51289457614525, 3.0855861951863277],
						[60.512894576957756, 3.0855861932116899],
						[60.512919576559341, 3.0855389938291706],
						[60.512919577452379, 3.0855389936658346],
						[60.512919578184359, 3.0855389898798906],
						[60.512919579077384, 3.0855389897165542],
						[60.512944579257912, 3.0854889890110764],
						[60.512944583320419, 3.0854889791378466],
						[60.512944591445468, 3.0854889593913803],
						[60.512944593721919, 3.0854889498448244],
						[60.513080794211135, 3.0849500485814674],
						[60.513080794211135, 3.0849500485814674],
						[60.513080795023626, 3.0849500466068021],
						[60.513144591844615, 3.0846778598405078],
						[60.513252986543407, 3.0843917703018375],
						[60.513394586975828, 3.0840194705824939],
						[60.513394586975828, 3.0840194705824939],
						[60.513394587788319, 3.0840194686077678],
						[60.51344738698976, 3.0838750706253921],
						[60.513489087357463, 3.083766771806935],
						[60.513644586787414, 3.0833611720124474],
						[60.513816885692016, 3.0829167720240873],
						[60.513816886585047, 3.0829167718606705],
						[60.5138168874781, 3.0829167716972545],
						[60.513839086812418, 3.0828583704341148],
						[60.513839092419083, 3.0828583547993964],
						[60.513839097951994, 3.0828583172650759],
						[60.513839099905958, 3.0828583004726995],
						[60.513839099840503, 3.0828500812441031],
						[60.513844543268725, 3.0828445399990017],
						[60.513844551796197, 3.0828445293080806],
						[60.513844569663611, 3.082844505951476],
						[60.51384457721749, 3.0828444936126265],
						[60.51391127424916, 3.0827167971199505],
						[60.514083470548499, 3.0824334035004819],
						[60.514083471441538, 3.0824334033370522],
						[60.514083472253986, 3.0824334013622634],
						[60.514294434396746, 3.082075334257901],
						[60.514430451601157, 3.0822251331713355],
						[60.51443045168174, 3.0822251349827101],
						[60.514544210005667, 3.0823500882704229],
						[60.514563702248843, 3.0824972262947092],
						[60.514610902114121, 3.082861125570604],
						[60.514610903248837, 3.0828611308413683],
						[60.514610904705854, 3.0828611433576851],
						[60.514610905840584, 3.0828611486284463],
						[60.514655400326099, 3.0830389253858157],
						[60.514660899774547, 3.0836083022136194],
						[60.514660899774547, 3.0836083022136194],
						[60.514663699923958, 3.0837722024801955],
						[60.514663700165634, 3.0837722079143957],
						[60.514663700568406, 3.0837722169713886],
						[60.514663700810075, 3.0837722224055724],
						[60.514677599946012, 3.083900012579643],
						[60.514694200376127, 3.08458610136138],
						[60.514694199706902, 3.0846806004433258],
						[60.514694199979509, 3.084775000733174],
						[60.514694199935292, 3.085497199403525],
						[60.514694199922374, 3.0855916873277858],
						[60.514677601999658, 3.0857166743964188],
						[60.514677601428765, 3.0857166818053834],
						[60.51467760028693, 3.0857166966233045],
						[60.514677599716009, 3.0857167040322739],
						[60.514691499892578, 3.0864000012046646],
						[60.514691499946267, 3.08650278880816],
						[60.514677601008778, 3.0866249771204815],
						[60.514677600759882, 3.0866249917750732],
						[60.514677601074617, 3.0866250191094986],
						[60.51467760342431, 3.0866250314627308],
						[60.514738702959001, 3.0869972311064648],
						[60.514738708390361, 3.0869972520268658],
						[60.51473872266417, 3.0869972895916158],
						[60.51473873166767, 3.0869973098587997],
						[60.514855431763159, 3.0871779092397027],
						[60.514855500343842, 3.087177953309161],
						[60.514855645403841, 3.0871779760886988],
						[60.514855723749733, 3.0871779562836186],
						[60.515011223265439, 3.0870557570927852],
						[60.515189022057449, 3.0869195589926672],
						[60.515189039274112, 3.0869195412348911],
						[60.515189068349187, 3.0869195066992079],
						[60.515189080939628, 3.0869194861351152],
						[60.515289079564511, 3.0867083866140197],
						[60.515389076847235, 3.0865028941496497],
						[60.515522372796937, 3.0862722995918546],
						[60.51563626743485, 3.0860751116586242],
						[60.515780759700235, 3.0858834197576921],
						[60.515780760593259, 3.0858834195943476],
						[60.515780761405779, 3.0858834176195358],
						[60.515991856792503, 3.085594523617107],
						[60.516052952830975, 3.0855223285341977],
						[60.516052954536534, 3.0855223263960014],
						[60.516052957947615, 3.0855223221196098],
						[60.516052959653145, 3.0855223199813984],
						[60.516086234834951, 3.0854779538332808],
						[60.516394497578752, 3.0853057752059581],
						[60.516394507241074, 3.0853057697859323],
						[60.516394524618569, 3.0853057556496042],
						[60.516394534200344, 3.0853057484180653],
						[60.516536211423997, 3.0851779684739671],
						[60.516736183298136, 3.0850862820312943],
						[60.516802859704107, 3.0850557922106017],
						[60.516986091464283, 3.085022405818036],
						[60.517177746885579, 3.0850751921476185],
						[60.517177777651469, 3.0850751956504747],
						[60.517177838780555, 3.0850751935984562],
						[60.517177869143758, 3.0850751880435952],
						[60.51743336873664, 3.0849807876227389],
						[60.517433395124982, 3.0849807736634793],
						[60.517433441328222, 3.0849807396424129],
						[60.517433461955662, 3.0849807176056649],
						[60.517522362296539, 3.0848584182627019],
						[60.517522369770042, 3.0848584041113032],
						[60.51752238471704, 3.0848583758084871],
						[60.517522391216978, 3.0848583600089121],
						[60.517575191209865, 3.0846889607214298],
						[60.517575191129303, 3.0846889589098709],
						[60.517575191048763, 3.0846889570983098],
						[60.51769188707943, 3.084302870581749],
						[60.51788348061492, 3.0838889838573635],
						[60.517883484757853, 3.0838889757939678],
						[60.517883491257663, 3.0838889599940091],
						[60.517883492721495, 3.0838889524208795],
						[60.517902993180108, 3.0838167545785673],
						[60.51791408486573, 3.0837778825172832],
						[60.518147370484101, 3.0834001057792295],
						[60.518333470188317, 3.083097304972457],
						[60.518333472706274, 3.0830973008589435],
						[60.518333476849179, 3.0830972927953333],
						[60.518333479447705, 3.0830972904934155],
						[60.518391879319019, 3.0829806904558112],
						[60.51839188005092, 3.082980686669166],
						[60.518391883381341, 3.0829806805805706],
						[60.518391884193804, 3.0829806786055265],
						[60.518439083974911, 3.0828694775582774],
						[60.518597384218253, 3.0824944783300259],
						[60.518597384137685, 3.0824944765184159],
						[60.518783481608608, 3.0820500840007958],
						[60.518805777442864, 3.0820056945853667],
						[60.518830773899985, 3.0819611979311596],
						[60.518830785193629, 3.0819611684681414],
						[60.51883079698402, 3.0819611096921875],
						[60.518830799266794, 3.0819610800522939],
						[60.518822400017648, 3.0818777860933069],
						[60.518791899905601, 3.081063895181912],
						[60.518791900239954, 3.081050000056754],
						[60.518791898621544, 3.0810499839156722],
						[60.518791892705686, 3.0810499521240229],
						[60.518791890194272, 3.0810499361464418],
						[60.518783490062319, 3.0810249368524554],
						[60.518783489088683, 3.0810249352043404],
						[60.518783489088683, 3.0810249352043404],
						[60.518477991080182, 3.0801388402474017],
						[60.518336294347101, 3.0796138508867639],
						[60.518244600986328, 3.0792277811120528],
						[60.518252998615317, 3.0790611175754221],
						[60.518336297837074, 3.0783833283830941],
						[60.518344592180192, 3.0783333632707657],
						[60.518352976610139, 3.0783167947992003],
						[60.518580774716071, 3.0779056974174286],
						[60.518786271233225, 3.0775418058642816],
						[60.518825165908062, 3.077483410357388],
						[60.518825166801086, 3.0774834101937891],
						[60.518825167613471, 3.0774834082185851],
						[60.518825167613471, 3.0774834082185851],
						[60.518875166319361, 3.0774057116711502],
						[60.519086264239171, 3.0771029138778343],
						[60.519086265132181, 3.0771029137142247],
						[60.519086265132181, 3.0771029137142247],
						[60.519233434539984, 3.0768890562666034],
						[60.519388986160543, 3.0768140795122902],
						[60.519569457105682, 3.0767279957174827],
						[60.519683192397025, 3.0767141151926118],
						[60.519866504368117, 3.077036159671009],
						[60.519894201014822, 3.0774083153143921],
						[60.519894201579348, 3.0774083279960682],
						[60.519894208066546, 3.0774083523777791],
						[60.519894211390785, 3.0774083663803213],
						[60.519991501335504, 3.0776889365207851],
						[60.520005400456903, 3.0780611064343577],
						[60.520010900092871, 3.0783194036317219],
						[60.520010900334768, 3.0783194090667645],
						[60.520010900818569, 3.0783194199368347],
						[60.520010901872858, 3.0783194233966023],
						[60.520035898701984, 3.0785222087141375],
						[60.520016499717237, 3.0789971922071526],
						[60.520016499717237, 3.0789971922071526],
						[60.520008099638893, 3.0792305873018346],
						[60.519980401353735, 3.0795332813689185],
						[60.519955401419779, 3.0797860790369529],
						[60.519916500869016, 3.0801416769506562],
						[60.519869202350058, 3.0805193736671699],
						[60.519838702357013, 3.0807277713500372],
						[60.519838701544586, 3.0807277733252265],
						[60.51983870097397, 3.0807277807354541],
						[60.51983870105456, 3.0807277825471289],
						[60.519810900664858, 3.0810527836166677],
						[60.519791501073044, 3.0812721814054371],
						[60.519791501153627, 3.0812721832171115],
						[60.519741501048436, 3.0818416838369846],
						[60.519741500316577, 3.081841687623831],
						[60.519741499826488, 3.0818416968456903],
						[60.519741499987653, 3.0818417004690346],
						[60.519741499639537, 3.0819610886303415],
						[60.519730402172897, 3.082058275180767],
						[60.519680401685335, 3.0824332736963695],
						[60.519633101969802, 3.0827749722450624],
						[60.519619201898145, 3.0828721724723098],
						[60.519619201898145, 3.0828721724723098],
						[60.519619201166265, 3.0828721762591029],
						[60.519619201246833, 3.0828721780707715],
						[60.51960260226322, 3.0830249733917072],
						[60.519527602868884, 3.083469365015862],
						[60.519469203363421, 3.0837832624727439],
						[60.519469201899604, 3.0837832700462506],
						[60.519469200758024, 3.0837832848664459],
						[60.519469200106649, 3.0837832904648694],
						[60.51944979955465, 3.0842305903076128],
						[60.519449799715751, 3.0842305939309416],
						[60.519449799876853, 3.0842305975542859],
						[60.519449799957421, 3.0842305993659487],
						[60.51944979974089, 3.084247197793788],
						[60.519438700073955, 3.084694393496382],
						[60.519424799711913, 3.0850888924890287],
						[60.519419199613615, 3.0852166914466257],
						[60.519419199855236, 3.0852166968816368],
						[60.519419200419023, 3.085216709563309],
						[60.51941920058011, 3.0852167131866386],
						[60.519447000298783, 3.0856028027296509],
						[60.519424799997147, 3.086002788220251],
						[60.519424800158212, 3.0860027918435855],
						[60.519419200073713, 3.0861305905304635],
						[60.519419200395831, 3.0861305977771361],
						[60.519419199985968, 3.0861306088105249],
						[60.519419201120584, 3.0861306140821805],
						[60.519447000026325, 3.0865139098331116],
						[60.519458100177872, 3.0869583053445795],
						[60.519458100822014, 3.0869583198379784],
						[60.519458105601906, 3.0869583463597596],
						[60.519458109818203, 3.0869583601998087],
						[60.519480409137905, 3.0870278600475092],
						[60.519602609010875, 3.0874250594501915],
						[60.519602609010875, 3.0874250594501915],
						[60.519602610064943, 3.0874250629102407],
						[60.519602611038486, 3.0874250645585954],
						[60.519633110551155, 3.0875139649015955],
						[60.519633118581062, 3.087513983523543],
						[60.519633138945075, 3.0875140163274235],
						[60.519633150466625, 3.0875140324843757],
						[60.519674850174781, 3.0875612313385639],
						[60.51967485682863, 3.0875612392537288],
						[60.519674869975219, 3.0875612514606754],
						[60.519674876548535, 3.0875612575641527],
						[60.519785975696266, 3.0876473569612681],
						[60.519949874038403, 3.0877807542931968],
						[60.519949898142983, 3.0877807699755886],
						[60.519949950414812, 3.087780791465232],
						[60.519949977769521, 3.0877807992475246],
						[60.520124975154978, 3.0878001982166654],
						[60.520324969802985, 3.0878279971658484],
						[60.52037496660175, 3.087836297948594],
						[60.520374977398731, 3.0878362978004406],
						[60.520374998180145, 3.0878362994791995],
						[60.520375008084102, 3.087836299494366],
						[60.520577808343909, 3.0878280006717143],
						[60.520577816381255, 3.0878279992017892],
						[60.520577831562932, 3.0878279964252604],
						[60.520577839680776, 3.0878279967670763],
						[60.520755639411064, 3.0877918958031367],
						[60.520855620693474, 3.0877723992852246],
						[60.521022195227502, 3.087769600041534],
						[60.521197186563185, 3.0877807993779758],
						[60.521197188349241, 3.0877807990513193],
						[60.521197189322798, 3.0877808006997602],
						[60.5212971467842, 3.0877862965812826],
						[60.521472067034864, 3.0878751578416228],
						[60.521610931092262, 3.0880640081014494],
						[60.521763724320799, 3.08834179433115],
						[60.521847024185092, 3.088497294035355],
						[60.521847025239111, 3.088497297495663],
						[60.521847029133284, 3.0884973040896622],
						[60.521847031080341, 3.0884973073866586],
						[60.521919231086692, 3.0886112071553709],
						[60.521919233033763, 3.0886112104523789],
						[60.521919235954378, 3.0886112153979028],
						[60.52191923782096, 3.0886112168830957],
						[60.522330433607515, 3.0891779117647502],
						[60.52236923059565, 3.0892390067061828],
						[60.522369246823679, 3.0892390274841106],
						[60.522369284316071, 3.0892390608128002],
						[60.522369303874818, 3.0892390755020043],
						[60.522399897608338, 3.0892557726491603],
						[60.522719293043927, 3.0894584696234504],
						[60.522719308708531, 3.0894584777186442],
						[60.522719344422512, 3.0894584912806926],
						[60.52271936171222, 3.0894584954255717],
						[60.52281930227894, 3.0894779857598573],
						[60.523033159614975, 3.0896890414912752],
						[60.523033166188178, 3.0896890475957912],
						[60.523033178361125, 3.0896890581562011],
						[60.523033184934327, 3.0896890642607193],
						[60.523266585010113, 3.0898529646024699],
						[60.523266585903144, 3.0898529644391846],
						[60.523266587769712, 3.0898529659245075],
						[60.523266588662764, 3.0898529657612177],
						[60.523705488932251, 3.0901473651335114],
						[60.523705538363679, 3.0901473834951472],
						[60.523705637644554, 3.0901473890891338],
						[60.523705689441023, 3.0901473796187808],
						[60.523716788869834, 3.0901418796093112],
						[60.523716802917058, 3.0901418715610212],
						[60.523716829064526, 3.0901418521671675],
						[60.523716841245211, 3.0901418426335159],
						[60.524008441415596, 3.0898529427196415],
						[60.524008455792504, 3.089852921824277],
						[60.524008481786701, 3.0898528787114876],
						[60.524008491617927, 3.0898528568206456],
						[60.524166854964797, 3.089311281212908],
						[60.524188914710741, 3.0893085002962795],
						[60.524597205201673, 3.0892973994327484],
						[60.524597210640408, 3.0892974002648637],
						[60.524597223223459, 3.0892973997904773],
						[60.524597227608176, 3.0892973971619533],
						[60.524616537299913, 3.0892946240540868],
						[60.524730402641161, 3.0899389344676007],
						[60.524730405883673, 3.0899389466615874],
						[60.524730413181203, 3.0899389690742654],
						[60.524730417316739, 3.0899389811049431],
						[60.524833115100023, 3.0901722764414457],
						[60.524933107760454, 3.0904361573105672],
						[60.525063702359923, 3.0910472288046291],
						[60.525066500123302, 3.0910833082125846],
						[60.525066500357021, 3.0910888887789953],
						[60.525063701641542, 3.0911138729120151],
						[60.524958101568878, 3.0917860689046184],
						[60.524924803253832, 3.0919943681685553],
						[60.52492480097694, 3.0919943777182688],
						[60.524924800156292, 3.0919943997887152],
						[60.524924799665506, 3.0919944090119436],
						[60.524935899987433, 3.0922499982480471],
						[60.524916500158859, 3.0926110883104321],
						[60.524916500239321, 3.0926110901224275],
						[60.524916500239321, 3.0926110901224275],
						[60.524902599784355, 3.0929055905283582],
						[60.524902601401386, 3.0929056066731406],
						[60.524902603661921, 3.092905637313919],
						[60.524902606984554, 3.0929056513202693],
						[60.52506370633553, 3.0935083486422745],
						[60.525110904683196, 3.0937083466892634],
						[60.525110907764478, 3.0937083552597544],
						[60.525110912953593, 3.0937083707519006],
						[60.525110916927936, 3.0937083791591986],
						[60.525158113900346, 3.0938167737875069],
						[60.525302610614794, 3.0942333655812293],
						[60.525302614669542, 3.094233375800663],
						[60.525302622618142, 3.0942333926154912],
						[60.525302628378562, 3.0942334006965413],
						[60.525508127758123, 3.0945834015361182],
						[60.525508168169686, 3.0945834398207119],
						[60.525508264915317, 3.094583489733123],
						[60.525508320275939, 3.0945834997120696],
						[60.525866719295621, 3.0945473998293176],
						[60.525958318328243, 3.0945390990543955],
						[60.525958359979896, 3.0945390841368607],
						[60.525958434602373, 3.0945390412738787],
						[60.52595846741238, 3.0945390097043046],
						[60.526161267016676, 3.0942307100956552],
						[60.526341862993448, 3.0939557161752798],
						[60.526405759109878, 3.0938723221217219],
						[60.526405759922511, 3.0938723201464406],
						[60.526405761547736, 3.0938723161958808],
						[60.526405763333855, 3.0938723158694823],
						[60.526419555908049, 3.0938529268526906],
						[60.52644734766811, 3.0938223333776378],
						[60.526447353758591, 3.0938223286110564],
						[60.526447363831736, 3.0938223121559663],
						[60.526447369841762, 3.0938223055773029],
						[60.526675168987609, 3.0934584069097335],
						[60.526855718069591, 3.0931723876873418],
						[60.526977823110855, 3.0931584997442925],
						[60.527194241931092, 3.093133519359049],
						[60.527305404026883, 3.0936611414855433],
						[60.527305407188607, 3.0936611518687327],
						[60.527305414405134, 3.0936611724719016],
						[60.52730541845991, 3.0936611826918994],
						[60.527344190209412, 3.0937472218247266],
						[60.527327605297153, 3.0938221570543192],
						[60.527327602939735, 3.0938221647924893],
						[60.527327600904044, 3.093822179779198],
						[60.527327600332704, 3.0938221871909612],
						[60.527324800860583, 3.0938638811690367],
						[60.527305401147053, 3.094024976362852],
						[60.527305401227487, 3.0940249781749833],
						[60.527305401388332, 3.0940249817992704],
						[60.527305401468766, 3.0940249836114035],
						[60.527252601250964, 3.0946277809500411],
						[60.527241500891719, 3.0947332790989801],
						[60.527241500802845, 3.0947332973835247],
						[60.52724150322382, 3.0947333316509251],
						[60.527241505814089, 3.0947333494459328],
						[60.527263706142897, 3.09481945075111],
						[60.527305406370544, 3.0949806508366637],
						[60.527305408478284, 3.0949806577588874],
						[60.527305412693806, 3.0949806716033277],
						[60.527305415614201, 3.0949806765502217],
						[60.527455412715597, 3.0953389708824197],
						[60.527558109928904, 3.0956472625230749],
						[60.527558118931218, 3.0956472828005497],
						[60.527558140266848, 3.0956473172664141],
						[60.52755815276096, 3.0956473350791107],
						[60.527683152995927, 3.0957834342895447],
						[60.527683168088778, 3.0957834498007224],
						[60.527683200632005, 3.0957834730849925],
						[60.527683219055852, 3.0957834825070925],
						[60.527752718830094, 3.0958140837749299],
						[60.527752782960356, 3.0958140885005836],
						[60.527752899708823, 3.095814061691073],
						[60.527752953952344, 3.0958140262052338],
						[60.527805754082316, 3.0957501277820829],
						[60.527805754894956, 3.0957501258067595],
						[60.527805756600664, 3.0957501236682821],
						[60.527805756600664, 3.0957501236682821],
						[60.527886256637672, 3.0956473235042927],
						[60.527886265978808, 3.0956473108364997],
						[60.527886281008442, 3.0956472843413643],
						[60.527886286696969, 3.0956472705140334],
						[60.528022387236291, 3.095280670247635],
						[60.528022388048932, 3.095280668272284],
						[60.528202987225285, 3.0947889712151251],
						[60.528214084220643, 3.0947611771076069],
						[60.528225182955651, 3.0947361814512999],
						[60.528225187751019, 3.0947361677868344],
						[60.528225194662625, 3.0947361409474943],
						[60.528225197671887, 3.0947361276094214],
						[60.52831688876703, 3.0940583944322975],
						[60.528550141028688, 3.0938279411344394],
						[60.528550159710576, 3.0938279157972652],
						[60.52855018863476, 3.0938278575308384],
						[60.52855019806443, 3.0938278265769816],
						[60.528572365214039, 3.0936613760558322],
						[60.528652721659512, 3.0936918838411978],
						[60.528947115609967, 3.0938279822956249],
						[60.528947125674783, 3.09382798593695],
						[60.52894714572399, 3.0938279914073545],
						[60.528947156681852, 3.093827994885447],
						[60.529097151167484, 3.0938612941680694],
						[60.529116640581321, 3.093866890673838],
						[60.529472135937553, 3.0939862897969763],
						[60.529472138616704, 3.0939862893073315],
						[60.529472143242806, 3.0939862921157855],
						[60.529472145028905, 3.0939862917893555],
						[60.529549873825985, 3.0940084717152163],
						[60.529755433207626, 3.0943223105658952],
						[60.529755434181119, 3.0943223122149579],
						[60.529755437994567, 3.0943223169989662],
						[60.529755438968046, 3.094322318648028],
						[60.529858139352157, 3.0944612180684379],
						[60.529858140325622, 3.0944612197175134],
						[60.52999981683174, 3.0946500887563992],
						[60.530024805060201, 3.0947417460867683],
						[60.530044204089037, 3.0948389394046782],
						[60.53014700376081, 3.0953500387557882],
						[60.530147003921634, 3.0953500423803835],
						[60.53014700594894, 3.0953500474909328],
						[60.530147006029374, 3.095350049303228],
						[60.530224806194781, 3.0956528503268061],
						[60.530224808141703, 3.0956528536250625],
						[60.530224810410239, 3.0956528641725525],
						[60.530224812357169, 3.0956528674708075],
						[60.530444212197231, 3.096258367939126],
						[60.530444212277636, 3.0962583697514416],
						[60.530444213170682, 3.0962583695882833],
						[60.530510909990021, 3.0964361624497401],
						[60.530547006795295, 3.0965667528447303],
						[60.530547007768732, 3.096566754493923],
						[60.53054700882258, 3.0965667579554186],
						[60.530547008902971, 3.0965667597677458],
						[60.530680408636648, 3.0969944598778487],
						[60.530680413664641, 3.0969944717484954],
						[60.530680423640234, 3.096994493677458],
						[60.530680430454325, 3.0969945052218226],
						[60.530894229832754, 3.097341804128749],
						[60.530894249873036, 3.097341829700567],
						[60.53089429929485, 3.0973418681756679],
						[60.53089432615802, 3.0973418851930341],
						[60.530949906705381, 3.0973640782644898],
						[60.531124891101655, 3.0974779672281101],
						[60.531124892968144, 3.0974779687142142],
						[60.531124897594175, 3.0974779715232845],
						[60.53112490035371, 3.0974779728462716],
						[60.531294300006905, 3.0975751734591261],
						[60.53129433296067, 3.0975751857102267],
						[60.531294400091568, 3.097575197199617],
						[60.531294435161804, 3.0975751962747395],
						[60.531341675669033, 3.0975669069997056],
						[60.531399921159938, 3.0975918843683505],
						[60.531399943968566, 3.0975918911644174],
						[60.53139999015734, 3.0975918973439209],
						[60.531400013617862, 3.0975918985397173],
						[60.531791664013291, 3.097564103236536],
						[60.531841618918946, 3.0975862833925176],
						[60.531841657963305, 3.0975862908770919],
						[60.531841734596291, 3.0975862933225704],
						[60.531841773890619, 3.0975862861448036],
						[60.532105674241961, 3.097480785639652],
						[60.53210567602806, 3.0974807853133828],
						[60.532105678707232, 3.0974807848239743],
						[60.532105680412947, 3.097480782685289],
						[60.532213979569796, 3.0974334831512795],
						[60.532213985740782, 3.0974334801968939],
						[60.5322139971897, 3.0974334744512451],
						[60.532214003360693, 3.0974334714968594],
						[60.532241802663997, 3.0974168715262969],
						[60.53224181923008, 3.097416859364543],
						[60.532241848629269, 3.0974168320687445],
						[60.532241862355427, 3.0974168167715619],
						[60.532489061800845, 3.0970723161534419],
						[60.532489065292658, 3.0970723136884093],
						[60.532489067730658, 3.0970723077616582],
						[60.532489070329426, 3.0970723054597586],
						[60.532689070274927, 3.0967473051003882],
						[60.532689071900251, 3.0967473011491609],
						[60.532689076124321, 3.0967472948960304],
						[60.532689077830028, 3.096747292757267],
						[60.53273347811308, 3.0966611912970095],
						[60.532733481444104, 3.096661185207005],
						[60.532733487132703, 3.0966611713777232],
						[60.532733488677628, 3.0966611656140599],
						[60.532764087627363, 3.0965722666771853],
						[60.53292518669771, 3.0961389707484623],
						[60.532925188403411, 3.0961389686096346],
						[60.532989088181843, 3.0959639690572467],
						[60.533102985079232, 3.0956611768978122],
						[60.533119579744721, 3.0956250874448195],
						[60.533139079321742, 3.0955861904685751],
						[60.533139079241323, 3.0955861886561138],
						[60.533139079241323, 3.0955861886561138],
						[60.53313908013439, 3.0955861884929132],
						[60.533344580265364, 3.0951667887463614],
						[60.533547380139481, 3.0947528870832754],
						[60.53354738176472, 3.0947528831318656],
						[60.533547385827852, 3.0947528732533187],
						[60.533547388346165, 3.0947528691386657],
						[60.533566888429647, 3.0947000697303753],
						[60.533566890626275, 3.0947000583657696],
						[60.533566896725155, 3.0947000334976362],
						[60.533566898921777, 3.0947000221330323],
						[60.533589091666315, 3.0945028813358348],
						[60.533791855382219, 3.0942529261588092],
						[60.533791867161021, 3.0942529075609926],
						[60.53379188617297, 3.0942528693690621],
						[60.533791894299156, 3.0942528496116912],
						[60.533897375956414, 3.0938418174208895],
						[60.53400012089137, 3.0937640586967246],
						[60.534000126981816, 3.0937640539289575],
						[60.534000139975348, 3.0937640424176407],
						[60.53400014598536, 3.0937640358373431],
						[60.534038829682942, 3.0937225688410037],
						[60.53406371397395, 3.0937861733114365],
						[60.534063737096204, 3.0937862074562306],
						[60.534063797307766, 3.0937862658837418],
						[60.534063834316626, 3.0937862883539489],
						[60.534222091098847, 3.0938418733029507],
						[60.534488758679217, 3.0941084418527445],
						[60.534488769878372, 3.0941084507690446],
						[60.534488793169714, 3.0941084684384181],
						[60.534488806154975, 3.0941084770282288],
						[60.534660982531307, 3.094200164265565],
						[60.534935934768491, 3.0944529199637527],
						[60.535008119335181, 3.0946083843756473],
						[60.535008154468997, 3.0946084254628041],
						[60.535008240739486, 3.0946084827848162],
						[60.535008292849589, 3.0946085006690192],
						[60.535316692059631, 3.0946196006214519],
						[60.535386091846263, 3.094622399808161],
						[60.535386133819522, 3.0946223921360518],
						[60.53538620989783, 3.0946223617844208],
						[60.535386244815527, 3.0946223371290511],
						[60.535525144625446, 3.0944751366690273],
						[60.535525148117202, 3.0944751342034515],
						[60.535525154127264, 3.0944751276229763],
						[60.535525156645548, 3.0944751235080439],
						[60.535630755594866, 3.0943418243976408],
						[60.53583625625982, 3.0940862256475099],
						[60.535836263002032, 3.0940862152784021],
						[60.535836275593418, 3.0940861947034439],
						[60.535836281442585, 3.0940861844975913],
						[60.53589188069175, 3.0939667851791186],
						[60.535950174226286, 3.0938473003705735],
						[60.536141866388945, 3.0935612112172302],
						[60.536141867201557, 3.0935612092413138],
						[60.53614186801417, 3.0935612072654171],
						[60.536141868907215, 3.0935612071021472],
						[60.536280758544819, 3.0933418218704753],
						[60.536411247399933, 3.093200134708515],
						[60.536411248292971, 3.093200134545234],
						[60.536411249186017, 3.0932001343819522],
						[60.536411249105562, 3.0932001325693128],
						[60.536644548852074, 3.0929390329637449],
						[60.536644564041936, 3.0929390100852938],
						[60.536644588089928, 3.0929389636587699],
						[60.53664459597455, 3.0929389384613208],
						[60.536669595992777, 3.0928111387509318],
						[60.536669596805382, 3.0928111367749813],
						[60.536669596724927, 3.09281113496233],
						[60.536730789666493, 3.0924667720790526],
						[60.536850170612112, 3.0922695041794213],
						[60.536850179630996, 3.0922694842563478],
						[60.536850194096644, 3.0922694450634389],
						[60.536850197676841, 3.0922694243075646],
						[60.536880798054312, 3.0920278258712397],
						[60.536880798705972, 3.0920278202699163],
						[60.536880799928817, 3.0920278072546235],
						[60.536880799767893, 3.0920278036292834],
						[60.536889100040646, 3.0914361126639989],
						[60.536922398977509, 3.0911194218833069],
						[60.53692239930723, 3.0911194090312923],
						[60.536922399315067, 3.0911193889286288],
						[60.536922398832225, 3.091119378052638],
						[60.536847400255489, 3.0904332873825897],
						[60.53684189988828, 3.0902055949295235],
						[60.536841899727307, 3.0902055913042141],
						[60.536841900459379, 3.0902055875155354],
						[60.53684189940536, 3.0902055840535763],
						[60.536775201279767, 3.0893778052360354],
						[60.536786297533013, 3.0892944255945585],
						[60.536786298426065, 3.0892944254311647],
						[60.536786299238592, 3.0892944234551236],
						[60.536786299158102, 3.0892944216424572],
						[60.536858479030137, 3.0886308016775619],
						[60.537186097713857, 3.0886334992598181],
						[60.537186104045617, 3.0886334999285951],
						[60.537186113869055, 3.0886334981310481],
						[60.537186119307776, 3.0886334989632402],
						[60.537299951597781, 3.0886224056345832],
						[60.5376331368252, 3.0888307336200422],
						[60.537810904134183, 3.0892972474328126],
						[60.537863700604781, 3.0897389249213969],
						[60.53786370157831, 3.0897389265707198],
						[60.53786920211143, 3.0897806256734834],
						[60.537869202191914, 3.089780627486197],
						[60.537869202272411, 3.0897806292989056],
						[60.537869202352901, 3.0897806311116183],
						[60.537935900255938, 3.0902083142414738],
						[60.53793309983763, 3.0905082988162302],
						[60.537933101213561, 3.0905083095291839],
						[60.537933103152888, 3.0905083329311691],
						[60.537933104609301, 3.0905083454568567],
						[60.538066505247151, 3.0910917445568726],
						[60.538066506220666, 3.0910917462062479],
						[60.538066507355133, 3.0910917514810938],
						[60.538066507435602, 3.0910917532938225],
						[60.538074804094443, 3.091122243490791],
						[60.538077603096298, 3.0911389332996713],
						[60.538077627836572, 3.0911389835978138],
						[60.538077701678546, 3.0911390651184765],
						[60.538077750699735, 3.0911390945282378],
						[60.538088851363128, 3.0911418940097417],
						[60.538088854122726, 3.0911418953324388],
						[60.538088859561448, 3.0911418961650643],
						[60.538088863133623, 3.0911418955116639],
						[60.538483213283165, 3.091216886585729],
						[60.538527618759908, 3.0912529104735733],
						[60.538641501084449, 3.091805625599549],
						[60.538655400295958, 3.0920333077541451],
						[60.538660899808299, 3.0923056036099608],
						[60.538660899888768, 3.0923056054227236],
						[60.53866919997602, 3.0926555998571357],
						[60.538658099703902, 3.0929443924774556],
						[60.538658099864811, 3.0929443961029848],
						[60.538658100186602, 3.092944403354065],
						[60.53865810026705, 3.0929444051668318],
						[60.538666499828928, 3.0932250047202592],
						[60.538672000104732, 3.0935582980449574],
						[60.538660899750354, 3.0938555934074774],
						[60.538660899991655, 3.0938555988458014],
						[60.538660899661679, 3.0938556116984746],
						[60.538660900876486, 3.093855618786288],
						[60.538691500827213, 3.0941889174302131],
						[60.538691500907646, 3.0941889192429852],
						[60.538710901018817, 3.0943972176401067],
						[60.538738701318273, 3.0947667141673691],
						[60.538738700827359, 3.0947667233945193],
						[60.538738704149907, 3.0947667374069803],
						[60.538738705364651, 3.094766744494847],
						[60.538927604145542, 3.0955833427457473],
						[60.538947003628714, 3.0956806396817367],
						[60.538947005736453, 3.0956806466064553],
						[60.538947009058916, 3.0956806606191054],
						[60.538947012059708, 3.0956806673805923],
						[60.538974811594912, 3.0957583679800651],
						[60.538974821409951, 3.0957583862885265],
						[60.538974844692618, 3.0957584240653429],
						[60.538974858972914, 3.0957584415576571],
						[60.539055455754685, 3.0958390398593831],
						[60.53942485294035, 3.0962390362148802],
						[60.539424930859767, 3.0962390676618416],
						[60.53942508356274, 3.0962390598557259],
						[60.539425159239386, 3.0962390204394188],
						[60.539591859418771, 3.096019521120466],
						[60.539591860311823, 3.0960195209572396],
						[60.539591860231404, 3.0960195191444133],
						[60.539847355976683, 3.0956807247890819],
						[60.539864052305489, 3.0956612305243674],
						[60.539864061566135, 3.0956612160388444],
						[60.539864080006986, 3.0956611852549432],
						[60.539864087401114, 3.095661169283038],
						[60.539872387363815, 3.0956389698442845],
						[60.539872388256875, 3.0956389696810422],
						[60.540055787644746, 3.0951361686386307],
						[60.540055787644746, 3.0951361686386307],
						[60.540055789350433, 3.095136166499258],
						[60.540055789350433, 3.095136166499258],
						[60.540183482267189, 3.0947722864560858],
						[60.54026127105066, 3.0946445039217831],
						[60.540261271943713, 3.0946445037585155],
						[60.540261272675899, 3.0946444999695264],
						[60.540261274381578, 3.0946444978301271],
						[60.540322351043102, 3.094536238306107],
						[60.540497270129322, 3.0944168946196697],
						[60.540591718127949, 3.0944084995519594],
						[60.540772052390146, 3.0943919130285362],
						[60.540877600194818, 3.0947750260678326],
						[60.540877600186498, 3.0949944004864278],
						[60.540877599847633, 3.095469394236988],
						[60.540863702227135, 3.0956860582072796],
						[60.540824811939366, 3.0957916307449662],
						[60.54077201615587, 3.0959332207955979],
						[60.540608121164034, 3.0962610105361477],
						[60.540438722354601, 3.0965943094834798],
						[60.540438720648886, 3.0965943116227925],
						[60.540438718130531, 3.0965943157382085],
						[60.540438717398295, 3.0965943195271795],
						[60.540383117073382, 3.0967193183917896],
						[60.540322024844201, 3.0968555018465191],
						[60.540133135820298, 3.0971276861986365],
						[60.540133134927238, 3.0971276863618349],
						[60.540133134927238, 3.0971276863618349],
						[60.540133134114591, 3.097127688337896],
						[60.539877634211443, 3.0975054871855474],
						[60.539877630880397, 3.0975054932768424],
						[60.539877625030989, 3.0975055034834069],
						[60.539877621699944, 3.097505509574717],
						[60.539874821673564, 3.097511008580748],
						[60.539874813707726, 3.0975110319668264],
						[60.539874802973557, 3.0975110741341738],
						[60.539874800366007, 3.0975110965411368],
						[60.539872000011158, 3.0976860945214497],
						[60.539847000113618, 3.0983721937369704],
						[60.539846999702824, 3.0983722047772484],
						[60.53984700245347, 3.0983722262051518],
						[60.539847002935744, 3.0983722370822648],
						[60.539855403072174, 3.0984167362244066],
						[60.53985540412598, 3.0984167396869462],
						[60.53985540526017, 3.0984167449623525],
						[60.539855406314004, 3.0984167484248886],
						[60.539860905363632, 3.0984389443476235],
						[60.539872004062779, 3.0984944384105466],
						[60.539985902756165, 3.0990944353270247],
						[60.54002760301433, 3.0993278343556634],
						[60.540027605282638, 3.0993278449065818],
						[60.540027610631924, 3.0993278640324204],
						[60.540027613712915, 3.099327872607339],
						[60.540158112299395, 3.0996583690993171],
						[60.540316510903871, 3.1001139657193235],
						[60.540316510984226, 3.1001139675322062],
						[60.540316511877286, 3.1001139673690861],
						[60.540316511957641, 3.1001139691819688],
						[60.540344212416649, 3.1001889697105978],
						[60.54034421736403, 3.1001889797723381],
						[60.540344228232264, 3.1001890015455795],
						[60.540344234072727, 3.1001890114441988],
						[60.540377625761337, 3.1002390004419267],
						[60.540766521283444, 3.1010278880856328],
						[60.54076652623079, 3.1010278981476338],
						[60.54076653880464, 3.1010279177823588],
						[60.540766546431151, 3.1010279273550707],
						[60.540805442567773, 3.1010751233544522],
						[60.540863723844481, 3.1011528985676393],
						[60.541213714251604, 3.1020194743810201],
						[60.541224812017902, 3.1020472690532923],
						[60.541230409673283, 3.1020639637857448],
						[60.541230410646683, 3.1020639654356281],
						[60.541230412673777, 3.1020639705483055],
						[60.541230413727511, 3.1020639740111302],
						[60.541305414408022, 3.1022528737386614],
						[60.541305415381402, 3.1022528753885417],
						[60.541305414568669, 3.1022528773645504],
						[60.541305416354817, 3.1022528770384161],
						[60.541613716117539, 3.1029778772870538],
						[60.541613720171675, 3.1029778875126777],
						[60.54161372893207, 3.1029779023619395],
						[60.541613733798954, 3.1029779106115383],
						[60.541663732377955, 3.1030529082204161],
						[60.54183592992581, 3.1033307052569534],
						[60.541835931872548, 3.1033307085568307],
						[60.541835934792694, 3.1033307135066561],
						[60.541835936659119, 3.1033307149935561],
						[60.54211093738116, 3.1037195148991854],
						[60.542110948820678, 3.1037195292597821],
						[60.542110976084686, 3.1037195553528472],
						[60.542110991016116, 3.1037195672483469],
						[60.542174824282547, 3.1037612235275396],
						[60.542205404840978, 3.1038917451800181],
						[60.542205408975342, 3.1038917572189448],
						[60.542205418137172, 3.1038917811337372],
						[60.542205423164624, 3.1038917930096206],
						[60.542388723107457, 3.1042389921111262],
						[60.542388724161135, 3.1042389955741267],
						[60.54238872708121, 3.1042390005241236],
						[60.542388729027941, 3.1042390038241097],
						[60.542560928589573, 3.1045251029008085],
						[60.542561016321244, 3.1045251526836433],
						[60.542561188100727, 3.104525148743889],
						[60.542561274095291, 3.1045250983213162],
						[60.542727948072631, 3.1042307437044601],
						[60.543011144216344, 3.1040585073611537],
						[60.543094374154698, 3.1040695981612538],
						[60.543094376021124, 3.1040695996482492],
						[60.543094376914183, 3.1040695994852228],
						[60.543358222945315, 3.1041001929017011],
						[60.543458105174935, 3.1041751042912415],
						[60.543488699625925, 3.1047417042226151],
						[60.543488699714892, 3.1048055999369479],
						[60.543488699795198, 3.1048056017500407],
						[60.543488699955773, 3.1048056053761894],
						[60.543488700036065, 3.1048056071892649],
						[60.543491500482212, 3.1048778087458078],
						[60.543491499589152, 3.1048778089088156],
						[60.543491499669436, 3.1048778107218897],
						[60.543491499749727, 3.1048778125349648],
						[60.543533099779637, 3.1055667053045575],
						[60.543533100058937, 3.1057167001041841],
						[60.543533102888702, 3.1057167233482876],
						[60.543533113986946, 3.1057167706716804],
						[60.543533123068194, 3.1057167927749063],
						[60.543902621086502, 3.1064250902856578],
						[60.544002602281267, 3.1066306492035909],
						[60.544010899803823, 3.1068417072057977],
						[60.544024799656874, 3.1072972056957191],
						[60.544030399975796, 3.1075417050008576],
						[60.544030402724992, 3.1075417264323812],
						[60.544030414635408, 3.1075417717811069],
						[60.544030422743049, 3.1075417922349993],
						[60.544352620975609, 3.1081583892800908],
						[60.544497000961307, 3.1084556481122831],
						[60.544505400245761, 3.108769405052116],
						[60.544505400245761, 3.108769405052116],
						[60.544522000286854, 3.1093667055294882],
						[60.544522004009067, 3.1093667286117652],
						[60.544522015918886, 3.1093667739618764],
						[60.54452202499953, 3.1093667960668046],
						[60.544799822862068, 3.1098722933495995],
						[60.545005402131451, 3.1102806517325901],
						[60.545021999968775, 3.1107306037536011],
						[60.545022000223078, 3.1107417003421611],
						[60.545022000303291, 3.1107417021553414],
						[60.545022000463725, 3.1107417057817024],
						[60.54502199957065, 3.1107417059445566],
						[60.54503589982599, 3.111191705961712],
						[60.545035901280507, 3.1111917184911775],
						[60.545035906055929, 3.1111917450375604],
						[60.545035909296601, 3.1111917572413357],
						[60.54524700924182, 3.111888957443032],
						[60.545247038279612, 3.1118890033278226],
						[60.545247117067191, 3.111889074870795],
						[60.54524716576352, 3.1118890970653981],
						[60.545344213958224, 3.111905771718694],
						[60.545360900087111, 3.1121028144078728],
						[60.545374800307442, 3.1123694095098484],
						[60.545374800868785, 3.1123694222022977],
						[60.545374805403391, 3.1123694433095328],
						[60.545374807670683, 3.1123694538631526],
						[60.545558107627471, 3.1130167544441032],
						[60.54555811078778, 3.1130167648350326],
						[60.545558117841168, 3.1130167818276542],
						[60.545558121814381, 3.1130167902425709],
						[60.545697021831955, 3.1132889903487664],
						[60.546019201899483, 3.1139278513341324],
						[60.546044200424461, 3.1146361063505359],
						[60.546049800104839, 3.1148417054931676],
						[60.546049802852892, 3.114841726926711],
						[60.54604981273426, 3.1148417671667872],
						[60.546049820840793, 3.1148417876238281],
						[60.546141521066822, 3.1150278875182584],
						[60.546141521146986, 3.1150278893315284],
						[60.546141521146986, 3.1150278893315284],
						[60.54614152204006, 3.1150278891687857],
						[60.546508099967376, 3.115752848740108],
						[60.546508099899718, 3.1159194002038046],
						[60.546508099979867, 3.1159194020170817],
						[60.546508100140151, 3.1159194056436594],
						[60.546508100140151, 3.1159194056436594],
						[60.546527600049544, 3.1165417043938044],
						[60.546530399672655, 3.1166639057840131],
						[60.546530403233362, 3.1166639252420558],
						[60.546530413274475, 3.1166639691098945],
						[60.546530421380744, 3.116663989567706],
						[60.546588720774174, 3.1167806882337645],
						[60.546588720854331, 3.1167806900470469],
						[60.54658872182754, 3.1167806916976351],
						[60.546588722800749, 3.1167806933482218],
						[60.547005399821259, 3.1175778499432192],
						[60.547005399600131, 3.1176472009172369],
						[60.547005399680245, 3.1176472027305535],
						[60.547005399840494, 3.1176472063571921],
						[60.547005399920607, 3.1176472081705082],
						[60.547035899831023, 3.1184500080527955],
						[60.547035944758242, 3.1184500876099372],
						[60.547036081008585, 3.1184501761252288],
						[60.547036172411822, 3.1184501868966534],
						[60.547397224804172, 3.1183113049932474],
						[60.547486074716311, 3.1183223985997648],
						[60.547486109786966, 3.1183223976959074],
						[60.547486175714994, 3.1183223820321175],
						[60.547486208438677, 3.1183223687601775],
						[60.547764008248159, 3.1181445670970609],
						[60.547764023923087, 3.118144555102194],
						[60.547764054139556, 3.1181445258350515],
						[60.547764066974999, 3.1181445107014829],
						[60.547936174635957, 3.1178835498072184],
						[60.548122118731293, 3.1179640828665156],
						[60.548183216795465, 3.1179918822575501],
						[60.548183264930096, 3.1179918917694867],
						[60.548183359184634, 3.1179918855686619],
						[60.548183405304506, 3.117991869855905],
						[60.548386099363022, 3.1178669362252842],
						[60.548583193221681, 3.1179918685213952],
						[60.548583219842186, 3.1179918801243143],
						[60.548583274548868, 3.1179918957512016],
						[60.54858330531431, 3.1179918992871114],
						[60.548830536219704, 3.1179863001991066],
						[60.549019266379226, 3.1181251504395107],
						[60.549185953215861, 3.1183057352539785],
						[60.54918595418907, 3.1183057369047371],
						[60.549185956055346, 3.1183057383928263],
						[60.549185956948442, 3.118305738230148],
						[60.549283156676665, 3.1184057388154809],
						[60.549283156756793, 3.1184057406289236],
						[60.549283157649853, 3.1184057404662533],
						[60.549283158542956, 3.1184057403035781],
						[60.549374859178073, 3.1184973414708899],
						[60.549374861124463, 3.1184973447724631],
						[60.549374865750096, 3.1184973475860063],
						[60.5493748676965, 3.1184973508875573],
						[60.549624845660105, 3.1187168316398712],
						[60.549727629838053, 3.1188834047143299],
						[60.549727635677186, 3.1188834146191877],
						[60.54972764898146, 3.1188834304766351],
						[60.549727655633589, 3.1188834384053572],
						[60.550122056398251, 3.1192945371475687],
						[60.55012206029101, 3.1192945437509376],
						[60.550122069622375, 3.1192945511918779],
						[60.550122075221182, 3.119294555656448],
						[60.550177664990819, 3.1193390472339613],
						[60.550249819325039, 3.1194140018181868],
						[60.550327606203489, 3.1197167499994327],
						[60.550327607176648, 3.1197167516502962],
						[60.55038040676385, 3.119916751302056],
						[60.550380408870396, 3.1199167582308331],
						[60.55038041300341, 3.1199167702748585],
						[60.550380416083144, 3.1199167788545035],
						[60.550552610248289, 3.120325062210449],
						[60.550577605948291, 3.1204250491197243],
						[60.550577614054049, 3.1204250695810134],
						[60.550577632864709, 3.1204251082021881],
						[60.550577645355808, 3.1204251260368272],
						[60.550627644628598, 3.1204862263964128],
						[60.550627733580612, 3.1204862632141177],
						[60.55062790196159, 3.1204862435218286],
						[60.550627979604428, 3.1204861873370766],
						[60.550666879552942, 3.1204056868899395],
						[60.55070576560955, 3.1203251174729205],
						[60.550952942229145, 3.1200751403173577],
						[60.550952942229145, 3.1200751403173577],
						[60.550952943122233, 3.1200751401547193],
						[60.551075143007864, 3.1199501396744496],
						[60.551075148126088, 3.1199501332579325],
						[60.551075159255653, 3.119950120262275],
						[60.551075164453991, 3.1199501156593223],
						[60.551230763882444, 3.1197279148813859],
						[60.551230763882444, 3.1197279148813859],
						[60.551230764775532, 3.1197279147187378],
						[60.551230764695418, 3.1197279129051831],
						[60.551444565034949, 3.1194168133917861],
						[60.551444569260084, 3.1194168071377684],
						[60.551444576817254, 3.1194167947924103],
						[60.55144458006918, 3.1194167868874891],
						[60.551483478730113, 3.1193361881434196],
						[60.55152514221318, 3.1192529631187478],
						[60.551841763107639, 3.1191473899389921],
						[60.551841776343714, 3.1191473838717703],
						[60.551841801029695, 3.1191473720626433],
						[60.55184181329254, 3.1191473643444891],
						[60.551975047104165, 3.1190558100167283],
						[60.552263868388387, 3.1191029977332945],
						[60.552263909710639, 3.1191029956911365],
						[60.552263989767873, 3.1191029737960525],
						[60.552264027609802, 3.1191029541057609],
						[60.552425128298864, 3.1189695532060382],
						[60.552425151370734, 3.1189695252366021],
						[60.552425185583999, 3.1189694641580767],
						[60.552425197618426, 3.1189694308862976],
						[60.552477997897768, 3.1186194298085623],
						[60.552477999363468, 3.1186194222286812],
						[60.552477999615661, 3.1186194075569937],
						[60.552478000188302, 3.1186194001397962],
						[60.552478000435428, 3.1185111036883915],
						[60.552505800046376, 3.1177611177314941],
						[60.552527996933968, 3.1175972375180403],
						[60.552647393010957, 3.1171333535469343],
						[60.552783490852562, 3.1166889591006988],
						[60.552783493211258, 3.1166889513579146],
						[60.552783496222609, 3.1166889380114879],
						[60.55278349768821, 3.116688930431454],
						[60.5528057983881, 3.1165472311392124],
						[60.552805798307965, 3.1165472293255765],
						[60.552805798960627, 3.1165472237219101],
						[60.552805798880478, 3.1165472219082764],
						[60.552875198692377, 3.1159222223218745],
						[60.552875198612234, 3.1159222205082364],
						[60.552875199264861, 3.1159222149045473],
						[60.552875200077786, 3.1159222129281465],
						[60.552883500085535, 3.1157944122420305],
						[60.552883500005379, 3.1157944104283928],
						[60.552883499684768, 3.1157944031738349],
						[60.552883500417543, 3.115794399383792],
						[60.552883500141405, 3.1157749999032136],
						[60.552883499740645, 3.1157749908350181],
						[60.552883498126199, 3.1157749746750465],
						[60.552883496832358, 3.1157749657696208],
						[60.552875197145958, 3.1157277648056243],
						[60.552875194878887, 3.1157277542493365],
						[60.552875188638744, 3.1157277352759363],
						[60.552875186371658, 3.1157277247196355],
						[60.552527986055566, 3.1148638255091923],
						[60.552527984028927, 3.1148638203939196],
						[60.552527980135956, 3.114863813790635],
						[60.552527978109289, 3.1148638086753673],
						[60.552425183890477, 3.1146638212343634],
						[60.55217800215771, 3.1139499709170622],
						[60.552200200301421, 3.1134861104180866],
						[60.552200200141051, 3.113486106790889],
						[60.552200199900518, 3.1134861013501149],
						[60.552200199820341, 3.1134860995365248],
						[60.552197400390178, 3.1130389391762212],
						[60.552302985345612, 3.1127806766801212],
						[60.552302985265413, 3.1127806748665279],
						[60.552430776612624, 3.1124639951689343],
						[60.552525113940582, 3.1123251890275623],
						[60.552880509989116, 3.1122863114370936],
						[60.553088741411031, 3.1125584216172562],
						[60.553088744250616, 3.1125584247559539],
						[60.553088750903129, 3.1125584326841378],
						[60.553088753822941, 3.1125584376364857],
						[60.553324820894282, 3.112811200121119],
						[60.553391507711027, 3.11304445523231],
						[60.553391509817928, 3.1130444621612527],
						[60.553391515898063, 3.1130444775070822],
						[60.553391518898067, 3.1130444842731735],
						[60.553774819214624, 3.1138694836302316],
						[60.553774822214564, 3.1138694903964841],
						[60.55377482813428, 3.1138695021152905],
						[60.553774832027301, 3.1138695087187127],
						[60.55383042993072, 3.1139557052894165],
						[60.554222023601397, 3.1146250952006675],
						[60.554341508598441, 3.1148694647845394],
						[60.554419204088703, 3.1152750376701763],
						[60.554419204168866, 3.1152750394838948],
						[60.554419205302416, 3.1152750447622828],
						[60.554419205382594, 3.1152750465759995],
						[60.554538701903461, 3.1157833315256429],
						[60.554558100768439, 3.1160083165278158],
						[60.554558100848574, 3.1160083183415668],
						[60.554608099705838, 3.1165639133104364],
						[60.554613699791844, 3.1166944018427212],
						[60.55461089998299, 3.1168166943858653],
						[60.554610900223402, 3.1168166998270928],
						[60.554610899650875, 3.116816707244789],
						[60.554610899811166, 3.1168167108722766],
						[60.554649800342943, 3.1175667101505988],
						[60.554649800342943, 3.1175667101505988],
						[60.554649799610104, 3.1175667139408145],
						[60.55464980139628, 3.1175667136153495],
						[60.554652599142635, 3.1176055881952243],
						[60.554644203241899, 3.1176527644670693],
						[60.554644201856377, 3.1176527738612485],
						[60.55464419989827, 3.1176527906731373],
						[60.55464420029891, 3.1176527997418408],
						[60.554641500211474, 3.1184666995920969],
						[60.554641500211474, 3.1184666995920969],
						[60.554641499964561, 3.1185193997746388],
						[60.554641499941354, 3.1185749996644225],
						[60.554641500582278, 3.1185750141744033],
						[60.554641504623476, 3.1185750445199818],
						[60.554641508836745, 3.1185750583791325],
						[60.55466920941133, 3.1186667586245265],
						[60.554669208598369, 3.1186667606009726],
						[60.55466921062488, 3.1186667657168217],
						[60.554669211598089, 3.1186667673678623],
						[60.554827611604011, 3.1191056682645848],
						[60.554827616550078, 3.1191056783337014],
						[60.554827627415413, 3.1191057001229612],
						[60.554827633254568, 3.119105710029368],
						[60.55504152802979, 3.119430702873963],
						[60.555080423753985, 3.119502895604886],
						[60.555080439004591, 3.1195029147672684],
						[60.555080472185054, 3.1195029526039955],
						[60.555080491740874, 3.1195029673254315],
						[60.555119281792038, 3.1195279618798204],
						[60.555199872823501, 3.1195945546615085],
						[60.555199872823501, 3.1195945546615085],
						[60.555199873796681, 3.1195945563126228],
						[60.55519987468977, 3.1195945561499454],
						[60.555563744645198, 3.1198862318992115],
						[60.555827627235509, 3.1203444990910483],
						[60.555827628208718, 3.1203445007421968],
						[60.555827629261977, 3.1203445042071647],
						[60.55582763023515, 3.1203445058583195],
						[60.556010922944587, 3.1206389931194378],
						[60.55628871756025, 3.1212583814556094],
						[60.556288719586654, 3.1212583865718799],
						[60.556288726398833, 3.1212583981303226],
						[60.556288729318339, 3.121258403083949],
						[60.556460922897372, 3.1215417941633334],
						[60.556749817882363, 3.1221722833291667],
						[60.556749818775451, 3.1221722831665457],
						[60.556749818935586, 3.1221722867943109],
						[60.556749819828688, 3.1221722866316917],
						[60.556910920097366, 3.1225083856539291],
						[60.556910921150575, 3.1225083891190861],
						[60.556910924150102, 3.1225083958868032],
						[60.556910926016336, 3.122508397375467],
						[60.557235922676796, 3.1230861924910207],
						[60.557297019944819, 3.1232111887827121],
						[60.557297021811067, 3.1232111902714315],
						[60.557297025783683, 3.1232111986906124],
						[60.55729702772998, 3.1232112019932359],
						[60.557358128383363, 3.1233140021040122],
						[60.557358202552479, 3.1233140507697787],
						[60.557358362630964, 3.1233140673389448],
						[60.557358446754165, 3.1233140355675437],
						[60.557430744592892, 3.1232362361737693],
						[60.557575143595571, 3.1230862398863848],
						[60.557575146194772, 3.1230862375846491],
						[60.557575147820863, 3.1230862336316054],
						[60.557575149607032, 3.1230862333064087],
						[60.557725149732853, 3.1229168338176074],
						[60.557725149652789, 3.1229168320036793],
						[60.55772515143898, 3.1229168316784692],
						[60.557725152252004, 3.1229168297019338],
						[60.55780845207331, 3.1228195300670949],
						[60.557808453779451, 3.1228195279279229],
						[60.557808458004715, 3.1228195216730832],
						[60.557808460603916, 3.1228195193713262],
						[60.558000161267074, 3.1225612190742917],
						[60.558000162080106, 3.1225612170977297],
						[60.558000162080106, 3.1225612170977297],
						[60.558211261709523, 3.122269517505956],
						[60.558211263415636, 3.1222695153667499],
						[60.558211265121734, 3.1222695132275446],
						[60.558211265041663, 3.1222695114135735],
						[60.558258453641528, 3.1222001288113121],
						[60.558266841135378, 3.1221918413628864],
						[60.558266844627681, 3.1221918388984133],
						[60.558266852265149, 3.1221918283649504],
						[60.558266855757424, 3.1221918259004786],
						[60.558277955535473, 3.1221779246311243],
						[60.558277968211179, 3.1221779058660291],
						[60.558277988043947, 3.1221778656837116],
						[60.558277995200982, 3.1221778442664716],
						[60.558297394650573, 3.122091745633425],
						[60.558414094221618, 3.1215917471259633],
						[60.558494593733769, 3.1212667479757656],
						[60.558494595932551, 3.1212667366039728],
						[60.558494599356926, 3.1212667122090778],
						[60.558494599689432, 3.1212666993486313],
						[60.55849460025793, 3.1208360997928835],
						[60.558494600189917, 3.1207860997455286],
						[60.558494600189917, 3.1207860997455286],
						[60.558494600029739, 3.1207860961175951],
						[60.558494600029739, 3.1207860961175951],
						[60.558486300277494, 3.1203555966697682],
						[60.558486298903851, 3.1203555859486434],
						[60.55848629712974, 3.1203555661576776],
						[60.558486294863009, 3.1203555555992315],
						[60.558414095790255, 3.1200360602381472],
						[60.558333496658967, 3.1195971653408798],
						[60.558308496621997, 3.1194416687642241],
						[60.558308496221464, 3.119441659694449],
						[60.558308490874865, 3.1194416405544696],
						[60.558308487795081, 3.1194416319728098],
						[60.558258491131575, 3.1193027397929844],
						[60.558052994898247, 3.1185305558567977],
						[60.557925196798273, 3.1178527635660345],
						[60.557925195905199, 3.1178527637287794],
						[60.557880795900438, 3.1176193629193012],
						[60.557880795900438, 3.1176193629193012],
						[60.557880795740189, 3.1176193592914556],
						[60.557880795740189, 3.1176193592914556],
						[60.557811295630962, 3.117286060665402],
						[60.557727996089973, 3.1168749606475408],
						[60.557727993983264, 3.1168749537174389],
						[60.557727990743039, 3.1168749415083425],
						[60.55772798863633, 3.1168749345782412],
						[60.557669589387153, 3.1167055352079518],
						[60.557669588333795, 3.1167055317428956],
						[60.557669585253834, 3.1167055231616723],
						[60.557669584280596, 3.1167055215105424],
						[60.55736688395902, 3.1160055213099653],
						[60.557366864654696, 3.1160054919157929],
						[60.55736681930216, 3.1160054434992355],
						[60.557366792280739, 3.1160054228257645],
						[60.556952989317402, 3.115791572838305],
						[60.556916899128396, 3.1154110814307323],
						[60.556916866610727, 3.1154110178765215],
						[60.556916769894208, 3.1154109276276318],
						[60.556916635623104, 3.1154109246778177],
						[60.556916534991885, 3.1154110106763149],
						[60.556916501753697, 3.1154110734183278],
						[60.556877601931859, 3.1157110735194493],
						[60.55686651894645, 3.1157915517720354],
						[60.556524937011567, 3.1159109099504114],
						[60.556463959061631, 3.1159303718228477],
						[60.556419581598888, 3.1158832015169908],
						[60.55639739638935, 3.1157888603768029],
						[60.556361297049634, 3.1155749686113383],
						[60.55630580039945, 3.1152082833342276],
						[60.556300199675782, 3.1148777986197516],
						[60.556300199961129, 3.1145416996914821],
						[60.556300200064406, 3.1143000178620572],
						[60.556358496856824, 3.113963934256522],
						[60.556358498333353, 3.1139639065605764],
						[60.556358492275514, 3.1139638509832976],
						[60.556358484821288, 3.1139638249157775],
						[60.556019591583308, 3.1131416398803085],
						[60.556000195246895, 3.1130527572820186],
						[60.556000194033039, 3.113052750189663],
						[60.556000188926141, 3.1130527364935423],
						[60.556000186819226, 3.113052729564068],
						[60.555927986685958, 3.1128638297526305],
						[60.555652993119871, 3.1121388437543707],
						[60.555572396399029, 3.1117555582580216],
						[60.555572393238563, 3.1117555478640573],
						[60.555572386024551, 3.1117555272390129],
						[60.55557238205121, 3.1117555188217239],
						[60.555333488952577, 3.111227732845292],
						[60.555122394189659, 3.1104055505484682],
						[60.555122380242601, 3.1104055201820824],
						[60.555122342685131, 3.1104054648690602],
						[60.555122319967815, 3.1104054397594858],
						[60.555000196745347, 3.1103137974203556],
						[60.554991900245192, 3.110041694531005],
						[60.554991899924275, 3.1100416872760461],
						[60.554991897656755, 3.1100416767194994],
						[60.55499189822887, 3.1100416693016033],
						[60.554894597828522, 3.109402769445849],
						[60.554894592560771, 3.1094027521233301],
						[60.55489458113221, 3.1094027176412533],
						[60.55489457407834, 3.1094027006446501],
						[60.554777995607893, 3.1091971401215992],
						[60.554733499188956, 3.1086082860187259],
						[60.554725206920146, 3.1084889974661265],
						[60.555019518321124, 3.1082751616745976],
						[60.555019543257046, 3.1082751351830646],
						[60.555019582010743, 3.1082750750872168],
						[60.555019595828547, 3.108275041482901],
						[60.555125196267355, 3.1077806420182728],
						[60.555125196106815, 3.1077806383907891],
						[60.555125196839363, 3.1077806346003127],
						[60.555125196759107, 3.1077806327865769],
						[60.555150197101916, 3.1076250318760623],
						[60.555150197914728, 3.1076250298993142],
						[60.555150197754188, 3.1076250262718439],
						[60.555150198566999, 3.1076250242950976],
						[60.555155799088944, 3.1075778242249714],
						[60.555155799008666, 3.1075778224112374],
						[60.555155799008666, 3.1075778224112374],
						[60.555164098249037, 3.1075028263653648],
						[60.555255797059552, 3.1069306325204189],
						[60.555302997247928, 3.1066667349256889],
						[60.555302997900156, 3.106666729321411],
						[60.555302999204621, 3.1066667181128698],
						[60.555302999937126, 3.1066667143223516],
						[60.555336299181974, 3.1061778218402818],
						[60.555397397736293, 3.1057583287533403],
						[60.555397397575696, 3.105758325125854],
						[60.555397398960388, 3.1057583157309909],
						[60.555397399692879, 3.1057583119404395],
						[60.555416899369568, 3.1054278284688346],
						[60.55555299177405, 3.1048444571115623],
						[60.555564088287007, 3.1048139677117605],
						[60.555564089912536, 3.1048139637580787],
						[60.555564093002957, 3.1048139522232034],
						[60.555564093735427, 3.1048139484326134],
						[60.555575190760351, 3.1047694605435292],
						[60.555755758754785, 3.1042973438204764],
						[60.556027625742431, 3.1041614117965279],
						[60.556085901093468, 3.1048472168266521],
						[60.556085904816577, 3.104847239916543],
						[60.556085920300369, 3.1048472846285136],
						[60.556085930194612, 3.1048473047629823],
						[60.55647762955936, 3.105480704551959],
						[60.556477632399165, 3.1054807076903286],
						[60.556477635319219, 3.1054807126425268],
						[60.556477637265935, 3.1054807159439792],
						[60.55667762632806, 3.1057612003441775],
						[60.55692201797455, 3.106297282169515],
						[60.556922019921238, 3.1062972854710638],
						[60.556922022921583, 3.1062972922372278],
						[60.556922023975211, 3.1062972957018329],
						[60.557127597032022, 3.1066750457480325],
						[60.55710539966654, 3.1070499880408189],
						[60.557105399907364, 3.1070499934823679],
						[60.557105400388998, 3.107050004365449],
						[60.55710539973677, 3.1070500099700133],
						[60.557133100406823, 3.1075861019719127],
						[60.557121999579422, 3.1079916950510031],
						[60.557121999659685, 3.1079916968648496],
						[60.557121999739934, 3.1079916986787159],
						[60.557121999820211, 3.1079917004925632],
						[60.557122000436735, 3.1080971884236828],
						[60.557074800969794, 3.1084971771015062],
						[60.557074802173602, 3.1084972043092431],
						[60.55707481196653, 3.1084972628623038],
						[60.557074821207927, 3.1084972886030759],
						[60.557369219610521, 3.1090889865579276],
						[60.557522019051241, 3.109411185809511],
						[60.557522019944315, 3.1094111856465356],
						[60.55752202002455, 3.1094111874604105],
						[60.55752202002455, 3.1094111874604105],
						[60.557819219810781, 3.110019488703037],
						[60.557969210014846, 3.1103250664355597],
						[60.558083100393397, 3.1108611194327138],
						[60.558077599839869, 3.1112360962055674],
						[60.55807760145489, 3.1112361123678811],
						[60.558077604604733, 3.1112361428785751],
						[60.558077608005888, 3.1112361587150321],
						[60.558266507688238, 3.1118639585419618],
						[60.558266509634819, 3.1118639618439836],
						[60.558266512715072, 3.1118639704248872],
						[60.558266514741845, 3.111863975540849],
						[60.558383114766222, 3.1121500745230311],
						[60.558383115819701, 3.1121500779879896],
						[60.558383117926667, 3.1121500849179227],
						[60.558383119793014, 3.112150086406043],
						[60.558716519940766, 3.1128472855962386],
						[60.558716522047696, 3.1128472925262729],
						[60.55871652788732, 3.1128473024326899],
						[60.558716530807132, 3.1128473073858838],
						[60.558852613336526, 3.1130611784228051],
						[60.558977602878194, 3.1136056366205316],
						[60.559027602283429, 3.1139750272878519],
						[60.559027605764456, 3.113975044939048],
						[60.559027617111731, 3.1139750776131478],
						[60.559027624165083, 3.1139750946129019],
						[60.559160919116408, 3.1142222856890229],
						[60.559435904778205, 3.1148889513980658],
						[60.559535897711946, 3.1156527931825995],
						[60.559508103128572, 3.1157999627048927],
						[60.559508103208728, 3.115799964518899],
						[60.559508101582871, 3.1157999684725581],
						[60.55950810166302, 3.1157999702865644],
						[60.5594637018593, 3.1160971694351001],
						[60.55946370071436, 3.1160971842727858],
						[60.559463701103724, 3.1160972134597267],
						[60.559463701664789, 3.1160972261577808],
						[60.55952480204158, 3.1165389261943957],
						[60.559546997850774, 3.1167111011075495],
						[60.559527601114297, 3.1168721761578211],
						[60.559527601182921, 3.1168721980887777],
						[60.559527604812345, 3.1168722394855446],
						[60.559527610239435, 3.116872260439818],
						[60.55960810995807, 3.1171222611599982],
						[60.55960811303801, 3.1171222697417678],
						[60.55960811911774, 3.1171222850912925],
						[60.559608123090761, 3.1171222935102691],
						[60.559872023238825, 3.1176250934484058],
						[60.559872039623201, 3.1176251178919334],
						[60.5598720818953, 3.1176251577324656],
						[60.559872105183935, 3.1176251754317748],
						[60.560016605430263, 3.1177029760610457],
						[60.560016611842094, 3.1177029785497998],
						[60.56001662563898, 3.1177029851785671],
						[60.560016632943906, 3.1177029875045417],
						[60.560055468823897, 3.1177168660138346],
						[60.560238733243416, 3.1179973080813461],
						[60.560238735269984, 3.1179973131979932],
						[60.560238740055901, 3.1179973196404456],
						[60.560238742975521, 3.1179973245943398],
						[60.560505442870721, 3.1183362238859447],
						[60.560505443843923, 3.118336225537266],
						[60.560505445790355, 3.1183362288399055],
						[60.560505447576517, 3.1183362285144072],
						[60.560677648003171, 3.1185390290405519],
						[60.560677649949568, 3.1185390323432416],
						[60.560677653682141, 3.1185390353204245],
						[60.560677655628567, 3.1185390386231133],
						[60.560949855960736, 3.1188223393748511],
						[60.560949866265346, 3.1188223484693234],
						[60.560949887687556, 3.1188223646814328],
						[60.560949898885241, 3.1188223736131859],
						[60.561149849189981, 3.1189390432692501],
						[60.561399821865372, 3.1194278902111656],
						[60.561399822918659, 3.1194278936767108],
						[60.561399823891861, 3.1194278953281089],
						[60.561399824865056, 3.1194278969795075],
						[60.561413710684775, 3.1194528727502004],
						[60.561424801367522, 3.1195028280032133],
						[60.561477600231896, 3.1203667124043166],
						[60.561477603792227, 3.1203667318717017],
						[60.561477613592089, 3.1203667703183817],
						[60.561477619751585, 3.1203667874835332],
						[60.561847010562687, 3.121119469363399],
						[60.561888705984877, 3.1212806494898757],
						[60.561888709144661, 3.1212806598868088],
						[60.56188871717027, 3.1212806785411651],
						[60.561888721223134, 3.1212806887754176],
						[60.562297018625351, 3.1221028853054955],
						[60.562338708954663, 3.1221944647868431],
						[60.562374800609078, 3.1223556249034714],
						[60.562394199660581, 3.1231056056909372],
						[60.562394203220499, 3.1231056251591971],
						[60.562394213179743, 3.1231056672362087],
						[60.562394220312036, 3.1231056860539463],
						[60.562744212223585, 3.123836169822781],
						[60.562794206597005, 3.124019452546229],
						[60.562794209756554, 3.1240194629437301],
						[60.562794216808683, 3.1240194799476848],
						[60.562794219888183, 3.1240194885309522],
						[60.563191519980265, 3.124830688074562],
						[60.563191520953389, 3.1248306897262186],
						[60.563191521846491, 3.1248306895636286],
						[60.563191521926527, 3.1248306913778716],
						[60.563244211352263, 3.1249333704401661],
						[60.563299801066258, 3.1251583272545291],
						[60.563316499996155, 3.1258444041403179],
						[60.563316503795768, 3.1258444290522269],
						[60.563316515700393, 3.1258444744347047],
						[60.563316524778564, 3.1258444965569923],
						[60.563641522823715, 3.1264306934367285],
						[60.563808121331142, 3.1267583906934791],
						[60.564085912686416, 3.1273167719356274],
						[60.564185907164173, 3.1276722547888265],
						[60.564185910243474, 3.1276722633728298],
						[60.564185917295141, 3.1276722803783104],
						[60.56418592126753, 3.1276722887997996],
						[60.564533118477556, 3.1283667838264613],
						[60.564624814638904, 3.128586176622238],
						[60.564624815611992, 3.128586178274082],
						[60.564624817638141, 3.1285861833921147],
						[60.564624818611243, 3.1285861850439529],
						[60.56498311898568, 3.1293472846625843],
						[60.564983119065651, 3.1293472864769445],
						[60.564983119958747, 3.1293472863144713],
						[60.564983120038733, 3.1293472881288267],
						[60.565058119416477, 3.1295000861825915],
						[60.565430418106516, 3.130313982442817],
						[60.565430419079583, 3.1303139840947511],
						[60.565430420132614, 3.1303139875610673],
						[60.565430421105674, 3.1303139892130063],
						[60.565480420021849, 3.1304139851889565],
						[60.565880417664886, 3.1312861825972536],
						[60.565880417744822, 3.1312861844116711],
						[60.565880418637931, 3.131286184249241],
						[60.565899817344011, 3.1313278808935028],
						[60.566021998791193, 3.1316139375782694],
						[60.566005400581695, 3.132238873443153],
						[60.565963705985538, 3.1324110525508444],
						[60.565880406208606, 3.1327582517368229],
						[60.565813706368466, 3.1330249510698089],
						[60.565813702622279, 3.1330249682114895],
						[60.565813700568498, 3.1330250033349665],
						[60.565813701367759, 3.1330250214791664],
						[60.565827600843249, 3.1331500216941559],
						[60.565827600923171, 3.1331500235085912],
						[60.565827601976125, 3.1331500269750507],
						[60.565827602056061, 3.1331500287894665],
						[60.565874801749892, 3.1334778286567189],
						[60.565874801829828, 3.133477830471139],
						[60.565874802802831, 3.1334778321232117],
						[60.565874802802831, 3.1334778321232117],
						[60.565972003031497, 3.1340639333115847],
						[60.565972004164351, 3.1340639385925346],
						[60.56597200635013, 3.1340639473399929],
						[60.565972006589867, 3.1340639527832774],
						[60.566097005763922, 3.1345167481127292],
						[60.566197004985504, 3.1349750435379296],
						[60.566197006118301, 3.1349750488189354],
						[60.566197008304059, 3.1349750575665127],
						[60.566197010329965, 3.1349750626852066],
						[60.56632200963125, 3.1353583613280382],
						[60.566322009711129, 3.1353583631424904],
						[60.566322009711129, 3.1353583631424904],
						[60.566322010684154, 3.1353583647946239],
						[60.566502609600512, 3.1358889625481816],
						[60.566772008951972, 3.136758359707124],
						[60.566772009924954, 3.1367583613593228],
						[60.566772012030697, 3.136758368292702],
						[60.566772013003678, 3.1367583699449022],
						[60.566788709745794, 3.1368028626338664],
						[60.566810907245326, 3.1368833529061675],
						[60.566810908218322, 3.1368833545583708],
						[60.566810908298194, 3.1368833563728735],
						[60.56681090927119, 3.1368833580250768],
						[60.566952609346814, 3.137352857058358],
						[60.56695260958643, 3.1373528625018401],
						[60.566952613638108, 3.1373528727397506],
						[60.566952615743801, 3.1373528796732089],
						[60.567108109982527, 3.1377167657274265],
						[60.56714700516882, 3.1378667462144696],
						[60.567219204166641, 3.138194438659144],
						[60.567274800631957, 3.1385111234127914],
						[60.567283100296734, 3.1386278128497835],
						[60.567291499967162, 3.138777810842162],
						[60.567321999680132, 3.1393278119554555],
						[60.56732199975999, 3.1393278137699929],
						[60.567322000732936, 3.1393278154223023],
						[60.56732200081278, 3.1393278172368206],
						[60.567341500970599, 3.1395417175100713],
						[60.567341501050443, 3.1395417193246096],
						[60.567341501050443, 3.1395417193246096],
						[60.567372000734352, 3.1398500192311798],
						[60.567394201200514, 3.1400944170637577],
						[60.567394201599676, 3.1400944261364141],
						[60.567394204024581, 3.1400944403282627],
						[60.567394206130153, 3.1400944472619994],
						[60.567483103823896, 3.140452839633141],
						[60.567527603142032, 3.1407306315709023],
						[60.567527603381507, 3.1407306370145331],
						[60.567527605566866, 3.1407306457628792],
						[60.567527606699436, 3.1407306510443167],
						[60.567663706856621, 3.1412444510549653],
						[60.567663710014898, 3.1412444614557642],
						[60.567663717144718, 3.1412444802806561],
						[60.567663721116276, 3.1412444887047473],
						[60.56769422102154, 3.1413056889392266],
						[60.567694221101348, 3.1413056907537773],
						[60.567724820910293, 3.1413667888235257],
						[60.567919218327106, 3.1417583847753172],
						[60.568110916392946, 3.1422056783480561],
						[60.568110917365864, 3.1422056800004903],
						[60.568110918418604, 3.1422056834675134],
						[60.568110919391536, 3.1422056851199462],
						[60.568130411103887, 3.1422472677532887],
						[60.568138705734576, 3.1422806477203631],
						[60.568138707840028, 3.1422806546543924],
						[60.568138711078035, 3.1422806668700409],
						[60.568138713996795, 3.1422806718273537],
						[60.568230413288532, 3.1425167711591127],
						[60.568322010011343, 3.1427667621086846],
						[60.568444208302402, 3.1431944554242008],
						[60.568444209434887, 3.1431944607058941],
						[60.568444212593, 3.143194471107142],
						[60.568444214618644, 3.1431944762266948],
						[60.568497015393909, 3.1433222764888233],
						[60.568497016366827, 3.1433222781413077],
						[60.568497018392442, 3.1433222832608809],
						[60.568497019365353, 3.1433222849133693],
						[60.568560911672876, 3.1434583701297396],
						[60.568741503581442, 3.1441083423611107],
						[60.568805401733613, 3.1445194307897779],
						[60.568824801559352, 3.1446500290968316],
						[60.568824801639131, 3.1446500309114533],
						[60.568824801639131, 3.1446500309114533],
						[60.568883101600967, 3.1450194311699753],
						[60.568883103466987, 3.1450194326604644],
						[60.568883102653622, 3.1450194346371618],
						[60.568883102733388, 3.1450194364517916],
						[60.568916501396096, 3.145202823747435],
						[60.568955400028543, 3.1458250096982749],
						[60.568958099934122, 3.1459333042007218],
						[60.56895810017339, 3.1459333096446236],
						[60.568958101385533, 3.1459333167411345],
						[60.568958100651919, 3.145933320532464],
						[60.568966499551983, 3.1460139126662257],
						[60.568988699824907, 3.146811102154393],
						[60.568988700127733, 3.1468443991836086],
						[60.568988700765665, 3.146844413700741],
						[60.56898870366831, 3.1468444387817027],
						[60.568988706905898, 3.1468444509981333],
						[60.569002607002432, 3.1468972508218469],
						[60.569002621021298, 3.1468972830318109],
						[60.569002661323609, 3.1468973397395614],
						[60.569002686713915, 3.1468973643993694],
						[60.569047083847991, 3.1469279632940226],
						[60.56931658101135, 3.1471279603683548],
						[60.569316583850217, 3.1471279635116316],
						[60.569316589368462, 3.1471279661688643],
						[60.569316592127585, 3.1471279674974824],
						[60.569455492350926, 3.1472168685447284],
						[60.569455570355998, 3.147216881831731],
						[60.569455711071527, 3.1472168471604567],
						[60.569455772888837, 3.1472167993641973],
						[60.569552973154359, 3.1470472993305441],
						[60.56955297404749, 3.1470472991685252],
						[60.569666873885936, 3.1468472984631983],
						[60.569666881286132, 3.1468472824875331],
						[60.569666893327415, 3.1468472492076138],
						[60.569666897075386, 3.1468472320653782],
						[60.569725195716032, 3.1464889418473008],
						[60.569866888447507, 3.1459361704847768],
						[60.569886278720176, 3.1458972891169119],
						[60.569886280426687, 3.145897286978121],
						[60.569886282053425, 3.1458972830246292],
						[60.569886282787024, 3.1458972792332047],
						[60.569905782591448, 3.1458528813111872],
						[60.570100181720534, 3.1454194815236085],
						[60.570277980379885, 3.1450250871717174],
						[60.57031687672076, 3.1449500925283091],
						[60.570355767986634, 3.1448779119096306],
						[60.570550149158777, 3.1446334337144481],
						[60.570591839772803, 3.1445918423796337],
						[60.570805605714554, 3.144383572779383],
						[60.571024862690571, 3.1445779465601045],
						[60.571033159434599, 3.1445862417656238],
						[60.571033166005563, 3.1445862478899422],
						[60.571033180040615, 3.1445862599765224],
						[60.571033188397813, 3.1445862657766686],
						[60.571249832249066, 3.1447334281474184],
						[60.571349803114714, 3.1450306408648583],
						[60.57136920129026, 3.1452750162427918],
						[60.57136920129026, 3.1452750162427918],
						[60.571399800854834, 3.1456417168964204],
						[60.571399802226509, 3.1456417276229938],
						[60.571399805863038, 3.1456417489140631],
						[60.571399808941202, 3.1456417575017288],
						[60.571491504621932, 3.1459444451820979],
						[60.571616501674804, 3.146691726158084],
						[60.571633099196113, 3.1468582999059884],
						[60.571616501256649, 3.1470221797322528],
						[60.571616501416138, 3.1470221833618255],
						[60.571616499869101, 3.1470221891302703],
						[60.571616500028576, 3.1470221927598452],
						[60.57159699997041, 3.1475666925199102],
						[60.57159700005014, 3.1475666943346998],
						[60.57159700005014, 3.1475666943346998],
						[60.571591499995108, 3.1477693948090977],
						[60.571591500234298, 3.1477694002534613],
						[60.571591499739839, 3.1477694094894288],
						[60.571591500872152, 3.1477694147717998],
						[60.571605400937585, 3.1479528151510578],
						[60.571605402548272, 3.1479528313221938],
						[60.571605411208161, 3.1479528645072139],
						[60.571605417284495, 3.1479528798682983],
						[60.571697014082559, 3.1481639730162971],
						[60.571802611160258, 3.1484639655892548],
						[60.5718026371881, 3.1484640047707737],
						[60.571802702082664, 3.1484640680002474],
						[60.571802741842532, 3.1484640918862228],
						[60.572077742355866, 3.1485473915500966],
						[60.572077743249018, 3.1485473913880999],
						[60.572077745114996, 3.1485473928789349],
						[60.572077746008127, 3.148547392716941],
						[60.572147054367782, 3.1485668671817475],
						[60.572197009300432, 3.1486833614766674],
						[60.572241505318033, 3.1488778446478065],
						[60.572322005143562, 3.1492361442315389],
						[60.572322028730937, 3.1492361893447276],
						[60.572322094916863, 3.1492362614889577],
						[60.572322137595137, 3.1492362903348163],
						[60.572433238029795, 3.1492723905632438],
						[60.572433239816064, 3.1492723902392932],
						[60.572433241682027, 3.1492723917301855],
						[60.572433244361427, 3.1492723912442586],
						[60.572594344020892, 3.1493195921385451],
						[60.572594392728227, 3.1493195942810144],
						[60.572594487240686, 3.149319573481959],
						[60.572594531259533, 3.149319550864405],
						[60.572811195121091, 3.1491307815505323],
						[60.573044347405485, 3.1490724196271809],
						[60.573372067902525, 3.1493612497062378],
						[60.573372193243365, 3.1493612544146261],
						[60.573372351153616, 3.149361139796321],
						[60.57337238177729, 3.1493610171638093],
						[60.573064101258865, 3.1486860593293922],
						[60.573086300172186, 3.1478611045122569],
						[60.573086300172186, 3.1478611045122569],
						[60.573086300012726, 3.1478611008825119],
						[60.573086300012726, 3.1478611008825119],
						[60.573086300175738, 3.1477749993489015],
						[60.573086298565002, 3.147774983177043],
						[60.573086292584449, 3.1477749495045271],
						[60.573086289187465, 3.1477749336567107],
						[60.573044588932902, 3.1476555332819207],
						[60.573044586907422, 3.1476555281613461],
						[60.573044582936234, 3.1476555197350771],
						[60.573044580910789, 3.1476555146145153],
						[60.57266690121596, 3.1468610565429711],
						[60.572678000121776, 3.1461139029899181],
						[60.572677999882522, 3.146113897545368],
						[60.572677999403993, 3.1461138866562899],
						[60.572677999084966, 3.1461138793969154],
						[60.572661298689894, 3.1459499808700642],
						[60.572661298370861, 3.1459499736106968],
						[60.572661296026311, 3.1459499612309045],
						[60.572661294893919, 3.1459499559484461],
						[60.572597395434201, 3.1456638558741448],
						[60.572597347587504, 3.1456637913842909],
						[60.572597221895165, 3.1456637172400472],
						[60.572597143156386, 3.1456637077476977],
						[60.572474943814292, 3.1456998079059408],
						[60.572239117043431, 3.1457664310310429],
						[60.572269600300778, 3.1452806120725354],
						[60.572269600300778, 3.1452806120725354],
						[60.572269600221013, 3.1452806102577182],
						[60.572283499833944, 3.145036113696885],
						[60.572327998810067, 3.1444806156054916],
						[60.572327999064981, 3.1444806009248176],
						[60.572327996815645, 3.1444805702349403],
						[60.572327995284326, 3.1444805558784785],
						[60.572247398328926, 3.1441221689113195],
						[60.572164099578195, 3.1432332903428262],
						[60.572164099740128, 3.1432110991409576],
						[60.572164096916559, 3.143211075872733],
						[60.57216408388507, 3.1432110251889807],
						[60.572164074809656, 3.1432110030557272],
						[60.572150182717664, 3.1431860161563856],
						[60.571833500358942, 3.1422971641098134],
						[60.57183079980755, 3.1416417047046159],
						[60.571841899860644, 3.1413861088731574],
						[60.571841897929886, 3.1413860854431386],
						[60.571841888709649, 3.1413860395562478],
						[60.57184188239313, 3.1413860187519678],
						[60.57170578322669, 3.1410832175084975],
						[60.571433499082502, 3.1404721556418136],
						[60.571419599847104, 3.1398861018440019],
						[60.571433500341975, 3.1395583072302702],
						[60.571433497597631, 3.1395582857776785],
						[60.571433490163031, 3.1395582395674446],
						[60.571433482873253, 3.1395582171112655],
						[60.571258483898596, 3.1391666205529209],
						[60.571041893224624, 3.1386443431346072],
						[60.570908501840272, 3.1379277933431946],
						[60.570933498475164, 3.1377333257709279],
						[60.57093349809039, 3.1377332965730962],
						[60.570933489282851, 3.1377332396379836],
						[60.570933479966961, 3.1377332120629866],
						[60.570811288166112, 3.1374832295336588],
						[60.570625193022458, 3.136819346804653],
						[60.570625190996594, 3.1368193416851553],
						[60.570625187837976, 3.1368193312838697],
						[60.570625185812112, 3.1368193261643871],
						[60.570366886400492, 3.1361721261237858],
						[60.570366884454494, 3.1361721228190684],
						[60.570366881375726, 3.1361721142326084],
						[60.570366879429734, 3.1361721109278746],
						[60.570233487129308, 3.1359055271592147],
						[60.570064096998671, 3.1352971617419954],
						[60.570027999786909, 3.1349916896085483],
						[60.570030799986789, 3.1347556013243687],
						[60.570030765132188, 3.1347555253472006],
						[60.57003064821982, 3.1347554258745141],
						[60.570030566162039, 3.1347554023789987],
						[60.569916848779698, 3.1347747728078401],
						[60.569741895225626, 3.1340777566984062],
						[60.569633499995163, 3.1334971808424124],
						[60.569630800163459, 3.1331666985051694],
						[60.569630798391138, 3.1331666787065982],
						[60.569630789407931, 3.1331666382692336],
						[60.569630782197045, 3.1331666176304225],
						[60.569469588925685, 3.1328082328558629],
						[60.569314093291936, 3.1322527467823682],
						[60.56913079972351, 3.1315610752919727],
						[60.569133500014281, 3.1313389012161021],
						[60.569133498241705, 3.1313388814179488],
						[60.569133490231025, 3.1313388426338853],
						[60.569133484886024, 3.1313388234855259],
						[60.569022393371533, 3.1310721427583221],
						[60.568900196674967, 3.1304277636629148],
						[60.568825200604245, 3.1300193887536762],
						[60.568864099132632, 3.1295139141812029],
						[60.568864098172895, 3.1295138924062593],
						[60.568864090734898, 3.1295138462021796],
						[60.568864085229677, 3.1295138234251443],
						[60.568577991318982, 3.1288221385151531],
						[60.568527999374083, 3.1285999714590025],
						[60.568522400289545, 3.1284971991613899],
						[60.568561299716279, 3.1276889109584278],
						[60.568561298916272, 3.1276888928128632],
						[60.568561295690046, 3.1276888604759483],
						[60.568561292290731, 3.1276888446325923],
						[60.568539105275768, 3.1276110883200645],
						[60.568577972346269, 3.1273834831142007],
						[60.56885832161008, 3.1273529989191977],
						[60.568858372849618, 3.1273529767889441],
						[60.568858457239742, 3.1273529102129971],
						[60.568858490310355, 3.1273528639527313],
						[60.569027705663935, 3.1268509041118948],
						[60.569108100598207, 3.1275250245562942],
						[60.56910810165131, 3.1275250280229057],
						[60.569108102864426, 3.1275250351187034],
						[60.569108103917529, 3.1275250385853148],
						[60.569141503625907, 3.1276917391171102],
						[60.569141503705922, 3.1276917409316956],
						[60.569141503785914, 3.1276917427462823],
						[60.569141504679031, 3.1276917425837287],
						[60.569235905426389, 3.1281222429463891],
						[60.569235904613286, 3.1281222449235173],
						[60.569235904613286, 3.1281222449235173],
						[60.569347004827037, 3.1286028457322104],
						[60.569347007986266, 3.1286028561322534],
						[60.569347016090944, 3.1286028766072866],
						[60.569347020223276, 3.1286028886594024],
						[60.569472020114254, 3.1288583885433372],
						[60.569472086323799, 3.1288584405158835],
						[60.569472234698402, 3.1288584775367112],
						[60.569472317756571, 3.128858462422472],
						[60.569564017651139, 3.1287918629752869],
						[60.569564017571167, 3.128791861160674],
						[60.569564019357358, 3.1287918608356331],
						[60.569564019357358, 3.1287918608356331],
						[60.569814019276997, 3.128605759121406],
						[60.569814020250085, 3.1286057607735054],
						[60.5698140228494, 3.128605758471295],
						[60.569814023662488, 3.1286057564941441],
						[60.569891823785007, 3.1285445569262453],
						[60.569891826384321, 3.1285445546240265],
						[60.569891827277395, 3.1285445544614983],
						[60.569922327142933, 3.1285195541456501],
						[60.569922335753922, 3.1285195452617969],
						[60.569922353055951, 3.1285195293087358],
						[60.569922360693866, 3.1285195187727801],
						[60.570050161122708, 3.1283473182266217],
						[60.570050161122708, 3.1283473182266217],
						[60.570050162828892, 3.1283473160869111],
						[60.570050162748899, 3.1283473142722555],
						[60.570164062611568, 3.1281862156727671],
						[60.570164064397765, 3.1281862153476818],
						[60.570164064317773, 3.1281862135330378],
						[60.570164065210868, 3.1281862133704972],
						[60.570372351995054, 3.1278834326267422],
						[60.570433431313447, 3.1278279514906795],
						[60.570591826794676, 3.1276973541212789],
						[60.570591827687799, 3.1276973539587161],
						[60.570755727734564, 3.1275612533606019],
						[60.570819393222067, 3.1275085653522714],
						[60.570883163584796, 3.1275668476978686],
						[60.571024857368116, 3.1277001392245913],
						[60.571183141743496, 3.1278779236133403],
						[60.571269227734788, 3.1280057023004839],
						[60.571422019581107, 3.1283000884942083],
						[60.571566517570375, 3.1286139814855134],
						[60.571716516559611, 3.1289556801131857],
						[60.571716519558862, 3.1289556868845452],
						[60.571716528316621, 3.1289557017544078],
						[60.57171653220896, 3.1289557083632196],
						[60.572083126875825, 3.1295279006074823],
						[60.57216652354608, 3.129683394726483],
						[60.572166532223804, 3.1296834077819673],
						[60.572166552258537, 3.129683433405412],
						[60.572166562722458, 3.1296834461358851],
						[60.572308157671088, 3.1298168410230707],
						[60.572613753798073, 3.1301418371567027],
						[60.572613767834369, 3.13014184923746],
						[60.572613797613151, 3.1301418712592],
						[60.572613814248754, 3.1301418810376624],
						[60.572735962114656, 3.1302001560375401],
						[60.572891510846553, 3.1304444768683517],
						[60.572941501078603, 3.1306889269298472],
						[60.572974800047021, 3.1311833123109887],
						[60.572974801100038, 3.1311833157781503],
						[60.57297480118001, 3.1311833175929595],
						[60.572974801259953, 3.1311833194077754],
						[60.572991496540709, 3.1313555734066116],
						[60.572830411573527, 3.1317999322265782],
						[60.572830410027166, 3.1317999379959152],
						[60.572830406121291, 3.1317999515118706],
						[60.572830403681834, 3.131799957443683],
						[60.572730403739904, 3.1322666577179543],
						[60.572730403166581, 3.1322666651396238],
						[60.572730401206748, 3.1322666819601972],
						[60.572730399740308, 3.1322666895443092],
						[60.57271919987641, 3.1324888901347703],
						[60.57271919987641, 3.1324888901347703],
						[60.572697001336671, 3.1330082772817982],
						[60.572666503338574, 3.1331777656572188],
						[60.572666502911183, 3.133177796833726],
						[60.572666511960492, 3.1331778592149142],
						[60.572666521357256, 3.1331778886047954],
						[60.573058117216128, 3.133961181286459],
						[60.573108112973721, 3.1340917711649685],
						[60.573108117025654, 3.1340917814043627],
						[60.573108126835834, 3.1340917997435289],
						[60.573108131780884, 3.1340918098205224],
						[60.573505432062518, 3.1347057096233746],
						[60.573505434901655, 3.1347057127659288],
						[60.573505439686826, 3.1347057192134398],
						[60.573505441632868, 3.1347057225183756],
						[60.573738738192553, 3.1350057183373905],
						[60.573955433759927, 3.1353251114922092],
						[60.573955449901582, 3.1353251305105347],
						[60.573955484624562, 3.1353251626153931],
						[60.573955504098961, 3.1353251755395619],
						[60.574169288689262, 3.1354418679144245],
						[60.57418317536694, 3.1354529560319735],
						[60.574183192082117, 3.1354529676285288],
						[60.574183228031941, 3.1354529867047227],
						[60.574183247186731, 3.1354529923694781],
						[60.574405546660977, 3.1355140931518277],
						[60.574405610391302, 3.1355140888833413],
						[60.574405721884261, 3.1355140448311976],
						[60.57440577143317, 3.1355140047227801],
						[60.57454186688917, 3.1352918105751839],
						[60.574744562071466, 3.1350112166349886],
						[60.574744568003595, 3.1350112082385908],
						[60.574744578894823, 3.1350111897932567],
						[60.574744583853914, 3.1350111797443407],
						[60.574794582913761, 3.1348944802295131],
						[60.574855782218414, 3.1347583822351468],
						[60.574855784578091, 3.1347583744882384],
						[60.574855790350391, 3.1347583624619069],
						[60.574855791816944, 3.1347583548773934],
						[60.574975192372541, 3.1343444556981326],
						[60.575047392076932, 3.1341000575079376],
						[60.575047395009996, 3.1341000423387761],
						[60.575047399169819, 3.1341000141401985],
						[60.575047400396613, 3.1341000011107969],
						[60.575050199871619, 3.1335889277718385],
						[60.57516409220537, 3.1331889557058994],
						[60.5751640929386, 3.1331889519135645],
						[60.57516409521827, 3.1331889423515311],
						[60.575164095951507, 3.1331889385592122],
						[60.575202987767753, 3.1329861796299725],
						[60.57530576428573, 3.132839015510501],
						[60.575305765912063, 3.1328390115556926],
						[60.575305769324586, 3.1328390072760115],
						[60.575305770137746, 3.1328390052986168],
						[60.575458462534584, 3.1325918162414124],
						[60.575716847624165, 3.1322779351533008],
						[60.575741840747568, 3.132252941937721],
						[60.575741847572623, 3.1322529333780889],
						[60.575741861302646, 3.1322529180738181],
						[60.575741867314541, 3.1322529114916393],
						[60.57575290317746, 3.132236306213029],
						[60.576194386669982, 3.1322640991675561],
						[60.576194419874668, 3.132264096786189],
						[60.576194481258966, 3.1322640801308204],
						[60.576194511224735, 3.1322640655318721],
						[60.576202653570988, 3.132258637989481],
						[60.576205399740743, 3.1322750177801946],
						[60.576205399838095, 3.1322805996975789],
						[60.576205403716585, 3.1322806264351688],
						[60.576205417725291, 3.1322806787730522],
						[60.576205428748608, 3.1322807042108929],
						[60.576652623969032, 3.1330167967189655],
						[60.576738719848869, 3.1331944871979411],
						[60.576738721794953, 3.1331944905031057],
						[60.576738725767029, 3.1331944989284817],
						[60.576738727713121, 3.1331945022336436],
						[60.577099823450212, 3.1338028942882836],
						[60.577247002626017, 3.1341111512782187],
						[60.577269199835762, 3.1344583133683734],
						[60.577269200728864, 3.1344583132059589],
						[60.577269200808772, 3.1344583150210243],
						[60.577283100142679, 3.1346472092723241],
						[60.577288699747406, 3.1350222030576012],
						[60.577288703385655, 3.1350222243512258],
						[60.577288713341432, 3.1350222664512946],
						[60.577288719659002, 3.135022287257716],
						[60.577544219619803, 3.1355528875472825],
						[60.57754422059282, 3.1355528891999769],
						[60.577735899764747, 3.1359361484511279],
						[60.577738699708213, 3.1363333006974026],
						[60.577738699788107, 3.1363333025125151],
						[60.577738699947894, 3.1363333061427063],
						[60.577738700027794, 3.1363333079577997],
						[60.577758099946578, 3.1368500089215554],
						[60.577758102611512, 3.1368500285629675],
						[60.577758114273081, 3.1368500685244132],
						[60.577758120590374, 3.1368500893315066],
						[60.577994221138056, 3.1373250884858193],
						[60.578213720426845, 3.1377639883477242],
						[60.578441519462913, 3.1382444867683983],
						[60.578441520435881, 3.1382444884212197],
						[60.578441522381844, 3.1382444917268635],
						[60.578441523354819, 3.138244493379688],
						[60.578669199922899, 3.1386778497778387],
						[60.578666499647852, 3.1391332992557706],
						[60.578666499647852, 3.1391332992557706],
						[60.578666500441734, 3.1391361003376685],
						[60.578666499628476, 3.1391361023151219],
						[60.578666499868035, 3.1391361077606086],
						[60.578666499947893, 3.1391361095757637],
						[60.578688699602885, 3.1395889097494409],
						[60.578688703160552, 3.1395889292293786],
						[60.578688711902437, 3.1395889642343473],
						[60.578688718139453, 3.1395889832274357],
						[60.578888717978252, 3.1400250824080747],
						[60.578888720004009, 3.1400250875290459],
						[60.578888724868797, 3.1400250957935589],
						[60.578888726894569, 3.1400251009145439],
						[60.579166525069546, 3.140502896629596],
						[60.579338724147547, 3.140825094090018],
						[60.579338725200337, 3.1408250975581824],
						[60.579338728039346, 3.1408251007018189],
						[60.57933872909215, 3.1408251041699624],
						[60.579699803375199, 3.141419461232394],
						[60.579752600008618, 3.1422722120678848],
						[60.579752600088426, 3.14227221388313],
						[60.579752601141166, 3.1422722173513691],
						[60.579752601141166, 3.1422722173513691],
						[60.579758100889769, 3.1423333175577541],
						[60.579758105579614, 3.1423333423221402],
						[60.579758123890471, 3.1423333902287522],
						[60.579758135725278, 3.1423334136954173],
						[60.579783131951494, 3.1423695083073362],
						[60.579899828902121, 3.1425612044839468],
						[60.579899914755494, 3.1425612529318543],
						[60.579900086618203, 3.1425612509936589],
						[60.579900172627504, 3.1425612006075641],
						[60.580033469883723, 3.1423334044983942],
						[60.580147368035, 3.142158408854256],
						[60.580147368848309, 3.1421584068767685],
						[60.580147371368035, 3.142158402759601],
						[60.58014737307446, 3.1421584006199041],
						[60.580233433363361, 3.1420112681080354],
						[60.580486157288895, 3.14193629125602],
						[60.580486162647595, 3.1419362902826387],
						[60.580486174991613, 3.141936284380872],
						[60.580486181243437, 3.1419362832452542],
						[60.580680565455211, 3.1418502341944148],
						[60.581047069418659, 3.1421668514008436],
						[60.581047075016805, 3.1421668558733762],
						[60.581047087106235, 3.1421668646562293],
						[60.581047092704395, 3.1421668691287774],
						[60.581130455727873, 3.1422195467507428],
						[60.581208132382599, 3.1423390080635683],
						[60.581208136274341, 3.1423390146759234],
						[60.581208143898166, 3.1423390242700031],
						[60.581208148682997, 3.14233903072014],
						[60.581577648603528, 3.1427668306545482],
						[60.581577672941926, 3.1427668518516554],
						[60.58157772836465, 3.1427668838714529],
						[60.581577756769612, 3.1427668951808059],
						[60.581794225277747, 3.1428140675711003],
						[60.581866503363536, 3.1432528331431331],
						[60.581866512758232, 3.143252862544295],
						[60.581866542185246, 3.1432529175848321],
						[60.58186656132446, 3.1432529433864071],
						[60.582027661032882, 3.1434084437839687],
						[60.582027695275293, 3.1434084650129992],
						[60.582027767667185, 3.1434084939525002],
						[60.582027806629945, 3.1434084996854201],
						[60.582102806602002, 3.1434057994405475],
						[60.582102808388264, 3.1434057991161404],
						[60.582102809361182, 3.1434058007693046],
						[60.582408308564709, 3.1433919001125261],
						[60.58247780361021, 3.1433890997639615],
						[60.582541699788969, 3.1433891003714494],
						[60.582541726582569, 3.1433890955053205],
						[60.582541778223927, 3.1433890824667028],
						[60.582541802991898, 3.1433890724788212],
						[60.582764002847576, 3.1432557713357747],
						[60.582764008126489, 3.1432557685471187],
						[60.582764016818288, 3.1432557614788079],
						[60.582764021204078, 3.1432557588523617],
						[60.582880684183081, 3.1431668876715948],
						[60.582925037083164, 3.1431584972040407],
						[60.582925081180541, 3.1431584763855054],
						[60.582925154287295, 3.1431584191897306],
						[60.582925183216908, 3.1431583809970598],
						[60.583114082731491, 3.1427306817292542],
						[60.583283482814871, 3.1423472810137856],
						[60.583283489241495, 3.1423472633770126],
						[60.583283496576392, 3.1423472254460418],
						[60.583283500243823, 3.1423472064805598],
						[60.583289098659073, 3.1421778196405041],
						[60.583377997036344, 3.1416472320883395],
						[60.583377997036344, 3.1416472320883395],
						[60.583377997769801, 3.1416472282952244],
						[60.583377997689972, 3.1416472264797797],
						[60.58340019760373, 3.1414861299017098],
						[60.583408490631243, 3.1414361719889397],
						[60.58364187003594, 3.1410612064933132],
						[60.583641869956111, 3.1410612046778708],
						[60.58364187084922, 3.1410612045155957],
						[60.583825171002069, 3.1407612045059379],
						[60.583825170922239, 3.1407612026904688],
						[60.583825172628629, 3.1407612005504482],
						[60.583825172628629, 3.1407612005504482],
						[60.583889073291701, 3.1406500996276283],
						[60.583889074184839, 3.1406500994653359],
						[60.583889074104995, 3.140650097649881],
						[60.583889074998105, 3.1406500974875953],
						[60.583958475238859, 3.1405250972971404],
						[60.583958475159029, 3.140525095481681],
						[60.58412517480739, 3.1402222976019747],
						[60.584275172262004, 3.139952902334731],
						[60.584369569156046, 3.1398029077698495],
						[60.584369569969311, 3.1398029057920378],
						[60.584369570782577, 3.1398029038142448],
						[60.584369570782577, 3.1398029038142448],
						[60.584483470735513, 3.1396140026212245],
						[60.584483475774789, 3.1396139943853596],
						[60.5844834840671, 3.1396139782382351],
						[60.584483488133401, 3.1396139683491895],
						[60.584586288159485, 3.1393361684781951],
						[60.584725187690829, 3.1389583699564034],
						[60.584725187531106, 3.1389583663254119],
						[60.584725190050726, 3.1389583622073967],
						[60.584725190863971, 3.1389583602295428],
						[60.584775190870985, 3.1388000593308756],
						[60.584775190791127, 3.1388000575153754],
						[60.584775193230882, 3.1388000515818422],
						[60.584775194044113, 3.1388000496039847],
						[60.584800193544012, 3.1387028495619771],
						[60.58480019435725, 3.1387028475841285],
						[60.584800195090637, 3.1387028437907825],
						[60.584800195010772, 3.1387028419752814],
						[60.584914087105048, 3.138175082162832],
						[60.58517516262993, 3.1378057147805301],
						[60.585175166855869, 3.1378057085220785],
						[60.585175175307711, 3.1378056960052012],
						[60.585175178640512, 3.1378056899091473],
						[60.58517796464298, 3.1378001178285957],
						[60.585183442213925, 3.1377945413556425],
						[60.585480740436957, 3.137500141739805],
						[60.585625066650927, 3.1373585141979055],
						[60.585880566380396, 3.1374029976833073],
						[60.585880576284474, 3.1374029977125755],
						[60.585880597958763, 3.1374029992618473],
						[60.585880608835865, 3.1374030009442766],
						[60.586074894355065, 3.1373946041916199],
						[60.586333112374973, 3.1377972772199771],
						[60.586522000832183, 3.1387111237499212],
						[60.586555399771271, 3.1396250072962375],
						[60.586558099648009, 3.1397000052882746],
						[60.586574799695121, 3.1404250057760938],
						[60.58657481054248, 3.1404250477275504],
						[60.586574849939858, 3.1404251247529857],
						[60.586574877516902, 3.1404251581736786],
						[60.586719187675243, 3.1405361890738415],
						[60.586680401257773, 3.1408666775182019],
						[60.586680401257773, 3.1408666775182019],
						[60.586644201221119, 3.141194378146599],
						[60.586644260650843, 3.1411944808148577],
						[60.586644436839229, 3.1411945567760462],
						[60.586644551731858, 3.1411945285779295],
						[60.586769552154962, 3.1410473306356401],
						[60.586966822552824, 3.140819563026104],
						[60.587080682690164, 3.1407668821975006],
						[60.587366781637087, 3.140639083313729],
						[60.587366794794271, 3.1406390754320852],
						[60.587366821108688, 3.1406390596688065],
						[60.587366833213089, 3.140639048318175],
						[60.587416637746564, 3.1405947223629411],
						[60.587849805957994, 3.1414556591778617],
						[60.587863701194152, 3.1415722246393498],
						[60.587863702167084, 3.1415722262927424],
						[60.587969202315485, 3.1423694268376883],
						[60.5879692023953, 3.1423694286533759],
						[60.58796920344804, 3.142369432122488],
						[60.587969202554945, 3.142369432284756],
						[60.588024787685939, 3.1426971454200312],
						[60.58786374255471, 3.1428998760190758],
						[60.587863734995494, 3.1428998883734813],
						[60.587863719877099, 3.142899913082283],
						[60.587863714183939, 3.1428999269278663],
						[60.587780491037577, 3.1431108316165002],
						[60.58741401108778, 3.1429470313027537],
						[60.587125135394487, 3.1426831523358194],
						[60.587125124198181, 3.1426831433889943],
						[60.587125100099172, 3.1426831276354932],
						[60.587125088089572, 3.1426831206665615],
						[60.586966825658173, 3.1426054388227889],
						[60.586761283185524, 3.1423610065781835],
						[60.586680794071071, 3.1420332524572983],
						[60.586680791885712, 3.1420332437037448],
						[60.586680785808625, 3.1420332283367975],
						[60.58668078273017, 3.1420332197455081],
						[60.586516882958314, 3.1416610195424703],
						[60.586516791187044, 3.1416609593524516],
						[60.586516606741085, 3.1416609617541345],
						[60.586516515772765, 3.1416610222056809],
						[60.586358135350103, 3.142033178203512],
						[60.586066570570836, 3.1422776474330041],
						[60.586066551720414, 3.1422776691585392],
						[60.586066519617752, 3.1422777170828038],
						[60.586066508231532, 3.1422777447726213],
						[60.586052609008888, 3.1423249404797651],
						[60.586041509776152, 3.1423582367326226],
						[60.586041509855981, 3.1423582385482192],
						[60.586041508229385, 3.1423582425038914],
						[60.586041508309201, 3.1423582443194715],
						[60.586002607599148, 3.1424943433115198],
						[60.585883107913602, 3.1428999437543603],
						[60.585883107913602, 3.1428999437543603],
						[60.585883107993411, 3.142899945569936],
						[60.585777608013665, 3.1432721449441314],
						[60.58577760573332, 3.1432721545086801],
						[60.585777601985939, 3.1432721716599654],
						[60.585777601412055, 3.143272179084486],
						[60.585749801708225, 3.143538869599567],
						[60.58562201465633, 3.1441832017401463],
						[60.585616556787727, 3.1441887607845036],
						[60.585616538909903, 3.1441887841619782],
						[60.585616511671077, 3.1441888403505072],
						[60.585616502230273, 3.144188871346],
						[60.585513707021953, 3.144883238913613],
						[60.585408121110987, 3.1450943106721336],
						[60.585408116230937, 3.1450943225385304],
						[60.585408107443726, 3.1450943479247231],
						[60.585408104429668, 3.1450943612823266],
						[60.585338707962357, 3.1454443376117869],
						[60.58516652335954, 3.1458221063374983],
						[60.585116581681348, 3.1459054086735558],
						[60.584716692816656, 3.1459330996532087],
						[60.584683299906921, 3.1459330993994024],
						[60.584683266861354, 3.1459331053988802],
						[60.584683203928172, 3.1459331278045091],
						[60.584683174040563, 3.1459331442106633],
						[60.584597074199685, 3.1460026435039063],
						[60.584597073386341, 3.1460026454815635],
						[60.584597070786721, 3.1460026477835297],
						[60.584597069893611, 3.1460026479456742],
						[60.584366570412335, 3.1461998472602426],
						[60.584366547334881, 3.1461998752395175],
						[60.584366513269224, 3.1461999399832838],
						[60.584366502201206, 3.1461999749322844],
						[60.584274809904578, 3.1469138054047896],
						[60.584269259935418, 3.1469192578358443],
						[60.584269245390296, 3.1469192751158581],
						[60.584269220191615, 3.1469193162893783],
						[60.584269210351387, 3.14691933820529],
						[60.584266510459493, 3.1469277392693793],
						[60.584266507206003, 3.1469277471798538],
						[60.584266502724525, 3.1469277681230534],
						[60.584266501337048, 3.1469277775247866],
						[60.58418310060285, 3.1476555762180203],
						[60.584163703253431, 3.1478249614175353],
						[60.584041506809733, 3.1482777485831841],
						[60.584041506809733, 3.1482777485831841],
						[60.584041505996332, 3.1482777505607511],
						[60.584041505996332, 3.1482777505607511],
						[60.584038705797369, 3.1482888505350961],
						[60.584038705143449, 3.1482888561436551],
						[60.584038702129085, 3.1482888695004023],
						[60.584038702368289, 3.1482888749468638],
						[60.583983101904614, 3.1487388737887869],
						[60.583947001701262, 3.1489999729660076],
						[60.583869201693325, 3.1495388716433741],
						[60.583869201995938, 3.149538899037688],
						[60.583869208853123, 3.1495389526920401],
						[60.583869216300776, 3.1495389787900283],
						[60.583916499862625, 3.149650040368718],
						[60.583916500175754, 3.1498555694443384],
						[60.583816509164677, 3.1501749391742115],
						[60.583816506963558, 3.1501749505531902],
						[60.583816503294976, 3.1501749695181664],
						[60.583816501014134, 3.1501749790816658],
						[60.583783106199441, 3.1504916316423248],
						[60.58373873179886, 3.1505609915635113],
						[60.583738727652012, 3.1505609996355135],
						[60.583738720985188, 3.1505610118245153],
						[60.583738716758624, 3.1505610180810386],
						[60.583560917243055, 3.1509582171717807],
						[60.583560909251659, 3.150958260709674],
						[60.583560909265522, 3.1509583430539698],
						[60.583560918243641, 3.1509583835138204],
						[60.583799817695855, 3.1514750836571679],
						[60.58379981964152, 3.151475086964175],
						[60.583799822639669, 3.1514750937401472],
						[60.583799824585334, 3.151475097047153],
						[60.583813722271856, 3.1515000914068647],
						[60.584224818147341, 3.1523889838812043],
						[60.584224819120159, 3.1523889855347487],
						[60.584224820013297, 3.1523889853727796],
						[60.584224820092992, 3.1523889871882909],
						[60.584263719003985, 3.1524694858987443],
						[60.584641516623222, 3.1533028807592487],
						[60.584708116330482, 3.1534611786848106],
						[60.584708116330482, 3.1534611786848106],
						[60.584708117382952, 3.1534611821539702],
						[60.584708118355742, 3.1534611838075723],
						[60.585052616871238, 3.1542167811872184],
						[60.585155416874777, 3.1544500819115324],
						[60.585155417767901, 3.1544500817496188],
						[60.585155418820349, 3.1544500852188597],
						[60.585155419793132, 3.154450086872516],
						[60.585483119006021, 3.1551333833737796],
						[60.585605418352209, 3.1554056817520144],
						[60.585605418431847, 3.1554056835676136],
						[60.585899818188039, 3.1560472822128722],
						[60.586052617872127, 3.1563861821779233],
						[60.586052617951744, 3.1563861839935523],
						[60.586052619817679, 3.1563861854854367],
						[60.586052619897288, 3.1563861873010661],
						[60.586333119079875, 3.1569611854142008],
						[60.586502617275968, 3.1573417805745101],
						[60.586502619301086, 3.157341785697803],
						[60.586502622219335, 3.1573417906592538],
						[60.586502624244474, 3.1573417957825276],
						[60.586788718899115, 3.1578750850761876],
						[60.586947014925144, 3.1582611766990487],
						[60.586947016870624, 3.1582611800067899],
						[60.586947020841208, 3.1582611884379346],
						[60.586947022866291, 3.1582611935613376],
						[60.587227619908134, 3.1587917877001184],
						[60.587397017757056, 3.1591639834215397],
						[60.587397018650222, 3.1591639832597367],
						[60.587397019782117, 3.1591639885450906],
						[60.587397020675255, 3.1591639883832894],
						[60.587666519791604, 3.1597056886517643],
						[60.587844220194569, 3.1600750868666507],
						[60.587844220274128, 3.160075088682393],
						[60.587844221246847, 3.1600750903363544],
						[60.587844222139999, 3.1600750901745758],
						[60.588122020577387, 3.1606222893562093],
						[60.588291519964336, 3.1609694854897001],
						[60.588558102117069, 3.1615361484807569],
						[60.588572000276884, 3.1618722031315767],
						[60.588572000186687, 3.1621082982954265],
						[60.58856649992574, 3.1624471975102266],
						[60.588566501773442, 3.1624472191379951],
						[60.58856651180043, 3.1624472630772975],
						[60.588566519086562, 3.1624472855505674],
						[60.588735919206414, 3.1628083844447383],
						[60.588735919285945, 3.1628083862605383],
						[60.588735920179104, 3.1628083860988272],
						[60.588735920258642, 3.1628083879146254],
						[60.589008119051016, 3.1633639842602763],
						[60.589188716571456, 3.1637750804842009],
						[60.589188716571456, 3.1637750804842009],
						[60.589188718437271, 3.1637750819766617],
						[60.589188717623649, 3.1637750839541749],
						[60.589416509434621, 3.1642778636883935],
						[60.589613697929003, 3.1651500154676944],
						[60.589610899911122, 3.1651916878269417],
						[60.589610901837887, 3.1651917112714463],
						[60.589610910157205, 3.1651917573522272],
						[60.589610916629248, 3.1651917818043445],
						[60.589633110125583, 3.1652417647623938],
						[60.589849806237787, 3.1661056496693987],
						[60.589849809314728, 3.1661056582638976],
						[60.589849817413949, 3.1661056787613893],
						[60.589849820570393, 3.1661056891717756],
						[60.590080420951509, 3.1665694882085864],
						[60.590080421030983, 3.1665694900244756],
						[60.590080422003638, 3.1665694916787626],
						[60.590080422976271, 3.1665694933330348],
						[60.590316502242878, 3.1670194533061364],
						[60.590335900318351, 3.1675389038811614],
						[60.590338700279666, 3.1679333015707241],
						[60.590338703178602, 3.1679333266702749],
						[60.590338715069628, 3.1679333721065328],
						[60.590338724141191, 3.1679333942591286],
						[60.590527620593882, 3.1682833895297802],
						[60.590794218585913, 3.1688472857370549],
						[60.59079422045172, 3.1688472872298736],
						[60.590794221424332, 3.1688472888842676],
						[60.590794222396944, 3.1688472905386469],
						[60.590977621840963, 3.1692083914617299],
						[60.590977621840963, 3.1692083914617299],
						[60.59097762281359, 3.1692083931161275],
						[60.590977623786202, 3.1692083947705254],
						[60.591274804382593, 3.1697639560624884],
						[60.591327599748013, 3.1704861126621093],
						[60.591335899745204, 3.1706778093518446],
						[60.591335903298464, 3.170677828843024],
						[60.591335914136565, 3.1706778708112817],
						[60.591335921262477, 3.1706778896564236],
						[60.591422021009571, 3.1708500896542948],
						[60.591422023927358, 3.170850094617724],
						[60.591422028869786, 3.1708501047060667],
						[60.591422031708127, 3.170850107853505],
						[60.591769231774776, 3.1713890088476813],
						[60.591769343361797, 3.1713890490736314],
						[60.591769526638657, 3.171388999462267],
						[60.591769599955938, 3.1713889056699589],
						[60.591789098775152, 3.1706778370090709],
						[60.591822322309355, 3.1705835542941823],
						[60.591869333373907, 3.1706001894898446],
						[60.591869371363316, 3.1706001936022532],
						[60.591869445079261, 3.1706001912540702],
						[60.591869480726295, 3.1706001829774935],
						[60.592205680821444, 3.1704501835749186],
						[60.592205713967211, 3.1704501592769256],
						[60.592205767277811, 3.1704501020460754],
						[60.592205788335818, 3.1704500689517152],
						[60.592319584413673, 3.1701417774926055],
						[60.592411279690893, 3.1699528876557004],
						[60.592500180180593, 3.1697694863675414],
						[60.592500185062846, 3.169769474502083],
						[60.592500193695876, 3.1697694454845897],
						[60.592500196712379, 3.1697694321261771],
						[60.592566896946472, 3.1693528314367465],
						[60.592566897601259, 3.1693528258270747],
						[60.592566898910874, 3.169352814607739],
						[60.59256690037936, 3.1693528070204939],
						[60.592586300270625, 3.1688583085179429],
						[60.592586299793851, 3.168858297621703],
						[60.59258629899923, 3.168858279461344],
						[60.592586297708763, 3.1688582705427235],
						[60.592505799231382, 3.168319379250141],
						[60.592486301751606, 3.1679444369376886],
						[60.592622382718417, 3.1676361810908067],
						[60.592622382638929, 3.1676361792747576],
						[60.592622384345781, 3.1676361771355221],
						[60.592622384345781, 3.1676361771355221],
						[60.592772379186179, 3.167277890432362],
						[60.592839072647187, 3.1671640013000091],
						[60.592839072567699, 3.1671639994839662],
						[60.592914072840188, 3.167033399476658],
						[60.592914078615365, 3.1670333874490191],
						[60.592914089352043, 3.1670333653714127],
						[60.592914093340916, 3.1670333536670121],
						[60.593025193022378, 3.1666333546954752],
						[60.593097389634977, 3.1663778617149552],
						[60.593191888230372, 3.1661222685119985],
						[60.593191888230372, 3.1661222685119985],
						[60.593191888964533, 3.1661222647182155],
						[60.593191889778183, 3.1661222627405095],
						[60.593205790359207, 3.1660806631548071],
						[60.593205791013879, 3.166080657544958],
						[60.593205794188989, 3.1660806478180508],
						[60.5932057957368, 3.1660806420465732],
						[60.593222386065833, 3.1660028874156834],
						[60.593383456266714, 3.1658001252913843],
						[60.59346405498917, 3.1657001276519283],
						[60.593675123992433, 3.1654473643986969],
						[60.593769478946662, 3.1654057842226],
						[60.594097230038031, 3.165269603332745],
						[60.594124979617312, 3.1652723982551914],
						[60.594125012902502, 3.1652723977214889],
						[60.594125076157376, 3.1652723826102385],
						[60.59412510620654, 3.165272369848783],
						[60.594147305044068, 3.1652584703908113],
						[60.594219494559383, 3.1652140753799709],
						[60.594505684008091, 3.1650807810441806],
						[60.594505684901243, 3.1650807808824957],
						[60.594505685794388, 3.165080780720813],
						[60.594505685794388, 3.165080780720813],
						[60.594569456868811, 3.1650501952624976],
						[60.594661094597981, 3.1650391014939849],
						[60.594955566312933, 3.1650890966234373],
						[60.595019285809471, 3.1651001835023833],
						[60.595055437728526, 3.1651501171405645],
						[60.595055446243997, 3.1651501265823727],
						[60.595055463274925, 3.1651501454659865],
						[60.595055471790388, 3.1651501549077947],
						[60.595138739423206, 3.1652195267603696],
						[60.59530262025234, 3.1655556885832068],
						[60.595302633790105, 3.1655557099300609],
						[60.595302666958695, 3.1655557478596941],
						[60.595302684803215, 3.1655557647658119],
						[60.595469227782111, 3.1656723238156745],
						[60.595608107873431, 3.1661361564352215],
						[60.595608108846079, 3.1661361580897553],
						[60.595608108925568, 3.1661361599059514],
						[60.595608109898222, 3.1661361615604848],
						[60.595730404395731, 3.1665167421422327],
						[60.59575540073741, 3.1667222239590571],
						[60.595755401710051, 3.1667222256136167],
						[60.595797002256738, 3.167050025401029],
						[60.595797002256738, 3.167050025401029],
						[60.595797002336226, 3.1670500272172384],
						[60.595797001522563, 3.1670500291950856],
						[60.595824800611041, 3.1672361200846213],
						[60.595855399677298, 3.1678417100055416],
						[60.595855399756772, 3.1678417118217568],
						[60.595855399836239, 3.167841713637972],
						[60.595855399836239, 3.167841713637972],
						[60.595863700350364, 3.1679611026221659],
						[60.595858099836867, 3.1680721899356366],
						[60.59585810007529, 3.1680721953842799],
						[60.595858099738471, 3.1680722082594333],
						[60.595858100870046, 3.1680722135464676],
						[60.595913700750053, 3.168852813683146],
						[60.595913700908994, 3.1688528173155852],
						[60.595913702120008, 3.1688528244188974],
						[60.595913702278935, 3.1688528280513406],
						[60.595916500319106, 3.1688722131422478],
						[60.595916499793752, 3.1688749994118788],
						[60.595916502692589, 3.1688750245158479],
						[60.595916515635487, 3.1688750734310629],
						[60.595916524786411, 3.1688750974038959],
						[60.596152624804148, 3.1693000967722478],
						[60.596152639155029, 3.1693001161434351],
						[60.596152671270552, 3.1693001506070182],
						[60.596152689035158, 3.1693001656994184],
						[60.596363789161408, 3.169441866036784],
						[60.596363857340236, 3.1694418811623324],
						[60.596363987245688, 3.1694418668150504],
						[60.596364048813378, 3.1694418337097106],
						[60.596541846609632, 3.1692445366854427],
						[60.596733443377147, 3.1690418393168773],
						[60.596808440667424, 3.1689668414922245],
						[60.596808450094855, 3.1689668306333356],
						[60.596808467163406, 3.168966809238738],
						[60.596808473911359, 3.1689667988646244],
						[60.596833474399979, 3.1689222979209384],
						[60.596833476841063, 3.1689222919873195],
						[60.596833482616383, 3.1689222799585135],
						[60.596833486030107, 3.1689222756795892],
						[60.596850186354395, 3.1688806749921508],
						[60.59685018708862, 3.1688806711979915],
						[60.596850188636544, 3.1688806654259811],
						[60.596850190263922, 3.1688806614702298],
						[60.596925189024233, 3.1686500658830457],
						[60.597019587633191, 3.1683861667054223],
						[60.597019589419531, 3.1683861663821991],
						[60.597166889379238, 3.167969466620566],
						[60.597166890927127, 3.1679694608484548],
						[60.597166893863935, 3.1679694456716239],
						[60.597166896304977, 3.1679694397378926],
						[60.597194595640737, 3.1678306397951972],
						[60.597194597188597, 3.1678306340230598],
						[60.597194599391194, 3.1678306226404254],
						[60.597194599152772, 3.1678306171915374],
						[60.597264099383281, 3.1670556170640367],
						[60.597264099878977, 3.167055607820918],
						[60.597264099004576, 3.1670555878416833],
						[60.597264098607134, 3.1670555787602264],
						[60.597161298622055, 3.1661416781021079],
						[60.597133498722229, 3.1658777785890257],
						[60.597133498642727, 3.165877776772744],
						[60.597133498404197, 3.1658777713238888],
						[60.597133498324695, 3.1658777695076079],
						[60.597030797965388, 3.1652277685812495],
						[60.597030796992726, 3.1652277669266735],
						[60.597030795861016, 3.1652277616395552],
						[60.597030795781521, 3.1652277598232632],
						[60.596864096159607, 3.1644055591521285],
						[60.596864096159607, 3.1644055591521285],
						[60.596864096080076, 3.164405557335852],
						[60.59686409518693, 3.1644055574975698],
						[60.596844596558469, 3.1643166652440171],
						[60.596839097962857, 3.1642721744231879],
						[60.596839096751602, 3.1642721673198593],
						[60.596839093276877, 3.1642721496424078],
						[60.596839091092946, 3.1642721408845431],
						[60.596816890696807, 3.1641999411548731],
						[60.596816890617283, 3.1641999393386149],
						[60.596816889644607, 3.1641999376840824],
						[60.596816888592386, 3.1641999342132774],
						[60.596541888760683, 3.163399935469617],
						[60.596541887708469, 3.1633999319988653],
						[60.59654188568355, 3.1633999268736401],
						[60.596541883658645, 3.1633999217483941],
						[60.596461284294769, 3.1632082227949185],
						[60.596461280324483, 3.1632082143607256],
						[60.596461272383856, 3.1632081974923669],
						[60.596461266627244, 3.1632081893816766],
						[60.596369582226544, 3.1630693137064578],
						[60.596183490922833, 3.1624860391611076],
						[60.5961834888979, 3.1624860340359744],
						[60.596183483875265, 3.1624860221312887],
						[60.596183481850325, 3.1624860170061755],
						[60.595919582303424, 3.1619055167213506],
						[60.595919581251152, 3.1619055132507157],
						[60.595919578333032, 3.1619055082874632],
						[60.595919576467196, 3.1619055067948252],
						[60.595741875901133, 3.1615721066663545],
						[60.595741874848876, 3.1615721031957484],
						[60.595741870958015, 3.1615720965781566],
						[60.595741869012599, 3.1615720932693439],
						[60.595475170642246, 3.1611470979461989],
						[60.595189073786138, 3.1606555018043974],
						[60.595025175441371, 3.1603638028570908],
						[60.594689077702874, 3.1597416089538597],
						[60.594577978765123, 3.1595166117240523],
						[60.594577976740062, 3.1595166065993818],
						[60.594577970903643, 3.1595165966737033],
						[60.594577968878582, 3.1595165915490147],
						[60.594136288130812, 3.1588249215283475],
						[60.59413079769962, 3.1587999689285384],
						[60.594039100029157, 3.1579110905993035],
						[60.5940390999044, 3.1577221999913538],
						[60.594039099585942, 3.1577221927270211],
						[60.594039097896662, 3.1577221747280739],
						[60.594039096685059, 3.1577221676256202],
						[60.59391689837642, 3.1569971711948939],
						[60.593841897535683, 3.1564027742824692],
						[60.593841898269538, 3.1564027704884237],
						[60.593841896085102, 3.1564027617319232],
						[60.593841894953073, 3.1564027564456341],
						[60.593772397805296, 3.1560860688406049],
						[60.593686298658525, 3.1552360808597806],
						[60.593686299392374, 3.1552360770657248],
						[60.593686299312729, 3.1552360752496726],
						[60.593686298339954, 3.1552360735955527],
						[60.593677999100819, 3.1551721766567269],
						[60.593675198568732, 3.1551471783347282],
						[60.593675198250139, 3.1551471710705079],
						[60.593675196065625, 3.1551471623141425],
						[60.593675194933546, 3.1551471570279412],
						[60.593477996624614, 3.154258264842551],
						[60.593400197528162, 3.1536721734244457],
						[60.593400198261953, 3.1536721696303776],
						[60.593400197129817, 3.1536721643442758],
						[60.593400196077347, 3.1536721608742075],
						[60.593333497967727, 3.1533443673167239],
						[60.593239098820057, 3.1525749764811937],
						[60.593239097687857, 3.1525749711951452],
						[60.593239096396296, 3.1525749622770625],
						[60.593239096236921, 3.1525749586450273],
						[60.593208495961036, 3.1524305591042761],
						[60.593208494908531, 3.1524305556342567],
						[60.593208492803534, 3.1524305486942401],
						[60.593208492723846, 3.1524305468782248],
						[60.59316959294182, 3.152291645517697],
						[60.592952992613412, 3.1515166465067601],
						[60.59279189261013, 3.1509138480624568],
						[60.592708496302983, 3.1506027581316833],
						[60.59266689677078, 3.1503471673683716],
						[60.59266689677078, 3.1503471673683716],
						[60.592616896991018, 3.1500471666607903],
						[60.592616896991018, 3.1500471666607903],
						[60.592616896831586, 3.15004716302884],
						[60.59261689585874, 3.150047161374951],
						[60.592547399414912, 3.1496888749735796],
						[60.592525199791012, 3.1493249872298215],
						[60.592525199392377, 3.1493249781499708],
						[60.592525195995456, 3.1493249622925648],
						[60.59252519470369, 3.1493249533748151],
						[60.592441894629516, 3.1489693542438335],
						[60.592441893656648, 3.148969352589988],
						[60.592394597969196, 3.1487749696795855],
						[60.592389100213062, 3.1486916871029051],
						[60.592389096816071, 3.1486916712456341],
						[60.592389090022053, 3.1486916395310689],
						[60.592389084918544, 3.1486916258140014],
						[60.592347384670177, 3.1485888257656205],
						[60.592347366185763, 3.1485887943427908],
						[60.592347317111823, 3.1485887428466963],
						[60.592347288228851, 3.1485887206332053],
						[60.592227888081247, 3.1485304197695614],
						[60.592227887188116, 3.1485304199316877],
						[60.592227886215248, 3.1485304182778653],
						[60.592227885322124, 3.1485304184399916],
						[60.592055685130731, 3.1484498186052168],
						[60.592055658671903, 3.1484498105953214],
						[60.592055606073146, 3.1484498018392952],
						[60.592055579040135, 3.1484498012552824],
						[60.591900017774357, 3.1484664972468352],
						[60.591719457376385, 3.1484137078990897],
						[60.591636157278153, 3.1483887086558706],
						[60.591636155412132, 3.1483887071641949],
						[60.59145013730376, 3.1483359300636344],
						[60.591197379775224, 3.1478582114102722],
						[60.591102981613624, 3.1476499172070649],
						[60.591000185326955, 3.1474221235410687],
						[60.590819590254519, 3.146944335179708],
						[60.590552992234095, 3.1460582426366654],
						[60.590552991340971, 3.1460582427988499],
						[60.590544596687742, 3.1460305605508423],
						[60.59054189902033, 3.1460027809147215],
						[60.590541897728365, 3.1460027719976633],
						[60.59054189522417, 3.1460027559793913],
						[60.590541893039081, 3.1460027472245344],
						[60.590302993157884, 3.1451166483007147],
						[60.590302993078119, 3.1451166464848828],
						[60.590302992105215, 3.1451166448312624],
						[60.590108491851858, 3.1444416457068862],
						[60.590108490719366, 3.1444416404216455],
						[60.590108487641047, 3.144441631829225],
						[60.590108486588356, 3.1444416283598158],
						[60.590016890013658, 3.1442027384174778],
						[60.589905792862261, 3.1437888498193272],
						[60.589777995953213, 3.1432888586894165],
						[60.589747396605439, 3.143108267700196],
						[60.589747394100904, 3.1431082516825626],
						[60.589747383812963, 3.1431082224366547],
						[60.58974737781579, 3.1431082088838633],
						[60.589661278125099, 3.1429388089770858],
						[60.589661275126495, 3.1429388022007152],
						[60.589661267422862, 3.1429387907883086],
						[60.589661262638032, 3.142938784336466],
						[60.589261263078384, 3.1423720852408601],
						[60.589261261052691, 3.142372080118129],
						[60.589261256267839, 3.1423720736664462],
						[60.589261253428838, 3.1423720705217546],
						[60.589230753131943, 3.1423359701165765],
						[60.58923073625764, 3.142335954879941],
						[60.589230699096206, 3.1423359286873129],
						[60.589230677836113, 3.1423359160778235],
						[60.589211244174358, 3.1423276437516803],
						[60.588855776692789, 3.1416471067265332],
						[60.588855775719843, 3.1416471050730728],
						[60.58876408007719, 3.141477712687518],
						[60.588755785413866, 3.1414582253155081],
						[60.588733486993476, 3.1413999284914533],
						[60.588733486993476, 3.1413999284914533],
						[60.588733486020537, 3.1413999268380253],
						[60.588733486020537, 3.1413999268380253],
						[60.588391886119439, 3.1405443265439636],
						[60.588391885066621, 3.1405443230748729],
						[60.588391884093681, 3.1405443214214852],
						[60.588391883120707, 3.1405443197681011],
						[60.588314083292957, 3.1403666199724412],
						[60.588314081267185, 3.1403666148499725],
						[60.588314075349587, 3.1403666031139936],
						[60.588314071537589, 3.1403665983161928],
						[60.587880792273971, 3.1396305337460912],
						[60.587866899203398, 3.1395332769942654],
						[60.587805801250823, 3.1388389014549865],
						[60.587819598733091, 3.1387167223721031],
						[60.587819598907402, 3.1387167058686987],
						[60.587819597310066, 3.138716669555254],
						[60.587819593832052, 3.138716651885634],
						[60.587769595321966, 3.1385138532956622],
						[60.587608495033393, 3.137802755356081],
						[60.587608491874796, 3.1378027449493167],
						[60.58760848466445, 3.1378027242981794],
						[60.587608480692616, 3.137802715869459],
						[60.587422381161396, 3.1374082148842981],
						[60.587422379135518, 3.1374082097621581],
						[60.587422374270545, 3.1374082014959348],
						[60.587422372244667, 3.1374081963738143],
						[60.587111280936853, 3.1368888126912604],
						[60.586972386840685, 3.1365166305262115],
						[60.586972386760799, 3.1365166287105946],
						[60.586766894286121, 3.1359749484379535],
						[60.586666897227929, 3.135349967043862],
						[60.586666897148021, 3.1353499652282695],
						[60.586666895935252, 3.1353499581283342],
						[60.586666894882349, 3.1353499546595982],
						[60.586600200434575, 3.1350610775899557],
						[60.586600199818562, 3.1349056045908141],
						[60.586625200244981, 3.1343444096115389],
						[60.586625200165052, 3.1343444077959535],
						[60.586625200165052, 3.1343444077959535],
						[60.58662520008513, 3.1343444059803658],
						[60.586630800449491, 3.1341500062284817],
						[60.586630800369555, 3.1341500044128954],
						[60.58663080020969, 3.1341500007817049],
						[60.58663080020969, 3.1341500007817049],
						[60.586630800180899, 3.1339333003164023],
						[60.586630800021041, 3.1339332966852123],
						[60.586630799701297, 3.1339332894228678],
						[60.586630798648343, 3.1339332859541678],
						[60.586589100061708, 3.1333638873629064],
						[60.586583500203773, 3.1332360907147967],
						[60.586583498910947, 3.1332360817993838],
						[60.58658349616546, 3.133236060337393],
						[60.586583493979553, 3.1332360515844906],
						[60.58656409424502, 3.1331582524606847],
						[60.586564092139049, 3.1331582455233735],
						[60.586564087927108, 3.1331582316487667],
						[60.586564084928035, 3.1331582248739673],
						[60.586527985404658, 3.1330693250759163],
						[60.58652798337863, 3.133069319954211],
						[60.5865279802996, 3.1330693113638519],
						[60.586527976567297, 3.1330693083827432],
						[60.586339077000936, 3.1327055084671667],
						[60.586339060698911, 3.1327054858121195],
						[60.58633902289619, 3.1327054451082801],
						[60.586339001395473, 3.1327054270595052],
						[60.586239001025589, 3.1326470272532903],
						[60.586077905598231, 3.1325526297888806],
						[60.585994532802637, 3.1324970484761887],
						[60.585839051389421, 3.1323192675350109],
						[60.585839048630177, 3.1323192662070491],
						[60.585839045790962, 3.1323192630635694],
						[60.585839043924786, 3.1323192615730986],
						[60.585700162941819, 3.132174881638019],
						[60.585627986648198, 3.1320416252480152],
						[60.585452995063669, 3.131405555503906],
						[60.585339095861791, 3.1308110628037107],
						[60.585339095781819, 3.1308110609882096],
						[60.585339095701848, 3.1308110591726868],
						[60.585339094728788, 3.1308110575197516],
						[60.585269595962608, 3.1304916620612757],
						[60.585183497247954, 3.1299777663426127],
						[60.585116898376135, 3.1295805719743779],
						[60.585097399414337, 3.1294082796030236],
						[60.585055798834361, 3.1289305828676235],
						[60.585055797621266, 3.1289305757682984],
						[60.585055796088191, 3.1289305614070293],
						[60.585055794875089, 3.1289305543077246],
						[60.584994597243039, 3.1286666668583814],
						[60.58494459901781, 3.1282388769903404],
						[60.584944596671555, 3.1282388646072885],
						[60.584944591165986, 3.1282388418192881],
						[60.584944588006664, 3.1282388314143454],
						[60.584852987876324, 3.1279916313373071],
						[60.58485298779631, 3.1279916295218406],
						[60.584852986903215, 3.127991629684483],
						[60.584764093406569, 3.1277527441465143],
						[60.584736295948559, 3.1276221589007958],
						[60.584736294815428, 3.1276221536170699],
						[60.584736291656057, 3.1276221432122555],
						[60.584736290602926, 3.127622139743973],
						[60.584586290737548, 3.1271471394341637],
						[60.584586275980747, 3.127147111011459],
						[60.584586238589388, 3.1271470592609409],
						[60.584586215061726, 3.127147036095792],
						[60.584358418031222, 3.1269859398590891],
						[60.58428906568804, 3.1269331744517137],
						[60.58425298815196, 3.1268388316486737],
						[60.584172393846167, 3.1265999496054708],
						[60.584052996646605, 3.125924964218334],
						[60.58405299227384, 3.1259249467149282],
						[60.584052977116642, 3.1259249092161516],
						[60.584052968278414, 3.1259248925262293],
						[60.583839068052228, 3.1255915917530692],
						[60.583839061320319, 3.1255915819995659],
						[60.583839046150395, 3.1255915646333676],
						[60.583839037632323, 3.1255915552052667],
						[60.583533456407586, 3.1252998715040374],
						[60.583394578628116, 3.1250805088969629],
						[60.58336689435906, 3.1250110477040018],
						[60.583355798323801, 3.1249360737140588],
						[60.583258499237068, 3.1240971765490522],
						[60.583216901184521, 3.1237360938224472],
						[60.583247399820706, 3.1231861106000496],
						[60.58324739966055, 3.1231861069693276],
						[60.583247400153269, 3.1231860977297345],
						[60.583247399913049, 3.1231860922836319],
						[60.583236301307714, 3.1228611124766656],
						[60.583325198364648, 3.1222722299998109],
						[60.583325198857302, 3.1222722207601854],
						[60.583325199842626, 3.1222722022809335],
						[60.583325200335253, 3.1222721930413302],
						[60.583319600408664, 3.1221221996542288],
						[60.583350200055122, 3.1213611071698226],
						[60.583350199654625, 3.1213610980929958],
						[60.583350198773523, 3.1213610781239867],
						[60.583350197399852, 3.1213610673946186],
						[60.583327997981364, 3.1212221685588926],
						[60.583252999721189, 3.1207416859270887],
						[60.583255799702812, 3.1204472027676893],
						[60.583255800195339, 3.1204471935280638],
						[60.583255798661355, 3.1204471791680626],
						[60.583255798260794, 3.1204471700912806],
						[60.583194598351788, 3.1200332713294405],
						[60.583194598271682, 3.1200332695140749],
						[60.58319459729848, 3.1200332678615794],
						[60.583194597218359, 3.1200332660462311],
						[60.583183500726349, 3.1199666897619167],
						[60.583205800418376, 3.1195361104657668],
						[60.583225199692286, 3.119186111155682],
						[60.58324189872318, 3.1189389153428309],
						[60.583266897960158, 3.1186222403368871],
						[60.583319590404081, 3.1184611610334563],
						[60.583319590404081, 3.1184611610334563],
						[60.583319591297155, 3.1184611608705608],
						[60.583400187373172, 3.1182083730077861],
						[60.583519580766335, 3.1179528856761487],
						[60.583633476294267, 3.1177111946038432],
						[60.583752970587057, 3.1175140033455171],
						[60.583752972373219, 3.1175140030196653],
						[60.58375297310598, 3.1175139992259808],
						[60.583752973999069, 3.1175139990630538],
						[60.583808334361969, 3.1174170422232734],
						[60.583983147415246, 3.1176168291436612],
						[60.584085940186306, 3.1177418205706684],
						[60.584147034694631, 3.1178307131993672],
						[60.584147043213235, 3.1178307226254689],
						[60.584147059357335, 3.1178307416405979],
						[60.584147067795797, 3.1178307492513069],
						[60.584335968122119, 3.1179973504792668],
						[60.584335969988416, 3.1179973519688389],
						[60.584335973721039, 3.1179973549479985],
						[60.584335975587351, 3.11799735643757],
						[60.584597075915028, 3.1182029561608675],
						[60.584597084273291, 3.1182029619563574],
						[60.58459709823051, 3.1182029722206228],
						[60.584597105615551, 3.118202976363603],
						[60.58498878155055, 3.1184112635193171],
						[60.585044251315161, 3.1184640335479199],
						[60.585097035011593, 3.1185334129896733],
						[60.585319230347331, 3.1188918054352648],
						[60.585319238945985, 3.1188918166774431],
						[60.58531925703641, 3.11889183899891],
						[60.585319267421241, 3.1188918499152893],
						[60.58549426740506, 3.1190473494192208],
						[60.585494268298149, 3.1190473492563253],
						[60.585494269271358, 3.1190473509089025],
						[60.585494270244574, 3.1190473525614797],
						[60.585633123245316, 3.1191668107957788],
						[60.58570810680029, 3.1194500503956322],
						[60.585708110040407, 3.1194500626154076],
						[60.585708119119808, 3.1194500847508211],
						[60.585708124065967, 3.1194500948293364],
						[60.585941524256697, 3.1198861945605847],
						[60.585941556464263, 3.1198862307794397],
						[60.585941635260596, 3.1198862822932041],
						[60.585941680143314, 3.1198862997293895],
						[60.586166680326627, 3.1199084997431612],
						[60.586166718809288, 3.1199084945549895],
						[60.586166791721524, 3.1199084739370759],
						[60.586166825990851, 3.1199084548762643],
						[60.586391826419899, 3.1197251556259395],
						[60.586391829019036, 3.1197251533217605],
						[60.586391834217252, 3.1197251487133961],
						[60.586391836816389, 3.1197251464092175],
						[60.586483436790495, 3.1196390463403967],
						[60.586483451331873, 3.1196390290470668],
						[60.586483475788981, 3.1196389916437397],
						[60.586483485704704, 3.1196389715337585],
						[60.586555779777001, 3.1194528878512906],
						[60.586608172106843, 3.1193645070353542],
						[60.586630401060191, 3.119547223767889],
						[60.586630404220209, 3.1195472341724733],
						[60.586630408834161, 3.1195472571229663],
						[60.5866304120743, 3.1195472693431108],
						[60.586891494596401, 3.1202611202124646],
						[60.586860902277728, 3.1204610705685556],
						[60.586860905013438, 3.1204611121635364],
						[60.586860929319741, 3.1204611937488678],
						[60.586860949917089, 3.120461232086555],
						[60.586985913019753, 3.1206028918785873],
						[60.587127602792741, 3.1213750367300062],
						[60.58712760587251, 3.1213750453193621],
						[60.587127611219046, 3.1213750644764957],
						[60.587127615272003, 3.1213750747185833],
						[60.587435914797922, 3.1221389755959765],
						[60.587497007034059, 3.1222889575017985],
						[60.587538702535134, 3.1225028343922165],
						[60.587652603279011, 3.1232028317951732],
						[60.587652605785884, 3.1232028478100955],
						[60.587652616078081, 3.1232028770475462],
						[60.587652622077236, 3.123202890595679],
						[60.587883117092069, 3.1236528804354244],
						[60.588058110302427, 3.124116762395146],
						[60.58830260059414, 3.1249750333571154],
						[60.588305400236493, 3.1250278106925391],
						[60.588305403876397, 3.1250278319921461],
						[60.58830541472858, 3.1250278739403594],
						[60.588305422833955, 3.125027894426216],
						[60.588330411997774, 3.1250750724619278],
						[60.588547005925896, 3.1259417472054687],
						[60.58854700908541, 3.1259417576113191],
						[60.588547016217511, 3.1259417764446211],
						[60.58854702027012, 3.1259417866877439],
						[60.588780420182744, 3.1264278868812547],
						[60.588985910769253, 3.1268583679294144],
						[60.589205402771192, 3.1277333372665947],
						[60.589210902288343, 3.1277722285032956],
						[60.589210904634676, 3.1277722408879303],
						[60.589210911113454, 3.1277722653318665],
						[60.589210915245943, 3.1277722773911454],
						[60.589224810194786, 3.1278056640085619],
						[60.589449806023943, 3.1286833494010979],
						[60.589449809183257, 3.128683359807515],
						[60.589449816314975, 3.1286833786419632],
						[60.589449820287371, 3.1286833870699948],
						[60.589672020253829, 3.1291444866349822],
						[60.589672020333815, 3.1291444884507262],
						[60.589894214203497, 3.1296000749435109],
						[60.590122010179236, 3.1303083614119838],
						[60.590122012285384, 3.1303083683498496],
						[60.590122018363815, 3.1303083837161116],
						[60.590122021283044, 3.1303083886755978],
						[60.590224806057364, 3.1305139582579526],
						[60.590258101419096, 3.1307944236025089],
						[60.590258101579053, 3.1307944272340693],
						[60.590258103845095, 3.1307944378035781],
						[60.590258104978126, 3.1307944430883161],
						[60.590397004739309, 3.1314250421736025],
						[60.590397007898424, 3.1314250525805978],
						[60.590397014216634, 3.1314250733946056],
						[60.590397018268852, 3.1314250836390154],
						[60.590569217409737, 3.131800080545859],
						[60.590794215332352, 3.1323389769448089],
						[60.590794216305405, 3.132338978598058],
						[60.59079421825151, 3.1323389819045611],
						[60.59079421922457, 3.132338983557811],
						[60.591019219143909, 3.1328250838532186],
						[60.59101921922386, 3.1328250856690492],
						[60.591019220196905, 3.1328250873223267],
						[60.591019221169965, 3.1328250889756197],
						[60.591233110676441, 3.1332556683346211],
						[60.591430400286015, 3.1341028230709873],
						[60.591430399717382, 3.1341694004958014],
						[60.591430403435808, 3.13416942361442],
						[60.591430412578944, 3.1341694677107808],
						[60.591430420682947, 3.1341694882009579],
						[60.591463710842994, 3.1342361707729678],
						[60.59167760639879, 3.1350833484048342],
						[60.591677609637522, 3.1350833606284856],
						[60.591677617741425, 3.1350833811190477],
						[60.591677621793366, 3.1350833913643474],
						[60.591910921091554, 3.1355361906624153],
						[60.592135916737384, 3.1359972812830539],
						[60.592358114207222, 3.1365528735390971],
						[60.592358116233122, 3.1365528786619503],
						[60.592358119232046, 3.1365528854382623],
						[60.592358121178059, 3.1365528887451801],
						[60.592535909234932, 3.1369111658255364],
						[60.592663703071779, 3.1375306356482446],
						[60.592708102376413, 3.1378250295417565],
						[60.59270810577469, 3.1378250453979777],
						[60.592708115170701, 3.1378250748071888],
						[60.592708121168435, 3.1378250883601755],
						[60.592808114246303, 3.1380250758053538],
						[60.59303590898007, 3.1387389618064283],
						[60.593035911899022, 3.1387389667671091],
						[60.59303591700354, 3.1387389804828265],
						[60.593035920002364, 3.138738987259468],
						[60.593252619595567, 3.1391861863855133],
						[60.593477613302966, 3.139652873759974],
						[60.593699807937725, 3.1403917588919317],
						[60.59369981093645, 3.1403917656688876],
						[60.593699817986739, 3.1403917826924226],
						[60.593699821958431, 3.1403917911230192],
						[60.593788708171999, 3.1405667632400953],
						[60.593833102944515, 3.1408361335212103],
						[60.593833103024373, 3.1408361353372203],
						[60.593833105129967, 3.1408361422765925],
						[60.593833105209811, 3.140836144092606],
						[60.593983105232219, 3.141480644358928],
						[60.593983109363535, 3.1414806564217796],
						[60.593983117626145, 3.1414806805475033],
						[60.59398312265057, 3.1414806924480252],
						[60.594149816805626, 3.1418000814968754],
						[60.594366512283464, 3.1423972677412286],
						[60.594366512363294, 3.1423972695572768],
						[60.594366512363294, 3.1423972695572768],
						[60.594366513256411, 3.1423972693949693],
						[60.594597012622025, 3.1430139692963261],
						[60.594597013674765, 3.1430139727661586],
						[60.594597016673362, 3.1430139795435461],
						[60.594597017726116, 3.1430139830133785],
						[60.594733110033268, 3.1433111652060952],
						[60.594860904270348, 3.1438444413683708],
						[60.594933104077299, 3.1442250367357438],
						[60.594933107395029, 3.1442250507776803],
						[60.594933115736957, 3.1442250767208986],
						[60.594933120761169, 3.1442250886222154],
						[60.59504421361266, 3.1444472755911352],
						[60.595263709032942, 3.1451389593864407],
						[60.595263710085618, 3.1451389628564339],
						[60.59526371211124, 3.1451389679802739],
						[60.595263713084144, 3.1451389696341585],
						[60.595494213419308, 3.1457528704493218],
						[60.595494213578867, 3.145752874081567],
						[60.595494217550232, 3.1457528825133001],
						[60.595494220468929, 3.1457528874750116],
						[60.595638709348833, 3.1460528654603164],
						[60.595752604223094, 3.1465889410547749],
						[60.595833103741214, 3.1469667413799804],
						[60.595833107872046, 3.1469667534442607],
						[60.595833117106579, 3.1469667792267373],
						[60.595833122050763, 3.1469667893126618],
						[60.595938714738153, 3.1471750776352625],
						[60.59618311004413, 3.1478806611505465],
						[60.596385907763334, 3.1485667572289167],
						[60.596385910841427, 3.1485667658233822],
						[60.596385916838095, 3.148566779379955],
						[60.59638591991618, 3.1485667879744064],
						[60.596497007046629, 3.1487944606965437],
						[60.596547002062316, 3.1491250302516169],
						[60.59654700319463, 3.1491250355380753],
						[60.596547005379549, 3.1491250442947791],
						[60.596547005618746, 3.1491250497433545],
						[60.59669420599996, 3.1497083491251923],
						[60.59669420810512, 3.1497083560657742],
						[60.59669421223569, 3.1497083681307179],
						[60.596694215313697, 3.1497083767253811],
						[60.596833114335858, 3.1500472737582514],
						[60.597052609193653, 3.1506250599789198],
						[60.597283100436066, 3.1515306254027653],
						[60.597283099857691, 3.1515389003087471],
						[60.597283101707234, 3.1515389219416043],
						[60.597283111658221, 3.1515389640727873],
						[60.597283118866542, 3.1515389847332069],
						[60.597302617743821, 3.1515806830929183],
						[60.597685911119861, 3.1524528667835003],
						[60.597727607282657, 3.152602850346804],
						[60.597919203926452, 3.1533667406232033],
						[60.59801920321496, 3.1539611337227065],
						[60.59801920321496, 3.1539611337227065],
						[60.598074802815766, 3.1542806339903593],
						[60.598074807105263, 3.1542806496890119],
						[60.598074816418119, 3.1542806772917293],
						[60.59807482233456, 3.1542806890337958],
						[60.598174816842253, 3.154477882396348],
						[60.598452610312471, 3.1551944621243346],
						[60.598622007157687, 3.1558278520953444],
						[60.598622010314948, 3.1558278625074458],
						[60.598622018336144, 3.1558278811913829],
						[60.598622022386543, 3.1558278914415197],
						[60.598766502988894, 3.1561083541375989],
						[60.598785900197051, 3.1564417122137249],
						[60.598785901408725, 3.1564417193171601],
						[60.598785903991349, 3.1564417371567131],
						[60.598785905282654, 3.1564417460764922],
						[60.598922005374995, 3.1570222466623252],
						[60.598922007559395, 3.1570222554202299],
						[60.598922015739682, 3.1570222777373598],
						[60.598922019710365, 3.157022286171395],
						[60.599072018545463, 3.1573333848850074],
						[60.599352610018151, 3.1579389660490329],
						[60.599516506103441, 3.1586083484623608],
						[60.599516509180916, 3.1586083570586387],
						[60.599516516388185, 3.158608377722083],
						[60.599516520438392, 3.1586083879728548],
						[60.599635901852352, 3.1588528519007242],
						[60.599649799997607, 3.1591222091793627],
						[60.599649801368344, 3.1591222199158935],
						[60.599649804030221, 3.1591222395725538],
						[60.599649806294089, 3.1591222501472034],
						[60.599816505639446, 3.1597667509737537],
						[60.599816507425714, 3.1597667506500255],
						[60.599816507584904, 3.15976675428286],
						[60.599816507664492, 3.1597667560992724],
						[60.599963707552675, 3.1602750555914403],
						[60.59996370965731, 3.1602750625334162],
						[60.599963714680129, 3.1602750744391286],
						[60.599963716784742, 3.1602750813811071],
						[60.60014421707529, 3.1606806801399019],
						[60.60014421804803, 3.1606806817944895],
						[60.60014421812761, 3.1606806836109391],
						[60.600144219020763, 3.1606806834490948],
						[60.600410919122673, 3.1612528848900303],
						[60.60041091920224, 3.16125288670648],
						[60.600410921068089, 3.1612528881992725],
						[60.600410921068089, 3.1612528881992725],
						[60.600583108982953, 3.1615972648691728],
						[60.600688702980477, 3.162161136250667],
						[60.600688704032741, 3.1621611397217957],
						[60.600688706137277, 3.1621611466640718],
						[60.600688707189548, 3.1621611501352014],
						[60.600780406737002, 3.1625111509788786],
						[60.600780409893765, 3.1625111613923504],
						[60.600780417993612, 3.1625111818957126],
						[60.600780422043528, 3.1625111921473876],
						[60.600860917617311, 3.1626667824163737],
						[60.601158114287983, 3.1634250721673203],
						[60.601305413406742, 3.1638056721591634],
						[60.601305415431661, 3.1638056772851733],
						[60.601305419401953, 3.1638056857206718],
						[60.60130542134732, 3.1638056890301587],
						[60.60157481402441, 3.1643389756716847],
						[60.601752608892312, 3.1649278588426486],
						[60.601752610996691, 3.1649278657853381],
						[60.601752618043989, 3.1649278828185823],
						[60.601752621041506, 3.1649278895995376],
						[60.601916511729037, 3.1652528715615564],
						[60.602105407291269, 3.1659750505339019],
						[60.602105407450289, 3.1659750541670206],
						[60.602105408581956, 3.1659750594550093],
						[60.602105409634135, 3.1659750629264196],
						[60.602169210222179, 3.1661694617029936],
						[60.602169213219653, 3.1661694684841541],
						[60.60216921832145, 3.1661694822081592],
						[60.602169221398462, 3.166169490805876],
						[60.602202615676482, 3.1662361798016927],
						[60.602508112076478, 3.1670833671304344],
						[60.602508114101255, 3.1670833722568292],
						[60.602508119123492, 3.1670833841645525],
						[60.602508121148283, 3.1670833892909473],
						[60.602649820342876, 3.1673639855256304],
						[60.602938716625147, 3.1680000813357783],
						[60.60309701620465, 3.1683694782175005],
						[60.603097017256751, 3.1683694816891155],
						[60.603097019202018, 3.1683694849990625],
						[60.603097020174651, 3.1683694866540346],
						[60.603358117233036, 3.1689139797080759],
						[60.603541513784158, 3.1693778735140832],
						[60.603541514836238, 3.1693778769857772],
						[60.603541518726729, 3.1693778836058972],
						[60.603541519858268, 3.169377888894227],
						[60.603760917104673, 3.1698278816103422],
						[60.603991514006616, 3.1704083747713785],
						[60.60399151587238, 3.1704083762648603],
						[60.603991518949108, 3.1704083848634763],
						[60.603991520001173, 3.1704083883352543],
						[60.604155417141641, 3.170744480660991],
						[60.604438713833147, 3.1714611743400343],
						[60.60443871488517, 3.171461177811878],
						[60.604438717802964, 3.1714611827773003],
						[60.604438719748153, 3.1714611860875705],
						[60.60453311634668, 3.1716583797239828],
						[60.604885914366747, 3.1725639725220107],
						[60.604885914605006, 3.1725639779722394],
						[60.604885918574773, 3.1725639864097563],
						[60.6048859214131, 3.1725639895585926],
						[60.604891512011072, 3.1725750728405715],
						[60.604899806640056, 3.1726056517392549],
						[60.604899808744058, 3.1726056586831182],
						[60.604899811820673, 3.1726056672821881],
						[60.604899813924668, 3.1726056742260691],
						[60.605249813795425, 3.1734889732642126],
						[60.605249815819974, 3.1734889783914504],
						[60.6052498188171, 3.1734889851739405],
						[60.605249820762261, 3.1734889884844302],
						[60.605335917757778, 3.1736611823349437],
						[60.605641515163398, 3.1744056762672295],
						[60.605641516135947, 3.1744056779225169],
						[60.605641518081086, 3.1744056812330861],
						[60.605641519133023, 3.1744056847051776],
						[60.605780417308942, 3.1747056795088642],
						[60.606033115111586, 3.1753194761941121],
						[60.606033116084163, 3.1753194778494414],
						[60.606033117056711, 3.1753194795047905],
						[60.6060331171361, 3.1753194813215999],
						[60.606230416026108, 3.1757667783490211],
						[60.606424814932993, 3.1762361743731837],
						[60.606677613765882, 3.1768778724501425],
						[60.606677614817784, 3.176877875922425],
						[60.606677616842205, 3.176877881050105],
						[60.606677617814732, 3.1768778827055351],
						[60.606802614903259, 3.1771500754448332],
						[60.607124811738998, 3.1780417680903441],
						[60.60712481376337, 3.1780417732181863],
						[60.60712481870533, 3.1780417833124366],
						[60.607124820729716, 3.1780417884402619],
						[60.60713590963919, 3.1780639676500742],
						[60.60714700529455, 3.178113943640259],
						[60.607147005373918, 3.1781139454571381],
						[60.607147005611949, 3.1781139509077843],
						[60.607147006584469, 3.1781139525632764],
						[60.607383107170051, 3.1789806524842952],
						[60.607383110246232, 3.1789806610846507],
						[60.607383116398651, 3.1789806782853516],
						[60.607383120368006, 3.1789806867243233],
						[60.607569213596534, 3.1793667761823627],
						[60.607747010207845, 3.1798944635340538],
						[60.607747011180329, 3.1798944651896135],
						[60.607747013204651, 3.1798944703176719],
						[60.607747014256496, 3.1798944737901476],
						[60.60801921367181, 3.1805889725787182],
						[60.608019214644301, 3.1805889742343116],
						[60.608019216668595, 3.1805889793624398],
						[60.608019217720404, 3.1805889828349887],
						[60.608119211162723, 3.1808111684347415],
						[60.608247004243786, 3.181275040527844],
						[60.608313702186315, 3.1817250284036716],
						[60.608313705579533, 3.1817250442723766],
						[60.608313714966215, 3.1817250737088982],
						[60.608313721038961, 3.1817250890936766],
						[60.608466512846157, 3.1820333727296912],
						[60.608638708444758, 3.1826389554744536],
						[60.608638708682612, 3.1826389609253942],
						[60.608638712731072, 3.1826389711821217],
						[60.608638714755266, 3.1826389763105007],
						[60.608913715023142, 3.183305675426062],
						[60.608913715102425, 3.1833056772430597],
						[60.608913715995605, 3.1833056770817851],
						[60.608913716074873, 3.1833056788987832],
						[60.609019209676781, 3.1835556645295049],
						[60.609147004268706, 3.1840306402142615],
						[60.609208102267928, 3.1844694282944461],
						[60.609208104688385, 3.1844694425081381],
						[60.609208115046926, 3.1844694736020664],
						[60.609208121119394, 3.184469488987792],
						[60.609360913325396, 3.1847750735950702],
						[60.609535907539815, 3.1853833546104724],
						[60.609535908670757, 3.1853833599003858],
						[60.609535911746569, 3.1853833685019315],
						[60.609535913770692, 3.1853833736306076],
						[60.60980541390861, 3.1860694739219095],
						[60.609805414881031, 3.1860694755777637],
						[60.60980541787756, 3.1860694823623739],
						[60.609805418929213, 3.1860694858353011],
						[60.60991371113878, 3.1863000690342886],
						[60.610038704431396, 3.1867750418542933],
						[60.610110903274496, 3.1872139325516868],
						[60.610110905694697, 3.1872139467659744],
						[60.610110914028567, 3.1872139727327942],
						[60.610110919128317, 3.1872139864635565],
						[60.610252613136822, 3.1875111736596358],
						[60.610444209096237, 3.1881278585469666],
						[60.610444211120267, 3.1881278636759585],
						[60.610444214275098, 3.1881278740951196],
						[60.610444216299122, 3.1881278792241297],
						[60.610702615196537, 3.1887250765019877],
						[60.610827612004719, 3.189044467827379],
						[60.611099803675444, 3.1898639421469732],
						[60.611110901077105, 3.1899611233750109],
						[60.611110905441748, 3.1899611409020521],
						[60.611110916929825, 3.1899611772899585],
						[60.611110923974053, 3.1899611943336796],
						[60.611149814249828, 3.190033376232762],
						[60.611388708451088, 3.1908750557583794],
						[60.611388710633356, 3.1908750645220398],
						[60.611388716784269, 3.1908750817271798],
						[60.611388720752942, 3.1908750901686722],
						[60.611594216418197, 3.1912833792795583],
						[60.61178311337386, 3.1917889704698075],
						[60.61178311337386, 3.1917889704698075],
						[60.611783114425386, 3.1917889739431211],
						[60.611783115397749, 3.1917889755992452],
						[60.612041514652972, 3.1924250756064709],
						[60.612155412894381, 3.1927056722432274],
						[60.612491500187751, 3.1936167352867217],
						[60.612491499646616, 3.1936222005869763],
						[60.612491502303548, 3.1936222202545435],
						[60.612491510296991, 3.1936222591066348],
						[60.612491515633529, 3.1936222782911545],
						[60.612499812887705, 3.1936417715869232],
						[60.612805411004921, 3.1945361652116464],
						[60.612805413028681, 3.1945361703414288],
						[60.612805418048588, 3.1945361822572758],
						[60.612805421044683, 3.1945361890433186],
						[60.612938716949465, 3.1948028797997177],
						[60.613185913381777, 3.1954528715770221],
						[60.613185915326405, 3.195452874889658],
						[60.613185918322486, 3.1954528816758603],
						[60.613185919373905, 3.1954528851494666],
						[60.613383116799397, 3.1958667816944017],
						[60.613585914906906, 3.1963667755542655],
						[60.613830414228268, 3.1969750740507341],
						[60.61383041520056, 3.1969750757071216],
						[60.613830415279665, 3.1969750775244554],
						[60.613830416172867, 3.1969750773635268],
						[60.613960915679115, 3.1972833775266785],
						[60.614280415185725, 3.1980556765512085],
						[60.61428041615801, 3.1980556782076701],
						[60.614280417130288, 3.1980556798641131],
						[60.614280417209386, 3.1980556816814616],
						[60.614344216378235, 3.1982000780613862],
						[60.614658112319916, 3.1989722682168416],
						[60.614702609345372, 3.1991139591166236],
						[60.614702613392602, 3.1991139693773807],
						[60.614702621407972, 3.1991139880815398],
						[60.614702626348404, 3.1991139981814216],
						[60.614727617955957, 3.199158384663479],
						[60.615058112807169, 3.2000306712798996],
						[60.615058114751683, 3.2000306745929881],
						[60.615058118798842, 3.2000306848539757],
						[60.615058120743356, 3.2000306881670819],
						[60.615172017617134, 3.2002611816594602],
						[60.615452614972604, 3.2009472767887712],
						[60.615452615944847, 3.2009472784453639],
						[60.615452618940651, 3.20094728523258],
						[60.615452619991949, 3.2009472887065926],
						[60.615619217655748, 3.2012889839040115],
						[60.615860915963438, 3.2018611776866752],
						[60.615860916042458, 3.201861179504125],
						[60.615860917986929, 3.2018611828174057],
						[60.615860918065977, 3.201861184634871],
						[60.616069217030095, 3.2023139795509685],
						[60.616260914720989, 3.2027778755618077],
						[60.616260914799994, 3.2027778773793014],
						[60.616516515006438, 3.2033917750932335],
						[60.616638714970748, 3.2036944746619458],
						[60.616638715049767, 3.2036944764794439],
						[60.616638715049767, 3.2036944764794439],
						[60.616960915091695, 3.2044694777601981],
						[60.616960915984912, 3.2044694775994547],
						[60.616960916957119, 3.2044694792562294],
						[60.616960917036124, 3.2044694810737475],
						[60.617022014537731, 3.2046083754646819],
						[60.617255410853119, 3.2052111659552902],
						[60.6173526090016, 3.2055250595964058],
						[60.617352611103961, 3.2055250665451487],
						[60.617352616122901, 3.2055250784644089],
						[60.61735261903948, 3.2055250834348921],
						[60.617408113918053, 3.2056444755086524],
						[60.617683110954815, 3.2064389646421976],
						[60.617683113057119, 3.2064389715910933],
						[60.617683117103802, 3.2064389818537302],
						[60.617683120020374, 3.2064389868243439],
						[60.617858116407369, 3.2068028784020997],
						[60.618060912381239, 3.2073556686797717],
						[60.618060913353411, 3.2073556703366912],
						[60.618060915376724, 3.2073556754681367],
						[60.618060915455665, 3.2073556772857286],
						[60.618305413475504, 3.2079444716411576],
						[60.618416511438134, 3.2082722648119053],
						[60.618416511438134, 3.2082722648119053],
						[60.618691503594086, 3.2090667448391588],
						[60.618705400835559, 3.2091861228330099],
						[60.618705405197773, 3.2091861403668505],
						[60.618705413764353, 3.2091861717993],
						[60.618705419834058, 3.2091861871942626],
						[60.618752611848208, 3.2092833700520416],
						[60.618969207119605, 3.2101028503146978],
						[60.618969210272795, 3.2101028607388322],
						[60.618969216421341, 3.210102877951742],
						[60.618969220388841, 3.2101028863976029],
						[60.619197019897484, 3.2105750873540626],
						[60.619410909813269, 3.2110194668940868],
						[60.619591498696757, 3.2118194170974035],
						[60.619585900193059, 3.211933290773382],
						[60.619585902111936, 3.2119333142428403],
						[60.61958591236008, 3.2119333636932912],
						[60.619585918745081, 3.2119333863599775],
						[60.619644208790028, 3.2120556632713377],
						[60.61980540393936, 3.2128500400323854],
						[60.619805407092358, 3.212850050457043],
						[60.619805413319426, 3.2128500694886624],
						[60.61980541639354, 3.2128500780956148],
						[60.620091514850529, 3.2135139755235476],
						[60.620188707395769, 3.213763955384092],
						[60.620258101099523, 3.2141111263114963],
						[60.620291500158125, 3.2146778116629786],
						[60.620291502812044, 3.2146778313371782],
						[60.62029151332154, 3.2146778660871966],
						[60.620291518655151, 3.2146778852799458],
						[60.620535913288066, 3.215197271945931],
						[60.620658109107517, 3.2155944574960968],
						[60.620849798752488, 3.2162361253472596],
						[60.620841499702351, 3.2165110934417651],
						[60.62084150162044, 3.2165111169124536],
						[60.620841510816049, 3.2165111628912215],
						[60.620841518014686, 3.2165111835815146],
						[60.620983111665382, 3.21681946979536],
						[60.621158108172146, 3.2174250563541107],
						[60.621158110195083, 3.2174250614866917],
						[60.621158115291827, 3.2174250752270188],
						[60.621158118286829, 3.2174250820169821],
						[60.621430412428694, 3.2180278697877269],
						[60.621524805681574, 3.2183417485944674],
						[60.621594199749403, 3.2186778201937427],
						[60.621594199843003, 3.2192556003477724],
						[60.621594201681837, 3.2192556220013704],
						[60.621594211612134, 3.2192556641860004],
						[60.621594219782409, 3.2192556865348845],
						[60.621874812579179, 3.2198417721669395],
						[60.62197760867052, 3.2201722591296984],
						[60.621977610772142, 3.2201722660804379],
						[60.621977616761868, 3.2201722796612127],
						[60.621977618863475, 3.2201722866119531],
						[60.622322018971118, 3.2208972854260849],
						[60.622322020021898, 3.2208972889015071],
						[60.622322022859173, 3.2208972920562986],
						[60.622322023909959, 3.2208972955317203],
						[60.622424809773264, 3.2210889686389295],
						[60.622496999560738, 3.2214417207690187],
						[60.622499799585569, 3.2220028016349449],
						[60.622499803289202, 3.2220028247866428],
						[60.622499815241433, 3.2220028721064113],
						[60.622499824304512, 3.222002894296335],
						[60.622769216967974, 3.2225000821361149],
						[60.622924811746039, 3.2229194692131626],
						[60.622924814740749, 3.222919476004019],
						[60.622924820730219, 3.2229194895857085],
						[60.622924823646187, 3.2229194945586497],
						[60.623219223394159, 3.2234667938702275],
						[60.623413713564574, 3.2238361758713507],
						[60.62366370829406, 3.2246722571259601],
						[60.623663712339379, 3.2246722673928305],
						[60.623663719379387, 3.2246722844508664],
						[60.623663724317943, 3.2246722945575077],
						[60.623705403614693, 3.224750057910085],
						[60.623713700611091, 3.224855616737313],
						[60.623713701897955, 3.2248556256669278],
						[60.623713705286242, 3.2248556415479661],
						[60.623713706573106, 3.2248556504775809],
						[60.623927606616995, 3.2256667519471454],
						[60.623927609769034, 3.2256667623744235],
						[60.623927618752816, 3.2256667827483936],
						[60.623927622876778, 3.2256667948334528],
						[60.624110920802259, 3.2260111882190667],
						[60.624377611034909, 3.2265833685906942],
						[60.624558106768056, 3.2272472520903812],
						[60.624558109919974, 3.2272472625180222],
						[60.624558116959619, 3.2272472795771101],
						[60.624558121004775, 3.2272472898445876],
						[60.624683121404111, 3.2275000895946753],
						[60.624683122376055, 3.2275000912525451],
						[60.624683124398608, 3.2275000963863132],
						[60.624683126263811, 3.2275000978840414],
						[60.625002625936482, 3.2280694973262696],
						[60.625002656251446, 3.2280695321987758],
						[60.625002730386548, 3.2280695812009776],
						[60.625002773392112, 3.2280695973088824],
						[60.625163700300661, 3.2280918741938396],
						[60.625163699660142, 3.228416700779642],
						[60.6251637011828, 3.2284167151639851],
						[60.625163706014632, 3.2284167436124167],
						[60.625163709402493, 3.2284167594945572],
						[60.625274804396703, 3.228777843143495],
						[60.625346999674484, 3.2293306126418693],
						[60.625344199992419, 3.2295499969574681],
						[60.62534422733534, 3.2295500671756896],
						[60.62534432169484, 3.2295501675219267],
						[60.625344388790083, 3.2295501994680458],
						[60.625449988842554, 3.2295557991753974],
						[60.625450051134138, 3.2295557825137728],
						[60.625450150762227, 3.2295557133554764],
						[60.625450189963871, 3.2295556623566783],
						[60.625522386598263, 3.2293389701617179],
						[60.625683483790063, 3.2289611782301577],
						[60.625683490963944, 3.228961156790036],
						[60.625683497929685, 3.2289611097365016],
						[60.625683499665378, 3.228961087439036],
						[60.623558512618132, 3.1962251999585232],
						[60.623561007054434, 3.1962251991636532],
						[60.623652647383629, 3.1963334292932224],
						[60.624008140615814, 3.1967612218851476],
						[60.624352637382387, 3.1972501151154638],
						[60.624352637382387, 3.1972501151154638],
						[60.624352638354679, 3.1972501167723846],
						[60.624455438213722, 3.1973918173295028],
						[60.624455440158329, 3.1973918206433622],
						[60.624455443968436, 3.1973918254531761],
						[60.624455445833924, 3.1973918269491168],
						[60.624624845931564, 3.1975973277742678],
						[60.624624854365862, 3.1975973354150686],
						[60.624624871313507, 3.1975973525146038],
						[60.624624879747792, 3.1975973601553869],
						[60.624905479581209, 3.1978084597376126],
						[60.624905512685579, 3.1978084757576992],
						[60.624905583778919, 3.1978084959243196],
						[60.624905620795623, 3.197808498413881],
						[60.625163921274179, 3.1977807984856619],
						[60.625163953271219, 3.1977807890543799],
						[60.625164010617432, 3.1977807622288581],
						[60.625164037753009, 3.197780744512674],
						[60.625355703734122, 3.1975973773998296],
						[60.625500058554344, 3.1975529908612681],
						[60.625500092914585, 3.1975529736747417],
						[60.625500151097974, 3.1975529247107652],
						[60.625500175079345, 3.1975528965692628],
						[60.625661269932174, 3.1972584065886753],
						[60.625744564375658, 3.1971390147870862],
						[60.625744578452618, 3.1971389865981132],
						[60.625744595650815, 3.1971389266982482],
						[60.625744599665246, 3.1971388948263533],
						[60.625722400396349, 3.1963472158107242],
						[60.625744596915951, 3.1962222342333337],
						[60.625744597650915, 3.196222230436331],
						[60.625744599120843, 3.1962222228423114],
						[60.625744598883493, 3.1962222173883337],
						[60.625805797186956, 3.1955306400681907],
						[60.625825189975181, 3.1954722636158159],
						[60.625825189896048, 3.1954722617978288],
						[60.625825192417388, 3.1954722576787011],
						[60.625825192259136, 3.1954722540427047],
						[60.625836272562829, 3.195433424095302],
						[60.626147312883987, 3.1952195644343782],
						[60.626147327672705, 3.1952195526063161],
						[60.626147355226315, 3.1952195238182761],
						[60.626147368070349, 3.1952195086763253],
						[60.626255737622607, 3.195052954291286],
						[60.626463989924801, 3.1949473789077856],
						[60.626464010886714, 3.1949473641339674],
						[60.626464048186286, 3.1949473317556172],
						[60.626464065417146, 3.1949473139900295],
						[60.626705760690136, 3.1945973190103167],
						[60.626727956242206, 3.1945695254845594],
						[60.626727957056275, 3.1945695235054403],
						[60.626727958763517, 3.1945695213652434],
						[60.626727959577565, 3.1945695193861234],
						[60.626761260080045, 3.1945251195689592],
						[60.626761260973261, 3.1945251194078739],
						[60.626761262601377, 3.194525115449613],
						[60.626761264387753, 3.1945251151274481],
						[60.626991863919557, 3.1941973142471802],
						[60.626991863919557, 3.1941973142471802],
						[60.62715576428306, 3.1939612143854905],
						[60.627155767618405, 3.193961208286908],
						[60.627155775261414, 3.1939611977466869],
						[60.62715577770355, 3.1939611918091915],
						[60.62724187579348, 3.193794494437169],
						[60.627341875283179, 3.1936139977117466],
						[60.627341876097219, 3.1936139957325675],
						[60.627341879432528, 3.1936139896339184],
						[60.627341880246561, 3.193613987654722],
						[60.627472379862191, 3.1933444875540529],
						[60.6274723831975, 3.1933444814553478],
						[60.627472387188547, 3.1933444697412807],
						[60.627472388737466, 3.1933444639647992],
						[60.62760578723455, 3.1929528722430325],
						[60.62766408303267, 3.1928194807293164],
						[60.627664086367972, 3.1928194746305043],
						[60.627664090279772, 3.1928194610982237],
						[60.627664091828677, 3.1928194553216844],
						[60.627697390845995, 3.1927028589871691],
						[60.627841889729211, 3.1922694632138047],
						[60.627841891357249, 3.1922694592552974],
						[60.627841892906126, 3.1922694534787168],
						[60.627841892826957, 3.1922694516606249],
						[60.627966893341139, 3.1917917502109656],
						[60.627966894234326, 3.191791750049799],
						[60.627966894155158, 3.1917917482317],
						[60.627997394060507, 3.1916722482732824],
						[60.627997394060507, 3.1916722482732824],
						[60.627997394874512, 3.1916722462940177],
						[60.627997394795344, 3.1916722444759165],
						[60.62805579487879, 3.1914194459463618],
						[60.62818909378079, 3.1908778485698783],
						[60.628189094594795, 3.1908778465905763],
						[60.628189095329617, 3.190877842793153],
						[60.628189096143608, 3.1908778408138532],
						[60.628283495593394, 3.1904250416615634],
						[60.62828349640737, 3.1904250396822493],
						[60.62828349640737, 3.1904250396822493],
						[60.628283496328187, 3.1904250378641201],
						[60.628372395665458, 3.1899667382018002],
						[60.628372397372672, 3.1899667360612423],
						[60.628372397293461, 3.1899667342431273],
						[60.62837239721425, 3.1899667324249985],
						[60.628405797239459, 3.1897611317174315],
						[60.628405798132654, 3.1897611315562058],
						[60.628405798053457, 3.1897611297380895],
						[60.628405797974246, 3.1897611279199731],
						[60.628505797807613, 3.1890528278022927],
						[60.628505799277129, 3.1890528202073103],
						[60.628505799615809, 3.1890528073192144],
						[60.628505800192137, 3.1890527998854759],
						[60.628505799588289, 3.1890472074409777],
						[60.628508499066342, 3.1890139160764095],
						[60.628577999145186, 3.188138915244036],
						[60.628577999879937, 3.1881389114465235],
						[60.628577999642246, 3.1881389059921466],
						[60.628578000376976, 3.1881389021946367],
						[60.628586300385173, 3.18737780114953],
						[60.628586300385173, 3.18737780114953],
						[60.628586300226701, 3.1873777975132933],
						[60.628586300147454, 3.1873777956951761],
						[60.628583499947105, 3.1872249966081867],
						[60.628583499788625, 3.1872249929719363],
						[60.628583499550906, 3.1872249875175833],
						[60.628583499471659, 3.187224985699467],
						[60.628514100141288, 3.1863166925943656],
						[60.628514100315975, 3.186311099993659],
						[60.628514099701341, 3.186294399359006],
						[60.62851410035676, 3.1862943937433541],
						[60.628514098750315, 3.1862943775416466],
						[60.628514097540112, 3.1862943704305278],
						[60.628380798314964, 3.1853971709251172],
						[60.628380797263254, 3.1853971674502635],
						[60.628380796053001, 3.1853971603391962],
						[60.628380795001284, 3.1853971568643416],
						[60.628280794979567, 3.1849555557200455],
						[60.628280793927836, 3.1849555522452015],
						[60.628280792876112, 3.1849555487703713],
						[60.628280792796836, 3.1849555469522803],
						[60.628261293041383, 3.1848860460857704],
						[60.628150192883801, 3.1844832463666672],
						[60.628150190859607, 3.1844832412351374],
						[60.628150186652675, 3.1844832273359271],
						[60.628150183735308, 3.1844832223657673],
						[60.628066883967634, 3.1842860226451504],
						[60.628066881864143, 3.184286015695569],
						[60.628066876029415, 3.1842860057553244],
						[60.628066873032751, 3.1842859989671175],
						[60.627644573137168, 3.1835637994658428],
						[60.627644569326591, 3.1835637946572328],
						[60.627644561626141, 3.1835637832219819],
						[60.627644558708731, 3.1835637782519837],
						[60.627616880174926, 3.183527706764842],
						[60.627339093362885, 3.1826499467348537],
						[60.627169595291186, 3.1819305539161777],
						[60.627169594318694, 3.18193055225959],
						[60.627169593266899, 3.1819305487849658],
						[60.627169593266899, 3.1819305487849658],
						[60.627116892992404, 3.1817360484261092],
						[60.627116891861284, 3.1817360431334794],
						[60.62711688878521, 3.1817360345276935],
						[60.627116886760923, 3.1817360293965176],
						[60.62691958887752, 3.1812138345934171],
						[60.62679739107319, 3.1808193410037853],
						[60.626797387997051, 3.1808193323981753],
						[60.626797379979074, 3.1808193136918828],
						[60.626797376009755, 3.1808193052477312],
						[60.626725177584881, 3.1806860081697916],
						[60.626347379998656, 3.1799027125069221],
						[60.626347377081132, 3.1799027075374564],
						[60.626347371166723, 3.1799026957805734],
						[60.626347367276693, 3.1799026891546212],
						[60.626277969198334, 3.1797970924560812],
						[60.626030769580453, 3.1793970948833543],
						[60.625827969832159, 3.1790665950707395],
						[60.625827967887098, 3.1790665917578633],
						[60.625827963997025, 3.1790665851321371],
						[60.625827962131332, 3.1790665836371916],
						[60.62580576877626, 3.1790359921158449],
						[60.625777974704427, 3.1789860035589403],
						[60.625777973731907, 3.1789860019025147],
						[60.625777971707521, 3.1789859967717113],
						[60.62577796984182, 3.1789859952767952],
						[60.625380774739654, 3.1783388037904801],
						[60.625247379594093, 3.1780693135333444],
						[60.624930781375532, 3.1774027141397649],
						[60.624930778378541, 3.1774027073528619],
						[60.624930770756983, 3.1774026977379179],
						[60.624930767759992, 3.1774026909510296],
						[60.624864067785524, 3.177299891915466],
						[60.62486405538062, 3.1772998758367801],
						[60.624864027077514, 3.1772998461434865],
						[60.624864011258673, 3.177299834346742],
						[60.624644559755879, 3.1771526668528698],
						[60.624569583225345, 3.176980519818438],
						[60.624569561094965, 3.176980487176936],
						[60.624569506592458, 3.1769804347397619],
						[60.624569474140927, 3.1769804131262127],
						[60.624486173906668, 3.1769470144603185],
						[60.624486115215753, 3.1769470104184787],
						[60.624486004701048, 3.1769470359047367],
						[60.624485952797919, 3.1769470636149855],
						[60.624419252702815, 3.1770192649307916],
						[60.624419252702815, 3.1770192649307916],
						[60.624419250916475, 3.1770192652538949],
						[60.624419250995864, 3.1770192670717439],
						[60.624302680342232, 3.1771498349746055],
						[60.624083219378164, 3.1772470171612412],
						[60.624083217591817, 3.177247017484329],
						[60.624083215884866, 3.1772470196252613],
						[60.624083214991686, 3.1772470197868072],
						[60.624036015209121, 3.1772692191911052],
						[60.624035993276763, 3.177269232318821],
						[60.624035953381579, 3.1772692670172318],
						[60.624035935339386, 3.1772692867701129],
						[60.623816535182314, 3.1775887869808122],
						[60.623816529247591, 3.1775887953828241],
						[60.623816519323228, 3.1775888154994196],
						[60.623816514361067, 3.1775888255577156],
						[60.623810923180052, 3.1776027040969383],
						[60.623585935779616, 3.1779220850610246],
						[60.62358593325883, 3.1779220891811994],
						[60.623585927403461, 3.1779220994008943],
						[60.623585924068863, 3.1779221055003957],
						[60.623549824074573, 3.1779888055883756],
						[60.623549820739981, 3.1779888116878627],
						[60.623549814229513, 3.1779888275224835],
						[60.623549810974275, 3.1779888354397929],
						[60.623524811285108, 3.1780610343883686],
						[60.623524809578122, 3.1780610365292081],
						[60.623524808764316, 3.1780610385085444],
						[60.623524808843676, 3.1780610403263458],
						[60.623374808893388, 3.1785443411088963],
						[60.623374808158921, 3.1785443449060096],
						[60.623374804983008, 3.178544354641017],
						[60.623374804248542, 3.1785443584381121],
						[60.623283108973219, 3.1789721391787924],
						[60.623213715988747, 3.1791332208421026],
						[60.623213716068086, 3.1791332226598956],
						[60.623213716068086, 3.1791332226598956],
						[60.62321371517492, 3.179133222821378],
						[60.623133114604052, 3.1793277236175488],
						[60.623133113790225, 3.1793277255968126],
						[60.623133113055744, 3.1793277293938775],
						[60.623133112241931, 3.1793277313731414],
						[60.623016512088462, 3.1796471295020692],
						[60.622927613327583, 3.179883229250557],
						[60.622822013982287, 3.1801582266389961],
						[60.622683114759063, 3.1804971247866454],
						[60.622683113945236, 3.1804971267658506],
						[60.622683113131366, 3.1804971287450772],
						[60.622683112317517, 3.1804971307242869],
						[60.622619211675854, 3.1806693305769231],
						[60.622619211675854, 3.1806693305769231],
						[60.622619210862005, 3.1806693325561235],
						[60.622619210941323, 3.1806693343738837],
						[60.622574811334452, 3.1807971347443074],
						[60.6225748088929, 3.180797140681888],
						[60.622574804982278, 3.1807971542133893],
						[60.622574804406405, 3.1807971616458732],
						[60.622558402039523, 3.1808790731676595],
						[60.621350200397849, 3.16222218660779],
						[60.62135019805455, 3.1622221742082464],
						[60.621350193367945, 3.1622221494091467],
						[60.621350190131473, 3.1622221371715482],
						[60.613225193846361, 3.137563850599606],
						[60.612611307784697, 3.1342389259690209],
						[60.61264738063084, 3.1341611834018699],
						[60.612647381523935, 3.1341611832392116],
						[60.612647382337087, 3.134161181259504],
						[60.612647383230204, 3.1341611810968435],
						[60.612750177387554, 3.1339306931331321],
						[60.612866869642239, 3.1337418052004633],
						[60.612866872161618, 3.1337418010783318],
						[60.612866874681004, 3.1337417969561994],
						[60.612866876387265, 3.1337417948137767],
						[60.612991861236644, 3.1335084211850694],
						[60.613261237984851, 3.133250144486678],
						[60.613261243183523, 3.1332501398763766],
						[60.613261252607806, 3.1332501290014139],
						[60.613261256913376, 3.1332501245537991],
						[60.613291667833231, 3.1332114631340136],
						[60.613310923938108, 3.1332472956192379],
						[60.613310942106409, 3.1332473197844619],
						[60.613310986241046, 3.1332473611994511],
						[60.613311011314273, 3.1332473786119035],
						[60.613316557439781, 3.1332501525168759],
						[60.613760924803778, 3.1340111973659863],
						[60.613844216567244, 3.1341667812926604],
						[60.614085905061913, 3.1348333498207333],
						[60.614124802093997, 3.1350806316841462],
						[60.614124809384876, 3.1350806541684366],
						[60.614124827539086, 3.1350806984864525],
						[60.614124840188595, 3.1350807199948809],
						[60.614208115986706, 3.1351917880279458],
						[60.614413705914878, 3.135994449678055],
						[60.614413710046762, 3.1359944617476723],
						[60.614413720096756, 3.1359944855616813],
						[60.614413726014853, 3.1359944973060601],
						[60.614658124259819, 3.1364306952629231],
						[60.614910901297677, 3.1369111510576588],
						[60.614924799577643, 3.137461105622001],
						[60.614924799577643, 3.137461105622001],
						[60.614935900355917, 3.1378250054051535],
						[60.614935904153882, 3.1378250303581319],
						[60.614935917921571, 3.1378250773088854],
						[60.614935927078136, 3.1378251012864191],
						[60.615105423846671, 3.1381139953452246],
						[60.615422001908456, 3.1387389513722375],
						[60.615444200415233, 3.1394333038889886],
						[60.615447000453976, 3.1396528036891103],
						[60.615447003278561, 3.1396528269880069],
						[60.61544701615253, 3.1396528741028371],
						[60.615447025228917, 3.1396528962640509],
						[60.615549822354986, 3.1398389903651487],
						[60.615897007213668, 3.1405694592701523],
						[60.615997001856186, 3.1412167311847434],
						[60.61599700606746, 3.1412167450729003],
						[60.615997015463023, 3.1412167745039965],
						[60.615997019674296, 3.1412167883921716],
						[60.616127598130312, 3.1414833426402984],
						[60.616119199779448, 3.1417277925018525],
						[60.616119200577934, 3.141727810674571],
						[60.616119205747367, 3.1417278463700997],
						[60.616119211011409, 3.1417278637304564],
						[60.616347011025013, 3.14239726383525],
						[60.616347012157632, 3.1423972691246633],
						[60.616347016129296, 3.1423972775612756],
						[60.616347018155039, 3.1423972826882163],
						[60.616447018360368, 3.1426194818648607],
						[60.616447018440212, 3.142619483682167],
						[60.616447019333322, 3.1426194835197183],
						[60.616447019413165, 3.1426194853370064],
						[60.616771999873237, 3.1433139435638577],
						[60.616772000198232, 3.1439806000066413],
						[60.616772000437685, 3.1439806054585979],
						[60.616772000836768, 3.1439806145451765],
						[60.616772001076214, 3.1439806199971168],
						[60.616797001365555, 3.1442250196095722],
						[60.616797004922866, 3.1442250391128459],
						[60.616797016423256, 3.1442250754900378],
						[60.616797022659938, 3.1442250945060835],
						[60.616894220735965, 3.1444083885891438],
						[60.61723311409812, 3.1451417745618317],
						[60.617338711153216, 3.145452863152296],
						[60.617338713258654, 3.1454528700969546],
						[60.617338717309913, 3.1454528803515567],
						[60.61733871933555, 3.1454528854788668],
						[60.617624800060568, 3.1460556457925919],
						[60.61763039993312, 3.146647202861125],
						[60.617630400252246, 3.1466472101306207],
						[60.617630401863401, 3.1466472263246348],
						[60.617630403075665, 3.1466472334317639],
						[60.617685903317124, 3.1469722338312085],
						[60.617685906714499, 3.1469722497005894],
						[60.617685917001928, 3.1469722789726182],
						[60.617685923078675, 3.1469722943549869],
						[60.617785922276994, 3.1471611903937808],
						[60.618135908835498, 3.1478861644327054],
						[60.618235904086241, 3.1483917389977782],
						[60.618235907323921, 3.1483917512327531],
						[60.618235915665309, 3.1483917771955245],
						[60.618235920768988, 3.1483917909233226],
						[60.618441501394408, 3.1488000493677144],
						[60.618449799770353, 3.1492417042988712],
						[60.618449801301459, 3.1492417186760018],
						[60.618449806149911, 3.1492417471057172],
						[60.618449810440161, 3.1492417628134448],
						[60.618602608763169, 3.149713957461274],
						[60.618683107703269, 3.1499972549529911],
						[60.618683110781333, 3.149997263553423],
						[60.618683117910329, 3.1499972824094682],
						[60.618683121881517, 3.149997290847641],
						[60.619008101277423, 3.1506306497318426],
						[60.619022000220774, 3.1513278039817378],
						[60.61902200069909, 3.1513278148865305],
						[60.619022004335115, 3.1513278362094641],
						[60.619022005706569, 3.1513278469520296],
						[60.619074806244591, 3.1515444470944631],
						[60.619074807297174, 3.1515444505671719],
						[60.619074808588891, 3.1515444594923019],
						[60.619074809641447, 3.1515444629650147],
						[60.619130410287895, 3.1517111621160723],
						[60.619130413365845, 3.1517111707167924],
						[60.619130419362342, 3.1517111842832861],
						[60.619130422360591, 3.1517111910665228],
						[60.619519201265753, 3.1524611511658502],
						[60.6195359004126, 3.1532917046364171],
						[60.619535900811073, 3.1532917137239074],
						[60.619535903394294, 3.1532917315745692],
						[60.619535903713071, 3.1532917388445787],
						[60.619552604300466, 3.1533750380713315],
						[60.619552605592069, 3.1533750469966542],
						[60.619552609802142, 3.1533750608879907],
						[60.619552611907174, 3.1533750678336596],
						[60.619574811783458, 3.1534361671660265],
						[60.619574813888477, 3.1534361741117061],
						[60.619574818911957, 3.1534361860233724],
						[60.619574821910142, 3.1534361928068759],
						[60.620013701260838, 3.1542889501927456],
						[60.620024800252864, 3.1546556064225793],
						[60.620024833072648, 3.1546556774083339],
						[60.62002493901624, 3.1546557735910157],
						[60.620025013112887, 3.1546558004433431],
						[60.620197212582163, 3.1546445993675589],
						[60.620197234017347, 3.1546445954761455],
						[60.620197275835245, 3.1546445842203807],
						[60.620197296217952, 3.1546445768560303],
						[60.62047229606975, 3.154494575891897],
						[60.620472307441453, 3.1544945683312893],
						[60.62047232839857, 3.1544945535343842],
						[60.62047233790431, 3.1544945444805377],
						[60.620541828638203, 3.1544279532143933],
						[60.620725117987448, 3.1542945627878205],
						[60.620725123107206, 3.1542945563621809],
						[60.620725135371998, 3.1542945486392835],
						[60.620725139598612, 3.1542945423758035],
						[60.62085842798507, 3.1541640546956122],
						[60.620922313962936, 3.1541195645397391],
						[60.620922322655176, 3.1541195574653744],
						[60.620922339960053, 3.1541195414990701],
						[60.620922347759162, 3.1541195345868829],
						[60.621158448181163, 3.1538584330702153],
						[60.621158449153967, 3.1538584347256391],
						[60.621158449074287, 3.1538584329080468],
						[60.621372349374823, 3.1536195327929328],
						[60.621372352787965, 3.1536195285090125],
						[60.621372358800805, 3.1536195219209398],
						[60.621372363027376, 3.1536195156572435],
						[60.621441862682957, 3.1535223157784809],
						[60.621441866909535, 3.1535223095147544],
						[60.621441876096441, 3.1535222931898752],
						[60.621441880243324, 3.1535222851085254],
						[60.621508480212022, 3.1533833854676954],
						[60.621508481105145, 3.1533833853055109],
						[60.621508481838887, 3.1533833815080876],
						[60.621508482652317, 3.1533833795282948],
						[60.621655783055118, 3.1530472799904024],
						[60.621655784761664, 3.1530472778483958],
						[60.621655787935708, 3.1530472681115094],
						[60.621655788669436, 3.1530472643140612],
						[60.621822389392548, 3.1525639644229528],
						[60.621822390205978, 3.1525639624431148],
						[60.621822391753113, 3.1525639566658215],
						[60.621822393379972, 3.1525639527061351],
						[60.621839092682329, 3.1525028530430022],
						[60.6218390949632, 3.1525028434682216],
						[60.621839097818352, 3.1525028264607293],
						[60.621839099206085, 3.1525028170481608],
						[60.62184189947282, 3.1524722197967661],
						[60.621914099087945, 3.1517417202300821],
						[60.621914098928485, 3.1517417165948318],
						[60.621914099662192, 3.1517417127973251],
						[60.621914100395877, 3.1517417089998374],
						[60.621922399836215, 3.1515583094522528],
						[60.621922399597054, 3.1515583039993751],
						[60.621922400091606, 3.1515582947489804],
						[60.621922399852437, 3.1515582892960841],
						[60.621880800163296, 3.1507555900653279],
						[60.621880800083566, 3.1507555882477045],
						[60.621880800003836, 3.1507555864300851],
						[60.621880799030968, 3.1507555847747244],
						[60.621872399438523, 3.1506443847396057],
						[60.621872399199326, 3.1506443792867445],
						[60.621872396934705, 3.1506443687055241],
						[60.621872395722619, 3.1506443615972901],
						[60.621822395855169, 3.1503860623690021],
						[60.621822395775446, 3.1503860605513809],
						[60.621822395775446, 3.1503860605513809],
						[60.621689097176578, 3.1497305663389485],
						[60.621636297700263, 3.1493416735296758],
						[60.621636296567893, 3.1493416682391628],
						[60.621636296169157, 3.1493416591511276],
						[60.621636295116531, 3.1493416556782186],
						[60.621514096017712, 3.1488138601904558],
						[60.621477998582883, 3.1486055784142382],
						[60.621453000202237, 3.1480527907391971],
						[60.621453000202237, 3.1480527907391971],
						[60.621453000122465, 3.1480527889216079],
						[60.621444600286303, 3.1478999950549085],
						[60.621453000324955, 3.1471361020577491],
						[60.621453000245182, 3.1471361002401612],
						[60.621453000245182, 3.1471361002401612],
						[60.621453000250753, 3.1469889007993239],
						[60.621453000170966, 3.1469888989817418],
						[60.621453000091194, 3.1469888971641367],
						[60.621444600216883, 3.1462082984454964],
						[60.621444600057281, 3.146208294810334],
						[60.621444599738119, 3.1462082875399875],
						[60.621444598605621, 3.1462082822496207],
						[60.621433499222135, 3.1460749837734592],
						[60.621433499142334, 3.146074981955878],
						[60.621433498982746, 3.1460749783206974],
						[60.621433498982746, 3.1460749783206974],
						[60.621380798610886, 3.1456027792446406],
						[60.621339102293717, 3.1451611146588943],
						[60.621352992410571, 3.1451056578596894],
						[60.62138078438911, 3.1450278790109114],
						[60.621575175712721, 3.1446417958747497],
						[60.621802970233553, 3.1442501051734166],
						[60.621819555141229, 3.1442251271079464],
						[60.621830740979021, 3.1442140419485938],
						[60.621830751297296, 3.144214030911292],
						[60.62183076909492, 3.144214005688815],
						[60.621830776654051, 3.1442139933212379],
						[60.622055775828485, 3.1437889945098845],
						[60.62210577623442, 3.1436972959532548],
						[60.622105776154598, 3.143697294135642],
						[60.622105776154598, 3.143697294135642],
						[60.6221057770477, 3.1436972939731849],
						[60.622280777000825, 3.143366794141321],
						[60.622280776921002, 3.1433667923237008],
						[60.622280778627392, 3.1433667901811275],
						[60.622280778547562, 3.1433667883635072],
						[60.622289079074761, 3.1433500880789307],
						[60.622289083954428, 3.1433500761983835],
						[60.622289092820736, 3.1433500525997471],
						[60.622289095834375, 3.1433500392264935],
						[60.622291896359854, 3.1433361386200178],
						[60.62229189758726, 3.1433361255717172],
						[60.622291898909488, 3.1433360941847019],
						[60.622291899163947, 3.1433360794812342],
						[60.622280799436901, 3.1432277814994487],
						[60.622208499079136, 3.1424221825806251],
						[60.622208497786815, 3.1424221736550346],
						[60.622208495282003, 3.1424221576214677],
						[60.622208494069497, 3.1424221505134926],
						[60.622127995582566, 3.1421082572772816],
						[60.622019597844385, 3.14150827384442],
						[60.621958500942569, 3.1408528054342173],
						[60.621994598160434, 3.1405944277035904],
						[60.621994598893792, 3.1405944239058603],
						[60.621994599467428, 3.140594416472922],
						[60.621994600200807, 3.1405944126751706],
						[60.622016900224303, 3.1402250116535755],
						[60.6220168999847, 3.1402250062007857],
						[60.622016900318684, 3.1402249933150506],
						[60.622016899999203, 3.1402249860446516],
						[60.622002999647471, 3.1400221867768101],
						[60.622002998754368, 3.140022186939369],
						[60.622002998674496, 3.1400221851217727],
						[60.621977998869404, 3.139680584789478],
						[60.621977997576892, 3.1396805758640722],
						[60.621977994912008, 3.1396805561956649],
						[60.621977992726357, 3.1396805474328313],
						[60.621839092797252, 3.1391777473234077],
						[60.621839091744363, 3.1391777438508091],
						[60.621839088665595, 3.1391777352506258],
						[60.621839087692614, 3.1391777335956208],
						[60.621691905812213, 3.1387666808956056],
						[60.621727986104467, 3.1385418022534672],
						[60.621839031900954, 3.1384445498505276],
						[60.621839045631383, 3.1384445345258389],
						[60.621839071146248, 3.1384445005664863],
						[60.621839082037546, 3.1384444820944251],
						[60.621977951345407, 3.1381418502198035],
						[60.622288986316171, 3.1379945800837952],
						[60.62228900385869, 3.1379945695610347],
						[60.622289037810866, 3.1379945432253296],
						[60.622289054220566, 3.1379945274123742],
						[60.622330740743941, 3.1379445446729077],
						[60.622441825242127, 3.1378557567538756],
						[60.62244182865475, 3.1378557524681665],
						[60.622441837346095, 3.1378557453891012],
						[60.62244183986558, 3.1378557412660197],
						[60.622639038980466, 3.1376612445443195],
						[60.622739036636617, 3.1375668443762934],
						[60.622739047847432, 3.1375668331739361],
						[60.622739068323042, 3.1375668074592094],
						[60.622739076694721, 3.1375667931094906],
						[60.622894570360643, 3.1372695070263306],
						[60.623133461164691, 3.1369473191553783],
						[60.623133476775074, 3.1369472851649527],
						[60.623133496225606, 3.1369472156632532],
						[60.623133499172681, 3.1369471803146456],
						[60.623119599072432, 3.1368110798564084],
						[60.623119599072432, 3.1368110798564084],
						[60.623119598912595, 3.1368110762211163],
						[60.623058501290757, 3.1362888935487079],
						[60.623069600281532, 3.1360333097668858],
						[60.623069600121681, 3.136033306131603],
						[60.623069599881894, 3.136033300678664],
						[60.623069599801958, 3.1360332988610238],
						[60.623066900336148, 3.1357888982814579],
						[60.623066900256227, 3.1357888964638145],
						[60.623061299537412, 3.1353721962616525],
						[60.62306130035055, 3.1353721942813237],
						[60.62306130019067, 3.1353721906460468],
						[60.623061300030805, 3.1353721870107525],
						[60.62304460091228, 3.1351167051909243],
						[60.623072397713088, 3.1348917272212726],
						[60.623172398036694, 3.1342389297194897],
						[60.623172398609967, 3.1342389222862002],
						[60.62317239975652, 3.1342389074195878],
						[60.623172400329821, 3.1342388999862782],
						[60.623172400208311, 3.1342055995534985],
						[60.623172398435713, 3.1342055797221402],
						[60.623172390345005, 3.1342055390554293],
						[60.62317238321382, 3.1342055202004309],
						[60.623050182946756, 3.1339249201762507],
						[60.622775183966617, 3.1332888213003289],
						[60.622775171316746, 3.1332887997870418],
						[60.622775140738398, 3.1332887595545613],
						[60.622775122889863, 3.1332887426530029],
						[60.622750159036897, 3.1332692699823421],
						[60.622741881024872, 3.1332527131048922],
						[60.622350186809484, 3.1323749274363162],
						[60.62229189014856, 3.1321999374904106],
						[60.622044591926972, 3.1314582435360556],
						[60.621844594170852, 3.1306860505386629],
						[60.621844591091545, 3.1306860419393199],
						[60.621844585986061, 3.1306860282129434],
						[60.621844583879849, 3.1306860212683398],
						[60.621783483835806, 3.1305443211997535],
						[60.621783478890301, 3.1305443111085296],
						[60.621783468106223, 3.130544291088897],
						[60.621783462267643, 3.130544281160486],
						[60.621552961765232, 3.1302276826719351],
						[60.621552959819034, 3.1302276793624948],
						[60.621552955113536, 3.1302276747240159],
						[60.621552953167324, 3.1302276714145956],
						[60.621394553131722, 3.1300387700932726],
						[60.621394511342302, 3.1300387410700279],
						[60.621394418086467, 3.1300387086054045],
						[60.621394367433155, 3.1300387031836685],
						[60.621177782596575, 3.1300747997670646],
						[60.621166713920282, 3.1300748006484733],
						[60.620950027920458, 3.1300442012692491],
						[60.620950011684748, 3.1300442005651354],
						[60.620949981079541, 3.1300442006487379],
						[60.620949965896955, 3.1300442034167997],
						[60.620724966391919, 3.130083103046772],
						[60.620724937159871, 3.1300831138724527],
						[60.620724884294333, 3.1300831399993729],
						[60.620724858954702, 3.1300831574437304],
						[60.620499975125654, 3.130305344609523],
						[60.620330709633024, 3.1301942328995316],
						[60.620330705007525, 3.1301942300786738],
						[60.620330695756579, 3.1301942244369876],
						[60.620330691131102, 3.1301942216161298],
						[60.620047346734417, 3.1300498507447618],
						[60.619822377003175, 3.1296249067707014],
						[60.619822376030065, 3.1296249051160969],
						[60.619822374083853, 3.1296249018068818],
						[60.619822373110743, 3.1296249001522605],
						[60.619602980224435, 3.129244313089385],
						[60.6193890931605, 3.1287082449903525],
						[60.619264097299002, 3.1280138648756206],
						[60.619225200870353, 3.1277943878566448],
						[60.61926409695549, 3.1270972544832856],
						[60.619377977079097, 3.1268806925229273],
						[60.619494556906488, 3.1266612310635016],
						[60.619602923931645, 3.1265751570501426],
						[60.619602927423941, 3.1265751545810727],
						[60.619602931729254, 3.1265751501316754],
						[60.619602934328469, 3.1265751478255139],
						[60.619808434163154, 3.1263890482087895],
						[60.619808436762355, 3.1263890459025863],
						[60.619808441960771, 3.1263890412902078],
						[60.619808445453053, 3.126389038821102],
						[60.620052905966752, 3.1261334789618003],
						[60.620125051763353, 3.1261140936100911],
						[60.620125103414686, 3.1261140603703907],
						[60.62012517693757, 3.1261139718431035],
						[60.620125198809149, 3.1261139165554961],
						[60.620136299126862, 3.1259722157397607],
						[60.620136299699695, 3.1259722083070551],
						[60.620136299952257, 3.1259721936045417],
						[60.620136299631987, 3.1259721863347711],
						[60.620075199909401, 3.1251027875659125],
						[60.620072400129906, 3.1250582867674055],
						[60.620072398916513, 3.1250582796606206],
						[60.620072396409654, 3.1250582636295756],
						[60.620072395116175, 3.1250582547053356],
						[60.620055794528938, 3.1249860542934016],
						[60.620055794368781, 3.124986050658531],
						[60.62005579128914, 3.1249860420602205],
						[60.620055790155803, 3.1249860367708777],
						[60.619777994684817, 3.1241416536061641],
						[60.619736297531787, 3.1238777684956678],
						[60.619736292105252, 3.1238777475016177],
						[60.61973627686686, 3.1238777081458764],
						[60.619736267135075, 3.1238776916016033],
						[60.619605779003564, 3.1236777072859945],
						[60.61942800817944, 3.1232277814377798],
						[60.619480795979328, 3.1229667402025134],
						[60.619480795899229, 3.1229667383851165],
						[60.619480795899229, 3.1229667383851165],
						[60.619480795819108, 3.1229667365677223],
						[60.619602993917688, 3.1223222511010889],
						[60.619605790332493, 3.1223139636521293],
						[60.619605793263894, 3.122313948460758],
						[60.619605799046603, 3.1223139162606297],
						[60.619605800111721, 3.1223138995779385],
						[60.619605799624381, 3.12231113147066],
						[60.619611290219147, 3.1222944634768468],
						[60.619611290871902, 3.1222944578616083],
						[60.619611293963537, 3.1222944463050513],
						[60.619611295589479, 3.1222944423441663],
						[60.619744595801173, 3.1216750421693304],
						[60.619744597839407, 3.1216750271408915],
						[60.619744599156554, 3.1216749957557708],
						[60.619744599328506, 3.121674979236043],
						[60.61971689890035, 3.1214027797205901],
						[60.619716898820229, 3.1214027779031679],
						[60.619716897766892, 3.1214027744314192],
						[60.619716897686764, 3.121402772614013],
						[60.619611297853623, 3.1206499742344689],
						[60.619591900978797, 3.120488895288958],
						[60.619594599072073, 3.1204556165571766],
						[60.619611299120528, 3.1202750191843482],
						[60.619672397962368, 3.1197000284414536],
						[60.619694596749248, 3.1195750358377636],
						[60.619694596669099, 3.1195750340203672],
						[60.61969459658895, 3.1195750322029521],
						[60.61969459658895, 3.1195750322029521],
						[60.619786296806552, 3.1190139325613662],
						[60.619786297619463, 3.119013930580842],
						[60.61978629835221, 3.1190139267829169],
						[60.619786299084971, 3.1190139229849723],
						[60.619827998612998, 3.1186611228885623],
						[60.619827999185411, 3.1186611154558035],
						[60.619828000169903, 3.1186610969554853],
						[60.619827999849242, 3.1186610896858618],
						[60.619805800011747, 3.1182721890142635],
						[60.619805798878176, 3.1182721837252112],
						[60.619805799450575, 3.1182721762924519],
						[60.619805798397159, 3.1182721728207978],
						[60.619780798777988, 3.1180943759692563],
						[60.619744598598047, 3.1177471791531941],
						[60.619744598357514, 3.1177471737009967],
						[60.619744597063558, 3.1177471647771666],
						[60.619744595929959, 3.117747159488145],
						[60.619705796127604, 3.1175582596697664],
						[60.619614096175731, 3.1170971607641191],
						[60.619614096175731, 3.1170971607641191],
						[60.619614096015368, 3.1170971571293493],
						[60.61961409512228, 3.1170971572925286],
						[60.619555794596735, 3.1168332570255175],
						[60.619555794516543, 3.1168332552081366],
						[60.619555795409617, 3.1168332550449525],
						[60.619555795329418, 3.1168332532275742],
						[60.619505794587823, 3.1166193558473658],
						[60.619433496196052, 3.1162860621385304],
						[60.61936959709697, 3.115919366762276],
						[60.619316898249579, 3.1156138709060008],
						[60.619266898587689, 3.1152110767584671],
						[60.619244599042268, 3.1150055783831845],
						[60.619244598962041, 3.1150055765658298],
						[60.619244597988775, 3.1150055749117005],
						[60.619244597908562, 3.115005573094344],
						[60.619227998863657, 3.1148832784808156],
						[60.619166898917285, 3.1142027826832015],
						[60.619158498692514, 3.114108282041391],
						[60.619158498532059, 3.1141082784066954],
						[60.619158498211156, 3.1141082711372809],
						[60.619158497157628, 3.1141082676658396],
						[60.619155799804126, 3.1140916845133901],
						[60.619155800056355, 3.1140693998028905],
						[60.6191557998959, 3.114069396168194],
						[60.619155800468072, 3.1140693887355222],
						[60.619155799334315, 3.1140693834467155],
						[60.619086299356724, 3.1131777839297747],
						[60.619086299276475, 3.1131777821124178],
						[60.619086299196248, 3.1131777802950733],
						[60.619086299116006, 3.1131777784777324],
						[60.619064099139131, 3.1129721816219593],
						[60.619008499261149, 3.1122638854383786],
						[60.618980800264282, 3.1118805897270176],
						[60.618958499999209, 3.1113499954619135],
						[60.618958499742931, 3.1109194086585119],
						[60.61896409916676, 3.1108500193096043],
						[60.619014096727071, 3.1104361401610618],
						[60.619072391586023, 3.1102389572780247],
						[60.619072396312163, 3.1102389216051183],
						[60.619072389609073, 3.1102388513825083],
						[60.619072380859009, 3.1102388163427208],
						[60.618275181320207, 3.1085221150474571],
						[60.618275181320207, 3.1085221150474571],
						[60.618275181239909, 3.1085221132301748],
						[60.618275180346842, 3.1085221133935792],
						[60.618072381437884, 3.1080999145141179],
						[60.617880781849472, 3.1076916184799575],
						[60.61782518266552, 3.107563819254751],
						[60.61782518266552, 3.107563819254751],
						[60.617825181692147, 3.1075638176009126],
						[60.617825181611842, 3.1075638157836556],
						[60.617639081625889, 3.1071610153849702],
						[60.617639080732829, 3.1071610155484097],
						[60.617639080652502, 3.1071610137311678],
						[60.617639079759456, 3.1071610138946055],
						[60.617455784431286, 3.1067777221721196],
						[60.617377986924687, 3.1065693299229551],
						[60.617377985870981, 3.106569326451956],
						[60.617377982870508, 3.1065693196733957],
						[60.617377981816801, 3.1065693162023993],
						[60.617211281876848, 3.1062027173940656],
						[60.617058492176874, 3.1058638391870454],
						[60.616927996018696, 3.1051971615254774],
						[60.616927993830878, 3.105197152766459],
						[60.616927988562146, 3.1051971354119141],
						[60.616927986454648, 3.1051971284701128],
						[60.616875185690986, 3.1050610280666349],
						[60.616875185610645, 3.1050610262494431],
						[60.616875184556889, 3.1050610227785334],
						[60.616875183663844, 3.1050610229420244],
						[60.616828001219368, 3.1049499623499623],
						[60.616833500446887, 3.1047500056107005],
						[60.616833498669948, 3.1047499857851033],
						[60.616833491624256, 3.1047499486050842],
						[60.616833488141602, 3.1047499309236644],
						[60.61505060518985, 3.0999304509434569],
						[60.61509738554129, 3.0998111726911932],
						[60.615097387327374, 3.0998111723639457],
						[60.615250186701488, 3.0994139729431511],
						[60.6152501897107, 3.099413959569004],
						[60.615250195889999, 3.0994139364549071],
						[60.615250198979638, 3.0994139248978607],
						[60.615269599301563, 3.0992528242737607],
						[60.615350195545787, 3.0986111423718121],
						[60.615372390055974, 3.0985417611543724],
						[60.615372395100771, 3.0985417327524187],
						[60.615372397152967, 3.09854167742145],
						[60.615372393186888, 3.0985416488390296],
						[60.615361295324654, 3.0984999550769601],
						[60.615350196788377, 3.0984388642399101],
						[60.615350155170084, 3.0984387985939716],
						[60.615350037329627, 3.09843871943945],
						[60.615349961026986, 3.0984387041137946],
						[60.615322292589802, 3.098444177762345],
						[60.614902967006842, 3.0978109898018991],
						[60.614902966033348, 3.0978109881485216],
						[60.614902966033348, 3.0978109881485216],
						[60.614902965059869, 3.0978109864951424],
						[60.614747405747814, 3.0975832476446805],
						[60.614772399133386, 3.0973194198182084],
						[60.614819598654975, 3.0968444195358598],
						[60.614819599226173, 3.0968444121039456],
						[60.614819600368591, 3.0968443972401305],
						[60.614819600046786, 3.0968443899719169],
						[60.6148113001056, 3.0966721945097748],
						[60.614808500464719, 3.0964805976778922],
						[60.614800199970155, 3.0959666965082993],
						[60.614800199889686, 3.0959666946912541],
						[60.614800200460849, 3.0959666872593283],
						[60.614800199406879, 3.095966683788967],
						[60.614783498671741, 3.0957582845007341],
						[60.614783496080946, 3.0957582666577381],
						[60.614783487327188, 3.0957582316266801],
						[60.614783481164253, 3.0957582144386318],
						[60.61445578321915, 3.0950555174316841],
						[60.614364101695244, 3.0948416636835536],
						[60.614372398877435, 3.0946750220479839],
						[60.614455680709796, 3.094181324867336],
						[60.614727615340271, 3.0948444762977223],
						[60.614727617367784, 3.0948444814213181],
						[60.61472762231589, 3.0948444915047792],
						[60.614727624262919, 3.0948444948113365],
						[60.614902624293904, 3.0951667952032009],
						[60.614902723800277, 3.0951668465660389],
						[60.614902908329512, 3.0951668255527078],
						[60.614902993271912, 3.095166751359486],
						[60.614958492784666, 3.0949583507596321],
						[60.614958495873935, 3.0949583392022828],
						[60.614958499212364, 3.0949583129447604],
						[60.614958500435044, 3.0949582998978804],
						[60.61495849974844, 3.0948443998050474],
						[60.614958500239062, 3.0948443905560215],
						[60.614958497728622, 3.0948443745300747],
						[60.614958497326207, 3.0948443654448075],
						[60.614902997058842, 3.0945277645293099],
						[60.614902991869251, 3.0945277489947087],
						[60.614902981409585, 3.0945277161084732],
						[60.614902972567378, 3.0945276994118984],
						[60.614558473127104, 3.0939304992953645],
						[60.61455846257924, 3.0939304847437352],
						[60.614558438804401, 3.0939304561318135],
						[60.61455842573843, 3.0939304457055639],
						[60.614455774308034, 3.0938470821205115],
						[60.614205792376261, 3.0930166424093457],
						[60.614205787347537, 3.0930166305093443],
						[60.614205777370586, 3.0930166085263275],
						[60.614205772422352, 3.0930165984433313],
						[60.61400577354253, 3.0926805014746774],
						[60.613697376727295, 3.0920999066990462],
						[60.613697375834256, 3.0920999068628716],
						[60.613697375753738, 3.0920999050459037],
						[60.613697375673219, 3.0920999032289154],
						[60.613561276302235, 3.0918499030752629],
						[60.613561275409211, 3.0918499032390971],
						[60.613197393324569, 3.0911860367419832],
						[60.613108498734086, 3.0905360774593889],
						[60.613083498610074, 3.0902721815786687],
						[60.613083498207352, 3.0902721724940077],
						[60.613083493749215, 3.0902721531632467],
						[60.61308349237293, 3.0902721424255239],
						[60.613047395389643, 3.0901499530924705],
						[60.612902997064346, 3.0893582638978438],
						[60.612902993821365, 3.0893582516711073],
						[60.612902987415957, 3.0893582290345303],
						[60.612902982386899, 3.0893582171355924],
						[60.612664081600556, 3.0888305179649715],
						[60.612664070891036, 3.0888304997820444],
						[60.612664045980445, 3.0888304658887407],
						[60.612664030805803, 3.0888304485253668],
						[60.612219622977484, 3.0884443294172153],
						[60.612366893597304, 3.0878361467587565],
						[60.612366897256678, 3.0878361277702462],
						[60.61236689905607, 3.0878360871430455],
						[60.612366897276701, 3.0878360673212524],
						[60.612316897041907, 3.0875305681787104],
						[60.612316894691766, 3.0875305557884265],
						[60.612316888205484, 3.087530531335775],
						[60.612316884149898, 3.087530521090271],
						[60.61221409035786, 3.0872916343820362],
						[60.612039093789079, 3.086616649798906],
						[60.612039092815458, 3.0866166481460158],
						[60.612039092734861, 3.0866166463291558],
						[60.612039091761233, 3.0866166446762682],
						[60.61178629195858, 3.085736044559185],
						[60.61178628887648, 3.0857360359669332],
						[60.611786282712302, 3.0857360187824234],
						[60.611786278737178, 3.0857360103541596],
						[60.611769593919128, 3.0857027399153001],
						[60.611766898172718, 3.0856832736763864],
						[60.611766898011503, 3.0856832700427068],
						[60.611766896957278, 3.0856832665730192],
						[60.611766896876667, 3.0856832647561623],
						[60.611602996680794, 3.0847888639635035],
						[60.611602995384693, 3.0847888550433598],
						[60.611602989139755, 3.0847888360423088],
						[60.611602986057605, 3.0847888274501982],
						[60.611319585786745, 3.0840638278922152],
						[60.611319578809955, 3.0840638126891271],
						[60.611319563231561, 3.0840637862446587],
						[60.611319553656337, 3.0840637733504983],
						[60.611164073011253, 3.0838748942461174],
						[60.610869583784996, 3.0831888226845074],
						[60.610775196659922, 3.0829610529285665],
						[60.610755798568853, 3.082722183309929],
						[60.610755798246267, 3.0827221760428172],
						[60.610755795815059, 3.0827221618367258],
						[60.61075579459947, 3.0827221547336978],
						[60.610594594812483, 3.0820471543565624],
						[60.610594594731836, 3.0820471525397979],
						[60.610425194755805, 3.0813388525513723],
						[60.610425192808449, 3.0813388492460745],
						[60.610425189645426, 3.0813388388378447],
						[60.610425189403436, 3.0813388333875902],
						[60.610352999195094, 3.0811332610486741],
						[60.610347399699094, 3.0809777931412228],
						[60.610347399215058, 3.0809777822407391],
						[60.610347395648652, 3.0809777627488457],
						[60.610347394271628, 3.0809777520124531],
						[60.610158493783722, 3.0802193522717394],
						[60.610158491674959, 3.0802193453330777],
						[60.61015848940486, 3.0802193347609315],
						[60.610158487376808, 3.0802193296389806],
						[60.609975186933625, 3.0797388288840035],
						[60.609975182958152, 3.0797388204569631],
						[60.609975175980864, 3.0797388052554742],
						[60.609975171112389, 3.0797387969925762],
						[60.609714080023245, 3.0793055102091715],
						[60.609527986331656, 3.0788360252410887],
						[60.609527985438646, 3.078836025405256],
						[60.609527985357936, 3.0788360235885639],
						[60.60934458613432, 3.0783888288637375],
						[60.609077987856132, 3.0776610303897698],
						[60.609077986882411, 3.0776610287373027],
						[60.609077984854252, 3.0776610236157049],
						[60.609077983880518, 3.0776610219632223],
						[60.60900019216303, 3.0774777409985097],
						[60.60894459577726, 3.0772027607036732],
						[60.608944594642082, 3.0772027554179209],
						[60.608944592291024, 3.0772027430297415],
						[60.608944590182126, 3.0772027360915235],
						[60.608733489616228, 3.0765638365689587],
						[60.608733484586068, 3.0765638246735838],
						[60.608733472739786, 3.0765638012112713],
						[60.608733466816652, 3.0765637894800975],
						[60.608630766800992, 3.0764081904698299],
						[60.608630764853515, 3.0764081871650193],
						[60.608630762906039, 3.0764081838601891],
						[60.608630760958569, 3.0764081805553731],
						[60.608405760893838, 3.076102681598949],
						[60.608405750424865, 3.0761026688725344],
						[60.60840572688867, 3.0761026457290517],
						[60.608405713821426, 3.0761026353119543],
						[60.608180768415913, 3.075947073738504],
						[60.608077988757366, 3.0756471350437278],
						[60.608077985674647, 3.0756471264534428],
						[60.608077977723184, 3.0756471096013662],
						[60.608077973747463, 3.0756471011753237],
						[60.607733474303082, 3.0750416000982774],
						[60.607733469595793, 3.0750415954698229],
						[60.607733462779535, 3.0750415839035652],
						[60.607733458884503, 3.0750415772942707],
						[60.607497359625604, 3.0747331796688528],
						[60.607283466757607, 3.0744442899603284],
						[60.606919578256317, 3.073819308876574],
						[60.606839084159731, 3.0736388221225308],
						[60.606541889672002, 3.0729055368692157],
						[60.606389093362957, 3.0723360480380548],
						[60.606389091253831, 3.0723360411007099],
						[60.606389086061782, 3.0723360255738652],
						[60.606389083059639, 3.0723360188008457],
						[60.606308495637478, 3.0721555482540124],
						[60.606291899427973, 3.0719916795218678],
						[60.606291894967299, 3.0719916601978055],
						[60.606291882393151, 3.0719916203905244],
						[60.606291874360487, 3.0719916017237918],
						[60.605941873828975, 3.0713749016848939],
						[60.605941833329261, 3.0713748615236676],
						[60.605941734788701, 3.0713748118995912],
						[60.605941679346103, 3.0713748001271926],
						[60.605786188871477, 3.0713914890494309],
						[60.605536257380741, 3.07107487600272],
						[60.605536252673332, 3.0710748713751648],
						[60.60553624512535, 3.0710748636077621],
						[60.605536241310922, 3.0710748588158365],
						[60.605491849365258, 3.0710304666295878],
						[60.605430754911524, 3.0709554745005399],
						[60.605430753937704, 3.0709554728484618],
						[60.605430751097103, 3.0709554697086445],
						[60.605430750042501, 3.0709554662401266],
						[60.605041859749967, 3.0705165796639609],
						[60.60481686894996, 3.0701609924717643],
						[60.604816866109346, 3.0701609893320723],
						[60.604816861240295, 3.0701609810719508],
						[60.604816859292661, 3.0701609777678858],
						[60.604594568571557, 3.0698720910772388],
						[60.604264076697064, 3.0692471063144624],
						[60.604264075723258, 3.0692471046624941],
						[60.604264072801776, 3.0692470997065713],
						[60.604264071747139, 3.0692470962382377],
						[60.604144577207556, 3.0690471059644322],
						[60.603805781044031, 3.0683332133934309],
						[60.603805779096348, 3.068333210089579],
						[60.603805776174873, 3.0683332051338237],
						[60.603805774227197, 3.0683332018299709],
						[60.603700174151456, 3.0681471017077144],
						[60.603700172203816, 3.0681470984038981],
						[60.603700169282298, 3.0681470934481605],
						[60.603700167334623, 3.0681470901443482],
						[60.603327966891229, 3.0675804902376882],
						[60.60324736769055, 3.067458191875843],
						[60.603222367548476, 3.0674192918629748],
						[60.603222362760114, 3.0674192854199549],
						[60.603222353183369, 3.0674192725339311],
						[60.603222348314169, 3.0674192642746072],
						[60.602797355193076, 3.0669526737628767],
						[60.60246686378121, 3.0665054842353476],
						[60.60235296678038, 3.0663331903359263],
						[60.602352966699506, 3.066333188519681],
						[60.602352965806517, 3.0663331886841427],
						[60.602352965806517, 3.0663331886841427],
						[60.602047366031016, 3.0658804871949878],
						[60.60204736513802, 3.0658804873594607],
						[60.602047363109435, 3.0658804822397236],
						[60.602047361323436, 3.0658804825686734],
						[60.601902960915893, 3.0656831821551536],
						[60.601902960835019, 3.0656831803389331],
						[60.601833464112495, 3.0655887854717099],
						[60.6014557672164, 3.0650165890462322],
						[60.601455753012772, 3.0650165733516714],
						[60.60145572192647, 3.06501654245604],
						[60.601455705124721, 3.0650165290711588],
						[60.60105010628719, 3.0647665302507567],
						[60.601005706159953, 3.0647387305131542],
						[60.601005694146671, 3.0647387235707835],
						[60.60100566760287, 3.0647387138118716],
						[60.601005654777474, 3.0647387088501841],
						[60.600783363174273, 3.064674810712591],
						[60.600555672153604, 3.0645887123343005],
						[60.600505679884343, 3.0645692162628659],
						[60.60010840789711, 3.0643748308276573],
						[60.599861229198382, 3.0641748469082635],
						[60.599658436079963, 3.0639942546201708],
						[60.599419542232397, 3.0637609603582519],
						[60.599214045295248, 3.0635442626581115],
						[60.598991847623815, 3.0633109654158468],
						[60.59876405078063, 3.0630498691631343],
						[60.598764045099074, 3.0630498628861567],
						[60.598764033816899, 3.0630498521482528],
						[60.598764029109255, 3.0630498475227945],
						[60.598519532860415, 3.0628442512819856],
						[60.598311236796249, 3.0626470545530959],
						[60.598311219994294, 3.0626470411706714],
						[60.598311181194248, 3.0626470190250901],
						[60.598311160008272, 3.0626470082813797],
						[60.598197292324151, 3.0626109194142028],
						[60.597861235187438, 3.0623637513340882],
						[60.597466869030896, 3.0619304880933784],
						[60.597416881792967, 3.0618221153524821],
						[60.597416868239208, 3.0618220940487761],
						[60.597416835854688, 3.061822054244685],
						[60.59741681710485, 3.0618220375602365],
						[60.5973362378556, 3.06176375232064],
						[60.596966854223311, 3.0613165726077529],
						[60.596966848541683, 3.0613165663314184],
						[60.596966837259373, 3.06131655559469],
						[60.596966830684771, 3.0613165494829198],
						[60.596619531005281, 3.0610137492918716],
						[60.59661952816446, 3.0610137461537734],
						[60.596619520696862, 3.061013740206699],
						[60.596619516963052, 3.0610137372331692],
						[60.596519548291923, 3.0609415608193311],
						[60.596339070051954, 3.0606554956387337],
						[60.596069584231522, 3.0602082165277147],
						[60.596036292253636, 3.0601027420148941],
						[60.595986292867515, 3.0599277453515339],
						[60.595783495163815, 3.0591888541094492],
						[60.595622395977593, 3.058363862572941],
						[60.595622394841719, 3.0583638572900327],
						[60.595622393624872, 3.0583638501912978],
						[60.595622393462932, 3.0583638465595926],
						[60.595597392612035, 3.0582749455733294],
						[60.595597391719039, 3.0582749457379741],
						[60.595597391638073, 3.0582749439221311],
						[60.595555793218168, 3.0581360475029133],
						[60.595361293684824, 3.0573610518077827],
						[60.595172394040155, 3.0566027537783631],
						[60.595136294961662, 3.056449954748051],
						[60.595136292851748, 3.0564499478141864],
						[60.595136288712894, 3.0564499357622381],
						[60.595136286683967, 3.0564499306441841],
						[60.595014094834958, 3.0561221513474313],
						[60.594933499127421, 3.0555360794918651],
						[60.594891901153517, 3.0549556003479137],
						[60.594916898853512, 3.0546222155569969],
						[60.594952998709608, 3.0541694221016504],
						[60.595016897674675, 3.0537111284078806],
						[60.595016898974464, 3.0537111171835711],
						[60.595016899950132, 3.0537110986960729],
						[60.595016900437962, 3.0537110894523223],
						[60.594997400302077, 3.0533499898462604],
						[60.594989099781557, 3.0531777909214548],
						[60.594989040041369, 3.0531777030847778],
						[60.594988875742324, 3.0531776327112579],
						[60.594988771021377, 3.0531776465428173],
						[60.594935971241803, 3.0532220473055198],
						[60.594935970348828, 3.0532220474702951],
						[60.594935967750942, 3.053222049780429],
						[60.594935966938984, 3.0532220517609945],
						[60.594727836565085, 3.0534079990492691],
						[60.594311265838741, 3.0527970873013457],
						[60.594277965833051, 3.0527470881318983],
						[60.594277962911008, 3.0527470831790033],
						[60.594277955280994, 3.0527470736027644],
						[60.594277949598997, 3.052747067328458],
						[60.594219550304466, 3.0526804674737975],
						[60.594219548356442, 3.0526804641718606],
						[60.594219541862515, 3.0526804598781254],
						[60.594219539021516, 3.052680456740974],
						[60.593825144382471, 3.0522998602005842],
						[60.593455778289474, 3.0518831990081017],
						[60.59338079603593, 3.0516471580550841],
						[60.593303001197022, 3.050969395072471],
						[60.593311300228059, 3.0508333118678213],
						[60.593353000158309, 3.0501111122024831],
						[60.593352999671865, 3.0501111013083335],
						[60.593352998698975, 3.0501110795200344],
						[60.593352998293604, 3.050111070441571],
						[60.593344602755963, 3.0500556026453465],
						[60.593352997190941, 3.0500056338241608],
						[60.593380796640375, 3.0498444341463418],
						[60.593380797452269, 3.0498444321657945],
						[60.593380798183077, 3.0498444283695409],
						[60.593380798101997, 3.0498444265538529],
						[60.59345579833974, 3.0492944274286891],
						[60.593455798258638, 3.0492944256129988],
						[60.593475198246665, 3.0491444268632231],
						[60.593475198734197, 3.049144417619865],
						[60.593475199790326, 3.0491444009488604],
						[60.593475200277858, 3.0491443917054997],
						[60.59346690143245, 3.0489639117294787],
						[60.593533498040507, 3.0485389301547117],
						[60.593533498040507, 3.0485389301547117],
						[60.593533497959427, 3.0485389283390165],
						[60.593577998015192, 3.0482306285014977],
						[60.593577998990121, 3.0482306100147021],
						[60.593577997368115, 3.0482305737007307],
						[60.593577994771174, 3.0482305558735683],
						[60.593380795378209, 3.0473555559680872],
						[60.593380775572257, 3.0473555156901946],
						[60.593380723945494, 3.0473554483375347],
						[60.593380690338783, 3.0473554215925858],
						[60.593364011409811, 3.0473470311864319],
						[60.593327929224429, 3.0473165476315462],
						[60.593327928250361, 3.0473165459807938],
						[60.593327926383317, 3.0473165444949717],
						[60.593327924597396, 3.0473165448248287],
						[60.593044557684536, 3.0470887704986929],
						[60.592933477120646, 3.0468777075394038],
						[60.592933472169094, 3.0468776974701339],
						[60.592933460642357, 3.0468776812928184],
						[60.592933454879002, 3.0468776732041509],
						[60.592789085607535, 3.0466999124641356],
						[60.592727996976187, 3.0464027644438181],
						[60.592666900787833, 3.0460305887717891],
						[60.592675199610369, 3.0458778110669469],
						[60.592675199529225, 3.0458778092512961],
						[60.592675200341056, 3.0458778072706956],
						[60.592675200259926, 3.0458778054550577],
						[60.592686300146354, 3.0454917054134203],
						[60.592686299821814, 3.0454916981508569],
						[60.592686299091596, 3.045491681810113],
						[60.592686297874067, 3.0454916747125274],
						[60.592644598264073, 3.0451638741476539],
						[60.592644595910166, 3.0451638617681724],
						[60.592644589335286, 3.0451638355235291],
						[60.592644585195465, 3.0451638234740122],
						[60.592483492259973, 3.0447749407389884],
						[60.592441899002651, 3.0445777762499708],
						[60.592439099623618, 3.044486096319901],
						[60.592430799946293, 3.0437749981453242],
						[60.59243079856639, 3.0437749874166271],
						[60.592430796861834, 3.0437749694254519],
						[60.592430795563068, 3.0437749605123736],
						[60.592408495996828, 3.0436638606600579],
						[60.592408495996828, 3.0436638606600579],
						[60.592408495915677, 3.0436638588444462],
						[60.592408495915677, 3.0436638588444462],
						[60.592350196404624, 3.0433916584221747],
						[60.592297395837051, 3.0431332595586436],
						[60.592297393807613, 3.0431332544418992],
						[60.592297391372362, 3.0431332402470859],
						[60.592297389342939, 3.0431332351303375],
						[60.59216689519117, 3.042752751025068],
						[60.592133497563104, 3.0425527742722553],
						[60.592072398871792, 3.0419110815498227],
						[60.592072398790613, 3.0419110797342168],
						[60.592072398709433, 3.0419110779186256],
						[60.592072398709433, 3.0419110779186256],
						[60.592064098621805, 3.0418388775014327],
						[60.592064097322911, 3.0418388685885409],
						[60.592064093994566, 3.0418388545590229],
						[60.592064092695679, 3.0418388456461307],
						[60.592036293359243, 3.0417388459900225],
						[60.592036292385089, 3.0417388443395104],
						[60.592036292303895, 3.0417388425239222],
						[60.592036291410949, 3.0417388426889955],
						[60.591883491095913, 3.0412332416885639],
						[60.591883485981576, 3.0412332279893648],
						[60.591883474048224, 3.0412332027366933],
						[60.591883466255041, 3.0412331895327309],
						[60.591677969911551, 3.0409248936288238],
						[60.591627972756037, 3.0408388008625487],
						[60.591627938742121, 3.0408387650480067],
						[60.591627859106168, 3.0408387157014416],
						[60.591627813402908, 3.0408387003538784],
						[60.591586246949596, 3.0408359090565904],
						[60.591311300408634, 3.0400138675173398],
						[60.59130849998877, 3.0396693978702642],
						[60.591305800295601, 3.0394388985991934],
						[60.591305800214393, 3.0394388967836523],
						[60.591300199847907, 3.0390999972263621],
						[60.591300199766692, 3.039099995410826],
						[60.591300199604241, 3.0390999917797528],
						[60.591300199604241, 3.0390999917797528],
						[60.591286299659401, 3.0388028025611082],
						[60.591308499333131, 3.0385278155861695],
						[60.591308499333131, 3.0385278155861695],
						[60.591308500144862, 3.0385278136054761],
						[60.591308500144862, 3.0385278136054761],
						[60.591330799851669, 3.0381889136416045],
						[60.591330799770425, 3.0381889118260714],
						[60.591358498199497, 3.0377389314819037],
						[60.591361293894366, 3.0377278482943084],
						[60.591361294543582, 3.0377278426825218],
						[60.591361296734988, 3.0377278312937506],
						[60.591361298358379, 3.0377278273323047],
						[60.591422397028168, 3.0372750316251471],
						[60.591469595760358, 3.0370306373203313],
						[60.59159189320377, 3.0363917552336437],
						[60.59159738610758, 3.0363778735669702],
						[60.591597390733931, 3.0363778562357191],
						[60.591597398038203, 3.0363778182725842],
						[60.591597399904437, 3.0363777996214498],
						[60.591597400005973, 3.0363639219514704],
						[60.591686758005565, 3.0359653810575788],
						[60.591705675976293, 3.0359557864458386],
						[60.591750056709309, 3.0359418920574557],
						[60.591900038372032, 3.0359001973095041],
						[60.591975021938261, 3.0358918999190356],
						[60.591975035332446, 3.0358918974405218],
						[60.591975060172373, 3.0358918891828326],
						[60.591975071780674, 3.0358918870347851],
						[60.592097245356648, 3.0358445972814261],
						[60.592200016080952, 3.0358362981138733],
						[60.59220003937881, 3.0358362956332785],
						[60.59220008370098, 3.035836280109105],
						[60.592200105780826, 3.0358362705314201],
						[60.59228897370788, 3.0357807894670699],
						[60.592422237290108, 3.035755797579148],
						[60.592422258558265, 3.035755789982165],
						[60.592422300932014, 3.0357557711570409],
						[60.592422321063403, 3.0357557582785337],
						[60.592480685735012, 3.0357112869076155],
						[60.592647242248731, 3.0356751944126166],
						[60.592647259133436, 3.0356751894573613],
						[60.592647290954361, 3.0356751762461256],
						[60.592647306864819, 3.0356751696405193],
						[60.59267787482996, 3.0356557903270929],
						[60.592872236577932, 3.0356195969323077],
						[60.592872239256771, 3.0356195964365615],
						[60.592872244614441, 3.0356195954450764],
						[60.592872247211986, 3.0356195931337218],
						[60.59288333040822, 3.0356168976912428],
						[60.593097205698584, 3.0356030001746039],
						[60.593102795017245, 3.0356030001344041],
						[60.593322190127999, 3.0356141003187034],
						[60.593322191913899, 3.035614099988202],
						[60.593322197352833, 3.0356141008123334],
						[60.593322200031665, 3.0356141003165837],
						[60.593330584861889, 3.0356140991826748],
						[60.593547156057312, 3.0356473956391574],
						[60.593574941864738, 3.0356557914940034],
						[60.593574947303665, 3.0356557923181495],
						[60.593574957288595, 3.0356557941316882],
						[60.593574960941652, 3.0356557952863352],
						[60.593772144613752, 3.0356945932033752],
						[60.593830529389713, 3.0357168875275913],
						[60.593830534828655, 3.0357168883517667],
						[60.593830546762021, 3.0357168934661654],
						[60.593830554068134, 3.0357168957755034],
						[60.593994339417357, 3.035755791614394],
						[60.594088823549498, 3.0357945843207159],
						[60.594088834589918, 3.0357945896004503],
						[60.594088859105788, 3.035794594217097],
						[60.594088870064937, 3.0357945976811314],
						[60.594219353189068, 3.0358140946281487],
						[60.594344336596471, 3.035855789336551],
						[60.594344352101608, 3.035855793790085],
						[60.594344383842284, 3.035855798900494],
						[60.594344400077837, 3.035855799557384],
						[60.594444373981347, 3.0358557995191497],
						[60.594588847724012, 3.0358945925144294],
						[60.594588871346922, 3.0358945972964544],
						[60.59458891729345, 3.0358945979471832],
						[60.594588939698347, 3.0358945956315986],
						[60.594669400684687, 3.0358780043937554],
						[60.594822161424759, 3.0359084957203857],
						[60.594822193895844, 3.0359084970341761],
						[60.594822258350419, 3.0359084887674244],
						[60.594822289440948, 3.0359084793521047],
						[60.594894452934454, 3.0358723971685717],
						[60.595025012821047, 3.0358641002789999],
						[60.595025050162199, 3.0358640897067772],
						[60.595025118024985, 3.0358640570089697],
						[60.595025149358307, 3.0358640329023943],
						[60.595119541860853, 3.0357584426477215],
						[60.595172333246524, 3.0357112492841369],
						[60.595172347857677, 3.0357112337644687],
						[60.595172373183118, 3.0357111961232115],
						[60.595172384059957, 3.0357111776331132],
						[60.595219581052767, 3.0356000868454966],
						[60.595283475818135, 3.0354778955371873],
						[60.5953418747565, 3.0353722973540109],
						[60.595341876379841, 3.0353722933919478],
						[60.595341878896114, 3.0353722892646302],
						[60.595341880519449, 3.0353722853025671],
						[60.595397371387925, 3.0352557074015776],
						[60.595486256047472, 3.035144524272201],
						[60.595486256940404, 3.0351445241069244],
						[60.595486256940404, 3.0351445241069244],
						[60.595486256859125, 3.0351445222911648],
						[60.59553623182898, 3.0350807561890734],
						[60.595566796332108, 3.0350640754868308],
						[60.595566804206044, 3.0350640703677523],
						[60.595566819060963, 3.0350640602948991],
						[60.595566826041939, 3.0350640553411248],
						[60.595700099618334, 3.0349557768130828],
						[60.595791764319735, 3.0349223888103123],
						[60.595888953308375, 3.0348918923415371],
						[60.596016697662044, 3.0348613054061406],
						[60.596111049400207, 3.034886293762586],
						[60.596111064824107, 3.0348862964000514],
						[60.596111097376472, 3.0348862995286008],
						[60.596111112719086, 3.034886300350256],
						[60.596241633994715, 3.0348780041651153],
						[60.596397073232168, 3.0350057547159812],
						[60.59639708622106, 3.0350057632968337],
						[60.59639711211755, 3.0350057786427009],
						[60.596397125918116, 3.0350057852424217],
						[60.596466559295315, 3.0350334588663874],
						[60.596527625417892, 3.0351444954915494],
						[60.596527630288996, 3.035144503744228],
						[60.59652764003124, 3.0351445202495833],
						[60.596527645714026, 3.035144526521139],
						[60.596685935742272, 3.0353362149658589],
						[60.596844227595135, 3.0356029015130614],
						[60.596844231410742, 3.0356029062994723],
						[60.596844238230275, 3.0356029178534216],
						[60.596844242045883, 3.035602922639848],
						[60.596910942245152, 3.0356890220195547],
						[60.597038738196488, 3.0358557173118372],
						[60.597135935166051, 3.0359973125111757],
						[60.597135936140255, 3.0359973141617642],
						[60.597135938088677, 3.0359973174629604],
						[60.597135938088677, 3.0359973174629604],
						[60.597180437665216, 3.0360584177099716],
						[60.597180438639434, 3.0360584193605651],
						[60.59718044139953, 3.0360584206806305],
						[60.597180440587863, 3.0360584226617662],
						[60.597363741132128, 3.0362973216211553],
						[60.597363744054768, 3.0362973265729951],
						[60.597363750630436, 3.0362973326796556],
						[60.597363754446022, 3.0362973374662383],
						[60.597569253970356, 3.036516837051439],
						[60.597569254944574, 3.0365168387020622],
						[60.597569257785928, 3.0365168418380621],
						[60.597569258678867, 3.0365168416728086],
						[60.597588759412325, 3.036536241948514],
						[60.597602656848693, 3.036550139163956],
						[60.597813756029637, 3.0367695393518437],
						[60.597813762605298, 3.0367695454586947],
						[60.597813777542491, 3.0367695573418878],
						[60.597813785011112, 3.0367695632834875],
						[60.597999874278209, 3.0369001557538602],
						[60.598033164765191, 3.0369307476956839],
						[60.598033174100948, 3.0369307551227767],
						[60.598033194558326, 3.0369307696464545],
						[60.598033205679975, 3.0369307767430529],
						[60.598110992590357, 3.0369723703550857],
						[60.598258181088092, 3.0370807604163432],
						[60.598258193102687, 3.0370807673477951],
						[60.598258217131843, 3.0370807812107032],
						[60.598258229958113, 3.037080786160991],
						[60.598324921174687, 3.0371057834824051],
						[60.598483212164091, 3.0371834801763948],
						[60.598483224990368, 3.037183485126735],
						[60.598483252428785, 3.0371834946969702],
						[60.598483265173812, 3.0371834978313763],
						[60.598594365398441, 3.0372029964931828],
						[60.59859437359745, 3.0372029986378823],
						[60.598594390807214, 3.0372030009461071],
						[60.598594399736676, 3.0372029992936818],
						[60.598597174630179, 3.037203000295897],
						[60.598708250766968, 3.0372307942342851],
						[60.598708265216644, 3.0372307952223085],
						[60.598708292411295, 3.0372307993447829],
						[60.598708305968003, 3.0372308004980351],
						[60.598808284978361, 3.0372279997456375],
						[60.598838858880313, 3.0372334963271208],
						[60.598933254331463, 3.0372557954654913],
						[60.598933266995225, 3.0372557967840015],
						[60.598933293215694, 3.0372557992557949],
						[60.598933305879441, 3.0372558005743069],
						[60.599022168430913, 3.0372530007773682],
						[60.599083228218241, 3.0372751868038206],
						[60.599158221674138, 3.0373057841509352],
						[60.599208195681179, 3.037327972918074],
						[60.599338755874648, 3.0374307403898282],
						[60.599363734353737, 3.0374640110994715],
						[60.599380424615447, 3.0374917954470653],
						[60.599577610188781, 3.0378861668101997],
						[60.599585903687547, 3.0379250376373346],
						[60.599605402755053, 3.0380389326398327],
						[60.599644200919393, 3.0382694241800583],
						[60.599649799877817, 3.0383444074221435],
						[60.599649799894806, 3.0384388999596812],
						[60.599649800057279, 3.0384389035916879],
						[60.599649800219751, 3.038438907223715],
						[60.599649800382231, 3.0384389108557217],
						[60.599669200152327, 3.0388000112434006],
						[60.599669200233571, 3.0388000130594053],
						[60.599669201451448, 3.038800020158241],
						[60.599669200720975, 3.0388000239554573],
						[60.599688701343993, 3.0389694236869969],
						[60.599688702318183, 3.0389694253378186],
						[60.5996887026431, 3.0389694326018515],
						[60.599688702805572, 3.0389694362338835],
						[60.599741502761525, 3.0392556356626215],
						[60.599741504953563, 3.0392556444122918],
						[60.599741509175182, 3.0392556582796444],
						[60.599741511285998, 3.039255665213318],
						[60.59983041061728, 3.0395139656688022],
						[60.59983041061728, 3.0395139656688022],
						[60.599830411591469, 3.0395139673196301],
						[60.599830411591469, 3.0395139673196301],
						[60.599902611789837, 3.0397139685681025],
						[60.599902614793578, 3.0397139753366598],
						[60.59990262080106, 3.039713988873777],
						[60.599902623804773, 3.0397139956423218],
						[60.600055424356206, 3.039997295305962],
						[60.600055427278711, 3.0399973002585305],
						[60.600055433042492, 3.0399973083476568],
						[60.600055435965004, 3.0399973133002418],
						[60.600174835667346, 3.0401695139746781],
						[60.600174840456965, 3.0401695204129924],
						[60.600174849954982, 3.0401695314735862],
						[60.600174854744587, 3.0401695379118858],
						[60.600280454789036, 3.0402807376812411],
						[60.600280466965906, 3.0402807482463774],
						[60.600280493998483, 3.0402807688811619],
						[60.600280507880022, 3.0402807772999152],
						[60.600349907800613, 3.040316877672006],
						[60.600349910560659, 3.0403168789925532],
						[60.600349916973748, 3.0403168814684727],
						[60.600349919733823, 3.0403168827890328],
						[60.600419314911008, 3.040347381084783],
						[60.600502710606953, 3.0403890797340081],
						[60.600502740724046, 3.0403890888119638],
						[60.600502802338077, 3.0403890975572438],
						[60.600502833835009, 3.0403890972245722],
						[60.600600033811688, 3.0403723963649187],
						[60.600600034704648, 3.0403723961997478],
						[60.600630635258504, 3.0403668971642204],
						[60.600630638830317, 3.0403668965035431],
						[60.600630645973943, 3.0403668951821894],
						[60.600630650438681, 3.0403668943563456],
						[60.600727850247971, 3.0403418948449503],
						[60.600727865265746, 3.0403418884048916],
						[60.600727895301226, 3.0403418755247995],
						[60.600727909344798, 3.0403418674338663],
						[60.600791808755432, 3.0403001661366691],
						[60.600791810622546, 3.0403001676224157],
						[60.60079181411313, 3.0403001651456392],
						[60.600791814924882, 3.040300163164392],
						[60.600941814686969, 3.0401945638681092],
						[60.600941816472876, 3.0401945635377552],
						[60.600941819070528, 3.0401945612261456],
						[60.600941819882252, 3.0401945592448865],
						[60.600952919945769, 3.0401862600693348],
						[60.600952936343333, 3.0401862442183742],
						[60.600952966297186, 3.0401862093798315],
						[60.600952978067554, 3.0401861907226038],
						[60.600955757315276, 3.0401807326286803],
						[60.600966820765137, 3.0401723596482779],
						[60.600966825067445, 3.0401723551902293],
						[60.600966833753311, 3.0401723480901954],
						[60.600966838948594, 3.0401723434669656],
						[60.601108436765394, 3.0400362452650427],
						[60.601177927083945, 3.0399723554515568],
						[60.601269517681814, 3.0399057613748748],
						[60.601269517681814, 3.0399057613748748],
						[60.601269519386513, 3.039905759228402],
						[60.601269520279445, 3.0399057590632168],
						[60.601325120411964, 3.0398640605819103],
						[60.601402905665367, 3.0398057713545774],
						[60.601436180396036, 3.0397890830488388],
						[60.601627835770017, 3.0397169003836502],
						[60.601636099980368, 3.0397169005328659],
						[60.601636105338073, 3.0397168995417023],
						[60.601636116134685, 3.0397168993755015],
						[60.601636122385344, 3.0397168982191443],
						[60.601841722110464, 3.0396945994519906],
						[60.601841754094231, 3.0396945898726631],
						[60.601841814002604, 3.039694560478027],
						[60.601841841034286, 3.0396945408278748],
						[60.601847277285451, 3.0396891055436894],
						[60.602069384638675, 3.039705799822594],
						[60.602069436429765, 3.039705790241173],
						[60.602069527104049, 3.0397057441675344],
						[60.602069566149709, 3.0397057113076005],
						[60.602075165980914, 3.0396973103506957],
						[60.602075166873867, 3.0396973101854985],
						[60.602075167685591, 3.0396973082041598],
						[60.602075169390254, 3.0396973060576058],
						[60.602194569232985, 3.0395084072258891],
						[60.60219457337282, 3.0395083991352578],
						[60.602194580840788, 3.0395083849353588],
						[60.602194584168913, 3.0395083788260786],
						[60.602300183998658, 3.0392583778460001],
						[60.602300190329942, 3.0392583583627286],
						[60.602300197634868, 3.0392583203874599],
						[60.602300200313145, 3.0392582997488731],
						[60.602300200157728, 3.0392528208653879],
						[60.602306031040236, 3.0392234172414612],
						[60.602363746790743, 3.0392918297194775],
						[60.602363754259244, 3.0392918356624334],
						[60.602363768384542, 3.0392918495297434],
						[60.602363774960097, 3.0392918556379254],
						[60.602499873659497, 3.0394001565595588],
						[60.602810972567504, 3.0396557544455045],
						[60.602811007636717, 3.0396557735944114],
						[60.602811080615993, 3.0396557948857317],
						[60.602811120393135, 3.0396557985139152],
						[60.603005605546471, 3.0396362997251827],
						[60.603069390955469, 3.0396390991216453],
						[60.603069391929644, 3.0396391007726398],
						[60.603069393715536, 3.0396391004422285],
						[60.603261093897316, 3.0396446002565498],
						[60.603261118088184, 3.0396445976121815],
						[60.603261163547408, 3.0396445873704288],
						[60.603261186601642, 3.0396445794426188],
						[60.603469485078044, 3.0395445808579442],
						[60.603505682718151, 3.0395279820736056],
						[60.603505686289921, 3.0395279814127605],
						[60.603505690673451, 3.039527978770479],
						[60.603505693271075, 3.0395279764586229],
						[60.603711137765792, 3.0394196061779595],
						[60.60386667506252, 3.0394390986929078],
						[60.603866687645045, 3.0394390981961212],
						[60.60386671199835, 3.0394390991840279],
						[60.603866724580875, 3.0394390986872408],
						[60.604155610666496, 3.0394030008094783],
						[60.604302796265145, 3.0394058007521845],
						[60.604302797158105, 3.0394058005869602],
						[60.604302798051037, 3.0394058004217412],
						[60.604605598160951, 3.0394085001415805],
						[60.604605611636416, 3.0394084994795323],
						[60.604605640210799, 3.0394084941924229],
						[60.604605653605041, 3.0394084917140853],
						[60.604733336955505, 3.0393723968814719],
						[60.605055618565416, 3.039341898654365],
						[60.605055632933826, 3.0393418978270419],
						[60.605055659559838, 3.0393418892376096],
						[60.605055672954059, 3.0393418867592046],
						[60.605147253741222, 3.0393057942671553],
						[60.605502833540505, 3.0392445965240431],
						[60.605502835326384, 3.0392445961935826],
						[60.605502837193534, 3.0392445976794478],
						[60.605502837112304, 3.0392445958631118],
						[60.605561136436165, 3.039233496698567],
						[60.605952834761915, 3.0391640970629417],
						[60.605952840119592, 3.0391640960715156],
						[60.605952849860792, 3.0391640924375207],
						[60.60595285521849, 3.0391640914460925],
						[60.605972243995318, 3.0391584945537309],
						[60.606219432599396, 3.0391168968740332],
						[60.606388916131273, 3.0390890993333315],
						[60.606402800262501, 3.0390890995114099],
						[60.606419399865935, 3.0390891008224568],
						[60.606419406116572, 3.0390890996657527],
						[60.606419416913198, 3.0390890994992188],
						[60.606419422270889, 3.0390890985077581],
						[60.606816722417719, 3.0390445985590855],
						[60.606816725096557, 3.0390445980633469],
						[60.606816729561309, 3.0390445972371087],
						[60.606816733133094, 3.039044596576121],
						[60.606850018616839, 3.0390390998092394],
						[60.607258283239418, 3.0390280008352524],
						[60.607299960899283, 3.0390362967802198],
						[60.607299975348951, 3.0390362977690932],
						[60.607300004248238, 3.0390362997468152],
						[60.607300017723688, 3.0390362990845095],
						[60.607688891111245, 3.0390002022618394],
						[60.60774996389685, 3.0390112971337588],
						[60.607750007002068, 3.0390112946509311],
						[60.607750088016651, 3.0390112741633994],
						[60.607750125844746, 3.0390112543422041],
						[60.607839012860786, 3.0389390661853488],
						[60.60805009948222, 3.0388195747994833],
						[60.608197298691351, 3.0387362744095867],
						[60.60819730916301, 3.038736266976934],
						[60.608197329213368, 3.0387362522769115],
						[60.608197337899114, 3.0387362451748068],
						[60.608375137744055, 3.038566844858833],
						[60.608375138637015, 3.0385668446935603],
						[60.608375140341678, 3.0385668425465049],
						[60.608375140341678, 3.0385668425465049],
						[60.608561228621824, 3.0383834534783056],
						[60.608647303432718, 3.0383223721394486],
						[60.608691788785372, 3.0383001798727092],
						[60.608691816628443, 3.0383001582351028],
						[60.608691864764772, 3.0383001071995759],
						[60.608691884165104, 3.0383000779669156],
						[60.608730775634349, 3.038208397010095],
						[60.608944565248052, 3.0378973129499038],
						[60.608944565166802, 3.0378973111333791],
						[60.609097365075606, 3.0376723111756991],
						[60.609097365968566, 3.0376723110103967],
						[60.609097367673215, 3.0376723088632436],
						[60.609097369377842, 3.0376723067161122],
						[60.609200168436409, 3.0375084094447744],
						[60.609341862136553, 3.0372973177054789],
						[60.609466854736496, 3.0371390274869738],
						[60.609547348617504, 3.0370445326600537],
						[60.609761246302376, 3.036816836924499],
						[60.609802943743411, 3.0367723395618311],
						[60.609991837596489, 3.0365807444905717],
						[60.610061232408725, 3.0365195494039545],
						[60.610061233301657, 3.0365195492386192],
						[60.610061234194603, 3.0365195490732781],
						[60.610061234113324, 3.036519547256697],
						[60.61021123360397, 3.0363834478001261],
						[60.610211236282808, 3.0363834473040949],
						[60.610211238799103, 3.0363834431748624],
						[60.610211239692049, 3.03638344300952],
						[60.61036683928554, 3.0362307435264078],
						[60.610444531766511, 3.0361557492714955],
						[60.61062512534258, 3.0360112553689631],
						[60.610625126235512, 3.0360112552036096],
						[60.610625126235512, 3.0360112552036096],
						[60.610683424663996, 3.0359640567651773],
						[60.610894518659791, 3.0357973611606277],
						[60.611011211603035, 3.0357168655070033],
						[60.611325111297425, 3.0355084666116867],
						[60.611325113894956, 3.0355084642989141],
						[60.611325120875918, 3.0355084593425925],
						[60.611325125259334, 3.0355084566990675],
						[60.611339007638989, 3.0354973700308867],
						[60.611350089165889, 3.0354918795784447],
						[60.611350090058821, 3.0354918794130685],
						[60.611388990087192, 3.0354723787070905],
						[60.611708390413803, 3.0353112787900982],
						[60.611708391306763, 3.0353112786247118],
						[60.611708392199702, 3.0353112784593299],
						[60.611788981599631, 3.0352695833431809],
						[60.611911170910865, 3.0352223866262888],
						[60.612083367854581, 3.0351584884243907],
						[60.61223896734797, 3.0351029886304008],
						[60.612238968240909, 3.0351029884650096],
						[60.61223897083844, 3.0351029861521313],
						[60.612238971731372, 3.03510298598674],
						[60.612461136989118, 3.0350169004690284],
						[60.612463900106469, 3.0350168997307541],
						[60.612463916260708, 3.0350168985703294],
						[60.612463946702, 3.0350168947635625],
						[60.612463961800707, 3.0350168901351089],
						[60.612686162301252, 3.0349445901445922],
						[60.612686164980083, 3.0349445896483931],
						[60.612686171149377, 3.0349445866738645],
						[60.612686173828209, 3.0349445861776676],
						[60.612847273883368, 3.0348807856818412],
						[60.613013973972969, 3.0348140860474104],
						[60.613013975758847, 3.0348140857165973],
						[60.613013981035195, 3.0348140829074026],
						[60.61301398282108, 3.0348140825765899],
						[60.613136183121163, 3.0347584811432058],
						[60.613136186692941, 3.0347584804815724],
						[60.613136194566813, 3.0347584753593959],
						[60.613136199031509, 3.0347584745323601],
						[60.613213998686639, 3.0347140742230487],
						[60.613480699317172, 3.0345612736998895],
						[60.613480702807628, 3.0345612712214591],
						[60.613480709788554, 3.034561266264594],
						[60.613480714253264, 3.0345612654375147],
						[60.613561214241955, 3.0345057648715499],
						[60.61356121594654, 3.0345057627239398],
						[60.613561221141566, 3.0345057580978869],
						[60.613561222927451, 3.0345057577670493],
						[60.613586206853192, 3.0344862695934331],
						[60.6136361848368, 3.0344612810452984],
						[60.61392228043114, 3.0343362829422076],
						[60.614036131292558, 3.0342863042873112],
						[60.614369369117384, 3.0343251982653832],
						[60.614483242842333, 3.0343473928349241],
						[60.614911008539366, 3.0345195781698546],
						[60.614933194311178, 3.0345334702416289],
						[60.614933214524989, 3.0345334793202241],
						[60.614933260228938, 3.0345334946679734],
						[60.614933282958937, 3.0345334996165798],
						[60.615294383255133, 3.03456409855798],
						[60.615294384148065, 3.0345640983925515],
						[60.615294386015258, 3.0345640998785628],
						[60.615294386908189, 3.0345640997131382],
						[60.615380586970716, 3.0345696003828992],
						[60.615380589649519, 3.0345695998866162],
						[60.615380596874353, 3.0345696003800611],
						[60.615380599553163, 3.0345695998837772],
						[60.6153832906651, 3.034569599608671],
						[60.615830556088518, 3.0346112974926065],
						[60.615861031985482, 3.0346223882296077],
						[60.615861043837626, 3.0346223915297319],
						[60.615861068353503, 3.0346223961476415],
						[60.61586108020564, 3.0346223994477532],
						[60.616280533834235, 3.0346640938380038],
						[60.616347093721075, 3.0347057697023709],
						[60.616347104761573, 3.0347057749849311],
						[60.61634712773548, 3.0347057853846029],
						[60.616347138775971, 3.0347057906671631],
						[60.616730496983912, 3.0348279765130908],
						[60.616960965427864, 3.0350390475506828],
						[60.616960970136475, 3.0350390521744628],
						[60.61696098036532, 3.0350390594396002],
						[60.616960985073909, 3.0350390640633815],
						[60.617174876716838, 3.0351890581594723],
						[60.617508169003251, 3.0354779507205163],
						[60.617508175579026, 3.0354779568305958],
						[60.617508190435117, 3.0354779669029153],
						[60.617508197903817, 3.0354779728475774],
						[60.617624898066914, 3.0355473728980584],
						[60.61762491186748, 3.0355473795018479],
						[60.617624942066179, 3.0355473903961521],
						[60.61762495757133, 3.0355473948521059],
						[60.617663858075034, 3.0355557941165103],
						[60.617663858156313, 3.0355557959335191],
						[60.617663859049244, 3.0355557957681034],
						[60.617663859942184, 3.03555579560269],
						[60.618002759884369, 3.0356251953694655],
						[60.618002761751541, 3.0356251968556576],
						[60.618074932592229, 3.0356390905610704],
						[60.618180506789834, 3.0356945774617565],
						[60.618180516937329, 3.0356945829103248],
						[60.618180538855682, 3.0356945898425636],
						[60.618180549814831, 3.0356945933086954],
						[60.618522112309165, 3.0357834831628598],
						[60.618758181144017, 3.0359584601292702],
						[60.618758188612702, 3.0359584660742627],
						[60.618758205254672, 3.0359584758163294],
						[60.618758212642042, 3.0359584799442381],
						[60.618972087067618, 3.0360612674316658],
						[60.619338765193476, 3.0363945471060712],
						[60.61933876900909, 3.0363945518957607],
						[60.619338776396475, 3.0363945560238492],
						[60.61933877923785, 3.0363945591618444],
						[60.61941927827683, 3.0364557578869928],
						[60.619472075844151, 3.0364973574939293],
						[60.619472078685554, 3.036497360631941],
						[60.619472086072925, 3.0364973647600704],
						[60.619472088914321, 3.0364973678980833],
						[60.619869277150947, 3.0367612585236241],
						[60.620188766870221, 3.0370473483813156],
						[60.620188771578746, 3.037047353005832],
						[60.620188781807492, 3.037047360272298],
						[60.620188787408978, 3.0370473647314014],
						[60.620319285562914, 3.0371362633318526],
						[60.620563784712829, 3.0373084644971784],
						[60.620563792100192, 3.0373084686256195],
						[60.620563805007748, 3.0373084753960846],
						[60.620563812395119, 3.0373084795245049],
						[60.620769286954854, 3.0374084686474645],
						[60.620852666050759, 3.0374834487925413],
						[60.620852696574389, 3.0374834669583706],
						[60.620852761680041, 3.0374834933771462],
						[60.620852797073738, 3.0374834996474962],
						[60.621216585007431, 3.0374890978702598],
						[60.621649827561576, 3.0382251009376464],
						[60.621649840956636, 3.0382251186121398],
						[60.621649872130241, 3.0382251513170386],
						[60.621649889015849, 3.0382251665128082],
						[60.621666588774744, 3.0382362667195544],
						[60.621666594294943, 3.0382362693618177],
						[60.621666603549428, 3.0382362749770984],
						[60.621666609069599, 3.0382362776193657],
						[60.621672109293762, 3.0382390774989658],
						[60.621672155727794, 3.0382390890548021],
						[60.621672250137088, 3.0382390862289581],
						[60.621672297381913, 3.0382390756471302],
						[60.62169729675248, 3.0382251744595083],
						[60.621697334660659, 3.0382251362900572],
						[60.621697387422095, 3.0382250477301893],
						[60.621697400326916, 3.0382249940360309],
						[60.621672403179147, 3.0373279041738073],
						[60.621697310654966, 3.0373112653461636],
						[60.62169732810748, 3.0373112529520427],
						[60.621697357329772, 3.0373112218872],
						[60.621697370073747, 3.0373112048683422],
						[60.621930760048009, 3.0369334226668081],
						[60.622116845634785, 3.036733436882773],
						[60.622116860894977, 3.0367334157326735],
						[60.622116885083436, 3.0367333727731172],
						[60.622116893930453, 3.036733349146397],
						[60.622172392806931, 3.0365167539909863],
						[60.622208490675298, 3.0363972577293583],
						[60.622208495220093, 3.0363972385641342],
						[60.622208498952098, 3.0363972012262206],
						[60.622208498950911, 3.0363971810708494],
						[60.622125200087815, 3.0355027913775601],
						[60.622125199629195, 3.0354832992079817],
						[60.622125197923282, 3.0354832812008259],
						[60.622125190858448, 3.0354832440310471],
						[60.62212518639241, 3.0354832247029799],
						[60.622119606896561, 3.0354693763746528],
						[60.622230797026475, 3.0348056338838258],
						[60.622230796945182, 3.0348056320665635],
						[60.622269596611233, 3.0345694317331211],
						[60.62226959571538, 3.0345693915878078],
						[60.622269576957848, 3.0345693144412063],
						[60.622269559177475, 3.0345692792571772],
						[60.622116859086994, 3.0343692782506988],
						[60.622116792518682, 3.034369239282058],
						[60.622116652161722, 3.0343692213172435],
						[60.622116578291759, 3.0343692405038003],
						[60.622044278188532, 3.0344248411401509],
						[60.622044277295601, 3.0344248413056296],
						[60.622044275591037, 3.0344248434538486],
						[60.622044274698105, 3.0344248436193255],
						[60.621863774899182, 3.0345692432733236],
						[60.621863742837562, 3.034569291357851],
						[60.621863707857578, 3.0345693949524928],
						[60.621863706806394, 3.0345694519489017],
						[60.622099695084792, 3.0354524338028752],
						[60.621666793897852, 3.0352470227962183],
						[60.621502907845979, 3.0351498323634183],
						[60.621216874479906, 3.0349498787379097],
						[60.62111409302225, 3.0345693483134593],
						[60.621114085877302, 3.0345693294825238],
						[60.621114068989868, 3.0345692941342572],
						[60.621114059328704, 3.0345692794341383],
						[60.620902974929471, 3.034291599277275],
						[60.620769586286485, 3.0339666259368951],
						[60.620650192056694, 3.0336555425716023],
						[60.62055579493542, 3.0332249562888483],
						[60.62054740078716, 3.0331860837787286],
						[60.620566899675772, 3.0327417082124],
						[60.620566898781121, 3.0327416882235982],
						[60.62056689260848, 3.0327416508907299],
						[60.620566888060743, 3.0327416297468277],
						[60.620319588001657, 3.0320749308103481],
						[60.620319578177551, 3.0320749124770354],
						[60.620319556743475, 3.0320748761414684],
						[60.620319544321916, 3.032074860121893],
						[60.620239073313208, 3.0319915907263257],
						[60.620177991790221, 3.0318277429324278],
						[60.62007799882192, 3.031408272983561],
						[60.620075199880631, 3.0313277934022373],
						[60.620064099883685, 3.0309139114213206],
						[60.620122397670485, 3.0305056283315652],
						[60.620122397589121, 3.0305056265144357],
						[60.620122398400703, 3.0305056245317341],
						[60.620122399293621, 3.0305056243661648],
						[60.62013909944141, 3.0303694243545434],
						[60.620139092779922, 3.0303693761198724],
						[60.620139059163591, 3.030369288910197],
						[60.620139031234459, 3.0303692482836384],
						[60.619991831095078, 3.0302415494757131],
						[60.619991830120789, 3.0302415478241542],
						[60.619872362045498, 3.0301387759041587],
						[60.619839080159842, 3.0300693142277062],
						[60.619839076181279, 3.0300693058044694],
						[60.619839065463978, 3.0300692876376307],
						[60.619839058806633, 3.0300692797111548],
						[60.619786281214481, 3.0299999082882398],
						[60.619655791952042, 3.029558243590186],
						[60.619655789922071, 3.0295582384700799],
						[60.619655785780708, 3.0295582264127434],
						[60.619655783750723, 3.0295582212926382],
						[60.619647383537142, 3.0295388206801652],
						[60.619647309821964, 3.0295387628953332],
						[60.619647148113479, 3.0295387305900459],
						[60.619647059308612, 3.0295387580522859],
						[60.619644280996319, 3.0295415360546754],
						[60.619422198990883, 3.0296553764471805],
						[60.619197315362541, 3.0295415346985206],
						[60.618972334984939, 3.0293359526031347],
						[60.618972263546056, 3.0293359255443528],
						[60.618972123353679, 3.02933593138986],
						[60.61897205362591, 3.0293359626426821],
						[60.618758153655619, 3.0295637625408496],
						[60.618758152844066, 3.0295637645234867],
						[60.618758151139581, 3.0295637666717288],
						[60.618758150246649, 3.0295637668373194],
						[60.61871375828779, 3.02961375903728],
						[60.618527665091932, 3.0297831516204496],
						[60.618527659978454, 3.0297831580650483],
						[60.618527648777189, 3.0297831693028141],
						[60.618527643663718, 3.0297831757474287],
						[60.618460944322869, 3.0298665756600869],
						[60.618460943429945, 3.0298665758256642],
						[60.618358146783962, 3.0299970707671013],
						[60.618174851141276, 3.0302026667710344],
						[60.618074886207722, 3.0303137270506251],
						[60.617824954568469, 3.0303998041841789],
						[60.617625006602871, 3.0304247977497565],
						[60.617436138172359, 3.0303887039126853],
						[60.617436119176688, 3.030388701938473],
						[60.617436083133917, 3.0303887012928681],
						[60.617436064300968, 3.030388702952608],
						[60.617374965021817, 3.0303998022867007],
						[60.617180607179925, 3.0304330968556492],
						[60.616913970170145, 3.0303692100806883],
						[60.616730691673318, 3.0302748226142628],
						[60.616730660581339, 3.0302748118907168],
						[60.616730598073985, 3.0302748033277664],
						[60.616730565684321, 3.0302748038369844],
						[60.616444450052562, 3.0303247881211663],
						[60.616280739645894, 3.0302026558265345],
						[60.616116893798321, 3.0299971228191236],
						[60.616080798635082, 3.0295888826596968],
						[60.61608079855371, 3.0295888808428009],
						[60.616080799446642, 3.0295888806772293],
						[60.616072398667477, 3.0295055806697722],
						[60.616072397123574, 3.0295055663001516],
						[60.616072389652444, 3.0295055402056597],
						[60.616072386322671, 3.0295055261671866],
						[60.615905804943111, 3.0290832744392295],
						[60.615933498167401, 3.028877826505262],
						[60.615933498897576, 3.0288778227058986],
						[60.615933499383601, 3.0288778134558556],
						[60.615933500113769, 3.0288778096564917],
						[60.615955800175428, 3.0284194100836253],
						[60.615955800094042, 3.0284194082667373],
						[60.615966900130125, 3.0281694088372912],
						[60.61596690004874, 3.0281694070204019],
						[60.615966899885962, 3.0281694033866149],
						[60.615966899804562, 3.0281694015697278],
						[60.615969599798859, 3.0278833016116478],
						[60.615969599066325, 3.0278832852596396],
						[60.615969593055191, 3.0278832515668452],
						[60.615969588750922, 3.0278832358773067],
						[60.61583348916551, 3.0274860358310289],
						[60.615833488109786, 3.0274860323628863],
						[60.61583348599833, 3.0274860254266325],
						[60.615833484049652, 3.0274860221241187],
						[60.61578078683128, 3.0273610293378352],
						[60.615744588660164, 3.0272555353786705],
						[60.615744588578742, 3.0272555335618039],
						[60.615744587604418, 3.0272555319105625],
						[60.615744586630093, 3.027255530259326],
						[60.615589086551971, 3.0268388295049733],
						[60.61558907664579, 3.0268388093590457],
						[60.615589050582955, 3.0268387702266728],
						[60.615589036374971, 3.0268387545426818],
						[60.615383457428692, 3.0266470736068776],
						[60.615200191421003, 3.0263416284033342],
						[60.615130798707646, 3.0259388749394596],
						[60.615119599115673, 3.025808283661338],
						[60.615089100556524, 3.0254278041762053],
						[60.615147397702678, 3.0249944275719796],
						[60.615147398104178, 3.0249943963544337],
						[60.615147387299139, 3.0249943360733362],
						[60.615147377066961, 3.0249943086609212],
						[60.614936276796392, 3.0245888080303827],
						[60.614936276714943, 3.0245888062135675],
						[60.614936275822039, 3.0245888063792679],
						[60.614936275740604, 3.0245888045624501],
						[60.614916875643971, 3.0245527048812018],
						[60.614916850880633, 3.024552674669907],
						[60.614916792913306, 3.0245526268052449],
						[60.614916757923503, 3.0245526094832922],
						[60.614788964136665, 3.0245137092326213],
						[60.614533369006459, 3.0244192112775834],
						[60.614533349036364, 3.0244192076558836],
						[60.614533309096217, 3.0244192004124453],
						[60.614533288396103, 3.0244192005900317],
						[60.61448611113714, 3.0244219980802267],
						[60.614436136209029, 3.0244137022884092],
						[60.614116738936588, 3.0243498045203201],
						[60.614116726191341, 3.0243498013898242],
						[60.614116699077876, 3.0243497990937991],
						[60.614116685602589, 3.0243497997625641],
						[60.614036088729613, 3.0243554008943221],
						[60.613961096141587, 3.0243581008039744],
						[60.613663972071542, 3.0243581007914111],
						[60.613586233911654, 3.0242942513496969],
						[60.613380741056901, 3.0240915573917651],
						[60.613358483263802, 3.0240693001811385],
						[60.613350196613602, 3.0240305630810687],
						[60.613277997384472, 3.0235999676654122],
						[60.613277996165742, 3.0235999605642307],
						[60.613277993891202, 3.0235999499953259],
						[60.613277991861018, 3.0235999448765978],
						[60.613227997716258, 3.0234249661093515],
						[60.613186300050536, 3.0227832863561495],
						[60.613186300050536, 3.0227832863561495],
						[60.613186299076155, 3.022783284705159],
						[60.613186298994691, 3.0227832828884478],
						[60.613177998819431, 3.0226860839221974],
						[60.613177998656496, 3.0226860802887563],
						[60.61317799841212, 3.0226860748386213],
						[60.613177998330656, 3.0226860730218923],
						[60.613169599228897, 3.0226249796154114],
						[60.613139099325608, 3.0222221841624108],
						[60.613139099081202, 3.0222221787122696],
						[60.613139096725085, 3.0222221663268072],
						[60.613139096399202, 3.0222221590599596],
						[60.613075197587882, 3.0219055715885039],
						[60.613064099226165, 3.0217749835504799],
						[60.613064091590658, 3.0217749538268568],
						[60.61306406925776, 3.0217748975224419],
						[60.613064051881601, 3.0217748714389536],
						[60.612689052242843, 3.0213331707581386],
						[60.612688969598707, 3.0213331348108703],
						[60.612688809844357, 3.0213331461521844],
						[60.612688731759768, 3.0213331917898598],
						[60.612566532063838, 3.0215220911557168],
						[60.612566532063838, 3.0215220911557168],
						[60.612566531252398, 3.0215220931381594],
						[60.612408131330945, 3.0217720935907035],
						[60.6124081206192, 3.0217721157289628],
						[60.612408104634738, 3.0217721608275911],
						[60.612408101229306, 3.0217721852730848],
						[60.612385902827604, 3.0220666561956877],
						[60.612244212985324, 3.0224471297278916],
						[60.612244212173877, 3.0224471317102917],
						[60.612244212255334, 3.0224471335269683],
						[60.612244211362423, 3.0224471336927086],
						[60.61219421063695, 3.0225888322571901],
						[60.612194210799878, 3.0225888358905006],
						[60.612194209988431, 3.0225888378729087],
						[60.61219420917697, 3.0225888398553047],
						[60.612163708887344, 3.0226860405506164],
						[60.612163705885891, 3.0226860539301557],
						[60.61216370158737, 3.022686078541128],
						[60.612163700371752, 3.0226860915891995],
						[60.612155400969108, 3.0228610793659265],
						[60.612085903137519, 3.0232888676499714],
						[60.612085902326058, 3.0232888696323412],
						[60.612085902326058, 3.0232888696323412],
						[60.612038703897866, 3.023599960916139],
						[60.611958105681651, 3.0239332514876636],
						[60.611835906897582, 3.0244249453657073],
						[60.611808115759885, 3.0245138187715535],
						[60.611799828022711, 3.024527697088923],
						[60.611799822260856, 3.0245277091485714],
						[60.61179981154865, 3.0245277312855103],
						[60.611799807654087, 3.0245277448304186],
						[60.611794219186123, 3.0245471066318834],
						[60.611744253570343, 3.024610962027507],
						[60.611499868150766, 3.0248276508300971],
						[60.611344447028884, 3.0249635937955657],
						[60.611069597902627, 3.024511041841309],
						[60.61105849994145, 3.0241832921025034],
						[60.611058483211451, 3.0241832402554594],
						[60.611058423452398, 3.0241831524315916],
						[60.611058381316262, 3.0241831162890516],
						[60.610894506084627, 3.0241109284422283],
						[60.610564043935774, 3.0238387594514626],
						[60.610447357095445, 3.0236915752227116],
						[60.610447352386487, 3.0236915706015055],
						[60.610447341019842, 3.0236915580573269],
						[60.610447335417959, 3.0236915536018185],
						[60.610344535058118, 3.0235970532234067],
						[60.610344531242085, 3.0235970484365278],
						[60.610344522880006, 3.0235970426615677],
						[60.610344519145393, 3.0235970396912522],
						[60.60999731907534, 3.0233415386932534],
						[60.609997305192778, 3.0233415302795477],
						[60.609997278402091, 3.0233415151029952],
						[60.609997262896592, 3.0233415106537969],
						[60.609847268674606, 3.023291511489393],
						[60.609547276069385, 3.0231692151315754],
						[60.609547254150542, 3.023169208209401],
						[60.609547208852803, 3.023169201962518],
						[60.60954718628539, 3.0231692006555773],
						[60.609344385686882, 3.0231830992087909],
						[60.60930282485122, 3.023185897551798],
						[60.609100118262397, 3.0231192281420527],
						[60.608966880272327, 3.0229555042750436],
						[60.608886296173232, 3.022683256202773],
						[60.608833497790407, 3.0223138720122806],
						[60.608833491210916, 3.0223138457595735],
						[60.608833470178588, 3.0223137983786144],
						[60.608833456537184, 3.0223137752681608],
						[60.608650189016842, 3.0220832154916386],
						[60.608611297221955, 3.0218499661735208],
						[60.608611294947302, 3.0218499556062577],
						[60.608611287637736, 3.0218499331524868],
						[60.608611284388687, 3.0218499209344905],
						[60.608578004338334, 3.0217721679137557],
						[60.608589098855226, 3.0216472172230704],
						[60.608650196904946, 3.0210000434175655],
						[60.608680787757024, 3.0209139673295393],
						[60.608680793678111, 3.0209139387566104],
						[60.608680797402556, 3.0209138812861607],
						[60.608680794231518, 3.0209138507379265],
						[60.608666903267654, 3.0208582877088936],
						[60.608672397450889, 3.0208139361922752],
						[60.608805794258856, 3.0202639488142551],
						[60.608886293620252, 3.0199444491608447],
						[60.608886295891509, 3.0199444395811725],
						[60.608886299459648, 3.0199444187711189],
						[60.608886299863592, 3.0199444077065265],
						[60.608902999612148, 3.0195389087877875],
						[60.60890300042356, 3.019538906805511],
						[60.60890300042356, 3.019538906805511],
						[60.60890300034206, 3.0195389049890484],
						[60.608905799844038, 3.0194278042761695],
						[60.608905799681025, 3.019427800643252],
						[60.608905800166418, 3.0194277913951293],
						[60.608905799921907, 3.0194277859457288],
						[60.608878001448005, 3.0190306100648248],
						[60.608936297333365, 3.0186972341461655],
						[60.608936297251851, 3.0186972323297052],
						[60.608936297251851, 3.0186972323297052],
						[60.608997396792397, 3.0183250318659902],
						[60.608997397603787, 3.0183250298836954],
						[60.608997399063547, 3.0183250222861582],
						[60.60899739890052, 3.0183250186532322],
						[60.609016891605627, 3.0181167933274646],
						[60.609075137625325, 3.0180612455760918],
						[60.609075141845338, 3.0180612392974826],
						[60.609075151341287, 3.0180612302073428],
						[60.609075154831416, 3.0180612277275105],
						[60.609100154745931, 3.0180307275977927],
						[60.609100160670238, 3.0180307191710067],
						[60.609100171625933, 3.0180307024832933],
						[60.609100176657307, 3.0180306942223534],
						[60.609311277339145, 3.0176333940488869],
						[60.609311277257618, 3.0176333922324075],
						[60.609536277104269, 3.017205693105681],
						[60.609536277022741, 3.0172056912891905],
						[60.609536277834124, 3.0172056893068144],
						[60.609536278727035, 3.0172056891409462],
						[60.609541863685372, 3.0171945172165562],
						[60.609550141036323, 3.0171862410008896],
						[60.609550143633527, 3.0171862386867936],
						[60.609550147935011, 3.0171862342244675],
						[60.609550151425147, 3.0171862317444802],
						[60.609569511385146, 3.0171640772181831],
						[60.609927854080453, 3.0170640926942931],
						[60.609997254445588, 3.0170445934153305],
						[60.609997273845039, 3.0170445843164919],
						[60.609997309883688, 3.0170445647999427],
						[60.609997328308708, 3.0170445540504645],
						[60.610261227539368, 3.016825153488921],
						[60.610261253103602, 3.016825121264425],
						[60.610261288644914, 3.0168250505528116],
						[60.610261300407799, 3.0168250117339133],
						[60.610291895253859, 3.0162917887908716],
						[60.61039733986491, 3.01618904254586],
						[60.610397340757821, 3.0161890423799576],
						[60.610397340676286, 3.0161890405634226],
						[60.610447341169824, 3.0161390411164115],
						[60.61044734636419, 3.0161390364879117],
						[60.610447355778454, 3.0161390255802623],
						[60.610447360891285, 3.0161390191352075],
						[60.610672361177841, 3.0158362192259598],
						[60.610672365316191, 3.0158362111301784],
						[60.610672373755968, 3.0158361985717312],
						[60.610672377894289, 3.0158361904759521],
						[60.610675071864584, 3.0158308981607282],
						[60.610897022819252, 3.0159918194185802],
						[60.61094699884589, 3.0161944194169146],
						[60.610941500186485, 3.0162916887908322],
						[60.610941503195519, 3.0162917157075304],
						[60.610941514652602, 3.0162917703621015],
						[60.610941523912025, 3.01629179611749],
						[60.611344223924256, 3.0170306968511933],
						[60.611344315339444, 3.0170307476424343],
						[60.611344493359056, 3.0170307420459341],
						[60.6113445799635, 3.0170306856581908],
						[60.611522380238014, 3.0166583857048379],
						[60.611697379656988, 3.0162917857496301],
						[60.611697386229437, 3.0162917717060926],
						[60.611697394746685, 3.0162917408153298],
						[60.611697397665921, 3.0162917256188249],
						[60.611716898103744, 3.0161389252997926],
						[60.611794477892602, 3.015562021468682],
						[60.612022008990742, 3.0162917598538321],
						[60.612022010046736, 3.0162917633211719],
						[60.612022012077205, 3.016291768439245],
						[60.61202201410768, 3.0162917735573154],
						[60.612244214137078, 3.0168611733275759],
						[60.612244236386395, 3.0168612076614578],
						[60.612244292003659, 3.0168612632727081],
						[60.61224432634603, 3.0168612862008226],
						[60.612591626276874, 3.0170001859547293],
						[60.612591636343538, 3.0170001895798619],
						[60.612591656395281, 3.0170001950134844],
						[60.612591666380389, 3.0170001968219582],
						[60.612688865858765, 3.017016896691858],
						[60.612688936398769, 3.0170168835861277],
						[60.612689049382823, 3.0170168131355983],
						[60.612689091826844, 3.017016755790785],
						[60.612822392177513, 3.0165639578805794],
						[60.612902992001999, 3.0162944562740237],
						[60.612902996136235, 3.0162944280283437],
						[60.612902995230918, 3.0162943677461365],
						[60.61290299035447, 3.0162943393429584],
						[60.612694589801535, 3.0156443391790875],
						[60.612694547740546, 3.0156442847135754],
						[60.612694436595085, 3.0156442156090244],
						[60.612694369377976, 3.0156442024547969],
						[60.612552909357298, 3.0156664806219169],
						[60.612400210628955, 3.0153805706216845],
						[60.612489095570147, 3.014966741818724],
						[60.612489096784984, 3.0149667287702777],
						[60.612489100107588, 3.0149667025074085],
						[60.612489100429542, 3.0149666896249108],
						[60.612461301329269, 3.014466705619383],
						[60.612505799251004, 3.0140889235007302],
						[60.612530797764876, 3.0138806303049765],
						[60.612591897147468, 3.0135528370549896],
						[60.612591897632434, 3.0135528278057566],
						[60.612591899576891, 3.0135528109579139],
						[60.61259190014345, 3.0135528035253123],
						[60.612594599816354, 3.0133528367659226],
						[60.612694589086715, 3.0130694670588101],
						[60.612694591357467, 3.013069457477517],
						[60.612694595980564, 3.0130694401315838],
						[60.612694598251316, 3.0130694305502899],
						[60.61274459793308, 3.0127472311316938],
						[60.612744597688291, 3.0127472256817143],
						[60.61274459898452, 3.0127472144497434],
						[60.612744599714226, 3.0127472106504216],
						[60.61275019899994, 3.0126389251854007],
						[60.612791896234143, 3.0124333401569787],
						[60.612791896152537, 3.0124333383403239],
						[60.612791896963834, 3.012433336357645],
						[60.612791896882236, 3.0124333345409875],
						[60.612858496604261, 3.0120583357059485],
						[60.612858497415559, 3.0120583337232567],
						[60.61285849733396, 3.0120583319065979],
						[60.61291129735757, 3.011727832040715],
						[60.612911298005649, 3.0117278264246896],
						[60.612911299465019, 3.0117278188259453],
						[60.612911300113083, 3.0117278132099039],
						[60.612939098853126, 3.01131111508206],
						[60.612980798680077, 3.0108139168016246],
						[60.612980800221017, 3.0108139110194849],
						[60.612980799649648, 3.0108138983028416],
						[60.612980800379304, 3.0108138945034497],
						[60.612969599791448, 3.010458311795067],
						[60.613047397155299, 3.0099000366870561],
						[60.613077982047109, 3.0097722983763893],
						[60.613138942975851, 3.0097085452716836],
						[60.61321661128396, 3.0097473785996414],
						[60.61321666307726, 3.0097473891153701],
						[60.613216767143456, 3.0097473807478439],
						[60.613216816737641, 3.0097473623628868],
						[60.613439016817239, 3.0095862616938245],
						[60.613439017710135, 3.0095862615277209],
						[60.613439018603046, 3.0095862613616133],
						[60.613588833304981, 3.0094752970735636],
						[60.613816508146861, 3.0099000654434422],
						[60.613872002875056, 3.0102417320520476],
						[60.613872002956683, 3.0102417338687748],
						[60.613872002956683, 3.0102417338687748],
						[60.613933102660404, 3.0105972321971928],
						[60.613966498564267, 3.0108139061252217],
						[60.613949801042594, 3.0109971828350877],
						[60.613949819323565, 3.0109972490472261],
						[60.613949895249846, 3.010997355831158],
						[60.613949952813535, 3.0109973945862296],
						[60.614041652722214, 3.0110195933936916],
						[60.614041697775413, 3.0110195941738063],
						[60.61404178511652, 3.0110195742654891],
						[60.614041825700284, 3.0110195557258943],
						[60.614113893548023, 3.0109613616545516],
						[60.614485966650982, 3.011291850642738],
						[60.614486055869378, 3.0112918725200624],
						[60.614486215372366, 3.0112918355273028],
						[60.614486284764055, 3.0112917768233016],
						[60.614594585402088, 3.0110306762584607],
						[60.614594585402088, 3.0110306762584607],
						[60.614594586213379, 3.0110306742756321],
						[60.614594586213379, 3.0110306742756321],
						[60.614680785761863, 3.0108139744469531],
						[60.61468078819567, 3.0108139684984452],
						[60.614680792170439, 3.0108139567674854],
						[60.614680792818454, 3.0108139511511194],
						[60.614769593070292, 3.0104750517506491],
						[60.614905793036641, 3.0099639517087584],
						[60.614905795307173, 3.00996394212659],
						[60.614905798144044, 3.0099639251112396],
						[60.614905798710396, 3.0099639176780668],
						[60.614911296673107, 3.0099000477896656],
						[60.614922386289997, 3.0098722732538108],
						[60.614922386208363, 3.0098722714370454],
						[60.614922387912529, 3.00987226928806],
						[60.614922387830902, 3.0098722674712906],
						[60.614936288109767, 3.0098333666649117],
						[60.614936289732306, 3.0098333626991578],
						[60.614936292084444, 3.0098333549337428],
						[60.61493629281405, 3.0098333511341013],
						[60.615080793158192, 3.0092833527586627],
						[60.615161293087077, 3.0089889521569999],
						[60.615161293087077, 3.0089889521569999],
						[60.615161293898325, 3.0089889501740879],
						[60.615161293898325, 3.0089889501740879],
						[60.61523909369626, 3.0086861506726232],
						[60.615386276611069, 3.0081195132644987],
						[60.615402927177357, 3.0081057544161682],
						[60.615402934968444, 3.0081057474703647],
						[60.615402947871928, 3.0081057340772177],
						[60.615402954770104, 3.0081057272975569],
						[60.615427954616102, 3.0080751267529204],
						[60.615427958917252, 3.0080751222885205],
						[60.615427965652117, 3.0080751118752675],
						[60.61542796906037, 3.0080751075770453],
						[60.615658424790752, 3.0077112763257152],
						[60.615833342298231, 3.0076723953016424],
						[60.616061140739895, 3.0076251963417433],
						[60.616061155837464, 3.0076251916998973],
						[60.616061185058022, 3.0076251807655638],
						[60.616061199992224, 3.00762517249006],
						[60.616283349814033, 3.0074974012122566],
						[60.616449991804963, 3.0075029996824636],
						[60.616561048765803, 3.0075084971787169],
						[60.616733213093084, 3.0075918796169883],
						[60.616733294270418, 3.0075918828291504],
						[60.616733431362526, 3.0075918280009368],
						[60.616733487277315, 3.0075917699605559],
						[60.616825186796838, 3.0073472698718584],
						[60.616894587345847, 3.007161170298537],
						[60.616894592697655, 3.0071611491495744],
						[60.616894598855154, 3.0071611058657557],
						[60.616894598849612, 3.0071610857139679],
						[60.616841904654301, 3.0064694592592542],
						[60.6170084602335, 3.006247320409162],
						[60.617008462748778, 3.0062473162767516],
						[60.617008467779385, 3.0062473080119134],
						[60.617008471187582, 3.0062473037132791],
						[60.61710289369239, 3.0060919308238034],
						[60.617180552106888, 3.006111293798337],
						[60.617180604303151, 3.006111293241593],
						[60.617180702031256, 3.0061112640553107],
						[60.617180746670222, 3.0061112355919981],
						[60.617430744985072, 3.0058418362580031],
						[60.617630744124419, 3.0056334386625068],
						[60.617630746639691, 3.0056334345299702],
						[60.617630752726555, 3.0056334297324532],
						[60.617630756134723, 3.0056334254336536],
						[60.617722353092489, 3.0055195289410097],
						[60.617886248606183, 3.005336233309726],
						[60.617886258748953, 3.0053362185962413],
						[60.617886278141654, 3.0053361893355017],
						[60.617886285605749, 3.0053361751207777],
						[60.617972385104835, 3.0051222762563614],
						[60.618080783850736, 3.0048694789970121],
						[60.618080784580222, 3.0048694751968865],
						[60.618080787906649, 3.0048694690810218],
						[60.618080788636135, 3.0048694652808932],
						[60.618172377950735, 3.0046056962635945],
						[60.618314056881317, 3.0044223238272822],
						[60.618447354764719, 3.0042584254910829],
						[60.618447355657615, 3.0042584253247955],
						[60.618527955612763, 3.0041584257914549],
						[60.618527958209697, 3.0041584234756344],
						[60.618527962429013, 3.0041584171933131],
						[60.618527965025962, 3.0041584148774914],
						[60.618714063678965, 3.0038890147471866],
						[60.618972363576219, 3.0035223145096883],
						[60.618972367795465, 3.0035223082271982],
						[60.618972375341102, 3.0035222958285428],
						[60.618972378667479, 3.0035222897123783],
						[60.618977923164501, 3.0035113008126544],
						[60.618980599952614, 3.0035113005875314],
						[60.618980616106349, 3.0035112994109068],
						[60.618980648250343, 3.0035112934237072],
						[60.618980664322351, 3.0035112904301049],
						[60.618988963991555, 3.0035084894606605],
						[60.618988965777341, 3.0035084891280355],
						[60.618988970160039, 3.0035084864794905],
						[60.618988971945804, 3.0035084861468708],
						[60.619358370122939, 3.0033668862203098],
						[60.619427861740512, 3.0033418896559749],
						[60.619522255837381, 3.0033140920664381],
						[60.619522270853047, 3.0033140856049823],
						[60.619522299098549, 3.0033140730147041],
						[60.619522313139562, 3.0033140649025514],
						[60.619716810607883, 3.0031807666497419],
						[60.619877910444878, 3.0030751676047154],
						[60.619877921725475, 3.0030751581743265],
						[60.619877945097834, 3.0030751373302147],
						[60.619877955403808, 3.003075126249132],
						[60.62002235497895, 3.0028973275608526],
						[60.620272336765652, 3.0025945476131968],
						[60.620311216369537, 3.0025668634311549],
						[60.620311223349162, 3.0025668584662748],
						[60.620311235440866, 3.0025668470521851],
						[60.620311240552972, 3.0025668406029662],
						[60.620322252825048, 3.0025558291590646],
						[60.620433196363756, 3.0025945755621581],
						[60.620669266892804, 3.002805749645808],
						[60.620669284593319, 3.0028057628389231],
						[60.620669323320655, 3.0028057831086086],
						[60.620669342643453, 3.0028057923349127],
						[60.620772091099269, 3.0028362766576957],
						[60.621024852846155, 3.0031084359009839],
						[60.621024860316197, 3.0031084418386618],
						[60.621024871684732, 3.0031084543793489],
						[60.621024879073019, 3.0031084584999412],
						[60.621222062795695, 3.0032584464598751],
						[60.621441549683823, 3.0035084316726439],
						[60.621441559103097, 3.0035084409120545],
						[60.621441576967001, 3.0035084577400957],
						[60.621441587197417, 3.0035084649960693],
						[60.621669267311844, 3.0036640508168784],
						[60.621841550094281, 3.0038584327223505],
						[60.621841560406416, 3.0038584417956549],
						[60.621841581030672, 3.0038584599422617],
						[60.62184159223569, 3.0038584688492449],
						[60.622119292287351, 3.0040362677168613],
						[60.622119304303553, 3.0040362746405034],
						[60.622119328254172, 3.004036286670646],
						[60.6221193410815, 3.0040362916108045],
						[60.622424940834208, 3.0041307907696875],
						[60.622424960804665, 3.0041307943794],
						[60.622425003342514, 3.004130799282692],
						[60.622425023149503, 3.0041307992580681],
						[60.622566705182265, 3.0041141016058694],
						[60.622874986905046, 3.0041334996457372],
						[60.622875044779413, 3.0041334852005059],
						[60.622875142173953, 3.0041334285777861],
						[60.622875180719511, 3.0041333847494167],
						[60.623016873830856, 3.0038278993452274],
						[60.623108404820627, 3.0036973989427072],
						[60.623466671797075, 3.0036945991220154],
						[60.623572143846026, 3.0037251912196616],
						[60.623572144820649, 3.0037251928705562],
						[60.623572145713545, 3.0037251927042203],
						[60.623572145713545, 3.0037251927042203],
						[60.623780546155466, 3.0037834923723059],
						[60.623780585040052, 3.0037834961233352],
						[60.623780661991916, 3.0037834854529368],
						[60.623780699329785, 3.0037834748323111],
						[60.623916798996333, 3.0037057740597923],
						[60.623916819042407, 3.0037057593304586],
						[60.623916853531995, 3.0037057254180572],
						[60.623916868950154, 3.0037057078859144],
						[60.623969569412452, 3.0036223067199201],
						[60.624041868626399, 3.0035084062414716],
						[60.624041883308948, 3.0035083723538034],
						[60.624041897249761, 3.0035083019545441],
						[60.6240418974009, 3.0035082652766119],
						[60.624008497194083, 3.0033193645563858],
						[60.624008477211227, 3.0033193206346671],
						[60.624008419959935, 3.0033192488390132],
						[60.624008382528011, 3.0033192173305845],
						[60.623916783369594, 3.003277617694879],
						[60.623916752439271, 3.0032776106300418],
						[60.62391668993093, 3.0032776021184708],
						[60.623916657623504, 3.0032776044725904],
						[60.623813858774895, 3.003299803932681],
						[60.623541690705046, 3.0033553987960877],
						[60.623466721854335, 3.0033470004426013],
						[60.623466717389903, 3.0033470012743306],
						[60.623466705618874, 3.003346999802381],
						[60.623466700261567, 3.003347000800455],
						[60.623386100920918, 3.0033469995708915],
						[60.623097295870302, 3.0033442014763225],
						[60.6230168618306, 3.0032442817895926],
						[60.622586266764408, 3.0025942905154923],
						[60.622586246869375, 3.0025942685686027],
						[60.622586202860134, 3.0025942309581359],
						[60.622586178745905, 3.0025942152945735],
						[60.622566812788037, 3.0025859312043748],
						[60.622561239955196, 3.0025804571542425],
						[60.622561238087663, 3.0025804556697975],
						[60.62256123524552, 3.0025804525345339],
						[60.622561233378015, 3.0025804510500822],
						[60.622119532810721, 3.0021859501514951],
						[60.622119513324378, 3.0021859372908937],
						[60.622119469076118, 3.002185914385072],
						[60.622119446181721, 3.0021859058242573],
						[60.621894446071806, 3.0021331048528452],
						[60.621894434300785, 3.0021331033813579],
						[60.621894409865874, 3.002133100604786],
						[60.621894398094867, 3.0021330991332942],
						[60.621669508844775, 3.0021358993065013],
						[60.62138076917293, 3.0016804929562624],
						[60.62138075869715, 3.0016804802495822],
						[60.621380736934462, 3.0016804568196802],
						[60.621380725565821, 3.001680444279355],
						[60.621222349104926, 3.0015526627825668],
						[60.620975165986529, 3.0011831896012682],
						[60.620975159245774, 3.0011831798635042],
						[60.620975144953128, 3.0011831623714409],
						[60.620975137401238, 3.0011831546171437],
						[60.620772369336471, 3.000991583042822],
						[60.620680784599315, 3.0007666251668024],
						[60.620680782404705, 3.0007666164142814],
						[60.620680774607514, 3.0007666032089659],
						[60.620680770708901, 3.0007665966063022],
						[60.620322371391204, 3.0001804958516041],
						[60.620322367656122, 3.0001804928831741],
						[60.620322362782844, 3.0001804846300284],
						[60.620322359858868, 3.0001804796781304],
						[60.620077959924807, 2.9998526792586442],
						[60.62007795139818, 2.9998526698542625],
						[60.620077930691664, 2.9998526498941351],
						[60.620077920379288, 2.999852640822593],
						[60.619930725279744, 2.9997415438650563],
						[60.61987796085986, 2.9996970758287107],
						[60.619511286616046, 2.9989388266669095],
						[60.619427991126656, 2.9986638420030127],
						[60.619427978128712, 2.9986638132780756],
						[60.619427941581833, 2.9986637614595404],
						[60.619427919900474, 2.9986637398500315],
						[60.619208437577107, 2.9984998526911135],
						[60.618977952541826, 2.998227670375146],
						[60.618977909833319, 2.9982276416929818],
						[60.618977816469183, 2.9982276077967414],
						[60.618977764920636, 2.9982276027491261],
						[60.618869417134476, 2.9982469936380398],
						[60.618586169126978, 2.9981470117610667],
						[60.618527870805771, 2.9981248128525269],
						[60.618527846207215, 2.9981248064452597],
						[60.618527793765786, 2.9981248015642952],
						[60.618527766815774, 2.9981248029241248],
						[60.618477767297101, 2.9981331036397809],
						[60.618477751225278, 2.998133106635994],
						[60.618477721112754, 2.9981331177462907],
						[60.61847770609738, 2.9981331242099238],
						[60.618202706057083, 2.9982804238599825],
						[60.618202705164208, 2.9982804240264334],
						[60.618202704271326, 2.9982804241928824],
						[60.618080621593904, 2.9983469602716806],
						[60.617686225689255, 2.9980276456734045],
						[60.617686221872319, 2.9980276408885733],
						[60.61768621261632, 2.998027635285561],
						[60.617686208881175, 2.998027632317612],
						[60.617630708774421, 2.9979915324299591],
						[60.617630690344228, 2.9979915230408536],
						[60.617630649101223, 2.9979915069118332],
						[60.617630628155965, 2.9979915016558727],
						[60.617611137783413, 2.9979887025177892],
						[60.617244446621946, 2.9978998061644018],
						[60.617244412283497, 2.9978998034053626],
						[60.617244344179234, 2.997899810605305],
						[60.617244310413412, 2.9978998205643039],
						[60.617183268400225, 2.9979303915706028],
						[60.617130632058931, 2.9979220022081723],
						[60.61681113311721, 2.9978692033453025],
						[60.616811121264355, 2.9978692000586662],
						[60.616811096911199, 2.9978691991023791],
						[60.616811086196634, 2.9978692010998071],
						[60.61673332523111, 2.9978747971481909],
						[60.616641765732339, 2.997844211569098],
						[60.616413968677705, 2.9977609117783177],
						[60.616413965024392, 2.9977609106273144],
						[60.616413956824871, 2.9977609084917485],
						[60.616413953171559, 2.9977609073407478],
						[60.616283381842273, 2.9977248145790472],
						[60.616069507387934, 2.9975887302201643],
						[60.616069505602141, 2.9975887305530788],
						[60.616069505602141, 2.9975887305530788],
						[60.615833409920086, 2.9974415333942765],
						[60.615397317295674, 2.9971359377516431],
						[60.615386220138653, 2.9971276393147268],
						[60.615386205361702, 2.9971276310776176],
						[60.615386174996772, 2.9971276165866074],
						[60.615386160219849, 2.9971276083494955],
						[60.615341759911843, 2.997113709665538],
						[60.61534175439094, 2.9971137070308238],
						[60.615341742619897, 2.9971137055613313],
						[60.615341738073703, 2.9971137045768952],
						[60.614936148772792, 2.9970359054899656],
						[60.614891760229419, 2.9970220085600698],
						[60.614891752922759, 2.997022006258327],
						[60.614891739284154, 2.9970220033050787],
						[60.614891732059313, 2.9970220028200583],
						[60.614486158260554, 2.9969554069866922],
						[60.614391780736703, 2.9969137164191797],
						[60.614391769776717, 2.9969137129666463],
						[60.614391747856736, 2.9969137060615436],
						[60.614391736978583, 2.9969137044257033],
						[60.614041774817935, 2.996847010760475],
						[60.61384450682614, 2.9967220310873359],
						[60.613844500330543, 2.9967220268025785],
						[60.613844487421169, 2.9967220200497411],
						[60.613844480114516, 2.9967220177481271],
						[60.613589014408895, 2.9966109325300567],
						[60.613175140609442, 2.9961998577302871],
						[60.613175134032012, 2.9961998516290911],
						[60.613175120958957, 2.9961998412433477],
						[60.613175114381541, 2.9961998351421477],
						[60.613139043999183, 2.9961748578108791],
						[60.613102965173638, 2.99612208702362],
						[60.613102962249535, 2.996122082073188],
						[60.613102955672112, 2.9961220759720213],
						[60.613102951773314, 2.9961220693714306],
						[60.612694567183034, 2.9956415888580565],
						[60.612511278370832, 2.9952860084509707],
						[60.612511274553825, 2.9952860036671836],
						[60.612511269598443, 2.9952859936001417],
						[60.612511266756137, 2.9952859904664604],
						[60.612244567108419, 2.994883189616786],
						[60.612244566215537, 2.9948831897832915],
						[60.612244566133697, 2.9948831879667246],
						[60.612244565158996, 2.9948831863166587],
						[60.611897364813871, 2.9943748877186294],
						[60.61189736091498, 2.9943748811184934],
						[60.611897352306187, 2.9943748699012991],
						[60.611897346621568, 2.9943748636341909],
						[60.611794547317253, 2.9942637642299372],
						[60.611794544556787, 2.9942637629129338],
						[60.611794542607349, 2.9942637596128905],
						[60.611794540821585, 2.9942637599459281],
						[60.611691840701518, 2.9941609590996041],
						[60.611691834042148, 2.9941609511825611],
						[60.611691819101381, 2.9941609393145789],
						[60.611691810738108, 2.9941609335471098],
						[60.611347324785513, 2.9939304434897638],
						[60.610939037193894, 2.9935470542314051],
						[60.610939024120668, 2.9935470438474132],
						[60.610938998137946, 2.9935470267124038],
						[60.6109389842537, 2.9935470183114306],
						[60.61089732096297, 2.9935276350512221],
						[60.610836247638296, 2.9934609657967584],
						[60.610447363763782, 2.9930248838741842],
						[60.610183477640376, 2.9925471086340347],
						[60.610000185353712, 2.9921610231610569],
						[60.609811289576434, 2.9916360382033123],
						[60.609550192062052, 2.9907610435147336],
						[60.6095390923671, 2.9907221453094146],
						[60.609539090253804, 2.9907221383769924],
						[60.609539085134294, 2.9907221246787765],
						[60.609539083102888, 2.990722119562756],
						[60.609427982597971, 2.9904721185141958],
						[60.609427981705089, 2.9904721186808039],
						[60.609427981623199, 2.9904721168644106],
						[60.609427981623199, 2.9904721168644106],
						[60.609122381919754, 2.9898082165068316],
						[60.609122379970231, 2.9898082132073087],
						[60.609122377938782, 2.989808208091429],
						[60.609122376071134, 2.9898082066083029],
						[60.609100175846123, 2.9897666056057144],
						[60.609100174789454, 2.9897666021395901],
						[60.609100171947034, 2.989766599006717],
						[60.609100170890365, 2.9897665955405772],
						[60.60900577138753, 2.9896109959585462],
						[60.609005768545103, 2.9896109928256949],
						[60.609005764646, 2.9896109862267188],
						[60.609005762778359, 2.9896109847436048],
						[60.608650165970062, 2.9891081884922888],
						[60.60851686928202, 2.9888970935727524],
						[60.608202972622166, 2.9883970989959052],
						[60.607977979235393, 2.9879832108522719],
						[60.607752984756928, 2.9874943249096919],
						[60.60759738861065, 2.9870693352029094],
						[60.607322390947601, 2.9861916398384492],
						[60.607322389890832, 2.986191636372622],
						[60.607322387859234, 2.9861916312572636],
						[60.607322386802458, 2.986191627791436],
						[60.607308486549094, 2.9861555276920342],
						[60.607308481593066, 2.9861555176280148],
						[60.607308472655802, 2.9861554991495201],
						[60.60730846680692, 2.9861554892522104],
						[60.607302975082227, 2.9861471022307642],
						[60.606869579828206, 2.9852443130572559],
						[60.606869578853377, 2.9852443114077571],
						[60.60685847912535, 2.9852221107628742],
						[60.606858476200841, 2.9852221058143686],
						[60.606858470269934, 2.9852220941011325],
						[60.606858466452593, 2.9852220893193726],
						[60.606808466066262, 2.9851470895937067],
						[60.606808465009458, 2.9851470861279701],
						[60.606808462166931, 2.985147082995721],
						[60.606808460217287, 2.9851470796967177],
						[60.606408466707151, 2.9846109897613533],
						[60.606244573218177, 2.9843304998030402],
						[60.606244572161359, 2.9843304963374062],
						[60.606244569318832, 2.9843304932052792],
						[60.606244568343982, 2.9843304915558271],
						[60.605958467893998, 2.983885991243056],
						[60.605958467001138, 2.9838859914098208],
						[60.605958465944319, 2.983885987944213],
						[60.605958464969476, 2.9838859862948035],
						[60.605636265012073, 2.9834165867842097],
						[60.605636263062387, 2.983416583485432],
						[60.605636258270138, 2.9834165770546632],
						[60.605636256402441, 2.9834165755720585],
						[60.605511255904688, 2.9832609750715591],
						[60.605511252087283, 2.9832609702902277],
						[60.605511244534483, 2.9832609625436928],
						[60.605511240799046, 2.9832609595784949],
						[60.605355741267871, 2.9831054585687142],
						[60.605355741267871, 2.9831054585687142],
						[60.605061240964424, 2.9828137572340681],
						[60.605061229758164, 2.9828137483387747],
						[60.605061207345649, 2.9828137305481701],
						[60.605061194435649, 2.9828137238025811],
						[60.604655693730543, 2.982597024793634],
						[60.604655688127423, 2.9825970203460836],
						[60.604655676192294, 2.9825970152499597],
						[60.604655669778296, 2.982597012785305],
						[60.604611170437423, 2.9825804127705857],
						[60.604611170437423, 2.9825804127705857],
						[60.604611168569697, 2.9825804112880752],
						[60.604405668626185, 2.9825054119573498],
						[60.604405663997909, 2.9825054091591419],
						[60.604405654012481, 2.9825054073616895],
						[60.604405649466209, 2.9825054063795475],
						[60.604161150955711, 2.9824442056092857],
						[60.604130652900537, 2.9824359073870492],
						[60.604130642915102, 2.9824359055896319],
						[60.604130622944254, 2.9824359019947724],
						[60.604130612958841, 2.9824359001973493],
						[60.603713917131756, 2.9824081004669041],
						[60.60366112131922, 2.982402601511347],
						[60.603661117665787, 2.9824026003624682],
						[60.603661114012368, 2.9824025992135832],
						[60.603661111333793, 2.9824025997139545],
						[60.603263918710198, 2.9823804018158135],
						[60.603200026083272, 2.982372001938526],
						[60.603200025190425, 2.9823720021053126],
						[60.603200023322714, 2.9823720006228553],
						[60.603200022429846, 2.9823720007896442],
						[60.602897222116887, 2.9823387011672748],
						[60.6028972175706, 2.9823387001852084],
						[60.602897211238613, 2.9823386995367134],
						[60.602897206774308, 2.9823387003706507],
						[60.602816709943959, 2.9823359002266341],
						[60.602730614092287, 2.9823303991205847],
						[60.602463914969412, 2.982310901747308],
						[60.602463904983999, 2.9823108999499879],
						[60.602463887044884, 2.9823109014697047],
						[60.602463877141453, 2.98231090148836],
						[60.602366677279221, 2.9823220004623447],
						[60.602366674682656, 2.9823220027786674],
						[60.602366670136384, 2.9823220017966112],
						[60.602366666564947, 2.9823220024637496],
						[60.602283267429719, 2.9823359037386066],
						[60.602283265562008, 2.9823359022561933],
						[60.601974965703128, 2.9823887022709554],
						[60.601974958560277, 2.9823887036051886],
						[60.601974944356584, 2.9823887080896205],
						[60.601974937213733, 2.9823887094238519],
						[60.601916643321232, 2.9824081081667368],
						[60.601874948755025, 2.9824192072774425],
						[60.60153325030322, 2.9825081060830323],
						[60.601533247624644, 2.982508106583353],
						[60.601533244053208, 2.9825081072504447],
						[60.601533242267507, 2.9825081075839885],
						[60.601477747361542, 2.982524807474249],
						[60.601466675374738, 2.98252760072205],
						[60.601458299913133, 2.9825275994194316],
						[60.601458282866851, 2.9825276007721597],
						[60.6014582489383, 2.9825276071094882],
						[60.601458232056018, 2.9825276120940885],
						[60.60109433217206, 2.98265811264252],
						[60.601094331279207, 2.9826581128092853],
						[60.601094330304342, 2.9826581111601533],
						[60.601019343370794, 2.9826859078707049],
						[60.60092215487154, 2.9827081054414912],
						[60.600922146835821, 2.9827081069423609],
						[60.600922129060692, 2.9827081120935284],
						[60.600922121106962, 2.9827081154102877],
						[60.60071662069371, 2.9827970164447857],
						[60.600716619800849, 2.982797016611543],
						[60.600569319699147, 2.9828609171188072],
						[60.600569317020557, 2.982860917619079],
						[60.600569309959702, 2.9828609207690184],
						[60.600569307363124, 2.9828609230851573],
						[60.600352706645374, 2.9829748232868352],
						[60.600352704859667, 2.9829748236203368],
						[60.600352699584491, 2.9829748264367111],
						[60.600352696987926, 2.9829748287528242],
						[60.600338798732047, 2.9829831266942026],
						[60.600119301073399, 2.983108126481453],
						[60.600119299287684, 2.9831081268149453],
						[60.600119295798237, 2.9831081292977806],
						[60.600119294905376, 2.9831081294645285],
						[60.600002696259132, 2.9831804282673557],
						[60.599699896170478, 2.9833637295990911],
						[60.599699891788184, 2.9833637322485997],
						[60.599699882212704, 2.9833637395301711],
						[60.599699876937528, 2.9833637423464179],
						[60.599674903599585, 2.9833831209909203],
						[60.599658235850626, 2.9833887092868774],
						[60.599658226193164, 2.9833887147526128],
						[60.599658206796235, 2.9833887238682797],
						[60.599658197138773, 2.9833887293340138],
						[60.599602697142622, 2.983422028513925],
						[60.599602695438875, 2.9834220306632151],
						[60.599602689270867, 2.9834220336461725],
						[60.599602686674274, 2.983422035962195],
						[60.599319286549985, 2.9836165345403129],
						[60.599319284846267, 2.9836165366895688],
						[60.599319283142535, 2.9836165388388256],
						[60.599319282249674, 2.9836165390055536],
						[60.599224910889674, 2.9836859176033292],
						[60.599074945637099, 2.9837276062907998],
						[60.599074935979623, 2.9837276117563247],
						[60.599074917311562, 2.9837276170733027],
						[60.599074906761224, 2.9837276227055489],
						[60.598958206638933, 2.983788723808821],
						[60.598777735668122, 2.9838831079761667],
						[60.598572168115069, 2.9839165037585054],
						[60.598572166247365, 2.9839165022761729],
						[60.598572162757911, 2.9839165047587883],
						[60.598572160890214, 2.9839165032764527],
						[60.59853056053575, 2.9839248036828425],
						[60.598530549010526, 2.9839248076658786],
						[60.598530526771043, 2.9839248136494674],
						[60.598530516220677, 2.9839248192815626],
						[60.59832772602109, 2.9840192139180117],
						[60.598194336639452, 2.9840637098580602],
						[60.59797773732906, 2.9841359104753491],
						[60.597977729375295, 2.984135913791413],
						[60.597977714360617, 2.9841359202568531],
						[60.597977707299727, 2.9841359234062104],
						[60.597877707223674, 2.9841887227158148],
						[60.597877701948491, 2.9841887255317467],
						[60.597877692372947, 2.9841887328126342],
						[60.597877687097743, 2.9841887356285732],
						[60.597824889364617, 2.9842248336936272],
						[60.597652691927557, 2.9843359314229563],
						[60.597652685841489, 2.98433593622125],
						[60.59765267366933, 2.9843359458178575],
						[60.59765266839414, 2.9843359486337455],
						[60.597497068252089, 2.9844720488249359],
						[60.597497068334064, 2.984472050640639],
						[60.597427667915362, 2.9845331498408325],
						[60.597427666211601, 2.9845331519899103],
						[60.597427661911226, 2.9845331564547743],
						[60.597427660207465, 2.9845331586038513],
						[60.597241559797318, 2.984716558275589],
						[60.597241558011604, 2.9847165586089539],
						[60.597241556307843, 2.9847165607580108],
						[60.597241554604082, 2.9847165629070642],
						[60.597194255423652, 2.9847665627373994],
						[60.596980458126829, 2.9849887595095672],
						[60.596894263325645, 2.9850720538114963],
						[60.596702665298501, 2.9852470511132565],
						[60.596702656779662, 2.9852470618582072],
						[60.596702642174719, 2.9852470774011657],
						[60.596702635441616, 2.9852470878128052],
						[60.596602634603592, 2.9853942855805058],
						[60.596530452890597, 2.9854970617190029],
						[60.596333176295218, 2.9856526424446201],
						[60.596333171994829, 2.9856526469091738],
						[60.596333163394014, 2.9856526558382486],
						[60.596333159011628, 2.985652658487147],
						[60.596297058763852, 2.9856887592763091],
						[60.596297056978123, 2.9856887596096082],
						[60.596297055274334, 2.9856887617585501],
						[60.596297054463435, 2.9856887637408396],
						[60.596083171290836, 2.9859165443314084],
						[60.595991592995993, 2.9859748301154725],
						[60.595991592185094, 2.9859748320977348],
						[60.59599159039935, 2.9859748324310149],
						[60.595991588695576, 2.9859748345799164],
						[60.595713789294301, 2.9861609340982738],
						[60.595713782315272, 2.9861609390625512],
						[60.595713769250118, 2.9861609488244891],
						[60.595713762353022, 2.9861609556043809],
						[60.59565816506327, 2.986213753537649],
						[60.595633184714522, 2.9862359353759462],
						[60.595586005990256, 2.9862609233506121],
						[60.595585998929309, 2.9862609264992406],
						[60.595585985864112, 2.986260936261103],
						[60.595585978885111, 2.9862609412253502],
						[60.595330479925067, 2.9864554394188936],
						[60.595183202958182, 2.9865637234921842],
						[60.594974933719939, 2.9866442112228078],
						[60.594938841146011, 2.9866554081206242],
						[60.594938828809767, 2.9866554140843506],
						[60.594938805841117, 2.9866554238630183],
						[60.594938794397756, 2.9866554296601269],
						[60.59473320303119, 2.9867831246358061],
						[60.594619313111934, 2.9868387193448358],
						[60.594336013444554, 2.9869748202817261],
						[60.594336009955022, 2.9869748227636617],
						[60.594336002001185, 2.9869748260786033],
						[60.594335999404514, 2.9869748283939552],
						[60.594288807634143, 2.9870026214022469],
						[60.594258216701448, 2.9870165186141961],
						[60.594258215808587, 2.9870165187807927],
						[60.594258214915719, 2.9870165189473945],
						[60.594258214022858, 2.9870165191139892],
						[60.594136014077897, 2.9870748196773214],
						[60.594136006205979, 2.9870748248077361],
						[60.594135988594466, 2.9870748335862536],
						[60.594135981615416, 2.9870748385500723],
						[60.593916582854163, 2.9872359378506195],
						[60.593838802317954, 2.9872915239978792],
						[60.593705523234284, 2.987347014341871],
						[60.593705517066162, 2.9873470173234709],
						[60.593705504729897, 2.9873470232866945],
						[60.593705498643686, 2.9873470280837955],
						[60.593563798999753, 2.987430428412261],
						[60.593388820751251, 2.987533113845573],
						[60.593191646097338, 2.9875887071316982],
						[60.593191645204463, 2.9875887072982716],
						[60.593191643418734, 2.9875887076314243],
						[60.593191641714917, 2.9875887097800504],
						[60.593127741797439, 2.9876081097569962],
						[60.593127732868773, 2.9876081114227446],
						[60.593127716068182, 2.98760811821862],
						[60.59312770730336, 2.9876081235153089],
						[60.592941623345624, 2.9877054143393447],
						[60.592819339927679, 2.98774420947603],
						[60.592572139945219, 2.9878220098939385],
						[60.592572131098493, 2.9878220133750264],
						[60.592572111619276, 2.9878220206703361],
						[60.592572102772529, 2.9878220241514271],
						[60.592491603360976, 2.9878665250890379],
						[60.592491601575261, 2.9878665254221639],
						[60.592491599871437, 2.987866527570727],
						[60.592491598978583, 2.9878665277372871],
						[60.59244429929862, 2.9878942261179247],
						[60.592280499883366, 2.9879887271225138],
						[60.59228049201139, 2.9879887322523899],
						[60.59228047716033, 2.98798874234559],
						[60.592280469370273, 2.9879887492908863],
						[60.592113770304408, 2.98813314759381],
						[60.592044275592706, 2.9881915432619484],
						[60.59188318131369, 2.9883109394020249],
						[60.591883175227444, 2.9883109441986573],
						[60.59188316394782, 2.98831095362539],
						[60.591883158754449, 2.9883109582554734],
						[60.591797060336042, 2.9883970581186077],
						[60.591594265288641, 2.9885942533213647],
						[60.591488770144679, 2.9886859483284978],
						[60.591235971175458, 2.9888998469359196],
						[60.591235964278219, 2.9888998537142508],
						[60.591235951376603, 2.9888998671043647],
						[60.591235943586511, 2.9888998740492014],
						[60.591177643717302, 2.9889720738859511],
						[60.591177643799199, 2.9889720757013163],
						[60.591144248717647, 2.9890137671890851],
						[60.591038755230244, 2.9891248619951636],
						[60.59103875441928, 2.9891248639770556],
						[60.591038750929712, 2.9891248664584862],
						[60.59103875003683, 2.9891248666250068],
						[60.590888750109201, 2.9892942662163775],
						[60.590888750191091, 2.9892942680317258],
						[60.590888748405362, 2.9892942683647514],
						[60.590888747594398, 2.9892942703466132],
						[60.590694258305469, 2.9895220578244457],
						[60.590594269554607, 2.9896081472791169],
						[60.590355469795789, 2.9898137486258411],
						[60.590355464602339, 2.9898137532554574],
						[60.59035545510833, 2.9898137623482022],
						[60.590355450807778, 2.9898137668113161],
						[60.59028595128251, 2.9898915678129425],
						[60.590285950389649, 2.9898915679794338],
						[60.590247069522427, 2.9899359441905102],
						[60.590163797157871, 2.9899859287745727],
						[60.590163790178664, 2.989985933737108],
						[60.590163775327419, 2.9899859438286844],
						[60.590163768348241, 2.9899859487912148],
						[60.589966568110832, 2.9901581488144271],
						[60.589966568192722, 2.9901581506297439],
						[60.589966567299854, 2.9901581507962272],
						[60.589797092004574, 2.9903081275740342],
						[60.589624922666928, 2.9903804166561465],
						[60.589624921774046, 2.9903804168226222],
						[60.589624920799316, 2.9903804151738109],
						[60.589624919988317, 2.9903804171555683],
						[60.589491620184432, 2.9904387164689434],
						[60.58949160963374, 2.990438722097156],
						[60.589491591210972, 2.9904387328541824],
						[60.58949158244603, 2.990438738149451],
						[60.589347098149268, 2.990544226602359],
						[60.589274916326275, 2.9905776187323991],
						[60.589274916326275, 2.9905776187323991],
						[60.589274915433393, 2.9905776188988678],
						[60.589274913647664, 2.9905776192317988],
						[60.588958214311347, 2.9907276184496965],
						[60.58895821082173, 2.990727620930786],
						[60.588958204653515, 2.9907276239112512],
						[60.588958202056759, 2.9907276262258824],
						[60.588913801940969, 2.9907526255580965],
						[60.588913795772747, 2.9907526285385528],
						[60.588913786196784, 2.9907526358153391],
						[60.588913780110431, 2.9907526406110576],
						[60.588902698319728, 2.9907609266777651],
						[60.588877718735283, 2.9907720178850532],
						[60.588877708184576, 2.9907720235130393],
						[60.588877687083162, 2.9907720347690279],
						[60.588877676614345, 2.9907720422122543],
						[60.58858597828123, 2.9909998402544034],
						[60.588449879207708, 2.9911026395190565],
						[60.588449871417488, 2.9911026464627297],
						[60.588449855918874, 2.9911026621653312],
						[60.588449848128654, 2.9911026691090017],
						[60.588283148300768, 2.9912970704567274],
						[60.588024857329223, 2.9915998603602572],
						[60.587999866853188, 2.991622050654013],
						[60.587999849732505, 2.991622070319381],
						[60.587999821904972, 2.9916221121155049],
						[60.587999810387124, 2.9916221362278783],
						[60.587997009995874, 2.9916305364985982],
						[60.587994212809939, 2.9916388281733872],
						[60.587913716977035, 2.9918221203632624],
						[60.587913716084152, 2.9918221205296858],
						[60.587913716166021, 2.9918221223448769],
						[60.587788716102281, 2.9921166214953026],
						[60.58759981623276, 2.9925527207012981],
						[60.587599814610705, 2.9925527246644514],
						[60.587599812259498, 2.9925527324243664],
						[60.587599809744596, 2.9925527365539182],
						[60.587580409968432, 2.9926110361282308],
						[60.587580410132126, 2.992611039758597],
						[60.587580408428238, 2.9926110419065659],
						[60.587580407617224, 2.9926110438881381],
						[60.587555409350905, 2.9926971402890321],
						[60.58740261009212, 2.99315823757372],
						[60.58740261009212, 2.99315823757372],
						[60.587302610848184, 2.9934666355562132],
						[60.587219211712963, 2.9936999338968131],
						[60.587219210820095, 2.9936999340631782],
						[60.587105410560397, 2.9940249334521614],
						[60.587105410642231, 2.9940249352673307],
						[60.587105409831175, 2.9940249372488412],
						[60.587035911155603, 2.9942360355796747],
						[60.586985911860559, 2.9943777322923522],
						[60.586844212054856, 2.9947666319281803],
						[60.586660911566369, 2.9952777323355164],
						[60.586660910026048, 2.9952777381135656],
						[60.586660906216181, 2.9952777534662296],
						[60.586660903864782, 2.9952777612257311],
						[60.586658108646667, 2.9952916344775886],
						[60.586655420310407, 2.9952971120272149],
						[60.586655416984307, 2.9952971181378873],
						[60.586655411388683, 2.995297133823196],
						[60.58665540903727, 2.9952971415826801],
						[60.586485909289252, 2.9958582423730693],
						[60.586485908396369, 2.9958582425393732],
						[60.586485908396369, 2.9958582425393732],
						[60.586383107781472, 2.9962027427286309],
						[60.586383107863291, 2.996202744543766],
						[60.586319207956926, 2.996427744197832],
						[60.586208108295914, 2.9968166454127947],
						[60.58620810659194, 2.9968166475604523],
						[60.586208106673745, 2.996816649375563],
						[60.586208105862639, 2.9968166513569492],
						[60.58615810560844, 2.997013850798381],
						[60.586158105690231, 2.9970138526134895],
						[60.586158105690231, 2.9970138526134895],
						[60.586133107190172, 2.9971166469271844],
						[60.586016509245738, 2.9974999411856569],
						[60.58601650762354, 2.9974999451483857],
						[60.586016506164917, 2.9974999527413022],
						[60.586016505353811, 2.9974999547226773],
						[60.585994204929214, 2.997597153799247],
						[60.585888707110165, 2.9980277471747927],
						[60.585838709123863, 2.9981888408215154],
						[60.585758110292609, 2.9984388389596663],
						[60.585758109399727, 2.9984388391258934],
						[60.585758108588607, 2.9984388411072103],
						[60.585758108670383, 2.9984388429223188],
						[60.585663709436041, 2.9987499421627519],
						[60.585605410911896, 2.9989416347220677],
						[60.585474813359156, 2.9992805282122257],
						[60.585474813359156, 2.9992805282122257],
						[60.585474813440918, 2.9992805300273044],
						[60.585308112867054, 2.9997249302528561],
						[60.585308111974179, 2.9997249304190432],
						[60.585308111974179, 2.9997249304190432],
						[60.585280411923733, 2.9997999316633508],
						[60.585280411194354, 2.9997999354596896],
						[60.58528040794981, 2.9997999433847262],
						[60.585280407302172, 2.9997999489961176],
						[60.585266508928463, 2.9998527387157656],
						[60.585127612735263, 3.0002193291743113],
						[60.585127611924108, 3.0002193311555465],
						[60.585127610383573, 3.0002193369330947],
						[60.585127608761297, 3.0002193408955611],
						[60.585094210321429, 3.0003249369119098],
						[60.584938713138634, 3.0007666297844744],
						[60.584902613978805, 3.000858227754966],
						[60.58490261316765, 3.000858229736187],
						[60.584902610734183, 3.0008582356798024],
						[60.5849026091119, 3.0008582396422114],
						[60.5848609092765, 3.0009888392123871],
						[60.5848609092765, 3.0009888392123871],
						[60.584727608864533, 3.001411039438179],
						[60.584644209926125, 3.0016777386237208],
						[60.584549810708715, 3.0019582367501192],
						[60.584410910664943, 3.002363835832623],
						[60.584410910664943, 3.002363835832623],
						[60.584410909853752, 3.0023638378137627],
						[60.584410909853752, 3.0023638378137627],
						[60.584366510998862, 3.0024999336725586],
						[60.584172013144602, 3.0030166289760092],
						[60.584069212830826, 3.0032888292118782],
						[60.584069212830826, 3.0032888292118782],
						[60.584069212019649, 3.0032888311929713],
						[60.584069212019649, 3.0032888311929713],
						[60.583991512029357, 3.0035027323421946],
						[60.583991512029357, 3.0035027323421946],
						[60.583991511218173, 3.0035027343232761],
						[60.583991510406982, 3.0035027363043776],
						[60.58398041603548, 3.0035360194848533],
						[60.583960924024154, 3.0035721054262554],
						[60.583960922320067, 3.0035721075734063],
						[60.583960918993597, 3.0035721136827336],
						[60.583960918264104, 3.0035721174788201],
						[60.583763718228191, 3.0040082167419362],
						[60.583572021753582, 3.0044166083795432],
						[60.583541525461378, 3.0044721044239533],
						[60.583541523675599, 3.0044721047560419],
						[60.58354152286438, 3.0044721067370737],
						[60.58354152286438, 3.0044721067370737],
						[60.5835137233681, 3.0045249067908042],
						[60.583513721663998, 3.0045249089378774],
						[60.583513720852778, 3.0045249109189061],
						[60.583513720934477, 3.0045249127339071],
						[60.583316521315844, 3.0049249104010847],
						[60.583113722123606, 3.0053277083530112],
						[60.583088723352958, 3.0053749056561836],
						[60.583063722942086, 3.0054221068480391],
						[60.583063723023763, 3.005422108663002],
						[60.583063721319633, 3.0054221108099957],
						[60.583063721401324, 3.0054221126249563],
						[60.582863720991718, 3.0058277108557632],
						[60.582655424840354, 3.0062416028261025],
						[60.582633129593816, 3.0062776941857505],
						[60.582633128782568, 3.0062776961666819],
						[60.58263312797132, 3.0062776981476174],
						[60.582633127078438, 3.0062776983136024],
						[60.582613726970358, 3.0063109985389453],
						[60.582613727052014, 3.006311000353886],
						[60.582613726240794, 3.0063110023348125],
						[60.582613725347883, 3.0063110025008011],
						[60.582394226369424, 3.0067055012186992],
						[60.582327625689963, 3.0068221002945159],
						[60.582327625771626, 3.0068221021094601],
						[60.582327624067489, 3.0068221042563277],
						[60.582327624149137, 3.0068221060712572],
						[60.58216922805039, 3.0071192973287157],
						[60.582158132875868, 3.0071359893735008],
						[60.58215813044211, 3.0071359953161423],
						[60.582158123707181, 3.00713600571844],
						[60.582158121273423, 3.0071360116611001],
						[60.582149822712978, 3.0071527054714249],
						[60.581919225833147, 3.0075610021495174],
						[60.581719226911829, 3.0079165992289285],
						[60.581708448141512, 3.0079345825351882],
						[60.580052957025877, 3.0058442759785575],
						[60.580052953209297, 3.0058442711980828],
						[60.580052943790328, 3.0058442619691221],
						[60.580052939973726, 3.0058442571886697],
						[60.577800140241713, 3.0036331566434495],
						[60.577800131878924, 3.0036331508790561],
						[60.577800115071568, 3.0036331375355827],
						[60.577800105734156, 3.0036331301225445],
						[60.575777905794638, 3.0023692302544882],
						[60.575777896538881, 3.0023692246567908],
						[60.575777878109065, 3.0023692152759627],
						[60.575777868934999, 3.0023692114928129],
						[60.57275006930923, 3.0012637114623435],
						[60.57275005201739, 3.0012637073599837],
						[60.572750016704305, 3.0012637029500828],
						[60.572750000387103, 3.0012637004960303],
						[60.56948333083966, 3.0012637002214571],
						[60.566997259630746, 3.0004748090341784],
						[60.566997254191676, 3.0004748082163886],
						[60.566997239578505, 3.0004748036168749],
						[60.566997234139443, 3.0004748027990868],
						[60.56513613393129, 3.0001581022720454],
						[60.565136088148691, 3.0001581052982118],
						[60.565136003732754, 3.0001581301416231],
						[60.565135964206519, 3.0001581521249419],
						[60.564280464117687, 3.0009498524963072],
						[60.564280447158751, 3.0009498757694213],
						[60.564280417705362, 3.0009499214855917],
						[60.564280407160105, 3.0009499472244197],
						[60.564270648017249, 3.0009859803479397],
						[60.564213959665331, 3.0009720084548093],
						[60.564091777178959, 3.0009248157223887],
						[60.563763982268618, 3.0007776183284016],
						[60.563763965787942, 3.0007776122472865],
						[60.563763932990085, 3.0007776037127964],
						[60.56376391577998, 3.0007776014254204],
						[60.563561115542626, 3.0007609002123452],
						[60.563561107424896, 3.0007608998925952],
						[60.563561088592436, 3.000760901564989],
						[60.56356107958181, 3.0007609014112511],
						[60.56331388173443, 3.0007859007300817],
						[60.563130584723055, 3.0007998018636006],
						[60.563130582855557, 3.0007998003817855],
						[60.563130581069764, 3.0007998007138008],
						[60.563130579284, 3.0007998010458148],
						[60.562866678805065, 3.0008276012523996],
						[60.562702780362457, 3.000844201685537],
						[60.562702779387848, 3.0008442000377333],
						[60.56257217930554, 3.0008581011300599],
						[60.562416680377368, 3.000874800302507],
						[60.56227498231965, 3.0008887012399561],
						[60.562099984026474, 3.0009026018219278],
						[60.562099982158976, 3.0009026003401518],
						[60.562099977694523, 3.0009026011701416],
						[60.562099975015848, 3.0009026016681362],
						[60.561966678387186, 3.0009192018423838],
						[60.561847182423982, 3.0009304013203626],
						[60.561519391608833, 3.0009581003483472],
						[60.561413899632853, 3.0009581000737566],
						[60.561413896061296, 3.0009581007377237],
						[60.56141388794353, 3.0009581004179013],
						[60.561413884371966, 3.0009581010818653],
						[60.56106941324672, 3.0009858988238984],
						[60.560966741635326, 3.0009637052101756],
						[60.56096672710391, 3.0009637024248637],
						[60.560966694632924, 3.0009637011455945],
						[60.560966679290324, 3.0009637003399821],
						[60.560619456698788, 3.0009997925746581],
						[60.560489019405182, 3.0009026393876108],
						[60.560488981734274, 3.0009026226151674],
						[60.56048890436751, 3.000902604077706],
						[60.56048886207472, 3.0009026046243656],
						[60.560169516687168, 3.0009636741729935],
						[60.559850182106899, 3.0002610161193397],
						[60.559850166676497, 3.0002609933842779],
						[60.55985012964716, 3.0002609508897882],
						[60.559850109022854, 3.0002609327780205],
						[60.559722309389429, 3.0001776323348368],
						[60.559722298266117, 3.0001776252586536],
						[60.559722275290014, 3.0001776148995836],
						[60.559722263355518, 3.0001776098030382],
						[60.559672263227981, 3.0001609105401097],
						[60.559672245043274, 3.0001609066055885],
						[60.559672206969736, 3.0001609008822179],
						[60.559672188055572, 3.0001609007409886],
						[60.55927223581461, 3.0001858972859412],
						[60.559227879973399, 3.000166517568756],
						[60.559227856186119, 3.000166509189425],
						[60.559227807234478, 3.0001665018308521],
						[60.559227781988334, 3.0001665010379748],
						[60.558827865148864, 3.0002025928109441],
						[60.558777928426387, 3.00016094666934],
						[60.558777888806183, 3.0001609266033391],
						[60.558777806811456, 3.000160905271211],
						[60.558777762569392, 3.0001609025234917],
						[60.55837512018681, 3.0002358730619036],
						[60.557986283155842, 2.999347120088494],
						[60.557986269592824, 2.9993470988366511],
						[60.557986239869827, 2.9993470586445246],
						[60.557986222005809, 2.9993470418498229],
						[60.557925132363906, 2.999299849492127],
						[60.557880741251616, 2.9992554593536012],
						[60.557880701386054, 2.9992554338486044],
						[60.557880612814046, 2.999255406427217],
						[60.557880565811629, 2.999255402365248],
						[60.55748071381295, 2.9993247770942322],
						[60.557044579635352, 2.9984360115383453],
						[60.557044570945379, 2.9984359985247568],
						[60.557044551779647, 2.9984359728296264],
						[60.557044542115023, 2.9984359581685744],
						[60.557030742204113, 2.9984220587648749],
						[60.557030729131434, 2.998422048394938],
						[60.557030702174963, 2.9984220296345563],
						[60.557030688209373, 2.9984220194306546],
						[60.557019488184601, 2.9984165203405411],
						[60.557019461718582, 2.9984165124610769],
						[60.557019409924713, 2.9984165019765867],
						[60.557019382892811, 2.9984165015171094],
						[60.556794382548723, 2.9984359002339747],
						[60.556794300579099, 2.9984359593641097],
						[60.556794227570052, 2.9984361174050029],
						[60.556794238398126, 2.9984362177971926],
						[60.557030431013906, 2.9987640075774107],
						[60.557349825129229, 2.9993472965046277],
						[60.557349829027736, 2.9993473030946167],
						[60.557349837799357, 2.9993473179220764],
						[60.557349843401894, 2.9993473223665501],
						[60.557480442875097, 2.9995140238822664],
						[60.557480446691855, 2.9995140286587771],
						[60.557480456192884, 2.9995140396933309],
						[60.557480460902532, 2.9995140443038357],
						[60.557633156711773, 2.9996612394526387],
						[60.557924853337994, 2.9999751357738966],
						[60.557924857154724, 2.9999751405505282],
						[60.557924864706507, 2.999975148290261],
						[60.5579248693344, 2.9999751510873769],
						[60.558255469036226, 3.0002612509491104],
						[60.558255470903745, 3.0002612524306866],
						[60.558255473745852, 3.0002612555598303],
						[60.558255475613358, 3.0002612570414056],
						[60.558374876193767, 3.000355756954034],
						[60.558374877168376, 3.0003557586016107],
						[60.558374878954169, 3.0003557582696332],
						[60.558374879928763, 3.0003557599172099],
						[60.558452679917387, 3.0004140597261597],
						[60.558827670800184, 3.0006973533523165],
						[60.559227663132567, 3.001075145358918],
						[60.559227664107176, 3.0010751470065706],
						[60.559227666867571, 3.0010751483222635],
						[60.559227667842151, 3.0010751499699055],
						[60.559272059944988, 3.001114043046675],
						[60.55932481236362, 3.0011722891614476],
						[60.559349802385434, 3.0013278314602241],
						[60.559466490330557, 3.0020832522694829],
						[60.559352689850023, 3.0022470075699617],
						[60.559272185801092, 3.0022526000394425],
						[60.559272165264503, 3.0022526038560988],
						[60.559272124354735, 3.0022526151166566],
						[60.559272104063254, 3.0022526243741883],
						[60.558986051571338, 3.0024080973969363],
						[60.558827807038334, 3.0024025994311287],
						[60.558827793563218, 3.0024026001065507],
						[60.558827769373359, 3.0024026027731723],
						[60.558827756872837, 3.0024026050962527],
						[60.558563884989852, 3.0024608976362339],
						[60.558375016053162, 3.0024470016825808],
						[60.558094419590638, 3.0024220001628246],
						[60.557925047047554, 3.0024026033238234],
						[60.557566770275109, 3.0022692124089772],
						[60.557566759315286, 3.0022692089595422],
						[60.557566736584434, 3.0022692040401466],
						[60.557566725706302, 3.0022692024042521],
						[60.557480625661221, 3.0022581012194611],
						[60.557480621115047, 3.0022581002355859],
						[60.557480611211503, 3.0022581002472752],
						[60.557480605772433, 3.0022580994293331],
						[60.557391820458783, 3.002255404595962],
						[60.557297375407416, 3.0020832047279877],
						[60.557297364768601, 3.0020831884181485],
						[60.557297340893946, 3.0020831581097491],
						[60.55729732684695, 3.0020831460906572],
						[60.557030751765033, 3.0018637669288593],
						[60.556600173045013, 3.0011721000038887],
						[60.556580776080175, 3.0011360050259865],
						[60.556580725412644, 3.0011359595834577],
						[60.55658060533861, 3.0011359124116708],
						[60.556580535932142, 3.0011359106824096],
						[60.556563929172746, 3.0011414787168973],
						[60.556130711095669, 3.000852634525383],
						[60.556130674399398, 3.000852619402441],
						[60.55613059808897, 3.000852604327922],
						[60.556130557581938, 3.0008526045423052],
						[60.555988858418466, 3.0008831035008932],
						[60.555988847785478, 3.00088310730581],
						[60.555988827412342, 3.0008831147497075],
						[60.555988816779355, 3.0008831185546239],
						[60.555683225580268, 3.0010220135453243],
						[60.555619334240347, 3.0010442106834101],
						[60.555619329857592, 3.0010442133265913],
						[60.555619321903258, 3.0010442166335545],
						[60.555619318331686, 3.0010442172973573],
						[60.555333218045732, 3.0011720167821627],
						[60.555333177305378, 3.0011720517825338],
						[60.555333117341711, 3.0011721397273603],
						[60.555333099822491, 3.001172190526511],
						[60.555324800148099, 3.0013527915836047],
						[60.555324800311503, 3.001352795210396],
						[60.55532479990886, 3.0013528062567265],
						[60.555324800153983, 3.0013528116969286],
						[60.555363699276647, 3.0020832793717958],
						[60.55531920563287, 3.002258251748688],
						[60.555319205714575, 3.0022582535620859],
						[60.555319204821664, 3.0022582537280038],
						[60.55523310621286, 3.0026193521175712],
						[60.555172005987146, 3.0028666509691169],
						[60.555138711959316, 3.0029943307288347],
						[60.554960920762326, 3.003355512385915],
						[60.554783121444196, 3.0037166127565333],
						[60.554783117224716, 3.0037166190260276],
						[60.554783110734924, 3.0037166348599968],
						[60.554783109275824, 3.0037166424452484],
						[60.554747008780829, 3.0038360420313177],
						[60.554747008051287, 3.003836045823943],
						[60.5547470056176, 3.0038360517616618],
						[60.554747004888043, 3.0038360555542738],
						[60.554730424119164, 3.0039081723729],
						[60.554427695834626, 3.0040915296767818],
						[60.554427694941729, 3.0040915298426412],
						[60.55442769413051, 3.0040915318218513],
						[60.554427693155937, 3.0040915301743571],
						[60.554335992714918, 3.0041498316400794],
						[60.554335977943978, 3.0041498435263954],
						[60.554335951162464, 3.0041498686148116],
						[60.554335939151912, 3.0041498818169079],
						[60.554138739030684, 3.0044192827751055],
						[60.554138738056132, 3.004419281127614],
						[60.554138738137787, 3.0044192829409537],
						[60.553958153414627, 3.004669260486009],
						[60.553885996396176, 3.0047276267925085],
						[60.553847121883379, 3.0047442160956543],
						[60.553847070917136, 3.0047442639598718],
						[60.553847013232094, 3.0047443825547222],
						[60.553847007324478, 3.0047444513061969],
						[60.553866476620961, 3.0048166428733132],
						[60.553580457789444, 3.0051081585309034],
						[60.55343601288164, 3.0052526038615568],
						[60.55320829918324, 3.0052637003318399],
						[60.552988913838007, 3.0052554006647574],
						[60.552741719545651, 3.0052304008835007],
						[60.55274171418823, 3.0052304018783951],
						[60.552741700631422, 3.0052304007390962],
						[60.55274169519236, 3.0052303999207268],
						[60.552538919942386, 3.0052358994855264],
						[60.552263945460872, 3.0051720052742485],
						[60.552263927357906, 3.0051720031507969],
						[60.552263890340726, 3.0051720008829625],
						[60.552263872401063, 3.0051720023860029],
						[60.552088871925754, 3.0051970019666592],
						[60.552088863889637, 3.0051970034589703],
						[60.552088848710341, 3.0051970062777711],
						[60.552088840755879, 3.0051970095833092],
						[60.55187221847082, 3.0052636854674644],
						[60.551861189644924, 3.0052581219366745],
						[60.551861188670344, 3.0052581202892594],
						[60.551644488674249, 3.005149820202063],
						[60.551644437855295, 3.0051498113553818],
						[60.551644336385984, 3.0051498173995106],
						[60.55164428679182, 3.0051498357509137],
						[60.551430489237305, 3.0052970336702827],
						[60.551391746669758, 3.0053219318010327],
						[60.551194562367996, 3.0050637834089127],
						[60.551025222272727, 3.0048166717653579],
						[60.551094588406549, 3.0046194681320575],
						[60.551194584796768, 3.004350076330335],
						[60.551300181024281, 3.0041222853290654],
						[60.551402981150559, 3.0039056860464055],
						[60.551402990068617, 3.0039056441661653],
						[60.551402990776353, 3.0039055599303071],
						[60.551402981673121, 3.0039055177405545],
						[60.551303003310359, 3.0036832641315918],
						[60.551333498647949, 3.0032833154970384],
						[60.551358489129562, 3.0029945378547218],
						[60.551594477671813, 3.0028945849340092],
						[60.55159447945762, 3.0028945846022648],
						[60.551594480268825, 3.0028945826231843],
						[60.551594481161722, 3.0028945824573112],
						[60.551644480751662, 3.0028723839584615],
						[60.551644507778008, 3.0028723643108242],
						[60.551644555417042, 3.0028723225502487],
						[60.551644574973459, 3.002872296976784],
						[60.551850175069141, 3.0025028961864662],
						[60.552083475104276, 3.0020833964277136],
						[60.552083480782677, 3.0020833825738973],
						[60.552083492139509, 3.0020833548662438],
						[60.552083495950406, 3.0020833395310085],
						[60.552086295579343, 3.0020694391475002],
						[60.552086297283445, 3.0020694370024863],
						[60.552086297931261, 3.002069431396921],
						[60.552086297849584, 3.0020694295837012],
						[60.552089072576109, 3.0020502003857463],
						[60.55210829998763, 3.0020502006503613],
						[60.552108326774487, 3.0020501956733967],
						[60.552108378399041, 3.0020501824248105],
						[60.552108402262107, 3.0020501725058928],
						[60.55243070242549, 3.0018584726955035],
						[60.552430703318372, 3.0018584725295994],
						[60.552430704129577, 3.0018584705504572],
						[60.552430705022466, 3.0018584703845521],
						[60.552538993081043, 3.0017918783713391],
						[60.552722227420993, 3.0017113068319836],
						[60.552811068904653, 3.0017251984360764],
						[60.552811069797549, 3.0017251982701647],
						[60.552811070690446, 3.0017251981042516],
						[60.552811071583314, 3.0017251979383421],
						[60.552988872263526, 3.0017501984999497],
						[60.552988928597578, 3.0017501898606964],
						[60.552989028187497, 3.0017501421002133],
						[60.552989070632144, 3.0017501049581674],
						[60.553147370062064, 3.0014918056964186],
						[60.553344569699519, 3.001172305664332],
						[60.553344584785243, 3.0011722608060309],
						[60.553344592470893, 3.0011721716111346],
						[60.553344584989148, 3.0011721254612671],
						[60.552989085223686, 3.0002999245023094],
						[60.552989052740656, 3.0002998829998315],
						[60.552988970733935, 3.0002998214465841],
						[60.552988922103125, 3.0002998012298661],
						[60.5526418799978, 3.0002609184263358],
						[60.552539100061907, 2.9993693887834825],
						[60.552539100252723, 2.9993472007643711],
						[60.552539097404434, 2.9993471775242595],
						[60.552539087243389, 2.9993471318739053],
						[60.552539079037764, 2.9993471096296513],
						[60.55253348006206, 2.9993360132940916],
						[60.552322401901165, 2.9988777611342106],
						[60.552344599841412, 2.9984361103356667],
						[60.552344596829478, 2.9984360834693464],
						[60.552344585448267, 2.9984360307326732],
						[60.552344576186094, 2.9984360050283367],
						[60.55208907614729, 2.9979638050220627],
						[60.552089054139351, 2.9979637762025972],
						[60.55208900265972, 2.9979637327502444],
						[60.552088973024524, 2.9979637144909312],
						[60.551911173339299, 2.9978942135129709],
						[60.551911149715458, 2.9978942087633005],
						[60.551911104335268, 2.9978942007451304],
						[60.551911080063782, 2.9978942016010675],
						[60.551827779836955, 2.9979026006375027],
						[60.551827778944059, 2.9979026008035174],
						[60.55182777715828, 2.9979026011355385],
						[60.551827776265384, 2.9979026013015506],
						[60.551644376178345, 2.9979248008252144],
						[60.551644374392566, 2.9979248011572337],
						[60.551644371713905, 2.9979248016552624],
						[60.551644369928113, 2.9979248019872831],
						[60.551405587841572, 2.9979608997827056],
						[60.551194404855551, 2.9979553992219841],
						[60.551194398605332, 2.9979554003840212],
						[60.551194386916016, 2.9979554007289422],
						[60.551194379691168, 2.9979554002438293],
						[60.55096937971777, 2.9979776008925927],
						[60.550969377931978, 2.9979776012246035],
						[60.550969372574656, 2.9979776022206228],
						[60.550969370788884, 2.9979776025526266],
						[60.550744384938206, 2.998010899960255],
						[60.550533299626778, 2.9980109004607649],
						[60.550505599613764, 2.9980108998773343],
						[60.550505595149318, 2.9980109007073326],
						[60.550505584352891, 2.9980109008862126],
						[60.550505578995562, 2.9980109018822056],
						[60.550297179174784, 2.9980331014218944],
						[60.550297170245898, 2.9980331030818648],
						[60.550297150602333, 2.9980331067338084],
						[60.550297141673433, 2.9980331083937823],
						[60.550122142443719, 2.9980859089907095],
						[60.550122139765044, 2.9980859094886942],
						[60.550122138872162, 2.9980859096546886],
						[60.549999959706, 2.998124803898913],
						[60.54984718155908, 2.9981387004360531],
						[60.549847144302952, 2.998138712846945],
						[60.549847075638553, 2.9981387475512409],
						[60.549847046179536, 2.9981387731388396],
						[60.549755446966721, 2.9982498718334361],
						[60.549594249213911, 2.9984359682793111],
						[60.54959423988258, 2.9984359809835137],
						[60.549594223087425, 2.9984360078730234],
						[60.549594215541859, 2.998436020245264],
						[60.549502616553106, 2.9986499200492593],
						[60.549397043079324, 2.9988859601296061],
						[60.54922209690551, 2.9989915294128302],
						[60.549083197057691, 2.9990748283951532],
						[60.549083188292229, 2.9990748336808362],
						[60.549083170924803, 2.9990748478782931],
						[60.549083163133979, 2.9990748548110697],
						[60.548949897116508, 2.9991998223910348],
						[60.548886042356294, 2.9992192082931175],
						[60.548886040570501, 2.9992192086250298],
						[60.548886039677605, 2.9992192087909855],
						[60.548502769774501, 2.9993387005122192],
						[60.548500000193108, 2.9993386997030527],
						[60.548499947757705, 2.9993387149330726],
						[60.548499859291979, 2.9993387697671521],
						[60.548499822368754, 2.9993388095371611],
						[60.54849702158505, 2.9993443082667537],
						[60.548497013636869, 2.9993443316823614],
						[60.548497003097886, 2.9993443775178923],
						[60.548496999614159, 2.9993444001037521],
						[60.548496999723312, 2.9993471588365805],
						[60.548477617413305, 2.9993916195520485],
						[60.548477613357498, 2.9993916294468321],
						[60.548477607842798, 2.9993916469255346],
						[60.548477604598133, 2.9993916548413657],
						[60.548347004823796, 2.9999527552482603],
						[60.548347004823796, 2.9999527552482603],
						[60.548277619138673, 3.0002609929582098],
						[60.548119268141335, 3.0003998494210493],
						[60.548049934708423, 3.000460890637497],
						[60.547958345331551, 3.0004498032507914],
						[60.54764176583295, 3.0003415118829313],
						[60.547602898345538, 3.0003276227139137],
						[60.547577957320826, 3.0003081695597431],
						[60.547552977378437, 3.0002610053406085],
						[60.547552966821172, 3.000260990849053],
						[60.54755294392087, 3.0002609621977925],
						[60.547552930684915, 3.0002609482039921],
						[60.547272349540705, 3.0000165646156409],
						[60.547152964125594, 2.9998470853742494],
						[60.547152874007075, 2.9998470436210374],
						[60.547152702565683, 2.9998470553706169],
						[60.54715262132455, 2.99984711068634],
						[60.547049821424743, 3.0000527110277417],
						[60.547049819720677, 3.0000527131725079],
						[60.546949823960347, 3.0002610049081655],
						[60.546813729969685, 3.0004831943816304],
						[60.546702641749157, 3.0006637735733328],
						[60.546535960190106, 3.0008276576177928],
						[60.546316560466963, 3.0010415573743194],
						[60.546316555272988, 3.0010415619954451],
						[60.546316545859668, 3.0010415728846596],
						[60.546316540665721, 3.0010415775057822],
						[60.546252664809842, 3.0011248472887995],
						[60.546238809144008, 3.0011331213538899],
						[60.5461471215269, 3.001172015257036],
						[60.546147112761354, 3.001172020541627],
						[60.546147095148562, 3.0011720292979436],
						[60.546147087275898, 3.0011720344166521],
						[60.545891586783128, 3.0013470348817624],
						[60.545805587614247, 3.0014053669612673],
						[60.545716794220731, 3.0013581221060974],
						[60.545716725462007, 3.0013581147711061],
						[60.545716597048511, 3.0013581422827817],
						[60.545716539424625, 3.0013581822362911],
						[60.545555439262735, 3.0015776820209479],
						[60.545555438369853, 3.0015776821868148],
						[60.545355611087075, 3.0018524448209147],
						[60.544922368901759, 3.0011720927442154],
						[60.544922365977975, 3.001172087803381],
						[60.544922360212098, 3.001172079734566],
						[60.544922356395411, 3.0011720749596078],
						[60.544911255937308, 3.0011581750732241],
						[60.544911205841849, 3.0011581423357154],
						[60.544911096325137, 3.0011581096690532],
						[60.54491103601098, 3.0011581099057749],
						[60.544902771981747, 3.0011608973048283],
						[60.544461110425274, 3.001138700749673],
						[60.544461057826169, 3.0011387123489266],
						[60.544460967246906, 3.0011387602512021],
						[60.544460928373866, 3.0011387967200971],
						[60.544452636949707, 3.0011526830996385],
						[60.544435948092847, 3.0011720705413674],
						[60.544435943873474, 3.0011720768090981],
						[60.544435933567243, 3.0011720878634978],
						[60.544435930322464, 3.0011720957781236],
						[60.544199830336787, 3.0015526932619996],
						[60.544010933119111, 3.0018554889229558],
						[60.543944236950225, 3.0019498858234646],
						[60.543944235975637, 3.0019498841765335],
						[60.543944236057342, 3.0019498859893083],
						[60.543944235164446, 3.0019498861551548],
						[60.543852634828589, 3.0020831874537257],
						[60.543852632313282, 3.0020831915767818],
						[60.543852626471427, 3.0020832018014771],
						[60.54385262395612, 3.0020832059245137],
						[60.543699823772464, 3.0023693067321502],
						[60.543699822879567, 3.0023693068979824],
						[60.543699821257135, 3.0023693108551468],
						[60.543699821257135, 3.0023693108551468],
						[60.543563885193699, 3.0026439781806515],
						[60.543236325401793, 3.0020832873437544],
						[60.543391882387517, 3.001736182751745],
						[60.543564081690825, 3.0013611839258445],
						[60.543564085017316, 3.0013611778241747],
						[60.543564089721116, 3.0013611623270968],
						[60.543564092154696, 3.0013611563912881],
						[60.543597389635565, 3.0012472629402738],
						[60.543625187815657, 3.0011722707948199],
						[60.54362519169662, 3.0011722170641879],
						[60.543625174620907, 3.0011721178726822],
						[60.543625152689636, 3.0011720707649112],
						[60.543564053403337, 3.0010998702329732],
						[60.54356401808441, 3.0010998457193603],
						[60.543563941686521, 3.001099808729633],
						[60.543563899878052, 3.0010998000448632],
						[60.54352500474613, 3.0010997995850643],
						[60.543155608836315, 3.0010831000864768],
						[60.543155593575399, 3.0010831010934678],
						[60.543155564028162, 3.0010831047542985],
						[60.543155548848937, 3.0010831075740052],
						[60.543113873618736, 3.0010941997169005],
						[60.543075002838968, 3.0010942001918708],
						[60.542705606265571, 3.0010830995697879],
						[60.542705588325973, 3.0010831010743391],
						[60.542705554314267, 3.0010831055643861],
						[60.54270553743094, 3.0010831105284725],
						[60.542663867632058, 3.0010969999357737],
						[60.542625002208396, 3.0010969993804482],
						[60.542255603998434, 3.0010886991361319],
						[60.542255589712113, 3.0010887017898744],
						[60.542255561868963, 3.0010887033061686],
						[60.542255549450147, 3.0010887074408532],
						[60.542213874212358, 3.0010997991996455],
						[60.542177800939655, 3.0010998008924492],
						[60.541802800926071, 3.0010970008397218],
						[60.541802790129637, 3.0010970010173477],
						[60.541802765939757, 3.0010970036828124],
						[60.541802755225014, 3.0010970056730777],
						[60.541766676659186, 3.0011053998230754],
						[60.541733300803202, 3.0011053994293548],
						[60.541350001197131, 3.0011025998386853],
						[60.541349988614904, 3.0011026003480037],
						[60.541349963532149, 3.0011026031792372],
						[60.541349951924495, 3.0011026053353183],
						[60.541316675300713, 3.0011108992729656],
						[60.541286101417846, 3.0011108993106159],
						[60.540897200611383, 3.0011080997652457],
						[60.540897188029149, 3.0011081002745272],
						[60.54089716026769, 3.0011081036032632],
						[60.54089714695597, 3.0011081079035855],
						[60.540866728257917, 3.0011164849817789],
						[60.540833400943129, 3.0010970262906729],
						[60.540833377074257, 3.0010970161020412],
						[60.540833326091736, 3.0010970036385407],
						[60.54083329978927, 3.0010969993852239],
						[60.54041940751933, 3.0010969992749934],
						[60.540383315171631, 3.0010942006477221],
						[60.540383312411244, 3.0010941993327021],
						[60.54038330437519, 3.0010942008253152],
						[60.540383299829017, 3.0010941998419867],
						[60.539969463396361, 3.0010942000223624],
						[60.539914015330744, 3.0010554356825425],
						[60.53991398683403, 3.0010554226983417],
						[60.539913930249028, 3.0010554057926262],
						[60.5399139002933, 3.0010554003902619],
						[60.539519449730854, 3.0010554006389643],
						[60.539447294662295, 3.0010165232099992],
						[60.539447271768033, 3.001016514668482],
						[60.539447224438781, 3.0010165033546574],
						[60.539447200003814, 3.0010165005823435],
						[60.53907503900102, 3.0010165007405827],
						[60.538977873886395, 3.0009776140115125],
						[60.538977855620011, 3.0009776082658859],
						[60.538977819332295, 3.0009776022120831],
						[60.538977800336397, 3.0009776002572783],
						[60.538625035108588, 3.0009776004435738],
						[60.53850836681616, 3.000935912500859],
						[60.538508351228437, 3.0009359062578187],
						[60.538508316808226, 3.0009359016849104],
						[60.538508299598128, 3.0009358993984483],
						[60.538175043631483, 3.0009359004813199],
						[60.53802508362093, 3.0008665180954233],
						[60.53802506267585, 3.0008665128475114],
						[60.538025020785661, 3.0008665023516854],
						[60.538025000003969, 3.0008665007286446],
						[60.537727871298799, 3.0008664996872936],
						[60.537472325671644, 3.0006581446271574],
						[60.537472297011469, 3.00065812801938],
						[60.537472233931084, 3.0006581068390719],
						[60.537472200322036, 3.0006581002883146],
						[60.537277919198161, 3.0006581007073057],
						[60.537064076206157, 3.0002610056516312],
						[60.537064063699759, 3.0002609878715272],
						[60.537064037064589, 3.0002609562677534],
						[60.537064021068304, 3.0002609409634236],
						[60.536827921190167, 3.0000804418263236],
						[60.536827915587658, 3.0000804373843395],
						[60.536827904382683, 3.0000804285003784],
						[60.536827896994403, 3.0000804243901076],
						[60.536708397072047, 3.00001372416975],
						[60.536708374177742, 3.0000137156295574],
						[60.536708325873818, 3.0000137026714273],
						[60.536708302331732, 3.0000136997341365],
						[60.536380648849359, 3.0000108995969859],
						[60.536216791058969, 2.9999276221761608],
						[60.536216769057525, 2.9999276134702755],
						[60.5362167253815, 2.9999276033078388],
						[60.536216701839429, 2.999927600370631],
						[60.535930656331388, 2.9999248009385027],
						[60.535694502886315, 2.9997831282254759],
						[60.535694478124476, 2.9997831182050843],
						[60.535694427060172, 2.9997831039328564],
						[60.535694399864774, 2.9997830998468924],
						[60.535480678611663, 2.999783099233039],
						[60.53504453695664, 2.9993720546080862],
						[60.535044505372504, 2.9993720330633851],
						[60.535044436689454, 2.9993720074452717],
						[60.535044400319961, 2.9993719995814989],
						[60.535033411279286, 2.9993719995788788],
						[60.535019569832578, 2.9993498948016724],
						[60.535019561224509, 2.999349883608589],
						[60.535019544008371, 2.9993498612224521],
						[60.535019533696236, 2.9993498521733564],
						[60.534583434104192, 2.9989554513420997],
						[60.534583432236673, 2.9989554498616067],
						[60.534583429394544, 2.9989554467347332],
						[60.534583426634164, 2.9989554454200995],
						[60.53426122676391, 2.9986915453124494],
						[60.534261196236031, 2.9986915272274426],
						[60.534261131369618, 2.9986915063844464],
						[60.534261095974742, 2.9986915001679328],
						[60.534133380923869, 2.9986941976953911],
						[60.533877941034184, 2.9984387589628265],
						[60.533877936242817, 2.9984387525434406],
						[60.533877923170245, 2.9984387421803707],
						[60.533877916674804, 2.9984387379049235],
						[60.53368903402535, 2.9983026506718446],
						[60.533530748165482, 2.9981276667414027],
						[60.533530744266997, 2.9981276601562472],
						[60.533530734929407, 2.9981276527542153],
						[60.533530730219745, 2.9981276481471273],
						[60.533236238402822, 2.9978748540410738],
						[60.532902944204309, 2.9975276617586912],
						[60.532902938601758, 2.9975276573176619],
						[60.532902929100658, 2.9975276462916831],
						[60.532902923579826, 2.9975276436627736],
						[60.532786233653965, 2.9974359519282436],
						[60.532700144051994, 2.9973470613569795],
						[60.532700139342317, 2.9973470567500962],
						[60.532700129922951, 2.9973470475363522],
						[60.53270012529503, 2.9973470447416051],
						[60.532336225056177, 2.9970554440598174],
						[60.532336223188658, 2.9970554425795277],
						[60.532336220346501, 2.9970554394530446],
						[60.532336217586057, 2.99705543813866],
						[60.531977917935194, 2.9967942375492282],
						[60.53197789122391, 2.9967942242397694],
						[60.531977836342534, 2.9967942052009864],
						[60.531977806386685, 2.9967941998034728],
						[60.531891779099041, 2.9967915025370666],
						[60.531697334074337, 2.9966165514925196],
						[60.53169732571132, 2.9966165457374649],
						[60.531697308903539, 2.9966165324152998],
						[60.531697299647632, 2.9966165268261729],
						[60.531441821732173, 2.9964692396197372],
						[60.531300140308325, 2.9963304576538738],
						[60.531300134623962, 2.9963304514012128],
						[60.531300124311649, 2.9963304423540595],
						[60.531300117816166, 2.9963304380793456],
						[60.530991828934681, 2.9961054464665646],
						[60.530572337636855, 2.995705455160858],
						[60.530572332034247, 2.9957054507204326],
						[60.530572320829009, 2.9957054418395979],
						[60.530572315144624, 2.9957054355871806],
						[60.530544514962656, 2.995685936561193],
						[60.530544514069774, 2.9956859367271234],
						[60.530544512120471, 2.9956859334349946],
						[60.530544511227575, 2.9956859336009258],
						[60.530527915395261, 2.9956748368593571],
						[60.530094518884368, 2.9953526393135013],
						[60.530094517016828, 2.9953526378334048],
						[60.530094511414191, 2.9953526333931046],
						[60.530094508653761, 2.9953526320789425],
						[60.529841808547999, 2.9951887314058885],
						[60.529841783785891, 2.9951887213905826],
						[60.529841732639433, 2.9951887053157309],
						[60.529841706255084, 2.9951886992562016],
						[60.529647277780576, 2.9951831022950755],
						[60.529202931836224, 2.9947942492804596],
						[60.529202920630944, 2.9947942404002936],
						[60.529202899276797, 2.9947942260978424],
						[60.529202887260411, 2.9947942191955308],
						[60.529197317017356, 2.9947915344280212],
						[60.529194541082752, 2.9947887597073248],
						[60.529194535398332, 2.9947887534553228],
						[60.529194523136596, 2.9947887411172833],
						[60.529194515748195, 2.9947887370090784],
						[60.528747315540535, 2.9944692366798167],
						[60.528747314647653, 2.9944692368457653],
						[60.528747310912522, 2.9944692338857957],
						[60.52874730993787, 2.9944692322398567],
						[60.52851401009255, 2.9943165326482131],
						[60.528513986141498, 2.9943165206560876],
						[60.528513935887858, 2.9943165044172018],
						[60.528513909585243, 2.9943165001704624],
						[60.528297280617487, 2.9943054039368389],
						[60.527866834015057, 2.9939165522680127],
						[60.527866810793277, 2.9939165364867697],
						[60.527866760130671, 2.9939165111895982],
						[60.527866732771599, 2.9939165034854915],
						[60.527850129300333, 2.9939137192678023],
						[60.527833476075045, 2.993883204383057],
						[60.527833462675005, 2.9938831867762952],
						[60.527833433196264, 2.9938831520606479],
						[60.527833417281144, 2.9938831385754292],
						[60.527400116936107, 2.9935720376706603],
						[60.527400115068559, 2.9935720361907801],
						[60.527400111333435, 2.9935720332310121],
						[60.527400108654781, 2.9935720337289067],
						[60.527175108637103, 2.993424833837274],
						[60.527175087364633, 2.993424821348365],
						[60.527175040764213, 2.9934248062593474],
						[60.527175017140266, 2.9934248015155216],
						[60.526950084570252, 2.9934054071694507],
						[60.526522335067341, 2.9930137516163065],
						[60.526522310952593, 2.9930137360022901],
						[60.526522261264489, 2.9930137123532576],
						[60.526522232930724, 2.9930137030043871],
						[60.526505720282977, 2.9930109170569543],
						[60.526480768280436, 2.9929720918394596],
						[60.526480756829663, 2.992972077525339],
						[60.526480732224115, 2.9929720510408298],
						[60.526480718176487, 2.9929720390363932],
						[60.526055718253133, 2.9926609390853294],
						[60.526055715410877, 2.9926609359598135],
						[60.526055708022398, 2.9926609318524608],
						[60.52605570428728, 2.992660928892922],
						[60.525833403691955, 2.9925248290191324],
						[60.525833382583031, 2.9925248201547023],
						[60.525833337768304, 2.9925248047355488],
						[60.525833315119002, 2.9925248016382571],
						[60.525605681308868, 2.9925081045948296],
						[60.525202931207069, 2.992158149223306],
						[60.525202907985118, 2.9921581334445251],
						[60.525202859271616, 2.9921581114433469],
						[60.525202832805356, 2.9921581035752625],
						[60.525152929801905, 2.9921498187884459],
						[60.525105776819075, 2.9920610051527463],
						[60.52510576333701, 2.9920609857364973],
						[60.525105731179202, 2.9920609515232965],
						[60.525105713314545, 2.9920609347486757],
						[60.524708413363818, 2.9917887345743512],
						[60.524708405082443, 2.9917887306333641],
						[60.524708389330748, 2.9917887207737097],
						[60.524708381049372, 2.9917887168327209],
						[60.524533380667876, 2.9917109163512174],
						[60.524533362401165, 2.99171091061294],
						[60.524533324163798, 2.9917109012800189],
						[60.524533304274961, 2.9917108994970154],
						[60.524258344219454, 2.991705401735536],
						[60.524033381095045, 2.9916054168490085],
						[60.524033361042576, 2.9916054114428539],
						[60.524033321019445, 2.9916054024421621],
						[60.524033300237711, 2.9916054008252484],
						[60.5238111669318, 2.9916054006255988],
						[60.523450120170764, 2.9913331400429821],
						[60.523450088830984, 2.9913331239479999],
						[60.523450022177933, 2.9913331034575372],
						[60.523449986946446, 2.9913331008736379],
						[60.523361209138727, 2.991338692740146],
						[60.523250171934862, 2.9911526976964553],
						[60.523250156666926, 2.9911526786136653],
						[60.523250122723169, 2.991152644735243],
						[60.523250103965516, 2.9911526281280389],
						[60.522911204476138, 2.9909470297985981],
						[60.522911191566685, 2.9909470230649413],
						[60.522911167697195, 2.990947012888713],
						[60.522911155762472, 2.9909470078006133],
						[60.52279725561155, 2.9909137086572688],
						[60.522797241080006, 2.9909137058787594],
						[60.522797210231118, 2.9909137006537456],
						[60.522797194888483, 2.9909136998527761],
						[60.522463904564468, 2.9909219996633789],
						[60.522347214281581, 2.9909137015886516],
						[60.522347207867774, 2.9909136991276482],
						[60.522347196178487, 2.990913699474175],
						[60.522347189928333, 2.990913700636205],
						[60.52201389000156, 2.9909303992440894],
						[60.522013889108692, 2.9909303994100913],
						[60.522013885618982, 2.9909304018855956],
						[60.522013883751399, 2.9909304004060995],
						[60.52190828498825, 2.9909387018697204],
						[60.521563885580143, 2.9909637013994934],
						[60.521563883794379, 2.9909637017314874],
						[60.521563881926795, 2.9909637002519922],
						[60.521563881033913, 2.9909637004179901],
						[60.521477780832285, 2.990972000699887],
						[60.521191681415687, 2.9909998005188623],
						[60.521191677844158, 2.9909998011828423],
						[60.521191674272636, 2.9909998018468169],
						[60.521191671593996, 2.9909998023447995],
						[60.52111387331604, 2.9910109027872291],
						[60.521049976392703, 2.9910192014359502],
						[60.520713876949756, 2.9910581001445977],
						[60.520713868995649, 2.9910581034499288],
						[60.520713852030887, 2.9910581066037287],
						[60.520713843994947, 2.9910581080976359],
						[60.520666656126728, 2.9910720041731484],
						[60.520630569679298, 2.9910776021260483],
						[60.520224969777644, 2.9911387022767451],
						[60.520224961741704, 2.9911387037706016],
						[60.520224944858789, 2.9911387087357091],
						[60.520224935929967, 2.9911387103955493],
						[60.52021666682694, 2.9911415006514175],
						[60.520213899928791, 2.991141499904407],
						[60.52021389091815, 2.9911414997528474],
						[60.520213872085819, 2.991141501427109],
						[60.520213863156982, 2.9911415030869479],
						[60.520155562614654, 2.9911526039774237],
						[60.520155559043133, 2.9911526046413544],
						[60.520155551007186, 2.9911526061352043],
						[60.520155547435664, 2.9911526067991363],
						[60.519813847893055, 2.9912470068903878],
						[60.519772168935035, 2.9912581009040156],
						[60.519713924625577, 2.9912608982444158],
						[60.519358358377843, 2.9911526095659187],
						[60.51935835196403, 2.9911526071050636],
						[60.519358339218236, 2.9911526039946832],
						[60.519358332886249, 2.9911526033451765],
						[60.519325033316299, 2.9911470029406626],
						[60.519325025198555, 2.9911470026231131],
						[60.51932500798835, 2.9911470003426244],
						[60.519324999870591, 2.9911470000250744],
						[60.519322248314879, 2.9911470000742262],
						[60.519316790817179, 2.9911442226275495],
						[60.519316785214428, 2.9911442181893708],
						[60.519316774254406, 2.9911442147470395],
						[60.519316768733482, 2.9911442121202074],
						[60.518944468522584, 2.9910081113292293],
						[60.518944456751491, 2.9910081098642842],
						[60.518944433938515, 2.9910081031457798],
						[60.518944423060319, 2.9910081015148688],
						[60.518872223118336, 2.9909998016576052],
						[60.518872211347229, 2.9909998001926636],
						[60.518872187968675, 2.9909998008854601],
						[60.518872176279388, 2.990999801231863],
						[60.518802776309464, 2.9910081009950882],
						[60.518474982996629, 2.9910470006134728],
						[60.518422188794474, 2.9910498005255395],
						[60.518422186115828, 2.9910498010234616],
						[60.518422179783826, 2.9910498003739696],
						[60.518422176212304, 2.9910498010378643],
						[60.518374977353609, 2.9910554001606675],
						[60.518002777802764, 2.9910970003034407],
						[60.518002773338345, 2.9910970011332929],
						[60.518002765302413, 2.9910970026270252],
						[60.518002760838016, 2.991097003456872],
						[60.51797498034702, 2.9911026002039236],
						[60.517947200071404, 2.9911026005556054],
						[60.517947197310924, 2.9911025992422453],
						[60.517947191060756, 2.9911026004040329],
						[60.517947188300283, 2.9911025990906697],
						[60.517536088145107, 2.9911276005532694],
						[60.517536079134452, 2.9911276004016827],
						[60.517536060383954, 2.9911276038869694],
						[60.517536050562249, 2.9911276057125957],
						[60.517524975187357, 2.9911304004307366],
						[60.51751389963853, 2.9911304006974722],
						[60.517513898745648, 2.9911304008634358],
						[60.517513897770961, 2.9911303992181533],
						[60.517513896878079, 2.9911303993841192],
						[60.517083296928128, 2.9911358998243673],
						[60.517083281667304, 2.9911359008345144],
						[60.517083251309323, 2.9911359064772496],
						[60.517083236130325, 2.9911359092986163],
						[60.517074967021706, 2.9911386992370526],
						[60.51706670066401, 2.991138699699083],
						[60.516636101264822, 2.9911359002954345],
						[60.516636100371954, 2.9911359004613933],
						[60.516627799757202, 2.991135899979203],
						[60.516619401893045, 2.9911358993085129],
						[60.516188926774007, 2.9911275992044288],
						[60.516177874839542, 2.9911248134746597],
						[60.516158402229536, 2.9911137285803671],
						[60.515841805427044, 2.9909192304897312],
						[60.515841793410488, 2.990919223591431],
						[60.515841768648144, 2.990919213583116],
						[60.515841755820517, 2.9909192086619294],
						[60.515727859931381, 2.9908859091635436],
						[60.515572263622808, 2.9908331116261255],
						[60.515572262648107, 2.9908331099809486],
						[60.515572260862335, 2.9908331103128694],
						[60.515572259887634, 2.9908331086676925],
						[60.515280659571779, 2.9907415083851716],
						[60.51528065689314, 2.9907415088830582],
						[60.515280653239778, 2.9907415077357835],
						[60.515280649586437, 2.9907415065885066],
						[60.51504444981326, 2.9906804066721282],
						[60.515044445267044, 2.9906804056908269],
						[60.515044436174577, 2.9906804037282102],
						[60.515044430735479, 2.9906804029128695],
						[60.51483063403068, 2.990647003267016],
						[60.514555638178166, 2.9905942042358267],
						[60.514555632739075, 2.9905942034205064],
						[60.514555623646608, 2.9905942014579505],
						[60.514555619100371, 2.990594200476675],
						[60.514380624190999, 2.9905776015616716],
						[60.51407502930504, 2.9905331026106112],
						[60.514075025651678, 2.9905331014633978],
						[60.514075019319691, 2.9905331008140572],
						[60.514075015748169, 2.9905331014778929],
						[60.513936119671939, 2.9905220018778644],
						[60.513602822632954, 2.9904831001671859],
						[60.513602819954322, 2.9904831006650583],
						[60.513602812729438, 2.9904831001816907],
						[60.513602809157923, 2.9904831008455171],
						[60.513486112763786, 2.9904776000087545],
						[60.513138918020601, 2.9904470018153311],
						[60.513138913392559, 2.990446999023086],
						[60.513138904463737, 2.9904470006826394],
						[60.513138899917521, 2.9904469997014136],
						[60.51303610807804, 2.9904469992333711],
						[60.512672215277959, 2.9904192000717131],
						[60.512672209838833, 2.9904191992564555],
						[60.512672199042441, 2.9904191994369116],
						[60.512672192792294, 2.9904192005985784],
						[60.51258890994152, 2.9904220004952431],
						[60.512202826391885, 2.9903720019710454],
						[60.51220281916703, 2.9903720014877004],
						[60.512202805610144, 2.990372000355054],
						[60.512202800171053, 2.9903719995398061],
						[60.512138924551252, 2.9903719995918805],
						[60.51169724891048, 2.9902609051501838],
						[60.51169723713938, 2.9902609036856838],
						[60.511697211811409, 2.9902609010886163],
						[60.511697200040317, 2.9902608996241153],
						[60.511688943586257, 2.9902609002148726],
						[60.511652884180442, 2.9902442186139497],
						[60.511652877766615, 2.9902442161537715],
						[60.511652864938981, 2.990244211233394],
						[60.511652859418071, 2.9902442086072583],
						[60.511238970951446, 2.9901165121054611],
						[60.511158382077816, 2.9900804161443046],
						[60.511158380292052, 2.9900804164762107],
						[60.511158376638704, 2.9900804153291261],
						[60.511158375745829, 2.9900804154950746],
						[60.510791781963007, 2.9899304175384716],
						[60.510586186982863, 2.9898304200856249],
						[60.510586185115258, 2.9898304186066813],
						[60.510586179676153, 2.989830417791552],
						[60.51058617780857, 2.9898304163125871],
						[60.510341781219104, 2.9897276178527901],
						[60.510011184813131, 2.9895720191767472],
						[60.510011175638837, 2.9895720154038381],
						[60.510011159157841, 2.9895720093369467],
						[60.510011149090658, 2.9895720057300141],
						[60.509891773601318, 2.9895415127771359],
						[60.509513995976484, 2.9893359246657276],
						[60.509513992241288, 2.9893359217079811],
						[60.50951398404171, 2.9893359195800415],
						[60.509513980306529, 2.9893359166222768],
						[60.509444480127044, 2.9893054177885579],
						[60.509444479152322, 2.9893054161437309],
						[60.509425079976232, 2.9892970177581573],
						[60.508994480631678, 2.9891081172321035],
						[60.508850080815357, 2.9890442175510463],
						[60.508850079922489, 2.9890442177170087],
						[60.508850078054891, 2.9890442162381801],
						[60.508850077080169, 2.9890442145933873],
						[60.508550077029582, 2.9889192145006134],
						[60.508550073376213, 2.9889192133537379],
						[60.508550065176621, 2.9889192112259524],
						[60.508550060630384, 2.9889192102450273],
						[60.508300061063899, 2.9888387104663705],
						[60.508300054650071, 2.9888387080066829],
						[60.508300041904221, 2.9888387048980247],
						[60.508300034597525, 2.988838702604304],
						[60.508097237311318, 2.9888026047702052],
						[60.507808339654012, 2.9887442036954983],
						[60.507808330643378, 2.9887442035444609],
						[60.507808311647402, 2.9887442015976329],
						[60.507808302554928, 2.9887441996358963],
						[60.507647213180149, 2.9887415006227074],
						[60.507338922604902, 2.9887054005968863],
						[60.507338915380046, 2.9887054001139055],
						[60.507338897358778, 2.9887053998118405],
						[60.507338888348144, 2.9887053996608062],
						[60.50719720586433, 2.9887136987141161],
						[60.506875023757878, 2.9886748004401147],
						[60.506875015721953, 2.9886748019337714],
						[60.506874999404609, 2.9886747994891238],
						[60.506874991286843, 2.9886747991721321],
						[60.506752816935467, 2.9886803996550109],
						[60.506388941994985, 2.9886026031996775],
						[60.506388932984351, 2.9886026030486632],
						[60.506388914881242, 2.9886026009359958],
						[60.506388906763497, 2.9886026006190178],
						[60.506302840652722, 2.9885998008498955],
						[60.505852871848788, 2.9884248139591048],
						[60.505852866327828, 2.9884248113336955],
						[60.505852851714401, 2.9884248067467114],
						[60.505852846193442, 2.9884248041213048],
						[60.505450046067054, 2.9883304043159455],
						[60.505450044281297, 2.9883304046478676],
						[60.50545003884217, 2.9883304038330571],
						[60.505450036974572, 2.988330402354415],
						[60.505405636723474, 2.9883220021913113],
						[60.505405633151952, 2.9883220028551585],
						[60.50540562316661, 2.9883220010595881],
						[60.50540561870222, 2.9883220018893915],
						[60.505347220731757, 2.9883165011315653],
						[60.505027824040901, 2.988277601427292],
						[60.505027820387532, 2.9882776002805902],
						[60.505027812269766, 2.9882775999636912],
						[60.505027807805362, 2.9882776007934941],
						[60.504955608407201, 2.9882748005215802],
						[60.504955600289442, 2.9882748002046804],
						[60.504955585028661, 2.9882748012154541],
						[60.504955576910895, 2.9882748008985556],
						[60.504883279647579, 2.9882831011164583],
						[60.504561083404788, 2.9883109014671994],
						[60.504561077072786, 2.9883109008183757],
						[60.504561066358249, 2.9883109028098467],
						[60.50456106100097, 2.9883109038055822],
						[60.504505560889321, 2.9883220044390675],
						[60.504505551960513, 2.9883220060986226],
						[60.504505534184787, 2.988322011228238],
						[60.504505526230716, 2.9883220145323675],
						[60.504463826380295, 2.988338714783791],
						[60.504463825405566, 2.9883387131392376],
						[60.504463824594538, 2.9883387151157002],
						[60.50446382370167, 2.9883387152816545],
						[60.504252723658283, 2.9884248152254513],
						[60.50425271659708, 2.9884248183635576],
						[60.504252701663653, 2.988424826616237],
						[60.504252693709567, 2.9884248299202949],
						[60.504105493815629, 2.9885165298690621],
						[60.504105493897455, 2.9885165316795548],
						[60.504105492029851, 2.9885165302009611],
						[60.504105492111691, 2.9885165320114502],
						[60.504058191729605, 2.9885470324909766],
						[60.504058185561263, 2.9885470354630908],
						[60.50405817517408, 2.9885470446964142],
						[60.504058169087592, 2.9885470494790165],
						[60.503955469058702, 2.9886359495086423],
						[60.503955464676139, 2.9886359521488415],
						[60.503955457860471, 2.9886359607183328],
						[60.503955454370775, 2.9886359631925883],
						[60.503794253766245, 2.9888081634531241],
						[60.503794252873362, 2.9888081636190615],
						[60.50379425206232, 2.9888081655954744],
						[60.503794251251264, 2.9888081675718867],
						[60.503608150645718, 2.9890165666408568],
						[60.503608143830007, 2.9890165752101754],
						[60.503608131984365, 2.989016592016934],
						[60.503608126061494, 2.9890166004203258],
						[60.503524826012431, 2.9891638009218355],
						[60.503424827303341, 2.9893360007051766],
						[60.503424824788354, 2.9893360048238677],
						[60.503424820651261, 2.9893360128952917],
						[60.503424819029156, 2.9893360168480592],
						[60.503297020057211, 2.9896110138343981],
						[60.50315813691509, 2.989894278909214],
						[60.503041566169209, 2.9900026509003106],
						[60.502760969121063, 2.9902470497976803],
						[60.502760933836107, 2.9902471257271248],
						[60.502760936244165, 2.9902472786322702],
						[60.502760972962541, 2.9902473539634506],
						[60.503044273233506, 2.9904807544855001],
						[60.50304434086128, 2.9904807766083112],
						[60.503044475693649, 2.9904807716402559],
						[60.503044540948885, 2.9904807412602641],
						[60.503158269693984, 2.9903670131640223],
						[60.503374834388048, 2.9906862110712442],
						[60.503374847788351, 2.9906862286639497],
						[60.50337487880789, 2.9906862575885835],
						[60.503374895616055, 2.9906862708968505],
						[60.503608195871664, 2.9908279701544536],
						[60.503608208781067, 2.9908279768845043],
						[60.503608235410972, 2.9908279883682267],
						[60.503608248238571, 2.9908279932878048],
						[60.503752740213919, 2.9908668902904267],
						[60.503947132662674, 2.9909362879248831],
						[60.503947133637361, 2.9909362895694938],
						[60.503947137290716, 2.9909362907164661],
						[60.503947138183598, 2.990936290550585],
						[60.504058238267241, 2.9909723893615787],
						[60.504058245573937, 2.9909723916555322],
						[60.504058261973043, 2.9909723959117107],
						[60.504058270172628, 2.990972398039788],
						[60.504444370358826, 2.9910307975579888],
						[60.504444377665514, 2.9910307998519818],
						[60.504444393008143, 2.9910308006530708],
						[60.504444400151222, 2.9910307993260239],
						[60.504505599840087, 2.9910308001921693],
						[60.504505603411594, 2.9910307995286485],
						[60.504505607957839, 2.9910308005097637],
						[60.50450560974361, 2.9910308001780042],
						[60.504883310027886, 2.9910112995695988],
						[60.504883311813636, 2.9910112992378353],
						[60.504883313681219, 2.991011300716607],
						[60.504883315385172, 2.9910112985742985],
						[60.504955614934381, 2.9910057992824033],
						[60.505030606584761, 2.9910001996416051],
						[60.5053277978487, 2.9910030007448158],
						[60.50532779874159, 2.9910030005789308],
						[60.505327799634486, 2.9910030004130395],
						[60.505405503228914, 2.9910030003629422],
						[60.505527624405588, 2.9911583981703491],
						[60.505669213266103, 2.9915333701802638],
						[60.505669221308985, 2.9915333887697679],
						[60.505669241695557, 2.9915334214982154],
						[60.505669254039262, 2.9915334356371717],
						[60.50585265383058, 2.9917307368988513],
						[60.505852676241382, 2.9917307546451815],
						[60.505852726174915, 2.9917307837108162],
						[60.505852751830076, 2.9917307935512492],
						[60.505999952326512, 2.9917668948200991],
						[60.505999964097605, 2.9917668962850126],
						[60.50599998763979, 2.9917668992148232],
						[60.506000000303764, 2.9917669005138485],
						[60.506152799903475, 2.9917669008087073],
						[60.506302689404308, 2.991766899405337],
						[60.506488688683156, 2.9920667398751499],
						[60.506435904191854, 2.9923360614747869],
						[60.506308104296615, 2.9929610604424663],
						[60.506308103090014, 2.9929610935313558],
						[60.506308112284351, 2.9929611575532316],
						[60.506308120981295, 2.9929611906285052],
						[60.506316517208461, 2.9929778802486253],
						[60.506366513289869, 2.9931083702590398],
						[60.506652601802756, 2.9938861394905416],
						[60.506691500348701, 2.9946777995746285],
						[60.506685900382685, 2.9947971903293573],
						[60.506685902338425, 2.9947972137021428],
						[60.506685909739673, 2.9947972579738544],
						[60.506685917052749, 2.994797280351889],
						[60.506752609662342, 2.9949500662576618],
						[60.506949806053989, 2.9957083495349335],
						[60.506949808085011, 2.9957083546354339],
						[60.506949810279536, 2.9957083633572807],
						[60.506949811335907, 2.9957083668128588],
						[60.507197006620082, 2.9964083546691036],
						[60.507241503061472, 2.9966167338530769],
						[60.507258102274001, 2.9967389272546749],
						[60.507258102600929, 2.9967389344974689],
						[60.507258105851704, 2.9967389466751202],
						[60.507258107071543, 2.9967389537521698],
						[60.507480407247343, 2.9975278531244971],
						[60.507480408303664, 2.9975278565801955],
						[60.507480409360021, 2.9975278600359054],
						[60.507480410416356, 2.9975278634916021],
						[60.507647009267316, 2.9980333592792219],
						[60.507763707670854, 2.9984389553766402],
						[60.507763708808874, 2.9984389606431434],
						[60.50776371287072, 2.9984389708447297],
						[60.507763713927019, 2.9984389743004898],
						[60.508097013604434, 2.9992722732212664],
						[60.508097014660727, 2.999272276677087],
						[60.508097016691622, 2.9992722817779947],
						[60.508097018559091, 2.9992722832573908],
						[60.508133118617693, 2.9993500844581411],
						[60.508133118617693, 2.9993500844581411],
						[60.508133118699391, 2.9993500862688904],
						[60.508549813084365, 3.0002250720829622],
						[60.508560909274216, 3.0002611590913197],
						[60.508560911386752, 3.0002611660031437],
						[60.508560917397624, 3.000261179495463],
						[60.508560918617263, 3.0002611865729389],
						[60.508955418675384, 3.0010889858775536],
						[60.508955419568295, 3.0010889857119176],
						[60.508955419649958, 3.0010889875227234],
						[60.508994199887702, 3.0011694452401656],
						[60.508994199911022, 3.0011722006080852],
						[60.508994204952707, 3.0011722325401293],
						[60.50899422477633, 3.0011722927714262],
						[60.50899424134407, 3.0011723207394287],
						[60.50900263424662, 3.0011834122845427],
						[60.509444228635211, 3.0019084035141881],
						[60.509444230584329, 3.0019084068046262],
						[60.509444234400945, 3.0019084115746795],
						[60.509444236268401, 3.0019084130542848],
						[60.509563735905488, 3.0020807143080304],
						[60.509563737854606, 3.0020807175985089],
						[60.509563744431574, 3.002080723682591],
						[60.509563746462348, 3.0020807287839113],
						[60.509894245998034, 3.0024779282828162],
						[60.50989424689093, 3.0024779281172145],
						[60.510324847082636, 3.002991827087016],
						[60.510324850006263, 3.0029918320229045],
						[60.510324855690286, 3.0029918382729179],
						[60.510324858532286, 3.0029918413979177],
						[60.51034155882968, 3.0030084416919021],
						[60.510360957712393, 3.0030279416185865],
						[60.510360958605304, 3.0030279414529955],
						[60.510360960391104, 3.0030279411218146],
						[60.510360959579849, 3.0030279430982931],
						[60.510791557415068, 3.0034501392844204],
						[60.511213754036859, 3.0039001375409615],
						[60.511213754929756, 3.0039001373753882],
						[60.511213756878824, 3.003900140666123],
						[60.511213758664653, 3.0039001403349781],
						[60.511238758978763, 3.0039251417973194],
						[60.511263759207047, 3.0039501414864667],
						[60.511263760181585, 3.0039501431318389],
						[60.511263763916467, 3.0039501460914413],
						[60.51126376578393, 3.0039501475712549],
						[60.511688761212987, 3.0043362443252506],
						[60.512130456311773, 3.0047945384947297],
						[60.512130463863151, 3.0047945462252823],
						[60.512130479777149, 3.0047945597098269],
						[60.512130488139817, 3.0047945654638304],
						[60.512138772250658, 3.0048001550677528],
						[60.512149859300685, 3.0048112402581895],
						[60.512149861249732, 3.0048112435490917],
						[60.512149865959138, 3.0048112481543043],
						[60.512149868801103, 3.0048112512796519],
						[60.512588765140954, 3.0051918479591482],
						[60.512938761994825, 3.0055251456486429],
						[60.512938766622597, 3.0055251484430299],
						[60.512938776041352, 3.0055251576539002],
						[60.512938780669124, 3.0055251604483004],
						[60.513035970148174, 3.0055973517404402],
						[60.513163760063009, 3.0057223430961431],
						[60.513163764772386, 3.0057223477016435],
						[60.513163774109508, 3.0057223551015815],
						[60.513163777925982, 3.0057223598726006],
						[60.513485972217033, 3.0059695545492766],
						[60.513697067311135, 3.0061584493746625],
						[60.513697071938893, 3.0061584521692146],
						[60.513697082250516, 3.006158461214949],
						[60.51369708695988, 3.0061584658205707],
						[60.513935976653208, 3.0063223576263374],
						[60.514294269309964, 3.0066334510786534],
						[60.514294274019321, 3.0066334556844376],
						[60.514294283274815, 3.00663346127376],
						[60.514294287984157, 3.00663346587953],
						[60.514380487621693, 3.0066918667598621],
						[60.514380489407515, 3.00669186642883],
						[60.514380491193315, 3.006691866097801],
						[60.514380491274927, 3.0066918679089225],
						[60.514427691396264, 3.0067223684149176],
						[60.514427697891442, 3.0067223726897039],
						[60.514427710800156, 3.006722379428135],
						[60.514427717213721, 3.0067223818917777],
						[60.514830499249648, 3.0069057732764994],
						[60.515041583010429, 3.0070584626792698],
						[60.515041587638159, 3.007058465474044],
						[60.515041599653955, 3.0070584723782323],
						[60.515041606149104, 3.0070584766531616],
						[60.51528049224347, 3.0071862685473452],
						[60.515713776602659, 3.0075140580431503],
						[60.515727674860457, 3.007525155690669],
						[60.515727688825223, 3.0075251658865843],
						[60.515727715617103, 3.0075251810102666],
						[60.515727731204507, 3.007525187252754],
						[60.515780530977516, 3.0075445872903153],
						[60.515780533737846, 3.0075445886050125],
						[60.5157805410443, 3.0075445909034024],
						[60.515780544697542, 3.0075445920525987],
						[60.51617774303098, 3.0076584922527068],
						[60.516241642388486, 3.007677990512406],
						[60.516241647909141, 3.0076779931418547],
						[60.516241660654622, 3.0076779962585274],
						[60.516241666986588, 3.0076779969112581],
						[60.516627748609871, 3.0077418940960308],
						[60.516741631153486, 3.0077834872772069],
						[60.516741641220243, 3.0077834908904642],
						[60.516741658593475, 3.0077834968022334],
						[60.516741668578661, 3.0077834986042435],
						[60.517074936076057, 3.0078362928385296],
						[60.517269306891684, 3.0079390766661498],
						[60.517269315172641, 3.0079390806105191],
						[60.517269330841607, 3.0079390886647537],
						[60.517269339933883, 3.0079390906323145],
						[60.517524901757078, 3.008019578961131],
						[60.517922072440967, 3.0083501541712327],
						[60.517922076175786, 3.0083501571319058],
						[60.517922082670886, 3.0083501614074462],
						[60.517922087298622, 3.0083501642026258],
						[60.517974872363027, 3.0083862540710897],
						[60.518044258018058, 3.0084557416436652],
						[60.518044264594756, 3.0084557477305727],
						[60.518044278641057, 3.0084557597388808],
						[60.518044286029081, 3.0084557638489615],
						[60.518343721869343, 3.008663672541469],
						[60.518138908186543, 3.0086554002779673],
						[60.518138875067251, 3.0086554045896614],
						[60.518138812889809, 3.0086554234191887],
						[60.518138782938742, 3.0086554381024975],
						[60.51791100489973, 3.0088192215886114],
						[60.517791629713692, 3.0088637135596348],
						[60.517791615590248, 3.008863719829959],
						[60.517791589210773, 3.0088637338509794],
						[60.517791576143416, 3.0088637435784809],
						[60.517485979627487, 3.0091054405714579],
						[60.517466584074164, 3.0091192372883526],
						[60.517466570195445, 3.0091192489924214],
						[60.517466545279859, 3.0091192755267619],
						[60.517466534161443, 3.0091192885457247],
						[60.517460934041544, 3.0091276886517826],
						[60.517460929091939, 3.009127698701124],
						[60.517460919029581, 3.0091277151772391],
						[60.517460914891281, 3.009127723249831],
						[60.517260915255797, 3.0096138241097727],
						[60.517260912091949, 3.0096138338280904],
						[60.517260905682747, 3.0096138514534214],
						[60.517260904304742, 3.0096138608408269],
						[60.517180405232736, 3.0100166530879151],
						[60.517119208344276, 3.0102332468032289],
						[60.517119205991762, 3.0102332545446995],
						[60.517119202179636, 3.010233269862236],
						[60.517119200801595, 3.0102332792495736],
						[60.51704420088933, 3.0109277786801565],
						[60.517044201378617, 3.0109277895478961],
						[60.517044201464252, 3.010927811448799],
						[60.517044201060614, 3.0109278224819578],
						[60.517052600379841, 3.0110028144465777],
						[60.517074799998397, 3.0118388944597512],
						[60.51706650134085, 3.011949984381058],
						[60.517066499636556, 3.011949986523129],
						[60.517066499718076, 3.0119499883344223],
						[60.517066499799618, 3.0119499901457139],
						[60.517027600169889, 3.0127193897556501],
						[60.517027600251417, 3.0127193915669426],
						[60.517027599603068, 3.0127193971661872],
						[60.517027599766116, 3.012719400788789],
						[60.517027600311273, 3.0127500000837859],
						[60.517027599956513, 3.0127805995445538],
						[60.517027600038027, 3.0127806013558471],
						[60.517027600038027, 3.0127806013558471],
						[60.517035899598191, 3.0136111014228275],
						[60.517035900221401, 3.0136582934597036],
						[60.517033101372228, 3.0136971769184382],
						[60.517013703651742, 3.0138193581742847],
						[60.516883106827358, 3.0143110485039997],
						[60.516883105934433, 3.0143110486693216],
						[60.516883106015932, 3.0143110504806123],
						[60.516883106097445, 3.0143110522919168],
						[60.516819206808357, 3.0145693475123685],
						[60.516724808407268, 3.0148916424402432],
						[60.516563714744294, 3.0154193211632871],
						[60.516547028262536, 3.0154498986069331],
						[60.516527643277499, 3.0154804732894358],
						[60.516249860872762, 3.0157526562158719],
						[60.516113762100936, 3.0158831563304256],
						[60.516113740025624, 3.0158831859851647],
						[60.516113708864829, 3.0158832538482931],
						[60.516113699697854, 3.0158832902454327],
						[60.516091499591326, 3.0163443902919274],
						[60.516091499754275, 3.0163443939144314],
						[60.516091499917223, 3.0163443975369542],
						[60.516091499998694, 3.0163443993482071],
						[60.516091500454863, 3.0163916483554165],
						[60.515866525910802, 3.0167943021969728],
						[60.515663726353679, 3.0171555023322201],
						[60.515663723756376, 3.0171555046391632],
						[60.515663722133453, 3.0171555085920998],
						[60.515663721321992, 3.0171555105685663],
						[60.515627621178851, 3.0172277115236081],
						[60.515627620285919, 3.017227711688844],
						[60.515627618662997, 3.0172277156417717],
						[60.515627617770079, 3.0172277158070062],
						[60.515594217706429, 3.0172999155336795],
						[60.515594212270535, 3.0172999348026215],
						[60.515594203021685, 3.0172999693875644],
						[60.515594200264566, 3.0172999881608087],
						[60.515552600040209, 3.017986087728767],
						[60.515552600203115, 3.0179860913512209],
						[60.515552599554539, 3.0179860969501351],
						[60.515552599717424, 3.0179861005725912],
						[60.515552599952237, 3.018211093468905],
						[60.51551370018916, 3.0188193872997822],
						[60.515513700270596, 3.0188193891110124],
						[60.515513700433473, 3.0188193927334672],
						[60.515513699621991, 3.0188193947099036],
						[60.515505400166397, 3.019122192316837],
						[60.515491499826339, 3.0194721926391712],
						[60.515483100357649, 3.0196638891524157],
						[60.515463703210472, 3.0200332451421494],
						[60.515335923675202, 3.0202749047372626],
						[60.515216524977788, 3.0204916031267905],
						[60.515216524166263, 3.0204916051031541],
						[60.51521652075737, 3.0204916093861738],
						[60.515216519945845, 3.0204916113625346],
						[60.515105419771814, 3.0207193125520622],
						[60.515105419771814, 3.0207193125520622],
						[60.515105419853228, 3.0207193143632933],
						[60.515105418960282, 3.0207193145284301],
						[60.514999819001162, 3.0209416138974494],
						[60.514999812671796, 3.0209416333305783],
						[60.514999802691875, 3.0209416717014475],
						[60.514999799934216, 3.0209416904740647],
						[60.514980399636187, 3.0213721908634334],
						[60.514980399717579, 3.0213721926746433],
						[60.5149803998804, 3.0213721962970719],
						[60.5149803999618, 3.0213721981082799],
						[60.514977600342917, 3.0218527944406222],
						[60.514960900024569, 3.0222471914848392],
						[60.514960900105955, 3.0222471932960482],
						[60.514960900187333, 3.0222471951072589],
						[60.514960900187333, 3.0222471951072589],
						[60.514949800391705, 3.0227638925716165],
						[60.514933100185786, 3.0231055899726944],
						[60.514933100267157, 3.0231055917839065],
						[60.514908102016918, 3.0236749549559416],
						[60.51484151591935, 3.0238332223203375],
						[60.514841515107769, 3.0238332242965926],
						[60.514841514296208, 3.0238332262728487],
						[60.514841514296208, 3.0238332262728487],
						[60.514766591129288, 3.0240247302341476],
						[60.51440838322366, 3.0238609186475811],
						[60.514408284593848, 3.0238609277465214],
						[60.514408143111581, 3.0238610251199614],
						[60.514408100259125, 3.0238611133944628],
						[60.51445809864304, 3.0245860997397096],
						[60.514438700768274, 3.0248332831667883],
						[60.514438700849617, 3.024833284977996],
						[60.514438700038042, 3.0248332869542001],
						[60.514438700119399, 3.0248332887653886],
						[60.514410901355411, 3.0253055778158338],
						[60.514380402717904, 3.0254943672025632],
						[60.514380402231701, 3.0254943764235143],
						[60.514380400203663, 3.0254943914080408],
						[60.514380399717474, 3.0254944006289906],
						[60.514380400064105, 3.0256249918340252],
						[60.51431650940534, 3.0263442921613803],
						[60.514294350637243, 3.0263608865491145],
						[60.513866736867314, 3.0262804021766554],
						[60.513866685725716, 3.0262804061466975],
						[60.513866591806085, 3.0262804399344319],
						[60.513866549839655, 3.0262804677759916],
						[60.513827649864766, 3.0263248669352483],
						[60.513755451069535, 3.026405467816168],
						[60.513755444251345, 3.0264054763805484],
						[60.513755429803382, 3.0264054954854638],
						[60.513755423959452, 3.0264055056960504],
						[60.513580423840651, 3.026730504792341],
						[60.513419265374068, 3.0270304285099887],
						[60.513294348969843, 3.027063706589233],
						[60.513294348076911, 3.0270637067541815],
						[60.513066682232491, 3.0271247977333147],
						[60.512969416940614, 3.027116501128635],
						[60.512969380167362, 3.0271165042691552],
						[60.512969310843218, 3.0271165243793239],
						[60.512969278292324, 3.0271165413490064],
						[60.512897078413054, 3.0271720417736598],
						[60.512897073218028, 3.0271720463855405],
						[60.512897062746703, 3.0271720537982039],
						[60.51289705933759, 3.0271720580801924],
						[60.512755462149229, 3.0273137544532998],
						[60.51259156707593, 3.0274609504873782],
						[60.512519267912538, 3.027524849620491],
						[60.512519259308384, 3.027524858514175],
						[60.51251924380464, 3.0275248741605751],
						[60.512519237067679, 3.0275248845354903],
						[60.512316536672763, 3.0278109841886014],
						[60.512188737868279, 3.0279887813081401],
						[60.512072041539263, 3.028147076457103],
						[60.512047044895731, 3.0281776725965881],
						[60.512047044084106, 3.0281776745725617],
						[60.512047042379535, 3.0281776767134438],
						[60.512047040674922, 3.028177678854334],
						[60.512010940828567, 3.0282248775351874],
						[60.512010935796113, 3.0282248857689127],
						[60.512010925649953, 3.0282249004252728],
						[60.512010922403427, 3.0282249083291761],
						[60.511813722045346, 3.0286110085744657],
						[60.511622022123341, 3.0289805082835719],
						[60.511622021311702, 3.028980510259518],
						[60.511622019688389, 3.0289805142113808],
						[60.51162201887675, 3.02898051618731],
						[60.511585920231042, 3.0290582136991566],
						[60.51154702715975, 3.029135997889576],
						[60.511330435083664, 3.0294526854618802],
						[60.511172123896898, 3.0296803575279689],
						[60.510955669559813, 3.0295998124258032],
						[60.510955664120836, 3.0295998116039731],
						[60.510955653973284, 3.0295998061734148],
						[60.510955648534321, 3.0295998053515834],
						[60.510944486901558, 3.0295970161963943],
						[60.510722350046244, 3.029433161552515],
						[60.510641888829966, 3.0293027257799756],
						[60.510608498051432, 3.0291332696948077],
						[60.51057799880833, 3.0288388800812518],
						[60.510552998830292, 3.0285721801713268],
						[60.510552997693445, 3.0285721749032453],
						[60.510552996231397, 3.0285721623912316],
						[60.510552995013249, 3.0285721553121712],
						[60.510472400021705, 3.0282221746245885],
						[60.510464099887209, 3.0278443951449843],
						[60.510464097125592, 3.0278443737430476],
						[60.510464086163367, 3.0278443301176399],
						[60.510464078044073, 3.0278443097051562],
						[60.510277978112818, 3.0274805093369763],
						[60.510277960820112, 3.0274804851409582],
						[60.51027792014672, 3.0274804415252947],
						[60.51027789595441, 3.0274804240815283],
						[60.509972300197269, 3.0273137266982273],
						[60.509827905421162, 3.0272248300870932],
						[60.5098278770081, 3.0272248189011601],
						[60.509827817747059, 3.0272248024569093],
						[60.509827787061745, 3.0272248008204521],
						[60.509783351641381, 3.0272275956186263],
						[60.509380754712694, 3.0269720615644311],
						[60.509102991371464, 3.0263999356663156],
						[60.509052995961262, 3.0261527603763527],
						[60.509052995879919, 3.0261527585654617],
						[60.509052994905652, 3.0261527569194975],
						[60.509052994905652, 3.0261527569194975],
						[60.508930794919031, 3.0256055555743337],
						[60.508930794837681, 3.0256055537634503],
						[60.508930793782056, 3.025605550306627],
						[60.508930792807789, 3.0256055486607027],
						[60.508911292997972, 3.0255332478413948],
						[60.508911283904069, 3.0255332257845313],
						[60.508911260358637, 3.0255331826606051],
						[60.508911245988443, 3.0255331634043903],
						[60.508872357373463, 3.0254915757845491],
						[60.50864736665693, 3.0251526890769416],
						[60.508647333777134, 3.0251526586335071],
						[60.508647258845976, 3.0251526140482716],
						[60.508647216875964, 3.0251526017173349],
						[60.508480616589388, 3.0251387013739452],
						[60.508480560415578, 3.0251387135778729],
						[60.508480462844936, 3.0251387662958011],
						[60.508480423233976, 3.0251388064798768],
						[60.508391522596561, 3.0253082068598034],
						[60.508294227267626, 3.0254887995318147],
						[60.508147029791061, 3.0257248934220238],
						[60.508147029872397, 3.0257248952328823],
						[60.508147028979458, 3.0257248953978322],
						[60.50803312871016, 3.025910995624725],
						[60.508033127168304, 3.0259110013871151],
						[60.508033121324395, 3.0259110115959205],
						[60.508033117915311, 3.0259110158773868],
						[60.507916519457581, 3.0261638168005938],
						[60.507805421323319, 3.0263999103692054],
						[60.507691531661209, 3.0266137897501606],
						[60.507583144247647, 3.0267498749926944],
						[60.507583143354722, 3.0267498751576092],
						[60.507583141650173, 3.0267498772982586],
						[60.507583140757198, 3.0267498774631805],
						[60.50741654127696, 3.0269665779282398],
						[60.507152648822903, 3.0273109675155121],
						[60.507141559408929, 3.0273220586405221],
						[60.507141535788897, 3.0273220940412009],
						[60.507141506895614, 3.0273221724092947],
						[60.507141500648089, 3.0273222137308262],
						[60.507205400672483, 3.0282222145334461],
						[60.50720540197198, 3.0282222234225595],
						[60.50720540457089, 3.0282222412007997],
						[60.507205406844598, 3.0282222517358406],
						[60.507288314102155, 3.0285346718340986],
						[60.507133473626858, 3.0282638008154454],
						[60.50713347265259, 3.028263799169514],
						[60.507133470704105, 3.0282637958776779],
						[60.507133469729865, 3.0282637942317594],
						[60.507105772348233, 3.0282192975229636],
						[60.506683472763271, 3.0274887998491176],
						[60.506683414880094, 3.0274887539386719],
						[60.506683282557361, 3.0274887144712594],
						[60.506683209092095, 3.0274887225601463],
						[60.506569322661747, 3.0275470152371438],
						[60.506383238032441, 3.0276081101287717],
						[60.50623618140304, 3.027655364637682],
						[60.506000167052143, 3.0273081884183424],
						[60.505786271593536, 3.0269776963868584],
						[60.505466875336964, 3.0263999023410637],
						[60.505466874362696, 3.0263999006952842],
						[60.505466870546947, 3.0263998959228626],
						[60.505466869572693, 3.0263998942770671],
						[60.505341870214863, 3.0261998946931525],
						[60.505341824346175, 3.0261998556962895],
						[60.505341716944187, 3.0261998098070446],
						[60.505341657278116, 3.0261998043955209],
						[60.505252756711073, 3.026219204943267],
						[60.505252755818113, 3.0262192051081884],
						[60.504944356867874, 3.0262887053752792],
						[60.504891802754869, 3.0262997733393027],
						[60.50444737475727, 3.0254999029492149],
						[60.504447259237295, 3.0254998567371532],
						[60.504447071721636, 3.02549991145488],
						[60.504447000781603, 3.0255000158409908],
						[60.504513699355336, 3.02639718836399],
						[60.504494258752487, 3.0265024606485431],
						[60.504441825312227, 3.0264665425594695],
						[60.504366869433781, 3.0263970842204806],
						[60.504066949068367, 3.0256361862030605],
						[60.504322300857972, 3.0254862727467406],
						[60.504322349718521, 3.0254861779162088],
						[60.504322332573906, 3.0254859966919958],
						[60.504322266650078, 3.0254859121089734],
						[60.503989029859135, 3.0253665347671923],
						[60.503769570319946, 3.0250359944109562],
						[60.503544572641083, 3.0246470997665882],
						[60.503544564928134, 3.0246470884121122],
						[60.503544545849117, 3.0246470645523598],
						[60.503544536350276, 3.0246470535277634],
						[60.50346687564285, 3.0245748913429846],
						[60.503350191801175, 3.0241832435001395],
						[60.503350188715594, 3.02418323494217],
						[60.503350182625752, 3.0241832196368161],
						[60.503350179621521, 3.0241832128894104],
						[60.503097379919424, 3.0236666117665409],
						[60.503097379026478, 3.0236666119315156],
						[60.503097378945121, 3.0236666101209466],
						[60.50309457936811, 3.0236610116391489],
						[60.503094548922782, 3.023660975275563],
						[60.503094477156196, 3.0236609209880339],
						[60.503094434942007, 3.0236609032290769],
						[60.502686135144984, 3.0235887038854372],
						[60.502686126052886, 3.0235887019141119],
						[60.502686108842994, 3.0235886996170001],
						[60.502686099832253, 3.0235886994562016],
						[60.502647328980544, 3.0235887008121742],
						[60.502269581674348, 3.0227555171696281],
						[60.50226958070003, 3.0227555155241213],
						[60.502197380642308, 3.0226027141702043],
						[60.50219737966799, 3.0226027125247037],
						[60.502197377719362, 3.0226027092337042],
						[60.502197376745045, 3.0226027075881845],
						[60.501797378356045, 3.0218443092053207],
						[60.501747379261921, 3.0217443103354622],
						[60.501747342321408, 3.0217442696988446],
						[60.501747253425592, 3.0217442149320863],
						[60.501747199603066, 3.0217441993214882],
						[60.501697203055635, 3.0217441999395676],
						[60.501355608250122, 3.0217331006963146],
						[60.501300125326473, 3.0217304057892687],
						[60.500886277633363, 3.0209332088440921],
						[60.500850178584983, 3.0208610115589201],
						[60.50085013790968, 3.0208609679637024],
						[60.500850040895777, 3.0208609128756487],
						[60.500849982771285, 3.0208609017129042],
						[60.500816685699803, 3.0208637006163919],
						[60.500422193767292, 3.020885900451725],
						[60.500400121586594, 3.0208858999813333],
						[60.499955799950875, 3.0200221523543189],
						[60.499955800410035, 3.0200193995430475],
						[60.499955771095806, 3.0200193282941501],
						[60.499955671153423, 3.0200192281158831],
						[60.499955599632315, 3.0200191993515699],
						[60.499950000330365, 3.0200191993375816],
						[60.499949998544508, 3.0200191996676859],
						[60.49994999586572, 3.0200192001628432],
						[60.499949994972788, 3.0200192003278965],
						[60.499838894776161, 3.0200220007580922],
						[60.499838804758014, 3.0200220612071078],
						[60.499838733911311, 3.0200222276369471],
						[60.499838752271252, 3.0200223355931746],
						[60.499955413635995, 3.0201500922750504],
						[60.500105390341332, 3.0209332653289866],
						[60.500022077367667, 3.0210776140338571],
						[60.499955679243925, 3.0210858893045458],
						[60.499819571732566, 3.0209331921705078],
						[60.499502985232894, 3.0201721235616503],
						[60.499502885214326, 3.0201720617315115],
						[60.499502695017242, 3.0201720768074329],
						[60.499502605650257, 3.0201721517381088],
						[60.499377652240589, 3.0206803646499401],
						[60.4990528733777, 3.0206137108408435],
						[60.498836201717424, 3.0204859272049909],
						[60.498836148869096, 3.0204859132429034],
						[60.49883604366336, 3.0204859162589504],
						[60.498835991224524, 3.020485931426804],
						[60.498608272800752, 3.0206330791943814],
						[60.49842786372394, 3.0205720115898966],
						[60.498427782952966, 3.0205720173909518],
						[60.498427652996718, 3.0205720906926881],
						[60.498427604622961, 3.0205721562180647],
						[60.498347004651272, 3.0209332558401667],
						[60.498347003921133, 3.0209332596257377],
						[60.498347003353786, 3.0209332670318827],
						[60.498347001812093, 3.0209332727927682],
						[60.498305402384496, 3.0212305712813046],
						[60.498305401005574, 3.0212305806627211],
						[60.498305399870837, 3.0212305954749676],
						[60.498305400277808, 3.021230604526365],
						[60.498316499706839, 3.0218417040045185],
						[60.498316499950981, 3.021841709435356],
						[60.498316501332219, 3.0218417201320551],
						[60.498316501657754, 3.0218417273731859],
						[60.498377600235436, 3.0222917133396212],
						[60.498377600013136, 3.0223000003437424],
						[60.498377601394338, 3.0223000110404823],
						[60.498377603263805, 3.0223000325989591],
						[60.498377603670676, 3.0223000416503965],
						[60.498474800883258, 3.0227528261412875],
						[60.498485899827308, 3.0229972094361917],
						[60.4984859023455, 3.0229972253989303],
						[60.498485910142037, 3.0229972586398062],
						[60.498485915339039, 3.0229972741076647],
						[60.498608114854022, 3.0232972755100307],
						[60.498608116883993, 3.0232972806110241],
						[60.49860812281117, 3.023297292293432],
						[60.498608124841141, 3.0232972973944232],
						[60.498702624943689, 3.0234667968496236],
						[60.498702647838719, 3.0234668254763983],
						[60.498702700284056, 3.023466870548476],
						[60.498702729834413, 3.0234668869937864],
						[60.499016622979994, 3.0235834843337068],
						[60.499052713009668, 3.0236001803249137],
						[60.499080511106094, 3.0236140783269794],
						[60.499080515733525, 3.0236140811228904],
						[60.49908052766714, 3.0236140862198941],
						[60.499080533187502, 3.0236140888508567],
						[60.499222133020204, 3.0236640896331388],
						[60.499222138459196, 3.0236640904538019],
						[60.499222145765408, 3.0236640927548919],
						[60.499222150311468, 3.0236640937404879],
						[60.499472149448835, 3.0237279937464536],
						[60.499502748092354, 3.0237362928190961],
						[60.499502760837565, 3.0237362959408989],
						[60.499502787058198, 3.0237362983988203],
						[60.499502799722045, 3.0237362997102735],
						[60.499538896592377, 3.0237362998508206],
						[60.499911093598577, 3.023747400904564],
						[60.499911108859898, 3.023747399910798],
						[60.499911140112701, 3.0237473941375481],
						[60.499911155292665, 3.0237473913333939],
						[60.49995001498926, 3.0237363046713184],
						[60.499991672602221, 3.023741898321088],
						[60.500338873006669, 3.0237890982979905],
						[60.50033889102815, 3.0237890986197509],
						[60.500338926989727, 3.0237890974528883],
						[60.500338943955555, 3.0237890943188033],
						[60.500399917227256, 3.0237752240206173],
						[60.50056373901996, 3.0239973193792267],
						[60.500563739912906, 3.0239973192142777],
						[60.500563741861491, 3.0239973225052221],
						[60.500563742835787, 3.0239973241506855],
						[60.500849827790248, 3.0243584049362209],
						[60.500949806871674, 3.02457506058429],
						[60.501002603386091, 3.0248861324437066],
						[60.501002602574474, 3.0248861344190736],
						[60.501002602655824, 3.0248861362295139],
						[60.501041502625547, 3.0250972353920909],
						[60.501041503762508, 3.0250972406585106],
						[60.501041505873744, 3.0250972475704372],
						[60.501041506929354, 3.0250972510264176],
						[60.501144198638421, 3.0254861207458359],
						[60.501113701395106, 3.0260166690348247],
						[60.50101921106279, 3.0263943297743263],
						[60.500933120812427, 3.0265666118644856],
						[60.500933119919495, 3.0265666120293639],
						[60.500933120000816, 3.0265666138398055],
						[60.500849819587543, 3.0267388124832304],
						[60.500849818775919, 3.0267388144585583],
						[60.500849818775919, 3.0267388144585583],
						[60.500713718928885, 3.0270277155159615],
						[60.500583121456238, 3.027305510798274],
						[60.500488722693127, 3.0274832063185495],
						[60.500399826022026, 3.0276499017550766],
						[60.500247028138389, 3.0279054964521817],
						[60.500247028219697, 3.0279054982625873],
						[60.500063727757436, 3.0282137981999635],
						[60.500063727757436, 3.0282137981999635],
						[60.500063726052858, 3.0282138003400347],
						[60.500063726134151, 3.0282138021504319],
						[60.500002641379304, 3.0283220742108172],
						[60.499949862015804, 3.0283720556392857],
						[60.499949860311204, 3.0283720577793258],
						[60.499949855116135, 3.0283720623890451],
						[60.499949853411536, 3.0283720645290848],
						[60.499783152754112, 3.0285526644934646],
						[60.499783151942474, 3.0285526664686646],
						[60.499783150237874, 3.0285526686086812],
						[60.499783149344935, 3.0285526687734965],
						[60.499710949141189, 3.0286359677742052],
						[60.499499849906378, 3.0288748677663824],
						[60.499499847308819, 3.0288748700711716],
						[60.499499841383354, 3.0288748784663051],
						[60.499499837974142, 3.0288748827462681],
						[60.499427639290488, 3.0289748820889684],
						[60.499316538851708, 3.0291248817649357],
						[60.499316538040041, 3.0291248837400913],
						[60.499316536335442, 3.0291248858800461],
						[60.499316534630843, 3.0291248880199992],
						[60.499163734682931, 3.029349888552058],
						[60.49916373378997, 3.0293498887168528],
						[60.499163732978303, 3.0293498906919942],
						[60.499163732166636, 3.0293498926671369],
						[60.499052632276275, 3.0295220913267009],
						[60.499052629678715, 3.0295220936314013],
						[60.499052627243707, 3.0295220995567971],
						[60.499052624727433, 3.0295221036718583],
						[60.49891922461179, 3.0297638035942152],
						[60.49891922461179, 3.0297638035942152],
						[60.498919223718858, 3.0297638037589918],
						[60.498772024750927, 3.0300332045314051],
						[60.498683125258232, 3.0301943020843294],
						[60.498602650691701, 3.0303359578919422],
						[60.498388795280896, 3.0304692301457741],
						[60.49837489730097, 3.0304776280989225],
						[60.498374896408023, 3.0304776282636761],
						[60.498152735077454, 3.03061370503577],
						[60.497999982423345, 3.0306276012902233],
						[60.497999968054913, 3.0306276021159002],
						[60.497999942240639, 3.0306276087038864],
						[60.497999928927662, 3.0306276129854166],
						[60.497708254792251, 3.0307387029244577],
						[60.497608291047847, 3.0307470006677453],
						[60.497258325713872, 3.0307497994453549],
						[60.497147296339342, 3.0307192184227292],
						[60.496808430133278, 3.0304276478798537],
						[60.496808417063136, 3.0304276375147139],
						[60.496808388406528, 3.0304276208991148],
						[60.496808372820041, 3.0304276146486591],
						[60.496494483705888, 3.0303054180360891],
						[60.496361194983486, 3.0302331243809562],
						[60.496361162998859, 3.0302331138553207],
						[60.496361094971185, 3.0302331026788196],
						[60.496361060795337, 3.0302331035086296],
						[60.49604166089005, 3.0302970034990584],
						[60.496041637835887, 3.0302970114027676],
						[60.496041596436157, 3.0302970318170326],
						[60.496041576223391, 3.0302970428468678],
						[60.495910975690563, 3.0303998427311476],
						[60.495910961890978, 3.0303998562280468],
						[60.495910936240215, 3.0303998865127162],
						[60.495910925282004, 3.0303999031357578],
						[60.495769225064628, 3.0306555026691897],
						[60.495769224252946, 3.030655504644086],
						[60.495613729561427, 3.0309415932463195],
						[60.495524838258596, 3.0310665817799523],
						[60.495460938738958, 3.0311526815650365],
						[60.495460938738958, 3.0311526815650365],
						[60.495255439239095, 3.0314331810765962],
						[60.495255438346163, 3.0314331812413018],
						[60.495255438427407, 3.0314331830514591],
						[60.495255436641514, 3.031433183380873],
						[60.495135940667076, 3.0315998784709914],
						[60.495013781138823, 3.0317526287027312],
						[60.494972136625911, 3.0317665095703807],
						[60.494716637701138, 3.0318498106150669],
						[60.494716626173989, 3.0318498145661827],
						[60.494716602389296, 3.0318498262533398],
						[60.494716590050437, 3.0318498321792609],
						[60.494602691065381, 3.0319248327049766],
						[60.494563799837628, 3.0319498275201182],
						[60.494494569371156, 3.0319857871565166],
						[60.494480799329367, 3.0318499798136256],
						[60.494480790723365, 3.0318499485493304],
						[60.494480764744289, 3.0318498912878389],
						[60.494480748264195, 3.0318498651259649],
						[60.494244590046669, 3.0315916118506911],
						[60.494172398355182, 3.0310499744939094],
						[60.494172398273918, 3.0310499726838152],
						[60.494172398192667, 3.0310499708737351],
						[60.494172398192667, 3.0310499708737351],
						[60.494155798109986, 3.0309388693494257],
						[60.494155797135782, 3.0309388677040427],
						[60.494155796973274, 3.0309388640838906],
						[60.494155795999077, 3.0309388624385241],
						[60.494144595841924, 3.0308805624649819],
						[60.494144595760666, 3.0308805606549027],
						[60.494114095918107, 3.0307277617970332],
						[60.494114088611077, 3.0307277394190728],
						[60.494114072454913, 3.0307277004228097],
						[60.494114060764396, 3.0307276806785208],
						[60.493908460744066, 3.0304498812466969],
						[60.493908425186724, 3.0304498513049811],
						[60.493908345062202, 3.0304498113341585],
						[60.493908300413764, 3.0304497994950026],
						[60.493872201068989, 3.0304497992398747],
						[60.49366670310976, 3.0304470002796857],
						[60.493666649695321, 3.0304470137832387],
						[60.493666560320982, 3.0304470685961524],
						[60.493666521600971, 3.0304471085896698],
						[60.493541522266767, 3.0306888083217403],
						[60.493541522266767, 3.0306888083217403],
						[60.49354152145505, 3.0306888102965197],
						[60.493541520562118, 3.0306888104612337],
						[60.493416530698525, 3.0309387921151041],
						[60.493294244792281, 3.031091574340945],
						[60.493216545654597, 3.0311859728990966],
						[60.493216544842909, 3.0311859748738237],
						[60.493216543949949, 3.0311859750385239],
						[60.493216543056995, 3.0311859752032251],
						[60.493016545146084, 3.0314387743556774],
						[60.492897046185043, 3.0315831710787911],
						[60.492897043587448, 3.0315831733828622],
						[60.492897041071068, 3.0315831774969628],
						[60.492897040259365, 3.0315831794716597],
						[60.492766564309171, 3.0317581478157618],
						[60.492733198593804, 3.0317776273095371],
						[60.492733187310414, 3.0317776366904092],
						[60.492733163769415, 3.0317776538068251],
						[60.492733152567268, 3.0317776649976986],
						[60.492669275551762, 3.031847040032583],
						[60.492394308381485, 3.0319942224351957],
						[60.492316667915553, 3.0320330916174809],
						[60.491930630674048, 3.0319720015994336],
						[60.491930626209296, 3.0319720024227834],
						[60.491930615331377, 3.031972000778917],
						[60.491930609892414, 3.0319719999569856],
						[60.491872282583351, 3.0319692041582624],
						[60.491827936669992, 3.0319276543475309],
						[60.491827934802842, 3.0319276528669223],
						[60.491827930094367, 3.0319276482604067],
						[60.491827928227224, 3.0319276467797973],
						[60.491730752055396, 3.0318470665532757],
						[60.491419584207136, 3.0313527162551783],
						[60.491344591638665, 3.0310916443095541],
						[60.491344589527728, 3.0310916373992383],
						[60.491344585387168, 3.0310916253885369],
						[60.491344583276259, 3.0310916184782188],
						[60.491275188364753, 3.0309360313507772],
						[60.491061293228213, 3.0302110478207727],
						[60.491014093868252, 3.0300249523535965],
						[60.491000194875966, 3.0299666542510173],
						[60.491000190735306, 3.02996664224059],
						[60.49100018261651, 3.0299666218395354],
						[60.491000179450062, 3.029966611474288],
						[60.490975179032482, 3.0299166115309659],
						[60.490975178058264, 3.0299166098857881],
						[60.490975177002781, 3.0299166064307164],
						[60.490975176028577, 3.0299166047855213],
						[60.490769576192825, 3.0295332047351788],
						[60.49076957327015, 3.0295331997997184],
						[60.490769564664689, 3.029533188613069],
						[60.49076956166077, 3.0295331818677198],
						[60.490641862712216, 3.0293581841711164],
						[60.490525165092137, 3.0291887861346249],
						[60.490525155593716, 3.029188775112901],
						[60.490525135703919, 3.0291887532341737],
						[60.490525125393816, 3.0291887441870591],
						[60.490497352230307, 3.0291665661705696],
						[60.490466870562116, 3.0291165951933459],
						[60.490466860982409, 3.029116582361798],
						[60.490466838251201, 3.0291165573576402],
						[60.490466825992648, 3.0291165450202917],
						[60.490186226152169, 3.028888745424819],
						[60.490186212189002, 3.0288887352269906],
						[60.490186181746346, 3.0288887189453075],
						[60.490186166078551, 3.0288887108868345],
						[60.490075065710201, 3.0288498113908524],
						[60.490075063924323, 3.02884981172034],
						[60.490075061082905, 3.0288498085948858],
						[60.490075059297006, 3.0288498089243729],
						[60.489922274884478, 3.0288026146121108],
						[60.489627899234499, 3.0286554267168913],
						[60.489300106638574, 3.0284470315083789],
						[60.489300050137842, 3.0284470163856398],
						[60.489299935920712, 3.0284470192108621],
						[60.489299880152778, 3.0284470404489032],
						[60.48917768017651, 3.0285387405247963],
						[60.489177661181969, 3.0285387586275858],
						[60.489177628794394, 3.0285387992740431],
						[60.489177616375613, 3.0285388234627746],
						[60.489047015650158, 3.0288499227099748],
						[60.488935916357832, 3.0291138223709715],
						[60.488935908160954, 3.0291138603786596],
						[60.488935908084088, 3.0291139388582589],
						[60.488935916122813, 3.0291139775203897],
						[60.489046994195604, 3.0293778255518378],
						[60.489002602442625, 3.02967497026152],
						[60.489002601549679, 3.0296749704262353],
						[60.489002601630951, 3.0296749722360197],
						[60.488952604064032, 3.0300249590957944],
						[60.488872008091718, 3.0303138435978729],
						[60.488727609283281, 3.0307832404620498],
						[60.488727606848201, 3.0307832463854347],
						[60.48872760384517, 3.0307832597125981],
						[60.488727603195983, 3.030783265306602],
						[60.48870810443362, 3.030894361682607],
						[60.488699803805822, 3.0309332581127584],
						[60.488699801858253, 3.0309332748947795],
						[60.488699800560703, 3.0309333061550041],
						[60.488699801291972, 3.0309333224429769],
						[60.488705398858649, 3.030983299509983],
						[60.48863310093337, 3.0316471763296717],
						[60.488608102091121, 3.0318443742182146],
						[60.488608100548944, 3.0318443799768451],
						[60.488608100062201, 3.0318443891903679],
						[60.488608100387154, 3.0318443964294688],
						[60.488594199868601, 3.0324805902725656],
						[60.488572001365405, 3.0327527844834732],
						[60.488571999823179, 3.0327527902420419],
						[60.488572000310555, 3.0327528011006892],
						[60.488571999661289, 3.0327528066946479],
						[60.488594199282723, 3.033391664940801],
						[60.488485913926439, 3.0336638261022002],
						[60.488485909380877, 3.0336638451875362],
						[60.488485902238054, 3.0336638866485561],
						[60.488485900371323, 3.0336639052400973],
						[60.488499798927968, 3.0341138725537879],
						[60.488360936065632, 3.0345720502304112],
						[60.488291661061282, 3.0345998006925647],
						[60.488277903804594, 3.0345997998073799],
						[60.488258464353272, 3.034572084775979],
						[60.488258462567352, 3.0345720851051272],
						[60.488258461593198, 3.034572083459941],
						[60.487827962236764, 3.0339776829235245],
						[60.487827918318779, 3.0339776472254583],
						[60.487827816683108, 3.033977609392843],
						[60.487827760588935, 3.0339776033096637],
						[60.487561095826422, 3.0340303978575673],
						[60.487380630074824, 3.0340026025978264],
						[60.487380597684755, 3.0340026030937142],
						[60.487380534284867, 3.0340026147791934],
						[60.487380503193826, 3.0340026241590556],
						[60.48716101362632, 3.0341248184711449],
						[60.48714716197216, 3.0341303985692165],
						[60.486930730276462, 3.0341276023917119],
						[60.486722383003205, 3.0336610170957892],
						[60.486722382029022, 3.033661015450706],
						[60.486722380080693, 3.0336610121605383],
						[60.486722378213564, 3.0336610106800466],
						[60.486483477884235, 3.0331888105267799],
						[60.48648344273316, 3.0331887695658355],
						[60.486483355709012, 3.0331887162712059],
						[60.486483304566455, 3.0331887001536058],
						[60.48627223175626, 3.0331831012976593],
						[60.486244456725309, 3.0331748083241554],
						[60.486244454939389, 3.0331748086533556],
						[60.486244451286368, 3.0331748075021068],
						[60.486244449419246, 3.0331748060216612],
						[60.486033348714031, 3.0331220061347848],
						[60.486033332397191, 3.0331220036686766],
						[60.486033297165832, 3.0331220010398798],
						[60.486033280037248, 3.033122000548004],
						[60.485838930173657, 3.0331414967204089],
						[60.485763976188096, 3.033110915104849],
						[60.485763975213935, 3.0331109134598297],
						[60.485763973428, 3.0331109137890282],
						[60.485763971560878, 3.0331109123086057],
						[60.485583443653084, 3.0330415414235365],
						[60.485436282412238, 3.0327499182985855],
						[60.485133486314218, 3.0319943268258376],
						[60.485133471701396, 3.0319943021514844],
						[60.485133440121821, 3.0319942605349963],
						[60.48513342120669, 3.0319942403029221],
						[60.484927986225955, 3.0318387904849993],
						[60.484872397748994, 3.0314666693717744],
						[60.484872392390542, 3.0314666502897536],
						[60.484872377046457, 3.0314666093297595],
						[60.484872367223261, 3.0314665910709233],
						[60.484689076069756, 3.0311860032361144],
						[60.484627981620278, 3.03105271763578],
						[60.484627961324577, 3.0310526867119281],
						[60.484627908638103, 3.0310526362169976],
						[60.484627878926176, 3.0310526161520128],
						[60.484344539839491, 3.0309304427799346],
						[60.484239072646815, 3.0307498995462558],
						[60.484239071672597, 3.0307498979013849],
						[60.484239069724204, 3.0307498946116227],
						[60.484239068831243, 3.0307498947762777],
						[60.483830769798132, 3.0300998941987247],
						[60.483791871185723, 3.0300359964367591],
						[60.483791856816325, 3.0300359771928078],
						[60.483791826616716, 3.0300359462722182],
						[60.483791809812253, 3.0300359329507591],
						[60.483783410155219, 3.0300304321247786],
						[60.483783402849092, 3.0300304298231406],
						[60.483783388967268, 3.0300304214361873],
						[60.483783381579897, 3.0300304173250341],
						[60.483758383847764, 3.0300192180151262],
						[60.483341785734645, 3.0298220190298526],
						[60.48334172241551, 3.0298220124621551],
						[60.483341602922721, 3.0298220381471559],
						[60.483341546749074, 3.0298220703998546],
						[60.483280456396081, 3.0298942603393328],
						[60.483141568571263, 3.0300192485150679],
						[60.482963798075303, 3.0301665246774236],
						[60.482891628247295, 3.0301942129462023],
						[60.4828916238638, 3.0301942155789421],
						[60.482891615908493, 3.0301942188703208],
						[60.482891612336708, 3.0301942195289526],
						[60.482777711656951, 3.0302498191840224],
						[60.482777707354714, 3.0302498236261863],
						[60.482777695015933, 3.0302498295502756],
						[60.482777689739478, 3.0302498323476543],
						[60.482613789787962, 3.0303581324588871],
						[60.482444329559407, 3.0304692062606846],
						[60.48220827740235, 3.0304970008246679],
						[60.482208263115133, 3.0304970034590029],
						[60.48220823640785, 3.0304970102077902],
						[60.482208223094851, 3.0304970144868855],
						[60.481994427977739, 3.0305858717070473],
						[60.481789019037343, 3.0304331398246922],
						[60.481788922110226, 3.030433126682059],
						[60.48178876300409, 3.0304331925059329],
						[60.481788701636738, 3.0304332694983955],
						[60.47664150211039, 3.0644805695875434],
					],
					[
						[60.577372818247234, 3.0103815627448771],
						[60.578110959278121, 3.0110057428000676],
						[60.579830426956704, 3.01301400655294],
						[60.579910941467311, 3.0132109815439678],
						[60.579727938902757, 3.0130331577295699],
						[60.579727936060905, 3.0130331545973119],
						[60.579727930377167, 3.0130331483327866],
						[60.579727925749495, 3.0130331455320931],
						[60.579475133800763, 3.012827651345559],
						[60.579277940910579, 3.0126304581864303],
						[60.579277939043187, 3.0126304567032367],
						[60.579277933359435, 3.0126304504388659],
						[60.579277929624659, 3.0126304474724765],
						[60.579025136784061, 3.0124137546599972],
						[60.578739042997469, 3.0121220598003013],
						[60.578739042022988, 3.0121220581513586],
						[60.578739041130063, 3.012122058317162],
						[60.578739040155583, 3.0121220566682192],
						[60.57857515660055, 3.0119609735050168],
						[60.578216869997263, 3.0113887939730621],
						[60.578216868048273, 3.0113887906752734],
						[60.578216864150313, 3.0113887840796778],
						[60.578216862282908, 3.0113887825966041],
						[60.57812796995028, 3.0112665941921559],
						[60.578097376857144, 3.0112082067192043],
						[60.57809737198464, 3.0112081984747778],
						[60.578097359479358, 3.0112081806686817],
						[60.578097352739469, 3.0112081709411922],
						[60.577677952547731, 3.0107109703064654],
						[60.577677950680325, 3.0107109688234557],
						[60.57767794783841, 3.010710965691576],
						[60.577677946863886, 3.0107109640427345],
						[60.577372818247234, 3.0103815627448771],
					],
					[
						[60.598140676303807, 3.0324876792233844],
						[60.598183454279564, 3.0324362274922638],
						[60.598325152586604, 3.0322668284924892],
						[60.598325167683655, 3.0322668037247582],
						[60.5983251905716, 3.0322667518804773],
						[60.598325199255427, 3.0322667246385517],
						[60.598369598895886, 3.0319000239286114],
						[60.598380797843838, 3.0318056281641166],
						[60.598477992296949, 3.0312028682674641],
						[60.598655745148996, 3.0308945486247238],
						[60.598747259704517, 3.0308445953049779],
						[60.598774984940889, 3.0308419016668307],
						[60.598797151643694, 3.0308473933195708],
						[60.59879717429245, 3.0308473964478084],
						[60.598797216667165, 3.0308473977527575],
						[60.598797239071899, 3.0308473954332014],
						[60.599163939102517, 3.0307751957900328],
						[60.599219300650617, 3.0307641247640169],
						[60.599297003360604, 3.0308944597566896],
						[60.599305400256149, 3.0310583085542535],
						[60.599321999907154, 3.0315972059689908],
						[60.599322001613395, 3.0315972239632507],
						[60.599322010464839, 3.0315972607752983],
						[60.599322015742842, 3.0315972781079474],
						[60.599410916402242, 3.0318083772687059],
						[60.599410915590617, 3.0318083792500903],
						[60.599410917457831, 3.0318083807352618],
						[60.599669217027547, 3.0324000795937249],
						[60.599669217108861, 3.0324000814097127],
						[60.599810917298214, 3.0327194817737171],
						[60.599810917298214, 3.0327194817737171],
						[60.59981091819116, 3.0327194816083427],
						[60.600119211863522, 3.0334056701778036],
						[60.600188707595215, 3.0336333547657408],
						[60.600269205898883, 3.0339389479230898],
						[60.600405405112191, 3.0345472442742043],
						[60.600405405193484, 3.0345472460902374],
						[60.600405407223242, 3.0345472512076812],
						[60.600405408278768, 3.0345472546744365],
						[60.600566506235261, 3.0351167486882806],
						[60.600644204259517, 3.0354611425356692],
						[60.600697001175817, 3.0357194254877871],
						[60.600714037433789, 3.0360274933726608],
						[60.599422366551103, 3.0340804890180832],
						[60.599422363709671, 3.0340804858821357],
						[60.599422358838488, 3.0340804776289056],
						[60.599422355997078, 3.0340804744929368],
						[60.598140676303807, 3.0324876792233844],
					],
				],
			],
		},
		{
			fldNpdidField: "43658",
			wlbNpdidWellbore: "417",
			fldName: "STATFJORD",
			fldCurrentActivitySatus: "Producing",
			wlbName: "33/12-1",
			fldDiscoveryYear: "1974",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43658",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43658",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "STATFJORD UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=417",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "41686",
			fldDateUpdated: "2022-09-13 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[
						[61.168188700619233, 1.8027416957368978],
						[61.16818870209719, 1.8027417233969654],
						[61.1681887027869, 1.8027417363050176],
						[61.16912200287409, 1.8077528336989517],
						[61.169833102174081, 1.8122139302623421],
						[61.169833104742665, 1.812213944607959],
						[61.169833112256605, 1.8122139671555584],
						[61.169833117398802, 1.8122139790458252],
						[61.171702616682964, 1.816508380870123],
						[61.171702625682542, 1.8165083974800353],
						[61.171702649025782, 1.8165084294780121],
						[61.171702662380397, 1.8165084432254732],
						[61.173694262352605, 1.8184168439394084],
						[61.173694264232289, 1.8184168453765945],
						[61.173694268089982, 1.8184168500953639],
						[61.173694270958691, 1.8184168531733282],
						[61.174752629954803, 1.819314018767558],
						[61.175360904449853, 1.8209806464811291],
						[61.175621997701441, 1.8233499949317957],
						[61.175255407309379, 1.8254499437174505],
						[61.174552614738722, 1.8271416233701732],
						[61.174552614738722, 1.8271416233701732],
						[61.17455261394619, 1.8271416254180337],
						[61.17455261394619, 1.8271416254180337],
						[61.173602613965365, 1.8295360264820388],
						[61.173602610795228, 1.8295360346728959],
						[61.173602606236386, 1.8295360506480682],
						[61.173602604055112, 1.8295360604800852],
						[61.17335260362519, 1.8307610601479729],
						[61.173352603028953, 1.8307610658844722],
						[61.173352600945755, 1.8307610775607657],
						[61.173352601240211, 1.830761083094018],
						[61.173255401376721, 1.8320110796325375],
						[61.173088704085465, 1.8333110594315503],
						[61.172677608040466, 1.8347443458793307],
						[61.172677604968129, 1.8347443559138485],
						[61.172677602386251, 1.8347443751703547],
						[61.17267760109533, 1.8347443847986029],
						[61.172622001327802, 1.8355027849940946],
						[61.172622000723422, 1.8355028075330986],
						[61.17262200733493, 1.8355028470942785],
						[61.172622012074882, 1.8355028684145893],
						[61.17315541187007, 1.8369556681708481],
						[61.173155414934506, 1.8369556749393658],
						[61.173155421063363, 1.8369556884763854],
						[61.173155424127799, 1.8369556952449015],
						[61.174249807982378, 1.8389889648706523],
						[61.174696998793799, 1.8419083119282027],
						[61.174583100077911, 1.8444610913850967],
						[61.1745831010576, 1.8444611098305765],
						[61.174583105591211, 1.8444611442683641],
						[61.174583110133817, 1.8444611619023277],
						[61.175763709741197, 1.848130660313295],
						[61.175763713000933, 1.8481306707727398],
						[61.175763721106044, 1.8481306875968078],
						[61.175763725158603, 1.8481306960088415],
						[61.17722201872477, 1.8507861868164548],
						[61.17824981472026, 1.8532694768569218],
						[61.178249817686073, 1.8532694817833473],
						[61.178249823715539, 1.8532694934809821],
						[61.178249826779208, 1.8532695002522033],
						[61.179699827199173, 1.8557834005126623],
						[61.179699851228776, 1.8557834286557646],
						[61.17969990859725, 1.8557834734889309],
						[61.179699942924671, 1.8557834918212728],
						[61.183597085176281, 1.8569529747111948],
						[61.185447037295972, 1.8591695160785249],
						[61.186838730351177, 1.8614223045748102],
						[61.186838737270769, 1.8614223160746406],
						[61.186838752696012, 1.8614223349786387],
						[61.186838762190149, 1.8614223440256463],
						[61.188822061861643, 1.8633112453472884],
						[61.188822075016468, 1.8633112554310272],
						[61.188822101228439, 1.8633112737529998],
						[61.188822115176315, 1.8633112817888016],
						[61.190994315086506, 1.864325181908854],
						[61.190994320626572, 1.864325184385307],
						[61.19099433071819, 1.8643251876950877],
						[61.190994337149007, 1.8643251899691087],
						[61.19380273140662, 1.8652612874791246],
						[61.196577672282451, 1.8663862634447148],
						[61.199774838649859, 1.8707612180439486],
						[61.199774842603517, 1.8707612246190704],
						[61.199774853976358, 1.870761235113845],
						[61.19977485882081, 1.8707612414866364],
						[61.202624859294239, 1.8735945423573854],
						[61.202624878085963, 1.8735945567746919],
						[61.202624919037333, 1.8735945811075365],
						[61.20262494119698, 1.8735945910230938],
						[61.205019314823218, 1.8743307830271083],
						[61.205822045453843, 1.8748445382730514],
						[61.206197000850324, 1.8756111493309755],
						[61.206235900211098, 1.8770083062260641],
						[61.206235900893979, 1.8770083191517677],
						[61.206235906018001, 1.8770083478873676],
						[61.206235910361613, 1.877008361850715],
						[61.206660910054318, 1.8783194616760059],
						[61.206660923195251, 1.8783194885925154],
						[61.206660957201656, 1.8783195350662103],
						[61.206660977371435, 1.8783195585186592],
						[61.207508136761767, 1.8789807267481864],
						[61.208149817274702, 1.8804361811686621],
						[61.208149825278682, 1.8804361961714515],
						[61.208149843861541, 1.8804362237239343],
						[61.208149853549585, 1.8804362364757776],
						[61.209355453712043, 1.8817195373033937],
						[61.209355479031132, 1.8817195558538309],
						[61.209355533830752, 1.8817195864039364],
						[61.209355564104641, 1.8817195963547686],
						[61.2109832439737, 1.8820140926944633],
						[61.212566574119272, 1.8826640645519155],
						[61.213438741825378, 1.8837973212609109],
						[61.213438742911102, 1.8837973247527784],
						[61.213438746766762, 1.8837973294853618],
						[61.213438748645835, 1.8837973309281584],
						[61.214399849056271, 1.8849084317007174],
						[61.214399880012174, 1.8849084545851829],
						[61.214399950344799, 1.8849084890982477],
						[61.214399987842448, 1.8849084992839438],
						[61.215611088196738, 1.8849807990995964],
						[61.215611129368789, 1.8849807934984266],
						[61.215611205393472, 1.8849807650373944],
						[61.21561124024614, 1.8849807421775298],
						[61.216466840124653, 1.8841473431836055],
						[61.216466847752045, 1.8841473339760262],
						[61.216466864690901, 1.8841473133095317],
						[61.216466872220842, 1.8841473022547943],
						[61.216964065184641, 1.8833057130482247],
						[61.217477802853573, 1.8826531199433674],
						[61.217833144636259, 1.8830862264491972],
						[61.217833155117482, 1.8830862371562205],
						[61.217833174994148, 1.8830862550779504],
						[61.217833185377877, 1.8830862639377433],
						[61.218635984818867, 1.8836473646614578],
						[61.218636047941452, 1.8836473821178128],
						[61.218636171053866, 1.8836473747451583],
						[61.218636231141183, 1.8836473517634251],
						[61.21980293072999, 1.8826307508399533],
						[61.219802933304933, 1.8826307483860791],
						[61.219802935879834, 1.8826307459322145],
						[61.219802937661449, 1.8826307455278586],
						[61.221380711983826, 1.881125169465002],
						[61.222811177656148, 1.8805168843416025],
						[61.222811182012585, 1.8805168814827959],
						[61.222811190823066, 1.8805168776126564],
						[61.222811195277067, 1.8805168766013545],
						[61.225313995069747, 1.8791723754316778],
						[61.225313996851327, 1.8791723750270437],
						[61.228352872160308, 1.8774807887255005],
						[61.231786056430948, 1.8767002146279868],
						[61.232588763181042, 1.8772862472363374],
						[61.233577609931451, 1.8784472853804699],
						[61.233844201259117, 1.8803722181939968],
						[61.233910900175495, 1.8819139097544371],
						[61.233910908263823, 1.8819139434534968],
						[61.233910936020877, 1.8819140082215571],
						[61.233910954701308, 1.8819140376446974],
						[61.234699855065742, 1.8827390379036339],
						[61.234699869207773, 1.8827390496549423],
						[61.234699900164223, 1.8827390725506852],
						[61.234699915892854, 1.8827390802009838],
						[61.235708191778471, 1.8832084717150146],
						[61.237244272622746, 1.8844751543455718],
						[61.237244323371598, 1.8844751764915817],
						[61.237244425981977, 1.8844751906027279],
						[61.237244479625161, 1.8844751821633472],
						[61.239250079620092, 1.883594582790012],
						[61.239250093677938, 1.8835945758560608],
						[61.239250120707808, 1.8835945584934652],
						[61.239250133777325, 1.8835945499133386],
						[61.240872331338721, 1.8821362500341248],
						[61.242252875393397, 1.880980797426822],
						[61.243616604189533, 1.8809363036120559],
						[61.244916537728216, 1.8826251173707056],
						[61.24681373361696, 1.8854418126871666],
						[61.246813738363414, 1.8854418172223966],
						[61.246813745086435, 1.885441825050842],
						[61.246813749039546, 1.8854418316373343],
						[61.249988749388287, 1.8891168317797729],
						[61.24998876076004, 1.889116842296843],
						[61.249988789043222, 1.8891168658158308],
						[61.249988803978205, 1.889116875523946],
						[61.252413783711937, 1.8904501644816394],
						[61.255344256679408, 1.893027940307813],
						[61.257147044681304, 1.8951557255772022],
						[61.258680431779588, 1.8971362105785479],
						[61.259974815438689, 1.8994861799709761],
						[61.260710909771298, 1.9017806604489302],
						[61.260710909868557, 1.9017806622987685],
						[61.260710910856687, 1.9017806639466099],
						[61.260710910953939, 1.901780665796434],
						[61.261188710840287, 1.9031694636202259],
						[61.261869210361979, 1.9052000641512929],
						[61.261869211350025, 1.9052000657993133],
						[61.261869212338098, 1.9052000674473455],
						[61.261869212435329, 1.9052000692972444],
						[61.262283112320603, 1.9063250690555702],
						[61.262283191428452, 1.9063251334965254],
						[61.262283364981563, 1.9063251596890611],
						[61.262283459426861, 1.9063251214406129],
						[61.263047358680723, 1.9053223203593628],
						[61.263047374440028, 1.9053222943236425],
						[61.263047393891668, 1.9053222356269226],
						[61.263047400353685, 1.9053222042102376],
						[61.263105799717607, 1.9025222036236895],
						[61.263105799717607, 1.9025222036236895],
						[61.263105800413932, 1.9025221997217652],
						[61.263105800413932, 1.9025221997217652],
						[61.263086300999994, 1.8999111813846987],
						[61.263797203627703, 1.899191979294734],
						[61.26497203768578, 1.900308422158558],
						[61.26615811286257, 1.9026139727082996],
						[61.266860900549041, 1.90519172831526],
						[61.266908099930568, 1.9073777845660336],
						[61.266633116194171, 1.9090081902429712],
						[61.266008315172776, 1.9094941353231805],
						[61.264841840343486, 1.9083526566789659],
						[61.264841839355469, 1.9083526550306769],
						[61.264841836585738, 1.9083526537860591],
						[61.264841835694888, 1.9083526539878859],
						[61.264161236144702, 1.9077192541928363],
						[61.264161209937555, 1.9077192357935626],
						[61.264161151773393, 1.9077192096581537],
						[61.264161120707136, 1.9077192017201603],
						[61.263336120845302, 1.907630400507379],
						[61.263336043730583, 1.9076304254667558],
						[61.26333593269915, 1.9076305255031876],
						[61.263335899576163, 1.9076305985283954],
						[61.263327599645727, 1.9089582984027158],
						[61.263327601605475, 1.9089583185510064],
						[61.263327608893981, 1.9089583543404143],
						[61.263327614417101, 1.9089583736815297],
						[61.264052613829811, 1.9108028736993059],
						[61.264052613829811, 1.9108028736993059],
						[61.265152607680932, 1.9135556580131523],
						[61.265647001036001, 1.9158917265258872],
						[61.26575260039295, 1.9178167103135493],
						[61.265752600878216, 1.9178167195644826],
						[61.265752603630474, 1.91781673766322],
						[61.265752605006611, 1.9178167467125709],
						[61.266810905350013, 1.9222806466313442],
						[61.266810907616708, 1.9222806554797895],
						[61.266810912943996, 1.9222806711249683],
						[61.26681091699249, 1.9222806795705105],
						[61.268219217455552, 1.9255139808254769],
						[61.268219219431224, 1.9255139841234783],
						[61.268219223479512, 1.9255139925698215],
						[61.268219224661216, 1.9255139979195381],
						[61.269466522942302, 1.9277750921106929],
						[61.270788720929097, 1.9304389882150637],
						[61.270788721025973, 1.9304389900655947],
						[61.272102620923221, 1.9330556895159707],
						[61.272102621910939, 1.9330556911654246],
						[61.272102623983287, 1.9330556963149081],
						[61.272102624874186, 1.9330556961137222],
						[61.273633125193726, 1.9357972974154127],
						[61.273633127169141, 1.9357973007146307],
						[61.273633130132296, 1.9357973056634219],
						[61.273633131120015, 1.935797307313019],
						[61.274769228842686, 1.9375807037230939],
						[61.275305397930161, 1.9384972505442863],
						[61.275260902123485, 1.9396110523365544],
						[61.274738718891015, 1.9407388162682155],
						[61.274738711646819, 1.9407388328835684],
						[61.274738704382457, 1.9407388663571754],
						[61.274738700895441, 1.9407388858701853],
						[61.274616500724406, 1.942324984999906],
						[61.274616511644396, 1.942325038715607],
						[61.274616561703326, 1.9423251341643846],
						[61.274616600745574, 1.94232517404667],
						[61.275311000627902, 1.9427223747984299],
						[61.275311072363969, 1.942722384836242],
						[61.275311204122069, 1.9427223532466176],
						[61.275311264240798, 1.9427223134700362],
						[61.276927946675194, 1.9403862399713125],
						[61.280025085338252, 1.9380112870323636],
						[61.284111119595295, 1.9371807992546883],
						[61.286616698620342, 1.9371918992220232],
						[61.287919398980222, 1.937200200369571],
						[61.287919447882828, 1.9372001874520408],
						[61.287919534629673, 1.9372001397598198],
						[61.287919570595285, 1.9372001035359259],
						[61.288991870606523, 1.9354195024559724],
						[61.288991874867335, 1.9354194977464352],
						[61.288991881316647, 1.9354194831749847],
						[61.288991883795674, 1.9354194788679611],
						[61.289802951824228, 1.933491854232698],
						[61.292227862101157, 1.9325001907534884],
						[61.295552830689267, 1.9316751982716214],
						[61.298441713153089, 1.9314807990919485],
						[61.298441722158842, 1.9314807989296232],
						[61.298441739085654, 1.9314807951021031],
						[61.298441747103652, 1.9314807932890583],
						[61.301577846946167, 1.9307223935620115],
						[61.301577856745894, 1.9307223913455513],
						[61.301577876248444, 1.9307223850602855],
						[61.301577885060361, 1.930722381192979],
						[61.304716782061398, 1.9292501814347636],
						[61.306380679674703, 1.9285279829595525],
						[61.306380697201469, 1.9285279733697007],
						[61.306380727606694, 1.9285279514927753],
						[61.306380743157796, 1.9285279386008893],
						[61.308100130381362, 1.9267612532765992],
						[61.309636164647777, 1.9256807992696772],
						[61.312463880063092, 1.9256251998927469],
						[61.315305517345855, 1.9262612863177291],
						[61.317147083964691, 1.9275640623596022],
						[61.31714708772212, 1.9275640652592407],
						[61.317147095140029, 1.9275640692052842],
						[61.317147098897486, 1.9275640721049139],
						[61.31870819893112, 1.9284779720841583],
						[61.318708221978717, 1.9284779818683833],
						[61.31870826956478, 1.9284779954733602],
						[61.318708294994138, 1.9284779990924219],
						[61.319505486691305, 1.9284973974347142],
						[61.320069212616751, 1.9294139764019198],
						[61.32063590462343, 1.9318056465886317],
						[61.320635916961564, 1.9318056756782298],
						[61.32063594936502, 1.9318057264823725],
						[61.320635968636367, 1.9318057502520338],
						[61.32209156895879, 1.9330751505222057],
						[61.322091609708124, 1.933075171308853],
						[61.322091699243558, 1.9330751941840216],
						[61.322091746151038, 1.9330751948221736],
						[61.323652845629049, 1.9327057942572339],
						[61.323652870476913, 1.932705786758099],
						[61.323652914342844, 1.9327057637012617],
						[61.323652934251811, 1.9327057479419489],
						[61.325239008882413, 1.9312695703681231],
						[61.325774916323716, 1.9310446510369983],
						[61.326310912768548, 1.9319583765900186],
						[61.326627606357484, 1.9332500474714231],
						[61.326627683267233, 1.9332501219770648],
						[61.326627860962816, 1.9332501586657531],
						[61.326627960760824, 1.9332501191966238],
						[61.327739061300655, 1.9317529195429968],
						[61.32773907280383, 1.9317528981804355],
						[61.327739092052788, 1.9317528525542751],
						[61.327739098016828, 1.931752828694044],
						[61.328077992013405, 1.9293361727617071],
						[61.328841767571831, 1.9281669616301589],
						[61.33090547065229, 1.9292612583890194],
						[61.333688726768173, 1.9326529036267988],
						[61.335610912154785, 1.9372167686162143],
						[61.337241507082176, 1.9425361514496897],
						[61.338299805341485, 1.9470806458027481],
						[61.33829980632914, 1.9470806474559836],
						[61.338299806425866, 1.9470806493105779],
						[61.338299805631657, 1.9470806513664995],
						[61.339335906051794, 1.9510500515956639],
						[61.33933590901453, 1.9510500565559321],
						[61.339335913351569, 1.9510500705882643],
						[61.339335916410974, 1.951050077403188],
						[61.340049815676217, 1.9527333789581047],
						[61.340049850262126, 1.9527334199375799],
						[61.340049933709871, 1.9527334817838775],
						[61.340049984160231, 1.9527334985389067],
						[61.341819384309481, 1.9528723990400523],
						[61.341819436079611, 1.9528723892250681],
						[61.341819526684809, 1.9528723462433821],
						[61.341819566314193, 1.9528723110206594],
						[61.343122366119076, 1.9509279104086465],
						[61.343122368695134, 1.9509279079499442],
						[61.343122373557321, 1.9509278974679252],
						[61.343122377024294, 1.9509278948079551],
						[61.344316871907139, 1.948672302498323],
						[61.345250104113319, 1.9472919036477367],
						[61.347377761380386, 1.9473668982699086],
						[61.349169282679519, 1.9480251703550056],
						[61.351922049285399, 1.9512084306272997],
						[61.351922053139099, 1.9512084353881773],
						[61.351922061737397, 1.9512084447085909],
						[61.351922065591083, 1.9512084494694526],
						[61.3557748361874, 1.9546807222855604],
						[61.358147018293344, 1.9597111826949185],
						[61.360138711805988, 1.964280669833719],
						[61.361458107042118, 1.9686917536575252],
						[61.362752605018585, 1.9738194462140024],
						[61.363913703264046, 1.9789667351356339],
						[61.36443589769808, 1.9827389026110518],
						[61.364016500699051, 1.9865832771479208],
						[61.36401650137249, 1.9865832901417422],
						[61.364016501033582, 1.9865833183865269],
						[61.364016501707006, 1.9865833313803531],
						[61.364872001572834, 1.9920583311454736],
						[61.364872025744468, 1.9920583801723961],
						[61.364872096676791, 1.9920584618809318],
						[61.364872144232329, 1.9920584925059472],
						[61.366402735020877, 1.9925029884432104],
						[61.368185968356741, 1.9932112632940522],
						[61.369255424579421, 1.994811198369796],
						[61.370185919236462, 1.9968111834306341],
						[61.370185927229109, 1.9968111985391102],
						[61.370185944900321, 1.9968112264990372],
						[61.370185956264834, 1.9968112370934417],
						[61.371074856045169, 1.9977390388268457],
						[61.37107488510717, 1.9977390604724039],
						[61.371074949686765, 1.997739089165071],
						[61.371074985300432, 1.9977390980689194],
						[61.372016552692209, 1.9978084895851351],
						[61.37240261123992, 1.9989389638423376],
						[61.373063709959098, 2.0009083635373237],
						[61.373063799117794, 2.0009084318002124],
						[61.373063985354221, 2.0009084425720487],
						[61.373064080649954, 2.0009083854811878],
						[61.374105781301154, 1.9986944845954475],
						[61.374105787564751, 1.9986944662819233],
						[61.374105797130809, 1.9986944246846787],
						[61.374105799734352, 1.9986944053149249],
						[61.374208500284794, 1.9943417054120605],
						[61.374208499804254, 1.9943416961275968],
						[61.374208498939282, 1.9943416794155255],
						[61.374208497567778, 1.9943416703313319],
						[61.373539097726677, 1.9899416691762228],
						[61.373539095560041, 1.9899416621496873],
						[61.373539092021602, 1.9899416460393835],
						[61.37353908985493, 1.9899416390128544],
						[61.372252990051912, 1.9859582383236867],
						[61.372252990051912, 1.9859582383236867],
						[61.37136129576924, 1.9832443566937097],
						[61.370989101372167, 1.980186094675821],
						[61.371230799214842, 1.9764083265657468],
						[61.371991882902407, 1.9726890055267525],
						[61.373089032853947, 1.9717140492455063],
						[61.37308903721231, 1.971714046384125],
						[61.373089043954295, 1.9717140373497499],
						[61.373089048312636, 1.971714034488359],
						[61.375083448194282, 1.9695251353910626],
						[61.375083454045132, 1.9695251265566147],
						[61.375083465843296, 1.9695251107445761],
						[61.375083471790603, 1.9695251037669688],
						[61.376164072116275, 1.9677168022145872],
						[61.376164072116275, 1.9677168022145872],
						[61.376164072910754, 1.9677168001565981],
						[61.376164073801689, 1.9677167999555314],
						[61.377025155049992, 1.966214030841303],
						[61.377952927395931, 1.965450153897341],
						[61.377952948607877, 1.9654501284416381],
						[61.37795298440922, 1.9654500715102357],
						[61.377952996229254, 1.9654500387809835],
						[61.378419596035087, 1.963080639665395],
						[61.378419598225207, 1.9630806297766314],
						[61.37841959913824, 1.9630806126609981],
						[61.378419599546469, 1.9630806031746781],
						[61.378519599930236, 1.9576278051560192],
						[61.378605799753494, 1.9557250095622623],
						[61.378605798980388, 1.9557249947062316],
						[61.378605796446607, 1.9557249633386178],
						[61.378605792904118, 1.9557249472298877],
						[61.377366892749478, 1.9511693489320541],
						[61.37736689156845, 1.9511693435628652],
						[61.377366890290752, 1.9511693363367602],
						[61.377366889303119, 1.9511693346814047],
						[61.376614089174666, 1.9490332330493165],
						[61.376614088187004, 1.9490332313940533],
						[61.376614087102652, 1.9490332278819433],
						[61.376614086114998, 1.9490332262266967],
						[61.375525191354946, 1.946269337168576],
						[61.374597396343468, 1.9426971588101107],
						[61.374128006932423, 1.939827823225232],
						[61.374700183810596, 1.9384611776806688],
						[61.374700186289495, 1.9384611733616139],
						[61.374700187780668, 1.9384611673877243],
						[61.37470018936871, 1.9384611632705528],
						[61.375411272953315, 1.9363584120180302],
						[61.376061236095836, 1.9357557469648243],
						[61.376061238671589, 1.9357557445021121],
						[61.376061243823074, 1.9357557395767193],
						[61.37606124630193, 1.9357557352572485],
						[61.376844532223281, 1.9349168514799955],
						[61.377202916016444, 1.9346612635615703],
						[61.377202944949467, 1.9346612306973114],
						[61.377202986992096, 1.9346611554052435],
						[61.377203000198591, 1.9346611148342558],
						[61.377241899875216, 1.9340917132630526],
						[61.377241900281454, 1.9340917037768406],
						[61.377241899312118, 1.9340916852084848],
						[61.377241898827464, 1.934091675924309],
						[61.377178180664487, 1.9335597498022479],
						[61.377183136020534, 1.9335668157029178],
						[61.377183197532908, 1.9335668543590048],
						[61.377183334266959, 1.9335668834703967],
						[61.377183450233559, 1.9335668083205164],
						[61.377183483828183, 1.9335666748208062],
						[61.377183473714453, 1.9335666019619446],
						[61.377177793876236, 1.9335565195375275],
						[61.377147398583311, 1.9333027765902757],
						[61.377147390796068, 1.933302748295437],
						[61.37714736819148, 1.9333026951790409],
						[61.377147353374141, 1.933302670357461],
						[61.376806276001311, 1.9328971583892409],
						[61.376530773717448, 1.9324082017022877],
						[61.376530769669159, 1.9324081932266581],
						[61.376530759984718, 1.9324081803931135],
						[61.376530754154693, 1.9324081723216275],
						[61.375369553896164, 1.9309998732753617],
						[61.375369543320751, 1.9309998606448384],
						[61.37536951781253, 1.9309998382510045],
						[61.37536950475846, 1.9309998299402056],
						[61.373600104925593, 1.929910930307275],
						[61.373600098398548, 1.9299109261523026],
						[61.373600085441375, 1.9299109196989945],
						[61.373600078914301, 1.9299109155440466],
						[61.371719478815308, 1.9291054154172989],
						[61.371719475154833, 1.9291054143693274],
						[61.371719466942999, 1.9291054124754972],
						[61.371719463185514, 1.929105409571044],
						[61.369261163174215, 1.9282887102811936],
						[61.369261148629271, 1.9282887079462738],
						[61.369261122212031, 1.9282887026700428],
						[61.369261107667057, 1.9282887003351252],
						[61.367563918712335, 1.9282248012940642],
						[61.364758362298467, 1.9277804061034374],
						[61.362594534097823, 1.9266804448756305],
						[61.361297377238813, 1.9248916031405872],
						[61.36063629067754, 1.9231610392001397],
						[61.360064094186434, 1.9209360499776222],
						[61.360064091028413, 1.9209360413056038],
						[61.360064083724332, 1.9209360223080394],
						[61.360064079675439, 1.9209360138382683],
						[61.359569579750108, 1.9198999148372551],
						[61.359569532099776, 1.9198998655683004],
						[61.359569414882479, 1.919899813318249],
						[61.359569346902958, 1.919899806221133],
						[61.35869164677527, 1.9201415076938639],
						[61.358691624698118, 1.920141516462087],
						[61.358691582616707, 1.920141539161021],
						[61.358691564394277, 1.9201415526871792],
						[61.357685964041714, 1.9210720534056522],
						[61.357685958890755, 1.9210720583303309],
						[61.357685947795062, 1.9210720702375643],
						[61.357685944425818, 1.9210720747577805],
						[61.35703596725741, 1.9218887466770376],
						[61.356411290073794, 1.9222552577214544],
						[61.356375200193618, 1.9210389140799022],
						[61.356700196840144, 1.9190972341874299],
						[61.35695849726595, 1.9176972357304802],
						[61.356958498076267, 1.9176972167701458],
						[61.356958496830103, 1.9176971757453312],
						[61.356958494870725, 1.9176971555364197],
						[61.356550194896641, 1.9159305550967816],
						[61.356550185615895, 1.9159305327961857],
						[61.356550164381865, 1.9159304888021624],
						[61.356550149756032, 1.9159304677158253],
						[61.355886250365714, 1.9151748683044345],
						[61.355886214764453, 1.9151748425964283],
						[61.355886138200511, 1.9151748092966545],
						[61.355886096249826, 1.9151748000517506],
						[61.354791696091887, 1.915197000374216],
						[61.354791663924225, 1.915197005804544],
						[61.354791602844436, 1.915197027190642],
						[61.354791574920363, 1.915197044799442],
						[61.353694274700167, 1.9160776443676562],
						[61.353694271233884, 1.9160776470324226],
						[61.353694265192281, 1.9160776521595801],
						[61.353694261823172, 1.9160776566797058],
						[61.352555478858335, 1.9171692385755306],
						[61.351483287973338, 1.9177886767272112],
						[61.350311203066873, 1.917263726612312],
						[61.349352941642138, 1.9165276574310901],
						[61.34807516412846, 1.9149026858988152],
						[61.346964079719093, 1.9131193104304847],
						[61.346119590433865, 1.9108638367110287],
						[61.345678001267636, 1.9093777732179984],
						[61.345705799352842, 1.9082833511006325],
						[61.346164065070752, 1.9073890166849559],
						[61.3468334470165, 1.9066668362621217],
						[61.346833460394308, 1.9066668163268707],
						[61.346833482695729, 1.9066667774691899],
						[61.346833492412941, 1.9066667564892541],
						[61.347041892270944, 1.9059583552052786],
						[61.347041896253877, 1.9059583280207271],
						[61.347041897093135, 1.9059582752722726],
						[61.3470418929613, 1.9059582480560109],
						[61.346783493363162, 1.9049999484133082],
						[61.346783471332408, 1.9049999064977672],
						[61.346783410135387, 1.9049998397031658],
						[61.346783370871847, 1.9049998129692098],
						[61.346116770782906, 1.9047470125933641],
						[61.346116755249589, 1.9047470086181764],
						[61.346116720619698, 1.9047470014782357],
						[61.346116704292868, 1.9047469995605228],
						[61.344966722350499, 1.904721999087392],
						[61.343491768066428, 1.9044276103782003],
						[61.342086237156387, 1.9036831455284629],
						[61.339341865131793, 1.8996498863182123],
						[61.339341861276388, 1.8996498815656502],
						[61.339341850795797, 1.8996498708141063],
						[61.339341845952248, 1.8996498644097772],
						[61.338075146171533, 1.8982887634410763],
						[61.338075121744303, 1.8982887446050236],
						[61.338075068825276, 1.8982887153639891],
						[61.338075041224286, 1.8982887047563248],
						[61.336788987527022, 1.8980220133074959],
						[61.335261258109497, 1.8967998693688017],
						[61.333994583115761, 1.8943943198074658],
						[61.333002994025115, 1.8916860475469406],
						[61.332608499141742, 1.8895360774571381],
						[61.332480799908787, 1.8870832896236778],
						[61.332480799323577, 1.8870832784997045],
						[61.33248079538329, 1.8870832550067338],
						[61.3324807921257, 1.8870832444917396],
						[61.331989091920718, 1.8853610443976234],
						[61.331989083721275, 1.885361025628367],
						[61.331989062673266, 1.885360985397138],
						[61.33198905001985, 1.8853609676430696],
						[61.33102794972919, 1.8842692680863686],
						[61.331027936379982, 1.8842692542441295],
						[61.331027907301952, 1.8842692327305317],
						[61.331027891377936, 1.8842692213513927],
						[61.329766791130908, 1.8836248213095703],
						[61.329766773718035, 1.8836248158987623],
						[61.329766739783025, 1.8836248048740953],
						[61.32976672157691, 1.8836248015201822],
						[61.328219506599098, 1.8834498111476423],
						[61.327022380665191, 1.8818777089230181],
						[61.326219594261758, 1.8792221494161179],
						[61.325780795446249, 1.87719165878935],
						[61.325780791992614, 1.8771916445705299],
						[61.325780781619933, 1.8771916187992945],
						[61.325780777373168, 1.8771916066372556],
						[61.324961277319801, 1.8756527068128097],
						[61.324961250424487, 1.8756526754338223],
						[61.324961188312166, 1.8756526258294415],
						[61.324961151215945, 1.8756526061570586],
						[61.323772258892362, 1.8753387090147791],
						[61.322277897223124, 1.8748081211267413],
						[61.320589039183353, 1.8735137536019046],
						[61.319175155850957, 1.8718415746300989],
						[61.317214059770706, 1.8692359801542981],
						[61.317214035046625, 1.8692359557936069],
						[61.317213979059041, 1.8692359198173105],
						[61.317213946806959, 1.8692359065520676],
						[61.31380284743009, 1.8684026063501604],
						[61.313802840108465, 1.8684026042714572],
						[61.31380282378143, 1.868402602373598],
						[61.313802815569026, 1.868402600498269],
						[61.312608404216881, 1.8683054080033357],
						[61.311283469781756, 1.866591593678147],
						[61.309930778840318, 1.8638527102071891],
						[61.309930777949575, 1.8638527104106499],
						[61.309930775874591, 1.8638527052598537],
						[61.30993077488597, 1.8638527036107537],
						[61.308419574587006, 1.8610888039947737],
						[61.308419570632552, 1.8610887973989989],
						[61.308419561735022, 1.8610887825585165],
						[61.308419556889817, 1.8610887761662942],
						[61.306866857084579, 1.8591304764303618],
						[61.306866849371254, 1.859130466944708],
						[61.306866832261079, 1.859130450232904],
						[61.306866822766253, 1.8591304411543932],
						[61.305294522756206, 1.8579109413640051],
						[61.305294515336556, 1.8579109374359311],
						[61.30529450039932, 1.8579109277274874],
						[61.305294491991013, 1.8579109221507377],
						[61.303716796779888, 1.8570942250790492],
						[61.301666810709278, 1.8558887335101593],
						[61.299986222065868, 1.8546415403743191],
						[61.298441833751632, 1.853422050399824],
						[61.297264069050591, 1.8522331868311288],
						[61.296577988831771, 1.8506027332240447],
						[61.296152992682671, 1.8490749468498195],
						[61.296152982305159, 1.8490749211120086],
						[61.296152952740854, 1.8490748735257094],
						[61.296152935335513, 1.8490748512697295],
						[61.295247334815237, 1.8482498512467169],
						[61.295247294054874, 1.848249830586838],
						[61.295247207973986, 1.8482498053029224],
						[61.29524716195882, 1.8482498045859403],
						[61.294269451098401, 1.8484414858149203],
						[61.293239057947837, 1.8476359702398875],
						[61.292025178389927, 1.8452610088373163],
						[61.292025175423568, 1.8452610038945267],
						[61.292025168600119, 1.8452609942127489],
						[61.292025165633795, 1.8452609892699492],
						[61.290352966003702, 1.8427554895106877],
						[61.290352963037257, 1.8427554845683745],
						[61.290352958093202, 1.8427554763311949],
						[61.290352954334132, 1.8427554734441276],
						[61.287575153990176, 1.8393776733441338],
						[61.287575153892035, 1.8393776714929826],
						[61.287575153001356, 1.8393776716969421],
						[61.287575152110634, 1.8393776719009143],
						[61.28439185246252, 1.8356304716303473],
						[61.284391840988555, 1.8356304592713226],
						[61.284391817346375, 1.8356304384591817],
						[61.284391804189184, 1.8356304283592031],
						[61.282172308093863, 1.8342804321798307],
						[61.280539027075129, 1.8331804443782551],
						[61.279125142348107, 1.8317970596295057],
						[61.277694545333844, 1.8302970627916633],
						[61.277694536826317, 1.8302970553762683],
						[61.277694520701985, 1.8302970403413268],
						[61.277694513183434, 1.8302970345723142],
						[61.275841813331084, 1.8290248347658702],
						[61.275841808681101, 1.8290248320858427],
						[61.275841801162485, 1.8290248263174567],
						[61.275841795720048, 1.8290248256920196],
						[61.274544533072564, 1.8283109457076436],
						[61.273589059381209, 1.8270581790368701],
						[61.273589046917607, 1.8270581650389435],
						[61.273589020405595, 1.8270581411520397],
						[61.273589005368265, 1.8270581296169677],
						[61.272316805317061, 1.8262748299789469],
						[61.272316788694866, 1.8262748225535301],
						[61.272316756439515, 1.8262748093486749],
						[61.272316739024944, 1.8262748039776691],
						[61.270733368725296, 1.825958110246876],
						[61.268489002217187, 1.8247276280022693],
						[61.266347307706567, 1.8233581313970788],
						[61.266347304936204, 1.8233581301599511],
						[61.266347298406409, 1.8233581260401421],
						[61.266347294647019, 1.8233581231574383],
						[61.264427915995959, 1.8223220345910609],
						[61.262191844375657, 1.8203331600310158],
						[61.260922359745145, 1.8188137794336758],
						[61.258439065743836, 1.8151109881651066],
						[61.258439058918505, 1.8151109784999258],
						[61.258439043584936, 1.8151109614277277],
						[61.258439035967392, 1.8151109538162928],
						[61.256916836222658, 1.8137054538720629],
						[61.256916821184348, 1.8137054423490853],
						[61.256916791304647, 1.8137054230015961],
						[61.25691677468194, 1.8137054155859786],
						[61.255808374617516, 1.8132581151777554],
						[61.25580836175439, 1.8132581106431862],
						[61.255808337017228, 1.8132581032188126],
						[61.255808324252541, 1.8132581005334258],
						[61.254650060356113, 1.8131165060640517],
						[61.253277934244743, 1.8123998443620652],
						[61.251869572394874, 1.8104776974284924],
						[61.250827980136883, 1.8083221127013491],
						[61.250827980136883, 1.8083221127013491],
						[61.250827979049184, 1.8083221092081569],
						[61.250827978158533, 1.8083221094126953],
						[61.249602978028094, 1.805927709796679],
						[61.249602975951113, 1.8059277046595219],
						[61.249602971005046, 1.8059276964385735],
						[61.249602968037401, 1.8059276915059976],
						[61.248686268093252, 1.8045109908084538],
						[61.248686259387362, 1.8045109797090289],
						[61.248686239205092, 1.8045109562753079],
						[61.24868622881651, 1.8045109474338541],
						[61.247616838101052, 1.8036137545351143],
						[61.246827973753959, 1.8027665925635208],
						[61.246314095068314, 1.8013805491915178],
						[61.246114097854594, 1.8000721692016854],
						[61.24611409330565, 1.8000721515350415],
						[61.246114079655946, 1.8000721153768611],
						[61.246114071544426, 1.8000720985291465],
						[61.24541127238053, 1.7988748993678134],
						[61.245411255957215, 1.7988748788180859],
						[61.245411217865431, 1.7988748407956789],
						[61.24541119718625, 1.7988748249667597],
						[61.244347297228785, 1.7982859253942138],
						[61.244347278725471, 1.798285916550427],
						[61.244347243004128, 1.7982859060518597],
						[61.24434722479679, 1.7982859027534051],
						[61.243016725393225, 1.7981192018493637],
						[61.243016702831412, 1.7981192014232665],
						[61.243016660675607, 1.7981192055018747],
						[61.24301664009235, 1.7981192083629902],
						[61.241808240082264, 1.7984998083735175],
						[61.241808227810708, 1.7984998149364457],
						[61.241808203960872, 1.7984998241609831],
						[61.241808191787939, 1.7984998325722286],
						[61.240736011967577, 1.799188718829563],
						[61.239458310790951, 1.7996386835432727],
						[61.237977915310061, 1.7989470322524885],
						[61.236800139084245, 1.7978109566720684],
						[61.236800135324373, 1.7978109537949212],
						[61.23680012770599, 1.7978109461926464],
						[61.236800124836783, 1.7978109431107938],
						[61.235308424924924, 1.7966220445906318],
						[61.235308412754641, 1.7966220361647669],
						[61.23530839039271, 1.7966220225993672],
						[61.235308378321115, 1.7966220160214059],
						[61.233947278450671, 1.7960498163009997],
						[61.233947256384731, 1.796049808280024],
						[61.233947210965034, 1.7960498018866815],
						[61.233947188304526, 1.7960497996139142],
						[61.232736087707167, 1.7961220004855776],
						[61.232736067124002, 1.7961220033465914],
						[61.232736025461676, 1.7961220166643754],
						[61.232736005075843, 1.7961220232208996],
						[61.231583204977177, 1.7967415231417361],
						[61.231583184985993, 1.7967415370883317],
						[61.231583148070158, 1.7967415717581907],
						[61.231583131937519, 1.7967415904290389],
						[61.230477631701575, 1.7984498908465067],
						[61.230477625960347, 1.798449901517182],
						[61.230477615467201, 1.7984499245015095],
						[61.230477610715248, 1.7984499368151687],
						[61.229908111358931, 1.8001221366764621],
						[61.22990810898289, 1.8001221428330636],
						[61.229908106012076, 1.8001221547370638],
						[61.229908103636056, 1.8001221608936573],
						[61.229563706079112, 1.8017971507867874],
						[61.22912762086019, 1.8032610009013319],
						[61.228480642595997, 1.8040052457871534],
						[61.227758490740122, 1.802594334616922],
						[61.227239096159828, 1.7998832618976428],
						[61.227239096159828, 1.7998832618976428],
						[61.227239094973292, 1.799883256559893],
						[61.227239094973292, 1.799883256559893],
						[61.226052994690725, 1.7946721555476843],
						[61.226052992317292, 1.7946721448729748],
						[61.226052983909241, 1.7946721224950215],
						[61.22605297975457, 1.7946721122297336],
						[61.223347380453404, 1.7891443124824742],
						[61.223347373626474, 1.7891443028333487],
						[61.223347360764464, 1.7891442814830771],
						[61.223347353937555, 1.7891442718339439],
						[61.222430753604314, 1.7880387712612615],
						[61.222430750734894, 1.788038768181645],
						[61.222430744996068, 1.7880387620223943],
						[61.222430742126647, 1.788038758942776],
						[61.220825142187131, 1.7864220585075163],
						[61.220825131797554, 1.7864220496793446],
						[61.220825112106667, 1.7864220355121219],
						[61.220825100826474, 1.7864220268888573],
						[61.219058401225418, 1.7853915262301085],
						[61.219058400334802, 1.7853915264350284],
						[61.217369519750136, 1.7844137376407327],
						[61.215911248246059, 1.7830609648275277],
						[61.214930757112505, 1.7818109771160651],
						[61.214930751274586, 1.7818109691126907],
						[61.214930737025739, 1.7818109555675623],
						[61.214930730297233, 1.7818109477691682],
						[61.213547330031659, 1.780622047791792],
						[61.213547316969944, 1.7806220395824623],
						[61.213547288966481, 1.7806220217272419],
						[61.213547274123584, 1.7806220139279016],
						[61.212177874330912, 1.7800776130893576],
						[61.212177848800764, 1.7800776077506917],
						[61.212177798927705, 1.7800776024077971],
						[61.212177773694165, 1.7800776026086014],
						[61.210436074346113, 1.7803054010506538],
						[61.210436069893071, 1.7803054020755971],
						[61.210436060987021, 1.7803054041254773],
						[61.210436057424609, 1.7803054049454243],
						[61.209138891193781, 1.7805886965331699],
						[61.208086213387638, 1.7804526125713349],
						[61.207230786559435, 1.7792305179572672],
						[61.206827998754633, 1.7773749731080726],
						[61.206691899811034, 1.7750027895593135],
						[61.206691898623646, 1.775002784225975],
						[61.206691899217375, 1.7750027784824562],
						[61.206691898128902, 1.7750027749952642],
						[61.206458497931138, 1.7731277756598762],
						[61.206458482988666, 1.7731277323790169],
						[61.206458435786701, 1.7731276591510725],
						[61.206458404417845, 1.7731276289988178],
						[61.205736203825978, 1.7726887288278155],
						[61.205736137328365, 1.7726887161135323],
						[61.205736010270918, 1.7726887341701805],
						[61.205735949909005, 1.7726887686332653],
						[61.204713749589395, 1.7738554685609038],
						[61.204713737813833, 1.7738554843547671],
						[61.204713718814602, 1.773855516762878],
						[61.204713710700368, 1.7738555335822761],
						[61.20351371131742, 1.7772666342280283],
						[61.203513709635139, 1.7772666364840195],
						[61.203513708249588, 1.7772666442779195],
						[61.203513706666207, 1.7772666483798729],
						[61.202783111217315, 1.7799527341777979],
						[61.202124829212345, 1.7814804912277431],
						[61.201244343846312, 1.7824830616437362],
						[61.200452962270333, 1.782160949030847],
						[61.199944595370212, 1.7805638549787046],
						[61.199675198431542, 1.7787360709514632],
						[61.19967519289083, 1.7787360516729367],
						[61.199675178147977, 1.7787360120899063],
						[61.199675169044809, 1.7787359936311533],
						[61.198719585624843, 1.7772277194238753],
						[61.198347394458168, 1.7757277527401814],
						[61.198347392182342, 1.7757277439219203],
						[61.19834738693794, 1.775727730181746],
						[61.19834738387047, 1.7757277234141473],
						[61.19746688382704, 1.7736277223777057],
						[61.197466873832603, 1.773627704126802],
						[61.197466849390842, 1.7736276686503083],
						[61.197466834052861, 1.7736276516298022],
						[61.196505733937464, 1.7727581511183643],
						[61.196505713354824, 1.7727581371748204],
						[61.196505666944979, 1.7727581123637268],
						[61.196505643096877, 1.7727581047771148],
						[61.194752865410948, 1.7723720095725159],
						[61.193147319024298, 1.7716081345293238],
						[61.191425149205784, 1.7698026664851658],
						[61.189647356122521, 1.7676887744304117],
						[61.188155759351019, 1.7657442772085996],
						[61.18815573758004, 1.7657442579431513],
						[61.188155693444685, 1.765744225152539],
						[61.188155668408555, 1.7657442122429967],
						[61.184966767722571, 1.7645970121576369],
						[61.184966746743989, 1.7645970076534403],
						[61.184966704885916, 1.7645970004898817],
						[61.184966683313974, 1.7645970017253776],
						[61.183308320052497, 1.7647358971203033],
						[61.181730676327135, 1.7642804130663345],
						[61.180811215257634, 1.7637887354272921],
						[61.179797332337316, 1.7628970494005107],
						[61.179797306212272, 1.762897033012407],
						[61.179797250796213, 1.7628970084351701],
						[61.179797220614631, 1.7628970004512319],
						[61.178727820724617, 1.7627831004166437],
						[61.178727762738298, 1.7627831119110682],
						[61.178727663688903, 1.7627831646115075],
						[61.178727622724949, 1.7627832076620007],
						[61.177535923030383, 1.7650582072586054],
						[61.17753591580761, 1.7650582238600274],
						[61.177535905814956, 1.7650582560371966],
						[61.177535902253524, 1.7650582736624805],
						[61.176888703124646, 1.7698249688860086],
						[61.176160905716216, 1.7736943492180919],
						[61.175041511306411, 1.7769388342796355],
						[61.175041508931251, 1.7769388404268813],
						[61.175041505962184, 1.7769388523118079],
						[61.175041505269377, 1.7769388562052011],
						[61.174447005372542, 1.7796499574099622],
						[61.17444700319497, 1.7796499672454553],
						[61.174447000719908, 1.7796499883512997],
						[61.174447000422425, 1.7796499996216573],
						[61.174435900317697, 1.7844749996458242],
						[61.174435899921015, 1.7844750090718655],
						[61.174435901799463, 1.7844750273102634],
						[61.174435903184033, 1.7844750363271709],
						[61.175283103148807, 1.789011137395724],
						[61.175283104138167, 1.7890111390356387],
						[61.175283105423667, 1.7890111462086578],
						[61.175283105621119, 1.7890111498973778],
						[61.176383105884042, 1.7933611490303039],
						[61.176383106971997, 1.7933611525148783],
						[61.176383109246594, 1.793361161328445],
						[61.176383111225178, 1.7933611646086702],
						[61.177691509147508, 1.7971972600732842],
						[61.178422005563519, 1.7997417492197312],
						[61.178824797093696, 1.8016194086403339],
						[61.178691519785467, 1.8026581324741089],
						[61.177841775902301, 1.8028303812208022],
						[61.176797356830534, 1.8016054768269978],
						[61.175877961087828, 1.8003554813354801],
						[61.175877948622279, 1.8003554673891597],
						[61.175877922008468, 1.8003554417492376],
						[61.175877906969546, 1.8003554302597651],
						[61.175111207296723, 1.7998692317700424],
						[61.1751111246707, 1.7998692208361415],
						[61.175110980878856, 1.7998692631283317],
						[61.175110917931782, 1.7998693167626822],
						[61.174660956256325, 1.8008526340870761],
						[61.173847294590999, 1.8010997622810694],
						[61.172980773993032, 1.7995693007189149],
						[61.172980771025372, 1.7995692957984697],
						[61.17298076419938, 1.7995692861617341],
						[61.172980761231678, 1.7995692812413142],
						[61.172180760921847, 1.7984887808126493],
						[61.172180723225644, 1.7984887502500584],
						[61.172180638131444, 1.7984887100168974],
						[61.17218058984276, 1.7984887005504899],
						[61.171130590176638, 1.7985386990296939],
						[61.171130542377597, 1.7985387155857819],
						[61.171130458650389, 1.7985387683782046],
						[61.171130423612809, 1.7985388044103745],
						[61.170672029704903, 1.7993831928996626],
						[61.169960999876857, 1.8003636978476243],
						[61.169011103581767, 1.800344200227181],
						[61.169011061721307, 1.8003442098185778],
						[61.169010984132768, 1.8003442425292755],
						[61.16901095028458, 1.8003442670844887],
						[61.168335950266453, 1.8011137685990835],
						[61.168335932745414, 1.8011137950121143],
						[61.16833590878543, 1.8011138527656521],
						[61.168335901357246, 1.8011138824662123],
						[61.168188700820167, 1.8027416826248512],
						[61.168188700619233, 1.8027416957368978],
					],
					[
						[61.376252783117458, 1.9322397201302466],
						[61.376805946308068, 1.9328973872292605],
						[61.377177405706597, 1.9335566470278802],
						[61.377177594004635, 1.933558216345632],
						[61.376252783117458, 1.9322397201302466],
					],
				],
			],
		},
		{
			fldNpdidField: "2845712",
			wlbNpdidWellbore: "3397",
			fldName: "ALVHEIM",
			fldCurrentActivitySatus: "Producing",
			wlbName: "24/6-2",
			fldDiscoveryYear: "1998",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=2845712",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=2845712",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "203",
			cmpLongName: "Aker BP ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=3397",
			fldHcType: "OIL/GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "28544099",
			fldNpdidOwner: "22436",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[59.542499800105595, 1.9395388870859855],
					[59.542499801152175, 1.9395389063906341],
					[59.542499805823581, 1.9395389426828549],
					[59.542499809543571, 1.9395389614253946],
					[59.544380409790747, 1.9453306620373922],
					[59.544380411858242, 1.9453306669282011],
					[59.544380415102083, 1.94533067689704],
					[59.544380418060719, 1.9453306816006033],
					[59.545977599911105, 1.9488889438423587],
					[59.546049800150918, 1.957649980818402],
					[59.544541504086482, 1.9650888600658694],
					[59.544541502266135, 1.9650888764225469],
					[59.544541501299008, 1.9650889085757506],
					[59.544541501261044, 1.9650889245589858],
					[59.545477601299254, 1.9729694241576463],
					[59.545477605905731, 1.9729694427193547],
					[59.545477617792301, 1.9729694792832451],
					[59.545477626058286, 1.9729694988541697],
					[59.550172026397007, 1.9812500997620781],
					[59.550172049394284, 1.9812501251342007],
					[59.550172104206425, 1.9812501722596851],
					[59.550172134846299, 1.9812501889327738],
					[59.553408130080427, 1.9823612531563912],
					[59.554852604589094, 1.9886694450857596],
					[59.554852629610259, 1.9886694913497747],
					[59.554852702163117, 1.9886695667469441],
					[59.554852746832132, 1.9886695929270153],
					[59.557127626096246, 1.9892890594702417],
					[59.558041503390982, 1.9942500360096114],
					[59.558041508980274, 1.9942500561512302],
					[59.558041526397574, 1.9942500951321434],
					[59.558041536443078, 1.9942501143435436],
					[59.561210888814564, 1.9988000469492293],
					[59.56029980296352, 2.0041610656460458],
					[59.560299807669125, 2.0041611197147517],
					[59.560299844237917, 2.0041612133118183],
					[59.560299875398478, 2.0041612565382736],
					[59.562580415980719, 2.0059890097840589],
					[59.563221986863418, 2.0097499403911776],
					[59.561827649818042, 2.0113387685449222],
					[59.561827629325954, 2.0113388065539857],
					[59.561827604949933, 2.0113388897684952],
					[59.561827602754313, 2.0113389328464684],
					[59.564480402897594, 2.0272611333313528],
					[59.564480410546523, 2.0272611583814792],
					[59.56448043297484, 2.0272612070000355],
					[59.564480447660209, 2.0272612288120153],
					[59.568180447834351, 2.0316195284147907],
					[59.568180554935779, 2.0316195594544428],
					[59.568180731179602, 2.0316195015342453],
					[59.568180800321983, 2.031619412574396],
					[59.568877999247519, 2.0207694264538127],
					[59.570227901814683, 2.0140199076318268],
					[59.572169195942713, 2.0168889553572771],
					[59.571388699813582, 2.0287805872502553],
					[59.571388700659355, 2.028780603061517],
					[59.571388704227452, 2.0287806360704392],
					[59.571388706855814, 2.028780651511255],
					[59.573988706620298, 2.0383389520778255],
					[59.573988708778344, 2.0383389587357632],
					[59.573988713985734, 2.0383389718666338],
					[59.573988716143795, 2.0383389785245671],
					[59.575149788834977, 2.0410611158445953],
					[59.574210922651126, 2.0445304899084524],
					[59.571780468005706, 2.0466609500762951],
					[59.571780441967661, 2.0466609856709752],
					[59.57178040837448, 2.0466610654634723],
					[59.571780399834282, 2.0466611080891708],
					[59.572102599919276, 2.0545611076909425],
					[59.572102614128468, 2.0545611544861782],
					[59.572102659905823, 2.0545612356009375],
					[59.572102693256674, 2.0545612695513631],
					[59.573938711686914, 2.055719517962189],
					[59.574599800721856, 2.0617194224823132],
					[59.574599822340147, 2.0617194730804185],
					[59.574599887112655, 2.0617195556103898],
					[59.574599931158232, 2.0617195873578549],
					[59.575908100934988, 2.0622001396157974],
					[59.575919198745801, 2.0644387707026164],
					[59.573488817618639, 2.065538718285469],
					[59.573488773747691, 2.0655387575531767],
					[59.573488714760252, 2.0655388514608979],
					[59.573488699830683, 2.0655389096150096],
					[59.573699799588432, 2.0703500088078268],
					[59.573699808681269, 2.0703500442334533],
					[59.573699839346375, 2.0703501125066821],
					[59.573699859933797, 2.0703501437813512],
					[59.576769226603496, 2.0733612108932058],
					[59.579269212214882, 2.0803500672713251],
					[59.579269225202438, 2.080350091241546],
					[59.579269257324107, 2.0803501361371133],
					[59.579269275566801, 2.0803501572463992],
					[59.58243043025098, 2.0828390205211367],
					[59.585188711284594, 2.0908500654056175],
					[59.585188714331466, 2.0908500718856033],
					[59.58518871962697, 2.0908500867870159],
					[59.585188722673827, 2.090850093266988],
					[59.591202614409838, 2.1022194777989918],
					[59.594149799794963, 2.1117889302859023],
					[59.594169199641222, 2.1157500006400971],
					[59.594169201275314, 2.1157500145235648],
					[59.594169204543498, 2.1157500422905198],
					[59.594169208053351, 2.1157500575661352],
					[59.59676090620259, 2.1244611494113017],
					[59.598299803961808, 2.1319889397923304],
					[59.598299805223775, 2.1319889466440261],
					[59.598299807562434, 2.1319889568302233],
					[59.598299809715911, 2.1319889634992228],
					[59.601588710402254, 2.1418889631810365],
					[59.601588754741378, 2.1418890181050769],
					[59.601588870005955, 2.141889085179697],
					[59.601588940838901, 2.1418890955714507],
					[59.604380417153891, 2.1413113424519938],
					[59.605146999956439, 2.1521389132934003],
					[59.605147001217375, 2.1521389201472929],
					[59.605147002755352, 2.1521389322783016],
					[59.605147003923932, 2.1521389373731705],
					[59.606960903526641, 2.1613889374901807],
					[59.60696095216219, 2.1613890057942249],
					[59.606961084019368, 2.161389080225339],
					[59.606961167333232, 2.161389088111576],
					[59.608002866543252, 2.1610195874358062],
					[59.608002910997719, 2.1610195588027219],
					[59.608002976234424, 2.1610194796982705],
					[59.608002998000444, 2.1610194308041097],
					[59.608922394618183, 2.1549694486537643],
					[59.612011287938536, 2.1462806671918488],
					[59.612011293254845, 2.1462806483212824],
					[59.612011298538334, 2.1462806116746314],
					[59.61201130028855, 2.1462805935337199],
					[59.611789099738118, 2.1397389114526799],
					[59.613289089164915, 2.1291889920841109],
					[59.61571954328884, 2.1267195392864169],
					[59.615719551740185, 2.1267195286593767],
					[59.615719570240437, 2.126719503520405],
					[59.615719577707594, 2.1267194913168348],
					[59.617961278408181, 2.1223611902654889],
					[59.617961289461284, 2.1223611435293823],
					[59.617961286142354, 2.1223610463858207],
					[59.617961271863074, 2.1223609977379505],
					[59.615750194151651, 2.118619334456822],
					[59.615472398835323, 2.1160499785079305],
					[59.615472385909484, 2.1160499384803693],
					[59.61547234625862, 2.1160498701542934],
					[59.615472319347894, 2.1160498383369131],
					[59.610730763245712, 2.1125498722791347],
					[59.608958485081786, 2.1082999222643704],
					[59.608958467455658, 2.1082998956614261],
					[59.608958425257676, 2.1082998474408527],
					[59.608958399887321, 2.1082998277656997],
					[59.602391890340463, 2.104510978945036],
					[59.602008504819743, 2.100211148461701],
					[59.60365017039949, 2.0975890052962751],
					[59.603650182281662, 2.0975889744016456],
					[59.603650196945566, 2.0975889109313193],
					[59.603650198742763, 2.097588876780514],
					[59.60253909892689, 2.0877999762372723],
					[59.60253908214758, 2.0877999316959142],
					[59.602539029443903, 2.0877998554521469],
					[59.602538994410985, 2.0877998235658382],
					[59.601311193613419, 2.0871498228402214],
					[59.601311153365891, 2.0871498115874672],
					[59.601311070237337, 2.087149807403434],
					[59.601311029045931, 2.0871498123457792],
					[59.596958320103212, 2.0888108781263774],
					[59.593450168182663, 2.0866692683783992],
					[59.589611302538813, 2.0743805777667772],
					[59.590339056911702, 2.0662698847763772],
					[59.592541548959673, 2.0687890327612544],
					[59.592541648830185, 2.0687890636537141],
					[59.592541822181758, 2.068789018920218],
					[59.592541894864887, 2.0687889452366419],
					[59.593891895291804, 2.0629000440618244],
					[59.593891896749909, 2.062900020652934],
					[59.593891895820072, 2.0628999692987535],
					[59.593891891836464, 2.0628999452386867],
					[59.591480791915309, 2.0545388451163578],
					[59.591480697910484, 2.0545387721721329],
					[59.59148050669635, 2.054538769136256],
					[59.591480410284767, 2.0545388371019411],
					[59.590619435784248, 2.0571381561467685],
					[59.584308495109461, 2.0294388553441305],
					[59.584308486663332, 2.029438832221774],
					[59.584308466393836, 2.029438790233272],
					[59.584308451802556, 2.0294387701655281],
					[59.580880786108935, 2.0254110104533116],
					[59.578430795608156, 2.0129193622205159],
					[59.57843079199241, 2.0129193452064436],
					[59.578430781384192, 2.0129193154353415],
					[59.57843077439172, 2.012919302678287],
					[59.576039086763018, 2.0086888240542375],
					[59.571991902407689, 1.9931499828569075],
					[59.57239188523738, 1.988489080783362],
					[59.57456921563611, 1.9882502202831531],
					[59.574969200934405, 1.9937500151431982],
					[59.574969243696643, 1.9937500897100939],
					[59.574969373717821, 1.9937501780273348],
					[59.574969459991038, 1.9937501902070114],
					[59.578011059202751, 1.9928002220786269],
					[59.580730476171965, 1.9949501578055369],
					[59.580730574220176, 1.99495017108321],
					[59.580730734914852, 1.9949501090819184],
					[59.580730796575665, 1.994950032231968],
					[59.581069597312826, 1.992880632526715],
					[59.581069596031377, 1.9928805919253736],
					[59.581069576629496, 1.9928805160185419],
					[59.581069560291496, 1.9928804803405813],
					[59.574980862923105, 1.9848000161636323],
					[59.577580742930664, 1.9821390407649448],
					[59.577580769597233, 1.9821389836585364],
					[59.577580786808724, 1.9821388681211847],
					[59.577580777164449, 1.9821388061763066],
					[59.576069595803766, 1.9792610447624994],
					[59.575297406034316, 1.969138969157018],
					[59.579361249824935, 1.9645390316246241],
					[59.579361259950069, 1.9645390188389229],
					[59.579361279024631, 1.9645389881835467],
					[59.579361287082847, 1.9645389705008816],
					[59.582358451121181, 1.9567001646004218],
					[59.586975054801741, 1.955375191803167],
					[59.586975055692903, 1.9553751916158817],
					[59.58697505658408, 1.9553751914285979],
					[59.587058356892591, 1.9553501921772056],
					[59.587058410625119, 1.9553501524479779],
					[59.587058481720312, 1.9553500504186931],
					[59.587058499879127, 1.95534998617401],
					[59.586861320732567, 1.9524308973546483],
					[59.599380424310574, 1.9572112473910745],
					[59.600288704181153, 1.9620000371668362],
					[59.600288757655875, 1.9620001094994481],
					[59.600288902884436, 1.9620001785594394],
					[59.600288992950802, 1.9620001774193219],
					[59.603419493004068, 1.9603501770912413],
					[59.603419529104315, 1.9603501446106122],
					[59.603419581735956, 1.9603500677581029],
					[59.603419599158507, 1.9603500231989659],
					[59.606280799123908, 1.9361611232407132],
					[59.606280796232554, 1.9361610865047882],
					[59.606280777082986, 1.9361610158519997],
					[59.606280760729526, 1.9361609801768438],
					[59.603278008099402, 1.9321193431895471],
					[59.604500198978592, 1.9215694224743347],
					[59.604500191850718, 1.921569374188226],
					[59.604500158277162, 1.9215692870332022],
					[59.604500130049296, 1.921569248540919],
					[59.60275013004334, 1.9200804482088654],
					[59.602750045962644, 1.9200804268589993],
					[59.602749891612639, 1.9200804559249889],
					[59.602749823220982, 1.9200805077221872],
					[59.598708351662751, 1.9277883684246255],
					[59.596200192199071, 1.9192610439879791],
					[59.596200103567618, 1.919260972046811],
					[59.596199917138605, 1.9192609581076101],
					[59.596199819245626, 1.9192610143518736],
					[59.594558211820605, 1.9227497162263956],
					[59.584830791206926, 1.9170387804981768],
					[59.584641902401131, 1.9148111174377636],
					[59.586239092619934, 1.9087306511505557],
					[59.586239096628006, 1.9087306254196823],
					[59.586239095542219, 1.9087305723288068],
					[59.586239093217166, 1.908730546160391],
					[59.584258500984234, 1.9015693781165091],
					[59.58464190033942, 1.8945000117361834],
					[59.584641900243639, 1.8945000099791958],
					[59.584641900147851, 1.8945000082222088],
					[59.585419599739787, 1.8738194082836122],
					[59.585419583903423, 1.8738193494422921],
					[59.585419523692103, 1.8738192538233605],
					[59.585419478330081, 1.8738192154782212],
					[59.58428897800674, 1.8733387165448374],
					[59.584288944174922, 1.8733387077418244],
					[59.584288874606841, 1.8733387047592347],
					[59.584288839665511, 1.8733387086333411],
					[59.578188861713741, 1.8752692025252329],
					[59.574347230234025, 1.8755691978064377],
					[59.567791822945843, 1.8730387333191472],
					[59.562141888574679, 1.8658305162515751],
					[59.560939097195721, 1.858469367703123],
					[59.560939074706759, 1.8584693192068793],
					[59.560939008224338, 1.8584692410005375],
					[59.560938964134635, 1.8584692095348423],
					[59.559100063692476, 1.8578498099745777],
					[59.559099971632953, 1.8578498242421524],
					[59.559099840054422, 1.8578499232899877],
					[59.559099799644429, 1.8578500082599252],
					[59.559330397920554, 1.8635193523124669],
					[59.557688724757988, 1.8664888030164148],
					[59.557688719193976, 1.8664888166306592],
					[59.557688708860823, 1.8664888419142487],
					[59.55768870498266, 1.866488853394189],
					[59.556338743815161, 1.8722108886622848],
					[59.553108396699805, 1.8719804061167171],
					[59.550380752291382, 1.8687998702485382],
					[59.550380632879531, 1.8687998459033592],
					[59.550380458068958, 1.8687999292061837],
					[59.550380403561221, 1.8688000366648909],
					[59.551749804070219, 1.8759806370283734],
					[59.551749813817423, 1.8759806669200325],
					[59.551749842824975, 1.8759807193574574],
					[59.551749861290283, 1.8759807438475471],
					[59.555710902811988, 1.879800088734304],
					[59.555938700388474, 1.8863306076129813],
					[59.555938704403758, 1.886330631622859],
					[59.555938716793598, 1.886330676942767],
					[59.555938727046104, 1.886330699630508],
					[59.55807202668705, 1.8900390007025401],
					[59.558072054443635, 1.8900390303393277],
					[59.558072117401302, 1.8900390773802038],
					[59.558072154384433, 1.8900390944067063],
					[59.559988693786337, 1.8904890574628594],
					[59.559780399907083, 1.8959999918940911],
					[59.559780410798844, 1.896000042867281],
					[59.559780457053101, 1.8960001307530048],
					[59.559780490633514, 1.8960001680428291],
					[59.561791591027266, 1.8973112678661934],
					[59.56179167284953, 1.8973112807383308],
					[59.561791816625991, 1.8973112396454919],
					[59.561791881253349, 1.8973111851146323],
					[59.564280595573152, 1.8920698733906154],
					[59.566058110777426, 1.8959694674791971],
					[59.568230383560433, 1.9041610651799188],
					[59.564769233275008, 1.9094304901653167],
					[59.564769218860263, 1.9094305234075113],
					[59.564769202888144, 1.9094305942792722],
					[59.564769202221825, 1.909430631720517],
					[59.565149801957233, 1.9118194303962845],
					[59.565149871274066, 1.9118195116669858],
					[59.565150040105529, 1.9118195665831712],
					[59.565150142388894, 1.9118195414198749],
					[59.569211051614516, 1.9077392327810625],
					[59.572138681729072, 1.9131500177535083],
					[59.569660976014688, 1.9201108534119018],
					[59.56962505867228, 1.9200998085006005],
					[59.565639056959334, 1.9188609385208852],
					[59.562869590109017, 1.9105193369372624],
					[59.562869534604317, 1.9105192776213002],
					[59.562869397946926, 1.9105192176921144],
					[59.562869315903143, 1.9105192172672114],
					[59.559650137758496, 1.9119997167520373],
					[59.557658495210518, 1.903638853409988],
					[59.55765848190515, 1.9036388242584192],
					[59.55765844924823, 1.9036387707855704],
					[59.557658428114529, 1.9036387468411327],
					[59.553261227997275, 1.8999998451256492],
					[59.553261135990788, 1.8999998273006558],
					[59.553260977956036, 1.8999998713848987],
					[59.553260910337038, 1.8999999371818794],
					[59.549858149133186, 1.9102498202864517],
					[59.548288871073417, 1.9104804027393112],
					[59.548288803893179, 1.9104804435565605],
					[59.548288720230637, 1.9104805624186034],
					[59.548288703748355, 1.9104806404634096],
					[59.550469195579872, 1.9210694016781751],
					[59.548380453636781, 1.9321385923542649],
					[59.546280808881832, 1.9309998852215617],
					[59.546750199219332, 1.9248000143025512],
					[59.546750135195175, 1.924799914180404],
					[59.546749955708101, 1.9247998454915485],
					[59.546749841231659, 1.9247998784921776],
					[59.543127641025769, 1.9295498792761743],
					[59.543127627020041, 1.929549903525571],
					[59.543127606613986, 1.9295499592980707],
					[59.543127600023062, 1.9295499873112592],
					[59.542499800105595, 1.9395388870859855],
				],
				[
					[59.526630401297233, 1.9632583225342837],
					[59.527344200993902, 1.9697722177369592],
					[59.527844201025964, 1.9767278132864792],
					[59.527844201120601, 1.9767278150408214],
					[59.527844201215217, 1.9767278167951701],
					[59.527844201309861, 1.9767278185495283],
					[59.528327601215977, 1.9822306179716098],
					[59.528327601310536, 1.9822306197259973],
					[59.528327601688758, 1.9822306267435748],
					[59.528327601783324, 1.9822306284979623],
					[59.529105397374224, 1.9875805977628394],
					[59.528469203120132, 1.9912305666191343],
					[59.528469201620972, 1.9912305722542463],
					[59.528469200593982, 1.9912305866615119],
					[59.528469200080487, 1.9912305938651549],
					[59.528294199633173, 1.9971888946196472],
					[59.528294200104938, 1.9971889033918881],
					[59.528294201048496, 1.99718892093637],
					[59.528294202411537, 1.9971889295228635],
					[59.529152602308564, 2.0029750298038755],
					[59.529152603388376, 2.002975033127306],
					[59.529152604845315, 2.0029750434687905],
					[59.529152606019409, 2.0029750485467464],
					[59.530138705867749, 2.0068667487844021],
					[59.530138710092444, 2.0068667603244572],
					[59.530138718636024, 2.0068667851591564],
					[59.530138724643237, 2.0068667963282083],
					[59.533058086627015, 2.0121556281598898],
					[59.531697006107265, 2.0177138506105132],
					[59.530260907411773, 2.0229832469157341],
					[59.530260903801377, 2.0229832636329386],
					[59.530260900943098, 2.0229832943873056],
					[59.530260900006589, 2.0229833105492689],
					[59.530516500244765, 2.027769411777478],
					[59.530516500714498, 2.0277694205508703],
					[59.530516504609785, 2.0277694428068345],
					[59.530516507050024, 2.0277694547196767],
					[59.53195810132091, 2.032955631800025],
					[59.532321999801958, 2.0423860993344394],
					[59.53207479996064, 2.0487832926301297],
					[59.532074800709992, 2.0487833066688212],
					[59.532074803006353, 2.04878333280698],
					[59.532074804553375, 2.0487833449064317],
					[59.533141504975717, 2.0534028447369588],
					[59.53314158837636, 2.0534029215293477],
					[59.533141771894087, 2.0534029492336781],
					[59.533141872902519, 2.0534028999613216],
					[59.534600029822862, 2.0508920452659121],
					[59.537349847169985, 2.0541557295191857],
					[59.537349854768841, 2.0541557368204089],
					[59.53734987184292, 2.054155752809367],
					[59.537349880333146, 2.0541557599262852],
					[59.54053878002717, 2.0565362598382695],
					[59.54053879030004, 2.0565362665878779],
					[59.540538809860799, 2.0565362785160719],
					[59.540538820931445, 2.0565362833261163],
					[59.542758164293232, 2.0574918582080937],
					[59.54484983081808, 2.0607751061640531],
					[59.547313710103076, 2.0647750734226911],
					[59.547694202783106, 2.0667972366459519],
					[59.547694239314971, 2.0667972965534598],
					[59.547694340112322, 2.0667973786920033],
					[59.547694405269205, 2.066797400739012],
					[59.548655604898904, 2.0667723991979119],
					[59.548655657210055, 2.0667723830713745],
					[59.548655744385854, 2.0667723277948125],
					[59.54865577925051, 2.0667722886447533],
					[59.549452978774283, 2.0651500877119133],
					[59.549452986753394, 2.0651500683128563],
					[59.549452997269945, 2.0651500288635498],
					[59.549452999807343, 2.0651500088133132],
					[59.54958080014665, 2.0620972071502952],
					[59.549580798133192, 2.0620971862649138],
					[59.549580791432192, 2.0620971450467032],
					[59.54958078496189, 2.0620971250822278],
					[59.54800299486309, 2.0582166482613093],
					[59.547691906609671, 2.0557556455445254],
					[59.549147359700441, 2.0533890215650792],
					[59.550391847311396, 2.0520473359395686],
					[59.55039184989181, 2.052047333630401],
					[59.5503918557567, 2.0520473253160127],
					[59.550391859228476, 2.0520473228223697],
					[59.551419501076467, 2.0507084965626734],
					[59.552744265234054, 2.0506724035037851],
					[59.553647010331211, 2.0529722610919388],
					[59.55425260662534, 2.0552806509738293],
					[59.554252653154606, 2.0552807123632642],
					[59.554252775868406, 2.0552807828473609],
					[59.554252853037909, 2.0552807935136195],
					[59.554844453231325, 2.0551195934005562],
					[59.554844497754544, 2.0551195664352835],
					[59.554844570337437, 2.0551194910550512],
					[59.55484459483165, 2.0551194433777265],
					[59.555344571199512, 2.0529251508681576],
					[59.55578606255694, 2.0527669256571066],
					[59.556674869218703, 2.0534695503130367],
					[59.557419262253546, 2.054175144960551],
					[59.5574192828932, 2.0541751602205398],
					[59.5574193265657, 2.0541751849186496],
					[59.557419351381263, 2.0541751939878456],
					[59.558452751271517, 2.0544362932111486],
					[59.5584527910529, 2.0544362956326112],
					[59.558452867896754, 2.054436283282155],
					[59.558452905944229, 2.0544362700819869],
					[59.559186206308382, 2.0539807703991393],
					[59.559186241966458, 2.053980729282304],
					[59.559186289358372, 2.0539806377947474],
					[59.559186299122246, 2.0539805842804202],
					[59.55894459871373, 2.0509332845364479],
					[59.558944572964378, 2.0509332241691669],
					[59.558944487920748, 2.0509331334631797],
					[59.558944430409127, 2.0509331027553737],
					[59.557319487083092, 2.0506887117682422],
					[59.556502972156508, 2.0499859843512955],
					[59.555825191110806, 2.047969340056607],
					[59.55476409612438, 2.0443666586468043],
					[59.553908498654479, 2.0382860796242261],
					[59.553658501435791, 2.0345139010408539],
					[59.553997399001375, 2.0301444260493953],
					[59.554775195950349, 2.0260528362182813],
					[59.55477519655966, 2.0260528307653156],
					[59.554775198669617, 2.026052819674188],
					[59.554775200076236, 2.0260528122801058],
					[59.554972400335046, 2.0229944123783321],
					[59.554972399394693, 2.022994394819011],
					[59.554972394746059, 2.0229943585002101],
					[59.55497239113182, 2.0229943414966636],
					[59.553464091166951, 2.0180971414555406],
					[59.553464091072847, 2.0180971396997101],
					[59.553464091072847, 2.0180971396997101],
					[59.552177990901498, 2.0140638397291037],
					[59.552177989821914, 2.0140638364030625],
					[59.552177987756885, 2.0140638315067592],
					[59.5521779866773, 2.0140638281807308],
					[59.551200193290903, 2.0114777443710423],
					[59.550664097347529, 2.0088777634037895],
					[59.549969597439606, 2.0046832668826871],
					[59.549969595091426, 2.0046832567204835],
					[59.549969587532736, 2.0046832334419671],
					[59.549969585184549, 2.0046832232797627],
					[59.548241889449827, 2.0005221351933971],
					[59.547327993222041, 1.9972055470123713],
					[59.547327992142037, 1.9972055436873726],
					[59.547327990076418, 1.997205538792836],
					[59.547327989887663, 1.9972055352819529],
					[59.546119589621107, 1.9936082363129999],
					[59.546119584409524, 1.9936082231999681],
					[59.546119570326972, 1.9936081959623984],
					[59.546119563332915, 1.9936081832212784],
					[59.543883462696265, 1.9904804832065057],
					[59.543883458847958, 1.9904804786849344],
					[59.543883453028215, 1.9904804710249813],
					[59.543883449179901, 1.9904804665034284],
					[59.542616888035141, 1.9890638107504333],
					[59.542233502881189, 1.9867500033942951],
					[59.543014095759382, 1.9828056384971726],
					[59.543014097352689, 1.9828056346143872],
					[59.543014098567774, 1.9828056237109679],
					[59.543014099175302, 1.9828056182592737],
					[59.543491899050139, 1.9777056184267077],
					[59.543491900359406, 1.977705609278146],
					[59.543491899223824, 1.9777055882161811],
					[59.543491898750659, 1.9777055794403591],
					[59.543011299368167, 1.9732166790673307],
					[59.54301129368114, 1.9732166571831529],
					[59.54301127874232, 1.9732166141607486],
					[59.543011268599315, 1.9732165932090036],
					[59.541666868765908, 1.9710776937575807],
					[59.541666797814301, 1.9710776482623977],
					[59.54166664614241, 1.9710776267616452],
					[59.541666564625636, 1.9710776526976725],
					[59.539688774811957, 1.9728804421524371],
					[59.538369460165029, 1.9737914152883782],
					[59.537322384433118, 1.9720805185989181],
					[59.536414094031564, 1.9690582508074115],
					[59.535491895845098, 1.9645832597238915],
					[59.53549189368352, 1.9645832530784055],
					[59.535491891142755, 1.9645832394141367],
					[59.535491889075963, 1.9645832345233429],
					[59.534436288849534, 1.9615666345538234],
					[59.534436285701801, 1.9615666263408416],
					[59.53443627959598, 1.9615666134241414],
					[59.534436277434267, 1.9615666067790793],
					[59.533611295972939, 1.9599943431031202],
					[59.532883499127081, 1.9515916835237519],
					[59.532883426781467, 1.9515915957853935],
					[59.532883247364879, 1.9515915447076699],
					[59.53288313940272, 1.9515915815552842],
					[59.530255439146259, 1.9551581806927048],
					[59.530255422235172, 1.9551582179503209],
					[59.530255403942618, 1.9551582963063674],
					[59.530255403357387, 1.9551583354635613],
					[59.530791495806028, 1.9580471993978943],
					[59.530369241897141, 1.960238661586176],
					[59.529186142329657, 1.9599831046022256],
					[59.529186108881696, 1.9599831027394812],
					[59.529186045323371, 1.9599831107342682],
					[59.52918601333576, 1.9599831192109123],
					[59.527569313285554, 1.9607609198785705],
					[59.527569282947773, 1.9607609422014574],
					[59.527569232739154, 1.9607609970734483],
					[59.527569212868293, 1.9607610296225413],
					[59.526630413394486, 1.9632582295225267],
					[59.526630407402699, 1.9632582520663164],
					[59.526630400861087, 1.9632582977884026],
					[59.526630401297233, 1.9632583225342837],
				],
				[
					[59.4991747998628, 2.0034971927728105],
					[59.499174803059006, 2.0034972186964919],
					[59.499174813907722, 2.0034972696170579],
					[59.499174824234061, 2.0034972940578468],
					[59.499435923904613, 2.0039861933614995],
					[59.499435927940901, 2.003986201384977],
					[59.499435937795944, 2.003986217061247],
					[59.499435942723473, 2.0039862248993789],
					[59.499897041058617, 2.0045695211152084],
					[59.500255439153769, 2.005055718190357],
					[59.500255466696643, 2.0050557443702792],
					[59.500255531912266, 2.0050557839880438],
					[59.500255567802498, 2.0050557977965493],
					[59.500763867701437, 2.0051390976554488],
					[59.500763876708362, 2.0051390975552099],
					[59.500763894910662, 2.0051391008608226],
					[59.500763904808863, 2.0051391005752666],
					[59.501372205438251, 2.0051252007238825],
					[59.501372206329521, 2.0051252005385494],
					[59.502030606450383, 2.0051058005327618],
					[59.502741687219277, 2.0050862999106291],
					[59.503299970091931, 2.0051723988261263],
					[59.503299978207586, 2.0051723989111876],
					[59.503299996315668, 2.0051724004637914],
					[59.5033000053226, 2.0051724003635027],
					[59.503808305392617, 2.0051584999483154],
					[59.503808351550212, 2.0051584868036807],
					[59.503808433727748, 2.0051584395762254],
					[59.503808468950645, 2.0051584074319559],
					[59.504508469371302, 2.0040473078016756],
					[59.504508470074086, 2.004047304109803],
					[59.504508471762392, 2.0040473019857976],
					[59.504508472559401, 2.0040473000471803],
					[59.505208472998639, 2.0028362002651061],
					[59.505208478577678, 2.0028361866942692],
					[59.505208489830046, 2.0028361613058312],
					[59.505208493626547, 2.0028361481058341],
					[59.505355789450327, 2.0022361691736354],
					[59.505955779077794, 2.001027889416481],
					[59.50595578057748, 2.0010278837856159],
					[59.505955786344892, 2.0010278737207754],
					[59.505955787938845, 2.0010278698432282],
					[59.506102987921231, 2.0006278695682758],
					[59.506102992420217, 2.0006278526755841],
					[59.506102998555782, 2.0006278159400277],
					[59.506103000380968, 2.0006277996037856],
					[59.506100200201644, 2.0002305985116111],
					[59.506100199918748, 2.0002305932517004],
					[59.506100199352993, 2.0002305827318607],
					[59.506100199070112, 2.0002305774719318],
					[59.505989098943282, 1.999241678233677],
					[59.505989096217711, 1.9992416610717603],
					[59.505989087484409, 1.9992416327498734],
					[59.505989082085044, 1.9992416161445241],
					[59.50567530695146, 1.9985529922515859],
					[59.506336105132604, 1.9985362991481379],
					[59.506336165266482, 1.998536277764597],
					[59.506336260286609, 1.9985362012487662],
					[59.506336294375899, 1.9985361480553352],
					[59.506436293905722, 1.9981361482882385],
					[59.506436295919244, 1.9981361354583587],
					[59.506436299149335, 1.9981361117374774],
					[59.506436300177256, 1.9981360973398268],
					[59.506425201011957, 1.9968472894622638],
					[59.506775054205605, 1.9965391193218602],
					[59.507133184632728, 1.9966279773367934],
					[59.507594243115598, 1.9972112246469922],
					[59.507594303688535, 1.9972112616782278],
					[59.507594434670587, 1.9972112840479832],
					[59.507594505079652, 1.9972112693865309],
					[59.508097305308013, 1.9969001699683524],
					[59.508097345215774, 1.9969001244238],
					[59.508097389842895, 1.9969000158399171],
					[59.508097394656602, 1.9968999545539703],
					[59.507939096784007, 1.9962082616155401],
					[59.507830800296503, 1.9955166848199448],
					[59.507819599862344, 1.9943249987599854],
					[59.507814100442914, 1.9933333371339303],
					[59.508011238076257, 1.9928307975544226],
					[59.508266704221711, 1.9928251995153778],
					[59.508266762571957, 1.9928251784916651],
					[59.508266856980285, 1.9928251074010541],
					[59.508266892052731, 1.9928250557665101],
					[59.508561292038593, 1.9918222567626194],
					[59.508561294848278, 1.9918222419924565],
					[59.508561298685215, 1.9918222128235823],
					[59.508561299712419, 1.9918221984248949],
					[59.508553000294079, 1.9910277981502742],
					[59.50855020027182, 1.9902360988400845],
					[59.508550200177389, 1.9902360970866764],
					[59.508550200177389, 1.9902360970866764],
					[59.508536300244614, 1.9891416987314277],
					[59.508533499568188, 1.9885471990375669],
					[59.508533500081626, 1.988547191838193],
					[59.508533498340313, 1.9885471762435112],
					[59.50853349697681, 1.9885471676623865],
					[59.508422396535792, 1.9878555673339466],
					[59.508422384842937, 1.9878555343105813],
					[59.508422349965649, 1.9878554724331603],
					[59.508422326875646, 1.9878554453324935],
					[59.508069599369243, 1.9875666049486644],
					[59.508061300871269, 1.9866750675977511],
					[59.508461175680061, 1.9861669258754731],
					[59.508816650223594, 1.9862584937052599],
					[59.508816729732295, 1.9862584806682013],
					[59.508816854249645, 1.9862583997312697],
					[59.508816896584577, 1.9862583323890437],
					[59.508961296733872, 1.9853583318173027],
					[59.508961299259347, 1.9853583117862648],
					[59.50896129718042, 1.9853582732114829],
					[59.508961295249733, 1.9853582541099994],
					[59.508800195306975, 1.9846666547675211],
					[59.508800158038035, 1.9846665987108048],
					[59.508800058275341, 1.9846665202294078],
					[59.50879999409365, 1.9846664999299604],
					[59.508244536201126, 1.9846830953692345],
					[59.50798350124095, 1.9839971643518888],
					[59.508019600222376, 1.9819111031870043],
					[59.508019600127824, 1.9819111014336701],
					[59.508019600033286, 1.9819110996803362],
					[59.508019599938748, 1.9819110979270005],
					[59.508008499983561, 1.9808193976045341],
					[59.508000200082058, 1.9797277983850947],
					[59.508000179744307, 1.979727738800263],
					[59.508000107930592, 1.9797276438617712],
					[59.508000057345811, 1.9797276083220472],
					[59.507691837118003, 1.9796359320315766],
					[59.507380777178369, 1.9790499062039468],
					[59.507380743715792, 1.9790498706371746],
					[59.507380665070166, 1.9790498161349313],
					[59.507380619185092, 1.9790498008920807],
					[59.506569419130351, 1.9789720013580143],
					[59.506569384980274, 1.9789720031685991],
					[59.506569319219174, 1.9789720204436434],
					[59.506569286622373, 1.9789720343409229],
					[59.506113786873861, 1.9792831348041873],
					[59.506113757099783, 1.9792831676143647],
					[59.506113714161025, 1.9792832404051808],
					[59.506113701090911, 1.9792832821390629],
					[59.505977601769786, 1.9807749702494644],
					[59.505783111610846, 1.9816749242268186],
					[59.505283130295439, 1.9824831941554142],
					[59.505283122233983, 1.9824832117937869],
					[59.505283108595627, 1.9824832430062636],
					[59.505283104099043, 1.9824832599007889],
					[59.505038704177167, 1.9836805604721957],
					[59.505038702867289, 1.9836805696099968],
					[59.505038701044249, 1.9836805859464834],
					[59.505038699639826, 1.9836805933310999],
					[59.504999800014517, 1.9850721938111919],
					[59.504999800109005, 1.9850721955643986],
					[59.504999800297995, 1.9850721990707774],
					[59.504999799595765, 1.9850722027630467],
					[59.505008099622295, 1.9858667013889666],
					[59.505016498975898, 1.9870582004585526],
					[59.504463780951298, 1.9874692396248761],
					[59.504463773213558, 1.9874692465567885],
					[59.504463759426109, 1.9874692582958071],
					[59.504463751688363, 1.987469265227733],
					[59.503810959191505, 1.9881831583669076],
					[59.503355466085068, 1.9885915506280418],
					[59.503355456753681, 1.9885915614372713],
					[59.50335543898214, 1.9885915828699456],
					[59.503355431433256, 1.9885915933076028],
					[59.502905430868772, 1.9892998938176658],
					[59.502905421915081, 1.9892999116389554],
					[59.502905407667086, 1.9892999482915759],
					[59.50290540157598, 1.9892999690617725],
					[59.502810904984628, 1.989897151050434],
					[59.502463710783779, 1.9908999347859757],
					[59.502463705583644, 1.9908999553699072],
					[59.502463700530853, 1.990899995423467],
					[59.502463700772587, 1.9909000166461794],
					[59.50257479953649, 1.9921861092156341],
					[59.502583099979233, 1.992980601132248],
					[59.502583104163179, 1.9929806286248177],
					[59.502583119661054, 1.9929806821246578],
					[59.502583130880588, 1.9929807063788147],
					[59.502891530781532, 1.9934695062859689],
					[59.502891531861543, 1.9934695096065411],
					[59.502891534724071, 1.9934695125558211],
					[59.502891535804075, 1.9934695158763955],
					[59.503096979118091, 1.993761133582844],
					[59.502899819645414, 1.9943610064640689],
					[59.502597111415, 1.994766484710943],
					[59.502041730284276, 1.9946831012676649],
					[59.502041660861138, 1.9946831174985999],
					[59.502041550220191, 1.9946831884045693],
					[59.502041508205529, 1.9946832450182923],
					[59.501841507986043, 1.9953832452117988],
					[59.501841505081536, 1.9953832582256583],
					[59.501841501243689, 1.9953832873883097],
					[59.501841500216031, 1.9953833017840306],
					[59.501852599965616, 1.9964750024143183],
					[59.501866499644365, 1.9977639012608071],
					[59.501877598128452, 1.9989554312745177],
					[59.501216665104593, 1.9990720029731441],
					[59.50121662289002, 1.999072022395044],
					[59.501216550098981, 1.9990720765434427],
					[59.501216521399371, 1.9990721126520137],
					[59.50096922111485, 1.9995749117515951],
					[59.500616598090083, 2.0002803563362601],
					[59.5000583622341, 2.0000970105301237],
					[59.50005828412867, 2.0000970161468627],
					[59.500058156361845, 2.0000970870482844],
					[59.50005810740312, 2.0000971486415398],
					[59.499713732323251, 2.0013970525137985],
					[59.499461023453236, 2.0015026153593638],
					[59.499460986165893, 2.0015026426148372],
					[59.499460928388089, 2.0015027078120511],
					[59.499460907100648, 2.0015027476921881],
					[59.499216506537294, 2.0024027475606152],
					[59.499216505320312, 2.0024027584491568],
					[59.499216501103824, 2.0024027805969826],
					[59.499216499886828, 2.00240279148551],
					[59.4991747998628, 2.0034971927728105],
				],
				[
					[59.476597001206606, 2.0226250156716485],
					[59.476749801182805, 2.0246444153460175],
					[59.476749803998736, 2.0246444342473957],
					[59.476749816057698, 2.0246444742614518],
					[59.476749823424143, 2.024644493991516],
					[59.47732202341453, 2.0257222936600092],
					[59.477322104715739, 2.0257223476750936],
					[59.477322273833288, 2.0257223587020965],
					[59.477322361743553, 2.0257223174659211],
					[59.477755752140197, 2.0251195310244041],
					[59.47850572669735, 2.0243807552396755],
					[59.47948321686782, 2.0237280957444113],
					[59.479916518616946, 2.0246611836081252],
					[59.479916521760593, 2.0246611918143347],
					[59.479916529642608, 2.0246612043533934],
					[59.47991653367756, 2.0246612123749572],
					[59.480458134372185, 2.0254612119479862],
					[59.480458177720799, 2.0254612472562181],
					[59.48045827924058, 2.0254612917721473],
					[59.480458335347301, 2.0254612960929048],
					[59.481491709893845, 2.025278000376312],
					[59.482608283851867, 2.0253668992577709],
					[59.48260829285892, 2.0253668991633331],
					[59.482608311764324, 2.0253668987898017],
					[59.482608321662696, 2.0253668985107023],
					[59.483658153083276, 2.02525301713704],
					[59.483988697187478, 2.0264333179182077],
					[59.483855400696882, 2.0278638808912213],
					[59.483855403466237, 2.0278639157517704],
					[59.483855419419051, 2.0278639780008167],
					[59.483855431898995, 2.0278640090784572],
					[59.484841531876498, 2.029389009473471],
					[59.484841544402478, 2.0293890245971791],
					[59.484841570157968, 2.0293890511553871],
					[59.484841584278819, 2.0293890624053259],
					[59.48620265274149, 2.0303529416885007],
					[59.487049832282104, 2.0316640096613936],
					[59.487049846684329, 2.0316640261702794],
					[59.487049879054105, 2.0316640584499317],
					[59.487049896927793, 2.0316640724682689],
					[59.488169239304284, 2.0323334365497683],
					[59.488555404616882, 2.0333028510681386],
					[59.488716499144942, 2.0345306072059826],
					[59.48864700021948, 2.0356999887469707],
					[59.488647001063697, 2.0357000045197906],
					[59.488647003737263, 2.035700037633517],
					[59.488647008240605, 2.0357000544211337],
					[59.488963707913747, 2.036822254849509],
					[59.488963718233876, 2.0368222792937858],
					[59.488963744925904, 2.0368223233863625],
					[59.488963762282886, 2.0368223446028368],
					[59.490413733788017, 2.0382112181527421],
					[59.490929377059359, 2.0393234742901996],
					[59.491149801173854, 2.0416889183007423],
					[59.491149804972544, 2.0416889387801058],
					[59.491149816041634, 2.0416889772489566],
					[59.491149824203333, 2.0416889950541623],
					[59.492802585844878, 2.0447389234003799],
					[59.490897006791705, 2.0516110468854811],
					[59.490897004349357, 2.0516110686541307],
					[59.49089700223233, 2.0516111133920667],
					[59.490897003355407, 2.0516111344245997],
					[59.491580394886192, 2.0554305884880733],
					[59.490360959255788, 2.0593997772326289],
					[59.489108329422947, 2.0592192019911719],
					[59.489108253843057, 2.0592192211209301],
					[59.489108140345024, 2.0592193065436368],
					[59.489108101628972, 2.0592193747730314],
					[59.487672002046516, 2.0702388745739948],
					[59.487672047759624, 2.0702389714725529],
					[59.487672200668896, 2.0702390675640219],
					[59.487672308849803, 2.0702390683260208],
					[59.489558202856891, 2.0690114026354651],
					[59.490502602952944, 2.071438945424815],
					[59.491269201076172, 2.0813194144880036],
					[59.491269206039725, 2.0813194400476038],
					[59.491269222113587, 2.0813194881312382],
					[59.491269233223875, 2.0813195106552436],
					[59.493141503866823, 2.0841500652574783],
					[59.494049799893872, 2.0984000134633272],
					[59.494049802327545, 2.0984000253690205],
					[59.494049807380819, 2.0984000526865088],
					[59.494049810705924, 2.0984000644093821],
					[59.497027598204653, 2.1071694260619838],
					[59.495991499990168, 2.1218999852583065],
					[59.495991499561939, 2.1218999942066876],
					[59.495991500581127, 2.1219000134922807],
					[59.495991501044394, 2.1219000222584632],
					[59.496799798588391, 2.1292306022860221],
					[59.495658100699522, 2.1440693849028958],
					[59.495658104237357, 2.144069417852335],
					[59.495658121119895, 2.1440694817533288],
					[59.49565813535613, 2.1440695125232456],
					[59.500738734607985, 2.151511213339834],
					[59.50073878890101, 2.1515112519151938],
					[59.500738909722074, 2.1515112875834004],
					[59.500738978033205, 2.1515112843133131],
					[59.503710980840282, 2.1502613662393344],
					[59.506480411067507, 2.1581889656172129],
					[59.506480455305393, 2.1581890186628265],
					[59.506480567792828, 2.1581890844170637],
					[59.506480696361621, 2.1581890618127493],
					[59.506480778418329, 2.1581889600354618],
					[59.506480800247964, 2.1581888953250385],
					[59.506039100870716, 2.1421000288305714],
					[59.507530763221403, 2.1373695462389604],
					[59.511422080350236, 2.1356808697114174],
					[59.513408110245201, 2.1416000643080939],
					[59.51340811731756, 2.1416000788232541],
					[59.513408132353746, 2.1416001076717421],
					[59.513408141209133, 2.1416001218232577],
					[59.519749840647556, 2.1498612212100721],
					[59.519749878247531, 2.149861250790067],
					[59.519749958672946, 2.1498612893782534],
					[59.51975000515688, 2.1498612994143902],
					[59.52175280459813, 2.1498113004629724],
					[59.521752872416037, 2.1498112706826138],
					[59.521752970174958, 2.1498111762763203],
					[59.521752999931358, 2.1498111081410336],
					[59.521900199824792, 2.1466111086385906],
					[59.521900197825097, 2.1466110877642834],
					[59.521900191058748, 2.1466110448062445],
					[59.521900183525176, 2.1466110215131051],
					[59.518872394312019, 2.1394305466677697],
					[59.517791912927834, 2.132000076192389],
					[59.518872334924026, 2.1310112488911752],
					[59.518872363199073, 2.1310112041003229],
					[59.518872393432723, 2.1310111110284309],
					[59.518872396190275, 2.1310110608107933],
					[59.518139105741646, 2.1274000118941934],
					[59.519200187294103, 2.1240694714706705],
					[59.521600182314906, 2.1187806821520847],
					[59.521600186308717, 2.1187806724668996],
					[59.521600194110924, 2.1187806495874209],
					[59.521600196229066, 2.1187806385126491],
					[59.523011295742243, 2.111388938471058],
					[59.525161296288779, 2.1002389375747477],
					[59.525161298312327, 2.1002389247430653],
					[59.525161298607671, 2.1002388963023528],
					[59.52516129875535, 2.1002388820820026],
					[59.524750204293035, 2.0956611407411287],
					[59.526297249361591, 2.0926392431256984],
					[59.530338755343337, 2.0969001384164954],
					[59.530338829077124, 2.096900169394587],
					[59.530338975777219, 2.0969001658691178],
					[59.530339049727985, 2.0969001329373573],
					[59.531891850208872, 2.0951501324582176],
					[59.53189187302906, 2.0951500869640922],
					[59.531891893150892, 2.0951499905749462],
					[59.531891890452563, 2.0951499396799314],
					[59.530739155407517, 2.0915612393536658],
					[59.534838950723113, 2.0904890938210405],
					[59.534838999525725, 2.0904890624930701],
					[59.534839073541711, 2.090488979848423],
					[59.534839097770551, 2.0904889269599352],
					[59.535208497647439, 2.0878111265414319],
					[59.535208486271323, 2.0878110650067807],
					[59.535208428421093, 2.0878109598043895],
					[59.535208381055533, 2.0878109163200502],
					[59.530911263598455, 2.085899853507998],
					[59.527980791085945, 2.0765888406979101],
					[59.527980780959133, 2.0765888197228697],
					[59.527980758124613, 2.076588780078477],
					[59.527980743634053, 2.0765887617763856],
					[59.525680817178049, 2.0741999364449426],
					[59.526780775394656, 2.0688390410357851],
					[59.53035008128726, 2.067250183133639],
					[59.530350096160539, 2.0672501747427674],
					[59.530350124828892, 2.0672501546352655],
					[59.530350137825977, 2.0672501448573954],
					[59.531958438054517, 2.0657112447933423],
					[59.531958463114023, 2.065711207686435],
					[59.531958492968577, 2.0657111252354752],
					[59.531958498748423, 2.0657110814623518],
					[59.531139099334411, 2.0568805826945531],
					[59.531139099147303, 2.0568805791849192],
					[59.531139097881706, 2.0568805723498254],
					[59.531139097788135, 2.0568805705950099],
					[59.529769597878726, 2.0478499695413652],
					[59.529769592297853, 2.0478499494068707],
					[59.529769575694331, 2.0478499084896629],
					[59.529769565656714, 2.047849889277253],
					[59.527802966384186, 2.0449192891155059],
					[59.527802870310822, 2.0449192451356417],
					[59.527802693075287, 2.0449192676255699],
					[59.527802611021812, 2.0449193342798528],
					[59.525891613681182, 2.050410738595017],
					[59.522889039312261, 2.0475109551353121],
					[59.522888961299451, 2.0475109287025748],
					[59.522888810522559, 2.0475109403794907],
					[59.522888737945827, 2.0475109819978488],
					[59.520260938452587, 2.0511109822418234],
					[59.520260936763528, 2.0511109843645698],
					[59.520260934276685, 2.0511109884258265],
					[59.520260931696264, 2.051110990732834],
					[59.518230470309753, 2.0542498324489475],
					[59.513741636174807, 2.0557692101823286],
					[59.513741595216239, 2.05576923637362],
					[59.513741530466312, 2.055769306489541],
					[59.513741506581439, 2.0557693486602564],
					[59.512419245803152, 2.0608108992755758],
					[59.506789061932054, 2.0607609004804606],
					[59.505269604995448, 2.0537610995127591],
					[59.506741894868014, 2.0471111422079069],
					[59.50674189693477, 2.0471111134145881],
					[59.506741891967401, 2.0471110541635893],
					[59.506741885026997, 2.0471110254594151],
					[59.504811285420999, 2.0423693254118707],
					[59.504811209098385, 2.0423692631968544],
					[59.504811041811209, 2.0423692357532506],
					[59.504810949767872, 2.0423692672022082],
					[59.501911187915233, 2.0456495992875392],
					[59.500191891030326, 2.0399499413620341],
					[59.500191841915679, 2.0399498823939437],
					[59.500191714927226, 2.0399498164938734],
					[59.500191637944774, 2.0399498093774913],
					[59.498408429163469, 2.0405303479557233],
					[59.497397390994898, 2.0373610396962012],
					[59.497397351261505, 2.0373609876570038],
					[59.497397249229294, 2.037360916606787],
					[59.497397185335423, 2.0373609014706693],
					[59.493419306644576, 2.037654675409367],
					[59.493447395015174, 2.0375250424541735],
					[59.49344739571896, 2.037525038764175],
					[59.493447397220265, 2.0375250331369705],
					[59.493447397924051, 2.0375250294469547],
					[59.493786297641051, 2.0352694296254632],
					[59.493786297547238, 2.0352694278726684],
					[59.493786298250939, 2.0352694241825917],
					[59.493786299048445, 2.0352694222452983],
					[59.494158496865531, 2.0321056392157946],
					[59.494298162009557, 2.031626150204449],
					[59.494758319883609, 2.0315807996595345],
					[59.494758377068599, 2.0315807736386828],
					[59.494758464698208, 2.0315806934615024],
					[59.494758496034116, 2.0315806391206084],
					[59.49638078898716, 2.0236389730715918],
					[59.498419572370082, 2.0201807027975143],
					[59.498419586251018, 2.0201806591504345],
					[59.498419593325004, 2.0201805726033308],
					[59.498419587315283, 2.0201805277654228],
					[59.497530787099457, 2.0178610286748659],
					[59.497530753176704, 2.0178609843138662],
					[59.497530668633871, 2.0178609203263869],
					[59.497530617122507, 2.0178609008848798],
					[59.496600594255938, 2.0177821605898423],
					[59.49661689974517, 2.0174306085613773],
					[59.496616899651116, 2.0174306068085017],
					[59.496616900354297, 2.0174306031177585],
					[59.496616900260243, 2.0174306013648815],
					[59.496627999791457, 2.0132389027718851],
					[59.496814100185652, 2.0071722053892849],
					[59.496814099808894, 2.0071721983778903],
					[59.49681409896116, 2.0071721826022224],
					[59.496814097598929, 2.007172174023208],
					[59.496314098693887, 2.003327780009363],
					[59.495961301481046, 1.9984833137402775],
					[59.49692519519872, 1.9937417477689188],
					[59.497197393015732, 1.9927722540627235],
					[59.497197395301086, 1.9927722128197951],
					[59.497197383829388, 1.9927721336752184],
					[59.497197370072321, 1.992772095773589],
					[59.497125169921709, 1.9926554955689644],
					[59.49712514209341, 1.9926554641431775],
					[59.497125075700112, 1.9926554194801318],
					[59.497125037931937, 1.9926554043044125],
					[59.496586138114147, 1.99254980313267],
					[59.496586086935537, 1.9925498067024092],
					[59.496585992327219, 1.9925498405870321],
					[59.496585948803158, 1.9925498691491368],
					[59.495338774882853, 1.9939804394412937],
					[59.494055532639912, 1.9946192100390248],
					[59.492874957554513, 1.9948720036168237],
					[59.492874917217407, 1.9948720244199207],
					[59.492874847993043, 1.9948720778204081],
					[59.492874820185733, 1.9948721137374705],
					[59.492174820297869, 1.9963360146638578],
					[59.492174809706952, 1.9963360523122331],
					[59.492174806821929, 1.9963361326618625],
					[59.492174814433426, 1.9963361736105609],
					[59.492563714417969, 1.9973333734310723],
					[59.492563726433517, 1.9973333957417012],
					[59.492563755623557, 1.9973334357449368],
					[59.49256377378358, 1.9973334550046553],
					[59.493388701797301, 1.9980056969986235],
					[59.49340809913577, 1.9989416451095789],
					[59.492660929190869, 2.0001665967448101],
					[59.49266092262647, 2.0001666087436636],
					[59.49266091226572, 2.0001666339378001],
					[59.492660906686865, 2.0001666475038622],
					[59.492122007289112, 2.0021527476805763],
					[59.49212200569508, 2.0021527515564372],
					[59.492122004992318, 2.0021527552469549],
					[59.492122004289556, 2.0021527589374895],
					[59.491710903861183, 2.0041360595708873],
					[59.491710903158371, 2.0041360632613223],
					[59.491710901846972, 2.0041360723947781],
					[59.491710901238378, 2.0041360778377828],
					[59.491527601418163, 2.0057777775927317],
					[59.491527600621097, 2.0057777795305434],
					[59.491527600715315, 2.0057777812831294],
					[59.491527600903709, 2.0057777847882705],
					[59.491302601209107, 2.0084999838014075],
					[59.491302599897395, 2.0084999929346026],
					[59.49130260093326, 2.0085000122128802],
					[59.491302600607, 2.0085000229134709],
					[59.49143590096098, 2.0096972218467735],
					[59.491435914807298, 2.0096972615019428],
					[59.491435956572126, 2.0096973343448532],
					[59.491435986179035, 2.0096973654097567],
					[59.492038735953834, 2.0101140292791078],
					[59.49241370255821, 2.0110639464560425],
					[59.492502590573061, 2.0120471126560679],
					[59.492052874024694, 2.0124190794123682],
					[59.491580782165862, 2.0113749188508603],
					[59.491580752182799, 2.0113748807739618],
					[59.491580677817907, 2.0113748217875393],
					[59.491580633530212, 2.0113748026306277],
					[59.490750034110036, 2.0112304026793395],
					[59.49074998293036, 2.0112304062195028],
					[59.490749890989953, 2.0112304394896947],
					[59.490749849337931, 2.0112304694048344],
					[59.489747049109425, 2.0123915692801915],
					[59.489747048218128, 2.0123915694652488],
					[59.489747045638396, 2.0123915717729184],
					[59.489747045732514, 2.0123915735254361],
					[59.488349845656536, 2.0140887736455171],
					[59.488349843170845, 2.0140887777053966],
					[59.488349837214116, 2.0140887842577224],
					[59.488349833837127, 2.0140887885026024],
					[59.487166533679037, 2.0158554893195006],
					[59.485935938666181, 2.0176942812881782],
					[59.484516583647668, 2.0194637250024625],
					[59.483661154746443, 2.0197053774515843],
					[59.482900141961544, 2.018983159790678],
					[59.482197346194091, 2.0182331638328095],
					[59.482197295901315, 2.0182331335154085],
					[59.482197187298041, 2.0182331082044804],
					[59.482197128987558, 2.0182331132109574],
					[59.481441628733286, 2.0185192128660745],
					[59.481441607718239, 2.0185192243104657],
					[59.481441567658756, 2.018519250333779],
					[59.481441549599658, 2.018519266479962],
					[59.480544294229439, 2.0195304187475625],
					[59.479713865049078, 2.0197054025562404],
					[59.479105571118019, 2.019794202224384],
					[59.479105552400711, 2.0197942061048164],
					[59.479105515248065, 2.0197942191216387],
					[59.479105496812743, 2.0197942282580095],
					[59.478369296639691, 2.0202359279928475],
					[59.478369290588567, 2.0202359327900932],
					[59.478369277595036, 2.020235942569355],
					[59.478369272341219, 2.0202359454298935],
					[59.47739157237433, 2.0210498459284989],
					[59.477391557880082, 2.0210498613322998],
					[59.477391531659478, 2.0210498933376053],
					[59.477391520824433, 2.0210499097543582],
					[59.476597020807141, 2.0226249106442151],
					[59.476597013115622, 2.0226249352653083],
					[59.476597002377062, 2.0226249870877888],
					[59.476597001206606, 2.0226250156716485],
				],
				[
					[59.462749800969704, 2.057094410560814],
					[59.46274980063928, 2.0570944212521374],
					[59.462824801071335, 2.0577944213164918],
					[59.462824801071335, 2.0577944213164918],
					[59.462902601060108, 2.0584972218685307],
					[59.462980400897209, 2.0591972224035269],
					[59.462980402068972, 2.0591972274737746],
					[59.462980404412491, 2.0591972376142831],
					[59.462980404692878, 2.0591972428681755],
					[59.463119205389035, 2.0598278426635859],
					[59.463258104807629, 2.0604583436649455],
					[59.463258141152771, 2.0604583999191761],
					[59.463258241763498, 2.0604584783479805],
					[59.463258306029076, 2.0604585005225649],
					[59.463619405625131, 2.0604473999831834],
					[59.463619432460021, 2.0604473962265732],
					[59.463619482096988, 2.0604473806910621],
					[59.463619505883912, 2.0604473704799058],
					[59.464061205533412, 2.0601723695813279],
					[59.464061211586163, 2.0601723647933645],
					[59.464061223691701, 2.0601723552174191],
					[59.464061229837888, 2.0601723521808091],
					[59.464380707987942, 2.0598973705333106],
					[59.464722280241929, 2.0597473824068175],
					[59.464722281133298, 2.0597473822231929],
					[59.465061180775848, 2.0595973836011563],
					[59.465400081170309, 2.0594473833037457],
					[59.465400096934736, 2.0594473747439994],
					[59.465400128183205, 2.059447352370269],
					[59.465400142869342, 2.0594473404913227],
					[59.466222329809185, 2.0586112537521291],
					[59.46694448561184, 2.0581084842907322],
					[59.467700007885973, 2.0579030049787228],
					[59.468213863352226, 2.0580001963029741],
					[59.468213870670198, 2.058000198336531],
					[59.468213887793304, 2.0580001983494132],
					[59.468213896894028, 2.0580002000155666],
					[59.468541697100598, 2.0580058008040725],
					[59.468541720369842, 2.0580057977794985],
					[59.468541765736497, 2.0580057866592729],
					[59.468541788725283, 2.0580057783799615],
					[59.468883388601611, 2.0578362799287246],
					[59.468883405163801, 2.0578362694319314],
					[59.468883433737787, 2.0578362476052954],
					[59.468883448423711, 2.0578362357243267],
					[59.469147327346747, 2.0575473577686814],
					[59.469480699890482, 2.0573557725843075],
					[59.469816799605908, 2.0571612729710944],
					[59.469816801388653, 2.0571612726036363],
					[59.470150100928663, 2.0569668731802633],
					[59.470483401293848, 2.0567723738247872],
					[59.470483426302152, 2.0567723527311075],
					[59.470483467828835, 2.0567723034391112],
					[59.470483485238631, 2.056772275057027],
					[59.470722385278194, 2.0561861740701977],
					[59.47072238626307, 2.0561861756381155],
					[59.470722386169548, 2.0561861738864353],
					[59.470958474636475, 2.0555973001662387],
					[59.471333458336915, 2.0551112211506686],
					[59.471705738184681, 2.0546279481238732],
					[59.471989008642232, 2.0544418672660778],
					[59.472275107626324, 2.0542584673248321],
					[59.4725611097655, 2.0540752310229373],
					[59.472897107765192, 2.0542501762070735],
					[59.473233208365805, 2.0544251760768621],
					[59.473233207568001, 2.0544251780124911],
					[59.473572107778814, 2.0546001775971603],
					[59.473908179883573, 2.0547751632536317],
					[59.474216557926702, 2.0550834408175644],
					[59.474522057798495, 2.0553918408142198],
					[59.474827626351917, 2.0557001097515641],
					[59.475047011183669, 2.0563278663687981],
					[59.475266511209689, 2.0569556653753653],
					[59.475485902078219, 2.0575833387131151],
					[59.475527600138903, 2.0583917100613522],
					[59.475569200111508, 2.0592000108473498],
					[59.475610900329663, 2.0600083100060509],
					[59.475610904218833, 2.060008332230324],
					[59.475610917438892, 2.0600083773286157],
					[59.475610925784949, 2.0600083986343858],
					[59.475902624835442, 2.0605278983542381],
					[59.476191525111652, 2.0610472961022008],
					[59.47619152520511, 2.0610472978541816],
					[59.476483096713672, 2.0615694464552026],
					[59.476435912252889, 2.0624136795255641],
					[59.476033321391718, 2.062372000689312],
					[59.476033319608952, 2.0623720010565898],
					[59.475627820328043, 2.0623304013142261],
					[59.475627808646635, 2.0623304019495468],
					[59.475627786081745, 2.0623304012845769],
					[59.475627774400358, 2.0623304019198954],
					[59.47528887416901, 2.0623748024537942],
					[59.474949973901317, 2.0624192018552816],
					[59.47460827368279, 2.0624637022398837],
					[59.474608245251979, 2.06246370986781],
					[59.474608189842272, 2.062463735451499],
					[59.474608163754816, 2.0624637532236174],
					[59.474252663808308, 2.0627915526596672],
					[59.473897065206756, 2.0631192535195089],
					[59.473541565264711, 2.0634442523874332],
					[59.473541549967095, 2.0634442697069515],
					[59.473541524813641, 2.0634443049962599],
					[59.473541515051174, 2.0634443247179326],
					[59.473302615298181, 2.0640332256069507],
					[59.473302614406798, 2.0640332257905221],
					[59.473066515177379, 2.0646193255214911],
					[59.472827615099526, 2.0652082247751582],
					[59.472827614208136, 2.065208224958698],
					[59.472591516371025, 2.0657943210736578],
					[59.47238041831281, 2.0662582163018754],
					[59.472166518170937, 2.0667221154742808],
					[59.472166518264288, 2.0667221172260932],
					[59.471955418415689, 2.0671860164267395],
					[59.471955416726274, 2.0671860185454891],
					[59.471955415130218, 2.0671860224160388],
					[59.471955414332179, 2.0671860243513094],
					[59.471719215096478, 2.067772124748902],
					[59.471480463949611, 2.0683581040573822],
					[59.471077794238028, 2.0683692000752791],
					[59.470674994020378, 2.0683804002641],
					[59.470674990361452, 2.0683803992461387],
					[59.470674982338899, 2.0683804008971114],
					[59.470674977881927, 2.0683804018143159],
					[59.470244378158043, 2.0684276012539207],
					[59.469816677555421, 2.0684748010676581],
					[59.469386077924163, 2.0685220008320151],
					[59.469386031146946, 2.0685220193119966],
					[59.469385951285496, 2.0685220764755621],
					[59.469385919186031, 2.068522116727352],
					[59.469105418770553, 2.06912771523892],
					[59.468822019301669, 2.0697360156786071],
					[59.468538718536621, 2.0703416146017575],
					[59.468538716142376, 2.0703416204067548],
					[59.468538710555833, 2.0703416339517249],
					[59.468538709052972, 2.0703416395733516],
					[59.468355408723362, 2.0709277401142629],
					[59.468172009081108, 2.0715110404604435],
					[59.467988709253923, 2.0720971396644048],
					[59.467805417419456, 2.0726832144657354],
					[59.46708593193074, 2.073785990811623],
					[59.467085922964721, 2.0737860085916577],
					[59.467085908598307, 2.0737860434186652],
					[59.467085903291185, 2.0737860622171636],
					[59.466869205597455, 2.0749471522567897],
					[59.466594220823985, 2.0758360015233759],
					[59.465610948046354, 2.0769859696474837],
					[59.465610941381193, 2.0769859798703867],
					[59.465610927159446, 2.0769860004993728],
					[59.465610921385725, 2.0769860105390929],
					[59.464994220665872, 2.078213810349002],
					[59.464994219069446, 2.0782138142182309],
					[59.464994215078427, 2.0782138238912671],
					[59.464994212590604, 2.078213827943634],
					[59.464633112908167, 2.0791555285019658],
					[59.464633109808489, 2.0791555379916891],
					[59.464633104593737, 2.0791555585394708],
					[59.464633102478679, 2.0791555695975301],
					[59.464310901668519, 2.0812305687897443],
					[59.464310901948032, 2.0812305740440671],
					[59.464310900631034, 2.0812305831674038],
					[59.464310900019122, 2.0812305886048028],
					[59.464222000020271, 2.0827055871802069],
					[59.464222001138047, 2.0827056081975508],
					[59.464222006939323, 2.0827056495001837],
					[59.464222011622795, 2.0827056697854593],
					[59.464441512383686, 2.0833056691342429],
					[59.464660912156539, 2.083908368399614],
					[59.464877612333922, 2.0845083677298675],
					[59.464877612427053, 2.0845083694813686],
					[59.465097012141584, 2.0851083691348533],
					[59.465097018235092, 2.0851083820490808],
					[59.465097032204952, 2.0851084075116399],
					[59.465097039988187, 2.0851084183084696],
					[59.465405439774472, 2.0855223187370839],
					[59.465713738832278, 2.0859362187280044],
					[59.46601923918945, 2.0863529171339108],
					[59.466019287964407, 2.0863529531617111],
					[59.466019399884253, 2.0863529903965459],
					[59.466019461153152, 2.0863529902179097],
					[59.46638336071522, 2.0862362902495382],
					[59.466383361606638, 2.0862362900666005],
					[59.466744461363184, 2.0861195917099424],
					[59.467105660120936, 2.0860057901490379],
					[59.467105661012354, 2.08600578996609],
					[59.467105661903787, 2.0860057897831381],
					[59.467466761537978, 2.0858890894319342],
					[59.4674667948536, 2.0858890719698326],
					[59.467466851825691, 2.0858890248621842],
					[59.467466876280454, 2.0858889932820812],
					[59.467733476096349, 2.0853889943432491],
					[59.467733476987767, 2.0853889941602821],
					[59.467997377173504, 2.0848917934558528],
					[59.468264070670607, 2.084391804705104],
					[59.46858346529956, 2.083925112331483],
					[59.46858346529956, 2.083925112331483],
					[59.468900164657818, 2.0834584133266194],
					[59.469219550822039, 2.0829918333751825],
					[59.469622331741533, 2.0826390499869487],
					[59.469622332632966, 2.0826390498038885],
					[59.469622332632966, 2.0826390498038885],
					[59.470022332628261, 2.0822834497704066],
					[59.470422332129118, 2.0819307504891067],
					[59.470422333020529, 2.0819307503060247],
					[59.470422332927377, 2.0819307485542646],
					[59.470822305881164, 2.0815751734301431],
					[59.471183373332018, 2.0814334862948995],
					[59.471544473326283, 2.0812918865372829],
					[59.471908373249747, 2.0811501864480029],
					[59.472269465294083, 2.0810084895390495],
					[59.472661157471926, 2.0808918925329771],
					[59.473055655779142, 2.0807751923924278],
					[59.473450040118237, 2.0806612966902982],
					[59.473825024186759, 2.0806140995100395],
					[59.474200023741858, 2.0805695987234998],
					[59.474575024205471, 2.080525199184212],
					[59.474575046397703, 2.0805251928535049],
					[59.474575089704381, 2.0805251768712898],
					[59.4745751099274, 2.0805251674029215],
					[59.474911210342761, 2.0803029667831354],
					[59.475244510149459, 2.0800834675913538],
					[59.475577909624739, 2.0798640672616537],
					[59.475914010293614, 2.0796418666498342],
					[59.4759140326316, 2.079641846118724],
					[59.475914070694863, 2.0796417993306382],
					[59.475914085528721, 2.0796417732568484],
					[59.476166886103421, 2.0790028739376765],
					[59.476166886010191, 2.0790028721856344],
					[59.47641958614544, 2.0783611724084383],
					[59.476672385657942, 2.0777222741912937],
					[59.476927986244235, 2.0770806733061651],
					[59.476927986244235, 2.0770806733061651],
					[59.477180774964651, 2.0764390025641326],
					[59.477530755193037, 2.0760084253751678],
					[59.477883455103729, 2.0755751255974566],
					[59.478233455314367, 2.0751445253853413],
					[59.478586254884263, 2.0747112268039301],
					[59.478939055384757, 2.0742807260783644],
					[59.478939065428669, 2.0742807116129711],
					[59.478939083453895, 2.0742806777923657],
					[59.478939090637063, 2.0742806603726773],
					[59.47915849074009, 2.0735778596119108],
					[59.47915849074009, 2.0735778596119108],
					[59.479377990654051, 2.0728722598430358],
					[59.479600190975255, 2.0721694602806537],
					[59.479819591337375, 2.0714667593068135],
					[59.480041891064197, 2.0707639602568171],
					[59.480041891768948, 2.0707639565689209],
					[59.480041893976527, 2.070763947257475],
					[59.480041894681264, 2.070763943569597],
					[59.480205794999321, 2.0700389443513592],
					[59.480372394524295, 2.0693111445052019],
					[59.480372395415714, 2.0693111443217127],
					[59.480536295263562, 2.0685861439383331],
					[59.480700195341647, 2.0678583439979632],
					[59.480866894805288, 2.0671333452085863],
					[59.480866894711916, 2.0671333434563417],
					[59.48103079515414, 2.0664056431192463],
					[59.48103079515414, 2.0664056431192463],
					[59.48103079595213, 2.0664056411834264],
					[59.481186295868007, 2.0656833426933812],
					[59.481341895448196, 2.0649611421686518],
					[59.481500194855812, 2.0642389432948147],
					[59.481500194762404, 2.0642389415425462],
					[59.481500195653787, 2.0642389413589153],
					[59.481655795841327, 2.0635167420217866],
					[59.481811289271192, 2.0627944705907644],
					[59.482111275261758, 2.0622583978271312],
					[59.482411274164861, 2.0617222981630734],
					[59.482714074117006, 2.0611833984093568],
					[59.482714079702482, 2.0611833848570722],
					[59.482714090966873, 2.0611833595048048],
					[59.48271409467614, 2.0611833445676351],
					[59.482852995350449, 2.0605833457690585],
					[59.482991895309475, 2.059980645361815],
					[59.483130795455963, 2.059377845615749],
					[59.483269594719772, 2.0587750453195337],
					[59.483408495012618, 2.0581722453630342],
					[59.483547393172032, 2.0575694546135272],
					[59.483730788626097, 2.0570361653959446],
					[59.483914066092083, 2.0565029315263654],
					[59.484325105979941, 2.056244570531454],
					[59.48473620629359, 2.0559890693339127],
					[59.484736207184959, 2.0559890691500402],
					[59.485144506574791, 2.0557307692564217],
					[59.485144522949611, 2.0557307552481308],
					[59.485144552133782, 2.0557307279670685],
					[59.485144564849598, 2.0557307129418696],
					[59.485439064574607, 2.0553001131645221],
					[59.485439065372439, 2.0553001112281875],
					[59.485439066357344, 2.0553001127967354],
					[59.485730765810779, 2.0548695123371372],
					[59.485730769893436, 2.0548695044077765],
					[59.485730778950128, 2.0548694883651457],
					[59.485730783032807, 2.0548694804357983],
					[59.485941883357647, 2.0543861803903662],
					[59.486152982893529, 2.0539056805910483],
					[59.486152989088886, 2.0539056615948237],
					[59.486152997116335, 2.0539056262745201],
					[59.486152999652703, 2.0539056062615657],
					[59.486175200020263, 2.0532722076811716],
					[59.486197399979893, 2.0526361077298763],
					[59.486197398950601, 2.0526360884526902],
					[59.486197394405053, 2.0526360539551844],
					[59.486197389810322, 2.0526360354138733],
					[59.485977989819922, 2.0519832352407241],
					[59.485758489825365, 2.051327735182805],
					[59.485539089968128, 2.0506749367995725],
					[59.485319590092814, 2.0500193375804154],
					[59.485319589014253, 2.0500193342596056],
					[59.485319586950695, 2.050019329370433],
					[59.48531958596574, 2.0500193278020475],
					[59.485116886677339, 2.0494971286564541],
					[59.484916886716391, 2.0489749272943714],
					[59.484716894717295, 2.0484527496300711],
					[59.484566897824685, 2.0473805714284308],
					[59.484566895667406, 2.0473805647871504],
					[59.484566892948273, 2.0473805476316684],
					[59.484566889712362, 2.0473805376697554],
					[59.484339090783358, 2.0466805395393952],
					[59.484102991098865, 2.0458971425921093],
					[59.484102991005201, 2.0458971408397755],
					[59.484102990911545, 2.0458971390874403],
					[59.483902999675443, 2.0452693682120584],
					[59.483889099683161, 2.0442306038366658],
					[59.483925200168287, 2.0433556076587278],
					[59.483925199606112, 2.0433555971448141],
					[59.483925198388064, 2.0433555743646759],
					[59.48392519693455, 2.0433555640349614],
					[59.483797398315787, 2.0426471696322865],
					[59.483589101942357, 2.0410917005320175],
					[59.483702998400553, 2.0403222299538473],
					[59.483819597886537, 2.0395528306550661],
					[59.483819597792781, 2.0395528289027713],
					[59.483933493551419, 2.0387833573058169],
					[59.484177981268296, 2.0382556835272991],
					[59.484177985443694, 2.0382556773486837],
					[59.484177990041587, 2.0382556622241856],
					[59.484177993231874, 2.0382556544775956],
					[59.484469591115221, 2.0372167576977804],
					[59.484758489730844, 2.0363417630926364],
					[59.484758492733427, 2.0363417518410714],
					[59.484758497753482, 2.0363417277699924],
					[59.484758498879621, 2.0363417151348675],
					[59.484844599203356, 2.0351833153166399],
					[59.484844600329374, 2.0351833026814505],
					[59.484844597937162, 2.0351832748284906],
					[59.484844596389202, 2.0351832627465454],
					[59.484591895718459, 2.0338749626678907],
					[59.484591890135945, 2.0338749425623348],
					[59.484591874608121, 2.0338749050258014],
					[59.484591864662811, 2.033874887594822],
					[59.48408346676004, 2.033133189426708],
					[59.483669568662165, 2.0324804939421659],
					[59.483669559889535, 2.0324804815843929],
					[59.483669539670252, 2.0324804574222681],
					[59.483669529208797, 2.0324804471857023],
					[59.483005729450703, 2.0319220473244899],
					[59.483005685071745, 2.0319220263922131],
					[59.483005592983389, 2.0319220064768566],
					[59.483005544382671, 2.0319220076782698],
					[59.482472144001449, 2.032077608107286],
					[59.482472103751292, 2.0320776306111599],
					[59.482472039435777, 2.0320776917568733],
					[59.482472012602607, 2.0320777291999939],
					[59.482133112640959, 2.0329666297377171],
					[59.482133111045961, 2.0329666336110055],
					[59.482133108043662, 2.0329666448619577],
					[59.482133105651194, 2.032966650671896],
					[59.481899805828185, 2.0338916515485956],
					[59.481899805124506, 2.033891655237392],
					[59.481899803717127, 2.0338916626149603],
					[59.48189980301342, 2.0338916663037629],
					[59.481788703090807, 2.0345388657709096],
					[59.481788702387078, 2.0345388694596682],
					[59.481788700979685, 2.0345388768372001],
					[59.481788701167311, 2.0345388803415467],
					[59.481733101347977, 2.0351055796299473],
					[59.481733101207077, 2.0351055938317355],
					[59.481733101722796, 2.0351056202987583],
					[59.481733103364547, 2.0351056341317495],
					[59.481852599985338, 2.0357889164131762],
					[59.481844199752857, 2.0363443877169707],
					[59.481752601077389, 2.0371499772293635],
					[59.481752601077389, 2.0371499772293635],
					[59.481663700833657, 2.0379555776209264],
					[59.481572001120249, 2.0387610769306881],
					[59.481480407946229, 2.0395666199822107],
					[59.481160944362045, 2.0399637747819415],
					[59.48084154505824, 2.0403581731238352],
					[59.480841544166893, 2.0403581733080887],
					[59.480841544260635, 2.0403581750602506],
					[59.480522082991932, 2.0407554251403845],
					[59.480130542929494, 2.0408720085690351],
					[59.479736043246653, 2.040988708189726],
					[59.479344342650691, 2.0411054072423975],
					[59.479344305964112, 2.0411054289963388],
					[59.479344243943061, 2.0411054825580774],
					[59.479344217811004, 2.0411055163021641],
					[59.479130417541597, 2.0415693157766497],
					[59.479130417635311, 2.04156931752871],
					[59.478919217581428, 2.0420332164695485],
					[59.478708162326704, 2.0424970191085685],
					[59.478305569026119, 2.0425609026357403],
					[59.477902769338279, 2.0426248021300033],
					[59.477902731385257, 2.0426248170574173],
					[59.47790266425087, 2.0426248592707656],
					[59.477902635069533, 2.0426248865566734],
					[59.477655443683183, 2.0429831740881839],
					[59.477283242318833, 2.0433830688093986],
					[59.476905736814871, 2.0432526355303593],
					[59.476652979992856, 2.0427999114448521],
					[59.47646129214521, 2.0423499417265507],
					[59.476333495909493, 2.0416832614309364],
					[59.476333495909493, 2.0416832614309364],
					[59.476333495722074, 2.0416832579271031],
					[59.476333494737027, 2.0416832563593719],
					[59.476152994975948, 2.0408610573030637],
					[59.476152992724678, 2.040861048911935],
					[59.476152988222069, 2.0408610321296914],
					[59.476152984985738, 2.0408610221708625],
					[59.475894585394997, 2.0402388243979579],
					[59.475583485085167, 2.0394693242460162],
					[59.475583478002157, 2.0394693097688403],
					[59.475583460270741, 2.0394692815514324],
					[59.475583449622363, 2.0394692678111954],
					[59.474569559061401, 2.0383248784837114],
					[59.471516867327658, 2.0336692900662086],
					[59.471516831299482, 2.0336692567842118],
					[59.471516746905102, 2.0336692122536717],
					[59.471516699336433, 2.0336691990691467],
					[59.47098892214575, 2.0336719999068511],
					[59.470566745394144, 2.0335748046712148],
					[59.470566702141291, 2.0335748047623934],
					[59.470566619247712, 2.0335748219075835],
					[59.470566579700794, 2.0335748407131864],
					[59.47022767994838, 2.0338304402597172],
					[59.470227679057032, 2.0338304404440692],
					[59.470227679057032, 2.0338304404440692],
					[59.46988879767509, 2.0340887278920099],
					[59.469549918918176, 2.0342387163131672],
					[59.469211019099141, 2.0343887164231771],
					[59.468872119234689, 2.0345387167917006],
					[59.468872088788487, 2.034538737254918],
					[59.468872039154888, 2.0345387864785658],
					[59.468872018184818, 2.034538815607644],
					[59.468608118720901, 2.035111016175271],
					[59.468341519243012, 2.0356805141746488],
					[59.468341519336803, 2.0356805159261468],
					[59.468077618726539, 2.0362499157309735],
					[59.468077617928984, 2.0362499176667259],
					[59.468077616333872, 2.0362499215382015],
					[59.468077614644969, 2.0362499236582048],
					[59.467797014948829, 2.0369277237947889],
					[59.467513714772139, 2.0376055229345233],
					[59.467233122873189, 2.0382805049011519],
					[59.466941534366015, 2.0387109872044737],
					[59.466649833857744, 2.0391415875111565],
					[59.466649823301438, 2.0391416091710082],
					[59.466649806832919, 2.0391416550726364],
					[59.466649800920763, 2.0391416793143984],
					[59.466572000898111, 2.0398832783389107],
					[59.466572000991832, 2.0398832800903155],
					[59.466497001366577, 2.0406249800799121],
					[59.466497000662656, 2.0406249837668784],
					[59.466497000052456, 2.0406249892052317],
					[59.466497000239883, 2.0406249927080555],
					[59.466469200306392, 2.041469393085849],
					[59.466438699592878, 2.0423166936219892],
					[59.466408101300267, 2.0431610761050201],
					[59.466252604215804, 2.043905558403023],
					[59.466097003984693, 2.0446471593203599],
					[59.465941504291891, 2.0453916590047458],
					[59.465785904114497, 2.0461332590781485],
					[59.465785903410428, 2.0461332627649149],
					[59.465785902095931, 2.0461332718898499],
					[59.465785900594149, 2.0461332775120082],
					[59.46568590105408, 2.0469832752105872],
					[59.465583105941988, 2.0478305398483725],
					[59.465319222756399, 2.0483305068623796],
					[59.465052624322063, 2.0488305052770834],
					[59.465052622539346, 2.0488305056449105],
					[59.465052622632946, 2.0488305073962709],
					[59.464788722594321, 2.0493305073621122],
					[59.464788717103588, 2.0493305226602048],
					[59.464788706028486, 2.0493305515050677],
					[59.464788703305437, 2.0493305680028069],
					[59.464685902737841, 2.0499666688917491],
					[59.464583102743681, 2.0506027688682193],
					[59.464480403717012, 2.0512388578495],
					[59.464272007205103, 2.051983246682147],
					[59.464060908170637, 2.0527277455472892],
					[59.464060908170637, 2.0527277455472892],
					[59.464060907279269, 2.052727745731096],
					[59.463852614737064, 2.0534721198221457],
					[59.463560928576392, 2.0539554956660151],
					[59.463266533858572, 2.0544387889654621],
					[59.463027639205947, 2.0547637812063568],
					[59.463027625972764, 2.0547638034103355],
					[59.46302760780933, 2.0547638514184396],
					[59.463027601002779, 2.0547638758387672],
					[59.462749800882655, 2.0570943751679605],
					[59.462749800645703, 2.0570943876105758],
					[59.462749800969704, 2.057094410560814],
				],
			],
		},
		{
			fldNpdidField: "43699",
			wlbNpdidWellbore: "425",
			fldName: "GULLFAKS SØR",
			fldCurrentActivitySatus: "Producing",
			wlbName: "34/10-2",
			fldDiscoveryYear: "1978",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43699",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43699",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "050",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=425",
			fldHcType: "OIL/GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "21188",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[61.165388707642357, 2.0278193637796664],
					[61.165388709073945, 2.0278194436964645],
					[61.165388743571313, 2.0278195181458214],
					[61.165388768744307, 2.0278195517470929],
					[61.168335926634576, 2.0303890132227305],
					[61.169666510065781, 2.0344750618306153],
					[61.169666513215986, 2.0344750704632806],
					[61.169666519325574, 2.0344750840385792],
					[61.16966652336685, 2.0344750924737749],
					[61.171005400077064, 2.0370306486385532],
					[61.170997000107171, 2.0406000006943739],
					[61.170997008346077, 2.0406000380607239],
					[61.170997039177067, 2.0406001114815253],
					[61.170997061578497, 2.0406001438457348],
					[61.171530400095527, 2.0411111841015885],
					[61.171527599680005, 2.044677799244615],
					[61.171527602795571, 2.0446778246825659],
					[61.171527614182949, 2.0446778706849065],
					[61.171527623441136, 2.0446778928972598],
					[61.172863722926586, 2.0472361923375719],
					[61.172863742272312, 2.0472362179188566],
					[61.172863786916103, 2.0472362621656752],
					[61.172863813105238, 2.0472362806340612],
					[61.17500551304019, 2.0482695809041345],
					[61.175005554092806, 2.0482695904871879],
					[61.175005638014646, 2.0482695924534484],
					[61.175005679006446, 2.0482695833855264],
					[61.178494479288531, 2.0467640843290358],
					[61.178494504559808, 2.0467640675367496],
					[61.178494550265839, 2.0467640275558479],
					[61.178494569809537, 2.0467640045644386],
					[61.180375169743435, 2.0437223052625044],
					[61.180375176300807, 2.0437222926100151],
					[61.180375186837452, 2.0437222697426214],
					[61.180375192408462, 2.0437222554417556],
					[61.181719592151232, 2.0391472567697613],
					[61.181719595048385, 2.0391472430598148],
					[61.181719598965188, 2.0391472141890463],
					[61.181719600080136, 2.0391472008739826],
					[61.181733499667359, 2.0335444003617011],
					[61.181733500367663, 2.0335443964726667],
					[61.181733499986073, 2.0335443890897804],
					[61.181733499890669, 2.0335443872440662],
					[61.181469599672944, 2.0294666876714413],
					[61.181469589648586, 2.029466650691389],
					[61.181469560179714, 2.0294665862888754],
					[61.181469538762116, 2.0294665555704663],
					[61.180400138964835, 2.0284387566244479],
					[61.180400119201849, 2.0284387404749866],
					[61.180400075602336, 2.0284387165471944],
					[61.180400054343558, 2.0284387063300708],
					[61.178525053599806, 2.0279165077431012],
					[61.178525040838188, 2.0279165049748031],
					[61.178525015410379, 2.0279165012837406],
					[61.178525001853139, 2.0279165005587036],
					[61.176108338173471, 2.0278969997314764],
					[61.173430671930788, 2.0268609131305673],
					[61.173430666393287, 2.0268609106264823],
					[61.173430653631669, 2.0268609078589246],
					[61.173430648094161, 2.0268609053548379],
					[61.171288999750843, 2.0263331184009212],
					[61.169680738178222, 2.0247915566581138],
					[61.169680681721061, 2.0247915281311353],
					[61.169680562888125, 2.024791513446504],
					[61.169680502199029, 2.0247915250483737],
					[61.166999901980091, 2.0263026255834626],
					[61.166999892369134, 2.0263026314476309],
					[61.166999872447064, 2.0263026470633307],
					[61.166999862931569, 2.0263026547723477],
					[61.165388763272304, 2.0278192544143905],
					[61.165388738862681, 2.0278192878167252],
					[61.165388707642357, 2.0278193637796664],
				],
				[
					[61.121799800348711, 2.0873249897768522],
					[61.121799801389443, 2.0873250100436329],
					[61.121799808116165, 2.087325053284073],
					[61.121799813707568, 2.0873250744152934],
					[61.123752614318093, 2.092263973406062],
					[61.123752644309469, 2.0922640134105714],
					[61.123752718686511, 2.0922640753559794],
					[61.123752764854629, 2.0922640969059558],
					[61.127011065227734, 2.0928501976804057],
					[61.127011074234439, 2.092850197568346],
					[61.127011093328171, 2.0928502008342176],
					[61.127011102334869, 2.0928502007221437],
					[61.129291702443666, 2.092825200576272],
					[61.129291758306657, 2.0928251827309365],
					[61.129291849709837, 2.0928251198166716],
					[61.12929188604673, 2.0928250727093265],
					[61.130269586192789, 2.0903361738798387],
					[61.130269586894848, 2.0903361699983836],
					[61.130269590175949, 2.0903361636872373],
					[61.130269590877987, 2.0903361598057888],
					[61.131244590944867, 2.0872222607751025],
					[61.131244595156659, 2.0872222374852347],
					[61.131244598138331, 2.0872221902365724],
					[61.131244597002848, 2.0872221681207699],
					[61.129939096643163, 2.0791721676954493],
					[61.129939062837671, 2.0791721061693611],
					[61.12993896306655, 2.0791720237328328],
					[61.129938897897389, 2.0791720007836632],
					[61.127663938111098, 2.0791970001225213],
					[61.12472787577903, 2.0779915148087547],
					[61.124727814230752, 2.0779915097031378],
					[61.124727698565657, 2.077991538853126],
					[61.124727646231221, 2.0779915727169467],
					[61.122124845637195, 2.0811220714460519],
					[61.122124829935515, 2.0811220991173562],
					[61.122124806837107, 2.0811221582252464],
					[61.122124800331513, 2.0811221894660785],
					[61.121799800348711, 2.0873249897768522],
				],
				[
					[61.108260908018558, 2.1009306220843542],
					[61.108260914321555, 2.100930674713215],
					[61.109249814253523, 2.1034111736495849],
					[61.109249819370397, 2.1034111855667303],
					[61.109249830306609, 2.1034112055224563],
					[61.109249837111584, 2.1034112152077706],
					[61.110558137351852, 2.1052612144259886],
					[61.110558137446212, 2.1052612162678339],
					[61.110558139323061, 2.105261217719677],
					[61.110558140308662, 2.1052612193665219],
					[61.112849839768998, 2.1083418191925283],
					[61.115141539699636, 2.1114223189893044],
					[61.115141591169802, 2.1114223561673984],
					[61.115141705593345, 2.1114223907577965],
					[61.115141769437983, 2.1114223879752374],
					[61.118713969323288, 2.110108488749165],
					[61.118713999059985, 2.1101084710674693],
					[61.118714050837632, 2.1101084262094534],
					[61.118714072972843, 2.1101084008754811],
					[61.121291846809967, 2.105691844467843],
					[61.124538957361722, 2.1037612985951966],
					[61.127138904149682, 2.1037112995311986],
					[61.127138912265224, 2.1037112996174137],
					[61.127138929293139, 2.1037112977518269],
					[61.127138938205498, 2.1037112958000428],
					[61.130713938371002, 2.1030251956285149],
					[61.130713982460769, 2.103025176652106],
					[61.130714055950016, 2.103025115825504],
					[61.130714084552707, 2.1030250760135387],
					[61.132000185117377, 2.0998806757784445],
					[61.132000191290579, 2.0998806203759539],
					[61.132000173335229, 2.0998805162120182],
					[61.132000150097888, 2.0998804672552636],
					[61.130364050310057, 2.0980359670641127],
					[61.130364029569392, 2.0980359492464133],
					[61.130363987574761, 2.0980359211783148],
					[61.130363965429545, 2.0980359111232496],
					[61.125150065357076, 2.0962581112563146],
					[61.125150061697695, 2.0962581101951248],
					[61.125150056161381, 2.0962581076820337],
					[61.125150053393192, 2.0962581064254944],
					[61.120913953338821, 2.0950859075535364],
					[61.120913914354723, 2.0950859030576177],
					[61.120913838317371, 2.0950859141369236],
					[61.120913801075069, 2.0950859260273518],
					[61.116374936135088, 2.0976637065700792],
					[61.110199975593304, 2.098397001816307],
					[61.110199939241788, 2.0983970135027907],
					[61.110199870953778, 2.0983970526711753],
					[61.11019984070527, 2.0983970779209158],
					[61.108260941231819, 2.1009304788605601],
					[61.108260922013734, 2.1009305259021653],
					[61.108260908018558, 2.1009306220843542],
				],
				[
					[61.107763711157126, 2.025413964800618],
					[61.108635892074901, 2.0279833091796329],
					[61.108424806117938, 2.0288527531468832],
					[61.108424802997988, 2.0288527799125311],
					[61.108424804968813, 2.0288528353524908],
					[61.108424809964156, 2.0288528621853685],
					[61.11125541028813, 2.0374167629070126],
					[61.111255419356276, 2.0374167813926451],
					[61.111255441152288, 2.0374168194178806],
					[61.111255453689587, 2.0374168352742106],
					[61.115560954168863, 2.0420362358020032],
					[61.115560965528914, 2.0420362463333954],
					[61.115560990922347, 2.0420362668057401],
					[61.115561004955744, 2.0420362767466727],
					[61.118572104632328, 2.0436695764511921],
					[61.118572108292028, 2.0436695775060691],
					[61.118572115706648, 2.0436695814578814],
					[61.118572119366348, 2.0436695825127562],
					[61.122219318741216, 2.0452807827355755],
					[61.122219373255902, 2.0452807911937625],
					[61.122219477007413, 2.0452807757359848],
					[61.122219527230563, 2.0452807534655228],
					[61.127125126689677, 2.0412223539534571],
					[61.127125130158902, 2.0412223513231438],
					[61.127125133628127, 2.0412223486928149],
					[61.127125136206246, 2.0412223462594365],
					[61.132452935996163, 2.0362834469161322],
					[61.132452939369998, 2.0362834424419427],
					[61.132452946308206, 2.0362834371792591],
					[61.132452948790913, 2.036283432902191],
					[61.135650148822798, 2.0327084325008222],
					[61.135650153182958, 2.0327084296715596],
					[61.135650159039194, 2.0327084209187114],
					[61.135650162412851, 2.0327084164436786],
					[61.136925162239756, 2.030933416369991],
					[61.136925181432048, 2.0309333692434177],
					[61.13692519082452, 2.0309332720886819],
					[61.136925183697976, 2.0309332214685791],
					[61.134758484070346, 2.0258055216949864],
					[61.134758441012046, 2.0258054734481412],
					[61.134758334241056, 2.025805413220453],
					[61.134758269637274, 2.025805401437045],
					[61.131119369983416, 2.0263609028800622],
					[61.131119359290338, 2.0263609052487328],
					[61.131119337904174, 2.0263609099860744],
					[61.131119328102187, 2.026360912157358],
					[61.126202769229018, 2.0282580975362494],
					[61.121916746182997, 2.0279692030060574],
					[61.11611947720467, 2.0255665164179657],
					[61.111397277912957, 2.0235609160493415],
					[61.111397236063688, 2.0235609085512714],
					[61.111397150551127, 2.0235609107212973],
					[61.111397108765473, 2.0235609218363373],
					[61.107763809403657, 2.0254137214181491],
					[61.107763761602264, 2.0254137729730846],
					[61.107763712876832, 2.0254138936449979],
					[61.107763711157126, 2.025413964800618],
				],
				[
					[61.106413700299129, 2.0094082982434305],
					[61.10641372827984, 2.0094083683970609],
					[61.106413826372673, 2.0094084695589669],
					[61.106413894702733, 2.0094085009625635],
					[61.10677767626504, 2.0094168969739865],
					[61.109652622192741, 2.0150972897179606],
					[61.109652629194912, 2.0150973030667938],
					[61.109652645681336, 2.0150973254889806],
					[61.109652655261129, 2.0150973364037776],
					[61.115074842397362, 2.020833423562495],
					[61.118316531605529, 2.0258168093881923],
					[61.118316550668155, 2.0258168293880554],
					[61.118316592262332, 2.0258168667566143],
					[61.118316614602982, 2.0258168804412975],
					[61.123027714625358, 2.0280279813275683],
					[61.123027756569911, 2.0280279906755712],
					[61.123027841287026, 2.0280279905543162],
					[61.123027883168447, 2.0280279812823259],
					[61.127400082788512, 2.0260223824609458],
					[61.127400109744137, 2.0260223634448824],
					[61.127400157035957, 2.0260223194242499],
					[61.127400177276691, 2.026022292577168],
					[61.12960012718063, 2.0218612876534778],
					[61.135055620559044, 2.0212834990575121],
					[61.135055667404188, 2.0212834812148248],
					[61.135055744099787, 2.0212834306560938],
					[61.135055777323529, 2.0212833934639396],
					[61.137991845139517, 2.0157362521814419],
					[61.139447284019937, 2.0150668818612498],
					[61.139447341996522, 2.0150667832307287],
					[61.139447327788695, 2.015066596215362],
					[61.13944725738645, 2.0150665074349212],
					[61.129291756835208, 2.0120304078997546],
					[61.129291744073413, 2.012030405140909],
					[61.129291717754356, 2.0120304016635902],
					[61.129291704101483, 2.012030399102541],
					[61.123116753648624, 2.0118915015265602],
					[61.116597297634023, 2.0082359255652742],
					[61.116597274592124, 2.0082359157752383],
					[61.116597228890988, 2.0082359035624586],
					[61.116597205245078, 2.0082358994956606],
					[61.106422204862554, 2.0080053994867932],
					[61.10642213335656, 2.008005428389485],
					[61.106422030352356, 2.0080055276044471],
					[61.106421999840897, 2.0080055995602502],
					[61.106413700299129, 2.0094082982434305],
				],
				[
					[61.09641371194143, 2.0884582561349805],
					[61.096413716251782, 2.0884583575832796],
					[61.096413771077565, 2.0884584423731223],
					[61.096413809887046, 2.0884584785464533],
					[61.100952703167984, 2.0907390750864319],
					[61.101708160081188, 2.091202948080253],
					[61.103747036096138, 2.0941473134616366],
					[61.103747048627049, 2.0941473293373778],
					[61.103747078941709, 2.0941473580758916],
					[61.103747095834244, 2.0941473711339031],
					[61.105263796405914, 2.0950751709195869],
					[61.105263854767124, 2.0950751842048976],
					[61.105263970679935, 2.0950751774332423],
					[61.105264028042505, 2.095075153693267],
					[61.107247327865629, 2.093425154010832],
					[61.107247358153757, 2.0934251119891161],
					[61.107247392128507, 2.0934250188767423],
					[61.10724739670632, 2.0934249675907703],
					[61.106433498884286, 2.0883666773248524],
					[61.106128004419787, 2.0837889471518207],
					[61.107352973300117, 2.0816722997357084],
					[61.107352956262417, 2.0816721786980739],
					[61.107352815938867, 2.081672043752838],
					[61.107352692747696, 2.0816720316868076],
					[61.101138794597162, 2.0856220292189582],
					[61.096413797359794, 2.0884581294323765],
					[61.096413761442072, 2.0884581670929183],
					[61.09641371194143, 2.0884582561349805],
				],
				[
					[61.080727605430532, 1.983191690612748],
					[61.080727617769355, 1.9831917716127418],
					[61.080727633246418, 1.9831918091118879],
					[61.081780432872925, 1.9847890103272106],
					[61.08178043771187, 1.9847890166952606],
					[61.081780448280853, 1.9847890292331734],
					[61.081780454106855, 1.9847890372427555],
					[61.082108140057159, 1.985139021871279],
					[61.082724830126104, 1.9861445040791497],
					[61.082724832100062, 1.9861445073624235],
					[61.08272483703491, 1.9861445155706297],
					[61.082724839995841, 1.9861445204955523],
					[61.08391094043391, 1.987736220547986],
					[61.083910965135445, 1.9877362448350719],
					[61.083911023764159, 1.9877362801911937],
					[61.083911056101179, 1.9877362953360855],
					[61.084380534683071, 1.9878418904306951],
					[61.085347029428938, 1.9882945410013766],
					[61.08597480622219, 1.9908083472218268],
					[61.085974831182931, 1.9908083937905288],
					[61.085974898857103, 1.9908084643697492],
					[61.085974941666393, 1.9908084902202283],
					[61.086333186182308, 1.9909168742295502],
					[61.088272034800767, 1.9932084149644207],
					[61.088599825447027, 1.993800097667382],
					[61.088599841936585, 1.9938001200639908],
					[61.088599881852254, 1.9938001595932808],
					[61.088599905278379, 1.9938001767259461],
					[61.089130505161314, 1.9940862765106533],
					[61.089130514359226, 1.9940862800515935],
					[61.08913053097293, 1.9940862875294163],
					[61.089130540170849, 1.9940862910703729],
					[61.08983323962503, 1.9943057911120625],
					[61.089833243285007, 1.9943057921604552],
					[61.089833248727103, 1.9943057928129231],
					[61.089833250605004, 1.9943057942572249],
					[61.090686050973652, 1.9945195945297425],
					[61.090686127794349, 1.9945195811860654],
					[61.090686250143023, 1.9945195074656974],
					[61.090686294588259, 1.9945194436064608],
					[61.091052995294405, 1.9928806449457863],
					[61.091555793988945, 1.9908111498258099],
					[61.092441892909719, 1.9874028525775487],
					[61.093269593100047, 1.9844194523661667],
					[61.093269595347408, 1.9844194090463187],
					[61.093269579156782, 1.9844193232858829],
					[61.093269561705817, 1.9844192824874083],
					[61.092972362038751, 1.984008182520109],
					[61.092972352456677, 1.9840081716203259],
					[61.092972330619425, 1.9840081504156155],
					[61.09297231925531, 1.9840081399123919],
					[61.092114024787847, 1.9833692422433176],
					[61.091705730299985, 1.9830220472768396],
					[61.091705722883837, 1.983022043342312],
					[61.091705708846625, 1.9830220334346997],
					[61.091705702225497, 1.9830220274616093],
					[61.091319501974738, 1.9827915285710105],
					[61.091319441823806, 1.9827915158946896],
					[61.091319326019118, 1.982791524911943],
					[61.091319268679385, 1.982791548842356],
					[61.091174935762339, 1.9829164891593951],
					[61.09041944154631, 1.9828220033357848],
					[61.090061157938727, 1.9827137082000283],
					[61.090061111825037, 1.9827137054312058],
					[61.090061024313826, 1.9827137211825618],
					[61.090060981038235, 1.9827137382591229],
					[61.089722081195639, 1.982963739722408],
					[61.089722075150576, 1.9829637447906283],
					[61.0897220630604, 1.9829637549270966],
					[61.089722057015322, 1.98296375999532],
					[61.088499943183514, 1.9842108732494328],
					[61.087800059724401, 1.9839915092155376],
					[61.087800018065764, 1.9839915054536881],
					[61.087799935612423, 1.9839915144904343],
					[61.087799895804743, 1.9839915289308516],
					[61.087594391287361, 1.984116472841146],
					[61.087036214382543, 1.9838359332154478],
					[61.086711233749725, 1.9835442515618213],
					[61.086711201220311, 1.9835442327401123],
					[61.086711132186359, 1.9835442052878201],
					[61.086711094982789, 1.9835442005354336],
					[61.086475051379303, 1.9835497981638457],
					[61.086277900405769, 1.983435927519875],
					[61.086277890220643, 1.9834359223405875],
					[61.086277868959336, 1.9834359121802172],
					[61.086277857979191, 1.9834359090391165],
					[61.085919472796704, 1.9833276127619599],
					[61.085686187652364, 1.9832137194539641],
					[61.085686155698909, 1.9832137116727149],
					[61.085686090297926, 1.9832137020264384],
					[61.085686057138403, 1.9832137056812154],
					[61.085361056981476, 1.9832859048351834],
					[61.085361033116065, 1.9832859138671612],
					[61.085360989237365, 1.9832859366579172],
					[61.085360968333006, 1.983285950614937],
					[61.084933288324095, 1.983660845096586],
					[61.084050129564211, 1.9830248467967979],
					[61.083758461667337, 1.982733178210889],
					[61.083247375801967, 1.9817832054559117],
					[61.083247357336518, 1.9817831797862726],
					[61.08324731277056, 1.9817831375903465],
					[61.08324728657405, 1.9817831192243172],
					[61.0830167871176, 1.981672020369994],
					[61.083016765061217, 1.9816720122497493],
					[61.083016718563456, 1.9816720021233132],
					[61.083016695904135, 1.9816719997206045],
					[61.082869407830707, 1.9816748003972018],
					[61.081936118694735, 1.9815859001682772],
					[61.081936113348661, 1.9815859013577681],
					[61.08193609979147, 1.9815859006520897],
					[61.081936094349388, 1.9815859000018794],
					[61.08166939386534, 1.9815942004190787],
					[61.081669360897898, 1.9815942077541742],
					[61.081669299007174, 1.9815942308298093],
					[61.081669271070929, 1.9815942482117879],
					[61.081005471085717, 1.9821581480216999],
					[61.081005450565463, 1.9821581693359533],
					[61.081005419011191, 1.9821582210200837],
					[61.081005406894093, 1.9821582479089002],
					[61.080727606786731, 1.9831916475082965],
					[61.080727605430532, 1.983191690612748],
				],
				[
					[61.071777600804587, 1.9678583209812113],
					[61.071816500665626, 1.9682222205690614],
					[61.071872000847954, 1.9687611191679657],
					[61.07199700114856, 1.9700250208678738],
					[61.071997003796014, 1.9700250370226013],
					[61.071997013642061, 1.9700250701787014],
					[61.071997019949634, 1.9700250873785559],
					[61.072566519968326, 1.9712111879079377],
					[61.072566525891176, 1.9712111977518609],
					[61.072566538627846, 1.9712112172412521],
					[61.072566546428831, 1.9712112285273693],
					[61.07295544640548, 1.9716807282362441],
					[61.07295546439196, 1.9716807446961935],
					[61.072955501954553, 1.9716807735409165],
					[61.072955523312679, 1.9716807855287843],
					[61.073763785055689, 1.9720168688892641],
					[61.074324845970096, 1.9726029288760967],
					[61.075272026918505, 1.9738973032226543],
					[61.07566371870918, 1.9747278856676282],
					[61.075663730458331, 1.9747279035193708],
					[61.075663755642481, 1.9747279369867821],
					[61.0756637699685, 1.9747279524040511],
					[61.076277648387425, 1.9752529332621169],
					[61.077010929370829, 1.9763751022242559],
					[61.077469224988882, 1.9772028967383874],
					[61.077469226963032, 1.977202900020564],
					[61.077469230911333, 1.977202906584933],
					[61.077469232885491, 1.9772029098671071],
					[61.078285932849823, 1.9784473101423621],
					[61.078285999088934, 1.9784473530864293],
					[61.078286141954585, 1.978447378976127],
					[61.078286219376047, 1.9784473598839665],
					[61.079136219021926, 1.977814060909461],
					[61.079136231111747, 1.9778140507747166],
					[61.079136254208223, 1.9778140270245193],
					[61.079136264419937, 1.9778140154469217],
					[61.079569564007066, 1.9771973141780042],
					[61.079569565692999, 1.9771973119417563],
					[61.079569570654741, 1.9771973033934431],
					[61.079569573135586, 1.9771972991193016],
					[61.080811272832065, 1.975047299820984],
					[61.080811273626978, 1.9750472977829507],
					[61.080811276107745, 1.9750472935084697],
					[61.080811276902658, 1.9750472914704362],
					[61.081322377172299, 1.9740639925773995],
					[61.081322378762046, 1.9740639885012103],
					[61.081322382133784, 1.9740639840281275],
					[61.081322382832568, 1.9740639801503992],
					[61.081836283213022, 1.9729028808669165],
					[61.081836284706597, 1.9729028749509574],
					[61.081836288680904, 1.9729028647601883],
					[61.081836291065493, 1.9729028586457176],
					[61.082297386879809, 1.9713778724080926],
					[61.082822373440749, 1.9702751014124928],
					[61.083302953783026, 1.9695945292302544],
					[61.083797318314716, 1.9690973642782923],
					[61.084872287170789, 1.9685807791477206],
					[61.084872319271504, 1.9685807552397303],
					[61.084872370325776, 1.9685806973259492],
					[61.084872390074111, 1.9685806612816867],
					[61.085189090355307, 1.9676028616744401],
					[61.085189091053877, 1.9676028577960885],
					[61.08518909423298, 1.9676028496420885],
					[61.085189094931529, 1.9676028457637407],
					[61.085361294839522, 1.9668722469039808],
					[61.085361296140384, 1.9668722373073901],
					[61.085361298838357, 1.9668722199540531],
					[61.085361300139233, 1.9668722103574532],
					[61.085408500115726, 1.9659667103835918],
					[61.085408499923261, 1.9659667067039126],
					[61.085408499634568, 1.965966701184382],
					[61.08540850033306, 1.965966697305993],
					[61.085403000162842, 1.965363923128286],
					[61.085716884160881, 1.9641472858224989],
					[61.086464063049881, 1.9630973152869862],
					[61.086464076440883, 1.9630972266758566],
					[61.086464025105826, 1.9630970724681251],
					[61.086463959585124, 1.9630970089102096],
					[61.085941760339622, 1.962933109074229],
					[61.085941734019968, 1.9629331056402906],
					[61.085941680682275, 1.9629331026508936],
					[61.085941654651442, 1.9629331047365322],
					[61.084724954660061, 1.9632137051451397],
					[61.084724950205192, 1.9632137061390758],
					[61.08472494129542, 1.9632137081269518],
					[61.084724936045859, 1.9632137111594588],
					[61.083936101838773, 1.9634803887451802],
					[61.083172267752921, 1.9632054125286122],
					[61.083172227779855, 1.9632054065571025],
					[61.083172148700093, 1.9632054111713639],
					[61.083172109593427, 1.9632054217571731],
					[61.081888810417787, 1.963849820854199],
					[61.081888800014816, 1.9638498287580333],
					[61.081888779016388, 1.9638498408864646],
					[61.081888768613467, 1.9638498487903091],
					[61.081455493962096, 1.9642248270279623],
					[61.080963896414367, 1.9644219856502867],
					[61.079900068073144, 1.9640359113449695],
					[61.079900040766319, 1.9640359062696184],
					[61.07989998733234, 1.9640359014387385],
					[61.079899958628516, 1.9640359041189084],
					[61.078877794094765, 1.9642497968121397],
					[61.078213942314513, 1.9641526047419471],
					[61.077450055407752, 1.9639331085420402],
					[61.07744999246178, 1.9639331114153358],
					[61.077449879282845, 1.9639331534042583],
					[61.07744983003716, 1.9639331941605349],
					[61.076835974965839, 1.9649192222791352],
					[61.075824958659247, 1.9651331034861867],
					[61.075824953409601, 1.9651331065174271],
					[61.07582494093586, 1.9651331092986768],
					[61.075824934699, 1.9651331106893011],
					[61.075241657606846, 1.9653331023123448],
					[61.074272181266018, 1.9654248001300973],
					[61.074272172356217, 1.9654248021164915],
					[61.074272155331322, 1.9654248040514597],
					[61.074272147408749, 1.9654248076784004],
					[61.073780546835543, 1.9655609062588615],
					[61.073780515241324, 1.9655609226054382],
					[61.073780459661897, 1.965560962905345],
					[61.073780434689475, 1.9655609852181621],
					[61.073397052212407, 1.9661165627606079],
					[61.072933205761451, 1.9664942196286319],
					[61.072449946422992, 1.9666304071530969],
					[61.072449913046647, 1.9666304238950152],
					[61.072449854095332, 1.9666304686637672],
					[61.072449829315126, 1.9666304946529241],
					[61.072185928933543, 1.96706099469155],
					[61.072185928234994, 1.9670609985682741],
					[61.07218592406867, 1.9670610050784043],
					[61.072185921588151, 1.9670610093522802],
					[61.071777621954944, 1.9678582083592744],
					[61.0717776126098, 1.9678582364887194],
					[61.071777602034636, 1.9678582927996744],
					[61.071777600804587, 1.9678583209812113],
				],
				[
					[61.061519200002358, 2.0709888948624293],
					[61.061519200286682, 2.0709889003790298],
					[61.061519201044938, 2.0709889150899943],
					[61.061519200627679, 2.0709889244799102],
					[61.061847001180638, 2.0737333234013917],
					[61.061847002166537, 2.0737333250448002],
					[61.062110901895011, 2.0757278243708486],
					[61.062305400607421, 2.0773500192690402],
					[61.062455400167778, 2.0794778130992344],
					[61.062455401248329, 2.079477816581798],
					[61.062455401626991, 2.0794778239376304],
					[61.062455401816322, 2.0794778276155381],
					[61.06283870226325, 2.0822194276895138],
					[61.062838702452517, 2.0822194313674842],
					[61.062838703722207, 2.0822194385281896],
					[61.062838703816837, 2.0822194403671754],
					[61.063338704177184, 2.0845778404706246],
					[61.063338705257578, 2.0845778439534901],
					[61.063338705541362, 2.0845778494705658],
					[61.063338706621771, 2.0845778529534091],
					[61.064194207374172, 2.0876639534940122],
					[61.064194208359929, 2.0876639551379799],
					[61.064194209534811, 2.0876639604601284],
					[61.064194209723944, 2.0876639641382977],
					[61.064858109276798, 2.0896333594258989],
					[61.065405406686551, 2.0916083526963112],
					[61.065405410912959, 2.0916083647904431],
					[61.065405419082268, 2.0916083834611885],
					[61.065405424105421, 2.09160839352112],
					[61.066119223538976, 2.0929444937395165],
					[61.066119229453221, 2.0929445036049055],
					[61.06611924118728, 2.0929445214964497],
					[61.066119247007059, 2.0929445295226112],
					[61.066824846741724, 2.0937779292963254],
					[61.066824888830894, 2.0937779591471144],
					[61.066824983367006, 2.0937779924035826],
					[61.06682503403151, 2.0937779961992158],
					[61.068036133618904, 2.0935695974537807],
					[61.068036144313638, 2.0935695951139119],
					[61.068036164717327, 2.0935695887898449],
					[61.06803617531758, 2.0935695846106475],
					[61.068783374690575, 2.093266885205824],
					[61.068783402642893, 2.0932668679294544],
					[61.068783450945268, 2.0932668257398621],
					[61.068783472186588, 2.0932668006316271],
					[61.069400172438044, 2.0922195010433629],
					[61.069400173329278, 2.0922195008483211],
					[61.06940017492272, 2.0922194967794341],
					[61.069400175719437, 2.0922194947449939],
					[61.070244560839065, 2.0906529236828595],
					[61.070530736939951, 2.0903834459453834],
					[61.070530757991648, 2.0903834171548539],
					[61.07053078832007, 2.0903833584311315],
					[61.070530798298982, 2.0903833246239132],
					[61.07089189551828, 2.0874667423540969],
					[61.07133629062249, 2.0860528602402728],
					[61.071336295118471, 2.0860528425134386],
					[61.071336298857837, 2.0860528100706217],
					[61.071336299694416, 2.0860527912851925],
					[61.071236299705966, 2.0834167010142091],
					[61.071328000444581, 2.0816500099230995],
					[61.071328000349936, 2.0816500080836224],
					[61.071328000160655, 2.0816500044046684],
					[61.071328000066003, 2.0816500025651941],
					[61.071350199643362, 2.0793861019596909],
					[61.071350200250556, 2.079386096245849],
					[61.071350199871844, 2.0793860888879747],
					[61.071350198696614, 2.0793860835649665],
					[61.071141898595521, 2.0768832830654915],
					[61.07100300037024, 2.0752582936319488],
					[61.071075199820605, 2.0723611043252657],
					[61.071075200427465, 2.0723610986113674],
					[61.071075198778132, 2.0723610840915523],
					[61.071075199384985, 2.0723610783776509],
					[61.070808499997511, 2.0699888877097745],
					[61.070786300157373, 2.0682277974463315],
					[61.070786298697222, 2.068227786605684],
					[61.070786296762975, 2.0682277665680475],
					[61.070786295397653, 2.0682277575667927],
					[61.07053909530584, 2.0671138573643906],
					[61.07053907126204, 2.0671138105598854],
					[61.070539004422201, 2.067113737811805],
					[61.070538959654115, 2.0671137085809823],
					[61.06955006047216, 2.0668026094275316],
					[61.069550045833964, 2.0668026052023021],
					[61.069550017638456, 2.0668026002347335],
					[61.069550003189988, 2.0668025996881467],
					[61.068047203174388, 2.0667775999162115],
					[61.068047198718538, 2.066777600894913],
					[61.068047190603117, 2.0667776008173595],
					[61.068047186943545, 2.0667775997611026],
					[61.066658287217834, 2.0668692004067828],
					[61.066658269299481, 2.0668692024821924],
					[61.066658232856099, 2.0668692123461816],
					[61.06665821611346, 2.0668692197433045],
					[61.06539433436501, 2.067458110063682],
					[61.063958252901635, 2.0678054063943154],
					[61.063958227247156, 2.0678054157470145],
					[61.063958179976972, 2.0678054428647141],
					[61.063958158361302, 2.0678054606296934],
					[61.063160984010814, 2.0686137337298165],
					[61.062183217265726, 2.0690581182741448],
					[61.062183199726555, 2.0690581277034541],
					[61.06218316938849, 2.0690581511006054],
					[61.062183153821294, 2.0690581638164565],
					[61.061558153686164, 2.0697276634810593],
					[61.06155813362497, 2.069727693919758],
					[61.061558108140673, 2.069727759022904],
					[61.061558100044039, 2.0697277942741681],
					[61.061519200002358, 2.0709888948624293],
				],
				[
					[
						[61.0577776001278, 2.1848443902073349],
						[61.057777600873614, 2.1848444049200713],
						[61.057777604241274, 2.1848444357999686],
						[61.057777605878471, 2.1848444503203845],
						[61.058016506322865, 2.1857639504267241],
						[61.058016509556317, 2.1857639608844384],
						[61.058016517806131, 2.1857639814152034],
						[61.058016522822399, 2.1857639914882929],
						[61.058413723099029, 2.186527891155742],
						[61.058413775108669, 2.1865279394315826],
						[61.058413898284634, 2.1865279872287706],
						[61.058413967761382, 2.186527988973844],
						[61.059291680326794, 2.1862113201365521],
						[61.060324899025602, 2.186814073711953],
						[61.06032495193854, 2.1868140864683299],
						[61.060325057257749, 2.1868140842000754],
						[61.060325109570826, 2.18681406733619],
						[61.061283360572261, 2.1861862981664375],
						[61.062319356330278, 2.1861751994990075],
						[61.063274886207019, 2.1866251673582093],
						[61.063578986347565, 2.1869134493365108],
						[61.063244418750237, 2.1869858901989954],
						[61.061808376791255, 2.1863859157953063],
						[61.061808337622132, 2.1863859075115606],
						[61.061808261014207, 2.1863859073057421],
						[61.061808221792589, 2.1863859157683216],
						[61.060691624232774, 2.1868609146690678],
						[61.059894343265618, 2.1871776083281413],
						[61.05909715969922, 2.1873415049309179],
						[61.059097105364188, 2.1873415352442653],
						[61.059097025206945, 2.1873416250468551],
						[61.059097001167487, 2.1873416841515616],
						[61.059016501006681, 2.1884166848866093],
						[61.059016499782956, 2.1884166963061826],
						[61.059016500901109, 2.1884167183763248],
						[61.05901650225843, 2.1884167273799622],
						[61.059258102357781, 2.1901056273796908],
						[61.059258102823577, 2.1901056365756868],
						[61.059258106243028, 2.1901056507126735],
						[61.059258108398467, 2.1901056576850442],
						[61.059577608196186, 2.1911778576524412],
						[61.059577609180756, 2.1911778592994771],
						[61.05957761035161, 2.1911778646249687],
						[61.059577611336145, 2.1911778662720107],
						[61.059897007390823, 2.1920944540762588],
						[61.060219204073348, 2.1936278406808887],
						[61.060219210446036, 2.1936278597601504],
						[61.060219224881173, 2.1936278956951671],
						[61.060219233834999, 2.1936279123588167],
						[61.060935934455273, 2.1946945123467314],
						[61.060935937315683, 2.1946945154491031],
						[61.060935944114185, 2.1946945251403469],
						[61.060935946974645, 2.1946945282426897],
						[61.061577646614154, 2.1954557286092315],
						[61.061577682163566, 2.1954557544187776],
						[61.061577759742121, 2.1954557916257675],
						[61.061577803461006, 2.195455800799726],
						[61.062213903388574, 2.1954473991502237],
						[61.062213910612982, 2.1954473994529748],
						[61.062213927642986, 2.1954473976428441],
						[61.062213936650281, 2.1954473975614253],
						[61.063091609707207, 2.1952835195000984],
						[61.063727612833802, 2.1961972813263793],
						[61.064208103682539, 2.1984972403528023],
						[61.064208105837601, 2.198497247326852],
						[61.064208110240713, 2.1984972631144646],
						[61.064208113287151, 2.1984972698965182],
						[61.065005412915042, 2.2006389707113718],
						[61.065005414976945, 2.2006389758461715],
						[61.065005420085221, 2.2006389877634085],
						[61.065005423038528, 2.200638992706252],
						[61.065966514893056, 2.2024694765045143],
						[61.066685909126072, 2.2047667605771482],
						[61.06668591118784, 2.2047667657124914],
						[61.066685915311346, 2.2047667759831913],
						[61.066685918357521, 2.2047667827663777],
						[61.067166517685763, 2.2058361817170509],
						[61.067166540047758, 2.2058362140993952],
						[61.06716659678716, 2.2058362669798792],
						[61.067166630273121, 2.2058362876698703],
						[61.067963718597341, 2.2061334452256158],
						[61.068122002012807, 2.207208328964851],
						[61.068122009646856, 2.2072083552189583],
						[61.068122033922265, 2.2072084076489942],
						[61.068122048687812, 2.2072084323687151],
						[61.068919248600679, 2.2081195320083626],
						[61.068919265575815, 2.2081195469546637],
						[61.068919302905982, 2.2081195724004887],
						[61.068919323353946, 2.2081195847398587],
						[61.069636023002928, 2.2084195831926761],
						[61.069636027738895, 2.2084195877534079],
						[61.069636039606365, 2.2084195907799637],
						[61.069636045047915, 2.2084195914691884],
						[61.069787625290793, 2.2084625615732123],
						[61.063624867352182, 2.2139609516348315],
						[61.063624846179955, 2.2139609784986387],
						[61.063624813121308, 2.213961037668696],
						[61.063624802126348, 2.2139610697833909],
						[61.061752602131769, 2.22584166902082],
						[61.06175260056942, 2.2258416916682502],
						[61.061752604669181, 2.2258417372728228],
						[61.061752609439822, 2.2258417604211731],
						[61.065327608699086, 2.2371500598988008],
						[61.065327617832786, 2.2371500802571735],
						[61.065327640557697, 2.2371501200192658],
						[61.065327654947836, 2.2371501373923151],
						[61.071455454841285, 2.2436140387493282],
						[61.071455473595769, 2.2436140533344582],
						[61.071455514578346, 2.2436140799013891],
						[61.071455537605502, 2.2436140898522847],
						[61.083041638082541, 2.2474057893395742],
						[61.083041642724908, 2.2474057920672417],
						[61.083041654591732, 2.2474057951094402],
						[61.083041660924714, 2.2474057956147648],
						[61.087024841908779, 2.2482057726034284],
						[61.090947008029218, 2.2616333563915783],
						[61.090947009012972, 2.2616333580424399],
						[61.090947010088918, 2.2616333615346313],
						[61.090947010181125, 2.2616333633759371],
						[61.095230410074166, 2.2743667648707655],
						[61.095230419018613, 2.2743667815757589],
						[61.095230439766233, 2.2743668180986072],
						[61.095230452276894, 2.2743668340431262],
						[61.104960952394606, 2.2851334342259575],
						[61.104960984178703, 2.2851334572449939],
						[61.104961056111208, 2.2851334903308467],
						[61.104961095368026, 2.2851335005875608],
						[61.118008294887673, 2.2854584991353217],
						[61.118008328059787, 2.2854584957917643],
						[61.118008390102446, 2.2854584751207709],
						[61.11800841995651, 2.2854584594463283],
						[61.125286220141597, 2.2800251598745227],
						[61.12528624160278, 2.2800251385292052],
						[61.125286277732322, 2.2800250861096281],
						[61.125286290709504, 2.2800250572591825],
						[61.128239052385041, 2.2702723869658366],
						[61.134402817960805, 2.2697223991444422],
						[61.134402828751476, 2.2697223987016222],
						[61.134402850056397, 2.2697223922843244],
						[61.134402859863407, 2.2697223901881736],
						[61.140580660373686, 2.2677695901022101],
						[61.140580718355672, 2.2677695422875632],
						[61.140580784332982, 2.2677694200696115],
						[61.140580792328358, 2.2677693456662826],
						[61.137377995829965, 2.2564610588361824],
						[61.136330814049607, 2.2494167801976062],
						[61.140339030134541, 2.2460057514107459],
						[61.140339044642815, 2.2460057352501965],
						[61.140339071691336, 2.2460056996233999],
						[61.140339083340145, 2.2460056803484147],
						[61.14217517958199, 2.2418389868089075],
						[61.143638992038667, 2.2390669533560494],
						[61.147258224067087, 2.2405612846459251],
						[61.147258320810664, 2.2405612731883919],
						[61.147258458149231, 2.2405611728428338],
						[61.14725849883672, 2.240561085799321],
						[61.146244599030361, 2.2271971841049161],
						[61.146244598844895, 2.2271971804161086],
						[61.146244598288526, 2.2271971693497155],
						[61.146244597118894, 2.2271971640083752],
						[61.143783497943311, 2.2138027729199652],
						[61.142414099835818, 2.1990277878022164],
						[61.142114100261566, 2.1877916947803535],
						[61.142114100261566, 2.1877916947803535],
						[61.142114100075034, 2.1877916910923467],
						[61.142114099981782, 2.1877916892483422],
						[61.141464099660162, 2.1744388959713987],
						[61.141541900141043, 2.1604028012930803],
						[61.141541899579231, 2.1604027902298739],
						[61.141541897751573, 2.1604027719848813],
						[61.141541896298399, 2.1604027611153729],
						[61.138716896043469, 2.1462999609407469],
						[61.136600195899618, 2.1357221609745758],
						[61.136600177601096, 2.1357221202292807],
						[61.136600128198403, 2.1357220527149039],
						[61.136600095217695, 2.1357220244910451],
						[61.131172295411751, 2.1327859246532244],
						[61.131172288890703, 2.1327859204845088],
						[61.131172277819232, 2.132785915444575],
						[61.13117227228345, 2.1327859129246214],
						[61.125375071538329, 2.1305442124483118],
						[61.125374987193119, 2.1305442196658522],
						[61.125374853176787, 2.1305442954806275],
						[61.125374802614338, 2.130544364272263],
						[61.12387760305463, 2.1389305648307038],
						[61.123877601929401, 2.1389305781183121],
						[61.123877599678956, 2.1389306046935403],
						[61.123877601227619, 2.138930617398672],
						[61.125244201390849, 2.1544028176052383],
						[61.125244202844364, 2.1544028284688763],
						[61.125244206736497, 2.1544028518453029],
						[61.125244210066427, 2.1544028641643735],
						[61.128099809885747, 2.1628917632883295],
						[61.128099885405078, 2.162891830746057],
						[61.128100054752188, 2.1628918647890747],
						[61.128100149564865, 2.1628918330239424],
						[61.129927628607987, 2.1608282941852615],
						[61.130260900126551, 2.1664528128944633],
						[61.130260902844682, 2.1664528309399351],
						[61.130260911752814, 2.1664528644139041],
						[61.130260918129892, 2.1664528835288652],
						[61.133488717871117, 2.1735472823469424],
						[61.133488722888657, 2.1735472924413246],
						[61.133488733721627, 2.1735473105933871],
						[61.133488739630522, 2.1735473204945133],
						[61.135647000138952, 2.176405666277911],
						[61.135577600323948, 2.1890388951113153],
						[61.135146999719979, 2.2009582935953702],
						[61.13514700065074, 2.200958312031887],
						[61.135147007062443, 2.2009583497860561],
						[61.135147011651945, 2.2009583692962131],
						[61.138008099645901, 2.2087500342692619],
						[61.137977599625323, 2.214363813246814],
						[61.135058383913957, 2.2170996470921738],
						[61.132197391226036, 2.2093110388070216],
						[61.128289093072055, 2.1951777474427754],
						[61.128289089838958, 2.1951777369612278],
						[61.128289082667607, 2.1951777198772051],
						[61.128289077651644, 2.1951777097808907],
						[61.125775218729657, 2.1902055895697452],
						[61.126880785547499, 2.1874250735058478],
						[61.126880792451004, 2.1874250496530712],
						[61.126880797862427, 2.1874249963104084],
						[61.126880798339698, 2.1874249701211057],
						[61.125130797682978, 2.1754527713941156],
						[61.125130792996522, 2.1754527500510275],
						[61.125130778368771, 2.1754527103667076],
						[61.125130768427503, 2.1754526920254538],
						[61.120094568399473, 2.1676137911057891],
						[61.120094539975433, 2.1676137637378097],
						[61.120094472805121, 2.1676137186923294],
						[61.120094435043718, 2.1676137026641498],
						[61.111030675619141, 2.165991511017102],
						[61.109947311149945, 2.1652665339145321],
						[61.109947246273599, 2.1652665163245657],
						[61.109947119046424, 2.1652665308799239],
						[61.109947058291255, 2.1652665589545199],
						[61.1052055316948, 2.1700636845635204],
						[61.096141739952358, 2.1684442033716489],
						[61.089255689286794, 2.166877614591916],
						[61.082027924899464, 2.1610915438752292],
						[61.082027839241057, 2.1610915252281142],
						[61.082027685224517, 2.1610915623330271],
						[61.082027616959984, 2.1610916199254349],
						[61.080191622031492, 2.1652579818332724],
						[61.072964033263901, 2.1594720516646588],
						[61.070075141005191, 2.1565970572214459],
						[61.070075045964849, 2.1565970313253384],
						[61.070074879658037, 2.1565970748188903],
						[61.070074809189293, 2.1565971421756025],
						[61.068599816986215, 2.1614749146280863],
						[61.064577631776743, 2.1676915903825185],
						[61.064577618916921, 2.1676916210598973],
						[61.064577603189193, 2.1676916839718356],
						[61.064577601212669, 2.1676917160135112],
						[61.064922001216246, 2.1719083161518888],
						[61.064922000605122, 2.1719083218630288],
						[61.064922001963538, 2.1719083308675557],
						[61.064922003135152, 2.1719083361931721],
						[61.065449419270145, 2.1748049909370168],
						[61.064624922167312, 2.1752331149724013],
						[61.063666666779085, 2.1755526001125953],
						[61.063030597646545, 2.1755581002900755],
						[61.063030564572124, 2.1755581055785926],
						[61.063030502642306, 2.1755581282605712],
						[61.063030472895505, 2.1755581458466842],
						[61.06207771039292, 2.1763387151483533],
						[61.061358259939716, 2.1764998032634657],
						[61.06048326300828, 2.1766637029480882],
						[61.06048322158, 2.1766637211961024],
						[61.060483151244831, 2.1766637735805934],
						[61.060483122337928, 2.1766638077170559],
						[61.05992482162209, 2.1777471085675995],
						[61.059924820917331, 2.1777471124385372],
						[61.059924817631781, 2.1777471187263515],
						[61.059924815942345, 2.1777471209506549],
						[61.059527618835048, 2.1786749154261238],
						[61.059127622044414, 2.1794471086295459],
						[61.059127622044414, 2.1794471086295459],
						[61.058891529624667, 2.1799109939876775],
						[61.058174841242078, 2.1808442786613664],
						[61.05817483208974, 2.1808442936521093],
						[61.058174814769764, 2.1808443252802503],
						[61.058174807586788, 2.180844343564289],
						[61.057852607684474, 2.1819221433166671],
						[61.057852604771782, 2.1819221569599829],
						[61.057852600822436, 2.1819221857008158],
						[61.057852599785804, 2.1819222007983625],
						[61.057858099580478, 2.1833055942480089],
						[61.0577776001278, 2.1848443902073349],
					],
					[
						[61.068056090407396, 2.1879521895526239],
						[61.068058148710087, 2.1879529561711282],
						[61.068058971440706, 2.1879549304511752],
						[61.068056090407396, 2.1879521895526239],
					],
				],
				[
					[61.056847018825863, 1.9559888590492904],
					[61.056847040403305, 1.9559889955682128],
					[61.056847077030014, 1.9559890580635622],
					[61.058260977213031, 1.957086257101029],
					[61.058260981065878, 1.9570862618207456],
					[61.058260988482601, 1.9570862657455095],
					[61.058260991348163, 1.9570862688257376],
					[61.058966574952422, 1.9575445576410169],
					[61.059699859948246, 1.9582445428272606],
					[61.06051925133314, 1.9590612336860711],
					[61.061558143987881, 1.9603557241747807],
					[61.061558147840664, 1.9603557288953775],
					[61.061558154655167, 1.9603557385352595],
					[61.061558159302606, 1.9603557412187347],
					[61.062027652460849, 1.9608223354786678],
					[61.062941531006345, 1.9619362103818341],
					[61.063624820953692, 1.9633028884036279],
					[61.063624823024398, 1.9633028935220993],
					[61.063624827960517, 1.9633029017218826],
					[61.063624830922208, 1.9633029066417578],
					[61.064480431053553, 1.9646612078678838],
					[61.064480435893387, 1.9646612142294717],
					[61.064480447451245, 1.9646612283941078],
					[61.064480452291043, 1.9646612347556902],
					[61.065066537087951, 1.9653029177464254],
					[61.066044226197342, 1.9670222988265844],
					[61.066044237947381, 1.967022316670203],
					[61.066044264722755, 1.9670223460471643],
					[61.066044279844348, 1.9670223594192331],
					[61.066197079637135, 1.9671362592259027],
					[61.066197084380669, 1.9671362637494609],
					[61.066197090906186, 1.9671362678759841],
					[61.066197094662513, 1.9671362707593407],
					[61.067222094550473, 1.9677668694538666],
					[61.067222116703675, 1.9677668793998229],
					[61.067222160817735, 1.9677668956141665],
					[61.067222184464349, 1.9677668996467186],
					[61.068302783584933, 1.9678529989178819],
					[61.068302860619809, 1.9678529724521332],
					[61.068302967953166, 1.9678528704047973],
					[61.068303000129752, 1.9678527962650489],
					[61.06825300017023, 1.9650777959373666],
					[61.068253000073987, 1.965077794098532],
					[61.068253000073987, 1.965077794098532],
					[61.068203000020318, 1.9632721969297788],
					[61.068191899643047, 1.9614027984522211],
					[61.068191899643047, 1.9614027984522211],
					[61.068180799881169, 1.9602583186275224],
					[61.068664093057805, 1.9578306549456108],
					[61.069008487391457, 1.9569139708298693],
					[61.069008491966542, 1.9569139549260042],
					[61.069008498443772, 1.9569139237148125],
					[61.069008500249609, 1.9569139065686234],
					[61.069030800195549, 1.9563111061173959],
					[61.069030800002857, 1.9563111024396973],
					[61.069030800315709, 1.9563110912077268],
					[61.069030799135724, 1.9563110858900055],
					[61.068908499818697, 1.9546860894885785],
					[61.068827999896214, 1.9524555985914089],
					[61.06885299977256, 1.9515500052905292],
					[61.068852999676139, 1.9515500034517081],
					[61.068853000374283, 1.951549999575078],
					[61.068853000277862, 1.9515499977362396],
					[61.068836299605927, 1.9498027976704075],
					[61.068836295293629, 1.9498027670076366],
					[61.068836275086689, 1.949802708269524],
					[61.06883626008301, 1.9498026799951482],
					[61.068394560393969, 1.9492137796109772],
					[61.068394531134913, 1.9492137545220138],
					[61.068394464189538, 1.9492137155285294],
					[61.068394427394189, 1.9492137014249449],
					[61.068041727096634, 1.9491665016320012],
					[61.068041666415525, 1.9491665133283209],
					[61.068041562826458, 1.949166566235951],
					[61.068041521002293, 1.9491666109257777],
					[61.067555425287914, 1.9501471014294238],
					[61.067172046371262, 1.9507609683332363],
					[61.066247091900451, 1.9515776285980602],
					[61.065780605097842, 1.951771981155022],
					[61.065547285699679, 1.9516609195323658],
					[61.065547256417851, 1.951660911189856],
					[61.065547194675929, 1.9516609026551912],
					[61.065547163106835, 1.9516609022640721],
					[61.064758262639714, 1.9518081023680409],
					[61.064758261845199, 1.9518081044055631],
					[61.064758260063272, 1.9518081048034528],
					[61.064758258184973, 1.9518081033627546],
					[61.064419357769168, 1.951880404125186],
					[61.064419335688079, 1.9518804127757994],
					[61.064419294295135, 1.9518804313187998],
					[61.064419275176107, 1.9518804448883127],
					[61.063719288175889, 1.9524442320615158],
					[61.063247104461738, 1.952702625306113],
					[61.063247095648535, 1.9527026291337024],
					[61.063247081778762, 1.9527026396702145],
					[61.063247074843893, 1.9527026449384861],
					[61.062935994755229, 1.9529526286786574],
					[61.062641697117499, 1.9530830822679421],
					[61.061961175942088, 1.9528054158724548],
					[61.061961154871675, 1.9528054094164116],
					[61.061961113911018, 1.9528054018207563],
					[61.06196109312981, 1.95280540088],
					[61.061497193052524, 1.9528219992338549],
					[61.061497169887595, 1.9528220044049724],
					[61.061497126326998, 1.9528220159889373],
					[61.061497104245831, 1.9528220246379755],
					[61.061149904172545, 1.9530109241998594],
					[61.061149902390632, 1.9530109245976219],
					[61.061149898032212, 1.9530109274304235],
					[61.061149896346684, 1.9530109296665656],
					[61.06051659624282, 1.953394228671703],
					[61.059780501773496, 1.953841525495744],
					[61.059033208462779, 1.954227622454134],
					[61.059033197073006, 1.954227628715459],
					[61.05903317626813, 1.9542276445169571],
					[61.059033165865706, 1.9542276524176967],
					[61.058413784439097, 1.954788736620388],
					[61.057224904601249, 1.9554331232266799],
					[61.05722488468718, 1.9554331388276291],
					[61.057224850301147, 1.9554331706750281],
					[61.057224834938246, 1.9554331871202404],
					[61.056847034958821, 1.9559887885032454],
					[61.056847018825863, 1.9559888590492904],
				],
				[
					[61.05223309990614, 2.1184027978845958],
					[61.052233102115174, 2.1184028234296446],
					[61.052233102774061, 2.1184028362992668],
					[61.052547003390309, 2.120141732706827],
					[61.052872002180713, 2.1222611300338912],
					[61.05287200326017, 2.1222611335169552],
					[61.052872003730549, 2.1222611427097946],
					[61.052872004715873, 2.1222611443543036],
					[61.05336370474263, 2.1243667445198842],
					[61.053363706901379, 2.1243667514862752],
					[61.053363710233583, 2.1243667637744972],
					[61.053363712298285, 2.1243667689022923],
					[61.053905408926433, 2.1258389618099707],
					[61.054210906690024, 2.1269500529380894],
					[61.054210907675305, 2.1269500545828168],
					[61.054210908754648, 2.1269500580662055],
					[61.054210908848653, 2.1269500599048694],
					[61.054633108933025, 2.128305659525751],
					[61.05463311207685, 2.1283056681375068],
					[61.054633119161849, 2.1283056833283931],
					[61.054633123196986, 2.1283056917462386],
					[61.055644223123146, 2.13025009146414],
					[61.055644225187628, 2.1302500965927083],
					[61.055644229128681, 2.1302501031723171],
					[61.055644231193192, 2.1302501083008645],
					[61.056647025799926, 2.1318194985282335],
					[61.057247021179528, 2.1330361872510033],
					[61.057247021273454, 2.1330361890898826],
					[61.057247023243924, 2.1330361923800143],
					[61.057247024229135, 2.1330361940250855],
					[61.057899823576648, 2.1342500950505094],
					[61.057899824561858, 2.1342500966956659],
					[61.057899828408814, 2.1342501014373161],
					[61.057899829394053, 2.1342501030824494],
					[61.058783129232225, 2.1357001048236128],
					[61.058783135049531, 2.1357001128560382],
					[61.058783146684206, 2.135700128920885],
					[61.058783154284143, 2.1357001365658324],
					[61.059666541157611, 2.1366473237140182],
					[61.060672032183746, 2.1382140073931106],
					[61.060672037109597, 2.138214015620147],
					[61.060672048743989, 2.1382140316868528],
					[61.060672055358623, 2.138214037687451],
					[61.062255454864257, 2.1398695381810153],
					[61.062255455849424, 2.1398695398265475],
					[61.062255456740722, 2.139869539632909],
					[61.06225545772589, 2.1398695412784408],
					[61.063308158146448, 2.1409307416092753],
					[61.063308161899364, 2.1409307445132959],
					[61.063308171375482, 2.1409307536125621],
					[61.063308176019717, 2.1409307563229434],
					[61.064474861337395, 2.1418584457428218],
					[61.065297048603924, 2.1428112302897988],
					[61.065297065585732, 2.1428112451989709],
					[61.065297103020811, 2.1428112724036219],
					[61.065297122582749, 2.142811284892669],
					[61.066341572191867, 2.1432445641270532],
					[61.066872039440582, 2.1439612191312656],
					[61.066872060268928, 2.1439612387858999],
					[61.066872105303332, 2.1439612736419531],
					[61.066872131292051, 2.1439612884562598],
					[61.068030463540055, 2.1443890625550215],
					[61.068388727663134, 2.1449945017081111],
					[61.068388760031254, 2.1449945355977307],
					[61.068388837293291, 2.1449945839177791],
					[61.068388881295895, 2.1449945985417673],
					[61.069138816416029, 2.1450668930315508],
					[61.070191567296057, 2.1460029485274834],
					[61.070191574895468, 2.1460029561775431],
					[61.070191591689451, 2.146002967411254],
					[61.070191601071549, 2.1460029746742415],
					[61.071183195911331, 2.1465668709369963],
					[61.07205547583262, 2.1471362582164026],
					[61.073047059561873, 2.1480751421755353],
					[61.074105458400489, 2.1491390415393643],
					[61.074105459291808, 2.149139041345892],
					[61.074105462153248, 2.1491390444452811],
					[61.074105463138288, 2.1491390460917081],
					[61.074980462899788, 2.1499584454796556],
					[61.074980473266727, 2.1499584543913377],
					[61.074980492030527, 2.1499584689217128],
					[61.074980503195114, 2.1499584757999757],
					[61.07619160340834, 2.150633474936317],
					[61.076191613587817, 2.1506334801684868],
					[61.076191639294791, 2.1506334894721073],
					[61.076191651256934, 2.1506334943173746],
					[61.077766650730076, 2.1510307942440683],
					[61.077766656171804, 2.1510307949234919],
					[61.077766664381159, 2.1510307968626714],
					[61.077766668931552, 2.1510307977355292],
					[61.079441669287654, 2.1512945972962423],
					[61.079441698185143, 2.1512945984664809],
					[61.079441753635152, 2.1512945901525495],
					[61.079441782064116, 2.1512945821216816],
					[61.082077881943995, 2.1501112814241594],
					[61.082077889872252, 2.1501112778421851],
					[61.082077905635046, 2.1501112688378545],
					[61.082077914360923, 2.1501112632220014],
					[61.083277914157328, 2.1492779644261537],
					[61.083277916737593, 2.1492779620050837],
					[61.08327792189808, 2.1492779571629534],
					[61.083277925463435, 2.1492779563887696],
					[61.08447792467534, 2.1483168552902132],
					[61.084477929038222, 2.148316852481809],
					[61.084477937576487, 2.1483168431840034],
					[61.084477941939397, 2.1483168403755792],
					[61.085275115438314, 2.1475084694931073],
					[61.087036177874936, 2.1467612839200481],
					[61.087036189368369, 2.1467612795620141],
					[61.0870362111826, 2.1467612655176485],
					[61.087036220799618, 2.1467612597062669],
					[61.088122321367642, 2.1459334585052345],
					[61.088122328310625, 2.1459334532744156],
					[61.08812234121153, 2.145933441165734],
					[61.088122347263223, 2.1459334361285887],
					[61.088744541185399, 2.1452612422442319],
					[61.089489026769691, 2.1445834550810039],
					[61.091202918412769, 2.1433362619150551],
					[61.091202955378947, 2.143336209208011],
					[61.09120298896751, 2.1433360902295449],
					[61.091202985683729, 2.1433360257989911],
					[61.090961285904172, 2.1427249258819114],
					[61.090961283933893, 2.1427249225877842],
					[61.090961280884592, 2.1427249158057307],
					[61.090961279805647, 2.1427249123178087],
					[61.090544580423931, 2.1418721124011868],
					[61.090544577468457, 2.1418721074601437],
					[61.090544570666218, 2.1418720977718353],
					[61.090544568602049, 2.1418720926369801],
					[61.089541869266228, 2.1403026925168365],
					[61.089541866310704, 2.140302687576078],
					[61.089541859602235, 2.1403026797291802],
					[61.089541856740567, 2.1403026766292026],
					[61.088600158392197, 2.1391081766430817],
					[61.088411323078816, 2.1388604376816041],
					[61.087780761337036, 2.1380331810753983],
					[61.086483462090783, 2.1362304823188709],
					[61.086483456273385, 2.1362304742796381],
					[61.086483441964653, 2.1362304587829897],
					[61.086483434364666, 2.136230451131647],
					[61.085316841884563, 2.1351776589116485],
					[61.084786269766951, 2.1345859906469862],
					[61.084130782694544, 2.1331193172065959],
					[61.084130770965452, 2.133119299290025],
					[61.084130743848142, 2.1331192623924782],
					[61.084130727662576, 2.1331192454459327],
					[61.083430735022247, 2.1325359526552052],
					[61.082725159776807, 2.1318304770075298],
					[61.082016872981725, 2.1306192996102644],
					[61.082016862237637, 2.1306192833420137],
					[61.082016838263492, 2.130619255068289],
					[61.082016824142194, 2.1306192432568642],
					[61.08143342375498, 2.1301554436269936],
					[61.081433421878351, 2.1301554421748694],
					[61.08143341803121, 2.1301554374303868],
					[61.081433415263355, 2.130155436172334],
					[61.080616814949629, 2.1295804351501877],
					[61.080616769068982, 2.1295804190912127],
					[61.080616672994083, 2.1295804083799315],
					[61.080616622893722, 2.1295804155678524],
					[61.07952780460792, 2.1300330813936976],
					[61.07859731684902, 2.1295915332130568],
					[61.078127941613722, 2.1291192593077173],
					[61.078127937766538, 2.1291192545638324],
					[61.078127929274892, 2.1291192471101748],
					[61.078127924630429, 2.1291192444003992],
					[61.077836228040638, 2.1288859452259055],
					[61.076900131180523, 2.1280692497674001],
					[61.07690009768384, 2.1280692291549097],
					[61.076900025297583, 2.1280692058483903],
					[61.076899987111297, 2.1280691992803007],
					[61.076152822421228, 2.1281191979893839],
					[61.075108440766115, 2.1278109336162738],
					[61.074752980097969, 2.1270832126957067],
					[61.074752957811818, 2.1270831822027692],
					[61.074752906767351, 2.127083135648729],
					[61.07475287683571, 2.1270831142621227],
					[61.074225076972112, 2.1268637150911069],
					[61.074225075189538, 2.1268637154793129],
					[61.074225070545026, 2.1268637127701702],
					[61.074225068668433, 2.1268637113185531],
					[61.07364732491871, 2.1266526321806856],
					[61.073294561192988, 2.1261720811478142],
					[61.073294522675383, 2.1261720504714181],
					[61.073294434993905, 2.1261720100397743],
					[61.073294386721265, 2.1261720000904232],
					[61.072719500227734, 2.1262108927064749],
					[61.07171686433125, 2.1247692854901681],
					[61.071716843406193, 2.1247692640058986],
					[61.071716794613941, 2.1247692262698394],
					[61.071716767732056, 2.1247692116635779],
					[61.071136168161615, 2.1245581124584767],
					[61.071136167270353, 2.124558112652617],
					[61.071136163611158, 2.1245581115895367],
					[61.071136161734543, 2.1245581101381892],
					[61.070494472192081, 2.124347012235611],
					[61.069508441133827, 2.1239109439586672],
					[61.06874187255066, 2.1225776996860817],
					[61.068741857067046, 2.1225776788795927],
					[61.068741818172448, 2.1225776408537316],
					[61.068741795652699, 2.1225776234401748],
					[61.0683333988488, 2.122355425981763],
					[61.066819503246087, 2.1214498283979442],
					[61.066819481102634, 2.1214498183436463],
					[61.06681943719208, 2.1214498055925648],
					[61.06681941434551, 2.1214497994112254],
					[61.065891742234484, 2.1213859020719794],
					[61.064730668795228, 2.1209609114196706],
					[61.064730662368099, 2.1209609091005239],
					[61.064730648716598, 2.12096090649566],
					[61.064730642289476, 2.1209609041765103],
					[61.063400041711134, 2.120672005175567],
					[61.063400040725782, 2.1206720035305842],
					[61.063400038943222, 2.1206720039189655],
					[61.063400038051938, 2.1206720041131559],
					[61.06196115436093, 2.1203915064283567],
					[61.060802869354404, 2.1199665122534843],
					[61.060802863912613, 2.1199665115796087],
					[61.060802855608785, 2.119966507810251],
					[61.060802851058298, 2.1199665069421765],
					[61.060047284776537, 2.1197665167411861],
					[61.058883413789864, 2.1189637349177803],
					[61.058883389769562, 2.1189637234169059],
					[61.058883340228846, 2.1189637063202609],
					[61.058883313817113, 2.1189637009187035],
					[61.057955653955446, 2.1188998021001644],
					[61.056850090098997, 2.1183442214893136],
					[61.056850079918462, 2.1183442162704083],
					[61.056850059745642, 2.1183442095101843],
					[61.056850049659225, 2.118344206130081],
					[61.055694509926923, 2.1180470218755674],
					[61.054869550795097, 2.1170942693761066],
					[61.054869525318523, 2.117094247040161],
					[61.054869466908848, 2.1170942151482657],
					[61.054869434772861, 2.1170942035594127],
					[61.054352893304795, 2.1170026128074357],
					[61.053469535911375, 2.1161831531023871],
					[61.053469509825902, 2.1161831364780754],
					[61.053469452589823, 2.1161831099106032],
					[61.053469423315867, 2.1161831014175672],
					[61.052775022806166, 2.1161026018224316],
					[61.052774961214368, 2.1161026133860092],
					[61.052774858021792, 2.1161026749148784],
					[61.052774816232699, 2.116102721203077],
					[61.052330415880569, 2.1171388202536492],
					[61.052330411286107, 2.1171388361269741],
					[61.05233040298841, 2.1171388676794511],
					[61.052330401161896, 2.1171388848087069],
					[61.052233101029792, 2.1184027846266633],
					[61.05223309990614, 2.1184027978845958],
				],
				[
					[61.01827480468517, 2.0025167449827763],
					[61.018755404535753, 2.0045750443694619],
					[61.018755405618094, 2.0045750478446371],
					[61.01875540679611, 2.0045750531559587],
					[61.018755407782791, 2.0045750547950063],
					[61.019099807605151, 2.0057722549500858],
					[61.019099808783132, 2.0057722602615073],
					[61.019099811934502, 2.0057722688511301],
					[61.019099813907879, 2.0057722721293274],
					[61.020205413670148, 2.0085861729056798],
					[61.02020541376573, 2.0085861747419287],
					[61.020205414752375, 2.0085861763811752],
					[61.020205415643467, 2.008586176184183],
					[61.021063716301448, 2.0106333770765867],
					[61.021063720343598, 2.0106333854702902],
					[61.021063729318897, 2.0106334020607828],
					[61.021063733361053, 2.0106334104544876],
					[61.022244231884244, 2.0124084080162596],
					[61.022880430068327, 2.0134251053943912],
					[61.022880432041546, 2.01342510867347],
					[61.022880434014759, 2.0134251119525319],
					[61.022880435892461, 2.0134251133951864],
					[61.023644235675285, 2.0145251142623728],
					[61.023644243377028, 2.0145251237063171],
					[61.023644258685025, 2.01452514075774],
					[61.023644267277838, 2.0145251500048347],
					[61.024113767312926, 2.0149418495581877],
					[61.02411383535425, 2.014941875394586],
					[61.024113968667152, 2.0149418738093168],
					[61.024114035720849, 2.0149418459939681],
					[61.024400136247671, 2.0146751451274039],
					[61.02440014744969, 2.0146751352223298],
					[61.024400166002842, 2.0146751106900913],
					[61.024400174340563, 2.0146750977025767],
					[61.025683460281947, 2.0123890232699737],
					[61.02613904017722, 2.0119445427529175],
					[61.026139041068298, 2.0119445425559768],
					[61.026139044441514, 2.0119445380950287],
					[61.026139046223669, 2.0119445377011433],
					[61.027225084057669, 2.0107863027779476],
					[61.028519358391726, 2.0108334980870488],
					[61.02931927919883, 2.0111529673014243],
					[61.031094247420839, 2.0132584287699355],
					[61.031094249298526, 2.0132584302129271],
					[61.031094253149448, 2.0132584349357834],
					[61.031094255122682, 2.0132584382156695],
					[61.031810933313658, 2.0140140153576533],
					[61.032794219709679, 2.0160556876034912],
					[61.032794226615742, 2.0160556990842671],
					[61.032794244087803, 2.01605572309529],
					[61.032794252776071, 2.0160557341822964],
					[61.033255453007548, 2.0165584346647969],
					[61.033255459722632, 2.0165584424718048],
					[61.033255473852854, 2.0165584542149215],
					[61.033255481459037, 2.016558461825058],
					[61.033930472044659, 2.0170557535556766],
					[61.035277626104012, 2.018311211552847],
					[61.035838709934062, 2.0200222619368624],
					[61.03583871811275, 2.0200222805715264],
					[61.035838736952485, 2.0200223135761104],
					[61.035838747708993, 2.0200223297832074],
					[61.036555448141158, 2.020861229886342],
					[61.036555451005334, 2.0208612329704767],
					[61.036555455842567, 2.0208612393354919],
					[61.036555458611254, 2.0208612405824113],
					[61.037647059262639, 2.0219529403784149],
					[61.037647061235731, 2.0219529436594996],
					[61.037647064990949, 2.0219529465470658],
					[61.037647066963984, 2.0219529498281421],
					[61.038580431979952, 2.0227890176851044],
					[61.039171995173106, 2.0242389266802339],
					[61.039055400770252, 2.0251916754267429],
					[61.039055416870212, 2.0251917424940977],
					[61.039055486433675, 2.0251918497978703],
					[61.039055539992511, 2.0251918918717005],
					[61.039811036323741, 2.0254279905844799],
					[61.040691606328352, 2.0257473783583677],
					[61.041497083359666, 2.0263279618717358],
					[61.041497083455042, 2.0263279637092677],
					[61.041958167289224, 2.0266584501508356],
					[61.042808076705199, 2.0275833539824726],
					[61.041510917749989, 2.0321388073298658],
					[61.039775551290269, 2.0343811261057048],
					[61.035555510611339, 2.0364748214845267],
					[61.035555503577591, 2.036474824892395],
					[61.035555489605315, 2.0364748335453817],
					[61.035555484353807, 2.0364748365605871],
					[61.033213920161636, 2.0381469398664587],
					[61.031636240021591, 2.0366081567628038],
					[61.031636219369446, 2.0366081408742316],
					[61.031636173895208, 2.036608115589571],
					[61.031636149964221, 2.0366081059972045],
					[61.029800050104683, 2.036138705095655],
					[61.029799962774717, 2.0361387243323708],
					[61.029799838071696, 2.0361388224006665],
					[61.02979979980757, 2.0361389014285249],
					[61.029810900179029, 2.0377306015784082],
					[61.029810900940653, 2.0377306162736484],
					[61.029810906123672, 2.0377306467160143],
					[61.029810908572344, 2.0377306591818396],
					[61.033280408661902, 2.048761159389898],
					[61.033280441812607, 2.048761207841435],
					[61.033280528265742, 2.0487612761584106],
					[61.033280580867142, 2.0487612998941054],
					[61.035012196751545, 2.0489222783710193],
					[61.03500810029599, 2.049336097852553],
					[61.035008112760053, 2.0493361471399338],
					[61.035008159586646, 2.0493362334665632],
					[61.035008195826535, 2.0493362719511468],
					[61.037360996370225, 2.0507751710808257],
					[61.037361010212628, 2.0507751773286333],
					[61.037361040570879, 2.0507751892364192],
					[61.037361056195586, 2.0507751950923399],
					[61.042927755829865, 2.0520168947272355],
					[61.042927761271784, 2.0520168953892326],
					[61.042927771359508, 2.0520168987469072],
					[61.0429277759103, 2.0520168996048489],
					[61.045998552072469, 2.0523945342844065],
					[61.050585972783921, 2.0561445556228923],
					[61.050585995026459, 2.0561445674649148],
					[61.050586042185003, 2.0561445905612459],
					[61.050586066816109, 2.0561445963010256],
					[61.053202766941531, 2.0565890977621515],
					[61.053202787723052, 2.0565890987712749],
					[61.053202829096186, 2.0565890971128358],
					[61.053202849687814, 2.0565890944452745],
					[61.055552798685596, 2.0559808063611467],
					[61.062630549450411, 2.0578668930982564],
					[61.062630601707419, 2.0578668927665511],
					[61.062630700341195, 2.0578668654996495],
					[61.062630744840718, 2.05786683711748],
					[61.065227944820293, 2.0551251377945037],
					[61.06522796080224, 2.0551251156793935],
					[61.065227984650697, 2.0551250713745977],
					[61.065227995095675, 2.0551250467577198],
					[61.065994594695397, 2.0519139463319478],
					[61.066056958587907, 2.0516517938854406],
					[61.066819532609145, 2.0509723487141676],
					[61.066819535187527, 2.0509723462865437],
					[61.066819540344291, 2.050972341431283],
					[61.066819543017701, 2.0509723408427605],
					[61.068377943271273, 2.0493862402619047],
					[61.068377946645676, 2.0493862357986163],
					[61.068377955972828, 2.0493862244441403],
					[61.068377960238386, 2.0493862197846155],
					[61.069119560454055, 2.048391820398753],
					[61.069119567903755, 2.0483918075970244],
					[61.069119581020871, 2.0483917823860991],
					[61.06911958678338, 2.0483917718161195],
					[61.069427986904159, 2.0475750718486925],
					[61.069427989292272, 2.0475750657420946],
					[61.0694279931773, 2.0475750537252235],
					[61.069427994579144, 2.0475750459757083],
					[61.070264090837689, 2.0440694605326439],
					[61.07282237785919, 2.037627893030606],
					[61.075377966202943, 2.0337223109483458],
					[61.07790296470202, 2.0300223134045683],
					[61.077902980012013, 2.0300222783901325],
					[61.077902996183596, 2.0300222078316117],
					[61.077902997745589, 2.0300221684113522],
					[61.077591898074125, 2.0280166692867052],
					[61.077591872839832, 2.0280166171807656],
					[61.077591801047511, 2.0280165362879399],
					[61.077591751720824, 2.02801650625213],
					[61.075233351791383, 2.0273804076320241],
					[61.075233339920928, 2.027380404673321],
					[61.075233313602105, 2.0273804011861425],
					[61.075233300935928, 2.0273804002638562],
					[61.072630850185405, 2.027360902426965],
					[61.073177987784518, 2.0249278778983664],
					[61.076983464870032, 2.0187668143871411],
					[61.077858452001124, 2.0176334293035656],
					[61.082383445216053, 2.01285843786895],
					[61.082383473057838, 2.0128583665661068],
					[61.082383469261643, 2.0128582241080362],
					[61.082383437814812, 2.0128581566325194],
					[61.0807584382922, 2.0112998564595319],
					[61.080758403792913, 2.011299834327434],
					[61.08075832843064, 2.0112998063604954],
					[61.080758288458767, 2.0112998003282403],
					[61.077419476089062, 2.0115025949206866],
					[61.076958568379176, 2.0110527828132776],
					[61.077658456793714, 2.0101640236136817],
					[61.077658457589166, 2.0101640215766867],
					[61.077658461057801, 2.0101640189474845],
					[61.077658461853261, 2.0101640169105059],
					[61.078741856279045, 2.0086612263635937],
					[61.079944549053685, 2.0073251335694269],
					[61.079944550740137, 2.0073251313347269],
					[61.079944555004133, 2.0073251266678094],
					[61.079944556690592, 2.0073251244331076],
					[61.081272352737685, 2.0056390288849117],
					[61.082694492838151, 2.0040390978091436],
					[61.083072206972254, 2.0040251994199276],
					[61.083072224889058, 2.0040251973072496],
					[61.083072260531203, 2.0040251894021837],
					[61.083072278256566, 2.0040251836097989],
					[61.08395007841262, 2.0036529842865809],
					[61.083950088022767, 2.0036529784326902],
					[61.083950108229821, 2.0036529683671653],
					[61.083950116948941, 2.0036529627108939],
					[61.084575084929384, 2.0032001841059515],
					[61.085400026586939, 2.0030029992561285],
					[61.086155515842513, 2.0029780025654054],
					[61.087094254281048, 2.0039862365057424],
					[61.087094273154996, 2.0039862527935233],
					[61.08709431417995, 2.003986279058164],
					[61.087094336330978, 2.0039862890350388],
					[61.088263795309842, 2.0043807754497536],
					[61.088897064065002, 2.0049695464472386],
					[61.088897137550596, 2.0049695729600221],
					[61.088897282158918, 2.0049695632211475],
					[61.088897353377327, 2.0049695288097071],
					[61.089272352855879, 2.0045251298666793],
					[61.089272356228662, 2.0045251253955971],
					[61.089272361287847, 2.0045251186889588],
					[61.089272363769595, 2.0045251144155358],
					[61.089644564089092, 2.0039945155387549],
					[61.089644565679762, 2.0039945114629241],
					[61.089644568956814, 2.0039945051514785],
					[61.089644570643181, 2.0039945029158823],
					[61.089805770767398, 2.0037279033419928],
					[61.089805778116833, 2.0037278886810608],
					[61.089805791033577, 2.0037278597545622],
					[61.08980579462731, 2.0037278422039115],
					[61.089977994721508, 2.0029444439763076],
					[61.089977996407875, 2.0029444417406315],
					[61.089977996216405, 2.0029444380601324],
					[61.089977996216405, 2.0029444380601324],
					[61.090394596007215, 2.00079723870041],
					[61.090394593613162, 2.0007971926936738],
					[61.090394570113062, 2.0007971048335351],
					[61.090394548211734, 2.0007970650181757],
					[61.090214063990736, 2.0005998825644609],
					[61.089102975895521, 1.9985582046992334],
					[61.089102962271426, 1.9985581853866246],
					[61.089102930759587, 1.9985581514309234],
					[61.089102913762893, 1.9985581365899974],
					[61.086952949157435, 1.9970665600585897],
					[61.086700179876942, 1.9966443119301389],
					[61.085383484548338, 1.9933971244808431],
					[61.08538344701811, 1.9933970788421591],
					[61.085383351819083, 1.9933970162347674],
					[61.085383294150219, 1.9933969992660747],
					[61.084377914333857, 1.9934275950871059],
					[61.083558473885958, 1.9919860019020466],
					[61.083558472899057, 1.991986000260179],
					[61.083558471816268, 1.9919859967784799],
					[61.083558470829381, 1.9919859951366123],
					[61.082886270992546, 1.9908804954818367],
					[61.082886269018708, 1.9908804921982257],
					[61.082886264180019, 1.9908804858290146],
					[61.082886262206166, 1.9908804825454274],
					[61.08203076178981, 1.9896970825917164],
					[61.082030681201495, 1.9896970409478592],
					[61.082030518994458, 1.9896970416365742],
					[61.082030438266749, 1.989697083771113],
					[61.081455464007632, 1.9904942451996055],
					[61.08112210881707, 1.9906776224135976],
					[61.080330588247378, 1.9910497856684373],
					[61.079869465160186, 1.9908915116507084],
					[61.079869457840203, 1.9908915095552095],
					[61.07986944320016, 1.9908915053642506],
					[61.079869434989114, 1.9908915034667469],
					[61.079450055661418, 1.9908165069776642],
					[61.078861177339881, 1.9905776150187082],
					[61.078483378079071, 1.9904165163581951],
					[61.078483357901085, 1.9904165096763544],
					[61.078483316050722, 1.9904165022272355],
					[61.078483294282471, 1.9904164996204059],
					[61.078105593998231, 1.9904275995725218],
					[61.078105575286614, 1.9904276037298623],
					[61.0781055368765, 1.9904276104029881],
					[61.078105519151819, 1.9904276162018668],
					[61.077686127276451, 1.990613669220662],
					[61.076927921953455, 1.9900331403437981],
					[61.07692784082991, 1.9900331230136514],
					[61.076927692727423, 1.9900331521977699],
					[61.0769276259403, 1.9900332023909113],
					[61.076430426306167, 1.9909138027078255],
					[61.076430419657356, 1.9909138134884539],
					[61.076430409416353, 1.9909138418135699],
					[61.076430404645443, 1.9909138540377382],
					[61.076308105432588, 1.9914360538865097],
					[61.076308104733336, 1.9914360577632504],
					[61.076308104129907, 1.991436063479433],
					[61.076308103334767, 1.991436065516766],
					[61.076185903261788, 1.9921332641417944],
					[61.075947004841794, 1.9933527563775277],
					[61.075335911020289, 1.9958777288514007],
					[61.074919225699517, 1.9966721008165895],
					[61.074263744327084, 1.9977276725260391],
					[61.072638805780237, 1.9992526139972027],
					[61.072094363876957, 1.9993526038550693],
					[61.072094345260723, 1.9993526098453274],
					[61.072094309810375, 1.9993526214304733],
					[61.072094293167808, 1.9993526307037883],
					[61.070799910240396, 2.0001748199878406],
					[61.070044330221826, 2.000458113603794],
					[61.070044318733942, 2.0004581180119474],
					[61.070044297636002, 2.0004581282721037],
					[61.070044288025954, 2.0004581341241159],
					[61.068172169179022, 2.0017275788046534],
					[61.067458367452083, 2.0014915123555124],
					[61.06662507200442, 2.0011720137915145],
					[61.066625061024581, 2.0011720106457314],
					[61.066625039064967, 2.0011720043541321],
					[61.066625028085156, 2.0011720012083232],
					[61.066119459156759, 2.0010998052009241],
					[61.065613987238713, 2.0008554204534033],
					[61.065613984374103, 2.0008554173684656],
					[61.065613977054205, 2.0008554152713995],
					[61.065613973394278, 2.0008554142228459],
					[61.064388973237115, 2.0003748139438122],
					[61.064388946726467, 2.0003748068022471],
					[61.064388893292829, 2.0003748019104681],
					[61.064388866274143, 2.0003748023214953],
					[61.063763865925246, 2.0004831038285986],
					[61.063763827897354, 2.0004831178402447],
					[61.063763760818382, 2.0004831624726163],
					[61.06376373256267, 2.0004831910570817],
					[61.063013754193506, 2.0016304576637323],
					[61.062480486747418, 2.001994234208365],
					[61.062480473860198, 2.0019942463643927],
					[61.062480448976899, 2.0019942704788858],
					[61.062480437871812, 2.0019942822398717],
					[61.061519238007264, 2.0033220830304099],
					[61.061519232948029, 2.003322089731042],
					[61.061519224707375, 2.0033221045759961],
					[61.061519220634921, 2.0033221129177514],
					[61.060741520595208, 2.0048999122018087],
					[61.06074151741371, 2.0048999203457512],
					[61.060741510159609, 2.0048999368310536],
					[61.060741507073779, 2.0048999468135604],
					[61.060252611886696, 2.0066443296773477],
					[61.059791528755149, 2.0076109932317996],
					[61.058883209275052, 2.0087636924047754],
					[61.058086165622178, 2.0086998053869252],
					[61.057833408651163, 2.0085359318182876],
					[61.05783335197065, 2.0085359164743908],
					[61.057833237657739, 2.0085359194698937],
					[61.057833181903071, 2.0085359392531172],
					[61.057708220258426, 2.0086276103357474],
					[61.057080701609038, 2.008733080046746],
					[61.05682797197143, 2.0083081981739794],
					[61.056827966051273, 2.0083081883276255],
					[61.056827951537727, 2.0083081692267997],
					[61.05682794393104, 2.0083081616133858],
					[61.056022356873427, 2.0074665732016528],
					[61.055347366089279, 2.0064526897063284],
					[61.055347356317789, 2.0064526751353209],
					[61.055347331619707, 2.0064526508538383],
					[61.055347319270687, 2.006452638713101],
					[61.054672318811328, 2.005955439596657],
					[61.054672277278641, 2.0059554209037609],
					[61.054672190810301, 2.0059554047250665],
					[61.054672145874541, 2.0059554072392749],
					[61.053622145622093, 2.0062470071914791],
					[61.05362214116677, 2.0062470081781072],
					[61.05362212878758, 2.0062470127788039],
					[61.053622122645791, 2.0062470159982371],
					[61.053166709113093, 2.0064358795040165],
					[61.052364031721382, 2.0060276424725938],
					[61.051858460242727, 2.0053526795853047],
					[61.051858457378202, 2.005352676501218],
					[61.051858450662451, 2.0053526686923018],
					[61.051858447797954, 2.0053526656081888],
					[61.051475161043975, 2.0049331794271694],
					[61.051014071082669, 2.0041692961593918],
					[61.051014064271207, 2.0041692865129277],
					[61.051014048866072, 2.0041692676147398],
					[61.051014040176788, 2.0041692565249929],
					[61.050841839725919, 2.0039998568155601],
					[61.050841831227991, 2.0039998494019025],
					[61.050841809681103, 2.0039998337234417],
					[61.050841799401056, 2.0039998267045149],
					[61.050127976421294, 2.0035915701814835],
					[61.0497863081738, 2.002047214413754],
					[61.050358481557552, 2.0004722837133975],
					[61.050811271356231, 1.9996807033533528],
					[61.051147369353018, 1.9991529069295806],
					[61.051147379691947, 1.9991528804682004],
					[61.051147395818731, 1.9991528266949767],
					[61.051147399824522, 1.9991527997781491],
					[61.051139100350653, 1.9979416995737935],
					[61.051136300326007, 1.9975110983203272],
					[61.051136298764561, 1.9975110856520262],
					[61.051136294654874, 1.9975110586749685],
					[61.051136292106598, 1.9975110443662405],
					[61.050961291694463, 1.9969110446739866],
					[61.050700214383063, 1.9959667238495147],
					[61.05136407639835, 1.99473619564229],
					[61.051364084245314, 1.9947361213918076],
					[61.051364046572623, 1.994735986586599],
					[61.051364001148769, 1.9947359278698535],
					[61.050941800851454, 1.9944887278757972],
					[61.05094177504003, 1.9944887168679064],
					[61.050941722018315, 1.9944887025992311],
					[61.050941693916997, 1.9944886995360762],
					[61.050144394084164, 1.9945137000616195],
					[61.048508321970665, 1.9945664993874588],
					[61.047591798312403, 1.9943359180370726],
					[61.046866875301426, 1.9936692881058848],
					[61.046786291238057, 1.9934110394085549],
					[61.046786211091884, 1.9934109716723183],
					[61.046786033889731, 1.9934109440524665],
					[61.046785935942665, 1.9934109843664551],
					[61.046535945872634, 1.9937665713374377],
					[61.04583322207926, 1.9944775934364602],
					[61.044991818804739, 1.9944192083785934],
					[61.044441872172555, 1.9934859988472295],
					[61.044441838878164, 1.9934859653368402],
					[61.044441762363419, 1.9934859153879934],
					[61.044441717456834, 1.9934859011823036],
					[61.043688927388125, 1.993422001064767],
					[61.043311138385263, 1.993349803611536],
					[61.043311126610114, 1.9933498025053431],
					[61.043311104841905, 1.993349799897786],
					[61.043311093162551, 1.9933498006291075],
					[61.042716692713711, 1.993369200459812],
					[61.042716686380643, 1.9933692000055174],
					[61.042716672028199, 1.9933692013295903],
					[61.042716664899864, 1.9933692029103778],
					[61.042136065158552, 1.9934720034646367],
					[61.042136047337749, 1.9934720074164378],
					[61.042136011983636, 1.9934720208323584],
					[61.042135995341319, 1.9934720300989126],
					[61.041838795115325, 1.9936554302326843],
					[61.041838782037189, 1.9936554387087029],
					[61.041838758745627, 1.9936554587428355],
					[61.041838747641179, 1.9936554704985425],
					[61.041386020074356, 1.9941886848275099],
					[61.04092783092792, 1.9941165024929772],
					[61.040927805691325, 1.9941165025125909],
					[61.040927755409825, 1.9941165062265587],
					[61.040927729665448, 1.9941165137932328],
					[61.040427729905367, 1.9943026132680708],
					[61.040427712276163, 1.9943026208938148],
					[61.040427678291962, 1.9943026432971553],
					[61.040427661745419, 1.9943026544000479],
					[61.040049882616529, 1.9946609357007727],
					[61.039508287079173, 1.9949386822475381],
					[61.039086173151055, 1.994780414757416],
					[61.038497322843746, 1.9945387346873349],
					[61.037944674834065, 1.9938639203835973],
					[61.038533421785033, 1.9933279607748882],
					[61.038819506872727, 1.9931473686194188],
					[61.038819548329784, 1.9931473166816469],
					[61.0388195880236, 1.9931471963704823],
					[61.038819587151416, 1.9931471277995156],
					[61.038655786600593, 1.9927193275568482],
					[61.038655759351713, 1.9927192889954326],
					[61.03865569037707, 1.9927192280922108],
					[61.038655646869231, 1.9927192061455534],
					[61.038025046614422, 1.9925665057181681],
					[61.038025030288253, 1.9925665037630318],
					[61.038024994962775, 1.9925665004455335],
					[61.038024977841417, 1.9925665005251874],
					[61.036930663660399, 1.9926886930040926],
					[61.036341836395707, 1.9921887529328797],
					[61.036091849523125, 1.9919359662936873],
					[61.035755755801191, 1.9915165759541213],
					[61.035755695296189, 1.9915165391968803],
					[61.035755565194478, 1.9915165160148349],
					[61.035755494706763, 1.9915165297876221],
					[61.035166594894001, 1.9918776300732179],
					[61.035166586175315, 1.9918776357230559],
					[61.035166567147506, 1.9918776510919707],
					[61.035166557633588, 1.9918776587764397],
					[61.034541586845414, 1.9925054306554448],
					[61.033630524296278, 1.9928804146665642],
					[61.033630516372746, 1.9928804182813489],
					[61.033630502307773, 1.9928804251158343],
					[61.033630495371071, 1.9928804303700456],
					[61.032458220845633, 1.9936081144459268],
					[61.031702816263284, 1.9938025891684201],
					[61.030950122287301, 1.9934831342994719],
					[61.030522352974813, 1.9929776702464963],
					[61.030522317390613, 1.9929776446900223],
					[61.03052223709151, 1.9929776086070539],
					[61.030522193363446, 1.9929775997198136],
					[61.029941693203206, 1.9929970005704607],
					[61.029941607605906, 1.9929970529871199],
					[61.029941527278389, 1.9929972064242292],
					[61.02994153076606, 1.992997307839701],
					[61.030369220022237, 1.9936722899187438],
					[61.030658112942071, 1.9944417698882986],
					[61.030658120137311, 1.9944417868738458],
					[61.03065813691353, 1.9944418147421696],
					[61.0306581466861, 1.9944418292985291],
					[61.031294246610067, 1.9952001294709678],
					[61.031294254121548, 1.9952001352386382],
					[61.031294268445059, 1.9952001506450645],
					[61.031294275956562, 1.9952001564127111],
					[61.031924875679287, 1.9956973577689447],
					[61.031924880326095, 1.9956973604554513],
					[61.031924886850746, 1.9956973645839122],
					[61.031924890606483, 1.9956973674678651],
					[61.032433191177041, 1.9960279685934887],
					[61.032433246967457, 1.9960279841030388],
					[61.032433357715355, 1.9960279818606794],
					[61.032433413563879, 1.9960279639113321],
					[61.033352798761214, 1.9953919443643693],
					[61.03393876759651, 1.9958057522609758],
					[61.035041554274024, 1.9969807376196349],
					[61.035041588870783, 1.9969807615443556],
					[61.035041665221769, 1.9969807910834909],
					[61.03504170627653, 1.9969808005694267],
					[61.035797060474074, 1.9969585045586811],
					[61.036822010889189, 1.9998639674571952],
					[61.036822031611557, 1.9998640018928477],
					[61.036822085052343, 1.9998640588152197],
					[61.036822116879762, 1.9998640814992927],
					[61.037705389577319, 2.0002668239822361],
					[61.037552605953266, 2.0023443048369769],
					[61.03684711114267, 2.0029720117355576],
					[61.036127770117496, 2.0030804021491555],
					[61.036127760315871, 2.0030804043191397],
					[61.036127739917177, 2.0030804106935398],
					[61.036127730115545, 2.0030804128635267],
					[61.03512497369168, 2.0034580954867089],
					[61.034497223592332, 2.0033915004857921],
					[61.033488931879731, 2.0032498025322361],
					[61.031897265596726, 2.0029554087091048],
					[61.030338993292631, 2.0021387221056219],
					[61.030338989728421, 2.0021387228946219],
					[61.030338984986038, 2.0021387183704897],
					[61.030338983203933, 2.0021387187649915],
					[61.02962788292821, 2.0018165178998797],
					[61.029627818610564, 2.0018165117006568],
					[61.029627700991028, 2.0018165377382333],
					[61.029627646798119, 2.0018165701722808],
					[61.029255463436563, 2.0022581520081797],
					[61.029002785642504, 2.0024414638563415],
					[61.027825097133018, 2.0017859254795267],
					[61.027825074982104, 2.0017859155201245],
					[61.027825030172018, 2.0017859031451386],
					[61.027825006621754, 2.0017859009267998],
					[61.02648610735239, 2.0017414995826783],
					[61.026486095672951, 2.0017415003101164],
					[61.026486074096205, 2.0017415013705211],
					[61.026486063403539, 2.0017415037372883],
					[61.025936078362399, 2.0018442016195821],
					[61.025355592738599, 2.0018637005439119],
					[61.025355574821745, 2.0018637026517787],
					[61.025355540070514, 2.0018637103432915],
					[61.025355523140405, 2.0018637140904438],
					[61.024686023325046, 2.0021415140065231],
					[61.024686018965468, 2.0021415168290049],
					[61.024686011932666, 2.0021415202431352],
					[61.024686007573088, 2.0021415230656174],
					[61.023974973193098, 2.0025108882647462],
					[61.023644448823809, 2.0024359060838108],
					[61.022797266330805, 2.0022026107063944],
					[61.022041778102725, 2.0018831160337149],
					[61.022041737143979, 2.0018831083804653],
					[61.022041655196489, 2.0018831097980141],
					[61.022041614303525, 2.0018831207051213],
					[61.021083252293536, 2.0023415014739485],
					[61.02024719258835, 2.0023691999119562],
					[61.020247189024083, 2.0023692007006137],
					[61.020247180017819, 2.0023692008360126],
					[61.020247174671489, 2.0023692020189845],
					[61.01932504022674, 2.0024858929768148],
					[61.018741798040296, 2.0021581251280947],
					[61.018741742441868, 2.0021581132850272],
					[61.018741631980504, 2.0021581210101425],
					[61.018741578008594, 2.0021581403811943],
					[61.018274877890725, 2.0025165412025236],
					[61.018274845148532, 2.0025165874491475],
					[61.018274808052382, 2.002516688519818],
					[61.01827480468517, 2.0025167449827763],
				],
				[
					[61.013410901085329, 1.9377916975830993],
					[61.01341090390725, 1.9377917340954178],
					[61.013410906558057, 1.9377917502176512],
					[61.013577606663112, 1.9384222504136968],
					[61.013577610999192, 1.9384222643026812],
					[61.013577621260104, 1.938422288011727],
					[61.013577627281521, 1.938422299667337],
					[61.013794226811534, 1.9387973008889015],
					[61.013794264862447, 1.938797338822938],
					[61.013794355746235, 1.9387973872482827],
					[61.013794408675629, 1.9387973995751799],
					[61.014113909347785, 1.9387835007771963],
					[61.014113972723017, 1.9387834717719037],
					[61.014114068045309, 1.9387833817770872],
					[61.014114099004871, 1.9387833191508563],
					[61.01416409568504, 1.9382694483199787],
					[61.014375184817013, 1.9377500742057634],
					[61.01437519107553, 1.9377500560937926],
					[61.014375198053898, 1.9377500173921816],
					[61.01437519966462, 1.9377499966036118],
					[61.014369604424147, 1.9373642068058461],
					[61.014908190807965, 1.937597371901939],
					[61.015449833567828, 1.9380834194167604],
					[61.015666516261483, 1.9385861796190893],
					[61.015666534927028, 1.9385862088822725],
					[61.015666581672278, 1.9385862578777808],
					[61.01566660975196, 1.9385862776101128],
					[61.016363809789567, 1.9389390776620075],
					[61.016363833726246, 1.9389390871762775],
					[61.016363883886278, 1.9389390982651167],
					[61.016363909218683, 1.9389391000385987],
					[61.017222208604061, 1.9389002008328711],
					[61.017758307923209, 1.9388780003752377],
					[61.01775832494755, 1.9388779984315323],
					[61.017758356130422, 1.9388779914692402],
					[61.017758372167272, 1.938877987888648],
					[61.018505671867814, 1.9385890862684576],
					[61.018505681571582, 1.9385890822442999],
					[61.0185056999916, 1.9385890725590513],
					[61.018505708804462, 1.9385890687338243],
					[61.019141763175213, 1.9381751973896533],
					[61.019733288990089, 1.9381502011685288],
					[61.020374934298879, 1.9382501914416248],
					[61.02102210378667, 1.9386057744540581],
					[61.021022121390303, 1.9386057835259272],
					[61.021022157102294, 1.9386057941266555],
					[61.021022175307202, 1.9386057974915487],
					[61.021769362707659, 1.9387001969163435],
					[61.022202702722311, 1.9388084824564829],
					[61.023010965979445, 1.939541848262373],
					[61.023010972602158, 1.9395418542142435],
					[61.023010986738491, 1.9395418659190282],
					[61.023010995046548, 1.939541869636886],
					[61.02398047274913, 1.9401418563592658],
					[61.024680455121626, 1.9408779380173125],
					[61.024680473014705, 1.9408779526000293],
					[61.024680512171543, 1.9408779772973217],
					[61.024680532544366, 1.9408779876108324],
					[61.025324881334889, 1.9411084698363441],
					[61.026244246202488, 1.9422223262385141],
					[61.026244254896483, 1.9422223373026763],
					[61.02624427575175, 1.9422223567991044],
					[61.026244287022088, 1.942222365430252],
					[61.026947074428641, 1.9427029563606026],
					[61.027863737952089, 1.9435640220424566],
					[61.02824702220753, 1.9443167911257075],
					[61.028247025169811, 1.9443167960384806],
					[61.028247031190794, 1.9443168077004622],
					[61.028247034153075, 1.9443168126132369],
					[61.029002629970101, 1.9454390057261162],
					[61.029441527288398, 1.9461918017573572],
					[61.029441529166739, 1.9461918031962528],
					[61.029441531141536, 1.946191806471681],
					[61.029441532128942, 1.9461918081093845],
					[61.029930415233153, 1.9469417819799943],
					[61.030094205948139, 1.9475750493755262],
					[61.030094210090567, 1.9475750595997905],
					[61.03009421659344, 1.9475750804458964],
					[61.030094220735847, 1.9475750906701657],
					[61.030474821371996, 1.9483306900306883],
					[61.030474830258392, 1.9483307047711509],
					[61.03047484872927, 1.9483307303801272],
					[61.030474860192108, 1.9483307426877186],
					[61.030852640991554, 1.9487001237166512],
					[61.031505426858992, 1.9498279009351147],
					[61.031505430808437, 1.9498279074868283],
					[61.031505439598256, 1.9498279203915172],
					[61.031505445329614, 1.9498279265457752],
					[61.032210940005093, 1.9507001194001301],
					[61.032647032046661, 1.9513251072653328],
					[61.033241527033468, 1.9523279005071263],
					[61.033674826344914, 1.9530833990913852],
					[61.03416922643251, 1.9539611987010599],
					[61.034169226528867, 1.9539612005378795],
					[61.034169231272742, 1.9539612050551973],
					[61.034169232356376, 1.9539612085302143],
					[61.034819232386617, 1.9549640092594311],
					[61.034819311394166, 1.9549640548310261],
					[61.034819473092895, 1.9549640615276491],
					[61.034819555880382, 1.9549640244895659],
					[61.03513624406861, 1.954566840372953],
					[61.035933429002334, 1.9538918534823482],
					[61.035933449325604, 1.9538918285085518],
					[61.035933481760814, 1.953891776674944],
					[61.035933493872705, 1.9538917498151414],
					[61.036194583164132, 1.9528528913599057],
					[61.036511220310217, 1.9524557711328427],
					[61.036938963683205, 1.9523112899756139],
					[61.036938985668094, 1.9523112794971269],
					[61.036939026579532, 1.9523112517883119],
					[61.036939045602438, 1.9523112363949837],
					[61.037311224085073, 1.9519112618108423],
					[61.037577893848855, 1.9517695765797478],
					[61.037577924959578, 1.9517695510555835],
					[61.03757797403297, 1.9517694899302667],
					[61.037577991995612, 1.9517694543291213],
					[61.038097392371981, 1.9499472551589099],
					[61.038097393671862, 1.9499472455761691],
					[61.03809739814993, 1.9499472278501608],
					[61.038097399449804, 1.9499472182674176],
					[61.038191898004818, 1.9489139262121937],
					[61.038400194084687, 1.9477500535139209],
					[61.039183486676194, 1.9456583709419977],
					[61.03918348975764, 1.9456583609609566],
					[61.039183495824091, 1.9456583391617894],
					[61.039183498014616, 1.9456583293796477],
					[61.039436295589034, 1.9439722443091854],
					[61.039750191227846, 1.9429306587787505],
					[61.039750194309093, 1.9429306487972731],
					[61.039750197004317, 1.9429306314673871],
					[61.039750199291149, 1.942930623521995],
					[61.039897395342521, 1.9416361539924354],
					[61.040316881590279, 1.9407167833919068],
					[61.04031688854684, 1.9407167613919933],
					[61.040316898101821, 1.9407167202245634],
					[61.040316899712771, 1.9407166994189768],
					[61.040311299914237, 1.9399444127823533],
					[61.04045849691299, 1.9387778363792638],
					[61.040614092759853, 1.9381278527390708],
					[61.041186291353362, 1.9363000602751792],
					[61.04118629205113, 1.9363000564016732],
					[61.041186294337514, 1.936300048455466],
					[61.041186294938676, 1.9363000427447983],
					[61.041447394967392, 1.9351278436873529],
					[61.041447396459411, 1.9351278377774013],
					[61.041447398455922, 1.9351278243195187],
					[61.041447398960386, 1.935127816771625],
					[61.041491897866209, 1.9346111353591968],
					[61.041961292866148, 1.9329167537997793],
					[61.041961295753339, 1.9329167401422844],
					[61.041961298758345, 1.9329167115879435],
					[61.041961299863686, 1.9329166983290236],
					[61.041950200099734, 1.9316277981114955],
					[61.04193630017631, 1.9300832988436771],
					[61.041936300079627, 1.9300832970064854],
					[61.041930800096331, 1.9296972083043096],
					[61.041980798826891, 1.9291778197929541],
					[61.041980798730201, 1.9291778179557759],
					[61.041980799427733, 1.9291778140820139],
					[61.041980800221943, 1.929177812045449],
					[61.04212239869323, 1.9269806171762081],
					[61.042269599257693, 1.9255583218210757],
					[61.042269598967472, 1.9255583163095187],
					[61.042269599568165, 1.9255583105984853],
					[61.04226960036231, 1.925558308561798],
					[61.042314100180775, 1.9243944079839064],
					[61.04231405782803, 1.9243943226680016],
					[61.042313921079341, 1.9243942250339507],
					[61.04231382678018, 1.9243942145529838],
					[61.041938827473622, 1.9245415145699083],
					[61.041938815988587, 1.9245415190008541],
					[61.041938794102926, 1.924541531337558],
					[61.041938782714645, 1.9245415376056592],
					[61.040980483317284, 1.925233137317488],
					[61.040980472122463, 1.9252331472593502],
					[61.040980453199694, 1.9252331645079801],
					[61.040980443883427, 1.9252331758879595],
					[61.040449847152864, 1.9258998716650613],
					[61.039497051958868, 1.9269776658041364],
					[61.038860955082193, 1.9276498623405094],
					[61.03886095171196, 1.9276498668121442],
					[61.038860945765684, 1.9276498737189858],
					[61.038860943189654, 1.9276498761542276],
					[61.038333147836234, 1.9283192694839262],
					[61.037960970174716, 1.9287220468245736],
					[61.037322117758663, 1.9291387145082206],
					[61.03673604887458, 1.9292942071327746],
					[61.036736024122291, 1.9292942163882372],
					[61.036735978664801, 1.9292942432861269],
					[61.036735957959607, 1.929294260928589],
					[61.035938768973026, 1.9301026488948643],
					[61.035194286218612, 1.9306526350080688],
					[61.034766606565718, 1.9309276220074398],
					[61.034077723035921, 1.9312165168115678],
					[61.034077721157423, 1.9312165153733847],
					[61.034077719472236, 1.9312165176086498],
					[61.034077717690394, 1.9312165180071932],
					[61.033436052732227, 1.9315054011386403],
					[61.032955590985274, 1.9315275998724677],
					[61.032149991384564, 1.9315637000375079],
					[61.031722212032477, 1.9315830984868807],
					[61.031130633138247, 1.931483102794618],
					[61.031130623144819, 1.9314831013131317],
					[61.031130601472711, 1.9314831005852036],
					[61.031130590588369, 1.9314830993029353],
					[61.030649990993005, 1.9315053995326001],
					[61.02995559100227, 1.9315358994947536],
					[61.029366691185629, 1.9315636998278711],
					[61.029366645941934, 1.9315637136611179],
					[61.029366567111992, 1.9315637573015771],
					[61.029366532731565, 1.9315637891444326],
					[61.029102663060378, 1.9319609446302224],
					[61.028252748881577, 1.9323859024879837],
					[61.027927876061142, 1.9323997974317115],
					[61.026900138387411, 1.931422055704362],
					[61.026900131764378, 1.931422049753553],
					[61.026900117724011, 1.93142203988742],
					[61.026900111991885, 1.9314220337374],
					[61.026364012219567, 1.9310609345752388],
					[61.026363982646913, 1.9310609207508966],
					[61.026363922299637, 1.9310609045183387],
					[61.026363891428375, 1.9310609002738479],
					[61.025294391463397, 1.9311109003925777],
					[61.025294369093679, 1.9311109035363898],
					[61.025294327316985, 1.9311109147351102],
					[61.025294307019109, 1.9311109229891839],
					[61.024227729935831, 1.9316720103739304],
					[61.0235332731162, 1.9318331018298076],
					[61.022838890797374, 1.9318636994689145],
					[61.022838847238773, 1.9318637110638515],
					[61.022838770190447, 1.9318637542951458],
					[61.022838735809792, 1.9318637861306431],
					[61.02246929204054, 1.9323942044804843],
					[61.02172220621604, 1.9324276004690171],
					[61.020864073918723, 1.9323387161492873],
					[61.020805797744124, 1.9319582699891562],
					[61.020805765755661, 1.9319582102669053],
					[61.020805673924436, 1.9319581267695025],
					[61.020805612997464, 1.9319580995215673],
					[61.019680613070761, 1.9318830995923093],
					[61.019680607725228, 1.9318831007871125],
					[61.019680596840885, 1.9318830995049203],
					[61.019680590604416, 1.9318831008988588],
					[61.019305590561252, 1.9318997994302312],
					[61.019305579870149, 1.9318998018198033],
					[61.019305558391331, 1.9318998047630569],
					[61.019305546809328, 1.9318998073517539],
					[61.01818324708551, 1.9322081069654236],
					[61.018183211040714, 1.9322081243081302],
					[61.018183149621038, 1.9322081733256169],
					[61.018183124246093, 1.9322082050004301],
					[61.017972044608946, 1.9325998649339184],
					[61.016483181753983, 1.9336915399677572],
					[61.016483172243596, 1.9336915476645478],
					[61.01648315331942, 1.9336915648938835],
					[61.016483143905653, 1.9336915744263932],
					[61.015847055929875, 1.9344887604203067],
					[61.014727703660739, 1.9354331196493397],
					[61.014194344650555, 1.9355859087890377],
					[61.014194317321397, 1.9355859204643722],
					[61.014194265915378, 1.935585954231708],
					[61.014194243620373, 1.9355859759257015],
					[61.013877643579576, 1.9359831750917837],
					[61.013877636935, 1.9359831858614638],
					[61.013877625331105, 1.9359832051673171],
					[61.013877618686529, 1.935983215936999],
					[61.013510919148985, 1.9367638149059907],
					[61.013510912793997, 1.9367638311820921],
					[61.013510903744375, 1.9367638647740339],
					[61.013510900953136, 1.936763880254331],
					[61.013410901107328, 1.937791680864116],
					[61.013410901085329, 1.9377916975830993],
				],
			],
		},
		{
			fldNpdidField: "1854729",
			wlbNpdidWellbore: "2849",
			fldName: "KRISTIN",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6406/2-3",
			fldDiscoveryYear: "1997",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=1854729",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=1854729",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "HALTENBANKEN VEST UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=2849",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "1854695",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-02-17 00:00:00",
			fldMainSupplyBase: "Kristiansund",
			fldAreaGeometryWKT: [
				[
					[64.855935915277712, 6.4387861470361356],
					[64.855935929739374, 6.4387862047530016],
					[64.860285929633221, 6.4458084056238514],
					[64.860285929633221, 6.4458084056238514],
					[64.860285930565809, 6.4458084076461191],
					[64.864463731119287, 6.4523751052009795],
					[64.869010929826899, 6.4597390054662167],
					[64.869010938074283, 6.4597390152445771],
					[64.869010956433726, 6.4597390388481166],
					[64.869010965577488, 6.459739048541806],
					[64.872072065715656, 6.4625195474843089],
					[64.872072088412736, 6.4625195622361487],
					[64.87207213463131, 6.4625195874385568],
					[64.87207216080597, 6.4625195955270485],
					[64.873683213634351, 6.4628418874917655],
					[64.875130468954936, 6.463977950087159],
					[64.879083161840072, 6.4677612443961783],
					[64.879083161840072, 6.4677612443961783],
					[64.879083162772318, 6.4677612464206193],
					[64.881577663388128, 6.4700918461918731],
					[64.881577763423948, 6.4700918684624913],
					[64.881577928608365, 6.4700918148895781],
					[64.881577996446069, 6.4700917387929451],
					[64.883586296180269, 6.4596306392455913],
					[64.886311295234535, 6.4472778426217525],
					[64.889033463615448, 6.4349223841391616],
					[64.892041718617534, 6.4346334990912419],
					[64.892041778309434, 6.4346334722577438],
					[64.892041866671832, 6.4346333898533805],
					[64.892041897135002, 6.4346333341113038],
					[64.892955754017649, 6.4293474852199051],
					[64.897405520116422, 6.4312834835213275],
					[64.897405539981747, 6.4312834900763161],
					[64.897405582328645, 6.4312834987085683],
					[64.897405603017518, 6.4312835009573099],
					[64.900319403059854, 6.4312419009866364],
					[64.900319461067625, 6.4312418806399219],
					[64.900319554164568, 6.4312418125431936],
					[64.900319590113668, 6.4312417625969491],
					[64.901941846651084, 6.4263584923991175],
					[64.907658153402124, 6.426047407386088],
					[64.909649808350366, 6.4327139580014379],
					[64.90964984332156, 6.4327140075243543],
					[64.909649935235862, 6.432714079092209],
					[64.909649993038926, 6.4327140989401954],
					[64.911455592577525, 6.4327779991328784],
					[64.911455649798228, 6.4327779852003646],
					[64.911455744833972, 6.4327779253538999],
					[64.911455782648972, 6.4327778794399304],
					[64.91329188336293, 6.4285833791545128],
					[64.913291891597211, 6.4285833360655786],
					[64.91329188920615, 6.4285832495797433],
					[64.913291876788037, 6.4285832063545945],
					[64.910697376580643, 6.4236277062596381],
					[64.910697371019992, 6.4236276962193912],
					[64.910697358142428, 6.4236276784222719],
					[64.910697350789064, 6.4236276685541016],
					[64.905572403877997, 6.4177332297053384],
					[64.905805797080504, 6.4136889587896935],
					[64.907658393874044, 6.4104363408462195],
					[64.909372072825079, 6.4109807686468887],
					[64.913838733577009, 6.4176473115352159],
					[64.913838833089429, 6.4176473548360473],
					[64.913839012173796, 6.4176473270285692],
					[64.913839092605514, 6.4176472537224418],
					[64.915064057885786, 6.4132723787765205],
					[64.919272230105847, 6.4126279975029483],
					[64.919272285311649, 6.4126279710242917],
					[64.919272368319653, 6.4126278910928418],
					[64.919272396121869, 6.4126278376400467],
					[64.920375168087574, 6.4068445850872449],
					[64.921380449168581, 6.4067474146581311],
					[64.922997005727566, 6.4129778506313713],
					[64.922997057992347, 6.4129779132960083],
					[64.922997192080203, 6.4129779828802072],
					[64.922997274726228, 6.4129779854888502],
					[64.926086045993387, 6.4117363363941111],
					[64.932708118359869, 6.4189861987793888],
					[64.934249806410733, 6.4251500486676001],
					[64.934249812080893, 6.42515006505631],
					[64.934249825177318, 6.4251500955484513],
					[64.934249834432833, 6.425150111592866],
					[64.937935934381528, 6.4306751114550833],
					[64.93793593714345, 6.4306751154242656],
					[64.937935943600024, 6.4306751253901506],
					[64.937935948154632, 6.430675129187498],
					[64.94350814797086, 6.437219530080875],
					[64.943508216820135, 6.4372195658447842],
					[64.943508358842848, 6.4372195755552619],
					[64.943508432016202, 6.437219549501858],
					[64.947950131809193, 6.4333112489684323],
					[64.947950142419714, 6.4333112394811476],
					[64.94795016091534, 6.4333112186499326],
					[64.947950168764038, 6.4333112051917274],
					[64.949219470277967, 6.4312919642845046],
					[64.95144984017702, 6.4325001418295464],
					[64.955021961681169, 6.441636040079187],
					[64.953141570227757, 6.4432359473686898],
					[64.95314154825418, 6.4432359748834136],
					[64.953141514275529, 6.4432360353157963],
					[64.953141503166904, 6.4432360681479421],
					[64.952869202588204, 6.4449193685578807],
					[64.952869204136874, 6.4449194065360684],
					[64.952869220679361, 6.4449194812101158],
					[64.952869237429738, 6.4449195156203238],
					[64.96027483720961, 6.4553501160449613],
					[64.960274879091727, 6.4553501502016353],
					[64.960274974971895, 6.4553501919396128],
					[64.960275028933836, 6.4553501974056484],
					[64.964785990000948, 6.454686318725412],
					[64.966960923676993, 6.4587444936814542],
					[64.966960968391618, 6.4587445360596574],
					[64.966961074311016, 6.458744587462725],
					[64.96696113461941, 6.4587445965726937],
					[64.969766623453125, 6.4582419177722468],
					[64.973558149238457, 6.462625131476428],
					[64.973558165660904, 6.4626251468773033],
					[64.973558202915612, 6.4626251730215687],
					[64.97355822195513, 6.4626251839348949],
					[64.977694300309153, 6.4643640745082509],
					[64.981402680184672, 6.4671029608791804],
					[64.984441579763313, 6.4693918604836584],
					[64.984441624976483, 6.4693918795329823],
					[64.984441718593303, 6.4693918940034436],
					[64.984441766961126, 6.4693918873073972],
					[64.986886165053505, 6.4685168883017958],
					[64.98959714173489, 6.4676169308095233],
					[64.990710954116167, 6.4688001368150063],
					[64.990711019300718, 6.4688001688248873],
					[64.990711154150276, 6.4688001793936749],
					[64.990711222918904, 6.4688001580374204],
					[64.992594523061584, 6.4673334583352142],
					[64.99259453543101, 6.4673334465575678],
					[64.992594555688001, 6.4673334234267514],
					[64.992594566264714, 6.4673334118188928],
					[64.996425165697289, 6.4616390120234204],
					[65.000344542297469, 6.4558084463921954],
					[65.007002907073243, 6.4516140696004785],
					[65.007002928296373, 6.4516140505975219],
					[65.00700296618858, 6.4516140087810792],
					[65.007002981028776, 6.451613984019632],
					[65.00943076984808, 6.4463834086505463],
					[65.013163943077487, 6.4420030582679626],
					[65.016613797733882, 6.4440501715902334],
					[65.016613843773811, 6.444050186293282],
					[65.016613937211133, 6.4440501900915557],
					[65.016613983748428, 6.4440501813922548],
					[65.019200074688925, 6.4428501864251091],
					[65.021999850150181, 6.4418724633245352],
					[65.024299803865944, 6.4531722393604598],
					[65.026599786427738, 6.464472151983184],
					[65.024241540681572, 6.4675470786971081],
					[65.024241533690372, 6.4675470899788339],
					[65.024241519743939, 6.4675471146626533],
					[65.024241513685084, 6.4675471279797332],
					[65.022994214153925, 6.4707527266426847],
					[65.022994209027019, 6.4707527419937376],
					[65.022994202394457, 6.4707527744765141],
					[65.022994199992553, 6.4707527916931111],
					[65.022699800234392, 6.4771943912029508],
					[65.02269980055658, 6.4771944102849988],
					[65.022699808443519, 6.4771944520120792],
					[65.022699813247527, 6.477194470670729],
					[65.024155413166014, 6.4810889703422632],
					[65.024155504950286, 6.4810890360144136],
					[65.024155690820166, 6.4810890375947023],
					[65.024155784941556, 6.4810889756231616],
					[65.029361284645304, 6.4681889746433603],
					[65.034564000769535, 6.4552863850338529],
					[65.042219303621593, 6.4594862750838296],
					[65.049874904367329, 6.4636862757424707],
					[65.049874998628411, 6.4636862752818836],
					[65.049875147203366, 6.4636861952537856],
					[65.0498751988642, 6.463686118064369],
					[65.050214098080929, 6.459613930217257],
					[65.052650195247466, 6.4491556440361304],
					[65.052650196822896, 6.4491556311293001],
					[65.052650199077462, 6.4491556054013497],
					[65.052650199756556, 6.4491555925802553],
					[65.052272400079588, 6.4389666969871877],
					[65.052258499662088, 6.4320722104811923],
					[65.052689090762115, 6.4282250938278578],
					[65.054830740371216, 6.4261168438696297],
					[65.054830763950946, 6.4261168054477755],
					[65.054830793147332, 6.4261167282117775],
					[65.054830798690958, 6.4261166851522242],
					[65.054533498915987, 6.4221805845584683],
					[65.054533497836715, 6.4221805740318132],
					[65.05453349485505, 6.4221805573104325],
					[65.054533492879443, 6.4221805468704067],
					[65.053311293124949, 6.4176999467853779],
					[65.053311286557474, 6.4176999304124616],
					[65.053311271666558, 6.4176998999628152],
					[65.053311263306497, 6.4176998837634871],
					[65.049689063126294, 6.4126415831661765],
					[65.049689043936425, 6.412641563769526],
					[65.049689001148479, 6.4126415296555335],
					[65.049688977587167, 6.4126415170604458],
					[65.047616839223352, 6.4117637411853883],
					[65.046205781770183, 6.4093582144267858],
					[65.045464087767996, 6.4072888320755679],
					[65.045464075711138, 6.407288809866956],
					[65.045464047115843, 6.4072887658853057],
					[65.04546402882157, 6.4072887464084163],
					[65.044211249119257, 6.4062331632841047],
					[65.042536264034709, 6.4038109863392778],
					[65.042536255783688, 6.4038109765158753],
					[65.042536238385253, 6.4038109569562565],
					[65.042536228341618, 6.4038109473072717],
					[65.039766842284848, 6.4014970587953801],
					[65.037258453081876, 6.3985109710288128],
					[65.037258447593246, 6.3985109651893319],
					[65.037258438445562, 6.3985109554568398],
					[65.037258432956975, 6.3985109496173358],
					[65.029836234224035, 6.391938752287853],
					[65.025611241017543, 6.3880276578313469],
					[65.022275145627432, 6.3844804621469287],
					[65.02227513468624, 6.3844804526001901],
					[65.022275111944708, 6.3844804357145328],
					[65.02227509921093, 6.3844804263433685],
					[65.018416840882892, 6.3822748493639221],
					[65.016452967703941, 6.3792415911724518],
					[65.016452944813054, 6.3792415658165842],
					[65.016452892046871, 6.3792415264061928],
					[65.016452863030693, 6.3792415101441637],
					[65.013436206275017, 6.3782470245724721],
					[65.009822338615578, 6.3747304565569056],
					[65.009822323117817, 6.3747304432207734],
					[65.009822286819016, 6.3747304213149434],
					[65.009822267810605, 6.3747304125691322],
					[65.006588968115466, 6.3735554113098587],
					[65.006588919566497, 6.3735554075916916],
					[65.006588826426366, 6.3735554209907841],
					[65.006588780938955, 6.373555438196111],
					[65.001433213455314, 6.3773831154445135],
					[64.996638851915421, 6.3785665050888465],
					[64.996638810124082, 6.3785665282813664],
					[64.99663874114249, 6.3785665880862252],
					[64.996638713989455, 6.3785666268165766],
					[64.992308159433406, 6.3894304122950256],
					[64.990500152224556, 6.389608084089911],
					[64.988991893861524, 6.3835860505456079],
					[64.988991877993357, 6.3835860160404456],
					[64.988991834753548, 6.3835859566396174],
					[64.988991808278229, 6.3835859316562829],
					[64.987061294273332, 6.382352686073693],
					[64.986755799922435, 6.3764527910626363],
					[64.986755792119368, 6.3764527557733581],
					[64.986755765906452, 6.3764526947186182],
					[64.986755748318487, 6.3764526646308459],
					[64.983377948151826, 6.3727498658821791],
					[64.983377922645559, 6.3727498450605715],
					[64.983377867337879, 6.3727498144421801],
					[64.983377836602884, 6.3727498026164868],
					[64.980052837254874, 6.3721276028692122],
					[64.980052828254642, 6.3721276016328696],
					[64.980052809357829, 6.3721275992482251],
					[64.980052800394887, 6.3721276001285538],
					[64.975133300342009, 6.3721359007501182],
					[64.975133266319958, 6.3721359062108984],
					[64.975133202084777, 6.372135929478131],
					[64.97513317187159, 6.37213594728457],
					[64.969988845096537, 6.3764358840579867],
					[64.967569438784082, 6.3759609035942804],
					[64.967569424406037, 6.3759609028842661],
					[64.967569394753667, 6.375960901552074],
					[64.967569380375622, 6.3759609008420615],
					[64.963958279841265, 6.3763165006467446],
					[64.963958239656549, 6.3763165130606705],
					[64.963958168585009, 6.3763165560489163],
					[64.963958136764703, 6.3763165845956582],
					[64.960135987259875, 6.3816665153227987],
					[64.955327834181432, 6.3823747932775259],
					[64.952591790649009, 6.3809831215584962],
					[64.95259177526323, 6.3809831145900686],
					[64.952591742773322, 6.3809831050575214],
					[64.952591726565544, 6.3809831024057733],
					[64.943955626594374, 6.3798137029747872],
					[64.935322240229283, 6.3786442036478386],
					[64.928361152843991, 6.3767220069664026],
					[64.928361140221298, 6.3767220039686245],
					[64.928361112324197, 6.376722000348666],
					[64.928361097946123, 6.3767219996388302],
					[64.923036098364889, 6.3767691991884625],
					[64.923036083127684, 6.3767692006785133],
					[64.923036052690463, 6.3767692057708016],
					[64.923036038386726, 6.3767692092853876],
					[64.919941637736159, 6.3777804090638748],
					[64.919941611929147, 6.3777804221641103],
					[64.919941565804436, 6.3777804541747845],
					[64.919941544590472, 6.3777804731728409],
					[64.912569246227676, 6.3867804713020941],
					[64.908044290603797, 6.3921887184617674],
					[64.905941659422524, 6.3926276029698705],
					[64.905941635369757, 6.3926276137637785],
					[64.90594158995313, 6.3926276350904185],
					[64.905941567766888, 6.3926276499319519],
					[64.898327678736152, 6.3992776390416299],
					[64.894458237229927, 6.4017831021938711],
					[64.886424992106669, 6.4020859004841393],
					[64.886424969735245, 6.4020859047600052],
					[64.886424925925581, 6.4020859153344425],
					[64.886424905420469, 6.4020859236556831],
					[64.883241604598524, 6.4038109249543194],
					[64.883241593056624, 6.4038109324073194],
					[64.883241569972768, 6.4038109473133069],
					[64.883241560297122, 6.403810958811329],
					[64.878997060418726, 6.4079998574200001],
					[64.878997044578597, 6.4079998758460421],
					[64.8789970201058, 6.4079999141154413],
					[64.878997009717153, 6.4079999362404472],
					[64.873938726741699, 6.4231331851750912],
					[64.871860966765183, 6.424985950157553],
					[64.871860955331641, 6.4249859639229649],
					[64.87186093422072, 6.4249859891738659],
					[64.87186092372005, 6.4249860049616982],
					[64.86699707321263, 6.4339526135888851],
					[64.858880574580937, 6.4349637016564927],
					[64.85888053797575, 6.4349637135892621],
					[64.858880471294299, 6.4349637516073042],
					[64.858880442114412, 6.4349637776071367],
					[64.855935941990168, 6.4387859777657415],
					[64.855935921421249, 6.4387860345960171],
					[64.855935915277712, 6.4387861470361356],
				],
				[
					[64.819558100031301, 6.3286139043746283],
					[64.819633100407131, 6.3328222039016904],
					[64.819633102577569, 6.3328222247643842],
					[64.819633112258359, 6.3328222638535427],
					[64.819633118910232, 6.3328222842727344],
					[64.820566518574225, 6.3348361849718131],
					[64.821502619305036, 6.3368500840199795],
					[64.821502624909144, 6.3368500961135048],
					[64.821502639702558, 6.336850119946253],
					[64.821502647957814, 6.3368501296698971],
					[64.822363747605863, 6.3378529299565711],
					[64.822363777673758, 6.3378529543896205],
					[64.822363842923323, 6.3378529879948022],
					[64.822363879038974, 6.3378529991826378],
					[64.822930465705056, 6.3379140864389818],
					[64.823333100995654, 6.3387778456738442],
					[64.823369200047139, 6.3408833023766826],
					[64.823369203112762, 6.3408833231544657],
					[64.823369211932871, 6.340883364444732],
					[64.823369217687343, 6.3408833849571939],
					[64.82430260132486, 6.3429000458631686],
					[64.824341499875104, 6.3450028032760697],
					[64.824341512392095, 6.3450028505291147],
					[64.824341556022603, 6.3450029305542142],
					[64.824341588070041, 6.3450029653423661],
					[64.827066588205568, 6.3468418656253389],
					[64.829794287945717, 6.3486834663541716],
					[64.82979431961671, 6.3486834801052998],
					[64.82979438545965, 6.3486834968187971],
					[64.829794419631682, 6.3486834997811572],
					[64.831586119578589, 6.3485057993508036],
					[64.83248322174606, 6.3484169098490373],
					[64.834313754617881, 6.3503473387963716],
					[64.834313791854157, 6.3503473625485203],
					[64.834313874989434, 6.3503473923295948],
					[64.834313919095763, 6.3503473985348409],
					[64.836105497706512, 6.3501724101827905],
					[64.836797031660424, 6.3512529077187887],
					[64.836797058216064, 6.3512529367429575],
					[64.836797120027597, 6.3512529791720569],
					[64.836797155321023, 6.3512529946823619],
					[64.839727755303059, 6.3519223945777687],
					[64.839727770615227, 6.3519223972904983],
					[64.839727802957015, 6.351922398328476],
					[64.83972781912793, 6.3519223988474529],
					[64.840625018660504, 6.3518362998928097],
					[64.840625020453103, 6.3518362997165054],
					[64.84331382182144, 6.3515724087388232],
					[64.845144255115628, 6.3535001379027305],
					[64.845144272407836, 6.3535001509707945],
					[64.845144309681146, 6.3535001768425907],
					[64.845144331417345, 6.3535001873640446],
					[64.849663830870924, 6.355166888720988],
					[64.849663853390837, 6.3551668928382146],
					[64.84966389660066, 6.3551668991424464],
					[64.849663919941975, 6.3551668989588119],
					[64.850558247611872, 6.3550780052500402],
					[64.853285987839925, 6.3569195657294255],
					[64.853286002330549, 6.356919572747608],
					[64.853286030415518, 6.3569195868719826],
					[64.853286043972389, 6.3569195918715211],
					[64.856480543929507, 6.3578445920128566],
					[64.856480591545818, 6.3578445936687977],
					[64.856480682818784, 6.3578445762634717],
					[64.856480726438022, 6.3578445550952374],
					[64.857144525598528, 6.3573057558296133],
					[64.857144546735242, 6.3573057326469193],
					[64.857144580792209, 6.3573056786459388],
					[64.857144593749865, 6.3573056499346379],
					[64.857522393690886, 6.3558472505431984],
					[64.857522396080029, 6.3558472334228568],
					[64.857522399103161, 6.3558472014653926],
					[64.857522398840871, 6.355847186716316],
					[64.857341898745119, 6.3533360857890333],
					[64.857341895681188, 6.3533360649836306],
					[64.857341886043045, 6.3533360279395037],
					[64.857341877638817, 6.353336009770107],
					[64.856580777559358, 6.351830509639135],
					[64.856580773824078, 6.3518305015642804],
					[64.856580764598391, 6.3518304876979537],
					[64.856580760004348, 6.3518304818182401],
					[64.854033460271182, 6.3484054816175473],
					[64.854033449252597, 6.3484054679303332],
					[64.854033424601752, 6.3484054450343042],
					[64.854033411828155, 6.3484054336304521],
					[64.851305729685762, 6.3465637464881448],
					[64.849475154611994, 6.3446331732579768],
					[64.848458522864902, 6.3432027679989851],
					[64.848666889169593, 6.3426028652336504],
					[64.848666887030674, 6.342602795814452],
					[64.848666836333905, 6.3426026721091118],
					[64.848666786917462, 6.3426026200177468],
					[64.847739040568158, 6.3421526454274622],
					[64.845761272042438, 6.3388415969549365],
					[64.845761263787281, 6.3388415872220838],
					[64.845761249069596, 6.3388415675791654],
					[64.845761239918147, 6.3388415579349173],
					[64.843569540285699, 6.3366887574513449],
					[64.843569533002167, 6.3366887518430488],
					[64.843569520227717, 6.3366887404490742],
					[64.843569512047907, 6.3366887349294441],
					[64.84215011575084, 6.3357248369131813],
					[64.840858419719268, 6.3347498404688061],
					[64.840858407916471, 6.3347498331995062],
					[64.840858383414613, 6.3347498187496472],
					[64.840858369857031, 6.3347498137634553],
					[64.840188970387331, 6.3344998125600851],
					[64.840188874560184, 6.3344998262651488],
					[64.840188739873099, 6.3344999260820058],
					[64.840188700116883, 6.334500012282545],
					[64.840321973606848, 6.3364774141179421],
					[64.839525124663624, 6.335958142382597],
					[64.837633438089, 6.3341609551424751],
					[64.837633418068521, 6.3341609402511745],
					[64.837633375414242, 6.334160914945568],
					[64.837633352818202, 6.3341609066367139],
					[64.834908361131681, 6.3334165089931309],
					[64.830388991676443, 6.331755420707732],
					[64.827661211837508, 6.3299165343484827],
					[64.82493625789337, 6.3280776647752015],
					[64.824000180643125, 6.3260638155389426],
					[64.824000173207679, 6.3260638015201236],
					[64.82400015478953, 6.3260637759425897],
					[64.824000144665249, 6.3260637621905786],
					[64.822172344999416, 6.3241387623555125],
					[64.822172306824868, 6.3241387366347857],
					[64.822172224580925, 6.3241387068582737],
					[64.822172179577279, 6.3241387007872829],
					[64.821274979750299, 6.3242276005113158],
					[64.821274929823915, 6.3242276202237653],
					[64.821274846559035, 6.324227683296284],
					[64.82127481411689, 6.3242277265673374],
					[64.820416513785673, 6.3264193277935465],
					[64.819558113905728, 6.3286138271204786],
					[64.819558107972355, 6.328613846678329],
					[64.819558102341716, 6.3286138830681429],
					[64.819558100031301, 6.3286139043746283],
				],
				[
					[64.784624801986141, 6.5131889052744913],
					[64.784624803340463, 6.5131889325124455],
					[64.785085902621319, 6.5160361312127337],
					[64.785085904625234, 6.5160361436574892],
					[64.785085911251983, 6.5160361640963202],
					[64.785085914117161, 6.5160361743569553],
					[64.789374814406159, 6.5267278732385021],
					[64.789374821857862, 6.5267278893971286],
					[64.789374840276849, 6.5267279171818409],
					[64.789374850382785, 6.5267279309920703],
					[64.792985931194309, 6.5308501102499275],
					[64.793577619438523, 6.5321278840168278],
					[64.793577623164069, 6.5321278920981403],
					[64.793577630580231, 6.5321279061584407],
					[64.793577636098632, 6.5321279140757929],
					[64.797366536248063, 6.5375668144315364],
					[64.797366542662644, 6.5375668222692669],
					[64.797366558215955, 6.5375668398019391],
					[64.797366566458251, 6.5375668495786714],
					[64.801741566045067, 6.5415084494526106],
					[64.801741582389866, 6.5415084605997764],
					[64.801741615941083, 6.5415084807094557],
					[64.801741634940356, 6.5415084895085727],
					[64.805047134797775, 6.5426529881686326],
					[64.80504718069011, 6.5426529945193996],
					[64.805047270264126, 6.5426529821456638],
					[64.805047313084245, 6.5426529656060639],
					[64.806450014988528, 6.541691932743765],
					[64.807599874382475, 6.5422807608853084],
					[64.81113873502521, 6.5464084153920004],
					[64.811919225409369, 6.547816796868597],
					[64.811919234651683, 6.547816812883422],
					[64.811919256617827, 6.5478168382756605],
					[64.811919269411149, 6.5478168518606052],
					[64.813549857585485, 6.5492223410300685],
					[64.815783123404884, 6.5518307017059678],
					[64.817188710224556, 6.5560222649950246],
					[64.817188711121005, 6.5560222649137492],
					[64.817188712052101, 6.5560222669366741],
					[64.817188712086732, 6.5560222690408549],
					[64.819558112065906, 6.56256116899368],
					[64.819558113927869, 6.5625611730403017],
					[64.819558117686313, 6.5625611832379427],
					[64.819558119548276, 6.562561187284583],
					[64.822958120318788, 6.5694917879768662],
					[64.822958128627903, 6.5694918019818456],
					[64.822958147935509, 6.5694918297492286],
					[64.822958158899567, 6.5694918414069958],
					[64.826508158719136, 6.5730362419363413],
					[64.826508163270191, 6.573036245742518],
					[64.826508172372257, 6.5730362533548385],
					[64.826508176923312, 6.5730362571610161],
					[64.829835957288566, 6.5756307426902429],
					[64.833616540632264, 6.5806001201055722],
					[64.833616547044599, 6.5806001279639812],
					[64.833616560765705, 6.5806001436002663],
					[64.83361656807449, 6.5806001513781602],
					[64.836122068305286, 6.5827918515714554],
					[64.836122075579709, 6.5827918572449553],
					[64.836122091921482, 6.5827918684310296],
					[64.836122100092382, 6.5827918740240561],
					[64.839022094071538, 6.5844612692744517],
					[64.840274886641666, 6.5853140656773999],
					[64.840274973044046, 6.5853140790208666],
					[64.840275122205568, 6.5853140319002099],
					[64.840275185895294, 6.5853139734617363],
					[64.840672274262801, 6.5843114559785487],
					[64.847772016159112, 6.5914056983862928],
					[64.84843590380143, 6.5947278400353593],
					[64.848435909452917, 6.5947278564082294],
					[64.848435921652339, 6.5947278890738934],
					[64.848435929993215, 6.594727905206482],
					[64.849283130295547, 6.5961001048317822],
					[64.84928313491416, 6.5961001128585126],
					[64.849283145013757, 6.5961001267251476],
					[64.849283149598293, 6.5961001326451276],
					[64.85283874828184, 6.6001307301399468],
					[64.856610946990841, 6.6046251296043561],
					[64.856610947887319, 6.6046251295245542],
					[64.856610949714224, 6.6046251314722895],
					[64.856610950644637, 6.6046251334998249],
					[64.86100815061468, 6.6095501338142713],
					[64.861008195946567, 6.6095501614470331],
					[64.861008296098902, 6.6095501926517661],
					[64.861008349988992, 6.6095501941956849],
					[64.863869341037656, 6.608811321217126],
					[64.866588760144907, 6.6114723421123918],
					[64.866588765659159, 6.6114723500672739],
					[64.866588780172037, 6.6114723593341962],
					[64.866588785652411, 6.6114723651809317],
					[64.869691569172588, 6.6136140536070789],
					[64.872213751282899, 6.6162890335434321],
					[64.874510948206805, 6.6189862301110205],
					[64.874510951860188, 6.6189862340109968],
					[64.87451096192396, 6.6189862457903255],
					[64.874510967370355, 6.6189862495315239],
					[64.878472066757524, 6.622505749838024],
					[64.878472067654016, 6.622505749758739],
					[64.880966567696063, 6.6247029496084826],
					[64.88096657769205, 6.6247029571741898],
					[64.880966600373426, 6.6247029720679276],
					[64.880966611265904, 6.6247029795544137],
					[64.884286010781906, 6.6263445796753988],
					[64.88428603694814, 6.6263445879269867],
					[64.884286090076117, 6.6263445980223477],
					[64.884286117934366, 6.6263445997869459],
					[64.886338899969303, 6.6261641015942638],
					[64.889011057709283, 6.6264084960274978],
					[64.891285982616751, 6.627175171640654],
					[64.893169239209556, 6.6294279209915095],
					[64.895277632992787, 6.6326307094243493],
					[64.89527765218817, 6.6326307309797672],
					[64.895277694064134, 6.6326307674433487],
					[64.895277717641235, 6.632630782272483],
					[64.897972118403501, 6.6338445818199938],
					[64.897972159843093, 6.6338445908508765],
					[64.897972245109841, 6.6338445896800735],
					[64.897972287144043, 6.6338445796363192],
					[64.900413906160153, 6.6326613186292036],
					[64.903305523228724, 6.6338612845484572],
					[64.903305633295062, 6.6338612621658601],
					[64.903305763166188, 6.63386113022404],
					[64.903305782970975, 6.6338610206647983],
					[64.900675483078686, 6.6277978127275166],
					[64.902410979770011, 6.6290973598684708],
					[64.902411100963121, 6.6290973597380631],
					[64.902411254407383, 6.6290972426076138],
					[64.902411284865636, 6.6290971257658695],
					[64.900702985313245, 6.6248943253577481],
					[64.900702981592644, 6.6248943172315844],
					[64.900702976011743, 6.6248943050423179],
					[64.900702973221286, 6.6248942989476758],
					[64.899064655428575, 6.6220869972174921],
					[64.900677670564804, 6.6234445532733943],
					[64.900677705864382, 6.6234445712876786],
					[64.90067777895085, 6.6234445944133027],
					[64.900677817634175, 6.6234445994453139],
					[64.90313611781194, 6.6232251990429738],
					[64.903136190750303, 6.6232251566490978],
					[64.903136279407533, 6.6232250304179328],
					[64.903136293333489, 6.623224946739338],
					[64.901172392711118, 6.6161388468465461],
					[64.901172365039571, 6.6161388006842703],
					[64.90117229117476, 6.6161387290285703],
					[64.901172244118783, 6.6161387057255343],
					[64.898083393153755, 6.6154442150228769],
					[64.89454183113574, 6.6123748497677166],
					[64.894541820208829, 6.6123748401722429],
					[64.894541795733289, 6.6123748254409946],
					[64.894541783943779, 6.6123748180355202],
					[64.891022331012252, 6.6107498398985065],
					[64.888708461916352, 6.6075692811073861],
					[64.888708447301511, 6.6075692655055933],
					[64.888708413623434, 6.6075692368109902],
					[64.888708396387031, 6.607569225668386],
					[64.886436195816714, 6.6063220249578487],
					[64.88643616871903, 6.6063220146953334],
					[64.886436111970042, 6.6063220028485583],
					[64.886436083215173, 6.6063220011844708],
					[64.884375064674188, 6.6065025941211442],
					[64.882300134615718, 6.6047526527494664],
					[64.87958075623277, 6.6020970723132635],
					[64.877258480279721, 6.5984332119396818],
					[64.875550187746668, 6.5937499299871813],
					[64.875550161997722, 6.5937498921663211],
					[64.875550097257502, 6.593749830380955],
					[64.875550057403885, 6.5937498086054447],
					[64.87306119919333, 6.5930081216654894],
					[64.869527930579821, 6.5899387481747231],
					[64.869527918789203, 6.5899387407857777],
					[64.869527893346699, 6.5899387219519108],
					[64.869527878900882, 6.589938716912318],
					[64.865802903803768, 6.5883387269245839],
					[64.863091855148411, 6.5861665678205661],
					[64.860975173276856, 6.5824887990679413],
					[64.860975164072443, 6.5824887851199492],
					[64.860975143042822, 6.5824887616808914],
					[64.860975131149061, 6.5824887479745584],
					[64.857452965660869, 6.5794442785963598],
					[64.857402987786216, 6.5793249311556981],
					[64.855700191050829, 6.5737832401780141],
					[64.85570017715709, 6.5737832139965056],
					[64.855700140370658, 6.5737831603344974],
					[64.85570011754676, 6.5737831370684496],
					[64.854677961770776, 6.5730387700151107],
					[64.854019583592503, 6.5715193195282744],
					[64.854019575283559, 6.5715193055067154],
					[64.854019555976294, 6.5715192777062601],
					[64.854019545874436, 6.5715192638464881],
					[64.849414045552123, 6.5666220620110609],
					[64.849413957079577, 6.5666220320319848],
					[64.849413793236636, 6.5666220595025084],
					[64.84941371876269, 6.5666221168710708],
					[64.848519320782756, 6.56854969551107],
					[64.847108540167454, 6.567522126340914],
					[64.847558487811213, 6.5663056695556534],
					[64.84755849136225, 6.5663056122729788],
					[64.847558467192229, 6.5663055068638592],
					[64.847558439436582, 6.5663054566308325],
					[64.842550164298345, 6.5614470796563999],
					[64.839569578582783, 6.5554388103973507],
					[64.839569552928211, 6.5554387789809621],
					[64.839569491034965, 6.5554387276543462],
					[64.839569454796276, 6.5554387077441207],
					[64.835475100730662, 6.5542776201670323],
					[64.832739036804895, 6.5517137532959913],
					[64.832739035012025, 6.5517137534589391],
					[64.832739032253315, 6.5517137494925866],
					[64.832739031356894, 6.5517137495740583],
					[64.832305745077619, 6.5513387620447538],
					[64.829377972963812, 6.5476748952246124],
					[64.824686282993596, 6.5370027197628007],
					[64.824686277405647, 6.537002707625966],
					[64.824686262678881, 6.5370026857847661],
					[64.824686256194482, 6.5370026737298659],
					[64.820697355597972, 6.5320581742030486],
					[64.820697353770143, 6.5320581722627988],
					[64.820697351010921, 6.5320581683002139],
					[64.820697349183092, 6.5320581663599633],
					[64.816297348609766, 6.5271526657845325],
					[64.816297336746032, 6.527152654229103],
					[64.816297312192219, 6.5271526354086076],
					[64.816297297674282, 6.5271526262039323],
					[64.809664025254278, 6.5234220408054036],
					[64.80255294574296, 6.5158748621356111],
					[64.802552920291305, 6.5158748434172962],
					[64.802552865011563, 6.5158748127023731],
					[64.802552834322185, 6.5158748028912994],
					[64.795333392816261, 6.5146109138903308],
					[64.789694536114013, 6.5093470536304858],
					[64.789694514317816, 6.5093470388000698],
					[64.789694465417455, 6.5093470138394789],
					[64.789694439209711, 6.5093470036266003],
					[64.786397238580264, 6.5086859044063718],
					[64.786397172316072, 6.5086859147319194],
					[64.786397059318134, 6.5086859756798363],
					[64.786397014377201, 6.5086860261367647],
					[64.784624814129202, 6.513188826277406],
					[64.784624808276959, 6.5131888520743839],
					[64.784624801986141, 6.5131889052744913],
				],
			],
		},
		{
			fldNpdidField: "20460969",
			wlbNpdidWellbore: "476",
			fldName: "VALEMON",
			fldCurrentActivitySatus: "Producing",
			wlbName: "34/10-23",
			fldDiscoveryYear: "1985",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=20460969",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=20460969",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "VALEMON UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=476",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "18403644",
			fldDateUpdated: "2022-09-13 00:00:00",
			fldDateUpdatedMax: "2021-12-22 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[60.990727612551467, 2.2454778080366493],
					[60.990727616152839, 2.2454778796191186],
					[60.991438155705502, 2.2471376709489879],
					[60.990741854960909, 2.2462831740596614],
					[60.990741794611438, 2.2462831386938862],
					[60.990741667305535, 2.2462831157495642],
					[60.990741555222151, 2.2462831804612802],
					[60.990741511913086, 2.2462833047143462],
					[60.990741513638667, 2.2462833748416999],
					[60.994802614185289, 2.2564889738485467],
					[60.994802615169007, 2.256488975494451],
					[60.994802616152768, 2.2564889771403287],
					[60.994802616244968, 2.2564889789760523],
					[60.997113716029489, 2.26184727833733],
					[60.997113718980522, 2.2618472832758361],
					[60.997113723990992, 2.2618472933425835],
					[60.997113726050465, 2.2618472984708151],
					[60.999749826302455, 2.2664611985190817],
					[60.999749842747832, 2.2664612210005988],
					[60.999749881603279, 2.2664612591283433],
					[60.99974990499701, 2.2664612764209879],
					[61.001966585045764, 2.2676612649114394],
					[61.004824867623128, 2.2701807491702937],
					[61.004824885391031, 2.2701807620963796],
					[61.004824923417409, 2.2701807837072465],
					[61.004824942784381, 2.2701807925815491],
					[61.008116581335806, 2.2711584733211758],
					[61.009002634977634, 2.2723112131513954],
					[61.009360929022883, 2.2729029031967682],
					[61.009360931973561, 2.272902908138164],
					[61.009360937782908, 2.2729029161843548],
					[61.009360940825552, 2.2729029229623938],
					[61.010074841161114, 2.2738334218888325],
					[61.010074852595743, 2.2738334343085533],
					[61.010074877156136, 2.2738334569324041],
					[61.010074891173481, 2.2738334669470452],
					[61.011749891270838, 2.2749279669208611],
					[61.011749909837945, 2.2749279778259925],
					[61.011749950170575, 2.2749279915313352],
					[61.01174997122844, 2.274927998194558],
					[61.017266671075511, 2.2757334984427398],
					[61.017266717621119, 2.2757334922643118],
					[61.01726680622653, 2.2757334622944079],
					[61.017266846411246, 2.2757334370447664],
					[61.017944507303056, 2.2750140779293702],
					[61.019352852201663, 2.2746334923903708],
					[61.019352863792079, 2.2746334899266025],
					[61.019352885805397, 2.2746334796769463],
					[61.019352898195372, 2.2746334751864552],
					[61.020794443825686, 2.2738280043556385],
					[61.021938882582056, 2.2739251985549731],
					[61.021938889006975, 2.2739252009027622],
					[61.021938900689342, 2.2739252002758286],
					[61.021938907913885, 2.2739252005967132],
					[61.025952798746935, 2.2737723992648302],
					[61.02658888758284, 2.2738058004129615],
					[61.028352786307202, 2.2739279992406756],
					[61.028352790857035, 2.2739280001303457],
					[61.028352801647834, 2.273927999692742],
					[61.028352807089213, 2.2739280003928073],
					[61.029610871604348, 2.2738835075675876],
					[61.029485901613135, 2.2748582752373343],
					[61.029485928225299, 2.2748583568740153],
					[61.029486034703282, 2.2748584679610375],
					[61.029486113769572, 2.2748584994387566],
					[61.033519414250613, 2.2745668997390909],
					[61.033519430298838, 2.2745668963256964],
					[61.033519459720544, 2.2745668900678142],
					[61.033519474785187, 2.2745668850060308],
					[61.039055674968893, 2.2723279851544413],
					[61.039055682009355, 2.2723279817981927],
					[61.039055695198769, 2.2723279752754086],
					[61.039055701347657, 2.2723279721089034],
					[61.046744495898849, 2.2678251752684284],
					[61.049761150776611, 2.26627799980061],
					[61.054813906112031, 2.266122399362323],
					[61.054813959328598, 2.2661223824424432],
					[61.054814048240011, 2.2661223225956424],
					[61.054814083043247, 2.2661222798587719],
					[61.055741883093589, 2.2639833806711049],
					[61.055741884692374, 2.2639833766123307],
					[61.055741887889951, 2.2639833684948223],
					[61.055741888597233, 2.2639833646261489],
					[61.056783486709321, 2.2608972697923959],
					[61.058383485398672, 2.2570194764486677],
					[61.058383486904994, 2.257019470549829],
					[61.058383490809177, 2.257019458561806],
					[61.058383493207025, 2.2570194524726319],
					[61.059197393090983, 2.2539833512749023],
					[61.059197393890209, 2.2539833492450572],
					[61.059197395396431, 2.2539833433459169],
					[61.059197396195636, 2.2539833413160775],
					[61.060080795641369, 2.2498333424408243],
					[61.060080787859363, 2.2498332771724177],
					[61.06008073638953, 2.249833167323847],
					[61.060080690826325, 2.2498331212852793],
					[61.058869532473778, 2.2492137436369481],
					[61.057047365072457, 2.2468192866463954],
					[61.054830804007885, 2.2432110507583261],
					[61.055183500453246, 2.2380361142208458],
					[61.055183500268257, 2.2380361105425628],
					[61.055183499805736, 2.238036101346828],
					[61.055183499620746, 2.2380360976685241],
					[61.055097400046343, 2.2313999973903766],
					[61.055097398228952, 2.2313999791902259],
					[61.055097392012293, 2.2313999452021052],
					[61.055097386721528, 2.2313999296051303],
					[61.052444586699558, 2.2244610290735407],
					[61.052444495071825, 2.2244609632189252],
					[61.052444309296803, 2.2244609603080998],
					[61.052444216040996, 2.2244610230607393],
					[61.052072019781619, 2.2253499123669669],
					[61.047927640827346, 2.2328526728520055],
					[61.04506378093906, 2.2354054377224792],
					[61.042886023170652, 2.2367248128730512],
					[61.037147311983887, 2.2380969672002879],
					[61.035697381113735, 2.2350221142905888],
					[61.03569737324159, 2.2350221011126048],
					[61.035697355714284, 2.2350220751381857],
					[61.035697347043161, 2.2350220639889837],
					[61.030152947382533, 2.2290526632669687],
					[61.030152939880239, 2.2290526574433875],
					[61.030152925767162, 2.2290526456053521],
					[61.030152919156372, 2.229052639590885],
					[61.025039046770956, 2.2252804602846474],
					[61.019936267133382, 2.2175859888155771],
					[61.019936250958231, 2.2175859718574018],
					[61.019936215042172, 2.2175859387055312],
					[61.019936195393925, 2.2175859243488762],
					[61.015933394730709, 2.215424823131936],
					[61.015933361803143, 2.2154248134824122],
					[61.015933296411937, 2.2154248033672577],
					[61.015933261273858, 2.2154248034751403],
					[61.014974977309478, 2.2156137016552364],
					[61.012222231847289, 2.2157164990117257],
					[61.007080688280659, 2.2138387182891797],
					[61.005122340975561, 2.2126276526095743],
					[61.004350177118482, 2.2114971031045916],
					[61.003997384751585, 2.2106471229870555],
					[61.003997361407293, 2.2106470890180199],
					[61.00399730181109, 2.2106470331282635],
					[61.003997266450597, 2.2106470110163565],
					[61.003569478046359, 2.2104970144457163],
					[61.003547289417803, 2.2104859215788308],
					[61.003547286650637, 2.2104859203163612],
					[61.003547281116226, 2.2104859177914404],
					[61.003547278349068, 2.2104859165289672],
					[61.003475080346341, 2.2104554161147476],
					[61.003097282664953, 2.2102831174504978],
					[61.00309727900629, 2.210283116379252],
					[61.003097270704707, 2.2102831125919589],
					[61.003097267937548, 2.2102831113295203],
					[61.002988977237322, 2.2102442155253148],
					[61.002647284822821, 2.2100831191520172],
					[61.002647282947095, 2.2100831176984168],
					[61.002647279288432, 2.2100831166272221],
					[61.002647276521245, 2.2100831153648199],
					[61.002402881293222, 2.2099804170837531],
					[61.002197283849057, 2.2098859198686802],
					[61.001808384633236, 2.2097026193682079],
					[61.00180838275751, 2.2097026179146653],
					[61.001747294213807, 2.2096748229149807],
					[61.001589004998799, 2.2095776298370176],
					[61.001589004014491, 2.2095776281922901],
					[61.001589003123044, 2.2095776283834954],
					[61.001294521093733, 2.209399838290742],
					[61.001133435678739, 2.2092498536235849],
					[61.001133432818705, 2.209249850525401],
					[61.001133428082923, 2.2092498459737158],
					[61.0011334253157, 2.209249844711445],
					[61.00084453553535, 2.2090192521889835],
					[61.000527946805754, 2.2086776643797981],
					[61.000527943945698, 2.2086776612817101],
					[61.000527939209917, 2.2086776567302069],
					[61.000527937241301, 2.2086776534408932],
					[61.000391837255819, 2.2085498540429418],
					[61.000391836271525, 2.2085498523983009],
					[61.000391832612834, 2.2085498513273318],
					[61.000391831628555, 2.2085498496826919],
					[61.000280731759041, 2.2084526497209658],
					[61.000280729883286, 2.2084526482675644],
					[61.000280727023224, 2.2084526451695381],
					[61.000280725147469, 2.2084526437161349],
					[60.99994182540329, 2.2081804447502598],
					[60.999941821651802, 2.2081804418435107],
					[60.999941815133063, 2.208180437674617],
					[60.999941812273022, 2.2081804345766378],
					[60.999564011848534, 2.2079248331171661],
					[60.999564001764014, 2.2079248297133263],
					[60.999563982207803, 2.2079248172069454],
					[60.999563972123291, 2.2079248138030874],
					[60.999491775591878, 2.2078970147557593],
					[60.999238979767448, 2.2077859170683682],
					[60.999238977891686, 2.2077859156150503],
					[60.999238973341569, 2.2077859147354446],
					[60.999238970574389, 2.20778591347336],
					[60.999041776092184, 2.207710915151373],
					[60.998991822293434, 2.20768873541983],
					[60.997866850807441, 2.2063942693620007],
					[60.997866844102944, 2.2063942615222993],
					[60.997866829988176, 2.206394249705605],
					[60.997866823283672, 2.2063942418659019],
					[60.996016823011644, 2.2049498426944103],
					[60.996016815601372, 2.2049498387179427],
					[60.996016802470898, 2.2049498285468294],
					[60.996016795060626, 2.2049498245703623],
					[60.994197294618168, 2.2039637242092627],
					[60.994197186010382, 2.2039637326732038],
					[60.994197042211951, 2.2039638470268379],
					[60.994197006928445, 2.2039639510810347],
					[60.995808105579272, 2.2100722462102134],
					[60.997094201258676, 2.2158083281358083],
					[60.997283099546515, 2.218966711709716],
					[60.997283099639226, 2.2189667135454356],
					[60.997283100530709, 2.2189667133545248],
					[60.997283100623413, 2.2189667151902635],
					[60.997352600886096, 2.2199000156136277],
					[60.997352613808467, 2.2199000573806358],
					[60.997352658188753, 2.2199001332346047],
					[60.997352688755129, 2.2199001675124777],
					[60.99838034362817, 2.2205833702071835],
					[60.998219224266279, 2.2209166049732132],
					[60.998016528141157, 2.2212581971064536],
					[60.998016524054499, 2.2212582054038128],
					[60.99801651597388, 2.2212582238343068],
					[60.998016511887222, 2.2212582321316519],
					[60.997860912009713, 2.2216860326004335],
					[60.997860912009713, 2.2216860326004335],
					[60.997860911210893, 2.2216860346270555],
					[60.997860911210893, 2.2216860346270555],
					[60.997824810897342, 2.2217916346095796],
					[60.997824810990025, 2.2217916364453409],
					[60.997777611143412, 2.2219305357614778],
					[60.997777610251916, 2.2219305359523127],
					[60.997777610344599, 2.2219305377880914],
					[60.997644209645145, 2.2223416389410842],
					[60.997644209645145, 2.2223416389410842],
					[60.997555409529632, 2.222619337403513],
					[60.997463709584729, 2.2228916353378887],
					[60.997463709677369, 2.2228916371736371],
					[60.997335911770037, 2.2232749328063734],
					[60.99727761460062, 2.2234249254382523],
					[60.997224815908048, 2.2235499227462099],
					[60.997224814310378, 2.2235499267992442],
					[60.997224811022384, 2.2235499330695765],
					[60.997224810408852, 2.2235499387675817],
					[60.997088709705494, 2.2239638368687653],
					[60.996969210198742, 2.2243221363340941],
					[60.996969210291375, 2.2243221381698164],
					[60.996916509711419, 2.2244832372384269],
					[60.996916508299002, 2.2244832449628174],
					[60.996916503691203, 2.224483260793134],
					[60.996916503077614, 2.2244832664910539],
					[60.996910905057426, 2.2245166524608431],
					[60.996894208870415, 2.2245693393606518],
					[60.996894208963056, 2.2245693411963909],
					[60.996894208164193, 2.2245693432228633],
					[60.996894208164193, 2.2245693432228633],
					[60.996741508298946, 2.2250888434812244],
					[60.996741506701227, 2.2250888475341197],
					[60.996741506087609, 2.2250888532320148],
					[60.996741505381394, 2.2250888570941729],
					[60.996669205190337, 2.2254138559709027],
					[60.996599805178874, 2.2257193565452131],
					[60.996599802874897, 2.225719364460208],
					[60.996599800941361, 2.225719379718039],
					[60.996599799528873, 2.2257193874423167],
					[60.996563701070372, 2.2263388775138817],
					[60.99653590317304, 2.2265027674394924],
					[60.996535901853093, 2.2265027769994332],
					[60.996535900197301, 2.2265027977643301],
					[60.996535899861485, 2.2265028089692795],
					[60.996569199634123, 2.2272639032240424],
					[60.996566500415121, 2.2274721966910409],
					[60.996566499801425, 2.2274722023888422],
					[60.996566500171795, 2.2274722097317143],
					[60.996566501341043, 2.2274722150482091],
					[60.996619201292212, 2.2281861152955571],
					[60.996619201292212, 2.2281861152955571],
					[60.996619200585918, 2.2281861191576358],
					[60.996619200678481, 2.2281861209933562],
					[60.996660901329946, 2.2285833197788576],
					[60.996705401339952, 2.2291083175846813],
					[60.996705401432536, 2.2291083194204271],
					[60.996705400726199, 2.2291083232824991],
					[60.996705401802814, 2.229108326763336],
					[60.996794200800458, 2.2297667188963275],
					[60.996808100224378, 2.230030607460773],
					[60.996827600227846, 2.2307443945630308],
					[60.996810901351743, 2.2309555810610262],
					[60.996755402058731, 2.2315110684667654],
					[60.996674804639149, 2.2318832580787435],
					[60.99667480374768, 2.2318832582692809],
					[60.99667480374768, 2.2318832582692809],
					[60.99661920614605, 2.2321499496665389],
					[60.99649151317044, 2.2325610277370611],
					[60.99643311896304, 2.2326888154027582],
					[60.996372021197473, 2.2328138107411708],
					[60.99620262167906, 2.233147108199248],
					[60.996202621771573, 2.2331471100349618],
					[60.996202620880091, 2.2331471102254619],
					[60.996047031248317, 2.2334554897978491],
					[60.995958145216306, 2.2335637735458076],
					[60.995958145216306, 2.2335637735458076],
					[60.995958144324796, 2.2335637737362992],
					[60.995805443923132, 2.2337526741581533],
					[60.995805442232644, 2.233752676374813],
					[60.995805437253715, 2.2337526848604932],
					[60.995805434671716, 2.233752687267637],
					[60.995685934586767, 2.2339276864245829],
					[60.995602640627411, 2.2340470780503279],
					[60.995405446352002, 2.2342831711463598],
					[60.995405446444494, 2.234283172982034],
					[60.9954054446615, 2.2342831733629627],
					[60.995405443862481, 2.2342831753891028],
					[60.995272046252317, 2.2344498711551743],
					[60.995155447716094, 2.2345859709459179],
					[60.995155440954093, 2.234585979812203],
					[60.995155429213057, 2.2345859971638733],
					[60.995155423342538, 2.2345860058396894],
					[60.995130427704431, 2.2346331966135664],
					[60.9950887349761, 2.234694287134217],
					[60.995088730796077, 2.2346942935934391],
					[60.995088724925544, 2.2346943022692329],
					[60.995088721637046, 2.2346943085379989],
					[60.994894221820402, 2.2350749086752719],
					[60.994710922616392, 2.2354249075680457],
					[60.994710919420292, 2.2354249156723256],
					[60.994710912136654, 2.2354249320712798],
					[60.99471090894059, 2.2354249401755504],
					[60.99467200866529, 2.2355499405436712],
					[60.994672008757767, 2.2355499423793246],
					[60.994672007958734, 2.2355499444053848],
					[60.994672007159693, 2.2355499464314481],
					[60.994649808746743, 2.2356305400498089],
					[60.994485912457698, 2.2360916336288326],
					[60.994485910060604, 2.2360916397069346],
					[60.994485906956953, 2.2360916496467094],
					[60.994485905451384, 2.2360916555344179],
					[60.994477607049596, 2.2361277468541649],
					[60.994335910119247, 2.2365610373950835],
					[60.994335910211724, 2.2365610392307009],
					[60.994335909412719, 2.2365610412567087],
					[60.994335907722139, 2.2365610434731384],
					[60.994310909819156, 2.236644335412671],
					[60.994269212650899, 2.2367555302996469],
					[60.994269211145266, 2.2367555361872928],
					[60.994269207150104, 2.2367555463173154],
					[60.994269205644493, 2.2367555522049356],
					[60.994152605861892, 2.2372416521647622],
					[60.994091505648768, 2.237491652308421],
					[60.994002605692678, 2.2378555536957756],
					[60.993902605871256, 2.2382693528987212],
					[60.993902605164656, 2.2382693567602781],
					[60.993902603566525, 2.238269360812164],
					[60.993902602859912, 2.2382693646737049],
					[60.993874803048264, 2.2384221631609935],
					[60.993874803140699, 2.238422164996595],
					[60.993874803233155, 2.2384221668321911],
					[60.993874803233155, 2.2384221668321911],
					[60.993863703344061, 2.2384888668058713],
					[60.993863703436517, 2.2384888686414852],
					[60.993863701653495, 2.2384888690221558],
					[60.993833103318892, 2.2386832619157411],
					[60.993730404664618, 2.2391332543275664],
					[60.993680404571279, 2.2393499546675999],
					[60.993585905661291, 2.2397582507954437],
					[60.993441509615522, 2.2402805369845167],
					[60.993419213019244, 2.2403388289727459],
					[60.993419213111657, 2.2403388308083261],
					[60.993394217641061, 2.2404055151460058],
					[60.993183125640584, 2.2407805012399242],
					[60.993183124841465, 2.2407805032657775],
					[60.993183124042361, 2.2407805052916121],
					[60.993183123243277, 2.2407805073174449],
					[60.992952637683395, 2.2412165783463283],
					[60.992949861071359, 2.2412192562173434],
					[60.992949839278118, 2.2412192886972742],
					[60.992949807743287, 2.2412193603616579],
					[60.992949799784725, 2.2412193991656255],
					[60.992949799650304, 2.2412221709811622],
					[60.99278040785601, 2.2417971432075752],
					[60.992780405735814, 2.2417971547915929],
					[60.992780402202193, 2.2417971740982896],
					[60.992780400973558, 2.2417971854920671],
					[60.992755400750958, 2.2421443825678491],
					[60.992708101139478, 2.2425610761366181],
					[60.992708100709876, 2.2425610855045885],
					[60.992708099665919, 2.2425611005694317],
					[60.992708100127864, 2.2425611097471854],
					[60.99273039970619, 2.2430693952148397],
					[60.992699801031968, 2.2434555836296095],
					[60.992699799618435, 2.2434555913522023],
					[60.992699800265044, 2.2434556042010758],
					[60.992699799650623, 2.2434556098979188],
					[60.992727600211168, 2.2439944023527065],
					[60.992716499640906, 2.2443999922826552],
					[60.99271089957076, 2.2445221848694445],
					[60.992669201144871, 2.2449193764731432],
					[60.99263870235518, 2.2451610754494125],
					[60.9926387006645, 2.2451610776652595],
					[60.992638700941541, 2.2451610831719231],
					[60.992638701033904, 2.2451610850074744],
					[60.992588701117377, 2.2458443840806548],
					[60.992580401378632, 2.2459555858074531],
					[60.992530400793683, 2.246594380178621],
					[60.992513706074419, 2.2467360386255786],
					[60.992494223974127, 2.2467721046593003],
					[60.992494222375733, 2.2467721087105885],
					[60.99249421908663, 2.2467721149776265],
					[60.992494217395922, 2.2467721171933599],
					[60.992297079084743, 2.2472136802036458],
					[60.992083405209627, 2.2471831132143039],
					[60.991755754320884, 2.2467887711331258],
					[60.991755749678624, 2.2467887684125367],
					[60.991755742976352, 2.2467887605655705],
					[60.991755740117128, 2.2467887574647962],
					[60.991633444650049, 2.246669261338341],
					[60.991491857447365, 2.2465109765262756],
					[60.99118076402933, 2.2460665844194114],
					[60.991180763137791, 2.2460665846095171],
					[60.991180761170071, 2.2460665813187743],
					[60.991180761170071, 2.2460665813187743],
					[60.991044561346278, 2.2458831801014374],
					[60.991044560454725, 2.2458831802915467],
					[60.991044557595487, 2.2458831771909349],
					[60.991044557595487, 2.2458831771909349],
					[60.990727958272913, 2.245477676803032],
					[60.990727896939383, 2.2454776397934877],
					[60.990727767758031, 2.2454776153971916],
					[60.990727653984401, 2.2454776823273699],
					[60.990727612551467, 2.2454778080366493],
				],
				[
					[60.987410899829321, 2.3072972004445642],
					[60.987419199742035, 2.3088556014342752],
					[60.987419213003029, 2.3088556505684221],
					[60.987419260375439, 2.308855733304219],
					[60.987419294669813, 2.3088557705768737],
					[60.988502695183548, 2.3095223706299093],
					[60.988502713656587, 2.3095223797127935],
					[60.988502753094643, 2.3095223936425042],
					[60.988502773168008, 2.3095223986776507],
					[60.989799972686221, 2.3097001980994891],
					[60.989799995251566, 2.3097001988987089],
					[60.989800039216171, 2.3097001951785305],
					[60.989800060615501, 2.3097001906591341],
					[60.991394438962637, 2.3091918977116475],
					[60.992774937942201, 2.3090780051954938],
					[60.993991530930259, 2.3100334220059069],
					[60.994519208353843, 2.3114889558937484],
					[60.994738698797725, 2.3124583163011914],
					[60.994658101249669, 2.3139193804759453],
					[60.994327601857044, 2.3162638723790874],
					[60.994327601948427, 2.3162638742150268],
					[60.994327601239554, 2.3162638780750204],
					[60.994327601422327, 2.3162638817468619],
					[60.994163701368812, 2.3180193810084386],
					[60.99416369995096, 2.3180193887283389],
					[60.994163699698809, 2.3180194017678848],
					[60.99416369997288, 2.3180194072756355],
					[60.994213700443801, 2.3193806069097089],
					[60.994213717541825, 2.3193806608143341],
					[60.99421377963143, 2.3193807497538499],
					[60.994213823731322, 2.3193807849768153],
					[60.994908197003753, 2.3196640737869005],
					[60.996077654177114, 2.320622339719951],
					[60.997208114081964, 2.3221639834318175],
					[60.997738705048434, 2.3245889437183509],
					[60.997738710419505, 2.3245889611400994],
					[60.997738724728244, 2.3245889952317804],
					[60.997738734557601, 2.324589011713774],
					[60.998524835358936, 2.3257473115157499],
					[60.998524867591975, 2.3257473436864973],
					[60.99852494362878, 2.325747387035018],
					[60.998524988415454, 2.3257473998610743],
					[60.999819387625159, 2.3258280009647985],
					[60.999819391191807, 2.3258280002130634],
					[60.999819399308059, 2.325828000357935],
					[60.999819402874721, 2.3258279996062017],
					[61.000469403092552, 2.3258196004214398],
					[61.00046945016831, 2.3258195867880556],
					[61.000469534308053, 2.3258195393649479],
					[61.000469568697049, 2.3258195061390099],
					[61.001025169188175, 2.3249334063899196],
					[61.001025178266303, 2.3249333896315285],
					[61.001025191781714, 2.3249333533819247],
					[61.001025197201912, 2.3249333355390678],
					[61.0014890937994, 2.3223972486949851],
					[61.002505789819871, 2.3192694612722802],
					[61.002505793021086, 2.3192694531741167],
					[61.002505795856884, 2.3192694377303118],
					[61.002505798075127, 2.319269427983857],
					[61.002841888300154, 2.3169278964553133],
					[61.004475090249514, 2.3153501902774387],
					[61.00650277768294, 2.3151280044152771],
					[61.007324834879078, 2.3154084556108696],
					[61.007808106592911, 2.3171528534180825],
					[61.007808110707821, 2.3171528636853647],
					[61.007808118937596, 2.3171528842199098],
					[61.007808123944137, 2.3171528942989448],
					[61.00837762389434, 2.3182167947537797],
					[61.008377664610869, 2.3182168344158911],
					[61.008377762780562, 2.3182168879349856],
					[61.008377820050939, 2.3182168981184663],
					[61.009497219585207, 2.3181029996137208],
					[61.009497221368505, 2.3181029992372713],
					[61.00949722315179, 2.3181029988608248],
					[61.009497224043464, 2.3181029986725941],
					[61.010405584703754, 2.3179946032360887],
					[61.01144427818938, 2.3182723733164812],
					[61.012530421048453, 2.3198139927010852],
					[61.013191501313891, 2.3216556369391346],
					[61.01324419981588, 2.3239888954663876],
					[61.013122000855837, 2.3256443806723559],
					[61.012833105049168, 2.3280805476755537],
					[61.012280416843687, 2.3293527209546752],
					[61.012280411949746, 2.3293527312673099],
					[61.012280405019396, 2.3293527550027822],
					[61.01228040289169, 2.3293527665886251],
					[61.011985907099785, 2.3311082432178827],
					[61.011516517378439, 2.3321860197001421],
					[61.011516514885798, 2.3321860239376337],
					[61.011516513375973, 2.3321860298242449],
					[61.011516511592625, 2.3321860301999329],
					[61.011088718113953, 2.3333582157811947],
					[61.010363753729912, 2.334633151607032],
					[61.009633212401148, 2.3350331200739647],
					[61.008941692916821, 2.3353358845248979],
					[61.008164014585766, 2.335055428275314],
					[61.007552996199301, 2.3343832187526479],
					[61.007458500236581, 2.3327277972244782],
					[61.007533499923149, 2.3305861077642578],
					[61.007533499558349, 2.3305861004173152],
					[61.00753349981165, 2.3305860873722644],
					[61.007533498646353, 2.3305860820499209],
					[61.007350199243213, 2.3285443827529066],
					[61.007350176616008, 2.3285443262752574],
					[61.007350103831648, 2.3285442395371843],
					[61.007350055366601, 2.3285442070642137],
					[61.006702855284445, 2.3283609070384736],
					[61.006702851717783, 2.3283609077901319],
					[61.006702848059888, 2.3283609067051136],
					[61.006702846185306, 2.328360905244264],
					[61.005925046111884, 2.3281776052623049],
					[61.005924983330154, 2.3281776110697945],
					[61.005924873949191, 2.328177658247498],
					[61.00592482735, 2.3281776996177177],
					[61.005238739019816, 2.3293526787659302],
					[61.004763756336466, 2.3298470605044579],
					[61.004763746183613, 2.3298470737782289],
					[61.004763730153932, 2.3298470957133079],
					[61.004763721784428, 2.3298471086113577],
					[61.004210922395842, 2.330924909390359],
					[61.00421091910269, 2.3309249156513197],
					[61.004210911807178, 2.3309249320341126],
					[61.004210909405735, 2.3309249381072363],
					[61.003869209259278, 2.3319999391240338],
					[61.003869206239621, 2.3319999508943963],
					[61.003869201983697, 2.3319999740595336],
					[61.003869199855714, 2.3319999856421063],
					[61.003788699984831, 2.3331693860613405],
					[61.00378870034946, 2.3331693934074611],
					[61.003788700187101, 2.3331694082874819],
					[61.003788700551745, 2.3331694156336154],
					[61.003927600683134, 2.33491941612471],
					[61.003927600774261, 2.3349194179612569],
					[61.003927600865396, 2.3349194197978029],
					[61.003927600956537, 2.3349194216343467],
					[61.004066501304074, 2.3361833217350494],
					[61.004066503998622, 2.3361833397252854],
					[61.004066513663965, 2.3361833710942905],
					[61.004066519834126, 2.3361833864972654],
					[61.004719219881089, 2.3375361872408149],
					[61.004719228726145, 2.3375362020814636],
					[61.00471924900031, 2.3375362293632018],
					[61.004719259628786, 2.3375362438285388],
					[61.005888737236035, 2.3386862194551359],
					[61.006633105755853, 2.3401361598828707],
					[61.006905401128918, 2.3424694228803924],
					[61.006905402384767, 2.342469430039845],
					[61.00690540489645, 2.3424694443587888],
					[61.006905407044002, 2.3424694513307389],
					[61.007347000942119, 2.3441167299224386],
					[61.007399799826622, 2.3464527924033614],
					[61.007197001953188, 2.3486943640850675],
					[61.006858110381053, 2.3499666356571489],
					[61.006216527243417, 2.3515331921005047],
					[61.005702676732049, 2.3521220370328977],
					[61.004841749381278, 2.3525247565037635],
					[61.00423344841866, 2.3518526662536372],
					[61.004233444579107, 2.3518526614926936],
					[61.004233436990887, 2.35185265380745],
					[61.004233433151313, 2.3518526490465277],
					[61.003191832988982, 2.3509276494187215],
					[61.003191825582562, 2.3509276454070598],
					[61.003191810587815, 2.3509276337106493],
					[61.003191804073097, 2.3509276295117378],
					[61.002411203916864, 2.3504526292269867],
					[61.002411178130664, 2.3504526179416882],
					[61.002411125138579, 2.350452603091703],
					[61.002411097040948, 2.3504525997142691],
					[61.001591697062452, 2.3504637002012263],
					[61.001591656935517, 2.3504637086277649],
					[61.00159158294111, 2.3504637427220123],
					[61.001591549073645, 2.3504637683897216],
					[61.000902649042395, 2.3512526673120075],
					[61.000902634883303, 2.3512526906956737],
					[61.000902612006378, 2.3512527381760533],
					[61.000902603379416, 2.3512527641092187],
					[61.000527603459176, 2.3533027644407647],
					[61.000527601948399, 2.3533027703243503],
					[61.000527600801149, 2.3533027835536382],
					[61.000527600182075, 2.3533027892500602],
					[61.000408099705588, 2.3554471884192578],
					[61.000408100250723, 2.3554471994377981],
					[61.00040810125013, 2.3554472196384242],
					[61.000408101795252, 2.3554472306569467],
					[61.000633102414653, 2.3569028316909129],
					[61.000633104833938, 2.3569028441718451],
					[61.000633113239417, 2.3569028683854492],
					[61.00063311842473, 2.3569028821416071],
					[61.00137201792554, 2.3585472812527204],
					[61.001372028824271, 2.3585473012332616],
					[61.001372054898717, 2.3585473365862417],
					[61.001372070074453, 2.3585473519586735],
					[61.002155469538664, 2.3592168514291676],
					[61.002155513885214, 2.3592168736735242],
					[61.002155605856423, 2.359216893352222],
					[61.002155655355374, 2.3592168922490546],
					[61.003236155346549, 2.3589084919898911],
					[61.003236158913452, 2.3589084912417619],
					[61.003236166848176, 2.3589084877218771],
					[61.003236171306803, 2.3589084867867136],
					[61.004830671383644, 2.3583001876927772],
					[61.00483067575145, 2.3583001849207839],
					[61.004830687253033, 2.3583001806522814],
					[61.004830691620846, 2.3583001778802855],
					[61.006377892029136, 2.3575001776654818],
					[61.006377899072071, 2.3575001743319466],
					[61.006377913067148, 2.357500165828081],
					[61.006377920019276, 2.3575001606577679],
					[61.009433411214452, 2.3552168660140951],
					[61.01076948577942, 2.354419581296495],
					[61.013308367360246, 2.3535057894339109],
					[61.014777854343201, 2.3529973922241463],
					[61.016236068134539, 2.3526835118357394],
					[61.017752658841175, 2.3535362506718402],
					[61.018447032623392, 2.3545973109552287],
					[61.018447054405179, 2.3545973323724567],
					[61.018447102245581, 2.3545973705956258],
					[61.018447129195913, 2.3545973872142971],
					[61.019183225670233, 2.3548779863271396],
					[61.020091496741792, 2.3552529334112995],
					[61.020058099556444, 2.3568110952879038],
					[61.020058100101579, 2.356811106313264],
					[61.02005810208351, 2.3568111281767803],
					[61.020058104412058, 2.356811138827704],
					[61.020233098385283, 2.3576833093027791],
					[61.019947000591166, 2.3606055804305104],
					[61.019947000681974, 2.3606055822680765],
					[61.019947000681974, 2.3606055822680765],
					[61.019702601427447, 2.363430583764309],
					[61.019702599916272, 2.3634305896510592],
					[61.019702599750659, 2.3634306045386202],
					[61.01970260011371, 2.3634306118888668],
					[61.019844200228739, 2.3657639118790272],
					[61.019844200591685, 2.3657639192293582],
					[61.019844203100988, 2.3657639335560741],
					[61.019844204355664, 2.365763940719436],
					[61.020285903563085, 2.3678972403531122],
					[61.020285907855644, 2.3678972543064014],
					[61.020285918224303, 2.3678972818391322],
					[61.020285925192077, 2.3678972952316748],
					[61.021288703939291, 2.369730657898395],
					[61.021380401060902, 2.3709917149794659],
					[61.021380401151561, 2.3709917168171533],
					[61.021380401332877, 2.3709917204925284],
					[61.021380401423549, 2.3709917223302144],
					[61.021652597395914, 2.3734193810827122],
					[61.021358134413127, 2.3743970556126159],
					[61.020277733801251, 2.3748998101722361],
					[61.018986101155996, 2.3752108935777638],
					[61.017127858323505, 2.3747526072518466],
					[61.015830698969452, 2.3742887221259945],
					[61.014877961742492, 2.3735248742993695],
					[61.014308481763102, 2.3722693166620359],
					[61.01430847390403, 2.3722693034576752],
					[61.014308456311717, 2.3722692755851802],
					[61.014308444794992, 2.3722692612905125],
					[61.013308444539902, 2.3712137613533337],
					[61.013308410986618, 2.3712137386812655],
					[61.013308337380678, 2.3712137076918109],
					[61.013308297327981, 2.371213699374449],
					[61.012530597374067, 2.3712247990815087],
					[61.012530540664919, 2.3712248183827693],
					[61.012530448406075, 2.3712248841086621],
					[61.012530411964661, 2.3712249307200275],
					[61.012060911581678, 2.3724971305026856],
					[61.012060910251343, 2.3724971400619137],
					[61.012060904824793, 2.3724971579033687],
					[61.012060903403835, 2.3724971656254406],
					[61.011810903313723, 2.3739610653125682],
					[61.011810902073911, 2.3739610767088068],
					[61.011810900395375, 2.3739610974774692],
					[61.011810900047337, 2.3739611086870274],
					[61.011908099048966, 2.3761971743917436],
					[61.01135541232167, 2.3779582321409602],
					[61.010844231560178, 2.3792304820609149],
					[61.010288778197399, 2.3797248403173876],
					[61.00998598975054, 2.3799248329472356],
					[61.009985959715749, 2.3799248633563956],
					[61.00998591642211, 2.3799249355143579],
					[61.009985901289234, 2.3799249757990482],
					[61.009902600932442, 2.3806110769302546],
					[61.009902601385029, 2.3806110861155734],
					[61.009902600687752, 2.3806111085332726],
					[61.009902601230891, 2.3806111195556454],
					[61.010041499822428, 2.3819667123429236],
					[61.010088700264241, 2.3834277989161103],
					[61.010013699577691, 2.3851832902415011],
					[61.01001370182636, 2.3851833176114297],
					[61.010013713276891, 2.3851833671863703],
					[61.010013721677474, 2.3851833914148046],
					[61.01079980700073, 2.3867250616151177],
					[61.011158099034006, 2.3892500053673964],
					[61.0109109049815, 2.3919777324465947],
					[61.009585947673543, 2.3940442655078868],
					[61.00851097824755, 2.3949359403348538],
					[61.007949884315103, 2.3953359375327987],
					[61.0079498576631, 2.3953359635073106],
					[61.007949818368225, 2.3953360255246468],
					[61.007949803851396, 2.3953360601025731],
					[61.007572004243293, 2.3971916598010887],
					[61.007572002019401, 2.3971916695440116],
					[61.007572000337227, 2.3971916903088792],
					[61.007571999987178, 2.3971917015168089],
					[61.007580400330035, 2.398552800906153],
					[61.007580401142505, 2.398552817439116],
					[61.007580407226435, 2.3985528495752564],
					[61.007580410623994, 2.3985528637133693],
					[61.008235910917172, 2.4004889645995866],
					[61.008235915025878, 2.4004889748782041],
					[61.008235924044811, 2.4004889934125067],
					[61.008235929045263, 2.400489003505228],
					[61.008933109418457, 2.401644470485929],
					[61.009199799749005, 2.4031972178847094],
					[61.009208100229706, 2.4046582466314357],
					[61.008522026906959, 2.4058359989823059],
					[61.008522018800463, 2.4058360173735709],
					[61.008522006154671, 2.4058360534130609],
					[61.008522001705579, 2.405836072898373],
					[61.008316502204778, 2.4072999721333104],
					[61.008316501863618, 2.4073000018978719],
					[61.008316511883102, 2.4073000591984082],
					[61.008316521442083, 2.4073000887571672],
					[61.008791520851872, 2.4082639882402153],
					[61.008791543968101, 2.4082640186885671],
					[61.008791602325402, 2.4082640696371729],
					[61.008791635782842, 2.4082640905088248],
					[61.009616587225061, 2.4085418736476667],
					[61.010485924364495, 2.4095029047700112],
					[61.011055399404079, 2.4111472316705567],
					[61.011019200384659, 2.4127999955280361],
					[61.01101920101523, 2.4128000083887606],
					[61.011019204150116, 2.4128000355763093],
					[61.011019205672532, 2.4128000482514462],
					[61.011283105605372, 2.413841748440245],
					[61.011283110875105, 2.4138417640478615],
					[61.011283123018082, 2.4138417912174797],
					[61.0112831299814, 2.4138418046167405],
					[61.011935929963542, 2.414900105518758],
					[61.011935963068609, 2.4149001376087034],
					[61.011936040151966, 2.4149001846782721],
					[61.011936084130284, 2.4149001996578829],
					[61.012930532312758, 2.4149807946602224],
					[61.014058155371814, 2.4157390422728899],
					[61.015185932438257, 2.4174723097034416],
					[61.015185937257506, 2.4174723161260374],
					[61.01518594680595, 2.4174723271337735],
					[61.015185951625192, 2.4174723335563719],
					[61.016144239394173, 2.4185279208118367],
					[61.017188719672667, 2.4202583889703697],
					[61.018019213715121, 2.4223861731288205],
					[61.018019216750488, 2.4223861799234738],
					[61.018019222731212, 2.42238619167509],
					[61.018019225766587, 2.4223861984697423],
					[61.018455426360049, 2.4231556998040436],
					[61.01845545018513, 2.4231557264115278],
					[61.018455508357498, 2.4231557737269016],
					[61.018455541633038, 2.4231557909447017],
					[61.019966641813731, 2.4236168902706741],
					[61.019966645560977, 2.4236168932048958],
					[61.019966655551109, 2.423616894841695],
					[61.019966660992125, 2.4236168955674331],
					[61.020874960938059, 2.423797396304963],
					[61.020874986269348, 2.4237973984666699],
					[61.020875035680312, 2.4237973956239998],
					[61.020875060651846, 2.4237973904342689],
					[61.022469433151677, 2.4232834982226006],
					[61.022986103075297, 2.423275199609372],
					[61.02298614409991, 2.4232751910819719],
					[61.022986218024869, 2.4232751552924645],
					[61.022986251105181, 2.423275131706295],
					[61.024361227841503, 2.4216945579612945],
					[61.025263874936194, 2.4211919424823067],
					[61.026260937323471, 2.4220501236226317],
					[61.027833114567599, 2.425427875938984],
					[61.028749805051881, 2.4280389480039051],
					[61.029063692245209, 2.4301721571110133],
					[61.028163728753377, 2.4316470958464875],
					[61.028163727149334, 2.4316470998934836],
					[61.028163722959619, 2.4316471063343088],
					[61.028163721355597, 2.4316471103812987],
					[61.02718312560588, 2.4336081997150352],
					[61.026027632616319, 2.4353776909699656],
					[61.026027630120311, 2.4353776952015593],
					[61.026027624326268, 2.4353777056879764],
					[61.026027620938365, 2.4353777101046195],
					[61.025085920676091, 2.4372443092629865],
					[61.025085924451858, 2.4372444050318127],
					[61.025086008069579, 2.437244549225734],
					[61.025086088893111, 2.4372445993039857],
					[61.027722189064349, 2.4373919008975],
					[61.027722195307398, 2.4373918996023725],
					[61.02772220708114, 2.4373919008737421],
					[61.027722213324196, 2.4373918995786146],
					[61.029836113246162, 2.4372558000485434],
					[61.029836114138043, 2.437255799863502],
					[61.029836115921761, 2.437255799493427],
					[61.029836116813613, 2.4372557993083901],
					[61.031302816772602, 2.4371334995384681],
					[61.031302842546836, 2.4371334923334373],
					[61.031302893915758, 2.4371334742463562],
					[61.031302919420682, 2.437133461525808],
					[61.032250118718295, 2.4364362602456571],
					[61.032250130845256, 2.4364362503003085],
					[61.032250153135827, 2.4364362271026274],
					[61.032250163389293, 2.4364362156888579],
					[61.033019511956212, 2.4353529876250612],
					[61.033925024187006, 2.4352418997939553],
					[61.033925039348631, 2.435241896646815],
					[61.033925066816629, 2.4352418872306276],
					[61.033925080996568, 2.4352418824299651],
					[61.035080681352738, 2.4347334821974864],
					[61.035080704092039, 2.4347334681900148],
					[61.035080745643924, 2.4347334335607989],
					[61.035080763654477, 2.4347334149629338],
					[61.035897324665349, 2.4335529700236904],
					[61.036883324055133, 2.4332446015852329],
					[61.03800828605857, 2.4333224003280756],
					[61.038008321822559, 2.4333223947585383],
					[61.038008390225883, 2.4333223749806829],
					[61.038008421793691, 2.4333223572798022],
					[61.038911222165076, 2.4326251571553041],
					[61.038911236877382, 2.4326251448116105],
					[61.038911264158997, 2.4326251131389434],
					[61.03891127503438, 2.4326250960194011],
					[61.039555739174297, 2.4314473625059656],
					[61.040672188275117, 2.4310391183202222],
					[61.042360983163611, 2.4318862649639006],
					[61.043747060899122, 2.4332223442358445],
					[61.043747086057188, 2.4332223613044901],
					[61.043747137797624, 2.4332223877144208],
					[61.043747167055621, 2.4332223964999069],
					[61.044783267125155, 2.4333973962795832],
					[61.044783304050519, 2.4333973960410256],
					[61.04478337629066, 2.4333973810349612],
					[61.044783409821797, 2.4333973666379647],
					[61.045689010259409, 2.4328001672407331],
					[61.045689030053282, 2.4328001482646013],
					[61.045689065983822, 2.4328001092141278],
					[61.045689080157061, 2.4328000858317118],
					[61.0466279791194, 2.4308389906969681],
					[61.047611276781474, 2.428975092493685],
					[61.047611279367096, 2.4289750900980516],
					[61.047611281683075, 2.4289750821841301],
					[61.047611283376874, 2.4289750799738958],
					[61.048508482774039, 2.4269194803100538],
					[61.048508485269728, 2.426919476074707],
					[61.048508486783639, 2.426919470185374],
					[61.048508487585565, 2.4269194681604191],
					[61.049147383202353, 2.4251583818625226],
					[61.049872343822699, 2.4237862561028187],
					[61.051297211380067, 2.4231780133065812],
					[61.053405472818369, 2.4238223695612033],
					[61.054622011936431, 2.4256472791610402],
					[61.054844201897943, 2.4267111310451295],
					[61.055069201220427, 2.4287500224228333],
					[61.055069202292053, 2.4287500259170702],
					[61.055069202741571, 2.4287500351163516],
					[61.05506920381324, 2.4287500386105805],
					[61.055510903698611, 2.4309750384211006],
					[61.055510914144939, 2.4309750678451207],
					[61.055510942794619, 2.4309751195047999],
					[61.055510962069576, 2.4309751452348296],
					[61.056510961931089, 2.4319307450748546],
					[61.056510981558517, 2.4319307595811641],
					[61.05651102598484, 2.4319307838015103],
					[61.056511048999958, 2.4319307938863437],
					[61.057894285207908, 2.4322945765194635],
					[61.05850262008039, 2.4330611932690891],
					[61.058677608595261, 2.4336417587387453],
					[61.058677715560407, 2.4336418275865004],
					[61.058677911684683, 2.433641803553567],
					[61.058677999951868, 2.4336417108582662],
					[61.058752999848402, 2.4321806095818004],
					[61.058752999578829, 2.4321806040615455],
					[61.0587529999315, 2.4321805928356577],
					[61.058752998680234, 2.4321805856607246],
					[61.058525199376135, 2.4290777843163935],
					[61.058525199376135, 2.4290777843163935],
					[61.058389098771343, 2.4273305840767776],
					[61.0583890982318, 2.4273305730365236],
					[61.05838909367526, 2.4273305535381873],
					[61.058389092243857, 2.4273305426834701],
					[61.057902992340011, 2.4256888423571481],
					[61.057336296728089, 2.4237555615922903],
					[61.057105799436712, 2.4214277798595383],
					[61.056930798960451, 2.4197138794887976],
					[61.056930769856429, 2.4197138186376965],
					[61.056930679019345, 2.4197137297525333],
					[61.056930617286255, 2.4197137017184795],
					[61.054725016681395, 2.4195248003964744],
					[61.054724995097445, 2.4195248011742847],
					[61.054724950416045, 2.4195248086207704],
					[61.054724928120379, 2.419524813263906],
					[61.052872132747929, 2.4202359105955851],
					[61.051277767359288, 2.4207498002673451],
					[61.050327835672803, 2.4207636988036416],
					[61.048425073032547, 2.4200192142367287],
					[61.048425027630906, 2.4200192069649882],
					[61.048424938799705, 2.4200192143090642],
					[61.048424895460144, 2.4200192307643213],
					[61.046958602913818, 2.4209189805472549],
					[61.04729738681057, 2.4194556801458655],
					[61.048286265732436, 2.4179807112972211],
					[61.04828627081325, 2.4179807046646467],
					[61.04828627901118, 2.4179806880920558],
					[61.048286283110087, 2.417980679805785],
					[61.04901128014999, 2.4163167880510796],
					[61.049825166103467, 2.4148445131058587],
					[61.05089455386112, 2.4135612274910558],
					[61.05089456313047, 2.4135612144092127],
					[61.050894578903616, 2.4135611869635585],
					[61.050894586299215, 2.4135611724138881],
					[61.05191688326147, 2.4109222825755445],
					[61.052516877654085, 2.4097444905869176],
					[61.052516880950868, 2.4097444843240781],
					[61.052516886562387, 2.4097444701447257],
					[61.052516889859184, 2.4097444638818826],
					[61.053069576779002, 2.4080834002164471],
					[61.054188958050446, 2.406803036621044],
					[61.055230527735077, 2.4072057865891927],
					[61.055230535941732, 2.4072057885940898],
					[61.055230552445209, 2.407205794443652],
					[61.055230559760041, 2.4072057966346105],
					[61.055960989333222, 2.4073557806537602],
					[61.056830421283948, 2.4084417971245897],
					[61.057052609897518, 2.4091194618046625],
					[61.057052614005777, 2.4091194720999032],
					[61.057052622132133, 2.4091194908504927],
					[61.057052627042054, 2.4091194991198006],
					[61.057699827253586, 2.4102417995086896],
					[61.057699851171712, 2.4102418279773556],
					[61.05769990925883, 2.4102418734829056],
					[61.057699941644245, 2.4102418908917889],
					[61.058308134995606, 2.4104251585656016],
					[61.059097007076907, 2.4134250517020912],
					[61.059097008058863, 2.4134250533562054],
					[61.059097010202947, 2.4134250603444629],
					[61.059097011365139, 2.4134250656786298],
					[61.06019150768067, 2.4166139571766294],
					[61.060985905876713, 2.4198056480380092],
					[61.060985909182378, 2.4198056603618721],
					[61.060985918379231, 2.4198056826121381],
					[61.060985923378553, 2.4198056927242981],
					[61.061591522936546, 2.4209611926754997],
					[61.061591551671746, 2.4209612275878705],
					[61.06159162081763, 2.4209612782504331],
					[61.061591662210184, 2.4209612956551001],
					[61.062977762066339, 2.4212307967590658],
					[61.062977801666896, 2.4212307959476167],
					[61.062977879256053, 2.4212307797881629],
					[61.062977916262838, 2.4212307627856142],
					[61.064094515724662, 2.4204334627643775],
					[61.064094534714712, 2.4204334457949654],
					[61.064094567875557, 2.4204334054236059],
					[61.06409458213637, 2.420433383862004],
					[61.064908481955023, 2.4186722842905892],
					[61.064908484360323, 2.4186722782119552],
					[61.064908489170996, 2.4186722660546547],
					[61.064908490684495, 2.4186722601618276],
					[61.065461289813285, 2.4169139622865314],
					[61.066227977858354, 2.4146639976630127],
					[61.067169478107971, 2.4134835212436849],
					[61.068377712192145, 2.4137529849860866],
					[61.069460976013296, 2.4146112561505046],
					[61.069461031144272, 2.4146112781234019],
					[61.069461143261414, 2.414611286353141],
					[61.069461201139369, 2.4146112724240179],
					[61.070664001236864, 2.4139112728441168],
					[61.070664027180243, 2.4139112506976046],
					[61.070664069698303, 2.413911199061114],
					[61.070664087164737, 2.4139111693851412],
					[61.071175183856845, 2.4125417781643819],
					[61.071941880115745, 2.410975087812925],
					[61.071941880115745, 2.410975087812925],
					[61.071941880827211, 2.410975083945301],
					[61.071941881719049, 2.4109750837591997],
					[61.072966874411456, 2.4087251013734838],
					[61.074300072780119, 2.4068585420190654],
					[61.075766614871185, 2.4074168811972059],
					[61.076938801705317, 2.4080751749848743],
					[61.076938827397456, 2.4080751844991712],
					[61.076938876637335, 2.4080751965359779],
					[61.076938902950651, 2.4080752003409098],
					[61.077755603237975, 2.4080612996925859],
					[61.077755645062538, 2.408061289098955],
					[61.077755719963839, 2.40806125485893],
					[61.077755753751937, 2.4080612273441391],
					[61.078572354171726, 2.4070779273865237],
					[61.078572361746865, 2.4070779165045022],
					[61.07857237680691, 2.4070778928993257],
					[61.078572383399994, 2.4070778803624324],
					[61.079383476861082, 2.4052194943841432],
					[61.080925162246722, 2.40286401753356],
					[61.082730751966672, 2.4006029309833754],
					[61.084105718264553, 2.3991251667218489],
					[61.085483379755118, 2.3985195824882624],
					[61.085483440079003, 2.3985194898716555],
					[61.085483435413465, 2.398519302946565],
					[61.085483368821123, 2.3985192126942008],
					[61.084227869166078, 2.3980554112832797],
					[61.084227856410031, 2.3980554083707513],
					[61.084227828132285, 2.3980554012639401],
					[61.084227812791248, 2.3980554007525194],
					[61.083061113355178, 2.3979776005185585],
					[61.083061089988078, 2.3979776016860823],
					[61.083061044416681, 2.3979776093587044],
					[61.083061022212405, 2.3979776158637849],
					[61.082113822078412, 2.3983804154099899],
					[61.082113799343695, 2.3983804294663282],
					[61.082113757712406, 2.3983804623568408],
					[61.082113738815828, 2.3983804811909915],
					[61.08065813906731, 2.4003470815107169],
					[61.080658138175494, 2.400347081697185],
					[61.080658137464361, 2.4003470855661222],
					[61.080658135680778, 2.4003470859390483],
					[61.079841536373216, 2.4015248863674654],
					[61.079841533788112, 2.4015248887679288],
					[61.079841531293347, 2.4015248930095945],
					[61.079841528708258, 2.4015248954100583],
					[61.078941549229008, 2.4029942631284853],
					[61.077911005705914, 2.4037887196270176],
					[61.076619373249727, 2.4041969991722287],
					[61.074975020053039, 2.4041276001857654],
					[61.07389446027203, 2.4039526067122421],
					[61.072511213974074, 2.40329423334027],
					[61.071902937044108, 2.4027220543987187],
					[61.07190292767482, 2.4027220470583326],
					[61.071902908044386, 2.4027220325639176],
					[61.071902897783268, 2.4027220254098656],
					[61.07056130006719, 2.4019665834294512],
					[61.070555800266526, 2.4005111128250061],
					[61.07084739803723, 2.3983694264531445],
					[61.070847397946899, 2.3983694246124894],
					[61.070847398748363, 2.3983694225853882],
					[61.070847398658046, 2.3983694207447313],
					[61.070972398772646, 2.3971056196643175],
					[61.070972391964681, 2.3971055727395387],
					[61.070972356144424, 2.3971054853926814],
					[61.07097232722252, 2.3971054468112465],
					[61.070277937694421, 2.3965331552287754],
					[61.06975579590587, 2.3959610184502456],
					[61.06971130135932, 2.3949889264248041],
					[61.070264089382469, 2.3932306633850726],
					[61.071155784719686, 2.3907889775015261],
					[61.071841861643144, 2.3893223269369535],
					[61.073302862951486, 2.388133507143861],
					[61.074166550249075, 2.3882168866589155],
					[61.074602610049304, 2.389569460551082],
					[61.074602646831103, 2.3895695123627023],
					[61.074602743025338, 2.3895695814909739],
					[61.07460280341995, 2.3895696004617748],
					[61.075122202649325, 2.3895613000048677],
					[61.075122261144379, 2.3895612803175768],
					[61.075122354212077, 2.3895612124753565],
					[61.075122389586006, 2.3895611622928477],
					[61.075675189880322, 2.3879028630053059],
					[61.075675190591141, 2.3879028591367373],
					[61.075675193705806, 2.3879028491851404],
					[61.075675195308399, 2.3879028451297799],
					[61.076058484761319, 2.3862472870163733],
					[61.077341799613414, 2.3845751989058055],
					[61.078638902648095, 2.3845558006363023],
					[61.078638914241019, 2.3845557982066974],
					[61.078638936625566, 2.3845557953754692],
					[61.078638948308978, 2.3845557947869285],
					[61.080322247609928, 2.3841390938701204],
					[61.080322268029917, 2.3841390877298725],
					[61.080322305832382, 2.3841390686455242],
					[61.08032232419724, 2.3841390573556422],
					[61.081569523641569, 2.3831501562091986],
					[61.081569528009823, 2.3831501534332036],
					[61.081569535764004, 2.3831501462269364],
					[61.081569540041684, 2.3831501416097298],
					[61.08277234009217, 2.3819640420776889],
					[61.082772347846294, 2.3819640348708382],
					[61.082772362281609, 2.381964016961589],
					[61.082772368161656, 2.3819640082874489],
					[61.083716863935798, 2.3804918149551204],
					[61.084705722753895, 2.3792140674510991],
					[61.08690004423579, 2.3784057968808248],
					[61.088366717025892, 2.3782834992884956],
					[61.088366747254724, 2.3782834910840012],
					[61.088366804674692, 2.3782834678700655],
					[61.08836683168456, 2.3782834491774167],
					[61.089266832021252, 2.3774945493010278],
					[61.089266855721327, 2.3774945182770875],
					[61.0892668901839, 2.3774944496413899],
					[61.089266899963988, 2.3774944103751605],
					[61.089347400143822, 2.3760361109261372],
					[61.089347399962527, 2.3760361072428275],
					[61.089347399690567, 2.376036101717891],
					[61.089347399599916, 2.3760360998762433],
					[61.089344599815853, 2.3746778002446272],
					[61.089344592380009, 2.3746777590103361],
					[61.089344560927884, 2.3746776874661233],
					[61.089344537712684, 2.3746776551273032],
					[61.088344537857907, 2.3737220553578524],
					[61.088344501629138, 2.3737220331961328],
					[61.088344421871362, 2.373722005290809],
					[61.088344380216519, 2.3737220010142255],
					[61.087222203256637, 2.3738358994844662],
					[61.085883332690152, 2.3736637023467808],
					[61.085019575154043, 2.3734831320369962],
					[61.08479740037545, 2.3721277852420029],
					[61.084830799823571, 2.3703806104624339],
					[61.084994595796303, 2.3686306499620731],
					[61.086150184243841, 2.365897278865531],
					[61.086619582659765, 2.3648222802558685],
					[61.086619589686471, 2.3648222583123526],
					[61.086619598389554, 2.3648222155503764],
					[61.08661960006588, 2.3648221947319197],
					[61.086569600463491, 2.3632693947872725],
					[61.086569597681006, 2.3632693749065377],
					[61.086569588730761, 2.363269339578157],
					[61.086569584164828, 2.3632693200725265],
					[61.0857390937134, 2.3613388457778877],
					[61.085297400101659, 2.3583388863001882],
					[61.08532240026117, 2.354811142006509],
					[61.086047370847339, 2.3531501079579256],
					[61.087422350804047, 2.351577931513575],
					[61.087422361668288, 2.3515779143394608],
					[61.087422381613372, 2.3515778803670457],
					[61.087422389711534, 2.3515778619151524],
					[61.08793348995539, 2.3500167611612728],
					[61.087933493594832, 2.3500167436483617],
					[61.08793349899932, 2.3500167071569691],
					[61.087933499963668, 2.3500166902078696],
					[61.08783629997302, 2.348074989224826],
					[61.087836298262019, 2.3480749728397061],
					[61.08783629038161, 2.3480749410094188],
					[61.08783628510389, 2.3480749253762627],
					[61.087444604775804, 2.3471110741470174],
					[61.087697397603883, 2.3451667256471183],
					[61.087697398840838, 2.3451667142223553],
					[61.08769739971352, 2.345166695431852],
					[61.087697399167084, 2.3451666843832135],
					[61.087558501784052, 2.3434194154078338],
					[61.088150194842733, 2.3408889457954531],
					[61.088150194751599, 2.3408889439539906],
					[61.088150196443834, 2.340888941736158],
					[61.088150196261573, 2.340888938053268],
					[61.088616891875546, 2.3385528601044729],
					[61.089386277767822, 2.3367944913527881],
					[61.089855771874745, 2.3360112028766435],
					[61.089855783081369, 2.3360111744584366],
					[61.089855797196059, 2.3360111137924919],
					[61.089855799212401, 2.3360110817331297],
					[61.089716899296519, 2.3344610813848314],
					[61.089716890338813, 2.334461046061624],
					[61.089716859322024, 2.3344609837655916],
					[61.089716838063374, 2.3344609547628283],
					[61.088202937578657, 2.3330276542538222],
					[61.088202931954932, 2.3330276498600471],
					[61.088202919724615, 2.3330276394195044],
					[61.088202914192152, 2.3330276368671585],
					[61.0872473142445, 2.3323609355099215],
					[61.0872473142445, 2.3323609355099215],
					[61.087247312369932, 2.3323609340454134],
					[61.08724731138701, 2.3323609323924832],
					[61.085819536901191, 2.3314109507503846],
					[61.084386264321502, 2.3295859852152736],
					[61.083252970077524, 2.3277581941778274],
					[61.083252933202886, 2.3277581591846981],
					[61.083252847083081, 2.3277581122785618],
					[61.083252796946269, 2.3277581005540915],
					[61.08191393941555, 2.3277775991875513],
					[61.079444521285581, 2.3267442334580579],
					[61.077883452142608, 2.3249192696345733],
					[61.07788344741035, 2.324919265055005],
					[61.077883436962786, 2.3249192542436696],
					[61.0778834322305, 2.3249192496641045],
					[61.077233648027679, 2.3243472391965367],
					[61.079086178089142, 2.3233501852959209],
					[61.081027860222797, 2.3227418902845911],
					[61.08102787172286, 2.322741885991189],
					[61.081027896414945, 2.3227418751861015],
					[61.08102790773227, 2.3227418672107749],
					[61.082877863579249, 2.3215529956875502],
					[61.084908243099953, 2.3214280043318434],
					[61.086685983010547, 2.3226640615808796],
					[61.08820268008931, 2.3238084602213456],
					[61.088202760290379, 2.3238084786042879],
					[61.088202908304247, 2.3238084472842497],
					[61.088202974333811, 2.3238083979586075],
					[61.08858907402891, 2.3231222983470246],
					[61.088589079722205, 2.3231222859777123],
					[61.088589091017468, 2.3231222593976688],
					[61.088589094836124, 2.3231222455643241],
					[61.089222395098417, 2.3203972451530706],
					[61.089222395007006, 2.3203972433116409],
					[61.089222395715815, 2.3203972394399899],
					[61.089222395624404, 2.3203972375985606],
					[61.089519595143734, 2.3188417431780248],
					[61.089941894132046, 2.3171833485945412],
					[61.089941889239348, 2.3171832863625434],
					[61.089941844154289, 2.3171831768194289],
					[61.089941803961899, 2.3171831295082845],
					[61.089164004223477, 2.3167109297605548],
					[61.089163981200905, 2.316710919751285],
					[61.089163933555326, 2.3167109037933313],
					[61.089163909023867, 2.3167108996860599],
					[61.087477849193725, 2.3166331025910241],
					[61.084880685335975, 2.3154081188427456],
					[61.084880681677966, 2.3154081177571109],
					[61.084880675253565, 2.3154081153969921],
					[61.084880670612435, 2.3154081126591031],
					[61.082672270873523, 2.3145637122286464],
					[61.082672268198664, 2.3145637127953158],
					[61.082672259899496, 2.3145637089722659],
					[61.082672256241445, 2.3145637078868124],
					[61.080772255731169, 2.3140054086595683],
					[61.080772222191811, 2.3140054046030976],
					[61.080772153878776, 2.31400540791345],
					[61.080772119996709, 2.3140054150913847],
					[61.078919320372826, 2.3148081158351328],
					[61.078919315114504, 2.314808118809077],
					[61.078919303706208, 2.3148081249458237],
					[61.078919299339496, 2.314808127730918],
					[61.076544307626456, 2.3162026221221033],
					[61.075425034350978, 2.3167025655232605],
					[61.074864080105478, 2.3162192902225605],
					[61.07428629481236, 2.3137055558200941],
					[61.074286291588528, 2.3137055453434057],
					[61.074286286215489, 2.3137055278822567],
					[61.07428628210004, 2.3137055175944155],
					[61.07324188151668, 2.311388817218277],
					[61.073241876417896, 2.3113888052795089],
					[61.073241860870603, 2.3113887825354436],
					[61.073241853096924, 2.3113887711634171],
					[61.071939080546798, 2.3098527034521212],
					[61.071322394918539, 2.3076277535785477],
					[61.071055795802522, 2.3062693605776214],
					[61.07105578434615, 2.3062693295311609],
					[61.071055750917154, 2.3062692733872932],
					[61.071055728852926, 2.3062692464495567],
					[61.070491846851461, 2.3057915633330435],
					[61.069927960961898, 2.3050220821351308],
					[61.069927957029023, 2.305022075530347],
					[61.069927947471612, 2.3050220645391852],
					[61.069927941755509, 2.3050220583125256],
					[61.068972341704225, 2.3040637595412528],
					[61.06897233313002, 2.3040637502018],
					[61.068972316256392, 2.3040637370435024],
					[61.068972306082166, 2.3040637317625992],
					[61.068366809317972, 2.3036831325934481],
					[61.067802955895608, 2.3033026636492107],
					[61.06680017890092, 2.3012777103603619],
					[61.066800177917685, 2.3012777087094403],
					[61.066800174967867, 2.3012777037566878],
					[61.066800173001361, 2.3012777004548561],
					[61.06562517668884, 2.299252705458283],
					[61.065014080706803, 2.2979971147353928],
					[61.064358481921431, 2.2965499164729595],
					[61.064358463421854, 2.2965498887905267],
					[61.064358415906895, 2.2965498393765236],
					[61.06435838787484, 2.2965498192955924],
					[61.063191796637938, 2.2959804255174134],
					[61.062580791135296, 2.2955998827618522],
					[61.062489098899384, 2.2945332827316318],
					[61.062489088948489, 2.2945332457998679],
					[61.062489054072962, 2.2945331788339325],
					[61.062489030039934, 2.2945331486105016],
					[61.061711263595924, 2.2938637776841708],
					[61.061191897112046, 2.29270275039099],
					[61.061055799549123, 2.2910527902329902],
					[61.061047399575628, 2.2895000042093168],
					[61.061127999980229, 2.28804171176981],
					[61.061127999704752, 2.2880417062507594],
					[61.061128000137195, 2.288041696862892],
					[61.061127999769909, 2.2880416895041824],
					[61.061078000255414, 2.2870055896392878],
					[61.061077980376389, 2.2870055343702242],
					[61.061077911039177, 2.2870054449923849],
					[61.061077864255722, 2.28700541031515],
					[61.059866764141631, 2.2865998108155239],
					[61.059866729618449, 2.2865998051393372],
					[61.059866663889785, 2.2865998060956461],
					[61.059866631792723, 2.2865998129176583],
					[61.058486032018429, 2.2871026114199688],
					[61.05848602497754, 2.2871026147753164],
					[61.058486010895855, 2.2871026214859609],
					[61.058486003854973, 2.2871026248412911],
					[61.057022103555653, 2.2878998231875909],
					[61.057022094023658, 2.2878998307897804],
					[61.057022074867824, 2.287899844154714],
					[61.057022065335829, 2.2878998517568867],
					[61.055727697144526, 2.2890804235748692],
					[61.054222136667782, 2.2895831105565136],
					[61.054222124277267, 2.2895831150470167],
					[61.054222099679905, 2.2895831277065986],
					[61.054222088272816, 2.2895831338470036],
					[61.052758206456915, 2.2905748222574274],
					[61.051247125870148, 2.291177614485425],
					[61.051247117045975, 2.2911776182175863],
					[61.051247098597777, 2.291177627710355],
					[61.051247089865392, 2.2911776332816305],
					[61.050041598046391, 2.2919692287944096],
					[61.048919363024943, 2.2925664938212065],
					[61.047622226999678, 2.2923887014616278],
					[61.047622206217639, 2.2923887002974741],
					[61.047622164037264, 2.2923887036751212],
					[61.047622144422107, 2.2923887078384531],
					[61.046241644114637, 2.2927942072713159],
					[61.046241624774702, 2.2927942169506599],
					[61.04624158956949, 2.2927942337136509],
					[61.046241573887727, 2.2927942444749574],
					[61.044780473828837, 2.293980444653998],
					[61.044780467771083, 2.2939804496557321],
					[61.044780454855719, 2.2939804616871444],
					[61.044780449781307, 2.2939804683384715],
					[61.043747071099659, 2.295158143029564],
					[61.041344300240063, 2.2965498276680867],
					[61.041344297565232, 2.2965498282353183],
					[61.041344294982125, 2.2965498306411081],
					[61.041344293198911, 2.2965498310192616],
					[61.040091625092487, 2.2973442114712399],
					[61.039013862154938, 2.2975526035811411],
					[61.039013839956411, 2.2975526101453321],
					[61.039013797617592, 2.2975526284109642],
					[61.039013778460586, 2.2975526417618117],
					[61.037852730807678, 2.2984415015262312],
					[61.036513897575453, 2.2984580995936192],
					[61.036513896683857, 2.2984580997826036],
					[61.035000043713808, 2.2984775996672866],
					[61.033875085818885, 2.2979415184432779],
					[61.033875046654678, 2.2979415100261344],
					[61.033874968967858, 2.2979415060588493],
					[61.033874929645364, 2.2979415125358091],
					[61.032883229723289, 2.2983109133711861],
					[61.032883172185201, 2.2983109701456161],
					[61.032883117880267, 2.298311109822722],
					[61.032883121913336, 2.2983111906983842],
					[61.033627618969426, 2.2997583853140289],
					[61.034410916009612, 2.3015972772622848],
					[61.034410930849774, 2.3015973038400039],
					[61.034410967204849, 2.3015973462934962],
					[61.034410988903062, 2.3015973658456148],
					[61.035408188747951, 2.3022640655354771],
					[61.035408195264104, 2.302264069728126],
					[61.035408208204814, 2.3022640762751649],
					[61.035408214720952, 2.3022640804677965],
					[61.037616588671114, 2.3033057694000418],
					[61.039352656949056, 2.3048390397797047],
					[61.040569238615419, 2.3062807191909642],
					[61.041438732014321, 2.3076307078343867],
					[61.041438732997506, 2.3076307094842265],
					[61.041438733980641, 2.3076307111340761],
					[61.04143873496384, 2.3076307127839319],
					[61.04222481309651, 2.3087861817931405],
					[61.042446992453677, 2.3097555873382705],
					[61.0420192701012, 2.3109275700906733],
					[61.041541737791306, 2.3108359041080959],
					[61.041541727800343, 2.310835902506271],
					[61.041541706926829, 2.3108358994913276],
					[61.041541697027377, 2.3108358997281453],
					[61.040980596831027, 2.3108441993443689],
					[61.040980549757492, 2.3108442130212241],
					[61.04098046731648, 2.310844258335413],
					[61.040980432040477, 2.3108442918113852],
					[61.040038731957935, 2.312313792793312],
					[61.040038730266133, 2.3123137950091066],
					[61.040038726082372, 2.3123138014678877],
					[61.040038723590442, 2.3123138057108488],
					[61.03935262414322, 2.3135860048033123],
					[61.038710939648276, 2.314763775587541],
					[61.037977662893049, 2.3154554546349342],
					[61.037977661201211, 2.3154554568504127],
					[61.037977658526302, 2.3154554574159669],
					[61.037977657726074, 2.3154554594429313],
					[61.036908197287588, 2.3165387194647442],
					[61.035697198878928, 2.3168469943995351],
					[61.034572308386728, 2.3165692218148317],
					[61.033966849285683, 2.3158970653791462],
					[61.033966848394023, 2.3158970655676274],
					[61.033966845627702, 2.3158970642948415],
					[61.033966845536277, 2.3158970624566328],
					[61.032791860259863, 2.3146498782668661],
					[61.031964093820612, 2.3132971336503454],
					[61.031783498931752, 2.3116443786153305],
					[61.031783497399793, 2.3116443659374197],
					[61.031783489786172, 2.3116443396864175],
					[61.031783487362546, 2.3116443271970941],
					[61.031039092141988, 2.3097110415447029],
					[61.030730795636892, 2.3083527602399418],
					[61.030330799339637, 2.3061221796196238],
					[61.030278000290444, 2.3042749936701017],
					[61.030278000290444, 2.3042749936701017],
					[61.030278000198869, 2.3042749918321492],
					[61.030278000107288, 2.3042749899941981],
					[61.030136299720319, 2.3015555897876241],
					[61.030136295946122, 2.3015555682989306],
					[61.030136284922797, 2.3015555279148896],
					[61.030136276690506, 2.3015555073704448],
					[61.029480776964036, 2.3002999066793719],
					[61.029480755790779, 2.3002998795905216],
					[61.029480705786263, 2.3002998344642966],
					[61.029480676955011, 2.3002998164269379],
					[61.028355715735792, 2.2998276328888339],
					[61.027530746433349, 2.2989637640249168],
					[61.026919548009886, 2.2982887655147239],
					[61.026919544168479, 2.298288760757166],
					[61.026919534702408, 2.2982887516198991],
					[61.026919530061029, 2.2982887488890111],
					[61.026139060746402, 2.2976192755685094],
					[61.025616879902984, 2.2965555125841171],
					[61.025097380259126, 2.2954916121056903],
					[61.025097342119132, 2.2954915700491147],
					[61.025097251074016, 2.2954915150642554],
					[61.025097197185481, 2.2954915004873864],
					[61.024536097181738, 2.2954997991093675],
					[61.02453605358469, 2.295499810206751],
					[61.024535976315292, 2.2954998507252515],
					[61.024535940767997, 2.2954998786867362],
					[61.023635965371717, 2.2966776472644264],
					[61.021911000028084, 2.297672026863121],
					[61.021910999136459, 2.2976720270520183],
					[61.021910998244863, 2.2976720272409081],
					[61.020233198258502, 2.2986665279976428],
					[61.018724902338896, 2.2995609250498972],
					[61.017605525769063, 2.3001609126874158],
					[61.01644164938174, 2.3004665074357877],
					[61.016441644923638, 2.3004665083796603],
					[61.016441636899081, 2.3004665100786186],
					[61.016441631549391, 2.3004665112112561],
					[61.015319380620184, 2.3008691949718871],
					[61.014194444407799, 2.3006887046314644],
					[61.013244479963753, 2.3004109145293561],
					[61.012591820282658, 2.3000304385289621],
					[61.011769547664116, 2.2992609657011616],
					[61.010941857594425, 2.2982026771872457],
					[61.01094185375301, 2.2982026724319771],
					[61.010941845178642, 2.298202663110239],
					[61.010941841337242, 2.2982026583549846],
					[61.01055294100545, 2.2978165581206889],
					[61.01055285757748, 2.2978165293818935],
					[61.010552700903673, 2.2978165495646934],
					[61.010552627657866, 2.2978165984862655],
					[61.009736045373316, 2.2991914016835762],
					[61.009127963786668, 2.2987109758478881],
					[61.008383483719456, 2.296969321370804],
					[61.008383480677978, 2.2969693145905996],
					[61.008383473703468, 2.2969693012189527],
					[61.008383469770358, 2.2969692946275413],
					[61.007305778243676, 2.2952305074029291],
					[61.006733483805476, 2.2938777227073284],
					[61.006733480763842, 2.2938777159277568],
					[61.006733474772332, 2.2938777042051721],
					[61.006733471730726, 2.2938776974255961],
					[61.005991885313819, 2.2926193208087531],
					[61.005466891750267, 2.2907777442030577],
					[61.005466890675173, 2.2907777407190855],
					[61.005466889416624, 2.290777733562158],
					[61.005466888341537, 2.2907777300781667],
					[61.004677988837777, 2.2886471352548554],
					[61.004016891301951, 2.2865138415395552],
					[61.0040168880764, 2.2865138310885227],
					[61.004016880025674, 2.2865138142373804],
					[61.004016876000328, 2.2865138058117984],
					[61.003230784563918, 2.2850638222693873],
					[61.002572390866689, 2.2829305403325195],
					[61.002572389791389, 2.2829305368491379],
					[61.002572386749243, 2.2829305300715164],
					[61.002572385674, 2.2829305265881277],
					[61.002002986320576, 2.2814777262269796],
					[61.00200298533715, 2.2814777245799376],
					[61.002002983278388, 2.2814777194496112],
					[61.002002982294933, 2.2814777178025762],
					[61.001433482339756, 2.2802193186579864],
					[61.001433473396723, 2.2802193019990451],
					[61.001433453019658, 2.2802192729211952],
					[61.001433442385348, 2.2802192584768983],
					[61.000089041970703, 2.2788692595947975],
					[60.998958442942055, 2.2777137608209648],
					[60.998958429724702, 2.2777137487824444],
					[60.998958402674099, 2.2777137304028532],
					[60.998958388657066, 2.2777137203896545],
					[60.998005688741884, 2.2772387212938279],
					[60.998005686866833, 2.277238719836403],
					[60.998005685975258, 2.2772387200256827],
					[60.996619485803606, 2.2765748198229496],
					[60.996619464653875, 2.2765748113252653],
					[60.996619420047445, 2.276574802241361],
					[60.996619397390333, 2.2765747996299637],
					[60.995541716045331, 2.2765887000662022],
					[60.994500034850283, 2.2764054026790639],
					[60.994499999711095, 2.2764054027177489],
					[60.994499932751019, 2.2764054150777571],
					[60.994499900038463, 2.2764054275883594],
					[60.993122125926973, 2.2771998123106911],
					[60.992213856061703, 2.2774054050622112],
					[60.992213851603815, 2.2774054060083713],
					[60.992213845362762, 2.2774054073329921],
					[60.992213840904903, 2.2774054082791455],
					[60.991222141381577, 2.2777109080716369],
					[60.991222131666113, 2.2777109119885988],
					[60.991222114018363, 2.277710919444111],
					[60.99122210519446, 2.2777109231718642],
					[60.990488856903227, 2.2781080965486682],
					[60.98980831628446, 2.2780526015155624],
					[60.989808282128763, 2.278052603198391],
					[60.989808217227257, 2.2780526206806324],
					[60.989808185589879, 2.2780526366692317],
					[60.989241585691545, 2.2784470363446334],
					[60.9892415467924, 2.2784470872630744],
					[60.989241511293287, 2.2784472060945418],
					[60.98924151272638, 2.2784472707149592],
					[60.989722008139587, 2.2797083580782327],
					[60.990035900898988, 2.2810694285115281],
					[60.990130399869706, 2.2827222101581701],
					[60.990130402295733, 2.2827222226289159],
					[60.990130405089083, 2.2827222424418845],
					[60.990130406531669, 2.2827222532661469],
					[60.990702606986801, 2.2847611542820321],
					[60.990702611104005, 2.28476116453955],
					[60.990702620321834, 2.284761186701175],
					[60.99070262533062, 2.2847611967696819],
					[60.991402624676198, 2.2860167974194505],
					[60.991402626642952, 2.2860168007127775],
					[60.991402629593132, 2.286016805652733],
					[60.991402632451475, 2.28601680875708],
					[60.992399831721706, 2.287564007371043],
					[60.992399845213519, 2.2875640249185141],
					[60.99239987558007, 2.2875640555862429],
					[60.992399893346338, 2.2875640685175478],
					[60.993747093227405, 2.2884223686817955],
					[60.993747095102364, 2.2884223701397075],
					[60.993747100727226, 2.2884223745134618],
					[60.993747104385413, 2.288422375593496],
					[60.994960980928447, 2.2890890633091585],
					[60.995872022878451, 2.2899529072909139],
					[60.99635810101455, 2.2916028339264556],
					[60.996402598824545, 2.2924777794878035],
					[60.99610810638135, 2.2936499515616848],
					[60.996108104689831, 2.2936499537752355],
					[60.996108103981605, 2.2936499576359153],
					[60.996108104073308, 2.2936499594718511],
					[60.995810904956933, 2.2951138539822473],
					[60.995219211815488, 2.297263831756323],
					[60.99462202408629, 2.2985388044817294],
					[60.994194229992907, 2.2992248933078216],
					[60.994194225101239, 2.2992249036188812],
					[60.994194216026365, 2.2992249203806892],
					[60.994194212026386, 2.299224930503116],
					[60.993724848333855, 2.3004942333122504],
					[60.992561049398411, 2.3008026062514277],
					[60.992561045831941, 2.3008026070057404],
					[60.992561041465422, 2.3008026097844141],
					[60.992561037898952, 2.300802610538724],
					[60.991005537612807, 2.3013081092202645],
					[60.991005524421652, 2.3013081157198942],
					[60.991005498039307, 2.3013081287191746],
					[60.991005484848159, 2.3013081352188007],
					[60.989586011231772, 2.3023026173890146],
					[60.988594341645815, 2.3026054082491121],
					[60.988594288630182, 2.3026054472805471],
					[60.988594218037214, 2.3026055493847495],
					[60.98859420036829, 2.3026056106219639],
					[60.988644200232812, 2.3034833111107367],
					[60.98864420032438, 2.3034833129462777],
					[60.988644201307523, 2.303483314593354],
					[60.98864420139909, 2.3034833164289141],
					[60.988780394700839, 2.3051360422349036],
					[60.98792203765376, 2.3063165825704472],
					[60.98792203436178, 2.3063165888305539],
					[60.987922025994543, 2.3063166017275281],
					[60.98792202270257, 2.3063166079876343],
					[60.98741092316952, 2.3072971083374538],
					[60.987410914368041, 2.3072971305997707],
					[60.987410903189492, 2.3072971774769031],
					[60.987410899829321, 2.3072972004445642],
				],
			],
		},
		{
			fldNpdidField: "43758",
			wlbNpdidWellbore: "133",
			fldName: "DRAUGEN",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6407/9-1",
			fldDiscoveryYear: "1984",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43758",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43758",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "093",
			cmpLongName: "OKEA ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=133",
			fldHcType: "OIL",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "34925006",
			fldNpdidOwner: "21548",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Kristiansund",
			fldAreaGeometryWKT: [
				[
					[64.385160900081729, 7.6404472000075181],
					[64.385160900100971, 7.6406777992283015],
					[64.385160901132366, 7.6406778136905791],
					[64.385160904989334, 7.6406778425264656],
					[64.385160907814878, 7.6406778569000577],
					[64.386274808059895, 7.6445333564407791],
					[64.386274810827629, 7.6445333645979767],
					[64.386274818176403, 7.6445333828963706],
					[64.386274821841212, 7.6445333910093485],
					[64.388091515824868, 7.6481222801253583],
					[64.389510911689854, 7.6519611681680937],
					[64.38951091901869, 7.6519611843979378],
					[64.389510935432355, 7.6519612126242036],
					[64.389510943620152, 7.6519612246646274],
					[64.391113727915254, 7.6539501050064578],
					[64.392424807029357, 7.656875058434732],
					[64.393044202024868, 7.6607556313280174],
					[64.393044202959743, 7.6607556354303004],
					[64.393044204829565, 7.6607556436348219],
					[64.39304420486738, 7.6607556477808076],
					[64.39386090327686, 7.6641694348587279],
					[64.394385901137326, 7.6687472233732104],
					[64.394385903006679, 7.6687472315787026],
					[64.394385905867054, 7.6687472501062226],
					[64.394385908633467, 7.6687472582682501],
					[64.395705408699669, 7.6730556582197664],
					[64.395705410550022, 7.6730556643527539],
					[64.395705415147788, 7.6730556765754017],
					[64.395705417895229, 7.6730556826650611],
					[64.396416502342035, 7.6746306488437126],
					[64.396644199561521, 7.6787639101770839],
					[64.396644201392988, 7.6787639142373978],
					[64.396644200570563, 7.6787639225735971],
					[64.39664420240203, 7.6787639266339101],
					[64.397583100783777, 7.6858556189112255],
					[64.398024800328812, 7.6929750124451814],
					[64.398024803167729, 7.6929750289043177],
					[64.398024809724163, 7.6929750597065034],
					[64.398024815254274, 7.6929750760376558],
					[64.399138702767104, 7.6956833476037687],
					[64.399466501386328, 7.7000417152369085],
					[64.39946650144141, 7.7000417214574091],
					[64.399466503364081, 7.7000417358870594],
					[64.399466505213312, 7.7000417420227114],
					[64.400794203627029, 7.7061944413470647],
					[64.400794206354846, 7.7061944453675668],
					[64.40079420824037, 7.7061944556511417],
					[64.400794209173966, 7.7061944597561167],
					[64.402122009091656, 7.7105056587558014],
					[64.40212200910986, 7.7105056608295106],
					[64.402122010025167, 7.7105056628611219],
					[64.402122010025167, 7.7105056628611219],
					[64.403847008564995, 7.7157167598374468],
					[64.40496920819426, 7.719577855613136],
					[64.404969214636566, 7.7195778739859113],
					[64.404969232885747, 7.7195779084066674],
					[64.404969242898375, 7.7195779245382656],
					[64.406372020885186, 7.7213500953858913],
					[64.407691502964795, 7.7254306410230784],
					[64.4080108995975, 7.7286417136575896],
					[64.408127599660233, 7.7316306089449176],
					[64.408127612613626, 7.7316306539970707],
					[64.408127657269674, 7.7316307328606735],
					[64.408127688972385, 7.7316307666721213],
					[64.409727689160121, 7.7327029655997315],
					[64.409727745855832, 7.7327029837354182],
					[64.409727860701381, 7.7327029805122285],
					[64.409727917972035, 7.7327029612690552],
					[64.411414018077608, 7.7314668617425237],
					[64.411414024321431, 7.7314668573036576],
					[64.411414035014829, 7.7314668485087683],
					[64.411414040343644, 7.7314668420368866],
					[64.413400139870689, 7.7295251436702275],
					[64.41340014519939, 7.7295251371975189],
					[64.413400154977666, 7.7295251263681521],
					[64.413400160324301, 7.7295251219700187],
					[64.415572350631166, 7.7266501320449077],
					[64.418244486965321, 7.7239779957071732],
					[64.421524932401695, 7.7238141031868519],
					[64.422827671596494, 7.7249001534600543],
					[64.422827747185906, 7.7249001769388723],
					[64.422827890629705, 7.7249001598958973],
					[64.422827960278298, 7.7249001192907683],
					[64.423911259988401, 7.7234640197700903],
					[64.423911277587536, 7.7234639795037863],
					[64.423911294807993, 7.7234638956547643],
					[64.423911294411312, 7.723463849996663],
					[64.422477993710459, 7.7177721500232153],
					[64.422477987267868, 7.7177721316391974],
					[64.422477968085005, 7.7177720930892129],
					[64.422477957175062, 7.7177720769899381],
					[64.419669578843113, 7.7142249035750492],
					[64.418144589720868, 7.7094610389884766],
					[64.418144587871993, 7.7094610328480471],
					[64.418144583277225, 7.7094610206093295],
					[64.418144581428379, 7.7094610144688795],
					[64.416327991021319, 7.7056360363512466],
					[64.415297395797566, 7.7006166596647923],
					[64.415297393911644, 7.7006166493761858],
					[64.415297388363996, 7.7006166309585593],
					[64.415297384702285, 7.7006166228295365],
					[64.41397798530555, 7.6974555224339154],
					[64.413977979849506, 7.6974555143907972],
					[64.413977970713162, 7.6974554961448973],
					[64.413977966154178, 7.6974554880592345],
					[64.412772374953391, 7.6956749026921178],
					[64.411964092928415, 7.6938721405177333],
					[64.411552997148476, 7.6915888641362518],
					[64.411552975193601, 7.6915888174504214],
					[64.411552910798946, 7.6915887416569095],
					[64.411552869256241, 7.6915887125064941],
					[64.410452959786113, 7.6911831463069715],
					[64.408622391735534, 7.6855166460339479],
					[64.407197393871982, 7.6798305538060578],
					[64.405966894840788, 7.6745943531825596],
					[64.405966892074687, 7.6745943450164944],
					[64.405966885626725, 7.6745943266536898],
					[64.405966881963565, 7.6745943185308994],
					[64.404755794724721, 7.6718943457085462],
					[64.404433498912027, 7.6691443767066065],
					[64.404433497996152, 7.6691443746762094],
					[64.40443349795855, 7.6691443705285112],
					[64.404433497939763, 7.6691443684546439],
					[64.403708500056069, 7.664574982521331],
					[64.40358080016874, 7.659741695227571],
					[64.403580798223174, 7.6597416787248527],
					[64.403580791640891, 7.6597416458506666],
					[64.403580787004131, 7.6597416294792193],
					[64.40256408907733, 7.657030533884206],
					[64.401550191224345, 7.6538582391892245],
					[64.401550187560048, 7.6538582310705099],
					[64.401550182960662, 7.6538582188485051],
					[64.401550180212453, 7.6538582127594648],
					[64.400239091884842, 7.6511638386276797],
					[64.399719596969447, 7.6481971657769803],
					[64.399719595079873, 7.6481971554978365],
					[64.399719587693369, 7.6481971330425766],
					[64.399719584906734, 7.6481971228075576],
					[64.39850578477359, 7.6452666234117101],
					[64.398505751180579, 7.64526658150674],
					[64.398505666244588, 7.645266519315042],
					[64.398505614920808, 7.645266501101692],
					[64.396722319971914, 7.6451304097065353],
					[64.395814073440619, 7.6435666001121154],
					[64.395814033606001, 7.6435665626695535],
					[64.395813941588614, 7.6435665112091709],
					[64.395813889425, 7.6435664992645451],
					[64.393630588708717, 7.6436831007509038],
					[64.393630558246954, 7.6436831064018351],
					[64.393630500129504, 7.6436831300089896],
					[64.393630473351749, 7.6436831458479375],
					[64.392441622737522, 7.6446665031728411],
					[64.391250091568608, 7.644730394434907],
					[64.389647355992423, 7.6427415743214597],
					[64.389647327953512, 7.6427415508216976],
					[64.389647265692133, 7.6427415144957171],
					[64.389647230572592, 7.642741501713803],
					[64.388550047084209, 7.6425692051881908],
					[64.386755705841722, 7.6419748238117355],
					[64.385161237999696, 7.6404470568282941],
					[64.38516113010283, 7.6404470352094487],
					[64.385160961153446, 7.6404471078352376],
					[64.385160900081729, 7.6404472000075181],
				],
				[
					[64.223785900207389, 7.7141306018422355],
					[64.223791500025811, 7.7147556022114809],
					[64.223791553752633, 7.7147556904112706],
					[64.223791712422951, 7.7147557716804975],
					[64.22379181467511, 7.71475576487481],
					[64.224506089040361, 7.7142551195513551],
					[64.224341504308427, 7.7150443585714168],
					[64.224341508367985, 7.7150444119753034],
					[64.22434154243075, 7.7150445093345743],
					[64.224341573331074, 7.715044553248342],
					[64.228660972584805, 7.7185973539155714],
					[64.2286609771065, 7.7185973578294567],
					[64.228660986131814, 7.7185973635965279],
					[64.228660989756392, 7.7185973675519239],
					[64.230880457561938, 7.7200612468773606],
					[64.231727636318695, 7.7212723141521131],
					[64.231727674303855, 7.7212723474482727],
					[64.231727759943979, 7.7212723909143932],
					[64.231727809393192, 7.7212724010014933],
					[64.233238799120656, 7.7212030054468022],
					[64.234085898276874, 7.7224167598875617],
					[64.233985899699718, 7.7261777953139763],
					[64.233985901691526, 7.7261778179037979],
					[64.233985911057886, 7.7261778628357947],
					[64.233985918414433, 7.7261778831168462],
					[64.234694217973797, 7.7277111841073625],
					[64.234694235234201, 7.7277112080582038],
					[64.23469427422269, 7.7277112536925792],
					[64.234694296811924, 7.7277112712125389],
					[64.235663776212249, 7.7282918584013176],
					[64.237755457818238, 7.7303862402620593],
					[64.237755465048863, 7.7303862461171313],
					[64.237755477715851, 7.7303862579095499],
					[64.237755484946476, 7.7303862637646024],
					[64.239005463118914, 7.7312668473555526],
					[64.240263734876009, 7.7327751147544248],
					[64.240966527031432, 7.7339972998994302],
					[64.240966531588427, 7.7339973079408662],
					[64.240966541599505, 7.7339973239826891],
					[64.240966547932871, 7.7339973298804106],
					[64.242508148334778, 7.7358029307205252],
					[64.24250815104395, 7.7358029326593716],
					[64.242508155583025, 7.7358029386397709],
					[64.242508159207119, 7.7358029425993848],
					[64.244316558902696, 7.7376001409773947],
					[64.244316578781635, 7.7376001565723138],
					[64.244316622989416, 7.7376001834337016],
					[64.244316646403405, 7.7376001926792108],
					[64.246252722893217, 7.7381390868940105],
					[64.247785997122136, 7.7390084723468009],
					[64.249174870882442, 7.7398834548181812],
					[64.250572053583383, 7.7413862357522376],
					[64.250572066249504, 7.7413862475550976],
					[64.250572093340452, 7.7413862669544633],
					[64.250572107783029, 7.7413862766133326],
					[64.251677708045051, 7.7419612761384373],
					[64.251677754819184, 7.7419612884542559],
					[64.251677849948734, 7.7419612882548474],
					[64.251677897407049, 7.7419612757804339],
					[64.252913979947266, 7.7412779830961451],
					[64.254013962200176, 7.7409168899098981],
					[64.254013966685747, 7.7409168897057299],
					[64.254013974724231, 7.7409168852129806],
					[64.254013979209773, 7.7409168850088124],
					[64.254836144749788, 7.7405640971961214],
					[64.255797209022674, 7.7405224008527505],
					[64.255797224255815, 7.7405223980955515],
					[64.255797253825008, 7.7405223926219824],
					[64.255797269040428, 7.7405223878020237],
					[64.256766654199893, 7.7401641303857192],
					[64.257735948264099, 7.7410612319121865],
					[64.258583119920871, 7.7422750921073842],
					[64.259010907639961, 7.743508356789353],
					[64.259588705389746, 7.7459889449059789],
					[64.259588733642531, 7.7459889952211149],
					[64.259588815969778, 7.7459890719782045],
					[64.25958886823237, 7.7459890964384304],
					[64.261108216037741, 7.7462334893122735],
					[64.262222074884889, 7.747122356351678],
					[64.262222075782006, 7.7471223563110305],
					[64.262222076696787, 7.7471223583336259],
					[64.263747045710105, 7.7483057338903434],
					[64.265297024904555, 7.7510556967091109],
					[64.266288724066428, 7.7528917942606954],
					[64.266288763011872, 7.7528918358552215],
					[64.266288854992652, 7.7528918874434956],
					[64.266288908942755, 7.7528918994603231],
					[64.26738610939077, 7.7528419007276206],
					[64.267386146999257, 7.7528418907732943],
					[64.26738621583084, 7.7528418587661303],
					[64.267386247036342, 7.7528418346496606],
					[64.268891847440898, 7.7512057351202213],
					[64.26889185189124, 7.7512057307900823],
					[64.268891859877201, 7.7512057201066273],
					[64.268891864327514, 7.7512057157764902],
					[64.269569563779612, 7.7502362141244214],
					[64.269569581351661, 7.7502361699735109],
					[64.269569594050253, 7.7502360806218826],
					[64.26956958915919, 7.7502360333573721],
					[64.269141889172261, 7.7490027341879903],
					[64.269141876400909, 7.749002709991216],
					[64.269141843698861, 7.7490026639862011],
					[64.269141824683004, 7.7490026442011546],
					[64.26802796161752, 7.7481109720762298],
					[64.267600225130295, 7.7471917097092664],
					[64.268141820117037, 7.7462279947806181],
					[64.269386014921096, 7.7461696045100936],
					[64.269660912210185, 7.7464722909387458],
					[64.269958099851038, 7.7483389161250091],
					[64.269974800032188, 7.7495917026596723],
					[64.269974801052754, 7.7495917170658677],
					[64.26997480578531, 7.7495917457565131],
					[64.269974808582504, 7.7495917580177425],
					[64.270547009396338, 7.7514444598453798],
					[64.270547017646507, 7.7514444801193658],
					[64.270547040409056, 7.7514445183198557],
					[64.27054705312716, 7.7514445363273863],
					[64.271385953330267, 7.7523473368085183],
					[64.271385958748141, 7.7523473406934311],
					[64.271385968704422, 7.752347350567665],
					[64.271385975037035, 7.752347356475993],
					[64.272499847701525, 7.7532390343971551],
					[64.273619228767004, 7.7550668034080719],
					[64.273619285689222, 7.7550668483365923],
					[64.273619411654252, 7.7550668860253449],
					[64.273619483423474, 7.7550668827925477],
					[64.275130683174737, 7.7543751818653863],
					[64.275130695681625, 7.7543751751066621],
					[64.275130719780705, 7.7543751595654156],
					[64.275130732270028, 7.7543751507424581],
					[64.276633417991462, 7.7530529622904334],
					[64.278891760297029, 7.7516973981718991],
					[64.279999867640498, 7.7516474052431832],
					[64.281135914863086, 7.7544167756707791],
					[64.281135916710028, 7.7544167817838883],
					[64.2811359212659, 7.7544167898403025],
					[64.281135923992366, 7.7544167938483088],
					[64.282119223999061, 7.7562528943494682],
					[64.282119243066575, 7.7562529203429351],
					[64.282119290102628, 7.756252963667059],
					[64.282119316276905, 7.7562529810784921],
					[64.283363750422367, 7.7568251502653984],
					[64.283935853271856, 7.7580527419667122],
					[64.283522075305186, 7.7583859437639848],
					[64.283522056605918, 7.7583859611294539],
					[64.283522028231033, 7.758386001651755],
					[64.283522015846614, 7.7583860228646646],
					[64.282980415749492, 7.7596638225960337],
					[64.282980415749492, 7.7596638225960337],
					[64.282980414852375, 7.7596638226363126],
					[64.282447014728675, 7.7609416233121493],
					[64.282447008658636, 7.760941648370987],
					[64.282447001918769, 7.7609417003120322],
					[64.282447002146029, 7.7609417271539867],
					[64.282744201987015, 7.7631222273241347],
					[64.282744236618441, 7.763122289806371],
					[64.28274433965764, 7.763122376078341],
					[64.282744408065398, 7.7631223998680756],
					[64.283294407999733, 7.7631002002723886],
					[64.283294408896836, 7.7631002002322225],
					[64.283705609391419, 7.7630807998195772],
					[64.283705653262899, 7.7630807875270378],
					[64.283705730100849, 7.7630807469050804],
					[64.283705763049838, 7.7630807165107907],
					[64.284394562675132, 7.7621084158477975],
					[64.284394573300787, 7.7621083988460757],
					[64.284394590031638, 7.7621083609138379],
					[64.284394596136806, 7.7621083399833006],
					[64.284783492071384, 7.7602111589603782],
					[64.285050135683093, 7.7595723949620838],
					[64.285469364672878, 7.7595530014011551],
					[64.286155424377597, 7.7598362438468058],
					[64.286452602310703, 7.7613889271852505],
					[64.286613701118014, 7.763263917690705],
					[64.2866137048984, 7.7632639402459107],
					[64.286613719636179, 7.7632639850349845],
					[64.28661372878183, 7.7632640052841113],
					[64.287735918841179, 7.7650944887301003],
					[64.289008113008407, 7.7684889707548903],
					[64.289008124879473, 7.7684889950180125],
					[64.28900815664349, 7.7684890369884672],
					[64.289008174759644, 7.7684890568410401],
					[64.290122028859159, 7.7693779197927135],
					[64.290838710171386, 7.7715417622773124],
					[64.290838711085854, 7.7715417643028042],
					[64.290838712017617, 7.7715417683936954],
					[64.290838712017617, 7.7715417683936954],
					[64.291977611897266, 7.7746278696982243],
					[64.291977613726104, 7.77462787374958],
					[64.291977617366442, 7.7746278797868262],
					[64.29197761829812, 7.774627883877991],
					[64.292824817649716, 7.7764722834587969],
					[64.292824826759031, 7.7764722995857465],
					[64.292824847651758, 7.7764723296548111],
					[64.292824860332303, 7.7764723435572121],
					[64.294358156685561, 7.7779751399608701],
					[64.295752653423676, 7.7794807367303784],
					[64.295752694932361, 7.7794807638270331],
					[64.295752785817115, 7.779480792874109],
					[64.29575283521045, 7.7794807968903017],
					[64.298094393675356, 7.7790641046286115],
					[64.299202671267224, 7.7793279756491787],
					[64.300469228027183, 7.7814668015452373],
					[64.300469249781628, 7.7814668274545049],
					[64.30046930129582, 7.7814668706521291],
					[64.300469331055567, 7.7814668879404856],
					[64.301986031301482, 7.7820279884138293],
					[64.301986033095716, 7.7820279883346588],
					[64.301986039392759, 7.7820279901238223],
					[64.301986042084124, 7.7820279900050684],
					[64.303786004409019, 7.7825779801447412],
					[64.305602673343941, 7.7840668545576328],
					[64.305602682366597, 7.7840668603619401],
					[64.305602698617761, 7.7840668720495794],
					[64.305602707640432, 7.7840668778538866],
					[64.307266554946452, 7.7849334494247833],
					[64.308813725645038, 7.7876917982085043],
					[64.308813732907538, 7.7876918082269997],
					[64.308813748329698, 7.7876918282245562],
					[64.30881375559224, 7.7876918382430489],
					[64.310555455993523, 7.7894973400087677],
					[64.310555491169211, 7.7894973612096345],
					[64.310555566817925, 7.7894973930407634],
					[64.310555609050994, 7.7894973994585381],
					[64.311661108830037, 7.7894502009648834],
					[64.311661171440676, 7.789450175472914],
					[64.311661264972258, 7.7894500907280477],
					[64.311661296790334, 7.7894500314357717],
					[64.311919596726725, 7.7878667319341224],
					[64.311919599264144, 7.7878667132129493],
					[64.311919597144907, 7.7878666740189342],
					[64.311919595196699, 7.7878666554948239],
					[64.311341904197576, 7.7853832949207362],
					[64.311858475905424, 7.782536253939937],
					[64.312958336105922, 7.7821723993909027],
					[64.313927729669331, 7.7821308030092844],
					[64.314622037094082, 7.7827279237101523],
					[64.315330417933779, 7.7842667837316002],
					[64.31533046679003, 7.7842668312105356],
					[64.315330583913209, 7.7842668860209763],
					[64.315330653077268, 7.7842668933129442],
					[64.316702799177762, 7.7838919088670586],
					[64.317672049718894, 7.7841640649801391],
					[64.319235912717431, 7.7881750720423542],
					[64.320160907745972, 7.7906472588785141],
					[64.321097004990278, 7.7946861449068798],
					[64.321097019616445, 7.7946861773625251],
					[64.321097058703046, 7.7946862377071779],
					[64.321097084043672, 7.7946862634892744],
					[64.323599845122757, 7.7964612358526022],
					[64.324730423294838, 7.7986111937791893],
					[64.324730462210255, 7.7986112334577138],
					[64.324730555088792, 7.7986112873347642],
					[64.324730609034916, 7.7986112994653336],
					[64.326111108618434, 7.7985501989973951],
					[64.326111143555394, 7.7985501912686965],
					[64.326111208841951, 7.7985501635983319],
					[64.326111239191505, 7.798550143656704],
					[64.326788862774748, 7.7978920143179709],
					[64.328338731004223, 7.8003390065908214],
					[64.328338731918279, 7.8003390086200151],
					[64.330299832345332, 7.8033918083756939],
					[64.330299849559594, 7.8033918283196542],
					[64.330299891148215, 7.8033918658276784],
					[64.330299912797429, 7.8033918793717856],
					[64.332099913400512, 7.8042557803493988],
					[64.332099947575642, 7.8042557892131992],
					[64.332100016738636, 7.8042557965593327],
					[64.332100051709617, 7.8042557929731347],
					[64.333480652271632, 7.8038834924396827],
					[64.333480677323564, 7.8038834830751691],
					[64.333480722874313, 7.8038834562663197],
					[64.333480744270233, 7.8038834387830551],
					[64.334433361145329, 7.8029002257045725],
					[64.335402656602554, 7.8031723672529063],
					[64.336813715103872, 7.806252876957755],
					[64.337813712394137, 7.8090361685336225],
					[64.33781371604988, 7.809036176654331],
					[64.337813724275307, 7.8090361949259606],
					[64.337813728828195, 7.809036203007917],
					[64.338935925686584, 7.8108722981187437],
					[64.33991922375516, 7.8127139941749579],
					[64.340905423722504, 7.8145583948670216],
					[64.340905461736497, 7.8145584346297428],
					[64.340905554592368, 7.8145584865224897],
					[64.340905609450985, 7.8145585007216969],
					[64.343116602364631, 7.8144641047613197],
					[64.344230438757748, 7.8159862171518286],
					[64.344230545866864, 7.8159862560220565],
					[64.344230725855127, 7.8159862068839292],
					[64.344230798750985, 7.8159861209450252],
					[64.344483488850202, 7.8134612186745871],
					[64.345030625355932, 7.8131252194765572],
					[64.34586372958708, 7.8134029542303365],
					[64.346580405013015, 7.8165139460031101],
					[64.346580416909561, 7.8165139744766758],
					[64.346580448760051, 7.8165140290073083],
					[64.346580469594457, 7.816514052956224],
					[64.347977640946183, 7.8177112262034161],
					[64.348960923699522, 7.8195556931075378],
					[64.348960927338013, 7.8195556991631472],
					[64.348960934631648, 7.8195557133441369],
					[64.348960939167284, 7.8195557193612784],
					[64.350083102715558, 7.8210778696223437],
					[64.350266500441066, 7.8251556098724118],
					[64.35026650328173, 7.825155628386673],
					[64.350266511654425, 7.8251556653004188],
					[64.3502665180836, 7.8251556836616363],
					[64.351538700076361, 7.8279306451168402],
					[64.351585900044171, 7.8323278019611688],
					[64.351585901073207, 7.8323278184831553],
					[64.351585906703249, 7.8323278493049528],
					[64.351585911320768, 7.8323278656748023],
					[64.352013711312139, 7.8335667642958979],
					[64.352013749403312, 7.8335668144509603],
					[64.352013847767012, 7.8335668827607243],
					[64.352013908039496, 7.8335669009153888],
					[64.353530607702027, 7.8335029998189523],
					[64.353530624731036, 7.8335029970268168],
					[64.353530656994835, 7.8335029915185377],
					[64.353530673110242, 7.8335029866942376],
					[64.355316773257357, 7.8328001851086313],
					[64.355316781315068, 7.8328001826961238],
					[64.355316797397521, 7.8328001737305293],
					[64.355316805438733, 7.8328001692477409],
					[64.356955705278281, 7.8317862689651605],
					[64.356955724019059, 7.8317862557434452],
					[64.35695575694902, 7.8317862212086995],
					[64.356955772051805, 7.8317862019280087],
					[64.357911272425696, 7.8301751018355166],
					[64.357911281248334, 7.830175082820098],
					[64.357911295272118, 7.8301750408007376],
					[64.35791129869547, 7.8301750199435203],
					[64.358405799400657, 7.8251250227813216],
					[64.358789083988583, 7.822277930969082],
					[64.360847290006106, 7.8212473782227203],
					[64.360847290903266, 7.8212473781843066],
					[64.360847295372253, 7.8212473759215646],
					[64.360847296269355, 7.8212473758831536],
					[64.362083295188881, 7.8205669305357501],
					[64.36402207187318, 7.8217418564473009],
					[64.366255445630003, 7.8241612263883686],
					[64.367377639106834, 7.8256862181612803],
					[64.367377653593479, 7.8256862341182964],
					[64.367377687933043, 7.8256862637314999],
					[64.367377706888789, 7.8256862774259437],
					[64.369041606889496, 7.8265584771126404],
					[64.369041608683759, 7.8265584770361354],
					[64.369041614099672, 7.8265584809492328],
					[64.369041615910504, 7.8265584829440522],
					[64.370291616083534, 7.8271362823990751],
					[64.370291712043027, 7.8271362741650599],
					[64.370291854007348, 7.8271361831580037],
					[64.370291899995593, 7.8271360983135834],
					[64.370286299744649, 7.8261916993926777],
					[64.37028629874807, 7.8261916870025257],
					[64.370286295841254, 7.8261916601890711],
					[64.370286293050412, 7.8261916478754534],
					[64.369705792577506, 7.8240138487355315],
					[64.369705778854907, 7.8240138161688506],
					[64.369705739830181, 7.8240137618906713],
					[64.369705716289047, 7.8240137359597526],
					[64.368872422175045, 7.8234221123885881],
					[64.36940019365197, 7.8208833498816848],
					[64.369797389383365, 7.8196083637520202],
					[64.370736286772512, 7.8170528698146997],
					[64.371941886839409, 7.8138556716848768],
					[64.371941890360887, 7.8138556632441292],
					[64.371941893832158, 7.8138556485888016],
					[64.371941895559402, 7.8138556402253982],
					[64.372336292377184, 7.8119528603170778],
					[64.373008456724108, 7.810350143702113],
					[64.374930550739322, 7.8093252534370583],
					[64.376463713267299, 7.8111444896813316],
					[64.376755403033428, 7.8127056377497093],
					[64.376755423122148, 7.8127056804074169],
					[64.376755479347239, 7.8127057525946437],
					[64.37675551638074, 7.8127057820854242],
					[64.37800551598832, 7.8132807827138056],
					[64.378005546557517, 7.8132807896858694],
					[64.378005611234073, 7.8132807972593001],
					[64.378005642633326, 7.8132807959047792],
					[64.379799930793467, 7.8128891207766893],
					[64.381752653103916, 7.8150084356707126],
					[64.38175268829248, 7.8150084590304285],
					[64.381752765746157, 7.8150084930071042],
					[64.38175280887495, 7.815008499440907],
					[64.38271390938435, 7.8149668999555253],
					[64.382713961283542, 7.8149668811349704],
					[64.382714048749719, 7.8149668213939707],
					[64.38271408429992, 7.8149667784011996],
					[64.383522383748556, 7.813044478564044],
					[64.38352239138689, 7.8130444243334374],
					[64.383522377108804, 7.8130443233670901],
					[64.383522356072731, 7.8130442745202453],
					[64.382544571096318, 7.8118276930861255],
					[64.382119622170976, 7.8109028048624181],
					[64.383744489306011, 7.8076863671338517],
					[64.386099912243864, 7.8088445783379914],
					[64.386099914952055, 7.8088445802939894],
					[64.386099921265512, 7.8088445841671303],
					[64.386099923956891, 7.8088445840505525],
					[64.388174886161721, 7.8096973683152004],
					[64.390830456805574, 7.8124168391567848],
					[64.390830466757748, 7.8124168490952277],
					[64.390830488422836, 7.8124168647487346],
					[64.39083049925533, 7.8124168725754917],
					[64.392838798687805, 7.8135890728516992],
					[64.392838825685203, 7.8135890820556755],
					[64.392838879646419, 7.8135890963174548],
					[64.392838908387787, 7.8135890992247123],
					[64.394080608002568, 7.8135362997211804],
					[64.395599955244762, 7.8134724019749617],
					[64.398933206742072, 7.8152168768882424],
					[64.39893323194508, 7.8152168861731477],
					[64.398933282300931, 7.8152168985222925],
					[64.398933308334136, 7.8152168994743052],
					[64.40058060764558, 7.815147400252874],
					[64.40058064972655, 7.8151473880665785],
					[64.40058072480015, 7.8151473495650396],
					[64.400580758673144, 7.8151473211374238],
					[64.402083458935508, 7.8131918220212633],
					[64.402083467789367, 7.8131918071172892],
					[64.402083484583201, 7.8131917752742801],
					[64.402083490728927, 7.8131917584127555],
					[64.403144585999257, 7.8096833771248333],
					[64.4043668756252, 7.8074278944844826],
					[64.40436688006028, 7.8074278880679122],
					[64.404366886205324, 7.8074278712037],
					[64.404366888846155, 7.8074278648650051],
					[64.405564045323416, 7.8039112938202573],
					[64.407219367904531, 7.8038419021537901],
					[64.410269330552808, 7.8049695872952656],
					[64.410269358430895, 7.8049695943830111],
					[64.410269414982949, 7.8049695960730476],
					[64.410269442793521, 7.8049695948631825],
					[64.412055625761781, 7.8045779988012285],
					[64.414816709120998, 7.8044584994740349],
					[64.41481674585205, 7.8044584916486386],
					[64.414816814693467, 7.8044584595951818],
					[64.414816845923596, 7.8044584374808945],
					[64.416047321775551, 7.8031445620412594],
					[64.418791790200956, 7.8017668781979506],
					[64.41879181073304, 7.8017668648473055],
					[64.418791849038087, 7.8017668299632819],
					[64.418791865930913, 7.8017668105441098],
					[64.419875165660244, 7.8001445102469154],
					[64.419875178050376, 7.8001444889468443],
					[64.419875193808608, 7.800144440513372],
					[64.419875199004835, 7.8001444174518015],
					[64.420241899041073, 7.7957194170575921],
					[64.420241897905811, 7.795719388044863],
					[64.420241886698193, 7.7957193345631923],
					[64.4202418784201, 7.7957193100155502],
					[64.41883078237646, 7.7929443160538607],
					[64.41741128496686, 7.7895416222195868],
					[64.41741127223635, 7.7895416020239141],
					[64.417411241392699, 7.789541561869834],
					[64.417411223296639, 7.7895415439863722],
					[64.415886271711727, 7.7883470818622218],
					[64.415597398021589, 7.787416661247371],
					[64.41527520114343, 7.7833360998551839],
					[64.415633500222441, 7.7782778147812426],
					[64.415633493597582, 7.7782777735648763],
					[64.415633467857404, 7.7782776999900438],
					[64.415633446913347, 7.7782776635618083],
					[64.414519577220517, 7.7770692960974772],
					[64.413519589968672, 7.7739638388554653],
					[64.413519575355252, 7.7739638083768536],
					[64.413519533638208, 7.7739637562787731],
					[64.41351950920857, 7.7739637324645479],
					[64.41171400883016, 7.7727831335471977],
					[64.411713972807334, 7.7727831185545799],
					[64.41171389819165, 7.7727831032109655],
					[64.411713858719054, 7.772783104974553],
					[64.409783258878036, 7.7731831039724391],
					[64.409783255289625, 7.7731831041326975],
					[64.409783247233008, 7.7731831065676209],
					[64.409783242764803, 7.7731831088422823],
					[64.408547240125543, 7.7735525785960382],
					[64.406180719909713, 7.7717692402740957],
					[64.406180644362081, 7.7717692208281495],
					[64.406180501861201, 7.7717692437973236],
					[64.406180435805027, 7.7717692861723595],
					[64.405502635954846, 7.7727442862859517],
					[64.405502621826486, 7.7727443138893095],
					[64.405502604369701, 7.7727443727632117],
					[64.405502600126852, 7.7727444019998142],
					[64.405541500260625, 7.7762055917248389],
					[64.405174811353191, 7.7803137629227646],
					[64.403525100702538, 7.7810164403812019],
					[64.402680786010009, 7.7791638250157282],
					[64.401825192295007, 7.7766832452663426],
					[64.401244594632175, 7.7741916554042101],
					[64.401244571823653, 7.774191610783304],
					[64.401244509317436, 7.7741915409650844],
					[64.401244468687963, 7.7741915116603835],
					[64.399719494189654, 7.7736304211691341],
					[64.398466868246047, 7.7727387748430985],
					[64.397186289297181, 7.7690193351665258],
					[64.397186269265347, 7.7690193008028778],
					[64.397186215814642, 7.7690192409718133],
					[64.397186184207357, 7.7690192174974175],
					[64.395936272836792, 7.7684442595143235],
					[64.394919594763749, 7.7640832541813571],
					[64.394919584668713, 7.7640832276732805],
					[64.394919557336706, 7.764083179126267],
					[64.394919540099778, 7.7640831570873079],
					[64.393386244187809, 7.7625776619589537],
					[64.391716852304185, 7.7607637697925131],
					[64.390039057112844, 7.7586359760748564],
					[64.390039039910107, 7.7586359581888944],
					[64.390039003780629, 7.7586359307892998],
					[64.390038983024553, 7.7586359172110253],
					[64.388786194921849, 7.7580609220420467],
					[64.386427944894393, 7.756594254383705],
					[64.384880775900342, 7.7541471040128336],
					[64.383614082145399, 7.7513721160725924],
					[64.38361407213624, 7.7513720999418094],
					[64.383614049426598, 7.7513720678024507],
					[64.383614035846691, 7.7513720539069508],
					[64.381661235836049, 7.7495720531871157],
					[64.38166123492131, 7.7495720511556829],
					[64.381661234024207, 7.749572051196469],
					[64.381661233127119, 7.7495720512372515],
					[64.379989113215373, 7.7480749227453387],
					[64.380500192138626, 7.7445917705514944],
					[64.381316868704261, 7.7432945063152303],
					[64.381316871405403, 7.7432944025427739],
					[64.38131678034695, 7.7432942491567109],
					[64.381316688381574, 7.7432941994611291],
					[64.38118090789537, 7.7433025819719621],
					[64.381583484567443, 7.7423389761884733],
					[64.381583492428234, 7.7423389509530205],
					[64.381583498246201, 7.7423388967889659],
					[64.381583497100451, 7.7423388678193259],
					[64.381133496860187, 7.7395277672712925],
					[64.381133488554951, 7.7395277407033074],
					[64.381133463888375, 7.7395276900094672],
					[64.381133446629946, 7.7395276659247454],
					[64.380302946699004, 7.7386220642696308],
					[64.380302934929873, 7.7386220523719027],
					[64.380302906941765, 7.7386220329263136],
					[64.380302891602085, 7.738622023265247],
					[64.378630691771022, 7.7377526226188351],
					[64.378630644100141, 7.737752610296794],
					[64.378630547213689, 7.7377526147425577],
					[64.378630498859593, 7.7377526273252561],
					[64.37630549941133, 7.7391192274822558],
					[64.376305478005605, 7.7391192429715714],
					[64.376305440647968, 7.7391192819906314],
					[64.376305424660458, 7.739119301376804],
					[64.37522204134666, 7.7410554731431906],
					[64.37413051929714, 7.7420470044307379],
					[64.37164440842831, 7.742160898573621],
					[64.369850198227397, 7.7419276238517165],
					[64.369805800876108, 7.7378418153165613],
					[64.372208399358186, 7.7364751733761841],
					[64.372208423419252, 7.7364751536206224],
					[64.372208465208146, 7.7364751081834502],
					[64.372208482038928, 7.7364750825429969],
					[64.373077980443909, 7.7345472864697244],
					[64.37456403524736, 7.7316473752241421],
					[64.376358307225814, 7.7312530028278834],
					[64.378013753290844, 7.7314918800706387],
					[64.379149811586814, 7.7345861684607407],
					[64.379149850608584, 7.734586218486176],
					[64.379149949878723, 7.7345862823226215],
					[64.379150010162803, 7.7345863002776243],
					[64.379844410423445, 7.7345529995418474],
					[64.379844475679079, 7.7345529695923707],
					[64.379844570882099, 7.7345528740060701],
					[64.379844599932326, 7.734552808410518],
					[64.379952999901633, 7.7320306096095459],
					[64.379952998843407, 7.7320305910023679],
					[64.379952993138573, 7.7320305539534431],
					[64.379952989389054, 7.732030535470324],
					[64.379097389238567, 7.7295555344604789],
					[64.379097385578405, 7.7295555263382374],
					[64.379097378240203, 7.7295555080217824],
					[64.379097373700915, 7.7295555020129614],
					[64.378394590080646, 7.7283277290030403],
					[64.377516906711847, 7.7239639152694064],
					[64.378319587804185, 7.7217250667604134],
					[64.378319593006239, 7.72172504579102],
					[64.378319598906842, 7.7217250019887418],
					[64.378319598708302, 7.7217249791975435],
					[64.377861298636233, 7.7173416795847292],
					[64.377861294884895, 7.7173416611050341],
					[64.377861284709013, 7.7173416263430372],
					[64.377861278266394, 7.7173416079888479],
					[64.376594582246028, 7.7148832173880262],
					[64.375739086314283, 7.7127221250202513],
					[64.375739085399005, 7.7127221229904848],
					[64.375739082671373, 7.7127221189729678],
					[64.375739081756123, 7.7127221169432199],
					[64.374611281930783, 7.7102582168027336],
					[64.374611261939918, 7.7102581887249597],
					[64.374611214836122, 7.7102581391207821],
					[64.374611187741394, 7.7102581196659949],
					[64.372252888205537, 7.7091081192811082],
					[64.372252863911299, 7.7091081121315961],
					[64.372252816256307, 7.7091081019334196],
					[64.372252791101388, 7.7091080989689278],
					[64.370461090770903, 7.7091914990275834],
					[64.368530590908222, 7.7092804000902095],
					[64.368530553303401, 7.7092804101420169],
					[64.368530483585658, 7.709280442420309],
					[64.368530452369811, 7.7092804646046966],
					[64.367027673188332, 7.7109220419275823],
					[64.365249899785368, 7.7119498260551218],
					[64.36524988372868, 7.7119498371654549],
					[64.365249855240052, 7.7119498633600578],
					[64.365249841892847, 7.7119498764153773],
					[64.363480586344622, 7.7142329906630858],
					[64.362641887230311, 7.7133277086708123],
					[64.361900200233137, 7.7089610822414913],
					[64.361847400155852, 7.7036166990266883],
					[64.361847400137549, 7.7036166969560202],
					[64.36184740011926, 7.7036166948853504],
					[64.361847400100942, 7.7036166928146796],
					[64.361647399607463, 7.6985971918038496],
					[64.361647399589103, 7.6985971897331993],
					[64.361647399570714, 7.6985971876625499],
					[64.361647398655279, 7.6985971856342879],
					[64.361489098838035, 7.6964027856481314],
					[64.361489048762238, 7.6964027051589525],
					[64.361488904455797, 7.6964026249888988],
					[64.36148881018832, 7.6964026211667536],
					[64.360113810194804, 7.6970970203426203],
					[64.360113789690644, 7.6970970358128374],
					[64.360113754046381, 7.6970970644280303],
					[64.360113738045996, 7.6970970817565059],
					[64.35916923775855, 7.6983970811510352],
					[64.359169228037615, 7.6983970981813012],
					[64.359169212184085, 7.6983971320721514],
					[64.359169206069865, 7.6983971510032374],
					[64.358374805853046, 7.7015777522635638],
					[64.358374804974275, 7.7015777543762756],
					[64.358374804150515, 7.701577762700226],
					[64.358374803271758, 7.7015777648129378],
					[64.357985904867661, 7.7037971561315999],
					[64.357452615242508, 7.7057082185906545],
					[64.356636069710959, 7.7070024722995685],
					[64.355239065585579, 7.7054970849461339],
					[64.35369187918171, 7.7024277108268526],
					[64.353691870082599, 7.7024276967591385],
					[64.353691850987317, 7.7024276686659601],
					[64.353691840112347, 7.7024276567528416],
					[64.352158439983683, 7.7009276577463535],
					[64.352158433649066, 7.7009276518327416],
					[64.35215842189524, 7.7009276420331254],
					[64.352158414663549, 7.7009276361618122],
					[64.350905715083456, 7.7000415369726412],
					[64.350905620742523, 7.7000415248597367],
					[64.350905465126544, 7.7000415860432065],
					[64.350905403851527, 7.7000416593395791],
					[64.350380408398308, 7.7025805419307369],
					[64.349708175563578, 7.704183077992508],
					[64.346672232432979, 7.7036970034209551],
					[64.346672170588789, 7.7036970125423645],
					[64.346672066929045, 7.7036970629689634],
					[64.346672023337689, 7.7036971064281081],
					[64.345658122634063, 7.7056305078877214],
					[64.345658120858133, 7.7056305100414946],
					[64.345658117342893, 7.7056305184879079],
					[64.345658115585252, 7.7056305227111359],
					[64.344985963957924, 7.7072331049865532],
					[64.34346672765632, 7.7073025985122605],
					[64.342636162594502, 7.7070276091234611],
					[64.342636108732563, 7.7070276075109154],
					[64.342636008422716, 7.7070276308490238],
					[64.34263596107769, 7.7070276558417969],
					[64.341949861312614, 7.7076887561690066],
					[64.341949835643575, 7.7076887967036303],
					[64.341949805908641, 7.7076888850398078],
					[64.341949802739848, 7.7076889327992664],
					[64.342102602907715, 7.7086250329756769],
					[64.342102622147635, 7.7086250776149701],
					[64.342102679338851, 7.7086251515262525],
					[64.342102717308336, 7.7086251828674319],
					[64.343344248706003, 7.709194551996795],
					[64.344191509306199, 7.7110389627582903],
					[64.344635893787199, 7.7132193894404386],
					[64.344230459070786, 7.7144941844786787],
					[64.342163935941088, 7.7142776029340068],
					[64.340089025703136, 7.7137442261800384],
					[64.339103014598138, 7.7122193643500454],
					[64.339500192010618, 7.7106306559510926],
					[64.339905790656474, 7.7093556608354579],
					[64.339905794099096, 7.7093556441151661],
					[64.339905799208395, 7.7093556128276228],
					[64.339905799959737, 7.7093555962333848],
					[64.339880799571191, 7.7074721967410609],
					[64.339880783846269, 7.7074721436617573],
					[64.339880724714376, 7.7074720532907275],
					[64.339880683119787, 7.7074720179839042],
					[64.338630683231344, 7.7068998176211005],
					[64.338630669720217, 7.7068998120457435],
					[64.338630641837398, 7.7068998050749649],
					[64.338630628344532, 7.7068998015685368],
					[64.336975028376301, 7.70666370220221],
					[64.336975019387154, 7.7066637005544036],
					[64.336974999632886, 7.7066636994117941],
					[64.336974990661957, 7.7066636998327933],
					[64.335869513965122, 7.7067136953513113],
					[64.334319574176362, 7.7039610024802512],
					[64.334319568720659, 7.7039609944589209],
					[64.334319558706326, 7.7039609783740985],
					[64.334319554165972, 7.7039609723791891],
					[64.333061271437472, 7.7024470930721183],
					[64.331933481587086, 7.6999888162794337],
					[64.331933442608545, 7.6999887725933531],
					[64.331933347863725, 7.6999887149829576],
					[64.331933291181983, 7.6999886990325139],
					[64.331244525248536, 7.7000191944068854],
					[64.330536282413689, 7.6984832164502093],
					[64.329405781596307, 7.6960249157531724],
					[64.329405771581094, 7.6960248996740708],
					[64.329405750672024, 7.6960248696265632],
					[64.32940573888105, 7.6960248557005499],
					[64.326622356035799, 7.6933276716874399],
					[64.324652967880795, 7.6902804906857511],
					[64.32465291187323, 7.6902804499023114],
					[64.324652786790011, 7.6902804123997806],
					[64.324652717732818, 7.6902804177485766],
					[64.322319317829908, 7.6913331187401086],
					[64.322319315138628, 7.6913331188678216],
					[64.322319307980408, 7.6913331212760792],
					[64.322319305307602, 7.6913331234714821],
					[64.320397104564535, 7.6923665244808532],
					[64.320397100994612, 7.6923665267185379],
					[64.320397093854837, 7.6923665311939011],
					[64.320397090284942, 7.6923665334315832],
					[64.318344289650071, 7.693716532527068],
					[64.318344256771198, 7.693716569244816],
					[64.318344211757108, 7.6937166541075488],
					[64.318344199621848, 7.6937167022525177],
					[64.318358100159756, 7.6949722009685226],
					[64.318358102156523, 7.694972223625042],
					[64.318358112392843, 7.6949722645061698],
					[64.318358117941131, 7.694972282858096],
					[64.319205418412778, 7.6968167828249525],
					[64.320052617616071, 7.698661182588622],
					[64.320052626733926, 7.6986611987055964],
					[64.320052647642484, 7.6986612287449976],
					[64.320052660330305, 7.698661242625132],
					[64.32158591883217, 7.70015840139971],
					[64.322310905400442, 7.7032639456095957],
					[64.322310910947678, 7.7032639639658429],
					[64.322310925593982, 7.7032639963741616],
					[64.322310934729629, 7.7032640145616753],
					[64.323569234558633, 7.7050890133850798],
					[64.323569248161192, 7.7050890292956025],
					[64.323569278021097, 7.7050890568546375],
					[64.323569294278414, 7.7050890685031694],
					[64.324966594376633, 7.7059668679791153],
					[64.324966621417246, 7.7059668811914515],
					[64.324966678995494, 7.7059668971082163],
					[64.324966708636069, 7.705966899854725],
					[64.326477746223986, 7.7058974029732656],
					[64.327866517912327, 7.7067751218321314],
					[64.32830808492902, 7.7092666305406663],
					[64.327627686626371, 7.7099248301576608],
					[64.325980615137738, 7.7106303769008901],
					[64.325005702696714, 7.7100470270549515],
					[64.325005609326155, 7.7100470231478848],
					[64.325005458281723, 7.7100470943455965],
					[64.325005402402013, 7.7100471693664216],
					[64.324622001675536, 7.7125777703667158],
					[64.324622000887459, 7.7125777828159272],
					[64.32462200019026, 7.7125778056045409],
					[64.32462200119636, 7.7125778179699651],
					[64.324935900713385, 7.7160167182265882],
					[64.324935901701068, 7.716016728524373],
					[64.32493590636777, 7.7160167489946225],
					[64.324935909149673, 7.7160167592088609],
					[64.325508100983541, 7.7178750332164894],
					[64.32569149973628, 7.7219500086626738],
					[64.325691500687469, 7.7219500148250209],
					[64.325691500759632, 7.7219500230969151],
					[64.325691501692816, 7.7219500271912702],
					[64.326133091845534, 7.7250693599689599],
					[64.324647026949805, 7.7276499002877879],
					[64.324647017279233, 7.7276499234900076],
					[64.324647004235743, 7.7276499716725846],
					[64.324646999965765, 7.7276499966943346],
					[64.324560900878438, 7.7326777578001842],
					[64.323752616054676, 7.7345971222594425],
					[64.323752614296211, 7.7345971264774622],
					[64.323752610797101, 7.7345971369812956],
					[64.323752609020758, 7.7345971391314805],
					[64.322555422917489, 7.7384220945198923],
					[64.321602723705695, 7.7394053902884012],
					[64.319527851436632, 7.7391859041046258],
					[64.317519477610318, 7.7383359170056805],
					[64.317519466791623, 7.7383359112959536],
					[64.317519445189902, 7.7383359040112731],
					[64.317519434406847, 7.7383359024363019],
					[64.316069433559349, 7.7380887024297857],
					[64.316069378871575, 7.7380887090676644],
					[64.316069282287032, 7.7380887486433263],
					[64.316069238596086, 7.7380887816631851],
					[64.315116539165416, 7.739385981517688],
					[64.315116532077539, 7.7393859921816155],
					[64.315116519713754, 7.7393860154946745],
					[64.315116514420097, 7.7393860260766116],
					[64.313972014129405, 7.7422638262964272],
					[64.313972013250037, 7.7422638284044334],
					[64.313972010611977, 7.7422638347284494],
					[64.313972009732609, 7.7422638368364556],
					[64.312905427146774, 7.7454526863813156],
					[64.311813912219421, 7.7464414180604608],
					[64.31041687084938, 7.7449359891008367],
					[64.30914458608693, 7.7418555265308893],
					[64.308005787970174, 7.7387693310477692],
					[64.308005774300014, 7.7387693068628716],
					[64.308005741612632, 7.7387692628723999],
					[64.30800572168053, 7.7387692410411715],
					[64.306472331767253, 7.737583148414684],
					[64.304664066234167, 7.7357831828110584],
					[64.304236280949596, 7.734861015887212],
					[64.304236270938617, 7.7348609998085394],
					[64.304236247310399, 7.7348609657493279],
					[64.304236233728858, 7.7348609519015339],
					[64.301589033869348, 7.732472052128033],
					[64.301589023014671, 7.7324720422914899],
					[64.301589000443968, 7.732472026791962],
					[64.301588987812949, 7.732472019103982],
					[64.299233420724136, 7.7313248361596303],
					[64.297280746786271, 7.7292192637849251],
					[64.2972807413678, 7.7292192599009848],
					[64.297280729597858, 7.7292192480426856],
					[64.297280725076476, 7.7292192441174361],
					[64.296166862165492, 7.7283304721144637],
					[64.295036284322634, 7.7258721207501413],
					[64.294044585815882, 7.7234082258323076],
					[64.294044530588266, 7.7234081705253974],
					[64.294044399089003, 7.7234081166782769],
					[64.294044321005089, 7.7234081161554018],
					[64.293219320779727, 7.7237609158861371],
					[64.293219286887947, 7.7237609401826646],
					[64.293219231771801, 7.7237610005884996],
					[64.29321921144458, 7.7237610366563461],
					[64.292677610653783, 7.7253527370334618],
					[64.292677604607874, 7.7253527641748798],
					[64.292677603317244, 7.7253528220917786],
					[64.292677607175435, 7.7253528529086708],
					[64.293258107101167, 7.7275222529743894],
					[64.29325810801619, 7.7275222549985951],
					[64.293258110779334, 7.7275222631367706],
					[64.293258111730324, 7.7275222692920629],
					[64.29382201195962, 7.7290639685866189],
					[64.293822014704702, 7.7290639746595113],
					[64.293822020194966, 7.7290639868053104],
					[64.293822022940034, 7.7290639928782214],
					[64.295097009068016, 7.7315139664672339],
					[64.295822003883757, 7.7352472388102953],
					[64.295822005749358, 7.7352472469910758],
					[64.295822012207523, 7.7352472673608847],
					[64.29582201498809, 7.7352472775663337],
					[64.29709700081267, 7.7383250413871831],
					[64.297119199013608, 7.7398916165069735],
					[64.29575266088618, 7.7412109561218108],
					[64.295752642260041, 7.7412109817696733],
					[64.295752613117287, 7.7412110368287452],
					[64.295752602618435, 7.7412110683057325],
					[64.295233102961532, 7.7443693672603855],
					[64.295233102135313, 7.7443693755640224],
					[64.29523309958573, 7.7443693922120964],
					[64.295233099674306, 7.7443694025406247],
					[64.295263698849411, 7.7471916371726275],
					[64.293908226866918, 7.7491358560228756],
					[64.292519510973506, 7.7485720290952376],
					[64.291266875014685, 7.7473720890613569],
					[64.290266890030537, 7.7442805387165921],
					[64.290266885420962, 7.7442805244631856],
					[64.290266871733991, 7.7442804982256561],
					[64.290266863553683, 7.7442804862007248],
					[64.289002964305496, 7.7424581850328522],
					[64.2890029425797, 7.7424581632955238],
					[64.289002895592873, 7.7424581261799954],
					[64.289002869452531, 7.742458112907876],
					[64.287483454013724, 7.7418998431831705],
					[64.285919586234186, 7.7378943259886173],
					[64.285919566230689, 7.7378942959152726],
					[64.285919513699781, 7.7378942404724693],
					[64.28591948298444, 7.7378942170860325],
					[64.284675142208656, 7.7373248459333386],
					[64.283127991691359, 7.7345721331833106],
					[64.28253079714942, 7.7308360675888039],
					[64.282530796198628, 7.7308360614357596],
					[64.282530794296946, 7.7308360491296959],
					[64.28253079155192, 7.7308360430590799],
					[64.281814092041841, 7.7283610434973511],
					[64.281814085618592, 7.7283610272688374],
					[64.281814070098676, 7.7283609970004132],
					[64.281814060984075, 7.7283609808957747],
					[64.28069186696581, 7.7268442897280938],
					[64.27872517487647, 7.7234860047985991],
					[64.277314083314238, 7.7207305185600079],
					[64.27603908942784, 7.7173416344999008],
					[64.275330798288024, 7.7151805601616328],
					[64.275002999143695, 7.7108055847114283],
					[64.275002999125505, 7.7108055826472626],
					[64.275002999089139, 7.7108055785189089],
					[64.275002999070935, 7.7108055764547432],
					[64.274697400356104, 7.70831389282771],
					[64.274802979871367, 7.7054866507176856],
					[64.275994237069057, 7.7069973155525844],
					[64.276977632188746, 7.7085195094449279],
					[64.276977654834852, 7.7085195331685439],
					[64.27697770724923, 7.7085195740874202],
					[64.276977736999271, 7.70851958921837],
					[64.277808237054487, 7.7087945893319336],
					[64.27780832043004, 7.7087945792402746],
					[64.277808449890017, 7.7087945029730855],
					[64.277808496835107, 7.7087944326268696],
					[64.278327997443427, 7.7056361317825495],
					[64.278327999091545, 7.7056361151831769],
					[64.278327997902267, 7.7056360821945669],
					[64.278327996859076, 7.7056360657212837],
					[64.278105798610497, 7.7043916756245432],
					[64.277947398871831, 7.7022055857167429],
					[64.277947398871831, 7.7022055857167429],
					[64.277778005333914, 7.700016751110148],
					[64.278191867711826, 7.6993723071008953],
					[64.278191882632143, 7.699372269223872],
					[64.278191895372927, 7.6993721880789163],
					[64.278191893211769, 7.6993721468753771],
					[64.274811292592545, 7.6869916476896938],
					[64.274811248079331, 7.6869915878540649],
					[64.274811130772022, 7.6869915170218128],
					[64.27481105795944, 7.6869915039610754],
					[64.272955558120714, 7.6873915039080503],
					[64.27295554737411, 7.6873915064833058],
					[64.272955525899448, 7.6873915136978113],
					[64.272955516068464, 7.6873915182944765],
					[64.271447115852268, 7.6880915192925334],
					[64.271447113160988, 7.688091519420273],
					[64.27144710602127, 7.6880915238886915],
					[64.27144710332999, 7.6880915240164303],
					[64.270211112692138, 7.6887747637554025],
					[64.268539015012593, 7.6875998354450896],
					[64.268538994268383, 7.6875998240426471],
					[64.268538949247116, 7.6875998075991365],
					[64.268538924970059, 7.6875998025580499],
					[64.266747327992434, 7.6873720141129214],
					[64.265616877289872, 7.6852332078175136],
					[64.265616848249053, 7.685233172040582],
					[64.265616777793667, 7.6852331217184977],
					[64.265616738154691, 7.6852331050245439],
					[64.264305638239634, 7.6849804036221512],
					[64.264305555855557, 7.6849804240542969],
					[64.26430543735853, 7.6849805205164143],
					[64.264305400329931, 7.6849805945257046],
					[64.264258100175297, 7.6868638954627215],
					[64.264258100249393, 7.6868639037161017],
					[64.264258101313189, 7.6868639222436403],
					[64.264258102284401, 7.6868639304544351],
					[64.264702601724707, 7.689663929201374],
					[64.264999797351464, 7.6918416923547257],
					[64.264344204384471, 7.6950082596008151],
					[64.264344204384471, 7.6950082596008151],
					[64.264083127014985, 7.6962720469226689],
					[64.262986085171335, 7.6966386946641725],
					[64.261597326409017, 7.6963887199532612],
					[64.260339072136588, 7.6942554974273571],
					[64.260339055804664, 7.6942554775591825],
					[64.260339018692193, 7.6942554421607428],
					[64.260338997911617, 7.6942554266304981],
					[64.25880571334298, 7.6933887330903943],
					[64.257697324883353, 7.6924998437537786],
					[64.257697245717608, 7.6924998227315324],
					[64.25769709875992, 7.6924998482526226],
					[64.257697030949529, 7.6924998927330979],
					[64.256880430972103, 7.6937915926140095],
					[64.256880422148541, 7.693791609540102],
					[64.256880408108231, 7.693791645285641],
					[64.256880402891483, 7.6937916641050856],
					[64.256363706949102, 7.6966360411787749],
					[64.255416589722032, 7.6988747474365411],
					[64.253900068931941, 7.69831921234276],
					[64.253900068034866, 7.6983192123849742],
					[64.252372276959221, 7.6977637147601472],
					[64.250564051262202, 7.6969109507009579],
					[64.249297382097978, 7.6941499175093604],
					[64.249297379351432, 7.694149911449677],
					[64.249297374792292, 7.6941499034124199],
					[64.249297372045731, 7.6941498973527178],
					[64.248030899312525, 7.6920168125878021],
					[64.249130524980529, 7.6916530346465093],
					[64.25025263881902, 7.6931668194216041],
					[64.250252676791987, 7.6931668506388196],
					[64.250252762403278, 7.6931668899217218],
					[64.250252810060019, 7.6931669000497145],
					[64.250800010028186, 7.6931391004615994],
					[64.250800059239481, 7.693139083694418],
					[64.250800142227092, 7.6931390302569884],
					[64.250800176900484, 7.6931389935443484],
					[64.251480777003124, 7.691855693156219],
					[64.251480786612859, 7.6918556638160158],
					[64.251480796842898, 7.6918556034973715],
					[64.251480798341888, 7.6918555704141323],
					[64.251030797908413, 7.688744370005459],
					[64.251030795106146, 7.6887443577588366],
					[64.25103078677337, 7.6887443292684123],
					[64.251030782176926, 7.6887443171068268],
					[64.250327981976511, 7.6872110168577361],
					[64.250327942994062, 7.6872109733173355],
					[64.250327847346526, 7.6872109159602235],
					[64.250327789765805, 7.6872109001237954],
					[64.248536121694585, 7.6872969995459641],
					[64.246597351607122, 7.6867609345648402],
					[64.246025194695548, 7.6852221501029057],
					[64.245583497893378, 7.6821138726852212],
					[64.245277997717949, 7.6799360720458605],
					[64.245277985720605, 7.6799360354874882],
					[64.245277948343983, 7.6799359712603579],
					[64.245277922049041, 7.6799359415724657],
					[64.243752921643548, 7.678755442061771],
					[64.243752841597356, 7.6787554231936541],
					[64.243752694660529, 7.6787554508358351],
					[64.243752627769908, 7.6787554973461329],
					[64.242797027651164, 7.6803665978050732],
					[64.242797024137232, 7.6803666062231537],
					[64.242797016193677, 7.6803666210403305],
					[64.242797012679773, 7.680366629458411],
					[64.241730412894157, 7.6832360307784873],
					[64.241730407975894, 7.6832360825762187],
					[64.241730425015021, 7.683236180767957],
					[64.24173044697244, 7.6832362271619479],
					[64.242988708773851, 7.6847417831213471],
					[64.24343869664105, 7.6881638896944251],
					[64.242774813679389, 7.691013816883582],
					[64.24141655578984, 7.692955457873599],
					[64.240463883949005, 7.6936275665152909],
					[64.239350199315751, 7.6930554760605565],
					[64.239311299857647, 7.6896110979912704],
					[64.239311296944564, 7.6896110733807843],
					[64.239311284875726, 7.6896110285800727],
					[64.239311275701453, 7.689611006328362],
					[64.238822376167434, 7.6886971063239242],
					[64.238822369795272, 7.6886970963141366],
					[64.238822357051006, 7.6886970762945612],
					[64.23882234980023, 7.6886970683887244],
					[64.237769550341042, 7.6874942685197318],
					[64.237769451327239, 7.6874942360938352],
					[64.237769279399402, 7.6874942793039232],
					[64.23776920469119, 7.6874943550249739],
					[64.237244205147789, 7.6897110532853281],
					[64.237244205184737, 7.6897110574079983],
					[64.237244202548908, 7.6897110637194048],
					[64.237244202585885, 7.6897110678420733],
					[64.236983104723379, 7.6912888509256687],
					[64.236319211237941, 7.6931971336211085],
					[64.236319206899907, 7.6931971503230088],
					[64.236319200915105, 7.6931971835997901],
					[64.23631920016544, 7.6931972001323192],
					[64.236319200099004, 7.6938248891827898],
					[64.235230494124437, 7.6945026299596098],
					[64.235230469208204, 7.6945026538172048],
					[64.235230428365085, 7.694502703170552],
					[64.235230410699302, 7.6945027349344928],
					[64.234558111219812, 7.6964110341632592],
					[64.234558106131345, 7.6964110673949921],
					[64.234558105840946, 7.6964111354549551],
					[64.234558113330294, 7.6964111701564661],
					[64.235130409229981, 7.6979500599525457],
					[64.235847006223722, 7.7007333509084246],
					[64.235847015396331, 7.7007333731610688],
					[64.235847039105764, 7.700733415352504],
					[64.235847052745513, 7.7007334353333796],
					[64.236960910628781, 7.7019333909954097],
					[64.237555401277334, 7.7059750227101507],
					[64.238035888392361, 7.7115887740021121],
					[64.237213821681848, 7.7119387156506276],
					[64.237213796690042, 7.7119387312488694],
					[64.237213753022559, 7.7119387662758792],
					[64.237213734365014, 7.7119387877659964],
					[64.236122034376749, 7.7135554874815746],
					[64.236122028187694, 7.7135554980797583],
					[64.236122016706773, 7.7135555192344132],
					[64.236122012330085, 7.7135555318104903],
					[64.235183111681039, 7.7161027310942565],
					[64.235183108237464, 7.7161027477503046],
					[64.235183102229328, 7.7161027789596179],
					[64.235183099682899, 7.7161027955740824],
					[64.235138700043365, 7.7182971965262297],
					[64.235138702000299, 7.7182972149940081],
					[64.235138708641557, 7.7182972559273741],
					[64.235138715102067, 7.7182972762486957],
					[64.235641408280074, 7.7195276128143959],
					[64.234261224917233, 7.7195914930085063],
					[64.233977981826399, 7.7189777167109606],
					[64.233977952793737, 7.7189776809390871],
					[64.233977884089612, 7.7189776243212398],
					[64.233977842641977, 7.7189776056194139],
					[64.2328029191534, 7.7187192210390974],
					[64.231955763949856, 7.7175054865281147],
					[64.231955725963829, 7.7175054532368419],
					[64.231955639425507, 7.717505409823584],
					[64.231955589976067, 7.7175053997431355],
					[64.230858359239363, 7.7175580967097224],
					[64.230019558693883, 7.7169692664475047],
					[64.229452981688979, 7.7157443165636641],
					[64.229452977112885, 7.7157443064677897],
					[64.229452967099803, 7.7157442904392131],
					[64.229452960729503, 7.7157442804265468],
					[64.228339092150861, 7.7142332243225766],
					[64.228189098702217, 7.7129860754557855],
					[64.228189071280781, 7.7129860190074089],
					[64.2281889879484, 7.7129859321729626],
					[64.228188931140281, 7.7129859018285831],
					[64.226661131311303, 7.7127442011126872],
					[64.226661092753844, 7.7127442049661896],
					[64.226661020197128, 7.7127442207069921],
					[64.226660985337034, 7.7127442367571399],
					[64.224506686845743, 7.7142542116471926],
					[64.224869596386043, 7.7125139403469714],
					[64.224869537057359, 7.7125138276731153],
					[64.224869355994457, 7.7125137515770232],
					[64.224869234260282, 7.7125137881548032],
					[64.223785934215371, 7.7141304889588147],
					[64.223785920994501, 7.7141305163681446],
					[64.223785904439026, 7.7141305727890348],
					[64.223785900207389, 7.7141306018422355],
				],
			],
		},
		{
			fldNpdidField: "43686",
			wlbNpdidWellbore: "424",
			fldName: "GULLFAKS",
			fldCurrentActivitySatus: "Producing",
			wlbName: "34/10-1",
			fldDiscoveryYear: "1978",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43686",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43686",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "050",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=424",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "21188",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[61.191819206421947, 2.1176249772003159],
					[61.191819216294043, 2.1176250646671577],
					[61.191819230142649, 2.1176251064474951],
					[61.192627627751918, 2.1189168027455056],
					[61.193627624392171, 2.1206583939420192],
					[61.194280415171008, 2.1219722772391476],
					[61.194905407937959, 2.1237972552296762],
					[61.195430406009578, 2.1259444470813609],
					[61.195430406197865, 2.1259444507751248],
					[61.195430408168704, 2.1259444540786996],
					[61.195430408357005, 2.1259444577724582],
					[61.195749808380448, 2.1270278562946321],
					[61.195749809648291, 2.1270278634871809],
					[61.19574981476363, 2.127027875440147],
					[61.195749817813969, 2.1270278822425603],
					[61.19605261768352, 2.1277000818735474],
					[61.196052630399599, 2.1277001015009263],
					[61.196052659302786, 2.127700138128517],
					[61.196052674598569, 2.1277001553237938],
					[61.196358175233918, 2.1279445551156386],
					[61.196358200333655, 2.1279445701656274],
					[61.196358253721776, 2.127944592097625],
					[61.196358282901407, 2.1279445987845662],
					[61.19668058314997, 2.1279723983162326],
					[61.196680593142226, 2.127972399864559],
					[61.196680614721039, 2.1279723988772021],
					[61.196680624619169, 2.1279723985785695],
					[61.197050008813697, 2.127925200947669],
					[61.197399975378623, 2.1279390996847005],
					[61.197677722368439, 2.1279973882939496],
					[61.198058151298937, 2.1282445420574856],
					[61.198438719235895, 2.1288944874267965],
					[61.198758114306891, 2.1297167714411671],
					[61.198758119421974, 2.1297167833957062],
					[61.198758130449349, 2.1297168052626669],
					[61.198758137252916, 2.1297168149800663],
					[61.199210937406853, 2.1303584149687413],
					[61.199210943225005, 2.1303584230343029],
					[61.199210957629226, 2.1303584404275324],
					[61.199210965229902, 2.1303584481030864],
					[61.199735964858952, 2.1308362479749627],
					[61.199735976118838, 2.1308362567179087],
					[61.199735997653207, 2.1308362725516625],
					[61.199736009710278, 2.1308362792524607],
					[61.200311009785032, 2.1311251791940218],
					[61.20031101433549, 2.131125180066245],
					[61.200311022639241, 2.1311251838528711],
					[61.200311027283817, 2.13112518657227],
					[61.200833198891544, 2.1313307751085828],
					[61.20141095786218, 2.1317890418099541],
					[61.201872028646896, 2.1323695054359275],
					[61.202280419073041, 2.1332500838800401],
					[61.202280419167096, 2.1332500857273651],
					[61.202280420152462, 2.1332500873797211],
					[61.202280421137793, 2.1332500890320807],
					[61.202672021124421, 2.1340417897411839],
					[61.202672026051083, 2.134041798003218],
					[61.202672036795683, 2.1340418143323703],
					[61.202672042707675, 2.134041824246812],
					[61.203035943167144, 2.1345029240611937],
					[61.203035948891007, 2.1345029302811223],
					[61.203035962215345, 2.1345029441784971],
					[61.20303596883052, 2.1345029502034989],
					[61.203477669146153, 2.1348862502460513],
					[61.203477698983825, 2.1348862698722679],
					[61.203477763254554, 2.1348862931761001],
					[61.203477796984416, 2.1348863007434278],
					[61.204133279929472, 2.1348946003614984],
					[61.204869361689525, 2.1350362957422262],
					[61.204869369007881, 2.1350362978778028],
					[61.204869381673952, 2.1350362988438683],
					[61.204869388898267, 2.1350362991319756],
					[61.205697119783167, 2.1350807952268367],
					[61.20621924413684, 2.1355362295062132],
					[61.206655413630287, 2.1362833784088124],
					[61.206958107340682, 2.1374056524861955],
					[61.206958107434687, 2.137405654333798],
					[61.206958109405271, 2.1374056576392531],
					[61.206958108607978, 2.1374056596817241],
					[61.207269209152386, 2.1383972607543513],
					[61.207269213187502, 2.1383972692130975],
					[61.20726922036647, 2.1383972863254312],
					[61.207269224401607, 2.1383972947841592],
					[61.207641523911725, 2.1390833949758821],
					[61.207641528838124, 2.1390834032399821],
					[61.207641538690858, 2.1390834197681969],
					[61.207641545305854, 2.1390834257949805],
					[61.208069244761752, 2.1396084254488317],
					[61.208069259962222, 2.1396084408085088],
					[61.208069291957777, 2.1396084674429172],
					[61.208069308752897, 2.1396084787176117],
					[61.208680500783146, 2.1399195738625765],
					[61.209274883950343, 2.1402945634554285],
					[61.209824860619001, 2.1407307459141065],
					[61.210385949048678, 2.1413807312713184],
					[61.210385955663583, 2.1413807372990981],
					[61.210385971755166, 2.1413807524659205],
					[61.210385980246613, 2.1413807599519448],
					[61.211305479906713, 2.1420695597159902],
					[61.211305492057257, 2.1420695682711881],
					[61.211305517953051, 2.1420695812962918],
					[61.211305530900979, 2.1420695878088383],
					[61.212755530710432, 2.1426029888237594],
					[61.212755538920014, 2.1426029907667434],
					[61.212755556230462, 2.1426029944579681],
					[61.212755563548718, 2.1426029965957296],
					[61.214733256898533, 2.1429612958163902],
					[61.216541649844665, 2.1434279944258514],
					[61.216541651627267, 2.1434279940362977],
					[61.21654165528642, 2.1434279951053865],
					[61.21654165706903, 2.1434279947158319],
					[61.217494356988404, 2.1436362949152832],
					[61.217494368857089, 2.1436362979278822],
					[61.217494393297926, 2.1436363000618317],
					[61.217494404978751, 2.1436362993779481],
					[61.218750005206019, 2.1436029996908319],
					[61.21875001243037, 2.143602999980823],
					[61.218750024017275, 2.1436029974485544],
					[61.218750031241598, 2.1436029977385695],
					[61.22018613082394, 2.143380798668753],
					[61.220186131621297, 2.1433807966255514],
					[61.221405632081343, 2.1431834974328514],
					[61.221405690858937, 2.143183465894988],
					[61.221405775245969, 2.1431833708201551],
					[61.221405800058037, 2.1431833093265],
					[61.221466900357875, 2.1417556081314859],
					[61.221466900263906, 2.1417556062830223],
					[61.221466899982033, 2.1417556007376217],
					[61.221466899888064, 2.1417555988891559],
					[61.221461300197461, 2.1405694205539922],
					[61.221539077832674, 2.1402168231461363],
					[61.221788891432958, 2.140053045490661],
					[61.222274878406459, 2.1404279583939938],
					[61.222274894216284, 2.1404279680201395],
					[61.222274926633276, 2.1404279852290053],
					[61.222274944131733, 2.1404279926168344],
					[61.22286934416659, 2.1406001928819536],
					[61.222869358709218, 2.1406001953090761],
					[61.222869387700534, 2.1406001983147527],
					[61.222869403134446, 2.1406002005469524],
					[61.223597198757268, 2.1405891005212903],
					[61.223983247876383, 2.1405973991380565],
					[61.224235959760911, 2.1407307519784466],
					[61.224424807286617, 2.1410250698893156],
					[61.224483098903491, 2.1414917052495666],
					[61.224416500619292, 2.1424749763074957],
					[61.224216503011604, 2.1436138657820516],
					[61.224216503105545, 2.1436138676306871],
					[61.224216502402093, 2.1436138715227937],
					[61.224216501604715, 2.1436138735662666],
					[61.224080402141809, 2.1446166710706063],
					[61.223985902101695, 2.1452221689130888],
					[61.223985906326064, 2.1452222165879076],
					[61.223985935978249, 2.1452223035654328],
					[61.22398596060868, 2.1452223449115526],
					[61.224699861373708, 2.145914044221422],
					[61.224699864141542, 2.1459140454857719],
					[61.224699869864978, 2.1459140517118485],
					[61.224699872726696, 2.1459140548248903],
					[61.225241572704476, 2.1463612533745131],
					[61.225241586731329, 2.1463612633940103],
					[61.225241617365086, 2.1463612810000017],
					[61.225241632095454, 2.1463612871273212],
					[61.225780532358186, 2.1465557882159287],
					[61.225780571435436, 2.1465557946310647],
					[61.225780648744902, 2.1465557908226374],
					[61.225780686977089, 2.1465557805990954],
					[61.226522286816056, 2.146197379746559],
					[61.226522298215237, 2.1461973735168631],
					[61.226522320028288, 2.1461973594034753],
					[61.226522329550917, 2.1461973517145458],
					[61.227469530015874, 2.1453862527330281],
					[61.22811401038981, 2.1448390687129621],
					[61.228641769895553, 2.1445807885273607],
					[61.229205649590376, 2.1444362936563124],
					[61.229205715262694, 2.1444362381711328],
					[61.229205781258607, 2.1444361003586025],
					[61.229205781394342, 2.1444360143333929],
					[61.228969585906569, 2.143933225284298],
					[61.2287390896145, 2.1432388375810221],
					[61.228739052832836, 2.1432387876705228],
					[61.228738959193684, 2.1432387184112947],
					[61.228738899662233, 2.1432386996471875],
					[61.228730734785643, 2.1432386995638466],
					[61.228658484816677, 2.1430610249172415],
					[61.228658484722736, 2.1430610230683484],
					[61.228658483737462, 2.1430610214143457],
					[61.228658482752245, 2.1430610197603293],
					[61.228030785956605, 2.1416249266949521],
					[61.22764458939087, 2.1405221339946148],
					[61.22764458732631, 2.1405221288380467],
					[61.227644583197247, 2.1405221185248782],
					[61.22764458024146, 2.1405221135632271],
					[61.227308479828245, 2.1398193131943666],
					[61.227308473916565, 2.1398193032713326],
					[61.2273084601226, 2.1398192801175808],
					[61.227308453319594, 2.1398192703895171],
					[61.226847353154916, 2.1392748703731397],
					[61.226847348416456, 2.1392748658016947],
					[61.22684733804828, 2.1392748568537949],
					[61.226847333215844, 2.1392748504335719],
					[61.22640574677763, 2.1388804635514211],
					[61.225969564891713, 2.138322086109405],
					[61.225608471682257, 2.137716597266154],
					[61.225608470790988, 2.1377165974611576],
					[61.225608469805664, 2.13771659580748],
					[61.225608468820369, 2.1377165941537966],
					[61.225208468560588, 2.137080493716232],
					[61.225208466589983, 2.1370804904089455],
					[61.225208462648745, 2.1370804837943602],
					[61.225208459786799, 2.1370804806821089],
					[61.224555759758729, 2.1362054804624551],
					[61.224555753940862, 2.1362054723895758],
					[61.22455573972519, 2.1362054586776051],
					[61.224555733016054, 2.1362054507997672],
					[61.223844533069112, 2.1355720502815658],
					[61.223844524671364, 2.1355720446427444],
					[61.223844506890536, 2.1355720317115785],
					[61.223844497601526, 2.1355720262678095],
					[61.222680700389496, 2.1349192266422397],
					[61.221030702277744, 2.1339387278392028],
					[61.221030697727258, 2.1339387269662033],
					[61.221030690220744, 2.1339387211331933],
					[61.221030685576217, 2.1339387184117786],
					[61.219852885917767, 2.1333804186493963],
					[61.219852878599362, 2.1333804165134249],
					[61.21985286574516, 2.1333804118512734],
					[61.219852859318046, 2.1333804095202114],
					[61.21839446407769, 2.1329304106962415],
					[61.216900077358524, 2.1323776144159452],
					[61.216019483958547, 2.1319720183564286],
					[61.2160194811906, 2.1319720170936138],
					[61.216019474763478, 2.1319720147630736],
					[61.21601947199558, 2.1319720135002496],
					[61.215388979026272, 2.1317304168967501],
					[61.21474180715817, 2.131424828152896],
					[61.213964039978386, 2.130802656695546],
					[61.213041856815579, 2.1297276759871937],
					[61.212316861783634, 2.1287304821515511],
					[61.212316860798211, 2.1287304804988332],
					[61.212316858827414, 2.1287304771933742],
					[61.212316857044875, 2.1287304775836904],
					[61.211591856796908, 2.12780547724714],
					[61.211591846239791, 2.1278054646114084],
					[61.211591822640024, 2.1278054436211806],
					[61.211591810394523, 2.1278054332236507],
					[61.210691809567898, 2.1272109325496804],
					[61.210691800278582, 2.1272109271104367],
					[61.210691779917454, 2.1272109166223343],
					[61.210691768845628, 2.1272109115734636],
					[61.209908369148764, 2.1269220119047909],
					[61.209908357279801, 2.1269220088990708],
					[61.209908334433138, 2.1269220026924223],
					[61.20990832176706, 2.1269220017296],
					[61.209216730986476, 2.126847001620408],
					[61.208550050644291, 2.1267081052368209],
					[61.20785006171652, 2.1264804101182433],
					[61.207850060731118, 2.126480408465814],
					[61.207263996732621, 2.1262942209535525],
					[61.206786259164737, 2.1259054725332502],
					[61.206578000207465, 2.1254971517189443],
					[61.206575199619884, 2.1249417188719608],
					[61.20677519426976, 2.1239306513828007],
					[61.20722238906685, 2.1225556641845253],
					[61.207619587743551, 2.1214250689808338],
					[61.207936283208028, 2.120566782369846],
					[61.20829187184399, 2.1198918022511339],
					[61.208602964770265, 2.1194362134685703],
					[61.208602968052517, 2.1194362071441302],
					[61.208602973914303, 2.1194361983858694],
					[61.208602976399554, 2.1194361941044493],
					[61.208841875928108, 2.11899179444708],
					[61.208841879210354, 2.1189917881225262],
					[61.208841884977844, 2.118991777516479],
					[61.208841887274552, 2.1189917695397149],
					[61.209011286753295, 2.1185417698536138],
					[61.209011289238525, 2.11854176557206],
					[61.209011293129187, 2.1185417535090747],
					[61.20901129373766, 2.1185417477706956],
					[61.209105794291112, 2.1181500470747596],
					[61.209105794702381, 2.1181500021447923],
					[61.209105775917337, 2.1181499165843385],
					[61.209105756815319, 2.1181498778015162],
					[61.20859185666356, 2.1174942766209361],
					[61.208591848076608, 2.1174942672942318],
					[61.208591829025934, 2.1174942471840725],
					[61.20859181865648, 2.1174942382482582],
					[61.208014019262336, 2.117069238327328],
					[61.208013976053543, 2.1170692216474363],
					[61.208013886919495, 2.1170692056977605],
					[61.208013841088516, 2.1170692082755398],
					[61.207474940841756, 2.1172359095755491],
					[61.207474923110993, 2.1172359153320057],
					[61.207474889809134, 2.1172359338442068],
					[61.20747487414372, 2.1172359447524114],
					[61.207038829200251, 2.1175915004817396],
					[61.206480660814023, 2.1175914999438867],
					[61.205886231633016, 2.1171942474612471],
					[61.205280755422926, 2.1165276735249665],
					[61.204719560796256, 2.1157609813316189],
					[61.204719556948334, 2.115760976571309],
					[61.204719548267001, 2.1157609653987794],
					[61.204719542542307, 2.1157609591820079],
					[61.204094543155776, 2.1151192599700468],
					[61.204094540293404, 2.1151192568617887],
					[61.204094532691897, 2.1151192491888611],
					[61.204094528938271, 2.1151192462760648],
					[61.203400129015414, 2.1145331463135042],
					[61.203400124370532, 2.1145331435963248],
					[61.203400113109701, 2.1145331348583429],
					[61.203400106588013, 2.1145331306848574],
					[61.202641806938608, 2.11405263129152],
					[61.202641800416949, 2.1140526271182134],
					[61.202641783808573, 2.1140526195535805],
					[61.202641777286885, 2.1140526153802632],
					[61.202556452106805, 2.1140172171262317],
					[61.201602996256717, 2.1093999603375226],
					[61.201602988885448, 2.1093999395406806],
					[61.201602973440458, 2.1093999018370018],
					[61.201602961707408, 2.1093998838654429],
					[61.198880761788779, 2.10563318366389],
					[61.198880759817463, 2.1056331803612918],
					[61.198880755969242, 2.1056331756030748],
					[61.198880753997926, 2.1056331723004758],
					[61.19692515390993, 2.1032665727052984],
					[61.196925083626169, 2.1032665377167756],
					[61.196924937965981, 2.103266526754858],
					[61.196924863669707, 2.1032665542794593],
					[61.194910963761892, 2.1051442538835414],
					[61.19491094509916, 2.1051442766563158],
					[61.194910915375722, 2.1051443298702335],
					[61.194910905111804, 2.1051443582689435],
					[61.193808105093964, 2.1102527581182344],
					[61.193808102703372, 2.11025276424501],
					[61.193808100784558, 2.1102527796054122],
					[61.193808100176419, 2.1102527853411268],
					[61.193474800349406, 2.1150666858527765],
					[61.193474806083259, 2.1150667275459023],
					[61.193474834296154, 2.1150668035262421],
					[61.193474855978209, 2.1150668398555768],
					[61.195080455748389, 2.1167334388246855],
					[61.195080481929075, 2.1167334573633809],
					[61.195080540246366, 2.1167334875328021],
					[61.195080571397469, 2.1167334975120524],
					[61.196147907863754, 2.1168900967340183],
					[61.195660982839549, 2.1173137366754551],
					[61.194927719784232, 2.1177387157656611],
					[61.194344375872973, 2.1179108984402446],
					[61.193825054384945, 2.1178831037543397],
					[61.193411193028268, 2.1176665236184249],
					[61.193411191151498, 2.1176665221623301],
					[61.193411188383486, 2.117666520901555],
					[61.193411187397963, 2.1176665192501467],
					[61.193041787015027, 2.1174887204664161],
					[61.193041771392394, 2.1174887145533146],
					[61.193041738364556, 2.1174887031177789],
					[61.193041721147935, 2.1174887012887336],
					[61.192644420952576, 2.1174470010422977],
					[61.192644412837048, 2.1174470009534776],
					[61.192644395714659, 2.1174470009711697],
					[61.192644387599117, 2.1174470008823358],
					[61.192286088092729, 2.1174691991093888],
					[61.192286074723938, 2.1174692020391106],
					[61.192286048877548, 2.1174692077032562],
					[61.19228603729124, 2.1174692102423518],
					[61.191819336699766, 2.1176248104717481],
					[61.191819298238642, 2.117624833838367],
					[61.191819235533615, 2.117624894261763],
					[61.191819206421947, 2.1176249772003159],
				],
				[
					[61.173777600631716, 2.1091889203484095],
					[61.174202600813047, 2.1134000192324565],
					[61.174202610720378, 2.1134000543867528],
					[61.174202640947122, 2.1134001168151255],
					[61.174202662252064, 2.113400145739551],
					[61.178988761568036, 2.117975145250004],
					[61.178988800603022, 2.1179751684319919],
					[61.178988883875078, 2.1179751931248432],
					[61.178988929191888, 2.1179751981322741],
					[61.18211112915936, 2.1175112985541613],
					[61.1821111300506, 2.1175112983589321],
					[61.182111131833153, 2.1175112979684618],
					[61.187716732125764, 2.1165918961898798],
					[61.187716786629288, 2.1165918693099348],
					[61.187716869781326, 2.1165917857416905],
					[61.187716897538557, 2.1165917292487197],
					[61.188541892750038, 2.1111000601329843],
					[61.18936958039621, 2.1094000879868706],
					[61.189369584642499, 2.1094000123686327],
					[61.18936954092937, 2.1093998800536928],
					[61.189369491984401, 2.1093998217060403],
					[61.184580692017789, 2.1069359213035486],
					[61.184580609876896, 2.1069359187907364],
					[61.184580470991364, 2.1069359698000634],
					[61.184580414435409, 2.1069360270145863],
					[61.183752614346233, 2.1090555272722744],
					[61.183752611861266, 2.1090555315510016],
					[61.183752608768138, 2.109055541563615],
					[61.183752607268772, 2.1090555474929746],
					[61.182930493450485, 2.1120190384150979],
					[61.181052932978879, 2.1103554507297817],
					[61.181052893145832, 2.1103554295973788],
					[61.181052808981448, 2.1103554051189568],
					[61.181052763853195, 2.1103554038143519],
					[61.1785610813386, 2.1108109007627522],
					[61.176483354682631, 2.1108386988614449],
					[61.173777903570262, 2.1091887288927773],
					[61.173777799053944, 2.1091887294125136],
					[61.173777647091889, 2.1091888243152557],
					[61.173777600631716, 2.1091889203484095],
				],
				[
					[61.162066499928883, 2.1933610931382246],
					[61.1620665003949, 2.1933611023641917],
					[61.162066502404755, 2.1933611243135691],
					[61.162066502870772, 2.1933611335395353],
					[61.163866502949986, 2.2039056344834322],
					[61.16386650412057, 2.2039056398268095],
					[61.163866507353205, 2.2039056503208965],
					[61.163866507725444, 2.2039056577022387],
					[61.166149807686871, 2.2115500567741213],
					[61.166149844536058, 2.2115501085170335],
					[61.166149942784507, 2.2115501805848226],
					[61.166150005075238, 2.2115502007172441],
					[61.168472130601593, 2.2114974026243619],
					[61.170780408585735, 2.2133668028935842],
					[61.171660900618924, 2.2220000198971062],
					[61.171660910405585, 2.222000053238729],
					[61.171660937723559, 2.222000112654563],
					[61.171660957130563, 2.2220001401902532],
					[61.175349840978612, 2.2257557242568855],
					[61.177647028788158, 2.2295473042206373],
					[61.177647046931405, 2.2295473245731432],
					[61.177647087582535, 2.2295473624717599],
					[61.177647110981809, 2.2295473798258301],
					[61.181344067823417, 2.2313778583510664],
					[61.178544346817212, 2.2343275652808603],
					[61.176230672781934, 2.2334248131995271],
					[61.176230579605431, 2.2334248239275989],
					[61.176230443686514, 2.2334249166359039],
					[61.176230400052596, 2.2334249988080681],
					[61.176219200292358, 2.235347198648487],
					[61.176219204356464, 2.2353472257656071],
					[61.176219217833477, 2.2353472788487401],
					[61.176219229029371, 2.2353473044309959],
					[61.178516520160848, 2.2391361871552289],
					[61.180338713250734, 2.2438917714190714],
					[61.180338722292007, 2.2438917900052164],
					[61.180338744739487, 2.2438918243728092],
					[61.180338757254162, 2.2438918403459112],
					[61.182183157108575, 2.2457695405849543],
					[61.182183190682466, 2.2457695632320611],
					[61.182183265206206, 2.2457695938757634],
					[61.182183305172025, 2.2457696002174292],
					[61.185888881677236, 2.2456752005987135],
					[61.19005550858256, 2.246527984691828],
					[61.193279731773472, 2.2493245117007667],
					[61.187733361453795, 2.2475470106420374],
					[61.187733345036889, 2.2475470067033361],
					[61.187733312295613, 2.247547000672796],
					[61.187733295172166, 2.2475470006195013],
					[61.183558294921291, 2.2476526005067767],
					[61.180316758217302, 2.2477358979733246],
					[61.176164010596928, 2.2449609346918988],
					[61.176163911052427, 2.2449609262269883],
					[61.176163756369824, 2.2449610042553751],
					[61.176163700155215, 2.2449610872478218],
					[61.175647005639135, 2.2536248937507484],
					[61.172858184405328, 2.2556192374133754],
					[61.172858158675901, 2.255619263457846],
					[61.172858120067744, 2.2556193202533943],
					[61.172858106297483, 2.2556193511957319],
					[61.17143592483464, 2.2614248732027709],
					[61.168183194719269, 2.2634359303031832],
					[61.168183154662472, 2.2634359761966545],
					[61.168183110425588, 2.263436082684311],
					[61.168183104370193, 2.2634361418146964],
					[61.170435904304242, 2.2739500426495134],
					[61.170435939623879, 2.2739501003931459],
					[61.170436040885129, 2.2739501794785064],
					[61.170436104951506, 2.2739501993557063],
					[61.174141704739569, 2.2738502003607084],
					[61.174141732377549, 2.2738501944473],
					[61.174141783903018, 2.2738501796910904],
					[61.174141809481533, 2.2738501686205836],
					[61.178786208750545, 2.2708390675212695],
					[61.178786228610875, 2.2708390502058458],
					[61.17878626538036, 2.2708390106082277],
					[61.178786280414307, 2.2708389868613224],
					[61.181130726752066, 2.2659723964648384],
					[61.185299677237339, 2.2658585082448117],
					[61.183422019629333, 2.2697527121599945],
					[61.183422010159497, 2.2697527571171272],
					[61.183422010034334, 2.2697528448683086],
					[61.18342202027052, 2.2697528874714044],
					[61.183880420356353, 2.2707000862549425],
					[61.183880448238732, 2.2707001213470357],
					[61.183880514856149, 2.2707001742751474],
					[61.183880554667056, 2.2707001956137804],
					[61.187574954605751, 2.2715612955626927],
					[61.187574967271637, 2.271561296583696],
					[61.18757499260343, 2.2715612986257194],
					[61.187575006160863, 2.2715612994557981],
					[61.192669162174234, 2.2714196061192506],
					[61.192183113199967, 2.2743138077385434],
					[61.191252655068553, 2.2752998625399332],
					[61.191252631671148, 2.2752998992863112],
					[61.191252605934572, 2.2752999794728166],
					[61.191252600920755, 2.2753000234854945],
					[61.191691500927739, 2.2791278235035839],
					[61.191691505322495, 2.2791278393658883],
					[61.191691514112016, 2.2791278710904819],
					[61.191691520289908, 2.2791278865712918],
					[61.1926025910292, 2.2810222262175248],
					[61.191647099661317, 2.2858494766540827],
					[61.187983475298807, 2.2801916019362007],
					[61.185700180140849, 2.2754527132674807],
					[61.18570017030433, 2.2754526967065991],
					[61.185700147157135, 2.2754526661949592],
					[61.185700133754402, 2.2754526503972832],
					[61.181555733795491, 2.2717248506033036],
					[61.181555663211512, 2.2717248265212984],
					[61.181555522840803, 2.2717248304447049],
					[61.181555455113575, 2.2717248636082772],
					[61.179694262211015, 2.2736970544848001],
					[61.177369270218982, 2.2756831487069014],
					[61.177369237566687, 2.2756831986125641],
					[61.177369208845121, 2.2756833092587443],
					[61.177369212775801, 2.2756833699992747],
					[61.180558112600508, 2.284241770009523],
					[61.180558115735124, 2.2842417786710891],
					[61.180558123787442, 2.2842417956132053],
					[61.180558128705179, 2.2842418038937491],
					[61.185135928830213, 2.2917973035359256],
					[61.185135937589493, 2.2917973165982413],
					[61.185135957690889, 2.2917973403049721],
					[61.185135969032984, 2.2917973509493721],
					[61.188349816912002, 2.2945834061215251],
					[61.189694204358091, 2.3012667392261701],
					[61.189694204449843, 2.3012667410733871],
					[61.189694204633362, 2.3012667447678381],
					[61.189694204725122, 2.3012667466150529],
					[61.19059420466067, 2.3050806467025233],
					[61.19059421092733, 2.3050806640353847],
					[61.190594227027084, 2.3050806979410741],
					[61.190594235968561, 2.3050807147039225],
					[61.195166536052341, 2.3116612142539315],
					[61.195166562575075, 2.3116612403469126],
					[61.195166624970142, 2.3116612812066144],
					[61.195166660842503, 2.3116612959733285],
					[61.199322160527693, 2.3124945955112093],
					[61.199322199325195, 2.3124945965863013],
					[61.199322273420812, 2.3124945826756349],
					[61.199322308718962, 2.3124945676898694],
					[61.20396116178857, 2.3094779977180742],
					[61.2081192872541, 2.3093530027765965],
					[61.210858126955799, 2.3140612011895394],
					[61.210858164818852, 2.314061237957437],
					[61.210858253711628, 2.3140612862725871],
					[61.210858305724535, 2.3140612994784759],
					[61.214550005821401, 2.3139502006057779],
					[61.217786106085256, 2.3138502005680572],
					[61.217786144333097, 2.313850190550526],
					[61.217786214037751, 2.3138501607517705],
					[61.217786245311366, 2.3138501372727083],
					[61.22151121413436, 2.3099057717808731],
					[61.224291769920065, 2.3088640875598485],
					[61.22429177874443, 2.30886408380896],
					[61.224291797193082, 2.3088640742677815],
					[61.224291805125858, 2.3088640707070649],
					[61.227541805305918, 2.3068501693382304],
					[61.227541830336904, 2.306850147176057],
					[61.227541871116031, 2.3068500973563171],
					[61.227541887755763, 2.3068500695085046],
					[61.228961288004868, 2.3029778691289784],
					[61.228961288712952, 2.3029778652395638],
					[61.228961291020632, 2.302977857270363],
					[61.228961291728716, 2.3029778533809471],
					[61.230866862089307, 2.296222363728321],
					[61.238741752960571, 2.2940779933022983],
					[61.238741757418424, 2.2940779923488437],
					[61.238741765350717, 2.2940779887825484],
					[61.238741769808598, 2.2940779878290889],
					[61.241527869838144, 2.2930418871412126],
					[61.241527897908959, 2.2930418717862762],
					[61.241527947258, 2.2930418313102559],
					[61.241527967736623, 2.2930418082301314],
					[61.24340016828026, 2.2901168081958785],
					[61.243400174953017, 2.2901167974175176],
					[61.243400184640336, 2.2901167747738267],
					[61.243400190329545, 2.2901167623359671],
					[61.245302990107632, 2.2843250612973733],
					[61.245302990907135, 2.2843250592559441],
					[61.245302991706659, 2.2843250572145086],
					[61.245302991614643, 2.2843250553640941],
					[61.249130789915604, 2.270833363624158],
					[61.251030786433233, 2.2660028736029929],
					[61.251030790337111, 2.2660028615409691],
					[61.251030796361789, 2.2660028378000985],
					[61.251030797591092, 2.2660028263128131],
					[61.251530797835883, 2.2621667258330169],
					[61.251530784704848, 2.2621666613165301],
					[61.251530721737844, 2.2621665551404164],
					[61.251530671901897, 2.262166513480786],
					[61.246916772412604, 2.2603776142534264],
					[61.242302927383371, 2.2585859355072033],
					[61.240928046635112, 2.2567110988104577],
					[61.242336253712139, 2.2547612274019415],
					[61.243272337160235, 2.2537779465903376],
					[61.245605728874786, 2.2518057535851637],
					[61.245605755124004, 2.2518057198802635],
					[61.245605790036606, 2.2518056431652194],
					[61.245605799683979, 2.2518056018134351],
					[61.245633500128193, 2.2489361264138581],
					[61.247075194047262, 2.2431611488764385],
					[61.247075196166108, 2.2431611371976587],
					[61.247075198620848, 2.2431611142244972],
					[61.247075199848211, 2.2431611027379184],
					[61.247086300060282, 2.2422056251978755],
					[61.247566892040147, 2.2402806557680677],
					[61.248519589830011, 2.237388962845273],
					[61.248519594772809, 2.2373889355926728],
					[61.248519597433919, 2.2373888807760585],
					[61.248519595152253, 2.2373888532120327],
					[61.248072394816134, 2.2354888548314946],
					[61.248072357695754, 2.2354887973798734],
					[61.248072259271076, 2.2354887213640211],
					[61.248072195107021, 2.2354886996762815],
					[61.245758471206642, 2.2355469951955813],
					[61.245319597894827, 2.2326888691579838],
					[61.245319593587041, 2.2326888551261397],
					[61.245319585862966, 2.2326888268699823],
					[61.245319579772278, 2.2326888132231177],
					[61.241680780378459, 2.2251249135652409],
					[61.241680762846961, 2.2251248874336],
					[61.241680720039398, 2.2251248424542753],
					[61.241680694763353, 2.2251248236065857],
					[61.236602895255615, 2.2223748247415944],
					[61.236602887845471, 2.2223748207342102],
					[61.236602874009407, 2.2223748143764404],
					[61.236602865707816, 2.2223748105617762],
					[61.231514009805636, 2.2205831274463126],
					[61.229675187941297, 2.218711006678006],
					[61.229236297788844, 2.2158499702963335],
					[61.229236263149311, 2.215849908624572],
					[61.229236162374555, 2.2158498220031584],
					[61.229236096425218, 2.2158498007519452],
					[61.227378002312236, 2.2158914941074346],
					[61.227394597915612, 2.2139779948982516],
					[61.228786104881863, 2.2139445996491549],
					[61.232036104770366, 2.2138695997647608],
					[61.235747204901216, 2.2137835007219877],
					[61.235747220946934, 2.2137834972490502],
					[61.235747253131336, 2.2137834921527593],
					[61.235747269177018, 2.213783488679832],
					[61.238541556839785, 2.2127613644813495],
					[61.238991505297648, 2.2146639458749307],
					[61.23899157108341, 2.214664017651816],
					[61.23899173323651, 2.2146640704407488],
					[61.238991828805425, 2.2146640534955435],
					[61.241325129215305, 2.2126973524680476],
					[61.241325151276243, 2.2126973252507267],
					[61.241325185220404, 2.2126972655396142],
					[61.24132519710362, 2.2126972330458248],
					[61.241816896883343, 2.2098167348396442],
					[61.241816898254925, 2.20981670836137],
					[61.241816893959751, 2.2098166587995425],
					[61.241816888199942, 2.2098166338660676],
					[61.240458487955621, 2.2060221332538736],
					[61.240458433700368, 2.2060220758223541],
					[61.240458300069761, 2.2060220168939404],
					[61.240458219802967, 2.2060220155902668],
					[61.235797171068526, 2.2080414958074992],
					[61.229308328570035, 2.2072304019409024],
					[61.224208333315048, 2.2063887032236718],
					[61.224208308967562, 2.2063887028895972],
					[61.224208263133008, 2.2063887053400495],
					[61.224208239863138, 2.206388708510659],
					[61.220950056921431, 2.2074219725290058],
					[61.219108500763951, 2.2055471198148555],
					[61.219116898760838, 2.2045890324575348],
					[61.221447263308022, 2.2035779909980366],
					[61.226097244770571, 2.2025140958715492],
					[61.226097252700129, 2.2025140922839297],
					[61.226097271326587, 2.2025140863781174],
					[61.226097279349268, 2.2025140846394704],
					[61.228427878957568, 2.2015057841312649],
					[61.228427904249429, 2.2015057674329528],
					[61.228427949298457, 2.2015057314974822],
					[61.228427967977908, 2.2015057087553305],
					[61.230308409882134, 2.1985918986544091],
					[61.233097203998057, 2.1985307992951242],
					[61.233097245002469, 2.1985307904009637],
					[61.23309731983953, 2.1985307554724041],
					[61.233097352780788, 2.1985307296313357],
					[61.236380753027532, 2.1946307296195831],
					[61.236380776080345, 2.1946306685219499],
					[61.236380780622447, 2.1946305441298422],
					[61.236380762204973, 2.1946304826849161],
					[61.233627961937238, 2.1908609813165594],
					[61.23362793923777, 2.1908609600710678],
					[61.233627888770421, 2.1908609242896646],
					[61.23362786100251, 2.1908609097537433],
					[61.230847283977006, 2.1899637166893076],
					[61.224839005224574, 2.1862609298517452],
					[61.224838979425776, 2.1862609186338888],
					[61.224838923744691, 2.186260904561721],
					[61.224838895551841, 2.1862608994712871],
					[61.222980700159923, 2.1862997983021293],
					[61.22160016081336, 2.1844137825165135],
					[61.221600148291706, 2.1844137665476491],
					[61.221600118978266, 2.1844137392755072],
					[61.221600103171227, 2.1844137296272153],
					[61.216975103205215, 2.1816359296436589],
					[61.216975089274179, 2.1816359214589984],
					[61.216975060800962, 2.181635910828458],
					[61.216975046071958, 2.1816359046858613],
					[61.21326394593585, 2.1807554053522216],
					[61.213263909813946, 2.1807554038593424],
					[61.213263840006853, 2.1807554134231619],
					[61.213263806321628, 2.180755424479881],
					[61.211394347610259, 2.18175260021783],
					[61.209539024312136, 2.1817914965665173],
					[61.208622435300576, 2.1798917270091005],
					[61.210966798370912, 2.1769668972419063],
					[61.21236390361809, 2.1769391006013779],
					[61.212363963763408, 2.176939078182877],
					[61.212364059009168, 2.1769390014231274],
					[61.212364094109617, 2.1769389470818954],
					[61.212844557407138, 2.175011296310235],
					[61.217491703684054, 2.174916900005075],
					[61.220283304131108, 2.1748612992181262],
					[61.220283332748089, 2.1748612948612616],
					[61.220283388623102, 2.1748612770988354],
					[61.220283414989801, 2.1748612638871698],
					[61.223086214549248, 2.1728890634324145],
					[61.223086223275708, 2.172889057795071],
					[61.223086237069687, 2.172889045447699],
					[61.223086243919944, 2.1728890383496737],
					[61.22402512614331, 2.1719112588470146],
					[61.227300057750988, 2.1699307992453778],
					[61.230552804033067, 2.1698640997416301],
					[61.231950004064721, 2.1698363005244095],
					[61.231950087245316, 2.1698362524926815],
					[61.231950174566492, 2.1698361082105588],
					[61.231950179692006, 2.1698360136151775],
					[61.230580780408346, 2.1669916128043027],
					[61.230580762959839, 2.16699158856379],
					[61.230580720134341, 2.1669915436798939],
					[61.230580695742354, 2.1669915246913836],
					[61.22548623510994, 2.1642192466585648],
					[61.222725161013095, 2.1604415812622486],
					[61.22272508511282, 2.1604415417401044],
					[61.222724928851036, 2.1604415346923642],
					[61.222724848583312, 2.1604415690153647],
					[61.218497052272589, 2.165313765376673],
					[61.215685956207714, 2.1682442623867826],
					[61.21568594935772, 2.1682442694840418],
					[61.215685938331781, 2.1682442830963344],
					[61.215685932373134, 2.1682442899995036],
					[61.213802720876565, 2.1711580545034295],
					[61.206847288671874, 2.1684192197765491],
					[61.202216803260683, 2.1656331282637731],
					[61.202216802369364, 2.165633128457801],
					[61.202216799601693, 2.1656331271924771],
					[61.202216798616753, 2.1656331255390771],
					[61.195727899445579, 2.1619220260177996],
					[61.195727883730889, 2.1619220182350429],
					[61.195727852582451, 2.1619220082106518],
					[61.195727836961417, 2.1619220022749492],
					[61.191083377327409, 2.1610498112787897],
					[61.186914022609628, 2.1582498416178302],
					[61.182750173834521, 2.1544859878897782],
					[61.18183633780523, 2.1516222895810202],
					[61.185108319289462, 2.1496391233065664],
					[61.187427726255493, 2.1505584858552829],
					[61.187427758202062, 2.1505584938254096],
					[61.187427821344727, 2.1505584949935668],
					[61.187427852822239, 2.1505584937311482],
					[61.191158240048317, 2.1495280233320648],
					[61.193008156802833, 2.1514140395273977],
					[61.193008235895043, 2.15141407083008],
					[61.193008388450906, 2.1514140581037271],
					[61.193008462102185, 2.1514140177683827],
					[61.194419561966711, 2.1494668165503978],
					[61.194419575899282, 2.1494667892352175],
					[61.19441959466355, 2.1494667328553194],
					[61.194419600292768, 2.1494667017492515],
					[61.19443909976281, 2.1465889018659334],
					[61.194439095680806, 2.1465888747458899],
					[61.194439082168998, 2.1465888216728763],
					[61.194439070956562, 2.1465887961089081],
					[61.192136271404792, 2.1427887962842034],
					[61.192136248416922, 2.1427887695597341],
					[61.192136195874248, 2.1427887287481302],
					[61.192136165240342, 2.1427887111620798],
					[61.184233369582408, 2.1400498125390981],
					[61.179588973674065, 2.1382081143436622],
					[61.179588955378335, 2.1382081090061873],
					[61.179588916300865, 2.1382081026071491],
					[61.179588896316503, 2.138208099504932],
					[61.173999996835626, 2.1383081003327775],
					[61.17120279685831, 2.1383554005725887],
					[61.171202711999904, 2.1383554508182709],
					[61.171202625788403, 2.1383555983925544],
					[61.171202625420591, 2.1383556973721403],
					[61.174891363597347, 2.1450220057587828],
					[61.173961168915199, 2.1450386986096182],
					[61.171641825534721, 2.1431581444778902],
					[61.171641746113217, 2.1431581244816029],
					[61.171641596889657, 2.143158149568178],
					[61.171641528964102, 2.1431581961077311],
					[61.164597028955157, 2.1548276971144187],
					[61.164597014785869, 2.1548277375109524],
					[61.164597005446389, 2.1548278197647828],
					[61.164597009384856, 2.1548278618161194],
					[61.166427609344552, 2.160563961396321],
					[61.166427619381807, 2.1605639816012574],
					[61.166427643021635, 2.1605640212355732],
					[61.166427656624194, 2.1605640406649536],
					[61.168277614924364, 2.1624528975514736],
					[61.169180401947713, 2.1672417268645021],
					[61.170052573869071, 2.1778024967449321],
					[61.167733373240651, 2.1768887143701798],
					[61.167733354054718, 2.1768887092051394],
					[61.167733315869768, 2.1768887025659804],
					[61.167733295885924, 2.1768886994398313],
					[61.16261669557376, 2.1769941993789512],
					[61.162616627685722, 2.1769942290318096],
					[61.16261652980667, 2.1769943248855594],
					[61.162616499909078, 2.1769943929315976],
					[61.162066499928883, 2.1933610931382246],
				],
				[
					[61.139563702962569, 2.1259722378569119],
					[61.141016499770771, 2.1337306186004463],
					[61.141035899999736, 2.1385972009821801],
					[61.141035910275811, 2.1385972434835248],
					[61.141035946683445, 2.1385973213011691],
					[61.141035974597663, 2.1385973562288596],
					[61.143410975423969, 2.1404973555805689],
					[61.143411062961242, 2.1404973756522985],
					[61.143411217672508, 2.1404973344732561],
					[61.143411285034325, 2.1404972769102941],
					[61.14622237269775, 2.1336334036884206],
					[61.149522353667301, 2.129680728117028],
					[61.149522355355835, 2.1296807258835395],
					[61.149522357935624, 2.1296807234554525],
					[61.149522358732845, 2.1296807214166091],
					[61.153286251972617, 2.1247473302832218],
					[61.156116844682487, 2.1217751380778886],
					[61.156116858189385, 2.1217751202029032],
					[61.156116878964262, 2.1217750858172453],
					[61.156116887920625, 2.1217750670721962],
					[61.158925166000842, 2.1139418310674367],
					[61.165066809435068, 2.1099390686786648],
					[61.165066852760759, 2.1099389994811797],
					[61.165066876878562, 2.109938854267003],
					[61.165066856685094, 2.1099387766005564],
					[61.161258456761985, 2.1051470758986954],
					[61.161258414673895, 2.1051470459513775],
					[61.161258320841341, 2.1051470086945456],
					[61.161258269096869, 2.1051470013850322],
					[61.154158268574655, 2.1062470018864565],
					[61.154158265900911, 2.1062470024724353],
					[61.154158259662253, 2.1062470038397065],
					[61.154158256097297, 2.1062470046210033],
					[61.149430556045331, 2.1073054054268496],
					[61.149430540988611, 2.1073054105903473],
					[61.149430510969502, 2.107305422761431],
					[61.149430496007142, 2.1073054297690308],
					[61.146124896061195, 2.1093109286852902],
					[61.146124861732069, 2.1093109623073474],
					[61.146124813624596, 2.109311043697025],
					[61.146124799751881, 2.1093110896207241],
					[61.145685902408395, 2.1180749648274042],
					[61.144752608625716, 2.1210110394549595],
					[61.144752608016965, 2.121011045181509],
					[61.144752605016926, 2.1210110570242575],
					[61.144752604408211, 2.1210110627507865],
					[61.143824834253046, 2.1258942020900435],
					[61.139563896293069, 2.1259719998964006],
					[61.139563820678347, 2.1259720369162132],
					[61.139563723966042, 2.1259721549746731],
					[61.139563702962569, 2.1259722378569119],
				],
			],
		},
		{
			fldNpdidField: "43745",
			wlbNpdidWellbore: "854",
			fldName: "VISUND",
			fldCurrentActivitySatus: "Producing",
			wlbName: "34/8-1",
			fldDiscoveryYear: "1986",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43745",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43745",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "VISUND INSIDE",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=854",
			fldHcType: "OIL/GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "40726",
			fldDateUpdated: "2022-09-13 00:00:00",
			fldDateUpdatedMax: "2021-12-22 00:00:00",
			fldMainSupplyBase: "Florø",
			fldAreaGeometryWKT: [
				[
					[61.41549980131547, 2.6177471828166778],
					[61.415499801562483, 2.6177472072019707],
					[61.415499809193541, 2.6177472545096396],
					[61.415499815685457, 2.6177472776149098],
					[61.415805416361273, 2.6184667785272948],
					[61.41580543863261, 2.6184668115568397],
					[61.415805495227083, 2.6184668657473815],
					[61.415805528658083, 2.6184668870912389],
					[61.416497058201287, 2.6187279591781976],
					[61.417063722614337, 2.6198056930955853],
					[61.417760922065391, 2.6211528918225229],
					[61.417760943181257, 2.6211529194531678],
					[61.41776099309191, 2.6211529656219565],
					[61.41776102188669, 2.6211529841600818],
					[61.418799904185278, 2.6215918761802799],
					[61.419580469344126, 2.6221251532108063],
					[61.420494252624309, 2.6231084369736224],
					[61.420494261983471, 2.6231084444563799],
					[61.420494277937877, 2.6231084581080824],
					[61.420494287297039, 2.6231084655908399],
					[61.421663782064485, 2.6239057608999059],
					[61.422227675661254, 2.6243501567393506],
					[61.422227687784321, 2.6243501655368484],
					[61.422227711855413, 2.6243501794075401],
					[61.422227724695553, 2.6243501842979984],
					[61.423310995236747, 2.6247890734319985],
					[61.42404987059075, 2.6254140534330643],
					[61.424049878970166, 2.6254140592377548],
					[61.424049896621085, 2.6254140706644167],
					[61.424049905892609, 2.6254140762864058],
					[61.425047082142754, 2.6259445638796168],
					[61.426652662546232, 2.6274612444054353],
					[61.42665266637735, 2.6274612492620228],
					[61.426652673864517, 2.6274612552503798],
					[61.426652677608089, 2.6274612582445722],
					[61.427347077760047, 2.6279945593428562],
					[61.427347086051867, 2.6279945632863235],
					[61.427347100938682, 2.6279945734010655],
					[61.427347109230503, 2.6279945773445501],
					[61.428213790849043, 2.6284362695259977],
					[61.428994275271243, 2.6290612565454685],
					[61.428994309417995, 2.6290612740012054],
					[61.428994379775581, 2.6290612953267338],
					[61.428994417770717, 2.6290612988312629],
					[61.430163890599559, 2.6289558017719754],
					[61.431116664765909, 2.6291251962906665],
					[61.431116673862384, 2.6291251981895032],
					[61.431116692859909, 2.6291251999418455],
					[61.431116701868909, 2.6291251999780076],
					[61.43193889168176, 2.6291168998497372],
					[61.432849983214652, 2.6291945980319436],
					[61.432849987850375, 2.6291946008442069],
					[61.43284999685936, 2.6291946008803424],
					[61.43285000221222, 2.6291945997843569],
					[61.433627802407749, 2.6291862994321766],
					[61.433627868706459, 2.6291862726918778],
					[61.433627967979071, 2.6291861808964625],
					[61.433627999081175, 2.6291861143438475],
					[61.433752999167652, 2.6275583155973963],
					[61.433752999709768, 2.6275583079632776],
					[61.433752999726828, 2.6275582891520779],
					[61.433752999289297, 2.627558279837853],
					[61.43366410042762, 2.6266555870150516],
					[61.43361689971951, 2.6251193942306279],
					[61.433616900436597, 2.6251193903221632],
					[61.433616899194277, 2.6251193830536432],
					[61.433616899019206, 2.6251193793279679],
					[61.433394599841932, 2.6230416894333977],
					[61.433394599993896, 2.6224083008041954],
					[61.433394599906322, 2.6224082989413757],
					[61.433389099739216, 2.621144399059177],
					[61.433389098950869, 2.6211443822939611],
					[61.43338909389324, 2.6211443513579056],
					[61.433389089623958, 2.6211443371870691],
					[61.432908499699877, 2.6197027679375577],
					[61.432908500198231, 2.6190694000567225],
					[61.432908497362511, 2.6190693780694301],
					[61.432908487142782, 2.619069333146864],
					[61.432908479934014, 2.6190693139371146],
					[61.43242798004524, 2.6180805126549149],
					[61.432427976126185, 2.6180805059359087],
					[61.432427969180196, 2.6180804923148795],
					[61.432427965261141, 2.6180804855958528],
					[61.431994564970722, 2.6174526857389031],
					[61.43199455730781, 2.6174526760266246],
					[61.431994541177581, 2.6174526586477533],
					[61.431994531730467, 2.6174526493014563],
					[61.431083454327265, 2.6166526695949646],
					[61.430516868596911, 2.6157554932305023],
					[61.430516860846268, 2.6157554816564699],
					[61.430516842756205, 2.6157554609201048],
					[61.430516833308992, 2.6157554515747439],
					[61.430214033084248, 2.6154859511159718],
					[61.430214022832516, 2.6154859438164122],
					[61.430214002241428, 2.6154859273546975],
					[61.430213991185248, 2.6154859221007647],
					[61.429694557190388, 2.6152220541283908],
					[61.429475185168947, 2.6146832242619324],
					[61.429475177242864, 2.6146832089634562],
					[61.429475157822168, 2.6146831790986984],
					[61.429475147219698, 2.6146831643493895],
					[61.428736262617569, 2.6138776813964553],
					[61.428433485150713, 2.6133388226362877],
					[61.428300192021766, 2.6128888429318016],
					[61.428300187927071, 2.612888832489372],
					[61.428300177865758, 2.6128888101082164],
					[61.428300172878927, 2.6128887998488834],
					[61.42803908085844, 2.612441614608954],
					[61.427561287268929, 2.6111805298442508],
					[61.427561286201339, 2.6111805263025625],
					[61.427561284241676, 2.6111805229439886],
					[61.427561284153931, 2.6111805210815762],
					[61.427211284821823, 2.6103721231597081],
					[61.426994586411119, 2.6098332258277419],
					[61.42699457563269, 2.6098332073564632],
					[61.426994553271406, 2.6098331724594663],
					[61.426994539012242, 2.6098331565832651],
					[61.426169538676447, 2.6090304579114725],
					[61.426169519868886, 2.6090304410896672],
					[61.426169480016185, 2.6090304173072716],
					[61.426169457991172, 2.6090304086675293],
					[61.425302941991895, 2.60876923343092],
					[61.424953003070037, 2.6080499590604869],
					[61.425036300214273, 2.6067833123469231],
					[61.425036294977922, 2.6067832776971143],
					[61.425036273712145, 2.6067832087341722],
					[61.4250362568784, 2.6067831764665361],
					[61.424255757347815, 2.6057998750781945],
					[61.424255719015456, 2.6057998453481401],
					[61.424255632611185, 2.60579980857145],
					[61.424255586323476, 2.6057998011583119],
					[61.422697275388771, 2.6059108930428705],
					[61.421958439155624, 2.6051970554021175],
					[61.421958398410226, 2.6051970318108233],
					[61.421958309856315, 2.6051970067603269],
					[61.421958262852087, 2.60519700325582],
					[61.42143886314787, 2.6052942027847661],
					[61.421438813000115, 2.6052942281269131],
					[61.421438733735833, 2.6052943045720061],
					[61.421438705423604, 2.605294353629644],
					[61.421138713284634, 2.6065610195820277],
					[61.420838757671966, 2.6070192523027562],
					[61.41975822125341, 2.6076637135603042],
					[61.419063848179249, 2.6078526075095616],
					[61.419063839258108, 2.6078526093412671],
					[61.419063823287829, 2.6078526145002283],
					[61.419063816326485, 2.6078526196893979],
					[61.417466654784498, 2.6085969995808842],
					[61.417119397880207, 2.6085997999927932],
					[61.41711939698807, 2.6085998001759294],
					[61.416599997403736, 2.6086080995451022],
					[61.416599931913801, 2.6086081242672456],
					[61.416599834263941, 2.6086082119841816],
					[61.416599802016286, 2.6086082731171931],
					[61.416430402418641, 2.6098749729178885],
					[61.416430400985341, 2.6098749807310857],
					[61.416430399815255, 2.6098749941295272],
					[61.416430400166199, 2.6098750015765373],
					[61.416433100378967, 2.610597129422187],
					[61.415699844300171, 2.6115109740123379],
					[61.415699830186654, 2.6115109994645906],
					[61.415699809096637, 2.6115110489047799],
					[61.415699801403406, 2.6115110767991672],
					[61.415655400884063, 2.6118721768405893],
					[61.415655402199654, 2.6118722047662857],
					[61.415655412143209, 2.6118722628768447],
					[61.415655420595762, 2.6118722893383066],
					[61.415699796637718, 2.6119611404449756],
					[61.415616499953984, 2.6133166888978137],
					[61.415616500129339, 2.6133166926212423],
					[61.415616499763331, 2.6133167039745233],
					[61.415616499938707, 2.6133167076979666],
					[61.415663700402945, 2.614583301349497],
					[61.41562479971094, 2.6159388927594893],
					[61.415583099604476, 2.6167527877994208],
					[61.41549980131547, 2.6177471828166778],
				],
				[
					[
						[61.312527602989526, 2.3477111361742056],
						[61.314074802943225, 2.3560194361027116],
						[61.314074804017153, 2.3560194396226524],
						[61.314074806164967, 2.3560194466625637],
						[61.3140748063472, 2.3560194503720386],
						[61.316258105789906, 2.3644639463289074],
						[61.318158105415243, 2.3727722456685898],
						[61.318158105597021, 2.3727722493786749],
						[61.318158108817507, 2.3727722599416849],
						[61.318158110873611, 2.3727722651286558],
						[61.320624810193664, 2.3799000620786144],
						[61.324360904775766, 2.3915500463588848],
						[61.325897002425862, 2.4011833313280802],
						[61.325897003408102, 2.4011833329953531],
						[61.325897002606872, 2.4011833350392937],
						[61.325897002697374, 2.4011833368949183],
						[61.326645991928331, 2.4052237575147086],
						[61.325869593847017, 2.4022471509682783],
						[61.325869576786644, 2.4022471152016713],
						[61.325869533309103, 2.4022470550178858],
						[61.325869504126096, 2.4022470293100255],
						[61.323333403784162, 2.4006942299430376],
						[61.323333400927886, 2.4006942267971225],
						[61.323333394414021, 2.4006942225490748],
						[61.323333389864693, 2.4006942216352827],
						[61.320288990177787, 2.3991526217292867],
						[61.32028888520864, 2.3991526307795596],
						[61.320288741871359, 2.3991527397789065],
						[61.320288703322156, 2.3991528360174659],
						[61.322083103247977, 2.408661136603635],
						[61.322083104230167, 2.4086611382709426],
						[61.322083104320569, 2.4086611401263545],
						[61.322083104410979, 2.4086611419817618],
						[61.324897003920618, 2.4218611419177005],
						[61.324897004992899, 2.4218611454412193],
						[61.32489700713738, 2.4218611524882312],
						[61.324897008299878, 2.4218611578673745],
						[61.326938701614324, 2.4287194361275533],
						[61.327716500614947, 2.439302815509437],
						[61.327716504190349, 2.4393028335062201],
						[61.327716515088454, 2.4393028724624193],
						[61.327716522321111, 2.4393028915659936],
						[61.332552622059325, 2.4487722906707372],
						[61.332552625986189, 2.4487722973473107],
						[61.332552634821582, 2.4487723123695857],
						[61.33255263874841, 2.4487723190461637],
						[61.338397039447464, 2.4566390191212077],
						[61.338397057380121, 2.4566390359930041],
						[61.338397094939417, 2.4566390675063206],
						[61.338397116349725, 2.4566390817741324],
						[61.343983216160531, 2.4592112817791905],
						[61.343983262542729, 2.459211290819884],
						[61.343983355122212, 2.4592112864316795],
						[61.343983401319463, 2.4592112730028037],
						[61.346766755984689, 2.457583499093281],
						[61.349299913217479, 2.4575474013228784],
						[61.353110932969081, 2.461736212086556],
						[61.356677606665578, 2.4691111599665536],
						[61.357702601855607, 2.4759889280921521],
						[61.357702606228806, 2.475988944066243],
						[61.357702613904273, 2.4759889724853714],
						[61.357702619971718, 2.4759889862288036],
						[61.358722020247875, 2.4780944872054067],
						[61.358722057358371, 2.4780945282393838],
						[61.35872214923517, 2.4780945840988053],
						[61.358722202217713, 2.4780945992970262],
						[61.359791338652649, 2.4780814371436732],
						[61.360783132268814, 2.4781115088358598],
						[61.362277613686558, 2.4801694837128423],
						[61.365094205154314, 2.4928583437993628],
						[61.365094206314083, 2.4928583491881544],
						[61.365094209614902, 2.4928583616379418],
						[61.365094210685413, 2.4928583651684595],
						[61.365568487165199, 2.4942072886186826],
						[61.365513972130564, 2.4942080994217051],
						[61.364952946155285, 2.4937331604261312],
						[61.363408458876002, 2.4917220782485536],
						[61.363408451204833, 2.4917220685879844],
						[61.363408434168008, 2.4917220514971117],
						[61.36340842480233, 2.4917220440668126],
						[61.361400158322645, 2.4901054701119092],
						[61.360697377072178, 2.4887582088941591],
						[61.360697356823216, 2.4887581812166997],
						[61.360697309636713, 2.4887581366407043],
						[61.360697281717933, 2.4887581180702667],
						[61.360277882316851, 2.4885692180145207],
						[61.360277847620125, 2.4885692083653268],
						[61.360277779833396, 2.488569203744579],
						[61.36027774487021, 2.488569207287298],
						[61.359205691369993, 2.4888775659248741],
						[61.357847373701887, 2.4866693011852354],
						[61.357191885348783, 2.4854193228544337],
						[61.356672396878949, 2.4837777593658421],
						[61.356575199005121, 2.482713882193063],
						[61.356575189189563, 2.4827138467148027],
						[61.356575157250703, 2.4827137820813467],
						[61.356575135037936, 2.482713751068419],
						[61.356155734642989, 2.4823304512829445],
						[61.356155685762602, 2.4823304277163425],
						[61.356155583010491, 2.4823304078948536],
						[61.356155528336338, 2.482330413683921],
						[61.355363827720574, 2.4826359139090988],
						[61.355363819782887, 2.4826359174430221],
						[61.355363803907522, 2.4826359245108378],
						[61.355363796059301, 2.4826359299023908],
						[61.354713993627328, 2.4830330089650334],
						[61.35410298367367, 2.4815888215987729],
						[61.35410297965889, 2.481588813055982],
						[61.354102969845556, 2.4815887963428995],
						[61.354102964849439, 2.4815887861287877],
						[61.353447364689785, 2.480630487074913],
						[61.353447361835222, 2.4806304839187199],
						[61.353447356928477, 2.4806304755625064],
						[61.353447354073879, 2.4806304724063226],
						[61.35241955900981, 2.4793859789724926],
						[61.351341863737325, 2.4778526846318694],
						[61.351341831712759, 2.4778526556773368],
						[61.351341760352483, 2.4778526143046937],
						[61.351341720035414, 2.4778526002155226],
						[61.350500069989458, 2.4777692050786846],
						[61.349519529274509, 2.4771054450517873],
						[61.348866873597693, 2.4764387922284685],
						[61.348630799991767, 2.4757638651715612],
						[61.348622399554429, 2.4734360981938561],
						[61.348622398838081, 2.4734360833360811],
						[61.348622393837879, 2.4734360543663478],
						[61.348622391337763, 2.4734360398814754],
						[61.348200191097298, 2.4720860396758808],
						[61.348200154429342, 2.4720859891897309],
						[61.348200057374633, 2.4720859194399569],
						[61.348199996987859, 2.4720859001763551],
						[61.347638897392741, 2.4720942005834594],
						[61.347638847626406, 2.4720942147412486],
						[61.347638761744435, 2.472094264589038],
						[61.347638725628762, 2.4720943002790432],
						[61.3465776261042, 2.4739555009297804],
						[61.346577625391411, 2.4739555048304065],
						[61.346577621111045, 2.4739555094767747],
						[61.346577620398207, 2.4739555133774092],
						[61.346483140261491, 2.4741526692263376],
						[61.345386995254003, 2.4749943134284753],
						[61.345288998760061, 2.4749387262316471],
						[61.345288970305866, 2.4749387152964961],
						[61.345288910277503, 2.474938703456937],
						[61.345288880397604, 2.4749387003226975],
						[61.344497180070192, 2.4750192010310914],
						[61.344497157772963, 2.4750192056906704],
						[61.344497115230894, 2.4750192202080203],
						[61.344497094094123, 2.475019230252157],
						[61.343252693820801, 2.4757942294246726],
						[61.343252666264867, 2.4757942558139776],
						[61.343252622303282, 2.4757943156409197],
						[61.343252605987075, 2.4757943509354838],
						[61.342863705722841, 2.4773082498534635],
						[61.342863705901806, 2.4773082535672502],
						[61.342863704386488, 2.4773082595105564],
						[61.342863703584101, 2.4773082615537527],
						[61.342572004317724, 2.4788193611407219],
						[61.342572002268277, 2.4788193746976956],
						[61.342571999774265, 2.478819397725347],
						[61.342572000311051, 2.4788194088666526],
						[61.342641499665277, 2.4803917007545375],
						[61.342552600306682, 2.482583289004713],
						[61.342430400237632, 2.4845721887776828],
						[61.342430399703247, 2.4845721963913623],
						[61.342430401399653, 2.4845722129173442],
						[61.342430400954655, 2.4845722223879347],
						[61.342563700638252, 2.4858028221166646],
						[61.342563705010193, 2.4858028380846169],
						[61.342563713843447, 2.4858028718774117],
						[61.342563719999234, 2.4858028874732354],
						[61.34293040742395, 2.4865500614096465],
						[61.343202600075841, 2.488255619159021],
						[61.343238699708245, 2.4895583052946426],
						[61.343238702385293, 2.4895583234923402],
						[61.343238710415022, 2.4895583593299122],
						[61.343238715857105, 2.4895583788267133],
						[61.343699806711584, 2.4906444558495955],
						[61.344072000144365, 2.4929000188616666],
						[61.344108099744446, 2.4943361048190416],
						[61.344108101261163, 2.4943361176324332],
						[61.344108105097192, 2.4943361412163876],
						[61.344108106524651, 2.4943361521727518],
						[61.344647006887989, 2.4963139538346653],
						[61.344647008939511, 2.4963139590343166],
						[61.344647013131826, 2.4963139712907001],
						[61.344647016164501, 2.4963139781616595],
						[61.345338716377512, 2.4979444774837982],
						[61.345338717358651, 2.4979444791551781],
						[61.345338719410123, 2.4979444843550502],
						[61.345338720391233, 2.4979444860264484],
						[61.346133120207604, 2.4995778861007354],
						[61.346133128145709, 2.4995779013297157],
						[61.346133147411265, 2.4995779273305154],
						[61.346133157044051, 2.4995779403309344],
						[61.34752205708164, 2.5009918392146502],
						[61.347522068408999, 2.5009918499874564],
						[61.347522090885391, 2.5009918678185481],
						[61.347522102926362, 2.5009918746911697],
						[61.348838764623395, 2.5017251542164338],
						[61.349435938831895, 2.5025362198627206],
						[61.349435940704915, 2.5025362213488211],
						[61.349435944540076, 2.5025362261784156],
						[61.349435947394191, 2.5025362293362483],
						[61.351152643082813, 2.504564024734024],
						[61.351749837402267, 2.5053779158826406],
						[61.352505435567736, 2.5064612141601952],
						[61.352505437440712, 2.5064612156466843],
						[61.352505438421737, 2.5064612173187069],
						[61.352505439313688, 2.5064612171331557],
						[61.353399838924581, 2.5076807179546527],
						[61.353399868178293, 2.5076807456408394],
						[61.35339993400062, 2.5076807844824534],
						[61.353399970658323, 2.5076807974955067],
						[61.354652761019359, 2.5078668966610325],
						[61.356494322714909, 2.5083168872077688],
						[61.357385996731821, 2.5088501716929574],
						[61.357386009664403, 2.5088501783850448],
						[61.357386035351453, 2.5088501880534633],
						[61.357386048194954, 2.508850192887687],
						[61.358108233009723, 2.5090445889488473],
						[61.35988881264214, 2.5098390796936152],
						[61.360774897823916, 2.5103057728664955],
						[61.361999867574823, 2.5111084526731831],
						[61.362658147232175, 2.5118501292709672],
						[61.363419244335532, 2.5127973250799824],
						[61.363419260388888, 2.5127973405097106],
						[61.363419293209567, 2.5127973674672579],
						[61.363419311849782, 2.5127973804823771],
						[61.364166552110845, 2.5131640498557211],
						[61.364847022106026, 2.514494490770057],
						[61.36484702799175, 2.514494500807571],
						[61.364847039763156, 2.5144945208826113],
						[61.364847047343794, 2.514494528690959],
						[61.365780447301283, 2.5155973285988358],
						[61.36578046710018, 2.5155973470063429],
						[61.365780510890801, 2.5155973773192182],
						[61.365780533990552, 2.5155973894099639],
						[61.366602708804969, 2.5158834803179531],
						[61.367677670711245, 2.516616854327657],
						[61.368380451017671, 2.5173362334170339],
						[61.368774845163117, 2.5178168262941947],
						[61.368774921686594, 2.5178168629502498],
						[61.368775075813119, 2.5178168647094861],
						[61.368775153416159, 2.5178168298126673],
						[61.369502934861593, 2.5169557514515808],
						[61.371216770113733, 2.5158112924156977],
						[61.372002821691012, 2.5157251988874938],
						[61.372002826150769, 2.5157251979603172],
						[61.372002833286444, 2.5157251964768199],
						[61.372002836854264, 2.5157251957350741],
						[61.37266351777253, 2.5155996460126406],
						[61.373480804294566, 2.5156116490242377],
						[61.373716586615373, 2.5157723655576487],
						[61.373716603204507, 2.5157723733727813],
						[61.373716636382795, 2.5157723890030583],
						[61.373716652882926, 2.5157723949593147],
						[61.374005553346827, 2.5158418944036787],
						[61.374005633711931, 2.5158418795721822],
						[61.374005756630353, 2.5158417939395998],
						[61.374005799183657, 2.5158417231385157],
						[61.374032563694684, 2.5156113863360825],
						[61.374055252892191, 2.5155932811346613],
						[61.37385810003412, 2.5188055879739619],
						[61.373858100745686, 2.5188056028450476],
						[61.373858104041645, 2.5188056340754033],
						[61.37385810573408, 2.5188056506200356],
						[61.374533104966773, 2.5214444465194923],
						[61.375097003941129, 2.524086142648831],
						[61.375097006080559, 2.5240861497142926],
						[61.375097011251405, 2.5240861636600309],
						[61.375097012587716, 2.5240861727697146],
						[61.376230411756865, 2.5270361688585661],
						[61.37792201019785, 2.5319917627842239],
						[61.379569209314646, 2.5371583607445953],
						[61.379569210295337, 2.5371583624189991],
						[61.379569211364704, 2.5371583659527004],
						[61.379569212345416, 2.5371583676271041],
						[61.380591507812213, 2.5400056564907652],
						[61.381574803588677, 2.5441250408009695],
						[61.38214420194636, 2.5472972291575409],
						[61.382563701139318, 2.5511056172061886],
						[61.382830399779927, 2.5554472126897925],
						[61.382830404320984, 2.5554472324073401],
						[61.382830415275613, 2.555447273333225],
						[61.382830422581186, 2.555447294357164],
						[61.384113723207257, 2.5578667934962551],
						[61.384113726148612, 2.557866798522177],
						[61.384113732923311, 2.5578668083897318],
						[61.384113735864659, 2.5578668134156528],
						[61.385794236278223, 2.560280714667694],
						[61.385794259904863, 2.5602807379571],
						[61.38579431402929, 2.560280777482463],
						[61.385794344438644, 2.5602807918586836],
						[61.390961043722378, 2.5617807921105902],
						[61.3909610446144, 2.5617807919263251],
						[61.390961045594821, 2.5617807936021246],
						[61.390961047378894, 2.5617807932335874],
						[61.395288477941698, 2.5629722907317394],
						[61.39360818276559, 2.5643748372742876],
						[61.392349899964664, 2.5651415251557257],
						[61.391152791710681, 2.5657858771637576],
						[61.390611251789267, 2.5655415495980134],
						[61.39000298312876, 2.5641721185751933],
						[61.390002945062044, 2.5641720757226261],
						[61.390002852147852, 2.5641720160215282],
						[61.390002797300411, 2.5641719991730096],
						[61.389466696846135, 2.564180399157765],
						[61.389466667408811, 2.5641804052360349],
						[61.389466610583256, 2.5641804226041489],
						[61.389466584263758, 2.5641804374297519],
						[61.388208328823808, 2.5650747348184182],
						[61.38742235170092, 2.5642081695405383],
						[61.386516878280204, 2.5630943021812684],
						[61.385975189908905, 2.5614749370678607],
						[61.38597514863509, 2.5614748836180135],
						[61.385975043681597, 2.5614748170283161],
						[61.385974979825164, 2.5614748001689294],
						[61.384527780167531, 2.561619200055143],
						[61.384527770355135, 2.5616192020816206],
						[61.384527749838306, 2.5616192063187992],
						[61.384527740025916, 2.561619208345276],
						[61.384111040150565, 2.5617498086399286],
						[61.384110987953832, 2.5617498475878469],
						[61.384110917891263, 2.5617499465623834],
						[61.384110900113747, 2.5617500084486813],
						[61.384174796593612, 2.5631277489172453],
						[61.38357483515518, 2.5641387863997749],
						[61.382980560382549, 2.5648969260408414],
						[61.381775116099909, 2.5640387362204118],
						[61.381775105934132, 2.5640387308078023],
						[61.381775085602563, 2.5640387199826016],
						[61.381775074544763, 2.564038714754123],
						[61.38057227509109, 2.563552614056158],
						[61.380572209177096, 2.5635526107633404],
						[61.380572089281692, 2.5635526467789402],
						[61.380572036192326, 2.5635526859032329],
						[61.379258136250577, 2.565449885406621],
						[61.379258124556756, 2.5654499047180854],
						[61.379258109285843, 2.5654499435437366],
						[61.379258103120939, 2.565449965469508],
						[61.379080402995385, 2.5664527653033251],
						[61.37908040236826, 2.5664527710654896],
						[61.379080401290516, 2.5664527863085582],
						[61.37908039985961, 2.5664527941141442],
						[61.378860900338815, 2.5734693938433595],
						[61.378860899799562, 2.5734694014647501],
						[61.378860901397246, 2.5734694161559712],
						[61.378860900857973, 2.5734694237773628],
						[61.379047001187082, 2.5750944222049266],
						[61.379047001539789, 2.5750944296425367],
						[61.379047004833254, 2.5750944421069413],
						[61.379047006077997, 2.5750944493607593],
						[61.379597006051803, 2.5772139501519584],
						[61.379597015226317, 2.5772139726710339],
						[61.379597037947576, 2.5772140149309788],
						[61.379597052386337, 2.5772140344881498],
						[61.380380450732979, 2.5780807325130737],
						[61.382005449241518, 2.5799390322022173],
						[61.382005455838431, 2.5799390383548166],
						[61.382005469032329, 2.5799390506599931],
						[61.382005475629278, 2.5799390568125888],
						[61.383088759337909, 2.5808001428750362],
						[61.383694244025676, 2.5815445257702656],
						[61.384477633165062, 2.5825334125873378],
						[61.384897025695771, 2.5832806975293998],
						[61.384897039241679, 2.5832807172761938],
						[61.384897069637567, 2.5832807504560109],
						[61.384897088359764, 2.5832807653815903],
						[61.385980487644083, 2.5840168649328583],
						[61.385980492280545, 2.5840168677345794],
						[61.385980500661489, 2.5840168735215676],
						[61.385980505297994, 2.5840168763232767],
						[61.386886004659907, 2.5845057754845038],
						[61.386886017589241, 2.5845057822137423],
						[61.386886044163909, 2.5845057917688044],
						[61.386886056917156, 2.5845057947782406],
						[61.387966610851691, 2.584741884810049],
						[61.389352675040165, 2.585852956675895],
						[61.389352711955858, 2.5858529753737893],
						[61.389352791239418, 2.5858529947440756],
						[61.389352832803233, 2.5858529974601088],
						[61.390972232664289, 2.5855807967752953],
						[61.390972253182099, 2.5855807925526859],
						[61.390972291189222, 2.5855807772176522],
						[61.390972308766628, 2.5855807679653844],
						[61.392352909292377, 2.5846834681572575],
						[61.392352924821381, 2.5846834536905972],
						[61.392352954987317, 2.5846834249409034],
						[61.392352967663989, 2.5846834073046794],
						[61.393489068025133, 2.5829168086575915],
						[61.393489080261105, 2.5829167817185525],
						[61.39348909644022, 2.5829167239127671],
						[61.393489099579291, 2.5829166950900091],
						[61.393350202805422, 2.5774057301187967],
						[61.39484989835146, 2.5767585620498692],
						[61.395577618057551, 2.5783778822162411],
						[61.395577625007185, 2.578377895812594],
						[61.395577640602411, 2.5783779207772128],
						[61.395577649248025, 2.5783779321455107],
						[61.39611924866955, 2.5789973312469501],
						[61.396119267392251, 2.5789973461748907],
						[61.396119304749483, 2.578997374170346],
						[61.396119326060273, 2.57899738668638],
						[61.396722062111259, 2.5792390605725966],
						[61.397447021339289, 2.5804833884867548],
						[61.398597016033577, 2.5832222763307033],
						[61.398597039293087, 2.5832223109939609],
						[61.398597095900371, 2.5832223650917001],
						[61.398597130316389, 2.5832223880636689],
						[61.399833230419496, 2.5836807885704385],
						[61.39983324772119, 2.5836807925225687],
						[61.399833284020666, 2.5836807981987095],
						[61.399833302126368, 2.5836808001064555],
						[61.400536101905573, 2.583672400711666],
						[61.400536143744908, 2.5836723902161589],
						[61.400536218690313, 2.5836723559929684],
						[61.40053625170826, 2.5836723304045521],
						[61.402725023890035, 2.5811058798940216],
						[61.403758172404345, 2.5819695534928711],
						[61.403758183637791, 2.5819695624527603],
						[61.4037582095849, 2.5819695777764373],
						[61.403758223406484, 2.5819695843240176],
						[61.405349872205434, 2.5826307644725768],
						[61.406572035792301, 2.5842751142474598],
						[61.409155422231017, 2.5881444942119258],
						[61.410241511263877, 2.5908639659987736],
						[61.410474808367567, 2.5916389585232036],
						[61.410474856139075, 2.5916390163527452],
						[61.410474980077467, 2.5916390848212494],
						[61.4104750560684, 2.5916390917375556],
						[61.412580635665982, 2.5910279981486473],
						[61.414216714723764, 2.5909084983655233],
						[61.414216719272176, 2.5909084993089855],
						[61.414216728193004, 2.5909084974728205],
						[61.414216731761336, 2.5909084967383555],
						[61.415522232323262, 2.5906973973639298],
						[61.415522284252191, 2.5906973716365762],
						[61.415522365380177, 2.5906972966646533],
						[61.415522395383235, 2.5906972453748085],
						[61.415847394874199, 2.5893250467674047],
						[61.415847396670294, 2.5893250275999753],
						[61.415847399282406, 2.5893249875871169],
						[61.415847396530069, 2.5893249674764061],
						[61.415658497443133, 2.5881555680812043],
						[61.415658489966141, 2.5881555433043899],
						[61.415658467875552, 2.5881554952203882],
						[61.415658454242077, 2.5881554735911134],
						[61.414861347676094, 2.5871916858013337],
						[61.417047209959698, 2.5838420503731045],
						[61.421274838962738, 2.5895473187391498],
						[61.421274916537946, 2.5895473591665632],
						[61.421275075304415, 2.5895473640730331],
						[61.421275153731408, 2.5895473272412755],
						[61.424305622957242, 2.5858696873337315],
						[61.426744267129315, 2.58803074961066],
						[61.426744269981612, 2.5880307527838662],
						[61.426744277382376, 2.5880307569003556],
						[61.426744280234672, 2.5880307600735621],
						[61.428849871181825, 2.5896168522342093],
						[61.431010944582077, 2.5916668285540063],
						[61.433788728418911, 2.5960140021683085],
						[61.435119059369349, 2.5984219967668474],
						[61.430644402745557, 2.5983636995804571],
						[61.43064435466033, 2.598363711353584],
						[61.430644272034101, 2.5983637546811127],
						[61.430644234816789, 2.5983637867860887],
						[61.428002635115369, 2.6022054864388906],
						[61.42800262126736, 2.6022055174928829],
						[61.428002603560209, 2.6022055813082803],
						[61.428002600680955, 2.6022056157487117],
						[61.428288701164178, 2.6058944146943821],
						[61.428288703914163, 2.6058944348146711],
						[61.428288714766836, 2.605894473955463],
						[61.428288721889587, 2.6058944912968083],
						[61.429344216306298, 2.6079556806868358],
						[61.43134981228733, 2.6134694677622798],
						[61.431349814422362, 2.613469474846692],
						[61.431349820388967, 2.6134694867866455],
						[61.431349822436324, 2.6134694920084018],
						[61.432241522420028, 2.6151889927389655],
						[61.432241528211151, 2.6151890009541643],
						[61.432241538901266, 2.615189017567626],
						[61.432241544692367, 2.6151890257828145],
						[61.43429154478094, 2.6177029269392516],
						[61.434291559931388, 2.6177029426396303],
						[61.434291593537914, 2.6177029677198131],
						[61.434291612169261, 2.6177029808253063],
						[61.437280511921053, 2.6191668796562104],
						[61.43728051281316, 2.6191668794732283],
						[61.439605464989917, 2.6202945560782029],
						[61.440713727984125, 2.6218945023750679],
						[61.442383121058604, 2.6252222907253486],
						[61.442383210118308, 2.6252223458424417],
						[61.442383386728636, 2.6252223472706779],
						[61.442383476955662, 2.6252222930332505],
						[61.443977977293599, 2.6222028926535006],
						[61.443977983013028, 2.6222028801922224],
						[61.443977991687859, 2.6222028539550779],
						[61.44397799642752, 2.6222028398133204],
						[61.445066873943588, 2.61676405168458],
						[61.447111166101884, 2.616044588582493],
						[61.447111186357674, 2.6160445787792441],
						[61.447111223929909, 2.6160445541313817],
						[61.447111241334007, 2.616044541150393],
						[61.448047341210369, 2.6151112423228731],
						[61.448047347996564, 2.6151112334027857],
						[61.448047360764562, 2.6151112176094551],
						[61.448047366746344, 2.6151112107362136],
						[61.448744372637933, 2.6140532049060665],
						[61.449577620326465, 2.6157722864307154],
						[61.449577635827929, 2.6157723095923862],
						[61.449577672008289, 2.6157723510891855],
						[61.44957769268715, 2.6157723694243034],
						[61.450297059978311, 2.6162251473378428],
						[61.452519237829698, 2.6193168175597239],
						[61.452519311829015, 2.619316858830083],
						[61.45251946631091, 2.6193168685271204],
						[61.452519545901367, 2.6193168371368918],
						[61.453069545744562, 2.6187334372650679],
						[61.453069571384667, 2.6187333887171449],
						[61.453069590651729, 2.6187332812546895],
						[61.453069586325817, 2.6187332275658419],
						[61.452119751078676, 2.6163197478408686],
						[61.454780481129362, 2.6173251694056017],
						[61.456608136604778, 2.6194973175506773],
						[61.45743872902063, 2.6208723022029976],
						[61.457438776884963, 2.6208723431998155],
						[61.457438887986953, 2.6208723881584772],
						[61.457438951224638, 2.620872392120317],
						[61.461086151002853, 2.6199057933600698],
						[61.461086200890179, 2.6199057624118174],
						[61.461086275262886, 2.6199056774934526],
						[61.461086298856124, 2.6199056237065061],
						[61.461464098960086, 2.6167833240880776],
						[61.461464099326157, 2.6167833127180438],
						[61.461464099078469, 2.6167832882967983],
						[61.461464099356867, 2.6167832750623226],
						[61.461183502917422, 2.6144750155581926],
						[61.462439092381352, 2.6100722556486939],
						[61.462439095876412, 2.6100722342220508],
						[61.462439098581591, 2.6100721960149285],
						[61.462439099400342, 2.6100721751385914],
						[61.461875210731598, 2.6054583765578392],
						[61.462755735938288, 2.6046390450933048],
						[61.462755767378518, 2.6046389859108299],
						[61.462755783971154, 2.6046388601287478],
						[61.462755769123611, 2.6046387935291291],
						[61.462036268876297, 2.6034915945931272],
						[61.462036261036637, 2.603491581146502],
						[61.462036242944563, 2.6034915603974951],
						[61.462036234388499, 2.6034915508634047],
						[61.460261236401834, 2.601897052594293],
						[61.459430762992298, 2.6010998794458375],
						[61.45820578924441, 2.5986916314971125],
						[61.458111295421219, 2.5982888542207951],
						[61.458111274664972, 2.5982888152028658],
						[61.458111221014953, 2.5982887471961398],
						[61.458111187316995, 2.5982887202552973],
						[61.455347391950035, 2.596955469692559],
						[61.455055800948585, 2.5926583056097634],
						[61.455694598680303, 2.5871778237635459],
						[61.455694598691608, 2.5871778049393979],
						[61.455694597018145, 2.5871777695232123],
						[61.455694594353233, 2.5871777512511867],
						[61.453511293809711, 2.5784110527327231],
						[61.453511287399003, 2.5784110314721862],
						[61.453511268597836, 2.5784109958325918],
						[61.453511257815059, 2.5784109773572776],
						[61.448955758920953, 2.5725109783696944],
						[61.44726685935516, 2.5702859789017118],
						[61.447266854541745, 2.5702859723702312],
						[61.447266845003199, 2.570285961170681],
						[61.447266840278076, 2.5702859565026466],
						[61.444216862872921, 2.567299879950526],
						[61.442050176705038, 2.5631277089136457],
						[61.442050175636204, 2.5631277053720596],
						[61.442050173675355, 2.5631277020150764],
						[61.44205017269492, 2.5631277003365978],
						[61.440027973084931, 2.5596387998649734],
						[61.440027968182584, 2.5596387914736352],
						[61.440027959446823, 2.5596387782321921],
						[61.44002795284888, 2.5596387720732547],
						[61.436552976927956, 2.5554888001540328],
						[61.435039088593555, 2.5511138347122806],
						[61.43503906514858, 2.5511137963093171],
						[61.435039003714273, 2.5511137356854108],
						[61.435038967420432, 2.5511137112319777],
						[61.431122341145816, 2.5497276376168885],
						[61.42740017692261, 2.5426527069607734],
						[61.427400138045485, 2.5426526661335349],
						[61.427400043603946, 2.5426526123889808],
						[61.427399988931498, 2.5426525992865789],
						[61.424827974049983, 2.5427886908628343],
						[61.424058497875137, 2.5370388722485955],
						[61.424058481384897, 2.5370388286637953],
						[61.424058428243669, 2.5370387532021845],
						[61.424058391592673, 2.5370387213253967],
						[61.421672338901956, 2.535805446373947],
						[61.416122367904151, 2.5271998929349944],
						[61.416122293613917, 2.5271998463436982],
						[61.41612213797324, 2.5271998317051443],
						[61.41612205733702, 2.5271998597495715],
						[61.413413899822601, 2.5299747121663461],
						[61.406711243233552, 2.5230831612299474],
						[61.406711243144635, 2.5230831593690981],
						[61.406711241271793, 2.5230831578792325],
						[61.40671124029091, 2.5230831562038745],
						[61.402550140198322, 2.5189970562461137],
						[61.402550133687726, 2.5189970519633946],
						[61.402550122361475, 2.5189970411661879],
						[61.402550115850858, 2.5189970368834538],
						[61.398116842296652, 2.5158359553261542],
						[61.394066861353828, 2.5103665817439058],
						[61.394066851810869, 2.5103665705811045],
						[61.394066833795009, 2.5103665517898546],
						[61.394066825322142, 2.5103665441614016],
						[61.387414073863383, 2.5050554823274651],
						[61.384897392410117, 2.4966860415077394],
						[61.384897390358518, 2.49668603630152],
						[61.384897385274094, 2.4966860242156783],
						[61.384897383222501, 2.4966860190094589],
						[61.384343796198614, 2.4954383420942441],
						[61.384348957881279, 2.4954052386385857],
						[61.385283343683597, 2.4932627577207129],
						[61.386391774384016, 2.4925834882642621],
						[61.389180639666272, 2.4920168963689777],
						[61.389180697460631, 2.4920168617604834],
						[61.389180778262123, 2.4920167622405676],
						[61.389180799574781, 2.492016699561324],
						[61.389164100365988, 2.4845888992919849],
						[61.389164100097716, 2.4845888937129876],
						[61.389164099471742, 2.4845888806952874],
						[61.389164098222132, 2.4845888734430819],
						[61.388389098041301, 2.4787638741873881],
						[61.388389097862287, 2.4787638704681774],
						[61.388389095630998, 2.4787638615433969],
						[61.388389096343886, 2.4787638576375812],
						[61.387364096390968, 2.474002761302339],
						[61.386591897078908, 2.4697666636246405],
						[61.386591896007765, 2.4697666600925867],
						[61.386591894936643, 2.4697666565605108],
						[61.38659189386555, 2.4697666530284481],
						[61.384544593896834, 2.4613082521009639],
						[61.384544590682822, 2.4613082415063667],
						[61.384544582381366, 2.4613082188320234],
						[61.38454457729388, 2.4613082067522711],
						[61.381489077096056, 2.455513807677383],
						[61.381489076024565, 2.4555138041463898],
						[61.381489074061243, 2.4555138008026329],
						[61.381489073079599, 2.4555137991307507],
						[61.378436273235472, 2.4502498999487337],
						[61.37843626074271, 2.4502498837952458],
						[61.378436233081665, 2.4502498520503213],
						[61.378436218003252, 2.450249838317788],
						[61.376124430135086, 2.448557496538931],
						[61.379458206324607, 2.4503279760550178],
						[61.379458314866419, 2.4503279663976381],
						[61.379458458866999, 2.4503278516429705],
						[61.379458492631997, 2.4503277487793915],
						[61.376022392935262, 2.4374582474742046],
						[61.376022386773855, 2.4374582318719522],
						[61.376022370703602, 2.4374581977008343],
						[61.376022361866738, 2.4374581826616719],
						[61.371361262130307, 2.4310609815277622],
						[61.371361261238505, 2.4310609817156017],
						[61.371361261238505, 2.4310609817156017],
						[61.371361260256556, 2.4310609800450202],
						[61.363236260071652, 2.4201665799319043],
						[61.363236259089554, 2.4201665782621111],
						[61.363236256143374, 2.4201665732527102],
						[61.363236254269516, 2.4201665717709844],
						[61.354114060818716, 2.4091470804606558],
						[61.349450166351545, 2.4021776887446187],
						[61.349450157692104, 2.4021776774420194],
						[61.349450138680247, 2.4021776570707929],
						[61.349450129219591, 2.4021776478136863],
						[61.344139032120722, 2.3977109491741562],
						[61.337836235382476, 2.3919442510243751],
						[61.337836202993174, 2.3919442334969916],
						[61.337836134118412, 2.3919442068108472],
						[61.337836096831907, 2.391944199697047],
						[61.33535295169775, 2.3919775989265761],
						[61.332894592768312, 2.3829388482486462],
						[61.332894591695066, 2.3829388447256359],
						[61.332894591422765, 2.3829388391577848],
						[61.332894590440262, 2.3829388374907454],
						[61.329441914603805, 2.372600014205358],
						[61.330364080051218, 2.370683387019044],
						[61.330364089642877, 2.3706833437322481],
						[61.330364090901206, 2.3706832590876941],
						[61.330364081676187, 2.3706832179191699],
						[61.329166899942194, 2.3680693565473514],
						[61.329166900095551, 2.3674834559302065],
						[61.331652848964325, 2.3668612941683582],
						[61.331652912329432, 2.3668612450846367],
						[61.331652981681863, 2.3668611159735278],
						[61.331652989452579, 2.3668610355674198],
						[61.33010020032421, 2.3623471673214773],
						[61.330116899906983, 2.3538611005947621],
						[61.330116884688778, 2.3538610475803758],
						[61.33011682884964, 2.3538609563317499],
						[61.33011678831987, 2.3538609199531817],
						[61.326358388119182, 2.3520192207117807],
						[61.322600178704697, 2.3501776655523914],
						[61.321055797225938, 2.3417193646871768],
						[61.321055790780228, 2.3417193435659289],
						[61.321055775396459, 2.3417193056035952],
						[61.321055764583782, 2.341719287287392],
						[61.318864064679516, 2.3385387876890964],
						[61.318864058872819, 2.3385387795546895],
						[61.318864048333822, 2.3385387668057294],
						[61.31886404172689, 2.3385387607163253],
						[61.316311241635432, 2.3359498600870707],
						[61.316311143944944, 2.3359498340595404],
						[61.316310975973018, 2.3359498811202029],
						[61.316310904799899, 2.3359499543985183],
						[61.315383112982083, 2.3399138205022068],
						[61.312888731702849, 2.3437554914610863],
						[61.312888718714305, 2.343755520463688],
						[61.312888702819301, 2.3437555819442433],
						[61.312888699912833, 2.3437556144221872],
						[61.313166486257032, 2.3476998037025689],
						[61.312527796801028, 2.3477109000305334],
						[61.312527721121469, 2.3477109367500018],
						[61.312527624215733, 2.3477110548218354],
						[61.312527602989526, 2.3477111361742056],
					],
					[
						[61.345110995630939, 2.4335810661540158],
						[61.347160949368821, 2.4358973324588264],
						[61.350316947895934, 2.4395513574643108],
						[61.34570296605321, 2.4348054842336877],
						[61.345110995630939, 2.4335810661540158],
					],
				],
				[
					[61.306697009013114, 2.4198833201808037],
					[61.306697015377694, 2.4198833769210526],
					[61.310708114996949, 2.4295056773833275],
					[61.310708117942703, 2.4295056823855439],
					[61.310708123032533, 2.429505694432184],
					[61.310708125978316, 2.4295056994343751],
					[61.314452625893274, 2.4360500984429803],
					[61.314452628838822, 2.4360501034463824],
					[61.314452634729896, 2.4360501134532089],
					[61.314452637675458, 2.4360501184566261],
					[61.319185938160594, 2.4425529187898807],
					[61.31918596912007, 2.4425529441564748],
					[61.319186039597668, 2.4425529855970969],
					[61.319186079025769, 2.4425529998157556],
					[61.322661079281914, 2.4429195990025043],
					[61.322661141620344, 2.4429195840480649],
					[61.322661245769957, 2.4429195209573704],
					[61.322661287401274, 2.4429194691099743],
					[61.32364188655572, 2.4402972692065501],
					[61.323641892610645, 2.4402972454414091],
					[61.323641898207825, 2.4402971936547613],
					[61.323641896948175, 2.4402971676760559],
					[61.320847396666288, 2.4228666683513853],
					[61.320847396576077, 2.4228666664959984],
					[61.320847396395664, 2.42286666278522],
					[61.320847396215235, 2.4228666590744292],
					[61.319583495674443, 2.4167277593683014],
					[61.319583493439403, 2.4167277504675253],
					[61.319583487987245, 2.4167277309985313],
					[61.319583483968628, 2.4167277224734494],
					[61.318080784411592, 2.4131832224585734],
					[61.318080735949557, 2.4131831708219789],
					[61.318080616934807, 2.4131831134372956],
					[61.318080547273929, 2.4131831075012529],
					[61.311394347062325, 2.4150304065632842],
					[61.31139432236327, 2.4150304173869652],
					[61.31139427680322, 2.4150304438474381],
					[61.311394256032536, 2.4150304613390139],
					[61.306697056193485, 2.4198831603701896],
					[61.306697029912044, 2.4198832127388128],
					[61.306697009013114, 2.4198833201808037],
				],
			],
		},
		{
			fldNpdidField: "43478",
			wlbNpdidWellbore: "328",
			fldName: "SLEIPNER ØST",
			fldCurrentActivitySatus: "Producing",
			wlbName: "15/9-9",
			fldDiscoveryYear: "1981",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43478",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43478",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "SLEIPNER ØST UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=328",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "41211",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Dusavik",
			fldAreaGeometryWKT: [
				[
					[58.407333100154695, 1.9808333000958962],
					[58.40733309976352, 1.9812611001406282],
					[58.407333102948463, 1.9812611252595829],
					[58.407333114573127, 1.9812611727305454],
					[58.407333123997695, 1.981261196602756],
					[58.407563699957151, 1.9816833514146039],
					[58.407566500099257, 1.9821111009311445],
					[58.407566503190594, 1.9821111243520122],
					[58.40756651481496, 1.9821111718235727],
					[58.407566524239378, 1.9821111956961928],
					[58.407794223913015, 1.9825333956364397],
					[58.407794224804398, 1.9825333954583622],
					[58.408024823920265, 1.982952895676269],
					[58.408249800011866, 1.9833750508453449],
					[58.408252599633002, 1.9838028014008233],
					[58.408252602817477, 1.9838028265207106],
					[58.408252616223997, 1.9838028736376878],
					[58.408252624663305, 1.9838028959908893],
					[58.408483124350347, 1.9842222952419633],
					[58.408710900215084, 1.9846444501983205],
					[58.408713699875833, 1.9850722007940034],
					[58.408713702966608, 1.9850722242159136],
					[58.408713714589922, 1.9850722716900591],
					[58.408713723029024, 1.9850722940438608],
					[58.408938722665013, 1.9854944944185549],
					[58.40893872355641, 1.9854944942405393],
					[58.40893872364984, 1.9854944959389722],
					[58.408938724541215, 1.9854944957609628],
					[58.409169224434478, 1.9859139957142962],
					[58.409397000397163, 1.9863361517249136],
					[58.409399800335116, 1.9867639014833736],
					[58.409399803425629, 1.9867639249059237],
					[58.409399815048324, 1.9867639723815831],
					[58.409399823487142, 1.9867639947362421],
					[58.409624823124503, 1.9871861943382338],
					[58.4096248240159, 1.9871861941602595],
					[58.409624824109294, 1.9871861958587347],
					[58.409855399842556, 1.9876083519723329],
					[58.409858099850453, 1.9880333004402682],
					[58.409858103034132, 1.9880333255617371],
					[58.409858114656387, 1.9880333730384587],
					[58.409858123986382, 1.9880333952157692],
					[58.41008872435517, 1.988455695144852],
					[58.41031652398825, 1.9888778948785846],
					[58.410541500952021, 1.9892972512280254],
					[58.410547000338454, 1.9897250023378101],
					[58.410547003428412, 1.989725025761421],
					[58.410547015050085, 1.9897250732396621],
					[58.410547024379795, 1.9897250954178625],
					[58.410774823801418, 1.9901472944950611],
					[58.411002624424071, 1.9905694951072552],
					[58.411227623810724, 1.9909889955538089],
					[58.411227623810724, 1.9909889955538089],
					[58.411458123821532, 1.9914111950522508],
					[58.411685924116966, 1.9918333941677242],
					[58.411913724183613, 1.9922528956184833],
					[58.412138723138135, 1.9926750939368956],
					[58.412138724122848, 1.9926750954576604],
					[58.412138724122848, 1.9926750954576604],
					[58.412369224118834, 1.9930972952968888],
					[58.412597023884089, 1.9935167951966482],
					[58.412597062059781, 1.9935168339466618],
					[58.412597152351708, 1.9935168863411716],
					[58.412597205359376, 1.9935168998078063],
					[58.412822082126532, 1.9935113037719967],
					[58.413047022589488, 1.9939333944639093],
					[58.413047024372275, 1.9939333941082571],
					[58.413047023574201, 1.9939333959847463],
					[58.413277624243179, 1.9943556953354753],
					[58.413505423620876, 1.9947750949355594],
					[58.413733073728061, 1.9951972033428464],
					[58.413513720588405, 1.9956332095568077],
					[58.413513712793922, 1.9956332317190137],
					[58.41351370264664, 1.9956332766754217],
					[58.413513700293869, 1.9956332994696],
					[58.413513700237843, 1.9960582527879942],
					[58.413291522009573, 1.9964943088989184],
					[58.413291514308199, 1.9964943327595042],
					[58.413291503362487, 1.9964943795918431],
					[58.413291500118142, 1.9964944025635958],
					[58.413296999280561, 1.9969221527726839],
					[58.413074822423205, 1.9973555089822681],
					[58.412852622276318, 1.9977888090934923],
					[58.412852613590033, 1.9977888314325483],
					[58.412852602643838, 1.9977888782639259],
					[58.412852600383928, 1.9977889027562237],
					[58.412858098948703, 1.998216652539849],
					[58.412635922223487, 1.9986527092977031],
					[58.412635913537024, 1.9986527316364202],
					[58.412635902590495, 1.9986527784672541],
					[58.412635900237149, 1.9986528012607023],
					[58.412638700193071, 1.9990777529239303],
					[58.412416521621388, 1.9995138082501951],
					[58.412416513919425, 1.9995138321095538],
					[58.412416502972576, 1.9995138789398288],
					[58.412416499820914, 1.9995139036094369],
					[58.41242199902171, 1.9999416526433174],
					[58.412199821827123, 2.0003749085435385],
					[58.412199814124989, 2.0003749324025701],
					[58.412199803084583, 2.0003749775336583],
					[58.412199799932772, 2.0003750022030449],
					[58.412205400043739, 2.0008028020973461],
					[58.412208099600413, 2.0012305514508339],
					[58.411985922119712, 2.0016638096718182],
					[58.41198591432412, 2.0016638318317788],
					[58.411985903376433, 2.0016638786608794],
					[58.411985900224366, 2.001663903330019],
					[58.411991499625486, 2.0020917021383866],
					[58.411994199805321, 2.0025222010134316],
					[58.411994199898537, 2.002522202712075],
					[58.411994199898537, 2.002522202712075],
					[58.411999799531088, 2.0029472005743214],
					[58.411999800233495, 2.0033750000728969],
					[58.411999800326683, 2.0033750017715479],
					[58.412002599584689, 2.0038027536338094],
					[58.411780475991158, 2.0042387028345985],
					[58.411555594769176, 2.0042441992218687],
					[58.41155554423775, 2.0042442144384376],
					[58.411555457145703, 2.0042442695639218],
					[58.411555422367883, 2.0042443091177282],
					[58.411335921601115, 2.0046777094775137],
					[58.411113776318096, 2.0051137034076092],
					[58.410891695141579, 2.0051191993384259],
					[58.410666694599115, 2.0051248008390439],
					[58.410666610792795, 2.0051248501549206],
					[58.410666526056978, 2.0051249975332945],
					[58.410666524236099, 2.0051250957733164],
					[58.410896999804464, 2.0055472503669911],
					[58.410897000259091, 2.0059749992919],
					[58.410897000352243, 2.005975000990508],
					[58.410899800220555, 2.0064028002022627],
					[58.41090259970732, 2.0068305530162451],
					[58.410683121842617, 2.007263809789865],
					[58.41068311315459, 2.0072638321252856],
					[58.41068310309619, 2.0072638787735571],
					[58.410683099849869, 2.0072639017427374],
					[58.410685899990725, 2.0076917012862161],
					[58.410688700429219, 2.008119401065664],
					[58.410688699537786, 2.0081194012431158],
					[58.410688699630931, 2.0081194029417184],
					[58.410694200339158, 2.0085472021750594],
					[58.410696999816608, 2.0089749534721633],
					[58.410477622381201, 2.0094082093271703],
					[58.410477612801358, 2.0094082318393731],
					[58.410477602742169, 2.0094082784868093],
					[58.410477600386876, 2.0094083012782677],
					[58.410480399719297, 2.0098361011820836],
					[58.410480400358672, 2.0102638998526117],
					[58.410480400451767, 2.0102639015512156],
					[58.410483099526914, 2.0106917005445291],
					[58.410483102706173, 2.0106917256688255],
					[58.410483115211619, 2.0106917729771046],
					[58.410483123646344, 2.0106917953384711],
					[58.410713700400152, 2.0111139517205725],
					[58.410716500106368, 2.0115417006254446],
					[58.410716500199449, 2.0115417023240623],
					[58.410716500199449, 2.0115417023240623],
					[58.410722000103121, 2.0119694015697607],
					[58.410724799738205, 2.0123972018151863],
					[58.410724799784717, 2.0128250008022714],
					[58.410724799784717, 2.0128250008022714],
					[58.410727600120076, 2.0132528006534343],
					[58.410727603298838, 2.0132528257781708],
					[58.410727614911892, 2.0132528732650798],
					[58.410727624237666, 2.0132528954499365],
					[58.410958123855231, 2.0136750943992276],
					[58.411185923909272, 2.0140972946507634],
					[58.411185962079109, 2.0140973334221575],
					[58.411186054239579, 2.0140973872123493],
					[58.411186107245683, 2.0140974007098156],
					[58.411411106108531, 2.0140890991505285],
					[58.411633182044426, 2.0140835029004589],
					[58.41186092411003, 2.014505695796466],
					[58.411860962279775, 2.0145057345690018],
					[58.411861054347064, 2.0145057866624825],
					[58.411861107353154, 2.0145058001607485],
					[58.412086106145473, 2.0144973994465496],
					[58.412311105283706, 2.0144919005363473],
					[58.412535981831823, 2.0144863021907788],
					[58.412760899973662, 2.0149083518267425],
					[58.412763699548691, 2.0153361004344252],
					[58.412763699641701, 2.0153361021331722],
					[58.412763699734754, 2.0153361038319],
					[58.412769199561332, 2.0157639026474232],
					[58.412769231382271, 2.01576397359492],
					[58.412769333185445, 2.0157640718392345],
					[58.412769405043584, 2.0157641004802493],
					[58.412994404679758, 2.0157584992818069],
					[58.412994405664229, 2.015758500803273],
					[58.412994407447108, 2.0157585004487206],
					[58.413219407035818, 2.0157501995565354],
					[58.41321945846034, 2.0157501841782199],
					[58.413219542883581, 2.0157501296097875],
					[58.41321957766516, 2.0157500900651177],
					[58.413439024121061, 2.0153168963899102],
					[58.413663905163148, 2.0153113000190688],
					[58.413663906054595, 2.015311299841779],
					[58.413663906946027, 2.0153112996644911],
					[58.41389170567497, 2.0153030002677066],
					[58.414111104811717, 2.0152973998693473],
					[58.414336104675883, 2.0152918995249167],
					[58.414561105380272, 2.0152862992307234],
					[58.414561106364758, 2.0152863007522548],
					[58.414561107256205, 2.0152863005749562],
					[58.414786106117795, 2.015278000822041],
					[58.415011105031184, 2.0152724005080649],
					[58.415236104879689, 2.0152668994353351],
					[58.41545830492047, 2.0152613007408555],
					[58.415458305811917, 2.0152613005635476],
					[58.41545830670335, 2.015261300386245],
					[58.415683305547311, 2.015252999835385],
					[58.415908305429447, 2.0152474003138847],
					[58.416133305355331, 2.0152419002134758],
					[58.416358305229835, 2.01523630033551],
					[58.416358306121268, 2.0152363001581972],
					[58.416358307012729, 2.0152362999808839],
					[58.416583305932015, 2.0152280003289551],
					[58.416808304906972, 2.0152224002539074],
					[58.417030605034299, 2.0152169005975367],
					[58.417255604893093, 2.0152112999904204],
					[58.417255605784526, 2.0152112998131022],
					[58.417255606675973, 2.015211299635784],
					[58.417480607453527, 2.0152030005300943],
					[58.417480657893464, 2.0152029836270322],
					[58.417480743207705, 2.0152029288726112],
					[58.417480777988992, 2.01520288932229],
					[58.417700123656687, 2.0147695972066217],
					[58.417925004876494, 2.0147640993356211],
					[58.418150004924009, 2.0147585003607031],
					[58.418150005815455, 2.0147585001833663],
					[58.418150006706881, 2.014758500006034],
					[58.418375005693221, 2.0147501994212758],
					[58.418602804689428, 2.0147445997350966],
					[58.418822204983634, 2.014739099145558],
					[58.419047205015438, 2.0147334994415065],
					[58.419047205906885, 2.0147334992641643],
					[58.419047206891378, 2.0147335007858476],
					[58.419272205860068, 2.0147251994030366],
					[58.419497204992318, 2.0147195995019116],
					[58.419722205153406, 2.0147141005537046],
					[58.419947205169464, 2.0147085001187253],
					[58.419947206060897, 2.0147084999413774],
					[58.419947206952344, 2.0147084997640281],
					[58.420169406148418, 2.0147001998072347],
					[58.420394405264965, 2.0146945991769427],
					[58.420619405410406, 2.0146890995021374],
					[58.42084440461236, 2.0146835002144279],
					[58.42084440639524, 2.0146834998597165],
					[58.420844407286673, 2.0146834996823615],
					[58.421069406406119, 2.014675200099572],
					[58.421294404708526, 2.0146696006147979],
					[58.421519404838278, 2.0146641002116095],
					[58.421741705041462, 2.0146584995587031],
					[58.421741705932881, 2.0146584993813432],
					[58.421741706824328, 2.0146584992039811],
					[58.421966706019163, 2.0146502005222069],
					[58.422191705197292, 2.0146446001308265],
					[58.422416582067733, 2.0146391029100736],
					[58.422644224060463, 2.0150611949085078],
					[58.422869201438964, 2.0154806513076378],
					[58.422874799332227, 2.0159082533801755],
					[58.422652621643984, 2.0163416094412741],
					[58.422652613845926, 2.0163416316050689],
					[58.422652602893081, 2.0163416784444554],
					[58.422652599645247, 2.0163417014208251],
					[58.422655400324707, 2.0167722017014285],
					[58.422655400324707, 2.0167722017014285],
					[58.42265540041776, 2.0167722034006346],
					[58.422660900295973, 2.017200002385378],
					[58.422660903381114, 2.0172000258197742],
					[58.422660914993074, 2.0172000733239241],
					[58.422660924318322, 2.0172000955171812],
					[58.422888724319662, 2.0176222947619156],
					[58.422888761597328, 2.0176223337264791],
					[58.422888853756803, 2.0176223875416506],
					[58.422888906669726, 2.017622399348419],
					[58.423113906296443, 2.0176140999461256],
					[58.423336105101825, 2.0176084994183632],
					[58.423336155635077, 2.017608484214541],
					[58.423336241841717, 2.0176084292784018],
					[58.423336277608129, 2.0176083912453286],
					[58.423558424272208, 2.0171751957445854],
					[58.423783306092638, 2.0171668992402321],
					[58.42400818160592, 2.0171613024111381],
					[58.424235923980532, 2.0175833944972021],
					[58.424235962149695, 2.0175834332858025],
					[58.424236052433315, 2.0175834857580162],
					[58.424236104547809, 2.0175834994416069],
					[58.424460981864108, 2.0175780025598513],
					[58.424685899597542, 2.0180000509621694],
					[58.42468870010628, 2.0184278014458972],
					[58.424691500097403, 2.0188556017388142],
					[58.424691500097403, 2.0188556017388142],
					[58.424691500190391, 2.0188556034381304],
					[58.42469699936202, 2.0192832533881364],
					[58.424474821754174, 2.0197166082819651],
					[58.424474821847163, 2.0197166099812742],
					[58.424255474536821, 2.0201498034674752],
					[58.424030594054166, 2.0201580997457533],
					[58.423802795049347, 2.0201637002924349],
					[58.423577794868528, 2.0201691994025341],
					[58.423358295451891, 2.0201748002397499],
					[58.423358293668997, 2.0201748005942188],
					[58.42335829277755, 2.0201748007714526],
					[58.423133292598791, 2.0201830994334662],
					[58.423133223995578, 2.0201831302501461],
					[58.423133127724867, 2.0201832318414055],
					[58.423133099964389, 2.0201833009167398],
					[58.423135900061574, 2.020611100483825],
					[58.423135903239078, 2.0206111256181223],
					[58.42313591475682, 2.0206111714248842],
					[58.423135923190046, 2.0206111937965989],
					[58.423360922810545, 2.0210333937187688],
					[58.423360961963105, 2.0210334340323493],
					[58.423361054028398, 2.0210334861579597],
					[58.423361107034061, 2.0210334996692367],
					[58.423586106112587, 2.0210251996667234],
					[58.423811105135179, 2.0210195997882989],
					[58.424036105088852, 2.0210140991765533],
					[58.424261105088192, 2.0210085004638665],
					[58.424261105979653, 2.0210085002866451],
					[58.4242611068711, 2.021008500109426],
					[58.424485981796181, 2.0210002033720369],
					[58.424710923047847, 2.0214222937404012],
					[58.424710961215929, 2.0214223325337475],
					[58.424711051591146, 2.0214223867158849],
					[58.424711104596824, 2.0214224002281336],
					[58.424935983054674, 2.0214169034590053],
					[58.42516652431123, 2.0218389963616192],
					[58.42516656158773, 2.0218390353331031],
					[58.425166652761305, 2.0218390876403709],
					[58.425166704782519, 2.0218390996312321],
					[58.425391704632553, 2.0218335003841257],
					[58.42539175605787, 2.0218334850080231],
					[58.425391842266471, 2.0218334300785297],
					[58.425391877941202, 2.0218333903479264],
					[58.425614078421603, 2.0213972917608563],
					[58.425614078328643, 2.0213972900614876],
					[58.425833477530212, 2.0209639895450766],
					[58.425833487204926, 2.0209639687251038],
					[58.425833497267973, 2.0209639220599507],
					[58.425833499625142, 2.0209638992590615],
					[58.425830799926068, 2.0205361465229541],
					[58.426050178216101, 2.020100091296027],
					[58.426272377885944, 2.0196667916662592],
					[58.426272385684562, 2.0196667695008137],
					[58.426272396638559, 2.0196667226574632],
					[58.426272399886933, 2.0196666996789698],
					[58.426269599606329, 2.0192389473974255],
					[58.426489078405737, 2.0188056906416563],
					[58.426489087095625, 2.0188056682986018],
					[58.426489097157869, 2.0188056216320005],
					[58.426489100406094, 2.0188055986532905],
					[58.426486300118746, 2.0183777987689924],
					[58.42648350018235, 2.0179499987045202],
					[58.42648350018235, 2.0179499987045202],
					[58.42648350008934, 2.0179499970051165],
					[58.426477999827, 2.0175221979871822],
					[58.426475199758627, 2.0170916989361842],
					[58.426475199849129, 2.0166639479488238],
					[58.426697323935038, 2.0162307978211285],
					[58.42691658289052, 2.0162252025151703],
					[58.42714702374407, 2.0166472959446349],
					[58.427147062804984, 2.0166473345578266],
					[58.427147153980471, 2.0166473868540442],
					[58.427147206986497, 2.0166474003597044],
					[58.427372205935477, 2.0166391003643778],
					[58.427597204927217, 2.0166335007771687],
					[58.427822082408277, 2.0166280024942713],
					[58.428047022523202, 2.0170500934123594],
					[58.428047061677056, 2.0170501337264102],
					[58.428047153836879, 2.0170501875469613],
					[58.428047206749845, 2.0170501993540197],
					[58.428272205671817, 2.017041899596931],
					[58.428497082183959, 2.0170363023439579],
					[58.428724824113722, 2.0174583942011837],
					[58.428724862376001, 2.0174584346937467],
					[58.428724951675342, 2.0174584856493043],
					[58.428725004774314, 2.0174585008561423],
					[58.428950004550863, 2.0174530000163697],
					[58.429174881924538, 2.0174474030870631],
					[58.429399824057818, 2.0178667945436746],
					[58.429399862226994, 2.017866833337878],
					[58.429399951619203, 2.0178668859948901],
					[58.429400004625158, 2.0178668995030136],
					[58.429625005216259, 2.0178612993365315],
					[58.429849881714439, 2.0178558027081404],
					[58.430077623811769, 2.018277894579048],
					[58.43007766198086, 2.0182779333743959],
					[58.430077752264388, 2.0182779858560096],
					[58.430077805270301, 2.0182779993649569],
					[58.430305604792686, 2.0182723995757055],
					[58.430305605684147, 2.0182723993983767],
					[58.430305606668604, 2.0182724009206421],
					[58.430305608451484, 2.0182724005659813],
					[58.430524884259249, 2.0182641047349814],
					[58.430755423623559, 2.0186861968469234],
					[58.430755461792529, 2.0186862356434174],
					[58.430755551982934, 2.0186862864273745],
					[58.430755604988811, 2.0186862999371242],
					[58.430980482228385, 2.01868080259784],
					[58.431208123651714, 2.0191028948197816],
					[58.431208161820578, 2.0191029336171891],
					[58.431208253979733, 2.0191029874474395],
					[58.43120830787705, 2.0191030007806252],
					[58.431430605687311, 2.0190945998081573],
					[58.4316554820048, 2.0190891031331835],
					[58.431883074255573, 2.0195111020435665],
					[58.431660921622296, 2.0199443091505143],
					[58.431660921715299, 2.0199443108501693],
					[58.431441522313008, 2.0203777102942237],
					[58.431441512731432, 2.0203777328174377],
					[58.431441502575773, 2.0203777777906669],
					[58.431441500218789, 2.0203778005952335],
					[58.431444199705588, 2.0208083005744477],
					[58.431444202883185, 2.0208083257146421],
					[58.431444216276944, 2.020808372877215],
					[58.431444224710233, 2.0208083952541123],
					[58.431674824670495, 2.0212278952407936],
					[58.431674862931743, 2.0212279357407144],
					[58.431674953121295, 2.0212279865324203],
					[58.431675005235547, 2.0212280002235432],
					[58.431894404662366, 2.0212224000670522],
					[58.432119404828057, 2.0212169007500007],
					[58.432344404853161, 2.021211299910072],
					[58.432344474440924, 2.0212112706083842],
					[58.432344571603366, 2.0212111688141121],
					[58.432344600255483, 2.0212110995436281],
					[58.432341899658987, 2.0207832984256267],
					[58.432341896574371, 2.0207832749844878],
					[58.432341884963449, 2.0207832274661826],
					[58.432341876623127, 2.0207832067884257],
					[58.432116900010094, 2.0203610491779456],
					[58.43211410007472, 2.0199333476289421],
					[58.432333305107704, 2.0194976350568057],
					[58.432558123228993, 2.0199194933270639],
					[58.432558161397644, 2.0199195321267802],
					[58.432558251773656, 2.0199195863156092],
					[58.432558304779469, 2.0199195998277046],
					[58.432783305175498, 2.0199141002490539],
					[58.43300818307717, 2.019908502862982],
					[58.43323872479359, 2.020327896275707],
					[58.433238762962141, 2.0203279350765739],
					[58.433238853245044, 2.0203279875676521],
					[58.433238905266386, 2.0203279995581207],
					[58.433463782250016, 2.0203224030094309],
					[58.433688723267196, 2.0207444937295858],
					[58.433688760544193, 2.0207445327086684],
					[58.433688851811375, 2.0207445867238643],
					[58.433688904817124, 2.0207446002375424],
					[58.433913905063164, 2.0207391001281167],
					[58.434138905261115, 2.0207335001904414],
					[58.434138906152562, 2.0207335000131446],
					[58.434138907044009, 2.0207334998358504],
					[58.434363907359518, 2.0207252002714751],
					[58.434363975962633, 2.020725169444388],
					[58.434364072233123, 2.0207250678198099],
					[58.434364099900456, 2.0207249970223504],
					[58.434358499632367, 2.0202971979446041],
					[58.434355799567527, 2.0198694473967884],
					[58.434577978404327, 2.0194361913373839],
					[58.43457798610978, 2.0194361674667198],
					[58.43457799715646, 2.0194361223118462],
					[58.434578000311696, 2.0194360976280303],
					[58.434572401141381, 2.0190083469348727],
					[58.434794578245629, 2.0185750911930982],
					[58.434794586043935, 2.0185750690218951],
					[58.434794596997264, 2.0185750221666865],
					[58.434794600245347, 2.0185749991824533],
					[58.434794599809045, 2.0181443996157715],
					[58.434794599809045, 2.0181443996157715],
					[58.434794599716028, 2.0181443979159699],
					[58.43479459962299, 2.0181443962161576],
					[58.434789100832766, 2.0177167462885661],
					[58.435011278393198, 2.0172833912849306],
					[58.435011286191255, 2.0172833691133332],
					[58.435011297144108, 2.01728332225745],
					[58.435011300391935, 2.0172832992729881],
					[58.435008500395682, 2.0168555991266692],
					[58.435008500302658, 2.01685559742686],
					[58.435008500302658, 2.01685559742686],
					[58.435003000758577, 2.0164278467560437],
					[58.435225177867011, 2.0159917911660177],
					[58.435225185571795, 2.0159917672941776],
					[58.435225196617225, 2.0159917221374655],
					[58.43522519986481, 2.0159916991527451],
					[58.43522240008658, 2.0155638996853704],
					[58.435222399993542, 2.0155638979855413],
					[58.435222399900482, 2.015563896285733],
					[58.435216899801318, 2.0151360980982238],
					[58.43521409985911, 2.0147083483071717],
					[58.435436277869421, 2.0142750910863274],
					[58.43543628566696, 2.01427506891382],
					[58.435436296618676, 2.0142750220565033],
					[58.435436299772888, 2.0142749973717042],
					[58.435430800773489, 2.0138472475654825],
					[58.43565280544199, 2.0134115314897998],
					[58.435880401059556, 2.0138333524076901],
					[58.435885899937809, 2.0142611025794097],
					[58.435885903023674, 2.0142611260222294],
					[58.435885914637076, 2.0142611735429004],
					[58.435885923962971, 2.0142611957434231],
					[58.436113699710646, 2.0146833512568998],
					[58.436116500292904, 2.0151111016145031],
					[58.436116503378614, 2.0151111250575759],
					[58.436116514991689, 2.0151111725788486],
					[58.436116523332956, 2.0151111932573591],
					[58.436341522892157, 2.0155333937152062],
					[58.436341561062108, 2.0155334325139358],
					[58.436341652331294, 2.0155334865189967],
					[58.436341705337462, 2.0155335000254868],
					[58.436566704722544, 2.0155279992251334],
					[58.436566756239834, 2.0155279855336095],
					[58.436566842445018, 2.0155279305693283],
					[58.436566878117382, 2.0155278908190009],
					[58.436789077624809, 2.0150917906898145],
					[58.437011277694538, 2.0146583911352423],
					[58.437233478291191, 2.0142250905053549],
					[58.43723348608868, 2.0142250683316743],
					[58.437233497040332, 2.0142250214719026],
					[58.437233500194488, 2.0142249967858263],
					[58.437227999983918, 2.0137971977549669],
					[58.437225199957957, 2.0133666992224919],
					[58.437225196871914, 2.0133666757788666],
					[58.437225185258157, 2.0133666282567497],
					[58.437225175839025, 2.0133666043557406],
					[58.437000176276655, 2.0129471046266381],
					[58.437000176276655, 2.0129471046266381],
					[58.436769600223201, 2.0125249486041317],
					[58.436766900198855, 2.0120972466903608],
					[58.436989077661927, 2.0116611904061479],
					[58.436989074044654, 2.0116610914577544],
					[58.436988982296583, 2.0116609464804052],
					[58.436988895057212, 2.0116609002738817],
					[58.436763895004603, 2.0116665002636136],
					[58.43676389411317, 2.0116665004411565],
					[58.436763893221752, 2.0116665006186962],
					[58.43653889413013, 2.0116747998533686],
					[58.436316694895119, 2.0116804001273136],
					[58.436091694779158, 2.0116858999095104],
					[58.436091626178602, 2.0116859307558799],
					[58.436091528039995, 2.0116860310652811],
					[58.436091500284782, 2.0116861001732347],
					[58.43609420022598, 2.012113852414211],
					[58.435874821604372, 2.0125499089256866],
					[58.435652795774345, 2.0129828696426273],
					[58.435425225878582, 2.0125610978158663],
					[58.435644578293648, 2.0121278894266039],
					[58.435644587075323, 2.0121278687758095],
					[58.435644597134811, 2.012127822095136],
					[58.435644600381707, 2.0121277991098321],
					[58.435641900386933, 2.0116999991106121],
					[58.435641900293831, 2.0116999974107941],
					[58.435641900293831, 2.0116999974107941],
					[58.435636299852085, 2.0112721976078234],
					[58.435633499606325, 2.0108416989359288],
					[58.435630799765065, 2.010413898602629],
					[58.435630799765065, 2.010413898602629],
					[58.435630799671955, 2.0104138969028198],
					[58.435625200370424, 2.0099860983941102],
					[58.435625200298631, 2.0095582995932553],
					[58.435625200298631, 2.0095582995932553],
					[58.435622399808388, 2.0091305997385507],
					[58.435622399715264, 2.0091305980387228],
					[58.435622399622112, 2.0091305963389208],
					[58.435616899659429, 2.0087027984286769],
					[58.435614099818963, 2.0082749985909607],
					[58.435611300329612, 2.0078471985725521],
					[58.435611297242581, 2.0078471751305695],
					[58.435611284735444, 2.0078471277901886],
					[58.435611276299902, 2.0078471054139899],
					[58.435383500437538, 2.0074249493221519],
					[58.435383499803208, 2.0069972006307024],
					[58.435383499710056, 2.0069971989309114],
					[58.435383499616862, 2.0069971972311271],
					[58.435383499616862, 2.0069971972311271],
					[58.435378000409955, 2.0065693980943458],
					[58.435375199687968, 2.0061416985761586],
					[58.435375199687968, 2.0061416985761586],
					[58.435375199594787, 2.0061416968763734],
					[58.435369600228391, 2.005713898679593],
					[58.435366900126425, 2.0052860981537877],
					[58.435364100402943, 2.0048582984725019],
					[58.435364097222205, 2.0048582733311848],
					[58.435364084807176, 2.0048582276920692],
					[58.435364076277914, 2.0048582036169909],
					[58.435133499846962, 2.0044360495775635],
					[58.435133499866907, 2.0040083008423255],
					[58.435133499773684, 2.0040082991425496],
					[58.435130799923698, 2.0035777996227524],
					[58.435130799830503, 2.0035777979229858],
					[58.435130799737287, 2.0035777962232242],
					[58.435125199780401, 2.0031499987479258],
					[58.435122400002612, 2.0027221995894013],
					[58.435122396821448, 2.0027221744484827],
					[58.435122385297078, 2.0027221286327315],
					[58.435122375876034, 2.0027221047361312],
					[58.434894598752109, 2.0022999480099881],
					[58.434889099741135, 2.0018721982544956],
					[58.434889100025067, 2.0014443998304321],
					[58.434889100025067, 2.0014443998304321],
					[58.434886299816654, 2.0010166992926086],
					[58.43488349989989, 2.0005888989218588],
					[58.434883496718314, 2.0005888737813344],
					[58.434883485099967, 2.0005888262669043],
					[58.43488347577177, 2.0005888040707935],
					[58.434653000159194, 2.0001666481152425],
					[58.434650200065455, 1.9997388984566162],
					[58.434647400292548, 1.9993110987914025],
					[58.434647400292548, 1.9993110987914025],
					[58.434647400199282, 1.9993110970916759],
					[58.434641899944481, 1.9988832965527421],
					[58.434641896949138, 1.9988832748120122],
					[58.434641885330151, 1.9988832272985135],
					[58.434641876986362, 1.9988832066249129],
					[58.434416900383994, 1.9984610493355934],
					[58.434414099800378, 1.9980332986451115],
					[58.434414099800378, 1.9980332986451115],
					[58.434414099707105, 1.9980332969454009],
					[58.434408499687741, 1.9976055978732168],
					[58.43440579993495, 1.9971777993795738],
					[58.434405796752714, 1.9971777742397516],
					[58.434405785226424, 1.9971777284268821],
					[58.434405775804365, 1.9971777045323067],
					[58.434177998628094, 1.9967555486878679],
					[58.434172400413537, 1.99632779854036],
					[58.434172399756662, 1.9958999998705382],
					[58.434172399756662, 1.9958999998705382],
					[58.434169600339374, 1.9954721995168918],
					[58.434169600246037, 1.9954721978172112],
					[58.434169600152728, 1.9954721961175237],
					[58.434164100352753, 1.9950443968821991],
					[58.434164097263441, 1.9950443734424685],
					[58.434164084844987, 1.9950443278086143],
					[58.434164076407271, 1.9950443054365408],
					[58.433936299701017, 1.9946221498421579],
					[58.433933500366393, 1.994194398318428],
					[58.433933500366393, 1.994194398318428],
					[58.433933500273056, 1.9941943966187421],
					[58.433927999792758, 1.9937666980121873],
					[58.433925200330734, 1.9933388982121505],
					[58.433925200325632, 1.9929110994628045],
					[58.433925197235929, 1.9929110760234499],
					[58.433925184723385, 1.9929110286910259],
					[58.43392517619197, 1.9929110046199894],
					[58.433697398970331, 1.9924888477207623],
					[58.433691899752858, 1.9920610980312186],
					[58.433689099893385, 1.9916332988988488],
					[58.433686299789912, 1.9912027985360048],
					[58.433686299789912, 1.9912027985360048],
					[58.433686299696525, 1.9912027968363388],
					[58.433680799709649, 1.9907749972676507],
					[58.43367800009672, 1.9903471995029727],
					[58.43367800000334, 1.9903471978033105],
					[58.43367800000334, 1.9903471978033105],
					[58.433672399857471, 1.9899193992185422],
					[58.43367240002285, 1.9894917000343868],
					[58.433672396932515, 1.9894916765955299],
					[58.433672385216752, 1.9894916273870455],
					[58.433672375886701, 1.9894916051947673],
					[58.433444599962208, 1.989069349815221],
					[58.433441899933619, 1.9886416994168257],
					[58.433441899840211, 1.988641697717197],
					[58.433441899840211, 1.988641697717197],
					[58.433436300097881, 1.9882138980566038],
					[58.433433499805574, 1.9877860991091552],
					[58.433433499712152, 1.9877860974095327],
					[58.433433499712152, 1.9877860974095327],
					[58.433427999847204, 1.987358298289172],
					[58.433425200328088, 1.9869305986588037],
					[58.433425200185177, 1.9865027999241542],
					[58.433425200091747, 1.9865027982245358],
					[58.433422400254315, 1.9860750468522479],
					[58.433641824719963, 1.9856390967163289],
					[58.433863905192375, 1.9856335006463841],
					[58.434088782950631, 1.9856280033571005],
					[58.434316524123986, 1.9860472949015788],
					[58.434316562302214, 1.9860473336655105],
					[58.43431665170602, 1.9860473862469901],
					[58.434316704714391, 1.9860473997081922],
					[58.434541704794931, 1.9860418992455382],
					[58.434766704893796, 1.9860363006540598],
					[58.434766705785165, 1.9860363004758859],
					[58.434766706676548, 1.9860363002977082],
					[58.434991581933119, 1.9860280045781298],
					[58.435216522891295, 1.9864500939614151],
					[58.435216561069431, 1.9864501327267192],
					[58.435216652349325, 1.9864501866537905],
					[58.435216705357675, 1.98645020011583],
					[58.4354417053327, 1.9864446003098393],
					[58.435666705375098, 1.9864390999780952],
					[58.435666706266481, 1.9864390997999231],
					[58.435666707157864, 1.9864390996217507],
					[58.435891705628016, 1.9864308001249331],
					[58.436116704699863, 1.9864251999433189],
					[58.436116705591274, 1.9864251997651385],
					[58.436116707374026, 1.9864251994087845],
					[58.436341581703097, 1.9864169036934984],
					[58.436566522595015, 1.9868389938910884],
					[58.436566560773059, 1.986839032658188],
					[58.436566652052853, 1.9868390865880938],
					[58.436566705061146, 1.9868391000511136],
					[58.436794404916398, 1.9868335001084911],
					[58.437019404916363, 1.9868279997103284],
					[58.437244404841643, 1.9868223994765835],
					[58.437244405733026, 1.9868223992984071],
					[58.437244406717838, 1.9868224008200401],
					[58.437469406039021, 1.9868141007738571],
					[58.437694405064882, 1.9868085003429041],
					[58.437694405956258, 1.9868085001647287],
					[58.43769440684764, 1.9868084999865485],
					[58.437694407739009, 1.986808499808373],
					[58.437913906344086, 1.986800200764177],
					[58.438138905361981, 1.9867945999613241],
					[58.438363782989221, 1.9867891022967956],
					[58.438594223657056, 1.9872111963801742],
					[58.438594313951945, 1.987211248792087],
					[58.438594491236294, 1.9872112442895069],
					[58.438594579303995, 1.9872111905966001],
					[58.438814025381802, 1.9867751974906203],
					[58.439038904708958, 1.9867695992551213],
					[58.439038905693785, 1.9867696007768212],
					[58.439038906585154, 1.9867696005986337],
					[58.43926390596426, 1.986761300634277],
					[58.439486105136432, 1.986755799556968],
					[58.439711105205248, 1.9867502006908098],
					[58.439711106096617, 1.9867502005126176],
					[58.439711106988, 1.9867502003344255],
					[58.439936106353869, 1.9867418997663675],
					[58.440161104631891, 1.9867363008812071],
					[58.440386104666651, 1.9867307994273775],
					[58.440386174244907, 1.9867307700500818],
					[58.440386272362225, 1.9867306696592391],
					[58.440386300009934, 1.9867305988239043],
					[58.440383500270912, 1.9863027984982824],
					[58.440383497086479, 1.9863027733553615],
					[58.440383484664821, 1.9863027277169114],
					[58.440383476132077, 1.9863027036432674],
					[58.44015299984752, 1.9858805482945467],
					[58.440150199879973, 1.985452846821101],
					[58.44037237804303, 1.9850167910857095],
					[58.440372377949572, 1.985016789385762],
					[58.440591823843903, 1.984583496421688],
					[58.440816704664407, 1.9845779995150237],
					[58.44081670555579, 1.9845779993367694],
					[58.440816707432013, 1.984578000680242],
					[58.44104170613965, 1.9845695995243497],
					[58.441266704844331, 1.9845640992916984],
					[58.441266756357336, 1.9845640855526634],
					[58.441266841656073, 1.9845640306834118],
					[58.441266878209426, 1.9845639907183936],
					[58.441489024060282, 1.9841279965867198],
					[58.44171390490402, 1.9841223999255804],
					[58.441713956323476, 1.9841223844856699],
					[58.441714043404751, 1.9841223292580288],
					[58.441714078175181, 1.9841222896485695],
					[58.441933423669795, 1.9836890966425482],
					[58.442158304618196, 1.9836835002890569],
					[58.442158356037574, 1.9836834848482638],
					[58.442158443118629, 1.9836834296187602],
					[58.442158478780271, 1.9836833898300594],
					[58.442377978657909, 1.9832472904303442],
					[58.442377988706475, 1.9832472437330777],
					[58.442377988208527, 1.983247152739412],
					[58.442377976677108, 1.9832471069212836],
					[58.44215297690512, 1.9828249054096596],
					[58.442152937834436, 1.9828248668195938],
					[58.442152845661631, 1.982824813071064],
					[58.442152792653019, 1.9828247996126334],
					[58.441927794373846, 1.9828330992875685],
					[58.441702794601007, 1.9828387006745685],
					[58.441477917896862, 1.9828441964850816],
					[58.44125017556113, 1.9824221044520047],
					[58.441025177052751, 1.9819999052617301],
					[58.441025176161382, 1.9819999054400528],
					[58.441025176067882, 1.9819999037400835],
					[58.441025175176513, 1.9819999039184077],
					[58.440794575935932, 1.9815805043648322],
					[58.440566876003359, 1.9811582046382428],
					[58.440566876003359, 1.9811582046382428],
					[58.440336276414826, 1.9807360056448078],
					[58.440111300023027, 1.9803138485528218],
					[58.440108500299338, 1.9798860992421823],
					[58.44010579991297, 1.9794583471270457],
					[58.440325177693651, 1.9790250906889117],
					[58.440547324286747, 1.9785890968140485],
					[58.440772205151823, 1.9785834994734697],
					[58.440991704991653, 1.9785779993290524],
					[58.440991705976565, 1.9785780008505947],
					[58.440991706867926, 1.9785780006721887],
					[58.441219405559245, 1.9785695995213137],
					[58.441444405366767, 1.978564099846561],
					[58.441669405092874, 1.9785585003240371],
					[58.441669405984229, 1.9785585001456256],
					[58.441669406875612, 1.9785584999672099],
					[58.44189440670732, 1.9785502001266546],
					[58.441894476189781, 1.9785501690317899],
					[58.44189457153248, 1.9785500674491878],
					[58.441894600066789, 1.9785499964262219],
					[58.441889099625563, 1.9781221964216111],
					[58.441889096626603, 1.9781221746784956],
					[58.441889084999872, 1.9781221271628129],
					[58.441889075667788, 1.9781221049686717],
					[58.441661275726908, 1.9776999058441354],
					[58.441433475908887, 1.9772777050350521],
					[58.441208499417918, 1.976858249249871],
					[58.441203000254575, 1.9764277981085057],
					[58.441200199638786, 1.9759999999307341],
					[58.441197400171873, 1.9755721996926252],
					[58.441197400078273, 1.9755721979926568],
					[58.441197399984688, 1.9755721962927069],
					[58.441191900167638, 1.9751443987431383],
					[58.44118909964812, 1.9747167463334661],
					[58.441408478050739, 1.9742833907938171],
					[58.441630777995385, 1.9738472908076998],
					[58.441852977618375, 1.9734139911433508],
					[58.441852977524768, 1.973413989443354],
					[58.441852979307463, 1.9734139890862683],
					[58.442072324876911, 1.9729779966170846],
					[58.442297205016899, 1.9729724001422939],
					[58.442297273700028, 1.9729723709146314],
					[58.442297371712023, 1.9729722687802864],
					[58.442297400336749, 1.9729721994521792],
					[58.442294600120853, 1.9725443986642464],
					[58.442294570058237, 1.97254432734291],
					[58.442294467237836, 1.972544227628708],
					[58.442294395465041, 1.9725442007572822],
					[58.442069516995467, 1.9725497962143588],
					[58.441839076314885, 1.9721277042968219],
					[58.441839037241238, 1.9721276657192159],
					[58.441838946049494, 1.9721276135209329],
					[58.441838893040071, 1.972127600078813],
					[58.441613894004973, 1.9721358998494845],
					[58.441394394988279, 1.972141500845781],
					[58.441169517293247, 1.9721469967608374],
					[58.440939075807691, 1.9717277039088905],
					[58.440711276263961, 1.9713055048700667],
					[58.440483499976175, 1.9708832496698974],
					[58.440483500297042, 1.970455600077158],
					[58.440483497109518, 1.9704555749358361],
					[58.440483484588654, 1.9704555276033844],
					[58.440483476052997, 1.9704555035337337],
					[58.440253000229987, 1.9700332471522373],
					[58.440250200383872, 1.9696055991852615],
					[58.440250197289842, 1.9696055757440964],
					[58.440250184768658, 1.9696055284122704],
					[58.440250176232894, 1.9696055043429965],
					[58.44002239907136, 1.9691832478413331],
					[58.440016899828791, 1.9687555986240082],
					[58.440014099761754, 1.9683278465949892],
					[58.440236277909243, 1.967891791622814],
					[58.440236285604747, 1.967891767737626],
					[58.440236297430381, 1.9678917206819229],
					[58.440236299777808, 1.9678916978687822],
					[58.440230800625123, 1.9674639477152696],
					[58.440452924354283, 1.9670307961090099],
					[58.440677804641602, 1.9670252001724693],
					[58.440677856058478, 1.9670251847082323],
					[58.440677943131526, 1.9670251294405614],
					[58.440677978787669, 1.9670250896371337],
					[58.440897324927668, 1.9665890969327711],
					[58.44112220522581, 1.966583499585381],
					[58.441122206117157, 1.966583499406668],
					[58.441122207008497, 1.9665834992279543],
					[58.441347206035516, 1.966575200132229],
					[58.441572205107541, 1.9665695992177921],
					[58.441572255726705, 1.9665695856310732],
					[58.441572341908149, 1.9665695305394284],
					[58.441572377564142, 1.9665694907344462],
					[58.441794578254274, 1.9661361903785477],
					[58.442016704374289, 1.9657004327686645],
					[58.442241500375445, 1.966122250797349],
					[58.442244199595706, 1.9665500011907933],
					[58.442244230552895, 1.9665500723279421],
					[58.442244335160311, 1.9665501716666445],
					[58.442244407027815, 1.9665502002256807],
					[58.442469406924054, 1.9665418999360007],
					[58.44246947640309, 1.9665418688169982],
					[58.442469571829221, 1.9665417689007039],
					[58.442469600450345, 1.9665416995672611],
					[58.442466900254622, 1.9661139466104338],
					[58.442686224412547, 1.9656807977168349],
					[58.442911104904361, 1.9656752002142599],
					[58.442911156321017, 1.9656751847468259],
					[58.442911241610645, 1.9656751298294222],
					[58.442911278251344, 1.9656750915431986],
					[58.443133478178645, 1.9652389904530139],
					[58.443355724158195, 1.9648029963267875],
					[58.443580604862838, 1.9647973995777479],
					[58.443805482086908, 1.9647919030397114],
					[58.444030422726044, 1.9652139943668763],
					[58.444030423617377, 1.9652139941881115],
					[58.444030423711098, 1.9652139958881809],
					[58.444258124333146, 1.9656333954246339],
					[58.444258124333146, 1.9656333954246339],
					[58.444488723849936, 1.966055696075933],
					[58.444488762034005, 1.9660557348280825],
					[58.444488853227952, 1.9660557870138515],
					[58.444488905346518, 1.9660558006262097],
					[58.44471378222282, 1.9660502033901717],
					[58.444938723401677, 1.9664722938027328],
					[58.444938724293031, 1.9664722936239889],
					[58.444938724386731, 1.9664722953240876],
					[58.44516652394352, 1.9668917945665003],
					[58.445166562127362, 1.9668918333202701],
					[58.44516665163205, 1.9668918875666959],
					[58.445166704548186, 1.9668918993016387],
					[58.445391704613797, 1.9668862995010805],
					[58.445391706396485, 1.9668862991436085],
					[58.445391707381553, 1.9668863006650124],
					[58.445616706408771, 1.9668780004570752],
					[58.44584170468373, 1.9668724006379759],
					[58.446066704824638, 1.9668668999636534],
					[58.446066705715971, 1.9668668997849101],
					[58.446066706607311, 1.9668668996061742],
					[58.446066708390028, 1.9668668992486855],
					[58.446288905580971, 1.9668585002063275],
					[58.446513904822645, 1.9668529993377446],
					[58.446738904958558, 1.9668474001289398],
					[58.446738905849905, 1.9668473999501925],
					[58.44673890674126, 1.9668473997714453],
					[58.446963905835858, 1.9668391000517129],
					[58.447188905072473, 1.966833500645627],
					[58.447413905283803, 1.9668280005668801],
					[58.447413906175157, 1.9668280003881267],
					[58.447413907066498, 1.966828000209377],
					[58.447638906060746, 1.9668196001968543],
					[58.447861105325991, 1.9668140993318268],
					[58.448086104640886, 1.9668085008935867],
					[58.448086106423574, 1.9668085005360789],
					[58.448086107314907, 1.9668085003573237],
					[58.448311106383173, 1.9668001994255686],
					[58.448535983249201, 1.9667946028190013],
					[58.448763699805482, 1.9672139506450914],
					[58.448766499884329, 1.9676417017562691],
					[58.448766502978842, 1.9676417252028617],
					[58.448766514609595, 1.9676417727239341],
					[58.448766522958465, 1.967641793397805],
					[58.448991501096501, 1.9680639501579329],
					[58.448997000061681, 1.9684917024177806],
					[58.448999800245623, 1.9689222013125194],
					[58.44899980343358, 1.9689222264597173],
					[58.448999814970207, 1.9689222722812378],
					[58.448999824303897, 1.9689222944771569],
					[58.449227623623109, 1.9693417953110992],
					[58.449227623623109, 1.9693417953110992],
					[58.449458124049386, 1.9697639951986357],
					[58.449683122870177, 1.9701861943950287],
					[58.449683213171468, 1.9701862467770375],
					[58.449683389749325, 1.9701862457623045],
					[58.449683477714906, 1.9701861903078506],
					[58.449902978282118, 1.9697528906857116],
					[58.449902986962854, 1.9697528683158265],
					[58.449902997006205, 1.9697528216048956],
					[58.449903000245207, 1.9697527986068208],
					[58.44990020043516, 1.9693221994102297],
					[58.449900200215652, 1.9688943996875543],
					[58.449900200215652, 1.9688943996875543],
					[58.449897400382731, 1.9684666986969497],
					[58.449897400382731, 1.9684666986969497],
					[58.449897400289039, 1.968466696996602],
					[58.449891899939161, 1.9680388978984478],
					[58.449889099926075, 1.9676110987624686],
					[58.449886300264296, 1.9671832994441094],
					[58.449883499859688, 1.9667528475332219],
					[58.450102978296727, 1.9663194906486801],
					[58.450325178136879, 1.9658833902148178],
					[58.450325178136879, 1.9658833902148178],
					[58.450544577864527, 1.965450090900722],
					[58.45076682422782, 1.9650140967336078],
					[58.45099170506591, 1.9650085000115092],
					[58.450991755591033, 1.965008484717502],
					[58.450991841771391, 1.9650084296055661],
					[58.450991878411713, 1.9650083913092156],
					[58.451214078060985, 1.9645722911338626],
					[58.451214077967244, 1.9645722894334672],
					[58.451433423958264, 1.9641390975831656],
					[58.451655604937535, 1.9641335001326381],
					[58.451655656353878, 1.9641334846581662],
					[58.451655741642448, 1.9641334297217945],
					[58.451655778282451, 1.9641333914237775],
					[58.451877925299343, 1.9636973955850792],
					[58.452102806328377, 1.9636891007244424],
					[58.452327805276809, 1.9636835001412298],
					[58.452327855801741, 1.9636834848445779],
					[58.452327942872728, 1.9636834295482248],
					[58.452327977636145, 1.9636833899062485],
					[58.452547324803518, 1.9632501964465694],
					[58.452772205829262, 1.9632418999447052],
					[58.452997204878592, 1.9632362994697587],
					[58.45299720576994, 1.9632362992908765],
					[58.452997206755029, 1.9632363008124729],
					[58.453222205661937, 1.9632280008724075],
					[58.453444404662974, 1.9632223997506881],
					[58.453669404776072, 1.9632168999464821],
					[58.453669456192245, 1.963216884469565],
					[58.453669542371635, 1.9632168293485948],
					[58.453669578119978, 1.9632167912261747],
					[58.45389182409447, 1.9627807965286546],
					[58.454116705440455, 1.962775200515624],
					[58.454116706331803, 1.9627752003367178],
					[58.454116707223129, 1.962775200157818],
					[58.454341706211473, 1.9627668998642911],
					[58.454566705439589, 1.9627613004455879],
					[58.454791704754676, 1.962755800553597],
					[58.454791705646016, 1.9627558003746923],
					[58.454791707428683, 1.962755800016879],
					[58.454791708320023, 1.9627557998379761],
					[58.455011106328122, 1.9627474006635146],
					[58.455236104744046, 1.9627419005790394],
					[58.455461104848119, 1.9627363002505012],
					[58.455461105739467, 1.9627363000715863],
					[58.455461106630786, 1.9627362998926809],
					[58.455686105686645, 1.9627280000891925],
					[58.455911104891456, 1.9627223995633032],
					[58.455911156307501, 1.9627223840844421],
					[58.455911241689016, 1.9627223308378672],
					[58.455911278234709, 1.9627222908328164],
					[58.456133478145993, 1.962286190460315],
					[58.456355778047303, 1.9618528909751003],
					[58.456355777953526, 1.9618528892744673],
					[58.456355778938644, 1.9618528907961601],
					[58.456575178053896, 1.9614167908444897],
					[58.456797377952547, 1.9609833915217305],
					[58.456797378750075, 1.9609833896421149],
					[58.456797378750075, 1.9609833896421149],
					[58.457014026141358, 1.9605473956455606],
					[58.457238906808627, 1.9605390992297709],
					[58.457238957333026, 1.9605390839259698],
					[58.457239042713489, 1.9605390306718953],
					[58.457239078367074, 1.9605389908419688],
					[58.45746127762559, 1.9601056906301573],
					[58.457683478255781, 1.9596722914868363],
					[58.457683478161954, 1.9596722897861492],
					[58.457683479053273, 1.9596722896071492],
					[58.457902978976918, 1.9592361891739087],
					[58.457902986858159, 1.9592361686760273],
					[58.457902996897388, 1.9592361219515673],
					[58.457903000134351, 1.9592360989473492],
					[58.457900199939878, 1.9588082985481667],
					[58.45789739984825, 1.9583777983285273],
					[58.45789739984825, 1.9583777983285273],
					[58.457897399754408, 1.9583777966278331],
					[58.457891900379451, 1.9579499982751403],
					[58.457891899666386, 1.9575222009099638],
					[58.457891897367389, 1.9575221755785965],
					[58.457891884841366, 1.957522128228103],
					[58.457891876303115, 1.9575221041501567],
					[58.457664099578082, 1.9570999488434899],
					[58.457661300269045, 1.9566721986050866],
					[58.457661300269045, 1.9566721986050866],
					[58.457661300175182, 1.9566721969044107],
					[58.457655800313155, 1.9562443976456456],
					[58.457655797216468, 1.9562443741943154],
					[58.457655784689955, 1.9562443268446281],
					[58.457655776245339, 1.9562443044678357],
					[58.457427999323492, 1.9558221476973321],
					[58.457422400132558, 1.9553943985388917],
					[58.457419599586949, 1.9549666990686343],
					[58.457416899730141, 1.9545360990390646],
					[58.457416897430576, 1.9545360737082746],
					[58.457416884903452, 1.9545360263595624],
					[58.457416875661103, 1.9545360058631607],
					[58.45718910028684, 1.9541166493133091],
					[58.457186300190187, 1.9536860994867453],
					[58.457183499939973, 1.953258299088936],
					[58.457183499846067, 1.9532582973882935],
					[58.457183499846067, 1.9532582973882935],
					[58.457178000085776, 1.9528305976202192],
					[58.45717799698847, 1.9528305741695657],
					[58.457177985352061, 1.952830526642634],
					[58.457177976015537, 1.9528305044462013],
					[58.456950200242993, 1.9524082482871059],
					[58.45694740028609, 1.9519805981591205],
					[58.45694740028609, 1.9519805981591205],
					[58.456947400192192, 1.9519805964584771],
					[58.456941899588827, 1.9515527979564828],
					[58.456939100280813, 1.9511249996283957],
					[58.456939097089275, 1.9511249744774368],
					[58.456939084654863, 1.9511249288312895],
					[58.456939077100685, 1.9511249062770302],
					[58.456714099799292, 1.9507027488318911],
					[58.45671130023927, 1.9502749994768678],
					[58.456711300145344, 1.9502749977762603],
					[58.456711300145344, 1.9502749977762603],
					[58.456705799851946, 1.9498471964879036],
					[58.456705796848055, 1.9498471747384654],
					[58.456705785210559, 1.9498471272132472],
					[58.456705775873552, 1.9498471050179325],
					[58.456477999339988, 1.9494249483454822],
					[58.456472399814317, 1.9489971988741372],
					[58.456469600153042, 1.948566699003939],
					[58.456466899567687, 1.948138899328445],
					[58.456466897266885, 1.9481388739988508],
					[58.456466884737473, 1.9481388266538533],
					[58.456466876291486, 1.9481388042798606],
					[58.456236299963855, 1.9477166497010041],
					[58.456236300159446, 1.9472889007629428],
					[58.456236300065477, 1.9472888990623638],
					[58.456233500452562, 1.946861099369158],
					[58.456233500358579, 1.9468610976685814],
					[58.456233500358579, 1.9468610976685814],
					[58.456228000067689, 1.9464332961574364],
					[58.456227997063216, 1.9464332744086261],
					[58.456227985424505, 1.9464332268852695],
					[58.456227976086922, 1.9464332046911863],
					[58.456000199908814, 1.9460110485202968],
					[58.455997399600911, 1.9455832995625575],
					[58.455997400398211, 1.9455832976826493],
					[58.455997400398211, 1.9455832976826493],
					[58.455991900452233, 1.9451555983793629],
					[58.455989100063015, 1.9447249983555381],
					[58.455989096870226, 1.9447249732059351],
					[58.455989085324902, 1.9447249273840661],
					[58.455989075987048, 1.9447249051906002],
					[58.455761298800411, 1.9443055479464739],
					[58.455755799886497, 1.9438749966698077],
					[58.455755796787578, 1.9438749732210125],
					[58.455755785241962, 1.9438749273997449],
					[58.455755775903974, 1.9438749052066604],
					[58.455530800277558, 1.9434555490458829],
					[58.455528000164868, 1.9430277997561063],
					[58.455525200462958, 1.942597199412452],
					[58.455525197269786, 1.9425971742634007],
					[58.455525183847094, 1.9425971271011993],
					[58.455525175400162, 1.942597104729195],
					[58.455294600386217, 1.9421777478784306],
					[58.455291900386491, 1.9417499980787776],
					[58.455291897287189, 1.9417499746305071],
					[58.455291884755489, 1.9417499272895211],
					[58.455291876214389, 1.9417499032173651],
					[58.455061300174584, 1.941327748376547],
					[58.45505849990591, 1.9408999989731361],
					[58.455058496806473, 1.94089997552509],
					[58.455058485165758, 1.9408999280052803],
					[58.455058476718548, 1.9408999056339975],
					[58.454833498560703, 1.9404777491952179],
					[58.454827999644358, 1.9400499972189833],
					[58.454825200356737, 1.9396221984505333],
					[58.454825170275804, 1.939622127134119],
					[58.454825066539733, 1.9396220276649334],
					[58.454824994761218, 1.9396220008536804],
					[58.454599994991362, 1.9396276000603765],
					[58.454375118438776, 1.9396330968998954],
					[58.454147375977151, 1.9392110054932439],
					[58.454147337785336, 1.9392109667610333],
					[58.454147245596211, 1.9392109131124127],
					[58.45414719258428, 1.9392108997169613],
					[58.453922194416968, 1.9392192002463435],
					[58.453700117146219, 1.9392247975056649],
					[58.453472376424884, 1.9388055048664166],
					[58.453472338232984, 1.9388054661353449],
					[58.453472247920388, 1.9388054138301205],
					[58.453472194908429, 1.9388054004354505],
					[58.453247194874102, 1.9388108992081861],
					[58.453022316586591, 1.9388164970752653],
					[58.452791899842374, 1.9383971484818758],
					[58.452789099743121, 1.9379666989429862],
					[58.452786299711697, 1.9375388987636668],
					[58.452786300217099, 1.9371110999928431],
					[58.452786300122995, 1.9371110982924709],
					[58.452786300122995, 1.9371110982924709],
					[58.452786300028883, 1.937111096592105],
					[58.45278080072567, 1.9366833464779334],
					[58.453002977685351, 1.9362472915922],
					[58.453002986265901, 1.9362472675120417],
					[58.453002997282148, 1.9362472223090761],
					[58.453002999623671, 1.936247199485865],
					[58.45300020020963, 1.9358193994128978],
					[58.453000169141319, 1.9358193265826937],
					[58.453000064511322, 1.9358192273093142],
					[58.452999992732209, 1.9358192005070105],
					[58.452772194015928, 1.9358275997474432],
					[58.452547195335796, 1.9358330997933983],
					[58.452547194444527, 1.9358330999729669],
					[58.452547192661953, 1.9358331003321112],
					[58.452322194120278, 1.9358415006472367],
					[58.452097195432309, 1.9358470003174604],
					[58.451872318399083, 1.9358525973682079],
					[58.451647375905416, 1.9354332054964853],
					[58.451647337712622, 1.9354331667710281],
					[58.451647248290158, 1.9354331142973789],
					[58.451647195183831, 1.9354330992080078],
					[58.45142231812379, 1.9354386969213484],
					[58.451194575815713, 1.9350166051063717],
					[58.451194537622783, 1.9350165663818415],
					[58.451194446323491, 1.9350165125687178],
					[58.451194393311262, 1.9350164991804113],
					[58.450969516064731, 1.9350247968188692],
					[58.450739075620902, 1.9346027045629768],
					[58.450514076376045, 1.9341832054459631],
					[58.450514038182888, 1.9341831667230558],
					[58.450513947868735, 1.9341831144337047],
					[58.450513894762317, 1.934183099346594],
					[58.450288895093756, 1.9341886998609452],
					[58.450288824732802, 1.9341887312310853],
					[58.45028872755173, 1.9341888316164566],
					[58.450288699934475, 1.9341889025115357],
					[58.450294199702121, 1.9346167018112537],
					[58.450296999721658, 1.9350443538844273],
					[58.450077621777815, 1.9354805091041596],
					[58.449855475911512, 1.9359137038546763],
					[58.449630594296011, 1.9359220007880924],
					[58.449405717313951, 1.9359275974812973],
					[58.449175175645635, 1.9355082045661494],
					[58.448947400302025, 1.9350860487497397],
					[58.448944599895114, 1.9346582998090258],
					[58.448944596700464, 1.9346582746654872],
					[58.448944585057667, 1.9346582271560422],
					[58.448944576703632, 1.9346582064903215],
					[58.448719576982889, 1.9342360064141193],
					[58.448719575997451, 1.934236004893523],
					[58.448719575903318, 1.9342360031933583],
					[58.448489076285163, 1.933813804225391],
					[58.44826127601651, 1.9333916046697168],
					[58.448033475935922, 1.9329721058464815],
					[58.44803343764832, 1.9329720654273279],
					[58.448033348225074, 1.9329720129647192],
					[58.44803329521271, 1.9329719995803847],
					[58.447811216982551, 1.9329775969117653],
					[58.447583476192968, 1.9325582055872008],
					[58.447583476098806, 1.9325582038870965],
					[58.447352976353407, 1.9321360043995999],
					[58.447352938159746, 1.9321359656821964],
					[58.447352846953677, 1.9321359135819023],
					[58.44735279483254, 1.9321359000193692],
					[58.447127794826841, 1.9321414997256803],
					[58.447127793935557, 1.9321414999053037],
					[58.447127793044302, 1.9321415000849256],
					[58.446902917149487, 1.9321497958539051],
					[58.446675175891414, 1.9317277057630544],
					[58.446675137603499, 1.9317276653467323],
					[58.446675046397303, 1.9317276132483847],
					[58.446674993384853, 1.9317275998662893],
					[58.446452916569172, 1.9317358942405198],
					[58.446225176403203, 1.9313138047905265],
					[58.446225138209336, 1.9313137660751825],
					[58.446225047894266, 1.931313713798871],
					[58.44622499488181, 1.9313137004175125],
					[58.445999994609878, 1.9313191998898571],
					[58.445999993718594, 1.9313192000694941],
					[58.445999992827339, 1.9313192002491302],
					[58.445774993676501, 1.931327599262266],
					[58.445549995273481, 1.9313330996995044],
					[58.445549994382191, 1.9313330998791405],
					[58.445549992599638, 1.9313331002384104],
					[58.445324992642796, 1.9313415007181867],
					[58.44532494212266, 1.9313415160572838],
					[58.445324856849609, 1.9313415710612056],
					[58.445324822096708, 1.9313416107260328],
					[58.445105475388281, 1.931774804754538],
					[58.444880593110838, 1.9317830992788558],
					[58.444880541793516, 1.9317831164966701],
					[58.444880457411507, 1.9317831713191367],
					[58.444880421673012, 1.931783209462647],
					[58.444660974801522, 1.932216504384642],
					[58.444436093604814, 1.932224800683342],
					[58.44421109453377, 1.9322303994735621],
					[58.444211093642501, 1.9322303996531607],
					[58.444211092751182, 1.9322303998327723],
					[58.443986092861202, 1.932238700728262],
					[58.443986043232186, 1.9322387158857439],
					[58.443985956973215, 1.9322387693648475],
					[58.443985922219994, 1.9322388090271905],
					[58.443763775016379, 1.9326720043776258],
					[58.443538894019596, 1.932680400190004],
					[58.44331669511179, 1.9326859005108101],
					[58.443316643700129, 1.9326859160264616],
					[58.443316556643808, 1.9326859713828199],
					[58.443316520904993, 1.9326860095236102],
					[58.4430970217362, 1.9331221094617947],
					[58.442874822160491, 1.9335555093533183],
					[58.442652622115382, 1.9339916089895752],
					[58.442652622209515, 1.933991610689449],
					[58.442433121714295, 1.9344249090423677],
					[58.442210921802761, 1.9348610090630594],
					[58.441988722351255, 1.9352971083104393],
					[58.441766574523911, 1.9357304034445628],
					[58.441541694007618, 1.9357387006766078],
					[58.441322194812386, 1.9357442003460776],
					[58.441097317058187, 1.9357497964439734],
					[58.440866875267595, 1.9353305041300657],
					[58.440866761686593, 1.9353304548174894],
					[58.440866575175903, 1.9353305044113285],
					[58.440866500463606, 1.9353306036767692],
					[58.440872000148673, 1.9357583012648658],
					[58.440874800400856, 1.9361860525536529],
					[58.440655421178818, 1.9366221093471354],
					[58.440655413395213, 1.9366221315392442],
					[58.440655403363898, 1.9366221782462487],
					[58.440655400130822, 1.9366222012408019],
					[58.440658100197275, 1.9370500006936675],
					[58.440660899824572, 1.9374777524868032],
					[58.440441522009799, 1.9379110097993393],
					[58.440441513334662, 1.9379110321704773],
					[58.440441503208788, 1.9379110771770771],
					[58.440441499975478, 1.9379111001713885],
					[58.440444200411193, 1.9383389016441188],
					[58.440444200411193, 1.9383389016441188],
					[58.440444199613992, 1.9383389035233209],
					[58.440449798533223, 1.9387666525177616],
					[58.440227621876986, 1.9392027081810388],
					[58.440227614186988, 1.9392027320721019],
					[58.440227603169355, 1.9392027772574814],
					[58.440227599935788, 1.9392028002515567],
					[58.440230399714103, 1.9396306010102737],
					[58.440230399808165, 1.9396306027100474],
					[58.440230399808165, 1.9396306027100474],
					[58.440235898920186, 1.9400582536849962],
					[58.440013721758227, 1.9404943082290647],
					[58.440013714067952, 1.940494332119721],
					[58.44001370304985, 1.9404943773044474],
					[58.440013699816049, 1.9404944002982762],
					[58.440016499860533, 1.9409222013819309],
					[58.440016499860533, 1.9409222013819309],
					[58.440016499954574, 1.9409222030817179],
					[58.44002200019947, 1.9413500003984649],
					[58.440021999995345, 1.9417777528563149],
					[58.439802621194119, 1.9422138091586396],
					[58.439802613409519, 1.9422138313490276],
					[58.439802603282075, 1.9422138763536674],
					[58.439802600047969, 1.9422138993472169],
					[58.439802599757002, 1.9426416522105361],
					[58.439585921264253, 1.9430749103745162],
					[58.439585913479476, 1.9430749325646006],
					[58.439585903351727, 1.9430749775687177],
					[58.43958590011745, 1.9430750005620749],
					[58.43958589961408, 1.9435028002944614],
					[58.43958589961408, 1.9435028002944614],
					[58.43958870031905, 1.9439332537651686],
					[58.439369222243755, 1.944366609961754],
					[58.439369212676148, 1.9443666325099409],
					[58.439369202547937, 1.9443666775134507],
					[58.439369200204737, 1.944366700327284],
					[58.439371999966042, 1.9447944011698857],
					[58.439371999966042, 1.9447944011698857],
					[58.43937200006004, 1.9447944028696393],
					[58.439377600288751, 1.9452222021093202],
					[58.439380399707936, 1.9456527523638094],
					[58.439158122395916, 1.946086009274655],
					[58.439158113719287, 1.9460860316430357],
					[58.43915810279313, 1.9460860785247838],
					[58.439158099652296, 1.9460861032173653],
					[58.43916370005492, 1.94651390118712],
					[58.43916650004018, 1.9469416537332256],
					[58.438947021234348, 1.9473777102275969],
					[58.438947013448796, 1.9473777324163699],
					[58.438947003319491, 1.9473777774185239],
					[58.438947000084426, 1.947377800411122],
					[58.438949799941661, 1.94780560159647],
					[58.438952599998018, 1.9482332531084807],
					[58.438733122047203, 1.9486666100782224],
					[58.438733113370098, 1.9486666324457245],
					[58.438733103240317, 1.9486666774472656],
					[58.438733100005031, 1.9486667004396161],
					[58.43873589966045, 1.9490972002785472],
					[58.438738700084514, 1.9495249517769415],
					[58.438516521599517, 1.9499582092972283],
					[58.438516513813497, 1.9499582314851664],
					[58.438516502885875, 1.9499582783649467],
					[58.438516499744246, 1.9499583030567678],
					[58.438522000006117, 1.9503861019797866],
					[58.438524800385203, 1.9508166534776801],
					[58.438305475106439, 1.9512498039959938],
					[58.438080593469451, 1.9512581004338192],
					[58.438080542946224, 1.9512581157404796],
					[58.438080456772049, 1.9512581708630847],
					[58.438080421918499, 1.9512582088002239],
					[58.437858176218704, 1.9516915025665802],
					[58.43763329501779, 1.9516969996527829],
					[58.437633243603194, 1.9516970151376529],
					[58.437633158320082, 1.9516970700793457],
					[58.437633121683739, 1.9516971083736694],
					[58.437410976185937, 1.952133102335752],
					[58.437191695330057, 1.952138700032666],
					[58.437191643915384, 1.9521387155166334],
					[58.437191557740746, 1.9521387706355484],
					[58.437191521995565, 1.9521388087498717],
					[58.436969276113395, 1.9525748021353924],
					[58.436744395220536, 1.9525803994559758],
					[58.436744343805792, 1.9525804149390544],
					[58.436744257630913, 1.9525804700561158],
					[58.436744221885576, 1.9525805081694818],
					[58.436522022173747, 1.9530166089464462],
					[58.436522022267624, 1.9530166106460909],
					[58.43630267521074, 1.9534498041071797],
					[58.436077793067177, 1.9534581005107552],
					[58.436077742543581, 1.9534581158131885],
					[58.436077657259574, 1.9534581707479446],
					[58.436077622405278, 1.9534582086806351],
					[58.435855422428119, 1.9538916089593983],
					[58.435633176032738, 1.9543276024780545],
					[58.435408295376817, 1.954333099974102],
					[58.435408243961803, 1.9543331154539125],
					[58.435408156894717, 1.9543331707433726],
					[58.435408122133971, 1.9543332103740492],
					[58.435188722350702, 1.9547666095243859],
					[58.434966521702727, 1.955202709893872],
					[58.434966521702727, 1.955202709893872],
					[58.434966520905249, 1.9552027117724109],
					[58.434749820713513, 1.9556388103928593],
					[58.434527675891687, 1.9560720027625875],
					[58.434302794777921, 1.9560776001155937],
					[58.434302744253948, 1.956077615413327],
					[58.434302657185981, 1.9560776706965723],
					[58.434302620641994, 1.9560777106820828],
					[58.434083122351502, 1.9565138084278824],
					[58.433860922435102, 1.9569471090450501],
					[58.43363872163399, 1.9573832083993914],
					[58.433416521961696, 1.9578166090490383],
					[58.433416522055509, 1.9578166107485502],
					[58.433416521070377, 1.957816609227915],
					[58.433197022247548, 1.9582527097318154],
					[58.432974876113853, 1.9586859024818792],
					[58.432749994569292, 1.9586914992901954],
					[58.432749944044907, 1.9586915145833448],
					[58.432749857866952, 1.9586915696786107],
					[58.432749822213353, 1.9586916094807223],
					[58.432527621688919, 1.9591277083415601],
					[58.432527620891378, 1.9591277102198756],
					[58.43230817536022, 1.9595637024185166],
					[58.432086094864808, 1.95956920026805],
					[58.431861094748314, 1.9595748008663789],
					[58.431861093763182, 1.9595747993457653],
					[58.431861092871848, 1.9595747995245878],
					[58.431636094012219, 1.9595831007843394],
					[58.431411094779087, 1.9595887008276542],
					[58.431411044160768, 1.9595887144193627],
					[58.431410957982322, 1.9595887695101057],
					[58.431410922328432, 1.9595888093097207],
					[58.431188775926231, 1.9600248034533185],
					[58.430963894687636, 1.9600304007510745],
					[58.430963844069247, 1.9600304143419258],
					[58.430963756999233, 1.960030469609626],
					[58.430963722236498, 1.9600305092294619],
					[58.430744222360396, 1.960463809085168],
					[58.43074421278974, 1.9604638316234675],
					[58.430744202749295, 1.9604638783109141],
					[58.430744200403083, 1.9604639011182414],
					[58.430747000126885, 1.9608917006743074],
					[58.430747000220663, 1.9608917023737007],
					[58.430747000220663, 1.9608917023737007],
					[58.430752598838154, 1.9613221534944258],
					[58.430530422384479, 1.9617555097114128],
					[58.430530413611152, 1.9617555303710867],
					[58.430530402678897, 1.9617555772366522],
					[58.430530400426186, 1.9617556017431845],
					[58.430533100261755, 1.9621833014391954],
					[58.430533100261755, 1.9621833014391954],
					[58.430533100355504, 1.9621833031385849],
					[58.430538698909849, 1.9626110537366708],
					[58.430316522279732, 1.9630471097930684],
					[58.430316513599934, 1.9630471321516911],
					[58.430316502667182, 1.9630471790166206],
					[58.430316500414243, 1.9630472035229296],
					[58.430321998727322, 1.9634749524478703],
					[58.430099876320881, 1.9639081024451637],
					[58.429874994902697, 1.9639136998694262],
					[58.429874944377495, 1.9639137151535389],
					[58.429874858196918, 1.9639137702311225],
					[58.42987482155646, 1.9639138085039312],
					[58.429652674615724, 1.9643498033856961],
					[58.429427792581748, 1.9643581004341035],
					[58.429427742056475, 1.9643581157173495],
					[58.429427656766997, 1.9643581706143896],
					[58.429427622002798, 1.9643582102281736],
					[58.429208176238227, 1.9647915037513199],
					[58.428986094799562, 1.9647969992617589],
					[58.428761094804919, 1.9648026002785455],
					[58.428761093913579, 1.9648026004572161],
					[58.428761093022246, 1.9648026006358839],
					[58.428536094075248, 1.9648109003849656],
					[58.428311094963973, 1.9648165008470491],
					[58.42831109397892, 1.9648164993264274],
					[58.428311093087572, 1.9648164995050927],
					[58.428086094225336, 1.9648248005429012],
					[58.427861095106074, 1.9648304006290087],
					[58.427638894956324, 1.9648358999385052],
					[58.427638844430973, 1.9648359152201011],
					[58.427638758249863, 1.9648359702915381],
					[58.427638721609057, 1.9648360085607792],
					[58.427416576248902, 1.9652720023081778],
					[58.427191695120854, 1.9652775994909391],
					[58.427191643797791, 1.965277616649552],
					[58.42719155672512, 1.9652776718977796],
					[58.427191520975484, 1.9652777099874044],
					[58.426972075275167, 1.9657137036750274],
					[58.426747194962417, 1.9657191996423198],
					[58.426522195153225, 1.9657248001247103],
					[58.426522143736392, 1.9657248155830607],
					[58.426522058446167, 1.9657248704717583],
					[58.426522021805035, 1.9657249087387936],
					[58.426299875906309, 1.9661609027393141],
					[58.426074994983402, 1.9661665003200879],
					[58.426074994092076, 1.9661665004987046],
					[58.426074993200729, 1.9661665006773206],
					[58.425849992727848, 1.9661748003309387],
					[58.425849943093624, 1.9661748154310357],
					[58.42584985691181, 1.9661748704960964],
					[58.425849822053216, 1.9661749084046523],
					[58.425627675631915, 1.9666081025381936],
					[58.425408294750326, 1.9666136993786956],
					[58.425408293858979, 1.9666136995572956],
					[58.425408292967639, 1.9666136997358958],
					[58.42518341582366, 1.9666219953711583],
					[58.424952976234458, 1.9661999033192366],
					[58.424952937159496, 1.966199864765503],
					[58.424952845966125, 1.9661998126045899],
					[58.424952792956354, 1.9661997991760352],
					[58.424727794337734, 1.9662080991566262],
					[58.424502794698995, 1.9662137003192428],
					[58.424277918208915, 1.966219197603148],
					[58.424052976839967, 1.9657971058724344],
					[58.424052937671192, 1.9657970656209645],
					[58.424052845586345, 1.9657970136408984],
					[58.42405279257656, 1.9657970002131779],
					[58.423827794017505, 1.9658054000475942],
					[58.423602918388099, 1.9658108976374495],
					[58.423375176165493, 1.965388805412726],
					[58.423375137981672, 1.9653887666828938],
					[58.423375045803006, 1.9653887130057086],
					[58.423374992793171, 1.9653886995788077],
					[58.423150118254682, 1.9653969959633333],
					[58.422925177265995, 1.9649749067025171],
					[58.422925138097028, 1.9649748664531979],
					[58.422925045918205, 1.9649748127777085],
					[58.422924992908335, 1.9649747993515456],
					[58.422700115677237, 1.9649830968984703],
					[58.422469576392814, 1.9645610035488401],
					[58.422469538208787, 1.9645609648208493],
					[58.422469447014898, 1.9645609126674415],
					[58.422469394990081, 1.9645609007624187],
					[58.422244517397623, 1.964566496927187],
					[58.42201687590687, 1.9641471047241206],
					[58.422016837722744, 1.9641470659970548],
					[58.422016748311414, 1.9641470134880299],
					[58.42201669530148, 1.9641470000633834],
					[58.421794517664175, 1.9641525968622422],
					[58.42156687611741, 1.9637305051899538],
					[58.421566837933199, 1.9637304664638024],
					[58.421566745753957, 1.9637304127934465],
					[58.421566692744022, 1.9637303993695339],
					[58.421341816161473, 1.9637386952306348],
					[58.421111275694315, 1.9633166049012338],
					[58.421111238307581, 1.9633165642984136],
					[58.421111148098348, 1.9633165136703299],
					[58.421111095088392, 1.9633165002471813],
					[58.420886218125702, 1.9633219969214029],
					[58.420661276340304, 1.9629027051547199],
					[58.420661238155873, 1.9629026664304154],
					[58.420661147852776, 1.9629026141050294],
					[58.420661094842764, 1.9629026006826331],
					[58.420436217759438, 1.962908096320866],
					[58.42020847576169, 1.962486005142936],
					[58.420208437577152, 1.9624859664195573],
					[58.420208346288831, 1.9624859125756242],
					[58.420208293278804, 1.9624858991539809],
					[58.419983415655864, 1.9624941953859107],
					[58.419752975664231, 1.9620721048760865],
					[58.41952797675836, 1.961649906203502],
					[58.419527975867027, 1.9616499063821888],
					[58.419527975773278, 1.9616499046833178],
					[58.419300199680222, 1.9612305489612787],
					[58.419297399878388, 1.9608027983661953],
					[58.419297396689288, 1.9608027732409041],
					[58.419297385150593, 1.9608027274602777],
					[58.419297375722131, 1.960802703585937],
					[58.419066899710934, 1.9603805476347267],
					[58.419064099794745, 1.9599527996225607],
					[58.41906129999618, 1.9595249982282938],
					[58.419061296900615, 1.9595249748021522],
					[58.419061285267695, 1.959524927323433],
					[58.419061276917915, 1.9595249066685396],
					[58.418836276703658, 1.9591027058229207],
					[58.41883618729058, 1.9591026533310598],
					[58.418836009822385, 1.9591026545677572],
					[58.41883592176729, 1.9591027082963379],
					[58.418613776249167, 1.9595387026628981],
					[58.418388895132004, 1.959544200544731],
					[58.418388894240664, 1.9595442007234694],
					[58.418388893255539, 1.9595441992034059],
					[58.41816389299283, 1.9595525994812408],
					[58.418163843453272, 1.9595526162852857],
					[58.418163757180601, 1.9595526696541345],
					[58.418163722417695, 1.9595527092590519],
					[58.417941576150639, 1.9599859026834616],
					[58.417716695225408, 1.9599915002766513],
					[58.417716694334047, 1.9599915004553723],
					[58.417716693442721, 1.9599915006340891],
					[58.417491818106058, 1.9599997952102206],
					[58.41726687737917, 1.9595777059651551],
					[58.417266838208768, 1.9595776657279818],
					[58.417266746121889, 1.9595776137730987],
					[58.417266693111664, 1.959577600356657],
					[58.417041693627063, 1.9595858998377429],
					[58.416816694777218, 1.9595915007441769],
					[58.416591694847519, 1.9595970003536209],
					[58.416591693956192, 1.9595970005323402],
					[58.416591693064859, 1.9595970007110612],
					[58.416366693751833, 1.959605400881147],
					[58.416141694705559, 1.9596108999370767],
					[58.415922194962825, 1.9596165001536474],
					[58.415922194071491, 1.9596165003323598],
					[58.415922193180172, 1.9596165005110726],
					[58.415697316109785, 1.9596247965600999],
					[58.415466875874664, 1.9592027046779514],
					[58.415466785570366, 1.959202652368947],
					[58.415466609087268, 1.959202655123681],
					[58.415466521125772, 1.9592027105448868],
					[58.415247022120646, 1.9596388084442884],
					[58.415024822378719, 1.9600721084847117],
					[58.414802621839492, 1.9605082091659789],
					[58.414802621933255, 1.9605082108646066],
					[58.41458312231412, 1.9609416089572052],
					[58.414360975980806, 1.9613748036501617],
					[58.414136092959865, 1.9613831004834588],
					[58.414136043326209, 1.9613831155840096],
					[58.414135957146286, 1.9613831706401681],
					[58.414135922288942, 1.9613832085398661],
					[58.413913897010161, 1.9618162671907711],
					[58.413689077410702, 1.9613943061084052],
					[58.413689038240904, 1.9613942658729684],
					[58.413688946061022, 1.9613942122192969],
					[58.413688893144709, 1.9613942004996177],
					[58.413464017330753, 1.9614025948883971],
					[58.413236276348044, 1.9609805057756347],
					[58.413236275362962, 1.9609805042557382],
					[58.413005775692788, 1.9605610049396689],
					[58.412780799962569, 1.9601388493461145],
					[58.412778000063305, 1.9597110990283755],
					[58.412777996967833, 1.9597110756063494],
					[58.412777985335161, 1.9597110281359098],
					[58.412777975906607, 1.9597110042661769],
					[58.41255017568043, 1.9592888044514394],
					[58.41255017568043, 1.9592888044514394],
					[58.412319575631962, 1.9588666044820833],
					[58.412319537352779, 1.9588665640722638],
					[58.412319446250919, 1.9588665136454169],
					[58.412319393240665, 1.9588665002313648],
					[58.412094392842448, 1.9588747992044317],
					[58.412094343302932, 1.958874816005898],
					[58.412094257030368, 1.9588748693660623],
					[58.412094222267534, 1.9588749089643314],
					[58.411872021902397, 1.9593082098464447],
					[58.411872021902397, 1.9593082098464447],
					[58.411872021011078, 1.9593082100251338],
					[58.41165267516535, 1.9597442025744078],
					[58.411430595229888, 1.9597497994662592],
					[58.411430543907557, 1.9597498166234513],
					[58.411430456836996, 1.9597498718575077],
					[58.41143042108876, 1.959749909934368],
					[58.411210922171477, 1.9601860086478466],
					[58.410988776112404, 1.9606192028536791],
					[58.410763895459489, 1.9606248004562012],
					[58.410763893676801, 1.9606248008135112],
					[58.410763892691705, 1.9606247992937325],
					[58.410538893567278, 1.9606330993179584],
					[58.410314017456884, 1.960638697349927],
					[58.41008347588776, 1.9602166045094251],
					[58.410083436717692, 1.9602165642791518],
					[58.410083345616336, 1.9602165138515242],
					[58.41008329260621, 1.9602165004360448],
					[58.409858294350329, 1.9602248007080256],
					[58.409638894864493, 1.960230400501608],
					[58.409638893973145, 1.9602304006802653],
					[58.409544393741328, 1.9602331006887885],
					[58.409544352833649, 1.9602331106052071],
					[58.409544278288962, 1.9602331478695891],
					[58.409544243760635, 1.9602331753962126],
					[58.409055443696928, 1.9608415747623442],
					[58.409055422349617, 1.9608416288377246],
					[58.409055413103239, 1.9608417388707831],
					[58.409055425297929, 1.9608417965268223],
					[58.409197023726747, 1.9611000964982943],
					[58.40942480014175, 1.9615222509882375],
					[58.409424799797918, 1.9619500005597486],
					[58.409424802986656, 1.9619500256781697],
					[58.409424814524705, 1.9619500714465892],
					[58.409424823952811, 1.9619500953147311],
					[58.409652600550558, 1.9623694517264869],
					[58.409658100030313, 1.9627972024845188],
					[58.409658103125146, 1.9627972259047957],
					[58.409658114756624, 1.9627972733722008],
					[58.409658124090853, 1.962797295542362],
					[58.409885899733077, 1.9632194504053089],
					[58.409888699820925, 1.9636472002305725],
					[58.409888699914639, 1.9636472019289737],
					[58.409888700008366, 1.9636472036273747],
					[58.409894200379583, 1.9640750013982653],
					[58.409897000321116, 1.9645028008607226],
					[58.409896999673528, 1.9649305996544955],
					[58.409896999767213, 1.9649306013529046],
					[58.409899800322364, 1.9653583008387683],
					[58.409899800416063, 1.9653583025371781],
					[58.409899800416063, 1.9653583025371781],
					[58.409905399674592, 1.9657861025108396],
					[58.409908100062246, 1.9662139008577908],
					[58.409908103250167, 1.9662139259770006],
					[58.409908115771742, 1.9662139732674746],
					[58.40990812510541, 1.9662139954387237],
					[58.410138700017313, 1.9666333519076826],
					[58.410141500133093, 1.967061100216752],
					[58.410141503320837, 1.9670611253362209],
					[58.410141514950759, 1.9670611728057936],
					[58.410141524284292, 1.967061194977441],
					[58.410369200895026, 1.9674833514355896],
					[58.410374799963094, 1.9679111016731443],
					[58.410374800209425, 1.9683388995407589],
					[58.410374803396927, 1.9683389246605221],
					[58.410374815026394, 1.9683389721308693],
					[58.410374824359707, 1.9683389943029939],
					[58.410602600928641, 1.9687611515325452],
					[58.410608099766534, 1.9691861012660297],
					[58.410610900216547, 1.9696139007355757],
					[58.410613700125488, 1.9700417002019308],
					[58.410616499692708, 1.9704722007853144],
					[58.410616499786343, 1.970472202483776],
					[58.410616499786343, 1.970472202483776],
					[58.410622000108262, 1.9708972014416286],
					[58.410624800177679, 1.971325000574438],
					[58.410624799740567, 1.971752800198024],
					[58.410624799740567, 1.971752800198024],
					[58.410624799834174, 1.971752801896492],
					[58.410624799927788, 1.971752803594955],
					[58.410630399003246, 1.9721832535607331],
					[58.410408121961282, 1.9726166085123407],
					[58.410408114170856, 1.9726166306773438],
					[58.410408103233991, 1.9726166775125502],
					[58.410408099993944, 1.9726167004842996],
					[58.410410899704694, 1.9730444003284766],
					[58.410410899798293, 1.9730444020269524],
					[58.410410899891879, 1.9730444037254116],
					[58.410416499070408, 1.9734721530010784],
					[58.410194221649249, 1.9739055085678352],
					[58.410194221742834, 1.9739055102662881],
					[58.409974821854426, 1.9743388081810593],
					[58.409752621862829, 1.9747749081985169],
					[58.40953042224703, 1.9752082085239313],
					[58.409530422340595, 1.9752082102223587],
					[58.409310922390638, 1.975641610498408],
					[58.409310920607901, 1.9756416108549331],
					[58.409091521185509, 1.9760777103739533],
					[58.408872022105783, 1.9765110097269569],
					[58.408649821812681, 1.9769443094384593],
					[58.40842767588645, 1.9773804033574172],
					[58.408202795409785, 1.9773859001245044],
					[58.408202743990969, 1.9773859155558489],
					[58.408202656911769, 1.9773859707411203],
					[58.408202622142738, 1.9773860103168419],
					[58.407983174812195, 1.9778192053163346],
					[58.407758292662422, 1.977827599412465],
					[58.407758243119815, 1.9778276161848791],
					[58.407758156838206, 1.9778276694917303],
					[58.407758122069019, 1.9778277090665086],
					[58.407535921717546, 1.9782610096003352],
					[58.407535913926068, 1.9782610317623019],
					[58.407535902987021, 1.9782610785920138],
					[58.407535899745895, 1.9782611015614249],
					[58.407538699794806, 1.9786889017141087],
					[58.407538699794806, 1.9786889017141087],
					[58.407538699888313, 1.9786889034124517],
					[58.407544200107324, 1.9791167011923145],
					[58.407546999891082, 1.9795444016203787],
					[58.40754980010032, 1.9799722014403622],
					[58.40754980010032, 1.9799722014403622],
					[58.407549800193813, 1.9799722031387101],
					[58.407555398913352, 1.9803999535601884],
					[58.407333122128215, 1.9808332081368376],
					[58.407333114336275, 1.9808332302981373],
					[58.407333103396248, 1.9808332771268431],
					[58.407333100154695, 1.9808333000958962],
				],
				[
					[58.318785906983706, 1.8848361099874293],
					[58.318785909918425, 1.8848361624935186],
					[58.319080409770244, 1.8857306632523909],
					[58.319080416955245, 1.8857306789307133],
					[58.31908043498472, 1.8857307112615638],
					[58.319080444843344, 1.8857307264002081],
					[58.320233145067633, 1.8871390263446786],
					[58.320233155628031, 1.8871390379166892],
					[58.320233178341972, 1.8871390573133044],
					[58.320233189698932, 1.8871390670116244],
					[58.321283189758198, 1.8878279675438971],
					[58.321283223868491, 1.8878279812155576],
					[58.321283295086097, 1.8878279976762067],
					[58.321283331112966, 1.887827997257272],
					[58.322708330622888, 1.8876029981569629],
					[58.322708351917235, 1.8876029921459967],
					[58.322708391548439, 1.8876029755818475],
					[58.322708410871108, 1.8876029665427334],
					[58.324408408896836, 1.8864668686042361],
					[58.325922268780388, 1.8855140926921283],
					[58.327822170370517, 1.8852752041159866],
					[58.328966544000586, 1.8857807494524723],
					[58.330230402759341, 1.8889944453368031],
					[58.330444199775194, 1.8918778002490146],
					[58.330110901388622, 1.8973110715245933],
					[58.329194207801876, 1.9013138424883571],
					[58.327894219158985, 1.9047888126214572],
					[58.326674829242016, 1.9068137974935622],
					[58.326674822583982, 1.9068138073999461],
					[58.326674812927294, 1.9068138281892202],
					[58.326674809037364, 1.9068138392515457],
					[58.325472011234133, 1.9106443331236322],
					[58.324913713078232, 1.9121082285357114],
					[58.324913707782592, 1.9121082467320216],
					[58.324913701647546, 1.9121082822282747],
					[58.324913700011237, 1.9121083014016187],
					[58.324938700252098, 1.914816702593314],
					[58.324938703354498, 1.9148167259530027],
					[58.324938715001153, 1.9148167732912607],
					[58.324938724342381, 1.9148167953964998],
					[58.325999824444281, 1.9167722951858224],
					[58.325999836176223, 1.9167723116721194],
					[58.32599986132842, 1.9167723425921808],
					[58.325999875639887, 1.9167723568467843],
					[58.328199876363612, 1.9185112561180153],
					[58.328199888609703, 1.918511265649484],
					[58.328199915587227, 1.9185112807863378],
					[58.328199930318647, 1.9185112863917497],
					[58.330872130376299, 1.9195140862290319],
					[58.330872172222634, 1.9195140932390968],
					[58.330872256567204, 1.9195140865675542],
					[58.330872298268382, 1.9195140747595174],
					[58.332575098203876, 1.9185584744389614],
					[58.332575106833829, 1.9185584675637211],
					[58.332575125079245, 1.9185584553285859],
					[58.332575133709199, 1.918558448453328],
					[58.333611233617646, 1.9176251490399707],
					[58.333611254579793, 1.9176251208359893],
					[58.333611288105651, 1.9176250592625197],
					[58.333611297995624, 1.9176250264306134],
					[58.334150194171741, 1.9136306607176565],
					[58.334802925132877, 1.9123473968218447],
					[58.336702805901446, 1.9122890998747484],
					[58.336702813922727, 1.9122890982605709],
					[58.336702829262698, 1.9122890986009682],
					[58.336702836392696, 1.9122890971661508],
					[58.339077800354005, 1.9118530020811195],
					[58.341552730805212, 1.9123195908012482],
					[58.342794260766475, 1.91300575258521],
					[58.343755403186115, 1.9144222655497758],
					[58.343872000390498, 1.9169500056222608],
					[58.34389700009104, 1.9198417017487994],
					[58.343897000750644, 1.91984171361432],
					[58.343897002866896, 1.9198417354710973],
					[58.343897005214814, 1.9198417452831245],
					[58.344488704590475, 1.9223528467095052],
					[58.344488707829477, 1.9223528563427184],
					[58.344488715198743, 1.9223528754299926],
					[58.344488719423204, 1.9223528865791861],
					[58.345166504336383, 1.9237806542367335],
					[58.345480400825537, 1.9272056180869279],
					[58.345794201372385, 1.9308111177099863],
					[58.345794203016375, 1.9308111310926797],
					[58.345794208978198, 1.9308111573212343],
					[58.345794213296031, 1.9308111701671011],
					[58.346474812920626, 1.9326000712620961],
					[58.346474823947659, 1.9326000913322803],
					[58.346474850458193, 1.9326001305781091],
					[58.346474864956306, 1.9326001482374098],
					[58.348199864752701, 1.9341751473922815],
					[58.34819986860002, 1.9341751517627415],
					[58.348199877091908, 1.9341751586294715],
					[58.348199880845165, 1.934175161304609],
					[58.350783181386191, 1.9360862615046961],
					[58.350783202732259, 1.9360862726502845],
					[58.350783247910194, 1.9360862910139613],
					[58.350783271742074, 1.9360862982320759],
					[58.351830566334073, 1.9362362966627917],
					[58.352591551709992, 1.9363918737712476],
					[58.353272012815147, 1.9381806702609079],
					[58.353272021964806, 1.9381806889986648],
					[58.353272042750056, 1.9381807225465362],
					[58.353272055276925, 1.938180737177847],
					[58.35461375211132, 1.9395862343759631],
					[58.355380448580235, 1.9404695305946547],
					[58.355380459042379, 1.9404695404980503],
					[58.355380478981395, 1.9404695587879286],
					[58.355380490240847, 1.9404695668168557],
					[58.356433163724397, 1.9411612504521945],
					[58.357297028963359, 1.9422195057254026],
					[58.358266518478572, 1.944361182699313],
					[58.358266528424828, 1.9443611995667496],
					[58.358266551882551, 1.9443612325868371],
					[58.358266565394032, 1.9443612487394972],
					[58.359413724390798, 1.9454112104250323],
					[58.360002608228342, 1.9473833567118086],
					[58.360002631169579, 1.9473833966979417],
					[58.360002689670743, 1.9473834604231386],
					[58.360002727107222, 1.9473834855008796],
					[58.362574926966218, 1.9483918861669995],
					[58.362574991986094, 1.9483918885707363],
					[58.362575109968404, 1.9483918546372674],
					[58.362575161945635, 1.9483918167826069],
					[58.364830761997951, 1.9452501164766371],
					[58.364830763686655, 1.9452501144230301],
					[58.364830766172773, 1.9452501104945339],
					[58.364830767861477, 1.9452501084409244],
					[58.367836267996346, 1.9406390095525319],
					[58.367836273671607, 1.9406389981227434],
					[58.367836284928146, 1.9406389735668648],
					[58.367836289712081, 1.9406389623159372],
					[58.368669552678611, 1.9380807754077547],
					[58.36990271039334, 1.9378613189117979],
					[58.371155416986895, 1.9396333871143312],
					[58.372427605117728, 1.944119443491338],
					[58.373424802325559, 1.9498806271550937],
					[58.373733101408142, 1.9525833225625446],
					[58.373733101689616, 1.95258332765243],
					[58.373733104035225, 1.9525833374749944],
					[58.373733105208025, 1.952583342386282],
					[58.37541090518603, 1.9603167412546767],
					[58.375410905373464, 1.960316744648156],
					[58.375410905467191, 1.9603167463448889],
					[58.375410906358532, 1.9603167461664763],
					[58.376885905767296, 1.9664250465916342],
					[58.376885908018238, 1.9664250547192348],
					[58.376885912332817, 1.9664250675807919],
					[58.376885913692398, 1.9664250758866459],
					[58.379116513530093, 1.9719694745825229],
					[58.379116520611802, 1.971969488608095],
					[58.379116536464359, 1.9719695146060163],
					[58.37911654523522, 1.9719695265783637],
					[58.381708145427176, 1.9751529256494513],
					[58.381708158935211, 1.9751529418243012],
					[58.381708190033997, 1.9751529664952125],
					[58.381708206826922, 1.9751529768664648],
					[58.38399989524774, 1.9763529703750919],
					[58.38744149633618, 1.9787862001268817],
					[58.387202602119466, 1.984405545878662],
					[58.385516524176261, 1.9875305056579484],
					[58.38551652337825, 1.987530507533066],
					[58.385516520890825, 1.987530511461101],
					[58.385516519201403, 1.987530513514014],
					[58.384205419390284, 1.9902832137093398],
					[58.384205422741012, 1.9902833074144459],
					[58.384205506298713, 1.9902834503318951],
					[58.384205584722885, 1.9902834998996854],
					[58.385919182181802, 1.9904140848208762],
					[58.38565541742004, 1.9933192051993143],
					[58.384419394746047, 1.9933526006010371],
					[58.384419333799023, 1.9933526230420926],
					[58.384419238916763, 1.9933527002905682],
					[58.384419204981548, 1.9933527550979717],
					[58.383960905113625, 1.995358255458892],
					[58.383960903185553, 1.995358269569838],
					[58.383960901205505, 1.9953582991338539],
					[58.383960900168844, 1.9953583130672119],
					[58.384283099904451, 2.0002361134882802],
					[58.384283102339722, 2.0002361250144998],
					[58.384283106225659, 2.0002361465470724],
					[58.384283108754126, 2.0002361597705836],
					[58.385160904362252, 2.0031056421040274],
					[58.385766502007399, 2.007613927683467],
					[58.385766505705874, 2.0076139458230444],
					[58.385766519435968, 2.0076139825585546],
					[58.385766525715631, 2.0076139984688512],
					[58.387502626154316, 2.0106417994229053],
					[58.387502630983754, 2.010641805326757],
					[58.387502639937374, 2.0106418207067014],
					[58.387502644766826, 2.0106418266105655],
					[58.388077644826161, 2.0113501263443596],
					[58.388077705375295, 2.0113501623562855],
					[58.388077833673606, 2.011350184899972],
					[58.388077903205684, 2.0113501710773045],
					[58.389969436652891, 2.0102113118420144],
					[58.391588862144651, 2.0105279951899511],
					[58.39158888034563, 2.0105279984355615],
					[58.391588916375348, 2.0105279981359949],
					[58.391588934297125, 2.0105279962885114],
					[58.392822234025026, 2.0103140961459656],
					[58.392822264147632, 2.0103140867231932],
					[58.392822317594323, 2.0103140589320199],
					[58.392822342701265, 2.0103140402090922],
					[58.395747342704468, 2.0073334400901839],
					[58.395747355424028, 2.0073334255444806],
					[58.395747376126643, 2.0073333922462528],
					[58.395747385094239, 2.0073333750142588],
					[58.396491885021931, 2.0055028744105594],
					[58.396491891221828, 2.0055028560120802],
					[58.396491898179988, 2.005502818581721],
					[58.396491899736503, 2.0055027976745166],
					[58.396483501089257, 2.0044167639181318],
					[58.397425161884151, 2.0031223172188528],
					[58.39742516268241, 2.0031223153434197],
					[58.397425165263471, 2.0031223131130207],
					[58.39742516517029, 2.0031223114150736],
					[58.398177964875316, 2.0020140124061738],
					[58.398177976423213, 2.0020139929410918],
					[58.398177991403053, 2.0020139539106272],
					[58.398177996524623, 2.0020139322922277],
					[58.398450197389707, 2.000377832258843],
					[58.398450198613233, 2.0003778217157886],
					[58.39845019998242, 2.0003777974112844],
					[58.398450199329915, 2.0003777855253535],
					[58.398341901847623, 1.99893062064321],
					[58.398897392876272, 1.9969250527837152],
					[58.398897395561292, 1.9969250196335198],
					[58.398897392017304, 1.9969249551096333],
					[58.398897383912178, 1.9969249223932621],
					[58.397541901397837, 1.9937027631949278],
					[58.397616899516251, 1.991347205344473],
					[58.397616900314304, 1.9913472034687774],
					[58.397616899940957, 1.9913471966771101],
					[58.397616899754283, 1.9913471932812867],
					[58.397497400223934, 1.9880916924197207],
					[58.397497398398741, 1.988091675618626],
					[58.397497391276197, 1.9880916444257644],
					[58.397497386870221, 1.9880916298561571],
					[58.396816887174204, 1.9862999288073515],
					[58.396816878124291, 1.9862999117320705],
					[58.396816856365504, 1.9862998765952657],
					[58.396816842858669, 1.9862998604095119],
					[58.395955743430676, 1.9854192602156708],
					[58.395955740569704, 1.985419257353799],
					[58.395955736817335, 1.9854192546698657],
					[58.395955734847732, 1.9854192516300944],
					[58.395380823335657, 1.9848943337701916],
					[58.395658494478901, 1.9838000497461297],
					[58.395658496923232, 1.9838000286607658],
					[58.39565849923116, 1.9837999887216977],
					[58.395658497218555, 1.9837999685261456],
					[58.395455796810801, 1.982538868440421],
					[58.395455794186979, 1.9825388535163886],
					[58.395455786452089, 1.9825388275978679],
					[58.395455780262758, 1.9825388133858202],
					[58.394683500301809, 1.9809305532922254],
					[58.394658499852568, 1.9778527979716141],
					[58.394658496760663, 1.9778527745597028],
					[58.394658485135082, 1.9778527271067716],
					[58.394658475803574, 1.9778527049415784],
					[58.393886299614323, 1.9764277489369109],
					[58.393877999670451, 1.9753417573862562],
					[58.394816869196198, 1.9738668073050605],
					[58.394816870791793, 1.9738668035532232],
					[58.39481687576572, 1.9738667956931144],
					[58.394816878252684, 1.9738667917630501],
					[58.39547237803648, 1.9725806917056743],
					[58.395472382729594, 1.9725806787520084],
					[58.395472392209349, 1.9725806545424187],
					[58.39547239601108, 1.9725806417669973],
					[58.396022395818171, 1.9700278421851705],
					[58.396022397320031, 1.9700278367352582],
					[58.396022399245169, 1.970027822618247],
					[58.396022398870691, 1.9700278158272031],
					[58.396108497768523, 1.9689389372853607],
					[58.396850186866068, 1.9665639707699356],
					[58.397597357029852, 1.9649140375737861],
					[58.398827904644222, 1.9641529707907808],
					[58.398827943584749, 1.9641529252275038],
					[58.398827990129405, 1.9641528214926307],
					[58.398827996842172, 1.9641527634995153],
					[58.398527999614629, 1.9625305812104012],
					[58.398508499879441, 1.960177797938464],
					[58.398508496784267, 1.9601777745258007],
					[58.398508485152135, 1.9601777270741034],
					[58.398508475817607, 1.9601777049114959],
					[58.397736276441776, 1.9587527055395935],
					[58.397736258897467, 1.9587526815897081],
					[58.397736219727214, 1.9587526413741998],
					[58.397736197209987, 1.9587526252871761],
					[58.39649730600518, 1.9580637307007982],
					[58.395252940110637, 1.9571970532076886],
					[58.394580782716794, 1.9563110096784846],
					[58.393894592548719, 1.9537971463425481],
					[58.393894591563559, 1.9537971448236551],
					[58.393894591282084, 1.9537971397308516],
					[58.393894590296924, 1.9537971382119617],
					[58.393603004464765, 1.9528999827083915],
					[58.39387239325729, 1.950900066873041],
					[58.394527928618515, 1.9497945759191655],
					[58.395380626954683, 1.9495890997907195],
					[58.396522119911253, 1.9495558017385213],
					[58.397766558720484, 1.9507862426508118],
					[58.397766593201283, 1.9507862631992481],
					[58.397766666947561, 1.9507862930366222],
					[58.397766706119157, 1.9507863006277897],
					[58.398716706058408, 1.9507557995471687],
					[58.398716731109225, 1.9507557962381314],
					[58.398716777270074, 1.9507557835440066],
					[58.398716800162745, 1.9507557738013259],
					[58.400041800323017, 1.9499945721148513],
					[58.400041816788608, 1.9499945602273883],
					[58.40004185032948, 1.9499945311799851],
					[58.400041863839441, 1.9499945147354105],
					[58.401075164050333, 1.9485140138963881],
					[58.401075170617418, 1.9485140022777208],
					[58.401075182860232, 1.9485139792192845],
					[58.401075187644693, 1.9485139679583956],
					[58.401541887817217, 1.9472333688366725],
					[58.401541890209373, 1.9472333632060745],
					[58.401541893304994, 1.947233354000665],
					[58.401541893914526, 1.9472333487279017],
					[58.402091894028509, 1.9450417494483108],
					[58.402091896232655, 1.9450417404215532],
					[58.402091898061002, 1.9450417246029452],
					[58.402091899467813, 1.9450417174531243],
					[58.402352999222948, 1.9419556162654239],
					[58.402352999926258, 1.9419556126904232],
					[58.402353000253591, 1.9419556023235929],
					[58.40235299997159, 1.9419555972297142],
					[58.40233080012478, 1.9392416977160642],
					[58.40233079993672, 1.9392416943201687],
					[58.402330799372528, 1.939241684132452],
					[58.402330799184469, 1.939241680736554],
					[58.401916899289468, 1.935088884731367],
					[58.40180579996079, 1.9332805887236306],
					[58.401694602047172, 1.931472221192464],
					[58.40215578968499, 1.9298306646019878],
					[58.402808471238501, 1.9281807120915957],
					[58.403939037188998, 1.9270584460147848],
					[58.405827933258678, 1.9253723493214914],
					[58.405827946859453, 1.9253723345629066],
					[58.405827971104571, 1.9253723004900336],
					[58.405827981937307, 1.9253722845719061],
					[58.406761281744281, 1.9233500843912932],
					[58.406761284135335, 1.9233500787582909],
					[58.406761288120428, 1.923350069369935],
					[58.406761288728958, 1.9233500640960466],
					[58.407872383525607, 1.920058380429059],
					[58.408997374842066, 1.918030697092735],
					[58.408997382811414, 1.9180306783136445],
					[58.40899739438818, 1.9180306433522512],
					[58.408997398698212, 1.9180306235938358],
					[58.40944189932155, 1.9143944247790328],
					[58.409441899227154, 1.9143944230808225],
					[58.409794599067148, 1.9113056228982894],
					[58.409794598972738, 1.9113056212000841],
					[58.409794598878321, 1.9113056195018778],
					[58.409794598878321, 1.9113056195018778],
					[58.410144599162635, 1.907672219113248],
					[58.410144598920382, 1.9076721985547231],
					[58.410144593885242, 1.9076721566394166],
					[58.41014458909239, 1.90767213528262],
					[58.409658490130184, 1.9062360378182281],
					[58.408777989637571, 1.9035471385336642],
					[58.407702999742661, 1.9001388678998807],
					[58.407691900051148, 1.8986917224770945],
					[58.408241893800792, 1.89631945001278],
					[58.408980791564638, 1.8937611550716629],
					[58.408980793954335, 1.8937611494363791],
					[58.408980796060121, 1.8937611387067754],
					[58.4089807966674, 1.8937611334321316],
					[58.409522397370765, 1.8904833329339972],
					[58.409522397788564, 1.8904833242629933],
					[58.409522399704677, 1.8904833101368037],
					[58.409522400217142, 1.8904833031639314],
					[58.409583499533682, 1.8864972025969549],
					[58.409583500330129, 1.8864972007183221],
					[58.409583500140641, 1.8864971973221178],
					[58.409583500045898, 1.8864971956240182],
					[58.409444599812936, 1.8814305945794709],
					[58.409444599623328, 1.8814305911833193],
					[58.40944459914931, 1.8814305826929183],
					[58.409444598959695, 1.8814305792967476],
					[58.408925198831199, 1.8765555790115243],
					[58.408925188723543, 1.8765555432815568],
					[58.408925157302413, 1.8765554809636966],
					[58.408925134908024, 1.876555451160465],
					[58.407200166163527, 1.8749804807240236],
					[58.406327982890488, 1.8730166198635978],
					[58.406327979837336, 1.8730166136145068],
					[58.406327975703171, 1.8730166041504339],
					[58.40632797274494, 1.8730165995992423],
					[58.404783473954119, 1.8703499011609301],
					[58.403333490094106, 1.8676777306874675],
					[58.402839095774056, 1.8653416591254084],
					[58.402839079235335, 1.8653416212765619],
					[58.402839033265671, 1.8653415567824174],
					[58.402839002848552, 1.8653415286204218],
					[58.401116803130058, 1.8643081293026842],
					[58.401116786424168, 1.8643081206776408],
					[58.401116749637836, 1.8643081075468682],
					[58.401116731339698, 1.864308102679092],
					[58.399213930934472, 1.8640081020868744],
					[58.399213921832931, 1.8640081005019054],
					[58.399213902833672, 1.8640080992105501],
					[58.399213893827145, 1.8640080993231012],
					[58.395791694260588, 1.8641192004096823],
					[58.395791686145209, 1.8641192003411666],
					[58.3957916709006, 1.8641192017205184],
					[58.395791662880228, 1.8641192033493592],
					[58.392280568271282, 1.8647748035753708],
					[58.388958271966132, 1.8652442014800226],
					[58.388958253346885, 1.865244206976076],
					[58.388958216203363, 1.8652442196652328],
					[58.388958197774087, 1.8652442285553563],
					[58.387349899196821, 1.8661998267857598],
					[58.383661002151499, 1.8683081242898931],
					[58.381202703911342, 1.8696554242282428],
					[58.381202702223959, 1.8696554262863989],
					[58.381202696971897, 1.8696554290675116],
					[58.381202695189565, 1.869655429428996],
					[58.378744354323437, 1.8711803932281035],
					[58.376366744920489, 1.8708942037561538],
					[58.373505692949394, 1.8698998201156947],
					[58.370825138997631, 1.8679970530355261],
					[58.36871126296537, 1.8653498835598641],
					[58.367361269238231, 1.8632220923417124],
					[58.367361249990125, 1.8632220705179285],
					[58.367361206431937, 1.8632220330431686],
					[58.367361181230734, 1.8632220175729968],
					[58.36583618169886, 1.8625470174605734],
					[58.362494482529506, 1.8610276176216949],
					[58.362494474224071, 1.8610276141618867],
					[58.36249445672204, 1.8610276074230714],
					[58.362494447620499, 1.8610276058398134],
					[58.358588948009327, 1.860069205765631],
					[58.358588938907765, 1.860069204182742],
					[58.358588922581994, 1.8600692023508492],
					[58.358588915262722, 1.8600692004063113],
					[58.355163915310527, 1.859816501179189],
					[58.355163904426668, 1.8598164999580604],
					[58.355163881957871, 1.8598165010873609],
					[58.355163871169047, 1.8598165015616188],
					[58.350322170681537, 1.8605165025846571],
					[58.350322159096585, 1.8605165049344372],
					[58.350322135130519, 1.8605165115099096],
					[58.350322123640559, 1.8605165155548549],
					[58.347955524131891, 1.8614942150382146],
					[58.347955516206504, 1.861494218359667],
					[58.347955501341929, 1.8614942265169168],
					[58.3479554926204, 1.8614942317141339],
					[58.346441593346476, 1.8624470306127336],
					[58.346441580359112, 1.8624470401028241],
					[58.346441554669369, 1.8624470641679505],
					[58.346441542763138, 1.8624470768673296],
					[58.345124842625502, 1.8641165758693463],
					[58.345124835971092, 1.8641165857889914],
					[58.345124822757199, 1.8641166073232056],
					[58.345124817980057, 1.8641166185765234],
					[58.342980457631491, 1.8688859299087401],
					[58.340419353197468, 1.8695109067209166],
					[58.340419351320243, 1.869510905387108],
					[58.340419347850457, 1.8695109078036505],
					[58.340419345973203, 1.8695109064698452],
					[58.338238928969375, 1.8701219841752561],
					[58.336519502968308, 1.8690942284186545],
					[58.336519486357901, 1.8690942214993442],
					[58.336519453137029, 1.8690942076607404],
					[58.336519434839161, 1.8690942027968462],
					[58.333950035316604, 1.8686331024049567],
					[58.333950008296824, 1.8686331027346967],
					[58.333949952759617, 1.8686331088382917],
					[58.333949926024566, 1.8686331142512667],
					[58.331011026131819, 1.8698109137308581],
					[58.331011019988487, 1.8698109166878054],
					[58.331011007701839, 1.8698109226016946],
					[58.331011001558508, 1.8698109255586628],
					[58.329311076861913, 1.8707691830856539],
					[58.327213990903893, 1.8701137191489181],
					[58.325777923686758, 1.8690748437234217],
					[58.324150129910819, 1.8676804486468042],
					[58.324150098187559, 1.8676804293709102],
					[58.324150030153255, 1.8676804054529577],
					[58.324149992856128, 1.8676803992973527],
					[58.322913892666982, 1.8677220005833088],
					[58.322913838685253, 1.8677220183618644],
					[58.322913749457115, 1.8677220826755123],
					[58.322913714115757, 1.8677221275167215],
					[58.3222637136016, 1.8693693265849007],
					[58.322263711402464, 1.8693693355951673],
					[58.322263706113027, 1.869369353796033],
					[58.32226370391389, 1.8693693628063002],
					[58.321808104153661, 1.8717305622222233],
					[58.321808101954353, 1.8717305712322361],
					[58.321808101120453, 1.8717305885311721],
					[58.321808099717472, 1.8717305956670789],
					[58.321744200343787, 1.8751666856603744],
					[58.321474807320115, 1.8771610329548158],
					[58.320819227698074, 1.8782665981803632],
					[58.320819225214038, 1.8782666021082832],
					[58.320819221231858, 1.8782666114778663],
					[58.320819217856616, 1.8782666155859042],
					[58.319888718261467, 1.8802832155202798],
					[58.319888712173963, 1.8802832355921846],
					[58.319888702767194, 1.8802832768896716],
					[58.319888700244455, 1.8802832962414169],
					[58.319822002213357, 1.8837193195473227],
					[58.318785952882443, 1.8848359636927534],
					[58.318785929404264, 1.8848360112539935],
					[58.318785906983706, 1.8848361099874293],
				],
			],
		},
		{
			fldNpdidField: "43732",
			wlbNpdidWellbore: "878",
			fldName: "VIGDIS",
			fldCurrentActivitySatus: "Producing",
			wlbName: "34/7-8",
			fldDiscoveryYear: "1986",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43732",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43732",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "089",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=878",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "21516",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Florø",
			fldAreaGeometryWKT: [
				[
					[
						[61.338977619153646, 2.1166499143283475],
						[61.338977621997095, 2.1166500056307478],
						[61.338977698552185, 2.1166501463427649],
						[61.338977773249496, 2.1166501974111598],
						[61.340574972884177, 2.1168695983593548],
						[61.340574980999691, 2.1168695984459549],
						[61.340574997325128, 2.1168696004744993],
						[61.340575005440627, 2.1168696005610825],
						[61.342175005335555, 2.1168280003518802],
						[61.343527746618179, 2.1167946007866165],
						[61.344760984729071, 2.1175390640799416],
						[61.345377673655513, 2.1180418551768381],
						[61.345377704293391, 2.11804187281483],
						[61.345377770350204, 2.1180418957775293],
						[61.345377804783503, 2.1180418994430905],
						[61.346608151435937, 2.1180113043722879],
						[61.346980408196515, 2.1192917562248348],
						[61.346980426126308, 2.119291789803075],
						[61.346980474274538, 2.1192918504967162],
						[61.346980504398537, 2.1192918757563937],
						[61.348336003610932, 2.1200362742618926],
						[61.348336021205021, 2.1200362835192816],
						[61.348336055124534, 2.1200362948071292],
						[61.348336073326756, 2.1200362983004206],
						[61.349933272984558, 2.1202529977347009],
						[61.349933281194446, 2.1202529996779984],
						[61.349933297425473, 2.120252999852656],
						[61.349933304649774, 2.1202530001364939],
						[61.351652754749296, 2.1202113008800048],
						[61.352641611035821, 2.120702978752643],
						[61.35264167366369, 2.1207029874659611],
						[61.352641792387232, 2.1207029650406373],
						[61.352641846794803, 2.12070293615117],
						[61.35361951280737, 2.1196445721530597],
						[61.355213965578685, 2.1190890892217906],
						[61.355213991938193, 2.119089075899045],
						[61.355214038743512, 2.1190890392955186],
						[61.355214061160545, 2.1190890193340688],
						[61.356194547984138, 2.1177695370542513],
						[61.357419530919607, 2.1167057503860658],
						[61.357419538657425, 2.1167057430468246],
						[61.357419554038643, 2.1167057265119742],
						[61.357419560885205, 2.1167057193694294],
						[61.358394541572444, 2.1153890442079155],
						[61.359497318199772, 2.1145862617103028],
						[61.359497336254378, 2.1145862445830024],
						[61.35949736762408, 2.1145862057426634],
						[61.359497380939175, 2.1145861840296267],
						[61.360108481372819, 2.1132750841609624],
						[61.360108485451072, 2.1132750757508596],
						[61.360108491636183, 2.1132750556111848],
						[61.360108494728706, 2.1132750455413563],
						[61.360347381206488, 2.1122362046497836],
						[61.361202933700866, 2.1114390484835246],
						[61.362430645214488, 2.1103724251195501],
						[61.363166638165787, 2.1106140895963099],
						[61.363166715377659, 2.1106140838034917],
						[61.363166840931747, 2.1106140185133913],
						[61.363166891150897, 2.1106139604790326],
						[61.363168674003539, 2.1106082455307429],
						[61.363716600666784, 2.1108251759484333],
						[61.36568312243633, 2.1123279163487214],
						[61.366935906259876, 2.1174694465009321],
						[61.366935908608845, 2.1174694572491797],
						[61.366935915980513, 2.1174694781554693],
						[61.366935920017532, 2.1174694866533059],
						[61.367927620259316, 2.1195111874721109],
						[61.367927646304913, 2.1195112211590974],
						[61.367927709321407, 2.1195112729770744],
						[61.367927747277932, 2.1195112927684159],
						[61.368424476066799, 2.1196484070309252],
						[61.367880455270893, 2.1205831512700315],
						[61.366413802604924, 2.121394225350083],
						[61.364447102957122, 2.1224776256486542],
						[61.364447095030293, 2.1224776292746137],
						[61.364447079365362, 2.1224776402401266],
						[61.36444707073597, 2.1224776477762992],
						[61.362244275593824, 2.1243442422625916],
						[61.360774882016457, 2.1254137377390294],
						[61.360774871698702, 2.1254137475229737],
						[61.360774851954488, 2.125413766894368],
						[61.360774843419229, 2.1254137762853977],
						[61.358697043345018, 2.1280498755944199],
						[61.358697030121952, 2.1280498991577788],
						[61.358697009117542, 2.1280499469628471],
						[61.358697002321755, 2.1280499728646713],
						[61.358219208586469, 2.1314249196044202],
						[61.357608174295166, 2.1322137363266394],
						[61.355644323327617, 2.133038715852742],
						[61.355644301422871, 2.133038728183033],
						[61.355644260569733, 2.1330387578240519],
						[61.355644242606822, 2.1330387767949155],
						[61.35356374337352, 2.1356748758129989],
						[61.353563736620004, 2.1356748848067655],
						[61.353563726583928, 2.1356749001536266],
						[61.353563721612964, 2.1356749087551821],
						[61.352224827872455, 2.1382915970730596],
						[61.350758136149658, 2.1403942864149306],
						[61.350758130287076, 2.1403942952109034],
						[61.350758118656032, 2.1403943146589075],
						[61.350758113778852, 2.1403943251150066],
						[61.3500276139467, 2.1422221253127778],
						[61.3500276131495, 2.1422221273647071],
						[61.35002761155507, 2.142222131468591],
						[61.350027610757849, 2.1422221335205274],
						[61.349299816026999, 2.1443055215155726],
						[61.348080427282042, 2.1466609989303338],
						[61.346369232171398, 2.1492859916480884],
						[61.346369226402182, 2.149286002296996],
						[61.34636921495769, 2.1492860254506621],
						[61.346369210173741, 2.1492860377597616],
						[61.345519209861578, 2.151888837606347],
						[61.345519206859976, 2.1518888495235604],
						[61.345519201654106, 2.1518888713066038],
						[61.34551920132639, 2.1518888826371048],
						[61.34540260097878, 2.1531832810984572],
						[61.345402639731716, 2.1531833701703156],
						[61.345402773761677, 2.1531834721150607],
						[61.345402870023939, 2.153183486648218],
						[61.346877837578027, 2.1526334985493718],
						[61.347369405184565, 2.1526224008614632],
						[61.34835000493247, 2.1525974001843227],
						[61.348350046729614, 2.1525973891360151],
						[61.348350122442135, 2.1525973537561369],
						[61.348350155278332, 2.1525973259081859],
						[61.349208397343361, 2.1515445977881322],
						[61.350686104672384, 2.1515113002618267],
						[61.35216110466822, 2.1514751997523733],
						[61.354002804858744, 2.1514334991349671],
						[61.35548058229837, 2.1514002004984274],
						[61.357697158798395, 2.151864096455935],
						[61.357697169681828, 2.1518640978208712],
						[61.35769719323131, 2.151864100159405],
						[61.3576972049121, 2.1518640994721276],
						[61.358802805297714, 2.1518391007112339],
						[61.358802856711101, 2.1518390837915309],
						[61.358802944853203, 2.1518390268949967],
						[61.358802981394049, 2.1518389832049656],
						[61.35989738122894, 2.1494861833963528],
						[61.360386229181415, 2.148439097808561],
						[61.361491704680049, 2.1484140996710752],
						[61.362847091366724, 2.148383503190999],
						[61.363960929567988, 2.1501668056173679],
						[61.363960941109255, 2.1501668199801705],
						[61.363960964003816, 2.1501668449919662],
						[61.363960977233688, 2.1501668571063006],
						[61.365560977133597, 2.1514223564402064],
						[61.36556099679035, 2.15142237089939],
						[61.365561040090554, 2.1514223895539772],
						[61.365561063030597, 2.1514223976591356],
						[61.366794280132893, 2.1516529817260972],
						[61.368155434147425, 2.1536918107604826],
						[61.368155470974443, 2.1536918439853956],
						[61.36815555616743, 2.1536918891236296],
						[61.368155605424718, 2.1536919008412201],
						[61.368647066166801, 2.1536808027422771],
						[61.369269214719864, 2.1552167742421799],
						[61.369269220818921, 2.1552167879260291],
						[61.369269235690943, 2.1552168147066606],
						[61.369269245261336, 2.1552168257505619],
						[61.3705054043266, 2.1567417762415877],
						[61.370644200385698, 2.1593250058598925],
						[61.370649799903568, 2.1613972004472739],
						[61.370649799934817, 2.1616528007910425],
						[61.370649824891508, 2.1616528666780011],
						[61.370649911911684, 2.1616529658997639],
						[61.370649973881356, 2.1616529973772121],
						[61.372374897810111, 2.1618751896430703],
						[61.373241548426797, 2.1628890305733939],
						[61.373241583093957, 2.1628890567752905],
						[61.373241661388526, 2.1628890903111722],
						[61.373241705109713, 2.1628890995026664],
						[61.37508890530075, 2.1628474000470823],
						[61.375088926786106, 2.1628473972125359],
						[61.375088972149392, 2.1628473853840937],
						[61.375088994244628, 2.1628473767812166],
						[61.378155677994087, 2.1612251858431963],
						[61.379013961934405, 2.1609473893031566],
						[61.379013990080999, 2.1609473756128668],
						[61.379014042245949, 2.1609473378675901],
						[61.379014063590375, 2.1609473143994014],
						[61.379744564226158, 2.159894513130681],
						[61.38121125413327, 2.1577890296214157],
						[61.382555739623967, 2.1564640421021357],
						[61.382555743095288, 2.1564640394611114],
						[61.38255574914659, 2.1564640343748183],
						[61.382555751726578, 2.1564640319295507],
						[61.384147341623041, 2.1546168425812828],
						[61.385372330590855, 2.1535529502382555],
						[61.385372342599268, 2.1535529382053658],
						[61.385372363050948, 2.1535529149229959],
						[61.385372373182854, 2.1535529014236263],
						[61.385983417399004, 2.1525029965763136],
						[61.386966704645602, 2.1524779999904577],
						[61.388563905244212, 2.1524418992206145],
						[61.38856397570207, 2.1524418668223229],
						[61.388564073972582, 2.1524417606657491],
						[61.388564100002647, 2.152441687299294],
						[61.388489305774584, 2.1513308652708312],
						[61.390135900223619, 2.1548639457427319],
						[61.390152600295878, 2.1565028014327252],
						[61.390152599686324, 2.1565028072039643],
						[61.390152601234959, 2.156502820017538],
						[61.390152602407987, 2.1565028253971605],
						[61.390369202096089, 2.1581333253285688],
						[61.39036921781279, 2.1581333688546147],
						[61.390369266602839, 2.1581334426988925],
						[61.390369300661391, 2.1581334746798708],
						[61.392380465751806, 2.1592862532039434],
						[61.394813725787102, 2.1624695012392094],
						[61.395838715758252, 2.1648889772541673],
						[61.395838722747861, 2.1648889907556499],
						[61.395838739400979, 2.1648890171717952],
						[61.395838747188108, 2.1648890286188154],
						[61.3968498470065, 2.1660807298216591],
						[61.39684986300297, 2.1660807432268432],
						[61.3968498958872, 2.1660807698416651],
						[61.396849915355119, 2.1660807806055815],
						[61.397655515238498, 2.1664612812234481],
						[61.39765560690325, 2.1664612761453301],
						[61.397655748764898, 2.1664611942682352],
						[61.3976557989618, 2.1664611174692521],
						[61.398189092806255, 2.1602889918620858],
						[61.399972248326186, 2.1585808306207173],
						[61.400974801025932, 2.1589501407174536],
						[61.400999799577249, 2.1614111012389516],
						[61.400999802579939, 2.1614111250163028],
						[61.400999813839256, 2.1614111695373528],
						[61.400999822095919, 2.1614111902810249],
						[61.401816512270599, 2.1630194704039463],
						[61.402233106158754, 2.1646444493840833],
						[61.402233163671369, 2.1646445175904598],
						[61.402233309189441, 2.1646445796352514],
						[61.402233397288732, 2.1646445753328956],
						[61.403825081042484, 2.1637612836660094],
						[61.405219435981614, 2.1633001992605876],
						[61.406616708005224, 2.1632446000291856],
						[61.40661675594837, 2.1632445857396747],
						[61.406616838934042, 2.1632445336725969],
						[61.406616874070394, 2.1632444977545147],
						[61.407794431457511, 2.161147554175642],
						[61.409416542914464, 2.1631362247339445],
						[61.410022041204932, 2.1639334204680587],
						[61.41002204514546, 2.1639334271237933],
						[61.410022054715327, 2.1639334381840372],
						[61.410022060344673, 2.1639334425885637],
						[61.411235936633055, 2.1651195195878019],
						[61.412666521754581, 2.1679389897387811],
						[61.412666559845427, 2.1679390302685846],
						[61.412666652821983, 2.1679390869563715],
						[61.412666707520181, 2.1679390993946139],
						[61.414463907807836, 2.1678696003371294],
						[61.414463939003994, 2.1678695934882319],
						[61.41446399825351, 2.1678695710776292],
						[61.414464026306881, 2.1678695555159195],
						[61.41605012564888, 2.1665779551001441],
						[61.416050157643063, 2.1665779104611107],
						[61.416050192265168, 2.1665778107059599],
						[61.416050194986859, 2.1665777574499043],
						[61.415419601126281, 2.1637277825017156],
						[61.415583499311687, 2.1604361195152246],
						[61.415958495334642, 2.1579583475998105],
						[61.416927989956008, 2.1550444634628403],
						[61.416927993660885, 2.1550444476019699],
						[61.416927999194108, 2.1550444144122816],
						[61.416928000131136, 2.1550443972795397],
						[61.416889099696405, 2.1517610977753825],
						[61.41688909777114, 2.1517610775109808],
						[61.416889089652202, 2.1517610416830748],
						[61.416889084161802, 2.1517610222033161],
						[61.415866895304312, 2.1493388477503044],
						[61.415425199339019, 2.1456582763599945],
						[61.415425198259577, 2.1456582728364291],
						[61.415425196897935, 2.1456582637330439],
						[61.415425195818479, 2.1456582602094967],
						[61.415005799925218, 2.1436221785172132],
						[61.414977999860305, 2.1411610989274177],
						[61.414950200340911, 2.1386971975728351],
						[61.414950197428318, 2.1386971756472528],
						[61.414950186161363, 2.1386971311152938],
						[61.414950177901162, 2.138697110368823],
						[61.414136285711493, 2.1370888252700082],
						[61.413514090772786, 2.1350610414099092],
						[61.413514068012546, 2.1350610012970979],
						[61.413514007108972, 2.1350609376289547],
						[61.413513968871428, 2.1350609122138189],
						[61.410502868766955, 2.133952611243509],
						[61.410502790568032, 2.1339526153320088],
						[61.410502662140793, 2.1339526775109783],
						[61.410502610224235, 2.1339527378542966],
						[61.40953311005039, 2.1368638368238684],
						[61.409533110144601, 2.1368638386834196],
						[61.4095331093475, 2.1368638407395033],
						[61.4095331093475, 2.1368638407395033],
						[61.408763763249226, 2.1393580652169795],
						[61.407158342042869, 2.1390137042403183],
						[61.407158329376813, 2.139013703271706],
						[61.407158303950567, 2.1390136994750675],
						[61.407158292269955, 2.1390137001694445],
						[61.406558420189491, 2.1390386955980567],
						[61.405127989643077, 2.1362249333058378],
						[61.404708495931452, 2.1341888601779324],
						[61.404708491706842, 2.1341888479493805],
						[61.404708483163432, 2.1341888216329972],
						[61.40470847804756, 2.1341888096010098],
						[61.404094578213289, 2.1329832081173628],
						[61.404094576242287, 2.1329832047921209],
						[61.404094571314808, 2.1329831964789969],
						[61.404094569343819, 2.1329831931537493],
						[61.403080768688199, 2.1313859930486125],
						[61.403080670821993, 2.1313859469619403],
						[61.403080490926122, 2.1313859716113641],
						[61.403080408802204, 2.1313860404882865],
						[61.402305418339452, 2.1338777114113512],
						[61.401713805726573, 2.1347219858901654],
						[61.399313925571811, 2.1344109011930876],
						[61.39931387903794, 2.1344109076938929],
						[61.399313793151144, 2.1344109379092919],
						[61.399313753001209, 2.1344109636793474],
						[61.398127738412072, 2.1356886734924068],
						[61.395722352100279, 2.1349665373904827],
						[61.395102987072008, 2.1333527280741271],
						[61.395102969333266, 2.133352698154968],
						[61.395102925320124, 2.1333526477164639],
						[61.395102898951521, 2.1333526253384498],
						[61.393094531155107, 2.1322054444936702],
						[61.390461254037938, 2.1290359727463457],
						[61.390461245451029, 2.1290359633653315],
						[61.390461227480131, 2.1290359466583104],
						[61.39046121711069, 2.1290359376704906],
						[61.390134000230645, 2.1288005250446771],
						[61.392024955286303, 2.1287530010573756],
						[61.393994286859659, 2.1297418665023184],
						[61.395722067653011, 2.131252951551839],
						[61.395722100072248, 2.1312529688350352],
						[61.395722168004369, 2.1312529933215494],
						[61.395722205299791, 2.1312530001317458],
						[61.398911104719879, 2.1311779992684552],
						[61.401855605163917, 2.1311057993426896],
						[61.403575004721134, 2.13106410062945],
						[61.403575056225179, 2.1310640855057992],
						[61.403575141776813, 2.1310640309103737],
						[61.403575177606854, 2.1310639910452989],
						[61.406005761982023, 2.1263390225584309],
						[61.407713980679645, 2.1247418986139115],
						[61.40918610466678, 2.124705800144767],
						[61.410661104967161, 2.1246695998856731],
						[61.410661147652263, 2.1246695885756903],
						[61.410661224057748, 2.1246695491319496],
						[61.410661257872441, 2.1246695228577503],
						[61.411880758105077, 2.1230862218343294],
						[61.411880772217899, 2.1230861980303137],
						[61.411880792139193, 2.1230861466158157],
						[61.411880798933339, 2.1230861206680331],
						[61.412347398821304, 2.1184056199646539],
						[61.412347398537953, 2.1184056143856922],
						[61.412347399848166, 2.1184056046932871],
						[61.41234739956483, 2.1184055991143413],
						[61.412330800264975, 2.1152971985546971],
						[61.412330798806828, 2.1152971875940332],
						[61.412330796876205, 2.1152971673351804],
						[61.412330795418036, 2.1152971563745204],
						[61.411075195322503, 2.1096249576618531],
						[61.411075191986889, 2.1096249452370226],
						[61.411075184613601, 2.1096249243037257],
						[61.411075180386824, 2.1096249120761996],
						[61.410580796372599, 2.108599945067414],
						[61.410319625025558, 2.1054946835720587],
						[61.411791637707807, 2.1059751907795294],
						[61.411791673032432, 2.105975194237383],
						[61.411791742317433, 2.1059751920526204],
						[61.411791776183193, 2.1059751845504606],
						[61.414486141690595, 2.1048695990651853],
						[61.414977804559122, 2.1048584993488433],
						[61.416941705089798, 2.1048057995157241],
						[61.416941739050081, 2.1048057938705598],
						[61.416941801757517, 2.1048057686887791],
						[61.416941832381731, 2.1048057506170297],
						[61.41816403170747, 2.1037390505272557],
						[61.418164061802912, 2.1037390043570441],
						[61.418164091732386, 2.1037389017960191],
						[61.418164093443423, 2.1037388468700762],
						[61.41716409323962, 2.1001332464685811],
						[61.417164069483455, 2.1001332047151897],
						[61.417164002933163, 2.1001331367155927],
						[61.41716396192141, 2.100133110074101],
						[61.415689056075671, 2.0996526409324412],
						[61.414439088747336, 2.0960555343922609],
						[61.414439085694795, 2.0960555275466879],
						[61.414439080386067, 2.0960555117981601],
						[61.414439076442321, 2.0960555051503214],
						[61.411964076051504, 2.0914527048280962],
						[61.411964069149583, 2.0914526931963238],
						[61.411964051780998, 2.0914526707241303],
						[61.411964043191503, 2.091452661347565],
						[61.411472406354513, 2.0909471246429163],
						[61.411700181075481, 2.0878308630704852],
						[61.414894342639677, 2.0887834924016175],
						[61.414894358074143, 2.0887834946156771],
						[61.414894389834245, 2.0887834988458716],
						[61.414894405173847, 2.0887834992002685],
						[61.418330605059978, 2.0886918996176758],
						[61.418330616740029, 2.0886918989039356],
						[61.418330639114124, 2.088691895814542],
						[61.418330649808183, 2.0886918934388805],
						[61.420536150334058, 2.0881168939815442],
						[61.420536192446129, 2.0881168714571006],
						[61.4205362613679, 2.0881168091161575],
						[61.420536288177694, 2.088116769299619],
						[61.421500188148535, 2.08549726844165],
						[61.421500191845126, 2.0854972525706823],
						[61.421500198157318, 2.0854972173066955],
						[61.421500199881741, 2.0854971981117689],
						[61.421494599790819, 2.0834221986712285],
						[61.42149460039716, 2.0834221928929022],
						[61.421494598746541, 2.0834221782105926],
						[61.421494598461742, 2.0834221726304096],
						[61.420983497848141, 2.0798027715691769],
						[61.420983495400947, 2.0798027589455841],
						[61.420983487833162, 2.0798027342931125],
						[61.420983483603649, 2.0798027220660185],
						[61.41924188431026, 2.0756999211783493],
						[61.419241883324062, 2.0756999195168091],
						[61.419241881256674, 2.0756999143338204],
						[61.419241880270476, 2.075699912672281],
						[61.418255786624826, 2.0736527263274227],
						[61.41650849633136, 2.0679943568512513],
						[61.415773443703102, 2.0630255605106802],
						[61.415777621963528, 2.0630333918843036],
						[61.415797020938172, 2.0630722890329749],
						[61.415797021924512, 2.0630722906939289],
						[61.415797022910809, 2.0630722923548879],
						[61.415797023006029, 2.0630722942145399],
						[61.415819218327357, 2.06311398360423],
						[61.415835914029934, 2.0631556747647468],
						[61.415835916002585, 2.063155678086682],
						[61.415835919151995, 2.0631556867888512],
						[61.415835921124639, 2.0631556901107722],
						[61.415855421410647, 2.0631944895785739],
						[61.415855422396966, 2.0631944912395537],
						[61.415855423383306, 2.0631944929005122],
						[61.415855423478504, 2.0631944947601526],
						[61.415877621818929, 2.0632361911572938],
						[61.415897020739067, 2.0632750885567801],
						[61.415897021725407, 2.0632750902177435],
						[61.415897022806924, 2.0632750937383535],
						[61.415897022806924, 2.0632750937383535],
						[61.415919221029036, 2.0633167883886294],
						[61.415938718285581, 2.0633583818232371],
						[61.41595539966108, 2.0633972419762308],
						[61.415955399787499, 2.0634388995493906],
						[61.415955402807349, 2.0634389233274852],
						[61.415955415180122, 2.0634389713526349],
						[61.415955422655607, 2.0634389941373854],
						[61.415977621911736, 2.0634806925037421],
						[61.415997020971837, 2.0635194885675241],
						[61.415997021958148, 2.063519490228507],
						[61.415997023039679, 2.0635194937491494],
						[61.415997023039679, 2.0635194937491494],
						[61.416019221191185, 2.0635611887075727],
						[61.41603871915737, 2.0636028857037472],
						[61.416038762909807, 2.0636029304950219],
						[61.416038870373676, 2.063602987414733],
						[61.416038933098733, 2.0636029978821915],
						[61.416055617015225, 2.0636001999849714],
						[61.416077799616147, 2.0636001994048589],
						[61.416097199999456, 2.0636002002850677],
						[61.416113783826475, 2.0636002008770373],
						[61.416135925578594, 2.0636389988857249],
						[61.416135962617183, 2.0636390357695467],
						[61.416136049905084, 2.063639085902266],
						[61.416136100154453, 2.0636390991511377],
						[61.416155599704041, 2.063639100515696],
						[61.416177800318131, 2.0636390997046097],
						[61.416194400376028, 2.0636391004577739],
						[61.416194406709089, 2.0636391009267587],
						[61.416194421062279, 2.0636390996077267],
						[61.41619442819124, 2.0636390980183896],
						[61.416213788983136, 2.0636363183857909],
						[61.416235923313224, 2.0636778943880816],
						[61.416235960446976, 2.0636779331317308],
						[61.416236048816437, 2.0636779867853572],
						[61.416236099956905, 2.0636779998356771],
						[61.416252800072115, 2.0636780008714819],
						[61.416252806309998, 2.0636779994808068],
						[61.416252818880899, 2.063677998559132],
						[61.416252825118754, 2.0636779971684609],
						[61.416275013291077, 2.063675200385656],
						[61.416294400117671, 2.0636752005652363],
						[61.416310986618541, 2.063675200594242],
						[61.416335928039466, 2.0637168036065603],
						[61.416335972397427, 2.0637168426207166],
						[61.416336074834405, 2.0637168893765865],
						[61.416336132913472, 2.0637168971183093],
						[61.416352690898634, 2.063714121677898],
						[61.416374821167871, 2.063755689412186],
						[61.416394217259388, 2.0637972817943875],
						[61.416410900207985, 2.0638361404856602],
						[61.416410900303987, 2.0638778005362348],
						[61.416410900399192, 2.0638778023959259],
						[61.416410899888838, 2.0638778100332908],
						[61.416410900079235, 2.0638778137526583],
						[61.41641370011564, 2.0639194138694719],
						[61.416413703931362, 2.0639194355896411],
						[61.416413716909538, 2.063919477837977],
						[61.416413726071966, 2.0639194983661659],
						[61.416435900385252, 2.0639583536405355],
						[61.416435899777895, 2.0640000007092238],
						[61.416435901620936, 2.0640000191073762],
						[61.416435908871584, 2.0640000551090245],
						[61.416435914374311, 2.064000074572212],
						[61.416452603239499, 2.0640417440448608],
						[61.416455400431715, 2.0640833072401499],
						[61.416455400331337, 2.0641222005740487],
						[61.416455403351087, 2.0641222243525945],
						[61.416455414737321, 2.0641222707177671],
						[61.416455423103834, 2.0641222933043863],
						[61.416477600182354, 2.0641639506954035],
						[61.416477599607923, 2.0642055996836222],
						[61.416477600110248, 2.0642471995262857],
						[61.41647760312997, 2.0642472233048612],
						[61.416477612638744, 2.0642472682077329],
						[61.41647762091003, 2.0642472889347094],
						[61.41649700034467, 2.0642861468490663],
						[61.41649699992351, 2.0643277995850267],
						[61.416497000388752, 2.064369399456039],
						[61.41649699964838, 2.0644110992817573],
						[61.416497001586585, 2.064411119539646],
						[61.416497010013799, 2.0644111609218707],
						[61.416497016312412, 2.0644111783268393],
						[61.416513703227636, 2.0644500478463894],
						[61.416516499804381, 2.0644917072035622],
						[61.416516499839076, 2.0645332996651242],
						[61.416516499939846, 2.0645749993215805],
						[61.416516500123009, 2.0646167008385508],
						[61.416516500156419, 2.0646555998377547],
						[61.416516503176062, 2.0646556236164071],
						[61.416516514657346, 2.0646556718415523],
						[61.416516523914872, 2.0646556942297245],
						[61.416538700152202, 2.0646972506734751],
						[61.416538700306432, 2.0647388995554024],
						[61.416538700344482, 2.0647805992456911],
						[61.416538699837496, 2.0648194005786085],
						[61.416538701870799, 2.0648194226962655],
						[61.416538712080147, 2.0648194636813852],
						[61.41653871946022, 2.0648194846071983],
						[61.41655809966614, 2.0648611434261332],
						[61.416558100151228, 2.0649027997760179],
						[61.416558103266006, 2.0649028254144333],
						[61.416558116624628, 2.0649028751021588],
						[61.416558125977303, 2.0649028993501251],
						[61.416580421777908, 2.0649417923272515],
						[61.416599800087468, 2.0649833444816807],
						[61.416599799549537, 2.0650249992287097],
						[61.416599801582812, 2.0650250213464063],
						[61.416599811887288, 2.0650250641913863],
						[61.416599819172134, 2.0650250832576016],
						[61.416619218628945, 2.0650667850061146],
						[61.416619220601554, 2.0650667883282359],
						[61.416619223655623, 2.0650667951711354],
						[61.416619225628232, 2.0650667984932598],
						[61.416641519316954, 2.0651056864823003],
						[61.41665809992471, 2.0651472379450286],
						[61.416658099718369, 2.0651889002120698],
						[61.416658103819422, 2.0651889275116613],
						[61.416658120136823, 2.0651889821828937],
						[61.416658132258, 2.0651890076948258],
						[61.416683120552051, 2.0652278914512876],
						[61.416699813588011, 2.0652694743955888],
						[61.416699815560598, 2.0652694777177363],
						[61.416699818709887, 2.0652694864203607],
						[61.416699820682489, 2.0652694897425041],
						[61.416719220835823, 2.0653083890415127],
						[61.416719221822135, 2.0653083907025827],
						[61.416719222808425, 2.0653083923636619],
						[61.416719223794743, 2.0653083940247332],
						[61.416741518527786, 2.0653500865121415],
						[61.416758102870794, 2.0653889476778717],
						[61.416760900342091, 2.0654306128439264],
						[61.416760902980904, 2.0654306291840356],
						[61.416760909945673, 2.0654306596072893],
						[61.416760914366733, 2.0654306755501586],
						[61.416777613546984, 2.0654722742538532],
						[61.416777616601067, 2.0654722810968198],
						[61.416777622614049, 2.0654722929230553],
						[61.416777625668146, 2.0654722997660029],
						[61.416799822054379, 2.0655111910968746],
						[61.416819219112085, 2.0655528845160136],
						[61.416819220098368, 2.0655528861771049],
						[61.416819222166133, 2.0655528913590029],
						[61.416819224138742, 2.0655528946811783],
						[61.416841522062754, 2.0655944927044572],
						[61.416860917101829, 2.0656333822178219],
						[61.416877613757912, 2.0656750738790954],
						[61.416877615825676, 2.0656750790610117],
						[61.416877620852354, 2.0656750892262075],
						[61.416877622920119, 2.0656750944081073],
						[61.4168998002474, 2.065716749562152],
						[61.416899800171677, 2.0657556001120061],
						[61.416899802204831, 2.0657556222299736],
						[61.416899811522796, 2.0657556634144911],
						[61.416899818902742, 2.0657556843407718],
						[61.416919219246324, 2.065797285133153],
						[61.4169192202326, 2.0657972867942571],
						[61.416919222300365, 2.0657972919761693],
						[61.416919224177825, 2.0657972934386679],
						[61.416941521728646, 2.065838991527249],
						[61.416960916701747, 2.0658778813181242],
						[61.416977613684615, 2.0659194753485051],
						[61.4169776166435, 2.0659194803318268],
						[61.416977622656439, 2.0659194921582213],
						[61.416977625710473, 2.0659194990012675],
						[61.416999822072093, 2.0659583927591076],
						[61.417019218905267, 2.0660000848653381],
						[61.417019219891543, 2.0660000865264552],
						[61.417019221959286, 2.0660000917084176],
						[61.417019223836718, 2.0660000931709224],
						[61.417041522220465, 2.0660417913392233],
						[61.417060921179456, 2.0660806898850752],
						[61.417060958407774, 2.066080730492327],
						[61.417061046776354, 2.0660807841541087],
						[61.417061099889267, 2.0660808005308562],
						[61.417077679980586, 2.0660807994845207],
						[61.417099821797585, 2.0661222919164564],
						[61.417119220640167, 2.0661611887245894],
						[61.417119221626457, 2.0661611903857131],
						[61.417119222707925, 2.0661611939065789],
						[61.41711922359903, 2.0661611937079791],
						[61.417141523814358, 2.0662028935864658],
						[61.417141567470829, 2.0662029365232089],
						[61.417141669206316, 2.0662029872064314],
						[61.417141729257892, 2.0662029982751475],
						[61.417160972945226, 2.0662002198913449],
						[61.417177613703515, 2.066241774204542],
						[61.417177616662357, 2.0662417791879339],
						[61.417177620797879, 2.0662417895519161],
						[61.417177623756736, 2.0662417945353257],
						[61.417199822222607, 2.0662833911966092],
						[61.417219221022293, 2.0663222882288395],
						[61.417219258345682, 2.0663223306962992],
						[61.417219346714205, 2.0663223843590264],
						[61.417219399827097, 2.0663224007362535],
						[61.417241572463283, 2.0663224005050647],
						[61.417260916668425, 2.0663639826029887],
						[61.41727761606586, 2.0664028794453353],
						[61.41727761803844, 2.0664028827676155],
						[61.417277621983629, 2.06640288941218],
						[61.417277624051373, 2.0664028945941948],
						[61.417299824444036, 2.0664444948246232],
						[61.417299859794817, 2.0664445339700723],
						[61.41729994895929, 2.0664445855749127],
						[61.417300000194736, 2.0664446004898442],
						[61.417319284220618, 2.0664446008142554],
						[61.417341525969206, 2.066483398696914],
						[61.417341563007099, 2.0664834355854977],
						[61.417341650389275, 2.0664834875877132],
						[61.417341699652127, 2.0664834991804359],
						[61.417358172560228, 2.0664834996546717],
						[61.417377618749526, 2.0665250856018327],
						[61.417377655977688, 2.0665251262100202],
						[61.417377746318742, 2.0665251831958624],
						[61.417377800322726, 2.0665251993749125],
						[61.417399875672317, 2.0665252002717898],
						[61.417419217143333, 2.066563982557629],
						[61.417435914327747, 2.0666056734053568],
						[61.417436002280837, 2.066605736566558],
						[61.417436183075907, 2.066605747061069],
						[61.417436276808935, 2.066605694195804],
						[61.417458420241708, 2.0665640995104013],
						[61.417477799774943, 2.066564099308557],
						[61.417477851365248, 2.066564085930267],
						[61.417477939656351, 2.0665640323966161],
						[61.417477977343445, 2.0665639939023883],
						[61.417500176696002, 2.0665222944980925],
						[61.417500179083916, 2.0665222883230507],
						[61.417500184750857, 2.0665222757743531],
						[61.417500187138728, 2.0665222695993015],
						[61.417516838189925, 2.0664779995658846],
						[61.417533300309167, 2.06647800055458],
						[61.417533350913153, 2.0664779855149868],
						[61.417533440190525, 2.0664779336421191],
						[61.41753347600018, 2.0664778936851596],
						[61.417555776272494, 2.066436194218352],
						[61.417555777959585, 2.066436191961381],
						[61.417555780442619, 2.0664361876460831],
						[61.417555781143435, 2.066436183727975],
						[61.417575128421348, 2.0663946005975977],
						[61.417597200109668, 2.0663946003274543],
						[61.417597255965276, 2.066394582236319],
						[61.417597349222405, 2.0663945200713227],
						[61.417597385637663, 2.0663944743362999],
						[61.417614084314991, 2.066352879494215],
						[61.417633428191166, 2.0663113007475493],
						[61.417655599935735, 2.0663113006878286],
						[61.4176556557913, 2.0663112825964944],
						[61.417655749048436, 2.0663112204311105],
						[61.4176557863548, 2.0663111744973115],
						[61.417672381876379, 2.0662694840151663],
						[61.417694520118779, 2.0662280008807654],
						[61.417711100210823, 2.0662279998907311],
						[61.41771115527041, 2.0662279838575763],
						[61.417711245949221, 2.0662279241474231],
						[61.417711283350734, 2.0662278800731988],
						[61.417730780015212, 2.0661833872824777],
						[61.417752919821055, 2.0661418998914272],
						[61.41776939995038, 2.0661419004417478],
						[61.41776945055431, 2.0661418854014713],
						[61.417769539831539, 2.0661418335272299],
						[61.417769575641039, 2.0661417935695807],
						[61.417791875801569, 2.066100093541948],
						[61.417791878379752, 2.0661000910863554],
						[61.417791879971688, 2.0661000869696013],
						[61.417791880767666, 2.0661000849112026],
						[61.417811227839366, 2.0660584993944431],
						[61.417827799909823, 2.0660585000976566],
						[61.417827850608923, 2.0660584869169791],
						[61.417827939790946, 2.0660584331826244],
						[61.41782797560046, 2.0660583932247918],
						[61.417850176359423, 2.0660167942079259],
						[61.417850178842492, 2.0660167898925255],
						[61.417850182822299, 2.0660167796005919],
						[61.417850186101298, 2.0660167732268295],
						[61.41786683532245, 2.065975200124651],
						[61.417888899781836, 2.0659751992731015],
						[61.417888953950296, 2.0659751834380162],
						[61.41788904383305, 2.0659751257851386],
						[61.417889081329626, 2.0659750835701267],
						[61.417908481143989, 2.0659333845066086],
						[61.417908481939968, 2.0659333824482147],
						[61.417908485218966, 2.0659333760744309],
						[61.417908486110107, 2.0659333758758116],
						[61.417925142164648, 2.0658918838852265],
						[61.41794722547435, 2.0658890986679785],
						[61.417947271337134, 2.0658890790409474],
						[61.417947348648887, 2.0658890223085895],
						[61.417947380989006, 2.06588898500465],
						[61.417966879240161, 2.065847290196225],
						[61.417989019730733, 2.0658058001893576],
						[61.418005599914373, 2.0658058008242017],
						[61.418005655769839, 2.0658057827317262],
						[61.418005749026705, 2.0658057205640801],
						[61.418005786332877, 2.0658056746291278],
						[61.418022381687855, 2.0657639834074204],
						[61.418044520065699, 2.0657223996815324],
						[61.418063899685798, 2.0657224007921648],
						[61.418063955541292, 2.0657223826995095],
						[61.41806404879808, 2.0657223205314925],
						[61.418064086104216, 2.0657222745963559],
						[61.418080782077368, 2.0656806830835888],
						[61.418102919536473, 2.0656390994181577],
						[61.418122199989718, 2.0656391000061283],
						[61.418122253266986, 2.0656390843689434],
						[61.418122342448811, 2.0656390306328229],
						[61.418122379054132, 2.0656389886157087],
						[61.418144524399558, 2.0655946004100785],
						[61.418161100030581, 2.0655946000605003],
						[61.418180600392823, 2.065594600507735],
						[61.418180650996696, 2.0655945854663296],
						[61.418180740273634, 2.0655945335897821],
						[61.418180776082998, 2.0655944936309281],
						[61.418202919958468, 2.0655530005004383],
						[61.418219400082805, 2.0655530007237592],
						[61.418241699786329, 2.0655529997886375],
						[61.418241707010488, 2.0655530000593925],
						[61.418241721363692, 2.0655529987410954],
						[61.418241729479007, 2.0655529988132137],
						[61.418261128866902, 2.0655501983432618],
						[61.418261174729636, 2.0655501787154926],
						[61.418261252837176, 2.0655501199232575],
						[61.41826128409572, 2.0655500790976236],
						[61.418277939270212, 2.0655112839641627],
						[61.418300012764902, 2.0655085006147593],
						[61.418316700132081, 2.0655084997609561],
						[61.418341700201061, 2.065508500682451],
						[61.41834175605652, 2.065508482589216],
						[61.418341849313173, 2.0655084204199632],
						[61.418341885728104, 2.0655083746827749],
						[61.418358436201935, 2.0654668997406134],
						[61.418375000151997, 2.0654669000380892],
						[61.418375005593916, 2.0654669007060891],
						[61.418375019056008, 2.0654668995863723],
						[61.418375025293862, 2.0654668981959428],
						[61.418397225006835, 2.0654640979534125],
						[61.418397270964689, 2.0654640801852264],
						[61.418397349167364, 2.0654640232522974],
						[61.418397380521071, 2.0654639842861839],
						[61.418416826640552, 2.0654223998820442],
						[61.418438900195206, 2.0654224003468387],
						[61.418455600227723, 2.0654224003807657],
						[61.418474999639841, 2.0654224006377429],
						[61.418475051134791, 2.0654223853972535],
						[61.418475140411687, 2.0654223335196629],
						[61.418475176220994, 2.0654222935602338],
						[61.418497320035812, 2.0653807999050806],
						[61.418513900215714, 2.065380800296106],
						[61.418513905657647, 2.0653808009640899],
						[61.418513919119739, 2.0653807998443448],
						[61.418513925357594, 2.065380798453889],
						[61.418536125067533, 2.0653779981311704],
						[61.418536171025394, 2.0653779803627632],
						[61.418536249228019, 2.0653779234293443],
						[61.418536280581669, 2.0653778844629702],
						[61.418555726671094, 2.0653362998157743],
						[61.418572199569255, 2.0653362996416047],
						[61.418594400199346, 2.0653362999272682],
						[61.418594407423505, 2.065336300197969],
						[61.418594420885569, 2.0653362990781896],
						[61.418594428014593, 2.0653362974890705],
						[61.418613914085469, 2.0653335000868305],
						[61.418636100267086, 2.0653334998271715],
						[61.418636156217673, 2.0653334835932391],
						[61.418636249379055, 2.0653334195629918],
						[61.418636285889114, 2.0653333756849723],
						[61.41865293546801, 2.0652919007185337],
						[61.418672199686966, 2.0652919009253461],
						[61.418694400221291, 2.0652918993149076],
						[61.418711100252601, 2.0652918992699449],
						[61.418711156203159, 2.0652918830358939],
						[61.418711249364556, 2.0652918190053637],
						[61.418711285779381, 2.0652917732673592],
						[61.418727941312753, 2.0652501858652359],
						[61.418752811371, 2.065247399621895],
						[61.418769399664974, 2.0652474000050898],
						[61.418791700351029, 2.0652474004867121],
						[61.418811099761257, 2.0652474006210859],
						[61.418827799792105, 2.065247400548194],
						[61.418827806029988, 2.0652473991576863],
						[61.418827818600924, 2.0652473982364983],
						[61.418827824933977, 2.065247398705818],
						[61.41885001305441, 2.06524460082779],
						[61.418869399798396, 2.0652446000194091],
						[61.418891699593232, 2.0652446006929925],
						[61.418908300458007, 2.0652446001486995],
						[61.418927799925214, 2.0652446005450265],
						[61.418927806163097, 2.0652445991545121],
						[61.418927817842906, 2.0652445984319607],
						[61.418927824175981, 2.0652445989012786],
						[61.418950011993822, 2.0652419007707481],
						[61.418966700249563, 2.0652418995527659],
						[61.41898889998712, 2.0652418999514692],
						[61.419008300288247, 2.0652418998757449],
						[61.419025000318918, 2.0652418997930053],
						[61.419025005760879, 2.065241900460959],
						[61.419025019222921, 2.0652418993411135],
						[61.419025024569677, 2.0652418981492411],
						[61.419047212689463, 2.0652391002404356],
						[61.419066700381528, 2.0652390994903631],
						[61.419088900118972, 2.0652390998824184],
						[61.419088956069523, 2.065239083648013],
						[61.419089049230884, 2.0652390196164756],
						[61.419089085645687, 2.0652389738778258],
						[61.41910573469189, 2.0651974005391822],
						[61.419124999800765, 2.0651974004706366],
						[61.419125006038648, 2.0651973990801009],
						[61.419125018609577, 2.0651973981588974],
						[61.419125024942645, 2.0651973986282033],
						[61.419147213060839, 2.0651946006741295],
						[61.419163900424905, 2.0651945996225618],
						[61.419186100161795, 2.0651945999783905],
						[61.419205599628299, 2.0651946003320618],
						[61.419205656374814, 2.0651945820390174],
						[61.419205748740183, 2.0651945200655848],
						[61.419205786046106, 2.0651944741280519],
						[61.419222341975285, 2.0651529834359872],
						[61.419244412783307, 2.0651502003011286],
						[61.419263899583257, 2.0651501996860291],
						[61.419280479760808, 2.0651501999277189],
						[61.419302623374463, 2.0651917938779487],
						[61.419302659616719, 2.0651918328256751],
						[61.419302748876838, 2.0651918862896972],
						[61.419302800017206, 2.0651918993434557],
						[61.419322200317616, 2.0651918992238492],
						[61.41932220575957, 2.0651918998918015],
						[61.419322219221641, 2.0651918987719147],
						[61.419322224568383, 2.0651918975800183],
						[61.419344412685923, 2.0651890995951643],
						[61.419361100145032, 2.065189100393594],
						[61.419380599611351, 2.0651891007366938],
						[61.419402800239119, 2.0651891008798522],
						[61.419419400211972, 2.0651891004868519],
						[61.419419405558749, 2.0651890992949444],
						[61.419419419020819, 2.06518909817505],
						[61.419419425353873, 2.0651890986443742],
						[61.419441712541925, 2.0651862992512116],
						[61.419461100271214, 2.0651863000437114],
						[61.419477800301166, 2.0651862999153114],
						[61.419500000037651, 2.0651863002506343],
						[61.419500007261874, 2.0651863005212814],
						[61.419500020723909, 2.0651862994013848],
						[61.419500028744046, 2.0651862976135225],
						[61.419519429104575, 2.0651834984127069],
						[61.419519472388998, 2.0651834812393144],
						[61.419519546326256, 2.0651834290164546],
						[61.419519576087957, 2.0651833941656252],
						[61.419541819862509, 2.0651418991740962],
						[61.419558299982668, 2.0651418991337427],
						[61.419577800339525, 2.0651418992405688],
						[61.419577851038511, 2.0651418860574782],
						[61.41957794022003, 2.0651418323171891],
						[61.419577977015564, 2.0651417940171517],
						[61.419600120129594, 2.0651001990818694],
						[61.41961670030652, 2.0651001992852875],
						[61.419616705748446, 2.065100199953231],
						[61.419616719210502, 2.0651001988332869],
						[61.419616724557244, 2.0651001976413603],
						[61.419638912671296, 2.0650973995511799],
						[61.41965830039959, 2.065097400280369],
						[61.419658356254978, 2.0650973821854626],
						[61.419658448620247, 2.065097320210767],
						[61.419658485926142, 2.0650972742724143],
						[61.419675134613954, 2.0650557998466814],
						[61.419697200048255, 2.065055799921601],
						[61.419713900076971, 2.0650557997149988],
						[61.419713950775964, 2.0650557865316701],
						[61.419714039957448, 2.0650557327908778],
						[61.419714076752953, 2.0650556944905651],
						[61.419736228972951, 2.0650140841033986],
						[61.419755613858648, 2.0650112994998975],
						[61.419772200367781, 2.0650113001198838],
						[61.419794400102155, 2.0650113003168151],
						[61.419794454270416, 2.0650112844788695],
						[61.419794544152644, 2.065011226819371],
						[61.419794580757767, 2.0650111847991406],
						[61.419814026800367, 2.0649696000595799],
						[61.419836100348952, 2.0649696001389697],
						[61.419852800376908, 2.0649695998812923],
						[61.419852806709933, 2.0649696003505631],
						[61.419852821063138, 2.0649695990318797],
						[61.419852828192163, 2.0649695974425932],
						[61.419872228243392, 2.064966897827162],
						[61.419872270636667, 2.0649668808519031],
						[61.419872346356094, 2.0649668282305651],
						[61.419872377008815, 2.0649667931804627],
						[61.419894520156404, 2.0649251995774929],
						[61.419911100331731, 2.0649251996775555],
						[61.419930599795123, 2.0649251998330067],
						[61.419930651385215, 2.0649251864507105],
						[61.419930739675522, 2.0649251329077902],
						[61.419930777362062, 2.0649250944083897],
						[61.4199529196041, 2.0648835008946218],
						[61.41996939972195, 2.0648835007033894],
						[61.419991700307811, 2.0648834990085918],
						[61.419991753584924, 2.0648834833689649],
						[61.419991844453413, 2.0648834273699253],
						[61.419991881058479, 2.0648833853492663],
						[61.420011237425555, 2.0648418804389617],
						[61.420027832862502, 2.0648390967960499],
						[61.420027875255748, 2.0648390798205392],
						[61.420027947410624, 2.0648390279932474],
						[61.420027977172197, 2.0648389931414988],
						[61.420050120276009, 2.0647973992353288],
						[61.420069399828805, 2.0647973994224569],
						[61.420091699617679, 2.0647973997199203],
						[61.420091756459307, 2.0647973832854123],
						[61.420091848729271, 2.064797319448981],
						[61.420091886035031, 2.0647972735096274],
						[61.420108435549018, 2.0647557997321133],
						[61.420125000383315, 2.0647557993921519],
						[61.420125051082273, 2.0647557862081389],
						[61.420125140263615, 2.0647557324656902],
						[61.420125177059006, 2.0647556941645102],
						[61.420147329270428, 2.064714084861822],
						[61.420166714146283, 2.0647113000186263],
						[61.420188900319815, 2.0647112992436849],
						[61.42018895627033, 2.0647112830076404],
						[61.420189048540195, 2.0647112191707517],
						[61.420189085845927, 2.0647111732311223],
						[61.420205734798664, 2.0646695999747298],
						[61.420224999901762, 2.0646695995354158],
						[61.420247199632087, 2.064669599467809],
						[61.420247255582545, 2.0646695832316602],
						[61.420247348743537, 2.06466951919584],
						[61.420247386144432, 2.0646694751159811],
						[61.420264034685943, 2.0646279996657042],
						[61.420286100115192, 2.0646279994104106],
						[61.420286107339365, 2.0646279996809351],
						[61.420286120801443, 2.0646279985607334],
						[61.420286128025616, 2.0646279988312579],
						[61.420305628329615, 2.0646251975872221],
						[61.420305675974411, 2.0646251775592677],
						[61.420305754877369, 2.0646251167019662],
						[61.420305786040409, 2.064625074012695],
						[61.420322335797692, 2.064583500087509],
						[61.420344400335296, 2.0645834999966763],
						[61.420363899795149, 2.0645834999205808],
						[61.420363951385191, 2.0645834865374844],
						[61.420364039675327, 2.0645834329927668],
						[61.420364076470634, 2.064583394691065],
						[61.420386220075791, 2.0645418997130682],
						[61.420402800247729, 2.0645418995920481],
						[61.420402806580789, 2.0645419000612253],
						[61.42040282093398, 2.0645418987423074],
						[61.42040282904928, 2.0645418988141482],
						[61.420422214144253, 2.064539100612707],
						[61.420444400315738, 2.0645390997032664],
						[61.420461100339779, 2.0645390991935493],
						[61.420483300163689, 2.0645391008818916],
						[61.420502799623009, 2.0645391007723113],
						[61.420502856369396, 2.0645390824771677],
						[61.420502948734395, 2.0645390204989802],
						[61.420502986040063, 2.0645389745586602],
						[61.42051954213369, 2.0644973838506497],
						[61.420541712975464, 2.0644946003420848],
						[61.420561099806314, 2.0644946008581604],
						[61.420577799829921, 2.0644946003205038],
						[61.420599999558043, 2.0644946001117894],
						[61.420600006782216, 2.0644946003822788],
						[61.4206000211354, 2.0644945990633259],
						[61.420600028264381, 2.0644945974738906],
						[61.420619414042228, 2.0644919006470537],
						[61.420641700270103, 2.0644918999604802],
						[61.420641756220562, 2.0644918837237562],
						[61.420641849381454, 2.0644918196865207],
						[61.420641885795952, 2.0644917739446185],
						[61.420658436395513, 2.0644501992924744],
						[61.420675000431082, 2.0644502008295413],
						[61.420700000484935, 2.0644502007783614],
						[61.420716699616975, 2.0644502004107217],
						[61.420716705950042, 2.0644502008798766],
						[61.420716719412084, 2.0644501997595825],
						[61.420716724758826, 2.0644501985674917],
						[61.420738912850055, 2.0644473998653297],
						[61.420758299680323, 2.0644474003440245],
						[61.420774999703411, 2.0644473997741621],
						[61.42079720032195, 2.0644473993239632],
						[61.420797256272394, 2.0644473830870629],
						[61.420797348542159, 2.0644473192480239],
						[61.420797385942976, 2.064447275167077],
						[61.42081404175719, 2.0644057840433607],
						[61.420836113429488, 2.0644029999656928],
						[61.420855600316315, 2.0644030006781753],
						[61.420872200281927, 2.0644029998157651],
						[61.420897200335006, 2.0644029997163602],
						[61.420913900357668, 2.0644029991178252],
						[61.420913905799601, 2.0644029997856639],
						[61.420913919261643, 2.0644029986653356],
						[61.420913924608371, 2.0644029974732256],
						[61.420936112792873, 2.0644002005705935],
						[61.42095559958441, 2.0644001994172743],
						[61.420972199645107, 2.0644002004098416],
						[61.42099440026297, 2.0644001999168298],
						[61.421013899720592, 2.0644001996994237],
						[61.421013906053652, 2.0644002001685902],
						[61.421013918624588, 2.0644001992469323],
						[61.421013924862436, 2.0644001978561377],
						[61.421036112951427, 2.0643973990779174],
						[61.421052672435501, 2.0643973997072997],
						[61.421072018972836, 2.0644389832461711],
						[61.421072056296872, 2.0644390257162981],
						[61.421072145842842, 2.0644390847607381],
						[61.421072199847032, 2.0644391009376744],
						[61.421094400465222, 2.0644391004677076],
						[61.421094407689424, 2.064439100738193],
						[61.42109442471596, 2.0644390988231258],
						[61.421094432736076, 2.0644390970349558],
						[61.421111116761416, 2.0644363008135116],
						[61.421133300257807, 2.0644363003948714],
						[61.421152799715649, 2.0644363001943851],
						[61.421169399681325, 2.0644362993393708],
						[61.421191700356474, 2.0644362991282814],
						[61.421191706689541, 2.0644362995974528],
						[61.421191721042696, 2.0644362982784377],
						[61.421191729158011, 2.0644362983502207],
						[61.421211114248159, 2.06443349998032],
						[61.421227799917666, 2.0644335007061536],
						[61.421249999644502, 2.0644335004236654],
						[61.421269399936293, 2.064433499752627],
						[61.421269406269346, 2.0644335002217868],
						[61.421269417949148, 2.0644334994988198],
						[61.421269424187017, 2.0644334981080084],
						[61.421291712031994, 2.0644307993545512],
						[61.421308300317698, 2.0644307992147484],
						[61.421327799870532, 2.0644308008636347],
						[61.421349999597297, 2.0644308005745633],
						[61.421366699619888, 2.0644307999750757],
						[61.42136670595292, 2.0644308004442546],
						[61.421366719414983, 2.0644307993239019],
						[61.421366724761725, 2.0644307981317729],
						[61.421388912850844, 2.0644279993272141],
						[61.421408299680415, 2.064427999767438],
						[61.421424999702907, 2.0644279991644132],
						[61.421447200415876, 2.0644280005301048],
						[61.421466699873378, 2.0644280003116133],
						[61.421466706206459, 2.0644280007807634],
						[61.42146671877736, 2.0644279998591037],
						[61.421466725015229, 2.0644279984682781],
						[61.421488913104035, 2.0644251996480953],
						[61.421505599664428, 2.0644252001611005],
						[61.421524999955928, 2.0644251994745004],
						[61.421525050654793, 2.0644251862892],
						[61.421525139835957, 2.0644251325431529],
						[61.421525176631206, 2.064425094239744],
						[61.421547319667646, 2.0643834998392911],
						[61.421563899837508, 2.0643834995936268],
						[61.42156390617054, 2.064383500062779],
						[61.421563917850364, 2.064383499339792],
						[61.42156392418341, 2.0643834998089439],
						[61.42158611196912, 2.0643808007153996],
						[61.421605599651073, 2.0643807993246845],
						[61.421622199711187, 2.06438080028432],
						[61.421644400328255, 2.0643807997472283],
						[61.421663899785194, 2.064380799491107],
						[61.421663906118241, 2.0643807999602752],
						[61.421663918689177, 2.0643807990385747],
						[61.421663924927053, 2.0643807976477309],
						[61.421686113109132, 2.0643780006270993],
						[61.421702799573794, 2.0643779992479447],
						[61.421722199959866, 2.0643780003839054],
						[61.421744399685707, 2.0643780000388525],
						[61.421744407801022, 2.064378000110612],
						[61.421744424827537, 2.0643779981954689],
						[61.421744432847674, 2.0643779964072322],
						[61.421761116869966, 2.0643752000720736],
						[61.421783300365121, 2.0643751995796977],
						[61.421802799821869, 2.0643751993144006],
						[61.421819287158577, 2.0643751990608585],
						[61.421844228097179, 2.0644168022752143],
						[61.421844271563899, 2.0644168414954467],
						[61.421844374987138, 2.0644168899220325],
						[61.421844433066155, 2.064416897665772],
						[61.421861116994172, 2.0644140994789368],
						[61.421877799989716, 2.0644141007677401],
						[61.421902800041877, 2.0644141006245733],
						[61.421919400006864, 2.0644140997316174],
						[61.421919406339896, 2.0644141002007763],
						[61.421919418910846, 2.0644140992790745],
						[61.421919425148715, 2.0644140978882222],
						[61.421941713293137, 2.0644112992616011],
						[61.421961100122118, 2.064411299668977],
						[61.421977800144077, 2.0644112990376464],
						[61.42199999996523, 2.0644113005644384],
						[61.422019400256204, 2.0644112998490511],
						[61.422019405698137, 2.0644113005169],
						[61.422019419160179, 2.0644112993965029],
						[61.422019425398048, 2.0644112980056439],
						[61.422041712651023, 2.0644084995620653],
						[61.422058300044966, 2.0644084995829703],
						[61.42207780039292, 2.0644084991286054],
						[61.422100000213938, 2.0644085006487551],
						[61.422116700235804, 2.0644085000110564],
						[61.422116705677759, 2.0644085006788981],
						[61.422116718248674, 2.064408499757191],
						[61.422116723595401, 2.0644084985650246],
						[61.422138912271798, 2.0644057992169524],
						[61.422158299991665, 2.0644057994142271],
						[61.42217500010868, 2.0644058006330535],
						[61.422197077213632, 2.0644057994089735],
						[61.422216520796397, 2.0644444901298482],
						[61.42221655802522, 2.0644445307414241],
						[61.42221664827197, 2.0644445858690883],
						[61.422216700398728, 2.0644446005838462],
						[61.422238900124825, 2.0644446002632444],
						[61.422255475743903, 2.0644445991595077],
						[61.422274820740611, 2.0644833890864924],
						[61.422274857969448, 2.0644834296981975],
						[61.422274947325036, 2.0644834850247529],
						[61.422275000342935, 2.0644834995409163],
						[61.422297200069401, 2.0644834992453158],
						[61.422313791123415, 2.0644835003625546],
						[61.422338732272785, 2.0645223086982654],
						[61.422338768229729, 2.0645223420686523],
						[61.422338851667433, 2.0645223874273744],
						[61.422338900039307, 2.064522399217021],
						[61.422355472189622, 2.0645224008060103],
						[61.422374818619645, 2.0645639842511323],
						[61.422374855943659, 2.0645640267230907],
						[61.422374946285544, 2.0645640837113883],
						[61.422375000289733, 2.0645640998890724],
						[61.42239720001696, 2.0645640996462387],
						[61.422413783847375, 2.0645641005324342],
						[61.422435925956442, 2.0646028991587051],
						[61.422435962994868, 2.0646029360506799],
						[61.422436050282606, 2.0646029861951081],
						[61.422436099640805, 2.0646029996462469],
						[61.422455599990272, 2.0646029992981814],
						[61.422472083763914, 2.0646029999373758],
						[61.422494225863296, 2.0646417986619237],
						[61.422494262901722, 2.0646418355540108],
						[61.422494350189439, 2.0646418856986157],
						[61.422494399642837, 2.0646419010098906],
						[61.422513899992623, 2.0646419006837919],
						[61.4225304801639, 2.0646419005420564],
						[61.422552624101314, 2.064683394814447],
						[61.422552665880914, 2.0646834362933282],
						[61.422552767712183, 2.0646834888392274],
						[61.422552827668682, 2.0646834980462048],
						[61.422572214243857, 2.0646807990295559],
						[61.42259427343371, 2.0646808000957342],
						[61.422613719288911, 2.0647222846413382],
						[61.422613755721763, 2.0647223273124871],
						[61.422613846063612, 2.0647223843016409],
						[61.422613900067802, 2.064722400479674],
						[61.422630600091814, 2.0647223999922555],
						[61.422630606424889, 2.0647224004614677],
						[61.422630618995839, 2.0647223995398671],
						[61.422630625233708, 2.0647223981490348],
						[61.422652679980423, 2.064719617824005],
						[61.422672019167806, 2.0647611845704921],
						[61.422672056396564, 2.0647612251830241],
						[61.422672145942485, 2.0647612842311029],
						[61.422672199946653, 2.0647613004092538],
						[61.42269426804851, 2.0647612995409244],
						[61.422710915689429, 2.0648000782968761],
						[61.422710953108549, 2.0648001226296091],
						[61.422711044627079, 2.0648001850006215],
						[61.422711100413473, 2.0648002007814754],
						[61.422730479926095, 2.0648001992690039],
						[61.422752622627677, 2.0648417938881627],
						[61.42275266806692, 2.0648418364328109],
						[61.422752771680422, 2.0648418885821469],
						[61.422752833419153, 2.0648418973921068],
						[61.422769417300366, 2.0648390990525924],
						[61.422794265043294, 2.0648390993009387],
						[61.422810914206693, 2.0648806734684224],
						[61.422810950829884, 2.0648807198601307],
						[61.422811044225767, 2.0648807836942544],
						[61.422811100012176, 2.0648807994752927],
						[61.422830484171492, 2.0648808007355925],
						[61.422852626407433, 2.06491949845686],
						[61.422852662554625, 2.0649195355483561],
						[61.422852749937491, 2.0649195875543285],
						[61.422852800186782, 2.0649196008074249],
						[61.422869280302933, 2.06491960053512],
						[61.422891523824902, 2.0649611936283851],
						[61.422891566590714, 2.0649612367694483],
						[61.422891669217869, 2.0649612872580096],
						[61.422891729269523, 2.0649612983256631],
						[61.422911114371708, 2.0649585000599622],
						[61.422927680096869, 2.0649584995317651],
						[61.422949822755413, 2.0650000945207507],
						[61.422949859888924, 2.0650001332738297],
						[61.422949949149199, 2.06500018674289],
						[61.422950000289589, 2.0650001997974687],
						[61.422969284297601, 2.0650001989995257],
						[61.422991522408346, 2.0650389922313748],
						[61.423010919250061, 2.0650806840814777],
						[61.423010955682777, 2.0650807267535698],
						[61.423011046024499, 2.0650807837444067],
						[61.423011100028639, 2.0650807999232459],
						[61.423027683767572, 2.0650807992031157],
						[61.423049826047304, 2.0651194991661268],
						[61.423049863085623, 2.0651195360594023],
						[61.423049950373205, 2.0651195862061651],
						[61.423049999731376, 2.0651195996583267],
						[61.423069280175881, 2.0651195997249925],
						[61.423091523642519, 2.0651611932227527],
						[61.423091568285713, 2.0651612378270672],
						[61.423091672695051, 2.0651612879192283],
						[61.423091732651514, 2.0651612971272213],
						[61.423108316636387, 2.0651585007751581],
						[61.423127679921379, 2.0651584993255661],
						[61.423149823415727, 2.0652000945197724],
						[61.42314986697744, 2.0652001356027343],
						[61.423149968808552, 2.0652001881512194],
						[61.423150028765022, 2.0652001973592946],
						[61.423169273396063, 2.0651974192406648],
						[61.423185914188622, 2.0652389733626033],
						[61.4231859508117, 2.0652390197552717],
						[61.423186044207434, 2.0652390835911776],
						[61.423186099993828, 2.0652390993730032],
						[61.423208299727506, 2.0652390995734664],
						[61.423227683794622, 2.0652390991805216],
						[61.423249825732377, 2.065277899275062],
						[61.423249862770625, 2.0652779361687545],
						[61.423249950058199, 2.0652779863162256],
						[61.423250000307455, 2.0652779995700485],
						[61.423266573259113, 2.0652779994706365],
						[61.423285918893143, 2.0653194850010292],
						[61.423285960862927, 2.0653195302018834],
						[61.423286063775528, 2.065319586272456],
						[61.42328612392236, 2.0653195992009832],
						[61.423308171955846, 2.0653169166567831],
						[61.423324814099061, 2.0653583747697697],
						[61.423324850626926, 2.0653584193026853],
						[61.423324944022617, 2.0653584831392102],
						[61.423324999904175, 2.0653585007813979],
						[61.423349864679288, 2.0653584995003254],
						[61.423366513698511, 2.0654000746778785],
						[61.423366556749784, 2.0654001234004467],
						[61.423366663607531, 2.0654001861171309],
						[61.423366729101119, 2.0654001978537568],
						[61.423385988981948, 2.0653974183129353],
						[61.423408123248237, 2.0654389934815662],
						[61.423408160381634, 2.0654390322357115],
						[61.423408248750604, 2.065439085905405],
						[61.423408299986185, 2.0654391008209556],
						[61.423424883727712, 2.0654391002777337],
						[61.423447026409839, 2.0654778986958595],
						[61.423447062556953, 2.0654779357886812],
						[61.423447149844414, 2.0654779859370249],
						[61.423447200093683, 2.0654779991912156],
						[61.423466564367693, 2.0654779995878729],
						[61.423483113598635, 2.0655194749372998],
						[61.423483155758746, 2.0655195238588964],
						[61.423483258956757, 2.0655195855107835],
						[61.423483320981006, 2.0655195999025096],
						[61.423508171034619, 2.0655169154891735],
						[61.423524814108504, 2.0655583756336022],
						[61.423524850636305, 2.0655584201670267],
						[61.423524943936769, 2.0655584821444157],
						[61.423524999818319, 2.0655584997870511],
						[61.42354707693427, 2.0655584993173899],
						[61.423566516676516, 2.0655972816141253],
						[61.423583113926583, 2.0656389740869239],
						[61.423583151440639, 2.0656390202819663],
						[61.42358324384994, 2.0656390824583792],
						[61.423583299731469, 2.0656391001011865],
						[61.423605476014266, 2.0656391001533501],
						[61.423624821405575, 2.0656778885517784],
						[61.423624857742993, 2.0656779293652603],
						[61.42362494709819, 2.0656779846974107],
						[61.42362500011599, 2.0656779992160992],
						[61.423647072777527, 2.0656779998198327],
						[61.423666516194459, 2.0657194793237985],
						[61.423683114396198, 2.0657611736559618],
						[61.423683155665103, 2.0657612227768412],
						[61.423683261631602, 2.0657612856940086],
						[61.4236833253429, 2.0657612978288129],
						[61.423705480081537, 2.0657585165043213],
						[61.423724818885432, 2.065800083418539],
						[61.423724856209098, 2.0658001258937402],
						[61.423724945754628, 2.0658001849466343],
						[61.423724999663541, 2.0658001992668988],
						[61.423741584394037, 2.0658002005663474],
						[61.4237637220509, 2.0658389913203683],
						[61.423783118669199, 2.0658806846867317],
						[61.423783155897667, 2.0658807253019842],
						[61.423783246334281, 2.0658807841565654],
						[61.423783300338357, 2.0658808003370974],
						[61.423805475732408, 2.0658808007507181],
						[61.423824817322618, 2.0659194812599444],
						[61.423841513647282, 2.065961174716004],
						[61.423841551066076, 2.0659612190516756],
						[61.423841643570427, 2.065961283089643],
						[61.423841700343054, 2.0659613005344819],
						[61.4238637756808, 2.0659613007329352],
						[61.423883120987668, 2.0660000896486133],
						[61.423883121973958, 2.0660000913100762],
						[61.423883122960262, 2.0660000929715614],
						[61.423883123946567, 2.0660000946330235],
						[61.423905421985808, 2.0660417923674537],
						[61.423924816551526, 2.0660806813982586],
						[61.42394151402371, 2.0661222750964887],
						[61.423941550551348, 2.0661223196311522],
						[61.423941643946748, 2.0661223834711455],
						[61.423941699733092, 2.0661223992548341],
						[61.42396377239902, 2.0661224001588336],
						[61.423983119180569, 2.0661639846524915],
						[61.423983121153164, 2.0661639879754579],
						[61.42398312430241, 2.0661639966801686],
						[61.423983126179834, 2.0661639981429811],
						[61.42400542612512, 2.0662028981158898],
						[61.424005463258382, 2.0662029368716603],
						[61.424005549654467, 2.0662029872215131],
						[61.424005599903708, 2.0662030004770489],
						[61.424022077260695, 2.0662029995797306],
						[61.424041520778474, 2.0662417895086334],
						[61.424041521764771, 2.0662417911701105],
						[61.424041522751054, 2.0662417928315953],
						[61.424041523737351, 2.0662417944930729],
						[61.424063723831033, 2.0662833940225558],
						[61.424063760073096, 2.0662834329771602],
						[61.424063849332924, 2.066283486451622],
						[61.424063899582123, 2.0662834997073132],
						[61.424083183601155, 2.0662834996587573],
						[61.424105419120501, 2.0663222862624968],
						[61.424122014096326, 2.0663639746555162],
						[61.424122015082609, 2.066363976317009],
						[61.424122018041501, 2.0663639813014698],
						[61.424122019122954, 2.0663639848231226],
						[61.424141518607819, 2.066405684247496],
						[61.424141520580399, 2.0664056875704806],
						[61.424141523729631, 2.0664056962752535],
						[61.424141525702225, 2.0664056995982532],
						[61.424163721789064, 2.066444491381874],
						[61.424183119030651, 2.066486183597732],
						[61.424183121098416, 2.0664861887808703],
						[61.424183124152478, 2.0664861956255196],
						[61.424183126125058, 2.0664861989485113],
						[61.424205419080963, 2.0665250877680066],
						[61.424222013902892, 2.0665667745433272],
						[61.424222014889189, 2.0665667762048257],
						[61.424222017848052, 2.0665667811893282],
						[61.424222018929527, 2.0665667847109912],
						[61.42424151865891, 2.0666083846160408],
						[61.42424152063149, 2.0666083879390622],
						[61.424241523780715, 2.066608396643868],
						[61.424241525753303, 2.0666083999668912],
						[61.424263722374228, 2.066647291600149],
						[61.424283118666196, 2.0666889842747644],
						[61.424283119747663, 2.0666889877964403],
						[61.424283121815421, 2.0666889929796071],
						[61.424283123692838, 2.0666889944424849],
						[61.424305420358884, 2.0667306878187643],
						[61.42432201570125, 2.0667694792746483],
						[61.424322016687555, 2.0667694809361588],
						[61.42432201767383, 2.066769482597671],
						[61.424322018660135, 2.066769484259197],
						[61.424341519014348, 2.0668111858666056],
						[61.424341520891765, 2.0668111873294763],
						[61.424341524040969, 2.0668111960343492],
						[61.424341526013549, 2.0668111993573954],
						[61.424363721689161, 2.0668500914577952],
						[61.424383115854972, 2.0668917792093193],
						[61.424399814080232, 2.0669333756159656],
						[61.424399818025378, 2.066933382262067],
						[61.424399826997153, 2.0669333990759542],
						[61.424399831928604, 2.0669334073835732],
						[61.424424831941131, 2.0669723085482565],
						[61.424424867897557, 2.0669723419235817],
						[61.424424952225664, 2.0669723870929797],
						[61.424424999801452, 2.0669724009458221],
						[61.424441579894847, 2.0669724001179333],
						[61.424463722687349, 2.0670139946109649],
						[61.424463767139684, 2.0670140354994775],
						[61.424463868970221, 2.0670140880558905],
						[61.424463928926613, 2.0670140972676885],
						[61.424483172717657, 2.0670113202957072],
						[61.424499813695562, 2.0670528732107778],
						[61.424499851209198, 2.0670529194088405],
						[61.424499943713087, 2.0670529834513109],
						[61.424500000390459, 2.0670529990382698],
						[61.424522076630304, 2.0670529997812941],
						[61.424541520813129, 2.0670917906602182],
						[61.424541557946107, 2.067091829417699],
						[61.424541647300842, 2.0670918847553956],
						[61.4245417003185, 2.0670918992768144],
						[61.424563764878897, 2.0670919007676045],
						[61.424580414408645, 2.0671333747838281],
						[61.424580450935942, 2.0671334193205708],
						[61.424580544330958, 2.0671334831647896],
						[61.424580600212323, 2.0671335008107414],
						[61.424599884143667, 2.0671334994073338],
						[61.424622026285199, 2.0671722986102732],
						[61.424622063323014, 2.0671723355077498],
						[61.424622149718822, 2.0671723858611943],
						[61.424622199967963, 2.0671723991185056],
						[61.424641584051962, 2.0671723998711511],
						[61.424663726183546, 2.0672111991524855],
						[61.424663763221346, 2.0672112360500572],
						[61.424663849617168, 2.0672112864036656],
						[61.424663899866296, 2.0672112996610545],
						[61.424680599908896, 2.0672113004361803],
						[61.424699880276385, 2.0672112998714334],
						[61.424722023003149, 2.0672528948719795],
						[61.42472206844171, 2.0672529374227002],
						[61.42472217195926, 2.0672529877228123],
						[61.424722232806808, 2.0672529967364719],
						[61.424738916810142, 2.0672501997057053],
						[61.424760973161554, 2.0672501993668515],
						[61.424780418694304, 2.067291784227717],
						[61.424780456017537, 2.0672918267060938],
						[61.424780546358463, 2.0672918837063068],
						[61.424780600362411, 2.0672918998897165],
						[61.424797091284766, 2.0672919002824584],
						[61.424822032314246, 2.0673307081039507],
						[61.424822068270593, 2.0673307414800934],
						[61.424822151802665, 2.0673307887097745],
						[61.424822200174368, 2.0673308005044855],
						[61.42483890012268, 2.0673307994779533],
						[61.424861099782007, 2.0673307992320402],
						[61.424880600155142, 2.0673308004924582],
						[61.424880608270477, 2.0673308005649558],
						[61.424880625297064, 2.0673307986510991],
						[61.42488063331723, 2.0673307968633887],
						[61.424897217265311, 2.0673279995804807],
						[61.424919399802775, 2.067327999383517],
						[61.424938900175832, 2.06732800063982],
						[61.424961099835038, 2.0673280003872652],
						[61.424961155690717, 2.0673279822926394],
						[61.424961248948065, 2.067327920114276],
						[61.424961286254621, 2.0673278741703114],
						[61.424977935230331, 2.0672862996438055],
						[61.4249972003539, 2.0672863006300726],
						[61.424997205700706, 2.0672862994382473],
						[61.424997219162769, 2.0672862983189018],
						[61.424997224604702, 2.0672862989872836],
						[61.425019412679802, 2.0672834998576373],
						[61.425036100056658, 2.0672834997195486],
						[61.425055600334034, 2.0672834990830147],
						[61.425077800087799, 2.0672835006535242],
						[61.425094399978377, 2.0672834993189348],
						[61.42509440631143, 2.0672834997886791],
						[61.425094417991282, 2.0672834990665958],
						[61.425094424324335, 2.0672834995363383],
						[61.425116723832716, 2.0672807997260536],
						[61.425116770586754, 2.0672807798972053],
						[61.425116848790083, 2.0672807229554007],
						[61.425116881035279, 2.0672806837836082],
						[61.425136228007247, 2.0672391002607289],
						[61.425152799986968, 2.067239099477634],
						[61.425174999645002, 2.0672390991517586],
						[61.42517500686921, 2.0672390994228516],
						[61.425175021222444, 2.0672390981048463],
						[61.42517502933773, 2.0672390981773079],
						[61.425194414408082, 2.0672362991679805],
						[61.425216699770068, 2.0672363004297662],
						[61.425233299755291, 2.067236300925412],
						[61.425252800032077, 2.0672363002512673],
						[61.425274999690046, 2.0672362999187515],
						[61.425275007805361, 2.067236299991206],
						[61.425275024831954, 2.0672362980772605],
						[61.425275032852113, 2.0672362962894959],
						[61.425291732989351, 2.0672334974655295],
						[61.425291775382881, 2.0672334804899029],
						[61.425291846647461, 2.0672334288572092],
						[61.425291876409602, 2.0672333940014878],
						[61.425314020144704, 2.0671918998407657],
						[61.425333299620362, 2.0671918994370411],
						[61.425349999662359, 2.0671919001783632],
						[61.425350051252686, 2.0671918867968926],
						[61.425350140434929, 2.0671918330513854],
						[61.425350177230946, 2.0671917947461926],
						[61.425372320268728, 2.0671501990310261],
						[61.425388899566947, 2.0671502003237059],
						[61.425411100114957, 2.0671501997248631],
						[61.42541110733913, 2.0671501999959387],
						[61.425411120801222, 2.0671501988765026],
						[61.425411127930275, 2.0671501972873481],
						[61.42543062839411, 2.0671473988087712],
						[61.425430676039269, 2.0671473787808452],
						[61.425430754848158, 2.0671473160587692],
						[61.425430786011866, 2.0671472733646219],
						[61.42544733554692, 2.067105799274628],
						[61.42546940010665, 2.0671058007334464],
						[61.425488900382007, 2.0671057999679761],
						[61.425488951081178, 2.0671057867849356],
						[61.425489040263386, 2.0671057330389009],
						[61.425489077059389, 2.0671056947334243],
						[61.425511220162122, 2.0670641006270944],
						[61.425527800255487, 2.0670640998102612],
						[61.425527806588555, 2.0670641002799575],
						[61.425527820941781, 2.0670640989618305],
						[61.42552782896194, 2.0670640971740037],
						[61.425547214121409, 2.0670612998702294],
						[61.425569400315162, 2.0670613005187644],
						[61.425586100260787, 2.0670612993215789],
						[61.425586150959937, 2.0670612861384217],
						[61.425586240142167, 2.0670612323920801],
						[61.425586276938105, 2.0670611940864361],
						[61.42560842002473, 2.0670195998150365],
						[61.425627799555713, 2.0670195995655791],
						[61.425644400430102, 2.0670195997327911],
						[61.425644405872028, 2.0670196004011325],
						[61.425644418442971, 2.0670195994802798],
						[61.425644423789748, 2.0670195982883808],
						[61.425666724275118, 2.0670168998777219],
						[61.425666771029107, 2.067016880048075],
						[61.425666849232265, 2.0670168231045247],
						[61.425666880586277, 2.0670167841303804],
						[61.425686228354628, 2.0669751994937027],
						[61.425702800427011, 2.0669752004113238],
						[61.425725000081776, 2.0669751998717927],
						[61.42574440039369, 2.0669752006017204],
						[61.425766700105576, 2.0669752003328732],
						[61.42576675685229, 2.0669751820384268],
						[61.425766850905433, 2.0669751177983322],
						[61.425766887225535, 2.0669750701911322],
						[61.425783438760192, 2.0669307992421877],
						[61.42580280027731, 2.0669307991829537],
						[61.425825000026663, 2.0669308004673739],
						[61.425841699971095, 2.0669307991912653],
						[61.425863899720383, 2.0669308004738687],
						[61.425863906944592, 2.0669308007448972],
						[61.425863921297776, 2.066930799426693],
						[61.425863929317927, 2.066930797638808],
						[61.4258833135816, 2.0669280004070725],
						[61.425900000063855, 2.0669280002469699],
						[61.42590005066787, 2.0669279852031663],
						[61.425900139945149, 2.0669279333161086],
						[61.425900176645911, 2.0669278931496406],
						[61.425922319778849, 2.0668863002126758],
						[61.425941700199459, 2.0668862996688868],
						[61.425963899948222, 2.0668863009151344],
						[61.425980599892156, 2.0668862996102937],
						[61.425980607116379, 2.066886299881312],
						[61.425980620578443, 2.0668862987617289],
						[61.425980628693758, 2.0668862988340817],
						[61.426000013751796, 2.066883499500566],
						[61.426022199943233, 2.0668835000014405],
						[61.426022255893962, 2.0668834837655661],
						[61.426022349055891, 2.0668834197232813],
						[61.426022386457419, 2.0668833756374214],
						[61.426039034968412, 2.0668418992148108],
						[61.426061100415943, 2.0668419002607044],
						[61.426080599797224, 2.0668418995044768],
						[61.426097199778724, 2.0668418997599023],
						[61.42609720611177, 2.0668419002295573],
						[61.426097218682735, 2.0668418993086028],
						[61.426097224920639, 2.066841897917993],
						[61.426119424659177, 2.0668390976001247],
						[61.426119474086541, 2.0668390771739005],
						[61.426119553881541, 2.0668390161111612],
						[61.426119585936291, 2.0668389732170374],
						[61.426136234924734, 2.0667974005731118],
						[61.426158300276491, 2.0667973997227174],
						[61.426177799752452, 2.0667974007949437],
						[61.426194399638383, 2.0667973991630015],
						[61.426194405971451, 2.0667973996326441],
						[61.426194418542394, 2.0667973987116723],
						[61.426194424780284, 2.0667973973210496],
						[61.426216712989735, 2.0667945998127775],
						[61.426236099742333, 2.0667945996696155],
						[61.426258300380958, 2.0667946006381706],
						[61.426275000323997, 2.0667945992738614],
						[61.426294399742631, 2.0667946000676274],
						[61.426294406075712, 2.066794600537285],
						[61.426294417755535, 2.0667945998149673],
						[61.426294423993447, 2.0667945984243361],
						[61.426316580237554, 2.0667919168684916],
						[61.426335918897479, 2.0668333843161988],
						[61.426335956220896, 2.0668334267960145],
						[61.42633604567088, 2.0668334839961022],
						[61.42633609967492, 2.0668335001793361],
						[61.426352799713335, 2.0668335006934466],
						[61.426375000257103, 2.066833499824007],
						[61.42637500659017, 2.0668335002936757],
						[61.426375020943389, 2.0668334989753778],
						[61.4263750280724, 2.0668334973860976],
						[61.426394413811252, 2.0668307994169686],
						[61.426416700058908, 2.0668308001340039],
						[61.426433300040109, 2.0668308003718243],
						[61.426452684119489, 2.0668308008383831],
						[61.426474825598824, 2.0668694987332548],
						[61.426474862636745, 2.0668695356324194],
						[61.426474950019021, 2.0668695876492942],
						[61.426475000173006, 2.0668695990464236],
						[61.426491700211635, 2.0668695995750297],
						[61.426513899959701, 2.0668696007838183],
						[61.426533300174846, 2.0668695995556385],
						[61.426533308290161, 2.0668695996279784],
						[61.426533324425634, 2.0668695979123828],
						[61.426533331554658, 2.0668695963230954],
						[61.426550016226585, 2.0668669003106737],
						[61.426572199648739, 2.0668668995098542],
						[61.426591583728374, 2.0668668999932303],
						[61.426613725883442, 2.0669056995299271],
						[61.426613762921342, 2.0669057364292907],
						[61.426613850208433, 2.0669057865861822],
						[61.426613899566455, 2.0669058000423783],
						[61.426630599605282, 2.0669058005855168],
						[61.426649999820661, 2.0669057993751538],
						[61.426672073383628, 2.0669058005104399],
						[61.426691519168138, 2.0669472841144407],
						[61.426691561137488, 2.0669473293221499],
						[61.426691664144691, 2.0669473872638675],
						[61.426691725087402, 2.0669473981375512],
						[61.42671391324324, 2.0669446003962024],
						[61.426730471757445, 2.0669446005212007],
						[61.426749818953482, 2.0669861833439955],
						[61.426749856276849, 2.0669862258245142],
						[61.426749945821996, 2.0669862848860401],
						[61.426749999730816, 2.0669862992094159],
						[61.426772067852582, 2.066986299728788],
						[61.426788715554515, 2.0670250788703304],
						[61.426788752973032, 2.0670251232112684],
						[61.426788845286673, 2.067025183537309],
						[61.426788900181812, 2.0670251995224076],
						[61.426810972854774, 2.0670252009345527],
						[61.426830419194467, 2.0670667843845703],
						[61.426830463041199, 2.0670668310556399],
						[61.426830569517733, 2.0670668863430186],
						[61.426830633133804, 2.0670668966210188],
						[61.426847217165545, 2.0670641008153936],
						[61.426869399698433, 2.0670641003398553],
						[61.426888783779631, 2.0670641009338988],
						[61.426910925790565, 2.0671028991105671],
						[61.42691096282843, 2.067102936010496],
						[61.426911050115436, 2.067102986168377],
						[61.426911100364599, 2.0671029994263259],
						[61.426930599842798, 2.0671030006591229],
						[61.426947199730662, 2.0671029991638283],
						[61.426969400371917, 2.0671030003191504],
						[61.426988768222799, 2.0671030007885345],
						[61.427005415801034, 2.0671417783965471],
						[61.427005453219536, 2.0671418227379177],
						[61.427005544641986, 2.0671418832633468],
						[61.427005599537111, 2.0671418992487363],
						[61.427027800178735, 2.0671419004290694],
						[61.427047200396011, 2.0671418993495849],
						[61.427069267628049, 2.0671419001641218],
						[61.427085916183195, 2.0671806795527381],
						[61.427085952615421, 2.0671807222325933],
						[61.427086045024176, 2.0671807844199379],
						[61.427086099919286, 2.0671808004054326],
						[61.427105600194039, 2.0671807996208162],
						[61.427127668317546, 2.0671808002615601],
						[61.427144216217989, 2.0672194798784509],
						[61.427144252650187, 2.0672195225584304],
						[61.427144344963772, 2.0672195828856679],
						[61.427144399954038, 2.0672196007315806],
						[61.427169264747512, 2.0672196007370558],
						[61.427185913795732, 2.0672611742714317],
						[61.427185951309369, 2.0672612204735361],
						[61.427186043718088, 2.067261282661303],
						[61.427186099599474, 2.0672613003086542],
						[61.427205483587116, 2.0672612991522166],
						[61.427227625644541, 2.0673000997128939],
						[61.427227662682355, 2.0673001366134276],
						[61.427227749969333, 2.0673001867722918],
						[61.427227800218468, 2.0673002000306622],
						[61.42724428025538, 2.0673001990064761],
						[61.427266523762398, 2.0673417937059955],
						[61.427266567418741, 2.067341836657385],
						[61.427266669154051, 2.067341887358658],
						[61.427266729205606, 2.0673418984319007],
						[61.427285972905288, 2.0673391194388029],
						[61.427302613744338, 2.0673806743166439],
						[61.427302651257946, 2.0673807205190382],
						[61.427302743666637, 2.0673807827073718],
						[61.427302800439143, 2.0673808001563363],
						[61.427325000096729, 2.0673807998314593],
						[61.42734428412335, 2.067380800330485],
						[61.427366527306987, 2.0674194995693886],
						[61.427366563453603, 2.0674195366688615],
						[61.427366649849418, 2.0674195870269352],
						[61.427366700098545, 2.0674196002855263],
						[61.427385965126291, 2.067419599397569],
						[61.427402614119096, 2.0674611733245234],
						[61.427402656278616, 2.0674612222546083],
						[61.427402762244512, 2.0674612851842809],
						[61.427402825064561, 2.0674612975221978],
						[61.427424879790586, 2.0674585166259831],
						[61.427444218766723, 2.0675000836629143],
						[61.427444256089984, 2.0675001261449433],
						[61.427444345539747, 2.0675001833488063],
						[61.427444399543695, 2.0675001995336628],
						[61.4274665677275, 2.0675002006590084],
						[61.427483116037948, 2.0675389785921907],
						[61.427483117024217, 2.0675389802538939],
						[61.427483118105663, 2.0675389837759375],
						[61.427483119091946, 2.0675389854376398],
						[61.427502618897165, 2.0675806842039615],
						[61.427502656220405, 2.067580726686181],
						[61.427502745670147, 2.0675807838903588],
						[61.427502799674095, 2.0675808000753739],
						[61.427525000224783, 2.0675807996850897],
						[61.42752500655785, 2.0675808001548974],
						[61.427525020911084, 2.0675807988368602],
						[61.427525029026398, 2.0675807989093706],
						[61.427544414101909, 2.0675779998056569],
						[61.427566700261352, 2.0675779991433143],
						[61.427583173171193, 2.0675779998435977],
						[61.427602618773989, 2.0676194850570191],
						[61.427602656097228, 2.0676195275394127],
						[61.427602746438097, 2.0676195845452323],
						[61.427602800442031, 2.0676196007303611],
						[61.427625000101898, 2.0676196005617151],
						[61.42762500821722, 2.0676196006342336],
						[61.427625024257551, 2.0676195970585529],
						[61.427625032372887, 2.0676195971310856],
						[61.427641716077247, 2.0676168997250941],
						[61.427661100162993, 2.0676169006318266],
						[61.42768316819545, 2.0676168996955142],
						[61.427699815643471, 2.0676556784280939],
						[61.427699853061846, 2.0676557227710264],
						[61.427699945375267, 2.0676557831005367],
						[61.427700000270306, 2.0676557990871256],
						[61.427722200025677, 2.067655800800126],
						[61.427741700304395, 2.0676558002868943],
						[61.427758300196132, 2.0676557990579743],
						[61.427780599913447, 2.0676557991823099],
						[61.42778060713762, 2.0676557994534885],
						[61.427780620599712, 2.0676557983341195],
						[61.42778062871502, 2.0676557984066473],
						[61.427799890340644, 2.0676530174278938],
						[61.427822024042392, 2.0676944945587783],
						[61.427822060284164, 2.0676945335194614],
						[61.427822148557254, 2.0676945853420396],
						[61.427822199792686, 2.0676946002629371],
						[61.427838899741843, 2.0676945993264084],
						[61.427858300058794, 2.0676946004185601],
						[61.427858306296692, 2.0676945990280253],
						[61.427858317976515, 2.0676945983059665],
						[61.427858324309582, 2.0676945987758146],
						[61.427880612144591, 2.0676918996126727],
						[61.427897086837376, 2.0676918999650726],
						[61.427922029320548, 2.0677334021519402],
						[61.427922064575995, 2.0677334394510485],
						[61.427922150876554, 2.0677334879504206],
						[61.427922200139363, 2.0677334995479741],
						[61.427938900183968, 2.0677335004891706],
						[61.427938907408141, 2.0677335007603634],
						[61.427938920870218, 2.0677334996410215],
						[61.427938927999293, 2.06773349805183],
						[61.427958190214341, 2.0677308166939294],
						[61.427980423953343, 2.0677722943598535],
						[61.427980461086207, 2.0677723331221562],
						[61.427980549454468, 2.067772386805578],
						[61.427980599703567, 2.0677724000649382],
						[61.42799708429451, 2.0677724001317275],
						[61.428019226035047, 2.067811198293934],
						[61.428019263072734, 2.067811235195947],
						[61.428019350359534, 2.0678112853574122],
						[61.428019399812669, 2.0678113006758729],
						[61.428038900092595, 2.0678113002478709],
						[61.42805548384986, 2.0678113008067291],
						[61.428077625580741, 2.0678500990673534],
						[61.428077662618414, 2.0678501359694783],
						[61.428077749905199, 2.0678501861311349],
						[61.428077800154313, 2.0678501993906497],
						[61.4280972004725, 2.0678502005700272],
						[61.428119273057874, 2.0678502006460131],
						[61.428138719082497, 2.067891784460671],
						[61.428138756405644, 2.0678918269440905],
						[61.428138845855294, 2.0678918841502156],
						[61.428138899859206, 2.0678919003360345],
						[61.428155599809763, 2.0678918994942022],
						[61.428155606142795, 2.0678918999640823],
						[61.42815561871376, 2.0678918990434334],
						[61.428155624951692, 2.0678918976529124],
						[61.428177679689384, 2.0678891169590465],
						[61.428197019348083, 2.0679306831870741],
						[61.428197055780089, 2.0679307258692607],
						[61.428197146216007, 2.0679307847373609],
						[61.42819720012475, 2.067930799062863],
						[61.428213783882882, 2.0679307996798659],
						[61.428235925887471, 2.0679694984245582],
						[61.428235962925143, 2.0679695353269989],
						[61.428236050307028, 2.0679695873496176],
						[61.428236099665028, 2.067969600808016],
						[61.428255480093895, 2.0679696008443198],
						[61.428277622188453, 2.0680111916806023],
						[61.428297020837341, 2.0680500881231714],
						[61.428297058160425, 2.0680501306069967],
						[61.4282971465286, 2.0680501842916832],
						[61.428297199641385, 2.0680502006764798],
						[61.428313779740677, 2.0680502002877472],
						[61.428335921812554, 2.0680917912580803],
						[61.428355420592496, 2.0681306899590122],
						[61.428355457820409, 2.0681307305826047],
						[61.428355547079683, 2.0681307840689716],
						[61.42835560019244, 2.0681308004539369],
						[61.428377663774107, 2.0681308008428321],
						[61.428394213616592, 2.0681722743248714],
						[61.428394215589144, 2.0681722776484022],
						[61.428394218738291, 2.0681722863545242],
						[61.428394220710835, 2.068172289678059],
						[61.428413721346907, 2.0682111899644675],
						[61.428413757683686, 2.0682112307868463],
						[61.428413846942917, 2.0682112842735596],
						[61.428413900055673, 2.068211300658664],
						[61.428435972644593, 2.0682113009759164],
						[61.428455419459191, 2.0682528852345454],
						[61.428455461428172, 2.0682529304464468],
						[61.428455564339778, 2.0682529865350103],
						[61.428455625282425, 2.0682529974116459],
						[61.428477671024204, 2.0682502170583263],
						[61.42849421423081, 2.0682917731173771],
						[61.428494250853028, 2.0682918195211997],
						[61.428494344247646, 2.0682918833761854],
						[61.428494400033777, 2.0682918991655721],
						[61.42851390031781, 2.0682918990190542],
						[61.428535968451818, 2.0682919003819928],
						[61.428552615632576, 2.0683306786595996],
						[61.428552653050758, 2.0683307230045003],
						[61.428552745363938, 2.0683307833375211],
						[61.428552800258927, 2.0683307993256403],
						[61.428574872848962, 2.0683307997211617],
						[61.428594218986461, 2.0683722843645405],
						[61.428594262832817, 2.068372331039944],
						[61.428594370200074, 2.0683723861359358],
						[61.428594432924939, 2.0683723966155778],
						[61.428611116952503, 2.0683695996998521],
						[61.428635964636428, 2.0683696006002665],
						[61.428652614165955, 2.0684111746199241],
						[61.42865265069296, 2.0684112191636967],
						[61.428652744087522, 2.0684112830193442],
						[61.428652799968823, 2.068411300669422],
						[61.428674999636179, 2.0684113010162655],
						[61.428694268236626, 2.0684112998943487],
						[61.42871091627817, 2.0684500782309483],
						[61.428710952805183, 2.0684501227748417],
						[61.42871104511832, 2.0684501831084954],
						[61.428711100013331, 2.0684501990968638],
						[61.428733177155266, 2.06845020043695],
						[61.428752620646428, 2.0684889898457248],
						[61.428752657874284, 2.0684890304702148],
						[61.428752747228572, 2.0684890858186784],
						[61.42875280024618, 2.0684891003439678],
						[61.42877499991426, 2.06848910074162],
						[61.428791571901449, 2.0684891004971973],
						[61.428810919379139, 2.0685306830590564],
						[61.428810961443176, 2.0685307301322542],
						[61.428811064354718, 2.0685307862222979],
						[61.428811125297315, 2.0685307970995668],
						[61.42883331260677, 2.0685280003938682],
						[61.428849999990966, 2.0685280007892182],
						[61.428869400219732, 2.0685280004990285],
						[61.428891699850233, 2.0685279993355037],
						[61.428891707965555, 2.0685279994082397],
						[61.42889172499217, 2.0685279974946638],
						[61.428891733107498, 2.068527997567398],
						[61.42890819907182, 2.0685252191986105],
						[61.428933128298716, 2.0685668034161315],
						[61.428933165241084, 2.0685668384596272],
						[61.428933250650253, 2.0685668871613592],
						[61.428933300008183, 2.068566900620938],
						[61.428949999963592, 2.068566900113237],
						[61.428969400192635, 2.0685668998431979],
						[61.428991699918612, 2.0685669005633085],
						[61.428991708033941, 2.0685669006360512],
						[61.428991725060577, 2.0685668987224664],
						[61.428991733080714, 2.0685668969347759],
						[61.429008317055796, 2.0685640997963368],
						[61.429030471692236, 2.0685641008649864],
						[61.429049819152191, 2.068605683784225],
						[61.429049855584005, 2.0686057264683209],
						[61.429049945924554, 2.0686057834794012],
						[61.429049999928402, 2.0686057996668188],
						[61.429066699884061, 2.0686057991758835],
						[61.429066706217135, 2.0686057996458995],
						[61.429066718788086, 2.0686057987254998],
						[61.429066725026004, 2.0686057973350676],
						[61.429088913228341, 2.0686030004492424],
						[61.429108299900356, 2.0686029994546398],
						[61.429130599626589, 2.0686030001940967],
						[61.429147076999151, 2.06860300038942],
						[61.429166521346616, 2.0686417901964416],
						[61.429166557588132, 2.0686418291598083],
						[61.429166646942384, 2.0686418845093693],
						[61.429166700055099, 2.0686419008955248],
						[61.429188899629324, 2.0686418995223952],
						[61.429205599680309, 2.0686419009064134],
						[61.429227800145632, 2.0686418993328179],
						[61.429227807369813, 2.0686418996042333],
						[61.429227820831947, 2.0686418984851929],
						[61.429227828947269, 2.0686418985579516],
						[61.429247081687805, 2.0686391199864627],
						[61.429266519229017, 2.0686806850328576],
						[61.429266555660831, 2.0686807277173198],
						[61.429266646001338, 2.0686807847289606],
						[61.429266700005179, 2.068680800916614],
						[61.42928889957976, 2.0686807995665291],
						[61.429305484234206, 2.0686808003463653],
						[61.429327625957136, 2.068719499083596],
						[61.429327662994616, 2.0687195359881714],
						[61.429327750281153, 2.0687195861540704],
						[61.429327799639083, 2.0687195996140244],
						[61.429347199869234, 2.0687195994237468],
						[61.429363783632859, 2.0687196004208284],
						[61.429385925932941, 2.068758398854178],
						[61.429385962970429, 2.0687584357588458],
						[61.429386050256944, 2.0687584859249375],
						[61.429386099614867, 2.0687584993849715],
						[61.429405480050171, 2.068758499865504],
						[61.42942762291964, 2.0688000944226559],
						[61.429427660052248, 2.0688001331879051],
						[61.429427749311301, 2.068800186677854],
						[61.429427799560337, 2.0688001999393606],
						[61.429444400351201, 2.0688001990630567],
						[61.42944440668424, 2.068800199533118],
						[61.429444421037473, 2.068800198215508],
						[61.429444428261682, 2.06880019848695],
						[61.429463789083897, 2.0687974184751035],
						[61.429485922876239, 2.0688389944582992],
						[61.429485960008847, 2.0688390332236462],
						[61.429486049267872, 2.0688390867138162],
						[61.429486100408049, 2.0688390997767536],
						[61.429505468177915, 2.0688390993632315],
						[61.429522016058009, 2.0688778786402571],
						[61.429522052584886, 2.0688779231857652],
						[61.429522144897867, 2.0688779835221145],
						[61.429522199792842, 2.0688779995115834],
						[61.429544400355539, 2.0688779999520226],
						[61.429563784356347, 2.0688779997059821],
						[61.429585925725256, 2.0689167986933925],
						[61.429585962762729, 2.0689168355984853],
						[61.429586050049195, 2.0689168857653106],
						[61.429586100298216, 2.0689168990270383],
						[61.429602800351127, 2.0689169005446457],
						[61.429622199691785, 2.0689169006647252],
						[61.42964426496772, 2.0689168992942708],
						[61.42966091376681, 2.0689583753293084],
						[61.429660955830734, 2.0689584224043025],
						[61.429661060904941, 2.0689584855418217],
						[61.42966112382009, 2.0689584997438244],
						[61.429683311632495, 2.0689558009649422],
						[61.429702673070238, 2.0689558001458952],
						[61.429722018949008, 2.0689972847860609],
						[61.429722056271835, 2.0689973272728688],
						[61.429722145721094, 2.0689973844848164],
						[61.429722199724957, 2.0689974006731968],
						[61.429744400193584, 2.0689973993289694],
						[61.429744408308913, 2.068997399401832],
						[61.429744425335528, 2.0689973974884133],
						[61.429744433450864, 2.0689973975612568],
						[61.429761116507677, 2.0689945991741698],
						[61.429783172873023, 2.0689945998386094],
						[61.429802619385661, 2.0690361844854346],
						[61.429802663231854, 2.0690362311634769],
						[61.42980276970772, 2.0690362864622833],
						[61.429802833323734, 2.0690362967448741],
						[61.429819291280445, 2.0690335203191057],
						[61.429841523985623, 2.0690750936890994],
						[61.429841560227054, 2.0690751326537891],
						[61.429841648594881, 2.0690751863437891],
						[61.429841699735022, 2.0690751994072345],
						[61.429861099968079, 2.0690751994175844],
						[61.429883168014037, 2.0690751994128505],
						[61.429899815892597, 2.0691139795555928],
						[61.429899853215396, 2.0691140220427715],
						[61.429899944732306, 2.069114084439609],
						[61.429899999627246, 2.0691141004296298],
						[61.429919280008228, 2.0691141008319396],
						[61.429941523635556, 2.0691556945310738],
						[61.429941567196281, 2.0691557356279846],
						[61.429941669026206, 2.0691557881995286],
						[61.429941728982527, 2.0691557974163954],
						[61.429960989757006, 2.0691530172818093],
						[61.42998312375834, 2.0691944943669216],
						[61.429983159999729, 2.0691945333319191],
						[61.42998324925869, 2.0691945868238508],
						[61.429983300398803, 2.069194599887537],
						[61.429999872390788, 2.0691945999765733],
						[61.430019218790932, 2.0692361847537746],
						[61.43001926075965, 2.0692362299691696],
						[61.4300193637661, 2.0692362879239696],
						[61.430019424708668, 2.0692362988029083],
						[61.430041571330776, 2.0692335171523828],
						[61.430058114179211, 2.0692750739254375],
						[61.430058150801138, 2.0692751203326676],
						[61.430058244100216, 2.0692751823330298],
						[61.43005829998144, 2.0692751999852814],
						[61.430080476201887, 2.0692752004652246],
						[61.430099821118937, 2.0693139894906061],
						[61.430099858346551, 2.0693140301177584],
						[61.430099946809456, 2.0693140856693653],
						[61.430099999826986, 2.0693141001965487],
						[61.430119280209624, 2.0693141007146094],
						[61.430141523781636, 2.0693556948183782],
						[61.430141560023003, 2.0693557337837505],
						[61.430141649186723, 2.0693557854158868],
						[61.430141700421999, 2.0693558003403933],
						[61.430158176813556, 2.0693557992372291],
						[61.43017762118059, 2.0693944890866827],
						[61.430177658408184, 2.0693945297140437],
						[61.430177747762201, 2.0693945850673692],
						[61.430177799888604, 2.0693945997933532],
						[61.430199867937638, 2.0693946000003129],
						[61.430216515638037, 2.0694333788425308],
						[61.430216518691978, 2.0694333856887641],
						[61.430216524704676, 2.069433397520704],
						[61.430216527663497, 2.0694334025064194],
						[61.430241520374466, 2.0694750897294916],
						[61.430258113859914, 2.0695167748421945],
						[61.430258115832437, 2.0695167781660064],
						[61.43025811898147, 2.0695167868727848],
						[61.430258120954001, 2.0695167901966149],
						[61.430277621060469, 2.0695556898809455],
						[61.430277622046724, 2.0695556915428712],
						[61.430277623032985, 2.0695556932047769],
						[61.43027762401924, 2.0695556948666853],
						[61.430299817749507, 2.0695972853915205],
						[61.430316514120477, 2.0696389740532717],
						[61.430316556279315, 2.0696390229906618],
						[61.430316662149259, 2.0696390840725258],
						[61.430316725064365, 2.069639098276137],
						[61.430338779756383, 2.0696363166639302],
						[61.430358118842591, 2.0696778849481374],
						[61.430358118937733, 2.0696778868086643],
						[61.430358120815164, 2.0696778882719595],
						[61.430358120910284, 2.0696778901325046],
						[61.430377620973871, 2.0697167900415625],
						[61.430377621960147, 2.0697167917034762],
						[61.430377622946388, 2.0697167933653988],
						[61.430377623837529, 2.0697167931667857],
						[61.430399817617158, 2.0697583857990791],
						[61.430416513941886, 2.0698000746807934],
						[61.430416517982067, 2.069800083189028],
						[61.430416526953508, 2.069800100006884],
						[61.430416531884816, 2.0698001083165116],
						[61.430441520783127, 2.0698389912537287],
						[61.430458113973359, 2.0698806731072965],
						[61.430458115054719, 2.0698806766297606],
						[61.430458118013512, 2.0698806816155586],
						[61.43045811909488, 2.0698806851380263],
						[61.430477619265943, 2.0699222855593633],
						[61.43047762123846, 2.0699222888832227],
						[61.430477624292372, 2.0699222957295631],
						[61.430477626264882, 2.0699222990534238],
						[61.430499818648094, 2.0699611863194964],
						[61.430516513927813, 2.0700028737824354],
						[61.430516517967959, 2.0700028822907295],
						[61.4305165269394, 2.070002899108693],
						[61.430516531870708, 2.0700029074183779],
						[61.430541520810252, 2.0700417925022681],
						[61.430558114247013, 2.07008337479783],
						[61.430558115233239, 2.0700833764597926],
						[61.430558118192032, 2.0700833814456012],
						[61.430558119273392, 2.070083384968104],
						[61.430577619080871, 2.0701250854504507],
						[61.430577621053374, 2.0701250887743354],
						[61.430577624107293, 2.0701250956207118],
						[61.43057762607981, 2.0701250989445965],
						[61.430599819299964, 2.0701639862806007],
						[61.43061651362985, 2.070205674185277],
						[61.430616517670003, 2.0702056826936222],
						[61.430616526641408, 2.0702056995117011],
						[61.43061653157271, 2.0702057078214455],
						[61.430641520668594, 2.0702444915301701],
						[61.430658113741423, 2.0702861738686411],
						[61.430658114822769, 2.0702861773911643],
						[61.430658117781554, 2.0702861823770125],
						[61.430658118862915, 2.0702861858995329],
						[61.430677619407938, 2.0703278845835946],
						[61.430677621380461, 2.0703278879074967],
						[61.430677623638324, 2.0703278968130698],
						[61.430677625515706, 2.0703278982764277],
						[61.43069982568062, 2.070366799375214],
						[61.430699862717717, 2.0703668362832901],
						[61.430699950003728, 2.0703668864558114],
						[61.430700000252664, 2.0703668997202413],
						[61.430716700221318, 2.070366900115943],
						[61.430738776300387, 2.0703668991995987],
						[61.430758120927372, 2.0704056897167118],
						[61.430758158154703, 2.0704057303460144],
						[61.430758246617209, 2.0704057859017877],
						[61.43075829963464, 2.0704058004310935],
						[61.430777799841565, 2.070405799655175],
						[61.430799865132258, 2.0704057992861875],
						[61.430816514306571, 2.0704472755864454],
						[61.430816550833001, 2.0704473201355769],
						[61.43081664413166, 2.0704473821408413],
						[61.43081670001272, 2.0704473997955732],
						[61.430836100257807, 2.0704474006211218],
						[61.430836105604598, 2.0704473994295589],
						[61.430836119066754, 2.0704473983111931],
						[61.430836125399772, 2.0704473987815986],
						[61.430858172000434, 2.0704446175971523],
						[61.430874814465696, 2.0704861745214211],
						[61.430874850992126, 2.0704862190706783],
						[61.430874944385842, 2.0704862829367343],
						[61.430875000266944, 2.0704863005915528],
						[61.430897199859267, 2.0704863004536622],
						[61.43089720708349, 2.0704863007254661],
						[61.430897221436723, 2.0704862994085516],
						[61.43089722767467, 2.0704862980183942],
						[61.43091671377838, 2.0704835003635518],
						[61.430933180280739, 2.0704835001542197],
						[61.430955424318476, 2.0705250941065767],
						[61.430955459668382, 2.0705251332728878],
						[61.430955548926889, 2.0705251867701571],
						[61.430955600066923, 2.0705251998363394],
						[61.430975000312607, 2.0705252007047354],
						[61.43097500565942, 2.0705251995131686],
						[61.430975019121554, 2.0705251983948365],
						[61.430975025359501, 2.0705251970046801],
						[61.430997081063822, 2.0705224175915955],
						[61.431016518970665, 2.0705639859168858],
						[61.431016556197953, 2.0705640265466974],
						[61.431016645646636, 2.0705640837653214],
						[61.431016699650343, 2.0705640999569597],
						[61.431033184166196, 2.0705640995360817],
						[61.431055425950511, 2.0706028997402539],
						[61.431055462987551, 2.0706029366490166],
						[61.431055550273534, 2.0706029868226801],
						[61.431055599631293, 2.0706030002862232],
						[61.431074879930009, 2.0706029997030844],
						[61.43109702418343, 2.0706444938402666],
						[61.431097060424428, 2.0706445328082945],
						[61.431097148791736, 2.0706445865047205],
						[61.431097199931799, 2.070644599571136],
						[61.431113776390212, 2.0706445994001874],
						[61.431133120851989, 2.0706833886862865],
						[61.431133158079277, 2.0706834293163801],
						[61.431133247432811, 2.070683484674964],
						[61.4311332995591, 2.0706834994034637],
						[61.431155600101775, 2.070683499479006],
						[61.43117220001534, 2.0706834997450976],
						[61.431172206348357, 2.0706835002155524],
						[61.431172218028259, 2.0706834994944634],
						[61.431172224266163, 2.0706834981043367],
						[61.431194412127923, 2.0706808003510377],
						[61.431213899766078, 2.0706808006491886],
						[61.431230599641779, 2.0706807993346708],
						[61.431252800127012, 2.0706807991203022],
						[61.43127220037389, 2.0706808000735686],
						[61.431272205815802, 2.0706808007426085],
						[61.431272219277957, 2.0706807996243159],
						[61.431272224624763, 2.0706807984327695],
						[61.431294412791864, 2.0706780008613879],
						[61.431310973019777, 2.0706780005548242],
						[61.431330419177236, 2.0707194847401702],
						[61.431330455608474, 2.0707195274297341],
						[61.431330545948263, 2.0707195844507309],
						[61.431330599951934, 2.070719600642775],
						[61.431352800437487, 2.0707196004514161],
						[61.431372199698416, 2.0707195997627745],
						[61.431372206031448, 2.0707196002332515],
						[61.431372217711349, 2.0707195995121515],
						[61.431372223949253, 2.0707195981220186],
						[61.431394424382681, 2.0707168994481053],
						[61.431394472919507, 2.0707168792225872],
						[61.431394554403361, 2.0707168159006542],
						[61.431394585568192, 2.070716773201422],
						[61.43141123496536, 2.0706751992473187],
						[61.431427799743972, 2.0706751998028414],
						[61.431452799671199, 2.0706752004643252],
						[61.431452807786542, 2.070675200537583],
						[61.431452824813228, 2.0706751986249063],
						[61.431452832833443, 2.0706751968375676],
						[61.431469416863713, 2.0706724005125023],
						[61.431486099617366, 2.0706723992366176],
						[61.431511099544558, 2.0706723998927896],
						[61.431527800406229, 2.0706724002253738],
						[61.43154999999998, 2.0706724001897894],
						[61.431550055856079, 2.0706723820965598],
						[61.431550149114926, 2.070672319913661],
						[61.431550185626485, 2.0706722760225929],
						[61.43156684266107, 2.0706307810441489],
						[61.431586129413951, 2.0706279977105564],
						[61.431586171807936, 2.0706279807355319],
						[61.431586245747155, 2.0706279285033387],
						[61.431586277292368, 2.0706278932461943],
						[61.431608476715049, 2.0705861942535355],
						[61.431608479103112, 2.0705861880759211],
						[61.431608484069436, 2.0705861794419023],
						[61.431608485566343, 2.0705861734628908],
						[61.431625182263524, 2.0705444850976935],
						[61.431647376387041, 2.0705028944313932],
						[61.431647378074182, 2.0705028921735709],
						[61.431647379571089, 2.0705028861945474],
						[61.431647381353358, 2.0705028857973518],
						[61.431666881255261, 2.070461184186958],
						[61.431666882051303, 2.0704611821277421],
						[61.431666885425592, 2.0704611776121218],
						[61.431666886126507, 2.0704611736922813],
						[61.431683481834618, 2.0704194850984621],
						[61.431705775974599, 2.0703778945473537],
						[61.431705779253768, 2.0703778881711066],
						[61.431705784220071, 2.0703778795370278],
						[61.431705785717007, 2.0703778735579603],
						[61.431722382289223, 2.0703361846693857],
						[61.431744576650004, 2.0702944939201768],
						[61.431744578337138, 2.0702944916623367],
						[61.431744579929195, 2.0702944875438898],
						[61.431744580725194, 2.0702944854846748],
						[61.43176408124004, 2.0702528850802033],
						[61.431764081940948, 2.0702528811603651],
						[61.431764085315237, 2.0702528766446893],
						[61.431764086111265, 2.0702528745854627],
						[61.431780781713918, 2.0702111841724982],
						[61.43180297702002, 2.0701694949177947],
						[61.431802979408069, 2.0701694887401105],
						[61.431802984279244, 2.0701694782453557],
						[61.431802985871265, 2.0701694741268835],
						[61.431819582070574, 2.0701278848024494],
						[61.431841876420449, 2.0700861940557003],
						[61.431841878107598, 2.0700861917978388],
						[61.431841879699604, 2.0700861876793772],
						[61.431841881291625, 2.0700861835609161],
						[61.431861227607627, 2.0700446003123014],
						[61.431877799605125, 2.0700446007884925],
						[61.431877851100566, 2.0700445855476115],
						[61.431877940379046, 2.0700445336583244],
						[61.431877976189504, 2.0700444936859013],
						[61.431900176006657, 2.0700028934910835],
						[61.431900179380932, 2.0700028889753792],
						[61.431900183360987, 2.0700028786791713],
						[61.431900185844135, 2.0700028743620611],
						[61.431916882256317, 2.0699611834344966],
						[61.431939076589032, 2.0699194940167196],
						[61.431939078276173, 2.0699194917588382],
						[61.431939079868187, 2.0699194876403579],
						[61.431939080664201, 2.0699194855811132],
						[61.43195848099927, 2.0698778844008503],
						[61.431958481795299, 2.0698778823415993],
						[61.431958485169559, 2.069877877825856],
						[61.431958485870467, 2.0698778739059835],
						[61.431975182337169, 2.0698361846944362],
						[61.431997328938643, 2.069794584775857],
						[61.432016727002768, 2.0697918983730981],
						[61.43201677117888, 2.0697918809994249],
						[61.432016846008842, 2.0697918285658616],
						[61.432016876662715, 2.0697917935059849],
						[61.432039076701791, 2.0697500931388135],
						[61.432039079184911, 2.0697500888216904],
						[61.432039084056044, 2.0697500783267868],
						[61.432039085648078, 2.0697500742082737],
						[61.432055734823322, 2.0697085007343721],
						[61.432072200427619, 2.0697085002745879],
						[61.432094400010584, 2.0697084995265573],
						[61.432094454179399, 2.0697084836891109],
						[61.432094544158609, 2.0697084278785542],
						[61.432094580764961, 2.0697083858462193],
						[61.432114026613753, 2.0696669002475869],
						[61.432130600390309, 2.0696669001167627],
						[61.432130605832228, 2.0696669007856334],
						[61.432130619294348, 2.0696668996668732],
						[61.432130624641161, 2.069666898475103],
						[61.43215282525049, 2.0696640972848823],
						[61.432152871208842, 2.0696640795136849],
						[61.432152950304157, 2.0696640223651563],
						[61.43215298076769, 2.0696639835837143],
						[61.432172327835112, 2.0696223994545919],
						[61.43219440043557, 2.0696224005982979],
						[61.432211100397211, 2.0696224005425043],
						[61.432230599704468, 2.0696223994152967],
						[61.432230651295029, 2.0696223860341401],
						[61.432230739587034, 2.0696223324809941],
						[61.432230776383648, 2.0696222941696347],
						[61.432252920219142, 2.0695807997336426],
						[61.432269400270691, 2.0695807997427176],
						[61.43226940571261, 2.0695808004115932],
						[61.432269419174723, 2.0695807992927664],
						[61.432269425412656, 2.0695807979023502],
						[61.432291725185102, 2.0695779971102217],
						[61.432291771143468, 2.0695779793388001],
						[61.43229185023872, 2.0695779221897999],
						[61.432291880702252, 2.0695778834080705],
						[61.432311227834759, 2.0695363008967251],
						[61.432327799732739, 2.069536299226165],
						[61.432350000299877, 2.0695363000055211],
						[61.432350053577551, 2.0695362843662264],
						[61.432350144352625, 2.0695362264954227],
						[61.432350180958956, 2.0695361844624967],
						[61.432369527981947, 2.069494599985219],
						[61.432391699651866, 2.0694945996465979],
						[61.432391707767174, 2.0694945997195369],
						[61.432391723902661, 2.0694945980047694],
						[61.432391731922849, 2.0694945962170577],
						[61.432408315707953, 2.0694919008149677],
						[61.432427799674343, 2.0694918992534741],
						[61.432427851264848, 2.0694918858720022],
						[61.432427939556788, 2.0694918323180951],
						[61.432427977244458, 2.0694917938076989],
						[61.432450119557053, 2.0694501996003423],
						[61.432466699664694, 2.0694501998127364],
						[61.432488900230801, 2.0694502005242352],
						[61.43250830046523, 2.0694502006606528],
						[61.432508307689417, 2.0694502009322324],
						[61.432508324620947, 2.0694501971581265],
						[61.432508332736248, 2.0694501972310557],
						[61.43252503282708, 2.0694473968275431],
						[61.432525075220873, 2.0694473798503243],
						[61.432525147377262, 2.0694473280109409],
						[61.432525176248774, 2.0694472933473791],
						[61.432547320117919, 2.0694058002451681],
						[61.432563900225155, 2.0694058004305269],
						[61.432588900136004, 2.0694058000134552],
						[61.43260560009562, 2.06940579982771],
						[61.432622199997994, 2.0694057993623263],
						[61.432622253370788, 2.0694057855833048],
						[61.432622341567544, 2.0694057301681426],
						[61.432622379160058, 2.0694056897967235],
						[61.432644523637762, 2.0693613001909026],
						[61.432663900416422, 2.0693612998482562],
						[61.432680600375619, 2.0693612996360233],
						[61.432702800049462, 2.0693613004728566],
						[61.432722200282896, 2.0693613005452005],
						[61.432722250887146, 2.069361285501321],
						[61.432722340165306, 2.0693612336085279],
						[61.432722376866657, 2.0693611934355642],
						[61.432744529955301, 2.0693195812633354],
						[61.432761116278151, 2.0693169002135745],
						[61.432780600337743, 2.0693169003888405],
						[61.432802799915883, 2.0693168993287068],
						[61.432819399912525, 2.0693169006697518],
						[61.432841699547872, 2.0693168998865157],
						[61.432841706772066, 2.0693169001580589],
						[61.432841721125328, 2.0693168988404378],
						[61.432841729145515, 2.0693168970526461],
						[61.432861128709987, 2.0693140988765886],
						[61.432861176355402, 2.0693140788464426],
						[61.432861255165122, 2.0693140161135739],
						[61.43286128632942, 2.0693139734108721],
						[61.432877935445781, 2.0692724004468377],
						[61.432899999831427, 2.0692723993763154],
						[61.432919400063881, 2.0692723993852593],
						[61.43294169979378, 2.0692724004261831],
						[61.432941755649651, 2.0692723823294639],
						[61.43294184890776, 2.0692723201393597],
						[61.432941886214813, 2.0692722741853022],
						[61.432958443009717, 2.0692307818865006],
						[61.432977827992957, 2.0692279981827877],
						[61.432977872168976, 2.0692279808076912],
						[61.432977946107535, 2.0692279285696835],
						[61.432977976761244, 2.0692278935081005],
						[61.433000119964909, 2.0691863000202595],
						[61.433016700070084, 2.0691863000730257],
						[61.433038899741717, 2.0691863007694917],
						[61.433058299973254, 2.0691863007191538],
						[61.433058355829147, 2.0691862826222112],
						[61.43305844908717, 2.0691862204316034],
						[61.433058486394188, 2.0691861744772475],
						[61.433075135371169, 2.0691445993166062],
						[61.433097199850408, 2.0691446000071001],
						[61.433097207074617, 2.0691446002785816],
						[61.433097221427829, 2.0691445989608717],
						[61.433097226774642, 2.069144597768962],
						[61.433116714406374, 2.0691419002609837],
						[61.433138899629078, 2.0691419003781562],
						[61.433138956376077, 2.0691418820824312],
						[61.433139048742959, 2.0691418200901643],
						[61.433139086049962, 2.0691417741356313],
						[61.433155735106467, 2.0691002007024073],
						[61.433172199814088, 2.0691002000816727],
						[61.433172206147162, 2.0691002005517993],
						[61.433172216840759, 2.0691001981679671],
						[61.433172222282664, 2.0691001988367512],
						[61.433197222406967, 2.0690973996988276],
						[61.433197270943502, 2.0690973794694481],
						[61.433197350834533, 2.0690973202580545],
						[61.433197383876134, 2.0690972790180466],
						[61.433214038082212, 2.0690584857903138],
						[61.43323611214533, 2.0690558006743549],
						[61.43325280032672, 2.069055799139373],
						[61.433272199665765, 2.0690557991976855],
						[61.433272251256241, 2.069055785815034],
						[61.433272339547983, 2.0690557322583074],
						[61.433272377235518, 2.0690556937462778],
						[61.433294520378446, 2.0690140997325353],
						[61.43331109959091, 2.0690140998820503],
						[61.43333059987787, 2.0690140999910112],
						[61.433330651468317, 2.0690140866082629],
						[61.433330739760031, 2.0690140330513112],
						[61.433330777447544, 2.0690139945391568],
						[61.433352930413349, 2.0689723812856173],
						[61.433369416972454, 2.0689695998909094],
						[61.433391700468093, 2.068969600548388],
						[61.433411099806186, 2.0689696005473843],
						[61.433427799761745, 2.0689696000989231],
						[61.433427806094798, 2.0689696005690426],
						[61.43342781777465, 2.0689695998471964],
						[61.433427824012568, 2.0689695984565875],
						[61.433450011817008, 2.0689668991840984],
						[61.433469400366327, 2.068966899702215],
						[61.433491700092461, 2.0689669004986704],
						[61.43350829999072, 2.0689668997684962],
						[61.433527800277083, 2.0689668998398445],
						[61.43352780571901, 2.0689669005086224],
						[61.433527819181101, 2.0689668993894568],
						[61.43352782541902, 2.0689668979988411],
						[61.433550012731466, 2.0689641009604482],
						[61.433566580168375, 2.0689640999356103],
						[61.43358872277097, 2.0690056944261137],
						[61.433588759903607, 2.0690057331964513],
						[61.433588849162689, 2.0690057866933595],
						[61.433588900302844, 2.0690057997578783],
						[61.433608299641165, 2.0690057997713875],
						[61.433608307756437, 2.0690057998441906],
						[61.433608324783094, 2.0690057979303904],
						[61.433608332803281, 2.0690057961424615],
						[61.433625016933888, 2.0690030008004148],
						[61.43364720027725, 2.0690029993316075],
						[61.43366669967272, 2.0690029996185508],
						[61.433688900232944, 2.0690029999554693],
						[61.433688907457146, 2.0690030002269162],
						[61.433688920919252, 2.0690029991077528],
						[61.433688928939439, 2.0690029973198167],
						[61.433708314139452, 2.0690002001558989],
						[61.433724999646685, 2.0690001991705653],
						[61.433747073130242, 2.0690001996028551],
						[61.433766518635849, 2.0690417858625758],
						[61.433766561400702, 2.069041829023412],
						[61.433766664407301, 2.0690418869837468],
						[61.433766725349912, 2.0690418978631309],
						[61.433788912664149, 2.0690391008455191],
						[61.433805599953899, 2.0690390994808752],
						[61.433825000183461, 2.0690390993076897],
						[61.433847068225404, 2.0690390990941387],
						[61.433863715899697, 2.0690778784330699],
						[61.43386371796737, 2.0690778836179549],
						[61.433863721912459, 2.0690778902662763],
						[61.433863723885025, 2.0690778935904244],
						[61.433885920593475, 2.069119489652977],
						[61.433905419385155, 2.0691611847279709],
						[61.433905463231369, 2.0691612314119761],
						[61.433905569707321, 2.0691612867175739],
						[61.43390563332332, 2.0691612970012327],
						[61.433922099390671, 2.0691585202069422],
						[61.433947028050476, 2.0692001020743227],
						[61.433947064992765, 2.0692001371238389],
						[61.433947151388125, 2.0692001874963455],
						[61.43394719975975, 2.0692001992965015],
						[61.433963771749063, 2.0692001992509454],
						[61.433983118829296, 2.0692417833838443],
						[61.433983160893227, 2.0692418304654003],
						[61.433983263804613, 2.0692418865659996],
						[61.433983324747196, 2.0692418974458264],
						[61.434005470564593, 2.0692391173207092],
						[61.4340220140935, 2.0692806734686897],
						[61.434022050715505, 2.0692807198815117],
						[61.434022144109818, 2.069280783749758],
						[61.434022199895935, 2.0692807995430735],
						[61.4340442769467, 2.0692807993659277],
						[61.434063721213271, 2.0693194901158547],
						[61.434063758440992, 2.0693195307478853],
						[61.434063847699946, 2.069319584246434],
						[61.434063899921512, 2.0693196008344548],
						[61.434080487156066, 2.069319599332037],
						[61.434105427701802, 2.069361203340792],
						[61.434105464644105, 2.0693612383906541],
						[61.43410555094426, 2.0693612869030922],
						[61.434105600302132, 2.0693613003656446],
						[61.434122083915213, 2.0693612993999957],
						[61.434144225837684, 2.0694000984274079],
						[61.434144262875101, 2.0694001353381131],
						[61.43414435016151, 2.0694001855128161],
						[61.434144399614503, 2.0694002008362133],
						[61.43416376827701, 2.0694002003840044],
						[61.43418041576161, 2.0694389784453175],
						[61.434180453179536, 2.0694390227991506],
						[61.434180544601297, 2.0694390833439518],
						[61.434180600387364, 2.0694390991376226],
						[61.434202672092887, 2.0694391002572901],
						[61.43422201910677, 2.0694806848527589],
						[61.434222061075488, 2.0694807300742051],
						[61.434222163986774, 2.0694807861760016],
						[61.43422222502452, 2.0694807989171018],
						[61.434244413147418, 2.0694780000901498],
						[61.434261099644388, 2.0694780010041902],
						[61.434280599839809, 2.0694779995041301],
						[61.434302800404531, 2.0694780001511575],
						[61.434319400306315, 2.0694779996652373],
						[61.434319405748248, 2.0694780003340951],
						[61.434319417428121, 2.0694779996123902],
						[61.434319421883743, 2.0694779986191416],
						[61.434344271451316, 2.0694752162473291],
						[61.434360914082518, 2.069516773443596],
						[61.434360950704402, 2.0695168198571916],
						[61.434361044098672, 2.0695168837267683],
						[61.434361099884732, 2.0695168995206488],
						[61.434380600175579, 2.0695168999016103],
						[61.434402799849515, 2.0695169007703882],
						[61.434419284354753, 2.0695168996781912],
						[61.434441526296929, 2.0695556994548596],
						[61.434441563334282, 2.069555736366087],
						[61.434441649729521, 2.0695557867403029],
						[61.434441699978535, 2.0695558000046383],
						[61.434461100212395, 2.0695558001275121],
						[61.434477800171962, 2.0695557999566994],
						[61.434477805613881, 2.0695558006255941],
						[61.434477819075994, 2.0695557995066136],
						[61.434477825313927, 2.0695557981160611],
						[61.434499880089149, 2.0695530177418284],
						[61.43451921920407, 2.0695944841027063],
						[61.434519255635671, 2.0695945267950515],
						[61.434519345975971, 2.0695945838180054],
						[61.434519399979763, 2.0695946000093857],
						[61.434541699616538, 2.0695945993455855],
						[61.434558299614196, 2.0695946007742996],
						[61.43455830674327, 2.0695945991850979],
						[61.434558321191666, 2.069594599728275],
						[61.434558327429556, 2.069594598337734],
						[61.434577688850986, 2.0695919177695021],
						[61.434599824212377, 2.0696333932704873],
						[61.434599859562589, 2.0696334324400802],
						[61.434599948821493, 2.0696334859403489],
						[61.434600000056733, 2.0696335008669822],
						[61.434616571953931, 2.0696334991685985],
						[61.434635918917998, 2.0696750844247531],
						[61.434635960886666, 2.069675129647031],
						[61.434636063893038, 2.0696751876109127],
						[61.43463612483562, 2.0696751984917121],
						[61.434658181396465, 2.069672417783146],
						[61.43467761934042, 2.0697139847169153],
						[61.43467762131295, 2.069713988041193],
						[61.434677624461976, 2.0697139967492002],
						[61.434677626339372, 2.0697139982126935],
						[61.434699825568231, 2.0697528982898743],
						[61.434699862605541, 2.0697529352016306],
						[61.434699949891844, 2.0697529853781256],
						[61.434700000235964, 2.0697530005036335],
						[61.434716573120312, 2.0697530005275304],
						[61.434735919369373, 2.0697944845137934],
						[61.434735955800946, 2.0697945272066667],
						[61.434736046141175, 2.0697945842305456],
						[61.434736100144946, 2.0697946004223606],
						[61.434758299726397, 2.0697945996118192],
						[61.434774890623913, 2.069794599404494],
						[61.434799832260019, 2.0698334077682325],
						[61.434799868215947, 2.0698334411572463],
						[61.434799951747443, 2.0698334884071041],
						[61.434800000119033, 2.069833500208484],
						[61.43481670008056, 2.0698335001747794],
						[61.434835984028801, 2.0698335000294645],
						[61.434858125738884, 2.0698722983971183],
						[61.434858162776152, 2.0698723353091952],
						[61.434858250062426, 2.0698723854862515],
						[61.434858300406525, 2.0698724006120157],
						[61.434875000368365, 2.0698724005971258],
						[61.434897072873845, 2.0698723999324304],
						[61.434916518843359, 2.0699139843230965],
						[61.434916562689352, 2.0699140310096174],
						[61.43491667005619, 2.0699140861207344],
						[61.434916732781012, 2.0699140966047813],
						[61.434933316782555, 2.0699112994439957],
						[61.434955600286806, 2.0699113007024783],
						[61.434974999537317, 2.0699112993638105],
						[61.434997067587481, 2.0699112997152604],
						[61.435013715836241, 2.0699500788694456],
						[61.43501375325404, 2.069950123225043],
						[61.435013844675602, 2.0699501837728027],
						[61.435013899570471, 2.069950199766367],
						[61.435033299807557, 2.0699502001117307],
						[61.435055600338799, 2.0699501994799667],
						[61.43507220024383, 2.0699501992196065],
						[61.435072205685742, 2.0699501998885563],
						[61.435072217365594, 2.0699501991669984],
						[61.435072221821272, 2.0699501981737773],
						[61.435097071402367, 2.0699474160717393],
						[61.435113713907327, 2.0699889745082909],
						[61.435113751420225, 2.0699890207248859],
						[61.435113843828034, 2.0699890829351255],
						[61.435113899709172, 2.0699891005909659],
						[61.435133299851422, 2.0699890990956886],
						[61.435133306184468, 2.0699890995660017],
						[61.435133318755469, 2.0699890986458103],
						[61.435133324993402, 2.0699890972553074],
						[61.435155613282539, 2.0699863008068942],
						[61.435172199630557, 2.0699862998202998],
						[61.435194273123251, 2.0699863008847692],
						[61.43521371906462, 2.0700278857336687],
						[61.43521375629215, 2.0700279263679753],
						[61.435213845741174, 2.0700279835919648],
						[61.435213899744909, 2.0700279997843842],
						[61.435230599707722, 2.0700279998405224],
						[61.43523060604079, 2.07002800031084],
						[61.43523061861179, 2.0700279993906583],
						[61.435230624849737, 2.0700279980001572],
						[61.435252812987471, 2.0700251994249057],
						[61.435272065462129, 2.0700252007507451],
						[61.435288714055034, 2.0700667731526714],
						[61.435288750676797, 2.0700668195682295],
						[61.43528884407084, 2.0700668834412155],
						[61.435288899856872, 2.0700668992364273],
						[61.435310976914828, 2.0700668995580775],
						[61.435330420587071, 2.0701056902909047],
						[61.435330457814587, 2.0701057309254463],
						[61.435330547073306, 2.0701057844282076],
						[61.435330600185921, 2.0701058008194297],
						[61.43535267625802, 2.0701057995045455],
						[61.435372017019013, 2.0701444815076053],
						[61.435388714025265, 2.0701861731670488],
						[61.435388750647, 2.0701862195829079],
						[61.435388844040958, 2.0701862834564304],
						[61.435388899826997, 2.0701862992519113],
						[61.435410973321609, 2.0701863004481385],
						[61.435430419207755, 2.0702278857065446],
						[61.435430461081133, 2.0702279290698344],
						[61.435430564087305, 2.0702279870368723],
						[61.435430625029888, 2.070227997918928],
						[61.435452670817362, 2.0702252180164042],
						[61.435469213985087, 2.0702667747285006],
						[61.435469251402779, 2.0702668190850861],
						[61.435469343905609, 2.0702668831576356],
						[61.435469399786761, 2.0702669008141239],
						[61.435488783700649, 2.0702668993312945],
						[61.435510926194155, 2.0703056985997383],
						[61.435510963231344, 2.0703057355130765],
						[61.435511049626349, 2.0703057858909268],
						[61.435511099875299, 2.070305799156805],
						[61.435530480320104, 2.0703058003518846],
						[61.435552624460563, 2.0703472944202872],
						[61.435552659810611, 2.0703473335918328],
						[61.435552748974153, 2.0703473852347885],
						[61.435552800209344, 2.0703474001629729],
						[61.435569276605101, 2.0703473993975838],
						[61.435588721095542, 2.070386190423446],
						[61.43558875822783, 2.0703862291977932],
						[61.43558884669045, 2.0703862847612551],
						[61.43558889970793, 2.0703862992922217],
						[61.435610967762578, 2.0703862999519829],
						[61.435627615795354, 2.0704250782513309],
						[61.435627653213004, 2.0704251226083215],
						[61.435627744634367, 2.0704251831585925],
						[61.435627799624363, 2.0704252010140753],
						[61.435650000102825, 2.0704252004130148],
						[61.43566927317206, 2.0704251992624467],
						[61.435688718992189, 2.0704667850092822],
						[61.43568875631474, 2.0704668275055513],
						[61.435688845763579, 2.0704668847316032],
						[61.435688899767293, 2.0704669009249774],
						[61.435711100246152, 2.0704669003508265],
						[61.435711108361481, 2.0704669004239671],
						[61.43571112538816, 2.0704668985107642],
						[61.435711133408375, 2.0704668967230555],
						[61.435727690559915, 2.0704641208015215],
						[61.435749823313046, 2.0705056930503529],
						[61.43574985964932, 2.0705057338845303],
						[61.435749948812756, 2.0705057855282245],
						[61.435750000047982, 2.0705058004567336],
						[61.435769400194523, 2.0705057992575302],
						[61.435769408309845, 2.0705057993306828],
						[61.435769425336488, 2.0705057974175021],
						[61.435769433451824, 2.0705057974906671],
						[61.435785990604387, 2.0705030215825277],
						[61.435808123652471, 2.0705444941176037],
						[61.435808159893618, 2.0705445330910242],
						[61.435808249152181, 2.0705445865957892],
						[61.435808300292258, 2.0705445996635321],
						[61.435827799700405, 2.0705446008276853],
						[61.435849867661375, 2.070544599727755],
						[61.435866515654482, 2.0705833784038861],
						[61.435866553072096, 2.0705834227614077],
						[61.43586664538455, 2.0705834831139107],
						[61.435866700279412, 2.0705834991089267],
						[61.435886099630544, 2.0705835000128889],
						[61.435908176693488, 2.0705835006732252],
						[61.435927621029592, 2.070622290398493],
						[61.435927658161859, 2.0706223291735135],
						[61.435927746624365, 2.0706223847381815],
						[61.435927799641846, 2.0706223992696882],
						[61.435944399551637, 2.0706223993373793],
						[61.435966700089217, 2.0706223991444865],
						[61.435986100331803, 2.0706223998699893],
						[61.436008299920907, 2.0706223995915223],
						[61.436008308036236, 2.0706223996647015],
						[61.436008325062929, 2.0706223977515523],
						[61.436008333178243, 2.0706223978247507],
						[61.436024883109425, 2.0706196216770714],
						[61.436044218578338, 2.070661183661382],
						[61.436044255900818, 2.0706612261583501],
						[61.436044346240728, 2.0706612831869209],
						[61.436044400244427, 2.07066129938078],
						[61.436066699891207, 2.070661299409728],
						[61.436083299801211, 2.0706612994933145],
						[61.436083306134293, 2.0706612999637581],
						[61.436083318705286, 2.0706612990437883],
						[61.436083324943226, 2.0706612976533654],
						[61.436105613156123, 2.0706584996947024],
						[61.436124999841688, 2.0706584996947881],
						[61.436141699808964, 2.0706585000572164],
						[61.436163775886172, 2.070658499096683],
						[61.436183121039946, 2.0706972886970774],
						[61.436183158267276, 2.0706973293333899],
						[61.436183246729811, 2.0706973848986747],
						[61.43618329974727, 2.0706973994304048],
						[61.436205600285447, 2.0706973992800881],
						[61.436222200195658, 2.0706973993780928],
						[61.436241699604899, 2.0706974006210732],
						[61.43626390008572, 2.0706974001855474],
						[61.436263908201042, 2.0706974002587413],
						[61.436263925227699, 2.0706973983456165],
						[61.436263933247908, 2.0706973965579252],
						[61.436280617326368, 2.070694599927966],
						[61.436302671826404, 2.0706946000319921],
						[61.436322019208568, 2.0707361842319187],
						[61.436322055639863, 2.0707362269279734],
						[61.436322145979766, 2.0707362839572361],
						[61.436322199983465, 2.0707363001513204],
						[61.436338790887454, 2.0707363003914514],
						[61.436363732102926, 2.0707751076729437],
						[61.436363768058641, 2.0707751410646233],
						[61.436363851589881, 2.0707751883191081],
						[61.436363899961421, 2.0707752001223905],
						[61.436380483641472, 2.0707752001099489],
						[61.436402625915839, 2.0708139989583092],
						[61.436402662952915, 2.070814035873231],
						[61.436402750238884, 2.0708140860551634],
						[61.436402799596692, 2.0708140995207756],
						[61.436422064648688, 2.0708141003682106],
						[61.436438713919664, 2.0708556745044731],
						[61.43643875143232, 2.0708557207239942],
						[61.436438843839788, 2.0708557829392231],
						[61.436438899720883, 2.0708558005971969],
						[61.436461100203218, 2.0708558002654627],
						[61.436480484121788, 2.0708557991089638],
						[61.436502625790155, 2.0708944985151621],
						[61.436502662827245, 2.0708945354303028],
						[61.436502750208312, 2.0708945874734987],
						[61.436502800457248, 2.0708946007406457],
						[61.436522068088202, 2.0708945991770564],
						[61.4365387159962, 2.0709333788606537],
						[61.436538753413707, 2.0709334232194916],
						[61.436538844834892, 2.070933483772865],
						[61.436538899729719, 2.0709334997687705],
						[61.436560973136075, 2.0709334995807187],
						[61.436580418727267, 2.0709750849489623],
						[61.436580456049683, 2.0709751274469981],
						[61.436580546389472, 2.0709751844773745],
						[61.436580600393164, 2.0709752006719939],
						[61.43659708401735, 2.0709752004752593],
						[61.436619222200378, 2.0710139912193974],
						[61.436638719304035, 2.0710556856833482],
						[61.436638721181431, 2.0710556871470045],
						[61.436638724330422, 2.0710556958556818],
						[61.43663872630291, 2.0710556991802647],
						[61.436660926203075, 2.0710944988575961],
						[61.436660963240072, 2.0710945357731596],
						[61.436661049634857, 2.0710945861548704],
						[61.43666109988375, 2.0710945994223962],
						[61.436680464104334, 2.0710946009152473],
						[61.436697014035367, 2.0711361731998181],
						[61.436697016102968, 2.0711361783853079],
						[61.436697021129341, 2.0711361885576767],
						[61.436697023196942, 2.0711361937431816],
						[61.436719221623207, 2.0711778923312418],
						[61.436738720814091, 2.0712167897298159],
						[61.436738758041287, 2.0712168303674443],
						[61.436738847394786, 2.0712168857364857],
						[61.436738900412173, 2.0712169002693051],
						[61.436760972930244, 2.0712169004715624],
						[61.436780418651807, 2.0712583846000379],
						[61.436780463388558, 2.0712584310924633],
						[61.436780568972665, 2.0712584866083787],
						[61.436780631697417, 2.0712584970955454],
						[61.436797216318936, 2.07125580003876],
						[61.436819272606662, 2.0712558001192471],
						[61.436838719208964, 2.0712972841494763],
						[61.436838755640146, 2.071297326846854],
						[61.43683884597985, 2.0712973838786102],
						[61.436838899983492, 2.0712974000738851],
						[61.436860967951532, 2.0712973994607693],
						[61.436877615753779, 2.0713361797775476],
						[61.436877653076053, 2.071336222276412],
						[61.436877744592238, 2.0713362846924883],
						[61.436877800378134, 2.0713363004906098],
						[61.436897079787869, 2.0713363003250334],
						[61.436919223249319, 2.0713778948648294],
						[61.436919267795759, 2.0713779376357828],
						[61.436919372203377, 2.0713779877681437],
						[61.436919433050804, 2.0713779967918784],
						[61.436936117147404, 2.071375200443923],
						[61.436958299621878, 2.0713752007099271],
						[61.436977799832917, 2.071375200287819],
						[61.436994290684204, 2.0713752005611221],
						[61.437019231641131, 2.0714140072882001],
						[61.437019267691831, 2.0714140425422132],
						[61.437019352018922, 2.0714140877397536],
						[61.437019400390376, 2.071414099544242],
						[61.437036100363095, 2.0714141002806956],
						[61.437058299959702, 2.0714141005179045],
						[61.437077800170968, 2.0714141001160877],
						[61.437094283798331, 2.0714141001345507],
						[61.437116525871758, 2.0714528987294041],
						[61.437116562908656, 2.0714529356458908],
						[61.43711665028956, 2.0714529876915821],
						[61.437116699552213, 2.0714529992975081],
						[61.437136099801599, 2.0714530004938823],
						[61.437152799679403, 2.0714529993862065],
						[61.437152806012456, 2.0714529998568212],
						[61.437152818583485, 2.0714529989371067],
						[61.437152824821403, 2.071452997546785],
						[61.43717501299939, 2.071450199723055],
						[61.437194279838906, 2.071450200535732],
						[61.437216524125695, 2.0714917937673523],
						[61.437216560366586, 2.0714918327435519],
						[61.437216648733724, 2.0714918864518483],
						[61.437216699873744, 2.0714918995215745],
						[61.437233299789575, 2.0714919000080427],
						[61.437233307013763, 2.0714919002800669],
						[61.437233321367046, 2.0714918989631306],
						[61.437233327605, 2.0714918975728067],
						[61.43725281372523, 2.0714890997181286],
						[61.437274864573119, 2.0714890992735873],
						[61.437291513659247, 2.0715306748933289],
						[61.437291551076626, 2.0715307192539023],
						[61.437291643578959, 2.0715307833336412],
						[61.437291700351103, 2.0715308007946192],
						[61.437313899853578, 2.0715307992422054],
						[61.437313907077787, 2.0715307995142145],
						[61.437313920539921, 2.0715307983959268],
						[61.437313928655257, 2.0715307984693214],
						[61.437333313827985, 2.0715280005420853],
						[61.437349886629562, 2.0715279995584646],
						[61.437374829281055, 2.0715695035773023],
						[61.437374865331726, 2.0715695388318704],
						[61.437374950740086, 2.071569587553606],
						[61.437375000002703, 2.0715695991597927],
						[61.437391699976409, 2.0715695999672508],
						[61.437391706309441, 2.0715696004378947],
						[61.437391717989335, 2.071569599716828],
						[61.437391724227261, 2.0715695983265161],
						[61.437413780439655, 2.0715669165456614],
						[61.437433118823662, 2.0716083842385062],
						[61.437433156145865, 2.0716084267384187],
						[61.437433245594349, 2.0716084839706546],
						[61.43743329959797, 2.0716085001667301],
						[61.437450000463066, 2.0716085007944027],
						[61.437472077440283, 2.0716085002483542],
						[61.437491521425905, 2.071647290656649],
						[61.437491557666739, 2.0716473296333637],
						[61.43749164702011, 2.0716473850048649],
						[61.437491700037462, 2.0716473995386697],
						[61.43751389963613, 2.0716473999210612],
						[61.437530599610326, 2.071647400765404],
						[61.437549884369254, 2.0716473995744038],
						[61.437572026329214, 2.0716861986014186],
						[61.43757206336609, 2.0716862355187051],
						[61.43757214976069, 2.0716862859033633],
						[61.437572200009562, 2.0716862991721574],
						[61.437588899984078, 2.0716863000353185],
						[61.437613899821955, 2.0716862993253033],
						[61.437630476284248, 2.0716862994827081],
						[61.437649821084982, 2.0717250896478379],
						[61.437649858312056, 2.0717251302871995],
						[61.437649946774236, 2.0717251858577801],
						[61.437649999791603, 2.0717252003917892],
						[61.437672200282108, 2.0717252006236833],
						[61.437688900161717, 2.0717251996432351],
						[61.437688905603636, 2.0717252003125233],
						[61.437688919065764, 2.0717251991942951],
						[61.437688925303711, 2.0717251978039952],
						[61.43771111259786, 2.0717224002994232],
						[61.4377306001454, 2.0717223991226823],
						[61.437747200062731, 2.0717223997142131],
						[61.437769399661974, 2.0717224001382086],
						[61.437788899875557, 2.0717223999004504],
						[61.437788907990893, 2.0717223999738885],
						[61.437788925017578, 2.0717223980611732],
						[61.437788933037801, 2.071722396273632],
						[61.437805491111988, 2.0717196205621944],
						[61.437827623438466, 2.0717611945457817],
						[61.437827659679307, 2.0717612335230164],
						[61.437827748842402, 2.0717612851734062],
						[61.437827800077486, 2.0717613001047743],
						[61.437847200234074, 2.071761299603418],
						[61.437869399833637, 2.0717613000504871],
						[61.437869407948973, 2.071761300123935],
						[61.437869424975645, 2.071761298211253],
						[61.437869432995861, 2.0717612964237184],
						[61.437886117101513, 2.0717585001621388],
						[61.437905600193609, 2.071758499993936],
						[61.437927799793044, 2.0717585004363039],
						[61.437944399615432, 2.0717584991792455],
						[61.437944405948485, 2.0717584996499223],
						[61.437944417628366, 2.0717584989289048],
						[61.437944423961419, 2.0717584993995839],
						[61.43796671180327, 2.0717557999341056],
						[61.43798610027978, 2.0717558001447083],
						[61.43800280015946, 2.0717557991696802],
						[61.438024999758824, 2.0717557996056173],
						[61.438044399915192, 2.0717557990928226],
						[61.438044406248224, 2.0717557995635061],
						[61.438044418819253, 2.0717557986438626],
						[61.438044425057207, 2.0717557972535454],
						[61.438066724884727, 2.071752997227168],
						[61.438066774407886, 2.0717529786606237],
						[61.438066854109799, 2.0717529157239043],
						[61.438066886261005, 2.0717528746784781],
						[61.438083436169087, 2.07171129927413],
						[61.438102800299141, 2.0717112992319646],
						[61.438124999897937, 2.0717112996315077],
						[61.43814169987224, 2.0717113004887726],
						[61.438163900362149, 2.0717113006879018],
						[61.438163906600096, 2.0717112992975699],
						[61.438163921048456, 2.0717112998416756],
						[61.438163929068679, 2.0717112980541059],
						[61.438183314245045, 2.0717085001281359],
						[61.438199999770873, 2.0717085004377518],
						[61.438222200260711, 2.0717085006321749],
						[61.438241699582697, 2.0717085005658356],
						[61.438263900072457, 2.0717085007583118],
						[61.438263908187778, 2.0717085008317571],
						[61.438263924228224, 2.0717084972566004],
						[61.43826393234356, 2.071708497330027],
						[61.438280616140815, 2.0717058008163258],
						[61.438300000065865, 2.0717058001151178],
						[61.438322199664398, 2.0717058005015971],
						[61.438338899543432, 2.0717057994880084],
						[61.438338905876485, 2.0717057999586768],
						[61.438338919338634, 2.0717057988403806],
						[61.438338924685439, 2.0717057976486588],
						[61.438361125344514, 2.0717029970623813],
						[61.438361169615966, 2.0717029815482899],
						[61.438361247024865, 2.0717029266507292],
						[61.438361279366298, 2.0717028893268816],
						[61.438380733110172, 2.0716640806013706],
						[61.438397217062189, 2.0716613006015483],
						[61.438419400429481, 2.0716613008049425],
						[61.438438899750871, 2.071661300700987],
						[61.438455599629471, 2.0716612996594432],
						[61.438455605962538, 2.0716613001300987],
						[61.438455619424673, 2.0716612990117773],
						[61.438455624771471, 2.0716612978200435],
						[61.438477824537784, 2.07165849738479],
						[61.438477869700328, 2.0716584816719656],
						[61.438477947109227, 2.0716584267740501],
						[61.438477979450631, 2.071658389450012],
						[61.438497330733689, 2.0716195864412641],
						[61.438519411992061, 2.0716169008490075],
						[61.438536100190341, 2.0716169005019158],
						[61.438555600402374, 2.0716169001667608],
						[61.43857779999972, 2.071616900477975],
						[61.438577808115042, 2.0716169005513749],
						[61.438577825141749, 2.0716168986385202],
						[61.438577833161936, 2.0716168968508999],
						[61.43859443324515, 2.0716140965635499],
						[61.438594474748072, 2.0716140797840414],
						[61.4385945469052, 2.0716140279385988],
						[61.438594575777181, 2.0716139932698883],
						[61.438616819962611, 2.0715723995352713],
						[61.438636100264283, 2.0715723992470525],
						[61.438652800237108, 2.0715724000119584],
						[61.438674999833935, 2.0715724002869149],
						[61.438675007058109, 2.0715724005589324],
						[61.438675021411399, 2.0715723992419246],
						[61.438675028540459, 2.071572397652913],
						[61.438694413711559, 2.0715695995739671],
						[61.438716699808367, 2.0715695993871077],
						[61.438733299723815, 2.0715695998629116],
						[61.438733353892836, 2.0715695840245396],
						[61.438733443777537, 2.0715695263451561],
						[61.438733481275499, 2.0715694841068482],
						[61.438752927157218, 2.0715280000206118],
						[61.438774999676752, 2.0715280003503715],
						[61.438791699554017, 2.0715279992255695],
						[61.438791705887084, 2.0715279996962002],
						[61.438791719349219, 2.0715279985777983],
						[61.438791724696046, 2.0715279973860192],
						[61.438813912872661, 2.0715251993922239],
						[61.438833299564102, 2.0715251998220823],
						[61.438850000427585, 2.0715252003561417],
						[61.438872200023674, 2.0715252005882716],
						[61.438891700234571, 2.0715252001819531],
						[61.438891705676468, 2.0715252008512359],
						[61.438891719138603, 2.0715251997328292],
						[61.438891725376557, 2.0715251983424148],
						[61.438913912661739, 2.0715224005316246],
						[61.438930599967875, 2.0715224003187176],
						[61.438950000121359, 2.0715223996236811],
						[61.438972199717362, 2.0715223998491763],
						[61.438988899689555, 2.0715224005755171],
						[61.438988905927516, 2.0715223991850964],
						[61.438988919389637, 2.0715223980666821],
						[61.438988924831563, 2.0715223987359441],
						[61.439011113007567, 2.0715196007112797],
						[61.439030599661017, 2.0715195995531563],
						[61.439047199575839, 2.071519599991396],
						[61.439069400062856, 2.0715196000117317],
						[61.439069407287029, 2.0715196002837351],
						[61.439069420749192, 2.0715195991653035],
						[61.439069426987103, 2.0715195977748899],
						[61.439088913689467, 2.0715168993268542],
						[61.439105600104341, 2.0715168993035111],
						[61.43912779970011, 2.0715168995178592],
						[61.439147199948486, 2.0715169006724401],
						[61.439169400435411, 2.0715169006862113],
						[61.439169407659584, 2.0715169009582133],
						[61.439169424591142, 2.071516897184194],
						[61.439169432706478, 2.0715168972575557],
						[61.439186116802496, 2.0715141007031539],
						[61.439205599891245, 2.0715141003289679],
						[61.439227800378049, 2.0715141003380348],
						[61.439244400292701, 2.0715141007664761],
						[61.439244450897171, 2.0715140857222405],
						[61.439244540176063, 2.0715140338234757],
						[61.439244575986805, 2.0715139938427241],
						[61.439266820126981, 2.0714723991399224],
						[61.439286099631175, 2.0714724008232475],
						[61.439286107746504, 2.0714724008966008],
						[61.439286124773183, 2.0714723989836012],
						[61.439286132793384, 2.0714723971958895],
						[61.439302832928334, 2.0714695970277188],
						[61.439302875322355, 2.0714695800489422],
						[61.439302946588292, 2.0714695284005131],
						[61.439302976351343, 2.0714694935322089],
						[61.439325120208132, 2.0714280001232721],
						[61.439344399616765, 2.0714279999156795],
						[61.439344451207454, 2.0714279865336667],
						[61.439344540391197, 2.071427932773398],
						[61.439344576297053, 2.0714278946534819],
						[61.439366820407841, 2.0713862997526276],
						[61.43938330046884, 2.0713863004719357],
						[61.439402799786869, 2.0713863001563841],
						[61.439402851377594, 2.0713862867742789],
						[61.43940293967016, 2.0713862332124235],
						[61.43940297735827, 2.0713861946951058],
						[61.439425130438856, 2.0713445823269594],
						[61.439441715818404, 2.0713418998836395],
						[61.439463900072504, 2.0713418996122863],
						[61.439463956023744, 2.0713418833758044],
						[61.439464049187556, 2.0713418193164594],
						[61.43946408560403, 2.0713417735533293],
						[61.43948073471055, 2.0713002002288619],
						[61.439499999669479, 2.0713001993919344],
						[61.439522200153746, 2.0713001992347824],
						[61.439522256105008, 2.0713001829981632],
						[61.439522349363884, 2.0713001207996515],
						[61.439522385685265, 2.0713000731753088],
						[61.439539042036735, 2.0712584830730223],
						[61.439561124268103, 2.0712557987495135],
						[61.439561171117774, 2.0712557807770633],
						[61.439561249322452, 2.0712557238163702],
						[61.439561280677466, 2.0712556848280932],
						[61.439580726748922, 2.0712140995858546],
						[61.439597199584227, 2.0712140999347004],
						[61.439619400067471, 2.0712140997116153],
						[61.439619454236428, 2.0712140838720936],
						[61.439619544120937, 2.0712140261898648],
						[61.439619580727658, 2.0712139841485215],
						[61.43963902687981, 2.071172400661895],
						[61.439661100285917, 2.0711724004999414],
						[61.439661156142016, 2.0711723824019401],
						[61.439661249400821, 2.0711723202027441],
						[61.439661285817266, 2.0711722744391059],
						[61.439677942616157, 2.0711307818506111],
						[61.439697229366637, 2.0711279977733525],
						[61.4396972717606, 2.0711279807938627],
						[61.439697345699798, 2.0711279285479982],
						[61.439697377244997, 2.0711278932816133],
						[61.439719520399969, 2.0710862994612969],
						[61.439736099624561, 2.0710863004912956],
						[61.439736156371815, 2.0710862821944138],
						[61.439736248739408, 2.0710862201934561],
						[61.439736286046944, 2.0710861742309734],
						[61.439752935067538, 2.0710446004019181],
						[61.439775000356853, 2.0710446000708314],
						[61.439775053634627, 2.0710445844295742],
						[61.43977514441022, 2.0710445265478894],
						[61.439775181016863, 2.0710444845061313],
						[61.439794526749488, 2.0710030002502289],
						[61.439816700210947, 2.0710030002437145],
						[61.439816756067025, 2.0710029821452864],
						[61.439816849325759, 2.0710029199452413],
						[61.439816885742118, 2.0710028741811892],
						[61.439833442732848, 2.0709612811797458],
						[61.439852827752986, 2.0709584976574096],
						[61.439852871929197, 2.0709584802802814],
						[61.439852945963445, 2.070958429894834],
						[61.439852975631212, 2.0709583931643025],
						[61.439875120301167, 2.0709169003058832],
						[61.439891700320317, 2.0709168991805154],
						[61.439891751019815, 2.0709168859960143],
						[61.439891840203281, 2.070916832233257],
						[61.439891877000129, 2.0709167939133879],
						[61.439914020084117, 2.0708751996782446],
						[61.439933300473434, 2.0708752007669697],
						[61.439933356329483, 2.0708751826682334],
						[61.439933448697005, 2.0708751206662144],
						[61.439933486004463, 2.0708750747031566],
						[61.439950134954664, 2.0708335004951923],
						[61.439972200241527, 2.0708335000053926],
						[61.439972256192725, 2.0708334837676667],
						[61.439972349356218, 2.070833419705663],
						[61.439972385867641, 2.070833375802279],
						[61.439989041757862, 2.0707918846734699],
						[61.440011125075372, 2.0707890980693882],
						[61.440011170142704, 2.0707890804932729],
						[61.440011246660006, 2.070789025788935],
						[61.440011279096254, 2.0707889903231722],
						[61.440030732633431, 2.0707501807805739],
						[61.440047232596584, 2.07074739651783],
						[61.440047274990505, 2.0707473795376199],
						[61.440047347147228, 2.0707473276875352],
						[61.440047376114066, 2.070747294877457],
						[61.440069519726947, 2.0707057999424965],
						[61.440088900076731, 2.0707057993406504],
						[61.44011109975812, 2.0707058007919845],
						[61.440111155614147, 2.0707057826928379],
						[61.440111248872704, 2.0707057204912682],
						[61.440111286180105, 2.0707056745277388],
						[61.440127819972439, 2.0706643872919299],
						[61.440147021306807, 2.0707028881102505],
						[61.440147057738216, 2.0707029308112097],
						[61.440147146996871, 2.0707029843230704],
						[61.440147200109436, 2.0707030007173404],
						[61.440169265394772, 2.0707030001259077],
						[61.440185913995009, 2.0707444757191844],
						[61.440185951412644, 2.0707445200827048],
						[61.440186043820262, 2.070744582304366],
						[61.440186099701371, 2.070744599963847],
						[61.440208176762098, 2.0707446005397316],
						[61.440227620844951, 2.0707833899680343],
						[61.440227658072338, 2.0707834306094157],
						[61.440227747330951, 2.0707834841216326],
						[61.440227800443509, 2.0707835005160731],
						[61.440244286737148, 2.07078349949593],
						[61.440269228275255, 2.0708251029191813],
						[61.440269271740561, 2.0708251421700337],
						[61.440269375067054, 2.0708251887815892],
						[61.440269433145943, 2.0708251965396367],
						[61.440286117219948, 2.0708223994782258],
						[61.44030817260883, 2.0708223992891588],
						[61.440327619005686, 2.0708639855178963],
						[61.440327656233059, 2.0708640261594971],
						[61.440327745681884, 2.0708640833943539],
						[61.440327799685605, 2.0708640995903087],
						[61.440344284292614, 2.0708641008678561],
						[61.440366526384651, 2.0709028992363887],
						[61.440366563421755, 2.0709029361558278],
						[61.440366649816674, 2.0709029865423103],
						[61.44036670006561, 2.0709029998107318],
						[61.440386100307286, 2.0709030005324554],
						[61.44040269120979, 2.0709030007202704],
						[61.440427632165814, 2.0709418074087855],
						[61.440427668216692, 2.0709418426658615],
						[61.44042775165282, 2.0709418880651236],
						[61.440427800024359, 2.0709418998697955],
						[61.440444399933639, 2.07094189995144],
						[61.440466576067209, 2.0709418992752622],
						[61.440485920933988, 2.0709806886607187],
						[61.440485958161347, 2.0709807293026503],
						[61.440486046623889, 2.070980784875629],
						[61.440486099641305, 2.0709807994093801],
						[61.440502679755646, 2.0709808001579511],
						[61.440524824302187, 2.0710222934736038],
						[61.440524859652129, 2.0710223326518169],
						[61.440524948910628, 2.0710223861651871],
						[61.440525000050691, 2.0710223992351762],
						[61.440544400293362, 2.0710224000248818],
						[61.440566568312391, 2.0710223993281862],
						[61.440583115914777, 2.0710611781135886],
						[61.440583153332334, 2.0710612224780252],
						[61.440583244753618, 2.0710612830388628],
						[61.440583299743523, 2.0710613008977594],
						[61.440602799948692, 2.0710613001309657],
						[61.440624867910799, 2.0710612991764914],
						[61.440641515560735, 2.0711000783367841],
						[61.440641518614626, 2.0711000851854129],
						[61.44064152462726, 2.0711000970215134],
						[61.44064152768113, 2.0711001038701444],
						[61.440666520133313, 2.0711417889298276],
						[61.440683113757615, 2.0711833736403071],
						[61.44068311582523, 2.0711833788264653],
						[61.440683118879079, 2.0711833856751003],
						[61.440683120946723, 2.07118339086125],
						[61.440702620907807, 2.0712222891334573],
						[61.440702621894047, 2.0712222907959745],
						[61.440702622880309, 2.0712222924584678],
						[61.440702622975429, 2.0712222943196181],
						[61.440724823044739, 2.0712639940304443],
						[61.440724860176886, 2.0712640328118668],
						[61.4407249494353, 2.0712640863262459],
						[61.440724999684207, 2.0712640995953482],
						[61.440741590589383, 2.071264099963853],
						[61.440766520793957, 2.0713028927879358],
						[61.440783114181897, 2.071344473995306],
						[61.4407831161544, 2.0713444773203138],
						[61.44078311930334, 2.0713444860301657],
						[61.44078312127585, 2.0713444893551731],
						[61.440802621289194, 2.0713833897133496],
						[61.440802622275442, 2.0713833913758548],
						[61.440802623261725, 2.0713833930383578],
						[61.440802623261725, 2.0713833930383578],
						[61.440824818353931, 2.0714250846840576],
						[61.440841513620789, 2.0714667731997323],
						[61.440841555779244, 2.0714668221552728],
						[61.440841659961542, 2.0714668855201825],
						[61.440841721985429, 2.0714668999295633],
						[61.440866570615817, 2.0714641165578422],
						[61.440883114029141, 2.0715056733493395],
						[61.44088311511053, 2.0715056768730116],
						[61.440883118069237, 2.0715056818605828],
						[61.440883119150627, 2.0715056853842539],
						[61.440902619169059, 2.0715472852731667],
						[61.440902621141554, 2.0715472885982202],
						[61.440902624195395, 2.0715472954469547],
						[61.440902626167883, 2.0715472987719901],
						[61.440924819404515, 2.0715861863392342],
						[61.440941513638897, 2.0716278734124405],
						[61.440941551151425, 2.0716279196392344],
						[61.440941643653801, 2.0716279837260778],
						[61.440941700330868, 2.0716279993276774],
						[61.440966567741093, 2.0716279996784439],
						[61.440983115978518, 2.0716667772216586],
						[61.440983117059893, 2.0716667807453515],
						[61.440983118046134, 2.0716667824078798],
						[61.440983119032389, 2.071666784070421],
						[61.44100261900487, 2.0717083841930206],
						[61.441002620086245, 2.0717083877167166],
						[61.441002622153832, 2.0717083929029623],
						[61.441002623140101, 2.0717083945654844],
						[61.441024822975898, 2.0717500931594621],
						[61.44102486761745, 2.071750137797379],
						[61.441024972025041, 2.0717501879367108],
						[61.44102503287241, 2.0717501969618346],
						[61.44104158292064, 2.0717474226521837],
						[61.441060918661556, 2.0717889842108823],
						[61.441060955983772, 2.0717890267156838],
						[61.441061046323398, 2.0717890837557977],
						[61.441061100327055, 2.0717890999536563],
						[61.441083168296444, 2.0717890994939401],
						[61.441099815752544, 2.0718278796119494],
						[61.44109981871128, 2.0718278845995783],
						[61.44109982472385, 2.0718278964359937],
						[61.44109982866884, 2.0718279030861533],
						[61.441124829111054, 2.0718695028003467],
						[61.441124865161697, 2.0718695380592371],
						[61.441124950570064, 2.0718695867869772],
						[61.441124999927766, 2.0718696002558539],
						[61.441141576389093, 2.07186960038903],
						[61.441160899824858, 2.0719083469626582],
						[61.441160900396738, 2.0719500002158515],
						[61.441160903415401, 2.0719500240139608],
						[61.441160914799532, 2.0719500704183744],
						[61.441160923165043, 2.0719500930246566],
						[61.441183122041103, 2.0719917921991602],
						[61.441202620800865, 2.0720306899347003],
						[61.441202621787092, 2.0720306915972508],
						[61.441202622773346, 2.0720306932597969],
						[61.441202623759573, 2.0720306949223493],
						[61.441224817892447, 2.0720722842011372],
						[61.441241513864668, 2.072113973399953],
						[61.441241515932255, 2.0721139785862448],
						[61.441241518986061, 2.0721139854351129],
						[61.441241520958535, 2.0721139887602216],
						[61.441260920709489, 2.072152889882767],
						[61.441260957936493, 2.0721529305270674],
						[61.441261047194629, 2.0721529840448527],
						[61.441261100307095, 2.0721530004420465],
						[61.441283164620529, 2.0721529991643979],
						[61.441299813606911, 2.0721944749474837],
						[61.441299817646964, 2.0721944834589263],
						[61.441299826618227, 2.0721945002831794],
						[61.441299832440549, 2.0721945083973639],
						[61.441324831757555, 2.0722334076641591],
						[61.441324867808135, 2.0722334429236824],
						[61.441324952135048, 2.072233488128973],
						[61.441324999615375, 2.0722335001345393],
						[61.441341571528667, 2.0722334995865173],
						[61.441360919061047, 2.0722750836572184],
						[61.441360920142372, 2.0722750871809805],
						[61.441360924182426, 2.0722750956924512],
						[61.441360926154928, 2.0722750990175731],
						[61.441383126102671, 2.0723139993065214],
						[61.441383163139442, 2.0723140362287902],
						[61.441383250425076, 2.0723140864220548],
						[61.441383299782757, 2.072314099891722],
						[61.441399879811989, 2.0723140994581226],
						[61.441422022784749, 2.0723556949371731],
						[61.441422066344785, 2.0723557360527356],
						[61.441422169065014, 2.0723557884531205],
						[61.441422228130072, 2.0723557978767499],
						[61.441441580917264, 2.0723530184426115],
						[61.441460918765038, 2.0723944843970163],
						[61.441460956087091, 2.0723945269030701],
						[61.44146104642649, 2.0723945839456346],
						[61.441461100430054, 2.0723946001447011],
						[61.441483300033049, 2.0723946008755116],
						[61.441499999915393, 2.0723946001208295],
						[61.441500006248461, 2.0723946005916432],
						[61.441500017928348, 2.0723945998706901],
						[61.441500024166309, 2.0723945984803009],
						[61.44152221205745, 2.0723919005695381],
						[61.441541564416113, 2.0723918996710737],
						[61.441558114075058, 2.0724333735751541],
						[61.441558150696189, 2.0724334200024113],
						[61.441558244089364, 2.0724334838941991],
						[61.441558299875233, 2.0724334996961331],
						[61.441580600427017, 2.072433500538537],
						[61.441580606664985, 2.0724334991481475],
						[61.441580621113367, 2.07243349969254],
						[61.441580628242455, 2.0724334981035253],
						[61.441600014017759, 2.0724307998179134],
						[61.441616700438175, 2.0724308001967588],
						[61.441638899946327, 2.07243079908493],
						[61.441658183913184, 2.0724308002730818],
						[61.441680426700032, 2.0724694994705768],
						[61.441680462845639, 2.0724695365919694],
						[61.441680550131188, 2.0724695867861258],
						[61.44168060038001, 2.0724696000574889],
						[61.441697200205482, 2.0724695990490565],
						[61.441716699531781, 2.0724695993176061],
						[61.441738768398942, 2.0724695991113875],
						[61.441755415582797, 2.0725083785386711],
						[61.441755452999928, 2.0725084229064614],
						[61.441755545311764, 2.0725084832750262],
						[61.44175560020647, 2.0725084992757838],
						[61.441780463966715, 2.0725084992208753],
						[61.441797014180175, 2.072550073137287],
						[61.441797016247747, 2.0725500783237156],
						[61.441797019301568, 2.0725500851727237],
						[61.44179702136914, 2.0725500903591527],
						[61.441816520971386, 2.0725889908594732],
						[61.441816558103191, 2.0725890296437806],
						[61.441816646565165, 2.0725890852235027],
						[61.441816699582482, 2.0725890997604712],
						[61.441838764886249, 2.0725891004288379],
						[61.441855414147916, 2.0726306730905182],
						[61.441855417296829, 2.0726306818007885],
						[61.441855423309335, 2.0726306936376395],
						[61.441855426268056, 2.0726306986254599],
						[61.441877626338034, 2.0726694980715132],
						[61.441877663374711, 2.0726695349947444],
						[61.441877749864183, 2.0726695872495888],
						[61.441877800112991, 2.0726696005213574],
						[61.441897073198014, 2.0726696005088101],
						[61.441916518791537, 2.0727111844026838],
						[61.441916556113483, 2.0727112269097145],
						[61.441916645561662, 2.0727112841525681],
						[61.441916700456318, 2.0727113001537583],
						[61.441938768434703, 2.0727113003082809],
						[61.441955416351099, 2.0727500780516586],
						[61.441955452877032, 2.0727501226186327],
						[61.441955545283875, 2.0727501848495016],
						[61.441955600178559, 2.0727502008507837],
						[61.441977799689766, 2.0727501999509865],
						[61.441997064660121, 2.0727501999106637],
						[61.442013713971896, 2.0727917747288562],
						[61.442013756129917, 2.0727918236879628],
						[61.442013861998909, 2.072791884800997],
						[61.442013924818717, 2.072791897153567],
						[61.442036113027555, 2.0727890996196678],
						[61.44205559969047, 2.0727890991327169],
						[61.442077667669558, 2.0727890993359792],
						[61.442094215604563, 2.0728278788636989],
						[61.442094253021587, 2.0728279232323383],
						[61.442094345333309, 2.0728279836024481],
						[61.442094400227994, 2.0728279996039261],
						[61.442113899557327, 2.0728280000807739],
						[61.442136100055457, 2.0728280008917217],
						[61.442152799940878, 2.0728280003467043],
						[61.442177668160227, 2.0728279995332226],
						[61.442194215991115, 2.0728667773422234],
						[61.442194252612111, 2.0728668237709038],
						[61.442194344828721, 2.0728668822800618],
						[61.442194399818462, 2.0728669001429005],
						[61.442213900039242, 2.072866900441412],
						[61.442236099551494, 2.0728668996128228],
						[61.442252800423361, 2.0728669007478158],
						[61.44225280666128, 2.0728668993574852],
						[61.442252821109683, 2.0728668999020647],
						[61.442252829129927, 2.0728668981144867],
						[61.442272214332078, 2.0728641003731494],
						[61.442294265189936, 2.0728641006708477],
						[61.442310914378368, 2.072905674077568],
						[61.442310950999364, 2.0729057205064607],
						[61.442311044297277, 2.0729057825398236],
						[61.442311100178209, 2.0729058002041518],
						[61.442333299690723, 2.0729057993988147],
						[61.442333306914946, 2.0729057996711102],
						[61.442333321268215, 2.072905798354459],
						[61.44233332760129, 2.072905798825377],
						[61.442352813657841, 2.0729029993261796],
						[61.442369400024162, 2.0729029996435098],
						[61.442391699689097, 2.0729030009830449],
						[61.442411099757564, 2.0729029991465251],
						[61.442411151348395, 2.0729029857653143],
						[61.442411239641572, 2.0729029322019827],
						[61.442411277330095, 2.0729028936825138],
						[61.442433430032722, 2.0728612802623108],
						[61.442450016741361, 2.0728585000853808],
						[61.442469399782631, 2.0728585001313262],
						[61.442491700243067, 2.0728584993744139],
						[61.442491756194542, 2.0728584831387042],
						[61.44249184935898, 2.0728584190770394],
						[61.442491885775937, 2.0728583733109649],
						[61.442508436013689, 2.072816899692846],
						[61.442530600390377, 2.0728168991161589],
						[61.442549999661907, 2.0728168992802298],
						[61.442550007777243, 2.0728168993538829],
						[61.442550024803928, 2.0728168974413075],
						[61.442550032919264, 2.0728168975149601],
						[61.442566716950523, 2.0728140993725273],
						[61.442588900326292, 2.0728141002038445],
						[61.442588953604321, 2.0728140845638667],
						[61.442589044380647, 2.0728140266815269],
						[61.442589080987787, 2.072813984637806],
						[61.442608427620982, 2.0727723996043799],
						[61.442630600112437, 2.0727723990649896],
						[61.44264720003477, 2.0727724000462664],
						[61.442666700254279, 2.0727724002671026],
						[61.44266675264118, 2.0727723848256345],
						[61.442666841730301, 2.0727723292015385],
						[61.442666879323667, 2.0727722888203011],
						[61.442689024182613, 2.0727279998154735],
						[61.442705599758561, 2.0727280005501378],
						[61.442727800159979, 2.0727279994010233],
						[61.442747200321627, 2.0727279993030607],
						[61.442763900300925, 2.0727280005442825],
						[61.442763950905523, 2.0727279854999376],
						[61.442764040184841, 2.0727279335980957],
						[61.44276407688703, 2.0727278934153066],
						[61.442786229621909, 2.0726862812473219],
						[61.442802817215984, 2.072683500734986],
						[61.442824999603928, 2.0726834997996173],
						[61.44282505377307, 2.0726834839606449],
						[61.44282514365819, 2.0726834262760714],
						[61.442825181156437, 2.0726833840332284],
						[61.442844526640933, 2.0726419004961367],
						[61.44286110033871, 2.0726418997155402],
						[61.442861151038407, 2.0726418865322835],
						[61.442861240222534, 2.0726418327687242],
						[61.442861277019844, 2.0726417944469953],
						[61.442883419792565, 2.072600199358908],
						[61.442902800157704, 2.0726001998222983],
						[61.442902856109107, 2.0726001835858718],
						[61.442902949273432, 2.0726001195225083],
						[61.442902985690289, 2.0726000737555017],
						[61.442919541622274, 2.072558483322211],
						[61.442941723943029, 2.0725557997585677],
						[61.442941770697757, 2.0725557799244911],
						[61.44294184890294, 2.0725557229602711],
						[61.442941881149423, 2.0725556837702022],
						[61.442961227788871, 2.0725140999570622],
						[61.442983300314992, 2.0725141007916257],
						[61.443000000197308, 2.0725141000485694],
						[61.443000056148684, 2.0725140838119289],
						[61.44300014931293, 2.0725140197480796],
						[61.443000185729801, 2.0725139739808309],
						[61.443016835321806, 2.0724724001365038],
						[61.443041699971481, 2.0724723997884706],
						[61.443041755922856, 2.072472383551728],
						[61.443041849087095, 2.0724723194876762],
						[61.443041886395036, 2.0724722735216696],
						[61.44305844207274, 2.0724307845344887],
						[61.443080625396611, 2.0724279970996276],
						[61.443080674028707, 2.0724279787292756],
						[61.443080753730769, 2.0724279157832766],
						[61.44308078588211, 2.0724278747315985],
						[61.44309733627918, 2.0723863002473069],
						[61.443116700411494, 2.0723863004217917],
						[61.443116751016056, 2.0723862853766688],
						[61.44311684029519, 2.0723862334732059],
						[61.44311687699728, 2.0723861932895349],
						[61.443139019778776, 2.0723445995326095],
						[61.443155599902155, 2.0723446009155957],
						[61.443177800299082, 2.0723445994720961],
						[61.443197199565702, 2.0723445993155352],
						[61.443197257299317, 2.0723445826811617],
						[61.443197351259535, 2.0723445165564343],
						[61.443197386785158, 2.0723444709873196],
						[61.443214038256379, 2.0723002006034656],
						[61.443236099896161, 2.0723001999358099],
						[61.443255600110788, 2.0723001998367891],
						[61.44327779971114, 2.0723002004169189],
						[61.443277855567388, 2.0723001823183878],
						[61.443277948826619, 2.0723001201146052],
						[61.443277986134525, 2.0723000741480249],
						[61.443294535596266, 2.0722584995267628],
						[61.443313899727229, 2.0722584996137337],
						[61.443313906060304, 2.0722585000845206],
						[61.443313917740163, 2.0722584993634374],
						[61.44331392407323, 2.0722584998342248],
						[61.443336124432093, 2.0722557988041084],
						[61.44333617296904, 2.0722557785720017],
						[61.443336253562173, 2.0722557154263752],
						[61.443336285713443, 2.0722556743741687],
						[61.443352935217362, 2.0722140999362075],
						[61.443374999624623, 2.0722141004664745],
						[61.443375056371977, 2.0722140821690807],
						[61.44337514874006, 2.0722140201634711],
						[61.443375186047923, 2.0722139741966381],
						[61.443391834646825, 2.0721723998825525],
						[61.443411099610707, 2.0721723994275867],
						[61.443433300100672, 2.0721723997106816],
						[61.443433356051997, 2.0721723834731516],
						[61.443433449216101, 2.072172319407322],
						[61.443433485727894, 2.0721722755003205],
						[61.443450142156834, 2.072130784049695],
						[61.443472224617125, 2.0721279981224869],
						[61.443472270575732, 2.0721279803473567],
						[61.443472348780702, 2.0721279233810135],
						[61.443472381027036, 2.0721278841898076],
						[61.443491826686724, 2.0720862998732166],
						[61.443508300417392, 2.0720863003501497],
						[61.44353059997745, 2.0720862991901448],
						[61.44355000022761, 2.0720863005209429],
						[61.443550056083822, 2.0720862824218376],
						[61.443550149342954, 2.0720862202168226],
						[61.443550185759655, 2.0720861744482146],
						[61.443566835205907, 2.0720445996298289],
						[61.44358889961115, 2.0720446000300585],
						[61.443588906835373, 2.0720446003021622],
						[61.443588921188628, 2.0720445989850247],
						[61.44358892831773, 2.0720445973957888],
						[61.443608314172593, 2.072041900474193],
						[61.443630600269934, 2.0720419003961936],
						[61.443647200090666, 2.0720418990918228],
						[61.443647254259709, 2.07204188325123],
						[61.443647344239587, 2.0720418274244747],
						[61.44364738084645, 2.0720417853783508],
						[61.443666827366478, 2.072000200570109],
						[61.443688899791425, 2.0720001991463279],
						[61.443688907906761, 2.072000199219771],
						[61.443688924933426, 2.0720001973066364],
						[61.443688933048762, 2.0720001973800577],
						[61.443705617149426, 2.0719974005201367],
						[61.443727799624334, 2.0719974009151931],
						[61.443747199778429, 2.0719974003212407],
						[61.443763899656034, 2.071997399274244],
						[61.443786100143811, 2.0719973994161469],
						[61.443786107368034, 2.0719973996882204],
						[61.443786120830168, 2.0719973985697027],
						[61.443786128054334, 2.0719973988417872],
						[61.443805614175659, 2.0719946004465273],
						[61.443827800214976, 2.0719946000403162],
						[61.443827856166315, 2.0719945838022058],
						[61.44382794933027, 2.0719945197349547],
						[61.443827985746879, 2.0719944739658418],
						[61.443844535781537, 2.0719529999403359],
						[61.443863899909225, 2.0719529998149544],
						[61.443886100396448, 2.0719529999205362],
						[61.443886107620649, 2.0719530001926039],
						[61.443886124647328, 2.0719529982794231],
						[61.443886132667544, 2.0719529964914902],
						[61.443902816766467, 2.0719501995817176],
						[61.443925000131834, 2.0719501997353382],
						[61.443944400285304, 2.0719501991039602],
						[61.443961100257532, 2.0719501998860617],
						[61.443983299853478, 2.0719502001837968],
						[61.443983354022521, 2.0719501843428128],
						[61.44398344390725, 2.0719501266537965],
						[61.443983481405226, 2.071950084408424],
						[61.444002934352646, 2.0719084844632198],
						[61.444025012043141, 2.0719057991406178],
						[61.444041700335056, 2.0719058006173428],
						[61.444061099596865, 2.0719058001522139],
						[61.44408330008342, 2.0719058002149628],
						[61.444099999960073, 2.0719057991070571],
						[61.44410000629312, 2.0719057995777801],
						[61.444100018864106, 2.0719057986578644],
						[61.444100025102067, 2.0719057972672248],
						[61.444122224958377, 2.0719029980971975],
						[61.444122270916957, 2.0719029803213025],
						[61.444122349121784, 2.0719029233531248],
						[61.444122381368054, 2.0719028841608256],
						[61.444141827038358, 2.0718613006746378],
						[61.444158299780561, 2.0718612993473684],
						[61.44418060032384, 2.0718612996584929],
						[61.444199999585166, 2.0718612991599743],
						[61.444200007700481, 2.0718612992333583],
						[61.444200024727145, 2.0718612973200998],
						[61.444200032842481, 2.0718612973934767],
						[61.444216716938165, 2.0718585003955168],
						[61.444238900302246, 2.071858500469193],
						[61.444258179710943, 2.071858500329856],
						[61.444280418699883, 2.0719000872127551],
						[61.444297000323282, 2.0719389405410911],
						[61.44429700015246, 2.071980600051742],
						[61.444297002184904, 2.0719806221895301],
						[61.444297012392639, 2.0719806632130973],
						[61.444297018880768, 2.0719806843575901],
						[61.444316500310244, 2.072022244157143],
						[61.444316499924014, 2.0720638999728256],
						[61.44431650303784, 2.07206392563472],
						[61.444316516394487, 2.0720639753692285],
						[61.444316525746238, 2.0720639996404904],
						[61.444338699781895, 2.0721028526553629],
						[61.44433870009312, 2.0721443991520694],
						[61.444338702125535, 2.0721444212898956],
						[61.44433871242834, 2.0721444641749636],
						[61.444338718821335, 2.0721444834581297],
						[61.44435811905371, 2.0721861838751643],
						[61.444358121026184, 2.072186187200598],
						[61.444358124175103, 2.0721861959115113],
						[61.444358126147591, 2.072186199236957],
						[61.44438042200013, 2.0722250911181308],
						[61.444399816196714, 2.0722667798034933],
						[61.444416514355702, 2.0723083748711724],
						[61.444416517409557, 2.0723083817207297],
						[61.444416523422106, 2.0723083935584592],
						[61.444416526380834, 2.0723083985466419],
						[61.444438704295905, 2.0723472585112725],
						[61.444441500391896, 2.0723889123375367],
						[61.444441503030099, 2.0723889286926509],
						[61.44444150999368, 2.0723889591442366],
						[61.44444151431906, 2.0723889732406695],
						[61.444458114146315, 2.0724306731762066],
						[61.444458117200156, 2.0724306800257839],
						[61.444458123307804, 2.0724306937249324],
						[61.444458127252744, 2.0724307003758691],
						[61.444480399676237, 2.0724694528061933],
						[61.444480399571738, 2.0725110993445917],
						[61.444480402590337, 2.072511123145298],
						[61.444480412988128, 2.0725111678920451],
						[61.444480421258476, 2.0725111886394738],
						[61.444499799675548, 2.0725500478476859],
						[61.444499799832037, 2.0725916999978153],
						[61.444499799986275, 2.0726332994340466],
						[61.444499802018655, 2.0726333215720159],
						[61.444499812321268, 2.072633364457479],
						[61.44449981871422, 2.0726333837408424],
						[61.444519199590083, 2.0726750437826507],
						[61.444519199898764, 2.0727166996840198],
						[61.444519203012476, 2.0727167253461323],
						[61.444519217260037, 2.0727167748826099],
						[61.444519225720519, 2.0727167993528948],
						[61.444541499637339, 2.0727556533760247],
						[61.444541499752845, 2.0727972001564789],
						[61.444541501785196, 2.0727972222944917],
						[61.444541511992711, 2.0727972633186544],
						[61.444541519371818, 2.0727972842648374],
						[61.444560900199718, 2.0728389444661057],
						[61.444560900458981, 2.072880600426553],
						[61.444560899916141, 2.0729194006784373],
						[61.44456090293469, 2.0729194244792253],
						[61.444560915209721, 2.0729194706903655],
						[61.444560922683948, 2.0729194934979689],
						[61.444583099960965, 2.0729611498326928],
						[61.444583099897891, 2.0730028002411145],
						[61.444583102821312, 2.0730028221805599],
						[61.444583113314039, 2.0730028687890196],
						[61.444583120693167, 2.0730028897352875],
						[61.444602599829366, 2.0730417476111622],
						[61.44460260014386, 2.0730833000630482],
						[61.444602599842788, 2.0731249994921241],
						[61.444602601779934, 2.0731250197688249],
						[61.444602609028635, 2.0731250558049128],
						[61.44460261354417, 2.0731250736243152],
						[61.44461920262637, 2.0731667445199848],
						[61.444622000434428, 2.0732083135235748],
						[61.44462200424897, 2.0732083352644208],
						[61.444622017224866, 2.0732083775543515],
						[61.444622026386199, 2.0732083981034286],
						[61.444644219040612, 2.0732472876374626],
						[61.444660899874307, 2.0732889381963524],
						[61.44466090028282, 2.0733305998838119],
						[61.444660904382616, 2.0733306272088994],
						[61.444660919806374, 2.0733306821314708],
						[61.444660931926414, 2.0733307076689074],
						[61.444685921245359, 2.0733694916977452],
						[61.444702600070848, 2.0734111390533663],
						[61.444702600442703, 2.0734528007991018],
						[61.444702603366053, 2.0734528227386626],
						[61.44470261287244, 2.0734528676846793],
						[61.444702621142611, 2.0734528884325196],
						[61.444722021190131, 2.0734917888169471],
						[61.444722022176364, 2.0734917904797374],
						[61.444722023162583, 2.0734917921425176],
						[61.444722024148824, 2.0734917938052893],
						[61.44474419968919, 2.0735333494570938],
						[61.444744200344985, 2.0735749998939204],
						[61.444744203363406, 2.0735750236949229],
						[61.444744212869729, 2.0735750686410404],
						[61.444744221139885, 2.0735750893889402],
						[61.444763721211686, 2.0736139901922783],
						[61.444763722197912, 2.0736139918550616],
						[61.444763723184138, 2.0736139935178439],
						[61.444763723184138, 2.0736139935178439],
						[61.444785923227833, 2.073655694451662],
						[61.444785960359383, 2.0736557332407144],
						[61.444786048725959, 2.073655786966845],
						[61.444786099865844, 2.0736558000425291],
						[61.444802799756019, 2.0736557998489751],
						[61.444802806980256, 2.0736558001214447],
						[61.444802821333525, 2.0736557988049635],
						[61.444802829353769, 2.0736557970174019],
						[61.444822213682912, 2.0736529996322655],
						[61.444844399738969, 2.0736530003609728],
						[61.444860973443419, 2.0736530000493305],
						[61.44488041891875, 2.0736944848470835],
						[61.444880456240512, 2.0736945273591201],
						[61.444880545688356, 2.0736945846097141],
						[61.444880599691807, 2.073694600812459],
						[61.444902800101545, 2.073694600251621],
						[61.444902808216881, 2.0736946003254677],
						[61.444902824352475, 2.0736945986117545],
						[61.444902832372712, 2.0736945968241938],
						[61.444919416154782, 2.0736919002751102],
						[61.444941700391169, 2.0736918998532228],
						[61.444960968041293, 2.0736918996803957],
						[61.44497761564454, 2.073730679765069],
						[61.44497765296628, 2.0737307222772778],
						[61.444977745372789, 2.0737307845165476],
						[61.444977800267395, 2.0737308005207749],
						[61.445002800027162, 2.073730799204601],
						[61.445019399955491, 2.073730800614662],
						[61.44504157601947, 2.073730799851627],
						[61.445060921014992, 2.0737694890472214],
						[61.445060958241648, 2.0737695296981604],
						[61.445061046703216, 2.0737695852864708],
						[61.445061099720469, 2.0737695998266115],
						[61.445077799611411, 2.0737695996845154],
						[61.445100000021775, 2.0737695991680036],
						[61.445119400191224, 2.0737695996229406],
						[61.445141699767795, 2.073769599593684],
						[61.445141707883145, 2.0737695996675645],
						[61.44514172401874, 2.0737695979538682],
						[61.445141732038977, 2.0737695961663096],
						[61.445158315822624, 2.0737668996281071],
						[61.445177799818751, 2.0737669002217967],
						[61.44517785140971, 2.0737668868405188],
						[61.445177939703157, 2.0737668332743482],
						[61.44517797739185, 2.0737667947522707],
						[61.445200119676734, 2.0737251992986887],
						[61.445216699714585, 2.0737251994842603],
						[61.445216751305523, 2.0737251861028931],
						[61.445216839598956, 2.073725132536532],
						[61.445216877287649, 2.0737250940143501],
						[61.44523901965357, 2.0736835003395768],
						[61.445258299874396, 2.0736834992302953],
						[61.445274999859585, 2.0736835008965411],
						[61.445275053137728, 2.0736834852563941],
						[61.445275142227153, 2.0736834296297402],
						[61.445275178929634, 2.0736833894446161],
						[61.445297324132703, 2.073639099097663],
						[61.445313899714094, 2.0736391002271133],
						[61.445313951305032, 2.0736390868455503],
						[61.445314039598415, 2.073639033278762],
						[61.445314077287087, 2.0736389947563487],
						[61.445336219623698, 2.0735974008876403],
						[61.44535559990107, 2.0735974000110762],
						[61.445355655852602, 2.0735973837748474],
						[61.445355749017345, 2.0735973197089161],
						[61.445355786325628, 2.0735972737406501],
						[61.445372336035021, 2.0735558005068291],
						[61.445394400359568, 2.0735558000335574],
						[61.445413899693044, 2.0735558008317305],
						[61.445413951188897, 2.0735557855885332],
						[61.445414040468435, 2.0735557336841417],
						[61.445414077170881, 2.073555693498677],
						[61.445436230207989, 2.0735140800989296],
						[61.4454528329696, 2.0735112963212408],
						[61.445452877051046, 2.0735112770826296],
						[61.44545295080087, 2.0735112211089364],
						[61.445452981360425, 2.0735111841752043],
						[61.445472328344351, 2.0734695993416681],
						[61.445494399987155, 2.0734696009365479],
						[61.445511099875361, 2.0734696006183397],
						[61.445511155731793, 2.0734695825203331],
						[61.445511248991544, 2.0734695203151201],
						[61.445511286299798, 2.073469474346465],
						[61.445527982289128, 2.0734278835008131],
						[61.445550177403192, 2.0733861945740495],
						[61.445550185173438, 2.0733861702503251],
						[61.445550197244557, 2.0733861242588403],
						[61.445550199572907, 2.0733860992654405],
						[61.445550200030304, 2.0733444004229962],
						[61.44555019968174, 2.0733028448393371],
						[61.445569580602296, 2.0732611843916757],
						[61.445569587671613, 2.0732611639894509],
						[61.445569598150591, 2.0732611221180628],
						[61.445569599682877, 2.0732610991847391],
						[61.445569600101486, 2.0732194003188047],
						[61.445569599911302, 2.0732193965958894],
						[61.445569599626033, 2.0732193910115453],
						[61.445569600326991, 2.0732193870899911],
						[61.445566903001094, 2.0731778433726529],
						[61.445589076672135, 2.0731361947508713],
						[61.445589085333502, 2.073136170228413],
						[61.445589097404508, 2.0731361242367679],
						[61.445589099732814, 2.0731360992433365],
						[61.445589099829157, 2.0730971999169081],
						[61.445589100295287, 2.0730556440876073],
						[61.445608481136929, 2.0730139834372987],
						[61.445608489002261, 2.0730139609748788],
						[61.445608498209623, 2.0730139118562048],
						[61.445608499646752, 2.0730138870613999],
						[61.445605799996507, 2.0729721932061831],
						[61.445605800304378, 2.0729305997307228],
						[61.445605799634571, 2.0728888991600893],
						[61.4456057999382, 2.0728472002530904],
						[61.445605799951451, 2.0728083009111846],
						[61.445605799828748, 2.0727666999931964],
						[61.445605799999726, 2.0727249992271832],
						[61.44560580025302, 2.0726833003234613],
						[61.445605800092011, 2.0726416994079693],
						[61.445605800319711, 2.0726000005059189],
						[61.445605800129499, 2.0725999967830231],
						[61.445605799749096, 2.0725999893372142],
						[61.445605798667771, 2.0725999858129707],
						[61.445602999547468, 2.0725610929454277],
						[61.44560300003581, 2.0725193996330948],
						[61.445602999919757, 2.0724778005860465],
						[61.445603000002059, 2.0724360998295817],
						[61.445603000071614, 2.0723943990739411],
						[61.445602999917256, 2.0723528000294085],
						[61.445602999788974, 2.0723139007002249],
						[61.445602999820984, 2.072272199947033],
						[61.445602997788548, 2.0722721778082902],
						[61.445602988472018, 2.0722721365842629],
						[61.445602981092783, 2.0722721156375208],
						[61.445583499807903, 2.0722305558726442],
						[61.445583500315053, 2.0721889002193254],
						[61.445583500045075, 2.0721499990561307],
						[61.445583500122268, 2.0721083001937846],
						[61.445583499785528, 2.0720666993195902],
						[61.445583499837127, 2.0720250004589125],
						[61.445583499780831, 2.0719832997376231],
						[61.445583499685718, 2.0719832978761623],
						[61.445583500196406, 2.0719832902317141],
						[61.44558350000618, 2.071983286508809],
						[61.445580799830559, 2.0719416945071401],
						[61.445580799870264, 2.0719028007977314],
						[61.445580796756424, 2.0719027751348342],
						[61.445580785372179, 2.0719027287239529],
						[61.445580776115506, 2.0719027063132032],
						[61.445558500326094, 2.071861050644598],
						[61.445558500114601, 2.0718194008151394],
						[61.445558499689845, 2.0717777999801319],
						[61.445558496671104, 2.0717777761786911],
						[61.445558487164199, 2.071777731231994],
						[61.445558478893737, 2.0717777104840844],
						[61.445539100339047, 2.0717388523537217],
						[61.445539099995365, 2.071697200691224],
						[61.445539100424718, 2.0716555996858843],
						[61.445539100255843, 2.0716138990314836],
						[61.445539097141967, 2.0716138733686518],
						[61.445539083880234, 2.0716138254938685],
						[61.445539073637256, 2.0716138014204812],
						[61.44551690033672, 2.0715749471217375],
						[61.44551690013342, 2.0715332992151092],
						[61.445516899716971, 2.0714917003028384],
						[61.445516897684456, 2.0714916781642256],
						[61.445516888272536, 2.0714916350791297],
						[61.44551688089318, 2.071491614132638],
						[61.445497399683987, 2.0714499551348653],
						[61.445497399704493, 2.0714110991650201],
						[61.44549740044765, 2.0713694002341283],
						[61.445497399886293, 2.0713277994897692],
						[61.445497397853714, 2.0713277773511916],
						[61.445497387645752, 2.0713277363262796],
						[61.445497381157523, 2.0713277151811469],
						[61.445477999957205, 2.0712860565970672],
						[61.445478000081629, 2.0712443992423779],
						[61.445478000223936, 2.0712055997398156],
						[61.445477999918118, 2.0711638991762809],
						[61.445477996899278, 2.0711638753749861],
						[61.445477984623636, 2.0711638291632402],
						[61.445477977149103, 2.0711638063554201],
						[61.445455800213942, 2.0711221499824752],
						[61.445455800363725, 2.071080599954819],
						[61.44545579734487, 2.07108057615355],
						[61.445455786946582, 2.0710805314059124],
						[61.445455778676056, 2.0710805106582528],
						[61.445436299872874, 2.0710416528173332],
						[61.4454363001134, 2.0709999992462085],
						[61.445436299840416, 2.0709583006040919],
						[61.445436297807774, 2.0709582784655827],
						[61.44543628839574, 2.0709582353807412],
						[61.445436281016306, 2.0709582144344343],
						[61.445416900293239, 2.0709166558166077],
						[61.445416900162655, 2.0708777999572261],
						[61.445416899757419, 2.0708360994820993],
						[61.445416896738507, 2.0708360756808868],
						[61.445416885353893, 2.0708360292706622],
						[61.445416876097056, 2.0708360068603748],
						[61.445394599899835, 2.0707943503268447],
						[61.445394599950575, 2.0707528003874209],
						[61.445394600163873, 2.0707138992884495],
						[61.445394598131195, 2.0707138771499904],
						[61.445394587923055, 2.0707138361254414],
						[61.445394580543578, 2.0707138151792219],
						[61.445375200053775, 2.0706721569244153],
						[61.44537519968695, 2.070630599567628],
						[61.445375200192913, 2.0705889008160536],
						[61.445375196983711, 2.0705888732920275],
						[61.445375183721602, 2.0705888254179596],
						[61.445375174369588, 2.070588801146338],
						[61.445352999803333, 2.07054994613123],
						[61.445353000275198, 2.070508300127134],
						[61.44535299824252, 2.0705082779887105],
						[61.445352987939174, 2.0705082351028476],
						[61.44535298065481, 2.0705082160181281],
						[61.445333499647418, 2.0704666556560114],
						[61.445333499618499, 2.0704250003725337],
						[61.44533349768092, 2.0704249800955594],
						[61.445333490241218, 2.0704249403364678],
						[61.445333483943124, 2.0704249229144893],
						[61.445316897459222, 2.0703860532051532],
						[61.445314100163401, 2.0703443872638854],
						[61.445314096348405, 2.0703443655229448],
						[61.445314084357889, 2.0703443248960602],
						[61.445314076182378, 2.0703443060101212],
						[61.445291899594103, 2.0703027500982611],
						[61.445291899801155, 2.0702611004580129],
						[61.445291897863513, 2.0702610801810839],
						[61.445291890423803, 2.0702610404220838],
						[61.445291884030539, 2.0702610211387409],
						[61.445275199607352, 2.0702221583394165],
						[61.445275199995962, 2.0701806009264074],
						[61.445275199805685, 2.0701805972035721],
						[61.445275200316296, 2.0701805895591558],
						[61.445275200221168, 2.0701805876977351],
						[61.445272399558952, 2.0701388924493243],
						[61.445272400201397, 2.0700971994295658],
						[61.445272400324278, 2.0700583003569943],
						[61.445272400256918, 2.0700166997408567],
						[61.445272399590131, 2.0699749994766012],
						[61.445272399896801, 2.0699333008758782],
						[61.445272399791143, 2.0698917002622186],
						[61.445272399977128, 2.0698499998017348],
						[61.445272396862919, 2.0698499741393186],
						[61.44527238439656, 2.0698499242055699],
						[61.445272374153276, 2.0698499001330051],
						[61.445250196426478, 2.0698110407936117],
						[61.445247399907743, 2.0697693936185977],
						[61.445247400132928, 2.0697278004873252],
						[61.445247398195235, 2.0697277802104597],
						[61.445247390945632, 2.0697277441744872],
						[61.445247386429685, 2.0697277263552358],
						[61.445230800157368, 2.0696860613314572],
						[61.445230800283902, 2.0696471999443626],
						[61.445230800091402, 2.0696055993920255],
						[61.445230798058546, 2.0696055772537636],
						[61.445230787850122, 2.069605536229806],
						[61.445230781361587, 2.0696055150852359],
						[61.445211300392891, 2.0695638556168667],
						[61.445211300089497, 2.0695222005149914],
						[61.445211299953698, 2.0694832996680987],
						[61.445211296934566, 2.0694832758671877],
						[61.44521128465837, 2.0694832296565271],
						[61.44521127619727, 2.069483205186637],
						[61.445189100247781, 2.0694416498392632],
						[61.445189100192742, 2.0694000003538005],
						[61.445189097173582, 2.0693999765528948],
						[61.445189086774761, 2.0693999318062524],
						[61.445189079299986, 2.0693999089990553],
						[61.445169600036401, 2.0693610525633779],
						[61.445169599766778, 2.0693193993827759],
						[61.445169600461355, 2.06927780058147],
						[61.4451696004609, 2.0692361002710009],
						[61.445169596265124, 2.0692360710846582],
						[61.445169580043739, 2.0692360182235023],
						[61.445169567922946, 2.0692359926873061],
						[61.445144599977155, 2.0691971405638032],
						[61.445144600019937, 2.0691556002970359],
						[61.44514459998242, 2.0691139000225189],
						[61.445144599932092, 2.0690721997488355],
						[61.445144598089485, 2.0690721813334845],
						[61.445144590744526, 2.0690721434363937],
						[61.445144586228494, 2.0690721256173177],
						[61.445127997390756, 2.0690305557505231],
						[61.445125200385469, 2.0689888862883841],
						[61.445125196855713, 2.068988870131967],
						[61.445125188809918, 2.068988836156501],
						[61.445125184389035, 2.0689888201988378],
						[61.445108499614967, 2.0689499583075683],
						[61.445108499826155, 2.0689082994339749],
						[61.445108496806917, 2.0689082756332042],
						[61.445108485421635, 2.0689082292241849],
						[61.445108476164492, 2.0689082068147067],
						[61.44508629995196, 2.0688666501595527],
						[61.445086300417579, 2.0688278001366021],
						[61.445086298384616, 2.0688277779984983],
						[61.445086288080766, 2.0688277351136044],
						[61.445086280796176, 2.0688277160294533],
						[61.445066899560963, 2.0687860556641517],
						[61.445066899912973, 2.0687444005723412],
						[61.445066900338318, 2.0687028000583494],
						[61.445066897223882, 2.0687027743962334],
						[61.445066882974864, 2.0687027248608341],
						[61.445066873622537, 2.0687027005900305],
						[61.445044597308403, 2.0686638416648209],
						[61.445041900120003, 2.068622187718177],
						[61.445041896590197, 2.0686221715618474],
						[61.445041889625777, 2.0686221411105974],
						[61.445041886096014, 2.0686221249542811],
						[61.445025200027757, 2.0685805601839031],
						[61.445025200032099, 2.0685388995678173],
						[61.445025199713278, 2.0685000008332852],
						[61.445025197680245, 2.0684999786952565],
						[61.445025188267415, 2.0684999356117744],
						[61.445025180982789, 2.0684999165277387],
						[61.445005799651227, 2.0684582564524194],
						[61.445005800396537, 2.0684166992355317],
						[61.44500579728205, 2.0684166735734806],
						[61.445005783128096, 2.0684166258997094],
						[61.445005773775712, 2.0684166016290586],
						[61.444983500247538, 2.0683777461138697],
						[61.444983500060665, 2.0683361006475933],
						[61.444983498027618, 2.0683360785096023],
						[61.444983487723611, 2.0683360356250202],
						[61.444983481330084, 2.0683360163422795],
						[61.444964099950575, 2.0682943564264038],
						[61.444964099755452, 2.0682527994672286],
						[61.444964100246914, 2.0682139006156404],
						[61.444964097227562, 2.0682138768150504],
						[61.444964084855776, 2.068213828743894],
						[61.444964077380789, 2.0682138059371806],
						[61.444941900377898, 2.0681721503289934],
						[61.444941899540204, 2.0681305991848418],
						[61.444941900178286, 2.0680889008650385],
						[61.44494189715887, 2.0680888770644721],
						[61.444941886759665, 2.0680888323186344],
						[61.444941879284649, 2.0680888095119694],
						[61.444922399567439, 2.0680499523584333],
						[61.444922399791722, 2.0680082993361166],
						[61.444922400089645, 2.0679667008913567],
						[61.444922399691507, 2.0679250009383483],
						[61.444922397658395, 2.0679249788004306],
						[61.444922388245374, 2.067924935717278],
						[61.444922380960683, 2.0679249166334386],
						[61.444903000351161, 2.0678832568623258],
						[61.444903000158206, 2.0678443996409386],
						[61.44490300031115, 2.0678027993639896],
						[61.444902997291727, 2.0678027755634751],
						[61.444902985014977, 2.0678027293539865],
						[61.444902976648777, 2.0678027067461886],
						[61.444880800321272, 2.0677610494579106],
						[61.444880799757016, 2.0677194004186137],
						[61.444880799699298, 2.0676805996660259],
						[61.444880797666123, 2.0676805775281442],
						[61.444880788348229, 2.067680536306522],
						[61.444880780968298, 2.0676805153613911],
						[61.444861300229775, 2.0676388555304266],
						[61.44486130023401, 2.0675972007367118],
						[61.444861297024197, 2.0675971732134864],
						[61.44486128376105, 2.0675971253415408],
						[61.444861274408566, 2.0675971010712475],
						[61.444839099823255, 2.0675582468009064],
						[61.444839099881214, 2.0675166993302536],
						[61.444839100237665, 2.0674749992990589],
						[61.44483909820449, 2.0674749771612326],
						[61.444839087995398, 2.0674749361385305],
						[61.444839080615438, 2.0674749151934768],
						[61.444819599816547, 2.0674332555494241],
						[61.444819599591568, 2.0673944003019367],
						[61.444819597558379, 2.0673943781641277],
						[61.444819588145201, 2.0673943350812873],
						[61.44481958076527, 2.067394314136259],
						[61.444800199792489, 2.0673527565470184],
						[61.44480019961501, 2.0673110999794218],
						[61.444800196595494, 2.0673110761790574],
						[61.444800185209694, 2.0673110297711101],
						[61.444800176748259, 2.0673110053021433],
						[61.444778000372338, 2.0672693503682233],
						[61.444778000084277, 2.0672306006058618],
						[61.444778000354127, 2.0671889006622641],
						[61.44477799832088, 2.0671888785244903],
						[61.444777988016519, 2.0671888356405899],
						[61.444777980636559, 2.0671888146956392],
						[61.444758499753718, 2.0671471553156766],
						[61.444758500197949, 2.0671056003164674],
						[61.444758498259858, 2.0671055800401099],
						[61.444758489928091, 2.0671055404814056],
						[61.4447584835344, 2.0671055211990637],
						[61.444741899987235, 2.0670666588076814],
						[61.444741899627992, 2.06702500046246],
						[61.444741900328772, 2.0670249965408831],
						[61.444741899948077, 2.0670249890953842],
						[61.444741899852886, 2.0670249872339941],
						[61.444739100018936, 2.066983292646416],
						[61.44473910029204, 2.066941700051236],
						[61.444739099582854, 2.0669000003643432],
						[61.444739099595843, 2.0668610999578312],
						[61.444739099753001, 2.0668194000737508],
						[61.444739099595182, 2.0667778000363484],
						[61.444739099726768, 2.0667361001539319],
						[61.444739099845542, 2.0666944002723335],
						[61.444739099649482, 2.066652800237422],
						[61.444739099591658, 2.0666138998355281],
						[61.444739100387572, 2.0666138977753428],
						[61.444739100006835, 2.0666138903298354],
						[61.444739099816474, 2.066613886607092],
						[61.444736299952091, 2.066572193929828],
						[61.444736300004195, 2.0665305994852181],
						[61.444736300059894, 2.0664888996115085],
						[61.44473630031947, 2.066447239225135],
						[61.444752984056144, 2.0664056781824152],
						[61.444772381085308, 2.0663639842440826],
						[61.444772388153567, 2.066363963840876],
						[61.44477239773925, 2.0663639221672234],
						[61.444772400161497, 2.066363899035403],
						[61.444772400062035, 2.0663222499703036],
						[61.444794575632706, 2.0662806946922463],
						[61.44479457891164, 2.0662806883127907],
						[61.444794582986518, 2.0662806798731475],
						[61.444794586265445, 2.0662806734936909],
						[61.444811286264255, 2.0662389730963047],
						[61.444811290754323, 2.0662389551509066],
						[61.444811297952157, 2.0662389196577999],
						[61.444811299673653, 2.0662389004475195],
						[61.444811299950224, 2.0661972418271488],
						[61.444827981284043, 2.0661583845112022],
						[61.444850179194027, 2.0661139888207956],
						[61.444850187153349, 2.0661139682186525],
						[61.444850196548586, 2.066113922822066],
						[61.444850199861932, 2.0661138994913131],
						[61.444850200149986, 2.0660750440314417],
						[61.444869579276457, 2.0660333895001251],
						[61.444891876171262, 2.0659917936173344],
						[61.444891885722413, 2.0659917688946998],
						[61.444891898206166, 2.0659917133957468],
						[61.444891899546839, 2.0659916867398751],
						[61.444889102664987, 2.065950038401581],
						[61.444908480859972, 2.0659083839401524],
						[61.444908481655887, 2.0659083818799204],
						[61.44490848503002, 2.0659083773617919],
						[61.444908485825934, 2.065908375301579],
						[61.444925186294761, 2.0658667742028367],
						[61.444925190784751, 2.0658667562573458],
						[61.444925197887272, 2.0658667189026318],
						[61.444925199608711, 2.0658666996922639],
						[61.444925200244811, 2.0658250502338031],
						[61.444947376847388, 2.065783394355007],
						[61.444947384711512, 2.0657833718913272],
						[61.444947396684775, 2.0657833240365786],
						[61.444947399998043, 2.0657833007057436],
						[61.444947399735582, 2.0657417398974047],
						[61.444964086030659, 2.0657000740354796],
						[61.444964075828878, 2.0656999802979055],
						[61.444963983270881, 2.0656998446933219],
						[61.444963899833112, 2.0656997993024047],
						[61.444947199816568, 2.0656998000476277],
						[61.444925124405557, 2.0656997991486512],
						[61.444905779081651, 2.0656610113153198],
						[61.4449057418527, 2.0656609706744473],
						[61.444905653388155, 2.0656609151096306],
						[61.444905600370255, 2.0656609005834281],
						[61.444888900258732, 2.0656608994861148],
						[61.44486682672553, 2.0656609000756965],
						[61.44484738066938, 2.0656193146469373],
						[61.44484733869924, 2.0656192694162785],
						[61.44484723578627, 2.0656192133104998],
						[61.444847174843488, 2.0656192024353897],
						[61.44482512002336, 2.065621983271384],
						[61.444805780902058, 2.0655805152538624],
						[61.444805743577909, 2.0655804727518174],
						[61.444805654126981, 2.0655804155248907],
						[61.44480560012277, 2.0655803993363206],
						[61.444788900107035, 2.0655804001400724],
						[61.444788893774003, 2.0655803996706359],
						[61.444788882094166, 2.0655804003943019],
						[61.444788875856332, 2.0655804017862343],
						[61.444766819556307, 2.0655830831935966],
						[61.444747381258686, 2.0655416148132915],
						[61.444747343934523, 2.0655415723113713],
						[61.444747253592453, 2.0655415152835066],
						[61.444747199683427, 2.0655415009563947],
						[61.444730599629814, 2.0655415001817992],
						[61.444708299855506, 2.0655415005028543],
						[61.444708292631319, 2.0655415002322801],
						[61.444708279169291, 2.0655415013536449],
						[61.444708271945082, 2.0655415010830578],
						[61.444689010624323, 2.0655441838517912],
						[61.444666876028379, 2.0655027054122561],
						[61.444666839785782, 2.0655026664343499],
						[61.444666750620407, 2.0655026147919764],
						[61.444666700275853, 2.0655025996695828],
						[61.444650000260687, 2.0655026005102068],
						[61.444630599882366, 2.0655025996049652],
						[61.444630593644497, 2.0655026009969117],
						[61.444630581073554, 2.0655026019194462],
						[61.444630574740501, 2.0655026014500297],
						[61.444608287409103, 2.0655054003365199],
						[61.444591700021796, 2.0655054005151805],
						[61.444569400247751, 2.0655054008556104],
						[61.444569346079625, 2.0655054167081279],
						[61.444569256102739, 2.0655054725554134],
						[61.444569218606908, 2.0655055148092591],
						[61.444549873336811, 2.0655469998217],
						[61.444533300397516, 2.0655470005173138],
						[61.444533294064456, 2.0655470000478893],
						[61.444533280602393, 2.0655470011692496],
						[61.444533275255665, 2.0655470023623312],
						[61.444511086993352, 2.065549799805551],
						[61.444491700171724, 2.0655497996052667],
						[61.444469400397111, 2.0655497999092143],
						[61.444469343555625, 2.0655498163581076],
						[61.444469251286129, 2.0655498802470578],
						[61.444469213980661, 2.0655499262234152],
						[61.444452663691514, 2.0655915008998167],
						[61.444433300229051, 2.0655914992239111],
						[61.444411099619835, 2.0655914999585159],
						[61.444411043669483, 2.0655915162084515],
						[61.444410951399945, 2.0655915800971298],
						[61.444410913999285, 2.0655916242120123],
						[61.444394257572704, 2.0656331151594838],
						[61.444372186710943, 2.0656358995034214],
						[61.444352799888385, 2.0656358992438668],
						[61.444352744033203, 2.0656359173550518],
						[61.444352650777354, 2.0656359795809629],
						[61.444352614362991, 2.0656360253581902],
						[61.444335964878483, 2.065677599367167],
						[61.444313900350998, 2.0656775997922887],
						[61.444294400008935, 2.0656776003609365],
						[61.444294349310105, 2.0656776135562516],
						[61.444294260129041, 2.0656776673423183],
						[61.444294224224997, 2.0656777054752009],
						[61.444272070100105, 2.065719218082823],
						[61.444255582669001, 2.0657220008831412],
						[61.444238899678297, 2.0657219996750378],
						[61.444238849074665, 2.0657220147315889],
						[61.444238759798417, 2.0657220666560794],
						[61.444238723098401, 2.0657221068490199],
						[61.444216580275864, 2.0657636996576518],
						[61.444197199785066, 2.0657636997816429],
						[61.444175000064909, 2.0657637001836004],
						[61.444174944114536, 2.065763716433076],
						[61.4441748509538, 2.0657637805195512],
						[61.444174813648274, 2.0657638264952123],
						[61.444158165011039, 2.0658053999958548],
						[61.444138899916354, 2.0658054006991287],
						[61.444138893583293, 2.0658054002296335],
						[61.444138881903498, 2.0658054009531792],
						[61.444138875570431, 2.0658054004837023],
						[61.444116687695818, 2.0658080997068256],
						[61.444100000248739, 2.0658080994418975],
						[61.444080599962298, 2.0658081001852482],
						[61.444058300184551, 2.0658081002849178],
						[61.444058244234157, 2.0658081165342446],
						[61.444058151073364, 2.0658081806203308],
						[61.444058113767817, 2.06580822659576],
						[61.444041667240761, 2.0658495402003441],
						[61.444019573994751, 2.065811001271749],
						[61.44401953695629, 2.0658109643544083],
						[61.444019449573318, 2.065810912314415],
						[61.444019400310296, 2.0658109007155896],
						[61.4439999999286, 2.0658108995925843],
						[61.443983299910499, 2.0658109002444465],
						[61.44396110018964, 2.0658109006027852],
						[61.443961092965459, 2.0658109003321261],
						[61.44396107861229, 2.0658109016521653],
						[61.443961071388117, 2.06581090138152],
						[61.443941686210259, 2.065813700003825],
						[61.443919527957036, 2.0658137005184489],
						[61.443900181130296, 2.0657721166821745],
						[61.443900143806246, 2.0657720741810541],
						[61.443900054260233, 2.0657720150937298],
						[61.443900000351228, 2.0657720007665281],
						[61.443883300238149, 2.0657719995744501],
						[61.44388329390506, 2.0657719991049666],
						[61.443883281334159, 2.0657720000273412],
						[61.44388327509629, 2.0657720014191918],
						[61.443861086920698, 2.0657748004750132],
						[61.443841700096449, 2.0657748001081857],
						[61.443819400318787, 2.0657748002205594],
						[61.443802922883428, 2.0657748001674872],
						[61.443783479135057, 2.0657360092538335],
						[61.443783442001383, 2.0657359704755338],
						[61.443783352645745, 2.0657359151111518],
						[61.443783299627846, 2.0657359005852092],
						[61.443761099812455, 2.065735899126417],
						[61.443744399794745, 2.0657358998101647],
						[61.443722200074475, 2.0657359002108802],
						[61.443722192850302, 2.0657358999402526],
						[61.443722179388274, 2.0657359010614682],
						[61.443722171368151, 2.0657359028509896],
						[61.44370292657441, 2.0657386795781676],
						[61.443686286071788, 2.0656971266248374],
						[61.443686283112854, 2.0656971216374047],
						[61.443686278977154, 2.0656971112649001],
						[61.443686276018219, 2.0656971062774852],
						[61.443664075723021, 2.065655506263655],
						[61.443664040371559, 2.0656554670879381],
						[61.443663951111063, 2.065655413585294],
						[61.443663899970645, 2.0656554005231502],
						[61.443644399533063, 2.0656553992178339],
						[61.443644394186322, 2.065655400410856],
						[61.443644381615378, 2.0656554013332702],
						[61.443644376173467, 2.0656554006649861],
						[61.443622188302335, 2.0656580999289669],
						[61.443605600022018, 2.0656581001908116],
						[61.443586215872031, 2.0656580992897844],
						[61.443564074119671, 2.0656193007706345],
						[61.44356403708116, 2.0656192638540523],
						[61.443563949793329, 2.0656192136765807],
						[61.443563900435116, 2.0656192002168789],
						[61.443544400093018, 2.06561920079145],
						[61.443522200278601, 2.0656191994047992],
						[61.443505600318737, 2.0656192004074803],
						[61.443486099881397, 2.0656191991183901],
						[61.443486093643521, 2.0656192005102509],
						[61.443486081072592, 2.0656192014326713],
						[61.443486074834723, 2.0656192028245313],
						[61.443463886567805, 2.0656220000735739],
						[61.443447200012884, 2.06562199968677],
						[61.443427799727694, 2.0656220005195509],
						[61.443405599913156, 2.0656219991254838],
						[61.443388899896149, 2.0656219998578877],
						[61.443388893563082, 2.0656219993884521],
						[61.443388880992153, 2.0656220003108481],
						[61.443388874754284, 2.0656220017026992],
						[61.443366687473393, 2.065624800598965],
						[61.443347335784743, 2.0656247999960518],
						[61.443330786054425, 2.0655832254255846],
						[61.44333074389391, 2.0655831764744756],
						[61.443330638022033, 2.0655831153838737],
						[61.443330575106565, 2.065583101184266],
						[61.443308286878569, 2.0655859000344559],
						[61.443288900055649, 2.0655858997628327],
						[61.443266700336558, 2.0655859002493395],
						[61.443250000224566, 2.0655858991349638],
						[61.443230599939547, 2.065585899982231],
						[61.443230593606479, 2.0655858995127843],
						[61.443230581035579, 2.0655859004351984],
						[61.443230574797724, 2.0655859018270442],
						[61.443208428924393, 2.0655886820757461],
						[61.443191886470984, 2.0655471267881471],
						[61.443191848956481, 2.0655470805656617],
						[61.443191755560285, 2.0655470166917089],
						[61.443191699773827, 2.0655470009015389],
						[61.443169399902864, 2.0655469992854396],
						[61.443149999618136, 2.0655470001536704],
						[61.443133408569807, 2.0655469991935451],
						[61.443108467776078, 2.0655081914374263],
						[61.443108431819041, 2.0655081580451906],
						[61.443108348381259, 2.0655081126571684],
						[61.443108300009364, 2.0655081008602276],
						[61.443091699954898, 2.0655081000464781],
						[61.443069400179439, 2.0655081003148519],
						[61.443069392955273, 2.0655081000442723],
						[61.443069378602104, 2.0655081013643861],
						[61.443069371377931, 2.0655081010938079],
						[61.443050126684753, 2.0655108797382375],
						[61.443033486031631, 2.0654693258986776],
						[61.443033449408205, 2.0654692794776763],
						[61.443033356107222, 2.065469217465528],
						[61.443033300225537, 2.0654691998142853],
						[61.443011099616349, 2.0654692005709596],
						[61.44299181641582, 2.0654691998289172],
						[61.442969573277438, 2.0654305000744655],
						[61.442969537225231, 2.0654304648211887],
						[61.44296944984211, 2.0654304127838481],
						[61.442969399592769, 2.0654303995234771],
						[61.442952799634028, 2.0654304006076019],
						[61.442933300089116, 2.0654303992153786],
						[61.442911231998501, 2.0654304005461919],
						[61.442894583625154, 2.065391621576548],
						[61.442894547096927, 2.0653915770170919],
						[61.442894454782198, 2.0653915166678543],
						[61.442894399886839, 2.0653915006792301],
						[61.442869399749313, 2.0653914992764295],
						[61.442852799790806, 2.0653915003778205],
						[61.442836099680044, 2.0653914993532823],
						[61.442836095224443, 2.065391500347467],
						[61.442836084435761, 2.065391500872269],
						[61.442836078993842, 2.0653915002040368],
						[61.442811089021703, 2.0653941997968661],
						[61.442794399794778, 2.0653942001058767],
						[61.44277779974103, 2.0653941993419562],
						[61.442752799698518, 2.0653941997921437],
						[61.442736215875335, 2.0653941991702127],
						[61.44271407376425, 2.0653555017030545],
						[61.442714036725697, 2.0653554647877095],
						[61.442713950233696, 2.0653554125521385],
						[61.442713899984355, 2.0653553992919527],
						[61.442694399644019, 2.0653553999970669],
						[61.442677799590513, 2.0653553992503637],
						[61.442677794243743, 2.0653554004433956],
						[61.442677781672856, 2.0653554013658511],
						[61.442677776230887, 2.0653554006976389],
						[61.442655720028277, 2.0653580838459558],
						[61.442636280622878, 2.0653166148135056],
						[61.442636244189828, 2.0653165721157332],
						[61.442636153942942, 2.0653165169532359],
						[61.442636099938703, 2.0653165007660084],
						[61.442613900126702, 2.065316499553028],
						[61.442597200111607, 2.0653165004216123],
						[61.442577916021015, 2.0653164999562961],
						[61.4425557740974, 2.0652777010352783],
						[61.442555737058811, 2.0652776641202264],
						[61.442555649770853, 2.0652776139451694],
						[61.442555600412639, 2.0652776004863358],
						[61.44253890030258, 2.0652775995125041],
						[61.442516699694998, 2.0652776003828199],
						[61.44249733177832, 2.0652775998179393],
						[61.442480784318121, 2.0652388214989128],
						[61.442480746898703, 2.0652387771390304],
						[61.442480654583946, 2.0652387167909847],
						[61.442480599688579, 2.0652387008027975],
						[61.442455599552453, 2.0652386995010441],
						[61.442439015825492, 2.0652387008128885],
						[61.4424168737877, 2.0651999002541332],
						[61.442416836749096, 2.0651998633393092],
						[61.442416750447457, 2.0651998148270745],
						[61.442416700102903, 2.0651997997060074],
						[61.442397199763825, 2.0651998004964418],
						[61.442380599711321, 2.0651997998223925],
						[61.442358424341457, 2.0651998007570067],
						[61.442339078639549, 2.0651610107544198],
						[61.442339042301647, 2.0651609699184603],
						[61.442338952945839, 2.0651609145580285],
						[61.442338899927897, 2.0651609000335953],
						[61.44231670021253, 2.0651609007789906],
						[61.442300000103231, 2.0651608998594337],
						[61.44228059982143, 2.0651609009329319],
						[61.442280594379504, 2.0651609002647326],
						[61.442280580917476, 2.0651609013861036],
						[61.442280574679607, 2.0651609027779854],
						[61.44225842872217, 2.0651636813383334],
						[61.442241886065197, 2.0651221256591659],
						[61.44224184855058, 2.0651220794385403],
						[61.442241756140568, 2.0651220172300291],
						[61.442241700258883, 2.0651219995797612],
						[61.442219399595643, 2.0651220002838726],
						[61.442200000110034, 2.0651219993182846],
						[61.442199991994727, 2.0651219992466157],
						[61.442199974968212, 2.0651220011633611],
						[61.442199966948102, 2.0651220029529274],
						[61.442183282850735, 2.0651247995783986],
						[61.442158299932579, 2.0651248001582712],
						[61.442141826956821, 2.0651247993977528],
						[61.442122380698557, 2.065083215982892],
						[61.442122338728296, 2.0650831707566857],
						[61.442122235720028, 2.0650831127958029],
						[61.44212217477719, 2.0650831019223546],
						[61.442100128082934, 2.0650858828397647],
						[61.442083486144703, 2.0650443250861703],
						[61.442083448725263, 2.0650442807270686],
						[61.442083356220024, 2.0650442166578671],
						[61.442083300338339, 2.0650441990078101],
						[61.442061224135749, 2.0650442005014429],
						[61.442041878986281, 2.0650055105950491],
						[61.442041841757245, 2.0650054699584626],
						[61.442041753292493, 2.0650054144001326],
						[61.442041700274565, 2.0650053998760476],
						[61.442025000166353, 2.0650053990302979],
						[61.442002926637493, 2.0650053999522195],
						[61.441983481142351, 2.0649638147341873],
						[61.44198343917207, 2.0649637695083354],
						[61.441983336258964, 2.0649637134092784],
						[61.441983275316112, 2.0649637025360961],
						[61.44196121961879, 2.0649664836463066],
						[61.441941881359831, 2.0649249159902707],
						[61.441941844035561, 2.064924873492723],
						[61.441941753693328, 2.0649248164712537],
						[61.441941699689075, 2.0649248002849894],
						[61.441925115869068, 2.0649247998849884],
						[61.441902977794562, 2.0648860086826959],
						[61.441883480725529, 2.0648443146955029],
						[61.441883444292344, 2.0648442719992719],
						[61.441883354045288, 2.0648442168393477],
						[61.44188330004102, 2.0648442006532481],
						[61.441861231860486, 2.0648442004898455],
						[61.441844583838325, 2.0648055208925231],
						[61.44184454730992, 2.0648054763351951],
						[61.441844454995007, 2.0648054159895421],
						[61.44184440009959, 2.064805400002375],
						[61.44182499972554, 2.0648053994177062],
						[61.441802927089768, 2.0648054002733449],
						[61.441783480647935, 2.0647638156421335],
						[61.441783438677597, 2.0647637704168105],
						[61.441783335669207, 2.0647637124575686],
						[61.441783274726347, 2.0647637015848077],
						[61.441761087373472, 2.0647664990114896],
						[61.441744399933107, 2.0647664992226162],
						[61.441724999654539, 2.0647665005192981],
						[61.441702799847562, 2.0647664996562165],
						[61.44168621602865, 2.064766499332253],
						[61.441664073969548, 2.0647277018827737],
						[61.441664036835604, 2.0647276631081768],
						[61.441663949642717, 2.0647276147971456],
						[61.441663900189269, 2.0647275994782821],
						[61.441644399853971, 2.0647276005337525],
						[61.441627799804728, 2.0647276000853458],
						[61.441627794362795, 2.0647275994172292],
						[61.441627780900767, 2.0647276005387485],
						[61.441627774662912, 2.0647276019306777],
						[61.441605587310775, 2.0647303993618431],
						[61.441586099546342, 2.0647303994904824],
						[61.441564035026424, 2.0647304004598106],
						[61.4415473858027, 2.0646888263805283],
						[61.44154734265561, 2.0646887757709482],
						[61.441547235892294, 2.0646887148853694],
						[61.441547171194514, 2.0646887010858501],
						[61.441527786045121, 2.0646915000904107],
						[61.441505599796002, 2.0646915000112926],
						[61.441488899689951, 2.0646914993151944],
						[61.441469400246, 2.0646915001872737],
						[61.441447199548449, 2.0646914995646468],
						[61.441447192324276, 2.0646914992942298],
						[61.441447176284093, 2.0646915028734596],
						[61.441447168168779, 2.0646915028019093],
						[61.441430568399788, 2.0646942035698261],
						[61.441430526006783, 2.0646942205591232],
						[61.441430453757356, 2.064694270564428],
						[61.441430423996209, 2.0646943054416091],
						[61.441408179887112, 2.0647359007970385],
						[61.441388900256584, 2.0647358996491447],
						[61.441388844401502, 2.0647359177598679],
						[61.441388751146, 2.0647359799819438],
						[61.441388713840752, 2.0647360259544842],
						[61.441372064768181, 2.0647776000872993],
						[61.441350000152347, 2.0647775991521056],
						[61.441349946079534, 2.0647776168650211],
						[61.441349856102889, 2.0647776727080212],
						[61.441349818607215, 2.0647777149581068],
						[61.441330472798789, 2.0648191999084897],
						[61.441308300105398, 2.0648192004688775],
						[61.441308291990083, 2.0648192003972925],
						[61.441308274963568, 2.0648192023141165],
						[61.441308266848282, 2.0648192022425254],
						[61.441291666774561, 2.0648220027603257],
						[61.441291621007444, 2.0648220242670479],
						[61.441291543887274, 2.0648220847706784],
						[61.441291513520561, 2.0648221254299153],
						[61.441274865310056, 2.06486369918635],
						[61.441249999572861, 2.0648637001352892],
						[61.441249943622559, 2.0648637163845098],
						[61.441249851353305, 2.0648637802682193],
						[61.441249814048021, 2.0648638262403964],
						[61.44123316493242, 2.0649054001170541],
						[61.441211100315137, 2.0649053990848514],
						[61.441211044460019, 2.0649054171951504],
						[61.44121095120444, 2.0649054794163297],
						[61.441210913899127, 2.0649055253883781],
						[61.441194265070592, 2.0649469993558771],
						[61.441174999981499, 2.0649470004706934],
						[61.441174949282733, 2.0649470136655403],
						[61.441174860101896, 2.0649470674477075],
						[61.441174823306902, 2.0649471057761959],
						[61.441152670223829, 2.0649887198033094],
						[61.441136066519526, 2.0649915021965053],
						[61.441136025112812, 2.0649915208475296],
						[61.441135952958511, 2.0649915727127741],
						[61.441135923993158, 2.0649916055293471],
						[61.441113780141727, 2.0650331007001896],
						[61.44109439956005, 2.0650330992918859],
						[61.441094343704954, 2.0650331174018466],
						[61.441094251340388, 2.0650331794235663],
						[61.44109421403504, 2.0650332253952985],
						[61.44107766402891, 2.0650747994337824],
						[61.441055600396012, 2.0650747999366401],
						[61.441036100056984, 2.0650748007497106],
						[61.441036049358182, 2.0650748139442832],
						[61.441035960177302, 2.0650748677258384],
						[61.441035923382266, 2.0650749060539941],
						[61.441013770160545, 2.0651165179394413],
						[61.440997167835015, 2.0651192039370647],
						[61.440997121081509, 2.06511922378081],
						[61.440997044947537, 2.0651192859453977],
						[61.440997013689568, 2.0651193268027881],
						[61.440980463654377, 2.0651609006640115],
						[61.440958299963469, 2.0651609008376131],
						[61.440958245795358, 2.0651609166884608],
						[61.440958155913705, 2.0651609743907335],
						[61.440958119309045, 2.0651610164409724],
						[61.440938772179543, 2.0652026006552959],
						[61.440922200036432, 2.065202599348845],
						[61.440922149432851, 2.0652026144042797],
						[61.440922060156723, 2.0652026663241121],
						[61.440922024347948, 2.0652027063142859],
						[61.440899880330093, 2.0652441991829633],
						[61.440880599898499, 2.0652441997597268],
						[61.440880549294882, 2.0652442148150731],
						[61.440880460018754, 2.0652442667346911],
						[61.440880424209965, 2.0652443067247619],
						[61.44085816998313, 2.0652859182126284],
						[61.440841667313144, 2.0652887023376079],
						[61.440841623232998, 2.0652887215845106],
						[61.440841548595571, 2.0652887777673024],
						[61.440841518929425, 2.0652888145043677],
						[61.440822072686487, 2.0653303998793326],
						[61.440800000044213, 2.0653304003163018],
						[61.440799944093854, 2.0653304165643975],
						[61.440799850933203, 2.0653304806445569],
						[61.440799814423684, 2.0653305245555225],
						[61.440783164657553, 2.0653719998020836],
						[61.440761100130111, 2.0653720002794955],
						[61.440761044179709, 2.0653720165274976],
						[61.44076095101908, 2.0653720806074398],
						[61.440760913713575, 2.0653721265782581],
						[61.440744265319339, 2.065413699324413],
						[61.440725000225541, 2.0654137001322113],
						[61.440724948635591, 2.0654137135248796],
						[61.440724860345696, 2.0654137671060533],
						[61.440724822659405, 2.0654138056322151],
						[61.440702670310024, 2.0654554185152891],
						[61.440686067879277, 2.0654581024338099],
						[61.44068602637725, 2.0654581192227868],
						[61.440685954222744, 2.0654581710861479],
						[61.440685922679108, 2.0654582063593661],
						[61.440663780151574, 2.0654998000219611],
						[61.440644399660421, 2.0654998001659637],
						[61.440644343709998, 2.065499816413666],
						[61.440644251440389, 2.0654998802941535],
						[61.440644214134863, 2.065499926264637],
						[61.440627663972883, 2.0655414994477055],
						[61.440605600334578, 2.0655414995989765],
						[61.440605546166445, 2.065541515448956],
						[61.440605456284622, 2.0655415731494013],
						[61.440605418788678, 2.0655416153975339],
						[61.440585972762499, 2.0655831004621352],
						[61.44056390011734, 2.0655831007090035],
						[61.440563892002025, 2.0655831006372063],
						[61.440563874975496, 2.0655831025535432],
						[61.440563866860188, 2.0655831024817455],
						[61.440547166706047, 2.0655859022351772],
						[61.44054712182993, 2.0655859235413798],
						[61.440547046396425, 2.0655859817828248],
						[61.440547016029456, 2.0655860224402973],
						[61.440530462375847, 2.065624814092089],
						[61.44050558860188, 2.0656276001917591],
						[61.440488900262636, 2.0656276000961968],
						[61.440466699649413, 2.0656276006214922],
						[61.440447200195862, 2.0656276008653909],
						[61.440447143354334, 2.0656276173115571],
						[61.440447049397605, 2.0656276834499594],
						[61.440447012983157, 2.0656277292211755],
						[61.440430461063656, 2.0656719998624826],
						[61.440408300040126, 2.0656719990502093],
						[61.44039170007769, 2.0656719999163515],
						[61.440366700029429, 2.0656720000183855],
						[61.4403500000099, 2.0656720006175653],
						[61.440349944059491, 2.0656720168647791],
						[61.440349850898656, 2.0656720809429117],
						[61.440349814484193, 2.0656721267139053],
						[61.440333157837458, 2.0657137156192231],
						[61.440311086964066, 2.0657164993551],
						[61.440291700232713, 2.0657165007415292],
						[61.440275000117644, 2.065716499453099],
						[61.440252800394397, 2.0657164997062329],
						[61.440252746226243, 2.0657165155556734],
						[61.440252656344313, 2.0657165732549165],
						[61.440252618848305, 2.065716615502315],
						[61.44023317275974, 2.0657580999765743],
						[61.440211100112386, 2.0657581000830434],
						[61.440211091997078, 2.0657581000111764],
						[61.440211074970534, 2.0657581019273845],
						[61.440211066950411, 2.0657581037166342],
						[61.440194382830782, 2.0657608997538461],
						[61.440174999663469, 2.0657609003126471],
						[61.440152799939675, 2.0657609005294821],
						[61.440136099824137, 2.0657608992123637],
						[61.440136049220413, 2.0657609142664608],
						[61.44013595994403, 2.0657609661832286],
						[61.440135923243929, 2.065761006370511],
						[61.440113779717848, 2.0658025992621889],
						[61.440094400209716, 2.0658026008576416],
						[61.440094392094402, 2.0658026007857808],
						[61.440094375067865, 2.0658026027019516],
						[61.440094366952565, 2.0658026026300718],
						[61.440077782983678, 2.0658054007560183],
						[61.440055600285902, 2.0658053990204071],
						[61.440055546212911, 2.0658054167306772],
						[61.44005545623574, 2.0658054725681563],
						[61.440055418739703, 2.0658055148151857],
						[61.440035972714206, 2.0658470008259386],
						[61.440013900065772, 2.0658470008602423],
						[61.43999719994941, 2.065846999492106],
						[61.439997145781213, 2.0658470153411601],
						[61.439997055994425, 2.0658470749005953],
						[61.439997019294282, 2.065847115087589],
						[61.439977664669755, 2.0658887142443261],
						[61.439955575076496, 2.0658915019829167],
						[61.439955525649466, 2.0658915224211492],
						[61.439955445855375, 2.0658915835157576],
						[61.439955413706066, 2.0658916245697245],
						[61.43993876473295, 2.0659331000351968],
						[61.439916700198829, 2.0659331000758572],
						[61.439916644248342, 2.065933116322316],
						[61.439916551087386, 2.0659331803987087],
						[61.439916513781668, 2.0659332263675356],
						[61.439899865288858, 2.0659747995288167],
						[61.439880600189234, 2.0659747999552618],
						[61.439880548599227, 2.0659748133465672],
						[61.43988046030902, 2.065974866924543],
						[61.439880423513671, 2.0659749052501453],
						[61.439858179952545, 2.0660164992350918],
						[61.439841699839192, 2.0660164995137498],
						[61.439841649235468, 2.0660165145672513],
						[61.439841560850084, 2.0660165662839516],
						[61.439841524149891, 2.0660166064705359],
						[61.439819270849384, 2.0660581159558049],
						[61.439799970692874, 2.066060902442854],
						[61.439799923939191, 2.0660609222843171],
						[61.43979984503617, 2.0660609831793515],
						[61.439799813873066, 2.0660610258952192],
						[61.439783165432928, 2.0661026006918566],
						[61.439761100005711, 2.0661026008039824],
						[61.439761045837457, 2.0661026166524699],
						[61.439760955860152, 2.0661026724885119],
						[61.43976091925515, 2.0661027145359556],
						[61.439741573187057, 2.0661442002296733],
						[61.439719399586956, 2.0661441999712999],
						[61.439719343636469, 2.066144216217249],
						[61.439719251366519, 2.0661442800937637],
						[61.439719214060737, 2.0661443260620533],
						[61.439702663772358, 2.0661858995091471],
						[61.439683300395785, 2.0661858991321354],
						[61.43968324632273, 2.0661859168414964],
						[61.439683156440559, 2.0661859745382176],
						[61.439683118944387, 2.0661860167842576],
						[61.439663764301514, 2.0662276171659584],
						[61.439644386455853, 2.0662304001518677],
						[61.439627799950514, 2.0662303995729152],
						[61.439605600221249, 2.0662303994294642],
						[61.439586099870226, 2.0662303994593891],
						[61.439563900140904, 2.0662303993140054],
						[61.439563892025589, 2.0662303992420261],
						[61.439563875985364, 2.0662304028201834],
						[61.439563867870049, 2.0662304027482028],
						[61.439547184132792, 2.0662331004840828],
						[61.439527800069392, 2.0662331009213797],
						[61.439505600340013, 2.0662331007713521],
						[61.439488900220219, 2.0662330991783278],
						[61.439488848725347, 2.0662331144300374],
						[61.43948876033982, 2.0662331661454854],
						[61.43948872274845, 2.0662332065301188],
						[61.43946657100458, 2.0662748169380283],
						[61.439447185932892, 2.0662775995982119],
						[61.439430600413338, 2.066277600649534],
						[61.439408299735177, 2.0662776003944088],
						[61.439388900331757, 2.066277600455575],
						[61.439388893998697, 2.0662775999859946],
						[61.439388881427782, 2.0662776009079145],
						[61.439388875189913, 2.0662776022993996],
						[61.439366686988357, 2.0662804003825084],
						[61.439349999534208, 2.0662803996983832],
						[61.439330719982941, 2.0662803993719541],
						[61.439308477774816, 2.0662388083079763],
						[61.439289078997859, 2.0661999108844653],
						[61.439289078011583, 2.0661999092221981],
						[61.439289077025244, 2.0661999075599433],
						[61.439289076930066, 2.0661999056988942],
						[61.439266881910491, 2.0661582157488572],
						[61.43925018618647, 2.0661166242264684],
						[61.439250143135133, 2.0661165754794437],
						[61.439250036372421, 2.0661165145929075],
						[61.439249971769961, 2.0661165026521582],
						[61.43923071032598, 2.0661191825141705],
						[61.439208475755912, 2.0660777066846943],
						[61.439208438622359, 2.0660776679113337],
						[61.439208351144387, 2.0660776140167085],
						[61.439208299908792, 2.0660775990942462],
						[61.439189015903679, 2.0660775998816212],
						[61.439166874059097, 2.0660388007494044],
						[61.439166837020778, 2.0660387638371818],
						[61.439166749733175, 2.0660387136648057],
						[61.439166700375004, 2.066038700205886],
						[61.439150000351745, 2.0660387005663354],
						[61.439130716251746, 2.0660386995144169],
						[61.439108474340436, 2.0659999002137428],
						[61.439108437302103, 2.0659998633016348],
						[61.439108350014472, 2.0659998131294572],
						[61.439108299765195, 2.0659997998694006],
						[61.439091699799263, 2.0659998005154794],
						[61.439069527982369, 2.0659997999321846],
						[61.439050181018388, 2.0659582166753054],
						[61.439050143694473, 2.0659581741802029],
						[61.43905005414863, 2.0659581151006381],
						[61.439050000239654, 2.0659581007748002],
						[61.439033409185292, 2.0659580994531774],
						[61.439008479044993, 2.0659193070973254],
						[61.438991885569955, 2.065877725218014],
						[61.438991883597339, 2.0658777218935276],
						[61.438991881339156, 2.0658777129859729],
						[61.43899187936654, 2.0658777096614833],
						[61.438972379356592, 2.0658388090628632],
						[61.438972377574345, 2.0658388094604492],
						[61.438972377479168, 2.0658388075994218],
						[61.438972377383969, 2.0658388057383958],
						[61.438950177236677, 2.0657971062902165],
						[61.438950140103067, 2.0657970675174937],
						[61.438950050842756, 2.0657970140216531],
						[61.438949999702352, 2.0657970009607682],
						[61.438933408649177, 2.0657969997215373],
						[61.438908479357693, 2.0657582074182752],
						[61.438891885836533, 2.0657166257581392],
						[61.438891884755023, 2.0657166222348815],
						[61.438891881796103, 2.0657166172482011],
						[61.438891880809756, 2.0657166155859845],
						[61.438872380801385, 2.0656749144019075],
						[61.438872378923946, 2.0656749129384813],
						[61.438872375774608, 2.0656749042297604],
						[61.438872373801978, 2.0656749009053161],
						[61.438850181264598, 2.065636012090494],
						[61.438833485884771, 2.0655943252959159],
						[61.438833481939511, 2.0655943186470278],
						[61.438833474844905, 2.0655943032894371],
						[61.438833470899645, 2.0655942966405645],
						[61.438808478741798, 2.0655527084292813],
						[61.438791884244907, 2.0655138226046752],
						[61.438791883163432, 2.0655138190814419],
						[61.438791882177078, 2.0655138174192365],
						[61.438791881190788, 2.0655138157570181],
						[61.438772381123904, 2.0654721148339035],
						[61.438772292564259, 2.0654720574184231],
						[61.438772113837111, 2.0654720521168382],
						[61.438772022873735, 2.065472106290517],
						[61.438749822778007, 2.0655138061254168],
						[61.438749814913876, 2.065513828584586],
						[61.438749802940528, 2.0655138764297538],
						[61.438749799627246, 2.0655138997559268],
						[61.438749800010186, 2.0655555614174514],
						[61.438733158280947, 2.0655970168694187],
						[61.438711074836654, 2.0655998013929575],
						[61.438711027191921, 2.0655998214331057],
						[61.438710948193894, 2.0655998804659776],
						[61.438710916044627, 2.0655999215185155],
						[61.438694217341407, 2.0656388184511423],
						[61.438674866320035, 2.0656804151443566],
						[61.438652776045643, 2.0656831013650434],
						[61.438652726618663, 2.0656831218026062],
						[61.438652645933502, 2.0656831830937485],
						[61.438652613879412, 2.065683226007144],
						[61.438635918288554, 2.0657249153715576],
						[61.438613779803724, 2.0657664994387854],
						[61.438594400199584, 2.0657664991347127],
						[61.43859434434431, 2.0657665172416264],
						[61.438594251088197, 2.0657665794545115],
						[61.438594213782515, 2.0657666254214955],
						[61.438577617631879, 2.0658082153016388],
						[61.438555480105727, 2.0658498008929498],
						[61.438538899935736, 2.0658498009484094],
						[61.438538849236842, 2.065849814140412],
						[61.438538760055529, 2.0658498679149724],
						[61.438538723260194, 2.0658499062389595],
						[61.438516523039539, 2.0658916054886776],
						[61.438516522243582, 2.0658916075484677],
						[61.438516519855789, 2.0658916137278425],
						[61.438516519059839, 2.0658916157876326],
						[61.438497063233719, 2.0659331206283733],
						[61.438480566778495, 2.0659359027268356],
						[61.438480525276383, 2.0659359195137976],
						[61.438480453121606, 2.0659359713720531],
						[61.438480424251189, 2.0659360060457916],
						[61.438458124040046, 2.0659777067491785],
						[61.438458122257792, 2.0659777071467484],
						[61.438458119869971, 2.0659777133261104],
						[61.438458119074042, 2.065977715385888],
						[61.438438773018319, 2.0660191993659254],
						[61.438416700366567, 2.0660191992042849],
						[61.43841664362013, 2.0660192175094028],
						[61.438416551255024, 2.0660192795223642],
						[61.438416513949257, 2.0660193254887722],
						[61.438399814363116, 2.0660610265151376],
						[61.43838316500289, 2.0661025995921416],
						[61.438361099574415, 2.0661025996395375],
						[61.438361046297274, 2.0661026152883539],
						[61.438360956415103, 2.0661026729826322],
						[61.438360918918939, 2.0661027152268598],
						[61.438341572917018, 2.0661442008592465],
						[61.438324999873551, 2.0661441991602634],
						[61.438324949269806, 2.0661442142126472],
						[61.43832485999318, 2.0661442661249723],
						[61.438324823292952, 2.0661443063093277],
						[61.43830267066177, 2.0661859153678499],
						[61.438283272318557, 2.0661887027746308],
						[61.438283228047879, 2.0661887182964773],
						[61.4382831541107, 2.0661887705513591],
						[61.438283122566837, 2.066188805820969],
						[61.438260979947913, 2.0662303993788185],
						[61.438244399774675, 2.0662303992213715],
						[61.438225000370828, 2.0662303992646138],
						[61.438224948875913, 2.066230414515597],
						[61.438224859599288, 2.0662304664274842],
						[61.438224823790129, 2.0662305064128303],
						[61.438202679676593, 2.0662720004339596],
						[61.438186100394141, 2.0662720000535515],
						[61.438186094061074, 2.0662719995839938],
						[61.438186080599017, 2.066272000704608],
						[61.438186075252254, 2.0662720018972562],
						[61.438163875463523, 2.0662748024043904],
						[61.438163826036416, 2.0662748228406511],
						[61.43816374624209, 2.0662748839304577],
						[61.438163714187787, 2.0662749268425524],
						[61.438147064755526, 2.0663164994858998],
						[61.438127799651312, 2.0663164996301533],
						[61.438105599919759, 2.0663164993555481],
						[61.438105544064392, 2.066316517461154],
						[61.438105450807996, 2.0663165796713354],
						[61.438105414393206, 2.0663166254381307],
						[61.438088765343977, 2.0663581000166444],
						[61.43806669991249, 2.0663580998693538],
						[61.438066645744208, 2.0663581157163202],
						[61.438066555861923, 2.0663581734092635],
						[61.438066519256765, 2.0663582154539979],
						[61.438047072809034, 2.0663998000845196],
						[61.438030599915606, 2.0663998003685449],
						[61.438030593582532, 2.0663997998989601],
						[61.438030581011624, 2.0663998008207551],
						[61.438030574773741, 2.0663998022121417],
						[61.438008274923561, 2.0664026023387652],
						[61.438008228965735, 2.0664026201186623],
						[61.438008149872118, 2.0664026772871011],
						[61.438008119314482, 2.0664027142183725],
						[61.437988773210797, 2.0664441991859039],
						[61.437966699758206, 2.0664442007618704],
						[61.437949999730883, 2.0664442008628048],
						[61.437949946453699, 2.0664442165107837],
						[61.437949855680209, 2.0664442744020186],
						[61.437949819075065, 2.0664443164464763],
						[61.437930471668501, 2.0664858994707331],
						[61.437908299940574, 2.0664859003482952],
						[61.437908291825259, 2.0664859002762062],
						[61.437908274798694, 2.0664859021917938],
						[61.437908266778543, 2.0664859039806913],
						[61.437891666650316, 2.066488703187944],
						[61.437891625148112, 2.0664887199737905],
						[61.437891552993079, 2.0664887718297273],
						[61.437891524122477, 2.0664888065022753],
						[61.43786927956095, 2.0665303994401691],
						[61.437850000101378, 2.0665304007601057],
						[61.437833300073237, 2.0665304008107275],
						[61.43781110033914, 2.0665304003699054],
						[61.437791700040989, 2.0665304004073901],
						[61.437791693707929, 2.0665303999377582],
						[61.437791682028106, 2.0665304006607301],
						[61.437791675695053, 2.0665304001911169],
						[61.43776938782235, 2.0665330996453006],
						[61.437752926702657, 2.0665331009872006],
						[61.437733481154837, 2.0664916145286778],
						[61.437733439185074, 2.0664915693065224],
						[61.437733336272629, 2.0664915132091326],
						[61.437733276125819, 2.0664915002741591],
						[61.437711088311296, 2.0664942000170163],
						[61.437694399963199, 2.0664941993592105],
						[61.437674999665262, 2.0664941994145156],
						[61.437652800026569, 2.0664942008530982],
						[61.437636099998599, 2.0664942009162353],
						[61.437636093665567, 2.0664942004466234],
						[61.43763608109461, 2.0664942013683656],
						[61.437636074856719, 2.0664942027597109],
						[61.437613886644442, 2.0664970004497101],
						[61.437594399846574, 2.0664970009717427],
						[61.437577799780506, 2.0664969994390825],
						[61.437555600141685, 2.0664970008711632],
						[61.437536099786513, 2.0664970006503491],
						[61.437536094344587, 2.0664969999819793],
						[61.437536080882516, 2.0664970011024728],
						[61.43753607464464, 2.0664970024938114],
						[61.437513887323163, 2.0664997999694252],
						[61.437497199865973, 2.0664997991029699],
						[61.437477799663043, 2.0664998010077023],
						[61.437455599928938, 2.0664998005721698],
						[61.437455591813638, 2.0664998005000856],
						[61.437455574787052, 2.0664998024156263],
						[61.437455566671723, 2.0664998023435461],
						[61.437439000629837, 2.0665025807086694],
						[61.437414072001111, 2.066460996374917],
						[61.437414035058097, 2.0664609613250668],
						[61.437413948756948, 2.0664609128161828],
						[61.437413900289961, 2.0664608991583977],
						[61.437397328324828, 2.0664609007817805],
						[61.43737798133948, 2.0664193156729582],
						[61.437377979366858, 2.0664193123486041],
						[61.437377976217562, 2.066419303640231],
						[61.43737797424496, 2.0664193003158724],
						[61.437355773841922, 2.0663805011394003],
						[61.437355736803703, 2.0663804642287782],
						[61.43735565031217, 2.0663804119983347],
						[61.437355600158106, 2.0663804005991562],
						[61.437339016323392, 2.0663803992644874],
						[61.43731687360863, 2.0663416004687898],
						[61.437316836570396, 2.0663415635582396],
						[61.437316750174041, 2.0663415131888923],
						[61.4373166999248, 2.0663414999288552],
						[61.437297335653753, 2.0663414995570841],
						[61.437280785968305, 2.0662999268778104],
						[61.437280782819023, 2.0662999181694581],
						[61.437280774928574, 2.0662999048721522],
						[61.437280771779292, 2.0662998961637968],
						[61.43725577227017, 2.066258196245681],
						[61.437255727912536, 2.0662581572035883],
						[61.437255624584786, 2.0662581106111459],
						[61.437255567396903, 2.0662581026632529],
						[61.437239009381571, 2.0662608793429729],
						[61.437216879100369, 2.06621931120391],
						[61.437197380944404, 2.0661777150159581],
						[61.43719733709716, 2.0661776683315733],
						[61.437197229728973, 2.0661776132299101],
						[61.437197167003944, 2.0661776027529459],
						[61.437180709047901, 2.0661803797359002],
						[61.437158478686904, 2.0661388114631656],
						[61.437139080867297, 2.0660971158489354],
						[61.437139038897463, 2.0660970706281425],
						[61.437138935889664, 2.0660970126720826],
						[61.437138874946918, 2.0660970017977447],
						[61.437116819298723, 2.0660997833109267],
						[61.437097384085313, 2.0660582203363029],
						[61.437080786362579, 2.0660166267739051],
						[61.437080744202369, 2.0660165778314514],
						[61.437080639126762, 2.0660165146905554],
						[61.437080576211358, 2.0660165004920934],
						[61.437058420014445, 2.0660191840255298],
						[61.437039082560794, 2.0659777179423884],
						[61.437022383926205, 2.0659388215333938],
						[61.437022347398262, 2.0659387769812412],
						[61.437022255083917, 2.0659387166406114],
						[61.437022200188679, 2.0659387006533678],
						[61.437000128427634, 2.06593870028451],
						[61.436980781294785, 2.065897116025035],
						[61.436980780213304, 2.0658971125019918],
						[61.436980778145511, 2.0658971073167969],
						[61.436980776268072, 2.0658971058534421],
						[61.436958477599461, 2.0658555080938648],
						[61.436939078663137, 2.0658166118958898],
						[61.436939042230328, 2.0658165692049058],
						[61.436938952970067, 2.0658165157120876],
						[61.436938899857019, 2.0658164993275303],
						[61.436922316121702, 2.0658165001396842],
						[61.436900178095577, 2.0657777087052076],
						[61.436880781159701, 2.0657360153207418],
						[61.436880780173411, 2.0657360136586149],
						[61.436880778105589, 2.0657360084734693],
						[61.436880776132973, 2.0657360051492062],
						[61.436858477720357, 2.0656943078166394],
						[61.436839083275842, 2.0656555181131475],
						[61.436822385777745, 2.0656138268840367],
						[61.436822383709938, 2.0656138216989066],
						[61.436822378683161, 2.0656138115274194],
						[61.436822376615339, 2.0656138063422742],
						[61.436800178129523, 2.0655721075801492],
						[61.436780800115692, 2.0655332522424645],
						[61.436780799844513, 2.065491700479992],
						[61.436780796824785, 2.0654916766859022],
						[61.436780785343345, 2.0654916284295317],
						[61.436780776085733, 2.0654916060269235],
						[61.436758477602623, 2.0654499090035365],
						[61.436739083298662, 2.0654110178963436],
						[61.436722385729396, 2.0653693269336793],
						[61.436722381688938, 2.0653693184243642],
						[61.436722374689516, 2.0653693049287263],
						[61.436722370649051, 2.0653692964194263],
						[61.436697400068766, 2.0653277458080015],
						[61.436697400284068, 2.0652889005376651],
						[61.436697398345807, 2.0652888802666469],
						[61.436697391095201, 2.0652888442415667],
						[61.436697385687623, 2.0652888266266021],
						[61.436680785786272, 2.0652471252834022],
						[61.4366807838136, 2.065247121959199],
						[61.436680778786851, 2.0652471117878077],
						[61.436680775827881, 2.0652471068015221],
						[61.436658477768752, 2.0652055078475673],
						[61.436639079548776, 2.0651666122305317],
						[61.436619581297407, 2.0651249157905878],
						[61.436619579324777, 2.0651249124664095],
						[61.436619576175403, 2.065124903758361],
						[61.436619574202787, 2.0651249004341645],
						[61.436597380567413, 2.065086013068711],
						[61.436580800299382, 2.0650443621566641],
						[61.436580800202513, 2.0650027993067788],
						[61.43658079610119, 2.0650027719899122],
						[61.436580779783164, 2.0650027172845062],
						[61.436580767661738, 2.0650026917568138],
						[61.436555779300562, 2.0649638087703712],
						[61.436539085641627, 2.0649221268047429],
						[61.436539085451223, 2.0649221230829866],
						[61.436539081601111, 2.0649221182955402],
						[61.436539081410707, 2.064922114573787],
						[61.436519580814696, 2.0648805149169265],
						[61.436519578842052, 2.0648805115927553],
						[61.436519575787869, 2.0648805047456555],
						[61.436519573815225, 2.0648805014214893],
						[61.436497380910197, 2.0648416122953783],
						[61.43648079662578, 2.0647999550003746],
						[61.436477999806328, 2.0647582878237705],
						[61.436477968373481, 2.0647582195516287],
						[61.436477868107183, 2.06475812711134],
						[61.436477800069696, 2.0647581008835068],
						[61.436461216247295, 2.0647581003824818],
						[61.436439077843005, 2.0647193084125259],
						[61.436419581133926, 2.0646777156645548],
						[61.436419539163666, 2.0646776704466827],
						[61.43641943615539, 2.0646776124965363],
						[61.436419375212523, 2.0646776016251356],
						[61.436397329409218, 2.0646803818575137],
						[61.436380786340408, 2.0646388266211031],
						[61.436380785258891, 2.06463882309817],
						[61.436380782299899, 2.0646388181119759],
						[61.436380781218396, 2.064638814589042],
						[61.436361280842171, 2.0645971155014413],
						[61.436361278869526, 2.0645971121773226],
						[61.436361275720152, 2.0645971034693948],
						[61.436361273747508, 2.0645971001452756],
						[61.436339077903355, 2.0645582082915155],
						[61.436319581148403, 2.0645166157771158],
						[61.436319580066858, 2.0645166122542018],
						[61.436319577998987, 2.0645166070692174],
						[61.436319577012647, 2.0645166054071669],
						[61.436297377224861, 2.0644749064372507],
						[61.436297331785255, 2.0644748638759283],
						[61.436297228171384, 2.0644748117080702],
						[61.436297167323737, 2.0644748026979549],
						[61.436280717339052, 2.0644775768172754],
						[61.436261280673932, 2.0644360143076987],
						[61.436261278796465, 2.0644360128444679],
						[61.436261275647091, 2.0644360041365823],
						[61.436261273674432, 2.0644360008124845],
						[61.436239077692434, 2.0643971073359819],
						[61.436219580891539, 2.0643555150551887],
						[61.436219579905199, 2.0643555133931466],
						[61.436219577837342, 2.0643555082082043],
						[61.436219576850988, 2.0643555065461472],
						[61.436197399893359, 2.0643138497723355],
						[61.43619739954228, 2.0642749995621728],
						[61.436197398399784, 2.0642749772318676],
						[61.43619738818991, 2.0642749362214774],
						[61.436197380809553, 2.0642749152828976],
						[61.436177984235663, 2.0642332217176449],
						[61.436161300118634, 2.0641916608112498],
						[61.436161300164926, 2.0641500005810243],
						[61.436161297049757, 2.0641499749266981],
						[61.436161283690446, 2.0641499252085573],
						[61.436161274337422, 2.0641499009459592],
						[61.436139096222675, 2.064111041437342],
						[61.436136299866014, 2.0640693878176837],
						[61.436136297226888, 2.0640693714676375],
						[61.436136290166374, 2.0640693391652047],
						[61.436136285840199, 2.0640693250736688],
						[61.436119599702458, 2.0640277609885005],
						[61.436119599590846, 2.0639888995168252],
						[61.436119596570848, 2.0639888757233824],
						[61.436119585184116, 2.0639888293294568],
						[61.436119576817397, 2.0639888067289665],
						[61.436097399753045, 2.0639471503528379],
						[61.436097399901236, 2.0639055993352691],
						[61.436097396976436, 2.0639055774027035],
						[61.436097387467164, 2.063905532472051],
						[61.436097379195623, 2.0639055117324538],
						[61.436077980339739, 2.0638666120452411],
						[61.436058499942213, 2.0638249563945701],
						[61.436058500068491, 2.0637833000309418],
						[61.436058471213315, 2.0637832293033807],
						[61.436058371456703, 2.0637831292252042],
						[61.436058299664118, 2.0637831000734215],
						[61.436036232380694, 2.0637831001939455],
						[61.436019584088513, 2.063744320955943],
						[61.436019546668767, 2.063744276606605],
						[61.436019455244718, 2.0637442160754578],
						[61.436019400349231, 2.0637442000924922],
						[61.435997326733109, 2.0637441997697503],
						[61.435977981245664, 2.0637027155767402],
						[61.435977937397659, 2.0637026688976285],
						[61.435977830028605, 2.0637026138064498],
						[61.435977767303477, 2.0637026033347343],
						[61.435961201147698, 2.0637053792795554],
						[61.435936280410381, 2.0636638103742588],
						[61.435919586184319, 2.0636221262176622],
						[61.435919544023292, 2.0636220772802525],
						[61.435919438055613, 2.063622014348423],
						[61.435919375235251, 2.0636220020160438],
						[61.435897319566045, 2.0636247832529828],
						[61.435877980644271, 2.0635832168638317],
						[61.435877944210823, 2.0635831741768769],
						[61.435877853773022, 2.0635831153084601],
						[61.435877799768669, 2.0635830991269999],
						[61.435861219614914, 2.0635831002964955],
						[61.435839077833776, 2.063541607921946],
						[61.435819578919897, 2.0635027103818833],
						[61.435819541690549, 2.0635026697547589],
						[61.435819453225392, 2.0635026142106891],
						[61.435819400207336, 2.0635025996913932],
						[61.435802909227327, 2.0635025998254988],
						[61.435777979077336, 2.0634638075564022],
						[61.435761285780828, 2.0634221253794411],
						[61.43576124925216, 2.0634220808319967],
						[61.435761155855261, 2.0634220169782918],
						[61.435761099973419, 2.0634219993340039],
						[61.435739027251593, 2.0634219990289013],
						[61.435719580725561, 2.0633805153159548],
						[61.43571953687745, 2.0633804686376518],
						[61.435719431290543, 2.0633804131503357],
						[61.435719367674281, 2.0633804028781388],
						[61.435702901979376, 2.0633830812651421],
						[61.435677970988714, 2.0633415961523855],
						[61.435677934936109, 2.063341560909266],
						[61.435677848634008, 2.06334151241149],
						[61.435677800261999, 2.0633415006199889],
						[61.435661216355143, 2.0633414989848649],
						[61.435639073534773, 2.0633027004994147],
						[61.435639037386942, 2.0633026633955653],
						[61.435638950098472, 2.0633026132359755],
						[61.435638899849003, 2.0633025999814039],
						[61.435619536400836, 2.0633025993718679],
						[61.435602985651052, 2.0632610268289886],
						[61.435602949027071, 2.0632609804211461],
						[61.435602855630115, 2.0632609165682174],
						[61.435602799843494, 2.0632609007851084],
						[61.435580722572404, 2.063260899720269],
						[61.435561278691154, 2.0632221108294453],
						[61.435561242352833, 2.063222070004143],
						[61.435561152996485, 2.0632220146601252],
						[61.435561099978408, 2.0632220001414026],
						[61.435539031809348, 2.0632220008354434],
						[61.435522384166248, 2.0631832204501617],
						[61.435522346841559, 2.0631831779629892],
						[61.435522255322056, 2.0631831155735711],
						[61.435522200426533, 2.0631830995917944],
						[61.435502919914626, 2.0631830993666602],
						[61.435480776144828, 2.0631416062822665],
						[61.435480739901706, 2.0631415673179676],
						[61.435480650640535, 2.0631415138351232],
						[61.435480600391053, 2.0631415005808602],
						[61.435461216162835, 2.0631414990560009],
						[61.435439074182128, 2.063102700757617],
						[61.435439037143105, 2.0631026638530536],
						[61.435438949854586, 2.0631026136943156],
						[61.435438899605089, 2.0631026004401338],
						[61.435422200398783, 2.0631026001852355],
						[61.435402916323028, 2.0631026008012836],
						[61.435380774237423, 2.0630638007404065],
						[61.435380737198415, 2.0630637638359537],
						[61.435380649909909, 2.063063713677419],
						[61.435380599660412, 2.0630637004233034],
						[61.435364013082193, 2.0630636995223548],
						[61.435339072345364, 2.0630220968095982],
						[61.435339035401519, 2.0630220617660662],
						[61.435338949099354, 2.0630220132696451],
						[61.435338899740977, 2.0630219998167822],
						[61.435322199644112, 2.0630219998004611],
						[61.435322192419903, 2.0630219995304162],
						[61.435322175393416, 2.0630220014476395],
						[61.435322167373336, 2.0630220032372324],
						[61.435305701292506, 2.0630247797200365],
						[61.435280772381468, 2.062983197057533],
						[61.435280735437644, 2.0629831620141053],
						[61.435280649135429, 2.0629831135178964],
						[61.435280599777066, 2.0629831000650953],
						[61.435264015967995, 2.0629831004678918],
						[61.435241873852007, 2.062944300660841],
						[61.435241836812921, 2.0629442637567155],
						[61.435241750415486, 2.0629442133998541],
						[61.435241700165989, 2.0629442001459948],
						[61.435222199747109, 2.0629442001949196],
						[61.435205719559754, 2.0629442000787872],
						[61.435183478436777, 2.0629027085579366],
						[61.435164079319314, 2.0628638106188455],
						[61.435164042089802, 2.0628637699933208],
						[61.435163952733276, 2.0628637146509625],
						[61.435163899715221, 2.0628637001330001],
						[61.435147313138437, 2.0628636993310709],
						[61.435122372346832, 2.0628220970607281],
						[61.435122327988324, 2.0628220580260663],
						[61.435122224659594, 2.062822011447822],
						[61.435122167471619, 2.0628220035065383],
						[61.435105708524979, 2.0628247784793494],
						[61.435083475844863, 2.0627832062379134],
						[61.435083439601655, 2.062783167274524],
						[61.435083351231484, 2.0627831135944747],
						[61.435083300090874, 2.0627831005397472],
						[61.435064034921744, 2.0627831008857416],
						[61.435047386154075, 2.0627416245911312],
						[61.435047344088005, 2.0627415775169902],
						[61.435047239011077, 2.0627415143909236],
						[61.435047176095459, 2.0627415001997567],
						[61.435025119955448, 2.0627441841860983],
						[61.435005781190043, 2.062702715384527],
						[61.435005743865204, 2.0627026728986042],
						[61.435005654413452, 2.062702615696197],
						[61.435005600409006, 2.0627025995166255],
						[61.434983424072712, 2.0627026002249065],
						[61.434964079118437, 2.0626638110078788],
						[61.434964041888826, 2.0626637703828341],
						[61.434963953423392, 2.0626637148425351],
						[61.434963900405315, 2.062663700324971],
						[61.434947320163047, 2.062663700091468],
						[61.434925177346081, 2.0626221062275949],
						[61.43492513279709, 2.0626220634718844],
						[61.434925031060089, 2.0626220127753103],
						[61.434924971008265, 2.0626220017094372],
						[61.434905710248181, 2.0626247827971484],
						[61.434883476335955, 2.0625832055767654],
						[61.434883440092676, 2.0625831666138281],
						[61.434883350831356, 2.0625831131335071],
						[61.434883299690696, 2.0625831000792001],
						[61.434866823070443, 2.0625830993470613],
						[61.434847378919379, 2.0625443098916185],
						[61.434847341689775, 2.0625442692668696],
						[61.434847253319511, 2.0625442155878506],
						[61.434847200206157, 2.0625441992097904],
						[61.434825000416666, 2.0625441996113856],
						[61.434808300323247, 2.0625441998333303],
						[61.434786099642579, 2.062544200431971],
						[61.434766700175118, 2.0625442007705188],
						[61.434766692059831, 2.0625442006994246],
						[61.434766675033316, 2.062544202616845],
						[61.434766666918016, 2.0625442025457574],
						[61.434749982879353, 2.0625469996215262],
						[61.434727800211434, 2.0625469999599355],
						[61.434708299795815, 2.0625470002366191],
						[61.434686100006132, 2.0625470006297619],
						[61.434669399912622, 2.0625470008453504],
						[61.434669392688463, 2.0625470005754032],
						[61.43466937922642, 2.0625470016974234],
						[61.434669371111148, 2.0625470016263079],
						[61.434649985915875, 2.0625497992029382],
						[61.434627799683383, 2.0625498003301224],
						[61.43461109958978, 2.0625498005421532],
						[61.434586100368982, 2.062549800788597],
						[61.434586092253681, 2.0625498007175027],
						[61.434586075227202, 2.062549802634897],
						[61.434586067111915, 2.0625498025638009],
						[61.434569383072677, 2.0625525996140293],
						[61.434552800157704, 2.0625526000225247],
						[61.434527800045686, 2.0625526004624972],
						[61.434511099952033, 2.0625526006695409],
						[61.434494399858323, 2.0625526008760033],
						[61.43449439441639, 2.0625526002083543],
						[61.434494382736617, 2.062552600932658],
						[61.434494378185789, 2.0625526000661427],
						[61.434469388876444, 2.0625554003875779],
						[61.434452799628303, 2.0625554003223088],
						[61.434430599838294, 2.0625554006975491],
						[61.434411231845303, 2.0625553996371755],
						[61.434394584037356, 2.0625166209859827],
						[61.434394546617277, 2.0625165766403279],
						[61.434394455192731, 2.0625165161156764],
						[61.434394400297201, 2.0625165001355232],
						[61.434372327583731, 2.0625165004090937],
						[61.434352980981615, 2.0624749157674187],
						[61.434352979900019, 2.062474912244797],
						[61.434352975954631, 2.0624749055972322],
						[61.43435297388671, 2.0624749004127199],
						[61.434330781152717, 2.0624360135917685],
						[61.434314086304944, 2.0623943266289708],
						[61.434314084237009, 2.0623943214444544],
						[61.43431408118272, 2.0623943145980586],
						[61.434314079114785, 2.0623943094135573],
						[61.43429457942041, 2.0623555105468259],
						[61.434294578434049, 2.0623555088849312],
						[61.434294577447709, 2.0623555072230477],
						[61.434294577352482, 2.0623555053623117],
						[61.434272376772306, 2.0623138068726785],
						[61.434272339542623, 2.0623137662488991],
						[61.434272251267537, 2.0623137144321761],
						[61.434272200031678, 2.0623136995177433],
						[61.434252920417919, 2.0623136995815861],
						[61.434230779673904, 2.0622721139141729],
						[61.434214083527351, 2.0622332203154117],
						[61.434214082636231, 2.0622332205142664],
						[61.434214081554643, 2.0622332169916695],
						[61.434214080568303, 2.0622332153297962],
						[61.434194580714774, 2.062191614441967],
						[61.434194578742073, 2.0621916111182275],
						[61.434194575687805, 2.0621916042718706],
						[61.434194573715097, 2.0621916009481311],
						[61.434172377851503, 2.0621527076784978],
						[61.43415298058946, 2.0621110162462242],
						[61.434152980398999, 2.0621110125247757],
						[61.434152978331035, 2.0621110073403046],
						[61.434152977344681, 2.0621110056784415],
						[61.434130799571371, 2.0620693494953994],
						[61.434130800004453, 2.0620305994588533],
						[61.434130800006457, 2.0619889004560639],
						[61.434130799900423, 2.0619471995933703],
						[61.434130799577744, 2.0619056004038816],
						[61.43413080033725, 2.0618638993439911],
						[61.434130797412131, 2.0618638774130313],
						[61.434130787011135, 2.061863832684693],
						[61.434130778644061, 2.0618638100862094],
						[61.434111280091699, 2.0618249136327811],
						[61.434091880570001, 2.0617832154454474],
						[61.43409188047476, 2.0617832135847292],
						[61.434091878406811, 2.061783208400306],
						[61.43409187554299, 2.0617832052754514],
						[61.43406958016886, 2.0617416132742901],
						[61.434052984029599, 2.0617027222803626],
						[61.434052980975309, 2.0617027154340848],
						[61.434052974961929, 2.0617027036022728],
						[61.434052971907597, 2.0617026967560039],
						[61.434027977626798, 2.0616610089647094],
						[61.434011284272813, 2.0616221209368106],
						[61.434011283286445, 2.0616221192749684],
						[61.434011282300098, 2.061622117613112],
						[61.434011281218474, 2.0616221140905586],
						[61.433991881244239, 2.0615805141139405],
						[61.433991879366765, 2.0615805126509548],
						[61.43399187621722, 2.0615805039440134],
						[61.433991874244498, 2.0615805006203156],
						[61.43396957421367, 2.0615416011406076],
						[61.433969537174249, 2.0615415642395685],
						[61.433969449790027, 2.0615415122266234],
						[61.433969399635679, 2.0615415008361473],
						[61.433952920254463, 2.0615414993595533],
						[61.433930779097381, 2.061499910921949],
						[61.433911281302976, 2.0614582143876858],
						[61.433911236658382, 2.0614581697742804],
						[61.433911130084461, 2.0614581126345128],
						[61.433911067359254, 2.0614581021677254],
						[61.433894501263033, 2.0614608790476767],
						[61.433869579714184, 2.0614193109132346],
						[61.433852986206965, 2.0613777266439022],
						[61.433852948691317, 2.0613776804396631],
						[61.433852856184735, 2.0613776163968076],
						[61.433852800397915, 2.0613776006177758],
						[61.433833416184825, 2.061377600124938],
						[61.433811281352895, 2.0613388127789141],
						[61.433794596622157, 2.0612971548861236],
						[61.433791900379262, 2.0612582862931834],
						[61.433791895671853, 2.06125826476026],
						[61.433791883678886, 2.0612582241518003],
						[61.433791876393357, 2.0612582050762431],
						[61.433769581856083, 2.0612166155099985],
						[61.433752985629795, 2.0611749266869652],
						[61.433752983561824, 2.0611749215026247],
						[61.433752981398605, 2.0611749144575868],
						[61.433752979330606, 2.0611749092732663],
						[61.433733478621363, 2.0611360103969698],
						[61.433733477635016, 2.0611360087351547],
						[61.433733476648648, 2.0611360070733453],
						[61.433733476553385, 2.0611360052126346],
						[61.433711278295775, 2.0610943077455324],
						[61.433691900295514, 2.0610555528375105],
						[61.433691899619483, 2.0610138996953622],
						[61.433691896599008, 2.0610138759040995],
						[61.433691885211367, 2.061013829514879],
						[61.433691875857811, 2.0610138052551386],
						[61.433669582439713, 2.060972115988652],
						[61.433652985748594, 2.060930525380595],
						[61.433652983775872, 2.0609305220569922],
						[61.433652980626285, 2.0609305133501907],
						[61.433652978653555, 2.0609305100265716],
						[61.433633499864186, 2.0608916535704549],
						[61.43363350003019, 2.0608500003108303],
						[61.433633497009666, 2.0608499765196333],
						[61.433633484635578, 2.0608499284687714],
						[61.433633477159482, 2.060849905672014],
						[61.433611277927874, 2.0608082087427482],
						[61.433591878958623, 2.0607693101274958],
						[61.433591877972269, 2.0607693084657011],
						[61.433591876985886, 2.0607693068039099],
						[61.433591875999532, 2.0607693051421161],
						[61.433569578171955, 2.060727707486695],
						[61.43355018322184, 2.0606888174813642],
						[61.433533500179443, 2.0606471617529314],
						[61.433533500484359, 2.0606056008000033],
						[61.433533497273309, 2.0606055732875417],
						[61.433533484008038, 2.0606055254358253],
						[61.433533473763283, 2.0606055013752154],
						[61.433511278269883, 2.0605666080012299],
						[61.433491881344167, 2.0605249163874735],
						[61.433491880262537, 2.0605249128650178],
						[61.433491878194538, 2.0605249076807639],
						[61.433491876317042, 2.0605249062178852],
						[61.433469577730811, 2.0604832074000554],
						[61.433450199864104, 2.0604443533653307],
						[61.433450199598546, 2.0604028001692534],
						[61.43345019775483, 2.0604027817613684],
						[61.433450191298846, 2.060402743681621],
						[61.433450185890685, 2.0604027260693472],
						[61.433433486030161, 2.0603610254350531],
						[61.43343348198939, 2.0603610169272777],
						[61.433433473016784, 2.0603610001106314],
						[61.4334334680849, 2.0603609918017813],
						[61.433408479015974, 2.0603221087507064],
						[61.433391899666375, 2.0602805619772449],
						[61.433391900064549, 2.0602388995507579],
						[61.433391896948677, 2.0602388738991175],
						[61.433391882696888, 2.0602388243860226],
						[61.433391874329565, 2.0602388017885169],
						[61.433369577789648, 2.0601999088026255],
						[61.433350180662181, 2.0601582157479248],
						[61.433350144227759, 2.0601581730683227],
						[61.433350053883935, 2.0601581160749389],
						[61.433349999879276, 2.0601580999004714],
						[61.433333408772008, 2.0601580996105713],
						[61.433308499960447, 2.0601193419461064],
						[61.433308500169815, 2.060077800109632],
						[61.433308498326056, 2.0600777817018532],
						[61.433308490978867, 2.0600777438212647],
						[61.433308485570663, 2.0600777262091277],
						[61.433291885672489, 2.0600360261892501],
						[61.433291881631725, 2.0600360176815573],
						[61.433291872659069, 2.0600360008650971],
						[61.433291867727164, 2.0600359925563212],
						[61.433266899986073, 2.0599971409181239],
						[61.433266900158877, 2.059955599139585],
						[61.433266898315097, 2.0599555807318395],
						[61.433266891063148, 2.059955544711991],
						[61.433266885559647, 2.0599555252392405],
						[61.433250185664164, 2.0599138269534656],
						[61.433250183596151, 2.0599138217693063],
						[61.433250181432868, 2.0599138147244731],
						[61.433250179460096, 2.0599138114009787],
						[61.433230800262791, 2.0598749526129869],
						[61.43323080012491, 2.0598332999124076],
						[61.43323079710423, 2.0598332761216209],
						[61.433230784729801, 2.059833228071799],
						[61.433230776362421, 2.0598332054745203],
						[61.433208478169725, 2.0597916071129778],
						[61.433189100116202, 2.0597527538367464],
						[61.433189099846402, 2.059711099333736],
						[61.433189098002629, 2.0597110809260428],
						[61.433189090750574, 2.0597110449063738],
						[61.433189086138192, 2.0597110252347868],
						[61.43317238607581, 2.0596693253258413],
						[61.433172383021422, 2.0596693184799593],
						[61.433172377007814, 2.0596693066488831],
						[61.433172374048709, 2.0596693016636571],
						[61.433150195856236, 2.0596305409890157],
						[61.433147399782243, 2.0595888877230206],
						[61.433147397142591, 2.0595888713749466],
						[61.433147390176345, 2.0595888409373102],
						[61.433147385849701, 2.0595888268477589],
						[61.433130785713125, 2.0595471254477893],
						[61.433130782753992, 2.0595471204625833],
						[61.43313077664515, 2.0595471067708893],
						[61.43313077368601, 2.0595471017856832],
						[61.433108499646764, 2.0595082478417055],
						[61.433108500278422, 2.0594667005033749],
						[61.43310849824406, 2.0594666783744295],
						[61.433108487937552, 2.0594666355090605],
						[61.433108480651775, 2.0594666164343467],
						[61.433089100270088, 2.0594249561607518],
						[61.433089099806402, 2.0593832999374406],
						[61.43308909678565, 2.0593832761467969],
						[61.433089087274965, 2.0593832312219016],
						[61.433089079002798, 2.0593832104854801],
						[61.433069579400559, 2.0593443094477961],
						[61.43306957761834, 2.0593443098456539],
						[61.43306957653671, 2.0593443063232812],
						[61.433069575645582, 2.0593443065222146],
						[61.433047400150286, 2.0593027490550768],
						[61.433047400031079, 2.059261100332578],
						[61.433047398091986, 2.0592610800643536],
						[61.433047389758151, 2.0592610405225757],
						[61.433047384254571, 2.0592610210500828],
						[61.433030796606324, 2.0592221532031161],
						[61.433028000337011, 2.0591805867255162],
						[61.433027995629274, 2.0591805651934663],
						[61.433027983730987, 2.0591805264474718],
						[61.433027977241018, 2.059180505313293],
						[61.433005800308273, 2.0591388504702701],
						[61.433005800044349, 2.059097199946053],
						[61.433005798105206, 2.0590971796778699],
						[61.433005789771364, 2.0590971401361866],
						[61.433005784267756, 2.0590971206637541],
						[61.432989097408957, 2.0590582525129593],
						[61.43298630019941, 2.0590166863077055],
						[61.432986296668588, 2.0590166701587198],
						[61.432986289797483, 2.0590166415820197],
						[61.432986286266647, 2.0590166254330202],
						[61.432969600381604, 2.0589749606026313],
						[61.432969599583146, 2.0589360992364578],
						[61.432969596562309, 2.0589360754459669],
						[61.432969585173986, 2.0589360290586129],
						[61.432969576806471, 2.0589360064617681],
						[61.432947378802403, 2.0588943113955116],
						[61.432927980806845, 2.0588527158147718],
						[61.432927978834066, 2.058852712491364],
						[61.432927975684336, 2.0588527037849995],
						[61.43292797371155, 2.0588527004615917],
						[61.432905773704675, 2.0588138005904466],
						[61.432905736664608, 2.0588137636938693],
						[61.432905650265873, 2.0588137133519218],
						[61.43290560001607, 2.0588137001055009],
						[61.432886234730034, 2.058813700713666],
						[61.432869585820889, 2.0587721257175966],
						[61.432869543658285, 2.0587720767913904],
						[61.432869437783957, 2.0587720157423313],
						[61.432869374868027, 2.05877200155994],
						[61.432847186696087, 2.05877480037078],
						[61.432827919589606, 2.0587747997828663],
						[61.432805777159572, 2.0587332055149443],
						[61.432805740024207, 2.0587331667579636],
						[61.43280565076153, 2.058733113291964],
						[61.432805599620643, 2.0587331002446265],
						[61.432788900352335, 2.0587331003801763],
						[61.432769516067772, 2.0587330996296545],
						[61.432747374418447, 2.0586943017972268],
						[61.432747337283082, 2.0586942630403398],
						[61.432747250088433, 2.0586942147585563],
						[61.432747199743368, 2.0586941996517294],
						[61.432730599640976, 2.058694200249648],
						[61.432705731900903, 2.0586942004956028],
						[61.432689084346606, 2.0586555217328502],
						[61.432689046925375, 2.058655477394213],
						[61.432688954512948, 2.0586554152223191],
						[61.432688899617027, 2.058655399249687],
						[61.432669519883966, 2.0586553994093748],
						[61.432647377421603, 2.0586138054329743],
						[61.43264733198037, 2.0586137628849608],
						[61.432647228459892, 2.0586137126027002],
						[61.432647166720926, 2.0586137038036116],
						[61.432630708684542, 2.0586164786295575],
						[61.432608476304452, 2.0585749066860104],
						[61.432608440060172, 2.0585748677304823],
						[61.432608350797452, 2.0585748142652402],
						[61.432608299561259, 2.0585747993576291],
						[61.432589031660633, 2.0585748009433709],
						[61.432572384276781, 2.0585360207204215],
						[61.43257234685548, 2.0585359763820921],
						[61.432572255429406, 2.058535915872433],
						[61.432572199642387, 2.0585359000990038],
						[61.432550127670112, 2.0585358991896037],
						[61.432530780621384, 2.0584943155399942],
						[61.432530744281763, 2.0584942747240458],
						[61.432530653842072, 2.0584942158762503],
						[61.432530599837257, 2.0584941997050152],
						[61.432514008742707, 2.0584942002731266],
						[61.432489067629241, 2.058455491580697],
						[61.432489031670798, 2.0584554582072974],
						[61.432488948231146, 2.0584554128519481],
						[61.432488899763534, 2.0584553992083219],
						[61.432472199606138, 2.0584553996806494],
						[61.43247214900336, 2.0584554147420229],
						[61.432472059729832, 2.0584554666653667],
						[61.432472023923012, 2.0584555066516788],
						[61.432449823643687, 2.0584971052696219],
						[61.432449821256213, 2.058497111448311],
						[61.432449817277082, 2.0584971217461074],
						[61.432449813903212, 2.0584971262631186],
						[61.432433115577687, 2.0585388213115006],
						[61.432413721148109, 2.0585805103860202],
						[61.432391524297131, 2.0586221061803567],
						[61.432391520923211, 2.0586221106973714],
						[61.432391516944101, 2.0586221209951145],
						[61.432391513570217, 2.0586221255121204],
						[61.432374864926715, 2.0586637001661319],
						[61.432352800177092, 2.0586636994260701],
						[61.432352746009805, 2.0586637152828176],
						[61.432352656130945, 2.0586637729860922],
						[61.432352618637104, 2.058663815230501],
						[61.432333119239296, 2.0587055143705904],
						[61.43233311764763, 2.0587055184896772],
						[61.43233311516483, 2.0587055228077138],
						[61.432333114464278, 2.0587055267278784],
						[61.432316517124825, 2.0587471180273669],
						[61.432294220654676, 2.0587916094216707],
						[61.432294219062975, 2.0587916135407562],
						[61.432294215879658, 2.0587916217789282],
						[61.432294214287992, 2.0587916258980057],
						[61.432277617811756, 2.0588332169010854],
						[61.43225542296836, 2.0588749059591143],
						[61.432255422172545, 2.0588749080186459],
						[61.432255419689753, 2.0588749123366514],
						[61.432255418893945, 2.058874914396184],
						[61.432235918631392, 2.058916615333731],
						[61.432235917835577, 2.058916617393276],
						[61.432235915448068, 2.0589166235718763],
						[61.432235913761126, 2.0589166258303422],
						[61.432219265318849, 2.0589581003142503],
						[61.432197199674874, 2.0589580995573056],
						[61.43219714382063, 2.0589581176719167],
						[61.432197050567709, 2.0589581798909626],
						[61.432197014155435, 2.058958225657074],
						[61.432180418218884, 2.0589999160478123],
						[61.432158124250087, 2.0590416062715446],
						[61.432158121672046, 2.0590416087289265],
						[61.432158120080381, 2.059041612847972],
						[61.43215811928453, 2.0590416149075157],
						[61.432138719225826, 2.0590832141899824],
						[61.432138717634167, 2.0590832183090257],
						[61.43213871515136, 2.0590832226270157],
						[61.432138714450787, 2.0590832265471319],
						[61.432122064766055, 2.0591248008164413],
						[61.432100000011324, 2.0591247997379707],
						[61.432099945844008, 2.0591248155937523],
						[61.432099855964921, 2.0591248732951959],
						[61.432099819362016, 2.0591249153397704],
						[61.432080420570315, 2.0591666101776775],
						[61.432058170452216, 2.0592081190964677],
						[61.432041666951015, 2.0592109036814326],
						[61.432041621089546, 2.0592109233284779],
						[61.432041544067047, 2.0592109856869629],
						[61.432041513701932, 2.0592110263388883],
						[61.432024864866115, 2.0592526002103395],
						[61.432002800109949, 2.0592525990368213],
						[61.431983299627817, 2.0592525994044699],
						[61.431966700411579, 2.0592525994791808],
						[61.431944399611425, 2.0592526001850011],
						[61.431944393278371, 2.0592525997168774],
						[61.43194437892528, 2.0592526010390673],
						[61.431944370905292, 2.0592526028293681],
						[61.431924985707525, 2.0592554002361969],
						[61.431902800208306, 2.0592553996635412],
						[61.431886100044089, 2.059255399686303],
						[61.431866699618645, 2.0592554002940013],
						[61.43186664892049, 2.0592554134931662],
						[61.431866560632969, 2.0592554670747538],
						[61.431866523839503, 2.0592555053976849],
						[61.431844280150813, 2.0592969993442214],
						[61.431827799894798, 2.0592969991809968],
						[61.431827793657007, 2.0592970005734412],
						[61.43182778108617, 2.0592970014977543],
						[61.431827774848365, 2.0592970028902009],
						[61.43180558656239, 2.0592997993250006],
						[61.431786099636874, 2.0592998003923837],
						[61.431769400363315, 2.0592998001883296],
						[61.431747199524025, 2.059299799237289],
						[61.431747146343049, 2.059299816754216],
						[61.431747056463863, 2.0592998744544229],
						[61.431747018969801, 2.0592999166972272],
						[61.431727665635201, 2.0593415142171496],
						[61.431705587918678, 2.0593441993599351],
						[61.431688900419751, 2.0593442002644426],
						[61.43166939993651, 2.0593442005619047],
						[61.431647199987751, 2.0593441993756456],
						[61.431647144133457, 2.0593442174891785],
						[61.431647050880343, 2.0593442797058676],
						[61.431647013576786, 2.0593443256695894],
						[61.431630458380994, 2.0593859156549241],
						[61.431608286641818, 2.0593887007779594],
						[61.431588899676875, 2.0593887001681246],
						[61.431572320254311, 2.059388699501528],
						[61.431550178125057, 2.0593471082641615],
						[61.431530778692455, 2.0593082112165839],
						[61.431530742353139, 2.0593081704008931],
						[61.431530652995406, 2.0593081150746269],
						[61.431530599977087, 2.0593081005640155],
						[61.431508427143036, 2.0593081008717591],
						[61.431489082719729, 2.0592666172819953],
						[61.431472383622442, 2.0592277216347723],
						[61.4314723815544, 2.0592277164509323],
						[61.431472377608863, 2.0592277098043978],
						[61.431472377418324, 2.0592277060832735],
						[61.431450179434123, 2.0591860108775939],
						[61.431430780864758, 2.0591443155837386],
						[61.431430778892, 2.0591443122604778],
						[61.431430774851194, 2.0591443037533943],
						[61.431430772878429, 2.0591443004301357],
						[61.431408478352452, 2.0591055079514207],
						[61.431389082719129, 2.0590638177483416],
						[61.431372384362987, 2.0590249202755744],
						[61.431372382390215, 2.0590249169523305],
						[61.431372378444671, 2.0590249103058236],
						[61.43137237736299, 2.0590249067836615],
						[61.43135017725254, 2.0589832066720382],
						[61.431350140022012, 2.0589831660559024],
						[61.431350050854718, 2.0589831144519559],
						[61.431349999618575, 2.0589830995441152],
						[61.431330731713167, 2.0589831008263175],
						[61.431314084370015, 2.0589443200399677],
						[61.431314081410839, 2.0589443150551232],
						[61.431314075397246, 2.0589443032248451],
						[61.431314070560568, 2.0589442967773137],
						[61.431289080472489, 2.0589027115265019],
						[61.431272386218751, 2.0588610263751503],
						[61.431272383164298, 2.0588610195297643],
						[61.431272377150691, 2.0588610076995351],
						[61.431272374096231, 2.0588610008541481],
						[61.431250177971435, 2.05882210775381],
						[61.431230780878145, 2.0587805142184523],
						[61.431230780878145, 2.0587805142184523],
						[61.43123077900065, 2.0587805127557739],
						[61.431230778905373, 2.0587805108952368],
						[61.431211279074141, 2.0587416106310261],
						[61.431211241843513, 2.058741570015342],
						[61.431211153376744, 2.0587415144922838],
						[61.431211100358333, 2.0587414999827627],
						[61.431189034715743, 2.0587414993326596],
						[61.431172385817831, 2.0586999265832198],
						[61.431172382763364, 2.0586999197378697],
						[61.431172376749743, 2.058699907907712],
						[61.431172373695276, 2.0586999010623632],
						[61.431150178418918, 2.0586610080011689],
						[61.431130783739867, 2.0586193219412658],
						[61.431114085767383, 2.0585777245287491],
						[61.431114081821818, 2.0585777178823736],
						[61.431114072753722, 2.0585776992069333],
						[61.431114067917065, 2.0585776927594712],
						[61.431089079005069, 2.0585388081666771],
						[61.431072385728079, 2.0584971270005288],
						[61.431072382578321, 2.0584971182946954],
						[61.431072376564693, 2.0584971064646136],
						[61.431072373605495, 2.0584971014798317],
						[61.431050178274972, 2.0584582086872083],
						[61.431030781077482, 2.0584166156447856],
						[61.431011300271308, 2.0583749566362557],
						[61.431011300324101, 2.0583360993866568],
						[61.431011297303172, 2.0583360755977025],
						[61.431011285023558, 2.0583360294124136],
						[61.431011276655994, 2.0583360068170999],
						[61.430989100117003, 2.0582943494156134],
						[61.430989100133672, 2.058252799347513],
						[61.430989099738909, 2.0582111007073403],
						[61.430989096717973, 2.0582110769184299],
						[61.430989087206939, 2.0582110319968927],
						[61.430989078839332, 2.0582110094016524],
						[61.430969599950089, 2.0581721521319118],
						[61.430969599834441, 2.0581306002317756],
						[61.430969596813497, 2.0581305764428692],
						[61.430969585329628, 2.0581305281982472],
						[61.430969576962021, 2.058130505603041],
						[61.43094740045823, 2.0580888502663557],
						[61.430947399920385, 2.0580500007428708],
						[61.430947400259996, 2.0580083001031366],
						[61.430947398225442, 2.058008277975842],
						[61.430947387918529, 2.0580082351138982],
						[61.430947380537276, 2.0580082141803144],
						[61.430928000262725, 2.0579666553884963],
						[61.430928000190747, 2.0579250000257292],
						[61.430927998251434, 2.0579249797589814],
						[61.430927989917308, 2.0579249402202309],
						[61.430927984413565, 2.0579249207492869],
						[61.430911300129907, 2.0578860588933079],
						[61.430911299842947, 2.0578443998334492],
						[61.430911295740287, 2.0578443725225397],
						[61.430911282378787, 2.0578443228155168],
						[61.430911271147217, 2.0578442970962501],
						[61.430886300375811, 2.0578027446376139],
						[61.430886299934983, 2.0577611006959629],
						[61.430886300296038, 2.057722200225065],
						[61.430886298452002, 2.0577221818188667],
						[61.430886291104194, 2.0577221439418052],
						[61.430886285600415, 2.0577221244709269],
						[61.430869599868842, 2.0576805608377309],
						[61.430869600315496, 2.0576388997783019],
						[61.4308696001249, 2.0576388960572642],
						[61.430869599839035, 2.0576388904757286],
						[61.430869599648439, 2.0576388867546913],
						[61.43086689960419, 2.0575971869510159],
						[61.430866895787318, 2.0575971652217366],
						[61.430866882711626, 2.0575971210964257],
						[61.430866873548155, 2.0575971005609057],
						[61.430844599712515, 2.0575582468469258],
						[61.430844599697359, 2.0575167007073758],
						[61.430844599875229, 2.0574750002159914],
						[61.430844597935859, 2.0574749799493257],
						[61.430844589601591, 2.0574749404108137],
						[61.430844584193089, 2.0574749228005125],
						[61.430827996692628, 2.0574360528304787],
						[61.430825200409195, 2.0573943933964172],
						[61.430825199744078, 2.0573528003753192],
						[61.430825199871968, 2.0573110999132038],
						[61.430825198027897, 2.0573110815070828],
						[61.430825190679954, 2.0573110436301869],
						[61.430825186162537, 2.0573110258209595],
						[61.430808499619019, 2.0572693610333106],
						[61.430808499763884, 2.0572305999382321],
						[61.430808499573281, 2.0572305962172242],
						[61.4308085001785, 2.0572305904367156],
						[61.430808499987897, 2.0572305867157059],
						[61.430805800201156, 2.0571888944791179],
						[61.430805799673884, 2.0571471998267752],
						[61.430805796652749, 2.0571471760381028],
						[61.43080578516858, 2.0571471277941256],
						[61.430805775909683, 2.0571471053982719],
						[61.430783499656513, 2.0571055503549069],
						[61.430783499731412, 2.057066700855307],
						[61.430783499772275, 2.0570250004547868],
						[61.430783499800349, 2.0569833000550752],
						[61.430783500411096, 2.0569416992579281],
						[61.430783498376329, 2.0569416771308395],
						[61.430783488164366, 2.0569416361300159],
						[61.430783480782999, 2.0569416151967927],
						[61.430764100395287, 2.0568999556409833],
						[61.430764100125806, 2.0568611005879207],
						[61.430764097104621, 2.0568610767993292],
						[61.430764084729226, 2.0568610287544953],
						[61.430764077252519, 2.0568610059607915],
						[61.430741900281383, 2.0568193499584102],
						[61.430741899851547, 2.0567778002496273],
						[61.43074189993353, 2.0567388999775242],
						[61.430741897898749, 2.0567388778504592],
						[61.430741887686729, 2.0567388368497603],
						[61.430741881196425, 2.0567388157176341],
						[61.430722399801361, 2.0566971563064484],
						[61.430722399939711, 2.056655600845644],
						[61.430722399855533, 2.0566139005351687],
						[61.430722397820716, 2.0566138784081476],
						[61.430722388404476, 2.0566138353480277],
						[61.430722381118336, 2.0566138162754317],
						[61.430702999647814, 2.056572155347939],
						[61.430703000176798, 2.0565333001782427],
						[61.43070299976015, 2.0564916996966414],
						[61.43070299673893, 2.056491675908116],
						[61.430702985349804, 2.0564916295250497],
						[61.430702976981905, 2.0564916069304813],
						[61.430680799807241, 2.0564499494169302],
						[61.430680799560783, 2.0564082999967144],
						[61.430680796539541, 2.0564082762082312],
						[61.430680787027896, 2.0564082312877296],
						[61.430680778755324, 2.056408210553704],
						[61.430661300168268, 2.0563693527578968],
						[61.430661300302781, 2.0563277992456794],
						[61.430661270552932, 2.0563277287362012],
						[61.430661170791375, 2.0563276286984906],
						[61.430661099888624, 2.0563275993692591],
						[61.430644399747109, 2.0563276009185527],
						[61.430644394305169, 2.0563276002520303],
						[61.430644380843212, 2.0563276013764802],
						[61.430644374605485, 2.0563276027694433],
						[61.430622319756594, 2.0563303826672126],
						[61.430602980872337, 2.0562888160208437],
						[61.430602943545736, 2.0562887735483932],
						[61.430602854091624, 2.0562887163718591],
						[61.430602800086618, 2.0562887002052475],
						[61.430580600074222, 2.0562886992870135],
						[61.430564023349049, 2.0562886996387473],
						[61.430544578841129, 2.0562499097145031],
						[61.430544541705125, 2.0562498709631511],
						[61.430544453237431, 2.056249815448306],
						[61.430544400123523, 2.0562497990827944],
						[61.430522200206831, 2.0562498000500522],
						[61.430502915904952, 2.0562497991298554],
						[61.430480781218129, 2.0562110135383604],
						[61.430464085889248, 2.05616932535612],
						[61.430464049358378, 2.0561692808245211],
						[61.430463955958579, 2.0561692170025863],
						[61.430463900076013, 2.0561691993737621],
						[61.43044452026907, 2.0561691991296569],
						[61.430422377614846, 2.056127707579174],
						[61.43040297899703, 2.0560888117015907],
						[61.43040294167038, 2.0560887692296417],
						[61.430402853297927, 2.0560887155759655],
						[61.430402800184005, 2.056088699210787],
						[61.43038073180022, 2.0560886991394165],
						[61.430364083670518, 2.0560499211041581],
						[61.430364047139619, 2.0560498765728359],
						[61.430363954821473, 2.0560498162734193],
						[61.430363899925332, 2.0560498003063241],
						[61.430344520119434, 2.0560498001324543],
						[61.430322380686107, 2.0560082139830578],
						[61.430305784323515, 2.055969321000676],
						[61.430305781268949, 2.055969314155798],
						[61.430305775255079, 2.0559693023264902],
						[61.430305771404704, 2.0559692975410906],
						[61.430280780174321, 2.0559277104492408],
						[61.430264085846296, 2.0558860262072147],
						[61.430264083873446, 2.0558860228842888],
						[61.430264080723582, 2.0558860141789408],
						[61.430264078750731, 2.055886010855998],
						[61.430244578835058, 2.0558471097064053],
						[61.430244541698961, 2.0558470709558851],
						[61.430244453231118, 2.055847015442676],
						[61.430244400117168, 2.0558469990779797],
						[61.430222200204526, 2.055847000318384],
						[61.43022212869208, 2.0558470294611433],
						[61.43022202923175, 2.0558471288250209],
						[61.430222000392703, 2.0558471992451572],
						[61.430221999687156, 2.0558888614618307],
						[61.430205418205183, 2.0559305157029164],
						[61.4301831236832, 2.0559721059252882],
						[61.430183121200514, 2.0559721102432378],
						[61.430183116330461, 2.055972120739586],
						[61.430183113943073, 2.0559721269179843],
						[61.430166514035591, 2.056013826570315],
						[61.430166508655667, 2.0560138447075018],
						[61.430166502351398, 2.0560138799869008],
						[61.430166499740118, 2.0560138993875516],
						[61.430166500478549, 2.0560555555539111],
						[61.430147020744904, 2.0560971116732945],
						[61.430124823447798, 2.056138806052429],
						[61.430124820965091, 2.0561388103703413],
						[61.430124816095031, 2.0561388208666407],
						[61.430124813612323, 2.0561388251845547],
						[61.430108113689755, 2.056180526294682],
						[61.430108109200937, 2.0561805442328267],
						[61.43010810210081, 2.0561805815715704],
						[61.430108100380622, 2.056180600773188],
						[61.430108100089463, 2.0562221498234043],
						[61.430085980129988, 2.0562637007057289],
						[61.430069399744902, 2.0562636999890787],
						[61.430069345577976, 2.0562637158485488],
						[61.430069255700161, 2.0562637735540257],
						[61.430069219098144, 2.0562638155990074],
						[61.430049872353123, 2.0563054000800114],
						[61.430027800402286, 2.0563054006347263],
						[61.430027743562043, 2.0563054170910715],
						[61.430027651201485, 2.0563054791142332],
						[61.430027613898986, 2.0563055250790234],
						[61.430010965433972, 2.0563469999480604],
						[61.429991700130017, 2.0563470003495916],
						[61.429991648541062, 2.056347013751453],
						[61.429991560254805, 2.0563470673376258],
						[61.429991524353255, 2.0563471054625064],
						[61.429969269632593, 2.0563887181705862],
						[61.429952783138461, 2.0563915002059852],
						[61.42993060024606, 2.0563914991243224],
						[61.429930544392207, 2.0563915172419289],
						[61.4299304511405, 2.0563915794636118],
						[61.429930413837958, 2.0563916254281529],
						[61.429913765343954, 2.0564331001200378],
						[61.42989439998243, 2.0564331002264495],
						[61.429894349284602, 2.0564331134291409],
						[61.429894260107204, 2.0564331672138705],
						[61.429894223314513, 2.0564332055375134],
						[61.429872080334192, 2.0564748006198936],
						[61.429855600003961, 2.0564748000214959],
						[61.429855549306126, 2.0564748132240811],
						[61.429855460128678, 2.0564748670086277],
						[61.429855424227085, 2.0564749051331828],
						[61.429833172041022, 2.0565165151069018],
						[61.429813872252495, 2.0565192026784311],
						[61.429813824609226, 2.0565192227257616],
						[61.42981374571049, 2.0565192836235369],
						[61.429813713659229, 2.0565193265333899],
						[61.429797064827419, 2.0565609007976016],
						[61.4297750000025, 2.0565608995676548],
						[61.429755600396341, 2.0565608997085025],
						[61.429733300418917, 2.056560900178861],
						[61.429733243578625, 2.0565609166345715],
						[61.429733151313243, 2.0565609805167773],
						[61.429733114010638, 2.0565610264808112],
						[61.429716564234326, 2.0566025991901777],
						[61.429697199857472, 2.0566026008446538],
						[61.429697194415517, 2.0566026001780955],
						[61.429697180953603, 2.0566026013023571],
						[61.429697174715848, 2.0566026026952167],
						[61.429674987400432, 2.0566054005566032],
						[61.429658299731521, 2.056605399136612],
						[61.429658249128998, 2.0566054141993315],
						[61.42965815985616, 2.0566054661226971],
						[61.42965812404983, 2.0566055061072825],
						[61.429635980318963, 2.0566469992858449],
						[61.429616699672451, 2.0566469991483869],
						[61.429600000418745, 2.0566470002908033],
						[61.42959999408567, 2.0566469998232186],
						[61.429599980623749, 2.0566470009474549],
						[61.429599975277121, 2.056647002141319],
						[61.429577787069007, 2.0566498001568494],
						[61.429558299976001, 2.0566497990703576],
						[61.429536100054136, 2.0566497997065114],
						[61.42951939981392, 2.0566497991831514],
						[61.429500000206765, 2.0566497992582122],
						[61.42949999396901, 2.0566498006510519],
						[61.429499981398159, 2.0566498015763104],
						[61.429499975065106, 2.0566498011087204],
						[61.429477786856687, 2.0566525991086024],
						[61.429461100173654, 2.0566525993178959],
						[61.429438900251654, 2.0566525999475456],
						[61.429419399696577, 2.056652599977852],
						[61.429419391581334, 2.0566525999082108],
						[61.429419374554946, 2.0566526018283269],
						[61.429419367426085, 2.0566526034201362],
						[61.429402783411348, 2.056655399494757],
						[61.429380599719813, 2.0566554002589492],
						[61.429380546443923, 2.0566554159183568],
						[61.429380455674824, 2.0566554738202592],
						[61.429380419072643, 2.0566555158638273],
						[61.429360973414866, 2.0566970007881022],
						[61.429344520101679, 2.0566969996664799],
						[61.429322377528827, 2.0566555072841615],
						[61.429302978981738, 2.056616610536544],
						[61.429302978090618, 2.0566166107355235],
						[61.429302977008916, 2.0566166072136696],
						[61.429302976913625, 2.056616605353252],
						[61.429280779883861, 2.0565749126060751],
						[61.429264083641229, 2.0565360203372327],
						[61.429264082750116, 2.0565360205362127],
						[61.429264081668414, 2.056536017014349],
						[61.429264080681982, 2.0565360153529184],
						[61.429244580832417, 2.0564943150603376],
						[61.429244579845992, 2.0564943133989115],
						[61.429244577777851, 2.0564943082156226],
						[61.429244575900334, 2.0564943067531742],
						[61.429222400241301, 2.0564527505182437],
						[61.42922239996799, 2.0564138992573686],
						[61.42922239793316, 2.0564138771314249],
						[61.429222387721076, 2.0564138361327862],
						[61.429222381326049, 2.0564138168621495],
						[61.429202999729597, 2.0563721561482646],
						[61.429202999703556, 2.0563306008608899],
						[61.429202996586994, 2.0563305752131331],
						[61.42920298519789, 2.0563305288323082],
						[61.429202976829963, 2.0563305062388633],
						[61.429180799636299, 2.056288850779834],
						[61.429180800207952, 2.056249999374939],
						[61.429180798173121, 2.0562499772490312],
						[61.429180787960995, 2.0562499362505031],
						[61.4291807805795, 2.0562499153185101],
						[61.429161283605609, 2.0562082203031995],
						[61.429144597170087, 2.0561666541718999],
						[61.429141900156637, 2.0561277861160621],
						[61.429141896339551, 2.0561277643881737],
						[61.429141884440497, 2.056127725648135],
						[61.429141876263202, 2.056127706775587],
						[61.429119599749789, 2.0560860492475652],
						[61.429119600218719, 2.0560443998630666],
						[61.429119598279179, 2.0560443795976169],
						[61.429119590039832, 2.0560443419220928],
						[61.429119583549429, 2.0560443207911798],
						[61.429103000128379, 2.0560055580843013],
						[61.429103000096418, 2.0559638994216072],
						[61.429102995993375, 2.0559638721125495],
						[61.429102981740193, 2.0559638226081933],
						[61.429102972290494, 2.055963796493097],
						[61.429077977621162, 2.055922108228796],
						[61.429061299623257, 2.0558832594963454],
						[61.429061300150835, 2.0558417004880405],
						[61.429061298115947, 2.0558416783622455],
						[61.429061287808373, 2.0558416355036191],
						[61.429061281413261, 2.0558416162332023],
						[61.429041881322114, 2.0557999150282393],
						[61.429041879349285, 2.0557999117054417],
						[61.429041876294676, 2.0557999048608417],
						[61.42904187432184, 2.0557999015380446],
						[61.429019599832991, 2.0557610476136872],
						[61.429019600299569, 2.0557194002298331],
						[61.429019598264624, 2.0557193781040835],
						[61.429019587957015, 2.0557193352455747],
						[61.429019580575456, 2.0557193143138011],
						[61.429000181140395, 2.0556777146979464],
						[61.429000180153977, 2.0556777130365536],
						[61.429000178085822, 2.0556777078533579],
						[61.429000177099375, 2.0556777061919704],
						[61.428977999620926, 2.055636049612295],
						[61.428978000104856, 2.0555972003329752],
						[61.428977998069925, 2.055597178207258],
						[61.428977987762309, 2.0555971353488425],
						[61.4289779812718, 2.0555971142181324],
						[61.428958482826339, 2.0555555180212015],
						[61.42894189975781, 2.0555166597046552],
						[61.428941900374831, 2.0554749992078394],
						[61.428941897353411, 2.0554749754207875],
						[61.428941885072881, 2.055474929239689],
						[61.428941875813791, 2.0554749068456588],
						[61.428919578014565, 2.0554332077252324],
						[61.428900200136844, 2.0553943527186491],
						[61.428900199912846, 2.0553527997152465],
						[61.428900196891405, 2.0553527759282368],
						[61.428900184610839, 2.0553527297472489],
						[61.428900177038599, 2.0553527050948674],
						[61.42887797846479, 2.0553110084121884],
						[61.428858483346751, 2.0552721176148356],
						[61.42884189678994, 2.0552305544919913],
						[61.428839100191404, 2.0551888865838746],
						[61.428839096469531, 2.0551888667166884],
						[61.428839083583753, 2.0551888263160363],
						[61.42883907709323, 2.0551888051855105],
						[61.428816879553764, 2.0551471122236395],
						[61.428800183813031, 2.055108221110999],
						[61.428800181840181, 2.0551082177882538],
						[61.428800177799133, 2.0551082092824089],
						[61.428800175826275, 2.0551082059596837],
						[61.428777977683396, 2.0550666073201969],
						[61.428758479635427, 2.0550277136774939],
						[61.428739081280611, 2.0549860167116654],
						[61.428739080198824, 2.0549860131899411],
						[61.42873907813064, 2.0549860080068392],
						[61.428739077144222, 2.0549860063454783],
						[61.428716900356989, 2.054944350410282],
						[61.428716900258351, 2.0549055994031948],
						[61.42871689841396, 2.0549055809984771],
						[61.428716891065399, 2.0549055431247236],
						[61.428716885656598, 2.0549055255160655],
						[61.428700185644452, 2.0548638262721872],
						[61.428700184562672, 2.0548638227504696],
						[61.428700181603396, 2.0548638177663974],
						[61.428700180616936, 2.0548638161050539],
						[61.428680781132641, 2.0548221157985669],
						[61.428680739159446, 2.0548220706047493],
						[61.428680636147632, 2.0548220127002015],
						[61.428680575204297, 2.0548220018498489],
						[61.428658420341222, 2.054824782312628],
						[61.42863908085711, 2.0547832154519345],
						[61.428639043625395, 2.0547831748442364],
						[61.42863895407546, 2.0547831158149079],
						[61.428638900070339, 2.0547830996517162],
						[61.428616831698832, 2.054783100423788],
						[61.428600183938798, 2.0547443213256575],
						[61.428600147407529, 2.0547442767982824],
						[61.428600055088928, 2.0547442165058438],
						[61.428600000192695, 2.0547442005417582],
						[61.428580720358831, 2.0547441994809708],
						[61.428558476086089, 2.054702705753773],
						[61.428558438949743, 2.054702667006604],
						[61.42855835057685, 2.054702613359733],
						[61.428558300326785, 2.0547026001213555],
						[61.428541809125797, 2.0547025990828751],
						[61.428516867945923, 2.0546637926531397],
						[61.428516831891294, 2.0546637574277842],
						[61.428516748450591, 2.0546637120877684],
						[61.428516700078056, 2.0546637003117616],
						[61.428500127819973, 2.0546637004614792],
						[61.428480781099999, 2.0546221166984542],
						[61.428480779031808, 2.0546221115154175],
						[61.428480775977143, 2.0546221046710738],
						[61.428480774004271, 2.0546221013483952],
						[61.428458474253979, 2.0545832018478491],
						[61.428458437117577, 2.054583163100943],
						[61.428458349826414, 2.0545831129762497],
						[61.42845829957632, 2.0545830997381036],
						[61.428441700299722, 2.0545831003184114],
						[61.428419523758301, 2.0545831009216786],
						[61.428400178683276, 2.0545443111889914],
						[61.428400142342653, 2.0545442703828209],
						[61.428400052983264, 2.0545442150753219],
						[61.428399999964533, 2.0545442005739725],
						[61.428383299645169, 2.0545441992794862],
						[61.42836122672513, 2.0545441993437779],
						[61.428341881460639, 2.0545027152319282],
						[61.428341838596239, 2.0545026702380396],
						[61.428341735584311, 2.054502612335118],
						[61.428341674640961, 2.0545026014854857],
						[61.428319387331655, 2.05450540042311],
						[61.428300000314366, 2.0545054008589867],
						[61.428283299995208, 2.0545053995818141],
						[61.428261099999794, 2.0545053998267027],
						[61.428261092775614, 2.0545053995585865],
						[61.428261079313707, 2.0545054006836647],
						[61.428261072184888, 2.0545054022758849],
						[61.42824180987796, 2.0545080825325779],
						[61.428219576297565, 2.0544666058285435],
						[61.428219539161141, 2.0544665670820601],
						[61.428219450788184, 2.0544665134363829],
						[61.428219399646977, 2.0544665003975338],
						[61.428202800275798, 2.0544664991712973],
						[61.428183299740283, 2.054466500519776],
						[61.428183294298336, 2.0544664998536066],
						[61.428183281727541, 2.0544665007796663],
						[61.428183276285587, 2.0544665001134952],
						[61.428161087545327, 2.0544691998930817],
						[61.42814439989251, 2.054469199564751],
						[61.428122199897324, 2.0544691998289788],
						[61.428102931840648, 2.0544692000524205],
						[61.428086283708858, 2.0544305215417848],
						[61.428086247177554, 2.0544304770154689],
						[61.428086154858825, 2.0544304167248697],
						[61.428086099962556, 2.0544304007615439],
						[61.428063899872384, 2.0544303991904007],
						[61.42804440022821, 2.0544304003567406],
						[61.428027799966095, 2.0544303993427055],
						[61.428027793728369, 2.0544304007359022],
						[61.428027782048673, 2.0544304014629384],
						[61.428027775715599, 2.0544304009958076],
						[61.428005587867183, 2.054433100581321],
						[61.427986099807299, 2.0544330991562378],
						[61.427963899812397, 2.0544330994388109],
						[61.427947199588935, 2.0544331000489806],
						[61.427927799905909, 2.0544330995804283],
						[61.427927793668189, 2.054433100973617],
						[61.427927781097381, 2.054433101899662],
						[61.427927774764349, 2.0544331014325232],
						[61.427905574942045, 2.0544359015212157],
						[61.427905526407976, 2.0544359217693282],
						[61.427905445823328, 2.0544359849266267],
						[61.427905413677401, 2.0544360259755132],
						[61.427888764665056, 2.0544776007570311],
						[61.427866699860544, 2.0544776009147174],
						[61.427847200120439, 2.054477600183112],
						[61.427830599857806, 2.0544775991370559],
						[61.427830549255539, 2.0544776142021246],
						[61.427830460970014, 2.0544776677894512],
						[61.42783042417787, 2.0544777061126505],
						[61.427808180229555, 2.0545192000688073],
						[61.427788900397154, 2.0545191991152914],
						[61.427788894159413, 2.0545192005084592],
						[61.427788880697527, 2.054519201633477],
						[61.427788875350863, 2.0545192028276293],
						[61.427766687109951, 2.0545220002487934],
						[61.427750000347565, 2.0545219996793391],
						[61.427749946180867, 2.0545220155403818],
						[61.427749856303762, 2.0545220732461056],
						[61.427749818811172, 2.0545221154888118],
						[61.427730471771895, 2.0545636998852257],
						[61.427708299685555, 2.0545636994740852],
						[61.427691700408594, 2.0545637000385897],
						[61.427691694075527, 2.0545636995714331],
						[61.427691680613627, 2.0545637006964452],
						[61.427691675266985, 2.0545637018905798],
						[61.427669387063176, 2.0545665008947931],
						[61.427649999949494, 2.0545664994058521],
						[61.42764994409589, 2.0545665175251102],
						[61.427649850845043, 2.0545665797471959],
						[61.427649814434204, 2.0545666255113155],
						[61.42763316477842, 2.0546081002840397],
						[61.427611099972303, 2.0546081003374206],
						[61.42759170028738, 2.0546080997461904],
						[61.427591693954319, 2.0546080992790223],
						[61.427591681383497, 2.0546081002049963],
						[61.427591675145756, 2.0546081015981326],
						[61.427569375260646, 2.0546109012839007],
						[61.427569325835464, 2.0546109217305024],
						[61.427569246141829, 2.0546109846876139],
						[61.427569213995824, 2.0546110257358423],
						[61.427552664086988, 2.0546526003985073],
						[61.427536099943623, 2.0546526005897197],
						[61.427511099538336, 2.0546525995617348],
						[61.427494400203898, 2.054652599839299],
						[61.427477800034964, 2.0546526005479895],
						[61.427477793701904, 2.0546526000808112],
						[61.427477782913321, 2.0546526006087218],
						[61.427477778457799, 2.0546526016038094],
						[61.427452789008591, 2.0546553998132633],
						[61.427436099571601, 2.0546553997584009],
						[61.427413899573807, 2.0546553998569017],
						[61.427394399926989, 2.0546554008557742],
						[61.427377915949791, 2.0546554000701898],
						[61.427355773593561, 2.0546166003178179],
						[61.427355736552528, 2.0546165634324685],
						[61.427355650152521, 2.0546165131102296],
						[61.427355599902455, 2.0546164998723802],
						[61.427336100160637, 2.0546164990329085],
						[61.427313900163163, 2.0546164991544957],
						[61.427313892047877, 2.0546164990853648],
						[61.427313876007972, 2.054616502667673],
						[61.427313867892707, 2.0546165025985372],
						[61.427297184169362, 2.0546191994701863],
						[61.427277799823528, 2.0546191991965546],
						[61.427255599825955, 2.0546191993134886],
						[61.427238899600553, 2.0546191997990264],
						[61.427216699602944, 2.0546191999141468],
						[61.427216693269898, 2.0546191994469707],
						[61.427216678916892, 2.0546192007709467],
						[61.427216671788038, 2.0546192023630825],
						[61.427197185675915, 2.0546220001366744],
						[61.42718059986003, 2.0546219995273414],
						[61.427158299805726, 2.0546219994051023],
						[61.42713890021566, 2.054622000647059],
						[61.427138893882592, 2.0546220001798896],
						[61.427138881311805, 2.0546220011058254],
						[61.427138875074078, 2.0546220024989337],
						[61.427116686828448, 2.0546247997583675],
						[61.427100000064783, 2.0546247991110698],
						[61.427080599583512, 2.0546248005479435],
						[61.427058300420221, 2.0546248002200005],
						[61.427041700155911, 2.0546247990680113],
						[61.427041693918163, 2.0546248004611161],
						[61.427041681347369, 2.0546248013870461],
						[61.427041675109614, 2.0546248027801512],
						[61.427019386807089, 2.0546275997916372],
						[61.42699999978764, 2.0546276000985242],
						[61.426983300453109, 2.0546276003715636],
						[61.426961100455173, 2.0546276004688027],
						[61.426941699878434, 2.0546276000379904],
						[61.426941693545366, 2.054627599570821],
						[61.426941680974565, 2.054627600496731],
						[61.426941674736852, 2.0546276018898197],
						[61.42691938742044, 2.054630400546904],
						[61.426902799822095, 2.0546304003214608],
						[61.426883300079801, 2.0546303994547834],
						[61.426883248586385, 2.0546304147180607],
						[61.426883160205428, 2.0546304664428776],
						[61.426883124399673, 2.0546305064259225],
						[61.426860979548714, 2.0546719994016791],
						[61.426844400065292, 2.0546719992212017],
						[61.426824999583367, 2.0546720006182451],
						[61.426824994141391, 2.0546719999520597],
						[61.426824980679491, 2.0546720010769692],
						[61.426824975332877, 2.0546720022710363],
						[61.426802787084966, 2.0546747994521124],
						[61.426786100416024, 2.0546748006273146],
						[61.426763900417455, 2.0546748006816964],
						[61.426744399783509, 2.0546747999805555],
						[61.42674439166823, 2.0546747999113975],
						[61.42674437464192, 2.0546748018323258],
						[61.426744366621975, 2.0546748036234241],
						[61.426727782658347, 2.0546776004152347],
						[61.426705599781336, 2.0546776004042164],
						[61.426686100038438, 2.0546775994999242],
						[61.426663900039721, 2.0546775995476612],
						[61.426647199813431, 2.0546775999811406],
						[61.426647192589236, 2.0546775997129796],
						[61.426647179127343, 2.054677600837874],
						[61.426647171107383, 2.0546776026289635],
						[61.426627770695866, 2.0546804018485108],
						[61.426627728304162, 2.0546804188419654],
						[61.426627654371551, 2.0546804711025053],
						[61.426627623721714, 2.0546805061706008],
						[61.426605479836013, 2.0547220004208144],
						[61.426588900352009, 2.054722000204833],
						[61.426566700352723, 2.0547220002163944],
						[61.4265471997182, 2.0547219994776333],
						[61.426530600439158, 2.0547219999173141],
						[61.426530594106104, 2.0547219994501256],
						[61.42653058064419, 2.0547220005749796],
						[61.426530575297576, 2.0547220017690226],
						[61.426508287086179, 2.0547248005015275],
						[61.426488900065415, 2.0547248007271137],
						[61.426466700066044, 2.0547248007320302],
						[61.42644999974388, 2.0547247992730413],
						[61.426449992615048, 2.0547248008650936],
						[61.426449979057821, 2.0547248001297205],
						[61.426449971037876, 2.0547248019207824],
						[61.426430585868616, 2.0547275995623719],
						[61.426408300260874, 2.054727599865803],
						[61.426391700090605, 2.0547276004966646],
						[61.426369400034496, 2.0547276002618928],
						[61.426350000347448, 2.0547275995457097],
						[61.426349992232169, 2.0547275994765144],
						[61.426349975205845, 2.054727601397393],
						[61.426349967185899, 2.0547276031884452],
						[61.426333283163409, 2.0547303996698854],
						[61.426311100285425, 2.0547303996029291],
						[61.426291699802547, 2.0547304009418568],
						[61.42626939974631, 2.054730400700401],
						[61.426252800371707, 2.0547303992656971],
						[61.426252793242838, 2.0547304008577405],
						[61.426252778794534, 2.0547304003213474],
						[61.426252772556779, 2.0547304017143855],
						[61.426233285842315, 2.0547330997055457],
						[61.426211100290942, 2.0547331002290155],
						[61.426194400063864, 2.054733100616855],
						[61.426175000376666, 2.0547330998901172],
						[61.426174948787903, 2.0547331132925812],
						[61.42617485961113, 2.0547331670750357],
						[61.426174822818865, 2.0547332053957912],
						[61.426152669763972, 2.0547748195623639],
						[61.426136083156145, 2.0547775998833435],
						[61.426113900277507, 2.0547775997735669],
						[61.426094399737423, 2.0547776008416947],
						[61.426077800362314, 2.0547775993757487],
						[61.426077794124588, 2.0547776007687633],
						[61.426077780662688, 2.0547776018935808],
						[61.42607777522074, 2.0547776012273569],
						[61.426055575478806, 2.0547804026786984],
						[61.426055528631537, 2.0547804206666691],
						[61.426055451325105, 2.0547804774423746],
						[61.426055418988369, 2.0547805147679061],
						[61.426035973151649, 2.0548220001805801],
						[61.426013900226529, 2.0548219999392745],
						[61.425997199998626, 2.0548220002725306],
						[61.42597780031042, 2.0548219994824053],
						[61.425955600309514, 2.0548219993942434],
						[61.425955592194221, 2.0548219993250179],
						[61.425955575167912, 2.0548220012458058],
						[61.425955567147966, 2.0548220030368034],
						[61.425938883121958, 2.05482479941859],
						[61.425916700242617, 2.0548247992660444],
						[61.425897199701943, 2.0548248002965392],
						[61.425880600421642, 2.0548248006587686],
						[61.42585830036402, 2.0548248003305138],
						[61.425858293139861, 2.0548248000622844],
						[61.425858278786841, 2.0548248013860686],
						[61.425858270671554, 2.0548248013168604],
						[61.425838885593407, 2.0548276006793418],
						[61.425816699945329, 2.0548275992569187],
						[61.425799999717242, 2.0548275995802778],
						[61.425780600124156, 2.0548276006388364],
						[61.425780593791089, 2.0548276001716275],
						[61.425780581220273, 2.0548276010974105],
						[61.425780574982568, 2.0548276024903931],
						[61.425758286670835, 2.0548303991847923],
						[61.425741700056548, 2.0548304004736297],
						[61.425716699647275, 2.0548303992088583],
						[61.425700000310194, 2.0548303993281971],
						[61.425683300082035, 2.0548303996459558],
						[61.425683294735407, 2.0548304008399398],
						[61.425683283946817, 2.054830401367719],
						[61.425683279395955, 2.0548304005025004],
						[61.425658289435212, 2.0548331005892297],
						[61.425641700052182, 2.0548331006099478],
						[61.425619399994225, 2.0548331002645592],
						[61.425619344045259, 2.0548331165217468],
						[61.425619250889532, 2.0548331805987741],
						[61.425619213587453, 2.0548332265584288],
						[61.425602663663774, 2.0548748002972275],
						[61.425583300095596, 2.0548748007992996],
						[61.425561100093773, 2.0548748006551261],
						[61.425561091978473, 2.0548748005859028],
						[61.425561074952164, 2.0548748025066046],
						[61.425561066836885, 2.0548748024373769],
						[61.425544382903908, 2.0548776006019045],
						[61.425525000336471, 2.0548775996982078],
						[61.425502800334563, 2.0548775995493078],
						[61.425486100105843, 2.0548775998348421],
						[61.425486093772811, 2.0548775993676163],
						[61.425486081201988, 2.0548776002933602],
						[61.425486074964248, 2.0548776016863246],
						[61.425463886802248, 2.0548804003988126],
						[61.425444399722579, 2.0548804002512333],
						[61.42542780044154, 2.0548804005680661],
						[61.425405600439511, 2.0548804004126411],
						[61.425386099802566, 2.0548803995272271],
						[61.425386094455952, 2.0548804007211827],
						[61.425386081885144, 2.0548804016469204],
						[61.425386076443189, 2.0548804009807071],
						[61.425363887684092, 2.0548831000933023],
						[61.425347200026046, 2.0548830994452825],
						[61.425327920283664, 2.0548831000271996],
						[61.425305775979957, 2.0548416068926909],
						[61.425305734102167, 2.0548415635636168],
						[61.425305632267495, 2.0548415110460825],
						[61.425305573201705, 2.0548415016586352],
						[61.425286085590919, 2.0548441996586342],
						[61.425264035228516, 2.05484419997254],
						[61.425247386198919, 2.0548027253090808],
						[61.425247343144022, 2.0548026765986243],
						[61.425247236281848, 2.0548026139152666],
						[61.425247170787699, 2.0548026022005139],
						[61.425227910940265, 2.0548053829386355],
						[61.425205776774085, 2.0547638068184595],
						[61.425205739542449, 2.0547637662149691],
						[61.425205651264996, 2.0547637144332338],
						[61.425205600028498, 2.0547636995346696],
						[61.425189008863526, 2.0547636999745569],
						[61.425164068364708, 2.0547248917168517],
						[61.425164032405483, 2.0547248583551214],
						[61.425163947978447, 2.0547248113580636],
						[61.425163899605941, 2.0547247995829077],
						[61.4251473282362, 2.0547247993844864],
						[61.425127981408188, 2.0546832162018487],
						[61.42512798032643, 2.0546832126805135],
						[61.425127978258217, 2.0546832074980363],
						[61.425127976380693, 2.0546832060358708],
						[61.42510578005988, 2.0546416139030201],
						[61.425089083864066, 2.0546027201336421],
						[61.425089047428166, 2.05460267747134],
						[61.42508895501426, 2.0546026153252845],
						[61.42508890011802, 2.0546025993628505],
						[61.425066822738891, 2.0546026002281397],
						[61.425047380345937, 2.0545638136920905],
						[61.425027980747416, 2.0545221164597516],
						[61.42502797966565, 2.0545221129384341],
						[61.425027977597438, 2.054522107755985],
						[61.425027975719907, 2.0545221062938164],
						[61.425005780149164, 2.0544805123487166],
						[61.424989084005588, 2.0544416206502221],
						[61.424989046583207, 2.0544415763270738],
						[61.424988955155669, 2.0544415158428486],
						[61.424988900259436, 2.0544414998807325],
						[61.424966827337421, 2.054441499860558],
						[61.42494738314079, 2.0543999181239307],
						[61.424930784073148, 2.0543610217898407],
						[61.424930781018482, 2.0543610149462626],
						[61.424930775004533, 2.054361003119261],
						[61.424930771949853, 2.0543609962756855],
						[61.424905778383355, 2.0543193087162432],
						[61.424889083875705, 2.0542805203325054],
						[61.424889082984592, 2.0542805205315151],
						[61.424889081902798, 2.0542805170102398],
						[61.424889080916365, 2.0542805153491059],
						[61.424869580989238, 2.0542388145521695],
						[61.424869580002834, 2.0542388128910307],
						[61.424869577934622, 2.0542388077086087],
						[61.424869576948161, 2.0542388060474761],
						[61.424847377740107, 2.0541971079480663],
						[61.424827979101593, 2.0541582105438567],
						[61.424827941869779, 2.0541581699415681],
						[61.424827853401482, 2.0541581144418424],
						[61.424827800382729, 2.0541580999424189],
						[61.424805734687773, 2.054158100383658],
						[61.424789086258642, 2.0541166246356677],
						[61.424789043203546, 2.0541165759268405],
						[61.424788936341095, 2.0541165132466834],
						[61.424788872724442, 2.0541165029956181],
						[61.424769385929544, 2.0541191993232144],
						[61.424747200383393, 2.0541192002157369],
						[61.424730720068432, 2.0541192006864875],
						[61.424708476289894, 2.0540777065875169],
						[61.424708432629672, 2.054077663658286],
						[61.424708330794772, 2.0540776111441295],
						[61.424708270837883, 2.0540776019572435],
						[61.424688885684276, 2.0540803997734614],
						[61.424672199814474, 2.0540803991338104],
						[61.424650127787103, 2.0540803991558367],
						[61.424630780681952, 2.0540388151697435],
						[61.424630738708586, 2.054038769982522],
						[61.424630635791871, 2.0540387139473713],
						[61.424630574753209, 2.0540387012393344],
						[61.424608287450027, 2.0540415000012229],
						[61.42459169985483, 2.0540415000089371],
						[61.424572316403342, 2.0540414997857601],
						[61.42455017373743, 2.0540027001252517],
						[61.424550136696283, 2.0540026632437445],
						[61.42455005039146, 2.0540026147873904],
						[61.424550000046011, 2.0540025996912932],
						[61.424530600364335, 2.0540025993525921],
						[61.424508300314308, 2.0540025995497584],
						[61.42449170014855, 2.0540026005007492],
						[61.424472200410243, 2.0540025999284679],
						[61.424472194077161, 2.0540025994614153],
						[61.424472180615268, 2.0540026005864642],
						[61.424472175268633, 2.0540026017805335],
						[61.424449987036397, 2.0540053991166847],
						[61.424433300371255, 2.0540054005679917],
						[61.424411228344496, 2.054005400632084],
						[61.42439188112629, 2.0539638151431725],
						[61.424391788107066, 2.0539637587796462],
						[61.424391603524008, 2.0539637623747073],
						[61.424391513933088, 2.0539638256555031],
						[61.424374857929841, 2.0540054162595114],
						[61.424352775713331, 2.0540081014779079],
						[61.424352728866182, 2.0540081194658799],
						[61.424352650668908, 2.0540081764391172],
						[61.424352619318832, 2.0540082154243735],
						[61.424333172600434, 2.0540498008055077],
						[61.424311099586696, 2.0540497991723994],
						[61.424294400254944, 2.0540497996609606],
						[61.424294344401389, 2.0540498177787936],
						[61.424294251150684, 2.0540498799950511],
						[61.42429421384886, 2.0540499259535854],
						[61.424277664239241, 2.0540915001385756],
						[61.424252799919451, 2.0540914991017698],
						[61.424252744065925, 2.0540915172194949],
						[61.424252650815205, 2.0540915794355361],
						[61.424252614404438, 2.0540916251949546],
						[61.424235956169341, 2.05413312008048],
						[61.424219383037801, 2.0541358993795154],
						[61.424197200164301, 2.0541358996374997],
						[61.424197145997624, 2.0541359154970018],
						[61.424197056120597, 2.0541359731965767],
						[61.42419701862805, 2.0541360154346417],
						[61.424177671922706, 2.0541776007563106],
						[61.424155599893858, 2.0541776006865518],
						[61.424155543944977, 2.0541776169439601],
						[61.424155450694215, 2.0541776791595305],
						[61.424155414283419, 2.0541777249186972],
						[61.424138764963423, 2.0542192006970996],
						[61.424119400413794, 2.054219199886091],
						[61.424097200417641, 2.0542192001409338],
						[61.424097142781839, 2.0542192186563497],
						[61.424097048735298, 2.0542192829307626],
						[61.424097013310941, 2.0542193303508838],
						[61.424080460951117, 2.0542637005208171],
						[61.424058299653282, 2.0542636996255075],
						[61.424058246377683, 2.0542637152857166],
						[61.424058155609487, 2.0542637731836266],
						[61.424058119008023, 2.0542638152223556],
						[61.424038772259408, 2.0543054002761512],
						[61.424022200001147, 2.0543054004607213],
						[61.424022149303525, 2.0543054136637324],
						[61.424022060126894, 2.0543054674432577],
						[61.424022024225835, 2.0543055055626978],
						[61.423999879703914, 2.0543470003992854],
						[61.423983300221138, 2.0543470002922533],
						[61.423958299815887, 2.0543469993232391],
						[61.423958291700615, 2.054346999254125],
						[61.423958274674312, 2.0543470011749756],
						[61.423958266654381, 2.054347002965947],
						[61.423941682694505, 2.0543497995847018],
						[61.423924999590632, 2.0543498000377145],
						[61.42392494889301, 2.0543498132406035],
						[61.423924859716308, 2.0543498670198281],
						[61.42392482292415, 2.0543499053380829],
						[61.423902679874317, 2.054391499390555],
						[61.423883300078799, 2.0543914999856829],
						[61.423861100080586, 2.054391500106731],
						[61.423844399854694, 2.0543915005953228],
						[61.423844392630521, 2.054391500327204],
						[61.423844379168607, 2.0543915014520291],
						[61.423844371944462, 2.0543915011839027],
						[61.423824986182566, 2.0543941991131449],
						[61.423802799741509, 2.0543941999648121],
						[61.423786100406666, 2.0543942002508966],
						[61.423761100000668, 2.0543941992336783],
						[61.423761091885389, 2.0543941991645465],
						[61.423761074954385, 2.0543942029454354],
						[61.423761066839134, 2.0543942028763023],
						[61.4237443828209, 2.05439699921279],
						[61.423727799773147, 2.0543969998662517],
						[61.423727749075525, 2.0543970130689546],
						[61.423727659898788, 2.0543970668476712],
						[61.423727623106579, 2.0543971051656191],
						[61.423705480133023, 2.0544387007730771],
						[61.423686100241532, 2.0544386994707513],
						[61.423663900242673, 2.0544386995489692],
						[61.423663892127394, 2.0544386994798272],
						[61.423663875101049, 2.0544387014006085],
						[61.42366386708111, 2.0544387031915345],
						[61.423647183061433, 2.0544414994921154],
						[61.423627799700796, 2.0544415008417123],
						[61.423605599701844, 2.0544415009152139],
						[61.423588900271142, 2.0544414993089912],
						[61.423569399732187, 2.0544415004841028],
						[61.423569394290247, 2.054441499817965],
						[61.423569381719446, 2.0544415007437773],
						[61.423569376277491, 2.0544415000776581],
						[61.423547187527817, 2.0544441992602978],
						[61.423530600023987, 2.0544442008767096],
						[61.423505599617123, 2.0544441998059613],
						[61.423505543763532, 2.0544442179225362],
						[61.423505451403699, 2.0544442799369231],
						[61.42350541410169, 2.0544443258937561],
						[61.423488765281625, 2.0544859002251039],
						[61.423472200245747, 2.0544859005626743],
						[61.423472148656984, 2.0544859139640907],
						[61.423472060371353, 2.0544859675430711],
						[61.42347202268801, 2.054486006059566],
						[61.423449822865784, 2.0545277061255369],
						[61.423449822070026, 2.0545277081845978],
						[61.423449819587347, 2.0545277125016783],
						[61.423449818791596, 2.0545277145607153],
						[61.423430464983873, 2.0545692155134683],
						[61.423408286948202, 2.0545720006166195],
						[61.42339170023903, 2.0545720001010976],
						[61.42339164607229, 2.0545720159593386],
						[61.423391556195057, 2.0545720736560176],
						[61.42339151870236, 2.0545721158924115],
						[61.423372072677033, 2.0546136994115876],
						[61.423349999751736, 2.0546136991945203],
						[61.423349943898117, 2.0546137173106818],
						[61.423349850647064, 2.0546137795232036],
						[61.423349814236126, 2.0546138252806201],
						[61.423333165359573, 2.0546553993116778],
						[61.423311099657973, 2.0546553993314345],
						[61.423311043804325, 2.054655417447512],
						[61.423310950553287, 2.0546554796598184],
						[61.423310914142306, 2.0546555254171142],
						[61.423294264655041, 2.0546969997958215],
						[61.423275000252211, 2.0546970007472329],
						[61.423274946881207, 2.0546970145461598],
						[61.423274857799711, 2.0546970701832126],
						[61.423274821102723, 2.0546971103602654],
						[61.423252676116476, 2.0547414993436925],
						[61.423236100289792, 2.054741500064833],
						[61.423213900287251, 2.0547414999063207],
						[61.423213846120483, 2.0547415157641566],
						[61.423213756243157, 2.0547415734599888],
						[61.423213718750389, 2.0547416156959293],
						[61.423194272962206, 2.0547830990918063],
						[61.42317779986508, 2.0547830994258218],
						[61.42317774926272, 2.0547831144876718],
						[61.423177659990493, 2.0547831664041958],
						[61.423177623293505, 2.0547832065810079],
						[61.423155480090621, 2.0548247993017781],
						[61.423136100290478, 2.0548247995969415],
						[61.423136047014772, 2.0548248152556141],
						[61.423135957933169, 2.054824870892022],
						[61.423135921236174, 2.0548249110687293],
						[61.423113775606964, 2.054869200182607],
						[61.423097199779164, 2.0548692008309648],
						[61.423097143830169, 2.054869217086472],
						[61.423097050578988, 2.0548692792976833],
						[61.423097014167958, 2.0548693250543995],
						[61.423080464371388, 2.0549108990412095],
						[61.423055600135896, 2.0549108991479765],
						[61.423038899905286, 2.0549108993298804],
						[61.42302219967462, 2.0549108995111895],
						[61.423022149072253, 2.054910914572738],
						[61.423022059799997, 2.0549109664886038],
						[61.423022023102938, 2.0549110066650669],
						[61.422999870816732, 2.0549526161515441],
						[61.422980585913756, 2.0549554002168473],
						[61.422958300300444, 2.0549554001959356],
						[61.42294170012601, 2.0549554005851367],
						[61.422922200377513, 2.0549553993530485],
						[61.422922148884012, 2.0549554146134206],
						[61.422922059611707, 2.0549554665289813],
						[61.422922023805789, 2.0549555065062912],
						[61.422899870110967, 2.0549970183064006],
						[61.422883282694492, 2.0549998001648113],
						[61.42286109981054, 2.054999799745421],
						[61.422841700213532, 2.0549998005769976],
						[61.422824999982019, 2.054999800703758],
						[61.422824949284298, 2.0549998139050087],
						[61.422824860107319, 2.0549998676802765],
						[61.422824823314905, 2.054999905996346],
						[61.422802680131284, 2.0550414999400033],
						[61.422783300328781, 2.0550415000860172],
						[61.422783293995714, 2.0550414996187687],
						[61.422783282316011, 2.0550415003453093],
						[61.422783276078263, 2.0550415017380836],
						[61.422761088199614, 2.0550442001904798],
						[61.422744399647428, 2.055044199564029],
						[61.422725000049915, 2.0550442003632221],
						[61.422702800043673, 2.0550441999651712],
						[61.422686099811727, 2.0550442000632381],
						[61.422686094369759, 2.0550441993970252],
						[61.422686080907859, 2.0550442005215057],
						[61.422686074670118, 2.0550442019142747],
						[61.422663887387699, 2.0550469999274403],
						[61.422644400304257, 2.0550469995611511],
						[61.422627800128886, 2.0550469998906182],
						[61.42260560012253, 2.0550469994860454],
						[61.422605592898371, 2.0550469992177609],
						[61.422605578545344, 2.0550470005412054],
						[61.422605572307589, 2.0550470019339686],
						[61.422586071915077, 2.055049802021311],
						[61.422586027741069, 2.0550498194096356],
						[61.422585953808131, 2.0550498716619092],
						[61.422585923158159, 2.0550499067248302],
						[61.422563723138076, 2.0550916051728834],
						[61.422563720750716, 2.0550916113498161],
						[61.422563716771776, 2.0550916216446864],
						[61.422563714193799, 2.0550916241015829],
						[61.422547015916081, 2.0551332209722961],
						[61.422527672330645, 2.0551748007692172],
						[61.422505600289739, 2.0551747999164993],
						[61.422505544340702, 2.0551748161710819],
						[61.422505451184662, 2.0551748802400933],
						[61.422505413882377, 2.05517492619451],
						[61.422488717574609, 2.0552166146795217],
						[61.42246652374601, 2.0552582061307758],
						[61.422466521263324, 2.0552582104476569],
						[61.422466517284406, 2.0552582207424659],
						[61.422466513910578, 2.0552582252583305],
						[61.422449816174819, 2.055299921484866],
						[61.42243047154831, 2.0553414993868762],
						[61.422408300435336, 2.0553414998366724],
						[61.422408243595157, 2.055341516289849],
						[61.422408151330146, 2.0553415801591917],
						[61.422408114027796, 2.0553416261132802],
						[61.422391564363373, 2.0553830991415882],
						[61.422372199896827, 2.0553830993961362],
						[61.422372193659072, 2.055383100788819],
						[61.422372181088264, 2.0553831017141846],
						[61.422372174755218, 2.0553831012468624],
						[61.42234997498597, 2.0553859017616714],
						[61.422349925560582, 2.0553859222030866],
						[61.422349845771038, 2.0553859832868131],
						[61.422349813720025, 2.0553860261870134],
						[61.422333164675379, 2.0554276003113996],
						[61.422311099855776, 2.0554275995389446],
						[61.422291700254313, 2.0554276000817246],
						[61.422291644305226, 2.0554276163357201],
						[61.42229155114908, 2.055427680403493],
						[61.422291513751382, 2.0554277244972896],
						[61.422274865082635, 2.0554692006069937],
						[61.422252800262548, 2.0554691998023689],
						[61.422252793038389, 2.0554691995339844],
						[61.422252778685362, 2.0554692008572086],
						[61.422252772447607, 2.055469202249868],
						[61.422233286394643, 2.0554720007041616],
						[61.422211099940661, 2.0554720007140634],
						[61.422211043991531, 2.0554720169679359],
						[61.422210950740038, 2.0554720791754115],
						[61.422210914424092, 2.0554721267900686],
						[61.422194265349496, 2.0555137006799362],
						[61.422177800361155, 2.0555137006625119],
						[61.422155600349434, 2.0555136999043953],
						[61.422136099799275, 2.0555137003496449],
						[61.42213609435732, 2.0555136996833561],
						[61.42213608089542, 2.0555137008075994],
						[61.422136074657665, 2.0555137022002405],
						[61.422113887359288, 2.0555164998046411],
						[61.422097199789285, 2.055516500563586],
						[61.422077800091508, 2.0555164991823109],
						[61.422055600174957, 2.0555165002775491],
						[61.422038899938798, 2.0555165000997402],
						[61.422038895387935, 2.0555164992345061],
						[61.422038883803552, 2.0555165018208243],
						[61.422038879252689, 2.0555165009555885],
						[61.422013889261592, 2.0555191999858686],
						[61.421997326889766, 2.0555191991258654],
						[61.421977980763906, 2.0554777153642547],
						[61.421977938791059, 2.0554776701786728],
						[61.421977835779607, 2.0554776122829601],
						[61.421977774836364, 2.0554776014328056],
						[61.421955586647655, 2.0554803992402548],
						[61.421938899968936, 2.0554803998140203],
						[61.421916700052641, 2.0554804009285066],
						[61.42189720029841, 2.0554803993259005],
						[61.421880600119067, 2.0554803993983759],
						[61.421880593881333, 2.0554804007910032],
						[61.421880582201638, 2.0554804015173529],
						[61.42188057586857, 2.0554804010500196],
						[61.421858288013155, 2.0554831006936385],
						[61.421838900090549, 2.0554831004570842],
						[61.421816700078821, 2.0554830997050013],
						[61.42180012424523, 2.055483099981581],
						[61.421780779156165, 2.0554443102067967],
						[61.421780741924771, 2.0554442696066806],
						[61.421780652565893, 2.0554442143070819],
						[61.421780599547255, 2.0554441998065376],
						[61.421758300465704, 2.0554442005040809],
						[61.421741700286546, 2.0554442005910278],
						[61.421722199641394, 2.0554441992028778],
						[61.421699999725327, 2.0554442003330333],
						[61.421699991610041, 2.0554442002636018],
						[61.421699974583682, 2.0554442021836423],
						[61.421699967454835, 2.0554442037752163],
						[61.421683401409759, 2.0554469804725097],
						[61.421658470775803, 2.0554054966912685],
						[61.421658434721472, 2.0554054614722768],
						[61.42165834930826, 2.0554054128170089],
						[61.421658299949371, 2.0554053993807582],
						[61.421641699770497, 2.0554053994848664],
						[61.421641691655211, 2.0554053994154438],
						[61.421641675615291, 2.0554054029965019],
						[61.421641668391118, 2.0554054027281308],
						[61.421625101846125, 2.0554080817263771],
						[61.421600171106526, 2.0553665961916736],
						[61.421600135052174, 2.0553665609727889],
						[61.421600048747877, 2.0553665125166543],
						[61.421600000375435, 2.0553665007414792],
						[61.421583300140121, 2.0553665006281441],
						[61.421561223640786, 2.0553665005906385],
						[61.421541879414889, 2.0553277109792227],
						[61.421541842183473, 2.0553276703795382],
						[61.421541752824538, 2.0553276150806572],
						[61.421541699805914, 2.0553276005803971],
						[61.421525120313646, 2.055327599865989],
						[61.421502977113562, 2.0552860057451752],
						[61.421502934440205, 2.0552859644793391],
						[61.421502830823556, 2.0552859123638396],
						[61.421502771757808, 2.0552859029762209],
						[61.421483410940276, 2.0552886828936421],
						[61.421461276702615, 2.0552471056639083],
						[61.421461239566526, 2.0552470669243563],
						[61.421461151193988, 2.0552470132868303],
						[61.421461100052881, 2.0552470002487944],
						[61.421444524220938, 2.055247000638619],
						[61.421425179072955, 2.0552082114431562],
						[61.421425141841524, 2.0552081708437728],
						[61.421425053373667, 2.0552081153464554],
						[61.421425000355008, 2.0552081008464609],
						[61.421402927421646, 2.0552081001180613],
						[61.42138348106306, 2.0551666152276948],
						[61.421383443736275, 2.0551665727684663],
						[61.421383354281978, 2.0551665156103494],
						[61.421383300276922, 2.0551664994494523],
						[61.421361100363491, 2.0551665007616959],
						[61.421361092248205, 2.0551665006923323],
						[61.421361076112959, 2.0551665024135368],
						[61.421361067997687, 2.0551665023441674],
						[61.421344384342547, 2.0551692000582067],
						[61.421324999986865, 2.0551691992002712],
						[61.42130280007337, 2.055169200507891],
						[61.421302744124297, 2.0551692167616991],
						[61.421302650968265, 2.0551692808280104],
						[61.421302613665958, 2.0551693267805824],
						[61.421285964667746, 2.0552108995823821],
						[61.421263899944833, 2.055210900774886],
						[61.421263892720624, 2.0552109005065624],
						[61.421263879258731, 2.0552109016308786],
						[61.421263872034551, 2.0552109013625337],
						[61.421244528245083, 2.0552136793753415],
						[61.421227985958886, 2.055172125729007],
						[61.421227983890709, 2.055172120547109],
						[61.421227978863257, 2.0551721103822835],
						[61.42122797590401, 2.0551721053993486],
						[61.421205778434654, 2.0551305088327512],
						[61.421186278886573, 2.0550916096121425],
						[61.421186241750441, 2.0550915708731168],
						[61.42118615328252, 2.0550915153765184],
						[61.421186100263874, 2.0550915008768049],
						[61.421164027331528, 2.0550915002199539],
						[61.421144583115165, 2.0550499174053338],
						[61.42112798410659, 2.0550110216083124],
						[61.421127982133719, 2.0550110182863692],
						[61.421127978092684, 2.055011009782564],
						[61.421127977010968, 2.0550110062616733],
						[61.421105776831219, 2.0549693051894207],
						[61.421105739695015, 2.054969266450648],
						[61.42110565141774, 2.0549692146743754],
						[61.421105600181278, 2.0549691997769735],
						[61.421086232057853, 2.0549691991724219],
						[61.421069583809171, 2.0549305214120084],
						[61.421069580754541, 2.0549305145691958],
						[61.42106957474067, 2.0549305027434968],
						[61.421069571781366, 2.0549304977606186],
						[61.421044579061636, 2.0548888089183333],
						[61.421027984051022, 2.0548499218352942],
						[61.421027946628854, 2.0548498775169866],
						[61.421027855201586, 2.0548498170384648],
						[61.421027800210048, 2.0548497992172932],
						[61.421005727375487, 2.0548498005842619],
						[61.420986281117486, 2.0548082148082609],
						[61.420986243790622, 2.0548081723499729],
						[61.420986154336177, 2.0548081151935724],
						[61.420986100331085, 2.0548080990334436],
						[61.420969516387046, 2.0548080995709781],
						[61.420947373900852, 2.0547693018109094],
						[61.420947336859953, 2.0547692649324474],
						[61.420947250460131, 2.0547692146189926],
						[61.42094720011476, 2.054769199523018],
						[61.420927920370005, 2.0547691999973221],
						[61.42090577770815, 2.0547277084840507],
						[61.420886278944913, 2.0546888096864477],
						[61.420886241808667, 2.0546887709482435],
						[61.420886153340625, 2.0546887154532949],
						[61.420886100226603, 2.0546886990944553],
						[61.42086951628346, 2.0546886996920635],
						[61.420847373670846, 2.0546499002790757],
						[61.420847336629947, 2.0546498634008632],
						[61.420847250230061, 2.0546498130878863],
						[61.420847199980017, 2.0546497998520445],
						[61.42082792023627, 2.0546498003961946],
						[61.420805776753724, 2.054608205809223],
						[61.420805739617471, 2.0546081670712089],
						[61.420805651244713, 2.0546081134365188],
						[61.420805600103556, 2.0546081003997245],
						[61.420789023289863, 2.0546080994457223],
						[61.420769578716104, 2.0545693091558008],
						[61.420769541579837, 2.0545692704178955],
						[61.420769453111767, 2.0545692149234709],
						[61.420769400093086, 2.0545692004247762],
						[61.420747327165934, 2.0545692001186757],
						[61.420727980591884, 2.0545277148598498],
						[61.420727938618768, 2.0545276696772725],
						[61.420727835702316, 2.0545276136466737],
						[61.420727774759015, 2.0545276027986623],
						[61.420705728059168, 2.0545303826937831],
						[61.420689086223483, 2.0544888259715566],
						[61.420689085141703, 2.0544888224507414],
						[61.420689082182427, 2.0544888174679401],
						[61.420689081195981, 2.0544888158070154],
						[61.420669581125985, 2.0544471147457286],
						[61.420669539152826, 2.0544470695633246],
						[61.420669436236352, 2.0544470135331054],
						[61.420669375293031, 2.0544470026852535],
						[61.420647328595571, 2.0544497826293813],
						[61.420630785902482, 2.0544082264599521],
						[61.420630782847844, 2.0544082196172875],
						[61.42063077486106, 2.0544082044700072],
						[61.420630770915302, 2.0544081978263109],
						[61.420605777755611, 2.0543666097456539],
						[61.420589099701608, 2.0543277589251017],
						[61.420589099644666, 2.0542861003224262],
						[61.4205890976096, 2.054286078202733],
						[61.420589088192806, 2.0542860351571783],
						[61.420589080811148, 2.0542860142312827],
						[61.420569599996874, 2.0542443561474917],
						[61.420569599716046, 2.0542027992003158],
						[61.420569596599201, 2.0542027735598292],
						[61.420569584223109, 2.0542027255315869],
						[61.420569573977382, 2.0542027014828941],
						[61.420547377682794, 2.054163807515073],
						[61.420527999944248, 2.0541221566392021],
						[61.420527999626792, 2.0540805997499749],
						[61.420527966025681, 2.0540805244812721],
						[61.420527853692256, 2.0540804254070655],
						[61.420527775851063, 2.0540804014025773],
						[61.420505727676456, 2.0540830822167329],
						[61.420489086308393, 2.0540416255708247],
						[61.420489048790643, 2.0540415793943554],
						[61.420488956376616, 2.0540415172582667],
						[61.420488899602809, 2.054041499836726],
						[61.420469399956268, 2.0540415009362407],
						[61.420469393623215, 2.0540415004692019],
						[61.420469381943519, 2.0540415011960227],
						[61.420469375610445, 2.054041500728967],
						[61.420447320308085, 2.0540441831546969],
						[61.420427980775287, 2.0540027158256247],
						[61.420427944339281, 2.0540026731700634],
						[61.420427853993466, 2.0540026162158735],
						[61.42042779998831, 2.0540026000573679],
						[61.420411215955014, 2.0540025991443538],
						[61.420389074052814, 2.053963800582284],
						[61.420389037011716, 2.0539637637054287],
						[61.42038894972054, 2.0539637135939861],
						[61.420388900361552, 2.0539637001604243],
						[61.420369399729218, 2.0539636996436479],
						[61.420347335814533, 2.0539636996181065],
						[61.420330785791215, 2.05392212690034],
						[61.420330783723003, 2.0539221217186951],
						[61.42033078066833, 2.0539221148761402],
						[61.420330778600132, 2.0539221096944731],
						[61.420311278637421, 2.0538832104496354],
						[61.420311277650967, 2.0538832087887506],
						[61.420311276664535, 2.0538832071278601],
						[61.420311275678095, 2.0538832054669771],
						[61.420289075776836, 2.0538416058440925],
						[61.420289040422588, 2.0538415667096483],
						[61.420288951158504, 2.0538415132769194],
						[61.420288900017297, 2.0538415002415675],
						[61.420269515673191, 2.0538415001667909],
						[61.420247380634272, 2.0538027135182895],
						[61.420230785955113, 2.0537610268243314],
						[61.420230749328383, 2.0537609804495669],
						[61.420230655927817, 2.0537609166539039],
						[61.420230600140428, 2.0537609008938666],
						[61.420211100400991, 2.0537609002985602],
						[61.420211094067923, 2.0537608998315782],
						[61.42021108060603, 2.0537609009564552],
						[61.420211075259381, 2.0537609021503584],
						[61.420188887025141, 2.0537636990772974],
						[61.420172200359033, 2.0537637005086142],
						[61.420150000268848, 2.0537636990442474],
						[61.420130732212463, 2.0537636993815873],
						[61.420114083845462, 2.0537249218143749],
						[61.420114047314087, 2.053724877299687],
						[61.420113954899954, 2.0537248151652236],
						[61.420113900003642, 2.0537247992063175],
						[61.420091700009159, 2.0537247996268109],
						[61.420072200269949, 2.0537247990483989],
						[61.420050000275467, 2.0537247994669419],
						[61.420049992160187, 2.0537247993979166],
						[61.42004997513385, 2.0537248013187348],
						[61.420049967113918, 2.0537248031095761],
						[61.420033283107365, 2.0537275993406112],
						[61.420013899750543, 2.0537276009871936],
						[61.419991699660628, 2.053727599541157],
						[61.419975109391501, 2.0537276001522269],
						[61.419950168319737, 2.0536887915256541],
						[61.419950132360349, 2.053688758170328],
						[61.419950047933085, 2.0536887111830273],
						[61.419949999560522, 2.0536886994109254],
						[61.41993330022872, 2.0536886999401212],
						[61.419913899655207, 2.0536886998079438],
						[61.419891699660937, 2.0536887002448192],
						[61.419891692436778, 2.0536886999768322],
						[61.419891675410454, 2.0536887018976517],
						[61.419891667390544, 2.0536887036884863],
						[61.419875109411663, 2.0536914793019792],
						[61.419852976610073, 2.0536499067723817],
						[61.419852940364663, 2.05364986783766],
						[61.419852851100515, 2.0536498144061559],
						[61.419852799863989, 2.0536497995114051],
						[61.41983343630271, 2.053649800541089],
						[61.419816886100314, 2.05360822680439],
						[61.419816843936232, 2.0536081779046236],
						[61.419816737964844, 2.0536081150361261],
						[61.419816675143927, 2.0536081027282513],
						[61.419794520298829, 2.0536108828494393],
						[61.419775180943837, 2.0535693168750284],
						[61.419775143616604, 2.0535692744198117],
						[61.419775054066434, 2.0535692154091505],
						[61.419775000061229, 2.0535691992516694],
						[61.419758408902076, 2.0535692001385248],
						[61.419733468388287, 2.0535304914664634],
						[61.41973343242887, 2.0535304581115379],
						[61.419733348001571, 2.0535304111249748],
						[61.419733299628994, 2.0535303993531828],
						[61.419716700354861, 2.0535304001902825],
						[61.41969452827238, 2.0535304001417014],
						[61.419675182690703, 2.0534888188036513],
						[61.419658483917658, 2.0534499215017554],
						[61.419658447386162, 2.0534498769880014],
						[61.41965835506727, 2.0534498167150241],
						[61.419658300170944, 2.0534498007567867],
						[61.419636228050457, 2.0534497991334604],
						[61.41961688145954, 2.05340821626165],
						[61.419616839390748, 2.0534081692222768],
						[61.419616735582835, 2.0534081133961637],
						[61.419616674639492, 2.0534081025505757],
						[61.419594519800143, 2.0534108827865869],
						[61.419575181185365, 2.0533693151402201],
						[61.419575143953452, 2.0533692745453194],
						[61.41957505440319, 2.0533692155355832],
						[61.419575000397934, 2.0533691993785381],
						[61.419558416464454, 2.0533692006328312],
						[61.419536277960141, 2.0533305081031128],
						[61.419516880678586, 2.0532888163580645],
						[61.41951684424243, 2.0532887737045242],
						[61.419516753896353, 2.0532887167539329],
						[61.419516699891126, 2.0532887005970442],
						[61.419494532266533, 2.0532886997162332],
						[61.419477983740272, 2.0532499215512856],
						[61.419477982753826, 2.053249919890443],
						[61.419477981672031, 2.0532499163698059],
						[61.419477980685592, 2.0532499147089776],
						[61.419458481161044, 2.0532082158203959],
						[61.419458479188144, 2.0532082124987525],
						[61.419458476038102, 2.0532082037966477],
						[61.419458474065216, 2.0532082004750039],
						[61.419436278407176, 2.0531693083705926],
						[61.419416880690875, 2.0531277147632201],
						[61.419416879704443, 2.0531277131023962],
						[61.419416877636202, 2.0531277079209347],
						[61.41941687575865, 2.0531277064591111],
						[61.419394580629316, 2.0530860131332873],
						[61.419377983873943, 2.0530471218059296],
						[61.419377982887475, 2.0530471201451195],
						[61.419377981805702, 2.053047116624477],
						[61.419377980819235, 2.0530471149636673],
						[61.41935850006687, 2.0530055550944968],
						[61.419358499724176, 2.0529639000235549],
						[61.419358496607117, 2.0529638743842145],
						[61.419358484135259, 2.0529638244985033],
						[61.419358473889353, 2.052963800451137],
						[61.41933627816568, 2.0529249086455867],
						[61.41931688067185, 2.0528832155608945],
						[61.419316878698957, 2.0528832122392688],
						[61.41931687554888, 2.0528832035372329],
						[61.419316873575987, 2.0528832002156228],
						[61.419294577774259, 2.0528443082904606],
						[61.419275200074587, 2.0528027557305943],
						[61.419275199671269, 2.0527611007751223],
						[61.419275197731345, 2.0527610805162677],
						[61.419275190287003, 2.0527610407945889],
						[61.419275183891557, 2.0527610215307557],
						[61.419258483841183, 2.0527221215973359],
						[61.419258481772914, 2.0527221164159255],
						[61.419258477827121, 2.0527221097727222],
						[61.419258475854221, 2.0527221064511298],
						[61.419236279289407, 2.0526805100992207],
						[61.419216880738261, 2.0526388156405311],
						[61.419216878765347, 2.052638812318949],
						[61.419216875615305, 2.0526388036169587],
						[61.419216873642405, 2.0526388002953575],
						[61.419194599572393, 2.05259994706705],
						[61.419194600380983, 2.0525582994635778],
						[61.419194598345655, 2.0525582773449984],
						[61.41919458813264, 2.0525582363606953],
						[61.419194580750677, 2.0525582154361719],
						[61.419175199913269, 2.0525166563471009],
						[61.419175199625471, 2.0524778005526891],
						[61.419175200400353, 2.0524361003062337],
						[61.419175198364989, 2.05243607818768],
						[61.419175188056599, 2.052436035343645],
						[61.419175180769997, 2.0524360162789583],
						[61.419155800094117, 2.0523943556703603],
						[61.419155800166209, 2.0523528004469807],
						[61.419155800216643, 2.0523138994476713],
						[61.419155797194797, 2.0523138756683634],
						[61.419155784913428, 2.0523138295028329],
						[61.419155775558529, 2.0523138052568206],
						[61.419133500112814, 2.0522721498020977],
						[61.419133500433986, 2.0522306001902844],
						[61.419133497412155, 2.0522305764109992],
						[61.419133487008303, 2.052230531707294],
						[61.419133478735219, 2.0522305109819046],
						[61.419114100179016, 2.0521916528966213],
						[61.41911409968678, 2.0521500000285813],
						[61.419114097842069, 2.0521499816296935],
						[61.419114091384081, 2.0521499435691339],
						[61.419114085974975, 2.0521499259663041],
						[61.419097396876367, 2.0521082551206726],
						[61.419094600340266, 2.0520666868771773],
						[61.419094595536194, 2.0520666634959639],
						[61.419094582554351, 2.0520666212492467],
						[61.419094574281232, 2.0520666005239265],
						[61.419072400016901, 2.0520277462283394],
						[61.419072399856603, 2.0519861008585134],
						[61.419072397916551, 2.0519860805998453],
						[61.419072390662741, 2.0519860445981899],
						[61.419072386144705, 2.0519860267963921],
						[61.419055800013773, 2.0519443613041477],
						[61.419055799664015, 2.0519028006437763],
						[61.419055796356034, 2.0519027712852145],
						[61.41905578012868, 2.0519027184769678],
						[61.419055767909676, 2.0519026911086686],
						[61.419030799719827, 2.0518638402335352],
						[61.419030799796147, 2.0518222005019537],
						[61.419030797856074, 2.0518221802433283],
						[61.419030790602235, 2.0518221442417768],
						[61.419030785988816, 2.0518221245802453],
						[61.4190140995562, 2.0517805606301174],
						[61.419014099665709, 2.0517416992720077],
						[61.419014097630232, 2.0517416771536228],
						[61.419014088308074, 2.051741635970791],
						[61.419014080926011, 2.0517416150465846],
						[61.418994600085455, 2.0516999567275964],
						[61.418994600145069, 2.0516583001106032],
						[61.418994597027719, 2.0516582744716971],
						[61.418994583664329, 2.0516582247861437],
						[61.418994574309309, 2.0516582005404582],
						[61.418972399934191, 2.0516193466632378],
						[61.418972400154125, 2.0515777991391033],
						[61.418972398213981, 2.0515777788805396],
						[61.418972387905299, 2.0515777360370908],
						[61.41897238061857, 2.0515777169727309],
						[61.418952999595817, 2.0515360553183863],
						[61.418952999701311, 2.0514944006200841],
						[61.418952997475074, 2.0514943747821852],
						[61.4189529832205, 2.0514943252958098],
						[61.418952973865458, 2.0514943010502269],
						[61.41893080004489, 2.0514555469061291],
						[61.418930799616511, 2.0514138998772622],
						[61.418930797580984, 2.0514138777589599],
						[61.418930788258727, 2.0514138365763595],
						[61.418930780876607, 2.051413815652289],
						[61.418911299844744, 2.0513721557930138],
						[61.418911300125338, 2.0513333001501159],
						[61.418911298089796, 2.051333278031819],
						[61.418911287781015, 2.0513332351885372],
						[61.418911281290015, 2.0513332140654565],
						[61.41889190009438, 2.051291656129266],
						[61.418891900030729, 2.0512499996577813],
						[61.418891896913344, 2.0512499740190147],
						[61.418891882658677, 2.051249924532855],
						[61.418891874194692, 2.0512499000883335],
						[61.418869599652417, 2.0512110464092368],
						[61.418869600041326, 2.0511693992680597],
						[61.418869598005742, 2.0511693771498223],
						[61.418869587792308, 2.0511693361664074],
						[61.418869581301266, 2.0511693150433894],
						[61.418850199962414, 2.0511277554065872],
						[61.418850199944657, 2.0510861008539196],
						[61.418850196731874, 2.0510860733554321],
						[61.418850184354724, 2.0510860253310903],
						[61.418850174108492, 2.0510860012847467],
						[61.418827979753445, 2.0510471129245897],
						[61.41881128641181, 2.0510055248259964],
						[61.418811248988504, 2.0510054805153692],
						[61.418811155586795, 2.0510054167306824],
						[61.418811099799164, 2.0510054009761545],
						[61.418791816332586, 2.0510054007696414],
						[61.418769574182932, 2.0509666003339193],
						[61.418769537141095, 2.0509665634624534],
						[61.418769449848988, 2.050966513361804],
						[61.418769399598695, 2.0509665001325867],
						[61.418752800249067, 2.0509665004422368],
						[61.418727932301429, 2.0509665005475402],
						[61.418711283787914, 2.0509277204457717],
						[61.418711247351027, 2.0509276777960892],
						[61.418711154935785, 2.0509276156725282],
						[61.418711100039218, 2.050927599719131],
						[61.418691700286324, 2.0509275991975979],
						[61.41866953625059, 2.0509275991620677],
						[61.418652986054333, 2.0508860263796698],
						[61.418652943889356, 2.0508859774853927],
						[61.418652837916859, 2.0508859146283078],
						[61.418652775095779, 2.0508859023262498],
						[61.418630586852665, 2.0508886992244451],
						[61.418611234900517, 2.0508886993326834],
						[61.418594586419538, 2.0508471260271253],
						[61.418594548900785, 2.0508470798572329],
						[61.418594456485479, 2.0508470177341298],
						[61.418594399711395, 2.0508470003192638],
						[61.418572323957825, 2.0508469994269838],
						[61.418552980127409, 2.0508082121881608],
						[61.418533481384017, 2.0507666154399766],
						[61.41853347941106, 2.0507666121185903],
						[61.418533476260883, 2.0507666034169865],
						[61.418533474287948, 2.0507666000955753],
						[61.418511280741527, 2.0507277121503908],
						[61.418494585820092, 2.0506860252779791],
						[61.418494582765305, 2.0506860184361351],
						[61.418494576751044, 2.0506860066122323],
						[61.418494573791641, 2.0506860016301376],
						[61.418472396007296, 2.0506471403616779],
						[61.418469600087285, 2.0506055935659937],
						[61.418469599696166, 2.0505639000797928],
						[61.418469597851249, 2.0505638816813727],
						[61.418469591392778, 2.0505638436219598],
						[61.418469585983537, 2.0505638260197636],
						[61.418453000330125, 2.0505221618992109],
						[61.418453000352621, 2.050480600012615],
						[61.418452999879605, 2.0504416999258819],
						[61.418452997843929, 2.0504416778079704],
						[61.418452987630275, 2.0504416368253682],
						[61.418452981139133, 2.0504416157027854],
						[61.418433499731826, 2.050399955230453],
						[61.418433500294746, 2.0503582991906781],
						[61.418433500142001, 2.0503166997403386],
						[61.418433497024445, 2.050316674102052],
						[61.418433483660557, 2.0503166244179893],
						[61.41843347430531, 2.050316600173149],
						[61.418411295624551, 2.0502777413067048],
						[61.418408499789003, 2.050236093012145],
						[61.418408500176675, 2.0501943994160703],
						[61.418408498236367, 2.050194379157964],
						[61.418408489995606, 2.0501943414968435],
						[61.418408484395499, 2.0501943201752795],
						[61.418391899671654, 2.0501555585676554],
						[61.418391900065046, 2.050113900728638],
						[61.418391900033932, 2.0500721997182865],
						[61.418391895929879, 2.0500721724193687],
						[61.418391881674815, 2.0500721229345511],
						[61.418391871333036, 2.0500720970291577],
						[61.418366878435592, 2.0500305094072582],
						[61.41835018435561, 2.0499916199958932],
						[61.418350182573427, 2.04999162039404],
						[61.418350182382646, 2.0499916166745504],
						[61.418350181396143, 2.049991615013897],
						[61.418330780788565, 2.0499499161931261],
						[61.418330779706679, 2.0499499126727376],
						[61.41833077763831, 2.0499499074916674],
						[61.418330775760744, 2.0499499060300872],
						[61.418308481301644, 2.0499082123269257],
						[61.418291883585283, 2.0498693222099851],
						[61.418291881421524, 2.0498693151691985],
						[61.418291875407235, 2.0498693033455235],
						[61.418291870570187, 2.0498692969019703],
						[61.418266900113373, 2.0498277440506176],
						[61.418266900185252, 2.0497860996025321],
						[61.418266898244873, 2.0497860793445515],
						[61.418266889908608, 2.0497860398239856],
						[61.418266884403863, 2.0497860203623199],
						[61.418250184338646, 2.0497471212439162],
						[61.418250183352164, 2.0497471195832673],
						[61.418250182270285, 2.0497471160628775],
						[61.418250181283788, 2.0497471144022299],
						[61.418230800425754, 2.0497055562804696],
						[61.418230799888526, 2.0496639007247048],
						[61.418230800076664, 2.0496250007316346],
						[61.418230796958994, 2.0496249750935873],
						[61.418230784676737, 2.0496249289305237],
						[61.418230776307858, 2.0496249063466987],
						[61.4182084998927, 2.0495832503422635],
						[61.418208500204905, 2.0495416999589979],
						[61.418208497182597, 2.0495416761806902],
						[61.4182084867779, 2.0495416314792632],
						[61.418208479395503, 2.0495416105561155],
						[61.418189078917933, 2.04950271113746],
						[61.418189077931451, 2.0495027094768221],
						[61.418189076944955, 2.0495027078161865],
						[61.418189076849565, 2.0495027059564612],
						[61.418166877575324, 2.0494610083994087],
						[61.418147382938692, 2.0494221188287307],
						[61.41813079971444, 2.049380562359957],
						[61.418130799588994, 2.0493388993064237],
						[61.418130796375863, 2.0493388718087568],
						[61.418130780052174, 2.0493388171437226],
						[61.418130767928076, 2.0493387916369707],
						[61.418105779130897, 2.0492999070372901],
						[61.418089100241922, 2.0492582610749905],
						[61.418089099884639, 2.0492166996991372],
						[61.418089096862289, 2.0492166759209667],
						[61.418089085470982, 2.0492166295591923],
						[61.41808907700667, 2.0492166051158742],
						[61.418066899638561, 2.0491749500104439],
						[61.418066900387771, 2.0491360989820504],
						[61.418066898447314, 2.049136078724235],
						[61.418066888137893, 2.0491360358828552],
						[61.418066880755433, 2.0491360149599149],
						[61.418047399858132, 2.0490943556314782],
						[61.418047399737681, 2.0490527998936914],
						[61.418047396810707, 2.0490527779752901],
						[61.418047387201561, 2.049052731215431],
						[61.418047378928023, 2.049052710491619],
						[61.418028000306883, 2.0490138536937925],
						[61.418027999656815, 2.0489722002827699],
						[61.418027996634422, 2.0489721765046585],
						[61.418027985147631, 2.0489721282833728],
						[61.418027975887561, 2.0489721058989692],
						[61.418005777950974, 2.0489305082999758],
						[61.417986279128819, 2.0488916107634658],
						[61.417986278142301, 2.0488916091028639],
						[61.417986277155819, 2.0488916074422536],
						[61.417986277060429, 2.0488916055825417],
						[61.417964081554842, 2.048849915377541],
						[61.417947400274926, 2.048808260949265],
						[61.417947400444426, 2.0487694007781547],
						[61.417947398408501, 2.0487693786607446],
						[61.417947388098959, 2.0487693358196686],
						[61.417947380716448, 2.0487693148969006],
						[61.417928000369628, 2.0487277557356709],
						[61.417928000429448, 2.0486861004052734],
						[61.417927997311615, 2.0486860747675606],
						[61.417927983947166, 2.0486860250850265],
						[61.417927973700536, 2.0486860010401888],
						[61.417905800116699, 2.0486471464966129],
						[61.417905800339213, 2.0486056002551707],
						[61.417905798303238, 2.0486055781377921],
						[61.417905787993661, 2.0486055352968249],
						[61.417905780611122, 2.0486055143741249],
						[61.417886299559072, 2.0485638555968251],
						[61.417886299569446, 2.0485222003254644],
						[61.417886296546975, 2.0485221765475261],
						[61.41788628703307, 2.0485221316477835],
						[61.417886278759418, 2.0485221109242291],
						[61.41786689989879, 2.04848325280099],
						[61.417866899690253, 2.0484416991758865],
						[61.417866896667768, 2.0484416753979517],
						[61.417866885276233, 2.0484416290367959],
						[61.417866876016085, 2.0484416066526889],
						[61.417844581023317, 2.0483999127984065],
						[61.417827983690898, 2.048361022017215],
						[61.417827980635977, 2.0483610151757579],
						[61.417827974621559, 2.0483610033525528],
						[61.417827971566624, 2.0483609965110978],
						[61.417802980248254, 2.0483193108620643],
						[61.417786286112566, 2.0482777256118121],
						[61.417786243055652, 2.0482776769215048],
						[61.41778613619099, 2.0482776142740473],
						[61.417786070696501, 2.0482776025748399],
						[61.417766809945618, 2.0482803828497285],
						[61.417744580779548, 2.0482388137188607],
						[61.417727984199708, 2.0481999210890218],
						[61.417727981144772, 2.0481999142476042],
						[61.417727975130362, 2.048199902424467],
						[61.41772797217083, 2.0481998974427404],
						[61.417702971928307, 2.0481581971095375],
						[61.417702934981165, 2.0481581621023524],
						[61.417702848674665, 2.0481581136717089],
						[61.417702800206357, 2.0481581000491573],
						[61.417686224237798, 2.0481581003599403],
						[61.4176668793936, 2.0481193103287376],
						[61.417666878407111, 2.0481193086681682],
						[61.417666877420601, 2.0481193070075849],
						[61.417666875542992, 2.0481193055461437],
						[61.41764458214184, 2.0480777152666159],
						[61.417627986214669, 2.0480360256309069],
						[61.417627982173236, 2.0480360171289624],
						[61.417627973199266, 2.0480360003241986],
						[61.417627968266736, 2.0480359920213793],
						[61.417602979025482, 2.0479971072323511],
						[61.417586300263032, 2.0479555603472797],
						[61.417586299806466, 2.0479138999101369],
						[61.417586300396181, 2.0478750005553903],
						[61.417586298360128, 2.0478749784382981],
						[61.417586288050309, 2.0478749355980268],
						[61.417586280763103, 2.0478749165353949],
						[61.417566900351787, 2.0478332567976341],
						[61.417566899759407, 2.0477916998662655],
						[61.417566896641404, 2.0477916742289688],
						[61.417566883276642, 2.0477916245474024],
						[61.417566873920961, 2.0477916003040075],
						[61.417544600040763, 2.0477527466346057],
						[61.417544600190709, 2.047711099275062],
						[61.417544598154592, 2.04771107715802],
						[61.417544587940156, 2.0477110361775406],
						[61.417544580557525, 2.0477110152552886],
						[61.417525180972852, 2.0476693166877231],
						[61.417525178904427, 2.0476693115069406],
						[61.417525175849491, 2.0476693046656136],
						[61.417525173876442, 2.0476693013445333],
						[61.417502999730218, 2.0476305477848489],
						[61.417502999843514, 2.0475889004833601],
						[61.417502997807368, 2.0475888783663585],
						[61.417502988388591, 2.0475888353271592],
						[61.417502981005903, 2.0475888144049539],
						[61.417483499659099, 2.0475471568058095],
						[61.417483499871871, 2.0475055997922742],
						[61.417483497931144, 2.0475055795349686],
						[61.417483489689701, 2.0475055418757022],
						[61.417483484184608, 2.0475055224149465],
						[61.417466899720516, 2.0474666597310605],
						[61.417466900007348, 2.0474249992642548],
						[61.417466895998153, 2.0474249738262222],
						[61.417466881646732, 2.0474249224844514],
						[61.417466872195583, 2.0474248963815724],
						[61.41744190033814, 2.0473832451769489],
						[61.41744189998137, 2.0473444007395307],
						[61.417441898040622, 2.0473443804822593],
						[61.417441890785639, 2.0473443444836219],
						[61.417441886171616, 2.0473443248237762],
						[61.417425186437761, 2.0473027248433806],
						[61.417425182682528, 2.0473027219206008],
						[61.417425179436719, 2.0473027113599955],
						[61.417425177368266, 2.0473027061792735],
						[61.41740299620011, 2.0472610427705362],
						[61.417400199293205, 2.0472221857057553],
						[61.417400196652203, 2.0472221693670196],
						[61.41740018977886, 2.047222140807158],
						[61.417400186246773, 2.0472221246675431],
						[61.417383485586129, 2.0471805250084376],
						[61.417383484599618, 2.0471805233479152],
						[61.417383481640073, 2.0471805183663374],
						[61.417383480653569, 2.0471805167058159],
						[61.417364099944102, 2.0471388558171317],
						[61.41736409983735, 2.0471000001241477],
						[61.417364096814623, 2.0470999763467441],
						[61.417364085327215, 2.0470999281271833],
						[61.417364076957966, 2.0470999055446852],
						[61.417341878769207, 2.0470582104151314],
						[61.417322399747398, 2.0470166543865722],
						[61.417322399701874, 2.0469778006075221],
						[61.41732239667914, 2.0469777768301789],
						[61.417322385191689, 2.0469777286107282],
						[61.417322376822391, 2.0469777060282812],
						[61.417300200397065, 2.046936049433],
						[61.417300200205872, 2.0468944005568814],
						[61.41730019816962, 2.0468943784400739],
						[61.417300187859546, 2.0468943356005109],
						[61.417300181367843, 2.0468943144794771],
						[61.417280800381398, 2.0468527554992617],
						[61.417280800194305, 2.0468138999155006],
						[61.417280798348912, 2.0468138815180281],
						[61.417280790998355, 2.0468138436600496],
						[61.417280785588588, 2.0468138260592257],
						[61.417264085988272, 2.0467721268261982],
						[61.417264083028705, 2.0467721218446999],
						[61.417264078891755, 2.0467721114833464],
						[61.417264075932181, 2.0467721065018449],
						[61.417241878059677, 2.0467305075800022],
						[61.41722237953249, 2.0466916121846745],
						[61.41720299976258, 2.0466499553388928],
						[61.417203000090304, 2.0466083008203118],
						[61.417202996876647, 2.0466082733237725],
						[61.417202983606913, 2.0466082255029665],
						[61.417202974251033, 2.0466082012602316],
						[61.417180780186172, 2.046569312175325],
						[61.417164086240589, 2.0465277247878664],
						[61.417164082199058, 2.046527716286247],
						[61.417164073224917, 2.0465276994821711],
						[61.417164068292287, 2.0465276911797163],
						[61.417139100157961, 2.0464888409946194],
						[61.417139100214499, 2.0464471997814195],
						[61.417139098369042, 2.0464471813840595],
						[61.417139091018399, 2.0464471435263398],
						[61.417139085608582, 2.0464471259256456],
						[61.417122386408465, 2.0464055247789048],
						[61.417122383544303, 2.0464055216571135],
						[61.417122381380388, 2.0464055146168345],
						[61.417122379407353, 2.0464055112958706],
						[61.417102999878757, 2.0463666535905864],
						[61.41710300012155, 2.0463249992113717],
						[61.417102997098702, 2.0463249754342479],
						[61.417102984815372, 2.0463249292742178],
						[61.417102977337073, 2.0463249064929405],
						[61.417080777841583, 2.0462832087481719],
						[61.417061279002418, 2.0462443101163377],
						[61.417061278015915, 2.0462443084558624],
						[61.417061277029369, 2.0462443067953933],
						[61.417061276042823, 2.0462443051349046],
						[61.417039082059652, 2.046202715362476],
						[61.417022386322692, 2.0461610252858029],
						[61.417022384349643, 2.0461610219648474],
						[61.41702238119916, 2.0461610132641432],
						[61.417022379321551, 2.0461610118028477],
						[61.417002978532771, 2.0461221102067593],
						[61.417002977641658, 2.0461221104059382],
						[61.417002976559708, 2.0461221068858282],
						[61.417002975573162, 2.0461221052253649],
						[61.416980777608828, 2.0460805089756549],
						[61.416961278715277, 2.0460416105952097],
						[61.41696127772876, 2.0460416089347437],
						[61.416961276742214, 2.0460416072742889],
						[61.416961276646788, 2.046041605414648],
						[61.416939080029614, 2.0459999130418005],
						[61.416922383580072, 2.0459610203580936],
						[61.416922382689009, 2.0459610205572631],
						[61.416922381607023, 2.0459610170371572],
						[61.416922380620477, 2.0459610153767041],
						[61.41690298132221, 2.0459193166823417],
						[61.416902980240231, 2.0459193131622571],
						[61.416902978171727, 2.0459193079816989],
						[61.416902976294089, 2.0459193065204233],
						[61.41688077817625, 2.0458777086884532],
						[61.416861279228264, 2.0458388105594603],
						[61.416861278241733, 2.045838808899012],
						[61.416861277255208, 2.0458388072385572],
						[61.416861277159768, 2.0458388053789265],
						[61.41683910040593, 2.045797150212032],
						[61.416839100382035, 2.0457555996718941],
						[61.416839100271709, 2.0457166994307876],
						[61.416839098235279, 2.0457166773143953],
						[61.416839088020261, 2.0457166363355204],
						[61.416839080637331, 2.0457166154142254],
						[61.416819600337533, 2.0456749557659926],
						[61.416819600278259, 2.0456332999198823],
						[61.416819600396281, 2.0455917004610944],
						[61.416819600239343, 2.045552800247405],
						[61.416819598202885, 2.045552778131039],
						[61.416819587892377, 2.0455527352926044],
						[61.416819580604873, 2.0455527162309859],
						[61.41680020021743, 2.0455110550596509],
						[61.416800200108263, 2.0454693992427835],
						[61.416800200176453, 2.0454277998131833],
						[61.416800197058009, 2.0454277741767615],
						[61.416800183692374, 2.0454277244974715],
						[61.416800174336302, 2.0454277002554138],
						[61.416777999779221, 2.0453888475857047],
						[61.416778000014759, 2.0453471992394907],
						[61.41677800004593, 2.0453055998419791],
						[61.416777998009458, 2.0453055777256566],
						[61.416777987794319, 2.0453055367469877],
						[61.416777981302374, 2.0453055156266466],
						[61.416758499990344, 2.0452638565220638],
						[61.41675850015374, 2.0452249995605873],
						[61.416758500435471, 2.0451833004362521],
						[61.416758497412445, 2.0451832766595097],
						[61.416758485033277, 2.045183228640822],
						[61.416758475772625, 2.0451832062584892],
						[61.416736300225665, 2.0451416492012209],
						[61.416736299845219, 2.0451027999074145],
						[61.416736300089816, 2.0450611008152362],
						[61.416736298053316, 2.0450610786989558],
						[61.416736287742658, 2.0450610358608228],
						[61.416736281346154, 2.0450610166001786],
						[61.41671689992291, 2.0450193560517298],
						[61.416716900268064, 2.044977799912076],
						[61.416716898231556, 2.0449777777958169],
						[61.416716888016325, 2.0449777368173603],
						[61.416716880633253, 2.0449777158963167],
						[61.416697400115929, 2.0449360568832478],
						[61.416697400186614, 2.0448972000041095],
						[61.416697397163524, 2.0448971762274653],
						[61.416697384784271, 2.0448971282089885],
						[61.416697376414668, 2.0448971056275771],
						[61.416675199988703, 2.0448555509171169],
						[61.416675199967159, 2.0448139008593889],
						[61.41667519694407, 2.0448138770827669],
						[61.416675187428972, 2.0448138321859606],
						[61.41667517915478, 2.0448138114642118],
						[61.416655799989016, 2.04477495325935],
						[61.416655799752228, 2.0447332995099434],
						[61.416655796729124, 2.0447332757333276],
						[61.41665578524087, 2.0447332275157781],
						[61.416655775980139, 2.0447332051336553],
						[61.416633500341455, 2.0446916501945807],
						[61.416633499738637, 2.0446527991777548],
						[61.416633497797534, 2.0446527789211926],
						[61.416633488377826, 2.0446527358841324],
						[61.416633481090194, 2.0446527168228461],
						[61.416614100438473, 2.0446110564706137],
						[61.416614100056755, 2.0445694009206172],
						[61.416614098115637, 2.0445693806640737],
						[61.416614090859937, 2.0445693446670909],
						[61.416614086245502, 2.0445693250082067],
						[61.416597385603964, 2.044527725787237],
						[61.416597382548886, 2.044527718946421],
						[61.416597377425212, 2.0445277069252006],
						[61.416597374370099, 2.0445277000844144],
						[61.416575200364207, 2.0444888462566446],
						[61.416575200423075, 2.0444472000591842],
						[61.416575200232153, 2.0444471963399495],
						[61.416575199945775, 2.0444471907611246],
						[61.416575199754853, 2.0444471870419085],
						[61.416572400296623, 2.0444055930366876],
						[61.416572399725617, 2.0443638999559237],
						[61.416572400112422, 2.0443249998746866],
						[61.416572400025906, 2.0442832991578728],
						[61.416572399735117, 2.0442417000561961],
						[61.416572400418325, 2.0442000499489938],
						[61.416594576733516, 2.0441583944305468],
						[61.416594585485335, 2.0441583717833365],
						[61.416594596560458, 2.0441583241642594],
						[61.416594599870322, 2.0441583008527933],
						[61.416594600336374, 2.0441166996651594],
						[61.416594599760849, 2.0440777997600663],
						[61.416594599693347, 2.044036100878134],
						[61.416594600393509, 2.0440360969596876],
						[61.416594600011642, 2.0440360895212399],
						[61.416594599916174, 2.0440360876616324],
						[61.416591899783924, 2.0439943943209835],
						[61.41659189969058, 2.0439528007779182],
						[61.416591900380354, 2.0439110998432741],
						[61.416591899745917, 2.0438721999454228],
						[61.416591900219807, 2.0438306006267335],
						[61.416591899980936, 2.043788899893785],
						[61.416591899729291, 2.0437471991616682],
						[61.416591900164839, 2.0437055998454965],
						[61.416591899887578, 2.0436638991150753],
						[61.416591900168854, 2.0436250008822312],
						[61.416591899977909, 2.0436249971630027],
						[61.416591899596014, 2.0436249897245649],
						[61.416591900391651, 2.0436249876657122],
						[61.416589099982069, 2.0435832923136106],
						[61.41658910027197, 2.0435417438116197],
						[61.416608481234846, 2.0435000845651921],
						[61.416608490814028, 2.0435000429231946],
						[61.416608490973083, 2.0434999582445545],
						[61.416608480661957, 2.0434999154071227],
						[61.41658909966764, 2.0434582558274825],
						[61.416589100428425, 2.04341669969032],
						[61.41658909965232, 2.0433777998052576],
						[61.416589100260978, 2.043336100745536],
						[61.416589099870237, 2.0432944000262863],
						[61.416589096751402, 2.0432943743902743],
						[61.416589083385013, 2.0432943247122828],
						[61.416589074028572, 2.0432943004710484],
						[61.416566896341536, 2.0432555404680492],
						[61.416564100084038, 2.0432138931411399],
						[61.416564100038158, 2.0431721998962353],
						[61.416564100298011, 2.0431306006287118],
						[61.416564099749301, 2.0430889507541736],
						[61.416586276602821, 2.043047294279519],
						[61.416586285354462, 2.0430472716320094],
						[61.416586297320258, 2.0430472238133408],
						[61.416586299738867, 2.0430472007010283],
						[61.416586299747998, 2.0430083006271298],
						[61.416586299861656, 2.0429666994736602],
						[61.416586300344022, 2.04292500042596],
						[61.416586299827031, 2.0428832997187345],
						[61.41658629999786, 2.0428417004273745],
						[61.416586299806902, 2.0428416967081695],
						[61.41658630031602, 2.0428416890704875],
						[61.416586300220551, 2.0428416872108799],
						[61.416583499666977, 2.0427999938119985],
						[61.416583500305741, 2.0427611424945025],
						[61.416602982875268, 2.0427167796429564],
						[61.416602989144586, 2.0427167613123496],
						[61.416602997927889, 2.0427167217290298],
						[61.416603000441917, 2.0427167004762889],
						[61.416603000355991, 2.0426778003877475],
						[61.416603000165018, 2.0426777966685421],
						[61.416602999783088, 2.0426777892301153],
						[61.416602999687591, 2.0426777873705109],
						[61.416600199974638, 2.0426360937681434],
						[61.416600199750526, 2.0425944004865668],
						[61.41660019973704, 2.0425527993228481],
						[61.416600200091928, 2.042511100264857],
						[61.416600199947148, 2.0424722001836861],
						[61.416600198101179, 2.0424721817869078],
						[61.416600190749413, 2.0424721439308708],
						[61.416600186134723, 2.0424721242723156],
						[61.416583498074871, 2.042430555258246],
						[61.416580799966077, 2.0423888935178076],
						[61.416580799666406, 2.0423472002671672],
						[61.416580797438449, 2.0423471744320132],
						[61.416580783180656, 2.0423471249537215],
						[61.416580773824052, 2.0423471007127882],
						[61.416558499957027, 2.0423082462434721],
						[61.416558499952622, 2.0422666996674592],
						[61.41655850003, 2.0422250496185046],
						[61.416580775869633, 2.0421833948078851],
						[61.416580784525642, 2.0421833703005468],
						[61.416580796586643, 2.0421833243411673],
						[61.416580799800663, 2.0421832991699085],
						[61.416580799884727, 2.0421417374905673],
						[61.416597386155061, 2.0421000740895607],
						[61.416597390642103, 2.0421000561574361],
						[61.416597397738563, 2.0421000188321115],
						[61.416597400347968, 2.0420999994389488],
						[61.416597400117595, 2.042061135100024],
						[61.41661408740606, 2.0420167705485137],
						[61.416614091893138, 2.0420167526163429],
						[61.416614098193968, 2.0420167173498895],
						[61.416614100007749, 2.0420167000155875],
						[61.416614099626109, 2.0419778507335189],
						[61.416636277016643, 2.0419361932219795],
						[61.416636284877029, 2.0419361707734218],
						[61.416636296937945, 2.041936124813867],
						[61.4166363001519, 2.0419360996425668],
						[61.416636299935242, 2.0418944457770483],
						[61.416655781038038, 2.0418528852234772],
						[61.416655788802878, 2.0418528609152706],
						[61.416655797999532, 2.0418528118343331],
						[61.416655800322388, 2.0418527868622771],
						[61.416653002934439, 2.0418111434896717],
						[61.416675177088898, 2.0417694940446145],
						[61.416675184949234, 2.0417694715959951],
						[61.416675196914596, 2.0417694237767003],
						[61.416675200224034, 2.0417694004649474],
						[61.416675199574847, 2.0417278444461044],
						[61.416694580853253, 2.0416861837900271],
						[61.416694587917988, 2.0416861634002719],
						[61.416694598387636, 2.0416861215583495],
						[61.416694600010409, 2.0416861005047706],
						[61.416694600224929, 2.0416444389286359],
						[61.416711286107301, 2.0416028750648021],
						[61.416711291389866, 2.0416028550736165],
						[61.416711297690561, 2.0416028198069647],
						[61.416711300299916, 2.0416028004136892],
						[61.41671130026517, 2.0415610993665321],
						[61.416711299751242, 2.041522249971834],
						[61.416733475914555, 2.0414806938247203],
						[61.416733484665926, 2.0414806711766791],
						[61.416733496631203, 2.0414806233571881],
						[61.416733499940577, 2.0414806000453671],
						[61.416733500091233, 2.0414389384213525],
						[61.416750186193013, 2.0413972746431974],
						[61.41675019067997, 2.0413972567108667],
						[61.416750197776246, 2.0413972193852254],
						[61.416750200385522, 2.0413971999919038],
						[61.416750199907369, 2.0413556494593501],
						[61.416772377192927, 2.0413139931887878],
						[61.416772385053207, 2.041313970739977],
						[61.416772397113881, 2.0413139247799976],
						[61.416772400327744, 2.0413138996085207],
						[61.416772400298498, 2.0412722003450163],
						[61.416772399971727, 2.0412306008375669],
						[61.416772399685208, 2.0412305952587451],
						[61.416772400289794, 2.0412305894805924],
						[61.41677239920768, 2.0412305859606925],
						[61.416769599575019, 2.0411916929416098],
						[61.416769599794783, 2.041149999263137],
						[61.416769599715266, 2.0411083000066879],
						[61.416769600229387, 2.0410667003069562],
						[61.416769600028729, 2.0410249991925711],
						[61.416769600134316, 2.0409833393890544],
						[61.416786285807298, 2.040941775081806],
						[61.416786291089778, 2.0409417550904605],
						[61.416786298281437, 2.0409417196242869],
						[61.416786299999558, 2.0409417004302304],
						[61.4167863002925, 2.0409027999468865],
						[61.416786300136643, 2.0408611006730761],
						[61.416786299872491, 2.0408193995404753],
						[61.41678630029773, 2.0407777998242431],
						[61.416786300103482, 2.040736100552929],
						[61.416786299670029, 2.0406972397232281],
						[61.4168029861438, 2.0406556750463123],
						[61.416802990535139, 2.0406556552542603],
						[61.416802997726677, 2.040655619787997],
						[61.416803000335889, 2.0406556003945964],
						[61.416803000008244, 2.040613899243791],
						[61.416802999763291, 2.040572199953433],
						[61.416803000112438, 2.0405306002198618],
						[61.416802999841877, 2.0404889009311993],
						[61.416803000354101, 2.0404471995844142],
						[61.416803000163064, 2.040447195865188],
						[61.416802999780998, 2.0404471884267652],
						[61.416802998794417, 2.0404471867664737],
						[61.416800204189883, 2.040408343405911],
						[61.416822375924959, 2.0403667948008066],
						[61.41682238458062, 2.0403667702927937],
						[61.416822396641017, 2.040366724332384],
						[61.416822399854667, 2.0403666991607698],
						[61.416822400043664, 2.040325044878736],
						[61.416841878544467, 2.040283389541083],
						[61.416864075761119, 2.0402417937010515],
						[61.416864085403326, 2.0402417708532443],
						[61.416864097368141, 2.0402417230331227],
						[61.416864099786224, 2.0402416999204225],
						[61.416864099649459, 2.0402000400063183],
						[61.416880786153733, 2.0401583733514852],
						[61.416897385924713, 2.0401167730770471],
						[61.416897391398095, 2.0401167568047218],
						[61.416897397602924, 2.0401167196779784],
						[61.416897400212036, 2.0401167002844676],
						[61.416897399622727, 2.0400750498257691],
						[61.416919576593877, 2.0400333943255125],
						[61.416919578980554, 2.0400333881486215],
						[61.416919583053868, 2.0400333797134227],
						[61.416919586427134, 2.0400333751968152],
						[61.416936286213925, 2.0399917749928691],
						[61.4169362906052, 2.0399917552006199],
						[61.41693629779656, 2.0399917197340782],
						[61.416936300405645, 2.0399917003405355],
						[61.416936299777191, 2.0399500498323579],
						[61.416958476707848, 2.0399083941918228],
						[61.416958485458956, 2.03990837154319],
						[61.41695849742365, 2.0399083237227997],
						[61.416958499841662, 2.039908300610012],
						[61.416958500399048, 2.0398667438520399],
						[61.416977981329758, 2.0398250855919118],
						[61.416977989094242, 2.0398250612829809],
						[61.416977998194618, 2.0398250103413456],
						[61.416977998734943, 2.0398249857676287],
						[61.416975199588563, 2.0397860924464597],
						[61.416975199882827, 2.0397444435507022],
						[61.416994581326051, 2.0397028845632565],
						[61.416994588390473, 2.0397028641728716],
						[61.416994597968447, 2.0397028225292617],
						[61.416994600386431, 2.0397027994164327],
						[61.416994599857652, 2.0396610998880398],
						[61.416994599826587, 2.0396194453899867],
						[61.417014081190345, 2.0395778846274317],
						[61.417014088254774, 2.0395778642370015],
						[61.417014097832698, 2.0395778225933334],
						[61.417014100250682, 2.0395777994804765],
						[61.417014099683108, 2.0395360999284895],
						[61.417014100408835, 2.0394944433479201],
						[61.417033480881273, 2.0394528843558843],
						[61.417033487945666, 2.0394528639654359],
						[61.417033498414604, 2.0394528221223447],
						[61.417033499941489, 2.0394527992088265],
						[61.417033499898508, 2.0394139003044676],
						[61.417033499707451, 2.0394138965852182],
						[61.417033500311931, 2.0394138908070074],
						[61.417033500120858, 2.0394138870877629],
						[61.417030802927243, 2.0393722434669863],
						[61.417052976111385, 2.0393306934284476],
						[61.417052984862373, 2.0393306707795888],
						[61.417052996922365, 2.0393306248184504],
						[61.417053000135844, 2.0393305996465618],
						[61.41705299990663, 2.0392889432174894],
						[61.417072380596643, 2.0392472842921903],
						[61.417072387661001, 2.0392472639016712],
						[61.417072398129882, 2.0392472220584614],
						[61.417072399656725, 2.039247199144909],
						[61.417072399818316, 2.0392056385290398],
						[61.417089086196022, 2.0391639747950872],
						[61.417089090682666, 2.0391639568622768],
						[61.417089097778302, 2.0391639195357403],
						[61.417089100387273, 2.0391639001420354],
						[61.417089100392758, 2.039122249246927],
						[61.417111275978584, 2.0390806946376743],
						[61.417111284729536, 2.0390806719887036],
						[61.417111296693932, 2.0390806241677764],
						[61.41711130000288, 2.0390806008554452],
						[61.41711130030022, 2.0390389385752123],
						[61.417127985733508, 2.0389972749013299],
						[61.417127991111229, 2.038997256769091],
						[61.417127998206787, 2.0389972194424764],
						[61.417127999924674, 2.0389972002481298],
						[61.417128000260057, 2.0389583462640419],
						[61.417150178688061, 2.0389139881497429],
						[61.417150186643468, 2.0389139675596941],
						[61.417150197016738, 2.0389139238566454],
						[61.417150200325629, 2.038913900544272],
						[61.417150200254618, 2.0388750390955641],
						[61.41716688553992, 2.0388333734242283],
						[61.417166890917628, 2.0388333552919198],
						[61.417166898108682, 2.0388333198248643],
						[61.417166899826533, 2.038833300630468],
						[61.41716689976392, 2.0387917380372564],
						[61.417183481975663, 2.0387500840716628],
						[61.417205775759776, 2.0387083935016688],
						[61.417205778337475, 2.0387083910438633],
						[61.41720577992853, 2.0387083869258462],
						[61.417205780724053, 2.0387083848668404],
						[61.417225181406046, 2.0386667848205851],
						[61.417225182201591, 2.0386667827615734],
						[61.417225184588176, 2.0386667765845221],
						[61.417225186274784, 2.0386667743261215],
						[61.417241842058139, 2.0386251846281831],
						[61.417263925473065, 2.038622398812572],
						[61.417263971331288, 2.0386223791461751],
						[61.417264049617245, 2.0386223240085051],
						[61.417264080962845, 2.038622285017389],
						[61.417283479134255, 2.0385806891564697],
						[61.417305776235615, 2.0385389938133769],
						[61.417305778717761, 2.0385389894959243],
						[61.4173057835865, 2.0385389790014297],
						[61.41730578606866, 2.0385389746839753],
						[61.417322385604066, 2.038497273240242],
						[61.417322392508403, 2.0384972321940302],
						[61.417322389195597, 2.0384971501708216],
						[61.417322379073966, 2.0384971110534624],
						[61.417302978732465, 2.0384582115532424],
						[61.417302977745855, 2.0384582098930082],
						[61.417302976663677, 2.0384582063731345],
						[61.417302975772628, 2.0384582065725247],
						[61.417280775949067, 2.0384166060527669],
						[61.417280739699592, 2.0384165671390697],
						[61.417280651321228, 2.0384165135561356],
						[61.417280600178955, 2.0384165005471071],
						[61.417264008662272, 2.0384165001170707],
						[61.417239068027378, 2.0383776911950604],
						[61.417239032064536, 2.0383776578603441],
						[61.417238947728215, 2.0383776127781261],
						[61.417238900245735, 2.0383776008312382],
						[61.417222199667172, 2.0383776003722827],
						[61.417205600035544, 2.0383775998958651],
						[61.417205593702462, 2.038377599432021],
						[61.417205583009554, 2.038377601824779],
						[61.417205577567557, 2.038377601161534],
						[61.417180728716339, 2.0383803833541281],
						[61.417164085920703, 2.0383388261461568],
						[61.417164084838525, 2.0383388226263213],
						[61.417164081878653, 2.038338817645641],
						[61.41716408079651, 2.038338814125797],
						[61.41714458075819, 2.0382971157166958],
						[61.417144578784949, 2.0382971123962572],
						[61.417144575633984, 2.0382971036963493],
						[61.417144573660742, 2.0382971003759089],
						[61.417122380677704, 2.0382582129204296],
						[61.417105785900418, 2.0382166262927579],
						[61.417105749268721, 2.0382165799410421],
						[61.417105655861654, 2.0382165161982355],
						[61.417105600072887, 2.0382165004673647],
						[61.417083424014848, 2.0382165008340007],
						[61.417064079806124, 2.0381777133705059],
						[61.417044580612838, 2.0381360149957906],
						[61.417044578639597, 2.0381360116753702],
						[61.41704457647527, 2.038136004635712],
						[61.417044573610937, 2.0381360015146979],
						[61.417022381380797, 2.0380971122381029],
						[61.417005785666277, 2.0380555260288635],
						[61.41700578171978, 2.038055519388029],
						[61.417005774622311, 2.0380555040473474],
						[61.417005771566927, 2.0380554972071181],
						[61.416980778432276, 2.03801380953167],
						[61.416964099681323, 2.0379749581501061],
						[61.416964099833301, 2.0379332998081607],
						[61.416964100261339, 2.0378916997131284],
						[61.416964100067773, 2.0378500000626412],
						[61.416964099876672, 2.0378499963434291],
						[61.416964099590011, 2.0378499907645971],
						[61.416964100289988, 2.0378499868459778],
						[61.416961299984131, 2.0378082930942463],
						[61.416961299757801, 2.0377693998897577],
						[61.416961300135654, 2.0377277998017536],
						[61.416961299891781, 2.0376861001583157],
						[61.416961299635084, 2.0376444005157213],
						[61.416961299974602, 2.0376028004302307],
						[61.416961300198217, 2.0375638995917571],
						[61.416961298160516, 2.0375638774758862],
						[61.41696128794306, 2.0375638364996917],
						[61.416961280558866, 2.0375638155803371],
						[61.416941900273187, 2.0375221550376907],
						[61.416941900195788, 2.0374806002093502],
						[61.416941899876342, 2.0374389005968583],
						[61.416941900053338, 2.0373999997856842],
						[61.416941897028948, 2.0373999760096497],
						[61.416941884742641, 2.0373999298535721],
						[61.416941876276212, 2.0373999054144751],
						[61.416919600242871, 2.0373582497639182],
						[61.41691960040238, 2.0373167005474135],
						[61.416919599937458, 2.0372749991084662],
						[61.416919597008587, 2.0372749771920833],
						[61.416919586599953, 2.0372749324968389],
						[61.416919579215723, 2.0372749115775837],
						[61.416900200439642, 2.0372360535907719],
						[61.416900199666138, 2.0371943992677481],
						[61.416900196641677, 2.0371943754917754],
						[61.416900185246384, 2.0371943291364119],
						[61.416900175984445, 2.0371943067564251],
						[61.41687799956955, 2.0371527493852155],
						[61.416877999948632, 2.0371111004729201],
						[61.416877996924207, 2.037111076696978],
						[61.416877987406586, 2.0371110318024046],
						[61.416877979035725, 2.0371110092230418],
						[61.416858500254705, 2.0370721530726481],
						[61.416858500136769, 2.0370306002241576],
						[61.416858497112315, 2.0370305764482426],
						[61.416858486703582, 2.0370305317531598],
						[61.416858479319309, 2.0370305108340014],
						[61.416839079375045, 2.0369916107756469],
						[61.416839078388399, 2.0369916091154856],
						[61.41683907740174, 2.0369916074553127],
						[61.416839077306172, 2.0369916055957193],
						[61.416816900318608, 2.0369499507553277],
						[61.416816899650058, 2.0369083002686144],
						[61.416816896625605, 2.0369082764927251],
						[61.416816887107906, 2.0369082315983258],
						[61.416816878832549, 2.0369082108786607],
						[61.41679739990095, 2.0368693530729369],
						[61.4167973997221, 2.03682780030999],
						[61.416797396697611, 2.0368277765341469],
						[61.416797385206621, 2.0368277283194702],
						[61.416797376835682, 2.0368277057402517],
						[61.416775179780892, 2.0367860122917283],
						[61.416758499609848, 2.036747158319812],
						[61.416758499998757, 2.0367055998329859],
						[61.416758497960899, 2.0367055777173282],
						[61.416758487743188, 2.036705536741696],
						[61.416758481249971, 2.0367055156232392],
						[61.416739099821662, 2.0366638560940276],
						[61.416739099671268, 2.0366221999388636],
						[61.41673909744226, 2.0366221741040342],
						[61.416739084073463, 2.0366221244288329],
						[61.416739073824807, 2.0366221003890046],
						[61.416716878222608, 2.0365832076056543],
						[61.416697381239786, 2.0365416154464993],
						[61.416697380253126, 2.0365416137863646],
						[61.416697378184288, 2.0365416086064796],
						[61.416697377102061, 2.0365416050867595],
						[61.416675199983921, 2.0364999507584982],
						[61.416675199708351, 2.0364610992454977],
						[61.416675197766025, 2.0364610789894995],
						[61.416675188343746, 2.0364610359550146],
						[61.416675181054998, 2.0364610168956925],
						[61.416655784182488, 2.0364193215719131],
						[61.416639086454154, 2.0363777256321787],
						[61.41663908339865, 2.0363777187921892],
						[61.416639077383238, 2.0363777069718014],
						[61.416639074327719, 2.0363777001318129],
						[61.416616895719528, 2.0363388414483059],
						[61.416614099638664, 2.036297186778214],
						[61.416614096996376, 2.0362971704408781],
						[61.416614090025199, 2.0362971400247059],
						[61.416614085696331, 2.036297125945866],
						[61.416597385863497, 2.0362555249484227],
						[61.416597382807971, 2.0362555181084581],
						[61.416597376792538, 2.0362555062881103],
						[61.416597373832602, 2.0362555013077399],
						[61.416575178036219, 2.0362166071030838],
						[61.416555799617079, 2.036174956824941],
						[61.416555800211349, 2.036133300725198],
						[61.416555797186746, 2.0361332769496125],
						[61.416555786777728, 2.0361332322552737],
						[61.416555779297767, 2.0361332094769602],
						[61.416536279054363, 2.0360943101843594],
						[61.416536278067738, 2.036094308524218],
						[61.416536277081072, 2.036094306864106],
						[61.416536276094433, 2.0360943052039868],
						[61.416514080424015, 2.0360527138446995],
						[61.416497399958068, 2.0360138587359318],
						[61.416497400312956, 2.0359721989983504],
						[61.416497398370517, 2.035972178742512],
						[61.416497388057032, 2.0359721359078975],
						[61.416497380672624, 2.0359721149892382],
						[61.416477981308262, 2.0359305147845554],
						[61.416477979334985, 2.0359305114643198],
						[61.416477976279438, 2.0359305046244311],
						[61.41647797430614, 2.0359305013041951],
						[61.416455778422232, 2.0358916074806297],
						[61.416436299757358, 2.0358499555317495],
						[61.416436300254269, 2.0358082995983113],
						[61.41643629713402, 2.0358082739632684],
						[61.41643628376498, 2.0358082242889042],
						[61.416436273516148, 2.0358082002495697],
						[61.416414077694832, 2.0357693084250847],
						[61.416394600400253, 2.0357277541044465],
						[61.416394600065033, 2.0356861002882183],
						[61.416394598122565, 2.0356860800324621],
						[61.416394589782286, 2.0356860405182866],
						[61.416394584275565, 2.0356860210603895],
						[61.416377999818934, 2.0356471598331307],
						[61.416377999783037, 2.035605600016301],
						[61.416377999591873, 2.0356055962971791],
						[61.416378000196175, 2.0356055905190145],
						[61.416378000005011, 2.0356055867998912],
						[61.416375199915493, 2.0355638938763891],
						[61.416375200196313, 2.0355222004401603],
						[61.416375199801493, 2.0354805993202283],
						[61.416375200318875, 2.0354416990538469],
						[61.416375200275397, 2.035400000041399],
						[61.416375200123554, 2.0353582991702059],
						[61.416375199774073, 2.0353166999131287],
						[61.416375199692183, 2.0352750009031806],
						[61.416375200150625, 2.0352361006406827],
						[61.416375199948384, 2.0351943997727999],
						[61.416375199852801, 2.0351943979132376],
						[61.416375200361536, 2.0351943902755014],
						[61.416375200170357, 2.0351943865563826],
						[61.416372399674835, 2.0351527934355516],
						[61.416372399829413, 2.0351111000113469],
						[61.416372399684427, 2.0350694010092831],
						[61.416372400041951, 2.0350277997027066],
						[61.416372399538517, 2.0349888996478347],
						[61.416372400247035, 2.034947200448753],
						[61.416372399675993, 2.0349055993441274],
						[61.416372400358938, 2.0348639001467181],
						[61.41637240004237, 2.0348221992901028],
						[61.416372400371074, 2.0347832990395864],
						[61.416372400275478, 2.0347832971800286],
						[61.416372399893099, 2.0347832897418003],
						[61.416372399797517, 2.034783287882223],
						[61.416369599685289, 2.0347416871725876],
						[61.416369595864978, 2.0347416654563384],
						[61.416369583960169, 2.0347416267404328],
						[61.416369576575562, 2.0347416058221639],
						[61.416347399630375, 2.0346999493155296],
						[61.416347400062868, 2.0346582991027415],
						[61.416347400440642, 2.0346194007460885],
						[61.416347398402415, 2.0346193786308864],
						[61.416347388088511, 2.0346193357969544],
						[61.416347380703925, 2.0346193148787024],
						[61.41632798094043, 2.0345777157375724],
						[61.41632797985816, 2.0345777122179669],
						[61.416327977884833, 2.0345777088978516],
						[61.416327976802592, 2.0345777053782195],
						[61.416305779885164, 2.0345360121249461],
						[61.416289083531836, 2.0344971201912863],
						[61.416289082640787, 2.0344971203907809],
						[61.41628908155851, 2.0344971168711812],
						[61.416289080571836, 2.0344971152111304],
						[61.41626959984648, 2.0344555550331092],
						[61.416269599918756, 2.0344138993505556],
						[61.416269596798266, 2.0344138737158186],
						[61.416269584415382, 2.0344138257023974],
						[61.416269574166314, 2.0344138016636624],
						[61.41624739966997, 2.0343749475800026],
						[61.416247400100332, 2.0343332993669572],
						[61.416247398062069, 2.0343332772518483],
						[61.416247387843697, 2.0343332362776967],
						[61.416247381350097, 2.0343332151601023],
						[61.41622799974153, 2.0342916555108284],
						[61.416228000433129, 2.0342528004787779],
						[61.416227999931699, 2.0342110998277132],
						[61.416227996906763, 2.0342110760525864],
						[61.416227984619404, 2.0342110298988572],
						[61.416227977043526, 2.0342110052616977],
						[61.416205799943853, 2.0341693493260906],
						[61.416205800032451, 2.034127800925893],
						[61.416205797007493, 2.0341277771507871],
						[61.416205786597843, 2.0341277324576725],
						[61.416205779117547, 2.0341277096801007],
						[61.416186300222421, 2.0340888523427254],
						[61.416186300375038, 2.0340472004980099],
						[61.416186270518764, 2.0340471281828671],
						[61.416186170742549, 2.0340470282610204],
						[61.416186099931529, 2.0340470008537967],
						[61.416169509244604, 2.034047000653088],
						[61.416144568132168, 2.0340081926975273],
						[61.416144532072742, 2.0340081575094731],
						[61.416144447735157, 2.034008112440568],
						[61.416144400252399, 2.0340081005006736],
						[61.416127920409856, 2.034008099953307],
						[61.416105775915184, 2.0339666062671617],
						[61.416105739664545, 2.0339665673601202],
						[61.416105651284624, 2.033966513791726],
						[61.416105600142046, 2.0339665007903922],
						[61.416086232433678, 2.033966499820663],
						[61.416069584115746, 2.0339277218216227],
						[61.416069581060128, 2.0339277149820028],
						[61.416069575044503, 2.0339277031623126],
						[61.416069571988892, 2.0339276963226895],
						[61.416044572338272, 2.0338859975062782],
						[61.416044535387755, 2.0338859625179677],
						[61.416044448981161, 2.0338859122699557],
						[61.416044399716313, 2.0338859007292771],
						[61.416027916214802, 2.0338858991815987],
						[61.416005774137481, 2.0338471005542136],
						[61.416005737091346, 2.0338470637064772],
						[61.416005649793654, 2.0338470136581299],
						[61.416005600433202, 2.0338470002580116],
						[61.415986231834829, 2.0338469995579778],
						[61.415969584376569, 2.0338082215464577],
						[61.415969582307625, 2.0338082163668956],
						[61.415969578360929, 2.0338082097268262],
						[61.415969576387596, 2.0338082064067979],
						[61.415947378778455, 2.0337666102131835],
						[61.415927981021859, 2.0337249157055619],
						[61.415927939042326, 2.0337248705579807],
						[61.415927836022867, 2.033724812744711],
						[61.415927775078515, 2.033724801938555],
						[61.415905719340913, 2.0337275829923516],
						[61.415886281165768, 2.0336860142295383],
						[61.415886243928355, 2.0336859736630677],
						[61.415886154466079, 2.0336859165762631],
						[61.415886100459055, 2.0336859004549881],
						[61.415869399821823, 2.0336859006035217],
						[61.415847200165537, 2.0336859000266032],
						[61.415847192941399, 2.0336858997632294],
						[61.415847178588713, 2.0336859010960082],
						[61.4158471714602, 2.0336859026921563],
						[61.415827786325998, 2.0336887008529305],
						[61.415811100041417, 2.0336886996651478],
						[61.415788900385003, 2.0336886990835197],
						[61.415769515653139, 2.03368870020486],
						[61.415747374417236, 2.0336499018314749],
						[61.415747337275469, 2.0336498631247246],
						[61.415747249977706, 2.0336498130773046],
						[61.415747199726148, 2.033649799877105],
						[61.415730600035886, 2.0336498000082179],
						[61.415711099909323, 2.0336498006565269],
						[61.415688900253258, 2.0336498000979315],
						[61.41568889213805, 2.0336498000340875],
						[61.41568887511221, 2.0336498019654221],
						[61.415688867092605, 2.0336498037610919],
						[61.415672183175161, 2.0336526008715201],
						[61.415649999749455, 2.0336526004368065],
						[61.415630599582869, 2.0336525993882071],
						[61.415613899836821, 2.033652599346087],
						[61.415613893599357, 2.033652600742712],
						[61.415613881028811, 2.0336526016764531],
						[61.41561387469573, 2.0336526012135727],
						[61.415591687399321, 2.0336554004477811],
						[61.415572199843112, 2.0336554001531648],
						[61.415550000186883, 2.0336553995840898],
						[61.415533299549708, 2.0336553997371642],
						[61.41551390036966, 2.033655400342036],
						[61.415513894036579, 2.0336553998791529],
						[61.415513882357111, 2.0336554006133705],
						[61.415513876024029, 2.0336554001504892],
						[61.415491688115409, 2.0336580998177762],
						[61.415475000144312, 2.0336581008931267],
						[61.415452799596871, 2.0336581005171102],
						[61.415433300265484, 2.0336580990897981],
						[61.415416808745796, 2.0336580994370919],
						[61.415391868431271, 2.0336192924984751],
						[61.41539183237176, 2.0336192573116705],
						[61.415391748034033, 2.0336192122447883],
						[61.415391699660226, 2.0336192005052136],
						[61.415375119764626, 2.0336191999698063],
						[61.415352975965085, 2.0335777054761612],
						[61.415352933285682, 2.0335776642480812],
						[61.41535283055299, 2.0335776120147271],
						[61.415352772377439, 2.0335776024699244],
						[61.415333427660855, 2.0335803802985959],
						[61.415316886103092, 2.0335388258589377],
						[61.415316848578854, 2.0335387797148483],
						[61.415316756156521, 2.033538717649491],
						[61.415316700271717, 2.0335387000682545],
						[61.415294524071065, 2.0335386999613694],
						[61.415275179205516, 2.0334999118653863],
						[61.415275141872456, 2.033499869440424],
						[61.415275053396819, 2.0334998140151228],
						[61.41527500037644, 2.0334997995543906],
						[61.415252935020213, 2.0334997991057611],
						[61.415236286095165, 2.0334582255901781],
						[61.41523624303327, 2.0334581769244742],
						[61.415236136162626, 2.0334581143328374],
						[61.415236070667319, 2.0334581026654734],
						[61.415216809874913, 2.0334608824519895],
						[61.415194576217438, 2.0334193068197348],
						[61.415194539966663, 2.0334192679143919],
						[61.415194450695523, 2.0334192145484735],
						[61.415194400348348, 2.0334191994893249],
						[61.415175134577808, 2.0334192004500729],
						[61.415158486146659, 2.0333777247709821],
						[61.41515848318663, 2.0333777197911114],
						[61.415158477075352, 2.0333777061123648],
						[61.415158474115309, 2.0333777011324954],
						[61.415136278346253, 2.0333388087015218],
						[61.415116881369023, 2.0332971153214401],
						[61.415116879395669, 2.033297112001534],
						[61.415116876244404, 2.0332971033027043],
						[61.415116874366667, 2.0332971018422565],
						[61.415094577533807, 2.033258207693752],
						[61.41507518311888, 2.0332166172893826],
						[61.415058484255844, 2.0331777205412438],
						[61.41505848228249, 2.0331777172213314],
						[61.415058478335766, 2.0331777105815276],
						[61.415058477157849, 2.0331777052026396],
						[61.415036278767488, 2.033136011094653],
						[61.415016880745128, 2.033094316314644],
						[61.415016878676148, 2.0330943111352742],
						[61.415016874729425, 2.0330943044955072],
						[61.415016872660459, 2.0330942993161369],
						[61.414994578359071, 2.0330555083062136],
						[61.414975183273569, 2.0330138186118627],
						[61.414958484260197, 2.0329749202380416],
						[61.414958482286842, 2.0329749169181714],
						[61.414958478340111, 2.0329749102784138],
						[61.414958477257798, 2.0329749067590179],
						[61.414936278713633, 2.0329332110692224],
						[61.414916881149566, 2.0328916142392139],
						[61.414916839169805, 2.0328915690941662],
						[61.414916737136721, 2.0328915129452629],
						[61.414916676096709, 2.0328915002815018],
						[61.414894388154522, 2.0328942002467132],
						[61.414874999769545, 2.0328942007644208],
						[61.414858299933485, 2.0328941992427092],
						[61.414836223794971, 2.0328941997384606],
						[61.414816879276259, 2.032855510296093],
						[61.414816842038675, 2.032855469731949],
						[61.414816752671733, 2.0328554145087652],
						[61.414816699651297, 2.032855400049296],
						[61.414794399947333, 2.0328553998495904],
						[61.414777800262804, 2.0328554003705563],
						[61.414758300047374, 2.0328553996174041],
						[61.414758293714293, 2.0328553991546712],
						[61.414758282130457, 2.0328554017486042],
						[61.414758275797368, 2.0328554012858877],
						[61.414736227587063, 2.032858083309999],
						[61.414719586191076, 2.0328166257085547],
						[61.414719548666625, 2.0328165795661879],
						[61.414719456244008, 2.0328165175040658],
						[61.414719400359154, 2.0328164999243792],
						[61.414697199820083, 2.0328165001126344],
						[61.414677799660559, 2.0328164995456857],
						[61.414677791545373, 2.0328164994820392],
						[61.414677776301644, 2.0328165010146653],
						[61.414677768282054, 2.0328165028104705],
						[61.414661083774057, 2.0328192006910508],
						[61.414639027430788, 2.0328192005599495],
						[61.414619580551459, 2.0327777149102317],
						[61.414619538571657, 2.032777669765728],
						[61.41461943564746, 2.0327776138172315],
						[61.414619374607462, 2.0327776011537497],
						[61.414597187240624, 2.0327803990145652],
						[61.414577799747512, 2.032780399391414],
						[61.414561100007802, 2.0327803997796967],
						[61.414538900360043, 2.0327803997867364],
						[61.414519400145139, 2.0327803990707021],
						[61.414519392125563, 2.0327804008665002],
						[61.414519375990764, 2.032780402598672],
						[61.414519367875563, 2.0327804025350322],
						[61.414502908555455, 2.0327830800838003],
						[61.414480776270487, 2.0327416053812364],
						[61.414480740019556, 2.0327415664775415],
						[61.414480650843799, 2.0327415149741164],
						[61.414480599605511, 2.0327415001158684],
						[61.414461100281976, 2.0327414992222708],
						[61.414461094044512, 2.0327415006190099],
						[61.414461082365044, 2.0327415013535184],
						[61.414461076031976, 2.0327415008908232],
						[61.414438888053425, 2.0327441992077904],
						[61.414422327163443, 2.0327442004928096],
						[61.414402981212703, 2.0327027151363413],
						[61.414402943879459, 2.0327026727134396],
						[61.414402854416856, 2.0327026156319206],
						[61.414402800409711, 2.032702599512946],
						[61.414380599871606, 2.0327025997675423],
						[61.414363900132386, 2.0327026001905941],
						[61.414363893799305, 2.0327025997279087],
						[61.414363881228773, 2.03270260066195],
						[61.414363874991352, 2.0327026020586856],
						[61.414341686735199, 2.0327054001374609],
						[61.414322336158754, 2.0327053998073636],
						[61.414305786083744, 2.0326638264459231],
						[61.414305743912678, 2.0326637775831484],
						[61.414305637932763, 2.0326637147964099],
						[61.414305575110632, 2.0326637025323122],
						[61.41428328679995, 2.0326665004659934],
						[61.414263900198762, 2.0326665007013505],
						[61.414241699660899, 2.0326665009751781],
						[61.414225124227478, 2.0326664999088777],
						[61.414205779044387, 2.0326277117988023],
						[61.414205741711122, 2.0326276693762635],
						[61.414205653330782, 2.0326276158145959],
						[61.414205600214743, 2.0326275994963012],
						[61.414183299621605, 2.0326275996304459],
						[61.414163900450198, 2.0326276008199864],
						[61.414141699817023, 2.0326275992574376],
						[61.414141691701836, 2.032627599193833],
						[61.414141674771621, 2.0326276029849906],
						[61.414141666656406, 2.0326276029213939],
						[61.414124982765152, 2.0326304003844928],
						[61.414105599728423, 2.0326303998377093],
						[61.41408330002627, 2.0326303997656097],
						[61.414066700343042, 2.0326304003815907],
						[61.414044399749777, 2.0326304005072195],
						[61.414044393416695, 2.0326304000445305],
						[61.414044379064045, 2.0326304013776619],
						[61.414044371839914, 2.0326304011145226],
						[61.414024986120999, 2.0326331001484785],
						[61.414008299843715, 2.032633099462652],
						[61.413986100197036, 2.0326330995487942],
						[61.413966700038713, 2.0326330990669854],
						[61.413966644188505, 2.0326331172158136],
						[61.413966550948217, 2.0326331794728736],
						[61.413966513653769, 2.0326332254405086],
						[61.413949858000464, 2.0326748159928765],
						[61.413927787047484, 2.0326775990526791],
						[61.41390830039029, 2.0326775991017256],
						[61.413886099851993, 2.0326775993510222],
						[61.413886044001771, 2.0326776174997145],
						[61.413885950761454, 2.0326776797564667],
						[61.413885914358076, 2.0326777255244073],
						[61.413869265331236, 2.0327191997264298],
						[61.413852799945737, 2.032719200308128],
						[61.413827799822855, 2.0327191992241564],
						[61.413827743081569, 2.0327192175722479],
						[61.413827649141403, 2.0327192837470895],
						[61.413827612738011, 2.0327193295148756],
						[61.413810961250149, 2.0327637006133856],
						[61.413788900355783, 2.0327636996203196],
						[61.413772199724612, 2.0327637001893528],
						[61.41375279956489, 2.0327636996175062],
						[61.413752748965436, 2.0327637147093758],
						[61.413752659702318, 2.0327637666709526],
						[61.413752623012059, 2.0327638068603866],
						[61.413730480166699, 2.032805399443034],
						[61.41371390027652, 2.0328053992826622],
						[61.413713894039056, 2.0328054006793503],
						[61.413713882359602, 2.0328054014137962],
						[61.41371387602652, 2.0328054009510725],
						[61.413691688044381, 2.0328080991230468],
						[61.413672199603624, 2.0328080994798796],
						[61.413672143753388, 2.0328081176281869],
						[61.41367205140407, 2.0328081796845554],
						[61.413672014109565, 2.0328082256515292],
						[61.41365546392327, 2.0328497996042003],
						[61.413633300394636, 2.0328498008354625],
						[61.413613900234012, 2.0328498002043291],
						[61.41359169969359, 2.0328498003171918],
						[61.413591642952284, 2.0328498186648942],
						[61.413591548916436, 2.0328498829793955],
						[61.413591512608605, 2.0328499306060674],
						[61.413574862145943, 2.0328941994895859],
						[61.41355559994328, 2.0328941999110834],
						[61.413533300237766, 2.0328941996244194],
						[61.41351669966101, 2.032894200280158],
						[61.413516694219012, 2.0328941996179046],
						[61.413516680757432, 2.0328942007513544],
						[61.41351667541101, 2.0328942019484968],
						[61.413494374616434, 2.0328970024030872],
						[61.413494328663532, 2.0328970202157528],
						[61.41349424958451, 2.0328970774192698],
						[61.413494219131621, 2.0328971162115508],
						[61.413474871685075, 2.0329386993098533],
						[61.413458299908868, 2.032938699133338],
						[61.413436100354055, 2.0329387008354498],
						[61.413416700192414, 2.0329387001409471],
						[61.413394399595241, 2.0329387000154235],
						[61.413394343649358, 2.0329387163039327],
						[61.413394251395601, 2.0329387802186818],
						[61.413394214101039, 2.0329388261850863],
						[61.413377656708825, 2.0329804182207911],
						[61.413358286348156, 2.032983100345561],
						[61.413336100254362, 2.0329831008779635],
						[61.41331939952552, 2.0329830994541176],
						[61.413319343675255, 2.0329831176018471],
						[61.413319251325845, 2.0329831796569318],
						[61.413319214031254, 2.0329832256231715],
						[61.413302663878206, 2.0330248008628375],
						[61.413277799831796, 2.0330247991277264],
						[61.413277791812234, 2.0330248009233682],
						[61.413277774786351, 2.0330248028548183],
						[61.413277766671143, 2.0330248027910964],
						[61.413261082766788, 2.0330275999341931],
						[61.413238900236678, 2.0330275996323164],
						[61.413238844386406, 2.03302761777993],
						[61.41323875114589, 2.033027680034226],
						[61.413238713755668, 2.0330277241409029],
						[61.413222064701287, 2.0330691991383314],
						[61.413202799823537, 2.0330692000358956],
						[61.413180600171479, 2.0330691997737289],
						[61.413163900428486, 2.0330691999581179],
						[61.413163848842331, 2.0330692133892918],
						[61.413163759674667, 2.0330692672082913],
						[61.413163722888676, 2.0330693055371918],
						[61.413141570992842, 2.0331109160244019],
						[61.413122185996308, 2.0331136991143341],
						[61.413105599865787, 2.0331137001653063],
						[61.413083300157503, 2.0331136997004573],
						[61.413063900089604, 2.0331137007419464],
						[61.413063893756537, 2.0331137002791766],
						[61.413063881186012, 2.0331137012129865],
						[61.413063874948556, 2.033113702609572],
						[61.413041675092742, 2.0331165028124225],
						[61.413041625575538, 2.033116521422488],
						[61.413041545892199, 2.0331165844021313],
						[61.413041513752717, 2.0331166254519881],
						[61.413024864666355, 2.0331581001343491],
						[61.413005599787624, 2.0331581009689366],
						[61.412983300078778, 2.0331581004676269],
						[61.412966700390598, 2.033158100764064],
						[61.412944399790668, 2.0331581004604167],
						[61.412944344735813, 2.0331581165487371],
						[61.412944254072819, 2.0331581763441227],
						[61.412944216682547, 2.0331582204502054],
						[61.412924868745769, 2.0332025994094898],
						[61.412908299735875, 2.0332026003342878],
						[61.412886100082112, 2.0332025999633356],
						[61.412866699917572, 2.0332025990821068],
						[61.412844400303769, 2.0332026004017707],
						[61.412844344357822, 2.033202616689445],
						[61.412844251212839, 2.0332026808016925],
						[61.412844213918177, 2.0332027267669304],
						[61.41282765671059, 2.0332442181722583],
						[61.412808286062081, 2.033246999805868],
						[61.412786099964968, 2.0332470001247036],
						[61.412769400220292, 2.0332470001999452],
						[61.412747199674861, 2.0332469999903866],
						[61.412747146402111, 2.033247015679398],
						[61.412747055643479, 2.0332470736147701],
						[61.412747019048631, 2.0332471156616125],
						[61.412727671571538, 2.0332886993815782],
						[61.412711099887652, 2.0332887008522427],
						[61.412711094445648, 2.033288700189912],
						[61.412711080984025, 2.0332887013231469],
						[61.412711074746589, 2.033288702719672],
						[61.41268888735943, 2.0332914999568548],
						[61.412669399804386, 2.0332914997707294],
						[61.412669343954065, 2.0332915179174726],
						[61.412669250713435, 2.0332915801697315],
						[61.412669214309773, 2.0332916259350977],
						[61.412652664325392, 2.0333331003857049],
						[61.412630599859703, 2.0333330997338206],
						[61.41263059263553, 2.0333330994705183],
						[61.412630579173936, 2.0333331006037181],
						[61.41263057204543, 2.0333331021997303],
						[61.412611085968223, 2.033335900191672],
						[61.412588899869988, 2.0333359004380265],
						[61.412588843924041, 2.0333359167252918],
						[61.412588750778966, 2.0333359808365747],
						[61.412588714375318, 2.0333360266017571],
						[61.412572064931581, 2.0333776002942967],
						[61.412555600431062, 2.0333776002836466],
						[61.412530600394554, 2.0333776004626123],
						[61.412530543557523, 2.033377616949271],
						[61.412530451303482, 2.0333776808608151],
						[61.412530413913139, 2.0333777249660208],
						[61.412513764829903, 2.0334192006753624],
						[61.412491700363127, 2.0334191999560991],
						[61.412491692247919, 2.0334191998922653],
						[61.412491675222064, 2.0334192018233979],
						[61.41249166720246, 2.0334192036188874],
						[61.412474983285193, 2.0334220004532155],
						[61.412455600239781, 2.0334219993467975],
						[61.412455548749136, 2.0334220146363533],
						[61.412455460376762, 2.0334220663941585],
						[61.412455423686254, 2.0334221065810607],
						[61.412433179849359, 2.0334637006679355],
						[61.412416700008684, 2.0334637002797886],
						[61.412394400296023, 2.0334636995321769],
						[61.412375000224287, 2.0334637003276534],
						[61.412374992109079, 2.0334637002637903],
						[61.412374975083225, 2.0334637021949016],
						[61.412374966968017, 2.0334637021310566],
						[61.412358267010042, 2.0334665025179839],
						[61.412358224621258, 2.0334665195310757],
						[61.412358153370363, 2.0334665712167732],
						[61.412358123617139, 2.0334666060888948],
						[61.412335980099002, 2.0335081004376607],
						[61.412316699877117, 2.0335081007007303],
						[61.412294400163887, 2.0335080999166544],
						[61.412277799581375, 2.0335081002020692],
						[61.412277793248329, 2.0335080997392141],
						[61.412277778895643, 2.0335081010718175],
						[61.412277771767094, 2.0335081026677737],
						[61.412258272222317, 2.0335109016374839],
						[61.4122582280514, 2.0335109190494385],
						[61.412258154127279, 2.0335109713333366],
						[61.412258122591929, 2.0335110066042863],
						[61.412235979669404, 2.0335526003254758],
						[61.412219399772241, 2.0335525997154447],
						[61.412197200114136, 2.0335525990629941],
						[61.412197146045884, 2.0335526168099447],
						[61.412197056082498, 2.0335526726845212],
						[61.412197018596494, 2.0335527149297139],
						[61.412177673168486, 2.0335941994299578],
						[61.412161099604013, 2.0335941992586499],
						[61.412161094257627, 2.0335942004555929],
						[61.412161080796025, 2.0335942015886328],
						[61.412161075449632, 2.0335942027855771],
						[61.412138874685667, 2.0335970027229866],
						[61.412138828732594, 2.0335970205337288],
						[61.412138751435393, 2.0335970773337637],
						[61.412138719104533, 2.0335971146632827],
						[61.412119273421879, 2.0336387004878786],
						[61.412097199946189, 2.0336386997304836],
						[61.412080600253532, 2.0336386997399769],
						[61.412061100124234, 2.0336387002453415],
						[61.412061045960378, 2.033638716132768],
						[61.412060956092596, 2.0336387738660981],
						[61.412060918606542, 2.0336388161110155],
						[61.412041564723324, 2.0336804169818752],
						[61.412022186319909, 2.0336831002983931],
						[61.412005600088456, 2.0336830991486021],
						[61.412005548597804, 2.0336831144374505],
						[61.412005460225316, 2.0336831661936889],
						[61.412005424425786, 2.0336832061801893],
						[61.411983179605528, 2.0337247996638026],
						[61.411963900272411, 2.0337247995790042],
						[61.411941699816772, 2.0337248008507363],
						[61.41194164387074, 2.0337248171369153],
						[61.411941550629848, 2.0337248793862956],
						[61.411941514226037, 2.03372492515002],
						[61.411924856691954, 2.0337665183443745],
						[61.41190558636108, 2.033769199966097],
						[61.411883300201879, 2.0337691997022325],
						[61.411883244351458, 2.0337692178475808],
						[61.41188315111053, 2.0337692800967013],
						[61.411883113815641, 2.0337693260597662],
						[61.411866564372325, 2.0338109005917984],
						[61.411844399845144, 2.0338108993962343],
						[61.411824999769728, 2.0338108999531146],
						[61.41180830001985, 2.0338108996924116],
						[61.411808249420226, 2.0338109147814554],
						[61.411808160156596, 2.0338109667364539],
						[61.411808123370328, 2.0338110050627458],
						[61.411785971401883, 2.0338526168831077],
						[61.411766685544919, 2.0338553997049296],
						[61.411744400275744, 2.0338553991734303],
						[61.41174434442533, 2.0338554173185028],
						[61.411744251184338, 2.0338554795670714],
						[61.411744213889413, 2.0338555255298356],
						[61.411727663804605, 2.0338970002883983],
						[61.411708299753357, 2.0338970002449064],
						[61.411686100091082, 2.0338969993232512],
						[61.411669400340415, 2.0338969990115494],
						[61.411669394102965, 2.0338970004078916],
						[61.411669380641335, 2.0338970015407942],
						[61.411669375294949, 2.0338970027376608],
						[61.411647175411467, 2.0338998021931372],
						[61.411647125989681, 2.0338998226602865],
						[61.411647046210298, 2.0338998837756086],
						[61.411647014166149, 2.0338999266820297],
						[61.411630463690223, 2.033941499164345],
						[61.411611099734095, 2.0339415009485107],
						[61.411588900071251, 2.0339414999906396],
						[61.411569399938713, 2.0339415002863759],
						[61.411569345774794, 2.0339415161729546],
						[61.411569255906819, 2.0339415739044444],
						[61.411569219216148, 2.033941614089529],
						[61.411549863820106, 2.033983116622109],
						[61.411530586073972, 2.0339858993966584],
						[61.411513899879679, 2.033985899758274],
						[61.411491700311899, 2.033985900623501],
						[61.411491646147979, 2.0339859165099532],
						[61.411491556184387, 2.0339859723818905],
						[61.411491518698206, 2.0339860146255631],
						[61.411472072879221, 2.0340275993252597],
						[61.411450000385464, 2.034027599922168],
						[61.411449992270235, 2.0340275998581592],
						[61.411449975244338, 2.0340276017888659],
						[61.411449967224769, 2.0340276035841116],
						[61.41143328328792, 2.034030399963517],
						[61.411413900331596, 2.0340304002955367],
						[61.411413848745262, 2.0340304137241922],
						[61.411413759577165, 2.0340304675370864],
						[61.411413723681882, 2.0340305056631349],
						[61.411391580061078, 2.0340720001804455],
						[61.411375000159197, 2.0340719992646519],
						[61.411355600080924, 2.0340719996398389],
						[61.411355593747892, 2.0340719991768528],
						[61.411355581177325, 2.0340720001101871],
						[61.411355574939847, 2.0340720015064799],
						[61.411333275090065, 2.0340748024840778],
						[61.41133322566828, 2.0340748229507057],
						[61.411333145888776, 2.0340748840649248],
						[61.411333113844599, 2.0340749269706908],
						[61.411316564293777, 2.0341165005937598],
						[61.411294399762824, 2.0341164991552314],
						[61.411274999684096, 2.0341164994994241],
						[61.411258300026972, 2.0341165009148972],
						[61.411258249331695, 2.0341165141438555],
						[61.411258160067973, 2.0341165660969533],
						[61.411258124268272, 2.03411660608195],
						[61.411235979725895, 2.0341581005248695],
						[61.411216700388159, 2.0341581001417381],
						[61.411216694055092, 2.0341580996787387],
						[61.411216680593469, 2.0341581008114979],
						[61.411216675247069, 2.0341581020082953],
						[61.41119438697816, 2.0341609003123349],
						[61.411177799946877, 2.0341609009381099],
						[61.411177745782929, 2.0341609168240642],
						[61.411177655819273, 2.0341609726948544],
						[61.41117761922407, 2.0341610147384253],
						[61.411158173438871, 2.0342026007600222],
						[61.411136099956281, 2.034202599558629],
						[61.411119400202779, 2.0342025990638359],
						[61.411097199741256, 2.0342025999557278],
						[61.411097142999679, 2.0342026182992159],
						[61.411097048963057, 2.0342026826040347],
						[61.411097012654636, 2.0342027302251671],
						[61.411080461136137, 2.0342470007319808],
						[61.411061099754328, 2.0342469998462636],
						[61.411038900183321, 2.0342470005043438],
						[61.4110222004294, 2.0342469999823471],
						[61.411022194096319, 2.0342469995193477],
						[61.411022183307928, 2.0342470000536625],
						[61.411022177961527, 2.0342470012504377],
						[61.410997177683655, 2.0342498009723324],
						[61.410997129057286, 2.0342498193797365],
						[61.410997048386626, 2.0342498806922533],
						[61.410997016246753, 2.0342499217381498],
						[61.410980459196175, 2.0342887206569156],
						[61.410963883452546, 2.0342915001404371],
						[61.410941700111408, 2.034291500890443],
						[61.410922199975062, 2.0342915009406273],
						[61.41092214927977, 2.0342915141691211],
						[61.410922060015913, 2.0342915661211123],
						[61.410922024216148, 2.0342916061054557],
						[61.410899879611343, 2.034333099964408],
						[61.410883299802613, 2.0343331007518093],
						[61.41088329257849, 2.0343331004882486],
						[61.410883279116867, 2.0343331016209101],
						[61.410883271001659, 2.0343331015568134],
						[61.410863885933061, 2.0343359005269566],
						[61.410841699822548, 2.0343358999798613],
						[61.41082500006781, 2.0343358994032927],
						[61.410824949468115, 2.0343359144908884],
						[61.410824860204215, 2.034335966442574],
						[61.41082482262231, 2.0343360068256682],
						[61.410802680394653, 2.0343775996574762],
						[61.410783300107774, 2.0343775991494542],
						[61.410783291992601, 2.0343775990853357],
						[61.410783275062272, 2.0343776028750296],
						[61.410783266947057, 2.0343776028109191],
						[61.410766683054561, 2.0343803990911979],
						[61.410744399656593, 2.0343803995977265],
						[61.410724999574938, 2.0343803997551895],
						[61.410708299819731, 2.0343803991506868],
						[61.410708249220015, 2.0343804142381625],
						[61.410708159956123, 2.0343804661895022],
						[61.410708124156372, 2.0343805061734455],
						[61.410685980409994, 2.034421999476828],
						[61.410666700178332, 2.0344219991075772],
						[61.410666693940833, 2.0344220005037639],
						[61.410666681370273, 2.0344220014369179],
						[61.410666675132823, 2.0344220028330962],
						[61.410644386854386, 2.0344248008735741],
						[61.410627799725027, 2.0344247994804769],
						[61.410608299587167, 2.0344247994343765],
						[61.410586100013852, 2.034424799944651],
						[61.410586044067742, 2.0344248162286855],
						[61.410585950922012, 2.0344248803322387],
						[61.410585913626861, 2.0344249262925365],
						[61.410569257626548, 2.0344665168713285],
						[61.410547187876851, 2.0344692001305664],
						[61.410527800364775, 2.0344691992915789],
						[61.41051109981327, 2.0344692006912379],
						[61.410488900143797, 2.0344691993061379],
						[61.410469400005404, 2.0344691992265647],
						[61.410469393767926, 2.0344692006227261],
						[61.410469381197359, 2.0344692015558343],
						[61.410469374864277, 2.0344692010927981],
						[61.410447175055751, 2.0344720018368507],
						[61.410447125633887, 2.0344720223021762],
						[61.410447045854163, 2.0344720834135015],
						[61.410447013809836, 2.0344721263175898],
						[61.410430464211338, 2.0345137003841267],
						[61.410411100153304, 2.0345136999046152],
						[61.410388899687781, 2.0345137005418068],
						[61.41037219993126, 2.0345136998540911],
						[61.41037214923594, 2.0345137130819357],
						[61.410372060067601, 2.0345137668914637],
						[61.410372023281091, 2.0345138052150635],
						[61.41034987115502, 2.0345554164249826],
						[61.410330585649746, 2.0345581007512226],
						[61.410313900341123, 2.0345581005648721],
						[61.410291699779457, 2.0345580993066972],
						[61.410291643928893, 2.0345581174495209],
						[61.410291550687539, 2.0345581796928141],
						[61.410291514283379, 2.034558225453035],
						[61.410274864694472, 2.0345998009501485],
						[61.410249999830619, 2.0345997998589498],
						[61.410233300073337, 2.0345997991202052],
						[61.410233292053711, 2.0345998009152213],
						[61.410233275027821, 2.0345998028455066],
						[61.410233266912606, 2.034599802781333],
						[61.410216683108224, 2.0346026007343307],
						[61.410191700176036, 2.0346025997365342],
						[61.410191644229876, 2.0346026160200199],
						[61.410191551084097, 2.0346026801221462],
						[61.410191513693256, 2.0346027242224181],
						[61.410174865259506, 2.0346441996265026],
						[61.410152799982143, 2.0346441999900917],
						[61.410136100224427, 2.0346441992241435],
						[61.410136093095879, 2.0346442008196983],
						[61.410136078647596, 2.0346442002924321],
						[61.410136070627971, 2.0346442020874322],
						[61.410116671195794, 2.0346470020988292],
						[61.410116627915748, 2.0346470193083448],
						[61.410116553991095, 2.0346470715856504],
						[61.41011652423753, 2.0346471064540155],
						[61.410094280154006, 2.034688700178819],
						[61.410077800302041, 2.0346886990618764],
						[61.410058300256864, 2.0346887006872616],
						[61.410036099693585, 2.0346886993241267],
						[61.41003609157837, 2.0346886992599234],
						[61.410036074552444, 2.0346887011901496],
						[61.410036067423903, 2.0346887027856835],
						[61.41001938266978, 2.0346915006942177],
						[61.40999720011898, 2.0346914990567959],
						[61.409997146050536, 2.0346915168002915],
						[61.40999705608661, 2.0346915726672252],
						[61.409997018600158, 2.0346916149079366],
						[61.409977672958647, 2.0347330994247064],
						[61.409961100370246, 2.0347331002307971],
						[61.409938899902002, 2.0347331006906697],
						[61.409919399760703, 2.0347331004233955],
						[61.409919349065312, 2.0347331136506668],
						[61.409919259896867, 2.0347331674587088],
						[61.409919223110272, 2.0347332057814627],
						[61.409897070109572, 2.0347748182728531],
						[61.409880582821742, 2.0347776001292504],
						[61.409861099801489, 2.0347775997607744],
						[61.409838900223733, 2.0347775999848436],
						[61.409838844277509, 2.0347776162677809],
						[61.409838751131616, 2.0347776803686144],
						[61.409838713740726, 2.0347777244681486],
						[61.409822065244747, 2.0348191993004252],
						[61.409799999965209, 2.034819199523739],
						[61.409799992741092, 2.0348191992600593],
						[61.409799979279455, 2.0348192003924384],
						[61.409799971259837, 2.0348192021873661],
						[61.409780586174364, 2.0348220007143745],
						[61.409763899972198, 2.0348220005668738],
						[61.409741700393866, 2.0348220007547964],
						[61.409741646229783, 2.0348220166387168],
						[61.409741556265786, 2.0348220725047703],
						[61.409741518779285, 2.034822114744967],
						[61.40972207285084, 2.0348637002516883],
						[61.409700000346568, 2.0348637001751713],
						[61.409699992231396, 2.0348637001109129],
						[61.40969997520547, 2.0348637020409912],
						[61.409699967185851, 2.0348637038358999],
						[61.409683283221526, 2.0348664995504215],
						[61.409663900255985, 2.0348664992910859],
						[61.409641699786015, 2.034866499642062],
						[61.40962500012175, 2.0348665005982132],
						[61.409624949426373, 2.0348665138250897],
						[61.409624860257814, 2.0348665676322142],
						[61.409624824362304, 2.0348666057549849],
						[61.409602679643875, 2.034908099288725],
						[61.409583300337772, 2.0349081000616374],
						[61.409583292222543, 2.034908099997375],
						[61.409583275196653, 2.0349081019274275],
						[61.409583267177027, 2.034908103722318],
						[61.409566683266917, 2.0349108995715075],
						[61.409544399862135, 2.0349108996424761],
						[61.409544344011515, 2.0349109177841456],
						[61.409544250769954, 2.0349109800247529],
						[61.409544214365646, 2.0349110257834186],
						[61.409527663814949, 2.0349526004486775],
						[61.409508299752112, 2.0349525996597153],
						[61.409486100172167, 2.0349525997426587],
						[61.409469399616029, 2.034952600846657],
						[61.409469394173996, 2.0349526001840754],
						[61.409469383385606, 2.0349526007180807],
						[61.409469378039162, 2.0349526019146666],
						[61.409444389414048, 2.0349554001169508],
						[61.409427799702051, 2.0349554008570752],
						[61.409427743755799, 2.034955417139428],
						[61.409427651405274, 2.0349554791802396],
						[61.409427614109887, 2.034955525138098],
						[61.409410964658576, 2.0349969995169603],
						[61.409388900267849, 2.0349969993958821],
						[61.409369400219227, 2.0349970008000988],
						[61.409369393886145, 2.0349970003369524],
						[61.409369381315585, 2.0349970012697995],
						[61.409369375078114, 2.0349970026657904],
						[61.409347186834431, 2.0349998002918519],
						[61.409330599795226, 2.0349998004066636],
						[61.409311099650935, 2.0349997999475806],
						[61.409288900070251, 2.0349997999876988],
						[61.409288891955036, 2.0349997999234075],
						[61.409288874929139, 2.0349998018533833],
						[61.409288866909506, 2.0349998036482257],
						[61.409272166901189, 2.0350026028284596],
						[61.409272125403248, 2.0350026196380866],
						[61.409272053260558, 2.0350026715141554],
						[61.409272024397936, 2.0350027061817615],
						[61.409249879724634, 2.0350442009945779],
						[61.409230600377157, 2.0350441999827664],
						[61.409213899724492, 2.0350441991695654],
						[61.409191700143303, 2.0350441991735133],
						[61.409172200094055, 2.0350442005401068],
						[61.409172193760973, 2.035044200076932],
						[61.409172181190442, 2.0350442010097418],
						[61.409172174952957, 2.0350442024057167],
						[61.409149986707384, 2.0350469999713261],
						[61.409133299611852, 2.0350469998806897],
						[61.409133246338861, 2.035047015564404],
						[61.409133155579241, 2.0350470734871831],
						[61.409133118983739, 2.0350471155267913],
						[61.409113772136159, 2.0350887004340983],
						[61.409091699628981, 2.0350887001699709],
						[61.409074999866981, 2.0350886991286212],
						[61.409074949267151, 2.0350887142139653],
						[61.409074860002903, 2.0350887661602712],
						[61.409074823311791, 2.0350888063406232],
						[61.409052670975974, 2.0351304153612126],
						[61.409033286382197, 2.0351331006540003],
						[61.409016700232733, 2.0351331004875077],
						[61.409016648646279, 2.035133113913044],
						[61.409016560368656, 2.0351331675188091],
						[61.40901652358194, 2.0351332058399141],
						[61.408994280307745, 2.035174799367923],
						[61.408975000163345, 2.0351748003235111],
						[61.408952799689473, 2.0351748004227002],
						[61.408952743743207, 2.0351748167043735],
						[61.408952650597065, 2.0351748808020207],
						[61.40895261419265, 2.0351749265594887],
						[61.408935965273059, 2.0352164997073494],
						[61.408916700372018, 2.0352164991037882],
						[61.408916694134518, 2.0352165004997138],
						[61.408916682455022, 2.0352165012330281],
						[61.408916676121954, 2.0352165007698377],
						[61.408894388190873, 2.0352192001334628],
						[61.408877800258516, 2.0352192003151344],
						[61.408858300111802, 2.0352191997002347],
						[61.408858245947684, 2.0352192155829094],
						[61.408858156079063, 2.0352192733052044],
						[61.408858118592413, 2.0352193155436447],
						[61.408838771684707, 2.035260899969443],
						[61.408819399598855, 2.0352609007556124],
						[61.408819394156822, 2.0352609000930051],
						[61.408819380695206, 2.0352609012251284],
						[61.408819375348777, 2.0352609024216215],
						[61.40879718709563, 2.0352636997851086],
						[61.408777799670347, 2.0352637002397094],
						[61.408761099906698, 2.0352636990935671],
						[61.408761049306861, 2.0352637141784409],
						[61.408760960042507, 2.0352637661236685],
						[61.408760923351359, 2.0352638063034036],
						[61.408738780086104, 2.0353053995621559],
						[61.408722200268173, 2.0353053997569019],
						[61.408722193935105, 2.0353053992936938],
						[61.408722182255609, 2.0353054000269637],
						[61.408722176018109, 2.0353054014228631],
						[61.408699988139659, 2.0353081008714504],
						[61.408680599727241, 2.0353080996347592],
						[61.408663900058762, 2.0353081003205329],
						[61.408663849363329, 2.0353081135461832],
						[61.408663760194543, 2.0353081673501996],
						[61.408663723407791, 2.0353082056706349],
						[61.408641580222003, 2.0353498006236208],
						[61.408622199924352, 2.0353497994230034],
						[61.408600000339362, 2.0353497991816574],
						[61.408599943597565, 2.0353498175213001],
						[61.408599851246812, 2.03534987955913],
						[61.40859981395127, 2.0353499255152547],
						[61.408583157014384, 2.0353915186753468],
						[61.408563885833814, 2.0353942009300425],
						[61.408541699709907, 2.0353941995223201],
						[61.408541643859202, 2.03539421766241],
						[61.408541550617365, 2.0353942798993905],
						[61.408541514212864, 2.0353943256559663],
						[61.408524865311321, 2.0354358999874402],
						[61.408502800024053, 2.0354358997139443],
						[61.408483299970598, 2.0354359008071823],
						[61.408483291855411, 2.0354359007427569],
						[61.408483274829464, 2.0354359026724049],
						[61.408483266714242, 2.0354359026079893],
						[61.408466666843147, 2.0354387034884649],
						[61.408466625345127, 2.0354387202969928],
						[61.408466553202217, 2.0354387721706173],
						[61.408466524339453, 2.0354388068368734],
						[61.408444280065702, 2.0354803995926831],
						[61.408424999918019, 2.0354804003369327],
						[61.408402800331423, 2.0354803999933644],
						[61.4084027443851, 2.035480416274122],
						[61.408402651238788, 2.0354804803696278],
						[61.408402613847628, 2.0354805244662089],
						[61.408385965292567, 2.0355220006941837],
						[61.408366700388243, 2.0355219998795433],
						[61.408344399854293, 2.0355219995278797],
						[61.408344343112489, 2.0355220178670286],
						[61.408344249075064, 2.0355220821616862],
						[61.408344212766117, 2.0355221297768913],
						[61.408327660751645, 2.0355665009108637],
						[61.408305599918144, 2.0355664995387568],
						[61.408288900247356, 2.035566500072898],
						[61.40826940009692, 2.0355664992165536],
						[61.40826939385942, 2.0355665006123704],
						[61.40826938217991, 2.035566501345524],
						[61.408269375846828, 2.0355665008822657],
						[61.408247187959397, 2.0355692000849834],
						[61.408230600023799, 2.0355692000577994],
						[61.408230545859588, 2.0355692159395038],
						[61.408230455990775, 2.0355692736595059],
						[61.408230419395096, 2.0355693156972383],
						[61.408210973294608, 2.0356109005340675],
						[61.408188899889979, 2.0356109000576561],
						[61.408172200218722, 2.0356109005638929],
						[61.408149999739443, 2.0356109003149903],
						[61.408149993406361, 2.0356108998517035],
						[61.40814997905364, 2.035610901183039],
						[61.408149970938432, 2.0356109011185692],
						[61.408130570677528, 2.0356137023286256],
						[61.408130523928634, 2.0356137221921973],
						[61.408130445039347, 2.0356137830963026],
						[61.408130413885637, 2.0356138257964989],
						[61.408113764906531, 2.0356553994524327],
						[61.408091699712138, 2.0356554008635581],
						[61.408072199560713, 2.0356553999435096],
						[61.408049999971915, 2.035655399458832],
						[61.408033300300218, 2.0356553999363634],
						[61.408033246136021, 2.0356554158177724],
						[61.40803315626713, 2.035655473537147],
						[61.408033118780324, 2.0356555157738874],
						[61.40801376474365, 2.0356970157436436],
						[61.40799168665022, 2.0356997993160273],
						[61.407974999644267, 2.0356998006942995],
						[61.407952800054922, 2.0356998001734556],
						[61.40793329990295, 2.0356997992199557],
						[61.407933244052167, 2.0356998173590988],
						[61.407933150810152, 2.0356998795938397],
						[61.407933114405559, 2.0356999253491024],
						[61.407916558196, 2.0357415155337177],
						[61.4078943875504, 2.0357441994228176],
						[61.407875000119908, 2.0357441995416972],
						[61.407852799638938, 2.0357441991839571],
						[61.407836099966389, 2.0357441996069836],
						[61.407836045802149, 2.0357442154881205],
						[61.407835955933237, 2.0357442732068352],
						[61.407835919337487, 2.03574431524379],
						[61.407816572338191, 2.0357858998080345],
						[61.407794399862247, 2.0357859006729258],
						[61.407794391747025, 2.0357859006084058],
						[61.407794374721071, 2.035785902537798],
						[61.407794366605849, 2.0357859024732781],
						[61.407777782762622, 2.0357886994928114],
						[61.407755600389194, 2.0357887006881552],
						[61.407736100236249, 2.0357886996709569],
						[61.40771939967216, 2.0357887002654174],
						[61.40771939423017, 2.0357886996027181],
						[61.407719380768533, 2.0357887007345421],
						[61.407719375422126, 2.0357887019308865],
						[61.407697187245496, 2.0357915006283851],
						[61.407677799814387, 2.0357915007098049],
						[61.407655600223805, 2.0357915001097764],
						[61.407638899659666, 2.0357915006999168],
						[61.407619400397643, 2.0357914994767952],
						[61.407619394160186, 2.0357915008725214],
						[61.407619382480654, 2.0357915016055501],
						[61.407619376147593, 2.0357915011422412],
						[61.407597188251842, 2.0357942000984628],
						[61.407580600313977, 2.0357941999288633],
						[61.407561100256395, 2.0357942007600873],
						[61.407538899774629, 2.0357942003520719],
						[61.407538891659399, 2.0357942002875458],
						[61.407538874633445, 2.0357942022169251],
						[61.407538866613805, 2.0357942040114336],
						[61.407522182714004, 2.0357970008185124],
						[61.40750000024471, 2.0357970001369723],
						[61.407480600147281, 2.0357969992803033],
						[61.407463899582964, 2.0357969998613501],
						[61.407441699992113, 2.0357969992474221],
						[61.407441692863564, 2.0357970008425288],
						[61.407441679306345, 2.035797000115303],
						[61.407441672177747, 2.0357970017104234],
						[61.407422186111091, 2.0357997993458756],
						[61.407400000077395, 2.0357997994552486],
						[61.407383300404071, 2.0357997998325543],
						[61.407363900402068, 2.0357998008284173],
						[61.407363894069022, 2.0357998003651048],
						[61.407363880607363, 2.035799801496907],
						[61.407363875260941, 2.0357998026932358],
						[61.407341686987628, 2.0358025994752822],
						[61.407324999980396, 2.0358026007756669],
						[61.407302800389346, 2.0358026001512659],
						[61.407283300235903, 2.0358025991068076],
						[61.407266808789316, 2.0358025998955949],
						[61.407241867837996, 2.0357637918240967],
						[61.407241831874693, 2.0357637585023802],
						[61.407241748333263, 2.0357637113815552],
						[61.407241699959606, 2.0357636996408819],
						[61.407225120136992, 2.035763699539626],
						[61.407202977025271, 2.0357221067886142],
						[61.407202934251018, 2.0357220637085929],
						[61.407202830628151, 2.0357220116794168],
						[61.407202772452713, 2.0357220021320823],
						[61.407183286387792, 2.0357247997830998],
						[61.407166820178453, 2.0357248006037314],
						[61.407144576013927, 2.0356832061206678],
						[61.407144539763898, 2.0356831672220834],
						[61.407144451384795, 2.0356831136625071],
						[61.407144400242338, 2.0356831006611462],
						[61.407125116431899, 2.0356831001657829],
						[61.40710297363929, 2.0356443017990307],
						[61.407102937484829, 2.0356442647595645],
						[61.407102850187925, 2.0356442147187956],
						[61.407102799840992, 2.0356441996591057],
						[61.407086100168719, 2.035644200109322],
						[61.407066816358615, 2.0356441996356747],
						[61.407044573123933, 2.0356054990562864],
						[61.407044537065026, 2.0356054638759389],
						[61.407044449768129, 2.0356054138353601],
						[61.407044400407798, 2.0356054004353576],
						[61.40702791341797, 2.0356054003232384],
						[61.407002971978791, 2.0355637966515365],
						[61.407002927613519, 2.0355637576887817],
						[61.407002825168412, 2.0355637110380416],
						[61.407002767088557, 2.0355637033500598],
						[61.406986217237225, 2.0355664777405238],
						[61.406966880536011, 2.0355249155820965],
						[61.406966844190364, 2.0355248748249055],
						[61.406966753742346, 2.0355248160878197],
						[61.406966699735548, 2.0355247999669426],
						[61.40694453171696, 2.0355247999809039],
						[61.406927984370398, 2.0354860209947474],
						[61.406927983383753, 2.0354860193351252],
						[61.406927982397114, 2.0354860176755163],
						[61.40692798131488, 2.0354860141569167],
						[61.406908480751611, 2.0354443157918967],
						[61.406908479669383, 2.0354443122732806],
						[61.406908477696078, 2.0354443089540775],
						[61.406908475722801, 2.0354443056348681],
						[61.406886278198257, 2.0354027087576387],
						[61.406866879190979, 2.0353638102281035],
						[61.406866878204347, 2.0353638085684844],
						[61.406866877217688, 2.0353638069088884],
						[61.406866876231057, 2.0353638052492902],
						[61.406844580977406, 2.0353221137647588],
						[61.40682798360578, 2.0352832220876724],
						[61.406827947068912, 2.0352831776129539],
						[61.406827854743099, 2.0352831174166175],
						[61.406827799749593, 2.0352830996366125],
						[61.406808420437798, 2.0352831000631766],
						[61.406786277802212, 2.0352416077233935],
						[61.406766878847669, 2.0352027112768281],
						[61.40676687786101, 2.0352027096172414],
						[61.406766876874393, 2.035202707957648],
						[61.406766875887719, 2.0352027062980684],
						[61.406744581383727, 2.0351610130026478],
						[61.406728000328876, 2.035122159242976],
						[61.406727999656965, 2.0350805992898944],
						[61.406727997618958, 2.0350805771814353],
						[61.406727988292033, 2.0350805360199322],
						[61.406727980907597, 2.0350805151078926],
						[61.406708500247134, 2.0350388560326595],
						[61.40670850002185, 2.034997200102485],
						[61.406708500008925, 2.034958300754917],
						[61.406708496888641, 2.0349582751279089],
						[61.406708484601737, 2.0349582289877173],
						[61.406708476230627, 2.0349582064161451],
						[61.406686297408079, 2.0349166435433439],
						[61.406683499722376, 2.0348749931459853],
						[61.406683500208835, 2.0348332994745966],
						[61.406683498266368, 2.0348332792251687],
						[61.406683490021621, 2.0348332415823531],
						[61.40668348362378, 2.0348332223299792],
						[61.406666900123163, 2.0347943580697447],
						[61.406666900338706, 2.0347527998643047],
						[61.406666897313954, 2.0347527760963335],
						[61.406666884931411, 2.0347527280973092],
						[61.406666875669174, 2.0347527057252406],
						[61.406644576196022, 2.0347110064509382],
						[61.406644532530429, 2.0347109635724556],
						[61.406644430689362, 2.0347109111486508],
						[61.406644370731762, 2.0347109020021348],
						[61.406624985642807, 2.0347137001843145],
						[61.406608300330461, 2.034713699784596],
						[61.406583300367409, 2.0347137004380289],
						[61.40656669977141, 2.0347136998818351],
						[61.40654440013563, 2.0347136998245587],
						[61.406544392911499, 2.0347136995608679],
						[61.406544378558799, 2.0347137008924951],
						[61.406544371430236, 2.0347137024877964],
						[61.406524986341005, 2.034716500655176],
						[61.40650830013746, 2.0347165004498686],
						[61.406486099666317, 2.0347165007601973],
						[61.406466699578196, 2.0347165005346115],
						[61.406466694136206, 2.0347164998720939],
						[61.406466681565647, 2.0347165008048926],
						[61.406466676123642, 2.034716500142395],
						[61.406444388202516, 2.0347191995357861],
						[61.406427800272532, 2.034719199897987],
						[61.406408300128604, 2.0347191994953717],
						[61.406386099657318, 2.0347191997991105],
						[61.40636939999208, 2.0347192007196537],
						[61.406369349296675, 2.0347192139450443],
						[61.406369260128102, 2.034719267746353],
						[61.40636922334145, 2.0347193060644817],
						[61.406347069666289, 2.0347609194373284],
						[61.406330583262786, 2.0347637006616717],
						[61.40631110023989, 2.0347637001563674],
						[61.406288899768029, 2.0347637004238783],
						[61.406272200006754, 2.0347636994582277],
						[61.40625279991805, 2.0347636991945328],
						[61.406252746645031, 2.0347637148769677],
						[61.406252658463096, 2.0347637703374559],
						[61.406252620880963, 2.034763810713756],
						[61.406230475877159, 2.0348080995661566],
						[61.406213899721408, 2.034808100999193],
						[61.406191700044488, 2.0348080991721709],
						[61.406172199995119, 2.0348081005632115],
						[61.406172191879911, 2.0348081004989194],
						[61.406172174854021, 2.0348081024286939],
						[61.406172166738799, 2.0348081023643889],
						[61.406155582920249, 2.0348108997006276],
						[61.406133300404584, 2.034810899483698],
						[61.406113900315312, 2.0348108991849641],
						[61.406113848824432, 2.0348109144684159],
						[61.40611375956022, 2.0348109664100171],
						[61.406113722869108, 2.0348110065866694],
						[61.406091579883132, 2.0348526002204115],
						[61.406075000067069, 2.0348526005636058],
						[61.40605559997735, 2.0348526002365408],
						[61.406055593644268, 2.034852599773413],
						[61.406055581073737, 2.0348526007061243],
						[61.406055574836238, 2.0348526021019557],
						[61.406033286533905, 2.0348553992049903],
						[61.406016700384711, 2.0348553990815454],
						[61.406016648893797, 2.0348554143648725],
						[61.406016560616237, 2.0348554679656976],
						[61.406016523829564, 2.034855506283201],
						[61.40599428016111, 2.0348970000573305],
						[61.405974999921412, 2.034896999204002],
						[61.405958300254454, 2.0348970000148401],
						[61.405936099780853, 2.0348970001709281],
						[61.405936092556722, 2.034896999907208],
						[61.405936079095056, 2.0348970010393077],
						[61.405936071075416, 2.0348970028339326],
						[61.405916670735643, 2.0348998023390474],
						[61.405916628346596, 2.0348998193460943],
						[61.405916554421701, 2.0348998716151305],
						[61.405916523776902, 2.0348999066776852],
						[61.405894279811747, 2.0349415000033799],
						[61.405877800050582, 2.0349415004662159],
						[61.405858299904125, 2.0349414999019642],
						[61.405836100321039, 2.034941499822287],
						[61.405836092205824, 2.0349414997579558],
						[61.405836076070976, 2.0349415014882282],
						[61.405836068051343, 2.0349415032828277],
						[61.40581938445316, 2.0349442006126677],
						[61.405797200209356, 2.0349442008573111],
						[61.405797146045238, 2.0349442167385594],
						[61.405797056176652, 2.0349442744553934],
						[61.405797018690023, 2.0349443166897694],
						[61.405777671996411, 2.034985900790506],
						[61.40576110019871, 2.034985899257264],
						[61.405738899723957, 2.0349858993408301],
						[61.4057388934865, 2.03498590073661],
						[61.405738879038189, 2.0349859002091315],
						[61.405738871909612, 2.034985901804319],
						[61.405719385863691, 2.0349886997230424],
						[61.405697199837178, 2.0349887003301625],
						[61.405680600129472, 2.0349886993971826],
						[61.405661100077964, 2.034988700654246],
						[61.405661049382552, 2.0349887138788363],
						[61.405660960213858, 2.0349887676780036],
						[61.405660923427106, 2.0349888059948951],
						[61.405638780470788, 2.0350304007395921],
						[61.405622199666333, 2.0350303993129],
						[61.405622193428819, 2.0350304007086737],
						[61.405622178980536, 2.0350304001811677],
						[61.405622171851988, 2.0350304017763343],
						[61.405602786140889, 2.0350331001069812],
						[61.405580600113787, 2.035033100677043],
						[61.405563900349875, 2.0350330995421904],
						[61.405541699874476, 2.0350330995829222],
						[61.405522199822492, 2.035033100806511],
						[61.405522191707284, 2.035033100742154],
						[61.40552217468133, 2.0350331026717496],
						[61.405522166566158, 2.0350331026073856],
						[61.405505582739849, 2.0350358997432032],
						[61.405483300221142, 2.0350358993349831],
						[61.405463900224866, 2.0350359007285954],
						[61.405463848638341, 2.0350359141524033],
						[61.405463760360689, 2.0350359677516714],
						[61.405463722682867, 2.0350360062676383],
						[61.405441579707421, 2.035077600707452],
						[61.405424999889071, 2.0350776009083038],
						[61.405424992664962, 2.0350776006445326],
						[61.405424979203318, 2.0350776017765213],
						[61.405424971088109, 2.0350776017121488],
						[61.405405585985541, 2.0350803995075792],
						[61.405383299902027, 2.0350803998604219],
						[61.405366700288951, 2.035080400726565],
						[61.405344399757055, 2.0350804005500622],
						[61.405324999664664, 2.0350804000514064],
						[61.405324991549456, 2.0350803999870304],
						[61.405324976305693, 2.0350804015177908],
						[61.405324968286052, 2.0350804033123202],
						[61.405308283791889, 2.0350831007052155],
						[61.40528610043723, 2.0350831006277712],
						[61.40526670034474, 2.0350831001250467],
						[61.405244399812752, 2.0350830999419141],
						[61.405244391697543, 2.0350830998775398],
						[61.405244374671639, 2.0350831018070719],
						[61.405244366651999, 2.0350831036015933],
						[61.405227766688853, 2.0350859024064039],
						[61.405227722613226, 2.0350859216704533],
						[61.405227648879404, 2.0350859776555779],
						[61.405227619221215, 2.0350860143766538],
						[61.405208173401824, 2.0351275990945719],
						[61.405204319409265, 2.035127600309302],
						[61.404808485928577, 2.0341082277978346],
						[61.404808484846299, 2.0341082242795192],
						[61.404808481695163, 2.0341082155834056],
						[61.404808479721844, 2.034108212264484],
						[61.404311299910752, 2.0330860536155311],
						[61.404300200181147, 2.0304943982969808],
						[61.404300157274719, 2.030494317652435],
						[61.404300020867431, 2.0304942240989701],
						[61.404299929244253, 2.0304942126498671],
						[61.402833229002347, 2.0310554132039931],
						[61.402833200075413, 2.0310554290807366],
						[61.402833150814935, 2.0310554701912999],
						[61.402833129590292, 2.0310554956246079],
						[61.401858130298741, 2.0326387946243614],
						[61.401858119066716, 2.0326388215793809],
						[61.401858103731222, 2.0326388738939736],
						[61.40185809972337, 2.0326389011122097],
						[61.401869200312284, 2.0347111016091652],
						[61.401869199707953, 2.0347111073846276],
						[61.401869201268106, 2.0347111201961519],
						[61.401869201650435, 2.0347111276309313],
						[61.402377602301364, 2.0383250277909841],
						[61.402377609080794, 2.038325054476485],
						[61.4023776313594, 2.0383251021372288],
						[61.402377645171939, 2.0383251253697661],
						[61.403610921307518, 2.0398444970719019],
						[61.404110880822792, 2.041383273975002],
						[61.403136017015221, 2.0429664534495249],
						[61.401908372765334, 2.0424831138143511],
						[61.401908298837114, 2.0424831134163104],
						[61.401908171666555, 2.0424831643991022],
						[61.401908118424259, 2.0424832157799453],
						[61.400935917594268, 2.0445860159270204],
						[61.400935915398207, 2.0445860258176927],
						[61.400935908237415, 2.0445860443375978],
						[61.400935905150284, 2.0445860544273327],
						[61.400455595146219, 2.0466713306721398],
						[61.39945579368699, 2.0425555527584995],
						[61.399455758361896, 2.0425554967352704],
						[61.39945565805121, 2.0425554213957748],
						[61.399455593861269, 2.0425554000217692],
						[61.39847510680017, 2.0425830974319812],
						[61.397489068261756, 2.041058191393851],
						[61.397489031313228, 2.0410581564163026],
						[61.397488944304776, 2.0410581119493041],
						[61.397488894149326, 2.0410581006013797],
						[61.396508382158572, 2.0410858976746069],
						[61.395519559269424, 2.0400776751494876],
						[61.394283476282823, 2.0380416047216308],
						[61.393786279729774, 2.0370193131324972],
						[61.393786230336502, 2.0370192640297629],
						[61.393786112361589, 2.0370192133453431],
						[61.393786043588882, 2.0370192080471852],
						[61.391830770226179, 2.0375941413677183],
						[61.391575201959043, 2.0355306027243136],
						[61.392052996866823, 2.0324083325045512],
						[61.392764097137544, 2.0282444344428154],
						[61.392764089716756, 2.0282443778381505],
						[61.392764047634962, 2.0282442782452867],
						[61.392764011987268, 2.0282442335984561],
						[61.389811212217317, 2.0262609345615283],
						[61.38981119916842, 2.0262609262070437],
						[61.389811174248734, 2.0262609148723993],
						[61.389811161391229, 2.0262609102338209],
						[61.388338961363075, 2.0257859105948368],
						[61.388338894755172, 2.0257859123540194],
						[61.38833877720176, 2.0257859574682473],
						[61.388338727147307, 2.0257860006237967],
						[61.387122082912867, 2.0278942031798821],
						[61.383694394081573, 2.0279941990851076],
						[61.383694327126292, 2.0279942291013686],
						[61.383694230167109, 2.0279943240813698],
						[61.383694200258901, 2.027994390902685],
						[61.383471999994811, 2.0331777906801611],
						[61.383472010690845, 2.0331778408995707],
						[61.383472054868356, 2.0331779287270817],
						[61.383472088349841, 2.0331779663352005],
						[61.384949793877418, 2.0341723021180238],
						[61.384724799828561, 2.0383193887114053],
						[61.384724800879241, 2.0383194091460477],
						[61.384724807436008, 2.0383194490196854],
						[61.384724812051005, 2.0383194686578099],
						[61.385466512072213, 2.0403694685182288],
						[61.385466526966304, 2.0403694952560163],
						[61.385466564583211, 2.0403695432244011],
						[61.385466588292601, 2.0403695661136809],
						[61.386944249735052, 2.0413640396641934],
						[61.388427622906136, 2.0439083939354679],
						[61.389172019533092, 2.0454417862997021],
						[61.389172021601532, 2.0454417914757288],
						[61.389172024561091, 2.0454417964528031],
						[61.389172026629552, 2.0454418016288418],
						[61.390655422846216, 2.0479889931315718],
						[61.391891519680328, 2.0505444879103032],
						[61.391891521653235, 2.0505444912289725],
						[61.391891526585525, 2.050544499525611],
						[61.391891528653801, 2.0505445047024247],
						[61.393127629238627, 2.0525807041296429],
						[61.393127630225059, 2.0525807057890995],
						[61.393127631211506, 2.0525807074485649],
						[61.393127632197924, 2.0525807091080233],
						[61.395108116359481, 2.0556333832427334],
						[61.395855408022129, 2.0582028566646438],
						[61.395855412253404, 2.0582028688790515],
						[61.39585542338925, 2.0582028927119289],
						[61.395855429402708, 2.0582029045290349],
						[61.397091521214769, 2.0602389894400699],
						[61.398330409944144, 2.063313964535971],
						[61.399330407177963, 2.0669139544563273],
						[61.39933041427215, 2.066913969795356],
						[61.399330429446756, 2.066914002133637],
						[61.399330438323197, 2.0669140170757934],
						[61.400810900350535, 2.0689444658625598],
						[61.400830399719794, 2.0725721722129085],
						[61.400108106663772, 2.075183246652319],
						[61.400108105356615, 2.0751832563425032],
						[61.400108101055004, 2.075183277978037],
						[61.400108099842882, 2.0751832895269713],
						[61.399883101720057, 2.0793360518274735],
						[61.399155470737753, 2.0809081025105427],
						[61.397188894706211, 2.0809609008479697],
						[61.397188880352736, 2.0809609021576594],
						[61.397188851740722, 2.0809609066356356],
						[61.397188836591013, 2.080960910001949],
						[61.395477794668061, 2.0815247906222103],
						[61.393755653172448, 2.0810526060778471],
						[61.393755637737918, 2.081052603868442],
						[61.393755608746126, 2.0810526009120331],
						[61.39375559518885, 2.0810526001650533],
						[61.392038894638141, 2.0810998002049468],
						[61.392038865134957, 2.0810998048797198],
						[61.392038807589358, 2.0810998251810839],
						[61.392038782125482, 2.0810998383554464],
						[61.390574898876494, 2.0821748262073774],
						[61.389349917767532, 2.0827248185174447],
						[61.389349914202896, 2.0827248193090555],
						[61.389349907168508, 2.0827248227504218],
						[61.389349902807616, 2.082724825598083],
						[61.387394302619938, 2.0838137244806263],
						[61.387394264927032, 2.0838137629148363],
						[61.387394214361876, 2.083813849305383],
						[61.387394199897081, 2.0838139013735097],
						[61.387405399939155, 2.0869222014938429],
						[61.387405400128856, 2.0869222052099459],
						[61.387405399617094, 2.0869222128398883],
						[61.387405400698, 2.0869222163582175],
						[61.387669201299794, 2.0900222160552704],
						[61.387669212297475, 2.0900222549560628],
						[61.387669248267144, 2.0900223240205236],
						[61.387669274225189, 2.090022355844587],
						[61.388899828143749, 2.0910251171909291],
						[61.389397004894406, 2.0925639477045657],
						[61.38964671677747, 2.0941093668381101],
						[61.388408486048966, 2.0910360241122259],
						[61.388408391868737, 2.0910359623184611],
						[61.388408206295168, 2.0910359640157052],
						[61.388408113119432, 2.0910360279020308],
						[61.387194260614059, 2.0941748047991342],
						[61.3862140439022, 2.094202595637876],
						[61.385219589969687, 2.0911221399304782],
						[61.38521956818343, 2.0911221015458912],
						[61.38521951002965, 2.0911220392840675],
						[61.385219473567339, 2.0911220135488806],
						[61.383991802248524, 2.0906359257246736],
						[61.382758425756521, 2.0896331449120145],
						[61.382758377803484, 2.0896331236076917],
						[61.382758276986578, 2.0896331083915354],
						[61.382758224122732, 2.0896331144797151],
						[61.380066624155347, 2.0907387148374861],
						[61.380066597002767, 2.0907387302511924],
						[61.38006655039586, 2.0907387706435334],
						[61.380066530050307, 2.0907387958197958],
						[61.379088763652483, 2.0923192401885498],
						[61.3763971287187, 2.0934248126046349],
						[61.373213862942769, 2.094541501762762],
						[61.37198609544118, 2.0945748007921003],
						[61.371986093564082, 2.0945747993297896],
						[61.371494440649407, 2.0945886987684537],
						[61.368297283727621, 2.0931192172562145],
						[61.36829719821224, 2.0931192193001196],
						[61.368297058408842, 2.0931192878310525],
						[61.368297005012053, 2.0931193541206201],
						[61.368058115679474, 2.0941610082641393],
						[61.365863978169124, 2.0968023930889879],
						[61.36486409227976, 2.0937277424433769],
						[61.363869593108355, 2.0901332475973846],
						[61.363869577939639, 2.0901332152830618],
						[61.363869537192407, 2.090133158594186],
						[61.363869511613913, 2.0901331342196428],
						[61.362391811764297, 2.0891359339315287],
						[61.362391784080899, 2.0891359212908394],
						[61.362391724637476, 2.0891359044232058],
						[61.36239169465977, 2.0891358998012866],
						[61.360919395229558, 2.0891747994609853],
						[61.360919349874237, 2.0891748113893192],
						[61.360919270104176, 2.0891748553514033],
						[61.360919235594636, 2.0891748855286867],
						[61.360188775584767, 2.0902304283804387],
						[61.3582305439678, 2.0907998078155421],
						[61.3582305123591, 2.0907998242042765],
						[61.358230454166339, 2.0907998671326262],
						[61.358230430350531, 2.0907998949363265],
						[61.356358763754891, 2.0938260417975982],
						[61.356083493843016, 2.0926943524687611],
						[61.356083489615052, 2.0926943402646714],
						[61.356083480173133, 2.0926943141976277],
						[61.356083474068043, 2.092694300532028],
						[61.355216877226269, 2.0911638062366489],
						[61.354722380385034, 2.0901443126776429],
						[61.354722307271182, 2.090144257542931],
						[61.354722147200668, 2.0901442291787604],
						[61.35472206033878, 2.0901442578054255],
						[61.353374860141876, 2.0914720572549812],
						[61.353374843265811, 2.0914720797626463],
						[61.353374815050337, 2.0914721273058983],
						[61.353374804791635, 2.0914721558562284],
						[61.353010905835411, 2.0930332485272123],
						[61.352405407667334, 2.0951166435200035],
						[61.352405407762042, 2.0951166453760042],
						[61.352405406870858, 2.0951166455732508],
						[61.35240540696558, 2.0951166474292502],
						[61.352044260900193, 2.0964191558019696],
						[61.351180704533668, 2.0961831206009083],
						[61.350689079190303, 2.0956776961730115],
						[61.349816891777124, 2.0925999453649951],
						[61.349816887454445, 2.0925999313074768],
						[61.349816875244308, 2.0925999039816197],
						[61.349816868248041, 2.0925998905160004],
						[61.348822368255384, 2.0910748913416866],
						[61.348822324000658, 2.0910748542220108],
						[61.34882222166771, 2.0910748093181803],
						[61.348822164575509, 2.0910748031924631],
						[61.348121322160637, 2.0911984271778969],
						[61.348164094115553, 2.0910000480107547],
						[61.348444593027629, 2.0899750533810519],
						[61.348444593729262, 2.0899750494722009],
						[61.348444595322071, 2.0899750453659691],
						[61.348444595227292, 2.0899750435102229],
						[61.348605795008844, 2.0892528441315212],
						[61.348605798137712, 2.0892528171640192],
						[61.348605795578422, 2.0892527670586278],
						[61.348605790686648, 2.0892527418676035],
						[61.348494591093441, 2.0888860418221684],
						[61.348494572170338, 2.0888860066019768],
						[61.348494518567328, 2.0888859452818789],
						[61.348494485669754, 2.0888859187871911],
						[61.34826118572969, 2.0887748191594548],
						[61.348261117848466, 2.0887748135510025],
						[61.348260994164072, 2.0887748447003571],
						[61.348260936673341, 2.0887748837086617],
						[61.347719236585483, 2.0895331838644005],
						[61.347719235789114, 2.0895331859174795],
						[61.347719232413958, 2.0895331904183561],
						[61.347719230726341, 2.0895331926688021],
						[61.347214077930815, 2.0903326439499192],
						[61.347200200776506, 2.0899167274915209],
						[61.347605783173726, 2.0886556844068522],
						[61.34802236908056, 2.0879973068147559],
						[61.348022374844781, 2.0879972961542714],
						[61.348022385387239, 2.0879972731750032],
						[61.348022390260283, 2.0879972627119372],
						[61.348186283129202, 2.0875056832887191],
						[61.348625171193135, 2.0867751024027577],
						[61.348625176160908, 2.0867750937950391],
						[61.348625185205243, 2.0867750767770765],
						[61.348625189187032, 2.0867750665110658],
						[61.348864072537232, 2.0860973115955228],
						[61.349319537301831, 2.0856668453142557],
						[61.349319550896766, 2.0856668291638853],
						[61.349319574427035, 2.0856667957973847],
						[61.349319584362384, 2.0856667785812135],
						[61.349777984314798, 2.0845889775475652],
						[61.349777985111146, 2.0845889754942308],
						[61.349777988391267, 2.0845889691367021],
						[61.349777989092729, 2.0845889652275691],
						[61.350139084077746, 2.0835611793481017],
						[61.350408477731726, 2.0830417920275179],
						[61.350408484196954, 2.083041777456057],
						[61.350408494359009, 2.0830417470500158],
						[61.350408497259558, 2.0830417332688582],
						[61.350516896945201, 2.0823917333246205],
						[61.350516895748932, 2.0823916922985157],
						[61.350516876613966, 2.082391617712303],
						[61.350516861253872, 2.0823915817035568],
						[61.350364061233542, 2.0821859800347333],
						[61.350363986202424, 2.0821859403653398],
						[61.350363834303955, 2.0821859308779032],
						[61.350363754763144, 2.082185961652653],
						[61.349658155168079, 2.0829248616321587],
						[61.349658154371767, 2.0829248636855242],
						[61.34965815179315, 2.0829248661340274],
						[61.349658150901973, 2.0829248663316022],
						[61.349127651385466, 2.0835192661323494],
						[61.349127644540715, 2.0835192732800412],
						[61.349127633619602, 2.0835192888385605],
						[61.34912762865202, 2.0835192974469154],
						[61.348458129381477, 2.0846276963140244],
						[61.34845812689764, 2.0846277006180238],
						[61.348458121835179, 2.0846277073702977],
						[61.348458120242547, 2.0846277114767773],
						[61.347644238117361, 2.0862942768026742],
						[61.347072099297847, 2.0867970227105301],
						[61.346372137835196, 2.087024809500118],
						[61.346372108900454, 2.0870248252933541],
						[61.346372056757296, 2.0870248631176871],
						[61.34637203373854, 2.0870248888600198],
						[61.346052634276724, 2.0874970873662355],
						[61.346052623639522, 2.0874971084885794],
						[61.346052606915819, 2.0874971516017933],
						[61.346052601815323, 2.0874971752508422],
						[61.345916501702597, 2.0885888752308155],
						[61.345916501190594, 2.0885888828505768],
						[61.345916500261403, 2.0885888999456852],
						[61.345916499749421, 2.0885889075654207],
						[61.345955398800335, 2.0896055764910693],
						[61.345763707333177, 2.090305546869355],
						[61.345763707427942, 2.0903055487249613],
						[61.345763705740353, 2.0903055509752098],
						[61.345763705740353, 2.0903055509752098],
						[61.345499805988787, 2.0913555508142139],
						[61.345499803789004, 2.0913555606840148],
						[61.345499801077082, 2.0913555781734292],
						[61.34549979967376, 2.0913555859903301],
						[61.345488871009998, 2.0915161222926004],
						[61.345263973338533, 2.0914276131544698],
						[61.345263916341096, 2.0914276088839405],
						[61.345263809304107, 2.0914276307037705],
						[61.345263757576987, 2.0914276590442684],
						[61.343674896956287, 2.0930192194946269],
						[61.342566807967316, 2.0933080648212496],
						[61.342319580326603, 2.0927971123497158],
						[61.342319483758892, 2.0927970567905714],
						[61.342319300440792, 2.0927970673395246],
						[61.3423192110168, 2.0927971340393392],
						[61.340860911022808, 2.0969693345155775],
						[61.340860910226283, 2.0969693365680029],
						[61.340860908727926, 2.096969342528197],
						[61.3408609079314, 2.0969693445806219],
						[61.339649808081745, 2.1011332449118614],
						[61.339649805273837, 2.1011332605419279],
						[61.339649800549338, 2.1011332916050813],
						[61.339649799618471, 2.1011333086964887],
						[61.339791499765745, 2.104747196404785],
						[61.339674802220365, 2.1065582636658982],
						[61.3391887088613, 2.1081221411798148],
						[61.3391887052559, 2.1081221588611281],
						[61.33918870081331, 2.108122195488682],
						[61.339188699881575, 2.1081222125796772],
						[61.339322000109746, 2.1101833065240387],
						[61.339344199548208, 2.1158666557018173],
						[61.338977619153646, 2.1166499143283475],
					],
					[
						[61.405863573259126, 2.0367946008691944],
						[61.405872199765497, 2.0367946001897317],
						[61.405872207880719, 2.0367946002545474],
						[61.40587222401561, 2.0367945985252458],
						[61.405872232035279, 2.0367945967311138],
						[61.405888783423933, 2.0367919224504396],
						[61.405908119118948, 2.036833384505822],
						[61.405908155559771, 2.0368334271221982],
						[61.405908245911696, 2.036833484002365],
						[61.405908299918366, 2.0368335001251103],
						[61.405930600373061, 2.0368334996703208],
						[61.405930606706136, 2.0368335001338362],
						[61.405930621058879, 2.0368334988032242],
						[61.405930628187456, 2.0368334972084514],
						[61.405950013950537, 2.0368307999533055],
						[61.405972064806633, 2.0368308008043141],
						[61.405988714292086, 2.0368722753917723],
						[61.405988750828449, 2.0368723198672543],
						[61.405988844235793, 2.0368723835854388],
						[61.405988900024646, 2.0368723993095852],
						[61.406008300133294, 2.0368724008963572],
						[61.406008306370822, 2.0368723995009321],
						[61.406008318941431, 2.0368723985690256],
						[61.406008325178945, 2.0368723971735969],
						[61.406030612557984, 2.0368695998537611],
						[61.406047079668262, 2.0368695994778823],
						[61.406069223404906, 2.0369111932382635],
						[61.40606925975014, 2.0369112339959936],
						[61.406069348924305, 2.0369112854991354],
						[61.40606940016221, 2.0369113003611439],
						[61.406088900231488, 2.0369113002899555],
						[61.406088908346717, 2.0369113003548112],
						[61.406088925372664, 2.0369112984261903],
						[61.406088933392319, 2.0369112966320784],
						[61.406105499323019, 2.036908519476647],
						[61.406130427618287, 2.0369501033845929],
						[61.406130465459, 2.0369501381668602],
						[61.406130550877734, 2.0369501867496584],
						[61.406130600237958, 2.0369502001514985],
						[61.406147199865522, 2.0369502002543061],
						[61.406147206198597, 2.0369502007178459],
						[61.406147218769199, 2.0369501997859647],
						[61.406147225006691, 2.0369501983905529],
						[61.4061692806753, 2.0369474172181752],
						[61.406188719063302, 2.0369889846151024],
						[61.406188756395174, 2.0369890270326829],
						[61.406188845855944, 2.03698908411313],
						[61.40618889986262, 2.0369891002362364],
						[61.406205600437431, 2.0369891003386851],
						[61.406227675545097, 2.0369890996254276],
						[61.406247020652181, 2.0370278881577488],
						[61.406247057983997, 2.0370279305754635],
						[61.406247147349262, 2.0370279857971245],
						[61.406247200369272, 2.0370280002607273],
						[61.406269399878838, 2.0370279999481102],
						[61.406285976943451, 2.0370279994941889],
						[61.406305421301177, 2.0370667903604032],
						[61.406305457550808, 2.037066829259643],
						[61.406305546916023, 2.0370668844815163],
						[61.406305600031615, 2.0370669008041666],
						[61.406327664351593, 2.0370669006745112],
						[61.406344213622255, 2.0371083738138211],
						[61.40634425114515, 2.0371084199496958],
						[61.406344343661353, 2.0371084838685913],
						[61.406344400341247, 2.0371084993939585],
						[61.406363900412146, 2.0371084994348454],
						[61.406363905854136, 2.0371085000977636],
						[61.406363918424745, 2.0371084991659321],
						[61.406363923866728, 2.0371084998288667],
						[61.40638598005313, 2.0371058164023683],
						[61.4064054186805, 2.0371472844594618],
						[61.406405456012287, 2.0371473268775224],
						[61.406405546364113, 2.0371473837594376],
						[61.406405600370768, 2.0371473998829082],
						[61.406427799881463, 2.0371473996479201],
						[61.406427807996678, 2.0371473997128176],
						[61.406427825022632, 2.0371473977843104],
						[61.406427833137855, 2.0371473978492065],
						[61.406444282883847, 2.0371446223253455],
						[61.406463719149841, 2.0371861850263944],
						[61.406463755590529, 2.0371862276439221],
						[61.406463845942334, 2.0371862845260273],
						[61.406463899948982, 2.0371863006496098],
						[61.406486100351124, 2.0371863002402582],
						[61.406502683749942, 2.037186300326562],
						[61.406524826107379, 2.0372250990983791],
						[61.406524863152505, 2.0372251359397193],
						[61.406524950448862, 2.0372251859841364],
						[61.406524999809051, 2.0372251993865289],
						[61.406544264635109, 2.0372251994693853],
						[61.406560913731958, 2.0372667747390292],
						[61.406560955901305, 2.0372668235966658],
						[61.40656106000138, 2.0372668849250277],
						[61.406561122027689, 2.0372668992547283],
						[61.406586110544104, 2.0372640995947213],
						[61.406602680386236, 2.0372640989910424],
						[61.406624823125775, 2.0373056939543952],
						[61.406624860266426, 2.037305732654934],
						[61.406624948644954, 2.0373057862183548],
						[61.406624999787283, 2.0373057992222465],
						[61.406644399803952, 2.0373057991938208],
						[61.406644407919174, 2.0373057992587551],
						[61.406644424945128, 2.0373057973302973],
						[61.40664443306035, 2.0373057973952324],
						[61.406660990885968, 2.0373030203151292],
						[61.406683123744529, 2.0373444934856835],
						[61.406683159994124, 2.0373445323856867],
						[61.406683249263679, 2.0373445857499761],
						[61.406683299610471, 2.0373446008122831],
						[61.406702799683345, 2.0373446009869558],
						[61.406702805920887, 2.0373445995915831],
						[61.40670281760039, 2.0373445988591721],
						[61.406702823933486, 2.0373445993227879],
						[61.406724872106636, 2.037341917812193],
						[61.406741514166001, 2.0373833746044929],
						[61.406741550797754, 2.0373834209405963],
						[61.406741644109331, 2.03738348280273],
						[61.406741699993695, 2.037383500387079],
						[61.406760983724723, 2.0373835000908915],
						[61.406783126032494, 2.0374222993159385],
						[61.406783163077542, 2.0374223361578081],
						[61.406783250373898, 2.0374223862031386],
						[61.40678329973403, 2.0374223996059388],
						[61.406802799807572, 2.0374223998251768],
						[61.406824865021967, 2.0374223997247269],
						[61.406841513969901, 2.0374639736090474],
						[61.40684155712578, 2.0374640241270594],
						[61.406841663994591, 2.0374640867175606],
						[61.406841728598593, 2.0374640985907311],
						[61.406861113673976, 2.0374613002279247],
						[61.406883165331514, 2.0374612994243551],
						[61.406899814268982, 2.0375028734059408],
						[61.406899855547159, 2.0375029224637466],
						[61.406899962415949, 2.0375029850545023],
						[61.406900025237753, 2.0375029973264334],
						[61.406922212578721, 2.0375002001529809],
						[61.40694156390569, 2.0375001998435072],
						[61.406958113667876, 2.0375417735412444],
						[61.406958151190643, 2.0375418196785082],
						[61.406958243706683, 2.0375418835998618],
						[61.40695830038652, 2.037541899126238],
						[61.406980475650869, 2.0375419008104094],
						[61.406999820427529, 2.0375806868842221],
						[61.407019218705059, 2.0376222853808175],
						[61.407019220678286, 2.0376222887001805],
						[61.407019223733705, 2.0376222955382426],
						[61.407019225706961, 2.0376222988575998],
						[61.407041519409283, 2.0376611869110648],
						[61.407058113747553, 2.0377028749513029],
						[61.407058116802972, 2.0377028817893832],
						[61.407058124791462, 2.0377028969258904],
						[61.407058128737965, 2.0377029035646372],
						[61.407083100129206, 2.0377444552108752],
						[61.407083100143915, 2.0377833003693535],
						[61.40708310199043, 2.0377833187602099],
						[61.407083109343297, 2.0377833566036658],
						[61.407083113863031, 2.0377833743965557],
						[61.407099814236695, 2.0378250745584481],
						[61.407099815223326, 2.037825076218152],
						[61.407099818183191, 2.0378250811972216],
						[61.407099819265326, 2.0378250847159345],
						[61.407119219067155, 2.037866784683442],
						[61.407119221040389, 2.0378667880028467],
						[61.407119224191334, 2.0378667966999773],
						[61.407119226069035, 2.0378667981603433],
						[61.407141521774498, 2.037905691691734],
						[61.407160918780484, 2.0379472852026788],
						[61.407160920753711, 2.0379472885220751],
						[61.407160923809116, 2.0379472953602029],
						[61.407160925782343, 2.0379472986795961],
						[61.407170026647989, 2.0379632451443537],
						[61.406544500050913, 2.0382025488764497],
						[61.405863573259126, 2.0367946008691944],
					],
					[
						[61.409464798881856, 2.043339542234897],
						[61.409469200223498, 2.0433472520392701],
						[61.409469199880164, 2.043359900529448],
						[61.409464798881856, 2.043339542234897],
					],
					[
						[61.409480875713612, 2.0434139131878886],
						[61.40948870019632, 2.0434306440736569],
						[61.409488699986369, 2.0434501056875973],
						[61.409480875713612, 2.0434139131878886],
					],
					[
						[61.409497780980956, 2.0434921139529933],
						[61.409505399733831, 2.0435111399635231],
						[61.409505399777643, 2.0435273595005605],
						[61.409497780980956, 2.0434921139529933],
					],
					[
						[61.409513706344875, 2.0435657836209709],
						[61.409530420942936, 2.0435917917170419],
						[61.409546999679982, 2.0436333389350181],
						[61.409547000079549, 2.0436750000938622],
						[61.409547003198263, 2.0436750257241609],
						[61.409547015673247, 2.0436750755904818],
						[61.409547025920617, 2.0436750996273179],
						[61.409569199611532, 2.043713953922309],
						[61.409569200114177, 2.0437556000397201],
						[61.409569202150813, 2.0437556221508442],
						[61.409569212366272, 2.0437556631196325],
						[61.409569218858337, 2.0437556842348745],
						[61.409588699557844, 2.0437972448728172],
						[61.40958869962153, 2.0438389005131063],
						[61.409588702549257, 2.043838922425087],
						[61.409588712955625, 2.0438389671123081],
						[61.409588721325356, 2.0438389896883908],
						[61.409608100114141, 2.0438778464067711],
						[61.409608100345032, 2.0439194004830528],
						[61.4096081033682, 2.0439194242542285],
						[61.409608114761113, 2.0439194706015384],
						[61.409608124117355, 2.0439194948376951],
						[61.409630400039873, 2.0439611492001282],
						[61.409630400200108, 2.0439999991105551],
						[61.409630402236729, 2.044000021221736],
						[61.409630411656494, 2.0440000642490759],
						[61.409630418944175, 2.0440000833060212],
						[61.409649800120064, 2.0440417435118419],
						[61.409649800397077, 2.0440832995061791],
						[61.409649802338208, 2.0440833197581898],
						[61.409649808702852, 2.0440833559462939],
						[61.409649814112903, 2.0440833735424184],
						[61.409666514146764, 2.0441250745743691],
						[61.409666518188381, 2.0441250830736926],
						[61.409666527162777, 2.0441250998731491],
						[61.409666532095514, 2.0441251081732941],
						[61.409691500427108, 2.044163958852546],
						[61.409691499616649, 2.0442055997639903],
						[61.409691502448858, 2.0442056198168679],
						[61.409691508813481, 2.0442056560050306],
						[61.409691514223475, 2.0442056736012058],
						[61.409708099972782, 2.0442472385649899],
						[61.409708099734253, 2.0442860999320027],
						[61.409708102757385, 2.0442861237033103],
						[61.409708115136674, 2.0442861717109477],
						[61.409708124397412, 2.0442861940880763],
						[61.409730403268291, 2.0443278556882434],
						[61.409733100234554, 2.0443694127022569],
						[61.409733102971281, 2.0443694308959746],
						[61.409733110927107, 2.044369462967496],
						[61.409733116241661, 2.044369478704517],
						[61.409749800179299, 2.0444083402063731],
						[61.409749800249564, 2.0444499997926706],
						[61.4097498022861, 2.0444500219039718],
						[61.409749811705701, 2.0444500649316057],
						[61.409749818993333, 2.0444500839887327],
						[61.409769200037601, 2.0444917446381363],
						[61.409769200179163, 2.0445333008007194],
						[61.409769203297685, 2.0445333264312895],
						[61.409769216568016, 2.0445333742399576],
						[61.409769225924158, 2.044533398476426],
						[61.409791500329732, 2.0445722536694735],
						[61.409791499682314, 2.0446139003003552],
						[61.40979150171885, 2.044613922411695],
						[61.40979151193401, 2.0446139633810803],
						[61.409791519317082, 2.0446139842974724],
						[61.409810919439408, 2.044655684084002],
						[61.409810921412515, 2.0446556874041022],
						[61.409810923671941, 2.0446556963018399],
						[61.409810925645019, 2.0446556996219432],
						[61.409833100153577, 2.0446944531580993],
						[61.409833100360565, 2.0447360996478476],
						[61.409833102397052, 2.0447361217592315],
						[61.409833111816617, 2.0447361647870776],
						[61.409833119199654, 2.044736185703532],
						[61.409852600025616, 2.0447778431306824],
						[61.409852600338013, 2.0448167007702374],
						[61.409852602183612, 2.0448167191632187],
						[61.409852608548043, 2.0448167553517091],
						[61.409852614053428, 2.0448167748072903],
						[61.409869200419216, 2.044858338259369],
						[61.409869199558038, 2.0449000000726771],
						[61.409869203663042, 2.0449000273634441],
						[61.409869219988295, 2.0449000820118268],
						[61.409869232113103, 2.0449001075102444],
						[61.409894200235762, 2.0449389590668701],
						[61.409894200082569, 2.0449806000655428],
						[61.409894201928161, 2.0449806184585362],
						[61.409894209279081, 2.0449806563071933],
						[61.409894213893352, 2.0449806759619795],
						[61.409910913760392, 2.0450222743593534],
						[61.40991091681542, 2.0450222811987961],
						[61.4099109228301, 2.045022293018425],
						[61.40991092588515, 2.0450222998578602],
						[61.409933121806084, 2.0450611918956363],
						[61.409952618799061, 2.0451028855193232],
						[61.409952618799061, 2.0451028855193232],
						[61.409952619785606, 2.0451028871793917],
						[61.409952620772124, 2.045102888839466],
						[61.40997202120743, 2.0451417889055801],
						[61.409972022193976, 2.0451417905656513],
						[61.409972023180515, 2.0451417922257229],
						[61.40997202416704, 2.0451417938858008],
						[61.409994218010098, 2.0451833851352763],
						[61.410010899900492, 2.0452250385546042],
						[61.410010900278685, 2.0452639001183441],
						[61.410010904383583, 2.0452639274092954],
						[61.410010917844566, 2.0452639789370961],
						[61.410010928091651, 2.0452640029748386],
						[61.41003591993983, 2.0453056893136985],
						[61.410052613602481, 2.0453472743969017],
						[61.410052615575545, 2.0453472777170747],
						[61.410052618725999, 2.0453472864158004],
						[61.410052620699076, 2.0453472897359868],
						[61.410072020972933, 2.0453861882203004],
						[61.410072021959493, 2.045386189880384],
						[61.410072023041479, 2.0453861933997097],
						[61.410072023041479, 2.0453861933997097],
						[61.410094218308231, 2.0454278842926024],
						[61.410110914004036, 2.0454694746988773],
						[61.410110918045604, 2.0454694831984916],
						[61.410110927019836, 2.0454694999985819],
						[61.410110931952502, 2.0454695082990551],
						[61.410135921340604, 2.0455083915555363],
						[61.410152613669986, 2.0455500749553845],
						[61.410152615643035, 2.0455500782755784],
						[61.410152618793525, 2.0455500869743437],
						[61.410152620671134, 2.0455500884353093],
						[61.41017202098601, 2.0455889890296306],
						[61.410172021972535, 2.0455889906897333],
						[61.410172022959067, 2.0455889923498316],
						[61.410172023054521, 2.0455889942090613],
						[61.410194222209157, 2.0456306920199907],
						[61.410213716832359, 2.0456694813692953],
						[61.410230399663668, 2.0457111388635476],
						[61.4102304003301, 2.0457528007785242],
						[61.410230403448402, 2.0457528264096059],
						[61.410230415827222, 2.0457528744186462],
						[61.410230426074243, 2.0457528984567177],
						[61.410252621799302, 2.0457917914122614],
						[61.410272018812996, 2.0458333859795252],
						[61.410272019799528, 2.0458333876396382],
						[61.41027202186801, 2.0458333928191181],
						[61.410272022854528, 2.0458333944792342],
						[61.410294221938635, 2.0458750925982461],
						[61.41031371709866, 2.0459139817609509],
						[61.410330414242786, 2.0459556739183129],
						[61.410330417202367, 2.0459556788986841],
						[61.410330421339339, 2.0459556892576654],
						[61.410330424298927, 2.0459556942380397],
						[61.410352621758761, 2.0459972913261844],
						[61.410372020871264, 2.0460361889293353],
						[61.410372021857782, 2.0460361905894784],
						[61.410372022844314, 2.0460361922495998],
						[61.41037202293974, 2.046036194108845],
						[61.410394221965596, 2.046077892505525],
						[61.410413717071243, 2.0461167819195332],
						[61.410430399965939, 2.0461583383281394],
						[61.410430399607982, 2.0462000007185726],
						[61.410430403712745, 2.0462000280100097],
						[61.410430420037478, 2.046200082660075],
						[61.41043043216203, 2.0462001081594661],
						[61.410455421355266, 2.0462389923972317],
						[61.410472014211678, 2.0462806743515252],
						[61.410472017171244, 2.0462806793319319],
						[61.410472023281244, 2.0462806930112958],
						[61.41047202633623, 2.0462806998509584],
						[61.410494200314403, 2.046319453505542],
						[61.410494200035899, 2.046361100911319],
						[61.410494201976739, 2.0463611211640296],
						[61.410494212286878, 2.046361163994189],
						[61.410494218778567, 2.0463611851105976],
						[61.410513700024822, 2.0464028442434632],
						[61.410513700391689, 2.046444399378637],
						[61.410513703414438, 2.0464444231507746],
						[61.410513712928868, 2.0464444680393639],
						[61.410513721202761, 2.046444488757567],
						[61.41053310008202, 2.0464833462019159],
						[61.410533100040304, 2.0465249992407433],
						[61.410533103158471, 2.0465250248721598],
						[61.410533115441595, 2.0465250710227276],
						[61.410533123810872, 2.0465250936002506],
						[61.41055542176462, 2.0465667911129741],
						[61.410574799624243, 2.0466056470257898],
						[61.410574799834826, 2.0466472003872189],
						[61.410574802857532, 2.0466472241594231],
						[61.410574815140606, 2.0466472703101055],
						[61.410574822714217, 2.0466472949460548],
						[61.410597000368746, 2.0466889498924354],
						[61.410596999670652, 2.0467277991995969],
						[61.410597001706854, 2.0467278213116629],
						[61.410597012016879, 2.0467278641420741],
						[61.410597019399582, 2.0467278850595285],
						[61.410616516102614, 2.0467694790252473],
						[61.410633100338785, 2.0468111370455144],
						[61.410633100274367, 2.0468499994148015],
						[61.410633103297052, 2.0468500231870861],
						[61.410633114784382, 2.0468500713962863],
						[61.410633124044715, 2.0468500937748662],
						[61.410655399914411, 2.04689174955422],
						[61.410655399848132, 2.0469332993103126],
						[61.410655402870802, 2.0469333230826083],
						[61.410655413276174, 2.0469333677724562],
						[61.410655420658891, 2.0469333886899914],
						[61.410674800296377, 2.0469722464192732],
						[61.410674800107401, 2.0470138996573053],
						[61.410674803130085, 2.047013923429613],
						[61.410674814617359, 2.0470139716389597],
						[61.410674822986564, 2.0470139942167029],
						[61.410697021632828, 2.0470556918611913],
						[61.410716499609777, 2.0470944468743899],
						[61.410716500275235, 2.0471360999715054],
						[61.410716502311338, 2.0471361220836957],
						[61.410716511634739, 2.0471361632542338],
						[61.410716519017434, 2.0471361841718561],
						[61.410735899980999, 2.0471778438806849],
						[61.410735900114979, 2.0472193993276462],
						[61.41073590332843, 2.0472194268185926],
						[61.410735915706745, 2.0472194748290184],
						[61.41073592595351, 2.0472194988679537],
						[61.41075811911908, 2.0472583864637404],
						[61.410774803268119, 2.047300045150771],
						[61.410777599592677, 2.0473417131134135],
						[61.410777604397452, 2.0473417364876902],
						[61.410777617380589, 2.0473417787212913],
						[61.410777625654305, 2.047341799439927],
						[61.410799799947782, 2.0473806537722368],
						[61.410799800434965, 2.0474221998129924],
						[61.410799802280224, 2.0474222182067008],
						[61.410799808739384, 2.0474222562561346],
						[61.410799814149009, 2.0474222738535017],
						[61.410816513906255, 2.0474639743222998],
						[61.410816515879269, 2.0474639776426873],
						[61.410816519029595, 2.0474639863418425],
						[61.410816521002609, 2.0474639896622304],
						[61.41083590040062, 2.0475028460663838],
						[61.410835900341667, 2.047544399793912],
						[61.410835903364223, 2.047544423566428],
						[61.410835914851305, 2.0475444717762104],
						[61.410835923220461, 2.0475444943542227],
						[61.410858099723065, 2.0475861504929425],
						[61.410858099717096, 2.0476204695658153],
						[61.410014089694847, 2.0458805335599095],
						[61.409513706344875, 2.0435657836209709],
					],
				],
				[
					[61.306113706074285, 2.0348361461030549],
					[61.306735904740336, 2.0373889446927786],
					[61.307110899828601, 2.0391778210905938],
					[61.30711650012482, 2.0404639012406567],
					[61.307116500697333, 2.04046391235932],
					[61.307116503719953, 2.0404639360528987],
					[61.307116506074628, 2.0404639467747092],
					[61.307613702286467, 2.0425056317817134],
					[61.307744201155643, 2.0443028133961985],
					[61.30774421423299, 2.0443028573453828],
					[61.307744256841801, 2.0443029322097219],
					[61.307744288250809, 2.044302964581405],
					[61.308108100284898, 2.0445501061217364],
					[61.308119199994863, 2.0473778012300743],
					[61.308133100351384, 2.0494333015731603],
					[61.308135899772324, 2.0509778003682424],
					[61.308135899772324, 2.0509778003682424],
					[61.308144199575189, 2.0527777815884156],
					[61.307908102811275, 2.0540693644713035],
					[61.307908102906516, 2.0540693663245104],
					[61.307908102205836, 2.0540693702289801],
					[61.307908102396318, 2.0540693739354139],
					[61.307669202221852, 2.0558749732368802],
					[61.307669201201271, 2.0558749884585179],
					[61.307669200942364, 2.0558750185057848],
					[61.307669203391058, 2.0558750310822198],
					[61.30792199688031, 2.0574110989381436],
					[61.307683103245246, 2.0587055646540313],
					[61.307683101843665, 2.0587055724627179],
					[61.307683100121977, 2.0587055915886356],
					[61.307683099706665, 2.0587056010526426],
					[61.307688699866155, 2.0602471926026791],
					[61.307458100902274, 2.0633388846285734],
					[61.307458100201366, 2.0633388885327881],
					[61.307458099690635, 2.0633388961434682],
					[61.307458099975953, 2.0633389017031387],
					[61.307472000282452, 2.0664250002822069],
					[61.307471999581473, 2.0664250041863776],
					[61.307471999961727, 2.066425011599303],
					[61.307472000947911, 2.0664250132548445],
					[61.307602600703781, 2.0682222147258362],
					[61.307602600893858, 2.0682222184323451],
					[61.307602602070091, 2.0682222237944443],
					[61.307602602260175, 2.0682222275009341],
					[61.307852601737096, 2.0700139280650167],
					[61.307852602913322, 2.0700139334272363],
					[61.307852605170702, 2.0700139422983974],
					[61.30785260634687, 2.0700139476606081],
					[61.308102605889715, 2.0710361489261975],
					[61.308102609038158, 2.0710361575999432],
					[61.308102616416242, 2.0710361784564255],
					[61.308102619564714, 2.0710361871301624],
					[61.308594203864295, 2.072050054950064],
					[61.308719201304314, 2.0733333193593468],
					[61.308719206008575, 2.0733333408093291],
					[61.308719219872891, 2.0733333827217377],
					[61.308719229032938, 2.0733334031841695],
					[61.309333118554846, 2.0743444865379388],
					[61.309708105704146, 2.0753639502135561],
					[61.309830402377195, 2.0761306290061099],
					[61.310080400395357, 2.0779250136143395],
					[61.310088699775982, 2.079466700579649],
					[61.310097000394443, 2.0815250005156218],
					[61.310105400060486, 2.0828111005161252],
					[61.310108100382465, 2.0840971990596939],
					[61.310108139689909, 2.0840972803343374],
					[61.31010826803945, 2.084097375644085],
					[61.310108356285184, 2.0840973917298635],
					[61.31108613107137, 2.083811298534628],
					[61.31230560512644, 2.0837779998565402],
					[61.312305632847703, 2.0837779955955926],
					[61.312305685142526, 2.0837779783974937],
					[61.312305710702098, 2.0837779671166952],
					[61.313519510909281, 2.082972366449714],
					[61.313519517849002, 2.0829723611641802],
					[61.313519530837219, 2.082972350790433],
					[61.313519537776898, 2.0829723455049058],
					[61.315464038204738, 2.0811168451430273],
					[61.315464043267141, 2.0811168383976661],
					[61.315464053581699, 2.0811168286144892],
					[61.315464058644103, 2.0811168218691245],
					[61.316069501328307, 2.0803279960898098],
					[61.317047204841934, 2.0803001999871369],
					[61.318511105359669, 2.0802613002135799],
					[61.31851110625081, 2.0802613000161929],
					[61.320461105656761, 2.0802057995915875],
					[61.321683305309485, 2.0801724007412208],
					[61.321683330262147, 2.0801723952134137],
					[61.32168337918143, 2.0801723825010856],
					[61.321683402067102, 2.0801723718057303],
					[61.323508356672001, 2.0790918984448661],
					[61.324241692333295, 2.07907240027372],
					[61.326194378805603, 2.0792751995237744],
					[61.326194386029826, 2.0792751997983063],
					[61.326194398695939, 2.0792752007422997],
					[61.326194405825255, 2.0792751991624283],
					[61.327780605988785, 2.0792279999304095],
					[61.328755460901178, 2.0792030036871143],
					[61.329124812224229, 2.080222267565055],
					[61.329124858226614, 2.0802223211412607],
					[61.329124973286177, 2.0802223856727999],
					[61.329125043139584, 2.0802223945761296],
					[61.330466724868323, 2.0799251998976018],
					[61.331933230748511, 2.0798863016774209],
					[61.332541554054899, 2.0803834396656842],
					[61.334013738093439, 2.0824001182550806],
					[61.334013739970658, 2.0824001197150235],
					[61.334013742928775, 2.0824001246872283],
					[61.334013744805979, 2.0824001261471738],
					[61.335244245247814, 2.083911226286157],
					[61.335244260455077, 2.0839112416767729],
					[61.335244292367349, 2.0839112664983177],
					[61.335244310153278, 2.0839112794416916],
					[61.335730509940191, 2.0841557793616201],
					[61.335730542876028, 2.0841557889491207],
					[61.33573060906977, 2.0841557967968805],
					[61.335730643218874, 2.0841557948597109],
					[61.337069275993599, 2.0838613327784623],
					[61.337566513804518, 2.0851333729523964],
					[61.337566570783892, 2.0851334297459259],
					[61.337566708727614, 2.0851334836131588],
					[61.337566788705928, 2.0851334790291993],
					[61.33866118912816, 2.0845890797367037],
					[61.338661202211206, 2.0845890712097259],
					[61.338661224812647, 2.0845890549455204],
					[61.338661237004537, 2.0845890466159904],
					[61.339511236968356, 2.0837918454607407],
					[61.339511248875645, 2.0837918315650574],
					[61.339511271609304, 2.0837918002607605],
					[61.339511280748212, 2.0837917851022811],
					[61.339875142776755, 2.0830112655429933],
					[61.340849873033811, 2.0827252455531124],
					[61.341469211115928, 2.0845111669047682],
					[61.34146923557671, 2.0845112046404854],
					[61.341469302833545, 2.0845112685439777],
					[61.341469342766352, 2.0845112915935231],
					[61.34305554336153, 2.084980791706609],
					[61.343055575121632, 2.0849807959279665],
					[61.343055638964003, 2.0849807930407569],
					[61.343055671141052, 2.0849807877876083],
					[61.344516738787938, 2.0844251993724767],
					[61.344761105153488, 2.0844195994742072],
					[61.344761156556871, 2.0844195824532634],
					[61.344761246450567, 2.0844195250016178],
					[61.34476128217247, 2.084419483307888],
					[61.345000129735233, 2.0838973979492681],
					[61.346344404685517, 2.0838613003582478],
					[61.346344413597244, 2.0838612983830567],
					[61.346344430624328, 2.083861296485817],
					[61.346344439630919, 2.0838612963662193],
					[61.347808340335895, 2.0835612964533032],
					[61.347808377385569, 2.0835612807339077],
					[61.347808441719437, 2.0835612345693475],
					[61.347808469989658, 2.0835612057823285],
					[61.348777970369397, 2.081989005301462],
					[61.348777977630803, 2.0819889886772458],
					[61.348777991357302, 2.0819889574821304],
					[61.348777995850334, 2.0819889395949382],
					[61.349255793732553, 2.0796583502923358],
					[61.349980790618439, 2.0773194599178302],
					[61.349980793007056, 2.0773194537572475],
					[61.349980795110831, 2.0773194420293057],
					[61.349980796608286, 2.0773194360664782],
					[61.35045579488385, 2.0747306438715296],
					[61.351055793905616, 2.0723944504989191],
					[61.351055794606701, 2.0723944465893771],
					[61.351055796008907, 2.0723944387702722],
					[61.351055796710028, 2.0723944348607239],
					[61.351391257028361, 2.0704480346137761],
					[61.351410900366652, 2.0711138976690258],
					[61.351316500056434, 2.0725777864289658],
					[61.351316500151469, 2.0725777882848302],
					[61.351316500436525, 2.0725777938523704],
					[61.351316499640383, 2.0725777959061],
					[61.351283099928892, 2.0743193969512923],
					[61.35128310011887, 2.0743194006629975],
					[61.351283099607727, 2.0743194082842455],
					[61.351283099892704, 2.0743194138518217],
					[61.351396999846052, 2.0759639068857068],
					[61.351377600435136, 2.0781693859700558],
					[61.350974801618982, 2.0811277728341691],
					[61.350974800690771, 2.0811277899322147],
					[61.350974802398994, 2.0811278233377508],
					[61.350974804144251, 2.0811278398428965],
					[61.351202603652354, 2.0822583397739982],
					[61.351202700566411, 2.0822584196383729],
					[61.351202896226944, 2.0822584212994277],
					[61.35120299497337, 2.0822583430961341],
					[61.351661294152152, 2.0802250462089567],
					[61.35240019193342, 2.0774194546399056],
					[61.352733491316712, 2.0763417597062639],
					[61.352733492112904, 2.0763417576525138],
					[61.352733492719146, 2.0763417518868943],
					[61.352733494406479, 2.0763417496353802],
					[61.353061290333414, 2.0750778640814755],
					[61.353336283695285, 2.0744250774892596],
					[61.353336290064576, 2.0744250610586743],
					[61.353336297266154, 2.0744250256726575],
					[61.353336299975808, 2.0744250081774838],
					[61.353402999042444, 2.0729167151719197],
					[61.353636298639302, 2.0708889236148056],
					[61.353636299245323, 2.0708889178489045],
					[61.353636300362297, 2.0708889044611523],
					[61.35363630007717, 2.070888898893203],
					[61.35360580002196, 2.0677110973148811],
					[61.353605799641613, 2.0677110898910098],
					[61.353605797894666, 2.0677110733853654],
					[61.353605796623192, 2.0677110661595264],
					[61.35319739738425, 2.0653332662290058],
					[61.353197396302846, 2.0653332627152508],
					[61.353197394044997, 2.065333253831787],
					[61.353197392868481, 2.0653332484621028],
					[61.352858382511513, 2.0640570322258904],
					[61.353089092687213, 2.062805655291017],
					[61.353691886690392, 2.0612444715038882],
					[61.353691891370957, 2.0612444573229327],
					[61.353691897167494, 2.061244429753911],
					[61.353691899079479, 2.0612444143116844],
					[61.353805798897611, 2.0596944152234502],
					[61.354039096969075, 2.0568528446573677],
					[61.354522388019447, 2.0555500702479961],
					[61.354522391107622, 2.0555500601745735],
					[61.354522396392888, 2.0555500402261075],
					[61.354522397698851, 2.0555500305494632],
					[61.354758496936526, 2.0540000377147178],
					[61.355116895012237, 2.0524417454043107],
					[61.355475191840824, 2.0508861586960214],
					[61.355961284986918, 2.0495833776316306],
					[61.356564030025936, 2.0482779954478878],
					[61.357419406355525, 2.0482529990700273],
					[61.358272205791138, 2.0482279996817052],
					[61.358272219253024, 2.0482279985584215],
					[61.358272246081434, 2.0482279944556967],
					[61.358272259447958, 2.0482279914762542],
					[61.360099982810247, 2.0476613152831584],
					[61.36107485038027, 2.0481473493625768],
					[61.361447012726288, 2.0489083736519715],
					[61.362063708910043, 2.0509528588511552],
					[61.362063709991773, 2.0509528623653712],
					[61.362063712155276, 2.0509528693938162],
					[61.362063714128176, 2.0509528727094377],
					[61.362560913742797, 2.0522278728310122],
					[61.362560952246312, 2.0522279205820482],
					[61.362561048319328, 2.052227983670877],
					[61.362561105984085, 2.0522280008651284],
					[61.364513906272336, 2.0521695999006995],
					[61.364513925972012, 2.0521695973887328],
					[61.364513964289657, 2.0521695888502762],
					[61.364513981921213, 2.0521695811657819],
					[61.365730645703628, 2.051619598305741],
					[61.366705512427977, 2.0515919034967265],
					[61.367808149602318, 2.0528473325631946],
					[61.367808185179889, 2.0528473584387643],
					[61.367808262606246, 2.0528473918905421],
					[61.367808305346109, 2.0528473992681837],
					[61.3684194047704, 2.0528308006178744],
					[61.368419405661498, 2.0528308004192954],
					[61.369030605889499, 2.052811300676336],
					[61.369883306149269, 2.0527862999096231],
					[61.369883376580241, 2.0527862673101258],
					[61.36988347387787, 2.0527861611110123],
					[61.369883499853458, 2.0527860877099702],
					[61.369750199835693, 2.0504721946282167],
					[61.369744600331828, 2.0489249992928098],
					[61.369736299830798, 2.0468638982100957],
					[61.369736299735408, 2.0468638963532433],
					[61.369736300435754, 2.046863892440804],
					[61.369736300340364, 2.0468638905839702],
					[61.369603000225815, 2.0442888906601477],
					[61.369602997584835, 2.0442888743464174],
					[61.369602990616123, 2.0442888439734608],
					[61.369602987179469, 2.0442888297154038],
					[61.368736290267243, 2.0419943366613449],
					[61.368366899819705, 2.0407166702152248],
					[61.368361554840035, 2.03948658906108],
					[61.368405415645157, 2.0395889793331623],
					[61.36840542156456, 2.0395889892797978],
					[61.368405435376523, 2.0395890124886282],
					[61.368405443173565, 2.039589023894083],
					[61.370135943222479, 2.0417973232712749],
					[61.370135962189671, 2.0417973415751973],
					[61.370136004388684, 2.0417973734747856],
					[61.370136027620418, 2.0417973870704684],
					[61.371566563349162, 2.0423501616178252],
					[61.372322008398996, 2.0436361668387608],
					[61.372680402149051, 2.0461278278255755],
					[61.372680402244441, 2.0461278296825869],
					[61.372680403326356, 2.0461278331978074],
					[61.372680402530641, 2.0461278352536274],
					[61.37315540262702, 2.0487333353356454],
					[61.373155404886042, 2.048733344223419],
					[61.373155411281665, 2.0487333634585374],
					[61.373155414431778, 2.0487333721475758],
					[61.373797013798288, 2.0503833728140175],
					[61.373797100865957, 2.050383436047432],
					[61.373797283443103, 2.0503834460523711],
					[61.373797377075071, 2.0503833913641758],
					[61.37436407691235, 2.049294492048364],
					[61.374364084869782, 2.04929447148981],
					[61.37436409632916, 2.0492944313663566],
					[61.374364099735736, 2.0492944099443364],
					[61.374511299575595, 2.0461917086522932],
					[61.374511300371303, 2.0461917065963515],
					[61.374511300085103, 2.0461917010249828],
					[61.374511299989713, 2.0461916991678648],
					[61.374497400472364, 2.0439278445963569],
					[61.374833481355502, 2.0432028843220675],
					[61.374833482151146, 2.0432028822660362],
					[61.374833483742428, 2.0432028781539726],
					[61.374833485429207, 2.0432028758990239],
					[61.375622383758476, 2.0412722787393647],
					[61.37651958329522, 2.0392194800633243],
					[61.376519588673332, 2.039219461955176],
					[61.376519598347457, 2.0392194222234696],
					[61.376519600065748, 2.039219403054243],
					[61.376561299623518, 2.0369556049444992],
					[61.376561296217396, 2.0369555737702161],
					[61.376561276834515, 2.0369555123518639],
					[61.376561263722067, 2.0369554852248948],
					[61.375986264114267, 2.0361387842954422],
					[61.375986209947818, 2.0361387475580117],
					[61.375986090859598, 2.0361387102998387],
					[61.375986025237793, 2.0361387136925511],
					[61.374386123129561, 2.0367830741606081],
					[61.372836230658955, 2.0357581478980933],
					[61.37133904931968, 2.0341359672128636],
					[61.369727964597388, 2.0322804843186133],
					[61.369144607953054, 2.0312249632100619],
					[61.369583498265712, 2.0284722316994852],
					[61.36958349899583, 2.0284722108765814],
					[61.369583496987445, 2.0284721718846677],
					[61.369583494153304, 2.0284721518588995],
					[61.368997394252631, 2.0261082525701557],
					[61.368997380946816, 2.0261082217408286],
					[61.368997344820578, 2.0261081678451256],
					[61.368997322795551, 2.026108142722681],
					[61.367964022715888, 2.0253054415040044],
					[61.367963973959021, 2.0253054223637683],
					[61.367963873233791, 2.0253054092165552],
					[61.367963821265477, 2.0253054152095542],
					[61.366763821457489, 2.025819216552645],
					[61.366763796095185, 2.0258192316163117],
					[61.366763751195222, 2.0258192698305377],
					[61.36676373165762, 2.0258192929810845],
					[61.365747032049406, 2.0273998911214042],
					[61.365747031158357, 2.0273998913206843],
					[61.365747031254017, 2.0273998931772068],
					[61.36574703036294, 2.0273998933764941],
					[61.364841529733894, 2.028858194713453],
					[61.364841523179138, 2.0288582074462025],
					[61.364841511151972, 2.0288582364254033],
					[61.364841505583904, 2.028858250815389],
					[61.364678980910071, 2.0295069263757242],
					[61.363922342615496, 2.0287331604583607],
					[61.36392230900713, 2.0287331379278863],
					[61.363922234440246, 2.0287331076549644],
					[61.363922194372776, 2.0287330997133042],
					[61.362822315984609, 2.0287664954596409],
					[61.361844586815479, 2.0269916255004374],
					[61.361222394702786, 2.0246916535925528],
					[61.360847396083557, 2.0228999593942345],
					[61.360847359764911, 2.0228999018033327],
					[61.36084725915839, 2.022899821044041],
					[61.360847194075248, 2.0228997999312193],
					[61.360113973390391, 2.0229219982059146],
					[61.359261240945266, 2.0221748571755991],
					[61.357911264672985, 2.0206692858416755],
					[61.356433476640333, 2.0178777054913453],
					[61.356433458273969, 2.0178776814423549],
					[61.356433413713297, 2.0178776388515645],
					[61.356433389396926, 2.0178776217667314],
					[61.354477918834135, 2.0169081359505956],
					[61.353989063316064, 2.0164081818165673],
					[61.352633492604973, 2.0138721366973704],
					[61.352383498414106, 2.012077771310214],
					[61.35238348876544, 2.0120777415602373],
					[61.35238346065357, 2.0120776859120442],
					[61.352383443081479, 2.0120776598142061],
					[61.350669562340059, 2.0103248791972508],
					[61.349686274843748, 2.0085527036431832],
					[61.349686261218679, 2.008552684172789],
					[61.349686228718248, 2.0085526482856308],
					[61.34968621082983, 2.0085526335246686],
					[61.348216811037865, 2.0075665344135305],
					[61.348216773259082, 2.0075665184810076],
					[61.348216694109475, 2.0075665043131461],
					[61.348216652738707, 2.0075665060778265],
					[61.34699715272064, 2.0078637061210007],
					[61.346997115777022, 2.0078637237844013],
					[61.346997051567257, 2.0078637719571084],
					[61.34699702519211, 2.0078638022666997],
					[61.346272049810757, 2.0091720588680535],
					[61.345422164774575, 2.0097136846123975],
					[61.343586147666876, 2.0092554061380961],
					[61.343586144006885, 2.0092554050814235],
					[61.34358613579586, 2.0092554031676904],
					[61.343586132135883, 2.0092554021110147],
					[61.342244506868099, 2.0090387151498001],
					[61.341627955345025, 2.0082859727821938],
					[61.341627944968387, 2.008285963847964],
					[61.341627923132314, 2.0082859424690738],
					[61.34162791186467, 2.0082859337344683],
					[61.340897312160791, 2.00779153344557],
					[61.340897279028965, 2.0077915202277015],
					[61.340897209680733, 2.0077915038653833],
					[61.340897174451221, 2.0077915023762762],
					[61.338333307268158, 2.0081303970895767],
					[61.33723333999621, 2.0079054032086678],
					[61.337233279118664, 2.0079054112173091],
					[61.337233171659818, 2.0079054596806523],
					[61.33723312686061, 2.0079054997361254],
					[61.336630454025439, 2.0089526536290467],
					[61.335655502125903, 2.009499825929304],
					[61.335655489048023, 2.0094998344864941],
					[61.335655463879199, 2.0094998532559685],
					[61.335655452775136, 2.0094998651234084],
					[61.33444155322168, 2.0108248646471241],
					[61.334441543008495, 2.0108248763141208],
					[61.334441526337976, 2.0108249025593432],
					[61.334441518098586, 2.0108249175365471],
					[61.33420270401021, 2.0113469297612969],
					[61.333222323200914, 2.0108609380227191],
					[61.331875149082101, 2.009358166069704],
					[61.331875090742265, 2.0093581359868833],
					[61.33187496796058, 2.0093581147058961],
					[61.331874901832549, 2.0093581257612092],
					[61.330413878361696, 2.0101747838238757],
					[61.328944461138342, 2.0097054084293955],
					[61.32894443937014, 2.0097054057998514],
					[61.328944394942702, 2.0097054007402519],
					[61.328944373366213, 2.0097054018192755],
					[61.326508273282251, 2.010038702088853],
					[61.326508235159501, 2.0100387143736063],
					[61.326508167891838, 2.0100387556893575],
					[61.326508138555241, 2.0100387810121023],
					[61.325538797142919, 2.011352602914803],
					[61.324683294233601, 2.011380399916245],
					[61.324683218658649, 2.0113804374589703],
					[61.32468312384578, 2.011380556201452],
					[61.324683103716829, 2.0113806376005923],
					[61.325183103251845, 2.0139389331109729],
					[61.325560901630929, 2.0165000287973038],
					[61.325560907139554, 2.0165000481961508],
					[61.325560918856269, 2.0165000830863704],
					[61.325560927037976, 2.0165001018874418],
					[61.326296879816894, 2.0177637469940781],
					[61.324222206265027, 2.0180858962233228],
					[61.322147242549754, 2.0176331045538975],
					[61.322147230774625, 2.0176331034357067],
					[61.322147206237617, 2.0176330995445944],
					[61.322147193667185, 2.0176331004795074],
					[61.321658294238269, 2.0176470003463698],
					[61.321658224611397, 2.0176470309139525],
					[61.321658127944076, 2.0176471312861],
					[61.321658100012613, 2.0176472012898969],
					[61.321666499860768, 2.0194499872462157],
					[61.321310903839304, 2.0220332611822989],
					[61.320349809448679, 2.0256638407862062],
					[61.319869215308763, 2.0269638229651483],
					[61.319144250387666, 2.0285304470489844],
					[61.316099918106019, 2.0299081171127384],
					[61.316099907604276, 2.02990812320557],
					[61.316099890069538, 2.0299081327424342],
					[61.316099882241019, 2.0299081382388562],
					[61.314638786816822, 2.0309804356209558],
					[61.313788793004832, 2.0315220315963107],
					[61.313788790331621, 2.03152203219252],
					[61.313788784285173, 2.0315220372906215],
					[61.313788781707473, 2.0315220397403069],
					[61.312694360382295, 2.0323247828071223],
					[61.311838974852741, 2.0320915132355846],
					[61.310494585967163, 2.0313609723499546],
					[61.310366898133339, 2.0303360749703834],
					[61.31036684097316, 2.0303359977396265],
					[61.310366685825883, 2.0303359254925684],
					[61.310366588825424, 2.0303359321307592],
					[61.309149888785534, 2.0311442322324105],
					[61.30914987421054, 2.0311442467296512],
					[61.309149845856055, 2.031144273672238],
					[61.309149833158749, 2.0311442896252538],
					[61.307938733059615, 2.0329804894283985],
					[61.30793872561302, 2.0329805023350338],
					[61.307938714284141, 2.0329805273536761],
					[61.30793870871522, 2.032980541716122],
					[61.307458259425481, 2.0345383542030633],
					[61.307214080070949, 2.0340305124976163],
					[61.30721401425707, 2.0340304596892267],
					[61.307213865381591, 2.034030421645638],
					[61.307213781524446, 2.0340304384621493],
					[61.306113781973465, 2.0348359398832816],
					[61.306113747348888, 2.0348359850857065],
					[61.306113709447089, 2.0348360891220989],
					[61.306113706074285, 2.0348361461030549],
				],
			],
		},
		{
			fldNpdidField: "43639",
			wlbNpdidWellbore: "118",
			fldName: "OSEBERG ØST",
			fldCurrentActivitySatus: "Producing",
			wlbName: "30/6-5",
			fldDiscoveryYear: "1981",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43639",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43639",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "OSEBERG AREA UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=118",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "3500071",
			fldDateUpdated: "2022-09-13 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[60.583538714200223, 2.958233307711478],
					[60.583538715624833, 2.9582333788246991],
					[60.586994216113176, 2.9662694792083792],
					[60.586994218063275, 2.9662694825042384],
					[60.586994222127835, 2.966269492726088],
					[60.586994224077934, 2.9662694960219649],
					[60.595783123999837, 2.9823972951450171],
					[60.595783128874103, 2.9823973033893521],
					[60.595783137565796, 2.9823973164135391],
					[60.595783143332916, 2.9823973244911355],
					[60.603602643001352, 2.9922362248954255],
					[60.60360265242128, 2.9922362341266937],
					[60.603602672154025, 2.9922362524227113],
					[60.603602682466821, 2.9922362614874634],
					[60.617885981642033, 3.0026862604061715],
					[60.617885997393287, 3.0026862702970072],
					[60.617886028732308, 3.00268628644486],
					[60.617886046105859, 3.0026862923692219],
					[60.624547145948092, 3.004555793246471],
					[60.624547188322495, 3.0045557945159036],
					[60.624547269657178, 3.0045557811980923],
					[60.62454731040323, 3.0045557662781923],
					[60.636733410446993, 2.9965695674504369],
					[60.636733414829543, 2.9965695647993513],
					[60.636733424405662, 2.9965695575126428],
					[60.636733428706357, 2.996569553043626],
					[60.650627918208173, 2.9848695621009482],
					[60.658285631254515, 2.980019021599924],
					[60.658755478096516, 2.9803473592641678],
					[60.660769272162064, 2.982027954534205],
					[60.66076927305491, 2.982027954367017],
					[60.660769274029803, 2.9820279560190994],
					[60.660769274922657, 2.9820279558519096],
					[60.662647075125349, 2.9835279557067533],
					[60.662647142754899, 2.9835279779048682],
					[60.662647274824195, 2.9835279715259304],
					[60.662647340156788, 2.9835279427816994],
					[60.663905526444701, 2.9822975522542525],
					[60.66442201410559, 2.9835861747135466],
					[60.664422076150984, 2.9835862328201035],
					[60.664422222370369, 2.9835862806709605],
					[60.664422305569495, 2.9835862687629806],
					[60.665577905955587, 2.9828612686176701],
					[60.665577914719975, 2.9828612633066203],
					[60.665577930381041, 2.9828612511993651],
					[60.665577938170564, 2.9828612442359792],
					[60.665947157620806, 2.9825086175482216],
					[60.666435929314268, 2.9833140046314579],
					[60.666435951080381, 2.9833140280797763],
					[60.666436001673389, 2.9833140718192732],
					[60.666436031229047, 2.983314088304116],
					[60.666927636953254, 2.9834945527783527],
					[60.667313708096877, 2.9848083554677403],
					[60.667313708178909, 2.9848083572873736],
					[60.667313709153774, 2.9848083589398522],
					[60.667313709235799, 2.984808360759502],
					[60.667727608671754, 2.9861167603888403],
					[60.667727650745405, 2.9861168149039998],
					[60.66772776012025, 2.9861168843469832],
					[60.667727827339455, 2.9861168974551218],
					[60.668755424625516, 2.9859780266593972],
					[60.668919201181922, 2.9874056236403916],
					[60.668919246753518, 2.9874056958552759],
					[60.668919378376167, 2.9874057794920761],
					[60.668919464345251, 2.9874057890942551],
					[60.669963732604799, 2.9870558667745573],
					[60.670322002101948, 2.9898361260762951],
					[60.670322028995585, 2.9898361834402816],
					[60.670322112967554, 2.9898362686641651],
					[60.67032216923495, 2.9898362985109448],
					[60.671294259572697, 2.989989079906552],
					[60.67184151577851, 2.9912694791619727],
					[60.671841596816819, 2.9912695392486617],
					[60.67184177135136, 2.991269556153584],
					[60.671841863143797, 2.9912695151257469],
					[60.672535994185161, 2.9902921936415039],
					[60.673335904330301, 2.9943083386087035],
					[60.673335978961525, 2.9943084164212688],
					[60.673336155535324, 2.9943084586562154],
					[60.673336256585017, 2.9943084232455184],
					[60.674763918666805, 2.9924863993297492],
					[60.675880417739585, 2.9935834012599041],
					[60.676205405284058, 2.99505004246882],
					[60.676205476179703, 2.9950501173170845],
					[60.676205646667043, 2.99505016436785],
					[60.676205746258717, 2.9950501365703506],
					[60.67773888228087, 2.9934142117787617],
					[60.680388739048809, 2.9969862193101373],
					[60.680388801081435, 2.9969862572784662],
					[60.680388933732637, 2.9969862838813905],
					[60.68038900605508, 2.9969862703617549],
					[60.682044394910704, 2.9959530397098564],
					[60.685741515348447, 2.9984751165505914],
					[60.686172001750585, 3.001452828219084],
					[60.686172049993978, 3.0014528999911918],
					[60.68617218323125, 3.0014529797516194],
					[60.68617226822515, 3.0014529877399538],
					[60.687027729224617, 3.0011419373365884],
					[60.687163713335813, 3.0013222848168803],
					[60.687377603800748, 3.0023917394520456],
					[60.687377670146638, 3.0023918133545342],
					[60.68737783389799, 3.0023918708958912],
					[60.687377932032746, 3.0023918507263021],
					[60.688922072143292, 3.0010364790939752],
					[60.689174804262414, 3.0023056392265723],
					[60.689174868822647, 3.0023057134664244],
					[60.689175031681195, 3.0023057711768439],
					[60.689175129897706, 3.0023057528264725],
					[60.690783181408911, 3.0009364636819398],
					[60.691066505255669, 3.0021306458059565],
					[60.691066565999115, 3.0021307152538568],
					[60.691066720010781, 3.0021307764556533],
					[60.691066812222495, 3.0021307647343067],
					[60.691941531081611, 3.0015364578486685],
					[60.692080400570084, 3.0031111182043682],
					[60.692080456364771, 3.0031111977626326],
					[60.692080609244634, 3.0031112738732282],
					[60.692080707140896, 3.0031112684376309],
					[60.693549936951364, 3.0021863752441971],
					[60.694247030706933, 3.0032973064987654],
					[60.694247105729161, 3.0032973530859164],
					[60.694247264838694, 3.0032973674333587],
					[60.694247348033116, 3.0032973353604975],
					[60.696113744380149, 3.0012476685863581],
					[60.69623870061686, 3.0025444198280367],
					[60.696238754626251, 3.0025444997288986],
					[60.696238905638936, 3.0025445743578758],
					[60.696239001912964, 3.0025445728954891],
					[60.698902732101907, 3.0009613732912328],
					[60.699427629004646, 3.0018279036408071],
					[60.699427671229387, 3.0018279416678149],
					[60.699427768819454, 3.0018279895502116],
					[60.699427824995837, 3.0018279974172133],
					[60.701222143038208, 3.0016030086487668],
					[60.702927676893914, 3.0029307577896307],
					[60.702927750771167, 3.0029307788833925],
					[60.702927891361782, 3.0029307617889653],
					[60.702927958075165, 3.0029307236007727],
					[60.705111255656327, 3.0001168252620101],
					[60.707122200697803, 2.9976892126273595],
					[60.707972046647626, 2.9987001298072911],
					[60.707972119312707, 2.9987001639818858],
					[60.707972264620011, 2.998700171704523],
					[60.707972339858941, 2.998700142929458],
					[60.710741610988272, 2.9959781673736114],
					[60.711044209644108, 2.9968917636925818],
					[60.711044265434992, 2.9968918230741277],
					[60.711044401346051, 2.9968918821591219],
					[60.711044483251918, 2.9968918815283132],
					[60.712147193265238, 2.9963919224718554],
					[60.713866596064541, 2.9972973713445556],
					[60.715897086340185, 2.9986973654709281],
					[60.715897088125928, 2.9986973651367181],
					[60.715897091861144, 2.9986973681131732],
					[60.715897093728742, 2.9986973696013921],
					[60.719016592764099, 3.0006418693162384],
					[60.722141590857959, 3.002655768197168],
					[60.724097091370282, 3.0039279675338832],
					[60.724097169384621, 3.0039279805142867],
					[60.724097311024565, 3.0039279466673134],
					[60.724097373675498, 3.0039278981840098],
					[60.724755713136439, 3.0027530076343365],
					[60.725269208388141, 3.0027862881146299],
					[60.725333100425523, 3.0041667085468404],
					[60.725333161094021, 3.0041667964658592],
					[60.725333328096497, 3.0041668663329082],
					[60.725333432644739, 3.0041668486149748],
					[60.726447332907469, 3.0031751482651066],
					[60.72644733639715, 3.0031751457738616],
					[60.726447342401819, 3.0031751391353625],
					[60.726447345891486, 3.0031751366441175],
					[60.728561162827894, 3.0009335250802107],
					[60.728855421287776, 3.0010140576055737],
					[60.729094202405008, 3.0026361282171421],
					[60.729094268186373, 3.0026362096710257],
					[60.729094434616826, 3.0026362667747835],
					[60.729094536322464, 3.0026362459039246],
					[60.732241835775667, 2.9997001464141704],
					[60.734494534604217, 2.9976390484791753],
					[60.734494559915937, 2.9976390106387294],
					[60.734494593247398, 2.997638930841851],
					[60.734494600210475, 2.9976388854057077],
					[60.734394599692756, 2.9961971998822539],
					[60.734450197330339, 2.9953500460349392],
					[60.734797367806138, 2.994511217415937],
					[60.736339036235336, 2.9930834475002221],
					[60.736339037046214, 2.9930834455092588],
					[60.736339037939082, 2.9930834453418558],
					[60.736339038749975, 2.9930834433508919],
					[60.738986238677178, 2.9905251445263414],
					[60.738986242084593, 2.9905251402089674],
					[60.738986247277694, 2.9905251355566245],
					[60.738986248981398, 2.990525133397921],
					[60.74057794941892, 2.9887390324672363],
					[60.740577951122617, 2.9887390303083432],
					[60.740577952826285, 2.9887390281494342],
					[60.740577954611965, 2.98873902781434],
					[60.741672354899912, 2.9874029272258262],
					[60.741672372419018, 2.9874028963485597],
					[60.741672394629227, 2.9874028296449344],
					[60.741672400213162, 2.9874027936509888],
					[60.741428000229085, 2.9788110934223098],
					[60.741427993393224, 2.9788110616007097],
					[60.741427969900336, 2.9788109998036334],
					[60.741427952350477, 2.9788109699960015],
					[60.73986126654782, 2.9769692867455984],
					[60.738830781295427, 2.9750166131382398],
					[60.737961284065072, 2.972963822427054],
					[60.737961283982827, 2.972963820603463],
					[60.737961283090016, 2.9729638207714322],
					[60.737708492809183, 2.9723888411580108],
					[60.737094597463532, 2.9689582655127702],
					[60.737094596406138, 2.9689582620337864],
					[60.737094596241548, 2.9689582583867318],
					[60.737094595266441, 2.9689582567312751],
					[60.736236305144388, 2.9650917005403019],
					[60.73724739535799, 2.9606361445646896],
					[60.737247396566779, 2.9606361314634952],
					[60.737247399066746, 2.9606361070845977],
					[60.737247400357909, 2.9606360958069158],
					[60.737208500106654, 2.9584832983540204],
					[60.737183500170509, 2.9544471983975478],
					[60.73716410008462, 2.9499888995244228],
					[60.737164099836967, 2.9499888940540009],
					[60.737164099341683, 2.9499888831131749],
					[60.737164099176582, 2.9499888794662374],
					[60.736877999097381, 2.9473305816036484],
					[60.736647398927026, 2.9445888830461131],
					[60.736647387123575, 2.9445888411401695],
					[60.736647345181474, 2.9445887699891036],
					[60.736647316745774, 2.944588738583056],
					[60.735516857895512, 2.9437748662509859],
					[60.73366132276756, 2.9399694014570041],
					[60.735277977961296, 2.9367833903175606],
					[60.735277982094217, 2.9367833821795126],
					[60.735277988574495, 2.9367833662413054],
					[60.735277991814655, 2.9367833582721992],
					[60.735947391602835, 2.9345583573247267],
					[60.736977991163918, 2.9311417580498218],
					[60.736977994965244, 2.9311417426170192],
					[60.736977998996899, 2.9311417124279169],
					[60.736978000119969, 2.9311416975025026],
					[60.736911299867515, 2.9270416972125251],
					[60.7368780000131, 2.9215444290919583],
					[60.737914068091293, 2.9182668314644222],
					[60.740605705103413, 2.9166029696403837],
					[60.740605741186947, 2.9166029315187751],
					[60.740605787610562, 2.9166028436110283],
					[60.740605799736102, 2.9166027934857919],
					[60.740525199780627, 2.9142499968436599],
					[60.740514099518421, 2.9119527982736479],
					[60.740514098936927, 2.9119527855089258],
					[60.740514095988537, 2.9119527603188189],
					[60.740514092728887, 2.9119527480631415],
					[60.73988629354966, 2.9096416504503249],
					[60.739361295012991, 2.9073777556000557],
					[60.739361290777104, 2.9073777416916289],
					[60.739361279627182, 2.9073777143841992],
					[60.739361273689013, 2.9073777026388368],
					[60.738905773829003, 2.9065666025960821],
					[60.738905771877299, 2.9065665992888801],
					[60.738905767973876, 2.9065665926744622],
					[60.738905766022178, 2.9065665893672583],
					[60.737136282824423, 2.9039277131805266],
					[60.737022395642001, 2.9035360582057654],
					[60.736564098290721, 2.9000027754704143],
					[60.736186299188986, 2.8968055769955221],
					[60.736186296903959, 2.8968055663962367],
					[60.736186292333926, 2.8968055451976942],
					[60.73618628915623, 2.8968055347684918],
					[60.73458628866922, 2.8921832351037078],
					[60.734586278659009, 2.8921832131049023],
					[60.734586253449116, 2.8921831737747037],
					[60.734586238166109, 2.8921831546201711],
					[60.733200140610265, 2.890863758751832],
					[60.732250231883498, 2.8898721537524903],
					[60.73236408698893, 2.889569469504162],
					[60.732364062264544, 2.8895693620469052],
					[60.732363926798513, 2.8895692352537319],
					[60.732363817032926, 2.8895692175705627],
					[60.730411304907754, 2.8904552877377241],
					[60.730433500276909, 2.8889194035599419],
					[60.730433439202152, 2.8889193085585094],
					[60.730433268410607, 2.8889192363207323],
					[60.730433158693806, 2.8889192590843864],
					[60.727633160638014, 2.8917192560468017],
					[60.724983363341643, 2.8942107662536678],
					[60.724230780957186, 2.8926221132335792],
					[60.724230708244498, 2.8926220590675826],
					[60.724230548464668, 2.8926220306845303],
					[60.72423045961213, 2.8926220568076815],
					[60.719180460857984, 2.8975554567093584],
					[60.718719503979116, 2.8979968237373854],
					[60.718411292839093, 2.8968777473164149],
					[60.718411237649065, 2.896877682461878],
					[60.718411096986564, 2.896877619174373],
					[60.718411012406797, 2.8968776205714573],
					[60.716708401843199, 2.8977108262791229],
					[60.716275184204548, 2.8966860212833283],
					[60.71627512318188, 2.896685966733318],
					[60.716274977912448, 2.8966859208689058],
					[60.71627489455841, 2.8966859293845477],
					[60.714927860374679, 2.8975164283186547],
					[60.714200166965462, 2.8964109894021064],
					[60.714200091099883, 2.8964109450326809],
					[60.714199932962629, 2.8964109328646059],
					[60.714199851666898, 2.8964109667179967],
					[60.712272232893433, 2.8985468643941759],
					[60.711569571663517, 2.8973665978806817],
					[60.71156950107121, 2.8973665506710988],
					[60.711569348683419, 2.8973665263782862],
					[60.711569267780604, 2.8973665491251621],
					[60.710025011110474, 2.8987275245124771],
					[60.709177944392067, 2.8978526615757594],
					[60.709177859289888, 2.8978526318295015],
					[60.709177699889537, 2.8978526511333764],
					[60.709177625674549, 2.8978527020052227],
					[60.707752626734489, 2.9003748999030603],
					[60.706738727227979, 2.9021137985524943],
					[60.706738727311134, 2.9021138003740798],
					[60.706738726418436, 2.9021138005437983],
					[60.705669228777488, 2.9040026964308172],
					[60.705310931364494, 2.9045637924245931],
					[60.705310919220906, 2.9045638222919274],
					[60.705310903134411, 2.9045638841428376],
					[60.705310901060066, 2.904563917608646],
					[60.705380391214156, 2.9053860021605802],
					[60.703541586410793, 2.9066665353371834],
					[60.703541557221953, 2.9066665666024831],
					[60.703541515411821, 2.906666637009343],
					[60.703541501005127, 2.9066666764900382],
					[60.703244212253644, 2.9092359852797012],
					[60.700997089140095, 2.9107387342109026],
					[60.699652688666703, 2.9116387342450678],
					[60.699652677393431, 2.9116387437322251],
					[60.699652655739577, 2.9116387625371201],
					[60.699652646334791, 2.9116387735066822],
					[60.697155449666234, 2.9146581666902924],
					[60.694363754571576, 2.9175942619153821],
					[60.694363754654525, 2.9175942637363197],
					[60.692647111538498, 2.9194081015830311],
					[60.69145559357424, 2.9194414992929216],
					[60.691455573933958, 2.9194415030138581],
					[60.691455532950819, 2.9194415126147821],
					[60.691455514369132, 2.9194415198081387],
					[60.690699913821263, 2.9198026191450204],
					[60.690699910333215, 2.9198026216422286],
					[60.690699902464381, 2.9198026268057662],
					[60.690699898893406, 2.9198026274822424],
					[60.689788931680354, 2.9203386483711453],
					[60.688744540634524, 2.9192970572888264],
					[60.688744470878945, 2.9192970282674242],
					[60.688744331260267, 2.9192970271714489],
					[60.688744261397183, 2.9192970550968433],
					[60.686741788005314, 2.921238535565486],
					[60.686214090406715, 2.9196721358377689],
					[60.686214009006818, 2.9196720686268534],
					[60.686213832268784, 2.9196720433440628],
					[60.686213736037935, 2.9196720854412748],
					[60.68319983643925, 2.923980484802347],
					[60.683199824042333, 2.9239805091805726],
					[60.683199805663449, 2.923980560394992],
					[60.683199799681461, 2.9239805872312208],
					[60.683085905175012, 2.9256749157395205],
					[60.676216554171724, 2.932991563674018],
					[60.672110954461168, 2.9373831636031227],
					[60.672110953568399, 2.9373831637716257],
					[60.672110952758295, 2.9373831657598743],
					[60.672110951865513, 2.9373831659283787],
					[60.669138917190487, 2.9406329857707516],
					[60.668694561161892, 2.9400276817240076],
					[60.668694507232438, 2.9400276441829138],
					[60.668694386906914, 2.9400276118256219],
					[60.668694321403642, 2.9400276168410473],
					[60.667144320650273, 2.9406915160995024],
					[60.667144285847947, 2.9406915428488736],
					[60.667144229237863, 2.9406916049072116],
					[60.667144207595399, 2.9406916438551391],
					[60.66639700990708, 2.9432110375427558],
					[60.665627627091894, 2.9453165918418605],
					[60.663752656975873, 2.9472304600440498],
					[60.66375265527283, 2.9472304621997027],
					[60.663752650163687, 2.9472304686666768],
					[60.663752648460616, 2.9472304708223334],
					[60.662166549584718, 2.9490859673670831],
					[60.661247050564498, 2.9501081654962933],
					[60.661247030622164, 2.9501082022753367],
					[60.661247006162505, 2.9501082784336599],
					[60.661247000834834, 2.9501083198002012],
					[60.661310899205127, 2.9507917006463824],
					[60.661141508266134, 2.9525666048041388],
					[60.657835965064301, 2.9556054525400044],
					[60.657835964171468, 2.955605452707911],
					[60.654658164203198, 2.9585526535967235],
					[60.654658160714334, 2.9585526560867432],
					[60.654658156414996, 2.9585526605633992],
					[60.654658153008498, 2.9585526648722684],
					[60.653708305199892, 2.9595802991564493],
					[60.652894552146556, 2.9586331705434956],
					[60.652894489376351, 2.9586331364843486],
					[60.652894356238839, 2.9586331193175419],
					[60.652894285871561, 2.9586331362098739],
					[60.651055686572626, 2.9599107954256914],
					[60.650475184152235, 2.9585693202455405],
					[60.650475117715231, 2.9585692648689035],
					[60.650474967271698, 2.9585692234438845],
					[60.650474880669059, 2.958569239717415],
					[60.648791722394201, 2.9598136342418964],
					[60.647608443650938, 2.9585942610764469],
					[60.647608375606254, 2.9585942298472925],
					[60.647608236713239, 2.9585942247670385],
					[60.647608166757692, 2.9585942507481935],
					[60.646897067084517, 2.9592248497146008],
					[60.646897062785165, 2.9592248541900212],
					[60.646897054186446, 2.9592248631408844],
					[60.646897049887087, 2.9592248676163071],
					[60.646248915008243, 2.9599614192704542],
					[60.646216891757753, 2.9598499444437545],
					[60.64621684869919, 2.9598498883476858],
					[60.646216735658868, 2.9598498178893422],
					[60.646216605143429, 2.9598498387373557],
					[60.646216521682497, 2.9598499442721642],
					[60.646216500458515, 2.9598500124429186],
					[60.646224799984296, 2.9599889121279235],
					[60.646224867902774, 2.9599890002313023],
					[60.64622504262951, 2.9599890609372177],
					[60.646225150248263, 2.959989031553711],
					[60.646248732156579, 2.9599622329951427],
					[60.647105408167498, 2.9629444554096764],
					[60.647105408167498, 2.9629444554096764],
					[60.6471054082498, 2.9629444572281165],
					[60.648024807603228, 2.9660278572833882],
					[60.648024808660573, 2.9660278607528272],
					[60.648024810610721, 2.9660278640547073],
					[60.648024810775262, 2.9660278676916718],
					[60.648922011177909, 2.9685611645779124],
					[60.649788710379582, 2.9711722613422338],
					[60.650202608577565, 2.97247785923626],
					[60.650202611666927, 2.9724778678272736],
					[60.650202616706309, 2.9724778797207967],
					[60.650202618820693, 2.9724778866605428],
					[60.650988719068089, 2.9741278867403111],
					[60.650988719960921, 2.9741278865729717],
					[60.650988720935914, 2.9741278882243085],
					[60.65098872191092, 2.9741278898756414],
					[60.651141563446188, 2.9744303086476678],
					[60.650944406225101, 2.9744359002647554],
					[60.637188947801192, 2.9731887034110542],
					[60.625141776012804, 2.9682359153132745],
					[60.625141765945038, 2.9682359117022572],
					[60.625141745891732, 2.9682359062974397],
					[60.625141735823966, 2.9682359026864384],
					[60.614108403672063, 2.9662387156615906],
					[60.605458449953233, 2.9563776671637654],
					[60.605458449953233, 2.9563776671637654],
					[60.605458448978062, 2.956377665515229],
					[60.605458448085258, 2.9563776656827518],
					[60.598452948434478, 2.9486526664793788],
					[60.598452884769777, 2.9486526326587432],
					[60.598452752689212, 2.9486526190125373],
					[60.598452684190889, 2.9486526373713322],
					[60.589413794308001, 2.9550665294583385],
					[60.583538804919705, 2.9582331241562101],
					[60.58353875787251, 2.9582331787297242],
					[60.583538714200223, 2.958233307711478],
				],
			],
		},
		{
			fldNpdidField: "3960848",
			wlbNpdidWellbore: "61",
			fldName: "TYRIHANS",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6407/1-2",
			fldDiscoveryYear: "1983",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=3960848",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=3960848",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "TYRIHANS UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=61",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "3488806",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-02-17 00:00:00",
			fldMainSupplyBase: "Kristiansund",
			fldAreaGeometryWKT: [
				[
					[64.846899799765467, 7.0291360960485427],
					[64.846899800857685, 7.029136110733134],
					[64.846899803911001, 7.0291361379295028],
					[64.846899805872027, 7.0291361504412846],
					[64.850677604702881, 7.0436667440146286],
					[64.853591502609618, 7.0593250380784136],
					[64.85359150723086, 7.059325048293851],
					[64.853591513838126, 7.0593250731341426],
					[64.853591519383599, 7.0593250853926834],
					[64.855233119412816, 7.0628167859290736],
					[64.855233171213555, 7.0628168349471325],
					[64.855233294863581, 7.0628168872335646],
					[64.855233366685383, 7.0628168883940914],
					[64.861174976727312, 7.0607002205584513],
					[64.869110996314362, 7.0655529718957393],
					[64.869110999004562, 7.0655529717022763],
					[64.869111004439901, 7.0655529755332793],
					[64.869111007157514, 7.0655529774487746],
					[64.872898729850547, 7.0675338603581794],
					[64.872741549656794, 7.0677109668316458],
					[64.872741546151971, 7.0677109734170873],
					[64.872741536452125, 7.0677109867812682],
					[64.872741532023241, 7.0677109913219072],
					[64.872566531670842, 7.0679831913380688],
					[64.872566528166033, 7.0679831979234296],
					[64.872566521128988, 7.0679832089849421],
					[64.872566517651578, 7.0679832176795534],
					[64.872522017875099, 7.0680805167337786],
					[64.872522017005721, 7.0680805189074221],
					[64.872522015267009, 7.0680805232547028],
					[64.872522015294393, 7.0680805253639507],
					[64.872341514801519, 7.0685277253838983],
					[64.872341512193415, 7.068527731904755],
					[64.872341507846571, 7.0685277427728366],
					[64.87234150703199, 7.0685277491648755],
					[64.872305406552456, 7.0686638485137232],
					[64.872305405683107, 7.0686638506873267],
					[64.872305405710492, 7.0686638527965373],
					[64.872305405710492, 7.0686638527965373],
					[64.872208105638677, 7.0690666529803412],
					[64.872208103899936, 7.0690666573275278],
					[64.872208102243306, 7.0690666680023284],
					[64.872208102325416, 7.0690666743299397],
					[64.872180401579001, 7.0692832719728766],
					[64.872130401836387, 7.0695999682730397],
					[64.872130424425436, 7.069600048987235],
					[64.872130520170728, 7.0696001645629378],
					[64.872130594223663, 7.0696001993601048],
					[64.872319330857508, 7.0696057972498751],
					[64.872330480977084, 7.069614060271352],
					[64.872330507201553, 7.0696140752785857],
					[64.872330563100718, 7.0696140944895269],
					[64.872330591906007, 7.069614100866815],
					[64.872541636089593, 7.0696223982911848],
					[64.872574889086678, 7.0696445669830767],
					[64.872574911669489, 7.0696445780294503],
					[64.872574960339918, 7.0696445935370535],
					[64.872574986427608, 7.0696445979982592],
					[64.872766614998326, 7.0696584947680865],
					[64.872847064370418, 7.0697334453629157],
					[64.872847093339843, 7.0697334643959753],
					[64.87284715472893, 7.0697334916583987],
					[64.872847188045284, 7.0697334998233865],
					[64.872991583714267, 7.0697418947411803],
					[64.873183130301939, 7.070052905826282],
					[64.873183153972946, 7.0700529315738843],
					[64.873183211993833, 7.0700529759690598],
					[64.873183245419625, 7.0700529925716618],
					[64.87322214055726, 7.0700640913920676],
					[64.873230536908849, 7.0700668901028303],
					[64.8732305468004, 7.0700668915042684],
					[64.873230566610843, 7.0700668964164501],
					[64.873230577426511, 7.0700668998628249],
					[64.873447139175852, 7.0700918936778727],
					[64.873477704982406, 7.0701084758414616],
					[64.873477732945645, 7.0701084865024892],
					[64.873477789632062, 7.0701084972136528],
					[64.873477820176078, 7.0701084992444176],
					[64.873669355259324, 7.0700891050655139],
					[64.873716598624583, 7.0701168711943607],
					[64.873716629305378, 7.0701168837717852],
					[64.873716692296341, 7.0701168961419336],
					[64.873716724633866, 7.0701168980440201],
					[64.87389425507935, 7.0700946196793231],
					[64.87396920795517, 7.0703583549316562],
					[64.873969228203279, 7.0703583935937191],
					[64.873969283780028, 7.0703584571679645],
					[64.873969318211891, 7.0703584821444734],
					[64.874124913584069, 7.0704279810454356],
					[64.87415271140199, 7.0704418785236616],
					[64.874152713222841, 7.0704418805043412],
					[64.874152714119589, 7.0704418804399998],
					[64.874274911609305, 7.0705001794562632],
					[64.874336004434312, 7.0705307769066019],
					[64.874349897011257, 7.0705390721812016],
					[64.874349898804738, 7.0705390720525276],
					[64.874349903343145, 7.0705390759495907],
					[64.874349905136626, 7.0705390758209159],
					[64.874513804124703, 7.0706279754901207],
					[64.874574903234787, 7.0706612755788152],
					[64.874691598737925, 7.0707279732612172],
					[64.874802693808832, 7.0707945707651687],
					[64.874866590859043, 7.070836266291904],
					[64.874866590886398, 7.0708362684013197],
					[64.874866591783132, 7.0708362683369916],
					[64.874866591783132, 7.0708362683369916],
					[64.8750276862541, 7.0709390650230928],
					[64.875038780160253, 7.0709473608878612],
					[64.875072080363395, 7.0709723591007183],
					[64.875072082184261, 7.0709723610814965],
					[64.87507208582592, 7.0709723650430609],
					[64.875072086722653, 7.0709723649787364],
					[64.875210986821912, 7.0710668653726287],
					[64.875255481956742, 7.07109736135095],
					[64.875377677970448, 7.071191857596081],
					[64.87537767799779, 7.0711918597055563],
					[64.875377678894523, 7.071191859641238],
					[64.875377678894523, 7.071191859641238],
					[64.875480477661512, 7.0712695579754641],
					[64.875544273927915, 7.071319556585042],
					[64.875708171357232, 7.0714557528652691],
					[64.875869268693634, 7.0715945501969113],
					[64.875935966943359, 7.0716529485048349],
					[64.876030463972285, 7.0717390473396513],
					[64.876160962909324, 7.0718612455321468],
					[64.876160963833371, 7.0718612475773677],
					[64.87616096562688, 7.0718612474487701],
					[64.876160965654208, 7.071861249558288],
					[64.876188761698387, 7.0718862454996492],
					[64.876247058937892, 7.0719445402031686],
					[64.87624705896522, 7.0719445423127132],
					[64.876247059861953, 7.071944542248418],
					[64.876247060758715, 7.0719445421841218],
					[64.876344261346247, 7.0720390429627003],
					[64.876344261346247, 7.0720390429627003],
					[64.87634426316707, 7.0720390449436463],
					[64.87634426316707, 7.0720390449436463],
					[64.876388759583037, 7.0720807430712611],
					[64.876499856825291, 7.0721945401402975],
					[64.876616553264896, 7.0723140348075564],
					[64.876652649122931, 7.0723557299907753],
					[64.876652650943754, 7.0723557319717854],
					[64.876652653688652, 7.0723557359980473],
					[64.876652656433507, 7.0723557400243129],
					[64.876730453682271, 7.0724362351933836],
					[64.876805450862832, 7.0725195350479355],
					[64.876805452683669, 7.0725195370289464],
					[64.87680545719472, 7.072519538817124],
					[64.876805458118753, 7.072519540862416],
					[64.876844254379918, 7.0725584374142443],
					[64.876958150750269, 7.0726862336104128],
					[64.876958151647003, 7.0726862335461416],
					[64.876958151674316, 7.0726862356557225],
					[64.877072050270627, 7.0728112328436774],
					[64.877108148708729, 7.0728529313097122],
					[64.877108148708729, 7.0728529313097122],
					[64.87710815142627, 7.0728529332265015],
					[64.87710815142627, 7.0728529332265015],
					[64.877180450789325, 7.0729334345313069],
					[64.877299848751719, 7.0730668301451773],
					[64.877408146981224, 7.0731945291923095],
					[64.877408147877986, 7.0731945291280498],
					[64.877527647356715, 7.0733334288583194],
					[64.877608146048004, 7.0734307278408899],
					[64.877608146048004, 7.0734307278408899],
					[64.877608146944738, 7.0734307277766417],
					[64.877608146972051, 7.0734307298862742],
					[64.877755447429209, 7.0736057290354442],
					[64.877930447077162, 7.0738140288386262],
					[64.877930448001237, 7.0738140308840434],
					[64.877930448897956, 7.0738140308198076],
					[64.877983142915667, 7.0738751229975314],
					[64.877997037080064, 7.0738945154403963],
					[64.877997040748951, 7.0738945215124645],
					[64.877997048956189, 7.0738945314826802],
					[64.87799705259782, 7.0738945354450617],
					[64.87802764880081, 7.0739279309948326],
					[64.878210946949494, 7.0741473285057648],
					[64.878210946949494, 7.0741473285057648],
					[64.878210947873541, 7.074147330551237],
					[64.878294246909448, 7.0742445288434199],
					[64.878438745844434, 7.0744195262659328],
					[64.878438748589289, 7.07441953029267],
					[64.878438754051643, 7.0744195362364506],
					[64.87843875679647, 7.0744195402632091],
					[64.878444252351414, 7.0744251353656313],
					[64.878588746188385, 7.0745973286668615],
					[64.87866654642761, 7.0746918277075919],
					[64.87866654642761, 7.0746918277075919],
					[64.87866654824839, 7.0746918296888861],
					[64.878666549172451, 7.0746918317344116],
					[64.878735948224715, 7.0747723305807],
					[64.878863744975121, 7.0749223254817473],
					[64.87888314182355, 7.0749473223203418],
					[64.878883146361858, 7.074947326218795],
					[64.878883153672277, 7.0749473362538602],
					[64.87888315910736, 7.0749473400881078],
					[64.878894253978302, 7.0749584366825591],
					[64.879033149089508, 7.0751168310382075],
					[64.879122049434997, 7.0752195301463709],
					[64.87912205035903, 7.0752195321919285],
					[64.879122051255763, 7.0752195321277354],
					[64.879299851023433, 7.0754195327420772],
					[64.879299851050703, 7.0754195348518447],
					[64.879299851947465, 7.0754195347876543],
					[64.879299852844198, 7.0754195347234674],
					[64.879333150706429, 7.0754557325201626],
					[64.879349847853646, 7.0754751309369324],
					[64.879349849674412, 7.0754751329183287],
					[64.87934985239194, 7.0754751348355382],
					[64.879349853316, 7.0754751368811428],
					[64.879485951581444, 7.0756223333084129],
					[64.879577650588487, 7.0757251332320834],
					[64.879577652409253, 7.0757251352135162],
					[64.879577655126795, 7.0757251371307852],
					[64.879577656050827, 7.0757251391763951],
					[64.87976095459571, 7.0759140378592429],
					[64.879791548987612, 7.0759473323822535],
					[64.879805445690792, 7.0759640281545302],
					[64.879805449305053, 7.0759640300076505],
					[64.879805454794663, 7.0759640380618478],
					[64.879805459332928, 7.0759640419606251],
					[64.879944259208372, 7.0761029407620377],
					[64.880033159055998, 7.0761918418449588],
					[64.880033159055998, 7.0761918418449588],
					[64.880033159980002, 7.0761918438906211],
					[64.880255458300041, 7.0764084402550775],
					[64.880258155795502, 7.0764112396789329],
					[64.880258156692264, 7.0764112396147736],
					[64.880258157616268, 7.0764112416604581],
					[64.880258159409749, 7.0764112415321438],
					[64.880260959216159, 7.0764140399720281],
					[64.880260960140191, 7.0764140420177331],
					[64.880260962884947, 7.0764140460449481],
					[64.880260963781694, 7.0764140459807887],
					[64.880416564202605, 7.0765584461312159],
					[64.880416565099324, 7.0765584460670636],
					[64.880485964849342, 7.076622347466258],
					[64.880485964849342, 7.076622347466258],
					[64.880485966670108, 7.0766223494478151],
					[64.880485967566841, 7.0766223493836646],
					[64.880577667952153, 7.0767029505222725],
					[64.880713768300282, 7.0768223509351493],
					[64.880713770121048, 7.0768223529167384],
					[64.880713774659299, 7.0768223568157671],
					[64.880713777376783, 7.0768223587332102],
					[64.880738774587599, 7.0768418558253225],
					[64.880805471632371, 7.0768973547175182],
					[64.880902672180696, 7.0769779550081475],
					[64.880902673077415, 7.0769779549440068],
					[64.880902675794928, 7.0769779568614775],
					[64.880902676691704, 7.0769779567973332],
					[64.880941576784139, 7.0770084571763414],
					[64.881069276222917, 7.0771084580002084],
					[64.881166575079888, 7.077186256676776],
					[64.88116657690064, 7.077186258658422],
					[64.881166581411634, 7.0771862604476814],
					[64.881166583232385, 7.0771862624293274],
					[64.881235981703639, 7.0772362626913194],
					[64.881394281408092, 7.0773529617732782],
					[64.881394284125619, 7.0773529636908208],
					[64.881394287739852, 7.0773529655442333],
					[64.881394289560603, 7.0773529675259077],
					[64.881402685580909, 7.0773584641082943],
					[64.881433182406838, 7.0773807599522742],
					[64.881433183330856, 7.0773807619980822],
					[64.881433186048341, 7.077380763915631],
					[64.881433186972359, 7.0773807659614576],
					[64.881574887120408, 7.0774779655696944],
					[64.881619287421373, 7.0775084647982824],
					[64.881619288345377, 7.0775084668441082],
					[64.881619290138886, 7.0775084667158552],
					[64.881619291035619, 7.0775084666517305],
					[64.881747090719955, 7.0775918675930667],
					[64.881747090719955, 7.0775918675930667],
					[64.881747091616688, 7.0775918675289429],
					[64.881747091643945, 7.0775918696389075],
					[64.881847091890194, 7.0776557682092074],
					[64.881847092786913, 7.0776557681450889],
					[64.88184709460765, 7.0776557701268157],
					[64.881919294598561, 7.0777001701988969],
					[64.882072093646443, 7.0777945690535571],
					[64.882094294301254, 7.0778084706794697],
					[64.882094296991511, 7.0778084704871169],
					[64.882094303323257, 7.0778084742582745],
					[64.882094306040727, 7.0778084761759334],
					[64.882177701802149, 7.0778529741495451],
					[64.882272097579616, 7.0779084727501651],
					[64.8822998966223, 7.0779251718104153],
					[64.882299898415781, 7.0779251716821863],
					[64.882299901133251, 7.0779251735998523],
					[64.882299902953989, 7.0779251755816315],
					[64.882449902583943, 7.0780084759977697],
					[64.882449904377424, 7.078008475869547],
					[64.882449907094937, 7.0780084777872485],
					[64.882449907991699, 7.0780084777231353],
					[64.882524907259892, 7.0780473764084686],
					[64.882630506905386, 7.0781029772290882],
					[64.882630507802105, 7.0781029771649813],
					[64.882630508698867, 7.0781029771008708],
					[64.882749908644442, 7.0781640777114179],
					[64.882749909568417, 7.0781640797573546],
					[64.882749911361898, 7.078164079629139],
					[64.8828110108781, 7.0781945786597555],
					[64.882811011802076, 7.0781945807056976],
					[64.882811012698824, 7.0781945806415907],
					[64.8829777116337, 7.0782751781347129],
					[64.882994310852453, 7.0782834786907483],
					[64.882994312673191, 7.0782834806726012],
					[64.883069313408086, 7.0783195802435541],
					[64.883069314304819, 7.0783195801794516],
					[64.883069315201595, 7.0783195801153465],
					[64.883069316125557, 7.0783195821613125],
					[64.883177715575286, 7.0783695824360988],
					[64.88317771736881, 7.0783695823078938],
					[64.883177718265543, 7.0783695822437931],
					[64.883177718265543, 7.0783695822437931],
					[64.88320271761711, 7.0783807820877289],
					[64.883361017117068, 7.0784529828481935],
					[64.88342771742721, 7.0784834820398501],
					[64.883427718323929, 7.0784834819757547],
					[64.883427721041429, 7.0784834838935717],
					[64.883427722834909, 7.0784834837653747],
					[64.883547120211361, 7.0785334849196362],
					[64.883652718357055, 7.0785807830496053],
					[64.883652720150536, 7.0785807829214127],
					[64.883652722868035, 7.0785807848392368],
					[64.88365272466153, 7.0785807847110442],
					[64.883816621702238, 7.0786473835736352],
					[64.883880519873173, 7.0786751820224696],
					[64.883880521693897, 7.0786751840044131],
					[64.883880524411396, 7.078675185922263],
					[64.883880526204877, 7.0786751857940757],
					[64.884102687891186, 7.0787640692016369],
					[64.884105458576556, 7.0787668407898829],
					[64.884105471294447, 7.0787668525535183],
					[64.884105496675744, 7.0787668718604939],
					[64.88410551113266, 7.0787668792756273],
					[64.884116610637918, 7.0787723800430236],
					[64.884116615148898, 7.0787723818327164],
					[64.884116623274096, 7.078772385476217],
					[64.884116626861086, 7.0787723852198514],
					[64.884330524616743, 7.0788557842236131],
					[64.884383222345662, 7.0788779844171961],
					[64.884383224139142, 7.0788779842890195],
					[64.884383226856599, 7.0788779862069457],
					[64.884383227753361, 7.0788779861428575],
					[64.884555527743487, 7.078944587872642],
					[64.884663827856372, 7.0789862870289229],
					[64.884663828753105, 7.078986286964839],
					[64.8847805285954, 7.0790307867420728],
					[64.884780530388923, 7.0790307866139015],
					[64.884944330165453, 7.0790918872057604],
					[64.884944331062201, 7.0790918871416766],
					[64.884944332882924, 7.0790918891237267],
					[64.884944333779686, 7.0790918890596437],
					[64.885008232281947, 7.0791140881853538],
					[64.885222130030826, 7.0791945861823233],
					[64.885222134569034, 7.0791945900823974],
					[64.885222146253994, 7.0791945913595908],
					[64.88522215076496, 7.0791945931494258],
					[64.885233236893157, 7.0791973895572058],
					[64.885280524080443, 7.0792168855123698],
					[64.885280525873938, 7.0792168853842101],
					[64.885280530384904, 7.079216887174054],
					[64.885280532178399, 7.0792168870458942],
					[64.885458231919571, 7.0792807880251889],
					[64.88545823374028, 7.0792807900073083],
					[64.885458236430537, 7.0792807898150736],
					[64.885458237327299, 7.0792807897509942],
					[64.885499933609481, 7.0792945896763291],
					[64.885683230762183, 7.079361287502028],
					[64.885811030602511, 7.0794084889155346],
					[64.88581103239602, 7.0794084887873847],
					[64.885811034189516, 7.0794084886592348],
					[64.885811035086277, 7.0794084885951589],
					[64.885908234452444, 7.0794418893353104],
					[64.886002732581147, 7.0794751876014548],
					[64.886133232251368, 7.0795223872936344],
					[64.886133233148129, 7.0795223872295612],
					[64.886133234968838, 7.0795223892117276],
					[64.886133236762333, 7.079522389083583],
					[64.886191634115107, 7.0795418887510211],
					[64.886361030854118, 7.0796029885134715],
					[64.886383229925585, 7.0796112879134929],
					[64.886383231719094, 7.0796112877853528],
					[64.886383235333312, 7.0796112896394021],
					[64.886383237126807, 7.0796112895112628],
					[64.886516634274429, 7.0796557888765594],
					[64.886586028713737, 7.0796807874833538],
					[64.886599925879409, 7.0796862856829144],
					[64.886599928596851, 7.07968628760106],
					[64.886599933080603, 7.079686287280718],
					[64.886599934901326, 7.0796862892629298],
					[64.886811035141832, 7.0797584899450383],
					[64.88681103603858, 7.0797584898809713],
					[64.88695823605967, 7.0798084893810929],
					[64.88695823605967, 7.0798084893810929],
					[64.88695823785315, 7.0798084892529642],
					[64.886958238749926, 7.0798084891888973],
					[64.887036037171484, 7.0798334902087481],
					[64.887149934876163, 7.0798723886679005],
					[64.887149935800153, 7.0798723907142245],
					[64.887149936696886, 7.0798723906501628],
					[64.887149937593648, 7.0798723905860994],
					[64.887261036442936, 7.079908489780756],
					[64.887341634559874, 7.0799362887809618],
					[64.887341636353355, 7.0799362886528394],
					[64.887341637277316, 7.0799362906991838],
					[64.887341638174078, 7.0799362906351204],
					[64.887486038363917, 7.0799834903870975],
					[64.887486040157413, 7.0799834902589769],
					[64.887486043771631, 7.0799834921131506],
					[64.887486045565097, 7.07998349198503],
					[64.887536042907016, 7.079997391736196],
					[64.887711038387494, 7.0800529895982365],
					[64.887727736902164, 7.0800584908860564],
					[64.887727738695645, 7.0800584907579402],
					[64.887727742282635, 7.0800584905017052],
					[64.887727743206611, 7.0800584925480807],
					[64.887830540301437, 7.080089092022873],
					[64.887922137042111, 7.0801195898048075],
					[64.887922143346572, 7.0801195914668575],
					[64.887922155058718, 7.080119594855014],
					[64.887922161363178, 7.0801195965170631],
					[64.887936050601809, 7.0801223940124283],
					[64.888116640578545, 7.0801779909830858],
					[64.888116642372083, 7.0801779908549713],
					[64.88811664329603, 7.0801779929013815],
					[64.888116644192792, 7.0801779928373252],
					[64.888163843765156, 7.0801918924245753],
					[64.888163844661918, 7.0801918923605189],
					[64.88816384555868, 7.0801918922964626],
					[64.888311044736383, 7.0802334912671938],
					[64.888388845036772, 7.080255792771033],
					[64.888388845933534, 7.0802557927069785],
					[64.888508246105729, 7.0802890924971704],
					[64.88850824789921, 7.080289092369064],
					[64.888508252410176, 7.0802890941592942],
					[64.888508254203671, 7.0802890940311869],
					[64.888613851248721, 7.0803140936915145],
					[64.888705546663942, 7.0803390936409141],
					[64.888705549354214, 7.0803390934487567],
					[64.88870555294119, 7.0803390931925483],
					[64.888705554761898, 7.0803390951749563],
					[64.888838851836354, 7.0803695948232788],
					[64.888902749158319, 7.080386295019677],
					[64.888902750055095, 7.0803862949556242],
					[64.888902751848576, 7.0803862948275205],
					[64.888902752745309, 7.0803862947634721],
					[64.88906385275456, 7.0804251940040333],
					[64.889063853678508, 7.0804251960505251],
					[64.889063857265512, 7.0804251957943212],
					[64.889063858162245, 7.080425195730272],
					[64.889102758323929, 7.080433495174784],
					[64.889102759220648, 7.0804334951107348],
					[64.889288859360747, 7.0804723964693332],
					[64.889288861154228, 7.0804723963412339],
					[64.889288864741218, 7.0804723960850353],
					[64.88928886745866, 7.0804723980034465],
					[64.889305565226238, 7.0804751980156331],
					[64.889449961824809, 7.0805029964880353],
					[64.889505560644139, 7.0805140961468371],
					[64.889505571432309, 7.0805140974888294],
					[64.889505590318478, 7.0805141003649732],
					[64.889505600182687, 7.080514099660439],
					[64.889513882993157, 7.0805140995805989],
					[64.889711066796082, 7.080547398059502],
					[64.889711070383072, 7.0805473978033087],
					[64.889711076687533, 7.080547399465563],
					[64.889711080274509, 7.0805473992093706],
					[64.889738875637249, 7.080550198889739],
					[64.889916671642581, 7.0805751986523147],
					[64.889916673436076, 7.0805751985242198],
					[64.8899166752296, 7.0805751983961214],
					[64.889916676126333, 7.0805751983320748],
					[64.889963876348006, 7.080580799207234],
					[64.890124976187849, 7.0806001999144783],
					[64.890124977981344, 7.0806001997863826],
					[64.890124980671573, 7.080600199594242],
					[64.890124983361829, 7.0806001994020971],
					[64.890188882028752, 7.0806057992126705],
					[64.890336080737271, 7.0806195996700811],
					[64.890336081634004, 7.0806195996060337],
					[64.890336084324261, 7.0806195994138905],
					[64.890336085221008, 7.080619599349844],
					[64.890411085069047, 7.0806251985947686],
					[64.890411085965823, 7.0806251985307194],
					[64.890411086889756, 7.0806252005773196],
					[64.890411087786518, 7.0806252005132704],
					[64.89054718798063, 7.0806335004027625],
					[64.890547188877349, 7.0806335003387169],
					[64.89054719156762, 7.080633500146571],
					[64.890547194257834, 7.0806334999544296],
					[64.890636091952771, 7.0806363003730031],
					[64.890763890671224, 7.0806418997063574],
					[64.890763893361481, 7.0806418995142106],
					[64.890763897845204, 7.0806418991939717],
					[64.890763899638699, 7.0806418990658759],
					[64.89086109971042, 7.0806419009590753],
					[64.890980599973147, 7.0806419009082431],
					[64.890980600869909, 7.080641900844193],
					[64.890980603560138, 7.080641900652048],
					[64.890980605353619, 7.0806419005239531],
					[64.891086105056843, 7.0806390997857367],
					[64.891200005150111, 7.0806362990210365],
					[64.891200006046844, 7.0806362989569891],
					[64.891200008764301, 7.0806363008755486],
					[64.891200009661077, 7.0806363008114968],
					[64.891311110407116, 7.0806307992700876],
					[64.891422209899531, 7.0806252005824488],
					[64.891422210796293, 7.0806252005183969],
					[64.891422214383283, 7.0806252002621939],
					[64.891422215252831, 7.0806251980874171],
					[64.891533314939323, 7.0806168993250074],
					[64.891533315836071, 7.0806168992609573],
					[64.891533317629595, 7.0806168991328526],
					[64.891533319423075, 7.0806168990047507],
					[64.891647219332029, 7.0806057994331812],
					[64.891647220228762, 7.0806057993691294],
					[64.891758320211181, 7.0805945996846447],
					[64.891758322004662, 7.0805945995565409],
					[64.891758325564439, 7.0805945971895783],
					[64.891758328254653, 7.0805945969974227],
					[64.891875026811135, 7.080577998646687],
					[64.891983324750825, 7.0805640981248814],
					[64.891983325647601, 7.0805640980608251],
					[64.891983327441096, 7.0805640979327169],
					[64.892105627326373, 7.0805473970832189],
					[64.892105628223092, 7.0805473970191661],
					[64.892105630940563, 7.0805473989377994],
					[64.892105632706844, 7.080547396698889],
					[64.892205632781739, 7.0805307970070537],
					[64.892338932945179, 7.0805084972399559],
					[64.892338933841941, 7.0805084971758969],
					[64.89233893473866, 7.0805084971118424],
					[64.892338935635436, 7.0805084970477825],
					[64.892430635829442, 7.0804918962606687],
					[64.892430636726175, 7.0804918961966123],
					[64.892430639416432, 7.0804918960044372],
					[64.892430641209927, 7.080491895876321],
					[64.892577841001341, 7.080461296853656],
					[64.892577842767622, 7.0804612946147198],
					[64.892652843232028, 7.080444595568026],
					[64.892816743244182, 7.080408495021377],
					[64.892816744140944, 7.0804084949573154],
					[64.892877844066476, 7.0803945950761129],
					[64.892877844963238, 7.0803945950120495],
					[64.892877845859985, 7.0803945949479887],
					[64.893055645676355, 7.0803529941540413],
					[64.893055648366584, 7.0803529939618484],
					[64.893055649263346, 7.0803529938977823],
					[64.893100049139093, 7.0803418946802026],
					[64.893100050035827, 7.0803418946161392],
					[64.893100052698856, 7.0803418923130872],
					[64.893100055389112, 7.0803418921208907],
					[64.893138952242523, 7.0803307930299431],
					[64.893325049342494, 7.0802834940086097],
					[64.89334724810935, 7.080277992966427],
					[64.893347249033326, 7.080277995013236],
					[64.893347251696326, 7.0802779927101573],
					[64.893347254386569, 7.0802779925179529],
					[64.893547226927467, 7.0802223994046134],
					[64.893552799976177, 7.0802224005518335],
					[64.893552814324153, 7.0802223995267077],
					[64.893552842992833, 7.0802223953655696],
					[64.89355285731358, 7.0802223922295529],
					[64.89360835476036, 7.0802057922590755],
					[64.893758353604554, 7.0801640921132662],
					[64.893758358985025, 7.0801640917288298],
					[64.893758368794821, 7.0801640868022115],
					[64.893758375072053, 7.080164086353701],
					[64.893772263769364, 7.0801584900848038],
					[64.893963953099586, 7.0801057921561563],
					[64.893994452942181, 7.0800973925404822],
					[64.893994453838943, 7.0800973924764055],
					[64.893994453838943, 7.0800973924764055],
					[64.893994454735704, 7.0800973924123296],
					[64.894169454354099, 7.0800473926558443],
					[64.894219453950541, 7.0800334920593206],
					[64.894219454847274, 7.0800334919952439],
					[64.894375054711489, 7.0799890929122871],
					[64.894375055608236, 7.0799890928482068],
					[64.894441755029362, 7.0799695911375862],
					[64.894580654141336, 7.0799307923164383],
					[64.894580654141336, 7.0799307923164383],
					[64.894580655038112, 7.0799307922523536],
					[64.894580655934831, 7.0799307921882724],
					[64.894666752814061, 7.079905792223486],
					[64.894786148384995, 7.0798751944280669],
					[64.894888947301524, 7.0798501942767436],
					[64.894994446131548, 7.079825195365097],
					[64.89499444700111, 7.0798251931900023],
					[64.894994448794591, 7.0798251930618266],
					[64.894994449691325, 7.07982519299774],
					[64.895113945783152, 7.0797945957146124],
					[64.895202842577419, 7.0797751947851051],
					[64.8952028443709, 7.0797751946569258],
					[64.895202845267661, 7.079775194592834],
					[64.895336143308938, 7.0797445950453382],
					[64.895413940942959, 7.0797279965077138],
					[64.89556113878767, 7.0796973961110092],
					[64.895725031906437, 7.0796668973453203],
					[64.895786127329771, 7.0796584988945899],
					[64.895786128226533, 7.0796584988304936],
					[64.895786130916775, 7.0796584986382083],
					[64.895786131786295, 7.0796584964630451],
					[64.895958326758759, 7.0796307973149313],
					[64.89600832210661, 7.0796251994913337],
					[64.896008323003329, 7.0796251994272374],
					[64.896008323900091, 7.0796251993631385],
					[64.89600832476961, 7.0796251971879567],
					[64.896186124219994, 7.0796029987148827],
					[64.89623332089495, 7.0795973979796454],
					[64.896413914908493, 7.0795807997223417],
					[64.89645831317263, 7.0795780001162232],
					[64.89663611215434, 7.0795669009741387],
					[64.896636113051059, 7.0795669009100388],
					[64.896680609140617, 7.0795641005984367],
					[64.896858303119942, 7.0795585007052946],
					[64.89690559985182, 7.0795585007612996],
					[64.89707499527097, 7.0795584999503314],
					[64.897130590282714, 7.0795613004833244],
					[64.897130591179433, 7.0795613004192228],
					[64.897191691002078, 7.0795641004992591],
					[64.897191692795559, 7.0795641003710523],
					[64.897355588810285, 7.0795696001480009],
					[64.897427784630295, 7.0795751994222638],
					[64.897427786423791, 7.0795751992940579],
					[64.897580579855713, 7.0795862981469542],
					[64.897669375410317, 7.0795973969382002],
					[64.897669376334321, 7.079597398985312],
					[64.897669378127816, 7.0795973988571053],
					[64.897669379024578, 7.079597398793001],
					[64.897802777000408, 7.0796112980306525],
					[64.897913872982372, 7.0796251982862914],
					[64.898027768680095, 7.079641897136832],
					[64.898161066337849, 7.0796640961348407],
					[64.898252763273447, 7.0796807972094804],
					[64.898413859885096, 7.0797112966012721],
					[64.898477756928017, 7.0797251944778461],
					[64.898669356578921, 7.0797668950282882],
					[64.898669360193111, 7.0797668968831822],
					[64.898669364676863, 7.0797668965626794],
					[64.898669368263825, 7.0797668963062792],
					[64.898702759791163, 7.07977239607873],
					[64.898916630322006, 7.0798251891705446],
					[64.898927710240699, 7.0798307792388302],
					[64.898927731871524, 7.079830786145731],
					[64.898927776899413, 7.0798307977200041],
					[64.898927800269306, 7.079830800276059],
					[64.898930573775189, 7.0798308006741957],
					[64.89915274321713, 7.0798890919348771],
					[64.899194337328041, 7.0799029892184437],
					[64.899194339121536, 7.0799029890902503],
					[64.899194342762982, 7.0799029930565487],
					[64.899194345453211, 7.0799029928642581],
					[64.899380542911331, 7.0799557920434681],
					[64.899461040945454, 7.0799807911510388],
					[64.899605541029985, 7.080025191432922],
					[64.89973053860939, 7.0800640902996195],
					[64.899830534773358, 7.080097388207756],
					[64.900005534210479, 7.0801584884056084],
					[64.90000553510724, 7.0801584883415165],
					[64.900005536003974, 7.0801584882774264],
					[64.900005536927949, 7.0801584903247337],
					[64.900055536998011, 7.0801751901643959],
					[64.900055537894772, 7.0801751901003049],
					[64.900055539688253, 7.0801751899721239],
					[64.900055540612215, 7.0801751920194373],
					[64.90009163686176, 7.0801862898411754],
					[64.900280400290896, 7.0802529420336278],
					[64.90028040045631, 7.080255600641272],
					[64.90028042918091, 7.0802556704429565],
					[64.900280529211216, 7.080255771075854],
					[64.900280599592932, 7.0802557998597333],
					[64.900286064021643, 7.080255800301309],
					[64.900505531295337, 7.0803362870843696],
					[64.900505533088804, 7.0803362869561974],
					[64.900505534909527, 7.0803362889394625],
					[64.900505536730236, 7.0803362909227481],
					[64.900555533137535, 7.0803529874232725],
					[64.90073052879724, 7.0804195859781807],
					[64.900730529721187, 7.0804195880255527],
					[64.90073053241143, 7.0804195878332994],
					[64.900730534232125, 7.080419589816608],
					[64.900833230748788, 7.0804557870605631],
					[64.900958229240331, 7.0805029868458238],
					[64.90095823013705, 7.0805029867817426],
					[64.900958231061026, 7.0805029888291342],
					[64.901041630789251, 7.080533487755182],
					[64.901183226616581, 7.0805862863699005],
					[64.901230524189685, 7.080605784579638],
					[64.901230525113633, 7.0806057866270553],
					[64.901230527803889, 7.0806057864348144],
					[64.90123052959737, 7.0806057863066556],
					[64.901408230409089, 7.0806723871308757],
					[64.901408234920055, 7.080672388921994],
					[64.901408245735425, 7.0806723923760799],
					[64.90140825114311, 7.0806723941031411],
					[64.901419337199769, 7.080675190589548],
					[64.901480523720096, 7.0807001850121338],
					[64.901480526437567, 7.0807001869314172],
					[64.901480530024543, 7.0807001866751085],
					[64.901480531845252, 7.0807001886584695],
					[64.901633229834118, 7.0807557864778232],
					[64.901669329428273, 7.0807695864825595],
					[64.901669330324992, 7.0807695864184854],
					[64.901669331248982, 7.0807695884659383],
					[64.901858230621201, 7.0808390877579299],
					[64.901858232414682, 7.0808390876297835],
					[64.901988831593442, 7.0808862880865853],
					[64.901988833386937, 7.0808862879584407],
					[64.901988833386937, 7.0808862879584407],
					[64.902083232969034, 7.0809195898870767],
					[64.902083233865753, 7.0809195898230053],
					[64.902083234762515, 7.0809195897589321],
					[64.902180534746734, 7.080952989034091],
					[64.902180535643495, 7.0809529889700187],
					[64.902180538360909, 7.0809529908893794],
					[64.902180539257685, 7.0809529908253062],
					[64.902311034771657, 7.080994588361972],
					[64.902372130837378, 7.0810168875593478],
					[64.902372132658087, 7.0810168895427958],
					[64.902372137141796, 7.0810168892224503],
					[64.90237213893532, 7.0810168890943102],
					[64.902536031615199, 7.0810695876896865],
					[64.90256382472549, 7.0810807863265071],
					[64.9025638292092, 7.0810807860061695],
					[64.902563834644127, 7.0810807898449823],
					[64.902563839127836, 7.0810807895246466],
					[64.902744339014177, 7.0811390890435275],
					[64.902744344449061, 7.0811390928823617],
					[64.902744355237218, 7.0811390942251826],
					[64.902744360644917, 7.0811390959524179],
					[64.90275826063197, 7.0811418967412543],
					[64.902758269626645, 7.0811418982122074],
					[64.902758290306238, 7.0811419009619359],
					[64.902758300170447, 7.0811419002572062],
					[64.90276107008836, 7.0811419010408283],
					[64.902952741903292, 7.0812001916017371],
					[64.902952743696758, 7.0812001914736085],
					[64.902952749104472, 7.081200193200849],
					[64.9029527517947, 7.0812001930086552],
					[64.902986048245566, 7.0812084920732676],
					[64.903149944874301, 7.0812557924616994],
					[64.903149946667796, 7.0812557923335717],
					[64.903149947564543, 7.0812557922695074],
					[64.90321104823218, 7.0812723920176506],
					[64.903211049128942, 7.0812723919535872],
					[64.903211050052903, 7.081272394001175],
					[64.903211050949636, 7.0812723939371125],
					[64.903344348854816, 7.0813057943235442],
					[64.903436047347768, 7.0813307934307899],
					[64.903436049141249, 7.0813307933026675],
					[64.903436052728239, 7.0813307930464182],
					[64.903436055445653, 7.081330794965905],
					[64.903555554209859, 7.0813584941625995],
					[64.903661053774883, 7.0813834947949585],
					[64.903661054671616, 7.0813834947308987],
					[64.903661055568378, 7.0813834946668361],
					[64.903661057361859, 7.0813834945387155],
					[64.90381385588465, 7.0814168958418993],
					[64.903886054978983, 7.081433494022356],
					[64.903886056799678, 7.0814334960059426],
					[64.903886058593159, 7.0814334958778229],
					[64.903886061283416, 7.0814334956856433],
					[64.904066660934404, 7.081469595439053],
					[64.904066661831138, 7.081469595374994],
					[64.904066662755113, 7.0814695974226538],
					[64.904111063452163, 7.0814779979941402],
					[64.904319363197288, 7.0815168965210376],
					[64.904319364094036, 7.0815168964569786],
					[64.904319366784293, 7.0815168962648034],
					[64.904319367681012, 7.0815168962007462],
					[64.904336067684255, 7.081519596662682],
					[64.904336068608217, 7.0815195987103623],
					[64.904477765591793, 7.0815418965714221],
					[64.904552764441931, 7.0815557970735803],
					[64.904552773436578, 7.0815557985447635],
					[64.904552791398714, 7.0815557993753879],
					[64.904552800393361, 7.0815558008465702],
					[64.904561067916845, 7.0815557999923584],
					[64.904569337785176, 7.0815584897189412],
					[64.904569346807023, 7.0815584933018849],
					[64.904569363002878, 7.0815584963723648],
					[64.904569371997553, 7.0815584978435675],
					[64.904786070190937, 7.0815890973825351],
					[64.904819367340266, 7.0815945967214793],
					[64.904819369160961, 7.0815945987051441],
					[64.904819371851175, 7.0815945985129751],
					[64.904819373644699, 7.0815945983848572],
					[64.905011074129206, 7.0816195987017903],
					[64.905011075025968, 7.081619598637733],
					[64.905011077716182, 7.0816195984455641],
					[64.905011078612944, 7.0816195983815069],
					[64.905063877710461, 7.0816251991439172],
					[64.905236077199319, 7.0816445984494614],
					[64.905380576961733, 7.0816612988634065],
					[64.905380577858452, 7.081661298799351],
					[64.905380578755214, 7.0816612987352947],
					[64.905461076672964, 7.0816695988427307],
					[64.90554997459472, 7.0816807972773956],
					[64.905549976415401, 7.0816807992611199],
					[64.905549978208924, 7.0816807991330055],
					[64.905549979105643, 7.0816807990689501],
					[64.905683279290471, 7.0816945985992517],
					[64.905683280187247, 7.0816945985351936],
					[64.905794379560874, 7.0817057987070742],
					[64.905794381354355, 7.0817057985789633],
					[64.905908280750552, 7.0817168981744123],
					[64.905908281647271, 7.0817168981103586],
					[64.905908282544047, 7.0817168980462988],
					[64.906036082637101, 7.0817279982715435],
					[64.90613328167791, 7.0817363000709843],
					[64.906219381321449, 7.0817445999731321],
					[64.906219382218211, 7.0817445999090749],
					[64.906219383114944, 7.0817445998450195],
					[64.906219384011692, 7.0817445997809632],
					[64.90635828115812, 7.0817557996029059],
					[64.906430577030235, 7.0817640984849035],
					[64.906430577926997, 7.0817640984208463],
					[64.906430580617211, 7.0817640982286783],
					[64.906430582410735, 7.0817640981005647],
					[64.906583281287013, 7.0817779987904812],
					[64.906638880394169, 7.0817834994849527],
					[64.906808276923385, 7.0818001986408223],
					[64.906849973022005, 7.0818057983608664],
					[64.906849974815501, 7.0818057982327547],
					[64.906849978402477, 7.0818057979765312],
					[64.906849979326438, 7.0818058000244122],
					[64.907033278099576, 7.0818251993550536],
					[64.907058278305414, 7.0818279986047061],
					[64.907258278342354, 7.0818501984107707],
					[64.907258282826064, 7.0818501980904935],
					[64.90725829361422, 7.0818501994338154],
					[64.907258299891453, 7.0818501989854239],
					[64.907266685918813, 7.0818502003132],
					[64.907366672147859, 7.0818640980439413],
					[64.907366673044621, 7.0818640979798841],
					[64.907366673941382, 7.0818640979158278],
					[64.907472162310441, 7.0818779980266866],
					[64.907483251002091, 7.0818807932990957],
					[64.907483256409762, 7.0818807950267519],
					[64.907483267197918, 7.0818807963700756],
					[64.907483271708841, 7.0818807981617899],
					[64.907680571738112, 7.0819084975424458],
					[64.90768057442834, 7.0819084973502822],
					[64.907680578042545, 7.0819084992060652],
					[64.90768057983604, 7.0819084990779562],
					[64.907708273649945, 7.0819112984269807],
					[64.907883266702598, 7.0819390970506326],
					[64.907930565180905, 7.0819473960870356],
					[64.907930566077624, 7.0819473960229828],
					[64.907930567898362, 7.0819473980068954],
					[64.907930568795081, 7.0819473979428418],
					[64.908088864157321, 7.0819723963994283],
					[64.908155558572957, 7.0819862968446872],
					[64.908155560366481, 7.0819862967165781],
					[64.90815556305671, 7.0819862965244171],
					[64.908155563953471, 7.0819862964603626],
					[64.908291659677175, 7.0820112955255414],
					[64.908380556708892, 7.0820307957730551],
					[64.908380557605668, 7.0820307957090005],
					[64.908380560295882, 7.082030795516844],
					[64.908380561192644, 7.0820307954527886],
					[64.908491659398493, 7.0820529951504962],
					[64.908605557272551, 7.0820779948514376],
					[64.908691655272662, 7.0820973952641042],
					[64.908830550914459, 7.0821307931662272],
					[64.909016648155884, 7.0821807936151497],
					[64.909016650846141, 7.0821807934229986],
					[64.909016656253797, 7.0821807951508111],
					[64.909016658047292, 7.0821807950227109],
					[64.909055550753294, 7.0821890947881627],
					[64.909280542868231, 7.0822557929065884],
					[64.909280557243378, 7.0822557939939488],
					[64.909280586020841, 7.0822557982808227],
					[64.909280600395974, 7.0822557993681841],
					[64.909283300311259, 7.082255801027836],
					[64.909286069303207, 7.0822557998679079],
					[64.90950823456177, 7.0823251892719403],
					[64.90955273028537, 7.0823418859774501],
					[64.909552731209303, 7.0823418880255566],
					[64.909552733926745, 7.0823418899455692],
					[64.90955273572024, 7.082341889817477],
					[64.909733234849497, 7.082402988097658],
					[64.909827730753449, 7.0824362870622215],
					[64.909958226873414, 7.0824862855231698],
					[64.910105525546101, 7.0825445854570335],
					[64.910105527339638, 7.0825445853289493],
					[64.910183225408161, 7.0825751842242459],
					[64.910391616876524, 7.0826612829991298],
					[64.9104082111498, 7.0826695800114701],
					[64.910408212943295, 7.0826695798833939],
					[64.910408216557457, 7.0826695817394842],
					[64.910408219274913, 7.0826695836595901],
					[64.91047771890247, 7.0827001830724416],
					[64.910477719799175, 7.0827001830084075],
					[64.910636014630001, 7.0827695816845893],
					[64.910680509652323, 7.0827918782116503],
					[64.910680512369765, 7.0827918801318086],
					[64.91068051505998, 7.0827918799397072],
					[64.910680515983927, 7.0827918819879132],
					[64.910861014239529, 7.0828751806710226],
					[64.910974910738886, 7.0829307786110443],
					[64.911086010915156, 7.0829862785705826],
					[64.911274910830514, 7.0830807794353916],
					[64.911313807132885, 7.0831001764029153],
					[64.911424903865253, 7.0831612743904673],
					[64.9114249047892, 7.0831612764387408],
					[64.911424908376176, 7.0831612761826461],
					[64.911424909300095, 7.0831612782309232],
					[64.911538805399289, 7.0832195763249821],
					[64.911577701310136, 7.0832418744655961],
					[64.911577702206884, 7.0832418744015744],
					[64.911577705821031, 7.0832418762578202],
					[64.911577706717793, 7.0832418761937976],
					[64.911763805716035, 7.0833390753231864],
					[64.911880504195679, 7.0834029742942919],
					[64.911880506016345, 7.0834029762785935],
					[64.911880508733759, 7.0834029781988788],
					[64.91188051142403, 7.0834029780068244],
					[64.911991607780905, 7.0834584777673184],
					[64.912186004075593, 7.0835640754597513],
					[64.912216604372432, 7.0835807760920115],
					[64.912216605269151, 7.0835807760279996],
					[64.912216606165913, 7.0835807759639859],
					[64.912299904607877, 7.0836251758899644],
					[64.912441604042243, 7.0837029747238196],
					[64.91244160496619, 7.08370297677219],
					[64.912441608580338, 7.0837029786285362],
					[64.91244161127058, 7.0837029784365084],
					[64.912491608887294, 7.0837279784508995],
					[64.912669307799362, 7.0838195781041904],
					[64.912669308696138, 7.0838195780401838],
					[64.912669309592872, 7.0838195779761799],
					[64.912791609846977, 7.0838807787696787],
					[64.912791611667629, 7.0838807807540789],
					[64.912791615254619, 7.0838807804980632],
					[64.912791616178552, 7.0838807825464682],
					[64.912894314691329, 7.083927981897804],
					[64.913086011941331, 7.0840195810177136],
					[64.913119310226065, 7.0840362783371873],
					[64.913119312943493, 7.0840362802576227],
					[64.913119316557626, 7.0840362821140586],
					[64.913119319275054, 7.0840362840345126],
					[64.913238818652516, 7.0840890832528567],
					[64.913344318399055, 7.0841362829971368],
					[64.913344322013202, 7.0841362848536216],
					[64.913344328317621, 7.0841362865180919],
					[64.913344331931768, 7.084136288374558],
					[64.913374928324316, 7.0841473874797893],
					[64.913572124250209, 7.084227985103638],
					[64.913572126967594, 7.0842279870241462],
					[64.91357213416876, 7.084227988624658],
					[64.913572136886145, 7.0842279905451448],
					[64.913655534849624, 7.0842557893468205],
					[64.913797133097489, 7.0843057893033858],
					[64.913797135787732, 7.0843057891114078],
					[64.913797141195374, 7.0843057908399594],
					[64.913797143016026, 7.0843057928244635],
					[64.913927742890223, 7.0843445913686391],
					[64.913927743786957, 7.0843445913046477],
					[64.914022144157343, 7.0843723922690467],
					[64.914022145054105, 7.0843723922050543],
					[64.914022148668238, 7.0843723940615977],
					[64.914022151358495, 7.0843723938696224],
					[64.914188851069795, 7.0844140934747752],
					[64.914188851966529, 7.0844140934107855],
					[64.91418885286329, 7.0844140933467941],
					[64.914188853787181, 7.0844140953953456],
					[64.914247153747283, 7.0844279948568598],
					[64.91424715643754, 7.0844279946648889],
					[64.914247159154911, 7.0844279965854451],
					[64.914247160948406, 7.0844279964574648],
					[64.914444360859974, 7.0844668965588493],
					[64.914472161091851, 7.0844723959702325],
					[64.914472164705998, 7.0844723978268185],
					[64.914472170983245, 7.0844723973788932],
					[64.914472174570207, 7.084472397122938],
					[64.914691640423527, 7.0845001937797702],
					[64.9146971092038, 7.0845029774124706],
					[64.914697129964793, 7.0845029865035452],
					[64.914697171378123, 7.0845029962354387],
					[64.914697192981549, 7.0845030010374055],
					[64.914774989027876, 7.0845057999484107],
					[64.914922185065109, 7.0845168983817297],
					[64.914922188679242, 7.0845169002383512],
					[64.914922195853237, 7.0845168997264381],
					[64.91492220033696, 7.084516899406494],
					[64.914930598912889, 7.0845169006342656],
					[64.915144397019873, 7.0845195990718182],
					[64.915144397916606, 7.0845195990078285],
					[64.915144398840525, 7.0845196010564537],
					[64.915144399737244, 7.0845196009924667],
					[64.915161099904111, 7.0845195997953674],
					[64.915161102594325, 7.0845195996033992],
					[64.915161107974825, 7.0845195992194601],
					[64.915161110665039, 7.0845195990274927],
					[64.915369405184208, 7.0845084994304486],
					[64.91538890040097, 7.0845084996560566],
					[64.915388904884722, 7.0845084993361027],
					[64.915388915645664, 7.0845084985682156],
					[64.915388921026164, 7.0845084981842712],
					[64.915594421418106, 7.0844862996071765],
					[64.915594424081192, 7.0844862973025693],
					[64.915594430385582, 7.0844862989672563],
					[64.915594433048639, 7.0844862966626501],
					[64.915611131354694, 7.0844834979922666],
					[64.915816728764028, 7.0844529986681239],
					[64.915816737704361, 7.0844529959155373],
					[64.915816754715379, 7.0844529925870097],
					[64.915816763655656, 7.0844529898344062],
					[64.915825051755078, 7.0844501928600341],
					[64.91596114019454, 7.0844223950444984],
					[64.915961141091259, 7.0844223949805043],
					[64.915961141988021, 7.0844223949165084],
					[64.915961142884754, 7.0844223948525142],
					[64.916036121628153, 7.084405800707378],
					[64.916041700016507, 7.0844058001541548],
					[64.916041711674225, 7.0844057993222069],
					[64.916041735859224, 7.0844057954816302],
					[64.916041746620166, 7.0844057947136809],
					[64.916247246750828, 7.0843557937894506],
					[64.916247249441085, 7.0843557935974548],
					[64.916247253028061, 7.0843557933414614],
					[64.916247254794385, 7.0843557911007844],
					[64.916266754327339, 7.0843501934839717],
					[64.916452851690948, 7.0843001934851131],
					[64.916452854381205, 7.0843001932931111],
					[64.916452857044291, 7.0843001909884107],
					[64.916452858837786, 7.0843001908604082],
					[64.916488959069341, 7.0842890902618372],
					[64.916488959966088, 7.0842890901978368],
					[64.916655658947448, 7.0842362910632737],
					[64.916711158154968, 7.0842195911989725],
					[64.916711159948449, 7.0842195910709638],
					[64.916711162638677, 7.084219590878952],
					[64.916711165301763, 7.0842195885742214],
					[64.916855664768931, 7.0841695890001937],
					[64.91693616523537, 7.0841418885854459],
					[64.916936166132089, 7.0841418885214402],
					[64.916936168822346, 7.0841418883294169],
					[64.916936169691894, 7.0841418861526755],
					[64.917055669861043, 7.0840973877816813],
					[64.917055670757776, 7.0840973877176729],
					[64.917158371278163, 7.0840584866909744],
					[64.91725287081745, 7.0840223869790524],
					[64.917252871714226, 7.0840223869150387],
					[64.917383371720788, 7.0839723864286261],
					[64.917383372617564, 7.0839723863646107],
					[64.917383376177355, 7.0839723839957838],
					[64.91738337710126, 7.0839723860445414],
					[64.917450076107741, 7.0839445848519578],
					[64.917605675974116, 7.0838807843119147],
					[64.917605676870821, 7.0838807842478975],
					[64.917605677767597, 7.0838807841838785],
					[64.91760567866433, 7.0838807841198612],
					[64.917644478557946, 7.0838640844648069],
					[64.917827878286232, 7.083786284373196],
					[64.917827880949304, 7.0837862820683259],
					[64.917827886302604, 7.0837862795713695],
					[64.917827888992861, 7.0837862793793027],
					[64.917838984056587, 7.08378078194536],
					[64.917897279230374, 7.083755783350127],
					[64.917897280127136, 7.0837557832861036],
					[64.918030679777104, 7.0836973827375136],
					[64.91803068336408, 7.0836973824814109],
					[64.918030686923885, 7.083697380112489],
					[64.91803068871738, 7.0836973799844385],
					[64.918052885576245, 7.0836862808481005],
					[64.918222283155771, 7.0836084820200877],
					[64.918222284052518, 7.0836084819560599],
					[64.918222284949266, 7.0836084818920293],
					[64.918222285845985, 7.0836084818280023],
					[64.918275085154733, 7.0835834815554746],
					[64.918413984327131, 7.0835195814849019],
					[64.918413985223907, 7.0835195814208678],
					[64.918413987886936, 7.0835195791159222],
					[64.918413988783712, 7.0835195790518863],
					[64.918497286712054, 7.0834779797842904],
					[64.91860288619813, 7.083427980146328],
					[64.91860288619813, 7.083427980146328],
					[64.918602887094849, 7.0834279800822939],
					[64.918602887991625, 7.0834279800182571],
					[64.918722288144068, 7.0833695798935183],
					[64.918722289040815, 7.0833695798294807],
					[64.918722290807111, 7.0833695775885293],
					[64.918722291703887, 7.0833695775244889],
					[64.918791791094904, 7.0833334778526886],
					[64.918944491403593, 7.0832557778981728],
					[64.918944492300326, 7.0832557778341307],
					[64.918944494093807, 7.0832557777060456],
					[64.918944494963398, 7.0832557755290884],
					[64.918980695316748, 7.0832362757164784],
					[64.919166749922979, 7.0831363001117333],
					[64.919169400432523, 7.0831362990288493],
					[64.919169424644679, 7.0831362972995677],
					[64.919169473829768, 7.0831362832123874],
					[64.919169497933183, 7.0831362730314646],
					[64.919352896153157, 7.0830334741674843],
					[64.919388994571378, 7.0830140761981175],
					[64.919388996364873, 7.0830140760700129],
					[64.919388998158354, 7.08301407594191],
					[64.919388999027944, 7.0830140737649092],
					[64.919536198641566, 7.0829307737677585],
					[64.91953620133178, 7.082930773575594],
					[64.919536202201357, 7.0829307713985994],
					[64.919611202336441, 7.0828862716651706],
					[64.919719500418253, 7.0828223723402814],
					[64.919836199113391, 7.0827557743765093],
					[64.919836200879701, 7.0827557721354228],
					[64.919836203569915, 7.0827557719432379],
					[64.919836206232986, 7.0827557696380881],
					[64.919902903929668, 7.0827140700003293],
					[64.920058401970579, 7.0826223718766004],
					[64.920058403764088, 7.0826223717484664],
					[64.920058408193441, 7.0826223672021706],
					[64.920058410883684, 7.0826223670099679],
					[64.920083409125624, 7.0826057680775412],
					[64.920197306667887, 7.0825334698162905],
					[64.920264005601339, 7.0824918704760371],
					[64.920264007367649, 7.0824918682348974],
					[64.92026400913393, 7.0824918659937577],
					[64.920264010954611, 7.08249186797861],
					[64.920280709219625, 7.0824807684981552],
					[64.920444505921012, 7.0823779697283484],
					[64.920444506817745, 7.0823779696642726],
					[64.920444510377521, 7.0823779672949581],
					[64.920444511247098, 7.0823779651178702],
					[64.920502910069501, 7.0823390667066626],
					[64.920622308814501, 7.0822612674977812],
					[64.920725108025053, 7.0821945688522367],
					[64.920802907607197, 7.0821445690431837],
					[64.920802909400678, 7.0821445689150133],
					[64.920802909373478, 7.0821445668019756],
					[64.920947308899244, 7.0820501677640806],
					[64.920947309795991, 7.0820501676999932],
					[64.920947310692753, 7.0820501676359022],
					[64.920980709840251, 7.0820279673417623],
					[64.921116809346117, 7.0819390670834013],
					[64.921116810242879, 7.0819390670193068],
					[64.921116811139598, 7.0819390669552149],
					[64.921116811139598, 7.0819390669552149],
					[64.921158411074941, 7.0819112655395369],
					[64.921158412868451, 7.0819112654113479],
					[64.921158418221694, 7.0819112629137182],
					[64.921158419987989, 7.0819112606724657],
					[64.92116951485734, 7.0819029636061028],
					[64.921339009885585, 7.0817918677796357],
					[64.921339011651895, 7.0817918655383592],
					[64.921339013445376, 7.0817918654101621],
					[64.921391811445702, 7.0817557674148626],
					[64.921516807930089, 7.0816751685775792],
					[64.921616808423153, 7.0816112690918924],
					[64.921616808395953, 7.0816112669787916],
					[64.9216945081982, 7.0815612687832568],
					[64.921839005895364, 7.0814695699852583],
					[64.921875104859154, 7.081447369774339],
					[64.922041804577248, 7.0813445708022247],
					[64.922041807240291, 7.0813445684967231],
					[64.922041809903305, 7.081344566191242],
					[64.922041810800053, 7.081344566127127],
					[64.922058361308174, 7.0813334990053889],
					[64.922061099806015, 7.0813334999090722],
					[64.922061126681129, 7.081333495872455],
					[64.922061178556277, 7.0813334815880227],
					[64.922061203556282, 7.0813334713402094],
					[64.922239000862945, 7.081225173433193],
					[64.922283397677589, 7.0812001731682859],
					[64.922283398574336, 7.0812001731041629],
					[64.922283400367832, 7.0812001729759215],
					[64.922422299936215, 7.0811195729427929],
					[64.922422300832963, 7.0811195728786691],
					[64.922422301729711, 7.0811195728145444],
					[64.92242230262643, 7.0811195727504215],
					[64.922505699162684, 7.0810695745435304],
					[64.922608395306597, 7.0810140755939415],
					[64.922608396203344, 7.0810140755298132],
					[64.922608397100078, 7.0810140754656858],
					[64.922727894212912, 7.0809473763106503],
					[64.92279448874325, 7.0809140783810358],
					[64.922794489640012, 7.0809140783169031],
					[64.922794489640012, 7.0809140783169031],
					[64.922794491433507, 7.0809140781886377],
					[64.92295288968846, 7.0808334786543297],
					[64.922980687948552, 7.0808195800616556],
					[64.923155686069578, 7.0807362818609194],
					[64.923155686939083, 7.0807362796835598],
					[64.923155688732564, 7.0807362795552811],
					[64.923172247291674, 7.0807280009220657],
					[64.923175000136879, 7.0807280007359887],
					[64.923175020734746, 7.080727997147557],
					[64.923175062827312, 7.0807279899065509],
					[64.923175083370793, 7.080727982091652],
					[64.923363982828306, 7.0806418819526211],
					[64.923363984621787, 7.0806418818243344],
					[64.923363988181535, 7.0806418794545269],
					[64.923363989975016, 7.0806418793262402],
					[64.923397283002814, 7.0806251823055408],
					[64.923558375205047, 7.080558486426793],
					[64.923622273440628, 7.0805334869329783],
					[64.923755671858501, 7.0804807875968336],
					[64.92384446908126, 7.0804473879790084],
					[64.923952865705232, 7.0804084888008942],
					[64.924066765315942, 7.0803695890457554],
					[64.924155661815405, 7.0803390900214964],
					[64.92429175727537, 7.0802973917417882],
					[64.924358355689961, 7.0802779914405169],
					[64.924358358380175, 7.0802779912480442],
					[64.924358358380175, 7.0802779912480442],
					[64.924513956262246, 7.080230791217808],
					[64.924702850080365, 7.080177993046834],
					[64.924738945388057, 7.0801695954577335],
					[64.924738946284819, 7.0801695953935697],
					[64.924738948051072, 7.0801695931518998],
					[64.924738948947819, 7.0801695930877369],
					[64.924947244117789, 7.0801168949619235],
					[64.924961139249248, 7.0801140963529088],
					[64.92496114014601, 7.0801140962887432],
					[64.924961141912263, 7.0801140940470502],
					[64.924961142808996, 7.0801140939828855],
					[64.925186143319721, 7.0800640960287478],
					[64.925186200302889, 7.0800640602212637],
					[64.92518627804553, 7.0800639636990415],
					[64.925186299674493, 7.0800639008067181],
					[64.925186300288289, 7.0800613010201028],
					[64.925191700052196, 7.0800612996229164],
					[64.925191710813138, 7.0800612988529039],
					[64.925191732334966, 7.0800612973128771],
					[64.925191743068652, 7.080061294429485],
					[64.925408337998306, 7.0800140970290597],
					[64.92542503262878, 7.0800112968283484],
					[64.925425035319023, 7.0800112966358375],
					[64.925425039802747, 7.0800112963149857],
					[64.925425041596228, 7.0800112961866457],
					[64.925633341302131, 7.0799668957721567],
					[64.925661138702793, 7.0799612956148543],
					[64.925858332751019, 7.0799223980029149],
					[64.925897228104162, 7.0799168983430141],
					[64.925897230794391, 7.0799168981504916],
					[64.925897236174862, 7.0799168977654414],
					[64.925897238837848, 7.0799168954594833],
					[64.926080639155956, 7.0798807950990419],
					[64.926080640949436, 7.0798807949706886],
					[64.926080641873412, 7.0798807970199613],
					[64.926133338411262, 7.079869596422097],
					[64.926305635229724, 7.0798390962900477],
					[64.926305637023191, 7.0798390961616899],
					[64.926305640610167, 7.079839095904978],
					[64.92630564330041, 7.0798390957124413],
					[64.92636944032094, 7.0798251965990646],
					[64.926527837874175, 7.0797945969876848],
					[64.926527838770937, 7.0797945969235032],
					[64.926527840564404, 7.0797945967951419],
					[64.926527842357885, 7.0797945966667815],
					[64.926605640773644, 7.079777996522008],
					[64.926752841119892, 7.0797473960020989],
					[64.926844441304127, 7.0797279968851852],
					[64.926844442200874, 7.0797279968210001],
					[64.926975042150318, 7.0797001959099219],
					[64.926975042150318, 7.0797001959099219],
					[64.926975043943798, 7.0797001957815526],
					[64.926975044840518, 7.0797001957173693],
					[64.927083341268286, 7.0796751967706069],
					[64.927200037305326, 7.0796529959790497],
					[64.927200039995526, 7.07965299578649],
					[64.927200046272731, 7.0796529953371792],
					[64.927200048935745, 7.0796529930310781],
					[64.927322244790716, 7.079622395426048],
					[64.927425041950755, 7.0796001947949678],
					[64.92742504374425, 7.079600194666587],
					[64.927425046434493, 7.0796001944740183],
					[64.927425048227974, 7.0796001943456393],
					[64.927561145842688, 7.0795668959958453],
					[64.927647244377226, 7.0795473944274958],
					[64.927647246170693, 7.0795473942991123],
					[64.927647249757669, 7.0795473940423461],
					[64.927647252447898, 7.0795473938497686],
					[64.927802848701361, 7.0795057940038761],
					[64.927872247229985, 7.0794890932452912],
					[64.92787224902348, 7.0794890931169023],
					[64.928047249255513, 7.0794445929504155],
					[64.928047251048994, 7.0794445928220213],
					[64.928047253739223, 7.0794445926294323],
					[64.928047256429466, 7.0794445924368405],
					[64.928094452343302, 7.0794307924794637],
					[64.928288946716762, 7.0793834932944177],
					[64.928288949407019, 7.079383493101818],
					[64.928288951200486, 7.0793834929734194],
					[64.928288952993967, 7.0793834928450217],
					[64.92831945227887, 7.0793751932043749],
					[64.928530649355807, 7.0793195931971393],
					[64.928541747139946, 7.0793168946142524],
					[64.928541748036665, 7.0793168945500513],
					[64.928677847867363, 7.0792834936909532],
					[64.928766747911524, 7.0792612933348682],
					[64.92876675864521, 7.0792612904507477],
					[64.928766780085326, 7.0792612825688277],
					[64.928766790791727, 7.0792612775710486],
					[64.928772270412466, 7.079258488249689],
					[64.928988945079794, 7.07920579451243],
					[64.929013943831919, 7.0792001943253968],
					[64.929213942627328, 7.0791557959001192],
					[64.929252839169791, 7.0791473958318312],
					[64.929436133605037, 7.0791140961605885],
					[64.929605632361898, 7.0790862966337604],
					[64.929605634155379, 7.0790862965053334],
					[64.929605637742341, 7.0790862962484811],
					[64.929605638639089, 7.0790862961842667],
					[64.929661132387494, 7.0790751989126903],
					[64.929822224152986, 7.079055799366027],
					[64.929822226815986, 7.0790557970596382],
					[64.929822232196429, 7.079055796674349],
					[64.929822234886672, 7.0790557964817049],
					[64.929886124111903, 7.0790445992748605],
					[64.930044413812624, 7.0790334989777906],
					[64.930044417399571, 7.0790334987209267],
					[64.930044422780028, 7.0790334983356287],
					[64.930044426367019, 7.079033498078763],
					[64.930108315248319, 7.0790251992795232],
					[64.930272203263499, 7.0790223998608202],
					[64.93027220505698, 7.079022399732386],
					[64.930272207747223, 7.079022399539733],
					[64.930272208643942, 7.0790223994755159],
					[64.930333300675585, 7.0790196007841466],
					[64.930502792743496, 7.0790251993764235],
					[64.930502798123939, 7.0790251989911157],
					[64.930502806221867, 7.0790252005269725],
					[64.930502809808829, 7.0790252002701024],
					[64.930558295610425, 7.0790224010360276],
					[64.930741682383243, 7.0790390983732907],
					[64.93074168327999, 7.0790390983090727],
					[64.930741685073471, 7.0790390981806359],
					[64.930741686894194, 7.0790391001660158],
					[64.930783278666496, 7.0790418998433156],
					[64.930988870547296, 7.0790723975188561],
					[64.931008263630346, 7.0790751980039781],
					[64.931144354343218, 7.0791057942039117],
					[64.931233238611398, 7.079127990359205],
					[64.931247125715231, 7.0791334850810177],
					[64.931247130226183, 7.0791334868737916],
					[64.931247135661124, 7.0791334907162042],
					[64.931247140144848, 7.0791334903951215],
					[64.931458232065935, 7.0792001886116633],
					[64.93151932415536, 7.0792251858374486],
					[64.931519325052079, 7.0792251857732351],
					[64.931519326845574, 7.0792251856448063],
					[64.931683219806686, 7.0792890824673274],
					[64.931808211888793, 7.079350179671783],
					[64.931808316780405, 7.0793501700452204],
					[64.931808458978921, 7.0793500604179949],
					[64.93180849631311, 7.079349962531249],
					[64.931752996345324, 7.0790638619608108],
					[64.931752988871722, 7.0790638392216785],
					[64.931752970392012, 7.0790637982280886],
					[64.931752957565195, 7.079063777988174],
					[64.931677964211886, 7.0789665851743662],
					[64.931527969267862, 7.0787304923328156],
					[64.931527892390847, 7.0787304470592609],
					[64.93152773157432, 7.0787304353038589],
					[64.931527649428276, 7.078730468693549],
					[64.931452925744068, 7.0788162519341169],
					[64.931408496926394, 7.0785582652739585],
					[64.93140849034944, 7.0785582424710238],
					[64.9314084682009, 7.0785581953936463],
					[64.931408455346727, 7.0785581730403653],
					[64.931222360934569, 7.0783304810070078],
					[64.931036265659159, 7.0780554872184123],
					[64.931036263838422, 7.0780554852330777],
					[64.931036261093695, 7.0780554811981551],
					[64.931036260169691, 7.0780554791485706],
					[64.930994560184743, 7.0779998806958009],
					[64.930994559260753, 7.077999878646219],
					[64.93099455561925, 7.0779998746755455],
					[64.930994554722517, 7.0779998747397972],
					[64.930933454862142, 7.0779248747031369],
					[64.930933453938152, 7.0779248726535613],
					[64.930933451193397, 7.0779248686186707],
					[64.93093344937266, 7.0779248666333512],
					[64.930766857908523, 7.0777387778050125],
					[64.930644565986768, 7.0775554892122265],
					[64.93064456413876, 7.0775554851131481],
					[64.930644557752515, 7.077555477107774],
					[64.93064455500776, 7.0775554730729624],
					[64.930536255963005, 7.0774220755911585],
					[64.930397356895185, 7.0772470765660271],
					[64.930397353226439, 7.077247070481774],
					[64.930397345046686, 7.0772470626051005],
					[64.930397341405197, 7.0772470586346135],
					[64.930308456310243, 7.077158172880555],
					[64.930241868318973, 7.0770554900364164],
					[64.930241863780722, 7.0770554861302832],
					[64.930241858263926, 7.0770554759470885],
					[64.930241853725661, 7.0770554720409553],
					[64.930080755171844, 7.0768609724435585],
					[64.929880755022893, 7.076613773631478],
					[64.929880752278109, 7.0766137695968787],
					[64.929880744995074, 7.0766137616562501],
					[64.929880741380842, 7.0766137597996916],
					[64.929852952092105, 7.0765859695539532],
					[64.929830762044929, 7.076555482351754],
					[64.929830760224178, 7.0765554803666095],
					[64.929830756582646, 7.076555476396301],
					[64.929830754761895, 7.0765554744111565],
					[64.929625155399179, 7.0763026731023286],
					[64.929625155399179, 7.0763026731023286],
					[64.929422354700549, 7.076055473948399],
					[64.929397356399505, 7.076024875953836],
					[64.929366857431248, 7.0759859771513494],
					[64.929366857403977, 7.0759859750376464],
					[64.929366854686464, 7.0759859731168691],
					[64.929366853789716, 7.0759859731811865],
					[64.929169554958918, 7.0757470730604064],
					[64.929014056027029, 7.0755554748967464],
					[64.928941857018103, 7.0754637752207863],
					[64.928941856121355, 7.0754637752851188],
					[64.928841857386772, 7.0753387755881496],
					[64.92871405832679, 7.0751748766348994],
					[64.928714057430071, 7.0751748766992399],
					[64.928714057430071, 7.0751748766992399],
					[64.928619558384, 7.0750554779990855],
					[64.928486257853862, 7.0748831777407339],
					[64.928291859265258, 7.0746304778076476],
					[64.92829185836851, 7.0746304778720068],
					[64.928291855650983, 7.074630475951464],
					[64.928291854726936, 7.0746304739021841],
					[64.928255757600724, 7.0745859781346567],
					[64.928233461088709, 7.0745554829589334],
					[64.928027961943897, 7.07427488271661],
					[64.927869563980025, 7.0740526856087209],
					[64.927800164826607, 7.0739526860202568],
					[64.927669565190612, 7.0737609870648628],
					[64.927669564266537, 7.0737609850156824],
					[64.927669564266537, 7.0737609850156824],
					[64.92757236516735, 7.07362208781191],
					[64.927522366781432, 7.0735470905294466],
					[64.927341868682959, 7.0732692940629658],
					[64.927200169965658, 7.0730415933976714],
					[64.927114071299101, 7.0729026950181941],
					[64.92689737196487, 7.0725359986792569],
					[64.926897371937528, 7.0725359965657519],
					[64.92689737011672, 7.0725359945810951],
					[64.92689737011672, 7.0725359945810951],
					[64.926883472265629, 7.0725137990091422],
					[64.92683077518933, 7.0724193024389121],
					[64.926652975098904, 7.0720999039607131],
					[64.926614077381515, 7.0720277063472645],
					[64.92642517772039, 7.0716582090994908],
					[64.92635297934568, 7.0715166106557321],
					[64.926194579597805, 7.0711860134517384],
					[64.926108480972644, 7.0710055159349992],
					[64.925961283288757, 7.0706832195972869],
					[64.925880783886882, 7.0704916228850259],
					[64.925880783886882, 7.0704916228850259],
					[64.925880783859526, 7.070491620771584],
					[64.925730783934796, 7.0701443224495994],
					[64.925664085883213, 7.0699805243220277],
					[64.925500185614794, 7.0695693259172305],
					[64.925500184718047, 7.0695693259817505],
					[64.925458485989282, 7.0694666278756175],
					[64.925266887894651, 7.0689666319610742],
					[64.925261289704466, 7.0689499365197044],
					[64.925261288780362, 7.0689499344708704],
					[64.925261286932127, 7.0689499303731838],
					[64.925261286904743, 7.0689499282598121],
					[64.92523348694975, 7.0688777291170046],
					[64.925072388036767, 7.0684360315778259],
					[64.925072387112635, 7.0684360295290256],
					[64.925072387112635, 7.0684360295290256],
					[64.925033487614954, 7.0683332326617023],
					[64.92488908866541, 7.0679193336850012],
					[64.92488908866541, 7.0679193336850012],
					[64.924889087741249, 7.0679193316362339],
					[64.924889087741249, 7.0679193316362339],
					[64.924800188389952, 7.0676777319194368],
					[64.924705789114128, 7.0674055343646609],
					[64.924569589341999, 7.0670138341746735],
					[64.924569588417825, 7.0670138321259621],
					[64.924569586569532, 7.0670138280285153],
					[64.924569585645372, 7.0670138259798021],
					[64.924519587678702, 7.0668888309599422],
					[64.924383489822333, 7.0664805364252032],
					[64.924347390249096, 7.0663721365199583],
					[64.924336289578747, 7.0663388361222008],
					[64.924336288682014, 7.066338836186822],
					[64.924336288654601, 7.0663388340735072],
					[64.924336287730426, 7.0663388320248357],
					[64.924164089147169, 7.0658555340194571],
					[64.924102988842108, 7.0656805348269396],
					[64.924102987917948, 7.0656805327783063],
					[64.924102987917948, 7.0656805327783063],
					[64.923980788017033, 7.0653416311697166],
					[64.923869587801974, 7.0650360328043806],
					[64.923869586877814, 7.0650360307557891],
					[64.92386958592617, 7.0650360265939218],
					[64.923869585001995, 7.0650360245453294],
					[64.923783486442431, 7.0648277275058735],
					[64.923636287838278, 7.06442773019248],
					[64.923636286017356, 7.0644277282086128],
					[64.923636284141551, 7.0644277219982481],
					[64.923636282293188, 7.0644277179011423],
					[64.923583485340117, 7.0643110245221941],
					[64.923405788165809, 7.063830531118481],
					[64.923405786317431, 7.0638305270214516],
					[64.92340578079974, 7.063830516843562],
					[64.923405778923865, 7.063830510633295],
					[64.923389083681826, 7.063797119032734],
					[64.923308487201822, 7.0635805301457184],
					[64.923308487174353, 7.0635805280325066],
					[64.923308486250178, 7.0635805259840065],
					[64.92330848532599, 7.0635805239355083],
					[64.923186285279499, 7.0632832240789272],
					[64.923186284355324, 7.0632832220304467],
					[64.923186283431107, 7.063283219981968],
					[64.923186281610171, 7.0632832179982117],
					[64.923172382991325, 7.0632527196685437],
					[64.922966884246932, 7.0627693212717144],
					[64.922966884246932, 7.0627693212717144],
					[64.922966883322701, 7.0627693192232526],
					[64.922966882398512, 7.0627693171748049],
					[64.922941882341249, 7.0627138178805993],
					[64.922941882341249, 7.0627138178805993],
					[64.922733483286891, 7.0622582207822351],
					[64.922711283977165, 7.0622055215776518],
					[64.922711283052934, 7.0622055195292468],
					[64.922711281204514, 7.0622055154324155],
					[64.922711280280311, 7.0622055133840087],
					[64.922491880141109, 7.0617471144579831],
					[64.922491880113611, 7.0617471123448192],
					[64.922491879189408, 7.0617471102964444],
					[64.922480779110316, 7.0617249099895485],
					[64.922480779110316, 7.0617249099895485],
					[64.922480778213583, 7.0617249100543207],
					[64.922372377896153, 7.0615110092983597],
					[64.922372376944409, 7.0615110051368504],
					[64.922372373275039, 7.0615109990565603],
					[64.922372370557326, 7.0615109971377432],
					[64.922250173197924, 7.0613081987804538],
					[64.922211274071913, 7.0612388012743796],
					[64.922211273147681, 7.0612387992260253],
					[64.922211270375016, 7.0612387930810216],
					[64.922211267657318, 7.0612387911622632],
					[64.922022372092997, 7.0609470988074268],
					[64.921905775675611, 7.0607305059804801],
					[64.921905773827135, 7.060730501883894],
					[64.921905769233476, 7.0607304937555018],
					[64.921905767384999, 7.0607304896589147],
					[64.921791871814989, 7.0605581984184989],
					[64.921616877107212, 7.0602249075711665],
					[64.921616875258735, 7.060224903474638],
					[64.921616871589279, 7.0602248973946953],
					[64.921616869740802, 7.0602248932981668],
					[64.921561273572678, 7.0601360015940413],
					[64.92134737803606, 7.0597138098521119],
					[64.921347376160071, 7.0597138036426061],
					[64.921347369745348, 7.0597137935311798],
					[64.921347367000109, 7.0597137894995843],
					[64.921330772990885, 7.0596887986995185],
					[64.921250178324968, 7.0595332083933924],
					[64.921250122039581, 7.0595331595873825],
					[64.92124999309128, 7.0595331139205468],
					[64.921249918634928, 7.059533117189364],
					[64.921105611048119, 7.0595969753277759],
					[64.921027899302459, 7.0595526269988245],
					[64.921027840794679, 7.0595526143092293],
					[64.921027727806305, 7.0595526224791501],
					[64.921027673353223, 7.0595526454517135],
					[64.920883175280181, 7.0596720441507967],
					[64.920858176726512, 7.0596915415285668],
					[64.920858174960571, 7.0596915437712511],
					[64.920858172297869, 7.059691546078791],
					[64.92085816963521, 7.0596915483863105],
					[64.920783171649106, 7.0597554464088033],
					[64.920739125681735, 7.0597917009920588],
					[64.915230778950061, 7.0497666104839611],
					[64.907997381616994, 7.0341388165449938],
					[64.907997380692365, 7.0341388144986761],
					[64.907997379795688, 7.0341388145643249],
					[64.907997379767792, 7.0341388124523556],
					[64.903586280360315, 7.0250138125585968],
					[64.903586273943276, 7.0250138024621149],
					[64.903586262005931, 7.0250137822032057],
					[64.903586254720196, 7.0250137742842931],
					[64.901558455331411, 7.0225137743644579],
					[64.901558377653103, 7.022513737813088],
					[64.901558222353088, 7.0225137365657346],
					[64.901558144759363, 7.0225137739812018],
					[64.89716376901788, 7.0279220447772586],
					[64.89220564761068, 7.0306636441969808],
					[64.887436254290705, 7.0248998712425994],
					[64.887436236132331, 7.0248998556777664],
					[64.887436195359982, 7.0248998269879692],
					[64.887436172745993, 7.0248998138629686],
					[64.882447336637142, 7.0229359387390931],
					[64.876594584135631, 7.0131388190341193],
					[64.87217799097543, 6.9984860427701108],
					[64.872177990021981, 6.9984860386186174],
					[64.872177987218379, 6.9984860303823453],
					[64.872177986264916, 6.9984860262308528],
					[64.867827986303411, 6.9874777250091373],
					[64.867827981734308, 6.9874777190182957],
					[64.86782797522919, 6.9874777026177997],
					[64.86782796973489, 6.9874776945852854],
					[64.865477970193794, 6.9836498959947653],
					[64.865477876194007, 6.983649848161348],
					[64.865477697779497, 6.9836498636439694],
					[64.865477613364803, 6.9836499269600063],
					[64.862785912873434, 6.9906332279837597],
					[64.86278591116546, 6.9906332344427895],
					[64.862785907721033, 6.9906332452525053],
					[64.862785905984595, 6.9906332496031682],
					[64.860069208340619, 7.0012360447300246],
					[64.855888709979652, 7.0145555378393141],
					[64.853180456704848, 7.0221692040195114],
					[64.847016693706863, 7.0223414992014197],
					[64.84701662589228, 7.022341529493068],
					[64.847016529354775, 7.0223416272805919],
					[64.8470164997631, 7.0223416969495451],
					[64.846899799765467, 7.0291360960485427],
				],
				[
					[64.751380401047641, 6.9734278169754473],
					[64.75217480067397, 6.9835250168955429],
					[64.752174807350741, 6.9835250458237947],
					[64.752174829642854, 6.9835251009118808],
					[64.752174845229632, 6.9835251249719832],
					[64.757216543726329, 6.9897223257429228],
					[64.760933114851355, 6.9944111884785283],
					[64.763030405419968, 7.0039028420375029],
					[64.763030407354805, 7.003902852408082],
					[64.763030412064552, 7.0039028688818199],
					[64.763030414867814, 7.0039028770855563],
					[64.765427614879101, 7.0096333774814488],
					[64.765427629564982, 7.0096334016343826],
					[64.76542766605418, 7.0096334452103326],
					[64.765427686960734, 7.0096334646993901],
					[64.770460987112386, 7.0130834654287524],
					[64.770461007009231, 7.0130834765852086],
					[64.770461049408667, 7.0130834923967242],
					[64.770461071014523, 7.0130834971177061],
					[64.776663773669867, 7.0139862836608486],
					[64.778930406556597, 7.0179722650913767],
					[64.780219201723412, 7.0269028277201953],
					[64.781555401961967, 7.0371194251324107],
					[64.781555401989763, 7.0371194272344946],
					[64.78155540201756, 7.0371194293365811],
					[64.78155540201756, 7.0371194293365811],
					[64.783394201845653, 7.0491278297376274],
					[64.783394208399201, 7.0491278503066068],
					[64.783394222375421, 7.0491278892775222],
					[64.783394230694825, 7.0491279076146904],
					[64.786285930790669, 7.0536862079165772],
					[64.786285941744609, 7.0536862218582241],
					[64.786285966315191, 7.0536862474451372],
					[64.78628597993179, 7.053686259090389],
					[64.791130417794292, 7.0573307119993007],
					[64.793030401901177, 7.067233327926675],
					[64.793791500922552, 7.0752333199058883],
					[64.793791533154319, 7.0752333828634777],
					[64.793791630361596, 7.0752334727631911],
					[64.793791697130629, 7.0752334995774389],
					[64.797652797452599, 7.0753002007889219],
					[64.797652810903941, 7.0753001998295844],
					[64.797652839572862, 7.0753001956796115],
					[64.797652853893723, 7.0753001925529304],
					[64.804497254312153, 7.0733668920781616],
					[64.804497260562144, 7.0733668895259649],
					[64.804497273089424, 7.0733668865254682],
					[64.804497279339387, 7.0733668839732742],
					[64.813155657681278, 7.0696334923634421],
					[64.820175036166944, 7.0683612975648531],
					[64.820175054047169, 7.0683612920695333],
					[64.820175089780335, 7.0683612789737733],
					[64.820175106709115, 7.0683612693324553],
					[64.826452906600053, 7.0644001683592279],
					[64.82645294287299, 7.064400127817545],
					[64.82645298922121, 7.0644000338625252],
					[64.82645299926908, 7.0643999783435296],
					[64.825311299375926, 7.0537638793627035],
					[64.825311296547824, 7.0537638690293605],
					[64.825311293636958, 7.053763852379471],
					[64.825311291705603, 7.053763841981378],
					[64.820983493109111, 7.0391527463597292],
					[64.818039094440991, 7.0273471525936628],
					[64.818039091638994, 7.0273471443708315],
					[64.818039087884387, 7.0273471320037606],
					[64.818039086007076, 7.0273471258202136],
					[64.81240848555656, 7.0133638251291863],
					[64.812408484659841, 7.0133638251952455],
					[64.80805849758238, 7.002594356977399],
					[64.80766960226083, 6.9942389397952569],
					[64.811516862108419, 6.9857834266456935],
					[64.815286223389975, 6.9828362569970359],
					[64.815286247258015, 6.9828362299301014],
					[64.815286284147945, 6.9828361702872499],
					[64.815286296273101, 6.9828361377784143],
					[64.819114094596685, 6.9629194453708267],
					[64.82284459309264, 6.9491167518588419],
					[64.822844594740701, 6.9491167411964483],
					[64.822844598904481, 6.949116717698204],
					[64.822844599626791, 6.949116704998807],
					[64.823136299979168, 6.9395972066959439],
					[64.823136291295981, 6.9395971631035636],
					[64.823136256083558, 6.9395970836052667],
					[64.823136229554379, 6.9395970476993467],
					[64.820008430350583, 6.9369220488415824],
					[64.820008404083836, 6.936922031887006],
					[64.820008349006443, 6.9369220087085104],
					[64.820008320166551, 6.936922000379627],
					[64.814330620025288, 6.9363526006410074],
					[64.814330583261437, 6.9363526034545471],
					[64.814330511731839, 6.9363526236759458],
					[64.814330478759359, 6.9363526409465681],
					[64.807083203232224, 6.9418859215452624],
					[64.796769363301507, 6.9455998005414123],
					[64.787116696407381, 6.9458137004429554],
					[64.787116693717309, 6.945813700647629],
					[64.787116691027265, 6.9458137008523],
					[64.787116689233912, 6.9458137009887482],
					[64.778847189431332, 6.9462719999472018],
					[64.778847157208702, 6.9462720066053256],
					[64.778847098289063, 6.9462720300212908],
					[64.778847070695335, 6.9462720468472989],
					[64.771583170924814, 6.9524165470750106],
					[64.771583169160394, 6.9524165493121286],
					[64.771583164734963, 6.9524165538543325],
					[64.771583162941582, 6.9524165539902461],
					[64.764397062603038, 6.9592054550489859],
					[64.757391562803832, 6.9658081540150221],
					[64.757391558406752, 6.9658081606527142],
					[64.757391547790363, 6.9658081719629461],
					[64.757391543364463, 6.9658081765005448],
					[64.751380443092188, 6.9734276751050315],
					[64.751380425617228, 6.9734277100435982],
					[64.751380405014615, 6.9734277788455472],
					[64.751380401047641, 6.9734278169754473],
				],
			],
		},
		{
			fldNpdidField: "1036101",
			wlbNpdidWellbore: "2377",
			fldName: "KVITEBJØRN",
			fldCurrentActivitySatus: "Producing",
			wlbName: "34/11-1",
			fldDiscoveryYear: "1994",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=1036101",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=1036101",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "193",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=2377",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "22356",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[61.043135909734005, 2.4821333629792752],
					[61.045241508697693, 2.4885722575229607],
					[61.047130407050105, 2.4953083533551026],
					[61.047130408209007, 2.4953083586904983],
					[61.047130411240879, 2.4953083654984431],
					[61.047130412221811, 2.4953083671545464],
					[61.048369204888047, 2.4987333492654265],
					[61.049672001782938, 2.5078333273280946],
					[61.051010902267876, 2.5176694264570845],
					[61.051010965574527, 2.5176695063804111],
					[61.051011130746886, 2.5176695691285129],
					[61.051011231631932, 2.517669550296274],
					[61.055222130812098, 2.513958628417214],
					[61.056780406421325, 2.5173361574451478],
					[61.057502602439001, 2.522119430772821],
					[61.057502605912752, 2.5221194467872725],
					[61.057502616339619, 2.522119476244411],
					[61.057502621597365, 2.5221194918931569],
					[61.059999817636054, 2.5269917831365061],
					[61.061358104069335, 2.5303917505816886],
					[61.062838701015529, 2.5429222221914958],
					[61.062838702261011, 2.5429222293720768],
					[61.062838704663584, 2.5429222418925108],
					[61.06283870590903, 2.5429222490730972],
					[61.064772006150768, 2.5506472484658724],
					[61.064772009356425, 2.5506472589647196],
					[61.064772015767822, 2.5506472799623938],
					[61.064772020669352, 2.5506472882561466],
					[61.068072020609897, 2.557322288670286],
					[61.06807202355057, 2.5573222936476836],
					[61.068072030412225, 2.5573223052615699],
					[61.068072034244985, 2.5573223100570091],
					[61.072680432331772, 2.564194508908705],
					[61.075516529871308, 2.5687501060355973],
					[61.075516612259761, 2.5687501524782883],
					[61.075516779144685, 2.5687501575387532],
					[61.075516863553119, 2.5687501143150135],
					[61.08046406323863, 2.5616862155538929],
					[61.083436232461644, 2.5575445595348487],
					[61.086547279426995, 2.5560945840224436],
					[61.090941752751277, 2.55435299333445],
					[61.094569310900638, 2.5537752172009389],
					[61.100830438658846, 2.5622390189888056],
					[61.100830442403392, 2.5622390219465623],
					[61.100830447216268, 2.5622390284082743],
					[61.100830450068727, 2.5622390315481125],
					[61.105560950415629, 2.5676362317820431],
					[61.105560957100479, 2.5676362397249237],
					[61.105560972166302, 2.5676362534035073],
					[61.105560979655195, 2.5676362593211306],
					[61.109616557295453, 2.5706834436429906],
					[61.114085936868392, 2.5765890155906015],
					[61.118274826369031, 2.5828583984316409],
					[61.120924816912499, 2.5884583806613635],
					[61.124030412304066, 2.5959417698184968],
					[61.126147008934453, 2.602658359258391],
					[61.12614711582291, 2.6026584288171741],
					[61.12614731118591, 2.6026584077812425],
					[61.126147398768282, 2.6026583173676445],
					[61.127536298962617, 2.5862222172749649],
					[61.127536298874794, 2.5862222154303236],
					[61.127536299591242, 2.5862222115594622],
					[61.127536300395555, 2.5862222095332315],
					[61.128516900410041, 2.564172209744513],
					[61.128516900321912, 2.564172207899877],
					[61.128825198930983, 2.5564111361839643],
					[61.131819590007147, 2.5475167637907865],
					[61.131819590722507, 2.5475167599185582],
					[61.131819593760582, 2.5475167481192051],
					[61.131819595367958, 2.5475167440642892],
					[61.134094595096393, 2.5373472432622104],
					[61.134094595811455, 2.5373472393894856],
					[61.134094598045095, 2.5373472296161985],
					[61.134094598671631, 2.5373472238986521],
					[61.136936298103912, 2.5140889316375432],
					[61.140389096430127, 2.4964194382872518],
					[61.140389094203776, 2.4964193921605946],
					[61.140389070217168, 2.4964193058050297],
					[61.140389046583941, 2.4964192640994898],
					[61.132255750738281, 2.4876109692981148],
					[61.125447355119299, 2.4792470734891574],
					[61.125447339955734, 2.4792470580012016],
					[61.125447306239465, 2.4792470314518473],
					[61.125447286794852, 2.4792470205750652],
					[61.123025086868481, 2.4780692207386075],
					[61.123025081249068, 2.4780692163142564],
					[61.12302506662116, 2.478069211891885],
					[61.123025059307167, 2.4780692096807173],
					[61.117986175038482, 2.476499813611178],
					[61.110583389626314, 2.47274702291495],
					[61.11058337205445, 2.4727470135206935],
					[61.110583333789826, 2.4727470046869917],
					[61.110583314702211, 2.4727470011917578],
					[61.104891715795397, 2.4723220014571945],
					[61.102525135043244, 2.4721248112517951],
					[61.099847382974119, 2.4660499199082753],
					[61.099847374052629, 2.4660499031451919],
					[61.099847351750142, 2.4660498705428311],
					[61.099847340242405, 2.4660498561766171],
					[61.095736240016947, 2.4620387567462179],
					[61.095736236270355, 2.462038753800925],
					[61.095736226903973, 2.4620387464376479],
					[61.095736223067938, 2.4620387416500109],
					[61.090325122576736, 2.4578387414606611],
					[61.090325105895936, 2.4578387318951602],
					[61.090325068966727, 2.4578387135038207],
					[61.090325050591673, 2.4578387061501661],
					[61.085297250860243, 2.4565248059098228],
					[61.085297241851762, 2.4565248059173048],
					[61.085297223655772, 2.456524802248822],
					[61.085297214557784, 2.4565248004145892],
					[61.078777917463825, 2.4560498087532983],
					[61.073872379329082, 2.4480221084300342],
					[61.06981958499496, 2.4383138221834373],
					[61.069819582050044, 2.438313817217141],
					[61.06981957705203, 2.4383138070992305],
					[61.069819575088729, 2.4383138037883549],
					[61.065911274816735, 2.4312999022452608],
					[61.065911201215073, 2.4312998524809109],
					[61.065911044229168, 2.4312998293541881],
					[61.06591095915109, 2.4312998582032543],
					[61.062655562750251, 2.4345303544260246],
					[61.059375091731077, 2.4328192223462484],
					[61.05937502019664, 2.4328192149104328],
					[61.059374888562118, 2.4328192497082854],
					[61.059374831227416, 2.4328192932259145],
					[61.057738812022329, 2.435427563463354],
					[61.05498065907495, 2.4345831092162848],
					[61.054980632672212, 2.4345831035508891],
					[61.054980581297563, 2.4345831030740164],
					[61.054980555254126, 2.434583104768111],
					[61.051363855063975, 2.4354248051200318],
					[61.051363833749228, 2.435424811405773],
					[61.051363795938251, 2.4354248304096724],
					[61.051363776676631, 2.4354248418438953],
					[61.047730476940941, 2.438249841533179],
					[61.04773046062396, 2.4382498579272074],
					[61.047730434322759, 2.4382498912587205],
					[61.047730421662926, 2.4382499087516369],
					[61.047302622265704, 2.4390916082058198],
					[61.047302610952137, 2.4390916532898061],
					[61.047302609909259, 2.4390917426938157],
					[61.047302619198298, 2.4390917853595013],
					[61.049952618863976, 2.4446611861058729],
					[61.04995262171893, 2.4446611892301755],
					[61.049952625734825, 2.4446611976883177],
					[61.049952627787619, 2.4446612028372119],
					[61.053060913941415, 2.4498778779664439],
					[61.054535903704028, 2.4554750411752253],
					[61.055399798998721, 2.4607194127282028],
					[61.054999800752697, 2.4689777752473372],
					[61.054249807917628, 2.4726166407794281],
					[61.052672027066251, 2.4764415941623628],
					[61.051338777972404, 2.4779887350338972],
					[61.048916620337273, 2.4790415169383975],
					[61.048916612399083, 2.4790415204341611],
					[61.048916596611917, 2.4790415292653813],
					[61.048916588673706, 2.4790415327611499],
					[61.044919318184334, 2.4816970133423557],
					[61.043136052180422, 2.4821331051108046],
					[61.043135988762884, 2.482133153481787],
					[61.04313591838703, 2.4821332813124806],
					[61.043135909734005, 2.4821333629792752],
				],
			],
		},
		{
			fldNpdidField: "43548",
			wlbNpdidWellbore: "276",
			fldName: "VALHALL",
			fldCurrentActivitySatus: "Producing",
			wlbName: "2/8-6",
			fldDiscoveryYear: "1975",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43548",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43548",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "VALHALL UNIT",
			cmpLongName: "Aker BP ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=276",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "28544099",
			fldNpdidOwner: "41657",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Tananger",
			fldAreaGeometryWKT: [
				[
					[56.230727601341073, 3.4305638905539642],
					[56.230727600712427, 3.4305639164992767],
					[56.230727602114008, 3.430563927606352],
					[56.232260901801432, 3.441355628197674],
					[56.232260902912657, 3.4413556328840231],
					[56.232260905135121, 3.4413556422567093],
					[56.23226090624636, 3.4413556469430451],
					[56.233847005684915, 3.447869446619658],
					[56.233847014888951, 3.4478694711398914],
					[56.233847038445475, 3.4478695145854266],
					[56.233847051976014, 3.4478695352459781],
					[56.239519251858255, 3.4540862353436133],
					[56.239519279767485, 3.4540862555411178],
					[56.239519339696095, 3.4540862872593019],
					[56.239519372609685, 3.4540862986503447],
					[56.244880572888576, 3.4548029985235744],
					[56.244880583691348, 3.4548029985739506],
					[56.244880605369048, 3.45480300028068],
					[56.244880615205403, 3.4548029988547211],
					[56.25013061519148, 3.4544112995803009],
					[56.250130626816265, 3.4544112978946218],
					[56.250130649993636, 3.4544112929170603],
					[56.25013066154623, 3.4544112896251895],
					[56.256966762072345, 3.4521862903305669],
					[56.256966764682737, 3.452186288334838],
					[56.256966771764198, 3.4521862856903294],
					[56.256966776235238, 3.4521862850415661],
					[56.262966775625294, 3.4497223853995127],
					[56.262966780918248, 3.4497223830137602],
					[56.262966792398402, 3.4497223781124124],
					[56.262966796724896, 3.4497223742497689],
					[56.271294497307927, 3.4451084754692278],
					[56.271294498129798, 3.4451084737321751],
					[56.271294500812388, 3.4451084733422102],
					[56.271294501634259, 3.4451084716051703],
					[56.277291801658841, 3.4415445717922961],
					[56.27729180605742, 3.4415445695344999],
					[56.277291814782181, 3.4415445634116151],
					[56.277291819180746, 3.4415445611538029],
					[56.281966818940127, 3.4380945602389463],
					[56.28196682162266, 3.4380945598483348],
					[56.2819668267705, 3.4380945542446537],
					[56.281966830274804, 3.4380945521163579],
					[56.288355729707376, 3.4326168522382519],
					[56.288355730529048, 3.43261685050014],
					[56.288355732317399, 3.4326168502394134],
					[56.288355732317399, 3.4326168502394134],
					[56.29227513238181, 3.429169550296479],
					[56.292275141033301, 3.4291695425602939],
					[56.292275156402837, 3.4291695241330453],
					[56.292275164087584, 3.429169514919423],
					[56.297050163879035, 3.4223501144239528],
					[56.29705016641622, 3.4223501108158616],
					[56.297050170596435, 3.4223501037303521],
					[56.297050173133627, 3.42235010012226],
					[56.301380772793898, 3.4148084003351191],
					[56.301380775330841, 3.4148083967261122],
					[56.301380778616426, 3.4148083897698016],
					[56.301380781153384, 3.4148083861608081],
					[56.305741880215855, 3.4056306877322329],
					[56.310225176906052, 3.3965944930940228],
					[56.314419575192979, 3.3890778965561181],
					[56.314419580192151, 3.3890778877235093],
					[56.314419588256094, 3.3890778671038961],
					[56.314419592214982, 3.3890778551853797],
					[56.317541891975658, 3.3783750557145775],
					[56.317541894438222, 3.3783750504928114],
					[56.317541895640524, 3.3783750373587695],
					[56.317541898029859, 3.3783750305281939],
					[56.319427998014973, 3.3664167303352324],
					[56.319427999436158, 3.3664167220268131],
					[56.319428000343621, 3.3664167024563878],
					[56.319427999976668, 3.3664166944120955],
					[56.319100199723735, 3.3556999944121881],
					[56.319100199282566, 3.3556999847592976],
					[56.319100196612112, 3.3556999657181339],
					[56.319100195203369, 3.3556999545887418],
					[56.316305795096255, 3.343511054505159],
					[56.316305791004339, 3.3435110437748334],
					[56.316305782746781, 3.3435110207055589],
					[56.316305777687127, 3.3435110084991577],
					[56.312972378014834, 3.3370638083956252],
					[56.312972370272512, 3.3370637965893222],
					[56.312972353893841, 3.3370637731094224],
					[56.312972345257485, 3.3370637614358238],
					[56.308894544871542, 3.3327748615128834],
					[56.308894525285154, 3.3327748466100715],
					[56.308894481716081, 3.332774819076751],
					[56.308894457881053, 3.3327748096628964],
					[56.304775058386497, 3.3315220089562358],
					[56.304775044754749, 3.3315220061238868],
					[56.304775019279269, 3.3315220001935644],
					[56.30477500490116, 3.331522000710291],
					[56.301208305287155, 3.3314303992031267],
					[56.301208282042673, 3.3314304026556605],
					[56.301208236595429, 3.3314304126439156],
					[56.301208215286643, 3.3314304190468689],
					[56.296161014798493, 3.3338054193335558],
					[56.296161012190211, 3.3338054213394721],
					[56.296161005111834, 3.3338054240089305],
					[56.296161001609541, 3.3338054261475532],
					[56.289008202554307, 3.337835924786114],
					[56.282441605698153, 3.3414248234931274],
					[56.276519306946859, 3.3445331226464203],
					[56.276519306052826, 3.3445331227787807],
					[56.276519305232419, 3.344533124518132],
					[56.276519304338379, 3.3445331246504919],
					[56.268349903898532, 3.3489804241811614],
					[56.268349896893291, 3.3489804284522133],
					[56.268349882062303, 3.3489804387332147],
					[56.268349874236577, 3.3489804447431575],
					[56.263452673966512, 3.3529304432008327],
					[56.263452669716791, 3.3529304486807501],
					[56.263452661070289, 3.3529304564276186],
					[56.263452656747035, 3.3529304603010668],
					[56.257855456624426, 3.3586748603467198],
					[56.25785545230098, 3.3586748642189206],
					[56.257855443800949, 3.3586748751758102],
					[56.257855440444999, 3.3586748805223117],
					[56.253074840208491, 3.3650442803399678],
					[56.253074836852235, 3.3650442856852316],
					[56.253074830066375, 3.3650442947697079],
					[56.253074826710126, 3.3650443001149868],
					[56.249760926921176, 3.3707831997504694],
					[56.249760923564651, 3.3707832050947641],
					[56.249760918566508, 3.3707832139141947],
					[56.24976091699817, 3.3707832189952707],
					[56.247227617452829, 3.3764943194426698],
					[56.247227613421536, 3.3764943297351624],
					[56.247227606326256, 3.3764943517945403],
					[56.247227603262267, 3.3764943635614157],
					[56.245283106326895, 3.38676664664488],
					[56.245210911833915, 3.3869666322619127],
					[56.245210924118453, 3.3869667242174768],
					[56.245211018389938, 3.3869668574863661],
					[56.245211100449943, 3.3869669004054748],
					[56.245219159734113, 3.3869668993454929],
					[56.243883108349451, 3.3943027374602472],
					[56.240783124534829, 3.400733201502927],
					[56.236277632338009, 3.4076498905468555],
					[56.23627762815844, 3.4076498976222411],
					[56.236277619872055, 3.4076499133784912],
					[56.23627761576526, 3.407649922059361],
					[56.232219216244474, 3.4171832212397177],
					[56.232219210566605, 3.4171832349955875],
					[56.232219203754347, 3.4171832634604171],
					[56.232219200831643, 3.4171832784302669],
					[56.230727600761199, 3.4305638777114327],
					[56.230727601341073, 3.4305638905539642],
				],
			],
		},
		{
			fldNpdidField: "21675433",
			wlbNpdidWellbore: "5612",
			fldName: "EDVARD GRIEG",
			fldCurrentActivitySatus: "Producing",
			wlbName: "16/1-8",
			fldDiscoveryYear: "2007",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=21675433",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=21675433",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "338",
			cmpLongName: "ABP Norway AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=5612",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "50000045",
			fldNpdidOwner: "2986578",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Tananger",
			fldAreaGeometryWKT: [
				[
					[58.84512479987044, 2.3253000036149221],
					[58.845124800138407, 2.3253000087778197],
					[58.845391500008418, 2.3314028082069944],
					[58.845391511159278, 2.3314028495099732],
					[58.845391550229941, 2.3314029254022453],
					[58.845391577168499, 2.3314029584427258],
					[58.847733177130159, 2.3332307575738853],
					[58.847733226813503, 2.3332307775360572],
					[58.847733331085614, 2.3332307904384599],
					[58.847733383711997, 2.333230780280767],
					[58.849977883130997, 2.3321890819880959],
					[58.851241708242036, 2.3316169157375333],
					[58.852441576216471, 2.3320612671436285],
					[58.853108140985647, 2.3329334216089475],
					[58.853108198697178, 2.3329334574094482],
					[58.853108326339402, 2.3329334866632299],
					[58.853108394307689, 2.3329334770181749],
					[58.854850093980247, 2.3320084762915383],
					[58.854850120025347, 2.3320084573524755],
					[58.854850164444329, 2.3320084105234695],
					[58.854850181926231, 2.3320083828057712],
					[58.856122381894302, 2.3291889814870914],
					[58.856122382786253, 2.3291889813147675],
					[58.856122384391611, 2.329188977527064],
					[58.856122385194276, 2.3291889756332194],
					[58.856516884910349, 2.3282278761590463],
					[58.85651688482104, 2.3282278744374971],
					[58.856908485073049, 2.3272639753026669],
					[58.857302985372016, 2.3263028762647946],
					[58.857302986887959, 2.3263028707552755],
					[58.857302990009153, 2.3263028614577936],
					[58.857302991614439, 2.3263028576698379],
					[58.857583492111665, 2.3253667578395758],
					[58.857866891393982, 2.3244278572653019],
					[58.857866892285948, 2.3244278570928425],
					[58.857866892285948, 2.3244278570928425],
					[58.858147391863426, 2.3234917578683154],
					[58.858427986122862, 2.3225556779843965],
					[58.858836275725217, 2.32180839579497],
					[58.858836275725217, 2.32180839579497],
					[58.859241868344697, 2.3210612098500554],
					[58.859661256958155, 2.3205279242802628],
					[58.86008075708515, 2.3199973232869802],
					[58.860500156756949, 2.3194640240155433],
					[58.860919540062163, 2.3189334445675294],
					[58.861352917961774, 2.3186140604447028],
					[58.861786217682941, 2.3182973611112163],
					[58.861786228028535, 2.3182973521524231],
					[58.861786247827744, 2.3182973344074886],
					[58.86178625719193, 2.3182973238995981],
					[58.862205757152537, 2.3177668235343409],
					[58.862625157025754, 2.3172334246575255],
					[58.862625167013434, 2.317233408810969],
					[58.862625185115483, 2.3172333757414294],
					[58.862625192248437, 2.3172333569693073],
					[58.862905791609144, 2.3162972586305304],
					[58.863189091340914, 2.3153583584500037],
					[58.863189092856359, 2.3153583529390676],
					[58.863189094995306, 2.3153583420899331],
					[58.863189097402696, 2.3153583364062675],
					[58.863358496963826, 2.3144472370872662],
					[58.863527996588992, 2.3135361363877887],
					[58.86352799810436, 2.3135361308767202],
					[58.863527999261713, 2.3135361184783263],
					[58.863527999885136, 2.3135361131400347],
					[58.863586300093246, 2.3126500124228651],
					[58.8636445997669, 2.3117639132630519],
					[58.863705799750612, 2.310877813521949],
					[58.863705799750612, 2.310877813521949],
					[58.863764098712281, 2.3099917242892651],
					[58.863933497343801, 2.3090806377525155],
					[58.863933497254251, 2.3090806360306759],
					[58.864102997385153, 2.3081667355156466],
					[58.864102998008356, 2.3081667301771875],
					[58.864102999254762, 2.3081667195002815],
					[58.864102999788386, 2.3081667124399958],
					[58.864161299794333, 2.3072806127860059],
					[58.864222400358827, 2.3063944132468159],
					[58.864222400000443, 2.3063944063594337],
					[58.864222400265177, 2.3063943941335654],
					[58.864222399906794, 2.3063943872461845],
					[58.864169599728889, 2.3055332873157379],
					[58.864116899983742, 2.3046721872177525],
					[58.864116898733322, 2.3046721805034296],
					[58.864116897124461, 2.3046721669017827],
					[58.864116895874055, 2.3046721601874571],
					[58.863950195798481, 2.3038388617172232],
					[58.863786295679795, 2.3030027628984882],
					[58.863786286039009, 2.3030027334633396],
					[58.863786258107197, 2.3030026814890139],
					[58.863786239726537, 2.3030026572280096],
					[58.863425139828273, 2.3026498572680962],
					[58.863064039780795, 2.3022942573368397],
					[58.863064019172526, 2.3022942422047534],
					[58.863063972783806, 2.3022942164225069],
					[58.86306394789527, 2.3022942055992783],
					[58.862605647956961, 2.3021804060518414],
					[58.862605647065017, 2.30218040622491],
					[58.862147314674061, 2.3020692225080621],
					[58.859841858029007, 2.2990998768402751],
					[58.859841812172917, 2.2990998440049268],
					[58.859841709409068, 2.2990998083036045],
					[58.859841651609379, 2.2990998056107559],
					[58.858136073437109, 2.2995192009242387],
					[58.857216710697642, 2.2995415001064732],
					[58.856000026412595, 2.2993804016218502],
					[58.856000009286937, 2.2993804014677584],
					[58.85599997601723, 2.2993804027078775],
					[58.855999958981279, 2.2993804042751913],
					[58.854561076271494, 2.2996831005540757],
					[58.852727794306595, 2.2997358991481347],
					[58.852727776468377, 2.2997359026093247],
					[58.852727740791941, 2.2997359095316963],
					[58.852727723043394, 2.2997359147141316],
					[58.852072122672176, 2.3000081147847307],
					[58.852072107778696, 2.3000081228901896],
					[58.852072079062978, 2.3000081423705305],
					[58.852072065151084, 2.3000081520241564],
					[58.851752664768242, 2.3002998527788354],
					[58.851430465541959, 2.3005915518402147],
					[58.851430446103898, 2.3005915764741367],
					[58.851430413919516, 2.3005916331367318],
					[58.851430402867358, 2.3005916630981509],
					[58.851260902867466, 2.3015027633188794],
					[58.851091503019227, 2.3024138638455987],
					[58.850919203122729, 2.303324962311728],
					[58.850919203212342, 2.3033249640328974],
					[58.850749804631839, 2.3042388523416855],
					[58.850469207939931, 2.3051749427136476],
					[58.850185911141985, 2.3061110325357856],
					[58.849794214748208, 2.307074924772917],
					[58.849399819075266, 2.3080360138142937],
					[58.848994223766404, 2.3087832057198199],
					[58.848588746176745, 2.3095304641351047],
					[58.848155482132213, 2.3098470392002741],
					[58.848155464117973, 2.309847056596869],
					[58.848155431836297, 2.3098470941411375],
					[58.848155418550306, 2.3098471158370764],
					[58.846847019153351, 2.3126555167616205],
					[58.846847015051672, 2.3126555245090756],
					[58.84684700791923, 2.3126555432733],
					[58.846847004888481, 2.3126555542900711],
					[58.845585904734897, 2.3180027535687553],
					[58.845585904290076, 2.318002762346083],
					[58.845585900635186, 2.3180027786974362],
					[58.845585900190372, 2.3180027874747795],
					[58.845124799869119, 2.3252999862329378],
					[58.845124800226422, 2.325299993116797],
					[58.84512479987044, 2.3253000036149221],
				],
				[
					[
						[58.817233099759974, 2.1938167001794131],
						[58.817272000296597, 2.1996889002344346],
						[58.817271999586829, 2.1996889038480849],
						[58.817271999859734, 2.199688909005511],
						[58.817272000041683, 2.199688912443778],
						[58.817702599679833, 2.2069861116564815],
						[58.817702600753336, 2.2069861149196415],
						[58.817702601116849, 2.2069861217963886],
						[58.817702602281209, 2.2069861267787347],
						[58.818344201631632, 2.2119389265874596],
						[58.818344206106772, 2.2119389430794674],
						[58.818344216039605, 2.2119389776076819],
						[58.81834422319001, 2.2119389935744693],
						[58.819530421068293, 2.2141750897378842],
						[58.820638706002818, 2.216566757621361],
						[58.82114149842559, 2.2200083006263371],
						[58.820574801934434, 2.2239527672096999],
						[58.819683102805172, 2.228997165172129],
						[58.819683101655677, 2.228997177556975],
						[58.81968310033912, 2.2289972038713399],
						[58.819683101063816, 2.2289972176262398],
						[58.819963699914503, 2.2323694080171017],
						[58.819941500029692, 2.235819398490714],
						[58.819941502007431, 2.2358194189490188],
						[58.819941510421913, 2.2358194589932534],
						[58.819941515966867, 2.2358194787536436],
						[58.820569216155853, 2.2372806797506417],
						[58.820569216155853, 2.2372806797506417],
						[58.820569217138122, 2.2372806812956294],
						[58.820824804992021, 2.237855653186533],
						[58.820908100860322, 2.2385583232845998],
						[58.820991498990558, 2.2392639033861732],
						[58.820930400722581, 2.2400582832916567],
						[58.820866501246186, 2.2408555803529935],
						[58.820769201434317, 2.2416943767759347],
						[58.820672000852127, 2.2425332769149797],
						[58.820672001032932, 2.2425332803538907],
						[58.82067199961093, 2.2425332875803541],
						[58.820671999791742, 2.2425332910192668],
						[58.820633100058586, 2.2433638892016732],
						[58.820591499614572, 2.2441943906862001],
						[58.82059149988573, 2.2441943958445876],
						[58.820591499626602, 2.244194408055074],
						[58.820591499897766, 2.2441944132134628],
						[58.820644200442061, 2.2449722137361712],
						[58.820694200363036, 2.245750012085042],
						[58.820694200543755, 2.2457500155239947],
						[58.820694200905223, 2.2457500224018832],
						[58.820694201977766, 2.2457500256665925],
						[58.820788701803096, 2.2464972243804091],
						[58.820788701893449, 2.2464972260998861],
						[58.820885900726871, 2.2472444188443772],
						[58.820930399615285, 2.2480167113361378],
						[58.820974800003015, 2.2487888994006697],
						[58.820924799573895, 2.2495943872916708],
						[58.820874801188083, 2.2504027710774483],
						[58.820355405270945, 2.2526138547805039],
						[58.820355403137093, 2.2526138656195203],
						[58.820355400653071, 2.2526138869494567],
						[58.820355400302866, 2.2526138974403795],
						[58.820327599639157, 2.2556860952816353],
						[58.820246999617353, 2.2584971949038724],
						[58.82024700015851, 2.2584972052208383],
						[58.820247002132689, 2.258497225680868],
						[58.820247002673845, 2.2584972359978357],
						[58.82055260009016, 2.26015831518836],
						[58.820533099967236, 2.2609332950578565],
						[58.820513699748297, 2.2617110941388807],
						[58.820485899603355, 2.2624916925196583],
						[58.820455400173351, 2.2632721913914815],
						[58.82045540035363, 2.2632721948305159],
						[58.820455399822293, 2.2632722018823817],
						[58.820455399912433, 2.2632722036018991],
						[58.820469200382682, 2.2640778021203385],
						[58.820483099679279, 2.2648833032356879],
						[58.82048309976939, 2.2648833049552151],
						[58.820483099859516, 2.2648833066747533],
						[58.820483099949612, 2.2648833083942828],
						[58.820519200052779, 2.2657083093660737],
						[58.820558099908013, 2.2665361048138228],
						[58.820560900396487, 2.2673472008394864],
						[58.820563700322801, 2.2681582982078292],
						[58.820552599858836, 2.2689388969892965],
						[58.820538700042597, 2.269719395150311],
						[58.820538700222691, 2.2697193985894168],
						[58.820538700312731, 2.2697194003089658],
						[58.820538700312731, 2.2697194003089658],
						[58.820541499686662, 2.2705306015585274],
						[58.820547000421861, 2.2713417004269552],
						[58.820541500383825, 2.272219398865515],
						[58.820535899585018, 2.2730971975269729],
						[58.820510899933389, 2.2740166943697151],
						[58.820488700179467, 2.2749388945062967],
						[58.820460900343321, 2.2758721941764248],
						[58.820433099816697, 2.2768055923832837],
						[58.820391499752517, 2.2777082902322285],
						[58.820349799612856, 2.2786110896837397],
						[58.820299800288275, 2.2794499882618631],
						[58.82024979964568, 2.2802860873376689],
						[58.820249799825483, 2.280286090776809],
						[58.820249799915374, 2.2802860924963775],
						[58.8202498000053, 2.2802860942159602],
						[58.820227599622797, 2.2810721945194019],
						[58.82020540017529, 2.2818610931401473],
						[58.820180399986867, 2.2826305930897264],
						[58.82015259957646, 2.2833999891438772],
						[58.8200942006318, 2.2841527835573783],
						[58.820033101129155, 2.284905583458587],
						[58.820033101218982, 2.2849055851781626],
						[58.820033100327095, 2.2849055853513809],
						[58.820033100416936, 2.2849055870709702],
						[58.819983099922752, 2.2856527856330131],
						[58.819930399653011, 2.2863999863321975],
						[58.819930399653011, 2.2863999863321975],
						[58.81993039983265, 2.2863999897713616],
						[58.81993039992247, 2.286399991490939],
						[58.819897000266138, 2.2871749915966628],
						[58.819860900066551, 2.287949985809548],
						[58.819780400796823, 2.2887582803294957],
						[58.819699801596151, 2.2895666714663174],
						[58.819547003765685, 2.2903721622635254],
						[58.81954700287379, 2.2903721624365989],
						[58.819397004033704, 2.2911777589243187],
						[58.819210904927537, 2.2919666537240388],
						[58.819210905017265, 2.2919666554436002],
						[58.81902760522842, 2.2927555538815287],
						[58.818835905664066, 2.2935388519218018],
						[58.818644206119345, 2.2943193527949326],
						[58.818452605555926, 2.2951027518620983],
						[58.818260906106488, 2.2958860513048105],
						[58.81806650577844, 2.2966693525677049],
						[58.817874806123001, 2.2974527513494851],
						[58.817874806212686, 2.2974527530690145],
						[58.817874805320763, 2.2974527532418945],
						[58.817874805410447, 2.2974527549614048],
						[58.81769980520874, 2.2982027538090146],
						[58.817522005386486, 2.2989555535896953],
						[58.817522004673876, 2.2989555572015514],
						[58.817522003338325, 2.2989555661447434],
						[58.817522001733799, 2.2989555699294231],
						[58.817430402043186, 2.2995582690988488],
						[58.817335902138701, 2.3001610698171211],
						[58.81733590134089, 2.3001610890772062],
						[58.817335903402515, 2.300161128625652],
						[58.817335906172346, 2.3001611471945118],
						[58.81742200600069, 2.3005083480918205],
						[58.817508106375598, 2.3008556477508511],
						[58.817508120744627, 2.3008556814414591],
						[58.8175081607191, 2.3008557396983602],
						[58.817508186324552, 2.3008557642646505],
						[58.817780486160927, 2.3010445634045054],
						[58.817780486250555, 2.3010445651240263],
						[58.817780487142471, 2.3010445649512361],
						[58.818052687189692, 2.3012307652385777],
						[58.818052691828541, 2.3012307678137183],
						[58.818052701106218, 2.3012307729640207],
						[58.818052704853145, 2.3012307757119483],
						[58.818408205217182, 2.301422376107582],
						[58.818408206109098, 2.3014223759347976],
						[58.818766605956796, 2.3016140766069957],
						[58.81876661059561, 2.301614079182241],
						[58.818766622638641, 2.3016140855339731],
						[58.818766629061336, 2.3016140877636655],
						[58.819133229226352, 2.3017529860902872],
						[58.81950272775336, 2.3018945876543788],
						[58.819502748715578, 2.3018945922785483],
						[58.81950278956888, 2.301894598260446],
						[58.819502811243801, 2.3018945992726034],
						[58.819888910909278, 2.3018723994974382],
						[58.820275010643634, 2.3018502006449029],
						[58.820275019562835, 2.3018501989170592],
						[58.820275035706992, 2.3018501975265924],
						[58.820275043644635, 2.3018501942518932],
						[58.820669444271559, 2.3017612947884891],
						[58.821063919985271, 2.3016724010020404],
						[58.821433295030651, 2.3016808007099718],
						[58.821433295922567, 2.3016808005371763],
						[58.821805573916507, 2.3016890998006407],
						[58.82216385328843, 2.301775193958838],
						[58.822522151782792, 2.3018640948610525],
						[58.822522152674708, 2.3018640946882529],
						[58.822522156331985, 2.3018640957168159],
						[58.822522157223915, 2.3018640955440146],
						[58.822911057212693, 2.3019501944451393],
						[58.823302753650225, 2.3020362951857116],
						[58.823733250899679, 2.3021445948102719],
						[58.82416384311626, 2.3022529912704037],
						[58.824613834840939, 2.3024084889240561],
						[58.825066630705379, 2.3025640879796878],
						[58.825519328004368, 2.3027390873159579],
						[58.825519328896284, 2.3027390871431614],
						[58.825974928697335, 2.3029112872039499],
						[58.825974929589265, 2.3029112870311534],
						[58.825974933246535, 2.3029112880599065],
						[58.825974934228064, 2.3029112896070427],
						[58.826424933676684, 2.3030695891642168],
						[58.8264249345686, 2.3030695889914212],
						[58.826874935388403, 2.3032251886784971],
						[58.826874942792571, 2.3032251924560994],
						[58.826874960989414, 2.3032251958801351],
						[58.826874970087829, 2.3032251975921709],
						[58.827308269683108, 2.3032918972097365],
						[58.827308270664638, 2.3032918987569571],
						[58.827741671178067, 2.3033557985626643],
						[58.827741690087599, 2.3033557983740041],
						[58.82774172692519, 2.3033557964494169],
						[58.827741744853213, 2.3033557947135135],
						[58.828147245378283, 2.3032612950565117],
						[58.828147246270213, 2.3032612948837121],
						[58.828147247162129, 2.3032612947109117],
						[58.828147247162129, 2.3032612947109117],
						[58.828550046598316, 2.3031640946291509],
						[58.828550058103666, 2.3031640906625865],
						[58.828550080222463, 2.3031640829022995],
						[58.828550089854367, 2.3031640775613078],
						[58.828938990413704, 2.302966879633539],
						[58.829330684714861, 2.3027695803746191],
						[58.829744478182718, 2.3025918844865521],
						[58.830161173440942, 2.3024168864172361],
						[58.830605669177039, 2.3022529871917596],
						[58.83060567015859, 2.3022529887390717],
						[58.831047270202404, 2.3020890882811815],
						[58.831047276356195, 2.3020890853510458],
						[58.831047290447636, 2.3020890791450443],
						[58.831047295619896, 2.3020890746675811],
						[58.831477895824882, 2.3018529749362617],
						[58.831908395756649, 2.3016168744084711],
						[58.831908401108166, 2.301616873371255],
						[58.831908408866532, 2.3016168666547654],
						[58.831908414218027, 2.3016168656175524],
						[58.832311214277297, 2.3013390643994556],
						[58.832311214277297, 2.3013390643994556],
						[58.83271120866096, 2.3010612666807],
						[58.833114005400937, 2.300814069879408],
						[58.833516794772642, 2.3005668752899306],
						[58.833927885275152, 2.3003723804863023],
						[58.834341770223944, 2.3001807882569918],
						[58.834741754953505, 2.3000668922677567],
						[58.835141745947219, 2.2999529955573679],
						[58.835522236438088, 2.2998807970905215],
						[58.835905636079573, 2.2998112962325599],
						[58.835905645890612, 2.2998112943301883],
						[58.83590566720688, 2.299811288459146],
						[58.835905676928263, 2.2998112848363452],
						[58.836277877471147, 2.2996557854020607],
						[58.836650077194491, 2.2995001851432564],
						[58.836650086826189, 2.2995001797998449],
						[58.836650106891867, 2.2995001672195974],
						[58.836650117415495, 2.2995001617032118],
						[58.837016816961139, 2.299236262727459],
						[58.837383409177875, 2.2989723681819054],
						[58.837764000943217, 2.2987501731404079],
						[58.838144475026461, 2.2985279887214465],
						[58.838547247109439, 2.2984307938853288],
						[58.838950031973887, 2.2983334983706576],
						[58.839355615578469, 2.2983001988967051],
						[58.839355617362287, 2.2983001985506966],
						[58.839355618254196, 2.2983001983776923],
						[58.839761104614539, 2.2982640993518815],
						[58.840136093420419, 2.2982779998464999],
						[58.84051386870243, 2.2982918986258536],
						[58.840869345907755, 2.2983918930850407],
						[58.841224940271552, 2.2984918909472762],
						[58.84160273277331, 2.2986251881924149],
						[58.841980532391695, 2.2987612885047888],
						[58.841980539706334, 2.2987612905621204],
						[58.841980556119445, 2.2987612943307765],
						[58.841980565307587, 2.2987612977627943],
						[58.842413864949137, 2.2988390971174777],
						[58.842844364443465, 2.2989168971726412],
						[58.842844378001509, 2.2989168980189487],
						[58.842844405117518, 2.2989168997115921],
						[58.842844418585869, 2.2989168988371489],
						[58.843305619339823, 2.2988723984445305],
						[58.843763918068788, 2.2988307990049153],
						[58.84376392698789, 2.2988307972746997],
						[58.84376394571801, 2.2988307936412475],
						[58.843763954637126, 2.2988307919110307],
						[58.844216754751002, 2.2987001916611742],
						[58.844666739717589, 2.2985723968900951],
						[58.845097225135291, 2.298516898753082],
						[58.845530585778697, 2.2984641037822122],
						[58.845952747972213, 2.2985779934726547],
						[58.846374932854424, 2.2986918898690534],
						[58.846780519920557, 2.2988695834738606],
						[58.846780520812452, 2.2988695833008217],
						[58.847188821355616, 2.299044584796198],
						[58.8471888341114, 2.2990445875365557],
						[58.847188859712659, 2.2990445947382714],
						[58.847188873360345, 2.2990445973055946],
						[58.847594372617557, 2.299100198757317],
						[58.847999972568026, 2.299155797290342],
						[58.847999986126069, 2.2991557981367281],
						[58.848000012260485, 2.2991557982815602],
						[58.848000025728851, 2.2991557974069323],
						[58.848430625949931, 2.2991001977964292],
						[58.848863911979194, 2.2990446003212162],
						[58.849313898060998, 2.2990501992442169],
						[58.849761083295888, 2.2990558006735178],
						[58.850194369747271, 2.2991223982906486],
						[58.850194370639166, 2.2991223981175914],
						[58.850630570615223, 2.2991862985121041],
						[58.850630579624003, 2.299186298502669],
						[58.850630598623162, 2.2991863000318462],
						[58.850630607631942, 2.2991863000223969],
						[58.851052807630936, 2.2991696007971174],
						[58.85105280941476, 2.2991696004509921],
						[58.851477809293748, 2.299150199578023],
						[58.851477814645207, 2.2991501985396301],
						[58.851477827221629, 2.2991501978379079],
						[58.851477832573075, 2.299150196799514],
						[58.851905632959976, 2.2990779977503952],
						[58.852336120824731, 2.2990057983516134],
						[58.852758309297855, 2.2989862993617067],
						[58.85318060763386, 2.2989696000787117],
						[58.853180609417677, 2.2989695997325481],
						[58.853180611201502, 2.2989695993863877],
						[58.853180612093404, 2.2989695992133035],
						[58.853600012122584, 2.2989445994776849],
						[58.854016712078923, 2.2989195994127467],
						[58.854016718322285, 2.2989195982011239],
						[58.854016729114875, 2.2989195978453623],
						[58.854016735358243, 2.2989195966337368],
						[58.854455635240171, 2.2988418964356496],
						[58.854455636132087, 2.2988418962625534],
						[58.854891726400588, 2.2987612979596861],
						[58.855361117357383, 2.2987223984547538],
						[58.855827797611184, 2.2986835019080356],
						[58.856305579924133, 2.2987307991197921],
						[58.8567832769332, 2.2987779989693724],
						[58.857247173191872, 2.298841897144797],
						[58.857713873222707, 2.2989057984183381],
						[58.857713879555774, 2.298905798928041],
						[58.857713894897586, 2.298905799428077],
						[58.857713903014464, 2.2989057995915343],
						[58.85813330318468, 2.2989002000213317],
						[58.858552803331655, 2.298894599679318],
						[58.858552822151417, 2.2988945977653175],
						[58.858552858719662, 2.2988945906673699],
						[58.858552877360069, 2.2988945853102996],
						[58.858925077334767, 2.2987390851812184],
						[58.859297277469793, 2.2985834857094094],
						[58.85929728790363, 2.2985834784670733],
						[58.859297309842518, 2.2985834672524397],
						[58.859297320276319, 2.2985834600101276],
						[58.859666819941928, 2.2983057605370227],
						[58.860039014206627, 2.2980307644681641],
						[58.860433407805402, 2.2977779672967391],
						[58.860827887316717, 2.2975251820553919],
						[58.861225064315981, 2.2973918896939072],
						[58.86162225035801, 2.2972584947727119],
						[58.862005635632102, 2.2971890977344911],
						[58.862005637415912, 2.2971890973881148],
						[58.862005637326206, 2.2971890956664147],
						[58.862386136817292, 2.2971168953858974],
						[58.862386151087755, 2.2971168926148184],
						[58.862386177575743, 2.2971168822538743],
						[58.862386191756499, 2.2971168777610771],
						[58.862741792319042, 2.2969334773516574],
						[58.862741792319042, 2.2969334773516574],
						[58.862741793210951, 2.2969334771784582],
						[58.863097292618377, 2.2967473774762555],
						[58.863097314377647, 2.2967473628155548],
						[58.863097351383644, 2.2967473295413416],
						[58.863097367432573, 2.2967473090328658],
						[58.863386266778761, 2.296305708730686],
						[58.863386267670677, 2.2963057085574619],
						[58.863386267670677, 2.2963057085574619],
						[58.863672367630038, 2.2958612075302698],
						[58.863672375118732, 2.2958611956404935],
						[58.863672385636626, 2.2958611727271001],
						[58.863672391251782, 2.2958611594620115],
						[58.863883490616359, 2.295186159418626],
						[58.864094591282793, 2.2945111607482627],
						[58.864094591995197, 2.2945111571314043],
						[58.864094592617825, 2.2945111517927415],
						[58.864094594311851, 2.2945111497244062],
						[58.864291894411679, 2.2937444504844864],
						[58.864291894321909, 2.293744448762685],
						[58.864486294312734, 2.2929806481519099],
						[58.864486294312734, 2.2929806481519099],
						[58.864486294222964, 2.2929806464301015],
						[58.864677993924566, 2.2921944465042237],
						[58.864869593523927, 2.291411147285086],
						[58.864869595217925, 2.2914111452165518],
						[58.864869595930216, 2.2914111415995397],
						[58.86486959584041, 2.291411139877721],
						[58.865030795574803, 2.2906000386305467],
						[58.865030796466684, 2.2906000384571699],
						[58.865189095814706, 2.2897861411901288],
						[58.865364095406527, 2.2890028440830847],
						[58.865541889894445, 2.2882194644558691],
						[58.865822381894894, 2.2876083836168535],
						[58.866105764123816, 2.2869945211555551],
						[58.86650293327763, 2.2866390489896764],
						[58.866900105354276, 2.2862834736803181],
						[58.867344470022658, 2.286116886582906],
						[58.867786155310533, 2.2859529938008336],
						[58.86821943912922, 2.2858668965217297],
						[58.868650025041553, 2.2857834994218456],
						[58.86906941233525, 2.2857584993500315],
						[58.869486095118397, 2.2857335009312032],
						[58.869894378441415, 2.2857779988296225],
						[58.86989437933331, 2.2857779986560862],
						[58.86989438022519, 2.2857779984825504],
						[58.870302779866755, 2.2858195989677039],
						[58.870302780758628, 2.2858195987941681],
						[58.870302782542403, 2.2858195984470924],
						[58.870302785307906, 2.285819599648554],
						[58.87069718408614, 2.2858501997143961],
						[58.871094383321243, 2.2858834996113333],
						[58.871094395987377, 2.2858835006259759],
						[58.87109442104996, 2.2858834974889408],
						[58.871094433626205, 2.2858834967814747],
						[58.871483334249362, 2.2858168963002989],
						[58.87153713881542, 2.2858076689095599],
						[58.870322037366364, 2.2875220844274642],
						[58.870322033176485, 2.2875220904611671],
						[58.870322024886619, 2.2875221042506726],
						[58.870322020696747, 2.2875221102843759],
						[58.867985920858452, 2.2921916116787893],
						[58.867985914441604, 2.2921916268414906],
						[58.867985903661086, 2.29219166198614],
						[58.867985900991407, 2.2921916798993842],
						[58.867377601715219, 2.2983305702860473],
						[58.865858104013029, 2.3055832597888575],
						[58.865858101610286, 2.3055832828658356],
						[58.865858103048225, 2.305583327808824],
						[58.865858105996985, 2.3055833498478129],
						[58.86708040571888, 2.3104222487453976],
						[58.867080415089426, 2.3104222730194657],
						[58.867080439894927, 2.3104223169134173],
						[58.867080455329898, 2.3104223365332963],
						[58.870472055249031, 2.3140140364631971],
						[58.870472091734996, 2.3140140624449357],
						[58.870472172641762, 2.3140140937362936],
						[58.870472217062577, 2.314014099045917],
						[58.872938916654306, 2.3138029990565085],
						[58.872938930033328, 2.3138029964639784],
						[58.872938955007463, 2.3138029916245948],
						[58.87293896740502, 2.3138029874826023],
						[58.878397266739022, 2.3118640874605423],
						[58.878397293138747, 2.3118640753825996],
						[58.878397339959896, 2.3118640402062778],
						[58.878397359578891, 2.3118640190033846],
						[58.880894560229272, 2.3085057189115297],
						[58.880894562815449, 2.3085057166697784],
						[58.880894567808653, 2.3085057087409004],
						[58.880894570394837, 2.3085057064991448],
						[58.882272364669134, 2.3062890128095286],
						[58.884322359817965, 2.3035529200858753],
						[58.884322370605943, 2.3035529023290802],
						[58.8843223876327, 2.303552865958538],
						[58.884322394673724, 2.3035528454487868],
						[58.885461294761868, 2.2987611453339043],
						[58.885461294672147, 2.2987611436110327],
						[58.885461296988993, 2.2987611361996159],
						[58.885461296809559, 2.2987611327538753],
						[58.885877996803352, 2.2962694332832387],
						[58.885877998048628, 2.2962694225991855],
						[58.885878000449452, 2.2962693995081773],
						[58.885877999821176, 2.2962693874479925],
						[58.885733500009884, 2.2939888870400535],
						[58.8857334931322, 2.2939888587918738],
						[58.885733472151891, 2.2939888019601047],
						[58.885733457247163, 2.2939887752728114],
						[58.885104092586246, 2.2931950140752821],
						[58.885186267242339, 2.2930695092103455],
						[58.885447366250858, 2.292677911291833],
						[58.885447375343276, 2.2926778956009337],
						[58.8854473881768, 2.2926778652598934],
						[58.885447393611848, 2.2926778485399861],
						[58.885622394216753, 2.2919833486919692],
						[58.885800193977829, 2.2912917495448868],
						[58.885800195402332, 2.2912917423064405],
						[58.88580019834118, 2.2912917295523774],
						[58.885800198873795, 2.2912917224874367],
						[58.885894598874884, 2.2904333218682105],
						[58.885989099426602, 2.2895750215455557],
						[58.88598909924692, 2.2895750180998271],
						[58.885989099779465, 2.2895750110348092],
						[58.885989099599776, 2.2895750075890815],
						[58.886022399839334, 2.288644406867208],
						[58.88605300040075, 2.2877167062925965],
						[58.886086299993778, 2.2867861074527163],
						[58.886119599672476, 2.2858583148968035],
						[58.886211298706847, 2.2849806212970707],
						[58.886211298616928, 2.2849806195742146],
						[58.88630019751966, 2.2841056273932154],
						[58.886444596578151, 2.2832917342665615],
						[58.886589096685157, 2.2824778348222168],
						[58.886722397256257, 2.2817167344849869],
						[58.886722397256257, 2.2817167344849869],
						[58.886852996691317, 2.2809528332544335],
						[58.886852998295169, 2.2809528294611097],
						[58.886852998827202, 2.2809528223958178],
						[58.886852998557337, 2.2809528172271882],
						[58.886919599273263, 2.2802028183977234],
						[58.886986298611163, 2.2794556181031074],
						[58.886986299323084, 2.2794556144835219],
						[58.886986299945015, 2.279455609141074],
						[58.886986299855039, 2.2794556074181802],
						[58.887016899760759, 2.2786722078483699],
						[58.887016899760759, 2.2786722078483699],
						[58.887044600019287, 2.277888908578666],
						[58.88709460034061, 2.2770500113086922],
						[58.887144598989885, 2.2762111164596619],
						[58.887230799109616, 2.275344419810573],
						[58.887316899131065, 2.2744806232471664],
						[58.887439097652418, 2.2736333284053614],
						[58.887561296872413, 2.2727861328469756],
						[58.887705796727836, 2.2719722343261077],
						[58.887850197035768, 2.2711583355223968],
						[58.887850196855609, 2.2711583320765989],
						[58.887850198459127, 2.2711583282827466],
						[58.887850198278954, 2.2711583248369647],
						[58.887950197665482, 2.2703667258030773],
						[58.88805019784634, 2.2695750256552509],
						[58.888050199269586, 2.2695750184155163],
						[58.888050199620785, 2.2695750079040531],
						[58.888050200152179, 2.2695750008383815],
						[58.888052999837598, 2.2687667012976824],
						[58.888055799573323, 2.2679611004804818],
						[58.888055800375042, 2.2679610985834597],
						[58.888055800194763, 2.2679610951376805],
						[58.888055800104645, 2.2679610934147898],
						[58.888027999920666, 2.2671249929855821],
						[58.888000199823388, 2.2662917023609972],
						[58.888041899967824, 2.2654472108556818],
						[58.888086299323888, 2.2646000172247205],
						[58.888183498583921, 2.2637611222679315],
						[58.888280798849529, 2.2629194225381806],
						[58.888280798759332, 2.2629194208152983],
						[58.888280800182208, 2.2629194135752511],
						[58.888280799911605, 2.2629194084066024],
						[58.888319600081871, 2.2620861095621478],
						[58.888361300203492, 2.2612556093147997],
						[58.8883612998426, 2.2612556024232573],
						[58.888361300102915, 2.2612555901887799],
						[58.888361298850164, 2.2612555834715358],
						[58.888297399359764, 2.2604943836391138],
						[58.888236298935041, 2.2597332846455473],
						[58.888236299465909, 2.2597332775797234],
						[58.888236295978231, 2.2597332625968782],
						[58.888236294725424, 2.2597332558797163],
						[58.888097395064946, 2.259124955557672],
						[58.887958495089805, 2.2585193558472656],
						[58.887958491872759, 2.2585193460332258],
						[58.887958486420771, 2.2585193279536329],
						[58.887958483203754, 2.2585193181395904],
						[58.887747382833759, 2.2580443187627792],
						[58.8875390834623, 2.257569320248205],
						[58.887539081407851, 2.2575693154284679],
						[58.887539077389192, 2.2575693075118455],
						[58.887539075424996, 2.2575693044149552],
						[58.887275174625316, 2.2570888044078758],
						[58.887011280399584, 2.2566082109170975],
						[58.886758483085117, 2.2560332194039359],
						[58.886758483085117, 2.2560332194039359],
						[58.886502986192156, 2.2554555266923884],
						[58.886280788566701, 2.2548055355281891],
						[58.886061289978826, 2.2541527373595591],
						[58.885841890358449, 2.2534916363757707],
						[58.885625190175098, 2.2528277387063684],
						[58.885625189102605, 2.2528277354354334],
						[58.885625187939795, 2.2528277304417617],
						[58.885625186867308, 2.2528277271708244],
						[58.885377986963597, 2.252183227191606],
						[58.885130787010844, 2.2515388281977629],
						[58.885130786028661, 2.251538826649603],
						[58.885130784064337, 2.2515388235532727],
						[58.885130783973999, 2.251538821830585],
						[58.884864083858311, 2.2509221203844079],
						[58.884864082966509, 2.2509221205589416],
						[58.884594582823276, 2.2503027200093375],
						[58.884594582732895, 2.2503027182866679],
						[58.884594581841064, 2.2503027184612217],
						[58.884327982123438, 2.2497138173245688],
						[58.884327982123438, 2.2497138173245688],
						[58.884061282173498, 2.2491277167839394],
						[58.884061281191286, 2.249127715235876],
						[58.88406128020911, 2.2491277136878272],
						[58.884061280118729, 2.2491277119652029],
						[58.883786280129478, 2.2485638135992603],
						[58.88351408019448, 2.2479999133358004],
						[58.883514080104078, 2.2479999116132103],
						[58.883514079212276, 2.2479999117878053],
						[58.883233479131285, 2.2474305121602867],
						[58.882952980092497, 2.2468610145483074],
						[58.882683481217235, 2.2462832146116471],
						[58.882414081359279, 2.2457082143542326],
						[58.882414081359279, 2.2457082143542326],
						[58.882414080286608, 2.2457082110838114],
						[58.882414079394813, 2.2457082112584628],
						[58.882133478754902, 2.2451388119404521],
						[58.881852978974386, 2.2445693111895602],
						[58.881852978974386, 2.2445693111895602],
						[58.881550178970485, 2.2439638113811027],
						[58.881250181000702, 2.243361014735556],
						[58.880958483380546, 2.2427055191234255],
						[58.880958483290087, 2.2427055174009771],
						[58.880666884161364, 2.2420527224439217],
						[58.88041128590428, 2.2414166261097743],
						[58.880155785585991, 2.2407777265909088],
						[58.879925187133786, 2.2401777284883919],
						[58.879691886794092, 2.2395749282602702],
						[58.879461286934571, 2.2389610300597238],
						[58.879230787400658, 2.2383471303876137],
						[58.879230787310149, 2.2383471286652661],
						[58.879230786327845, 2.2383471271177391],
						[58.879230786237322, 2.2383471253953933],
						[58.878980785914258, 2.2377166262682535],
						[58.878730785580714, 2.2370832263958973],
						[58.878730785580714, 2.2370832263958973],
						[58.878730784598396, 2.2370832248484276],
						[58.878730783616071, 2.2370832233009605],
						[58.878466884135314, 2.2364527227856064],
						[58.87820298612332, 2.2358221267233271],
						[58.877958487249899, 2.2351666304891018],
						[58.877714090464792, 2.2345110377475921],
						[58.877508492175473, 2.2337999443755527],
						[58.877302993035514, 2.233088846433712],
						[58.877102992774681, 2.2323499477755853],
						[58.876902992863997, 2.231613847964439],
						[58.876902991790992, 2.2316138446949902],
						[58.876902989645025, 2.2316138381561181],
						[58.87690298857202, 2.2316138348866703],
						[58.87666688866139, 2.2309221355041786],
						[58.876433489336435, 2.2302305362193526],
						[58.876433489245798, 2.2302305344971747],
						[58.87643348719039, 2.2302305296806577],
						[58.876433486207993, 2.2302305281334922],
						[58.87618078617659, 2.2295805279526397],
						[58.876180786085946, 2.2295805262304809],
						[58.875927989208904, 2.2289305349253912],
						[58.875722390904222, 2.2282499421031994],
						[58.875516894216936, 2.2275693508473324],
						[58.875366895590879, 2.2268166605850386],
						[58.875366895590879, 2.2268166605850386],
						[58.875214095790021, 2.2260666594564573],
						[58.875058496319227, 2.2253110601545587],
						[58.874902995867707, 2.2245527594919556],
						[58.874902994794553, 2.2245527562229364],
						[58.874902993358596, 2.2245527460655792],
						[58.874902992285435, 2.224552742796547],
						[58.874702992307917, 2.2238777433035937],
						[58.87450299205198, 2.2232027424871843],
						[58.874502990978769, 2.2232027392182485],
						[58.874502988923112, 2.2232027344024141],
						[58.874502987849908, 2.2232027311334805],
						[58.874277987742161, 2.2225943308531053],
						[58.874055788299806, 2.2219860315652991],
						[58.874055787317296, 2.2219860300184631],
						[58.873825186953333, 2.2213721301452924],
						[58.873594586928938, 2.2207582292584944],
						[58.873594586928938, 2.2207582292584944],
						[58.873594585946428, 2.2207582277117237],
						[58.873594585764948, 2.2207582242677182],
						[58.873339085809427, 2.2201221266495259],
						[58.873083485954474, 2.2194832251665231],
						[58.87308348497195, 2.2194832236198128],
						[58.873083481933605, 2.2194832172576984],
						[58.873083480951081, 2.2194832157109876],
						[58.872783481212899, 2.2188388167904618],
						[58.872483480590127, 2.2181916154573251],
						[58.872483481391107, 2.2181916135600934],
						[58.872483478534271, 2.2181916106420934],
						[58.872483478443499, 2.2181916089201548],
						[58.872152978257219, 2.2175443091990399],
						[58.872152978166433, 2.2175443074771191],
						[58.871819578287074, 2.2168999087352801],
						[58.871819577304507, 2.2168999071886999],
						[58.871819576321968, 2.2168999056421015],
						[58.871819576321968, 2.2168999056421015],
						[58.871483476286485, 2.2162777041780419],
						[58.871147377699579, 2.2156582088043053],
						[58.870847380460155, 2.2150416133111506],
						[58.87054738580936, 2.2144249248622798],
						[58.870330790404971, 2.213761037268402],
						[58.870330790404971, 2.213761037268402],
						[58.870111292377217, 2.2130999430562874],
						[58.869939094233452, 2.2124055515876817],
						[58.869766893567274, 2.2117082513640898],
						[58.869766894368176, 2.2117082494668674],
						[58.869766892312079, 2.2117082446523941],
						[58.8697668922212, 2.2117082429306141],
						[58.869566891786306, 2.2110332430966095],
						[58.869366892054572, 2.2103582434847064],
						[58.86936689098107, 2.2103582402166526],
						[58.869158491082352, 2.2096888412777744],
						[58.8689501942495, 2.209019349612698],
						[58.868797395936795, 2.2083110575366773],
						[58.868644596958084, 2.2076027626454553],
						[58.868527998105378, 2.2068693697512436],
						[58.868411297948995, 2.2061360690632941],
						[58.868411296966322, 2.2061360675171771],
						[58.868411296966322, 2.2061360675171771],
						[58.868411296875394, 2.2061360657954805],
						[58.868286297195922, 2.2054082656933423],
						[58.868161296720949, 2.2046805661626081],
						[58.868161296629999, 2.2046805644409289],
						[58.86816129635713, 2.2046805592759067],
						[58.86816129626618, 2.204680557554231],
						[58.868008495919604, 2.2039721572230566],
						[58.867855795888218, 2.2032638576970847],
						[58.867855793831851, 2.2032638528833899],
						[58.867855791593506, 2.2032638446263899],
						[58.867855791320572, 2.2032638394614259],
						[58.867647391004326, 2.2025971396383008],
						[58.867439091228654, 2.2019277416808314],
						[58.86743908908123, 2.201927735145663],
						[58.867439085951069, 2.2019277270645303],
						[58.867439084877375, 2.2019277237969277],
						[58.867177985134369, 2.201302723494007],
						[58.866919585106395, 2.200677723805339],
						[58.866919584032644, 2.2006777205378456],
						[58.866919582067176, 2.2006777174460561],
						[58.866919580993432, 2.2006777141785441],
						[58.866650181473162, 2.2001027155225508],
						[58.866650181382163, 2.2001027138009666],
						[58.866377981257095, 2.1995277137216926],
						[58.866377978308861, 2.1995277090841627],
						[58.866377973304097, 2.1995276996333883],
						[58.866377970355863, 2.199527694995874],
						[58.86608347013361, 2.1990498953179043],
						[58.865789069691189, 2.1985720959259307],
						[58.865789060937381, 2.1985720837353884],
						[58.865789040754642, 2.1985720598815361],
						[58.865789030217414, 2.1985720480424891],
						[58.865450130745479, 2.19828044915544],
						[58.865111231069214, 2.1979859491148739],
						[58.865111205900227, 2.1979859332023879],
						[58.865111153251242, 2.1979859087907005],
						[58.865111123987816, 2.197985900643034],
						[58.86475002433302, 2.1979415006481657],
						[58.864388923943601, 2.1978970023121489],
						[58.864388902269141, 2.1978970013659378],
						[58.864388856609274, 2.1978970068866754],
						[58.864388835207947, 2.1978970111048741],
						[58.864016636425177, 2.1980248117176422],
						[58.863644345169327, 2.1981498079655344],
						[58.863238852697407, 2.198247006205797],
						[58.862836076592117, 2.1983442004546268],
						[58.862388900829508, 2.1983414993880213],
						[58.861938909637793, 2.1983387005766231],
						[58.861461118759827, 2.1982942005129464],
						[58.860983338691561, 2.1982498034353695],
						[58.860536158967797, 2.1981109082949035],
						[58.860536158076073, 2.1981109084706296],
						[58.86008901516923, 2.1979748267455528],
						[58.859777953166841, 2.1976026715995483],
						[58.859464088099486, 2.1972277134725218],
						[58.859352997029973, 2.1966027657019702],
						[58.859241899925216, 2.195977781007417],
						[58.859233500061436, 2.1952527971787865],
						[58.859225200323131, 2.1945249986251705],
						[58.859225199776645, 2.1945249882979803],
						[58.859225198065175, 2.1945249729830043],
						[58.85922519671805, 2.1945249645528375],
						[58.859116897320291, 2.1939193648803119],
						[58.859008497070121, 2.1933110650182939],
						[58.859008488387715, 2.1933110371648947],
						[58.859008462287996, 2.1933109866588456],
						[58.859008444779576, 2.1933109622850155],
						[58.858719545881932, 2.1930054637500227],
						[58.85843074568723, 2.1926970636771324],
						[58.858430719352654, 2.1926970427836823],
						[58.858430658221977, 2.1926970113610111],
						[58.858430626192074, 2.1926970020254242],
						[58.8580444256772, 2.1926470023955646],
						[58.858044424785497, 2.1926470025714098],
						[58.858044423802667, 2.1926470010261299],
						[58.857661124030173, 2.192599800511243],
						[58.857661118679893, 2.1925998015663279],
						[58.857661106905418, 2.1925998004101293],
						[58.857661100572336, 2.1925997999199502],
						[58.857283301128554, 2.1925970006229045],
						[58.857283300236837, 2.192597000798751],
						[58.856902824253254, 2.192597000261125],
						[58.856544446585566, 2.1925109059204746],
						[58.856186175112562, 2.1924248112875024],
						[58.855825100210481, 2.1922165262771922],
						[58.855825099318778, 2.1922165264530356],
						[58.855464005928027, 2.1920109306378017],
						[58.855077912098558, 2.1917498335445669],
						[58.854691812037991, 2.1914887344669181],
						[58.854691798115596, 2.1914887267789855],
						[58.854691767686823, 2.1914887136516428],
						[58.854691751981036, 2.191488706315416],
						[58.854269451917432, 2.1913748073080801],
						[58.853847251597749, 2.1912609066928561],
						[58.853847227957651, 2.1912609026605119],
						[58.853847178457968, 2.1912609037279807],
						[58.853847154199549, 2.1912609050342526],
						[58.853402753639095, 2.1913665054631455],
						[58.85295825408798, 2.1914720057648749],
						[58.852958252304539, 2.1914720061165704],
						[58.852958247846004, 2.1914720069957863],
						[58.852958246062578, 2.1914720073474747],
						[58.852519345805952, 2.1915942076458297],
						[58.852519345805952, 2.1915942076458297],
						[58.852077785603349, 2.1917191963108742],
						[58.851672226731601, 2.1916637020798722],
						[58.851672225839877, 2.1916637022557053],
						[58.851266768354144, 2.1916109066774587],
						[58.850908405017897, 2.1913915307723184],
						[58.85055291729833, 2.1911720371951477],
						[58.850202927405661, 2.1908831464662657],
						[58.850202927314548, 2.190883144745531],
						[58.849852926723599, 2.1905970453053891],
						[58.84985292386623, 2.1905970423914907],
						[58.849852919134293, 2.1905970381085567],
						[58.849852916368036, 2.1905970369153729],
						[58.849464016818423, 2.1903192382248147],
						[58.849075116669063, 2.1900387384598305],
						[58.849075111937125, 2.1900387341770573],
						[58.849075101672675, 2.1900387275080573],
						[58.84907509703185, 2.1900387249459801],
						[58.84864449795252, 2.1897998243043419],
						[58.848216798252771, 2.189558126755093],
						[58.84778069773791, 2.1893137260027746],
						[58.847341803888995, 2.1890665282126021],
						[58.846922310238654, 2.1887887329230713],
						[58.846500109784344, 2.1885109336800639],
						[58.84650010505235, 2.1885109293978178],
						[58.846500094878976, 2.1885109244502603],
						[58.846500090238145, 2.1885109218885557],
						[58.846094489640137, 2.1883054223849556],
						[58.846094489548982, 2.1883054206644337],
						[58.846094488657279, 2.1883054208403103],
						[58.845686188772959, 2.1881026208771486],
						[58.845686174941591, 2.1881026149128386],
						[58.845686147461159, 2.188102606425232],
						[58.845686132829243, 2.1881026023573034],
						[58.845283333366098, 2.1880359022659932],
						[58.844880632980839, 2.1879692029758475],
						[58.844880631106257, 2.1879692016071468],
						[58.844880626647743, 2.1879692024865349],
						[58.844880625756026, 2.1879692026624151],
						[58.844475025981886, 2.1879165022932123],
						[58.844069445632606, 2.1878609045519748],
						[58.843647264871947, 2.1877165103368199],
						[58.843647263980259, 2.1877165105126934],
						[58.843647263980259, 2.1877165105126934],
						[58.843225063543663, 2.1875748108331554],
						[58.842775064902433, 2.1874192111469988],
						[58.842325064913098, 2.1872637109572115],
						[58.842325061163947, 2.1872637082200659],
						[58.842325051172871, 2.1872637067140532],
						[58.842325045640308, 2.1872637043286653],
						[58.841855645789771, 2.1871526059011281],
						[58.841386149293115, 2.1870415064984852],
						[58.840919451634257, 2.1869165079032764],
						[58.840919450651363, 2.1869165063588976],
						[58.840452858952553, 2.1867942083634171],
						[58.839997266581769, 2.1866331112013868],
						[58.839997265690059, 2.186633111377267],
						[58.839538966156823, 2.1864720114103351],
						[58.839083366394753, 2.1863137113262234],
						[58.838625066148566, 2.1861526104155824],
						[58.838625063382281, 2.1861526092230643],
						[58.838625056066306, 2.1861526071898325],
						[58.838625051516622, 2.1861526063490975],
						[58.838161151775672, 2.1860276070330489],
						[58.838161150883955, 2.1860276072089344],
						[58.837694451133785, 2.1859054069452091],
						[58.837694448367479, 2.1859054057527634],
						[58.83769444381781, 2.1859054049120688],
						[58.837694441051518, 2.1859054037196017],
						[58.837222241264755, 2.1858054033534926],
						[58.836750045900594, 2.1857054043851907],
						[58.83628335122178, 2.1855831068092395],
						[58.835816759130175, 2.1854581083912086],
						[58.835361166589294, 2.1852970112696966],
						[58.835361165697591, 2.1852970114455759],
						[58.835361164805889, 2.1852970116214565],
						[58.834902867638355, 2.1851387128395139],
						[58.834450070912595, 2.1849665122343187],
						[58.833994473761919, 2.1847915139300942],
						[58.83354447578234, 2.1846081145526162],
						[58.833091782708145, 2.1844220187124015],
						[58.832650089119319, 2.1842026209026799],
						[58.832208394300167, 2.183980423684341],
						[58.831772299472441, 2.18373312646693],
						[58.831772297689042, 2.183733126818721],
						[58.831336198120688, 2.1834887262487293],
						[58.830900099012155, 2.183241526601285],
						[58.830464007624506, 2.1829942304462193],
						[58.830041817790061, 2.1826887388817364],
						[58.829622334916834, 2.1823804508937066],
						[58.82923074777716, 2.1819498642085344],
						[58.828839051986364, 2.1815220693637825],
						[58.828458456932275, 2.181041575455132],
						[58.828080757171634, 2.1805637766006414],
						[58.828080755205768, 2.1805637735133452],
						[58.828080750382341, 2.1805637675146845],
						[58.828080748416468, 2.1805637644273888],
						[58.8276890477702, 2.1801331650502016],
						[58.827689046878511, 2.1801331652261617],
						[58.827297346915351, 2.1797054645813692],
						[58.827297344949443, 2.1797054614941933],
						[58.827297340217243, 2.1797054572153556],
						[58.827297337359667, 2.1797054543041305],
						[58.826894536949332, 2.1793248553444164],
						[58.826894536858077, 2.1793248536248764],
						[58.826489037286841, 2.1789442536765011],
						[58.826489036303883, 2.1789442521329678],
						[58.82648903264586, 2.1789442511173824],
						[58.826489031662909, 2.1789442495738491],
						[58.826080731750267, 2.1785859505195662],
						[58.826080730767309, 2.1785859489760622],
						[58.82567233126079, 2.1782304488941389],
						[58.825672328403186, 2.1782304459831785],
						[58.825672320904587, 2.1782304405132344],
						[58.825672317155295, 2.1782304377782684],
						[58.8252501177722, 2.1779248382920207],
						[58.824827918169682, 2.1776165371929546],
						[58.824827910762323, 2.1776165334426967],
						[58.824827896839359, 2.1776165257661808],
						[58.824827890232413, 2.1776165201204871],
						[58.824386189565189, 2.1773942209639632],
						[58.8243861886735, 2.1773942211399704],
						[58.82394448910005, 2.1771748215290487],
						[58.82394448070972, 2.1771748162355946],
						[58.823944465094627, 2.1771748106308371],
						[58.823944455903906, 2.1771748072327721],
						[58.823480656357013, 2.1770387079039728],
						[58.823019456884246, 2.1769026075889748],
						[58.823019452334513, 2.1769026067496942],
						[58.823019443235076, 2.1769026050711302],
						[58.823019437793654, 2.1769026044078581],
						[58.822558338265253, 2.1768137031190298],
						[58.822558337373565, 2.1768137032950423],
						[58.822100037253698, 2.1767276026401774],
						[58.822100030920573, 2.1767276021529529],
						[58.822100018254389, 2.1767276011784991],
						[58.822100011921279, 2.1767276006912599],
						[58.821694411675452, 2.1767025995532587],
						[58.821694410783749, 2.1767025997292673],
						[58.821288910631743, 2.1766804004107154],
						[58.821288887265275, 2.1766804015483219],
						[58.821288840005835, 2.1766804108765787],
						[58.821288816913217, 2.1766804171719754],
						[58.820949916962618, 2.1768359184728654],
						[58.820608218077723, 2.1769887167712514],
						[58.820608203284124, 2.1769887266399959],
						[58.82060817360567, 2.176988744658293],
						[58.820608160686753, 2.1769887558942829],
						[58.820263761203108, 2.1773220563351945],
						[58.819922061356237, 2.1776526559240605],
						[58.819922057880753, 2.1776526583471263],
						[58.819922052804408, 2.1776526645605259],
						[58.819922050311888, 2.1776526685268127],
						[58.818894250094147, 2.1788220678969741],
						[58.818894236648354, 2.1788220861842005],
						[58.818894216089866, 2.1788221232462406],
						[58.818894208176737, 2.1788221439161473],
						[58.818374807854909, 2.1806082446071566],
						[58.818374807145645, 2.1806082482213021],
						[58.818374804126272, 2.1806082592395719],
						[58.818374802616574, 2.1806082647487006],
						[58.817780403252918, 2.1838221645371561],
						[58.81778040192544, 2.1838221734842822],
						[58.817780400162221, 2.1838221912027311],
						[58.817780399817622, 2.1838222016931601],
						[58.817827600163682, 2.1887277883584093],
						[58.817233101251439, 2.1938166773043233],
						[58.817233100632883, 2.1938166826372085],
						[58.817233100378523, 2.1938166948465412],
						[58.817233099759974, 2.1938167001794131],
					],
					[
						[58.874126681021878, 2.2826062223833907],
						[58.874316815672984, 2.2823057900897274],
						[58.874590407199207, 2.2822815527330271],
						[58.874126681021878, 2.2826062223833907],
					],
					[
						[58.875430187612103, 2.2822071565703852],
						[58.875697149107772, 2.2821835051377803],
						[58.877374891672829, 2.2832612686398845],
						[58.877374891672829, 2.2832612686398845],
						[58.877374894348463, 2.2832612681189306],
						[58.877374894438383, 2.2832612698413417],
						[58.877435655412484, 2.2832991091268648],
						[58.87703339831441, 2.2831359244143448],
						[58.876325116795833, 2.2826220379723305],
						[58.876325109301106, 2.2826220324721707],
						[58.876325092617869, 2.2826220235415349],
						[58.87632508423129, 2.2826220182150303],
						[58.875430187612103, 2.2822071565703852],
					],
					[
						[58.87881333202256, 2.2842799703473071],
						[58.878941606604116, 2.2843168802792708],
						[58.879285976779919, 2.2845862579617982],
						[58.879630442140879, 2.2848557300775205],
						[58.879913721918079, 2.2854139911435434],
						[58.880197015455238, 2.2859694776427659],
						[58.880433110396105, 2.2866806627877563],
						[58.880433109594101, 2.2866806646839355],
						[58.880558220703698, 2.2870530610962541],
						[58.87939187708281, 2.2848610066588928],
						[58.879391869228584, 2.2848609942677229],
						[58.879391850844506, 2.2848609700062275],
						[58.879391842188298, 2.2848609595111813],
						[58.87881333202256, 2.2842799703473071],
					],
				],
			],
		},
		{
			fldNpdidField: "43771",
			wlbNpdidWellbore: "464",
			fldName: "HEIDRUN",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6507/7-2",
			fldDiscoveryYear: "1985",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43771",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43771",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "HEIDRUN UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=464",
			fldHcType: "OIL/GAS",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "40490",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Kristiansund",
			fldAreaGeometryWKT: [
				[
					[
						[65.276733100224433, 7.3142860962499086],
						[65.276733101313056, 7.3142861133266228],
						[65.276733101408965, 7.314286121893617],
						[65.276802600659224, 7.3149250214522965],
						[65.276802601604004, 7.3149250256785621],
						[65.276802602596646, 7.3149250341883523],
						[65.276802604438231, 7.3149250383574058],
						[65.277180403687865, 7.3168361381556952],
						[65.277560903912786, 7.3187444380488191],
						[65.277935904117143, 7.3206556385011314],
						[65.277935905061739, 7.320655642727786],
						[65.27793590697479, 7.3206556533229659],
						[65.277935907919399, 7.3206556575496418],
						[65.278285908266525, 7.3218194587718921],
						[65.27828591194924, 7.3218194671113972],
						[65.278285921156098, 7.3218194879601972],
						[65.27828592668024, 7.3218195004694726],
						[65.278916527346183, 7.3229167990842736],
						[65.278916531028855, 7.3229168074241509],
						[65.27891654195767, 7.3229168217340863],
						[65.27891654743398, 7.3229168299600316],
						[65.279680446839478, 7.3238251298782364],
						[65.280444246880037, 7.3247334280692815],
						[65.281208147327959, 7.3256390280246899],
						[65.281977646777747, 7.3265473282036258],
						[65.282741546611703, 7.3274557289886388],
						[65.283505447399577, 7.3283640295040984],
						[65.284269247385993, 7.3292695284634979],
						[65.285033147151395, 7.3301779286724562],
						[65.285033148968779, 7.330177930701419],
						[65.285033152603575, 7.330177934759365],
						[65.285033155317791, 7.3301779367315918],
						[65.285780455268409, 7.3309695378322779],
						[65.285780455268409, 7.3309695378322779],
						[65.286527655158935, 7.3317584366155808],
						[65.287272053615169, 7.3325501380898759],
						[65.28727205540882, 7.332550137976555],
						[65.28727205540882, 7.332550137976555],
						[65.288019255084663, 7.3333390372135607],
						[65.288763753789539, 7.3341307370807627],
						[65.289508140718624, 7.3349223230680938],
						[65.290030431270409, 7.3357529054445871],
						[65.290030431294056, 7.3357529075874348],
						[65.29055543079545, 7.3365834053229975],
						[65.291074826379898, 7.3374139993198213],
						[65.291685922233896, 7.3386056910498096],
						[65.291685922233896, 7.3386056910498096],
						[65.292299822093298, 7.3397944923125467],
						[65.292910922060713, 7.3409833913187104],
						[65.292910923877983, 7.3409833933490161],
						[65.292910926639237, 7.3409833996091072],
						[65.292910927559674, 7.340983401695822],
						[65.293372028122135, 7.3417584018186739],
						[65.293830428410757, 7.3425307009372442],
						[65.29383043025156, 7.3425307051108906],
						[65.293830434829971, 7.3425307134018665],
						[65.293830437567607, 7.3425307175191934],
						[65.294477638310582, 7.3434251178900176],
						[65.294477638310582, 7.3434251178900176],
						[65.295127637816407, 7.3443195168560598],
						[65.295774838044665, 7.3452140170335696],
						[65.295774840782229, 7.3452140211514756],
						[65.295774848050996, 7.3452140292747474],
						[65.295774850788561, 7.3452140333926543],
						[65.296505451171456, 7.3460251339330052],
						[65.297233150860521, 7.346839032447817],
						[65.29723315088404, 7.3468390345912615],
						[65.297963750573146, 7.3476501339546507],
						[65.298694235767485, 7.3484612162871263],
						[65.299283123699411, 7.3495444962616645],
						[65.299872024416985, 7.3506278940355383],
						[65.300458123881072, 7.3517111950482121],
						[65.30045813025265, 7.3517112032307672],
						[65.300458142122494, 7.3517112217956067],
						[65.300458147597254, 7.3517112300342049],
						[65.301072047662458, 7.3524279305307303],
						[65.301685948276798, 7.3531445309899963],
						[65.302294241617375, 7.3538612226620179],
						[65.302685937118596, 7.354411215882636],
						[65.303077626786205, 7.3549612020282726],
						[65.30362481990295, 7.356088987592984],
						[65.30362481990295, 7.356088987592984],
						[65.304174819556764, 7.3572167861667044],
						[65.304722020029061, 7.3583444879319675],
						[65.304722022766242, 7.3583444920526082],
						[65.304722029184191, 7.3583445045262073],
						[65.304722031921415, 7.3583445086468657],
						[65.305269231730463, 7.3591834099995355],
						[65.30526923807831, 7.3591834160412475],
						[65.305269248106868, 7.3591834304362278],
						[65.305269253557881, 7.3591834365337441],
						[65.306027653735441, 7.3599945377420255],
						[65.30679155389231, 7.3608084372820191],
						[65.30754985371675, 7.3616195364283872],
						[65.30831095401976, 7.3624334368259374],
						[65.309074854050735, 7.3632445361465857],
						[65.309833153581096, 7.3640584350596301],
						[65.309833153604359, 7.3640584372041351],
						[65.310594253813207, 7.3648695369680963],
						[65.311352654017583, 7.365683436434928],
						[65.311352654017583, 7.365683436434928],
						[65.312116554424932, 7.36649453689569],
						[65.312116558955594, 7.3664945409072029],
						[65.312116567120071, 7.3664945489857576],
						[65.312116570753886, 7.3664945530528412],
						[65.31294987080976, 7.367194553942193],
						[65.312949871706607, 7.3671945538866437],
						[65.313785970736504, 7.3678945540001308],
						[65.314619271207363, 7.3685973532374573],
						[65.314619272104181, 7.3685973531819515],
						[65.315458171991864, 7.3692945526459317],
						[65.316291571160022, 7.3699945530663209],
						[65.317124871225545, 7.3706973530685351],
						[65.317124872122406, 7.3706973530130933],
						[65.31796094697016, 7.3713973336600427],
						[65.318577631191062, 7.3723779066801338],
						[65.319194231202317, 7.3733612050618937],
						[65.319810931188485, 7.374344506292684],
						[65.31981093668513, 7.3743445166873025],
						[65.319810952139548, 7.374344535054612],
						[65.319810959383631, 7.3743445410479485],
						[65.320569258670886, 7.3750945425391183],
						[65.320569282173622, 7.3750945582643022],
						[65.320569330926531, 7.3750945853133487],
						[65.320569357050445, 7.3750945944365185],
						[65.320938857446123, 7.3751751951605327],
						[65.320938890675379, 7.3751751974053192],
						[65.320938956098345, 7.3751751890777255],
						[65.320938988292056, 7.375175178505387],
						[65.321272288308094, 7.3750112794508942],
						[65.321272299897643, 7.3750112722956285],
						[65.321272323076712, 7.3750112579851219],
						[65.321272333746293, 7.3750112487397361],
						[65.321925133826056, 7.3744195472069727],
						[65.322577934367146, 7.3738307487534644],
						[65.322577934367146, 7.3738307487534644],
						[65.323233427964666, 7.3732390535830641],
						[65.323983420637305, 7.3726668597733127],
						[65.324736220950157, 7.3720945582919395],
						[65.325489020683563, 7.3715195590710314],
						[65.326241820845937, 7.3709473588130781],
						[65.326997320579821, 7.3703723580575069],
						[65.327750121297541, 7.3698001580055745],
						[65.327750131093154, 7.3698001509572411],
						[65.32775014884426, 7.3698001326796367],
						[65.327750157719862, 7.3698001235408519],
						[65.328480758151755, 7.368861222100203],
						[65.328480758151755, 7.368861222100203],
						[65.329205757632479, 7.3679223229858],
						[65.329936258307285, 7.3669834235899403],
						[65.330666857610908, 7.3660445234280418],
						[65.331397358297423, 7.3651057227873489],
						[65.332125158336055, 7.3641668224741528],
						[65.332855758308853, 7.3632279233761313],
						[65.333583457674877, 7.362289023129807],
						[65.334311245970682, 7.3613501379372277],
						[65.335019530091415, 7.3607445515256886],
						[65.335019530988234, 7.3607445514698639],
						[65.335725129673406, 7.360136251028365],
						[65.336433430108968, 7.3595279512234146],
						[65.337139030368604, 7.3589223515059512],
						[65.337847329942662, 7.3583140522832764],
						[65.337847346795584, 7.3583140340467628],
						[65.337847375947121, 7.3583139914130555],
						[65.337847387372292, 7.3583139692185169],
						[65.338047386629412, 7.3577778690804232],
						[65.338047390996735, 7.3577778580668527],
						[65.338047396970737, 7.3577778297671914],
						[65.338047398624198, 7.3577778167746493],
						[65.338189098717962, 7.3560111164003734],
						[65.338189099591375, 7.356011114197595],
						[65.33818910044144, 7.3560111098480077],
						[65.338189100418063, 7.3560111077012227],
						[65.338258499607491, 7.3540028063913567],
						[65.338258499584072, 7.3540028042445682],
						[65.338258500434065, 7.3540027998949276],
						[65.338258500410646, 7.3540027977481177],
						[65.338236299839892, 7.3525332966632408],
						[65.338216899553501, 7.351061108524668],
						[65.338277994222381, 7.3504639651783634],
						[65.338705748711547, 7.3497751395530662],
						[65.339466817290443, 7.3492251627414742],
						[65.340227854685622, 7.3486752072565169],
						[65.340899924291591, 7.3487473929013891],
						[65.341522079640129, 7.3492168597527412],
						[65.342144271874133, 7.3496834542277831],
						[65.342955465134779, 7.3504251463861632],
						[65.343763765394428, 7.3511695467061804],
						[65.343763765417904, 7.3511695488534139],
						[65.344574865232644, 7.3519112470127572],
						[65.345385965438041, 7.3526529473663533],
						[65.346191563657342, 7.3533973471878884],
						[65.346191564554132, 7.3533973471317866],
						[65.346191564554132, 7.3533973471317866],
						[65.347005464602375, 7.354139048689988],
						[65.347816564785049, 7.3548834463061539],
						[65.348622064631272, 7.3556251466483449],
						[65.349433164638157, 7.3563695466608738],
						[65.350241564820536, 7.3571112462340782],
						[65.351052664830618, 7.3578557475828905],
						[65.351052664830618, 7.3578557475828905],
						[65.351863765038857, 7.3585973475235544],
						[65.352669263920703, 7.3593418460075233],
						[65.352669270268507, 7.3593418520600249],
						[65.352669281123838, 7.3593418599808826],
						[65.352669286574837, 7.3593418660893022],
						[65.353469286660257, 7.3598890655308749],
						[65.354269287096372, 7.360436265560991],
						[65.35426929162719, 7.3604362695777876],
						[65.354269303355935, 7.3604362752955819],
						[65.354269308760237, 7.3604362771083931],
						[65.354938808704617, 7.3607779787505656],
						[65.355605508249681, 7.3611223784744233],
						[65.356274908275637, 7.3614668787186428],
						[65.356274919084228, 7.3614668823449296],
						[65.356274938907717, 7.3614668897091784],
						[65.35627494971628, 7.361466893335467],
						[65.356855550316354, 7.3616168935339354],
						[65.356855576371018, 7.3616168962108635],
						[65.356855626616678, 7.3616168952314815],
						[65.356855652624702, 7.3616168936117807],
						[65.35735565274949, 7.3614807921133565],
						[65.357355662591303, 7.3614807893505665],
						[65.357355684045203, 7.3614807815648993],
						[65.357355693863681, 7.3614807766537558],
						[65.358180693822092, 7.361039076220214],
						[65.359005694341874, 7.3606001769668303],
						[65.359005694341874, 7.3606001769668303],
						[65.359827893724471, 7.3601612760491451],
						[65.360652893792377, 7.3597223768945197],
						[65.361477893634301, 7.3592834777649401],
						[65.362302894123857, 7.3588445764554109],
						[65.363127863363445, 7.358405793222027],
						[65.363563858350417, 7.3583446083982924],
						[65.363949865377364, 7.3585751513809319],
						[65.364547041685427, 7.3593445219561104],
						[65.365138740677168, 7.3601168212353976],
						[65.365138741573986, 7.3601168211794752],
						[65.365138741597335, 7.3601168233284628],
						[65.365735941984667, 7.36088622273944],
						[65.366327638446208, 7.3616584168446186],
						[65.366905433809137, 7.3625112119112002],
						[65.367480433925849, 7.363364011567862],
						[65.367480455729222, 7.3633640360184591],
						[65.367480506394116, 7.363364073727122],
						[65.367480535278958, 7.3633640891344063],
						[65.368130534881999, 7.3635890889328435],
						[65.368777735085743, 7.363811288594392],
						[65.369427696798752, 7.3640362758840814],
						[65.369874843378454, 7.3644362281873965],
						[65.370219212223361, 7.365022275288303],
						[65.370491498967695, 7.3661583186575443],
						[65.37043590095611, 7.3674416721008145],
						[65.370097007027653, 7.3688888477287238],
						[65.369655409122615, 7.3702805389750328],
						[65.36921650925315, 7.371669340096302],
						[65.369216506678512, 7.3716693510095981],
						[65.369216502426141, 7.3716693727806843],
						[65.369216500748337, 7.3716693836384586],
						[65.369185900769452, 7.3720332835108477],
						[65.369185901001146, 7.37203330500419],
						[65.369185906868694, 7.3720333498070811],
						[65.369185913378203, 7.3720333709117458],
						[65.369619213086992, 7.3731889691268018],
						[65.370052612938267, 7.3743444696625442],
						[65.370052613881384, 7.3743444739059036],
						[65.370052615721335, 7.3743444780938114],
						[65.370052616641289, 7.3743444801877871],
						[65.37062481696158, 7.3756333811721868],
						[65.371194217435118, 7.3769222802609358],
						[65.371763716796949, 7.3782111810604372],
						[65.372330417209582, 7.3795000793742629],
						[65.372330418129479, 7.3795000814685787],
						[65.372330419072455, 7.3795000857124915],
						[65.372330419992323, 7.3795000878068073],
						[65.372697020040818, 7.3802528862291172],
						[65.373063719585176, 7.3810083859710902],
						[65.373063722321774, 7.3810083901047125],
						[65.373063726921174, 7.3810084005768655],
						[65.373063728760926, 7.3810084047657183],
						[65.373497028938289, 7.3817223036082531],
						[65.373933128578443, 7.3824390048466775],
						[65.373933140375442, 7.3824390170276741],
						[65.373933163095629, 7.3824390435945872],
						[65.373933174892613, 7.3824390557755848],
						[65.37442487540018, 7.382830755360767],
						[65.374424912400627, 7.3828307745964183],
						[65.374424989804666, 7.3828307956488164],
						[65.374425030208286, 7.3828307974655605],
						[65.375147229668272, 7.3827195980097287],
						[65.375147246685117, 7.3827195948114985],
						[65.375147279752952, 7.382719582020699],
						[65.375147295826906, 7.3827195745779672],
						[65.375852896144949, 7.3823334740181252],
						[65.376552896051294, 7.3819501755654429],
						[65.377258395921842, 7.3815640758700978],
						[65.377258403947295, 7.381564071073079],
						[65.377258419078288, 7.3815640593842433],
						[65.377258427103726, 7.3815640545872059],
						[65.378014027331972, 7.3809445550413271],
						[65.378775127131902, 7.3803223547130878],
						[65.378775127131902, 7.3803223547130878],
						[65.379530678049491, 7.3797001953925285],
						[65.379694413859568, 7.3796890998959999],
						[65.379694413859568, 7.3796890998959999],
						[65.3797720988306, 7.3796835076855594],
						[65.380077621003181, 7.380136193065236],
						[65.380602612676171, 7.3815083711760696],
						[65.380602612676171, 7.3815083711760696],
						[65.381130412953581, 7.3828778721162003],
						[65.381652613095653, 7.3842500719162016],
						[65.381652613095653, 7.3842500719162016],
						[65.382177613406753, 7.3856194723094806],
						[65.382177614303558, 7.3856194722543842],
						[65.382177615246363, 7.3856194765001035],
						[65.38217761616616, 7.3856194785954354],
						[65.382680416087879, 7.3868083770984638],
						[65.383180416164407, 7.3879972770964422],
						[65.383180416187358, 7.3879972792469362],
						[65.383688715722585, 7.3891861777899885],
						[65.384188707718153, 7.3903750582466143],
						[65.384397003709168, 7.3914417380052591],
						[65.384397003709168, 7.3914417380052591],
						[65.384608103695115, 7.3925083380618499],
						[65.384608105580298, 7.3925083465545942],
						[65.384608110224661, 7.3925083613345741],
						[65.384608113006678, 7.3925083697724512],
						[65.385113712916763, 7.3938694702865533],
						[65.385113712916763, 7.3938694702865533],
						[65.385627609224755, 7.3952278581731328],
						[65.386058106425523, 7.3969806470003423],
						[65.386491504822985, 7.3987306445327379],
						[65.386788704368982, 7.4001306402012164],
						[65.387085903097358, 7.4015333378265789],
						[65.387266503069227, 7.4026611329662062],
						[65.387447002052525, 7.4037889278049871],
						[65.387683101384908, 7.4057500235236606],
						[65.387919201030073, 7.4077111230935504],
						[65.38791920199489, 7.4077111294919185],
						[65.387919203901902, 7.4077111401377325],
						[65.387919205763609, 7.4077111464817209],
						[65.388083105818737, 7.408386147190912],
						[65.388083108645233, 7.4083861599334195],
						[65.388083117862877, 7.4083861830501823],
						[65.38808312335722, 7.4083861934787691],
						[65.388635922849247, 7.4094417924156657],
						[65.388635922871885, 7.4094417945666491],
						[65.389194222935913, 7.4104944937587209],
						[65.389749822980889, 7.4115500931504172],
						[65.389749822980889, 7.4115500931504172],
						[65.390308122762747, 7.4126056940837355],
						[65.390308123682203, 7.4126056961806386],
						[65.390308127314768, 7.4126057002660559],
						[65.390308128234224, 7.412605702362959],
						[65.390963728416054, 7.4137029023232683],
						[65.390963729312901, 7.413702902269109],
						[65.390963729335482, 7.4137029044202656],
						[65.391627629300643, 7.4148001032441586],
						[65.392288729208516, 7.4158945035187305],
						[65.392947027782327, 7.4169918029238175],
						[65.392947028679146, 7.4169918028697639],
						[65.393608129335462, 7.4180890029867479],
						[65.393608129335462, 7.4180890029867479],
						[65.394272028627839, 7.4191862033932843],
						[65.39493312897973, 7.4202807034751475],
						[65.395591526294155, 7.4213778985435246],
						[65.396291522848557, 7.4226972949507557],
						[65.396991523093774, 7.424013994071891],
						[65.39699152399065, 7.4240139940180665],
						[65.397697024028929, 7.4253306932730228],
						[65.398397022787705, 7.4266500938165416],
						[65.398397031890596, 7.4266501061898618],
						[65.398397051015664, 7.4266501330345607],
						[65.398397061912249, 7.4266501453004041],
						[65.398952662246927, 7.42717794428179],
						[65.39895272177499, 7.4271779730136274],
						[65.398952844845212, 7.4271779850202551],
						[65.398952908387315, 7.4271779682950649],
						[65.399177907648379, 7.4270334670065754],
						[65.399177935226874, 7.4270334438225598],
						[65.399177976796665, 7.4270333853493504],
						[65.399177992604123, 7.4270333521045631],
						[65.399280792660292, 7.4266500525109036],
						[65.399280795261234, 7.4266500437422556],
						[65.399280797772619, 7.4266500263661763],
						[65.399280798579909, 7.4266500177050254],
						[65.399441898709341, 7.4248306178169896],
						[65.399441898709341, 7.4248306178169896],
						[65.399600198991706, 7.4230083180495594],
						[65.39960019982118, 7.4230083115400713],
						[65.399600199708942, 7.423008300780678],
						[65.399600199641597, 7.423008294325034],
						[65.399572400131149, 7.4220416932842603],
						[65.399572400086228, 7.4220416889804977],
						[65.399572399054577, 7.4220416761231478],
						[65.399572398112781, 7.4220416718733064],
						[65.399444598162617, 7.4211805709987857],
						[65.399444597220793, 7.4211805667489941],
						[65.399444596234019, 7.4211805581954629],
						[65.399444595292252, 7.4211805539456677],
						[65.399086294682306, 7.419633254366504],
						[65.398725195141324, 7.4180860550120435],
						[65.398366894582324, 7.4165388556401766],
						[65.398005794932104, 7.4149916556286035],
						[65.397647395399446, 7.4134443557416985],
						[65.39764739537685, 7.4134443535899948],
						[65.39764739356059, 7.4134443515466435],
						[65.397647392641147, 7.4134443494491267],
						[65.397186294330481, 7.4116832486934712],
						[65.396727993755817, 7.409922149096424],
						[65.396727993755817, 7.409922149096424],
						[65.396727992858985, 7.4099221491507281],
						[65.396264092879846, 7.4081610489072789],
						[65.395805794165312, 7.4063999497752073],
						[65.395347393622743, 7.4046388504697278],
						[65.395347392703172, 7.4046388483727039],
						[65.394886294108815, 7.402877749642494],
						[65.394427994119084, 7.4011166489230185],
						[65.394427994119084, 7.4011166489230185],
						[65.394427993222237, 7.4011166489776192],
						[65.39396409387183, 7.3993555537785536],
						[65.393625194818398, 7.3978166565992618],
						[65.393625194818398, 7.3978166565992618],
						[65.393280794590552, 7.3962749568072166],
						[65.392941894842494, 7.3947360560276278],
						[65.392941894842494, 7.3947360560276278],
						[65.392597397234994, 7.3931971663475196],
						[65.392369598671635, 7.3913249761640074],
						[65.392369597774831, 7.3913249762189341],
						[65.39213909892382, 7.3894555833257156],
						[65.392050200144396, 7.3873778039198079],
						[65.392177997917969, 7.385958336008688],
						[65.392472393357892, 7.3848722523768995],
						[65.392472393334899, 7.3848722502256487],
						[65.392761293246821, 7.383786150633151],
						[65.392761294948457, 7.3837861419176631],
						[65.392761299225583, 7.3837861222802248],
						[65.392761300007407, 7.3837861114686705],
						[65.392791900343681, 7.3832722119825043],
						[65.392791899285783, 7.3832721969787611],
						[65.3927918971699, 7.3832721669712562],
						[65.392791894318279, 7.3832721520779243],
						[65.392422393638597, 7.3817305520247194],
						[65.392050193789814, 7.380191653726035],
						[65.39167519405062, 7.3786527536277751],
						[65.391302994365105, 7.3771138527878541],
						[65.390933496581937, 7.3755721649291033],
						[65.390819599297032, 7.3746971818860612],
						[65.39075020003628, 7.3733443889870793],
						[65.390686300187852, 7.3719888918563434],
						[65.390686299198336, 7.371988883307564],
						[65.390686298185614, 7.3719888726076741],
						[65.390686297219247, 7.3719888662099677],
						[65.390405796730406, 7.3703582670062593],
						[65.390405795763968, 7.3703582606086817],
						[65.390405793854313, 7.3703582499646103],
						[65.390405792014278, 7.3703582457737555],
						[65.390000191974622, 7.3689249469749099],
						[65.390000191951401, 7.3689249448238785],
						[65.389589092195877, 7.3674888457135594],
						[65.389183491951542, 7.3660555460432811],
						[65.389183491008211, 7.3660555417971194],
						[65.389183488201439, 7.3660555312096418],
						[65.389183486361262, 7.3660555270192862],
						[65.38882798617685, 7.3651416279681126],
						[65.388827986153586, 7.3651416258171816],
						[65.388466886318852, 7.3642305255510587],
						[65.388466883581813, 7.3642305214167871],
						[65.388466880798205, 7.3642305129806953],
						[65.388466878981234, 7.364230510941483],
						[65.387783479048935, 7.3628693108431671],
						[65.387100179284616, 7.3615110089830056],
						[65.386414079387762, 7.360149910675637],
						[65.386414076650581, 7.3601499065420963],
						[65.386414073913457, 7.3601499024085708],
						[65.386414072969927, 7.3601498981630931],
						[65.385783472691898, 7.3590665994098954],
						[65.385150172820232, 7.35798049925915],
						[65.38480577264572, 7.3573888000636032],
						[65.384516873415052, 7.3568942980538363],
						[65.383883473447909, 7.3558109998373773],
						[65.383252976572308, 7.3547249073893157],
						[65.38265578706212, 7.3534471295859687],
						[65.382177992170639, 7.3518527423032864],
						[65.382177992170639, 7.3518527423032864],
						[65.381697392277744, 7.3502555411892541],
						[65.381697391380953, 7.3502555412455548],
						[65.381216891554956, 7.3486610422936485],
						[65.380739091865635, 7.3470638423259969],
						[65.380739091865635, 7.3470638423259969],
						[65.380739090968802, 7.3470638423824086],
						[65.380258496405986, 7.3454693564068334],
						[65.380116898986003, 7.3444555821655841],
						[65.380050199796059, 7.3429499913268144],
						[65.379986300026957, 7.3414471914115307],
						[65.379986299035707, 7.3414471828674017],
						[65.37998629707684, 7.3414471679292825],
						[65.379986296085576, 7.3414471593851527],
						[65.379739096355067, 7.3402499599929545],
						[65.379739093570151, 7.3402499515622281],
						[65.379739088897082, 7.340249934644099],
						[65.379739086112124, 7.3402499262133549],
						[65.379230785906913, 7.3389777250686894],
						[65.378719586298814, 7.3377027264383283],
						[65.378719586275153, 7.3377027242882527],
						[65.378208486136444, 7.3364305244049044],
						[65.378208485239654, 7.3364305244616697],
						[65.377694585388056, 7.3351582258587165],
						[65.377694585364367, 7.3351582237087278],
						[65.377694582626546, 7.3351582195791751],
						[65.377694582626546, 7.3351582195791751],
						[65.377064082935846, 7.3337443181041895],
						[65.377064082935846, 7.3337443181041895],
						[65.376433482733887, 7.3323332179381255],
						[65.375802983169734, 7.3309193190928035],
						[65.375175188401826, 7.3295055304431322],
						[65.374769598292488, 7.3281388634822715],
						[65.374689100741776, 7.3270417032075583],
						[65.374802995248245, 7.3260694519551697],
						[65.375052976137795, 7.3254750955351389],
						[65.375711265048793, 7.3245140119222985],
						[65.37571127559589, 7.3245139918879394],
						[65.375711293055147, 7.3245139477482768],
						[65.37571129907046, 7.3245139237001204],
						[65.375764099262625, 7.3240778240697342],
						[65.375764099095647, 7.3240778090209666],
						[65.375764097864888, 7.3240777789806533],
						[65.375764095904273, 7.324077764046268],
						[65.375541895663318, 7.3229110627480125],
						[65.375541895663318, 7.3229110627480125],
						[65.375541895639444, 7.322911060598214],
						[65.375311295720635, 7.321747160811487],
						[65.37531129477604, 7.3217471565691898],
						[65.375311292862989, 7.3217471459347934],
						[65.375311291942268, 7.3217471438422761],
						[65.374836291855871, 7.3201638427222679],
						[65.374836291855871, 7.3201638427222679],
						[65.374361292136157, 7.3185832417100656],
						[65.374361291239325, 7.3185832417674312],
						[65.373880791280854, 7.3170027414526038],
						[65.373405795259657, 7.3154221539156321],
						[65.37314739937014, 7.3138638791860346],
						[65.373105799575171, 7.3125778079839323],
						[65.373239098431554, 7.311227831953218],
						[65.37346959503752, 7.3102389446793579],
						[65.373697392004914, 7.3092528571493762],
						[65.373977865348181, 7.3084976006572377],
						[65.374124844080569, 7.3086807242920058],
						[65.374124868558994, 7.3086807463804426],
						[65.374124922800405, 7.3086807816124342],
						[65.374124954356986, 7.3086807946405683],
						[65.37578048214489, 7.3090751766082551],
						[65.377458138018838, 7.3113807171620868],
						[65.377458183291623, 7.3113807529802148],
						[65.37745828513502, 7.3113807916181974],
						[65.377458341729763, 7.3113807965880291],
						[65.378124834615633, 7.3112391407365749],
						[65.378524781320706, 7.3137859959235918],
						[65.377858198689154, 7.3141748264373199],
						[65.377858175564199, 7.3141748451328157],
						[65.377858135663914, 7.3141748885710518],
						[65.377858118888597, 7.3141749133138125],
						[65.377052618823427, 7.3158693137455852],
						[65.377052611063817, 7.3158693422116912],
						[65.377052601846145, 7.3158694008915894],
						[65.377052602181735, 7.3158694309904515],
						[65.3775026020909, 7.3188000305698333],
						[65.377502603011607, 7.3188000326624234],
						[65.377502603059469, 7.3188000369623518],
						[65.377502603980204, 7.3188000390549401],
						[65.37820810362777, 7.3223083393567645],
						[65.378208109199633, 7.3223083562134494],
						[65.378208123057689, 7.3223083919050698],
						[65.378208132216798, 7.3223084085327423],
						[65.378980432022487, 7.3235001089456722],
						[65.378980459236928, 7.3235001351804918],
						[65.378980520768692, 7.323500180742216],
						[65.378980555935115, 7.3235001957117509],
						[65.38226665644477, 7.3242418954182344],
						[65.382266674404775, 7.3242418964247387],
						[65.382266712118422, 7.3242418983233613],
						[65.382266730054596, 7.3242418971795145],
						[65.386627751408412, 7.3235752109672978],
						[65.389449845825837, 7.32556683546622],
						[65.392030423148697, 7.3304361949445092],
						[65.392030454020457, 7.330436227424844],
						[65.392030527303703, 7.3304362808886259],
						[65.392030568770878, 7.330436297626747],
						[65.393122168829549, 7.3306057975758572],
						[65.393122250225389, 7.3306057730272913],
						[65.39312236491574, 7.3306056753213387],
						[65.393122399956411, 7.3306055977474713],
						[65.393100200357708, 7.3285555976411727],
						[65.393100197429334, 7.3285555762999381],
						[65.393100187064789, 7.3285555317515927],
						[65.393100178755574, 7.3285555107527998],
						[65.392153001668603, 7.3266499577606963],
						[65.392322400149965, 7.322625008859232],
						[65.392380798155614, 7.3214250468461604],
						[65.393247383862885, 7.3194056779353449],
						[65.393247390798095, 7.319405655963779],
						[65.393247398319104, 7.3194056059686181],
						[65.393247398952767, 7.3194055822475113],
						[65.393188848693072, 7.3187238577679947],
						[65.393580409077543, 7.3197833597444619],
						[65.394072004906178, 7.321636145066047],
						[65.39443590380705, 7.323419440275817],
						[65.39443590380705, 7.323419440275817],
						[65.394963703854188, 7.3259167416365942],
						[65.394963704774852, 7.3259167437308159],
						[65.394963705719277, 7.3259167479764322],
						[65.394963706639942, 7.3259167500706539],
						[65.395538706647102, 7.328083350601065],
						[65.395538707615316, 7.3280833569982908],
						[65.395538711297746, 7.3280833653756892],
						[65.395538713138961, 7.3280833695643794],
						[65.396208112623057, 7.3298611689573709],
						[65.396208113567383, 7.3298611732033496],
						[65.39620811630536, 7.3298611773352338],
						[65.396208117249714, 7.3298611815811912],
						[65.397172016588129, 7.3320528807566028],
						[65.397172025746173, 7.3320528973996071],
						[65.397172046705222, 7.3320529262115794],
						[65.397172059426779, 7.3320529404751422],
						[65.397963728630899, 7.3328445124800758],
						[65.398608113737865, 7.3344917732086383],
						[65.398608113761583, 7.3344917753603376],
						[65.398608114658373, 7.3344917753034533],
						[65.399127610203521, 7.3357694621716281],
						[65.400135905976654, 7.3396861503440363],
						[65.400135907841189, 7.3396861566861205],
						[65.400135912443446, 7.3396861671617311],
						[65.400135914307967, 7.3396861735037984],
						[65.401169214024662, 7.3423194730518277],
						[65.401169223181554, 7.3423194897009427],
						[65.401169243289047, 7.3423195228859388],
						[65.401169255112819, 7.3423195372132799],
						[65.402155454789721, 7.3433529389949834],
						[65.402155468359851, 7.343352948906122],
						[65.402155494603321, 7.3433529687849672],
						[65.402155509967073, 7.3433529785829199],
						[65.403563809619271, 7.3440668789168573],
						[65.403563840228841, 7.3440668877540283],
						[65.403563902250468, 7.3440668967633211],
						[65.403563932765763, 7.3440668969920289],
						[65.404844328286373, 7.3438502148926919],
						[65.405597054574528, 7.3446473372161014],
						[65.405597062740128, 7.344647345316222],
						[65.405597079944542, 7.3446473593076647],
						[65.405597089903779, 7.3446473672946704],
						[65.406502690264148, 7.3452473670937177],
						[65.406502696588973, 7.3452473710026815],
						[65.406502712849445, 7.3452473807467946],
						[65.406502721864683, 7.3452473844861244],
						[65.407591621683991, 7.3457112849095028],
						[65.407591653166719, 7.345711291540896],
						[65.407591715141166, 7.3457112962504336],
						[65.40759174742648, 7.3457112942155103],
						[65.408461146798146, 7.3455001932747495],
						[65.408461147694965, 7.3455001932182133],
						[65.408461148591755, 7.3455001931616808],
						[65.409472157989626, 7.3452474177865277],
						[65.410299882160942, 7.3458529614428905],
						[65.41029991373783, 7.3458529766858147],
						[65.410299981257978, 7.3458529961256316],
						[65.410300016280843, 7.3458529982263823],
						[65.411488915718166, 7.345758499585858],
						[65.411488962211195, 7.3457584837294565],
						[65.411489040636269, 7.3457584335462496],
						[65.411489074338391, 7.3457583969536104],
						[65.411972373905471, 7.3449028990305925],
						[65.411972378295303, 7.3449028901365239],
						[65.411972384431635, 7.3449028768237143],
						[65.411972387051378, 7.3449028701955816],
						[65.41246958689878, 7.3435778710759996],
						[65.41246959204382, 7.3435778492078372],
						[65.41246959879382, 7.3435778100036506],
						[65.412469600375218, 7.3435777905147983],
						[65.412411299953632, 7.3424360892355818],
						[65.412411298938849, 7.3424360785280136],
						[65.412411295115618, 7.3424360572261991],
						[65.412411293227564, 7.3424360487281248],
						[65.411964095855296, 7.3407749591823848],
						[65.411794598054811, 7.3396582703919675],
						[65.411794578701716, 7.3396582242222124],
						[65.411794521327977, 7.3396581481438545],
						[65.411794482410485, 7.3396581182919762],
						[65.411247281649523, 7.3394109181963838],
						[65.411247259134683, 7.3394109110041619],
						[65.41124721056498, 7.3394109011522257],
						[65.411247187224248, 7.3394109004749959],
						[65.410736209726466, 7.3394441910271393],
						[65.41026130039603, 7.3386305454782281],
						[65.410266899658751, 7.3360027991769643],
						[65.410266899611358, 7.3360027948716509],
						[65.410266898596092, 7.3360027841652151],
						[65.410266899421856, 7.3360027776503589],
						[65.409964098984986, 7.3332082781783328],
						[65.409964092422555, 7.3332082527454405],
						[65.409964073020035, 7.3332082022784135],
						[65.40996406020372, 7.3332081793968831],
						[65.408816887769149, 7.3316860170111031],
						[65.408444597189416, 7.3296471640672314],
						[65.408444594356297, 7.3296471513234405],
						[65.408444585102842, 7.3296471260641685],
						[65.408444579579324, 7.3296471134916059],
						[65.407877979777879, 7.3284610145363827],
						[65.407877979754076, 7.3284610123839311],
						[65.407877977912833, 7.3284610081932593],
						[65.407877976992239, 7.3284610060979229],
						[65.407164081613217, 7.3271027162206783],
						[65.406761286085526, 7.3260943256402902],
						[65.406761267815781, 7.326094296651088],
						[65.406761225990763, 7.3260942476253152],
						[65.406761199745091, 7.3260942277603087],
						[65.40573620913959, 7.3255026313277467],
						[65.404791868212826, 7.324810974143686],
						[65.404266890606593, 7.3233471400535315],
						[65.403861294093957, 7.3217777497015684],
						[65.403861286704526, 7.3217777307911716],
						[65.403861269259082, 7.3217776952944575],
						[65.403861258306293, 7.3217776787655122],
						[65.403086257759298, 7.3207720774019949],
						[65.403086248671713, 7.320772067215378],
						[65.403086228702961, 7.3207720469568907],
						[65.403086217845711, 7.3207720390370534],
						[65.401983418206953, 7.3199692375468599],
						[65.401983376738713, 7.3199692208191509],
						[65.401983293096791, 7.3199692046367986],
						[65.401983249153432, 7.3199692074489162],
						[65.401261118840011, 7.320160886973536],
						[65.400552940177718, 7.3198304447535953],
						[65.399958494311946, 7.3188360334115927],
						[65.399786298710296, 7.3173471761560931],
						[65.399786287565419, 7.317347142417308],
						[65.399786257276276, 7.3173470819122954],
						[65.399786236314441, 7.3173470531092777],
						[65.398839067799628, 7.3164720820697218],
						[65.398311285200236, 7.3151916239074861],
						[65.398311272381207, 7.3151916010448641],
						[65.398311242307059, 7.315191559910649],
						[65.398311224179139, 7.3151915438482513],
						[65.39776402431049, 7.3147581435904288],
						[65.397764002619297, 7.3147581299105164],
						[65.397763958412156, 7.3147581090630744],
						[65.397763934999318, 7.3147581019531032],
						[65.397369537228286, 7.314688722035708],
						[65.396772381315074, 7.3134138142283929],
						[65.396772374917433, 7.313413803873944],
						[65.396772360304524, 7.3134137811287241],
						[65.39677235211326, 7.3134137708894791],
						[65.396016852076812, 7.3125276702955997],
						[65.396016850259201, 7.3125276682593929],
						[65.396016845703116, 7.3125276620931281],
						[65.396016843885491, 7.312527660056924],
						[65.39550297256865, 7.3119970917986343],
						[65.395058490761059, 7.3108082390277564],
						[65.394469593007258, 7.3085971490137203],
						[65.394469573741105, 7.3085971115026167],
						[65.394469523670736, 7.3085970479879334],
						[65.394469491072954, 7.3085970220998675],
						[65.393458391320323, 7.3080776210036413],
						[65.393458376899261, 7.3080776154742741],
						[65.393458348081282, 7.3080776065667683],
						[65.393458332787532, 7.3080776032464012],
						[65.392513932975461, 7.3079192029963131],
						[65.3925139150154, 7.3079192020006722],
						[65.392513878222516, 7.307919202218339],
						[65.392513859389737, 7.307919203431644],
						[65.391511059116596, 7.3081276029430606],
						[65.391511044815971, 7.3081276081695252],
						[65.391511017111512, 7.3081276185646509],
						[65.391511003731765, 7.3081276258844383],
						[65.391122103691075, 7.3083415248435593],
						[65.391122069028938, 7.308341555059922],
						[65.391122017833268, 7.3083416315459786],
						[65.391122002172494, 7.3083416756068544],
						[65.39096650176171, 7.3095943750139494],
						[65.390966501009316, 7.3095943879779597],
						[65.390966501298053, 7.3095944137905526],
						[65.390966502315152, 7.3095944244880897],
						[65.391124801779782, 7.3108444246371906],
						[65.391124802772765, 7.3108444331837781],
						[65.391124806552327, 7.3108444501615901],
						[65.391124809338919, 7.3108444585928325],
						[65.391710903669249, 7.3127417449356349],
						[65.391947002397174, 7.3143083297338052],
						[65.392122716230432, 7.3155178810952526],
						[65.391350130111704, 7.315252633314743],
						[65.389319580565811, 7.3121388117799624],
						[65.387994589444702, 7.3083805348841446],
						[65.38799458210174, 7.3083805202905063],
						[65.387994568336708, 7.3083804931962772],
						[65.38799456012103, 7.3083804808111683],
						[65.386591859726508, 7.3065109810468414],
						[65.38659185880563, 7.3065109789539626],
						[65.386591856067, 7.3065109748260006],
						[65.386591855146122, 7.3065109727331254],
						[65.380689056398722, 7.2993276750394962],
						[65.378752957702645, 7.296822078640405],
						[65.378752916958945, 7.2968220468551586],
						[65.378752824079911, 7.2968220076906176],
						[65.378752772865582, 7.2968220024032187],
						[65.376838975145901, 7.29708308837992],
						[65.375705755135371, 7.2958859735955102],
						[65.375444563217073, 7.2955165852888744],
						[65.375444551388867, 7.2955165709965373],
						[65.375444523248603, 7.2955165427026074],
						[65.375444506960719, 7.2955165308507999],
						[65.374594507126858, 7.2949748308326141],
						[65.374594499903978, 7.2949748269985744],
						[65.37459448274349, 7.2949748173553406],
						[65.374594474648063, 7.2949748157291836],
						[65.373847274582346, 7.2946720147944442],
						[65.373847274582346, 7.2946720147944442],
						[65.373094475372412, 7.29436921328604],
						[65.372344474766933, 7.2940665160673506],
						[65.372344395800368, 7.2940665168895258],
						[65.372344263657354, 7.2940665770906383],
						[65.372344211353422, 7.2940666342618927],
						[65.372283111383567, 7.2942443354182798],
						[65.37228310629412, 7.2942443615614598],
						[65.372283102368527, 7.2942444112909968],
						[65.372283103580926, 7.2942444391763557],
						[65.372488703648401, 7.2952917394340622],
						[65.372694201717678, 7.2963389289209513],
						[65.372777598788232, 7.2972777940107134],
						[65.372622007111659, 7.2982916417894588],
						[65.372202649389337, 7.2992054496528676],
						[65.37187499565168, 7.2993553829847251],
						[65.371325095912155, 7.2991276235050844],
						[65.37067511440182, 7.298680435318512],
						[65.370027925719896, 7.2982331441053638],
						[65.369241854225947, 7.2974887710275418],
						[65.368616865736129, 7.2965609877746633],
						[65.367989066420577, 7.295630488133491],
						[65.367364070702877, 7.2946998957656337],
						[65.366869574909487, 7.2938138022121288],
						[65.366869574012725, 7.2938138022703169],
						[65.366869573988438, 7.2938138001212591],
						[65.366369574151449, 7.2929305011773717],
						[65.365875182954724, 7.292044316284958],
						[65.365500191116539, 7.2909916406367365],
						[65.365066893757444, 7.2893305494248528],
						[65.365066892860682, 7.2893305494831893],
						[65.36463079279892, 7.2876721481370819],
						[65.364630791853401, 7.2876721438977361],
						[65.364630788120024, 7.2876721312380788],
						[65.364630786277658, 7.2876721270571316],
						[65.364286286046493, 7.2868082259176576],
						[65.364286282361817, 7.286808217555965],
						[65.364286273198857, 7.2868082009494461],
						[65.364286268617334, 7.286808192646177],
						[65.363544569150278, 7.2856387926906301],
						[65.362805768783033, 7.2844720921895734],
						[65.362064068767538, 7.2833026934496869],
						[65.361325169171664, 7.2821359937519672],
						[65.360583468580941, 7.280966594080005],
						[65.359844569346777, 7.2797998929909147],
						[65.359102968692355, 7.278630492256065],
						[65.359102957759433, 7.2786304779214115],
						[65.359102932330885, 7.2786304516352942],
						[65.35910291873202, 7.2786304396251271],
						[65.358533418686278, 7.2782109399764989],
						[65.358533391586533, 7.2782109245505673],
						[65.358533334843784, 7.2782109067649516],
						[65.3585333060485, 7.2782109000498529],
						[65.357863906069596, 7.2781915000295578],
						[65.357191706990079, 7.2781692010688079],
						[65.357191706068761, 7.2781691989792545],
						[65.356516744426912, 7.2781498012283956],
						[65.35595011725303, 7.2779054338984119],
						[65.355258451707925, 7.2771748703463492],
						[65.35465015794594, 7.2763859780555],
						[65.35403905772597, 7.2755970779106827],
						[65.353427958387314, 7.2748081773995787],
						[65.352816858160708, 7.2740192787873559],
						[65.35281685542121, 7.2740192746680403],
						[65.352816849045482, 7.2740192664882484],
						[65.352816846305984, 7.2740192623689515],
						[65.352136245975416, 7.2732970627299816],
						[65.352136245078654, 7.2732970627888278],
						[65.351455744752172, 7.2725776640856079],
						[65.350775145192912, 7.2718581625277645],
						[65.350097345755131, 7.2711387631179667],
						[65.350097341197412, 7.2711387569695409],
						[65.350097330337547, 7.2711387490858739],
						[65.350097324883038, 7.271138742996385],
						[65.349564024946218, 7.2707109444050175],
						[65.349564014086326, 7.2707109365216835],
						[65.349563990597616, 7.2707109230205127],
						[65.349563979762365, 7.2707109172848128],
						[65.34893067976958, 7.2704359177521782],
						[65.348930678848205, 7.2704359156635476],
						[65.348294504421304, 7.2701637271121013],
						[65.347852944755246, 7.2698109601740555],
						[65.34711686529289, 7.2688220871569511],
						[65.346441870357594, 7.2677387935684328],
						[65.345764069630732, 7.266655494692694],
						[65.345089070383736, 7.2655692943061938],
						[65.344411277315956, 7.2644860063209231],
						[65.343947383001691, 7.2634416187528039],
						[65.34348628259859, 7.262397119606721],
						[65.343486282573878, 7.2623971174596065],
						[65.343019582711207, 7.2613527169838061],
						[65.343019578128349, 7.2613527086916418],
						[65.343019567144268, 7.2613526900787253],
						[65.343019561664633, 7.2613526818457927],
						[65.342350161851357, 7.2604359813141457],
						[65.342350151862988, 7.260435971230895],
						[65.342350131886221, 7.2604359510643963],
						[65.342350121001076, 7.2604359410404076],
						[65.341597321058757, 7.2598665413093606],
						[65.34085292222467, 7.2592970415084217],
						[65.340852915897642, 7.2592970376296773],
						[65.340852904140405, 7.2592970298129105],
						[65.340852897813377, 7.2592970259341874],
						[65.340125097648297, 7.2588887260324055],
						[65.339402898989832, 7.2584776264965667],
						[65.339402882748658, 7.2584776189772011],
						[65.339402850291023, 7.2584776060852141],
						[65.33940283409936, 7.2584776028594034],
						[65.338766734205876, 7.2583692037229826],
						[65.338766709943172, 7.2583692010311998],
						[65.33876666241369, 7.258369204175211],
						[65.338766639146911, 7.2583692100110042],
						[65.338155538922564, 7.2585665089998788],
						[65.337541747220627, 7.2587608745058114],
						[65.336972357307047, 7.2582637708193385],
						[65.336569581888128, 7.2575666151816476],
						[65.336322394919307, 7.2568860502440327],
						[65.336230800860108, 7.256238891953612],
						[65.336280798404971, 7.2555667344415289],
						[65.336508485038493, 7.2547583794841515],
						[65.336952930284937, 7.2540195700100645],
						[65.337494453096454, 7.2538695930078525],
						[65.337494453993244, 7.2538695929483827],
						[65.337494453993244, 7.2538695929483827],
						[65.338036154245714, 7.2537168909707015],
						[65.338036205039188, 7.2537168596738582],
						[65.33803627670936, 7.2537167711363342],
						[65.338036299404521, 7.2537167159233329],
						[65.338055798985607, 7.2534556143008828],
						[65.338055798761943, 7.2534555949808945],
						[65.338055794752364, 7.2534555587255483],
						[65.33805579096645, 7.2534555417901885],
						[65.337875190961469, 7.2528721399454037],
						[65.337875188196534, 7.2528721336839661],
						[65.337875181720221, 7.252872116927322],
						[65.337875178033642, 7.2528721085787442],
						[65.337364077950042, 7.2518693094294386],
						[65.336855778210378, 7.2508638106173189],
						[65.336855776342176, 7.2508638042967819],
						[65.336855769940286, 7.2508637939809617],
						[65.336855766303401, 7.2508637899261243],
						[65.336341866858916, 7.2500915896364191],
						[65.335830766608296, 7.2493192910365751],
						[65.335830762946443, 7.2493192848356367],
						[65.3358307538292, 7.2493192725529809],
						[65.335830750192272, 7.2493192684984953],
						[65.335058449686485, 7.2484442669798206],
						[65.334286250398634, 7.2475692686471529],
						[65.334286250373708, 7.2475692665008058],
						[65.333511249829684, 7.2466942669679586],
						[65.332739050055622, 7.2458192671073087],
						[65.331966850062997, 7.2449442685820618],
						[65.33119455018425, 7.2440692672974327],
						[65.331194533867418, 7.2440692533509408],
						[65.331194501283647, 7.2440692297501466],
						[65.331194483198203, 7.2440692180693231],
						[65.33058338292453, 7.2437915173498428],
						[65.330583346928535, 7.2437915090114338],
						[65.33058327508644, 7.2437915052108375],
						[65.330583238343607, 7.2437915098084646],
						[65.329863838054052, 7.2440248099350955],
						[65.329863812172405, 7.2440248223987922],
						[65.329863764993007, 7.2440248556112063],
						[65.329863742798452, 7.2440248764196919],
						[65.329255444229361, 7.2447915755886187],
						[65.328638744124703, 7.245555474111538],
						[65.328638744124703, 7.245555474111538],
						[65.328027714136013, 7.2463219869517372],
						[65.327225025909542, 7.2462192013528881],
						[65.326419425923575, 7.2461137015393797],
						[65.32641939989206, 7.2461137011251013],
						[65.3264193497224, 7.2461137087599523],
						[65.326419325559286, 7.2461137146634007],
						[65.325983226144899, 7.2462887131144216],
						[65.325983200312962, 7.2462887298653644],
						[65.325983153182918, 7.2462887673600811],
						[65.325983132806542, 7.2462887901898254],
						[65.325235933125782, 7.2474165902645664],
						[65.32523592958853, 7.2474165947944327],
						[65.325235925204368, 7.2474166036751555],
						[65.325235922563905, 7.2474166081453646],
						[65.324627623260426, 7.2485860073282602],
						[65.324016522685753, 7.2497555078663858],
						[65.323405422912089, 7.2509221063416911],
						[65.323405422936972, 7.250922108487174],
						[65.322797022675061, 7.2520916078131359],
						[65.322185928181483, 7.2532609970374269],
						[65.321599853880571, 7.2541304596679081],
						[65.320852681541751, 7.2546748370869611],
						[65.320852681566606, 7.2546748392322211],
						[65.320108182186218, 7.2552192381089018],
						[65.319360981726589, 7.2557637373742558],
						[65.319360981751402, 7.2557637395193986],
						[65.31861928165705, 7.2563081376712448],
						[65.317869282973007, 7.2568526372181221],
						[65.317869282076245, 7.2568526372774347],
						[65.317122082403884, 7.2573970384219528],
						[65.317122082403884, 7.2573970384219528],
						[65.316380482332619, 7.2579415383245731],
						[65.316380470773538, 7.2579415476747648],
						[65.316380451242523, 7.25794156613805],
						[65.316380442398597, 7.2579415774552984],
						[65.315799841695735, 7.2586942778988899],
						[65.31579984079896, 7.2586942779581358],
						[65.315224840639416, 7.2594442783272095],
						[65.314649857402074, 7.2601942573628868],
						[65.313916576580993, 7.2607720428814249],
						[65.313180476783387, 7.2613470413797039],
						[65.313180476808128, 7.2613470435243483],
						[65.312444277199162, 7.261924842655902],
						[65.31171097716701, 7.2624998426088103],
						[65.310972076711778, 7.2630776428771568],
						[65.310972076711778, 7.2630776428771568],
						[65.310972075815016, 7.263077642936242],
						[65.310238776266445, 7.2636554428349678],
						[65.310238743579291, 7.2636554879085518],
						[65.310238707897739, 7.2636555846842574],
						[65.310238704031292, 7.2636556385898698],
						[65.310302604103924, 7.2639861376912798],
						[65.310302608810133, 7.2639861566957569],
						[65.310302622681775, 7.263986192265059],
						[65.31030263184725, 7.2639862088298797],
						[65.310933131722209, 7.2649612090641806],
						[65.310933136255485, 7.264961213058009],
						[65.310933142656438, 7.2649612233672114],
						[65.310933147214428, 7.2649612295055022],
						[65.311677647157339, 7.2658445297813437],
						[65.31242484661648, 7.2667279293876037],
						[65.312424848410075, 7.266727929269674],
						[65.313174833750935, 7.2676112124727172],
						[65.313458115178022, 7.2681389779166068],
						[65.313649803222518, 7.2687556417922243],
						[65.313735898975693, 7.2695056073371447],
						[65.313688700900229, 7.2705166837868491],
						[65.313497001427521, 7.2722610789173086],
						[65.313497001427521, 7.2722610789173086],
						[65.31331090244332, 7.2740027758012653],
						[65.313033103863376, 7.2758888551222292],
						[65.312763708634975, 7.2767610409354306],
						[65.312494211343406, 7.2776305346734942],
						[65.311949813280876, 7.2790555296138244],
						[65.311402613239281, 7.2804832272473021],
						[65.311402613263738, 7.2804832293918231],
						[65.310858112972909, 7.2819082272650704],
						[65.310308113309731, 7.2833305276574292],
						[65.310308113334131, 7.2833305298018676],
						[65.309763712764479, 7.284755528137052],
						[65.309213712712477, 7.2861832277718204],
						[65.309213712736849, 7.2861832299161708],
						[65.308669212598687, 7.2876082286980726],
						[65.308119220290465, 7.2890332108782978],
						[65.307777642130148, 7.2895776749027954],
						[65.306985956701254, 7.2903831593256152],
						[65.306197057049658, 7.2911887597557827],
						[65.306197051741776, 7.2911887665368109],
						[65.306197041998502, 7.2911887778966618],
						[65.306197036690591, 7.2911887846777121],
						[65.3057470372854, 7.2918248843993902],
						[65.305747031104957, 7.2918248933823628],
						[65.305747021434513, 7.2918249111740066],
						[65.305747017047693, 7.2918249200407921],
						[65.305144216816359, 7.2932027205041958],
						[65.305144215943869, 7.2932027227062672],
						[65.305144213326273, 7.2932027293125037],
						[65.305144211556936, 7.2932027315726202],
						[65.304722012178075, 7.2943666311556417],
						[65.304299812218289, 7.295533230653886],
						[65.3038720158427, 7.2966971199741151],
						[65.303419261899251, 7.2976054293184331],
						[65.302722178671289, 7.2977858998490559],
						[65.301861109070586, 7.2977470002079929],
						[65.301002808898403, 7.2977080998856243],
						[65.300144409911496, 7.2976665008522188],
						[65.300144409014692, 7.2976665009100987],
						[65.299283309400664, 7.2976275990108892],
						[65.298425008883285, 7.2975887009421498],
						[65.297569423042574, 7.2975498014181914],
						[65.296825036708412, 7.2974081048574631],
						[65.296825036684183, 7.2974081027141082],
						[65.296083337260882, 7.2972692024839487],
						[65.295341738096525, 7.2971276029911634],
						[65.295341717445794, 7.2971276021792955],
						[65.295341677065281, 7.2971276026408924],
						[65.295341657335541, 7.2971276039143769],
						[65.294741657196127, 7.2972609058138849],
						[65.29474163305504, 7.2972609138061877],
						[65.2947415884327, 7.2972609359888789],
						[65.294741567054658, 7.2972609502371233],
						[65.294330467402048, 7.2976276516205711],
						[65.294330456737256, 7.297627660887593],
						[65.294330439043264, 7.2976276834764837],
						[65.29433043019624, 7.2976276947709104],
						[65.293966529916858, 7.2982109945790947],
						[65.293966528171666, 7.2982109989810331],
						[65.293966522887615, 7.2982110079006226],
						[65.293966520245633, 7.2982110123604054],
						[65.293310920782574, 7.2995471117013366],
						[65.292647021136034, 7.3008805107707806],
						[65.292647021160207, 7.3008805129138024],
						[65.291988721064101, 7.3022166104761332],
						[65.291327621289113, 7.3035527111691785],
						[65.291327621289113, 7.3035527111691785],
						[65.290669221415683, 7.3048888108221917],
						[65.290005420999449, 7.3062249108454855],
						[65.289347020914008, 7.3075582120123244],
						[65.288685921637693, 7.3088943076852271],
						[65.288005431105645, 7.3101526921944533],
						[65.287513769919258, 7.3108081395371824],
						[65.287222211791146, 7.3109441722628699],
						[65.286966833324897, 7.31078874686908],
						[65.286325153819789, 7.3100137715020495],
						[65.28632515292297, 7.3100137715594746],
						[65.28567795390552, 7.3092415711673695],
						[65.28503625444462, 7.3084692723220055],
						[65.284394554449648, 7.307697072820261],
						[65.284394547178877, 7.3076970647108084],
						[65.284394532661395, 7.3076970506342249],
						[65.284394526311502, 7.3076970446096343],
						[65.283772326432512, 7.307191544705268],
						[65.283772290319263, 7.3071915255826818],
						[65.283772212007719, 7.3071915048785945],
						[65.283772170682099, 7.3071915010972548],
						[65.282905570633389, 7.3073165010432835],
						[65.282036071686633, 7.3074415024652346],
						[65.281172171777783, 7.307563701127032],
						[65.280302772204067, 7.3076887026105259],
						[65.280302771307277, 7.3076887026680115],
						[65.27943887120172, 7.3078137009993638],
						[65.279438842599944, 7.3078137114065633],
						[65.279438789031829, 7.3078137362749906],
						[65.279438764986367, 7.3078137528206941],
						[65.27895816521891, 7.3082554533903812],
						[65.278958153680634, 7.3082554648470239],
						[65.278958134215415, 7.3082554896723675],
						[65.278958125391625, 7.3082555030985352],
						[65.27846092495416, 7.3091638041853972],
						[65.277960924588839, 7.3100693027167623],
						[65.277960921970475, 7.3100693093144811],
						[65.277960916709787, 7.3100693203680915],
						[65.277960914091452, 7.3100693269658263],
						[65.277588714358558, 7.3110027266882662],
						[65.27721921379792, 7.3119332265879509],
						[65.277219212949106, 7.3119332309288465],
						[65.277219210330657, 7.3119332375262136],
						[65.277219208585052, 7.3119332419244438],
						[65.2767915090146, 7.3133499428643685],
						[65.276791506396108, 7.3133499494614682],
						[65.276791503000752, 7.3133499668246289],
						[65.276791502199856, 7.3133499754489248],
						[65.276785902139125, 7.3133943754787767],
						[65.27678590126628, 7.313394377677807],
						[65.276785901338243, 7.3133943841030478],
						[65.276785899592596, 7.3133943885011297],
						[65.276733100128538, 7.3142860876829126],
						[65.276733100224433, 7.3142860962499086],
					],
					[
						[65.368225203255534, 7.3368694126313798],
						[65.368566847709502, 7.3354808343761011],
						[65.369208163260126, 7.3356112768997779],
						[65.370083119273431, 7.3374333839992891],
						[65.37212761810747, 7.3419139823188555],
						[65.372127619051483, 7.3419139865613756],
						[65.372127622709556, 7.3419139927837627],
						[65.372127623629964, 7.3419139948767418],
						[65.374091522191748, 7.3455472906949062],
						[65.375913700929871, 7.3493611455188335],
						[65.375944200267867, 7.3520917011787228],
						[65.375944200291315, 7.3520917033285853],
						[65.375944200314777, 7.3520917054784496],
						[65.375944200338239, 7.3520917076283121],
						[65.376046999942602, 7.3549861060304815],
						[65.376047001923553, 7.3549861231172668],
						[65.376047008552518, 7.354986154972539],
						[65.376047014120758, 7.3549861718348151],
						[65.376305413998267, 7.355652870985371],
						[65.376305453123351, 7.3556529201704519],
						[65.376305551649409, 7.3556529850017016],
						[65.376305611947174, 7.3556530005917677],
						[65.377833250390751, 7.3555585034083109],
						[65.380916596912655, 7.3574140715352954],
						[65.38091662216398, 7.3574140828673746],
						[65.380916674366787, 7.3574140968182942],
						[65.380916702215075, 7.3574140993810975],
						[65.382618950338482, 7.3574005185319757],
						[65.382027798110059, 7.3574054005890632],
						[65.3820277098623, 7.3574054555975534],
						[65.382027629934782, 7.3574056155256899],
						[65.382027639128452, 7.357405718238911],
						[65.383430412425739, 7.3593083825613812],
						[65.384185900611328, 7.3633611250193125],
						[65.3843608983465, 7.3664249799975661],
						[65.383333135370918, 7.3702442435202666],
						[65.381419369977039, 7.3708775998119114],
						[65.379494521792964, 7.3708248026795067],
						[65.37818627792501, 7.3683443097346126],
						[65.376341880194119, 7.3645777130075771],
						[65.3763417611612, 7.3645776601757253],
						[65.376341572396228, 7.3645777149479494],
						[65.37634150089383, 7.3645778248135541],
						[65.376558146291131, 7.3663503805787478],
						[65.376211298570595, 7.3635166765808995],
						[65.37621129574039, 7.3635166638490022],
						[65.376211289159784, 7.3635166362911528],
						[65.376211285432731, 7.3635166236150731],
						[65.374089098168966, 7.3583527554459724],
						[65.374083499776489, 7.3582610883074775],
						[65.374083497819271, 7.3582610733714526],
						[65.374083491214378, 7.3582610436674152],
						[65.374083486566704, 7.3582610288994221],
						[65.372914086820757, 7.3551832298261086],
						[65.372914077641823, 7.3551832110406448],
						[65.372914055720088, 7.3551831758437594],
						[65.372914042977271, 7.3551831594323422],
						[65.371186250843323, 7.3534137679185312],
						[65.369522377805964, 7.3512971014817472],
						[65.368641901378595, 7.3487916679878396],
						[65.368689099783651, 7.3467417090512654],
						[65.368930800330105, 7.3428000119503496],
						[65.368930800235773, 7.3428000033532292],
						[65.368930799150249, 7.3427999862154758],
						[65.368930799055917, 7.3427999776183341],
						[65.368539098877179, 7.3394110761381048],
						[65.368539098877179, 7.3394110761381048],
						[65.368539097956742, 7.3394110740454792],
						[65.368225203255534, 7.3368694126313798],
					],
				],
			],
		},
		{
			fldNpdidField: "43725",
			wlbNpdidWellbore: "1187",
			fldName: "TORDIS",
			fldCurrentActivitySatus: "Producing",
			wlbName: "34/7-12",
			fldDiscoveryYear: "1987",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43725",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43725",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "089",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=1187",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "21516",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Florø",
			fldAreaGeometryWKT: [
				[
					[
						[61.246035907983909, 2.1141221866536175],
						[61.246035930295925, 2.1141222883714295],
						[61.246035955313324, 2.1141223371196447],
						[61.246191554250565, 2.1142862361495305],
						[61.246344253714021, 2.114450135986079],
						[61.246344253808367, 2.1144501378358984],
						[61.246344254699586, 2.1144501376400653],
						[61.246344254699586, 2.1144501376400653],
						[61.246502653656378, 2.11461683641358],
						[61.246655454419887, 2.11478073578047],
						[61.246655453622971, 2.1147807378261443],
						[61.246655455405453, 2.1147807374344834],
						[61.246655455405453, 2.1147807374344834],
						[61.246810953548426, 2.1149445357379095],
						[61.246963753300285, 2.1151112349189742],
						[61.246963753394624, 2.1151112367688336],
						[61.246963754285893, 2.1151112365730076],
						[61.246963755271508, 2.1151112382270525],
						[61.247119254402072, 2.1152751366803746],
						[61.247272054177792, 2.1154390367794722],
						[61.247427654130036, 2.1156057351877533],
						[61.247580453710682, 2.1157695355160375],
						[61.247580453805007, 2.1157695373659395],
						[61.247580455681842, 2.1157695388242166],
						[61.247580455681842, 2.1157695388242166],
						[61.247738753862862, 2.1159334364631897],
						[61.247891552956531, 2.1161001357273417],
						[61.247891552956531, 2.1161001357273417],
						[61.247891553942104, 2.1161001373814741],
						[61.247891554833338, 2.1161001371856729],
						[61.248047054311733, 2.1162640373719279],
						[61.248199854245435, 2.1164279354243147],
						[61.248199854339767, 2.1164279372742514],
						[61.24835545398323, 2.116594536626395],
						[61.248508154299834, 2.11675843531438],
						[61.248508154394159, 2.1167584371643358],
						[61.248508155285386, 2.1167584369685524],
						[61.24850815537971, 2.1167584388185268],
						[61.248663753742704, 2.1169223365577139],
						[61.248816553069304, 2.1170890356952974],
						[61.248816553069304, 2.1170890356952974],
						[61.24881655494611, 2.1170890371537081],
						[61.248816555931668, 2.1170890388079058],
						[61.248974854811124, 2.1172529375697287],
						[61.249127653214728, 2.1174168358169783],
						[61.249283153403951, 2.11758623506178],
						[61.249435952615016, 2.1177529348335549],
						[61.249591553288646, 2.1179223357607007],
						[61.249744252775358, 2.1180890343698771],
						[61.249899851996389, 2.1182584350515268],
						[61.250052651342379, 2.1184279340996639],
						[61.250052652327916, 2.1184279357539841],
						[61.250052654204715, 2.1184279372125294],
						[61.250052655190281, 2.1184279388668257],
						[61.250210953381632, 2.1185945349434343],
						[61.250360950248194, 2.1187640334418023],
						[61.250360951139427, 2.1187640332460558],
						[61.250360954001785, 2.1187640363589559],
						[61.250360954987308, 2.1187640380133046],
						[61.250519253354419, 2.1189307363812575],
						[61.250672050940885, 2.1191001336232942],
						[61.25067205192645, 2.1191001352776415],
						[61.250672052912016, 2.119100136931988],
						[61.250672053803257, 2.1191001367362512],
						[61.250827653322894, 2.1192668342308751],
						[61.250980451427516, 2.1194362345083908],
						[61.250980452318778, 2.1194362343126532],
						[61.250980453304308, 2.1194362359670356],
						[61.250980454195577, 2.1194362357712988],
						[61.25113595319425, 2.119602934172828],
						[61.251288750737167, 2.1197723339808796],
						[61.251288751722718, 2.1197723356352838],
						[61.251288752613966, 2.1197723354395581],
						[61.251288754396448, 2.119772335048109],
						[61.251444252934469, 2.1199390348305247],
						[61.2515970508069, 2.1201084339733773],
						[61.251597051792459, 2.1201084356278042],
						[61.251597053669222, 2.1201084370865204],
						[61.251597054654773, 2.1201084387409463],
						[61.25175545264657, 2.120275135445107],
						[61.251908150788474, 2.1204445334595241],
						[61.251908151679743, 2.1204445332638091],
						[61.251908151774025, 2.1204445351139767],
						[61.251908152665258, 2.120444534918267],
						[61.252063753139517, 2.1206140343145634],
						[61.252216553073637, 2.1207807358883937],
						[61.252372053277149, 2.1209501351309843],
						[61.252524852561613, 2.1211168343582298],
						[61.252680453249695, 2.1212862352935979],
						[61.252833152903996, 2.1214529351984419],
						[61.252833153889533, 2.1214529368529917],
						[61.252833153889533, 2.1214529368529917],
						[61.252991553169537, 2.1216223364194868],
						[61.253144253159945, 2.1217890356452549],
						[61.253299852725711, 2.1219584357124281],
						[61.25345265311239, 2.1221251347281398],
						[61.253608152150825, 2.1222945357213203],
						[61.253760939823493, 2.1224640209327981],
						[61.253902630299216, 2.1226890053115213],
						[61.254038729019349, 2.1229140049248669],
						[61.254038729910626, 2.122914004729203],
						[61.254038730004872, 2.1229140065795074],
						[61.254180430422778, 2.1231418047388204],
						[61.254319230122213, 2.1233668045754692],
						[61.254458128969418, 2.1235945031427663],
						[61.254458129954934, 2.1235945047974498],
						[61.254597029723072, 2.1238195051895596],
						[61.254597029723072, 2.1238195051895596],
						[61.25473873023509, 2.1240473054854632],
						[61.254874828884127, 2.1242723033594344],
						[61.254874829869607, 2.1242723050141712],
						[61.254874829963825, 2.1242723068645311],
						[61.255016530381376, 2.1245001054440782],
						[61.255152629037987, 2.124725103349014],
						[61.255152630023488, 2.1247251050037677],
						[61.255152630117713, 2.1247251068541466],
						[61.255294229583249, 2.124952905271619],
						[61.255433130267406, 2.1251779056961122],
						[61.255572030418037, 2.1254029043965645],
						[61.255710929451055, 2.1256307043984317],
						[61.255710928559772, 2.1256307045940317],
						[61.255710930342296, 2.1256307042028371],
						[61.2557109304365, 2.1256307060532693],
						[61.25585263022279, 2.125858404152702],
						[61.255991530032482, 2.1260834049313302],
						[61.256130428522681, 2.1263112033488407],
						[61.256266529181133, 2.126536204705721],
						[61.256266530166606, 2.1265362063605919],
						[61.256266531057847, 2.1265362061650221],
						[61.256408130426671, 2.126761205938708],
						[61.256549830400346, 2.1269890053840297],
						[61.256685928656218, 2.1272140035879348],
						[61.256824828577834, 2.1274418037937264],
						[61.256824830454534, 2.127441805253123],
						[61.256824829563293, 2.1274418054486732],
						[61.256824831440014, 2.1274418069080498],
						[61.256966529901824, 2.1276668057600414],
						[61.257105429099369, 2.1278945033250674],
						[61.257105430084806, 2.1278945049800315],
						[61.257244229333793, 2.1281195038308875],
						[61.257380428276484, 2.1283473036417284],
						[61.257380429167767, 2.1283473034461902],
						[61.257380430153191, 2.1283473051011845],
						[61.257380431138643, 2.1283473067561895],
						[61.257522029557649, 2.1285723053223435],
						[61.257663730422358, 2.1288001051271039],
						[61.25779982870948, 2.1290251034736096],
						[61.257799829694882, 2.1290251051286502],
						[61.25779982978905, 2.1290251069792183],
						[61.257941529668173, 2.1292529052631513],
						[61.258080429894171, 2.1294779046484429],
						[61.258219229714392, 2.1297029055044248],
						[61.258219234641516, 2.1297029137798225],
						[61.258219247075424, 2.1297029278935202],
						[61.25821925279967, 2.1297029341228364],
						[61.258372052610284, 2.129869535247634],
						[61.258372052610284, 2.129869535247634],
						[61.258372054581123, 2.1298695385578155],
						[61.258372056363655, 2.1298695381668171],
						[61.258530453743141, 2.130033436628235],
						[61.258683153478415, 2.1302001357944809],
						[61.258683152587139, 2.1302001359899747],
						[61.258683154463817, 2.1302001374496169],
						[61.258683155355101, 2.1302001372541244],
						[61.25883875401437, 2.1303640370700241],
						[61.258991554131093, 2.1305279365966547],
						[61.25914705434996, 2.1306945351606266],
						[61.259299854018593, 2.1308584359858984],
						[61.259455454377338, 2.1310251354747853],
						[61.25960815433416, 2.1311890350427989],
						[61.259608154428278, 2.1311890368934683],
						[61.259608156304971, 2.1311890383531891],
						[61.259608156304971, 2.1311890383531891],
						[61.259766554289804, 2.1313529360937129],
						[61.2599192531775, 2.1315195355578851],
						[61.2599192531775, 2.1315195355578851],
						[61.259919254162888, 2.1315195372131095],
						[61.259919255148297, 2.1315195388683299],
						[61.260074854317281, 2.1316834360017389],
						[61.260227653249771, 2.131850134517435],
						[61.260227653343904, 2.1318501363681377],
						[61.260227654235202, 2.1318501361726758],
						[61.260380453884153, 2.1320140365276705],
						[61.260380453884153, 2.1320140365276705],
						[61.260380454775394, 2.1320140363322237],
						[61.260380454869527, 2.1320140381829527],
						[61.260535954245888, 2.1321779375096828],
						[61.260688754152888, 2.1323418352531598],
						[61.260844254318435, 2.1325084360305575],
						[61.260997053871478, 2.1326723369235383],
						[61.260997053871478, 2.1326723369235383],
						[61.260997054762733, 2.1326723367281013],
						[61.260997054856851, 2.1326723385788537],
						[61.261155454140287, 2.132839036616379],
						[61.261305452106996, 2.1330029354728395],
						[61.26130549677044, 2.1330029631075522],
						[61.261305593786609, 2.1330029923625893],
						[61.261305647030582, 2.1330029937874659],
						[61.261477847119018, 2.132961293384934],
						[61.261477848010294, 2.132961293189497],
						[61.26164724811305, 2.1329195942846333],
						[61.261813947837659, 2.1328779948217833],
						[61.261986146971935, 2.1328362930733782],
						[61.261986147957302, 2.1328362947287514],
						[61.261986149739847, 2.1328362943378689],
						[61.26198615053702, 2.1328362922916075],
						[61.26215284944378, 2.1327918941535171],
						[61.262325046846584, 2.1327501936040809],
						[61.26232504773786, 2.1327501934086301],
						[61.262494447829269, 2.132708493794313],
						[61.262663947601268, 2.1326668934536714],
						[61.26283334774498, 2.1326251946721873],
						[61.262833348636256, 2.1326251944767294],
						[61.263000048667685, 2.1325834940530779],
						[61.26300004955894, 2.1325834938576222],
						[61.263172249374087, 2.1325390933194783],
						[61.263338947674391, 2.1324973941138108],
						[61.263508256652884, 2.1324558161953222],
						[61.263672082127883, 2.1325751626106593],
						[61.263672083019138, 2.1325751624151974],
						[61.263672083910436, 2.1325751622197284],
						[61.263835982848128, 2.132691862283719],
						[61.263999881948813, 2.1328112622737398],
						[61.264163782290836, 2.132930760493807],
						[61.264324881063345, 2.1330501612011648],
						[61.264324881954586, 2.133050161005714],
						[61.264324882939995, 2.1330501626612053],
						[61.26432488383125, 2.133050162465755],
						[61.264488783037528, 2.1331668614978763],
						[61.264652681601014, 2.1332862625592939],
						[61.264816582296746, 2.133405761657424],
						[61.264980482278986, 2.1335251610249437],
						[61.26498048326436, 2.1335251626804892],
						[61.265141581882901, 2.1336418628580072],
						[61.265305481597608, 2.1337612627489984],
						[61.265469281694962, 2.1338807624024643],
						[61.265469282586217, 2.1338807622070277],
						[61.26546928446291, 2.1338807636671735],
						[61.265633182963661, 2.1339973624242714],
						[61.265797081868904, 2.1341168614743209],
						[61.265960981937795, 2.1342362622507745],
						[61.265960982829078, 2.1342362620553366],
						[61.266122082343522, 2.1343529614521715],
						[61.266285982144836, 2.1344723627521809],
						[61.266449882295113, 2.1345918624850331],
						[61.266613781638611, 2.1347112606309326],
						[61.266772080563271, 2.1348279610287872],
						[61.266772082345824, 2.1348279606379377],
						[61.266772082439914, 2.1348279624890378],
						[61.266935981609791, 2.1349473629998785],
						[61.267099882019615, 2.1350668617502087],
						[61.26709988291087, 2.1350668615547916],
						[61.267099883896243, 2.1350668632104903],
						[61.267266583654745, 2.1351862622271494],
						[61.267430482969338, 2.1353029615435331],
						[61.267519898357918, 2.1353681407983474],
						[61.265897311768313, 2.134963722471181],
						[61.26527795407803, 2.1342165713506307],
						[61.265277951216014, 2.1342165682348782],
						[61.265277943709371, 2.1342165623942879],
						[61.265277940753258, 2.134216557427528],
						[61.264533440569735, 2.1334748573220921],
						[61.264533419035196, 2.133474841456708],
						[61.264533370806703, 2.133474814600536],
						[61.264533345098137, 2.1334748052652666],
						[61.26354724487193, 2.1332470055782347],
						[61.263547243980675, 2.1332470057736743],
						[61.263547241212727, 2.1332470045091072],
						[61.263547240321451, 2.1332470047045513],
						[61.262436140167381, 2.1330220046758726],
						[61.262436129189751, 2.1330220014686536],
						[61.262436106625543, 2.1330220008021614],
						[61.262436094850749, 2.1330219996412252],
						[61.261699994731316, 2.1330386991430319],
						[61.260341694758615, 2.1330747994055281],
						[61.260341620926766, 2.1330748361789853],
						[61.260341524918353, 2.1330749507977482],
						[61.260341501850519, 2.1330750288384759],
						[61.260599777875584, 2.1348442617190084],
						[61.259738839015874, 2.1351192085985224],
						[61.259738795486875, 2.1351192387241471],
						[61.259738727289374, 2.1351193154257948],
						[61.259738702714955, 2.1351193638525054],
						[61.259497003008541, 2.1363943629858984],
						[61.259497003998568, 2.1363944001948765],
						[61.259497018644758, 2.1363944755796571],
						[61.259497033098086, 2.1363945117094718],
						[61.259994215857716, 2.137141785712271],
						[61.260497000280445, 2.1389056291491921],
						[61.260505398571929, 2.1401720052306099],
						[61.260261094875759, 2.1401776003648711],
						[61.260261026107415, 2.1401776303980657],
						[61.260260927557951, 2.1401777305796839],
						[61.260260899559398, 2.1401778003376495],
						[61.260269200009994, 2.1417000015911714],
						[61.260277600445725, 2.143475001220303],
						[61.260277602276723, 2.1434750195327354],
						[61.260277608612604, 2.1434750555721962],
						[61.2602776140088, 2.1434750731041117],
						[61.260777614111994, 2.144733373135236],
						[61.260777629876003, 2.1447333996263116],
						[61.260777667455159, 2.1447334475412565],
						[61.26077769006767, 2.1447334669192051],
						[61.261519212308215, 2.1452223167240199],
						[61.261780401394013, 2.1475000233516739],
						[61.261780402660989, 2.1475000305600886],
						[61.261780406274049, 2.1475000484835953],
						[61.26178040763493, 2.1475000575428673],
						[61.262152606595571, 2.148758353834396],
						[61.262530400378054, 2.1502722244859234],
						[61.262546999655633, 2.152808300698041],
						[61.26254700129769, 2.1528083153105566],
						[61.262547005379325, 2.1528083424897901],
						[61.262547007912708, 2.1528083569074212],
						[61.263297007583105, 2.1553278567202896],
						[61.263297028364619, 2.1553278933506768],
						[61.263297080060489, 2.1553279531677894],
						[61.263297112053856, 2.155327979861656],
						[61.264286011546794, 2.1558112797410076],
						[61.26428602440042, 2.1558112844178194],
						[61.264286048231327, 2.1558112923099979],
						[61.264286060099856, 2.1558112953305817],
						[61.265397160187845, 2.1560390963371772],
						[61.265397171071257, 2.156039097701635],
						[61.265397193729413, 2.1560391002357062],
						[61.265397205410331, 2.1560390995542504],
						[61.266258304794839, 2.1560169009049339],
						[61.267241704902361, 2.1559946007976412],
						[61.268722070987934, 2.1559613033656966],
						[61.269719209168365, 2.1582222625584548],
						[61.270099804253086, 2.1599889421898104],
						[61.270099807583343, 2.1599889545653701],
						[61.270099815932717, 2.1599889770756362],
						[61.27009982104564, 2.159988989061711],
						[61.270722020740116, 2.1612444885622168],
						[61.270722024774123, 2.1612444970405722],
						[61.270722035422359, 2.161244511561712],
						[61.27072204034765, 2.1612445198453702],
						[61.271466503562095, 2.1622417714309994],
						[61.271599799600217, 2.1642694133437459],
						[61.271599812451235, 2.1642694535927336],
						[61.271599849365757, 2.1642695241545065],
						[61.271599875305661, 2.1642695559295446],
						[61.272219275248091, 2.1647640566407165],
						[61.272219305786336, 2.1647640724367037],
						[61.272219370053413, 2.1647640958440411],
						[61.272219405377534, 2.1647640993630519],
						[61.273327805326957, 2.1647391002954377],
						[61.273941705256895, 2.164725199716385],
						[61.273941752403815, 2.1647251875490259],
						[61.273941833798609, 2.1647251398215843],
						[61.27394186912521, 2.1647251077701646],
						[61.274916869248017, 2.1631779078460132],
						[61.274916873329772, 2.1631778994658202],
						[61.274916880695734, 2.1631778847517591],
						[61.274916883979898, 2.1631778784179398],
						[61.275891884204036, 2.1608694781975886],
						[61.275891887394351, 2.1608694700115856],
						[61.275891891992316, 2.160869454029076],
						[61.275891895182603, 2.1608694458430637],
						[61.276375194134019, 2.1588278483255081],
						[61.276855702766213, 2.1570364870711036],
						[61.277349835386993, 2.1575334175152832],
						[61.277724821156319, 2.1582861897116135],
						[61.277724888707532, 2.158286242348705],
						[61.277725040934087, 2.1582862764751414],
						[61.277725124624361, 2.1582862563074818],
						[61.278705725203984, 2.1575029564102248],
						[61.27870574059115, 2.1575029399391226],
						[61.278705768597703, 2.1575029057296997],
						[61.278705781123314, 2.1575028861394543],
						[61.278950180656828, 2.1569889867033356],
						[61.278950186943156, 2.1569889684770107],
						[61.278950195950486, 2.1569889328039298],
						[61.278950198671531, 2.1569889153571951],
						[61.27906409871526, 2.1554611179843497],
						[61.279297398929387, 2.1531694203252596],
						[61.27929739864782, 2.1531694147695157],
						[61.279297399867296, 2.1531694032680297],
						[61.279297399679592, 2.1531693995642103],
						[61.279295686669734, 2.1529074257910437],
						[61.279297001108354, 2.1529139258199019],
						[61.279430399230897, 2.1549443962184749],
						[61.279197002985406, 2.1574888551663163],
						[61.278713718125168, 2.1587721143253669],
						[61.277980426252221, 2.1600582006460605],
						[61.277980422170643, 2.1600582090275187],
						[61.277980414101265, 2.1600582276422697],
						[61.27798041091102, 2.1600582358289238],
						[61.277374810596349, 2.1618499369183093],
						[61.277374806889611, 2.1618499527066892],
						[61.277374801352472, 2.1618499857458144],
						[61.27737480031972, 2.1618500009499755],
						[61.277380400268378, 2.1628666394748164],
						[61.276527633291288, 2.1641554899982052],
						[61.276527625831513, 2.1641555028610173],
						[61.276527612882056, 2.1641555319008838],
						[61.276527608096302, 2.1641555441796738],
						[61.276160911835042, 2.1654360282329219],
						[61.275310919121303, 2.1672332145674176],
						[61.275310911331857, 2.1672332387339641],
						[61.275310902179633, 2.1672332894085007],
						[61.275310900816855, 2.1672333159164916],
						[61.275444198213663, 2.1690082839726048],
						[61.275202605296322, 2.1700277546483937],
						[61.274963705169718, 2.1710499531951997],
						[61.274963703150931, 2.1710499667406968],
						[61.274963700708852, 2.1710499897392728],
						[61.274963700379132, 2.171050001044101],
						[61.274971999672793, 2.1723194015289229],
						[61.274971999859972, 2.1723194052323822],
						[61.274972000234328, 2.1723194126393155],
						[61.274972001312868, 2.1723194161483419],
						[61.275099800956596, 2.1738389166091858],
						[61.275099804753452, 2.1738389382469694],
						[61.27509981769532, 2.173838980356225],
						[61.275099826840304, 2.1738390008276802],
						[61.275969226710373, 2.1753445009494321],
						[61.2759692515694, 2.1753445292287346],
						[61.275969313392181, 2.1753445756592216],
						[61.275969347588322, 2.1753445925416632],
						[61.276835901053374, 2.1755779528000447],
						[61.276844200121104, 2.1773582316113722],
						[61.276233143165449, 2.1781331765343297],
						[61.276233126647483, 2.1781332063454166],
						[61.276233105386197, 2.1781332671457108],
						[61.276233099845058, 2.1781333001809919],
						[61.276241500345719, 2.1796556009453871],
						[61.2762525998996, 2.1816861002745762],
						[61.276252599993057, 2.1816861021264145],
						[61.276260899666482, 2.1827028026320634],
						[61.276260902383704, 2.1827028207622581],
						[61.276260909694322, 2.1827028584862038],
						[61.276260916070456, 2.1827028776916646],
						[61.277008116431013, 2.184463977877003],
						[61.277008131202933, 2.1844640027439555],
						[61.27700816679949, 2.184464047415378],
						[61.277008189406871, 2.1844640668316435],
						[61.277377688984785, 2.1847112661262353],
						[61.277377693721874, 2.1847112707115266],
						[61.277377705683342, 2.1847112755959559],
						[61.277377712203212, 2.1847112797930262],
						[61.278366612228744, 2.1851973787341543],
						[61.278366704082586, 2.1851973774566482],
						[61.278366847040395, 2.185197299520178],
						[61.278366899035696, 2.1851972226671479],
						[61.278602991714912, 2.1831611830591378],
						[61.279700094083495, 2.1818668980155853],
						[61.280933304022113, 2.1818391004205129],
						[61.282044404116959, 2.1818168993350171],
						[61.282044405008307, 2.1818168991408062],
						[61.282905604764245, 2.1817974006680521],
						[61.284380604419454, 2.1817641001944095],
						[61.285366703830377, 2.1817446006947305],
						[61.285366730664705, 2.1817445967197426],
						[61.285366782974663, 2.1817445797021198],
						[61.285366807652423, 2.1817445687060841],
						[61.286594507749356, 2.1809557679007265],
						[61.286594526798396, 2.1809557525119154],
						[61.286594560159251, 2.1809557171483323],
						[61.286594573673121, 2.1809556992202843],
						[61.287327950213481, 2.179666841090766],
						[61.288922310213721, 2.1786168660554197],
						[61.288922334423546, 2.1786168457926274],
						[61.288922373275042, 2.1786167942426369],
						[61.288922388995097, 2.1786167664662277],
						[61.289647382683356, 2.1765667813150653],
						[61.290622321133782, 2.1747640975836311],
						[61.291113903711995, 2.1747529994323318],
						[61.291977804422466, 2.1747363006003915],
						[61.291977855839924, 2.1747362837613009],
						[61.291977944886, 2.1747362268676955],
						[61.29197798063835, 2.1747361853494196],
						[61.292100181109895, 2.174477886144822],
						[61.292100188102694, 2.1744778640140217],
						[61.292100197631548, 2.1744778207249551],
						[61.292100200167646, 2.17447779956668],
						[61.29209189980859, 2.1729527990022226],
						[61.292091900325566, 2.1729527913966926],
						[61.292091897700516, 2.1729527751110949],
						[61.292091897326131, 2.1729527677001355],
						[61.291836296822602, 2.1714332670864982],
						[61.291836296728995, 2.1714332652337776],
						[61.291836295650434, 2.1714332617229068],
						[61.291836296448125, 2.1714332596756054],
						[61.291202996468265, 2.1683943595471185],
						[61.291202995389582, 2.1683943560364538],
						[61.291202995202276, 2.1683943523311116],
						[61.291202994310957, 2.1683943525257789],
						[61.290572395159025, 2.165866655488236],
						[61.290069597602141, 2.1633360717872714],
						[61.289892238142308, 2.1612892280346729],
						[61.291894418075593, 2.1611084986590545],
						[61.291894424314876, 2.1611084972948693],
						[61.291894436887148, 2.161108496419184],
						[61.291894443126417, 2.161108495054997],
						[61.294172242703141, 2.1606084958760579],
						[61.294172247159757, 2.1606084949014752],
						[61.294172255979191, 2.1606084910994912],
						[61.29417225954451, 2.1606084903198197],
						[61.299450060300231, 2.1589445912849015],
						[61.299450095296358, 2.1589445705128236],
						[61.299450155015201, 2.1589445218480883],
						[61.299450178565188, 2.158944488591084],
						[61.300015094727641, 2.1578084493641687],
						[61.301102815285368, 2.1577251996838265],
						[61.301102817067978, 2.1577251992937225],
						[61.301102820633282, 2.1577251985135009],
						[61.301102823401024, 2.1577251997815576],
						[61.302766722740095, 2.157536298891479],
						[61.302766728087981, 2.157536297721049],
						[61.302766738783781, 2.1575362953801727],
						[61.302766745116799, 2.1575362958679953],
						[61.303775035045277, 2.1573029971364868],
						[61.304897225031688, 2.1571612972621645],
						[61.304897239386513, 2.1571612959940185],
						[61.304897266923412, 2.1571612880924591],
						[61.304897281090618, 2.1571612831174178],
						[61.305433358750449, 2.1569251919594414],
						[61.306200036111164, 2.1567834965369301],
						[61.30620005651754, 2.1567834901956262],
						[61.306200095359991, 2.1567834741962244],
						[61.306200113796052, 2.1567834645381487],
						[61.306536197755904, 2.1565501757089183],
						[61.306961179682304, 2.156364082149735],
						[61.306961193755633, 2.1563640753203788],
						[61.306961220823297, 2.156364058149717],
						[61.306961233911458, 2.1563640496619665],
						[61.307272306339542, 2.1560834734393155],
						[61.307769470839474, 2.1558945869314878],
						[61.307769496312119, 2.1558945738574513],
						[61.307769542237779, 2.1558945375635932],
						[61.307769561893366, 2.1558945163925003],
						[61.30803904638239, 2.155522340690696],
						[61.308394524148653, 2.1552418570822676],
						[61.308394537049061, 2.1552418448863344],
						[61.308394561770776, 2.1552418169823904],
						[61.308394572700827, 2.1552418014695629],
						[61.308666849681146, 2.154775138400753],
						[61.309205714387822, 2.1544001636032948],
						[61.30920572907074, 2.1544001510162767],
						[61.309205757357539, 2.1544001223300508],
						[61.309205769178767, 2.1544001066213099],
						[61.309561242690464, 2.1538390469118252],
						[61.310191802284642, 2.1534640722731453],
						[61.310191812698683, 2.1534640643689542],
						[61.310191834418056, 2.1534640483653482],
						[61.310191843846916, 2.1534640388026927],
						[61.310733421100672, 2.1529029611876056],
						[61.311447291956839, 2.1525307773718803],
						[61.311447310298689, 2.1525307658556856],
						[61.31144734144668, 2.1525307402873888],
						[61.311447356035529, 2.1525307258447093],
						[61.311897338078637, 2.1519695468543758],
						[61.312433414905819, 2.1515945641621683],
						[61.312433432965832, 2.1515945470834286],
						[61.312433466224128, 2.1515945098042146],
						[61.312433480343302, 2.1515944860913261],
						[61.312705766236498, 2.15103341506954],
						[61.313061244897071, 2.1506612387530497],
						[61.313061258406535, 2.1506612207962923],
						[61.313061280781106, 2.150661182151739],
						[61.313061289646228, 2.1506611614639386],
						[61.313241876260719, 2.15010010550525],
						[61.313602944150105, 2.1497251396762187],
						[61.313602954282082, 2.149725126208113],
						[61.313602972763462, 2.1497250996626525],
						[61.313602981112872, 2.1497250865852986],
						[61.313869569270175, 2.149164010774697],
						[61.314230752385015, 2.1487445304117112],
						[61.314230759936947, 2.1487445193832007],
						[61.314230775744257, 2.1487444934228583],
						[61.314230783202248, 2.1487444805403952],
						[61.314500165807388, 2.1481362182664845],
						[61.314947337277843, 2.1477168463484824],
						[61.314947349989531, 2.1477168304387715],
						[61.314947372645079, 2.1477167973517379],
						[61.314947382588905, 2.1477167801743913],
						[61.315216857375987, 2.1471084381019669],
						[61.315913982378341, 2.1466862834971834],
						[61.316519430136943, 2.1465001998765643],
						[61.317394387430085, 2.146497400647291],
						[61.318091655410186, 2.1465890968229098],
						[61.318941628791599, 2.1468695876620756],
						[61.319369320082131, 2.1470557828738226],
						[61.319369340254433, 2.1470557896982418],
						[61.319369380317077, 2.1470557977843243],
						[61.319369401192702, 2.1470558007047478],
						[61.319930601263145, 2.1470530005967858],
						[61.319930615617785, 2.1470529993229333],
						[61.31993064601572, 2.1470529945299202],
						[61.319930661167696, 2.1470529912062704],
						[61.32021946113494, 2.1469612904677198],
						[61.320219465591414, 2.1469612894901493],
						[61.320219476192968, 2.1469612852896813],
						[61.32021948055543, 2.1469612824578195],
						[61.320647280812828, 2.146772383006442],
						[61.320647298356718, 2.1467723735330262],
						[61.320647328800135, 2.1467723518551081],
						[61.320647344373477, 2.1467723390640749],
						[61.320916821263353, 2.146491861958336],
						[61.321275092025857, 2.146305777862707],
						[61.321275110367061, 2.1463057663388896],
						[61.321275143202264, 2.1463057385103625],
						[61.321275156805036, 2.1463057224011775],
						[61.321497333860144, 2.1460223546811061],
						[61.321816801384877, 2.1458362737181784],
						[61.32181680912452, 2.1458362663949848],
						[61.321816825683079, 2.1458362552617958],
						[61.321816834313978, 2.1458362477430302],
						[61.324272277386882, 2.1436169007102612],
						[61.329199999680306, 2.1436057992614073],
						[61.329200013143613, 2.1436057981809991],
						[61.329200039178957, 2.1436057962158768],
						[61.32920005076565, 2.1436057936720174],
						[61.332977832046517, 2.1426057972978501],
						[61.337877812767076, 2.1422891006515887],
						[61.3378778640852, 2.1422890818744817],
						[61.337877949361555, 2.1422890218699115],
						[61.337877983319828, 2.1422889806424359],
						[61.338452962407551, 2.1409834280454394],
						[61.339922321057017, 2.1398612596346154],
						[61.339922357029693, 2.1398612048259964],
						[61.339922388817897, 2.1398610852728872],
						[61.339922383742149, 2.1398610207242768],
						[61.339266904758574, 2.1383305708853775],
						[61.339469598898127, 2.1365306227869367],
						[61.339469583922742, 2.1365305585401226],
						[61.339469517197543, 2.1365304512648415],
						[61.339469464650591, 2.1365304102877531],
						[61.338605665239584, 2.1362359107395545],
						[61.338605646943492, 2.136235905382164],
						[61.338605611619094, 2.1362359018928125],
						[61.338605594402559, 2.1362359000501399],
						[61.336700141604993, 2.1362886957435685],
						[61.336036303623956, 2.1343166782597875],
						[61.336183487758134, 2.1329751195792541],
						[61.337075103679567, 2.1324334714874289],
						[61.337075136228933, 2.1324334380636571],
						[61.337075183828489, 2.1324333638098336],
						[61.337075198784511, 2.1324333211245747],
						[61.33724739901232, 2.1308306203460625],
						[61.337247396613662, 2.1308305908582428],
						[61.337247385389141, 2.1308305295448764],
						[61.337247373983679, 2.1308305001629098],
						[61.334941935174726, 2.126794408486643],
						[61.335644566152794, 2.1257418117580573],
						[61.335644579187168, 2.125741784501499],
						[61.335644596154864, 2.1257417282405169],
						[61.335644600088209, 2.1257416992360922],
						[61.335675199788732, 2.1117028065984425],
						[61.336250197948416, 2.1011694478808955],
						[61.336619441936406, 2.1003254036533434],
						[61.337424836751921, 2.1014557162446481],
						[61.33742492721224, 2.1014557581636066],
						[61.33742509945894, 2.1014557426116873],
						[61.337425181245358, 2.1014556851408122],
						[61.337941881020654, 2.1003611853900437],
						[61.337941888000579, 2.1003611632103669],
						[61.337941897599016, 2.1003611216910913],
						[61.337941900122914, 2.1003611004963201],
						[61.337933499605114, 2.0943917127293408],
						[61.338052998280375, 2.0934389244171672],
						[61.338052999076865, 2.0934389223648409],
						[61.338052998887413, 2.0934389186545355],
						[61.338052998792698, 2.0934389167993896],
						[61.338094598815324, 2.0929250152307559],
						[61.338094599517035, 2.0929250113232785],
						[61.338094600029329, 2.0929250037054845],
						[61.338094599839863, 2.0929249999951978],
						[61.338094599816273, 2.0922694236856407],
						[61.338186293859785, 2.0918972482145648],
						[61.338186295964888, 2.0918972364919974],
						[61.338186299189161, 2.091897211388948],
						[61.338186300403059, 2.0918971998635927],
						[61.338186299797968, 2.0911472166120433],
						[61.338272396046676, 2.09063334276905],
						[61.338452989192767, 2.0899333687257995],
						[61.338722379763915, 2.0893722878640908],
						[61.338722383745903, 2.0893722776017429],
						[61.338722390818759, 2.089372257274333],
						[61.33872239390962, 2.0893722472092722],
						[61.338902983352433, 2.0886222911940164],
						[61.339216852848374, 2.0882473287616694],
						[61.339216861286182, 2.0882473175123355],
						[61.339216873800737, 2.0882472978555309],
						[61.339216881347383, 2.088247286803528],
						[61.339439066350614, 2.0877807155508794],
						[61.339711244064432, 2.0875001397186699],
						[61.33971125578249, 2.0875001221138487],
						[61.339711278327428, 2.087500087101545],
						[61.339711287371962, 2.0875000700888058],
						[61.339886275805334, 2.0870306997816943],
						[61.340111255892211, 2.0867501253255925],
						[61.340111267610183, 2.086750107720257],
						[61.340111287196933, 2.0867500677333979],
						[61.340111294269377, 2.0867500474044931],
						[61.340202989806947, 2.0863750666741905],
						[61.340333480591944, 2.0860944832622677],
						[61.340333486356066, 2.0860944726041528],
						[61.340333494129901, 2.0860944483670627],
						[61.340333497030834, 2.0860944345906698],
						[61.340425197187471, 2.0855806343555781],
						[61.340425197092628, 2.0855806325003279],
						[61.340425197794168, 2.0855806285923761],
						[61.340425197604489, 2.0855806248818705],
						[61.340466897801136, 2.0852528249650248],
						[61.34046689929901, 2.085252819004392],
						[61.340466899621148, 2.0852528076754031],
						[61.340466900132988, 2.0852528000569497],
						[61.340469599741482, 2.084833300656884],
						[61.340469599077586, 2.0848332876700808],
						[61.340469594981428, 2.084833260433578],
						[61.340469593331505, 2.0848332457889653],
						[61.340377993171089, 2.0845055457221049],
						[61.34037799218509, 2.084505544064307],
						[61.340377991104212, 2.0845055405512505],
						[61.340377991009355, 2.0845055386960043],
						[61.340244591466458, 2.0840860393396174],
						[61.340244581321684, 2.0840860171961211],
						[61.340244557751966, 2.0840859792647004],
						[61.340244544232164, 2.0840859616215419],
						[61.340064067336108, 2.0838998837006151],
						[61.339930780845961, 2.0836193143818873],
						[61.339930768122443, 2.0836192946864061],
						[61.339930738219529, 2.0836192562827942],
						[61.339930720243835, 2.0836192396273705],
						[61.339683453323005, 2.0834331646786657],
						[61.339525174264999, 2.0831555009149794],
						[61.339525161731146, 2.0831554849303129],
						[61.339525134881079, 2.0831554533559631],
						[61.339525118877418, 2.0831554400164385],
						[61.339275144616614, 2.0829692597362786],
						[61.339077964301907, 2.0826887853616962],
						[61.33907795087682, 2.0826887695749368],
						[61.339077921542909, 2.0826887423042333],
						[61.33907790741641, 2.0826887304252879],
						[61.338783443845585, 2.0825026558321009],
						[61.338602968179721, 2.0822220923589425],
						[61.338602955645776, 2.0822220763751087],
						[61.338602928094105, 2.0822220487102312],
						[61.338602912981536, 2.0822220351740364],
						[61.338336246143442, 2.0820387580809849],
						[61.338155768248804, 2.0817581921152639],
						[61.338155754728788, 2.0817581744742397],
						[61.338155724313758, 2.0817581436924759],
						[61.338155707418792, 2.0817581305517154],
						[61.337861207428212, 2.0815720306594039],
						[61.337861150752559, 2.0815720150789709],
						[61.337861040000362, 2.0815720171121739],
						[61.337860984236322, 2.0815720369759299],
						[61.337660983974317, 2.0817137375063903],
						[61.337660965923945, 2.0817137546396225],
						[61.337660932781411, 2.0817137938788006],
						[61.337660919376653, 2.0817138137346336],
						[61.337549818587682, 2.0819471134847731],
						[61.337549811610842, 2.0819471356680794],
						[61.337549802909287, 2.0819471769946096],
						[61.337549800388253, 2.0819471981904152],
						[61.337547000648641, 2.0823221191365788],
						[61.337102655251506, 2.0827887615006859],
						[61.337102645922961, 2.0827887729481458],
						[61.337102629048239, 2.0827887954481108],
						[61.337102621596898, 2.082788808355668],
						[61.336766542728689, 2.0834442689255086],
						[61.336335977727622, 2.083774841819376],
						[61.336335961459341, 2.08377485855577],
						[61.336335931691117, 2.0837748932912672],
						[61.336335919272081, 2.0837749148029343],
						[61.335824825963918, 2.0848499019373938],
						[61.335197033786919, 2.0857859889268657],
						[61.335197023149846, 2.0857860100422059],
						[61.335197005724936, 2.0857860570482156],
						[61.335197000624611, 2.0857860806892141],
						[61.335016501650124, 2.0876555709225233],
						[61.334683104015696, 2.0893388613568993],
						[61.334683104110475, 2.0893388632118284],
						[61.334683103314056, 2.0893388652640428],
						[61.334683103408821, 2.0893388671189732],
						[61.334435907674433, 2.0908360347520039],
						[61.334033123860998, 2.0915832049349143],
						[61.334033121376955, 2.0915832092363211],
						[61.334033115707108, 2.0915832217461241],
						[61.334033114208964, 2.0915832277052187],
						[61.33371928228825, 2.092377554078519],
						[61.33326945838985, 2.0922415096585913],
						[61.333269435636488, 2.0922415053136323],
						[61.333269391305116, 2.0922415019911367],
						[61.333269367944759, 2.092241503407942],
						[61.332977768243104, 2.092288703092823],
						[61.332977748636942, 2.0922887074305687],
						[61.3329777132736, 2.0922887208819048],
						[61.332977694748145, 2.0922887287321514],
						[61.332597094947232, 2.0925220292167914],
						[61.332597075113014, 2.0925220467352181],
						[61.332597038402284, 2.09252208674507],
						[61.332597023308224, 2.0925221088421613],
						[61.332377631319133, 2.0929442899918329],
						[61.332083145232424, 2.0933192741678481],
						[61.331927706428722, 2.0935054007094656],
						[61.331591797945642, 2.093505400208802],
						[61.331341880855987, 2.0931805066325539],
						[61.331186296027347, 2.0926666587628673],
						[61.331094599891991, 2.0919666864614364],
						[61.331097399851892, 2.0914528163681871],
						[61.33127239770608, 2.0903333307264598],
						[61.331272397611322, 2.090333328871711],
						[61.331272398218246, 2.0903333231103027],
						[61.331272399109451, 2.0903333229130867],
						[61.331361299385293, 2.0895389218930664],
						[61.33136129910099, 2.0895389163288485],
						[61.331361300409512, 2.0895389066606951],
						[61.331361300030423, 2.0895388992417665],
						[61.331361300055654, 2.0886028123208247],
						[61.331450198816789, 2.0878556240711674],
						[61.331450199423614, 2.087855618309685],
						[61.331450199651236, 2.0878556051292652],
						[61.331450200258047, 2.0878555993677659],
						[61.331447400149742, 2.0869222268530478],
						[61.33153909286753, 2.0865944546305792],
						[61.331539094460275, 2.0865944505264751],
						[61.331539094972221, 2.0865944429102092],
						[61.331539095673762, 2.0865944390034263],
						[61.331716894223121, 2.0857056505123666],
						[61.331964089812701, 2.0849583637769014],
						[61.33196409290322, 2.084958353713652],
						[61.331964097207099, 2.0849583321271452],
						[61.331964099406477, 2.0849583222612611],
						[61.3321223988765, 2.0836028222100134],
						[61.332122398591956, 2.0836028166457323],
						[61.332122399615486, 2.0836028014128569],
						[61.332122400222076, 2.0836027956511698],
						[61.332094599614337, 2.0824360958531645],
						[61.332094600315749, 2.0824360919462106],
						[61.332094600031155, 2.082436086381966],
						[61.332094599045135, 2.0824360847246388],
						[61.331983498661387, 2.0810805849385332],
						[61.33198349818695, 2.0810805756648341],
						[61.331983494659497, 2.0810805595646076],
						[61.331983494185074, 2.0810805502909071],
						[61.331780794246299, 2.0802888492104015],
						[61.331780793355122, 2.0802888494079093],
						[61.331780793260222, 2.0802888475531685],
						[61.331780793165329, 2.0802888456984454],
						[61.331508492873546, 2.0793082467587709],
						[61.331508479769866, 2.0793082196513422],
						[61.331508448310437, 2.0793081684763424],
						[61.331508428267242, 2.0793081466585508],
						[61.33106126136714, 2.0789359732167694],
						[61.330791879892168, 2.0783777133620345],
						[61.330791855714658, 2.0783776812075399],
						[61.330791799112546, 2.0783776318566356],
						[61.330791764715698, 2.0783776113460242],
						[61.329713981859797, 2.0780081162267279],
						[61.328725098048011, 2.0774498255872906],
						[61.328725089742754, 2.0774498218016952],
						[61.328725073132276, 2.0774498142305204],
						[61.328725064827033, 2.0774498104449419],
						[61.32764728642239, 2.0770804183562026],
						[61.326750105249886, 2.0765248297689869],
						[61.326750096849707, 2.0765248241294447],
						[61.32675007934801, 2.076524816756729],
						[61.326750070151597, 2.0765248131691667],
						[61.325764060078868, 2.0761554466179164],
						[61.325580789877591, 2.0755943389086351],
						[61.325580783676067, 2.0755943234050873],
						[61.32558077057174, 2.0755942963042395],
						[61.325580762777776, 2.0755942849045201],
						[61.325314080623521, 2.0752221089927207],
						[61.325133490268158, 2.0746638386536382],
						[61.3251334830804, 2.0746638214937834],
						[61.325133467112522, 2.0746637912779118],
						[61.32513345734624, 2.0746637765652047],
						[61.324839056679124, 2.074291576880698],
						[61.324839051843433, 2.0742915704516243],
						[61.324839041280981, 2.0742915577910446],
						[61.324839034757957, 2.0742915536114874],
						[61.324636235014886, 2.0741054525700067],
						[61.324636197849088, 2.0741054308062892],
						[61.324636118134158, 2.0741054053506889],
						[61.324636074693899, 2.0741054018564209],
						[61.3242555748608, 2.0741526022147445],
						[61.32425554911228, 2.0741526097997984],
						[61.324255497900168, 2.0741526305326734],
						[61.324255474313823, 2.0741526451395313],
						[61.323963781421902, 2.0743887390236839],
						[61.32333600225207, 2.0748109255088933],
						[61.322819350341653, 2.075047003486409],
						[61.322102785639743, 2.0750969998231947],
						[61.322102773163522, 2.0750970025891693],
						[61.322102747319967, 2.0750970083186817],
						[61.32210273395259, 2.0750970112822236],
						[61.321563866120975, 2.0752858995337249],
						[61.320847199009002, 2.0752886995546715],
						[61.320847176635098, 2.0752887026394586],
						[61.320847132968396, 2.0752887123195811],
						[61.320847110879342, 2.0752887209665549],
						[61.320466611379132, 2.0754776206964602],
						[61.320466600875136, 2.075477626775073],
						[61.320466580758314, 2.0754776387347622],
						[61.320466572131572, 2.0754776462723381],
						[61.320016576453668, 2.0758526434769102],
						[61.319769333211944, 2.0760387008016208],
						[61.319344524637749, 2.0760414989215366],
						[61.319097394282515, 2.0755277426348004],
						[61.319008498649104, 2.0748277813999887],
						[61.318980802774099, 2.0743139465680085],
						[61.31916406969615, 2.0739862057832328],
						[61.319519505113071, 2.0734723997814686],
						[61.319969401008919, 2.0734696008540778],
						[61.319969444580508, 2.0734695893177695],
						[61.319969522848517, 2.0734695513375319],
						[61.319969557449909, 2.0734695230396127],
						[61.32026125720575, 2.0730973226852694],
						[61.320261259784182, 2.0730973202384311],
						[61.320261263955011, 2.0730973136883182],
						[61.320261265642344, 2.0730973114390823],
						[61.320639065958922, 2.0725334104882234],
						[61.320639075096658, 2.0725333953360456],
						[61.320639088726416, 2.0725333623117503],
						[61.320639095095679, 2.0725333458984165],
						[61.320822395249685, 2.0717389444469836],
						[61.320822395950799, 2.0717389405412585],
						[61.320822397353091, 2.0717389327297715],
						[61.320822398054247, 2.071738928824014],
						[61.320908498102675, 2.0711333280557174],
						[61.320908498613768, 2.0711333204418589],
						[61.320908499730983, 2.071133307068195],
						[61.320908500242098, 2.071133299454333],
						[61.320908500278684, 2.0703389001629624],
						[61.320908496084868, 2.0703388710913591],
						[61.320908479866979, 2.0703388184354932],
						[61.320908467652863, 2.070338791143135],
						[61.320639069935723, 2.0699192957708554],
						[61.320280772229864, 2.0693137982940883],
						[61.320280757817294, 2.0693137808658442],
						[61.320280728195982, 2.0693137480610786],
						[61.320280710313867, 2.0693137332777072],
						[61.319716810485644, 2.0689415338026045],
						[61.319716801194041, 2.0689415283639931],
						[61.319716781719642, 2.068941517684463],
						[61.319716771631917, 2.0689415142975438],
						[61.319244472173544, 2.0687581132401816],
						[61.319244453875434, 2.0687581079250044],
						[61.319244418455455, 2.0687581026587516],
						[61.31924439936121, 2.0687580993952399],
						[61.318616736811734, 2.0687608992396513],
						[61.31826117287364, 2.0686220143476208],
						[61.318261146365117, 2.0686220071043975],
						[61.31826109293214, 2.0686220020850543],
						[61.318261065021503, 2.0686220026527962],
						[61.317991811797725, 2.0686691763434255],
						[61.317922403134482, 2.0685305584385683],
						[61.317944597903022, 2.0682028408843389],
						[61.318125178929016, 2.0676889923860351],
						[61.318391863165509, 2.0673140150881526],
						[61.318391870615436, 2.067314002185018],
						[61.31839188542024, 2.0673139745248665],
						[61.318391890101651, 2.0673139603611386],
						[61.318572381028012, 2.0667528913871345],
						[61.318841861784151, 2.0663779168597931],
						[61.318841871016261, 2.0663779035606695],
						[61.318841884834626, 2.0663778742435497],
						[61.318841891298213, 2.0663778596838984],
						[61.319016881045521, 2.0658167918837798],
						[61.319289061731148, 2.0654418170539368],
						[61.319289069275968, 2.0654418060039288],
						[61.319289081597091, 2.0654417826434117],
						[61.319289088155706, 2.0654417699372587],
						[61.319444587621682, 2.0650222703115899],
						[61.319444589213767, 2.0650222662080582],
						[61.319444592302858, 2.0650222561470404],
						[61.31944459300383, 2.0650222522413375],
						[61.319600193124408, 2.0644611534024873],
						[61.319758492385702, 2.0639000567532748],
						[61.319914090816404, 2.0633861588096321],
						[61.31991409381029, 2.0633861468943477],
						[61.319914097920581, 2.0633861216056095],
						[61.319914100023297, 2.0633861098882145],
						[61.319936299718883, 2.0628722122281005],
						[61.319980798841939, 2.062311115348118],
						[61.319980799958287, 2.0623111019745775],
						[61.31998079773534, 2.0623110762171066],
						[61.319980796178264, 2.0623110634373303],
						[61.319936296363302, 2.0620777633760876],
						[61.319936294010233, 2.0620777526482117],
						[61.319936287616976, 2.0620777334422509],
						[61.319936285359042, 2.0620777245683262],
						[61.319802984568618, 2.0617527239718121],
						[61.319802972638236, 2.0617527022457005],
						[61.319802943620942, 2.0617526636889143],
						[61.319802927425187, 2.0617526466603282],
						[61.319577950991913, 2.061566564223742],
						[61.31939456657603, 2.0612859911371433],
						[61.319394554931002, 2.0612859749732313],
						[61.31939452826672, 2.0612859471450116],
						[61.319394513247467, 2.0612859354807043],
						[61.319125147140525, 2.0611026572205797],
						[61.318947368861991, 2.0608220922000582],
						[61.318947357216949, 2.0608220760365366],
						[61.318947328770285, 2.0608220486049946],
						[61.318947313655869, 2.0608220350871553],
						[61.318677946262582, 2.06063595650682],
						[61.318494567434172, 2.060358190725049],
						[61.3184945501568, 2.0603581701881089],
						[61.31849450955449, 2.0603581342076267],
						[61.318494488106928, 2.0603581202220718],
						[61.318116788064223, 2.0601720201617173],
						[61.318116787173118, 2.0601720203596714],
						[61.318116783418283, 2.0601720174438412],
						[61.318116781636029, 2.0601720178397622],
						[61.317463982100932, 2.0598804177363386],
						[61.317463972904164, 2.0598804141545668],
						[61.317463953619473, 2.059880407189012],
						[61.317463945408974, 2.0598804052630948],
						[61.317127844747489, 2.0598026059933976],
						[61.317127740450729, 2.0598026460370056],
						[61.317127634829852, 2.0598028007448099],
						[61.317127635097691, 2.0598029113055087],
						[61.317285893032292, 2.0600361502898381],
						[61.317238704295306, 2.0604555538664067],
						[61.317085911914674, 2.0608777316504328],
						[61.317085906723356, 2.0608777534275444],
						[61.317085900701279, 2.0608777941383192],
						[61.317085900951909, 2.06087781658161],
						[61.317127597613037, 2.0613916810389101],
						[61.316997007083295, 2.06185824664133],
						[61.316997005586437, 2.0618582525984626],
						[61.316997002687849, 2.0618582663665217],
						[61.316997002082125, 2.0618582721257681],
						[61.316908106562579, 2.0625110347110822],
						[61.316724824939982, 2.0628388015873829],
						[61.316724825035109, 2.0628388034411445],
						[61.31672482344306, 2.0628388075444359],
						[61.316724821755933, 2.0628388097939454],
						[61.316460934084276, 2.0633554862226609],
						[61.316122052203788, 2.0637304660768243],
						[61.316122037815319, 2.0637304842700472],
						[61.316122016452816, 2.0637305246349142],
						[61.316122007601393, 2.0637305453485366],
						[61.315963716374043, 2.0642916157903746],
						[61.315719232555537, 2.0646637896025752],
						[61.315719224404937, 2.0646638064104121],
						[61.315719209981054, 2.0646638414841916],
						[61.315719203707815, 2.0646638597501301],
						[61.315563707870183, 2.0654138386858474],
						[61.315316517084149, 2.0659749185782723],
						[61.315316513994979, 2.065974928637841],
						[61.315316507531392, 2.0659749431958865],
						[61.315316506224505, 2.0659749528599112],
						[61.315135910051517, 2.0667221358156178],
						[61.31486651717227, 2.0673305188655107],
						[61.314866513191845, 2.0673305291225419],
						[61.314866506312242, 2.0673305531462081],
						[61.314866503222952, 2.0673305632055041],
						[61.314688707054088, 2.0683110405096761],
						[61.31441921680571, 2.068919318230749],
						[61.314419212920178, 2.068919330341084],
						[61.314419205945285, 2.0689193525104086],
						[61.31441920295088, 2.0689193644230688],
						[61.314241508382914, 2.0698999403361662],
						[61.313885916855767, 2.070694317429012],
						[61.31388591545354, 2.0706943252387862],
						[61.31388590978554, 2.0706943377439551],
						[61.313885908383291, 2.0706943455537314],
						[61.3136165179695, 2.0716305084210411],
						[61.313191546347362, 2.0721915735718204],
						[61.312855509941471, 2.0725664991112374],
						[61.312338988111343, 2.0725691991379858],
						[61.312000147863294, 2.0721970650792256],
						[61.312000144108715, 2.0721970621624566],
						[61.3120001373957, 2.0721970542778179],
						[61.312000132749937, 2.0721970515586228],
						[61.31137233272549, 2.071638750724222],
						[61.311372330848201, 2.0716387492658987],
						[61.311372326107445, 2.0716387446933529],
						[61.311372324230142, 2.0716387432350136],
						[61.310900124044821, 2.0712665434676141],
						[61.310900109121384, 2.0712665336547968],
						[61.310900077587213, 2.0712665162778205],
						[61.310900061962649, 2.0712665103695076],
						[61.31034176167141, 2.071083109250357],
						[61.310341747127985, 2.0710831068514759],
						[61.310341716163848, 2.0710831005954846],
						[61.310341699933105, 2.0710831004452177],
						[61.309958300262885, 2.0710831001668177],
						[61.309958269868766, 2.0710831050312715],
						[61.309958212228914, 2.0710831234344229],
						[61.309958184888231, 2.071083135119733],
						[61.309419285105733, 2.0714609353200588],
						[61.309419284309584, 2.0714609373709911],
						[61.309419280744962, 2.0714609381612723],
						[61.30941927994882, 2.0714609402122042],
						[61.309354990379724, 2.0715093396261937],
						[61.309261292840404, 2.0712277455912935],
						[61.308630799843606, 2.0684443768318106],
						[61.308619599686551, 2.0669166987921272],
						[61.308608500022352, 2.0643721991415909],
						[61.308597399677168, 2.0625888993224306],
						[61.308586300320854, 2.060808326010156],
						[61.309069592899867, 2.0590111529256228],
						[61.309069595097441, 2.0590111430653768],
						[61.309069597900603, 2.0590111274472962],
						[61.309069599302198, 2.0590111196382552],
						[61.30930299886321, 2.0567111191490017],
						[61.30930299886321, 2.0567111191490017],
						[61.309422398833284, 2.0554333193326526],
						[61.309422398547632, 2.0554333137727285],
						[61.30942239985378, 2.0554333041101494],
						[61.309422399568142, 2.0554332985502399],
						[61.309403000176758, 2.0521249983434111],
						[61.30939459978395, 2.0505971992996228],
						[61.309380799615816, 2.0483055991024073],
						[61.309369599857959, 2.0457582991464021],
						[61.309369600367688, 2.0457582915350367],
						[61.309369597727354, 2.0457582752522594],
						[61.30936959734597, 2.0457582678391919],
						[61.308600197058794, 2.040947167563866],
						[61.308600194704141, 2.0409471568415167],
						[61.308600187035303, 2.0409471304325173],
						[61.30860018280304, 2.0409471182538113],
						[61.307480783306573, 2.0384360189775452],
						[61.307480782320013, 2.0384360173229181],
						[61.30748078025151, 2.0384360121605303],
						[61.307480779264992, 2.0384360105058787],
						[61.3072307789161, 2.0379360103585693],
						[61.307230752947703, 2.0379359786559554],
						[61.307230688917343, 2.0379359254422327],
						[61.307230651046382, 2.0379359076373018],
						[61.306366751035263, 2.0377081077018548],
						[61.306366690759617, 2.0377081098830998],
						[61.306366583701539, 2.0377081487262401],
						[61.306366536028037, 2.0377081855865971],
						[61.305266593912364, 2.0392720023097684],
						[61.304527793301126, 2.0392969995046148],
						[61.304527745468484, 2.039297015779312],
						[61.304527660559465, 2.039297064675385],
						[61.304527626442692, 2.0392971022603046],
						[61.303066526300071, 2.0418888028090647],
						[61.303066519648276, 2.041888813660298],
						[61.303066510099839, 2.0418888382751006],
						[61.303066506216716, 2.0418888503841472],
						[61.302219209427619, 2.0452249397682416],
						[61.301127627363847, 2.0480581914227565],
						[61.300391596902514, 2.0488470166310919],
						[61.298919361941479, 2.0491470039064867],
						[61.297194379190387, 2.0494554008516728],
						[61.296086092828723, 2.0494914992372042],
						[61.296086072333125, 2.049491503793115],
						[61.296086029559739, 2.0494915133010925],
						[61.296086009254751, 2.0494915215619813],
						[61.294491631781987, 2.050305409689178],
						[61.293136173007923, 2.0506025785139266],
						[61.292269550737906, 2.0496109683178072],
						[61.292269535622587, 2.0496109548161416],
						[61.292269503705008, 2.0496109300611627],
						[61.292269486106889, 2.0496109208581696],
						[61.291775114340915, 2.0493748336187956],
						[61.289919558853128, 2.0476526749666242],
						[61.288672375177867, 2.045402703671626],
						[61.288672368272792, 2.0454026920942798],
						[61.288672352775798, 2.0454026711879267],
						[61.288672345074922, 2.0454026616607806],
						[61.286566845184815, 2.0431831610272155],
						[61.286566832932969, 2.0431831506402358],
						[61.286566805755932, 2.0431831304608155],
						[61.286566791817265, 2.0431831223221075],
						[61.284716803205335, 2.0422276287226007],
						[61.282244513336614, 2.0405276356133624],
						[61.282244512445494, 2.0405276358115887],
						[61.282244509581396, 2.0405276327029793],
						[61.282244508690333, 2.0405276329011945],
						[61.280391809005685, 2.0393192311222466],
						[61.280391786951554, 2.0393192229193526],
						[61.280391740074549, 2.0393192052567528],
						[61.280391714646854, 2.0393192015499024],
						[61.278050015393497, 2.0391387015436799],
						[61.278050009951528, 2.0391387008816593],
						[61.278049999067576, 2.0391386995576024],
						[61.278049993720991, 2.0391387007469763],
						[61.276077793740257, 2.0392026008955599],
						[61.276077784733879, 2.0392026010263953],
						[61.276077767707669, 2.0392026029411019],
						[61.276077759687816, 2.0392026047249976],
						[61.273127764374124, 2.0398081021044456],
						[61.271155568205295, 2.0401248031049],
						[61.271155530174234, 2.040124817178063],
						[61.271155462099486, 2.040124860398163],
						[61.271155432946813, 2.040124889346953],
						[61.270302635758, 2.041422085521778],
						[61.268591538626701, 2.0437637826567183],
						[61.268591531879238, 2.0437637916442948],
						[61.26859152035712, 2.0437638129251261],
						[61.26859151459611, 2.0437638235655338],
						[61.267741520272224, 2.0458221111782979],
						[61.26627762609273, 2.0484082002137982],
						[61.266277618134289, 2.0484082206998644],
						[61.26627760479554, 2.0484082592276298],
						[61.266277601387962, 2.0484082805750812],
						[61.266044203187981, 2.0506971646798879],
						[61.265560906999816, 2.0524888472637892],
						[61.265560904101847, 2.0524888610097674],
						[61.265560900979253, 2.0524888879084449],
						[61.265560899863516, 2.0524889012589083],
						[61.26557199958858, 2.0542666763638091],
						[61.265330404803684, 2.0552888535037757],
						[61.265330402701352, 2.0552888652010273],
						[61.265330401265302, 2.0552888898532147],
						[61.265330400054111, 2.0552889013528133],
						[61.265341500372259, 2.0570666790605254],
						[61.264860904176544, 2.0593638585842737],
						[61.264860902774736, 2.0593638663821476],
						[61.264860900957437, 2.0593638836310597],
						[61.264860900446784, 2.0593638912314001],
						[61.264752599800801, 2.0616527908074471],
						[61.2647525998959, 2.0616527926581405],
						[61.264752600181154, 2.0616527982102473],
						[61.264752600276239, 2.0616528000609469],
						[61.264766500341679, 2.0644444005970017],
						[61.26477480029962, 2.0662194004396675],
						[61.264774802141069, 2.0662194187494967],
						[61.264774808307301, 2.0662194510756597],
						[61.264774811836013, 2.0662194671400695],
						[61.265777611763866, 2.0689806685335057],
						[61.265777628623326, 2.068980698493895],
						[61.265777670873788, 2.0689807490386793],
						[61.265777697250968, 2.0689807712765682],
						[61.26687530601496, 2.0696415935379755],
						[61.266644236661044, 2.0699692847407292],
						[61.266644230299875, 2.0699693891014914],
						[61.26664431497607, 2.0699695463134669],
						[61.26664440601342, 2.0699695991646916],
						[61.267383306034382, 2.0699474002501281],
						[61.267383400415881, 2.0699473250721687],
						[61.2673834477255, 2.0699471405118341],
						[61.267383403232174, 2.0699470286867583],
						[61.26687588109445, 2.0696414718495064],
						[61.267375105706265, 2.0689334973942373],
						[61.26836110600032, 2.0689029992469004],
						[61.269100005926312, 2.0688807992961431],
						[61.269719311531503, 2.0688613025666989],
						[61.270583132932131, 2.0698501127745401],
						[61.2714581113528, 2.0716000700746546],
						[61.271960905587534, 2.0736167482397381],
						[61.271960908830593, 2.0736167587551573],
						[61.271960917099108, 2.0736167793915774],
						[61.271960921233358, 2.0736167897098063],
						[61.272583120802331, 2.0748667889584147],
						[61.273205421109736, 2.0761194885404413],
						[61.273205424162825, 2.0761194953540394],
						[61.273205432051377, 2.0761195085869399],
						[61.273205435995635, 2.0761195152033802],
						[61.274074827846469, 2.0773640029220872],
						[61.275069220978921, 2.0793667888851979],
						[61.275444221145499, 2.0801167894411736],
						[61.27544424710414, 2.0801168211489491],
						[61.275444311118427, 2.0801168744002334],
						[61.275444348984379, 2.0801168922409667],
						[61.276305420896946, 2.0803445593650389],
						[61.27656089996259, 2.0821167144941612],
						[61.276577600313992, 2.08440000005139],
						[61.276577600882739, 2.0844000111602936],
						[61.276577602816587, 2.08440003132969],
						[61.276577604276525, 2.0844000422416578],
						[61.276955403524148, 2.0861667413035763],
						[61.276955407752247, 2.0861667534766357],
						[61.276955416208466, 2.0861667778227178],
						[61.276955421327763, 2.0861667897988712],
						[61.27726028955464, 2.0867781730226733],
						[61.277252619415442, 2.0867943135137037],
						[61.277252614636893, 2.0867943258041461],
						[61.27725260676737, 2.0867943481397311],
						[61.277252603865939, 2.0867943618879452],
						[61.277160909155292, 2.0872610369733242],
						[61.277027619429475, 2.0875416147877419],
						[61.277027614556104, 2.0875416252264505],
						[61.277027606781203, 2.0875416494132124],
						[61.277027603879695, 2.0875416631612609],
						[61.276938705897912, 2.0880082463149634],
						[61.276805411782398, 2.0883805334136545],
						[61.276805408596566, 2.0883805416069916],
						[61.276805404102056, 2.0883805594515548],
						[61.276805402698635, 2.0883805672512552],
						[61.276713705969946, 2.0889416496432562],
						[61.276577611997439, 2.0893138307491563],
						[61.276577608906294, 2.0893138407938512],
						[61.276577603615188, 2.0893138606864539],
						[61.276577602306439, 2.0893138703375596],
						[61.276491504935521, 2.0898749503065899],
						[61.276355412236263, 2.0902471314451749],
						[61.276355409941523, 2.090247139441459],
						[61.276355405352085, 2.0902471554340116],
						[61.276355403057323, 2.0902471634302797],
						[61.276269208320869, 2.0907138363567062],
						[61.276130421047867, 2.090994311778418],
						[61.276130415377736, 2.0909943242645177],
						[61.276130406805713, 2.0909943504979944],
						[61.276130403012644, 2.090994364442091],
						[61.276044207682332, 2.0914610372527482],
						[61.275908119850762, 2.0917416133004973],
						[61.275908115071751, 2.0917416255896542],
						[61.275908107390748, 2.091741651626013],
						[61.275908102611723, 2.0917416639151734],
						[61.275822005672033, 2.0922082455248812],
						[61.275818539662076, 2.0922175373167446],
						[61.275816801790278, 2.0922165278602995],
						[61.275647304419877, 2.0921137296611634],
						[61.275647302542815, 2.0921137282031195],
						[61.275647301651624, 2.092113728399827],
						[61.275477902161441, 2.0920137275241806],
						[61.275477901270236, 2.0920137277208939],
						[61.275477901270236, 2.0920137277208939],
						[61.275477900284365, 2.0920137260661544],
						[61.275305702422116, 2.0919137278794775],
						[61.275139002945963, 2.0918137284683445],
						[61.275139002054807, 2.091813728665048],
						[61.275139001068887, 2.0918137270103241],
						[61.275139000177695, 2.0918137272070383],
						[61.274966802008855, 2.0917137273852999],
						[61.274800103211497, 2.0916137279626215],
						[61.274627903088906, 2.0915109277824837],
						[61.274461243117273, 2.0914109517714294],
						[61.274322368579732, 2.0911970918253497],
						[61.274186270056653, 2.0909804939894094],
						[61.274052969639058, 2.0907665941706091],
						[61.274052968747881, 2.0907665943673375],
						[61.274052968653201, 2.0907665925159593],
						[61.274052967761989, 2.0907665927126975],
						[61.273914069350823, 2.0905498938419349],
						[61.273780769899801, 2.09033599358435],
						[61.273780769008582, 2.0903359937810984],
						[61.273780769008582, 2.0903359937810984],
						[61.273644569795628, 2.0901220932581053],
						[61.27351127029479, 2.0899054948657851],
						[61.27351127029479, 2.0899054948657851],
						[61.273511269308898, 2.0899054932112131],
						[61.273511268322984, 2.0899054915566455],
						[61.273372369156277, 2.0896887925774279],
						[61.273239069950421, 2.0894748936689305],
						[61.273239069059258, 2.0894748938656891],
						[61.273239069059258, 2.0894748938656891],
						[61.273102969165521, 2.0892609934879744],
						[61.273102924816826, 2.0892609546022913],
						[61.27310282426707, 2.0892609094032299],
						[61.273102766283664, 2.0892609034834],
						[61.272924966217666, 2.0892915030187273],
						[61.272749965761115, 2.0893220026182204],
						[61.27257496595012, 2.0893526027659566],
						[61.272399966452809, 2.0893831032113286],
						[61.2722249657239, 2.0894137027460675],
						[61.27204996619988, 2.0894442023827993],
						[61.271872166242623, 2.0894748031651234],
						[61.271872165351454, 2.0894748033618735],
						[61.271872162677866, 2.0894748039521303],
						[61.271872162677866, 2.0894748039521303],
						[61.271697164015272, 2.0895081032833458],
						[61.271522166000217, 2.0895387024402607],
						[61.2713471656254, 2.0895692024884167],
						[61.271172165895962, 2.0895998030803025],
						[61.27099716615308, 2.0896304032670616],
						[61.270819365550388, 2.0896609024820743],
						[61.270644365875405, 2.0896915037065495],
						[61.270469366419732, 2.0897220033836308],
						[61.27029436573212, 2.0897526021439883],
						[61.270294364840929, 2.0897526023407207],
						[61.270294363058532, 2.0897526027341846],
						[61.270294363153248, 2.0897526045853159],
						[61.270119363565229, 2.0897859021657208],
						[61.269944365713016, 2.089816503212889],
						[61.269766666168373, 2.0898470038425248],
						[61.269591666412211, 2.0898776026187411],
						[61.26941666617379, 2.0899081037498877],
						[61.269241665594414, 2.0899387037642687],
						[61.269066666125731, 2.0899692020390979],
						[61.268891665614319, 2.0899998030948992],
						[61.268891664723135, 2.0899998032916067],
						[61.268891663831937, 2.0899998034883231],
						[61.268891662940753, 2.0899998036850302],
						[61.268713866007097, 2.0900331031848038],
						[61.268538869051582, 2.0900609022025542],
						[61.268538868160412, 2.090060902399256],
						[61.268538866377995, 2.09006090279268],
						[61.268363865826366, 2.0900915026299725],
						[61.268363864935189, 2.0900915028266738],
						[61.268363863152814, 2.0900915032200822],
						[61.268363863152814, 2.0900915032200822],
						[61.2681888637862, 2.0901248035627553],
						[61.268013865975206, 2.090155403835761],
						[61.267838865710438, 2.0901859031599828],
						[61.267661065901301, 2.0902165033287106],
						[61.267486065704446, 2.0902470036916885],
						[61.267311066057545, 2.0902776027351369],
						[61.267136065833988, 2.0903081022896473],
						[61.266961066255, 2.0903387023745013],
						[61.266961065363816, 2.0903387025711861],
						[61.266961063581434, 2.0903387029645613],
						[61.266961062690235, 2.0903387031612488],
						[61.266783264005497, 2.0903720020729115],
						[61.26660826627591, 2.0904026027863911],
						[61.266433266092719, 2.0904331025556044],
						[61.266258440715589, 2.0904636719042569],
						[61.266166892232235, 2.0901555423681129],
						[61.266166891341022, 2.0901555425648035],
						[61.266166891246343, 2.0901555407139081],
						[61.266072391000733, 2.089847141543955],
						[61.265980791781807, 2.0895388431578348],
						[61.265980790795929, 2.0895388415036562],
						[61.265980790795929, 2.0895388415036562],
						[61.265980790701214, 2.0895388396527776],
						[61.265883491168374, 2.0892305414522805],
						[61.265791891627515, 2.088922143605878],
						[61.265700191726623, 2.0886138425935736],
						[61.265700190835418, 2.0886138427903056],
						[61.265700190740688, 2.0886138409394484],
						[61.265605790927381, 2.088305542405414],
						[61.265514091689361, 2.0879971435628302],
						[61.26542239224549, 2.0876888437799495],
						[61.265422349717426, 2.087688787657517],
						[61.265422241602778, 2.0876887179384744],
						[61.26542217413904, 2.0876887028845172],
						[61.265249975674777, 2.0877109012009676],
						[61.265083277353597, 2.0877304006688782],
						[61.264911078052386, 2.0877498003474271],
						[61.26491107716118, 2.0877498005441733],
						[61.264911075473542, 2.0877498027884704],
						[61.26491107359643, 2.0877498013311504],
						[61.264744375610633, 2.087772001071222],
						[61.264572176954019, 2.087791501116552],
						[61.264405577206254, 2.08781090099236],
						[61.264405576315035, 2.0878109011891035],
						[61.264405574532667, 2.0878109015825785],
						[61.264405573641461, 2.0878109017793198],
						[61.264236076336758, 2.0878331009693234],
						[61.264063876766578, 2.0878526003775391],
						[61.263897177032725, 2.0878720009169998],
						[61.263724976651616, 2.0878915018268245],
						[61.263724974869227, 2.0878915022202857],
						[61.263724973978064, 2.087891502417011],
						[61.263558275936518, 2.0879137001527028],
						[61.263386077649017, 2.0879331008392339],
						[61.263386076757847, 2.087933101035957],
						[61.26321667654075, 2.0879526006211639],
						[61.263216612525952, 2.0879526353398803],
						[61.263216524105118, 2.0879527390812562],
						[61.26321649969907, 2.0879528081039171],
						[61.26323039975766, 2.0882917078136014],
						[61.263241499580637, 2.0886306062591462],
						[61.263252599547243, 2.0889667067053272],
						[61.263263699563097, 2.0893056071973044],
						[61.263274799857506, 2.0896444066688011],
						[61.263274799952207, 2.0896444085195185],
						[61.263274800046929, 2.0896444103702545],
						[61.263291500437916, 2.0899833085943667],
						[61.263302599637079, 2.0903194078334382],
						[61.263313699748757, 2.0906583069279514],
						[61.263324799906023, 2.09099720612108],
						[61.263335900255782, 2.091333307304263],
						[61.263335900350462, 2.0913333091549928],
						[61.263335900823883, 2.0913333184086569],
						[61.263335901013264, 2.0913333221101125],
						[61.263374801410635, 2.0916861219552385],
						[61.263374800614095, 2.0916861240025941],
						[61.263374800614095, 2.0916861240025941],
						[61.263416501159533, 2.0920361226409403],
						[61.263455401343471, 2.0923889202833785],
						[61.263491500603116, 2.0927389207958345],
						[61.263491500697768, 2.0927389226465816],
						[61.263491500792448, 2.0927389244973233],
						[61.263533101174019, 2.0930889231773135],
						[61.263572001230067, 2.0934417211672574],
						[61.263610900576381, 2.0937917219531004],
						[61.263649800844156, 2.0941444202902164],
						[61.263685900781894, 2.0944944203949651],
						[61.263685900876538, 2.0944944222457282],
						[61.26372760107899, 2.0948472232641193],
						[61.263766501012611, 2.0951972212204448],
						[61.263805401039434, 2.0955472217554463],
						[61.263805401134064, 2.0955472236062191],
						[61.263847001056774, 2.0959000228498335],
						[61.263847002137233, 2.0959000263548955],
						[61.263847003312293, 2.0959000317107255],
						[61.263847002610348, 2.0959000356087816],
						[61.263905403277427, 2.0962306338959285],
						[61.26396090323037, 2.0965611335136685],
						[61.26396090323037, 2.0965611335136685],
						[61.263960903324985, 2.0965611353644555],
						[61.263960903419601, 2.0965611372152408],
						[61.264022002718221, 2.0968889360522871],
						[61.264080402949844, 2.0972194347970872],
						[61.264135902691535, 2.0975500331273493],
						[61.264135902786151, 2.0975500349781475],
						[61.264135902786151, 2.0975500349781475],
						[61.264194202828321, 2.0978806347213586],
						[61.264252603270663, 2.098211134825259],
						[61.264252603365257, 2.0982111366760852],
						[61.264313702731123, 2.0985417355232294],
						[61.264369202928243, 2.0988722332273424],
						[61.264369202928243, 2.0988722332273424],
						[61.264369203022824, 2.0988722350781619],
						[61.264427602569754, 2.0992000341207326],
						[61.264483102641357, 2.0995306331620647],
						[61.264483102641357, 2.0995306331620647],
						[61.264483102830496, 2.0995306368637414],
						[61.264544161242121, 2.0998609067074607],
						[61.264366694116447, 2.0998664992848539],
						[61.264186094062595, 2.0998719997785749],
						[61.264011094967316, 2.0998776003599233],
						[61.263833296786736, 2.0998804000295643],
						[61.263833295895509, 2.0998804002259619],
						[61.263833295004332, 2.0998804004223475],
						[61.263833294113098, 2.0998804006187419],
						[61.263652794016757, 2.0998858992973628],
						[61.263477794023139, 2.0998914997077023],
						[61.263297193956944, 2.0998969995668748],
						[61.263119394741437, 2.0999026007968413],
						[61.262944396752978, 2.0999054004954458],
						[61.262944395861737, 2.0999054006918336],
						[61.262944394970546, 2.099905400888213],
						[61.262944393984768, 2.0999053992338603],
						[61.262763893970629, 2.0999108991289779],
						[61.26258329365394, 2.0999164995996638],
						[61.26240829398089, 2.0999219999921319],
						[61.262230593826665, 2.09992759934832],
						[61.262049993837621, 2.0999331001619912],
						[61.262049937974915, 2.0999331180857399],
						[61.262049846761812, 2.0999331849724254],
						[61.262049812208026, 2.0999332318883202],
						[61.261947012093529, 2.1002193318539106],
						[61.261947012188095, 2.1002193337045938],
						[61.26184421218116, 2.1005082333049385],
						[61.261741511837485, 2.1007943316133266],
						[61.261741511932051, 2.1007943334640142],
						[61.261638711718909, 2.1010832328278357],
						[61.261535912107647, 2.1013693321582347],
						[61.261433111714766, 2.1016555325948589],
						[61.261433111714766, 2.1016555325948589],
						[61.26133041203699, 2.1019443326629843],
						[61.261227612397164, 2.1022332332072589],
						[61.261124812397846, 2.1025193320989399],
						[61.261019212344927, 2.1028082315546412],
						[61.261019212344927, 2.1028082315546412],
						[61.260916512305172, 2.1030943324719384],
						[61.26081371221963, 2.1033805320266956],
						[61.26081371221963, 2.1033805320266956],
						[61.260710911959094, 2.1036693327936304],
						[61.260608111679026, 2.1039555321280314],
						[61.260505412148177, 2.1042416324227524],
						[61.260505412148177, 2.1042416324227524],
						[61.26040261178332, 2.1045305338522771],
						[61.260299811907778, 2.1048193320999755],
						[61.260197012224367, 2.105105532649048],
						[61.26009421224574, 2.1053916320807247],
						[61.26009421224574, 2.1053916320807247],
						[61.259991512419035, 2.1056805332688748],
						[61.25988874612824, 2.1059665370682423],
						[61.259711043002497, 2.1060192075263995],
						[61.259711042111292, 2.1060192077225817],
						[61.259711042205765, 2.1060192095731565],
						[61.259536042155098, 2.1060720085643996],
						[61.259361042168074, 2.1061248087545659],
						[61.259186042150233, 2.1061776082930672],
						[61.259011042195972, 2.1062304090304957],
						[61.259011041304767, 2.1062304092266642],
						[61.259011040413526, 2.1062304094228423],
						[61.259011038631094, 2.1062304098151836],
						[61.258838839626762, 2.106285909351501],
						[61.25866384228334, 2.1063387081878919],
						[61.258488843221095, 2.1063915086155167],
						[61.258311043364934, 2.1064442081428916],
						[61.258311041582502, 2.1064442085352111],
						[61.258311041582502, 2.1064442085352111],
						[61.258136041661729, 2.1064970097010929],
						[61.257961041615673, 2.1065498083648646],
						[61.257961040724453, 2.1065498085610135],
						[61.257788840954305, 2.1066026096323962],
						[61.257788840063078, 2.1066026098285486],
						[61.257613840904362, 2.1066581090673102],
						[61.25743884184471, 2.1067109092718086],
						[61.257263841863029, 2.1067637090208713],
						[61.25708884274168, 2.1068165079222076],
						[61.256911042920571, 2.1068692077321103],
						[61.256911042029365, 2.1068692079282427],
						[61.256911041138125, 2.1068692081243841],
						[61.256911040246912, 2.1068692083205187],
						[61.256741642380796, 2.1069220082040698],
						[61.256563843143041, 2.1069748076087564],
						[61.256563842251794, 2.1069748078048911],
						[61.25656384234626, 2.1069748096552843],
						[61.256388842304844, 2.1070276079974657],
						[61.256213842421502, 2.1070804093888511],
						[61.256038841767968, 2.107133107378],
						[61.256038841862434, 2.1071331092283616],
						[61.25586384191746, 2.107185909317177],
						[61.255688842832818, 2.1072387085583433],
						[61.255511042707063, 2.1072915079110124],
						[61.255511041815865, 2.1072915081071226],
						[61.255511039236623, 2.1072915105457994],
						[61.255511038345404, 2.1072915107419101],
						[61.255341640369487, 2.1073470092295437],
						[61.255163842948797, 2.1073998085201859],
						[61.255163842057563, 2.1073998087162944],
						[61.255163842057563, 2.1073998087162944],
						[61.254988842145956, 2.1074526092330914],
						[61.25481384220349, 2.1075054090983785],
						[61.254813838638583, 2.1075054098827888],
						[61.254813834182436, 2.1075054108633031],
						[61.254813830617536, 2.1075054116477148],
						[61.25464713125907, 2.1075665125409522],
						[61.254647130367829, 2.1075665127370535],
						[61.254647128585368, 2.107566513129254],
						[61.254477728726791, 2.1076304134751696],
						[61.254311029227686, 2.1076942128717895],
						[61.254138830056903, 2.1077581120500821],
						[61.254138830151355, 2.1077581139003354],
						[61.25413882916569, 2.1077581122461679],
						[61.254138828274456, 2.1077581124422609],
						[61.253972129430672, 2.1078220131013419],
						[61.253802731281617, 2.1078859119205791],
						[61.253630533379855, 2.1079470113736858],
						[61.253630531597395, 2.1079470117658463],
						[61.253630529814963, 2.107947012158002],
						[61.253630528032502, 2.1079470125501651],
						[61.253463828268124, 2.1080109130458955],
						[61.253297129353285, 2.1080748126105662],
						[61.253127731227274, 2.1081387121600739],
						[61.25295553335426, 2.1081998123768404],
						[61.252955532463055, 2.108199812572904],
						[61.252955529789354, 2.1081998131611077],
						[61.252955528006893, 2.1081998135532425],
						[61.252788829062759, 2.1082637127584971],
						[61.252619329159309, 2.1083276137325706],
						[61.252619328173644, 2.1083276120784644],
						[61.252452729300238, 2.1083915120967731],
						[61.252280531034344, 2.1084554120728689],
						[61.252113830658296, 2.1085165115802407],
						[61.252113829861514, 2.1085165136264279],
						[61.25211382897028, 2.10851651382248],
						[61.251944328995414, 2.1085804136757043],
						[61.251944318395083, 2.1085804178784202],
						[61.251944294709659, 2.1085804305722391],
						[61.251944283312547, 2.1085804368211463],
						[61.25177768392934, 2.1086998377798585],
						[61.251610985245016, 2.1088165352282169],
						[61.251610985339454, 2.1088165370783258],
						[61.25144158478858, 2.1089359378554695],
						[61.251441583802936, 2.1089359362013993],
						[61.251441582911696, 2.108935936397438],
						[61.251274883925625, 2.1090554375450972],
						[61.251105484904883, 2.1091748352565376],
						[61.250938785216142, 2.1092915350709021],
						[61.250769285278004, 2.1094109354269603],
						[61.250602685593812, 2.1095276367766447],
						[61.250430486234222, 2.1096470350480838],
						[61.250430485343003, 2.1096470352440848],
						[61.250430483654952, 2.1096470374861429],
						[61.250430482763711, 2.1096470376821492],
						[61.250263784200811, 2.1097665362452225],
						[61.250094284934946, 2.1098859366099596],
						[61.249927684948538, 2.1100026364197491],
						[61.249758184636192, 2.1101220361711488],
						[61.249591585391272, 2.1102387350281004],
						[61.249591583703221, 2.1102387372700622],
						[61.249424884345274, 2.1103581376085025],
						[61.249255485344769, 2.1104776363488122],
						[61.249088785313809, 2.1105942355184668],
						[61.249088785408219, 2.1105942373684328],
						[61.248919285395594, 2.1107137368976612],
						[61.248919283613134, 2.1107137372895868],
						[61.248919282721893, 2.1107137374855527],
						[61.24875268400254, 2.1108331364883464],
						[61.248585985326145, 2.1109498361271792],
						[61.248585985326145, 2.1109498361271792],
						[61.248416584638981, 2.1110692367863839],
						[61.248416583747762, 2.1110692369823294],
						[61.248416582856521, 2.1110692371782824],
						[61.248416582059704, 2.1110692392241583],
						[61.248260984063577, 2.1111831364576941],
						[61.248099886248603, 2.1112942346728465],
						[61.248099885451765, 2.1112942367187002],
						[61.248099881981219, 2.1112942393523908],
						[61.248099880198744, 2.1112942397442729],
						[61.247947081399722, 2.1114081383628571],
						[61.24779158340678, 2.1115220380784701],
						[61.247635983856682, 2.1116331361267968],
						[61.247635983059816, 2.1116331381726146],
						[61.247477683027114, 2.1117470379198946],
						[61.247477682135866, 2.1117470381158223],
						[61.247477682135866, 2.1117470381158223],
						[61.247322083040643, 2.1118609377592534],
						[61.247166584298185, 2.1119720362868417],
						[61.247166582610106, 2.1119720385285286],
						[61.247008183257016, 2.1120859377780445],
						[61.247008182365761, 2.1120859379739549],
						[61.247008182460149, 2.1120859398238085],
						[61.246852683179306, 2.1121998382518963],
						[61.246697084233404, 2.1123109365512889],
						[61.246697062801907, 2.1123109580973392],
						[61.246697027257603, 2.1123110033218753],
						[61.246697013333559, 2.1123110307000679],
						[61.246599812873221, 2.1125693306639359],
						[61.246599811981966, 2.1125693308598317],
						[61.246599812076354, 2.1125693327096591],
						[61.246599812076354, 2.1125693327096591],
						[61.246508111933224, 2.1128277314608446],
						[61.246413711645019, 2.1130860318028892],
						[61.246413711645019, 2.1130860318028892],
						[61.246319212219689, 2.1133471319266159],
						[61.246319212314063, 2.1133471337764322],
						[61.246227612195746, 2.1136027316364756],
						[61.246130412641214, 2.1138638307222561],
						[61.246130411750002, 2.1138638309181035],
						[61.246130411750002, 2.1138638309181035],
						[61.246035912377479, 2.1141221314425445],
						[61.246035907983909, 2.1141221866536175],
					],
					[
						[61.268064727833192, 2.1356675601199995],
						[61.268105523855859, 2.1356834845238795],
						[61.268274920941387, 2.1357557840439321],
						[61.268274921832671, 2.1357557838485182],
						[61.26827492370932, 2.1357557853088873],
						[61.268274924600576, 2.135755785113481],
						[61.268452723987245, 2.1358279858446276],
						[61.268627723190413, 2.1359001854202577],
						[61.268799923101099, 2.1359723836286193],
						[61.268799924086437, 2.1359723852844392],
						[61.268799924977728, 2.1359723850890258],
						[61.26879992596308, 2.1359723867448439],
						[61.268974924734152, 2.1360418849145977],
						[61.26914992286995, 2.1361140850485989],
						[61.269322122604308, 2.1361862836038643],
						[61.269322123589646, 2.1361862852597167],
						[61.269322125466289, 2.1361862867201582],
						[61.269322126357565, 2.1361862865247478],
						[61.269497124956132, 2.1362557850122355],
						[61.269672122915807, 2.1363279855092543],
						[61.26984432336517, 2.1364001842160443],
						[61.26984432336517, 2.1364001842160443],
						[61.269844324256454, 2.1364001840206384],
						[61.27001932394321, 2.1364723856113401],
						[61.270194322522784, 2.1365445844398585],
						[61.270366623385002, 2.136616883131675],
						[61.270366624370354, 2.1366168847876068],
						[61.270366625355699, 2.1366168864435222],
						[61.270366626247004, 2.1366168862481154],
						[61.270541624767148, 2.1366862842426233],
						[61.270716623264725, 2.136758485285708],
						[61.270831373690321, 2.1368066639747747],
						[61.269352876991086, 2.1366526078371688],
						[61.268064727833192, 2.1356675601199995],
					],
					[
						[61.271420922570208, 2.1370513156669917],
						[61.271586024714964, 2.1371168859507863],
						[61.271763822734194, 2.1371890841900298],
						[61.271933222412592, 2.137261284158408],
						[61.271933224289221, 2.1372612856190427],
						[61.271933225180511, 2.1372612854236435],
						[61.272111024926943, 2.1373334847676175],
						[61.272111025912274, 2.1373334864236471],
						[61.2722860244168, 2.1374029852194951],
						[61.272458212910557, 2.1374751806363976],
						[61.272633201636857, 2.1375723748230708],
						[61.27280550217445, 2.1376695733851876],
						[61.272805503159809, 2.1376695750412544],
						[61.272980502391405, 2.1377668737834035],
						[61.273152701866692, 2.1378640740422656],
						[61.273152702852052, 2.1378640756983751],
						[61.273327702148265, 2.1379612734820674],
						[61.273497100370015, 2.138058472038209],
						[61.273497101355325, 2.1380584736943535],
						[61.27349710224663, 2.1380584734989601],
						[61.273669301991248, 2.1381557740467136],
						[61.273669302976558, 2.1381557757028666],
						[61.273844302013856, 2.1382529746913979],
						[61.274016601884114, 2.1383501747940663],
						[61.274016602775383, 2.1383501745986853],
						[61.274191601702931, 2.1384473756324174],
						[61.274363802105398, 2.1385445733355581],
						[61.27453600218378, 2.1386418732070234],
						[61.274536003169096, 2.1386418748632217],
						[61.274711001743775, 2.1387390752579996],
						[61.274883201887121, 2.1388362741507003],
						[61.274883202872466, 2.1388362758069106],
						[61.275058202040583, 2.1389334743481125],
						[61.275230501677576, 2.1390307732597025],
						[61.275230502662914, 2.1390307749159403],
						[61.27540550172121, 2.1391279755030048],
						[61.275577702347704, 2.1392251745572968],
						[61.275749902027904, 2.1393223748179753],
						[61.275749902919209, 2.1393223746225991],
						[61.27592490162472, 2.1394195745701485],
						[61.276097092339519, 2.1395168686713331],
						[61.276260982242874, 2.1396362620956269],
						[61.276260983134144, 2.1396362619002622],
						[61.276260983228191, 2.1396362637519388],
						[61.276427681941023, 2.1397557616484626],
						[61.276591579730528, 2.1398779604404279],
						[61.276591581607128, 2.1398779619014019],
						[61.276591584375041, 2.1398779631670131],
						[61.27659158526631, 2.1398779629716516],
						[61.276760983185596, 2.1399973621876947],
						[61.276924882364177, 2.1401168613231829],
						[61.277088781724991, 2.140236260493928],
						[61.277255481705019, 2.1403584602982137],
						[61.277255481799038, 2.1403584621499747],
						[61.27741928233943, 2.14047796213227],
						[61.277419283230699, 2.1404779619369236],
						[61.277419284216037, 2.1404779635933351],
						[61.2774192851073, 2.1404779633979869],
						[61.277588783257542, 2.1405973635403757],
						[61.277752681618907, 2.140716861315644],
						[61.277752777787683, 2.1407168739407436],
						[61.277752935026342, 2.1407168113923145],
						[61.277752996987502, 2.1407167360234363],
						[61.277814097347161, 2.1403833369185157],
						[61.277877995770595, 2.1400500383000693],
						[61.277877995676569, 2.1400500364482933],
						[61.277877996567859, 2.140050036252922],
						[61.277939096757535, 2.1397167353872319],
						[61.278000197321688, 2.139380635637842],
						[61.27806129726536, 2.1390472364505544],
						[61.278122396990014, 2.1387139369825703],
						[61.278186296031976, 2.1383806383387158],
						[61.278186295937921, 2.1383806364869278],
						[61.278186296829205, 2.1383806362915112],
						[61.278247397110896, 2.1380472364290442],
						[61.278308497174351, 2.137713936286413],
						[61.278308498016258, 2.1377139173775945],
						[61.278308498011576, 2.1377138818026205],
						[61.278308497070931, 2.1377138632846728],
						[61.278247397100849, 2.1373805661365428],
						[61.27819189662717, 2.1370471675267919],
						[61.278191896533087, 2.1370471656750101],
						[61.278191897424357, 2.1370471654795602],
						[61.278191897330274, 2.1370471636277752],
						[61.278130797350109, 2.1367138650168229],
						[61.27807239666808, 2.1363832653953518],
						[61.27807239657399, 2.1363832635435824],
						[61.278019798654512, 2.1360963469353922],
						[61.278135937637245, 2.1362168197982094],
						[61.278319225690083, 2.1365417972008944],
						[61.278319234558282, 2.1365418121077897],
						[61.278319256563002, 2.1365418372406189],
						[61.278319268105086, 2.1365418515610757],
						[61.278585938205431, 2.1367751242993842],
						[61.27872201979801, 2.1370528876711608],
						[61.278722027774904, 2.1370529027738434],
						[61.27872204612035, 2.1370529268373626],
						[61.278722055785721, 2.1370529396972979],
						[61.278902632753699, 2.1372390146787423],
						[61.279033118952832, 2.1375194832909408],
						[61.279033126929711, 2.137519498393881],
						[61.279033146354479, 2.1375195259661299],
						[61.279033156019814, 2.1375195388263291],
						[61.279213724261034, 2.1377057061896743],
						[61.279305409854764, 2.137986161983993],
						[61.279305419005041, 2.1379861824473028],
						[61.279305441667944, 2.1379862205448852],
						[61.27930545518057, 2.1379862381791557],
						[61.279483134245865, 2.1381723166520481],
						[61.279619220117858, 2.1384500887082889],
						[61.27961922800057, 2.1384501019598812],
						[61.279619246345739, 2.138450126024884],
						[61.279619256011038, 2.1384501388856143],
						[61.279799834252522, 2.1386362151403779],
						[61.279933118678343, 2.1389167856293869],
						[61.279933128437648, 2.1389168003422792],
						[61.279933145985559, 2.1389168264551062],
						[61.279933155650767, 2.1389168393160993],
						[61.280113723557186, 2.1391029053349073],
						[61.280205410326005, 2.1393833611994628],
						[61.280205418678833, 2.1393833837110847],
						[61.280205443123947, 2.139383421419943],
						[61.280205455745126, 2.1393834392507145],
						[61.280385933191781, 2.1395695142391307],
						[61.280516519144022, 2.1398472845460295],
						[61.280516527120639, 2.139847299650278],
						[61.280516545559649, 2.1398473255687103],
						[61.280516556116076, 2.1398473382348255],
						[61.280697033787568, 2.1400334156196825],
						[61.280833120276917, 2.1403139862714307],
						[61.280833128253462, 2.140314001375951],
						[61.280833145707092, 2.1403140256383266],
						[61.280833155372186, 2.1403140385001271],
						[61.281010929318604, 2.1405001118393439],
						[61.281124814919174, 2.1407806755334291],
						[61.281124824960322, 2.1407806958033695],
						[61.28112485109363, 2.140780731271688],
						[61.281124866388495, 2.1407807485174128],
						[61.281327641200228, 2.1409640255620221],
						[61.281480424169551, 2.1412444961867725],
						[61.281480433928586, 2.141244510901092],
						[61.281480454149943, 2.1412445364303969],
						[61.281480465597539, 2.1412445489020167],
						[61.281685941714535, 2.1414307255143838],
						[61.281844226357357, 2.1417111977456336],
						[61.281844232269187, 2.1417112076856224],
						[61.281844246860679, 2.141711228831586],
						[61.28184425356978, 2.1417112367242082],
						[61.282019223896683, 2.1418973049024257],
						[61.282110910272827, 2.1421750620912818],
						[61.282110920313805, 2.1421750823622956],
						[61.282110942975621, 2.1421751204662782],
						[61.282110955596472, 2.1421751382992307],
						[61.282291533787557, 2.1423612155102529],
						[61.282424819066257, 2.1426417866271485],
						[61.282424827839883, 2.1426417996856268],
						[61.282424846372443, 2.1426418274592924],
						[61.282424855943326, 2.1426418384703774],
						[61.282605434261903, 2.1428279162595043],
						[61.282741519688287, 2.1431083876868735],
						[61.282741531417699, 2.1431084057158789],
						[61.282741558347766, 2.1431084391405246],
						[61.282741572657066, 2.1431084547314803],
						[61.282966538561581, 2.1432945253506732],
						[61.283099819545022, 2.1435722866905182],
						[61.283099828318555, 2.1435722997495352],
						[61.283099845959612, 2.1435723277196512],
						[61.283099856421742, 2.1435723385359338],
						[61.283280434017598, 2.1437584160702361],
						[61.283416520391413, 2.1440389868075478],
						[61.283416528273605, 2.1440390000621088],
						[61.283416544835355, 2.1440390245238223],
						[61.283416553608852, 2.1440390375830818],
						[61.283591524387063, 2.1442251045312397],
						[61.283683110308736, 2.1445056620385823],
						[61.283683118566792, 2.1445056827019457],
						[61.283683143010599, 2.1445057204188993],
						[61.283683155631152, 2.144505738253669],
						[61.283863734369731, 2.1446918165014868],
						[61.283997018675372, 2.1449722852461095],
						[61.283997028434051, 2.1449722999627618],
						[61.283997045980854, 2.1449723260822968],
						[61.283997055645578, 2.1449723389467761],
						[61.284177633620516, 2.1451584179675689],
						[61.284313719567137, 2.1454361874927015],
						[61.284313728434434, 2.1454362024049001],
						[61.284313745887232, 2.1454362266727762],
						[61.28431375644319, 2.1454362393422612],
						[61.284494223040987, 2.1456223059140873],
						[61.284580409034305, 2.1459028593894081],
						[61.284580418183474, 2.1459028798584523],
						[61.284580441923538, 2.1459029214772158],
						[61.284580456326545, 2.1459029389225304],
						[61.284760935292596, 2.1460890162354973],
						[61.284899820947572, 2.1463694886074189],
						[61.284899827844328, 2.1463695002062098],
						[61.284899845390875, 2.1463695263272697],
						[61.284899854070211, 2.1463695375355765],
						[61.285074833694246, 2.1465557151733821],
						[61.285210920347957, 2.1468333874198846],
						[61.28521092832387, 2.1468334025281388],
						[61.285210945776427, 2.1468334267974543],
						[61.285210956332278, 2.1468334394677759],
						[61.285391524077653, 2.1470195041616633],
						[61.285480408675809, 2.1473000603144019],
						[61.285480418716084, 2.1473000805892144],
						[61.285480442361816, 2.1473001203579463],
						[61.285480455967253, 2.1473001398518754],
						[61.285660934237143, 2.1474862151374579],
						[61.28579701966742, 2.147766786262205],
						[61.2857970275493, 2.1477667995186907],
						[61.285797045095634, 2.1477668256412583],
						[61.285797053774843, 2.1477668368502858],
						[61.285972033161279, 2.1479529147235059],
						[61.286108119658643, 2.1482333871984265],
						[61.286108127540452, 2.1482334004551635],
						[61.286108146963237, 2.1482334280401343],
						[61.28610815662767, 2.1482334409064832],
						[61.286288723897293, 2.1484168065865124],
						[61.286377608958865, 2.1486972598169216],
						[61.286377618998905, 2.1486972800927635],
						[61.28637764184689, 2.1486973219111882],
						[61.286377656249535, 2.1486973393587641],
						[61.286558134011038, 2.1488834163060084],
						[61.286694219658557, 2.1491639877218485],
						[61.286694228431607, 2.1491640007838972],
						[61.286694245977699, 2.1491640269079624],
						[61.286694255642018, 2.1491640397748375],
						[61.286919236918393, 2.1493973197541454],
						[61.287097025123479, 2.1497222966180973],
						[61.287097033990371, 2.1497223115328268],
						[61.287097056978084, 2.1497223383389032],
						[61.287097069316282, 2.1497223506205851],
						[61.28736654189192, 2.1499557282306885],
						[61.287544225289224, 2.1502806961730685],
						[61.28754423218566, 2.1502807077737045],
						[61.28754424677593, 2.1502807289274011],
						[61.287544256252389, 2.1502807380901663],
						[61.287769235059542, 2.1505140173272577],
						[61.287908120665257, 2.1507944891757962],
						[61.287908132299869, 2.1507945053581761],
						[61.287908157351673, 2.1507945373326454],
						[61.28790817076888, 2.1507945531247423],
						[61.28812763808083, 2.1509807241056675],
						[61.288263720276468, 2.1512611872956615],
						[61.288263728158, 2.1512612005540603],
						[61.28826374570373, 2.1512612266805911],
						[61.288263756259191, 2.1512612393536044],
						[61.288444234106031, 2.1514473167394041],
						[61.288577620223926, 2.1517250876606746],
						[61.288577631061017, 2.1517251058913129],
						[61.288577657097811, 2.1517251395245176],
						[61.28857767318884, 2.1517251547319702],
						[61.288802639186812, 2.1519112258472819],
						[61.288938719556953, 2.1521917870988636],
						[61.288938734146946, 2.1521918082543881],
						[61.288938767689636, 2.1521918477373596],
						[61.288938786548378, 2.1521918642123641],
						[61.288975438189517, 2.1522169223796723],
						[61.28456670911514, 2.1520108999434178],
						[61.284566703673441, 2.152010899261676],
						[61.284566693775183, 2.1520108995553637],
						[61.284566689318645, 2.1520109005307768],
						[61.283809139095631, 2.1520527359722648],
						[61.283469589889428, 2.1510360373257011],
						[61.283469583696586, 2.1510360218269553],
						[61.283469568730915, 2.1510359932669187],
						[61.283469559958071, 2.1510359802056369],
						[61.2823557784494, 2.1495388043962778],
						[61.281983490131807, 2.1485305379050716],
						[61.281477991826108, 2.1467638446356907],
						[61.281477972824661, 2.1467638076002529],
						[61.281477921030778, 2.1467637459127493],
						[61.28147788814438, 2.1467637194086726],
						[61.280491787897311, 2.1462804208914865],
						[61.28049169079172, 2.1462804253067462],
						[61.280491546734183, 2.1462805167792895],
						[61.280491499688281, 2.1462806019846017],
						[61.28049700039525, 2.1480582610250858],
						[61.279769217441249, 2.1498555207112577],
						[61.279408118665188, 2.1506249150226324],
						[61.279408117070368, 2.1506249191166034],
						[61.279408112989401, 2.1506249274996438],
						[61.279408112379748, 2.1506249332504459],
						[61.279283061678697, 2.1509745667703646],
						[61.279280799677352, 2.1506278139286406],
						[61.279519595970513, 2.1488444446708748],
						[61.280002990626848, 2.1473083608968886],
						[61.280002994331845, 2.1473083451055524],
						[61.280002999067975, 2.1473083141083982],
						[61.280003000099086, 2.1473082989025789],
						[61.280000199960931, 2.1465472174653852],
						[61.280239096850913, 2.1452722376194053],
						[61.280239098069714, 2.1452722261173056],
						[61.280239099897933, 2.145272208864144],
						[61.280239100319399, 2.1452721994092228],
						[61.280230800391408, 2.1437471992261288],
						[61.280230797293036, 2.1437471736896501],
						[61.280230783074686, 2.1437471243742423],
						[61.280230772845954, 2.143747100400037],
						[61.279361272795995, 2.1422443002252551],
						[61.279361258204602, 2.1422442790806397],
						[61.279361222173506, 2.1422442439095919],
						[61.279361202422365, 2.1422442276406408],
						[61.278125113756538, 2.1415109345548102],
						[61.276266824199602, 2.1400331435357742],
						[61.276266809280798, 2.1400331336996752],
						[61.276266776863395, 2.1400331164651996],
						[61.276266761147319, 2.1400331086761391],
						[61.274788993274754, 2.1395637208422906],
						[61.273800119997347, 2.1388248402080419],
						[61.27380009034772, 2.1388248242414587],
						[61.273800028047596, 2.1388248041989586],
						[61.273799995397141, 2.1388248001230421],
						[61.27231952163099, 2.1388636966489831],
						[61.271420922570208, 2.1370513156669917],
					],
				],
			],
		},
		{
			fldNpdidField: "43492",
			wlbNpdidWellbore: "251",
			fldName: "GYDA",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "2/1-3",
			fldDiscoveryYear: "1980",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43492",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43492",
			fldOwnerKind: "",
			fldOwnerName: "",
			cmpLongName: "",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=251",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "",
			fldNpdidOwner: "",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Tananger",
			fldAreaGeometryWKT: [
				[
					[56.833235901338576, 3.102591709301187],
					[56.833235903313856, 3.1025917319811649],
					[56.833991502769301, 3.107230632345531],
					[56.833991512201273, 3.1072306604199884],
					[56.833991538059472, 3.1072307121819889],
					[56.833991554562921, 3.107230737499624],
					[56.836363755157763, 3.1097223373321445],
					[56.836363770226924, 3.1097223513816932],
					[56.836363801843781, 3.1097223726783025],
					[56.836363819439391, 3.1097223830450114],
					[56.839377718597341, 3.1110501830370056],
					[56.839377758609814, 3.1110501915160307],
					[56.839377836969575, 3.1110501923112688],
					[56.839377877104049, 3.1110501843459408],
					[56.842716776693493, 3.1096640857222426],
					[56.842716781900684, 3.1096640816162795],
					[56.842716793362975, 3.1096640765245098],
					[56.842716797753752, 3.109664074189872],
					[56.845511197743043, 3.1080862732593406],
					[56.84551119871383, 3.1080862747490743],
					[56.847233399034884, 3.107100174719899],
					[56.847233410342604, 3.1071001663652735],
					[56.8472334312481, 3.1071001515685452],
					[56.84723344069144, 3.1071001418650614],
					[56.848416673137407, 3.105925307763675],
					[56.85046373721471, 3.108814016517802],
					[56.850463742962233, 3.1088140238266253],
					[56.850463755273644, 3.1088140366725563],
					[56.85046376102116, 3.1088140439813827],
					[56.853049861208689, 3.1113140432304092],
					[56.853049892825446, 3.1113140645369497],
					[56.853049964377504, 3.1113140926939624],
					[56.853050001632049, 3.1113140999669802],
					[56.854769401912051, 3.1112973997352138],
					[56.857352802196516, 3.1112723998312557],
					[56.857352846721639, 3.1112723895255732],
					[56.857352926649178, 3.1112723473460422],
					[56.857352962128779, 3.1112723171033605],
					[56.859180762064696, 3.1087334172847263],
					[56.859180764591031, 3.1087334135994915],
					[56.859180769566514, 3.1087334045977997],
					[56.859180772986434, 3.1087334007716292],
					[56.860900173314221, 3.1058029004732886],
					[56.860900179767647, 3.1058028846636412],
					[56.860900192597256, 3.1058028514130833],
					[56.860900197186261, 3.1058028342541975],
					[56.86186129594396, 3.1001583413214124],
					[56.863041888651416, 3.095483367399249],
					[56.864222339615104, 3.0929445740375519],
					[56.867988933902204, 3.0921251967872356],
					[56.869605626191401, 3.0919112980658743],
					[56.869605677320756, 3.0919112751803763],
					[56.869605759575805, 3.0919112062728455],
					[56.869605790701527, 3.0919111602507967],
					[56.871322384699056, 3.0864444787761647],
					[56.873897358191442, 3.0817445265090289],
					[56.875941831283754, 3.0799640514914852],
					[56.875941845775493, 3.0799640343956187],
					[56.875941871262448, 3.07996400240261],
					[56.875941882024847, 3.079963982609895],
					[56.876480781610425, 3.0787889838110356],
					[56.876480790780569, 3.0787889494725489],
					[56.876480795640255, 3.0787888812898987],
					[56.876480793116862, 3.0787888471622282],
					[56.876012781039755, 3.0770463544961602],
					[56.879274975331043, 3.0770001992176441],
					[56.88207486276908, 3.0777418703348269],
					[56.884555421883533, 3.0825778906390067],
					[56.884555462632839, 3.0825779335131558],
					[56.884555560605953, 3.0825779870471326],
					[56.884555618800889, 3.0825779991975275],
					[56.886925019445016, 3.082352999683168],
					[56.886925063656484, 3.0823529828032608],
					[56.886925143184271, 3.0823529323631349],
					[56.886925174771264, 3.0823528961050917],
					[56.88875297165621, 3.079014002582483],
					[56.890686262618047, 3.0760640162041057],
					[56.891761072309023, 3.0746836604735717],
					[56.893922017931608, 3.0785556854809211],
					[56.895322004988046, 3.0822444496053869],
					[56.895863696803247, 3.0861388913388481],
					[56.894683111809158, 3.0912277249685491],
					[56.891888728111482, 3.0959387972914483],
					[56.891888714623818, 3.0959388356127562],
					[56.891888703809961, 3.0959389113424209],
					[56.891888706638575, 3.0959389520159806],
					[56.893291498106443, 3.1011917185974784],
					[56.89276090107478, 3.1076332762790377],
					[56.891797006991567, 3.1142721394186141],
					[56.889538763670878, 3.1187776234689042],
					[56.885666659347059, 3.1195887035408272],
					[56.885666644310049, 3.1195887091995878],
					[56.885666614236044, 3.1195887205171169],
					[56.885666600169728, 3.1195887276674648],
					[56.883622099962636, 3.1207720277624333],
					[56.883622061369067, 3.1207720683761773],
					[56.883622011098289, 3.1207721667410482],
					[56.883622001131222, 3.1207722225782968],
					[56.884269201192708, 3.1264167227671131],
					[56.885027600849092, 3.1336222210199312],
					[56.885027610583585, 3.1336222556686093],
					[56.885027644196676, 3.1336223194534201],
					[56.885027666288046, 3.1336223488705022],
					[56.887397065956556, 3.1357501487285488],
					[56.887397130124725, 3.1357501731802984],
					[56.887397258249457, 3.1357501793573848],
					[56.887397322945894, 3.1357501576772431],
					[56.891164022881028, 3.1328029585796466],
					[56.891164038427739, 3.1328029446212748],
					[56.891164063928677, 3.1328029126497152],
					[56.891164074853442, 3.132802896128664],
					[56.893311262932663, 3.1288890185701357],
					[56.897075073222943, 3.1249613135399739],
					[56.899658150085024, 3.1253334799773818],
					[56.900844194340941, 3.1288389140789707],
					[56.899880407202296, 3.1339193452153258],
					[56.89805261401775, 3.1386166268871696],
					[56.898052613200974, 3.1386166286605928],
					[56.898052611567429, 3.1386166322074551],
					[56.898052610750646, 3.1386166339808801],
					[56.896544211170628, 3.142916633819425],
					[56.896544206297456, 3.1429166641946176],
					[56.896544204824188, 3.1429167285808894],
					[56.896544208964137, 3.1429167591857756],
					[56.897519208821308, 3.1460361601230722],
					[56.897519228382663, 3.1460361932438095],
					[56.897519276134751, 3.1460362515507723],
					[56.89751930611277, 3.1460362764564924],
					[56.900422059772929, 3.1475834517424661],
					[56.902038730801024, 3.1501168079037964],
					[56.902038791342484, 3.1501168510544839],
					[56.902038922558617, 3.1501168847641021],
					[56.902038995914239, 3.1501168749024928],
					[56.904188995565491, 3.1489362743304614],
					[56.904189018263168, 3.1489362592516841],
					[56.904189055232138, 3.1489362221896884],
					[56.9041890723378, 3.1489362030523655],
					[56.906769571745848, 3.1446251037515243],
					[56.906769573379556, 3.1446251002040717],
					[56.906769574196417, 3.1446250984303497],
					[56.906769575013271, 3.1446250966566218],
					[56.90956407514426, 3.1395306958436713],
					[56.909564081678475, 3.1395306816519049],
					[56.909564092882853, 3.1395306519158042],
					[56.909564096659352, 3.1395306365119438],
					[56.910747383607784, 3.1330723087834262],
					[56.912900126822763, 3.131297354622347],
					[56.912900136344675, 3.1312973465402938],
					[56.912900152630698, 3.1312973291646493],
					[56.91290016028843, 3.1312973197303542],
					[56.914514059666715, 3.1291362194902979],
					[56.914514059666715, 3.1291362194902979],
					[56.914514061453943, 3.1291362192087986],
					[56.91741686088028, 3.1252029189342592],
					[56.917416861696836, 3.1252029171596059],
					[56.917416865117175, 3.1252029133285624],
					[56.917416865933738, 3.1252029115539086],
					[56.92160846588623, 3.1189084107359397],
					[56.921608470939276, 3.118908403353895],
					[56.921608479335312, 3.1189083905058412],
					[56.921608481707572, 3.1189083835468887],
					[56.924727973164131, 3.1120279039497234],
					[56.928061259173802, 3.1076862215055523],
					[56.928061269940194, 3.1076862016918678],
					[56.928061288792222, 3.107686162488505],
					[56.928061295829728, 3.1076861399717277],
					[56.929672395935718, 3.0998306404168572],
					[56.929672396597127, 3.0998306353725411],
					[56.929672397842559, 3.0998306236496638],
					[56.929672399397518, 3.0998306184638138],
					[56.930314099233193, 3.092963918142575],
					[56.930314099816847, 3.0929639114638019],
					[56.930314100013113, 3.0929638966136865],
					[56.930314099625754, 3.092963888442346],
					[56.929878000190008, 3.0857166897233217],
					[56.929444599869349, 3.0776860885723343],
					[56.929444599403304, 3.0776860787672287],
					[56.929444594819451, 3.0776860580910501],
					[56.929444593459877, 3.0776860484280033],
					[56.927716893898143, 3.0712416516037799],
					[56.926094594826921, 3.0644082546482858],
					[56.926094593699879, 3.0644082498886549],
					[56.926094590630136, 3.0644082421457326],
					[56.926094590396609, 3.0644082372437289],
					[56.924689093827716, 3.0601193509271738],
					[56.922527997110123, 3.0480138649491368],
					[56.92252799703207, 3.0480138633153433],
					[56.922527995982477, 3.0480138601904923],
					[56.922527995982477, 3.0480138601904923],
					[56.921336296912699, 3.0421610676992619],
					[56.920686298783899, 3.0370832781734798],
					[56.920139099234845, 3.0310249823946607],
					[56.920139082591398, 3.0310249356754482],
					[56.920139030118207, 3.031024855187554],
					[56.920138992579872, 3.0310248233387624],
					[56.91798354407242, 3.0298943015894206],
					[56.919166890400064, 3.0263472634521462],
					[56.919166892532054, 3.0263472515873806],
					[56.919166897767788, 3.0263472293482034],
					[56.919166899006321, 3.026347217626653],
					[56.919697394207162, 3.020266777053505],
					[56.922061257167591, 3.017280723285507],
					[56.922061258875992, 3.0172807213649047],
					[56.922061263922721, 3.0172807139694018],
					[56.922061267339537, 3.0172807101281931],
					[56.9232445670023, 3.0154918099009977],
					[56.923244583174352, 3.0154917529742207],
					[56.923244581332874, 3.0154916396728901],
					[56.923244562425914, 3.0154915834418468],
					[56.920872361939715, 3.0122165835882773],
					[56.919361324617945, 3.0100971715800595],
					[56.920325187137657, 3.0075306699982498],
					[56.920325188767336, 3.0075306664437274],
					[56.920325191948066, 3.007530657701099],
					[56.920325192605738, 3.0075306526566732],
					[56.921077993240019, 3.0047722522178408],
					[56.92107799484819, 3.0047722108027615],
					[56.921077981825938, 3.0047721272930166],
					[56.92107796645935, 3.0047720886092915],
					[56.919133466357046, 3.0018804871527833],
					[56.919133458737889, 3.0018804785022439],
					[56.919133445365063, 3.001880462547041],
					[56.919133436852498, 3.0018804540402999],
					[56.917300175639561, 3.0001637917152078],
					[56.916325212748042, 2.9968611151707258],
					[56.917508481763555, 2.9942861836754759],
					[56.917508487308524, 2.9942861679668358],
					[56.917508495639524, 2.9942861353481165],
					[56.917508499318942, 2.9942861182940379],
					[56.91771959630924, 2.9921278501285009],
					[56.91911688324133, 2.9889639816640341],
					[56.919116890756321, 2.9889639508203754],
					[56.919116897824686, 2.9889638920636119],
					[56.91911689632704, 2.9889638610277109],
					[56.918464095670522, 2.985652761684563],
					[56.918464046401603, 2.9856526922674034],
					[56.918463912287784, 2.9856526184371019],
					[56.918463827442885, 2.9856526140239423],
					[56.914694327358845, 2.9871192142882479],
					[56.914694323785319, 2.9871192148648169],
					[56.914694319397277, 2.9871192172188077],
					[56.914694315902601, 2.9871192194286404],
					[56.911144318416589, 2.9887720181915203],
					[56.907805520363283, 2.9902248165352692],
					[56.907805501102793, 2.9902248278688188],
					[56.90780546541955, 2.9902248533697895],
					[56.907805448182224, 2.9902248693142259],
					[56.905227648351101, 2.9932220702977941],
					[56.905227638181707, 2.9932220834552612],
					[56.905227619865961, 2.9932221143809103],
					[56.90522761171961, 2.9932221321491097],
					[56.903510925937113, 2.9979526946281005],
					[56.901466593639903, 3.0001498199081587],
					[56.899741696869256, 3.0005803943408691],
					[56.89812784259572, 3.0002276051306631],
					[56.898127758851786, 3.0002276235398044],
					[56.898127636657513, 3.0002277221710236],
					[56.898127599915313, 3.0002278004730401],
					[56.898133099680571, 3.0037416956002501],
					[56.897922003166698, 3.0076499361537765],
					[56.895988731898285, 3.0106220908933179],
					[56.895988727745262, 3.0106220981408107],
					[56.895988720332646, 3.0106221124923027],
					[56.895988716258159, 3.0106221213723137],
					[56.894377615823544, 3.0143638210185357],
					[56.894377611905682, 3.0143638331626934],
					[56.894377604963438, 3.014363857307615],
					[56.894377601939013, 3.0143638693084069],
					[56.893844201949911, 3.0178888692352666],
					[56.893844202106742, 3.0178888725001682],
					[56.893844200555158, 3.0178888776841175],
					[56.893844200633588, 3.0178888793165668],
					[56.893208102681747, 3.0243388574597709],
					[56.891708117813728, 3.02866101421048],
					[56.889880429987301, 3.0316220955678745],
					[56.889880420126481, 3.0316221152387253],
					[56.889880405185075, 3.0316221603950009],
					[56.889880400841477, 3.0316221824728262],
					[56.889666501435336, 3.0341610828935845],
					[56.889666500620081, 3.0341610846687823],
					[56.889666500698283, 3.0341610863010993],
					[56.889666499804839, 3.0341610864439796],
					[56.88945810642808, 3.0372832033805923],
					[56.888058281748521, 3.0384803520841008],
					[56.885364006625409, 3.0367748308098883],
					[56.885363933082218, 3.0367748178942859],
					[56.885363795801318, 3.0367748464134565],
					[56.885363733035227, 3.0367748893375559],
					[56.883535932878182, 3.0395387907808469],
					[56.883535925383867, 3.0395388034898008],
					[56.883535912416605, 3.0395388335184719],
					[56.883535905972032, 3.0395388493488498],
					[56.882572005889664, 3.04325824958778],
					[56.882572003911967, 3.0432582647039799],
					[56.882572000850075, 3.0432582947937861],
					[56.882571999765872, 3.0432583097673578],
					[56.882794199859426, 3.0479278101174567],
					[56.882794215680335, 3.0479278585735905],
					[56.882794268219619, 3.0479279406410384],
					[56.882794305015999, 3.0479279758844275],
					[56.884197104982633, 3.0486834759875134],
					[56.884197163101796, 3.0486834864526284],
					[56.884197275992868, 3.0486834750253946],
					[56.884197329793309, 3.0486834516434418],
					[56.885594398284134, 3.047489164247394],
					[56.887535890807172, 3.0492083825157419],
					[56.887108105398433, 3.0535055402821878],
					[56.884527640151518, 3.0582248746005853],
					[56.88087486408093, 3.0615942526352415],
					[56.88087486415877, 3.0615942542672712],
					[56.880874862371762, 3.0615942545516286],
					[56.88087486066258, 3.061594256468005],
					[56.878616565664558, 3.063769250981232],
					[56.876033225846314, 3.065952601054498],
					[56.872697197250751, 3.0660025998805698],
					[56.872697126702953, 3.0660026308230748],
					[56.872697028808936, 3.0660027302298976],
					[56.872696999753479, 3.0660028006099598],
					[56.872699542080639, 3.0667089147610911],
					[56.870975086401067, 3.0660276193017295],
					[56.868602898070861, 3.0647026244793452],
					[56.868602861552453, 3.0647026138459883],
					[56.868602789953691, 3.0647026038576883],
					[56.86860275316409, 3.0647026064183156],
					[56.866019352690387, 3.0653248054820295],
					[56.866019318387806, 3.0653248224400964],
					[56.866019255610382, 3.0653248652924541],
					[56.866019230049424, 3.0653248956548675],
					[56.864191530331148, 3.0682692946156127],
					[56.864191523727186, 3.0682693071702185],
					[56.864191513199778, 3.0682693318536662],
					[56.864191508382852, 3.0682693441244155],
					[56.863230415119197, 3.0715860185542927],
					[56.861297087427097, 3.0747220027529552],
					[56.859680596829655, 3.0747442000868777],
					[56.859680560272572, 3.0747442075298177],
					[56.859680490537841, 3.0747442366706528],
					[56.859680460040792, 3.074744257943292],
					[56.858283271676228, 3.0761219462676381],
					[56.856772350027576, 3.0751720587704274],
					[56.855586275083581, 3.0730527023130199],
					[56.85558627216998, 3.0730526978454882],
					[56.85558626731401, 3.073052690399583],
					[56.855586265371599, 3.0730526874212152],
					[56.854400165246133, 3.0713248864629592],
					[56.854400127718215, 3.0713248546245953],
					[56.85440004458173, 3.0713248103057365],
					[56.854399997263812, 3.0713247997397399],
					[56.853969396957673, 3.0713304001280917],
					[56.85396931362655, 3.0713304462167867],
					[56.853969224972715, 3.0713305884560267],
					[56.853969217707565, 3.0713306816284311],
					[56.854405352795077, 3.0722943402492238],
					[56.852147091360401, 3.0740720318050601],
					[56.850638806952269, 3.0748692236197219],
					[56.850638797278698, 3.0748692284398946],
					[56.850638778086854, 3.0748692413417262],
					[56.850638769462094, 3.0748692492816927],
					[56.848594308672936, 3.076644214137358],
					[56.846444362255518, 3.0770609047037549],
					[56.846444349746008, 3.077060906686504],
					[56.846444326669285, 3.0770609136298765],
					[56.84644431610208, 3.077060918590512],
					[56.844722115811003, 3.0778609186876591],
					[56.844722097434705, 3.0778609298136472],
					[56.844722062701976, 3.0778609566739128],
					[56.844722047161483, 3.0778609706360882],
					[56.842894247114756, 3.0800165717127475],
					[56.842894243695739, 3.0800165755397328],
					[56.842894236935237, 3.0800165848241141],
					[56.842894232622662, 3.0800165887926232],
					[56.839777633295498, 3.0847054888691297],
					[56.839777629953822, 3.0847054943256538],
					[56.839777623425455, 3.0847055084992805],
					[56.83977761919023, 3.0847055140972093],
					[56.837738719026262, 3.0889916132037123],
					[56.837738719181161, 3.0889916164641487],
					[56.83773871665538, 3.0889916201484811],
					[56.83773871576183, 3.0889916202897747],
					[56.835163716251941, 3.0950249207369787],
					[56.83516371306451, 3.0950249294521579],
					[56.83516370668962, 3.0950249468825217],
					[56.835163705289311, 3.0950249553154308],
					[56.834197006421235, 3.0992916503879968],
					[56.833235908154627, 3.1025916441782302],
					[56.833235903797579, 3.1025916662147668],
					[56.833235901338576, 3.102591709301187],
				],
			],
		},
		{
			fldNpdidField: "1578840",
			wlbNpdidWellbore: "1523",
			fldName: "FRAM",
			fldCurrentActivitySatus: "Producing",
			wlbName: "35/11-4",
			fldDiscoveryYear: "1990",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=1578840",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=1578840",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "090",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=1523",
			fldHcType: "OIL/GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "21524",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[61.049080403895552, 3.4835639367294089],
					[61.04932760447273, 3.4848667377766986],
					[61.049327604774732, 3.4848667451491711],
					[61.049327609997668, 3.4848667628017198],
					[61.049327612980441, 3.4848667697070161],
					[61.049799813301263, 3.4861250692237427],
					[61.049799813376744, 3.4861250710668883],
					[61.049799813376744, 3.4861250710668883],
					[61.049799814345796, 3.4861250727543442],
					[61.050285912558913, 3.4873722716532916],
					[61.050769213158887, 3.4886306720925804],
					[61.051255412914486, 3.4898917713709037],
					[61.051730412194161, 3.4911722686991586],
					[61.052199812214759, 3.4924639671545332],
					[61.052666510823542, 3.4937722657977344],
					[61.053110908812727, 3.4951028581476775],
					[61.053499806768748, 3.4965167512951529],
					[61.053883106712917, 3.4979528520707328],
					[61.053883106788248, 3.4979528539141671],
					[61.053883107757159, 3.4979528556022061],
					[61.053883107832483, 3.4979528574456227],
					[61.054297008309071, 3.4993417561068392],
					[61.05429700935327, 3.4993417596384044],
					[61.05429701144174, 3.499341766701491],
					[61.05429701241065, 3.4993417683896069],
					[61.054777612346001, 3.5006472688668775],
					[61.054777613390172, 3.5006472723985218],
					[61.054777615403246, 3.5006472776183482],
					[61.054777617265785, 3.5006472791511918],
					[61.055319216625499, 3.5018944807554417],
					[61.055319216625499, 3.5018944807554417],
					[61.055319217594409, 3.5018944824436691],
					[61.055319217669677, 3.5018944842871851],
					[61.055880418109957, 3.5031111811510898],
					[61.056460916809698, 3.5044333805550791],
					[61.057035917405422, 3.5057528808198413],
					[61.057035917405422, 3.5057528808198413],
					[61.05703591658704, 3.5057528828186508],
					[61.057035918374275, 3.5057528825082795],
					[61.057624817891465, 3.5070556815912299],
					[61.057624817966669, 3.5070556834348952],
					[61.058216517858689, 3.5083528829392709],
					[61.058802618199934, 3.5096361832808656],
					[61.058802618199934, 3.5096361832808656],
					[61.058802619168759, 3.5096361849695143],
					[61.05880261924392, 3.5096361868132573],
					[61.059399818947902, 3.5108972857566538],
					[61.059399819916713, 3.5108972874453719],
					[61.05939982088546, 3.5108972891341006],
					[61.05939982088546, 3.5108972891341006],
					[61.06001372065866, 3.5121167895975156],
					[61.060013721627442, 3.5121167912863043],
					[61.060013725427353, 3.5121167961976778],
					[61.060013726396129, 3.5121167978864696],
					[61.060658125641766, 3.5132556973626983],
					[61.060658127579273, 3.5132557007404235],
					[61.060658131454268, 3.5132557074958881],
					[61.060658133316636, 3.5132557090297536],
					[61.061344232793218, 3.5143029107922077],
					[61.061344234655579, 3.5143029123261846],
					[61.061344239349069, 3.5143029170830271],
					[61.061344241286534, 3.5143029204609046],
					[61.06206374116482, 3.5152529205793934],
					[61.06206374213356, 3.5152529222683864],
					[61.062063744964625, 3.5152529254914513],
					[61.062063745933358, 3.515252927180446],
					[61.062799846261065, 3.5161390285541341],
					[61.062799847154672, 3.5161390283992078],
					[61.062799847154672, 3.5161390283992078],
					[61.062799848123404, 3.5161390300882673],
					[61.063563743435736, 3.5170334247402839],
					[61.064297035024929, 3.5180529110876151],
					[61.06502203042745, 3.5191834054181474],
					[61.06571092613315, 3.5203389988812051],
					[61.066283123736291, 3.521394495747499],
					[61.066283161282215, 3.5213945338164692],
					[61.066283248878506, 3.5213945855017799],
					[61.066283299897528, 3.5213946008075059],
					[61.066322088006103, 3.52139459909335],
					[61.066947029233894, 3.5224195047754812],
					[61.066947031096184, 3.5224195063101753],
					[61.066947034002119, 3.5224195113786116],
					[61.066947034970774, 3.5224195130680851],
					[61.067599835375347, 3.5233695133404996],
					[61.067599836343994, 3.5233695150300339],
					[61.06759983820627, 3.5233695165648133],
					[61.067599839174875, 3.523369518254357],
					[61.068291538672256, 3.5243112180002876],
					[61.068291539640846, 3.5243112196898947],
					[61.068291541578084, 3.5243112230691001],
					[61.068291544334009, 3.524311224449229],
					[61.069033144337972, 3.5252418247445951],
					[61.069033146200262, 3.5252418262795446],
					[61.069033149999726, 3.5252418311938594],
					[61.069033151936942, 3.5252418345732002],
					[61.0698304520241, 3.5261251333196775],
					[61.069830453961288, 3.5261251366991591],
					[61.069830458654373, 3.5261251414590031],
					[61.069830460591575, 3.5261251448384834],
					[61.070674860961937, 3.5269362444415311],
					[61.07067486468641, 3.526936247511796],
					[61.070674873103947, 3.5269362553421444],
					[61.070674876828413, 3.5269362584124284],
					[61.071547077011878, 3.5276140581017446],
					[61.071547082598578, 3.5276140627073955],
					[61.071547093697021, 3.5276140700741427],
					[61.0715471001024, 3.5276140726805796],
					[61.072388721198188, 3.5281001279471353],
					[61.072430404159832, 3.5283111385931694],
					[61.072430423012264, 3.5283111817697002],
					[61.072430477990686, 3.5283112502730045],
					[61.072430513372929, 3.5283112794436375],
					[61.073002713420493, 3.5285862808268429],
					[61.073002716101456, 3.5285862803629064],
					[61.073002720719558, 3.5285862832789303],
					[61.073002722581776, 3.5285862848142671],
					[61.073544322821668, 3.5288112854582052],
					[61.073544326471172, 3.528811286684296],
					[61.073544331907989, 3.5288112876011013],
					[61.073544333770194, 3.5288112891364936],
					[61.074161033572985, 3.5290251889249724],
					[61.074161037222495, 3.5290251901511285],
					[61.074161041765677, 3.5290251912226176],
					[61.074161043627882, 3.5290251927580352],
					[61.0747943440348, 3.5292084922221942],
					[61.074794344928463, 3.5292084920675517],
					[61.074794346790661, 3.5292084936030093],
					[61.075424944796019, 3.529380792119309],
					[61.076047134681573, 3.5295695885597613],
					[61.076666620206396, 3.5298057823407536],
					[61.077277698792649, 3.5301084731395935],
					[61.077872079131701, 3.5305084591578679],
					[61.078463760610269, 3.5310112434295706],
					[61.079044246197157, 3.5316473262156318],
					[61.07958589993406, 3.5323750653808879],
					[61.079585899560485, 3.5323860999538517],
					[61.079585905376469, 3.5323861323913812],
					[61.079585924826681, 3.5323861903401301],
					[61.07958594039799, 3.5323862192322428],
					[61.08019704038945, 3.5332029207823679],
					[61.0801970412831, 3.5332029206278097],
					[61.080197043220117, 3.5332029240088225],
					[61.080197044188651, 3.5332029256993183],
					[61.080844243762144, 3.5340140243217855],
					[61.081505442376503, 3.5348362221519998],
					[61.082133099606146, 3.5356861666731998],
					[61.082133099759439, 3.5356888999390876],
					[61.082133104680992, 3.5356889325343555],
					[61.082133126066857, 3.5356889938711422],
					[61.08213314163752, 3.5356890227671767],
					[61.08258314180619, 3.5362723218959724],
					[61.082583144711627, 3.5362723269681413],
					[61.08258315216019, 3.5362723331130717],
					[61.082583155065656, 3.5362723381852201],
					[61.083008154696991, 3.5367168382637901],
					[61.0830081593149, 3.5367168411818626],
					[61.083008165944612, 3.5367168493266852],
					[61.083008169668879, 3.5367168523992505],
					[61.08342486977763, 3.5370723528958683],
					[61.083424875289211, 3.5370723556595527],
					[61.083424886387228, 3.5370723630321912],
					[61.083424891079972, 3.5370723677956186],
					[61.08389159060539, 3.537375167455163],
					[61.083891601703336, 3.5373751748280138],
					[61.08389162464335, 3.5373751857286542],
					[61.083891637379018, 3.537375189102002],
					[61.084366636889676, 3.5375334906973408],
					[61.08436664955056, 3.5375334922254749],
					[61.084366676809239, 3.5375334986634273],
					[61.084366691257458, 3.5375334998826111],
					[61.08486939083835, 3.537555799589613],
					[61.084869401637093, 3.5375557995813454],
					[61.084869423159766, 3.5375557977194658],
					[61.084869433064839, 3.537555797865668],
					[61.08539443266482, 3.5374668962885223],
					[61.085394440782586, 3.5374668967436511],
					[61.085394455006345, 3.5374668924266848],
					[61.085394462080856, 3.5374668893455219],
					[61.085913961690487, 3.5372973893312976],
					[61.08591396705247, 3.5372973884044034],
					[61.085913976807944, 3.5372973848597047],
					[61.0859139820951, 3.5372973820874112],
					[61.086411181557075, 3.5370751828214706],
					[61.086411183344396, 3.5370751825124884],
					[61.086411186844217, 3.5370751800490949],
					[61.086411188631544, 3.5370751797401128],
					[61.086886189228544, 3.5368390780442849],
					[61.086886190197006, 3.5368390797352602],
					[61.086886190122193, 3.5368390778897854],
					[61.087341788944855, 3.5366084789219272],
					[61.087772281293979, 3.536400183316176],
					[61.088183364303831, 3.5362362896777784],
					[61.088588943023183, 3.5361223964707347],
					[61.08898891929681, 3.53605850038005],
					[61.089394276002018, 3.5360474025115503],
					[61.089497019914418, 3.5362611864456208],
					[61.089497047401892, 3.5362612207219994],
					[61.089497115257956, 3.5362612740374573],
					[61.089497154807724, 3.5362612950766952],
					[61.089949940262422, 3.5363668903447003],
					[61.09043051339254, 3.5365584816496907],
					[61.090935991091243, 3.5368445676935698],
					[61.09143317387246, 3.5372140562983097],
					[61.091922064062395, 3.5376418453021725],
					[61.092383158265569, 3.5381001418617912],
					[61.092827657228867, 3.5385557383343489],
					[61.093247054591558, 3.5389890370797108],
					[61.093880452622535, 3.5396751363676686],
					[61.09388045440987, 3.539675136058738],
					[61.09449705402173, 3.5403307375317277],
					[61.094497054915415, 3.540330737377273],
					[61.094497054915415, 3.540330737377273],
					[61.094497055883842, 3.5403307390687377],
					[61.0951220558145, 3.540980738439433],
					[61.095122056708149, 3.5409807382849987],
					[61.095122056782905, 3.540980740130951],
					[61.095749857028572, 3.5416251404539558],
					[61.09574985985909, 3.5416251436826833],
					[61.095749866339013, 3.5416251481397021],
					[61.095749870063216, 3.5416251512140069],
					[61.0963887701011, 3.5421751515388906],
					[61.09638877196317, 3.5421751530761001],
					[61.096388772037926, 3.5421751549221456],
					[61.09703317187202, 3.5427084547474088],
					[61.09703318110769, 3.5427084605876962],
					[61.097033197716932, 3.5427084707309899],
					[61.097033206058875, 3.5427084767256867],
					[61.097666604945367, 3.543044576299001],
					[61.098349697349704, 3.5434167638256766],
					[61.098219243097923, 3.5435831765175041],
					[61.098219222851817, 3.5435832543771544],
					[61.098219247672205, 3.5435834006726781],
					[61.09821929273869, 3.5435834691085564],
					[61.098991586007031, 3.544072365053899],
					[61.099705478655665, 3.5446057598135474],
					[61.100410978607918, 3.5451445593424999],
					[61.100410980395303, 3.5451445590337891],
					[61.100410984119399, 3.5451445621089235],
					[61.100410985087755, 3.5451445638008479],
					[61.101149884753312, 3.5456612643125824],
					[61.101149885646969, 3.5456612641582428],
					[61.101149889296387, 3.5456612653871855],
					[61.101149890264736, 3.5456612670791627],
					[61.101877690395824, 3.5461418669385627],
					[61.101877692257908, 3.5461418684762678],
					[61.101877696875647, 3.546141871397325],
					[61.101877698737681, 3.5461418729350198],
					[61.102608198633476, 3.546566873204462],
					[61.102608203176487, 3.5465668742792427],
					[61.102608208762646, 3.5465668788925075],
					[61.102608212412029, 3.546566880121607],
					[61.103344312249206, 3.5469251798628441],
					[61.103344316866966, 3.5469251827841517],
					[61.103344325059396, 3.5469251850881607],
					[61.103344328708779, 3.546925186317325],
					[61.104080528588433, 3.5472057879271262],
					[61.104080533056823, 3.5472057871555349],
					[61.104080542217609, 3.5472057911518533],
					[61.104080545866964, 3.5472057923810807],
					[61.104813845860704, 3.5474112929237283],
					[61.104813851297422, 3.5474112938443678],
					[61.104813862170857, 3.5474112956856607],
					[61.10481386671394, 3.5474112967606088],
					[61.105586066769426, 3.5475390975931682],
					[61.105586071312494, 3.547539098668159],
					[61.10558607764289, 3.5475390994345144],
					[61.105586081217595, 3.5475390988172428],
					[61.106383274370145, 3.5476140982070294],
					[61.107116656225536, 3.5477362956133622],
					[61.107852734786036, 3.5479445896886537],
					[61.108538815865415, 3.5482251820320299],
					[61.109238805671133, 3.548591877614776],
					[61.109988805133291, 3.5489973751173851],
					[61.109988806995297, 3.5489973766556191],
					[61.110730506927439, 3.5493862761505408],
					[61.110730508789452, 3.5493862776888294],
					[61.110730511619813, 3.5493862809197236],
					[61.110730514300819, 3.5493862804568335],
					[61.111466613947464, 3.5497362801619694],
					[61.111466617596825, 3.5497362813917253],
					[61.111466625863883, 3.5497362855438968],
					[61.111466628619596, 3.5497362869279452],
					[61.11222492767272, 3.5500223863331111],
					[61.112972118406873, 3.5503140821234833],
					[61.11374710167, 3.5507057751899223],
					[61.114513789362029, 3.5511779666131571],
					[61.115305479643851, 3.5517501605157298],
					[61.116110978318204, 3.5523723582496856],
					[61.116111060092628, 3.5523723776171625],
					[61.116111209932328, 3.5523723443145796],
					[61.11611127717854, 3.5523722936459947],
					[61.117016876640548, 3.5506389936849749],
					[61.117016887733655, 3.5506389564267926],
					[61.117016894578121, 3.5506388808390423],
					[61.117016892116816, 3.5506388422008688],
					[61.11606129215906, 3.54740554223077],
					[61.11606129215906, 3.54740554223077],
					[61.116061291190718, 3.5474055405379605],
					[61.116061291116004, 3.5474055386907737],
					[61.115986290930216, 3.547169339554276],
					[61.115886291340338, 3.5468555386089142],
					[61.115886289553011, 3.5468555389177165],
					[61.115886290446653, 3.54685553876332],
					[61.115761289697154, 3.5464721397943579],
					[61.115722391955572, 3.5463499422797211],
					[61.115708492776939, 3.5462999454533737],
					[61.115708490840184, 3.5462999420678734],
					[61.11570848771094, 3.5462999314480985],
					[61.115708485699443, 3.5462999262154189],
					[61.115683488233287, 3.5462360327327414],
					[61.115622391710673, 3.546047145023099],
					[61.115608494173394, 3.5459916516701067],
					[61.115608493130281, 3.5459916481301956],
					[61.115608491044107, 3.5459916410504002],
					[61.115608490001023, 3.5459916375104834],
					[61.115527990365223, 3.5457471375451179],
					[61.115458490080456, 3.5455388376632326],
					[61.115380789737927, 3.5453027379215194],
					[61.11534458991062, 3.5451943379907456],
					[61.115280789669391, 3.5449971382116341],
					[61.115280789594671, 3.5449971363644943],
					[61.115280789594671, 3.5449971363644943],
					[61.115180790240821, 3.5446999365005669],
					[61.115180789347136, 3.5446999366550305],
					[61.115180789272408, 3.5446999348078969],
					[61.115161291322714, 3.5446443393282729],
					[61.115150191676811, 3.5446055450136189],
					[61.115150191602083, 3.5446055431664889],
					[61.115150189590558, 3.5446055379340216],
					[61.11515018854741, 3.5446055343942082],
					[61.115069590163749, 3.544369337287395],
					[61.114983490557137, 3.544094339449813],
					[61.114983489663466, 3.5440943396042917],
					[61.114983489588724, 3.5440943377571719],
					[61.114983488545548, 3.5440943342173967],
					[61.114839100271809, 3.5436721665894302],
					[61.114839100443724, 3.5436666999236692],
					[61.114839097908963, 3.543666681761517],
					[61.114839091945754, 3.5436666455917214],
					[61.114839086804714, 3.5436666297401382],
					[61.114794589191433, 3.5435499342652803],
					[61.114758491233829, 3.5434360390435264],
					[61.114758491233829, 3.5434360390435264],
					[61.114739091241809, 3.5433749391387934],
					[61.114739090348124, 3.5433749392932921],
					[61.114739090273375, 3.5433749374461896],
					[61.114739090198633, 3.5433749355990689],
					[61.114614090135028, 3.5430027359753367],
					[61.114614089241364, 3.5430027361298388],
					[61.114614089166622, 3.5430027342827426],
					[61.114614089166622, 3.5430027342827426],
					[61.114566888747397, 3.5428666334896302],
					[61.11452238914741, 3.5427388342385777],
					[61.114441889279135, 3.5425027351162646],
					[61.114441888310694, 3.5425027334236843],
					[61.114441887192747, 3.5425027280369568],
					[61.114441886224341, 3.5425027263443871],
					[61.11442518806129, 3.5424610327601496],
					[61.114391889897789, 3.5423582381570973],
					[61.114391889823032, 3.5423582363100166],
					[61.114391889823032, 3.5423582363100166],
					[61.114297389997155, 3.54207773608884],
					[61.114297389028742, 3.5420777343962944],
					[61.114297389028742, 3.5420777343962944],
					[61.114297388060301, 3.5420777327037509],
					[61.114200189084222, 3.5418055332749252],
					[61.114119588944789, 3.5415693351485422],
					[61.113950189109978, 3.5410777350250662],
					[61.113950189035208, 3.5410777331780179],
					[61.113950188066781, 3.5410777314855197],
					[61.113950188066781, 3.5410777314855197],
					[61.1138751892504, 3.5408721357415267],
					[61.113850190343626, 3.540794339356971],
					[61.113850190268842, 3.5407943375099289],
					[61.113850190268842, 3.5407943375099289],
					[61.113797390082645, 3.5406360362012315],
					[61.113797390082645, 3.5406360362012315],
					[61.113797389114218, 3.5406360345087533],
					[61.113680789137945, 3.5402971358893258],
					[61.113680789063146, 3.5402971340422953],
					[61.113680788019927, 3.540297130502803],
					[61.113680787126256, 3.5402971306573803],
					[61.113547389382241, 3.5399388353668093],
					[61.11350579095771, 3.539808239491621],
					[61.113505790064075, 3.5398082396462041],
					[61.113505789989283, 3.5398082377991842],
					[61.113491889849101, 3.5397666374727872],
					[61.113491888880638, 3.5397666357803561],
					[61.113491888880638, 3.5397666357803561],
					[61.113491888805839, 3.5397666339333211],
					[61.113469589232224, 3.5397027330748556],
					[61.113469588338575, 3.53970273322944],
					[61.113469588263783, 3.539702731382405],
					[61.113469587295306, 3.5397027296899841],
					[61.113408487664024, 3.5395388314325236],
					[61.113347387743687, 3.53936933165518],
					[61.113347386775224, 3.5393693299627702],
					[61.11329738823293, 3.5392360318016527],
					[61.113214088368814, 3.5390055324130771],
					[61.113127987912065, 3.5387693325573761],
					[61.113105789186278, 3.5387055346593606],
					[61.113105789111472, 3.5387055328123664],
					[61.113105788142988, 3.5387055311199918],
					[61.113105787174526, 3.5387055294275953],
					[61.113061287372183, 3.5385860292992901],
					[61.112964086704139, 3.538324930158224],
					[61.112911287174796, 3.5381832293112248],
					[61.112911287174796, 3.5381832293112248],
					[61.112911286206305, 3.5381832276188763],
					[61.112911286131485, 3.5381832257718981],
					[61.112866885904509, 3.5380721278414384],
					[61.112777987246211, 3.5378360302223104],
					[61.11271408840463, 3.5376638295293836],
					[61.112714086617302, 3.5376638298386696],
					[61.112714087436139, 3.5376638278370609],
					[61.112714085648832, 3.5376638281463433],
					[61.112616887246617, 3.5374138284348575],
					[61.112519586574066, 3.5371610282733466],
					[61.112519584637091, 3.5371610248887495],
					[61.112519580613487, 3.5371610144256138],
					[61.112519578676505, 3.5371610110410154],
					[61.112514089221918, 3.5371499293024469],
					[61.112511294309392, 3.5371388518091824],
					[61.11251129222277, 3.5371388447306789],
					[61.112511288124317, 3.5371388324205975],
					[61.11251128603768, 3.5371388253420939],
					[61.112416886055094, 3.536902725149718],
					[61.11231128640253, 3.5366388267976627],
					[61.112172386308025, 3.5362916256482766],
					[61.112172386308025, 3.5362916256482766],
					[61.112172385414404, 3.5362916258029498],
					[61.112136284824409, 3.5362027243269054],
					[61.112136284749567, 3.5362027224799784],
					[61.112136283781048, 3.5362027207877329],
					[61.112136282887413, 3.5362027209424092],
					[61.11210578590152, 3.5361332247690322],
					[61.112075187802354, 3.5360499310202962],
					[61.112075186833877, 3.5360499293280352],
					[61.112075185790509, 3.536049925788872],
					[61.112075184822018, 3.5360499240966292],
					[61.112041885377096, 3.5359693243626831],
					[61.112041885302261, 3.5359693225157596],
					[61.112041884408612, 3.5359693226704425],
					[61.112041884408612, 3.5359693226704425],
					[61.111900183589306, 3.5356332225539795],
					[61.111747384824817, 3.5352693221634439],
					[61.111727986375016, 3.5352221254862797],
					[61.111694585966191, 3.5351360285648887],
					[61.111694584922844, 3.5351360250257766],
					[61.111694582910928, 3.5351360197944981],
					[61.111694581867575, 3.5351360162553855],
					[61.111647384615779, 3.5350332221383298],
					[61.11163078689065, 3.534988829106549],
					[61.111630785922131, 3.5349888274143666],
					[61.111630783910243, 3.5349888221831054],
					[61.11163078294171, 3.5349888204909039],
					[61.111480782803071, 3.534647120528946],
					[61.111344584211828, 3.5343360202228107],
					[61.111283487113624, 3.5341916293589617],
					[61.111272389891838, 3.5341582361716215],
					[61.111272387879922, 3.53415823094045],
					[61.111272382962426, 3.5341582206328632],
					[61.111272380875626, 3.5341582135548251],
					[61.111244581096827, 3.5340999152535804],
					[61.111183481686389, 3.5339666177098996],
					[61.111052983288708, 3.5336721206312149],
					[61.110936284205408, 3.5333999216755299],
					[61.110936283236903, 3.5333999199834247],
					[61.110936282268348, 3.5333999182913298],
					[61.110936282193471, 3.5333999164444796],
					[61.110839099816729, 3.5331888565672465],
					[61.110839099847318, 3.5331806008469013],
					[61.11083909596821, 3.5331805717616311],
					[61.110839079273489, 3.5331805151386511],
					[61.110839067426433, 3.5331804892930281],
					[61.110827976056889, 3.5331638044452394],
					[61.110741883275011, 3.5329666208218669],
					[61.110741883200134, 3.5329666189750291],
					[61.110741882156731, 3.5329666154360959],
					[61.11074188126306, 3.532966615590861],
					[61.110661280583187, 3.5327943145298533],
					[61.110622381808888, 3.5327110162939857],
					[61.110511282328396, 3.5324666162202583],
					[61.110405781619441, 3.5322360166781124],
					[61.110405772902546, 3.5322360014497609],
					[61.11040575189412, 3.532235971612208],
					[61.110405739602562, 3.5322359570029906],
					[61.110400179776128, 3.5322304962514242],
					[61.110394594437089, 3.5322082516002387],
					[61.110394591381649, 3.5322082428304946],
					[61.110394584302227, 3.5322082235989716],
					[61.110394580278253, 3.5322082131371939],
					[61.110294580305926, 3.5319999134898574],
					[61.110183481426951, 3.5317666135765018],
					[61.110183479639666, 3.5317666138860848],
					[61.110183479639666, 3.5317666138860848],
					[61.110069579580859, 3.5315305145519078],
					[61.109958499873088, 3.5312971549839007],
					[61.109958499745822, 3.531294400693445],
					[61.10995849698471, 3.5312943769948038],
					[61.10995848706915, 3.5312943322183012],
					[61.109958479021067, 3.5312943112952646],
					[61.109947378758577, 3.5312721111509129],
					[61.109847379830349, 3.5310693125847621],
					[61.109736280981586, 3.5308360133971086],
					[61.109736280087951, 3.5308360135519203],
					[61.109736280087951, 3.5308360135519203],
					[61.109736280013045, 3.5308360117051483],
					[61.109619581015203, 3.5305971138142844],
					[61.109508480925811, 3.5303582165117309],
					[61.109508476976629, 3.5303582078972373],
					[61.109508466322367, 3.5303581892859826],
					[61.109508459617302, 3.53035817928922],
					[61.109500172618255, 3.5303470989914012],
					[61.109405782106514, 3.5301388169844063],
					[61.109405781137959, 3.5301388152924864],
					[61.109405781063039, 3.530138813445737],
					[61.109291881176375, 3.5298999133831002],
					[61.10917798088682, 3.5296610150433416],
					[61.109069599656827, 3.5294277565657768],
					[61.109069600311066, 3.5294250003532079],
					[61.109069596655999, 3.5294249768102581],
					[61.109069586739842, 3.5294249320356221],
					[61.109069578691454, 3.529424911113658],
					[61.109058479029983, 3.5294027120953912],
					[61.108958480297488, 3.529197112771076],
					[61.108958480297488, 3.529197112771076],
					[61.108844579936346, 3.5289638132826191],
					[61.108730781212223, 3.5287249131246354],
					[61.108730780318567, 3.5287249132795071],
					[61.108730780243633, 3.5287249114327999],
					[61.108730779349997, 3.5287249115876675],
					[61.108619588798987, 3.5284999308998213],
					[61.108616893889561, 3.5284888529837222],
					[61.108616883828894, 3.5284888268319539],
					[61.108616857601987, 3.5284887793059103],
					[61.108616840617053, 3.5284887599332082],
					[61.108611263192572, 3.5284831804274708],
					[61.108580777197162, 3.5284249071289229],
					[61.108580777122199, 3.528424905282209],
					[61.10858077622855, 3.5284249054370864],
					[61.108514076825095, 3.5282999064513216],
					[61.108386277207508, 3.5280582063311505],
					[61.108386277207508, 3.5280582063311505],
					[61.108386276313873, 3.5280582064860337],
					[61.108241875723792, 3.5277888052047555],
					[61.108241875723792, 3.5277888052047555],
					[61.108241874755208, 3.5277888035129692],
					[61.108241874755208, 3.5277888035129692],
					[61.108166876857233, 3.5276527076170563],
					[61.108150179221575, 3.5276193104605609],
					[61.108116879155382, 3.5275527117486929],
					[61.108116878111808, 3.527552708210258],
					[61.108116876249568, 3.5275527066733856],
					[61.108116876174606, 3.5275527048267006],
					[61.108066876787809, 3.5274610054985129],
					[61.107916877594874, 3.5271693101152737],
					[61.107758479148124, 3.5268527111210939],
					[61.107758479073155, 3.526852709274432],
					[61.107758478179491, 3.5268527094293511],
					[61.107758478104536, 3.526852707582707],
					[61.10771957828198, 3.5267777085936567],
					[61.107689079359773, 3.526716610327286],
					[61.107639082861255, 3.5266166189854116],
					[61.107625186849305, 3.5265805279531537],
					[61.107625184912081, 3.5265805245697108],
					[61.107625180962671, 3.526580515956204],
					[61.107625179025447, 3.5265805125727767],
					[61.107464079824915, 3.5262527127123215],
					[61.107302980043023, 3.5259193131339908],
					[61.107302979968054, 3.525919311287375],
					[61.10730297907439, 3.5259193114423191],
					[61.107302978999407, 3.5259193095956864],
					[61.107272381775758, 3.5258582169247208],
					[61.107241883573451, 3.5257860220378285],
					[61.107241884392117, 3.5257860200362727],
					[61.107197384182001, 3.5256832207358491],
					[61.107197383213382, 3.5256832190441858],
					[61.107197382244749, 3.5256832173525243],
					[61.107197381276144, 3.5256832156608424],
					[61.107177982357477, 3.5256416174898888],
					[61.107025183230029, 3.5252999188404988],
					[61.107025172650097, 3.5252999020790021],
					[61.107025151565217, 3.5252998704026175],
					[61.107025139347982, 3.5252998576442205],
					[61.106830739227192, 3.5251109578720521],
					[61.106830739152208, 3.5251109560254696],
					[61.106830738183589, 3.5251109543338401],
					[61.106830737289926, 3.5251109544888006],
					[61.1067307741165, 3.5250165884832136],
					[61.106725189576153, 3.5249999354349995],
					[61.106725156198955, 3.5249998891543655],
					[61.106725068822094, 3.5249998206245663],
					[61.106725014897442, 3.5249998002219747],
					[61.106500016608734, 3.5249831009543207],
					[61.106441718731482, 3.524977600758604],
					[61.106441717837832, 3.524977600913564],
					[61.10638061801022, 3.5249720008355339],
					[61.106380617116564, 3.5249720009904961],
					[61.106380612573396, 3.5249719999187321],
					[61.106380611679732, 3.5249720000736944],
					[61.106286112162046, 3.5249664994835532],
					[61.106286108587483, 3.5249665001033899],
					[61.10628610315063, 3.5249664991865974],
					[61.106286099576081, 3.5249664998064336],
					[61.106280604612735, 3.5249665005512143],
					[61.106036108976859, 3.5249554008857173],
					[61.10603610800824, 3.5249553991941336],
					[61.106036106220976, 3.5249553995040492],
					[61.106036105327313, 3.5249553996590115],
					[61.105930605840534, 3.5249526005447067],
					[61.105841705560849, 3.5249497998321644],
					[61.105841704667192, 3.5249497999871249],
					[61.1058417019863, 3.5249498004519917],
					[61.105841700199008, 3.5249498007619073],
					[61.105836119664694, 3.5249497996076888],
					[61.105822238927246, 3.5249470035500785],
					[61.105822238033582, 3.524947003705039],
					[61.105822234384036, 3.5249470024783229],
					[61.105822232596758, 3.5249470027882368],
					[61.105572232598838, 3.5249054039582939],
					[61.105572231630219, 3.5249054022667368],
					[61.10557223073657, 3.5249054024216933],
					[61.105483331430186, 3.5248915022240168],
					[61.105483329642908, 3.5248915025339294],
					[61.105483328749244, 3.5248915026888885],
					[61.105483327855623, 3.524891502843841],
					[61.105386145663907, 3.5248776052729998],
					[61.105336162966275, 3.5248609104221496],
					[61.105336162072611, 3.5248609105771092],
					[61.105336161103992, 3.5248609088855662],
					[61.105336160210349, 3.5248609090405219],
					[61.105061159657311, 3.5247748088024768],
					[61.105061155114129, 3.5247748077307759],
					[61.105061145134094, 3.5247748057423234],
					[61.105061138728622, 3.5247748031340627],
					[61.105033352744933, 3.5247692062279348],
					[61.104938976304169, 3.5247359154467519],
					[61.10489728934418, 3.5247165218091179],
					[61.104797293333299, 3.5246637236586853],
					[61.10479729236468, 3.5246637219671739],
					[61.104797287821469, 3.5246637208955027],
					[61.104797286852829, 3.5246637192039993],
					[61.104588987116607, 3.5245637203266078],
					[61.104588986147967, 3.5245637186351164],
					[61.104588984285684, 3.5245637170985842],
					[61.104588983392041, 3.5245637172535464],
					[61.104497296601046, 3.5245220249335381],
					[61.104489010148427, 3.5245165335079194],
					[61.104191810003343, 3.5243220334735765],
					[61.104191806278791, 3.5243220304005747],
					[61.10419179987332, 3.5243220277924672],
					[61.104191796148775, 3.5243220247194489],
					[61.104136203158866, 3.5242915272228479],
					[61.104041815929691, 3.5242304365028727],
					[61.103836222562471, 3.5240692428683333],
					[61.103836223456121, 3.5240692427133609],
					[61.103836221593852, 3.5240692411768921],
					[61.103836221593852, 3.5240692411768921],
					[61.103752922102025, 3.5240054423365277],
					[61.103691823747575, 3.5239581431403644],
					[61.10359182792309, 3.5238776459080614],
					[61.10349733011688, 3.5237970473036042],
					[61.10349732728595, 3.5237970440757564],
					[61.10349732088045, 3.523797041467791],
					[61.103497318049541, 3.5237970382399362],
					[61.103455723392976, 3.5237665411771508],
					[61.103244528094024, 3.5235915464314389],
					[61.103244527200381, 3.5235915465864216],
					[61.103244526231748, 3.5235915448950257],
					[61.103244526156736, 3.5235915430486542],
					[61.103144534280318, 3.5235109506466675],
					[61.103108441242512, 3.5234748591428726],
					[61.103108440273843, 3.5234748574514918],
					[61.103108437442934, 3.5234748542237049],
					[61.103108434686995, 3.523474852842289],
					[61.102797335423539, 3.5231887531475183],
					[61.102730738179474, 3.5231276565695118],
					[61.102700140952408, 3.5230970591373754],
					[61.102664042971334, 3.5230609601361103],
					[61.102436245248427, 3.5228220625942157],
					[61.102436244279744, 3.5228220609028931],
					[61.102436242417454, 3.5228220593665611],
					[61.102436240630183, 3.5228220596765514],
					[61.102347345280606, 3.522733162010153],
					[61.102269548318901, 3.5226470662450295],
					[61.102269543625638, 3.5226470614810932],
					[61.102269535132805, 3.5226470517981783],
					[61.1022695295459, 3.5226470471892437],
					[61.102250142272375, 3.5226304592986208],
					[61.10190295307806, 3.5222192713679048],
					[61.101819553757181, 3.5221192716494687],
					[61.101819550926209, 3.522119268421938],
					[61.101819545264263, 3.5221192619668567],
					[61.101819540646019, 3.52211925904933],
					[61.10180574086224, 3.5221054582694853],
					[61.101791843562232, 3.5220915609992876],
					[61.101597348098906, 3.5218804653809168],
					[61.101455747946446, 3.5217248658504046],
					[61.101455746977784, 3.521724864159161],
					[61.101455746977784, 3.521724864159161],
					[61.101355751861519, 3.521616569890722],
					[61.101294556860942, 3.5215387754066123],
					[61.1012945559673, 3.521538775561639],
					[61.101294554998631, 3.5215387738704114],
					[61.101294554029948, 3.5215387721791691],
					[61.101008453930362, 3.5211915731631649],
					[61.101008451024327, 3.5211915680895465],
					[61.101008445362353, 3.5211915616348062],
					[61.101008440744089, 3.5211915587174838],
					[61.100994545145547, 3.5211776618761061],
					[61.100911248432077, 3.5210859661908054],
					[61.100911245601061, 3.5210859629634585],
					[61.100911240907799, 3.5210859581999281],
					[61.100911239045459, 3.5210859566637738],
					[61.100833439102736, 3.5210109557590257],
					[61.100833431653498, 3.5210109496144075],
					[61.100833416754988, 3.5210109373251894],
					[61.10083340930575, 3.52101093118057],
					[61.100722311346374, 3.520938734609528],
					[61.100564012829601, 3.5208304350276824],
					[61.10056401193598, 3.5208304351827193],
					[61.100486211597271, 3.5207776348795958],
					[61.100486210628596, 3.5207776331884308],
					[61.100486207872635, 3.5207776318073534],
					[61.100486206010331, 3.5207776302712164],
					[61.100464033311546, 3.5207637475501441],
					[61.100116853384378, 3.5203526707642339],
					[61.10011685152206, 3.520352669228155],
					[61.10011685062841, 3.5203526693832088],
					[61.100116849659706, 3.5203526676920838],
					[61.100016867276643, 3.5202387873370649],
					[61.100014079322747, 3.5202332100854163],
					[61.100014063155264, 3.5202331887210452],
					[61.100014026352177, 3.5202331467675387],
					[61.100014004973033, 3.5202331300258209],
					[61.099686204753802, 3.5200304305869099],
					[61.09968620378509, 3.5200304288958173],
					[61.099686201029158, 3.5200304275148113],
					[61.099686200135508, 3.5200304276698708],
					[61.099666801162762, 3.5200192288307921],
					[61.099625104033052, 3.5199942294152886],
					[61.099566805328664, 3.5199581296865912],
					[61.099566804435057, 3.5199581298416467],
					[61.099383404219864, 3.5198470290817361],
					[61.099383380384957, 3.5198470183441763],
					[61.099383332121683, 3.5198470044086929],
					[61.099383306724661, 3.5198469995196708],
					[61.099219406958859, 3.5198414997552843],
					[61.099147208141673, 3.5198387004833083],
					[61.099147206354402, 3.5198387007934229],
					[61.099147201811171, 3.5198386997225959],
					[61.0991472000239, 3.5198387000327145],
					[61.099122260024842, 3.5198386993881727],
					[61.098850108791254, 3.5196609328907589],
					[61.098850087637231, 3.5196609216883301],
					[61.098850043767051, 3.519660905131913],
					[61.098850020232369, 3.5196609017790812],
					[61.098769419945626, 3.5196526001458306],
					[61.098769418158355, 3.5196526004559532],
					[61.098769413615123, 3.5196525993851613],
					[61.09876941182786, 3.5196525996952812],
					[61.098672264601966, 3.5196470038113072],
					[61.098583411365382, 3.5195887334725557],
					[61.09832511244165, 3.5194137346368515],
					[61.098325110654358, 3.5194137349469874],
					[61.098275111373866, 3.5193804343738466],
					[61.098275092082119, 3.5193804247077325],
					[61.098275052680101, 3.5193804073766355],
					[61.098275032719918, 3.5193804034037903],
					[61.098225066039646, 3.5193720072679904],
					[61.098044495250839, 3.5192748236514957],
					[61.098044482439718, 3.5192748184382192],
					[61.098044455923855, 3.5192748081666974],
					[61.098044443187803, 3.5192748047994744],
					[61.097980642919872, 3.5192609045312127],
					[61.097980642026236, 3.5192609046862797],
					[61.097980642026236, 3.5192609046862797],
					[61.097980641132615, 3.5192609048413446],
					[61.097875041422917, 3.51923870483219],
					[61.097875039560563, 3.5192387032962849],
					[61.097875038666956, 3.5192387034513497],
					[61.097875037773306, 3.5192387036064194],
					[61.0977750483477, 3.5192192055813316],
					[61.097747257453292, 3.5192109085473549],
					[61.09774725201644, 3.5192109076317202],
					[61.097747241067651, 3.5192109039543977],
					[61.097747235630798, 3.5192109030387613],
					[61.097488935627119, 3.5191637028361167],
					[61.097427837403593, 3.5191526023112369],
					[61.097327850724554, 3.5191331061415276],
					[61.097252862970016, 3.5191081102685917],
					[61.09725285932042, 3.5191081090428504],
					[61.097252850158881, 3.5191081050554791],
					[61.097252844722028, 3.519108104139872],
					[61.096988947039733, 3.5190470063203287],
					[61.096977849434118, 3.5190442063923975],
					[61.096977847646862, 3.5190442067025285],
					[61.096977844890915, 3.5190442053217188],
					[61.096977843103659, 3.5190442056318512],
					[61.096877842734088, 3.5190220056568431],
					[61.096877832753989, 3.519022003670591],
					[61.096877811081612, 3.5190220018542391],
					[61.09687780020792, 3.5190220000230474],
					[61.096875037388592, 3.5190219998132339],
					[61.096744471883341, 3.5189720134245954],
					[61.096744464584162, 3.518972010973175],
					[61.096744451848096, 3.5189720076061684],
					[61.096744445442539, 3.5189720049996862],
					[61.096527845260063, 3.5189220055601527],
					[61.096527844366413, 3.5189220057152228],
					[61.096527843397723, 3.5189220040243234],
					[61.096477849611283, 3.5189109069421889],
					[61.096430667713307, 3.5188970112356186],
					[61.096219480080798, 3.5188054176687076],
					[61.096219473600165, 3.5188054132163482],
					[61.096219462651348, 3.5188054095393193],
					[61.096219458108138, 3.5188054084687139],
					[61.096080658443569, 3.5187637085852272],
					[61.096080656656312, 3.5187637088953627],
					[61.09608065479393, 3.5187637073595726],
					[61.096080653900323, 3.5187637075146374],
					[61.095980675220879, 3.5187359133818861],
					[61.095938993923106, 3.5187137244383848],
					[61.095658394306248, 3.5185637243164862],
					[61.095658376876806, 3.5185637161873573],
					[61.095658341349491, 3.5185637056219323],
					[61.095658322282944, 3.5185637014947733],
					[61.095633322397575, 3.5185609013841215],
					[61.095633320610304, 3.5185609016942667],
					[61.095633317854364, 3.5185609003135667],
					[61.095633316960708, 3.518560900468644],
					[61.095536173155004, 3.5185526044998965],
					[61.095352917641186, 3.5184192389799049],
					[61.095191818568651, 3.5182998384279016],
					[61.095191805682418, 3.5182998313695322],
					[61.095191778047663, 3.5182998157170493],
					[61.095191763449236, 3.5182998108147197],
					[61.095183387543543, 3.5182970185636453],
					[61.095086216572533, 3.5182331374501521],
					[61.095036223264763, 3.5181942417942262],
					[61.095036222371121, 3.5181942419493084],
					[61.094864023949221, 3.5180609431611689],
					[61.094739025382417, 3.5179609431027523],
					[61.09473902359516, 3.5179609434129189],
					[61.094739020764102, 3.5179609401864371],
					[61.094739019870453, 3.517960940341522],
					[61.094661220115377, 3.5179026405523821],
					[61.094639028962348, 3.5178859472963961],
					[61.094291837242942, 3.5175609537464481],
					[61.094291804914661, 3.5175609333329785],
					[61.094291737209218, 3.517560906047259],
					[61.094291700044742, 3.5175608994851708],
					[61.094288946237818, 3.5175608996861101],
					[61.094191834203095, 3.5175137438235664],
					[61.094080763969671, 3.5173554850287276],
					[61.094080760169852, 3.5173554801116476],
					[61.09408075167665, 3.5173554704325904],
					[61.094080746983266, 3.5173554656706134],
					[61.09386124689167, 3.5171165643030271],
					[61.093861243091872, 3.5171165593860225],
					[61.093861234673746, 3.5171165515528959],
					[61.093861230055403, 3.5171165486367797],
					[61.09384183566663, 3.5170998523381956],
					[61.093825143995439, 3.5170831609683759],
					[61.093741851428518, 3.5169942682520685],
					[61.093394556175255, 3.5165665743251697],
					[61.093330756786656, 3.5164859756482807],
					[61.093330744643751, 3.5164859647443754],
					[61.093330720282921, 3.5164859410907652],
					[61.093330707246437, 3.516485930341966],
					[61.093300148080189, 3.5164665572446689],
					[61.093269573338809, 3.5164138008649224],
					[61.093269566632806, 3.5164137908761215],
					[61.093269556051887, 3.5164137741247039],
					[61.093269548602471, 3.5164137679825549],
					[61.093058450779068, 3.5161776694834961],
					[61.092950153447831, 3.5160498714183288],
					[61.092950151585477, 3.5160498698828322],
					[61.092950150616723, 3.5160498681922134],
					[61.092950149723109, 3.5160498683473378],
					[61.092850163300909, 3.5159359838035322],
					[61.092766873312399, 3.5157916005172498],
					[61.092766873237302, 3.5157915986714952],
					[61.09276687234366, 3.5157915988266293],
					[61.092766871374927, 3.515791597136023],
					[61.092577970981786, 3.5154776979249132],
					[61.092577969937928, 3.5154776943886064],
					[61.09257796613808, 3.5154776894719904],
					[61.092577964200608, 3.515477686090803],
					[61.09250296411048, 3.5153692865682795],
					[61.092502964035369, 3.5153692847225586],
					[61.092502963141726, 3.5153692848777029],
					[61.092502962173, 3.5153692831871219],
					[61.092408461633006, 3.5152387823859401],
					[61.092408445615071, 3.5152387647205208],
					[61.092408410230021, 3.5152387355474084],
					[61.092408390787831, 3.5152387221940113],
					[61.092402936577123, 3.5152359441639001],
					[61.092055768176799, 3.5147220913226991],
					[61.092019570185606, 3.5146637944760535],
					[61.092019555061214, 3.5146637766559823],
					[61.092019521237958, 3.5146637416364648],
					[61.092019501795662, 3.5146637282835838],
					[61.091958448352891, 3.5146276542328576],
					[61.091908474024656, 3.5145388018175296],
					[61.091908469180886, 3.5145387933649106],
					[61.091908457781273, 3.5145387786156701],
					[61.091908452937503, 3.5145387701630528],
					[61.091705753329379, 3.5142998709702575],
					[61.091705752360603, 3.5142998692797551],
					[61.091705751466975, 3.5142998694349243],
					[61.091611250778641, 3.51419156979322],
					[61.0915112673446, 3.5140748865310507],
					[61.091391878047432, 3.513847107551531],
					[61.091266877651876, 3.5135999091864356],
					[61.091266873776853, 3.5135999024245836],
					[61.091266864089199, 3.5135998855199713],
					[61.091266858351773, 3.5135998772228465],
					[61.091164057825281, 3.5134665788465647],
					[61.091164057750149, 3.5134665770009295],
					[61.091164055887738, 3.5134665754656682],
					[61.091164054919005, 3.5134665737752164],
					[61.091080754833271, 3.5133637734979448],
					[61.091080754833271, 3.5133637734979448],
					[61.091080752895735, 3.5133637701170626],
					[61.091080752002128, 3.5133637702722509],
					[61.09106406485455, 3.5133442859632962],
					[61.090716876660785, 3.5127138074421471],
					[61.09069187886373, 3.512663811129443],
					[61.090691874913482, 3.5126638025222228],
					[61.09069186530089, 3.5126637874638056],
					[61.090691859638561, 3.512663781012622],
					[61.090619560086353, 3.5125665807393029],
					[61.090619559117592, 3.5125665790488907],
					[61.090619557180034, 3.5125665756681097],
					[61.090619556286399, 3.5125665758233184],
					[61.090508455739048, 3.5124276750680843],
					[61.090508452907883, 3.5124276718425307],
					[61.090508446276758, 3.5124276637010285],
					[61.090508443445579, 3.5124276604754772],
					[61.090502942642267, 3.5124220604426166],
					[61.090502928636653, 3.5124220480060386],
					[61.09050289995735, 3.5124220288248451],
					[61.090502885133319, 3.5124220183890649],
					[61.09027228598368, 3.5123137197069725],
					[61.090202886996146, 3.5122804192627712],
					[61.09020288610251, 3.5122804194179862],
					[61.090202884240114, 3.5122804178828209],
					[61.090202883346471, 3.5122804180380398],
					[61.090172345852636, 3.512266546624911],
					[61.089894578312297, 3.5117249084208795],
					[61.089894570712346, 3.5117248985893483],
					[61.089894557224518, 3.5117248767702609],
					[61.089894548730953, 3.511724867093958],
					[61.089825148866737, 3.511647067700288],
					[61.089825148791576, 3.511647065854735],
					[61.089825147822765, 3.5116470641644182],
					[61.089825146929158, 3.5116470643196451],
					[61.089725146792745, 3.5115387636438249],
					[61.089725144930341, 3.5115387621087266],
					[61.089725140236737, 3.5115387573482502],
					[61.089725138374313, 3.5115387558131692],
					[61.089669538459376, 3.5114859557821818],
					[61.089669536596958, 3.5114859542471075],
					[61.089669533840919, 3.5114859528672686],
					[61.089669532872122, 3.5114859511769598],
					[61.08956403306123, 3.5113915501470876],
					[61.089564014587459, 3.511391538487473],
					[61.08956397495907, 3.5113915156339601],
					[61.089563953879576, 3.5113915062856083],
					[61.089375054186704, 3.5113387086942782],
					[61.089297255065098, 3.5113165071835759],
					[61.08929725327787, 3.5113165074940467],
					[61.089297249628224, 3.5113165062694671],
					[61.089297248734617, 3.5113165064247012],
					[61.08927511210932, 3.5113109214564151],
					[61.088927954220189, 3.5108970715947079],
					[61.088833454325282, 3.5107831723566982],
					[61.08883341908971, 3.5107831468827091],
					[61.088833342889465, 3.5107831099399176],
					[61.088833300212677, 3.5107831006271071],
					[61.088828000390556, 3.5107830995159897],
					[61.088828000261095, 3.5107805997830925],
					[61.088827990339155, 3.5107805550429605],
					[61.088827951136309, 3.5107804763601389],
					[61.088827921780222, 3.5107804405719469],
					[61.088516825734516, 3.510541543876633],
					[61.088480728975917, 3.5105109473695801],
					[61.08848072428227, 3.5105109426094381],
					[61.088480712214142, 3.5105109335548903],
					[61.088480705808436, 3.5105109309507059],
					[61.08845841696629, 3.5104970375830034],
					[61.088383425946212, 3.5104359442963102],
					[61.088383423115026, 3.5104359410711434],
					[61.088383416709299, 3.5104359384669994],
					[61.088383413878084, 3.5104359352418197],
					[61.088141813699067, 3.5102692356747189],
					[61.088141807218179, 3.5102692312251764],
					[61.08814179336278, 3.5102692224813272],
					[61.088141785988299, 3.5102692181870219],
					[61.088036186044292, 3.5102192184269794],
					[61.088036184257064, 3.5102192187374945],
					[61.088036182394596, 3.5102192172025761],
					[61.088036180607361, 3.5102192175130913],
					[61.087936181404132, 3.5101748166487159],
					[61.087936171348751, 3.5101748128202379],
					[61.087936150419559, 3.5101748071639975],
					[61.087936138576957, 3.5101748036460552],
					[61.087852845056787, 3.5101581044402095],
					[61.087788974968703, 3.5101415134673819],
					[61.087586198688655, 3.510027626737279],
					[61.087486208866551, 3.5099692329302687],
					[61.087436216875695, 3.5099331388550103],
					[61.087436202945106, 3.5099331282660811],
					[61.087436172703804, 3.5099331149356434],
					[61.087436157136302, 3.5099331083480636],
					[61.087277856812932, 3.5098859075248132],
					[61.087277855919339, 3.5098859076800735],
					[61.087136158781242, 3.5098442097500819],
					[61.087075061189104, 3.5098248093549151],
					[61.087075057614634, 3.5098248099759686],
					[61.087075053964966, 3.5098248087516368],
					[61.087075052102513, 3.5098248072167824],
					[61.087044457859797, 3.5098165097084166],
					[61.087036164172943, 3.5098137097257096],
					[61.087036157842441, 3.5098137089671497],
					[61.087036146893425, 3.5098137052941656],
					[61.087036141456529, 3.5098137043803606],
					[61.086772243006209, 3.5097581053532028],
					[61.086688944604902, 3.5097387045265704],
					[61.086688942817659, 3.5097387048370994],
					[61.086688939168013, 3.5097387036127876],
					[61.086688937380785, 3.5097387039233148],
					[61.086630638416658, 3.509727604347511],
					[61.086588940974707, 3.5097192049122552],
					[61.08627224635805, 3.5096498049221965],
					[61.086238947681672, 3.5096415056035357],
					[61.086238944925618, 3.5096415042239908],
					[61.086238936807852, 3.5096415037760234],
					[61.086238933158192, 3.5096415025517405],
					[61.08622223582335, 3.5096387039334287],
					[61.086138939422483, 3.5096220035855192],
					[61.086041741377805, 3.5096026043780517],
					[61.085900043631852, 3.5095720055640376],
					[61.085791745096664, 3.5095470051399613],
					[61.085791734222845, 3.5095470033125014],
					[61.085791710763161, 3.5095470018133947],
					[61.085791699889342, 3.509546999985933],
					[61.085780621533523, 3.5095469995306892],
					[61.085694444419374, 3.5095276055672797],
					[61.085694440769679, 3.509527604343015],
					[61.085694436226419, 3.5095276032740217],
					[61.085694432651906, 3.5095276038950773],
					[61.08554724026181, 3.509502604392861],
					[61.085341746771498, 3.5094526062228635],
					[61.085341744909051, 3.509452604688089],
					[61.085341741259406, 3.5094526034638549],
					[61.085341738578514, 3.5094526039296468],
					[61.085286139403905, 3.509441504447333],
					[61.085244439192515, 3.5094331042744762],
					[61.085244438298936, 3.5094331044297351],
					[61.085244437330111, 3.5094331027397221],
					[61.085052842890242, 3.509397004802576],
					[61.084897250968197, 3.5093581065802466],
					[61.084797253268732, 3.5093304071781866],
					[61.084797239714057, 3.5093304058166268],
					[61.084797213423137, 3.5093304010929751],
					[61.084797199868461, 3.509330399731394],
					[61.084786126055953, 3.5093304004026629],
					[61.0845444508683, 3.5092665076664478],
					[61.084544449899504, 3.5092665059764738],
					[61.084447252168388, 3.509241507610505],
					[61.084347253889639, 3.5092137067889104],
					[61.084347252102397, 3.5092137070994376],
					[61.084347247559137, 3.5092137060305246],
					[61.084347246665494, 3.5092137061857906],
					[61.084277847097709, 3.5091970052145309],
					[61.084038958377946, 3.5091387085919585],
					[61.084000067898884, 3.5091248128348251],
					[61.083900067927033, 3.5090887121150449],
					[61.083900064277358, 3.509088710890901],
					[61.083900056978038, 3.5090887084425959],
					[61.083900053328342, 3.5090887072184582],
					[61.083758356348739, 3.5090498075737768],
					[61.083550058813259, 3.5089859096261975],
					[61.08351396231398, 3.5089748104489806],
					[61.083450071377662, 3.5089526135462883],
					[61.083225077095825, 3.5088609142995448],
					[61.083166803244609, 3.5088359263435818],
					[61.083100126018181, 3.5087831446026252],
					[61.083002927805765, 3.5087026456932953],
					[61.083002910225446, 3.5087026338822227],
					[61.083002871640673, 3.5087026145714391],
					[61.083002852423469, 3.508702606761203],
					[61.082930687857107, 3.5086831174377857],
					[61.082808418349529, 3.5085942375126056],
					[61.082652917758146, 3.5084804387217607],
					[61.082569518197808, 3.5084192385045512],
					[61.082569517228961, 3.5084192368147105],
					[61.082569512685652, 3.5084192357459481],
					[61.08256951082322, 3.5084192342113756],
					[61.082552910657348, 3.5084081339118205],
					[61.082552895014594, 3.5084081254807673],
					[61.082552862017138, 3.5084081107743073],
					[61.082552843768745, 3.5084081046541922],
					[61.082333385325029, 3.5083581133186015],
					[61.082205718685522, 3.5082637391607663],
					[61.082108419153514, 3.5081915396695154],
					[61.082108410810143, 3.5081915336866296],
					[61.082108393304978, 3.5081915237212433],
					[61.082108384143162, 3.508191519738733],
					[61.081988984351348, 3.5081359190117456],
					[61.08175568370693, 3.5080276188572528],
					[61.08175568370693, 3.5080276188572528],
					[61.081719499425638, 3.5080109249584299],
					[61.081658419053859, 3.5079692395111008],
					[61.081550153995522, 3.5078831664306938],
					[61.081414074266078, 3.5076471010647867],
					[61.08131127414628, 3.5074666018489822],
					[61.081311273177441, 3.507466600159233],
					[61.081214073404041, 3.507297100627258],
					[61.081214047922508, 3.5072970716095613],
					[61.081213989292927, 3.5072970241970398],
					[61.081213955326497, 3.5072970078025616],
					[61.081136155154724, 3.5072748080232481],
					[61.080891804732687, 3.5072054223881302],
					[61.080864040692383, 3.5071776583133456],
					[61.080764041155902, 3.5070776596887816],
					[61.08076402990595, 3.5070776486372868],
					[61.080764004875675, 3.5070776306902469],
					[61.080763991913742, 3.507077621794374],
					[61.08053899255151, 3.5069609226236196],
					[61.080497363658154, 3.5069387608104363],
					[61.080416888648244, 3.5067027346156698],
					[61.080416853402966, 3.506702686857337],
					[61.080416757975449, 3.5067026198295022],
					[61.080416699580425, 3.5067026002494224],
					[61.080414099643583, 3.5067026006040209],
					[61.08041409977745, 3.5066999992888719],
					[61.080414099175663, 3.5066999845289262],
					[61.080414093278328, 3.5066999502506362],
					[61.080414089026853, 3.5066999342669374],
					[61.080319588971292, 3.5064249344153602],
					[61.080319574363401, 3.5064249072253357],
					[61.080319537255562, 3.5064248579331081],
					[61.080319514755608, 3.5064248358308903],
					[61.080264018197944, 3.5063859378659448],
					[61.079966820690025, 3.5061609413909154],
					[61.079966820614793, 3.506160939545937],
					[61.0799668197212, 3.5061609397012545],
					[61.079933453558169, 3.5061359644563042],
					[61.079872373671108, 3.5060277019798285],
					[61.079872346251626, 3.506027669585067],
					[61.079872280397481, 3.5060276215744639],
					[61.079872241887642, 3.5060276041136187],
					[61.079741884062052, 3.5059998354069175],
					[61.079716899123966, 3.5057638796826347],
					[61.079716899048726, 3.5057638778376878],
					[61.079716897929387, 3.5057638724581701],
					[61.079716897929387, 3.5057638724581701],
					[61.07970019862055, 3.5056471778119622],
					[61.079658498850613, 3.5051110845641835],
					[61.079655800054958, 3.5050721859263243],
					[61.079655797816265, 3.5050721751673688],
					[61.079655794232465, 3.505072153494095],
					[61.079655792069019, 3.505072144580097],
					[61.079586291615044, 3.5048360436980097],
					[61.079525191820537, 3.5046277451751382],
					[61.079430793176044, 3.5042888476769867],
					[61.079391892880679, 3.504141649077082],
					[61.079391892730165, 3.5041416453872389],
					[61.079391890717176, 3.5041416401632222],
					[61.079391889673026, 3.5041416366287397],
					[61.079314089979995, 3.5039055370791181],
					[61.079314086998096, 3.5039055301655964],
					[61.079314081927947, 3.5039055161831376],
					[61.079314079021351, 3.5039055111145285],
					[61.079311285304954, 3.5038999230645893],
					[61.079083490380683, 3.5032249365443411],
					[61.079077989800787, 3.5032082365861021],
					[61.079077988831898, 3.5032082348966025],
					[61.079077986818859, 3.503208229672683],
					[61.079077985774724, 3.5032082261382786],
					[61.079036289836566, 3.5031027379023287],
					[61.079002993960422, 3.5029749499079772],
					[61.079002992991526, 3.5029749482184895],
					[61.079002991947391, 3.502974944684083],
					[61.079002990903199, 3.5029749411497013],
					[61.07898349171311, 3.5029110433062209],
					[61.078894592858539, 3.5025860486311213],
					[61.078816894111604, 3.5022777524833759],
					[61.078758493825916, 3.5020416519200563],
					[61.078758493825916, 3.5020416519200563],
					[61.078639094228137, 3.5015693516692377],
					[61.078639093183924, 3.5015693481349439],
					[61.078639092139746, 3.5015693446006275],
					[61.078639091095539, 3.5015693410663298],
					[61.078627993013022, 3.5015332463692492],
					[61.078583494575618, 3.5013471526284392],
					[61.078583493682018, 3.5013471527838855],
					[61.078583493606729, 3.5013471509390208],
					[61.078583493606729, 3.5013471509390208],
					[61.078541894156878, 3.5011860520841669],
					[61.078525195237773, 3.5011138544235445],
					[61.078525195237773, 3.5011138544235445],
					[61.07852519419356, 3.5011138508892614],
					[61.07852519419356, 3.5011138508892614],
					[61.078389094026377, 3.5005832504674244],
					[61.078347394160041, 3.5004166509748433],
					[61.078347394084751, 3.500416649129992],
					[61.078347393115855, 3.5004166474406126],
					[61.078347392146917, 3.5004166457512409],
					[61.07828079261968, 3.5001805476689829],
					[61.078197393006157, 3.4998666496376365],
					[61.078197392930839, 3.4998666477927971],
					[61.078197392930839, 3.4998666477927971],
					[61.078097395694435, 3.4995055586524466],
					[61.078094597729695, 3.4994860726608206],
					[61.0780945973531, 3.4994860634366458],
					[61.078094593176118, 3.499486049299966],
					[61.07809459198122, 3.4994860420761227],
					[61.078086291547585, 3.4994582424584104],
					[61.078086290653999, 3.4994582426139051],
					[61.07808629147226, 3.4994582406135804],
					[61.07808628960975, 3.4994582390797428],
					[61.078019590301416, 3.4992499382637283],
					[61.078019590301416, 3.4992499382637283],
					[61.078019590226099, 3.4992499364189036],
					[61.078019590226099, 3.4992499364189036],
					[61.077786289615062, 3.498555536209532],
					[61.077786269267406, 3.4985555007343963],
					[61.077786215544606, 3.4985554413410282],
					[61.077786183956746, 3.4985554171117448],
					[61.07775008525563, 3.4985387190326001],
					[61.077655743028892, 3.4984942468326543],
					[61.077555775358753, 3.4983166034503967],
					[61.077447376237146, 3.498116604717918],
					[61.077447368636271, 3.4981165948934243],
					[61.077447354252826, 3.4981165732441024],
					[61.077447344864765, 3.4981165637306657],
					[61.077305746027072, 3.4979665647281641],
					[61.077208461334372, 3.4978609806531442],
					[61.077064071981916, 3.4976165985917018],
					[61.077064052086058, 3.4976165741865826],
					[61.077064005371597, 3.4976165321550012],
					[61.07706397862831, 3.4976165163733106],
					[61.076947279391952, 3.4975665161491798],
					[61.076858381419363, 3.4975276175790357],
					[61.076758410744624, 3.4974831315189978],
					[61.076650135698429, 3.4973831534073407],
					[61.076650111635892, 3.4973831371593982],
					[61.076650062025102, 3.4973831123535732],
					[61.076650034614246, 3.497383102262035],
					[61.07660283494662, 3.497374803703531],
					[61.076602834053041, 3.4973748038590777],
					[61.076602832190495, 3.4973748023254339],
					[61.076411131862564, 3.4973442034620259],
					[61.076411123669445, 3.4973442011724951],
					[61.076411108327477, 3.497344200127348],
					[61.076411100209732, 3.4973441996825385],
					[61.076397215677503, 3.4973441994694303],
					[61.076314016037308, 3.4973304165840031],
					[61.075966865037344, 3.4968276861523777],
					[61.075966864068384, 3.4968276844632231],
					[61.075966862205824, 3.4968276829296392],
					[61.075966861236886, 3.4968276812404966],
					[61.075889060630857, 3.496722081188727],
					[61.075889030754546, 3.4967220548068108],
					[61.075888963411622, 3.4967220145112519],
					[61.075888925126776, 3.4967220025978625],
					[61.075866724862692, 3.4967192014538897],
					[61.075866704159125, 3.4967192013424131],
					[61.075866661115519, 3.4967192051199483],
					[61.075866639669066, 3.4967192088534191],
					[61.075680540213263, 3.4967776091306901],
					[61.075680539319684, 3.496777609286243],
					[61.07551664158612, 3.4968304088225803],
					[61.075497165936454, 3.4968359021102757],
					[61.075416685594881, 3.4968414997809063],
					[61.075416671297226, 3.4968415022697874],
					[61.075416640990134, 3.4968415094033176],
					[61.075416625874226, 3.4968415138924218],
					[61.07529162686739, 3.4968915132724949],
					[61.075111045041311, 3.496960907431427],
					[61.07506666328365, 3.4969692032965445],
					[61.075066662390036, 3.4969692034520947],
					[61.074966662051729, 3.4969887041097629],
					[61.07496665669013, 3.496988705043047],
					[61.074966646860496, 3.4969887067540744],
					[61.074966641574207, 3.4969887095320082],
					[61.074902744227558, 3.4970081083191382],
					[61.074774946450631, 3.4970442087201032],
					[61.074774940195461, 3.4970442098089114],
					[61.074774930441173, 3.4970442133645574],
					[61.074774925079531, 3.49704421429783],
					[61.07461662756004, 3.4971081130584252],
					[61.074519363840302, 3.497144199875974],
					[61.074516699938712, 3.4971441991213852],
					[61.074516682884898, 3.4971442002320443],
					[61.074516648928011, 3.4971442061425853],
					[61.074516632100242, 3.4971442127871089],
					[61.074222132206046, 3.4972498111493211],
					[61.07422213131246, 3.4972498113048536],
					[61.074222126919786, 3.4972498139271324],
					[61.074222126026164, 3.4972498140826711],
					[61.074166626270888, 3.4972720136213429],
					[61.074166623665413, 3.4972720159325439],
					[61.074166620984627, 3.4972720163991453],
					[61.074166619197385, 3.4972720167102196],
					[61.074141625341468, 3.4972831141863709],
					[61.074066629917724, 3.497310911956693],
					[61.074066628130531, 3.4973109122677544],
					[61.074066623737856, 3.4973109148900301],
					[61.074066621057028, 3.497310915356632],
					[61.074008225314486, 3.4973359148333092],
					[61.073963830369856, 3.4973526126187648],
					[61.073963826795406, 3.4973526132408974],
					[61.073963822402767, 3.4973526158631318],
					[61.073963818828318, 3.4973526164852617],
					[61.073769321987356, 3.4974387158612203],
					[61.073713825765289, 3.4974609140885859],
					[61.073713823978096, 3.4974609143996371],
					[61.073713821372614, 3.4974609167108062],
					[61.073713818691829, 3.4974609171773841],
					[61.073619319404848, 3.4975026174983559],
					[61.073594319344281, 3.4975137175118842],
					[61.073594315769888, 3.4975137181339786],
					[61.073594308696407, 3.4975137212227363],
					[61.073594306090925, 3.4975137235338751],
					[61.073505505822574, 3.4975609223764588],
					[61.073505504110699, 3.4975609245320638],
					[61.073505499718024, 3.4975609271542356],
					[61.073505498824439, 3.4975609273097534],
					[61.07341099942235, 3.4976165279746558],
					[61.07341099763515, 3.4976165282856928],
					[61.073410996741565, 3.4976165284412106],
					[61.073410995847929, 3.4976165285967333],
					[61.073269295812885, 3.4977026297947029],
					[61.073269294025685, 3.4977026301057372],
					[61.073247093811766, 3.4977165306120828],
					[61.073247088525491, 3.4977165333897244],
					[61.073247078103606, 3.497716542634115],
					[61.073247072817317, 3.4977165454117585],
					[61.073169277006976, 3.4977804416863765],
					[61.073074882055515, 3.4978498381930461],
					[61.073074881161936, 3.4978498383485537],
					[61.073074878556447, 3.4978498406596268],
					[61.0729220789922, 3.4979665403234526],
					[61.072922072069389, 3.4979665471010826],
					[61.072922059042014, 3.4979665586563407],
					[61.072922052937464, 3.4979665634339394],
					[61.072819253808866, 3.4980776631885817],
					[61.07276655481315, 3.4981331611900908],
					[61.072766554888489, 3.4981331630346064],
					[61.072719263766892, 3.4981831537505035],
					[61.07264987519553, 3.4982387431295541],
					[61.072649874377248, 3.4982387451295609],
					[61.072649872590056, 3.4982387454405557],
					[61.072649871696449, 3.4982387455960553],
					[61.0726167514349, 3.4982663972550396],
					[61.072600174931416, 3.4982388037724994],
					[61.072369577858872, 3.4977888097234739],
					[61.072369577783526, 3.4977888078789805],
					[61.072369576814602, 3.4977888061899916],
					[61.072369576814602, 3.4977888061899916],
					[61.07226957673776, 3.4975999051997921],
					[61.072269575844167, 3.4975999053553029],
					[61.072269573906276, 3.4975999019773392],
					[61.072269573830937, 3.4975999001328506],
					[61.072236276404226, 3.497541603954748],
					[61.072114078350154, 3.4973055080764741],
					[61.072114077456533, 3.4973055082319986],
					[61.072114077381194, 3.4973055063875207],
					[61.071922376668979, 3.4969416068324808],
					[61.071922376668979, 3.4969416068324808],
					[61.071822377028887, 3.4967527053322365],
					[61.071822375166327, 3.4967527037988488],
					[61.071822371215163, 3.4967526951986749],
					[61.07182236935261, 3.496752693665286],
					[61.071730773167005, 3.4966081996350007],
					[61.071605777173538, 3.4963721056110053],
					[61.071475177449564, 3.4961249072699969],
					[61.071380778164333, 3.4959416079487902],
					[61.071380773394864, 3.4959416013488434],
					[61.071380763780596, 3.4959415863044794],
					[61.07138075901112, 3.4959415797045179],
					[61.071175169395978, 3.4956720920357349],
					[61.071050177291809, 3.4954360067354555],
					[61.071050175353875, 3.4954360033577703],
					[61.071050170584378, 3.4954359967579496],
					[61.071050168646451, 3.4954359933802643],
					[61.071030773092367, 3.4954054996959725],
					[61.070933477249902, 3.495222105496818],
					[61.070933472405017, 3.4952220970526522],
					[61.070933461897077, 3.4952220821643079],
					[61.070933456233995, 3.4952220757201138],
					[61.070922358777317, 3.4952081778973647],
					[61.070583466651321, 3.4947498891222741],
					[61.070575172839, 3.4947359994232747],
					[61.070489074988529, 3.4945805026507464],
					[61.070489074019527, 3.4945805009619622],
					[61.070489071187957, 3.4945804977399617],
					[61.070489070143616, 3.4945804942067995],
					[61.070439070859088, 3.4944998967222061],
					[61.070141872209646, 3.4939970972056771],
					[61.070141870347058, 3.4939970956725097],
					[61.070141864608523, 3.4939970873843578],
					[61.07014186267056, 3.4939970840068395],
					[61.070114071690384, 3.4939581962551265],
					[61.070041878688471, 3.4938138103915528],
					[61.070041876750508, 3.4938138070140545],
					[61.070041872874533, 3.493813800259078],
					[61.070041870936556, 3.4938137968815983],
					[61.070033476121083, 3.4937999048032515],
					[61.069916879230213, 3.4935638105407922],
					[61.06969457913489, 3.4931166109704304],
					[61.069694579059487, 3.493116609126107],
					[61.069694578165915, 3.4931166092817265],
					[61.06959458038304, 3.4929221130773795],
					[61.069569582098225, 3.492866617834125],
					[61.069569581053848, 3.4928666143011222],
					[61.069569577177809, 3.492866607546365],
					[61.069569575164401, 3.4928666023246624],
					[61.069439074624313, 3.4926305048532922],
					[61.069247376259689, 3.4922805051416721],
					[61.069150176344088, 3.4920999051225596],
					[61.069061276175155, 3.4919332064843909],
					[61.069061276099731, 3.4919332046401093],
					[61.069061274161719, 3.4919332012628463],
					[61.069061273192723, 3.4919331995741967],
					[61.068925172971909, 3.4916971004462174],
					[61.068802973100269, 3.4914860008889947],
					[61.068702974768819, 3.4913138038264715],
					[61.068539086312612, 3.4909999246918995],
					[61.068466891176939, 3.4907638421329379],
					[61.068466891101508, 3.4907638402886771],
					[61.068355791260316, 3.4904055417041131],
					[61.06826129167564, 3.490086043776131],
					[61.068261287573186, 3.4900860314892967],
					[61.068261278625556, 3.4900860107597933],
					[61.068261273704934, 3.4900860004728926],
					[61.068250173754926, 3.4900666006832362],
					[61.068250166078066, 3.4900665890191975],
					[61.068250148043631, 3.4900665661582431],
					[61.068250138655067, 3.4900665566498437],
					[61.06800573863314, 3.4898304561896514],
					[61.068005737664095, 3.4898304545011443],
					[61.068005736770509, 3.4898304546568468],
					[61.067908438882462, 3.489738756628562],
					[61.067827941927298, 3.4896581583046489],
					[61.067827941033727, 3.4896581584603541],
					[61.067827941033727, 3.4896581584603541],
					[61.067811263231725, 3.4896415790822961],
					[61.067541877945089, 3.4891305082042745],
					[61.067464079873197, 3.4889749127316949],
					[61.067425180432132, 3.4888943135572892],
					[61.067425179387648, 3.488894310024659],
					[61.06742517655595, 3.4888943068034854],
					[61.067425175586912, 3.4888943051150174],
					[61.06736407623918, 3.4887805045864622],
					[61.067364074301082, 3.4887805012095741],
					[61.067364070424901, 3.488780494455781],
					[61.067364067593203, 3.4887804912346065],
					[61.067016868076294, 3.4882387925356606],
					[61.067016868000827, 3.4882387906915073],
					[61.06701686710722, 3.4882387908472552],
					[61.067016866138182, 3.4882387891588245],
					[61.066989071936696, 3.4881970985725879],
					[61.066916877229637, 3.4880582072305311],
					[61.066916875291533, 3.4880582038537149],
					[61.066916868583576, 3.4880581938790609],
					[61.066916865676397, 3.4880581888138673],
					[61.066850165539336, 3.4879581887358455],
					[61.066850164570248, 3.4879581870474592],
					[61.066850162632136, 3.4879581836706777],
					[61.066850161738536, 3.4879581838264286],
					[61.066719561648917, 3.4877776831235092],
					[61.066719555910026, 3.4877776748373508],
					[61.066719541751432, 3.4877776587323037],
					[61.066719534300844, 3.4877776526017867],
					[61.066569534383149, 3.4876415510934877],
					[61.066569534383149, 3.4876415510934877],
					[61.066569532595977, 3.4876415514049999],
					[61.066569533414068, 3.4876415494051227],
					[61.06647235941994, 3.4875554734000129],
					[61.066311275566903, 3.4872582043783367],
					[61.066311271690637, 3.4872581976249681],
					[61.066311263044504, 3.4872581842739696],
					[61.066311259243726, 3.4872581793647095],
					[61.066130770183307, 3.4870220920631176],
					[61.066125178656982, 3.4870110094697284],
					[61.066125175749754, 3.48701100440474],
					[61.066125169935333, 3.487010994274776],
					[61.066125167028112, 3.4870109892097871],
					[61.066114067245763, 3.4869942884274878],
					[61.066114064414052, 3.4869942852066105],
					[61.066114059719652, 3.4869942804531697],
					[61.066114057781512, 3.4869942770765232],
					[61.066025168961893, 3.4868804908925464],
					[61.065736276887705, 3.4863249080656371],
					[61.065736274874055, 3.4863249028449999],
					[61.065736270028651, 3.4863248944035714],
					[61.065736267121444, 3.486324889338702],
					[61.065677967610007, 3.486235992237853],
					[61.065583469176666, 3.4860859929352754],
					[61.065583452186161, 3.4860859736108054],
					[61.065583412919104, 3.4860859377406745],
					[61.06558339064258, 3.4860859211950328],
					[61.065577949466174, 3.4860831516823922],
					[61.06523078474725, 3.4853971217758377],
					[61.065227990365415, 3.4853888355739029],
					[61.065227986413561, 3.4853888269769371],
					[61.065227977616253, 3.4853888099388168],
					[61.065227972695297, 3.4853887996536259],
					[61.065133474747959, 3.4852249035900296],
					[61.065094575658762, 3.4851527053259916],
					[61.064786276277637, 3.4845777055674154],
					[61.064786276202128, 3.484577703723402],
					[61.064719575679888, 3.48445550538653],
					[61.064689077240985, 3.4843971061917225],
					[61.06468907634742, 3.4843971063475578],
					[61.064689074333707, 3.4843971011271972],
					[61.06468907336459, 3.4843970994390312],
					[61.064586273027196, 3.4842192998822461],
					[61.064586272058101, 3.484219298194084],
					[61.064586272058101, 3.484219298194084],
					[61.064522372300488, 3.4841109984908138],
					[61.064522356127775, 3.4841109771681804],
					[61.064522316784746, 3.4841109394576786],
					[61.064522296370683, 3.4841109244462629],
					[61.064338995816208, 3.4840109246014346],
					[61.064239049675123, 3.4839554549718286],
					[61.064014077602941, 3.4835166095794725],
					[61.064014075664709, 3.4835166062032448],
					[61.064014072681829, 3.4835165992949655],
					[61.06401407074361, 3.4835165959187355],
					[61.063975171225721, 3.4834526961831376],
					[61.06397517025664, 3.4834526944950297],
					[61.063975168318414, 3.4834526911188086],
					[61.063975168318414, 3.4834526911188086],
					[61.063891869610934, 3.4833248949489266],
					[61.063866872216636, 3.483283196359487],
					[61.063866871323029, 3.4832831965153557],
					[61.063866870353941, 3.4832831948272593],
					[61.063794574355995, 3.4831666009314182],
					[61.063491878331071, 3.4825832087455217],
					[61.063447378748933, 3.4824943108079824],
					[61.063447377779795, 3.4824943091199212],
					[61.063447376810679, 3.4824943074318755],
					[61.063447376810679, 3.4824943074318755],
					[61.063369580312489, 3.482347112981945],
					[61.063350183349634, 3.482302719114033],
					[61.063350182380518, 3.4823027174259806],
					[61.063350179397588, 3.4823027105179607],
					[61.063350176565777, 3.4823027072977588],
					[61.063008476992863, 3.4816499062227466],
					[61.063008473116355, 3.4816498994707601],
					[61.063008465438841, 3.4816498878106588],
					[61.063008459699589, 3.4816498795265671],
					[61.063000172214601, 3.4816387957056736],
					[61.062905780144455, 3.4814416130571084],
					[61.062905778206186, 3.4814416096811387],
					[61.062905774254098, 3.4814416010852951],
					[61.062905771346699, 3.4814415960213227],
					[61.062889073807419, 3.4814138025736083],
					[61.062552979211475, 3.480780510308529],
					[61.062522379714402, 3.4807166149669651],
					[61.062458480276035, 3.4805832140181296],
					[61.062458480200483, 3.480583212174265],
					[61.062458479231317, 3.4805832104863352],
					[61.062458478262187, 3.4805832087983797],
					[61.062405777974561, 3.4804805084397699],
					[61.062405777081004, 3.4804805085956994],
					[61.062405776111852, 3.4804805069077713],
					[61.062405776036279, 3.4804805050638965],
					[61.062111275808128, 3.4799360053069952],
					[61.062111275732555, 3.4799360034631519],
					[61.062027977141987, 3.4797832073792625],
					[61.062011278585466, 3.4797499096029139],
					[61.062011276722728, 3.4797499080709815],
					[61.062011274708837, 3.4797499028513585],
					[61.062011272770533, 3.4797498994755878],
					[61.061925172658185, 3.4795998996928343],
					[61.061925164011356, 3.4795998863457691],
					[61.061925143143355, 3.4795998602754361],
					[61.061925130997778, 3.4795998493960147],
					[61.061864032079619, 3.4795470491711473],
					[61.061664033022957, 3.4793692509508549],
					[61.061664032947363, 3.4793692491070418],
					[61.061566832704216, 3.4792831500547541],
					[61.061566816984289, 3.4792831397994024],
					[61.061566784650879, 3.4792831194446863],
					[61.061566768112975, 3.4792831111891163],
					[61.061536167690363, 3.4792720120027063],
					[61.061536165827619, 3.4792720104708224],
					[61.061536161284145, 3.4792720094068224],
					[61.061536158603396, 3.4792720098747076],
					[61.06128337873951, 3.4791942154732549],
					[61.061213998218392, 3.4791554249531722],
					[61.061213997324806, 3.4791554251091346],
					[61.061213993674905, 3.479155423889198],
					[61.06121399270576, 3.4791554222013681],
					[61.061113993227337, 3.4791026225806614],
					[61.061113984064782, 3.4791026186089522],
					[61.06111396484615, 3.4791026108214691],
					[61.061113954865618, 3.4791026088494821],
					[61.060988954796528, 3.4790665089552752],
					[61.060988953827362, 3.4790665072674662],
					[61.060988952040198, 3.4790665075793936],
					[61.06098895017746, 3.4790665060475452],
					[61.060763951748591, 3.4790081061596689],
					[61.060744454394062, 3.4790026088038246],
					[61.060744444337963, 3.4790026049881422],
					[61.0607444243769, 3.4790026010442832],
					[61.060744414396375, 3.4790025990723463],
					[61.060663914660296, 3.4789970009169271],
					[61.060500017162795, 3.4789831016777448],
					[61.060316718058061, 3.4789665018031433],
					[61.060316717088881, 3.4789665001153725],
					[61.060283321420641, 3.4789637015751498],
					[61.06021942610537, 3.4789554023060139],
					[61.060219392816244, 3.4789554025453984],
					[61.060219330341432, 3.4789554153064151],
					[61.06021930018656, 3.4789554261402875],
					[61.060080500427901, 3.4790359272522955],
					[61.060080498640751, 3.4790359275642118],
					[61.060080497747151, 3.4790359277201701],
					[61.059916608043054, 3.4791331220012935],
					[61.059866618774443, 3.4791554167634695],
					[61.059866617956466, 3.4791554187631273],
					[61.0598666169873, 3.4791554170753729],
					[61.059769317102912, 3.479199817417169],
					[61.059769301320834, 3.4791998275991287],
					[61.059769272437443, 3.4791998474951673],
					[61.059769259411709, 3.4791998590529731],
					[61.059733159255046, 3.479235958874126],
					[61.059733158361453, 3.4792359590300799],
					[61.059733155756319, 3.4792359613416282],
					[61.059733154862741, 3.4792359614975785],
					[61.059472056584362, 3.4795109593070133],
					[61.059435964225926, 3.479547053330267],
					[61.059416570244316, 3.4795637478866324],
					[61.05941656503402, 3.4795637525096486],
					[61.059416556400535, 3.4795637614437775],
					[61.059416551190239, 3.4795637660667924],
					[61.05939155116144, 3.4795915663069632],
					[61.059391548556256, 3.4795915686184622],
					[61.059391545208712, 3.4795915747732735],
					[61.059391542527976, 3.4795915752410886],
					[61.059319254760936, 3.4796831609550982],
					[61.059299898201857, 3.479699824690651],
					[61.059188833893984, 3.4797387104357203],
					[61.059188811038801, 3.479738723708345],
					[61.059188768084795, 3.4797387516294558],
					[61.059188749030689, 3.4797387698093547],
					[61.059119250968635, 3.4798192650981115],
					[61.058966553971722, 3.4799831633558949],
					[61.058966553078164, 3.4799831635118155],
					[61.058966552260138, 3.4799831655114013],
					[61.058966551366595, 3.4799831656673219],
					[61.058869251252581, 3.4800915655771214],
					[61.058869246193368, 3.4800915738872829],
					[61.058869235030265, 3.4800915869761875],
					[61.058869229971087, 3.4800915952863383],
					[61.058833129736435, 3.4801498952353058],
					[61.058833128842856, 3.4801498953912247],
					[61.058833127206839, 3.4801498993903657],
					[61.058833127206839, 3.4801498993903657],
					[61.058674828436232, 3.4804248986449866],
					[61.058585929596781, 3.4805692954687775],
					[61.058585928703181, 3.4805692956246843],
					[61.058585927960742, 3.4805692994678648],
					[61.058585927067156, 3.4805692996237716],
					[61.058535927152711, 3.4806554991512781],
					[61.058535924547556, 3.4806555014626195],
					[61.058535922987097, 3.4806555073053298],
					[61.058535921351059, 3.4806555113044237],
					[61.058516523156847, 3.4806943052803923],
					[61.058413725914193, 3.4808777027180104],
					[61.058413725096166, 3.4808777047175368],
					[61.058413723384554, 3.4808777068729597],
					[61.058413721672963, 3.4808777090283796],
					[61.058349823222031, 3.4810027072046572],
					[61.058169223262873, 3.4813416053610124],
					[61.058169223338425, 3.481341607204647],
					[61.058113723147414, 3.4814471070486004],
					[61.058063725256162, 3.4815416033035369],
					[61.058044226615444, 3.4815748989585931],
					[61.0580442250549, 3.4815749048011764],
					[61.058044219102058, 3.4815749132667451],
					[61.05804421664795, 3.4815749192652139],
					[61.057963717448935, 3.4817582203971837],
					[61.057963715661764, 3.4817582207089193],
					[61.057963715661764, 3.4817582207089193],
					[61.057894216683607, 3.4819193205251944],
					[61.057791517013513, 3.4821499182834272],
					[61.057791514559412, 3.4821499242818015],
					[61.057791509651153, 3.4821499362785389],
					[61.057791508166147, 3.4821499439646568],
					[61.057758109205487, 3.4822638428354931],
					[61.057699808958247, 3.482449940130945],
					[61.057699808215766, 3.4824499439739727],
					[61.057699805837153, 3.4824499518159038],
					[61.057699805019105, 3.4824499538153466],
					[61.057688707841727, 3.4824971424244175],
					[61.057613727235932, 3.4827054897843697],
					[61.057608159972368, 3.4827109577864408],
					[61.057608146203762, 3.4827109731849992],
					[61.057608121649501, 3.4827110108889605],
					[61.057608113393464, 3.4827110290396797],
					[61.057510940393911, 3.4829720577696031],
					[61.057505508969548, 3.4829748227140511],
					[61.057505486265043, 3.4829748396707538],
					[61.05750544376351, 3.4829748786474179],
					[61.057505425753526, 3.482974900355734],
					[61.057391526315278, 3.4831749013041873],
					[61.057391522149466, 3.4831749094576359],
					[61.0573915138934, 3.483174927608105],
					[61.05739150972758, 3.4831749357615363],
					[61.057388721061152, 3.4831832036954111],
					[61.057247042170907, 3.4833665775892868],
					[61.057247040459274, 3.4833665797444917],
					[61.057247037929564, 3.4833665838990857],
					[61.057247037111523, 3.4833665858984872],
					[61.05721374202313, 3.4834137765760991],
					[61.057158148640347, 3.4834776686563611],
					[61.057158147822271, 3.4834776706557427],
					[61.0571581469287, 3.4834776708115545],
					[61.057158146110623, 3.4834776728109533],
					[61.057116576028172, 3.4835276374032325],
					[61.057061069669615, 3.4835525949605688],
					[61.056880628291474, 3.4835276023187918],
					[61.056708328567119, 3.4835026013796959],
					[61.056708326779962, 3.4835026016913155],
					[61.05670832313006, 3.4835026004710268],
					[61.056708321342896, 3.4835026007826486],
					[61.056655621137693, 3.4834970012135416],
					[61.056655613019906, 3.4834970007723007],
					[61.056655595890746, 3.4834970000456318],
					[61.056655586879366, 3.4834969997602032],
					[61.056611086593669, 3.4834997998152963],
					[61.056611065223152, 3.4834998053982758],
					[61.056611020770497, 3.4834998187193809],
					[61.056610998581917, 3.4834998263017214],
					[61.05654440986293, 3.4835386636958354],
					[61.056389013758761, 3.483430435863736],
					[61.056389012789673, 3.4834304341760238],
					[61.056389011896066, 3.483430434331837],
					[61.056389011002508, 3.4834304344876443],
					[61.056347311467398, 3.4834026344528461],
					[61.056347302229476, 3.4834026286368682],
					[61.056347283829112, 3.4834026188484564],
					[61.056347273697583, 3.4834026131882929],
					[61.056263974116227, 3.4833692130662],
					[61.056263973222649, 3.48336921322201],
					[61.056263972329063, 3.4833692133778213],
					[61.056163972190951, 3.4833304131008798],
					[61.056163927876661, 3.4833304078311054],
					[61.056163841714913, 3.4833304135715197],
					[61.05616379904945, 3.4833304265810341],
					[61.056049898906636, 3.4833970268382886],
					[61.056049886698538, 3.4833970363936411],
					[61.056049863175929, 3.4833970553485161],
					[61.056049851936955, 3.483397066591555],
					[61.05596925327054, 3.4834859642030684],
					[61.055827663595288, 3.4836359521219697],
					[61.055813778712306, 3.4836470417584264],
					[61.055713782731289, 3.4837192384223807],
					[61.055713781837703, 3.483719238578181],
					[61.055713780126048, 3.4837192407332571],
					[61.055713779232477, 3.4837192408890543],
					[61.055655478961867, 3.4837637406220039],
					[61.05565547725022, 3.4837637427770747],
					[61.05565547456947, 3.4837637432444635],
					[61.055655473751393, 3.4837637452437402],
					[61.055447077872046, 3.4839331420163053],
					[61.055363782293313, 3.4839942394204804],
					[61.055333183378835, 3.4840165380644947],
					[61.055263783176379, 3.4840665383769127],
					[61.055263765833281, 3.4840665543968345],
					[61.05526373487244, 3.4840665895004661],
					[61.055263721330199, 3.4840666104276368],
					[61.055258120990061, 3.484077709092094],
					[61.055258117793301, 3.4840777189325305],
					[61.055258111173217, 3.484077733083021],
					[61.055258107976414, 3.4840777429234642],
					[61.055216518372596, 3.4842166094299434],
					[61.055149837944533, 3.4843081817707016],
					[61.05514983802005, 3.4843081836141709],
					[61.055149837126457, 3.484308183769949],
					[61.055149836308388, 3.4843081857691747],
					[61.055077637137344, 3.4844109831235026],
					[61.054913738591175, 3.4846387811285298],
					[61.05480814823224, 3.4847776692211654],
					[61.05480547888731, 3.4847804366986828],
					[61.054505638639917, 3.484933055402196],
					[61.054461240603338, 3.4848887580620511],
					[61.054461239634236, 3.484888756374398],
					[61.054461238740664, 3.4848887565301525],
					[61.054366862591266, 3.4847970801088826],
					[61.054364078120322, 3.4847916095863507],
					[61.054364037732981, 3.4847915683553556],
					[61.054363941855286, 3.4847915126613671],
					[61.054363886440441, 3.4847915000417808],
					[61.054161085849913, 3.4848054007407581],
					[61.05416105286271, 3.484805408347202],
					[61.05416099017306, 3.4848054378399644],
					[61.054160961213206, 3.4848054558837682],
					[61.054010960935983, 3.4849498564904282],
					[61.054010960935983, 3.4849498564904282],
					[61.054010959224307, 3.4849498586453214],
					[61.054010959224307, 3.4849498586453214],
					[61.053999859209142, 3.4849609591637565],
					[61.053977659100248, 3.4849831583334496],
					[61.05391395770323, 3.4850468591460317],
					[61.053883480853727, 3.4849832154798475],
					[61.053733482413186, 3.4846555175944718],
					[61.053733475629528, 3.4846555057811863],
					[61.053733462137743, 3.4846554839979631],
					[61.053733455354092, 3.4846554721846763],
					[61.053566855080838, 3.4844526727491201],
					[61.053566854111722, 3.4844526710615278],
					[61.053566853218165, 3.4844526712172867],
					[61.053566852249048, 3.4844526695296949],
					[61.053466876325871, 3.4843359965475256],
					[61.053455796189439, 3.4843055521785864],
					[61.053452998222575, 3.4842832752915855],
					[61.053452995906802, 3.4842832626996474],
					[61.053452989337131, 3.4842832341405723],
					[61.053452984340609, 3.4842832220159337],
					[61.05335298389388, 3.4840471229454293],
					[61.053250183704904, 3.4838055224377498],
					[61.053250172969257, 3.4838055020312337],
					[61.05325014531843, 3.4838054641519913],
					[61.053250130265894, 3.4838054482110539],
					[61.053119529680373, 3.4836942472054711],
					[61.053119528786794, 3.4836942473612522],
					[61.053119525955019, 3.4836942441419194],
					[61.053119525061412, 3.4836942442977037],
					[61.053019531156494, 3.4836137484072145],
					[61.052933436757129, 3.4835331541180903],
					[61.052933416343052, 3.4835331391121467],
					[61.052933371273539, 3.4835331154091653],
					[61.052933347436174, 3.4835331047130045],
					[61.052863946702473, 3.4835165044609373],
					[61.052863945808873, 3.4835165046167242],
					[61.052863944915309, 3.4835165047725076],
					[61.052669444990805, 3.4834720050875614],
					[61.052669444097212, 3.4834720052433501],
					[61.052669443203605, 3.483472005399137],
					[61.052569442724192, 3.4834498054845118],
					[61.052569392381365, 3.4834498068352966],
					[61.052569298189979, 3.4834498362516833],
					[61.052569255159526, 3.483449862318194],
					[61.052472068758675, 3.4835526482977937],
					[61.052444286363887, 3.4835720352097272],
					[61.052444282865089, 3.4835720376761592],
					[61.052444276761001, 3.4835720424532139],
					[61.052444275049368, 3.4835720446080645],
					[61.052219275392716, 3.4837526431094195],
					[61.052219273605552, 3.4837526434209676],
					[61.052219272787475, 3.4837526454200392],
					[61.052219272787475, 3.4837526454200392],
					[61.052124891307201, 3.4838304297031959],
					[61.052119312803597, 3.4838331193688874],
					[61.052119296127543, 3.4838331297016629],
					[61.052119263895548, 3.4838331557412605],
					[61.052119249157656, 3.4838331694490452],
					[61.051985949229447, 3.4839859687537973],
					[61.051985944912545, 3.4839859732191618],
					[61.05198593642978, 3.4839859858364379],
					[61.05198593218838, 3.4839859921450884],
					[61.051960932608587, 3.484024890728632],
					[61.051849834869842, 3.4841887885462985],
					[61.051802635200339, 3.4842581886464226],
					[61.051802629247277, 3.4842581971097695],
					[61.051802619203841, 3.4842582155681829],
					[61.051802614219874, 3.4842582257190293],
					[61.051769213925716, 3.4843416254878483],
					[61.051669214723816, 3.4845888238451694],
					[61.051635915812284, 3.4846693203483756],
					[61.051533125011609, 3.4848999018683298],
					[61.051502635165797, 3.4849442874568979],
					[61.051502634272182, 3.4849442876126377],
					[61.051394233858481, 3.4851054874143208],
					[61.051394233040384, 3.4851054894132898],
					[61.051394231404203, 3.4851054934112224],
					[61.051394229692534, 3.4851054955659362],
					[61.051349832370967, 3.4851776924976119],
					[61.051316533966677, 3.4852276887764169],
					[61.051316533148587, 3.485227690775373],
					[61.051316532254994, 3.4852276909311031],
					[61.051316532330496, 3.48522769277435],
					[61.051216612722428, 3.4853830650075652],
					[61.051061157932644, 3.4853359096978562],
					[61.050869458528744, 3.4852776082355068],
					[61.050816768358118, 3.4852609127246508],
					[61.050769506361156, 3.4852415263316971],
					[61.050683435194749, 3.4851665519839625],
					[61.050433438025586, 3.4849276542439189],
					[61.050433434300253, 3.484927651180481],
					[61.050433424986885, 3.484927643521873],
					[61.050433421261552, 3.4849276404584169],
					[61.050422331898929, 3.4849192481058116],
					[61.050411240959292, 3.4849081587745188],
					[61.050411239990204, 3.4849081570870748],
					[61.050411238127531, 3.4849081555553578],
					[61.050411237158457, 3.4849081538679099],
					[61.050322337247422, 3.4848248547527119],
					[61.05032232607136, 3.4848248455624544],
					[61.050322304763846, 3.4848248307125544],
					[61.050322292769735, 3.4848248235211963],
					[61.050105693956461, 3.4847109232409985],
					[61.049972296207102, 3.4846387256598352],
					[61.049872314073639, 3.4845831346406757],
					[61.049766828955569, 3.4844942468569267],
					[61.049525128623294, 3.4842915472806335],
					[61.049525127729737, 3.4842915474363725],
					[61.049525127654221, 3.4842915455932513],
					[61.049441827917818, 3.4842220466015972],
					[61.049441827024211, 3.4842220467573468],
					[61.049441825979613, 3.4842220432268509],
					[61.049441825086021, 3.4842220433825952],
					[61.04942792489981, 3.4842109449013656],
					[61.049427921174448, 3.4842109418381035],
					[61.04942791283014, 3.4842109358673126],
					[61.049427909998336, 3.4842109326483115],
					[61.04941955188982, 3.4842054598942109],
					[61.049300176627156, 3.4839860064381885],
					[61.049080776634007, 3.4835638086448073],
					[61.049080726114852, 3.4835637617628983],
					[61.049080604398334, 3.4835637124412324],
					[61.049080481020596, 3.4835637543659739],
					[61.04908041465626, 3.4835638698845526],
					[61.049080403895552, 3.4835639367294089],
				],
				[
					[61.036144199826907, 3.5320360932986703],
					[61.036144200201136, 3.5320361025111606],
					[61.036144200949593, 3.532036120936128],
					[61.036144202142609, 3.5320361281518173],
					[61.036583101601934, 3.5350583286878874],
					[61.03658310167674, 3.5350583305304193],
					[61.036583102794793, 3.5350583359037797],
					[61.036583102869614, 3.5350583377463258],
					[61.037080402791155, 3.5377361367210529],
					[61.037080410912523, 3.5377361594411796],
					[61.037080430729887, 3.5377362042648213],
					[61.037080444213224, 3.5377362260600016],
					[61.038030444189474, 3.5389140260777689],
					[61.038030451712594, 3.5389140340578593],
					[61.038030467652518, 3.5389140498639104],
					[61.038030476962994, 3.5389140575357327],
					[61.039097027999475, 3.5397445193926962],
					[61.039763705590268, 3.5418444502280231],
					[61.040205404090685, 3.5439389406540904],
					[61.040205407219851, 3.5439389512487121],
					[61.040205413403449, 3.5439389705951627],
					[61.040205417351586, 3.5439389791929794],
					[61.041322016764362, 3.5465111799747753],
					[61.041322017807367, 3.5465111835065151],
					[61.041322021680699, 3.5465111902621338],
					[61.041322023692018, 3.5465111954827933],
					[61.042327623948097, 3.5483861940936232],
					[61.042327659699147, 3.5483862324754853],
					[61.042327746391997, 3.5483862843542306],
					[61.042327795621055, 3.548386300001837],
					[61.043508295674691, 3.5484084992614648],
					[61.043508337603178, 3.5484084901852202],
					[61.043508414607388, 3.5484084583662083],
					[61.043508450427503, 3.5484084317836007],
					[61.044414050230351, 3.5473779318674072],
					[61.044414063857488, 3.5473779128172294],
					[61.044414086419415, 3.5473778699574932],
					[61.044414093641485, 3.5473778482988085],
					[61.044986293671521, 3.5450667486031859],
					[61.044986295384199, 3.5450667464521315],
					[61.044986296053857, 3.5450667407689669],
					[61.044986295979186, 3.5450667389259172],
					[61.04538909095384, 3.5429833663273347],
					[61.046352951710453, 3.5411445412234892],
					[61.047255699710114, 3.5405779730488751],
					[61.048100079238026, 3.5401279843316611],
					[61.049116761803525, 3.539797390620679],
					[61.04911679263347, 3.5397973741648263],
					[61.049116846695519, 3.5397973314268869],
					[61.049116869927644, 3.5397973051447837],
					[61.049964069910921, 3.5384195056686116],
					[61.049964073261279, 3.538419499521761],
					[61.049964079143116, 3.5384194892256233],
					[61.049964081599832, 3.5384194832329907],
					[61.05070297668064, 3.5368056931509151],
					[61.05132512936035, 3.5357723734466204],
					[61.052172164121728, 3.5355558151792614],
					[61.053069227108772, 3.5361501258909303],
					[61.053791500702822, 3.538833332778196],
					[61.053894200236385, 3.5406917101455102],
					[61.053894201354296, 3.5406917155219984],
					[61.053894201802727, 3.5406917265833298],
					[61.053894202920603, 3.5406917319598064],
					[61.054388702130517, 3.5437194241935077],
					[61.054497000737278, 3.5448806183901125],
					[61.054497006251218, 3.544880643430222],
					[61.054497023460215, 3.5448806905883345],
					[61.054497034261544, 3.5448807128604147],
					[61.055449834251107, 3.5462918128721523],
					[61.05544986322446, 3.5462918394393412],
					[61.055449927947258, 3.5462918821231257],
					[61.055449965409395, 3.546291896087995],
					[61.056911064783492, 3.5465501975165785],
					[61.056911106116715, 3.5465501959621442],
					[61.056911183642818, 3.5465501770296992],
					[61.056911220729368, 3.5465501594976678],
					[61.057813992047457, 3.5458668808862392],
					[61.058547148947753, 3.5456474240441116],
					[61.059724833782049, 3.5467140198451941],
					[61.060783115781348, 3.5491694792290041],
					[61.06078312829478, 3.5491694993549401],
					[61.060783154959736, 3.5491695356108797],
					[61.060783170973295, 3.5491695532768848],
					[61.061905471399662, 3.5501195534077894],
					[61.061905516309395, 3.550119573524102],
					[61.06190561156393, 3.5501195923944269],
					[61.061905661834061, 3.5501195893043884],
					[61.063258362394642, 3.5496779890815962],
					[61.063258372150521, 3.5496779855435983],
					[61.063258389874939, 3.5496779787755566],
					[61.063258398662491, 3.5496779735474253],
					[61.06483619854103, 3.5487751735605517],
					[61.064836210009602, 3.5487751678698132],
					[61.064836230041792, 3.5487751514177517],
					[61.064836239573737, 3.5487751423466167],
					[61.066475140206265, 3.5471779422161194],
					[61.066475147131733, 3.5471779354505681],
					[61.066475160833278, 3.5471779182308425],
					[61.06647516760944, 3.5471779077766659],
					[61.067439068271007, 3.5456834086113957],
					[61.067439075716649, 3.5456833924693769],
					[61.067439088895249, 3.5456833623379755],
					[61.067439095447192, 3.5456833463500859],
					[61.068183487597871, 3.5425611717563705],
					[61.069486240298573, 3.5401445616227307],
					[61.070388933825789, 3.5398113008371102],
					[61.071177735250316, 3.5398251998355432],
					[61.071513678638048, 3.5400639894579999],
					[61.0712248121432, 3.5415666201973077],
					[61.070660935797839, 3.5423720858265271],
					[61.070660923364265, 3.5423721121190952],
					[61.070660904976968, 3.5423721691581549],
					[61.070660899916952, 3.5423721997504036],
					[61.070649800421471, 3.5449277989775907],
					[61.070649799677199, 3.5449278028208515],
					[61.070649799975975, 3.5449278101990243],
					[61.070649800125373, 3.5449278138881013],
					[61.070863699969003, 3.5479500147939098],
					[61.070863704364911, 3.5479500344677657],
					[61.070863715912409, 3.5479500751977828],
					[61.070863723883022, 3.5479500942552966],
					[61.071366524069774, 3.5488889944274553],
					[61.071366527943056, 3.5488890011895933],
					[61.071366536657955, 3.5488890164043805],
					[61.071366542318593, 3.5488890228583956],
					[61.072094205338011, 3.5498306747809885],
					[61.072146998299807, 3.5505277806167292],
					[61.071919207245337, 3.551338845420335],
					[61.071919204269101, 3.551338883083011],
					[61.071919212764719, 3.551338959633727],
					[61.071919224087416, 3.5513389948324741],
					[61.072363723566085, 3.5521583944892847],
					[61.072363755592541, 3.5521584298366791],
					[61.07236383237931, 3.5521584816198613],
					[61.072363876245966, 3.5521584982096028],
					[61.073488875885133, 3.5522945996064235],
					[61.073488893833435, 3.5522945983715517],
					[61.073488930698304, 3.5522945975925557],
					[61.073488950359391, 3.5522945942049957],
					[61.074336150008115, 3.5520779937726892],
					[61.074336167732618, 3.5520779870032935],
					[61.074336202213331, 3.5520779717737327],
					[61.074336218894956, 3.5520779614687954],
					[61.075408418856519, 3.5512834603151089],
					[61.075408436207923, 3.551283444320815],
					[61.07540846711214, 3.5512834074138255],
					[61.075408478952184, 3.5512833886539878],
					[61.076258474721683, 3.5495556961203687],
					[61.076825170083886, 3.548633404607088],
					[61.076825176785412, 3.5486333923047835],
					[61.076825188326502, 3.5486333661634459],
					[61.076825192346988, 3.5486333543234561],
					[61.07728349176552, 3.5470167559156787],
					[61.077283496455536, 3.5470167383863505],
					[61.077283498686164, 3.5470167045609289],
					[61.077283499801467, 3.5470166876482105],
					[61.07707240006998, 3.5436416917699951],
					[61.077022400403273, 3.5414332953329724],
					[61.077022400328524, 3.5414332934880552],
					[61.077022400253782, 3.541433291643155],
					[61.077022400179025, 3.5414332897982592],
					[61.076814100195193, 3.5372471897855347],
					[61.076814096766192, 3.537247171800034],
					[61.07681408707753, 3.5372471326025736],
					[61.076814080892738, 3.5372471132354746],
					[61.075866880603193, 3.5352582143124338],
					[61.075866875760738, 3.5352582058607327],
					[61.075866865107344, 3.5352581872669888],
					[61.075866859371239, 3.5352581789697766],
					[61.074522359246217, 3.5334915784199121],
					[61.074522344199195, 3.5334915624448677],
					[61.074522311648643, 3.5334915364925061],
					[61.074522294070327, 3.5334915246704517],
					[61.073175093664865, 3.532772024264113],
					[61.073175059925823, 3.5327720133797826],
					[61.073174989322283, 3.5327720032971643],
					[61.073174953426296, 3.532772005788992],
					[61.071372167644633, 3.5332054011530065],
					[61.069119437605707, 3.5333997951911105],
					[61.067658402968398, 3.5326804281713233],
					[61.066144516104245, 3.5316081364438374],
					[61.066144513348434, 3.531608135063145],
					[61.066144505974549, 3.5316081307665703],
					[61.066144503218723, 3.5316081293858654],
					[61.063169525001825, 3.5298165421083225],
					[61.061319543274458, 3.5279276603342336],
					[61.061319542305888, 3.5279276586448636],
					[61.061319540443662, 3.527927657110097],
					[61.061319538656356, 3.5279276574192759],
					[61.059133440747345, 3.5258026588911107],
					[61.057619541757624, 3.5242665595334075],
					[61.057619524997321, 3.5242665457239624],
					[61.057619488945662, 3.5242665222565108],
					[61.057619468760663, 3.5242665127531829],
					[61.054977871675085, 3.5232942146167203],
					[61.05379728778265, 3.5228109205937153],
					[61.052111198729826, 3.5218554261556072],
					[61.05211117213922, 3.521855414051422],
					[61.052111115014768, 3.5218554035202891],
					[61.052111086043197, 3.5218553992537722],
					[61.050761085772827, 3.5219470005705849],
					[61.050761078623694, 3.5219470018081043],
					[61.050761065144073, 3.5219470022851453],
					[61.050761057176274, 3.5219470055206696],
					[61.048283257466487, 3.5224887055984326],
					[61.048283254710597, 3.5224887042192332],
					[61.048283249423704, 3.5224887069903628],
					[61.04828324674277, 3.5224887074543392],
					[61.047100055688574, 3.5228164765991834],
					[61.045866842902953, 3.5216359598471407],
					[61.044577947761226, 3.5202220648357243],
					[61.044577908878026, 3.5202220381599933],
					[61.044577824787702, 3.5202220063185683],
					[61.044577778686879, 3.5202220013075922],
					[61.042663879314226, 3.5204220001458109],
					[61.04266386680326, 3.5204220023113506],
					[61.042663843643567, 3.5204220081759332],
					[61.042663832994883, 3.5204220118749494],
					[61.041422132724435, 3.520866512059087],
					[61.041422103684233, 3.5208665282195266],
					[61.041422054021652, 3.520866568362305],
					[61.041422031611958, 3.520866592653968],
					[61.040685932112801, 3.5220137917670247],
					[61.04068592370124, 3.5220138062123802],
					[61.040685910602697, 3.5220138381700625],
					[61.040685904947097, 3.5220138539942667],
					[61.040172011448483, 3.5242110296043427],
					[61.038583134255148, 3.5272026855437071],
					[61.037002648709183, 3.5290331697374935],
					[61.037002644390682, 3.529033174194582],
					[61.037002635009785, 3.5290331869482507],
					[61.037002630766175, 3.5290331932478898],
					[61.036208130814366, 3.5302942927482706],
					[61.03620812009526, 3.530294316864941],
					[61.036208105062371, 3.5302943677027483],
					[61.036208099780104, 3.5302943927357298],
					[61.036144199826907, 3.5320360932986703],
				],
				[
					[61.008777603281217, 3.4932667012297598],
					[61.008777603894721, 3.4932667382008273],
					[61.009183104229976, 3.4953556381077688],
					[61.009183156617532, 3.4953557087286047],
					[61.00918329807498, 3.4953557787138698],
					[61.009183387220197, 3.4953557799191044],
					[61.011355686564265, 3.4943112801058316],
					[61.011355707558771, 3.4943112653349502],
					[61.011355745898008, 3.4943112345731486],
					[61.011355763092062, 3.4943112149003079],
					[61.012708458202844, 3.4923945236542608],
					[61.016780743813342, 3.4877168382204706],
					[61.021936214782826, 3.482916865317665],
					[61.028438989307794, 3.4796918782925741],
					[61.02843900509005, 3.4796918681217703],
					[61.02843903389828, 3.4796918464054487],
					[61.028439047817848, 3.4796918347042087],
					[61.032016837448623, 3.4757584464108371],
					[61.036983425352403, 3.4717945559040952],
					[61.036983455189649, 3.4717945154387615],
					[61.03698349184382, 3.4717944218272296],
					[61.036983497842897, 3.4717943706793775],
					[61.03581959826996, 3.4639777716933615],
					[61.035819597224865, 3.4639777681650381],
					[61.035819597073321, 3.4639777644805094],
					[61.035819596103963, 3.4639777627944732],
					[61.034700203809237, 3.4581778035671396],
					[61.036108495020244, 3.4519167436776796],
					[61.036108496182088, 3.4519167063630376],
					[61.036108485102666, 3.4519166340822327],
					[61.036108472861407, 3.4519165991161005],
					[61.032386273197993, 3.4454805002169011],
					[61.03238627222845, 3.445480498531615],
					[61.03238626931978, 3.4454804934757481],
					[61.032386267380673, 3.445480490105179],
					[61.027750166756903, 3.4383859914443984],
					[61.027750165711154, 3.4383859879178575],
					[61.027750162878355, 3.4383859847050879],
					[61.0277501609391, 3.4383859813354394],
					[61.02505296123433, 3.4347304812440465],
					[61.025052901175535, 3.4347304435673474],
					[61.025052771270246, 3.4347304144519208],
					[61.025052700682565, 3.4347304268532888],
					[61.02388320072545, 3.4353942253148362],
					[61.023883186733791, 3.4353942351920415],
					[61.023883162400743, 3.4353942561601012],
					[61.023883150272297, 3.4353942675648828],
					[61.021172049674043, 3.4384859683593541],
					[61.021172043081656, 3.4384860919267983],
					[61.021172154930376, 3.4384862503440918],
					[61.02117227426502, 3.4384862850371092],
					[61.02378882370931, 3.4374446467455768],
					[61.025610873792495, 3.4401667225619281],
					[61.024558282333402, 3.4426662276686866],
					[61.023258478145856, 3.4401332088242103],
					[61.023258409361929, 3.4401331559772919],
					[61.023258255104111, 3.4401331255505516],
					[61.023258171417282, 3.4401331476571082],
					[61.022208171121498, 3.4410165470953316],
					[61.022208142187125, 3.4410165874127494],
					[61.022208108158694, 3.4410166787024266],
					[61.022208102171057, 3.4410167298314911],
					[61.022549801711286, 3.4433556284206497],
					[61.02254980500026, 3.4433556426819729],
					[61.022549813289167, 3.4433556690497165],
					[61.022549817395607, 3.4433556813128874],
					[61.023846986085957, 3.4462500122475426],
					[61.022719273254005, 3.4498358383972647],
					[61.021222409883237, 3.4492665666216622],
					[61.021589099190706, 3.444241714698582],
					[61.021589090959139, 3.4442416679194769],
					[61.021589053127279, 3.4442415799632164],
					[61.021589021892019, 3.4442415427821982],
					[61.019936222186942, 3.4429609415511484],
					[61.019936193651006, 3.4429609261552123],
					[61.019936134278844, 3.4429609050398886],
					[61.019936102549053, 3.4429608994772209],
					[61.018105602875686, 3.4429358993923862],
					[61.018105509093317, 3.4429359603479148],
					[61.01810543700303, 3.4429361324789824],
					[61.018105457725511, 3.4429362419700418],
					[61.020102565609236, 3.444938949321954],
					[61.018697019378529, 3.4485110158077017],
					[61.017119224809711, 3.4513638022236259],
					[61.017119215816017, 3.4513638241972906],
					[61.017119204159364, 3.4513638688905321],
					[61.017119199709306, 3.4513638919230512],
					[61.016930400168008, 3.4554916915752836],
					[61.016930400168008, 3.4554916915752836],
					[61.016930400319772, 3.4554916952575865],
					[61.016930400319772, 3.4554916952575865],
					[61.016824799695442, 3.460880574522601],
					[61.015763704666632, 3.4653555538592995],
					[61.015763703273521, 3.4653555856263498],
					[61.015763707635855, 3.4653556479119785],
					[61.015763715996236, 3.4653556761212712],
					[61.016974810971242, 3.468247266523282],
					[61.018008108397638, 3.4718528541440352],
					[61.018008108548941, 3.4718528578265682],
					[61.018008109669474, 3.4718528631944499],
					[61.018008110714348, 3.4718528667210737],
					[61.019905359837587, 3.4772721195215421],
					[61.018333189124554, 3.4783248337468229],
					[61.018333161436729, 3.4783248608251469],
					[61.018333119024618, 3.4783249238496454],
					[61.018333104300332, 3.4783249597958354],
					[61.017624834206785, 3.4817276143969789],
					[61.015966794046783, 3.4818830894148856],
					[61.014233463726399, 3.4795192842629747],
					[61.012150165791859, 3.4764359886357692],
					[61.012150045420519, 3.4764359502813957],
					[61.012149863975139, 3.4764360245463202],
					[61.012149802749946, 3.4764361334837521],
					[61.013091497077355, 3.4818388991313638],
					[61.012377607253448, 3.4857777462461406],
					[61.010883119106815, 3.4897055124837442],
					[61.008777627882772, 3.4932665987004121],
					[61.008777614866112, 3.4932666324775532],
					[61.008777603281217, 3.4932667012297598],
				],
				[
					[60.985622000053908, 3.5822417052453317],
					[60.985633099887337, 3.5826167069250809],
					[60.985633101225801, 3.5826167178108745],
					[60.985633103902735, 3.5826167395824622],
					[60.985633107028676, 3.582616750163111],
					[60.985777607231149, 3.5831667506437603],
					[60.985777608421351, 3.5831667578501594],
					[60.985777612440984, 3.5831667682783528],
					[60.985777613557076, 3.5831667736450172],
					[60.985794214020139, 3.5832083732834588],
					[60.985794227644213, 3.5832083987437491],
					[60.985794262713746, 3.5832084427720656],
					[60.985794284233293, 3.5832084631798664],
					[60.985813775818677, 3.583222357740627],
					[60.985858160770967, 3.5832612431877666],
					[60.986258154346835, 3.5836890373868679],
					[60.986258159037881, 3.583689042143424],
					[60.986258170207456, 3.5836890513514628],
					[60.986258175866325, 3.5836890577952603],
					[60.986655475566536, 3.5840029562711022],
					[60.986655480183465, 3.5840029591879929],
					[60.986655488523581, 3.58400296517428],
					[60.986655493140489, 3.5840029680911747],
					[60.986708192793174, 3.5840362684699789],
					[60.986708200165431, 3.5840362727690525],
					[60.98670821215461, 3.5840362799850078],
					[60.986708218559023, 3.5840362825968093],
					[60.986877703920683, 3.5841112761673419],
					[60.987155487123268, 3.5842918652072067],
					[60.987285983191278, 3.584386262197182],
					[60.987285995180457, 3.5843862694133897],
					[60.987286020946257, 3.5843862835407241],
					[60.987286033755076, 3.5843862887645721],
					[60.987602686538992, 3.5844973713178252],
					[60.987980452847822, 3.5849057360847487],
					[60.987980454709401, 3.584905737619569],
					[60.987980456570966, 3.5849057391543919],
					[60.987980459326316, 3.5849057405366858],
					[60.988052645935255, 3.5849779295984088],
					[60.98810813530433, 3.5850584148229609],
					[60.988108137165909, 3.5850584163577954],
					[60.988108139995319, 3.5850584195800059],
					[60.98810814185692, 3.5850584211148373],
					[60.988499842418456, 3.585564022829737],
					[60.988499846215696, 3.5855640277394194],
					[60.988499853810161, 3.585564037558767],
					[60.98849985932079, 3.5855640403235194],
					[60.988933158719263, 3.5859973402252789],
					[60.988933164378054, 3.5859973466700068],
					[60.988933173685894, 3.5859973543446668],
					[60.988933179270603, 3.5859973589494527],
					[60.988944269027833, 3.5860057521836097],
					[60.988952658600319, 3.5860140429699641],
					[60.988952661281552, 3.5860140425124714],
					[60.988952663217198, 3.5860140458873451],
					[60.98895266411094, 3.5860140457348497],
					[60.989391564067077, 3.586422345986386],
					[60.989391573374931, 3.5864223536612712],
					[60.989391591022766, 3.5864223673235678],
					[60.989391601224341, 3.5864223748459669],
					[60.989585971433975, 3.5865334572432181],
					[60.989716549257679, 3.5866834307425441],
					[60.989838748866703, 3.5868251307993528],
					[60.989838766588576, 3.5868251463020537],
					[60.989838808288553, 3.5868251762400689],
					[60.989838830331003, 3.5868251873004033],
					[60.989883185943185, 3.5868418706652831],
					[60.989977652830625, 3.5869418357376577],
					[60.990180432017944, 3.5871640123182322],
					[60.990283119137885, 3.587380686715322],
					[60.990283123009128, 3.587380693465497],
					[60.99028313164532, 3.5873807068133687],
					[60.990283135590616, 3.5873807154035502],
					[60.99063043632799, 3.5878779151695146],
					[60.990630440051092, 3.5878779182397471],
					[60.990630446751631, 3.5878779282127491],
					[60.990630450622852, 3.5878779349630574],
					[60.990730450786536, 3.5879890334168691],
					[60.99073045823274, 3.587989039557399],
					[60.990730471337628, 3.5879890521433468],
					[60.990730477890075, 3.5879890584363294],
					[60.990813778176623, 3.5880529598315989],
					[60.990813784580965, 3.5880529624445288],
					[60.990813799325267, 3.5880529710455464],
					[60.990813805877693, 3.5880529773385592],
					[60.991180505678301, 3.5882473765557577],
					[60.991180521242299, 3.5882473831644432],
					[60.991180550434663, 3.5882473930065744],
					[60.991180564956792, 3.5882473960875845],
					[60.991366665250709, 3.5882807970576502],
					[60.991366691391612, 3.5882807981568412],
					[60.991366744270934, 3.5882807928424412],
					[60.991366770115633, 3.5882807865813211],
					[60.991627869762247, 3.5881834876699763],
					[60.991627870655996, 3.5881834875175227],
					[60.991627874156961, 3.5881834850676162],
					[60.99162787505071, 3.5881834849151635],
					[60.991744475114324, 3.5881362843020423],
					[60.991744503270006, 3.5881362683828351],
					[60.991744551093348, 3.5881362268759713],
					[60.991744570835039, 3.5881362031283963],
					[60.991886270817425, 3.5879001025755661],
					[60.991886280727826, 3.5879000805048382],
					[60.991886295112117, 3.5879000354380564],
					[60.991886300405667, 3.5879000104494265],
					[60.991905799994818, 3.5875417102126308],
					[60.991905799920758, 3.587541708372509],
					[60.991905799698593, 3.5875417028521999],
					[60.99190579955048, 3.5875416991719962],
					[60.991905799997269, 3.586977856436818],
					[60.992005770336895, 3.5868168040942359],
					[60.992005771230644, 3.5868168039417418],
					[60.992083445235458, 3.5866890470701636],
					[60.992322234877022, 3.5865474117862814],
					[60.992533197179121, 3.5865918838098101],
					[60.992910941931015, 3.5869945228465228],
					[60.99298041727819, 3.5871028840434387],
					[60.993233105413573, 3.587925046457868],
					[60.993380402176612, 3.5887694263364072],
					[60.993388700631627, 3.5888528201947274],
					[60.993388700779711, 3.5888528238751127],
					[60.993388701895576, 3.5888528292432622],
					[60.993388701969621, 3.588852831083452],
					[60.993419201026825, 3.589044423101432],
					[60.99347200057602, 3.5896611176396869],
					[60.99347200191395, 3.5896611285284781],
					[60.993472007345098, 3.5896611516889863],
					[60.993472009576777, 3.5896611624253532],
					[60.993510909770293, 3.5897778637085977],
					[60.993652610201778, 3.5902139613830069],
					[60.993652615188687, 3.5902139735024581],
					[60.993652626056281, 3.5902139975889655],
					[60.993652631936953, 3.5902140095560044],
					[60.99386093203691, 3.5905334090340895],
					[60.993860933004662, 3.5905334107219145],
					[60.993860934866191, 3.5905334122573334],
					[60.993860935833972, 3.590533413945157],
					[60.993910935676148, 3.5906057149589095],
					[60.993910970151312, 3.5906057442844728],
					[60.993911048414837, 3.590605788377593],
					[60.993911092055143, 3.5906057994646825],
					[60.994263889972856, 3.5906195997264492],
					[60.994311070041455, 3.590622399467096],
					[60.994355552000663, 3.5906334932634025],
					[60.994355572853053, 3.5906334971191765],
					[60.994355615155499, 3.5906334973172713],
					[60.994355635859854, 3.590633497492536],
					[60.994686135784647, 3.5905723966104475],
					[60.994686136678396, 3.5905723964580418],
					[60.994686136678396, 3.5905723964580418],
					[60.99468613757216, 3.5905723963056353],
					[60.99475831795386, 3.5905584998435871],
					[60.995124983377679, 3.5905613000450756],
					[60.995208237712561, 3.5905751913860966],
					[60.995547111013074, 3.5907418787771266],
					[60.995658181558277, 3.5907973641812752],
					[60.995705456339813, 3.5908445393374864],
					[60.996105447999476, 3.5912723308274197],
					[60.996430441697015, 3.5916834238748452],
					[60.99655264196948, 3.5918418222516082],
					[60.996552647702025, 3.5918418305392379],
					[60.996552661774359, 3.591841844816996],
					[60.996552670188137, 3.5918418526474984],
					[60.996672060703432, 3.5919445442275393],
					[60.996997052898251, 3.5922945364506389],
					[60.99699706868406, 3.5922945485836317],
					[60.996997101149418, 3.5922945726972322],
					[60.996997117754994, 3.5922945828374249],
					[60.997363808180317, 3.5924584778984072],
					[60.997444298894322, 3.5925057711227639],
					[60.997444332777192, 3.592505785729954],
					[60.997444403452, 3.5925057977708774],
					[60.997444439350197, 3.5925057953569968],
					[60.997513938958882, 3.5924918955461109],
					[60.997513939852645, 3.5924918953937417],
					[60.997513941714132, 3.5924918969294448],
					[60.997513943427656, 3.5924918947842621],
					[60.997794442847585, 3.5924307947410514],
					[60.997794443741334, 3.5924307945886778],
					[60.997894443635744, 3.5924084942090042],
					[60.997894467619105, 3.5924084864139427],
					[60.997894514322184, 3.5924084617738816],
					[60.997894535328321, 3.5924084470740829],
					[60.998091826114759, 3.592227955189577],
					[60.998130715601121, 3.5922001623351809],
					[60.998344490335249, 3.5920473815360503],
					[60.998480656616572, 3.5920057928525644],
					[60.998688925998415, 3.5919446008785574],
					[60.998794311097406, 3.5919473979705532],
					[60.999008153763363, 3.5921751369734638],
					[60.999008154731129, 3.5921751386616014],
					[60.999008156592645, 3.592175140197353],
					[60.999008159347909, 3.592175141580713],
					[60.999019254698283, 3.5921862384999472],
					[60.9992415508924, 3.5924307336333232],
					[60.999447012329192, 3.5926611901824668],
					[60.999558102198819, 3.5933250298453761],
					[60.999591495118302, 3.5935888730600865],
					[60.99943041432649, 3.5939971261060806],
					[60.999430408662001, 3.593997141896935],
					[60.999430402621549, 3.5939971707240703],
					[60.999430399638328, 3.5939971860579214],
					[60.999394200363369, 3.5945110850228352],
					[60.999394201177012, 3.5945111052689969],
					[60.999394206379328, 3.5945111451520213],
					[60.99939421076801, 3.5945111647888801],
					[60.999674810734788, 3.5953417642886452],
					[60.999674810734788, 3.5953417642886452],
					[60.999674811776458, 3.5953417678174935],
					[60.999674812744189, 3.5953417695057639],
					[60.999710913280587, 3.5954389697367857],
					[60.999710921170234, 3.5954389869241727],
					[60.999710939556785, 3.5954390190015091],
					[60.999710950947488, 3.5954390337391171],
					[60.99998044807402, 3.5957390296252596],
					[61.000122044907954, 3.5959140251546158],
					[61.000122054363203, 3.5959140365159539],
					[61.000122074019558, 3.5959140554051294],
					[61.000122085188352, 3.5959140646212862],
					[61.00028877394675, 3.5960307557553395],
					[61.000491564157898, 3.5962168473528782],
					[61.000569264296317, 3.5962890460344981],
					[61.000569276358846, 3.596289055098628],
					[61.000569302345433, 3.5962890747629408],
					[61.000569316121542, 3.5962890816818733],
					[61.00060551142063, 3.5963057797769342],
					[61.000755505400484, 3.5963862767830457],
					[61.000755517241195, 3.5963862803253175],
					[61.000755539209059, 3.5963862895550798],
					[61.000755551049778, 3.5963862930973685],
					[61.001019350809806, 3.5964529935492546],
					[61.001019359001539, 3.5964529958600564],
					[61.001019375311067, 3.5964529986409728],
					[61.001019383428847, 3.5964529991111207],
					[61.001052783253492, 3.5964557981579923],
					[61.001052784221208, 3.5964557998463675],
					[61.001052785114972, 3.5964557996940867],
					[61.001469384954518, 3.5964862995699334],
					[61.001469392178542, 3.5964863001923559],
					[61.001469405658902, 3.5964862997487974],
					[61.001469412882905, 3.5964863003712391],
					[61.001511109583141, 3.5964834992339489],
					[61.00188329409842, 3.5964723990734853],
					[61.001913881842931, 3.5964751991591322],
					[61.00191388631174, 3.5964751983977004],
					[61.001913895397209, 3.5964752005562639],
					[61.001913899866018, 3.5964751997948343],
					[61.001950000268501, 3.5964751998681876],
					[61.00195000205602, 3.5964751995636135],
					[61.001950004811249, 3.5964752009474683],
					[61.001950006598754, 3.5964752006428959],
					[61.00234166717599, 3.5964613003489942],
					[61.002363830203628, 3.5964695873340884],
					[61.002363831097384, 3.5964695871818004],
					[61.002394330684176, 3.5964807886628991],
					[61.002394343344704, 3.5964807902123499],
					[61.002394366132371, 3.5964807974495727],
					[61.00239437789913, 3.5964807991512928],
					[61.002813838640556, 3.5965279947790947],
					[61.002880504839048, 3.5965640759839612],
					[61.002880510349485, 3.5965640787517552],
					[61.002880518615164, 3.5965640829034644],
					[61.002880524125615, 3.5965640856712557],
					[61.003261005713142, 3.5967195774012817],
					[61.003463790420319, 3.5968529670073477],
					[61.003708186812055, 3.5970140642829054],
					[61.003838785022502, 3.5971057645580693],
					[61.003838787777703, 3.5971057659420556],
					[61.003838795149605, 3.5971057702462788],
					[61.003838797904834, 3.5971057716302748],
					[61.004105498371409, 3.5972640714569035],
					[61.004105499339104, 3.5972640731454621],
					[61.00410550023286, 3.5972640729931866],
					[61.004158199944428, 3.5972945723906533],
					[61.00415820366733, 3.5972945754632453],
					[61.004158212826752, 3.5972945794630009],
					[61.004158217443432, 3.5972945823833147],
					[61.004188817168263, 3.5973084815736982],
					[61.004188825433914, 3.5973084857257382],
					[61.004188840923597, 3.5973084905004229],
					[61.004188849115295, 3.5973084928116243],
					[61.00445274325444, 3.5973779911201782],
					[61.004605538252669, 3.5974279892999252],
					[61.004605552848595, 3.5974279942269947],
					[61.004605583606136, 3.5974279982539721],
					[61.004605599841682, 3.5974279991947427],
					[61.004627794468831, 3.5974280006796135],
					[61.005055584899566, 3.5974529989129316],
					[61.005086071789918, 3.5974557987118994],
					[61.005505561538236, 3.5975362954772963],
					[61.005505564293436, 3.5975362968613829],
					[61.005505566080984, 3.5975362965568207],
					[61.00550556697474, 3.597536296404543],
					[61.005572156352969, 3.5975473952386672],
					[61.005949944860909, 3.5976557922165511],
					[61.005949956701592, 3.5976557957597946],
					[61.005949982916412, 3.5976557987075193],
					[61.005949995576927, 3.5976558002575372],
					[61.006072184869396, 3.5976584995933578],
					[61.006399974754842, 3.597700198189008],
					[61.006399976616308, 3.5977001997254185],
					[61.006399981978873, 3.5977001988117445],
					[61.006399983766393, 3.5977001985071841],
					[61.006541684109955, 3.5977112993890454],
					[61.006541685897467, 3.5977112990844882],
					[61.006541687758926, 3.5977113006209058],
					[61.006541688652703, 3.5977113004686236],
					[61.0068471582439, 3.597727998041623],
					[61.007027727953329, 3.5977973878987113],
					[61.007027737038797, 3.5977973900579228],
					[61.007027755209755, 3.5977973943763617],
					[61.007027764295223, 3.5977973965355909],
					[61.007297149691823, 3.5978473949648926],
					[61.007533237260155, 3.5979251906386414],
					[61.00753324992067, 3.5979251921888125],
					[61.007533273528111, 3.5979251974347375],
					[61.007533287156313, 3.5979252006736484],
					[61.007744386703244, 3.5979390998866609],
					[61.007744401077353, 3.5979390992912132],
					[61.007744430719349, 3.5979390979480548],
					[61.007744445019526, 3.5979390955115589],
					[61.007961145108432, 3.5978890957839069],
					[61.00796115486586, 3.5978890922677227],
					[61.007961175274531, 3.5978890850830498],
					[61.007961184138225, 3.5978890817191465],
					[61.008194483770438, 3.5977807815941145],
					[61.008194490846613, 3.5977807785347293],
					[61.008194504031231, 3.5977807707271579],
					[61.008194510213642, 3.5977807678200624],
					[61.008316805693156, 3.5977001706987073],
					[61.008644502218011, 3.5975057719714116],
					[61.008644505719126, 3.5975057695211148],
					[61.008644512721332, 3.5975057646205042],
					[61.008644516222461, 3.5975057621702042],
					[61.008663999680373, 3.5974918741736865],
					[61.008738980982443, 3.5974584838673378],
					[61.009030654318302, 3.5973307946455471],
					[61.009094426176745, 3.5973223982420071],
					[61.009094442264434, 3.5973223955004823],
					[61.00909447421801, 3.5973223844940363],
					[61.009094489264079, 3.5973223782225627],
					[61.009188988697865, 3.5972751798396478],
					[61.009188991305209, 3.5972751775415883],
					[61.009188994880269, 3.5972751769323508],
					[61.009188996593849, 3.5972751747866032],
					[61.009394496189266, 3.5971612758173799],
					[61.009544495339803, 3.5970807751189264],
					[61.009544504129515, 3.5970807699134375],
					[61.009544523422548, 3.5970807573566965],
					[61.009544532138335, 3.5970807503100746],
					[61.009725131155776, 3.5969223512902402],
					[61.009889024684846, 3.59678075652285],
					[61.009994505980309, 3.5967029702341318],
					[61.010052893281753, 3.5966723769230309],
					[61.010052894175516, 3.596672376770699],
					[61.010052895069279, 3.5966723766183688],
					[61.010052895889089, 3.5966723746248581],
					[61.010250082301056, 3.5965640822119855],
					[61.010413960237649, 3.5965057902991546],
					[61.010444441178691, 3.5964973952475892],
					[61.010483301360352, 3.5964919024632329],
					[61.01087497435023, 3.5965418982574247],
					[61.010894371672855, 3.5965445984384745],
					[61.010894387014673, 3.5965445995311494],
					[61.010894416656626, 3.5965445981863997],
					[61.01089443192447, 3.5965445974378496],
					[61.010911089396359, 3.5965419046457607],
					[61.011044318334569, 3.596577984952491],
					[61.011272038841774, 3.5967251342136133],
					[61.011341515139534, 3.5968944757602976],
					[61.011341525858221, 3.5968944961797376],
					[61.011341551616489, 3.596894532574443],
					[61.011341567623788, 3.5968945502386291],
					[61.011549839132883, 3.5970779241049584],
					[61.011769158220226, 3.5975166626675015],
					[61.011483155580187, 3.5978137611191428],
					[61.011483140231796, 3.5978137822733904],
					[61.0114831159392, 3.5978138271982649],
					[61.011483106101238, 3.5978138511211988],
					[61.011333106053662, 3.5984138516067148],
					[61.0113331038159, 3.5984138631111651],
					[61.011333101201849, 3.5984138876567573],
					[61.011333099931782, 3.5984139008501761],
					[61.011333100010965, 3.5984277547247161],
					[61.011330419678707, 3.5984332112889308],
					[61.011330414833587, 3.5984332250915068],
					[61.011330406037132, 3.5984332525443805],
					[61.011330402979532, 3.5984332660423792],
					[61.011213703344865, 3.5991138651021677],
					[61.011213702074734, 3.599113878295527],
					[61.011213700428236, 3.5991139045299443],
					[61.011213700871707, 3.5991139155774921],
					[61.011233099689292, 3.5993556026858289],
					[61.011199799702325, 3.6000193902981308],
					[61.011199799924022, 3.6000193958219024],
					[61.011199800293539, 3.6000194050282035],
					[61.011199799621465, 3.6000194107042418],
					[61.011213699612433, 3.600280608413279],
					[61.011219200353551, 3.6004972021107875],
					[61.01122199976863, 3.6009944002431094],
					[61.011221999599165, 3.6012055962079512],
					[61.011213700071643, 3.6014166927442157],
					[61.011213700145511, 3.6014166945854953],
					[61.011213700367158, 3.6014167001092821],
					[61.011213699621152, 3.6014167039440124],
					[61.011222000186983, 3.6019333034829302],
					[61.011222000186983, 3.6019333034829302],
					[61.011222000260865, 3.6019333053241946],
					[61.011222000260865, 3.6019333053241946],
					[61.011227600388679, 3.6021305965604218],
					[61.011213700299564, 3.6023388867116166],
					[61.011213699553544, 3.6023388905463247],
					[61.011213699996738, 3.6023389015939493],
					[61.011213700144481, 3.6023389052764792],
					[61.011227600128599, 3.602872203650576],
					[61.01123039984595, 3.6030527939464903],
					[61.011213700813897, 3.6032582842857583],
					[61.011213700215535, 3.6032582918029856],
					[61.011213699912616, 3.6032583066853157],
					[61.011213701101816, 3.603258313898249],
					[61.011258099756375, 3.6038722111443784],
					[61.011263699114394, 3.6039805904461955],
					[61.011244201685386, 3.6041138711249063],
					[61.011244201086996, 3.6041138786421394],
					[61.011244199964061, 3.6041138955178651],
					[61.01124420033328, 3.6041139047242448],
					[61.011258100214363, 3.6048056034479932],
					[61.011258100288217, 3.6048056052892665],
					[61.011258100288217, 3.6048056052892665],
					[61.011258529890618, 3.6048205149443007],
					[61.009211185553106, 3.6038415194091606],
					[61.009211142881284, 3.6038415099885923],
					[61.009211057382323, 3.6038415097111662],
					[61.009211013661428, 3.6038415190064161],
					[61.008655514134041, 3.6041054194380444],
					[61.008655507951495, 3.6041054223439608],
					[61.008655494766458, 3.6041054301490485],
					[61.008655488657759, 3.6041054348960859],
					[61.006777689307548, 3.605355434455574],
					[61.006777654811671, 3.6053554718355847],
					[61.00677761076382, 3.605355562741051],
					[61.006777600170452, 3.6053556127364996],
					[61.007388700196209, 3.6151694127116496],
					[61.007388726013879, 3.6151694732029767],
					[61.007388808867567, 3.6151695647869175],
					[61.007388865977269, 3.6151695977206342],
					[61.011697141801086, 3.6159029932057432],
					[61.014836002595025, 3.6172890767373627],
					[61.017460962370798, 3.6190612477201958],
					[61.020916543173634, 3.6234473237862126],
					[61.020916547863393, 3.6234473285536528],
					[61.020916558210288, 3.6234473397788136],
					[61.020916562900027, 3.6234473445462578],
					[61.02462766342228, 3.6269529453501774],
					[61.024627664316078, 3.626952945198608],
					[61.024627666250787, 3.6269529485797158],
					[61.024627666250787, 3.6269529485797158],
					[61.027610965901324, 3.6296279494555872],
					[61.027610997172815, 3.6296279664117144],
					[61.027611061209377, 3.629627992651745],
					[61.027611095688563, 3.6296279997903045],
					[61.029102747803577, 3.6296584982976841],
					[61.031497091253456, 3.6309501670505226],
					[61.03450817777604, 3.6332612583616468],
					[61.034508195272679, 3.6332612683829804],
					[61.034508231086313, 3.633261286431503],
					[61.034508249329832, 3.6332612926159462],
					[61.035511048619426, 3.6335251933273356],
					[61.035511057704461, 3.6335251954983492],
					[61.035511074907248, 3.6335251981490297],
					[61.035511083918813, 3.6335251984772765],
					[61.038688867588945, 3.6337807972782143],
					[61.041652752390753, 3.6345112933479364],
					[61.041652767805914, 3.6345112963024215],
					[61.041652800276907, 3.6345112982222352],
					[61.041652814724792, 3.6345112994850579],
					[61.04380561485258, 3.6343445985388763],
					[61.043805632728798, 3.6343445955092255],
					[61.043805668407764, 3.6343445876066589],
					[61.043805685243242, 3.6343445810419932],
					[61.045616784925784, 3.6334890816227148],
					[61.045616801614294, 3.6334890713704389],
					[61.045616832236391, 3.6334890494771117],
					[61.045616846096515, 3.6334890359926697],
					[61.046936245935882, 3.6320695353107242],
					[61.046936270386489, 3.6320694940467164],
					[61.046936293893737, 3.6320694065454009],
					[61.04693629563176, 3.6320693598533853],
					[61.044752995643705, 3.621580558218787],
					[61.044752949870144, 3.6215804936198972],
					[61.044752826273289, 3.6215804181173761],
					[61.044752746662439, 3.6215804075174494],
					[61.041180546689226, 3.6225609061641371],
					[61.041180543187664, 3.6225609086144095],
					[61.041180534249747, 3.6225609101323819],
					[61.041180529854373, 3.6225609127344374],
					[61.038249968807456, 3.6236553974528301],
					[61.03690752480869, 3.6235792274951697],
					[61.036980680078557, 3.6235473824590922],
					[61.036980683653724, 3.6235473818521169],
					[61.036980691550689, 3.6235473768007784],
					[61.03698069691346, 3.6235473758903161],
					[61.037061195701021, 3.6235029757322392],
					[61.037258380763859, 3.6233973848369541],
					[61.037425066176517, 3.6233390892028239],
					[61.037425067070345, 3.6233390890510662],
					[61.037558367292711, 3.6232918880428127],
					[61.037558369974114, 3.6232918875875471],
					[61.037558376157087, 3.623291884682406],
					[61.037558379658648, 3.6232918822325364],
					[61.037705663818201, 3.6232279904838718],
					[61.037808338902757, 3.623202996605174],
					[61.038155622749365, 3.6231501992577138],
					[61.038222216104145, 3.6231445991781928],
					[61.038605612974344, 3.6231169004904529],
					[61.038655611394397, 3.6231140994801208],
					[61.038655614969578, 3.623114098873053],
					[61.038655623087337, 3.6231140993500746],
					[61.038655628450094, 3.6231140984394785],
					[61.039055628211273, 3.6230584976099052],
					[61.039055638936816, 3.6230584957886567],
					[61.039055662995644, 3.6230584898479181],
					[61.039055673647589, 3.6230584861837274],
					[61.039069466686911, 3.623052989805156],
					[61.039166759971131, 3.6230223915697772],
					[61.039166764366499, 3.62302238896795],
					[61.039166770623048, 3.6230223879055483],
					[61.039166775018408, 3.6230223853037384],
					[61.039447275107285, 3.6229084842118362],
					[61.039447277788675, 3.6229084837565031],
					[61.039447283151446, 3.6229084828458422],
					[61.039447285759195, 3.6229084805475495],
					[61.039505686019417, 3.6228807806263044],
					[61.039505693096181, 3.622880777569109],
					[61.039505707996227, 3.6228807676169938],
					[61.03950571507297, 3.6228807645597993],
					[61.039783413441754, 3.6226862648210187],
					[61.039955711777111, 3.6225695662198762],
					[61.039955714384902, 3.6225695639214934],
					[61.039955718780263, 3.6225695613195517],
					[61.039955721388012, 3.6225695590211942],
					[61.040116820779097, 3.6224473594024889],
					[61.040116821672903, 3.6224473592506929],
					[61.040116821599291, 3.622447357407693],
					[61.040322322123764, 3.6222890572484245],
					[61.04032232301757, 3.6222890570966229],
					[61.040322323911376, 3.6222890569448216],
					[61.04032232480516, 3.6222890567930239],
					[61.040405713313866, 3.6222223661844399],
					[61.040444498842518, 3.6222001738380354],
					[61.040444502344052, 3.6222001713878043],
					[61.040444505919226, 3.6222001707805904],
					[61.040444507633211, 3.6222001686339609],
					[61.040561208022673, 3.6221251681128801],
					[61.04056122374282, 3.6221251561652417],
					[61.040561253174744, 3.6221251270444386],
					[61.040561266066284, 3.6221251118661462],
					[61.04073076568185, 3.6218723106965549],
					[61.040730766575642, 3.6218723105447421],
					[61.040730766575642, 3.6218723105447421],
					[61.040855748922951, 3.6216834363413826],
					[61.041022325210257, 3.6215501555608136],
					[61.041022332213316, 3.6215501506601124],
					[61.04102234517844, 3.6215501373244101],
					[61.041022351214103, 3.62155013073246],
					[61.041305690631944, 3.6212252000720775],
					[61.041313900064402, 3.6212252008597927],
					[61.041313953397378, 3.6212251843773333],
					[61.041314042050416, 3.6212251284943306],
					[61.041314079158049, 3.6212250887901147],
					[61.041319579292278, 3.6212139895703057],
					[61.041319580858946, 3.6212139837374133],
					[61.041319585853522, 3.6212139736110567],
					[61.041319588387644, 3.6212139694693923],
					[61.04147375128094, 3.6207945698656094],
					[61.042744453394413, 3.6204473931280949],
					[61.042744456075795, 3.6204473926724909],
					[61.042744462258653, 3.6204473897662788],
					[61.042744465833842, 3.6204473891588074],
					[61.046719458315792, 3.6190695918068849],
					[61.047991750646439, 3.6187362936035159],
					[61.04799179892067, 3.6187362631249584],
					[61.047991872314107, 3.6187361856886113],
					[61.047991897212263, 3.6187361332004544],
					[61.048144595349285, 3.6178528442001077],
					[61.048236292668548, 3.617525054385391],
					[61.04823629462259, 3.6175250132213068],
					[61.048236284156346, 3.6175249314814333],
					[61.048236269128438, 3.6175248932050414],
					[61.048094570040512, 3.6172998949237538],
					[61.04798907123638, 3.6171248969115286],
					[61.047989071162675, 3.6171248950680872],
					[61.047844583927876, 3.6168860184366598],
					[61.047752990725947, 3.6165888408433609],
					[61.047752984773645, 3.6165888270079698],
					[61.047752971901559, 3.6165887976457456],
					[61.047752964161667, 3.616588784114354],
					[61.047627984938217, 3.6164110158071567],
					[61.047541894184477, 3.6160749494930666],
					[61.047541892102082, 3.6160749424234369],
					[61.047541887117248, 3.6160749302796198],
					[61.047541885034825, 3.6160749232099958],
					[61.047450198712738, 3.6158555584534287],
					[61.047447399616416, 3.615658296299074],
					[61.047447400362792, 3.6156582924602256],
					[61.047447399026701, 3.6156582815518057],
					[61.047447398805566, 3.6156582760215694],
					[61.047414098571785, 3.6153777756835743],
					[61.047378001905734, 3.6150833030520348],
					[61.047433496724715, 3.6147306363282992],
					[61.047469596273658, 3.6145611413880596],
					[61.047469596273658, 3.6145611413880596],
					[61.047550192833057, 3.6141806569453103],
					[61.047630787080202, 3.6139667712239678],
					[61.047630787006476, 3.6139667693805548],
					[61.047689086087857, 3.6138111748496247],
					[61.047836284402237, 3.6134694798275779],
					[61.048002973702573, 3.6130834007867567],
					[61.048061260075265, 3.6130057191991445],
					[61.048061270210624, 3.6130057026263183],
					[61.048061287652466, 3.6130056662501535],
					[61.048061294065214, 3.6130056465988969],
					[61.048089092253697, 3.6128917556342843],
					[61.048236289301983, 3.6124444640363738],
					[61.048266888187356, 3.6123583676489734],
					[61.048266888933604, 3.6123583638099586],
					[61.048266891467421, 3.6123583596666626],
					[61.048266892287458, 3.6123583576710874],
					[61.048380791769219, 3.6119722569523898],
					[61.048380795951694, 3.6119722265442951],
					[61.048380795452566, 3.6119721690929953],
					[61.048380790697202, 3.6119721402063187],
					[61.048225245253342, 3.6114751121116497],
					[61.048460960274582, 3.6113724793334088],
					[61.04860260484994, 3.6119750457574553],
					[61.048602612885304, 3.6119750666619037],
					[61.04860263431874, 3.6119751075579409],
					[61.048602645929243, 3.6119751278538366],
					[61.048794245752546, 3.6122057279468982],
					[61.048794246646324, 3.6122057277947621],
					[61.048794247613877, 3.612205729486099],
					[61.048794247613877, 3.612205729486099],
					[61.048908118756124, 3.6123389964639618],
					[61.049063703166716, 3.6129111354666286],
					[61.049105401284329, 3.6133361194605689],
					[61.049105401358077, 3.6133361213040653],
					[61.049105400538018, 3.6133361232996699],
					[61.049105402473067, 3.6133361266824648],
					[61.049113700180904, 3.6134000140825013],
					[61.049122000234696, 3.6138389048113297],
					[61.049122001792121, 3.6138389212507565],
					[61.049122009449626, 3.6138389552126231],
					[61.049122013688375, 3.6138389711957721],
					[61.049347013698693, 3.6144194718656899],
					[61.049347047865545, 3.6144195161658175],
					[61.049347135945133, 3.6144195809896091],
					[61.049347189784136, 3.6144195996697404],
					[61.049511088421525, 3.6144279999748767],
					[61.049630585647819, 3.6144363008833214],
					[61.049630587435374, 3.6144363005791624],
					[61.049630589222936, 3.6144363002750062],
					[61.049630590116735, 3.6144363001229238],
					[61.049794390259102, 3.6144446003391364],
					[61.049794434874372, 3.6144445908915985],
					[61.049794518005001, 3.6144445544735628],
					[61.049794553765196, 3.6144445261157609],
					[61.049975154174227, 3.6142251273648371],
					[61.05020295268077, 3.6139557294376976],
					[61.050202956108414, 3.6139557251421914],
					[61.050202962963681, 3.6139557165511569],
					[61.050202966317592, 3.6139557104120876],
					[61.050247324279219, 3.613889074336893],
					[61.05025834889117, 3.6138862942992156],
					[61.050258360436558, 3.6138862904783684],
					[61.050258382633587, 3.613886282988771],
					[61.05025839321145, 3.6138862774764435],
					[61.050305693371762, 3.6138612762379059],
					[61.0503056969469, 3.6138612756295032],
					[61.050305703055855, 3.6138612708776598],
					[61.050305705737188, 3.6138612704213608],
					[61.050602906187066, 3.6136751695856475],
					[61.050602926227832, 3.6136751531820823],
					[61.050602959905312, 3.6136751177525319],
					[61.050602975255941, 3.613675096578735],
					[61.050697351084793, 3.6135057402032498],
					[61.050902910381076, 3.6133695675558775],
					[61.050902932881861, 3.6133695451647925],
					[61.050902972373258, 3.6133694976081228],
					[61.050902986608783, 3.613369471055293],
					[61.051061286947053, 3.6129500707568134],
					[61.051061291645951, 3.6129500532515268],
					[61.051061298436153, 3.6129500205409668],
					[61.051061300453746, 3.6129500034920636],
					[61.051064100460088, 3.6127778037998763],
					[61.051064100238811, 3.6127777982690366],
					[61.051064099943794, 3.6127777908945879],
					[61.051064099796292, 3.6127777872073712],
					[61.051016899875854, 3.6120221884929431],
					[61.051016898391971, 3.6120221738962557],
					[61.051016891922849, 3.6120221471550966],
					[61.051016888725144, 3.612022134706331],
					[61.050708488690859, 3.6111388338550277],
					[61.05070848772332, 3.6111388321636197],
					[61.050708485714409, 3.6111388269372195],
					[61.050708484673095, 3.6111388234022326],
					[61.050689085369363, 3.6110916244112308],
					[61.050689084328049, 3.6110916208762447],
					[61.050689081425375, 3.6110916158020268],
					[61.050689080384039, 3.6110916122670491],
					[61.050472388062417, 3.6106499294078724],
					[61.050439093375054, 3.6105249493714471],
					[61.050439093301293, 3.6105249475278818],
					[61.050439093301293, 3.6105249475278818],
					[61.050339096083981, 3.6101582600969304],
					[61.050322400555771, 3.6100332978318321],
					[61.0503834985917, 3.6094778217939369],
					[61.050411297659195, 3.6092333286958831],
					[61.050489097269498, 3.6087861348645802],
					[61.050489097794213, 3.6087861254945106],
					[61.050489099811259, 3.608786108445682],
					[61.050489100335959, 3.6087860990756129],
					[61.050489100203059, 3.6087667210187142],
					[61.050583496224284, 3.608311140185505],
					[61.050583496224284, 3.608311140185505],
					[61.05058349615048, 3.6083111383419348],
					[61.050622395947869, 3.6081083391620234],
					[61.050725191780508, 3.6076111595778393],
					[61.050775184321722, 3.6074917774338591],
					[61.050775186781522, 3.6074917714462762],
					[61.050775190807435, 3.6074917596233971],
					[61.050775193193445, 3.6074917517922493],
					[61.050802993059612, 3.6073889524920202],
					[61.050802995371782, 3.6073889428172645],
					[61.050802998954666, 3.607388919932895],
					[61.050803000373065, 3.6073889104104264],
					[61.050811300366782, 3.607216709149661],
					[61.050811300219117, 3.6072167054625086],
					[61.050811299997633, 3.607216699931779],
					[61.050811299849968, 3.6072166962446097],
					[61.050800200417477, 3.6066082953317631],
					[61.050797399928264, 3.606463895589747],
					[61.050797399411373, 3.6064638826847015],
					[61.050797393761108, 3.6064638539490317],
					[61.050797389595303, 3.6064638398096722],
					[61.05076958960921, 3.6063777387971743],
					[61.050769584549606, 3.6063777248101405],
					[61.050769571823004, 3.6063776991365897],
					[61.050769565049727, 3.6063776872977691],
					[61.050727979703936, 3.6063166086669112],
					[61.050444587603288, 3.6055305314492379],
					[61.050444587603288, 3.6055305314492379],
					[61.050444586635685, 3.6055305297580209],
					[61.050444585594207, 3.6055305262232591],
					[61.050286287826538, 3.605124933268455],
					[61.050114100259044, 3.60459716608892],
					[61.050111299856979, 3.6042332995517219],
					[61.050111300280328, 3.6040389002632067],
					[61.050111300206446, 3.6040388984196841],
					[61.050111300132585, 3.6040388965761556],
					[61.050111300058717, 3.6040388947326294],
					[61.05010300122008, 3.6036722310509965],
					[61.050183488997732, 3.6034389651075154],
					[61.050183488923835, 3.6034389632639727],
					[61.050183489743745, 3.6034389612680475],
					[61.050183490563647, 3.6034389592721232],
					[61.050297390715379, 3.6030778591957033],
					[61.05029739235512, 3.6030778552038139],
					[61.050297393847103, 3.6030778475248737],
					[61.05029739459308, 3.6030778436853876],
					[61.050347394584044, 3.6028556445843614],
					[61.050347395403925, 3.6028556425884131],
					[61.050347396297688, 3.6028556424360065],
					[61.050347396223785, 3.6028556405924537],
					[61.050369592643939, 3.6027500554909948],
					[61.050533487179798, 3.6023111695583689],
					[61.050533488073548, 3.6023111694059446],
					[61.050533487999644, 3.6023111675624007],
					[61.050594586799875, 3.6021417696698292],
					[61.050714086772047, 3.6018306711230168],
					[61.050714088411752, 3.6018306671310296],
					[61.050714090797449, 3.6018306592994693],
					[61.050714091543377, 3.6018306554599229],
					[61.050727991787419, 3.6017833561850474],
					[61.050727991713529, 3.6017833543414923],
					[61.050727992533361, 3.601783352345481],
					[61.050727993427138, 3.6017833521930362],
					[61.050750187888859, 3.6017000726763886],
					[61.050950177195837, 3.6013167938645245],
					[61.050950183680719, 3.601316776052756],
					[61.050950194936895, 3.6013167425776786],
					[61.050950197772814, 3.6013167235321548],
					[61.051000198352519, 3.6009111240580638],
					[61.051000199098453, 3.6009111202184574],
					[61.051000199622656, 3.6009111108481258],
					[61.051000200294645, 3.6009111051649407],
					[61.051011299977027, 3.6005167053110201],
					[61.051011299829192, 3.6005167016238842],
					[61.051011300279391, 3.6005166904099863],
					[61.051011300131549, 3.6005166867228344],
					[61.050975200025981, 3.5999860901843723],
					[61.050961300397134, 3.5995638920649351],
					[61.050961300323202, 3.599563890221372],
					[61.050961300175331, 3.5995638865342503],
					[61.050961299207614, 3.5995638848431977],
					[61.050952999312145, 3.5994527850146598],
					[61.050952999238191, 3.5994527831711012],
					[61.050952999164259, 3.5994527813275394],
					[61.05095299909032, 3.5994527794839781],
					[61.050911299749146, 3.5990582904007233],
					[61.050922400117926, 3.5984528035959857],
					[61.050922400117926, 3.5984528035959857],
					[61.050922400043973, 3.5984528017524116],
					[61.050922399970027, 3.5984527999088534],
					[61.050922400359397, 3.5981306040361574],
					[61.050941899586036, 3.5977750133619666],
					[61.050955799435123, 3.5976000194271833],
					[61.051000198840953, 3.5972083231798622],
					[61.051000198693018, 3.5972083194927484],
					[61.051000199364857, 3.5972083138094901],
					[61.051000200184646, 3.597208311813358],
					[61.051022399927042, 3.5968028132116583],
					[61.051033498791789, 3.596672217620581],
					[61.051033498717821, 3.5966722157770228],
					[61.051033500357377, 3.5966722117847159],
					[61.051033500283395, 3.5966722099411599],
					[61.051052999315836, 3.5962833184723122],
					[61.05108909821007, 3.596005625351407],
					[61.051158497987998, 3.5955000265854822],
					[61.051158498585806, 3.5955000190586137],
					[61.051158499781387, 3.5955000040048479],
					[61.051158500379152, 3.5954999964779684],
					[61.051155799579114, 3.5953583099723585],
					[61.051169595263339, 3.5952389559479747],
					[61.051227980135529, 3.5951167852410162],
					[61.051227984308312, 3.5951167771035748],
					[61.051227990792356, 3.5951167592904234],
					[61.051227993997415, 3.5951167494620493],
					[61.051341887121929, 3.5946667768461387],
					[61.051472374384289, 3.5944389995420716],
					[61.051472377589256, 3.5944389897135589],
					[61.051472385860706, 3.5944389715948248],
					[61.051472389959436, 3.5944389616136583],
					[61.051555789651687, 3.5941833612365843],
					[61.051555791365168, 3.5941833590876842],
					[61.051555792184885, 3.5941833570914454],
					[61.051555792110882, 3.5941833552478646],
					[61.051680792013606, 3.5937528550811284],
					[61.05168079357906, 3.5937528492450235],
					[61.051680795890242, 3.5937528395690501],
					[61.051680797455695, 3.5937528337329274],
					[61.051711296775508, 3.5935722355294066],
					[61.051722395871458, 3.5935167388149045],
					[61.051722397960589, 3.5935167236081229],
					[61.051722399457525, 3.593516693652631],
					[61.051722398791348, 3.5935166770603275],
					[61.051686298868042, 3.5932055766788795],
					[61.051614099064786, 3.5925888777883475],
					[61.051614097874904, 3.5925888705667264],
					[61.051614094675429, 3.5925888581197838],
					[61.051614094453342, 3.5925888525890315],
					[61.051536293718563, 3.5922749524145088],
					[61.051536293644517, 3.5922749505709386],
					[61.051536293570486, 3.5922749487273635],
					[61.051536292676751, 3.5922749488800778],
					[61.05137239327486, 3.5916582492239297],
					[61.051372392159003, 3.5916582438459712],
					[61.051372389033489, 3.5916582332427658],
					[61.051372387097906, 3.5916582298611064],
					[61.05124738811768, 3.5913277311401415],
					[61.051036288540459, 3.5907277345017041],
					[61.050800189930499, 3.590013837107755],
					[61.050800188888616, 3.5900138335734684],
					[61.050800185911108, 3.5900138266576991],
					[61.05080018486926, 3.590013823123408],
					[61.050708487229841, 3.5897943305411935],
					[61.050483490873127, 3.5891221408582412],
					[61.050408501138094, 3.5888638739180232],
					[61.050428000244771, 3.5880778128459254],
					[61.050441898095571, 3.5879389281204768],
					[61.050544596677739, 3.5873833354300024],
					[61.050544598242951, 3.5873833295937847],
					[61.050544598617982, 3.5873833165364228],
					[61.050544600183159, 3.5873833107002091],
					[61.050564099266708, 3.5870139154833902],
					[61.050605796387885, 3.5865778540195148],
					[61.050819581293197, 3.5861250854719451],
					[61.050819586136825, 3.586125071650033],
					[61.050819594856179, 3.5861250423155711],
					[61.050819597912295, 3.5861250287994326],
					[61.050822392645109, 3.5861056602375432],
					[61.050827979000729, 3.5860944881292398],
					[61.050827987196918, 3.5860944681652311],
					[61.05082799711078, 3.5860944237768662],
					[61.050827999722223, 3.5860943991996028],
					[61.050827999977606, 3.5860750000627095],
					[61.050827998639143, 3.5860749891545027],
					[61.050827996855958, 3.586074967185203],
					[61.050827994697883, 3.5860749582733922],
					[61.05081959517539, 3.5860360581555639],
					[61.050819595101281, 3.5860360563120524],
					[61.050819594059284, 3.5860360527779163],
					[61.050819593985175, 3.5860360509344029],
					[61.050675197784599, 3.5854666662993497],
					[61.050647398690309, 3.5851638819377301],
					[61.050647398319647, 3.5851638727202162],
					[61.050647394897119, 3.5851638547439348],
					[61.050647392813126, 3.5851638476757364],
					[61.050408492596475, 3.5842999499880976],
					[61.050391894232312, 3.5842332524515332],
					[61.050391892148269, 3.5842332453834564],
					[61.05039188902218, 3.5842332347813533],
					[61.050391886938144, 3.5842332277132791],
					[61.050377989936507, 3.5841971365854879],
					[61.050122391838791, 3.5833027441809957],
					[61.050122390870875, 3.5833027424904937],
					[61.050122388786782, 3.5833027354225426],
					[61.050122387744757, 3.5833027318885713],
					[61.049933488189843, 3.5827860312050812],
					[61.049933486179903, 3.5827860259806559],
					[61.04993348215995, 3.5827860155318261],
					[61.049933479330448, 3.5827860123038389],
					[61.049727994891619, 3.5823693437408846],
					[61.049672400115568, 3.5818138882221953],
					[61.04966689984866, 3.5814443974252606],
					[61.049666880419743, 3.5814443394990887],
					[61.049666810055264, 3.5814442457459532],
					[61.049666760013423, 3.5814442097659658],
					[61.049491844404578, 3.5813887365878974],
					[61.049080780854908, 3.5805055165005473],
					[61.049080765442319, 3.5805054912980774],
					[61.049080726796142, 3.5805054478006375],
					[61.049080705350001, 3.5805054291995697],
					[61.049044525459443, 3.5804831423338079],
					[61.049030741923652, 3.5804692597385279],
					[61.049030719657985, 3.5804692431339538],
					[61.049030673561788, 3.5804692157610538],
					[61.049030648837508, 3.5804692051457501],
					[61.048600098104096, 3.5803609198438786],
					[61.048486235631415, 3.5802554528542485],
					[61.048486215227399, 3.5802554377873799],
					[61.048486170247486, 3.5802554157922657],
					[61.048486147384828, 3.5802554067146004],
					[61.048150117208252, 3.580174821501025],
					[61.047741859757799, 3.5796276806541405],
					[61.047741841886491, 3.5796276614422382],
					[61.047741801004101, 3.5796276294666614],
					[61.047741778886753, 3.5796276165499399],
					[61.047702902108412, 3.5796109252680193],
					[61.047627922983381, 3.5795526425136428],
					[61.047627906376803, 3.5795526323653561],
					[61.04762787145043, 3.5795526142181622],
					[61.047627853130614, 3.5795526062192797],
					[61.047255704096415, 3.5794498205100296],
					[61.047169541228854, 3.5793637582477618],
					[61.047169538399238, 3.5793637550203576],
					[61.04716953095258, 3.579363748871629],
					[61.047169528122971, 3.579363745644224],
					[61.046811230124064, 3.5790665485156228],
					[61.046422330569762, 3.5787276488457551],
					[61.046422312995162, 3.5787276370080727],
					[61.046422275239074, 3.5787276156351417],
					[61.046422255131759, 3.5787276079431325],
					[61.046364012826572, 3.5787109240013408],
					[61.046272352919246, 3.5786026713149415],
					[61.046272344430371, 3.5786026616331763],
					[61.046272326707374, 3.5786026461091565],
					[61.046272318292722, 3.5786026382706164],
					[61.045914025809587, 3.5783415448719249],
					[61.045700133352831, 3.5781498502562998],
					[61.045700130597417, 3.5781498488723393],
					[61.045700125906087, 3.5781498441081516],
					[61.045700124044416, 3.5781498425710923],
					[61.045466823891068, 3.5779665430902763],
					[61.045466822923096, 3.577966541400182],
					[61.045466822029383, 3.5779665415532582],
					[61.045466821135648, 3.5779665417063415],
					[61.045058432165533, 3.5776554489392702],
					[61.045022342343245, 3.5776192594173506],
					[61.045022334896487, 3.5776192532694338],
					[61.045022320896734, 3.5776192408205176],
					[61.04502231441797, 3.5776192363626564],
					[61.044986222801739, 3.5775942418105644],
					[61.044572331175125, 3.5772359490658636],
					[61.04457232841969, 3.5772359476820106],
					[61.044572323728374, 3.5772359429181138],
					[61.044572320972946, 3.5772359415342776],
					[61.044258420615392, 3.5769970409993541],
					[61.044258419647406, 3.5769970393093371],
					[61.044258418753699, 3.5769970394624337],
					[61.044258417785727, 3.5769970377724301],
					[61.044125118862731, 3.5768998400515719],
					[61.043872323037576, 3.5767081414293336],
					[61.043677925005888, 3.5765526448900076],
					[61.043677924037901, 3.5765526432000363],
					[61.043677923069957, 3.5765526415100695],
					[61.043677922176208, 3.576552641663183],
					[61.04355572194256, 3.5764581428162141],
					[61.043555721868316, 3.5764581409731369],
					[61.043555720974567, 3.5764581411262504],
					[61.043227922747505, 3.5762081411908775],
					[61.042855724942285, 3.5759109437044021],
					[61.042855723080606, 3.5759109421676238],
					[61.042855720325164, 3.5759109407839711],
					[61.042855719357206, 3.5759109390940518],
					[61.042780718822449, 3.5758554382629768],
					[61.042780710556166, 3.5758554341120341],
					[61.042780695811089, 3.5758554255038839],
					[61.042780686576805, 3.5758554196630365],
					[61.04258068683724, 3.5757581199621127],
					[61.042580685869282, 3.5757581182722316],
					[61.042580684975547, 3.5757581184253571],
					[61.042380684979769, 3.5756637185531148],
					[61.042380682224362, 3.5756637171694972],
					[61.042380674032351, 3.5756637148616406],
					[61.042380670383196, 3.5756637136311515],
					[61.04233616999494, 3.5756470133479503],
					[61.042269475930262, 3.5756220145596531],
					[61.041886182416626, 3.5754498174318936],
					[61.041886178767463, 3.5754498162014454],
					[61.041886173256593, 3.5754498134342754],
					[61.041886169607437, 3.5754498122038259],
					[61.041713979460589, 3.5753859164854953],
					[61.04143898668822, 3.575252619739786],
					[61.041438978421915, 3.5752526155891595],
					[61.041438959282445, 3.575252609590212],
					[61.041438949228699, 3.5752526057458311],
					[61.041175062059395, 3.5751859094958496],
					[61.040988974634466, 3.5751109145632598],
					[61.040988960037843, 3.5751109096417411],
					[61.040988930099381, 3.5751109036376314],
					[61.040988913789576, 3.5751109008652651],
					[61.040680625235673, 3.575088700304911],
					[61.040541735221481, 3.5750637025113252],
					[61.040541730678591, 3.5750637014340985],
					[61.040541721667111, 3.5750637011225193],
					[61.040541717198487, 3.5750637018881726],
					[61.040213938973103, 3.5750359028223064],
					[61.040091760622616, 3.5749970088755632],
					[61.040091748855694, 3.5749970071805182],
					[61.040091726141277, 3.5749970017944781],
					[61.040091714374356, 3.5749970000994331],
					[61.0397389347632, 3.5749720029483791],
					[61.039641754531409, 3.5749442073241657],
					[61.039641742764474, 3.574944205629174],
					[61.039641719156364, 3.5749442003963403],
					[61.039641707463687, 3.5749442005441767],
					[61.039272218941726, 3.5749304001108082],
					[61.039197230339077, 3.5749192024383176],
					[61.039197225796173, 3.5749192013611295],
					[61.039197217678407, 3.5749192008964594],
					[61.039197214997266, 3.5749192013558364],
					[61.03912221478933, 3.5749137003252622],
					[61.039122211140175, 3.5749136990949659],
					[61.039122203990381, 3.5749137003199749],
					[61.039122200341225, 3.5749136990896782],
					[61.038819400338902, 3.57491370047891],
					[61.038747207220581, 3.5749137006809382],
					[61.038672214767487, 3.5749081018710767],
					[61.038672211118346, 3.5749081006407928],
					[61.038672203894279, 3.5749081000230096],
					[61.038672200319368, 3.5749081006355019],
					[61.038369400232064, 3.5749081000115122],
					[61.038300007613245, 3.574908100934544],
					[61.038227815127584, 3.5749026013546783],
					[61.038227810584708, 3.5749026002775341],
					[61.038227804254376, 3.5749025995066304],
					[61.038227799785744, 3.5749026002722339],
					[61.037922200153716, 3.5749026004063849],
					[61.037850007924064, 3.5749026003741133],
					[61.037777815001277, 3.5748970007119194],
					[61.037777812245864, 3.5748969993285469],
					[61.037777805096063, 3.5748970005534928],
					[61.037777802340649, 3.5748969991701194],
					[61.037475001960182, 3.5748942002659021],
					[61.03747500106649, 3.5748942004190138],
					[61.037475000172741, 3.5748942005721323],
					[61.037400008332106, 3.5748941995882801],
					[61.037327814949016, 3.5748887002426297],
					[61.037327806831264, 3.574888699777969],
					[61.037327788808255, 3.5748886991548656],
					[61.03732778076472, 3.5748887005329055],
					[61.037041680935509, 3.5749165007291972],
					[61.037041679148061, 3.5749165010354225],
					[61.037041677360591, 3.5749165013416517],
					[61.037041676466878, 3.5749165014947613],
					[61.036949990765685, 3.5749276002082979],
					[61.036858305561424, 3.5749248000068179],
					[61.036858298337364, 3.5749247993890423],
					[61.036858281282349, 3.5749248004554852],
					[61.036858273238835, 3.5749248018334878],
					[61.036613873886964, 3.5749581015323488],
					[61.036502776924323, 3.5749720002667149],
					[61.036372179364299, 3.5749859017851788],
					[61.036372176608836, 3.5749859004018627],
					[61.036372173108212, 3.5749859028569286],
					[61.036372172140204, 3.5749859011673975],
					[61.036197173879891, 3.5750109025132089],
					[61.036052776710456, 3.5750276018235856],
					[61.036052775742455, 3.5750276001340735],
					[61.035891686350553, 3.575046999203217],
					[61.035769395584602, 3.5750497996073776],
					[61.035602796724795, 3.5750526000661504],
					[61.035602791288198, 3.575052599142154],
					[61.035602780563494, 3.5750526009793506],
					[61.035602776094905, 3.5750526017448463],
					[61.035422185390352, 3.5750747991815564],
					[61.035336094710011, 3.5750776004535081],
					[61.035155596921726, 3.5750803993153588],
					[61.035155588878204, 3.5750804006932229],
					[61.035155573610602, 3.5750804014532926],
					[61.035155565567109, 3.5750804028311536],
					[61.034947174912965, 3.5751165008811254],
					[61.034911089935754, 3.5751191998220579],
					[61.03470559528612, 3.5751247996604936],
					[61.034705585455157, 3.5751248013445052],
					[61.034705566612658, 3.5751248027168976],
					[61.034705556781695, 3.5751248044009105],
					[61.034488856733873, 3.5751720055523273],
					[61.034488853978431, 3.5751720041690658],
					[61.034488847796638, 3.5751720070832183],
					[61.034488845115455, 3.5751720075424864],
					[61.034469368279602, 3.5751776016407235],
					[61.034255591762694, 3.5751858996011201],
					[61.034255581037975, 3.5751859014381742],
					[61.0342555586206, 3.575185903422843],
					[61.034255548863882, 3.5751859069493235],
					[61.034077749429287, 3.5752331075942574],
					[61.034077747641838, 3.5752331079004271],
					[61.033974968673078, 3.5752609016072303],
					[61.033805590277339, 3.5752692003133073],
					[61.03380558938364, 3.5752692004663849],
					[61.033805586702442, 3.5752692009256331],
					[61.033805584915001, 3.5752692012317988],
					[61.033652785166126, 3.5752804001420464],
					[61.033652772653952, 3.5752804022851854],
					[61.033652749417108, 3.5752804062652901],
					[61.033652736979221, 3.5752804102509086],
					[61.033358261566278, 3.5753776012921756],
					[61.033261089458733, 3.5753831007088359],
					[61.033261071584235, 3.5753831037703487],
					[61.033261040303863, 3.5753831091279973],
					[61.033261024365316, 3.5753831155682803],
					[61.032908223797115, 3.5755276158801572],
					[61.032908221115946, 3.5755276163393632],
					[61.032908214040383, 3.575527619406361],
					[61.032908211433472, 3.5755276217080074],
					[61.032886011074631, 3.5755387203278],
					[61.03288600936142, 3.5755387224763777],
					[61.032886004892809, 3.5755387232417224],
					[61.032886003179605, 3.5755387253903153],
					[61.032805524599787, 3.5755831134533556],
					[61.032505547856928, 3.5756637079130429],
					[61.032505546888963, 3.5756637062236689],
					[61.032505545175759, 3.5756637083722298],
					[61.032505544282039, 3.5756637085252918],
					[61.032458243586987, 3.5756776085659054],
					[61.032458232042806, 3.5756776123981293],
					[61.032458209922417, 3.5756776217519315],
					[61.032458199346209, 3.5756776272735249],
					[61.032391617558162, 3.5757165158638835],
					[61.032122139317771, 3.5758026091210877],
					[61.032008240304016, 3.5758387089634529],
					[61.03200823941031, 3.575838709116506],
					[61.032008237622847, 3.5758387094226163],
					[61.032008236729141, 3.5758387095756703],
					[61.031738891560416, 3.5759275919532523],
					[61.03155834759162, 3.5758831064652798],
					[61.031558324057741, 3.5758831030751348],
					[61.031558276393305, 3.5758831038173744],
					[61.031558251294733, 3.5758831062604175],
					[61.031380551763704, 3.5759276060186078],
					[61.031299952603206, 3.5759470069829788],
					[61.031299950741499, 3.5759470054467251],
					[61.031113898773462, 3.5759941942887492],
					[61.030844448224364, 3.5759276066710846],
					[61.030844418360189, 3.5759276025099447],
					[61.030844358257781, 3.575927607236987],
					[61.030844327871101, 3.5759276124405184],
					[61.030663827994701, 3.57599701356961],
					[61.030663823526069, 3.5759970143348188],
					[61.030663817344227, 3.5759970172484254],
					[61.030663812949847, 3.5759970198559627],
					[61.030480515400413, 3.57608591869309],
					[61.030369318245619, 3.5761359178759711],
					[61.030369312063762, 3.5761359207895209],
					[61.030369298806356, 3.5761359267696551],
					[61.030369292698744, 3.5761359315255188],
					[61.030211005562499, 3.5762359222050164],
					[61.030122121836804, 3.5762748151394774],
					[61.029780547257488, 3.5764165045359761],
					[61.029761085912646, 3.5764192004645219],
					[61.029750000071672, 3.5764191990945582],
					[61.029749978622228, 3.5764192027671449],
					[61.029749936691275, 3.5764192118015541],
					[61.029749917177767, 3.5764192188526258],
					[61.029683226106627, 3.5764498143843753],
					[61.029366634937467, 3.5765581105828299],
					[61.029366634043761, 3.5765581107358448],
					[61.029311047585658, 3.5765776056277172],
					[61.029238862067629, 3.5765915042524417],
					[61.029238855811528, 3.5765915053235577],
					[61.029238843299353, 3.5765915074657855],
					[61.029238837117482, 3.5765915103791324],
					[61.028980536612814, 3.5766776108249276],
					[61.028980534825365, 3.5766776111309526],
					[61.028980533037881, 3.5766776114369807],
					[61.028980531250433, 3.5766776117430039],
					[61.028866630637978, 3.5767192117660924],
					[61.028866629818467, 3.5767192137613146],
					[61.028866627137297, 3.5767192142203443],
					[61.02886662624357, 3.5767192143733544],
					[61.028686026083314, 3.5767915131280676],
					[61.028686025263809, 3.5767915151232765],
					[61.028686024370089, 3.5767915152762813],
					[61.028605525344176, 3.5768248140863808],
					[61.02841662630042, 3.576899814024205],
					[61.02841662279971, 3.5768998164784209],
					[61.028416614830398, 3.5768998196976249],
					[61.028416612149215, 3.5768998201566347],
					[61.028236012227353, 3.5769887197347248],
					[61.028105516191822, 3.5770526198340598],
					[61.027966619201699, 3.5771137172693277],
					[61.027966617414243, 3.577113717575318],
					[61.027966611232365, 3.5771137204884309],
					[61.027966607731678, 3.5771137229425811],
					[61.027874908030498, 3.5771609217174301],
					[61.027874907210993, 3.5771609237125763],
					[61.027541607082625, 3.5773359224775159],
					[61.027541600900747, 3.5773359253905448],
					[61.027541590398663, 3.5773359327527645],
					[61.027541584291001, 3.577335937507947],
					[61.027522083909055, 3.5773498369684318],
					[61.02752207698191, 3.5773498437187206],
					[61.02752206387283, 3.5773498533820405],
					[61.027522056945699, 3.5773498601323124],
					[61.02751658080237, 3.5773554364707829],
					[61.027499911118348, 3.5773637208751787],
					[61.027499909330864, 3.5773637211811504],
					[61.027499904116958, 3.5773637257833317],
					[61.027499901435775, 3.5773637262422819],
					[61.027172101321142, 3.5775498262016496],
					[61.027172098639952, 3.5775498266605807],
					[61.027172097820433, 3.5775498286556711],
					[61.02706383258429, 3.5776137064588669],
					[61.026916674404291, 3.5776331018868479],
					[61.026916659210912, 3.5776331044873761],
					[61.026916630760013, 3.5776331130666938],
					[61.026916616534535, 3.5776331173563545],
					[61.02679431695389, 3.5776887183375514],
					[61.026794316060169, 3.5776887184905211],
					[61.026613815995859, 3.57777201773153],
					[61.026613810707701, 3.5777720204914125],
					[61.026613801025086, 3.5777720258582124],
					[61.02661379663067, 3.5777720284651426],
					[61.026438797900596, 3.5778776273511301],
					[61.026297100253167, 3.5779609273195345],
					[61.026163801610792, 3.5780359264507915],
					[61.026163800717057, 3.5780359266037483],
					[61.026163798929602, 3.5780359269096604],
					[61.026163798110083, 3.5780359289046735],
					[61.026088797625796, 3.5780804272903404],
					[61.02583599838384, 3.5782304281022874],
					[61.025835991308199, 3.5782304311679356],
					[61.025835978273292, 3.5782304426723788],
					[61.02583597127186, 3.5782304475800486],
					[61.025749870695947, 3.5783026476842035],
					[61.025713818017792, 3.5783331089133164],
					[61.025661079262925, 3.5783387010352516],
					[61.025661057887596, 3.5783387065479721],
					[61.025661016998626, 3.5783387191095097],
					[61.025660996665444, 3.578338728153343],
					[61.025397096668989, 3.5784970285986866],
					[61.025397096668989, 3.5784970285986866],
					[61.025263799367757, 3.578577627800744],
					[61.0250471064357, 3.5786998237056702],
					[61.025019310708288, 3.5787137217440992],
					[61.02501930713337, 3.5787137223558201],
					[61.025019300951442, 3.5787137252683263],
					[61.025019298344453, 3.578713727569125],
					[61.02481380014931, 3.5788359274258288],
					[61.024699902015371, 3.5788998246399593],
					[61.024391602057172, 3.5790720251308188],
					[61.024391601163437, 3.579072025283736],
					[61.024391597662692, 3.5790720277373613],
					[61.024391596843159, 3.57907202973225],
					[61.024363819784696, 3.5790887151809989],
					[61.024344346010075, 3.5790942077666794],
					[61.024344340647666, 3.5790942086841708],
					[61.024344331784569, 3.5790942120552747],
					[61.024344328283824, 3.5790942145088938],
					[61.024258227643763, 3.5791276149385034],
					[61.024258219600163, 3.5791276163147265],
					[61.024258204629334, 3.5791276244401229],
					[61.024258196659929, 3.5791276276582988],
					[61.023994297920098, 3.5792859284058514],
					[61.023913830376607, 3.5793331084240152],
					[61.02380276545999, 3.5793526034103271],
					[61.023802752054003, 3.5793526057038942],
					[61.02380272635834, 3.5793526156639173],
					[61.023802713026548, 3.5793526197994066],
					[61.023619312657139, 3.5794415192782449],
					[61.023619311837614, 3.5794415212730759],
					[61.023619309156423, 3.5794415217317765],
					[61.023619308262695, 3.579441521884676],
					[61.023463839014802, 3.5795220068734195],
					[61.023249973693922, 3.5795498015444358],
					[61.023249954106006, 3.5795498067500247],
					[61.023249915972251, 3.5795498206921192],
					[61.023249897352265, 3.5795498275867055],
					[61.023236008644716, 3.5795581215857188],
					[61.023013851541187, 3.5796526022455355],
					[61.022838884046948, 3.5796665010831319],
					[61.022736083666956, 3.5796748011605981],
					[61.022736071154696, 3.5796748033010424],
					[61.022736045236456, 3.5796748077348108],
					[61.022736031904643, 3.5796748118700226],
					[61.022566647717952, 3.579735906741067],
					[61.022441668570536, 3.5797581021284062],
					[61.022224971810964, 3.5797887020083157],
					[61.022224969129752, 3.5797887024669621],
					[61.022224962873629, 3.5797887035371376],
					[61.022224960192432, 3.5797887039957832],
					[61.022116684818741, 3.5798108993515858],
					[61.022019411881224, 3.5798053994880399],
					[61.021666714010614, 3.5797830999144016],
					[61.021555614868191, 3.5797748004473098],
					[61.021555610325322, 3.5797747993699076],
					[61.021555601313821, 3.5797747990568793],
					[61.02155559684514, 3.5797747998212746],
					[61.021216703988799, 3.5797804004507325],
					[61.021111111078341, 3.5797747994799916],
					[61.021111106609673, 3.5797748002443703],
					[61.021111099385614, 3.5797747996256084],
					[61.021111094916961, 3.579774800389985],
					[61.020772218971842, 3.5797830997210132],
					[61.020655641874981, 3.5797581034993486],
					[61.02065562928852, 3.5797581037978325],
					[61.020655602105606, 3.5797580991752955],
					[61.020655587731689, 3.579758099779526],
					[61.020322230140849, 3.5797775977286417],
					[61.0202028702927, 3.5797331132102013],
					[61.020202848472202, 3.5797331076705068],
					[61.02020280319207, 3.5797331005803095],
					[61.020202779806631, 3.5797331008715272],
					[61.019875034875959, 3.5797664959093205],
					[61.019744485143633, 3.5797054192114679],
					[61.019744455950693, 3.579705409369736],
					[61.019744398977849, 3.5797054024252373],
					[61.019744369262106, 3.5797054019448442],
					[61.019425053311174, 3.5797553885111859],
					[61.019264021400438, 3.579633140498939],
					[61.019263978356399, 3.5796331219004296],
					[61.019263888615676, 3.5796331057260575],
					[61.019263842812727, 3.5796331079973243],
					[61.018975035281052, 3.5797191814606517],
					[61.018805712781415, 3.5796026349300374],
					[61.018805659906306, 3.5796026180133174],
					[61.018805552661824, 3.5796026141047133],
					[61.018805499186215, 3.5796026269599595],
					[61.018525004851362, 3.5797664657100094],
					[61.018394507826834, 3.5796831306728869],
					[61.018394431714654, 3.5796831177306085],
					[61.018394292663174, 3.5796831507850251],
					[61.018394229649665, 3.5796831949400962],
					[61.018233132361281, 3.5799415911655874],
					[61.01814156240831, 3.5800804472474619],
					[61.018077708341359, 3.580113722935196],
					[61.018077693370401, 3.5801137310583835],
					[61.01807766633226, 3.5801137523710285],
					[61.018077653297127, 3.580113763871728],
					[61.017838771289277, 3.5803720433900246],
					[61.017624894338873, 3.5805054291330851],
					[61.017624885624038, 3.5805054361861273],
					[61.017624870727253, 3.5805054461505881],
					[61.017624863799888, 3.5805054528979614],
					[61.017508172164, 3.5806137459931966],
					[61.017188794820548, 3.5808498290106807],
					[61.017177710243423, 3.58085542170855],
					[61.017177697059893, 3.5808554295255095],
					[61.017177673448209, 3.5808554465424995],
					[61.017177661232573, 3.5808554560481811],
					[61.017174873672971, 3.5808581447914367],
					[61.017166587598823, 3.5808637336488998],
					[61.017166584991784, 3.5808637359489026],
					[61.017166579777701, 3.5808637405489159],
					[61.017166577170677, 3.5808637428489196],
					[61.016852677312457, 3.5811081420919315],
					[61.016852677312457, 3.5811081420919315],
					[61.016852674705419, 3.5811081443918922],
					[61.016724876355944, 3.5812109424022354],
					[61.016533177075907, 3.5813609420583754],
					[61.016533176182143, 3.5813609422111803],
					[61.016533173575105, 3.5813609445110952],
					[61.01653317268137, 3.5813609446638983],
					[61.016272078584855, 3.5815748401522538],
					[61.016205484072138, 3.5816220367042773],
					[61.016205483252563, 3.5816220386985607],
					[61.016205480571344, 3.5816220391569424],
					[61.016205479677609, 3.5816220393097371],
					[61.016030480154456, 3.581752639793077],
					[61.016030473227019, 3.5817526465398273],
					[61.016030459298008, 3.5817526581918586],
					[61.016030452444724, 3.581752666780107],
					[61.015897054363855, 3.5818998630796366],
					[61.015822092319432, 3.5819776248285047],
					[61.01569434215979, 3.5820165081823405],
					[61.015694328008294, 3.5820165143097236],
					[61.015694299705302, 3.5820165265645034],
					[61.015694287415421, 3.5820165342277925],
					[61.015552686580463, 3.5821137352016996],
					[61.015372126303795, 3.5822359098177596],
					[61.01517777241321, 3.5822637024910295],
					[61.015177769731984, 3.5822637029493349],
					[61.015177767050766, 3.5822637034076408],
					[61.015177766157031, 3.582263703560411],
					[61.015113865595751, 3.5822748023282163],
					[61.015113849582612, 3.5822748069194663],
					[61.015113820311704, 3.5822748174851058],
					[61.015113805266459, 3.5822748237650139],
					[61.014922105292598, 3.5823776239401224],
					[61.014922100004327, 3.582377626698122],
					[61.014922088608145, 3.5823776342083238],
					[61.014922084213609, 3.582377636813562],
					[61.014824883819237, 3.5824470368195316],
					[61.014538783859685, 3.5826498367287689],
					[61.014538776038449, 3.5826498436277459],
					[61.014538761215611, 3.5826498554315584],
					[61.014538754288125, 3.582649862177798],
					[61.014497055709057, 3.5826942614594062],
					[61.014472058973546, 3.5827192585138605],
					[61.01447204772564, 3.5827192697065957],
					[61.014472029920945, 3.5827192968525434],
					[61.014472022322131, 3.5827193092756859],
					[61.014258121926986, 3.5831388094523584],
					[61.014258121033251, 3.5831388096050976],
					[61.014258121107382, 3.5831388114464979],
					[61.014258120213633, 3.5831388115992384],
					[61.014049819837219, 3.5835666117236125],
					[61.014049811043876, 3.5835666391828438],
					[61.014049801574942, 3.5835666945681419],
					[61.01404980097351, 3.5835667243355931],
					[61.014058099479314, 3.5836361047399268],
					[61.014019220699858, 3.5841386261861068],
					[61.013816832481481, 3.5840692348409955],
					[61.013572391366409, 3.5836777290276198],
					[61.013552998025254, 3.5835555688320069],
					[61.013552994825162, 3.5835555564007358],
					[61.013552987605323, 3.5835555335322917],
					[61.013552984405216, 3.5835555211010375],
					[61.013411285192497, 3.5832221246867033],
					[61.013355786193813, 3.5830832256217415],
					[61.013355769888229, 3.5830832005982307],
					[61.013355732882552, 3.5830831531561635],
					[61.013355711362813, 3.5830831327317147],
					[61.013339017197502, 3.5830720379957874],
					[61.013125161213374, 3.5829054723699976],
					[61.013058481940497, 3.5827610160640382],
					[61.013058469432238, 3.5827609959538922],
					[61.013058439127441, 3.5827609584913551],
					[61.013058422298762, 3.5827609428275404],
					[61.012875122008992, 3.5826192411048461],
					[61.012875121115258, 3.582619241257591],
					[61.01287511925365, 3.5826192397217751],
					[61.012875118285763, 3.5826192380332049],
					[61.012730718073549, 3.5825137384263677],
					[61.01273068687091, 3.5825137233654787],
					[61.012730623122962, 3.5825137045969697],
					[61.012730588716067, 3.5825136993535431],
					[61.012680589346587, 3.582516499138253],
					[61.012680583090408, 3.5825165002074728],
					[61.012680570578084, 3.5825165023459111],
					[61.012680565215653, 3.5825165032623856],
					[61.012633292375213, 3.5825247975388157],
					[61.012230618726797, 3.5824859001696456],
					[61.012230586478083, 3.5824859038271519],
					[61.012230522277186, 3.5824859185072229],
					[61.012230491292925, 3.582485931218319],
					[61.012183190704278, 3.582516532964656],
					[61.012183188916779, 3.5825165332701436],
					[61.012183184596367, 3.5825165377163768],
					[61.012183181915169, 3.5825165381746027],
					[61.012060982258589, 3.5826054373929601],
					[61.012060975331096, 3.5826054441386348],
					[61.012060961401964, 3.5826054557887499],
					[61.012060953580736, 3.5826054626871828],
					[61.011877653703095, 3.5827998624838271],
					[61.011877650350549, 3.5827998686184879],
					[61.011877640815968, 3.5827998776635401],
					[61.0118776365697, 3.5827998839509489],
					[61.011780437401697, 3.5829359845235871],
					[61.011780431442062, 3.582935992957657],
					[61.011780421384401, 3.5829360113615598],
					[61.011780416392618, 3.5829360214841408],
					[61.011647015670981, 3.583252722489664],
					[61.011647015670981, 3.583252722489664],
					[61.011647014777232, 3.5832527226423818],
					[61.011535914934484, 3.5835193223700963],
					[61.01153591031332, 3.5835193416986542],
					[61.011535901816465, 3.583519376520591],
					[61.011535899876485, 3.5835193953910234],
					[61.011527599791208, 3.5839360952005621],
					[61.011527599865325, 3.5839360970418079],
					[61.011527599939456, 3.5839360988830329],
					[61.011527600013565, 3.5839361007242592],
					[61.01152760007804, 3.5840277998688843],
					[61.011527599554903, 3.584027809227734],
					[61.011527602157692, 3.584027829175878],
					[61.011527604315759, 3.5840278380766413],
					[61.011610902839735, 3.5844472369999192],
					[61.011652603133257, 3.5846833354622296],
					[61.011652608343056, 3.5846833531112421],
					[61.011652621443908, 3.584683387951225],
					[61.011652630228689, 3.5846834049895215],
					[61.011772023640688, 3.5848778952337681],
					[61.012008111647312, 3.5853778716517541],
					[61.012085907930356, 3.585650054110336],
					[61.01208591500162, 3.5856500732957093],
					[61.012085931677206, 3.585650107525939],
					[61.012085942175247, 3.5856501224181629],
					[61.012213741706809, 3.5858140224920838],
					[61.01221376322615, 3.5858140429177126],
					[61.012213809543397, 3.5858140757932824],
					[61.012213836202918, 3.5858140897791908],
					[61.012419336366222, 3.5858834897635261],
					[61.012419344558083, 3.5858834920722726],
					[61.012419362729325, 3.5858834963844415],
					[61.01241937181495, 3.5858834985405172],
					[61.01245827243762, 3.5858890978490119],
					[61.012458273331369, 3.5858890976963629],
					[61.012458276980432, 3.5858890989270695],
					[61.012458278767902, 3.5858890986217746],
					[61.012663879107045, 3.5859112992186954],
					[61.01266391403712, 3.5859112951066447],
					[61.012663982707181, 3.5859112796699919],
					[61.012664015331119, 3.585911262974109],
					[61.012852915082959, 3.5857779632264317],
					[61.012852918583846, 3.5857779607745006],
					[61.01285292819275, 3.5857779535713501],
					[61.012852930799873, 3.5857779512720751],
					[61.013114031037652, 3.5855501505595377],
					[61.013114032751041, 3.585550148412886],
					[61.013114036326009, 3.5855501478022371],
					[61.013114037145655, 3.5855501458082513],
					[61.013164036840394, 3.5855029458439822],
					[61.013164037734136, 3.5855029456913168],
					[61.013272337929607, 3.5854001452231334],
					[61.01327234478314, 3.5854001366364385],
					[61.013272358564308, 3.5854001213044007],
					[61.013272366311575, 3.5854001125650394],
					[61.013441865844158, 3.585150111308431],
					[61.013441870984302, 3.5851501048683283],
					[61.013441880148513, 3.5851500866167965],
					[61.013441885140431, 3.5851500764940143],
					[61.013569562697562, 3.5848445293453435],
					[61.013697313974824, 3.5847529635668289],
					[61.013983412217129, 3.5845584655425218],
					[61.013983414004606, 3.5845584652371305],
					[61.013983415717981, 3.5845584630903669],
					[61.013983416611715, 3.5845584629376708],
					[61.014013845934059, 3.5845363867450053],
					[61.01411373005012, 3.5846918049123797],
					[61.014460928194424, 3.5852807022778075],
					[61.014460933033661, 3.5852807107214333],
					[61.014460945319257, 3.5852807253092487],
					[61.014460951052229, 3.5852807336001979],
					[61.014508146983609, 3.5853334288005727],
					[61.014580442543789, 3.585425123396127],
					[61.014580446266947, 3.5854251264682437],
					[61.014580451032067, 3.5854251330705154],
					[61.014580452967763, 3.5854251364479799],
					[61.014797052633973, 3.585658436158937],
					[61.014797057250881, 3.5856584390784279],
					[61.01479706290975, 3.5856584455281064],
					[61.014797065739174, 3.5856584487529388],
					[61.014908166281614, 3.5857584490539365],
					[61.014908214909646, 3.5857584722668427],
					[61.014908319172022, 3.585758491538614],
					[61.014908372944809, 3.5857584860613603],
					[61.015041773394898, 3.5857057864450481],
					[61.015275071165625, 3.5856140872567517],
					[61.015358303234869, 3.5855835119719619],
					[61.015441637353042, 3.5856112905035831],
					[61.015441638246791, 3.5856112903509079],
					[61.015441639140541, 3.5856112901982309],
					[61.015441640108371, 3.5856112918870147],
					[61.015688839622982, 3.5856890904189123],
					[61.015688845953292, 3.585689091191655],
					[61.015688857794238, 3.5856890947313],
					[61.015688863304881, 3.5856890974982121],
					[61.015808221748436, 3.5857112881408337],
					[61.015991587355941, 3.5858362654670031],
					[61.015991588249676, 3.5858362653143341],
					[61.01599159011127, 3.5858362668504769],
					[61.015991591079114, 3.5858362685392948],
					[61.016047090811924, 3.5858723685183027],
					[61.016047107343901, 3.5858723768191823],
					[61.01604713936586, 3.5858723898906337],
					[61.016047155749597, 3.5858723945085291],
					[61.016255545400597, 3.5859195923661917],
					[61.016449935346223, 3.5859862889048197],
					[61.016449940856866, 3.5859862916718446],
					[61.016449951729982, 3.5859862935228208],
					[61.016449957166522, 3.5859862944483112],
					[61.016513856753598, 3.586000195293757],
					[61.016513860402647, 3.5860001965246115],
					[61.01651386405171, 3.5860001977554483],
					[61.016513865839194, 3.5860001974501046],
					[61.016705565734476, 3.5860334968815573],
					[61.016705585545012, 3.586033497205841],
					[61.016705626953566, 3.5860334975490811],
					[61.016705646615911, 3.5860334941902954],
					[61.01690002362978, 3.5859862998802168],
					[61.016955596990563, 3.585986300032252],
					[61.017155594304853, 3.5859919005657588],
					[61.017155607710983, 3.5859918982756187],
					[61.017155636384864, 3.5859918952315439],
					[61.017155648897266, 3.5859918930940808],
					[61.017374988664358, 3.5859363094925381],
					[61.017599847782691, 3.5860195570872535],
					[61.017685910814095, 3.5862306656013629],
					[61.017738705179895, 3.5864083463093483],
					[61.017822000197981, 3.5868722180218913],
					[61.017821999607172, 3.5868749995095754],
					[61.017821999977592, 3.586875008717596],
					[61.017822001612195, 3.5868750269809651],
					[61.017822002876365, 3.5868750360363295],
					[61.017905403415661, 3.5873361365399439],
					[61.017905403563823, 3.5873361402231567],
					[61.017905405647618, 3.587336147284315],
					[61.017905406689515, 3.5873361508149042],
					[61.017955404721562, 3.5875250463159798],
					[61.018044202847705, 3.5879444345239069],
					[61.018077602401519, 3.5881917256733766],
					[61.018085900849677, 3.5882639231563251],
					[61.018085901891538, 3.5882639266869516],
					[61.018085903155615, 3.5882639357424502],
					[61.018085904197505, 3.5882639392730726],
					[61.018116503337737, 3.5884194368888274],
					[61.018197002292524, 3.5888639273909719],
					[61.018230401026898, 3.5891917166995357],
					[61.018263700168689, 3.5896639126245851],
					[61.018291500290445, 3.5901194119927688],
					[61.018291500364498, 3.5901194138344055],
					[61.018291501332278, 3.590119415523477],
					[61.018291501332278, 3.590119415523477],
					[61.018344198699225, 3.5907610931518654],
					[61.018299802091462, 3.5910443689048108],
					[61.018299802313543, 3.5910443744297522],
					[61.018299800896202, 3.5910443839430553],
					[61.018299800150487, 3.5910443877788816],
					[61.018290133808684, 3.5912014835902228],
					[61.018119399091304, 3.5912025991966514],
					[61.018119384791305, 3.5912026016372192],
					[61.018119358798529, 3.5912026042191179],
					[61.018119344572526, 3.5912026085013213],
					[61.018099980189639, 3.591208097726319],
					[61.017661114746979, 3.5911748003022548],
					[61.017661111097944, 3.5911747990707847],
					[61.01766110394793, 3.5911748002910535],
					[61.017661100298916, 3.5911747990595813],
					[61.017650011998825, 3.5911748001368564],
					[61.017422224251462, 3.5911470012707158],
					[61.017422223357713, 3.5911470014232472],
					[61.017422220676465, 3.5911470018808447],
					[61.017422219708699, 3.5911470001917842],
					[61.017200057406178, 3.5911248054907685],
					[61.017188990280324, 3.5911192217797994],
					[61.01718898290823, 3.5911192174753075],
					[61.017188970099632, 3.5911192122444313],
					[61.017188962801569, 3.5911192097815334],
					[61.016752895434983, 3.5909748210373982],
					[61.016633422148232, 3.5908831406113828],
					[61.016633400105981, 3.5908831295398413],
					[61.016633353414292, 3.5908831096959419],
					[61.01663332883885, 3.5908831027650967],
					[61.016308328749247, 3.5908359026177252],
					[61.016308324206449, 3.590835901538874],
					[61.016308316088676, 3.5908359010701414],
					[61.016308310652128, 3.5908359001438233],
					[61.016163911252121, 3.5908275997479491],
					[61.016163907677139, 3.5908276003580797],
					[61.016163899559345, 3.5908275998893702],
					[61.016163895984342, 3.5908276004995048],
					[61.01585830200343, 3.5908330991468151],
					[61.0157167076581, 3.5908276000046655],
					[61.015716706764351, 3.5908276001571959],
					[61.015550015112538, 3.5908219992240222],
					[61.015408322839598, 3.5908054014357749],
					[61.015408312040563, 3.590805401424658],
					[61.01540828776124, 3.5908054018599973],
					[61.015408275994432, 3.5908054001599377],
					[61.015269377977631, 3.5908220016660466],
					[61.015083278827021, 3.5908415002235219],
					[61.015083276219805, 3.5908415025225566],
					[61.015083269069791, 3.5908415037427708],
					[61.015083265420756, 3.590841502511422],
					[61.014958265283141, 3.59086370253877],
					[61.014958259920647, 3.5908637034539215],
					[61.01495825105718, 3.5908637068206364],
					[61.014958245694665, 3.5908637077357901],
					[61.014858247977294, 3.590891506293215],
					[61.014572149000216, 3.5909665077604425],
					[61.014572131199238, 3.5909665126522667],
					[61.01457209678712, 3.5909665296491102],
					[61.01457208099572, 3.5909665397601835],
					[61.014508182589346, 3.5910137372628901],
					[61.014480492691064, 3.5910331318141147],
					[61.014377700606175, 3.5910915252145652],
					[61.014377697179199, 3.5910915295074735],
					[61.014377688389764, 3.5910915347154511],
					[61.014377683995029, 3.5910915373194428],
					[61.014144286349833, 3.5912581350735229],
					[61.014058203017029, 3.5913165237167064],
					[61.013916621086054, 3.5913776166507083],
					[61.013916607008106, 3.5913776246149403],
					[61.013916580639695, 3.5913776402384139],
					[61.013916568423248, 3.5913776497390457],
					[61.013802667702578, 3.591477649347488],
					[61.013802666808814, 3.59147764949999],
					[61.013802665989083, 3.5914776514938751],
					[61.013802665095334, 3.5914776516463744],
					[61.013610998248708, 3.5916526224739909],
					[61.013461039998276, 3.5916998095523383],
					[61.013274940136334, 3.5917581089704385],
					[61.01327492687809, 3.5917581149404851],
					[61.013274902149099, 3.591758126575618],
					[61.013274889784604, 3.5917581323931751],
					[61.013161023392648, 3.5918331105947048],
					[61.013088862420602, 3.5918470036809982],
					[61.012744362089144, 3.5919137048788374],
					[61.01274435933388, 3.5919137034949582],
					[61.012744353971364, 3.5919137044098517],
					[61.012744352257876, 3.5919137065561362],
					[61.012711075042787, 3.5919219998528007],
					[61.012680599698207, 3.5919220007168553],
					[61.01268059694295, 3.591921999332961],
					[61.012680589792922, 3.5919220005528119],
					[61.012680587037657, 3.5919219991689366],
					[61.012261092782914, 3.5919497996766339],
					[61.012250000825453, 3.5919497991533844],
					[61.011811100791228, 3.5919469996032172],
					[61.011811099897464, 3.5919469997556921],
					[61.011802808392048, 3.5919469997708688],
					[61.011363936895755, 3.5919137026299648],
					[61.011336157071831, 3.5919054072742602],
					[61.011336152529047, 3.5919054061953877],
					[61.011336144411274, 3.5919054057264028],
					[61.01133613986849, 3.5919054046475316],
					[61.01091398386302, 3.5918192139248033],
					[61.01081402041553, 3.5917442392047647],
					[61.010814008426685, 3.5917442319808677],
					[61.010813982735499, 3.5917442196792337],
					[61.010813969852919, 3.5917442126078254],
					[61.010466806355019, 3.5916137256500225],
					[61.010189034337209, 3.5913637506414124],
					[61.010189019519068, 3.5913637401928882],
					[61.010188989956781, 3.5913637211370144],
					[61.01018897528666, 3.5913637143708352],
					[61.010016808754621, 3.5912942280908986],
					[61.009700135357605, 3.5910026536602642],
					[61.00970012969902, 3.5910026472106029],
					[61.009700117636129, 3.5910026381460769],
					[61.009700112051533, 3.5910026335375638],
					[61.009572325831286, 3.5909165446685605],
					[61.009494538783706, 3.5908415569561192],
					[61.009494533125114, 3.590841550506529],
					[61.009494521062201, 3.5908415414421184],
					[61.009494515477627, 3.5908415368336759],
					[61.009125126295316, 3.5905831444209628],
					[61.008761236858241, 3.5902415548648619],
					[61.008761232093363, 3.5902415482630383],
					[61.008761220104439, 3.5902415410401418],
					[61.008761215413607, 3.5902415362793825],
					[61.008677915412996, 3.5901831362196712],
					[61.008677913625498, 3.5901831365246784],
					[61.008677912657731, 3.5901831348361011],
					[61.008677911689951, 3.5901831331475234],
					[61.008489011540412, 3.5900554336929824],
					[61.008488999551489, 3.5900554264702138],
					[61.008488973860167, 3.5900554141707586],
					[61.00848896105154, 3.5900554089415815],
					[61.008227861023052, 3.5899720091405616],
					[61.008227845607195, 3.589972006210036],
					[61.008227814775502, 3.5899720003489657],
					[61.008227800327411, 3.5899719991069707],
					[61.008188910086531, 3.5899719990618046],
					[61.007783320296888, 3.5899304006041821],
					[61.00778331761564, 3.589930401061701],
					[61.007783312179122, 3.5899304001357089],
					[61.007783310391595, 3.5899304004407249],
					[61.00772501040457, 3.5899275990479778],
					[61.00772500504209, 3.5899275999630116],
					[61.007724996030547, 3.5899275996470488],
					[61.007724992455543, 3.5899276002570746],
					[61.00733061318099, 3.5899441995507164],
					[61.007283335336098, 3.5899359028010172],
					[61.007283325430805, 3.5899359026375524],
					[61.007283306439959, 3.5899359003171192],
					[61.007283295640903, 3.5899359003061417],
					[61.00688063658891, 3.589944198824075],
					[61.006833376293606, 3.589924814578394],
					[61.006833366240251, 3.5899248107329784],
					[61.006833346281596, 3.5899248067240817],
					[61.006833336228247, 3.5899248028786528],
					[61.006436171686254, 3.5898526095416288],
					[61.006325102733484, 3.5897859294358265],
					[61.006325095287352, 3.5897859232920446],
					[61.006325077861881, 3.5897859151438904],
					[61.006325068702267, 3.5897859111460755],
					[61.005986201613744, 3.5896609242745696],
					[61.005705728544747, 3.5894248464738197],
					[61.0057057210986, 3.589424840330234],
					[61.005705705386553, 3.5894248300364979],
					[61.005705696152894, 3.5894248241979327],
					[61.005539026206108, 3.5893331403915525],
					[61.005305749221883, 3.5890720661489652],
					[61.005305743637237, 3.58907206154141],
					[61.005305732393943, 3.5890720504853757],
					[61.005305726809304, 3.5890720458778191],
					[61.005091835248749, 3.5888970526302209],
					[61.004927943239366, 3.5887304607008947],
					[61.004927938622536, 3.5887304577817667],
					[61.004927932963831, 3.588730451333463],
					[61.004927930134492, 3.5887304481092941],
					[61.004647329723412, 3.5884915475596424],
					[61.004647322277194, 3.5884915414164751],
					[61.004647306565118, 3.588491531123521],
					[61.00464730008671, 3.5884915266686677],
					[61.004269500393178, 3.5882748272819436],
					[61.00426949302102, 3.5882748229797157],
					[61.004269479244506, 3.5882748160635805],
					[61.004269471946422, 3.5882748136021698],
					[61.004197271696619, 3.588247013780522],
					[61.004197252557631, 3.5882470077795818],
					[61.004197215395585, 3.5882470011476353],
					[61.004197195585014, 3.5882470008216756],
					[61.003813895712717, 3.588255400314782],
					[61.003813892063668, 3.5882553990841042],
					[61.003813885807439, 3.588255400151803],
					[61.003813883126192, 3.5882554006093894],
					[61.003749987631018, 3.5882609004981236],
					[61.003374994336738, 3.5882720001109507],
					[61.003374991655477, 3.5882720005685314],
					[61.003374987112693, 3.588271999490388],
					[61.003374985399262, 3.5882720016362071],
					[61.003300009918306, 3.5882775981551198],
					[61.002900035941536, 3.5882054027251051],
					[61.002900028717484, 3.5882054021045628],
					[61.002900016950655, 3.5882054004058976],
					[61.002900010620365, 3.5882053996328267],
					[61.00285003816353, 3.58820260176527],
					[61.002536164052131, 3.5880970103678522],
					[61.002536157721835, 3.5880970095947928],
					[61.002536146774695, 3.5880970059029353],
					[61.002536141264081, 3.5880970031366326],
					[61.002402872370197, 3.5880692110279813],
					[61.002383399239349, 3.5880581263083138],
					[61.002383388218142, 3.5880581207757571],
					[61.002383365356046, 3.588058111703897],
					[61.002383352547326, 3.5880581064763946],
					[61.001952888387414, 3.5879387176914017],
					[61.001841816723861, 3.5878581385305304],
					[61.001841803767029, 3.5878581296218255],
					[61.001841776214, 3.5878581157908478],
					[61.001841760650002, 3.5878581091804023],
					[61.001505694322042, 3.5877498192459618],
					[61.001258420920074, 3.5875609418697563],
					[61.001258406101663, 3.5875609314257302],
					[61.001258373931726, 3.5875609146765997],
					[61.001258358367721, 3.5875609080664068],
					[61.001055691150988, 3.5874998189980429],
					[61.000633418550265, 3.5871859387319502],
					[61.000633401944334, 3.5871859285933856],
					[61.000633367093137, 3.5871859123025467],
					[61.000633347954114, 3.5871859063028118],
					[61.000611187840867, 3.5871804166316923],
					[61.000552921259832, 3.5871359405832446],
					[61.000552910164529, 3.5871359332106953],
					[61.000552885366744, 3.5871359207638331],
					[61.000552873377686, 3.5871359135438237],
					[61.000161184835655, 3.5869831178659419],
					[61.000069495736433, 3.5869331241331741],
					[61.000069490225819, 3.5869331213672622],
					[61.000069478310834, 3.586933115988018],
					[61.000069471906457, 3.5869331133746529],
					[60.999713988081979, 3.5867970205136954],
					[60.999497302884713, 3.5866665296678573],
					[60.999497298267826, 3.5866665267495095],
					[60.999497287172503, 3.5866665193773524],
					[60.999497280768125, 3.5866665167640814],
					[60.999266797218773, 3.5865637230495353],
					[60.998875112262638, 3.5862998335458949],
					[60.998875104890416, 3.5862998292447976],
					[60.99887509297541, 3.5862998238659713],
					[60.998875085603217, 3.5862998195648683],
					[60.998816795296001, 3.5862720241787951],
					[60.998661204023264, 3.5861776294156988],
					[60.998661194789506, 3.5861776235792697],
					[60.998661178257578, 3.5861776152823044],
					[60.998661169097886, 3.5861776112863661],
					[60.998372279392385, 3.5860720157172215],
					[60.998305689343354, 3.5860387201617701],
					[60.998305681151493, 3.5860387178538389],
					[60.998305662832088, 3.58603870986214],
					[60.998305652852729, 3.5860387078593083],
					[60.997925079962855, 3.5859331149512248],
					[60.997764002734037, 3.5858359285679517],
					[60.997763991712759, 3.5858359230369112],
					[60.99776396788269, 3.5858359122799146],
					[60.997763955148002, 3.5858359088944263],
					[60.997475085375783, 3.5857526153969639],
					[60.997189010824322, 3.5855609336468977],
					[60.997188997047694, 3.5855609267333133],
					[60.99718896584541, 3.5855609116759886],
					[60.997188950281306, 3.5855609050675268],
					[60.997027866814626, 3.5855192111886587],
					[60.996638983190913, 3.5853415187337436],
					[60.996638961296483, 3.5853415113530835],
					[60.996638915048706, 3.5853415025705599],
					[60.99663889062122, 3.5853414993283366],
					[60.996577842028238, 3.5853441971521969],
					[60.996297287188739, 3.5852081205268651],
					[60.996297281678103, 3.5852081177615669],
					[60.99629727065679, 3.5852081122309585],
					[60.99629726611397, 3.5852081111534462],
					[60.996130722750443, 3.5851498301438856],
					[60.99605575968183, 3.5850498799423884],
					[60.996055755884562, 3.5850498750316637],
					[60.996055747396312, 3.5850498653627478],
					[60.996055741811539, 3.5850498607571657],
					[60.995686258347526, 3.584674874449655],
					[60.995430768598844, 3.5842692928062383],
					[60.995430760930141, 3.5842692811448371],
					[60.995430742091948, 3.5842692602727055],
					[60.995430730848291, 3.5842692492216712],
					[60.995236230633395, 3.5840998498093848],
					[60.995236214846891, 3.5840998376811313],
					[60.99523618074091, 3.5840998175630001],
					[60.995236163315148, 3.5840998094205156],
					[60.995144462717988, 3.5840692108877508],
					[60.995144459962653, 3.5840692095052495],
					[60.995144453632356, 3.5840692087331125],
					[60.995144450877014, 3.5840692073506091],
					[60.994788950759123, 3.5839748078671114],
					[60.994788934375322, 3.5839748032527363],
					[60.994788900116653, 3.583974801690196],
					[60.994788882987315, 3.5839748009089338],
					[60.994658282945366, 3.5839859015573432],
					[60.994658280190038, 3.583985900174866],
					[60.994658273933851, 3.5839859012430022],
					[60.994658271252604, 3.5839859017007782],
					[60.994338870646487, 3.584033101330899],
					[60.994244370798249, 3.5840470024167193],
					[60.994244366329518, 3.5840470031796547],
					[60.994244354710858, 3.5840470051632849],
					[60.994244350242155, 3.5840470059262164],
					[60.993888875084664, 3.5841387004501066],
					[60.993844399927568, 3.5841387003311826],
					[60.993844389128554, 3.5841387003219598],
					[60.99384436671086, 3.5841387022963],
					[60.993844355985956, 3.5841387041272816],
					[60.993441678178861, 3.5842304002168603],
					[60.99343890022223, 3.5842304001338765],
					[60.993438892104422, 3.5842303996668679],
					[60.993438876017059, 3.5842304024132465],
					[60.99343886797336, 3.5842304037864374],
					[60.993422167662558, 3.5842331028693715],
					[60.993422162300092, 3.5842331037848303],
					[60.993422149787676, 3.5842331059209029],
					[60.993422143605585, 3.5842331088291179],
					[60.993049943630375, 3.584341508821498],
					[60.993049919721599, 3.5843415184614118],
					[60.993049875849522, 3.5843415463317529],
					[60.993049854918411, 3.5843415628745876],
					[60.992991635288078, 3.5844025797645402],
					[60.992583345967233, 3.5843054058350914],
					[60.992583302771131, 3.5843054057978851],
					[60.992583220620794, 3.5843054216743848],
					[60.992583181740692, 3.5843054394282396],
					[60.992541740658055, 3.5843358217452743],
					[60.992450159667065, 3.5842137808725929],
					[60.99245013896725, 3.5842137584673672],
					[60.992450091756055, 3.5842137257656885],
					[60.992450065096477, 3.5842137117889874],
					[60.992097320942008, 3.5840915296195348],
					[60.991947358176674, 3.5838998765710381],
					[60.991947351624049, 3.5838998702786826],
					[60.991947341200003, 3.5838998572362386],
					[60.99194733472148, 3.5838998527839938],
					[60.991650158931016, 3.5836276743418174],
					[60.991450174309314, 3.5832721019502651],
					[60.991450160981657, 3.5832720838458099],
					[60.991450129931742, 3.5832720502399167],
					[60.991450112209492, 3.5832720347384992],
					[60.991200111999781, 3.5831026346397916],
					[60.99120010276588, 3.5831026288055261],
					[60.991200084372224, 3.5831026189770547],
					[60.991200074244588, 3.5831026132953818],
					[60.991102874439072, 3.5830637148480662],
					[60.991102869896253, 3.5830637137709975],
					[60.991102860736468, 3.583063709776801],
					[60.991102856193656, 3.5830637086997301],
					[60.990752855661171, 3.582960908114837],
					[60.990752842926419, 3.5829609047310909],
					[60.990752816711421, 3.5829609017962545],
					[60.990752804050821, 3.5829609002525453],
					[60.990608304116407, 3.5829581003003752],
					[60.990608302328916, 3.5829581006055635],
					[60.990608297786082, 3.582958099528526],
					[60.990608295998598, 3.5829580998337138],
					[60.990302802351003, 3.5829636992235474],
					[60.990161108020324, 3.5829581006678302],
					[60.990161106158716, 3.5829580991330281],
					[60.990161101690013, 3.5829580998959858],
					[60.990161099902529, 3.5829581002011706],
					[60.989855614629477, 3.5829580992153232],
					[60.989708330145646, 3.5829359015037374],
					[60.989708323889467, 3.5829359025718612],
					[60.989708312122573, 3.5829359008755888],
					[60.989708304898521, 3.5829359002563437],
					[60.989405617804273, 3.5829275998421282],
					[60.9892472310462, 3.5829026016352015],
					[60.989247220247172, 3.5829026016263188],
					[60.989247195893796, 3.5829026002263804],
					[60.989247185094769, 3.5829026002174977],
					[60.988955615434733, 3.5829247979434506],
					[60.988797244235876, 3.5828887041666335],
					[60.988797225319054, 3.5828887036911046],
					[60.988797184730061, 3.5828887013578798],
					[60.988797165887362, 3.5828887027222622],
					[60.988511119015207, 3.5829386939156804],
					[60.9883417710586, 3.582874813434382],
					[60.988341742907885, 3.5828748071251391],
					[60.988341686902949, 3.5828748018661902],
					[60.988341659122796, 3.582874804756377],
					[60.988061149405844, 3.5829330865175502],
					[60.987850120577278, 3.5827720410871815],
					[60.987850096598798, 3.5827720266557566],
					[60.987850046331182, 3.5827720074499423],
					[60.987850019074202, 3.5827720009882826],
					[60.987614009242265, 3.5827498082958633],
					[60.987269561687178, 3.5822720831932831],
					[60.98726954664604, 3.5822720672365529],
					[60.987269514850446, 3.5822720374680919],
					[60.987269497276351, 3.5822720256487934],
					[60.987164016036452, 3.5822137353301011],
					[60.987116831835849, 3.5821720507258967],
					[60.987116824315279, 3.5821720427475912],
					[60.987116805847435, 3.5821720310809786],
					[60.987116795719778, 3.5821720254002853],
					[60.986716813744714, 3.5819526350926552],
					[60.986469529741463, 3.5817415474084822],
					[60.986469507624484, 3.5817415345129802],
					[60.986469462645076, 3.5817415125541556],
					[60.986469438888889, 3.5817415036433986],
					[60.986272239004791, 3.5817026041264719],
					[60.986272201171154, 3.5817026031767312],
					[60.986272128852299, 3.5817026173773097],
					[60.986272094292936, 3.581702630687869],
					[60.986002736600767, 3.5818720032464486],
					[60.985819387569698, 3.5818831000725786],
					[60.985819339604227, 3.5818831156716446],
					[60.985819258862655, 3.5818831665050914],
					[60.985819225266972, 3.58188320373181],
					[60.985622024799127, 3.582241604696895],
					[60.985622014963724, 3.5822416286046121],
					[60.985622002665245, 3.5822416807185604],
					[60.985622000053908, 3.5822417052453317],
				],
				[
					[60.944822000957913, 3.4845193861738735],
					[60.944822001572909, 3.4845194230703758],
					[60.944822004038954, 3.4845194392938788],
					[60.947577603749323, 3.4978861401491197],
					[60.947577672520332, 3.497886215207064],
					[60.947577840257509, 3.4978862675803755],
					[60.947577939148431, 3.4978862430584714],
					[60.949222338876716, 3.4962918434598049],
					[60.949222342375926, 3.4962918410033841],
					[60.949222348405442, 3.4962918344079359],
					[60.949222350935763, 3.496291830268921],
					[60.950016739694377, 3.4953752592164831],
					[60.951363794561573, 3.4962084695942264],
					[60.951363852806956, 3.49620848541303],
					[60.951363966823337, 3.4962084786182146],
					[60.95136402266963, 3.4962084578420942],
					[60.953400043349006, 3.49461692049629],
					[60.955147146105404, 3.4951057926911857],
					[60.955147162416353, 3.4951057954172406],
					[60.955147194069362, 3.4951057991864753],
					[60.95514721119865, 3.4951057999199837],
					[60.956836011302784, 3.495016905743638],
					[60.958127628805315, 3.4965445079345034],
					[60.958858080387053, 3.4981805971842195],
					[60.957830419120029, 3.5003666154686601],
					[60.95783041101123, 3.5003666372322941],
					[60.957830402243523, 3.5003666868733996],
					[60.95783039964693, 3.5003667113845292],
					[60.957941499532907, 3.5024556094545432],
					[60.958094200333605, 3.5055889090385657],
					[60.958094205035394, 3.5055889359888526],
					[60.958094222406501, 3.5055889866604661],
					[60.958094234182184, 3.5055890105363545],
					[60.959666534245656, 3.5079334102427695],
					[60.959666585423797, 3.5079334513709872],
					[60.959666702137724, 3.5079334885723341],
					[60.959666766036484, 3.5079334886304712],
					[60.961247136775711, 3.5073780334530702],
					[60.963249848007614, 3.5097251296967928],
					[60.963249918770195, 3.5097251655955199],
					[60.963250063922331, 3.5097251719742406],
					[60.963250139280646, 3.5097251441379576],
					[60.965627865688226, 3.5074419136385115],
					[60.967994266996477, 3.5078279801784618],
					[60.969449820948462, 3.5107528886276045],
					[60.969449845684807, 3.5107529213823812],
					[60.969449906249167, 3.5107529720135644],
					[60.969449941183527, 3.5107529900444647],
					[60.971033132034961, 3.5112445572043218],
					[60.971699779208414, 3.5140388365847719],
					[60.968524861356585, 3.5171165560425264],
					[60.968524831666734, 3.5171166444860238],
					[60.968524859939379, 3.5171168080898152],
					[60.968524917826905, 3.517116881411555],
					[60.97173318447031, 3.5185612677530438],
					[60.973360957628593, 3.5202112399539622],
					[60.973360969770795, 3.5202112508205361],
					[60.973360994873879, 3.5202112705606106],
					[60.973361008653455, 3.520211277441041],
					[60.974766566367911, 3.5209307550745534],
					[60.975610937631366, 3.5221057162087708],
					[60.975611001391734, 3.5221057570593652],
					[60.975611138281813, 3.5221057815869314],
					[60.97561121141149, 3.522105765263893],
					[60.976455600769746, 3.5215391394314786],
					[60.97717199961221, 3.5220084073782911],
					[60.977160899754253, 3.5247916984328294],
					[60.977160901396942, 3.5247917166693044],
					[60.977160907288379, 3.524791750840754],
					[60.977160911612025, 3.524791768614802],
					[60.977833112034396, 3.5266583683785213],
					[60.977833113077807, 3.5266583719026303],
					[60.977833115089759, 3.5266583771117515],
					[60.977833117026833, 3.5266583804817859],
					[60.979060916815747, 3.5294639801301058],
					[60.979060944378482, 3.5294640161255],
					[60.979061010597732, 3.5294640732396769],
					[60.979061051041541, 3.5294640940504158],
					[60.980522150735013, 3.5298362935270839],
					[60.980522218878015, 3.5298362873391813],
					[60.980522335344965, 3.5298362302227555],
					[60.980522382850111, 3.5298361812875418],
					[60.981033483258919, 3.528686180882691],
					[60.981033485565625, 3.5286861712240327],
					[60.981033492041263, 3.5286861534378833],
					[60.981033495241661, 3.5286861436251589],
					[60.981952951596917, 3.5246391359209417],
					[60.983194360103681, 3.5248918965666007],
					[60.983194440308054, 3.5248918771723066],
					[60.983194559073823, 3.5248917881458528],
					[60.983194599422553, 3.5248917182053354],
					[60.983427987654601, 3.5223418432406786],
					[60.98461662994125, 3.5218974399402025],
					[60.985233139919984, 3.5227195199495234],
					[60.985233178952157, 3.5227195502613626],
					[60.985233267884603, 3.5227195904845439],
					[60.985233316816299, 3.5227195987106015],
					[60.986358148657651, 3.522622414491702],
					[60.98721370473983, 3.5258972426742141],
					[60.987633103287749, 3.5282139354817823],
					[60.987633133461735, 3.5282139914136184],
					[60.987633221358784, 3.5282140725901776],
					[60.98763327826304, 3.5282140998287055],
					[60.988872094274164, 3.5283501902818402],
					[60.990613742284218, 3.5305862232943452],
					[60.990613804105379, 3.5305862608082381],
					[60.990613937269991, 3.5305862823171661],
					[60.99061400779464, 3.5305862683061333],
					[60.992027778217405, 3.5296808513537923],
					[60.992927665020304, 3.5305084462779446],
					[60.992927718274672, 3.530508472300979],
					[60.992927831479363, 3.5305084898404937],
					[60.992927888673854, 3.5305084799792237],
					[60.993830592017943, 3.5300585285405846],
					[60.995235997803803, 3.5308945715374054],
					[60.995236078312722, 3.5308945817453745],
					[60.995236219582111, 3.5308945370043081],
					[60.995236282204793, 3.5308944835872302],
					[60.996088994937523, 3.5290502721711983],
					[60.997274910147517, 3.5296501775643012],
					[60.997274931150876, 3.5296501850606501],
					[60.997274973901469, 3.529650196218749],
					[60.997274995648766, 3.5296501998804923],
					[60.998685932680971, 3.5296751974213629],
					[60.999010902975506, 3.5314222374357134],
					[60.999010903869163, 3.5314222372816197],
					[60.999010904018803, 3.5314222409623039],
					[60.999010904093637, 3.5314222428026634],
					[60.999235865392656, 3.5324692587319055],
					[60.998838846325292, 3.532580407606964],
					[60.998838774175006, 3.5325804923213417],
					[60.998838753802943, 3.5325806774504387],
					[60.998838806474822, 3.5325807777110949],
					[61.000863737852704, 3.5336584410540106],
					[61.002146998225122, 3.5371639291077526],
					[61.001963699849668, 3.5401805888680196],
					[61.00196370283242, 3.5401806180089661],
					[61.001963717809332, 3.5401806765927293],
					[61.001963728835143, 3.5401807043488711],
					[61.003252629082183, 3.5422918040815197],
					[61.003252664685043, 3.5422918387302116],
					[61.003252749442808, 3.5422918871602831],
					[61.003252796735673, 3.5422918994086778],
					[61.005961019584461, 3.542339098609566],
					[61.006858163783711, 3.5431668470324],
					[61.006858236846305, 3.5431668733847519],
					[61.006858378794576, 3.5431668674903549],
					[61.006858447605609, 3.5431668334027537],
					[61.008102948062266, 3.5417945346576238],
					[61.008102972781224, 3.541794478496501],
					[61.0081029869175, 3.5417943611294262],
					[61.008102973728505, 3.5417943022259597],
					[61.004747385697407, 3.5358138247878856],
					[61.00328360681744, 3.5304639486191389],
					[61.003372375222284, 3.5299168699083201],
					[61.003627838347612, 3.5298668961987745],
					[61.003627879900563, 3.5298668779092295],
					[61.00362795056553, 3.52986682308764],
					[61.003627979677518, 3.5298667865555982],
					[61.004127980222925, 3.5288278867916154],
					[61.004127986623537, 3.528827867151676],
					[61.004127996818639, 3.5288278301750302],
					[61.004127999569739, 3.5288278093112631],
					[61.004200198641783, 3.5274222292006452],
					[61.00451408676485, 3.5261944754909424],
					[61.004947301075319, 3.5254446255273137],
					[61.005649900076207, 3.5255918826951951],
					[61.006347053010586, 3.5262973353908604],
					[61.006972032058293, 3.5270168101354424],
					[61.00736651957493, 3.5278333866285227],
					[61.007366523449086, 3.5278333933748023],
					[61.007366530228886, 3.5278334051807771],
					[61.007366534103042, 3.5278334119270554],
					[61.007666534257481, 3.5282779112650346],
					[61.007666607996448, 3.5282779541510565],
					[61.007666760746901, 3.5282779704239893],
					[61.007666841470794, 3.5282779416615933],
					[61.007941705364992, 3.5280030787604821],
					[61.008341564129438, 3.5283751465501663],
					[61.008341612841107, 3.5283751715111999],
					[61.008341717109346, 3.5283751905911216],
					[61.008341771847149, 3.5283751867051008],
					[61.008622203548406, 3.5282669127380419],
					[61.009147106411191, 3.5284473787894455],
					[61.009505420080153, 3.5287140150683642],
					[61.009760905368424, 3.5298917422155571],
					[61.009760905368424, 3.5298917422155571],
					[61.009760905443287, 3.5298917440565343],
					[61.009760904549623, 3.5298917442107389],
					[61.009883105215856, 3.5304250456369157],
					[61.009883195643482, 3.5304251227288348],
					[61.009883387789706, 3.530425134069211],
					[61.009883487721019, 3.5304250686260494],
					[61.010186274832044, 3.5295890038113651],
					[61.010361075281629, 3.5293920306912558],
					[61.010552631926956, 3.5296890083523134],
					[61.010552638631758, 3.5296890183188352],
					[61.010552652785236, 3.5296890344156622],
					[61.010552661277345, 3.5296890440737525],
					[61.010794261371792, 3.5299223443552417],
					[61.010794318275885, 3.5299223716145445],
					[61.010794434980347, 3.5299223867006093],
					[61.01079449656806, 3.529922374218958],
					[61.011250097238211, 3.529669575105788],
					[61.011250124193076, 3.5296695519144206],
					[61.011250169760388, 3.52966949955549],
					[61.011250188298, 3.5296694685469001],
					[61.011666808582191, 3.5285335839989114],
					[61.011824827897819, 3.5286168359357459],
					[61.011947006390173, 3.5290944508428042],
					[61.011947049225249, 3.5290945083399099],
					[61.011947164750936, 3.5290945829549942],
					[61.011947237291828, 3.5290945963908005],
					[61.01245824940623, 3.5289974129052295],
					[61.012805477397322, 3.5292668585348101],
					[61.012805515010619, 3.5292668761456096],
					[61.012805594106567, 3.5292668958669262],
					[61.012805634620712, 3.5292668962905123],
					[61.012961011203259, 3.5292391181401999],
					[61.013455438228995, 3.5299279177945575],
					[61.013455439122652, 3.5299279176403298],
					[61.013455439197507, 3.5299279194815019],
					[61.013888739473941, 3.5305140198017955],
					[61.013888775823737, 3.5305140506114809],
					[61.013888861104874, 3.5305140896643756],
					[61.013888909217407, 3.5305140999030162],
					[61.014663855160393, 3.5304808017706253],
					[61.01529710783074, 3.5308084788656404],
					[61.015297127865523, 3.5308084846793246],
					[61.015297169797257, 3.5308084978395482],
					[61.015297192363327, 3.5308084995080224],
					[61.015786091791178, 3.5308280007911339],
					[61.015786134612043, 3.5308279915473477],
					[61.015786214293065, 3.5308279592545606],
					[61.015786249216191, 3.5308279328313712],
					[61.016083289312796, 3.5304947116184744],
					[61.016574842331053, 3.5311279235406134],
					[61.016574863858253, 3.5311279439313714],
					[61.016574913837388, 3.5311279779550704],
					[61.016574942289346, 3.5311279915880229],
					[61.017252645123655, 3.5313334618631869],
					[61.017605411740263, 3.5323278666446227],
					[61.017605413752051, 3.53232787186053],
					[61.017605417775691, 3.5323278822923214],
					[61.017605420681171, 3.5323278873540391],
					[61.018333121237113, 3.533805687474544],
					[61.018333217095865, 3.5338057432602383],
					[61.018333401120124, 3.5338057319155314],
					[61.018333490179323, 3.5338056646309739],
					[61.018772374014695, 3.5325001124239486],
					[61.019294500174823, 3.5320112803977493],
					[61.019638737713741, 3.5319196506674624],
					[61.01974420320991, 3.5325667314887936],
					[61.019744220272841, 3.5325667749062046],
					[61.019744272716714, 3.532566847453662],
					[61.01974430809765, 3.532566876583707],
					[61.020138808244035, 3.5327723773290742],
					[61.020138819267302, 3.5327723828450726],
					[61.020138843919973, 3.5327723915729154],
					[61.020138855762056, 3.5327723950931551],
					[61.020816617981431, 3.5329251850783878],
					[61.021319276975277, 3.5332751578626049],
					[61.021747035094492, 3.5336418225635131],
					[61.021949817219074, 3.5341056791012866],
					[61.021949852304502, 3.5341057231225745],
					[61.021949940194993, 3.5341057821441342],
					[61.021949992181213, 3.5341057991402516],
					[61.022441522843998, 3.5341251927909929],
					[61.022552602138589, 3.5349972257103182],
					[61.022552602213381, 3.5349972275520511],
					[61.022552602362985, 3.5349972312354843],
					[61.022552603331434, 3.534997232923057],
					[61.022788702989999, 3.5364000328840612],
					[61.022788735023475, 3.5364000904176405],
					[61.022788828428276, 3.536400174460832],
					[61.022788888831158, 3.5364001992828538],
					[61.023255534172492, 3.5364251975610475],
					[61.023708133377184, 3.5367307285005509],
					[61.023852598361287, 3.5371500291379476],
					[61.023810900121845, 3.538074991785193],
					[61.023810900271357, 3.5380749954687891],
					[61.023810899826202, 3.5380750066736613],
					[61.023810899975715, 3.5380750103572587],
					[61.023944199850625, 3.5406417103705992],
					[61.023944210356497, 3.5406417475088028],
					[61.023944241942893, 3.5406418162536903],
					[61.023944264810758, 3.5406418475523536],
					[61.024399809042286, 3.5410583956489896],
					[61.024483101046897, 3.541877819716591],
					[61.024483104325547, 3.541877833989528],
					[61.024483109989177, 3.5418778626893523],
					[61.024483115055219, 3.5418778766543451],
					[61.024716503908095, 3.5424472472222575],
					[61.024808100688723, 3.5434611176590503],
					[61.024808100912779, 3.5434611231846835],
					[61.024808103073532, 3.5434611320862275],
					[61.024808104191266, 3.5434611374579243],
					[61.025035904203612, 3.5446444376121198],
					[61.025035907407336, 3.5446444500436978],
					[61.025035915676746, 3.5446444764409888],
					[61.025035920667825, 3.5446444885647885],
					[61.025538716268542, 3.5456639806129613],
					[61.02614981283466, 3.5472778704652015],
					[61.026149815888871, 3.5472778792135644],
					[61.026149823710021, 3.5472778945606711],
					[61.026149827583275, 3.5472779013132536],
					[61.02658042813637, 3.5480057027367717],
					[61.026580453609064, 3.5480057317399343],
					[61.026580513937255, 3.5480057770024667],
					[61.026580548792737, 3.5480057932618556],
					[61.027077749264897, 3.5481362927010514],
					[61.027077808621222, 3.5481362917601591],
					[61.02707791586171, 3.5481362510457286],
					[61.02707796389511, 3.5481362149562257],
					[61.027711236848617, 3.547236253477736],
					[61.028163781223277, 3.5469863931990484],
					[61.028374809045303, 3.5476722592928938],
					[61.028374811056544, 3.5476722645115242],
					[61.028374816121989, 3.5476722784791757],
					[61.028374819026929, 3.5476722835439785],
					[61.029110912198576, 3.5492611709649484],
					[61.029469207903183, 3.550475056695936],
					[61.029469213936636, 3.5504750723529344],
					[61.029469228609983, 3.5504751013634985],
					[61.029469238218184, 3.5504751164054857],
					[61.029822029321181, 3.5509668048629992],
					[61.03032762331835, 3.5519306929390386],
					[61.030327628159668, 3.5519307013815657],
					[61.030327637767726, 3.5519307164243958],
					[61.030327642609016, 3.5519307248669238],
					[61.031444232583482, 3.5533390109102623],
					[61.031666524848418, 3.5537417970554692],
					[61.03166652678491, 3.5537418004327228],
					[61.031666530657866, 3.5537418071872495],
					[61.031666532594357, 3.5537418105645031],
					[61.032355433204138, 3.5547834095707591],
					[61.032355455845348, 3.554783435360743],
					[61.032355508723811, 3.5547834745037754],
					[61.032355538141879, 3.5547834898528268],
					[61.032905479724214, 3.5549612718923584],
					[61.033505442012562, 3.5557390212786197],
					[61.033505446853617, 3.5557390297225657],
					[61.033505458993353, 3.55573904062229],
					[61.033505464653594, 3.55573904707019],
					[61.033960925880386, 3.5561584111072455],
					[61.034405409970049, 3.5575222627770304],
					[61.034405438792412, 3.557522307916309],
					[61.03440551706646, 3.5575223742415862],
					[61.034405565698918, 3.5575223974236421],
					[61.034869229262696, 3.5576029731870888],
					[61.035102603358808, 3.5588417370689833],
					[61.03510260455041, 3.5588417442855169],
					[61.035102608795519, 3.5588417602539759],
					[61.035102611774555, 3.5588417671634014],
					[61.035510907848035, 3.5599917560143499],
					[61.035733104883704, 3.5609528447325598],
					[61.03573316528211, 3.5609529141389333],
					[61.035733318029784, 3.560952975105359],
					[61.035733410379024, 3.5609529666653996],
					[61.036208408564967, 3.5606418664541821],
					[61.036688836643776, 3.5603280796040782],
					[61.037210919956962, 3.5611778891826003],
					[61.037616513759048, 3.5622167724487626],
					[61.037616527313034, 3.5622167960976028],
					[61.037616560602466, 3.5622168404782846],
					[61.037616581231617, 3.5622168610566511],
					[61.03833878072637, 3.5627529604629307],
					[61.038338830102887, 3.5627529798167283],
					[61.038338932208056, 3.5627529901161874],
					[61.038338984042994, 3.5627529812153265],
					[61.038741739822399, 3.5625669023871489],
					[61.039233292384218, 3.5625863001702638],
					[61.03923331926979, 3.5625862974088305],
					[61.039233370955841, 3.5625862848218386],
					[61.039233396650019, 3.5625862748427854],
					[61.039841657879407, 3.5622502512232836],
					[61.040058152140418, 3.5624890348505547],
					[61.040058157800402, 3.5624890413009416],
					[61.040058171801448, 3.5624890537412628],
					[61.040058180142516, 3.5624890597312096],
					[61.040588780414602, 3.5628890594524218],
					[61.040588797990424, 3.5628890712792476],
					[61.040588834631784, 3.5628890872545114],
					[61.040588854739866, 3.5628890949352012],
					[61.041266654868423, 3.56304459587348],
					[61.041266719364067, 3.5630445885091793],
					[61.041266829737594, 3.5630445361529888],
					[61.04126687658362, 3.5630444928505152],
					[61.041625176863448, 3.5623639939209717],
					[61.041625187886567, 3.5623639549138595],
					[61.041625194665421, 3.5623638776661477],
					[61.04162519034675, 3.5623638375826254],
					[61.041214089574659, 3.5611027382700682],
					[61.041214057997792, 3.5611026917358006],
					[61.041213972352274, 3.5611026210878061],
					[61.041213919326282, 3.5611026005063029],
					[61.040619542043622, 3.5610470114677755],
					[61.039894587191313, 3.559174927822367],
					[61.03989455397538, 3.5591748852846625],
					[61.039894472201681, 3.5591748214015904],
					[61.039894422750187, 3.5591748002097807],
					[61.03896404035693, 3.5590692141781379],
					[61.0382418852668, 3.5573082233104079],
					[61.03824187744668, 3.5573082079550544],
					[61.038241857263486, 3.5573081761697223],
					[61.038241845943077, 3.5573081632715402],
					[61.038108502685809, 3.5571666232195325],
					[61.038130800395649, 3.5564944067409856],
					[61.038130798682317, 3.5564943866250474],
					[61.038130791755364, 3.5564943488504288],
					[61.038130787286377, 3.5564943273527097],
					[61.037872387198043, 3.5558193281003856],
					[61.037872379377788, 3.5558193127455784],
					[61.037872362024373, 3.5558192841859619],
					[61.037872351523021, 3.5558192692921367],
					[61.037005770956959, 3.5548109920607573],
					[61.036439087250677, 3.5535221272636175],
					[61.035794589447178, 3.5516360356082131],
					[61.035794586393244, 3.5516360268567952],
					[61.035794578572506, 3.5516360115040477],
					[61.035794574624852, 3.5516360029063936],
					[61.035458491040771, 3.5510305323770677],
					[61.035152996658915, 3.5493027652865066],
					[61.035152993380976, 3.5493027510080131],
					[61.035152985112362, 3.5493027246011906],
					[61.035152979153374, 3.549302710784159],
					[61.033889079737669, 3.5467527136741763],
					[61.033039081618291, 3.5449082164160295],
					[61.033039080649921, 3.5449082147276116],
					[61.03303907871323, 3.5449082113507648],
					[61.033039077744895, 3.5449082096623212],
					[61.032336278202855, 3.5435332088251839],
					[61.032336277234506, 3.5435332071368206],
					[61.032336276266129, 3.5435332054484818],
					[61.032336275297737, 3.5435332037601404],
					[61.031752998014788, 3.5424721445050857],
					[61.031728000235709, 3.5418638918869219],
					[61.031727996658184, 3.5418638702416847],
					[61.031727986971511, 3.5418638310977792],
					[61.031727979893965, 3.5418638119108508],
					[61.03152799664695, 3.5414555471153255],
					[61.031472398653243, 3.5406860862812457],
					[61.03147239708705, 3.5406860698549965],
					[61.031472388592611, 3.5406860379267591],
					[61.031472384345406, 3.5406860219626486],
					[61.030902984207998, 3.5393443216859368],
					[61.030902963127097, 3.5393442900734744],
					[61.030902908752893, 3.5393442363744234],
					[61.030902878215315, 3.5393442156678652],
					[61.030533489916778, 3.5391887626480107],
					[61.030480799211432, 3.5384749858487754],
					[61.030480775146962, 3.538474925070465],
					[61.030480694105051, 3.5384748351601503],
					[61.030480638840231, 3.5384748038777687],
					[61.02961675092741, 3.538302606426373],
					[61.029072262163567, 3.5381248098409346],
					[61.029072256726792, 3.538124808923413],
					[61.029072246746914, 3.5381248069342774],
					[61.029072242203796, 3.5381248058626626],
					[61.028522364908326, 3.5380054312690934],
					[61.028369595338319, 3.5373638535909131],
					[61.028369564423102, 3.5373638014185937],
					[61.028369478541912, 3.5373637253359886],
					[61.028369423575938, 3.5373637014257069],
					[61.028186231598482, 3.5373415142658118],
					[61.02777797975908, 3.5364999137120035],
					[61.02777795689024, 3.5364998824127407],
					[61.027777901770897, 3.5364998325618413],
					[61.027777870414063, 3.5364998138560648],
					[61.027277945686734, 3.536313741618776],
					[61.026433481171217, 3.534522116788712],
					[61.025639081810674, 3.5327777173413941],
					[61.025639081735854, 3.532777715499487],
					[61.025639080767348, 3.532777713811829],
					[61.025639079798871, 3.5327777121241657],
					[61.024650180097979, 3.5307305132144653],
					[61.024650168550643, 3.5307304948056895],
					[61.024650143893226, 3.5307304638221821],
					[61.024650129739769, 3.5307304477180632],
					[61.024169562146554, 3.5303220746866741],
					[61.023266881981101, 3.528427716287287],
					[61.022158483274623, 3.525905519569696],
					[61.022158482306061, 3.5259055178824252],
					[61.022158481262544, 3.5259055143534943],
					[61.022158481262544, 3.5259055143534943],
					[61.021089081060225, 3.5236555131703424],
					[61.021089048058641, 3.5236554762194343],
					[61.021088969250336, 3.5236554193672092],
					[61.021088921731256, 3.5236554016163959],
					[61.018855622201542, 3.5234026002093612],
					[61.018855611402792, 3.5234026002212517],
					[61.018855587124349, 3.5234026007083776],
					[61.018855574613255, 3.5234026028706276],
					[61.01700557532056, 3.5236359013277117],
					[61.017005544192685, 3.5236359104154205],
					[61.017005485961235, 3.5236359390213119],
					[61.017005458857682, 3.5236359585394941],
					[61.016658158809371, 3.5239804571690638],
					[61.016658144291803, 3.5239804763662361],
					[61.016658119874762, 3.5239805176711894],
					[61.016658109975324, 3.523980539778965],
					[61.016544232006595, 3.5243359688572728],
					[61.016013839401708, 3.5246581035668543],
					[61.015574989177743, 3.5246831002966283],
					[61.015574938539324, 3.5246831164619588],
					[61.01557485447217, 3.5246831736328872],
					[61.015574820074853, 3.5246832129515671],
					[61.015219287688545, 3.5254191731237254],
					[61.014933340204756, 3.5253609045673744],
					[61.014933305052594, 3.525360903222674],
					[61.014933236166399, 3.5253609132676487],
					[61.014933203326002, 3.5253609245029631],
					[61.014783246706763, 3.525444201267796],
					[61.014136097411843, 3.5254525992504573],
					[61.01413609383723, 3.5254525998678923],
					[61.014136086613085, 3.525452599261564],
					[61.014136083113407, 3.5254526017201964],
					[61.013069401466282, 3.5255414997524217],
					[61.012475126728269, 3.5254859112298798],
					[61.012055780976453, 3.5246749146530569],
					[61.011622384868204, 3.5236388229303923],
					[61.011622370414266, 3.5236387994715774],
					[61.011622336219361, 3.5236387553213815],
					[61.011622315659707, 3.5236387366254398],
					[61.011252957758252, 3.5233748676285144],
					[61.010741881130954, 3.5222999157243233],
					[61.010461282175932, 3.5216860174846465],
					[61.010461269658876, 3.5216859974006578],
					[61.010461242987404, 3.5216859612235085],
					[61.010461227045703, 3.5216859454392324],
					[61.00985015760908, 3.5211859710916937],
					[61.00914738058205, 3.5198693147264271],
					[61.008008485332653, 3.5171860246576232],
					[61.007519588417225, 3.5159416325017681],
					[61.006808489737317, 3.5137360390395909],
					[61.006808486681031, 3.5137360302998042],
					[61.006808480718583, 3.5137360165017326],
					[61.006808477662311, 3.5137360077619446],
					[61.006336290315218, 3.5128221323362476],
					[61.00622239583673, 3.5122860584627129],
					[61.006222393749084, 3.5122860514092524],
					[61.006222389648897, 3.5122860391430475],
					[61.006222387636328, 3.5122860339302902],
					[61.00609189963621, 3.5119193645593625],
					[61.006089099704553, 3.5111416996630744],
					[61.006089094778041, 3.5111416671494462],
					[61.006089075245285, 3.5111416075054063],
					[61.006089059745399, 3.5111415805296922],
					[61.00597799865664, 3.5109943317674652],
					[61.005966899877933, 3.5100499986434275],
					[61.005966898157943, 3.5100499785506694],
					[61.005966891293653, 3.5100499426654879],
					[61.005966885180626, 3.5100499251871042],
					[61.004816885436597, 3.507199924950779],
					[61.004816883423864, 3.5071999197385777],
					[61.004816876642352, 3.5071999079380247],
					[61.004816873660793, 3.5071999010400337],
					[61.004097374175608, 3.5059443009819691],
					[61.004097372237986, 3.5059442976105255],
					[61.004097370300379, 3.5059442942390984],
					[61.004097368437947, 3.5059442927082269],
					[61.003491875772752, 3.5049999056329431],
					[61.002639083227443, 3.5030971173791143],
					[61.002639077414393, 3.5030971072657797],
					[61.002639064001102, 3.5030970873489666],
					[61.002639057294431, 3.5030970773905636],
					[61.002258469044051, 3.5026109911487771],
					[61.001994577336966, 3.5021055060551292],
					[61.001994546192449, 3.5021054706786803],
					[61.001994469388961, 3.5021054191235668],
					[61.001994423654736, 3.502105401104469],
					[60.999827823733028, 3.5018415008822972],
					[60.999827797667592, 3.5018415016946935],
					[60.999827746656038, 3.5018415086854846],
					[60.99982772089151, 3.5018415168591117],
					[60.998899920560476, 3.5022387151924668],
					[60.9988998891424, 3.5022387391718572],
					[60.998899834724952, 3.5022387949376816],
					[60.998899812694447, 3.5022388284094319],
					[60.998644262454313, 3.5028998023095737],
					[60.997666697217497, 3.5029137001954154],
					[60.997666654398884, 3.502913709470207],
					[60.997666577706809, 3.5029137487079254],
					[60.997666542789318, 3.5029137751453971],
					[60.997249889968764, 3.5034387178712318],
					[60.996966662601402, 3.5034915031468592],
					[60.996966615841089, 3.5034915260759139],
					[60.996966540201726, 3.5034915910732667],
					[60.996966511473111, 3.5034916368218489],
					[60.996824855286683, 3.5039081046144456],
					[60.995333292864586, 3.5039637003483115],
					[60.995333234336627, 3.5039637216082458],
					[60.995333141125307, 3.5039637970579913],
					[60.995333107260379, 3.5039638492528966],
					[60.995133138993673, 3.5047248262025623],
					[60.993647162775737, 3.5050026033763859],
					[60.993647107379573, 3.5050026352084251],
					[60.993647025567718, 3.5050027253528135],
					[60.993647001014487, 3.5050027851955208],
					[60.993594201031051, 3.5056832845837942],
					[60.993594200212591, 3.5056832865785275],
					[60.993594200362942, 3.5056832902584443],
					[60.993594200438118, 3.5056832920983929],
					[60.993583103849147, 3.5059638048288293],
					[60.993330587915246, 3.5061775489291223],
					[60.993016815256482, 3.505958135884244],
					[60.993016796857745, 3.5059581261002424],
					[60.993016759317001, 3.5059581103668762],
					[60.993016740174987, 3.5059581044174948],
					[60.992444439745213, 3.505841503391149],
					[60.992444376222537, 3.5058415125400813],
					[60.992444265947455, 3.5058415668444227],
					[60.992444220982321, 3.5058416116902995],
					[60.991988720944683, 3.5067610109905814],
					[60.991988710162552, 3.5067610554738309],
					[60.991988711089327, 3.5067611442514837],
					[60.991988721086116, 3.5067611906952321],
					[60.995016520561229, 3.5127667899897252],
					[60.995016625363938, 3.5127668441282198],
					[60.995016812359282, 3.5127668173382536],
					[60.995016897232816, 3.5127667359460277],
					[60.995166897411735, 3.5119583363271256],
					[60.995166898005003, 3.5119583288122702],
					[60.995166899191553, 3.5119583137825234],
					[60.995166899784813, 3.5119583062676676],
					[60.99520849918629, 3.5105028285073354],
					[60.995411289924689, 3.5097417629255352],
					[60.995739075239449, 3.5089001029950539],
					[60.996010970855167, 3.5085726689096726],
					[60.996180401572644, 3.5096528301571315],
					[60.99618040261651, 3.5096528336826833],
					[60.996180403810619, 3.5096528408884389],
					[60.996180404854506, 3.5096528444139907],
					[60.996277605359509, 3.510080644289002],
					[60.996277609534978, 3.5100806583912707],
					[60.996277620641933, 3.5100806879718762],
					[60.996277626604659, 3.5100807017647746],
					[60.996719193074171, 3.5108333431465115],
					[60.996644200908513, 3.5114610759738212],
					[60.996644238846869, 3.5114611676252387],
					[60.996644371818427, 3.5114612724817116],
					[60.996644467820367, 3.5114612873722657],
					[60.996972165172586, 3.5113419260661489],
					[60.997319276605936, 3.5116112577344638],
					[60.997319361959342, 3.5116112763215752],
					[60.997319514395414, 3.5116112406770297],
					[60.997319581402955, 3.5116111846051963],
					[60.997891586281469, 3.5103924133006923],
					[60.998005401214066, 3.5113167243334726],
					[60.998005402333028, 3.5113167296994807],
					[60.998005404420702, 3.5113167367510769],
					[60.998005403677269, 3.5113167405861745],
					[60.99832200353805, 3.5128167405968611],
					[60.998322005625653, 3.5128167476486278],
					[60.998322009800845, 3.5128167617521666],
					[60.998322011888455, 3.5128167688039174],
					[60.998724811785159, 3.5139111693380474],
					[60.998724863562579, 3.5139112252454567],
					[60.998724992868489, 3.5139112844210025],
					[60.998725070396951, 3.5139112876891434],
					[60.999030669974893, 3.5137973873255435],
					[60.999030711075036, 3.5137973579762938],
					[60.999030774651921, 3.5137972839666771],
					[60.999030796159921, 3.5137972376206181],
					[60.999097260090075, 3.5134507518321927],
					[60.999210911826999, 3.5137639682983695],
					[60.999210969116476, 3.5137640269594068],
					[60.999211108177391, 3.5137640825943364],
					[60.999211189948838, 3.5137640795682272],
					[60.999563740653976, 3.5135864039749634],
					[60.999710902227505, 3.5146167277590856],
					[60.999710902377629, 3.5146167314397458],
					[60.999710904465154, 3.5146167384919389],
					[60.999710903646573, 3.5146167404868418],
					[60.999999804259041, 3.5160111413328345],
					[60.999999871159353, 3.5160112150172154],
					[61.000000035391501, 3.5160112700169766],
					[61.000000132648289, 3.5160112494920228],
					[61.000397332997281, 3.5156584500856769],
					[61.000397356818517, 3.5156584163123528],
					[61.000397390831218, 3.5156583455627395],
					[61.000397400054034, 3.5156583069006531],
					[61.000419599058603, 3.5149306393070714],
					[61.00051100044859, 3.5146897262037102],
					[61.000591501709962, 3.51534172580183],
					[61.000591501709962, 3.51534172580183],
					[61.000591501860072, 3.5153417294825968],
					[61.000591501935105, 3.5153417313229802],
					[61.000694195391965, 3.5159916827492799],
					[61.000533111079974, 3.5164666368599797],
					[61.000533135016994, 3.5164667383624821],
					[61.00053326255491, 3.5164668645780455],
					[61.000533366974423, 3.5164668872961835],
					[61.000861011997578, 3.5163502431456575],
					[61.001277611769297, 3.5170528739917812],
					[61.001499805181837, 3.5180694425767993],
					[61.001499805256849, 3.518069444417264],
					[61.001499806300515, 3.5180694479436649],
					[61.001499806375527, 3.5180694497841105],
					[61.00174700237163, 3.519025032306077],
					[61.001799801020788, 3.5197389148907079],
					[61.00179983529798, 3.5197389831040975],
					[61.001799940704409, 3.519739074239987],
					[61.00180001287724, 3.5197391006890224],
					[61.002261113352915, 3.5197085001759243],
					[61.002261170021342, 3.5197084774079754],
					[61.002261258848556, 3.5197084045991796],
					[61.002261291901007, 3.5197083544039049],
					[61.002577991681548, 3.5185917557245641],
					[61.002577994881115, 3.5185917459040863],
					[61.002577997705622, 3.5185917268810543],
					[61.002577999117875, 3.5185917173695356],
					[61.002686234063752, 3.5173479650899693],
					[61.002994214720545, 3.5180139767154435],
					[61.003199800192952, 3.5185861345724221],
					[61.003197000279997, 3.5198138998574739],
					[61.00319699968631, 3.5198139073741137],
					[61.003197001254861, 3.5198139237846373],
					[61.003197002373462, 3.519813929151852],
					[61.003444201624355, 3.521436128831549],
					[61.003630391092109, 3.5229055451963234],
					[61.003094240045158, 3.5236192793732193],
					[61.003094235801697, 3.5236192856665842],
					[61.00309422902717, 3.5236192961040946],
					[61.003094224783723, 3.5236193023974747],
					[61.002608125172898, 3.5244888028025567],
					[61.002608116910679, 3.5244888209104595],
					[61.002608104929465, 3.5244888581952645],
					[61.002608101285368, 3.5244888792126794],
					[61.002499804367105, 3.5255138557066976],
					[61.002197011708709, 3.5263499327450436],
					[61.002197007020854, 3.5263499502352209],
					[61.002197001369602, 3.5263499882795464],
					[61.002197000406177, 3.5263500088337132],
					[61.002213697637295, 3.5267916434660656],
					[61.002044233052203, 3.5270470895730841],
					[61.002044218389287, 3.5270471273189981],
					[61.002044204405379, 3.5270472038697864],
					[61.002044205159315, 3.5270472445151775],
					[61.002097553071799, 3.5272860212030817],
					[60.996105779288769, 3.51525271174402],
					[60.996105778245031, 3.5152527082183047],
					[60.996105776307651, 3.51525270484712],
					[60.996105775263921, 3.5152527013213839],
					[60.993416875056283, 3.5104499026073905],
					[60.993416855163339, 3.5104498782556552],
					[60.993416811134573, 3.5104498358453169],
					[60.993416785211529, 3.5104498180960015],
					[60.988072304913004, 3.5079248286777922],
					[60.985483441193033, 3.5059109567552973],
					[60.98318345498781, 3.5030887732146345],
					[60.983183439044176, 3.5030887574526446],
					[60.983183402839174, 3.5030887303813136],
					[60.983183384365084, 3.5030887187623696],
					[60.981888987957859, 3.5024887206030586],
					[60.980480750270701, 3.5017692513780054],
					[60.978861278738108, 3.4984971111429273],
					[60.97886125310518, 3.4984970785434273],
					[60.978861190673868, 3.4984970263925108],
					[60.978861153875485, 3.4984970068410726],
					[60.975761192393854, 3.4976359176052245],
					[60.974266824180702, 3.4964526438452102],
					[60.974266813005599, 3.4964526346714093],
					[60.974266788125121, 3.4964526204661768],
					[60.974266776206974, 3.4964526151249209],
					[60.971616829003729, 3.4953665374954088],
					[60.97010296439332, 3.4932554856565474],
					[60.96679456564285, 3.4883304873136365],
					[60.96679455804167, 3.4883304775243351],
					[60.966794541052103, 3.4883304582559376],
					[60.966794531663702, 3.4883304487768241],
					[60.965447331933156, 3.4871526487576983],
					[60.96544731993928, 3.4871526415829752],
					[60.965447296769717, 3.4871526252401366],
					[60.965447283882263, 3.487152618220533],
					[60.963647284030309, 3.4863137192670455],
					[60.963647226979248, 3.4863137106590329],
					[60.96364711699087, 3.486313727902695],
					[60.963647064871729, 3.4863137517610614],
					[60.963025020846452, 3.4868830096577383],
					[60.960611254146656, 3.483947073813809],
					[60.960611243788939, 3.483947062654976],
					[60.960611222255366, 3.4839470423304673],
					[60.960611210185895, 3.4839470333199785],
					[60.957352934972185, 3.4818109487836035],
					[60.954316853776206, 3.4781692725367757],
					[60.954316812947795, 3.4781692426182356],
					[60.954316723336838, 3.4781692082230182],
					[60.954316673585204, 3.478169202064044],
					[60.948052773938095, 3.4790026028191354],
					[60.948052740132574, 3.4790026123928257],
					[60.948052676700009, 3.4790026456171592],
					[60.948052648784611, 3.4790026671200898],
					[60.946355448928713, 3.4809470681742671],
					[60.946355446398876, 3.4809470723140921],
					[60.946355441263748, 3.4809470787566057],
					[60.946355437840324, 3.4809470830516065],
					[60.945110938395324, 3.4826692826508636],
					[60.945110925745901, 3.4826693033485046],
					[60.945110908640331, 3.4826693470218579],
					[60.945110902321531, 3.4826693684707331],
					[60.94482200206626, 3.484519369330088],
					[60.944822000957913, 3.4845193861738735],
				],
			],
		},
		{
			fldNpdidField: "4467574",
			wlbNpdidWellbore: "1375",
			fldName: "GJØA",
			fldCurrentActivitySatus: "Producing",
			wlbName: "35/9-1",
			fldDiscoveryYear: "1989",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=4467574",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=4467574",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "153",
			cmpLongName: "Neptune Energy Norge AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=1375",
			fldHcType: "OIL/GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "31123361",
			fldNpdidOwner: "22036",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Florø",
			fldAreaGeometryWKT: [
				[
					[61.304533104571895, 3.93038607642139],
					[61.304533111001689, 3.9303861520785106],
					[61.30453312140493, 3.9303861896792252],
					[61.304738721098559, 3.9307972880843245],
					[61.304738744775527, 3.9307973197962776],
					[61.304738803336548, 3.9307973701827459],
					[61.304738837395973, 3.9307973908613678],
					[61.304847136847393, 3.9308334908157057],
					[61.304847154045888, 3.9308334936450029],
					[61.304847189337096, 3.9308334991588896],
					[61.304847207360211, 3.9308334999840531],
					[61.305166688754674, 3.9308224004345935],
					[61.305263861267782, 3.9308362962800278],
					[61.305494341687648, 3.9308945913553357],
					[61.305666591115198, 3.9309557729438147],
					[61.305724858596591, 3.9310140414946604],
					[61.305724858596591, 3.9310140414946604],
					[61.305724859560378, 3.9310140432094558],
					[61.305991559878002, 3.9312751422854926],
					[61.305991563593935, 3.9312751454257744],
					[61.305991571095412, 3.931275153565847],
					[61.305991574811344, 3.9312751567061284],
					[61.30611656195498, 3.9313751456758093],
					[61.306194235875076, 3.9314612166117597],
					[61.306372011119393, 3.9317889701763002],
					[61.306463705050362, 3.9322083429803256],
					[61.306463707256128, 3.9322083538482078],
					[61.306463714280696, 3.9322083732904733],
					[61.306463718274856, 3.9322083838690443],
					[61.306555417656945, 3.9324083836344119],
					[61.306555432937984, 3.9324084073535333],
					[61.306555468656519, 3.9324084483452917],
					[61.306555490057839, 3.9324084673327895],
					[61.30674988957329, 3.9325362664850751],
					[61.306749905192014, 3.9325362751833643],
					[61.306749937184605, 3.9325362887161992],
					[61.306749953627985, 3.9325362954102911],
					[61.306983238593396, 3.9325918915460303],
					[61.307038826282565, 3.9326140861098007],
					[61.307038829928921, 3.9326140873907756],
					[61.307038837221668, 3.9326140899527404],
					[61.307038840868003, 3.932614091233718],
					[61.307244340645646, 3.9326779919298112],
					[61.307244360526717, 3.9326779943263328],
					[61.30724440386566, 3.9326779985407714],
					[61.307244425465584, 3.9326779987884084],
					[61.307444385340851, 3.9326530034138516],
					[61.30749433569315, 3.9326668907539259],
					[61.307716618613917, 3.9327557832515274],
					[61.307897111777763, 3.9328445782277361],
					[61.307897128290698, 3.932844586781842],
					[61.307897161932551, 3.9328445963068908],
					[61.307897179131032, 3.9328445991374812],
					[61.307977779078143, 3.9328529996123187],
					[61.30829716095122, 3.9328862967658815],
					[61.308408228715841, 3.9329195872871789],
					[61.308686008431735, 3.9330473778306709],
					[61.308810999754698, 3.9331195735987365],
					[61.308811012621234, 3.9331195808721007],
					[61.308811040897872, 3.933119591265537],
					[61.30881105630796, 3.9331195943856248],
					[61.30887214869351, 3.9331334935908853],
					[61.309274942688376, 3.933252991380586],
					[61.309274953557868, 3.9332529933642926],
					[61.309274976191091, 3.9332529971870267],
					[61.309274987060626, 3.9332529991707288],
					[61.309658286973409, 3.9332779994026468],
					[61.309658289725569, 3.9332780008284352],
					[61.309658296879157, 3.9332779996712857],
					[61.309658299561775, 3.9332779992373501],
					[61.30973044743223, 3.9332779998897891],
					[61.309777604172062, 3.9334528412117229],
					[61.309885900983168, 3.9341500221964352],
					[61.309924801069741, 3.93465831441533],
					[61.309924801139267, 3.9346583162750886],
					[61.309924801139267, 3.9346583162750886],
					[61.309924801208794, 3.9346583181348298],
					[61.309972000147937, 3.9351750117258142],
					[61.309980400406047, 3.9356194037552017],
					[61.309983100265825, 3.9357556037583601],
					[61.309983100335344, 3.9357556056181089],
					[61.309983100404857, 3.9357556074778572],
					[61.309983099580165, 3.9357556094821793],
					[61.310002600294276, 3.9361528093273566],
					[61.310002600363788, 3.9361528111871049],
					[61.3100026004333, 3.9361528130468546],
					[61.310002599539111, 3.9361528131914172],
					[61.310052600179716, 3.9368833134709798],
					[61.310110898818003, 3.9378943959365236],
					[61.310038702639922, 3.9386027640157777],
					[61.309949806839072, 3.9389360489042247],
					[61.309949805259059, 3.9389360547724777],
					[61.309949802993245, 3.9389360663644779],
					[61.309949802307479, 3.9389360720882403],
					[61.309869202060554, 3.9394999728569107],
					[61.309869202130017, 3.9394999747166612],
					[61.309869201444215, 3.9394999804404001],
					[61.309869200619445, 3.9394999824446217],
					[61.309760901230632, 3.9407638836748209],
					[61.309760899581107, 3.9407638876831981],
					[61.309760899789445, 3.9407638932624431],
					[61.309760899997784, 3.9407638988417077],
					[61.309752599579454, 3.9417249972059136],
					[61.309752599857184, 3.9417250046449359],
					[61.309752601237456, 3.9417250175188032],
					[61.309752602409375, 3.9417250248134232],
					[61.309858100794109, 3.9425667176660411],
					[61.309888698584629, 3.9431193965384606],
					[61.309847002131335, 3.9435416705655575],
					[61.30979700729651, 3.9437832434201088],
					[61.309766512584154, 3.9438638295880226],
					[61.309766509354311, 3.9438638394642016],
					[61.309766503927634, 3.9438638627917388],
					[61.3097665016614, 3.9438638743833354],
					[61.309672001960138, 3.9446027756314588],
					[61.309672001274066, 3.9446027813550657],
					[61.309671999971393, 3.9446027946620079],
					[61.309672000179575, 3.9446028002412885],
					[61.309674799994482, 3.9472693992243459],
					[61.309674800202558, 3.9472694048036501],
					[61.309674801443556, 3.9472694139582343],
					[61.309674800757421, 3.9472694196817666],
					[61.309930400287058, 3.9499861133843717],
					[61.30996920014698, 3.9511111000604289],
					[61.309963700097384, 3.9513277948770233],
					[61.309963700166676, 3.9513277967368148],
					[61.30996370023599, 3.9513277985966035],
					[61.309963700305289, 3.9513278004564136],
					[61.309963699929675, 3.9516999988874018],
					[61.309958099589679, 3.9521888973035613],
					[61.309958099658978, 3.9521888991633536],
					[61.309958099797548, 3.9521889028829413],
					[61.309958099866847, 3.9521889047427514],
					[61.309969200365181, 3.9526500033377978],
					[61.309974800413976, 3.9530417037635033],
					[61.30997480503008, 3.9530417310841726],
					[61.30997482341234, 3.9530417898640757],
					[61.30997483697071, 3.9530418157439136],
					[61.310235936744967, 3.9534112146258273],
					[61.310235938741251, 3.9534112199171174],
					[61.310235944314421, 3.9534112246321329],
					[61.310235946241434, 3.9534112280636093],
					[61.311319246014861, 3.9547195279046568],
					[61.311319254409213, 3.9547195359076892],
					[61.311319270303677, 3.9547195520577993],
					[61.31131927862878, 3.9547195582009507],
					[61.311691578618536, 3.9550029587141009],
					[61.311691628840016, 3.9550029786906498],
					[61.31169173360955, 3.9550029898809131],
					[61.311691787194164, 3.9550029793787371],
					[61.31177226432898, 3.954964091046139],
					[61.311813935085361, 3.9549557978229215],
					[61.31194171871514, 3.954936298132349],
					[61.312013867235571, 3.9549335005788726],
					[61.312427712178575, 3.9550918815278067],
					[61.312860997880456, 3.955350172572659],
					[61.312861007924752, 3.9553501765682006],
					[61.312861028082558, 3.9553501864192784],
					[61.312861038126854, 3.9553501904148374],
					[61.31315273378101, 3.9554445883552858],
					[61.313338811965146, 3.9555140818069217],
					[61.313533194939275, 3.955633469187291],
					[61.313533222389395, 3.9556334816065299],
					[61.313533276943481, 3.9556334971449405],
					[61.313533305835897, 3.9556334999760177],
					[61.313813905681627, 3.9556252004100028],
					[61.313813925354701, 3.9556251972412904],
					[61.313813962843177, 3.9556251893318963],
					[61.313813980589309, 3.9556251827311657],
					[61.31412780305245, 3.9554863161555751],
					[61.314319281182364, 3.9555640678787709],
					[61.314766538861221, 3.9560751199955377],
					[61.315060929982494, 3.9565473053856142],
					[61.315060936657524, 3.9565473155381836],
					[61.315060950832567, 3.9565473338392039],
					[61.315060959226827, 3.9565473418436383],
					[61.315155459434195, 3.9566418407734507],
					[61.315155462255333, 3.9566418440616666],
					[61.315155469755389, 3.9566418522101561],
					[61.315155474364978, 3.9566418552103322],
					[61.315460962340161, 3.9568890464430835],
					[61.315660946033539, 3.9571084274748678],
					[61.315988740898511, 3.9575362228371449],
					[61.315988747365815, 3.9575362274097055],
					[61.315988757756138, 3.9575362407071664],
					[61.315988764223441, 3.9575362452797251],
					[61.316424863840787, 3.9579418466241738],
					[61.316424883242611, 3.9579418603423266],
					[61.316424924590599, 3.957941883626269],
					[61.316424947430953, 3.9579418930480874],
					[61.3167999473267, 3.9580445928097996],
					[61.316799950078632, 3.9580445942380682],
					[61.316799954619029, 3.9580445953783752],
					[61.316799958265165, 3.9580445966626856],
					[61.317302715557972, 3.958152985857109],
					[61.317619268601902, 3.9583862524301581],
					[61.317802659331619, 3.9585695415981252],
					[61.317802727027576, 3.9585695718703149],
					[61.317802865639443, 3.9585695738821931],
					[61.317802935730334, 3.9585695476261495],
					[61.317986223595639, 3.9584001577017083],
					[61.318280673105861, 3.9582029914187831],
					[61.318702794769543, 3.958144602276771],
					[61.31912494868201, 3.9582251931582921],
					[61.319441635073368, 3.9583334883990031],
					[61.319441636036807, 3.9583334901153888],
					[61.319441637825271, 3.9583334898274103],
					[61.319441638788739, 3.9583334915437915],
					[61.319738817611082, 3.9584279849723245],
					[61.319991568354055, 3.9585779553447229],
					[61.320160911268189, 3.9587806866523416],
					[61.32020809608062, 3.9590749897498383],
					[61.320060914336445, 3.9596305203425555],
					[61.319952628665895, 3.9598081961551004],
					[61.319952621171289, 3.9598082123339453],
					[61.319952607214745, 3.9598082482684731],
					[61.319952602541228, 3.9598082677362827],
					[61.31991090272512, 3.960066667726923],
					[61.319910902176829, 3.9600666771729123],
					[61.319910900116824, 3.9600666943484208],
					[61.319910900393573, 3.9600667017900442],
					[61.31991649961855, 3.9606277807246628],
					[61.319852605789961, 3.9609443513834268],
					[61.319752619638983, 3.9612749037612836],
					[61.319533153811761, 3.9615442625986153],
					[61.319149916273155, 3.9618998048250562],
					[61.318847191757278, 3.9619192005078752],
					[61.318672271886591, 3.9619219996496251],
					[61.318494529265493, 3.9617720478348359],
					[61.318494519083039, 3.9617720401159242],
					[61.318494496998831, 3.9617720268261976],
					[61.318494485922116, 3.9617720192511747],
					[61.318266785710478, 3.9616637186884915],
					[61.318266759362558, 3.9616637116993205],
					[61.31826670501659, 3.9616637017293779],
					[61.318266677087678, 3.9616637006089355],
					[61.318169377385381, 3.9616748014217986],
					[61.318169319882351, 3.961674827517172],
					[61.318169232192474, 3.9616749127414534],
					[61.318169202761531, 3.9616749680058461],
					[61.318135903345372, 3.9618832683401899],
					[61.31813590162605, 3.9618832704882578],
					[61.318135901764364, 3.9618832742088683],
					[61.318135900939318, 3.9618832762130678],
					[61.318072004307702, 3.9624388492904075],
					[61.317894225685983, 3.9628776952327738],
					[61.317766683755274, 3.9630303074580446],
					[61.317633468951776, 3.9629026841273789],
					[61.317450195231409, 3.9624582497595369],
					[61.317380799306477, 3.961880576760993],
					[61.317380797172198, 3.9618805677474236],
					[61.317380792834456, 3.9618805478600225],
					[61.317380789805924, 3.9618805389903278],
					[61.317330789926217, 3.9617249380836235],
					[61.317330720916779, 3.9617248724600498],
					[61.317330559183119, 3.9617248292409921],
					[61.317330467353138, 3.9617248515016463],
					[61.317169267311755, 3.9618692517219407],
					[61.317169249155192, 3.9618692714855741],
					[61.317169220203432, 3.9618693154425966],
					[61.317169209408284, 3.9618693396359763],
					[61.31700541315103, 3.9623943263903492],
					[61.31684423846238, 3.9627359754190303],
					[61.316597127979279, 3.9629441986446596],
					[61.316405686282685, 3.9629415010162239],
					[61.316144544238817, 3.9626720605554508],
					[61.316144534950631, 3.962672052692946],
					[61.31614451823198, 3.9626720385404193],
					[61.31614450901295, 3.9626720325381193],
					[61.315791808737323, 3.9624442322131692],
					[61.315791794014537, 3.9624442233537485],
					[61.315791762987971, 3.9624442115031178],
					[61.31579174661502, 3.9624442066517309],
					[61.315422247276402, 3.9623554060038657],
					[61.315422212949514, 3.9623554021694454],
					[61.315422145812327, 3.9623554110980597],
					[61.315422112863764, 3.9623554201408306],
					[61.315077712588582, 3.9625220199653257],
					[61.315077695874599, 3.9625220301388775],
					[61.315077662584962, 3.9625220542062136],
					[61.315077648761196, 3.9625220695286223],
					[61.314885958087196, 3.9627442588010302],
					[61.314599888197904, 3.9629915312890494],
					[61.314224937226157, 3.9631776074425029],
					[61.313988865387842, 3.963219202494634],
					[61.313988858303055, 3.9632192055052338],
					[61.313988843100958, 3.9632192079501238],
					[61.313988835947015, 3.9632192091006648],
					[61.313699978305436, 3.9633164965749521],
					[61.313327821722908, 3.9632748010216874],
					[61.313327791180399, 3.9632748021912376],
					[61.31332773229871, 3.9632748154028605],
					[61.313327702996133, 3.9632748257287411],
					[61.313127702775347, 3.9633859251588266],
					[61.313127687849807, 3.9633859350436191],
					[61.313127660750538, 3.9633859562418019],
					[61.313127648645995, 3.9633859694151967],
					[61.313022048950053, 3.9635081686301192],
					[61.313022036983774, 3.9635081855234358],
					[61.313022017591514, 3.9635082204510881],
					[61.313022010165554, 3.9635082384854177],
					[61.312927609592229, 3.9637999372089698],
					[61.312927607324291, 3.9637999488003861],
					[61.312927602581034, 3.963799966403208],
					[61.312927601138213, 3.9637999759908262],
					[61.312847000896625, 3.9644666766274526],
					[61.312847001311354, 3.9644666877874677],
					[61.312847000283199, 3.9644667085350522],
					[61.312847000697943, 3.9644667196950683],
					[61.312922001151925, 3.9652528174695196],
					[61.312958101196273, 3.9657000164783534],
					[61.313002598747381, 3.9662416906047899],
					[61.31294151089638, 3.9665916183021426],
					[61.312788795584908, 3.9667970057294979],
					[61.31256112328559, 3.9668080993787571],
					[61.312352853998007, 3.9667498065827713],
					[61.312352844917328, 3.9667498042998748],
					[61.312352826825126, 3.966749801594081],
					[61.312352817813583, 3.9667498011711704],
					[61.312136138367677, 3.9667304036368352],
					[61.311902857313164, 3.9666609085147053],
					[61.311902840115167, 3.9666609056652709],
					[61.311902803930785, 3.9666609002538209],
					[61.31190278680188, 3.9666608992643537],
					[61.311652787275904, 3.9666776001080359],
					[61.311652754257878, 3.966677607285066],
					[61.311652693176676, 3.9666776339401344],
					[61.311652665044384, 3.9666776515582756],
					[61.311422089798704, 3.96688872878565],
					[61.311122163568662, 3.9670164977164015],
					[61.311011109704623, 3.9670109005293464],
					[61.311011047383651, 3.9670109180273636],
					[61.311010945723609, 3.9670109904926085],
					[61.311010908172975, 3.967011045172474],
					[61.310974808245554, 3.9671360454131799],
					[61.310974803709065, 3.9671360685941015],
					[61.31097480192831, 3.9671361175262421],
					[61.310974803720683, 3.9671361415612578],
					[61.311069204216665, 3.9675750419424256],
					[61.311069208277203, 3.9675750543870647],
					[61.311069217292562, 3.9675750791326596],
					[61.311069222247319, 3.9675750914336443],
					[61.311391512076959, 3.9682056720479935],
					[61.311624804262003, 3.9690667422387582],
					[61.311763687906421, 3.9698860466023924],
					[61.311435942322824, 3.970310977124631],
					[61.311435938883882, 3.970310981418903],
					[61.311435934619759, 3.970310987716716],
					[61.31143593296936, 3.97031099172378],
					[61.311038733053032, 3.9709192903534678],
					[61.311038726313235, 3.9709193026615925],
					[61.311038714622086, 3.9709193269907024],
					[61.311038709601746, 3.9709193371517379],
					[61.310833129112851, 3.9715470785565303],
					[61.31052215780965, 3.9717858801921291],
					[61.310116753637118, 3.9716720079106977],
					[61.310116745450792, 3.9716720054829602],
					[61.310116728252908, 3.971672002630882],
					[61.310116719172306, 3.9716720003467034],
					[61.309597219218865, 3.9716220012480918],
					[61.309597191358939, 3.9716220019785453],
					[61.30959713777272, 3.9716220124514821],
					[61.309597111083171, 3.971622020477688],
					[61.309294311298125, 3.9717720204728204],
					[61.30929430338788, 3.9717720254843694],
					[61.309294288461651, 3.9717720353639039],
					[61.309294279657124, 3.9717720405189842],
					[61.309116590683892, 3.9719054315143993],
					[61.308733203739948, 3.9721165244123608],
					[61.308733183586178, 3.9721165388722963],
					[61.308733148851225, 3.9721165725103225],
					[61.308733133306795, 3.9721165899721447],
					[61.308349832815765, 3.9726998904774544],
					[61.308349824425285, 3.9726999067906612],
					[61.308349808676546, 3.9726999429931165],
					[61.308349804001111, 3.9726999624517818],
					[61.308199803638452, 3.9734721629279597],
					[61.30819980212592, 3.9734721706539431],
					[61.308199800889383, 3.973472185818959],
					[61.30819980027114, 3.9734721934014647],
					[61.30816919969314, 3.9745166948479134],
					[61.308169199831113, 3.9745166985674163],
					[61.308169200038037, 3.9745167041466907],
					[61.30816920024499, 3.9745167097259433],
					[61.308205399741851, 3.9753556087143962],
					[61.308205402082152, 3.9753556233055853],
					[61.308205407725971, 3.9753556542042978],
					[61.308205412817998, 3.9753556702249484],
					[61.308302612690532, 3.9756139705294542],
					[61.308302626175731, 3.9756139945581332],
					[61.308302659336945, 3.9756140397517359],
					[61.308302678049763, 3.9756140592003075],
					[61.308577633087573, 3.9758251248858301],
					[61.308799808231946, 3.9764111596565477],
					[61.308922005369311, 3.9769222463511849],
					[61.308922012318646, 3.9769222639454695],
					[61.308922027111556, 3.9769222989906243],
					[61.308922035780469, 3.976922314438311],
					[61.309119235711144, 3.9772029153885753],
					[61.309119237568609, 3.9772029169616108],
					[61.309119241352526, 3.9772029219675056],
					[61.309119243209985, 3.9772029235405451],
					[61.309274842640313, 3.9774001243510533],
					[61.309274846355251, 3.9774001274971802],
					[61.309274852959803, 3.9774001357926241],
					[61.309274855780494, 3.9774001390821176],
					[61.309455456422114, 3.9775862388083039],
					[61.309455481394508, 3.9775862572550795],
					[61.309455532851999, 3.9775862864225311],
					[61.309455562019934, 3.9775862967130391],
					[61.309641658924541, 3.9776223968871807],
					[61.310016655453268, 3.9777084952510728],
					[61.31001666267629, 3.9777084959638982],
					[61.310016679874124, 3.9777084988192817],
					[61.310016687991421, 3.9777084993887226],
					[61.310391624245163, 3.9777307968079421],
					[61.310722074009732, 3.9779973558072377],
					[61.310722075867204, 3.9779973573803966],
					[61.310722081370628, 3.9779973602399612],
					[61.310722083228086, 3.9779973618131224],
					[61.310833174252529, 3.9780779544534433],
					[61.310985941808759, 3.9782168263468227],
					[61.311235926365086, 3.9786528995340533],
					[61.311235927328262, 3.9786529012506411],
					[61.31123592829146, 3.9786529029672395],
					[61.311235929254636, 3.9786529046838246],
					[61.311638729026363, 3.9793168047399057],
					[61.311638729920638, 3.9793168045965595],
					[61.311638731847012, 3.9793168080298278],
					[61.311638732810174, 3.9793168097464564],
					[61.312197033040533, 3.9801640103600655],
					[61.312197033040533, 3.9801640103600655],
					[61.312197034003731, 3.9801640120767434],
					[61.312197034966879, 3.980164013793428],
					[61.312524820679101, 3.9806444912611227],
					[61.312685912384588, 3.9810806693181982],
					[61.312685916306123, 3.9810806780452022],
					[61.312685925112376, 3.9810806972159414],
					[61.312685929928207, 3.9810807057996391],
					[61.312808130326751, 3.981277904347432],
					[61.312808145461716, 3.9812779243752798],
					[61.312808178138923, 3.9812779565608984],
					[61.312808195750037, 3.9812779705786987],
					[61.313119295895127, 3.9814668710997898],
					[61.313119364754989, 3.9814668843904588],
					[61.313119495249076, 3.98146686160969],
					[61.313119555094758, 3.9814668258248731],
					[61.313375154605495, 3.9811529253264148],
					[61.313375164854406, 3.9811529105862151],
					[61.313375181706313, 3.9811528798189286],
					[61.313375190097837, 3.9811528635052325],
					[61.313622376574237, 3.9804195002223617],
					[61.313961204729203, 3.9800251831334297],
					[61.314288866072339, 3.97996690899399],
					[61.314549877231826, 3.9801084611187232],
					[61.314888756357753, 3.9804612398664436],
					[61.314888765644909, 3.98046124773398],
					[61.314888787865243, 3.9804612647558879],
					[61.314888798940963, 3.9804612723367576],
					[61.31507489067765, 3.9805695681982578],
					[61.31540266793408, 3.9808084508226149],
					[61.315735954856514, 3.9811584372342845],
					[61.315735955819662, 3.9811584389511885],
					[61.315735958640254, 3.9811584422416737],
					[61.315735959603444, 3.9811584439585737],
					[61.315841559992435, 3.9812612421290794],
					[61.315841574851866, 3.9812612547178823],
					[61.315841606221454, 3.9812612758883796],
					[61.315841622731611, 3.9812612844700879],
					[61.315874864038463, 3.9812751602246905],
					[61.31593312958654, 3.9813695043433444],
					[61.315933136259758, 3.9813695145015648],
					[61.31593315043159, 3.9813695328144614],
					[61.315933157861288, 3.9813695391089072],
					[61.316133158366107, 3.9815723395868061],
					[61.316133194344793, 3.981572363761761],
					[61.316133271873063, 3.9815723925060644],
					[61.316133315280091, 3.9815723986490394],
					[61.316316714808316, 3.9815584990674657],
					[61.316316753949359, 3.9815584871811223],
					[61.316316825420479, 3.9815584495295067],
					[61.316316856856282, 3.9815584239075617],
					[61.316561257275097, 3.9812501237246818],
					[61.316561269174748, 3.9812501049757758],
					[61.316561288364959, 3.9812500644740347],
					[61.316561294761215, 3.9812500428645068],
					[61.316697390060462, 3.9806194664955177],
					[61.316816841745116, 3.9803779631409282],
					[61.317136157354582, 3.9802723911644313],
					[61.317397251156194, 3.980202992941507],
					[61.317397261818442, 3.9802029893609499],
					[61.31739728224872, 3.9802029823431835],
					[61.317397291053517, 3.9802029771890206],
					[61.317933379377095, 3.9799279845881941],
					[61.317988951888907, 3.9799084945182979],
					[61.318108242392491, 3.9798863141435374],
					[61.318310965728138, 3.9800612473151946],
					[61.318580461941458, 3.9803168454691717],
					[61.318580494136548, 3.9803168646367091],
					[61.318580561759219, 3.9803168930963992],
					[61.318580598906472, 3.9803169002414474],
					[61.318994398928112, 3.9803196001545809],
					[61.318994418670847, 3.9803195988610907],
					[61.318994458843861, 3.980319590549465],
					[61.318994478379857, 3.9803195836747287],
					[61.319400078236526, 3.9801473846967688],
					[61.319400084427478, 3.9801473818327668],
					[61.319400095846163, 3.9801473743876943],
					[61.319400102037115, 3.9801473715236892],
					[61.31964729261513, 3.9800001775158154],
					[61.319883383039759, 3.9798918822360583],
					[61.319883406771389, 3.97989186720207],
					[61.319883447768014, 3.9798918325563939],
					[61.31988346585841, 3.9798918109408268],
					[61.320052966444784, 3.9796390113426852],
					[61.320052974079644, 3.9796389988893779],
					[61.320052985634476, 3.9796389708353628],
					[61.320052992306117, 3.9796389566649739],
					[61.320091891575004, 3.9795083566624632],
					[61.320091893913194, 3.979508346929916],
					[61.320091897557447, 3.9795083238872704],
					[61.320091899826686, 3.9795083122942576],
					[61.320116900142331, 3.9791111124473577],
					[61.32011689993557, 3.9791111068659397],
					[61.320116900416231, 3.9791110955596936],
					[61.320116900209456, 3.9791110899782582],
					[61.320094601060198, 3.9786472087853948],
					[61.320164095878241, 3.9781611418709053],
					[61.320275185751449, 3.9777722785436795],
					[61.32039454497874, 3.9775640488681097],
					[61.320608360145719, 3.9774473947569793],
					[61.320727745846945, 3.9774363063249387],
					[61.320988774257351, 3.9776168558478311],
					[61.321449863093058, 3.9780529441945336],
					[61.321449880704492, 3.9780529582141617],
					[61.321449921155065, 3.9780529816716661],
					[61.321449942205696, 3.9780529913964195],
					[61.321688841783178, 3.9781251910802715],
					[61.321688888698397, 3.9781251947848704],
					[61.321688978055597, 3.9781251785804601],
					[61.321689021391776, 3.9781251585280279],
					[61.321852917177708, 3.9780001615832954],
					[61.322139013969782, 3.9778029657695932],
					[61.322139015689366, 3.9778029636221039],
					[61.322139020022789, 3.9778029591836574],
					[61.322139021811289, 3.9778029588967589],
					[61.322397284343815, 3.977602988630017],
					[61.322619354883166, 3.977561312576857],
					[61.322869280102708, 3.9777334593236024],
					[61.32309987370116, 3.9779195563400003],
					[61.323099886565565, 3.9779195636349045],
					[61.323099914151776, 3.9779195797984639],
					[61.323099926947222, 3.9779195852327072],
					[61.323499927391062, 3.9780751859031405],
					[61.323499945621009, 3.9780751923374948],
					[61.323499983593642, 3.978075197476604],
					[61.323500004368455, 3.9780751997592523],
					[61.323922171139273, 3.9780669005831562],
					[61.324213840254231, 3.9781584902794984],
					[61.324213843900203, 3.9781584915664023],
					[61.324213852086459, 3.9781584939967534],
					[61.324213855732431, 3.9781584952836573],
					[61.324399956009628, 3.9782001946536538],
					[61.324399974995949, 3.9782001972233219],
					[61.324400012830687, 3.9782001986412316],
					[61.324400031679097, 3.9782001974894587],
					[61.324555631635235, 3.9781751975558945],
					[61.324555682056555, 3.9781751744931371],
					[61.324555762191338, 3.9781751036125454],
					[61.324555791973722, 3.9781750576554673],
					[61.324639085635376, 3.9778944758208228],
					[61.324750130684478, 3.9776779838382672],
					[61.324908282867383, 3.9776558040930636],
					[61.325238838652844, 3.9777612908888269],
					[61.32523888467383, 3.9777612947365117],
					[61.325238974099882, 3.9777612803895201],
					[61.325239016610652, 3.9777612623383178],
					[61.325436217425718, 3.9776195615202319],
					[61.325436227881028, 3.9776195523553808],
					[61.325436248722717, 3.9776195321648928],
					[61.325436259109097, 3.9776195211392547],
					[61.325700159059863, 3.9772751214900905],
					[61.325700161604757, 3.9772751173380141],
					[61.325700167588742, 3.9772751088904168],
					[61.325700171027876, 3.9772751045948578],
					[61.325808470663837, 3.9770973043714082],
					[61.325808479811116, 3.9770972841849197],
					[61.325808493565376, 3.9770972426686217],
					[61.325808499066653, 3.9770972211952969],
					[61.325830798835085, 3.9768917221122178],
					[61.325830799315554, 3.9768917108038786],
					[61.325830799313316, 3.9768916864698665],
					[61.325830797936298, 3.97689167358772],
					[61.325766899264778, 3.9764138762616934],
					[61.325730804829412, 3.9760694383327344],
					[61.325808459676551, 3.9759168262704399],
					[61.32605012906447, 3.9757140537460178],
					[61.32605015059314, 3.9757140278278924],
					[61.326050185188365, 3.9757139661185206],
					[61.326050196604299, 3.9757139343359844],
					[61.326158497376781, 3.9750917344342924],
					[61.326158497307802, 3.9750917325734538],
					[61.326158498133054, 3.9750917305690883],
					[61.326158498064061, 3.9750917287082697],
					[61.326258496014006, 3.9744111396927373],
					[61.326330788571731, 3.9741333666346685],
					[61.326477968822175, 3.9738084111927106],
					[61.326664018708023, 3.9736084667523883],
					[61.326855681540877, 3.973522381544758],
					[61.326855693097173, 3.9735223778170812],
					[61.326855714145218, 3.9735223632055252],
					[61.326855723843991, 3.9735223579042094],
					[61.327119523622692, 3.9733140566804375],
					[61.327119544463685, 3.9733140364859971],
					[61.327119576032821, 3.9733139902332821],
					[61.32711958861848, 3.973313965748666],
					[61.327200189009737, 3.973080664188859],
					[61.327200189834954, 3.9730806621843726],
					[61.327200190591185, 3.9730806583190064],
					[61.327200192310684, 3.9730806561708851],
					[61.327386284594581, 3.9724583803121618],
					[61.327558410225336, 3.9721696066979879],
					[61.327722117640704, 3.9721779965355539],
					[61.328022046655079, 3.9724501309057278],
					[61.328347033157755, 3.972941809167609],
					[61.328347066731233, 3.9729418412160022],
					[61.328347144882287, 3.9729418866971233],
					[61.328347188565623, 3.9729419002734949],
					[61.328713888657994, 3.9729613006334419],
					[61.328713943069452, 3.9729612881502412],
					[61.328714034760424, 3.9729612378546135],
					[61.328714072934261, 3.9729611998985668],
					[61.328919567916742, 3.9726029103875224],
					[61.329072361090162, 3.9723973196064244],
					[61.329072361021147, 3.9723973177454388],
					[61.329072362740604, 3.9723973155971648],
					[61.329072363634886, 3.9723973154535166],
					[61.329255764015535, 3.9721362146013912],
					[61.329255775775763, 3.9721361921192617],
					[61.32925579289833, 3.9721361444385872],
					[61.329255799154964, 3.9721361190963971],
					[61.329311295331166, 3.9715667583865839],
					[61.329491853250701, 3.9712279375122432],
					[61.330025084190957, 3.9708668844403134],
					[61.330263876943079, 3.970803012561352],
					[61.330361009007994, 3.9708529783249675],
					[61.330361029095783, 3.9708529863299815],
					[61.330361071746971, 3.9708529963257519],
					[61.330361094379441, 3.9708530001775575],
					[61.330547194230981, 3.970858500078152],
					[61.330547251186786, 3.9708584834371869],
					[61.330547345283449, 3.9708584252568753],
					[61.330547383180466, 3.9708583798516774],
					[61.330652982906081, 3.9706167801456451],
					[61.330652990125728, 3.9706167565193859],
					[61.33065299816716, 3.9706167065506479],
					[61.330652998988953, 3.9706166802081864],
					[61.330625198766299, 3.9703360797384826],
					[61.330625198421032, 3.970336070433115],
					[61.330625194222549, 3.9703360542583024],
					[61.330625192088789, 3.9703360452403604],
					[61.330422395692253, 3.969630559746109],
					[61.330303000878189, 3.9687638918904011],
					[61.330341899196469, 3.9680000198028371],
					[61.330391898130777, 3.9676306276025932],
					[61.330391898817766, 3.967630621875637],
					[61.330391900191714, 3.9676306104217498],
					[61.330391900053534, 3.9676306066996516],
					[61.330411299670729, 3.9669639067015803],
					[61.330411297878193, 3.9669638826517288],
					[61.3304112889967, 3.9669638372759168],
					[61.3304112819078, 3.9669638159499629],
					[61.330277981894788, 3.9666749165308364],
					[61.330277955404746, 3.9666748814757864],
					[61.330277891283217, 3.9666748262625893],
					[61.330277852757483, 3.9666748062482702],
					[61.330033352746987, 3.9666081079552908],
					[61.329944462192614, 3.9665831094072481],
					[61.329780670363178, 3.9665220133320793],
					[61.329780644015464, 3.9665220063367035],
					[61.329780590771314, 3.9665220017948339],
					[61.329780564700023, 3.9665220022434968],
					[61.329672247792033, 3.9665414872308045],
					[61.329541912366388, 3.9664471055013175],
					[61.329605799093862, 3.9658833227385024],
					[61.329605798955633, 3.9658833190165068],
					[61.329605799642543, 3.9658833132896811],
					[61.329605800398561, 3.9658833094238344],
					[61.329625200206024, 3.9654611134930988],
					[61.329669598914059, 3.9649667181898045],
					[61.329669600356901, 3.9649667085970757],
					[61.329669599734743, 3.9649666918480593],
					[61.329669599389106, 3.9649666825430505],
					[61.329627999163684, 3.9644999813269814],
					[61.329627997923801, 3.9644999721658625],
					[61.329627993655485, 3.9644999541313806],
					[61.329627992415567, 3.9644999449702674],
					[61.329497391888189, 3.9640471459178501],
					[61.329497385831345, 3.9640471281712788],
					[61.329497372205715, 3.9640471004098701],
					[61.329497363604432, 3.9640470868169451],
					[61.329286264034842, 3.9637442865001131],
					[61.32928625364503, 3.9637442731951502],
					[61.329286231215256, 3.9637442505949307],
					[61.329286219106123, 3.9637442394387241],
					[61.328972319048837, 3.9635109393486854],
					[61.328972305220361, 3.9635109303414229],
					[61.328972275913188, 3.9635109163366051],
					[61.328972261259636, 3.9635109093341927],
					[61.328711160877639, 3.9634276081695634],
					[61.328711099723591, 3.9634276086499911],
					[61.328710990423531, 3.9634276449589696],
					[61.328710940419924, 3.9634276792143783],
					[61.328544239603318, 3.9636498795002932],
					[61.328544235408685, 3.9636498876634603],
					[61.328544225230949, 3.9636499042776197],
					[61.328544220142085, 3.9636499125846951],
					[61.32837762899743, 3.9639942943434678],
					[61.328099901965643, 3.9643608991681116],
					[61.327911312266245, 3.9643581033518163],
					[61.32793629994999, 3.963950011575855],
					[61.327936299811697, 3.9639500078540535],
					[61.327936300291121, 3.9639499965447982],
					[61.327936300083699, 3.9639499909621225],
					[61.32791409992091, 3.9634417023258268],
					[61.327950198579714, 3.9629083185057898],
					[61.328036299106365, 3.9621361224788822],
					[61.328036298622202, 3.9621361094526133],
					[61.328036299373203, 3.9621360812513005],
					[61.328036297994792, 3.9621360683689657],
					[61.327936298320004, 3.9615055687530356],
					[61.327936295222273, 3.9615055580195508],
					[61.327936289026731, 3.9615055365525897],
					[61.327936285998184, 3.961505527679988],
					[61.327800247097144, 3.9611611813887642],
					[61.32809725916789, 3.9609751992325219],
					[61.328425003362781, 3.9609696007121733],
					[61.328425025718687, 3.9609695971129928],
					[61.328425070292134, 3.9609695861928045],
					[61.328425091546308, 3.9609695771548274],
					[61.328666791535831, 3.9608445773367658],
					[61.328666801234085, 3.9608445720311982],
					[61.328666819667113, 3.960844559703129],
					[61.32866682840195, 3.9608445526806242],
					[61.329277920658576, 3.9603334606378691],
					[61.329864012736046, 3.959933464131236],
					[61.32986401631301, 3.9599334635552106],
					[61.329864022434244, 3.9599334588251693],
					[61.329864025047755, 3.9599334565321556],
					[61.330214024683627, 3.9596529555130995],
					[61.330214025577888, 3.9596529553690809],
					[61.330214027366353, 3.9596529550810513],
					[61.330214028191378, 3.9596529530760227],
					[61.330408427718716, 3.9594918546302038],
					[61.330408432051485, 3.9594918501880274],
					[61.330408440717029, 3.9594918413037061],
					[61.330408445118998, 3.9594918387225584],
					[61.330580738969545, 3.9593112444792808],
					[61.330989033601092, 3.9589445491372959],
					[61.330989036214589, 3.9589445468441147],
					[61.330989038758844, 3.9589445426898617],
					[61.330989041372355, 3.9589445403966788],
					[61.331305734788657, 3.9586279482855251],
					[61.331719512109444, 3.9582862673280341],
					[61.332141773893603, 3.9580612872527956],
					[61.33243614487241, 3.9579834948281944],
					[61.33262780987868, 3.957944602427609],
					[61.332824979616426, 3.9579640984005549],
					[61.332824986839512, 3.9579640991090845],
					[61.332825001285656, 3.957964100526151],
					[61.332825007545296, 3.9579640995175933],
					[61.332961107979209, 3.957958499412376],
					[61.332961109767673, 3.9579584991242163],
					[61.332961113413852, 3.9579585004090649],
					[61.332961115133081, 3.9579584982597318],
					[61.333075014922613, 3.9579501990306749],
					[61.333075015816817, 3.957950198886599],
					[61.333075017605282, 3.9579501985984358],
					[61.333075019393782, 3.9579501983102663],
					[61.333486118666158, 3.9579112982171241],
					[61.333486123206576, 3.9579112993579177],
					[61.333486131185431, 3.9579112961999345],
					[61.333486134831567, 3.9579112974848205],
					[61.33465833507217, 3.9577001963611531],
					[61.335311110803616, 3.9575835001837461],
					[61.335708252712486, 3.9576112972383419],
					[61.335894298754283, 3.9576890744742537],
					[61.336088777764559, 3.957839057347694],
					[61.336088789804933, 3.9578390666425367],
					[61.336088814572229, 3.9578390795040215],
					[61.336088828331867, 3.9578390866492943],
					[61.336238809031677, 3.9578973781078943],
					[61.336458191252873, 3.9580390683431679],
					[61.336458202260594, 3.9580390740595206],
					[61.336458224275965, 3.9580390854922163],
					[61.336458235214423, 3.9580390893471891],
					[61.336669319377698, 3.9581112837275545],
					[61.337124902378264, 3.9583612747677561],
					[61.338460999328341, 3.9591390726381777],
					[61.33846100393793, 3.9591390756407869],
					[61.33846101308793, 3.9591390797845167],
					[61.33846101769749, 3.9591390827871282],
					[61.339905516828161, 3.9597890815276302],
					[61.340655506263971, 3.9601362763019372],
					[61.341124884650384, 3.9604168642680144],
					[61.341574860308363, 3.9607862440387676],
					[61.34199704901971, 3.9612751301478575],
					[61.341997051840757, 3.9612751334391798],
					[61.341997058446353, 3.9612751417395144],
					[61.341997062161646, 3.961275144886796],
					[61.342341561842844, 3.9616057436545731],
					[61.342341564663919, 3.9616057469459736],
					[61.342341571200301, 3.9616057533847249],
					[61.342341574021354, 3.9616057566761147],
					[61.34271656362106, 3.9619084487147562],
					[61.34296375536119, 3.9621668386889395],
					[61.342963770222383, 3.9621668512788837],
					[61.342963799806377, 3.9621668727351893],
					[61.342963816317678, 3.9621668813135216],
					[61.343361016166433, 3.9623501819843407],
					[61.343361031645138, 3.9623501869832798],
					[61.343361064391047, 3.9623501966931247],
					[61.343361079800594, 3.9623501998302446],
					[61.343811079223336, 3.9623945996452981],
					[61.344197178624434, 3.9624362987428681],
					[61.344197191212928, 3.9624362985884631],
					[61.344197214601415, 3.9624362985676598],
					[61.344197227189916, 3.9624362984132375],
					[61.344383327041712, 3.9624112972135777],
					[61.344383337772534, 3.9624112954853228],
					[61.344383360953543, 3.9624112898788981],
					[61.344383371615187, 3.962411286288781],
					[61.344669405925607, 3.9623002114334112],
					[61.344872102126239, 3.9623668788551853],
					[61.345177673421453, 3.9626168554953702],
					[61.34517767803095, 3.9626168584991115],
					[61.345177687250008, 3.9626168645065549],
					[61.345177690965279, 3.9626168676543165],
					[61.345683189880582, 3.9629445671938606],
					[61.346441588049991, 3.9634557651644844],
					[61.346441596305617, 3.963455769454415],
					[61.346441611028354, 3.9634557783223086],
					[61.346441618389747, 3.9634557827562595],
					[61.346949918095376, 3.9636834834975452],
					[61.346949929033684, 3.9636834873556182],
					[61.346949950841179, 3.9636834932097256],
					[61.346949961710358, 3.9636834952057582],
					[61.347363829315604, 3.9637640901415199],
					[61.347716599933442, 3.9639668728254613],
					[61.347716610940893, 3.9639668785457669],
					[61.347716633780927, 3.9639668879803418],
					[61.347716644719277, 3.9639668918385733],
					[61.348213844568164, 3.9641084911536755],
					[61.348213857294922, 3.964108494724043],
					[61.348213880821717, 3.9641084984285384],
					[61.34821389258515, 3.9641085002807981],
					[61.348666693167786, 3.9641251993839992],
					[61.348666703073576, 3.9641251996621425],
					[61.348666722815963, 3.9641251983563262],
					[61.348666732652582, 3.9641251967723488],
					[61.348994432690922, 3.9640695964835415],
					[61.348994441633316, 3.9640695950435236],
					[61.34899445766046, 3.9640695905893493],
					[61.348994465708614, 3.9640695892933335],
					[61.349233355590528, 3.9639862918978808],
					[61.349650045226532, 3.9638890953918073],
					[61.349650049697694, 3.9638890946717562],
					[61.349650058570937, 3.9638890913694658],
					[61.34965006393638, 3.9638890905054023],
					[61.350138962668154, 3.9637251891504586],
					[61.350525063274326, 3.9635973894081693],
					[61.350525073936012, 3.9635973858176445],
					[61.350525094226825, 3.9635973750561462],
					[61.350525104888526, 3.9635973714656205],
					[61.350819504594597, 3.9634168716012868],
					[61.350819521170138, 3.9634168576911817],
					[61.350819549850023, 3.9634168305911293],
					[61.350819562779435, 3.9634168153949059],
					[61.350989026845703, 3.9631779663880171],
					[61.351163925591088, 3.9631141021731207],
					[61.351477759849551, 3.9631445963416705],
					[61.351888818119356, 3.9632751834232307],
					[61.352330498896933, 3.9635334724190607],
					[61.352330530817696, 3.9635334841388419],
					[61.352330596032694, 3.9635334961163138],
					[61.352330630290368, 3.963533498092267],
					[61.352788929851492, 3.9634640970944344],
					[61.352788957434505, 3.9634640889043982],
					[61.352789008746967, 3.9634640656510389],
					[61.352789033301434, 3.9634640485812973],
					[61.353247320590576, 3.9630557598060312],
					[61.35353070633397, 3.9628779695426704],
					[61.353530717682347, 3.9628779602202058],
					[61.35353074127336, 3.9628779414312256],
					[61.353530751658312, 3.9628779303904165],
					[61.353630752238487, 3.9627612304571365],
					[61.353630758221463, 3.9627612219989929],
					[61.353630771012433, 3.9627612030762336],
					[61.35363077610117, 3.9627611947621748],
					[61.353752968062743, 3.9625334115232866],
					[61.354066855703437, 3.962141825560662],
					[61.354066861617184, 3.9621418152398431],
					[61.354066873583029, 3.9621417983230853],
					[61.354066878602531, 3.9621417881463552],
					[61.354166878938344, 3.9619417893303552],
					[61.354166884644528, 3.9619417734221161],
					[61.354166895162621, 3.9619417417497549],
					[61.354166898116901, 3.961941724411373],
					[61.354211296868975, 3.9615889363266907],
					[61.354414088158144, 3.9607944727980859],
					[61.354583478325949, 3.9604667922033787],
					[61.354794571888448, 3.9600584030500556],
					[61.355172320138841, 3.9595029792588354],
					[61.355661083863801, 3.9594030062257284],
					[61.356330530300298, 3.9596529863436407],
					[61.356330537661783, 3.9596529907779869],
					[61.35633055403482, 3.9596529956331641],
					[61.356330563046349, 3.9596529960540332],
					[61.357574962603628, 3.9598890965833911],
					[61.357574972578611, 3.9598890987227779],
					[61.357574993284338, 3.9598890991321034],
					[61.357575003190114, 3.9598890994088496],
					[61.359097192389527, 3.9598695992064736],
					[61.360449953363052, 3.9599945964359269],
					[61.361402712677432, 3.9603751792122392],
					[61.361513799958011, 3.9604390735357282],
					[61.36151382094058, 3.9604390813968662],
					[61.36151386469421, 3.9604390968307754],
					[61.361513888221104, 3.9604391005336139],
					[61.361647188081413, 3.9604473997589635],
					[61.361647207823715, 3.9604473984498934],
					[61.361647249027584, 3.9604473936804743],
					[61.361647268631465, 3.9604473886456195],
					[61.361988968730252, 3.9603223878539748],
					[61.361988972307167, 3.9603223872772313],
					[61.361988976709128, 3.9603223846933933],
					[61.361988980216836, 3.9603223822537292],
					[61.362497247394245, 3.9601001982314146],
					[61.362966655395439, 3.9600724034048738],
					[61.36338317895585, 3.960297361676127],
					[61.363688746688062, 3.9606029301722847],
					[61.364022037430651, 3.9610723159552763],
					[61.364022039288322, 3.9610723175299483],
					[61.364022042178632, 3.9610723226865145],
					[61.364022043142057, 3.9610723244053676],
					[61.364333143236564, 3.9614640243047647],
					[61.364333148878785, 3.9614640308919591],
					[61.364333161057381, 3.961464043922188],
					[61.364333167593813, 3.9614640503651999],
					[61.364749867854421, 3.9618307498350602],
					[61.364749886361821, 3.9618307637195547],
					[61.364749923994111, 3.9618307838920743],
					[61.364749944082391, 3.9618307918990165],
					[61.365208244247, 3.9619640921449086],
					[61.365208256079619, 3.9619640958601448],
					[61.365208278712217, 3.9619640997085996],
					[61.365208291300689, 3.9619640995534788],
					[61.365655551407094, 3.9619834985476876],
					[61.36588599764994, 3.96209187378911],
					[61.366099858150811, 3.962247343570735],
					[61.366333125068913, 3.9625611994197998],
					[61.366533114703692, 3.9630472751810144],
					[61.366749813014515, 3.9635806715838604],
					[61.366966511870174, 3.9641917671994218],
					[61.366966511870174, 3.9641917671994218],
					[61.366966512902771, 3.9641917707817336],
					[61.366966512971928, 3.9641917726449445],
					[61.367138712356905, 3.9646389673051159],
					[61.367324809665782, 3.9652000639715923],
					[61.36732481056, 3.9652000638275164],
					[61.367324810629142, 3.9652000656907522],
					[61.367324811592546, 3.9652000674099028],
					[61.367549810961322, 3.9658333652787632],
					[61.367705409813041, 3.9662972637302163],
					[61.367705410776431, 3.9662972654494233],
					[61.367705411739784, 3.9662972671686547],
					[61.367705411808913, 3.9662972690319158],
					[61.367905408026132, 3.9668389577810577],
					[61.36802760529072, 3.9673833441584767],
					[61.36802760529072, 3.9673833441584767],
					[61.368027606323182, 3.9673833477410438],
					[61.36802760639231, 3.9673833496043249],
					[61.368127601987183, 3.9677806322265989],
					[61.368155400785341, 3.9681278163435798],
					[61.368155404228261, 3.9681278364078332],
					[61.368155415585342, 3.9681278758163439],
					[61.368155424393706, 3.968127895016599],
					[61.368288723903596, 3.9683722953895497],
					[61.368288757616597, 3.9683723311984731],
					[61.368288837834342, 3.9683723838866509],
					[61.368288885164169, 3.9683723987586372],
					[61.36850558503847, 3.9683890988021462],
					[61.368505604850007, 3.9683890993608895],
					[61.368505645159964, 3.9683890947444591],
					[61.368505663869939, 3.9683890898572338],
					[61.368930663818837, 3.9682445892235494],
					[61.368930665607316, 3.968244588935546],
					[61.368930668290062, 3.9682445885035382],
					[61.368930669184323, 3.9682445883595339],
					[61.369202821419655, 3.9681446049317155],
					[61.369444362941316, 3.9681807962645754],
					[61.369974945360731, 3.9683029930606168],
					[61.371466634583363, 3.9688168896766935],
					[61.371947115216571, 3.9689807819907021],
					[61.372388788147887, 3.9692473651809173],
					[61.373010965085356, 3.9697168494712654],
					[61.373216545066391, 3.969941826927835],
					[61.373435938729067, 3.9702418187164872],
					[61.373435945265122, 3.9702418251631384],
					[61.373435958475362, 3.9702418417837038],
					[61.373435965905635, 3.9702418480864048],
					[61.373644266402835, 3.970430747338757],
					[61.373644268329471, 3.970430750778092],
					[61.373644273007919, 3.9704307556491392],
					[61.373644275759759, 3.9704307570808588],
					[61.373819275873153, 3.9705695568007773],
					[61.37381928144584, 3.9705695615279044],
					[61.373819293416418, 3.970569568974569],
					[61.373819298920068, 3.9705695718380709],
					[61.374169287964428, 3.9707751653168137],
					[61.374424878139692, 3.9709723577734661],
					[61.37442488556993, 3.9709723640765309],
					[61.374424903938333, 3.9709723742431398],
					[61.374424913157092, 3.9709723802582868],
					[61.374580495227328, 3.9710473721351303],
					[61.374794280073488, 3.9712084592101675],
					[61.374794281036785, 3.9712084609299176],
					[61.374794282894385, 3.9712084625057034],
					[61.374794283788603, 3.9712084623617563],
					[61.375052674034308, 3.971391856826008],
					[61.375447049946651, 3.9717501348169049],
					[61.375772038349396, 3.9721973182878534],
					[61.375772041170229, 3.9721973215835997],
					[61.375772045917714, 3.9721973283189818],
					[61.375772048738575, 3.972197331614725],
					[61.376258126926963, 3.9727557063822148],
					[61.376483113901919, 3.9733139757483222],
					[61.376483114796137, 3.9733139756044245],
					[61.37648311679181, 3.9733139809080233],
					[61.37648311679181, 3.9733139809080233],
					[61.376630410917087, 3.9736444685780818],
					[61.376691507358004, 3.9738667537082186],
					[61.376691509353613, 3.973866759011897],
					[61.376691514308114, 3.9738667713391957],
					[61.376691516372752, 3.9738667785067086],
					[61.376777610925636, 3.9740694672811419],
					[61.37686650432849, 3.9743833425180974],
					[61.376902592953442, 3.9746221672424],
					[61.376741531592756, 3.9749415895913431],
					[61.376433155810325, 3.9753109593881817],
					[61.376205474914372, 3.9755137435185883],
					[61.375985983340271, 3.9756720382286077],
					[61.375985974604774, 3.9756720452582663],
					[61.375985958922314, 3.9756720590299355],
					[61.375985951150142, 3.9756720677795521],
					[61.375841579556621, 3.9758331348146241],
					[61.375622231446279, 3.9759303679705624],
					[61.375430725797827, 3.9757748449580941],
					[61.375430639601817, 3.9757748250739882],
					[61.375430485310488, 3.9757748611382571],
					[61.375430417215142, 3.9757749170866381],
					[61.375291516983047, 3.9760860179017881],
					[61.37529151375108, 3.976086027795767],
					[61.375291508112355, 3.9760860455761735],
					[61.375291504880373, 3.9760860554701534],
					[61.375213705661096, 3.976422152720839],
					[61.37511370600496, 3.9768138501441017],
					[61.374913709235742, 3.9775777385366564],
					[61.374505416007608, 3.9786416211322315],
					[61.374091519781807, 3.9794999130215478],
					[61.374091519025477, 3.9794999168926326],
					[61.37409151558635, 3.9794999211948356],
					[61.374091514761076, 3.9794999232022237],
					[61.373844217104697, 3.980105519894849],
					[61.373622018568355, 3.9805721139184915],
					[61.373622018637292, 3.9805721157821456],
					[61.373622018637292, 3.9805721157821456],
					[61.373499818964582, 3.9808360165533543],
					[61.373499813256295, 3.9808360324682508],
					[61.373499803697115, 3.9808360658743402],
					[61.373499800671603, 3.9808360813582109],
					[61.373469201072226, 3.9811721823565791],
					[61.373469199697311, 3.9811721938258104],
					[61.373469200593462, 3.9811722180532452],
					[61.373469201901294, 3.9811722290914848],
					[61.373585901895844, 3.9819583293665861],
					[61.373585902927985, 3.9819583329502555],
					[61.373585904028992, 3.9819583383976052],
					[61.373585904166838, 3.9819583421249152],
					[61.373708095696713, 3.9825250053947978],
					[61.373641507937236, 3.982924938899878],
					[61.373508219168237, 3.9831941128002599],
					[61.373119505942832, 3.982949831287721],
					[61.37311950587393, 3.9829498294240904],
					[61.373119504979663, 3.9829498295676995],
					[61.373119504085402, 3.9829498297113046],
					[61.372700103811255, 3.9826942286648754],
					[61.372700089158556, 3.9826942216446111],
					[61.372700057239186, 3.9826942098985372],
					[61.372700040797952, 3.9826942031654959],
					[61.372383340740321, 3.9826276037370452],
					[61.372383317282818, 3.982627601880167],
					[61.37238326699746, 3.9826276043316553],
					[61.372383242852443, 3.9826276082091576],
					[61.37213055235086, 3.982702606403056],
					[61.371827759877242, 3.9827637045189417],
					[61.371827745637987, 3.9827637086801806],
					[61.371827719016899, 3.9827637185789664],
					[61.371827705671876, 3.9827637225965864],
					[61.371744305670667, 3.9828081233309982],
					[61.371744274304177, 3.9828081508633235],
					[61.371744223541178, 3.9828082133788616],
					[61.371744206070986, 3.9828082518019863],
					[61.371697006201892, 3.982994350777092],
					[61.371697003863666, 3.9829943605256264],
					[61.371697000906806, 3.9829943778719628],
					[61.371697000357067, 3.9829943873333082],
					[61.371652601300077, 3.9837138726663217],
					[61.37153591158711, 3.9842443234183644],
					[61.371349830023249, 3.9845442943547376],
					[61.371349823351373, 3.9845443085478842],
					[61.371349810901791, 3.9845443367906017],
					[61.371349806018436, 3.9845443506966292],
					[61.371274805616892, 3.9848443508231823],
					[61.371274804172778, 3.9848443604279127],
					[61.371274801284507, 3.9848443796373831],
					[61.371274799840393, 3.9848443892421148],
					[61.371241502942823, 3.9855193369226236],
					[61.370991531914783, 3.9859081918868187],
					[61.370991520977995, 3.985908212387602],
					[61.370991505570906, 3.9859082579751379],
					[61.370991501031725, 3.9859082811983986],
					[61.370924800814613, 3.9865943806454056],
					[61.370924801365483, 3.9865943955535239],
					[61.370924802536052, 3.9865944272332543],
					[61.370924804944309, 3.9865944437179039],
					[61.371116501786631, 3.9874611291456197],
					[61.371160899549757, 3.9880861111415542],
					[61.371183098720493, 3.9884971893399848],
					[61.371105404809718, 3.9889916554210414],
					[61.370988709175961, 3.9893721407312306],
					[61.370988706768422, 3.9893721486155544],
					[61.370988703673078, 3.9893721622338867],
					[61.370988702228637, 3.9893721718383346],
					[61.370949814283698, 3.9896359913150006],
					[61.370822076872038, 3.9897359427006767],
					[61.370822075152311, 3.9897359448509877],
					[61.370822073363797, 3.9897359451377823],
					[61.370822071644071, 3.9897359472880956],
					[61.370616571908286, 3.9899081478242708],
					[61.370616551133921, 3.9899081699007524],
					[61.37061651887192, 3.9899082219373185],
					[61.370616507246694, 3.989908248170376],
					[61.370505406788958, 3.990324947966418],
					[61.370505403693706, 3.9903249859533165],
					[61.370505411742826, 3.9903250577695015],
					[61.370505421992853, 3.9903250917421942],
					[61.370783119811321, 3.9908611878542368],
					[61.370949790909386, 3.9912250239233429],
					[61.370908114871384, 3.9914221107740522],
					[61.370808173166445, 3.9915415407492421],
					[61.370747111438902, 3.9915720217121535],
					[61.370747106967535, 3.991572022428886],
					[61.370747099056658, 3.9915720274460318],
					[61.370747094654057, 3.9915720300262967],
					[61.370566595176356, 3.9916831291635422],
					[61.370566575983872, 3.9916831453616108],
					[61.370566542139031, 3.9916831789045597],
					[61.370566527624284, 3.9916831999764435],
					[61.370388740391419, 3.9919859771519253],
					[61.370305461417743, 3.9920720561412186],
					[61.370183183415044, 3.9921831357863495],
					[61.369891653038508, 3.9923441972901528],
					[61.369527811006122, 3.9923248008586469],
					[61.369527809148806, 3.9923247992818185],
					[61.369527805571693, 3.9923247998550946],
					[61.369527804677432, 3.9923247999984097],
					[61.369405605326655, 3.9923220007939819],
					[61.36940559005518, 3.9923220013669383],
					[61.369405562263928, 3.9923220039463243],
					[61.36940554795553, 3.9923220062393971],
					[61.369302748156422, 3.992349806401255],
					[61.369302747262125, 3.9923498065445742],
					[61.369302743685033, 3.9923498071178352],
					[61.369302742790744, 3.992349807261153],
					[61.368688843260337, 3.9925331085404738],
					[61.368688824136498, 3.9925331266003927],
					[61.36868878575148, 3.992533134625972],
					[61.368410986218237, 3.9927248349437403],
					[61.368410965374572, 3.9927248551533743],
					[61.368410929259277, 3.9927249003029481],
					[61.368410913987574, 3.992724925242936],
					[61.368297013863483, 3.9930082254173547],
					[61.368297013863483, 3.9930082254173547],
					[61.368199818760232, 3.9932527124803792],
					[61.368080595588623, 3.9934607053855302],
					[61.367858470628128, 3.9930915962883358],
					[61.367858466844744, 3.9930915912714195],
					[61.367858459209188, 3.9930915793742612],
					[61.367858453568438, 3.9930915727805756],
					[61.367647354400702, 3.9928359730250791],
					[61.36764734222497, 3.9928359599811714],
					[61.367647315259426, 3.9928359361865837],
					[61.367647301432676, 3.9928359271559457],
					[61.367366819091124, 3.9926720376989118],
					[61.367066844554259, 3.9923998610399027],
					[61.366822352978822, 3.9921081728394512],
					[61.36682233894571, 3.9921081582194926],
					[61.366822309228425, 3.9921081329927941],
					[61.366822291755753, 3.9921081226726307],
					[61.366614000393724, 3.9919998269704267],
					[61.366461219977786, 3.9919026391200485],
					[61.366286232124061, 3.9917498495526624],
					[61.366286231229765, 3.9917498496959789],
					[61.366286231160977, 3.991749847832732],
					[61.365422331116534, 3.9910054484264834],
					[61.365422328364922, 3.9910054469932637],
					[61.365422323687163, 3.991005442120334],
					[61.365422320935551, 3.9910054406871294],
					[61.364727920558643, 3.9904748409572464],
					[61.364727911271927, 3.9904748330748809],
					[61.364727888364698, 3.9904748217531907],
					[61.364727877289447, 3.990474814157507],
					[61.363850076761231, 3.9901109144435019],
					[61.363850072221055, 3.9901109132971411],
					[61.363850064034978, 3.9901109108610484],
					[61.363850060389112, 3.9901109095713356],
					[61.362002860020297, 3.9895331091030766],
					[61.362002857268706, 3.9895331076701428],
					[61.362002850939987, 3.9895331068106232],
					[61.362002848188354, 3.989533105377693],
					[61.361388948026274, 3.989380405427486],
					[61.361388944380387, 3.9893804041379277],
					[61.36138893447459, 3.9893804038518645],
					[61.361388929934414, 3.9893804027056801],
					[61.360741730041255, 3.9892831011820697],
					[61.360741717452633, 3.9892831013260883],
					[61.360741691381158, 3.9892831017574748],
					[61.360741677829438, 3.9892831001819209],
					[61.360222178195869, 3.9893415003459785],
					[61.360222152399636, 3.9893415082288368],
					[61.360222103558755, 3.9893415254273754],
					[61.360222080651781, 3.989341538468818],
					[61.359747081414433, 3.989691538305018],
					[61.359747077974966, 3.9896915426040809],
					[61.359747070958392, 3.9896915474764709],
					[61.359747066624671, 3.9896915519188663],
					[61.359247084202167, 3.9901359356784223],
					[61.35867771997497, 3.9904526157280045],
					[61.358033239178191, 3.9906581094074425],
					[61.358033237389634, 3.9906581096940368],
					[61.358033232987047, 3.9906581122732945],
					[61.358033231198519, 3.9906581125598888],
					[61.35746663317444, 3.9908665128041663],
					[61.356611046237802, 3.9911609072638061],
					[61.356202775113537, 3.9912498011008077],
					[61.355858294326133, 3.9912608998069499],
					[61.355858286277652, 3.991260901096354],
					[61.355858271900459, 3.9912609015259961],
					[61.355858264746288, 3.9912609026721273],
					[61.355558317416069, 3.9913136934646509],
					[61.355263994906011, 3.9912109207273843],
					[61.354966851954373, 3.990988764458359],
					[61.354802983304516, 3.9907138173745582],
					[61.35461689374295, 3.9901416491989528],
					[61.354575199613251, 3.9899277782307427],
					[61.354569599668245, 3.9895082976164771],
					[61.354569600424909, 3.9895082937480728],
					[61.354569599117788, 3.9895082827160882],
					[61.354569598980163, 3.9895082789909972],
					[61.354514098825369, 3.9889943786365762],
					[61.354514098825369, 3.9889943786365762],
					[61.354514098756553, 3.9889943767740346],
					[61.354422398900056, 3.9881916765162337],
					[61.354422397936936, 3.9881916747970485],
					[61.354422396698531, 3.988191665627709],
					[61.354422396629701, 3.9881916637651771],
					[61.354300197065569, 3.9875443662879579],
					[61.354247398165164, 3.9871943698938463],
					[61.354247396101279, 3.987194362730492],
					[61.354247392936628, 3.9871943501229459],
					[61.354247391766982, 3.9871943428162218],
					[61.354136294575909, 3.9868221534716941],
					[61.354052997154263, 3.98633886656468],
					[61.354052990893599, 3.9863388432124367],
					[61.354052974107454, 3.9863388028124596],
					[61.354052962618887, 3.9863387840456079],
					[61.353905763381626, 3.9861331839874872],
					[61.353905732631425, 3.9861331551947181],
					[61.353905666385103, 3.9861331152321116],
					[61.353905628137305, 3.9861331026299851],
					[61.353516739138449, 3.9860776032602958],
					[61.353088949139163, 3.9859692061882885],
					[61.353088940058811, 3.9859692038974011],
					[61.35308892017833, 3.98596920146487],
					[61.353088911097927, 3.9859691991739896],
					[61.352775041333018, 3.9859526015518227],
					[61.352611169822595, 3.9858915136842232],
					[61.352611142581438, 3.9858915068117082],
					[61.352611088443368, 3.9858915023787969],
					[61.352611060652215, 3.9858915049617991],
					[61.352430560789962, 3.9859276036242162],
					[61.352430530660286, 3.9859276159494206],
					[61.352430475973087, 3.9859276453266683],
					[61.352430450659014, 3.9859276662469445],
					[61.352160961942978, 3.9862276543047792],
					[61.351877675978479, 3.9864526436306753],
					[61.351877674258795, 3.9864526457798148],
					[61.351877670750589, 3.9864526482157112],
					[61.351877668962047, 3.9864526485024716],
					[61.351688812377262, 3.9866165114964143],
					[61.351466668425431, 3.9866526024972524],
					[61.351466620616812, 3.9866526232758659],
					[61.351466542885682, 3.986652686319613],
					[61.351466512963135, 3.9866527285847901],
					[61.350708113318241, 3.9886610298634708],
					[61.350708107746541, 3.9886610494898198],
					[61.350708101074417, 3.9886610880259572],
					[61.350708100042873, 3.9886611087980706],
					[61.350721999789748, 3.9890306075591471],
					[61.350721999996175, 3.9890306131460975],
					[61.35072200123453, 3.9890306223143939],
					[61.350722002335218, 3.9890306277580643],
					[61.350799802139051, 3.9895722286472544],
					[61.350799802139051, 3.9895722286472544],
					[61.350799802207852, 3.9895722305095798],
					[61.350799802276669, 3.9895722323718994],
					[61.350894201353583, 3.9901639244129945],
					[61.350952600654502, 3.9908083178046376],
					[61.350952600723303, 3.9908083196669701],
					[61.350952600792098, 3.990808321529304],
					[61.350952600792098, 3.990808321529304],
					[61.351010900904157, 3.9913333192969893],
					[61.351049801220768, 3.9918056164216487],
					[61.35104980163343, 3.9918056275957259],
					[61.351049806998873, 3.9918056510901185],
					[61.35104981016309, 3.9918056636968759],
					[61.351224809265595, 3.9923361597871589],
					[61.351363696004761, 3.9928167128175995],
					[61.351247002827314, 3.9935888661488392],
					[61.351124803232331, 3.9942666641412901],
					[61.351124803369807, 3.9942666678660008],
					[61.351124800961927, 3.9942666757448988],
					[61.351124801099402, 3.9942666794696096],
					[61.351088701890994, 3.9946277651029183],
					[61.350983112407782, 3.995061028167656],
					[61.35076094718314, 3.9954776618222616],
					[61.350352689721888, 3.9957470338977572],
					[61.350049894131821, 3.9959470298887303],
					[61.349722098173693, 3.9961415282950483],
					[61.3497220824202, 3.9961415401820899],
					[61.349722051944866, 3.9961415675376655],
					[61.349722039011638, 3.9961415827199902],
					[61.349485939451348, 3.9964637826962819],
					[61.349485930852069, 3.9964637934384242],
					[61.349485919156614, 3.996463817788761],
					[61.349485913240194, 3.9964638281016587],
					[61.349333112959606, 3.9968666288191539],
					[61.349333113028308, 3.9968666306814109],
					[61.348941531323106, 3.9979165817482634],
					[61.3484888253357, 3.99830260106223],
					[61.348086132859045, 3.9983108989252298],
					[61.347847268362173, 3.9982248113980718],
					[61.34784725928197, 3.9982248091039323],
					[61.347847241121535, 3.9982248045156399],
					[61.347847232041325, 3.9982248022215003],
					[61.347538931915722, 3.998174802707104],
					[61.347538906669705, 3.9981748011251912],
					[61.347538857346628, 3.9981748052669985],
					[61.347538833338298, 3.9981748128528531],
					[61.347136033309731, 3.9983192125318063],
					[61.347136015561382, 3.998319219116341],
					[61.347135984742167, 3.9983192371567484],
					[61.347135970777046, 3.9983192487556303],
					[61.346749871417678, 3.9986470487482166],
					[61.346749853255794, 3.9986470685102624],
					[61.346749823398007, 3.9986471126196692],
					[61.346749810739169, 3.9986471352479227],
					[61.346608110807274, 3.9990666367116887],
					[61.346608106747887, 3.9990666485992525],
					[61.346608102206204, 3.9990666718024341],
					[61.346608100829663, 3.9990666832610526],
					[61.346580400565557, 3.9994055773085968],
					[61.346491505184467, 4.0000555530814363],
					[61.346258114903122, 4.0007166234954665],
					[61.34613041889741, 4.0009888152320201],
					[61.346130414769192, 4.0009888252570844],
					[61.346130406650062, 4.0009888490313656],
					[61.346130404379046, 4.0009888606326474],
					[61.346066504363307, 4.0013055585575197],
					[61.345927605444039, 4.0019277567564382],
					[61.345927603792724, 4.0019277607663986],
					[61.345927603172989, 4.0019277683575893],
					[61.345927602415941, 4.0019277722246329],
					[61.345830402317738, 4.0026055710591288],
					[61.345830401560704, 4.0026055749261387],
					[61.345830400940891, 4.0026055825173001],
					[61.345830401009501, 4.0026055843793644],
					[61.345808100748421, 4.002905578733162],
					[61.345741505791018, 4.0033555437854851],
					[61.345549824255663, 4.0037943015089308],
					[61.34518044028178, 4.0043304797104975],
					[61.344863744974951, 4.0047165723525797],
					[61.344863743323543, 4.0047165763622603],
					[61.344863738989275, 4.0047165808003706],
					[61.344863737337846, 4.0047165848100379],
					[61.344619250198328, 4.0050609654924942],
					[61.344422067026883, 4.0052359501232839],
					[61.34442206358689, 4.0052359544184615],
					[61.344422056569691, 4.005235959284839],
					[61.34442205402398, 4.0052359634372356],
					[61.344269299790099, 4.0053998136457372],
					[61.343936114287814, 4.0054553941898821],
					[61.343519458882731, 4.0053276097682931],
					[61.343519446156819, 4.0053276061815852],
					[61.343519419879335, 4.0053276010128753],
					[61.343519407222047, 4.0053275992880781],
					[61.343130606711334, 4.0053136998889896],
					[61.343130595911227, 4.0053136997405616],
					[61.34313057259093, 4.0053137015912084],
					[61.343130560965072, 4.0053137034474879],
					[61.342680561382885, 4.005402603199296],
					[61.342680533865469, 4.0054026132113165],
					[61.342680484470712, 4.0054026398261708],
					[61.342680460804793, 4.0054026567146108],
					[61.342510961390282, 4.0055665555480795],
					[61.342510944190153, 4.0055665770224493],
					[61.34251091708142, 4.0055666225527391],
					[61.342510907241433, 4.0055666484705315],
					[61.342424807171504, 4.0058860488588568],
					[61.342424804831481, 4.005886058596543],
					[61.342424801114326, 4.0058860797910167],
					[61.342424799668613, 4.0058860893859354],
					[61.342408100440906, 4.0062277902986745],
					[61.342408105389502, 4.0062278269651355],
					[61.342408128632606, 4.0062278962947646],
					[61.342408146927077, 4.0062279289579354],
					[61.342624846722615, 4.0064862285048388],
					[61.342624874580039, 4.0064862521505296],
					[61.342624936075012, 4.0064862854094505],
					[61.342624970743955, 4.0064862986036651],
					[61.343016665163411, 4.0065445962620903],
					[61.343369348684831, 4.0066168948350267],
					[61.343541639205014, 4.0066723893316141],
					[61.343541649247982, 4.0066723933471362],
					[61.343541669196775, 4.0066723976543228],
					[61.343541680065471, 4.0066723996651525],
					[61.343813802138413, 4.0067001914340885],
					[61.34395813770746, 4.0068640193162475],
					[61.344091505239504, 4.0070833653025915],
					[61.344124801112983, 4.0074222207629404],
					[61.344124802350031, 4.0074222299301097],
					[61.344124806681371, 4.00742224984094],
					[61.344124808881283, 4.0074222607273482],
					[61.344235903052756, 4.0077778394679155],
					[61.344285900882277, 4.0082889183954862],
					[61.34428590211926, 4.0082889275627407],
					[61.344285904387668, 4.008288940311254],
					[61.344285905624702, 4.0082889494785023],
					[61.344330406418969, 4.0084667495729596],
					[61.344330427738548, 4.0084667911212701],
					[61.344330491429666, 4.0084668596209534],
					[61.344330530223999, 4.0084668871431388],
					[61.34442772982954, 4.0085029871712026],
					[61.344427734369603, 4.0085029883196777],
					[61.344427741661065, 4.0085029909020182],
					[61.344427746201113, 4.0085029920504791],
					[61.344697098865858, 4.0085779797754464],
					[61.34484705567413, 4.0087195399120503],
					[61.345041527223039, 4.0089390051092728],
					[61.345233113560568, 4.0094222732008538],
					[61.345233113629071, 4.0094222750629038],
					[61.345233114591927, 4.0094222767822636],
					[61.345233114591927, 4.0094222767822636],
					[61.34544151477261, 4.0099222761337865],
					[61.345441516698259, 4.009922279572586],
					[61.34544151876095, 4.0099222867354865],
					[61.345441520618074, 4.0099222883122216],
					[61.345635921004686, 4.0103139898731337],
					[61.345635921967478, 4.0103139915925512],
					[61.345635925818797, 4.0103139984702096],
					[61.34563592678159, 4.0103140001896254],
					[61.345733120821926, 4.0104806890139377],
					[61.345880408777781, 4.0108333620250169],
					[61.345972003288693, 4.0112278378147446],
					[61.346027602149434, 4.0116028286965104],
					[61.346027603180751, 4.0116028322780837],
					[61.34602760434904, 4.0116028395838814],
					[61.346027605380357, 4.0116028431654547],
					[61.346158104313453, 4.0121889412167748],
					[61.346277603859043, 4.0127833383639775],
					[61.346277605027247, 4.0127833456698667],
					[61.346277608121085, 4.0127833564148103],
					[61.346277610115145, 4.0127833617159974],
					[61.346391502378324, 4.0131361384465363],
					[61.346419199249475, 4.0135027962647412],
					[61.346358103679229, 4.0140860557226601],
					[61.346135920349653, 4.0147138101016804],
					[61.345916533292552, 4.0150470907455409],
					[61.345916529852254, 4.0150470950398667],
					[61.345916523108507, 4.0150471073527463],
					[61.345916519668165, 4.0150471116470943],
					[61.345691519653293, 4.0155082116399976],
					[61.345691516418277, 4.0155082215205402],
					[61.345691509879806, 4.0155082394195345],
					[61.345691506644812, 4.0155082493000727],
					[61.345560906649169, 4.0159999491565088],
					[61.345560905134313, 4.0159999568898543],
					[61.345560902173027, 4.0159999742186683],
					[61.34556090072661, 4.0159999838141172],
					[61.345494201114214, 4.0168388836175355],
					[61.345494201182632, 4.0168388854796202],
					[61.345494200425193, 4.0168388893462641],
					[61.345494199599301, 4.0168388913508277],
					[61.345452601277294, 4.0177221810260626],
					[61.345360902835594, 4.0183471668894732],
					[61.345297008151896, 4.0186805360988638],
					[61.34518592276828, 4.018911007121071],
					[61.345002649923124, 4.0192331581952443],
					[61.344713818885502, 4.0194220132753635],
					[61.344291652736771, 4.0195248059108781],
					[61.344291636707489, 4.019524810335958],
					[61.344291605748431, 4.0195248246298059],
					[61.344291590750231, 4.0195248326365558],
					[61.343858191050742, 4.0198081328621225],
					[61.343858171854492, 4.019808149028508],
					[61.343858138070388, 4.0198081843733764],
					[61.343858124445184, 4.0198082052714801],
					[61.343524824176271, 4.0204277056675268],
					[61.343524820804149, 4.020427711822907],
					[61.343524815780142, 4.0204277219869873],
					[61.343524813302324, 4.0204277280000253],
					[61.343324816380857, 4.0209471224101048],
					[61.34289422334642, 4.0218749067088],
					[61.342338728316143, 4.0228081984103214],
					[61.342338725701509, 4.0228082006991013],
					[61.342338723291945, 4.0228082085736334],
					[61.342338720677326, 4.0228082108624097],
					[61.342022033973912, 4.0234470839503569],
					[61.341791597857728, 4.0236887161649211],
					[61.341508257779637, 4.0237498038513735],
					[61.341508248904773, 4.0237498071357916],
					[61.341508231086685, 4.0237498118427668],
					[61.341508222211829, 4.0237498151272],
					[61.341086022220288, 4.0239276150954115],
					[61.341085993254318, 4.023927634684366],
					[61.341085942750254, 4.0239276801716501],
					[61.341085922243131, 4.0239277096514527],
					[61.340949821825191, 4.024194309038907],
					[61.340949816043221, 4.024194323067448],
					[61.340949806404524, 4.0241943545637504],
					[61.340949801585197, 4.0241943703119079],
					[61.340888701686936, 4.0245971700521839],
					[61.340888701270821, 4.0245971832272662],
					[61.340888701332979, 4.0245972094351927],
					[61.340888700916864, 4.0245972226102937],
					[61.340941501377287, 4.025072221099685],
					[61.340941504811703, 4.0250722411532927],
					[61.340941516978098, 4.0250722785453918],
					[61.340941524815825, 4.0250722960260905],
					[61.341122024644115, 4.0254000960117411],
					[61.341122042592545, 4.0254001193757611],
					[61.34112208365044, 4.02540015966503],
					[61.341122107722569, 4.0254001783098996],
					[61.341369276768297, 4.0255279624728191],
					[61.341555440126633, 4.0257279214419679],
					[61.341749830148089, 4.0260418050660034],
					[61.341749836818153, 4.0260418152420128],
					[61.341749852841239, 4.0260418351674607],
					[61.341749861231662, 4.0260418431971985],
					[61.341972060611234, 4.0262557440651348],
					[61.34197209011743, 4.0262557637199548],
					[61.341972153191733, 4.026255791147185],
					[61.341972186828094, 4.0262558007815388],
					[61.342147187406518, 4.0262668995369211],
					[61.342147213410207, 4.0262668972754048],
					[61.342147261635411, 4.0262668877353827],
					[61.342147285645581, 4.0262668801724812],
					[61.342438943311599, 4.0261280010287726],
					[61.342861076115788, 4.0261362995187975],
					[61.343655536868319, 4.0263168906278661],
					[61.344077719350643, 4.0265029823642866],
					[61.344077741224183, 4.0265029901238671],
					[61.344077785592425, 4.0265029980527087],
					[61.344077809049743, 4.0265029999418225],
					[61.344505609251819, 4.0264834992251002],
					[61.344505612003069, 4.0264835006605706],
					[61.344505618263348, 4.0264834996652041],
					[61.344505620946322, 4.02648349923862],
					[61.344908321229489, 4.0264418985582378],
					[61.344908328384058, 4.0264418974206544],
					[61.344908341798913, 4.026441895287677],
					[61.344908349847827, 4.0264418940078892],
					[61.345602843436694, 4.0262640947863026],
					[61.346458316147263, 4.0261057995738048],
					[61.34693889488139, 4.0261168999887715],
					[61.346938899352985, 4.0261168992776772],
					[61.34693890836455, 4.0261168997176702],
					[61.346938911941834, 4.0261168991487919],
					[61.347319411646133, 4.0260946002706746],
					[61.347319433109895, 4.026094596857333],
					[61.347319474938168, 4.0260945845861942],
					[61.347319495302699, 4.0260945757284139],
					[61.347691795177035, 4.0258945766313765],
					[61.347691816162651, 4.0258945601821239],
					[61.34769185269964, 4.0258945262747883],
					[61.347691869971364, 4.0258945066699701],
					[61.348014067035329, 4.0253779107094099],
					[61.348322347405428, 4.0249418393875036],
					[61.348719422604837, 4.0246252387182961],
					[61.349052636838245, 4.0248001399021476],
					[61.349274794607965, 4.0254333204207526],
					[61.349141503586253, 4.0263388587581375],
					[61.348894207318914, 4.0272471462818373],
					[61.348894205666845, 4.0272471502908864],
					[61.348894204909108, 4.0272471541577151],
					[61.348894204977391, 4.0272471560200307],
					[61.348702607947345, 4.0281027436500718],
					[61.348513712753537, 4.0286110297469593],
					[61.348513708623244, 4.0286110397692552],
					[61.348513704008248, 4.0286110611075081],
					[61.348513701666583, 4.0286110708454919],
					[61.348452604351522, 4.0290305581200725],
					[61.348360909696048, 4.0293471351315615],
					[61.348138743135522, 4.0299192524990985],
					[61.347527717001618, 4.0301998181765715],
					[61.347527708194789, 4.030199823322131],
					[61.347527689686878, 4.0301998337553631],
					[61.347527681774409, 4.0301998387588114],
					[61.347135981577296, 4.0304859377188995],
					[61.347135967677474, 4.030485951165784],
					[61.347135941666487, 4.0304859777753625],
					[61.347135931412211, 4.0304859925160841],
					[61.346822030958762, 4.0309831931496047],
					[61.346822029306573, 4.0309831971581724],
					[61.346822024145297, 4.0309832035972661],
					[61.346822021598769, 4.0309832077479335],
					[61.346433136322638, 4.0317331819625846],
					[61.345991556341147, 4.0321915602507588],
					[61.345991552005913, 4.0321915646853004],
					[61.34599154512415, 4.0321915732702562],
					[61.345991541683219, 4.0321915775627568],
					[61.345558141666594, 4.0327498773688752],
					[61.345558134852993, 4.0327498878157382],
					[61.345558121293969, 4.0327499105716056],
					[61.345558114548574, 4.0327499228806047],
					[61.345238715248961, 4.0335166234869861],
					[61.345238715248961, 4.0335166234869861],
					[61.345074814967937, 4.0339138238065138],
					[61.345074813452051, 4.0339138315389382],
					[61.34507480766905, 4.0339138455676213],
					[61.345074806084966, 4.0339138514379353],
					[61.344905406103891, 4.0346055505024356],
					[61.344702610461901, 4.0353666387527509],
					[61.344599822598312, 4.03563320469451],
					[61.344427639077935, 4.0358720818353619],
					[61.344191540750053, 4.036180478335659],
					[61.344191535656698, 4.0361804866354207],
					[61.344191526296122, 4.036180501230958],
					[61.344191522097063, 4.0361805093888092],
					[61.344072021616775, 4.0364138094084421],
					[61.344072013423116, 4.0364138313101856],
					[61.344072001780177, 4.0364138818523632],
					[61.344071999983228, 4.036413906484853],
					[61.344080399577159, 4.0366944056901115],
					[61.344080399713462, 4.036694409414225],
					[61.344080400812253, 4.0366944148585144],
					[61.344080400948563, 4.0366944185826306],
					[61.344141501139021, 4.0373472188020285],
					[61.344141501207162, 4.0373472206640919],
					[61.344141502237818, 4.0373472242463304],
					[61.344141502374093, 4.037347227970475],
					[61.344252601581736, 4.0381417257202781],
					[61.344369201349963, 4.0391889227538451],
					[61.344369202448668, 4.0391889281982554],
					[61.344369203751725, 4.0391889392289313],
					[61.34436920485043, 4.0391889446733602],
					[61.344577605140813, 4.0400833450932527],
					[61.344577606239476, 4.040083350537734],
					[61.344577609263055, 4.0400833594228089],
					[61.344577611256064, 4.0400833647254997],
					[61.344783110430612, 4.0406889634633529],
					[61.344944210281476, 4.0411806625516808],
					[61.344944211243941, 4.0411806642720185],
					[61.3449442132369, 4.0411806695748433],
					[61.344944214267414, 4.0411806731573101],
					[61.345041514253971, 4.0414306736196304],
					[61.3450415152845, 4.0414306772021131],
					[61.345041519134227, 4.0414306840835543],
					[61.345041521127186, 4.0414306893863943],
					[61.345197020294513, 4.0417444879197539],
					[61.345410919935766, 4.0421861867616071],
					[61.345410923785472, 4.0421861936432224],
					[61.345410930590525, 4.0421862075482036],
					[61.345410936228909, 4.0421862141462963],
					[61.345666536202117, 4.042555713201657],
					[61.345666542870994, 4.0425557233824749],
					[61.345666559718062, 4.0425557413149757],
					[61.345666569069948, 4.0425557510705694],
					[61.346002668725681, 4.0428473506195681],
					[61.346002674295953, 4.0428473553557138],
					[61.346002685368497, 4.0428473629658264],
					[61.346002690938782, 4.0428473677019889],
					[61.346580475016069, 4.04322515836364],
					[61.346852644163775, 4.0434834288890231],
					[61.347144217848303, 4.043947286318641],
					[61.347272007617484, 4.0443333572384734],
					[61.34743590503043, 4.0449556447706181],
					[61.347563701620828, 4.0456028297161692],
					[61.347588701144403, 4.0458778186175612],
					[61.347588706568601, 4.0458778439813745],
					[61.347588724571757, 4.0458778935757431],
					[61.347588738045125, 4.0458779176646322],
					[61.347710937958567, 4.0460473174520226],
					[61.347710952948432, 4.0460473338085601],
					[61.347710986369577, 4.0460473622303867],
					[61.347711004868842, 4.046047376157996],
					[61.34795270499248, 4.0461779765966854],
					[61.34795273229917, 4.0461779853833137],
					[61.34795278670849, 4.0461779973695897],
					[61.347952815531769, 4.0461779984236212],
					[61.348124978901517, 4.0461641018788725],
					[61.34834432009005, 4.046227985581476],
					[61.348888788682224, 4.0465473672859789],
					[61.349399872422573, 4.0469334533951402],
					[61.349722059251832, 4.0472307425727463],
					[61.349910954985795, 4.0474307378919319],
					[61.349910955948161, 4.0474307396127349],
					[61.349910959661599, 4.0474307427711027],
					[61.349910962412665, 4.047430744208647],
					[61.350080458654503, 4.0475918425529533],
					[61.350383156860083, 4.0479029397715394],
					[61.350383165249291, 4.0479029478093036],
					[61.350383180997312, 4.0479029603015286],
					[61.350383190212845, 4.0479029663352151],
					[61.350810990340442, 4.0481862674826656],
					[61.350811013243259, 4.0481862788422758],
					[61.350811063316677, 4.0481862952659737],
					[61.350811088698599, 4.0481863006132528],
					[61.351211088696616, 4.0482084991678198],
					[61.351211113874513, 4.0482084989276306],
					[61.35121116292791, 4.0482084874137945],
					[61.351211186939409, 4.0482084798651741],
					[61.351416715006643, 4.0481085157011494],
					[61.352602738269944, 4.0484973894631411],
					[61.352602751957257, 4.0484973947893206],
					[61.352602779059829, 4.0484973979912704],
					[61.352602794399807, 4.0484973993090332],
					[61.354797192654388, 4.0485668991704449],
					[61.356369391842456, 4.0486335009556385],
					[61.356369415989995, 4.048633497131549],
					[61.356369463322693, 4.0486334877621681],
					[61.356369484651147, 4.0486334806373385],
					[61.357038985933613, 4.048316881414209],
					[61.357039030253468, 4.0483168387958708],
					[61.357039086706905, 4.0483167342995907],
					[61.357039098014077, 4.0483166744261441],
					[61.356925197863468, 4.0474332741811159],
					[61.356925194636283, 4.0474332597033058],
					[61.356925187287572, 4.0474332308893368],
					[61.356925182271688, 4.0474332166948521],
					[61.356614081931951, 4.0467499167447754],
					[61.35661407230819, 4.0467498995333235],
					[61.356614049619331, 4.0467498694028432],
					[61.356614037380567, 4.0467498544792804],
					[61.356466851628561, 4.0466109690643695],
					[61.356283481714655, 4.0463471122110706],
					[61.356114095290017, 4.0457832538423117],
					[61.356003000544135, 4.0451305854881028],
					[61.356014100360738, 4.0448028072103499],
					[61.35601410022462, 4.0448028034847567],
					[61.356014100088522, 4.0448027997591778],
					[61.356014099952418, 4.0448027960335837],
					[61.356002999825868, 4.0441749958875537],
					[61.356002999621687, 4.0441749902991946],
					[61.356002999213317, 4.0441749791224595],
					[61.356002998114782, 4.0441749736758421],
					[61.355947399434875, 4.0437582769276155],
					[61.355894601726042, 4.0432139069243096],
					[61.355952997832567, 4.0428417317582275],
					[61.355952999280866, 4.0428417221607198],
					[61.35595299963061, 4.0428417071166658],
					[61.355953000252626, 4.0428416995237351],
					[61.355950200158468, 4.0422221993766208],
					[61.355950199954208, 4.0422221937882874],
					[61.355950199409506, 4.0422221788860311],
					[61.355950198310893, 4.0422221734395123],
					[61.355872398115451, 4.0416527729912266],
					[61.355872392894682, 4.0416527532098527],
					[61.355872379838253, 4.0416527159353537],
					[61.355872371040135, 4.041652696721294],
					[61.355672406831182, 4.041322156452388],
					[61.355697391987064, 4.0411194800404902],
					[61.356058454447748, 4.0406584286684568],
					[61.356350150193315, 4.0403279324157353],
					[61.356350153566332, 4.0403279262598497],
					[61.356350163063532, 4.0403279153852818],
					[61.356350167399022, 4.0403279109503387],
					[61.356575158657613, 4.0399890219654528],
					[61.356858445108074, 4.0396668382976042],
					[61.356989040246894, 4.0395390436359575],
					[61.356989049675889, 4.0395390308980916],
					[61.356989068533892, 4.0395390054223599],
					[61.35698907706854, 4.0395389928263921],
					[61.357044576988017, 4.0394333927052086],
					[61.35704458531827, 4.0394333745206676],
					[61.357044595582188, 4.0394333354192229],
					[61.35704459930453, 4.0394333142185044],
					[61.357050198594486, 4.0393611147844801],
					[61.357050200042536, 4.0393611051864848],
					[61.357050199293148, 4.0393610846952619],
					[61.357050198058154, 4.0393610755229732],
					[61.357039099383385, 4.0392721763432213],
					[61.356978000858376, 4.0387082977587445],
					[61.357000196835692, 4.0384139501022709],
					[61.357202972337632, 4.0379640042234106],
					[61.357530755731815, 4.0375557247184402],
					[61.357530758346705, 4.0375557224297101],
					[61.357530760893376, 4.0375557182781305],
					[61.35753076171958, 4.0375557162732951],
					[61.357922361619373, 4.0370140171520568],
					[61.357922365886594, 4.0370140108535448],
					[61.357922374421008, 4.0370139982565112],
					[61.357922377793869, 4.0370139920999586],
					[61.358336273973435, 4.0362167973277989],
					[61.358614062767231, 4.035764015366178],
					[61.358875149937241, 4.0354473324664824],
					[61.359266844548443, 4.0350362372518989],
					[61.359266852324687, 4.0350362285217232],
					[61.359266866088547, 4.0350362113454414],
					[61.359266872007964, 4.0350362010363847],
					[61.359416872371305, 4.0347807016742943],
					[61.359416878290681, 4.0347806913651407],
					[61.359416887378259, 4.0347806693100061],
					[61.359416890614639, 4.034780659426989],
					[61.359536285103658, 4.034391780852439],
					[61.359733472753803, 4.0340501005175344],
					[61.35973347695267, 4.0340500923552529],
					[61.359733482735592, 4.0340500783198951],
					[61.359733486108333, 4.034050072162664],
					[61.359786286091342, 4.0339139725427033],
					[61.359786293117303, 4.0339139433192734],
					[61.359786297331567, 4.0339138864351831],
					[61.35978629534597, 4.0339138567694874],
					[61.359775195214333, 4.0338638577346826],
					[61.359775193084658, 4.0338638487039056],
					[61.359775188961649, 4.0338638343683222],
					[61.359775186900194, 4.0338638272005403],
					[61.35972798709787, 4.0337416272753943],
					[61.359727986203545, 4.0337416274174709],
					[61.359727986135347, 4.0337416255544731],
					[61.359727986135347, 4.0337416255544731],
					[61.35946688604799, 4.0330916249582129],
					[61.359466883160358, 4.0330916197956137],
					[61.359466877316876, 4.0330916076074175],
					[61.359466874429238, 4.0330916024448182],
					[61.359280781362656, 4.0327610135532268],
					[61.359144604305364, 4.0324249700649597],
					[61.359189099227429, 4.0319528182320292],
					[61.359189099227429, 4.0319528182320292],
					[61.359189099159195, 4.0319528163690874],
					[61.359250199137527, 4.0312500227584653],
					[61.359322396933685, 4.0307306342403413],
					[61.359408495800508, 4.0303250421869325],
					[61.359408497937473, 4.0303250268567803],
					[61.359408498634096, 4.030324996765188],
					[61.35940849898244, 4.030324981719394],
					[61.3593195986358, 4.0293860817009737],
					[61.359219599611251, 4.0283110924964349],
					[61.35923349981546, 4.0273472195370035],
					[61.359400187824676, 4.0264444804108654],
					[61.359802839015458, 4.0258946857362812],
					[61.36022483874369, 4.02622792841475],
					[61.360358109650875, 4.026511166967687],
					[61.360460904608004, 4.0269500467914545],
					[61.360460906669843, 4.0269500539589309],
					[61.360460911619541, 4.0269500662885909],
					[61.360460913681386, 4.0269500734560681],
					[61.360627614172678, 4.0273722728909611],
					[61.36062762001665, 4.0273722850784921],
					[61.360627633561577, 4.027372311032047],
					[61.360627642088531, 4.0273723227927807],
					[61.360777641693097, 4.027564022476696],
					[61.360777643618363, 4.0275640259182177],
					[61.360777648294835, 4.0275640307959906],
					[61.360777650151753, 4.0275640323745128],
					[61.361080450393203, 4.0279084321550336],
					[61.36108045225015, 4.02790843373357],
					[61.36108045603229, 4.027908438753709],
					[61.361080458783555, 4.0279084401899938],
					[61.361263735831436, 4.0280918181922569],
					[61.361410914642136, 4.0283806775967301],
					[61.361560909902607, 4.0288167614977972],
					[61.361727608133741, 4.0293722563372159],
					[61.361727609096356, 4.0293722580580997],
					[61.36172761012719, 4.0293722616420613],
					[61.361727610195452, 4.0293722635051452],
					[61.361988709642624, 4.0301583624843307],
					[61.361988713561196, 4.0301583712310913],
					[61.361988722360948, 4.0301583904455169],
					[61.36198872621128, 4.0301583973291555],
					[61.362288726390481, 4.0306889979665508],
					[61.362288738767475, 4.0306890166125902],
					[61.362288767788343, 4.0306890476044073],
					[61.362288783674387, 4.030689063818591],
					[61.36273598434321, 4.0310084620766062],
					[61.36273598530579, 4.0310084637975807],
					[61.362735988951343, 4.0310084650920377],
					[61.362735989913929, 4.0310084668130113],
					[61.362897044252293, 4.0311140367964198],
					[61.363010910581586, 4.0313500672236753],
					[61.363210902251744, 4.0321528333394943],
					[61.363285900967831, 4.0329611171079254],
					[61.363285901104256, 4.0329611208343028],
					[61.363285902135047, 4.0329611244185717],
					[61.363285902271478, 4.0329611281449473],
					[61.363341501986298, 4.0333583284715138],
					[61.363438700172516, 4.0340583150991138],
					[61.363458099964639, 4.0348527923367072],
					[61.363416503437783, 4.0352610622764944],
					[61.363299815598431, 4.0356749157378013],
					[61.363141535182322, 4.0359081868768385],
					[61.362988743536668, 4.0361304736247092],
					[61.362844260436795, 4.0362831576502618],
					[61.36265816465491, 4.036452652057692],
					[61.36265815426372, 4.0364526630777222],
					[61.36265813279153, 4.0364526908492957],
					[61.362658124325392, 4.0364527053116079],
					[61.362491523578981, 4.036761005261944],
					[61.362491520274268, 4.0367610132825842],
					[61.362491512012575, 4.0367610333342059],
					[61.362491507881721, 4.0367610433600198],
					[61.362405410591229, 4.0370499334652274],
					[61.362135915270073, 4.0376971237243628],
					[61.362135912723389, 4.0376971278765623],
					[61.362135910449283, 4.0376971394813133],
					[61.362135907902548, 4.0376971436335376],
					[61.361966507940267, 4.038280543494225],
					[61.361966505597941, 4.0382805532357535],
					[61.361966502633905, 4.0382805705717493],
					[61.361966501117799, 4.0382805783081865],
					[61.361930400959075, 4.0386110777206481],
					[61.361930401981169, 4.0386111056675498],
					[61.361930412074408, 4.0386111602837671],
					[61.361930420251191, 4.0386111870950563],
					[61.362010919791324, 4.038775087512759],
					[61.36201092831736, 4.0387750992771458],
					[61.362010943648798, 4.0387751249529726],
					[61.362010953001032, 4.0387751347122833],
					[61.362347038353001, 4.0391418202647476],
					[61.362602628183701, 4.0395751011367569],
					[61.36260263010864, 4.0395751045792334],
					[61.362602633958495, 4.0395751114642033],
					[61.362602635883434, 4.039575114906679],
					[61.36286373279048, 4.0399501095111328],
					[61.363202628827644, 4.0405029047986387],
					[61.363202630684441, 4.040502906378034],
					[61.363202632609351, 4.0405029098206331],
					[61.363202635360494, 4.0405029112581206],
					[61.363297035455382, 4.0406418123274159],
					[61.363297043981241, 4.0406418240927877],
					[61.363297064610308, 4.040641847055932],
					[61.363297076713522, 4.0406418582537187],
					[61.363385977403041, 4.0407112573943795],
					[61.36338598297344, 4.0407112621326426],
					[61.363385994046084, 4.0407112697459366],
					[61.363385999548377, 4.0407112726209631],
					[61.363999900385146, 4.0410640721579334],
					[61.363999917854365, 4.0410640825047448],
					[61.363999956029673, 4.041064093314521],
					[61.363999975083281, 4.041064097787781],
					[61.364877775441137, 4.0411751981615645],
					[61.364877835293882, 4.0411751867912331],
					[61.364877936781028, 4.0411751332070596],
					[61.364877979241641, 4.0411750889879849],
					[61.365102979106716, 4.0407250898779559],
					[61.365102985648456, 4.040725071972795],
					[61.365102995980742, 4.0407250347248569],
					[61.365102998808865, 4.0407250136606869],
					[61.365127998617766, 4.0403806166084495],
					[61.365186290928207, 4.0397889996089935],
					[61.365477772836662, 4.0395363874345085],
					[61.36564981768727, 4.0397528927533575],
					[61.365763707193366, 4.040158353346448],
					[61.365763713104407, 4.0401583674017427],
					[61.365763724926389, 4.0401583955123304],
					[61.365763731663627, 4.0401584075623234],
					[61.36593592892671, 4.0404251031823568],
					[61.366016525311487, 4.0405639984679889],
					[61.366097022591696, 4.0407139922476221],
					[61.366258122065972, 4.0410278906836758],
					[61.366258131622352, 4.0410279060351186],
					[61.366258153350088, 4.0410279344488682],
					[61.36625816462702, 4.0410279476531095],
					[61.366552665019206, 4.0412973472988822],
					[61.366552673340692, 4.0412973534754046],
					[61.366552690051797, 4.0412973676918513],
					[61.366552699267622, 4.041297373726473],
					[61.366880498681866, 4.0414890727539436],
					[61.366880507829627, 4.0414890769252469],
					[61.36688052612508, 4.0414890852678491],
					[61.366880535272792, 4.0414890894391595],
					[61.367302734635821, 4.0416334895356627],
					[61.367302744609773, 4.0416334917016998],
					[61.367302763663368, 4.0416334961756855],
					[61.367302773637348, 4.0416334983417217],
					[61.36775277361054, 4.0416918979337968],
					[61.367752785373185, 4.0416918998160822],
					[61.367752806973591, 4.0416919001374874],
					[61.367752817705679, 4.0416918984347161],
					[61.368202817922487, 4.0416501997820404],
					[61.368202830443288, 4.0416501977954136],
					[61.36820285362807, 4.0416501922424457],
					[61.36820286608075, 4.0416501883923175],
					[61.368697266196229, 4.041477989645732],
					[61.368697270599839, 4.0414779870726498],
					[61.368697282090075, 4.0414779815007371],
					[61.368697287456101, 4.0414779806492858],
					[61.36938338716336, 4.0411445803958115],
					[61.370063986081952, 4.0408195803786677],
					[61.370063988764954, 4.0408195799528634],
					[61.370063993994791, 4.0408195753740159],
					[61.370063996677793, 4.040819574948209],
					[61.370475087432325, 4.0405918805578285],
					[61.370902877326152, 4.0404140840215632],
					[61.370902878220477, 4.0404140838796128],
					[61.370902881797832, 4.0404140833118021],
					[61.370902882624065, 4.0404140813061833],
					[61.371297282895895, 4.0402334817493042],
					[61.371297296174788, 4.0402334758925234],
					[61.371297319845048, 4.040233459013769],
					[61.371297332161447, 4.0402334514352685],
					[61.371452932023857, 4.0400973504194955],
					[61.371452945030185, 4.0400973371078397],
					[61.371452968223593, 4.0400973071830144],
					[61.37145297841068, 4.0400972905698627],
					[61.371552975659874, 4.0399028956831371],
					[61.371683462709768, 4.0396723190119479],
					[61.371877931053838, 4.0394612527666949],
					[61.372266811840603, 4.0391973658265421],
					[61.372266835306341, 4.0391973433552275],
					[61.372266873158161, 4.0391972961050797],
					[61.372266888370461, 4.0391972693205052],
					[61.372383488159493, 4.0388778694889629],
					[61.372383492707783, 4.0388778462719506],
					[61.372383499189539, 4.0388778021276641],
					[61.372383499266192, 4.0388777796206705],
					[61.372297399075912, 4.0380721819120255],
					[61.372230799293391, 4.0372193842091244],
					[61.37223079689123, 4.0372193677196222],
					[61.372230787615244, 4.0372193354508612],
					[61.372230783424392, 4.0372193192454535],
					[61.372033483160529, 4.0367721182206209],
					[61.37203347834803, 4.036772109612337],
					[61.372033468722961, 4.0367720923957524],
					[61.372033463016102, 4.0367720839295211],
					[61.371789076361438, 4.0364277018529915],
					[61.371391885476015, 4.03547492384949],
					[61.371391878738407, 4.0354749117984063],
					[61.371391867119932, 4.0354748892757177],
					[61.371391859556162, 4.0354748792304065],
					[61.370947359613488, 4.0348831805206764],
					[61.370947354869045, 4.0348831737766169],
					[61.370947343591467, 4.0348831605727433],
					[61.370947338020891, 4.0348831558344447],
					[61.370547347381006, 4.0345026646730178],
					[61.370314058619627, 4.0342165774402403],
					[61.370027962126201, 4.0338192829309136],
					[61.370027952705442, 4.0338192713070962],
					[61.370027933037726, 4.0338192500652355],
					[61.370027921964727, 4.03381924245289],
					[61.369675125028643, 4.0335470437326189],
					[61.369627951694831, 4.0335081664655439],
					[61.369422382749569, 4.0331805155028322],
					[61.369172392721353, 4.032377747734059],
					[61.368958497356246, 4.0314666650592281],
					[61.368880799423714, 4.0308332795625832],
					[61.368841900038362, 4.0303638966591917],
					[61.368877999304608, 4.0295333195072249],
					[61.368997397018035, 4.0287167378584829],
					[61.369102992427848, 4.0282778567618127],
					[61.369297380324426, 4.027730691070615],
					[61.369619550212697, 4.0272612339038156],
					[61.370341831316907, 4.0266362502157573],
					[61.370341836546196, 4.0266362456345233],
					[61.370341847830851, 4.0266362344660953],
					[61.370341852991849, 4.0266362280212507],
					[61.370708452685982, 4.0261973271367451],
					[61.370708459635566, 4.0261973204070394],
					[61.370708470715222, 4.0261973036474901],
					[61.370708475807916, 4.0261972953388829],
					[61.370875175628335, 4.0258889947852738],
					[61.370875178174657, 4.025888990630917],
					[61.370875183198969, 4.0258889804586167],
					[61.370875184850959, 4.0258889764466268],
					[61.3709473787912, 4.0257139915613038],
					[61.371072369986827, 4.0255112047790762],
					[61.371400165553545, 4.0249807105842654],
					[61.371697341943523, 4.0245668451284127],
					[61.371977913223354, 4.024375165226167],
					[61.37197791404931, 4.0243751632200722],
					[61.37197791673227, 4.0243751627928201],
					[61.371977917558233, 4.0243751607867226],
					[61.372411218276191, 4.0240584604935812],
					[61.372411222679439, 4.0240584579177137],
					[61.372411233137882, 4.0240584487537463],
					[61.372411238367064, 4.0240584441717475],
					[61.372677936626545, 4.0238029463258824],
					[61.372827935987139, 4.0236640460946607],
					[61.37282794981774, 4.0236640307694413],
					[61.372827973764977, 4.0236639969613375],
					[61.372827983881621, 4.0236639784784476],
					[61.372964083854647, 4.0233417776298541],
					[61.372964087984464, 4.0233417675988905],
					[61.372964093629449, 4.0233417498280666],
					[61.372964095902276, 4.0233417382182717],
					[61.373083496105082, 4.0227361384250431],
					[61.373083496105082, 4.0227361384250431],
					[61.373083496931024, 4.0227361364188337],
					[61.373083496931024, 4.0227361364188337],
					[61.373175197269539, 4.0222361363047456],
					[61.373175197822007, 4.0222361268435129],
					[61.373175199821233, 4.0222361077785678],
					[61.373175200373694, 4.022236098317336],
					[61.37317240013212, 4.0218721983932673],
					[61.373172400063751, 4.0218721965295039],
					[61.373172399858618, 4.0218721909382715],
					[61.373172399721867, 4.0218721872107643],
					[61.373133500257822, 4.0212805867633064],
					[61.373133499295122, 4.0212805850420619],
					[61.373133499158357, 4.0212805813145813],
					[61.373133499158357, 4.0212805813145813],
					[61.373080801656698, 4.0207417082156756],
					[61.373152996061123, 4.0203500417111835],
					[61.373211290888172, 4.0201083616551401],
					[61.37330848565005, 4.0198639743280022],
					[61.373308488885272, 4.0198639644390344],
					[61.373308493703902, 4.0198639486737218],
					[61.373308496044828, 4.0198639389273065],
					[61.373416895757089, 4.019325039307704],
					[61.373416897272079, 4.0193250315675568],
					[61.373416898581802, 4.0193250182361195],
					[61.373416900165182, 4.0193250123597277],
					[61.3734669004347, 4.0184583118314325],
					[61.373466900229438, 4.0184583062401575],
					[61.373466899818908, 4.0184582950576235],
					[61.373466899613632, 4.0184582894663503],
					[61.373422399605225, 4.0176582897961657],
					[61.373422399263063, 4.017658280477411],
					[61.373422395895808, 4.0176582622677541],
					[61.373422394727754, 4.0176582549553821],
					[61.373358494696831, 4.0173888546320269],
					[61.373358477093653, 4.0173888161968625],
					[61.373358427989018, 4.0173887527909953],
					[61.373358397313424, 4.0173887258139329],
					[61.373172297142133, 4.0172859253761706],
					[61.3731722861367, 4.0172859196327435],
					[61.373172265156974, 4.0172859117307489],
					[61.37317225511427, 4.0172859077084482],
					[61.372869543887781, 4.017199833280765],
					[61.372600203743886, 4.0165999674197108],
					[61.372652990953185, 4.0160890016009088],
					[61.372902886718208, 4.0158807886785013],
					[61.373094423144934, 4.0158473989429311],
					[61.373583311225147, 4.0158196004590163],
					[61.373583330899862, 4.0158195973202586],
					[61.373583369218117, 4.0158195874579024],
					[61.373583386967333, 4.0158195808769621],
					[61.373847287282771, 4.0156918794441205],
					[61.373847308403789, 4.0156918667010411],
					[61.373847345900458, 4.0156918344731807],
					[61.373847363238866, 4.0156918167095048],
					[61.37418626263009, 4.0152168154400369],
					[61.374186268616377, 4.0152168069860474],
					[61.374186281277794, 4.015216784343993],
					[61.374186286301303, 4.0152167741688931],
					[61.374361285953775, 4.0147750738144703],
					[61.374361290634866, 4.0147750543202703],
					[61.374361298208441, 4.0147750156172419],
					[61.374361300206594, 4.0147749965511421],
					[61.374355800040064, 4.0144388961991151],
					[61.374355798666407, 4.0144388832952567],
					[61.374355795093294, 4.0144388594940246],
					[61.374355792825334, 4.0144388467328813],
					[61.374252993169435, 4.0140721453113457],
					[61.374252987255758, 4.0140721312573824],
					[61.374252973639749, 4.0140721034348932],
					[61.374252966831747, 4.0140720895236388],
					[61.374172385807157, 4.0139499177251778],
					[61.374119598287741, 4.0137388680424211],
					[61.374083500393091, 4.0132721893061305],
					[61.374069599709991, 4.0128666924383953],
					[61.374069599230502, 4.0128666793919541],
					[61.374069593731512, 4.0128666521491194],
					[61.374069590569114, 4.0128666395309516],
					[61.373972390585436, 4.0125555407945868],
					[61.373972356335443, 4.0125554900211222],
					[61.373972263822637, 4.0125554204281659],
					[61.37397220638556, 4.0125553996021486],
					[61.373797205638191, 4.0125498001596416],
					[61.373797154800023, 4.0125498120247851],
					[61.373797065777154, 4.0125498618555255],
					[61.373797028418281, 4.0125498978145764],
					[61.373577628279044, 4.0129220987361816],
					[61.37357762070981, 4.0129221130671846],
					[61.373577609148583, 4.0129221411581755],
					[61.373577605225037, 4.0129221567819275],
					[61.373441504966337, 4.0135332561366832],
					[61.373441504277523, 4.0135332618706432],
					[61.373441501868555, 4.0135332697538173],
					[61.37344150207403, 4.0135332753450284],
					[61.373410906838004, 4.0137749348729006],
					[61.373355480426305, 4.0138692092773587],
					[61.373322276046984, 4.0138719926846456],
					[61.373319559462651, 4.0138692767988422],
					[61.373211284627722, 4.0136888197215477],
					[61.373066892344127, 4.0131999427316805],
					[61.373066891381363, 4.013199941010706],
					[61.373066891312867, 4.0131999391469906],
					[61.373066891312867, 4.0131999391469906],
					[61.372961291137088, 4.0128693385035712],
					[61.372961278552182, 4.0128693142673972],
					[61.372961250904929, 4.0128692718144618],
					[61.372961233985507, 4.0128692520194775],
					[61.372844534091819, 4.0127637527695912],
					[61.372844502795019, 4.0127637333950998],
					[61.372844437035283, 4.0127637063993182],
					[61.372844401746484, 4.0127637007845074],
					[61.372544429717038, 4.0127608995091215],
					[61.372341775786261, 4.0126998135645033],
					[61.371897301594181, 4.0124637277137216],
					[61.371586208721709, 4.0122609319458507],
					[61.371586206864606, 4.0122609303677494],
					[61.371586203150386, 4.0122609272115293],
					[61.371586201361794, 4.0122609274970484],
					[61.371466801458524, 4.0121915274245863],
					[61.371466797744311, 4.0121915242683839],
					[61.371466791347189, 4.0121915215404877],
					[61.371466787701472, 4.0121915202479199],
					[61.371275093126975, 4.0120970235750901],
					[61.371141836580769, 4.0120220481106745],
					[61.370994597049666, 4.0118138315501781],
					[61.370978000420379, 4.0114833008980071],
					[61.371011297243086, 4.0109472625840024],
					[61.371494566344609, 4.0101557114084958],
					[61.372958448978643, 4.0081890345042304],
					[61.373772277997659, 4.0074418993784091],
					[61.374444394068881, 4.0074418998837515],
					[61.375247188136967, 4.0074918995632096],
					[61.375247198937046, 4.0074918997120168],
					[61.375247221431572, 4.0074918998667046],
					[61.375247232094551, 4.0074918962878536],
					[61.375977830715165, 4.0073751988080497],
					[61.376141729627122, 4.0073501985787585],
					[61.376141731415714, 4.0073501982929036],
					[61.376141733135725, 4.007350196143169],
					[61.376600010359439, 4.0072724017978016],
					[61.377019349260543, 4.0073001960877619],
					[61.377441589127002, 4.0075001680649729],
					[61.37783045461989, 4.0078390388736986],
					[61.378077638274974, 4.0081501177427468],
					[61.37848592728794, 4.0087695013959319],
					[61.378719213407877, 4.0092250720489124],
					[61.378791506830069, 4.0094833540925761],
					[61.378791507792897, 4.0094833558137397],
					[61.378791508824314, 4.0094833593989421],
					[61.378791508824314, 4.0094833593989421],
					[61.378910909123377, 4.009861159514446],
					[61.378910910086205, 4.0098611612356443],
					[61.378910910154744, 4.009861163099699],
					[61.378910911117607, 4.0098611648208724],
					[61.379024811093842, 4.0101917654208687],
					[61.379024827462068, 4.0101917946812513],
					[61.379024867078755, 4.0101918446028488],
					[61.379024891358597, 4.0101918688493345],
					[61.379255491162468, 4.0103418672226416],
					[61.379255563665794, 4.0103418818908594],
					[61.379255700356275, 4.0103418563049935],
					[61.379255762754802, 4.010341816336636],
					[61.379372363112715, 4.0101779159644888],
					[61.37937237157589, 4.0101779014872321],
					[61.37937238575087, 4.0101778710972615],
					[61.379372392356913, 4.0101778550416567],
					[61.379472370933968, 4.0098307287175068],
					[61.379647305257897, 4.0097223706170322],
					[61.379647313100932, 4.0097223637388186],
					[61.379647328924101, 4.0097223537105622],
					[61.379647335804265, 4.0097223451111184],
					[61.379814007646154, 4.0095668711796746],
					[61.380133373050825, 4.0094418851608662],
					[61.380133387222472, 4.0094418791463475],
					[61.380133412745735, 4.00944186381773],
					[61.380133425954554, 4.0094418560819625],
					[61.38056681860423, 4.009091860420213],
					[61.380988967806502, 4.0088057952166887],
					[61.381272211810376, 4.0087835008289714],
					[61.38148058721945, 4.0087752016272837],
					[61.381713867183961, 4.0088140975887407],
					[61.38171387261827, 4.008814098595443],
					[61.381713884312674, 4.0088140986017287],
					[61.381713888852723, 4.0088140997513477],
					[61.381924980054606, 4.0088251984688403],
					[61.382155571398002, 4.0088584979753819],
					[61.382155576832311, 4.0088584989821214],
					[61.382155586738122, 4.0088584992742389],
					[61.382155592172445, 4.0088585002809589],
					[61.382372191899961, 4.008866900011661],
					[61.382372192794236, 4.0088668998687442],
					[61.382602793064322, 4.0088751998961536],
					[61.3826028092302, 4.0088751991878135],
					[61.382602843281994, 4.0088751956210222],
					[61.382602859310715, 4.0088751911841518],
					[61.382855658722114, 4.0087973914372794],
					[61.382855671173679, 4.0087973875720113],
					[61.382855693256815, 4.0087973765417164],
					[61.382855703851234, 4.0087973710980149],
					[61.38326399872782, 4.0085473742354596],
					[61.38365286130216, 4.0083418943353841],
					[61.38384721755731, 4.0083168985574975],
					[61.384116689774856, 4.0083030013516128],
					[61.384297169981238, 4.0083307986828638],
					[61.38429717087552, 4.0083307985399168],
					[61.384633266906015, 4.0083807966712612],
					[61.385086063969879, 4.0084640969378382],
					[61.38508606665274, 4.0084640965089946],
					[61.385086073875677, 4.0084640972298144],
					[61.385086076627111, 4.0084640986653852],
					[61.385524976929197, 4.0085140984441985],
					[61.385524984152099, 4.0085140991650423],
					[61.385524996809366, 4.0085141008926204],
					[61.385525003069439, 4.0085140998919666],
					[61.386036102716687, 4.0085057995620588],
					[61.3860361090453, 4.0085058004258585],
					[61.386036120671129, 4.0085057985674606],
					[61.386036126931202, 4.0085057975667846],
					[61.386891727274381, 4.0083890983282409],
					[61.38689172906296, 4.0083890980423194],
					[61.386891734428723, 4.0083890971845486],
					[61.386891736217308, 4.0083890968986244],
					[61.387694435781128, 4.0082418962002979],
					[61.387694437569706, 4.008241895914356],
					[61.387694442041187, 4.0082418951994994],
					[61.38769444382978, 4.0082418949135583],
					[61.387952839576506, 4.0081834965952154],
					[61.388952825392757, 4.0080057990025741],
					[61.389627796191256, 4.0079585008154393],
					[61.390352778274227, 4.0080390992396362],
					[61.390352782745708, 4.0080390985246739],
					[61.39035279272008, 4.0080391006812306],
					[61.390352798085829, 4.0080390998232778],
					[61.390811097912078, 4.0080445994211233],
					[61.390811106923564, 4.008044599855948],
					[61.390811126666591, 4.0080445985748137],
					[61.390811137398117, 4.0080445968588725],
					[61.39133063683034, 4.0079473967906107],
					[61.391330644878977, 4.0079473955036029],
					[61.391330661801945, 4.0079473909217871],
					[61.391330670676325, 4.0079473876269862],
					[61.391816770660498, 4.0077640878102461],
					[61.391816776851975, 4.0077640849443688],
					[61.391816791023452, 4.0077640789265851],
					[61.391816798040658, 4.0077640740528784],
					[61.392252898030208, 4.0075195749206021],
					[61.392252900644472, 4.0075195726267099],
					[61.392252906835942, 4.0075195697607393],
					[61.392252910344524, 4.0075195673238255],
					[61.392694510079615, 4.0072279663831054],
					[61.392694511868193, 4.0072279660970462],
					[61.392694516271071, 4.0072279635170327],
					[61.392694517991067, 4.0072279613660919],
					[61.393127917614116, 4.0069112605935784],
					[61.393127920297005, 4.0069112601644532],
					[61.39312792291129, 4.0069112578704384],
					[61.393127924631273, 4.0069112557194657],
					[61.393661219681327, 4.0064862607969181],
					[61.394539011908549, 4.005861265794799],
					[61.3956695076768, 4.0051334676177852],
					[61.395669511185325, 4.0051334651803225],
					[61.395669517376732, 4.0051334623135331],
					[61.395669519990953, 4.0051334600191799],
					[61.396139020282853, 4.0047807606012542],
					[61.396139028125567, 4.004780753717986],
					[61.39613904284807, 4.0047807382294671],
					[61.396139049796531, 4.0047807314893147],
					[61.396422350321515, 4.0044584322284953],
					[61.396422357201288, 4.0044584236231158],
					[61.396422370892225, 4.0044584045472709],
					[61.396422376809099, 4.0044583942199381],
					[61.396647377165763, 4.0040333941033834],
					[61.396647381294052, 4.0040333840621827],
					[61.396647388587638, 4.0040333622578386],
					[61.396647392715906, 4.0040333522166387],
					[61.396761293054148, 4.0036167539165861],
					[61.396761293742479, 4.0036167481781231],
					[61.396761296150785, 4.0036167402882441],
					[61.396761296907783, 4.0036167364148936],
					[61.396819597105491, 4.0032972368670743],
					[61.396819597793815, 4.0032972311285953],
					[61.396819599239173, 4.0032972215167426],
					[61.396819599033236, 4.0032972159214424],
					[61.396866899374615, 4.0026944164773015],
					[61.396866900131613, 4.0026944126038959],
					[61.396866899856988, 4.0026944051434912],
					[61.396866899788343, 4.0026944032783902],
					[61.396872399853173, 4.0023111140334722],
					[61.39693349762608, 4.0018306245412827],
					[61.396933499345984, 4.0018306223897628],
					[61.396933499071324, 4.0018306149293332],
					[61.396933499828286, 4.0018306110559188],
					[61.396955797852769, 4.0014361391155404],
					[61.397066873412498, 4.0011251103224605],
					[61.397258440259115, 4.0009362412702458],
					[61.397258449752833, 4.0009362303690308],
					[61.397258467777284, 4.0009362068447185],
					[61.397258476308053, 4.0009361942216177],
					[61.397511275866599, 4.0004667954170667],
					[61.397511283365645, 4.0004667792067066],
					[61.39751129299804, 4.0004667476455111],
					[61.397511296919994, 4.0004667320081673],
					[61.397614096597557, 3.9998361311950208],
					[61.397614098248724, 3.9998361271782095],
					[61.397614098868246, 3.9998361195743684],
					[61.39761409866216, 3.9998361139789544],
					[61.397636299118105, 3.9995278221782464],
					[61.397702995690921, 3.9991139380170968],
					[61.397805790613511, 3.9986611625364117],
					[61.397961255761849, 3.9983084417390318],
					[61.398197297002262, 3.9981779753823958],
					[61.398197299616399, 3.9981779730872624],
					[61.398197306633172, 3.9981779682103635],
					[61.398197310210278, 3.9981779676370808],
					[61.398550109734302, 3.9979445671859684],
					[61.398550124593434, 3.9979445554234081],
					[61.398550150665841, 3.9979445306064201],
					[61.398550162704709, 3.9979445155434696],
					[61.398733462682792, 3.9976862161964322],
					[61.398733472038778, 3.9976862015633099],
					[61.39873348703621, 3.997686169140001],
					[61.398733492746366, 3.9976861532150152],
					[61.398858493300253, 3.9972361544729527],
					[61.398858493162798, 3.9972361507425247],
					[61.398858494813886, 3.9972361467254132],
					[61.398858494676425, 3.9972361429950065],
					[61.39895019318368, 3.9968222513032554],
					[61.399064086783341, 3.9964611701354378],
					[61.399200175551009, 3.9961556968073793],
					[61.399361264048402, 3.9959140143848169],
					[61.399525150758102, 3.9956918329001416],
					[61.399972328077943, 3.995252955219065],
					[61.400700102701592, 3.9947501725271342],
					[61.402013987007635, 3.9940668805490187],
					[61.402675062875687, 3.9937751905229435],
					[61.403086133900878, 3.9936807971075661],
					[61.403158304708811, 3.993672400399026],
					[61.40320278690826, 3.9936751995808626],
					[61.403574965964715, 3.9937001987431628],
					[61.403969329598979, 3.9938112885803081],
					[61.404191601200978, 3.9939140744769714],
					[61.404499882312841, 3.9941223615143713],
					[61.404827668401069, 3.9943807508204823],
					[61.405135949221794, 3.9946779318669634],
					[61.405372036313743, 3.9950001155086268],
					[61.405574833947874, 3.9953029115339245],
					[61.405574836836955, 3.9953029167004033],
					[61.405574844334957, 3.9953029248808214],
					[61.405574847155279, 3.9953029281817014],
					[61.406188747433134, 3.9960279291986085],
					[61.406188756788396, 3.9960279389581723],
					[61.406188778181722, 3.9960279580469789],
					[61.406188789256795, 3.9960279656540258],
					[61.406672089307627, 3.9963501651293161],
					[61.406672101276932, 3.9963501725931683],
					[61.406672126041073, 3.9963501855117625],
					[61.406672138835951, 3.9963501909665164],
					[61.407011035539909, 3.9964584894683814],
					[61.407383234235908, 3.9965890898666379],
					[61.407383237813058, 3.9965890892929492],
					[61.407383245104718, 3.9965890918770053],
					[61.407383248750548, 3.9965890931690522],
					[61.407836029821453, 3.9967084883930175],
					[61.40834431281688, 3.9969557796791624],
					[61.408344313711133, 3.9969557795357495],
					[61.408344313779871, 3.9969557814015184],
					[61.408524882921199, 3.9970418657539097],
					[61.408624858555953, 3.9971418403203649],
					[61.408624859587725, 3.9971418439085222],
					[61.408624864196568, 3.9971418469230517],
					[61.408624866053856, 3.9971418485020118],
					[61.408816551975782, 3.997314036038452],
					[61.408949838280286, 3.9974918161637869],
					[61.409105418865799, 3.9977139892189593],
					[61.40921651013997, 3.998058360069491],
					[61.409216514198164, 3.9980583725567382],
					[61.409216524997355, 3.9980583971010262],
					[61.409216531669678, 3.9980584072922589],
					[61.409419232207412, 3.9983751084985539],
					[61.409419240736952, 3.9983751202689981],
					[61.409419260410132, 3.9983751415139186],
					[61.409419270728243, 3.9983751529975953],
					[61.409797070726079, 3.9986945530879066],
					[61.409797110142044, 3.998694573044872],
					[61.409797192826964, 3.9986945954491664],
					[61.409797236921435, 3.998694595887252],
					[61.410088937275063, 3.998639095333659],
					[61.410088989418973, 3.9986390700813996],
					[61.410089068461787, 3.998638993594017],
					[61.410089095360732, 3.9986389423588689],
					[61.410214095234693, 3.9980611427066175],
					[61.410214096748341, 3.9980611349562745],
					[61.410214098812638, 3.9980611177330969],
					[61.410214100257576, 3.9980611081168846],
					[61.410255799809974, 3.9971778087963563],
					[61.410255799809974, 3.9971778087963563],
					[61.410255799672498, 3.9971778050645885],
					[61.41025579960376, 3.9971778031987042],
					[61.410272400078782, 3.9962417164749304],
					[61.410397386137547, 3.9954112110563762],
					[61.410852850626419, 3.9950724101702901],
					[61.41116666744243, 3.9951251975457889],
					[61.41116668099405, 3.9951251991253942],
					[61.411166707959758, 3.9951251985527017],
					[61.411166722336894, 3.9951251981228704],
					[61.411369408267703, 3.9951030005037573],
					[61.411736094112015, 3.9951140998109826],
					[61.411736101334995, 3.9951141005290509],
					[61.411736115643357, 3.9951140982332269],
					[61.411736123760548, 3.9951140988077976],
					[61.412063923921899, 3.9950751982078225],
					[61.412063926604681, 3.9950751977773504],
					[61.412063932864598, 3.9950751967729041],
					[61.412063935547437, 3.9950751963424258],
					[61.412308297874418, 3.9950308039150482],
					[61.412655561457477, 3.9951001967330093],
					[61.412655576797647, 3.9951001980256406],
					[61.41265561009201, 3.9951001983144159],
					[61.412655626257688, 3.9951001975975284],
					[61.412952826150139, 3.9950612987766596],
					[61.412952873065173, 3.9950612781089938],
					[61.412952951830455, 3.995061218543146],
					[61.412952983611916, 3.9950611777789438],
					[61.413097384314376, 3.9947222770877833],
					[61.413097388441884, 3.9947222670399904],
					[61.413097394770752, 3.9947222434993361],
					[61.413097397935204, 3.9947222317290039],
					[61.413150198029726, 3.9943861314184943],
					[61.413150199061718, 3.9943861106048875],
					[61.413150196723066, 3.9943860715613204],
					[61.413150194177973, 3.9943860513217859],
					[61.413016893810266, 3.9938582511497507],
					[61.413016892778401, 3.9938582475611901],
					[61.413016890783496, 3.9938582422501376],
					[61.413016890645935, 3.9938582385180417],
					[61.412897392026672, 3.9934832436156751],
					[61.412802993169961, 3.9931249489271199],
					[61.41280299310116, 3.9931249470610974],
					[61.412758493295733, 3.9929610481447027],
					[61.412758492263883, 3.9929610445561967],
					[61.412758491231997, 3.9929610409677125],
					[61.412758490200154, 3.9929610373792248],
					[61.412639091984403, 3.9925916431184243],
					[61.412597394767495, 3.9924305562509046],
					[61.412486298298155, 3.9918166699328541],
					[61.412402999205199, 3.9911082761433576],
					[61.41240299817327, 3.9911082725549805],
					[61.412402996934908, 3.9911082633685946],
					[61.412402995902994, 3.9911082597802126],
					[61.412308495683696, 3.9906582585357526],
					[61.412308493688684, 3.9906582532250101],
					[61.412308491418358, 3.9906582404503173],
					[61.412308489423332, 3.9906582351395756],
					[61.412141890053725, 3.990172138002356],
					[61.411980790776553, 3.9896527404627276],
					[61.411980790776553, 3.9896527404627276],
					[61.411980790707716, 3.9896527385967611],
					[61.411725192176107, 3.9888499432275371],
					[61.411558497047416, 3.9882249604624707],
					[61.411494599053597, 3.9877499795125098],
					[61.411447399721851, 3.9870082906576885],
					[61.411428000053697, 3.9865110927490703],
					[61.411427999984802, 3.986511090883146],
					[61.411427999847035, 3.9865110871512819],
					[61.411427998883873, 3.9865110854290937],
					[61.411391898659971, 3.9860388843126411],
					[61.411391899347549, 3.9860388785711214],
					[61.411391897145684, 3.9860388676630776],
					[61.411391896044769, 3.9860388622090426],
					[61.411330795824981, 3.9857221618628245],
					[61.411330794724016, 3.9857221564088166],
					[61.411330792591023, 3.9857221473667499],
					[61.411330792453228, 3.9857221436349026],
					[61.411197392347916, 3.9852749421557934],
					[61.411197390421606, 3.9852749387114943],
					[61.411197388357479, 3.9852749315353884],
					[61.411197387325437, 3.9852749279473403],
					[61.411091886950082, 3.9849999283098705],
					[61.410980787400419, 3.9847082284421624],
					[61.410980779626151, 3.9847082127993638],
					[61.410980762358058, 3.9847081836672271],
					[61.41098075196993, 3.984708170321662],
					[61.410775155745561, 3.9844665744601437],
					[61.410380770252999, 3.983941592709026],
					[61.410022379771938, 3.9832471114928207],
					[61.409952981608242, 3.9830971167165385],
					[61.409952978718692, 3.9830971115505074],
					[61.409952972870649, 3.983097099352642],
					[61.40995296998107, 3.9830970941866126],
					[61.409889070066811, 3.9829942947093562],
					[61.409889059678541, 3.9829942813648356],
					[61.409889038076678, 3.9829942566854233],
					[61.409889026863056, 3.9829942453505534],
					[61.409661227735199, 3.9828081452406634],
					[61.40947512962893, 3.9826498489649054],
					[61.409475099222306, 3.982649829455962],
					[61.409475035383551, 3.9826498059398143],
					[61.40947500009392, 3.9826498003544692],
					[61.409255609335617, 3.9826497997258956],
					[61.409041766591344, 3.9826304046058749],
					[61.408852961424756, 3.9825081665948225],
					[61.408725185302913, 3.9821999234574688],
					[61.408725182344362, 3.9821999164259734],
					[61.408725174638803, 3.9821999026506609],
					[61.408725170786006, 3.9821998957630234],
					[61.408639071390368, 3.9820581962267245],
					[61.408639040570016, 3.9820581655243585],
					[61.408638971363608, 3.982058118474487],
					[61.408638931189053, 3.9820581024146331],
					[61.408550031160075, 3.9820442028235297],
					[61.408549967667959, 3.9820442130364317],
					[61.408549860979889, 3.9820442714878386],
					[61.40854981771497, 3.9820443178606073],
					[61.408480418268297, 3.9821971176226065],
					[61.408480417442973, 3.982197119632183],
					[61.408480414898072, 3.9821971237951743],
					[61.408480414967009, 3.982197125660909],
					[61.408352668998681, 3.9825136908241641],
					[61.408163974840186, 3.9824998050569449],
					[61.407997323216506, 3.9823692426761998],
					[61.407997307600539, 3.9823692339270669],
					[61.407997274580033, 3.9823692167164726],
					[61.407997258069791, 3.9823692081111735],
					[61.407722309742411, 3.9822859233795578],
					[61.407533473072036, 3.9820804925296662],
					[61.407414086815315, 3.9817721285474135],
					[61.407414078077529, 3.9817721111852782],
					[61.407414057988206, 3.9817720787582074],
					[61.407414045742357, 3.9817720638371372],
					[61.407222345859729, 3.9815665636554827],
					[61.407222340218418, 3.9815665570559253],
					[61.40722232811045, 3.9815665458663414],
					[61.407222320749526, 3.98156654142015],
					[61.406933456844051, 3.9813470683948098],
					[61.406777995708659, 3.9810499438501061],
					[61.406733499964801, 3.9804805853532748],
					[61.406711300414514, 3.9801221933084183],
					[61.406711300031425, 3.9795860994542767],
					[61.406711300031425, 3.9795860994542767],
					[61.406711299962446, 3.9795860975886521],
					[61.406708499619931, 3.9792193988322815],
					[61.406708500307253, 3.9792193930914852],
					[61.406708498999087, 3.9792193820416597],
					[61.406708498792149, 3.9792193764447972],
					[61.406653000243175, 3.9787499876318266],
					[61.406652999669646, 3.9782861086483057],
					[61.406697398978068, 3.9777333168936213],
					[61.406730798350551, 3.9773444268442506],
					[61.406825195595943, 3.9768500388344021],
					[61.406825197315428, 3.9768500366807991],
					[61.40682519710839, 3.9768500310839356],
					[61.406825198002629, 3.9768500309399544],
					[61.406877997739379, 3.9765111315482677],
					[61.406877997601342, 3.976511127817004],
					[61.406877999113803, 3.9765111200665042],
					[61.406877998975752, 3.9765111163352582],
					[61.406886298680178, 3.9764083158141581],
					[61.406886300261611, 3.9764083099292811],
					[61.406886299778471, 3.9764082968698986],
					[61.406886300396657, 3.9764082892633899],
					[61.406880799584677, 3.9762999918980424],
					[61.406858499945287, 3.9756916924360994],
					[61.406858499462068, 3.9756916793767427],
					[61.406858493955383, 3.9756916521124692],
					[61.406858490858468, 3.9756916413507777],
					[61.406791891351475, 3.9754777403771957],
					[61.406791882543878, 3.975477721151583],
					[61.406791863278293, 3.9754776867196275],
					[61.406791851856973, 3.9754776697916951],
					[61.406558487332155, 3.9752055116986367],
					[61.406464098877485, 3.9746888783948968],
					[61.406452999602067, 3.9743778005280417],
					[61.406475200036546, 3.9736889064017968],
					[61.40647519982938, 3.9736889008050227],
					[61.406475199276919, 3.9736888858802595],
					[61.406475199000681, 3.973688878417895],
					[61.406411299382775, 3.9730860798201695],
					[61.406411296078446, 3.9730860634621434],
					[61.406411288782778, 3.9730860364869125],
					[61.406411283759027, 3.9730860222826467],
					[61.40623348435259, 3.9726666217007387],
					[61.406233478572666, 3.9726666113718707],
					[61.406233467012918, 3.9726665907141245],
					[61.406233460338804, 3.9726665805293466],
					[61.405827973067574, 3.9721220970834747],
					[61.40554738394507, 3.9715110217852714],
					[61.405377990449736, 3.9710777350293491],
					[61.405211291983356, 3.9705055441777368],
					[61.405211291019995, 3.9705055424563858],
					[61.40521128998757, 3.9705055388695047],
					[61.405211288955115, 3.9705055352826495],
					[61.40497518905034, 3.9698110352187532],
					[61.40497518705449, 3.9698110299106157],
					[61.404975182099555, 3.969811017573019],
					[61.404975179209508, 3.9698110124090467],
					[61.404616878657777, 3.9690832120271775],
					[61.404616867166638, 3.9690831932372892],
					[61.404616838887954, 3.9690831583881359],
					[61.404616822994726, 3.9690831421846879],
					[61.404233474945094, 3.9687859824738121],
					[61.404075193614311, 3.9682138509962117],
					[61.403941894974672, 3.9676249555719014],
					[61.403941890913742, 3.9676249430908226],
					[61.40394188189768, 3.9676249182729233],
					[61.403941877011661, 3.9676249078015124],
					[61.403777981593393, 3.967313814863668],
					[61.403625185321374, 3.9669388243781851],
					[61.403625184357971, 3.9669388226570339],
					[61.40362518332546, 3.9669388190704731],
					[61.403625182362056, 3.9669388173493192],
					[61.403372382309563, 3.9663860180715975],
					[61.403272386064536, 3.966161025694328],
					[61.403186293011906, 3.9659138478098948],
					[61.40302799712186, 3.9651110657755506],
					[61.402897398322381, 3.9642471692547914],
					[61.402897396187981, 3.9642471602166576],
					[61.402897390955829, 3.9642471404193529],
					[61.402897386963751, 3.9642471298045132],
					[61.402689087162003, 3.9636860308749129],
					[61.40268908702361, 3.9636860271442234],
					[61.402689085096739, 3.9636860237022207],
					[61.402689083169882, 3.9636860202601967],
					[61.402452983377152, 3.9631416207417023],
					[61.402452982344499, 3.9631416171554097],
					[61.402452978421593, 3.963141608406175],
					[61.402452975669689, 3.9631416069739007],
					[61.402044575659176, 3.962377706337894],
					[61.402044572699644, 3.9623776993097994],
					[61.402044565955599, 3.9623776872632872],
					[61.402044562171014, 3.9623776822448593],
					[61.401733470933671, 3.9619498957340005],
					[61.401569585335736, 3.9616221225536035],
					[61.401430791065756, 3.9612110404407774],
					[61.401197399635436, 3.9604110735322751],
					[61.401208499744392, 3.959736102325802],
					[61.401208499744392, 3.959736102325802],
					[61.401208499675157, 3.9597361004605629],
					[61.401208499605893, 3.9597360985953278],
					[61.401205800297511, 3.9593582998310621],
					[61.401205800020506, 3.9593582923701001],
					[61.401205798641506, 3.959358279457883],
					[61.401205798433764, 3.9593582738621573],
					[61.401136297606968, 3.9588332737386338],
					[61.401136297399191, 3.9588332681429281],
					[61.401136295125944, 3.9588332553752235],
					[61.401136293060468, 3.9588332482032103],
					[61.401080801910524, 3.9586221820873826],
					[61.401097397868377, 3.9584000404041557],
					[61.401280777242597, 3.9578557016594238],
					[61.401658451118848, 3.9574223317507884],
					[61.401658452838014, 3.957422329596501],
					[61.401658452768743, 3.9574223277312424],
					[61.402069553075364, 3.956930727357328],
					[61.402069561671148, 3.9569307165856813],
					[61.402069576935723, 3.9569306916008706],
					[61.402069582779568, 3.9569306793975088],
					[61.402205783056218, 3.9566167806867067],
					[61.40220578635595, 3.9566167726474251],
					[61.402205791166949, 3.9566167568578963],
					[61.402205793641762, 3.9566167508284336],
					[61.402297379876387, 3.9562612004955637],
					[61.402483443985666, 3.956069538510175],
					[61.402483454300516, 3.9560695255837457],
					[61.402483473897448, 3.9560694961448051],
					[61.402483482354555, 3.9560694816421713],
					[61.402658481632599, 3.9556806818917125],
					[61.402658485001595, 3.9556806757175065],
					[61.402658490706685, 3.9556806597830652],
					[61.402658493112149, 3.9556806518881111],
					[61.402814092875253, 3.9550972523304084],
					[61.402814095211347, 3.9550972425700581],
					[61.402814098164441, 3.9550972252038439],
					[61.40281409878142, 3.9550972175979706],
					[61.402841897692966, 3.9547778373593361],
					[61.402950192307514, 3.9544028556929995],
					[61.402950193063063, 3.9544028518177528],
					[61.402950195399143, 3.9544028420573034],
					[61.402950196154698, 3.9544028381820548],
					[61.403039095618368, 3.9539500399688587],
					[61.403039097815707, 3.9539500264776954],
					[61.4030390997358, 3.9539500055251913],
					[61.403039100144717, 3.9539499923232557],
					[61.403028000450988, 3.9536193970402174],
					[61.403030800375575, 3.9528278003722139],
					[61.403039099921948, 3.9521028051896572],
					[61.403050199988186, 3.95177500609203],
					[61.403050198608462, 3.9517749931794239],
					[61.40305019674328, 3.9517749672095079],
					[61.403050195363569, 3.9517749542968996],
					[61.402980794610606, 3.9514749559419409],
					[61.402980793577704, 3.9514749523559822],
					[61.402980793369608, 3.9514749467600279],
					[61.402980792336649, 3.9514749431740599],
					[61.402911300254289, 3.9512416718788668],
					[61.402911299964174, 3.9510000166010677],
					[61.402986294418476, 3.9505389551850287],
					[61.403161271202976, 3.9501140092169873],
					[61.403589046871083, 3.9496501364348813],
					[61.403589061378817, 3.9496501153207642],
					[61.403589084134836, 3.949650074105711],
					[61.40358909327729, 3.9496500538600365],
					[61.403708492580854, 3.9492194535568168],
					[61.403708494916508, 3.9492194437957644],
					[61.403708497799414, 3.9492194245631609],
					[61.40370849931027, 3.9492194168122161],
					[61.403739096120084, 3.9488361537277705],
					[61.403872379769147, 3.9485611879594131],
					[61.403872383068389, 3.9485611799188489],
					[61.403872388842061, 3.9485611658478725],
					[61.403872391316455, 3.948561159817451],
					[61.403955791123934, 3.948291759268209],
					[61.40395579256532, 3.9482917496517906],
					[61.403955797305926, 3.9482917319947579],
					[61.403955798747326, 3.9482917223783396],
					[61.40402519912741, 3.9477028229831554],
					[61.404025198988585, 3.9477028192523966],
					[61.404025199605158, 3.9477028116461148],
					[61.404025200360529, 3.9477028077705612],
					[61.404044599603878, 3.9472389086882886],
					[61.404044600081598, 3.9472388973512338],
					[61.404044599387404, 3.947238878697497],
					[61.404044598146086, 3.9472388695154423],
					[61.403919597616181, 3.9464499696334943],
					[61.403919596652536, 3.946449967912951],
					[61.403919596444226, 3.9464499623168736],
					[61.403919596374799, 3.9464499604515111],
					[61.403783496398795, 3.945777759346063],
					[61.403783491302846, 3.9457777432821302],
					[61.403783478358825, 3.9457777097234947],
					[61.403783469686026, 3.9457776942389748],
					[61.403558470365532, 3.945416593661458],
					[61.40355846568616, 3.945416588789739],
					[61.403558457082774, 3.945416575170781],
					[61.40355845329762, 3.9454165701541988],
					[61.403141852670387, 3.9449248702662314],
					[61.403141843311666, 3.9449248605230762],
					[61.403141822875206, 3.9449248431918291],
					[61.403141811728034, 3.9449248337384089],
					[61.402377915357903, 3.9444109368355535],
					[61.402072335920245, 3.9441887526357911],
					[61.401689050615786, 3.9437470694132495],
					[61.401688989036977, 3.9437470343560195],
					[61.401688857093994, 3.9437470182065959],
					[61.401688788379282, 3.9437470330941347],
					[61.401263787847697, 3.9440331345486941],
					[61.40126377471249, 3.9440331441827907],
					[61.401263752157803, 3.9440331666018311],
					[61.401263740949908, 3.9440331796765502],
					[61.40097765088445, 3.9444109650359458],
					[61.400802747945527, 3.9445719761577678],
					[61.400727946748255, 3.9445498348598971],
					[61.400666884090079, 3.9444055217290583],
					[61.400666884090079, 3.9444055217290583],
					[61.400666883126398, 3.9444055200087567],
					[61.400516893042443, 3.9440610422532307],
					[61.400433497214934, 3.9435777651822765],
					[61.400433497145485, 3.9435777633171303],
					[61.400433496112313, 3.943577759731741],
					[61.400433496042851, 3.9435777578665787],
					[61.40039189614118, 3.9433832583131636],
					[61.4003918891176, 3.9433832388108745],
					[61.400391871424148, 3.9433831985207544],
					[61.400391859929577, 3.9433831797429613],
					[61.40034185979605, 3.9433165806331636],
					[61.400341788103091, 3.9433165397115126],
					[61.40034163923967, 3.9433165281826046],
					[61.400341561999774, 3.9433165557101701],
					[61.400272062045168, 3.9433831564652553],
					[61.400272056818871, 3.9433831610649217],
					[61.400272047260515, 3.9433831701193687],
					[61.400272042997933, 3.9433831764392644],
					[61.400158143415098, 3.9435276755565383],
					[61.400158132346185, 3.9435276923612088],
					[61.400158113924135, 3.9435277291212563],
					[61.40015810657097, 3.9435277490766016],
					[61.400066506876094, 3.9438749494367791],
					[61.400066505365551, 3.9438749571870568],
					[61.400066502344444, 3.9438749726876132],
					[61.400066500764417, 3.94387497857275],
					[61.400016501987778, 3.944355568468219],
					[61.399969229795623, 3.9445831343473658],
					[61.399886184867619, 3.9446025752667544],
					[61.39980298841926, 3.9444860192183167],
					[61.399716896752182, 3.9440305669036206],
					[61.399639098039259, 3.9435166700480755],
					[61.399639091909918, 3.9435166504013242],
					[61.399639076899142, 3.9435166096773995],
					[61.399639068087183, 3.9435165904653231],
					[61.399439068213702, 3.9432081910524563],
					[61.399439051284389, 3.9432081712796023],
					[61.399439011305326, 3.9432081364783507],
					[61.399438989149758, 3.9432081213050547],
					[61.399194488791046, 3.9430859201995254],
					[61.399194472348036, 3.9430859134824048],
					[61.399194436024139, 3.9430859043578708],
					[61.399194417037485, 3.9430859018055875],
					[61.399000016559633, 3.9430692006410708],
					[61.399000008442336, 3.9430692000801186],
					[61.398999988700403, 3.9430692014028557],
					[61.398999978794706, 3.9430692011317072],
					[61.398919378951902, 3.9430776010911082],
					[61.3989193735867, 3.9430776019605047],
					[61.398919363750466, 3.9430776035544062],
					[61.398919359279432, 3.9430776042789071],
					[61.398722173565204, 3.9431192009349085],
					[61.398544395275316, 3.9431275997049027],
					[61.398466700400213, 3.9431275994309649],
					[61.398466695035012, 3.9431276003003362],
					[61.398466686092959, 3.9431276017492989],
					[61.398466680658281, 3.9431276007536451],
					[61.398205580845364, 3.9431526001088781],
					[61.398205574655414, 3.9431526029881421],
					[61.398205563030771, 3.9431526048717576],
					[61.398205556771352, 3.9431526058860111],
					[61.397788899228175, 3.9432441945345547],
					[61.397641759403299, 3.9432137072744831],
					[61.397575097748877, 3.9431859237631861],
					[61.397555715870062, 3.9431720374304025],
					[61.397555713048526, 3.9431720341351038],
					[61.397555706650166, 3.9431720314193854],
					[61.39755570382858, 3.9431720281241138],
					[61.397200126587151, 3.9429554433585858],
					[61.396802944661438, 3.9425387628505901],
					[61.396802935302517, 3.9425387531100506],
					[61.396802915829511, 3.9425387375037175],
					[61.396802904751674, 3.9425387299179095],
					[61.396650105233057, 3.9424442293290234],
					[61.396650068770164, 3.9424442164760092],
					[61.39664999275395, 3.9424442044040351],
					[61.396649954094833, 3.9424442050401578],
					[61.39626385412982, 3.942535904101832],
					[61.396263837278909, 3.9425359105847262],
					[61.396263806259753, 3.9425359231157762],
					[61.396263791336231, 3.9425359330386542],
					[61.395655491325542, 3.9429331327161923],
					[61.395655478190356, 3.9429331423488954],
					[61.395655456460553, 3.9429331627547564],
					[61.395655445183287, 3.9429331739625586],
					[61.395249844947521, 3.9434304746921631],
					[61.395249838071756, 3.9434304833104594],
					[61.395249825353368, 3.94343050413186],
					[61.395249819441254, 3.943430514470128],
					[61.394913742300723, 3.944138766032558],
					[61.394561034160638, 3.9443887012901517],
					[61.39445834471482, 3.9443914982319961],
					[61.394447301555935, 3.9443859271920392],
					[61.3942612133769, 3.9442581342626539],
					[61.394261207872759, 3.944258131402107],
					[61.394261196864491, 3.9442581256810523],
					[61.394261192254568, 3.9442581226756657],
					[61.394077892209744, 3.9441637211228113],
					[61.394077865791623, 3.944163712269777],
					[61.394077814127421, 3.9441637018779341],
					[61.394077787092918, 3.944163700628823],
					[61.394033287410075, 3.9441664998582828],
					[61.394033254393911, 3.9441665070821106],
					[61.39403319153049, 3.9441665341486227],
					[61.394033164365766, 3.9441665535567787],
					[61.393916564388931, 3.9442748523741047],
					[61.393916558337835, 3.9442748589822298],
					[61.393916547060442, 3.9442748701888992],
					[61.393916541903543, 3.9442748766522104],
					[61.393699854861808, 3.9445526599774698],
					[61.393330470946914, 3.9448637473419348],
					[61.393330470052696, 3.9448637474867518],
					[61.393330465720553, 3.9448637519402783],
					[61.393330464895797, 3.9448637539498161],
					[61.393160974812716, 3.9450192426077244],
					[61.393116657330062, 3.9450497864445326],
					[61.393063941066742, 3.9450387045854618],
					[61.393063931985786, 3.9450387023041316],
					[61.39306391306858, 3.9450387016156743],
					[61.393063903093434, 3.9450386994791504],
					[61.392716703073191, 3.9450330994317682],
					[61.392716686013834, 3.9450331003183901],
					[61.392716652928179, 3.9450331056762433],
					[61.392716636901937, 3.9450331101474658],
					[61.392324936564123, 3.9451637096996182],
					[61.392324925972517, 3.9451637151665593],
					[61.392324904719864, 3.9451637242357758],
					[61.392324895022476, 3.9451637295579292],
					[61.391841595120191, 3.9454609299460741],
					[61.39184159333179, 3.9454609302356509],
					[61.391841588999604, 3.9454609346888918],
					[61.391841586316986, 3.9454609351232595],
					[61.39151932708333, 3.9456831071532124],
					[61.391297175309617, 3.9457109023435128],
					[61.391297174415406, 3.9457109024882921],
					[61.391297170769157, 3.9457109012028004],
					[61.391297168980728, 3.9457109014923604],
					[61.390905569375036, 3.9457720018439839],
					[61.390905548947124, 3.9457720089029884],
					[61.390905508985526, 3.9457720228761803],
					[61.390905489590686, 3.945772033519575],
					[61.39054988979408, 3.9460054321392848],
					[61.39054986462574, 3.9460054568477196],
					[61.390549824333675, 3.9460055102658624],
					[61.390549809348748, 3.9460055427047021],
					[61.39044700926145, 3.9463443424426523],
					[61.390447006101162, 3.9463443542090348],
					[61.390447000744182, 3.9463443794616029],
					[61.390447000335953, 3.9463443926582729],
					[61.390416500321329, 3.9471416925648919],
					[61.390416500460141, 3.947141696294008],
					[61.39041649984361, 3.9471417038969858],
					[61.390416499982422, 3.9471417076261019],
					[61.390477600170883, 3.948658308812623],
					[61.390477601203905, 3.9486583123970607],
					[61.390477600726008, 3.9486583237291843],
					[61.390477601828415, 3.9486583291781896],
					[61.390627600780697, 3.9496833215618077],
					[61.390666496688695, 3.9501721744512928],
					[61.39065262098422, 3.9502138033771961],
					[61.390577669805836, 3.9503081417564019],
					[61.39038882914803, 3.9504054110707738],
					[61.390108255188466, 3.9504748052501863],
					[61.389938969313384, 3.9505108805606635],
					[61.389714063977209, 3.9502692826880068],
					[61.389541876066488, 3.9499527033686133],
					[61.389541874208696, 3.9499527017933769],
					[61.389541871317931, 3.9499526966337783],
					[61.389541870284951, 3.9499526930493989],
					[61.389464069785433, 3.949827694748699],
					[61.389464068821866, 3.9498276930288201],
					[61.389464068752488, 3.9498276911643124],
					[61.389380769168447, 3.9496970911313807],
					[61.389380762631454, 3.9496970846859263],
					[61.389380753134319, 3.9496970712164154],
					[61.389380746597325, 3.9496970647709602],
					[61.389205747041693, 3.9495081634989524],
					[61.389205723923212, 3.9495081466059485],
					[61.389205675142371, 3.9495081169828961],
					[61.389205647760974, 3.9495081064066713],
					[61.38902784756344, 3.94946370528185],
					[61.389027819565321, 3.9494637023083223],
					[61.389027763021787, 3.949463705828312],
					[61.389027735301198, 3.9494637103127119],
					[61.388730541370968, 3.9495665086416913],
					[61.388619347306374, 3.9495970068020672],
					[61.388619320757456, 3.9495970185994733],
					[61.388619273302488, 3.9495970487841929],
					[61.388619250538582, 3.9495970655963615],
					[61.388494252774578, 3.9497359634451383],
					[61.388205454613804, 3.9500415626134875],
					[61.388205453788949, 3.9500415646225786],
					[61.388205452069897, 3.9500415667762856],
					[61.388205451175672, 3.9500415669209246],
					[61.387999850677083, 3.9502720667199656],
					[61.387999842975979, 3.9502720773437567],
					[61.387999827573815, 3.9502720985913338],
					[61.387999821661147, 3.9502721089258666],
					[61.387852622678729, 3.9505610076800233],
					[61.387777660399344, 3.9507026359918203],
					[61.387655569006014, 3.9507441981524796],
					[61.387336119168637, 3.9507358999339712],
					[61.387233358185007, 3.9507192076490973],
					[61.387183385143395, 3.950697018983353],
					[61.386877887795471, 3.9505470193659504],
					[61.386877879608825, 3.9505470169387467],
					[61.386877864060352, 3.9505470100753874],
					[61.386877855873706, 3.9505470076482019],
					[61.386744456023017, 3.9505081072539663],
					[61.386744444259492, 3.9505081054052407],
					[61.386744420732462, 3.9505081017078232],
					[61.386744408968966, 3.9505080998590945],
					[61.386372209174382, 3.9504914996719989],
					[61.386372196586052, 3.9504914998322449],
					[61.386372172372887, 3.9504915018724649],
					[61.386372159853863, 3.9504915038970445],
					[61.385911060005363, 3.9505859036191948],
					[61.385911043084647, 3.9505859082310311],
					[61.385911010345517, 3.9505859229030116],
					[61.385910993563499, 3.9505859312434519],
					[61.385499894347547, 3.9508442307526215],
					[61.385499888157412, 3.9508442336290921],
					[61.385499877634878, 3.9508442409570925],
					[61.385499874196746, 3.9508442452640486],
					[61.385213779712799, 3.9510776403377896],
					[61.384902686689294, 3.9512915358361651],
					[61.384902683181785, 3.9512915382787295],
					[61.384902675272528, 3.951291543308443],
					[61.384902671765019, 3.9512915457510238],
					[61.384197071728664, 3.9518804468448083],
					[61.384197063063866, 3.9518804557472293],
					[61.384197044840072, 3.9518804736966091],
					[61.384197037138833, 3.9518804843186728],
					[61.38367203668998, 3.9526137849432734],
					[61.38367203325177, 3.9526137892497775],
					[61.383672025619788, 3.9526138017356525],
					[61.383672023145124, 3.9526138077617756],
					[61.383308123398066, 3.9533138088255702],
					[61.383308115904676, 3.9533138250394289],
					[61.383308104633379, 3.9533138606174165],
					[61.383308100786202, 3.9533138781173847],
					[61.383227601168798, 3.9540388783994365],
					[61.383227601238112, 3.9540388802635853],
					[61.383227601376717, 3.9540388839918861],
					[61.383227599657566, 3.9540388861450246],
					[61.383213701864179, 3.9542471579199479],
					[61.38319702494119, 3.9542915978409248],
					[61.383155551181261, 3.9543414451463912],
					[61.383119494902338, 3.9543220231506586],
					[61.383119455827071, 3.9543220125861365],
					[61.383119377336847, 3.9543220065147549],
					[61.383119336958295, 3.954322009288243],
					[61.382861107650506, 3.954408085770305],
					[61.382697304007813, 3.9543387263820442],
					[61.382500142810592, 3.9541804574693384],
					[61.382391857455431, 3.9540442764232475],
					[61.38239184995512, 3.9540442682586301],
					[61.382391835918, 3.9540442536490112],
					[61.382391829381206, 3.9540442472040258],
					[61.382286232353692, 3.953955450853214],
					[61.38208343515884, 3.953769253222458],
					[61.382083419402584, 3.9537692407661664],
					[61.382083386240254, 3.9537692198707712],
					[61.382083367939977, 3.9537692115761365],
					[61.381875100895584, 3.9536942242593902],
					[61.381686228193459, 3.9535359478433914],
					[61.381686226266403, 3.9535359444042717],
					[61.381686221656643, 3.9535359413986333],
					[61.381686219798894, 3.9535359398235728],
					[61.381391820343779, 3.9533165407365285],
					[61.381391804656801, 3.9533165301447646],
					[61.381391776242793, 3.9533165159839365],
					[61.381391759800252, 3.9533165092647695],
					[61.38104726045443, 3.9532081094190263],
					[61.381047219798646, 3.9532081047375498],
					[61.381047140968725, 3.9532081137257831],
					[61.381047102725262, 3.9532081255314924],
					[61.380922102559303, 3.9532776245094126],
					[61.380922085090859, 3.9532776385834976],
					[61.380922052836603, 3.9532776662981886],
					[61.380922038189411, 3.9532776836667987],
					[61.380711004968624, 3.9535691903477166],
					[61.380363960801823, 3.9535387059259661],
					[61.380100099377167, 3.953388725641223],
					[61.380100089332799, 3.9533887216387371],
					[61.380100070138262, 3.9533887134892951],
					[61.380100060093881, 3.9533887094868279],
					[61.380011160292149, 3.9533609094605553],
					[61.380011144882459, 3.9533609063250625],
					[61.380011115026633, 3.9533609017735225],
					[61.380011099686236, 3.9533609005019903],
					[61.379999999574437, 3.9533608995879974],
					[61.379999975430493, 3.9533609034892998],
					[61.379999927281311, 3.9533609150198084],
					[61.379999904239561, 3.9533609243684977],
					[61.379741603997921, 3.9535026253736305],
					[61.379741580339214, 3.9535026423223787],
					[61.379741537908785, 3.9535026866811198],
					[61.379741520856179, 3.953502711938182],
					[61.379458123951679, 3.9540721051401948],
					[61.379166563631102, 3.9545748355409911],
					[61.378902759387167, 3.9546692027210257],
					[61.378697243309745, 3.9546830967122593],
					[61.37845574809775, 3.9545554521505912],
					[61.378219575604781, 3.9541138067409327],
					[61.378219555717202, 3.954113779952928],
					[61.378219507207817, 3.9541137334130889],
					[61.378219477761085, 3.9541137156695654],
					[61.37782787815437, 3.9539470163615911],
					[61.377827822088904, 3.9539470085438215],
					[61.377827715746001, 3.9539470275987227],
					[61.377827665399195, 3.9539470526075768],
					[61.37766926527194, 3.9540915521805919],
					[61.377669248080601, 3.9540915737079678],
					[61.377669219201877, 3.9540916196236098],
					[61.377669208408712, 3.9540916438674181],
					[61.377569236524067, 3.954433142185338],
					[61.377386157368313, 3.954494170563875],
					[61.37719184392283, 3.9542915612524934],
					[61.377191840138046, 3.9542915562389149],
					[61.37719182995508, 3.9542915485088859],
					[61.377191825276064, 3.954291543639743],
					[61.376833440353053, 3.9540054553353698],
					[61.376514079211098, 3.9536305018865598],
					[61.376325191287904, 3.9530277396967373],
					[61.376325190324387, 3.953027737977477],
					[61.376325189291506, 3.9530277343944724],
					[61.376325188327982, 3.9530277326752148],
					[61.376086287866997, 3.9523666321547521],
					[61.376086284907004, 3.9523666251333514],
					[61.376086279950634, 3.9523666128097812],
					[61.376086276096402, 3.9523666059328812],
					[61.375997376912444, 3.9521999063492697],
					[61.375933477707008, 3.9520749081482438],
					[61.375933476743462, 3.9520749064290381],
					[61.375933474885663, 3.9520749048543431],
					[61.375933474746994, 3.9520749011269065],
					[61.375761275394858, 3.9517666024085916],
					[61.375761274431291, 3.9517666006894085],
					[61.375761271679281, 3.9517665992592508],
					[61.375761271609946, 3.95176659739555],
					[61.375547372421856, 3.9514081982718983],
					[61.375547345172038, 3.9514081670529682],
					[61.375547284142748, 3.9514081225444384],
					[61.37554724933036, 3.9514081056720149],
					[61.375147249027798, 3.9513081064578812],
					[61.375147196885806, 3.9513081073855076],
					[61.375147100726402, 3.9513081341758975],
					[61.375147056709004, 3.9513081600386251],
					[61.374619256901738, 3.9518498601688514],
					[61.37461925174442, 3.9518498666267696],
					[61.374619242254688, 3.9518498775344812],
					[61.37461923709742, 3.9518498839924092],
					[61.373863741014034, 3.9529109791253618],
					[61.373383144970681, 3.9535026743338997],
					[61.373383140638225, 3.9535026787832819],
					[61.373383133006122, 3.9535026912647133],
					[61.37338312867363, 3.9535026957140831],
					[61.373144233302874, 3.9538970882094784],
					[61.372858143337702, 3.9542915753065682],
					[61.372522069627706, 3.9546831448657604],
					[61.372363847241921, 3.9547748001673519],
					[61.3720583018642, 3.9547719992813914],
					[61.372058276825982, 3.9547720033247225],
					[61.372058228676529, 3.9547720148495573],
					[61.372058204740441, 3.9547720243389581],
					[61.371866605385108, 3.954874824600255],
					[61.371866587777838, 3.9548748349421703],
					[61.37186655731157, 3.9548748623579355],
					[61.371866543419763, 3.9548748758492378],
					[61.371549843191538, 3.9552776763592155],
					[61.371549840578162, 3.9552776786558494],
					[61.371549836314898, 3.955277684968161],
					[61.371549833701515, 3.9552776872647826],
					[61.371283147195875, 3.9556720708139355],
					[61.371094288370536, 3.9558498305996577],
					[61.371019318821318, 3.9558831172033408],
					[61.371019317032875, 3.9558831174920739],
					[61.371019311736809, 3.9558831202217033],
					[61.371019309948366, 3.9558831205104363],
					[61.370986020544898, 3.9558998156606262],
					[61.370666633265429, 3.9560137118830556],
					[61.370666625286681, 3.9560137150457155],
					[61.370666610223395, 3.9560137212266695],
					[61.370666603138901, 3.9560137242449795],
					[61.370333214283818, 3.9561998186258571],
					[61.369824939325405, 3.9564026085601154],
					[61.369594352922306, 3.9564581059169823],
					[61.369594349345419, 3.9564581064943498],
					[61.36959434047241, 3.9564581098011162],
					[61.369594336001327, 3.9564581105228207],
					[61.369258271546258, 3.9565719971530604],
					[61.368852889451141, 3.9565526045860553],
					[61.368544551131542, 3.9562331697466964],
					[61.368280765230878, 3.9558942864829851],
					[61.368119577184295, 3.9556277057036637],
					[61.368027980799333, 3.9554332151544407],
					[61.368027978872313, 3.9554332117166506],
					[61.368027974948987, 3.9554332029778383],
					[61.36802797302196, 3.9554331995400642],
					[61.367927973627488, 3.9552610003487301],
					[61.367772374238129, 3.9549860012747655],
					[61.367772354420104, 3.9549859763580599],
					[61.36777230604968, 3.954985933558095],
					[61.367772279285731, 3.9549859153860898],
					[61.367508379025907, 3.9548720164086717],
					[61.367508309616404, 3.9548720126197701],
					[61.36750818601228, 3.9548720513200508],
					[61.367508130854141, 3.9548720920903855],
					[61.367160950596208, 3.9554220619411842],
					[61.367024997072214, 3.9555219532412855],
					[61.366925114298638, 3.9554526357908757],
					[61.366925110583182, 3.9554526326419279],
					[61.366925103221561, 3.9554526282071936],
					[61.366925099575347, 3.9554526269214354],
					[61.366452904043577, 3.9551804287159409],
					[61.366147308078546, 3.9549831311014048],
					[61.366147308078546, 3.9549831311014048],
					[61.366147306220817, 3.9549831295269953],
					[61.366147305326578, 3.9549831296713607],
					[61.366111227949744, 3.9549609441141742],
					[61.366036262816102, 3.9548804814003207],
					[61.366008485866907, 3.9548305243730173],
					[61.36594459274324, 3.9545999471678868],
					[61.365944592673941, 3.9545999453047633],
					[61.365944591710409, 3.9545999435860155],
					[61.365852992335306, 3.95428884453528],
					[61.365852988273346, 3.9542888320709721],
					[61.365852979393772, 3.9542888110129586],
					[61.365852973681953, 3.9542888025636467],
					[61.365711285375646, 3.9540360205671887],
					[61.365611302295221, 3.9537055775057994],
					[61.365636298310491, 3.9533889273188323],
					[61.365744596300331, 3.9528444386444903],
					[61.36574459698663, 3.9528444329107311],
					[61.365744599253361, 3.952844421298817],
					[61.365744599114727, 3.9528444175726056],
					[61.36577519857164, 3.9524778169633197],
					[61.365775200221435, 3.9524778129482439],
					[61.365775200013466, 3.9524778073589082],
					[61.365775199874832, 3.9524778036326911],
					[61.365789099561198, 3.9516472025946081],
					[61.365789100247397, 3.9516471968608231],
					[61.365789098937199, 3.9516471858266282],
					[61.365789098659867, 3.95164717837421],
					[61.365700198806337, 3.9508110798955389],
					[61.365700197634709, 3.9508110725876189],
					[61.365700196324454, 3.9508110615534942],
					[61.365700195222182, 3.9508110561086851],
					[61.365522395326998, 3.9500138566617329],
					[61.365522391195299, 3.9500138423350442],
					[61.365522380249203, 3.9500138141151595],
					[61.365522373434843, 3.9500138002219622],
					[61.365289073059245, 3.9496109990976396],
					[61.36528906920492, 3.9496109922233917],
					[61.365289059777226, 3.9496109806270074],
					[61.365289055028732, 3.9496109738972667],
					[61.365239054874607, 3.9495498727513914],
					[61.365239052053255, 3.9495498694587785],
					[61.365239047374068, 3.9495498645921474],
					[61.365239044552702, 3.9495498612995528],
					[61.365069568374224, 3.949372086851219],
					[61.364847390345076, 3.9488888358042988],
					[61.364652995099362, 3.9480388556773001],
					[61.364652995029992, 3.9480388538142552],
					[61.364652994066418, 3.948038852095777],
					[61.364472393633434, 3.9472916537733269],
					[61.364472394388841, 3.9472916499027293],
					[61.364472392322845, 3.9472916427398004],
					[61.364472391289873, 3.9472916391583417],
					[61.364375191081841, 3.9469832395817557],
					[61.36437519011821, 3.9469832378633329],
					[61.364375189085244, 3.9469832342818765],
					[61.364375188121599, 3.9469832325634537],
					[61.364116887864945, 3.9462666333808616],
					[61.36402798909657, 3.9460138343903255],
					[61.363864090443585, 3.945519336996298],
					[61.363864089410519, 3.9455193334149761],
					[61.363864087413852, 3.9455193281152781],
					[61.363864086450249, 3.9455193263969215],
					[61.36373349366589, 3.9451888479502824],
					[61.363672398780203, 3.944797179973516],
					[61.36363910027697, 3.9440305905823845],
					[61.363639100207536, 3.9440305887194276],
					[61.363639099174478, 3.9440305851381758],
					[61.363639099035602, 3.9440305814122483],
					[61.363577998741555, 3.9433388822667372],
					[61.363577999427434, 3.943338876533188],
					[61.363577997222343, 3.9433388656448343],
					[61.363577996119787, 3.9433388602006691],
					[61.363533495935101, 3.9431166616592432],
					[61.363533437438477, 3.9431165886600255],
					[61.363533283878077, 3.9431165235457586],
					[61.363533189777947, 3.943116533148987],
					[61.363427782929847, 3.9431858723362452],
					[61.363108457229416, 3.9430387523379871],
					[61.362839093602275, 3.9423388474229557],
					[61.362666898325962, 3.9413277738251109],
					[61.362552999380455, 3.9401416803331348],
					[61.362552999380455, 3.9401416803331348],
					[61.362519599170177, 3.9398110803386968],
					[61.362519596825827, 3.9398110657252001],
					[61.362519590418181, 3.9398110386506522],
					[61.362519585391254, 3.9398110244714921],
					[61.362489087176861, 3.9397360306286013],
					[61.362339098229803, 3.9393027592960372],
					[61.362294599600354, 3.9385750006752396],
					[61.36235299930641, 3.9377806154321133],
					[61.362353000061553, 3.9377806115615646],
					[61.362352999922514, 3.9377806078358395],
					[61.362352999783468, 3.9377806041101042],
					[61.362364099920619, 3.9373028047098453],
					[61.362364099781566, 3.9373028009841247],
					[61.362364100397677, 3.937302793387818],
					[61.36236410025861, 3.9373027896621013],
					[61.362336299754226, 3.9367860914331088],
					[61.362333499645317, 3.9366971942891409],
					[61.362333500400474, 3.9366971904185619],
					[61.362333499228107, 3.9366971831119857],
					[61.362333499089026, 3.9366971793862722],
					[61.362311299700337, 3.936472188903402],
					[61.362302999610137, 3.9358889157430696],
					[61.362375197143194, 3.9354778352293978],
					[61.362375197073632, 3.9354778333665426],
					[61.362466896566104, 3.9349389341648715],
					[61.362466899447291, 3.9349389149565899],
					[61.362466898056084, 3.9349388776993526],
					[61.362466896396732, 3.9349388573527775],
					[61.3623779957042, 3.9345249582882031],
					[61.362377994740442, 3.9345249565702742],
					[61.362377994740442, 3.9345249565702742],
					[61.362377994670879, 3.9345249547074039],
					[61.362225194884729, 3.9338666542275309],
					[61.362225194884729, 3.9338666542275309],
					[61.362016895271736, 3.932986054197797],
					[61.362016894307978, 3.9329860524799329],
					[61.362016894238387, 3.9329860506171062],
					[61.3620168932746, 3.9329860488992465],
					[61.361800192854894, 3.9321638490426345],
					[61.361800186793779, 3.9321638312844294],
					[61.361800172058537, 3.9321637980657647],
					[61.361800163454014, 3.9321637844681514],
					[61.361544562666204, 3.9318054842454426],
					[61.361544555095065, 3.9318054742286277],
					[61.361544537270149, 3.9318054546300001],
					[61.361544527086032, 3.9318054469109729],
					[61.361355726992805, 3.9316498460076241],
					[61.361355726028997, 3.9316498442898431],
					[61.361355723276851, 3.9316498428620559],
					[61.361355722313029, 3.9316498411442771],
					[61.361319522306616, 3.9316220416904222],
					[61.361319515838503, 3.9316220371170614],
					[61.361319504760161, 3.9316220295431532],
					[61.361319499255799, 3.9316220266875948],
					[61.361133438132157, 3.9315165477996605],
					[61.360922375942792, 3.9312166026604665],
					[61.360866885079901, 3.9310860245298258],
					[61.360836286969601, 3.9310082308190419],
					[61.36069459136143, 3.9306082422914264],
					[61.360558493755761, 3.9300805506035759],
					[61.360558487763996, 3.9300805347091989],
					[61.360558473922445, 3.9300805013478102],
					[61.360558465317723, 3.9300804877512872],
					[61.360455765260937, 3.9299304872834306],
					[61.360455740003772, 3.9299304613959691],
					[61.360455683647892, 3.929930421812716],
					[61.360455651585355, 3.9299304063992495],
					[61.360413951632296, 3.9299192079467473],
					[61.360413946127913, 3.9299192050915632],
					[61.360413935258364, 3.929919203106635],
					[61.360413928929404, 3.9299192022592231],
					[61.360169472422434, 3.9298831091461741],
					[61.360011231896081, 3.9297804473400539],
					[61.359991850906098, 3.9297581684971798],
					[61.359991829573801, 3.9297581513435427],
					[61.359991784365917, 3.9297581211967989],
					[61.359991759596177, 3.9297581083487327],
					[61.359813960239507, 3.9297026084172373],
					[61.35981394937, 3.9297026064324201],
					[61.359813927630931, 3.9297026024627817],
					[61.359813916761389, 3.9297026004779725],
					[61.359586162001669, 3.929683104102546],
					[61.359505742705906, 3.9296359514266026],
					[61.359164077336054, 3.9290999045050041],
					[61.358833482726524, 3.9283582194366731],
					[61.358833479765366, 3.9283582124214154],
					[61.358833473018542, 3.9283582003985593],
					[61.358833470127024, 3.9283581952459263],
					[61.358683470211609, 3.9281165949553341],
					[61.358683461676371, 3.9281165832227791],
					[61.358683445639343, 3.9281165633378214],
					[61.358683437173788, 3.9281165534678766],
					[61.358300141848915, 3.9277581589179738],
					[61.358183458581969, 3.9276304771741462],
					[61.357961267895021, 3.9272831919146429],
					[61.357961266931149, 3.9272831901971639],
					[61.357961264109257, 3.9272831869073412],
					[61.357961263075765, 3.9272831833273028],
					[61.357891871838184, 3.9271859971609033],
					[61.357789080119758, 3.9269749131811462],
					[61.357764081399758, 3.926922113246976],
					[61.357764073828285, 3.9269221032325272],
					[61.357764060334333, 3.9269220791882771],
					[61.357764051868649, 3.9269220693189451],
					[61.357591860147835, 3.9267220795238207],
					[61.357319567949688, 3.9263026911438637],
					[61.357319563200051, 3.9263026844193942],
					[61.357319553770495, 3.9263026728329464],
					[61.35731954998473, 3.9263026678258757],
					[61.356952949926225, 3.9258887676735443],
					[61.35695294903207, 3.925888767818678],
					[61.356952948068184, 3.9258887661013127],
					[61.356952947998487, 3.9258887642388092],
					[61.356836254921404, 3.9257609724632521],
					[61.356464066997063, 3.9252554882708406],
					[61.356058470732243, 3.9245831970690146],
					[61.355914072137523, 3.9243415968501312],
					[61.355914071173629, 3.9243415951328706],
					[61.355914070209728, 3.9243415934155941],
					[61.35591406931556, 3.9243415935607682],
					[61.355614071602361, 3.9238665964257216],
					[61.355555773939322, 3.9237638009054363],
					[61.355555768225678, 3.9237637924644844],
					[61.355555753976446, 3.9237637722933134],
					[61.355555747368612, 3.9237637639975524],
					[61.355458458319418, 3.9236581768807812],
					[61.355319570024733, 3.9234498934515978],
					[61.355152974879566, 3.9231610031515829],
					[61.35479189343318, 3.9224832370929743],
					[61.35469460305243, 3.9217972063504138],
					[61.354750184385587, 3.9215222974365544],
					[61.354989043247762, 3.92127794003193],
					[61.354989045860577, 3.9212779377337763],
					[61.354989051016396, 3.9212779312750876],
					[61.354989052735014, 3.9212779291221942],
					[61.35517790780613, 3.9210529823869726],
					[61.355402839421558, 3.9210084956883535],
					[61.355402850081937, 3.9210084920827368],
					[61.3554028732608, 3.9210084864433647],
					[61.355402883851461, 3.9210084809753432],
					[61.355563915652901, 3.9209335123036126],
					[61.355611043787675, 3.9209473925171157],
					[61.355611049222482, 3.9209473935079009],
					[61.355611060092123, 3.9209473954894651],
					[61.355611066421126, 3.920947396334995],
					[61.355886066091053, 3.9209945965307829],
					[61.355886076066504, 3.9209945986576691],
					[61.355886094983759, 3.9209945993318156],
					[61.355886104889457, 3.9209945995962596],
					[61.356097204826604, 3.9209890993892689],
					[61.356097219203207, 3.9209890989273384],
					[61.356097247816898, 3.920989094278601],
					[61.35609726115981, 3.9209890902370654],
					[61.356347261268468, 3.9209084905372342],
					[61.356347263951022, 3.9209084901013989],
					[61.356347269246328, 3.9209084873672859],
					[61.356347271034679, 3.9209084870767335],
					[61.356500062339016, 3.9208501894150318],
					[61.356633351930292, 3.9208140938309004],
					[61.356633363415121, 3.9208140882172962],
					[61.356633384735893, 3.9208140810056014],
					[61.356633396220673, 3.9208140753919873],
					[61.356883395903743, 3.9206779757490033],
					[61.356883402917653, 3.9206779708617394],
					[61.356883416945507, 3.920677961087188],
					[61.356883423959388, 3.9206779561999263],
					[61.356950124313265, 3.9206251561682359],
					[61.356950125207419, 3.9206251560229499],
					[61.35699182498022, 3.9205918564552897],
					[61.356991836255759, 3.9205918452540449],
					[61.356991857842871, 3.9205918211343538],
					[61.356991867260312, 3.9205918083611921],
					[61.357186266722209, 3.9202945086979128],
					[61.357186277648928, 3.9202944881839539],
					[61.357186293103616, 3.9202944444481296],
					[61.357186298595529, 3.9202944229434618],
					[61.357241899013097, 3.9198083221099713],
					[61.357241899013097, 3.9198083221099713],
					[61.357241898943343, 3.9198083202474669],
					[61.357283499086833, 3.9194194202113573],
					[61.357283499086833, 3.9194194202113573],
					[61.357283499017065, 3.9194194183488342],
					[61.357283498947311, 3.9194194164863267],
					[61.357327999420306, 3.9189028168088487],
					[61.357327999350524, 3.918902814946323],
					[61.357328000105127, 3.9189028110759718],
					[61.357327999965577, 3.9189028073509551],
					[61.357341899295164, 3.9185694251381369],
					[61.357430790906783, 3.9181444655066286],
					[61.357602978853087, 3.9177972896146755],
					[61.357602981395992, 3.9177972854535028],
					[61.357602984693543, 3.9177972774219239],
					[61.3576029863423, 3.9177972734061184],
					[61.357830785904241, 3.9172167738038093],
					[61.357830790710871, 3.917216758031171],
					[61.357830797641633, 3.9172167269220757],
					[61.357830799695968, 3.9172167097230912],
					[61.357844599571415, 3.916936109468252],
					[61.357844594598653, 3.9169360727991274],
					[61.357844571310338, 3.9169360035044187],
					[61.35784455299482, 3.9169359708788307],
					[61.357611252971239, 3.9166581706397867],
					[61.357611241752544, 3.9166581593470098],
					[61.357611217596563, 3.9166581389147956],
					[61.357611204659335, 3.9166581297753469],
					[61.357416820666401, 3.9165387398188423],
					[61.357355754135533, 3.9164831695892377],
					[61.357108468148759, 3.9161026916902646],
					[61.357108459682337, 3.9161026818240776],
					[61.357108442679667, 3.9161026602292552],
					[61.357108435177231, 3.9161026520801521],
					[61.356955734567364, 3.9159637517802577],
					[61.356955723418444, 3.9159637423504363],
					[61.356955696719503, 3.9159637260803808],
					[61.356955683852007, 3.9159637188038841],
					[61.35658338385258, 3.9157915180998533],
					[61.356583381994426, 3.9157915165282464],
					[61.356583377453752, 3.9157915153929133],
					[61.356583373807268, 3.9157915141121542],
					[61.356500099954289, 3.9157581241332715],
					[61.356344522171632, 3.9156387412685665],
					[61.356344516666979, 3.9156387384162463],
					[61.356344508340165, 3.9156387322753168],
					[61.356344503729666, 3.9156387292775747],
					[61.356097303860118, 3.9154887292727754],
					[61.356097300143766, 3.9154887261296341],
					[61.356097291886769, 3.9154887218512293],
					[61.356097288240257, 3.9154887205705067],
					[61.355988987768008, 3.9154359200668289],
					[61.355988984121502, 3.9154359187861343],
					[61.35598897772266, 3.9154359160792978],
					[61.355988974970309, 3.9154359146531745],
					[61.355569497893086, 3.9152665244703089],
					[61.355114037443528, 3.9149304529056725],
					[61.354505752256244, 3.9142220700847132],
					[61.354358453345213, 3.9140470707839805],
					[61.354358444948488, 3.9140470627815227],
					[61.354358428155045, 3.9140470467766084],
					[61.354358418864173, 3.9140470389196085],
					[61.354294518956344, 3.9139998389514497],
					[61.354294506088813, 3.9139998316763234],
					[61.354294477601321, 3.913999815700155],
					[61.354294463015243, 3.9139998105782889],
					[61.354244462692442, 3.9139831108296383],
					[61.354244458151761, 3.9139831096946351],
					[61.354244447212224, 3.9139831058532262],
					[61.354244441777375, 3.9139831048636857],
					[61.354000041957256, 3.9139304035208511],
					[61.354000038380555, 3.9139304041027034],
					[61.354000028405011, 3.9139304019781922],
					[61.354000023864366, 3.9139304008432014],
					[61.353836124207149, 3.9139109005936681],
					[61.353836108042287, 3.9139109013496873],
					[61.353836078395041, 3.9139109024253207],
					[61.353836063194187, 3.9139109048981631],
					[61.35377776282175, 3.9139220041379965],
					[61.353777755668389, 3.9139220053016857],
					[61.353777744044201, 3.9139220071926735],
					[61.353777736960716, 3.9139220102186405],
					[61.353508236768675, 3.9140109102271343],
					[61.353508233261856, 3.914010912671225],
					[61.353508224320173, 3.9140109141257873],
					[61.353508219025024, 3.9140109168608084],
					[61.353438819073595, 3.9140415177557615],
					[61.353438809307576, 3.9140415212180315],
					[61.35343878998507, 3.9140415337293764],
					[61.35343878118308, 3.9140415389084517],
					[61.353374880605642, 3.9140887390937555],
					[61.35337487799297, 3.9140887413923759],
					[61.353374871049155, 3.9140887481427957],
					[61.353374866648132, 3.9140887507323243],
					[61.353349867398265, 3.9141109502362017],
					[61.353349858735911, 3.9141109591397765],
					[61.353349843199624, 3.9141109766559969],
					[61.353349836325606, 3.9141109852686684],
					[61.353144236470072, 3.9144081867920852],
					[61.353144227947404, 3.9144081994200048],
					[61.353144213724242, 3.9144082279640129],
					[61.353144208848057, 3.9144082418724295],
					[61.353002619183592, 3.9148721077906043],
					[61.352883192817728, 3.9150276103327131],
					[61.352572195165045, 3.9150636987828018],
					[61.352489035850901, 3.9150581089817802],
					[61.352383486378663, 3.9148082264756665],
					[61.352133488155616, 3.9141332316348678],
					[61.352133486157783, 3.9141332263392012],
					[61.352133484159907, 3.9141332210435196],
					[61.352133483126103, 3.9141332174645878],
					[61.352027982729055, 3.9138971182154925],
					[61.352027976945003, 3.9138971079151101],
					[61.352027964412876, 3.9138970855976174],
					[61.352027955946305, 3.91389707573359],
					[61.352005755925973, 3.9138692751331221],
					[61.352005754961944, 3.913869273416382],
					[61.352005752139775, 3.9138692701283802],
					[61.352005750281563, 3.9138692685571108],
					[61.35180016671233, 3.9136359887460563],
					[61.351650178807539, 3.9133332110005954],
					[61.3516001790275, 3.9132332112199224],
					[61.351600165670973, 3.9132331909106162],
					[61.351600134626736, 3.9132331547436445],
					[61.351600116939025, 3.9132331388859809],
					[61.351550117371879, 3.9131970374707064],
					[61.351550112761323, 3.913197034473733],
					[61.351550106292606, 3.9131970299055645],
					[61.351550101751933, 3.9131970287707545],
					[61.351311227534673, 3.9130554435598648],
					[61.351286249204968, 3.9130276652463825],
					[61.351286213214763, 3.9130276411256069],
					[61.351286132781702, 3.9130276073736892],
					[61.35128608930286, 3.9130275994592334],
					[61.351186089163285, 3.9130330994790232],
					[61.351186036826533, 3.913033119234544],
					[61.351185949156132, 3.9130331803348075],
					[61.351185914786498, 3.9130332233962073],
					[61.351091514809312, 3.9132638241653601],
					[61.351091512406242, 3.913263832050244],
					[61.351091506705849, 3.9132638479654913],
					[61.351091505127066, 3.9132638538427802],
					[61.350949809890416, 3.9138666337512626],
					[61.350802632223576, 3.9141776881621171],
					[61.350685973862838, 3.9143081408436995],
					[61.350649968076354, 3.9143275907686728],
					[61.350497238959058, 3.9142970049187675],
					[61.350497225337001, 3.9142970015139591],
					[61.350497197408245, 3.9142970004360125],
					[61.350497182137545, 3.9142970010462363],
					[61.350277787231811, 3.9143164991420476],
					[61.350208314317534, 3.9143191991814641],
					[61.349997266202415, 3.9142804098567794],
					[61.349864005337423, 3.9142109286917828],
					[61.349755716676079, 3.9141331376557038],
					[61.349755715781932, 3.9141331378011306],
					[61.349755712959741, 3.9141331345133179],
					[61.349755712065594, 3.9141331346587451],
					[61.349411212327084, 3.9138998332354302],
					[61.349411210538747, 3.9138998335263011],
					[61.349411208680564, 3.9138998319551472],
					[61.34941120682236, 3.9138998303839805],
					[61.349289013153133, 3.9138220344446335],
					[61.348944559895912, 3.9135692697515547],
					[61.348672398261691, 3.9129777519345619],
					[61.348647400375988, 3.9124527980617816],
					[61.34865300008429, 3.9122278053413271],
					[61.348652999665106, 3.9122277941694739],
					[61.348652997932618, 3.9122277719712391],
					[61.348652995725111, 3.9122277610903238],
					[61.3486112963963, 3.9120193604743534],
					[61.348611289298752, 3.9120193391490665],
					[61.348611270702676, 3.9120192990878695],
					[61.348611260098323, 3.912019280206489],
					[61.348569559918843, 3.9119637802169893],
					[61.348569534728377, 3.9119637562155267],
					[61.348569480295779, 3.9119637201118689],
					[61.348569449195487, 3.9119637064386534],
					[61.348458348631141, 3.9119359069499295],
					[61.348458336797393, 3.9119359032553551],
					[61.34845831244531, 3.9119359015975577],
					[61.348458299787225, 3.9119358999104348],
					[61.348411100079375, 3.911935900748674],
					[61.348411085702857, 3.9119359012144748],
					[61.348411059772026, 3.9119359054335394],
					[61.348411046359537, 3.9119359076158133],
					[61.348141645601316, 3.9120109062165267],
					[61.348141640306139, 3.9120109089513799],
					[61.348141627033392, 3.9120109148574769],
					[61.348141620774214, 3.9120109158758485],
					[61.3479221296353, 3.9121054128660089],
					[61.347613839715073, 3.9122026097229714],
					[61.347613808838418, 3.9122026259859952],
					[61.347613753763433, 3.9122026686652309],
					[61.347613730389412, 3.9122026930740472],
					[61.34759987531335, 3.91222482453052],
					[61.347502758168574, 3.9122470048143878],
					[61.347363860942622, 3.9122748037306549],
					[61.347363857365963, 3.9122748043125277],
					[61.347363849388316, 3.9122748074836458],
					[61.34736384491751, 3.9122748082109862],
					[61.347158245122927, 3.9123331087360755],
					[61.347158237969609, 3.9123331098998015],
					[61.347158222838615, 3.9123331142346127],
					[61.347158215755151, 3.9123331172602227],
					[61.347049915672365, 3.9123831186156797],
					[61.347049910377237, 3.912383121350357],
					[61.347049896280126, 3.9123831292634166],
					[61.347049890090808, 3.9123831321435429],
					[61.346741589625957, 3.9125859336309738],
					[61.346558190972388, 3.9127054325669919],
					[61.346558182240265, 3.9127054396070613],
					[61.34655816477607, 3.9127054536872086],
					[61.346558156938116, 3.9127054605818472],
					[61.346374857317535, 3.9128942617591518],
					[61.346374847761091, 3.9128942708064263],
					[61.346374834083051, 3.9128942898901276],
					[61.346374827278979, 3.9128943003629022],
					[61.346199827331219, 3.9131971005572721],
					[61.346199823069902, 3.9131971068699638],
					[61.346199816405509, 3.9131971210662746],
					[61.346199813038382, 3.9131971272335058],
					[61.345955412984921, 3.9138305279641816],
					[61.345955409002933, 3.9138305417240056],
					[61.34595540275749, 3.9138305670910087],
					[61.345955401458035, 3.9138305804145803],
					[61.345888701425125, 3.9144971807454323],
					[61.345888701229228, 3.9144971995090017],
					[61.345888705378187, 3.9144972381709993],
					[61.345888707864795, 3.9144972564983975],
					[61.345963661308247, 3.9147498965920717],
					[61.34579170574559, 3.9148358735703632],
					[61.345602924289217, 3.9147276417028318],
					[61.345314064741281, 3.9144276827209676],
					[61.345027981395532, 3.9138666139408977],
					[61.344961290999734, 3.9137138365542254],
					[61.344889096275331, 3.913405561109677],
					[61.344827998430304, 3.9130388692415377],
					[61.344791897977935, 3.9127916705382795],
					[61.344791897013899, 3.9127916688219733],
					[61.344791895840352, 3.9127916615204121],
					[61.344791894806455, 3.9127916579423636],
					[61.344697398619161, 3.9123610754282319],
					[61.344680801051467, 3.9118639111391262],
					[61.344772397666254, 3.9112750307801156],
					[61.344772399105217, 3.911275021180435],
					[61.344772400264688, 3.9112750041337967],
					[61.344772399985196, 3.9112749966868261],
					[61.34476690035229, 3.9109305965454837],
					[61.344766899038873, 3.9109305855205236],
					[61.34476689737604, 3.9109305651868622],
					[61.344766895238322, 3.9109305561691357],
					[61.344669595221575, 3.9105027559818555],
					[61.344669591155707, 3.9105027435317181],
					[61.344669583093925, 3.9105027204931999],
					[61.344669578133903, 3.9105027081885799],
					[61.344494577558272, 3.9101610088052725],
					[61.34449457370205, 3.9101610019404411],
					[61.344494564131402, 3.9101609866400473],
					[61.344494560275209, 3.9101609797751968],
					[61.344416859771108, 3.9100581782040247],
					[61.344416851374227, 3.9100581702050534],
					[61.3444168336163, 3.9100581524909304],
					[61.344416825219383, 3.9100581444919684],
					[61.344361224890626, 3.9100137441740319],
					[61.344044527087092, 3.9097609444012584],
					[61.343958434814333, 3.9096887513749849],
					[61.343750139629726, 3.9094831582157687],
					[61.34375013955983, 3.9094831563540766],
					[61.343750138665648, 3.9094831564996051],
					[61.343514057220176, 3.9092554725007349],
					[61.343375169875344, 3.909033195433044],
					[61.343136279617127, 3.9086388114903388],
					[61.342941886393533, 3.9081499264857902],
					[61.342941885429425, 3.9081499247697322],
					[61.342941884395458, 3.9081499211920279],
					[61.342941884325548, 3.9081499193304072],
					[61.342761283824103, 3.9077332200243542],
					[61.342761272255125, 3.90773319943185],
					[61.342761243961995, 3.9077331647050868],
					[61.342761228886275, 3.9077331465564753],
					[61.342327928850068, 3.9073692461678102],
					[61.342327924239413, 3.9073692431725187],
					[61.342327914054025, 3.9073692354659655],
					[61.342327907655033, 3.9073692327618348],
					[61.342147307616649, 3.9072526313632299],
					[61.34214730486422, 3.9072526299384029],
					[61.34214729660701, 3.9072526256639089],
					[61.342147293784699, 3.9072526223774888],
					[61.341822293704041, 3.9070804233899126],
					[61.34182228544681, 3.9070804191155086],
					[61.341822269002286, 3.9070804124282374],
					[61.3418222598509, 3.9070804082993957],
					[61.341716759778187, 3.9070470086113156],
					[61.34171674705015, 3.9070470050648365],
					[61.341716719875706, 3.907047000124606],
					[61.341716707287517, 3.9070470003012394],
					[61.341466714197644, 3.9070386998384357],
					[61.341311133029023, 3.9070220025324436],
					[61.341238959471227, 3.907005408840583],
					[61.34092227318439, 3.9068831148640437],
					[61.340839037209726, 3.9068498393185189],
					[61.340661286200273, 3.9065499205102951],
					[61.340466893272229, 3.905833248198785],
					[61.340466893202283, 3.9058332463373175],
					[61.340466893202283, 3.9058332463373175],
					[61.340466892308115, 3.9058332464829313],
					[61.340358492382464, 3.9054499468535844],
					[61.340358490314365, 3.9054499396989639],
					[61.34035848528395, 3.9054499255353528],
					[61.340358482321669, 3.9054499185263731],
					[61.340261281877751, 3.9052332171832087],
					[61.340261280913623, 3.9052332154673848],
					[61.340261278021281, 3.9052332103198868],
					[61.340261276987235, 3.905233206742603],
					[61.340116882466361, 3.9049582167519197],
					[61.339983498419073, 3.9046249548643628],
					[61.339980799744986, 3.9045832874908886],
					[61.339980798291116, 3.9045832727450134],
					[61.339980791876769, 3.9045832456973004],
					[61.339980787810418, 3.9045832332497876],
					[61.339883492025457, 3.9043110437512745],
					[61.339691894765643, 3.9035027548595211],
					[61.339691893801486, 3.9035027531437829],
					[61.339691893731512, 3.9035027512823657],
					[61.339691893661545, 3.9035027494209507],
					[61.33963349385003, 3.9032777497192002],
					[61.339633491851792, 3.9032777444263029],
					[61.339633488749435, 3.9032777336948556],
					[61.339633486751197, 3.9032777284019624],
					[61.339541886590197, 3.9030360284257095],
					[61.339541884591938, 3.9030360231328487],
					[61.339541879701301, 3.9030360126928185],
					[61.33954187680888, 3.9030360075456603],
					[61.33948347712758, 3.9029249063361364],
					[61.339483472306867, 3.9029248977575546],
					[61.339483460947143, 3.9029248827531813],
					[61.339483455232298, 3.9029248743202838],
					[61.339186254739921, 3.9025581733123431],
					[61.339186253775772, 3.9025581715966586],
					[61.339186250059178, 3.9025581684566628],
					[61.339186248200896, 3.9025581668866725],
					[61.339077948204483, 3.9024387651569024],
					[61.339077937948844, 3.902438755591322],
					[61.339077916613391, 3.9024387384672394],
					[61.339077906427747, 3.9024387307630386],
					[61.339033406089044, 3.9024109290860252],
					[61.339033403406603, 3.9024109295231248],
					[61.339033397901659, 3.9024109266745812],
					[61.339033395973374, 3.902410923243234],
					[61.338789035396417, 3.9022776465607327],
					[61.338639079910926, 3.9020721088429484],
					[61.338422393554403, 3.9014138492882933],
					[61.338275195654965, 3.9006499628910691],
					[61.338275194550775, 3.9006499574528246],
					[61.338275194270722, 3.9006499500075136],
					[61.338275192342401, 3.9006499465763573],
					[61.338208491707022, 3.9004138461435685],
					[61.338208479856952, 3.9004138181114381],
					[61.338208446461103, 3.900413767373113],
					[61.338208425809533, 3.9004137445211415],
					[61.338150126280652, 3.9003665450988616],
					[61.338150116094901, 3.9003665373955498],
					[61.338150096757573, 3.900366525565806],
					[61.338150085747678, 3.9003665198695847],
					[61.337952886374822, 3.9002720199570593],
					[61.337952885410679, 3.9002720182414898],
					[61.337952882728203, 3.9002720186787596],
					[61.337952881834035, 3.9002720188245141],
					[61.337775082398444, 3.9001915172794694],
					[61.337775069670286, 3.90019151373615],
					[61.337775044143882, 3.9001915047882503],
					[61.337775031415745, 3.9001915012449295],
					[61.337722230776393, 3.9001831022178779],
					[61.337722207388424, 3.9001831022849438],
					[61.337722158070072, 3.9001831065789037],
					[61.337722133927969, 3.9001831105143063],
					[61.33744993387414, 3.9002776114243196],
					[61.337449915306998, 3.9002776200689326],
					[61.337449878382806, 3.9002776429419632],
					[61.337449860849851, 3.9002776551633751],
					[61.337177661058732, 3.9005415562774419],
					[61.337177639685109, 3.9005415859786399],
					[61.337177609666135, 3.9005416489244507],
					[61.337177601020713, 3.9005416821690471],
					[61.337135903417561, 3.9010360564145024],
					[61.337016533821163, 3.9013609722081597],
					[61.336894288873303, 3.9014470343431271],
					[61.336833242285977, 3.9014859001770281],
					[61.336816807792005, 3.9014859004853131],
					[61.336739067818073, 3.9013665906806421],
					[61.336739064995598, 3.9013665873953229],
					[61.336739060244817, 3.9013665806789488],
					[61.336739057352368, 3.9013665755323732],
					[61.336583457000771, 3.9011692763869981],
					[61.336583398989006, 3.9011692408979237],
					[61.336583272951628, 3.9011692146224042],
					[61.336583204926015, 3.9011692238359439],
					[61.336427704906598, 3.9012526245450769],
					[61.336427701329995, 3.901252625127944],
					[61.336427692528488, 3.9012526303075474],
					[61.336427689091863, 3.9012526346128693],
					[61.336361007994796, 3.9012970213439839],
					[61.33610822837538, 3.9013942136383055],
					[61.336108186366069, 3.9013942448280412],
					[61.336108121894661, 3.9013943246207954],
					[61.336108102185044, 3.9013943746478765],
					[61.336069201982262, 3.9016916735756055],
					[61.336069200753933, 3.9016916887565918],
					[61.336069200979736, 3.9016917186814752],
					[61.33606920332803, 3.9016917332796526],
					[61.336160896391391, 3.9022416908091295],
					[61.336091539847672, 3.9025054203035006],
					[61.335941688929701, 3.9025275986447623],
					[61.335788908793923, 3.9025220003804342],
					[61.33556392781307, 3.9025109018741873],
					[61.335447270039701, 3.9024831104370676],
					[61.335072291865195, 3.9022887226194789],
					[61.335072287254434, 3.9022887196255835],
					[61.335072277208781, 3.9022887156446422],
					[61.335072271703865, 3.9022887127964272],
					[61.334666771654845, 3.9021331124795862],
					[61.334666770760712, 3.9021331126252652],
					[61.334666769866544, 3.9021331127709464],
					[61.334666768972404, 3.9021331129166268],
					[61.33453897420317, 3.9020859130990839],
					[61.334222278662153, 3.9019498158185049],
					[61.334222277768021, 3.9019498159641834],
					[61.33422227590971, 3.9019498143944671],
					[61.334222275015541, 3.9019498145401537],
					[61.334133374952415, 3.9019137155312804],
					[61.334133367659213, 3.901913712974594],
					[61.334133353072758, 3.9019137078612052],
					[61.334133344885373, 3.9019137054502044],
					[61.333930644694256, 3.9018665049052705],
					[61.3339306392593, 3.9018665039183142],
					[61.333930625707005, 3.9018665023814294],
					[61.333930619377931, 3.9018665015401512],
					[61.333730618979963, 3.9018470010597754],
					[61.333730618085795, 3.9018470012054616],
					[61.333730616297487, 3.9018470014968321],
					[61.333730613615046, 3.9018470019338785],
					[61.333691781876752, 3.9018442042510548],
					[61.333544581090351, 3.9017137924207974],
					[61.333464094951992, 3.9013499578615236],
					[61.333464093777849, 3.9013499505630405],
					[61.333464090675371, 3.9013499398338767],
					[61.333464088677069, 3.9013499345421492],
					[61.333383489097422, 3.9011166352507005],
					[61.333383473600882, 3.9011166059443099],
					[61.333383434840492, 3.901116556087],
					[61.33338341240075, 3.9011165335293332],
					[61.333272312173264, 3.9010415340556586],
					[61.333272306668334, 3.9010415312078117],
					[61.333272295588465, 3.901041523651108],
					[61.333272290083542, 3.9010415208032767],
					[61.333261190243356, 3.9010359203105858],
					[61.333261158457965, 3.9010359123830312],
					[61.333261093308856, 3.901035902402417],
					[61.333261060085178, 3.901035904071406],
					[61.333138860395906, 3.9010609041004258],
					[61.333138833711295, 3.9010609121935538],
					[61.333138786127094, 3.9010609386717405],
					[61.333138764193279, 3.9010609534804526],
					[61.332938764123377, 3.9012470535932064],
					[61.332938762405092, 3.9012470557456225],
					[61.332938757180138, 3.901247060341821],
					[61.332938755461839, 3.9012470624942233],
					[61.332886069910209, 3.9013025418676679],
					[61.33278074238126, 3.901233153788862],
					[61.33245846855533, 3.9007748924525441],
					[61.332316881068898, 3.9005249134699107],
					[61.332239086689796, 3.9003221271619903],
					[61.332239082763124, 3.9003221184400263],
					[61.332239074015604, 3.9003221011418163],
					[61.332239069194763, 3.9003220925655722],
					[61.332025169218149, 3.8999859926506169],
					[61.332025162608993, 3.899985984365935],
					[61.332025150284828, 3.8999859676508599],
					[61.332025142851556, 3.8999859613728529],
					[61.33152234306894, 3.8994692613056023],
					[61.331522334671448, 3.8994692533126378],
					[61.331522316982301, 3.899469237472446],
					[61.331522306866532, 3.899469231631874],
					[61.331097306780492, 3.8991998306473956],
					[61.331036207002889, 3.8991609318261125],
					[61.331036169572393, 3.8991609173318089],
					[61.331036091695026, 3.8991609038133328],
					[61.331036052212319, 3.8991609065043069],
					[61.330911052342707, 3.8991915057068542],
					[61.330911041682938, 3.8991915093166458],
					[61.330911021257506, 3.8991915163904967],
					[61.330911010667784, 3.8991915218611877],
					[61.330552711159399, 3.8993692201805286],
					[61.330552699745525, 3.8993692276576737],
					[61.330552679530172, 3.8993692403139169],
					[61.330552669904577, 3.8993692474996102],
					[61.330174870024663, 3.8996942477398107],
					[61.330174857926664, 3.8996942609450032],
					[61.330174836413121, 3.8996942869182205],
					[61.330174826173469, 3.8996943016928185],
					[61.329874826717784, 3.9002192994224516],
					[61.32969153115215, 3.9005331922696476],
					[61.329638738913125, 3.90061098051862],
					[61.32938595437524, 3.9009248616298233],
					[61.329236073326399, 3.9010608532502604],
					[61.329139022391352, 3.901005439522395],
					[61.328836262207346, 3.9007026798480111],
					[61.32861128090812, 3.9002832146113957],
					[61.328491885228559, 3.8999916242721397],
					[61.32849188426438, 3.8999916225571045],
					[61.328491882266022, 3.8999916172662741],
					[61.328491881301872, 3.8999916155512371],
					[61.328350186121114, 3.8996888245622912],
					[61.328325189180674, 3.8996166344518297],
					[61.328325140304031, 3.8996165787640678],
					[61.328325017336724, 3.899616514556886],
					[61.328324943316048, 3.899616507898183],
					[61.328166684776008, 3.8996636967728757],
					[61.327947284060187, 3.8996331099456389],
					[61.327783427605056, 3.8994970469113546],
					[61.327783400010233, 3.8994970308151036],
					[61.327783339735824, 3.899497006939693],
					[61.327783308020372, 3.899497000875519],
					[61.327513908262617, 3.8994858990358279],
					[61.327513886802969, 3.8994859025329593],
					[61.327513845672001, 3.8994859092357954],
					[61.327513826070685, 3.8994859143021814],
					[61.327430526081763, 3.8995192134435666],
					[61.327430511091265, 3.8995192215027066],
					[61.327430482074412, 3.8995192393359859],
					[61.32743046804805, 3.8995192491100954],
					[61.327244341926075, 3.8996830857900648],
					[61.327019477986731, 3.8996387126727958],
					[61.326952955947192, 3.8995942632596594],
					[61.326741898348281, 3.8991610498558376],
					[61.326711300895461, 3.8984111287810168],
					[61.326850171739537, 3.8980029134458363],
					[61.327002933128462, 3.8978668488443016],
					[61.327002940071552, 3.8978668420962852],
					[61.32700295223939, 3.8978668307524433],
					[61.327002958218273, 3.8978668222895583],
					[61.327061239022342, 3.8977918461146075],
					[61.32730011254796, 3.8976279647659022],
					[61.327300129186547, 3.897627952693024],
					[61.327300156818673, 3.8976279219791996],
					[61.327300168846413, 3.8976279069138067],
					[61.327422368878771, 3.8974334053879289],
					[61.327422372315311, 3.8974334010834792],
					[61.327422377470022, 3.89743339462684],
					[61.327422379048159, 3.8974333887532699],
					[61.327458470867001, 3.897361204473464],
					[61.327602960727873, 3.897166818449219],
					[61.327602963340254, 3.8971668161511799],
					[61.327602966706692, 3.8971668099860182],
					[61.32760296924905, 3.8971668058273123],
					[61.327889068577541, 3.8967112062725446],
					[61.327889072014003, 3.8967112019679737],
					[61.327889077098639, 3.8967111936504035],
					[61.327889078746807, 3.8967111896374238],
					[61.328008461040334, 3.8964723250141704],
					[61.328030730747322, 3.8964529508830212],
					[61.328030765779182, 3.896452877773851],
					[61.328030770149759, 3.8964527310348847],
					[61.328030739418416, 3.8964526555444183],
					[61.32793903937273, 3.8963637560250901],
					[61.327939023611705, 3.8963637436181804],
					[61.327938992299835, 3.8963637243864588],
					[61.327938974820576, 3.8963637141318399],
					[61.32764447490672, 3.8962442147394292],
					[61.32764446396677, 3.896244210907053],
					[61.327644442156902, 3.8962442051030028],
					[61.327644431216932, 3.8962442012706497],
					[61.327469430589488, 3.8962165021640711],
					[61.327469423366225, 3.8962165014698598],
					[61.327469408025593, 3.8962165002272338],
					[61.327469399908196, 3.8962164996788249],
					[61.327438938196075, 3.8962165003290878],
					[61.327241779019687, 3.8961387159548369],
					[61.327036212630816, 3.8960442323416089],
					[61.326952951058615, 3.8959665670943315],
					[61.326644562952566, 3.8955359845851354],
					[61.326644560059918, 3.8955359794407398],
					[61.326644554414713, 3.8955359728731778],
					[61.326644551592139, 3.8955359695893836],
					[61.326541851882133, 3.8954165700780869],
					[61.326541833228355, 3.895416552527796],
					[61.326541794412833, 3.8954165251613286],
					[61.326541772392694, 3.8954165137761723],
					[61.326311171748536, 3.8953276134311996],
					[61.326311162666947, 3.8953276111682498],
					[61.326311146291985, 3.8953276063506914],
					[61.326311137140294, 3.8953276022271295],
					[61.326133336792701, 3.8952942029579227],
					[61.326133302604909, 3.8952942029174902],
					[61.326133237332243, 3.8952942135627118],
					[61.326133204459083, 3.8952942245400131],
					[61.326066603817637, 3.8953304240459059],
					[61.326066592403976, 3.8953304315233646],
					[61.326066567928528, 3.8953304504911253],
					[61.326066556584948, 3.8953304598291623],
					[61.325691556683537, 3.8957137603379337],
					[61.325691554071163, 3.8957137626359257],
					[61.32569154891651, 3.8957137690924935],
					[61.325691546304128, 3.8957137713904886],
					[61.325291554023991, 3.8961942623436649],
					[61.325172081888283, 3.896308136276756],
					[61.324849921783603, 3.8964776150716967],
					[61.324494351049701, 3.896594206366776],
					[61.324324964291023, 3.8966248023515999],
					[61.324324935888484, 3.8966248125979024],
					[61.324324883010249, 3.8966248418098299],
					[61.324324859358647, 3.8966248587691941],
					[61.324316616862369, 3.8966331005565134],
					[61.324183300475667, 3.8966331008013708],
					[61.324183292358271, 3.8966331002528549],
					[61.324183277087698, 3.8966331008705537],
					[61.324183269934515, 3.8966331020367524],
					[61.32391106971334, 3.8966748022179774],
					[61.323911062560157, 3.896674803384153],
					[61.323911050042064, 3.896674805424968],
					[61.323911043853087, 3.8966748083058484],
					[61.323863878875116, 3.8966886972285875],
					[61.323697216807524, 3.8966748007033947],
					[61.323697214125055, 3.8966748011407106],
					[61.323697209584253, 3.8966748000091185],
					[61.323697206901826, 3.8966748004464296],
					[61.323325028784957, 3.8966609004494859],
					[61.32313336867351, 3.896610911695146],
					[61.323063987236658, 3.8965776205731379],
					[61.322339004905494, 3.8962248287008596],
					[61.322077941261874, 3.8960276550821229],
					[61.322055756176667, 3.895999874930816],
					[61.322055749637371, 3.8959998685098838],
					[61.32205573559456, 3.8959998539534206],
					[61.322055729055251, 3.8959998475324733],
					[61.321880745839273, 3.895852662550737],
					[61.321716860381088, 3.8956331794215706],
					[61.321716860381088, 3.8956331794215706],
					[61.321716857628559, 3.8956331779986129],
					[61.321716857558485, 3.895633176138285],
					[61.32164736998525, 3.8955442923542125],
					[61.321641878599443, 3.8955332103924474],
					[61.321641870885692, 3.8955331966761615],
					[61.321641851951661, 3.895533171687116],
					[61.321641840661343, 3.8955331585539925],
					[61.321558465109433, 3.8954498825998329],
					[61.321380778962663, 3.8950916112377789],
					[61.321380763675208, 3.8950915875263008],
					[61.321380727121607, 3.8950915485651834],
					[61.321380704821159, 3.8950915297407134],
					[61.321219505415975, 3.8949915298476436],
					[61.321219424031767, 3.8949915187861013],
					[61.321219280775004, 3.8949915608635157],
					[61.321219218902492, 3.8949916140024516],
					[61.321038722748185, 3.8953721071491589],
					[61.320772032680502, 3.8958387894342148],
					[61.320674852437513, 3.8959665640626078],
					[61.320360965284415, 3.8962526520212788],
					[61.320360960059652, 3.8962526566163507],
					[61.32036095057434, 3.8962526675210003],
					[61.320360946243717, 3.8962526719703283],
					[61.320127645548411, 3.8965331717581884],
					[61.320127645618477, 3.8965331736184234],
					[61.32012764390025, 3.8965331757701653],
					[61.319866543896602, 3.8968609750754228],
					[61.319866539565957, 3.8968609795245945],
					[61.319866532833089, 3.8968609918518751],
					[61.319866529396613, 3.8968609961552847],
					[61.319827648400683, 3.8969248640500482],
					[61.319747075875355, 3.8969887424407652],
					[61.319747066319913, 3.8969887514847583],
					[61.31974704817322, 3.8969887712872056],
					[61.319747040406092, 3.8969887800397203],
					[61.319616569229581, 3.8971637416765526],
					[61.319411063148678, 3.8972636967179319],
					[61.31899171782883, 3.897224800241506],
					[61.318991683781121, 3.8972248039189497],
					[61.318991618718321, 3.897224820137541],
					[61.318991587773333, 3.8972248345388438],
					[61.318680487876172, 3.8974359356418051],
					[61.318680475568186, 3.8974359432623289],
					[61.318680453914922, 3.8974359655068525],
					[61.318680442641188, 3.8974359767019773],
					[61.318510942798731, 3.8976498761630172],
					[61.318510940256353, 3.8976498803204569],
					[61.318510934277469, 3.8976498887810278],
					[61.31851093077092, 3.8976498912240176],
					[61.318344233427489, 3.8979109906174734],
					[61.318066536795087, 3.8983165843039473],
					[61.317952641993685, 3.8984692778445664],
					[61.317577645367315, 3.8989304747599269],
					[61.317577643579021, 3.8989304750512659],
					[61.317577641860744, 3.8989304772026974],
					[61.317577641036635, 3.8989304792084711],
					[61.317313745355499, 3.8992776735918455],
					[61.317147072190949, 3.899466542572867],
					[61.316949916646522, 3.8995804163535537],
					[61.316638836089055, 3.899685911240756],
					[61.316638809614524, 3.8996859249102362],
					[61.316638763134549, 3.8996859568098339],
					[61.316638742304988, 3.8996859770456087],
					[61.316485943362323, 3.8998831758230139],
					[61.3163415762508, 3.9000609363181957],
					[61.316219362928656, 3.9001136975065935],
					[61.316158337099921, 3.9001109015717486],
					[61.315877895985274, 3.900016521899857],
					[61.315827923355421, 3.8999776419798282],
					[61.315827905876439, 3.8999776317268329],
					[61.31582787009431, 3.899977613226469],
					[61.31582785096704, 3.8999776069847152],
					[61.315775099024229, 3.8999637194199126],
					[61.315647348327914, 3.8998470632848252],
					[61.315494559316946, 3.8996470797853435],
					[61.315494554636174, 3.8996470749335952],
					[61.315494546992845, 3.8996470630788869],
					[61.315494543276202, 3.8996470599414956],
					[61.315336273621448, 3.8994859915900748],
					[61.315316888722528, 3.8994305340892819],
					[61.315316813425461, 3.8994304696278315],
					[61.315316643014171, 3.8994304356297596],
					[61.315316548934071, 3.8994304696674149],
					[61.315210968758564, 3.8995526462850028],
					[61.315047096038029, 3.8996526287558817],
					[61.315047095213885, 3.8996526307614592],
					[61.315047091637261, 3.8996526313439945],
					[61.315047090743107, 3.8996526314896296],
					[61.314905491413953, 3.8997442320056193],
					[61.314905479175827, 3.8997442414841883],
					[61.314905455663762, 3.8997442621556626],
					[61.314905445283998, 3.8997442732029146],
					[61.314819245232073, 3.8998498732349938],
					[61.314819240971318, 3.8998498795429382],
					[61.314819232379811, 3.8998498902988792],
					[61.314819228119099, 3.8998498966067978],
					[61.314463732876348, 3.9004442901075596],
					[61.314127644805808, 3.9009137727444498],
					[61.313969273177001, 3.9010804430103958],
					[61.313897103998364, 3.9011248230712319],
					[61.313574915573376, 3.9012776175660626],
					[61.313488881680016, 3.9013164881629385],
					[61.31328895067206, 3.9012637062238054],
					[61.313288941660488, 3.9012637058197397],
					[61.313288925355707, 3.9012637028605859],
					[61.313288916274182, 3.9012637005966693],
					[61.313086115718484, 3.9012470015751983],
					[61.313086103954475, 3.9012469997480306],
					[61.313086078778092, 3.9012470001045183],
					[61.31308606722407, 3.9012470038568461],
					[61.313052888987841, 3.9012525814614043],
					[61.312897372948221, 3.9010387982256653],
					[61.312639083928836, 3.9004805201909525],
					[61.312341885890397, 3.899733225878665],
					[61.312186286213482, 3.8993443258934417],
					[61.312186283250981, 3.8993443188912047],
					[61.312186276431788, 3.8993443050323848],
					[61.312186271680943, 3.8993442983214055],
					[61.312141872386803, 3.8992692986414919],
					[61.312141862885127, 3.8992692852195878],
					[61.31214184216352, 3.8992692605267858],
					[61.312141830943581, 3.8992692492559051],
					[61.311908439904833, 3.8990665578101202],
					[61.31177234819851, 3.898916565635846],
					[61.311772340765224, 3.8989165593619202],
					[61.311772326792756, 3.8989165466684521],
					[61.31177232025361, 3.8989165402488912],
					[61.311716828046855, 3.898874845987653],
					[61.311641841608967, 3.8988054587095808],
					[61.31134454679475, 3.8984831632863512],
					[61.311344545900596, 3.8984831634319961],
					[61.311258446231655, 3.8983915628152825],
					[61.311258441620836, 3.8983915598240886],
					[61.311258434117498, 3.8983915516906675],
					[61.311258429436648, 3.8983915468397403],
					[61.311039029238572, 3.8982054471167213],
					[61.311039024627775, 3.8982054441255709],
					[61.311039017194425, 3.8982054378519555],
					[61.311039012583585, 3.8982054348607931],
					[61.310864013427405, 3.8980859347611969],
					[61.310864002347436, 3.8980859272105803],
					[61.310863980397592, 3.8980859176883857],
					[61.310863967599317, 3.8980859122887708],
					[61.310802867626961, 3.8980637122221964],
					[61.310802851252092, 3.8980637074051963],
					[61.310802816854128, 3.8980637017818913],
					[61.310802799725188, 3.8980637008299306],
					[61.310688900292106, 3.8980637007426688],
					[61.310688897539606, 3.8980636993199478],
					[61.310688894857179, 3.8980636997569027],
					[61.31068889306885, 3.8980637000482137],
					[61.310455593301327, 3.898071999726894],
					[61.310455581677353, 3.8980720016203576],
					[61.310455560147666, 3.8980720032563045],
					[61.31045554859373, 3.8980720070094539],
					[61.310338849409774, 3.8981026077038239],
					[61.310338828090174, 3.8981026149187539],
					[61.310338786625266, 3.8981026366415854],
					[61.310338768268281, 3.8981026508581995],
					[61.30999706845455, 3.8984026504809157],
					[61.309997056286534, 3.8984026618180829],
					[61.309997034773048, 3.8984026877747784],
					[61.309997026251665, 3.8984027003890298],
					[61.309688725857121, 3.8989471015582038],
					[61.309688725033013, 3.8989471035634446],
					[61.309688724208854, 3.8989471055686931],
					[61.309688723314714, 3.8989471057143117],
					[61.309502631147801, 3.8992970928444119],
					[61.309430447805866, 3.8993915708266718],
					[61.30906375528113, 3.8997776613136188],
					[61.309063749302084, 3.8997776697711535],
					[61.309063738992251, 3.8997776826758406],
					[61.309063733837348, 3.8997776891281899],
					[61.308677634079146, 3.9003554898619659],
					[61.308677629748352, 3.9003554943089989],
					[61.308677623085117, 3.9003555084905952],
					[61.308677618824326, 3.9003555147971771],
					[61.308569219190602, 3.9005832139357488],
					[61.30856921184315, 3.9005832338415214],
					[61.308569202653196, 3.9005832764987796],
					[61.308569199846495, 3.9005832975362771],
					[61.308566499613988, 3.9007999976971335],
					[61.308566499683977, 3.9007999995567135],
					[61.308566499753972, 3.9008000014162749],
					[61.308566499753972, 3.9008000014162749],
					[61.308574800160017, 3.9015222028257162],
					[61.308574800229998, 3.9015222046852807],
					[61.308574799615755, 3.9015222122691031],
					[61.3085748006499, 3.9015222158426885],
					[61.308627599945758, 3.9022389095243541],
					[61.308638700256161, 3.9026000041123514],
					[61.308649799906732, 3.9032361003623066],
					[61.308647000003447, 3.9035249963797294],
					[61.30863310029671, 3.9042805959445519],
					[61.308633100436602, 3.9042805996637049],
					[61.308633099752299, 3.9042806053879122],
					[61.308633099822245, 3.9042806072474878],
					[61.308666500345872, 3.9050833085836962],
					[61.308680400070955, 3.9054639059803313],
					[61.308710900420962, 3.9065972021347544],
					[61.308705400083547, 3.9072360972339801],
					[61.308688699773015, 3.9080360963612053],
					[61.308688699773015, 3.9080360963612053],
					[61.308677600420644, 3.9087277965800737],
					[61.30867760208352, 3.9087278168902895],
					[61.308677607952035, 3.9087278533555176],
					[61.308677613121723, 3.9087278712247708],
					[61.308794208575719, 3.9090306596419757],
					[61.308860906277467, 3.9093000485611178],
					[61.308860918125475, 3.9093000765709882],
					[61.308860949729301, 3.9093001275636126],
					[61.308860970449139, 3.9093001522606343],
					[61.308960969692841, 3.9093862515566884],
					[61.308960997356401, 3.9093862695124479],
					[61.308961056735065, 3.9093862935396944],
					[61.308961089344365, 3.9093862994658526],
					[61.309011085217428, 3.9093890987400557],
					[61.309222182390073, 3.9094084994528551],
					[61.30922219766078, 3.9094084988420272],
					[61.309222231778918, 3.9094084970390974],
					[61.309222247873912, 3.9094084944233241],
					[61.309244392973078, 3.9094030075521631],
					[61.309391592937587, 3.9094501765308252],
					[61.309435948729785, 3.9094945325012267],
					[61.309602626616403, 3.9097140035067324],
					[61.3097191815869, 3.9099750028876841],
					[61.309599830070134, 3.9102526888335154],
					[61.309433183370729, 3.91043043205282],
					[61.309230522228859, 3.9105165167991931],
					[61.309230494928755, 3.9105165324609681],
					[61.309230448795255, 3.9105165736347067],
					[61.309230428997807, 3.9105165974323333],
					[61.309055449464125, 3.9108081626333124],
					[61.308922121560023, 3.9109054082298886],
					[61.308538870578012, 3.9109609016583895],
					[61.308538849257637, 3.9109609088641166],
					[61.308538805792587, 3.9109609252804427],
					[61.308538786400256, 3.9109609359148223],
					[61.308124885689828, 3.9112498368356388],
					[61.30812486740119, 3.9112498529031252],
					[61.308124833715986, 3.9112498901810278],
					[61.308124820177575, 3.9112499129604914],
					[61.307808119662056, 3.9119027132168851],
					[61.307808118013398, 3.9119027172264689],
					[61.307808113891731, 3.9119027272504447],
					[61.30780811224308, 3.9119027312600454],
					[61.307424815084943, 3.9129555238457443],
					[61.307202622080865, 3.9134388092434542],
					[61.307105426092008, 3.9136110024469302],
					[61.307105424373468, 3.9136110045968233],
					[61.307105421006234, 3.9136110107561324],
					[61.307105420251666, 3.913611014620352],
					[61.306924825163009, 3.9139888027820429],
					[61.306735932033568, 3.9142804909914739],
					[61.306735927841913, 3.9142804991552849],
					[61.306735920282968, 3.9142805134782819],
					[61.306735916091355, 3.9142805216420848],
					[61.306627616299778, 3.9145332213311277],
					[61.306627610598966, 3.9145332372231652],
					[61.306627602774064, 3.9145332684266148],
					[61.306627600719771, 3.9145332855975177],
					[61.306599801250769, 3.9148999856677706],
					[61.306599800356615, 3.9148999858129137],
					[61.306599800426405, 3.9148999876724018],
					[61.30654420101029, 3.9157555835397342],
					[61.306472002961179, 3.9164638620566539],
					[61.306263708286508, 3.9171832447224757],
					[61.306263706637694, 3.9171832487315847],
					[61.306263704303973, 3.9171832584642203],
					[61.306263702655144, 3.9171832624733494],
					[61.306199803077838, 3.917522163878755],
					[61.306199803147599, 3.9175221657382271],
					[61.306199802462707, 3.9175221714617292],
					[61.3061998016383, 3.9175221734662724],
					[61.306113702349656, 3.918166673996049],
					[61.306113700631059, 3.9181666761456384],
					[61.306113700840299, 3.9181666817240441],
					[61.306113700910061, 3.9181666835835109],
					[61.306085901077815, 3.9185193835991372],
					[61.306085901147554, 3.9185193854586049],
					[61.306072001081212, 3.918708284427483],
					[61.306072001150959, 3.9187082862869507],
					[61.30607200025679, 3.9187082864319862],
					[61.306016500079885, 3.919561086569685],
					[61.306016500219343, 3.9195610902886164],
					[61.306016500358815, 3.9195610940075492],
					[61.306016499604127, 3.9195610978715063],
					[61.306010900056059, 3.9201332983571175],
					[61.306010900056059, 3.9201332983571175],
					[61.306008099707924, 3.9207610985661145],
					[61.306008099777657, 3.9207611004256],
					[61.306008099917079, 3.9207611041445385],
					[61.306008100056516, 3.920761107863477],
					[61.306046999920987, 3.9218000078482533],
					[61.306046999990691, 3.9218000097077259],
					[61.30604700109398, 3.9218000151412031],
					[61.306047001233388, 3.9218000188601678],
					[61.306091499880303, 3.9222972086450776],
					[61.306083100009296, 3.9229860976488196],
					[61.306083100148676, 3.9229861013677962],
					[61.306083100427422, 3.9229861088057341],
					[61.306083099672627, 3.9229861126696046],
					[61.306138699626011, 3.9239000116237586],
					[61.306138700659567, 3.9239000151978369],
					[61.306138700938263, 3.9239000226357983],
					[61.306138701902107, 3.9239000243503974],
					[61.306208102223863, 3.9244528255370028],
					[61.306208102363186, 3.9244528292559857],
					[61.306208103605684, 3.9244528384085902],
					[61.306208104708894, 3.9244528438421877],
					[61.30627199763348, 3.9247389142544096],
					[61.306263700668495, 3.9248360781613227],
					[61.306188702081371, 3.925369371457653],
					[61.306188702151026, 3.9253693733171482],
					[61.306188701326469, 3.9253693753214893],
					[61.306188701396124, 3.9253693771809823],
					[61.306060902162891, 3.9265166684561992],
					[61.30591370382745, 3.9272555604924739],
					[61.305913703002858, 3.9272555624967542],
					[61.305913703142117, 3.927255566215718],
					[61.305913702317575, 3.927255568219989],
					[61.30583310559274, 3.927761048290789],
					[61.305733117276098, 3.9280332192036309],
					[61.305599836298917, 3.9282887818972001],
					[61.305405456288369, 3.928491561870354],
					[61.305405445906068, 3.928491572904711],
					[61.305405428857533, 3.9284915981133031],
					[61.305405421227448, 3.9284916105728662],
					[61.305335920694773, 3.9286305102944148],
					[61.30533591987021, 3.9286305122986143],
					[61.305335918221054, 3.9286305163070168],
					[61.305335916571842, 3.9286305203154419],
					[61.305266516875868, 3.9287888192648328],
					[61.305266516051255, 3.9287888212690305],
					[61.305266513577521, 3.9287888272816063],
					[61.305266512752915, 3.9287888292858208],
					[61.305135917628881, 3.9291388174268884],
					[61.304980424227672, 3.9294249033699145],
					[61.304980420104698, 3.9294249133907213],
					[61.304980410964518, 3.9294249335770712],
					[61.304980407805303, 3.9294249453125927],
					[61.304922023192006, 3.9296276902330467],
					[61.304816563721765, 3.9297248535429845],
					[61.304816554233561, 3.9297248644320022],
					[61.304816533607983, 3.9297248902183153],
					[61.304816525083559, 3.9297249028220422],
					[61.304652624928579, 3.930022102511681],
					[61.304652620805534, 3.9300221125323103],
					[61.304652613314474, 3.930022128710029],
					[61.30465261008564, 3.9300221385859504],
					[61.304533110333814, 3.9303860380755626],
					[61.304533104571895, 3.93038607642139],
				],
			],
		},
		{
			fldNpdidField: "43807",
			wlbNpdidWellbore: "1038",
			fldName: "YME",
			fldCurrentActivitySatus: "Producing",
			wlbName: "9/2-1",
			fldDiscoveryYear: "1987",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43807",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43807",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "316",
			cmpLongName: "Repsol Norge AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=1038",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "27052812",
			fldNpdidOwner: "2786469",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[57.776838699974775, 4.5479166885105977],
					[57.776838702768472, 4.5479167167847985],
					[57.776838714565343, 4.5479167708841777],
					[57.776838725418436, 4.5479167981647297],
					[57.780166525173087, 4.5538750988016377],
					[57.780166535553704, 4.5538751126757386],
					[57.780166560851313, 4.5538751415489012],
					[57.780166573977311, 4.5538751567685276],
					[57.785502674279329, 4.5581862560710507],
					[57.785502695405498, 4.5581862686261179],
					[57.785502740167786, 4.5581862883758477],
					[57.785502762967262, 4.5581862973573344],
					[57.79358606317313, 4.5597112975649452],
					[57.793586091051303, 4.5597112975031528],
					[57.793586146631164, 4.5597112923485694],
					[57.793586174274004, 4.559711285578838],
					[57.798480674098499, 4.5577584856257323],
					[57.798480675889486, 4.5577584854052242],
					[57.798480680308124, 4.5577584831767268],
					[57.798480682040243, 4.5577584812790164],
					[57.804100082203668, 4.5552390819125046],
					[57.804100113793488, 4.555239059488156],
					[57.804100166651445, 4.5552390024335621],
					[57.804100187082994, 4.5552389695911151],
					[57.808180787239756, 4.5442917695534186],
					[57.810244572692817, 4.538764009465976],
					[57.816472282650267, 4.532316902644772],
					[57.82510277322573, 4.5325112991375631],
					[57.829230455034846, 4.5335612695698186],
					[57.833808116387367, 4.5443722774853512],
					[57.833808145682859, 4.5443723177016082],
					[57.833808219742728, 4.5443723776739073],
					[57.833808265402524, 4.5443723973192043],
					[57.842655564732155, 4.5459279975225613],
					[57.842655573745965, 4.5459279980944274],
					[57.84265559272815, 4.5459280008066152],
					[57.842655601682914, 4.5459279996992397],
					[57.851302801616669, 4.54584190063078],
					[57.851302850631562, 4.5458418878195479],
					[57.851302936075264, 4.545841836763052],
					[57.851302971726689, 4.5458418019878541],
					[57.854030772351379, 4.5412251016099745],
					[57.854030782387539, 4.5412250801221736],
					[57.854030796073339, 4.5412250345634009],
					[57.854030799722963, 4.541225010492421],
					[57.854569586366821, 4.5310974665988013],
					[57.857738816701712, 4.5320612842216637],
					[57.864160996210281, 4.5359807704162298],
					[57.864161028143435, 4.5359807833306158],
					[57.8641610960726, 4.5359807968425505],
					[57.864161131173205, 4.5359807975511854],
					[57.870725030923076, 4.5349612972145605],
					[57.870725037191285, 4.5349612964366486],
					[57.870725050563969, 4.5349612930891867],
					[57.870725056832178, 4.5349612923112748],
					[57.878322254602416, 4.532697392427421],
					[57.882797254291383, 4.531450193788924],
					[57.882797293934807, 4.5314501702900776],
					[57.882797360211157, 4.531450111401635],
					[57.882797385889347, 4.5314500744422341],
					[57.884994585619388, 4.5259472745898819],
					[57.884994591234722, 4.5259472553172637],
					[57.884994597988175, 4.5259472173290991],
					[57.88499460002172, 4.5259471985021413],
					[57.884880799856333, 4.5164777978894008],
					[57.884880792279958, 4.5164777616861294],
					[57.884880763755177, 4.5164776926354699],
					[57.884880742806757, 4.5164776597880802],
					[57.880550142977775, 4.5120304595795862],
					[57.880550109849423, 4.5120304383885621],
					[57.880550037741386, 4.5120304085551366],
					[57.880549997866289, 4.512030400024476],
					[57.873377797688441, 4.5120942000852491],
					[57.873377786943266, 4.5120942014257661],
					[57.873377763662077, 4.5120942043302179],
					[57.873377752021476, 4.5120942057824438],
					[57.86523327291475, 4.5140887012456048],
					[57.862327895298691, 4.5141719972175141],
					[57.856072356755433, 4.5062442767727013],
					[57.856072317000788, 4.5062442463042585],
					[57.856072226625606, 4.5062442086573826],
					[57.856072176900469, 4.5062442013672026],
					[57.849372233074313, 4.5070247943527688],
					[57.843250092865517, 4.5041692238246469],
					[57.83666120173114, 4.5002804281778195],
					[57.836661198030157, 4.5002804252674959],
					[57.836661191642889, 4.5002804226927209],
					[57.836661188896976, 4.5002804213494123],
					[57.82938068937041, 4.4966831210726577],
					[57.829380643107129, 4.4966831099946711],
					[57.829380551416293, 4.4966831113376786],
					[57.829380505093326, 4.4966831238705742],
					[57.82451932505672, 4.4992915123824559],
					[57.815333246071965, 4.5018554065071248],
					[57.815333218671441, 4.5018554200373719],
					[57.815333167690611, 4.50185545336244],
					[57.815333145901114, 4.5018554729338414],
					[57.809008145838256, 4.509538772987483],
					[57.809008136464548, 4.5095387876345923],
					[57.809008118612567, 4.5095388168172947],
					[57.809008111925166, 4.5095388311299009],
					[57.805991515886916, 4.517741620136329],
					[57.803622022189458, 4.5223777094178077],
					[57.803622022766753, 4.5223778020061181],
					[57.803622102607939, 4.5223779487749018],
					[57.803622180857673, 4.5223779997118161],
					[57.803963264253063, 4.5224112392842768],
					[57.796361010147464, 4.5268387213105852],
					[57.783633221203843, 4.5322887166834622],
					[57.783633202635777, 4.5322887257165574],
					[57.783633170154594, 4.5322887482580212],
					[57.783633155345996, 4.5322887618772159],
					[57.777355455421201, 4.5389081614646649],
					[57.777355435829975, 4.5389081925042918],
					[57.777355408347859, 4.5389082548214725],
					[57.777355399679692, 4.5389082895620483],
					[57.776838699974775, 4.5479166885105977],
				],
				[
					[57.737597001617587, 4.3393805712249556],
					[57.737597032764164, 4.3393806597109803],
					[57.737597154730182, 4.3393807718091857],
					[57.737597244654367, 4.3393807955367585],
					[57.741355591344572, 4.3385196064850664],
					[57.753002737650917, 4.3422862904656334],
					[57.753002788092552, 4.3422862923768051],
					[57.7530028819072, 4.342286275237182],
					[57.753002927009057, 4.3422862542809764],
					[57.758225090088331, 4.3379751852318611],
					[57.760905437905471, 4.3374058387436518],
					[57.762669203182043, 4.3476528340530329],
					[57.762669208397703, 4.3476528535771921],
					[57.762669222348393, 4.3476528904892584],
					[57.762669231021789, 4.3476529062021143],
					[57.771794229848155, 4.3620195043192806],
					[57.779505419874688, 4.3748361886280085],
					[57.783938708201269, 4.3858611592876544],
					[57.785297004814836, 4.3920944423101735],
					[57.785297106593895, 4.392094520252356],
					[57.785297303334865, 4.3920945136588756],
					[57.785297398174677, 4.3920944257707841],
					[57.787341898066906, 4.3764694265276569],
					[57.787341898655903, 4.3764694180316273],
					[57.787341899895125, 4.3764694027158537],
					[57.787341899588839, 4.3764693943345243],
					[57.786555800182057, 4.3500471931626219],
					[57.786555800058842, 4.3500471898102919],
					[57.786555798793941, 4.3500471798686444],
					[57.786555797713888, 4.3500471749554839],
					[57.783919598352654, 4.329402774306379],
					[57.78391959816701, 4.3294027692783867],
					[57.783919596005305, 4.3294027594541102],
					[57.783919594924463, 4.3294027545419613],
					[57.781272395455296, 4.3179499557800174],
					[57.781272389339776, 4.3179499363665821],
					[57.781272373713989, 4.3179499030317094],
					[57.781272365036898, 4.3179498873183091],
					[57.775647364663719, 4.3097276874481398],
					[57.775647324679333, 4.3097276522352965],
					[57.7756472340672, 4.3097276101304773],
					[57.775647183377316, 4.3097276015629395],
					[57.764574982948922, 4.310663700780669],
					[57.764574982053738, 4.3106637008968907],
					[57.764574980263355, 4.3106637011293349],
					[57.76457497936817, 4.3106637012455584],
					[57.756266679205915, 4.311530401036415],
					[57.756266675625128, 4.3115304015010691],
					[57.756266669358752, 4.3115304023142178],
					[57.75626666488278, 4.3115304028950376],
					[57.746077764600734, 4.3133304024663719],
					[57.746077730060314, 4.3133304170384825],
					[57.746077665889992, 4.3133304573217828],
					[57.746077638050444, 4.3133304828008123],
					[57.740197038343645, 4.3214581824400478],
					[57.740197025658944, 4.3214582042651823],
					[57.740197008408344, 4.3214582485467883],
					[57.740197002052057, 4.3214582712349641],
					[57.737597001617587, 4.3393805712249556],
				],
				[
					[57.728152600968421, 4.350613918627622],
					[57.728533101410456, 4.3547528182714528],
					[57.728533106932332, 4.3547528461460026],
					[57.728533127700871, 4.3547528972833858],
					[57.728533142113797, 4.3547529223347095],
					[57.731297041625965, 4.3583307212836502],
					[57.731297049156794, 4.3583307304062613],
					[57.731297064095543, 4.3583307453042295],
					[57.731297071626379, 4.3583307544268397],
					[57.733977659145246, 4.3605640428504779],
					[57.73661374875519, 4.3636223313961766],
					[57.736613762736731, 4.3636223447393405],
					[57.736613793262769, 4.3636223677335186],
					[57.736613809807302, 4.3636223773845311],
					[57.74058880880726, 4.3656390778114433],
					[57.744347093839934, 4.3675695708202529],
					[57.748091552761309, 4.3703584407982818],
					[57.751930433093271, 4.3761112096262167],
					[57.754944232816946, 4.3807084093947486],
					[57.754944233834586, 4.380708412629863],
					[57.757880434328094, 4.3850445117413193],
					[57.757880444726382, 4.385044525557718],
					[57.757880469938335, 4.3850445509438405],
					[57.757880482961383, 4.3850445627422747],
					[57.760277683129175, 4.3867779627991883],
					[57.760277738658488, 4.3867779809511429],
					[57.76027785297147, 4.386777983182693],
					[57.760277909964593, 4.3867779674909375],
					[57.762127909664194, 4.3855584665684386],
					[57.762127939187224, 4.3855584375534091],
					[57.762127982646597, 4.3855583714167512],
					[57.762127997417132, 4.3855583325056458],
					[57.762866896860281, 4.3811000335838104],
					[57.762866898406116, 4.3811000266543028],
					[57.76286689893432, 4.3811000164889551],
					[57.762866899646063, 4.3811000113490604],
					[57.763039099816673, 4.3781806127964904],
					[57.763039099633041, 4.3781806077710632],
					[57.763039100160988, 4.3781805976056534],
					[57.763039100038547, 4.3781805942553547],
					[57.762803000346814, 4.3708693945504429],
					[57.762803000162869, 4.3708693895250885],
					[57.762802998961035, 4.3708693812642299],
					[57.762802998777083, 4.3708693762388897],
					[57.76207799874328, 4.3646999774671533],
					[57.762077997725221, 4.3646999742318808],
					[57.762077997602425, 4.3646999708817509],
					[57.762077996584367, 4.364699967646481],
					[57.760611297268667, 4.3556138671854283],
					[57.760611287147363, 4.3556138365117745],
					[57.76061125628474, 4.3556137798955312],
					[57.760611236377173, 4.3556137521628813],
					[57.758489035631129, 4.3536442532553963],
					[57.758489023623618, 4.353644244702858],
					[57.75848899871341, 4.3536442277129312],
					[57.758488984915431, 4.3536442193906701],
					[57.755438985157575, 4.352202619563335],
					[57.75543897781094, 4.3522026154603708],
					[57.755438963240728, 4.3522026106039355],
					[57.755438955060391, 4.3522026082908543],
					[57.749211154575868, 4.3504137085398025],
					[57.749211149976475, 4.3504137057667869],
					[57.749211139110379, 4.3504137037999691],
					[57.749211132782094, 4.3504137029317134],
					[57.742638934737649, 4.349302602530595],
					[57.734344436700169, 4.3477359036249048],
					[57.734344431267083, 4.3477359026421913],
					[57.73434442040093, 4.34773590067676],
					[57.734344414134206, 4.3477359014829622],
					[57.730975014359771, 4.3474915005954466],
					[57.730974969597519, 4.3474915063535642],
					[57.73097488899262, 4.3474915402636052],
					[57.730974852131546, 4.3474915651834971],
					[57.728152652013421, 4.3506137657864352],
					[57.728152630801816, 4.3506138004591284],
					[57.728152605757714, 4.3506138776589198],
					[57.728152600968421, 4.350613918627622],
				],
			],
		},
		{
			fldNpdidField: "43590",
			wlbNpdidWellbore: "359",
			fldName: "HEIMDAL",
			fldCurrentActivitySatus: "Producing",
			wlbName: "25/4-1",
			fldDiscoveryYear: "1972",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43590",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43590",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "036 BS",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=359",
			fldHcType: "OIL/GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "2473189",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Dusavik",
			fldAreaGeometryWKT: [
				[
					[59.546535900878865, 2.2213917071419469],
					[59.546535901792524, 2.2213917247042176],
					[59.547449801948837, 2.2285917243271332],
					[59.54744980338814, 2.2285917346851631],
					[59.547449808141401, 2.2285917567979934],
					[59.547449811272799, 2.2285917650401861],
					[59.553466510715417, 2.2459528652976304],
					[59.553466516885081, 2.2459528800315822],
					[59.553466532791305, 2.2459529087820993],
					[59.553466540653396, 2.2459529214006619],
					[59.560660940594936, 2.255327922763855],
					[59.560660954789242, 2.2553279358902323],
					[59.560660984069521, 2.2553279619638773],
					[59.560660999956362, 2.2553279729748574],
					[59.576749756858433, 2.2646444908321004],
					[59.576441510636457, 2.2655388355505122],
					[59.576441509083367, 2.26553889269975],
					[59.576441537188352, 2.2655390007332148],
					[59.576441567647393, 2.2655390496804819],
					[59.59136656801666, 2.2786779504510353],
					[59.591366577387568, 2.2786779574572931],
					[59.591366597912881, 2.2786779711122929],
					[59.591366608175555, 2.2786779779397919],
					[59.600247108144153, 2.2832723786471281],
					[59.6002471338386, 2.2832723877175054],
					[59.600247185756928, 2.2832723986425272],
					[59.600247212872581, 2.283272400318491],
					[59.608208313053453, 2.2827529996812301],
					[59.608208359153977, 2.2827529851060606],
					[59.608208437782942, 2.2827529373425666],
					[59.608208472004357, 2.2827529020370134],
					[59.616547359851474, 2.2687695220476747],
					[59.620777943607763, 2.2644029390464291],
					[59.620777949577388, 2.2644029325102255],
					[59.620777963118343, 2.2644029155585894],
					[59.620777968105372, 2.2644029074414602],
					[59.624436267809436, 2.2586834085804792],
					[59.624436282314633, 2.2586833754217337],
					[59.624436296875444, 2.2586833084558067],
					[59.624436297822832, 2.258683274469107],
					[59.622230797918355, 2.2419888747116263],
					[59.622230796662038, 2.2419888678505671],
					[59.622230795132211, 2.2419888557087635],
					[59.622230792984155, 2.2419888490276603],
					[59.616839099226624, 2.221527771892128],
					[59.616641900284428, 2.2126528055505976],
					[59.61795019859543, 2.1972083168454355],
					[59.617950187634662, 2.1972082621597639],
					[59.617950137640229, 2.1972081673832395],
					[59.617950100389763, 2.197208126930156],
					[59.61100295924804, 2.1932137614530811],
					[59.605522384705466, 2.179977722515158],
					[59.60552235289174, 2.1799776827659101],
					[59.605522273059982, 2.1799776207886641],
					[59.605522224242343, 2.1799776005012856],
					[59.602530623614022, 2.179613701432114],
					[59.602530571718113, 2.1796137084390002],
					[59.602530480096981, 2.1796137484190914],
					[59.60253043858858, 2.1796137817552319],
					[59.598772038689752, 2.1847415818130633],
					[59.598772037890072, 2.1847415837531754],
					[59.59877203610688, 2.1847415841157769],
					[59.598772036198781, 2.1847415858745896],
					[59.59362770198311, 2.1921469898838915],
					[59.581544525112371, 2.1910248111587789],
					[59.578736287622725, 2.1857971284468332],
					[59.576136293845174, 2.1750138531474756],
					[59.57613627943072, 2.1750138187774088],
					[59.576136236704571, 2.1750137599699753],
					[59.576136210975605, 2.1750137332308159],
					[59.569311211037281, 2.1704637331297301],
					[59.56931120639527, 2.1704637305226071],
					[59.569311197111247, 2.170463725308347],
					[59.56931119246925, 2.1704637227012058],
					[59.559283392390491, 2.1652970217703542],
					[59.559283341357308, 2.1652970108557441],
					[59.559283240672862, 2.1652970153767894],
					[59.559283192005338, 2.1652970323876093],
					[59.554416592342513, 2.1684276325300407],
					[59.554416569006769, 2.1684276514796821],
					[59.554416530020347, 2.1684276984663198],
					[59.554416514369677, 2.1684277265033134],
					[59.552030414456638, 2.1744138264326511],
					[59.552030407628394, 2.1744138508962134],
					[59.552030401996191, 2.1744138981921952],
					[59.552030400609482, 2.1744139233246602],
					[59.553621998684463, 2.1876250066447418],
					[59.553283101316289, 2.1939749711489811],
					[59.546535906162916, 2.2213916528355551],
					[59.546535902526863, 2.2213916695413674],
					[59.546535900878865, 2.2213917071419469],
				],
			],
		},
		{
			fldNpdidField: "4966234",
			wlbNpdidWellbore: "3322",
			fldName: "MORVIN",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6506/11-7",
			fldDiscoveryYear: "2001",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=4966234",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=4966234",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "134 B",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=3322",
			fldHcType: "OIL/GAS",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "1025257",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Kristiansund",
			fldAreaGeometryWKT: [
				[
					[65.095963700415425, 6.4362249994001113],
					[65.095963699628285, 6.4362250058644133],
					[65.095963699664708, 6.4362250079903998],
					[65.096113699705228, 6.4393972080086668],
					[65.09611370369052, 6.4393972310497993],
					[65.096113717038975, 6.4393972766146561],
					[65.096113724573229, 6.4393972971848408],
					[65.097860924856477, 6.4425194985457415],
					[65.097860925752798, 6.4425194984596059],
					[65.097860927618086, 6.4425195025396462],
					[65.097860929447052, 6.4425195044935197],
					[65.099608129414676, 6.4453779031972953],
					[65.099608131279879, 6.445377907277785],
					[65.099608134041446, 6.4453779112722218],
					[65.099608134974019, 6.4453779133124884],
					[65.102577635310638, 6.449714012761806],
					[65.102577637175756, 6.4497140168430498],
					[65.102577640833502, 6.4497140207524684],
					[65.102577642662396, 6.4497140227071963],
					[65.105110942606956, 6.4529445233559795],
					[65.105110948093483, 6.4529445292213214],
					[65.105110959999095, 6.4529445429929515],
					[65.105110966381929, 6.4529445487725017],
					[65.108894266072994, 6.456339049853403],
					[65.108894270626948, 6.4563390536789287],
					[65.108894279698688, 6.4563390592029499],
					[65.108894285148978, 6.4563390629427602],
					[65.112135972188796, 6.4586140547936024],
					[65.11524985805012, 6.4616612401600992],
					[65.118691555287413, 6.4652751376924948],
					[65.118691557116094, 6.4652751396494601],
					[65.11869156077347, 6.4652751435633489],
					[65.118691562602152, 6.4652751455202937],
					[65.122916555349192, 6.4692612383002484],
					[65.126013748643174, 6.4728334308262543],
					[65.126013752300352, 6.4728334347422445],
					[65.126013757786154, 6.4728334406162453],
					[65.126013761407435, 6.4728334424037879],
					[65.129463760582269, 6.4761890428688824],
					[65.129463762410836, 6.4761890448273576],
					[65.129463766067957, 6.4761890487443061],
					[65.129463767896496, 6.4761890507028026],
					[65.133360960911318, 6.4796084442291759],
					[65.13813045371819, 6.4847223365010729],
					[65.138130459167797, 6.4847223402507277],
					[65.138130469206374, 6.4847223499643318],
					[65.1381304737953, 6.4847223559282829],
					[65.142822065163614, 6.4885140485370316],
					[65.146377657319462, 6.4921501401389765],
					[65.146377658215854, 6.4921501400543198],
					[65.146377660976157, 6.4921501440605995],
					[65.146377661872549, 6.4921501439759446],
					[65.150269262420665, 6.4958362447912599],
					[65.150269263352584, 6.4958362468371451],
					[65.150269264248962, 6.4958362467525994],
					[65.154280461108883, 6.4995390445570278],
					[65.158835957796938, 6.5041057409610747],
					[65.158835958728758, 6.504105743007905],
					[65.158835962349684, 6.5041057448018371],
					[65.158835963246077, 6.5041057447175357],
					[65.163069263062425, 6.5081029455691972],
					[65.163069264890567, 6.5081029475323122],
					[65.163069270339619, 6.5081029512901969],
					[65.163069272167746, 6.5081029532533119],
					[65.166860969621027, 6.511252951582847],
					[65.170433146289412, 6.5143723315621953],
					[65.172408129818393, 6.5175390062697671],
					[65.172408131646407, 6.5175390082342322],
					[65.172408134406083, 6.5175390122470196],
					[65.172408136234111, 6.5175390142114642],
					[65.175827635272384, 6.5224834132516616],
					[65.177810934702762, 6.5253890131918606],
					[65.177810952085565, 6.5253890329293478],
					[65.177810991227616, 6.5253890655880245],
					[65.177811012125616, 6.5253890807255202],
					[65.18006101167029, 6.5264862805506105],
					[65.18006103063486, 6.5264862873259846],
					[65.180061067597308, 6.5264862966946025],
					[65.180061088284361, 6.5264862990369652],
					[65.183577762161022, 6.5267029987912766],
					[65.187644337197824, 6.5280501906141568],
					[65.187644338094202, 6.5280501905305561],
					[65.187644340783336, 6.5280501902797523],
					[65.187644341679743, 6.5280501901961498],
					[65.190811042122803, 6.5290084911415276],
					[65.190811063741265, 6.5290084955368028],
					[65.190811107804336, 6.5290084999763147],
					[65.190811130213859, 6.5290084978867888],
					[65.19355003038028, 6.5285918976200197],
					[65.196288929857673, 6.528175199062626],
					[65.19628895843644, 6.5281751899839033],
					[65.196289010971569, 6.5281751637078935],
					[65.196289034927943, 6.5281751465106037],
					[65.197791834786528, 6.5267973468807634],
					[65.197791854155398, 6.52679732369673],
					[65.197791883823911, 6.5267972717625913],
					[65.19779189505509, 6.5267972450631033],
					[65.198547391235707, 6.523472262880925],
					[65.199630782709121, 6.5209833807038065],
					[65.199630788666695, 6.520983360906631],
					[65.199630797927909, 6.5209833236984016],
					[65.199630800299943, 6.5209833042367755],
					[65.199683499590577, 6.5183528031693667],
					[65.199683500416427, 6.5183527988165153],
					[65.19968350027537, 6.5183527902787484],
					[65.199683500204841, 6.5183527860098751],
					[65.199250200214479, 6.5122277947417047],
					[65.199386300368701, 6.5061833059026961],
					[65.199386300333259, 6.5061833037682826],
					[65.199386300262375, 6.5061832994994973],
					[65.199386300226948, 6.5061832973651041],
					[65.199286300350067, 6.5001028009456085],
					[65.199402999828862, 6.4948444034877095],
					[65.199402999828862, 6.4948444034877095],
					[65.199402999793264, 6.4948444013533342],
					[65.199402999757666, 6.4948443992189588],
					[65.199403000152699, 6.4895721990641073],
					[65.19940300011703, 6.4895721969297391],
					[65.199402999938656, 6.4895721862578828],
					[65.199402998970967, 6.4895721820740748],
					[65.198952999279115, 6.4842360826002352],
					[65.198952999279115, 6.4842360826002352],
					[65.19895299920762, 6.4842360783315911],
					[65.19895299920762, 6.4842360783315911],
					[65.198380798730142, 6.4791499773287837],
					[65.198380797726315, 6.4791499710112763],
					[65.198380794750719, 6.4791499541929785],
					[65.198380792850543, 6.479149947960769],
					[65.197119592817828, 6.4744971488192409],
					[65.197119590881556, 6.4744971404535043],
					[65.197119586148659, 6.4744971259415927],
					[65.197119583351949, 6.4744971197954628],
					[65.194633489122097, 6.4688916334363302],
					[65.192847392821847, 6.462061049462557],
					[65.192847392785794, 6.462061047328743],
					[65.192847391817295, 6.462061043147008],
					[65.192847390884936, 6.4620610410990631],
					[65.191155791001592, 6.4565610414247638],
					[65.191155790965425, 6.4565610392911159],
					[65.191155790032951, 6.4565610372434978],
					[65.191155790032951, 6.4565610372434978],
					[65.188705791137039, 6.4491193395489841],
					[65.186583491002182, 6.4422471411175808],
					[65.186583490069495, 6.4422471390708234],
					[65.186583489100528, 6.4422471348907822],
					[65.186583489100528, 6.4422471348907822],
					[65.184341889696753, 6.4358888377441961],
					[65.183280797666399, 6.4323221629783669],
					[65.183022399909973, 6.4285999861681633],
					[65.183022374649994, 6.428599926660028],
					[65.183022294990195, 6.4285998361083712],
					[65.183022239657504, 6.4285998030188267],
					[65.179291755346483, 6.4278415073546196],
					[65.174788974090859, 6.4261887136191493],
					[65.169502879108208, 6.4239109153491798],
					[65.169502874626644, 6.4239109157844236],
					[65.169502865553724, 6.4239109102593295],
					[65.16950286196851, 6.4239109106075221],
					[65.165444461572804, 6.4225831100921926],
					[65.165444457950954, 6.4225831083089746],
					[65.165444448018306, 6.4225831050037723],
					[65.165444444433064, 6.4225831053520821],
					[65.159683343769302, 6.4212915057042945],
					[65.15968333656221, 6.4212915042700143],
					[65.159683323044291, 6.421291501314359],
					[65.159683316770099, 6.4212915019240482],
					[65.154358317352674, 6.4208498003410641],
					[65.15435831556006, 6.4208498005152483],
					[65.154358313767446, 6.4208498006894308],
					[65.154358312871111, 6.4208498007765238],
					[65.150391769148612, 6.4205887036230616],
					[65.146697314613149, 6.4179970363881358],
					[65.146697310991257, 6.4179970346067501],
					[65.146697302814431, 6.4179970290011257],
					[65.146697299155846, 6.4179970250897034],
					[65.143677904163539, 6.416280429490274],
					[65.14166401071094, 6.4149609350547863],
					[65.138305711990739, 6.4126776358559887],
					[65.138305708332013, 6.4126776319465364],
					[65.138305698362402, 6.4126776265188532],
					[65.138305692911075, 6.4126776227840088],
					[65.134269493437301, 6.4105665223933022],
					[65.130905705375383, 6.4088054297142394],
					[65.1286695171231, 6.4071970382377019],
					[65.128669480006423, 6.4071970205375735],
					[65.128669404311822, 6.4071970044692286],
					[65.128669363867715, 6.4071970020187816],
					[65.125358264706506, 6.4078109022172969],
					[65.122741688596832, 6.4082553993519431],
					[65.119225030970838, 6.4080525999650613],
					[65.117194451038301, 6.4075220064013418],
					[65.11719443031285, 6.4075220020284718],
					[65.117194388112836, 6.4075220018806478],
					[65.117194366601524, 6.4075220039780749],
					[65.114569395889049, 6.4079636973167808],
					[65.111855625930616, 6.4076054020907405],
					[65.111855621412261, 6.407605400400378],
					[65.11185561061977, 6.407605399321576],
					[65.111855606138249, 6.4076053997584177],
					[65.10924440621892, 6.407524801004997],
					[65.109244398115351, 6.4075247996642455],
					[65.109244382878174, 6.4075248011493819],
					[65.109244374811396, 6.4075248019356321],
					[65.10719997513543, 6.4077776027291753],
					[65.107199968824474, 6.4077776012137511],
					[65.107199957209303, 6.4077776044760615],
					[65.10719995093514, 6.4077776050874737],
					[65.104452750835762, 6.4084665051830516],
					[65.104452741013162, 6.4084665082701031],
					[65.104452719612198, 6.4084665167453982],
					[65.104452708930125, 6.4084665220463757],
					[65.101586008858987, 6.4099248229732586],
					[65.101585996421022, 6.4099248305739449],
					[65.101585970685477, 6.409924847988945],
					[65.101585959180582, 6.4099248576287744],
					[65.099485958832091, 6.4120109588061451],
					[65.099485944748338, 6.4120109750844936],
					[65.0994859203131, 6.4120110157974288],
					[65.09948590988806, 6.4120110359795692],
					[65.098388718308598, 6.4152805146615153],
					[65.097327630195309, 6.4169776949511474],
					[65.097327619769629, 6.4169777151296969],
					[65.097327606162111, 6.4169777590429655],
					[65.09732760115098, 6.4169777808255875],
					[65.096869201135434, 6.4219055810560537],
					[65.096869201135434, 6.4219055810560537],
					[65.096869201172041, 6.421905583182089],
					[65.096869201172041, 6.421905583182089],
					[65.096397001369596, 6.4276193840693709],
					[65.096397001406103, 6.4276193861953788],
					[65.096396999613475, 6.4276193863686455],
					[65.096047000141397, 6.432822186420518],
					[65.096047000177862, 6.432822188546524],
					[65.096047000250749, 6.4328221927984979],
					[65.0960470002872, 6.4328221949244861],
					[65.095963700342637, 6.4362249951481179],
					[65.095963700415425, 6.4362249994001113],
				],
			],
		},
		{
			fldNpdidField: "43506",
			wlbNpdidWellbore: "172",
			fldName: "EKOFISK",
			fldCurrentActivitySatus: "Producing",
			wlbName: "2/4-2",
			fldDiscoveryYear: "1969",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43506",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43506",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "018",
			cmpLongName: "ConocoPhillips Skandinavia AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=172",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "2410696",
			fldNpdidOwner: "20900",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Tananger",
			fldAreaGeometryWKT: [
				[
					[56.49356090063543, 3.230999985567732],
					[56.493560900645178, 3.2310000050939287],
					[56.493560906027646, 3.2310000433296131],
					[56.493560909612711, 3.231000062311328],
					[56.494633110427969, 3.2343111626235497],
					[56.49463311259192, 3.2343111704309302],
					[56.494633119676607, 3.2343111872534336],
					[56.494633123628233, 3.2343111947887171],
					[56.496924824317524, 3.2385611941974513],
					[56.496924830207, 3.2385612046937342],
					[56.49692484272942, 3.2385612223182982],
					[56.496924850406579, 3.2385612325426454],
					[56.499441549848818, 3.2414168324103452],
					[56.499441562964023, 3.2414168434366122],
					[56.499441590088317, 3.2414168653532482],
					[56.499441604991233, 3.2414168761077167],
					[56.503436004641841, 3.2435723764157953],
					[56.503436013805789, 3.2435723799059133],
					[56.503436032208818, 3.2435723885024603],
					[56.503436041297611, 3.2435723903763023],
					[56.510888840755726, 3.245875191746125],
					[56.510888841649574, 3.2458751916102675],
					[56.510888844331156, 3.2458751912026851],
					[56.510888846193971, 3.2458751925475817],
					[56.518108245140866, 3.2479140923051917],
					[56.526861043522203, 3.2504445913216009],
					[56.526861048066557, 3.250444592259738],
					[56.526861056261438, 3.2504445942718445],
					[56.526861059018088, 3.2504445954816652],
					[56.534413858590511, 3.2520112964885746],
					[56.534413867604165, 3.2520112967478427],
					[56.534413885706549, 3.2520112988840162],
					[56.534413894795279, 3.2520113007609357],
					[56.539158254069712, 3.2521251995576352],
					[56.545941617349456, 3.2552195819808309],
					[56.545941620106056, 3.2552195831915074],
					[56.545941627407032, 3.2552195853412149],
					[56.54594163016364, 3.2552195865519078],
					[56.551327729873982, 3.2572362876873724],
					[56.551327738962655, 3.2572362895661087],
					[56.551327759002724, 3.2572362946703719],
					[56.551327768091397, 3.2572362965491117],
					[56.557074967764606, 3.258158496864997],
					[56.557074982291468, 3.2581584995479353],
					[56.557075009257453, 3.2581584987109218],
					[56.557075022740442, 3.2581584982924165],
					[56.562819422867712, 3.2574945991590716],
					[56.562819432700223, 3.2574945976646776],
					[56.562819453109078, 3.2574945913023035],
					[56.56281946294159, 3.2574945898079117],
					[56.56844726274705, 3.2556168894411739],
					[56.572813961620291, 3.2541973895903213],
					[56.572813973165381, 3.2541973862032623],
					[56.572813994317706, 3.2541973764625354],
					[56.57281400481881, 3.2541973699728963],
					[56.576972304886617, 3.2516418692582878],
					[56.576972324919538, 3.2516418547926258],
					[56.57697236014063, 3.2516418184443525],
					[56.576972375328765, 3.2516417965617284],
					[56.578197374809626, 3.2494361974703367],
					[56.578197378903212, 3.2494361886922372],
					[56.578197386196564, 3.2494361712721256],
					[56.578197390290164, 3.2494361624940051],
					[56.579644589860898, 3.2450778629232775],
					[56.579644590604367, 3.245077859547949],
					[56.579644592909943, 3.2450778510415019],
					[56.579644593653406, 3.2450778476661735],
					[56.583997393811387, 3.2271917471653686],
					[56.583997395448186, 3.227191743652631],
					[56.583997396783261, 3.2271917336611673],
					[56.583997398344607, 3.2271917285287683],
					[56.584661298161471, 3.2226944282595857],
					[56.58466129882882, 3.2226944232637065],
					[56.584661300088044, 3.2226944116522196],
					[56.58466129978612, 3.2226944051734274],
					[56.584836299594016, 3.2150056043552411],
					[56.584836300412228, 3.2150056025985889],
					[56.58483630026106, 3.2150055993592099],
					[56.584836300185479, 3.2150055977395224],
					[56.584703000465062, 3.2041082983577964],
					[56.584703000313603, 3.204108295118504],
					[56.584703000086428, 3.2041082902595517],
					[56.584702999934976, 3.2041082870202611],
					[56.584097399694151, 3.1945916875747713],
					[56.584097398572837, 3.19459168285344],
					[56.584097398193592, 3.1945916747554612],
					[56.58409739796604, 3.194591669896667],
					[56.583039098185559, 3.1877193699331925],
					[56.583039093775241, 3.187719352668771],
					[56.583039083318965, 3.1877193216542139],
					[56.583039076227386, 3.1877193048026071],
					[56.580550175728185, 3.1830943052353478],
					[56.577502975857684, 3.1773832054734692],
					[56.57750297287221, 3.1773831994098631],
					[56.577502967053384, 3.1773831905211973],
					[56.577502963174183, 3.1773831845954104],
					[56.574661262975994, 3.1733526848130635],
					[56.574661253353632, 3.1733526716198712],
					[56.574661230686232, 3.173352649023375],
					[56.574661217641179, 3.1733526396200409],
					[56.572005717634845, 3.1714026397007955],
					[56.572005700938639, 3.1714026292314581],
					[56.572005665911021, 3.1714026118066974],
					[56.572005646762051, 3.1714026066081957],
					[56.568808347020841, 3.17062200670907],
					[56.568808318040851, 3.1706220030285648],
					[56.568808259644044, 3.1706220055187235],
					[56.568808231120954, 3.1706220115514556],
					[56.565527731338726, 3.1718276113370387],
					[56.565527726052473, 3.171827613783099],
					[56.56552771458626, 3.1718276188131043],
					[56.565527709300014, 3.1718276212591787],
					[56.560924910135874, 3.1741665206579235],
					[56.556599931882445, 3.1763304107029136],
					[56.551738854282263, 3.1774692048907394],
					[56.551738851601037, 3.1774692053037881],
					[56.551738848102126, 3.1774692074726798],
					[56.551738846314656, 3.177469207748044],
					[56.547836046072881, 3.1785692062810389],
					[56.54783603728751, 3.1785692108933987],
					[56.547836018747013, 3.1785692186377696],
					[56.547836009885614, 3.1785692216321322],
					[56.544608209829732, 3.1801998211219904],
					[56.54460819583381, 3.1801998297947645],
					[56.544608169705484, 3.1801998484830136],
					[56.544608156755316, 3.1801998602539339],
					[56.541469261657745, 3.1833970554190572],
					[56.537583188329108, 3.1868665326662744],
					[56.529491612214578, 3.1908054197282887],
					[56.529491607003692, 3.1908054237863084],
					[56.52949159471855, 3.1908054305596112],
					[56.529491590325577, 3.1908054328631255],
					[56.521308190403424, 3.1962137334082006],
					[56.521308183404777, 3.1962137377387134],
					[56.521308171270796, 3.1962137477424668],
					[56.521308165241713, 3.1962137535528137],
					[56.512897065332595, 3.2039165524650124],
					[56.512897061833115, 3.2039165546290262],
					[56.512897056697376, 3.2039165602999224],
					[56.51289705498548, 3.2039165621902326],
					[56.508216555331991, 3.2088748634246067],
					[56.50821653115068, 3.208874904566593],
					[56.508216506404729, 3.2088749913779284],
					[56.508216504128086, 3.2088750389371086],
					[56.508260885066299, 3.2090999439937753],
					[56.505649848413377, 3.2121554702402668],
					[56.505649846701331, 3.2121554721298176],
					[56.505649844171039, 3.2121554757723048],
					[56.505649842458993, 3.2121554776618555],
					[56.500608141881976, 3.2186220773168857],
					[56.500608140988142, 3.2186220774533347],
					[56.500608139351456, 3.2186220809584491],
					[56.500608137639304, 3.2186220828474457],
					[56.495799838174484, 3.2252192826639434],
					[56.495799837356032, 3.2252192844161276],
					[56.495799834749924, 3.2252192864408658],
					[56.495799833931478, 3.2252192881930499],
					[56.493763733664103, 3.2282498881555877],
					[56.49376372220518, 3.2282499126839732],
					[56.493763706437953, 3.2282499606512398],
					[56.493763701235814, 3.2282499842263093],
					[56.49356090063543, 3.230999985567732],
				],
			],
		},
		{
			fldNpdidField: "34833011",
			wlbNpdidWellbore: "7098",
			fldName: "SOLVEIG",
			fldCurrentActivitySatus: "Producing",
			wlbName: "16/4-6 S",
			fldDiscoveryYear: "2013",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=34833011",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=34833011",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "359",
			cmpLongName: "ABP Norway AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=7098",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "50000045",
			fldNpdidOwner: "3810373",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Tananger",
			fldAreaGeometryWKT: [
				[
					[58.642355400084341, 2.299613902633951],
					[58.642355400710706, 2.2996139146101462],
					[58.642469200564172, 2.3011778151361448],
					[58.642469212612035, 2.3011778560209719],
					[58.64246925525962, 2.3011779307652951],
					[58.64246928398601, 2.3011779632570728],
					[58.643249856066802, 2.3017362423796039],
					[58.644816535915488, 2.3039751155685533],
					[58.644816540732918, 2.30397512155493],
					[58.644816553133062, 2.3039751347240705],
					[58.644816558842436, 2.3039751405389044],
					[58.646269242183877, 2.3054279242466253],
					[58.648060926102666, 2.3083361996617633],
					[58.649960923438144, 2.3119139940095708],
					[58.649960926382001, 2.3119139986293367],
					[58.649960933250995, 2.3119140094088189],
					[58.649960936194866, 2.3119140140286021],
					[58.652533125996243, 2.3156056995700944],
					[58.654994219275444, 2.3208611857912564],
					[58.654994221237807, 2.3208611888720667],
					[58.654994226232972, 2.3208611982856291],
					[58.654994228195321, 2.3208612013664234],
					[58.656783128414368, 2.3238807027362069],
					[58.656783134212105, 2.3238807102678822],
					[58.656783146877942, 2.3238807285835175],
					[58.656783153656846, 2.3238807376557666],
					[58.658460948285324, 2.3256695306002575],
					[58.660138743124186, 2.3277945236409909],
					[58.66013876123057, 2.3277945426453681],
					[58.660138800565662, 2.3277945714107182],
					[58.660138821972645, 2.3277945845953862],
					[58.662263821583323, 2.3286890845152044],
					[58.662263845309724, 2.3286890903402044],
					[58.662263890711266, 2.3286890971963814],
					[58.662263914259455, 2.3286890995974625],
					[58.663827813739871, 2.3285779997476435],
					[58.663827842283546, 2.3285779942742639],
					[58.663827894465378, 2.3285779756225291],
					[58.663827918014405, 2.3285779607321406],
					[58.665505718278531, 2.3273473602465908],
					[58.665505733799485, 2.327347346894356],
					[58.665505762700931, 2.3273473136835756],
					[58.665505776170541, 2.3273472955371513],
					[58.666847375997861, 2.3248861959734257],
					[58.666847378406331, 2.324886190323423],
					[58.666847383312373, 2.3248861807356107],
					[58.666847385631648, 2.3248861733734589],
					[58.667741886209249, 2.3226500743105292],
					[58.667741887636424, 2.3226500671192407],
					[58.667741893255894, 2.3226500539352379],
					[58.667741893791067, 2.3226500469151761],
					[58.668414094479793, 2.3198556477463281],
					[58.668414095906805, 2.3198556405548181],
					[58.668414097779603, 2.3198556246308288],
					[58.668414099206601, 2.3198556174393019],
					[58.668750197045242, 2.3161639472894571],
					[58.669197385794483, 2.3150444740881801],
					[58.669197387399812, 2.3150444703208137],
					[58.669197389005141, 2.3150444665534629],
					[58.669197389718512, 2.3150444629575331],
					[58.669644583047827, 2.3137028826484434],
					[58.670761272191697, 2.3118029001144991],
					[58.670761276383438, 2.3118028941199475],
					[58.670761282091071, 2.3118028826454098],
					[58.670761285301538, 2.3118028751100708],
					[58.671544584948954, 2.3099000777280891],
					[58.672664085389201, 2.3072167773286347],
					[58.672664086102323, 2.3072167737322027],
					[58.672664087617996, 2.3072167682517102],
					[58.672664089312498, 2.307216766196023],
					[58.674005789350161, 2.3034139677393064],
					[58.674005790686756, 2.3034139588335534],
					[58.674005796035765, 2.3034139405067822],
					[58.67400579826429, 2.3034139314292759],
					[58.674341897003927, 2.3012889344396705],
					[58.674900197364998, 2.2982694352461577],
					[58.674900197364998, 2.2982694352461577],
					[58.674900197185927, 2.2982694318212156],
					[58.674900197988343, 2.2982694299368487],
					[58.675347395941436, 2.2953639398670629],
					[58.676019591848728, 2.2926778561081176],
					[58.67713629046694, 2.289325063394215],
					[58.677136290377291, 2.2893250616816654],
					[58.677136291269193, 2.289325061509532],
					[58.677919590802873, 2.2868639601907512],
					[58.677919593742722, 2.2868639475138517],
					[58.677919597838638, 2.2868639225044856],
					[58.677919599886572, 2.2868639099998056],
					[58.678030798874254, 2.2846278157454001],
					[58.678255796373534, 2.282502850829609],
					[58.67892518009068, 2.2809361878818017],
					[58.680044571437925, 2.2789223054872947],
					[58.682394555048532, 2.2754557283292152],
					[58.683958441363323, 2.2738918409904447],
					[58.683958443147091, 2.2738918406452946],
					[58.683958447426797, 2.273891836356746],
					[58.683958449120709, 2.2738918342987664],
					[58.684966848961551, 2.2727723343468051],
					[58.684966864828837, 2.2727723105126523],
					[58.684966889068811, 2.2727722573737417],
					[58.684966898423298, 2.272772229609247],
					[58.685189097937851, 2.2713194294097092],
					[58.685189097031802, 2.2713193949793853],
					[58.685189082733345, 2.271319328535784],
					[58.685189071124718, 2.2713192961772495],
					[58.683958473957276, 2.2693055008789007],
					[58.682727976703696, 2.2669555065000098],
					[58.682727976703696, 2.2669555065000098],
					[58.681722377447535, 2.2650555062741402],
					[58.681722372628258, 2.2650555002873105],
					[58.681722363791643, 2.2650554864281429],
					[58.681722359864246, 2.2650554802685128],
					[58.680380789330421, 2.2632666185425614],
					[58.680269620165781, 2.2625945052416032],
					[58.682058388992864, 2.2615890799804199],
					[58.685188979285073, 2.2602473844477617],
					[58.685188982762483, 2.2602473820431723],
					[58.68518899328474, 2.2602473765423046],
					[58.685188996852176, 2.2602473758505792],
					[58.687202897482649, 2.259127975625101],
					[58.687202906131063, 2.2591279687565309],
					[58.687202922626085, 2.2591279569053255],
					[58.687202932256419, 2.2591279515767355],
					[58.688991832291599, 2.2575640512548292],
					[58.68899183389513, 2.2575640474827421],
					[58.688991839156188, 2.2575640447315655],
					[58.688991840759726, 2.2575640409594588],
					[58.691786240570899, 2.2547668412131991],
					[58.691786249128917, 2.2547668326294286],
					[58.691786262587435, 2.2547668144412261],
					[58.691786270253601, 2.2547668060305734],
					[58.693464048173055, 2.2520834406122066],
					[58.695366780111463, 2.2507418882181978],
					[58.697713918596797, 2.2502946005408084],
					[58.699616699988724, 2.2502946007668347],
					[58.699616705339771, 2.2502945997271295],
					[58.69961671434838, 2.2502945997078014],
					[58.699616718807576, 2.2502945988413825],
					[58.701963918763482, 2.2500695989387234],
					[58.701963923312874, 2.2500695997858404],
					[58.701963931249246, 2.250069596512442],
					[58.701963935798652, 2.2500695973595581],
					[58.703194436227982, 2.2498473962061327],
					[58.703194474126107, 2.2498473801849141],
					[58.703194542066036, 2.2498473358194917],
					[58.703194570143772, 2.2498473043945668],
					[58.704089061611199, 2.2483918186637211],
					[58.70543073897808, 2.2468279450298176],
					[58.707555713223613, 2.2451501652611636],
					[58.709458400781379, 2.2440307713596752],
					[58.7094584121946, 2.2440307656760781],
					[58.709458432074669, 2.2440307496874694],
					[58.709458442415489, 2.2440307407494111],
					[58.710686242261225, 2.2428001401715885],
					[58.710686251619812, 2.2428001296923488],
					[58.710686270957908, 2.2428001034182237],
					[58.710686279244065, 2.2428000896844726],
					[58.711469579404515, 2.2412361907537401],
					[58.711469579314198, 2.2412361890396739],
					[58.712364079365969, 2.2394444890692924],
					[58.712364080167454, 2.239444487181538],
					[58.712364082571874, 2.2394444815182668],
					[58.712364084265168, 2.2394444794568709],
					[58.713036284418514, 2.2378806792648178],
					[58.71303629152932, 2.2378806432452216],
					[58.713036295049122, 2.2378805732903539],
					[58.713036290385602, 2.2378805361005041],
					[58.712589090345254, 2.2365388368901571],
					[58.712589088290464, 2.2365388320952766],
					[58.712589084180891, 2.2365388225055369],
					[58.712589082126129, 2.2365388177106693],
					[58.71203078228163, 2.2353082161169091],
					[58.712030780317193, 2.2353082130362703],
					[58.712030776297951, 2.2353082051609028],
					[58.712030774333527, 2.2353082020802617],
					[58.711022373903674, 2.2335193022066373],
					[58.711022370956968, 2.2335192975859632],
					[58.711022364973147, 2.23351928663058],
					[58.711022361134638, 2.2335192821836789],
					[58.709791860830975, 2.2318415828482712],
					[58.709791849224857, 2.2318415677946639],
					[58.709791821824922, 2.2318415436984025],
					[58.70979180763382, 2.2318415308802031],
					[58.708564073377794, 2.2310581731530741],
					[58.70789189345826, 2.2285971475925268],
					[58.707891888184889, 2.2285971330373835],
					[58.707891875854536, 2.2285971042748574],
					[58.707891867996224, 2.2285970919552476],
					[58.706886267930486, 2.2270304926865778],
					[58.706886266857687, 2.2270304894329267],
					[58.706886264001284, 2.2270304865271346],
					[58.706886262928464, 2.2270304832734662],
					[58.705766872625738, 2.2254665974839849],
					[58.70464738722093, 2.2231166275558394],
					[58.704200191242151, 2.2216638415687813],
					[58.704200191151571, 2.2216638398551272],
					[58.70364189126267, 2.2198749393249169],
					[58.703641885096594, 2.2198749249469718],
					[58.703641870089037, 2.2198748967133741],
					[58.703641862139392, 2.2198748826836079],
					[58.702747365135636, 2.2186442869320082],
					[58.701741867742506, 2.21707769270771],
					[58.701741859883185, 2.2170776803928467],
					[58.701741844436491, 2.2170776609036493],
					[58.701741834083116, 2.2170776525382476],
					[58.700511234343466, 2.2159609519462511],
					[58.700511222115829, 2.2159609422163373],
					[58.700511195075848, 2.2159609249924825],
					[58.700511181155292, 2.2159609173243582],
					[58.698497305304329, 2.2150665276888257],
					[58.697377945028741, 2.2141692605984993],
					[58.696372369142395, 2.2128276920933687],
					[58.695589076169988, 2.2113749043772426],
					[58.695589068400906, 2.2113748937789808],
					[58.695589050897794, 2.2113748695045836],
					[58.695589041345166, 2.2113748592548261],
					[58.694694540711275, 2.2104804594582554],
					[58.69469453303283, 2.2104804505735922],
					[58.694694516164574, 2.2104804382922079],
					[58.694694506793319, 2.2104804314692075],
					[58.693464016413031, 2.2096970364445339],
					[58.691900130140404, 2.20846654790282],
					[58.690111262752922, 2.2067887795774443],
					[58.688766890271651, 2.2041055321771315],
					[58.688433508356958, 2.2025389146113983],
					[58.68876688430808, 2.2016444804136421],
					[58.689327963735636, 2.2005279184868032],
					[58.690111237890086, 2.1997445433127565],
					[58.6914529196386, 2.1985140609500684],
					[58.69201120341863, 2.1981779704638416],
					[58.692011235140988, 2.1981779400235388],
					[58.692011282608107, 2.19817786669392],
					[58.692011298443759, 2.1981778255175355],
					[58.69212239617422, 2.1972833441744406],
					[58.692458462624018, 2.1962779418545364],
					[58.693130646662155, 2.1959419006359759],
					[58.693688885716597, 2.1959419007556309],
					[58.694472127395116, 2.1960529916933371],
					[58.695477688601564, 2.1967251663558667],
					[58.695477696099104, 2.1967251718113703],
					[58.69547770992974, 2.1967251777576262],
					[58.695477717336388, 2.1967251814999909],
					[58.696708217391141, 2.1972834822720744],
					[58.696708232022594, 2.1972834863309259],
					[58.696708261376429, 2.1972834961618144],
					[58.696708275917018, 2.1972834985074607],
					[58.698608275871891, 2.1975084988570464],
					[58.698608285771961, 2.1975084986494382],
					[58.698608303879524, 2.1975085002967658],
					[58.698608312887849, 2.1975085002637842],
					[58.700397212645989, 2.1973945996420801],
					[58.700397231463484, 2.1973945976879814],
					[58.700397266059689, 2.1973945874502436],
					[58.700397282911922, 2.1973945824186925],
					[58.701627883030255, 2.1968362822585847],
					[58.701627901393238, 2.1968362717369296],
					[58.701627934279507, 2.1968362462520044],
					[58.701627948893702, 2.1968362330021618],
					[58.70252234935014, 2.1958307327771598],
					[58.702522356921207, 2.1958307226375626],
					[58.702522371972456, 2.1958307006448994],
					[58.7025223786518, 2.1958306906799958],
					[58.703083478862382, 2.194711189446036],
					[58.703083479663192, 2.1947111875578171],
					[58.703083482065594, 2.1947111818931728],
					[58.703083482866404, 2.1947111800049401],
					[58.704200178595009, 2.1921389898323098],
					[58.705655763163442, 2.1895668171769844],
					[58.707555747414602, 2.1874418355261578],
					[58.7095695301334, 2.1853168531803577],
					[58.712030683662633, 2.1836390859231072],
					[58.714602850222064, 2.1829695944198635],
					[58.714602856372949, 2.1829695914800995],
					[58.714602866181821, 2.1829695895539207],
					[58.714602872332719, 2.1829695866141572],
					[58.716055646071645, 2.1824112963167615],
					[58.717029958993351, 2.1823211103153501],
					[58.716849801214913, 2.1842638823044416],
					[58.716849800069056, 2.1842638946535415],
					[58.716849801344246, 2.1842639186513444],
					[58.716849801981816, 2.1842639306502498],
					[58.717349800638495, 2.1875500246545303],
					[58.717574799728361, 2.1900222125616824],
					[58.717685900341102, 2.1936639054246365],
					[58.717685905363389, 2.1936639321524884],
					[58.717685919866668, 2.1936639847339072],
					[58.717685931931875, 2.1936640083486973],
					[58.718855432187837, 2.1954862073244419],
					[58.718855449603183, 2.1954862298917703],
					[58.718855492987466, 2.1954862664211832],
					[58.71885551726389, 2.1954862824471642],
					[58.720677717186469, 2.1963168819443495],
					[58.72067776990751, 2.1963168906634594],
					[58.720677872821142, 2.1963168774179964],
					[58.720677923104688, 2.1963168571677634],
					[58.723150119573624, 2.1943973601921085],
					[58.725819517170663, 2.1924668627901207],
					[58.72581953178419, 2.1924668495285142],
					[58.725819558245071, 2.1924668218155245],
					[58.72581957000142, 2.1924668056495356],
					[58.728030738874217, 2.188936255846353],
					[58.731166787055798, 2.1874251792689723],
					[58.731166787947529, 2.1874251790938612],
					[58.731166790622694, 2.1874251785685397],
					[58.731166792315079, 2.187425176503484],
					[58.735308392252954, 2.1852695770432686],
					[58.73530839572873, 2.1852695746274655],
					[58.735308403663083, 2.1852695713357075],
					[58.735308408030569, 2.1852695687447157],
					[58.741177907688304, 2.1814890683782306],
					[58.741177912055711, 2.1814890657862565],
					[58.741177918915945, 2.1814890592376894],
					[58.741177923374472, 2.1814890583610511],
					[58.744758422878412, 2.1787001583831365],
					[58.744758424570648, 2.1787001563168071],
					[58.744758428046268, 2.1787001538996305],
					[58.744758428846801, 2.1787001520087252],
					[58.747508429268287, 2.1763640518399479],
					[58.747508435327738, 2.1763640471802144],
					[58.747508446463726, 2.1763640363206229],
					[58.747508452431944, 2.1763640299452662],
					[58.751711251600682, 2.1714695294647441],
					[58.751711265046097, 2.1714695112120284],
					[58.751711286221656, 2.1714694688973539],
					[58.751711294934772, 2.1714694463755335],
					[58.753630794784748, 2.1634528464926959],
					[58.753630796728615, 2.1634528322381508],
					[58.753630798650164, 2.163452800649083],
					[58.753630799702343, 2.163452786570403],
					[58.753355799625432, 2.1594777866135635],
					[58.753355774841836, 2.159477727351006],
					[58.753355696055451, 2.1594776353991212],
					[58.753355641252419, 2.1594776046016069],
					[58.751761141341291, 2.1591470034332412],
					[58.751761135008188, 2.1591470029492985],
					[58.751761124125309, 2.1591470016294685],
					[58.751761117792199, 2.1591470011455103],
					[58.748144433406388, 2.158822001393828],
					[58.745444484187182, 2.1581609142707694],
					[58.742952915137643, 2.1564081362931855],
					[58.742952903980296, 2.1564081298284052],
					[58.742952881757112, 2.1564081186141513],
					[58.742952870691262, 2.1564081138646811],
					[58.739555670628754, 2.1551276136069273],
					[58.739555646805165, 2.1551276061767046],
					[58.739555600598599, 2.1551276014311522],
					[58.73955557714099, 2.1551276008615017],
					[58.737252860579737, 2.1553969904416959],
					[58.735041826918192, 2.1535776466548495],
					[58.73504182120223, 2.153577640851204],
					[58.735041809061634, 2.1535776328497214],
					[58.735041803437149, 2.153577628760976],
					[58.732969508087358, 2.1523331325199595],
					[58.731500112619131, 2.1513304346008],
					[58.731500093162126, 2.1513304245796485],
					[58.731500049972901, 2.1513304088469529],
					[58.73150002802403, 2.1513304027833517],
					[58.729033328198135, 2.1509804024639005],
					[58.729033279774264, 2.1509804068254135],
					[58.729033191432791, 2.1509804398594086],
					[58.729033149640394, 2.1509804671693082],
					[58.726297064353219, 2.1540720516747069],
					[58.724557114625817, 2.1553510193155314],
					[58.724555796174229, 2.1553443617155139],
					[58.724555782570128, 2.155344326285086],
					[58.724555744227764, 2.1553442662826994],
					[58.724555720289644, 2.1553442398204807],
					[58.724108420411895, 2.1550109406466706],
					[58.724108412021018, 2.1550109353721298],
					[58.724108397022604, 2.1550109244712909],
					[58.724108388723202, 2.1550109209111299],
					[58.723213989321835, 2.1545637205566956],
					[58.723213985663712, 2.1545637195459331],
					[58.72321397825597, 2.1545637158100654],
					[58.723213973614691, 2.1545637132608659],
					[58.722094476019073, 2.1541165153122206],
					[58.720752876569591, 2.1535554147668408],
					[58.720752872911454, 2.1535554137562243],
					[58.720752864703556, 2.1535554119109221],
					[58.720752860953908, 2.1535554091861169],
					[58.718291761076479, 2.1527720087162021],
					[58.718291745643789, 2.1527720065643288],
					[58.718291714686892, 2.1527720005464741],
					[58.718291700237351, 2.1527719999327837],
					[58.716727800415221, 2.1527719995212191],
					[58.714825000367107, 2.1527719992545569],
					[58.713147236377921, 2.1527719990635226],
					[58.712252905855799, 2.1524387259377713],
					[58.711022335097688, 2.1513192521548441],
					[58.711022323848731, 2.1513192439831967],
					[58.711022301350759, 2.1513192276399047],
					[58.711022290193306, 2.1513192211819807],
					[58.709458390018973, 2.1505359211505861],
					[58.709458359770622, 2.1505359115325233],
					[58.709458298039785, 2.1505359029300397],
					[58.70945826744893, 2.1505359037697498],
					[58.708786066802467, 2.1506498035356185],
					[58.708786049861018, 2.1506498068775959],
					[58.708786017144355, 2.1506498185264848],
					[58.708786002260787, 2.1506498266575211],
					[58.706213802198178, 2.1521026252485975],
					[58.706213789989462, 2.1521026328508044],
					[58.706213769321671, 2.1521026507788794],
					[58.706213758987786, 2.1521026597428952],
					[58.704535958933377, 2.1537804589265108],
					[58.704535948690832, 2.1537804696029994],
					[58.704535931955348, 2.1537804936796614],
					[58.704535924479266, 2.1537805055422119],
					[58.703752624209059, 2.1552332044740461],
					[58.703752620208007, 2.1552332139196695],
					[58.703752612205967, 2.1552332328109065],
					[58.703752609005114, 2.155233240367409],
					[58.703305428939935, 2.156688776061388],
					[58.702522096170682, 2.1572470278222307],
					[58.701852709919784, 2.157583120829794],
					[58.701852689959935, 2.1575831351499528],
					[58.701852652898111, 2.1575831666898693],
					[58.701852635887555, 2.1575831856229208],
					[58.701069252695532, 2.1586998612619137],
					[58.700063777259395, 2.1594831413957554],
					[58.70006376781658, 2.1594831501803098],
					[58.700063749822633, 2.1594831675737916],
					[58.700063742254564, 2.1594831777203405],
					[58.699280458586813, 2.1604887545914013],
					[58.698049881678813, 2.1613859381474416],
					[58.698049868760513, 2.1613859493462391],
					[58.698049844890022, 2.1613859748189905],
					[58.698049833937816, 2.1613859890929432],
					[58.697377633617506, 2.1623915891616314],
					[58.697377631125185, 2.1623915931143554],
					[58.697377626231891, 2.1623916027329209],
					[58.69737762373957, 2.1623916066856506],
					[58.696483133807384, 2.1640692868357161],
					[58.695255447324513, 2.165522071100948],
					[58.695255434862382, 2.1655220908622526],
					[58.695255414396534, 2.1655221295078015],
					[58.695255405592455, 2.1655221502804443],
					[58.694919206298508, 2.1668638510980363],
					[58.694919204697733, 2.1668638548747929],
					[58.69491920398864, 2.1668638584761517],
					[58.69491920407993, 2.1668638601891383],
					[58.694583104081644, 2.168541661296028],
					[58.694583103281239, 2.1685416631843419],
					[58.69458310177167, 2.1685416686739689],
					[58.694583101954223, 2.16854167209992],
					[58.694358104144825, 2.1701082572588204],
					[58.693910908841694, 2.1716749393774504],
					[58.693241515677251, 2.1735749217650895],
					[58.69256922084854, 2.1749166094165235],
					[58.692569219429885, 2.1749166166184635],
					[58.692569213643893, 2.1749166264091162],
					[58.692569212042841, 2.1749166301852427],
					[58.691338714976439, 2.1782721223844219],
					[58.689885918827848, 2.1814027155462874],
					[58.689885918027201, 2.1814027174340773],
					[58.68988591722659, 2.1814027193218468],
					[58.68988591722659, 2.1814027193218468],
					[58.687985920399711, 2.1857638138013162],
					[58.686755423098582, 2.1881138076218889],
					[58.686755419713684, 2.1881138117463701],
					[58.686755415801066, 2.1881138228962858],
					[58.686755414199624, 2.1881138266711808],
					[58.685747014875368, 2.1906860231993277],
					[58.684405423031457, 2.193816606145286],
					[58.68339985719247, 2.195383152497516],
					[58.682841596919658, 2.1957192289379477],
					[58.682841589967509, 2.1957192337594709],
					[58.682841576954971, 2.1957192432279311],
					[58.682841570002829, 2.1957192480494387],
					[58.682058170166847, 2.1963887478796309],
					[58.682058150656538, 2.196388770731192],
					[58.682058119843369, 2.1963888182883857],
					[58.682058107739657, 2.1963888448810072],
					[58.681274808190189, 2.199072143352899],
					[58.681274807389286, 2.1990721452398008],
					[58.68127480587836, 2.1990721507260318],
					[58.68127480587836, 2.1990721507260318],
					[58.680602609317077, 2.2017582401072633],
					[58.679708122093153, 2.2042166031950496],
					[58.67836654047111, 2.2060054805239],
					[58.678366537085566, 2.2060054846456136],
					[58.678366531206272, 2.2060054927147816],
					[58.678366527820721, 2.2060054968364966],
					[58.677024828195485, 2.2082442969667646],
					[58.677024828286207, 2.2082442986790216],
					[58.677024825792351, 2.2082443026261527],
					[58.677024824900585, 2.2082443028003564],
					[58.675235931803378, 2.2114859906642192],
					[58.673558140192753, 2.2137220795693922],
					[58.673558137608069, 2.2137220818036401],
					[58.673558135204679, 2.2137220874620849],
					[58.673558134403557, 2.2137220893482308],
					[58.672438733796291, 2.2153998885266457],
					[58.672438728006966, 2.2153998983048808],
					[58.672438718211879, 2.2153999175133778],
					[58.672438714206123, 2.2153999269436193],
					[58.671433114746407, 2.2179721235551617],
					[58.670538717048821, 2.2199860196416026],
					[58.670538714645147, 2.2199860252991437],
					[58.670538709928358, 2.2199860383261911],
					[58.670538706723462, 2.2199860458695713],
					[58.669755406912465, 2.2227832458591217],
					[58.669755404689731, 2.2227832549402495],
					[58.669755402118341, 2.2227832744669],
					[58.66975540078738, 2.2227832833742518],
					[58.66941920165754, 2.2266971733726204],
					[58.668749805058674, 2.2302749546211942],
					[58.668077611291544, 2.2326221340642474],
					[58.667072023114031, 2.2350832037797277],
					[58.666066535220416, 2.2365387852190013],
					[58.66606653201449, 2.2365387927599305],
					[58.666066525421975, 2.2365388044181298],
					[58.666066521233901, 2.2365388104206447],
					[58.665283125271195, 2.2381027041279018],
					[58.663941528282145, 2.2403387965758697],
					[58.663941526588779, 2.2403387986342067],
					[58.663941524985717, 2.240338802404255],
					[58.663941524184175, 2.2403388042892831],
					[58.663158124332938, 2.2417943045040993],
					[58.663158121837974, 2.2417943084472549],
					[58.663158118541453, 2.2417943142753778],
					[58.663158117028608, 2.2417943197569734],
					[58.662263717090141, 2.2438082188339235],
					[58.662263714685324, 2.243808224488502],
					[58.66226370996602, 2.2438082375093282],
					[58.662263707651491, 2.2438082448755705],
					[58.661369208441783, 2.2469388428427211],
					[58.660808110807793, 2.2487277354210318],
					[58.660024814246384, 2.250852726658009],
					[58.658908115157907, 2.2535360238285689],
					[58.658908111239285, 2.2535360349623956],
					[58.65890810527587, 2.2535360585957234],
					[58.658908102249107, 2.25353606955664],
					[58.658572002883979, 2.2557721638089818],
					[58.65801371322452, 2.258233219205743],
					[58.656672054791478, 2.2602470572121995],
					[58.655216586283203, 2.2612526345826192],
					[58.655216582003881, 2.2612526388688861],
					[58.655216573355183, 2.2612526457300661],
					[58.655216568183988, 2.261252650189038],
					[58.654322067832418, 2.2620359501064913],
					[58.654322048399322, 2.262035974614125],
					[58.654322015244318, 2.2620360294386876],
					[58.654322004287962, 2.2620360609489483],
					[58.653985903701319, 2.2637138616603476],
					[58.653985902899407, 2.2637138635443055],
					[58.653985902367403, 2.2637138705622539],
					[58.653985901655467, 2.2637138741575398],
					[58.65376370529335, 2.2653916494451907],
					[58.653316513841226, 2.2665082251834381],
					[58.653316510011365, 2.2665082380253514],
					[58.653316504135368, 2.266508263364087],
					[58.653316501197374, 2.266508276033445],
					[58.652869201637799, 2.270199975520732],
					[58.652644209022704, 2.2718777216651111],
					[58.651638752728367, 2.273219262882308],
					[58.650855470307349, 2.2738915483643236],
					[58.650855458359835, 2.273891561044505],
					[58.650855436338453, 2.2738915877713701],
					[58.650855426264556, 2.2738916018180744],
					[58.649849825828419, 2.2756805019521003],
					[58.649849820124004, 2.2756805134251969],
					[58.649849809786652, 2.2756805396214412],
					[58.649849805955803, 2.2756805524611106],
					[58.649177605659347, 2.278474952380416],
					[58.649177604946992, 2.2784749559749389],
					[58.649177604234609, 2.2784749595694476],
					[58.64917760441417, 2.2784749629917282],
					[58.648730403885274, 2.2808249628908666],
					[58.648730403172827, 2.280824966485286],
					[58.648730401747976, 2.2808249736741333],
					[58.648730401035536, 2.2808249772685669],
					[58.64828310302368, 2.2848499589595148],
					[58.647388714154808, 2.2877582228985709],
					[58.646269224063701, 2.2898832046914777],
					[58.645152625175484, 2.2918971035109488],
					[58.645152624373139, 2.2918971053937955],
					[58.64515262187652, 2.2918971093313583],
					[58.645152621074168, 2.2918971112142059],
					[58.643810920644533, 2.2945805112917128],
					[58.643810918950223, 2.2945805133461992],
					[58.643810915830251, 2.294580522588002],
					[58.643810914225483, 2.294580526353426],
					[58.642691513800209, 2.2973749257979126],
					[58.642691510769531, 2.2973749367500642],
					[58.642691504708161, 2.2973749586543719],
					[58.642691501677483, 2.2973749696065386],
					[58.642355401951988, 2.2996138694405581],
					[58.642355400794457, 2.2996138817600471],
					[58.642355400084341, 2.299613902633951],
				],
			],
		},
		{
			fldNpdidField: "18212090",
			wlbNpdidWellbore: "1840",
			fldName: "MARULK",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6507/2-2",
			fldDiscoveryYear: "1992",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=18212090",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=18212090",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "122",
			cmpLongName: "Vår Energi ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=1840",
			fldHcType: "GAS",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "50000032",
			fldNpdidOwner: "21788",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Sandnessjøen",
			fldAreaGeometryWKT: [
				[
					[65.900144199853202, 7.5025360935573646],
					[65.900144200002998, 7.5025361089143434],
					[65.900144200985451, 7.5025361176374155],
					[65.900199800116837, 7.5031639131294821],
					[65.900213699816675, 7.5034806093197508],
					[65.900213699816675, 7.5034806093197508],
					[65.90025259993088, 7.5042583029777008],
					[65.900247000125304, 7.5045166956138907],
					[65.900247000146678, 7.5045166978077527],
					[65.900247000189424, 7.5045167021954757],
					[65.900247000232156, 7.504516706583221],
					[65.900271999613935, 7.5053528035322712],
					[65.900272000073997, 7.5055805991840261],
					[65.900272000116715, 7.5055806035717572],
					[65.900272000159418, 7.5055806079594882],
					[65.900272000180792, 7.5055806101533724],
					[65.900316500069366, 7.5064500102721858],
					[65.900316500069366, 7.5064500102721858],
					[65.90031650009071, 7.5064500124660771],
					[65.900316500112055, 7.5064500146599453],
					[65.900324800354781, 7.5065722142932607],
					[65.90032480127293, 7.5065722164349218],
					[65.900324801336964, 7.506572223016553],
					[65.900324802255156, 7.5065722251582132],
					[65.90037760235974, 7.5069806267395931],
					[65.90037760235974, 7.5069806267395931],
					[65.900435901895094, 7.5074056277939718],
					[65.900435902834573, 7.5074056321295721],
					[65.900435903816728, 7.5074056408529337],
					[65.900435904756193, 7.5074056451885145],
					[65.900466504980272, 7.5075389441178579],
					[65.900466505001603, 7.507538946311743],
					[65.900466505898393, 7.507538946259567],
					[65.900602605982513, 7.5081056474101375],
					[65.900602606922007, 7.5081056517457698],
					[65.900602608800952, 7.5081056604170575],
					[65.900602610637236, 7.5081056647005324],
					[65.9007776106845, 7.5086167657749217],
					[65.900808111431644, 7.5087056656294928],
					[65.900808113267928, 7.5087056699130468],
					[65.900808117879947, 7.5087056828158607],
					[65.900808120634338, 7.5087056892412125],
					[65.900844220886825, 7.5087778900918645],
					[65.900844221783643, 7.5087778900397293],
					[65.900844222723094, 7.5087778943754486],
					[65.900844223619899, 7.5087778943233143],
					[65.90106092368282, 7.5091778958510167],
					[65.901060924600941, 7.5091778979928323],
					[65.901060927312727, 7.5091779000304228],
					[65.901060928230876, 7.5091779021722358],
					[65.901299826710115, 7.5095833993580241],
					[65.901324817677676, 7.5096278840687472],
					[65.901344211791155, 7.5096806692109874],
					[65.901344213627411, 7.5096806734946995],
					[65.901344217299908, 7.5096806820621422],
					[65.901344219114861, 7.5096806841518937],
					[65.90156091575669, 7.5101444788817426],
					[65.901760910128786, 7.5106667641885343],
					[65.901785907784046, 7.5107528567160253],
					[65.90178590870218, 7.5107528588579555],
					[65.901785909620259, 7.5107528609998866],
					[65.901785911435184, 7.5107528630897518],
					[65.901888710848908, 7.5110556650494154],
					[65.902152611092134, 7.5118306631877712],
					[65.902152611113394, 7.5118306653818232],
					[65.902183110741134, 7.5119194640191331],
					[65.902222011247929, 7.5120333636544743],
					[65.90238590777146, 7.5125222547208264],
					[65.902477604573804, 7.5129083431790358],
					[65.90254420237261, 7.513244431242744],
					[65.902633101300751, 7.513997220011448],
					[65.902641500340096, 7.5141194117452992],
					[65.902694200383436, 7.5150917041036829],
					[65.902694200193352, 7.5151056004529986],
					[65.902694200235786, 7.5151056048411622],
					[65.902694200320681, 7.5151056136175098],
					[65.902694201259933, 7.5151056179537559],
					[65.902699801200598, 7.5151694167072804],
					[65.902699801221829, 7.5151694189013618],
					[65.902777600617867, 7.5160167172281112],
					[65.902777600681489, 7.5160167238103828],
					[65.902777602559993, 7.5160167324829867],
					[65.902777602602384, 7.5160167368711663],
					[65.90280810333158, 7.5161833337414965],
					[65.902908103112566, 7.5168083311461995],
					[65.902908103154971, 7.5168083355344022],
					[65.902908105012216, 7.5168083420130092],
					[65.902908105054621, 7.5168083464012128],
					[65.903016503959549, 7.5172694419490949],
					[65.903066502845164, 7.5175222367869408],
					[65.903177603194905, 7.5181417348232813],
					[65.903177603216093, 7.518141737017408],
					[65.9031776041341, 7.5181417391597209],
					[65.903177604155289, 7.5181417413538458],
					[65.903222001753406, 7.518352830659377],
					[65.903235901019855, 7.518488919314148],
					[65.903235901041043, 7.5184889215082791],
					[65.903235901980239, 7.5184889258447596],
					[65.903235901980239, 7.5184889258447596],
					[65.903360902016118, 7.5194444252997075],
					[65.903360902037292, 7.5194444274938501],
					[65.903360902037292, 7.5194444274938501],
					[65.903360902058452, 7.5194444296880132],
					[65.903435901677682, 7.5199528301108485],
					[65.903435901677682, 7.5199528301108485],
					[65.903435902595618, 7.5199528322532476],
					[65.903435902595618, 7.5199528322532476],
					[65.903533103413395, 7.5205306330331316],
					[65.903533104352491, 7.5205306373697196],
					[65.903533106230753, 7.5205306460429142],
					[65.903533107190995, 7.5205306525736804],
					[65.903580407421046, 7.5207056516832687],
					[65.903580408360128, 7.5207056560198708],
					[65.903580410217216, 7.520705662498929],
					[65.903580411156355, 7.5207056668355294],
					[65.903652610741219, 7.5209139661586599],
					[65.903652610741219, 7.5209139661586599],
					[65.903652611659169, 7.5209139683011133],
					[65.903652612577176, 7.5209139704435826],
					[65.903791513324734, 7.5212861691774746],
					[65.903791513345851, 7.5212861713716785],
					[65.903791515181794, 7.5212861756566305],
					[65.903791516099758, 7.521286177799106],
					[65.903927615789954, 7.5216056785586005],
					[65.903927617625882, 7.5216056828435978],
					[65.903927622194558, 7.5216056913619207],
					[65.903927624030473, 7.5216056956469197],
					[65.904030421942281, 7.5217972903191468],
					[65.904113718763952, 7.5219667848344001],
					[65.904269215383692, 7.5223111740149466],
					[65.904402611960606, 7.5226778683966042],
					[65.904402612878556, 7.5226778705391801],
					[65.904402613796506, 7.5226778726817569],
					[65.904402613817638, 7.5226778748759919],
					[65.904483111793937, 7.5228806697639135],
					[65.904572009317206, 7.5231417593282934],
					[65.904674806674478, 7.523513951743519],
					[65.904738707320703, 7.5237556512495454],
					[65.904738707320703, 7.5237556512495454],
					[65.904738708259742, 7.523755655586454],
					[65.904738708259742, 7.523755655586454],
					[65.904858108075715, 7.5241667565899579],
					[65.90485810899365, 7.5241667587326466],
					[65.904858110829494, 7.5241667630179849],
					[65.904858110871686, 7.5241667674065349],
					[65.905035909334828, 7.5246694617441827],
					[65.905063708414687, 7.5247667549869339],
					[65.905063709353669, 7.524766759323958],
					[65.905063711210587, 7.5247667658036663],
					[65.905063713046403, 7.5247667700891014],
					[65.905088712728471, 7.524833371014684],
					[65.905088712728471, 7.524833371014684],
					[65.905088713646379, 7.5248333731573966],
					[65.9050887145643, 7.5248333753001289],
					[65.905285914891962, 7.5253222750985076],
					[65.905285915830959, 7.5253222794355672],
					[65.905285920399422, 7.5253222879550012],
					[65.905285923132055, 7.5253222921889265],
					[65.905494220607849, 7.5257222895783267],
					[65.905535919671649, 7.5258083863529048],
					[65.905535920610603, 7.5258083906900257],
					[65.90553592424007, 7.5258083948724899],
					[65.905535925157977, 7.5258083970152949],
					[65.905588724013796, 7.5259028951655509],
					[65.905785922059465, 7.526286190298646],
					[65.905952622125184, 7.5266139912678405],
					[65.905952623043035, 7.5266139934106882],
					[65.906035918986774, 7.5267722855516022],
					[65.906116513678768, 7.5269694730219543],
					[65.906252611311714, 7.5273361655112865],
					[65.906410910304359, 7.5278306614015573],
					[65.906410910325391, 7.52783066359597],
					[65.906410911243256, 7.5278306657389198],
					[65.906410912161149, 7.5278306678818483],
					[65.906452609873142, 7.5279472640410203],
					[65.906483106673718, 7.5280472524018975],
					[65.906583106425614, 7.5284472490913465],
					[65.906583106425614, 7.5284472490913465],
					[65.906758105345574, 7.5291278433406461],
					[65.90687760390577, 7.5297333399832844],
					[65.90687760390577, 7.5297333399832844],
					[65.906877603926787, 7.5297333421777388],
					[65.906877604823649, 7.5297333421263204],
					[65.906947004137791, 7.5300528423380682],
					[65.906980402232833, 7.5302139323842576],
					[65.907019201443546, 7.5305361239162485],
					[65.907102600678371, 7.531302815118579],
					[65.907133100297543, 7.5318722072810456],
					[65.90714420026049, 7.5324000048449911],
					[65.90714420026049, 7.5324000048449911],
					[65.907144200281465, 7.5324000070394721],
					[65.907144200281465, 7.5324000070394721],
					[65.907169200090777, 7.5330333051153699],
					[65.907174799984418, 7.5334944015426455],
					[65.90717480002634, 7.5334944059316129],
					[65.907174800068248, 7.5334944103205999],
					[65.907174800089209, 7.5334944125150827],
					[65.907199799902557, 7.533902812341351],
					[65.907199799902557, 7.533902812341351],
					[65.907199800841283, 7.5339028166790714],
					[65.907199800841283, 7.5339028166790714],
					[65.907258100997041, 7.5345889177997005],
					[65.907258101017987, 7.5345889199941904],
					[65.907258101038934, 7.5345889221887017],
					[65.907258101956714, 7.5345889243319455],
					[65.907285901663911, 7.5348056250857809],
					[65.907285901663911, 7.5348056250857809],
					[65.907285901684858, 7.5348056272802957],
					[65.907383101660798, 7.5355194274816242],
					[65.907383101660798, 7.5355194274816242],
					[65.907383101702649, 7.5355194318706511],
					[65.907383102620443, 7.5355194340139366],
					[65.907402602708459, 7.535636133081522],
					[65.907402603647157, 7.5356361374193197],
					[65.907402606421371, 7.5356361460437213],
					[65.907402607360055, 7.535636150381519],
					[65.907413705639698, 7.535677849816258],
					[65.907569205348196, 7.5363278460359568],
					[65.907569207204631, 7.5363278525171591],
					[65.907569210917487, 7.5363278654795431],
					[65.907569212773907, 7.5363278719607267],
					[65.907733111358382, 7.536758365984217],
					[65.907780408695317, 7.5369139577208006],
					[65.907780409633986, 7.5369139620587084],
					[65.907780412387226, 7.5369139684888395],
					[65.907780413304991, 7.5369139706322086],
					[65.907847012877625, 7.537088969986276],
					[65.907847012898529, 7.5370889721808227],
					[65.907847013795362, 7.5370889721296601],
					[65.907847013816266, 7.5370889743242033],
					[65.90814151197371, 7.537830667942802],
					[65.908308107926914, 7.5383333561608055],
					[65.908455405631656, 7.5389111486446376],
					[65.908455407446198, 7.538911150737035],
					[65.908455408384782, 7.538911155075132],
					[65.908455408405686, 7.5389111572697294],
					[65.908772007978314, 7.539983356893746],
					[65.908772008896022, 7.5399833590373078],
					[65.908772009813759, 7.53998336118089],
					[65.908772009834607, 7.5399833633755176],
					[65.908774810386959, 7.5399917632189295],
					[65.908774811304653, 7.5399917653624948],
					[65.908774814078654, 7.5399917739878282],
					[65.908774814996363, 7.5399917761313917],
					[65.908997015100937, 7.540536175521618],
					[65.908997016039507, 7.5405361798598642],
					[65.908997019689423, 7.5405361862396436],
					[65.908997022421687, 7.5405361904757999],
					[65.909230420266994, 7.540994486169283],
					[65.909244214747304, 7.5410250778860517],
					[65.909258108028709, 7.5410611580531262],
					[65.909413702393209, 7.5417194308983584],
					[65.909449800182841, 7.5421500070306582],
					[65.909444199754361, 7.5427638899791694],
					[65.909399801095432, 7.54324997667175],
					[65.909260901870553, 7.5441443683534253],
					[65.909227602152498, 7.5443582687258042],
					[65.909205403197589, 7.5444999668456205],
					[65.909022004236093, 7.5454666614219166],
					[65.908958103649937, 7.5457916590704261],
					[65.908830405833442, 7.546338850678703],
					[65.908791509791016, 7.5464777342204066],
					[65.908749815136503, 7.5465805252973803],
					[65.908749814239684, 7.5465805253482134],
					[65.908749813384375, 7.5465805297883284],
					[65.908749811611486, 7.546580532084624],
					[65.908577626988787, 7.5470470896818309],
					[65.908391557982782, 7.5472359584749809],
					[65.908255507408441, 7.5473720110732971],
					[65.907944374680667, 7.5474109016019897],
					[65.907944364815435, 7.5474109021608111],
					[65.907944345126452, 7.5474109076676017],
					[65.907944335281954, 7.547410910420985],
					[65.907830573730848, 7.5474497964194001],
					[65.907497214770473, 7.5474248020734223],
					[65.907497197709716, 7.5474248008441078],
					[65.907497163650547, 7.5474248049690491],
					[65.907497146610552, 7.5474248059342584],
					[65.907374963749348, 7.5474581014677531],
					[65.907047180827703, 7.5474887019777634],
					[65.907047171859318, 7.5474887024857331],
					[65.907047153943239, 7.5474887056961624],
					[65.90704714409874, 7.5474887084494178],
					[65.906941643791043, 7.5475192072556681],
					[65.906941642894196, 7.5475192073064648],
					[65.906941640224446, 7.547519209653327],
					[65.906941638430766, 7.5475192097549186],
					[65.906599937737482, 7.5476304103997114],
					[65.906599936840678, 7.5476304104505019],
					[65.90659993325329, 7.5476304106536682],
					[65.906599932377219, 7.5476304128989264],
					[65.906538831978139, 7.5476526128440282],
					[65.906538823030516, 7.5476526155463928],
					[65.906538806949627, 7.5476526230439465],
					[65.90653879891957, 7.5476526278899634],
					[65.906255499357158, 7.5478192281281835],
					[65.90625549576977, 7.5478192283313188],
					[65.90625548863656, 7.5478192331264253],
					[65.906255485966781, 7.5478192354732165],
					[65.906174885887381, 7.5478748359474697],
					[65.906174885887381, 7.5478748359474697],
					[65.906174884093701, 7.5478748360490329],
					[65.906155483567005, 7.5478887370373782],
					[65.906155475557682, 7.5478887440776585],
					[65.906155460415164, 7.5478887559130063],
					[65.906155453302702, 7.5478887629024838],
					[65.905858153267644, 7.5482109641759489],
					[65.90571095397263, 7.5483692639750775],
					[65.90571095042678, 7.5483692685668746],
					[65.90571094331429, 7.5483692755561176],
					[65.90571093887155, 7.5483692801986795],
					[65.905269239333464, 7.5489637814661021],
					[65.905269234014611, 7.5489637883535776],
					[65.905269225191361, 7.5489638042213585],
					[65.905269219872551, 7.5489638111088304],
					[65.905266534754048, 7.5489692827528305],
					[65.905263758657966, 7.5489720590633196],
					[65.905263749772487, 7.5489720683480908],
					[65.9052637329399, 7.5489720912555587],
					[65.905263724972002, 7.5489721026839245],
					[65.904824824703866, 7.5497582037340223],
					[65.904824823827767, 7.5497582059790327],
					[65.904824820302579, 7.5497582127647975],
					[65.904824819426466, 7.5497582150098079],
					[65.904663719314257, 7.550105515294578],
					[65.904663714912928, 7.5501055243252368],
					[65.904663708780078, 7.5501055400401507],
					[65.90466370706929, 7.550105548918709],
					[65.904391507281929, 7.5511388484205204],
					[65.904391506405787, 7.5511388506654669],
					[65.904391506426478, 7.5511388528597285],
					[65.904391506426478, 7.5511388528597285],
					[65.904372006363388, 7.5512193523048534],
					[65.904372003734963, 7.5512193590396386],
					[65.90437200208622, 7.5512193745008043],
					[65.904372001251488, 7.5512193811342678],
					[65.904366501203398, 7.5512805821688449],
					[65.904366501203398, 7.5512805821688449],
					[65.904366500347976, 7.5512805866080246],
					[65.904366500347976, 7.5512805866080246],
					[65.904299800080381, 7.5523221876466318],
					[65.904299800121777, 7.5523221920351444],
					[65.904299800225218, 7.5523222030064439],
					[65.904299800287291, 7.5523222095892297],
					[65.904310899779858, 7.5525500107915153],
					[65.904310900718102, 7.5525500151294152],
					[65.904310901718389, 7.5525500260501053],
					[65.904310901759771, 7.5525500304386384],
					[65.904410901984065, 7.5531889318507579],
					[65.90443590234527, 7.5533528292781495],
					[65.904435903304162, 7.5533528358103865],
					[65.904435906118763, 7.5533528488242592],
					[65.904435907974459, 7.5533528553058922],
					[65.904452608159005, 7.5534111550833307],
					[65.904452610014729, 7.5534111615649904],
					[65.904452616437368, 7.553411176570811],
					[65.904452619189897, 7.5534111830018915],
					[65.904669219417201, 7.553877883366841],
					[65.904669222169758, 7.553877889798021],
					[65.904669227674816, 7.553877902660366],
					[65.904669232221053, 7.5538779089904118],
					[65.904866532079907, 7.5541862082265103],
					[65.904941527015097, 7.5543029010217957],
					[65.905030421629846, 7.554475092390823],
					[65.905030425258573, 7.5544750965772902],
					[65.905030429825416, 7.5544751051018322],
					[65.905030432557268, 7.5544751093388474],
					[65.905208123058586, 7.5547472954699888],
					[65.905324815633591, 7.5550167790213676],
					[65.905324817447919, 7.5550167811146745],
					[65.905324819282939, 7.555016785402306],
					[65.905324820200434, 7.5550167875461218],
					[65.905444213369137, 7.5552639729549673],
					[65.905527607563371, 7.5555444569567651],
					[65.905630405091642, 7.5559083454442897],
					[65.9057637017861, 7.5566306295803738],
					[65.905769200907756, 7.5566778208178613],
					[65.905791500959182, 7.5569111188263243],
					[65.905791500979817, 7.5569111210207129],
					[65.905791500979817, 7.5569111210207129],
					[65.905791501000436, 7.5569111232150998],
					[65.905866501284009, 7.5575500236953532],
					[65.905866502222068, 7.5575500280336998],
					[65.905866503180775, 7.5575500345664617],
					[65.905866504118833, 7.55755003890481],
					[65.905899800281759, 7.5577194199153572],
					[65.905913699813823, 7.5585556038840922],
					[65.905913700793064, 7.5585556126112827],
					[65.905913701834123, 7.5585556279217148],
					[65.905913702813379, 7.5585556366489071],
					[65.905960902846829, 7.5588139354668149],
					[65.906772002933508, 7.5632194359293319],
					[65.907616503094843, 7.5682611332161667],
					[65.907616503135756, 7.5682611376052771],
					[65.907616504990855, 7.5682611440887957],
					[65.907616505928644, 7.5682611484278537],
					[65.908605405814754, 7.5722111484372698],
					[65.908605407669668, 7.5722111549213471],
					[65.90860541227633, 7.5722111678395452],
					[65.908605414131301, 7.5722111743235994],
					[65.909730408834236, 7.5750417599765942],
					[65.910477605421917, 7.5782472452602399],
					[65.910477610965586, 7.5782472625203559],
					[65.910477627454398, 7.5782472989370362],
					[65.9104776356683, 7.5782473138531534],
					[65.910497029061105, 7.5782751041535148],
					[65.910522017223329, 7.5783222818937279],
					[65.910716512572961, 7.5788361718497335],
					[65.910716512572961, 7.5788361718497335],
					[65.910716515283838, 7.5788361738954348],
					[65.910716515304159, 7.5788361760902863],
					[65.910935911122564, 7.5793694680361359],
					[65.910944207186105, 7.5793972530594536],
					[65.910955405382779, 7.5794417468258848],
					[65.911191504713656, 7.5804806441545187],
					[65.911191504713656, 7.5804806441545187],
					[65.911191504733949, 7.5804806463493914],
					[65.911191505630811, 7.5804806462997378],
					[65.911402605430339, 7.5813583458267324],
					[65.911433105334126, 7.5814917441976162],
					[65.911449805421555, 7.5815639455596671],
					[65.911449806358931, 7.5815639498998442],
					[65.911449808213462, 7.5815639563853194],
					[65.911449809130602, 7.5815639585305972],
					[65.911624807762266, 7.5821278552761484],
					[65.911758105706767, 7.5826444495102949],
					[65.911758106623893, 7.5826444516556384],
					[65.911758107561226, 7.582644455995907],
					[65.911758109375214, 7.5826444580916723],
					[65.911866506691226, 7.582997252942933],
					[65.912052606298388, 7.5837278495843021],
					[65.912052606298388, 7.5837278495843021],
					[65.9120526072155, 7.5837278517297086],
					[65.912052607235751, 7.5837278539246782],
					[65.91233040683781, 7.5847389540429226],
					[65.912330407734657, 7.5847389539934174],
					[65.912330408671991, 7.5847389583338565],
					[65.912330409589046, 7.5847389604793252],
					[65.912352609744289, 7.5848083614641126],
					[65.912352609744289, 7.5848083614641126],
					[65.912352609764511, 7.5848083636590875],
					[65.912424810367853, 7.5850250624264026],
					[65.912697008933861, 7.5858861574495808],
					[65.912794207962648, 7.5862222553977547],
					[65.912794207982856, 7.5862222575927696],
					[65.912794208899896, 7.5862222597383289],
					[65.91279420981698, 7.5862222618838873],
					[65.912930409804403, 7.5866444620409066],
					[65.912930411638513, 7.5866444663320776],
					[65.912930416244009, 7.5866444792550514],
					[65.91293041899516, 7.5866444856918278],
					[65.913080411051382, 7.5869639691923787],
					[65.913147007444948, 7.5872167511692288],
					[65.913147009319431, 7.5872167598505893],
					[65.913147014841897, 7.5872167749194013],
					[65.913147017593076, 7.5872167813562914],
					[65.913252618119373, 7.5874500812565104],
					[65.913252619953454, 7.5874500855477924],
					[65.913252622724798, 7.5874500941797907],
					[65.913252626352602, 7.587450098372245],
					[65.913394225560708, 7.5877028979319689],
					[65.913394240092089, 7.5877029168971264],
					[65.913394275432793, 7.5877029544815837],
					[65.913394294428272, 7.5877029710046227],
					[65.913705494329875, 7.5878973708272968],
					[65.913705512347718, 7.5878973786197017],
					[65.913705548363211, 7.5878973920093999],
					[65.91370556725775, 7.5878973975573096],
					[65.913755567248742, 7.5879057980778102],
					[65.913755588793464, 7.5879057990873422],
					[65.913755633636256, 7.5879057966173695],
					[65.913755656017301, 7.5879057909921626],
					[65.914152855896376, 7.5877890917372817],
					[65.914152861277486, 7.5877890914408557],
					[65.91415286932903, 7.5877890888010828],
					[65.914152872896253, 7.5877890864083328],
					[65.914469463704791, 7.587664090349107],
					[65.914600001872373, 7.5876280065478152],
					[65.914952750596854, 7.5877168943555313],
					[65.914952755977978, 7.5877168940590805],
					[65.914952766780644, 7.5877168978565734],
					[65.914952772161769, 7.5877168975601217],
					[65.915049905398803, 7.5877307883574066],
					[65.915258158180123, 7.5879418401091527],
					[65.91525816091108, 7.5879418443514224],
					[65.915258169043327, 7.5879418504924896],
					[65.915258172671102, 7.5879418546853579],
					[65.915502653680306, 7.5881418391687028],
					[65.915713738786337, 7.5884307178923374],
					[65.915713738806502, 7.5884307200876044],
					[65.915958130645777, 7.5887612080851463],
					[65.916013723912926, 7.5888639945553287],
					[65.916013723933105, 7.5888639967506224],
					[65.916013724829952, 7.5888639967012503],
					[65.916013724829952, 7.5888639967012503],
					[65.916085925207369, 7.5889944975185406],
					[65.916085926124381, 7.5889944996644756],
					[65.91608592885531, 7.5889945039069922],
					[65.916085930669183, 7.5889945060035595],
					[65.916413730810035, 7.5895168065168246],
					[65.916413730810035, 7.5895168065168246],
					[65.916413731727019, 7.5895168086628031],
					[65.916413732644045, 7.5895168108087807],
					[65.916552632832293, 7.5897279103555446],
					[65.916552637377066, 7.5897279166948728],
					[65.916552646446391, 7.5897279271781741],
					[65.91655265099115, 7.5897279335174819],
					[65.916841541681791, 7.5900529239698056],
					[65.916866536423967, 7.5900890144840796],
					[65.916866550017886, 7.5900890291117369],
					[65.916866579896265, 7.5900890582190188],
					[65.916866597974462, 7.5900890726000236],
					[65.917210997932685, 7.5902945727634785],
					[65.917210999726407, 7.5902945726648312],
					[65.917211004251001, 7.5902945768090042],
					[65.917211006941585, 7.5902945766610328],
					[65.917316607397325, 7.5903501770353525],
					[65.917316641598802, 7.5903501883335638],
					[65.917316715241725, 7.5903501952661969],
					[65.917316751116005, 7.5903501932933084],
					[65.917661116090073, 7.5902585029783651],
					[65.917766678682142, 7.5902695996169482],
					[65.917766712762699, 7.5902695977425561],
					[65.917766778112295, 7.5902695809690472],
					[65.917766810278238, 7.5902695660206216],
					[65.918211153715347, 7.5899780037045357],
					[65.918638728924336, 7.590005788494155],
					[65.918660903236955, 7.5901389323111799],
					[65.918660903277242, 7.5901389367022425],
					[65.918660905151555, 7.5901389453856822],
					[65.918660906088704, 7.5901389497273923],
					[65.918802605191985, 7.5907056439367464],
					[65.918874796560004, 7.5910389065769763],
					[65.918713701596502, 7.5920582700025587],
					[65.918713700800254, 7.5920582810294812],
					[65.918713701041753, 7.592058307375849],
					[65.918713701142366, 7.5920583183535069],
					[65.918721998827792, 7.59214439906909],
					[65.918683100667081, 7.5925166794223049],
					[65.918683100707312, 7.592516683813356],
					[65.918683099870819, 7.5925166904491999],
					[65.918683099911078, 7.59251669484025],
					[65.918660900327808, 7.593247191128647],
					[65.91865539972396, 7.59333888824131],
					[65.918655401738889, 7.5933389122936648],
					[65.918655411149786, 7.5933389601030568],
					[65.918655419442672, 7.5933389838108702],
					[65.918691519054363, 7.5934167843497047],
					[65.918691601231316, 7.5934168413457073],
					[65.918691775442838, 7.5934168559482318],
					[65.918691865663547, 7.593416811457665],
					[65.91881126099878, 7.5932390176200952],
					[65.919136255935413, 7.5928334254639367],
					[65.919136269207229, 7.5928334049652255],
					[65.91913629030941, 7.5928333576765477],
					[65.919136297263009, 7.5928333331313844],
					[65.91926129465493, 7.5921139455261795],
					[65.919569590703546, 7.591094458256241],
					[65.919569591580284, 7.5910944560113389],
					[65.919569591580284, 7.5910944560113389],
					[65.919569591580284, 7.5910944560113389],
					[65.919597388853205, 7.5909972655074807],
					[65.919808450151393, 7.5904890604529651],
					[65.920011152760551, 7.5904195933325642],
					[65.920219421737457, 7.5903752003734333],
					[65.920458202231131, 7.5903724007476026],
					[65.920610929814586, 7.5905723075764646],
					[65.920797016036332, 7.5909333796132827],
					[65.920849808947935, 7.5910861590695333],
					[65.920916504672022, 7.5913389455151439],
					[65.921010903734683, 7.5918000391644558],
					[65.921010903754791, 7.5918000413602007],
					[65.921010904671817, 7.5918000435066428],
					[65.92101090469194, 7.5918000457023656],
					[65.92106090326152, 7.5920167361280786],
					[65.921138701982613, 7.5925972266801534],
					[65.921138702022844, 7.5925972310716245],
					[65.921138702959951, 7.5925972354138418],
					[65.921138703897014, 7.592597239756083],
					[65.921238701681645, 7.5931056293025057],
					[65.921266501366119, 7.5933889209718188],
					[65.921266502303183, 7.5933889253140867],
					[65.921266504217542, 7.5933889383901407],
					[65.921266505174728, 7.5933889449281757],
					[65.921388703961256, 7.5939306412184342],
					[65.921424803560328, 7.5941056402541598],
					[65.921424810019204, 7.594105659671631],
					[65.921424824690462, 7.5941056940166671],
					[65.921424833819785, 7.5941057110907977],
					[65.921480423327395, 7.5941889964082767],
					[65.921663716117422, 7.594619477051288],
					[65.921663761442417, 7.5946195272910018],
					[65.921663870544762, 7.5946195850176865],
					[65.921663936155994, 7.5946195967978358],
					[65.921844433601422, 7.5945862969924125],
					[65.922133332315951, 7.5945390965898634],
					[65.92213335826456, 7.5945390885759805],
					[65.922133407410911, 7.5945390661082657],
					[65.922133429711835, 7.5945390517036406],
					[65.922291829854814, 7.5944029528049963],
					[65.922291831628428, 7.5944029505107782],
					[65.922291834298875, 7.5944029481673683],
					[65.922291836092597, 7.5944029480689839],
					[65.922586233844171, 7.5941307482832645],
					[65.922736232657144, 7.5939973506720024],
					[65.922811232912423, 7.5939307489945209],
					[65.922811232912423, 7.5939307489945209],
					[65.922811233809242, 7.5939307489453123],
					[65.922811234706131, 7.5939307488961019],
					[65.923180711432053, 7.5935945687162789],
					[65.923425058812725, 7.5934834930637436],
					[65.923627741263886, 7.5934502130161752],
					[65.923813748947182, 7.5936084349982043],
					[65.924080422895386, 7.5940111940660504],
					[65.924088707285762, 7.5940306561841249],
					[65.924091503363613, 7.5940472336555702],
					[65.924091504300634, 7.5940472379983426],
					[65.9240915052779, 7.5940472467331119],
					[65.924091507111797, 7.5940472510266961],
					[65.924263707105268, 7.5947056519073746],
					[65.924263707105268, 7.5947056519073746],
					[65.924263707105268, 7.5947056519073746],
					[65.92438040718757, 7.5951306519606945],
					[65.924547005738347, 7.5957667481962288],
					[65.924649805360474, 7.5962139457159363],
					[65.924769205158668, 7.5967306431734603],
					[65.924891503948501, 7.5972972402243686],
					[65.925013702578099, 7.5979444359726731],
					[65.925069202863725, 7.5982583309729499],
					[65.925088701720185, 7.5983861310184855],
					[65.925088701720185, 7.5983861310184855],
					[65.925088702617046, 7.5983861309694269],
					[65.925197003294542, 7.5990556312011002],
					[65.925263702261347, 7.5994750320499111],
					[65.925263703158194, 7.5994750320008908],
					[65.925263704095087, 7.5994750363440602],
					[65.925263704115125, 7.5994750385401559],
					[65.925474804343651, 7.6005306370368952],
					[65.925480403161657, 7.6005611339440184],
					[65.925485902774895, 7.6005944333327751],
					[65.925485902794904, 7.6005944355289099],
					[65.925485904668633, 7.6005944442154068],
					[65.925485905585489, 7.6005944463625399],
					[65.925741505787556, 7.601647247576949],
					[65.925741505787556, 7.601647247576949],
					[65.925741506724364, 7.6016472519202791],
					[65.925741506724364, 7.6016472519202791],
					[65.925952606815258, 7.6024306501766956],
					[65.926030404865671, 7.6027306432702781],
					[65.926147002060759, 7.6033667304311878],
					[65.926205400748742, 7.6038194172762044],
					[65.926230400163362, 7.6042778059008675],
					[65.926238699693855, 7.6049138952015705],
					[65.926210899873709, 7.6054805887093213],
					[65.926180401216939, 7.6060166835535172],
					[65.926077601312556, 7.6069193776234085],
					[65.926077601332466, 7.6069193798195798],
					[65.926077601352389, 7.6069193820157723],
					[65.926077601372285, 7.6069193842119454],
					[65.926060900877445, 7.6071221827822004],
					[65.925999800652122, 7.6077332750618503],
					[65.925949804195071, 7.6080971598365261],
					[65.925913706944584, 7.608227747419118],
					[65.925913705210547, 7.6082277541050498],
					[65.925913701782264, 7.6082277718692088],
					[65.925913700945074, 7.6082277785064241],
					[65.925880401263612, 7.6085471786579513],
					[65.925880401303402, 7.6085471830502867],
					[65.925880399569309, 7.6085471897361687],
					[65.925880399589204, 7.608547191932324],
					[65.925852600124571, 7.6093305931158834],
					[65.925852600164319, 7.6093305975081948],
					[65.925852600263724, 7.6093306084889898],
					[65.925852600303472, 7.6093306128812994],
					[65.925874799579177, 7.6096778132782132],
					[65.925874801412647, 7.6096778175731963],
					[65.925874802388989, 7.6096778263091815],
					[65.925874802428737, 7.6096778307014956],
					[65.925988701901048, 7.6104222304787363],
					[65.925988702837643, 7.6104222348224315],
					[65.925988704690965, 7.6104222413136764],
					[65.925988704730685, 7.6104222457060109],
					[65.926002605431407, 7.610480646185688],
					[65.926002606348149, 7.6104806483332172],
					[65.926002607264863, 7.6104806504807483],
					[65.926002607284715, 7.6104806526769204],
					[65.926027607240144, 7.6105722516462295],
					[65.926180407133756, 7.6111472515616692],
					[65.926180413590473, 7.6111472709870505],
					[65.926180431865234, 7.6111473073499551],
					[65.926180442786446, 7.6111473243360956],
					[65.926455424042913, 7.6114945010115296],
					[65.926469213149204, 7.6115306703999179],
					[65.926469235011339, 7.6115307065690967],
					[65.926469292049646, 7.611530762804934],
					[65.926469326348752, 7.6115307851164058],
					[65.926483225923391, 7.6115362853702075],
					[65.926483260083558, 7.6115362923081991],
					[65.926483328304755, 7.6115362952031065],
					[65.926483363242738, 7.6115362889151896],
					[65.926500008026522, 7.6115308077611425],
					[65.926933253021943, 7.6116362948693785],
					[65.926933270979063, 7.6116362960936392],
					[65.926933306893261, 7.6116362985421624],
					[65.926933324830557, 7.6116362975701737],
					[65.926999993936988, 7.6116280023590361],
					[65.927383184944588, 7.6116973821902238],
					[65.927602631126263, 7.6120001061130109],
					[65.927838712605364, 7.6124333763739109],
					[65.927852607296842, 7.6124833509915062],
					[65.927860903794752, 7.6125167417559938],
					[65.927988703077162, 7.6132556332750267],
					[65.928049802163201, 7.6136056300716062],
					[65.928108101903291, 7.6140722260628646],
					[65.928108101923101, 7.6140722282592384],
					[65.928108103796077, 7.6140722369476146],
					[65.928108103835683, 7.6140722413403408],
					[65.928241502235352, 7.6146944287470486],
					[65.9282526012733, 7.6148306170484812],
					[65.92825260318584, 7.6148306301297142],
					[65.928252609741108, 7.6148306605394627],
					[65.928252615260902, 7.6148306756231001],
					[65.928308113641876, 7.6149667732494732],
					[65.92847201363081, 7.6153889725683346],
					[65.92847204812729, 7.6153890168505836],
					[65.928472136633616, 7.6153890801884279],
					[65.928472189746572, 7.615389099292476],
					[65.928761089974074, 7.6154029997108097],
					[65.928761113292552, 7.615402998450544],
					[65.928761158076426, 7.6154029894377251],
					[65.92876117956159, 7.6154029838815624],
					[65.92893898045061, 7.6153251838380251],
					[65.92893899561814, 7.6153251742282313],
					[65.928939025056323, 7.6153251550571381],
					[65.928939039307181, 7.6153251432993949],
					[65.929208439291031, 7.6150640444754982],
					[65.929208441064972, 7.6150640421820794],
					[65.929208442838885, 7.6150640398886438],
					[65.929208444612854, 7.6150640375952241],
					[65.929633444630696, 7.6146195391129616],
					[65.929633446404608, 7.6146195368194753],
					[65.929633449075425, 7.6146195344774865],
					[65.92963344995249, 7.614619532232501],
					[65.929652942958583, 7.6145973419417823],
					[65.929977933549182, 7.6143029471491159],
					[65.929977943355254, 7.6143029400259117],
					[65.929977960217386, 7.614302919335505],
					[65.92997796819013, 7.6143029079163025],
					[65.930097359379189, 7.6141195211217978],
					[65.930272350106421, 7.6139223339995121],
					[65.930272358956174, 7.6139223203349937],
					[65.930272374881767, 7.6139222952995471],
					[65.930272381957636, 7.6139222839286189],
					[65.930475181678815, 7.6134750832131086],
					[65.930475181659006, 7.6134750810165333],
					[65.930475182555881, 7.613475080967989],
					[65.930475182555881, 7.613475080967989],
					[65.930508483025093, 7.6134000819522578],
					[65.930508485636409, 7.6134000730203688],
					[65.930508490898674, 7.6134000595497255],
					[65.93050849263291, 7.6134000528629606],
					[65.930539092864493, 7.6132861522007476],
					[65.930539093741515, 7.6132861499556368],
					[65.930539093721691, 7.613286147759057],
					[65.930539094598743, 7.6132861455139462],
					[65.930675194626332, 7.612713947007963],
					[65.930675196360568, 7.6127139403211075],
					[65.930675198055127, 7.6127139292410906],
					[65.930675198015479, 7.6127139248479478],
					[65.930719598315534, 7.6123639241135015],
					[65.930808497637486, 7.6116583270930809],
					[65.930866898169242, 7.6112556283780322],
					[65.930866898149389, 7.6112556261814461],
					[65.930866898129537, 7.6112556239848388],
					[65.930866899026427, 7.6112556239362164],
					[65.930889099210802, 7.6110639220912644],
					[65.930889099210802, 7.6110639220912644],
					[65.930889099190949, 7.6110639198946544],
					[65.930961298636021, 7.6103889209501778],
					[65.930989098943073, 7.6101500219171712],
					[65.930989098943073, 7.6101500219171712],
					[65.930989098903339, 7.6101500175239787],
					[65.930989098903339, 7.6101500175239787],
					[65.930997398907152, 7.6100500167544549],
					[65.931089099115539, 7.6090472209538236],
					[65.931155797953096, 7.6085194252755795],
					[65.931155798830076, 7.6085194230302529],
					[65.931155798830076, 7.6085194230302529],
					[65.931155798830076, 7.6085194230302529],
					[65.931219597800364, 7.607941729514228],
					[65.931272396143655, 7.6076639385466045],
					[65.931380793784669, 7.6071500496560409],
					[65.931477988793702, 7.6068278642078324],
					[65.931816877931567, 7.6059389956751504],
					[65.931989057676603, 7.6057001233025954],
					[65.932261189005857, 7.6053724065021591],
					[65.93259718360288, 7.6053918996298924],
					[65.932708214337168, 7.6054029930279032],
					[65.932974857146391, 7.6056473402149587],
					[65.933160947191766, 7.605855728367235],
					[65.933355440761417, 7.6060973205667768],
					[65.933616536557423, 7.6064640149674352],
					[65.933616542915104, 7.6064640234131575],
					[65.93361655379698, 7.6064640360085365],
					[65.93361656013478, 7.6064640422574197],
					[65.933855460411863, 7.6066973437919643],
					[65.933855461308724, 7.6066973437431749],
					[65.93385546312237, 7.6066973458424378],
					[65.93385546312237, 7.6066973458424378],
					[65.934069263264476, 7.60689734652121],
					[65.934069268685491, 7.6068973506222335],
					[65.93406928134111, 7.6068973609235968],
					[65.934069286762124, 7.6068973650246212],
					[65.9341915824387, 7.606980761144766],
					[65.934519277298534, 7.6072362571769414],
					[65.934519286326903, 7.60723626327992],
					[65.934519304383542, 7.6072362754859029],
					[65.934519313391988, 7.6072362793920076],
					[65.934697112702779, 7.6073223796561615],
					[65.934697119020626, 7.6073223837086061],
					[65.934697130719641, 7.6073223874684643],
					[65.934697137017594, 7.6073223893239978],
					[65.934972137101269, 7.6074140901931786],
					[65.934972158665744, 7.6074140934166961],
					[65.934972205382167, 7.6074140996686452],
					[65.934972227803669, 7.6074140984495067],
					[65.93510832807118, 7.6073945979726076],
					[65.935108337916731, 7.6073945952392279],
					[65.935108357627783, 7.6073945919694168],
					[65.935108366556548, 7.6073945870878585],
					[65.935419466837786, 7.6072834884121043],
					[65.935419490056617, 7.6072834761591679],
					[65.935419532867016, 7.6072834474544315],
					[65.935419551561736, 7.6072834310514033],
					[65.93573625236121, 7.6069112308141511],
					[65.935736254115099, 7.6069112263225813],
					[65.935736257662725, 7.6069112217334398],
					[65.935736260333385, 7.6069112193900654],
					[65.935861259913111, 7.6067445193459875],
					[65.935861262563876, 7.6067445148055723],
					[65.935861269659071, 7.6067445056272023],
					[65.935861273206669, 7.6067445010379959],
					[65.935955767604881, 7.606583409098687],
					[65.936305763246452, 7.6060918150964971],
					[65.936305764143285, 7.6060918150476837],
					[65.936466856110059, 7.6058612250161062],
					[65.93675014197477, 7.6055529426481288],
					[65.93678342895528, 7.6055223537951004],
					[65.93690011793187, 7.6054334600427635],
					[65.937133396702023, 7.6052695769529679],
					[65.937194468290784, 7.6052445874163688],
					[65.937536139859475, 7.6051390971863837],
					[65.937641710056312, 7.6051280007059825],
					[65.937991690846928, 7.605130798971822],
					[65.938091679073594, 7.6051390984476903],
					[65.938474975883921, 7.6051862996275492],
					[65.938474976780796, 7.6051862995786967],
					[65.938474981265102, 7.6051862993344388],
					[65.938474983058839, 7.6051862992367347],
					[65.938538871597359, 7.605191898342623],
					[65.938597158810879, 7.6052029950401332],
					[65.938988854040602, 7.6052918942686691],
					[65.938999933279689, 7.6052945905686942],
					[65.939047114728851, 7.6053168799397088],
					[65.939047115645636, 7.6053168820881432],
					[65.939047118336219, 7.6053168819415973],
					[65.939047119233052, 7.605316881892751],
					[65.93937491039037, 7.6054612789276526],
					[65.939438788390021, 7.6054973669570121],
					[65.939713769575675, 7.6057168516242983],
					[65.939891559750379, 7.6058779434848729],
					[65.940030450309195, 7.6060251329173747],
					[65.940297041623325, 7.606347322973523],
					[65.940327636291613, 7.6063890139928443],
					[65.940344233316821, 7.6064140109883711],
					[65.940344236027315, 7.6064140130393278],
					[65.940344239674516, 7.6064140194362615],
					[65.940344242404947, 7.6064140236846338],
					[65.940619236174214, 7.6067668139568649],
					[65.940799831435427, 7.6070529059270324],
					[65.940799833268954, 7.6070529102243185],
					[65.940799838729802, 7.6070529187213269],
					[65.940799842357052, 7.6070529229210306],
					[65.941072037701048, 7.607405717926099],
					[65.941255435031238, 7.6076723130746142],
					[65.941255436844841, 7.6076723151745487],
					[65.941255442305618, 7.6076723236718413],
					[65.94125544411925, 7.607672325771774],
					[65.941499844388758, 7.6079751252891361],
					[65.941499845305515, 7.6079751274378706],
					[65.941499847119147, 7.607975129537845],
					[65.941499848016008, 7.6079751294890823],
					[65.94170814804302, 7.6082195301077133],
					[65.94191374812695, 7.6084584309659409],
					[65.94191374902384, 7.6084584309171959],
					[65.94216094928224, 7.6087418311747204],
					[65.942160951095858, 7.6087418332748022],
					[65.942160953806294, 7.6087418353261711],
					[65.942160954723064, 7.6087418374749891],
					[65.942388754963716, 7.6089807387813524],
					[65.942388755860591, 7.6089807387326243],
					[65.942613755675907, 7.6092140380454154],
					[65.942613762930335, 7.609214046446044],
					[65.94261377919311, 7.6092140587546604],
					[65.942613787324504, 7.6092140649089774],
					[65.942710987095893, 7.6092807652552175],
					[65.942710990703219, 7.609280767257947],
					[65.942710999731503, 7.6092807733635901],
					[65.942711005132509, 7.6092807752688838],
					[65.943066605449943, 7.6094723769335362],
					[65.943066627950984, 7.6094723845062822],
					[65.943066673830046, 7.6094723974054119],
					[65.943066698981909, 7.6094724004367604],
					[65.943455598583412, 7.6094751998279468],
					[65.943455600377121, 7.6094751997305234],
					[65.943513899839658, 7.6094751994072753],
					[65.943513923138141, 7.6094751959430722],
					[65.943513967921575, 7.6094751869143957],
					[65.943513990283407, 7.6094751791035655],
					[65.943525082353602, 7.6094695832678845],
					[65.943908373425145, 7.609319585670768],
					[65.943908377889585, 7.6093195832294676],
					[65.943908387735164, 7.6093195804958524],
					[65.94390839309645, 7.6093195780058327],
					[65.943961192742904, 7.6092918766639066],
					[65.943961193639751, 7.6092918766151865],
					[65.943961195433474, 7.6092918765177462],
					[65.944280694602739, 7.6091195758657069],
					[65.944280702634742, 7.6091195710316732],
					[65.944280719575673, 7.6091195591171594],
					[65.944280726710772, 7.6091195543318744],
					[65.944408407608705, 7.609014070117686],
					[65.944647285831195, 7.6089001808240679],
					[65.944647295656893, 7.6089001758924431],
					[65.944647316165344, 7.6089001615849048],
					[65.944647325971133, 7.6089001544554931],
					[65.944852917331161, 7.6087334630856001],
					[65.945205690183684, 7.6085084801867451],
					[65.945300070957359, 7.6084723870983453],
					[65.945300085247439, 7.6084723797248444],
					[65.945300112033877, 7.608472365075329],
					[65.945300123633388, 7.608472357848064],
					[65.94556682256146, 7.6082612583332727],
					[65.945705720847755, 7.6081557592390645],
					[65.945705722621582, 7.6081557569436802],
					[65.945705727982869, 7.6081557544532377],
					[65.945705729756682, 7.6081557521578516],
					[65.945744529958318, 7.6081223515109446],
					[65.945744532628993, 7.6081223491667886],
					[65.945744538867217, 7.6081223444297104],
					[65.945744542434738, 7.6081223420367916],
					[65.946027941587232, 7.6078362414348684],
					[65.94618903869808, 7.6076751432274738],
					[65.946352927052871, 7.6075223550782107],
					[65.946636200387402, 7.6073168749285172],
					[65.946711174332805, 7.6072834849316644],
					[65.947083277923909, 7.6071530197841151],
					[65.947413796639211, 7.6073501713746943],
					[65.94753315623224, 7.6074223478343379],
					[65.947713727413472, 7.6077057009596025],
					[65.947935916672847, 7.6081306818165135],
					[65.947988711321628, 7.6082694640092994],
					[65.948155408910665, 7.6088222571125135],
					[65.948155409847303, 7.6088222614599026],
					[65.948155411700711, 7.608822267956632],
					[65.948155414431028, 7.6088222722065311],
					[65.94830810913912, 7.6092111619114515],
					[65.948360906022771, 7.6094194485382927],
					[65.948360907896003, 7.6094194572331846],
					[65.94836091431317, 7.6094194722787467],
					[65.948360917083292, 7.6094194809249114],
					[65.948449817196249, 7.6096222797355271],
					[65.948449817216101, 7.6096222819336212],
					[65.948449818112977, 7.609622281884902],
					[65.948449818132872, 7.6096222840829943],
					[65.948597018402481, 7.6099444830010778],
					[65.948597022966283, 7.6099444915499648],
					[65.948597032990733, 7.6099445085990691],
					[65.948597037554507, 7.6099445171479765],
					[65.94884153500881, 7.610280712817004],
					[65.94888043066554, 7.6103418062992318],
					[65.948880437939749, 7.6103418169003518],
					[65.948880450654755, 7.6103418338036946],
					[65.948880458786064, 7.6103418399598581],
					[65.948905459217855, 7.6103668401694859],
					[65.948905464678504, 7.6103668486698739],
					[65.948905479127589, 7.6103668588814397],
					[65.948905487258926, 7.6103668650376157],
					[65.949224886579756, 7.6105862653363481],
					[65.949224895588102, 7.6105862692458004],
					[65.949224913624647, 7.6105862792628853],
					[65.949224922652817, 7.6105862853705206],
					[65.949358222871723, 7.610641886091905],
					[65.949358223768598, 7.6106418860432186],
					[65.949358224665431, 7.610641885994534],
					[65.949358225562335, 7.6106418859458476],
					[65.949602726308314, 7.610739085090704],
					[65.949602730812487, 7.6107390870454852],
					[65.949602741614555, 7.6107390908577015],
					[65.949602747912436, 7.6107390927151162],
					[65.949808235836556, 7.6107945903011567],
					[65.950091621936238, 7.6109084839745389],
					[65.950258212089423, 7.61098348078648],
					[65.950677706104827, 7.6112057776641535],
					[65.950677708795439, 7.611205777518145],
					[65.950677715113159, 7.6112057815740375],
					[65.950677717803728, 7.6112057814280343],
					[65.950708203444179, 7.6112195754832941],
					[65.950791589158072, 7.6112751661185909],
					[65.950791591868509, 7.6112751681708861],
					[65.950791598186242, 7.6112751722268346],
					[65.950791600896665, 7.6112751742791325],
					[65.951158176120032, 7.6114834601255765],
					[65.951330455980226, 7.6116612398105516],
					[65.951330463214688, 7.6116612460163662],
					[65.951330476766827, 7.6116612562782908],
					[65.951330484001247, 7.6116612624840858],
					[65.951610957692566, 7.6118612429186969],
					[65.951941538664016, 7.6123112188627164],
					[65.951941538664016, 7.6123112188627164],
					[65.952066532153282, 7.6124807100159133],
					[65.95235812602148, 7.6130000980639441],
					[65.952358127854865, 7.6130001023635909],
					[65.952358132398629, 7.6130001087158616],
					[65.952358134232043, 7.6130001130155058],
					[65.952522028135704, 7.6132418020411574],
					[65.952594222851289, 7.6133778943017161],
					[65.952594223748122, 7.6133778942531203],
					[65.952594225581493, 7.6133778985528329],
					[65.952594227395039, 7.6133779006540916],
					[65.952977625610046, 7.6140361992072902],
					[65.953205423556753, 7.6144444942383362],
					[65.953433122660826, 7.6148806927755199],
					[65.953433123577469, 7.6148806949255228],
					[65.953433128121191, 7.6148807012783699],
					[65.953433129037847, 7.6148807034283523],
					[65.953819223116781, 7.6155111954029691],
					[65.9538887188111, 7.6156611836124792],
					[65.953888723374604, 7.6156611921641453],
					[65.953888731584911, 7.6156612071174719],
					[65.953888737045247, 7.6156612156206185],
					[65.954047036784161, 7.6158834151646877],
					[65.954047037700832, 7.6158834173147536],
					[65.954047038617446, 7.6158834194648231],
					[65.954047040411211, 7.6158834193677967],
					[65.954341539778923, 7.6162751207412951],
					[65.954341565108663, 7.6162751435680205],
					[65.954341621070242, 7.6162751801360482],
					[65.954341652598885, 7.6162751938288338],
					[65.954455502429212, 7.6163029827821465],
					[65.954733156679453, 7.6165612407482115],
					[65.954774824377964, 7.6166084023486595],
					[65.954794210560692, 7.6166639661835207],
					[65.95479422877483, 7.6166639959948794],
					[65.954794274132183, 7.6166640507354169],
					[65.954794301255589, 7.6166640734659534],
					[65.954852700883066, 7.6166973744316895],
					[65.954852750348934, 7.6166973871555497],
					[65.954852847269379, 7.6166973885150453],
					[65.954852895601022, 7.6166973749035689],
					[65.955141771885323, 7.6165390885785094],
					[65.955236145675201, 7.6165168960134153],
					[65.955236157294834, 7.6165168909856229],
					[65.955236178760188, 7.6165168832257457],
					[65.955236188586113, 7.6165168782949362],
					[65.955241788563072, 7.6165140787831325],
					[65.955241804627448, 7.6165140691155262],
					[65.955241834045879, 7.6165140477270654],
					[65.955241848296779, 7.6165140359577395],
					[65.95555011870556, 7.6161751654172036],
					[65.955688981383176, 7.6161140835555914],
					[65.955689000118369, 7.6161140715432953],
					[65.955689035775194, 7.6161140454169978],
					[65.955689051780169, 7.6161140291527847],
					[65.955883390389403, 7.6158863019403897],
					[65.956133296191055, 7.615891899496213],
					[65.956133324870834, 7.615891895744916],
					[65.956133382151236, 7.6158918794472621],
					[65.956133408958138, 7.6158918669979396],
					[65.956300032911059, 7.615783516869608],
					[65.956583244705769, 7.615864091515113],
					[65.956583280639606, 7.6158640961707551],
					[65.956583350575002, 7.6158640901874897],
					[65.956583386390093, 7.6158640816503222],
					[65.956758327888807, 7.6157808090917012],
					[65.957030564086281, 7.6158307973349784],
					[65.95703059908368, 7.6158307976415154],
					[65.957030666269148, 7.6158307852070735],
					[65.95703069937386, 7.6158307746164304],
					[65.95719727401611, 7.6157362890213234],
					[65.95747784568043, 7.6156695944919903],
					[65.957477861784341, 7.6156695892207429],
					[65.957477892178687, 7.6156695765764066],
					[65.957477907365941, 7.6156695691548126],
					[65.957869501767149, 7.6154223716078819],
					[65.957925084976196, 7.6153918812430748],
					[65.958225072413413, 7.6152723857651097],
					[65.958372270690333, 7.6152168869218357],
					[65.958394354228389, 7.615208530483474],
					[65.95844706231253, 7.6152584452502241],
					[65.958447067733275, 7.6152584493568733],
					[65.958447077677945, 7.6152584576187383],
					[65.958447083098704, 7.6152584617253876],
					[65.958733154066039, 7.6154640413942696],
					[65.958822024144723, 7.6155972968522265],
					[65.958977610886507, 7.6159583672411229],
					[65.959083108073926, 7.6163250552837107],
					[65.959163707053307, 7.6166056520923897],
					[65.959285905176088, 7.6170750454114939],
					[65.959333104341638, 7.6173000378880706],
					[65.959352601744541, 7.617411130253088],
					[65.959380400721471, 7.6176278239523354],
					[65.959477601353171, 7.6185028197151823],
					[65.959524799726751, 7.6190444142264457],
					[65.959555399819365, 7.6196000102520189],
					[65.95958870034697, 7.6202722050619629],
					[65.959591500203061, 7.620697200229964],
					[65.95958590011027, 7.6213443924280089],
					[65.959555400621056, 7.6217999833711048],
					[65.9595109021095, 7.6222443716468016],
					[65.959383104041009, 7.6229082574988496],
					[65.959366508852113, 7.6229777413654043],
					[65.959338713561806, 7.6230499290810085],
					[65.959147016186222, 7.6235416219092862],
					[65.958922021289908, 7.6240332105321995],
					[65.958899829910209, 7.6240748941174834],
					[65.95886373832694, 7.6241248824218157],
					[65.9584554455773, 7.6246776720921883],
					[65.958035969648634, 7.6251220463192046],
					[65.958010989163213, 7.6251387336985417],
					[65.958010987389144, 7.6251387359938656],
					[65.958010982924492, 7.6251387384337788],
					[65.958010981130769, 7.6251387385301719],
					[65.957947081328811, 7.6251859389385173],
					[65.957947080431936, 7.6251859389867125],
					[65.957947078657867, 7.6251859412820258],
					[65.957649879405537, 7.6254109408293367],
					[65.957649858057664, 7.6254109617759234],
					[65.957649824409216, 7.6254110119828633],
					[65.957649810275555, 7.6254110369417729],
					[65.957566521122146, 7.625663802737658],
					[65.957391543718757, 7.6258804734087402],
					[65.95739153135915, 7.6258804960719226],
					[65.957391510246936, 7.6258805434044872],
					[65.957391503288136, 7.6258805679775294],
					[65.957319202572023, 7.626319366184223],
					[65.957319201714469, 7.6263193706301164],
					[65.957319200876498, 7.6263193772749061],
					[65.957319200915776, 7.6263193816726478],
					[65.957283101083007, 7.6266999799840924],
					[65.9572831002843, 7.6266999910265953],
					[65.957283101357902, 7.6267000107683076],
					[65.957283100559209, 7.6267000218108105],
					[65.957358100641542, 7.6274167202082745],
					[65.957358100680807, 7.6274167246060456],
					[65.957358101616919, 7.6274167289556809],
					[65.957358103449906, 7.6274167332571983],
					[65.957424799607807, 7.6278111171776395],
					[65.95742759964368, 7.6285139019980122],
					[65.957427599663276, 7.628513904196895],
					[65.957427599722124, 7.6285139107935604],
					[65.957427600638596, 7.6285139129443662],
					[65.957452600338897, 7.6288611047808672],
					[65.957435899699888, 7.6296138966293201],
					[65.957435899699888, 7.6296138966293201],
					[65.957435899739082, 7.629613901027108],
					[65.957435899739082, 7.629613901027108],
					[65.957438699861058, 7.6300277946494592],
					[65.957402600284283, 7.630716690018871],
					[65.957402600303865, 7.6307166922177521],
					[65.957402600343016, 7.6307166966155178],
					[65.957402600382181, 7.630716701013303],
					[65.957402600051225, 7.6312388992500146],
					[65.957402602021091, 7.6312389189440344],
					[65.957402609567865, 7.6312389603390125],
					[65.957402616022051, 7.6312389797931237],
					[65.961541515838718, 7.6408833783968264],
					[65.961541532215605, 7.6408834039300784],
					[65.961541569395365, 7.6408834481605759],
					[65.96154159109517, 7.6408834668101688],
					[65.961741535186746, 7.6410140320611939],
					[65.961833101048867, 7.6412611378440474],
					[65.961838699666259, 7.6414806050848894],
					[65.961838699685686, 7.6414806072841612],
					[65.961838699724538, 7.6414806116827085],
					[65.96183870066028, 7.6414806160336415],
					[65.961894199766206, 7.6422278080369805],
					[65.96189420044044, 7.642577800761936],
					[65.961894199582403, 7.6425778052080879],
					[65.961894200556884, 7.6425778139576312],
					[65.961894200595708, 7.6425778183561892],
					[65.961935900249486, 7.6430056110599454],
					[65.9619525997594, 7.6436750055954805],
					[65.961952599798195, 7.6436750099940509],
					[65.96195260170829, 7.6436750230946702],
					[65.961952601766484, 7.6436750296925462],
					[65.962230402350343, 7.6454972293029764],
					[65.962230403305327, 7.6454972358534352],
					[65.96223040611217, 7.6454972489068389],
					[65.962230407047741, 7.6454972532579699],
					[65.962330406855159, 7.6458528545983535],
					[65.96233040870699, 7.6458528611013712],
					[65.962330414204388, 7.6458528740124256],
					[65.962330416036849, 7.6458528783161039],
					[65.962688714942971, 7.6466917757047757],
					[65.962783113647134, 7.6469306720646379],
					[65.962783113666489, 7.6469306742639969],
					[65.963147014239937, 7.647850073654495],
					[65.963147016988557, 7.6478500801104516],
					[65.963147023382632, 7.6478500929749362],
					[65.963147026131267, 7.6478500994308725],
					[65.963235917139528, 7.6480056836187185],
					[65.963402610729332, 7.6484917643679262],
					[65.963402624414314, 7.6484917900500795],
					[65.963402656230002, 7.6484918367786046],
					[65.963402676154459, 7.648491857730197],
					[65.963602675655508, 7.6486501571341101],
					[65.963602681056059, 7.6486501590492511],
					[65.963602690998954, 7.6486501673257363],
					[65.96360269641886, 7.6486501714403072],
					[65.963744295819822, 7.6487362716482759],
					[65.96374435335494, 7.6487362840119131],
					[65.963744466360936, 7.6487362780417003],
					[65.963744520934966, 7.6487362597552311],
					[65.964050110615375, 7.6485029679504803],
					[65.964338999800319, 7.6483362738669891],
					[65.964339014950511, 7.6483362620637516],
					[65.964339041663408, 7.6483362386468272],
					[65.964339055019863, 7.6483362269383646],
					[65.964497343387023, 7.64814184177028],
					[65.964758428170072, 7.6479251548550033],
					[65.964758429047635, 7.6479251526080603],
					[65.964941810645556, 7.647769568926261],
					[65.96501398899062, 7.6477334790437803],
					[65.965013991681204, 7.6477334789015208],
					[65.965013997023803, 7.6477334742178913],
					[65.965013999714387, 7.64773347407563],
					[65.965388976887724, 7.6475168864963754],
					[65.965555617090374, 7.6474724019181552],
					[65.965836081880241, 7.647497398936963],
					[65.96583608277713, 7.6474973988895316],
					[65.965836083673977, 7.6474973988421011],
					[65.965836084570881, 7.6474973987946697],
					[65.965980510226046, 7.6475084950841312],
					[65.966288758158058, 7.6478195412216605],
					[65.966288765391042, 7.6478195474413022],
					[65.966288779857024, 7.6478195598805643],
					[65.966288787986883, 7.6478195660527648],
					[65.966297049863329, 7.6478251393012799],
					[65.966308120202171, 7.6478444878234217],
					[65.966522009316932, 7.648369462749125],
					[65.966658094619532, 7.6489250051309927],
					[65.966572004427618, 7.6493666629879442],
					[65.966572001949444, 7.6493666873266459],
					[65.966572006000433, 7.6493667399297776],
					[65.966572011632735, 7.6493667682415536],
					[65.966755403829794, 7.6498778471786464],
					[65.966769202355195, 7.6499861251464143],
					[65.96676926407612, 7.6499862055158454],
					[65.966769427047197, 7.6499862695366865],
					[65.966769527400473, 7.6499862532354426],
					[65.967202781894613, 7.6496280751256238],
					[65.967305452746999, 7.6497390349340515],
					[65.967305509558912, 7.6497390671467365],
					[65.967305629971591, 7.6497390871981628],
					[65.967305693591655, 7.6497390772366769],
					[65.967650029201693, 7.6495558113692477],
					[65.967802757248933, 7.6495890949114758],
					[65.967802789574989, 7.6495890976060386],
					[65.967802855008102, 7.6495890897490426],
					[65.967802887218269, 7.6495890792448256],
					[65.968097268615992, 7.6494473899801134],
					[65.968322249954824, 7.6493890937432241],
					[65.968322259801113, 7.649389091022317],
					[65.968322277680628, 7.6493890834753842],
					[65.968322288423806, 7.649389080707107],
					[65.968544471806368, 7.6492807863395891],
					[65.968836151619854, 7.6491973931754789],
					[65.968991732846945, 7.6491584980413068],
					[65.969299986833036, 7.649133502206098],
					[65.969441658251682, 7.6491640943488486],
					[65.969441658270995, 7.6491640965488035],
					[65.969441659167884, 7.6491640965014227],
					[65.969705504119077, 7.6492195833870653],
					[65.969891563044456, 7.6493945456929815],
					[65.969891563960601, 7.6493945478455823],
					[65.969891566670555, 7.6493945499034348],
					[65.969891567567402, 7.649394549856062],
					[65.970027630376975, 7.6495140177431251],
					[65.970149813122418, 7.6498417693879937],
					[65.970149815890281, 7.6498417780459214],
					[65.970149823200401, 7.649841793067047],
					[65.970149826845798, 7.6498417994776222],
					[65.970263709057946, 7.6500389673962035],
					[65.970349802830171, 7.6505667314246972],
					[65.970349802849469, 7.6505667336247107],
					[65.970349802849469, 7.6505667336247107],
					[65.970394201436093, 7.6508278218327392],
					[65.970399800072357, 7.650930609905906],
					[65.970399800091656, 7.650930612105924],
					[65.970399800091656, 7.650930612105924],
					[65.97039980011094, 7.6509306143059632],
					[65.970458099992285, 7.6517861084693752],
					[65.970460899693549, 7.6520278027830679],
					[65.970460899693549, 7.6520278027830679],
					[65.970460899712819, 7.6520278049831116],
					[65.970483099569975, 7.6528528059882053],
					[65.970483099569975, 7.6528528059882053],
					[65.970483099569975, 7.6528528059882053],
					[65.970483099589231, 7.652852808188233],
					[65.970494199855921, 7.6531250056427789],
					[65.970502599996308, 7.6539250024593377],
					[65.970502600054047, 7.6539250090594262],
					[65.970502601047201, 7.6539250200123767],
					[65.970502602021028, 7.6539250287653013],
					[65.970544199734235, 7.6542222168924194],
					[65.970563700035754, 7.6548889059114664],
					[65.970563703834841, 7.6548889299231702],
					[65.97056371681424, 7.6548889776634903],
					[65.970563725975353, 7.6548889991920461],
					[65.970805414573363, 7.6553111789130082],
					[65.970819208124567, 7.6553583554446947],
					[65.970819258907156, 7.6553584166051785],
					[65.970819389588499, 7.6553584823681309],
					[65.970819470384129, 7.6553584869234319],
					[65.97084170193034, 7.6553502127081572],
					[65.971213834373359, 7.6554807883316665],
					[65.971213872099781, 7.6554807929511091],
					[65.971213946559601, 7.6554807912366956],
					[65.971213984151433, 7.6554807804554947],
					[65.971291733759188, 7.6554446040964406],
					[65.971649978013559, 7.6554834985532905],
					[65.971649985188577, 7.6554834981759816],
					[65.971649998660922, 7.6554834996686569],
					[65.971650005835912, 7.6554834992913499],
					[65.971738859408021, 7.6554808012007722],
					[65.972036005019646, 7.6556168779927081],
					[65.9721887680506, 7.6557112535022132],
					[65.972588746785846, 7.6561834291276814],
					[65.972588747682707, 7.6561834290805422],
					[65.972588748598795, 7.656183431233611],
					[65.972644240262085, 7.6562473218190021],
					[65.972685930688641, 7.6563112072182857],
					[65.973097029621911, 7.6569779043979755],
					[65.973097032350964, 7.6569779086571419],
					[65.973097036892923, 7.6569779150223587],
					[65.973097039621976, 7.6569779192815259],
					[65.973394229860389, 7.6573751069612994],
					[65.973552623210281, 7.6576750926363149],
					[65.973552625958476, 7.6576750990959486],
					[65.973552631416524, 7.657675107614585],
					[65.973552634145548, 7.6576751118739219],
					[65.973905432102384, 7.6582029090013002],
					[65.974008130020749, 7.6583695055444068],
					[65.974008130020749, 7.6583695055444068],
					[65.974008131833685, 7.6583695076506073],
					[65.974008132749759, 7.6583695098038742],
					[65.974055420024001, 7.6584417904287427],
					[65.974316511024512, 7.6591694671373487],
					[65.974438704724676, 7.6595222467058219],
					[65.974469201964496, 7.6597361257053294],
					[65.974566501177151, 7.6606167219131285],
					[65.974566502093197, 7.6606167240665419],
					[65.974566503066654, 7.6606167328210955],
					[65.974566503085811, 7.6606167350214998],
					[65.97463590063775, 7.6610000256474358],
					[65.974685900332346, 7.661711114592161],
					[65.974685902221822, 7.6617111255002577],
					[65.974685906935818, 7.6617111516702989],
					[65.97468590972214, 7.6617111625314465],
					[65.974941509927092, 7.6624833619275767],
					[65.974941510843067, 7.662483364081071],
					[65.974941511778226, 7.6624833684349838],
					[65.974941512694187, 7.6624833705884798],
					[65.975058113199907, 7.6627917711325093],
					[65.975058117799023, 7.6627917841005546],
					[65.97505813144339, 7.6627918054011479],
					[65.975058137817157, 7.6627918160749129],
					[65.975397037942287, 7.6632612169093646],
					[65.975397048819488, 7.6632612295493532],
					[65.975397074161464, 7.6632612546417285],
					[65.975397087710164, 7.6632612649405605],
					[65.975680467764136, 7.6634529524589459],
					[65.975847052223898, 7.6636362354833381],
					[65.975847060353132, 7.663636241662851],
					[65.975847075733839, 7.6636362562693012],
					[65.975847083863059, 7.6636362624488141],
					[65.97612768400181, 7.6638362637889097],
					[65.976127685795575, 7.6638362636951483],
					[65.976127690318179, 7.6638362678617913],
					[65.97612769211193, 7.6638362677680298],
					[65.976299880055535, 7.6639473612987858],
					[65.976377667667975, 7.6640140510721713],
					[65.97675265391112, 7.664352937558351],
					[65.976899836137989, 7.6645390155700079],
					[65.977116529711694, 7.6648862043838637],
					[65.977205430005085, 7.6650307050655409],
					[65.977205466185623, 7.6650307384018266],
					[65.977205550053412, 7.6650307868604939],
					[65.97720559772155, 7.6650307997822589],
					[65.980649998348966, 7.6650613005669044],
					[65.980650030598383, 7.6650612944785506],
					[65.980650093227041, 7.6650612735919079],
					[65.980650121812587, 7.6650612588872908],
					[65.980794499330358, 7.6649501762521997],
					[65.981191763350594, 7.664797389398208],
					[65.981241696986899, 7.6647835087207836],
					[65.981594310294028, 7.6648918810035376],
					[65.981691569201701, 7.6649612518399852],
					[65.981916556643029, 7.6651918397193537],
					[65.981916599044411, 7.6651918661309377],
					[65.98191669170906, 7.6651918943212287],
					[65.981916741972356, 7.6651918960999197],
					[65.987183342156086, 7.6640723962070334],
					[65.987183378794256, 7.6640723788739136],
					[65.987183443006344, 7.6640723336692842],
					[65.987183470561092, 7.664072303596261],
					[65.98751127025092, 7.6635334048974695],
					[65.9877057678912, 7.6632307090447167],
					[65.987705768768947, 7.6632307067962708],
					[65.987705769665837, 7.6632307067493421],
					[65.987705769646723, 7.6632307045478241],
					[65.987955761860519, 7.6628251173967463],
					[65.988241847302504, 7.6624918349090665],
					[65.988400140779007, 7.6623334416245372],
					[65.988400141675896, 7.6623334415775766],
					[65.98840014255363, 7.6623334393290179],
					[65.988400144347381, 7.6623334392350992],
					[65.990033443844908, 7.6606501389310564],
					[65.990033451840176, 7.6606501297009819],
					[65.990033468689219, 7.6606501067903334],
					[65.990033475768456, 7.6606500954055425],
					[65.991055776338882, 7.6587528951786608],
					[65.991055779849688, 7.6587528861830583],
					[65.991055787748863, 7.6587528659429802],
					[65.991055792156502, 7.6587528569002865],
					[65.991719591732249, 7.6565444586503686],
					[65.99171959259067, 7.6565444541994792],
					[65.991719595204444, 7.6565444452505114],
					[65.991719595185231, 7.6565444430486531],
					[65.992522389336358, 7.6528306721024189],
					[65.993711273961281, 7.650750098382936],
					[65.99371128014289, 7.6507500870411382],
					[65.993711289776911, 7.6507500600956302],
					[65.993711295042345, 7.6507500465991871],
					[65.994633487576465, 7.6468500735919456],
					[65.99719187394868, 7.6423028972086504],
					[65.997191881884561, 7.6423028813632596],
					[65.997191893252548, 7.6423028477085557],
					[65.997191897600928, 7.6423028320538968],
					[65.999136297589828, 7.6299278313702752],
					[65.999136299187597, 7.629927809249252],
					[65.999136296105092, 7.6299277653440756],
					[65.999136292341291, 7.629927745714256],
					[65.996094591979229, 7.619363844816311],
					[65.996094589189909, 7.6193638339507395],
					[65.996094580960204, 7.6193638167694768],
					[65.99609457727405, 7.6193638059523892],
					[65.995769578644087, 7.6187443095729019],
					[65.995639080306077, 7.6184749123460582],
					[65.99525578085651, 7.6176693140121641],
					[65.995255779959649, 7.6176693140607092],
					[65.995255779959649, 7.6176693140607092],
					[65.995180779740267, 7.617513813700854],
					[65.995180778823624, 7.6175138115472816],
					[65.994725199782295, 7.6165999520737522],
					[65.994725200182302, 7.616597200309231],
					[65.994725197254297, 7.6165971740299536],
					[65.994725185159865, 7.6165971262156225],
					[65.994725175096576, 7.6165971047291467],
					[65.994269575269016, 7.6157638048790508],
					[65.99426957524922, 7.6157638026770096],
					[65.994269574332591, 7.6157638005235757],
					[65.994269573435702, 7.6157638005721857],
					[65.99413347382432, 7.6155277006016382],
					[65.993811274095961, 7.6149610019430236],
					[65.993811273179276, 7.614960999789659],
					[65.993811273179276, 7.614960999789659],
					[65.993811272282429, 7.614960999838293],
					[65.993516874796157, 7.6144610026038801],
					[65.993355777028157, 7.61415550665295],
					[65.993355777028157, 7.61415550665295],
					[65.992947379386379, 7.6133888089961435],
					[65.992900181059326, 7.6132888149679152],
					[65.992900106981622, 7.6132887595086869],
					[65.992899945230164, 7.6132887330424355],
					[65.99289985663971, 7.6132887598821855],
					[65.991783156852819, 7.6144331608320588],
					[65.99178314443553, 7.614433176925969],
					[65.991783122311361, 7.6144332111696258],
					[65.991783112604466, 7.6144332293194132],
					[65.990380418954331, 7.6182082134717843],
					[65.989313728726771, 7.6199831974203587],
					[65.989313726952759, 7.6199831997188756],
					[65.989313723424544, 7.6199832065175448],
					[65.989313720773467, 7.6199832110661241],
					[65.987330421315505, 7.6239277089667956],
					[65.987330420438312, 7.6239277112165738],
					[65.987330418683939, 7.6239277157160865],
					[65.987330417806774, 7.6239277179658425],
					[65.985347036295977, 7.628316578271475],
					[65.98433607109655, 7.6291914589669059],
					[65.981630720082833, 7.6277970364295866],
					[65.980266842349039, 7.6264137590901155],
					[65.980266836012049, 7.626413752825],
					[65.980266821564072, 7.6264137425919944],
					[65.980266815227083, 7.6264137363268585],
					[65.97831684156732, 7.6250442540759886],
					[65.976266861423767, 7.6222581807347316],
					[65.976266855983511, 7.6222581744232567],
					[65.976266845102927, 7.6222581618003282],
					[65.976266838765795, 7.6222581555372155],
					[65.974830761881336, 7.6208692777824885],
					[65.973252976682204, 7.6178916069323908],
					[65.973252971221996, 7.6178915984223714],
					[65.973252960301537, 7.6178915814022936],
					[65.973252953944495, 7.6178915729407581],
					[65.971497375597679, 7.6157720999458718],
					[65.97057799098836, 7.6132805383145232],
					[65.969330793650443, 7.6086471489850913],
					[65.968494593761406, 7.6053332516247227],
					[65.968494593741454, 7.6053332494249108],
					[65.968494592824655, 7.6053332472740136],
					[65.968494592824655, 7.6053332472740136],
					[65.967397393652959, 7.6013027504654485],
					[65.966314097241977, 7.5967693653284538],
					[65.965664192185173, 7.5913090610742735],
					[65.966108131763221, 7.5919390096902086],
					[65.966116529373394, 7.5919529025437829],
					[65.966116530290364, 7.591952904694022],
					[65.966116532124332, 7.5919529089944531],
					[65.966116533021193, 7.5919529089450739],
					[65.966122033209274, 7.5919612108830172],
					[65.966122034106149, 7.5919612108336372],
					[65.966122035023105, 7.5919612129838541],
					[65.966122035023105, 7.5919612129838541],
					[65.966572034813055, 7.5926140133264486],
					[65.966852635264701, 7.5930223136435453],
					[65.966852637078532, 7.5930223157445198],
					[65.966852638912457, 7.5930223200451739],
					[65.966852640706179, 7.5930223199464857],
					[65.967027635610293, 7.5932529142717016],
					[65.967324829747838, 7.5937195069760408],
					[65.96748042893779, 7.59397510404402],
					[65.967480429854717, 7.5939751061944438],
					[65.967480431668534, 7.5939751082955356],
					[65.967480434379212, 7.5939751103473156],
					[65.967552634118363, 7.5940834115377935],
					[65.967552634118363, 7.5940834115377935],
					[65.96755263503529, 7.5940834136882094],
					[65.96755263503529, 7.5940834136882094],
					[65.967935935409258, 7.5946390144678997],
					[65.967935940850666, 7.5946390207714289],
					[65.96793595354724, 7.5946390354797035],
					[65.967935960802478, 7.594639043884408],
					[65.96834425958599, 7.5950362428205818],
					[65.968388759394514, 7.5950807408711922],
					[65.968388765732769, 7.5950807471256718],
					[65.968388782016717, 7.5950807616373055],
					[65.968388790148666, 7.5950807677932355],
					[65.968469289212251, 7.5951334651956479],
					[65.968841584834436, 7.5953862643369598],
					[65.969002683188535, 7.5955029618109711],
					[65.96900268589917, 7.5955029638630345],
					[65.969002692217316, 7.5955029679179002],
					[65.969002694927966, 7.5955029699699619],
					[65.969291580223199, 7.595680761959958],
					[65.969685966769248, 7.5960307492181345],
					[65.969685976714942, 7.5960307574760932],
					[65.969685996586207, 7.5960307717920852],
					[65.969686007408697, 7.596030777800892],
					[65.969744282726296, 7.5960612645922918],
					[65.969847061722859, 7.5961584444342236],
					[65.970197062356888, 7.596491845949596],
					[65.970197065964399, 7.5964918479526489],
					[65.970197074096276, 7.5964918541094368],
					[65.970197077723824, 7.5964918583124428],
					[65.970413777579125, 7.5966584594470046],
					[65.970413812716913, 7.5966584751270707],
					[65.970413887376438, 7.5966584952411944],
					[65.970413925981234, 7.5966584975245102],
					[65.970647226168182, 7.5966279981043314],
					[65.970647266426226, 7.5966279848892206],
					[65.970647335999445, 7.5966279392497604],
					[65.970647367128393, 7.5966279089269708],
					[65.970777966906553, 7.5964307104171178],
					[65.970777978364922, 7.5964306877770502],
					[65.970777994943504, 7.5964306362415428],
					[65.970778000083797, 7.5964306095461014],
					[65.97079460002017, 7.5961083102171987],
					[65.97079459998001, 7.5961083058171956],
					[65.970794599899719, 7.5961082970171701],
					[65.970794599839479, 7.5961082904171455],
					[65.970758499838297, 7.5953138909498685],
					[65.970758498861102, 7.5953138821991306],
					[65.970758496946885, 7.5953138690976685],
					[65.970758495969662, 7.5953138603469492],
					[65.970697395685221, 7.5950110595518376],
					[65.970697394768266, 7.5950110574011278],
					[65.970697393811164, 7.5950110508504132],
					[65.970697392894195, 7.595011048699706],
					[65.970630793299819, 7.5947638474637271],
					[65.97063079327971, 7.5947638452637412],
					[65.970630792362783, 7.5947638431130242],
					[65.970630790548995, 7.5947638410116189],
					[65.970375191099066, 7.593927741939372],
					[65.970375190161974, 7.5939277375887455],
					[65.970375188307969, 7.5939277310874944],
					[65.970375185577183, 7.5939277268355063],
					[65.970169588313425, 7.5934027326146989],
					[65.969986290436992, 7.5928499398051548],
					[65.969705793362735, 7.5919471475510401],
					[65.969664096598592, 7.5917666649848004],
					[65.969644597769189, 7.5916249724841522],
					[65.969644597728902, 7.5916249680843553],
					[65.969644595894906, 7.5916249637833566],
					[65.969644595854618, 7.591624959383541],
					[65.969447396751562, 7.5906777633154325],
					[65.969305797995673, 7.5897555709770863],
					[65.969283497623849, 7.5895860729686886],
					[65.969283497623849, 7.5895860729686886],
					[65.969283497623849, 7.5895860729686886],
					[65.969233497872864, 7.5892277715822374],
					[65.969130797874456, 7.588494372305961],
					[65.969130797854262, 7.5884943701061101],
					[65.969130796917028, 7.5884943657559161],
					[65.969130796896849, 7.5884943635560438],
					[65.968758496655994, 7.5864388648694181],
					[65.968758494801676, 7.5864388583691227],
					[65.968758491989846, 7.586438845318936],
					[65.968758491032375, 7.586438838769042],
					[65.968719590750624, 7.586316638575374],
					[65.96871958708239, 7.5863166299744274],
					[65.968719579745894, 7.5863166127725581],
					[65.968719575180785, 7.5863166042211958],
					[65.968133475404471, 7.5852499040245913],
					[65.968133467211487, 7.5852498912725643],
					[65.96813344905199, 7.5852498680675096],
					[65.96813343908552, 7.5852498576144827],
					[65.967847339332195, 7.5849720560093177],
					[65.96784728434119, 7.5849720282402204],
					[65.967847168445388, 7.5849720126447746],
					[65.967847106623537, 7.5849720226683184],
					[65.967747204027731, 7.5850247722995112],
					[65.96739729931177, 7.5848248258785036],
					[65.967397259729012, 7.5848248148640902],
					[65.967397178870996, 7.5848248039330208],
					[65.967397137656448, 7.5848248106155003],
					[65.967227781064011, 7.5848803953324824],
					[65.966950025612533, 7.5848442011092754],
					[65.966950011262938, 7.5848442019033566],
					[65.966949981646721, 7.5848442013414887],
					[65.966949967297154, 7.5848442021355682],
					[65.966766679003371, 7.5848748010531075],
					[65.966500040093905, 7.5848858981411311],
					[65.966266783714829, 7.5847776180556155],
					[65.966266777416649, 7.5847776162034304],
					[65.966266763903221, 7.5847776103490681],
					[65.966266756708208, 7.5847776085465126],
					[65.966050123028722, 7.584713727405509],
					[65.965741869236766, 7.584280491965302],
					[65.965597383075803, 7.5840221170551141],
					[65.965308488969384, 7.5832055339307942],
					[65.965136289366825, 7.5826971356238184],
					[65.965136289346546, 7.5826971334243165],
					[65.965136286595225, 7.5826971269748933],
					[65.965136285698364, 7.582697127024594],
					[65.964908496138662, 7.5821249516863789],
					[65.964269601745073, 7.576177805563864],
					[65.965216897753891, 7.5702278310856688],
					[65.965216897692557, 7.5702278244871772],
					[65.965216900260401, 7.5702278111397598],
					[65.965216900219517, 7.5702278067407445],
					[65.965405800279257, 7.5639833053650332],
					[65.965405800238187, 7.5639833009660196],
					[65.965405800114979, 7.5639832877689548],
					[65.965405799156528, 7.5639832812207697],
					[65.965141899123552, 7.5609805828357786],
					[65.96514189439246, 7.5609805566942683],
					[65.965141876899821, 7.5609805092643771],
					[65.96514186503515, 7.5609804879255318],
					[65.96505016805213, 7.5608470910197054],
					[65.964855771263103, 7.5605276951426665],
					[65.964855771263103, 7.5605276951426665],
					[65.964855770366299, 7.5605276951931435],
					[65.964594571036557, 7.5601026951747814],
					[65.964483470956665, 7.5599192968664157],
					[65.964483470039212, 7.5599192947174911],
					[65.964139069713838, 7.5593581947736492],
					[65.964139069713838, 7.5593581947736492],
					[65.964139067899566, 7.5593581926752895],
					[65.964139067878946, 7.5593581904758951],
					[65.963811268170062, 7.5588554919624373],
					[65.963811267252609, 7.5588554898136069],
					[65.963811267252609, 7.5588554898136069],
					[65.963811266355776, 7.5588554898641389],
					[65.96368346597437, 7.5586637882208967],
					[65.963683465056889, 7.558663786072084],
					[65.96368346234577, 7.5586637840243505],
					[65.963683461428332, 7.558663781875536],
					[65.963489061295363, 7.5583998809961548],
					[65.96348906039853, 7.5583998810467046],
					[65.96348905858423, 7.5583998789484657],
					[65.963489057666806, 7.5583998767996556],
					[65.963230757618831, 7.5580665767957349],
					[65.96323075672197, 7.5580665768462945],
					[65.963230755804531, 7.5580665746975404],
					[65.963016859367627, 7.5577998786359002],
					[65.96277516383833, 7.5574692849946947],
					[65.962386265623039, 7.5568915888157351],
					[65.9623862647056, 7.5568915866670716],
					[65.962386262891272, 7.5568915845690299],
					[65.962386261973791, 7.5568915824203904],
					[65.962322365664775, 7.5568026890666724],
					[65.962283469976242, 7.5567415957691964],
					[65.961864071614457, 7.5560442974903994],
					[65.961650172792687, 7.5556776993103165],
					[65.961650170978359, 7.5556776972124204],
					[65.961650169143383, 7.5556776929153484],
					[65.961650167329054, 7.5556776908174514],
					[65.961408466684617, 7.5553081912631583],
					[65.961408463932116, 7.5553081848176307],
					[65.961408455757294, 7.5553081742777275],
					[65.961408452128637, 7.5553081700820064],
					[65.961091855420378, 7.5549387719615346],
					[65.960955757698386, 7.5547637772032923],
					[65.960955754966548, 7.554763772957072],
					[65.960955750441059, 7.554763768812192],
					[65.960955746812346, 7.5547637646166237],
					[65.960825147335129, 7.5546220636364909],
					[65.960825146438282, 7.554622063687165],
					[65.960825141912792, 7.5546220595423321],
					[65.960825140995254, 7.5546220573938827],
					[65.960502941256209, 7.5543026589122055],
					[65.96050293491632, 7.5543026526697767],
					[65.960502924050914, 7.5543026422826003],
					[65.960502917731731, 7.5543026382392249],
					[65.960297322172849, 7.5541526418119131],
					[65.960050126751057, 7.5539498444072741],
					[65.960050124957377, 7.5539498445086686],
					[65.960050121328663, 7.5539498403133631],
					[65.960050118617488, 7.5539498382664148],
					[65.959614021844715, 7.5536276413562922],
					[65.959600124865574, 7.5536165439660152],
					[65.959600121257523, 7.5536165419698484],
					[65.959600114917649, 7.5536165357278042],
					[65.959600111309598, 7.5536165337316392],
					[65.959566811344132, 7.553594234471233],
					[65.959566808632971, 7.5535942324243619],
					[65.959566803210549, 7.553594228330601],
					[65.959566801396207, 7.553594226233022],
					[65.95914731724028, 7.5533498379989847],
					[65.958969532289402, 7.5531942491664044],
					[65.958969529578212, 7.5531942471196238],
					[65.958969525052666, 7.5531942429753407],
					[65.958969523258986, 7.5531942430767831],
					[65.958694540844505, 7.5529804568185126],
					[65.958361256003883, 7.5525637743743079],
					[65.958361255086373, 7.5525637722261569],
					[65.958361252375155, 7.5525637701794919],
					[65.958361249663938, 7.5525637681328046],
					[65.958241851585143, 7.5524276694526558],
					[65.958122353112472, 7.5522859719973781],
					[65.958122351298087, 7.5522859699000087],
					[65.958122347669345, 7.5522859657052708],
					[65.958122346751836, 7.5522859635571509],
					[65.957789052094171, 7.5519248700847337],
					[65.957461256287274, 7.5515137746711112],
					[65.957461256287274, 7.5515137746711112],
					[65.957461253576042, 7.5515137726246282],
					[65.957461252679181, 7.5515137726754045],
					[65.957333457139157, 7.5513609750468191],
					[65.957183459588038, 7.5511609797600698],
					[65.957183455041701, 7.5511609734176259],
					[65.957183444176096, 7.5511609630331167],
					[65.957183438732926, 7.5511609567414801],
					[65.956880750262556, 7.55086926591588],
					[65.956572364793161, 7.5504637863861559],
					[65.956427973127489, 7.5502276987739609],
					[65.955972375401743, 7.5494027025407009],
					[65.955972367226565, 7.549402692004886],
					[65.955972350855347, 7.5494026687345768],
					[65.955972340886461, 7.5494026583004574],
					[65.955969599746126, 7.5493999164928427],
					[65.95596960014025, 7.5493971996411853],
					[65.955969598139134, 7.5493971777560169],
					[65.95596958785913, 7.5493971343416275],
					[65.95596958139464, 7.5493971149093735],
					[65.955511281000966, 7.5484360134313029],
					[65.955511279186538, 7.5484360113344202],
					[65.955511276433754, 7.548436004891113],
					[65.955511273722522, 7.5484360028451079],
					[65.955450178871558, 7.5483277115310585],
					[65.955052983192843, 7.5474138204638805],
					[65.955052976790384, 7.547413807628649],
					[65.955052962191772, 7.547413782059996],
					[65.955052953119605, 7.5474137715761298],
					[65.954922353125696, 7.5472581716201272],
					[65.95492235039363, 7.5472581673756833],
					[65.9549223440535, 7.5472581611363481],
					[65.954922340424602, 7.5472581569428456],
					[65.954600139572861, 7.5469415564880542],
					[65.954600138676014, 7.5469415565389841],
					[65.954600137758433, 7.5469415543913287],
					[65.954600136861572, 7.5469415544422604],
					[65.954369537130006, 7.5467248530656805],
					[65.954369527202488, 7.5467248470303367],
					[65.954369508223508, 7.5467248327101899],
					[65.954369497378366, 7.5467248245272414],
					[65.954150111559301, 7.5466026339623884],
					[65.953702933926792, 7.5462415514089844],
					[65.953697341018511, 7.5462359586499153],
					[65.953689041325703, 7.546227657907286],
					[65.953689038614399, 7.5462276558616619],
					[65.953689034088669, 7.5462276517194358],
					[65.95368903137738, 7.5462276496738099],
					[65.953244553250627, 7.5458415686267788],
					[65.952822368925823, 7.5451776919049296],
					[65.952822361667927, 7.5451776835191762],
					[65.952822348945858, 7.5451776666457118],
					[65.952822341687963, 7.5451776582599583],
					[65.952789062038732, 7.5451442798384125],
					[65.95272517462557, 7.545027704695431],
					[65.952725173707947, 7.5450277025480244],
					[65.95272517095502, 7.5450276961057821],
					[65.952725170037382, 7.5450276939583762],
					[65.952333470612402, 7.5443942958640644],
					[65.952166872409379, 7.5441137982493665],
					[65.952166866048273, 7.5441137898131041],
					[65.952166855140547, 7.5441137750369069],
					[65.952166847882623, 7.544113766651666],
					[65.951877956845181, 7.5437942769664135],
					[65.951505764912284, 7.5432498876135607],
					[65.951505762180119, 7.5432498833701089],
					[65.951505754922152, 7.5432498749853494],
					[65.951505752189973, 7.5432498707418993],
					[65.951425151728458, 7.5431554692595215],
					[65.951425140862327, 7.5431554588807259],
					[65.951425117336328, 7.5431554382252708],
					[65.95142510469735, 7.5431554301468715],
					[65.951280713981689, 7.5430665348359227],
					[65.950972322080702, 7.5428276416885351],
					[65.950972319369356, 7.5428276396434635],
					[65.950972312132194, 7.5428276334572448],
					[65.950972307627183, 7.5428276315143234],
					[65.950825107914952, 7.5427331326488609],
					[65.950825070978041, 7.5427331171567387],
					[65.950824992786863, 7.5427331040137631],
					[65.950824953326162, 7.542733106260755],
					[65.950524972406257, 7.5428054014037533],
					[65.950399991330187, 7.5428108989021672],
					[65.950399952849637, 7.5428109098907212],
					[65.950399883167435, 7.5428109424503598],
					[65.950399851986589, 7.5428109662196228],
					[65.950108167602721, 7.5431331468166745],
					[65.950083189159685, 7.5431498344694425],
					[65.950083168678319, 7.5431498510308437],
					[65.950083134952692, 7.5431498903397651],
					[65.950083120790808, 7.5431499109401656],
					[65.95007762835732, 7.5431609960551],
					[65.950055443867797, 7.5431915741153244],
					[65.949633151884782, 7.5436581655896928],
					[65.949633151008783, 7.5436581678388759],
					[65.94963315011195, 7.5436581678899097],
					[65.949633149215131, 7.5436581679409418],
					[65.949272055159909, 7.5440748620809996],
					[65.949191563021202, 7.5441526550541678],
					[65.949038885789747, 7.5442914440679791],
					[65.948622355226149, 7.5439887639084411],
					[65.948341877344163, 7.5434582069698042],
					[65.948341876426497, 7.5434582048228211],
					[65.948341874612012, 7.5434582027268728],
					[65.948341873715194, 7.5434582027779076],
					[65.948164074601848, 7.5431416040244788],
					[65.947752975105132, 7.5423916048169533],
					[65.94775296872308, 7.5423915941845943],
					[65.947752955979837, 7.5423915751178425],
					[65.947752948721828, 7.5423915667345449],
					[65.947708448641066, 7.5423415674589789],
					[65.947708438671697, 7.5423415570309658],
					[65.94770841604246, 7.5423415363281237],
					[65.947708403403411, 7.5423415282512796],
					[65.947680702777092, 7.5423248286176525],
					[65.947680701880273, 7.5423248286687254],
					[65.947680700065746, 7.5423248265729121],
					[65.947680699168899, 7.5423248266239886],
					[65.947258399446611, 7.5420831260035115],
					[65.947258386828423, 7.542083120124861],
					[65.947258359819173, 7.542083110667674],
					[65.947258347221833, 7.5420831069869703],
					[65.946980646995115, 7.5420165065131934],
					[65.946980646077421, 7.5420165043663747],
					[65.946980645180602, 7.5420165044174574],
					[65.946980644283755, 7.5420165044685419],
					[65.946808367126579, 7.5419776099412239],
					[65.946561188594472, 7.5418554203760211],
					[65.946561187697625, 7.5418554204271118],
					[65.946561185883112, 7.5418554183314219],
					[65.946561184089433, 7.5418554184335989],
					[65.946358427134783, 7.5417609395237051],
					[65.946064059358491, 7.5413887779387094],
					[65.945902966794733, 7.5411665893186655],
					[65.945569571373042, 7.5406137969571834],
					[65.945569570455305, 7.5406137948105343],
					[65.945569565908585, 7.5406137884728199],
					[65.945569564990862, 7.5406137863261691],
					[65.945447376154888, 7.5404360021364996],
					[65.945400182765781, 7.5403277203190555],
					[65.945400181827239, 7.5403277159746631],
					[65.945400179074113, 7.5403277095347629],
					[65.945400177259586, 7.5403277074392756],
					[65.944991876935504, 7.5395471063139308],
					[65.944991876017767, 7.5395471041673687],
					[65.944991874203225, 7.5395471020719693],
					[65.944991873285545, 7.5395470999254037],
					[65.944825173416277, 7.5392609997837772],
					[65.944825171601721, 7.5392609976884133],
					[65.944533475661501, 7.5387693038753136],
					[65.944244578803804, 7.5381943091871699],
					[65.944077981659277, 7.5378638166439682],
					[65.943777986070742, 7.5371221261845456],
					[65.943616886109695, 7.5367110274230233],
					[65.943358485980809, 7.5360471261813755],
					[65.94315848626357, 7.5355360273972085],
					[65.943158486242638, 7.5355360251996411],
					[65.943158485345791, 7.5355360252509431],
					[65.943158485324844, 7.5355360230533739],
					[65.942927984986852, 7.5349721242861198],
					[65.942927984965905, 7.534972122088571],
					[65.942927983151293, 7.5349721199936655],
					[65.942927983151293, 7.5349721199936655],
					[65.942700183393853, 7.5344610199149589],
					[65.942700181579241, 7.5344610178201075],
					[65.942700181558294, 7.5344610156225773],
					[65.942700180661475, 7.5344610156739149],
					[65.94243908092561, 7.5338999155349144],
					[65.942241881930016, 7.5334749171575703],
					[65.941955782798175, 7.5328277184523644],
					[65.941955781880338, 7.5328277163062962],
					[65.941955780044722, 7.5328277120141589],
					[65.941955779126943, 7.5328277098680871],
					[65.941783480710143, 7.5324860143756034],
					[65.941466885434778, 7.5317582240340073],
					[65.941322387373702, 7.5313860291243175],
					[65.941069590372081, 7.5306805385267594],
					[65.94086129174174, 7.5299582446716036],
					[65.94086129174174, 7.5299582446716036],
					[65.940861291720736, 7.5299582424742377],
					[65.940861290823875, 7.529958242525729],
					[65.940752991764867, 7.5295999446560122],
					[65.940575193318367, 7.5289332496770784],
					[65.94046689359304, 7.5285166502929606],
					[65.940466892654115, 7.52851664594984],
					[65.940466891736264, 7.5285166438040498],
					[65.940466890818399, 7.5285166416582623],
					[65.940397390724328, 7.5282916405681819],
					[65.940397389806449, 7.5282916384224059],
					[65.940397388867567, 7.5282916340793049],
					[65.940397387949687, 7.5282916319335085],
					[65.940083487657475, 7.527438830976239],
					[65.939936288245178, 7.5270249319894953],
					[65.939936288245178, 7.5270249319894953],
					[65.939936288245178, 7.5270249319894953],
					[65.939691887718482, 7.5263638301053621],
					[65.939691886800603, 7.5263638279597123],
					[65.939691885903756, 7.5263638280113279],
					[65.939691885882681, 7.5263638258140668],
					[65.939475185760784, 7.5258221249262283],
					[65.939475184863937, 7.5258221249778625],
					[65.939475183946044, 7.5258221228322322],
					[65.939475183924969, 7.5258221206349889],
					[65.939247383575832, 7.5252888220080161],
					[65.939247382657896, 7.5252888198624257],
					[65.93901688377349, 7.5247610216557375],
					[65.93878348497627, 7.5242166239710571],
					[65.938558486015253, 7.5236555255459185],
					[65.938355787455663, 7.5231416290161803],
					[65.938097386735848, 7.5224499293773057],
					[65.937955787898076, 7.5220666317537921],
					[65.937955785144197, 7.5220666253177475],
					[65.937955779636468, 7.5220666124456192],
					[65.937955776882632, 7.5220666060095729],
					[65.937677977423419, 7.521538806244461],
					[65.937677969246295, 7.5215387957250259],
					[65.937677952870914, 7.5215387724890714],
					[65.93767794379697, 7.5215387620214145],
					[65.937425155426567, 7.5212776739310039],
					[65.937172368804184, 7.520908192172465],
					[65.936669572884085, 7.5200360006265043],
					[65.936669563788897, 7.5200359879627925],
					[65.936669543804825, 7.5200359627390263],
					[65.936669533812804, 7.5200359501271237],
					[65.935914034286299, 7.5193581503125619],
					[65.935914033389452, 7.5193581503644147],
					[65.935914030677822, 7.5193581483230343],
					[65.935914029780974, 7.5193581483748853],
					[65.9322445749862, 7.5162081870355371],
					[65.930325192407665, 7.5097582439030104],
					[65.930325188735125, 7.5097582353259149],
					[65.930325183183768, 7.509758218067395],
					[65.930325178635741, 7.5097582117389132],
					[65.927602981039669, 7.5042916134691087],
					[65.9250945968016, 7.4987082509405933],
					[65.925075200028402, 7.4984138868917887],
					[65.925075200028402, 7.4984138868917887],
					[65.925075199110154, 7.4984138847483424],
					[65.925000199555058, 7.4974166895180296],
					[65.924997400290096, 7.497319393045248],
					[65.924997400268609, 7.4973193908492739],
					[65.924997399285886, 7.4973193821179551],
					[65.924997399264413, 7.4973193799219828],
					[65.924958498891954, 7.4969499816733816],
					[65.924897399374885, 7.4962277838578943],
					[65.924897399331883, 7.4962277794659666],
					[65.924897398370561, 7.4962277729306894],
					[65.924897398327573, 7.4962277685387617],
					[65.924866897767416, 7.4960305733756973],
					[65.924761298920714, 7.4951360772130444],
					[65.924761298002423, 7.4951360750697633],
					[65.924761297980908, 7.4951360728738132],
					[65.924761297980908, 7.4951360728738132],
					[65.92467239883635, 7.4944999766051161],
					[65.924627998984121, 7.4940471790315231],
					[65.924627995203096, 7.4940471594788756],
					[65.924627982260219, 7.4940471206898485],
					[65.924627973995129, 7.494047101400743],
					[65.923400173702333, 7.4919027016515436],
					[65.921791874725471, 7.488991605080936],
					[65.920197375944397, 7.48604710457262],
					[65.92019737502595, 7.4860471024300468],
					[65.920197373210669, 7.4860471003404534],
					[65.92019737229225, 7.486047098197858],
					[65.918647372983472, 7.4834081986897312],
					[65.916197374796596, 7.4791749031191657],
					[65.913380775844487, 7.4739388043322963],
					[65.913380737785843, 7.4739387670651798],
					[65.913380646619089, 7.4739387131931609],
					[65.913380594451439, 7.4739387009247409],
					[65.910130593746587, 7.4740359006287127],
					[65.910130548993294, 7.4740359120760926],
					[65.910130467776071, 7.4740359564372811],
					[65.910130434002625, 7.4740359891909689],
					[65.908335934279847, 7.4767220882656327],
					[65.908335931632934, 7.4767220928145282],
					[65.908335928986077, 7.4767220973634023],
					[65.908335927214225, 7.4767220996644763],
					[65.906316539194577, 7.4802081791862287],
					[65.903760955209705, 7.4829026611775777],
					[65.903760940158961, 7.4829026818266691],
					[65.90376091635693, 7.4829027249476976],
					[65.903760906708797, 7.4829027474726635],
					[65.902874806592976, 7.4861805472910063],
					[65.902874805717815, 7.4861805495380027],
					[65.902874805761073, 7.4861805539261619],
					[65.90287480486424, 7.4861805539790929],
					[65.90124420487588, 7.4932221548773237],
					[65.901244204897409, 7.4932221570712585],
					[65.90124420402212, 7.493222159317896],
					[65.90124420404365, 7.4932221615118317],
					[65.901210904956145, 7.4933888520564578],
					[65.90114420885719, 7.4936221388399149],
					[65.901010913744699, 7.4939999253769596],
					[65.900855420257813, 7.4943443118086019],
					[65.900769230757632, 7.494505490798427],
					[65.900705441111683, 7.4945887783198497],
					[65.900705426999238, 7.4945888032947918],
					[65.900705406888719, 7.4945888571587798],
					[65.900705399993853, 7.494588886100475],
					[65.900144199788997, 7.5025360869757902],
					[65.900144199853202, 7.5025360935573646],
				],
			],
		},
		{
			fldNpdidField: "43604",
			wlbNpdidWellbore: "2390",
			fldName: "JOTUN",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "25/8-5 S",
			fldDiscoveryYear: "1994",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43604",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43604",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "1043",
			cmpLongName: "Vår Energi ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=2390",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "50000032",
			fldNpdidOwner: "36464418",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Dusavik",
			fldAreaGeometryWKT: [
				[
					[59.413322000082182, 2.3319471870244453],
					[59.413322002320491, 2.3319472130942116],
					[59.413322012148484, 2.3319472641771721],
					[59.413322020540285, 2.3319472872645313],
					[59.415335921228177, 2.3360417886196605],
					[59.415335966820315, 2.3360418344166662],
					[59.415336078597569, 2.3360418883636549],
					[59.415336143801092, 2.3360418949397848],
					[59.417866650586937, 2.3354669153592367],
					[59.420097053225739, 2.3372279396612692],
					[59.422255424292956, 2.3402722985475237],
					[59.423652615179421, 2.3435528742108898],
					[59.42431371312383, 2.3452722713595375],
					[59.424313759071481, 2.3452723241819227],
					[59.424313873077452, 2.3452723865720619],
					[59.424313942027673, 2.3452723959639803],
					[59.428986142005805, 2.3442779956168516],
					[59.428986193642899, 2.3442779659835407],
					[59.428986272293628, 2.3442778832805984],
					[59.428986298325704, 2.3442778286362782],
					[59.429466893453103, 2.3410333653004076],
					[59.430797374801195, 2.3386000965708265],
					[59.43079738353584, 2.3386000736265475],
					[59.430797395653762, 2.3386000287942763],
					[59.430797399928899, 2.3386000067302297],
					[59.430861296850708, 2.3364917940993197],
					[59.434180699825632, 2.333641877544081],
					[59.43609674777273, 2.3330031616862548],
					[59.434994228619864, 2.3348304970102025],
					[59.434994215342243, 2.334830536773032],
					[59.434994206092007, 2.3348306199548379],
					[59.434994210029629, 2.3348306616229575],
					[59.435577550374262, 2.3366248775032279],
					[59.435547132203197, 2.3366359125042546],
					[59.435547131311303, 2.3366359126803813],
					[59.433205531329335, 2.3374998119392325],
					[59.433205492982815, 2.337499837194847],
					[59.433205432349141, 2.3374999022202538],
					[59.433205409080351, 2.3374999404153614],
					[59.431658109421754, 2.3424943410420824],
					[59.431658105056314, 2.3424943613555458],
					[59.431658100964249, 2.3424944046041678],
					[59.431658102129518, 2.3424944273633663],
					[59.431980393070333, 2.3448971637437652],
					[59.429852702096163, 2.348924756228842],
					[59.428355663216593, 2.3484276100322039],
					[59.428355609074515, 2.3484276083243398],
					[59.428355508466687, 2.3484276316871626],
					[59.428355461019457, 2.3484276551830745],
					[59.427402718777117, 2.3493469996878682],
					[59.427400036753603, 2.349346999452179],
					[59.425136170884542, 2.3484887132692993],
					[59.42513612013105, 2.3484887073573826],
					[59.425136020948784, 2.3484887233641012],
					[59.425135974214299, 2.3484887431808441],
					[59.423416574360736, 2.3498748446584377],
					[59.423416553849883, 2.3498748663781992],
					[59.423416519519293, 2.3498749173393896],
					[59.423416506591515, 2.3498749464051367],
					[59.422927607073326, 2.3516166456834671],
					[59.422927603777289, 2.3516166693154523],
					[59.422927601823844, 2.3516167192017869],
					[59.422927604860803, 2.3516167433545569],
					[59.423516497575619, 2.3542361142135846],
					[59.423155402328476, 2.3591332689901088],
					[59.422405405296004, 2.3623082540610967],
					[59.422405403247403, 2.3623082845176486],
					[59.422405408159001, 2.3623083454274605],
					[59.422405415029829, 2.3623083741304103],
					[59.42294979679945, 2.3636500317194855],
					[59.42276652002149, 2.3659108415874925],
					[59.421769554855693, 2.3656276367491773],
					[59.420994589236003, 2.3633860353898348],
					[59.420994576211122, 2.3633860096653199],
					[59.42099454222317, 2.3633859615444339],
					[59.420994521349442, 2.3633859408982776],
					[59.418877920595072, 2.3617720410289027],
					[59.418877852893146, 2.3617720207525865],
					[59.418877720080921, 2.3617720309533681],
					[59.418877655862609, 2.3617720612550963],
					[59.417847178400521, 2.362844134200242],
					[59.416766820151345, 2.3620359402982412],
					[59.416766716058405, 2.362035930710864],
					[59.416766556047612, 2.3620360081208625],
					[59.416766500219133, 2.362036096868271],
					[59.416594199689989, 2.373772196527399],
					[59.416594202811972, 2.37377222242799],
					[59.416594215121329, 2.3737722695039478],
					[59.416594222703175, 2.3737722945294433],
					[59.418377610151452, 2.3771222697456005],
					[59.418963694873007, 2.3799721984798929],
					[59.418438710415984, 2.3823110321083538],
					[59.416727620888778, 2.3857277104053014],
					[59.416727612948371, 2.3857277314033509],
					[59.416727602597589, 2.3857277758514113],
					[59.416727600098156, 2.3857277975513229],
					[59.416674800037406, 2.3903166984274562],
					[59.416674800838258, 2.3903167141784012],
					[59.4166748060969, 2.3903167467320405],
					[59.416674809662723, 2.3903167637093365],
					[59.417841500664615, 2.3938278356481928],
					[59.418060898678618, 2.3999193902260081],
					[59.417763701696622, 2.4020277715403977],
					[59.41776370338431, 2.4020278047942285],
					[59.417763713629391, 2.4020278646570383],
					[59.41776372414845, 2.4020278944178313],
					[59.419541524134338, 2.4053056954070442],
					[59.419541546160204, 2.405305721155409],
					[59.4195415971706, 2.4053057677578455],
					[59.419541626066312, 2.4053057868616214],
					[59.421730526193933, 2.4061723859462436],
					[59.421730550811247, 2.4061723917453093],
					[59.421730599779579, 2.4061723980921714],
					[59.421730625022604, 2.4061723984657766],
					[59.425869359669861, 2.4056474073274132],
					[59.429785951823121, 2.4078362477166952],
					[59.431177604750793, 2.4103694619017504],
					[59.431860901060361, 2.4168278213929821],
					[59.431860902395591, 2.4168278299739026],
					[59.431860905958132, 2.4168278469617492],
					[59.431860908185421, 2.4168278553686755],
					[59.433299807703222, 2.4217694553502018],
					[59.433299810910817, 2.4217694653350312],
					[59.433299818129534, 2.4217694833797347],
					[59.433299822140611, 2.4217694914396164],
					[59.435347021929772, 2.425722292635252],
					[59.435347031647083, 2.4257223066581752],
					[59.435347052865808, 2.4257223343564451],
					[59.435347065170767, 2.4257223461068205],
					[59.437119265397733, 2.4273473473381841],
					[59.437119283231958, 2.427347361549808],
					[59.43711932309516, 2.4273473838504276],
					[59.437119344232094, 2.4273473921131843],
					[59.441302743585183, 2.4285557915091407],
					[59.441302798620463, 2.4285557931710509],
					[59.441302898981085, 2.4285557647798752],
					[59.441302946179064, 2.428555736130817],
					[59.443669495574468, 2.4260334898755387],
					[59.445849890670893, 2.4257696148267467],
					[59.446305414695502, 2.4265861785163132],
					[59.446910908411247, 2.4286111568651476],
					[59.44691091259898, 2.4286111684326053],
					[59.446910922758555, 2.4286111912199684],
					[59.446910928730389, 2.4286112024398601],
					[59.453319226686453, 2.4392111994637529],
					[59.455547005036422, 2.4432250596688432],
					[59.455872000557271, 2.4468306172752143],
					[59.455872000733805, 2.4468306207798651],
					[59.455872001978946, 2.4468306276158152],
					[59.455872003135802, 2.446830632699426],
					[59.456383102840491, 2.4499861311621101],
					[59.456383105242224, 2.4499861430820915],
					[59.456383111829865, 2.4499861665754818],
					[59.456383116015786, 2.4499861781489058],
					[59.457466515644676, 2.452527877559894],
					[59.457466525623936, 2.4525278968567754],
					[59.457466549866588, 2.4525279310795383],
					[59.457466564218173, 2.4525279477578477],
					[59.459388764093632, 2.4543057464851441],
					[59.459388780945027, 2.45430575914046],
					[59.459388818039869, 2.4543057802532315],
					[59.459388838371517, 2.454305790463216],
					[59.460924852592854, 2.4548084620923931],
					[59.463013706717327, 2.4595167577440864],
					[59.463722002302859, 2.4636500283035843],
					[59.464674801114981, 2.4725194202364653],
					[59.464674802358822, 2.4725194270752171],
					[59.464674803954338, 2.4725194409254549],
					[59.464674806090308, 2.4725194475914525],
					[59.465885905698535, 2.477472247605307],
					[59.465885912017846, 2.4774722658520285],
					[59.465885929117235, 2.4774723014823179],
					[59.465885939005148, 2.4774723190385211],
					[59.468197039438522, 2.4806001197531464],
					[59.468197063059584, 2.4806001417353443],
					[59.468197118067643, 2.4806001788874066],
					[59.468197149366809, 2.4806001923041885],
					[59.472799949044443, 2.481811293533251],
					[59.472799993286785, 2.4818112955976228],
					[59.472800076257073, 2.481811279549246],
					[59.472800115964951, 2.4818112630172613],
					[59.473989016445245, 2.4809640635897416],
					[59.473989043676823, 2.4809640317659407],
					[59.473989086102932, 2.4809639615951342],
					[59.473989099337501, 2.4809639200865363],
					[59.474358499181108, 2.4774917207931857],
					[59.474358497950981, 2.4774916962456857],
					[59.474358490942159, 2.477491646260698],
					[59.474358484271313, 2.4774916209959326],
					[59.47056688399168, 2.4686610202796828],
					[59.470566879003144, 2.4686610106251599],
					[59.47056686813395, 2.4686609914890028],
					[59.470566862253285, 2.4686609820073748],
					[59.46802242389699, 2.4651610671908624],
					[59.469914080274854, 2.4598611917111635],
					[59.472277965210608, 2.4564334140068027],
					[59.472277978877976, 2.4564333812573143],
					[59.47227799613507, 2.4564333124042075],
					[59.472277998920823, 2.4564332782270482],
					[59.470603000250456, 2.441544390055685],
					[59.470630800146836, 2.4383056012220838],
					[59.470630799793284, 2.4383055942098273],
					[59.47063079908618, 2.4383055801853311],
					[59.470630797840549, 2.4383055733467716],
					[59.470352998409595, 2.4361999739646589],
					[59.470352979876651, 2.4361999280069813],
					[59.470352922823601, 2.436199850606088],
					[59.47035288519556, 2.4361998189891216],
					[59.469427885350996, 2.4357637194123778],
					[59.469427830854059, 2.4357637105546854],
					[59.469427725147497, 2.4357637222926476],
					[59.469427674026278, 2.4357637446412945],
					[59.468666803945695, 2.436377459570398],
					[59.467152991302953, 2.4315082415323288],
					[59.46715296295578, 2.4315081974939261],
					[59.467152889216386, 2.4315081286698708],
					[59.467152843020614, 2.4315081058109116],
					[59.465886241751484, 2.4312276271806459],
					[59.465186282612521, 2.4296582183903568],
					[59.46518619861007, 2.4296581604244842],
					[59.465186024052755, 2.4296581466600649],
					[59.465185931713798, 2.4296581912092456],
					[59.46419443530371, 2.4311801798971802],
					[59.462764097202637, 2.4278916508629869],
					[59.462397399044541, 2.4230777850537897],
					[59.462397379260885, 2.4230777322778771],
					[59.462397312660116, 2.4230776443893571],
					[59.462397266912241, 2.4230776126078672],
					[59.459789068828925, 2.4221442476653356],
					[59.459553024406013, 2.4211694746305148],
					[59.460852926599735, 2.4200945535715315],
					[59.460852953638586, 2.4200945182077329],
					[59.460852991216342, 2.4200944418515866],
					[59.46085299988254, 2.4200943994549786],
					[59.460839099948409, 2.4173832983880432],
					[59.460839097992455, 2.4173832775324366],
					[59.460839088728328, 2.4173832368662906],
					[59.46083908231217, 2.4173832168815816],
					[59.459408482074181, 2.4142443166984737],
					[59.459408457910634, 2.4142442842544249],
					[59.459408399322577, 2.4142442302193019],
					[59.459408364094763, 2.4142442105548807],
					[59.455800123951732, 2.4130276311496206],
					[59.452441862332464, 2.4084165827084179],
					[59.452441851810022, 2.4084165706102008],
					[59.452441831923451, 2.4084165514954807],
					[59.4524418223818, 2.4084165409749181],
					[59.45071407520259, 2.4070915813918186],
					[59.449894592736953, 2.4041721452451235],
					[59.44989455582018, 2.4041720923106826],
					[59.449894457539337, 2.404172019530773],
					[59.449894397067283, 2.4041719995108388],
					[59.44836126616174, 2.4041969974333184],
					[59.447850197029794, 2.4014221631315325],
					[59.447850161629418, 2.4014221045989492],
					[59.447850062010929, 2.4014220232468899],
					[59.447849996900764, 2.4014220006019156],
					[59.447044396858004, 2.4014358992610152],
					[59.447044329775892, 2.4014359265369203],
					[59.447044231818552, 2.4014360200021212],
					[59.44704420085445, 2.4014360844397249],
					[59.446899814424349, 2.4033970025574614],
					[59.443297335753599, 2.4034580983357667],
					[59.441941886600169, 2.399947127770699],
					[59.44194187768332, 2.3999471118268185],
					[59.441941859046466, 2.3999470818650241],
					[59.441941848345579, 2.3999470662701596],
					[59.439400192594711, 2.3971305158231688],
					[59.439155799377623, 2.3945777812199407],
					[59.4391557980409, 2.3945777726381632],
					[59.439155795278481, 2.3945777537233384],
					[59.439155792157749, 2.3945777454908193],
					[59.438647401063932, 2.3927805772709307],
					[59.438825200369905, 2.3887889096811414],
					[59.438825196983508, 2.3887888785083375],
					[59.438825181468836, 2.38878882141299],
					[59.438825169251551, 2.3887887937392063],
					[59.435694600707727, 2.3838027424092387],
					[59.435722399629412, 2.3812444027078534],
					[59.435722398738214, 2.3812443851972929],
					[59.435722393298747, 2.3812443491249438],
					[59.435722387947642, 2.381244332489163],
					[59.433430810389716, 2.3748138927438007],
					[59.43391409200435, 2.3730667551290305],
					[59.433955735853338, 2.372925248041867],
					[59.435647045039055, 2.3734195627286678],
					[59.436808111277806, 2.3767861644985206],
					[59.436808138660886, 2.3767862068758454],
					[59.436808213228893, 2.3767862735949294],
					[59.436808257648721, 2.3767862967107996],
					[59.439727757758597, 2.3774084963806272],
					[59.439727822158837, 2.3774084872770591],
					[59.439727931247205, 2.3774084357953909],
					[59.439727977630113, 2.3774083913158579],
					[59.441775177919965, 2.3734583926119002],
					[59.441775187730507, 2.3734583376188541],
					[59.441775178630344, 2.3734582297365261],
					[59.441775157935659, 2.3734581771976915],
					[59.441086300855538, 2.3725666337707363],
					[59.441114095208398, 2.370761397054971],
					[59.44303602388446, 2.3715529850198371],
					[59.44303610665883, 2.371552982906989],
					[59.443036241878431, 2.3715529138831464],
					[59.443036294412941, 2.3715528487235802],
					[59.443447371893413, 2.3698890391378127],
					[59.444099934395496, 2.3695752541719592],
					[59.444830406784781, 2.3706167731648047],
					[59.445110900723833, 2.372944424231795],
					[59.44511092025909, 2.3729444716960919],
					[59.445110976454949, 2.372944549107777],
					[59.445111014988676, 2.372944580456219],
					[59.44603319792423, 2.3733779736097413],
					[59.446838724466957, 2.3739668194640529],
					[59.447424805862667, 2.3762889491584476],
					[59.447424819063819, 2.3762889784108401],
					[59.447424855188558, 2.3762890332371382],
					[59.447424878112159, 2.3762890588110483],
					[59.448849790577938, 2.3773917912527507],
					[59.448669200960985, 2.3793527823744718],
					[59.448669202565846, 2.3793528139054079],
					[59.448669218352322, 2.3793528762676268],
					[59.448669229768875, 2.3793529058725151],
					[59.452416483541597, 2.3853806316931974],
					[59.451922006961695, 2.3871971464429227],
					[59.451922004373358, 2.3871971664136247],
					[59.451922001069732, 2.3871972077571106],
					[59.45192200213841, 2.3871972287800935],
					[59.452283102321459, 2.3897500288029727],
					[59.452283132286013, 2.3897500866274766],
					[59.452283223080464, 2.3897501714548226],
					[59.452283283018389, 2.3897501986325058],
					[59.453010910107274, 2.3898112847163318],
					[59.453341500061242, 2.3954528126414818],
					[59.453341507995226, 2.3954528447068837],
					[59.453341531713356, 2.3954529037612833],
					[59.453341550262415, 2.3954529319782241],
					[59.454188750209553, 2.3964168311069987],
					[59.454188797747207, 2.3964168624955589],
					[59.454188904690689, 2.3964168928673448],
					[59.454188962223547, 2.39641689044785],
					[59.455413962329295, 2.3960168893273597],
					[59.455413992212677, 2.396016874626806],
					[59.455414045023865, 2.3960168324316338],
					[59.455414068129564, 2.396016808441253],
					[59.456291867648822, 2.3946473074676473],
					[59.456291884334725, 2.3946472634981637],
					[59.45629189335569, 2.3946471750208156],
					[59.456291887385738, 2.3946471284113056],
					[59.455669586745344, 2.3930027298126997],
					[59.455669518784347, 2.3930026688078105],
					[59.455669361623677, 2.3930026252813703],
					[59.455669272601973, 2.393002646264085],
					[59.454830755168302, 2.3936940124644153],
					[59.454750208838469, 2.393016762441492],
					[59.455513968838488, 2.3921002308557222],
					[59.457163842230202, 2.3926001901661142],
					[59.457163894588895, 2.3926001922928224],
					[59.457163995117455, 2.3926001672831645],
					[59.457164042395327, 2.3926001403216119],
					[59.458233293614867, 2.3915252892443668],
					[59.458847052580268, 2.3921918344164972],
					[59.458847118225755, 2.3921918675632483],
					[59.458847253001913, 2.392191878311944],
					[59.458847322221509, 2.3921918576661882],
					[59.459647322333616, 2.3915751583815417],
					[59.459647352843312, 2.3915751205450198],
					[59.459647390672806, 2.3915750317199365],
					[59.459647399062597, 2.391574984061172],
					[59.459530799318038, 2.3901471834188848],
					[59.459530756867338, 2.3901471103323866],
					[59.459530631643048, 2.3901470216188447],
					[59.459530548958448, 2.3901470077441345],
					[59.457077861314929, 2.3907941773773791],
					[59.455611284752266, 2.3893138036829966],
					[59.454669597146115, 2.3842110631855085],
					[59.454669594916666, 2.3842110547752982],
					[59.454669588584714, 2.3842110365528484],
					[59.454669586355237, 2.3842110281426416],
					[59.453972405279913, 2.3824166735868744],
					[59.454155791428335, 2.3809083904432278],
					[59.455108352682167, 2.3799113344527765],
					[59.455683113599598, 2.3801279448220973],
					[59.455805400591224, 2.3812528227206444],
					[59.455805457763894, 2.3812529017468331],
					[59.455805613677839, 2.3812529737772019],
					[59.4558057124191, 2.3812529667813993],
					[59.456950070041977, 2.3804807947129141],
					[59.458713775713306, 2.3802974148506379],
					[59.459372016640103, 2.3817917808340119],
					[59.459372054904073, 2.381791824645902],
					[59.459372148023625, 2.3817918842356622],
					[59.459372203771203, 2.3817918998384187],
					[59.459950004451194, 2.3817808003802883],
					[59.459950049763677, 2.3817807879450461],
					[59.459950132183238, 2.3817807434477367],
					[59.459950167417219, 2.381780709983583],
					[59.460555733303302, 2.380866861158458],
					[59.461133256795534, 2.3806280334790357],
					[59.463633162709357, 2.3829945454593933],
					[59.463633244234302, 2.3829945719352761],
					[59.463633397297208, 2.3829945525075567],
					[59.463633468835148, 2.3829945066039642],
					[59.465500101115033, 2.3800224157232965],
					[59.466952773237715, 2.3802223988201843],
					[59.4669528521774, 2.3802223744640538],
					[59.466952965994544, 2.3802222742203032],
					[59.466952999980016, 2.3802221985078891],
					[59.46694460017067, 2.3779612053366632],
					[59.46889175135184, 2.3766474069051688],
					[59.470694184416779, 2.3768390783626567],
					[59.470149801160893, 2.3830249826725556],
					[59.470149799822593, 2.3830249917870661],
					[59.470149799732781, 2.3830250077378041],
					[59.470149801070399, 2.3830250163268696],
					[59.470394201058248, 2.3861083163100778],
					[59.470394245384547, 2.3861083908166982],
					[59.470394375963672, 2.3861084784904771],
					[59.470394463019382, 2.3861084897297231],
					[59.473033362581376, 2.385233490456522],
					[59.47303339933174, 2.3852334690778805],
					[59.473033460434792, 2.385233412820063],
					[59.473033483984636, 2.3852333798689713],
					[59.474175119966056, 2.382575227052468],
					[59.475974961980363, 2.3829195968131134],
					[59.475975034230402, 2.3829195826225704],
					[59.475975148672575, 2.3829195123375495],
					[59.475975191667544, 2.3829194543147389],
					[59.476616891979198, 2.3806472532215683],
					[59.476616896171883, 2.3806472293788365],
					[59.476616898224243, 2.38064718116699],
					[59.476616896173105, 2.3806471585510356],
					[59.476186296368283, 2.3786221587535166],
					[59.476186248915731, 2.3786220937116735],
					[59.476186124308029, 2.3786220172738068],
					[59.476186044566148, 2.3786220081568277],
					[59.473964058837716, 2.3792636457348357],
					[59.473766928307292, 2.378139042230166],
					[59.475758354429523, 2.3775751927047755],
					[59.475758379136963, 2.3775751825361686],
					[59.475758423645971, 2.3775751543100219],
					[59.475758445320679, 2.3775751376549512],
					[59.478083445313523, 2.3751223383072739],
					[59.478083469841323, 2.3751222892142834],
					[59.478083491691876, 2.3751221875245196],
					[59.478083488925392, 2.3751221331745236],
					[59.477658489289105, 2.3739221328786355],
					[59.47765844058592, 2.3739220787109381],
					[59.477658322933486, 2.3739220150851557],
					[59.477658251308377, 2.3739220061533941],
					[59.477008423963781, 2.3740858617720177],
					[59.476697428092223, 2.3732639368754276],
					[59.47886669189743, 2.3705864263408447],
					[59.480177590989044, 2.372369454734311],
					[59.479847002047954, 2.374861073316624],
					[59.479847022030121, 2.3748611473047574],
					[59.47984710980279, 2.3748612610858792],
					[59.479847176612111, 2.3748612993009974],
					[59.484333277368741, 2.3753834995615772],
					[59.484333349260609, 2.3753834783360257],
					[59.484333458613399, 2.3753833966087416],
					[59.484333496787805, 2.3753833324244944],
					[59.48504189685567, 2.3710722314180495],
					[59.485041898995711, 2.371072220369919],
					[59.485041899618693, 2.3710721972223396],
					[59.485041898993572, 2.3710721849473457],
					[59.484591902321085, 2.3652778113681721],
					[59.485080795740735, 2.3628583392326137],
					[59.485080796807125, 2.3628583071414506],
					[59.485080788057836, 2.3628582415614603],
					[59.48508077922348, 2.3628582096504038],
					[59.482758496284013, 2.3582305440767075],
					[59.482508499135712, 2.3549943847395554],
					[59.482508497438133, 2.3549943691350927],
					[59.48250848958341, 2.3549943388059975],
					[59.482508485299576, 2.3549943254828394],
					[59.480686285307975, 2.3505082250114779],
					[59.480686273350919, 2.3505082025786082],
					[59.480686244264504, 2.3505081622759043],
					[59.480686226243201, 2.3505081445821423],
					[59.478339074956295, 2.3485942850388],
					[59.477908519796458, 2.3470972395744596],
					[59.478594560297992, 2.3461779199484396],
					[59.478594578217006, 2.3461778827626723],
					[59.478594595858347, 2.3461778049021476],
					[59.478594596382948, 2.3461777622980291],
					[59.477455800622543, 2.3402499858283785],
					[59.477858500056279, 2.3294667063863694],
					[59.477858499607002, 2.3294666976211116],
					[59.477858498708457, 2.3294666800905994],
					[59.47785849816934, 2.3294666695722959],
					[59.477027997601624, 2.3239082698772187],
					[59.477027982957217, 2.3239082302800753],
					[59.477027937082568, 2.3239081614560764],
					[59.477027906654286, 2.3239081302994755],
					[59.476219591785465, 2.3233970851832835],
					[59.476014101155116, 2.320688908656388],
					[59.477202996602671, 2.3136583328226124],
					[59.477202998557679, 2.3136583182678643],
					[59.477202998900324, 2.3136582898664462],
					[59.477202999071686, 2.313658275665738],
					[59.47668079882061, 2.3093749765767169],
					[59.476680794171784, 2.309374956250247],
					[59.476680782378835, 2.3093749196345414],
					[59.476680774252763, 2.3093749017695271],
					[59.476216874414277, 2.3085555013807775],
					[59.476216787156218, 2.3085554514233557],
					[59.47621661412019, 2.3085554503789991],
					[59.47621652655851, 2.3085554996463853],
					[59.475647026760797, 2.3095470993693539],
					[59.475647014383817, 2.3095471390134916],
					[59.475647005061525, 2.309547220549264],
					[59.47564700891796, 2.3095472605109038],
					[59.476310897806215, 2.311641724060689],
					[59.47616920357212, 2.3137555314016898],
					[59.474950077398923, 2.3155134382472675],
					[59.474716902667232, 2.3146888809324793],
					[59.474861299242384, 2.31310281806683],
					[59.474861293333156, 2.3131027732011749],
					[59.474861263056084, 2.3131026924458604],
					[59.474861237706307, 2.313102654980391],
					[59.473011286743571, 2.3113332028933367],
					[59.47233909697897, 2.3071277676303779],
					[59.472339044582711, 2.3071276948210397],
					[59.472338902010982, 2.3071276204485289],
					[59.472338811925646, 2.307127620638052],
					[59.471536012077159, 2.307522019601659],
					[59.471535968396196, 2.3075220636890901],
					[59.47153591324021, 2.3075221649428661],
					[59.471535901945465, 2.3075222256145067],
					[59.472052594603078, 2.311577771831574],
					[59.470844214908581, 2.31446662385035],
					[59.470844208412736, 2.3144666552377795],
					[59.470844203717867, 2.3144667216778565],
					[59.470844208104346, 2.314466754447031],
					[59.471783089572313, 2.317761093800089],
					[59.47136664045766, 2.3189745586831454],
					[59.470555765286043, 2.3177859877624152],
					[59.470555679283095, 2.3177859446219142],
					[59.470555509371849, 2.3177859517609423],
					[59.470555426265364, 2.3177860001109805],
					[59.469947025778239, 2.31885270068359],
					[59.469947018561392, 2.3188527180485488],
					[59.469947006803281, 2.3188527522479894],
					[59.469947002352015, 2.3188527708350506],
					[59.469722056845846, 2.3203635035446335],
					[59.468647387462418, 2.3194054994168289],
					[59.468208497616779, 2.3164027704583212],
					[59.468208422209059, 2.3164026880449087],
					[59.46820824157065, 2.31640264420649],
					[59.468208137321867, 2.316402684357088],
					[59.467827637299813, 2.3169387846994338],
					[59.467827623398399, 2.3169388122410868],
					[59.46782760407239, 2.3169388744949782],
					[59.467827600251468, 2.316938905348489],
					[59.467877598776191, 2.318819380452144],
					[59.466305417746618, 2.3258554992463321],
					[59.46627485911192, 2.3258859574158266],
					[59.466274840569397, 2.3258859823308722],
					[59.466274812672737, 2.3258860356520215],
					[59.466274803318626, 2.3258860640581198],
					[59.466266503364288, 2.3259332649036306],
					[59.466266502029995, 2.3259332740190763],
					[59.4662665001634, 2.3259332903208949],
					[59.466266499720973, 2.3259332992597144],
					[59.466224799657112, 2.3331638334100235],
					[59.464816679195238, 2.3350718924402494],
					[59.463661294526332, 2.3338916123692388],
					[59.463536299726961, 2.3318610876988304],
					[59.463536293740802, 2.3318610587916764],
					[59.463536273831295, 2.3318610043177435],
					[59.463536259016095, 2.3318609789274074],
					[59.46230015899873, 2.3302276788520477],
					[59.462300089412707, 2.3302276395209334],
					[59.462299940988139, 2.3302276264104389],
					[59.462299864915089, 2.3302276538538367],
					[59.461880464760455, 2.3306137538794891],
					[59.461880440422853, 2.330613788785028],
					[59.461880408340491, 2.3306138659329871],
					[59.461880399703837, 2.3306139083518809],
					[59.461966497882365, 2.3327943492718548],
					[59.457977891148424, 2.3394994562286198],
					[59.457427995917094, 2.3368749598523078],
					[59.457427986364529, 2.3368749316544521],
					[59.457427958609671, 2.3368748822775389],
					[59.45742794308304, 2.3368748605696608],
					[59.456119543277609, 2.3355442597321354],
					[59.456119441943976, 2.3355442337509125],
					[59.456119271687889, 2.3355442868721279],
					[59.456119202675659, 2.3355443642226246],
					[59.455744213022314, 2.3376610073124726],
					[59.451247053117221, 2.3425665652400327],
					[59.45124703234368, 2.3425665994213247],
					[59.451247006407279, 2.342566673549122],
					[59.451247000352502, 2.3425667136717072],
					[59.451369192064163, 2.3443720959102752],
					[59.449077692083485, 2.3458470327597527],
					[59.449077678171335, 2.3458470425819193],
					[59.449077652399666, 2.3458470671291467],
					[59.449077640450525, 2.3458470801025952],
					[59.446677967340577, 2.3490522426968004],
					[59.446514100311028, 2.3462721877723269],
					[59.446514085315435, 2.3462721411939569],
					[59.446514037131678, 2.3462720622411277],
					[59.446514002961997, 2.3462720282911298],
					[59.443130736169216, 2.344227648403741],
					[59.438733500106032, 2.3385193328111691],
					[59.438733499589389, 2.338486099508247],
					[59.438733499589389, 2.338486099508247],
					[59.438719600763285, 2.3364889783476559],
					[59.439291552824436, 2.3358754503636447],
					[59.439491500564294, 2.3375278237653174],
					[59.439491509224567, 2.3375278521248699],
					[59.439491530735488, 2.3375279027100344],
					[59.439491546441204, 2.3375279279094396],
					[59.439919245851812, 2.3380445281178166],
					[59.439919325156566, 2.3380445637538019],
					[59.439919480890445, 2.3380445613026732],
					[59.439919559103323, 2.3380445228633024],
					[59.440677959136231, 2.3370529223505061],
					[59.440677973132154, 2.3370528965909068],
					[59.440677992827773, 2.3370528414035947],
					[59.440678000400979, 2.3370528133747626],
					[59.440855792029716, 2.334339023701042],
					[59.442463994069819, 2.3334807763589618],
					[59.442464029380616, 2.3334807446150356],
					[59.442464081446197, 2.3334806706425377],
					[59.442464098111245, 2.3334806266626962],
					[59.443864098427767, 2.3226139266962789],
					[59.443864095557771, 2.322613888344955],
					[59.443864079295174, 2.3226138172635107],
					[59.443864063137028, 2.3226137833117817],
					[59.441814088739129, 2.3197193218838588],
					[59.441422403908703, 2.3175444069345472],
					[59.4420973937493, 2.3148222479862914],
					[59.442097395974784, 2.3148222387003048],
					[59.442097399353869, 2.3148222168027912],
					[59.442097399705624, 2.3148222061191555],
					[59.442227999744041, 2.3105278065344743],
					[59.442227987141372, 2.3105277541946263],
					[59.44222793839657, 2.310527664797057],
					[59.442227901272517, 2.310527626165042],
					[59.43922793503976, 2.3087831462301769],
					[59.437722418864034, 2.3067832588529518],
					[59.438400192845506, 2.3042861513901585],
					[59.438400195691614, 2.3042861366748135],
					[59.438400199419853, 2.304286104096275],
					[59.438400200392131, 2.304286087983999],
					[59.43831690038391, 2.3029332879269537],
					[59.43831684931947, 2.3029332060854113],
					[59.43831670183669, 2.3029331239265125],
					[59.43831660631021, 2.3029331234321373],
					[59.436252705997269, 2.3040276231234404],
					[59.436252686917769, 2.3040276375220832],
					[59.436252650722778, 2.3040276694669948],
					[59.436252635390964, 2.3040276866592988],
					[59.434158134838299, 2.3070804873610231],
					[59.434158118182204, 2.3070805313421658],
					[59.43415810672483, 2.3070806238125066],
					[59.434158112725292, 2.3070806703740976],
					[59.434547012976964, 2.3081278700946406],
					[59.434547088289008, 2.3081279329772562],
					[59.434547256614145, 2.3081279650332842],
					[59.434547350428979, 2.3081279322790711],
					[59.436644361300878, 2.3057531467768189],
					[59.437497006945563, 2.3073139631535518],
					[59.438133085337881, 2.3118166057150398],
					[59.437333455102724, 2.3123607521693477],
					[59.437091898017123, 2.310558272935062],
					[59.437091895512872, 2.3105582592817249],
					[59.437091887918847, 2.3105582342563875],
					[59.437091883720974, 2.3105582227075674],
					[59.436394583851609, 2.308916622886779],
					[59.436394494180206, 2.3089165610782025],
					[59.436394311063218, 2.3089165567123096],
					[59.436394218599553, 2.3089166157289576],
					[59.435105418955054, 2.3116527151245347],
					[59.435105411828921, 2.3116527342233324],
					[59.435105403017829, 2.311652773111005],
					[59.435105400440982, 2.3116527930766706],
					[59.434972003396929, 2.3153416061409016],
					[59.434016768242238, 2.3161885726538287],
					[59.4320001813308, 2.3118666156996666],
					[59.432000159999141, 2.3118665863265599],
					[59.432000108777466, 2.3118665363503781],
					[59.432000078887448, 2.3118665157472886],
					[59.427158463936941, 2.3097831529398696],
					[59.426419590808429, 2.3073888416148582],
					[59.426419588664331, 2.3073888349672758],
					[59.426419584376141, 2.3073888216721188],
					[59.426419581340184, 2.3073888152013682],
					[59.425758481107216, 2.3059749146946538],
					[59.425758414361788, 2.3059748607417645],
					[59.425758261999441, 2.305974823768008],
					[59.425758177364472, 2.3059748423205302],
					[59.42476657691725, 2.3067470428623991],
					[59.42476654892814, 2.3067470767016562],
					[59.424766509633898, 2.3067471534486561],
					[59.424766500202558, 2.3067471977529297],
					[59.424713711750229, 2.3109601393296813],
					[59.423550184000234, 2.3081999211397508],
					[59.423550115291825, 2.3081998640388917],
					[59.423549956596936, 2.3081998265441568],
					[59.423549868574327, 2.3081998492970301],
					[59.423016568740834, 2.3086637493498561],
					[59.423016546723851, 2.3086637766978497],
					[59.42301651366234, 2.3086638345229518],
					[59.423016502707831, 2.308663866750206],
					[59.421749912079243, 2.3162104151838192],
					[59.420314082705488, 2.3130055173348163],
					[59.420314061284223, 2.3130054862206109],
					[59.420314007297641, 2.3130054350386589],
					[59.420313974732323, 2.3130054149708958],
					[59.419433374792888, 2.3126470146955986],
					[59.419433290418269, 2.312647020800493],
					[59.41943315362942, 2.3126470938564645],
					[59.419433103178967, 2.312647163954189],
					[59.419169202790755, 2.3140805634720021],
					[59.419169202267135, 2.3140805883246838],
					[59.41916920478733, 2.3140806373236038],
					[59.419169209704847, 2.3140806628665906],
					[59.420222001361587, 2.3172944363060384],
					[59.42044419653606, 2.323158212535358],
					[59.418116675596004, 2.3252330542549973],
					[59.415772301298823, 2.323852627709984],
					[59.415772225033081, 2.323852616274003],
					[59.415772088133401, 2.3238526521772367],
					[59.415772026607584, 2.323852699692778],
					[59.413608126868176, 2.327580499558322],
					[59.413608118045481, 2.327580520743608],
					[59.413608104949333, 2.3275805639828584],
					[59.413608099783971, 2.3275805862130192],
					[59.413322000082182, 2.3319471870244453],
				],
			],
		},
		{
			fldNpdidField: "23384520",
			wlbNpdidWellbore: "5773",
			fldName: "IVAR AASEN",
			fldCurrentActivitySatus: "Producing",
			wlbName: "16/1-9",
			fldDiscoveryYear: "2008",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=23384520",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=23384520",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "IVAR AASEN UNIT",
			cmpLongName: "Aker BP ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=5773",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "28544099",
			fldNpdidOwner: "25307128",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Tananger",
			fldAreaGeometryWKT: [
				[
					[58.916972001959024, 2.1242444187593286],
					[58.916972005031212, 2.1242444425367046],
					[58.917327605286488, 2.1258917424785602],
					[58.917327606454222, 2.1258917474720911],
					[58.917327607898109, 2.1258917576371474],
					[58.917327609865396, 2.1258917607288739],
					[58.91785809240136, 2.1275361067105116],
					[58.91715540695936, 2.1301916486646304],
					[58.917155407136441, 2.1301917026374722],
					[58.917155435837444, 2.130191801443682],
					[58.917155464361336, 2.1301918462770417],
					[58.917860963766145, 2.1308418462306462],
					[58.917861032842993, 2.130841874264612],
					[58.917861169652326, 2.1308418713647357],
					[58.917861237660752, 2.1308418456025717],
					[58.91856394045729, 2.1301696382643178],
					[58.919266508919456, 2.1304890330287365],
					[58.919619201351225, 2.1354417131638459],
					[58.919619206480384, 2.1354417417606464],
					[58.919619225378881, 2.1354417920047633],
					[58.91961923844049, 2.1354418172778229],
					[58.921027637832772, 2.1374029163213026],
					[58.921027666326957, 2.1374029437472415],
					[58.921027732479594, 2.1374029845758766],
					[58.92102776924645, 2.1374029981562623],
					[58.922958268777052, 2.1377029977070987],
					[58.922958277876845, 2.1377029993782797],
					[58.922958295001067, 2.1377029994499979],
					[58.922958303117383, 2.1377029995747163],
					[58.924888902863295, 2.1376723995431388],
					[58.924888934960933, 2.1376723931448538],
					[58.924888994238657, 2.1376723726156412],
					[58.92488902231036, 2.1376723583069821],
					[58.925761222405612, 2.1369973586975113],
					[58.925761231845549, 2.136997349845017],
					[58.925761248942223, 2.1369973324955533],
					[58.92576125659896, 2.1369973239985671],
					[58.927866856816131, 2.1343195236678487],
					[58.92786685761579, 2.1343195217656157],
					[58.927866860198613, 2.1343195195077134],
					[58.927866860998257, 2.1343195176054826],
					[58.929091707834061, 2.1326475273915224],
					[58.930144237317094, 2.133952915759465],
					[58.931377630747001, 2.1359168066760499],
					[58.93137768432598, 2.1359168482775588],
					[58.931377805418208, 2.1359168886145206],
					[58.931377872839555, 2.1359168856254138],
					[58.932255673124892, 2.1355723852144024],
					[58.932255718908465, 2.1355723481951983],
					[58.932255783332202, 2.1355722551693765],
					[58.932255800189168, 2.1355721995184163],
					[58.932250200451151, 2.1342500753635694],
					[58.933127951095791, 2.1332445317745941],
					[58.933127951895393, 2.1332445298720266],
					[58.933127956169436, 2.1332445255332084],
					[58.933127956969052, 2.1332445236306574],
					[58.934177956900385, 2.1319057233019096],
					[58.934177965264354, 2.1319057111742583],
					[58.93417798011707, 2.1319056855501457],
					[58.934177985714278, 2.131905672231631],
					[58.934702985572933, 2.1305750722560988],
					[58.934702988063663, 2.1305750682727145],
					[58.934702991969552, 2.1305750570344704],
					[58.934702993476726, 2.1305750515043278],
					[58.935227993037962, 2.1285833515538335],
					[58.935227995960055, 2.1285833387684971],
					[58.935227999129488, 2.1285833137319208],
					[58.935228000176387, 2.1285832995779219],
					[58.935222399709005, 2.1249471993506099],
					[58.935222390182268, 2.1249471559327158],
					[58.935222351790365, 2.1249470781899986],
					[58.935222323816774, 2.1249470436870395],
					[58.93399452962111, 2.1239748481236309],
					[58.932941877658543, 2.1229998915612889],
					[58.932411293272231, 2.1210249486056196],
					[58.932411291028437, 2.1210249403391903],
					[58.932411284665562, 2.1210249224381523],
					[58.932411280638632, 2.1210249145281281],
					[58.931183505384951, 2.1183999655312991],
					[58.931355798323487, 2.1167444305186378],
					[58.932052995843584, 2.1134278414507857],
					[58.932052993076603, 2.1134277897149429],
					[58.932052962855799, 2.1134276964121734],
					[58.93205293620133, 2.1134276529423279],
					[58.931702935821143, 2.1131026527711314],
					[58.931702904124762, 2.1131026329694667],
					[58.931702834245243, 2.1131026068650054],
					[58.931702796953658, 2.1131026003837801],
					[58.930472287573153, 2.1131219985510219],
					[58.929591851917216, 2.1121415697514272],
					[58.928539056372479, 2.1108387737452921],
					[58.928539046903381, 2.1108387651837104],
					[58.928539027872951, 2.110838746336178],
					[58.928539019387678, 2.1108387393204868],
					[58.927661218574649, 2.1101915387077592],
					[58.927661212057004, 2.1101915347840641],
					[58.927661199021671, 2.110191526936696],
					[58.927661193395572, 2.1101915228345405],
					[58.92643345791862, 2.1095498571489664],
					[58.925902984961148, 2.1082360251141004],
					[58.92590297788967, 2.1082360108457432],
					[58.925902959104384, 2.10823597975312],
					[58.925902949450546, 2.1082359677444491],
					[58.925025149401677, 2.1072554664702507],
					[58.925025138056995, 2.1072554565433479],
					[58.925025116351492, 2.1072554382351623],
					[58.925025104115264, 2.1072554284867731],
					[58.9245001042745, 2.1069359292643832],
					[58.924500078480484, 2.106935918745775],
					[58.924500025386315, 2.1069359032379831],
					[58.924499997286901, 2.1069359001514645],
					[58.923447196868082, 2.1069525998396657],
					[58.923447136704709, 2.1069526205988431],
					[58.923447041588894, 2.1069526954055586],
					[58.923447006636472, 2.1069527494530971],
					[58.922744207125056, 2.1096082494029336],
					[58.922744203590064, 2.1096082675356302],
					[58.922744200977824, 2.1096083029088355],
					[58.922744201009017, 2.109608320327764],
					[58.923099785940337, 2.1129054830454881],
					[58.922402834013553, 2.113249759943288],
					[58.921694534943676, 2.1125998538380579],
					[58.921694485846629, 2.1125998288106325],
					[58.921694382088077, 2.1125998094907699],
					[58.921694327334357, 2.1125998134743154],
					[58.919944360982299, 2.1132887002011249],
					[58.919413896765079, 2.1132969991924333],
					[58.919413836692634, 2.1132970216615701],
					[58.919413742464187, 2.1132970962631656],
					[58.919413707416624, 2.113297148573948],
					[58.91871650667882, 2.1159527494705923],
					[58.918716505172242, 2.1159527549986952],
					[58.918716503142868, 2.1159527676005379],
					[58.91871650172849, 2.1159527748525271],
					[58.918544202115349, 2.1172777733616059],
					[58.918544202207507, 2.1172777750854714],
					[58.918544201315946, 2.1172777752636849],
					[58.918544201408118, 2.1172777769875513],
					[58.918197022431912, 2.1202581028240792],
					[58.917319397262837, 2.1202720002994377],
					[58.917319333622636, 2.1202720234673773],
					[58.917319237175612, 2.1202721071987258],
					[58.917319203292962, 2.1202721644926088],
					[58.917144202840603, 2.1212666645194851],
					[58.917144201794379, 2.1212666786661809],
					[58.917144200685613, 2.1212667085053045],
					[58.917144201422531, 2.121266722295823],
					[58.917324797313398, 2.1229166853732075],
					[58.916972007283889, 2.1242443497503083],
					[58.916972003039319, 2.1242443715042167],
					[58.916972001959024, 2.1242444187593286],
				],
				[
					[58.892863699579948, 2.196986107112282],
					[58.892938700163732, 2.1991611066211285],
					[58.892938701419673, 2.1991611133367694],
					[58.89293870205703, 2.1991611253969929],
					[58.892938703312964, 2.1991611321126356],
					[58.893127603392607, 2.2003333318980451],
					[58.893127603756767, 2.200333338789644],
					[58.893127606177465, 2.200333350498195],
					[58.89312760823401, 2.2003333553151077],
					[58.893502607668729, 2.2016194567422507],
					[58.893502612855542, 2.2016194696463489],
					[58.893502625995303, 2.2016194966498692],
					[58.893502632965536, 2.2016195092022284],
					[58.894116529829851, 2.2025473061742251],
					[58.89473312776974, 2.2035945003945496],
					[58.894733143311313, 2.2035945217033253],
					[58.894733179380879, 2.2035945563739876],
					[58.894733200090833, 2.2035945731818503],
					[58.89522209975452, 2.2038779730248095],
					[58.895222124650125, 2.2038779837849169],
					[58.895222176752519, 2.2038779978855816],
					[58.895222204760074, 2.203877999327315],
					[58.896533251615338, 2.2038474005966262],
					[58.897480481224967, 2.2043557631540591],
					[58.898491539833678, 2.2053334231727799],
					[58.899444220067771, 2.2070167891488124],
					[58.899816515861538, 2.2078917772994404],
					[58.89981651595248, 2.2078917790227002],
					[58.899816516935154, 2.2078917805702156],
					[58.899816516935154, 2.2078917805702156],
					[58.9005887116653, 2.2096389696672465],
					[58.90118040810335, 2.2116278562167113],
					[58.901180417401541, 2.2116278787625947],
					[58.901180439201326, 2.211627916258367],
					[58.90118045188472, 2.211627934654925],
					[58.902130452148711, 2.2126668349885898],
					[58.902130474732019, 2.2126668531773981],
					[58.902130524084711, 2.212666883506615],
					[58.902130550763232, 2.2126668939236227],
					[58.902772109296102, 2.2128279837225189],
					[58.903413716005744, 2.213344508618079],
					[58.903602598975297, 2.2144000110082098],
					[58.903547001891887, 2.2152832676890286],
					[58.903280415052862, 2.2162916182580634],
					[58.90285543157087, 2.2169470907489304],
					[58.902855424271699, 2.2169471061163204],
					[58.902855409855043, 2.2169471402979752],
					[58.902855403629275, 2.2169471589367222],
					[58.902677603783779, 2.2177749572543304],
					[58.902677601925852, 2.2177749732918408],
					[58.902677600976077, 2.2177750065637474],
					[58.902677600992462, 2.2177750239736742],
					[58.902838700987658, 2.2191278243394854],
					[58.902838702061011, 2.2191278276109068],
					[58.902838703225143, 2.2191278326058024],
					[58.902838703315958, 2.2191278343292495],
					[58.903122002804309, 2.2207083344904572],
					[58.903408102588834, 2.2224694314728386],
					[58.903408102679606, 2.2224694331963279],
					[58.90340810384361, 2.2224694381914256],
					[58.90340810393436, 2.2224694399149167],
					[58.90378590430069, 2.2243444394302716],
					[58.903785904572871, 2.2243444446008338],
					[58.903785907792532, 2.2243444544159039],
					[58.903785909047215, 2.2243444611346375],
					[58.904344209209228, 2.2261000612795514],
					[58.904344212247345, 2.2261000676478306],
					[58.904344217431827, 2.2261000805597257],
					[58.904344220379258, 2.2261000852044504],
					[58.904899816434401, 2.227263978073514],
					[58.905394212354523, 2.2286083690046938],
					[58.905858108935064, 2.2298917603783734],
					[58.906169204850102, 2.2310639446800904],
					[58.906516503116755, 2.2328222377694438],
					[58.906863703443065, 2.2348167347485983],
					[58.906863703533659, 2.2348167364723079],
					[58.906863704787803, 2.2348167431920367],
					[58.906863704968991, 2.2348167466394648],
					[58.907330403609819, 2.2368083397402443],
					[58.907963701648079, 2.2407972306902151],
					[58.907963703793726, 2.2407972372354412],
					[58.90796370719319, 2.2407972505008811],
					[58.907963709429346, 2.2407972587699017],
					[58.908616509169896, 2.2429611578381508],
					[58.90861651337049, 2.2429611692053335],
					[58.908616522753952, 2.2429611934885885],
					[58.908616528738115, 2.2429612045059701],
					[58.909538728933242, 2.2444723046605475],
					[58.909538730897779, 2.2444723077585533],
					[58.909538733844578, 2.2444723124055845],
					[58.909538735718648, 2.2444723137797165],
					[58.910183109200666, 2.2454000766273685],
					[58.910374797143945, 2.246691694245289],
					[58.910197013327632, 2.247577718562646],
					[58.909805443795264, 2.2481165746114091],
					[58.909319251368601, 2.2486581658367846],
					[58.909319239516137, 2.24865818035062],
					[58.9093192214333, 2.2486582135013711],
					[58.909319213328878, 2.2486582307639336],
					[58.908630413284811, 2.2504971307123682],
					[58.908630410790153, 2.2504971346842],
					[58.908630409368051, 2.2504971419290429],
					[58.908630406873378, 2.2504971459008742],
					[58.908152607057318, 2.2522166461989563],
					[58.908152606436559, 2.2522166515451412],
					[58.908152604122563, 2.2522166589644579],
					[58.908152602610024, 2.2522166644852994],
					[58.907769203388632, 2.2542860644148184],
					[58.907769201966325, 2.2542860716593336],
					[58.907769200995681, 2.2542860875229751],
					[58.907769199573366, 2.2542860947674774],
					[58.907694200150999, 2.2570555951838953],
					[58.907694200241288, 2.2570555969077248],
					[58.907694199620352, 2.2570556022537427],
					[58.907694199710654, 2.2570556039775838],
					[58.907735899572295, 2.2591139032795056],
					[58.907735900915483, 2.2591139117242225],
					[58.907735901727889, 2.2591139272387801],
					[58.907735903071057, 2.2591139356835011],
					[58.908113702585041, 2.2611667364961474],
					[58.908113704819904, 2.2611667447666215],
					[58.908113710181496, 2.261166761133131],
					[58.908113713308182, 2.2611667692291766],
					[58.908947010388196, 2.263386164411187],
					[58.909816505064562, 2.2663139472992451],
					[58.910224800938408, 2.2684250228642777],
					[58.910333099906701, 2.2713667069681081],
					[58.910333101339191, 2.2713667171380041],
					[58.910333104114066, 2.2713667357537868],
					[58.910333104654704, 2.2713667460978666],
					[58.910677603638085, 2.2728306394488227],
					[58.911274802838321, 2.2763528332712686],
					[58.911274802838321, 2.2763528332712686],
					[58.911274803018394, 2.2763528367194197],
					[58.911274803018394, 2.2763528367194197],
					[58.911808102556641, 2.2791667370538296],
					[58.911808104790396, 2.2791667453264197],
					[58.911808109167907, 2.2791667601474837],
					[58.911808112203502, 2.2791667665219535],
					[58.912458111571453, 2.2809806666569354],
					[58.91245811782246, 2.2809806828547714],
					[58.912458132730158, 2.2809807095561205],
					[58.912458142368685, 2.2809807216098368],
					[58.913533141713863, 2.282369521887075],
					[58.913533170981189, 2.2823695475313031],
					[58.913533236912926, 2.2823695851849815],
					[58.913533272685477, 2.2823695973683558],
					[58.913927773008631, 2.2824223982276872],
					[58.913927816088304, 2.2824223950520679],
					[58.913927896708749, 2.2824223688796557],
					[58.913927934339483, 2.2824223476070906],
					[58.914261234453065, 2.2821195484121],
					[58.914261253886473, 2.2821195237206502],
					[58.914261283654639, 2.2821194726286302],
					[58.914261294791316, 2.2821194443298469],
					[58.914500194859016, 2.2810556439858058],
					[58.914500197084706, 2.2810556348426392],
					[58.914500198770526, 2.2810556153539414],
					[58.914500200104349, 2.2810556063847374],
					[58.914552998787663, 2.2794056384633063],
					[58.915033487584559, 2.2781000695323175],
					[58.915033488386385, 2.2781000676340075],
					[58.915033488386385, 2.2781000676340075],
					[58.915033489278265, 2.2781000674599641],
					[58.915452983246205, 2.2769111817928467],
					[58.915933474869789, 2.2760194971796701],
					[58.916539073677882, 2.2749472996431117],
					[58.917472363713784, 2.2733362144790132],
					[58.918230752758397, 2.2724362293961904],
					[58.91823075614559, 2.2724362252505212],
					[58.918230762829893, 2.2724362152347992],
					[58.918230766217107, 2.2724362110891261],
					[58.919075160434971, 2.2711834210513131],
					[58.919830743838602, 2.2702834408577535],
					[58.920711233166884, 2.2694973487076853],
					[58.920711244310169, 2.2694973378191232],
					[58.920711264632772, 2.2694973129415672],
					[58.920711273812081, 2.2694972989525724],
					[58.921494559049009, 2.2681279252783324],
					[58.922314038396408, 2.2673445447742502],
					[58.922314042585093, 2.2673445387286248],
					[58.922314053728179, 2.2673445278388371],
					[58.922314057916871, 2.2673445217931945],
					[58.923008458467997, 2.2664445225041576],
					[58.923008465953444, 2.266444510586819],
					[58.923008479140663, 2.2664444871009319],
					[58.923008484842455, 2.2664444755323792],
					[58.92336958535531, 2.265555674276801],
					[58.923369586247169, 2.2655556741023788],
					[58.923369586958593, 2.2655556704786788],
					[58.923369587760241, 2.2655556685796165],
					[58.92375847654435, 2.264486197221276],
					[58.924455753102976, 2.2635862284998116],
					[58.925272347696485, 2.2626862339648821],
					[58.925272355362019, 2.2626862254953966],
					[58.925272367927384, 2.2626862073551979],
					[58.925272373809271, 2.2626861992347118],
					[58.925814056065171, 2.2617334307368995],
					[58.926694285992056, 2.2610059545005621],
					[58.926724806915281, 2.2611222506623845],
					[58.926724858063395, 2.2611223155768299],
					[58.926724992162427, 2.2611223816796557],
					[58.926725074402029, 2.261122386492207],
					[58.927819473716099, 2.2606890854371433],
					[58.927819484327777, 2.2606890816173508],
					[58.927819503586953, 2.2606890708772198],
					[58.927819513216541, 2.2606890655071625],
					[58.928791784059861, 2.2600195848315519],
					[58.929825049129349, 2.2597612934280464],
					[58.929825079090335, 2.2597612805912974],
					[58.929825134813044, 2.2597612435416825],
					[58.929825158881407, 2.2597612214029721],
					[58.930369559023241, 2.2590418217700563],
					[58.930369559824769, 2.2590418198704532],
					[58.930369560626303, 2.2590418179708487],
					[58.930369562409957, 2.259041817621569],
					[58.931064058306262, 2.25808342265436],
					[58.931972346231696, 2.2570057385373801],
					[58.93312232564687, 2.2559195554747387],
					[58.933911213607274, 2.2553723634466434],
					[58.933911218867841, 2.2553723606729652],
					[58.933911230100122, 2.255372351500577],
					[58.93391123527033, 2.2553723470017739],
					[58.934700134582606, 2.2546473474166513],
					[58.934700148219221, 2.2546473325439407],
					[58.934700170051158, 2.2546473021220828],
					[58.934700180030141, 2.2546472862233391],
					[58.93539187992819, 2.2532194865698063],
					[58.935391880729625, 2.2532194846697879],
					[58.935391883224312, 2.2532194806949106],
					[58.935391883935374, 2.2532194770696994],
					[58.936114079780246, 2.251494489117464],
					[58.937169573915064, 2.2496444993998614],
					[58.937169574716435, 2.2496444974996668],
					[58.937169576409623, 2.2496444954245383],
					[58.937169577211009, 2.2496444935243383],
					[58.937891868535011, 2.2482723073140782],
					[58.938708455796728, 2.247194524398783],
					[58.939464052916215, 2.2462945296121064],
					[58.939464056302498, 2.2462945254612334],
					[58.93946406218322, 2.246294517334511],
					[58.939464064677701, 2.2462945133586696],
					[58.940039065268998, 2.2454557124931815],
					[58.940039071860525, 2.2454557007403162],
					[58.940039084862576, 2.245455673783777],
					[58.940039091363609, 2.2454556603055176],
					[58.940336291067482, 2.2445056601707574],
					[58.940336291778287, 2.2445056565448405],
					[58.940336294001213, 2.2445056473925353],
					[58.940336294712033, 2.2445056437666131],
					[58.940633488997449, 2.2432028686069949],
					[58.940814060821843, 2.242847324292482],
					[58.941633378396595, 2.2421223971435373],
					[58.942516704293105, 2.2421030001828033],
					[58.942516715976936, 2.2421029996312374],
					[58.942516740145855, 2.2421029966274348],
					[58.942516751648625, 2.242102992624849],
					[58.943002852391224, 2.2419723935359124],
					[58.943002872630856, 2.241972384330476],
					[58.943002912127788, 2.2419723643692255],
					[58.94300293040277, 2.2419723520630384],
					[58.943639023600959, 2.2414279567768389],
					[58.944216819408517, 2.2410029611376352],
					[58.944216831440862, 2.2410029500568576],
					[58.94421685532447, 2.2410029244441221],
					[58.944216866374468, 2.2410029118129593],
					[58.944700164177782, 2.2402862157227248],
					[58.945394561773384, 2.2393279165756077],
					[58.945394570147847, 2.2393279044692105],
					[58.945394584040287, 2.2393278773308625],
					[58.945394589558255, 2.2393278622989299],
					[58.945844588011575, 2.2379611687864935],
					[58.946502968328097, 2.2363557160930632],
					[58.947291834753656, 2.235630747682666],
					[58.94729184927958, 2.2356307326214457],
					[58.947291871907744, 2.2356307002750664],
					[58.947291881793539, 2.2356306826391767],
					[58.947802964878214, 2.2345001205556785],
					[58.948469533901395, 2.2338945488589861],
					[58.948469542456579, 2.2338945402017436],
					[58.948469556891638, 2.2338945234134631],
					[58.948469563663267, 2.2338945151070471],
					[58.949014063572676, 2.2331168146637248],
					[58.949014072656979, 2.2331167989274787],
					[58.949014088059592, 2.2331167662554781],
					[58.949014094377922, 2.2331167493197186],
					[58.949222394276376, 2.2322861493560731],
					[58.949222396407706, 2.2322861384748167],
					[58.949222398796152, 2.2322861153374087],
					[58.94922240003573, 2.2322861046316045],
					[58.949247399639887, 2.2313417048640845],
					[58.949247400350295, 2.2313417012369703],
					[58.949247400078264, 2.2313416960595416],
					[58.949247399896912, 2.2313416926079257],
					[58.949211300080634, 2.2303416930603257],
					[58.949211299445807, 2.2303416809796692],
					[58.949211294609064, 2.2303416575204267],
					[58.94921129308247, 2.23034164561528],
					[58.948930793164372, 2.2293443464164588],
					[58.948930786906722, 2.2293443302117839],
					[58.948930773681084, 2.2293443014295091],
					[58.948930765730601, 2.2293442873016858],
					[58.948344572068493, 2.2284748961500895],
					[58.947791877922697, 2.2274249086533149],
					[58.947358478847377, 2.2265499102603004],
					[58.947358463218066, 2.226549887184325],
					[58.947358423132592, 2.2265498445139769],
					[58.947358399658889, 2.2265498264697459],
					[58.946472350249515, 2.226038756684674],
					[58.945950187657331, 2.2250499275170936],
					[58.945636297296744, 2.2238193647071873],
					[58.945378000761323, 2.2212888950897596],
					[58.945541899287178, 2.2180444287530299],
					[58.945897393933002, 2.2165028492618446],
					[58.946286293197034, 2.2150806533882208],
					[58.946286295855074, 2.2150806354286323],
					[58.946286298677755, 2.2150806034883188],
					[58.946286299552327, 2.2150805858804938],
					[58.946186299707605, 2.2136082863789115],
					[58.946186298543154, 2.213608281378034],
					[58.946186298088598, 2.2136082727500566],
					[58.946186297906763, 2.2136082692988732],
					[58.945964097965707, 2.2121971681394843],
					[58.945964093398558, 2.2121971498619315],
					[58.945964078913775, 2.2121971143625663],
					[58.945964070779674, 2.2121970967888336],
					[58.945469571342635, 2.2113831963737081],
					[58.945469546429671, 2.2113831681635254],
					[58.945469487340283, 2.2113831240321571],
					[58.945469453964648, 2.2113831062094667],
					[58.944891753671982, 2.2112220075132076],
					[58.944891714781711, 2.2112220047269147],
					[58.944891638620497, 2.2112220127825162],
					[58.944891602332184, 2.2112220251739778],
					[58.944344301823698, 2.2115276259004073],
					[58.944344293088214, 2.2115276311110068],
					[58.944344274015663, 2.2115276453351247],
					[58.944344266262846, 2.2115276520952731],
					[58.943677711739163, 2.2121331108646549],
					[58.943130689871502, 2.212205386721418],
					[58.94260846354365, 2.2115081845346731],
					[58.941930767562063, 2.2104637903580002],
					[58.94106957403767, 2.2091277018323199],
					[58.940083485912147, 2.2071471244339507],
					[58.939650191655552, 2.2057999440633038],
					[58.939086293823685, 2.2036332499122819],
					[58.939086290602319, 2.2036332400894345],
					[58.939086284250557, 2.2036332221689521],
					[58.939086280137452, 2.2036332125222367],
					[58.938747379714101, 2.2029332125301404],
					[58.938747348011169, 2.2029331752132633],
					[58.938747269373735, 2.2029331192767465],
					[58.938747223330985, 2.2029331004809762],
					[58.93746681921462, 2.2027859127593459],
					[58.937036274138208, 2.2020888008624704],
					[58.936450187693382, 2.2009832277116144],
					[58.936108500095266, 2.1995749754663634],
					[58.936100200059201, 2.1985167412353248],
					[58.936522378950315, 2.1975639894296357],
					[58.937125150322444, 2.1964890406480975],
					[58.937972308762355, 2.195939067902319],
					[58.937972313930317, 2.1959390633941682],
					[58.937972322665118, 2.1959390581807225],
					[58.937972326941384, 2.195939053848889],
					[58.938852926870823, 2.1952112543261775],
					[58.938852930346478, 2.1952112518956444],
					[58.938852933822162, 2.1952112494651068],
					[58.938852936406136, 2.1952112472109118],
					[58.940155734213363, 2.1940029493565394],
					[58.941336232317944, 2.1929723504833492],
					[58.941336234010166, 2.1929723484052359],
					[58.941336239178014, 2.1929723438961499],
					[58.941336240870235, 2.1929723418180358],
					[58.942727927642515, 2.1915834546509134],
					[58.94363901056056, 2.190972365580504],
					[58.94363902357118, 2.1909723560316681],
					[58.943639047626611, 2.1909723338361702],
					[58.943639058671423, 2.1909723211895265],
					[58.944364058569811, 2.1900140205570104],
					[58.944364061954154, 2.190014016400113],
					[58.944364066138945, 2.1900140103412644],
					[58.94436406872282, 2.1900140080862989],
					[58.945358467781389, 2.1884584074387337],
					[58.946052964217486, 2.1873807157539846],
					[58.946839059383301, 2.1863584213537695],
					[58.946839066060427, 2.1863584113130949],
					[58.946839079323432, 2.1863583895062173],
					[58.946839084034636, 2.1863583763677275],
					[58.947439084245666, 2.1849306771061068],
					[58.947439085046057, 2.1849306752038862],
					[58.947439086646845, 2.1849306713994281],
					[58.947439088247627, 2.1849306675949838],
					[58.947827987775483, 2.1838611679746878],
					[58.94782798857586, 2.183861166072421],
					[58.947827990085301, 2.1838611605423006],
					[58.947827990885685, 2.1838611586400365],
					[58.948247390717334, 2.1824944586008468],
					[58.948247393027053, 2.1824944511682878],
					[58.948247396754859, 2.1824944364799137],
					[58.94824739808157, 2.1824944274985079],
					[58.948422397896252, 2.1812528282273025],
					[58.948422399222899, 2.1812528192458043],
					[58.948422400001448, 2.1812527999107716],
					[58.948422400345059, 2.1812527893804585],
					[58.948350201248708, 2.179780604436838],
					[58.948522398417325, 2.1781250307284594],
					[58.948789095904786, 2.1767639380377242],
					[58.948789098214185, 2.1767639306046953],
					[58.948789099174888, 2.1767639147205751],
					[58.948789099609535, 2.1767639059157231],
					[58.948814099699426, 2.1759361057939137],
					[58.948814100134015, 2.175936096989048],
					[58.948814099128427, 2.1759360780075485],
					[58.94881409679661, 2.1759360680078217],
					[58.948625197333222, 2.1747610681575784],
					[58.948625193743915, 2.174761051432621],
					[58.948625183090058, 2.1747610204160548],
					[58.948625176917176, 2.1747610059474898],
					[58.948191877031228, 2.1739471060250342],
					[58.948191872115693, 2.173947098282059],
					[58.948191864159412, 2.1739470841677395],
					[58.94819185933531, 2.1739470781503272],
					[58.947486259274939, 2.1730220793152317],
					[58.947486245877137, 2.1730220645377574],
					[58.947486216589404, 2.1730220389647772],
					[58.947486200699494, 2.1730220281692727],
					[58.946905700687971, 2.1726804276309575],
					[58.946905664907092, 2.1726804155529047],
					[58.946905590418872, 2.1726804041831054],
					[58.946905550911318, 2.1726804067938241],
					[58.946386051094343, 2.1728109062354934],
					[58.945597219961158, 2.1730080881743672],
					[58.944864041016032, 2.1726720447856569],
					[58.944555774849483, 2.1721471017061265],
					[58.944247377929536, 2.1715666089073844],
					[58.943783478844381, 2.1706332108941493],
					[58.943783470979263, 2.1706331985076681],
					[58.943783454265855, 2.1706331721864274],
					[58.943783444708892, 2.1706331618793104],
					[58.943233444676807, 2.1700581621346617],
					[58.943233434228134, 2.1700581520048687],
					[58.943233411730453, 2.1700581355499389],
					[58.943233399589964, 2.1700581274994963],
					[58.942650099866903, 2.1697192264328709],
					[58.942650084159759, 2.1697192190907804],
					[58.94265004927032, 2.1697192068399764],
					[58.942650031962849, 2.1697192033024617],
					[58.942011132083422, 2.1696165025196046],
					[58.942011126641987, 2.1696165018565354],
					[58.942011116650747, 2.1696165003533574],
					[58.942011112992638, 2.1696164993362341],
					[58.941311112654795, 2.1695720002425887],
					[58.941311082155437, 2.1695720028109742],
					[58.94131102438066, 2.1695720177678086],
					[58.941310996122077, 2.1695720286081159],
					[58.94070270902187, 2.1699415212279907],
					[58.939944324120574, 2.1702554149455398],
					[58.939944322337247, 2.1702554152995339],
					[58.939944318862111, 2.1702554177326352],
					[58.939944317970436, 2.1702554179096332],
					[58.939397184791055, 2.1705025870103127],
					[58.93845010243988, 2.1702304217359165],
					[58.937900161299872, 2.1697137762165593],
					[58.937497377805585, 2.1689582083101677],
					[58.937036284134379, 2.1680249217275458],
					[58.936508488414987, 2.1665666322090731],
					[58.93650848743183, 2.1665666306611948],
					[58.936508486265581, 2.1665666256634255],
					[58.936508485282395, 2.1665666241155543],
					[58.936075184858559, 2.1655138242953118],
					[58.936075181725904, 2.1655138162019929],
					[58.936075174751984, 2.1655138036422774],
					[58.936075171710883, 2.1655137972738885],
					[58.935489073598745, 2.1645276992853959],
					[58.934780774911872, 2.1632471027142444],
					[58.93478076999579, 2.163247094975715],
					[58.934780760163605, 2.1632470794986793],
					[58.934780754447438, 2.1632470736621365],
					[58.934258460374195, 2.1626137790217794],
					[58.933458465044822, 2.161452687087535],
					[58.932597366278223, 2.1601776872254654],
					[58.93259736538657, 2.1601776874026717],
					[58.93259736538657, 2.1601776874026717],
					[58.932597364403321, 2.1601776858551527],
					[58.932075163686847, 2.1594248867845365],
					[58.932075158770537, 2.1594248790471555],
					[58.932075147154656, 2.1594248639268256],
					[58.932075139655076, 2.1594248584457909],
					[58.93149183987321, 2.1588498587232472],
					[58.931491831298693, 2.1588498499703315],
					[58.931491813349652, 2.1588498343663933],
					[58.931491803083482, 2.1588498276926029],
					[58.930544502859966, 2.1582831286622812],
					[58.930544493577059, 2.1582831235361954],
					[58.930544476977758, 2.158283116378795],
					[58.930544467694865, 2.1582831112527217],
					[58.929627896067565, 2.1579526217514124],
					[58.929322320228472, 2.1577248395681989],
					[58.929322312820453, 2.1577248358125036],
					[58.929322299696047, 2.1577248262220565],
					[58.929322293179666, 2.1577248222891008],
					[58.928680692869122, 2.1573859234281447],
					[58.928680686352742, 2.1573859194953338],
					[58.928680669753412, 2.1573859123386883],
					[58.928680662437031, 2.1573859103076267],
					[58.927672287645784, 2.1570581185032438],
					[58.926908411663554, 2.1565442349182442],
					[58.926908393097612, 2.1565442246679662],
					[58.926908354457396, 2.1565442096952347],
					[58.926908334291497, 2.1565442032483402],
					[58.925413934250209, 2.156285903717527],
					[58.925413930591994, 2.156285902702193],
					[58.925413926042175, 2.1562859018641136],
					[58.925413923275613, 2.1562859006715258],
					[58.923341722968388, 2.1560442001543909],
					[58.923341720293493, 2.1560442006861273],
					[58.923341713960383, 2.1560442002026066],
					[58.923341712177113, 2.1560442005570968],
					[58.92117781200232, 2.1559192000966707],
					[58.921177785961554, 2.1559192017881803],
					[58.921177736029989, 2.1559192117132735],
					[58.921177712230815, 2.1559192216709961],
					[58.920174912143366, 2.1564137217646047],
					[58.920174905010256, 2.1564137231823217],
					[58.920174892802308, 2.1564137308355842],
					[58.920174886744149, 2.1564137355242745],
					[58.918930486908678, 2.1572692352377256],
					[58.918930482542116, 2.1572692378476734],
					[58.918930477375575, 2.1572692423588493],
					[58.918930473900645, 2.1572692447916131],
					[58.917810993162526, 2.1581804283645769],
					[58.917141643369625, 2.1584915054738194],
					[58.916138893221948, 2.1586330987317637],
					[58.914888935956277, 2.1585470020694211],
					[58.914155705797441, 2.1583276238676676],
					[58.913419547785161, 2.1575804652799246],
					[58.912836253262093, 2.1568887701199708],
					[58.912252963731795, 2.1561970840884221],
					[58.911666882092625, 2.1552082151699188],
					[58.911202988924174, 2.1538666356038227],
					[58.911202987757505, 2.1538666306101257],
					[58.911202984624254, 2.1538666225235943],
					[58.911202982657642, 2.1538666194307634],
					[58.910894582798498, 2.153166618785058],
					[58.910894573856979, 2.1531666031439887],
					[58.910894552315746, 2.1531665708471337],
					[58.910894539899381, 2.1531665576385923],
					[58.910127940128319, 2.1524192570077223],
					[58.910127938253368, 2.1524192556386401],
					[58.910127935395096, 2.1524192527232158],
					[58.910127933428456, 2.1524192496305612],
					[58.909483432849498, 2.1518470506742293],
					[58.90948341508328, 2.1518470385302337],
					[58.90948337607594, 2.1518470166747865],
					[58.909483354926572, 2.1518470086869028],
					[58.908536196352763, 2.1515748198994507],
					[58.907586232546237, 2.1507748499098889],
					[58.906422340623536, 2.1496859573123963],
					[58.905102944855365, 2.1483026624298174],
					[58.905102939138651, 2.1483026566004888],
					[58.905102926905343, 2.148302646842422],
					[58.905102921188643, 2.1483026410130925],
					[58.903908434889082, 2.1473915508864843],
					[58.90262234585564, 2.1460109646874788],
					[58.902622344780482, 2.1460109614184848],
					[58.902622341922097, 2.1460109585042053],
					[58.902622340047081, 2.1460109571357302],
					[58.901455739771833, 2.1448609588404648],
					[58.901455733963225, 2.1448609512891537],
					[58.901455720838079, 2.1448609417106668],
					[58.901455714229613, 2.1448609360598581],
					[58.90047791396735, 2.144180436394326],
					[58.900477889867396, 2.1441804237758362],
					[58.900477839359823, 2.144180405963318],
					[58.90047781196877, 2.1441803992235866],
					[58.899775012249549, 2.1441387002203731],
					[58.899774997000293, 2.1441387015129365],
					[58.899774966409957, 2.1441387023750131],
					[58.899774951252475, 2.1441387053906067],
					[58.899258250622893, 2.1442692068433384],
					[58.899258218891859, 2.1442692201210751],
					[58.899258163205246, 2.1442692573185465],
					[58.899258138358078, 2.1442692814156588],
					[58.898866537829015, 2.1448081816813933],
					[58.898866537120973, 2.1448081853047447],
					[58.898866532846434, 2.1448081896375397],
					[58.898866532046604, 2.1448081915378903],
					[58.898444232233089, 2.1454665910947939],
					[58.89844423143326, 2.1454665929951036],
					[58.898444230633409, 2.1454665948954288],
					[58.898444229650025, 2.1454665933497905],
					[58.897960951140433, 2.1462415612482046],
					[58.897627732160693, 2.1464859017158555],
					[58.8971723448878, 2.1464969962127483],
					[58.896986296384469, 2.1459138574362093],
					[58.896919603590696, 2.1453278235735973],
					[58.897158488087776, 2.1446722679697716],
					[58.897158488795831, 2.1446722643465992],
					[58.897158491195334, 2.1446722586458327],
					[58.897158491995178, 2.1446722567455585],
					[58.897366892016002, 2.1439611567347017],
					[58.897366894664529, 2.1439611387961022],
					[58.897366899161796, 2.1439611048191485],
					[58.897366899043682, 2.14396108568975],
					[58.897300198850616, 2.1430221857086051],
					[58.897300198299796, 2.1430221753711609],
					[58.897300194706915, 2.1430221586743259],
					[58.897300193264456, 2.1430221485142993],
					[58.897175193399136, 2.1425527492728653],
					[58.897175182122403, 2.1425527236580351],
					[58.897175154311071, 2.1425526752157391],
					[58.897175136976657, 2.142552654288568],
					[58.896991837235689, 2.1423804541442122],
					[58.89699183062713, 2.1423804484945563],
					[58.896991816518444, 2.1423804373726689],
					[58.896991810001694, 2.1423804334458985],
					[58.896564009817851, 2.1420970327198439],
					[58.896563994017711, 2.1420970236759036],
					[58.896563959926233, 2.1420970095659748],
					[58.896563941634902, 2.1420970045000209],
					[58.896075042081641, 2.14199150481471],
					[58.89607504020659, 2.1419915034467065],
					[58.896075036548318, 2.141991502433541],
					[58.896075034765076, 2.1419915027883771],
					[58.895497235355769, 2.1418887027203506],
					[58.895497210115145, 2.1418887025195685],
					[58.895497159817559, 2.141888705563602],
					[58.89549713485242, 2.1418887105312412],
					[58.894949934982485, 2.1420776107440678],
					[58.89494993409086, 2.1420776109214725],
					[58.894949932307647, 2.1420776112762865],
					[58.89494992963278, 2.1420776118085136],
					[58.89443322966158, 2.1422692117544653],
					[58.894433214779582, 2.1422692199385081],
					[58.894433184215799, 2.1422692382067656],
					[58.894433170317235, 2.1422692479361825],
					[58.894008169624712, 2.1426331487603405],
					[58.894008155109923, 2.1426331638350771],
					[58.894008129830404, 2.1426331967204963],
					[58.894008119065703, 2.1426332145311737],
					[58.893616518596637, 2.1434666151241109],
					[58.893616512381683, 2.14346663377022],
					[58.893616502810183, 2.1434666739758303],
					[58.893616500253465, 2.1434666936352285],
					[58.893591499530501, 2.1443499935240564],
					[58.893591499805865, 2.1443499986922392],
					[58.893591500172988, 2.144350005583143],
					[58.893591500356571, 2.1443500090285874],
					[58.893630400210547, 2.1452306095749307],
					[58.893630400577649, 2.1452306164658523],
					[58.893630402203428, 2.1452306300703694],
					[58.893630402570516, 2.145230636961295],
					[58.89391370314538, 2.1467556375660428],
					[58.893913704128757, 2.1467556391115235],
					[58.893913705203872, 2.1467556423797407],
					[58.893913705387376, 2.146755645825233],
					[58.894133104592854, 2.1476917460423701],
					[58.894133105576216, 2.1476917475878747],
					[58.89413310566794, 2.1476917493106376],
					[58.894352605819876, 2.1485694486595159],
					[58.894352606986693, 2.1485694536506053],
					[58.894352610120215, 2.1485694617327917],
					[58.894352611287019, 2.1485694667238961],
					[58.8947248105409, 2.1496194667477697],
					[58.894724814565983, 2.1496194746529276],
					[58.894724821724523, 2.1496194906404558],
					[58.894724825749606, 2.1496194985456123],
					[58.895155411663637, 2.1503722754483423],
					[58.895469204744664, 2.1516611452017202],
					[58.895658104632318, 2.1524806450808049],
					[58.895658105707305, 2.152480648349369],
					[58.89565810687396, 2.1524806533408052],
					[58.895658107948961, 2.1524806566093697],
					[58.895969208177547, 2.1535333575779529],
					[58.895969209160846, 2.153533359123724],
					[58.895969212294077, 2.1535333672067489],
					[58.895969213460688, 2.1535333721982495],
					[58.89637201317592, 2.1545806714062796],
					[58.896372020242239, 2.1545806856727086],
					[58.896372034191543, 2.1545807107597814],
					[58.896372041966188, 2.154580721403335],
					[58.896863731106748, 2.1552168093155313],
					[58.897324823146789, 2.1560889931143317],
					[58.897324830029689, 2.1560890039355569],
					[58.89732484379546, 2.1560890255780283],
					[58.897324852370005, 2.1560890343221755],
					[58.897847052202948, 2.1566640347273665],
					[58.8978470579193, 2.1566640405569828],
					[58.897847069260344, 2.1566640504932248],
					[58.89784707497666, 2.1566640563228487],
					[58.898491574847192, 2.1571779563337228],
					[58.898491595287858, 2.1571779679459215],
					[58.898491635094288, 2.157177987901314],
					[58.898491657134997, 2.1571779957133819],
					[58.899222086073713, 2.1573390801438168],
					[58.8997137124639, 2.1579722853013363],
					[58.899902599116231, 2.1589694103378587],
					[58.899847002390231, 2.1596777637674145],
					[58.899549808319506, 2.160686043656193],
					[58.899549808411074, 2.1606860453792702],
					[58.899549806810924, 2.160686049179362],
					[58.89954980601086, 2.1606860510793968],
					[58.899399806328802, 2.1612777501600231],
					[58.899399804294816, 2.1612777627523725],
					[58.899399801210009, 2.1612777894832251],
					[58.899399799976102, 2.1612778001755593],
					[58.899408100125122, 2.1623971996165312],
					[58.899385899902462, 2.1635749820196186],
					[58.899122009124568, 2.1652277308459142],
					[58.898730427948635, 2.1658859974814471],
					[58.898730423856371, 2.1658860052577857],
					[58.898730414963154, 2.1658860244333953],
					[58.898730411854025, 2.1658860337559704],
					[58.898369212205473, 2.166894332605434],
					[58.898369209713486, 2.1668943365818891],
					[58.898369206695776, 2.1668943476273195],
					[58.898369205987088, 2.1668943512502135],
					[58.898102606149564, 2.167961051326321],
					[58.89783590611993, 2.169027751701051],
					[58.89783590381078, 2.1690277591233569],
					[58.897835901867467, 2.1690277734377506],
					[58.897835901341644, 2.169027780506585],
					[58.897663700829021, 2.1708555809335661],
					[58.897663700920447, 2.1708555826566038],
					[58.897663701011894, 2.1708555843796216],
					[58.897663701103312, 2.1708555861026442],
					[58.89749700088435, 2.1731555855397482],
					[58.89749700008408, 2.1731555874394073],
					[58.897497000175477, 2.1731555891624299],
					[58.897497000266888, 2.1731555908854494],
					[58.897416499606123, 2.1749832900462929],
					[58.897280402145398, 2.1775749657233869],
					[58.896894220805436, 2.178822101056844],
					[58.896380467906027, 2.1794220464211906],
					[58.895802739581796, 2.1797887005562036],
					[58.895286096193367, 2.1797998002339738],
					[58.895286090751959, 2.1797997995696679],
					[58.895286082726813, 2.1797998011576123],
					[58.895286079068754, 2.179799800140414],
					[58.894555579129729, 2.1798776011483634],
					[58.894555563079429, 2.1798776043241221],
					[58.894555531070125, 2.1798776123985397],
					[58.894555515202441, 2.1798776190201128],
					[58.894158215156565, 2.1800637184699627],
					[58.894158196887737, 2.1800637307892679],
					[58.894158163916821, 2.1800637547221857],
					[58.894158147614, 2.1800637701343977],
					[58.893797048227988, 2.1804831703100849],
					[58.893797034874055, 2.1804831903613073],
					[58.893797012807205, 2.1804832330274238],
					[58.893797004986006, 2.1804832554659068],
					[58.893558104713129, 2.1815499555894386],
					[58.893558102768374, 2.1815499699014529],
					[58.89355810038839, 2.1815499930041149],
					[58.893558100135714, 2.1815500052404935],
					[58.893599800126715, 2.182961106117991],
					[58.893599801474764, 2.1829611145559666],
					[58.893599802204918, 2.1829611283388783],
					[58.89359980256998, 2.1829611352303435],
					[58.893849803164017, 2.1843083356427564],
					[58.89384980432947, 2.184308340635094],
					[58.89384980576866, 2.1843083507960723],
					[58.893849807825802, 2.1843083556120866],
					[58.894349803814848, 2.1860611448279705],
					[58.894635900847604, 2.1878222223655679],
					[58.894738699091654, 2.1897027932183586],
					[58.894563703188645, 2.1911193642031783],
					[58.894238708382723, 2.1925388413365972],
					[58.8937581144475, 2.193786027451611],
					[58.893219213739208, 2.1951527273565841],
					[58.893219211428494, 2.1951527347762343],
					[58.893219206097584, 2.1951527532373292],
					[58.893219203877976, 2.1951527623798484],
					[58.892863704436628, 2.1969860626325013],
					[58.892863702307999, 2.1969860734977025],
					[58.892863699925172, 2.1969860965990349],
					[58.892863699579948, 2.196986107112282],
				],
			],
		},
		{
			fldNpdidField: "43437",
			wlbNpdidWellbore: "239",
			fldName: "ALBUSKJELL",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "1/6-1",
			fldDiscoveryYear: "1972",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43437",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43437",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "018",
			cmpLongName: "ConocoPhillips Skandinavia AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=239",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "2410696",
			fldNpdidOwner: "20900",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[56.603485899591213, 3.0860166887229949],
					[56.60348590397215, 3.08601672394507],
					[56.603485925089622, 3.0860167891874406],
					[56.603485940039, 3.0860168194879427],
					[56.603766525179978, 3.0863889992434141],
					[56.604205404248582, 3.0874694492062944],
					[56.6045998008155, 3.0914361195756066],
					[56.604599802172366, 3.0914361291566546],
					[56.604599805702456, 3.0914361465585896],
					[56.604599807875701, 3.0914361543794775],
					[56.604672006814397, 3.0916861524911936],
					[56.604855404996499, 3.0923917457195649],
					[56.605072004229406, 3.0934917392630625],
					[56.605072004306592, 3.0934917408832576],
					[56.605072004383771, 3.0934917425034554],
					[56.60541089866382, 3.0950889182177428],
					[56.605347000285335, 3.0969610932549427],
					[56.605346999546008, 3.0969610966352423],
					[56.605346999931704, 3.0969611047363514],
					[56.605347000163121, 3.0969611095970229],
					[56.605505400337719, 3.1004500090792226],
					[56.605505400877384, 3.1004500204209018],
					[56.605505404637512, 3.1004500426848862],
					[56.605505406964376, 3.1004500537469868],
					[56.606147007319358, 3.1027750527293314],
					[56.606147011587396, 3.1027750667528511],
					[56.606147022727221, 3.1027750927604503],
					[56.606147029599008, 3.1027751047445271],
					[56.606777629940233, 3.1037918044691377],
					[56.606777653744558, 3.1037918317621327],
					[56.606777708933414, 3.1037918753689344],
					[56.606777741211559, 3.1037918915430329],
					[56.607994337765973, 3.104166890721225],
					[56.609313834991539, 3.1046223889612206],
					[56.609313852278397, 3.1046223927884911],
					[56.609313885881441, 3.1046223989623596],
					[56.609313903907818, 3.1046223994091067],
					[56.610447203703451, 3.1046001999491759],
					[56.610447212639606, 3.1046001985520726],
					[56.610447229695325, 3.1046001975180353],
					[56.610447238631473, 3.1046001961209346],
					[56.611602839376431, 3.1043723956027951],
					[56.611602850022756, 3.1043723923056254],
					[56.611602871392435, 3.1043723873317952],
					[56.611602881961716, 3.1043723824141178],
					[56.612672281373669, 3.1038945830329534],
					[56.615233380211727, 3.1027723823690363],
					[56.615233386389924, 3.1027723797699269],
					[56.615233397852712, 3.1027723747114861],
					[56.615233403060238, 3.1027723706314783],
					[56.615994502723169, 3.1023140715110702],
					[56.61599451834568, 3.1023140592706273],
					[56.61599454690986, 3.102314035209123],
					[56.615994559774471, 3.1023140217673681],
					[56.616825159953308, 3.1012140212004811],
					[56.616825164112967, 3.1012140140184554],
					[56.616825173325921, 3.1012139995145791],
					[56.61682517837918, 3.1012139921927453],
					[56.617502977850272, 3.0999028922795548],
					[56.617502977773157, 3.099902890658806],
					[56.618130777872757, 3.0986778915890869],
					[56.618130778689228, 3.0986778898284242],
					[56.618130781138653, 3.0986778845464387],
					[56.618130781955131, 3.0986778827857768],
					[56.61865578215513, 3.0975139837549905],
					[56.619183481052474, 3.0963944853829859],
					[56.619183480975316, 3.0963944837621744],
					[56.619183482685351, 3.0963944818614646],
					[56.619183482608193, 3.0963944802406558],
					[56.619666883287572, 3.0952917804366509],
					[56.619666884843255, 3.0952917752941942],
					[56.619666888108938, 3.0952917682509709],
					[56.619666889741787, 3.0952917647293448],
					[56.620183490291552, 3.0937528640211127],
					[56.620183491030751, 3.0937528606394062],
					[56.620183491692764, 3.0937528556368381],
					[56.620183493325563, 3.0937528521151125],
					[56.620525193365673, 3.0924861526638283],
					[56.620525194027636, 3.0924861476612113],
					[56.620525196245154, 3.092486137515897],
					[56.62052519690711, 3.0924861325132813],
					[56.620739097375271, 3.0912167330450631],
					[56.620739097959941, 3.0912167264215271],
					[56.620739099868437, 3.0912167097926435],
					[56.620739100453115, 3.0912167031691018],
					[56.620766900402593, 3.0892028026638516],
					[56.620766900248093, 3.0892027994221389],
					[56.620766899939071, 3.0892027929386998],
					[56.620766899784556, 3.0892027896969871],
					[56.620650199861593, 3.08685558925113],
					[56.620650199861593, 3.08685558925113],
					[56.620650199784308, 3.0868555876302843],
					[56.620650199784308, 3.0868555876302843],
					[56.620514101307975, 3.0846944146181428],
					[56.621386295389868, 3.0807250455095163],
					[56.621772392574691, 3.0791667512769756],
					[56.622483491950788, 3.0766306575534217],
					[56.62410018291073, 3.0714556846017222],
					[56.625586263807484, 3.0690862154768315],
					[56.62732235813985, 3.0668390221409592],
					[56.627322364822966, 3.066839011288975],
					[56.627322378266719, 3.0668389912060858],
					[56.627322384056278, 3.0668389804947962],
					[56.628205784316279, 3.064800079562608],
					[56.628205785793085, 3.0648000727966846],
					[56.6282057913497, 3.0648000572215266],
					[56.628205792826478, 3.0648000504556081],
					[56.62894459337479, 3.061975050940442],
					[56.628944594190706, 3.0619750491784994],
					[56.629883492161326, 3.0583361583625832],
					[56.630875188431943, 3.0555083673770214],
					[56.631741887832646, 3.0530944684353751],
					[56.631741890862628, 3.0530944565226545],
					[56.631741897000303, 3.0530944343184179],
					[56.631741899214497, 3.0530944241679592],
					[56.631989099028743, 3.0510361236661372],
					[56.631989098795458, 3.0510361188024473],
					[56.631989100193699, 3.0510361104141261],
					[56.631989099960421, 3.05103610555045],
					[56.63204190018967, 3.0492917114644054],
					[56.632180797626795, 3.0475472326542441],
					[56.6326973943127, 3.0454250473884605],
					[56.632697395128396, 3.0454250456259779],
					[56.632697395788405, 3.0454250406210348],
					[56.632697395788405, 3.0454250406210348],
					[56.633439096131291, 3.041744439231469],
					[56.633922393381212, 3.0393750546557095],
					[56.634700187665779, 3.0372722690775418],
					[56.634700189218954, 3.0372722639307623],
					[56.634700193218819, 3.0372722534957934],
					[56.634700193878487, 3.0372722484904626],
					[56.63511959370809, 3.0355667485845816],
					[56.635119595261187, 3.0355667434376796],
					[56.635119595842838, 3.035566736810952],
					[56.635119596580402, 3.0355667334268377],
					[56.635486294685023, 3.0334278438401525],
					[56.635886286677469, 3.0320111734546922],
					[56.636680776629056, 3.0304917931869726],
					[56.63668078241529, 3.0304917824675588],
					[56.636680790413806, 3.0304917615951146],
					[56.63668079262608, 3.0304917514421041],
					[56.637150193357471, 3.0287250505919117],
					[56.637150194094851, 3.028725047207506],
					[56.637150196306976, 3.0287250370542727],
					[56.637150197044349, 3.0287250336698501],
					[56.637833493745887, 3.0245861564171546],
					[56.638547383729154, 3.0228806776151713],
					[56.638547386175183, 3.0228806723255319],
					[56.638547390251901, 3.0228806635094445],
					[56.638547390910993, 3.0228806585033685],
					[56.639075189776918, 3.0211750637321977],
					[56.63964188890413, 3.0195528659654478],
					[56.63964188956308, 3.0195528609591902],
					[56.639641894454847, 3.0195528503791618],
					[56.639641895113776, 3.0195528453728908],
					[56.640250194804864, 3.016911144822422],
					[56.640250194804864, 3.016911144822422],
					[56.640250194726654, 3.0169111432009417],
					[56.640697395410996, 3.0148722428412391],
					[56.640697395332758, 3.0148722412197446],
					[56.640697396304461, 3.0148722426992456],
					[56.640697396226223, 3.0148722410777538],
					[56.641261295913679, 3.0121889406191129],
					[56.641261295835399, 3.0121889389976051],
					[56.641261297465775, 3.012188935470455],
					[56.641261297387494, 3.0121889338489503],
					[56.64166409670117, 3.0098194348249958],
					[56.641664098253152, 3.0098194296762113],
					[56.641664098833402, 3.0098194230480031],
					[56.641664098676792, 3.009819419804951],
					[56.641864097588979, 3.0078222301949542],
					[56.642483495081088, 3.0047028464598449],
					[56.643764090614077, 3.0001667608323035],
					[56.644230788722602, 2.9988333664672684],
					[56.644669588185977, 2.997605667497615],
					[56.644669591445833, 2.9976056604414891],
					[56.644669595285279, 2.9976056467564649],
					[56.644669595786375, 2.9976056385059739],
					[56.645019595677113, 2.99581673826289],
					[56.645019597385478, 2.9958167363563253],
					[56.645019597149997, 2.9958167314914643],
					[56.645019597964954, 2.9958167297273777],
					[56.645205797653432, 2.9945889299858619],
					[56.645205799126273, 2.9945889232143554],
					[56.645205800285069, 2.9945889099563363],
					[56.645205799971052, 2.9945889034698352],
					[56.645233500216023, 2.9927389029233806],
					[56.645233499980435, 2.992738898058517],
					[56.645233500402668, 2.9927388881862416],
					[56.645233499273644, 2.9927388834639044],
					[56.645200199710203, 2.9923443929223255],
					[56.645225199827109, 2.9903694187684731],
					[56.645469597147837, 2.9889750353815048],
					[56.645469597069258, 2.9889750337598855],
					[56.645469596990679, 2.9889750321382653],
					[56.645764096772716, 2.9871444352920413],
					[56.646080795609322, 2.9855000386643127],
					[56.646080797238902, 2.9855000351356127],
					[56.64608079781781, 2.98550002850634],
					[56.646080797660559, 2.9855000252630703],
					[56.646327998246456, 2.9835667246288131],
					[56.646327999061214, 2.9835667228644134],
					[56.646552998751787, 2.9816111235785883],
					[56.646552999251803, 2.9816111153275449],
					[56.64655300033052, 2.981611100447116],
					[56.646552999937128, 2.981611092338881],
					[56.646491900200644, 2.9799277925356789],
					[56.646491900121937, 2.9799277909140454],
					[56.646491899964545, 2.9799277876707708],
					[56.646491898913744, 2.9799277845703327],
					[56.646400199683299, 2.9786999889600021],
					[56.64632520092583, 2.976788910787298],
					[56.646636297911932, 2.974522228848659],
					[56.646933498192709, 2.972627830830417],
					[56.646933498035125, 2.9726278275871505],
					[56.646933499427931, 2.9726278191929207],
					[56.646933499191547, 2.9726278143280047],
					[56.647083498833751, 2.9706528150609124],
					[56.647083500384085, 2.9706528099098626],
					[56.647083499911155, 2.9706528001800452],
					[56.647083499674693, 2.9706527953151283],
					[56.647030800264339, 2.9683027955833317],
					[56.646983500258777, 2.9659139054027022],
					[56.647339099452694, 2.9609000227070683],
					[56.647830797932208, 2.9575722298675515],
					[56.648555797364004, 2.9530361321365133],
					[56.648555798020205, 2.9530361271280707],
					[56.648555799411675, 2.9530361187328418],
					[56.648555799253572, 2.9530361154895521],
					[56.648758499101788, 2.9502694257985],
					[56.649064094125968, 2.9486889495488331],
					[56.649905790794378, 2.9459611589547094],
					[56.649905793078695, 2.945961150415279],
					[56.649905796753998, 2.945961133480083],
					[56.649905798144971, 2.9459611250843221],
					[56.650094597914226, 2.9444833313129646],
					[56.650408495741011, 2.9428611372733906],
					[56.650408495741011, 2.9428611372733906],
					[56.650408496555173, 2.9428611355079521],
					[56.650408497448517, 2.9428611353642053],
					[56.650741897453017, 2.9410083355183589],
					[56.650741897373784, 2.9410083338966442],
					[56.650741898108713, 2.9410083305094479],
					[56.650741898108713, 2.9410083305094479],
					[56.651102996890977, 2.9385944383474709],
					[56.651539094724704, 2.9367417465766845],
					[56.651539096273567, 2.9367417414237198],
					[56.651539096849838, 2.9367417347929123],
					[56.651539098398693, 2.9367417296399494],
					[56.651772397601825, 2.9351806303944787],
					[56.651772398415858, 2.9351806286288151],
					[56.651772398415858, 2.9351806286288151],
					[56.652064095627075, 2.9331000439148784],
					[56.652530789161567, 2.9316000652124146],
					[56.653208486620883, 2.9297889700313027],
					[56.653208488407543, 2.9297889697431594],
					[56.653208489142145, 2.9297889663555421],
					[56.653208489062784, 2.9297889647337669],
					[56.653747388580996, 2.9282056637839613],
					[56.653747391102186, 2.9282056601080444],
					[56.6537473941992, 2.9282056498008266],
					[56.653747394854378, 2.9282056447913218],
					[56.654183494177609, 2.9263111476911465],
					[56.65476959183006, 2.923977856850577],
					[56.65526958928875, 2.9225389655917962],
					[56.6552695923058, 2.9225389536618822],
					[56.655269598181, 2.9225389265583512],
					[56.655269600225289, 2.9225389131508348],
					[56.655400200277853, 2.9205444136891248],
					[56.655400200118891, 2.9205444104454288],
					[56.655400199721491, 2.920544402336211],
					[56.655400199562528, 2.9205443990925311],
					[56.655391899816827, 2.919025010817744],
					[56.655844595785254, 2.9152583415925108],
					[56.656672385598085, 2.912572274581779],
					[56.657391878648049, 2.9111111886872356],
					[56.657391883609762, 2.9111111797105185],
					[56.657391890694115, 2.9111111589468961],
					[56.657391893789637, 2.9111111486373558],
					[56.657894593956009, 2.9090917483266363],
					[56.657894596158073, 2.9090917381613863],
					[56.657894598696011, 2.9090917164981422],
					[56.657894600004781, 2.9090917064774806],
					[56.65795020035349, 2.9073028095274234],
					[56.658007786317832, 2.90646045256412],
					[56.657999800192336, 2.9072193977905658],
					[56.657999828868526, 2.9072194683489609],
					[56.657999929291947, 2.9072195697958896],
					[56.658000000066252, 2.9072195992071346],
					[56.658008299838365, 2.907219599209975],
					[56.658008369611252, 2.9072195715649536],
					[56.658008470142661, 2.9072194735473564],
					[56.658008500087547, 2.9072194049413236],
					[56.658025199776347, 2.9064917055889081],
					[56.658025199617015, 2.9064917023450709],
					[56.658025200271297, 2.9064916973346904],
					[56.658025200191631, 2.9064916957127811],
					[56.658000200053621, 2.9054527952783036],
					[56.658000199973948, 2.905452793656397],
					[56.6580001997349, 2.9054527887906785],
					[56.658000199655227, 2.9054527871687554],
					[56.657872399901024, 2.903538887655249],
					[56.657872398449818, 2.903538876446687],
					[56.657872393760826, 2.9035388543189824],
					[56.657872392309613, 2.903538843110419],
					[56.657741892426081, 2.9031027430361096],
					[56.657741888374701, 2.9031027338836748],
					[56.657741881165244, 2.9031027154340769],
					[56.657741878086846, 2.9031027077588023],
					[56.657214078098569, 2.9020860079272928],
					[56.65721407615257, 2.9020860049730661],
					[56.657214072260579, 2.9020859990645942],
					[56.657214071207861, 2.9020859959656184],
					[56.656658482273443, 2.9011749141522127],
					[56.656350191997696, 2.9003027427847612],
					[56.655777994442929, 2.8979582532409598],
					[56.65577799331033, 2.8979582485204181],
					[56.655777990151861, 2.8979582392241903],
					[56.655777989019263, 2.8979582345036641],
					[56.655602988556929, 2.8974582341413511],
					[56.655602935262841, 2.897458175759835],
					[56.655602801749822, 2.8974581156738335],
					[56.655602721610691, 2.897458115591121],
					[56.654700175967506, 2.8978386095290953],
					[56.654594599402849, 2.8965916822003828],
					[56.654594599323062, 2.8965916805786485],
					[56.65459459908368, 2.8965916757134327],
					[56.65459459811062, 2.8965916742365634],
					[56.654469598444102, 2.8956360747261534],
					[56.654469597231625, 2.8956360683841402],
					[56.654469595859524, 2.8956360587986594],
					[56.654469593753767, 2.8956360526015179],
					[56.654294593640493, 2.8949082526169994],
					[56.65429459024238, 2.8949082384562357],
					[56.654294578006727, 2.894908209382427],
					[56.654294571035564, 2.8949081958012708],
					[56.65394187086671, 2.8943276958655213],
					[56.65394185180412, 2.8943276744382773],
					[56.653941810265536, 2.8943276354068512],
					[56.653941786082846, 2.8943276197141978],
					[56.653252885790934, 2.8939998195940553],
					[56.653252861034247, 2.8939998105334612],
					[56.653252810213274, 2.8939998024321487],
					[56.653252784069174, 2.8939998017697586],
					[56.652747248769444, 2.8940414948692923],
					[56.652244524266898, 2.8937331417419925],
					[56.651694545699932, 2.8931970625555707],
					[56.651161251551734, 2.8925776695094081],
					[56.651161248712278, 2.8925776667010799],
					[56.651161242953492, 2.8925776594628356],
					[56.651161240114035, 2.8925776566544901],
					[56.650527940159357, 2.8919554567588976],
					[56.6499557398134, 2.8913970567311877],
					[56.649955733241164, 2.8913970512598253],
					[56.649955718230323, 2.8913970389855064],
					[56.649955710764829, 2.8913970336591222],
					[56.649339010984512, 2.8909859347569786],
					[56.649338992480445, 2.8909859246843745],
					[56.649338952059033, 2.8909859083620413],
					[56.649338931034926, 2.8909859019673334],
					[56.648572230933354, 2.8908665026013245],
					[56.648572199349864, 2.8908665011892927],
					[56.648572137794915, 2.8908665128134219],
					[56.648572107663703, 2.8908665226066455],
					[56.647719308359122, 2.8913081214329504],
					[56.647719295279636, 2.8913081300929226],
					[56.64771927009383, 2.8913081488893129],
					[56.647719258880741, 2.8913081588808027],
					[56.647091564910134, 2.8919359527987161],
					[56.646385989103287, 2.8925220312913393],
					[56.645358212601479, 2.8930470199288965],
					[56.644424921061898, 2.8934665166946703],
					[56.643391624101454, 2.8938887142063443],
					[56.642211024265748, 2.8943748156099978],
					[56.642211021585958, 2.8943748160445408],
					[56.642211018092716, 2.8943748182451277],
					[56.642211016306163, 2.8943748185348288],
					[56.641299915935789, 2.8947970194413424],
					[56.64129990269641, 2.894797024856171],
					[56.641299879216646, 2.8947970417359423],
					[56.641299867923415, 2.894797050103417],
					[56.640563767690153, 2.895444250401777],
					[56.640563758263177, 2.8954442601003709],
					[56.640563738595773, 2.8954442812635253],
					[56.640563730221665, 2.8954442940595886],
					[56.639963730248063, 2.8964054939740667],
					[56.639963726914331, 2.8964054994165478],
					[56.639963721219928, 2.8964055117778114],
					[56.639963717886197, 2.8964055172202916],
					[56.639416518262692, 2.8976138155778095],
					[56.638997021865578, 2.8985110098253766],
					[56.63851923465571, 2.8993637850779783],
					[56.637944256921948, 2.9000331589911967],
					[56.637213766614757, 2.9006804497193817],
					[56.637213764907926, 2.9006804516297389],
					[56.637213759787436, 2.9006804573607692],
					[56.637213758080605, 2.9006804592711144],
					[56.636527658035156, 2.9013720597094967],
					[56.636527652021364, 2.9013720655849915],
					[56.636527640966733, 2.9013720788122872],
					[56.636527635925937, 2.9013720861641006],
					[56.635860936014311, 2.9023304855526355],
					[56.63586093512103, 2.9023304856972585],
					[56.635860934307459, 2.9023304874628306],
					[56.635860934387161, 2.9023304890837927],
					[56.634377633920032, 2.9045415882697534],
					[56.633566534093127, 2.905752688186229],
					[56.633566532465871, 2.9057526917170104],
					[56.633566529945313, 2.9057526953923261],
					[56.633566528238397, 2.9057526973022632],
					[56.632783128137703, 2.9070665984961948],
					[56.632783127244416, 2.9070665986406929],
					[56.632783124723844, 2.9070666023158398],
					[56.632783123910194, 2.9070666040811797],
					[56.632033124177127, 2.908441604322908],
					[56.632033120922436, 2.9084416113839682],
					[56.632033113599384, 2.9084416272713494],
					[56.632033111237988, 2.9084416341879331],
					[56.631377610783623, 2.9103360346319413],
					[56.631377609969917, 2.9103360363971333],
					[56.631377609235813, 2.9103360397831177],
					[56.631377609315408, 2.9103360414038963],
					[56.630822009054889, 2.912147141265466],
					[56.630822008320727, 2.9121471446513683],
					[56.630822005879537, 2.9121471499467475],
					[56.63082200603867, 2.9121471531882706],
					[56.630374805642397, 2.9139777531660336],
					[56.630374804828648, 2.913977754931099],
					[56.630374804253506, 2.9139777615584239],
					[56.63037480343975, 2.913977763323492],
					[56.630091502813634, 2.9154971623307731],
					[56.630091502893158, 2.9154971639515113],
					[56.630091502972682, 2.9154971655722495],
					[56.630091503052199, 2.9154971671929903],
					[56.629847002621226, 2.9169527678550531],
					[56.629847002621226, 2.9169527678550531],
					[56.629847001807413, 2.9169527696200359],
					[56.629847001807413, 2.9169527696200359],
					[56.629580402081885, 2.9187221696099654],
					[56.629580402161373, 2.9187221712306908],
					[56.629299802003082, 2.9206555714250304],
					[56.629299802082542, 2.9206555730457655],
					[56.629299801348147, 2.9206555764313658],
					[56.629299800613758, 2.9206555798169611],
					[56.629085901470575, 2.9227360804976832],
					[56.629085900736094, 2.9227360838832479],
					[56.629085900319367, 2.9227360937516749],
					[56.629085899664339, 2.9227360987579356],
					[56.629069199988194, 2.924272194431548],
					[56.628977600131513, 2.9266638912553824],
					[56.62897760029027, 2.9266638944968335],
					[56.628977600449034, 2.9266638977382824],
					[56.628977599635085, 2.926663899503037],
					[56.628966499813046, 2.9281610944408949],
					[56.628885900146223, 2.929697189785665],
					[56.628885900225555, 2.9296971914063947],
					[56.628885900225555, 2.9296971914063947],
					[56.628885900304894, 2.9296971930271267],
					[56.628849800116363, 2.9307999933131375],
					[56.628849800195688, 2.9307999949338708],
					[56.628849800354338, 2.9307999981753499],
					[56.628849800354338, 2.9307999981753499],
					[56.628844200389871, 2.9319221927126757],
					[56.628730401344122, 2.9333971754873494],
					[56.628483103833467, 2.9349166585371989],
					[56.628416506898105, 2.9351666484589658],
					[56.628416506084037, 2.9351666502235019],
					[56.628416505428504, 2.9351666552295055],
					[56.628416504614428, 2.9351666569940416],
					[56.628199805239802, 2.9361443577448587],
					[56.628199803611651, 2.9361443612738656],
					[56.628199802300479, 2.936144371285792],
					[56.628199800751574, 2.9361443764355202],
					[56.62807760257099, 2.9371416667903434],
					[56.627863707654519, 2.938161039491253],
					[56.62765542619929, 2.9386609964905097],
					[56.626999851387261, 2.9394748652235374],
					[56.62641096373607, 2.9400581531606007],
					[56.625572069376879, 2.9407887492613281],
					[56.625572065068532, 2.9407887532208754],
					[56.625572057345188, 2.9407887609963219],
					[56.625572053036855, 2.9407887649558662],
					[56.624802652777298, 2.9416248654486052],
					[56.624802651883954, 2.9416248655922419],
					[56.62480265099061, 2.941624865735879],
					[56.624802651069793, 2.9416248673564689],
					[56.624355450567521, 2.9421248668181961],
					[56.624355442188254, 2.9421248795984654],
					[56.624355425429727, 2.9421249051589733],
					[56.624355417129628, 2.9421249195598156],
					[56.623474817002894, 2.9441027196859042],
					[56.623474815374486, 2.9441027232141446],
					[56.623474812196818, 2.9441027318911508],
					[56.623474810568403, 2.9441027354193774],
					[56.623263711290598, 2.9447277359029735],
					[56.623263710397239, 2.944727736046528],
					[56.622983109699703, 2.9455610365920033],
					[56.622983108229484, 2.9455610433611907],
					[56.622983103581518, 2.9455610588071321],
					[56.622983103004664, 2.9455610654327811],
					[56.622866503308501, 2.9462471654486828],
					[56.622866501838267, 2.9462471722178223],
					[56.622866500605369, 2.9462471838485289],
					[56.622866500028479, 2.946247190474149],
					[56.622830399569821, 2.9469749901085773],
					[56.622830399886254, 2.946974996590682],
					[56.622830399625762, 2.946975009698396],
					[56.622830400914665, 2.946975017657524],
					[56.622891501307862, 2.9476806171842296],
					[56.622891501861552, 2.9476806285279387],
					[56.62289150743571, 2.9476806504979565],
					[56.622891509776117, 2.9476806615546982],
					[56.623158109708079, 2.9485111615676876],
					[56.623158109787155, 2.9485111631882308],
					[56.623158111811151, 2.9485111677629403],
					[56.623158112862683, 2.948511170860558],
					[56.623485913006505, 2.9493806715383739],
					[56.623485920944148, 2.9493806865964167],
					[56.623485938685228, 2.9493807180461551],
					[56.623485950196304, 2.9493807325304142],
					[56.623999698402955, 2.9499610616584766],
					[56.623402688755057, 2.9503581332481237],
					[56.62340264677519, 2.9503581955183051],
					[56.6234026172868, 2.9503583292794917],
					[56.623402628964008, 2.9503584025344574],
					[56.623949817170896, 2.9512694839549316],
					[56.624335904949469, 2.9524306476779816],
					[56.624858101023172, 2.955877821774207],
					[56.624988699581614, 2.9576667002586579],
					[56.624638700941304, 2.9631110847376756],
					[56.624174801037164, 2.9683082823271687],
					[56.624174801037164, 2.9683082823271687],
					[56.624174801115991, 2.9683082839478141],
					[56.624174801194826, 2.9683082855684586],
					[56.623916501664596, 2.9716749713593793],
					[56.623530404145008, 2.9735471602204759],
					[56.623530402673268, 2.9735471669887699],
					[56.623530400780695, 2.9735471836237384],
					[56.623530400202341, 2.973547190249159],
					[56.623466499568735, 2.9749999903503053],
					[56.623466499726227, 2.974999993591573],
					[56.623466499962447, 2.9749999984534927],
					[56.623466500041189, 2.9750000000741279],
					[56.623466499759985, 2.9766832890680823],
					[56.623208101278223, 2.9789499763161893],
					[56.622938700821642, 2.9812582764908746],
					[56.622938700978956, 2.9812582797321356],
					[56.622938700400198, 2.9812582863573618],
					[56.62293869966409, 2.9812582897413145],
					[56.622760899809727, 2.9849971894954224],
					[56.622760900281392, 2.9849971992192406],
					[56.622760901067522, 2.9849972154256075],
					[56.622760902432617, 2.9849972250068233],
					[56.622946999656591, 2.9864722147345835],
					[56.622966499686576, 2.9884471966545432],
					[56.622894200114601, 2.9901721856740511],
					[56.622660902884618, 2.9925416582169797],
					[56.622341510018273, 2.9935388392761757],
					[56.622341509203338, 2.993538841039217],
					[56.622341506758552, 2.9935388463283443],
					[56.622341506837053, 2.9935388479489857],
					[56.621933107723265, 2.9950582452861063],
					[56.621463708202775, 2.9966166423436209],
					[56.621463706572811, 2.9966166458695009],
					[56.621463705178236, 2.9966166542572026],
					[56.62146370354828, 2.9966166577830844],
					[56.620747004251619, 2.9999443579667977],
					[56.620747004330049, 2.999944359587388],
					[56.620747004330049, 2.999944359587388],
					[56.61956370596954, 3.005744352565741],
					[56.618180407728985, 3.0104416434210974],
					[56.618180407070355, 3.0104416484246288],
					[56.618180404044459, 3.010441660336137],
					[56.618180403385828, 3.0104416653396515],
					[56.617777604440626, 3.0127082614292253],
					[56.616847005389801, 3.0170110551492177],
					[56.616141504671447, 3.0200249542212951],
					[56.616141504012411, 3.0200249592244126],
					[56.616141501800882, 3.0200249693723684],
					[56.616141502035333, 3.0200249742337459],
					[56.615580401574782, 3.0242666724757887],
					[56.615269202166516, 3.0264277690720429],
					[56.615085903367522, 3.0275277666229434],
					[56.615085902048861, 3.0275277766286526],
					[56.615085900148898, 3.0275277932576667],
					[56.615085899645671, 3.0275278015013924],
					[56.61509420014459, 3.0290027933593864],
					[56.614905402314946, 3.0313916668582297],
					[56.614241507214189, 3.0340110485602936],
					[56.613144209197998, 3.0378360425390269],
					[56.611205410366047, 3.0438027372298291],
					[56.611205408812523, 3.0438027423730696],
					[56.611205407336847, 3.0438027491366362],
					[56.611205405705491, 3.0438027526595777],
					[56.610335906453301, 3.0473971527262296],
					[56.609710905794479, 3.0498499507555534],
					[56.609710905134229, 3.0498499557573511],
					[56.609710902920192, 3.049849965901966],
					[56.609710902259934, 3.0498499709037827],
					[56.609438702066832, 3.0517166704199283],
					[56.609438702300025, 3.0517166752807112],
					[56.609438700746161, 3.0517166804234144],
					[56.609438700979354, 3.0517166852841981],
					[56.609355401304718, 3.0528193722661583],
					[56.609005406113901, 3.0546277471809837],
					[56.608441511779247, 3.0562277314912283],
					[56.607910912585204, 3.0576610280595764],
					[56.607394214046138, 3.0589499257069055],
					[56.60698311414302, 3.0599888253009113],
					[56.6069831125888, 3.0599888304429577],
					[56.60698311029627, 3.059988838966111],
					[56.606983107770887, 3.0599888426287056],
					[56.60652481043585, 3.0615277373808563],
					[56.606197011754993, 3.0624193303020109],
					[56.606197011910169, 3.0624193335423362],
					[56.606197011094217, 3.062419335303173],
					[56.606197010278265, 3.0624193370640076],
					[56.605613711180077, 3.0641860344648486],
					[56.605116511904818, 3.0655360316626052],
					[56.605116511904818, 3.0655360316626052],
					[56.605116511166358, 3.0655360350434426],
					[56.605116510272808, 3.065536035184047],
					[56.604613709741457, 3.0670332361199479],
					[56.604613709158023, 3.0670332427409264],
					[56.604613705971452, 3.0670332514037013],
					[56.60461370360089, 3.0670332583058015],
					[56.604349803836229, 3.0682582581648954],
					[56.604349804068775, 3.0682582630251836],
					[56.604349801930688, 3.0682582747874512],
					[56.604349801192164, 3.0682582781681687],
					[56.603985901296078, 3.0716860762161819],
					[56.60374700193799, 3.0735138734521437],
					[56.603747001199316, 3.0735138768327155],
					[56.60374700143165, 3.0735138816929735],
					[56.603747000692962, 3.0735138850735475],
					[56.603652600990415, 3.0747805858069714],
					[56.603652599745246, 3.0747805974283136],
					[56.603652600829221, 3.0747806201094785],
					[56.603652603158359, 3.0747806311693182],
					[56.603816502974801, 3.0757972318362392],
					[56.603816505226419, 3.0757972412760908],
					[56.603816509807068, 3.0757972617759011],
					[56.60381651295225, 3.0757972710753969],
					[56.604022008367693, 3.0763389585276215],
					[56.604272003831909, 3.0774389415416419],
					[56.604408101199425, 3.078125024747155],
					[56.604477599163914, 3.0794527899438489],
					[56.604227603201657, 3.0811138613121267],
					[56.603941504678353, 3.0823193539147447],
					[56.603941504910345, 3.082319358775091],
					[56.603941502538916, 3.0823193656761743],
					[56.60394150187733, 3.0823193706767227],
					[56.603541502352151, 3.0849777703630243],
					[56.603541501690451, 3.0849777753634937],
					[56.603541501260636, 3.0849777852242837],
					[56.603541499628058, 3.0849777887447698],
					[56.603485899591213, 3.0860166887229949],
				],
			],
		},
		{
			fldNpdidField: "43751",
			wlbNpdidWellbore: "474",
			fldName: "NJORD",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6407/7-1 S",
			fldDiscoveryYear: "1986",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43751",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43751",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "NJORD UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=474",
			fldHcType: "OIL",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "40585",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2021-12-21 00:00:00",
			fldMainSupplyBase: "Kristiansund",
			fldAreaGeometryWKT: [
				[
					[64.278647010325869, 7.1070722609764445],
					[64.2794581078752, 7.1095972560348848],
					[64.280052605236321, 7.1119778459659786],
					[64.280730403915584, 7.1151611424159853],
					[64.280730405762426, 7.115161146421598],
					[64.280730408612513, 7.1151611586220325],
					[64.280730409589125, 7.1151611647528563],
					[64.281685909607461, 7.1180000650590074],
					[64.281685911427701, 7.1180000670008727],
					[64.281685912351065, 7.1180000690038447],
					[64.281685913274472, 7.1180000710068132],
					[64.28270811338453, 7.1206722708373924],
					[64.282708114334383, 7.1206722749046811],
					[64.282708116181084, 7.120672278910968],
					[64.282708117104463, 7.1206722809140999],
					[64.284010917379547, 7.1236472801925288],
					[64.285172014772471, 7.1263083766698774],
					[64.286269213667325, 7.12913897126883],
					[64.286269213693728, 7.1291389733332799],
					[64.287505411681295, 7.1322778673626193],
					[64.288610910229295, 7.1355917636013286],
					[64.288610932227144, 7.1355917993111797],
					[64.288610987750431, 7.1355918596205745],
					[64.288611023069535, 7.135591884099056],
					[64.291563803616455, 7.136825177496406],
					[64.292838764260097, 7.1376973498698622],
					[64.294616546048942, 7.139827927141722],
					[64.294616552431847, 7.1398279349789933],
					[64.29461656332532, 7.1398279445791708],
					[64.294616568785145, 7.1398279504117861],
					[64.296324863212206, 7.1413168464340213],
					[64.298102638724146, 7.143122321247569],
					[64.298897025792343, 7.1445194999295705],
					[64.298897029432112, 7.1445195038191942],
					[64.298897034944133, 7.1445195137844335],
					[64.298897038583931, 7.144519517674075],
					[64.300469224007344, 7.146663998937127],
					[64.301566515191382, 7.1493333768857923],
					[64.301566515191382, 7.1493333768857923],
					[64.301566516114363, 7.1493333788912485],
					[64.301566516114363, 7.1493333788912485],
					[64.302580407432899, 7.1516833569472862],
					[64.303360902881423, 7.1563167323400956],
					[64.303360903830338, 7.1563167364116751],
					[64.303360904831223, 7.1563167446147178],
					[64.303360905780124, 7.1563167486862964],
					[64.303885906430935, 7.1583778487800309],
					[64.303885910174273, 7.1583778609353272],
					[64.303885919428723, 7.1583778830604299],
					[64.303885924068922, 7.1583778951558745],
					[64.30548042429534, 7.1613333941164443],
					[64.30548042706377, 7.1613334001348727],
					[64.305480435291187, 7.1613334119924827],
					[64.305480438956465, 7.1613334179511501],
					[64.307052639377375, 7.1634807182697466],
					[64.307052643939429, 7.1634807241693421],
					[64.307052654883165, 7.1634807379152017],
					[64.307052661238927, 7.1634807436954029],
					[64.307983160585366, 7.1643807450000061],
					[64.307983216553453, 7.1643807702258853],
					[64.307983334353267, 7.1643807872023917],
					[64.307983395262241, 7.1643807769465928],
					[64.308677894631884, 7.1640057757246804],
					[64.308677920433638, 7.1640057574646692],
					[64.308677962938972, 7.1640057112107067],
					[64.308677980565335, 7.1640056852232386],
					[64.309150181139501, 7.1629972852538168],
					[64.309150188926125, 7.1629972619883295],
					[64.309150198195397, 7.1629972138091951],
					[64.309150199678072, 7.1629971888955497],
					[64.308944599548596, 7.1592916881337283],
					[64.308944598625772, 7.1592916861273839],
					[64.308944599444686, 7.1592916798690629],
					[64.308944599418709, 7.1592916778029023],
					[64.308525199087242, 7.1555999765170419],
					[64.308525198112292, 7.1555999703786206],
					[64.308525196162435, 7.1555999581017584],
					[64.308525194290652, 7.155599952023274],
					[64.307625195064574, 7.1519471544130608],
					[64.306708496213105, 7.1476471583006447],
					[64.306708495290096, 7.1476471562948554],
					[64.306708494314847, 7.1476471501571224],
					[64.306708493391838, 7.1476471481513535],
					[64.305808495805152, 7.1443193585391951],
					[64.305266897282536, 7.1409610670064536],
					[64.305266892614696, 7.1409610528474747],
					[64.305266883226466, 7.1409610203978113],
					[64.305266875841809, 7.1409610043542573],
					[64.303672375661151, 7.1380082057805909],
					[64.303672374738042, 7.1380082037753629],
					[64.303672372891796, 7.138008199764907],
					[64.303672371968659, 7.1380081977596808],
					[64.302383477410274, 7.1358416063479906],
					[64.301147380715292, 7.1331860154880191],
					[64.301147380715292, 7.1331860154880191],
					[64.301147379792098, 7.1331860134831357],
					[64.301147378868919, 7.1331860114782524],
					[64.299766878817721, 7.1303777119598735],
					[64.29976687422797, 7.1303777040018499],
					[64.299766863202009, 7.1303776840764277],
					[64.299766856818579, 7.1303776762399078],
					[64.29820296181974, 7.1283942810219356],
					[64.29633346595331, 7.1256220886718848],
					[64.29633345956961, 7.1256220808374513],
					[64.29633344769907, 7.1256220651076472],
					[64.296333441315383, 7.1256220572732127],
					[64.295127959807289, 7.1244192765796699],
					[64.293327972614009, 7.1213220992132786],
					[64.293327971690687, 7.1213220972093572],
					[64.293327970767308, 7.1213220952054384],
					[64.293327969870489, 7.121322095266466],
					[64.292183470303101, 7.1194665940633195],
					[64.290747388085919, 7.1171499235556244],
					[64.290444596258681, 7.1157166585739926],
					[64.290444585124433, 7.1157166304021953],
					[64.290444555734354, 7.1157165786777199],
					[64.290444538375397, 7.1157165550638162],
					[64.289025155174315, 7.1143692711636817],
					[64.287736267985707, 7.1123637922857936],
					[64.287736261574665, 7.1123637823923049],
					[64.28773624698556, 7.1123637647924722],
					[64.287736239704344, 7.1123637570248048],
					[64.285889040918136, 7.1105637584775341],
					[64.284400171341005, 7.1090581870513452],
					[64.283019585591688, 7.105605525535502],
					[64.283019578229897, 7.1056055115784531],
					[64.283019560789171, 7.1056054817847025],
					[64.283019550736896, 7.1056054680121834],
					[64.282097351203859, 7.1045442687882518],
					[64.282097307754668, 7.1045442407808945],
					[64.282097213078899, 7.1045442080250876],
					[64.282097160955516, 7.1045442033381967],
					[64.280777760909785, 7.1048054046391211],
					[64.280777735878601, 7.104805412553965],
					[64.280777687716835, 7.1048054365165596],
					[64.280777666379947, 7.1048054524412461],
					[64.279608165553611, 7.1058665516967974],
					[64.279608159355902, 7.105866558318982],
					[64.279608146090425, 7.1058665736887283],
					[64.279608138995854, 7.1058665803724068],
					[64.278716593933652, 7.1070665077865947],
					[64.278647183798327, 7.107072001210005],
					[64.278647108971413, 7.1070720455848271],
					[64.278647021786767, 7.1070721754987725],
					[64.278647010325869, 7.1070722609764445],
				],
				[
					[64.266677617895596, 7.0687806266577571],
					[64.266677628795748, 7.0687807043586472],
					[64.268966515748986, 7.0725583823015192],
					[64.271027608904006, 7.0791361594842437],
					[64.271027610778944, 7.0791361655492873],
					[64.271027616349542, 7.0791361796179162],
					[64.271027619121313, 7.0791361856206221],
					[64.272566519196417, 7.0823972859863629],
					[64.273583112615441, 7.0845722728731024],
					[64.274619209397216, 7.0878639609439444],
					[64.274619214966989, 7.0878639750161794],
					[64.274619228797008, 7.087864002974424],
					[64.27461923705728, 7.0878640168604701],
					[64.276138737440519, 7.0900112154660651],
					[64.276138748391034, 7.090011229167521],
					[64.27613877295569, 7.0900112543207703],
					[64.276138786569774, 7.0900112657725849],
					[64.278410973586645, 7.0915640560496707],
					[64.281188747723334, 7.0942057324698018],
					[64.282713736880922, 7.096355715454985],
					[64.282713741445619, 7.0963557213383126],
					[64.282713749678223, 7.0963557331668143],
					[64.282713755139781, 7.0963557389883318],
					[64.284735933384042, 7.0984779156968472],
					[64.286277609749547, 7.1017472656805571],
					[64.287055404973614, 7.1050528462428],
					[64.287055415159614, 7.1050528703394269],
					[64.287055439989047, 7.1050529161605418],
					[64.287055454632451, 7.1050529378850138],
					[64.289077654581945, 7.107177937916906],
					[64.289077664580645, 7.1071779475637591],
					[64.289077685421489, 7.1071779626667384],
					[64.289077696290349, 7.1071779701874993],
					[64.291602695584828, 7.1087195719822196],
					[64.291602744227433, 7.1087195851833105],
					[64.291602840322355, 7.1087195889340142],
					[64.291602888671449, 7.1087195794221971],
					[64.292847288769238, 7.1080973791271544],
					[64.292847324322366, 7.1080973518900858],
					[64.292847377278349, 7.1080972821258666],
					[64.292847395604767, 7.1080972416021746],
					[64.293327985402925, 7.1058389916499438],
					[64.294319394321903, 7.1046697162309282],
					[64.295585942630993, 7.1062779241050489],
					[64.295586029288202, 7.1062779615659828],
					[64.295586194305741, 7.1062779502456994],
					[64.295586272639355, 7.1062778993993829],
					[64.296577864637925, 7.104550288500934],
					[64.298088787381161, 7.1055890656002596],
					[64.298088856704652, 7.1055890815136147],
					[64.298088990225978, 7.1055890640811246],
					[64.298089052603402, 7.1055890287931218],
					[64.29908075259064, 7.104416828342309],
					[64.299080770152841, 7.1044167981953645],
					[64.299080794435071, 7.1044167324445322],
					[64.299080800284997, 7.1044166989675812],
					[64.299072400067757, 7.1033027972811738],
					[64.299072399037101, 7.1033027870160206],
					[64.299072396052253, 7.1033027644819446],
					[64.299072394098005, 7.1033027522130263],
					[64.298805798388685, 7.1021999682949994],
					[64.298530799356826, 7.0988638848830021],
					[64.298530798352687, 7.0988638766835317],
					[64.298530796344437, 7.0988638602845491],
					[64.298530795367142, 7.0988638541503883],
					[64.29774459504948, 7.0955555533715957],
					[64.297225199712571, 7.0933499764341237],
					[64.297202999652583, 7.0911166977102518],
					[64.297202991993217, 7.0911166610326912],
					[64.297202962352145, 7.0911165907351359],
					[64.297202940370468, 7.0911165571151198],
					[64.292908445979492, 7.0868804636630491],
					[64.290116864012077, 7.0836831836708045],
					[64.288589103380986, 7.0809749530352146],
					[64.288811299621727, 7.0776139135670979],
					[64.288811291850493, 7.0776138686462122],
					[64.288811259376956, 7.0776137882489651],
					[64.28881123285386, 7.0776137508329171],
					[64.287041833278437, 7.0760359502071726],
					[64.287041827843225, 7.0760359464531941],
					[64.28704181786965, 7.0760359388827929],
					[64.287041813331271, 7.076035935066332],
					[64.284019564737648, 7.0739665706424555],
					[64.282989086693206, 7.0712332281739263],
					[64.282989076556106, 7.0712332082216172],
					[64.28298905185224, 7.0712331727583608],
					[64.28298903815508, 7.0712331551206908],
					[64.27742793816688, 7.0659609549827644],
					[64.277427924578234, 7.0659609456059993],
					[64.277427897400941, 7.0659609268524708],
					[64.277427882915461, 7.0659609175384901],
					[64.274155683043276, 7.0644665176538481],
					[64.274155640729163, 7.0644665082257285],
					[64.274155558167521, 7.064466509878371],
					[64.274155516099128, 7.0644665190213063],
					[64.271408216173953, 7.0657331189089057],
					[64.271408208157183, 7.0657331236003644],
					[64.27140819122684, 7.065733133046014],
					[64.271408182313209, 7.0657331378002475],
					[64.26892209164393, 7.0675415310769676],
					[64.266677703195924, 7.0687804245416839],
					[64.266677654660882, 7.0687804878120533],
					[64.266677617895596, 7.0687806266577571],
				],
				[
					[64.236855401561542, 7.1616222294680272],
					[64.237266501749417, 7.1643194294182928],
					[64.237266503646694, 7.1643194375425914],
					[64.237266507441262, 7.1643194537912311],
					[64.237266509312661, 7.1643194598547142],
					[64.238860908878763, 7.1693833599410191],
					[64.238860908904584, 7.1693833620019785],
					[64.238860909801431, 7.1693833619426561],
					[64.240713710323774, 7.1750472612373892],
					[64.2407137112464, 7.1750472632393292],
					[64.240713712194733, 7.1750472673023555],
					[64.240713712220483, 7.1750472693634411],
					[64.241905409241582, 7.1782778605066211],
					[64.243360906379749, 7.1838806475334023],
					[64.244866505193286, 7.1903889450333276],
					[64.244866505193286, 7.1903889450333276],
					[64.246216504257532, 7.1960750415322563],
					[64.24750540217002, 7.2030444279832126],
					[64.247772000779946, 7.2060528173227132],
					[64.247772003673049, 7.2060528336414471],
					[64.247772012149895, 7.2060528661044181],
					[64.247772017733638, 7.2060528822486587],
					[64.249183118148196, 7.2091778830067694],
					[64.249183120914665, 7.2091778890178713],
					[64.249183126422281, 7.2091778989783002],
					[64.249183130085655, 7.2091779049313187],
					[64.250480429715836, 7.2112862052580784],
					[64.251983120777169, 7.2137306903584228],
					[64.253685913871792, 7.2178306733619539],
					[64.255133113050974, 7.2216389719193161],
					[64.255133114894932, 7.2216389759283972],
					[64.25513311766089, 7.2216389819420153],
					[64.25513311860793, 7.2216389860087888],
					[64.256247018802057, 7.2239806858533333],
					[64.256247021567901, 7.223980691867447],
					[64.256247027971412, 7.2239807017757443],
					[64.256247030737285, 7.2239807077898588],
					[64.25826372759451, 7.2271723032944912],
					[64.260460925638426, 7.2310389985780477],
					[64.260460928379004, 7.2310390025311637],
					[64.26046093296334, 7.2310390104947366],
					[64.260460934782088, 7.231039012442614],
					[64.262649823401844, 7.2342306943211687],
					[64.263374814841313, 7.2360250743045231],
					[64.263374846780323, 7.236025117684199],
					[64.263374928297836, 7.2360251785448488],
					[64.263374977004332, 7.2360251981458941],
					[64.264977777093478, 7.236211299239109],
					[64.264977781577997, 7.2362112989529832],
					[64.264977790571933, 7.2362113004436921],
					[64.264977795056424, 7.2362113001575672],
					[64.268036095279228, 7.2362834990840836],
					[64.268036099788631, 7.2362835008611199],
					[64.268036109654574, 7.2362835002315826],
					[64.268036115035983, 7.2362834998881986],
					[64.271063905835774, 7.2360557999220383],
					[64.272949996738987, 7.236083500766747],
					[64.272950014677022, 7.2360834996217527],
					[64.272950051400258, 7.2360834931473974],
					[64.272950069288513, 7.2360834878752707],
					[64.273619469148301, 7.2358390877238534],
					[64.273619505647559, 7.2358390626764999],
					[64.273619566836942, 7.2358390009445026],
					[64.273619589683577, 7.2358389602471389],
					[64.274283488575634, 7.2337806658109089],
					[64.274916887339856, 7.2321028704869637],
					[64.274916889083769, 7.2321028662447979],
					[64.274916891649767, 7.2321028557541247],
					[64.274916893393694, 7.2321028515119563],
					[64.275161253243382, 7.2311863027912926],
					[64.276833280407786, 7.2312196007594673],
					[64.279036064887492, 7.2316168972212687],
					[64.279036074778332, 7.2316168986537646],
					[64.279036095431991, 7.2316168993973546],
					[64.279036105322817, 7.2316169008298496],
					[64.281319404604147, 7.2315558008315746],
					[64.281319410882446, 7.2315558004296481],
					[64.28131942164525, 7.2315557997406303],
					[64.281319427898595, 7.2315557972745266],
					[64.283272228409572, 7.2312779971161874],
					[64.283272249013294, 7.2312779937309255],
					[64.283272289224001, 7.2312779787605255],
					[64.283272307934126, 7.2312779672328169],
					[64.284302907819153, 7.2306140679699276],
					[64.284302922941592, 7.230614056671155],
					[64.284302948677052, 7.2306140322964918],
					[64.284302961058913, 7.2306140170412876],
					[64.285150160689668, 7.2294557176197891],
					[64.2851501659961, 7.2294557110814095],
					[64.285150173046418, 7.2294557002991127],
					[64.285150177455989, 7.2294556938182479],
					[64.28672797478049, 7.226461196373708],
					[64.287605740702773, 7.2249223554139288],
					[64.287963816421779, 7.2247613570857139],
					[64.2884304257134, 7.2255806991880869],
					[64.288430433013744, 7.2255807090505755],
					[64.288430447639428, 7.2255807308402566],
					[64.288430456708539, 7.2255807385227815],
					[64.289077656654285, 7.2262473393614135],
					[64.289077656654285, 7.2262473393614135],
					[64.289077657576186, 7.2262473413685742],
					[64.290327657917615, 7.2275001413213289],
					[64.29032768235885, 7.2275001583505194],
					[64.29032773385704, 7.2275001860416026],
					[64.290327761810843, 7.2275001966459627],
					[64.292397161874604, 7.2279001967912357],
					[64.29239724508588, 7.2279001749176484],
					[64.292397364144847, 7.2279000742786819],
					[64.292397399992481, 7.2278999955133409],
					[64.292344601387811, 7.225097231983681],
					[64.293350163188336, 7.2221140413673366],
					[64.294944405740551, 7.2213113218266933],
					[64.296591619902529, 7.2220251847031074],
					[64.29659162979344, 7.2220251861329814],
					[64.296591649625412, 7.2220251931233665],
					[64.296591661335171, 7.2220251965030178],
					[64.299263861367805, 7.222561296800591],
					[64.299263873949386, 7.2225612980575127],
					[64.299263901803172, 7.2225613003981115],
					[64.299263916153464, 7.2225612994740107],
					[64.302216716161013, 7.2223279995002594],
					[64.302216740326926, 7.2223279938089542],
					[64.302216786814796, 7.222327978410445],
					[64.302216810008588, 7.2223279665797202],
					[64.303422310232477, 7.2215362670097898],
					[64.30342234388776, 7.221536229694399],
					[64.303422388600353, 7.2215361420478734],
					[64.303422399682759, 7.2215360937825555],
					[64.303366900395432, 7.219563893925975],
					[64.303366895609528, 7.219563869425504],
					[64.303366883372149, 7.2195638226639698],
					[64.303366874126894, 7.2195638005186344],
					[64.302216873554428, 7.217547100598563],
					[64.302216856236868, 7.2175470789761631],
					[64.3022168162707, 7.2175470402103761],
					[64.302216793622122, 7.217547023067004],
					[64.300041820027189, 7.2163942377370569],
					[64.298425144585622, 7.2147692626090496],
					[64.297958697820903, 7.2142529429685256],
					[64.299805629577904, 7.2139723984209443],
					[64.299805652821391, 7.213972390715373],
					[64.299805698386265, 7.2139723732966887],
					[64.299805718888692, 7.2139723616341289],
					[64.301186218600549, 7.2129473616429447],
					[64.301186248666085, 7.2129473245524718],
					[64.301186288989939, 7.2129472454522006],
					[64.301186300119952, 7.2129472013186859],
					[64.301200199872099, 7.2101389015921917],
					[64.301200199821579, 7.2101388974609124],
					[64.301200199745807, 7.2101388912639734],
					[64.301200199720554, 7.2101388891983316],
					[64.301077999965258, 7.2077916898692482],
					[64.301077997969216, 7.2077916734606839],
					[64.301077991311672, 7.2077916428838904],
					[64.301077985728071, 7.2077916267082891],
					[64.299666886127241, 7.2041888270908823],
					[64.29966688517969, 7.2041888230181979],
					[64.299666882413007, 7.2041888169966875],
					[64.299666879671676, 7.2041888130407123],
					[64.298041885170022, 7.2008249236130402],
					[64.296622389159481, 7.1968471342481957],
					[64.295211288939214, 7.1927193350360445],
					[64.295211288016844, 7.1927193330295847],
					[64.295211287068966, 7.1927193289579545],
					[64.295211286146625, 7.1927193269514973],
					[64.293327987444613, 7.1879249305736943],
					[64.292161288617024, 7.1846277326098171],
					[64.292161284004564, 7.1846277225799726],
					[64.292161273882826, 7.1846277025792498],
					[64.292161269270423, 7.1846276925494026],
					[64.290833470807399, 7.1825470978643491],
					[64.288702974572786, 7.1787943028761383],
					[64.286727983142683, 7.1751471173419699],
					[64.285530801028756, 7.1719277654156803],
					[64.285583499315706, 7.169655636752597],
					[64.28676688311738, 7.1663583823436667],
					[64.288169558741615, 7.1638112266247553],
					[64.289580733415789, 7.1625501487003618],
					[64.28958075813911, 7.1625501160585197],
					[64.289580791312645, 7.162550041525491],
					[64.289580799788723, 7.1625500016989987],
					[64.289605800079428, 7.1603500012610422],
					[64.289605798078071, 7.1603499848627781],
					[64.289605792203758, 7.1603499459916264],
					[64.289605786614942, 7.160349929832365],
					[64.288155787013622, 7.1565388290958669],
					[64.288155782373309, 7.1565388170075819],
					[64.288155770428034, 7.1565387950752326],
					[64.288155763123072, 7.1565387852311675],
					[64.286430762786907, 7.1540970841278719],
					[64.286430750945442, 7.1540970704558475],
					[64.286430722778192, 7.1540970434115279],
					[64.28643070734924, 7.154097029979269],
					[64.284247392739061, 7.1527054855392551],
					[64.284058499584461, 7.1499860931870964],
					[64.284102999543549, 7.1466500023699266],
					[64.284103000440425, 7.1466500023097446],
					[64.284103000414291, 7.1466500002454563],
					[64.284103000388143, 7.1466499981811475],
					[64.284080802227635, 7.1437002687118696],
					[64.284758133440278, 7.1439001597696929],
					[64.285560906202733, 7.1472028474264366],
					[64.285560906228881, 7.1472028494908342],
					[64.285560908101004, 7.1472028555636946],
					[64.285560908127152, 7.1472028576281135],
					[64.286544208352112, 7.1505000567262913],
					[64.286544214864961, 7.150500074886172],
					[64.286544231425893, 7.1505001068367093],
					[64.286544240577115, 7.1505001206874317],
					[64.288055441060095, 7.1524918211776454],
					[64.288055462871469, 7.1524918424481285],
					[64.288055511771006, 7.1524918763707044],
					[64.288055538885274, 7.1524918910874051],
					[64.28976383890911, 7.1530362918393218],
					[64.289763862279557, 7.1530362944090422],
					[64.289763907226771, 7.1530362996684804],
					[64.28976392875137, 7.1530362982287414],
					[64.291113929046844, 7.1528362989926162],
					[64.291113960332709, 7.152836288633341],
					[64.291114018289733, 7.1528362578906917],
					[64.291114044090151, 7.1528362396321148],
					[64.292244543664381, 7.1516612385467413],
					[64.292244560443905, 7.1516612167569225],
					[64.292244585852998, 7.1516611675228798],
					[64.292244596302353, 7.151661142023519],
					[64.292655795568493, 7.1497500420814246],
					[64.292655791250127, 7.1497499845036536],
					[64.292655750457072, 7.1497498818365228],
					[64.292655713982398, 7.1497498367471595],
					[64.291619531875497, 7.1490304483323879],
					[64.289575154275582, 7.146830471147628],
					[64.287316867211544, 7.1438054907070594],
					[64.284827981701895, 7.1395055158816598],
					[64.283050188397922, 7.1346499308260958],
					[64.283050184731579, 7.1346499248757587],
					[64.283050179166281, 7.1346499107897747],
					[64.283050174603062, 7.1346499049000069],
					[64.281180777961609, 7.1312388084782068],
					[64.279052982667778, 7.126780519788511],
					[64.276877984990804, 7.1215360240351933],
					[64.275252986313305, 7.1174832252113616],
					[64.275252983569658, 7.117483221267487],
					[64.275252980799479, 7.1174832152599965],
					[64.275252978952679, 7.1174832112550179],
					[64.273125178992927, 7.1131832117614584],
					[64.270814078915365, 7.1085138108811954],
					[64.270814068809997, 7.108513792987103],
					[64.27081404419512, 7.1085137636956093],
					[64.270814029632248, 7.1085137481717018],
					[64.267708430185749, 7.1058442485337796],
					[64.267708407551055, 7.1058442335661303],
					[64.267708359671317, 7.1058442100043031],
					[64.26770833445299, 7.1058442034731213],
					[64.265961133909059, 7.1055387026167214],
					[64.265961108770853, 7.1055387022748855],
					[64.26596106034161, 7.1055387055940509],
					[64.265961036180443, 7.1055387113794053],
					[64.263938836423947, 7.1062276115265854],
					[64.263938813212988, 7.1062276213749396],
					[64.263938772278976, 7.106227648953964],
					[64.263938752735498, 7.1062276647447939],
					[64.262497052766349, 7.1077998660316792],
					[64.262497036020008, 7.1077998898869303],
					[64.262497009782066, 7.1077999432942605],
					[64.262497002110848, 7.1077999747862215],
					[64.262091502609422, 7.1108360660051773],
					[64.260994211007059, 7.1158666277526104],
					[64.260147026597309, 7.117333200330771],
					[64.260147018711592, 7.1173332153176636],
					[64.260147006580624, 7.1173332491721304],
					[64.260147003205688, 7.1173332659161508],
					[64.259772003404038, 7.1195388658306893],
					[64.259772001742974, 7.1195388762649037],
					[64.259772000187994, 7.119538894948902],
					[64.259772000294092, 7.1195389031986647],
					[64.259833099998716, 7.1224389044768674],
					[64.259933100104746, 7.1269639002137755],
					[64.25986370628037, 7.1322523530232314],
					[64.258827934674471, 7.1313109530442684],
					[64.258827861634103, 7.1313109270179229],
					[64.258827720775614, 7.131310932409062],
					[64.258827651190174, 7.1313109660101484],
					[64.258055451357336, 7.1321692669711556],
					[64.258055432865945, 7.1321692950536555],
					[64.258055406777089, 7.1321693608033625],
					[64.25805540002375, 7.1321693942853166],
					[64.257994199868264, 7.1341360943468572],
					[64.257994199999843, 7.1341361046585048],
					[64.25799420208304, 7.1341361272230914],
					[64.257994204034645, 7.1341361394760305],
					[64.258399800841744, 7.136158323990772],
					[64.258566499868763, 7.1393222049730198],
					[64.258530399619914, 7.1446082981204109],
					[64.258530399646077, 7.1446083001827896],
					[64.258530399724592, 7.1446083063699231],
					[64.258530399750768, 7.1446083084323195],
					[64.258705399106049, 7.1488277821369621],
					[64.257941512114598, 7.1521777232519073],
					[64.256577644331912, 7.1544470716973532],
					[64.254983220745174, 7.1558637032902066],
					[64.25309731018659, 7.1559303960838614],
					[64.251519571206629, 7.1533442952255468],
					[64.251519565747301, 7.1533442893992119],
					[64.251519556622398, 7.1533442776267497],
					[64.251519552956822, 7.1533442716806617],
					[64.250839052828169, 7.1525415700229322],
					[64.250838951884376, 7.1525415375573234],
					[64.250838778283651, 7.1525415801054137],
					[64.250838704755949, 7.1525416572408069],
					[64.250252609840402, 7.1551999356730391],
					[64.249060925579627, 7.1577304988740282],
					[64.248252696060433, 7.1588886989813956],
					[64.246330601306411, 7.1588831001424467],
					[64.246330561025289, 7.1588831090126845],
					[64.246330488690631, 7.158883138584919],
					[64.246330455766142, 7.1588831614081068],
					[64.245055475973317, 7.1602081407836371],
					[64.242683201170721, 7.16157202564602],
					[64.240988829760994, 7.1625331105666969],
					[64.238572252957752, 7.1629941861619875],
					[64.236855725051342, 7.1616220429812953],
					[64.236855611838308, 7.161622034000799],
					[64.236855450541867, 7.1616221272143692],
					[64.236855401561542, 7.1616222294680272],
				],
			],
		},
		{
			fldNpdidField: "38702206",
			wlbNpdidWellbore: "1986",
			fldName: "BREIDABLIKK",
			fldCurrentActivitySatus: "Approved for production",
			wlbName: "25/8-4",
			fldDiscoveryYear: "1992",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=38702206",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=38702206",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "BREIDABLIKK UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=1986",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "39099356",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-02-17 00:00:00",
			fldMainSupplyBase: "Dusavik",
			fldAreaGeometryWKT: [
				[
					[59.23908589961723, 2.5962528028344001],
					[59.23912759982769, 2.6000000009304638],
					[59.239127600005965, 2.6034443996534975],
					[59.239127600177909, 2.6034444031369688],
					[59.239127600435801, 2.6034444083621966],
					[59.239127599629363, 2.6034444102717238],
					[59.2395137003952, 2.6103806113486758],
					[59.2395137003952, 2.6103806113486758],
					[59.239513699588663, 2.6103806132580654],
					[59.239513699588663, 2.6103806132580654],
					[59.239769199573438, 2.6141500137213218],
					[59.240027600050347, 2.617875014398459],
					[59.240027601114335, 2.6178750177147161],
					[59.240027601543211, 2.617875026423889],
					[59.240027602692969, 2.6178750314819612],
					[59.240583102824644, 2.6213000321313396],
					[59.240583108315562, 2.6213000521971574],
					[59.240583123759613, 2.6213000914921265],
					[59.240583133712711, 2.6213001107212719],
					[59.241269234236626, 2.622327910550005],
					[59.241269245803004, 2.6223279259613763],
					[59.241269274204647, 2.6223279540383677],
					[59.241269289255058, 2.6223279670385948],
					[59.242297070050292, 2.6230112535769465],
					[59.243538754317726, 2.6243390376064371],
					[59.243538756188286, 2.6243390390139232],
					[59.243538760907555, 2.6243390434036593],
					[59.243538762863814, 2.6243390465531773],
					[59.244266563196774, 2.6250251459707652],
					[59.24426660302823, 2.6250251683937957],
					[59.244266689866215, 2.6250251943137823],
					[59.24426673589462, 2.6250251962359079],
					[59.245208336162698, 2.6248529962978422],
					[59.245208366248505, 2.6248529853842211],
					[59.245208422421868, 2.6248529555154794],
					[59.245208446638905, 2.6248529351527785],
					[59.246150147181829, 2.6238251351027611],
					[59.246150154064196, 2.6238251285378147],
					[59.246150164980214, 2.6238251124255272],
					[59.246150170884455, 2.6238251042857201],
					[59.246964059322686, 2.6224973231414865],
					[59.247861244410338, 2.6215557385946129],
					[59.248930742838809, 2.6204612393726245],
					[59.248930745430364, 2.6204612371280929],
					[59.248930749720998, 2.6204612328064356],
					[59.248930752312525, 2.6204612305619079],
					[59.249275152008067, 2.6200584302935011],
					[59.249275152814732, 2.62005842838377],
					[59.250000095349129, 2.6192001972654348],
					[59.251286032248586, 2.6191585010977909],
					[59.251972035636477, 2.6197140238708809],
					[59.252485910022131, 2.6209139624069575],
					[59.252785896603491, 2.6221556108775066],
					[59.252613703833362, 2.6234388604204728],
					[59.252358109884781, 2.624380538309051],
					[59.251930422376901, 2.6254943068870529],
					[59.251116533892464, 2.6266942882940687],
					[59.251116530579715, 2.6266942941904023],
					[59.251116523061775, 2.626694306150362],
					[59.251116519834682, 2.6266943137891201],
					[59.250644222974877, 2.6276777083296579],
					[59.249488726328835, 2.6297332025345681],
					[59.249488722930309, 2.629733206687968],
					[59.249488718810575, 2.629733214493267],
					[59.249488717282574, 2.6297332200546744],
					[59.248888717303252, 2.6311027187845424],
					[59.248119217629679, 2.6328166174195817],
					[59.248119215209073, 2.6328166231477161],
					[59.248119211260338, 2.6328166344369115],
					[59.248119208839718, 2.632816640165065],
					[59.247560909102511, 2.6346138414643336],
					[59.247560906681827, 2.6346138471922464],
					[59.247560903796483, 2.6346138617985861],
					[59.247560903160711, 2.6346138671924324],
					[59.247305403296401, 2.6361555669333607],
					[59.247305401682567, 2.6361555707518871],
					[59.247305401046731, 2.636155576145669],
					[59.247305401217794, 2.636155579630215],
					[59.247177603736155, 2.6374416486385299],
					[59.246363715802673, 2.6393249205681339],
					[59.246363715081209, 2.6393249242195069],
					[59.246363711853348, 2.6393249318560192],
					[59.246363711131885, 2.6393249355073891],
					[59.245849810735322, 2.6408221347439151],
					[59.245849807763797, 2.640822147606809],
					[59.245849802627717, 2.64082217142353],
					[59.245849801355654, 2.6408221822104982],
					[59.245677601288044, 2.6427916831347718],
					[59.245677601458915, 2.6427916866191827],
					[59.245677599930339, 2.6427916921794044],
					[59.245677600101239, 2.6427916956638291],
					[59.245635899537483, 2.6447193854955859],
					[59.245463701092937, 2.6461332767630559],
					[59.245463700712904, 2.6461332873830146],
					[59.245463700845264, 2.6461333084562315],
					[59.245463701443093, 2.6461333206516771],
					[59.245591500874511, 2.6473750193329182],
					[59.245591503257167, 2.6473750311950668],
					[59.245591508022507, 2.6473750549193782],
					[59.245591511297647, 2.6473750666148423],
					[59.246105411322887, 2.648830667267466],
					[59.24610542224994, 2.6488306880835317],
					[59.246105446696092, 2.6488307274734839],
					[59.246105462000166, 2.6488307457140485],
					[59.247133162127568, 2.6498140451844527],
					[59.247133167738525, 2.6498140494115621],
					[59.247133180916087, 2.6498140610171577],
					[59.24713318839737, 2.6498140666533163],
					[59.248460974262734, 2.6507140552395017],
					[59.249360937890977, 2.6515695227584657],
					[59.24999982187034, 2.6528111913664754],
					[59.24999983636674, 2.6528112115198899],
					[59.249999866973887, 2.6528112480086152],
					[59.249999884869595, 2.6528112640108024],
					[59.250858147977013, 2.6534112387124504],
					[59.251408125936287, 2.654386198441713],
					[59.251408142302758, 2.6543862200060584],
					[59.251408181112495, 2.6543862584838553],
					[59.251408202663285, 2.6543862755638803],
					[59.251886002701617, 2.6546529750548951],
					[59.25188601927816, 2.6546529825126042],
					[59.251886054045642, 2.6546529936098091],
					[59.251886072236573, 2.6546529972493209],
					[59.252486072169873, 2.6547390983395061],
					[59.252486079395062, 2.6547390987497863],
					[59.252486092952971, 2.6547390997369011],
					[59.252486100178146, 2.6547391001471823],
					[59.253555600260313, 2.6547390991785016],
					[59.25355561560319, 2.6547390998325096],
					[59.253555647732732, 2.6547390938368496],
					[59.253555662819736, 2.6547390892629203],
					[59.254841762894166, 2.654311290449062],
					[59.254841776110887, 2.6543112844651491],
					[59.254841799781502, 2.6543112712543073],
					[59.254841812105717, 2.6543112654369647],
					[59.256169512199307, 2.6534112663162319],
					[59.256169520868198, 2.6534112594219286],
					[59.256169537313525, 2.6534112457998931],
					[59.256169545004639, 2.6534112373293928],
					[59.256980745025743, 2.6525557379590552],
					[59.256980746725382, 2.652555735882971],
					[59.256980751824308, 2.6525557296547748],
					[59.256980754416418, 2.6525557274120852],
					[59.258008454288451, 2.6513140282080068],
					[59.258008462615855, 2.6513140143413039],
					[59.258008479355951, 2.6513139883507524],
					[59.25800848589838, 2.6513139748173851],
					[59.258522385693411, 2.6500278737017138],
					[59.258522387393008, 2.6500278716254235],
					[59.258522388836496, 2.6500278643204722],
					[59.258522389643616, 2.6500278624108855],
					[59.259208487256458, 2.6479306702526237],
					[59.259722373907849, 2.646730701348365],
					[59.260491859641689, 2.6457029207901384],
					[59.260491864654831, 2.6457029128172702],
					[59.260491874681094, 2.6457028968715592],
					[59.260491878801759, 2.645702889065523],
					[59.260919577974747, 2.6448472903227205],
					[59.261733421893986, 2.6432641001420967],
					[59.262461051025127, 2.6432641008506814],
					[59.263533160532397, 2.643819552369655],
					[59.264347033358703, 2.6450612102335898],
					[59.264347034336623, 2.6450612118098822],
					[59.264347034336623, 2.6450612118098822],
					[59.265244234400086, 2.6463890122246578],
					[59.265244248812159, 2.6463890306406439],
					[59.265244282734898, 2.646389061241917],
					[59.265244302245556, 2.6463890734271898],
					[59.265930502245133, 2.6467751737607985],
					[59.265930534335773, 2.6467751853540618],
					[59.265930598811643, 2.6467751961710082],
					[59.265930633067249, 2.6467751968042519],
					[59.267213933189666, 2.6465612971884092],
					[59.26721397640825, 2.6465612785528361],
					[59.267214049411265, 2.6465612244410792],
					[59.267214080088138, 2.6465611887980516],
					[59.268369579569949, 2.6442056878435358],
					[59.26836958271258, 2.6442056784590053],
					[59.268369589890277, 2.6442056595230277],
					[59.268369593032908, 2.6442056501384958],
					[59.26905578984487, 2.641594464778692],
					[59.270169582065357, 2.6388972828877528],
					[59.271111275262214, 2.6370111983551592],
					[59.273166870381218, 2.6337168064453809],
					[59.273166875307936, 2.6337167967241291],
					[59.273166884268932, 2.6337167774488459],
					[59.273166889195636, 2.6337167677275954],
					[59.27462238791972, 2.6296056692171477],
					[59.275264086903761, 2.6279361718135834],
					[59.275264086903761, 2.6279361718135834],
					[59.275264087710525, 2.6279361699025272],
					[59.275264087624841, 2.6279361681588651],
					[59.276119588376773, 2.6255806670737423],
					[59.276119589183502, 2.6255806651625662],
					[59.276119589904525, 2.6255806615077097],
					[59.276119590711254, 2.6255806595965527],
					[59.277147390850544, 2.6222417596376473],
					[59.277147393184819, 2.6222417521600128],
					[59.277147396240018, 2.6222417410273402],
					[59.277147396789445, 2.6222417338848065],
					[59.277491896791275, 2.6202722339246933],
					[59.27749189840457, 2.6202722301019761],
					[59.277491898782316, 2.6202722194718433],
					[59.277491898524985, 2.620272214240571],
					[59.277705798703195, 2.6174028151427544],
					[59.277705800402217, 2.6174028130636358],
					[59.277705800230599, 2.6174028095761255],
					[59.277705800144773, 2.6174028078323754],
					[59.277747399339283, 2.616416717879857],
					[59.277919595920459, 2.6152194445546297],
					[59.278389087418006, 2.6137639731635467],
					[59.278947380710761, 2.6125639835243186],
					[59.278947382409704, 2.6125639814448838],
					[59.278947384022764, 2.6125639776216301],
					[59.278947384829287, 2.612563975710013],
					[59.279416884702485, 2.6114083758604938],
					[59.279416887122039, 2.6114083701254645],
					[59.279416890348074, 2.6114083624787798],
					[59.279416891068685, 2.6114083588232719],
					[59.279802991274479, 2.6101667588775674],
					[59.279802992715638, 2.6101667515664353],
					[59.279802995683859, 2.6101667386880263],
					[59.279802998017416, 2.6101667312090071],
					[59.280016898439662, 2.60879723111174],
					[59.280016898730544, 2.6087972187369046],
					[59.280016899226347, 2.6087971922433804],
					[59.280016898538875, 2.6087971782926278],
					[59.279844599364765, 2.6071277797325592],
					[59.279844595913971, 2.6071277645419895],
					[59.279844588033981, 2.6071277325849689],
					[59.279844581905984, 2.6071277178982806],
					[59.279030782083204, 2.6053277184975712],
					[59.279030773191835, 2.6053277025718069],
					[59.279030752731884, 2.6053276712243032],
					[59.279030741335283, 2.6053276592901367],
					[59.277919541452277, 2.6042165596616185],
					[59.277919526399998, 2.6042165466565783],
					[59.277919493790243, 2.604216524638042],
					[59.277919477125138, 2.6042165154565184],
					[59.276377876852706, 2.6035720158055606],
					[59.276377852156031, 2.6035720081370415],
					[59.276377801407889, 2.6035720018542792],
					[59.276377775270404, 2.6035720014963819],
					[59.274663874678012, 2.6037859018647942],
					[59.274663860399684, 2.6037859045530913],
					[59.274663830230217, 2.6037859137528447],
					[59.27466381514548, 2.6037859183527119],
					[59.273380514716244, 2.6043859185774827],
					[59.273380488632249, 2.6043859375652456],
					[59.273380443861335, 2.6043859794272675],
					[59.273380424368064, 2.6043860042130191],
					[59.272566524057609, 2.6058860041426057],
					[59.272566519219005, 2.6058860156110257],
					[59.272566510348234, 2.6058860366364676],
					[59.272566507380397, 2.6058860495124421],
					[59.272010906958691, 2.6079832485163545],
					[59.272010906152225, 2.6079832504276732],
					[59.272010905431678, 2.607983254082447],
					[59.272010904539265, 2.6079832542503367],
					[59.27153871079198, 2.610038827931104],
					[59.270897077668316, 2.6111942072154619],
					[59.270297266111712, 2.6112358945813994],
					[59.269527941582666, 2.6105942578014076],
					[59.268669572002807, 2.6095665927162868],
					[59.267855803635136, 2.6077277670436039],
					[59.268072399122246, 2.605541721491325],
					[59.268327996662215, 2.6033583372480655],
					[59.26956959212982, 2.5987361576577555],
					[59.269783490228264, 2.5980917628862148],
					[59.269783490142203, 2.5980917611429306],
					[59.269783491034573, 2.5980917609747975],
					[59.269783490948505, 2.5980917592315151],
					[59.271066890845624, 2.5940250594834935],
					[59.271066890845624, 2.5940250594834935],
					[59.271066891565752, 2.5940250558285749],
					[59.271066892371998, 2.5940250539169916],
					[59.272652990746693, 2.5884167583047426],
					[59.273422376019226, 2.5861473052361967],
					[59.274022253633248, 2.5855058358581964],
					[59.274577652237106, 2.585719556482486],
					[59.275008109865666, 2.5866611654148257],
					[59.275305405333526, 2.5879444447472832],
					[59.275305406312107, 2.5879444463223775],
					[59.275305406570723, 2.5879444515529824],
					[59.27530540665694, 2.5879444532965117],
					[59.275819207305815, 2.5897861536010574],
					[59.275819211392431, 2.5897861633888732],
					[59.275819218759423, 2.5897861848764885],
					[59.275819222846017, 2.5897861946643084],
					[59.276547023209908, 2.5911556942862202],
					[59.276547027037893, 2.5911556988436026],
					[59.276547032995289, 2.5911557100386804],
					[59.276547036823288, 2.5911557145960638],
					[59.27706093710659, 2.5918834149447978],
					[59.277060989405847, 2.5918834525955767],
					[59.277061107365398, 2.5918834884189463],
					[59.277061172133322, 2.5918834867598521],
					[59.277833371812662, 2.5915862866407751],
					[59.277833390207846, 2.5915862761305406],
					[59.277833425213515, 2.5915862554467957],
					[59.277833440673135, 2.5915862402105971],
					[59.278733440894243, 2.5906862417550385],
					[59.278733451891867, 2.5906862273599538],
					[59.27873347218852, 2.59068620065031],
					[59.278733481315214, 2.5906861848483067],
					[59.279461273804493, 2.5891445007092462],
					[59.280402964616293, 2.5877751129960966],
					[59.280402968819487, 2.5877751069223462],
					[59.280402976333498, 2.5877750949433103],
					[59.280402978751951, 2.5877750892064961],
					[59.28130017940201, 2.5859750885494877],
					[59.281300184325012, 2.5859750788191458],
					[59.281300191321385, 2.585975056376403],
					[59.281300195265764, 2.5859750450707613],
					[59.281727994740486, 2.5841778454507218],
					[59.281727996093871, 2.5841778363943426],
					[59.281727998628149, 2.5841778147938297],
					[59.281727999981541, 2.5841778057374323],
					[59.281772399648396, 2.5822500057307289],
					[59.281772400023016, 2.5822499950990361],
					[59.281772398181459, 2.5822499760853845],
					[59.28177239685764, 2.5822499675347932],
					[59.281386297719557, 2.5799388712658904],
					[59.281130799225558, 2.5778416767638057],
					[59.28113079692276, 2.5778416666385122],
					[59.281130793382204, 2.5778416497067731],
					[59.28113079107942, 2.5778416395814787],
					[59.280486291261433, 2.575786039499897],
					[59.280486288152517, 2.5757860312874845],
					[59.280486282021045, 2.575786016606433],
					[59.280486278019779, 2.5757860085628095],
					[59.279502985064667, 2.5738582219240471],
					[59.278902990339475, 2.5720610362619443],
					[59.278902988295521, 2.5720610313687047],
					[59.278902985186392, 2.5720610231570205],
					[59.278902983228846, 2.5720610200074581],
					[59.27813348324289, 2.5703055205831546],
					[59.278133482177658, 2.570305517264845],
					[59.27813347915486, 2.5703055107971253],
					[59.278133477197308, 2.5703055076477295],
					[59.277147376687246, 2.568422106889356],
					[59.277147368856795, 2.5684220942925489],
					[59.277147351411237, 2.5684220694368727],
					[59.277147340990261, 2.5684220590905227],
					[59.276077940916075, 2.5673526588707807],
					[59.276077936194945, 2.567352654485175],
					[59.276077924881569, 2.5673526443084422],
					[59.276077919181652, 2.5673526383483165],
					[59.27492231901816, 2.5664942395934864],
					[59.274922291296797, 2.5664942254855223],
					[59.274922230846045, 2.566494205257341],
					[59.27492219998782, 2.5664942005425662],
					[59.274194399850472, 2.5664941997287865],
					[59.274194392625333, 2.5664941993373143],
					[59.274194378261541, 2.5664942002977718],
					[59.274194371122903, 2.5664942016496974],
					[59.273336071439275, 2.5666220021710497],
					[59.273336051894489, 2.5666220076320068],
					[59.273336017353053, 2.5666220194523821],
					[59.273335998787104, 2.5666220264877144],
					[59.272524912850955, 2.5670942184769059],
					[59.271411062751575, 2.5675219996443182],
					[59.270597295713607, 2.5675219992608254],
					[59.270083469900506, 2.566880492330351],
					[59.269483486325491, 2.5656388248820878],
					[59.269014093658143, 2.564224950249427],
					[59.268455799710281, 2.561099979642135],
					[59.268369600328782, 2.557588906294864],
					[59.268586299310066, 2.5552333175486903],
					[59.268586233893522, 2.5552332155882702],
					[59.268586052277286, 2.5552331497439438],
					[59.268585935271915, 2.555233187772362],
					[59.267172034680272, 2.5572887873276477],
					[59.267172032982252, 2.557288789409025],
					[59.267172028867122, 2.5572887972270015],
					[59.267172027169103, 2.5572887993083797],
					[59.26605812738979, 2.5592165994748539],
					[59.266058125691742, 2.5592166015560656],
					[59.266058123187946, 2.5592166055493641],
					[59.266058121576499, 2.559216609373546],
					[59.265288722417559, 2.5607138086858816],
					[59.265288720806041, 2.5607138125098983],
					[59.265288716604132, 2.5607138185840825],
					[59.265288715884928, 2.5607138222390322],
					[59.264302615715685, 2.5630277220158075],
					[59.264302615802222, 2.563027723758688],
					[59.264302614996431, 2.5630277256705836],
					[59.264302614104096, 2.5630277258396008],
					[59.263619227735077, 2.5647387903128962],
					[59.262205484698043, 2.566238729352373],
					[59.259808244267127, 2.5671804073845523],
					[59.258266680844045, 2.5674804003526899],
					[59.256466729413894, 2.5674804001229918],
					[59.254713992843897, 2.5669665188418009],
					[59.253300141934275, 2.5658526566642803],
					[59.252058463087295, 2.5643109821871963],
					[59.251072369872787, 2.5627692947373757],
					[59.250000170723887, 2.5610137961231514],
					[59.250000150686859, 2.5610137735393366],
					[59.250000107303165, 2.5610137342740447],
					[59.250000082171859, 2.5610137179305124],
					[59.249147282148215, 2.5606304179012453],
					[59.249147241214679, 2.5606304098262447],
					[59.249147160126569, 2.5606304093551966],
					[59.249147119079666, 2.5606304171281082],
					[59.248374919076525, 2.5609720167741532],
					[59.248374903360848, 2.5609720267837415],
					[59.24837487282187, 2.5609720466339487],
					[59.248374858085079, 2.5609720582166369],
					[59.247691573317404, 2.5616581450171414],
					[59.246791658411638, 2.5622553884177148],
					[59.245936213744699, 2.5620859196711967],
					[59.245294578911178, 2.561227705157044],
					[59.244822389044927, 2.5598582343968124],
					[59.244822373209708, 2.5598582057464538],
					[59.244822332875721, 2.5598581553610447],
					[59.244822309182723, 2.5598581317151483],
					[59.243336209102019, 2.558891531442657],
					[59.243336172370313, 2.5588915173003621],
					[59.243336096922519, 2.5588915034571156],
					[59.243336057400654, 2.5588915056669506],
					[59.242183256987147, 2.5591442056654938],
					[59.242183221640509, 2.5591442193912104],
					[59.242183160649574, 2.5591442608286377],
					[59.242183134112935, 2.5591442887093145],
					[59.241183134270841, 2.560630487536284],
					[59.24118312603995, 2.5606305031591035],
					[59.241183112255122, 2.5606305338979536],
					[59.241183105808901, 2.5606305491829042],
					[59.240669206822808, 2.5625999484490647],
					[59.240197007058114, 2.564397148287139],
					[59.239855406628692, 2.5656388477485894],
					[59.239855405909296, 2.5656388514006623],
					[59.239855403664677, 2.5656388606152474],
					[59.23985540303179, 2.56563886600896],
					[59.239555403338265, 2.567394364471975],
					[59.239085904448899, 2.5697916620975723],
					[59.239085902376821, 2.5697916747949452],
					[59.239085901082525, 2.569791703166886],
					[59.239085900881541, 2.5697917172685369],
					[59.239255400724275, 2.5718056178849551],
					[59.239255404351638, 2.5718056365371105],
					[59.239255416154485, 2.5718056747399638],
					[59.239255423437612, 2.5718056944592993],
					[59.239983123001693, 2.5731750935292079],
					[59.239983132788922, 2.573175109260132],
					[59.239983155846389, 2.5731751383059307],
					[59.239983169116641, 2.5731751516208252],
					[59.240624869302579, 2.5737307517942813],
					[59.240624871173665, 2.5737307531988485],
					[59.240624874915788, 2.5737307560079659],
					[59.24062487678686, 2.5737307574125228],
					[59.241666577342478, 2.574544557592358],
					[59.24166658206331, 2.5745445619748586],
					[59.241666593203284, 2.5745445686609583],
					[59.241666598816479, 2.5745445728748777],
					[59.242980428968949, 2.5753140328260331],
					[59.243366503633204, 2.5766861414784752],
					[59.243580401681562, 2.5780972295723679],
					[59.243580406027803, 2.5780972445753574],
					[59.24358041472027, 2.5780972745813195],
					[59.24358042085121, 2.5780972892473475],
					[59.244180417257624, 2.5792972814353265],
					[59.244822014443585, 2.5809250737794494],
					[59.244822019595603, 2.5809250868729756],
					[59.244822034275145, 2.5809251104760071],
					[59.244822042017951, 2.5809251213223363],
					[59.245549842472393, 2.5818668225894488],
					[59.24554984532201, 2.5818668255682504],
					[59.245549850215141, 2.5818668334362145],
					[59.245549853064752, 2.5818668364150161],
					[59.247777612811319, 2.5842639913851886],
					[59.248074792364243, 2.5860610692533266],
					[59.247560944378655, 2.5870887647824801],
					[59.24627768861761, 2.587988733799107],
					[59.244994312890263, 2.5888026196085896],
					[59.243752782173893, 2.5892303949934314],
					[59.242380630903519, 2.5890165025778034],
					[59.24238059673479, 2.5890165037434434],
					[59.242380530612905, 2.5890165144476049],
					[59.242380499724455, 2.5890165273016472],
					[59.241055499971822, 2.5897859268737666],
					[59.241055475674536, 2.589785945516577],
					[59.241055436434472, 2.5897859898295641],
					[59.241055418728408, 2.5897860142624207],
					[59.240333124162568, 2.5913221042811965],
					[59.23938313111551, 2.592827693529522],
					[59.239383121354145, 2.5928277147052383],
					[59.239383106465574, 2.5928277596997358],
					[59.239383101338355, 2.5928277835185396],
					[59.239085901347671, 2.5962527831716304],
					[59.239085899821092, 2.59625278873273],
					[59.239085900251425, 2.5962527974412954],
					[59.23908589961723, 2.5962528028344001],
				],
				[
					[59.214063700939221, 2.5311056010892283],
					[59.214063700742251, 2.5311056151804503],
					[59.214222001018015, 2.5332361155326191],
					[59.214419200711419, 2.5359944137808297],
					[59.214419200971975, 2.5359944190015686],
					[59.214419202385358, 2.5359944292736931],
					[59.214419202645907, 2.5359944344944321],
					[59.214852598611913, 2.5385167098063639],
					[59.214694201219118, 2.5403666745479008],
					[59.214260903351388, 2.543008268147716],
					[59.214260901368554, 2.5430082825774543],
					[59.214260900972043, 2.54300831076011],
					[59.214260901752851, 2.5430083264224845],
					[59.214616501963498, 2.5457667257087313],
					[59.214616504355135, 2.5457667375525133],
					[59.214616510030737, 2.5457667610709547],
					[59.214616513314667, 2.5457667727456044],
					[59.215877612025167, 2.5490361674196698],
					[59.216666508426172, 2.5513611564419989],
					[59.217019205760771, 2.5527806479334187],
					[59.217019222835944, 2.5527806833506594],
					[59.217019268239838, 2.5527807450268254],
					[59.21701929656858, 2.5527807712857622],
					[59.217610996931363, 2.5531362707499596],
					[59.217611000673884, 2.5531362735550505],
					[59.217611008964568, 2.5531362772558106],
					[59.217611013599402, 2.5531362798919557],
					[59.218597058031435, 2.553608453577568],
					[59.219344217415475, 2.5549083831030397],
					[59.219819194995267, 2.5562889189787485],
					[59.219583105977875, 2.5579416465859381],
					[59.219030415037565, 2.5592832246683757],
					[59.21903040813168, 2.5592832488150083],
					[59.219030401631613, 2.5592832992388974],
					[59.219030402037426, 2.5592833255161724],
					[59.219227602282764, 2.560780626238607],
					[59.219227603694215, 2.5607806365133103],
					[59.219227609280672, 2.5607806582969879],
					[59.219227611584458, 2.5607806684029191],
					[59.219819212099132, 2.5623944682944781],
					[59.219819217166318, 2.5623944796351061],
					[59.219819227127651, 2.562394498835141],
					[59.219819232108307, 2.5623945084351618],
					[59.221277629181863, 2.5646418035697396],
					[59.223363725584626, 2.5683056994855225],
					[59.223363728434492, 2.5683057024613558],
					[59.223363732349569, 2.5683057087502088],
					[59.22336373422069, 2.5683057101538127],
					[59.225335900205437, 2.5712611603801867],
					[59.225335899720584, 2.5722471575486532],
					[59.224941571888515, 2.5731525923991914],
					[59.224272275210652, 2.5731137036029246],
					[59.223680746759001, 2.5726415606608812],
					[59.223089062741316, 2.5718137836488597],
					[59.223089061848967, 2.5718137838173694],
					[59.223089060783856, 2.5718137805042436],
					[59.223089059891507, 2.571813780672755],
					[59.222144560035346, 2.5705526792044116],
					[59.222144540172962, 2.5705526601137878],
					[59.222144494374589, 2.5705526265938099],
					[59.222144468438579, 2.570552612164462],
					[59.221277867806883, 2.5702387125901911],
					[59.221277845872208, 2.5702387061908301],
					[59.221277799671448, 2.5702387008605783],
					[59.221277777190025, 2.5702387015926251],
					[59.219936077143316, 2.5703942012766228],
					[59.219936060188758, 2.5703942044786166],
					[59.219936027344787, 2.5703942141953791],
					[59.219936010563011, 2.570394220878661],
					[59.218674911213512, 2.5710248202022452],
					[59.218674898979828, 2.5710248277830234],
					[59.21867487638351, 2.5710248443481456],
					[59.218674865042161, 2.5710248517604257],
					[59.21780816612312, 2.5718137511174426],
					[59.216627665615341, 2.5728776511737439],
					[59.216627665701708, 2.5728776529142285],
					[59.216627664723006, 2.5728776513421803],
					[59.215760964841486, 2.5736665513479071],
					[59.215760948663942, 2.5736665702117723],
					[59.215760921115994, 2.5736666140592179],
					[59.215760909659231, 2.573666637302356],
					[59.215247009923395, 2.575202736749969],
					[59.215247005605207, 2.5752027586452848],
					[59.215247000538206, 2.5752028017624569],
					[59.215247001660444, 2.5752028243879885],
					[59.215483102045688, 2.5770944244596405],
					[59.215483103542162, 2.5770944364744257],
					[59.21548311019081, 2.5770944615711713],
					[59.215483114364353, 2.5770944730809928],
					[59.216152614294934, 2.5787889721325343],
					[59.216152615359846, 2.578788975445232],
					[59.216152618382047, 2.5787889819023122],
					[59.216152619446973, 2.5787889852150103],
					[59.216705403801981, 2.5799722533574889],
					[59.216824798087465, 2.5812332867897818],
					[59.216547012684934, 2.5824527205263665],
					[59.215760932676218, 2.5836359891914915],
					[59.215760926946935, 2.5836360008114396],
					[59.215760917100646, 2.5836360202340831],
					[59.215760912263711, 2.58363603168588],
					[59.215247012302996, 2.5850555319941342],
					[59.215247006918361, 2.5850555505755146],
					[59.215247000783293, 2.5850555903786345],
					[59.215246999946693, 2.5850556098599169],
					[59.215324799522236, 2.5866694093106597],
					[59.215324801996381, 2.5866694228982823],
					[59.215324806858455, 2.5866694483330459],
					[59.215324810224985, 2.5866694617525749],
					[59.215955409921584, 2.5886000613692928],
					[59.215955422986994, 2.5886000887736125],
					[59.215955455912251, 2.5886001352760193],
					[59.215955475944412, 2.588600157855133],
					[59.21670546131795, 2.5891918446028264],
					[59.218044248673685, 2.5907307319196331],
					[59.218044253307816, 2.5907307345609754],
					[59.218044260071217, 2.5907307438288254],
					[59.21804426381297, 2.5907307466381355],
					[59.219188763573257, 2.591794545478356],
					[59.219188766422626, 2.5917945484558484],
					[59.219188771142861, 2.5917945528380755],
					[59.219188773013741, 2.59179455424283],
					[59.220055450806036, 2.5925029368193875],
					[59.220724828127913, 2.5934890031299584],
					[59.221788722848693, 2.5954972940290122],
					[59.221788727740936, 2.5954973018937406],
					[59.22178873833176, 2.5954973157144896],
					[59.221788743224003, 2.5954973235791998],
					[59.22265542293465, 2.5966000989991911],
					[59.223049812301326, 2.5976639690982797],
					[59.223049820300787, 2.5976639851646044],
					[59.223049837106089, 2.5976640153885207],
					[59.223049846804301, 2.5976640293782647],
					[59.223877647069351, 2.5986501286695347],
					[59.223877659358642, 2.598650140415518],
					[59.223877685721995, 2.5986501635718229],
					[59.223877700688462, 2.5986501748143644],
					[59.225255501252477, 2.5994390743871505],
					[59.225255504015664, 2.5994390756247561],
					[59.225255510434444, 2.5994390779321837],
					[59.225255513197659, 2.5994390791697888],
					[59.2265582133194, 2.6000695805732916],
					[59.226558234360525, 2.6000695871605202],
					[59.22655827805557, 2.6000695965172174],
					[59.226558299817093, 2.6000695994544638],
					[59.227580600360547, 2.6000696003827395],
					[59.227580617402062, 2.6000695989356379],
					[59.227580651313112, 2.6000695925591555],
					[59.227580668182611, 2.6000695876297808],
					[59.228566767835311, 2.5997140872661122],
					[59.228566795961896, 2.599714073189995],
					[59.228566846172647, 2.5997140321151542],
					[59.228566866644023, 2.5997140089344541],
					[59.229236267236217, 2.5986890081826179],
					[59.229236269020994, 2.5986890078469216],
					[59.2292362723325, 2.5986890019518771],
					[59.229236273945247, 2.598688998133746],
					[59.229866874115871, 2.5975861981366513],
					[59.229866874922209, 2.5975861962275264],
					[59.229866876620953, 2.5975861941505012],
					[59.229866878319726, 2.5975861920734893],
					[59.230497378047993, 2.5963639920848816],
					[59.230497382886, 2.5963639806297198],
					[59.23049739086327, 2.596363959796514],
					[59.230497393830412, 2.5963639469359228],
					[59.230814093706641, 2.5950639503828774],
					[59.23164189269275, 2.5920306527912569],
					[59.23164189269275, 2.5920306527912569],
					[59.232230785680052, 2.5898222793141898],
					[59.233491869901911, 2.5877751044296566],
					[59.233491875717668, 2.5877750945455249],
					[59.233491884672027, 2.5877750752817525],
					[59.233491888616868, 2.5877750639925496],
					[59.234241887969375, 2.5855667683114456],
					[59.234911287138516, 2.58383337250911],
					[59.234911292811567, 2.5838333415581394],
					[59.234911296932495, 2.5838332792609502],
					[59.234911292703302, 2.5838332484195519],
					[59.234555793338181, 2.582494349727078],
					[59.234555765764597, 2.5824943021966527],
					[59.234555692020379, 2.5824942317358048],
					[59.23455564389252, 2.5824942056591067],
					[59.233688944077961, 2.5822970055134316],
					[59.233688925971926, 2.5822970036553961],
					[59.233688887975163, 2.5822970002759487],
					[59.233688869149255, 2.5822970020690117],
					[59.232941681886949, 2.5824137003777237],
					[59.231641697430035, 2.5824526005914299],
					[59.230338953211557, 2.582452599886035],
					[59.229789105371331, 2.5821387863098648],
					[59.229827999047195, 2.5811917266685427],
					[59.230222391593166, 2.5794972562977545],
					[59.230852987944829, 2.5777639688186942],
					[59.230852990276652, 2.5777639613485541],
					[59.230852994134302, 2.5777639483179211],
					[59.230852995660086, 2.5777639427574144],
					[59.231247393214225, 2.5759111515926412],
					[59.231680788140721, 2.5745722683105043],
					[59.232272349912535, 2.5730751647671788],
					[59.233611038587831, 2.5726808270572912],
					[59.234319255007115, 2.5734307366194891],
					[59.234319268190916, 2.573430748191031],
					[59.234319296256885, 2.5734307692556229],
					[59.234319311139018, 2.5734307787486754],
					[59.235030510956108, 2.573783478480014],
					[59.23503054207324, 2.5737834884225768],
					[59.235030606466353, 2.5737834973535052],
					[59.235030638850013, 2.5737834965104054],
					[59.235619439272483, 2.5736668962160101],
					[59.235619483285355, 2.5736668755985486],
					[59.235619555910787, 2.5736668144213684],
					[59.235619584609715, 2.5736667756031157],
					[59.236489084764685, 2.5715389759305638],
					[59.23648908467829, 2.5715389741890711],
					[59.237077985112286, 2.5700806748920968],
					[59.237077991300445, 2.5700806543861838],
					[59.237077998322754, 2.5700806143862489],
					[59.237078000049223, 2.5700805947235787],
					[59.23703910039692, 2.5685833135381433],
					[59.237236286267532, 2.5673223037730568],
					[59.237789014293099, 2.5668501661479159],
					[59.238300095997673, 2.5665723756150625],
					[59.238300121183713, 2.5665723567887819],
					[59.238300164704221, 2.5665723081264877],
					[59.238300183211592, 2.5665722817736092],
					[59.238772383000011, 2.5655083809875672],
					[59.238772389273898, 2.565508362221419],
					[59.238772397360023, 2.5655083255330169],
					[59.23877240006459, 2.565508307441986],
					[59.238814100390464, 2.564405608758793],
					[59.238814098546719, 2.5644055897701166],
					[59.238814094053403, 2.5644055537031618],
					[59.238814089619162, 2.5644055369624956],
					[59.238419590344741, 2.563222137040079],
					[59.238419583319832, 2.5632221225478626],
					[59.238419568377779, 2.5632220937322239],
					[59.238419558675915, 2.5632220797465148],
					[59.237789059457583, 2.5623942793393999],
					[59.237789050821, 2.5623942686683789],
					[59.237789032828545, 2.5623942509782482],
					[59.237789024365, 2.5623942437902838],
					[59.237039023956434, 2.5618054424553423],
					[59.237039013708205, 2.5618054356053999],
					[59.237038995169463, 2.5618054250507591],
					[59.237038985900078, 2.5618054197734574],
					[59.23636958928364, 2.5614887674020923],
					[59.236291910277124, 2.5606223043819343],
					[59.236725077657482, 2.5603473882635859],
					[59.237788941674204, 2.5601112943234607],
					[59.238536141239429, 2.5599529968307673],
					[59.238536155430076, 2.5599529923864415],
					[59.238536181047799, 2.5599529822629936],
					[59.238536194259567, 2.5599529762460338],
					[59.239050093845002, 2.5596779765244286],
					[59.239050130110819, 2.5596779450470351],
					[59.239050182232333, 2.5596778701344953],
					[59.239050198001486, 2.5596778249577663],
					[59.239166897613764, 2.5587694254286748],
					[59.239166898878999, 2.5587694146412332],
					[59.239166899624841, 2.5587693934042606],
					[59.239166899192007, 2.5587693846963186],
					[59.239089099004701, 2.5578249834271718],
					[59.239089088003574, 2.5578249433190439],
					[59.23908904939357, 2.5578248732797886],
					[59.239089022590399, 2.5578248414380931],
					[59.238025122811564, 2.5569970418663899],
					[59.238025099637689, 2.5569970286760642],
					[59.238025049980422, 2.5569970081959861],
					[59.238025024389366, 2.5569970007372325],
					[59.237038924057771, 2.5568776013516907],
					[59.237038923165436, 2.5568776015206867],
					[59.2370389212942, 2.5568776001172062],
					[59.237038920401872, 2.5568776002861986],
					[59.235858327939042, 2.5567609024150699],
					[59.234794594192842, 2.5565637326569206],
					[59.234753001457285, 2.5553417325606977],
					[59.235383462242446, 2.5536084397533609],
					[59.236961144897137, 2.5527419033904328],
					[59.238102776120229, 2.5528195991023188],
					[59.239719293028394, 2.5530945850637519],
					[59.240783120429242, 2.5543972966040189],
					[59.241413709378634, 2.5564861576652365],
					[59.241413710617358, 2.5564861644629859],
					[59.241413715771749, 2.5564861775513883],
					[59.241413718795087, 2.5564861840110593],
					[59.241963718584337, 2.5576667832620461],
					[59.241963720628725, 2.5576667881492243],
					[59.241963723738593, 2.5576667963508317],
					[59.241963726588743, 2.5576667993272753],
					[59.242438726995729, 2.5584945008127433],
					[59.24243876485292, 2.5584945375970984],
					[59.242438855590201, 2.5584945872230085],
					[59.242438909362619, 2.5584945998955715],
					[59.243344356927679, 2.5584558019639299],
					[59.243974911456128, 2.5587695797591885],
					[59.243974934370151, 2.5587695877272623],
					[59.243974981723156, 2.5587695980999321],
					[59.243975007054431, 2.5587696003355416],
					[59.24515830693106, 2.5587308006854133],
					[59.245158317638897, 2.5587307986574266],
					[59.245158340033527, 2.5587307961743302],
					[59.245158350654798, 2.5587307924044493],
					[59.246338951403381, 2.5584168927796891],
					[59.246338959434254, 2.5584168912585521],
					[59.246338973624809, 2.5584168868123545],
					[59.246338980676789, 2.5584168837182832],
					[59.247050072110561, 2.5581001862542885],
					[59.248350031543268, 2.5576668997728107],
					[59.249999992088981, 2.5576668996701892],
					[59.250477742349766, 2.5577057961335696],
					[59.25150270495287, 2.558258475266316],
					[59.251502711458848, 2.5582584793095946],
					[59.251502724384245, 2.558258485653917],
					[59.251502729911344, 2.5582584881240136],
					[59.25288049716977, 2.5587695743784655],
					[59.254180469869915, 2.5598751518649121],
					[59.25508876869926, 2.5606612504116653],
					[59.255088778055267, 2.5606612574335492],
					[59.255088796767289, 2.5606612714773118],
					[59.255088806036746, 2.56066127675679],
					[59.256349905989417, 2.561330777850094],
					[59.256349936128508, 2.5613307862126802],
					[59.256349997039372, 2.5613307975414026],
					[59.256350027724572, 2.5613307987650873],
					[59.257175028024648, 2.5612140987170937],
					[59.257175028916954, 2.56121409854808],
					[59.257175029809282, 2.5612140983790659],
					[59.257963878939151, 2.5610946052678751],
					[59.258594260911437, 2.5613307603378388],
					[59.258988712210218, 2.5620417738756047],
					[59.259422006154942, 2.5637750483125545],
					[59.259422014418298, 2.563775069615148],
					[59.259422036298993, 2.5637751112065716],
					[59.259422049023982, 2.5637751316643507],
					[59.259933148953444, 2.564366831466879],
					[59.259933189053818, 2.5643668590553266],
					[59.25993327722621, 2.5643668933733617],
					[59.259933325211733, 2.5643668983603036],
					[59.260563925366561, 2.564286297988831],
					[59.260563941428472, 2.564286294947598],
					[59.2605639707023, 2.5642862858865976],
					[59.260563984893032, 2.5642862814405887],
					[59.26131398536014, 2.5639334813052201],
					[59.261314001968302, 2.5639334711238115],
					[59.261314032421119, 2.5639334495251758],
					[59.261314047071608, 2.5639334361962436],
					[59.262300147177484, 2.5628695365981895],
					[59.26230015137952, 2.562869530524821],
					[59.26230015906431, 2.562869522032635],
					[59.262300162374032, 2.5628695161282549],
					[59.263322361587079, 2.5614501170132007],
					[59.263322374106401, 2.5614500970491694],
					[59.263322390854484, 2.5614500534140201],
					[59.263322396954429, 2.5614500311476665],
					[59.263519596884365, 2.5602278314416167],
					[59.263519598609037, 2.5602278117631778],
					[59.263519596617947, 2.5602277716779605],
					[59.263519593881043, 2.5602277528449324],
					[59.263166894080676, 2.558811051238238],
					[59.263166890884193, 2.5588110412887159],
					[59.263166881641098, 2.5588110184114048],
					[59.263166878444615, 2.5588110084618849],
					[59.262455777698371, 2.557430508198264],
					[59.26158908112496, 2.5557360144634824],
					[59.26111689434984, 2.5546721433116084],
					[59.261000202673991, 2.5538833058716519],
					[59.261275195755566, 2.5525056396242367],
					[59.261275197739124, 2.552505625174879],
					[59.261275200008299, 2.5525055983573752],
					[59.261275199314923, 2.5525055844158091],
					[59.261194599287812, 2.5513999847094646],
					[59.261194597875395, 2.5513999744226457],
					[59.261194595137226, 2.5513999555916897],
					[59.26119459194021, 2.5513999456434657],
					[59.260683496846362, 2.5495888624670799],
					[59.260525200020531, 2.5479721899552383],
					[59.260525199705135, 2.5458833004844292],
					[59.260525200337149, 2.5458832950870858],
					[59.260525200076877, 2.545883289859193],
					[59.260525198924313, 2.5458832848007211],
					[59.260327998783119, 2.5432443850630624],
					[59.260327999415033, 2.5432443796657034],
					[59.260327998001976, 2.5432443693795252],
					[59.260327996849306, 2.5432443643211808],
					[59.259855797139281, 2.5406027645968403],
					[59.25985579500739, 2.54060275796567],
					[59.259855791635914, 2.5406027445337243],
					[59.259855790396315, 2.5406027377329661],
					[59.259461290223882, 2.5394221367190517],
					[59.259461288178784, 2.5394221318305901],
					[59.259461283022596, 2.5394221187381261],
					[59.259461279998362, 2.5394221122766942],
					[59.258950180391814, 2.5383582128796265],
					[59.258950178433544, 2.5383582097338255],
					[59.258950173450977, 2.5383582001267868],
					[59.258950171405829, 2.5383581952384477],
					[59.257964070729244, 2.5367415953245862],
					[59.257964064941184, 2.5367415876301842],
					[59.257964054257293, 2.5367415720717474],
					[59.257964046684656, 2.5367415647166691],
					[59.256664047138969, 2.5353220658410991],
					[59.256664019959437, 2.5353220446260525],
					[59.256663959788924, 2.5353220120958002],
					[59.256663926884841, 2.535322002522971],
					[59.255205627436126, 2.5351248027413642],
					[59.255205615662703, 2.5351248014626062],
					[59.255205593987348, 2.5351248003080293],
					[59.25520558319311, 2.5351248006019116],
					[59.253827783133609, 2.5352442010834491],
					[59.253827766180315, 2.5352442043072014],
					[59.253827733252898, 2.5352442123272896],
					[59.253827717278803, 2.5352442171236196],
					[59.252958216615745, 2.5356387184191282],
					[59.252958194743194, 2.535638731371566],
					[59.252958153304014, 2.5356387673904006],
					[59.252958135348166, 2.5356387866330641],
					[59.2521720348321, 2.5367804866845098],
					[59.25217202579887, 2.5367805042299154],
					[59.252172009603839, 2.5367805407236452],
					[59.252172004313458, 2.5367805610749197],
					[59.2517387040998, 2.5389499603483214],
					[59.251738702749442, 2.5389499693982533],
					[59.251738700854183, 2.53894998558637],
					[59.251738700396132, 2.538949994466738],
					[59.251699800711592, 2.5402888598752553],
					[59.251224828961256, 2.5414720907157813],
					[59.250397097205301, 2.5423387186713864],
					[59.248822191216199, 2.5426914968991756],
					[59.24641942888362, 2.5423776016290995],
					[59.245197245776936, 2.5421804051238666],
					[59.242713996614654, 2.5413915202089905],
					[59.241650152259602, 2.5405248652880177],
					[59.240861269594248, 2.5392637937892415],
					[59.240861268615127, 2.5392637922171155],
					[59.240861267636028, 2.53926379064499],
					[59.240861266743728, 2.5392637908144713],
					[59.240191879025325, 2.5382388077336131],
					[59.239797388769261, 2.5372138353780276],
					[59.239539097280726, 2.5363693617735734],
					[59.23940299898041, 2.5348110821647771],
					[59.239402998893532, 2.5348110804232604],
					[59.239402998632869, 2.5348110751987001],
					[59.239402997653691, 2.5348110736267713],
					[59.239205799214808, 2.5333332795509076],
					[59.239050199138141, 2.5311471855643246],
					[59.239050199051185, 2.5311471838228434],
					[59.239050198703453, 2.5311471768569063],
					[59.239050197637305, 2.5311471735435993],
					[59.238772399170244, 2.529136081238458],
					[59.238694600408834, 2.5277166887335198],
					[59.238694599081654, 2.5277166801959976],
					[59.238694597406528, 2.5277166646926439],
					[59.238694595187084, 2.5277166563248881],
					[59.23822239474724, 2.5256305562190806],
					[59.238222391374372, 2.5256305427971149],
					[59.238222381059579, 2.5256305166324711],
					[59.238222375009897, 2.5256305037199795],
					[59.23763077458797, 2.5245666032011203],
					[59.237630771563097, 2.5245665967450699],
					[59.237630764795121, 2.5245665874856082],
					[59.23763076087797, 2.5245665811994016],
					[59.236764061266129, 2.5233831813808685],
					[59.236764007164986, 2.5233831442168264],
					[59.236763888385958, 2.52338311057642],
					[59.23676382201058, 2.5233831161811513],
					[59.235738821670424, 2.5238165153640066],
					[59.235738797209322, 2.5238165305675033],
					[59.23573875139904, 2.5238165691714247],
					[59.23573873183441, 2.52381659223215],
					[59.235108131907666, 2.5248026922004776],
					[59.235108125291667, 2.5248027040066083],
					[59.235108113844227, 2.5248027272792277],
					[59.235108109012771, 2.5248027387457168],
					[59.234794234229803, 2.5257859633137425],
					[59.23400555630478, 2.5262191963667981],
					[59.233019519809638, 2.5261415082947689],
					[59.232350172978371, 2.5249999000957195],
					[59.231050182306461, 2.5227138163230354],
					[59.230064087807392, 2.5199943321230829],
					[59.230064087720301, 2.5199943303820813],
					[59.230064086828065, 2.5199943305519885],
					[59.228802994020775, 2.5166443477174161],
					[59.228144600776034, 2.5130055874199924],
					[59.228172399842528, 2.512466710037434],
					[59.228172399755344, 2.5124667082965688],
					[59.22829189989389, 2.5099444096646533],
					[59.228291899806685, 2.5099444079237907],
					[59.228408498907861, 2.5071472338335035],
					[59.228802992439583, 2.5058083572962064],
					[59.228802993700448, 2.5058083465104],
					[59.228802998268421, 2.5058083298208684],
					[59.228802998637057, 2.5058083192053342],
					[59.228961299299726, 2.5041917194848056],
					[59.228961299057197, 2.5041916966830611],
					[59.228961293218788, 2.504191652101436],
					[59.228961287710199, 2.5041916320624544],
					[59.228330788230927, 2.5024582314586343],
					[59.228330780220027, 2.5024582154130552],
					[59.228330762413769, 2.5024581836626174],
					[59.228330751813488, 2.5024581698689476],
					[59.227147352358493, 2.5010776701022452],
					[59.227147346743202, 2.5010776659022218],
					[59.227147338101901, 2.5010776552502447],
					[59.227147333378817, 2.5010776508798238],
					[59.225336232951427, 2.4994637503220325],
					[59.225336227336072, 2.4994637461224709],
					[59.22533621610534, 2.4994637377233624],
					[59.225336209597764, 2.4994637336942302],
					[59.223839009826953, 2.4984776337072847],
					[59.223838963993011, 2.4984776178603489],
					[59.223838869804624, 2.4984776077353965],
					[59.223838820645298, 2.4984776153684067],
					[59.222183221665055, 2.4991859156274638],
					[59.221119323436902, 2.4996359163880886],
					[59.220686033326302, 2.4998165114088198],
					[59.220091644995456, 2.4999887071897398],
					[59.218755545207685, 2.5003692074887258],
					[59.218755534588361, 2.5003692112732145],
					[59.218755515134156, 2.5003692185015027],
					[59.218755504602143, 2.5003692240263544],
					[59.217833204868711, 2.500866523153003],
					[59.217833203976475, 2.500866523323332],
					[59.217297104325375, 2.5011581231778015],
					[59.217297079236779, 2.5011581437794241],
					[59.217297034849508, 2.5011581926621393],
					[59.217297017247958, 2.5011582188623298],
					[59.216666517292104, 2.5025777182472067],
					[59.216666511114596, 2.5025777387521271],
					[59.216666502328472, 2.5025777790808719],
					[59.216666499807147, 2.5025778006449468],
					[59.21666650021043, 2.5167222005068179],
					[59.216666499810231, 2.5201916999674507],
					[59.21666651108351, 2.5201917452576579],
					[59.216666551126991, 2.5201918254805471],
					[59.216666579897151, 2.5201918604132345],
					[59.217610954893068, 2.5209001410106997],
					[59.218477626336913, 2.5221223001170912],
					[59.219266467354579, 2.5238555714269117],
					[59.218085990073206, 2.5253526156975399],
					[59.215799965933208, 2.5257470038715635],
					[59.215799925323886, 2.5257470203774983],
					[59.215799854877027, 2.5257470706661809],
					[59.215799824952512, 2.5257471027086642],
					[59.2148526252465, 2.5274416012721255],
					[59.214852618804052, 2.5274416165509543],
					[59.214852609401291, 2.5274416446899401],
					[59.214852603764164, 2.527441658058919],
					[59.214063704206907, 2.5311055583070039],
					[59.214063702225396, 2.531105572737264],
					[59.214063700939221, 2.5311056010892283],
				],
			],
		},
		{
			fldNpdidField: "43618",
			wlbNpdidWellbore: "508",
			fldName: "VESLEFRIKK",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "30/3-2 R",
			fldDiscoveryYear: "1981",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43618",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43618",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "052",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=508",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "21212",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[60.74421650909553, 2.8778306156888309],
					[60.744216512577786, 2.8778306720505871],
					[60.744808112663506, 2.8793694714773066],
					[60.744808114699396, 2.8793694766070597],
					[60.744808118687615, 2.879369485042929],
					[60.744808120639981, 2.8793694883490408],
					[60.745841521080912, 2.8814417890308812],
					[60.745841535556281, 2.8814418101809052],
					[60.745841569779579, 2.8814418496338399],
					[60.745841588551329, 2.8814418662835815],
					[60.746799852198748, 2.8820779416776854],
					[60.748480428978887, 2.8848473040545564],
					[60.748480429955009, 2.8848473057079564],
					[60.748480431823815, 2.8848473071908645],
					[60.748480431907261, 2.8848473090147544],
					[60.750152631715501, 2.8874112084297683],
					[60.750152633667689, 2.887411211736894],
					[60.750152637572036, 2.8874112183511662],
					[60.7501526404169, 2.8874112214878624],
					[60.751147040344911, 2.8887279212063461],
					[60.751147062044062, 2.8887279428239387],
					[60.75114711225028, 2.8887279773997312],
					[60.751147138971966, 2.8887279906987109],
					[60.754405530413727, 2.8897668872220708],
					[60.758483207159045, 2.8915223787235558],
					[60.761244278088093, 2.8932445593022735],
					[60.764155453979313, 2.8959723375731294],
					[60.766588746313637, 2.8989084285335331],
					[60.766588775236215, 2.8989084506311094],
					[60.766588838997173, 2.8989084863348125],
					[60.766588873752269, 2.8989084981159605],
					[60.767708257337041, 2.8990557967360324],
					[60.77000554068411, 2.8997640904575008],
					[60.770005549777594, 2.8997640924055954],
					[60.770005567071877, 2.8997640964719982],
					[60.77000557616536, 2.8997640984200808],
					[60.773830519604736, 2.9002334927744955],
					[60.777580472146276, 2.9032084539075549],
					[60.780269239384239, 2.9055723248007919],
					[60.781872009089881, 2.9087778658044017],
					[60.782710903177559, 2.913177832764283],
					[60.783491500784869, 2.9184250191227012],
					[60.783577600150792, 2.9200722093126652],
					[60.783577609035468, 2.9200722463007689],
					[60.783577640029755, 2.9200723140786509],
					[60.783577662139365, 2.9200723448684407],
					[60.784655452037839, 2.9211001353246191],
					[60.786083141202482, 2.9228890226662911],
					[60.786747014171404, 2.9237944838658345],
					[60.787410903664345, 2.9267750391788714],
					[60.787997002795393, 2.9301917343981048],
					[60.787997004019587, 2.9301917415337737],
					[60.787997008253441, 2.9301917554661094],
					[60.787997010370354, 2.9301917624322571],
					[60.789333105782859, 2.9342861518723766],
					[60.789891502193363, 2.937083328612299],
					[60.790116501434085, 2.9395333183523427],
					[60.790116501765013, 2.9395333256581266],
					[60.790116504129671, 2.9395333381047175],
					[60.790116505353353, 2.939533345241244],
					[60.791038705360251, 2.9435028443000784],
					[60.791038706666406, 2.9435028532634808],
					[60.79103871187435, 2.943502868856315],
					[60.791038714883392, 2.9435028756548784],
					[60.792447015281816, 2.9469611743707258],
					[60.792447019348714, 2.9469611846540684],
					[60.792447027234608, 2.9469611997409553],
					[60.79244703113622, 2.9469612063710833],
					[60.793894217990513, 2.9492333862250133],
					[60.795355403649943, 2.9536500435804665],
					[60.795902599617392, 2.9583222133786382],
					[60.79597760029413, 2.9618417041648084],
					[60.795977652791692, 2.9618417900526155],
					[60.795977806024389, 2.9618418734901799],
					[60.795977906677052, 2.9618418692130923],
					[60.803741793144582, 2.9569529776679304],
					[60.810077879383009, 2.9542195837866889],
					[60.810077896908439, 2.9542195730964136],
					[60.810077930091225, 2.9542195502262163],
					[60.81007794485577, 2.9542195382152721],
					[60.816416845426417, 2.9475334382285614],
					[60.816416851427881, 2.9475334315601365],
					[60.816416860752469, 2.9475334187309241],
					[60.816416865778507, 2.947533410403735],
					[60.819472365739813, 2.9429640101531209],
					[60.819472383312835, 2.9429639607238527],
					[60.819472387608734, 2.9429638566549658],
					[60.81947237628254, 2.9429638053328691],
					[60.818508476210901, 2.9411832050398958],
					[60.81850845516373, 2.9411831776897368],
					[60.818508405448227, 2.9411831336544254],
					[60.818508375804427, 2.9411831153106274],
					[60.81714183674633, 2.9406248407562181],
					[60.816025188209245, 2.9387721249897418],
					[60.815491894958114, 2.9363832560307466],
					[60.815491893899797, 2.9363832525445113],
					[60.815491892758637, 2.9363832472303888],
					[60.815491891700297, 2.9363832437441566],
					[60.814864099387364, 2.9342193676883608],
					[60.81473910079032, 2.9317472090834484],
					[60.815194596763504, 2.9285361323596368],
					[60.815597393794619, 2.9263722528208587],
					[60.81631958033811, 2.9243528911816501],
					[60.817466864676966, 2.9226640112970217],
					[60.817466875535587, 2.9226639926365912],
					[60.817466891647499, 2.9226639508513728],
					[60.817466897876514, 2.9226639293846275],
					[60.817847398133281, 2.9200889285778353],
					[60.817847399337587, 2.9200889154421472],
					[60.817847398985045, 2.9200888878527649],
					[60.817847398320879, 2.9200888732290866],
					[60.817519602558114, 2.917658310028632],
					[60.818072390446616, 2.9153639676729939],
					[60.819369571590627, 2.9126029036003831],
					[60.820380759412451, 2.9112501209075954],
					[60.821347339373759, 2.9100140466529476],
					[60.822636213188368, 2.9091279655693159],
					[60.822636245614056, 2.9091279261892562],
					[60.822636290243764, 2.909127838379022],
					[60.822636299769641, 2.9091277904598676],
					[60.822450199596787, 2.9054193913873134],
					[60.822369600310473, 2.9028332931142016],
					[60.822369600143936, 2.9028332894578948],
					[60.822369598751706, 2.9028332786594513],
					[60.822369597692507, 2.9028332751736268],
					[60.821941898208699, 2.8994860744037898],
					[60.821941893804905, 2.8994860568049612],
					[60.821941883128638, 2.8994860201203378],
					[60.82194187613009, 2.8994860048613922],
					[60.819900180240055, 2.8957360124302474],
					[60.819319584745053, 2.8943999239094791],
					[60.818261285710655, 2.8916999273693356],
					[60.818261282615566, 2.8916999187422845],
					[60.818261274723518, 2.8916999036575897],
					[60.818261270735775, 2.8916998952013042],
					[60.817025174583307, 2.8896638026255759],
					[60.815422395274169, 2.8864527423277351],
					[60.814319598785588, 2.8768943764408128],
					[60.814319597809337, 2.8768943747843743],
					[60.814319598451185, 2.876894369130381],
					[60.81431959658228, 2.876894367645098],
					[60.813880797071768, 2.8742777671925617],
					[60.813880785663486, 2.8742777343522596],
					[60.81388075327871, 2.8742776760380009],
					[60.813880732218578, 2.8742776487364923],
					[60.808772344798477, 2.8698109605339939],
					[60.804219555772299, 2.8641887752209008],
					[60.798455755954613, 2.8569470755502713],
					[60.798455754978114, 2.8569470738952507],
					[60.798455752132526, 2.8569470707568096],
					[60.798455750179549, 2.8569470674467676],
					[60.794361249585506, 2.8522720673215742],
					[60.794361248692901, 2.8522720674932875],
					[60.794361245847199, 2.8522720643556481],
					[60.794361244870665, 2.8522720627009761],
					[60.792789044675175, 2.8506220616902151],
					[60.792789040936839, 2.8506220587246367],
					[60.792789032399668, 2.8506220493126286],
					[60.792789026876129, 2.8506220466905492],
					[60.78422514509333, 2.8435470612614866],
					[60.780952959209742, 2.8392248794595165],
					[60.78095295343374, 2.8392248713635144],
					[60.780952939203928, 2.8392248556874677],
					[60.780952930834204, 2.8392248499330193],
					[60.779019531055766, 2.8375442490415312],
					[60.779019514316211, 2.8375442375341149],
					[60.779019476374202, 2.8375442153793817],
					[60.779019456232511, 2.8375442082109767],
					[60.776511156001114, 2.8368137074759492],
					[60.776511140574705, 2.8368137049243254],
					[60.776511109806023, 2.8368137016464061],
					[60.776511094379622, 2.8368136990947974],
					[60.77302219397609, 2.836910899719836],
					[60.773022185942757, 2.836910901267772],
					[60.773022168006833, 2.8369109028824897],
					[60.773022159973507, 2.8369109044304248],
					[60.770377759958514, 2.8374554039115409],
					[60.770377719403861, 2.8374554227715012],
					[60.770377649762665, 2.837455474852673],
					[60.770377621652784, 2.8374555097269032],
					[60.768741523992432, 2.8406666031547996],
					[60.766522110726264, 2.8444830541334736],
					[60.762538969265826, 2.8430192115668005],
					[60.762538904410263, 2.8430192111609234],
					[60.762538787258841, 2.8430192484282077],
					[60.76253873496298, 2.8430192861013848],
					[60.760577650738803, 2.8458831633510386],
					[60.757174875542518, 2.8486942426048882],
					[60.755344279810799, 2.8500748397318154],
					[60.755344265948551, 2.8500748515966752],
					[60.755344241962362, 2.8500748782887091],
					[60.75534423086188, 2.8500748914632208],
					[60.753819245781557, 2.8524665696265843],
					[60.752935965200336, 2.8532720522322359],
					[60.752935940656322, 2.8532720863873493],
					[60.752935908054866, 2.8532721607326192],
					[60.752935900081305, 2.8532722027468043],
					[60.7529608995834, 2.8557582884489268],
					[60.752597009398016, 2.858738817781159],
					[60.751338746768688, 2.8602442720540493],
					[60.751338733240573, 2.8602442912088488],
					[60.751338711959029, 2.8602443376111655],
					[60.751338703954275, 2.8602443593867646],
					[60.750763709079919, 2.8630610375789387],
					[60.75002201830101, 2.8646666154173257],
					[60.750022012080777, 2.8646666368488272],
					[60.750022003043455, 2.8646666753797745],
					[60.750022000393813, 2.8646666961270322],
					[60.74991920134412, 2.8707110328121401],
					[60.746363746009322, 2.875591571544085],
					[60.744216556056088, 2.8778304613830823],
					[60.74421653038835, 2.8778305104404041],
					[60.74421650909553, 2.8778306156888309],
				],
			],
		},
		{
			fldNpdidField: "1035937",
			wlbNpdidWellbore: "1872",
			fldName: "GRANE",
			fldCurrentActivitySatus: "Producing",
			wlbName: "25/11-15",
			fldDiscoveryYear: "1991",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=1035937",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=1035937",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "GRANE UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=1872",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "1031365",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-02-17 00:00:00",
			fldMainSupplyBase: "Dusavik",
			fldAreaGeometryWKT: [
				[
					[59.111774801399967, 2.4377194104642044],
					[59.111774802456836, 2.4377194312806498],
					[59.1121331008124, 2.4399694218561572],
					[59.112197000253779, 2.4409833122196938],
					[59.11219700132196, 2.440983315517991],
					[59.112197000781954, 2.4409833226281137],
					[59.112197001850141, 2.4409833259264118],
					[59.11237200186757, 2.4423333265262674],
					[59.112372001955585, 2.4423333282610127],
					[59.112372003111751, 2.4423333332941342],
					[59.112372003199752, 2.4423333350288834],
					[59.112788703029238, 2.4446944356526505],
					[59.112788706321496, 2.4446944472828704],
					[59.112788714866269, 2.4446944736707144],
					[59.112788720030807, 2.4446944866935474],
					[59.114077620302794, 2.4473778862407349],
					[59.114077625291173, 2.4473778957947694],
					[59.114077636160054, 2.4473779147318222],
					[59.114077641952647, 2.4473779223799847],
					[59.116233082267122, 2.4501611457234338],
					[59.115835906366172, 2.451747151193596],
					[59.115835907218525, 2.4517472035841688],
					[59.115835935599819, 2.4517473015028832],
					[59.11583596402091, 2.4517473468600985],
					[59.11798868941738, 2.4537417769882475],
					[59.117652600599946, 2.4565638762235311],
					[59.117652606452047, 2.4565639206520564],
					[59.117652633059436, 2.4565640014002499],
					[59.117652654706887, 2.4565640375491111],
					[59.118816495899182, 2.4577861765932831],
					[59.118763700189078, 2.4588027906210432],
					[59.118763703553753, 2.4588028215119038],
					[59.11876372107681, 2.4588028829796853],
					[59.11876373523522, 2.4588029135566418],
					[59.121041534701249, 2.4621501135176502],
					[59.121041536573323, 2.4621501149115472],
					[59.121041539600853, 2.4621501213405361],
					[59.121041542365113, 2.462150122563747],
					[59.122610942297555, 2.4641557230787434],
					[59.12261100542154, 2.4641557600648873],
					[59.122611138939263, 2.464155781835176],
					[59.122611211117352, 2.4641557662780413],
					[59.123133411006307, 2.4638084674276661],
					[59.123133435914148, 2.4638084433897007],
					[59.123133476369496, 2.4638083883434403],
					[59.123133491024788, 2.4638083575058021],
					[59.123650048925029, 2.4621088278389158],
					[59.124233091466259, 2.4632278317715546],
					[59.123608103396961, 2.4666193639643268],
					[59.123608107899898, 2.4666194174206377],
					[59.123608146172167, 2.4666195152332668],
					[59.123608179049306, 2.4666195597601672],
					[59.124191486952505, 2.4670611891418268],
					[59.124077601920611, 2.467966675869449],
					[59.124077608032017, 2.4679667255147488],
					[59.124077645952603, 2.467966816388548],
					[59.124077676869604, 2.4679668577875811],
					[59.125358123809711, 2.4689612162991281],
					[59.125827605824881, 2.4707583502356205],
					[59.12582760902729, 2.4707583601373266],
					[59.125827616236641, 2.470758378034724],
					[59.125827620331236, 2.4707583877659385],
					[59.126530420096486, 2.4722139873733955],
					[59.126530428022591, 2.4722140016298795],
					[59.1265304483357, 2.4722140292905488],
					[59.126530458850731, 2.472214041300099],
					[59.127869258592398, 2.4735473424682666],
					[59.127869356246542, 2.4735473676232433],
					[59.127869523276743, 2.473547321697112],
					[59.1278695926528, 2.4735472506160225],
					[59.128441883163823, 2.4713944910303183],
					[59.129244374087058, 2.4703670577118642],
					[59.12994701318862, 2.471597274761435],
					[59.130477590692692, 2.4737332874507856],
					[59.129905413598713, 2.4752082287775501],
					[59.129905409575706, 2.4752082383083112],
					[59.129905404381425, 2.4752082603301324],
					[59.129905402317981, 2.4752082729915865],
					[59.12973310227337, 2.4764499730334903],
					[59.129733101364693, 2.4764499907316933],
					[59.129733103203691, 2.4764500271823744],
					[59.129733104971599, 2.4764500443694746],
					[59.129913694122841, 2.4772360942853231],
					[59.127513706780356, 2.4858443463804165],
					[59.127513719812271, 2.4858444262556669],
					[59.127513800056263, 2.4858445528957827],
					[59.127513868965309, 2.4858445975847538],
					[59.129483268798246, 2.4861529984173689],
					[59.129483288689165, 2.4861529998821723],
					[59.129483329100871, 2.486152997434345],
					[59.129483348729465, 2.4861529936918405],
					[59.130683134124794, 2.485850247557448],
					[59.131316502226284, 2.4902028295322398],
					[59.131316505689426, 2.4902028446450366],
					[59.131316516359348, 2.4902028776623215],
					[59.131316523391362, 2.490202892095049],
					[59.132174800151603, 2.4918444493462313],
					[59.132180399777674, 2.4931972007454628],
					[59.132180401281353, 2.4931972127270243],
					[59.13218040322176, 2.4931972333882144],
					[59.132180404725425, 2.4931972453697582],
					[59.133044204955617, 2.4969444455757457],
					[59.133044218213264, 2.496944476351326],
					[59.133044254368471, 2.4969445325620656],
					[59.133044276286505, 2.4969445564311141],
					[59.136610976145754, 2.4997501564472309],
					[59.136611005831632, 2.4997501735836547],
					[59.1366110699459, 2.499750194684407],
					[59.136611102677115, 2.49975020072455],
					[59.137691703363984, 2.4997334996314415],
					[59.137691751369964, 2.4997334869889323],
					[59.13769183545341, 2.4997334394344715],
					[59.137691870638655, 2.4997334046923392],
					[59.138613916813085, 2.4982170562921966],
					[59.139391539756588, 2.4992584203335491],
					[59.139391581914289, 2.4992584526259871],
					[59.139391678391277, 2.4992584903548862],
					[59.139391731905626, 2.4992584976974972],
					[59.143486131655692, 2.4985973965577135],
					[59.143486167082997, 2.4985973845527853],
					[59.143486230906404, 2.4985973461034319],
					[59.14348625938981, 2.4985973213955304],
					[59.144177933727207, 2.4976834550101699],
					[59.145027670746209, 2.4972196982124091],
					[59.145497005937628, 2.4990194496831366],
					[59.145497042984552, 2.4990195057458386],
					[59.145497141294634, 2.4990195799487225],
					[59.145497203537332, 2.4990195996556102],
					[59.145961104317685, 2.4990112994989286],
					[59.145961163573361, 2.4990112776963995],
					[59.145961259730434, 2.4990112015292585],
					[59.14596129395516, 2.4990111476743118],
					[59.147102954752263, 2.4943308100877273],
					[59.149036086531765, 2.4944529993858371],
					[59.149036140869875, 2.4944529872772523],
					[59.149036234765255, 2.4944529378219369],
					[59.149036274235137, 2.4944528987384174],
					[59.149802811054293, 2.4930864892219473],
					[59.151510931215839, 2.4957723063997306],
					[59.151510959816491, 2.4957723377746142],
					[59.151511026851153, 2.4957723811150778],
					[59.151511064567593, 2.4957723967244938],
					[59.152286015398964, 2.4959112874217011],
					[59.155077676009263, 2.4981251569425216],
					[59.155077757163475, 2.4981251765568828],
					[59.155077905273082, 2.4981251483410833],
					[59.155077973120648, 2.4981251003409586],
					[59.156308369085231, 2.496000279358249],
					[59.157702678021849, 2.4968834594611962],
					[59.159174797373808, 2.4982167857411826],
					[59.159108099893395, 2.500474994516344],
					[59.159108142884911, 2.500475079286709],
					[59.159108280064764, 2.5004751741803437],
					[59.159108375145337, 2.5004751841336597],
					[59.159877728588327, 2.5001641447494807],
					[59.16073313828327, 2.5013557164828284],
					[59.160733228688997, 2.5013557589015116],
					[59.160733400782796, 2.5013557419118513],
					[59.160733482470917, 2.5013556825034788],
					[59.162036183016944, 2.4984753023025599],
					[59.163508119404334, 2.4995084176141704],
					[59.163824803744475, 2.5011583385297085],
					[59.163824815217161, 2.501158369673838],
					[59.163824847802537, 2.5011584266174443],
					[59.163824868827938, 2.50115845067939],
					[59.164680469185313, 2.5019001511923227],
					[59.164680548729258, 2.5019001746347578],
					[59.164680698885967, 2.5019001512974932],
					[59.164680768693813, 2.5019001064253095],
					[59.165833299259823, 2.5000753762354453],
					[59.166296994475587, 2.5008222475060897],
					[59.165924800619308, 2.5041388787121761],
					[59.165924804148467, 2.5041389131259075],
					[59.165924821108561, 2.5041389818222388],
					[59.165924836236755, 2.5041390140273925],
					[59.166235936044295, 2.5045862137166739],
					[59.167480435734049, 2.5063751134046517],
					[59.167480511775437, 2.5063751568236539],
					[59.16748066894359, 2.5063751655016868],
					[59.167480750157551, 2.5063751324984924],
					[59.168019329418037, 2.505764282754007],
					[59.168408111833287, 2.5068139692843272],
					[59.168408124740395, 2.5068139931435831],
					[59.168408154754225, 2.5068140347994947],
					[59.168408172840387, 2.5068140541641197],
					[59.168949872892469, 2.507258455396046],
					[59.168949940662166, 2.5072584775854154],
					[59.168950075457417, 2.5072584712271233],
					[59.16895014061128, 2.5072584412812855],
					[59.170027893687141, 2.5061890890774445],
					[59.171188814402385, 2.5060224137398501],
					[59.172119243170492, 2.5072112233218293],
					[59.172119284347403, 2.5072112540843197],
					[59.172119377253978, 2.5072112925452128],
					[59.172119428004208, 2.5072112986754695],
					[59.173277646210266, 2.5070446244260371],
					[59.173752604885166, 2.5091472431604878],
					[59.173752625083722, 2.5091472866926554],
					[59.173752682541128, 2.509147354717904],
					[59.17375271997431, 2.5091473826871833],
					[59.174755520183147, 2.5095834838191151],
					[59.174755580835182, 2.5095834898233624],
					[59.174755694063002, 2.5095834665195178],
					[59.174755746551618, 2.5095834354732904],
					[59.176449861863603, 2.5077532438861527],
					[59.176688701707768, 2.5094056299101481],
					[59.176688704994703, 2.5094056415683434],
					[59.176688712722324, 2.5094056699296421],
					[59.176688716901516, 2.5094056814180243],
					[59.177624809074807, 2.5115000625026367],
					[59.178096993728161, 2.5135999977396351],
					[59.177866508976649, 2.5145082410683091],
					[59.176491511717124, 2.518291631324006],
					[59.176491506146014, 2.5182916639694404],
					[59.176491504992747, 2.5182917308713111],
					[59.17649151021579, 2.5182917632199002],
					[59.177035888684152, 2.5199416973227708],
					[59.176197010983799, 2.5223638352000397],
					[59.176197007173904, 2.5223638850577057],
					[59.176197023645294, 2.5223639801969382],
					[59.176197044818842, 2.5223640253090145],
					[59.178372045325659, 2.5250418252011446],
					[59.178372080078972, 2.5250418536997996],
					[59.178372159423212, 2.5250418912801904],
					[59.178372202229596, 2.5250419007007654],
					[59.179527672355185, 2.5250280026626863],
					[59.180077594861565, 2.5262250325111233],
					[59.179852601442796, 2.5281860769359459],
					[59.179852601072056, 2.5281860875360915],
					[59.179852601309797, 2.5281861103055117],
					[59.17985260093905, 2.5281861209056626],
					[59.180010900995043, 2.5296889206164797],
					[59.180010907826087, 2.5296889491546928],
					[59.180010930324059, 2.5296890027994419],
					[59.180010945011794, 2.5296890263368219],
					[59.180860907784094, 2.530730680306128],
					[59.181022000629724, 2.532086123507145],
					[59.181022002935514, 2.532086133599845],
					[59.181022007373393, 2.5320861503081695],
					[59.181022008786918, 2.5320861605701293],
					[59.181258109103808, 2.5328361602704672],
					[59.181258161653965, 2.5328362205040129],
					[59.181258295378321, 2.5328362828909063],
					[59.181258375660271, 2.5328362852134974],
					[59.181644279571081, 2.532678065249006],
					[59.182113702232634, 2.5340278398069942],
					[59.182194199698422, 2.5352333134985572],
					[59.182194205288667, 2.5352333352545151],
					[59.182194218340584, 2.5352333801666633],
					[59.182194228392277, 2.5352334010766642],
					[59.183516528291271, 2.5374723023037857],
					[59.183516557775192, 2.5374723335727705],
					[59.183516628627487, 2.5374723815719804],
					[59.183516669995896, 2.5374723983022163],
					[59.184444237060823, 2.5376112787178848],
					[59.184835904654101, 2.5392639466877962],
					[59.184835910962455, 2.5392639647995008],
					[59.184835927061556, 2.5392639986077428],
					[59.18483593596001, 2.5392640144733853],
					[59.186080435648385, 2.5410529137036941],
					[59.186080480734404, 2.5410529507980479],
					[59.186080585406131, 2.5410529906447517],
					[59.186080643294034, 2.5410529954740513],
					[59.1875443942969, 2.5407335059858802],
					[59.188552698368902, 2.541019579575265],
					[59.189174862035557, 2.5416140458691845],
					[59.189174916416235, 2.5416140706737096],
					[59.189175029862128, 2.5416140877975337],
					[59.189175088840607, 2.5416140783778438],
					[59.189791702898447, 2.5413058228941798],
					[59.190411014657755, 2.5415973803598022],
					[59.190411017421404, 2.5415973815916093],
					[59.190411021164103, 2.5415973843933983],
					[59.190411023035466, 2.5415973857942804],
					[59.191108222745122, 2.5418890848528761],
					[59.191108302853728, 2.5418890837177459],
					[59.191108436661395, 2.541889021497338],
					[59.191108490360449, 2.5418889604120762],
					[59.191641845940531, 2.5402251975373193],
					[59.192105463858297, 2.5402196017488405],
					[59.192341514321505, 2.5408194734200804],
					[59.192341525264737, 2.5408194941686912],
					[59.192341552331428, 2.5408195311729691],
					[59.192341566757072, 2.5408195495060073],
					[59.193347067202723, 2.5417112493257519],
					[59.193347098842182, 2.5417112696644462],
					[59.193347166694103, 2.5417112936744766],
					[59.19334720308008, 2.5417113008241983],
					[59.194508203269102, 2.5416946006815562],
					[59.194972041613461, 2.542289022457604],
					[59.194972087331323, 2.5422890541755758],
					[59.194972185583289, 2.5422890917397512],
					[59.194972240707521, 2.5422890953394046],
					[59.195747240568096, 2.5421279955417222],
					[59.195747258327309, 2.542127990420378],
					[59.195747291887663, 2.5421279770372318],
					[59.195747307688762, 2.5421279687754579],
					[59.197674961226376, 2.5408974611638864],
					[59.199458147718786, 2.5429834304774817],
					[59.199458165626659, 2.5429834463991412],
					[59.199458203945802, 2.5429834742561317],
					[59.199458225162651, 2.5429834842828463],
					[59.200541625241371, 2.5434223843756825],
					[59.200541686785179, 2.5434223902725899],
					[59.200541802350521, 2.5434223595917724],
					[59.200541854760921, 2.5434223268313803],
					[59.201161255199075, 2.5426612250929179],
					[59.201161263601549, 2.5426612129640929],
					[59.201161280319781, 2.5426611869668552],
					[59.201161285958641, 2.542661173605806],
					[59.20169458624077, 2.5412972743443043],
					[59.202308473280972, 2.5397807054344375],
					[59.202694553023015, 2.539325128990658],
					[59.202694563122968, 2.5393251147823426],
					[59.20269457966689, 2.5393250853029707],
					[59.202694587003158, 2.539325069862659],
					[59.202922338641706, 2.5387168978295316],
					[59.203850002427593, 2.5387057996621656],
					[59.203850053920107, 2.5387057846267962],
					[59.203850142131841, 2.5387057292591959],
					[59.203850178045606, 2.5387056908359154],
					[59.204158455170528, 2.5380973369769633],
					[59.204850111418352, 2.5376362663879344],
					[59.204850120973084, 2.5376362593065616],
					[59.204850140801184, 2.5376362414949774],
					[59.204850150269095, 2.5376362326738384],
					[59.205927949838632, 2.5364168321187659],
					[59.205927964139157, 2.5364168118421562],
					[59.205927986233654, 2.5364167672547278],
					[59.205927995006753, 2.5364167445143999],
					[59.206311295093208, 2.5347528448814645],
					[59.206311296727726, 2.5347528234953862],
					[59.206311298038528, 2.5347527775822769],
					[59.206311295124763, 2.5347527553031775],
					[59.206075195012801, 2.5337027552426012],
					[59.206075194925923, 2.5337027535027885],
					[59.206075192880753, 2.5337027486221246],
					[59.206075192793882, 2.5337027468822937],
					[59.205525201083361, 2.5317499744972132],
					[59.205594599122691, 2.5291861265342535],
					[59.206050190867728, 2.5273722619277934],
					[59.206969548993094, 2.5250973647217143],
					[59.207511155699152, 2.5249390920642498],
					[59.207511188191091, 2.5249390753488314],
					[59.207511243730295, 2.5249390331751083],
					[59.207511267669858, 2.5249390075471929],
					[59.208589067671554, 2.5232668078995255],
					[59.208589070261326, 2.5232668056505663],
					[59.208589072764077, 2.5232668016617152],
					[59.208589074374586, 2.523266797842544],
					[59.209355762826981, 2.5219001185392762],
					[59.210358424040365, 2.5208307597984971],
					[59.211205696081493, 2.5203668746220913],
					[59.211205729747817, 2.5203668453805448],
					[59.211205780149278, 2.5203667725530203],
					[59.211205797058504, 2.5203667324471204],
					[59.211364092642896, 2.5193750575919296],
					[59.212544528929733, 2.516747400488303],
					[59.215677798869407, 2.5167585006976845],
					[59.215677800653928, 2.5167585003578892],
					[59.215677805115192, 2.5167584995084038],
					[59.215677806899684, 2.5167584991686107],
					[59.216666706568098, 2.5167224001480082],
					[59.216666776184809, 2.5167223693225829],
					[59.216666872022728, 2.5167222684992856],
					[59.216666900115541, 2.5167221999019165],
					[59.21666690002003, 2.5025778419805795],
					[59.217297354553061, 2.5011584426134621],
					[59.217833394581689, 2.5008668752273646],
					[59.218755675929238, 2.500369586645693],
					[59.220091755284614, 2.4999890920184877],
					[59.220091757069056, 2.4999890916777607],
					[59.220091762422385, 2.49998909065557],
					[59.220091765011702, 2.4999890884040696],
					[59.221119464605636, 2.4996362883018222],
					[59.221119468174521, 2.4996362876202984],
					[59.221119475224931, 2.4996362845167921],
					[59.221119477901588, 2.499636284005653],
					[59.222183378002953, 2.4991862846537916],
					[59.222183409336672, 2.4991862628547517],
					[59.222183461665651, 2.499186210687967],
					[59.222183484357956, 2.4991861782389018],
					[59.223044584201823, 2.4971778790856498],
					[59.22304459226865, 2.4971778423995219],
					[59.223044594126833, 2.4971777717546124],
					[59.223044588635666, 2.4971777341442496],
					[59.22254458876926, 2.4957360335431145],
					[59.222544579690584, 2.4957360141912468],
					[59.222544558139184, 2.4957359796505454],
					[59.222544544686848, 2.4957359628916738],
					[59.219250144909076, 2.4922776617352032],
					[59.219250087581116, 2.4922776322820144],
					[59.219249966819021, 2.4922776131982212],
					[59.219249904364503, 2.4922776251374232],
					[59.218391647200939, 2.492749800230472],
					[59.216358421249957, 2.4927775973398303],
					[59.215889077883141, 2.4918721081596606],
					[59.215889061403999, 2.4918720849564102],
					[59.215889023354798, 2.4918720447936886],
					[59.215889000805085, 2.4918720262645944],
					[59.214869500574764, 2.4912776262045662],
					[59.214869470344915, 2.4912776161718817],
					[59.214869406666203, 2.4912776037491806],
					[59.214869374109561, 2.4912776011886217],
					[59.210888874397334, 2.4917942020299595],
					[59.21088882585056, 2.4917942218478437],
					[59.210888745236119, 2.4917942846835963],
					[59.210888714060637, 2.4917943275309504],
					[59.210347145942272, 2.4931717911458886],
					[59.20956125986433, 2.492116580513605],
					[59.209561195766412, 2.4921165418223379],
					[59.209561056810443, 2.492116519192825],
					[59.209560983824247, 2.4921165366534259],
					[59.208469353399501, 2.4928969866435637],
					[59.206986292577326, 2.4926137331741045],
					[59.206897400350798, 2.4904832910716599],
					[59.206897394315618, 2.4904832606095155],
					[59.206897372518476, 2.4904832033006792],
					[59.206897357648678, 2.4904831762834583],
					[59.204072358403849, 2.4868665766543456],
					[59.204072269148028, 2.4868665392762517],
					[59.204072100627016, 2.4868665556928069],
					[59.20407202225401, 2.4868666093168419],
					[59.203605520797176, 2.487788613800618],
					[59.202430706791091, 2.4870442308059943],
					[59.202430641152759, 2.4870442152564309],
					[59.202430515002547, 2.4870442323507205],
					[59.202430455295378, 2.4870442630845204],
					[59.201263813878128, 2.4882804008880872],
					[59.200000200446972, 2.4882831000110803],
					[59.200000199982036, 2.4847749999880011],
					[59.200000195817573, 2.4847749709311175],
					[59.200000177674468, 2.4847749146942304],
					[59.200000164588026, 2.4847748873436073],
					[59.199016864669332, 2.4833331868505093],
					[59.199016828840051, 2.4833331550712092],
					[59.199016747438108, 2.4833331126926153],
					[59.199016699993528, 2.4833331006953481],
					[59.197391786653164, 2.483333100639094],
					[59.193180746382012, 2.4788081634741626],
					[59.193180685395369, 2.4788081330073597],
					[59.193180555797937, 2.478808117426103],
					[59.193180489059131, 2.4788081337091792],
					[59.191544587592418, 2.4798996332622303],
					[59.191455799705118, 2.4771610935541379],
					[59.191455734759387, 2.4771610024016746],
					[59.191455562122236, 2.4771609371285361],
					[59.191455452646466, 2.4771609633494256],
					[59.190597219553041, 2.4780857834802057],
					[59.189736287050117, 2.4768832169986124],
					[59.189336295572915, 2.4750610594427553],
					[59.188777996308822, 2.4721749621403144],
					[59.188777990883459, 2.472174943868731],
					[59.188777978336041, 2.4721749094060428],
					[59.18877796853748, 2.4721748937275732],
					[59.188305799534426, 2.4714221415039184],
					[59.188294599872286, 2.4685305990270958],
					[59.188294593744601, 2.4685305668468671],
					[59.188294571587591, 2.4685305026284179],
					[59.188294554841598, 2.4685304742385297],
					[59.187194567659056, 2.4671748907982152],
					[59.186091876936416, 2.4650610079709807],
					[59.186091850024823, 2.4650609745118439],
					[59.186091782541446, 2.4650609225002627],
					[59.18609174384175, 2.4650609053442656],
					[59.184216754648297, 2.4646359078031028],
					[59.181714045694768, 2.4637609392624014],
					[59.181080781085683, 2.4623999154129934],
					[59.181080743832958, 2.4623998734127373],
					[59.181080651733566, 2.4623998156028226],
					[59.18108059688695, 2.4623997997931593],
					[59.180377832109144, 2.4624108997025518],
					[59.179127866451381, 2.4619748112720807],
					[59.179127792767517, 2.4619748148713203],
					[59.179127669270244, 2.4619748666335046],
					[59.179127616955945, 2.4619749187860771],
					[59.177963823525658, 2.4645802794649088],
					[59.176319526811611, 2.4632359443711964],
					[59.176319451006428, 2.4632359238051937],
					[59.176319306929514, 2.4632359426501713],
					[59.176319238657761, 2.4632359820611733],
					[59.175074855796119, 2.4649304583297225],
					[59.172427822694956, 2.4669497315216078],
					[59.171172346229305, 2.4655998641271046],
					[59.171172278819569, 2.4655998314219909],
					[59.171172140650818, 2.4655998245559614],
					[59.171172069804037, 2.4655998486572237],
					[59.1706278258049, 2.4660636151093982],
					[59.168194554745128, 2.4630581748882969],
					[59.168194481630763, 2.4630581362659609],
					[59.168194332668314, 2.4630581297240823],
					[59.168194256732441, 2.4630581600668586],
					[59.165313932432326, 2.4659940819483923],
					[59.164527967818088, 2.4647887908530564],
					[59.164527943129897, 2.4647887657596552],
					[59.164527885987276, 2.4647887223234157],
					[59.164527853708378, 2.4647887074555199],
					[59.162494483711257, 2.4642137153170189],
					[59.160691922819801, 2.4630249077285291],
					[59.160847395660582, 2.4622611399057615],
					[59.160847394182447, 2.4622610928284416],
					[59.160847369009936, 2.4622610046847484],
					[59.160847346207717, 2.46226096344743],
					[59.159591846335402, 2.4609109646504459],
					[59.159591772591902, 2.4609109314238249],
					[59.159591625852812, 2.4609109332344956],
					[59.159591551789475, 2.4609109649683512],
					[59.158888895635627, 2.4616830067429416],
					[59.157166844282095, 2.4598859624567768],
					[59.157166782312103, 2.4598859304857474],
					[59.157166652889337, 2.4598859184578372],
					[59.157166586240933, 2.4598859364928902],
					[59.155605549378571, 2.4609747905473283],
					[59.153788925784966, 2.4607331017083491],
					[59.15378890232541, 2.4607331009420443],
					[59.153788854865326, 2.4607331065279938],
					[59.153788830864812, 2.4607331128802619],
					[59.150894535930902, 2.4617941362514189],
					[59.150189092903254, 2.4592110476839397],
					[59.150188996356555, 2.4592109732459795],
					[59.150188802786239, 2.4592109752667395],
					[59.150188705674793, 2.4592110499887565],
					[59.15013042172108, 2.4594359896993625],
					[59.149727670488772, 2.4597804487772059],
					[59.149727658437612, 2.4597804598538224],
					[59.149727636383027, 2.4597804868752804],
					[59.149727626291799, 2.4597805010834235],
					[59.14914982564823, 2.4608055015158379],
					[59.149149824843867, 2.4608055034234173],
					[59.149149823235156, 2.4608055072385873],
					[59.149149821538629, 2.4608055093170709],
					[59.148574853770533, 2.4619415456270004],
					[59.147474979782324, 2.4624108913813374],
					[59.146488940845636, 2.4622026032412303],
					[59.146488892317684, 2.4622026055210338],
					[59.146488800951495, 2.4622026335380167],
					[59.146488758917627, 2.4622026573678064],
					[59.145680602418658, 2.4630025167494902],
					[59.144811298766577, 2.4621138186992364],
					[59.144802999710841, 2.4612138981151452],
					[59.144802995454192, 2.4612138673715087],
					[59.14480297436279, 2.4612138065399525],
					[59.144802959400174, 2.4612137778467105],
					[59.143519574143035, 2.4595387992338589],
					[59.142761291646188, 2.4577471414624767],
					[59.141636296025943, 2.4520110615440776],
					[59.141636288462294, 2.4520110366976984],
					[59.141636265393586, 2.4520109902809728],
					[59.141636249888549, 2.4520109687106251],
					[59.13976955497057, 2.4498970731707645],
					[59.139186266331073, 2.4491165861889193],
					[59.138427970069053, 2.4478887955732551],
					[59.138427961248162, 2.4478887814890027],
					[59.138427940213731, 2.4478887574773296],
					[59.138427928892341, 2.4478887473787561],
					[59.133950133235004, 2.4441248502465802],
					[59.131447335682225, 2.4417970536376501],
					[59.131447308666004, 2.4417970360405064],
					[59.131447252397379, 2.4417970100386608],
					[59.131447221272673, 2.441797000240773],
					[59.130519420777453, 2.4416998005420698],
					[59.130519364573324, 2.4416998113325015],
					[59.130519268023768, 2.4416998614149374],
					[59.130519226786184, 2.4416999008782039],
					[59.130058242645468, 2.4424968992090172],
					[59.129361224374634, 2.4419442435485075],
					[59.129361143126403, 2.441944222343106],
					[59.129360994228762, 2.4419442526795581],
					[59.129360925599173, 2.4419443026570664],
					[59.128783252391436, 2.4429663776092849],
					[59.127619600335926, 2.4418582148036818],
					[59.127614099936871, 2.4402806215084354],
					[59.128239094765142, 2.4375639457913714],
					[59.128239093014592, 2.4375638935539161],
					[59.128239064798322, 2.4375637990839683],
					[59.128239039224745, 2.4375637566800865],
					[59.127891838598728, 2.4372304557755289],
					[59.127891774124052, 2.4372304278549017],
					[59.127891644072875, 2.4372304212936169],
					[59.127891576623995, 2.4372304412601862],
					[59.125522241079622, 2.4390747137998252],
					[59.124005763161534, 2.4369554838548999],
					[59.124005758348538, 2.4369554777703963],
					[59.124005748810681, 2.4369554673367251],
					[59.124005744977907, 2.4369554628161976],
					[59.121036293198259, 2.4338471125691545],
					[59.120975199106041, 2.4331721827696837],
					[59.120975190118152, 2.433172147702936],
					[59.120975158936936, 2.4331720836112534],
					[59.120975137635718, 2.4331720544148738],
					[59.120158437936198, 2.4323970552771415],
					[59.120158351961869, 2.432397029750649],
					[59.120158192989265, 2.4323970567982434],
					[59.12015812088314, 2.4323971092008532],
					[59.119930517928729, 2.4328497179603499],
					[59.118647302862421, 2.43208312773677],
					[59.118647232043401, 2.4320831168191406],
					[59.118647100902706, 2.4320831420217366],
					[59.118647039777052, 2.4320831800484557],
					[59.118305592517835, 2.4325385752840556],
					[59.117489054737632, 2.4315387736250447],
					[59.117488967253969, 2.4315387361289122],
					[59.11748879981176, 2.4315387560457418],
					[59.117488720568993, 2.4315388098172952],
					[59.11720276070276, 2.4321079337401477],
					[59.116036256307119, 2.430660974386563],
					[59.116036165254812, 2.4306609375798991],
					[59.116035996116729, 2.4306609595775068],
					[59.116035917138852, 2.430661018553244],
					[59.115172081256496, 2.4325914753728557],
					[59.114013935305401, 2.4323859035807796],
					[59.114013874465293, 2.4323859117668447],
					[59.114013768909999, 2.432385961827253],
					[59.114013725086906, 2.4323860035301923],
					[59.112519225242913, 2.435113804023306],
					[59.112519220330732, 2.435113813724926],
					[59.112519210594471, 2.4351138348628978],
					[59.112519208446756, 2.4351138457852692],
					[59.111774807954681, 2.4377193443963572],
					[59.111774803658804, 2.4377193662403216],
					[59.111774801399967, 2.4377194104642044],
				],
			],
		},
		{
			fldNpdidField: "5506919",
			wlbNpdidWellbore: "1521",
			fldName: "OSELVAR",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "1/3-6",
			fldDiscoveryYear: "1991",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=5506919",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=5506919",
			fldOwnerKind: "",
			fldOwnerName: "",
			cmpLongName: "",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=1521",
			fldHcType: "GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "",
			fldNpdidOwner: "",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[56.899522001386494, 2.7543693763249011],
					[56.899522042018354, 2.7543694682541076],
					[56.899522183164088, 2.754369571318473],
					[56.899522283596085, 2.7543695808217343],
					[56.901822107599472, 2.7533002634880162],
					[56.903560867657006, 2.7567999823476752],
					[56.902222035022717, 2.7587387858253227],
					[56.902222019610235, 2.7587388196748046],
					[56.902222004211687, 2.7587388897279146],
					[56.902222001546647, 2.7587389263801243],
					[56.902510901850349, 2.7608500266467675],
					[56.90251095887227, 2.760850104326138],
					[56.902511111936143, 2.7608501758039132],
					[56.902511207896332, 2.7608501679702564],
					[56.904999927644454, 2.7592502845680538],
					[56.906158122121603, 2.7613500927709249],
					[56.907508093749414, 2.7641500347550378],
					[56.907130402281616, 2.7671388747559136],
					[56.90713040407924, 2.7671389106657931],
					[56.907130421069681, 2.767138980245273],
					[56.907130436262491, 2.767139013914873],
					[56.908099785281649, 2.7685389400525611],
					[56.907427605067795, 2.7713499529645822],
					[56.907427603286955, 2.7713499713675649],
					[56.907427600699926, 2.7713500096565888],
					[56.90742760159813, 2.771350027611815],
					[56.907822001678994, 2.7741611275758911],
					[56.907822043833519, 2.7741611962474946],
					[56.907822164175656, 2.7741612798383262],
					[56.907822243256277, 2.7741612946083838],
					[56.910308021214014, 2.773619664195218],
					[56.909358110684344, 2.7771305342603041],
					[56.907927617946122, 2.7804805175384004],
					[56.905719219653307, 2.7850499133198157],
					[56.905719211051959, 2.7850499394418162],
					[56.905719202131074, 2.7850499952423537],
					[56.905719200837034, 2.7850500234375413],
					[56.906210901285419, 2.7892611234420461],
					[56.906210970307413, 2.7892612074002732],
					[56.906211142353271, 2.7892612626694646],
					[56.906211247244656, 2.789261235315097],
					[56.908988636425718, 2.786261678885241],
					[56.908419200783491, 2.7915305795676368],
					[56.908419212725548, 2.7915306384786316],
					[56.908419270950453, 2.7915307407039411],
					[56.908419316177401, 2.7915307809022267],
					[56.911399810995533, 2.7929195328433032],
					[56.911799799841013, 2.7973111065772307],
					[56.911708100366262, 2.8023999957152181],
					[56.911708134874651, 2.8024000739263992],
					[56.911708250654002, 2.8024001748345331],
					[56.911708331924956, 2.8024001975315014],
					[56.913922231748046, 2.8020390968453825],
					[56.913922256591512, 2.8020390894204401],
					[56.913922304167244, 2.8020390683369807],
					[56.913922326006436, 2.8020390548270186],
					[56.9180418257694, 2.7986807558367581],
					[56.918041835267871, 2.798680747670121],
					[56.918041849718172, 2.7986807304473289],
					[56.918041858323591, 2.7986807224293497],
					[56.920150141097118, 2.7959695435347371],
					[56.92361112867728, 2.7934002283034323],
					[56.925030523794568, 2.7939807850082308],
					[56.925030608311417, 2.7939807824517615],
					[56.92503074169592, 2.7939807124714218],
					[56.925030794298564, 2.7939806477185551],
					[56.92541126088679, 2.7924307833703845],
					[56.928211120883958, 2.7921307992005953],
					[56.928211190132593, 2.792130761307241],
					[56.92821127804438, 2.7921306461950826],
					[56.928211297681997, 2.7921305704606669],
					[56.9274307981606, 2.7868610721903635],
					[56.926978008402806, 2.7831306550276915],
					[56.928619379181171, 2.7809309632882147],
					[56.93030812726758, 2.7838612005083334],
					[56.930308132140333, 2.7838612079295673],
					[56.930308143834949, 2.7838612257405262],
					[56.930308151386818, 2.7838612327145063],
					[56.932330451061553, 2.7861307337972732],
					[56.9323305148, 2.7861307659824979],
					[56.932330648762736, 2.786130779859731],
					[56.932330718987039, 2.7861307615517332],
					[56.933289019207834, 2.7854195612847645],
					[56.933289043965033, 2.7854195340933932],
					[56.933289082437007, 2.7854194749655257],
					[56.933289096070276, 2.7854194413955686],
					[56.934341869076583, 2.7805001654322434],
					[56.936550047319514, 2.7799612941049752],
					[56.936550099677739, 2.7799612606483204],
					[56.936550176216663, 2.7799611704403464],
					[56.936550200397342, 2.7799611136890157],
					[56.936827999564017, 2.7755611126805344],
					[56.936827998584491, 2.7755610930774544],
					[56.936827993946345, 2.7755610543192577],
					[56.936827990369387, 2.7755610367977228],
					[56.935189090101588, 2.7706499373686544],
					[56.935189089045188, 2.770649934251082],
					[56.935189088070487, 2.7706499327670095],
					[56.935189087988796, 2.7706499311335091],
					[56.933639088443392, 2.7664499313577373],
					[56.933639088361637, 2.7664499297243172],
					[56.933639086575631, 2.7664499300233563],
					[56.932189086685931, 2.7625888303870685],
					[56.932189073686445, 2.7625888045649969],
					[56.932189040870632, 2.7625887606510982],
					[56.932189020972515, 2.7625887409259007],
					[56.930369520723858, 2.761199840354251],
					[56.930369485726374, 2.7611998248076226],
					[56.930369410864294, 2.7611998044116834],
					[56.93036937205629, 2.7611998026792848],
					[56.92776953700578, 2.7615691779180169],
					[56.926900197213421, 2.7589388570077249],
					[56.926708498844349, 2.7571805780441729],
					[56.926708498435062, 2.7571805698787166],
					[56.926708495019341, 2.7571805556300153],
					[56.926708492824069, 2.7571805477639613],
					[56.926030795102605, 2.7547305532155026],
					[56.925639109332387, 2.7528000236865422],
					[56.926689081658388, 2.7505111828904631],
					[56.926689088793822, 2.7505111635779604],
					[56.92668909835394, 2.7505111208030559],
					[56.926689100049558, 2.7505111007566452],
					[56.926689099745886, 2.7469889224829154],
					[56.927350180917074, 2.7441807017942295],
					[56.928791840184061, 2.7427612435809809],
					[56.928791850325354, 2.7427612303483366],
					[56.928791868093072, 2.7427612075994796],
					[56.928791875555447, 2.7427611948170054],
					[56.930319544392361, 2.7399390544951121],
					[56.932238983591347, 2.7390501810838028],
					[56.932239013213106, 2.7390501612786791],
					[56.932239062870238, 2.7390501101039018],
					[56.932239082987699, 2.7390500803675084],
					[56.93386958270986, 2.7353500803949653],
					[56.933869585142268, 2.7353500750441637],
					[56.933869589114146, 2.7353500644928359],
					[56.9338695906536, 2.7353500592922937],
					[56.934630790771408, 2.7328806590985137],
					[56.934630795142901, 2.7328806385962254],
					[56.934630799338983, 2.7328805967099057],
					[56.934630799245731, 2.7328805769592499],
					[56.934239105620179, 2.729550039723454],
					[56.935200141345369, 2.7277890595439711],
					[56.937019473914972, 2.72706958533217],
					[56.937019506213375, 2.7270695650634029],
					[56.937019561140467, 2.7270695113296437],
					[56.937019583851402, 2.7270694794980965],
					[56.9377779841181, 2.7253111788394642],
					[56.937777991883287, 2.7253111363468205],
					[56.937777990447856, 2.7253110542215033],
					[56.937777980189736, 2.7253110114724177],
					[56.93624189733319, 2.7221610469517841],
					[56.93603909868429, 2.7193499854359504],
					[56.936039099330131, 2.7193499803851631],
					[56.936039096885388, 2.7193499676194288],
					[56.93603909566302, 2.7193499612365621],
					[56.935558503204916, 2.7168888941121243],
					[56.935927962280708, 2.7140809022124488],
					[56.937661010580314, 2.7149390795144375],
					[56.937661090063777, 2.7149390842142229],
					[56.937661226172096, 2.7149390332267611],
					[56.937661282961805, 2.7149389808063753],
					[56.939188950927587, 2.7114197852160586],
					[56.940441527895715, 2.7129890066411155],
					[56.941799818142179, 2.7159611828430315],
					[56.941799903916881, 2.7159612408513074],
					[56.941800080907015, 2.7159612505068393],
					[56.941800172932965, 2.7159612003697116],
					[56.943139073356484, 2.7136695016444028],
					[56.943139078947368, 2.713669487519168],
					[56.943139090294004, 2.7136694625360742],
					[56.943139094099052, 2.713669448712511],
					[56.94427799407832, 2.7090889481564742],
					[56.944277996097028, 2.7090889346339644],
					[56.944277999241521, 2.7090889077398819],
					[56.944278000284811, 2.7090888927346022],
					[56.944180806306861, 2.7062807786778595],
					[56.94600002126122, 2.7060890988045401],
					[56.94600008788364, 2.7060890628194638],
					[56.946000176933396, 2.7060889538359589],
					[56.946000199360739, 2.7060888808375108],
					[56.945703010023472, 2.7029306947627258],
					[56.948872237325958, 2.7006196202270156],
					[56.951080540693745, 2.7013001908212977],
					[56.951080630875957, 2.7013001755525079],
					[56.951080761009841, 2.7013000777129537],
					[56.95108079998603, 2.7012999936593989],
					[56.950869602412254, 2.6941000825370458],
					[56.952880738831027, 2.6921501446080285],
					[56.952880759911992, 2.6921501163124257],
					[56.952880789159956, 2.6921500536707477],
					[56.952880799195448, 2.692150020655899],
					[56.953158484282838, 2.6895112661645357],
					[56.954027840896465, 2.689330795999505],
					[56.954027888283875, 2.6893307714788959],
					[56.954027963149592, 2.6893307027417479],
					[56.954027990627893, 2.6893306585251882],
					[56.954880788300237, 2.6865111696403248],
					[56.956322161758152, 2.6831616926944504],
					[56.957480402989518, 2.687188938497584],
					[56.957969200859651, 2.69176112157654],
					[56.957969213642698, 2.6917611606155272],
					[56.957969255032346, 2.691761231064747],
					[56.957969282746049, 2.6917612626264273],
					[56.959899882721061, 2.693150162626075],
					[56.959899884589532, 2.6931501639575703],
					[56.959899889219386, 2.6931501664691342],
					[56.959899891087858, 2.6931501678006313],
					[56.961238790701735, 2.69401956853289],
					[56.961238884719215, 2.6940195756679972],
					[56.961239037202148, 2.6940195102486157],
					[56.961239095667651, 2.6940194376941076],
					[56.961911296096794, 2.6904889394158364],
					[56.962569594770606, 2.6875000424615809],
					[56.962569596473543, 2.687500040523997],
					[56.962569597035198, 2.6875000338346759],
					[56.962569597762453, 2.687500030414216],
					[56.962952930864226, 2.6850310623539602],
					[56.964297047918038, 2.6866112292842397],
					[56.964297161927938, 2.6866112577346537],
					[56.964297338430775, 2.6866111865510884],
					[56.964297399948059, 2.6866110854341967],
					[56.964000200082786, 2.6823888934458355],
					[56.963989100349437, 2.6792193986036654],
					[56.9639890890994, 2.6792193543561171],
					[56.963989047122375, 2.6792192724691031],
					[56.9639890165612, 2.6792192380985949],
					[56.962550218663864, 2.6781805118304871],
					[56.963300183186554, 2.6739501111891877],
					[56.964450122112552, 2.6730695596407203],
					[56.964450122029554, 2.6730695580062478],
					[56.964450122922393, 2.6730695578543182],
					[56.966280481089569, 2.6716392474920392],
					[56.967338705951043, 2.6758500493515309],
					[56.96733871311352, 2.6758500662691138],
					[56.967338729307102, 2.6758501014351368],
					[56.967338739148111, 2.6758501178970837],
					[56.968497038661212, 2.6774307178441168],
					[56.968497065153883, 2.6774307430164077],
					[56.96849712567726, 2.6774307821863643],
					[56.968497159707972, 2.6774307961840162],
					[56.970130559647785, 2.6777612966710884],
					[56.970130636266994, 2.6777612803425779],
					[56.970130753898999, 2.677761202627035],
					[56.970130795638731, 2.6777611378186683],
					[56.970877996399267, 2.6738611386026778],
					[56.970877996110261, 2.673861097429755],
					[56.970877978651217, 2.6738610196056718],
					[56.970877962374047, 2.6738609828025579],
					[56.969639097873888, 2.6721388335985585],
					[56.969528000703683, 2.6688000343621154],
					[56.970211279018685, 2.6670806922664596],
					[56.971939068000715, 2.6644001080990742],
					[56.971939079419819, 2.6644000847164682],
					[56.971939094782996, 2.6644000326295671],
					[56.971939099786105, 2.6644000070426448],
					[56.972011297662789, 2.6622694540117702],
					[56.972869576273851, 2.6606806941348276],
					[56.972869586838662, 2.6606806362672959],
					[56.972869573791634, 2.6606805214142466],
					[56.972869549370152, 2.6606804662157697],
					[56.971619609845398, 2.6592888344772776],
					[56.972091898536497, 2.6554111235193831],
					[56.972091880672657, 2.6554110556632087],
					[56.972091804874417, 2.6554109465781037],
					[56.972091746047198, 2.6554109055015824],
					[56.970650176256179, 2.6550692357050827],
					[56.970161298230998, 2.6520888704808057],
					[56.96966959838754, 2.6482193747100622],
					[56.969669583722009, 2.648219334345522],
					[56.969669538653839, 2.6482192629008758],
					[56.969669509144005, 2.6482192316682336],
					[56.967258408963602, 2.6466609318113754],
					[56.967258370382737, 2.6466609169708746],
					[56.967258291459345, 2.6466609057272663],
					[56.96725825005737, 2.6466609062077051],
					[56.963038849991385, 2.6477609058576488],
					[56.963038834087648, 2.6477609118717846],
					[56.963038804149072, 2.6477609252293304],
					[56.963038789221464, 2.6477609327252547],
					[56.960361046142481, 2.6495386956161582],
					[56.958338914791113, 2.649388701440786],
					[56.958338911136593, 2.6493887004164938],
					[56.958338903018074, 2.6493887001544958],
					[56.958338899363568, 2.6493886991301991],
					[56.954208299098568, 2.6494192006188206],
					[56.954208271338373, 2.6494192037103397],
					[56.954208218996143, 2.6494192192397916],
					[56.954208194330825, 2.6494192300437573],
					[56.952202693794554, 2.6506692305447266],
					[56.952202683413979, 2.6506692389089244],
					[56.952202660950483, 2.6506692575759625],
					[56.952202650653184, 2.6506692675740275],
					[56.948077660976566, 2.6552804553539877],
					[56.944819282855079, 2.6579498386595151],
					[56.941458193063418, 2.6600887317031137],
					[56.941458174812965, 2.6600887446979073],
					[56.941458144001551, 2.660088776308585],
					[56.941458129654954, 2.6600887952286789],
					[56.939172234709289, 2.6637995625725135],
					[56.937430785374616, 2.6595999228927187],
					[56.937430693077417, 2.6595998611871319],
					[56.937430507576117, 2.6595998614866598],
					[56.937430416074534, 2.6595999215543196],
					[56.93571098755308, 2.6636497514289168],
					[56.932152863099816, 2.6624609104587122],
					[56.932152780710233, 2.662460919543141],
					[56.932152651230773, 2.662460994295365],
					[56.932152604223958, 2.6624610615961939],
					[56.931399830036483, 2.6663304261736354],
					[56.930338865881986, 2.6665109035189944],
					[56.930338814118159, 2.6665109304429695],
					[56.930338733430332, 2.6665110084060171],
					[56.930338703613522, 2.6665110595969721],
					[56.92958040374409, 2.6702110601777749],
					[56.929580403100218, 2.670211065228393],
					[56.929580401085666, 2.6702110787473323],
					[56.929580401334668, 2.6702110836461506],
					[56.929119202944186, 2.6758305584597912],
					[56.926922012289438, 2.6819999325035071],
					[56.926922006950633, 2.6819999515255013],
					[56.926922000820184, 2.6819999904448557],
					[56.92692200002854, 2.6820000103422341],
					[56.927130394212114, 2.6862109760934838],
					[56.924819315342027, 2.6872887192780572],
					[56.924819275594182, 2.6872887523652196],
					[56.924819219330779, 2.6872888327162197],
					[56.924819201029479, 2.6872888802827917],
					[56.924358114766029, 2.6922109323225158],
					[56.922438861950035, 2.6925804033035297],
					[56.922438812775603, 2.6925804280988355],
					[56.922438736286196, 2.6925805003346324],
					[56.922438707102756, 2.6925805464448476],
					[56.921477613705129, 2.6960999210053109],
					[56.919572029381023, 2.6992692973104644],
					[56.919572026057267, 2.699269302812437],
					[56.919572020302667, 2.6992693136653219],
					[56.919572016896289, 2.6992693175346854],
					[56.91775813236471, 2.7033304861901799],
					[56.914210954795315, 2.7070387614644678],
					[56.91421095398492, 2.7070387632476938],
					[56.914210951388704, 2.7070387653325598],
					[56.914210949685376, 2.7070387672666003],
					[56.912491549648387, 2.7089887683371505],
					[56.912491531406403, 2.7089887994035236],
					[56.912491506132191, 2.7089888695209416],
					[56.912491499910416, 2.7089889067888975],
					[56.912688699085294, 2.7160110522623642],
					[56.911072055165505, 2.7190109484709648],
					[56.908858228757573, 2.7200804119372819],
					[56.906941646135529, 2.7206192062920636],
					[56.906941593876645, 2.7206192414335439],
					[56.906941522010904, 2.7206193341962943],
					[56.906941499725271, 2.720619392268949],
					[56.906849802911893, 2.7228999305906743],
					[56.904360938995104, 2.7262498815061962],
					[56.904360921406401, 2.7262499256135175],
					[56.904360907260134, 2.726250020163123],
					[56.904360913381389, 2.7262500701544461],
					[56.905419171791969, 2.7290610622764153],
					[56.90322204621755, 2.7316998720825745],
					[56.903222035348698, 2.7316998887227157],
					[56.903222017182799, 2.7316999214023339],
					[56.903222009074966, 2.7316999392239336],
					[56.901219208969138, 2.7380388398622175],
					[56.901219205407244, 2.7380388585633875],
					[56.901219201126551, 2.7380388987795254],
					[56.901219201136506, 2.738038916880646],
					[56.901710900667851, 2.7436500166573632],
					[56.90171090660894, 2.7436500452832022],
					[56.901710928149782, 2.743650097622246],
					[56.901710941963593, 2.7436501216352274],
					[56.903060854805034, 2.745400008466631],
					[56.9013276331236, 2.7480387907673385],
					[56.901327627364459, 2.7480388016074864],
					[56.90132761495321, 2.7480388234375557],
					[56.901327611061987, 2.7480388356101204],
					[56.899710911117289, 2.7527888355855996],
					[56.899710908036667, 2.7527888459756009],
					[56.899710902768447, 2.7527888666059468],
					[56.899710901473796, 2.752788876696644],
					[56.899522001386494, 2.7543693763249011],
				],
			],
		},
		{
			fldNpdidField: "4467595",
			wlbNpdidWellbore: "205",
			fldName: "VEGA",
			fldCurrentActivitySatus: "Producing",
			wlbName: "35/8-1",
			fldDiscoveryYear: "1981",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=4467595",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=4467595",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "VEGA UNIT",
			cmpLongName: "Wintershall Dea Norge AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=205",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "35318843",
			fldNpdidOwner: "20459640",
			fldDateUpdated: "2022-09-13 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Florø",
			fldAreaGeometryWKT: [
				[
					[61.339583108234564, 3.3592972264487324],
					[61.339583144344111, 3.3592973154935715],
					[61.33958321793893, 3.3592973752906974],
					[61.339583261981467, 3.3592973954503584],
					[61.345449923575494, 3.3604334890522707],
					[61.351880457366555, 3.3645418466461221],
					[61.355627633908931, 3.3701668104002986],
					[61.355627653751753, 3.3701668330701291],
					[61.355627700275306, 3.3701668696789895],
					[61.355627725246499, 3.3701668858007374],
					[61.358360934166072, 3.3712668482414734],
					[61.359830406933831, 3.3765111547323383],
					[61.359830436790361, 3.3765112018550076],
					[61.359830517172533, 3.3765112736305025],
					[61.359830566727595, 3.3765112965831432],
					[61.364444366810218, 3.3772779972779681],
					[61.364444374927857, 3.3772779976917522],
					[61.364444390346932, 3.3772780005414407],
					[61.364444398387363, 3.3772779990939474],
					[61.37402219820391, 3.3773946001755695],
					[61.374022203564188, 3.3773945992102115],
					[61.374022217935497, 3.3773945984977671],
					[61.374022224266369, 3.3773945992333809],
					[61.382005624086503, 3.3764195992805872],
					[61.382005701821065, 3.3764195421203964],
					[61.382005775694616, 3.3764193918439425],
					[61.382005770862932, 3.3764192970263229],
					[61.379052972412858, 3.3715137977879412],
					[61.374741876063929, 3.364097104421607],
					[61.372416878865621, 3.3594360114047728],
					[61.372416878788172, 3.3594360095430758],
					[61.372416877817386, 3.3594360078427519],
					[61.372416876846586, 3.3594360061424515],
					[61.369977996895486, 3.3547749458288472],
					[61.369650200052376, 3.3495443945462311],
					[61.369664099727025, 3.3436056005141532],
					[61.369664099183389, 3.3436055874837565],
					[61.369664096309478, 3.3436055617465961],
					[61.369664093085845, 3.3436055492016861],
					[61.367630792651376, 3.3358638496388586],
					[61.367630773967505, 3.3358638117680317],
					[61.367630719937765, 3.3358637465504097],
					[61.367630686456295, 3.3358637207408841],
					[61.363305685730793, 3.3337970198908229],
					[61.363305651084687, 3.3337970092995919],
					[61.363305578026043, 3.3337970056771411],
					[61.363305542137851, 3.3337970084375894],
					[61.358797142422148, 3.3351748084997426],
					[61.358797115933697, 3.3351748208028376],
					[61.35879706862773, 3.3351748518803626],
					[61.358797047810263, 3.3351748706547473],
					[61.352280448428218, 3.3428220698953122],
					[61.345591549056266, 3.3505831683567782],
					[61.345591544822177, 3.3505831747443637],
					[61.345591538801415, 3.3505831814548661],
					[61.34559153538315, 3.3505831858209065],
					[61.33958313482087, 3.3592970867442049],
					[61.339583117841109, 3.3592971329032411],
					[61.339583108234564, 3.3592972264487324],
				],
				[
					[61.233952636802705, 3.354563961922282],
					[61.233952672173324, 3.3545640545362541],
					[61.242219264615663, 3.361439047685975],
					[61.248208156761521, 3.3675029391398534],
					[61.255258155757609, 3.374686239586024],
					[61.259805436595641, 3.3794751196355026],
					[61.264038725355789, 3.387102896196577],
					[61.264038728266968, 3.3871029012830296],
					[61.264038734089389, 3.3871029114559583],
					[61.264038737000568, 3.3871029165424114],
					[61.266991522021335, 3.3912444934084269],
					[61.268577611750246, 3.3955250698280857],
					[61.268577620559817, 3.3955250869476687],
					[61.268577640782397, 3.3955251188521252],
					[61.268577652272228, 3.3955251354926625],
					[61.270469252122446, 3.3976029354057662],
					[61.270469318216634, 3.3976029665954064],
					[61.270469453690666, 3.3976029779106955],
					[61.270469523963889, 3.3976029578767064],
					[61.272833423828502, 3.395736257133195],
					[61.272833426431916, 3.3957362547981771],
					[61.272833429928703, 3.3957362523034638],
					[61.272833431715561, 3.3957362519840637],
					[61.276566832198682, 3.3924695503793165],
					[61.276566838221839, 3.3924695436922172],
					[61.276566851161547, 3.3924695301581886],
					[61.27656685718469, 3.3924695234710733],
					[61.280380738636119, 3.3876195469607762],
					[61.285255692262645, 3.384205779386233],
					[61.290505621269631, 3.3823752041438837],
					[61.298252773104878, 3.3834168982742288],
					[61.298252783902775, 3.3834168982089876],
					[61.29825280378877, 3.3834169002563281],
					[61.29825281361618, 3.3834168984939303],
					[61.305780614049915, 3.3828751984536769],
					[61.305780645318855, 3.3828751928437635],
					[61.305780702773141, 3.3828751675627431],
					[61.305780730899414, 3.3828751512867341],
					[61.307227931303885, 3.3816279519120243],
					[61.307227941639169, 3.381627940698547],
					[61.307227963048895, 3.3816279143954717],
					[61.3072279733071, 3.381627901324094],
					[61.308602959409242, 3.3792751240903582],
					[61.310814041143438, 3.3770890413989822],
					[61.310814052294532, 3.3770890281641051],
					[61.310814071023159, 3.3770890023362732],
					[61.31081408031033, 3.3770889875642371],
					[61.312419580381103, 3.373786187391687],
					[61.312419585278164, 3.3737861752790446],
					[61.312419593285533, 3.3737861513749259],
					[61.312419596318612, 3.3737861377252716],
					[61.313497395688366, 3.3682667379525855],
					[61.313497397982054, 3.3682667281791678],
					[61.313497399889371, 3.3682667091145655],
					[61.313497399580235, 3.3682667016816383],
					[61.313503000416688, 3.3663694007423608],
					[61.313502956426795, 3.3663693169253377],
					[61.313502819337266, 3.3663692236559246],
					[61.313502725344264, 3.3663692143643575],
					[61.311600000789419, 3.3671386845772062],
					[61.308866792075904, 3.3659998215162417],
					[61.303480705691015, 3.3626165302950484],
					[61.303480698311972, 3.3626165260089462],
					[61.303480680873896, 3.3626165179192387],
					[61.303480671708137, 3.3626165139548054],
					[61.299683409565453, 3.3611526283211868],
					[61.296727939885329, 3.3582720570067921],
					[61.296727937050413, 3.3582720537751314],
					[61.296727930564678, 3.3582720493299618],
					[61.296727926836397, 3.3582720462592262],
					[61.29323903330593, 3.3553887505552802],
					[61.29073624157094, 3.35299155820982],
					[61.28831124481151, 3.3504359628736462],
					[61.288311236306285, 3.3504359531833243],
					[61.288311217741693, 3.3504359396949281],
					[61.288311208420659, 3.3504359320223793],
					[61.285805708405, 3.3488304317936373],
					[61.285805682693486, 3.3488304195961258],
					[61.285805629871383, 3.3488304048060415],
					[61.285805601789932, 3.3488304005180312],
					[61.28314720217643, 3.3487998008635484],
					[61.28314719673881, 3.3487997999737793],
					[61.283147186834441, 3.3487997998895218],
					[61.283147182367692, 3.3487998006950299],
					[61.276836101401962, 3.3493608996110833],
					[61.271669425606802, 3.3488304029134173],
					[61.266427832103524, 3.3479831030555758],
					[61.266427825772588, 3.3479831023272766],
					[61.26642781311061, 3.3479831008706951],
					[61.266427805886295, 3.3479831003034137],
					[61.258675005600153, 3.3477386992001752],
					[61.258675002920086, 3.3477386996830822],
					[61.258674994802398, 3.3477386992768579],
					[61.258674991229007, 3.3477386999207299],
					[61.252897191016196, 3.3479914999900897],
					[61.252897181189326, 3.3479915017602431],
					[61.252897158778033, 3.347991503928728],
					[61.252897149028691, 3.3479915075534685],
					[61.24430274942651, 3.3502637068000887],
					[61.244302742279636, 3.3502637080865019],
					[61.244302729850148, 3.3502637121918095],
					[61.244302722780766, 3.350263715332312],
					[61.233952722793539, 3.354563714903307],
					[61.233952663044874, 3.3545637928719554],
					[61.233952636802705, 3.354563961922282],
				],
				[
					[61.131130429036297, 3.422588900186442],
					[61.131130429741248, 3.4225890042888785],
					[61.13488043037394, 3.4286890058478803],
					[61.134880451099328, 3.4286890282458677],
					[61.134880499621744, 3.4286890699310089],
					[61.13488052912939, 3.4286890870545235],
					[61.135199860362853, 3.4288112618374238],
					[61.1361081250772, 3.4304611951745545],
					[61.136108134775192, 3.4304612120763718],
					[61.136108159303213, 3.4304612393890852],
					[61.136108171605358, 3.4304612539694483],
					[61.137049859396626, 3.4312501437328002],
					[61.139110931430444, 3.4336195109368202],
					[61.140238719594095, 3.4359806853111916],
					[61.140238735033613, 3.4359807105120468],
					[61.140238772679488, 3.4359807504109332],
					[61.140238794221027, 3.4359807708114269],
					[61.141841566623462, 3.4369807531316345],
					[61.143341546834918, 3.4387612282680671],
					[61.143341561816761, 3.4387612423819975],
					[61.143341595278308, 3.4387612681308872],
					[61.143341612864496, 3.4387612799234843],
					[61.145419284603427, 3.439761266386864],
					[61.147299853236206, 3.4415390365114429],
					[61.149360933893256, 3.4441112128264315],
					[61.151519209914369, 3.4478500707118327],
					[61.152449804271683, 3.4527389361338101],
					[61.152449804423703, 3.4527389398318769],
					[61.152449803606189, 3.4527389418381977],
					[61.152449804575731, 3.4527389435299214],
					[61.153277605051173, 3.4564611438722288],
					[61.153277605127137, 3.456461145721311],
					[61.153277606172573, 3.4564611492623025],
					[61.153277607218044, 3.4564611528032709],
					[61.154030404768513, 3.4592111463250048],
					[61.154677599224712, 3.4623389172565089],
					[61.154572000096856, 3.4656610940578658],
					[61.154572016896189, 3.4656611525700596],
					[61.15457208019285, 3.4656612476156163],
					[61.154572126765977, 3.4656612859981548],
					[61.156649883281645, 3.4664695697088121],
					[61.15805812992938, 3.4680501098129422],
					[61.159272016434123, 3.4708111779248823],
					[61.160299808178777, 3.4733611580979362],
					[61.160949803931302, 3.4764889417972213],
					[61.160949804900568, 3.4764889434901334],
					[61.160949806990473, 3.4764889505751713],
					[61.160949808035397, 3.476488954117702],
					[61.162072007856857, 3.4804194556093502],
					[61.162072017851472, 3.480419479939052],
					[61.162072043944413, 3.4804195238030897],
					[61.162072060042711, 3.4804195433374101],
					[61.163288760217604, 3.4816112427324017],
					[61.163288776088898, 3.4816112567187978],
					[61.163288811178816, 3.4816112785161728],
					[61.163288828610376, 3.4816112866402191],
					[61.164891604091736, 3.4822195772919726],
					[61.167338781720083, 3.4840112619191297],
					[61.167338783582807, 3.4840112634561233],
					[61.167338790064598, 3.4840112679105428],
					[61.167338791927335, 3.4840112694475169],
					[61.169219285053842, 3.485211264373234],
					[61.171291577658003, 3.4868112571619516],
					[61.171291601646509, 3.4868112715964985],
					[61.171291654682804, 3.4868112921259029],
					[61.17129168194338, 3.486811298533679],
					[61.173838882336057, 3.4870390990670326],
					[61.173838885910349, 3.4870390984411728],
					[61.173838893210039, 3.4870391008902422],
					[61.173838896784332, 3.4870391002643846],
					[61.177661096994306, 3.4871002006678853],
					[61.177661172116913, 3.4871001670097264],
					[61.177661272887022, 3.4871000561701675],
					[61.177661299428088, 3.4870999788322803],
					[61.17761962271441, 3.4867113026733252],
					[61.178180597410204, 3.4867195993485987],
					[61.178180657795359, 3.4867195794532972],
					[61.178180755608565, 3.48671950640591],
					[61.178180793036631, 3.486719453253857],
					[61.178558492621249, 3.4853611543058172],
					[61.178558494924062, 3.4853611445828969],
					[61.178558497893654, 3.4853611291514603],
					[61.178558499378468, 3.4853611214357567],
					[61.178950199367122, 3.4816500203077263],
					[61.17895019914021, 3.4816500147557141],
					[61.17895019957998, 3.4816500034950484],
					[61.178950200246625, 3.481649997786405],
					[61.178877999820912, 3.47578890020336],
					[61.179000200129572, 3.4675806041363253],
					[61.179219599977543, 3.4597694068944174],
					[61.179219599825679, 3.4597694031931727],
					[61.1792195996738, 3.4597693994919467],
					[61.179219599597879, 3.4597693976413337],
					[61.179150199955295, 3.453130597093538],
					[61.179150199955295, 3.453130597093538],
					[61.179069600316382, 3.4478499962694462],
					[61.179069600240275, 3.4478499944188807],
					[61.179069600011992, 3.4478499888671807],
					[61.17906959985978, 3.4478499851660311],
					[61.178711300295546, 3.4425693856518196],
					[61.178711292822285, 3.4425693571475846],
					[61.178711272667044, 3.4425693047867187],
					[61.178711259015444, 3.4425692792372855],
					[61.177211274932183, 3.4405887990987334],
					[61.176077984947909, 3.4378388233168446],
					[61.176077983978182, 3.4378388216243363],
					[61.176077982932213, 3.4378388180814894],
					[61.176077981962464, 3.4378388163890032],
					[61.174111281756275, 3.4335193174320535],
					[61.172150181848913, 3.4291888166803801],
					[61.172150179909245, 3.4291888132963542],
					[61.172150175953568, 3.429188804678184],
					[61.1721501740139, 3.4291888012941589],
					[61.170927973690326, 3.4270305010421391],
					[61.17092793157412, 3.4270304619079242],
					[61.170927834115325, 3.4270304120701307],
					[61.170927777879157, 3.4270304015247266],
					[61.170658391017817, 3.4270608886432701],
					[61.168461260219914, 3.4241387796873841],
					[61.168461245160074, 3.424138763719994],
					[61.168461214376208, 3.4241387374930414],
					[61.16846119589529, 3.4241387258582265],
					[61.168208395909851, 3.423999825798441],
					[61.168208349425427, 3.4239998116689181],
					[61.168208254922298, 3.4239998116323918],
					[61.168208207720717, 3.4239998237173253],
					[61.167727891210959, 3.4242497268856069],
					[61.166600180058389, 3.4218888147508593],
					[61.166600176102229, 3.4218888061351183],
					[61.166600168266449, 3.4218887907533921],
					[61.166600162599813, 3.4218887843039183],
					[61.165200165023144, 3.419919287361004],
					[61.164161277981755, 3.4183388071674061],
					[61.163130785341288, 3.4157888247118984],
					[61.163130730841793, 3.4157887691659772],
					[61.163130598151469, 3.4157887163045375],
					[61.163130519884113, 3.4157887171395052],
					[61.161811020204226, 3.4163609166798325],
					[61.161811005244779, 3.4163609249207791],
					[61.161810976295932, 3.4163609430937187],
					[61.161810962383029, 3.4163609548751883],
					[61.160388839669061, 3.417710880992026],
					[61.158508344276818, 3.4172887041586502],
					[61.158508294012968, 3.4172887074777538],
					[61.158508199687581, 3.4172887335079176],
					[61.158508156672511, 3.4172887597591712],
					[61.157180492179123, 3.4186387244046479],
					[61.155391749039524, 3.4191997755978445],
					[61.154072333606109, 3.4180109519209334],
					[61.154072315941768, 3.4180109382872925],
					[61.154072277268611, 3.4180109172002204],
					[61.154072256183355, 3.418010907897771],
					[61.152661155645049, 3.417599807330145],
					[61.152661152964626, 3.4175998078050194],
					[61.152661145663885, 3.4175998053734764],
					[61.1526611420135, 3.4175998041577151],
					[61.150769442398143, 3.4171887042048636],
					[61.150769395631627, 3.4171887050406848],
					[61.150769310164343, 3.4171887276318076],
					[61.150769268783151, 3.4171887498619693],
					[61.149447068749893, 3.4183387494176092],
					[61.149447060938066, 3.4183387563880889],
					[61.149447046284386, 3.4183387720195131],
					[61.149447038625524, 3.4183387826874712],
					[61.1483110002562, 3.4198886983648382],
					[61.146419402608977, 3.4198608999168787],
					[61.14641938287604, 3.4198609015484092],
					[61.146419343563068, 3.4198609085086065],
					[61.146419324876526, 3.4198609136790861],
					[61.144058225007456, 3.4208109143806458],
					[61.144058223220505, 3.4208109146969319],
					[61.144058218829542, 3.420810917336087],
					[61.144058217042591, 3.4208109176523749],
					[61.141980557926239, 3.421760898343448],
					[61.138591709297152, 3.4217109000471235],
					[61.135280615402856, 3.4214692001873521],
					[61.13528058755211, 3.4214692013916252],
					[61.135280536700179, 3.4214692122494292],
					[61.135280512729068, 3.4214692202131309],
					[61.132769360427552, 3.4226886977423017],
					[61.131130611577817, 3.4225887003966955],
					[61.131130519954574, 3.4225887482403441],
					[61.131130429036297, 3.422588900186442],
				],
			],
		},
		{
			fldNpdidField: "33197696",
			wlbNpdidWellbore: "6776",
			fldName: "NOVA",
			fldCurrentActivitySatus: "Producing",
			wlbName: "35/9-7",
			fldDiscoveryYear: "2012",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=33197696",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=33197696",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "418",
			cmpLongName: "Wintershall Dea Norge AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=6776",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "35318843",
			fldNpdidOwner: "4238348",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Florø",
			fldAreaGeometryWKT: [
				[
					[61.238016500395943, 3.7264082900439113],
					[61.238016504452013, 3.7264083248364814],
					[61.238016524040361, 3.726408388760746],
					[61.23801653875109, 3.7264084198973833],
					[61.240141519957362, 3.7292833946888719],
					[61.242058110217172, 3.7349750646724162],
					[61.242058207329585, 3.7349751324178637],
					[61.242058397032103, 3.7349751286225659],
					[61.242058490588335, 3.7349750587871373],
					[61.242102978682126, 3.7348306964172773],
					[61.242225154370992, 3.7346834288665183],
					[61.242225158551769, 3.7346834206964963],
					[61.242225168845572, 3.7346834077669784],
					[61.242225173026362, 3.7346833995969559],
					[61.242364072907719, 3.7344418002930588],
					[61.242364077910167, 3.7344417901179048],
					[61.242364087093371, 3.7344417717726412],
					[61.2423640912019, 3.7344417617473713],
					[61.242580791130081, 3.7337611613947281],
					[61.242580791807299, 3.7337611556792982],
					[61.242580794949596, 3.7337611439486054],
					[61.24258079562685, 3.733761138233155],
					[61.24272239576284, 3.7330389382727791],
					[61.24272239779441, 3.7330389211263539],
					[61.242722399248052, 3.7330388891384265],
					[61.242722397776191, 3.733038874446891],
					[61.242694599802945, 3.732830589934018],
					[61.242716900368869, 3.7321194057658031],
					[61.242716900224345, 3.7321194020554498],
					[61.242716899863062, 3.7321193927795435],
					[61.242716899646297, 3.7321193872139924],
					[61.242703002185991, 3.7319000287967401],
					[61.242827988148825, 3.7315528688810033],
					[61.242827989719892, 3.7315528630154944],
					[61.242827992862004, 3.7315528512844978],
					[61.242827993611407, 3.7315528474241617],
					[61.242966893896707, 3.7309750461558719],
					[61.242966894718357, 3.7309750441506928],
					[61.242966896361658, 3.7309750401403372],
					[61.242966896289381, 3.7309750382851488],
					[61.243058496195495, 3.730511139553264],
					[61.243058497621952, 3.7305111299772755],
					[61.243058500474881, 3.7305111108252995],
					[61.243058500041208, 3.7305110996941266],
					[61.243058499861796, 3.7300444664407233],
					[61.243286063694462, 3.7297364856175492],
					[61.243438719940244, 3.7300528869399789],
					[61.243438722911165, 3.7300528939107966],
					[61.243438729674622, 3.7300529058471823],
					[61.243438734433354, 3.7300529125179684],
					[61.243733133818161, 3.7304890114677356],
					[61.243733162830821, 3.7304890402117366],
					[61.243733231077243, 3.7304890829130093],
					[61.243733268523208, 3.7304890971702851],
					[61.243961069121447, 3.7305251973277627],
					[61.243961085356645, 3.7305251983381269],
					[61.243961118648642, 3.7305251983535701],
					[61.243961134811542, 3.7305251975086895],
					[61.244177716626652, 3.7304863188991155],
					[61.244335929858288, 3.7306862078943182],
					[61.244494216090601, 3.7310111805929664],
					[61.244552612737067, 3.7311639718161724],
					[61.24455261370327, 3.7311639735214595],
					[61.244552614741721, 3.7311639770820331],
					[61.244552615707939, 3.7311639787873379],
					[61.244624816416959, 3.7313333787990186],
					[61.244624826078869, 3.731333395852038],
					[61.244624848978432, 3.7313334293581204],
					[61.244624862143795, 3.7313334439558763],
					[61.244827661652621, 3.7315279449453933],
					[61.244827675567358, 3.7315279556827377],
					[61.244827706078581, 3.731527976707476],
					[61.244827721636661, 3.731527983434245],
					[61.245072105398187, 3.7316307765878678],
					[61.245494272592609, 3.7319112556524257],
					[61.245516559259904, 3.7319334425744537],
					[61.245516564840266, 3.7319334472406287],
					[61.245516577861011, 3.7319334581283545],
					[61.245516584335284, 3.7319334626445522],
					[61.24556378409433, 3.7319668633980974],
					[61.245563790568589, 3.7319668679143101],
					[61.245563805304975, 3.73196687664676],
					[61.245563811706973, 3.7319668793076128],
					[61.245966611771536, 3.732164080070457],
					[61.245966630011338, 3.7321640863478414],
					[61.245966668206549, 3.7321640967472756],
					[61.245966687195782, 3.7321640991639478],
					[61.24631938652513, 3.7321862993135038],
					[61.246319391067026, 3.7321863004190163],
					[61.246319397324513, 3.7321862993691881],
					[61.246319400078519, 3.7321863007746563],
					[61.246413885423749, 3.732186300273904],
					[61.246747164254536, 3.7322362966154206],
					[61.246863857706423, 3.7322612968868296],
					[61.246863878411233, 3.7322612971482259],
					[61.246863918926898, 3.7322612978209673],
					[61.246863939559447, 3.7322612962269415],
					[61.24722216318149, 3.7321891101025835],
					[61.247313795814058, 3.7322445713748973],
					[61.247313841305164, 3.7322445842858731],
					[61.24731393156479, 3.7322445915533038],
					[61.247313978048936, 3.7322445837543428],
					[61.247741686699001, 3.7320641230839819],
					[61.247761001339185, 3.7320751732546671],
					[61.247761071932487, 3.7320751838213675],
					[61.247761203944364, 3.7320751542013175],
					[61.247761263575143, 3.732075114314517],
					[61.247800163595443, 3.7320195142817707],
					[61.247800170457779, 3.7320195056599341],
					[61.247800179568337, 3.7320194854552673],
					[61.247800184642834, 3.7320194771334081],
					[61.247997382639667, 3.7315444797422153],
					[61.248200182416163, 3.7310972825815103],
					[61.248200184881114, 3.7310972765649639],
					[61.248200189738725, 3.7310972626763577],
					[61.248200192203683, 3.7310972566597917],
					[61.248219571225967, 3.7310307254059696],
					[61.248530709911947, 3.7308251673955408],
					[61.248530731609506, 3.7308251469451874],
					[61.248530768602663, 3.7308251033835966],
					[61.248530783825956, 3.7308250784168671],
					[61.248669561007404, 3.7304973327646618],
					[61.249119199692863, 3.7301892784137718],
					[61.249119199587462, 3.7301917008200638],
					[61.249119239563456, 3.7301917800234308],
					[61.249119366584914, 3.7301918763664523],
					[61.249119455345884, 3.7301918913504468],
					[61.249138930698969, 3.7301862989081727],
					[61.249569405957544, 3.7301724005852717],
					[61.249569416684629, 3.730172398784636],
					[61.249569438138757, 3.7301723951833683],
					[61.249569448865813, 3.730172393382738],
					[61.249580581287894, 3.7301696113945328],
					[61.249641529438911, 3.7301973420230361],
					[61.249766506261928, 3.7307139467675134],
					[61.249766575664822, 3.7307140191663053],
					[61.249766744004077, 3.7307140674884209],
					[61.24976684108028, 3.730714041856253],
					[61.250014041392767, 3.7304668416780418],
					[61.250014050864507, 3.7304668307494926],
					[61.250014068841764, 3.7304668071868385],
					[61.250014077347281, 3.7304667945527363],
					[61.250150161013316, 3.7302112236974923],
					[61.250469532341413, 3.7299168502890887],
					[61.250683427664278, 3.7297390549415961],
					[61.250683438029867, 3.7297390438625122],
					[61.250683457045504, 3.7297390238601364],
					[61.250683466444869, 3.7297390110754884],
					[61.250919560254609, 3.7293862208167523],
					[61.250994527318859, 3.7292973608652438],
					[61.251369462263391, 3.729105793291644],
					[61.251694429909215, 3.7290557975053638],
					[61.25169443974233, 3.7290557958542903],
					[61.25169445844238, 3.7290557908465387],
					[61.251694467309257, 3.7290557874898496],
					[61.251819464993488, 3.7290112884938487],
					[61.251994438300898, 3.7289529975123386],
					[61.25226940126587, 3.728936299901279],
					[61.252533288950247, 3.7289501991396388],
					[61.25253331137052, 3.7289501972427423],
					[61.252533355244928, 3.7289501917433028],
					[61.252533375660491, 3.728950184579372],
					[61.25271675355777, 3.7288751934992281],
					[61.252925023242419, 3.7288418993558126],
					[61.253166713617702, 3.7288252002540498],
					[61.253166722556891, 3.7288251987529195],
					[61.253166740435276, 3.728825195750654],
					[61.253166749374486, 3.7288251942495174],
					[61.253341749272323, 3.7287807942094675],
					[61.253341753741914, 3.7287807934588879],
					[61.253341761714857, 3.7287807902520602],
					[61.253341767078403, 3.7287807893513598],
					[61.253616754125105, 3.7286834937683482],
					[61.253983333821544, 3.7286057977437199],
					[61.254066660001165, 3.728594607762715],
					[61.254169300713968, 3.7286529732709446],
					[61.254169311730237, 3.7286529788928204],
					[61.254169333690463, 3.7286529882807642],
					[61.254169346422287, 3.728652991746547],
					[61.254513845598652, 3.7287501919820003],
					[61.254513848352751, 3.728750193387504],
					[61.254513852894654, 3.728750194492747],
					[61.254513855648696, 3.7287501958982436],
					[61.254672112184288, 3.7287862847547024],
					[61.254963774891017, 3.7290140560373874],
					[61.255394265105302, 3.7293695471997066],
					[61.255408144915911, 3.7293834274698372],
					[61.255413733351155, 3.7293918106628716],
					[61.255419233031461, 3.7294001097556007],
					[61.255419233997706, 3.7294001114614028],
					[61.255419233997706, 3.7294001114614028],
					[61.255419234963917, 3.729400113167213],
					[61.25567483474147, 3.7297751138814479],
					[61.255674873804395, 3.7297751465518534],
					[61.255674963649874, 3.7297751893759239],
					[61.255675015326332, 3.7297751993794921],
					[61.255855615111507, 3.7297612981552404],
					[61.255855646326438, 3.7297612910456741],
					[61.255855703925185, 3.7297612682973225],
					[61.255855731130659, 3.7297612506524827],
					[61.25624732398289, 3.7294195563188901],
					[61.256305716370605, 3.7293779626443748],
					[61.256305719873971, 3.7293779601879398],
					[61.256305726808407, 3.729377953419077],
					[61.256305730384092, 3.7293779528186071],
					[61.256344523094256, 3.7293445579569089],
					[61.256755701207652, 3.7290584733969974],
					[61.256972226698657, 3.7289558098440754],
					[61.257205562667856, 3.7290001953073619],
					[61.257205620023321, 3.7290001894108658],
					[61.257205722777968, 3.7290001478670662],
					[61.257205767210912, 3.7290001105138559],
					[61.257522366834948, 3.7285168109144009],
					[61.257522370193669, 3.7285168047456883],
					[61.25752237608954, 3.7285167944144293],
					[61.257522378626668, 3.7285167902519012],
					[61.257661255701066, 3.728241835773741],
					[61.257891808895373, 3.7280807679411194],
					[61.258111185073247, 3.727950181529105],
					[61.258450064707795, 3.7278334892709766],
					[61.258450076256402, 3.7278334854626203],
					[61.258450095633286, 3.7278334747344015],
					[61.258450106215662, 3.7278334692201223],
					[61.258561205722302, 3.7277640706489414],
					[61.258561217053916, 3.7277640612722314],
					[61.258561238823262, 3.7277640426689995],
					[61.25856124926095, 3.727764033442468],
					[61.258697348833586, 3.7276112331254123],
					[61.258697353158517, 3.7276112286622873],
					[61.258697359126636, 3.7276112201865694],
					[61.258697361736083, 3.7276112178799128],
					[61.259011245127894, 3.7271779410056456],
					[61.259183422242351, 3.727044557269215],
					[61.259183425745661, 3.7270445548122808],
					[61.259183432752344, 3.7270445498983813],
					[61.25918343707724, 3.7270445454351129],
					[61.25946123464626, 3.7267834476922062],
					[61.25955573208924, 3.7267001505454016],
					[61.259555741632951, 3.7267001414684389],
					[61.259555758787847, 3.7267001199025982],
					[61.25955576736532, 3.7267001091196748],
					[61.259914067382439, 3.7261529106604074],
					[61.259914068204026, 3.7261529086539822],
					[61.259914069025562, 3.7261529066475818],
					[61.259914069025562, 3.7261529066475818],
					[61.260147368675113, 3.7257807057824839],
					[61.260147370462946, 3.7257807054819954],
					[61.260147371284511, 3.7257807034755657],
					[61.260147372106054, 3.7257807014691391],
					[61.260369566902916, 3.7254084108621401],
					[61.260769561097725, 3.7248640178004706],
					[61.260769565422564, 3.7248640133366471],
					[61.260769571318122, 3.7248640030036198],
					[61.260769573855107, 3.7248639988403611],
					[61.260819574101433, 3.7247750975122695],
					[61.260819576638418, 3.7247750933489816],
					[61.260819581567702, 3.7247750813099696],
					[61.260819584998607, 3.7247750769964219],
					[61.261166883012493, 3.7239417806046164],
					[61.261272381725604, 3.7237111822645881],
					[61.261272384262526, 3.7237111781011563],
					[61.261272386654753, 3.723711170225243],
					[61.261272389191724, 3.723711166061805],
					[61.261514088770845, 3.7230167664134712],
					[61.261514090413868, 3.7230167624002952],
					[61.261514091984516, 3.7230167565308414],
					[61.261514092733613, 3.7230167526679989],
					[61.261730780934315, 3.7222278944200728],
					[61.261772354004705, 3.7221779274798741],
					[61.261772354004705, 3.7221779274798741],
					[61.261772354826221, 3.7221779254732432],
					[61.261772355720119, 3.722177925322891],
					[61.261841855615437, 3.7220918259712303],
					[61.261841859867737, 3.7220918196506183],
					[61.261841870160218, 3.7220918067086304],
					[61.261841874340149, 3.7220917985317352],
					[61.262025142043292, 3.7217695545559075],
					[61.262183382680142, 3.7216973824662665],
					[61.262183404665763, 3.7216973694254882],
					[61.262183443877881, 3.7216973366705881],
					[61.262183461176761, 3.7216973188127827],
					[61.262336260996527, 3.7214890188695153],
					[61.262336267785685, 3.7214890083849608],
					[61.262336280470159, 3.7214889875662238],
					[61.262336285399144, 3.7214889755261171],
					[61.262464085085441, 3.7211750753285431],
					[61.262464089192868, 3.7211750652950424],
					[61.262464093832143, 3.7211750458295838],
					[61.262464097045672, 3.721175035946489],
					[61.262511292349551, 3.7209167600806778],
					[61.262639082244277, 3.7206389848353836],
					[61.26263908299331, 3.7206389809723457],
					[61.262639084636284, 3.7206389769588823],
					[61.262639086351683, 3.7206389748017528],
					[61.262794583683373, 3.7202500799856799],
					[61.263091881547361, 3.7196028826537155],
					[61.263091882441287, 3.7196028825032768],
					[61.263155782072097, 3.7194611814361576],
					[61.263155784536494, 3.7194611754157787],
					[61.26315579035915, 3.7194611632246084],
					[61.263155791857201, 3.7194611554983279],
					[61.263194591570652, 3.7193278562473786],
					[61.263194594711592, 3.7193278445075029],
					[61.263194598311777, 3.7193278214790655],
					[61.263194599737361, 3.7193278118964423],
					[61.263230799994147, 3.7186750103259505],
					[61.263230799994147, 3.7186750103259505],
					[61.263244599026685, 3.7183944162144806],
					[61.263291899000372, 3.7180000249205607],
					[61.263291899169644, 3.7180000062065579],
					[61.263291897937762, 3.7179999746485861],
					[61.263291894603867, 3.7179999583928698],
					[61.263175212050939, 3.71746113272218],
					[61.263552971200312, 3.7168029033582393],
					[61.26372236281437, 3.7165418151129064],
					[61.264008452435277, 3.7161723299697571],
					[61.264389027106255, 3.7157668573072025],
					[61.264458402519871, 3.7157251701847325],
					[61.26445840870467, 3.7157251672744303],
					[61.264458420107864, 3.7157251597479615],
					[61.264458426220187, 3.7157251549812482],
					[61.264575125640135, 3.7156307544972549],
					[61.264575136076957, 3.7156307452648591],
					[61.264575155090277, 3.7156307252447909],
					[61.264575162845382, 3.7156307164640747],
					[61.264911251057676, 3.7151557325661075],
					[61.265336234908325, 3.7147695486231447],
					[61.265336241769454, 3.7147695399925871],
					[61.265336257279557, 3.7147695224302919],
					[61.265336264140686, 3.7147695137997503],
					[61.265361254312772, 3.7147334298345114],
					[61.265377932064467, 3.7147168518020139],
					[61.265811154223883, 3.7143835106386609],
					[61.266116668854544, 3.7144307977457047],
					[61.266116676972146, 3.7144307982467999],
					[61.266116692313403, 3.7144307993995662],
					[61.266116700431006, 3.7144307999006436],
					[61.266261046721731, 3.7144308001793882],
					[61.266377701307427, 3.7144973735572178],
					[61.266377724162446, 3.7144973827878132],
					[61.26637777330307, 3.7144973969335702],
					[61.266377797728332, 3.7144974002933857],
					[61.266708211730887, 3.7145001998602778],
					[61.266941527243738, 3.7147501073390505],
					[61.267155412728918, 3.7153250703790919],
					[61.267155413767803, 3.7153250739416661],
					[61.267155415773104, 3.7153250792102033],
					[61.26715541681201, 3.7153250827727695],
					[61.26723871737164, 3.7155111825712721],
					[61.267238738269917, 3.7155112108203361],
					[61.267238787966569, 3.7155112622501072],
					[61.26723881661993, 3.7155112817176676],
					[61.267599916891157, 3.7156751811249311],
					[61.267599922399533, 3.7156751839346831],
					[61.267599930662122, 3.7156751881492784],
					[61.267599936098016, 3.7156751891024324],
					[61.267608236163944, 3.7156779901813359],
					[61.267608271460979, 3.7156779954484631],
					[61.267608342441477, 3.7156779928358952],
					[61.267608377231035, 3.7156779851068134],
					[61.268049964140907, 3.7154946325997869],
					[61.268124838831923, 3.7155584250652725],
					[61.268199799225449, 3.7157111455365106],
					[61.268191499583807, 3.716344396582886],
					[61.268191499873772, 3.7163444040094316],
					[61.268191501275076, 3.7163444168553186],
					[61.268191500671151, 3.7163444244324229],
					[61.268227600872315, 3.7166444218875241],
					[61.268272001337998, 3.7170972207904271],
					[61.268272013659377, 3.7170972598300724],
					[61.268272054174872, 3.7170973296297118],
					[61.268272082368988, 3.7170973603897015],
					[61.268488749948325, 3.7172557376554862],
					[61.268577610380007, 3.7174028710896665],
					[61.268610902595036, 3.7175861362098619],
					[61.268610906750432, 3.7175861504610528],
					[61.268610916921467, 3.7175861805190551],
					[61.268610922864688, 3.7175861944692024],
					[61.268933122569322, 3.718191794208527],
					[61.268933148009069, 3.7181918235649856],
					[61.268933204783508, 3.7181918719409057],
					[61.268933238799974, 3.7181918905088001],
					[61.269072078033702, 3.7182362715751687],
					[61.269291509166564, 3.7185333781393983],
					[61.269310902199905, 3.7186778277050934],
					[61.26931091244316, 3.7186778596208439],
					[61.269310941723873, 3.7186779182338676],
					[61.269310961655144, 3.718677944780671],
					[61.269377661531692, 3.7187418432249584],
					[61.269377765030725, 3.7187418669155776],
					[61.269377932674175, 3.7187418050501537],
					[61.269377998751331, 3.7187417229065285],
					[61.269397388961544, 3.7185723063112923],
					[61.269444522393925, 3.7185362582547459],
					[61.269444537155543, 3.718536244555831],
					[61.269444564673506, 3.7185362118888734],
					[61.269444577429901, 3.7185361929208396],
					[61.269644545671099, 3.7181529520766259],
					[61.269833191098115, 3.7180641712100302],
					[61.269935899772776, 3.7183306364272246],
					[61.269935899890925, 3.7185472004895423],
					[61.269935901364541, 3.7185472151929813],
					[61.269935907138354, 3.7185472478618387],
					[61.269935911366105, 3.7185472639705139],
					[61.27007761088862, 3.7189667641940671],
					[61.270077616831649, 3.7189667781451257],
					[61.270077631399481, 3.7189668055957252],
					[61.270077639057916, 3.7189668173890129],
					[61.270274834988356, 3.7192362136627199],
					[61.270344204574144, 3.719344466721219],
					[61.270358101267242, 3.7194917195022557],
					[61.270358102378516, 3.719491724922074],
					[61.270358103779536, 3.7194917377690153],
					[61.270358104890782, 3.7194917431888386],
					[61.270472005187067, 3.7200028435203323],
					[61.270472006298277, 3.7200028489401955],
					[61.270472009414661, 3.7200028596294632],
					[61.270472011419784, 3.7200028648988743],
					[61.270619200140295, 3.7204278331358722],
					[61.270619199703134, 3.7206360995965291],
					[61.27061920236013, 3.7206361215771184],
					[61.270619212143671, 3.7206361647860389],
					[61.270619219197741, 3.720636184157569],
					[61.270713719082025, 3.7208389839843767],
					[61.270713726812694, 3.7208389976351612],
					[61.270713744061872, 3.7208390246358412],
					[61.270713754474301, 3.7208390378352907],
					[61.271105447102627, 3.7212557308394212],
					[61.271160941032697, 3.72132792161347],
					[61.271160966254534, 3.721327945404012],
					[61.271161020733189, 3.7213279810919908],
					[61.271161051850257, 3.7213279945453515],
					[61.271183232347347, 3.7213334890512444],
					[61.271269276338408, 3.7213751612666699],
					[61.271491522818849, 3.7216279017833722],
					[61.271605409644799, 3.7219694627464013],
					[61.27160541164988, 3.7219694680161051],
					[61.27160541469366, 3.7219694768491096],
					[61.271605416626308, 3.7219694802619845],
					[61.271685916638454, 3.7221528808413851],
					[61.271685923402693, 3.7221528927864718],
					[61.271685936931192, 3.7221529166766523],
					[61.271685945410844, 3.7221529264640454],
					[61.271824845194068, 3.7223223277067943],
					[61.271824861042234, 3.7223223418615672],
					[61.271824893559987, 3.7223223681638289],
					[61.271824911945018, 3.7223223781536197],
					[61.272010956366621, 3.7224140517990416],
					[61.272052622952586, 3.7224889937133234],
					[61.272244221914953, 3.7228667908168975],
					[61.272244230611761, 3.7228668061753405],
					[61.272244254262752, 3.7228668358394024],
					[61.272244265568858, 3.7228668488897565],
					[61.272499866285301, 3.7230973493741852],
					[61.272499884742672, 3.723097361221555],
					[61.272499926126976, 3.7230973841642889],
					[61.272499948087592, 3.7230973935531457],
					[61.272572064865479, 3.723116870337416],
					[61.272655424135614, 3.7232722943658585],
					[61.272655429933444, 3.7232723046050222],
					[61.272655444210855, 3.7232723246321742],
					[61.272655451796538, 3.7232723345705505],
					[61.272847052029846, 3.7234834333278113],
					[61.272847059615479, 3.7234834432663035],
					[61.272847078217616, 3.7234834588278765],
					[61.272847086552396, 3.7234834649021198],
					[61.272944287199998, 3.7235501645787261],
					[61.272944318316874, 3.7235501780349587],
					[61.272944383836673, 3.723550196918167],
					[61.272944418094824, 3.7235501986312514],
					[61.273066717905635, 3.7235390981310106],
					[61.273066731386656, 3.7235390977321488],
					[61.273066754555906, 3.723539091965141],
					[61.273066766998255, 3.7235390880027772],
					[61.273394467377884, 3.7234223888355684],
					[61.27339449465471, 3.7234223730316169],
					[61.273394545343123, 3.7234223345974269],
					[61.273394566894474, 3.7234223104110167],
					[61.273480767348751, 3.7232918103575372],
					[61.273480768991774, 3.7232918063427971],
					[61.273480772422637, 3.7232918020272749],
					[61.273480774138065, 3.7232917998695152],
					[61.273850162955817, 3.7226445177484822],
					[61.273936247728351, 3.722550134268948],
					[61.27393625205309, 3.7225501298028596],
					[61.273936258914702, 3.7225501211715204],
					[61.273936262345543, 3.7225501168558535],
					[61.274064062386735, 3.7223723176383521],
					[61.274064065745151, 3.7223723114656351],
					[61.274064074249765, 3.722372298819371],
					[61.274064076714261, 3.7223722927970981],
					[61.274186271950711, 3.722139002475187],
					[61.274300165067565, 3.721972312662678],
					[61.274300170141352, 3.7219723043319837],
					[61.274300179395013, 3.7219722878210519],
					[61.274300182680953, 3.7219722797912405],
					[61.274527967861836, 3.7214501152449384],
					[61.274752940652895, 3.7212251413083308],
					[61.274752947586848, 3.7212251345335221],
					[61.274752961309936, 3.7212251172697988],
					[61.274752967349997, 3.7212251106454515],
					[61.274947366874194, 3.7209307101327211],
					[61.274947375233822, 3.7209306937716087],
					[61.274947389271304, 3.7209306615008004],
					[61.274947394949209, 3.7209306455910944],
					[61.275039094696467, 3.7205306457253871],
					[61.275039096194533, 3.7205306379962018],
					[61.275039098369206, 3.7205306245453831],
					[61.275039098973345, 3.720530616966689],
					[61.275064095739559, 3.7202333593090398],
					[61.275208369869404, 3.7199696890106422],
					[61.275413746715842, 3.7201084385856076],
					[61.275647022409636, 3.7205444924057414],
					[61.275649821259933, 3.7205500889141465],
					[61.275649857933125, 3.7205501294723113],
					[61.27564994739452, 3.7205501854443193],
					[61.275650000182694, 3.720550200858145],
					[61.275652766083276, 3.7205502006725153],
					[61.27603883369413, 3.7206862887172107],
					[61.276038882472072, 3.7206862935907021],
					[61.276038973650394, 3.7206862782410548],
					[61.276039018877356, 3.7206862612806733],
					[61.276102919017255, 3.7206390616247278],
					[61.276102929454368, 3.7206390523904145],
					[61.276102946752985, 3.7206390345237104],
					[61.276102956296207, 3.7206390254398705],
					[61.276169555788961, 3.7205557251917272],
					[61.276169560113622, 3.7205557207250357],
					[61.276169566008747, 3.7205557103860025],
					[61.276169568545605, 3.7205557062202947],
					[61.276552969412414, 3.719947306768812],
					[61.276552970233865, 3.7199473047611655],
					[61.276552971949215, 3.7199473026030101],
					[61.276552972770688, 3.7199473005953578],
					[61.276733473082167, 3.7196361997485639],
					[61.276733475546507, 3.7196361937255484],
					[61.276733483156939, 3.7196361812279628],
					[61.27673348554886, 3.7196361733478125],
					[61.277011285934393, 3.7189417736970753],
					[61.277011285934393, 3.7189417736970753],
					[61.277011286755837, 3.7189417716893716],
					[61.277100182694177, 3.718711181615411],
					[61.277466872048855, 3.7179862032848909],
					[61.27752236338327, 3.7179084155046085],
					[61.277522366741458, 3.7179084093307289],
					[61.277522374424187, 3.7179083986896155],
					[61.277522376888442, 3.7179083926663128],
					[61.277583477114753, 3.7177917931554658],
					[61.277583477936155, 3.7177917911477003],
					[61.27758348136684, 3.7177917868309986],
					[61.277583482188248, 3.7177917848232314],
					[61.277744582360214, 3.7174417845762826],
					[61.277744582287731, 3.7174417827190802],
					[61.277744582287731, 3.7174417827190802],
					[61.277744583181651, 3.7174417825684909],
					[61.27791684987011, 3.717055754121203],
					[61.27799177611206, 3.7170251842144664],
					[61.277991791984796, 3.7170251759319721],
					[61.277991823585246, 3.7170251556525318],
					[61.27799183931301, 3.717025143655595],
					[61.278150138892777, 3.7168723442762377],
					[61.278150144932617, 3.7168723376502966],
					[61.278150156118357, 3.71687232454904],
					[61.278150161264278, 3.7168723180737273],
					[61.278283439371066, 3.7166918476880508],
					[61.278369454084348, 3.7166363036151249],
					[61.278483249609295, 3.7166418981594314],
					[61.278816592220586, 3.7168029701247796],
					[61.278847057970431, 3.7168279411358172],
					[61.278897044877368, 3.7168890261564753],
					[61.278897049636825, 3.7168890328324946],
					[61.278897060871024, 3.7168890440260287],
					[61.278897067345781, 3.7168890485435568],
					[61.279155466621944, 3.7171195491324842],
					[61.279155484185949, 3.7171195611292305],
					[61.279155521029203, 3.7171195829642145],
					[61.27915554112991, 3.7171195907945589],
					[61.279263840802926, 3.7171529909176728],
					[61.279263894799321, 3.7171529911676986],
					[61.279263997307808, 3.7171529664186957],
					[61.279264043959621, 3.7171529398635914],
					[61.279505743998513, 3.7169029393517565],
					[61.279505744819893, 3.7169029373438418],
					[61.27950574742912, 3.7169029350346916],
					[61.279505748250529, 3.716902933026756],
					[61.279716848409564, 3.7166695336834681],
					[61.279716856986084, 3.7166695228906463],
					[61.279716873172717, 3.7166694995983098],
					[61.279716879888966, 3.7166694872494164],
					[61.279883470900344, 3.7163251063875316],
					[61.28015015791091, 3.7159834233671778],
					[61.280150162984278, 3.7159834150339348],
					[61.280150174024939, 3.7159833982168045],
					[61.280150179098307, 3.7159833898835783],
					[61.280158455135549, 3.7159668367563787],
					[61.280166810801347, 3.7159612669536153],
					[61.280166833390155, 3.7159612463207226],
					[61.28016686858993, 3.7159612029973839],
					[61.280166883737508, 3.7159611761402975],
					[61.280544584236402, 3.7150611789181354],
					[61.28062518321839, 3.7148778811963248],
					[61.280625184788647, 3.7148778753228573],
					[61.280625189716929, 3.714877863274582],
					[61.28062519128715, 3.7148778574011194],
					[61.280850191236674, 3.7141333580262996],
					[61.280850195657194, 3.7141333329761737],
					[61.280850198240913, 3.7141332839308916],
					[61.280850196331642, 3.7141332580783146],
					[61.280783496226611, 3.7138110580374066],
					[61.280783495260209, 3.7138110563307456],
					[61.280783494148693, 3.7138110509093392],
					[61.28078349303722, 3.7138110454879429],
					[61.280633493412886, 3.7132721468253767],
					[61.280633490295955, 3.713272136133376],
					[61.280633483313274, 3.7132721186148663],
					[61.280633479447552, 3.7132721117883212],
					[61.280614107121551, 3.7132332676237092],
					[61.280619598287039, 3.7131972307784191],
					[61.280619598745702, 3.7131972194835292],
					[61.280619599662984, 3.7131971968937592],
					[61.280619599155216, 3.7131971838922264],
					[61.280558499973985, 3.7124193911846084],
					[61.280558500186814, 3.7122639142163045],
					[61.280639098092983, 3.7116917313050837],
					[61.280700197252692, 3.711333334446155],
					[61.280700197783759, 3.7113333250085754],
					[61.280700199812351, 3.711333307840007],
					[61.280700200415957, 3.7113333002597879],
					[61.280700199896586, 3.7112139002319311],
					[61.280700199751443, 3.711213896517223],
					[61.28070020042761, 3.7112138907943555],
					[61.280700200282475, 3.7112138870796456],
					[61.280647400179497, 3.710399993166583],
					[61.280647399906499, 3.710397200033249],
					[61.280647400237022, 3.7103943993197079],
					[61.280647400019284, 3.7103943937476394],
					[61.28064739936606, 3.7103943770314749],
					[61.28064739825443, 3.7103943716102163],
					[61.280541897798486, 3.7096860713584916],
					[61.280541896541628, 3.7096860622225973],
					[61.280541893351824, 3.7096860496736732],
					[61.280541892167605, 3.7096860423951123],
					[61.280475191642232, 3.7094638412885907],
					[61.280475187703694, 3.7094638326052265],
					[61.280475178787619, 3.7094638116746266],
					[61.280475174849087, 3.7094638029912779],
					[61.280327975366262, 3.7091999030476046],
					[61.280327969712523, 3.7091998965233217],
					[61.280327961014187, 3.7091998811648978],
					[61.280327956254361, 3.7091998744897872],
					[61.280205756095938, 3.7090470753952345],
					[61.28020575512943, 3.7090470736887622],
					[61.280205754235567, 3.709047073839602],
					[61.279766891591926, 3.7085138192670097],
					[61.279758498935955, 3.7084360808496695],
					[61.27969459926603, 3.7077249813188162],
					[61.279694599193412, 3.7077249794615312],
					[61.279694599048192, 3.707724975746959],
					[61.279694598081711, 3.7077249740405493],
					[61.279675206285816, 3.7075778348288559],
					[61.279761265412745, 3.707408416363803],
					[61.28000568001994, 3.7071558046388895],
					[61.280211044325824, 3.707166896750651],
					[61.280377703947366, 3.7072584742404473],
					[61.280377746758774, 3.70725848757897],
					[61.280377834191867, 3.707258491515387],
					[61.280377878886128, 3.7072584839706111],
					[61.280663978837197, 3.7071362837474329],
					[61.280664002536348, 3.7071362685290481],
					[61.280664046141233, 3.7071362331238409],
					[61.280664065153076, 3.7071362130879399],
					[61.280977964879099, 3.7066751127567801],
					[61.280977966594243, 3.7066751105975739],
					[61.280977970845768, 3.7066751042709192],
					[61.280977971594368, 3.7066751004052731],
					[61.281116868278581, 3.7064390093883079],
					[61.281314063065651, 3.7061612157473349],
					[61.281314067317162, 3.7061612094205398],
					[61.281314074105026, 3.7061611989261833],
					[61.281314076568783, 3.7061611929012335],
					[61.281527977315001, 3.7057556927115947],
					[61.281527983884935, 3.7057556766449098],
					[61.281527995091679, 3.7057556410986239],
					[61.281527998086084, 3.7057556256357169],
					[61.281536297607154, 3.7056889281776844],
					[61.281569596826209, 3.7054861324604591],
					[61.281569597647447, 3.7054861304521167],
					[61.281569599217306, 3.7054861245780164],
					[61.281569599072, 3.7054861208632381],
					[61.281627998961426, 3.7049361236278031],
					[61.281641898111374, 3.7048250251591717],
					[61.28164189939055, 3.7048250118554642],
					[61.281641899194589, 3.7048249838435572],
					[61.281641897719453, 3.7048249691353812],
					[61.281575197634972, 3.7043888708791628],
					[61.281575197416977, 3.7043888653069863],
					[61.281575195193234, 3.7043888544646202],
					[61.281575194081363, 3.7043888490434447],
					[61.281444599001155, 3.7038888716849332],
					[61.281436300228826, 3.7035944117098083],
					[61.281533495074207, 3.7029583504925809],
					[61.28155578541628, 3.7028972754789526],
					[61.281580782071636, 3.7028417821400321],
					[61.281580785356411, 3.7028417741063739],
					[61.28158079110478, 3.7028417600474768],
					[61.281580792601801, 3.702841752315873],
					[61.281722391474787, 3.7023139597748891],
					[61.28182238861551, 3.7020306665606224],
					[61.2818223885428, 3.7020306647032291],
					[61.281822390185162, 3.7020306606863356],
					[61.281822391006372, 3.7020306586778831],
					[61.281905790220229, 3.7017611623138582],
					[61.282039026389327, 3.7013724491758118],
					[61.282188840274053, 3.7014195914112538],
					[61.282188842061807, 3.7014195911091088],
					[61.282188845709989, 3.7014195923622322],
					[61.282188848464337, 3.7014195937664272],
					[61.282488848406707, 3.7015001937776639],
					[61.282488917453307, 3.7015001877174054],
					[61.282489034063651, 3.701500132484592],
					[61.282489081627396, 3.7015000833120375],
					[61.282664082351559, 3.7011167829605833],
					[61.282664082278856, 3.701116781103142],
					[61.2826640839939, 3.7011167789435309],
					[61.28266408392119, 3.7011167770860891],
					[61.282944583798539, 3.7004556782819131],
					[61.282944587904339, 3.7004556682390914],
					[61.282944594328157, 3.7004556484556614],
					[61.282944595752319, 3.7004556388661545],
					[61.282997396232545, 3.7001917403532252],
					[61.283036295697734, 3.7000028404579841],
					[61.283036297267294, 3.7000028345833527],
					[61.28303629854598, 3.7000028212788791],
					[61.283036299221713, 3.7000028155553588],
					[61.283097399346261, 3.6992611173716194],
					[61.283130798649431, 3.6988889176442199],
					[61.283130798815904, 3.6988888989184163],
					[61.283130793567437, 3.6988888568013194],
					[61.28313079015841, 3.6988888386801193],
					[61.282950200313955, 3.6983388672114752],
					[61.282950199530916, 3.6983305992864208],
					[61.282950199760933, 3.6983249993306555],
					[61.282950199251687, 3.6983249863284926],
					[61.282950195624409, 3.6983249626351054],
					[61.282950194221286, 3.6983249497840909],
					[61.282794593946207, 3.6977221501255091],
					[61.282794589934191, 3.6977221395855868],
					[61.282794581837436, 3.6977221166482979],
					[61.282794576931543, 3.6977221062595564],
					[61.282616900868184, 3.6973860529087728],
					[61.282622400065193, 3.6971500051637802],
					[61.282622399774148, 3.6971499977340567],
					[61.282622399192043, 3.6971499828746257],
					[61.282622397934354, 3.6971499737386737],
					[61.282530817955291, 3.6964501214235916],
					[61.282963983154197, 3.6962529811838811],
					[61.28296400163434, 3.6962529705783727],
					[61.282964038376313, 3.6962529437950202],
					[61.282964053883752, 3.6962529262133885],
					[61.283214026797488, 3.6959501608957086],
					[61.283413987327428, 3.6958529806946578],
					[61.283414015276172, 3.6958529591377616],
					[61.283414062016689, 3.6958529119639327],
					[61.283414080735653, 3.6958528844895402],
					[61.283558481031747, 3.6955417846875243],
					[61.283558485958238, 3.6955417726351527],
					[61.283558494023524, 3.6955417488329023],
					[61.283558497089501, 3.6955417352255506],
					[61.283644596793998, 3.6950694353463733],
					[61.283644596793998, 3.6950694353463733],
					[61.283725196967644, 3.6946111352182287],
					[61.283725196894849, 3.6946111333607417],
					[61.283725196894849, 3.6946111333607417],
					[61.28372519682204, 3.6946111315032582],
					[61.283875196679567, 3.6936833326161285],
					[61.283875198176048, 3.693683324883545],
					[61.283875200202367, 3.6936833077121833],
					[61.283875199911115, 3.6936833002822231],
					[61.283875199682321, 3.6936750076778559],
					[61.283939097064248, 3.6927500504791766],
					[61.284333482108678, 3.6918861831312477],
					[61.284333485392786, 3.6918861750957537],
					[61.284333490246105, 3.6918861611850096],
					[61.284333491815325, 3.6918861553097417],
					[61.284344582932718, 3.6918472879014783],
					[61.284358463046154, 3.691827916325221],
					[61.284358473937985, 3.6918278957824042],
					[61.284358491179518, 3.6918278535960165],
					[61.284358496635363, 3.6918278321038192],
					[61.284466893847899, 3.6911667491515958],
					[61.284561284698405, 3.6909000785694004],
					[61.284675143778557, 3.690669559462127],
					[61.284786169845638, 3.6906279871687446],
					[61.284786190113024, 3.690627976256498],
					[61.284786226999493, 3.690627953180023],
					[61.28478624443958, 3.6906279390068768],
					[61.284997343737054, 3.6904084394283179],
					[61.284997354847263, 3.6904084244572219],
					[61.284997373637914, 3.6904083988357526],
					[61.284997381993655, 3.6904083824613108],
					[61.285191881705082, 3.689980682455321],
					[61.285191885810057, 3.6899806724104374],
					[61.285191893053302, 3.6899806506145238],
					[61.28519189619157, 3.6899806388634997],
					[61.285205791875889, 3.6899111573087704],
					[61.285239084935945, 3.6898306763828264],
					[61.28523908568409, 3.6898306725162655],
					[61.285239088968062, 3.6898306644803269],
					[61.285239090610055, 3.6898306604623659],
					[61.285389090642582, 3.6893500603290903],
					[61.285389093780822, 3.6893500485779245],
					[61.285389098415259, 3.6893500290936063],
					[61.285389098944741, 3.689350019654321],
					[61.285416898637372, 3.6890528192518985],
					[61.285416898802474, 3.6890528005247476],
					[61.285416895557262, 3.6890527636762354],
					[61.285416892146934, 3.689052745554859],
					[61.285247391962081, 3.6884527461409427],
					[61.285247390849584, 3.6884527407197165],
					[61.285247385870107, 3.6884527284741133],
					[61.285247383790868, 3.688452721346795],
					[61.285103005360448, 3.6881110722044594],
					[61.285133497769422, 3.687869424111899],
					[61.285133499119823, 3.6878694126636398],
					[61.285133499138972, 3.6878693902215658],
					[61.285133498628731, 3.6878693772187012],
					[61.285058500298803, 3.6871777930538987],
					[61.285072400418265, 3.6867917074129459],
					[61.285072400345371, 3.6867917055554047],
					[61.285089099197187, 3.6862472298798772],
					[61.285150191797214, 3.6860139565249934],
					[61.285264083086894, 3.6856611836944699],
					[61.285355770554744, 3.68550840218473],
					[61.285355782266542, 3.6855083796299777],
					[61.285355795566034, 3.6855083287572432],
					[61.285355799981176, 3.6855083036997418],
					[61.285358499635912, 3.6853194029185152],
					[61.285358499562989, 3.6853194010609616],
					[61.285358500311006, 3.6853193971943039],
					[61.285358500238083, 3.6853193953367329],
					[61.285339100382089, 3.6845361027499104],
					[61.285347399910009, 3.6843861114477185],
					[61.285347399837072, 3.6843861095901689],
					[61.285347399764134, 3.6843861077326192],
					[61.285347399691183, 3.6843861058750718],
					[61.28536690007266, 3.683647216221198],
					[61.285391898457945, 3.6834556267817473],
					[61.285391899059981, 3.6834556191999326],
					[61.285391900409962, 3.6834556077514136],
					[61.285391900191115, 3.6834556021787646],
					[61.285397399699974, 3.6827694180970982],
					[61.285439097290187, 3.6825222328991289],
					[61.285439099460966, 3.6825222194413789],
					[61.2854390991874, 3.6825221895689095],
					[61.285439098676655, 3.6825221765660481],
					[61.285339100832907, 3.6817027937595093],
					[61.285344599353294, 3.6815889341875256],
					[61.285491847229295, 3.68109190645615],
					[61.285733257295959, 3.6811029983301276],
					[61.285991625844083, 3.6812057861302074],
					[61.285991665684207, 3.6812057924597315],
					[61.285991746568293, 3.6812057899547947],
					[61.28599178575157, 3.6812057795661013],
					[61.286183385856255, 3.6811140793972643],
					[61.28618341223374, 3.6811140637014601],
					[61.286183456433221, 3.6811140206717692],
					[61.286183475295019, 3.6811139969014057],
					[61.286297374881222, 3.6809083966118461],
					[61.286297376595932, 3.6809083944508867],
					[61.286297379879386, 3.6809083864137593],
					[61.286297382414936, 3.6809083822435289],
					[61.28640296595713, 3.6806779182922407],
					[61.286561238786575, 3.6805251440755558],
					[61.286561249074751, 3.6805251311095541],
					[61.286561267936435, 3.6805251073385414],
					[61.286561277330783, 3.6805250945242314],
					[61.286636272837583, 3.6803834002813089],
					[61.287025165002554, 3.6797584133861521],
					[61.28708625790167, 3.6796779229838119],
					[61.287197327397507, 3.6795390601149194],
					[61.287536048384638, 3.679378045746942],
					[61.287658145317003, 3.6795195251896979],
					[61.287852638603241, 3.679775119016365],
					[61.287933123752985, 3.6798861973522001],
					[61.287983112022829, 3.6800083677586972],
					[61.288116508904089, 3.6804361598080382],
					[61.288205393274893, 3.6807167084636543],
					[61.288013705370361, 3.6815638555503436],
					[61.288013703874583, 3.6815638632845005],
					[61.288013700883042, 3.6815638787527973],
					[61.288013700281091, 3.6815638863352875],
					[61.28800810524519, 3.6816443274284509],
					[61.287974840089078, 3.6816887799402753],
					[61.287974834124086, 3.6816887884327651],
					[61.287974823981713, 3.6816888051144132],
					[61.28797481980439, 3.6816888133035497],
					[61.287552628704461, 3.6825692949106528],
					[61.287522042752983, 3.6826081766089018],
					[61.287522032537559, 3.6826081914323177],
					[61.287522015828017, 3.6826082241879488],
					[61.287522008440064, 3.6826082422718063],
					[61.287255408091539, 3.6834943421166009],
					[61.287255405172722, 3.6834943594420078],
					[61.287255401268638, 3.6834943975049548],
					[61.287255401031373, 3.6834944143755353],
					[61.287291499784743, 3.6839611099658121],
					[61.287296999877995, 3.684427802561439],
					[61.287297002395086, 3.6844278208349426],
					[61.287297009144048, 3.6844278552210987],
					[61.287297014342691, 3.6844278730398399],
					[61.287394210184239, 3.6846722643396985],
					[61.287510908104025, 3.6850833543390369],
					[61.287510914196439, 3.6850833720064431],
					[61.287510929883766, 3.6850834048772705],
					[61.287510940372506, 3.6850834199291422],
					[61.287594223753949, 3.6851944995102612],
					[61.287663713596373, 3.6853694733782105],
					[61.287663715602847, 3.6853694786481519],
					[61.287663721476477, 3.6853694907426258],
					[61.287663724376799, 3.6853694958610057],
					[61.287819224312145, 3.6856583940753938],
					[61.287819232046338, 3.6856584077245564],
					[61.28781925101724, 3.6856584325589741],
					[61.287819262253954, 3.6856584437442086],
					[61.287955462078578, 3.6857890443928034],
					[61.287955480684843, 3.6857890599388492],
					[61.287955523862657, 3.6857890825392201],
					[61.287955546792354, 3.6857890936120654],
					[61.288177735111418, 3.6858501898328218],
					[61.288402723165191, 3.6859445842997967],
					[61.288402733216344, 3.6859445882059472],
					[61.288402754139575, 3.6859445940089479],
					[61.288402764117798, 3.6859445960573476],
					[61.288691663777534, 3.6859973978881122],
					[61.288691683588141, 3.685997398269472],
					[61.288691724103188, 3.6859973988806591],
					[61.288691743767963, 3.6859973955465146],
					[61.288852843587776, 3.6859612951917011],
					[61.288852877189605, 3.6859612801438222],
					[61.288852935983996, 3.685961242123228],
					[61.28885296281841, 3.6859612151318824],
					[61.289252963238425, 3.6854029161034969],
					[61.289252968309846, 3.6854029077629091],
					[61.28925297845263, 3.6854028910817416],
					[61.289252981663402, 3.68540288118652],
					[61.289302981211186, 3.6852917853154752],
					[61.289505738308272, 3.6848890707595761],
					[61.289755650414627, 3.6848251936506422],
					[61.289755662855626, 3.6848251896705406],
					[61.289755690273275, 3.6848251775399272],
					[61.289755701747474, 3.6848251718536074],
					[61.290161177153273, 3.6845834869535605],
					[61.290205648133117, 3.6845723947874203],
					[61.290205649920814, 3.6845723944842166],
					[61.290205654317141, 3.6845723918683677],
					[61.290205656104831, 3.6845723915651654],
					[61.290461155681584, 3.6844973922191766],
					[61.290461169910252, 3.6844973879356315],
					[61.290461194573368, 3.6844973744013942],
					[61.290461207835286, 3.684497368411598],
					[61.290652908218775, 3.6843751685391459],
					[61.290652908218775, 3.6843751685391459],
					[61.290652909933527, 3.6843751663780617],
					[61.29065291082739, 3.6843751662264501],
					[61.290744483585485, 3.6843140842775393],
					[61.291102854448823, 3.6842140929740097],
					[61.291102890586068, 3.6842140737524387],
					[61.291102955053283, 3.6842140198011983],
					[61.291102980701645, 3.6842139855263905],
					[61.29125576385777, 3.6838918201493085],
					[61.291558437213226, 3.6836084452020375],
					[61.29155844668081, 3.683608434244432],
					[61.291558464722137, 3.6836084124808566],
					[61.291558474116755, 3.6836083996653355],
					[61.291569574449127, 3.6835889993625504],
					[61.29156958340603, 3.6835889753995157],
					[61.291569596777599, 3.6835889263737096],
					[61.291569600225515, 3.6835888996046737],
					[61.291569600296604, 3.6835611051500776],
					[61.291622399728986, 3.6826556129344685],
					[61.291630799723322, 3.682516711282565],
					[61.291630799577383, 3.6825167075667364],
					[61.291630800033431, 3.6825166962675566],
					[61.291630799814527, 3.6825166906937934],
					[61.291591901604988, 3.6817222367691764],
					[61.291744584137724, 3.681363977239914],
					[61.291744584137724, 3.681363977239914],
					[61.291744584958586, 3.68136397523029],
					[61.291744585852427, 3.6813639750785905],
					[61.291972366597797, 3.6807973220713106],
					[61.292019427800717, 3.6807585352774943],
					[61.292111011357051, 3.6808029787721432],
					[61.292469309366517, 3.6809862770316308],
					[61.292469340414151, 3.6809862885948728],
					[61.292469403713234, 3.6809862965542299],
					[61.292469436110643, 3.6809862966662714],
					[61.292544365349848, 3.6809724094280902],
					[61.292916602230072, 3.6811807750842371],
					[61.292916644076847, 3.6811807866852315],
					[61.292916728901197, 3.6811807928618663],
					[61.292916771878801, 3.6811807874375035],
					[61.293061151888971, 3.6811251934811087],
					[61.293366730825021, 3.6810779973462919],
					[61.293366755706707, 3.6810779893820702],
					[61.293366805251146, 3.6810779678795487],
					[61.293366828126167, 3.6810779546447328],
					[61.293658427842672, 3.6808362532915382],
					[61.293658441706249, 3.6808362397156595],
					[61.293658466532818, 3.6808362074449961],
					[61.293658477568897, 3.680836190608245],
					[61.293816877795322, 3.6805250915857384],
					[61.293816878543161, 3.6805250877179247],
					[61.293816881972575, 3.6805250833948828],
					[61.293816882793401, 3.6805250813850923],
					[61.293961283216582, 3.6802000811388011],
					[61.293961284785269, 3.6802000752611437],
					[61.29396128806863, 3.680200067221961],
					[61.293961289637302, 3.6802000613443098],
					[61.294055790073465, 3.6799111623041081],
					[61.294055794633451, 3.6799111409549679],
					[61.294055799211236, 3.6799110971574214],
					[61.294055798408174, 3.6799110767188319],
					[61.29398079981128, 3.6793027831540921],
					[61.293969600264006, 3.6789749927453168],
					[61.293969597819142, 3.6789749763264701],
					[61.293969591141774, 3.6789749437923565],
					[61.293969586909242, 3.6789749276770793],
					[61.293827987099988, 3.6786055301663727],
					[61.293708500662937, 3.6782805662907654],
					[61.293714099980335, 3.678036109509907],
					[61.293775198896881, 3.6772278157551272],
					[61.29377519913335, 3.677227798881042],
					[61.293775196997778, 3.6772277674463858],
					[61.293775193658895, 3.6772277511796165],
					[61.293744597143643, 3.677105562536215],
					[61.293664097769422, 3.6765305713092835],
					[61.293664091602835, 3.676530551782172],
					[61.293664077335833, 3.6765305093156293],
					[61.293664065733175, 3.6765304888416148],
					[61.293419579810056, 3.6761638090010749],
					[61.293389096967168, 3.6760805566098052],
					[61.293322400653274, 3.6752306084905939],
					[61.293341896796726, 3.6751167335221266],
					[61.293341898966752, 3.6751167200604775],
					[61.293341898837582, 3.6751166938966247],
					[61.293341899219939, 3.6751166807387574],
					[61.293261298815004, 3.6742943806774924],
					[61.293261296588831, 3.6742943698333832],
					[61.293261292957261, 3.6742943461352628],
					[61.293261289837275, 3.6742943354430651],
					[61.292952994650129, 3.6733749541677456],
					[61.292950197645901, 3.6733555716463338],
					[61.292950195346563, 3.6733555589444364],
					[61.292950187993299, 3.6733555321384705],
					[61.2929501839794, 3.6733555215984119],
					[61.292889120215897, 3.6732139042469534],
					[61.292958479567616, 3.6730722879469271],
					[61.292958486954241, 3.6730722698576885],
					[61.292958497039017, 3.6730722288650512],
					[61.292958499810247, 3.673072207819617],
					[61.292983499076819, 3.6724778236829927],
					[61.292994595152692, 3.6724222436958169],
					[61.293133494412324, 3.6718472495911292],
					[61.293227990716758, 3.6714944608104498],
					[61.293308424370373, 3.671278039323695],
					[61.293413849091806, 3.6713057927330284],
					[61.293413860037141, 3.6713057964830162],
					[61.293413882602295, 3.6713057982570039],
					[61.293413894368349, 3.6713057999969814],
					[61.293505489962257, 3.6713084978568475],
					[61.293633115757615, 3.6715028841995174],
					[61.293719207321573, 3.6717972526494211],
					[61.293858106273873, 3.6723556470465586],
					[61.293858106346974, 3.6723556489045754],
					[61.293858106420089, 3.6723556507625896],
					[61.293880400054483, 3.6724417242545053],
					[61.293880399995096, 3.6724889007693879],
					[61.293880400214412, 3.6724889063434603],
					[61.293880400653052, 3.6724889174915836],
					[61.293880400799267, 3.6724889212076186],
					[61.293980400852156, 3.6733778221106443],
					[61.293980403151494, 3.6733778348129729],
					[61.293980408570903, 3.6733778582076289],
					[61.293980412657902, 3.6733778706060556],
					[61.294297012923174, 3.6742250707494382],
					[61.294297025566209, 3.6742250947871526],
					[61.294297060684457, 3.6742251411914606],
					[61.294297081225828, 3.6742251601458014],
					[61.294424836443177, 3.6743195279421776],
					[61.294741514944228, 3.6750917752192334],
					[61.29474152371948, 3.6750917924329296],
					[61.29474154573915, 3.6750918261008145],
					[61.294741558910523, 3.6750918406969331],
					[61.29479705891778, 3.6751473414802822],
					[61.294797130244596, 3.6751473705099991],
					[61.294797273241478, 3.6751473686550624],
					[61.294797344017702, 3.675147337922311],
					[61.295191682858047, 3.6747364062737233],
					[61.295349841763382, 3.6749418220766445],
					[61.295349850246353, 3.6749418318581606],
					[61.295349867139237, 3.6749418495630786],
					[61.295349876442998, 3.6749418573345691],
					[61.295638747662601, 3.6751695352996854],
					[61.295669228601071, 3.6752195047065244],
					[61.295669238050955, 3.6752195161944399],
					[61.295669256950653, 3.6752195391702931],
					[61.295669268188199, 3.6752195503544041],
					[61.295738768317761, 3.6752807507629011],
					[61.295738773899977, 3.6752807554259208],
					[61.295738786852127, 3.6752807644481384],
					[61.295738794222018, 3.6752807688073563],
					[61.296008193895922, 3.6754501690716737],
					[61.296008193968994, 3.6754501709298237],
					[61.296008195756656, 3.6754501706260299],
					[61.296085995757188, 3.6754973708405205],
					[61.296086019581487, 3.6754973817564887],
					[61.296086067831659, 3.6754973960038653],
					[61.29608609411823, 3.6754974008896615],
					[61.296433216386987, 3.6755084964922506],
					[61.296535960859416, 3.6756084425995521],
					[61.296535972096912, 3.6756084537840996],
					[61.29653599703424, 3.6756084701229486],
					[61.296536010880189, 3.6756084789936212],
					[61.29678051110092, 3.6757307781384614],
					[61.296780532170644, 3.6757307876522867],
					[61.29678057483855, 3.675730797237061],
					[61.296780597403696, 3.6757307990142847],
					[61.296980597250034, 3.6757335003373472],
					[61.296980639187289, 3.6757334913400102],
					[61.296980716147438, 3.6757334576846992],
					[61.29698075109723, 3.6757334311685028],
					[61.297344551238304, 3.6753168323608558],
					[61.297344558023624, 3.6753168218544041],
					[61.297344571667374, 3.6753168026997267],
					[61.297344578452709, 3.6753167921932732],
					[61.297436277904836, 3.6751389915226333],
					[61.297436280440209, 3.6751389873504121],
					[61.297436283723258, 3.6751389793097902],
					[61.297436286185551, 3.6751389732793327],
					[61.297600186001333, 3.6747278747582781],
					[61.297600186749023, 3.6747278708898583],
					[61.297600190105129, 3.6747278647074118],
					[61.297600190852776, 3.6747278608389804],
					[61.297705790845455, 3.6743917601128055],
					[61.297705793982281, 3.6743917483555371],
					[61.297705797647545, 3.6743917271550606],
					[61.297705799069796, 3.6743917175599163],
					[61.297733499132441, 3.6740667170847661],
					[61.297733498986268, 3.6740667133682656],
					[61.29773349966085, 3.6740667076415474],
					[61.29773349958775, 3.6740667057832987],
					[61.29774739952726, 3.6734583044019482],
					[61.297747400201793, 3.673458298675238],
					[61.297747399763203, 3.6734582875257265],
					[61.29774739865011, 3.6734582821029198],
					[61.2977140986807, 3.6730860815365056],
					[61.297714098607614, 3.6730860796782561],
					[61.297714099355247, 3.6730860758097696],
					[61.297714098388305, 3.673086074103499],
					[61.297639100030381, 3.672522188488164],
					[61.29765020012519, 3.6720028176790747],
					[61.297714097179799, 3.6715917358999364],
					[61.297772388250053, 3.671316775902278],
					[61.297905757942466, 3.6711029236781334],
					[61.298325122433617, 3.670669561089289],
					[61.298355673921392, 3.6706529881446901],
					[61.298744424647275, 3.6705530001642908],
					[61.298805600288013, 3.6705530001733884],
					[61.298805614662427, 3.6705529995988142],
					[61.298805644158804, 3.670552994581004],
					[61.298805659280767, 3.6705529901377667],
					[61.298877858569824, 3.6705307909796661],
					[61.299252859233917, 3.670414090444917],
					[61.299252876070412, 3.6704140838390682],
					[61.299252907809411, 3.6704140672148604],
					[61.299252922711965, 3.6704140571964756],
					[61.299472133968642, 3.6702420053468927],
					[61.299702622376351, 3.6706861920723433],
					[61.299702706347695, 3.6706862451361499],
					[61.299702877711169, 3.6706862552709341],
					[61.299702966817755, 3.6706862101794511],
					[61.299708464786015, 3.6706779132692056],
					[61.300152890179021, 3.670050219772083],
					[61.300449822921614, 3.6701029711773532],
					[61.30054149858222, 3.6707167091758857],
					[61.300533100150169, 3.670852788703217],
					[61.300533100881516, 3.6708528072873121],
					[61.300533107032749, 3.6708528492704384],
					[61.300533112379476, 3.6708528708110673],
					[61.300597011728279, 3.6710250709130752],
					[61.300597103362108, 3.671025135771651],
					[61.300597287166021, 3.6710251419211897],
					[61.300597382017614, 3.6710250827559703],
					[61.300736277785013, 3.6707222918676621],
					[61.301047374086764, 3.6701694982347428],
					[61.301047380652228, 3.6701694821506123],
					[61.301047392816244, 3.6701694482759684],
					[61.301047396700199, 3.6701694326480632],
					[61.301094595639206, 3.6698833415943422],
					[61.301116894096531, 3.6697972508246046],
					[61.301116896265988, 3.6697972373592336],
					[61.301116898890335, 3.6697972125911487],
					[61.301116900239109, 3.6697972011363231],
					[61.301119600032749, 3.6689944187542309],
					[61.301144596409827, 3.6688639376823518],
					[61.301144598359656, 3.668863918641605],
					[61.301144597790262, 3.668863881320688],
					[61.301144597058645, 3.6688638627363059],
					[61.301061296662958, 3.668416663847752],
					[61.301061281645893, 3.6684166252441663],
					[61.301061238643314, 3.6684165614695456],
					[61.301061209617728, 3.6684165327337697],
					[61.300880709637013, 3.6682970336543175],
					[61.300880661313037, 3.6682970175578355],
					[61.30088056025334, 3.6682970104371488],
					[61.300880509451616, 3.6682970228255125],
					[61.300608304848637, 3.668435873957415],
					[61.300414056807227, 3.6683248601406104],
					[61.300236284248861, 3.667911020970029],
					[61.300236274578872, 3.6679110039075842],
					[61.300236252703741, 3.667910973955895],
					[61.30023624131929, 3.6679109590561096],
					[61.300186276859016, 3.6678609943387075],
					[61.30016409205215, 3.667786043905402],
					[61.300164088037796, 3.6677860333637029],
					[61.300164080082247, 3.6677860141386813],
					[61.300164076141073, 3.6677860054553553],
					[61.29995850300859, 3.6674055545484174],
					[61.299983499204799, 3.6669722260527542],
					[61.300172395584156, 3.6660472395413835],
					[61.300172397005824, 3.6660472299451259],
					[61.300172398955276, 3.6660472109048166],
					[61.30017240030373, 3.6660471994501913],
					[61.300172399843376, 3.6660444007560251],
					[61.30017239989143, 3.6660417291922105],
					[61.300458482970164, 3.6651167833883758],
					[61.300552968993792, 3.6649668076612687],
					[61.300552975631838, 3.6649667934347105],
					[61.300552988160604, 3.6649667688506251],
					[61.30055299301101, 3.6649667549285341],
					[61.300627991016874, 3.6647000587838665],
					[61.300800188562221, 3.6641917633512993],
					[61.300800190349847, 3.6641917630468037],
					[61.300800190349847, 3.6641917630468037],
					[61.301083468944526, 3.6633445239027003],
					[61.301114017264908, 3.6633223617514692],
					[61.301114038057413, 3.6633223413710723],
					[61.301114073165969, 3.6633222961009517],
					[61.301114086588228, 3.6633222713635005],
					[61.301136280150182, 3.6632639891902756],
					[61.301358470180382, 3.6629029049296951],
					[61.301358476891444, 3.6629028925607869],
					[61.301358489273291, 3.6629028642583745],
					[61.301358494123527, 3.6629028503356045],
					[61.301505794140091, 3.6623389518391329],
					[61.30150579630881, 3.6623389383731295],
					[61.3015057989318, 3.6623389136041804],
					[61.30150580020667, 3.6623389002904703],
					[61.301505800276615, 3.6622750494127705],
					[61.301536235379068, 3.662216871151363],
					[61.301883327224175, 3.6621307982032172],
					[61.301986002646444, 3.6621280033298609],
					[61.302158133669657, 3.6623529117651645],
					[61.302433112949643, 3.6628222774142447],
					[61.302555406232429, 3.6632944498451598],
					[61.30255541128745, 3.6632944639517921],
					[61.302555423185055, 3.6632944918604782],
					[61.302555430027716, 3.6632945056625221],
					[61.302874830017615, 3.6638140040892946],
					[61.30287483961488, 3.6638140192933193],
					[61.302874864025917, 3.6638140450706653],
					[61.302874877125205, 3.6638140578070661],
					[61.303024877207413, 3.6639307583245615],
					[61.303024884577653, 3.663930762682019],
					[61.303024901252265, 3.6639307748094367],
					[61.303024908549261, 3.6639307773083645],
					[61.303324908653501, 3.6640834784734349],
					[61.303324958545844, 3.6640834886842764],
					[61.303325058272385, 3.6640834847924735],
					[61.303325106245723, 3.6640834691358304],
					[61.303608406010646, 3.6639057697577653],
					[61.30360841912502, 3.6639057600393841],
					[61.30360844259905, 3.6639057392008398],
					[61.303608454746367, 3.6639057277761484],
					[61.303775146150642, 3.6637029376465007],
					[61.304183435114616, 3.663327946190889],
					[61.304183453225662, 3.6633279262652776],
					[61.304183481930927, 3.6633278783394219],
					[61.304183492598447, 3.6633278521977681],
					[61.304225193277809, 3.6631750531224521],
					[61.304225193204552, 3.6631750512638495],
					[61.304225193204552, 3.6631750512638495],
					[61.30422519402515, 3.6631750492529469],
					[61.304425181805627, 3.6624000951175586],
					[61.304680712013862, 3.6621001772694695],
					[61.304852853217078, 3.6620529924287673],
					[61.304852876236517, 3.6620529828921411],
					[61.304852920268004, 3.6620529585476533],
					[61.304852940312976, 3.6620529420334882],
					[61.305130739782065, 3.6617807425677138],
					[61.305130745892257, 3.6617807377840021],
					[61.305130757072298, 3.6617807246516025],
					[61.305130762142127, 3.661780716302919],
					[61.305216861792502, 3.6616612177356038],
					[61.305216866041803, 3.6616612113978753],
					[61.305216872005452, 3.6616612028968158],
					[61.305216875360927, 3.661661196711441],
					[61.305314074819961, 3.6614861969709644],
					[61.305314083098708, 3.6614861787194468],
					[61.305314095040622, 3.6614861392608731],
					[61.305314098777046, 3.6614861199124631],
					[61.305358496310376, 3.6610222503875711],
					[61.305561283321055, 3.6605583791951131],
					[61.305561288873889, 3.6605583146286929],
					[61.305561258116917, 3.6605581963751641],
					[61.305561222700867, 3.6605581425356877],
					[61.305139023192162, 3.660230441923753],
					[61.305138989241676, 3.6602304252583311],
					[61.305138918205124, 3.6602304036889239],
					[61.30513888119237, 3.6602304006435977],
					[61.304824994021189, 3.660260900572978],
					[61.304691707904276, 3.6602554003786123],
					[61.304691691742349, 3.6602554012629018],
					[61.304691661279421, 3.6602554045853672],
					[61.304691645190758, 3.6602554073282967],
					[61.304322199142113, 3.660360892806183],
					[61.304241772292251, 3.6603387128839024],
					[61.303950164607137, 3.6601942595614396],
					[61.303794592387739, 3.6596971424773472],
					[61.303708495683694, 3.6593999593491682],
					[61.303608498420353, 3.658649974742965],
					[61.30360849820044, 3.6586499691672953],
					[61.303608495972995, 3.6586499583208192],
					[61.303608494932568, 3.6586499547561431],
					[61.303422395207299, 3.6578471542155393],
					[61.303422394166851, 3.6578471506508934],
					[61.303422391118872, 3.6578471418155196],
					[61.30342239007841, 3.6578471382508937],
					[61.303378018106187, 3.6577111223713841],
					[61.303608435140816, 3.6573084664105489],
					[61.303805661712452, 3.6572445895562549],
					[61.303805689947723, 3.6572445753845657],
					[61.303805741582671, 3.6572445385106964],
					[61.303805764015145, 3.6572445141024295],
					[61.304119540261794, 3.6567945493205034],
					[61.304255695191173, 3.6567112764118814],
					[61.304313986083862, 3.6566834817141918],
					[61.304313993087689, 3.6566834767771415],
					[61.304314006274815, 3.6566834689141019],
					[61.304314013351927, 3.656683465835644],
					[61.304705675669766, 3.6564168896223568],
					[61.304913931835593, 3.656383497094188],
					[61.30491393719845, 3.6563834961792065],
					[61.304913947924135, 3.6563834943492499],
					[61.304913953286999, 3.6563834934342685],
					[61.305155621945971, 3.6563169012061447],
					[61.305369368726474, 3.656327998685871],
					[61.305605511180431, 3.6563890845479352],
					[61.305972078793488, 3.6566668591310094],
					[61.305972085270113, 3.6566668636396193],
					[61.305972097256316, 3.6566668709506027],
					[61.305972102839156, 3.6566668756117058],
					[61.306052683252894, 3.6567112639719901],
					[61.306191559148246, 3.6568362415479481],
					[61.306499852748274, 3.6571723353508188],
					[61.306499860192034, 3.6571723415658388],
					[61.306499875152916, 3.6571723558545854],
					[61.306499882596675, 3.6571723620695882],
					[61.306716583292285, 3.6573279622115011],
					[61.306716608085054, 3.657327974823128],
					[61.306716661025817, 3.6573279938602337],
					[61.306716688280041, 3.6573280004382229],
					[61.306949987776683, 3.6573418994069486],
					[61.30694999500048, 3.6573419000458363],
					[61.30695000758714, 3.6573418997698335],
					[61.306950014737602, 3.6573418985499786],
					[61.307172215396442, 3.6573251983525958],
					[61.30717222619549, 3.6573251983815598],
					[61.307172245785964, 3.6573251931681394],
					[61.307172257405462, 3.65732519118585],
					[61.307397235190656, 3.6572584971025917],
					[61.30766671220551, 3.6572419003630743],
					[61.307666719356, 3.6572418991431559],
					[61.307666732763131, 3.6572418968558162],
					[61.307666739019787, 3.6572418957883897],
					[61.307847238718303, 3.6572057960287769],
					[61.307847251231642, 3.6572057938938909],
					[61.307847273430227, 3.657205786364004],
					[61.307847284009256, 3.6572057808164966],
					[61.308225064748463, 3.6570307894097134],
					[61.308297245162713, 3.6570140952362178],
					[61.308297246056497, 3.6570140950837224],
					[61.308297247844109, 3.6570140947787224],
					[61.308297248737937, 3.6570140946262186],
					[61.308352849397941, 3.657000193633277],
					[61.308352861837939, 3.657000189639438],
					[61.308352889252696, 3.6570001774765926],
					[61.308352901619351, 3.6570001716239302],
					[61.308544501667043, 3.6568862715591832],
					[61.308544506956544, 3.6568862687853163],
					[61.308544516568432, 3.656886261531247],
					[61.308544521857954, 3.6568862587573605],
					[61.308689016860043, 3.6567751623695322],
					[61.308747297687077, 3.6567362754367991],
					[61.308844482952189, 3.6566918821851475],
					[61.308844490029315, 3.6566918791061926],
					[61.308844502322621, 3.6566918713944516],
					[61.308844507612122, 3.6566918686205057],
					[61.309197259377527, 3.6564640993791917],
					[61.309411091027691, 3.6564640990679469],
					[61.309647181020004, 3.6564862981634003],
					[61.309647190104727, 3.6564863003559744],
					[61.309647207160396, 3.6564862993169331],
					[61.309647216171825, 3.6564862996506138],
					[61.30986109722005, 3.6564696014688809],
					[61.310091678203356, 3.6564945991969791],
					[61.310091686320931, 3.6564945996831768],
					[61.310091701662337, 3.6564946008080899],
					[61.310091708812799, 3.6564945995878828],
					[61.310330608997596, 3.656483499773826],
					[61.310330623298526, 3.6564834973333786],
					[61.310330650112768, 3.6564834927575398],
					[61.310330664340313, 3.656483488458159],
					[61.310544464054573, 3.6564112881433761],
					[61.310544475600707, 3.6564112843015155],
					[61.310544500333947, 3.6564112725948057],
					[61.310544510839641, 3.6564112651875451],
					[61.310694501719432, 3.6563112734159176],
					[61.310994486628061, 3.6561557793845445],
					[61.311055683049055, 3.6561279826960074],
					[61.311055685657131, 3.6561279803793947],
					[61.31105569094661, 3.6561279776051596],
					[61.311055693628063, 3.6561279771475261],
					[61.311275093677594, 3.656011277651666],
					[61.311275099787558, 3.6560112728658232],
					[61.311275112080779, 3.6560112651531753],
					[61.311275118190743, 3.6560112603673334],
					[61.311400115959479, 3.6559195629777039],
					[61.311444494710152, 3.655889077361913],
					[61.311519475910124, 3.6558584852603055],
					[61.311519484774827, 3.6558584818757667],
					[61.311519504071839, 3.6558584692245235],
					[61.31151951293657, 3.6558584658399784],
					[61.311894452869844, 3.6556002053437782],
					[61.31209167967441, 3.6556195995873435],
					[61.312091705741459, 3.6556195988811111],
					[61.312091755794597, 3.6556195903375501],
					[61.312091779780665, 3.6556195825002287],
					[61.312344469325303, 3.6555084877894251],
					[61.312641744773899, 3.6554195949180941],
					[61.312794432470284, 3.655394597780814],
					[61.312794461745796, 3.6553945871685882],
					[61.312794517321983, 3.6553945589655377],
					[61.312794541835039, 3.6553945416798275],
					[61.313072342178693, 3.655114040821315],
					[61.313072353357995, 3.6551140276831027],
					[61.31307237288847, 3.6551139981462297],
					[61.313072382206819, 3.6551139834540818],
					[61.313244582126316, 3.6547361830352338],
					[61.313244583693823, 3.6547361771526985],
					[61.313244587869406, 3.654736168953276],
					[61.313244588616492, 3.6547361650824426],
					[61.313355784108289, 3.6544111783034645],
					[61.313472376752351, 3.654188992653252],
					[61.313472381001283, 3.6541889863128194],
					[61.313472386670924, 3.6541889703715231],
					[61.313472389952658, 3.6541889623245662],
					[61.313564088370448, 3.6539139682200887],
					[61.313697387457886, 3.6535694727345347],
					[61.31369738902535, 3.6535694668518541],
					[61.313697392980671, 3.6535694530747502],
					[61.313697395368585, 3.6535694451803025],
					[61.313747395328463, 3.653358345322447],
					[61.313747395328463, 3.653358345322447],
					[61.313769595154817, 3.6532611446435599],
					[61.313769595081418, 3.6532611427844244],
					[61.313769595755083, 3.6532611370543542],
					[61.313769596575504, 3.6532611350425785],
					[61.313872396705705, 3.652680634316376],
					[61.313872396705705, 3.652680634316376],
					[61.313872396632313, 3.6526806324572347],
					[61.313930795583204, 3.6523306425911932],
					[61.31401129280264, 3.6520333527940636],
					[61.314011292729226, 3.6520333509349157],
					[61.314011294370026, 3.6520333469112498],
					[61.314011295190447, 3.6520333448994244],
					[61.314158495011412, 3.6514028446205331],
					[61.314158497325771, 3.6514028348666563],
					[61.314158499052823, 3.6514028102395368],
					[61.314158500473361, 3.6514028006383592],
					[61.314158499695957, 3.651400000710789],
					[61.314158500093008, 3.6513972305297373],
					[61.314336281355963, 3.6508306901988248],
					[61.314586261933442, 3.650477917628109],
					[61.314602959877313, 3.6504557201129786],
					[61.314602965840216, 3.6504557116071839],
					[61.314602977619131, 3.6504556908772119],
					[61.314602982614815, 3.6504556806649484],
					[61.314614029869801, 3.6504308006868338],
					[61.315033300002163, 3.6504308002374271],
					[61.315033342757609, 3.6504307891879724],
					[61.315033419636954, 3.6504307535900091],
					[61.31503345458119, 3.6504307270295588],
					[61.315058213386585, 3.6504004227784765],
					[61.315074807651534, 3.6504583555572605],
					[61.315074807651534, 3.6504583555572605],
					[61.315074807724955, 3.6504583574164653],
					[61.315083107817941, 3.6504861572184146],
					[61.315083113768097, 3.6504861711757086],
					[61.315083126562236, 3.6504861989375774],
					[61.315083134299989, 3.6504862125894313],
					[61.315333133738008, 3.6508557112690339],
					[61.315333134705241, 3.6508557129755319],
					[61.315333134705241, 3.6508557129755319],
					[61.315505434698622, 3.6511057124317645],
					[61.315505436633046, 3.6511057158448192],
					[61.315505441322294, 3.6511057206589466],
					[61.315505443256718, 3.6511057240719853],
					[61.315766542598226, 3.651436223846408],
					[61.315766546320241, 3.651436226954083],
					[61.315766551976651, 3.6514362334748647],
					[61.315766554804895, 3.6514362367352402],
					[61.31595265542731, 3.6516334378375053],
					[61.31595266756058, 3.6516334488672255],
					[61.315952698157083, 3.65163347171704],
					[61.315952712898294, 3.6516334804294024],
					[61.316241612213929, 3.6517723794879262],
					[61.316402710619357, 3.6518529794349046],
					[61.31640274695858, 3.651852988201306],
					[61.316402821878015, 3.6518529941202882],
					[61.31640286045824, 3.6518529912728441],
					[61.316569424887348, 3.6518002029282228],
					[61.316849988276147, 3.6518168994460734],
					[61.316850007119356, 3.6518168980986458],
					[61.31685004294475, 3.6518168938499018],
					[61.316850060820755, 3.6518168907958639],
					[61.317180651380788, 3.6517112939042344],
					[61.317300040826389, 3.6516862967293586],
					[61.317300077925509, 3.6516862791595468],
					[61.317300142745317, 3.6516862343909411],
					[61.317300170392578, 3.6516862053328132],
					[61.317433470009846, 3.6514695042318781],
					[61.317433475899364, 3.6514694938661156],
					[61.317433486637846, 3.6514694695686063],
					[61.317433490666353, 3.6514694576489282],
					[61.317652991191892, 3.6507444576788681],
					[61.317714091176398, 3.6505417580428112],
					[61.317714094311064, 3.6505417462756449],
					[61.317714097898943, 3.6505417231995572],
					[61.31771410021323, 3.6505417134444968],
					[61.317755798610925, 3.6498917155660382],
					[61.317780797755518, 3.6496083343159316],
					[61.317805791854312, 3.6495139555215377],
					[61.318058490673657, 3.6486806594335093],
					[61.318214088929494, 3.648180664459963],
					[61.318375187178404, 3.6477528705136235],
					[61.318375188819061, 3.647752866489185],
					[61.31837519202692, 3.6477528565808996],
					[61.318375192773765, 3.6477528527092908],
					[61.318572390660499, 3.6470222581271154],
					[61.318641888856, 3.6468250663704938],
					[61.318641891316972, 3.646825060333692],
					[61.318641894377798, 3.6468250467064185],
					[61.318641895944964, 3.6468250408224918],
					[61.318669595607751, 3.6466889396192212],
					[61.318669596428087, 3.6466889376069522],
					[61.318669597174932, 3.6466889337352604],
					[61.318669597921755, 3.6466889298635934],
					[61.318791894715147, 3.6458944504748994],
					[61.319125181940464, 3.6449944845295827],
					[61.319139074790129, 3.6449694976247087],
					[61.319139080532125, 3.6449694835383117],
					[61.3191390911959, 3.6449694573778504],
					[61.319139095150319, 3.6449694435972759],
					[61.319150195148239, 3.6449194452222047],
					[61.319364092428913, 3.6440389546494272],
					[61.319583490365957, 3.6433806622162721],
					[61.319583491186194, 3.6433806602038654],
					[61.319583491859433, 3.6433806544725336],
					[61.319583492606185, 3.6433806506006725],
					[61.319630791033873, 3.6432028595057999],
					[61.319664088348226, 3.6431111686996984],
					[61.319664090661959, 3.6431111589435043],
					[61.319664096183203, 3.6431111392781523],
					[61.319664097603138, 3.6431111296749248],
					[61.319752987008378, 3.6425194982735092],
					[61.320039036035688, 3.6422557466214465],
					[61.320039036035688, 3.6422557466214465],
					[61.320039037749751, 3.6422557444559613],
					[61.320039038643515, 3.6422557443029686],
					[61.320111235654977, 3.6421862465899131],
					[61.320489011717726, 3.6418501682138444],
					[61.320716739839611, 3.6417419016339228],
					[61.320938882237954, 3.6417529993950923],
					[61.321144373210579, 3.6417807979802634],
					[61.321144375965453, 3.6417807993807627],
					[61.321144381328182, 3.6417807984626696],
					[61.32114438408307, 3.6417807998631671],
					[61.321388873811934, 3.6418001997987317],
					[61.321572150108551, 3.6418334945313844],
					[61.32183323584529, 3.6419223888764782],
					[61.321833248578933, 3.6419223923130017],
					[61.321833277621288, 3.6419223985740148],
					[61.321833292068938, 3.6419223998449479],
					[61.321974985765337, 3.641927999440703],
					[61.322283279920143, 3.6419584981168178],
					[61.322283295261563, 3.6419584992347445],
					[61.322283323115983, 3.6419584982104451],
					[61.322283336596279, 3.6419584977747999],
					[61.322430607158438, 3.6419308022005041],
					[61.322730545316247, 3.6419668955335212],
					[61.323130517258249, 3.6421501811490495],
					[61.323130533714099, 3.6421501876930464],
					[61.323130570053827, 3.6421501964496454],
					[61.323130589117461, 3.6421502006749393],
					[61.323180587617536, 3.6421529998283719],
					[61.323227711880321, 3.6421557949125125],
					[61.323324859350471, 3.6422529404318347],
					[61.323324872451799, 3.6422529531670063],
					[61.323324900221373, 3.6422529727522814],
					[61.323324914963194, 3.6422529814620668],
					[61.32357489087385, 3.6423695704898806],
					[61.323627670610939, 3.6424140538574927],
					[61.323627686246532, 3.642414062414419],
					[61.323627716697445, 3.6424140815409749],
					[61.32362773315328, 3.6424140880852036],
					[61.323947112766071, 3.6425279814820133],
					[61.324077695104769, 3.6426084703071706],
					[61.324077733452654, 3.6426084843376514],
					[61.32407781216795, 3.6426084952021407],
					[61.324077852608895, 3.6426084938958425],
					[61.324219430741827, 3.6425695982266482],
					[61.324525007084844, 3.6425585000894536],
					[61.32452502764189, 3.6425584965701328],
					[61.324525067788692, 3.6425584878247577],
					[61.324525088198698, 3.6425584805859481],
					[61.324633365949765, 3.6425057902443938],
					[61.324975042917274, 3.6424307950773196],
					[61.324975055356731, 3.6424307910752645],
					[61.324975077554278, 3.6424307835302159],
					[61.324975089099951, 3.6424307796811801],
					[61.325030689330312, 3.6424029788559427],
					[61.325255689403413, 3.6422918781103144],
					[61.32525570959276, 3.6422918652917735],
					[61.32525574960372, 3.6422918303557834],
					[61.325255766817527, 3.6422918105571709],
					[61.325352893041249, 3.6421446219252585],
					[61.325424962430148, 3.6421584958209849],
					[61.325425004658648, 3.6421584942078979],
					[61.325425085772387, 3.6421584747026863],
					[61.325425124657642, 3.6421584568105434],
					[61.325536224857174, 3.6420695567086891],
					[61.325536231786799, 3.6420695499049982],
					[61.325536244752278, 3.642069536450673],
					[61.325536250861688, 3.6420695316598315],
					[61.325875093027499, 3.641677997611573],
					[61.326019404279933, 3.6416751992236298],
					[61.326019417760229, 3.6416751987877269],
					[61.326019444573731, 3.6416751941962664],
					[61.326019458874271, 3.6416751917474897],
					[61.326325051552246, 3.641580792390112],
					[61.326727846035965, 3.6414862942898902],
					[61.326727851398658, 3.6414862933715422],
					[61.326727861156726, 3.6414862898280269],
					[61.326727867413226, 3.6414862887566155],
					[61.32677506656556, 3.6414695888775874],
					[61.326775068353122, 3.6414695885714701],
					[61.326775070067136, 3.6414695864054814],
					[61.326775070960934, 3.6414695862524189],
					[61.326972270808902, 3.6413945874585538],
					[61.326972272522902, 3.6413945852925451],
					[61.326972276098026, 3.6413945846802971],
					[61.326972276991796, 3.6413945845272369],
					[61.327225077241607, 3.6412890851843045],
					[61.32722508342453, 3.6412890822529338],
					[61.327225094002827, 3.6412890766963297],
					[61.327225100185721, 3.6412890737649684],
					[61.327297299626608, 3.6412473725290671],
					[61.327297304095545, 3.6412473717637206],
					[61.327297311098675, 3.6412473668193694],
					[61.32729731460028, 3.6412473643471701],
					[61.32767511197175, 3.6409834665661842],
					[61.327861196650126, 3.6408612755621297],
					[61.328124942418121, 3.640741946841167],
					[61.328419245085897, 3.6411057256323431],
					[61.328419282234861, 3.6411057548485637],
					[61.328419364661407, 3.6411057912905571],
					[61.328419410906363, 3.6411058002232091],
					[61.328572211106575, 3.6410974004158771],
					[61.328572236952688, 3.6410973941165277],
					[61.328572285816477, 3.6410973782571503],
					[61.328572308834168, 3.6410973686970869],
					[61.32880290921053, 3.6409473671924268],
					[61.328802925751035, 3.6409473531238183],
					[61.328802956077098, 3.6409473235858725],
					[61.328802968895317, 3.6409473064096178],
					[61.32902233094164, 3.6405973662500943],
					[61.329063962857596, 3.6405834898531668],
					[61.329063986695409, 3.6405834782794799],
					[61.329064031395461, 3.6405834481513315],
					[61.329064051437484, 3.640583431610005],
					[61.329133450983619, 3.6405029313448556],
					[61.329133474243868, 3.6405028824177466],
					[61.329133491942599, 3.6405027838827402],
					[61.329133488021533, 3.6405027302486341],
					[61.329025187780957, 3.6402082304931827],
					[61.329025172303282, 3.6402082031828797],
					[61.329025132557277, 3.6402081538133646],
					[61.329025110150077, 3.6402081333079557],
					[61.328789071212739, 3.640052673879592],
					[61.328705791988504, 3.6397666445390371],
					[61.328705744650655, 3.6397665852356549],
					[61.328705622246069, 3.6397665163214219],
					[61.328705547179325, 3.6397665067105951],
					[61.325836090328082, 3.6405608943341603],
					[61.320586288433283, 3.6396442301728764],
					[61.319483499167362, 3.6250443851335987],
					[61.319483488680525, 3.6250443476211864],
					[61.31948345630925, 3.6250442801697553],
					[61.319483432637313, 3.6250442505377265],
					[61.317161256062164, 3.6229748705251885],
					[61.315227971883971, 3.6196970984421739],
					[61.315227937190549, 3.6196970632248564],
					[61.315227852454591, 3.6196970141464084],
					[61.315227804199544, 3.6196969999780464],
					[61.312502804228643, 3.6196442001511606],
					[61.312502786279744, 3.6196442013640056],
					[61.312502749635868, 3.6196442076611994],
					[61.312502732728426, 3.619644212438367],
					[61.303927732662153, 3.6227109112296971],
					[61.303927688039785, 3.6227109432155884],
					[61.303927622918359, 3.6227110254992874],
					[61.303927602419265, 3.6227110757971031],
					[61.302108109312613, 3.6372443152014702],
					[61.299758305903545, 3.6400274825633803],
					[61.295891857045959, 3.6350942771717016],
					[61.295891842976673, 3.6350942627451586],
					[61.295891812230451, 3.6350942362092042],
					[61.295891794659717, 3.6350942242528368],
					[61.292008395015493, 3.6329942243625233],
					[61.29200834139899, 3.632994211100776],
					[61.292008233704074, 3.6329942183236619],
					[61.292008182306965, 3.6329942383490561],
					[61.284977943691551, 3.6381107486427955],
					[61.284030797033232, 3.6324277675608143],
					[61.28403078781087, 3.6324277392211521],
					[61.284030763257014, 3.6324276873277928],
					[61.284030746064325, 3.6324276622228542],
					[61.282483446209582, 3.6307804627127331],
					[61.282483373907077, 3.6307804320902042],
					[61.282483229871957, 3.6307804305823632],
					[61.282483158139343, 3.6307804596970654],
					[61.27622205772655, 3.6371220592783877],
					[61.276222054298572, 3.6371220636035737],
					[61.276222045655047, 3.6371220725596913],
					[61.276222042300638, 3.637122078741744],
					[61.269761041829234, 3.6454803469006132],
					[61.267239006884914, 3.6442165295006217],
					[61.258705722851303, 3.6375776419539241],
					[61.25870567258962, 3.6375776225170329],
					[61.258705571308575, 3.6375776099304282],
					[61.258705516714031, 3.6375776173916745],
					[61.251880516789612, 3.6406692186447045],
					[61.251880489596644, 3.6406692363636797],
					[61.251880441908362, 3.640669281866054],
					[61.251880422306847, 3.6406693094968237],
					[61.247166522341551, 3.6498666095284822],
					[61.247166515704762, 3.6498666237334092],
					[61.247166506006486, 3.6498666515339404],
					[61.247166503018342, 3.6498666669847801],
					[61.245760903441386, 3.658319367210511],
					[61.245760901727031, 3.6583193693697802],
					[61.245760901946831, 3.6583193749352034],
					[61.245760901052996, 3.6583193750872769],
					[61.244949804937711, 3.6651693475182339],
					[61.242388715590948, 3.6711749228910455],
					[61.242388710739519, 3.6711749367859898],
					[61.242388702897493, 3.6711749661274942],
					[61.242388700800689, 3.6711749814223649],
					[61.241358101455987, 3.6828638826804285],
					[61.241358100635047, 3.6828638846867263],
					[61.241358099960003, 3.6828638904029334],
					[61.241358100032919, 3.6828638922578856],
					[61.240896999905694, 3.6965860926290359],
					[61.240897001745211, 3.6965861165926635],
					[61.240897011681355, 3.6965861634632184],
					[61.240897018884112, 3.6965861865211362],
					[61.241863719223979, 3.6986278855386869],
					[61.24186373171694, 3.6986279058373408],
					[61.241863760060248, 3.6986279402659665],
					[61.241863776949913, 3.6986279579550905],
					[61.243994205669864, 3.7002918027252072],
					[61.244355390226112, 3.7059526548205479],
					[61.241822143474629, 3.7067054087262736],
					[61.241822098684885, 3.7067054368160566],
					[61.241822030390935, 3.7067055080807121],
					[61.241822005171542, 3.7067055534119757],
					[61.238235904844807, 3.7219693547654287],
					[61.238235904240476, 3.7219693623351753],
					[61.238235901388727, 3.721969381484906],
					[61.238235899890469, 3.7219693892048862],
					[61.238016500395943, 3.7264082900439113],
				],
			],
		},
		{
			fldNpdidField: "23384544",
			wlbNpdidWellbore: "315",
			fldName: "GINA KROG",
			fldCurrentActivitySatus: "Producing",
			wlbName: "15/5-1",
			fldDiscoveryYear: "1978",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=23384544",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=23384544",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "GINA KROG UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=315",
			fldHcType: "OIL/GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "23412556",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2021-12-21 00:00:00",
			fldMainSupplyBase: "Dusavik",
			fldAreaGeometryWKT: [
				[
					[58.53956649995429, 1.7013443975146505],
					[58.539566500635537, 1.7013444094404711],
					[58.539566503877019, 1.7013444346235529],
					[58.539566506437239, 1.7013444478807886],
					[58.540085905910658, 1.7034472474044886],
					[58.540085910252294, 1.7034472602899429],
					[58.540085918838258, 1.7034472843571096],
					[58.540085923973386, 1.7034472953527595],
					[58.541030423814007, 1.7051889955272865],
					[58.541030436561925, 1.7051890135539882],
					[58.541030468293506, 1.705189048305096],
					[58.541030485398267, 1.7051890636978055],
					[58.542385963710409, 1.7061390496807018],
					[58.543688746797315, 1.7076779298420142],
					[58.54368874966427, 1.7076779326918357],
					[58.543688756289008, 1.7076779382054765],
					[58.543688760144029, 1.7076779425732036],
					[58.544833160310525, 1.7088057427157104],
					[58.544833191166191, 1.7088057621379329],
					[58.544833258724395, 1.7088057928645815],
					[58.544833296123301, 1.7088058005751128],
					[58.546447148079224, 1.70883629874179],
					[58.547497106232193, 1.7093973767533248],
					[58.547497144716338, 1.7093973876870763],
					[58.547497225263776, 1.70939739328772],
					[58.54749726465456, 1.709397388512548],
					[58.549088937604253, 1.7088473984135026],
					[58.550330608935695, 1.708791900851762],
					[58.550330640114545, 1.7087918943410028],
					[58.550330697434731, 1.7087918720241495],
					[58.550330725454934, 1.7087918575502514],
					[58.552177907716988, 1.7073223703025395],
					[58.55347508922403, 1.7066751794168515],
					[58.553475099719343, 1.7066751737749069],
					[58.553475119624586, 1.7066751592683398],
					[58.553475130022626, 1.7066751519220011],
					[58.554961229761226, 1.7054112528151675],
					[58.554961234020745, 1.7054112484755455],
					[58.554961241746234, 1.7054112416869298],
					[58.554961245908473, 1.7054112356428566],
					[58.556086244036663, 1.7042057395235144],
					[58.55728624177268, 1.702991841306785],
					[58.55728625108501, 1.7029918307355849],
					[58.557286267830733, 1.702991808261086],
					[58.557286275264119, 1.702991796357779],
					[58.558175138877004, 1.7013862621072222],
					[58.559616751294143, 1.7008584942159977],
					[58.560927792976663, 1.7006474042808735],
					[58.562408187107387, 1.7009918789611926],
					[58.563027644465294, 1.7017668252753346],
					[58.563027682141801, 1.7017668536250625],
					[58.563027766320317, 1.7017668912269921],
					[58.56302781282232, 1.7017669004791922],
					[58.564561077786792, 1.7016641019925085],
					[58.565524943873307, 1.7019473924064592],
					[58.56552498454375, 1.7019473942523864],
					[58.565525065996695, 1.7019473841183264],
					[58.565525104900253, 1.7019473708059891],
					[58.566169504709983, 1.7015501699029845],
					[58.566169511641746, 1.7015501650024607],
					[58.566169527189544, 1.7015501531237665],
					[58.566169534121322, 1.7015501482232456],
					[58.567158431955868, 1.7006584504306796],
					[58.568008428598908, 1.6999362527487674],
					[58.568008437214843, 1.6999362457698113],
					[58.568008450786117, 1.6999362308523547],
					[58.568008458413885, 1.6999362223547076],
					[58.568914057525944, 1.698766821387329],
					[58.568914062675987, 1.698766816858639],
					[58.568914070902302, 1.6987668030587959],
					[58.568914074966742, 1.6987667953063164],
					[58.569797375311069, 1.6971611951870718],
					[58.570683460684656, 1.695552922601804],
					[58.571950138634037, 1.6943251448483543],
					[58.571950147945522, 1.6943251342701555],
					[58.571950163896091, 1.6943251136734145],
					[58.571950171425961, 1.6943251034683233],
					[58.572636270684541, 1.6931890029837211],
					[58.57263627246612, 1.6931890026105578],
					[58.572636273259455, 1.693189000718716],
					[58.572636274052762, 1.6931889988268729],
					[58.573455763303613, 1.6917418192528069],
					[58.574572344155946, 1.6905362396654129],
					[58.575630740953578, 1.6894862418447947],
					[58.575630747689758, 1.689486233529659],
					[58.575630762943703, 1.6894862165259461],
					[58.575630768691596, 1.6894862066920908],
					[58.576450168706714, 1.6881834065148156],
					[58.576450170390729, 1.6881834044359016],
					[58.576450171183978, 1.6881834025437388],
					[58.576450172074765, 1.688183402356999],
					[58.576930771552739, 1.6873723018175355],
					[58.576930774127511, 1.6873722995518132],
					[58.576930777397976, 1.6873722936884146],
					[58.576930778093661, 1.687372290090762],
					[58.577006638356345, 1.6872226551429093],
					[58.576405424260109, 1.6886166023972551],
					[58.574535934991083, 1.6913692875115547],
					[58.574535929243041, 1.6913692973447898],
					[58.574535918637729, 1.691369316824612],
					[58.574535913780473, 1.6913693264712091],
					[58.573513735764664, 1.6939609712295585],
					[58.57219708471947, 1.6948887377526891],
					[58.572197065010762, 1.6948887556849777],
					[58.572197029546274, 1.6948887976244014],
					[58.572197015572101, 1.6948888212584718],
					[58.571544216457752, 1.6964110212330203],
					[58.571544210904101, 1.6964110344750341],
					[58.571544203554893, 1.6964110636234961],
					[58.571544200868495, 1.6964110797164793],
					[58.571366501486125, 1.6980832797168681],
					[58.571366509850307, 1.6980833314600385],
					[58.571366553010435, 1.69808342423731],
					[58.571366587806445, 1.6980834652714016],
					[58.572024888072704, 1.6985279656861971],
					[58.572024904788861, 1.698527974267052],
					[58.572024939905418, 1.6985279893506118],
					[58.572024958305803, 1.6985279958533288],
					[58.572685988642654, 1.6986695805665246],
					[58.574013716235406, 1.7003167891990723],
					[58.574302605358987, 1.7015250466472382],
					[58.574302610786582, 1.7015250627691025],
					[58.574302623228668, 1.7015250912293085],
					[58.574302631328699, 1.7015251067920298],
					[58.575344185588321, 1.7031778360286314],
					[58.574883104909617, 1.7051527554290622],
					[58.574883108023506, 1.7051528100024682],
					[58.574883143550828, 1.7051529112961759],
					[58.57488317586693, 1.7051529563110688],
					[58.576019185316014, 1.7060472848981927],
					[58.575652602372479, 1.7084749691260939],
					[58.575652606974842, 1.708475018212275],
					[58.57565263914632, 1.7084751081321075],
					[58.57565266582462, 1.7084751491519821],
					[58.576316477595263, 1.709072269911744],
					[58.575852605399248, 1.7110471536172691],
					[58.575852606437309, 1.7110472034487385],
					[58.575852630589615, 1.7110472950469449],
					[58.575852654691928, 1.711047338333006],
					[58.576846994861668, 1.7120917751079046],
					[58.576366499906371, 1.720280587534202],
					[58.576366503630247, 1.7202806212733384],
					[58.576366520585978, 1.7202806815898337],
					[58.576366534012031, 1.7202807115783074],
					[58.577174799527192, 1.7214806603779411],
					[58.57719420039966, 1.7257222012374982],
					[58.57719420689174, 1.7257222361263638],
					[58.577194232250811, 1.7257223015974728],
					[58.577194251117803, 1.7257223321796891],
					[58.577858150551911, 1.7264668328228738],
					[58.577858199108086, 1.7264668623924795],
					[58.577858303576512, 1.7264668923836566],
					[58.577858360282583, 1.7264668909138046],
					[58.579363959638783, 1.7259890911256961],
					[58.579363994593692, 1.7259890717538249],
					[58.579364054721658, 1.7259890195165857],
					[58.579364079991727, 1.7259889883569666],
					[58.579739043119176, 1.7252251628075466],
					[58.580208163242418, 1.7250669568149488],
					[58.580496999656269, 1.7261222278538644],
					[58.580505399683886, 1.7276361019095894],
					[58.580505400168974, 1.7276361104384839],
					[58.580505402030006, 1.7276361273104963],
					[58.580505403405951, 1.7276361356536272],
					[58.580697002854677, 1.728694436758675],
					[58.580697022400066, 1.7286944792860308],
					[58.58069707882305, 1.7286945520970156],
					[58.580697115603634, 1.7286945806748766],
					[58.581641548864013, 1.7291334501210511],
					[58.58211919678574, 1.7301833353965557],
					[58.58194420114981, 1.7324582845219718],
					[58.581944200046756, 1.7324582968343505],
					[58.581944201210206, 1.7324583173047392],
					[58.581944201791913, 1.7324583275399359],
					[58.582138702381506, 1.7338194279573846],
					[58.582138704938735, 1.7338194412331869],
					[58.582138712822747, 1.7338194689338382],
					[58.582138718052569, 1.7338194816528081],
					[58.582613681255992, 1.7348722008379938],
					[58.582149816914715, 1.7359388194998369],
					[58.582149810659899, 1.7359388363373578],
					[58.582149802701537, 1.7359388707904508],
					[58.582149800107096, 1.7359388885916129],
					[58.582060899946377, 1.7374527875953896],
					[58.582060903375222, 1.7374528162244895],
					[58.582060914590393, 1.7374528708491612],
					[58.582060925049376, 1.7374528962881663],
					[58.582724818405026, 1.7386528844215527],
					[58.583016500285346, 1.7394056371585085],
					[58.583021999792741, 1.7410722014539073],
					[58.583022003027132, 1.7410722266723011],
					[58.583022016622948, 1.7410722756255779],
					[58.583022026093509, 1.7410722995458761],
					[58.583877620313423, 1.7425722875380585],
					[58.584499805138599, 1.7440750517649966],
					[58.584594197202371, 1.7448305869796343],
					[58.584274807005357, 1.7460471491376688],
					[58.584274806151519, 1.7460471976478322],
					[58.584274825631688, 1.7460472868083599],
					[58.584274846953413, 1.7460473289794298],
					[58.585033146658517, 1.7469418289210843],
					[58.585033183338709, 1.7469418558156113],
					[58.585033261076859, 1.7469418914321002],
					[58.585033303916589, 1.7469418997834687],
					[58.585880603560696, 1.7469251998176927],
					[58.586538800564604, 1.7469141017923369],
					[58.587208124172577, 1.747811198514174],
					[58.587683114437866, 1.7490139728095748],
					[58.587683117691, 1.7490139824910098],
					[58.587683125882329, 1.7490139997771257],
					[58.587683130820508, 1.7490140073818115],
					[58.588347024836132, 1.7500611978546243],
					[58.588919211937004, 1.7512611704075374],
					[58.589210902174464, 1.7523167349787012],
					[58.589308101286882, 1.7535250151402528],
					[58.589308102060329, 1.7535250287907038],
					[58.589308106880814, 1.7535250502319806],
					[58.58930811023032, 1.7535250616206133],
					[58.589599810053691, 1.7544278614028326],
					[58.589599878645991, 1.7544279265629932],
					[58.589600041578016, 1.7544279703911068],
					[58.589600134930151, 1.7544279475378894],
					[58.590441612613056, 1.7536559520670238],
					[58.590922011798419, 1.7550083668318937],
					[58.590922017026259, 1.7550083795573042],
					[58.590922030154736, 1.7550084044526586],
					[58.590922037164397, 1.7550084168077649],
					[58.591777627165854, 1.7562029023677652],
					[58.59234981886344, 1.7574028866024849],
					[58.592349824788727, 1.7574028957307326],
					[58.592349835748379, 1.7574029141723233],
					[58.592349841673688, 1.7574029233005677],
					[58.593394238786878, 1.758744519136328],
					[58.594535928018516, 1.7603890036582077],
					[58.595299813088808, 1.7618861728256203],
					[58.595877606815151, 1.7639917522595601],
					[58.595877615197708, 1.7639917729665866],
					[58.595877635430007, 1.7639918119341813],
					[58.595877648170656, 1.7639918300097857],
					[58.596638746197208, 1.7648862275601627],
					[58.597588713560683, 1.7660778881825372],
					[58.597877603954835, 1.7674333420000183],
					[58.598266504382416, 1.7692417413732233],
					[58.598266505466327, 1.7692417446019824],
					[58.59826650575576, 1.7692417497223909],
					[58.59826650674318, 1.769241751244353],
					[58.598747006912809, 1.7710472515771856],
					[58.598747015294293, 1.7710472722876409],
					[58.598747036608422, 1.7710473144914372],
					[58.598747051130061, 1.7710473322015321],
					[58.599410947304406, 1.7717890286733953],
					[58.599888732763894, 1.7723862105252324],
					[58.600935910389659, 1.7743306704551012],
					[58.601422004167716, 1.7765889412799163],
					[58.601422005251443, 1.7765889445092067],
					[58.601422005540606, 1.7765889496301588],
					[58.601422006624325, 1.7765889528594443],
					[58.601710899361898, 1.7776417255278818],
					[58.601671999818862, 1.7796082964371691],
					[58.601672001384266, 1.7796083082016003],
					[58.60167200381678, 1.7796083353290952],
					[58.601672006369498, 1.7796083486159386],
					[58.602302606339848, 1.7821667479526495],
					[58.602302607616068, 1.7821667545963065],
					[58.602302611950435, 1.7821667675145221],
					[58.602302614117612, 1.7821667739736371],
					[58.602780414171086, 1.7833667731250535],
					[58.602780415254635, 1.7833667763546841],
					[58.602780417325484, 1.7833667811068796],
					[58.602780418312754, 1.7833667826294572],
					[58.603735908609011, 1.785463963648297],
					[58.604124804368261, 1.7872694422589095],
					[58.60412480535549, 1.7872694437816412],
					[58.604124805451725, 1.7872694454887945],
					[58.604510904760829, 1.7889250442058062],
					[58.604510908107329, 1.788925055602923],
					[58.604510913620707, 1.7889250734599946],
					[58.604510917761978, 1.7889250829655483],
					[58.604988718037838, 1.7899722823437936],
					[58.60498873778166, 1.7899723128008012],
					[58.604988788370939, 1.7899723627821462],
					[58.604988817434453, 1.7899723826752052],
					[58.605938816837174, 1.790405781289651],
					[58.605938838003155, 1.7904057890003429],
					[58.605938881732172, 1.7904057972239915],
					[58.605938904391472, 1.7904057994442173],
					[58.606833220004411, 1.7903863023814095],
					[58.607738726853604, 1.7912751099595201],
					[58.608313711001422, 1.7929250662330771],
					[58.608313716129594, 1.7929250772631993],
					[58.608313726289715, 1.7929250976160809],
					[58.608313732308886, 1.7929251084619107],
					[58.608697031621233, 1.7935195089192544],
					[58.608697033595533, 1.7935195119654863],
					[58.608697039326117, 1.7935195176893381],
					[58.608697041300424, 1.793519520735543],
					[58.60926374121793, 1.7942640215566479],
					[58.60926374417938, 1.7942640261261096],
					[58.609263751788056, 1.7942640331890434],
					[58.609263755736656, 1.7942640392816438],
					[58.609549811034498, 1.794561193184387],
					[58.609749801694115, 1.7959167289083648],
					[58.60974983108995, 1.7959167867414609],
					[58.609749917842123, 1.7959168707158841],
					[58.609749976185618, 1.7959168983804124],
					[58.610786075656208, 1.7960445983611579],
					[58.61078608288026, 1.7960445985947202],
					[58.610786097424494, 1.7960446007693704],
					[58.610786103661397, 1.7960445994796668],
					[58.611163903820675, 1.7960363003468467],
					[58.611163945601014, 1.7960362899797382],
					[58.611164021072348, 1.7960362536441894],
					[58.611164054763336, 1.7960362276757555],
					[58.611541855087268, 1.795575127218896],
					[58.611541872355815, 1.7955750977362133],
					[58.611541893938345, 1.7955750328127893],
					[58.611541900130447, 1.7955749987110459],
					[58.61153079976517, 1.7939138991237698],
					[58.611530800271474, 1.7939138921093014],
					[58.611530798803479, 1.793913882048372],
					[58.611530799405969, 1.7939138767414544],
					[58.611233498867414, 1.7913499769096446],
					[58.611233497687834, 1.7913499719714694],
					[58.611233496315847, 1.7913499636182546],
					[58.611233496027232, 1.7913499584957024],
					[58.611039095954119, 1.7904471584379278],
					[58.611039094870733, 1.7904471552072985],
					[58.611039093787319, 1.7904471519766914],
					[58.611039093691119, 1.7904471502691868],
					[58.610844610708959, 1.7896972191728175],
					[58.611405781722645, 1.7884750829363303],
					[58.611405788080511, 1.7884750678007131],
					[58.611405796052658, 1.7884750333291044],
					[58.61140579865414, 1.7884750155162124],
					[58.611491898814663, 1.7874167160606145],
					[58.611491900115318, 1.7874167071541134],
					[58.611491899947495, 1.7874166881869964],
					[58.611491898575252, 1.7874166798338931],
					[58.611191901291321, 1.784400001599201],
					[58.61136959586306, 1.7827333503667258],
					[58.612022384051286, 1.7812111785458731],
					[58.612022390022922, 1.7812111565786504],
					[58.612022398402345, 1.7812111133827133],
					[58.61202239982282, 1.7812110906311291],
					[58.611916899622571, 1.7789443907283975],
					[58.611916896684264, 1.7789443706077472],
					[58.611916887243801, 1.7789443311051787],
					[58.611916879947103, 1.7789443136154579],
					[58.611630791048235, 1.7783471351711877],
					[58.61133909992455, 1.7769916772833898],
					[58.611327999801851, 1.7756332981841949],
					[58.611319600165636, 1.7738193992582021],
					[58.611319595853409, 1.7738193707859307],
					[58.611319580294278, 1.7738193187347808],
					[58.611319568950947, 1.7738192934484567],
					[58.610558479669905, 1.7726249112312109],
					[58.61017239507327, 1.7715749496779727],
					[58.610077998372986, 1.7709721692247569],
					[58.610077987630639, 1.7709721386332613],
					[58.610077958320453, 1.7709720825288906],
					[58.610077938861643, 1.7709720572009138],
					[58.609316871082122, 1.7702331863242515],
					[58.608455787220862, 1.7679832290988773],
					[58.608455786136901, 1.7679832258692421],
					[58.608455784065519, 1.7679832211172357],
					[58.608455782981565, 1.7679832178875987],
					[58.607783483136515, 1.7664832173010192],
					[58.607783477211775, 1.7664832081673425],
					[58.607783466253203, 1.7664831897150077],
					[58.607783460328427, 1.7664831805813357],
					[58.606452959715909, 1.7646970816298784],
					[58.606452954778518, 1.7646970740190113],
					[58.606452944205905, 1.7646970623966554],
					[58.606452939365077, 1.7646970564929814],
					[58.605219545256517, 1.7635109611304107],
					[58.604175157379942, 1.7623220756257287],
					[58.603127976905888, 1.7608277038706044],
					[58.602458485391359, 1.7591777245409872],
					[58.602458485391359, 1.7591777245409872],
					[58.602458484307185, 1.759177721312182],
					[58.602075193741491, 1.7582777463491843],
					[58.601877997791384, 1.7569221715455674],
					[58.601877996417223, 1.7569221631961136],
					[58.601877992777993, 1.756922146682335],
					[58.601877991403839, 1.7569221383328588],
					[58.601494595003004, 1.7557193517046632],
					[58.601008496731318, 1.7530055653589505],
					[58.601008495260196, 1.7530055553029875],
					[58.601008489935388, 1.7530055408674612],
					[58.601008487573367, 1.753005530996764],
					[58.600625194370018, 1.7519555475985444],
					[58.600333497580067, 1.7502971708087631],
					[58.600230799213357, 1.7493916762891872],
					[58.600230791332372, 1.7493916485707104],
					[58.600230765673757, 1.7493915934658226],
					[58.600230748883774, 1.7493915676008809],
					[58.598902966629439, 1.7479026856488311],
					[58.598141877720153, 1.7464027106767184],
					[58.59814186972195, 1.7464026967996529],
					[58.598141850161959, 1.7464026697872037],
					[58.598141840381977, 1.7464026562809734],
					[58.597380756076625, 1.7456609731422501],
					[58.59614458815453, 1.7437166222978338],
					[58.59576129626938, 1.7419082584889505],
					[58.59576129291883, 1.7419082470994653],
					[58.595761285326844, 1.7419082245059911],
					[58.595761281085416, 1.7419082133020198],
					[58.595189089177858, 1.7407082325115069],
					[58.59470299416175, 1.7387499514498888],
					[58.594702992089353, 1.7387499467015322],
					[58.594702988738526, 1.7387499353126956],
					[58.594702986569231, 1.7387499288578314],
					[58.594130787164183, 1.737247128874436],
					[58.594130783019267, 1.7372471193780425],
					[58.594130773838558, 1.737247100570855],
					[58.594130768899653, 1.7372470929665589],
					[58.593369597017272, 1.7360499371617903],
					[58.593266900301998, 1.7339332891766934],
					[58.593266899217255, 1.7339332859495453],
					[58.593266898732509, 1.7339332774174305],
					[58.593266897647766, 1.7339332741902855],
					[58.593066898127262, 1.7324221733282144],
					[58.59306685169922, 1.7324221018565136],
					[58.593066719978367, 1.7324220205411516],
					[58.59306663468557, 1.732422010697529],
					[58.592597397989415, 1.7325830215079103],
					[58.592586300057881, 1.7307666995621129],
					[58.59258629800317, 1.730766679271343],
					[58.592586290427292, 1.7307666411392832],
					[58.592586283918273, 1.7307666217773816],
					[58.592014084450135, 1.7294138224244753],
					[58.592014082377403, 1.7294138176770331],
					[58.592014078231934, 1.7294138081821742],
					[58.592014075268331, 1.72941380362054],
					[58.590777977733168, 1.7271638088090879],
					[58.589825182161604, 1.7252138175212453],
					[58.589061283785384, 1.7234082218602202],
					[58.589061278748645, 1.723408212552817],
					[58.589061268578106, 1.7234081922318205],
					[58.589061262747599, 1.7234081848165226],
					[58.588208483349867, 1.7222110124037133],
					[58.587916900049798, 1.7211582735330282],
					[58.587916900320486, 1.7205499996123925],
					[58.587916892838841, 1.7205499631939187],
					[58.587916867379676, 1.7205498960011547],
					[58.587916847620463, 1.7205498655988718],
					[58.586966878098153, 1.7195081971730763],
					[58.586491891097786, 1.7179999397872321],
					[58.586491859579311, 1.7179998928538778],
					[58.586491777218917, 1.7179998237387246],
					[58.586491724595312, 1.7179998019290315],
					[58.58545306987633, 1.717866532873741],
					[58.585822388695398, 1.7168000643644945],
					[58.585822375430944, 1.7167999739154272],
					[58.585822278590499, 1.7167998405034719],
					[58.585822196990541, 1.7167998005803957],
					[58.585164059641158, 1.716810896261441],
					[58.584827995054788, 1.715302756992573],
					[58.584827993872423, 1.7153027520608415],
					[58.584827991604826, 1.7153027439033532],
					[58.584827990422433, 1.715302738971644],
					[58.584586302009555, 1.71454717481483],
					[58.584675199581973, 1.7130333114681582],
					[58.584675198026559, 1.7130332841733502],
					[58.584675186007431, 1.7130332314453864],
					[58.584675177325359, 1.713033205639894],
					[58.584197402944703, 1.7121332561763718],
					[58.584327999739749, 1.7098583112135417],
					[58.58432799964249, 1.7098583095076512],
					[58.584411296071224, 1.7081917913901756],
					[58.584744536306502, 1.7078834463349011],
					[58.584744554737092, 1.7078834217654757],
					[58.584744585070702, 1.7078833688129949],
					[58.58474459598564, 1.7078833389103183],
					[58.584836266735088, 1.7074279837234752],
					[58.584839069360065, 1.7074276841334359],
					[58.584913705725192, 1.7077250492907983],
					[58.584913706713316, 1.7077250508104052],
					[58.584913707895971, 1.7077250557418664],
					[58.584913708981354, 1.7077250589673942],
					[58.585255408599174, 1.7088389596743569],
					[58.585255413831433, 1.7088389723904787],
					[58.585255427065697, 1.708838998969838],
					[58.58525543506768, 1.7088390128330793],
					[58.58588313495644, 1.7097640115355128],
					[58.585883152352856, 1.7097640320665086],
					[58.585883191210733, 1.7097640653733106],
					[58.585883211878667, 1.7097640800413625],
					[58.586333212044153, 1.7099834801519125],
					[58.586333280913699, 1.7099834864685528],
					[58.586333407021357, 1.7099834531965064],
					[58.586333464259454, 1.7099834136078385],
					[58.586677964297941, 1.7094890146674087],
					[58.586677970144642, 1.7094890065394837],
					[58.586677979861847, 1.7094889872441574],
					[58.586677983829638, 1.7094889777827642],
					[58.587211283820302, 1.7082306779283225],
					[58.587211284516556, 1.7082306743299527],
					[58.587211287787973, 1.7082306684666511],
					[58.587211289472314, 1.7082306663880122],
					[58.587539055069122, 1.7073001636413385],
					[58.588627834887049, 1.7069779986703204],
					[58.590088912258359, 1.706889099224892],
					[58.590088916712418, 1.706889098293096],
					[58.590088926608679, 1.7068890979493003],
					[58.590088931953574, 1.7068890968311365],
					[58.590891614663242, 1.7067585168691397],
					[58.592213718985391, 1.708447292594552],
					[58.592735907652951, 1.7102667542531416],
					[58.592735913970444, 1.7102667701984597],
					[58.592735929277957, 1.7102668015301881],
					[58.592735937279869, 1.7102668153966067],
					[58.593674835303723, 1.7115779121040651],
					[58.594458132564299, 1.7127695093901822],
					[58.594458146105353, 1.7127695255531934],
					[58.594458174871932, 1.7127695558003322],
					[58.594458190891025, 1.7127695679918211],
					[58.595183191182613, 1.7132390675610505],
					[58.595183248957461, 1.7132390848376344],
					[58.595183364052765, 1.7132390797702617],
					[58.595183419688752, 1.7132390595052114],
					[58.595680719634842, 1.7128640592852562],
					[58.595680746780076, 1.7128640294348378],
					[58.595680787027575, 1.7128639605825366],
					[58.595680799239027, 1.7128639217668804],
					[58.595836299159977, 1.7113750210727472],
					[58.59583629877099, 1.7113750142468938],
					[58.595836299969172, 1.7113750036355424],
					[58.595836299580164, 1.7113749968096872],
					[58.595791899572291, 1.7087471975755475],
					[58.595780800106759, 1.7068471982371949],
					[58.595780798729415, 1.7068471898913828],
					[58.595780796670894, 1.7068471696004792],
					[58.595780796087062, 1.7068471593618284],
					[58.595222395764182, 1.7041555594594751],
					[58.595222392313019, 1.7041555463676394],
					[58.595222383726409, 1.7041555222633016],
					[58.595222379384445, 1.7041555093579321],
					[58.594333486908127, 1.702394326936491],
					[58.593814093096853, 1.7005777482137672],
					[58.593364094226096, 1.6987471519700914],
					[58.593364093140472, 1.6987471487440817],
					[58.593364090871816, 1.6987471405857937],
					[58.593364089688777, 1.6987471356535166],
					[58.592944601831931, 1.6974971736128468],
					[58.593025199605357, 1.6960194111667699],
					[58.593025199814015, 1.6960193990362473],
					[58.593025198352287, 1.6960193734422473],
					[58.593025195888565, 1.6960193618716994],
					[58.59255299609265, 1.6936082614568895],
					[58.592552992640449, 1.6936082483671657],
					[58.592552985040207, 1.6936082257868845],
					[58.5925529807947, 1.6936082145901044],
					[58.591908497764564, 1.6922499512323836],
					[58.591805800339955, 1.6899250017914116],
					[58.591883498901268, 1.6884472315402932],
					[58.592261293341622, 1.6870694523363772],
					[58.592261294732893, 1.6870694451378498],
					[58.592261298211113, 1.6870694271414939],
					[58.592261298614048, 1.6870694184236381],
					[58.592361298778485, 1.6860278196389309],
					[58.592361298680906, 1.6860278179327381],
					[58.592361298583327, 1.6860278162265439],
					[58.592361299376506, 1.6860278143334284],
					[58.592500199110781, 1.6841028147839345],
					[58.592500199708738, 1.6841028094783972],
					[58.592500200209081, 1.6841028024666498],
					[58.592500199916252, 1.6841027973480782],
					[58.592439100416655, 1.6794277967943838],
					[58.592439100318991, 1.6794277950882102],
					[58.592439100221334, 1.6794277933820383],
					[58.592439100123663, 1.6794277916758631],
					[58.592341899848869, 1.677247197884447],
					[58.592377999571958, 1.6747528023641207],
					[58.592377999571958, 1.6747528023641207],
					[58.592378000364967, 1.6747528004707779],
					[58.592378000267253, 1.6747527987646218],
					[58.592372400417901, 1.6729971996518271],
					[58.592372398354115, 1.672997179365308],
					[58.592372389870555, 1.6729971414345759],
					[58.592372385232281, 1.6729971234159178],
					[58.591800198763664, 1.6716277572140623],
					[58.591750199822854, 1.6688528098092481],
					[58.591964094974415, 1.6670611514320641],
					[58.59229188520704, 1.666275077613449],
					[58.592291886597131, 1.6662750704141744],
					[58.592291891256629, 1.6662750573468925],
					[58.592291892744569, 1.6662750518537375],
					[58.592950193062165, 1.6638250526867668],
					[58.59295019534288, 1.6638250452997712],
					[58.592950197232028, 1.6638250310882241],
					[58.592950197829133, 1.6638250257823339],
					[58.593191898264415, 1.6618833251502787],
					[58.593191899458489, 1.6618833145383614],
					[58.593191900065108, 1.6618832936895804],
					[58.593191899379804, 1.6618832817465754],
					[58.592908498632212, 1.6587082825016561],
					[58.592908498142499, 1.6587082739710635],
					[58.592908495381636, 1.6587082572850929],
					[58.592908494099149, 1.6587082506482198],
					[58.59188909439883, 1.654777749108447],
					[58.591889092617414, 1.6547777494838367],
					[58.591889093410124, 1.6547777475900931],
					[58.591325196065554, 1.6526749601333206],
					[58.591111297896767, 1.6510999736276206],
					[58.591111297700692, 1.6510999702156248],
					[58.59111129730853, 1.6510999633916095],
					[58.591111296221747, 1.6510999601673955],
					[58.590736295939728, 1.6492582597747878],
					[58.590736295841666, 1.6492582580688115],
					[58.590736294754819, 1.6492582548446799],
					[58.590736294754819, 1.6492582548446799],
					[58.590197396146721, 1.6470027595658143],
					[58.589711296580496, 1.644158265764837],
					[58.589711289960604, 1.6441582447157657],
					[58.58971127207117, 1.6441582001455508],
					[58.589711260107158, 1.6441581802241736],
					[58.589322390245535, 1.6436360206446849],
					[58.589066896538512, 1.6420666674472888],
					[58.589066896244027, 1.6420666623296905],
					[58.589066893873685, 1.6420666524704877],
					[58.589066892688521, 1.6420666475408823],
					[58.58854189923661, 1.6401055711885268],
					[58.588505800229079, 1.6383555957785272],
					[58.588505798356323, 1.638355578908369],
					[58.588505791938736, 1.638355545732302],
					[58.588505787393942, 1.6383555294264029],
					[58.587719596410103, 1.6362888523581347],
					[58.587550199073092, 1.6348527767687058],
					[58.587550197789398, 1.6348527701338127],
					[58.587550195123761, 1.6348527551582808],
					[58.587550193938327, 1.634852750229143],
					[58.586891899906007, 1.6323277746335929],
					[58.586900200200219, 1.630863901485395],
					[58.586900198031579, 1.6308638794993193],
					[58.586900188251967, 1.6308638349510647],
					[58.586900180739342, 1.6308638140946043],
					[58.586058485363068, 1.6290999235475641],
					[58.585394589138289, 1.6271610353721055],
					[58.585394581920134, 1.6271610196342519],
					[58.585394564811892, 1.6271609887236],
					[58.58539455492182, 1.6271609735507959],
					[58.584550164174047, 1.6261331849710789],
					[58.583844589077657, 1.62493322678098],
					[58.583477994803779, 1.6232388573506085],
					[58.58347799470539, 1.6232388556450885],
					[58.583477993617919, 1.6232388524224917],
					[58.583477994410167, 1.6232388505285127],
					[58.583125194351851, 1.6218332523437107],
					[58.582591895191705, 1.619580554186044],
					[58.582591890841535, 1.6195805412964135],
					[58.582591883327169, 1.6195805204450135],
					[58.582591878184793, 1.6195805094493732],
					[58.581558480829266, 1.6175582142134715],
					[58.580914090481471, 1.6160582365845269],
					[58.580166894312605, 1.6131082502353933],
					[58.580166872058314, 1.61310820834242],
					[58.580166811317568, 1.6131081400784404],
					[58.58016677184191, 1.6131081121908104],
					[58.578583371938024, 1.6124970134542576],
					[58.578583356304655, 1.6124970081354939],
					[58.578583325333518, 1.6124970026136771],
					[58.578583308908051, 1.6124969991888447],
					[58.577202809332427, 1.6124359005377016],
					[58.577202785979026, 1.6124359020326418],
					[58.57720274223972, 1.6124359095720402],
					[58.577202720963207, 1.6124359158051926],
					[58.576091621138481, 1.6129137154560507],
					[58.576091588279944, 1.6129137396757933],
					[58.576091533743046, 1.6129137943771545],
					[58.576091513250951, 1.612913829785489],
					[58.575672012788267, 1.6140110290703618],
					[58.575672009718318, 1.6140110383503599],
					[58.575672005359721, 1.6140110565331252],
					[58.575672003180429, 1.6140110656245021],
					[58.575438706243183, 1.615363848442565],
					[58.574980413585926, 1.6166110262608784],
					[58.574391514870157, 1.6180277227665909],
					[58.574391511799924, 1.6180277320458976],
					[58.574391507342256, 1.6180277485224572],
					[58.574391505162666, 1.6180277576132602],
					[58.573963707890769, 1.6199999437609831],
					[58.573444211572173, 1.6214027302619314],
					[58.573444210976788, 1.6214027355654301],
					[58.573444207807889, 1.6214027431391751],
					[58.573444207114058, 1.6214027467376453],
					[58.573191507041386, 1.6223249465656164],
					[58.57319150495988, 1.6223249573608818],
					[58.573191500698442, 1.622324977246403],
					[58.573191499605997, 1.622324989558297],
					[58.573108106297823, 1.6237998914867113],
					[58.572544293376744, 1.6241859306794346],
					[58.571833200263171, 1.6245970275228241],
					[58.571833192444053, 1.6245970326276065],
					[58.571833175915145, 1.624597043025455],
					[58.57183316908506, 1.6245970496468833],
					[58.571413769069565, 1.6249609494177297],
					[58.571413764714677, 1.6249609520642088],
					[58.571413758775179, 1.6249609584971605],
					[58.571413755409324, 1.6249609626602537],
					[58.570580454870992, 1.6258331619019275],
					[58.570580445565568, 1.6258331724974906],
					[58.570580428932757, 1.6258331967218735],
					[58.570580422397647, 1.6258332084575178],
					[58.570174821668196, 1.6266305093009619],
					[58.570174816815992, 1.6266305189549075],
					[58.57017480988187, 1.6266305394029639],
					[58.570174806018642, 1.6266305505735712],
					[58.569861036917722, 1.6278522394630388],
					[58.569308482627015, 1.6267749175552069],
					[58.568661286022312, 1.6251277273656144],
					[58.568661285033286, 1.6251277258490815],
					[58.568661283847561, 1.6251277209229207],
					[58.568661282858542, 1.6251277194064004],
					[58.568180800226948, 1.6240388596232231],
					[58.568194599680481, 1.6227222019812275],
					[58.568194598696522, 1.6227221849334514],
					[58.568194593067631, 1.6227221498864581],
					[58.568194587630408, 1.6227221337803677],
					[58.567702987771078, 1.6213416322243746],
					[58.567702983618027, 1.6213416227493542],
					[58.567702975213464, 1.6213416020945826],
					[58.567702969377528, 1.6213415947010508],
					[58.567039068966515, 1.6202831935371107],
					[58.567039053338235, 1.6202831726853815],
					[58.567039014361136, 1.6202831377915987],
					[58.567038991902976, 1.6202831235611386],
					[58.565552892441993, 1.6195081232012065],
					[58.565552882251176, 1.6195081184550477],
					[58.565552859197624, 1.6195081095279351],
					[58.565552848214587, 1.6195081066748107],
					[58.564494448005291, 1.619247006535379],
					[58.56449440021057, 1.6192470062929794],
					[58.56449431045251, 1.6192470287303646],
					[58.564494268390703, 1.6192470497055804],
					[58.563472099691992, 1.6201498224157069],
					[58.562438840945852, 1.6204692093475659],
					[58.562438817193687, 1.6204692195465489],
					[58.562438772853355, 1.6204692479018872],
					[58.562438753057428, 1.6204692641654008],
					[58.560841553320294, 1.6221970642027344],
					[58.560841549063838, 1.6221970685530478],
					[58.56084154233222, 1.6221970768770968],
					[58.56084153906481, 1.6221970827435233],
					[58.559738739263885, 1.6236970827109252],
					[58.559738734116706, 1.6236970872491792],
					[58.559738725898811, 1.6236971010622692],
					[58.559738721839039, 1.6236971088210097],
					[58.558660922179641, 1.6257777083033593],
					[58.558660919010414, 1.625777715873423],
					[58.558660911879606, 1.6257777329060583],
					[58.558660908710365, 1.6257777404761236],
					[58.557852608641589, 1.6283971409183453],
					[58.55785260576711, 1.6283971536008865],
					[58.557852601105409, 1.6283971821864685],
					[58.557852600012254, 1.6283971944927946],
					[58.557799801430818, 1.6305971686562648],
					[58.557033107746641, 1.6332082445421365],
					[58.557033107746641, 1.6332082445421365],
					[58.557033107052462, 1.633208248138676],
					[58.557033107052462, 1.633208248138676],
					[58.556591507344898, 1.6348888490219906],
					[58.556591505759975, 1.6348888528064118],
					[58.55659150446975, 1.6348888617036017],
					[58.556591502884842, 1.6348888654880054],
					[58.556116507799096, 1.6375971377281033],
					[58.55511092256544, 1.6396638057533262],
					[58.554088727251809, 1.6414416004969403],
					[58.554088722398333, 1.6414416101443197],
					[58.554088712691396, 1.6414416294390608],
					[58.554088708826711, 1.6414416406028443],
					[58.55350810931602, 1.6432888394478746],
					[58.553508105647389, 1.6432888540195556],
					[58.553508100982278, 1.6432888825998053],
					[58.553508099985734, 1.6432888966083596],
					[58.553488700445428, 1.6446082975134979],
					[58.553488702905561, 1.6446083245921919],
					[58.553488716732801, 1.6446083768728312],
					[58.553488727111109, 1.6446084005583235],
					[58.554347001769997, 1.646072257729579],
					[58.554405400044899, 1.6475222044513318],
					[58.55443040033331, 1.6500027893152138],
					[58.554163700875741, 1.6523832778266698],
					[58.554163701169713, 1.6523832829392922],
					[58.554163700074199, 1.6523832952437212],
					[58.554163700368171, 1.6523833003563626],
					[58.554172000079021, 1.6544277841244532],
					[58.55376370344846, 1.6569777686051295],
					[58.553763701764943, 1.6569777706840578],
					[58.553763701167988, 1.6569777759840498],
					[58.553763701363849, 1.6569777793924609],
					[58.553499801763209, 1.6595027672954568],
					[58.552819205979603, 1.6625360504550797],
					[58.552113709805425, 1.6649888377299233],
					[58.551258112752137, 1.6673221298046754],
					[58.55026091887607, 1.6698221143218146],
					[58.549308123720252, 1.6715832058784823],
					[58.549308122829494, 1.6715832060654447],
					[58.549308122134185, 1.6715832096605101],
					[58.549308121341184, 1.6715832115515152],
					[58.548363737925669, 1.6734915772128371],
					[58.547161003173343, 1.6745637173231493],
					[58.545569359061567, 1.6749720047740315],
					[58.544699968166583, 1.6751137029527841],
					[58.544699926691678, 1.6751137185493716],
					[58.544699857384586, 1.6751137675729419],
					[58.54469982678242, 1.675113799856585],
					[58.543674826567631, 1.6768887998113564],
					[58.543674824188287, 1.6768888054830979],
					[58.543674819331997, 1.6768888151227661],
					[58.543674816061881, 1.6768888209812811],
					[58.542533124614664, 1.6795555006977867],
					[58.541424842909741, 1.6810498757471373],
					[58.540583145991832, 1.6820637722830512],
					[58.540583135985635, 1.6820637864482431],
					[58.540583119633894, 1.6820638157357957],
					[58.54058311239757, 1.6820638310447642],
					[58.540122011795297, 1.6833082304328011],
					[58.540122008121934, 1.6833082449949373],
					[58.540122001665971, 1.683308273932643],
					[58.540121999871715, 1.6833082898252532],
					[58.539902599714758, 1.6871360882730033],
					[58.53990259990978, 1.6871360916802995],
					[58.53990260029984, 1.687136098494902],
					[58.539902599604062, 1.687136102088703],
					[58.539938699702446, 1.6900472028223956],
					[58.539938699799912, 1.6900472045260535],
					[58.539938699897384, 1.6900472062297158],
					[58.539938699994849, 1.6900472079333959],
					[58.539999800110976, 1.6916417079458295],
					[58.540108099257402, 1.6943971861256963],
					[58.539622002806929, 1.6971027642697094],
					[58.539622002403028, 1.6971027729743311],
					[58.539621999716218, 1.6971027890523784],
					[58.53962200020311, 1.6971027975707405],
					[58.53956649995429, 1.7013443975146505],
				],
			],
		},
		{
			fldNpdidField: "43610",
			wlbNpdidWellbore: "392",
			fldName: "ODIN",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "30/10-2",
			fldDiscoveryYear: "1974",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43610",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43610",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "903",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=392",
			fldHcType: "GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "28974935",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[59.999955399931643, 2.1479583004849943],
					[59.999969200025149, 2.1527889011089405],
					[59.999969200210558, 2.1527889046688311],
					[59.999969199782612, 2.1527889137536769],
					[59.999969200859496, 2.1527889171284857],
					[60.000435900689965, 2.1583722178002493],
					[60.000435900967858, 2.1583722231402236],
					[60.000435903399278, 2.1583722352302885],
					[60.000435903769812, 2.1583722423502527],
					[60.001880404279703, 2.1651417426870885],
					[60.001880405356289, 2.165141746062496],
					[60.001880407602009, 2.1651417545934217],
					[60.001880408771136, 2.1651417597489235],
					[60.004197009047772, 2.1725750601720266],
					[60.004197010031739, 2.1725750617676742],
					[60.004197012092114, 2.1725750667392294],
					[60.004197012184555, 2.1725750685194742],
					[60.006280411692821, 2.1782167683782641],
					[60.00628041778095, 2.1782167815145401],
					[60.006280430756355, 2.1782168058222657],
					[60.006280438627556, 2.1782168185896134],
					[60.008019238615844, 2.180589017621593],
					[60.008019269790857, 2.1805890453652235],
					[60.008019342192839, 2.1805890861761306],
					[60.008019383419835, 2.1805890992434347],
					[60.010194382789955, 2.1807751998538838],
					[60.010194401696801, 2.1807751995422193],
					[60.010194439325829, 2.1807751953576573],
					[60.010194458047984, 2.1807751914847802],
					[60.013000057703692, 2.1799307912294932],
					[60.013000064835936, 2.1799307897537608],
					[60.013000078024184, 2.1799307834252564],
					[60.013000085064071, 2.1799307801687755],
					[60.014869485354474, 2.1790501806146838],
					[60.014869499341813, 2.1790501723200721],
					[60.014869523750413, 2.1790501564688789],
					[60.014869535862331, 2.179050146762445],
					[60.017691809046383, 2.1764195719693462],
					[60.020083327088045, 2.175455805004368],
					[60.022616613410648, 2.1757584909839589],
					[60.024774847224023, 2.1777279313809292],
					[60.027538725621419, 2.1819250992146682],
					[60.028758120597239, 2.1843861883425162],
					[60.028758131419508, 2.1843862059111765],
					[60.028758155554037, 2.1843862369318847],
					[60.028758168866304, 2.1843862503839162],
					[60.030663763019923, 2.1860307460322077],
					[60.032055439411863, 2.1874473227014231],
					[60.033480427042022, 2.1899167997341471],
					[60.033480428025818, 2.1899168013316994],
					[60.035266528147886, 2.1929279017083534],
					[60.035266539768486, 2.1929279173169398],
					[60.035266566575885, 2.192927947796989],
					[60.035266581670463, 2.1929279608864589],
					[60.037069282232324, 2.1942529602997332],
					[60.037069302676144, 2.1942529722849056],
					[60.037069347837161, 2.194252991769774],
					[60.037069371570624, 2.1942529976716245],
					[60.040072171930731, 2.1946779972649924],
					[60.040072189146798, 2.1946779991106511],
					[60.040072222410807, 2.194677997639451],
					[60.040072239442509, 2.1946779959205736],
					[60.042305588665769, 2.1942335052261925],
					[60.044213794114349, 2.1948279768879777],
					[60.045549820941503, 2.1961418031236684],
					[60.046505404758726, 2.1997500435229016],
					[60.04744150269854, 2.2047278373453474],
					[60.04744150368218, 2.2047278389439846],
					[60.047441503774216, 2.2047278407266795],
					[60.047441503866274, 2.204727842509389],
					[60.048883103551368, 2.2115083413837495],
					[60.04888311590566, 2.2115083712675632],
					[60.048883146579399, 2.2115084243996033],
					[60.048883166774026, 2.2115084490628543],
					[60.051094266983334, 2.2134834482407721],
					[60.051094310542759, 2.2134834716927161],
					[60.051094406273904, 2.2134834933962799],
					[60.051094455770873, 2.2134834921994364],
					[60.052708356066496, 2.2130168926985498],
					[60.052708380663134, 2.2130168804181531],
					[60.05270842610615, 2.2130168530268448],
					[60.052708447752217, 2.2130168359490123],
					[60.054850148274411, 2.2106779342141669],
					[60.054850155930751, 2.2106779254261544],
					[60.054850171059428, 2.210677904283902],
					[60.054850176748651, 2.2106778922975554],
					[60.057327977421345, 2.205930693507689],
					[60.057327978128839, 2.2059306897571158],
					[60.05732797972788, 2.2059306858224677],
					[60.057327982310568, 2.2059306834869483],
					[60.058380747712398, 2.2036557557667313],
					[60.060988924018964, 2.2025946067769056],
					[60.063566646639785, 2.2030057934295684],
					[60.065944297863687, 2.2039334744393275],
					[60.068513774144527, 2.2060279541785768],
					[60.068513777003353, 2.2060279571937196],
					[60.068513784596178, 2.2060279646395102],
					[60.06851378914606, 2.206027965502471],
					[60.07067768923168, 2.2074751652699973],
					[60.070677689323709, 2.2074751670539712],
					[60.070677691106845, 2.2074751666856676],
					[60.070677691198874, 2.207475168469625],
					[60.072230490779027, 2.2084807684274135],
					[60.072230519338085, 2.2084807805596545],
					[60.072230577779209, 2.2084807955356229],
					[60.072230607753305, 2.2084808001634002],
					[60.073575007611772, 2.2084251997344162],
					[60.073575040600041, 2.2084251929209309],
					[60.073575102458101, 2.2084251693258454],
					[60.073575130436346, 2.2084251527283847],
					[60.07498623000938, 2.2072140526814259],
					[60.07498624273866, 2.2072140374292202],
					[60.074986265614569, 2.2072140092615502],
					[60.07498627745229, 2.2072139941935349],
					[60.076302957258221, 2.2047362300821267],
					[60.078072274693973, 2.2033279966582238],
					[60.080197179344623, 2.2031974017511602],
					[60.084127746155481, 2.2042890930958796],
					[60.084127747047042, 2.2042890929115426],
					[60.084127749813831, 2.2042890941431996],
					[60.084127750705392, 2.2042890939588609],
					[60.087491650889362, 2.2051473933502788],
					[60.087491653656137, 2.2051473945821174],
					[60.087491660972837, 2.2051473966771087],
					[60.087491663739627, 2.2051473979089455],
					[60.091158263960253, 2.2058112975108815],
					[60.091158294641829, 2.2058112983832494],
					[60.091158356344131, 2.2058112892332757],
					[60.091158385581686, 2.2058112795796179],
					[60.093819486076882, 2.2045390800420579],
					[60.093819501848785, 2.2045390713671846],
					[60.093819531333111, 2.2045390490306676],
					[60.093819544246095, 2.2045390373386322],
					[60.097425144259439, 2.2007779374494532],
					[60.097425149424467, 2.2007779327714942],
					[60.097425158770854, 2.2007779218147356],
					[60.097425162952206, 2.2007779155359426],
					[60.10004186282611, 2.1970807166156514],
					[60.10004186860597, 2.1970807063955466],
					[60.100041880257841, 2.1970806877408475],
					[60.100041884162408, 2.1970806761045383],
					[60.10247518397302, 2.191272277328332],
					[60.102475187262321, 2.1912722712321648],
					[60.102475191166313, 2.1912722595943248],
					[60.102475192580286, 2.1912722520821899],
					[60.104030792776413, 2.1855361518718448],
					[60.104030795695991, 2.1855361386320959],
					[60.104030798583501, 2.1855361073505493],
					[60.104030799627672, 2.1855360926951204],
					[60.103866900280195, 2.1808943923699093],
					[60.103866900095355, 2.1808943887985874],
					[60.103866898649294, 2.1808943782697732],
					[60.103866898372026, 2.1808943729128165],
					[60.103180797824869, 2.1759193724268004],
					[60.103180796655899, 2.1759193672552586],
					[60.10318079628594, 2.1759193601128555],
					[60.103180795209447, 2.1759193567269084],
					[60.101786294996806, 2.1697610553600293],
					[60.101786294996806, 2.1697610553600293],
					[60.101786294904251, 2.1697610535745295],
					[60.101583494841741, 2.1689110545660184],
					[60.101583468977914, 2.1689110076079223],
					[60.101583396594989, 2.168910932425788],
					[60.101583351152563, 2.1689109075872932],
					[60.100188951371706, 2.1685442072642753],
					[60.100188922288282, 2.1685442024853638],
					[60.100188864769571, 2.1685442054254001],
					[60.10018883614908, 2.1685442095735374],
					[60.098780596823744, 2.169019189795931],
					[60.097594522628519, 2.1686609283404188],
					[60.09620849684417, 2.1667166317724824],
					[60.096080800923602, 2.1636583162786356],
					[60.096622395918487, 2.1610444417279986],
					[60.096622397145367, 2.1610444306456609],
					[60.096622398892968, 2.1610444122369716],
					[60.096622400119827, 2.1610444011546428],
					[60.096647400052213, 2.1582028026581077],
					[60.096647399217716, 2.1582027865914815],
					[60.096647393890095, 2.1582027534157757],
					[60.096647390473862, 2.1582027396913586],
					[60.095383489850335, 2.1542610385558572],
					[60.095383483666545, 2.154261023604616],
					[60.095383467640289, 2.1542609926601575],
					[60.09538345789062, 2.1542609784520104],
					[60.09338345830534, 2.1516637773262701],
					[60.093383455445334, 2.1516637743137847],
					[60.093383448741029, 2.1516637666897038],
					[60.093383444989556, 2.1516637638630498],
					[60.091122348714165, 2.1492692655844023],
					[60.088508455774956, 2.1462276754325167],
					[60.086405759919458, 2.1434165791177144],
					[60.086405755183456, 2.1434165746940068],
					[60.086405745525639, 2.1434165622774159],
					[60.086405740789658, 2.1434165578537052],
					[60.084200169272343, 2.1412331860393374],
					[60.082214086027314, 2.1365332262711121],
					[60.080597390704114, 2.1322666399118697],
					[60.079436292810477, 2.1279110489371531],
					[60.079436286437677, 2.1279110304298436],
					[60.079436268250397, 2.1279109927492859],
					[60.07943625652905, 2.127910975360165],
					[60.078158457189112, 2.1262887753541744],
					[60.078158403158319, 2.1262887415637866],
					[60.078158284386404, 2.126288710489781],
					[60.078158219831565, 2.1262887167742668],
					[60.077272120247251, 2.1266776163570018],
					[60.077272112317587, 2.1266776198184503],
					[60.077272098334234, 2.126677628152613],
					[60.077272091295995, 2.1266776314276794],
					[60.076960991206093, 2.1268804311038969],
					[60.076960990407798, 2.1268804330742825],
					[60.076960988624961, 2.1268804334470306],
					[60.076960987733536, 2.1268804336334042],
					[60.074144288227679, 2.1287804334075457],
					[60.074144287429348, 2.1287804353776969],
					[60.074144283956734, 2.128780437906757],
					[60.074144283065309, 2.1287804380930564],
					[60.07123048882481, 2.1308887337184932],
					[60.068941605062797, 2.1322831247542422],
					[60.067274915086536, 2.1330692182961521],
					[60.067274901994125, 2.1330692264386717],
					[60.067274877592148, 2.1330692423514259],
					[60.067274867267059, 2.1330692517190379],
					[60.065241567185481, 2.1348915518097691],
					[60.062838771253674, 2.1370165460927835],
					[60.060597073995055, 2.1388331452746501],
					[60.060597066250878, 2.1388331522974804],
					[60.060597049964031, 2.1388331683122459],
					[60.060597042312828, 2.1388331771182498],
					[60.058130441778616, 2.1420054766171659],
					[60.058130439382971, 2.142005482523746],
					[60.058130432530056, 2.1420054893594695],
					[60.058130430134426, 2.1420054952660665],
					[60.056922038264609, 2.1439637812123671],
					[60.055247048858398, 2.1459026689934646],
					[60.055247040408048, 2.1459026797649385],
					[60.05524702547595, 2.1459027045022783],
					[60.055247018901348, 2.1459027166852507],
					[60.054191518963584, 2.148180516121823],
					[60.054191518072116, 2.1481805163074545],
					[60.054191517366277, 2.1481805200587765],
					[60.054191515676166, 2.14818052221288],
					[60.052874815737518, 2.1512888225164968],
					[60.052874815737518, 2.1512888225164968],
					[60.052874814938825, 2.1512888244848178],
					[60.05160261965257, 2.1543999117354273],
					[60.050083127615572, 2.1569748981511427],
					[60.050083126017995, 2.1569749020871827],
					[60.050083122730165, 2.156974908176617],
					[60.050083121039904, 2.156974910329994],
					[60.048494221229639, 2.1601777107084055],
					[60.048494217235465, 2.1601777205476567],
					[60.048494210045931, 2.1601777382582945],
					[60.048494206943225, 2.1601777479122855],
					[60.047697006877144, 2.1630943469219721],
					[60.047697006263476, 2.1630943524548236],
					[60.047697003959456, 2.1630943601405641],
					[60.047697003345782, 2.1630943656734174],
					[60.046985903272507, 2.1671693634959346],
					[60.046288703869543, 2.1706138602630891],
					[60.046288703163057, 2.1706138640130641],
					[60.046288701750072, 2.1706138715130296],
					[60.046288701842592, 2.1706138732955309],
					[60.045844202716722, 2.1740693612275703],
					[60.045258120566629, 2.1763609933150838],
					[60.04415818428533, 2.1773720354056874],
					[60.042441654825524, 2.1782580975901382],
					[60.040419500650827, 2.1781859026061134],
					[60.038680759542693, 2.175913779822602],
					[60.036933478811619, 2.1735388057192782],
					[60.035672394326227, 2.1698138486504464],
					[60.03463080070582, 2.1643138879720993],
					[60.034927999312238, 2.1601194193834532],
					[60.035430798740855, 2.1560361240561678],
					[60.035430799354245, 2.1560361185252788],
					[60.035430799689564, 2.1560361076487924],
					[60.035430800210243, 2.156036100336042],
					[60.035455800069073, 2.1526722010937349],
					[60.035455798621143, 2.1526721905879653],
					[60.035455797415516, 2.1526721674238187],
					[60.03545579498337, 2.15267215532156],
					[60.034808494623256, 2.1498110569109388],
					[60.034808493360678, 2.1498110499691436],
					[60.034808490036824, 2.1498110380527691],
					[60.034808487882756, 2.1498110312964211],
					[60.034150188447015, 2.1480027322521686],
					[60.034150175373107, 2.1480027061551672],
					[60.034150143356214, 2.1480026623865824],
					[60.034150123428972, 2.1480026431187502],
					[60.032605723090569, 2.1467915430131215],
					[60.032605707193113, 2.1467915319132458],
					[60.032605672018015, 2.1467915140188967],
					[60.032605653631784, 2.1467915070389099],
					[60.031311153729504, 2.1464304063168895],
					[60.031311105924949, 2.1464304054589851],
					[60.031311014196902, 2.146430426347596],
					[60.031310971072052, 2.1464304461269954],
					[60.029641571416022, 2.1478442474835253],
					[60.029641565361338, 2.1478442523447501],
					[60.029641554236271, 2.1478442636632842],
					[60.029641549073055, 2.1478442683390573],
					[60.02770545366436, 2.1500887636196149],
					[60.025930461075411, 2.1518137568963218],
					[60.025930454221836, 2.1518137637228936],
					[60.02593044239039, 2.1518137787867677],
					[60.02593043642829, 2.1518137854280179],
					[60.023047043711095, 2.1559137746877708],
					[60.020533164997524, 2.1586609506187164],
					[60.017824890442519, 2.1606665320252008],
					[60.016108287178362, 2.1616553767956694],
					[60.014244512644758, 2.1608554317022954],
					[60.012555741987704, 2.1592192585607553],
					[60.011008463991949, 2.1575887827801599],
					[60.009891878068792, 2.1554499093398101],
					[60.008925180596762, 2.1535249157318601],
					[60.007094583793638, 2.1492582208549971],
					[60.007094582809401, 2.1492582192599294],
					[60.007094581825157, 2.149258217664864],
					[60.007094581825157, 2.149258217664864],
					[60.005772381811973, 2.1463749171240796],
					[60.0057723808277, 2.1463749155291603],
					[60.005772380734903, 2.1463749137489083],
					[60.005772379843435, 2.1463749139342236],
					[60.004444579648705, 2.1435999140272783],
					[60.004444565868532, 2.14359989170045],
					[60.004444531269314, 2.14359985030997],
					[60.004444511434592, 2.1435998328411032],
					[60.002947311371692, 2.1426054322549462],
					[60.00294725559985, 2.1426054168630575],
					[60.002947147417466, 2.1426054159696561],
					[60.002947092332541, 2.1426054310243616],
					[60.001544292195504, 2.1434998310837403],
					[60.001544273233137, 2.1434998476216958],
					[60.001544238261282, 2.1434998854814928],
					[60.001544224034753, 2.1434999064326239],
					[60.000444224008582, 2.1455610061922386],
					[60.00044421832515, 2.1455610181690021],
					[60.00044420803534, 2.1455610454971388],
					[60.000444204320431, 2.1455610606631987],
					[59.999955404130979, 2.1479582600299301],
					[59.999955402811892, 2.1479582693000405],
					[59.999955400266479, 2.147958289620191],
					[59.999955399931643, 2.1479583004849943],
				],
			],
		},
		{
			fldNpdidField: "97002",
			wlbNpdidWellbore: "72",
			fldName: "HULDRA",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "30/2-1",
			fldDiscoveryYear: "1982",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=97002",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=97002",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "878",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=72",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "28973861",
			fldDateUpdated: "2022-10-11 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[60.798763709347803, 2.6451028375783143],
					[60.798763716783192, 2.6451028803352243],
					[60.801960917214387, 2.6522944814289309],
					[60.801960927982513, 2.6522944995656124],
					[60.801960952108914, 2.6522945334808568],
					[60.801960966272851, 2.65229454725596],
					[60.809822066146964, 2.6594362478681619],
					[60.809822068996937, 2.6594362509897698],
					[60.809822075589153, 2.6594362570558143],
					[60.809822079331397, 2.6594362600002337],
					[60.81610817879492, 2.6641890601568345],
					[60.816108201880667, 2.6641890721719435],
					[60.816108247879185, 2.664189092548209],
					[60.816108273382326, 2.6641890985510792],
					[60.824347172888118, 2.6653057986097584],
					[60.824347180112952, 2.6653057990201239],
					[60.824347194562606, 2.6653057998408771],
					[60.824347201787425, 2.6653058002512435],
					[60.828469402300556, 2.6652641008192748],
					[60.835133302304392, 2.6651946003530695],
					[60.843566651008828, 2.6651058003868049],
					[60.8510277065864, 2.669047377591709],
					[60.851027708370999, 2.6690473772372609],
					[60.851027709263292, 2.6690473770600387],
					[60.851027710242043, 2.6690473787117939],
					[60.854172109948543, 2.6706223790801777],
					[60.854172136430265, 2.670622386745463],
					[60.854172188328491, 2.6706223985949165],
					[60.85417221357212, 2.6706223991207532],
					[60.860641714374928, 2.6701529989425814],
					[60.860641726953524, 2.6701529982904342],
					[60.860641750153192, 2.6701529936816071],
					[60.860641761666599, 2.6701529895476557],
					[60.86573335216444, 2.6684918933110398],
					[60.871616734324995, 2.6672251962896567],
					[60.878475025176598, 2.6663501980541668],
					[60.878475026068898, 2.6663501978766657],
					[60.885144350837251, 2.6654752089000429],
					[60.887499887847092, 2.667058466516063],
					[60.887499945358911, 2.6670584827966928],
					[60.887500057932904, 2.6670584825763828],
					[60.887500113800876, 2.6670584640668351],
					[60.891608414027367, 2.6642001638679327],
					[60.89160842099249, 2.6642001587841309],
					[60.891608434836172, 2.6642001467852316],
					[60.89160844180131, 2.6642001417014147],
					[60.8972807131601, 2.658502969714692],
					[60.903355633340517, 2.6560224021974781],
					[60.908455585757245, 2.6563723997859321],
					[60.908455671353451, 2.6563723623658735],
					[60.908455774290019, 2.6563722345593237],
					[60.908455791543687, 2.6563721423406119],
					[60.907269591646127, 2.6523582437080102],
					[60.907269589601398, 2.6523582385677718],
					[60.907269588362205, 2.6523582314172964],
					[60.90726958631749, 2.6523582262770757],
					[60.904111289759982, 2.6443388364721661],
					[60.902130793397468, 2.6371138478065164],
					[60.902130789219967, 2.6371138356979142],
					[60.902130779080508, 2.6371138118376711],
					[60.902130773031615, 2.6371137982542536],
					[60.899566873410457, 2.6327137994335867],
					[60.899566867622141, 2.6327137913469771],
					[60.899566856045496, 2.6327137751737619],
					[60.899566850257152, 2.6327137670871594],
					[60.893272349748464, 2.6255415682663812],
					[60.893272327203206, 2.6255415487501064],
					[60.893272274344014, 2.6255415168199798],
					[60.893272245901613, 2.625541505879911],
					[60.883461150736508, 2.6232415058503649],
					[60.876789015054257, 2.6213109248888351],
					[60.869900154307821, 2.612944273213504],
					[60.869900149585142, 2.6129442686185431],
					[60.869900140944708, 2.6129442574198554],
					[60.869900136309248, 2.6129442546547685],
					[60.862233435883624, 2.6057998531025008],
					[60.862233401562897, 2.6057998322870954],
					[60.862233329159146, 2.6057998061868188],
					[60.862233290096626, 2.6057997992516202],
					[60.85144719011155, 2.6063331003056205],
					[60.851447168611791, 2.6063331027722416],
					[60.851447128638071, 2.6063331144836974],
					[60.851447110076798, 2.6063331218997292],
					[60.841655510087399, 2.6112776205060411],
					[60.841655503124322, 2.6112776255926393],
					[60.841655490003099, 2.6112776337588532],
					[60.841655483844981, 2.6112776368384467],
					[60.833047083701288, 2.617408136520913],
					[60.833047076825082, 2.6174081434324892],
					[60.833047062985507, 2.6174081554278525],
					[60.833047056022146, 2.6174081605116415],
					[60.826008165642456, 2.6247220517425789],
					[60.819155511285103, 2.630022016782811],
					[60.810730551572497, 2.632127605754389],
					[60.810730530332705, 2.6321276136785152],
					[60.810730489898347, 2.6321276346504821],
					[60.810730470703803, 2.6321276476983382],
					[60.801533170629888, 2.6398581478053593],
					[60.801533157767054, 2.639858161426837],
					[60.801533133912649, 2.6398581901404965],
					[60.801533123007914, 2.6398582070587464],
					[60.798763722992227, 2.6451027076848828],
					[60.798763712408949, 2.6451027503401976],
					[60.798763709347803, 2.6451028375783143],
				],
			],
		},
		{
			fldNpdidField: "32017325",
			wlbNpdidWellbore: "6484",
			fldName: "JOHAN CASTBERG",
			fldCurrentActivitySatus: "Approved for production",
			wlbName: "7220/8-1",
			fldDiscoveryYear: "2011",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=32017325",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=32017325",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "532",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=6484",
			fldHcType: "OIL/GAS",
			fldMainArea: "Barents sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "5463659",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Hammerfest",
			fldAreaGeometryWKT: [
				[
					[72.470069199965025, 20.319077801035174],
					[72.47007759976934, 20.321261102165341],
					[72.470077600688285, 20.321261117861315],
					[72.470077607088967, 20.321261149120676],
					[72.470077610812723, 20.321261163570494],
					[72.470566507091561, 20.322683352751103],
					[72.470916501937765, 20.324355630765382],
					[72.471130400384183, 20.32652501008053],
					[72.471130400011134, 20.328222194802169],
					[72.471008099820168, 20.330897190604293],
					[72.470877600171505, 20.333572188613605],
					[72.470877600003519, 20.333572191530081],
					[72.470877599667546, 20.333572197363075],
					[72.470877600378529, 20.333572200836969],
					[72.470877599669805, 20.334786101160702],
					[72.47087760004473, 20.33478611046759],
					[72.470877601673564, 20.334786129638818],
					[72.470877603974429, 20.33478613714415],
					[72.471297003645986, 20.336941736757087],
					[72.471297004356884, 20.336941740231161],
					[72.471297005778695, 20.336941747179381],
					[72.471297007368548, 20.336941751211061],
					[72.471863706126868, 20.339086147773124],
					[72.472274804849789, 20.340758343269869],
					[72.472419200707392, 20.341477822011271],
					[72.472497000022258, 20.343652806259975],
					[72.472497017004713, 20.343652859367861],
					[72.472497075671157, 20.343652948007279],
					[72.472497119281172, 20.343652981738042],
					[72.472977719346574, 20.343866882915663],
					[72.472977727425047, 20.343866885020738],
					[72.472977741655995, 20.343866891031659],
					[72.472977750613381, 20.343866893694702],
					[72.473738769783481, 20.34406127250687],
					[72.473877627620638, 20.344294501335163],
					[72.473877727779751, 20.344294549807998],
					[72.473877909541429, 20.344294526122006],
					[72.473877993069976, 20.344294452162337],
					[72.474144592603992, 20.343308352174439],
					[72.474144596081416, 20.343308339263835],
					[72.474144599352485, 20.343308314127633],
					[72.474144600193142, 20.343308299543065],
					[72.474144599813613, 20.342336099907897],
					[72.47414459872823, 20.342336087124348],
					[72.474144594967726, 20.342336057524395],
					[72.474144592124503, 20.342336043624947],
					[72.473939106195104, 20.341622192793917],
					[72.474205781743294, 20.340391801873164],
					[72.474755727471418, 20.339872354432501],
					[72.475508257237976, 20.339339177562323],
					[72.476202640094272, 20.340264020947235],
					[72.476202652942149, 20.340264035150483],
					[72.476202681610843, 20.340264059396077],
					[72.476202698142515, 20.340264072913637],
					[72.477310974598453, 20.340922357971614],
					[72.478416555676873, 20.342066837953567],
					[72.478416591544885, 20.34206686375218],
					[72.47841667006756, 20.342066892439359],
					[72.478416713433063, 20.342066898803591],
					[72.479447150723814, 20.342000204183279],
					[72.480552697174446, 20.342661273610499],
					[72.480552715968201, 20.342661279496262],
					[72.480552754266483, 20.342661294743927],
					[72.480552774107295, 20.342661298269906],
					[72.48179714178481, 20.342825195227899],
					[72.48268877867514, 20.343252963885369],
					[72.483108152087965, 20.343711234022539],
					[72.483108164935544, 20.343711248233944],
					[72.483108192014043, 20.343711268459977],
					[72.483108206076849, 20.343711277392984],
					[72.483519306000034, 20.343930775144706],
					[72.483519352712975, 20.343930786667734],
					[72.483519446437001, 20.343930788726514],
					[72.483519492737315, 20.343930775785548],
					[72.485100092960721, 20.343102975727724],
					[72.485100102254435, 20.343102972555187],
					[72.485100121178064, 20.343102960372754],
					[72.485100129929094, 20.343102950804507],
					[72.485716829973398, 20.342575150864629],
					[72.485716844502377, 20.342575135889543],
					[72.485716869165728, 20.342575103147638],
					[72.485716880179012, 20.34257508593916],
					[72.486197363747976, 20.341575120792903],
					[72.486747145316812, 20.341055926179003],
					[72.487235906130394, 20.341994459707028],
					[72.487585900640482, 20.344886118837437],
					[72.487808100697094, 20.347788913367818],
					[72.48780810107138, 20.347788922683684],
					[72.487808103035249, 20.347788936035968],
					[72.487808105335574, 20.347788943550114],
					[72.488433100181027, 20.350663923367534],
					[72.488441500166985, 20.352847201830937],
					[72.488441500541001, 20.3528472111472],
					[72.488441503046857, 20.352847230897655],
					[72.4884415042998, 20.35284724077291],
					[72.4889970032023, 20.355488936739484],
					[72.489280402678673, 20.35716943148249],
					[72.489280410289027, 20.357169457508732],
					[72.489280432167007, 20.357169504717795],
					[72.489280446434606, 20.357169525900602],
					[72.490044218771644, 20.358094495141707],
					[72.490530408220749, 20.359766756928423],
					[72.490530480867491, 20.359766824349066],
					[72.490530649800434, 20.359766865279351],
					[72.490530746255061, 20.359766835869632],
					[72.491494521749729, 20.358733462153772],
					[72.492041790142991, 20.358458479215802],
					[72.492041818698198, 20.358458458040449],
					[72.492041867356505, 20.358458404257021],
					[72.492041887459649, 20.358458371649043],
					[72.492522327874227, 20.357214123459148],
					[72.493622169284251, 20.357389097280794],
					[72.493622179120308, 20.357389100513537],
					[72.493622201055246, 20.357389099338],
					[72.493622211938558, 20.357389100210217],
					[72.494591712016287, 20.357330801321734],
					[72.49548329583493, 20.3572752017554],
					[72.497002726181805, 20.35742519391621],
					[72.497619280889097, 20.357875160065092],
					[72.498519280329802, 20.358547359191832],
					[72.498519312137788, 20.358547376415203],
					[72.498519378353365, 20.358547397377613],
					[72.498519411882086, 20.358547400557097],
					[72.499413789690792, 20.358491907588739],
					[72.50038042834214, 20.360133400577812],
					[72.500380486721355, 20.36013344684045],
					[72.50038062212559, 20.360133484619229],
					[72.500380697392785, 20.360133475015925],
					[72.500869353060295, 20.359864155245109],
					[72.502044223217752, 20.361250099139834],
					[72.502524809811746, 20.362680664041758],
					[72.502524817216198, 20.3626806778447],
					[72.50252483114626, 20.362680704890678],
					[72.502524840308453, 20.362680719813387],
					[72.503394240128898, 20.363844519949286],
					[72.503394299012555, 20.363844557467978],
					[72.503394428600799, 20.36384458551958],
					[72.503394499473842, 20.36384457313109],
					[72.503875099107375, 20.363572374027253],
					[72.503875135873244, 20.363572336876594],
					[72.503875186386182, 20.363572250937185],
					[72.503875200301621, 20.363572199226944],
					[72.503869601739837, 20.361870130962419],
					[72.504288728059564, 20.36257510220458],
					[72.50428875023627, 20.362575128449592],
					[72.504288799826213, 20.362575169131446],
					[72.504288828828734, 20.362575187609771],
					[72.504836028722579, 20.362783486925693],
					[72.504836123288257, 20.362783474483464],
					[72.504836258362189, 20.362783375778761],
					[72.504836299749229, 20.36278329007623],
					[72.504761299667365, 20.361083292231843],
					[72.504761299293776, 20.361083282906961],
					[72.504761298209687, 20.361083270100469],
					[72.504761295909944, 20.36108326257747],
					[72.504486298027075, 20.359638873033578],
					[72.504264099295824, 20.3569777840241],
					[72.504264092732768, 20.356977755613958],
					[72.504264072070541, 20.356977703081132],
					[72.50426405709247, 20.356977678398792],
					[72.503716895189797, 20.35628052414598],
					[72.503502999240908, 20.353619384368848],
					[72.503502997445821, 20.35361936808269],
					[72.503502989124499, 20.353619338555966],
					[72.50350298452436, 20.353619323512863],
					[72.50280849518451, 20.351958249232986],
					[72.502525198187513, 20.349786074710053],
					[72.502525196766229, 20.349786067748656],
					[72.502525194802516, 20.349786054385163],
					[72.502525193212961, 20.349786050345209],
					[72.501961296179886, 20.347630559156233],
					[72.501541901310574, 20.344738891254416],
					[72.501611298385285, 20.343519435975942],
					[72.502152991061493, 20.341780660044844],
					[72.50215299227672, 20.341780654760836],
					[72.502152994370832, 20.341780650035673],
					[72.502152993660033, 20.341780646555335],
					[72.50269457129923, 20.339558443458362],
					[72.503936103886048, 20.338994617464341],
					[72.505455481676179, 20.339625166862991],
					[72.507530441042974, 20.341930721077738],
					[72.508285932027036, 20.343100109257481],
					[72.508285956132397, 20.343100133680597],
					[72.508286012926177, 20.343100175869459],
					[72.508286044735584, 20.34310019307599],
					[72.508980463227815, 20.343300169465191],
					[72.510222022878793, 20.345655693110725],
					[72.510222031163366, 20.345655707472165],
					[72.510222048947782, 20.345655730908884],
					[72.51022205915838, 20.345655743466278],
					[72.510916534357563, 20.346341816577855],
					[72.511680417771871, 20.348000082837896],
					[72.511680473012618, 20.348000136182932],
					[72.511680602304992, 20.348000185157119],
					[72.511680677067304, 20.348000184268681],
					[72.512366746209651, 20.34771409809758],
					[72.512916713291588, 20.347677999292198],
					[72.512916772027538, 20.347677976079286],
					[72.512916863262689, 20.347677894769696],
					[72.512916896472532, 20.347677840155612],
					[72.51312231513981, 20.34669212646806],
					[72.513952650985345, 20.347614032582872],
					[72.513952662242943, 20.347614042780322],
					[72.513952684758152, 20.347614063175261],
					[72.513952697941804, 20.347614071568529],
					[72.515060998221657, 20.348275173600094],
					[72.515061008936797, 20.348275177392082],
					[72.515061031956634, 20.34827518901875],
					[72.515061043718916, 20.348275190446891],
					[72.516433170944026, 20.348677971601624],
					[72.517472030703445, 20.350319505650322],
					[72.51851653094009, 20.351958405476953],
					[72.518516534119058, 20.351958413564617],
					[72.518516540981935, 20.351958420967943],
					[72.518516544329202, 20.351958426131592],
					[72.519827633016916, 20.35358341170614],
					[72.520591514247116, 20.355000075930526],
					[72.520941504951352, 20.356197247741367],
					[72.521216504231759, 20.357644436726726],
					[72.521216577963031, 20.357644517079425],
					[72.521216754096073, 20.357644559635094],
					[72.521216856329431, 20.357644524762424],
					[72.521836256275378, 20.356875125710793],
					[72.521836270600232, 20.356875098460861],
					[72.521836292929336, 20.356875042963537],
					[72.521836300054744, 20.3568750141559],
					[72.521902987953681, 20.355894590208862],
					[72.522452704184204, 20.355861305512128],
					[72.522797038837794, 20.356327919819513],
					[72.52279714896072, 20.356327953637837],
					[72.522797328892466, 20.356327898551655],
					[72.522797399580213, 20.356327810207464],
					[72.522866900435915, 20.354861109272615],
					[72.52286690006207, 20.354861099938418],
					[72.522866899146024, 20.354861084194681],
					[72.522866898772165, 20.354861074860452],
					[72.522719599259474, 20.353649983007021],
					[72.522614100326351, 20.351949986789222],
					[72.52261409890518, 20.351949979819793],
					[72.522614096941695, 20.351949966441026],
					[72.522614096399394, 20.351949960031618],
					[72.522225199719074, 20.350022181270255],
					[72.52222519964748, 20.348317226561647],
					[72.52284154979327, 20.349011233366404],
					[72.522841620778706, 20.349011266461247],
					[72.522841765235881, 20.349011273596602],
					[72.522841837996964, 20.349011244152436],
					[72.523391838181624, 20.348489046289746],
					[72.52339184308093, 20.34848904031487],
					[72.523391851832372, 20.348489030730029],
					[72.523391856731635, 20.348489024755164],
					[72.523597179803872, 20.348230946131984],
					[72.524916524258813, 20.350344496017982],
					[72.52623591909034, 20.353188985054576],
					[72.52623592067981, 20.353188989100307],
					[72.526235927374273, 20.353188999432671],
					[72.526235929842628, 20.353189004038619],
					[72.527685922449407, 20.3555389916683],
					[72.528585915764666, 20.357680676490642],
					[72.528585921411619, 20.357680689191131],
					[72.528585934631707, 20.357680712787548],
					[72.528585942204771, 20.357680723683533],
					[72.529488718995538, 20.358844492844845],
					[72.529838707778097, 20.360041756082058],
					[72.529838774982508, 20.360041823222371],
					[72.529838936210879, 20.360041871484597],
					[72.529839030234783, 20.36004185260644],
					[72.530455729934573, 20.359516850449381],
					[72.530455754432694, 20.359516820579],
					[72.530455789366982, 20.35951675186714],
					[72.530455798924308, 20.359516712464984],
					[72.5305251619573, 20.358539441621456],
					[72.531594240795627, 20.359936220580586],
					[72.531594245021552, 20.359936226310371],
					[72.531594251884087, 20.359936233722411],
					[72.531594256109983, 20.359936239452189],
					[72.532699821916822, 20.361089001677872],
					[72.53325540760828, 20.363005656052827],
					[72.533255419780374, 20.363005682024824],
					[72.533255449903052, 20.363005728555724],
					[72.533255467853721, 20.363005749114642],
					[72.534294216973564, 20.363919506263716],
					[72.534927604231385, 20.367058341526423],
					[72.534927612177228, 20.367058361771054],
					[72.534927632463024, 20.367058405066324],
					[72.534927644971646, 20.367058425190514],
					[72.535683145481528, 20.367989024975977],
					[72.535683148828369, 20.367989030147719],
					[72.535683158158562, 20.367989042175083],
					[72.535683162552786, 20.36798904498151],
					[72.53665263406721, 20.368905718596569],
					[72.5378331180045, 20.371522283464433],
					[72.537833118883327, 20.371522284025957],
					[72.537833121351241, 20.371522288637468],
					[72.537833122230083, 20.371522289199021],
					[72.538313721945428, 20.37247228892101],
					[72.538313737800152, 20.37247231422624],
					[72.538313774746769, 20.372472353009638],
					[72.538313797259136, 20.372472373465051],
					[72.539422049607481, 20.373136244632143],
					[72.540322014198452, 20.374791776974121],
					[72.540741507983256, 20.376233357463757],
					[72.540741546012057, 20.376233409093555],
					[72.540741648327867, 20.376233483613142],
					[72.540741712073455, 20.376233500086386],
					[72.541497024405572, 20.376189111809843],
					[72.541641501249103, 20.377400024588603],
					[72.541641522952816, 20.377400074890929],
					[72.541641590186217, 20.377400157341981],
					[72.541641635715905, 20.377400189490686],
					[72.542258236028985, 20.377608489440032],
					[72.542258311838452, 20.377608486315417],
					[72.542258439454741, 20.377608422220767],
					[72.542258489166528, 20.377608365981949],
					[72.542602989023578, 20.376613964209604],
					[72.542602992672101, 20.376613948329545],
					[72.542602999090406, 20.376613916007528],
					[72.542603000102503, 20.376613898441676],
					[72.54259459992889, 20.375147284823576],
					[72.542869284712154, 20.37488648373348],
					[72.543152605373621, 20.376091747362953],
					[72.543152643233753, 20.376091801927423],
					[72.543152746259722, 20.376091879946703],
					[72.543152811594268, 20.376091900473803],
					[72.544044411928439, 20.37603909990538],
					[72.544388910714019, 20.376019599865451],
					[72.544388911592833, 20.376019600427416],
					[72.54438891335046, 20.376019601551366],
					[72.544730612740381, 20.37599740025064],
					[72.544730674453348, 20.375997372925728],
					[72.544730765869005, 20.375997288698482],
					[72.544730798376818, 20.375997230554148],
					[72.54500579278556, 20.374272257765202],
					[72.545350182314536, 20.373516783246071],
					[72.545350188936595, 20.373516763192413],
					[72.545350197786647, 20.373516720275674],
					[72.545350200014582, 20.373516697412569],
					[72.545341899690058, 20.372783342260174],
					[72.545686178223846, 20.372030908954812],
					[72.546585951277748, 20.372711238017757],
					[72.547485931017803, 20.374125106620117],
					[72.547485954071476, 20.374125133507597],
					[72.547486005584858, 20.374125172521804],
					[72.54748603563354, 20.374125188700891],
					[72.54810263731369, 20.374333454718087],
					[72.548522007621159, 20.37577505581314],
					[72.548522050754187, 20.37577511376654],
					[72.548522165169445, 20.375775183911362],
					[72.54852223452535, 20.375775197907224],
					[72.550591724137689, 20.375405798056612],
					[72.552033249628266, 20.375319602569004],
					[72.553138779614557, 20.375989061266601],
					[72.55390266644946, 20.376677948209142],
					[72.553902690177509, 20.376677963392112],
					[72.553902739728628, 20.376677989023751],
					[72.553902768356863, 20.376677998229962],
					[72.554999898308864, 20.376858486798572],
					[72.556244251258832, 20.378252932386609],
					[72.556244252847719, 20.378252936441417],
					[72.556244258830688, 20.378252943308677],
					[72.556244262345942, 20.378252945558639],
					[72.556724846779531, 20.37871403122184],
					[72.557213736084591, 20.379419514091168],
					[72.557213742946317, 20.379419521521775],
					[72.557213759137454, 20.379419541000765],
					[72.557213766877993, 20.379419548993965],
					[72.558249823780557, 20.380339011251831],
					[72.558455408147054, 20.381058354777608],
					[72.558455425251807, 20.381058390035715],
					[72.558455471188253, 20.381058446793187],
					[72.558455498803681, 20.381058473590191],
					[72.559219261244777, 20.381502951832815],
					[72.559874836340185, 20.382447315446292],
					[72.559874918743532, 20.382447356066841],
					[72.559875084761089, 20.382447353005738],
					[72.559875168375228, 20.382447309324071],
					[72.560700162409631, 20.381175116131523],
					[72.561316855936724, 20.380402925492593],
					[72.561316858910658, 20.380402921319625],
					[72.561316862932145, 20.380402914778742],
					[72.561316865906036, 20.380402910605746],
					[72.56166126565374, 20.379894512054857],
					[72.5616612736967, 20.379894498972668],
					[72.561661288362444, 20.379894465821309],
					[72.561661292890165, 20.379894450488088],
					[72.561927956375357, 20.378900189838898],
					[72.562827721947826, 20.378844604187307],
					[72.563513757973396, 20.379539039582507],
					[72.563513792956925, 20.379539065025568],
					[72.563513869715536, 20.379539092909415],
					[72.563513911153137, 20.379539101212217],
					[72.564827810997102, 20.379464099671026],
					[72.564827811875887, 20.379464100233861],
					[72.565788912340196, 20.379405800646488],
					[72.565788977906593, 20.379405769694362],
					[72.565789071925636, 20.379405671861072],
					[72.565789100209557, 20.379405607911281],
					[72.56585019913598, 20.377686141933598],
					[72.566261284798031, 20.376683374608884],
					[72.566261290035655, 20.376683362765224],
					[72.566261296116807, 20.376683336264122],
					[72.566261298886673, 20.376683319800694],
					[72.566466880849831, 20.374714089438829],
					[72.566741712155178, 20.374697401603491],
					[72.566741782789762, 20.374697361719704],
					[72.566741875723721, 20.374697251004989],
					[72.566741898360448, 20.37469717431086],
					[72.566314098262353, 20.371536073527249],
					[72.566314098262353, 20.371536073527249],
					[72.566108501624498, 20.370080596340816],
					[72.566375197911739, 20.367613926557471],
					[72.566850196750039, 20.364891734044019],
					[72.566850197929767, 20.364891713522091],
					[72.566850198194487, 20.364891677217212],
					[72.566850194811451, 20.364891656815985],
					[72.566427994831812, 20.362958257238375],
					[72.566427994831812, 20.362958257238375],
					[72.566427994289981, 20.362958250812959],
					[72.566427994289981, 20.362958250812959],
					[72.565872394375845, 20.360786049031979],
					[72.565872388018846, 20.360786032809891],
					[72.565872371620856, 20.360786001049433],
					[72.565872362627246, 20.360785983141433],
					[72.564697376603505, 20.359141604535179],
					[72.564141886711184, 20.357705529240675],
					[72.564141876127948, 20.357705507281128],
					[72.56414184918296, 20.357705468785532],
					[72.56414183386849, 20.357705449879862],
					[72.563377933970926, 20.357019249518572],
					[72.563377918151133, 20.357019239408011],
					[72.563377884585449, 20.357019220994765],
					[72.563377866839502, 20.357019212692023],
					[72.56221122081493, 20.356602630589485],
					[72.561652956483471, 20.355899877075348],
					[72.561652948031465, 20.355899865597586],
					[72.561652930790657, 20.355899848503999],
					[72.561652921122999, 20.355899842326579],
					[72.560761221922334, 20.355219241506195],
					[72.559586255414885, 20.354313766393563],
					[72.558547375144457, 20.352419304260032],
					[72.558547360876688, 20.352419282995854],
					[72.558547328320543, 20.352419247013749],
					[72.558547309153298, 20.352419231734505],
					[72.557511208603813, 20.351747033152652],
					[72.557511198057085, 20.35174702641778],
					[72.557511175037561, 20.351747014755883],
					[72.557511162564694, 20.351747009828834],
					[72.556266790704242, 20.351335919056506],
					[72.555305741287228, 20.350660956002482],
					[72.554261259820024, 20.349258181636191],
					[72.55426125136762, 20.349258170166475],
					[72.554261230610578, 20.34925815084269],
					[72.554261221110963, 20.349258141741799],
					[72.553366821494436, 20.348580440107938],
					[72.553366738615949, 20.348580423657335],
					[72.553366591091788, 20.348580454023285],
					[72.553366524351802, 20.348580505577367],
					[72.552819229855501, 20.34959159540449],
					[72.552269339199441, 20.350358041387995],
					[72.551436300014458, 20.349922077687047],
					[72.551436300022914, 20.348452810716473],
					[72.551575198923501, 20.34722222061415],
					[72.551575197801142, 20.347222192567106],
					[72.551575188188608, 20.34722213784524],
					[72.551575178819533, 20.347222110609625],
					[72.551225178725119, 20.346511012742074],
					[72.551225171319686, 20.346510998907117],
					[72.551225151945999, 20.346510971362655],
					[72.551225140856658, 20.346510958213958],
					[72.550530740671803, 20.345819258371474],
					[72.550530704636387, 20.345819235382294],
					[72.550530627872291, 20.345819207665876],
					[72.550530586264628, 20.345819202377861],
					[72.550255702900287, 20.345838691556402],
					[72.549636267404935, 20.344897090147846],
					[72.549636213248689, 20.344897049530225],
					[72.549636090650395, 20.344897010801546],
					[72.549636022919088, 20.344897016180283],
					[72.548255584930516, 20.345471990287365],
					[72.547500076826125, 20.34527481274306],
					[72.546739061246313, 20.344835960735029],
					[72.546189086925096, 20.343402729452329],
					[72.546189058387412, 20.343402686968659],
					[72.546188985399141, 20.343402625248444],
					[72.546188940069641, 20.343402605451455],
					[72.545288972817943, 20.343216512373715],
					[72.544533467018354, 20.342772064652049],
					[72.543908489647748, 20.340858237586456],
					[72.543908484878671, 20.340858225440325],
					[72.543908474293417, 20.340858203516049],
					[72.543908467766457, 20.340858190249438],
					[72.543144593184735, 20.339683230620103],
					[72.542930798001834, 20.337988876306429],
					[72.542930793736929, 20.337988855377457],
					[72.542930782944879, 20.337988821183632],
					[72.542930775706907, 20.337988804430644],
					[72.542166882752753, 20.336569316504271],
					[72.541541887216923, 20.334897129008201],
					[72.541541884037244, 20.334897120912903],
					[72.541541875752017, 20.334897106530491],
					[72.541541871693411, 20.334897097875331],
					[72.540436271927319, 20.333016598745697],
					[72.540436268579526, 20.333016593578975],
					[72.540436261173113, 20.333016579758084],
					[72.54043625589938, 20.333016576399615],
					[72.539877956416504, 20.332316574113911],
					[72.539877941642317, 20.332316561671547],
					[72.539877911214987, 20.332316536227133],
					[72.539877893803919, 20.332316522105696],
					[72.539055738050493, 20.331880447168743],
					[72.53843076565154, 20.330944288252514],
					[72.538430669715908, 20.330944245383229],
					[72.538430491637357, 20.330944268560597],
					[72.538430411252378, 20.330944335726389],
					[72.53791921120397, 20.332441636173559],
					[72.537919206847988, 20.332441648571731],
					[72.53791920165186, 20.332441675606557],
					[72.53791920010076, 20.332441686756273],
					[72.537819200553088, 20.334158285033276],
					[72.537619200601924, 20.336124979360356],
					[72.537619201351291, 20.336124998043438],
					[72.53761920372898, 20.336125035969431],
					[72.537619207994112, 20.336125056891703],
					[72.537972000367063, 20.337325028322034],
					[72.537971999773333, 20.338788840245037],
					[72.537627750254515, 20.339296918384367],
					[72.53701121676859, 20.338849837651729],
					[72.537011204463482, 20.338849829812979],
					[72.537011176169472, 20.338849814822993],
					[72.537011160891353, 20.338849811158802],
					[72.536383476028675, 20.338647048390925],
					[72.536002997136023, 20.336472166796984],
					[72.536002997304124, 20.336472163870003],
					[72.536002994835371, 20.336472159263732],
					[72.536002995003457, 20.336472156336757],
					[72.535447395211392, 20.334066656320037],
					[72.535447379855796, 20.334066622271845],
					[72.535447338803905, 20.334066559727273],
					[72.53544731206064, 20.334066533598183],
					[72.534789012416667, 20.333619235433869],
					[72.534788990274834, 20.333619224371187],
					[72.534788943897198, 20.333619206980352],
					[72.534788919661366, 20.333619200652159],
					[72.533302962879574, 20.333469214914114],
					[72.532539094851913, 20.330099956527658],
					[72.532539091840064, 20.330099945510888],
					[72.53253908564831, 20.33009992640379],
					[72.532539081589505, 20.330099917754186],
					[72.531777987680812, 20.328441630666916],
					[72.530800192461342, 20.325088843199602],
					[72.530800162329399, 20.325088796738285],
					[72.530800079834037, 20.325088726073115],
					[72.530800029228558, 20.325088702987319],
					[72.529625131629885, 20.324919217025439],
					[72.528794578825099, 20.323263810374378],
					[72.528794574598066, 20.323263804654079],
					[72.528794568069856, 20.323263791405289],
					[72.528794563842823, 20.323263785684951],
					[72.527825185016823, 20.321863817217878],
					[72.527336294346, 20.319947151438502],
					[72.527336292755862, 20.319947147395442],
					[72.52733629187685, 20.319947146836771],
					[72.52733629204468, 20.31994714391103],
					[72.526847392045298, 20.318269344119638],
					[72.526847384805563, 20.318269327389864],
					[72.526847366810102, 20.318269291696026],
					[72.526847356765543, 20.318269276216242],
					[72.526297385010125, 20.317572111166083],
					[72.525811295419274, 20.315411056159768],
					[72.525811279181298, 20.31541102158765],
					[72.525811235486174, 20.315410957445017],
					[72.52581120698224, 20.315410930241615],
					[72.525116859731853, 20.314969263473721],
					[72.524422385210073, 20.313308223105032],
					[72.524422370226503, 20.31330819843005],
					[72.524422335361109, 20.313308155064945],
					[72.524422313721217, 20.313308135258318],
					[72.523452958348287, 20.312638766312482],
					[72.522902980920648, 20.311458216237394],
					[72.52290297933034, 20.311458212196097],
					[72.522902974391627, 20.311458202997283],
					[72.522902971922292, 20.311458198397883],
					[72.522208471943927, 20.310285998481152],
					[72.522208465247147, 20.310285988167021],
					[72.522208448337437, 20.310285965306662],
					[72.522208438835875, 20.310285956243501],
					[72.521033451589389, 20.309144269399077],
					[72.519858461582118, 20.307516582697243],
					[72.519858454006126, 20.307516571827726],
					[72.519858435881773, 20.30751655426463],
					[72.519858426380068, 20.307516545204145],
					[72.51902792619839, 20.306841544954473],
					[72.519027912133822, 20.30684153603065],
					[72.519027882246633, 20.306841517067532],
					[72.519027866256366, 20.30684150995279],
					[72.517852951089594, 20.306430442562824],
					[72.517364084911051, 20.305244325140297],
					[72.517364044937125, 20.30524427553863],
					[72.517363946317076, 20.305244216011225],
					[72.517363887127146, 20.305244199679315],
					[72.516127914076819, 20.305327591130506],
					[72.515091874856921, 20.303447103366526],
					[72.515091842291113, 20.303447067564143],
					[72.515091764056052, 20.303447017951992],
					[72.515091718554388, 20.303447001218288],
					[72.513572218907115, 20.303305399730483],
					[72.513572200655844, 20.303305400277605],
					[72.513572165911427, 20.303305402486611],
					[72.513572149250678, 20.303305407072166],
					[72.51232775028268, 20.303633107482984],
					[72.511025131209877, 20.303963660365739],
					[72.510466900267005, 20.302297167137443],
					[72.510461300035033, 20.299863911526788],
					[72.510758499044258, 20.29740832309496],
					[72.510758499211761, 20.297408320171691],
					[72.510758499881732, 20.297408308478499],
					[72.510758500216724, 20.297408302631922],
					[72.510789100269747, 20.295458303267747],
					[72.510789095623466, 20.295458273059587],
					[72.510789078795909, 20.295458216957911],
					[72.510789065903083, 20.295458187584245],
					[72.510475166340854, 20.294991587512474],
					[72.510475107610475, 20.294991547278794],
					[72.510474979223872, 20.294991514418442],
					[72.510474908688522, 20.294991521234937],
					[72.509377708617905, 20.29555262294744],
					[72.509377672198823, 20.29555265440225],
					[72.509377617653684, 20.295552731929082],
					[72.509377601453224, 20.295552776191723],
					[72.509208107675065, 20.297024919156556],
					[72.508797087601621, 20.297541520125261],
					[72.507141703873401, 20.29789699553001],
					[72.505630669783997, 20.297510912079048],
					[72.504350089777716, 20.296869220486251],
					[72.504350011665778, 20.296869216439198],
					[72.504349875952883, 20.296869263732347],
					[72.504349818184423, 20.296869317994815],
					[72.504005468113149, 20.29762200727188],
					[72.503594556308258, 20.297649788404261],
					[72.503036295159447, 20.295255554086651],
					[72.50303627293161, 20.295255512758995],
					[72.50303621323863, 20.295255441652003],
					[72.503036174726958, 20.29525541423812],
					[72.502000074612354, 20.294838714388785],
					[72.502000053179685, 20.294838706874746],
					[72.502000008932967, 20.294838700055763],
					[72.501999986118889, 20.294838700750841],
					[72.50083058611915, 20.294919199109341],
					[72.500830538774707, 20.294919214551424],
					[72.500830458192752, 20.294919269505268],
					[72.500830424243645, 20.294919305538837],
					[72.499594223830556, 20.297194306017815],
					[72.499594218597849, 20.297194317842219],
					[72.49959420901142, 20.297194342047611],
					[72.499594204657754, 20.297194354428623],
					[72.499252639263673, 20.298674810948643],
					[72.497877926062117, 20.298766492334451],
					[72.497458492313356, 20.297824939947169],
					[72.496833496683934, 20.294461063452921],
					[72.49683341836257, 20.294460983611284],
					[72.496833237482562, 20.294460944804854],
					[72.496833133877445, 20.294460988204733],
					[72.496491629081689, 20.294971945407436],
					[72.495589041220427, 20.294547045835902],
					[72.494897371670874, 20.293377698671581],
					[72.494897286232884, 20.293377647634557],
					[72.494897114059924, 20.29337764764799],
					[72.494897028204036, 20.293377699254673],
					[72.494141582355809, 20.294644208581257],
					[72.493452786328817, 20.294691499488938],
					[72.493452765984543, 20.294691504773912],
					[72.493452728100635, 20.294691514092126],
					[72.493452709347096, 20.294691523410165],
					[72.492905607242889, 20.294971973201989],
					[72.491797379969512, 20.294319275247563],
					[72.491661296732886, 20.293599962761391],
					[72.491661236327431, 20.293599888230592],
					[72.491661081358856, 20.293599826500419],
					[72.491660986084071, 20.293599835824647],
					[72.490486032094978, 20.294410904746858],
					[72.489525116299149, 20.294474790976619],
					[72.488900176102732, 20.293305505462044],
					[72.48890017204215, 20.293305496842148],
					[72.488900162707125, 20.293305484885902],
					[72.488900157767489, 20.293305475709932],
					[72.488000157763977, 20.292149878289241],
					[72.488000129256449, 20.292149851183137],
					[72.488000065795092, 20.292149814072733],
					[72.488000030129641, 20.292149800592824],
					[72.486900030292531, 20.291983103328235],
					[72.486900011329567, 20.291983100412224],
					[72.48689997290154, 20.291983103338676],
					[72.486899953603839, 20.291983106261657],
					[72.485450089302944, 20.292324774707268],
					[72.484825166455636, 20.291397087056083],
					[72.484825127398935, 20.291397053286289],
					[72.484825036895359, 20.291397011187549],
					[72.484824985615845, 20.291396999939426],
					[72.483313886327224, 20.291499798701857],
					[72.481452786216437, 20.291630400762628],
					[72.481452767797833, 20.291630404241189],
					[72.481452731839681, 20.291630411754017],
					[72.481452714132786, 20.291630418706923],
					[72.479661013933182, 20.292480419246477],
					[72.479661013054098, 20.292480418690758],
					[72.479661010082026, 20.29248042286029],
					[72.479661008323887, 20.292480421748866],
					[72.478041607693498, 20.293319221429883],
					[72.478041590530836, 20.293319234771793],
					[72.478041558131011, 20.29331925964895],
					[72.478041543605585, 20.293319274657961],
					[72.477219252261634, 20.294347064123141],
					[72.476119300127451, 20.295391518197153],
					[72.474816650929213, 20.295722007364951],
					[72.474816602036114, 20.295722033901082],
					[72.474816528194168, 20.295722114202988],
					[72.474816503412796, 20.295722165051274],
					[72.474135903280526, 20.299649966501359],
					[72.474135902233982, 20.299649968862852],
					[72.474135901899004, 20.299649974697555],
					[72.474135900684928, 20.299649979976397],
					[72.473799801455911, 20.302824978568527],
					[72.473335903159054, 20.307222159339553],
					[72.472724808850018, 20.309202740885755],
					[72.472724807635714, 20.309202746163525],
					[72.472724806421411, 20.309202751441326],
					[72.472724805374767, 20.309202753802083],
					[72.472047010408929, 20.312158231752637],
					[72.471563720403623, 20.313161012545869],
					[72.471563714123363, 20.313161026708066],
					[72.471563706125636, 20.313161054897837],
					[72.471563701603415, 20.3131610701729],
					[72.471297002162544, 20.314877769538384],
					[72.471297000780382, 20.314877777732086],
					[72.471296999941799, 20.314877792315801],
					[72.471297000317676, 20.314877801622554],
					[72.471296999980112, 20.316088850708663],
					[72.470405421848369, 20.317844307431354],
					[72.470405418372565, 20.31784432034431],
					[72.470405409998449, 20.317844339223843],
					[72.470405406858148, 20.317844346303673],
					[72.470069206668754, 20.31907774784834],
					[72.470069204071876, 20.319077761317502],
					[72.470069200803906, 20.319077786452663],
					[72.470069199965025, 20.319077801035174],
				],
				[
					[72.431227600322913, 20.124544401874871],
					[72.431252600251781, 20.129097200196647],
					[72.431252600086822, 20.129097203108827],
					[72.431252599921862, 20.129097206021001],
					[72.431252599756903, 20.129097208933185],
					[72.43143309952606, 20.133136109435636],
					[72.431433100405641, 20.133136109982011],
					[72.431433100240625, 20.133136112894174],
					[72.431433100955161, 20.133136116352688],
					[72.431769199870701, 20.137672211816774],
					[72.431952599882067, 20.141711107907902],
					[72.431952601310869, 20.141711114825846],
					[72.431952600815436, 20.141711123562335],
					[72.431952602244223, 20.141711130480275],
					[72.432755399860582, 20.147236116893868],
					[72.43294419993714, 20.152794408290525],
					[72.432944201365544, 20.152794415209701],
					[72.432944203012212, 20.152794434325074],
					[72.432944205154826, 20.152794444703815],
					[72.433738704314692, 20.156294438815614],
					[72.434058102154225, 20.158305631894827],
					[72.434058168770136, 20.158305709561198],
					[72.434058334837061, 20.158305767742899],
					[72.434058436212453, 20.158305746441211],
					[72.436364013278535, 20.156169568815358],
					[72.437591725376649, 20.155603009757876],
					[72.439605560762104, 20.156008497245942],
					[72.439605631625597, 20.156008484070405],
					[72.439605747576309, 20.156008414520088],
					[72.439605791949361, 20.15600835468425],
					[72.440055751581895, 20.154469592762531],
					[72.440675009676426, 20.154439098740131],
					[72.440675063498517, 20.154439080978431],
					[72.440675149816514, 20.154439020108732],
					[72.440675185116234, 20.15443897573039],
					[72.441733448634182, 20.151855764486779],
					[72.443741599817713, 20.151250237767162],
					[72.444519210979948, 20.152733371081275],
					[72.444991504251234, 20.154733343356099],
					[72.445952603851794, 20.159752836405922],
					[72.445952605279928, 20.159752843330651],
					[72.44595260830161, 20.1597528542659],
					[72.445952608850249, 20.159752860642485],
					[72.447844208779657, 20.165738959908111],
					[72.447844254781486, 20.16573901575682],
					[72.447844372082201, 20.165739082876968],
					[72.44784444338103, 20.165739094148371],
					[72.450766623862634, 20.16509192234258],
					[72.452788745932935, 20.167527927021553],
					[72.452788770226874, 20.167527948216819],
					[72.452788822994805, 20.167527981141269],
					[72.452788852348249, 20.167527993419185],
					[72.454491556771544, 20.16795296954631],
					[72.456688717395608, 20.172919479835315],
					[72.456688719702711, 20.17291948731436],
					[72.456688727120877, 20.172919501004017],
					[72.456688731352472, 20.172919506665515],
					[72.457930425104806, 20.174886198195694],
					[72.459655421215061, 20.178355689260588],
					[72.4596554286328, 20.178355702954789],
					[72.459655446438049, 20.1783557261592],
					[72.459655455780393, 20.178355738036142],
					[72.461052655863568, 20.179808438810721],
					[72.461052668009827, 20.179808449421756],
					[72.461052694226908, 20.179808468826561],
					[72.461052707418318, 20.17980847707075],
					[72.464613806769279, 20.181669577031723],
					[72.464613831724577, 20.18166958659269],
					[72.464613883011921, 20.181669597513373],
					[72.464613909343939, 20.181669598873111],
					[72.4666220553122, 20.18157520570961],
					[72.467560908845712, 20.184575059907498],
					[72.467560915383615, 20.184575073060387],
					[72.467560928293594, 20.184575102283588],
					[72.467560935710864, 20.184575115986487],
					[72.469277635828519, 20.187033415956055],
					[72.469277721794143, 20.187033457658234],
					[72.469277890786046, 20.187033448579093],
					[72.469277972767074, 20.187033400165259],
					[72.469580608126634, 20.186511486565426],
					[72.470208130775461, 20.187497306726826],
					[72.470208154735388, 20.187497333806444],
					[72.470208210685456, 20.187497374860744],
					[72.470208243389251, 20.187497392303683],
					[72.473144342729569, 20.188375190752652],
					[72.473144399076915, 20.188375192777894],
					[72.47314450287746, 20.188375161039961],
					[72.473144551044186, 20.188375130745531],
					[72.474061107720985, 20.187319695986133],
					[72.475930456034661, 20.189261238923116],
					[72.47593047785368, 20.189261255606056],
					[72.475930524627628, 20.18926128186741],
					[72.475930551175452, 20.189261295465688],
					[72.47763044934841, 20.189689067741458],
					[72.478730411704234, 20.192686168059176],
					[72.478730414176439, 20.19268617263084],
					[72.478730418954981, 20.192686184693343],
					[72.478730421427187, 20.19268618926499],
					[72.479980420050708, 20.195166787919529],
					[72.481383119055863, 20.198150085171203],
					[72.481383125758825, 20.198150095419773],
					[72.481383140757401, 20.198150119938823],
					[72.481383150098438, 20.198150131840947],
					[72.483099849773154, 20.200102933013703],
					[72.483099850652522, 20.200102933565049],
					[72.483099854169978, 20.200102935770378],
					[72.483099854883292, 20.200102939241514],
					[72.484497054833497, 20.201564038251103],
					[72.484497106881747, 20.201564067866773],
					[72.484497216820657, 20.201564088423662],
					[72.484497276137915, 20.201564086307847],
					[72.485886175554683, 20.200991886087653],
					[72.485886215643035, 20.200991853764698],
					[72.48588627630771, 20.20099177990668],
					[72.485886297050087, 20.200991735451368],
					[72.486625197197696, 20.196894435270764],
					[72.486625197861684, 20.196894423589139],
					[72.486625200069099, 20.196894400777147],
					[72.486625199687722, 20.196894391464628],
					[72.486441900079228, 20.192838891242609],
					[72.486441900245168, 20.192838888322189],
					[72.486441898652359, 20.19283888429959],
					[72.486441898818299, 20.192838881379174],
					[72.485947399335245, 20.187274981102849],
					[72.485947391154568, 20.187274948760379],
					[72.48594736269753, 20.18727488859658],
					[72.485947344179976, 20.187274861876841],
					[72.484550182908421, 20.185816602059557],
					[72.483436295128143, 20.180788858275903],
					[72.483436295293899, 20.180788855355846],
					[72.483436293700819, 20.180788851334977],
					[72.48343629298715, 20.180788847864516],
					[72.482644592497067, 20.177777750004225],
					[72.482644587883357, 20.177777735022975],
					[72.482644575851936, 20.177777706329746],
					[72.482644569313607, 20.177777693167979],
					[72.48201688892658, 20.176791623758042],
					[72.481064096849096, 20.171761061872122],
					[72.48106409580403, 20.171761064242002],
					[72.481064095969671, 20.17176106132224],
					[72.48058350141018, 20.169244387674059],
					[72.480719598725898, 20.167205621217125],
					[72.481316897719992, 20.163116728247111],
					[72.481316898930473, 20.163116722956783],
					[72.481316899757971, 20.163116708357329],
					[72.481316900088984, 20.163116702517527],
					[72.481422399210615, 20.156000066140667],
					[72.482186261763601, 20.154947317331683],
					[72.482186277106123, 20.154947287606802],
					[72.482186296026853, 20.154947226858869],
					[72.482186299770476, 20.154947192915696],
					[72.482002999971115, 20.149880593099788],
					[72.482002968480273, 20.149880522052012],
					[72.482002862832402, 20.149880425890021],
					[72.482002789720198, 20.14988039840453],
					[72.481536244991773, 20.149902594171277],
					[72.480277990713375, 20.145902738795805],
					[72.480277952621847, 20.145902687829423],
					[72.480277851806605, 20.145902618899864],
					[72.480277789962386, 20.145902601485304],
					[72.478736233145725, 20.145977593321497],
					[72.477950195285388, 20.143986048736515],
					[72.477464097968848, 20.140458272315332],
					[72.477464098134021, 20.14045826939584],
					[72.477464096540103, 20.14045826537982],
					[72.477464095825738, 20.14045826191208],
					[72.476983496154276, 20.137941661908538],
					[72.476983460315068, 20.137941603306423],
					[72.476983356639124, 20.137941520564681],
					[72.476983290396262, 20.137941500440654],
					[72.47421389033164, 20.138080400445649],
					[72.472977821591513, 20.138144198441413],
					[72.471427928166264, 20.137710929367859],
					[72.470489089559038, 20.136236026314918],
					[72.469697396673709, 20.131711065025673],
					[72.469697360997515, 20.131711003542026],
					[72.469697257318614, 20.131710920871793],
					[72.469697189315923, 20.131710899685224],
					[72.469383472201784, 20.131727591410215],
					[72.468736298964814, 20.127194377056565],
					[72.468239099439941, 20.121130582878326],
					[72.46823909817536, 20.121130573029689],
					[72.468239093172258, 20.12113054877322],
					[72.468239091028082, 20.121130538377578],
					[72.46744459144476, 20.118636038075543],
					[72.467444579954361, 20.118636015829068],
					[72.467444553290363, 20.118635972066784],
					[72.467444536027799, 20.118635955293477],
					[72.465897335997511, 20.117194253777754],
					[72.465897320329248, 20.117194241018687],
					[72.465897286024187, 20.117194219696064],
					[72.465897268267057, 20.117194211679273],
					[72.464661190905986, 20.116749821040738],
					[72.463264011773106, 20.115805433573598],
					[72.463264010893496, 20.115805433027042],
					[72.463264010013859, 20.115805432480443],
					[72.463264009134221, 20.115805431933858],
					[72.461097309259998, 20.114397031898996],
					[72.461097294141595, 20.114397025526664],
					[72.461097264069551, 20.114397009864867],
					[72.461097247906807, 20.114397005863239],
					[72.459397276965319, 20.113980413625214],
					[72.457069503325442, 20.112577627940087],
					[72.457069490845868, 20.112577623209539],
					[72.457069466051465, 20.11257761083197],
					[72.457069452692252, 20.112577605555213],
					[72.455527910664827, 20.112152621750216],
					[72.453819564275349, 20.110210983471067],
					[72.452719574610299, 20.108244303170281],
					[72.452719535849695, 20.108244264027313],
					[72.452719443157662, 20.108244212543443],
					[72.452719390105912, 20.108244200748462],
					[72.449486090242999, 20.108410901094945],
					[72.447630590421639, 20.10850809887291],
					[72.447630572005366, 20.108508102533857],
					[72.447630538856131, 20.108508109123598],
					[72.447630522034487, 20.108508116790979],
					[72.446249922025657, 20.109088716610607],
					[72.446249906083651, 20.109088724822627],
					[72.4462498738703, 20.10908874707625],
					[72.446249858643242, 20.109088758748737],
					[72.445174875670446, 20.110158141175024],
					[72.44425273998516, 20.110710903082342],
					[72.44317508821463, 20.110766493892648],
					[72.441933451513066, 20.109310970875381],
					[72.441933400658527, 20.109310936319016],
					[72.44193328927733, 20.109310909467254],
					[72.441933228915417, 20.109310914257819],
					[72.440391628755904, 20.109899814106885],
					[72.440391589011298, 20.1098998407297],
					[72.440391526785547, 20.10989991071374],
					[72.440391505184024, 20.109899954620456],
					[72.439788730416396, 20.112460950600564],
					[72.438247129194053, 20.113047014228908],
					[72.438247093297065, 20.113047037197447],
					[72.438247035412502, 20.113047094777844],
					[72.438247012380543, 20.113047131757565],
					[72.437491518175378, 20.115111015133078],
					[72.43657764934126, 20.116677662273261],
					[72.435805531882508, 20.117224803062079],
					[72.4342638897418, 20.117302600691009],
					[72.434263888862205, 20.117302600145333],
					[72.433341688696672, 20.117352601051799],
					[72.433341633832953, 20.117352621285129],
					[72.433341545436804, 20.117352687043869],
					[72.433341510859918, 20.117352734936212],
					[72.432280411145939, 20.120444334684841],
					[72.432280409936666, 20.12044433996391],
					[72.432280406803315, 20.120444347063874],
					[72.432280405758888, 20.120444349430528],
					[72.43122760592982, 20.124544351092709],
					[72.431227604390628, 20.12454436219566],
					[72.431227601147413, 20.124544387313765],
					[72.431227600322913, 20.124544401874871],
				],
				[
					[72.395638699846955, 20.126461098219888],
					[72.395638700616786, 20.126461116748626],
					[72.395638702046185, 20.12646112365136],
					[72.396230402440921, 20.131136124767131],
					[72.396230402825637, 20.131136134031923],
					[72.39623040727831, 20.131136151835303],
					[72.396230408707552, 20.131136158738602],
					[72.397383108614918, 20.134841758437258],
					[72.397383109329482, 20.134841761889287],
					[72.397383110044046, 20.134841765341303],
					[72.397383110923613, 20.134841765886627],
					[72.398441511343705, 20.1379278656255],
					[72.398441520358617, 20.137927883253415],
					[72.398441541906692, 20.13792792069124],
					[72.398441554604901, 20.137927937594348],
					[72.399585946153664, 20.139127930319521],
					[72.400922011003999, 20.140947279244379],
					[72.401505403364609, 20.144369431153372],
					[72.401699800808544, 20.145613920234766],
					[72.401908099827608, 20.14968330903719],
					[72.401908101421412, 20.149683313036824],
					[72.401908100925709, 20.149683321758665],
					[72.401908101640004, 20.149683325212134],
					[72.402299800627986, 20.152800021182518],
					[72.402702601361099, 20.158113914561714],
					[72.402702619439864, 20.158113964929132],
					[72.402702679238487, 20.158114050256394],
					[72.402702722003198, 20.158114082855512],
					[72.403366622050314, 20.158397383984553],
					[72.403366703136783, 20.158397383210971],
					[72.403366838766772, 20.158397320012739],
					[72.403366891551286, 20.158397256494784],
					[72.404302992407921, 20.155216754885615],
					[72.404302992573236, 20.15521675197801],
					[72.404302996038354, 20.155216739079606],
					[72.404302997248493, 20.155216733810942],
					[72.404580796000744, 20.153636137159651],
					[72.405511293468152, 20.149200054787247],
					[72.406355787223333, 20.146963969980778],
					[72.406355790357551, 20.14696396289537],
					[72.406355793987316, 20.146963947085869],
					[72.406355796076781, 20.146963942362248],
					[72.406819594116186, 20.144747248909006],
					[72.407569591785105, 20.142202858038104],
					[72.407569592994889, 20.14220285276761],
					[72.407569594204716, 20.142202847497128],
					[72.407569595249413, 20.142202845134875],
					[72.408314095378586, 20.139027846862447],
					[72.408314095708747, 20.139027841045639],
					[72.408314098128201, 20.139027830503775],
					[72.408314098293289, 20.13902782759537],
					[72.40877239756783, 20.135555626267415],
					[72.408772398942517, 20.135555618087601],
					[72.408772399602654, 20.135555606453536],
					[72.40877240009776, 20.135555597728015],
					[72.408769600152866, 20.134613897612926],
					[72.408769599218829, 20.134613881978943],
					[72.408769592787905, 20.13461385089105],
					[72.408769589050138, 20.134613836528537],
					[72.407711288570937, 20.131527735333353],
					[72.40771128785633, 20.13152773187948],
					[72.40771128642713, 20.131527724971725],
					[72.407711284667954, 20.131527723880705],
					[72.40694460034581, 20.129683259997389],
					[72.406933500358548, 20.126858299080176],
					[72.406933500358548, 20.126858299080176],
					[72.406933499643856, 20.126858295626615],
					[72.406933499808758, 20.126858292718325],
					[72.406819600038176, 20.123099994052527],
					[72.406819600203008, 20.123099991144208],
					[72.406819598773524, 20.123099984237413],
					[72.406819599103216, 20.123099978420765],
					[72.406525199413977, 20.120291677973576],
					[72.406525198534339, 20.120291677428654],
					[72.406525197984337, 20.120291671067125],
					[72.40652519814914, 20.12029166815881],
					[72.406230797678191, 20.118422167792019],
					[72.406230792344772, 20.118422149436885],
					[72.406230777994665, 20.118422113455654],
					[72.40623076914278, 20.118422092921261],
					[72.40470296875327, 20.115985995058232],
					[72.404702920153255, 20.115985952925584],
					[72.404702805913729, 20.11598591229092],
					[72.404702742363014, 20.115985909062093],
					[72.403469546763574, 20.116360847025415],
					[72.402694595324263, 20.112947156240232],
					[72.402694590705224, 20.112947141342509],
					[72.402694582897098, 20.112947118451551],
					[72.402694577069042, 20.112947108824937],
					[72.402216877194249, 20.112030506806185],
					[72.402216851628552, 20.112030475936372],
					[72.402216789833005, 20.11203042565478],
					[72.402216753603156, 20.112030406243001],
					[72.40126687607507, 20.111763741093991],
					[72.40068349932433, 20.108341676742448],
					[72.400580800101807, 20.106777788362404],
					[72.400580765790068, 20.106777718991669],
					[72.400580661877711, 20.106777624628464],
					[72.400580590353144, 20.106777601455356],
					[72.398777790316672, 20.106866499219134],
					[72.398777750516317, 20.10686651071299],
					[72.398777681142164, 20.106866546043168],
					[72.398777648929482, 20.106866568247547],
					[72.398211023270974, 20.107521985089491],
					[72.396788934041794, 20.107277603167848],
					[72.39678888280595, 20.107277607588184],
					[72.396788790782026, 20.107277640941682],
					[72.396788749114336, 20.10727766933093],
					[72.395938749148854, 20.108260970076454],
					[72.395938732219349, 20.108260995709479],
					[72.395938707871792, 20.108261055866116],
					[72.395938700783034, 20.108261084576267],
					[72.395758100928219, 20.110777785027537],
					[72.395758100269575, 20.110777796654265],
					[72.395758101040883, 20.110777815182548],
					[72.395758101426566, 20.110777824446682],
					[72.396349798947156, 20.115766702804805],
					[72.395991501080744, 20.12017218545768],
					[72.395991501080744, 20.12017218545768],
					[72.395991500036345, 20.12017218781974],
					[72.395991500036345, 20.12017218781974],
					[72.395638700341621, 20.126461089500395],
					[72.395638699846955, 20.126461098219888],
				],
			],
		},
		{
			fldNpdidField: "23395946",
			wlbNpdidWellbore: "3075",
			fldName: "AASTA HANSTEEN",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6707/10-1",
			fldDiscoveryYear: "1997",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=23395946",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=23395946",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "218",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=3075",
			fldHcType: "GAS",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "22556",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Sandnessjøen",
			fldAreaGeometryWKT: [
				[
					[67.073641500324953, 6.8673360990896084],
					[67.073641499933032, 6.8675806001137669],
					[67.073641500983101, 6.8675806115284308],
					[67.07364150401034, 6.86758063657763],
					[67.073641505956786, 6.8675806479134645],
					[67.073699805965347, 6.8678111480806141],
					[67.073699805996085, 6.867811150379314],
					[67.073766506334152, 6.8680722490723056],
					[67.073783102873051, 6.868138937035221],
					[67.073794202094476, 6.8682167271396395],
					[67.073835901903237, 6.8685278261455149],
					[67.07386090125172, 6.8687417228728265],
					[67.07386090125172, 6.8687417228728265],
					[67.07389420141277, 6.8690167228541981],
					[67.073924801342955, 6.869272223796707],
					[67.07395260104316, 6.8695083240904404],
					[67.073985900963052, 6.8698028215242957],
					[67.073985900993748, 6.8698028238230275],
					[67.073985900993748, 6.8698028238230275],
					[67.074005400804879, 6.8699667219071552],
					[67.074033100756637, 6.870202823262165],
					[67.074033101683739, 6.8702028254821634],
					[67.074033101745144, 6.8702028300796565],
					[67.074033102672232, 6.8702028322996567],
					[67.074058102550268, 6.8703528328310828],
					[67.074058102580963, 6.8703528351298209],
					[67.074058102611673, 6.8703528374285812],
					[67.074058104404415, 6.8703528372711204],
					[67.074080403612712, 6.8704667378383473],
					[67.074133103691139, 6.8707361378904892],
					[67.074133103721834, 6.8707361401892353],
					[67.074133105606705, 6.8707361469280599],
					[67.074133105637387, 6.8707361492268078],
					[67.074191506107368, 6.870961149715674],
					[67.074191507961501, 6.8709611541557631],
					[67.074191509846344, 6.8709611608946224],
					[67.074191510804084, 6.8709611654134202],
					[67.074233110762833, 6.8710806657914612],
					[67.074233111689921, 6.8710806680115128],
					[67.074233112616966, 6.8710806702315885],
					[67.074233112616966, 6.8710806702315885],
					[67.07427761285328, 6.8711972719989465],
					[67.074277613780367, 6.8711972742190071],
					[67.074277615634472, 6.8711972786591309],
					[67.074277616561574, 6.8711972808791888],
					[67.074347013970126, 6.8713528750865107],
					[67.074408111711904, 6.8715250674007979],
					[67.074408113596704, 6.8715250741397433],
					[67.074408119097683, 6.8715250828627319],
					[67.074408120982469, 6.8715250896016764],
					[67.074463712068933, 6.8716361717261965],
					[67.074508104777124, 6.8718028468203336],
					[67.074572003897856, 6.8721083415259523],
					[67.074572004794248, 6.8721083414472846],
					[67.074572004855597, 6.8721083460448638],
					[67.074572005751946, 6.8721083459661987],
					[67.074652606117425, 6.8724417460753617],
					[67.074702606090312, 6.8726472460730852],
					[67.074702608932782, 6.8726472573311792],
					[67.074702617306826, 6.8726472796114253],
					[67.074702621942023, 6.8726472907122247],
					[67.074758121696817, 6.872755691946332],
					[67.074758129948208, 6.872755705031409],
					[67.074758147316643, 6.8727557288241155],
					[67.074758157330095, 6.8727557394530994],
					[67.074866556692214, 6.8728668397532475],
					[67.074866595604306, 6.8728668639576176],
					[67.074866680354233, 6.8728668933466199],
					[67.074866725295664, 6.8728668986098942],
					[67.074975025042477, 6.872852999091676],
					[67.074975070573643, 6.8728529812879886],
					[67.074975147914344, 6.8728529261707276],
					[67.074975178827529, 6.8728528889357889],
					[67.075094578703144, 6.8726111899711801],
					[67.075094583928049, 6.8726111780050578],
					[67.075094590853737, 6.8726111589851104],
					[67.075094594285886, 6.8726111471762881],
					[67.075175194150859, 6.8722861468892313],
					[67.075175195047237, 6.872286146810568],
					[67.075175195047237, 6.872286146810568],
					[67.075211294986715, 6.8721333460629026],
					[67.075211294986715, 6.8721333460629026],
					[67.075211295821731, 6.872133341386534],
					[67.075211295791064, 6.8721333390876822],
					[67.075236296267263, 6.8720111399066743],
					[67.075289095699134, 6.8717500413095935],
					[67.075347394718648, 6.8714917430597326],
					[67.075347395584359, 6.8714917406821749],
					[67.075347397315724, 6.8714917359270649],
					[67.075347397285057, 6.8714917336282015],
					[67.075366885681404, 6.8713778998457622],
					[67.075408258379369, 6.8713392123810628],
					[67.075502618381918, 6.8714917849367367],
					[67.075616510734434, 6.8718194656200104],
					[67.075616514411962, 6.8718194722019499],
					[67.075616520004999, 6.8718194878221208],
					[67.075616522786135, 6.8718194944827431],
					[67.075713722988979, 6.8720028947083547],
					[67.075713733960214, 6.8720029098565583],
					[67.07571376041524, 6.8720029420585096],
					[67.075713774044914, 6.8720029546717925],
					[67.075833173944147, 6.8721001554545174],
					[67.075833255882372, 6.8721001758823128],
					[67.075833407155002, 6.8721001464943461],
					[67.075833474696651, 6.8721000968359256],
					[67.075866874619294, 6.872038996863],
					[67.075866885069004, 6.8720389729297393],
					[67.075866897004758, 6.8720389258499601],
					[67.075866900252805, 6.8720389002471816],
					[67.075866900174461, 6.8719056002718251],
					[67.075866900143765, 6.8719055979728925],
					[67.075866900143765, 6.8719055979728925],
					[67.075866900143765, 6.8719055979728925],
					[67.075861299740907, 6.8715332979954153],
					[67.07586129971024, 6.8715332956965032],
					[67.075861299679545, 6.8715332933975928],
					[67.075861299648864, 6.8715332910986815],
					[67.075855800389959, 6.8713943930797727],
					[67.075855799370856, 6.8713943839628069],
					[67.075855798321044, 6.8713943725469315],
					[67.075855797332622, 6.8713943657288965],
					[67.075825196979395, 6.8712193660608829],
					[67.075825196021626, 6.8712193615417743],
					[67.075825195063885, 6.8712193570226416],
					[67.07582519410613, 6.871219352503533],
					[67.075741899313144, 6.870877771391072],
					[67.075730799981898, 6.8705888921443901],
					[67.075722399766079, 6.8702832942961987],
					[67.07571689964314, 6.869997196437426],
					[67.075711299691832, 6.8697082994951142],
					[67.075714099958617, 6.8694333026191075],
					[67.075714099927893, 6.8694333003201935],
					[67.075714099927893, 6.8694333003201935],
					[67.075714099794425, 6.86914440385035],
					[67.075725199737818, 6.868888908319466],
					[67.075725199707108, 6.8688889060205698],
					[67.07572519964566, 6.8688889014227819],
					[67.075725199614936, 6.8688888991238652],
					[67.075725200352139, 6.8685806021961664],
					[67.075733500198936, 6.8683361077930449],
					[67.075733500168212, 6.8683361054941496],
					[67.07573350010675, 6.8683361008963404],
					[67.075733500076012, 6.8683360985974478],
					[67.075730800175506, 6.8680138979354783],
					[67.07573080039198, 6.8677666991447444],
					[67.07573080039198, 6.8677666991447444],
					[67.075730800361242, 6.8677666968458508],
					[67.07572520021904, 6.8674388960306381],
					[67.075722399834461, 6.8671888985819214],
					[67.075722399803709, 6.8671888962830314],
					[67.075722399772971, 6.8671888939841388],
					[67.075722399772971, 6.8671888939841388],
					[67.075711299915881, 6.8668610938740082],
					[67.075702999640583, 6.8665999942726144],
					[67.075702999609817, 6.866599991973704],
					[67.075703000444705, 6.8665999872970502],
					[67.075702998621196, 6.8665999851559114],
					[67.07567519937156, 6.8662416859734163],
					[67.075675199279274, 6.8662416790767589],
					[67.075675198259887, 6.8662416699600817],
					[67.075675197301962, 6.8662416654411782],
					[67.075608496637628, 6.8658638650812982],
					[67.075577996905281, 6.8656888648773782],
					[67.075577994989473, 6.8656888558396902],
					[67.07557798933432, 6.8656888356232164],
					[67.075577985625756, 6.8656888267433258],
					[67.075539086071714, 6.8655916258155827],
					[67.075539076861844, 6.8655916082136805],
					[67.075539055783779, 6.8655915755455021],
					[67.075539043915583, 6.8655915604792268],
					[67.075464043830365, 6.8655137618987334],
					[67.07546404018332, 6.8655137576166627],
					[67.075464031096544, 6.8655137492103222],
					[67.075464026553149, 6.8655137450071635],
					[67.075325127380111, 6.8653998452096916],
					[67.07532507684482, 6.8653998243416803],
					[67.075324974320054, 6.8653998080508947],
					[67.075324920568576, 6.8653998150848086],
					[67.075202721062283, 6.8654526161001463],
					[67.075202697044929, 6.865452632023918],
					[67.075202652657126, 6.8654526681535062],
					[67.075202634079531, 6.8654526882014659],
					[67.075074860876896, 6.8656415492942511],
					[67.074911111656775, 6.8657330651619342],
					[67.074855765006276, 6.8656942703562427],
					[67.074764086751273, 6.8654555287758363],
					[67.074764083969825, 6.8654555221161635],
					[67.074764079334116, 6.8654555110167195],
					[67.074764075656319, 6.86545550443598],
					[67.074661275871819, 6.8652638060876559],
					[67.074661273090385, 6.8652637994280301],
					[67.074661266692715, 6.8652637907853169],
					[67.074661263014875, 6.8652637842046156],
					[67.074627977650977, 6.8652166065596854],
					[67.074602990116873, 6.865149938942813],
					[67.074533492837091, 6.8648999459984141],
					[67.074533491940713, 6.8648999460773492],
					[67.074533491940713, 6.8648999460773492],
					[67.074464099053145, 6.8646555678647347],
					[67.074453000002919, 6.8644499895923801],
					[67.07443909992854, 6.8641193908809726],
					[67.07443909992854, 6.8641193908809726],
					[67.074439099897774, 6.8641193885822069],
					[67.074439099866964, 6.8641193862834422],
					[67.07442799973829, 6.8639555849911744],
					[67.074427998811117, 6.8639555827713794],
					[67.074427997822397, 6.8639555759540301],
					[67.074427997760807, 6.8639555713565041],
					[67.07440019868254, 6.8637638768818903],
					[67.074380800335959, 6.8635638868702307],
					[67.074375200106758, 6.8634138937016749],
					[67.074375200075963, 6.8634138914028942],
					[67.074361300162266, 6.8630610987483269],
					[67.074364100409625, 6.8628722091981746],
					[67.074386299215561, 6.8626000245181009],
					[67.07442799694347, 6.8623333330421623],
					[67.07448079628854, 6.8620583383928899],
					[67.074544595957136, 6.8617556428690918],
					[67.074591807946817, 6.8615393455740836],
					[67.074644225647447, 6.8616029044956965],
					[67.074699814322543, 6.8617389738514998],
					[67.074758113046187, 6.8618917715992351],
					[67.074758122256469, 6.8618917891991629],
					[67.074758144262475, 6.8618918240828197],
					[67.074758156131026, 6.8618918391468],
					[67.074827656306837, 6.86196403799691],
					[67.074827750022365, 6.8619640665572863],
					[67.074827917335185, 6.8619640287882726],
					[67.074827990066908, 6.8619639648366837],
					[67.074919589839823, 6.861691763902682],
					[67.074919589839823, 6.861691763902682],
					[67.075033424204818, 6.8613502612014754],
					[67.075141567746869, 6.8613918648735552],
					[67.075249833003809, 6.8615557102023335],
					[67.075249841225272, 6.8615557209850113],
					[67.075249859430059, 6.8615557400933911],
					[67.075249867651522, 6.8615557508760681],
					[67.075319267583069, 6.8616168493892493],
					[67.075319299264237, 6.8616168696107307],
					[67.075319365099901, 6.8616168937245821],
					[67.075319400181584, 6.8616168998367204],
					[67.0754833003312, 6.8616169003890803],
					[67.075483335258795, 6.86161689500695],
					[67.075483402209031, 6.8616168683878023],
					[67.075483431573403, 6.8616168496868379],
					[67.075625105624084, 6.8614918751705067],
					[67.075725051423319, 6.8614529959296986],
					[67.075844400998164, 6.8614363024876406],
					[67.075949970626539, 6.8614501977522435],
					[67.076086046600125, 6.8614723948897796],
					[67.076158209907504, 6.8615001791205863],
					[67.076210992563986, 6.8615334684086973],
					[67.076210999796587, 6.8615334723740444],
					[67.076211013365423, 6.8615334803838248],
					[67.076211019670836, 6.861533482129305],
					[67.076324908809283, 6.8615834776980504],
					[67.076372098570232, 6.861611271754839],
					[67.076372107626398, 6.8616112778610763],
					[67.076372126573446, 6.8616112853965801],
					[67.076372135598803, 6.861611289203851],
					[67.076388810306625, 6.8616168799769666],
					[67.076433184077416, 6.86164736231704],
					[67.076597081878518, 6.8617668622482908],
					[67.076597084598461, 6.8617668643100833],
					[67.076597090903888, 6.8617668660556514],
					[67.076597092727454, 6.8617668681965034],
					[67.076724887152068, 6.8618473658883525],
					[67.076830476956388, 6.8619251570987574],
					[67.076910972149818, 6.8619918534948807],
					[67.076910981205955, 6.8619918596013942],
					[67.076910998421923, 6.8619918718934478],
					[67.076911008374424, 6.8619918779208913],
					[67.077038795744969, 6.8620584713727304],
					[67.077074839965931, 6.8620834337464931],
					[67.077099816948703, 6.8621389793895533],
					[67.077191516136793, 6.8623528778640051],
					[67.077283111742844, 6.8625667709504308],
					[67.077305402926413, 6.8626361393045574],
					[67.077313700710661, 6.8627333152299297],
					[67.077338700045701, 6.8630694144849409],
					[67.07735259986417, 6.8632833116762777],
					[67.077366499580037, 6.8636111070939361],
					[67.077366499610847, 6.863611109392977],
					[67.077366499672436, 6.8636111139910829],
					[67.0773665005688, 6.8636111139120919],
					[67.077383100865049, 6.8638333154896038],
					[67.077383100895858, 6.8638333177886484],
					[67.077408101133713, 6.864122216629795],
					[67.077408101164508, 6.8641222189288422],
					[67.077430401207977, 6.8643694180280965],
					[67.077430401238772, 6.8643694203271464],
					[67.077430401269552, 6.8643694226261944],
					[67.077430402196725, 6.864369424846279],
					[67.077460901313628, 6.8646139180504449],
					[67.07748039957076, 6.8649111129895664],
					[67.077494200046516, 6.8651417088916658],
					[67.077508099944026, 6.8654694034160872],
					[67.077508100331301, 6.8657055996520651],
					[67.077508100144172, 6.8660555931673262],
					[67.077491499650748, 6.8663332874186551],
					[67.0774914996815, 6.8663332897177112],
					[67.0774914996815, 6.8663332897177112],
					[67.077474800244204, 6.8666721870413419],
					[67.077441500967751, 6.8670193806027688],
					[67.077441500998518, 6.8670193829018222],
					[67.07744150016363, 6.8670193875787975],
					[67.077441500194354, 6.8670193898778527],
					[67.077427599695127, 6.8672832899841971],
					[67.077427599725866, 6.8672832922832505],
					[67.07742759978737, 6.8672832968813564],
					[67.077427599818108, 6.8672832991804311],
					[67.077427599785068, 6.8676194001385173],
					[67.077427600320291, 6.8678639001022228],
					[67.077427600320291, 6.8678639001022228],
					[67.077427600381782, 6.8678639047003296],
					[67.077427600381782, 6.8678639047003296],
					[67.077435899994327, 6.8681889040036213],
					[67.077438700431202, 6.8684389030036055],
					[67.077438700431202, 6.8684389030036055],
					[67.077444200407598, 6.8687667013423619],
					[67.077444199968753, 6.8690167003409091],
					[67.077444199968753, 6.8690167003409091],
					[67.077444199999462, 6.8690167026399882],
					[67.077447000438596, 6.8693556003675642],
					[67.077446999650988, 6.869599999173551],
					[67.077446999693763, 6.8699444001918151],
					[67.077447000102254, 6.8701778010145631],
					[67.077447000102254, 6.8701778010145631],
					[67.077447000102254, 6.8701778010145631],
					[67.077447000132963, 6.8701778033136218],
					[67.07745259990017, 6.8705167037358077],
					[67.07745259990017, 6.8705167037358077],
					[67.077452599930879, 6.8705167060348664],
					[67.077458099661484, 6.8707472046966735],
					[67.07745809969218, 6.870747206995734],
					[67.07745809969218, 6.870747206995734],
					[67.077469200247606, 6.8710750065332586],
					[67.077474799618869, 6.8713139050096901],
					[67.077474799618869, 6.8713139050096901],
					[67.077474799649551, 6.8713139073087559],
					[67.077485900025252, 6.8716333081301739],
					[67.077485900025252, 6.8716333081301739],
					[67.077497000022333, 6.8718778075374596],
					[67.077508100126394, 6.8721861074416362],
					[67.077516499815033, 6.8724417062743033],
					[67.077527599755797, 6.8727389075057079],
					[67.077527599755797, 6.8727389075057079],
					[67.077538700122631, 6.8730028069735081],
					[67.077546999613347, 6.8732917055643634],
					[67.077546999644028, 6.8732917078634372],
					[67.077546999644028, 6.8732917078634372],
					[67.077558099908131, 6.8735667068629711],
					[67.077566500341575, 6.8738444057539265],
					[67.077566500372214, 6.873844408053003],
					[67.077566500402867, 6.8738444103520786],
					[67.07756650043352, 6.8738444126511551],
					[67.07758310020246, 6.8741250110741481],
					[67.077583100233099, 6.8741250133732255],
					[67.077599799617857, 6.874375012874971],
					[67.077599800544846, 6.8743750150954588],
					[67.077599800636776, 6.8743750219927211],
					[67.077599800636776, 6.8743750219927211],
					[67.077633100780787, 6.8746639234159765],
					[67.077633101738428, 6.8746639279355612],
					[67.077633102757375, 6.8746639370533318],
					[67.077633103715002, 6.8746639415729192],
					[67.077658104131572, 6.874783341137527],
					[67.077658115286397, 6.8747833700827332],
					[67.077658145632739, 6.8747834249668411],
					[67.077658165690011, 6.8747834485280777],
					[67.077688765846958, 6.874811248452283],
					[67.077688855882883, 6.8748112704825886],
					[67.077689016924225, 6.8748112333474589],
					[67.077689087002668, 6.8748111719614684],
					[67.077736286841358, 6.8746861699258304],
					[67.077736290304316, 6.8746861604150986],
					[67.0777362963032, 6.8746861391731269],
					[67.077736297973416, 6.8746861298195618],
					[67.07777239760884, 6.8744500310689958],
					[67.077772397578215, 6.874450028769898],
					[67.077772398443955, 6.8744500263922079],
					[67.077772399309666, 6.8744500240145197],
					[67.077816898266036, 6.8740861245039389],
					[67.077872397882061, 6.8736528240006445],
					[67.077872398778453, 6.8736528239220194],
					[67.077886298300839, 6.8735361281229519],
					[67.077905797356124, 6.8734111309745787],
					[67.077983496789784, 6.8729583350054977],
					[67.078064096858043, 6.8725083359895827],
					[67.078089094593452, 6.8723722430587406],
					[67.078102980392202, 6.8723195004087732],
					[67.078158434938956, 6.872261247031215],
					[67.078316756405982, 6.8721363101978428],
					[67.078463814405751, 6.872158489120415],
					[67.078538725873074, 6.8722223138444436],
					[67.078569204728964, 6.8723222465476974],
					[67.078597002857492, 6.8724806359100263],
					[67.078597002857492, 6.8724806359100263],
					[67.078674802836247, 6.872900036864845],
					[67.078674802836247, 6.872900036864845],
					[67.078755403016046, 6.8733333375305126],
					[67.078780401598181, 6.8734667300136358],
					[67.07879700072462, 6.8736167218365258],
					[67.078797000755273, 6.8736167241357196],
					[67.078838700608642, 6.8739778237009839],
					[67.078869201419948, 6.8742389224819274],
					[67.07890810076384, 6.8746000210247651],
					[67.078908100794465, 6.8746000233239704],
					[67.078908101752148, 6.8746000278437869],
					[67.078908101782787, 6.8746000301430117],
					[67.078960902250813, 6.8749611265850064],
					[67.078991501815509, 6.8751972250908828],
					[67.078991503761415, 6.8751972364298322],
					[67.078991510373001, 6.875197261171234],
					[67.0789915132153, 6.8751972724316133],
					[67.079066512897157, 6.8753917725697873],
					[67.079066591800796, 6.8753918347115537],
					[67.079066762663601, 6.8753918611706677],
					[67.079066857281333, 6.8753918229530981],
					[67.079216850776504, 6.8752029317515566],
					[67.079280687892535, 6.8751362982338744],
					[67.079386054756156, 6.8751335006445364],
					[67.079485941920254, 6.8751834445129392],
					[67.079583112114676, 6.8754333678554858],
					[67.07965811009143, 6.8756556605905956],
					[67.079691506942581, 6.8757639510933908],
					[67.079727604983859, 6.8759222434128269],
					[67.079727605014483, 6.8759222457121112],
					[67.0797276059415, 6.8759222479328477],
					[67.0797276059415, 6.8759222479328477],
					[67.079813706367716, 6.8762667472941761],
					[67.079813708252317, 6.8762667540350098],
					[67.079813712021519, 6.8762667675166558],
					[67.079813713875495, 6.8762667719581758],
					[67.079955413603543, 6.8766278739875526],
					[67.079955443765598, 6.8766279150832945],
					[67.079955521772362, 6.8766279773098464],
					[67.079955567793704, 6.8766279962984127],
					[67.080094349950912, 6.8766501943674676],
					[67.080172133395365, 6.8766779868841033],
					[67.08017213521876, 6.8766779890263896],
					[67.080172139731232, 6.8766779909331435],
					[67.080172142420366, 6.8766779906975835],
					[67.080236042291475, 6.8766973909014846],
					[67.080236060280228, 6.876697393929792],
					[67.080236095361371, 6.8766974000648879],
					[67.080236113319529, 6.8766974007938613],
					[67.080405593624221, 6.8766863003055683],
					[67.080580575078102, 6.8767084980564963],
					[67.080580575974494, 6.8767084979779751],
					[67.080627775974406, 6.8767140987882014],
					[67.080627777767148, 6.8767140986311617],
					[67.08062777955989, 6.8767140984741228],
					[67.080627782249039, 6.8767140982385619],
					[67.080658267257903, 6.8767168975340551],
					[67.080749952644467, 6.8767390945921951],
					[67.080749955333573, 6.8767390943566395],
					[67.080749958022722, 6.8767390941210795],
					[67.080749958949724, 6.8767390963419412],
					[67.080830554365619, 6.8767557938640289],
					[67.08088324443591, 6.8767695927502501],
					[67.081013820650853, 6.8768112847613025],
					[67.081080504811482, 6.8768473763060953],
					[67.081080521068699, 6.8768473840904711],
					[67.081080554418293, 6.8768473949818594],
					[67.081080570614304, 6.8768473981673868],
					[67.081155570547793, 6.8768584973136333],
					[67.08115558581683, 6.8768584982782706],
					[67.081155617251255, 6.8768585001290541],
					[67.08115563156268, 6.8768584965733686],
					[67.081308331717878, 6.8768334975781418],
					[67.081308356755201, 6.8768334907807454],
					[67.081308404914722, 6.8768334681452625],
					[67.081308427171081, 6.8768334546851149],
					[67.081466826914124, 6.8767029533842514],
					[67.081466827810473, 6.8767029533057284],
					[67.08146682870688, 6.8767029532272037],
					[67.081466829603229, 6.8767029531486825],
					[67.081522306551932, 6.8766557707418992],
					[67.081566763983275, 6.876636290599671],
					[67.081719333789422, 6.8766002208610733],
					[67.0818165329826, 6.8767029141395595],
					[67.081952620405048, 6.8769861874236939],
					[67.08195262133205, 6.8769861896446756],
					[67.081952623186027, 6.8769861940866397],
					[67.081952624113015, 6.8769861963076195],
					[67.082019222042447, 6.8771083921583855],
					[67.082069219620266, 6.8772111869978509],
					[67.082138719400675, 6.8773583850210738],
					[67.082138733152377, 6.8773584068387352],
					[67.082138764179987, 6.8773584455610122],
					[67.082138782352331, 6.8773584623871171],
					[67.082230481847489, 6.8774251612662134],
					[67.082230556583426, 6.8774251800455417],
					[67.082230697192244, 6.8774251585229731],
					[67.082230762138195, 6.8774251160000484],
					[67.082377962432574, 6.8772223169770754],
					[67.08237796595688, 6.8772223120639691],
					[67.082377973840664, 6.8772222975601531],
					[67.082377978230753, 6.8772222902689784],
					[67.082441877617654, 6.8770972909809212],
					[67.082441881141946, 6.8770972860677846],
					[67.082441886367164, 6.877097274098972],
					[67.082441888068132, 6.8770972670433128],
					[67.08253078741825, 6.8768500708468654],
					[67.082625184275642, 6.8766194784361883],
					[67.082644583308053, 6.8765750791098528],
					[67.082644586801706, 6.8765750718970162],
					[67.082644592831457, 6.8765750529507716],
					[67.082644595398108, 6.8765750435169055],
					[67.082658494753275, 6.8765111424402621],
					[67.082658495649611, 6.8765111423617293],
					[67.082658495619, 6.8765111400621661],
					[67.082658495619, 6.8765111400621661],
					[67.08271689511092, 6.8762250447489537],
					[67.082772394163371, 6.8760000486494546],
					[67.08277239413276, 6.8760000463498807],
					[67.082772395029139, 6.8760000462713275],
					[67.082808484318249, 6.8758472916572044],
					[67.082883328932056, 6.8757585755533723],
					[67.083030469694478, 6.8758723508973159],
					[67.083083118065403, 6.8759223032582826],
					[67.083088701721749, 6.8759472311938978],
					[67.083097001385894, 6.8760333186191192],
					[67.083097001416505, 6.8760333209187232],
					[67.083097001416505, 6.8760333209187232],
					[67.083133101232733, 6.8763528169562376],
					[67.083146999708433, 6.8765750115949746],
					[67.083166499704603, 6.8768889112329603],
					[67.083180400352717, 6.877130611310089],
					[67.083196999568329, 6.8774250120643199],
					[67.083196999568329, 6.8774250120643199],
					[67.083197001422278, 6.8774250165065665],
					[67.083197001422278, 6.8774250165065665],
					[67.08321920112563, 6.8776778173207509],
					[67.083219201156226, 6.8776778196203683],
					[67.083219201217418, 6.8776778242196048],
					[67.083219202144406, 6.877677826440749],
					[67.083252602137023, 6.8779306254945221],
					[67.083252603125189, 6.8779306323149241],
					[67.083252605070896, 6.8779306436560832],
					[67.083252606028452, 6.8779306481768412],
					[67.083316505834475, 6.8781889463045944],
					[67.083333105124069, 6.8782583467111538],
					[67.083333112570415, 6.8782583667800763],
					[67.08333313101798, 6.87825840430438],
					[67.083333142946174, 6.8782584239809408],
					[67.083363742837747, 6.8782973249148629],
					[67.083363743734097, 6.8782973248363923],
					[67.083363745557435, 6.8782973269790828],
					[67.083363747380787, 6.8782973291217715],
					[67.083472047394991, 6.8784251288454357],
					[67.083472064670858, 6.8784251457517422],
					[67.083472105436059, 6.8784251744157512],
					[67.083472127132637, 6.8784251863304116],
					[67.083563827439775, 6.8784612855155727],
					[67.083563884960881, 6.8784612919919983],
					[67.083563993208486, 6.8784612663999702],
					[67.083564043996162, 6.8784612389308188],
					[67.08364184411424, 6.8783807397831644],
					[67.083641852925084, 6.8783807275001498],
					[67.083641871443163, 6.8783807028556518],
					[67.083641877564872, 6.8783806908080676],
					[67.083719576614882, 6.8782278924404485],
					[67.083764076035507, 6.8781444940845091],
					[67.083764077797667, 6.878144491627884],
					[67.083764081291406, 6.8781444844149346],
					[67.083764082157217, 6.8781444820367836],
					[67.083800181651625, 6.8780639830388886],
					[67.083886282403199, 6.8778778846250166],
					[67.083886284104167, 6.8778778775689728],
					[67.083886289298903, 6.8778778632999247],
					[67.083886291030453, 6.8778778585435836],
					[67.083955791272601, 6.8776472571780651],
					[67.083955792169007, 6.8776472570995661],
					[67.083955792138397, 6.8776472547998786],
					[67.083955793004193, 6.8776472524216912],
					[67.083983492307866, 6.877547254417526],
					[67.084014092434984, 6.8774417573078557],
					[67.084075191115431, 6.8772417581406966],
					[67.084075192816371, 6.8772417510845534],
					[67.084075196218265, 6.8772417369722865],
					[67.084075197919191, 6.8772417299161646],
					[67.084108497712577, 6.8770278317256786],
					[67.08414459791949, 6.8768000321130387],
					[67.08414459785827, 6.8768000275136298],
					[67.084144598662803, 6.8768000205359678],
					[67.084144598632193, 6.8768000182362616],
					[67.084172399116255, 6.8764944174105924],
					[67.084172399116255, 6.8764944174105924],
					[67.084186298934441, 6.8763278162038155],
					[67.084208499427049, 6.8760722203787381],
					[67.084230798240682, 6.8758722252847839],
					[67.084258498173156, 6.8756833328440123],
					[67.084327996213048, 6.8752833379744862],
					[67.084408495111845, 6.8748972450940711],
					[67.08446689366049, 6.874663949528502],
					[67.084466893629838, 6.8746639472287496],
					[67.084466895391941, 6.8746639447717968],
					[67.084466895391941, 6.8746639447717968],
					[67.084530795293759, 6.8743806448966911],
					[67.084591891867206, 6.8741333577338999],
					[67.084641883301131, 6.8739917815622151],
					[67.084739070785787, 6.8738112050706688],
					[67.084847364572497, 6.873652912068752],
					[67.084847366365238, 6.8736529119114547],
					[67.084847368993067, 6.8736529070759511],
					[67.084847369858764, 6.8736529046975381],
					[67.08495016958797, 6.8734862048223588],
					[67.084950171380711, 6.8734862046650465],
					[67.084950173112134, 6.873486199908184],
					[67.08495017400854, 6.8734861998295251],
					[67.085083473070384, 6.8732529004885574],
					[67.085241869615757, 6.8729779056124212],
					[67.085289053137075, 6.8729057322138862],
					[67.085339033394646, 6.8728612493647514],
					[67.085339036053114, 6.8728612468288981],
					[67.085339042266412, 6.8728612416785113],
					[67.085339044924837, 6.8728612391426607],
					[67.08550294519334, 6.8726890375568468],
					[67.08550294961384, 6.8726890325637591],
					[67.085502960216999, 6.8726890201203537],
					[67.085502964606874, 6.8726890128274123],
					[67.0856529408683, 6.8724695490589003],
					[67.085738842796374, 6.8724169695829271],
					[67.085838736418395, 6.8725612132775433],
					[67.085838739199545, 6.8725612199410602],
					[67.08583874559686, 6.8725612285896647],
					[67.085838750140084, 6.8725612327959125],
					[67.085963750118424, 6.8727029329156748],
					[67.085963755557984, 6.8727029370433099],
					[67.085963768291251, 6.8727029497409147],
					[67.085963773761492, 6.8727029561683972],
					[67.086097069970009, 6.8728112512772057],
					[67.086180466002872, 6.8728862481260578],
					[67.086180481394592, 6.8728862582879096],
					[67.086180511281597, 6.872886278690344],
					[67.086180526642607, 6.8728862865523297],
					[67.086258227014895, 6.8729168855566485],
					[67.086258253132414, 6.8729168924744952],
					[67.086258306141175, 6.8729168970318879],
					[67.086258333063043, 6.8729168969713363],
					[67.086391698927059, 6.872894602948544],
					[67.086469365023916, 6.8729084974702941],
					[67.086469430397912, 6.8729084871266055],
					[67.086469539080881, 6.8729084269289666],
					[67.086469583316941, 6.8729083792962937],
					[67.086516867177565, 6.8728001181177998],
					[67.086597335552796, 6.8727251451957825],
					[67.086597340004005, 6.8727251425023788],
					[67.086597347979364, 6.8727251348943748],
					[67.086597352399906, 6.8727251299010614],
					[67.086630554738264, 6.872686459911824],
					[67.086649811380397, 6.8727250700603113],
					[67.086674803703303, 6.872827839340248],
					[67.086722001833152, 6.8731278309650135],
					[67.086722005633291, 6.873127846749937],
					[67.086722016819039, 6.8731278780051017],
					[67.086722024235357, 6.873127895775264],
					[67.086819224454985, 6.8733083956378005],
					[67.086819226278408, 6.8733083977804137],
					[67.0868192290595, 6.8733084044442636],
					[67.086819230882924, 6.873308406586899],
					[67.086852630551206, 6.8733612063518743],
					[67.086852635125041, 6.8733612128584278],
					[67.086852644272753, 6.8733612258715091],
					[67.086852648815949, 6.8733612300780829],
					[67.086888748941632, 6.873402931374665],
					[67.086888758955041, 6.8734029420091378],
					[67.086888779847598, 6.873402960899492],
					[67.08688879162311, 6.8734029690766922],
					[67.087005491606433, 6.8734779674462398],
					[67.087005528603058, 6.8734779826208081],
					[67.087005605967178, 6.8734779965554953],
					[67.087005646334674, 6.8734779953155947],
					[67.087088946349823, 6.8734584947078794],
					[67.08708898736846, 6.8734584749893974],
					[67.087089054787768, 6.8734584161112666],
					[67.087089083042514, 6.8734583813942463],
					[67.087164081388863, 6.8732889839673508],
					[67.087291879551842, 6.8730250861399762],
					[67.087291883045339, 6.8730250789252567],
					[67.087291888270244, 6.8730250669531605],
					[67.087291890867377, 6.8730250598171017],
					[67.087389086401544, 6.8727139761133191],
					[67.087516846233356, 6.8724668536618712],
					[67.087630684191183, 6.8724140806988645],
					[67.087630685087532, 6.8724140806201612],
					[67.087630685983925, 6.8724140805414535],
					[67.087741786423635, 6.8723612803289322],
					[67.087741788185696, 6.8723612778714775],
					[67.087741792667572, 6.8723612774779363],
					[67.087741795325996, 6.8723612749417766],
					[67.08783898486125, 6.8723084798984297],
					[67.087969474627613, 6.8722557860739872],
					[67.087969499542055, 6.8722557700696436],
					[67.087969543961933, 6.8722557362331562],
					[67.087969564333108, 6.8722557160222584],
					[67.088086263952377, 6.8720890158397303],
					[67.088086265683756, 6.8720890110821502],
					[67.088086270104284, 6.8720890060884088],
					[67.088086270969995, 6.8720890037096183],
					[67.088152970825419, 6.8719779023598759],
					[67.08815297169113, 6.8719778999810739],
					[67.088152974380264, 6.8719778997449001],
					[67.088152975245947, 6.8719778973661008],
					[67.088222374829414, 6.8718528958712053],
					[67.088222375725806, 6.8718528957924745],
					[67.088222379249927, 6.8718528908773715],
					[67.088222380084957, 6.8718528861984742],
					[67.088369579040858, 6.8715500907627973],
					[67.088411275008838, 6.8714694960722413],
					[67.088450172002595, 6.8714029016434779],
					[67.088602971984329, 6.8711445018879882],
					[67.088602971984329, 6.8711445018879882],
					[67.088686272207312, 6.8710029005388229],
					[67.088686276566392, 6.8710028909445082],
					[67.088686286180959, 6.8710028716770717],
					[67.088686289643661, 6.8710028621614967],
					[67.088733489727161, 6.8708556605765532],
					[67.088733492385572, 6.8708556580400986],
					[67.088733494055532, 6.8708556486820394],
					[67.0887334949212, 6.870855646303121],
					[67.088783494880829, 6.8706417451096007],
					[67.088783494850148, 6.8706417428094655],
					[67.088783496550775, 6.8706417357514873],
					[67.088783497416472, 6.8706417333725751],
					[67.088833497422542, 6.8703417326061853],
					[67.088833498288224, 6.870341730227258],
					[67.088833498257529, 6.8703417279271157],
					[67.088833498226819, 6.8703417256269788],
					[67.088841898020902, 6.8702750269911697],
					[67.08885299831195, 6.8702028289321744],
					[67.08885299831195, 6.8702028289321744],
					[67.08885299828124, 6.8702028266320339],
					[67.088852998250516, 6.8702028243318924],
					[67.088891898153776, 6.8698944249950156],
					[67.088922397815836, 6.8696750269195315],
					[67.088958497989807, 6.8694222280044741],
					[67.088997398281109, 6.8691472288966526],
					[67.089019596939167, 6.8690028331461717],
					[67.08905299732946, 6.8688083340454469],
					[67.08905299732946, 6.8688083340454469],
					[67.089100197237613, 6.8685250331427188],
					[67.089152996563641, 6.8682333349767513],
					[67.089152996563641, 6.8682333349767513],
					[67.089152997460005, 6.8682333348978846],
					[67.089152997429281, 6.8682333325977165],
					[67.089202997248435, 6.8679361337801099],
					[67.089252997040248, 6.8676333330805521],
					[67.089252997905859, 6.8676333307014907],
					[67.089252997875107, 6.8676333284012943],
					[67.089252997875107, 6.8676333284012943],
					[67.089289097628765, 6.8673750280982349],
					[67.089289097628765, 6.8673750280982349],
					[67.089319597968796, 6.8671500269099477],
					[67.089319597968796, 6.8671500269099477],
					[67.089319597938044, 6.8671500246097663],
					[67.089319598834436, 6.8671500245308579],
					[67.089366898546857, 6.8667639230885369],
					[67.089416898788713, 6.866355624088313],
					[67.089439099202394, 6.8661722232557096],
					[67.089439099202394, 6.8661722232557096],
					[67.089439099171628, 6.8661722209555194],
					[67.089439099171628, 6.8661722209555194],
					[67.089461298884714, 6.8659528207992304],
					[67.089461298853962, 6.8659528184990366],
					[67.089461298823181, 6.8659528161988446],
					[67.08948629872657, 6.8656528181519567],
					[67.089486298695789, 6.865652815851762],
					[67.089486299561401, 6.8656528134726038],
					[67.089486299561401, 6.8656528134726038],
					[67.089500200060954, 6.8654389128564199],
					[67.089525199648747, 6.8650694141303479],
					[67.089525199617981, 6.8650694118301505],
					[67.089525199587186, 6.865069409529954],
					[67.089525199556419, 6.8650694072297567],
					[67.089536299603381, 6.8647778082140594],
					[67.0895362995726, 6.8647778059138389],
					[67.089547400351606, 6.8644639073902143],
					[67.089547400320811, 6.864463905090016],
					[67.08954740029003, 6.8644639027897947],
					[67.089547400259235, 6.8644639004895973],
					[67.089547399568545, 6.8641693989996222],
					[67.089547399568545, 6.8641693989996222],
					[67.089547400434128, 6.8641693966203841],
					[67.089547400434128, 6.8641693966203841],
					[67.089541900055792, 6.86388329509656],
					[67.089541900055792, 6.86388329509656],
					[67.089541899994202, 6.8638832904961431],
					[67.089541899994202, 6.8638832904961431],
					[67.089527999766773, 6.863608290552774],
					[67.089527999735978, 6.8636082882525793],
					[67.089527999705197, 6.8636082859523828],
					[67.089527999705197, 6.8636082859523828],
					[67.089508499880921, 6.8633277856209158],
					[67.08950849895372, 6.8633277833997726],
					[67.089508498861306, 6.8633277764991716],
					[67.089508497934162, 6.8633277742780257],
					[67.089480797813067, 6.8631138747042684],
					[67.089480797751449, 6.8631138701038665],
					[67.089480795804675, 6.8631138587610074],
					[67.089480794846665, 6.8631138542396855],
					[67.089427994572077, 6.862888853692283],
					[67.089402997609653, 6.8627832665266384],
					[67.089391899845097, 6.8626666868536956],
					[67.089375200293048, 6.8623277933998557],
					[67.089372400393231, 6.862136111979761],
					[67.089408497097367, 6.8618778386821937],
					[67.089489093036931, 6.8615583521779602],
					[67.089600191335464, 6.8611917590144298],
					[67.089600192170181, 6.8611917543349028],
					[67.089600193901219, 6.8611917495762276],
					[67.089600194735937, 6.8611917448967006],
					[67.089647395249429, 6.8609917463005177],
					[67.089647395187754, 6.8609917417001141],
					[67.089647396887955, 6.8609917346412344],
					[67.089647396857117, 6.8609917323410095],
					[67.089677997378686, 6.8608000314156472],
					[67.08967799827505, 6.8608000313365034],
					[67.089739097840265, 6.8604139309896226],
					[67.089739097778576, 6.8604139263892012],
					[67.089739099447954, 6.8604139170300193],
					[67.0897391002826, 6.8604139123504204],
					[67.089761299822456, 6.8600389123330734],
					[67.089777999385646, 6.8598028204339467],
					[67.089819597404528, 6.8595167331736766],
					[67.089872395659228, 6.8592306409183195],
					[67.089941895088472, 6.8589194431164424],
					[67.089941895088472, 6.8589194431164424],
					[67.089941895088472, 6.8589194431164424],
					[67.090000194994062, 6.8586500418974836],
					[67.090000196725057, 6.8586500371385704],
					[67.090000198363427, 6.8586500254789371],
					[67.09000019916715, 6.8586500184989969],
					[67.090027998826898, 6.8583639187059289],
					[67.090027998826898, 6.8583639187059289],
					[67.090027998796018, 6.8583639164056942],
					[67.090027998765137, 6.8583639141054586],
					[67.090047398926529, 6.8581139160006623],
					[67.090047399791985, 6.8581139136211844],
					[67.090047399761119, 6.8581139113209488],
					[67.090047399730224, 6.8581139090207115],
					[67.09005579982896, 6.8579361093673672],
					[67.090055799798094, 6.8579361070671299],
					[67.090055799798094, 6.8579361070671299],
					[67.090069600031867, 6.8575583071250019],
					[67.090077999832189, 6.8573000066377308],
					[67.090077999832189, 6.8573000066377308],
					[67.090077999801309, 6.8573000043374917],
					[67.090086300285734, 6.856958305570676],
					[67.090086300254839, 6.856958303270436],
					[67.090091900048492, 6.8566833047286027],
					[67.090100200045057, 6.8563611048099737],
					[67.090100200045057, 6.8563611048099737],
					[67.090105799642174, 6.8560694047179522],
					[67.090111299718288, 6.8557694041122605],
					[67.090111299718288, 6.8557694041122605],
					[67.090111299687379, 6.8557694018120223],
					[67.090111299656456, 6.8557693995117823],
					[67.090111300243578, 6.8554667004964349],
					[67.090111300290644, 6.8551861008787949],
					[67.090111300259721, 6.8551860985785558],
					[67.090111300228799, 6.8551860962783184],
					[67.090111300228799, 6.8551860962783184],
					[67.090105799734218, 6.8548860951045487],
					[67.090100199665585, 6.8546082966528763],
					[67.090097400251707, 6.8543110986882416],
					[67.090097399685177, 6.8540278023492478],
					[67.090102999705209, 6.8537167034012425],
					[67.090108499668958, 6.8534417031473103],
					[67.090108499668958, 6.8534417031473103],
					[67.090108499638006, 6.8534417008470534],
					[67.090108499607069, 6.8534416985468178],
					[67.090105799988123, 6.853119398331442],
					[67.090105799957172, 6.8531193960312065],
					[67.090100199826807, 6.8528666956556314],
					[67.090100199795856, 6.8528666933553977],
					[67.09010019976489, 6.8528666910551435],
					[67.09010019976489, 6.8528666910551435],
					[67.090086300342804, 6.8525638903802717],
					[67.090086300342804, 6.8525638903802717],
					[67.090086300311839, 6.8525638880800415],
					[67.090072400328836, 6.8523082896153946],
					[67.090072400297856, 6.8523082873151644],
					[67.090072400297856, 6.8523082873151644],
					[67.090072400266905, 6.852308285014912],
					[67.090053000378148, 6.852033285901479],
					[67.090052998585421, 6.8520332860604158],
					[67.090033498586934, 6.8517610851327486],
					[67.090033498586934, 6.8517610851327486],
					[67.090033498586934, 6.8517610851327486],
					[67.090014098760506, 6.851516684138927],
					[67.090014098760506, 6.851516684138927],
					[67.09001409872954, 6.851516681838703],
					[67.09001409872954, 6.851516681838703],
					[67.089986298678184, 6.8512193807828874],
					[67.08996689877506, 6.8510110820056029],
					[67.089966898744066, 6.8510110797053647],
					[67.089933498842854, 6.8506860789744675],
					[67.089933498842854, 6.8506860789744675],
					[67.089925199017458, 6.8506055781208719],
					[67.08992519805912, 6.8506055735999425],
					[67.089925196111466, 6.8506055622579165],
					[67.089925195153114, 6.8506055577369898],
					[67.089911294613387, 6.8505416584596652],
					[67.089850195922722, 6.8502582578984859],
					[67.08985019499535, 6.8502582556778151],
					[67.089850194036998, 6.8502582511569372],
					[67.08985019400599, 6.8502582488567096],
					[67.089794593930847, 6.850044350835776],
					[67.089794593003518, 6.8500443486151141],
					[67.089750192804203, 6.849874950093346],
					[67.089750189032785, 6.8498749366103366],
					[67.089750178831835, 6.8498749121831581],
					[67.089750173267689, 6.8498748988592428],
					[67.08967517283601, 6.8497442992798296],
					[67.08967510399961, 6.8497442524213401],
					[67.089674951987718, 6.8497442267623141],
					[67.089674869708588, 6.8497442478822261],
					[67.089558178041116, 6.8498442412293912],
					[67.089463948397238, 6.8499080257723941],
					[67.089358471437251, 6.8497637954522883],
					[67.089258480074122, 6.8495666124376706],
					[67.089236291010366, 6.8495193365697462],
					[67.089222396056641, 6.8494499605968686],
					[67.089222396056641, 6.8494499605968686],
					[67.089164095753432, 6.8491666607857695],
					[67.08911959605841, 6.8489443611790843],
					[67.089075196446544, 6.8487166624182674],
					[67.089075196415521, 6.8487166601181375],
					[67.089075196384513, 6.8487166578180068],
					[67.089075194591786, 6.8487166579771825],
					[67.089014095377337, 6.8484416563680384],
					[67.0890140953463, 6.8484416540679147],
					[67.089014092564199, 6.8484416474063314],
					[67.089014091636813, 6.848441645185785],
					[67.088997394436646, 6.8483832507047415],
					[67.088983494881433, 6.8483221552897566],
					[67.088983493954075, 6.848322153069236],
					[67.088983492995652, 6.8483221485485766],
					[67.088983492964644, 6.8483221462484556],
					[67.088908492862203, 6.8480527451941633],
					[67.08890849100743, 6.8480527407531371],
					[67.088908487266892, 6.848052729570993],
					[67.088908485412134, 6.848052725129989],
					[67.088825185392182, 6.8478471246201567],
					[67.088825185392182, 6.8478471246201567],
					[67.088825183568446, 6.8478471224792878],
					[67.088825184433787, 6.8478471200995648],
					[67.088789084155195, 6.8477638196848982],
					[67.088789081373008, 6.8477638130234615],
					[67.088789074047028, 6.8477638021599025],
					[67.088789071264841, 6.8477637954984418],
					[67.088750170640992, 6.8476998969557847],
					[67.088750168817256, 6.8476998948149337],
					[67.088750166035069, 6.8476998881535112],
					[67.088750165107683, 6.8476998859330171],
					[67.08865296487545, 6.8475581879346716],
					[67.088652959373135, 6.8475581792120703],
					[67.088652944783163, 6.8475581620853978],
					[67.088652938415549, 6.8475581557425143],
					[67.088539056875788, 6.8474498720048596],
					[67.08843347056839, 6.8472776972253717],
					[67.088316875051262, 6.8470805033159543],
					[67.088227978411638, 6.8469055083906829],
					[67.088227978411638, 6.8469055083906829],
					[67.088227975660459, 6.8469055040295226],
					[67.088227975660459, 6.8469055040295226],
					[67.088144576317831, 6.8467527044319292],
					[67.088144575421438, 6.8467527045115881],
					[67.088144575390402, 6.8467527022115489],
					[67.088102974888045, 6.8466777033004727],
					[67.088102970282122, 6.8466776944986041],
					[67.088102959308486, 6.8466776793542499],
					[67.088102952909779, 6.8466776707117036],
					[67.088058452652902, 6.8466248707788715],
					[67.088058446285288, 6.8466248644363814],
					[67.088058431726225, 6.8466248496107207],
					[67.088058425358568, 6.8466248432682342],
					[67.08794732474712, 6.8465359436421629],
					[67.087947316586749, 6.8465359374590564],
					[67.087947300297103, 6.8465359273928792],
					[67.087947291240354, 6.8465359212894583],
					[67.087844490940995, 6.8464831222153686],
					[67.087844469304187, 6.846483114927274],
					[67.087844424268951, 6.8464831028104429],
					[67.087844400005167, 6.8464831003613655],
					[67.087736149728784, 6.8464831002807669],
					[67.087627954118915, 6.8464248564156174],
					[67.087519586576448, 6.8461860265450216],
					[67.087411291904729, 6.8458582423406948],
					[67.087372393777372, 6.8457110512530113],
					[67.087347395220434, 6.8456027544059319],
					[67.087347395220434, 6.8456027544059319],
					[67.087347395189397, 6.8456027521059672],
					[67.087347394293019, 6.8456027521856671],
					[67.087283493980095, 6.8453388540480749],
					[67.087283492990565, 6.8453388472279144],
					[67.087283489218748, 6.8453388337469843],
					[67.087283487332854, 6.8453388270065076],
					[67.087200187082601, 6.8451221271827878],
					[67.087200186155187, 6.8451221249625531],
					[67.087200184331408, 6.8451221228220325],
					[67.087200183403965, 6.8451221206017987],
					[67.087172382853879, 6.8450582195636605],
					[67.087172380999021, 6.8450582151232027],
					[67.087172376392957, 6.8450582063219834],
					[67.087172373641764, 6.8450582019612405],
					[67.087150174222302, 6.8450193008253439],
					[67.087150172398538, 6.8450192986848348],
					[67.087150167792473, 6.8450192898836297],
					[67.087150165968666, 6.8450192877431215],
					[67.087075167112403, 6.844908190594353],
					[67.08694186985295, 6.8447026949140035],
					[67.086833475024392, 6.8445193025806512],
					[67.086714078005258, 6.8442860108689132],
					[67.086639085207253, 6.8441360231414636],
					[67.086589089579505, 6.8439888366207589],
					[67.0865307918301, 6.8438138434513718],
					[67.086472394024199, 6.8435860511309672],
					[67.086472393096727, 6.8435860489108586],
					[67.08647239306562, 6.8435860466109855],
					[67.08647239306562, 6.8435860466109855],
					[67.086444592997907, 6.8434860483104245],
					[67.086419593946815, 6.8433888519479851],
					[67.086358495764443, 6.8431416582178173],
					[67.086311297200552, 6.8428888625966717],
					[67.086275197284536, 6.8426943679012462],
					[67.086233498415623, 6.8423999709329673],
					[67.086233498415623, 6.8423999709329673],
					[67.086233496591802, 6.8423999687927362],
					[67.086233496560709, 6.8423999664928639],
					[67.086216898439204, 6.8422999700690124],
					[67.086200197632778, 6.8421888704697036],
					[67.086141899168979, 6.8417721741060289],
					[67.086105799085317, 6.8414388831201105],
					[67.086089100055901, 6.8411638891065749],
					[67.086075200161943, 6.8409027909737237],
					[67.086061300017477, 6.8406082914711339],
					[67.086052999654726, 6.8403527944595206],
					[67.086044600036956, 6.8400388938554473],
					[67.086039100326957, 6.8397860971244233],
					[67.086036299623899, 6.8394666986616945],
					[67.086033500010217, 6.8392110985123313],
					[67.08603080019553, 6.8388888980435194],
					[67.086030799665778, 6.8386278006463099],
					[67.086030799745387, 6.8383082997764459],
					[67.08603080038344, 6.8380361013236222],
					[67.086033499561239, 6.837725001221064],
					[67.086033499561239, 6.837725001221064],
					[67.086033500426396, 6.8377249988412583],
					[67.086033500384417, 6.8374417017623319],
					[67.086036300180297, 6.8371389026173963],
					[67.086036300149104, 6.8371389003175729],
					[67.086036300149104, 6.8371389003175729],
					[67.086036300004039, 6.8368472009091903],
					[67.08603910040776, 6.8365528014527817],
					[67.08603910040776, 6.8365528014527817],
					[67.086039100376567, 6.8365527991529582],
					[67.086039099677279, 6.8362528010664363],
					[67.086041900306, 6.8359667023268509],
					[67.086041900274793, 6.8359667000270266],
					[67.086041900274793, 6.8359667000270266],
					[67.086041900244865, 6.835658299529074],
					[67.086041899647142, 6.835383300752202],
					[67.08604460038174, 6.8350639010714973],
					[67.08604460038174, 6.8350639010714973],
					[67.08604460038174, 6.8350639010714973],
					[67.08604460011108, 6.8347972007824342],
					[67.086047400135755, 6.8344694013120888],
					[67.086047400135755, 6.8344694013120888],
					[67.086047400104547, 6.8344693990122662],
					[67.086047400155493, 6.8342139008148006],
					[67.086050199944893, 6.8338750025628991],
					[67.086050199913672, 6.8338750002630801],
					[67.086050199913672, 6.8338750002630801],
					[67.086050200425191, 6.8336278005542717],
					[67.086050200067945, 6.8332833015267553],
					[67.086053000228929, 6.833044401011823],
					[67.086053000228929, 6.833044401011823],
					[67.086053000228929, 6.833044401011823],
					[67.086053000256456, 6.832691699893803],
					[67.086053000256456, 6.832691699893803],
					[67.086053000225206, 6.8326916975939849],
					[67.086050200451893, 6.8324638990738666],
					[67.086047399625699, 6.8321110984755542],
					[67.08604739959442, 6.8321110961757165],
					[67.08604460007777, 6.8318916976405664],
					[67.086044600046492, 6.83189169534075],
					[67.086044600015242, 6.8318916930409319],
					[67.08603350033323, 6.8315499946021463],
					[67.086033500301966, 6.8315499923023113],
					[67.086025200120375, 6.8313277934391925],
					[67.086025200089111, 6.8313277911393779],
					[67.086025200057833, 6.8313277888395643],
					[67.086025200057833, 6.8313277888395643],
					[67.086005800272488, 6.8310110872176866],
					[67.086005800272488, 6.8310110872176866],
					[67.086005799344861, 6.8310110849981047],
					[67.086005799313583, 6.8310110826982928],
					[67.085986299403828, 6.8307805819215952],
					[67.085986299403828, 6.8307805819215952],
					[67.085961298943189, 6.8305027809390459],
					[67.085961298943189, 6.8305027809390459],
					[67.085961298880633, 6.8305027763394328],
					[67.085961297953034, 6.8305027741198723],
					[67.08592799886361, 6.8302471793440418],
					[67.085908499421237, 6.8300082844940464],
					[67.08590849938993, 6.8300082821942247],
					[67.08590849938993, 6.8300082821942247],
					[67.085880799402716, 6.8297082833876157],
					[67.08586690010614, 6.8294943890988806],
					[67.085852999724736, 6.8291555927747458],
					[67.08584740043932, 6.8289471954267933],
					[67.085839099971324, 6.8285888944456978],
					[67.085833500364984, 6.8283805940316915],
					[67.085833500364984, 6.8283805940316915],
					[67.085822399949862, 6.8280221943213055],
					[67.085822399918541, 6.8280221920215158],
					[67.085822399887221, 6.8280221897217253],
					[67.085822399887221, 6.8280221897217253],
					[67.085814100073009, 6.8278749892898007],
					[67.085814100041688, 6.8278749869900119],
					[67.085814100041688, 6.8278749869900119],
					[67.085814100041688, 6.8278749869900119],
					[67.085800200355251, 6.8276721858138032],
					[67.085800199458888, 6.8276721858941567],
					[67.085783499146203, 6.8274499856487294],
					[67.085783499114896, 6.8274499833489433],
					[67.085783499083561, 6.8274499810491589],
					[67.085783499083561, 6.8274499810491589],
					[67.085769599108318, 6.8273082797539013],
					[67.085769599108318, 6.8273082797539013],
					[67.085769599045662, 6.8273082751543122],
					[67.085769598149298, 6.8273082752346799],
					[67.085730798412087, 6.8270027759721161],
					[67.085730798380766, 6.8270027736723362],
					[67.085730798318096, 6.8270027690727764],
					[67.085730797390426, 6.8270027668533739],
					[67.085694597408803, 6.8267888672550159],
					[67.085694597377469, 6.82678886495522],
					[67.085694597377469, 6.82678886495522],
					[67.085694597377469, 6.82678886495522],
					[67.085650197097294, 6.82654716320482],
					[67.085650196169595, 6.8265471609854433],
					[67.085650195210604, 6.8265471564662921],
					[67.085650195179284, 6.8265471541665201],
					[67.085589095347402, 6.8262860553108613],
					[67.085569594972597, 6.826197156602726],
					[67.085569594941248, 6.8261971543029629],
					[67.085569594013549, 6.8261971520835871],
					[67.085569593085879, 6.8261971498642273],
					[67.085544592635856, 6.8261027487883599],
					[67.085544592635856, 6.8261027487883599],
					[67.085544592604521, 6.8261027464885764],
					[67.085544592604521, 6.8261027464885764],
					[67.085480793123409, 6.8258749454417487],
					[67.08548079222706, 6.8258749455221652],
					[67.085480792195725, 6.8258749432223889],
					[67.085480791268026, 6.8258749410030539],
					[67.08542239167879, 6.8256832443743551],
					[67.085377993071901, 6.8255166474155091],
					[67.085377993071901, 6.8255166474155091],
					[67.085377993040552, 6.8255166451157656],
					[67.085319593241209, 6.8253082451049707],
					[67.085319592344888, 6.8253082451854077],
					[67.085319592313525, 6.8253082428856695],
					[67.085252992650766, 6.8250860460894698],
					[67.085177994328191, 6.8247860507855274],
					[67.085122393815837, 6.8245638501489116],
					[67.08512239288811, 6.8245638479296593],
					[67.085077994398574, 6.8243971513642432],
					[67.085022395430045, 6.8241638541661311],
					[67.085022393605982, 6.8241638520273815],
					[67.085022393574619, 6.8241638497276496],
					[67.085022393574619, 6.8241638497276496],
					[67.084975194334973, 6.823983250116413],
					[67.084975193407274, 6.8239832478971953],
					[67.084975193375897, 6.8239832455974918],
					[67.084975191583169, 6.8239832457584662],
					[67.084922392416473, 6.8237971447402703],
					[67.084922390592396, 6.8237971426015349],
					[67.084922388705564, 6.823797135863428],
					[67.084922387777866, 6.8237971336442218],
					[67.084852990048404, 6.8236027361239762],
					[67.0847945930972, 6.8234110471712492],
					[67.084733494864238, 6.8231527578184599],
					[67.084672395555103, 6.8228555599374117],
					[67.084611296583333, 6.8225416635113474],
					[67.084572399289968, 6.8223166757093399],
					[67.084558499794312, 6.8221305874990019],
					[67.0845419001773, 6.8217777935420534],
					[67.084536299689844, 6.8215305955708967],
					[67.084536299658453, 6.8215305932712376],
					[67.084536299627018, 6.8215305909715589],
					[67.084536299627018, 6.8215305909715589],
					[67.084519599917044, 6.8211666902562955],
					[67.084519598926491, 6.8211666834378919],
					[67.084519597873083, 6.8211666720201789],
					[67.084519596851109, 6.8211666629021197],
					[67.084458497288452, 6.8208332636127658],
					[67.084458496392131, 6.8208332636933644],
					[67.084458496329304, 6.8208332590940426],
					[67.084458496329304, 6.8208332590940426],
					[67.084408495916435, 6.8205971581591527],
					[67.084408494988651, 6.820597155940118],
					[67.084408494029489, 6.8205971514214356],
					[67.084408493101719, 6.8205971492024009],
					[67.084352995254577, 6.8203860557419036],
					[67.08428909765054, 6.8200499697284398],
					[67.084258498909222, 6.8197721811531773],
					[67.084233498771894, 6.8194471841292925],
					[67.084233498771894, 6.8194471841292925],
					[67.084233498740446, 6.8194471818296671],
					[67.084233498709025, 6.8194471795300418],
					[67.084200198807622, 6.8191138813780565],
					[67.084175198601201, 6.8188416809895509],
					[67.08417519943464, 6.818841676309618],
					[67.084175196619825, 6.8188416673531584],
					[67.08417519658839, 6.8188416650535162],
					[67.084111297419852, 6.8184916648685343],
					[67.084111294605023, 6.8184916559121174],
					[67.0841112898717, 6.8184916379186173],
					[67.084111287984683, 6.8184916311811277],
					[67.084033488336132, 6.8182805310873382],
					[67.084033483697141, 6.818280519992757],
					[67.084033475378462, 6.8182805023221276],
					[67.084033469874598, 6.8182804936078405],
					[67.083880769903317, 6.8180359932280252],
					[67.083880748045104, 6.81803596986932],
					[67.083880699013505, 6.8180359282353233],
					[67.083880670975262, 6.8180359123403012],
					[67.083741770737674, 6.8179831123878261],
					[67.083741747338408, 6.8179831075873478],
					[67.083741697017402, 6.8179831029083831],
					[67.08374167188839, 6.8179831028684861],
					[67.08364437155582, 6.817997002316357],
					[67.083644345624805, 6.8179970092558735],
					[67.083644298338854, 6.8179970296300807],
					[67.083644276087583, 6.8179970431454953],
					[67.08353597620679, 6.8180831435507541],
					[67.083535963783817, 6.8180831538787867],
					[67.083535944347375, 6.8180831763502177],
					[67.0835359346449, 6.818083188735713],
					[67.083435934987364, 6.8182304890702108],
					[67.083435929672177, 6.8182304941534673],
					[67.083435920928935, 6.8182305110572772],
					[67.083435918334274, 6.8182305181980025],
					[67.083349818308946, 6.8184221185933147],
					[67.083349815651346, 6.8184221211349128],
					[67.083349813953006, 6.8184221281949275],
					[67.083349812191784, 6.818422130655839],
					[67.083233111986246, 6.8187388315696298],
					[67.083233111986246, 6.8187388315696298],
					[67.083233111121373, 6.8187388339498316],
					[67.083233111152808, 6.8187388362493619],
					[67.083183110762889, 6.8188860366478563],
					[67.083183109033101, 6.8188860414082635],
					[67.083183105573497, 6.8188860509290361],
					[67.083183104771493, 6.8188860579083013],
					[67.083158104953085, 6.8189999559981507],
					[67.083097005968028, 6.819247152883027],
					[67.083097005071707, 6.819247152963678],
					[67.083097005134576, 6.8192471575627369],
					[67.083097004269675, 6.8192471599429085],
					[67.083041504105211, 6.8195138569025442],
					[67.08301920760384, 6.8196166410781611],
					[67.082969216805139, 6.8197416198716576],
					[67.082833131218464, 6.8200304887196399],
					[67.082755542017281, 6.8201191630775551],
					[67.08262233204718, 6.8200665370684765],
					[67.082547387930333, 6.8199527226568675],
					[67.082480798235338, 6.8196388687328016],
					[67.082452998557841, 6.8193610796995499],
					[67.082452999454176, 6.819361079618905],
					[67.082452999422728, 6.8193610773194475],
					[67.082414098885693, 6.8190166788520568],
					[67.082414098854244, 6.8190166765525824],
					[67.082414097895025, 6.8190166720343335],
					[67.082414097895025, 6.8190166720343335],
					[67.082358497989148, 6.8186249715161429],
					[67.082358497989148, 6.8186249715161429],
					[67.082358497957713, 6.818624969216696],
					[67.082333498368087, 6.8184582711007033],
					[67.082333497377405, 6.8184582642830263],
					[67.082333493603315, 6.8184582508090577],
					[67.08233349171627, 6.8184582440720645],
					[67.082289092295213, 6.8183082435724582],
					[67.082289085800639, 6.8183082280411513],
					[67.082289072874445, 6.8183082015773957],
					[67.082289064587201, 6.8183081862074602],
					[67.082175164975993, 6.818141585893704],
					[67.082175150414329, 6.8181415710887121],
					[67.082175116777861, 6.818141539582796],
					[67.082175098630842, 6.8181415251005673],
					[67.082077899387087, 6.8180859248345795],
					[67.082077815838517, 6.8180859185424536],
					[67.082077672958476, 6.8180859705434713],
					[67.082077612730657, 6.8180860289173113],
					[67.082008113332549, 6.8182666279233617],
					[67.082008109039549, 6.8182666421232918],
					[67.082008103111121, 6.8182666679816846],
					[67.082008100610793, 6.8182666820202451],
					[67.081985900767123, 6.8185027819951687],
					[67.081985900767123, 6.8185027819951687],
					[67.081985900767123, 6.8185027819951687],
					[67.081963701006586, 6.8187555819813204],
					[67.081963701069498, 6.8187555865801412],
					[67.081963700267451, 6.8187555935590609],
					[67.081963700330348, 6.8187555981578827],
					[67.08196090020418, 6.8190610977140675],
					[67.081960900235629, 6.8190611000134762],
					[67.081960900267063, 6.8190611023128875],
					[67.081960900298498, 6.819061104612298],
					[67.08196650031239, 6.8193333037715771],
					[67.081966500343839, 6.8193333060709875],
					[67.081974800047533, 6.8196389028444484],
					[67.081974800382895, 6.8199138943332835],
					[67.081960900924955, 6.8201971830553667],
					[67.081935902158762, 6.8203916706677887],
					[67.081891504828349, 6.8206416547691209],
					[67.081830414875512, 6.8208471194588478],
					[67.081683157773512, 6.8210998470424755],
					[67.081552722382668, 6.8211637154499662],
					[67.081452732151917, 6.8211998123418951],
					[67.081452726805239, 6.8211998151247126],
					[67.081452716080534, 6.8211998183909781],
					[67.081452710733871, 6.8211998211737948],
					[67.081363824821679, 6.8212442134991011],
					[67.08123884126941, 6.8212831089027448],
					[67.081238837684054, 6.8212831092250283],
					[67.081238833233712, 6.8212831119272321],
					[67.081238829648342, 6.8212831122495148],
					[67.081163830286229, 6.8213109117196753],
					[67.081163792953845, 6.8213109380970112],
					[67.081163731891266, 6.8213110011398879],
					[67.081163709088841, 6.8213110400241774],
					[67.081119208994025, 6.821452739150847],
					[67.081119208160516, 6.8214527438300783],
					[67.081119205597091, 6.8214527532691305],
					[67.081119204763581, 6.8214527579483608],
					[67.08107480502872, 6.8216582586957646],
					[67.081074803361602, 6.8216582680542235],
					[67.081074800892424, 6.8216582843912246],
					[67.081074800121655, 6.8216582936691248],
					[67.081063700043529, 6.8219832873669031],
					[67.081047000855307, 6.8221582759073875],
					[67.081016502998381, 6.8223527668805088],
					[67.080985904346278, 6.8225138599457678],
					[67.08095810530989, 6.8226499530520579],
					[67.080891508367088, 6.8228888450810983],
					[67.080816512224516, 6.823144331063479],
					[67.080755417940324, 6.8232832191211736],
					[67.08061371822744, 6.823591615811142],
					[67.080613717362468, 6.8235916181909166],
					[67.080613714767566, 6.8235916253302324],
					[67.080613713902594, 6.8235916277100266],
					[67.080560913997132, 6.8237277290688123],
					[67.080560909640923, 6.823727738668361],
					[67.080560903648859, 6.8237277599253128],
					[67.080560902013048, 6.8237277715827123],
					[67.080544201750598, 6.8238471709491932],
					[67.080499802874499, 6.8241138680254014],
					[67.080499802040862, 6.824113872704447],
					[67.080499801301372, 6.8241138842813269],
					[67.080499799571442, 6.8241138890408131],
					[67.080485899939049, 6.8243832874301296],
					[67.080469200092423, 6.8246388870951105],
					[67.080469200155136, 6.8246388916936631],
					[67.080469200249226, 6.8246388985915161],
					[67.080469200280575, 6.8246389008907933],
					[67.080471999793772, 6.824941692930536],
					[67.080455400310782, 6.8251916866031435],
					[67.080435900948203, 6.8254971810534233],
					[67.08041650262841, 6.8256443650206684],
					[67.080374807002869, 6.8258221475881307],
					[67.080277609816633, 6.8261277370083704],
					[67.080180412892545, 6.8264055310576444],
					[67.08011371381447, 6.8265721268318194],
					[67.08011371381447, 6.8265721268318194],
					[67.080113712949455, 6.8265721292114367],
					[67.08011371208444, 6.8265721315910772],
					[67.080058112866311, 6.826724929515211],
					[67.080005413142743, 6.826863829439719],
					[67.080005412277728, 6.826863831819316],
					[67.080005409682656, 6.8268638389581078],
					[67.08000540878632, 6.8268638390384675],
					[67.079952609377045, 6.8270332401609215],
					[67.079952607646987, 6.8270332449200923],
					[67.079952605948279, 6.8270332519784951],
					[67.07995260508325, 6.8270332543581018],
					[67.079874804941966, 6.8273666552436385],
					[67.079772005015357, 6.8278082543070502],
					[67.079747005899478, 6.8279110538439216],
					[67.079747005003156, 6.8279110539242405],
					[67.079747005034463, 6.8279110562234751],
					[67.079747005034463, 6.8279110562234751],
					[67.079733105159136, 6.8279749559081102],
					[67.079666505760585, 6.8282499532506931],
					[67.079610906235118, 6.8284805523430796],
					[67.079574807161308, 6.8286249475929237],
					[67.079530410103985, 6.8287749382895973],
					[67.079474811876793, 6.8289332312930195],
					[67.079416512783439, 6.8290860288798001],
					[67.079363714141778, 6.8292249263774281],
					[67.079310919026852, 6.829349914376146],
					[67.079235923179667, 6.8294916062258553],
					[67.079235922314581, 6.8294916086052844],
					[67.079235919688145, 6.8294916134443984],
					[67.079235918823059, 6.8294916158238488],
					[67.079155419381379, 6.8296638148758362],
					[67.079155419381379, 6.8296638148758362],
					[67.079155417619972, 6.8296638173355024],
					[67.079155417619972, 6.8296638173355024],
					[67.079119218067632, 6.8297443168518299],
					[67.07911921457611, 6.8297443240703002],
					[67.079119209417016, 6.8297443406459415],
					[67.079119206853093, 6.829744350083323],
					[67.079085906630525, 6.8298693490754117],
					[67.079085905765453, 6.8298693514548123],
					[67.079085904066616, 6.8298693585127639],
					[67.079085904129172, 6.8298693631110954],
					[67.07900540434882, 6.8302888630152196],
					[67.07900540434882, 6.8302888630152196],
					[67.07900540255612, 6.830288863175678],
					[67.078922003298075, 6.830733261972747],
					[67.078897003556733, 6.8308638622245583],
					[67.078897002691662, 6.8308638646039244],
					[67.078897002722925, 6.830863866903063],
					[67.078897002722925, 6.830863866903063],
					[67.078880403162842, 6.8309638660209213],
					[67.078833103086893, 6.8312443648965795],
					[67.078794202603504, 6.8314555621002633],
					[67.078749804000324, 6.8316888631788055],
					[67.07874980310396, 6.8316888632589823],
					[67.078697004431405, 6.831972162012077],
					[67.07868310369193, 6.832041659417321],
					[67.078660905076845, 6.832144358138998],
					[67.078660904180509, 6.8321443582191561],
					[67.078660904211759, 6.8321443605182735],
					[67.078569203739534, 6.8326110619006304],
					[67.078569203739534, 6.8326110619006304],
					[67.078483104430688, 6.833058261395756],
					[67.078483102669225, 6.8330582638551292],
					[67.07848310270046, 6.8330582661542314],
					[67.07848310270046, 6.8330582661542314],
					[67.078458103269483, 6.8332027649186529],
					[67.078458103300719, 6.8332027672177524],
					[67.078458103300719, 6.8332027672177524],
					[67.078435903092299, 6.8333416681864279],
					[67.078435902195977, 6.8333416682665389],
					[67.078435902227199, 6.8333416705656367],
					[67.078435902258434, 6.8333416728647345],
					[67.078374801711007, 6.8337693714156575],
					[67.078374801742228, 6.8337693737147509],
					[67.078374800877128, 6.8337693760939606],
					[67.078374800877128, 6.8337693760939606],
					[67.078330400593231, 6.8341555759415273],
					[67.078330400624452, 6.8341555782406385],
					[67.078302601065317, 6.8344027771055149],
					[67.078266502680918, 6.8347110649357976],
					[67.078197010879762, 6.8349860336331423],
					[67.07806097262673, 6.8352776007428346],
					[67.077936110069373, 6.8352776003798974],
					[67.07782221883086, 6.8352665014558074],
					[67.077822158003684, 6.8352665160947854],
					[67.077822054744473, 6.8352665782576008],
					[67.077822014105152, 6.8352666256213572],
					[67.07770541381683, 6.8355610271029512],
					[67.077705412086544, 6.835561031861074],
					[67.077705407760774, 6.8355610437564041],
					[67.077705406030475, 6.8355610485145482],
					[67.07762200556553, 6.8358805493428143],
					[67.077622004731538, 6.8358805540209033],
					[67.077622003094831, 6.8358805656760824],
					[67.07762200229206, 6.8358805726531751],
					[67.0775859019747, 6.8361360713808628],
					[67.077585902005879, 6.8361360736798851],
					[67.077585902037086, 6.8361360759789056],
					[67.07758590114075, 6.8361360760589136],
					[67.077560901290809, 6.8363443768174941],
					[67.077513701013658, 6.836741675166504],
					[67.077463701727936, 6.8371360750581474],
					[67.077463700862737, 6.8371360774371528],
					[67.077438701450873, 6.837352777527502],
					[67.077408101897618, 6.837622174398887],
					[67.07736650300609, 6.8379027668728227],
					[67.077316502893069, 6.8381749648359262],
					[67.077316502893069, 6.8381749648359262],
					[67.077316502924219, 6.8381749671349477],
					[67.07731650205902, 6.8381749695138785],
					[67.07726650306482, 6.8384888652093769],
					[67.0772081037954, 6.8387916612120101],
					[67.077208102930172, 6.8387916635909098],
					[67.077208102992486, 6.8387916681888905],
					[67.077208102127301, 6.8387916705677858],
					[67.077169202236064, 6.8390527706092117],
					[67.077169202267228, 6.8390527729081985],
					[67.077169201433193, 6.8390527775860663],
					[67.077169200567965, 6.8390527799649741],
					[67.077147000841947, 6.8392693799386901],
					[67.077147000841947, 6.8392693799386901],
					[67.077147000904247, 6.8392693845366592],
					[67.077147000904247, 6.8392693845366592],
					[67.077119201156094, 6.8396416840511343],
					[67.077088700753336, 6.8399832830314322],
					[67.077088700753336, 6.8399832830314322],
					[67.077088699888094, 6.8399832854102778],
					[67.07708869991923, 6.8399832877092575],
					[67.077069201101381, 6.8402888821863561],
					[67.07702200137912, 6.8407082769391563],
					[67.077008102750568, 6.840819365879427],
					[67.076983104986667, 6.8409360558635264],
					[67.076883108032192, 6.8413416428777376],
					[67.076783110900848, 6.8416332357212957],
					[67.076783110004484, 6.8416332358010994],
					[67.076783110035592, 6.8416332381000524],
					[67.076783109170378, 6.8416332404788056],
					[67.07667200856838, 6.8419888402674607],
					[67.076672007703152, 6.8419888426461917],
					[67.076672006869003, 6.8419888473238899],
					[67.076672006900125, 6.8419888496228332],
					[67.076583107420859, 6.8423277484926572],
					[67.076583105659211, 6.8423277509511466],
					[67.076502607212035, 6.8426388478322648],
					[67.076388706768299, 6.8430582489539402],
					[67.076388706768299, 6.8430582489539402],
					[67.076358106652918, 6.8431749485685565],
					[67.076358104922392, 6.8431749533258728],
					[67.076358104150387, 6.8431749626013012],
					[67.076358103316238, 6.8431749672788742],
					[67.076338703124406, 6.8432916668930464],
					[67.07625810317559, 6.8437582652225615],
					[67.076172003697607, 6.8442166605669446],
					[67.076138706033674, 6.8443693492028519],
					[67.076077608904086, 6.8445721417671326],
					[67.076077608038787, 6.8445721441457152],
					[67.076077608038787, 6.8445721441457152],
					[67.076077608038787, 6.8445721441457152],
					[67.075988709270788, 6.8448805416986715],
					[67.075922009604355, 6.8450805359851028],
					[67.075922008770149, 6.8450805406625275],
					[67.075922006205317, 6.8450805500970704],
					[67.075922005371112, 6.8450805547744951],
					[67.075819204822722, 6.8455249559193225],
					[67.075819203988488, 6.8455249605967117],
					[67.075819202320005, 6.8455249699515139],
					[67.075819202382121, 6.8455249745492504],
					[67.075760902074961, 6.8459666748742363],
					[67.075760901178597, 6.8459666749538739],
					[67.07576090127175, 6.8459666818504648],
					[67.0757609013028, 6.8459666841493494],
					[67.075747000656065, 6.8461388838385533],
					[67.075747000687116, 6.8461388861374139],
					[67.075746999821817, 6.8461388885159282],
					[67.075746999852868, 6.8461388908147898],
					[67.075735899552967, 6.846374990077714],
					[67.075735899584004, 6.8463749923765764],
					[67.075735899584004, 6.8463749923765764],
					[67.075727599957816, 6.8466138922947941],
					[67.075721999779248, 6.8467610927553917],
					[67.075721999779248, 6.8467610927553917],
					[67.075721999810284, 6.8467610950542523],
					[67.075710900125387, 6.8471055930726452],
					[67.075702600142066, 6.8473166911890972],
					[67.075688699760192, 6.8476360918111183],
					[67.075677599851801, 6.8478693908588539],
					[67.075677599851801, 6.8478693908588539],
					[67.075663699789004, 6.8481638895579717],
					[67.07564699978127, 6.848419386952183],
					[67.07564699978127, 6.848419386952183],
					[67.075646999812278, 6.8484193892510383],
					[67.075633100883806, 6.848680585077715],
					[67.075605400938286, 6.8489638806139599],
					[67.075605400969309, 6.8489638829128126],
					[67.075605400969309, 6.8489638829128126],
					[67.075588701232263, 6.849158278280302],
					[67.075555403017816, 6.8494027670908446],
					[67.075530405840368, 6.8495221496830867],
					[67.075499810327031, 6.8496166373307892],
					[67.075499810358039, 6.8496166396296321],
					[67.075499808565311, 6.8496166397886364],
					[67.075499808565311, 6.8496166397886364],
					[67.075430418140144, 6.8498360113282049],
					[67.075327703004092, 6.8499886839149218],
					[67.075202826355195, 6.8499720029958535],
					[67.075202817360548, 6.8499720014918903],
					[67.075202796713242, 6.8499720010212828],
					[67.075202786822288, 6.8499719995968258],
					[67.075113887157514, 6.8499775998399208],
					[67.075113830903646, 6.8499776209392689],
					[67.075113741269902, 6.8499776956330019],
					[67.075113707859018, 6.8499777469285554],
					[67.07504700775425, 6.8502138470007452],
					[67.075047006888866, 6.8502138493790259],
					[67.075047005189091, 6.8502138564344106],
					[67.075047004323736, 6.8502138588126886],
					[67.074977604308685, 6.8505443601139433],
					[67.07493040404087, 6.8507721601058496],
					[67.074930404071864, 6.8507721624046409],
					[67.07493040323746, 6.8507721670816819],
					[67.07493040323746, 6.8507721670816819],
					[67.074897003106059, 6.850980567748568],
					[67.074897002240647, 6.8509805701268283],
					[67.07489700230262, 6.8509805747244066],
					[67.07489700143725, 6.850980577102642],
					[67.074858100630237, 6.8513221780796281],
					[67.074858100630237, 6.8513221780796281],
					[67.074858100661217, 6.8513221803784141],
					[67.074858100661217, 6.8513221803784141],
					[67.074833101225508, 6.8515777807287161],
					[67.074833101256473, 6.8515777830274995],
					[67.074799800697747, 6.8519416825344051],
					[67.074766501098694, 6.8523027822411917],
					[67.074766501098694, 6.8523027822411917],
					[67.074766500264232, 6.8523027869181501],
					[67.074766500264232, 6.8523027869181501],
					[67.074749800229625, 6.852583289074019],
					[67.074724799595387, 6.8529777859988039],
					[67.074724799626338, 6.8529777882975802],
					[67.074724799626338, 6.8529777882975802],
					[67.074716499562726, 6.853127788327348],
					[67.074702600678847, 6.8533332833319776],
					[67.074672001031686, 6.8536499766803756],
					[67.074638702024146, 6.85390557513335],
					[67.074588703393445, 6.8542777675402045],
					[67.074510903980837, 6.8546749613822131],
					[67.074510903115396, 6.8546749637602842],
					[67.074477603260235, 6.854852763929606],
					[67.074477603260235, 6.854852763929606],
					[67.074477603260235, 6.854852763929606],
					[67.074444203071081, 6.8550388647456328],
					[67.07444420310199, 6.855038867044386],
					[67.07444420310199, 6.855038867044386],
					[67.074444202205655, 6.8550388671236808],
					[67.074380402709096, 6.8554388660503403],
					[67.074302604404267, 6.855847163968293],
					[67.074302602611539, 6.8558471641268257],
					[67.074302602611539, 6.8558471641268257],
					[67.074302602642447, 6.8558471664255656],
					[67.074272002781427, 6.8560221630735088],
					[67.07423870416585, 6.8561999640198934],
					[67.074163704375394, 6.8565860605188922],
					[67.074091504100551, 6.8569332587737231],
					[67.074091503296899, 6.8569332657491344],
					[67.074091500762265, 6.8569332774804419],
					[67.074091500824053, 6.8569332820778852],
					[67.074072001332823, 6.8571555838179723],
					[67.074072001363717, 6.8571555861166917],
					[67.074071999632764, 6.8571555908725674],
					[67.074071999663659, 6.8571555931713091],
					[67.074066499624166, 6.8573110936385842],
					[67.074066499624166, 6.8573110936385842],
					[67.074066499655061, 6.8573110959373045],
					[67.074066499655061, 6.8573110959373045],
					[67.074058099796474, 6.8576916952396827],
					[67.074052599582686, 6.8579305954694991],
					[67.074052599613566, 6.8579305977682186],
					[67.074052599644446, 6.8579306000669389],
					[67.074052599675326, 6.8579306023656796],
					[67.074055399569801, 6.8582833010007436],
					[67.074055400332014, 6.8585167002984679],
					[67.074055400332014, 6.8585167002984679],
					[67.074055400362894, 6.8585167025971874],
					[67.074058100086233, 6.8588611018545835],
					[67.07405810023063, 6.8590999993371815],
					[67.074058100261482, 6.8591000016359036],
					[67.074058100261482, 6.8591000016359036],
					[67.074058100292348, 6.8591000039346248],
					[67.074063699891823, 6.8594389021912754],
					[67.074063700305686, 6.8596833001093831],
					[67.074063700305686, 6.8596833001093831],
					[67.074063700336538, 6.8596833024081061],
					[67.074066499728772, 6.8600194008079853],
					[67.074066499605379, 6.8602667008660312],
					[67.074066499605379, 6.8602667008660312],
					[67.074066499636217, 6.8602667031647568],
					[67.074066499636217, 6.8602667031647568],
					[67.074072000201113, 6.8605972008669402],
					[67.074069200245447, 6.8608499975358015],
					[67.074069200276284, 6.8608499998345485],
					[67.074069200307108, 6.8608500021332732],
					[67.074069200307108, 6.8608500021332732],
					[67.074074799899833, 6.8611778030880712],
					[67.074074799889829, 6.8614305989527606],
					[67.074074799920652, 6.861430601251489],
					[67.074074799951489, 6.8614306035502146],
					[67.074080400413891, 6.8617528028223598],
					[67.074083099903774, 6.8620056023001634],
					[67.074083099903774, 6.8620056023001634],
					[67.074083099934597, 6.8620056045989148],
					[67.074083099934597, 6.8620056045989148],
					[67.07409150010227, 6.8623278034550967],
					[67.074091500242872, 6.8625778007852007],
					[67.074091500242872, 6.8625778007852007],
					[67.074091500273681, 6.8625778030839522],
					[67.074091500304505, 6.8625778053826823],
					[67.074099800376572, 6.8629028003440755],
					[67.074096999646102, 6.8631527982769454],
					[67.074096999646102, 6.8631527982769454],
					[67.074096999676883, 6.8631528005756772],
					[67.074096999707692, 6.8631528028744073],
					[67.074099800188947, 6.8634832997815405],
					[67.074094199698109, 6.8637499950131806],
					[67.07409419972889, 6.8637499973119134],
					[67.074094199759685, 6.8637499996106452],
					[67.074094199759685, 6.8637499996106452],
					[67.074094200018436, 6.8640721955510644],
					[67.074083100282678, 6.8643610913454163],
					[67.074083100313459, 6.8643610936441704],
					[67.074083100313459, 6.8643610936441704],
					[67.074083100344254, 6.8643610959429022],
					[67.074074799881075, 6.8646832861916209],
					[67.074035901090213, 6.8650471775668978],
					[67.074010901675862, 6.865249969969053],
					[67.073972003170567, 6.8654638632806115],
					[67.0739720032321, 6.8654638678780575],
					[67.073972002397255, 6.8654638725544359],
					[67.07397200063528, 6.8654638750109802],
					[67.073930402423812, 6.8658055713052102],
					[67.073883103215763, 6.8660943680813586],
					[67.073883102319371, 6.8660943681602467],
					[67.073883102350138, 6.8660943704589839],
					[67.07388310238089, 6.8660943727576988],
					[67.07383590286527, 6.8664305665859704],
					[67.073763706381001, 6.8667999490925045],
					[67.073685910290521, 6.867036036492653],
					[67.073685907724382, 6.8670360459240012],
					[67.073685904354122, 6.867036062330337],
					[67.073685901787996, 6.8670360717616843],
					[67.073641501748767, 6.8673360713475509],
					[67.073641500944632, 6.8673360783224746],
					[67.073641500232725, 6.8673360921935025],
					[67.073641500324953, 6.8673360990896084],
				],
				[
					[67.056952601415162, 6.9038694196348382],
					[67.057060901312937, 6.9050111200606095],
					[67.0570609042135, 6.9050111359087589],
					[67.057060914587211, 6.905011174109652],
					[67.057060921999934, 6.9050111918680361],
					[67.05723592182953, 6.9053556912537415],
					[67.057235952820264, 6.9053557276754765],
					[67.057236029798929, 6.9053557808259969],
					[67.057236074890469, 6.9053557976321382],
					[67.057999974838268, 6.9054501971507749],
					[67.057999999101369, 6.9054501996562543],
					[67.058000045683769, 6.9054501933356125],
					[67.058000069856277, 6.9054501889492848],
					[67.058891770182115, 6.9051195877628393],
					[67.058891806662558, 6.9051195639137219],
					[67.058891866862623, 6.9051195012173761],
					[67.058891889685796, 6.9051194624475603],
					[67.059319589866718, 6.9038194638169328],
					[67.05931959330124, 6.9038194520201976],
					[67.059319596645096, 6.9038194333312877],
					[67.059319599213424, 6.9038194239093826],
					[67.059722397211232, 6.9004444392372015],
					[67.060150179849813, 6.8988861990211854],
					[67.06059174972458, 6.8984058406080599],
					[67.061049854922345, 6.898608453904906],
					[67.061508121559115, 6.8994972918601229],
					[67.061508188649071, 6.8994973435572486],
					[67.061508339848544, 6.89949737647144],
					[67.061508422165232, 6.8994973578437415],
					[67.061950122254117, 6.8991557583552954],
					[67.061950147019957, 6.8991557309079186],
					[67.061950184747189, 6.8991556655340283],
					[67.061950197738867, 6.8991556299051666],
					[67.062775194068621, 6.8935667579224953],
					[67.06304188084718, 6.8929917850486095],
					[67.06304188517727, 6.892991773170686],
					[67.063041892105403, 6.8929917541660224],
					[67.063041894673106, 6.8929917447415283],
					[67.063308494645113, 6.8918194432873703],
					[67.063308497212745, 6.8918194338626515],
					[67.06330849876241, 6.8918194153248198],
					[67.063308500433621, 6.8918194059779987],
					[67.063430799977823, 6.8883583144760259],
					[67.063583498047137, 6.886950034752032],
					[67.063880794582232, 6.8856778461020101],
					[67.063880796314052, 6.8856778413501658],
					[67.063880797088515, 6.8856778320808303],
					[67.063880797923957, 6.8856778274071111],
					[67.064147395404248, 6.8836944459995379],
					[67.064980788962046, 6.8813222668156948],
					[67.064980790663185, 6.8813222597654295],
					[67.064980794961855, 6.8813222455865883],
					[67.064980795736048, 6.8813222363166977],
					[67.065230796334802, 6.8800111361641534],
					[67.065230798066438, 6.8800111314116208],
					[67.065230798779496, 6.8800111175457568],
					[67.065230799584214, 6.8800111105736654],
					[67.065339100350641, 6.8778389109844271],
					[67.065339100197676, 6.8778388994947885],
					[67.065339099025991, 6.8778388788918718],
					[67.065339098007257, 6.8778388697785733],
					[67.064994598343617, 6.8755693695778213],
					[67.064994593677994, 6.8755693561829858],
					[67.064994586108867, 6.8755693269384199],
					[67.064994579650474, 6.8755693137005904],
					[67.064627979661822, 6.8748055130124071],
					[67.064627955927136, 6.8748054828841765],
					[67.064627897793116, 6.8748054304636472],
					[67.064627864320798, 6.8748054103906924],
					[67.064247264192616, 6.8746776098345581],
					[67.064247202281251, 6.8746776106576792],
					[67.06424708967468, 6.8746776458287986],
					[67.064247039875781, 6.874677680098273],
					[67.063872040137994, 6.8751804801274217],
					[67.06387203135796, 6.875180494699257],
					[67.06387201472495, 6.875180526062211],
					[67.063872008634064, 6.8751805403985067],
					[67.063355409374566, 6.8768638414140684],
					[67.063355407643016, 6.8768638461664358],
					[67.063355405911466, 6.8768638509188218],
					[67.063355405076322, 6.8768638555927373],
					[67.062827608117999, 6.8792082420184455],
					[67.062369212907413, 6.8803999271431584],
					[67.062369207773557, 6.8803999459942569],
					[67.062369201987707, 6.8803999833048639],
					[67.062369199543014, 6.8804000019209974],
					[67.062385900126003, 6.8815471849496239],
					[67.061980402572686, 6.8838832659491747],
					[67.061980401767826, 6.8838832729202224],
					[67.061980401054456, 6.8838832867841164],
					[67.061980400249581, 6.8838832937551446],
					[67.061897000674463, 6.8861916707529014],
					[67.061583107152174, 6.8873693472347322],
					[67.061583104554416, 6.8873693543616374],
					[67.061583102078401, 6.8873693706789192],
					[67.061583101273357, 6.8873693776497209],
					[67.061447003717561, 6.8885305562502452],
					[67.060647011199649, 6.890899935480256],
					[67.060647008601705, 6.8908999426065316],
					[67.060647006034145, 6.890899952030308],
					[67.06064700433258, 6.8908999590786451],
					[67.060080406695377, 6.8935805481057004],
					[67.059219212988026, 6.896188829525066],
					[67.058449816075296, 6.8979916200959313],
					[67.05844981174485, 6.8979916319707062],
					[67.058449805742796, 6.8979916531900107],
					[67.058449803205093, 6.8979916649094895],
					[67.058030404951026, 6.9003277546799033],
					[67.057074810924092, 6.9035693333764216],
					[67.056952615415113, 6.9038693241345257],
					[67.056952607649691, 6.9038693478030959],
					[67.056952601979191, 6.9038693942885176],
					[67.056952601415162, 6.9038694196348382],
				],
				[
					[67.049185900323025, 7.0022471967630242],
					[67.049185900438189, 7.0022472059493452],
					[67.049185899570546, 7.0022472083196856],
					[67.049238700059277, 7.0033944088270736],
					[67.049238703007603, 7.0033944292751773],
					[67.049238713357795, 7.0033944675061548],
					[67.04923871989196, 7.003394487659337],
					[67.049841520438108, 7.0046417870243811],
					[67.049841534172515, 7.00464180888559],
					[67.04984156785892, 7.0046418474990126],
					[67.049841586914511, 7.00464186432486],
					[67.051199887290679, 7.0055723646642951],
					[67.051199910770919, 7.0055723765300062],
					[67.051199963052667, 7.0055723952259754],
					[67.051199990032472, 7.0055723999067698],
					[67.052894215058828, 7.0056584908254314],
					[67.053060900891523, 7.0076778162554811],
					[67.053060902885463, 7.0076778321869844],
					[67.053060912280785, 7.0076778659054781],
					[67.053060916964043, 7.0076778816162433],
					[67.053519217276403, 7.00871118059084],
					[67.053519235578605, 7.008711208980948],
					[67.053519281061469, 7.0087112581347055],
					[67.05351930824213, 7.0087112788983754],
					[67.054838808094559, 7.009394578712878],
					[67.054838878190182, 7.009394586760811],
					[67.054839006096273, 7.0093945532757633],
					[67.05483906393539, 7.0093945140399052],
					[67.055725059081695, 7.0081252645687409],
					[67.056235902136464, 7.0084195180218929],
					[67.056258098070501, 7.0097138012540388],
					[67.055855478645427, 7.0100192421052787],
					[67.055855458256431, 7.0100192621736195],
					[67.055855423000622, 7.0100193133553974],
					[67.05585540897286, 7.0100193398008805],
					[67.055572008902899, 7.0109221410034355],
					[67.055572004621325, 7.0109221574511409],
					[67.055572001436886, 7.0109221899057559],
					[67.055571999844673, 7.0109222061330518],
					[67.055588698740493, 7.0113721878424053],
					[67.055335905221995, 7.0128499554350752],
					[67.054252606774284, 7.0167777460743705],
					[67.054252604342153, 7.0167777669678078],
					[67.054252601213705, 7.016777804014052],
					[67.054252600574543, 7.0167778247610109],
					[67.054413700925636, 7.0181361233039103],
					[67.054413711215176, 7.0181361569551584],
					[67.054413740673155, 7.0181362166343479],
					[67.054413759841623, 7.0181362426623304],
					[67.055374859825918, 7.0190723420131205],
					[67.055374887901706, 7.0190723627167992],
					[67.05537494657132, 7.0190723901215115],
					[67.055374978986649, 7.0190723989734103],
					[67.056755511015439, 7.0192195913801818],
					[67.057213734091803, 7.0196612171975419],
					[67.058388707291655, 7.0221889601947032],
					[67.05860810273478, 7.0234556345309844],
					[67.058608104613185, 7.0234556412774367],
					[67.058608108398445, 7.023455657067859],
					[67.05860811027685, 7.0234556638143335],
					[67.05907480981729, 7.0248472637260404],
					[67.059074820859522, 7.0248472858258921],
					[67.05907484557649, 7.0248473252115797],
					[67.05907486104411, 7.0248473423515083],
					[67.059983160993127, 7.0257307434401621],
					[67.059983213357896, 7.0257307690789883],
					[67.059983323992768, 7.0257307899768051],
					[67.059983382234421, 7.0257307829381919],
					[67.060424969930651, 7.0255308321553924],
					[67.060883109242027, 7.0259001032024644],
					[67.061058100998366, 7.0277222182745156],
					[67.061058101980137, 7.0277222250948457],
					[67.061058103972059, 7.0277222410332856],
					[67.061058105850265, 7.0277222477807495],
					[67.061613699338977, 7.0299778225444669],
					[67.061538699750031, 7.0331193948264472],
					[67.061538699835083, 7.0331194017198273],
					[67.061538699948485, 7.0331194109109951],
					[67.061538700930001, 7.033119417731684],
					[67.061705401238925, 7.0350444177051381],
					[67.061705402277042, 7.035044429121581],
					[67.06170540611781, 7.035044449511445],
					[67.061705408920531, 7.0350444584848839],
					[67.061897009136871, 7.0356667581180856],
					[67.061897015638678, 7.0356667759926514],
					[67.061897032171515, 7.0356668068558434],
					[67.061897042230868, 7.0356668221422742],
					[67.062355416264808, 7.0362611890528983],
					[67.063058106157612, 7.0390611486943229],
					[67.063058108035349, 7.0390611554432247],
					[67.063058111790852, 7.0390611689410472],
					[67.063058114565038, 7.0390611756175163],
					[67.064216515357003, 7.0419111746676819],
					[67.064216518131119, 7.041911181344771],
					[67.064216523679278, 7.0419111946989714],
					[67.06421652824632, 7.0419112012313709],
					[67.064674828212091, 7.0426862020946341],
					[67.064674844630702, 7.0426862237737087],
					[67.06467488365864, 7.0426862597313047],
					[67.064674906296133, 7.0426862763078857],
					[67.065583169821281, 7.0431723577688032],
					[67.065955430439999, 7.043627908718034],
					[67.067444213039082, 7.0466861710134134],
					[67.06779420811543, 7.0478806558424747],
					[67.067794210917427, 7.0478806648196484],
					[67.067794217389732, 7.047880680403428],
					[67.067794220163563, 7.0478806870822055],
					[67.068358119989057, 7.0490361877651502],
					[67.068358124583895, 7.049036196598454],
					[67.068358133745534, 7.0490362119665821],
					[67.06835814013337, 7.0490362206556707],
					[67.069252639805313, 7.0502362210490501],
					[67.069252653421074, 7.0502362337593283],
					[67.06925268065261, 7.0502362591798846],
					[67.069252696061326, 7.0502362717460532],
					[67.071060951021977, 7.0513362432826945],
					[67.072899825293604, 7.0546667980643454],
					[67.072899859808388, 7.0546668321139228],
					[67.072899942116948, 7.0546668853409784],
					[67.072899989854562, 7.0546668999206554],
					[67.073799868599721, 7.0547112943268644],
					[67.074677617666808, 7.0566306828878371],
					[67.074677624082256, 7.0566306938802335],
					[67.074677637809557, 7.0566307157931831],
					[67.074677645121454, 7.0566307267137338],
					[67.076363726697295, 7.058697303924423],
					[67.077002616324506, 7.0602028786422775],
					[67.077002642797794, 7.0602029156502342],
					[67.077002709892312, 7.0602029724235171],
					[67.077002751437973, 7.0602029944164082],
					[67.07745275056304, 7.0603168943967587],
					[67.077452825811349, 7.060316883773444],
					[67.07745294697753, 7.0603168119372262],
					[67.077452992923327, 7.0603167530236579],
					[67.078316793362987, 7.0570754272055662],
					[67.081108145740444, 7.0604529281280248],
					[67.0811081684041, 7.060452947031929],
					[67.081108219054315, 7.0604529798099867],
					[67.081108246144424, 7.0604529937558675],
					[67.083580507411753, 7.0611418827771537],
					[67.08465547406081, 7.0620084552890399],
					[67.084655489440664, 7.0620084655705098],
					[67.084655520144494, 7.0620084815333932],
					[67.084655536392887, 7.0620084894431621],
					[67.085555492035141, 7.0623112760517026],
					[67.086463759007842, 7.0632112426696212],
					[67.086463831145082, 7.0632112714408128],
					[67.086463973797095, 7.0632112692498428],
					[67.086464046076884, 7.0632112358441796],
					[67.089880745809467, 7.0595862359121844],
					[67.089880753765883, 7.0595862260639954],
					[67.089880769650676, 7.059586204067112],
					[67.089880776682648, 7.0595861919902063],
					[67.09095846007942, 7.0575279248732761],
					[67.091575105020425, 7.0569751743482962],
					[67.092666633896116, 7.0565752371348749],
					[67.093116525579433, 7.057136202487583],
					[67.093583116002279, 7.0582472778572152],
					[67.093583147014414, 7.0582473191305581],
					[67.093583226716248, 7.0582473795323555],
					[67.093583273612964, 7.0582473988045455],
					[67.094033274195439, 7.0583057977681083],
					[67.094033333306882, 7.0583057884239384],
					[67.09403343494526, 7.0583057342144615],
					[67.094033476603741, 7.0583056917219293],
					[67.094466876841594, 7.0574722919073807],
					[67.094466882976889, 7.0574722798994474],
					[67.094466890765105, 7.0574722562430114],
					[67.094466895107402, 7.0574722443788529],
					[67.09500019124107, 7.0551778636024016],
					[67.095330775714203, 7.0544194978623276],
					[67.098664066240389, 7.0495029123953206],
					[67.098664069713777, 7.0495029029011986],
					[67.098664079406205, 7.0495028882990463],
					[67.098664082907732, 7.0495028811062532],
					[67.098900182687416, 7.0489583811295349],
					[67.098900187029102, 7.0489583692616753],
					[67.098900193947713, 7.0489583479717579],
					[67.098900197392936, 7.0489583361761143],
					[67.09904459696709, 7.0481889367751762],
					[67.099044597582136, 7.0481889136892528],
					[67.099044596122823, 7.0481888677341402],
					[67.099044592255481, 7.0481888450094479],
					[67.098900200333489, 7.047677771124337],
					[67.098877999920248, 7.0455416989410438],
					[67.098877999835693, 7.0455416920369904],
					[67.098877998826495, 7.0455416829039592],
					[67.098877998741941, 7.045541675999905],
					[67.09880019910473, 7.0448638773335546],
					[67.098800197170846, 7.0448638659715916],
					[67.098800191566482, 7.0448638479950931],
					[67.098800189632598, 7.0448638366331293],
					[67.098594597967022, 7.0442471606190846],
					[67.09835019865146, 7.0409305846548955],
					[67.098350198594986, 7.0409305800523194],
					[67.098350197585503, 7.040930570919663],
					[67.098350196604315, 7.0409305640883106],
					[67.098130797560941, 7.0396777724526265],
					[67.097289099240683, 7.0314138806682225],
					[67.09728909921229, 7.031413878367049],
					[67.097289098259054, 7.0314138738375407],
					[67.097289098259054, 7.0314138738375407],
					[67.096794598089048, 7.0277471733582546],
					[67.096794598060612, 7.0277471710571113],
					[67.096794597107248, 7.0277471665278641],
					[67.096794597078826, 7.0277471642267413],
					[67.096314096985409, 7.025049965716792],
					[67.096314096031989, 7.0250499611877188],
					[67.096314095107076, 7.0250499589597375],
					[67.096314095050133, 7.0250499543575922],
					[67.095366895180277, 7.0209416542256475],
					[67.095366894255307, 7.0209416519979104],
					[67.095366893330308, 7.0209416497701751],
					[67.095366893273223, 7.0209416451682012],
					[67.093947393276295, 7.0158332453985333],
					[67.093947392379818, 7.015833245471959],
					[67.093947392351211, 7.0158332431711257],
					[67.093947391426127, 7.0158332409437163],
					[67.092552990678556, 7.0112860418754774],
					[67.092552989753457, 7.0112860396483674],
					[67.092552988799653, 7.0112860351205635],
					[67.092552987845863, 7.0112860305927587],
					[67.0911640882277, 7.0074999310908179],
					[67.091164086377375, 7.0074999266371449],
					[67.091164080855023, 7.007499915576668],
					[67.091164079004685, 7.0074999111229745],
					[67.089316878867706, 7.0038277091688412],
					[67.089316877046016, 7.0038277070161676],
					[67.089316872448677, 7.0038276981838923],
					[67.089316869701761, 7.0038276938046913],
					[67.086900169807265, 6.9999554950409255],
					[67.086900165238518, 6.9999554885104285],
					[67.086900156101052, 6.99995547544939],
					[67.086900150635898, 6.9999554689928782],
					[67.084297350869221, 6.9969720681669525],
					[67.084297347225686, 6.9969720638634971],
					[67.084297338145745, 6.9969720554047798],
					[67.084297333605761, 6.996972051175435],
					[67.081122342111186, 6.9941248593830796],
					[67.079583452783865, 6.992399870723716],
					[67.076697360648751, 6.9887998831450791],
					[67.07290577336974, 6.9831387971034626],
					[67.071525182271955, 6.980447117215931],
					[67.069214084854949, 6.9747638252144926],
					[67.069214083003686, 6.9747638207673379],
					[67.069214079301176, 6.9747638118730055],
					[67.069214076553507, 6.9747638075007039],
					[67.066605779991221, 6.969755513959309],
					[67.064141896248472, 6.9642860470834469],
					[67.063919598720858, 6.9619277819148753],
					[67.063919595796548, 6.9619277637574655],
					[67.063919587288012, 6.961927729966523],
					[67.063919580807394, 6.9619277144082572],
					[67.063400189891397, 6.960822132135311],
					[67.063186293930301, 6.9599471533199635],
					[67.063186283716746, 6.9599471265750168],
					[67.063186257073426, 6.9599470782084571],
					[67.063186238850818, 6.9599470567376045],
					[67.062591839129439, 6.9593692575447568],
					[67.062591837307167, 6.9593692553977711],
					[67.062591832766202, 6.9593692511791989],
					[67.06259183094393, 6.959369249032191],
					[67.061866831332821, 6.9587415492802007],
					[67.061866773579055, 6.9587415242366815],
					[67.061866656808306, 6.9587415156594226],
					[67.061866596865485, 6.9587415299033655],
					[67.061491597155182, 6.9589665292961449],
					[67.061491565236892, 6.9589665595830557],
					[67.061491516845507, 6.958966634958708],
					[67.061491501209986, 6.9589666753766632],
					[67.0604081015628, 6.9679749716617945],
					[67.059472004126633, 6.9738027609730251],
					[67.058922006234454, 6.976183249253018],
					[67.057577608051503, 6.9808582438583553],
					[67.057577607213247, 6.9808582485276416],
					[67.057577604611254, 6.9808582556434162],
					[67.057577603743908, 6.9808582580153429],
					[67.056572006032795, 6.9855416515082256],
					[67.055916509625334, 6.9878055372649781],
					[67.054527612960698, 6.9914610292914539],
					[67.054527611225708, 6.9914610340339731],
					[67.054527606917205, 6.9914610481873023],
					[67.054527605211149, 6.9914610552268668],
					[67.053985908040005, 6.9938055420202883],
					[67.053755432226666, 6.9944220764569742],
					[67.05331377599984, 6.9947692430268384],
					[67.05331375916964, 6.994769260512661],
					[67.053313729152919, 6.9947692997819582],
					[67.053313715966354, 6.9947693215654363],
					[67.052880415821363, 6.9957721216599325],
					[67.052880414953819, 6.9957721240308652],
					[67.052880412351129, 6.995772131143692],
					[67.052880410616012, 6.9957721358855594],
					[67.051147105089498, 7.0008940572184395],
					[67.050827928969539, 7.0006248465938219],
					[67.050827884726488, 7.0006248249475673],
					[67.050827791149828, 7.0006248050653763],
					[67.050827740948634, 7.0006248091999961],
					[67.05024714092724, 7.0008026081422834],
					[67.050247128434549, 7.0008026137691637],
					[67.050247102552746, 7.000802625096771],
					[67.050247090985337, 7.0008026329465221],
					[67.049880491062495, 7.0010415316376191],
					[67.049880455665445, 7.0010415713364553],
					[67.049880411041343, 7.0010416623733782],
					[67.049880400021365, 7.0010417138590961],
					[67.049896994667364, 7.0012777393023535],
					[67.049552952204976, 7.0017272722776447],
					[67.049491898840472, 7.0010749807232129],
					[67.049491863911001, 7.0010749146298448],
					[67.049491756890959, 7.0010748245854959],
					[67.049491684800344, 7.0010748006345187],
					[67.049238885035621, 7.0010942013373283],
					[67.049238820808071, 7.0010942319140188],
					[67.049238727746783, 7.0010943246375366],
					[67.04923869983827, 7.0010943890071582],
					[67.049202600079084, 7.0018360899347654],
					[67.049202600079084, 7.0018360899347654],
					[67.049202600107876, 7.0018360922313629],
					[67.049185900265442, 7.0022471921698743],
					[67.049185900323025, 7.0022471967630242],
				],
				[
					[67.041169200194545, 6.8799499912159385],
					[67.041169203250362, 6.8799500185289419],
					[67.041169215667196, 6.8799500749027986],
					[67.041169226759934, 6.879950099215888],
					[67.04434152675033, 6.8854195003291316],
					[67.044341531323667, 6.8854195068266781],
					[67.044341539573992, 6.8854195198998491],
					[67.044341545043778, 6.8854195263193523],
					[67.047027644625373, 6.8887001278683524],
					[67.047027654607348, 6.8887001361957596],
					[67.047027676394535, 6.8887001549908602],
					[67.047027687303341, 6.8887001655365259],
					[67.047455451678303, 6.8889918400712942],
					[67.047949830045354, 6.8897862068824933],
					[67.047949846423634, 6.8897862238504768],
					[67.047949881003447, 6.889786259926888],
					[67.047949900936885, 6.8897862742868998],
					[67.04836925214903, 6.8900251458036292],
					[67.048941524832927, 6.8910528975884997],
					[67.04894157294946, 6.8910529393893194],
					[67.048941684891957, 6.8910529894397543],
					[67.048941748657171, 6.8910529930965607],
					[67.050172146382423, 6.8907446200823115],
					[67.05062766697533, 6.8911501480289079],
					[67.050627741801122, 6.8911501737172633],
					[67.050627886119727, 6.8911501611797652],
					[67.050627955642938, 6.8911501252504834],
					[67.051055672375924, 6.8906196291476975],
					[67.051522143306329, 6.8907584922803142],
					[67.05152220073596, 6.8907584918886178],
					[67.051522307102189, 6.8907584596532505],
					[67.051522355142367, 6.8907584278874943],
					[67.05219182767776, 6.8899418579653018],
					[67.054108391418453, 6.8889584786907543],
					[67.054108421591351, 6.8889584530710666],
					[67.054108471089179, 6.8889583958766645],
					[67.054108490383712, 6.8889583620050487],
					[67.054419590300299, 6.8879917606488252],
					[67.05441959286766, 6.8879917512271724],
					[67.054419597972029, 6.8879917300869788],
					[67.054419598716194, 6.8879917185244279],
					[67.054527998972404, 6.8868333195120588],
					[67.054527998667822, 6.8868332965428767],
					[67.054527991783957, 6.886833251150839],
					[67.054527986997442, 6.8868332285719127],
					[67.054344600934698, 6.886355564371077],
					[67.054414099672854, 6.8833944149705086],
					[67.05503079633111, 6.8787417434474731],
					[67.055411284375367, 6.8775556819386763],
					[67.055825150127447, 6.8768445401320264],
					[67.05673877068628, 6.876228129160026],
					[67.05701369619598, 6.8774167040737524],
					[67.056747003675227, 6.878788861550408],
					[67.056747002870608, 6.8787888685201164],
					[67.056747001230718, 6.8787888801624009],
					[67.056747000426043, 6.8787888871320915],
					[67.056549803178129, 6.8820582488310587],
					[67.05590812040478, 6.8833666110590617],
					[67.055908115209519, 6.8833666253104004],
					[67.055908105715389, 6.8833666537348952],
					[67.055908103209305, 6.8833666677516927],
					[67.055497012267963, 6.8858277123357743],
					[67.054649847886964, 6.8868220709950689],
					[67.05464982605605, 6.8868221165879273],
					[67.054649806718402, 6.8868222148540461],
					[67.054649810974027, 6.8868222650743016],
					[67.05503041073392, 6.8879389643073834],
					[67.055030428191486, 6.8879389949827594],
					[67.055030472905898, 6.887939050881525],
					[67.055030500132375, 6.8879390738079245],
					[67.055777622778052, 6.8883723288798224],
					[67.055938693105958, 6.8891221897903838],
					[67.055122019755174, 6.891583207427245],
					[67.054680437227461, 6.892202684227434],
					[67.054680426683447, 6.8922027012402003],
					[67.054680411004199, 6.8922027370955901],
					[67.054680404972501, 6.8922027560160686],
					[67.054060906992433, 6.8949221491461898],
					[67.053313708569505, 6.8972860400250973],
					[67.053313706062156, 6.8972860540389975],
					[67.053313701017188, 6.8972860797699385],
					[67.053313700302624, 6.8972860936285043],
					[67.053274799824806, 6.8984388938014884],
					[67.053274802877482, 6.8984389211304418],
					[67.053274817977041, 6.8984389773090227],
					[67.053274829097219, 6.8984390039394761],
					[67.053749828748394, 6.8992251033072547],
					[67.053749895777898, 6.8992251503937005],
					[67.05375004330115, 6.8992251767161932],
					[67.053750122032341, 6.8992251584042776],
					[67.053872321573976, 6.8991307579995738],
					[67.053872331343456, 6.8991307502554697],
					[67.053872348193224, 6.8991307350000124],
					[67.053872355243271, 6.8991307251917862],
					[67.054519555061717, 6.8983307250799832],
					[67.054519562141977, 6.8983307175681929],
					[67.054519572656332, 6.8983306982612644],
					[67.0545195788402, 6.8983306908270983],
					[67.055191863976276, 6.8969890198234998],
					[67.056633313239416, 6.8955669688489705],
					[67.056969238701768, 6.89583902792657],
					[67.057891520099417, 6.8977472872096621],
					[67.057891578195736, 6.8977473373737093],
					[67.057891711619249, 6.8977473833118363],
					[67.057891788739241, 6.8977473789306218],
					[67.058341788840096, 6.8975223783232575],
					[67.058341822569631, 6.8975223501016885],
					[67.058341875565205, 6.8975222857120313],
					[67.058341894770635, 6.8975222449493634],
					[67.058502995373587, 6.8968361463603562],
					[67.058502996148732, 6.8968361370934232],
					[67.058502997759618, 6.8968361231541886],
					[67.058502998565046, 6.8968361161845815],
					[67.05873909903481, 6.8938361208744654],
					[67.05932519786505, 6.8898639287533445],
					[67.05932519786505, 6.8898639287533445],
					[67.05932519870062, 6.8898639240806245],
					[67.05932519870062, 6.8898639240806245],
					[67.059958496995961, 6.8843056371638705],
					[67.060386292868714, 6.8827139514211693],
					[67.060386293704042, 6.8827139467480256],
					[67.060386296271133, 6.8827139373234916],
					[67.060386297106461, 6.882713932650347],
					[67.060761295815269, 6.8804278394238558],
					[67.061652995022953, 6.8766639451357205],
					[67.061652995827515, 6.8766639381645298],
					[67.061652998333031, 6.8766639241437204],
					[67.061652999137564, 6.8766639171725537],
					[67.061827998557419, 6.8746944181195051],
					[67.061827999392506, 6.8746944134457895],
					[67.061828000197011, 6.8746944064745037],
					[67.061828000166358, 6.8746944041769176],
					[67.061889100149145, 6.8716139041819355],
					[67.061889027954834, 6.8716138070026132],
					[67.061888842350299, 6.871613751976426],
					[67.061888728104989, 6.8716137988034021],
					[67.061158127895567, 6.8728387987812445],
					[67.061158124402041, 6.8728388059881604],
					[67.061158117384352, 6.8728388181044924],
					[67.061158113921465, 6.8728388276089527],
					[67.059447014005428, 6.8772027274330467],
					[67.059447008036756, 6.8772027509556883],
					[67.059447000581287, 6.8772027976088443],
					[67.059447000887275, 6.8772028205825384],
					[67.059563696831319, 6.8783443782694356],
					[67.058813709100249, 6.8807110400018221],
					[67.058813704862715, 6.8807110587717952],
					[67.058813700869564, 6.8807110959202697],
					[67.058813700217556, 6.8807111143770632],
					[67.058844199208181, 6.8811499902317772],
					[67.058730404880919, 6.8818666534905653],
					[67.057960909720592, 6.8842332371647164],
					[67.057960907183968, 6.8842332488853764],
					[67.05796090211058, 6.8842332723267052],
					[67.057960901366698, 6.8842332838910556],
					[67.057869202600529, 6.8853916534190569],
					[67.056441869107488, 6.8889323471686454],
					[67.056366900636945, 6.8866722142775814],
					[67.056975194600838, 6.8831694433732],
					[67.058097383748702, 6.8791278876186199],
					[67.058975164093297, 6.8778751157410847],
					[67.058975170245589, 6.8778751060030396],
					[67.058975179861051, 6.8778750867620877],
					[67.058975185117006, 6.8778750771024342],
					[67.059739084558117, 6.8760278760959217],
					[67.059739088051785, 6.8760278688898877],
					[67.05973909321574, 6.8760278523373373],
					[67.059739094916637, 6.8760278452882115],
					[67.06055579491445, 6.8725083449824496],
					[67.060555796584552, 6.8725083356353993],
					[67.060555798997683, 6.8725083147223929],
					[67.060555799740754, 6.8725083031564553],
					[67.060600200056911, 6.8702028155948582],
					[67.060919597860632, 6.8678750260414603],
					[67.06091959869552, 6.8678750213676878],
					[67.0609196003346, 6.8678750097226722],
					[67.0609196002424, 6.8678750028302007],
					[67.060936299695328, 6.8670833048707056],
					[67.060936297595148, 6.8670832820533736],
					[67.060936288912899, 6.8670832368126717],
					[67.060936280568839, 6.8670832168444145],
					[67.060691880963773, 6.8665582171215656],
					[67.060691794020144, 6.8665581580615251],
					[67.060691615365059, 6.8665581530692323],
					[67.060691525415635, 6.8665582046818745],
					[67.06018872541587, 6.8674777032565411],
					[67.060188720160596, 6.8674777129189186],
					[67.060188712339126, 6.8674777320073765],
					[67.060188708876595, 6.8674777415122117],
					[67.059752628613566, 6.8689109749679469],
					[67.059310986109338, 6.8692192364087061],
					[67.059310960421584, 6.869219261664897],
					[67.059310919925437, 6.8692193204220553],
					[67.059310905117059, 6.8692193539230431],
					[67.058883118981583, 6.8710081989692506],
					[67.058502779887618, 6.8713886381336682],
					[67.058158519038116, 6.871136012300167],
					[67.058419594433985, 6.8697083499008871],
					[67.058852989361654, 6.8684444640339635],
					[67.058852992793518, 6.8684444522325165],
					[67.05885299786452, 6.868444428787063],
					[67.058852998576569, 6.8684444149245225],
					[67.059172398387759, 6.8643889286916817],
					[67.059380796425003, 6.8634111422986663],
					[67.059380798002167, 6.8634111260594191],
					[67.059380799363751, 6.8634110937387591],
					[67.059380799148173, 6.8634110776573687],
					[67.059241899444459, 6.8622082770014048],
					[67.059241895674091, 6.862208263533339],
					[67.059241888998898, 6.862208234220911],
					[67.059241884332195, 6.8622082208318167],
					[67.059005795197663, 6.8616555478397396],
					[67.058891898182608, 6.8607916732845098],
					[67.058891897193732, 6.8607916664716493],
					[67.058891894288791, 6.8607916506276849],
					[67.058891892403537, 6.8607916438938492],
					[67.058364092277216, 6.8590277435668732],
					[67.058364055681807, 6.8590276915966344],
					[67.0583639595866, 6.8590276195765876],
					[67.058363900086789, 6.8590275995267627],
					[67.058350121835502, 6.8590276008223547],
					[67.05689187786156, 6.8562027085539343],
					[67.056891873287015, 6.8562027020585719],
					[67.056891864107044, 6.8562026867707715],
					[67.056891858605269, 6.8562026780575005],
					[67.055977981789141, 6.8549971085955139],
					[67.055711295260565, 6.8540499540500415],
					[67.055502999682901, 6.8529499793288293],
					[67.055480799803121, 6.8511306137929484],
					[67.056008497955517, 6.8476083298232364],
					[67.056008497924509, 6.8476083275262205],
					[67.0560084987588, 6.8476083228527296],
					[67.056008498727778, 6.8476083205557332],
					[67.056252999035195, 6.8452861214488605],
					[67.056252998973065, 6.8452861168548313],
					[67.056252999714161, 6.8452861052901453],
					[67.05625299965206, 6.8452861006961152],
					[67.056258500129857, 6.8442028011113116],
					[67.056258499015996, 6.8442027851118175],
					[67.056258495057705, 6.8442027578660936],
					[67.056258492182153, 6.8442027443228719],
					[67.055816892298665, 6.8427027447313398],
					[67.055816878449065, 6.8427027160658787],
					[67.055816842744719, 6.8427026640460715],
					[67.055816820890058, 6.8427026406917006],
					[67.055358420945424, 6.8423526407775448],
					[67.055358365925358, 6.8423526203725338],
					[67.055358253662703, 6.8423526142555353],
					[67.055358197316437, 6.8423526284638374],
					[67.054916861892949, 6.8426163711019319],
					[67.054839099262338, 6.8419638763618416],
					[67.054839097345194, 6.841963867333746],
					[67.054839092583435, 6.841963847060339],
					[67.05483908976997, 6.8419638381119237],
					[67.054444593221575, 6.8407388475435313],
					[67.053964096446549, 6.8384916585715523],
					[67.053964091777829, 6.8384916451899462],
					[67.05396408244043, 6.838491618426735],
					[67.053964076906482, 6.8384916074217497],
					[67.053497377201083, 6.8376082058519607],
					[67.053497267035169, 6.8376081558794573],
					[67.053497079142986, 6.8376081979142675],
					[67.053496999624016, 6.8376082900812989],
					[67.053138702821641, 6.8439777380979754],
					[67.052697293149947, 6.8447133561875031],
					[67.05221689596101, 6.8423666603885476],
					[67.052216893085344, 6.8423666468477684],
					[67.052216883810758, 6.8423666246782515],
					[67.052216880038728, 6.8423666112171748],
					[67.05168078008461, 6.8412749111232172],
					[67.051680689608588, 6.8412748570873019],
					[67.051680510984099, 6.8412748545784714],
					[67.051680421105189, 6.8412749108581403],
					[67.051316521027971, 6.8419999094741257],
					[67.051316519297458, 6.8419999142265899],
					[67.051316514971163, 6.8419999261077766],
					[67.051316513240664, 6.841999930860263],
					[67.048285912849366, 6.850127729798773],
					[67.048285911952973, 6.8501277298781522],
					[67.048285911983967, 6.8501277321744203],
					[67.048285911118597, 6.8501277345500657],
					[67.047705410912997, 6.8517916329416781],
					[67.047705410078578, 6.8517916376134229],
					[67.047705408378732, 6.8517916446607234],
					[67.047705406616956, 6.8517916471155678],
					[67.046738709215703, 6.8553277403744826],
					[67.045455412066758, 6.858888832394924],
					[67.045455408604724, 6.8588888418951566],
					[67.045455405235259, 6.8588888582834739],
					[67.04545540266956, 6.858888867704664],
					[67.044480404889995, 6.8647193550883321],
					[67.043449808193785, 6.8682610437971725],
					[67.043449807358868, 6.8682610484675388],
					[67.043449804761892, 6.868261055591149],
					[67.04344980392699, 6.8682610602615153],
					[67.04321370581944, 6.869430548369607],
					[67.042433110419267, 6.8717971369453439],
					[67.042433106987133, 6.8717971487383283],
					[67.042433101915606, 6.8717971721671844],
					[67.042433101172563, 6.8717971837245049],
					[67.042335901627936, 6.8729527634413943],
					[67.041999807960082, 6.8741332452358828],
					[67.041999806228588, 6.8741332499842613],
					[67.041999803692676, 6.8741332616982378],
					[67.041999802857561, 6.874133266368128],
					[67.041222002721071, 6.8787971675955166],
					[67.041222001885842, 6.8787971722651182],
					[67.041222001172855, 6.878797186117299],
					[67.04122200033764, 6.8787971907868775],
					[67.041169200194545, 6.8799499912159385],
				],
				[
					[67.006324801313099, 6.9885556027310525],
					[67.006324800619524, 6.9885556188527671],
					[67.006455400611173, 6.9900306178409144],
					[67.006455405354075, 6.9900306381033603],
					[67.006455416632804, 6.9900306784801041],
					[67.006455424932497, 6.9900306961537533],
					[67.008094217121197, 6.9930000837929072],
					[67.009710905868616, 6.9974583530897512],
					[67.010505403385181, 7.0019833359104098],
					[67.010505406218513, 7.0019833471541322],
					[67.010505414632092, 7.0019833740065112],
					[67.010505420183549, 7.0019833873222437],
					[67.010780419746425, 7.0025528862637225],
					[67.010780429837681, 7.0025529037975662],
					[67.010780451755565, 7.0025529341320745],
					[67.010780463582179, 7.0025529469327816],
					[67.011238730793153, 7.0029751169763914],
					[67.011719205223173, 7.0041778501055623],
					[67.012772002034694, 7.0123028259558797],
					[67.012772002091992, 7.0123028305422039],
					[67.012772003970838, 7.0123028372751257],
					[67.012772004028122, 7.012302841861449],
					[67.013458100249125, 7.0155139191486375],
					[67.013410900423409, 7.0178138965201065],
					[67.013410899584059, 7.0178139011796112],
					[67.013410900651905, 7.0178139148658802],
					[67.013410900709033, 7.0178139194523412],
					[67.013702600855311, 7.0206861205796987],
					[67.013702601837323, 7.0206861273864911],
					[67.013702603801406, 7.0206861410000938],
					[67.01370260567991, 7.0206861477339455],
					[67.014388703099058, 7.0234722368724158],
					[67.014877601707298, 7.0268750280097061],
					[67.01487760268904, 7.0268750348171016],
					[67.014877604624147, 7.0268750461384988],
					[67.014877605577482, 7.0268750506525022],
					[67.01545810399189, 7.0291250408783323],
					[67.016188703102927, 7.0336583313258867],
					[67.0161887059907, 7.0336583471629517],
					[67.016188715295414, 7.0336583739601188],
					[67.016188720872591, 7.0336583895797355],
					[67.017027620870721, 7.0353417883876848],
					[67.017027628212347, 7.0353418015697571],
					[67.017027642839039, 7.0353418233467275],
					[67.017027651048849, 7.0353418341627814],
					[67.017477650596305, 7.0358418334752288],
					[67.017477683180672, 7.0358418560982239],
					[67.017477753586746, 7.0358418894415644],
					[67.017477792304931, 7.0358419000894932],
					[67.017913813992223, 7.0358584959653827],
					[67.018227621614315, 7.0361751041522904],
					[67.018860907810549, 7.0383472551544104],
					[67.018860909688343, 7.038347261891035],
					[67.0188609152368, 7.0383472752196576],
					[67.018860917114537, 7.0383472819563071],
					[67.020202613107443, 7.0413694729030993],
					[67.022080409877944, 7.0469583639685336],
					[67.022080411727174, 7.0469583684126427],
					[67.022080415397511, 7.0469583750068088],
					[67.022080417246755, 7.0469583794509187],
					[67.02304701687504, 7.0491778809249377],
					[67.023047018724228, 7.049177885369394],
					[67.023047025140144, 7.0491778963366665],
					[67.023047027913947, 7.0491779030033683],
					[67.023941527751489, 7.0506723020627557],
					[67.023941545065554, 7.0506723236394135],
					[67.023941583195267, 7.0506723596224425],
					[67.023941604935516, 7.0506723762511347],
					[67.024758204793329, 7.0511140767841578],
					[67.024758235414083, 7.0511140858120092],
					[67.024758295674857, 7.0511140970565656],
					[67.024758328004339, 7.0511140990576555],
					[67.025291632845253, 7.051039110088845],
					[67.025749836574519, 7.0514668230817881],
					[67.027305409418886, 7.0545778660682084],
					[67.028183103895728, 7.0588500410185855],
					[67.028183104848154, 7.0588500455363405],
					[67.028183106725066, 7.0588500522771351],
					[67.028183107677478, 7.0588500567948911],
					[67.028769204205588, 7.060808342879505],
					[67.029197001737487, 7.0636139250908059],
					[67.029524800621033, 7.0667222217932091],
					[67.029524852696014, 7.0667222980463729],
					[67.029524998117196, 7.0667223760616231],
					[67.02952509235989, 7.0667223777523898],
					[67.030419435965754, 7.0662613081232966],
					[67.031083150142408, 7.066350182805917],
					[67.032249809953157, 7.0698333648185514],
					[67.03224984281097, 7.0698334104513663],
					[67.032249927971108, 7.0698334771995803],
					[67.032249981169969, 7.0698334982437681],
					[67.033149876626496, 7.0699195899065428],
					[67.034797026102325, 7.0728389990806697],
					[67.034797026998802, 7.0728389990095595],
					[67.034797028847379, 7.0728390034579585],
					[67.034797029771624, 7.072839005682181],
					[67.036438729678025, 7.0754751048970155],
					[67.036438739705858, 7.0754751178886419],
					[67.036438760658001, 7.0754751438008761],
					[67.03643877334757, 7.0754751542839722],
					[67.036810944199132, 7.0757807290103818],
					[67.037827624804166, 7.0776111970222493],
					[67.037827631190453, 7.0776112057080676],
					[67.037827643066507, 7.0776112231506314],
					[67.0378276503493, 7.077611231765502],
					[67.039672030351042, 7.0797001100994539],
					[67.040466518085012, 7.0814694825700348],
					[67.040466531808946, 7.0814695044664795],
					[67.040466567297642, 7.0814695453261747],
					[67.040466587269378, 7.0814695644310719],
					[67.041374838831914, 7.0820945313895622],
					[67.042297016276223, 7.0842389786347297],
					[67.042297031848008, 7.0842390049850383],
					[67.042297069156575, 7.0842390480064363],
					[67.042297090024491, 7.0842390670443161],
					[67.0432054602759, 7.0848334476148258],
					[67.044113737874298, 7.0860862158844142],
					[67.044113747004801, 7.0860862289551472],
					[67.044113765155387, 7.0860862459117557],
					[67.044113775154784, 7.0860862566156246],
					[67.045022051271104, 7.0868140360172092],
					[67.046066533802644, 7.0883779099127233],
					[67.046066558366277, 7.0883779378603595],
					[67.046066615369114, 7.0883779770456563],
					[67.046066646070486, 7.0883779930172448],
					[67.047736002193048, 7.0888418806844991],
					[67.049369268245087, 7.090277950627927],
					[67.049369280933689, 7.090277961124257],
					[67.049369308945359, 7.0902779773119393],
					[67.049369324295924, 7.0902779853000037],
					[67.051352724376713, 7.0910918848353193],
					[67.051352784552151, 7.0910918892997916],
					[67.051352899083838, 7.0910918619011989],
					[67.051352950750584, 7.0910918302496393],
					[67.051802951307423, 7.0905723319674632],
					[67.05180296276923, 7.090572314972138],
					[67.051802982975886, 7.0905722788960679],
					[67.0518029908242, 7.0905722598858603],
					[67.052597380207018, 7.0880167959748199],
					[67.053208354912115, 7.0872502528535888],
					[67.054908172776322, 7.0881084589881542],
					[67.055916546415631, 7.0893279276232102],
					[67.055916564565919, 7.0893279445903827],
					[67.055916606190351, 7.0893279735065775],
					[67.055916627899052, 7.0893279878941211],
					[67.056472127590624, 7.0895418864498838],
					[67.056472212757825, 7.0895418797457719],
					[67.056472348254331, 7.0895418046923462],
					[67.05647239676307, 7.0895417341867688],
					[67.056564087907404, 7.0890250848850007],
					[67.056708339271907, 7.0888391748752966],
					[67.057166581776528, 7.089172362138334],
					[67.057166698431303, 7.0891723621520173],
					[67.057166853320012, 7.0891722579719971],
					[67.05716689329185, 7.0891721490422483],
					[67.056816904612276, 7.0878555930777249],
					[67.05693079583807, 7.0873000392705254],
					[67.056930798306979, 7.0873000206791534],
					[67.05693079786586, 7.0872999839203077],
					[67.056930796721247, 7.0872999633141021],
					[67.056197396605128, 7.0832666647775788],
					[67.056197395653399, 7.0832666602536589],
					[67.056197393749898, 7.0832666512058626],
					[67.056197392825766, 7.0832666489792944],
					[67.053505794998998, 7.0731721542646575],
					[67.051269595740195, 7.0618527617214131],
					[67.051269594787883, 7.0618527571992766],
					[67.051269592883159, 7.0618527481549878],
					[67.05126959193079, 7.0618527436328549],
					[67.050522392072494, 7.059272144184269],
					[67.050522390195624, 7.0592721374372616],
					[67.050522385573331, 7.0592721263116749],
					[67.050522382799969, 7.0592721196363142],
					[67.049697383049107, 7.0573805196866948],
					[67.04969738027566, 7.0573805130117986],
					[67.049697373860283, 7.0573805020304166],
					[67.049697370190373, 7.057380495427231],
					[67.047311269936557, 7.053491594484707],
					[67.047311268087498, 7.0534915900354855],
					[67.047311262624447, 7.0534915835772933],
					[67.04731125987891, 7.0534915791999424],
					[67.045944560094355, 7.0516748802630884],
					[67.045944551913706, 7.0516748717251216],
					[67.045944536420862, 7.0516748522808763],
					[67.045944527371816, 7.0516748461111582],
					[67.039597336843443, 7.0464776535776323],
					[67.038230758198708, 7.0450276751994432],
					[67.036400170879915, 7.0422081959035738],
					[67.033997378071504, 7.0379610074065209],
					[67.030425183319252, 7.0302805183523471],
					[67.026725185729745, 7.0213916254660811],
					[67.023941888629693, 7.0139638342863773],
					[67.021605790037, 7.0068332379290226],
					[67.021605789111831, 7.0068332357085437],
					[67.021605788157913, 7.0068332311940908],
					[67.021605787232758, 7.0068332289735897],
					[67.020675187371381, 7.0043777286677713],
					[67.020675184624523, 7.0043777243007588],
					[67.020675180894884, 7.004377713125657],
					[67.020675178176802, 7.0043777110525269],
					[67.019294577882434, 7.0016499105902401],
					[67.019294576003148, 7.0016499038563289],
					[67.019294568687542, 7.0016498929770048],
					[67.019294565940569, 7.0016498886105483],
					[67.016136297054786, 6.9969305350093647],
					[67.016083500221626, 6.9957249917026054],
					[67.016083499238505, 6.9957249848961522],
					[67.016083498226564, 6.9957249757962785],
					[67.01608349813992, 6.9957249689159369],
					[67.015594598075438, 6.9925999697106613],
					[67.015594563200409, 6.9925999083077199],
					[67.015594461612054, 6.9925998225668238],
					[67.015594329747145, 6.9925998265649412],
					[67.015594233046542, 6.9925999148999374],
					[67.015594200658896, 6.9925999795596452],
					[67.015494202591526, 6.9935388598328485],
					[67.014738833452228, 6.9959078538697606],
					[67.014283463906608, 6.9952526848983085],
					[67.014283433051716, 6.9952526575983258],
					[67.014283363418315, 6.9952526151298651],
					[67.014283322846907, 6.9952526001091471],
					[67.013991796772601, 6.9952192091327463],
					[67.01267235611563, 6.9937720749690184],
					[67.011550169347458, 6.9921692921750189],
					[67.009711273930705, 6.9889027014548368],
					[67.00971126936156, 6.9889026949469297],
					[67.009711258372334, 6.9889026774936545],
					[67.009711252010263, 6.9889026711339843],
					[67.007508451752884, 6.9863387710069427],
					[67.007508374136137, 6.9863387361212563],
					[67.007508218905699, 6.9863387374953794],
					[67.007508143113895, 6.9863387758994211],
					[67.006552643036684, 6.9875554767488657],
					[67.006552630718375, 6.9875554961273085],
					[67.006552612356927, 6.9875555343651268],
					[67.006552604549867, 6.9875555556653435],
					[67.006324805215669, 6.9885555565101694],
					[67.006324802700249, 6.9885555704876055],
					[67.006324801313099, 6.9885556027310525],
				],
			],
		},
		{
			fldNpdidField: "18116481",
			wlbNpdidWellbore: "309",
			fldName: "GUDRUN",
			fldCurrentActivitySatus: "Producing",
			wlbName: "15/3-1 S",
			fldDiscoveryYear: "1975",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=18116481",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=18116481",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "025",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=309",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "34512",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Dusavik",
			fldAreaGeometryWKT: [
				[
					[58.811260901215043, 1.7168917152941603],
					[58.811385900631016, 1.7185361155587666],
					[58.811385917805083, 1.7185361640676564],
					[58.81138597482736, 1.718536247620257],
					[58.81138601457819, 1.7185362809469025],
					[58.812858189781672, 1.719230769865689],
					[58.81490266983036, 1.7209890520297824],
					[58.814902735219526, 1.7209890764859976],
					[58.814902865944724, 1.7209890767525631],
					[58.814902931183369, 1.7209890508456747],
					[58.816325131106488, 1.7197557504998329],
					[58.81632514140675, 1.7197557413782787],
					[58.816325159140135, 1.7197557202635956],
					[58.816325166670623, 1.7197557099877989],
					[58.817274976754156, 1.7183142978559716],
					[58.818099798729676, 1.7199472456975071],
					[58.818055400407303, 1.7235221973727901],
					[58.818005400326832, 1.7262388957711765],
					[58.818005399630565, 1.726238899393566],
					[58.818005400116952, 1.7262389079806502],
					[58.818005400311485, 1.7262389114155012],
					[58.818172000352725, 1.7291694109947477],
					[58.818172000936165, 1.7291694212994013],
					[58.818172003884669, 1.729169441533742],
					[58.818172006152516, 1.7291694497460091],
					[58.818969164424409, 1.7322331855381365],
					[58.817136019339465, 1.7330415176715286],
					[58.81713598130893, 1.7330415465241322],
					[58.817135921655449, 1.7330416233704935],
					[58.817135903401493, 1.7330416671800086],
					[58.81689152308811, 1.7345470432864663],
					[58.81641386030978, 1.7346857104437321],
					[58.816375168850414, 1.7346248933438386],
					[58.816375056378753, 1.7346248492184244],
					[58.816374871958793, 1.7346249036356922],
					[58.816374799913348, 1.7346250004609876],
					[58.816374800234279, 1.7346971990956062],
					[58.81637483993223, 1.7346972793764719],
					[58.816374967984814, 1.7346973758356792],
					[58.816375056339389, 1.7346973920140301],
					[58.816413683967298, 1.7346861784973617],
					[58.817458103004952, 1.7363278638679087],
					[58.817633099644283, 1.7399722109343028],
					[58.817633099644283, 1.7399722109343028],
					[58.817633099741379, 1.7399722126517514],
					[58.817633100826406, 1.7399722158994761],
					[58.817924800981984, 1.7439722150705765],
					[58.817924806001017, 1.7439722400845021],
					[58.81792482316591, 1.7439722886156794],
					[58.817924834420943, 1.7439723123200228],
					[58.820277633692513, 1.7474473113057774],
					[58.820277679963702, 1.7474473485177551],
					[58.820277784220153, 1.7474473909371009],
					[58.820277842205378, 1.7474473961444719],
					[58.822405557267935, 1.7469891140880629],
					[58.824924886197117, 1.7487473638905595],
					[58.824924932195152, 1.7487473803076476],
					[58.824925026590826, 1.7487473917795808],
					[58.824925075782389, 1.7487473849295789],
					[58.826905655260994, 1.7479334927458601],
					[58.829188866902236, 1.7475474082421294],
					[58.831702653467516, 1.7488751497137316],
					[58.833088723960067, 1.7514333955529755],
					[58.833088725935731, 1.7514333986155031],
					[58.833088730874849, 1.7514334062717964],
					[58.833088733838323, 1.75143341086557],
					[58.83413039125098, 1.7529917471913685],
					[58.83346650201382, 1.7575805713694261],
					[58.833466500716447, 1.7575805803346496],
					[58.833466500000291, 1.7575805996097031],
					[58.833466499690658, 1.757580610106378],
					[58.833599799893506, 1.7603667104705556],
					[58.833599801171751, 1.7603667171570565],
					[58.833599803030999, 1.7603667341535019],
					[58.833599805296963, 1.7603667423715572],
					[58.834402602688314, 1.7640056345733544],
					[58.834910896285898, 1.767791682821366],
					[58.833477609222371, 1.7723860398651585],
					[58.833477607730565, 1.7723860453931741],
					[58.833477604747003, 1.7723860564492195],
					[58.833477604146132, 1.7723860617907798],
					[58.832805403927154, 1.7758277625059309],
					[58.832805403132866, 1.7758277644106648],
					[58.832805401737552, 1.7758277716567668],
					[58.832805401930834, 1.7758277750934564],
					[58.83252200160365, 1.7780471756255309],
					[58.832522005800556, 1.7780472182112832],
					[58.832522031025242, 1.7780472981244531],
					[58.832522052943958, 1.7780473352655426],
					[58.833305408657189, 1.7788972872476199],
					[58.833622000779783, 1.7816111201570206],
					[58.833824800929939, 1.7839694168231406],
					[58.833824801316027, 1.7839694236968344],
					[58.833824802882617, 1.7839694355396218],
					[58.833824804159647, 1.7839694422271437],
					[58.834205403868957, 1.7857528425796028],
					[58.834205474719852, 1.7857529164467385],
					[58.834205644271748, 1.785752966216392],
					[58.834205742779773, 1.7857529386819964],
					[58.835261243088667, 1.7846668394224598],
					[58.835261244774017, 1.7846668373316332],
					[58.835261248241238, 1.7846668348684542],
					[58.835261249035639, 1.7846668329637949],
					[58.837125077076131, 1.7825724147794284],
					[58.838455569811877, 1.782772397981625],
					[58.838455643662577, 1.7827723808051317],
					[58.838455757886358, 1.7827722960688892],
					[58.838455798356009, 1.7827722302277957],
					[58.839122391922849, 1.7785417640979544],
					[58.840900143864772, 1.7751584555284901],
					[58.842297241582635, 1.7745696002985663],
					[58.84347220206525, 1.7745558001830717],
					[58.843472267316251, 1.7745557743509992],
					[58.843472365207383, 1.7745556877746278],
					[58.843472397847499, 1.7745556270303415],
					[58.843608474553079, 1.7735529972286821],
					[58.84563326383519, 1.7735279993410011],
					[58.848186013597591, 1.7745001817489368],
					[58.850402655327848, 1.7757612471748647],
					[58.851374799402286, 1.7773972537731095],
					[58.851352600240453, 1.7794721980961548],
					[58.851352632302721, 1.7794722714037705],
					[58.851352740726576, 1.7794723722205203],
					[58.851352817979091, 1.77947239954322],
					[58.853122218179223, 1.7793084995343351],
					[58.853122250155813, 1.7793084911032906],
					[58.85312230907472, 1.7793084648571991],
					[58.853122336017059, 1.7793084470421727],
					[58.854216772665758, 1.7782946054157587],
					[58.857241666637002, 1.7784723988633759],
					[58.859749946948568, 1.7791584928793058],
					[58.859749969704652, 1.7791584968155509],
					[58.859750014830361, 1.7791584978092754],
					[58.859750038187528, 1.7791584963999525],
					[58.862136077141756, 1.7787030080614665],
					[58.863246998404641, 1.7791890301678728],
					[58.863196999894846, 1.7821249962460579],
					[58.863197000184584, 1.7821250014056618],
					[58.863196999776555, 1.7821250101914528],
					[58.863197000957207, 1.7821250151646413],
					[58.863402600900635, 1.7849139146007302],
					[58.863402603841024, 1.7849139348667094],
					[58.863402615067301, 1.7849139742804483],
					[58.863402623353252, 1.7849139934282081],
					[58.865008120100029, 1.7879750878486125],
					[58.866805415289797, 1.7919639766722315],
					[58.868241511216908, 1.7958861643032353],
					[58.869369209855769, 1.7993833601934277],
					[58.869369220210679, 1.7993833841366862],
					[58.869369245977204, 1.7993834257462309],
					[58.869369262376104, 1.799383444946721],
					[58.870852662105023, 1.800797344550001],
					[58.870852674361878, 1.8007973541740796],
					[58.87085269788831, 1.8007973718879198],
					[58.870852710939779, 1.8007973796056598],
					[58.872555483414466, 1.8016390651569891],
					[58.873852620808556, 1.8029112041652984],
					[58.875222007411239, 1.8081250515460781],
					[58.875222063322532, 1.8081251164712926],
					[58.875222206620904, 1.8081251805740253],
					[58.875222294706454, 1.8081251761245534],
					[58.876719495262535, 1.8073223755961005],
					[58.876719499621153, 1.807322372946021],
					[58.876719510915031, 1.8073223653675592],
					[58.876719517055591, 1.8073223623457031],
					[58.878338847933534, 1.8061585846479913],
					[58.880085932611664, 1.8087890115780065],
					[58.880085957316879, 1.8087890342782089],
					[58.880086010098744, 1.8087890754934952],
					[58.880086039764855, 1.8087890901951942],
					[58.881633239909135, 1.8092723908462074],
					[58.881633297605312, 1.8092723909964423],
					[58.881633404932302, 1.8092723599011595],
					[58.881633452684987, 1.8092723273064932],
					[58.883025152640535, 1.8076112276232217],
					[58.883025156108189, 1.8076112251585574],
					[58.883025160274272, 1.8076112190660569],
					[58.883025162850942, 1.8076112167873215],
					[58.885247341793473, 1.8045057466716155],
					[58.887188995759281, 1.8031945760891765],
					[58.889422279010617, 1.802239083749295],
					[58.889422299912027, 1.8022390706763389],
					[58.889422338849279, 1.8022390416462728],
					[58.889422356788778, 1.8022390239679213],
					[58.890961214306472, 1.8002862787203393],
					[58.893566745230274, 1.7996862957054192],
					[58.893566793269109, 1.7996862682494796],
					[58.893566866710991, 1.7996861954278178],
					[58.893566893799544, 1.7996861479682185],
					[58.894316893797637, 1.7966667488925212],
					[58.894316895193874, 1.7966667416340485],
					[58.894316898179191, 1.7966667305600506],
					[58.89431689868448, 1.7966667234878628],
					[58.894661299121914, 1.793797224389764],
					[58.89466129863964, 1.7937972157823718],
					[58.894661299649911, 1.7937972016377997],
					[58.894661300155022, 1.7937971945655309],
					[58.894566900300596, 1.7907888940995607],
					[58.894566889869786, 1.7907888527410096],
					[58.894566854248211, 1.7907887800793485],
					[58.894566828069983, 1.7907887472411996],
					[58.892197327930297, 1.7888081464572219],
					[58.892197327930297, 1.7888081464572219],
					[58.892197327039362, 1.7888081466437009],
					[58.892197326942828, 1.788808144922353],
					[58.889783441287264, 1.7868304563693582],
					[58.886141853211498, 1.7825026708752214],
					[58.886141849358012, 1.7825026664585792],
					[58.886141841747644, 1.7825026593462912],
					[58.886141837894172, 1.7825026549296283],
					[58.883025146688574, 1.7795304647444674],
					[58.879305764212013, 1.7749887835382041],
					[58.875877970838665, 1.7693720960741697],
					[58.875877967875688, 1.7693720914733797],
					[58.875877962937402, 1.769372083805409],
					[58.87587795997441, 1.7693720792046332],
					[58.873377977653981, 1.7660388035585046],
					[58.871425188248708, 1.7606916304774427],
					[58.869580787586614, 1.7556305319845278],
					[58.86958078838056, 1.7556305300772779],
					[58.86958078659881, 1.7556305304516069],
					[58.867097390881796, 1.7490027390557654],
					[58.86584189261891, 1.7443638488580655],
					[58.865841833811182, 1.7443637811776271],
					[58.865841686226688, 1.7443637217332955],
					[58.865841597352855, 1.7443637282495619],
					[58.864741596789216, 1.7450220274387349],
					[58.864741566077562, 1.7450220582624876],
					[58.864741517805669, 1.7450221293250388],
					[58.864741502124254, 1.7450221709088145],
					[58.864252661826924, 1.7483217678640637],
					[58.862208477693365, 1.7461998978792486],
					[58.860847392100297, 1.7415638431631315],
					[58.860847352970303, 1.7415637887560194],
					[58.860847248048408, 1.7415637186129616],
					[58.86084718394109, 1.7415637007825098],
					[58.858783388825429, 1.7417330928563843],
					[58.856666856346294, 1.7391109733885575],
					[58.856666849721833, 1.7391109678234837],
					[58.856666839145426, 1.7391109561308582],
					[58.856666833411801, 1.7391109503782844],
					[58.854697359672556, 1.7373498751890215],
					[58.853686321117642, 1.7354305917473323],
					[58.854480747101796, 1.7335584652365852],
					[58.858452854653606, 1.7324334926693239],
					[58.858452871384863, 1.7324334856645418],
					[58.858452903859344, 1.7324334701231843],
					[58.858452918614496, 1.7324334600548521],
					[58.86119451942092, 1.7303918599768784],
					[58.861194553804879, 1.7303918144484245],
					[58.861194591117602, 1.7303917091401573],
					[58.861194594240921, 1.7303916527995238],
					[58.860019594101239, 1.7256832515855531],
					[58.86001958768577, 1.7256832337982613],
					[58.860019572182374, 1.7256831987872561],
					[58.860019562203647, 1.725683181751418],
					[58.858525161920497, 1.7236276821799743],
					[58.858525101988057, 1.7236276426234587],
					[58.858524972377658, 1.7236276142847309],
					[58.858524901808892, 1.7236276256904479],
					[58.857352861385621, 1.7242886363772651],
					[58.85642236466478, 1.7229387858318213],
					[58.856422299289953, 1.7229387456867711],
					[58.856422158098951, 1.7229387197945705],
					[58.856422083368294, 1.7229387372981217],
					[58.85521383417889, 1.7238137012642654],
					[58.8524500458863, 1.7238497996473781],
					[58.850344524023875, 1.7228054378587061],
					[58.847964049907205, 1.7201192671576362],
					[58.847964048028224, 1.7201192658145912],
					[58.847964046051814, 1.7201192627526809],
					[58.847964044172819, 1.7201192614096232],
					[58.845586243778925, 1.7176470618219557],
					[58.845586211835595, 1.7176470389939316],
					[58.845586143383471, 1.7176470082156627],
					[58.845586105093048, 1.7176470006413562],
					[58.843708335115643, 1.7175998008279765],
					[58.842266763031127, 1.7171192092275966],
					[58.842266721372354, 1.717119205843318],
					[58.842266638931626, 1.7171192145420109],
					[58.842266599137886, 1.7171192281556151],
					[58.841022190988134, 1.7178497724578177],
					[58.839172309192037, 1.7169470313865005],
					[58.837391850733574, 1.7154665655911445],
					[58.836833496218624, 1.7146166343482716],
					[58.836625199874632, 1.7115443911770611],
					[58.836516899958092, 1.7075388951545754],
					[58.836516897994329, 1.7075388764418262],
					[58.836516890601217, 1.7075388414872059],
					[58.836516886062654, 1.7075388250571872],
					[58.835200185630406, 1.7042666253123222],
					[58.833847386197476, 1.7008527251441699],
					[58.833847381157838, 1.7008527157770785],
					[58.833847370980955, 1.700852695324776],
					[58.833847365050566, 1.7008526861459847],
					[58.831608465125065, 1.6975915874686793],
					[58.831608459194456, 1.6975915782910378],
					[58.831608446637837, 1.6975915635600682],
					[58.83160843991412, 1.6975915562887811],
					[58.829600156117692, 1.695619272755357],
					[58.828966869086493, 1.6946248933642571],
					[58.828966795199186, 1.6946248464080458],
					[58.828966639253117, 1.6946248307186575],
					[58.828966557096642, 1.6946248602676388],
					[58.827763779836211, 1.6958581362592833],
					[58.826588881344357, 1.6964469866628489],
					[58.825886155928863, 1.6962415086185996],
					[58.825886133171423, 1.696241504739296],
					[58.825886086168069, 1.6962415025104953],
					[58.825886061922176, 1.6962415041610124],
					[58.825080736795911, 1.696396969869653],
					[58.82428629115573, 1.6939055392386977],
					[58.82428628700643, 1.6939055296867191],
					[58.824286278805545, 1.6939055123003601],
					[58.824286274656231, 1.6939055027483847],
					[58.822711274822304, 1.6910693031693587],
					[58.822711267902804, 1.6910692924660671],
					[58.822711251293818, 1.6910692699073835],
					[58.822711242592803, 1.6910692595810495],
					[58.821044543019468, 1.6893748594721449],
					[58.82104453263463, 1.6893748512411493],
					[58.821044512755691, 1.6893748345906576],
					[58.821044501577873, 1.689374828265592],
					[58.818822302358591, 1.6880498271089763],
					[58.818822292169251, 1.6880498223134821],
					[58.818822270899844, 1.6880498129110422],
					[58.818822259819754, 1.6880498083040756],
					[58.81620006013997, 1.687227608988316],
					[58.816200051927687, 1.6872276072506915],
					[58.81620003451458, 1.687227602246782],
					[58.816200026400068, 1.6872276022263391],
					[58.815238926303145, 1.6870998011030165],
					[58.815238843159946, 1.6870998291269317],
					[58.81523872967847, 1.6870999417741857],
					[58.815238701023887, 1.6871000243033649],
					[58.815797000963393, 1.691738923418987],
					[58.81579700323357, 1.691738931628064],
					[58.815797004906187, 1.6917389451770821],
					[58.815797007078629, 1.6917389516689871],
					[58.817202607124614, 1.6970083521344552],
					[58.817202611566643, 1.6970083668367457],
					[58.81720262599061, 1.6970083985461397],
					[58.817202634886456, 1.6970084123070177],
					[58.81951373518406, 1.7004084116140681],
					[58.819513736172446, 1.7004084131432631],
					[58.819513738149226, 1.7004084162016255],
					[58.819513739235255, 1.7004084194481959],
					[58.821341526590153, 1.702886202686954],
					[58.822616504612142, 1.7055861557661314],
					[58.82273589814374, 1.7067277872186444],
					[58.822238715356328, 1.7089499118522682],
					[58.820197082232703, 1.711552625552188],
					[58.816597228874897, 1.7126025836668888],
					[58.815005705382561, 1.7116248297144039],
					[58.815005614841255, 1.7116248227453379],
					[58.815005464965921, 1.7116248821623914],
					[58.815005405826817, 1.7116249519829441],
					[58.814505405566315, 1.7136332517946258],
					[58.814505405663766, 1.713633253511839],
					[58.814505404967846, 1.713633257134068],
					[58.814505404077046, 1.7136332573219035],
					[58.814122023866751, 1.7154248659572462],
					[58.813130508608936, 1.7159387213486459],
					[58.811261009256604, 1.7168915222429226],
					[58.811260967360283, 1.7168915623485594],
					[58.81126091333951, 1.7168916588741783],
					[58.811260901215043, 1.7168917152941603],
				],
				[
					[58.804294199965241, 1.7818694024051163],
					[58.804297000020803, 1.7822361012199646],
					[58.804297000310356, 1.7822361063708394],
					[58.80429700088947, 1.7822361166725751],
					[58.80429700117903, 1.7822361218234499],
					[58.804377600910364, 1.7829611153391109],
					[58.804460899927577, 1.7846194036007463],
					[58.804447000117449, 1.7853638954785609],
					[58.804447000406874, 1.7853639006294946],
					[58.804446999901842, 1.7853639076833066],
					[58.804447000094797, 1.7853639111172657],
					[58.804497000027901, 1.786177812167105],
					[58.80449700120824, 1.7861778171321385],
					[58.804497001979975, 1.7861778308679921],
					[58.804497003256763, 1.7861778375500068],
					[58.804663702955111, 1.7870722378246213],
					[58.804663704038951, 1.7870722410727182],
					[58.804663706303117, 1.7870722492859201],
					[58.804663707386972, 1.787072252534033],
					[58.804763706890157, 1.7874333524886119],
					[58.804763708961389, 1.7874333572678709],
					[58.804763711321968, 1.7874333671981206],
					[58.804763713296751, 1.7874333702603682],
					[58.805063712876752, 1.7882250705893525],
					[58.805063716922753, 1.7882250784310165],
					[58.805063725111125, 1.7882250958313699],
					[58.805063729157119, 1.7882251036730323],
					[58.805155429372206, 1.7883751041568168],
					[58.80515544201532, 1.7883751206279823],
					[58.805155468890547, 1.7883751497645826],
					[58.805155484013625, 1.7883751622441568],
					[58.805327659428158, 1.7884973456996296],
					[58.80559423963831, 1.7888501208499932],
					[58.806019239774386, 1.7894140199244288],
					[58.806019244614831, 1.7894140258635487],
					[58.806019254295705, 1.7894140377417929],
					[58.806019259039708, 1.789414041963836],
					[58.8063359587997, 1.7897279427326431],
					[58.806335961665383, 1.7897279456093322],
					[58.806335969178654, 1.7897279509910708],
					[58.80633597194791, 1.7897279521506742],
					[58.806624872226081, 1.7899695531178046],
					[58.806624890118265, 1.7899695667581954],
					[58.806624929971761, 1.7899695862414784],
					[58.806624951138502, 1.7899695939872962],
					[58.806869350769986, 1.7900307943358882],
					[58.806869357993975, 1.7900307945664418],
					[58.806869371743907, 1.7900307986475499],
					[58.806869378967903, 1.7900307988780892],
					[58.807080579290798, 1.7900529987256451],
					[58.807080594629774, 1.7900529990009111],
					[58.807080626102262, 1.7900529976484771],
					[58.807080641344804, 1.7900529962066156],
					[58.807316740963103, 1.7900029957639503],
					[58.807316741854081, 1.7900029955781185],
					[58.807316743635965, 1.7900029952064691],
					[58.807316745417864, 1.7900029948348197],
					[58.807677827011268, 1.7899195994573203],
					[58.807963906832946, 1.7899085003219031],
					[58.808158303104037, 1.7899029994513396],
					[58.80817500036251, 1.7899029999922693],
					[58.808250000023023, 1.7899030008010159],
					[58.808269399586145, 1.7899030005714196],
					[58.808338899833927, 1.7899030000893541],
					[58.808352800080961, 1.7899030002979557],
					[58.808433299948014, 1.7899030004489762],
					[58.808449999879159, 1.7899030004223901],
					[58.808450001661058, 1.7899030000507152],
					[58.80845000611582, 1.7899029991215272],
					[58.808450007994118, 1.7899030004670435],
					[58.808522203906051, 1.7899002000373836],
					[58.808536099601781, 1.789900199451306],
					[58.808536102371058, 1.7899002006109888],
					[58.808536105043892, 1.7899002000534789],
					[58.808536106825791, 1.7899001996817991],
					[58.808613903983847, 1.7898974001037871],
					[58.808624999995331, 1.7898973997476619],
					[58.808625006328398, 1.7898974001639902],
					[58.808625017116206, 1.7898973996511274],
					[58.808625021570954, 1.7898973987219409],
					[58.80870001135537, 1.789889100615812],
					[58.808716700402009, 1.7898890993684733],
					[58.80871670762604, 1.7898890995989791],
					[58.808716724650481, 1.7898890977852393],
					[58.808716732765454, 1.7898890978298894],
					[58.80878333289192, 1.789877997859642],
					[58.808783338237632, 1.7898779967446015],
					[58.808783349819947, 1.7898779943286871],
					[58.808783355069217, 1.7898779914964478],
					[58.808802846049325, 1.7898723953653408],
					[58.808863928050783, 1.7898612979630955],
					[58.808891720120094, 1.7898584995048643],
					[58.808891729029575, 1.7898584976464533],
					[58.808891747739509, 1.7898584937437951],
					[58.808891756648997, 1.7898584918853859],
					[58.8089472432669, 1.7898418954261612],
					[58.808986128364147, 1.7898362983419278],
					[58.808986132818909, 1.7898362974127144],
					[58.808986140837447, 1.789836295740137],
					[58.80898614618313, 1.7898362946250854],
					[58.809033345847958, 1.7898251949202091],
					[58.809033352084612, 1.7898251936193019],
					[58.809033365352413, 1.7898251891144485],
					[58.809033372480009, 1.7898251876277029],
					[58.809069459688992, 1.7898112911302329],
					[58.809113948136321, 1.7898001939947947],
					[58.80911394991822, 1.7898001936231056],
					[58.809113954372982, 1.7898001926938811],
					[58.809113956154867, 1.7898001923221982],
					[58.809161156298458, 1.7897862918243808],
					[58.809161157189429, 1.7897862916385265],
					[58.809188954924089, 1.7897779914790715],
					[58.809238953953312, 1.7897640925680183],
					[58.809238966330177, 1.7897640882489492],
					[58.809238990987467, 1.7897640778935704],
					[58.809239003267891, 1.7897640718572767],
					[58.809252896396629, 1.7897557741813703],
					[58.809263975241592, 1.7897501865744043],
					[58.809344458841977, 1.7897251909105401],
					[58.809344460623876, 1.789725190538846],
					[58.80934446507861, 1.7897251896096058],
					[58.809344466764102, 1.7897251875206763],
					[58.809422264571396, 1.7896973884605933],
					[58.809430663406118, 1.7896945906046702],
					[58.809430670437251, 1.7896945874006429],
					[58.809430682717696, 1.7896945813642697],
					[58.809430688857908, 1.789694578346092],
					[58.809447272100797, 1.789686287553391],
					[58.809497254283286, 1.7896723936270702],
					[58.809497263096347, 1.7896723900513125],
					[58.809497280722461, 1.7896723828998304],
					[58.809497288644586, 1.7896723795099381],
					[58.80952507692713, 1.7896584851023962],
					[58.809558356023992, 1.7896473925679166],
					[58.809569449046329, 1.7896445938468142],
					[58.809569456173897, 1.789644592360002],
					[58.809569471223611, 1.7896445874832829],
					[58.809569478254772, 1.7896445842792319],
					[58.809608365147596, 1.7896279906860129],
					[58.809650042304085, 1.7896168957699354],
					[58.809666732910692, 1.7896140968749521],
					[58.809666746274885, 1.7896140940871641],
					[58.809666771919545, 1.7896140852629363],
					[58.809666784392817, 1.7896140826609928],
					[58.809702861645398, 1.7895973922895412],
					[58.809730639282108, 1.7895918966408666],
					[58.80973064631327, 1.789591893436782],
					[58.809730659677491, 1.7895918906489749],
					[58.809730666708639, 1.7895918874448795],
					[58.809769458159735, 1.7895779926324895],
					[58.809791748706573, 1.7895723941475223],
					[58.809791749597522, 1.789572393961665],
					[58.809791753161321, 1.7895723932182486],
					[58.809791754943205, 1.7895723928465419],
					[58.8098111540193, 1.7895668936872808],
					[58.809872253063588, 1.7895501921680899],
					[58.809872261973076, 1.7895501903095248],
					[58.809872281381061, 1.789550182786166],
					[58.809872290194122, 1.789550179210351],
					[58.809883366257267, 1.7895445902635732],
					[58.809952839267233, 1.7895307962363807],
					[58.809952841049146, 1.7895307958646676],
					[58.809952846394815, 1.7895307947495269],
					[58.809952849067663, 1.7895307941919518],
					[58.80997504668634, 1.7895251941742976],
					[58.810047223213147, 1.7895084993032804],
					[58.810061100098622, 1.7895085006994562],
					[58.810061110789995, 1.7895084984691543],
					[58.810061132365604, 1.7895084974430835],
					[58.810061143056977, 1.7895084952127815],
					[58.810136122183131, 1.7894919001354952],
					[58.810147200179607, 1.7894918998951534],
					[58.810147207403602, 1.7894919001255671],
					[58.810147220864245, 1.7894918990549447],
					[58.810147227991841, 1.789491897568064],
					[58.810166728173591, 1.7894890979512204],
					[58.810166729064555, 1.7894890977653568],
					[58.810166729955505, 1.7894890975794979],
					[58.81016673084644, 1.7894890973936406],
					[58.810219415936736, 1.7894807997483502],
					[58.810241699986896, 1.7894808003405329],
					[58.810241714338488, 1.7894807990840389],
					[58.810241742054288, 1.7894807950396439],
					[58.810241755418524, 1.789480792251732],
					[58.810261133845444, 1.7894751982256496],
					[58.810305613252638, 1.7894723998432189],
					[58.810305615034551, 1.7894723994714947],
					[58.81030562038022, 1.7894723983563272],
					[58.810305622258532, 1.7894723997018862],
					[58.810330610965785, 1.7894696000330963],
					[58.810347200051496, 1.7894696002645976],
					[58.810391699571433, 1.7894696000910204],
					[58.810391711250162, 1.7894695993920968],
					[58.810391731838429, 1.7894695968345491],
					[58.810391742529802, 1.789469594604209],
					[58.810416722351086, 1.7894641003276812],
					[58.810444400220689, 1.7894640997839686],
					[58.810486100057048, 1.7894640998741644],
					[58.810508300284376, 1.7894641005549907],
					[58.81053059957663, 1.7894640996800393],
					[58.810572200251478, 1.7894640995998432],
					[58.810597200162015, 1.7894640999955647],
					[58.81061940038925, 1.7894641006710397],
					[58.810663899908619, 1.7894641004639809],
					[58.810683300356125, 1.7894640997004092],
					[58.81070279095907, 1.7894640992398918],
					[58.810761081261148, 1.7894695981644282],
					[58.810761085812331, 1.789469598952401],
					[58.81076109491466, 1.7894696005283595],
					[58.810761100260343, 1.7894695994131695],
					[58.810780599965632, 1.7894696005284041],
					[58.81079440015565, 1.7894696005123103],
					[58.810855567140621, 1.7894695997939996],
					[58.810863836285669, 1.7894723902900349],
					[58.810863851721052, 1.7894723922822549],
					[58.810863883675637, 1.7894723995154309],
					[58.810863899905577, 1.7894723996044832],
					[58.81088330044944, 1.7894724005544196],
					[58.810947200416059, 1.7894723995623665],
					[58.810958300084138, 1.789472399987365],
					[58.810966700121277, 1.7894724006712812],
					[58.811041699755727, 1.7894724000906232],
					[58.811041709652585, 1.789472399763403],
					[58.811041730240802, 1.7894723972057869],
					[58.811041739150284, 1.7894723953471248],
					[58.811055627393152, 1.7894695970603971],
					[58.811127808046805, 1.7894641000407396],
					[58.81113330013693, 1.7894640992653585],
					[58.8111333029062, 1.7894641004250891],
					[58.811133310130217, 1.7894641006554661],
					[58.811133313693993, 1.7894640999120019],
					[58.811213906609851, 1.7894585002101322],
					[58.811224999944685, 1.789458500207979],
					[58.81122500805963, 1.7894585002524892],
					[58.811225024193121, 1.7894584986241837],
					[58.811225032211638, 1.7894584969513694],
					[58.811294432041748, 1.7894473964172557],
					[58.811294435701939, 1.7894473973911096],
					[58.811294443720477, 1.7894473957182842],
					[58.811294448175211, 1.789447394788942],
					[58.811316747865149, 1.7894418947611366],
					[58.811316751332512, 1.7894418923003301],
					[58.811316757569152, 1.7894418909992464],
					[58.811316761132922, 1.7894418902557694],
					[58.811377860900279, 1.7894223918732493],
					[58.811377862585736, 1.7894223897841783],
					[58.811377867931434, 1.7894223886689566],
					[58.811377869616884, 1.7894223865798824],
					[58.811400059226415, 1.7894140913370447],
					[58.811455648736981, 1.7894001937673827],
					[58.811455657646441, 1.7894001919086713],
					[58.811455676163476, 1.789400184570699],
					[58.811455684976529, 1.7894001809946534],
					[58.811497284386668, 1.7893807823150625],
					[58.811527882892754, 1.7893668825091604],
					[58.811527883687269, 1.7893668806059546],
					[58.811527888142017, 1.7893668796765838],
					[58.811527889923909, 1.7893668793048454],
					[58.811583390184524, 1.7893390796739785],
					[58.811583391870016, 1.7893390775848821],
					[58.81158339711925, 1.7893390747523055],
					[58.811583398901142, 1.7893390743805575],
					[58.811602899046328, 1.7893279746508028],
					[58.811602900731792, 1.7893279725616906],
					[58.81160290598104, 1.7893279697291076],
					[58.811602908557447, 1.7893279674541427],
					[58.811650098890397, 1.7892973735307507],
					[58.811672289054023, 1.7892862789550181],
					[58.811672297770642, 1.7892862736615684],
					[58.811672314312908, 1.7892862632605344],
					[58.811672322138577, 1.7892862581529581],
					[58.811733419565314, 1.7892390592679475],
					[58.811764017220675, 1.7892168612425676],
					[58.811764024155401, 1.7892168563208279],
					[58.811764036242927, 1.7892168468491376],
					[58.811764042190255, 1.7892168403959274],
					[58.81179453622849, 1.7891862476325664],
					[58.811855731223282, 1.7891334507307439],
					[58.811855738856096, 1.7891334421883831],
					[58.811855755012623, 1.7891334249177979],
					[58.811855762645408, 1.7891334163754433],
					[58.81186954992048, 1.7891140356071018],
					[58.811916832234189, 1.7890723507179098],
					[58.811916834810582, 1.7890723484429067],
					[58.811916839072445, 1.7890723440787644],
					[58.811916840661475, 1.7890723402722846],
					[58.811939040920549, 1.7890501408506143],
					[58.811939042702448, 1.7890501404788439],
					[58.811939045278834, 1.7890501382038346],
					[58.811939046073363, 1.7890501363005795],
					[58.81197794645945, 1.7890084364743468],
					[58.8119779473504, 1.7890084362884577],
					[58.8119779481449, 1.7890084343852217],
					[58.8119779481449, 1.7890084343852217],
					[58.812033447844357, 1.788947333241508],
					[58.812033456368063, 1.788947324513174],
					[58.81203347223525, 1.7889473020903175],
					[58.812033478784201, 1.7889472902990327],
					[58.812039071307005, 1.7889362041738925],
					[58.812083454642021, 1.7888751269218313],
					[58.812119547190449, 1.7888362365283161],
					[58.812119549670435, 1.7888362325359106],
					[58.812119558194112, 1.788836223807535],
					[58.812119560577663, 1.7888362180977475],
					[58.812141858297878, 1.7888057223780796],
					[58.812180752765272, 1.7887584285128943],
					[58.812200150704108, 1.788736231336489],
					[58.812200160816836, 1.7887362188015334],
					[58.812200178080126, 1.7887361891371869],
					[58.812200186314506, 1.7887361752566497],
					[58.81220577708239, 1.7887222972533181],
					[58.812247360962346, 1.7886640188146368],
					[58.812264056676987, 1.7886418241476683],
					[58.812302953653187, 1.7885945276503246],
					[58.812302962778517, 1.7885945135838126],
					[58.812302979343698, 1.7885944875399487],
					[58.81230298579618, 1.7885944740311359],
					[58.812308478428548, 1.7885806946238558],
					[58.812350156834626, 1.7885195257474646],
					[58.812386245945753, 1.788480736137372],
					[58.812386252687553, 1.7884807277806332],
					[58.812386266965632, 1.7884807091638812],
					[58.812386272816489, 1.7884807009930601],
					[58.812405762728368, 1.7884473173835707],
					[58.812441850674325, 1.7884057304865386],
					[58.812441854045225, 1.7884057263081501],
					[58.812441860883432, 1.7884057196687819],
					[58.812441864157904, 1.7884057137730085],
					[58.812461263814875, 1.7883779141296927],
					[58.812461268774776, 1.7883779061447242],
					[58.812461278598136, 1.7883778884574291],
					[58.812461284352544, 1.788377878569172],
					[58.812469574109706, 1.7883584036618441],
					[58.812511254390138, 1.788305728495108],
					[58.812569551201406, 1.7882390322555499],
					[58.812569565286573, 1.7882390102038308],
					[58.812569587918333, 1.7882389637810638],
					[58.81256959646494, 1.7882389394100286],
					[58.812572386267242, 1.7882250878895551],
					[58.81261954912965, 1.7881668342109067],
					[58.812652941372889, 1.7881334404185247],
					[58.812652948211088, 1.7881334337790611],
					[58.812652960900117, 1.7881334189686207],
					[58.812652965860003, 1.7881334109835885],
					[58.812675158920129, 1.788100123086092],
					[58.812722344884421, 1.7880473374140067],
					[58.812736240820769, 1.788033440943902],
					[58.812736241711718, 1.788033440757989],
					[58.812736244288111, 1.7880334384828445],
					[58.81273624517906, 1.7880334382969332],
					[58.81279454006286, 1.7879723432947496],
					[58.812830733862576, 1.7879390473542649],
					[58.812855732881076, 1.7879168497903217],
					[58.812855734566519, 1.7879168477010712],
					[58.812855738828333, 1.7879168433366741],
					[58.812855741404725, 1.7879168410615185],
					[58.812905723950955, 1.7878668576914807],
					[58.812919503835985, 1.7878584706693452],
					[58.812919511661562, 1.7878584655612488],
					[58.812919527216309, 1.7878584536276374],
					[58.812919534150971, 1.7878584487054476],
					[58.812977933635025, 1.7878057495719883],
					[58.813002916823201, 1.7877834630194855],
					[58.813041799398917, 1.7877612731162391],
					[58.813041803757201, 1.7877612704692034],
					[58.813041814255619, 1.7877612648032757],
					[58.813041819408383, 1.7877612602529149],
					[58.813094519253781, 1.7877223612427287],
					[58.813105706734753, 1.7877140702956211],
					[58.813163982395857, 1.7876834823586802],
					[58.813186170175051, 1.78767518758371],
					[58.813186175424264, 1.7876751847507137],
					[58.813186185128188, 1.7876751809880904],
					[58.813186188595523, 1.7876751785269598],
					[58.813252879904233, 1.7876418837549075],
					[58.81327506039991, 1.7876334914067344],
					[58.813330648943079, 1.787619593631079],
					[58.813330654288741, 1.7876195925154994],
					[58.813330663198172, 1.7876195906561916],
					[58.81333066755645, 1.7876195880091146],
					[58.813361148703336, 1.7876084955416078],
					[58.813416729954135, 1.7876001976090927],
					[58.81341673262699, 1.7876001970512887],
					[58.813416740645465, 1.7876001953779097],
					[58.813416745100199, 1.7876001944482416],
					[58.813452823296878, 1.7875919007183285],
					[58.813500000020774, 1.7875919003385496],
					[58.81350000269363, 1.7875918997807472],
					[58.813500009917611, 1.7875919000107188],
					[58.81350001259046, 1.7875918994529194],
					[58.81354170131975, 1.787589100102654],
					[58.813594382267908, 1.7875918987668531],
					[58.813636061057522, 1.7875973966224605],
					[58.813688848809917, 1.787611293456419],
					[58.81368885791224, 1.7876112950319625],
					[58.81368887611692, 1.7876112981830572],
					[58.813688885219278, 1.7876112997585976],
					[58.813724956224348, 1.7876140966392193],
					[58.813747129868965, 1.7876223862673064],
					[58.813747131747313, 1.7876223876128781],
					[58.813747135407546, 1.7876223885865827],
					[58.81374713728588, 1.7876223899321546],
					[58.81380551602463, 1.7876418826816356],
					[58.813819296768351, 1.7876501706664221],
					[58.813819304185266, 1.7876501743312989],
					[58.813819318128139, 1.787650181846983],
					[58.813819325545069, 1.7876501855118598],
					[58.813847115624995, 1.787661282125113],
					[58.813888800805906, 1.7876834734184432],
					[58.813902696778833, 1.7876918711833298],
					[58.81390269954813, 1.7876918723429776],
					[58.813902707061494, 1.7876918777253268],
					[58.813902710721727, 1.7876918786990394],
					[58.813930497406297, 1.7877057718456932],
					[58.81395818461224, 1.7877251643744414],
					[58.813958186490574, 1.7877251657200401],
					[58.813958188272473, 1.787725165348176],
					[58.813958189259864, 1.7877251668796927],
					[58.813991584643226, 1.7877473628979181],
					[58.814024875782692, 1.7877723573748725],
					[58.814074871999388, 1.7878140547546526],
					[58.814074881391086, 1.787814061482647],
					[58.814074900969004, 1.7878140730352232],
					[58.81407491125163, 1.7878140795772854],
					[58.814091578860278, 1.7878223629571932],
					[58.814144255487562, 1.787877938765994],
					[58.814144257365896, 1.7878779401115961],
					[58.814144263000934, 1.7878779441484149],
					[58.814144264975731, 1.7878779472114799],
					[58.814177647129554, 1.7879084310913995],
					[58.814199832655746, 1.7879418111296461],
					[58.814199837399812, 1.7879418153524098],
					[58.814199846189922, 1.7879418274187822],
					[58.814199850139502, 1.7879418335449424],
					[58.814244248762499, 1.7879918305993519],
					[58.814258117336486, 1.7880083923033356],
					[58.814260906083696, 1.7880194487646812],
					[58.814260912586761, 1.7880194682587605],
					[58.814260930842117, 1.7880195044138876],
					[58.814260941703466, 1.7880195212608658],
					[58.814308138925973, 1.7880807188770003],
					[58.814322035010449, 1.7881001141435617],
					[58.814360899925482, 1.7881583609820741],
					[58.814360900028142, 1.7881639004395022],
					[58.814360904244147, 1.7881639273613488],
					[58.81436091900926, 1.7881639816210344],
					[58.814360931243755, 1.7881640068695623],
					[58.814408125026041, 1.788238996933776],
					[58.814444220000397, 1.7883139870463163],
					[58.814444230958159, 1.7883140056109468],
					[58.814444257135513, 1.7883140383756473],
					[58.814444272451539, 1.788314054293199],
					[58.814460936401346, 1.7883279234213758],
					[58.814499813361515, 1.7884167727327116],
					[58.814510910226637, 1.7884500624530586],
					[58.81451091338171, 1.7884500704827284],
					[58.814510918800877, 1.7884500867279838],
					[58.814510922750436, 1.7884500928542362],
					[58.81454149979934, 1.7885083495941414],
					[58.814541499575228, 1.7885138995724565],
					[58.814541500539676, 1.7885139167473214],
					[58.814541506923305, 1.7885139501674672],
					[58.814541510560574, 1.7885139667845678],
					[58.814574811263384, 1.7886083675673472],
					[58.814574812250768, 1.7886083690989212],
					[58.814574814321979, 1.7886083738795635],
					[58.814574815405813, 1.7886083771286194],
					[58.814602611993088, 1.7886750683182215],
					[58.814613708247009, 1.78871115763485],
					[58.814627607846901, 1.7887583561586673],
					[58.814627610014583, 1.7887583626568124],
					[58.81462761524083, 1.788758375467185],
					[58.814627618299419, 1.7887583817794204],
					[58.814652606622715, 1.7888139580554303],
					[58.81465810315877, 1.7888472333082213],
					[58.814658104628364, 1.7888472434272642],
					[58.814658110143917, 1.7888472613901465],
					[58.814658113395417, 1.7888472711373746],
					[58.814685908320506, 1.7889194573549343],
					[58.814697005058711, 1.7889694424617426],
					[58.814697007322785, 1.7889694506774143],
					[58.814697010767134, 1.78896946385967],
					[58.814697012934772, 1.7889694703578312],
					[58.814713705631057, 1.7890139524799773],
					[58.81471650274846, 1.7890306338201469],
					[58.814716506289223, 1.7890306487198957],
					[58.81471651505624, 1.7890306764301016],
					[58.814716521173416, 1.7890306890546581],
					[58.814727610675767, 1.7890528703495552],
					[58.814749805812248, 1.789141747464869],
					[58.814749805908683, 1.7891417491823653],
					[58.814760906233367, 1.7891861489766709],
					[58.814760907220752, 1.7891861505082687],
					[58.814760908401006, 1.7891861554748749],
					[58.814760909484825, 1.7891861587239657],
					[58.814780408533295, 1.789250057234163],
					[58.814780408629737, 1.7892500589516751],
					[58.814808108752992, 1.7893389588434276],
					[58.81480811092063, 1.7893389653416474],
					[58.814808114172074, 1.7893389750889661],
					[58.814808116146814, 1.7893389781521742],
					[58.814816500027639, 1.7893583409728846],
					[58.814816500154606, 1.7893778005970555],
					[58.814816500829636, 1.7893778126195905],
					[58.814816503961588, 1.789377836292831],
					[58.814816506322082, 1.7893778462260548],
					[58.814841505733206, 1.7894806478080798],
					[58.814841506817011, 1.7894806510571946],
					[58.814841509081056, 1.78948065927293],
					[58.814841510164875, 1.7894806625220525],
					[58.814858109354532, 1.7895306589321403],
					[58.814902606926495, 1.7896889532281921],
					[58.814902614223833, 1.7896889708195649],
					[58.814902629613059, 1.7896890040989044],
					[58.814902640377774, 1.789689019229215],
					[58.81491089991065, 1.7897000675086854],
					[58.814910900473841, 1.7897139006485521],
					[58.814910901245248, 1.7897139143886198],
					[58.814910904570013, 1.7897139414969914],
					[58.814910908110704, 1.7897139563969122],
					[58.814941507400896, 1.7898194528621365],
					[58.814955407248483, 1.7898722508104234],
					[58.81495540744131, 1.7898722542454606],
					[58.814955408621564, 1.7898722592121004],
					[58.81495540970537, 1.7898722624612435],
					[58.814974808397366, 1.7899306552008003],
					[58.815002605665128, 1.7900444464938874],
					[58.81500260684534, 1.7900444514605478],
					[58.815002609109349, 1.7900444596763594],
					[58.815002610193154, 1.7900444629255028],
					[58.815005410025954, 1.7900528623478575],
					[58.81500541101331, 1.7900528638795046],
					[58.815005411109745, 1.7900528655970152],
					[58.815041508010843, 1.7901556552498514],
					[58.815055405353853, 1.7902167442422847],
					[58.815066505050986, 1.7902667435107098],
					[58.815066506327611, 1.7902667501948999],
					[58.815066509675425, 1.7902667616599106],
					[58.815066511939435, 1.7902667698757397],
					[58.815097008796705, 1.7903500590262273],
					[58.815102604114159, 1.7903722403290663],
					[58.815105402627232, 1.7903889338142602],
					[58.815105405975004, 1.7903889452792885],
					[58.815105412670597, 1.7903889682093559],
					[58.815105416909319, 1.7903889794885093],
					[58.815124810595329, 1.7904333666835421],
					[58.815138707439303, 1.7904833542493246],
					[58.815138708330267, 1.7904833540634515],
					[58.815138708619507, 1.7904833592160312],
					[58.815138709606877, 1.7904833607476844],
					[58.815163703209492, 1.7905611396886811],
					[58.815166500663857, 1.7905944174666164],
					[58.8151665031207, 1.7905944291175671],
					[58.815166508130744, 1.7905944541369583],
					[58.815166510587574, 1.790594465787912],
					[58.815185910832732, 1.7906500665273795],
					[58.815185911820087, 1.7906500680590376],
					[58.815185912903871, 1.7906500713082159],
					[58.815185913891256, 1.7906500728398835],
					[58.815205402919858, 1.7907000460107174],
					[58.815208100738651, 1.7907306182614424],
					[58.815208103195452, 1.7907306299124168],
					[58.815208108109061, 1.7907306532143326],
					[58.815208110565891, 1.7907306648652896],
					[58.815235911349056, 1.7908111652403711],
					[58.81523591361303, 1.7908111734562908],
					[58.81523592180119, 1.7908111908622135],
					[58.815235925847055, 1.7908111987064059],
					[58.815247013828618, 1.7908306774000951],
					[58.815266503587097, 1.7909028403102401],
					[58.815269201741756, 1.7909222273399172],
					[58.815269205282299, 1.7909222422401305],
					[58.81526921315799, 1.7909222701371366],
					[58.815269218480452, 1.790922284665621],
					[58.815285910251241, 1.7909583653049901],
					[58.815313704728574, 1.7910750468645473],
					[58.815313705715958, 1.7910750483962325],
					[58.815313707979904, 1.7910750566121763],
					[58.81531370896726, 1.7910750581438677],
					[58.81535810881158, 1.7912194597944493],
					[58.815358111870054, 1.7912194661070298],
					[58.815358118179866, 1.7912194821672758],
					[58.81535812123834, 1.7912194884798736],
					[58.815369205308926, 1.7912417571044676],
					[58.815372001105075, 1.7912667225467251],
					[58.81537200238165, 1.7912667292310298],
					[58.815372006115012, 1.7912667475663868],
					[58.815372008378958, 1.7912667557823703],
					[58.815413707682303, 1.7914139558305393],
					[58.815413707778717, 1.7914139575480708],
					[58.815413709753422, 1.7914139606114665],
					[58.815413709849828, 1.7914139623290026],
					[58.81547200774417, 1.7915889572756294],
					[58.815516507226157, 1.7917583511825099],
					[58.815516507226157, 1.7917583511825099],
					[58.815558106859513, 1.7919139521255034],
					[58.81555810893061, 1.7919139569064864],
					[58.815558112278282, 1.7919139683718102],
					[58.815558113554822, 1.7919139750561786],
					[58.815563710106836, 1.7919278645114982],
					[58.815569207991572, 1.7919472551223383],
					[58.815569208087972, 1.7919472568398855],
					[58.815569209075313, 1.7919472583716007],
					[58.815569209075313, 1.7919472583716007],
					[58.815616508343446, 1.7921000566446903],
					[58.815655404901229, 1.7922417439851033],
					[58.815660902866405, 1.792272234854023],
					[58.815660905226693, 1.7922722447876969],
					[58.815660908767107, 1.7922722596882201],
					[58.815660911921924, 1.7922722677185148],
					[58.815674810127284, 1.7923111631747639],
					[58.81571650714136, 1.7924444525975884],
					[58.815741504676126, 1.7925500463207844],
					[58.815741506843587, 1.7925500528193923],
					[58.815741511082145, 1.7925500640990213],
					[58.815741513249627, 1.7925500705976261],
					[58.815766511872965, 1.7926167683186467],
					[58.815799808841945, 1.7927111610736521],
					[58.815822008478463, 1.7927889556597305],
					[58.815822009658582, 1.7927889606266023],
					[58.815822013993511, 1.7927889736238738],
					[58.815822017051907, 1.792788979936677],
					[58.815841514847556, 1.7928333753552772],
					[58.815869211307891, 1.7929056656293954],
					[58.815885909096039, 1.7929583603839478],
					[58.815885911070723, 1.7929583634474551],
					[58.815885914225525, 1.7929583714778625],
					[58.815885915405651, 1.7929583764447472],
					[58.815905412751647, 1.7930056722999672],
					[58.815930411951683, 1.7930750673774691],
					[58.815930414022745, 1.7930750721585775],
					[58.815930418261281, 1.793075083438332],
					[58.815930421319678, 1.7930750897511956],
					[58.815944215141521, 1.7931028777617262],
					[58.815952610230852, 1.7931278645555038],
					[58.815952613192877, 1.7931278691508041],
					[58.815952616540429, 1.7931278806163904],
					[58.815952619598811, 1.7931278869292635],
					[58.815985919821763, 1.7931972869518742],
					[58.815985920809119, 1.7931972884836374],
					[58.815985923867515, 1.7931972947965265],
					[58.815985924951249, 1.793197298045859],
					[58.816024809469887, 1.7932667673597034],
					[58.816027603064938, 1.7932833321710127],
					[58.816027605714304, 1.7932833472576049],
					[58.816027615371375, 1.7932833747841797],
					[58.816027620693596, 1.7932833893133446],
					[58.816035921129945, 1.7933000881045786],
					[58.816035923200992, 1.7933000928857139],
					[58.816035927246723, 1.7933001007303693],
					[58.816035929221393, 1.7933001037939256],
					[58.816074829287324, 1.79336400428096],
					[58.816074830274651, 1.7933640058127294],
					[58.816074832249321, 1.793364008876281],
					[58.816074834127605, 1.7933640102222648],
					[58.816113731704355, 1.7934223090781558],
					[58.816127629961379, 1.7934445063077242],
					[58.816127636776343, 1.7934445153126091],
					[58.816127651297244, 1.7934445331365776],
					[58.816127658015809, 1.7934445404238835],
					[58.816149842188302, 1.7934668255891111],
					[58.816177629799128, 1.7935112063609659],
					[58.816177633652089, 1.7935112107705125],
					[58.816177639576104, 1.7935112199612213],
					[58.81617764432, 1.7935112241849918],
					[58.816199844131042, 1.7935390250517236],
					[58.816199849958657, 1.7935390325248597],
					[58.816199863299445, 1.7935390453819373],
					[58.816199870018011, 1.7935390526692798],
					[58.81623876992542, 1.7935723513084603],
					[58.816238772791039, 1.7935723541862325],
					[58.816238779413247, 1.7935723597559976],
					[58.816238782182481, 1.7935723609161893],
					[58.816280456372219, 1.7936029419835804],
					[58.816294236731061, 1.7936223158952809],
					[58.816294254912115, 1.7936223346938014],
					[58.816294295632638, 1.7936223696442759],
					[58.816294318870284, 1.7936223821752446],
					[58.816319306688641, 1.793633477748811],
					[58.816369295896621, 1.793664071338221],
					[58.81636929678757, 1.7936640711524257],
					[58.816369296883948, 1.7936640728700206],
					[58.81638319673651, 1.7936723719293937],
					[58.816383205935196, 1.793672375224213],
					[58.816383222647026, 1.7936723839030291],
					[58.816383231845712, 1.7936723871978435],
					[58.816413829226299, 1.7936834877082273],
					[58.816441625929308, 1.7936945866787326],
					[58.816441631371404, 1.7936945872815431],
					[58.816441642352004, 1.7936945902047781],
					[58.816441646999543, 1.793694592710982],
					[58.816472146909689, 1.7937029923443428],
					[58.816472152448171, 1.7937029946647547],
					[58.816472163428777, 1.793702997587993],
					[58.816472168870874, 1.7937029981908001],
					[58.816524968770771, 1.7937112984869716],
					[58.816524973225562, 1.793711297557981],
					[58.816524982327877, 1.7937112991352038],
					[58.81652498687901, 1.7937112999238189],
					[58.816566686854998, 1.793714100538587],
					[58.816566690418831, 1.7937140997953918],
					[58.816566696751877, 1.7937141002124084],
					[58.816566700315711, 1.7937140994692162],
					[58.816608300001867, 1.7937141007035753],
					[58.81660830267473, 1.7937141001461798],
					[58.816608309007783, 1.7937141005631998],
					[58.816608311680675, 1.7937141000057997],
					[58.816655612246763, 1.7937112997873876],
					[58.816702811869092, 1.7937084997546004],
					[58.816702825329784, 1.7937084986852201],
					[58.816702851167491, 1.7937084932970409],
					[58.816702862749914, 1.7937084908816514],
					[58.816744449715657, 1.7936945947800744],
					[58.816775035176668, 1.7936890974969397],
					[58.816775042207929, 1.7936890942929189],
					[58.816775055572265, 1.7936890915059149],
					[58.816775062699904, 1.7936890900195146],
					[58.816800055670555, 1.7936807917817583],
					[58.816833349290732, 1.7936723942172579],
					[58.816833358994856, 1.7936723904558305],
					[58.816833379294074, 1.7936723827471726],
					[58.816833388998198, 1.7936723789857389],
					[58.816855669907113, 1.7936612890240005],
					[58.816911149023611, 1.7936473941995403],
					[58.816911166649952, 1.7936473870482539],
					[58.816911202697227, 1.7936473708422385],
					[58.816911220130798, 1.7936473602556955],
					[58.816922299243373, 1.7936390754941403],
					[58.816936173296341, 1.7936334852058362],
					[58.816994470235201, 1.7936112871485714],
					[58.817016769798251, 1.7936029874622252],
					[58.817016774252998, 1.7936029865332108],
					[58.817016782078852, 1.7936029814257171],
					[58.817016785642664, 1.793602980682504],
					[58.817080670634518, 1.7935723881837493],
					[58.817100054656265, 1.7935668912291229],
					[58.817100062674868, 1.7935668895568717],
					[58.817100081192159, 1.7935668822196928],
					[58.817100089114383, 1.7935668788298085],
					[58.817127876921248, 1.7935529841909481],
					[58.817161163403313, 1.7935418891846813],
					[58.817161169640009, 1.7935418878840317],
					[58.817161182811567, 1.79354188166165],
					[58.817161188951872, 1.7935418786433688],
					[58.817188987350157, 1.7935279800090638],
					[58.817230680981993, 1.7935084830616608],
					[58.817283377621187, 1.7934862847473279],
					[58.8172833810886, 1.7934862822864419],
					[58.817283386337955, 1.7934862794539512],
					[58.817283389010804, 1.7934862788965225],
					[58.817305685742397, 1.7934751818701884],
					[58.817366758871501, 1.7934473926177958],
					[58.817383333395803, 1.7934445963000494],
					[58.817383344978218, 1.7934445938845056],
					[58.817383368046663, 1.7934445873357738],
					[58.817383380423642, 1.7934445830167809],
					[58.817427876548322, 1.7934251843038547],
					[58.817455674283998, 1.7934140851030229],
					[58.81745567527134, 1.793414086634856],
					[58.817455675174948, 1.7934140849172102],
					[58.817455676065897, 1.793414084731396],
					[58.817530675545001, 1.7933834846473347],
					[58.817530678217864, 1.7933834840898948],
					[58.817530679108813, 1.7933834839040819],
					[58.81755007534575, 1.7933751846809407],
					[58.817608369549141, 1.7933529862772866],
					[58.817638967952369, 1.7933418884718009],
					[58.817638969637898, 1.7933418863825168],
					[58.817638973201696, 1.7933418856392545],
					[58.817638974092645, 1.7933418854534402],
					[58.817680667138966, 1.7933251893746882],
					[58.81772505907923, 1.7933112912060674],
					[58.817761159378946, 1.7933001910290185],
					[58.817761160269896, 1.7933001908432058],
					[58.817761162051809, 1.7933001904715706],
					[58.817761162942759, 1.7933001902857515],
					[58.817786154191474, 1.793291893567976],
					[58.817822244788687, 1.7932834958617103],
					[58.817822253601854, 1.7932834922858651],
					[58.817822270433574, 1.7932834870376542],
					[58.817822278355791, 1.7932834836476217],
					[58.817841763246413, 1.7932751901237354],
					[58.817888939175674, 1.7932640963539268],
					[58.81790563344299, 1.7932612975033153],
					[58.817905635224889, 1.7932612971316755],
					[58.817905637897738, 1.7932612965742212],
					[58.817905639679658, 1.7932612962025767],
					[58.817919440442189, 1.7932584960804241],
					[58.817919441333139, 1.7932584958946081],
					[58.817919444896937, 1.79325849515133],
					[58.817919445787901, 1.7932584949655124],
					[58.817988924166713, 1.7932418998897175],
					[58.818005599683829, 1.7932418992739176],
					[58.818005609677094, 1.7932419006652465],
					[58.818005630265425, 1.7932418981090443],
					[58.818005639174956, 1.7932418962508339],
					[58.818088926431763, 1.7932251997518798],
					[58.818175006016745, 1.7932196004342005],
					[58.81827220014371, 1.7932196003250231],
					[58.818302794832569, 1.7932196004614054],
					[58.818358274146931, 1.7932223991220215],
					[58.818436058357328, 1.7932390967117278],
					[58.818436068254208, 1.7932390963853775],
					[58.818436089131687, 1.7932390989822018],
					[58.81843610001593, 1.793239100187713],
					[58.818444380126763, 1.7932391005987998],
					[58.818458253243513, 1.793241894937543],
					[58.818516644934277, 1.793258492591435],
					[58.818516646716176, 1.7932584922197903],
					[58.818516650376374, 1.7932584931941946],
					[58.81851665136373, 1.7932584947260786],
					[58.818538840961637, 1.7932640901977279],
					[58.818569331899937, 1.7932751887718328],
					[58.818569334669192, 1.7932751899320609],
					[58.818569341002252, 1.7932751903489965],
					[58.818569344662443, 1.7932751913234153],
					[58.818588827399715, 1.7932807865875158],
					[58.818627711382334, 1.793300179209772],
					[58.818627715042517, 1.793300180184181],
					[58.818627724337595, 1.7933001851967718],
					[58.818627727997793, 1.7933001861711808],
					[58.818663810157105, 1.7933140791253046],
					[58.81868599382733, 1.7933279704623757],
					[58.81868599471828, 1.7933279702765501],
					[58.818685996596564, 1.7933279716226149],
					[58.818685996596564, 1.7933279716226149],
					[58.818713797346881, 1.7933445719776073],
					[58.818713801103478, 1.7933445746697305],
					[58.818713806641981, 1.7933445769902234],
					[58.818713810398542, 1.7933445796823484],
					[58.818730498052147, 1.7933529719391361],
					[58.818766586398333, 1.7933779631216449],
					[58.81879428466614, 1.7933973636944052],
					[58.81879428842273, 1.7933973663865332],
					[58.818794293961211, 1.7933973687070421],
					[58.818794296826852, 1.7933973715849987],
					[58.818808186667184, 1.7934057652422644],
					[58.818833172050802, 1.793425154715194],
					[58.818858165986654, 1.7934473472731001],
					[58.818897064331878, 1.7934834463757434],
					[58.818897065319213, 1.79348344790764],
					[58.818897068184839, 1.793483450785627],
					[58.818897070063109, 1.7934834521317045],
					[58.818916563526905, 1.7935001465301519],
					[58.818958158133306, 1.7935418405215187],
					[58.818958159024255, 1.7935418403356995],
					[58.818958159120641, 1.7935418420534377],
					[58.818977644968669, 1.7935612282544291],
					[58.818988733239486, 1.7935779101038247],
					[58.818988736201504, 1.7935779146995323],
					[58.818988741041807, 1.7935779206413396],
					[58.818988744003811, 1.7935779252370503],
					[58.819033144038222, 1.7936334252582693],
					[58.819033145025543, 1.7936334267901755],
					[58.819033146903841, 1.7936334281362658],
					[58.819033147891169, 1.7936334296681671],
					[58.819080416970635, 1.7936889935718081],
					[58.819083105818613, 1.7937000467139557],
					[58.819083116270221, 1.7937000723394796],
					[58.819083142422826, 1.7937001207578698],
					[58.819083158918367, 1.7937001416472216],
					[58.819094247870368, 1.7937112293644204],
					[58.819138733962554, 1.7937723128007632],
					[58.819172021302869, 1.7938250911875655],
					[58.819183114009967, 1.7938528735790324],
					[58.819183118152068, 1.7938528831421907],
					[58.819183127230808, 1.7938529003649659],
					[58.81918313127651, 1.7938529082103956],
					[58.819202631324984, 1.7938834086805306],
					[58.819202632215941, 1.7938834084947211],
					[58.81920263320329, 1.7938834100266559],
					[58.81920263419061, 1.7938834115585811],
					[58.819235929247618, 1.7939334045130424],
					[58.819263724959939, 1.7939833974868573],
					[58.819263725850888, 1.7939833973010462],
					[58.819263726934608, 1.7939834005507285],
					[58.819283127285267, 1.7940167998152707],
					[58.819283127381631, 1.7940168015330089],
					[58.819302620621578, 1.7940500893554523],
					[58.819324816061837, 1.7941028788033839],
					[58.819324816061837, 1.7941028788033839],
					[58.819324817049186, 1.7941028803353172],
					[58.819324818036506, 1.7941028818672504],
					[58.819349818264193, 1.7941583813982744],
					[58.819349818360571, 1.7941583831160142],
					[58.819349820431597, 1.7941583878976282],
					[58.819349821418932, 1.7941583894295789],
					[58.819366521199164, 1.7941917890012959],
					[58.819366524161168, 1.7941917935971086],
					[58.819366529194177, 1.7941918029745509],
					[58.819366531265217, 1.7941918077561874],
					[58.819385924083676, 1.7942222944297568],
					[58.819410918390673, 1.7942778822627892],
					[58.819410919474365, 1.7942778855124795],
					[58.819410920654441, 1.7942778904799239],
					[58.819410923423682, 1.7942778916402617],
					[58.819447015385848, 1.7943472785348946],
					[58.819455410096978, 1.7943722646797817],
					[58.819455413058968, 1.7943722692756228],
					[58.819455418284704, 1.7943722820886305],
					[58.819455421343079, 1.7943722884022144],
					[58.81946651772413, 1.7943944832236352],
					[58.81953311559748, 1.7945500788147031],
					[58.819533124579785, 1.7945500943200772],
					[58.81953314620457, 1.7945501263053938],
					[58.819533158750694, 1.7945501410675742],
					[58.819544230444016, 1.7945612119274816],
					[58.819572013927541, 1.7946306740615037],
					[58.819572015011232, 1.7946306773112164],
					[58.819572016985894, 1.7946306803751368],
					[58.819572018069572, 1.7946306836248671],
					[58.819619217874205, 1.7947333843440527],
					[58.819619219848832, 1.7947333874079829],
					[58.819619223894499, 1.7947333952536144],
					[58.81961922685646, 1.7947333998495207],
					[58.819630422076024, 1.7947528910180912],
					[58.81966651729504, 1.7948333795640379],
					[58.819697016076717, 1.7949056786485995],
					[58.819697021109697, 1.7949056880262391],
					[58.819697031175622, 1.7949057067815375],
					[58.819697037099559, 1.7949057159733803],
					[58.819710915194996, 1.7949250857210921],
					[58.819716505871561, 1.7949472489524327],
					[58.819716510206291, 1.794947261951398],
					[58.819716520657693, 1.7949472875777694],
					[58.819716526774343, 1.7949473002051743],
					[58.819755418916714, 1.7950139877163473],
					[58.819777612893716, 1.7950722703600128],
					[58.819777617035726, 1.7950722799234828],
					[58.819777626114316, 1.7950722971468702],
					[58.819777631147289, 1.795072306524556],
					[58.81979703003757, 1.7951029057076424],
					[58.819813722062875, 1.795130692695073],
					[58.819838717639051, 1.795186182478985],
					[58.819838720697369, 1.7951861887927281],
					[58.819838726717649, 1.795186199702433],
					[58.819838729679596, 1.7951862042984092],
					[58.819874821195711, 1.7952444897585307],
					[58.819885913712824, 1.7952722748936125],
					[58.819885915687451, 1.7952722779576016],
					[58.819885918842125, 1.7952722859891366],
					[58.819885920816759, 1.7952722890531287],
					[58.819899821015092, 1.7953000883145918],
					[58.819899824073396, 1.7953000946283475],
					[58.819899829106355, 1.7953001040060936],
					[58.819899832068295, 1.7953001086020874],
					[58.81993592761885, 1.795355703207044],
					[58.819966525354964, 1.7954111965149453],
					[58.819966527329605, 1.7954111995789344],
					[58.819966531375229, 1.7954112074247202],
					[58.819966533349842, 1.7954112104887394],
					[58.819983132954768, 1.7954362103524035],
					[58.819983135916715, 1.795436214948402],
					[58.819983142731566, 1.7954362239546389],
					[58.819983145693513, 1.7954362285506533],
					[58.819997034422194, 1.7954529131585326],
					[58.820030425308694, 1.7955139954274577],
					[58.820030427379663, 1.7955140002092467],
					[58.820030433303572, 1.795514009401282],
					[58.820030436361854, 1.7955140157150948],
					[58.820083127846701, 1.7955890033689315],
					[58.820085921680267, 1.7955944912301356],
					[58.820085923654908, 1.7955944942941531],
					[58.820085928687817, 1.7955945036719965],
					[58.820085931649764, 1.7955945082680409],
					[58.820110932051477, 1.7956334079792631],
					[58.820130430872993, 1.7956640077604273],
					[58.820130433738598, 1.7956640106386719],
					[58.820130437784186, 1.7956640184845294],
					[58.820130440649777, 1.7956640213627961],
					[58.820174840674625, 1.7957223201998138],
					[58.820174844623871, 1.7957223263278983],
					[58.820174854207934, 1.7957223364947068],
					[58.820174858951788, 1.7957223407192275],
					[58.820185952589419, 1.7957334353637953],
					[58.820202648086543, 1.7957529307689315],
					[58.820202648977492, 1.7957529305831648],
					[58.820202649073849, 1.7957529323009522],
					[58.820202649964813, 1.7957529321151791],
					[58.820244244776433, 1.7958001253232336],
					[58.820269239887992, 1.7958334200238746],
					[58.820294239978395, 1.7958668197434253],
					[58.820294244818598, 1.7958668256857717],
					[58.82029425726823, 1.7958668387309378],
					[58.82029426210844, 1.7958668446732857],
					[58.820349837411996, 1.7959195206194405],
					[58.820355421396762, 1.7959306884101551],
					[58.82035543620642, 1.795930711390652],
					[58.820355469004156, 1.7959307497373609],
					[58.820355488870511, 1.7959307664498287],
					[58.820372075300128, 1.7959418577372797],
					[58.820416557946679, 1.7959834406457036],
					[58.82044985312762, 1.7960195357379569],
					[58.820449857967851, 1.7960195416803304],
					[58.8204498693338, 1.7960195514757868],
					[58.820449874968595, 1.7960195555145966],
					[58.820477669340818, 1.7960418516832173],
					[58.820524863254981, 1.7960862462698544],
					[58.82052487363363, 1.796086254533287],
					[58.820524898051119, 1.7960862720349013],
					[58.820524911102652, 1.7960862797410337],
					[58.820541550112544, 1.7960945482636865],
					[58.820544219650422, 1.7961000864796806],
					[58.820544232581796, 1.7961001081140824],
					[58.820544265379482, 1.796100146461149],
					[58.82054428435486, 1.7961001633595681],
					[58.820602680736947, 1.7961418609004609],
					[58.820627676843706, 1.7961612579314337],
					[58.82062768238216, 1.7961612602524848],
					[58.820627690882532, 1.7961612671696663],
					[58.820627694639086, 1.7961612698622413],
					[58.820663795192786, 1.7961834697803611],
					[58.820685994233052, 1.7961973691188997],
					[58.820685995220344, 1.7961973706509697],
					[58.820685997989592, 1.7961973718114874],
					[58.820685998976899, 1.7961973733435415],
					[58.820719298007027, 1.7962168727979626],
					[58.820733196747348, 1.796225172275709],
					[58.820733202285822, 1.7962251745967759],
					[58.820733209702546, 1.7962251782641161],
					[58.820733214350021, 1.7962251807709531],
					[58.820786003897183, 1.7962501767819969],
					[58.820808193540699, 1.7962640694756127],
					[58.82080820837411, 1.7962640768103504],
					[58.820808236066384, 1.7962640884156951],
					[58.820808250803474, 1.7962640940326053],
					[58.82081934140561, 1.7962668911259254],
					[58.820880508672822, 1.796289080382492],
					[58.820897089346936, 1.796300166236789],
					[58.820897101411148, 1.7963001724110153],
					[58.820897128308793, 1.796300185920003],
					[58.82089714116762, 1.7963001901906228],
					[58.820933226457761, 1.79631128653909],
					[58.820949910944755, 1.796319578790325],
					[58.820949920239748, 1.7963195838040227],
					[58.820949938637007, 1.7963195903957556],
					[58.820949947835651, 1.796319593691607],
					[58.820991647298669, 1.796330793426353],
					[58.821030533075515, 1.7963418881383197],
					[58.821049920958643, 1.7963501833351652],
					[58.82104992827901, 1.796350185284745],
					[58.821049941234186, 1.7963501912732491],
					[58.821049947663589, 1.7963501934085766],
					[58.821080548070498, 1.796358493551415],
					[58.821080550839717, 1.7963584947119628],
					[58.821080559051047, 1.7963584964757664],
					[58.821080561723932, 1.796358495918476],
					[58.821152734749177, 1.7963723914534577],
					[58.821169310032069, 1.7963807778026657],
					[58.821169322987231, 1.7963807837911987],
					[58.821169349692184, 1.7963807938646403],
					[58.821169363345618, 1.7963807962317315],
					[58.821258256191634, 1.7963973945704954],
					[58.821299949075964, 1.7964084933039939],
					[58.821299956396345, 1.7964084952535959],
					[58.821299970049779, 1.796408497620684],
					[58.821299978261116, 1.7964084993845162],
					[58.821349970837694, 1.7964140980744876],
					[58.821441663618323, 1.7964307975179816],
					[58.8214416662912, 1.7964307969606912],
					[58.821441669951405, 1.7964307979354821],
					[58.821441671733318, 1.7964307975639597],
					[58.821461063094333, 1.7964334977169645],
					[58.821530552000659, 1.7964501933554495],
					[58.821563852045742, 1.796458493894318],
					[58.821563852936706, 1.7964584937085513],
					[58.821563856596896, 1.7964584946833515],
					[58.821563859366108, 1.7964584958439265],
					[58.82161665809808, 1.7964695941166118],
					[58.821666648372243, 1.7964807930885462],
					[58.821711040837442, 1.7964945913047694],
					[58.821711041728392, 1.796494591119006],
					[58.821711046279567, 1.7964945919080475],
					[58.821711047266859, 1.7964945934401557],
					[58.821772132633484, 1.7965112895499737],
					[58.821797119095692, 1.7965223837209712],
					[58.821797123646832, 1.7965223845100382],
					[58.821797132941803, 1.7965223895238851],
					[58.821797138383921, 1.7965223901271823],
					[58.821891637961826, 1.7965529907320243],
					[58.821891639743754, 1.7965529903604927],
					[58.821891644294901, 1.796552991149547],
					[58.821891645282193, 1.796552992681663],
					[58.821911036633864, 1.7965584893133184],
					[58.821983213794653, 1.7965862814349387],
					[58.822002700821272, 1.7965973735401468],
					[58.822002703686827, 1.7965973764186409],
					[58.822002710116251, 1.7965973785540525],
					[58.822002713776435, 1.7965973795288814],
					[58.82206660682256, 1.7966279765599729],
					[58.822124901402994, 1.7966612738606091],
					[58.822124906050497, 1.7966612763675827],
					[58.822124916236426, 1.7966612811957601],
					[58.822124920883901, 1.7966612837027258],
					[58.822163807900459, 1.7966779785707661],
					[58.822235994749327, 1.7967223703168134],
					[58.822235996627583, 1.7967223716632026],
					[58.822236000287781, 1.7967223726380457],
					[58.822236001275073, 1.796722374170181],
					[58.822255494818741, 1.7967334702766746],
					[58.822338787622115, 1.7967890665173825],
					[58.822399886953548, 1.7968307649607196],
					[58.822399887844512, 1.7968307647749626],
					[58.822399888831804, 1.7968307663071217],
					[58.82243318486681, 1.7968529634612804],
					[58.822474877408183, 1.7968834573908099],
					[58.822522071689768, 1.7969223528789233],
					[58.82255817080209, 1.7969529529699579],
					[58.822558171693053, 1.7969529527842008],
					[58.82255817268036, 1.7969529543163647],
					[58.822558173667652, 1.7969529558485222],
					[58.8226165697642, 1.7970001526075197],
					[58.822674862290199, 1.7970529448975157],
					[58.822699859437428, 1.797077941531678],
					[58.82269986042472, 1.7970779430638433],
					[58.822699863193968, 1.7970779442244851],
					[58.82269986427761, 1.7970779474745873],
					[58.822738761184127, 1.7971140441144127],
					[58.822797058613595, 1.7971723417641674],
					[58.82284425398565, 1.79721953676074],
					[58.822885950247631, 1.7972668320825],
					[58.822924847375759, 1.7973112281012074],
					[58.82294704406285, 1.797339024912511],
					[58.822947048903004, 1.7973390308554997],
					[58.822947059474252, 1.7973390425557236],
					[58.822947064218077, 1.7973390467807582],
					[58.822983138370915, 1.797372322444305],
					[58.822991521052728, 1.7973889891316133],
					[58.822991526085559, 1.7973889985104821],
					[58.82299153497123, 1.7973890123001666],
					[58.822991539907704, 1.7973890199611067],
					[58.823033140028691, 1.7974445200672575],
					[58.823033142003268, 1.7974445231316363],
					[58.82303314585613, 1.7974445275424422],
					[58.823033147830721, 1.7974445306068383],
					[58.82306654261189, 1.7974834244458466],
					[58.823088738251464, 1.79751401643594],
					[58.823088738347806, 1.797514018153896],
					[58.82308873923877, 1.7975140179681501],
					[58.823119235405052, 1.797555713100824],
					[58.823138731337806, 1.7975862075606965],
					[58.823138734203347, 1.7975862104393578],
					[58.823138738152537, 1.7975862165681546],
					[58.823138740127121, 1.7975862196325476],
					[58.823155439071733, 1.7976084193800466],
					[58.823191536725396, 1.7976584156800282],
					[58.823238715153849, 1.7977250848065502],
					[58.823241506100381, 1.7977361495273305],
					[58.823241513396738, 1.7977361671245922],
					[58.823241529867744, 1.7977362036655713],
					[58.823241539836992, 1.7977362207055998],
					[58.823249835226271, 1.7977473127992991],
					[58.823291529083889, 1.7978140045033035],
					[58.823338725419433, 1.7978944981174274],
					[58.823383122409773, 1.7979778923274168],
					[58.823405421346202, 1.7980222894741984],
					[58.823405422333472, 1.7980222910064194],
					[58.823405423320779, 1.7980222925386533],
					[58.8234054234171, 1.798022294256614],
					[58.823427623361219, 1.7980639937955616],
					[58.823427631259555, 1.7980640060533477],
					[58.823427648838084, 1.7980640301974837],
					[58.823427659312962, 1.7980640401801078],
					[58.823441535343733, 1.7980779178594573],
					[58.823519219662494, 1.7982361883693236],
					[58.823519220649771, 1.7982361899015575],
					[58.823519220649771, 1.7982361899015575],
					[58.823524821196528, 1.7982472877938294],
					[58.823599813863254, 1.7984028751844332],
					[58.82361091028352, 1.7984361625418412],
					[58.823610912354432, 1.7984361673242903],
					[58.823610914617966, 1.7984361755426956],
					[58.82361091668885, 1.7984361803251685],
					[58.823672017197929, 1.798575081691522],
					[58.823672019172506, 1.798575084756012],
					[58.823672024205237, 1.7985750941352434],
					[58.8236720261798, 1.7985750971997381],
					[58.823708119024701, 1.7986389867173225],
					[58.823749813305547, 1.7987472719207451],
					[58.823749814389139, 1.7987472751709794],
					[58.823749817350972, 1.7987472797677651],
					[58.823749818434571, 1.7987472830179947],
					[58.823794218081808, 1.798844482327066],
					[58.8237942191654, 1.7988444855773067],
					[58.823794220152699, 1.7988444871095801],
					[58.823794221236284, 1.7988444903598255],
					[58.823830420149207, 1.7989167861100446],
					[58.82389151859465, 1.7990472842667296],
					[58.823891518690971, 1.7990472859847331],
					[58.823891519678241, 1.7990472875170083],
					[58.823891520665512, 1.7990472890492788],
					[58.823910918304783, 1.7990861829207703],
					[58.823977614856666, 1.7992472762522522],
					[58.823977623934788, 1.7992472934787851],
					[58.82397764555855, 1.7992473254710226],
					[58.823977659091469, 1.7992473417690085],
					[58.82398873696355, 1.7992584193328145],
					[58.824055418126378, 1.7993861837838803],
					[58.824074814818552, 1.7994333757685392],
					[58.824074816889386, 1.7994333805511202],
					[58.824074820934804, 1.7994333883982883],
					[58.824074823005667, 1.799433393180877],
					[58.824160921608254, 1.7995972919492949],
					[58.824166521155078, 1.7996083904546585],
					[58.824166522046056, 1.799608390268957],
					[58.824166523129634, 1.7996083935192548],
					[58.824166524116904, 1.7996083950515571],
					[58.824255423994586, 1.7997722947886463],
					[58.824255425969127, 1.7997722978532587],
					[58.82425542893094, 1.7997723024501953],
					[58.824255430014503, 1.7997723057005124],
					[58.82426922867252, 1.799794503296813],
					[58.824347027075035, 1.7999279009190561],
					[58.824347029049562, 1.7999279039837035],
					[58.824347032011374, 1.7999279085806514],
					[58.824347033985923, 1.7999279116452844],
					[58.824352633391953, 1.7999362102309868],
					[58.824435930797883, 1.8000668077148358],
					[58.824435932772424, 1.8000668107794799],
					[58.824435936721478, 1.8000668169087921],
					[58.824435938599713, 1.8000668182554247],
					[58.824466538379632, 1.8001084172710826],
					[58.824530438007976, 1.8001973168727445],
					[58.824530438104262, 1.8001973185907656],
					[58.824530438995225, 1.8001973184050752],
					[58.824530439982496, 1.8001973199374093],
					[58.824588739075367, 1.8002751195794278],
					[58.824619238885404, 1.8003168179948841],
					[58.824619239872675, 1.8003168195272214],
					[58.824619241847188, 1.8003168225919053],
					[58.824619242834459, 1.8003168241242582],
					[58.824683142910267, 1.8003973241265057],
					[58.824683144884808, 1.8003973271911944],
					[58.82468314962852, 1.8003973314168713],
					[58.824683151603054, 1.80039733448156],
					[58.824710942065614, 1.8004279246576003],
					[58.824722033855579, 1.8004445114324032],
					[58.824722037804634, 1.8004445175617885],
					[58.824722046401114, 1.8004445261988344],
					[58.824722050350161, 1.8004445323282325],
					[58.824802649610419, 1.8005362326179186],
					[58.824802652379624, 1.8005362337789037],
					[58.824802656232357, 1.8005362381902981],
					[58.824802659097855, 1.8005362410693155],
					[58.824877644209458, 1.8006112266128318],
					[58.824888733352267, 1.8006279114057224],
					[58.824888739179528, 1.8006279188818579],
					[58.824888751725048, 1.8006279336484243],
					[58.824888759334243, 1.8006279407531904],
					[58.824908159038159, 1.8006473414317996],
					[58.824908161012686, 1.8006473444965423],
					[58.824908166647376, 1.8006473485365768],
					[58.824908170403823, 1.8006473512299532],
					[58.824985966565713, 1.800714049794546],
					[58.825055454956697, 1.800777938285921],
					[58.82506924555986, 1.8007945277786848],
					[58.825069252181798, 1.8007945333511532],
					[58.825069263740026, 1.8007945465854969],
					[58.825069270361965, 1.8007945521579496],
					[58.825088770143651, 1.8008112519812656],
					[58.825088771130908, 1.80081125351366],
					[58.825088773009142, 1.8008112548603514],
					[58.825088773900106, 1.8008112546746737],
					[58.825163773856055, 1.8008723558713913],
					[58.825163776721539, 1.8008723587504676],
					[58.825163783247184, 1.8008723626049119],
					[58.825163787003639, 1.8008723652983152],
					[58.825252686975453, 1.8009334657266156],
					[58.825252695283041, 1.8009334692097221],
					[58.82525271209078, 1.8009334796120426],
					[58.825252721385638, 1.8009334846275309],
					[58.825272098806586, 1.8009418740333458],
					[58.825347080635026, 1.8009973619290547],
					[58.825347082416968, 1.8009973615577091],
					[58.825388782285629, 1.8010279602359589],
					[58.825388787920325, 1.8010279642761251],
					[58.825388800080638, 1.801027972170766],
					[58.82538880571532, 1.8010279762109149],
					[58.825435999204672, 1.8010529735202088],
					[58.825522091713424, 1.8011084686307803],
					[58.825522096360828, 1.8011084711385601],
					[58.825522103873745, 1.8011084765254799],
					[58.825522109315848, 1.8011084771295118],
					[58.825619308923621, 1.8011584787643731],
					[58.825619309814599, 1.8011584785787034],
					[58.825619313571046, 1.8011584812721642],
					[58.82561931634023, 1.8011584824332287],
					[58.825655501884107, 1.8011751748210472],
					[58.825705489254773, 1.8012084652695806],
					[58.825705495876697, 1.8012084708422242],
					[58.825705508927989, 1.8012084785513225],
					[58.825705516248306, 1.8012084805021078],
					[58.825783207776993, 1.8012445772025021],
					[58.82580269541409, 1.8012557701622467],
					[58.82582768862374, 1.8012723669261845],
					[58.825827696040363, 1.8012723705950895],
					[58.82582770820067, 1.8012723784899132],
					[58.825827714630016, 1.8012723806263888],
					[58.825874893163707, 1.8012945710489277],
					[58.825888774676933, 1.8013057556034537],
					[58.82588877566419, 1.8013057571358784],
					[58.825888779324345, 1.8013057581112815],
					[58.825888780311587, 1.8013057596437043],
					[58.82591098034068, 1.8013223597499233],
					[58.825910987853597, 1.8013223651369401],
					[58.82591100278308, 1.8013223741928777],
					[58.825911011186932, 1.8013223793942297],
					[58.82593879860864, 1.8013362733997542],
					[58.825972088866621, 1.8013584658674517],
					[58.825972093610332, 1.8013584700934038],
					[58.825972104783368, 1.8013584764558634],
					[58.825972110321771, 1.8013584787780277],
					[58.826010999241291, 1.8013779732459656],
					[58.826052686794043, 1.801405764849475],
					[58.826072082282877, 1.8014195600985032],
					[58.826094279769862, 1.8014362599611229],
					[58.826094280757118, 1.8014362614935524],
					[58.82609428441728, 1.8014362624689824],
					[58.826094285404523, 1.8014362640014165],
					[58.826141562792458, 1.8014695480709166],
					[58.826155445811828, 1.8014862285657378],
					[58.826155450651804, 1.8014862345098384],
					[58.826155462017404, 1.8014862443085737],
					[58.826155467748379, 1.8014862500669966],
					[58.826202667764186, 1.8015279492490766],
					[58.826202668751442, 1.8015279507815123],
					[58.826244269284793, 1.8015640502072792],
					[58.826244273137526, 1.8015640546189471],
					[58.826244279759436, 1.8015640601917353],
					[58.826244284406826, 1.8015640626996234],
					[58.826263765243958, 1.8015779498239621],
					[58.826285950078372, 1.8016029331989536],
					[58.826285951065628, 1.8016029347314146],
					[58.826285951956599, 1.8016029345457434],
					[58.826285952943856, 1.8016029360781936],
					[58.826319250843554, 1.8016390346001407],
					[58.826338750266068, 1.8016612316502971],
					[58.826338751253317, 1.8016612331827504],
					[58.826338753131537, 1.8016612345295355],
					[58.826338754118801, 1.8016612360619808],
					[58.826377643765085, 1.801702925776677],
					[58.826427630830679, 1.8017751078640367],
					[58.826449826061406, 1.8018139982904182],
					[58.826449827144934, 1.8018140015409991],
					[58.82644982902314, 1.8018140028878018],
					[58.826449830010397, 1.80181400442026],
					[58.826472030282851, 1.8018501057873992],
					[58.826472030282851, 1.8018501057873992],
					[58.826472031270107, 1.801850107319859],
					[58.826472032161078, 1.8018501071341941],
					[58.826527619390035, 1.8019361875617148],
					[58.82654981131779, 1.802000065410283],
					[58.82654981565188, 1.802000078412707],
					[58.826549828774944, 1.8020001034892417],
					[58.826549835685647, 1.8020001142165545],
					[58.82656922052896, 1.8020278924842941],
					[58.826599812240644, 1.8021111687760059],
					[58.826599813227887, 1.8021111703084829],
					[58.826599814311415, 1.8021111735591036],
					[58.826599814407686, 1.8021111752772345],
					[58.826605408414096, 1.802125058924785],
					[58.826610905081409, 1.8021500421214378],
					[58.826610906261202, 1.8021500470901941],
					[58.826610906646295, 1.8021500539627255],
					[58.826610907729815, 1.8021500572133526],
					[58.826635907642135, 1.8022333568793503],
					[58.826635909712905, 1.8022333616624402],
					[58.826635912867204, 1.8022333696961603],
					[58.826635914046982, 1.8022333746649295],
					[58.826702612432754, 1.8024000686358306],
					[58.826919209541387, 1.8030583625247398],
					[58.826919214766356, 1.8030583753417933],
					[58.826919227985464, 1.8030584021371601],
					[58.826919235883352, 1.8030584143973238],
					[58.827113735858894, 1.8033390139471259],
					[58.827113739807821, 1.8033390200772554],
					[58.827113749391366, 1.8033390302481349],
					[58.827113753340292, 1.8033390363782678],
					[58.827349852680001, 1.803594535530884],
					[58.827349861180053, 1.8035945424511508],
					[58.827349876398181, 1.803594556662949],
					[58.827349884898219, 1.8035945635832125],
					[58.827927685293098, 1.8040029637870114],
					[58.827927692805886, 1.8040029691748902],
					[58.827927711395375, 1.804002979208214],
					[58.827927719702899, 1.8040029826922699],
					[58.828438820118336, 1.8042251830188496],
					[58.828438837624326, 1.8042251898015573],
					[58.828438874129539, 1.8042251978410486],
					[58.82843889223777, 1.8042251992834333],
					[58.828780592272949, 1.8042391007498888],
					[58.828780619098417, 1.8042390968996127],
					[58.82878067156966, 1.8042390842299367],
					[58.8287806972154, 1.8042390754105193],
					[58.829188997201612, 1.8040112755729207],
					[58.829189011072188, 1.804011265729818],
					[58.829189036044134, 1.8040112448822458],
					[58.829189048036518, 1.8040112336921288],
					[58.829425138966492, 1.8037501445917314],
					[58.829975127616443, 1.8032890527162169],
					[58.829975133756975, 1.8032890496983476],
					[58.829975143967317, 1.8032890388790259],
					[58.829975149120628, 1.8032890343285],
					[58.830227948044175, 1.8030084347261406],
					[58.830464040075775, 1.8027473437422379],
					[58.830525131233216, 1.8026945517699937],
					[58.830525142334487, 1.8026945407646289],
					[58.830525161767838, 1.8026945175925879],
					[58.830525170894617, 1.8026945035219077],
					[58.83056407095809, 1.8026307041584229],
					[58.830564079699784, 1.8026306832143781],
					[58.830564095208651, 1.802630638260978],
					[58.830564099399226, 1.8026306165269423],
					[58.830577998833739, 1.8024639161794822],
					[58.830578000423138, 1.8024639123714725],
					[58.83057799994176, 1.8024639037798098],
					[58.830577999749231, 1.8024639003431457],
					[58.830577999830894, 1.8022638996075149],
					[58.830578000433022, 1.8022638942668276],
					[58.830577998771844, 1.8022638807058664],
					[58.830577997592037, 1.8022638757365439],
					[58.830566897983651, 1.8021749753113552],
					[58.830566896611309, 1.8021749669053846],
					[58.83056689297559, 1.8021749502791289],
					[58.830566890615984, 1.8021749403405076],
					[58.83052519115919, 1.8020388416651121],
					[58.830525189979369, 1.8020388366958244],
					[58.830525186921342, 1.8020388303795689],
					[58.830525185741521, 1.8020388254102671],
					[58.830436288322829, 1.8018166306690386],
					[58.83039458969585, 1.8016916375306706],
					[58.830394583387168, 1.801691621461637],
					[58.830394566315022, 1.8016915902520259],
					[58.830394557429734, 1.8016915764584056],
					[58.830319561989896, 1.8015970837061392],
					[58.830233477774641, 1.8014666077221522],
					[58.830197388216639, 1.8013749308128602],
					[58.830169589310039, 1.8012943344378023],
					[58.830169588226511, 1.8012943311868905],
					[58.830169585072142, 1.8012943231524643],
					[58.830169584084885, 1.8012943216198631],
					[58.830108484116614, 1.8011527202170072],
					[58.830108483225651, 1.8011527204027133],
					[58.830108482142087, 1.8011527171518176],
					[58.830108482142087, 1.8011527171518176],
					[58.830094584756282, 1.8011221240409365],
					[58.830066887830796, 1.8010471304620637],
					[58.830066886747233, 1.8010471272111759],
					[58.830066884772705, 1.8010471241459782],
					[58.830066883785435, 1.8010471226133946],
					[58.830027984034608, 1.800955521202187],
					[58.830027984034608, 1.800955521202187],
					[58.830027982951059, 1.8009555179513024],
					[58.830027981963802, 1.8009555164187154],
					[58.830014086412298, 1.8009249248063017],
					[58.829964088712238, 1.8007832355587265],
					[58.82992519035583, 1.8006638379215694],
					[58.82992518828501, 1.8006638331381137],
					[58.829925184047049, 1.8006638218529381],
					[58.829925181976215, 1.8006638170694855],
					[58.829911290404851, 1.8006332335159803],
					[58.829905793701677, 1.8006110521575134],
					[58.82990579360537, 1.8006110504392252],
					[58.8299057924255, 1.8006110454700586],
					[58.829905792329221, 1.8006110437517735],
					[58.829880792413036, 1.800527742606937],
					[58.829880791425765, 1.8005277410743741],
					[58.829880790245909, 1.8005277361052121],
					[58.829880789258638, 1.8005277345726491],
					[58.82985019311031, 1.8004388448013406],
					[58.829844594932851, 1.80041385634482],
					[58.829844593752966, 1.8004138513756869],
					[58.82984459139324, 1.8004138414374076],
					[58.829844590213369, 1.800413836468252],
					[58.829825192196822, 1.800355544106063],
					[58.829811294267408, 1.800299952279192],
					[58.829802993949251, 1.8002666528139435],
					[58.829783495983342, 1.8001832586542579],
					[58.829766895862505, 1.8000971622965249],
					[58.829766895766213, 1.800097160578243],
					[58.829766895669906, 1.800097158859961],
					[58.829766894682621, 1.8000971573274107],
					[58.829758496090427, 1.8000582617954026],
					[58.829739096620941, 1.7999443669404336],
					[58.829736296758618, 1.7999277669446849],
					[58.829736292135387, 1.7999277487865308],
					[58.829736280312297, 1.7999277147457109],
					[58.829736272125196, 1.7999276973304978],
					[58.82972799086015, 1.7999138288781507],
					[58.829708496733502, 1.799802766581283],
					[58.829700196755844, 1.7997527668559392],
					[58.829686297863852, 1.7996693741123653],
					[58.829677999219264, 1.799583280690755],
					[58.829677999026629, 1.7995832772542071],
					[58.82967799784673, 1.7995832722851119],
					[58.829677997750423, 1.7995832705668395],
					[58.829655797949989, 1.7994332702792508],
					[58.82965579696269, 1.7994332687467323],
					[58.829655796673762, 1.799433263591915],
					[58.829655795590156, 1.7994332603410987],
					[58.829650196847538, 1.7994055639223594],
					[58.829639097778433, 1.7993360716622633],
					[58.829625197846958, 1.7992332724353977],
					[58.82962519775063, 1.7992332707171284],
					[58.829625196570717, 1.7992332657480541],
					[58.82962519736536, 1.7992332638440325],
					[58.829619596659789, 1.799202767398417],
					[58.82960019890708, 1.7990638807259001],
					[58.829594600255575, 1.7989749874247627],
					[58.829594598979334, 1.7989749807374509],
					[58.829594597317815, 1.7989749671770336],
					[58.829594596041574, 1.7989749604897074],
					[58.829577997060724, 1.7988916653192457],
					[58.829555798393002, 1.7987388702965146],
					[58.829552998180979, 1.7987193727074713],
					[58.829547397790876, 1.7986777734814341],
					[58.829547396610927, 1.7986777685123931],
					[58.829547396032993, 1.7986777582027889],
					[58.82954739494938, 1.7986777549520314],
					[58.829516895767817, 1.7985471581836003],
					[58.829502996539603, 1.7984749647734246],
					[58.829486297286721, 1.7983749662803035],
					[58.829486297094064, 1.7983749628437715],
					[58.829486294926838, 1.7983749563422549],
					[58.829486293746889, 1.7983749513732505],
					[58.829452994301086, 1.7982388512581116],
					[58.829452992133831, 1.7982388447566204],
					[58.829452986715715, 1.7982388285028952],
					[58.82945298356119, 1.7982388204689157],
					[58.829436293768445, 1.798199945693066],
					[58.829430798196533, 1.7981610713987137],
					[58.829430796920242, 1.798161064711453],
					[58.82943079328404, 1.7981610480861878],
					[58.829430791116799, 1.7981610415847191],
					[58.829391891422532, 1.7980332420376937],
					[58.829391890338904, 1.7980332387869504],
					[58.829391887184372, 1.7980332307530205],
					[58.829391886004409, 1.7980332257840239],
					[58.829358488260077, 1.797949929843464],
					[58.829327989082778, 1.7978610353868136],
					[58.829327987011844, 1.7978610306036362],
					[58.829327982773648, 1.7978610193190077],
					[58.829327980702743, 1.797861014535808],
					[58.829266880668023, 1.7977305144204661],
					[58.829266877706125, 1.7977305098230902],
					[58.829266872576959, 1.7977304987242697],
					[58.829266869615076, 1.7977304941268955],
					[58.829252984275186, 1.7977082184440591],
					[58.829247392044245, 1.7976888443761585],
					[58.829247387709707, 1.7976888313733355],
					[58.829247377355031, 1.7976888074574915],
					[58.82924737133493, 1.7976887965444868],
					[58.829208475855282, 1.7976249054182798],
					[58.829177980225722, 1.7975610133144941],
					[58.82917797923843, 1.7975610117820282],
					[58.829177975192877, 1.7975610039339747],
					[58.829177974109243, 1.7975610006832823],
					[58.829166873947884, 1.7975416018713628],
					[58.829166869998687, 1.797541595741553],
					[58.829166862100301, 1.7975415834819428],
					[58.829166858151112, 1.7975415773521313],
					[58.829147366354938, 1.7975165874851746],
					[58.829116873113534, 1.7974637995401648],
					[58.829116870151651, 1.7974637949428081],
					[58.829116863240522, 1.7974637842156862],
					[58.829116860278624, 1.7974637796183535],
					[58.829083465392415, 1.7974192854821167],
					[58.829064068866963, 1.7973887931830113],
					[58.829064066892371, 1.7973887901181269],
					[58.829064063930467, 1.7973887855207942],
					[58.829064062943168, 1.7973887839883536],
					[58.829036262843296, 1.7973498836331043],
					[58.829036258003121, 1.7973498776891357],
					[58.829036247335473, 1.7973498642687693],
					[58.829036241604371, 1.7973498585105898],
					[58.829005764181467, 1.7973192816324948],
					[58.82899457885339, 1.7972971107349232],
					[58.828994572833224, 1.7972970998220521],
					[58.828994560985606, 1.7972970814328038],
					[58.828994554170826, 1.7972970724239812],
					[58.828955754280493, 1.7972498727299424],
					[58.828955754280493, 1.7972498727299424],
					[58.82895575230588, 1.7972498696650629],
					[58.828955751318581, 1.7972498681326319],
					[58.828900150810497, 1.7971859693405692],
					[58.828900149823198, 1.7971859678081255],
					[58.828900148835906, 1.7971859662756928],
					[58.8289001478486, 1.7971859647432666],
					[58.82884184954969, 1.7971220672661996],
					[58.828811252733793, 1.7970859704115389],
					[58.828811251842829, 1.7970859705973405],
					[58.828811249868203, 1.7970859675324897],
					[58.828811249868203, 1.7970859675324897],
					[58.828789049659335, 1.7970609675872793],
					[58.828747349946468, 1.7970137682138323],
					[58.82874734411898, 1.7970137607375458],
					[58.828747330874791, 1.7970137495930141],
					[58.828747325143638, 1.7970137438349427],
					[58.828719534220966, 1.7969915508455261],
					[58.828689042300432, 1.7969609584353927],
					[58.828689041409469, 1.7969609586211959],
					[58.828627940642264, 1.7968998591795147],
					[58.828575146619286, 1.796847064578927],
					[58.828541851880694, 1.7968081707217129],
					[58.828541846053191, 1.796808163245498],
					[58.82854183369993, 1.7968081519153127],
					[58.828541827968799, 1.7968081461573],
					[58.828491833285177, 1.7967665502217789],
					[58.828444547381622, 1.796722063646004],
					[58.828408454846397, 1.7966776746965438],
					[58.828408451884464, 1.7966776700993541],
					[58.828408445165998, 1.796677662808998],
					[58.828408441313101, 1.7966776583976338],
					[58.828358441367492, 1.7966276592372838],
					[58.828358440380185, 1.7966276577049005],
					[58.828358435636353, 1.7966276534793508],
					[58.828358433758069, 1.7966276521327611],
					[58.828300138331151, 1.7965748548920761],
					[58.828266844802272, 1.796541562095161],
					[58.828239047686949, 1.7965109664235197],
					[58.82823904571233, 1.7965109633587628],
					[58.828239040872134, 1.7965109574150391],
					[58.828239038006544, 1.7965109545360789],
					[58.828177938144421, 1.7964526567920669],
					[58.828102945722812, 1.7963804630632583],
					[58.828086258127541, 1.7963609754398311],
					[58.828072362952241, 1.7963415833873966],
					[58.828072356137419, 1.7963415743789908],
					[58.828072340822182, 1.7963415584519911],
					[58.828072334103723, 1.7963415511617669],
					[58.827991842149444, 1.7962692584956457],
					[58.827908449624587, 1.7961748675132063],
					[58.827908446759018, 1.796174864634297],
					[58.827908440040517, 1.7961748573441587],
					[58.827908437174926, 1.7961748544652463],
					[58.827811242340914, 1.7960831582909642],
					[58.827744546792474, 1.7960109634615962],
					[58.827744545901524, 1.7960109636474184],
					[58.827744543035934, 1.7960109607685537],
					[58.827744541157664, 1.7960109594220262],
					[58.827725149180623, 1.7959915651258773],
					[58.827702956287609, 1.7959637751569726],
					[58.827702952434699, 1.7959637707457723],
					[58.8277029428506, 1.7959637605768217],
					[58.827702938106732, 1.7959637563514406],
					[58.827633441897127, 1.7958970583972771],
					[58.827580745538214, 1.7958415624522666],
					[58.827541846102427, 1.7957998641473774],
					[58.827541844127801, 1.7957998610827062],
					[58.827541839287584, 1.7957998551392116],
					[58.827541837409314, 1.7957998537926889],
					[58.82749455413731, 1.7957554698989875],
					[58.827483467235062, 1.7957387884006346],
					[58.827483463478522, 1.795738785707635],
					[58.82748345567633, 1.7957387751671752],
					[58.827483452714368, 1.7957387705701826],
					[58.827452952652898, 1.7957026716905105],
					[58.827452945934382, 1.7957026644005329],
					[58.827452931606416, 1.7957026500064126],
					[58.827452924984257, 1.7957026444345865],
					[58.827439033582486, 1.7956915505882489],
					[58.82742234413449, 1.7956748608649213],
					[58.827380753639801, 1.7956304719371763],
					[58.827358462194198, 1.7955998818345369],
					[58.827358461206877, 1.7955998803022157],
					[58.827358459328607, 1.7955998789557295],
					[58.827358459232251, 1.7955998772375859],
					[58.82733075882846, 1.7955637787162668],
					[58.82733075497552, 1.7955637743051396],
					[58.827330749147983, 1.7955637668293889],
					[58.827330745295043, 1.7955637624182825],
					[58.827277944662946, 1.7955081622742215],
					[58.827277943675611, 1.7955081607419145],
					[58.82727794179732, 1.795508159395425],
					[58.827277940809992, 1.7955081578631165],
					[58.827269558621055, 1.795499876516456],
					[58.827252971833218, 1.7954720964308484],
					[58.827252966992958, 1.7954720904874364],
					[58.82725295711974, 1.7954720751643334],
					[58.827252951388552, 1.7954720694067503],
					[58.827216850700147, 1.7954304695113303],
					[58.82721684971284, 1.7954304679790249],
					[58.827175167227246, 1.7953831872220711],
					[58.827166878627779, 1.7953666106636916],
					[58.82716687458214, 1.7953666028163409],
					[58.827166865696213, 1.7953665890256243],
					[58.827166861746925, 1.7953665828964014],
					[58.827144562443443, 1.7953359826071769],
					[58.827144559577853, 1.7953359797284105],
					[58.827144557603205, 1.7953359766638015],
					[58.827144555724928, 1.7953359753173328],
					[58.827111256227539, 1.7952942746410046],
					[58.827111255240219, 1.7952942731087056],
					[58.827111255240219, 1.7952942731087056],
					[58.827086262353937, 1.7952637810582381],
					[58.827061268098682, 1.7952248919399267],
					[58.827061267111354, 1.7952248904076324],
					[58.827061264149393, 1.7952248858107418],
					[58.827061263162072, 1.7952248842784475],
					[58.827019562820254, 1.7951665838341604],
					[58.827019555018033, 1.7951665732939597],
					[58.827019537728034, 1.7951665543033637],
					[58.827019528336628, 1.7951665475710963],
					[58.827002982401986, 1.7951526912091857],
					[58.827000195686665, 1.7951388608167458],
					[58.827000191062787, 1.7951388426606958],
					[58.827000176469284, 1.7951388074636201],
					[58.827000167390629, 1.7951387902367564],
					[58.826958466661068, 1.7950748912431818],
					[58.826958467455661, 1.7950748893392205],
					[58.826958464590057, 1.7950748864604942],
					[58.826958463602736, 1.795074884928211],
					[58.826905768366061, 1.7949998915753804],
					[58.826858473885395, 1.7949193012038611],
					[58.826816875205608, 1.7948443037514465],
					[58.826816867306981, 1.7948442914932548],
					[58.826816849824183, 1.7948442690666881],
					[58.826816841130977, 1.7948442587124673],
					[58.826800160331338, 1.7948276773678873],
					[58.826758475796929, 1.7947555052695237],
					[58.826722379212335, 1.7946832106559061],
					[58.826722375166632, 1.7946832028087349],
					[58.826722365293342, 1.7946831874860836],
					[58.826722360356683, 1.7946831798247636],
					[58.826714099662034, 1.7946721322876791],
					[58.826714100059185, 1.7946610997557073],
					[58.826714097024414, 1.7946610777919527],
					[58.826714087198262, 1.7946610311716129],
					[58.826714078817744, 1.7946610103229608],
					[58.826675179306086, 1.7945832116100651],
					[58.826675178222381, 1.794583208359702],
					[58.826675176247711, 1.7945832052951882],
					[58.826675175260384, 1.794583203762913],
					[58.826630800466695, 1.7945027493581187],
					[58.826630799886082, 1.794491699672746],
					[58.826630796851269, 1.7944916777090631],
					[58.826630787217816, 1.7944916345250979],
					[58.826630779728248, 1.7944916134906759],
					[58.826589079863943, 1.7944055135669639],
					[58.826589075818227, 1.7944055057198596],
					[58.826589067823178, 1.7944054917437422],
					[58.826589063873861, 1.7944054856147513],
					[58.826569575057469, 1.7943777018046949],
					[58.82654459987932, 1.7943221578483495],
					[58.826544599933392, 1.7943138996163877],
					[58.826544597885878, 1.7943138791850053],
					[58.826544589239717, 1.79431383753346],
					[58.826544581750092, 1.7943138164991159],
					[58.82650298223399, 1.7942221164116574],
					[58.82650298223399, 1.7942221164116574],
					[58.826469586239917, 1.794149925203246],
					[58.826461289753055, 1.794124936252208],
					[58.826461284720004, 1.794124926872924],
					[58.826461275352059, 1.7941249044923022],
					[58.826461270222602, 1.794124893394927],
					[58.826447380328666, 1.7941027104110727],
					[58.826419586805486, 1.7940305274522155],
					[58.826419584830795, 1.7940305243877575],
					[58.826419581772413, 1.7940305180729617],
					[58.826419580688679, 1.7940305148226368],
					[58.82639458382377, 1.7939777204735958],
					[58.826358487205653, 1.7938832288300104],
					[58.826358486218332, 1.7938832272977718],
					[58.82635848503822, 1.7938832223293733],
					[58.826358484050871, 1.7938832207971553],
					[58.826325183628114, 1.7938055227598344],
					[58.826264088819649, 1.7936582343233414],
					[58.826258492291998, 1.7936388442533227],
					[58.826258491208264, 1.7936388410030089],
					[58.826258488053448, 1.7936388329702015],
					[58.826258486969721, 1.7936388297199053],
					[58.82623628720853, 1.7935805297743068],
					[58.826236286124818, 1.7935805265240121],
					[58.826236284053742, 1.7935805217415044],
					[58.826236283066407, 1.7935805202092943],
					[58.826186289428328, 1.7934666345902108],
					[58.826175192901701, 1.7934249490628709],
					[58.826175190734233, 1.793424942562299],
					[58.82617518719384, 1.7934249276572021],
					[58.826175184135423, 1.7934249213425191],
					[58.826119584343324, 1.7932943215226147],
					[58.826119584343324, 1.7932943215226147],
					[58.826077986826284, 1.7931971288422297],
					[58.826052989886605, 1.7931221372233355],
					[58.826052988802864, 1.7931221339730821],
					[58.826052985744433, 1.7931221276584279],
					[58.826052984660699, 1.7931221244081714],
					[58.825989084681702, 1.7929666247979983],
					[58.825989082417834, 1.7929666165794242],
					[58.825989075410007, 1.7929666041360766],
					[58.825989072255148, 1.7929665961033903],
					[58.825980785173002, 1.7929527197129653],
					[58.825966891756693, 1.792905544911735],
					[58.825966890383761, 1.7929055365072815],
					[58.825966885157804, 1.7929055236921683],
					[58.825966882099365, 1.792905517377557],
					[58.825911282289184, 1.7927832171545568],
					[58.82591128219277, 1.7927832154364942],
					[58.825894585255597, 1.7927471225753777],
					[58.825844587834204, 1.7926110312936427],
					[58.825844585763107, 1.7926110265112574],
					[58.825844582608248, 1.7926110184786328],
					[58.82584458063355, 1.7926110154142909],
					[58.825805782188503, 1.7925277174581418],
					[58.825766883080696, 1.7924388194376959],
					[58.825766883080696, 1.7924388194376959],
					[58.825766882093347, 1.7924388179055415],
					[58.825766881996941, 1.7924388161874902],
					[58.825711283992469, 1.7923166212582315],
					[58.825691884984622, 1.7922693234776006],
					[58.825691884984622, 1.7922693234776006],
					[58.825691883900866, 1.7922693202273872],
					[58.825691883009917, 1.7922693204132873],
					[58.825622383102413, 1.7921110206019295],
					[58.825622374023382, 1.792111003376551],
					[58.825622353385256, 1.7921109729196039],
					[58.82562234093524, 1.7921109598739531],
					[58.825611269900662, 1.7920998878291856],
					[58.825577985952101, 1.7920166257097441],
					[58.825577983977368, 1.7920166226454799],
					[58.825577980822473, 1.7920166146129699],
					[58.825577978847768, 1.7920166115486822],
					[58.825561280573289, 1.7919832137970246],
					[58.825536281698632, 1.7919277179331659],
					[58.825536279723906, 1.7919277148689035],
					[58.825536275581655, 1.7919277053042821],
					[58.825536273606922, 1.7919277022400102],
					[58.825486276215578, 1.7918388043908418],
					[58.825444577711465, 1.7917582094417681],
					[58.825439079339098, 1.7917471114722596],
					[58.825439079242678, 1.7917471097542272],
					[58.825439078255307, 1.7917471082221048],
					[58.825439078255307, 1.7917471082221048],
					[58.825397377776831, 1.7916666077548684],
					[58.825397376789468, 1.7916666062227538],
					[58.825397376789468, 1.7916666062227538],
					[58.825397375898518, 1.7916666064086684],
					[58.82535578840973, 1.7915888270896247],
					[58.825352993583003, 1.791577750772714],
					[58.825352989151483, 1.7915777360540992],
					[58.825352976724623, 1.7915777073605088],
					[58.825352969716654, 1.791577694917678],
					[58.825302969890572, 1.7914970946920847],
					[58.825302969890572, 1.7914970946920847],
					[58.82525297016862, 1.7914165937409889],
					[58.82525296927767, 1.7914165939269113],
					[58.825252969181264, 1.7914165922088854],
					[58.825205768663736, 1.7913415925887957],
					[58.825205767676387, 1.7913415910566939],
					[58.825205766785437, 1.7913415912426163],
					[58.825164067408046, 1.7912776907892816],
					[58.825164060592904, 1.7912776817826106],
					[58.825164047949947, 1.7912776653013771],
					[58.825164041327632, 1.7912776597307485],
					[58.825152964401717, 1.7912665806193118],
					[58.825144578864716, 1.7912499096914305],
					[58.825144576890004, 1.7912499066272412],
					[58.82514457195316, 1.7912498989667558],
					[58.825144569882006, 1.7912498941845312],
					[58.825108469650907, 1.7911915955555582],
					[58.825108463726693, 1.791191586363003],
					[58.825108452962063, 1.7911915712279869],
					[58.825108446243306, 1.791191563939369],
					[58.82506955625059, 1.7911498732375353],
					[58.825050165760231, 1.7911220891998656],
					[58.825025167804441, 1.791083191976184],
					[58.825025164842337, 1.791083187379912],
					[58.825025158027174, 1.791083178373319],
					[58.825025155065056, 1.7910831737770534],
					[58.825000159013364, 1.7910526783530767],
					[58.824986262578363, 1.7910331842168896],
					[58.824986259616246, 1.7910331796206336],
					[58.824986252897517, 1.7910331723320634],
					[58.824986249935392, 1.7910331677358262],
					[58.824944550254976, 1.7909859680592661],
					[58.82494454926762, 1.7909859665271866],
					[58.82494454926762, 1.7909859665271866],
					[58.824944548376656, 1.7909859667131203],
					[58.824916847832284, 1.7909554657110087],
					[58.824916842991826, 1.7909554597686259],
					[58.824916831625515, 1.7909554499737244],
					[58.824916826881498, 1.7909554457493435],
					[58.824886249703155, 1.7909304640193415],
					[58.824875166974842, 1.7909137895347238],
					[58.824875161050599, 1.7909137803422608],
					[58.824875147613099, 1.7909137657652365],
					[58.82487514089437, 1.7909137584767156],
					[58.824825140875433, 1.7908637582414859],
					[58.824825139888034, 1.7908637567094017],
					[58.824825136034981, 1.7908637522991238],
					[58.824825134156676, 1.7908637509529841],
					[58.824797334998692, 1.7908387527695513],
					[58.82476403579647, 1.7908081532987534],
					[58.824719538965191, 1.7907665576040879],
					[58.824711241789956, 1.7907581593866559],
					[58.824711240898999, 1.7907581595725912],
					[58.824700141105332, 1.7907470594575203],
					[58.824700138143221, 1.7907470548613214],
					[58.824700129642551, 1.790747047944744],
					[58.824700125885926, 1.7907470452524872],
					[58.824641826141111, 1.7906998447841598],
					[58.824641824262805, 1.7906998434380377],
					[58.824641821397108, 1.7906998405598424],
					[58.824641819615195, 1.7906998409317161],
					[58.824619522230606, 1.7906831414760676],
					[58.824577924818904, 1.7906498436272817],
					[58.82457792294057, 1.7906498422811645],
					[58.824577918292988, 1.7906498397748636],
					[58.824577916318233, 1.7906498367107426],
					[58.824558420904403, 1.7906359401422793],
					[58.824530724848977, 1.7906137434909806],
					[58.824530720104974, 1.790613739266687],
					[58.824530707944071, 1.7906137313759281],
					[58.824530703200061, 1.7906137271516538],
					[58.824516809665113, 1.7906054314803665],
					[58.824477916111647, 1.7905776372697819],
					[58.824477913245957, 1.7905776343916215],
					[58.824477905829092, 1.7905776307251688],
					[58.824477902963402, 1.7905776278469943],
					[58.824436218348232, 1.7905526374783687],
					[58.824414032144077, 1.7905331496754517],
					[58.824414025618147, 1.7905331458230604],
					[58.824414013264388, 1.790533134496396],
					[58.824414005847544, 1.7905331308299384],
					[58.824347305750095, 1.7904915306166254],
					[58.824347304859145, 1.7904915308025637],
					[58.82434730199347, 1.7904915279244067],
					[58.8243473010061, 1.7904915263923635],
					[58.824314000989219, 1.7904720272976855],
					[58.824313999110906, 1.7904720259515714],
					[58.824313996341623, 1.7904720247914132],
					[58.824313994463317, 1.7904720234453118],
					[58.824272320243082, 1.7904498364283741],
					[58.824261240583802, 1.7904387584094579],
					[58.824261222691518, 1.7904387447625407],
					[58.824261184330531, 1.7904387197445137],
					[58.824261163958241, 1.7904387100913617],
					[58.824244501074347, 1.7904331234099435],
					[58.824222332007757, 1.7904137486567067],
					[58.824222322616137, 1.7904137419262394],
					[58.824222303832897, 1.7904137284652875],
					[58.824222293646763, 1.7904137236387354],
					[58.824180699002582, 1.7903915253486429],
					[58.824166803427566, 1.7903831294551025],
					[58.824166802440196, 1.7903831279230578],
					[58.824166798683528, 1.7903831252308868],
					[58.824166796901643, 1.7903831256027669],
					[58.824141803932385, 1.7903692295198836],
					[58.824116810790009, 1.79035263249765],
					[58.824116803373137, 1.7903526288312765],
					[58.824116786661087, 1.7903526201524222],
					[58.824116779244214, 1.7903526164860379],
					[58.824077890257399, 1.7903359213291761],
					[58.824044504264023, 1.7903165291004675],
					[58.824014009449073, 1.7902970323498597],
					[58.823989010761004, 1.7902804331146165],
					[58.823989006113408, 1.7902804306083888],
					[58.823988994939889, 1.79028042424987],
					[58.823988990292285, 1.7902804217436645],
					[58.82397788997541, 1.7902748214011563],
					[58.82397788908446, 1.7902748215870963],
					[58.823977886218756, 1.7902748187090045],
					[58.823977884436871, 1.7902748190808846],
					[58.823936204881022, 1.7902554284342043],
					[58.823911222617951, 1.7902359423569576],
					[58.823911215104637, 1.7902359369726539],
					[58.82391119829613, 1.7902359265759564],
					[58.823911189000945, 1.7902359215635359],
					[58.823894493789403, 1.7902276234120698],
					[58.823875098621201, 1.7902165255668112],
					[58.823836209066783, 1.790194232428558],
					[58.823805717818978, 1.7901720376493098],
					[58.82380571317136, 1.7901720351431121],
					[58.823805704767125, 1.7901720299447961],
					[58.823805700119522, 1.7901720274385922],
					[58.823772303824825, 1.7901526289584238],
					[58.823733412207524, 1.7901276336764551],
					[58.823714045867717, 1.7901137576907828],
					[58.823708466308652, 1.7901054879069558],
					[58.823708449017985, 1.7901054689206219],
					[58.823708411065738, 1.79010543512765],
					[58.823708390404143, 1.7901054203209943],
					[58.823680707967682, 1.7900915297124547],
					[58.823641824281545, 1.7900609432746859],
					[58.823627924755783, 1.7900498440184713],
					[58.82362792099913, 1.790049841326367],
					[58.823627914376772, 1.790049835756206],
					[58.823627910716546, 1.7900498347820419],
					[58.823586226584752, 1.790022045453987],
					[58.823577940983633, 1.7900137582239255],
					[58.82357793623958, 1.7900137539998435],
					[58.823577924873177, 1.7900137442056085],
					[58.823577920129154, 1.7900137399815044],
					[58.823533437774238, 1.7899804532527503],
					[58.823516852046403, 1.7899609706171564],
					[58.82351684532761, 1.7899609633290918],
					[58.823516830999068, 1.7899609489389123],
					[58.823516823389298, 1.7899609418367974],
					[58.823466823155421, 1.7899220423136128],
					[58.823466821277073, 1.789922040967564],
					[58.823466818411383, 1.7899220380895373],
					[58.823466815642085, 1.7899220369294491],
					[58.823447334205468, 1.7899081504128171],
					[58.823422347739225, 1.7898804655535971],
					[58.823422346751855, 1.7898804640216146],
					[58.823422342898766, 1.789880459611602],
					[58.823422341020432, 1.7898804582655692],
					[58.823394541181152, 1.7898526584415249],
					[58.823394540193767, 1.7898526569095454],
					[58.823394538315448, 1.7898526555635108],
					[58.8233945364371, 1.7898526542174715],
					[58.823355736130985, 1.7898165534048553],
					[58.82335573524005, 1.7898165535908002],
					[58.823355733265267, 1.7898165505268511],
					[58.823355732277882, 1.7898165489948605],
					[58.823333444943557, 1.7897970607223854],
					[58.823300156062544, 1.7897554750436888],
					[58.82330015023468, 1.789755467569762],
					[58.823300137880864, 1.7897554562437019],
					[58.823300132149448, 1.7897554504877107],
					[58.823277931518369, 1.7897359494006202],
					[58.82327793062742, 1.7897359495865697],
					[58.823277928652651, 1.7897359465226108],
					[58.823277927761715, 1.789735946708559],
					[58.823261244112686, 1.7897220586826743],
					[58.823216855991049, 1.7896665754456718],
					[58.823216855003665, 1.789666573913689],
					[58.823216853125331, 1.7896665725676704],
					[58.823216852041526, 1.7896665693177891],
					[58.823169564807358, 1.7896109856513756],
					[58.823155774625121, 1.7895860029847004],
					[58.823155768700794, 1.789585993792921],
					[58.823155755961245, 1.7895859755953076],
					[58.823155749145975, 1.7895859665894795],
					[58.823111248707256, 1.7895359663124319],
					[58.823111248707256, 1.7895359663124319],
					[58.823111247719886, 1.7895359647804605],
					[58.823111246732502, 1.7895359632485019],
					[58.823077960087296, 1.7894998784947784],
					[58.823055769532992, 1.7894637946825054],
					[58.823055769436557, 1.7894637929645987],
					[58.823055765583469, 1.7894637885546913],
					[58.82305576459607, 1.7894637870227246],
					[58.823025164883319, 1.7894192873884969],
					[58.82302516478687, 1.7894192856705886],
					[58.823025163895934, 1.7894192858565461],
					[58.823025163895934, 1.7894192858565461],
					[58.823005767117465, 1.7893915907824642],
					[58.82299186983434, 1.7893692933375793],
					[58.822991867859571, 1.7893692902736824],
					[58.822991863019062, 1.789369284331837],
					[58.822991861044294, 1.7893692812679403],
					[58.822952964640223, 1.7893165875549446],
					[58.822911279358308, 1.7892499096001251],
					[58.822905785674664, 1.7892360257437108],
					[58.82290578162867, 1.7892360178980282],
					[58.8229057723564, 1.7892359972389229],
					[58.822905765541108, 1.7892359882332254],
					[58.822855769382585, 1.7891609923629219],
					[58.822808471671046, 1.7890831983621611],
					[58.822761275202573, 1.7889999034374926],
					[58.822716876391127, 1.7889166052762249],
					[58.822716875403728, 1.7889166037443043],
					[58.822716873332489, 1.7889165989625677],
					[58.822716872345111, 1.7889165974306425],
					[58.822666873969887, 1.7888332012590602],
					[58.822619578564932, 1.7887443103579344],
					[58.822577980694419, 1.788655514803674],
					[58.822577979610593, 1.7886555115538818],
					[58.822577976648411, 1.7886555069581409],
					[58.82257797556457, 1.7886555037083487],
					[58.822544583697187, 1.7885943192460565],
					[58.822536289970742, 1.7885693371662095],
					[58.822536287803032, 1.788569330666625],
					[58.822536282673163, 1.7885693195713268],
					[58.822536280601923, 1.7885693147896204],
					[58.822494581386522, 1.7884805147223612],
					[58.822494580399123, 1.7884805131904566],
					[58.822494579315268, 1.7884805099406804],
					[58.822494578327884, 1.7884805084087823],
					[58.822452988176408, 1.7883999291644939],
					[58.822450193796818, 1.7883888504162861],
					[58.822450187390153, 1.788388832635508],
					[58.822450170025668, 1.7883887962859615],
					[58.82245016005524, 1.7883887792490962],
					[58.822441873397324, 1.7883776981505235],
					[58.822405783385527, 1.7882971181009857],
					[58.822405782398128, 1.7882971165690922],
					[58.822405781410708, 1.7882971150372051],
					[58.82240578042331, 1.7882971135053116],
					[58.82237238355853, 1.7882277203581853],
					[58.822364086976734, 1.7882055303139719],
					[58.82236408490548, 1.7882055255323086],
					[58.82236408066651, 1.7882055142511404],
					[58.822364078691706, 1.78820551118735],
					[58.822350180734965, 1.7881777156786569],
					[58.822322382671274, 1.7881138192482882],
					[58.822322382671274, 1.7881138192482882],
					[58.822322381683875, 1.7881138177164058],
					[58.822297383240127, 1.7880582198968324],
					[58.822280784200181, 1.7880193228743591],
					[58.822261285249702, 1.7879721239910151],
					[58.822241885347751, 1.7879249235991801],
					[58.822241884360338, 1.7879249220673104],
					[58.82224188228907, 1.7879249172857026],
					[58.822241882192614, 1.787924915567823],
					[58.82222518264161, 1.7878888204477636],
					[58.822200185388709, 1.7878277236519049],
					[58.822200185388709, 1.7878277236519049],
					[58.822200183413898, 1.7878277205881465],
					[58.822200183317442, 1.7878277188702891],
					[58.822166893705074, 1.7877527442993018],
					[58.822164097541339, 1.7877332715304723],
					[58.822164087690055, 1.7877332405650548],
					[58.822164060258416, 1.7877331854617509],
					[58.822164040703271, 1.7877331582601015],
					[58.82215017393267, 1.7877192918328835],
					[58.822119588803673, 1.78763053541549],
					[58.822119587816275, 1.7876305338836298],
					[58.822119586635942, 1.7876305289160599],
					[58.822119585552088, 1.787630525666325],
					[58.822086291245959, 1.78754713879107],
					[58.822083493545009, 1.7875360504833948],
					[58.822083494435958, 1.7875360502973991],
					[58.822083493352089, 1.7875360470476722],
					[58.822083492364698, 1.7875360455158185],
					[58.822077991588259, 1.7875166455827662],
					[58.822077991298883, 1.7875166404291893],
					[58.822077987156312, 1.7875166308660535],
					[58.822077985975973, 1.7875166258984818],
					[58.822044586321603, 1.7874332264545734],
					[58.822022388133732, 1.7873749315207499],
					[58.822005788788353, 1.7873277345225078],
					[58.82198079374345, 1.7872527497427493],
					[58.821975196551179, 1.7872221634818048],
					[58.821975188964018, 1.7872221407339419],
					[58.821975169334998, 1.787222096168253],
					[58.821975156402203, 1.7872220745364322],
					[58.821964081049181, 1.7872082060936443],
					[58.82194189575727, 1.7871332570733487],
					[58.821939098090191, 1.7871138716813917],
					[58.821939095825968, 1.7871138634641712],
					[58.821939093079379, 1.7871138466577148],
					[58.821939089924207, 1.7871138386264993],
					[58.821914089937145, 1.7870360396210132],
					[58.821914089840661, 1.7870360379031733],
					[58.82191408974419, 1.787036036185327],
					[58.821902992669813, 1.7870027451532016],
					[58.821891894762928, 1.7869555556467529],
					[58.821877995405394, 1.7868943554625416],
					[58.821877991069826, 1.7868943424638404],
					[58.821877980616861, 1.7868943168384739],
					[58.821877975390358, 1.786894304025797],
					[58.821861289194338, 1.7868638293246513],
					[58.821841894861279, 1.7867749572519862],
					[58.821841894764809, 1.7867749555341463],
					[58.821841893584427, 1.7867749505666335],
					[58.821841893391479, 1.7867749471309602],
					[58.821819597706167, 1.7866916654467946],
					[58.821816899416881, 1.7866610824387337],
					[58.821816898043544, 1.7866610740355573],
					[58.821816895296926, 1.7866610572292105],
					[58.821816894020074, 1.7866610505438689],
					[58.821797395642818, 1.7865860573268002],
					[58.821789097169514, 1.7865388658031867],
					[58.82178909382133, 1.7865388543363758],
					[58.821789088015954, 1.7865388312167678],
					[58.82178908387332, 1.786538821653826],
					[58.821780792105201, 1.7865193391113332],
					[58.821772396025686, 1.7864777608224693],
					[58.821758496665112, 1.7864082673718025],
					[58.821750197841027, 1.7863471721744539],
					[58.821750197744542, 1.7863471704566189],
					[58.82175019646769, 1.7863471637713013],
					[58.821750196178243, 1.7863471586178088],
					[58.821736297599884, 1.7862805692535326],
					[58.82173079903442, 1.786222181155237],
					[58.821730797757553, 1.7862221744699274],
					[58.821730796191233, 1.7862221626311299],
					[58.821730794914366, 1.786222155945822],
					[58.821719598050649, 1.7861721668084907],
					[58.821716899377527, 1.7861471802818656],
					[58.821705798689187, 1.7860332813124959],
					[58.821705798110294, 1.7860332710055062],
					[58.821705794376129, 1.7860332526674678],
					[58.821705791027924, 1.7860332412007494],
					[58.821697393072093, 1.7860055464521429],
					[58.821694598340954, 1.7859943691178559],
					[58.821686299630898, 1.7858638870962587],
					[58.821686299148475, 1.7858638785071104],
					[58.821686296305224, 1.785863859983059],
					[58.821686294040923, 1.7858638517659862],
					[58.821677998712076, 1.7858305706387632],
					[58.821675199596939, 1.7857832877780173],
					[58.821675199596939, 1.7857832877780173],
					[58.82167519940397, 1.7857832843423693],
					[58.821669600073434, 1.7857110866219932],
					[58.821666899796732, 1.7856582946085418],
					[58.821666900460528, 1.7855889005511774],
					[58.821666900171046, 1.7855888953977024],
					[58.821666899688609, 1.7855888868085652],
					[58.821666898604676, 1.7855888835589606],
					[58.821664098771677, 1.7855555828905703],
					[58.821664098771677, 1.7855555828905703],
					[58.821664098578694, 1.7855555794549223],
					[58.821664099373152, 1.7855555775510488],
					[58.821655799632993, 1.7854832885586687],
					[58.821655800258355, 1.7854055991589277],
					[58.821655799775904, 1.7854055905697921],
					[58.821655797727054, 1.7854055701419276],
					[58.821655796353639, 1.785405561738848],
					[58.821653000215335, 1.7853916804626602],
					[58.821652999697456, 1.7853110993082073],
					[58.821653000100568, 1.7852305996395572],
					[58.821653000344952, 1.7852138993737832],
					[58.821652999808457, 1.785138904064846],
					[58.821655800228022, 1.7850667076584175],
					[58.821655800131538, 1.7850667059405936],
					[58.821655799938554, 1.7850667025049474],
					[58.821655799842048, 1.7850667007871122],
					[58.821655799829664, 1.7850361002322224],
					[58.821655800107962, 1.7849639133039421],
					[58.821664097980083, 1.784905628361017],
					[58.821664098581529, 1.7849056230214715],
					[58.821664098989949, 1.7849056142462894],
					[58.821664099591416, 1.7849056089067583],
					[58.82166689995703, 1.7848417095575579],
					[58.821669599611219, 1.7847917107185696],
					[58.82166960030915, 1.7847917070968513],
					[58.821669600116167, 1.7847917036612018],
					[58.821669599923148, 1.7847917002255633],
					[58.821669600051472, 1.7847528077271191],
					[58.821677999156435, 1.7846389157851039],
					[58.821677999757867, 1.7846389104455729],
					[58.821678000262786, 1.7846389033882002],
					[58.821678000069781, 1.7846388999525444],
					[58.82167799960127, 1.7846167008486911],
					[58.821677999740402, 1.7846056159913963],
					[58.821689097218552, 1.784536132368747],
					[58.821689097819984, 1.7845361270292079],
					[58.821689099215838, 1.7845361197857665],
					[58.821689098926321, 1.7845361146322851],
					[58.821694598974233, 1.7844611157177028],
					[58.821694599575636, 1.7844611103781542],
					[58.821694600080555, 1.7844611033207927],
					[58.821694599887557, 1.7844610998851322],
					[58.821694599719677, 1.7844444100998391],
					[58.821697398922431, 1.7844167234214785],
					[58.821711298329745, 1.7843194272803833],
					[58.821711298329745, 1.7843194272803833],
					[58.821711299027648, 1.7843194236586539],
					[58.821711298931149, 1.7843194219408298],
					[58.821716898395287, 1.7842694273552722],
					[58.821730797022504, 1.7841833342800928],
					[58.821747396442255, 1.7840944385116655],
					[58.821755795775125, 1.7840528391953863],
					[58.821755796569562, 1.7840528372914746],
					[58.821755798061886, 1.7840528317658269],
					[58.821755797868882, 1.7840528283301758],
					[58.821772392011148, 1.7839333728011795],
					[58.821777966326003, 1.7839251123169062],
					[58.821777979229807, 1.7839250852899424],
					[58.821777995935008, 1.7839250296612141],
					[58.821777999639899, 1.7839249993416399],
					[58.821777999815247, 1.7839139161746271],
					[58.821797396129661, 1.7838000415650128],
					[58.821811293386311, 1.7837472516484725],
					[58.821811294878657, 1.7837472461228105],
					[58.821811296972363, 1.7837472352575698],
					[58.821811296779337, 1.7837472318219012],
					[58.821822396321572, 1.7836778410087679],
					[58.821839093370286, 1.7836139513545151],
					[58.821850192898673, 1.7835722518185748],
					[58.821850194391004, 1.7835722462929002],
					[58.821850195690281, 1.783572237331557],
					[58.821850197279126, 1.7835722335236983],
					[58.821852997231048, 1.783555633101612],
					[58.82185849583658, 1.7835250412744059],
					[58.821877993357987, 1.7834417544862611],
					[58.821891890310482, 1.7834000631580553],
					[58.821891891008377, 1.7834000595362973],
					[58.821891891706279, 1.7834000559145218],
					[58.821891893295103, 1.7834000521066664],
					[58.821911292532427, 1.7833278514851871],
					[58.821939093257363, 1.7832250520487323],
					[58.821939096145442, 1.7832250392794906],
					[58.821939099152274, 1.7832250125814726],
					[58.821939100258497, 1.783225000184421],
					[58.821939100179407, 1.7832139135346736],
					[58.821941895049747, 1.7831944481423043],
					[58.821975188222694, 1.7831028687369634],
					[58.821975189618463, 1.7831028614934059],
					[58.821975194288406, 1.7831028483519169],
					[58.821975195684189, 1.7831028411083563],
					[58.821986293766088, 1.7830500496222823],
					[58.822002991676626, 1.782994458072565],
					[58.822002991580106, 1.7829944563547315],
					[58.822027984449562, 1.7829111836126856],
					[58.822041869970491, 1.7828890067775893],
					[58.82204187791455, 1.7828889877380671],
					[58.822041892117355, 1.7828889517490922],
					[58.822041897292102, 1.782888931550086],
					[58.822044593648123, 1.7828722535423092],
					[58.822077986456222, 1.7827861725951286],
					[58.822077988045024, 1.7827861687872206],
					[58.822077990428262, 1.7827861630753403],
					[58.822077991126129, 1.7827861594535568],
					[58.822105791001462, 1.7826972597458759],
					[58.822105793288145, 1.7826972523161764],
					[58.822105796176174, 1.7826972395468093],
					[58.822105796681008, 1.7826972324893429],
					[58.822108492158186, 1.7826806675857512],
					[58.822119574130802, 1.7826611983834177],
					[58.822119578199342, 1.7826611905814471],
					[58.822119585445499, 1.7826611751636463],
					[58.822119587732182, 1.7826611677339197],
					[58.822147388424867, 1.7825833682779273],
					[58.822169586371722, 1.7825222734796808],
					[58.822186283173039, 1.7824833803557152],
					[58.822208482592352, 1.7824333805603214],
					[58.822208482592352, 1.7824333805603214],
					[58.822208484277674, 1.7824333784702246],
					[58.822208485072103, 1.7824333765662539],
					[58.822227985248709, 1.7823861756999262],
					[58.82222798604311, 1.7823861737959557],
					[58.822227986740963, 1.7823861701741308],
					[58.822227988329765, 1.7823861663661911],
					[58.822241884718878, 1.7823472776618383],
					[58.822269580839034, 1.7822889860899007],
					[58.822269583222209, 1.7822889803779776],
					[58.822269587194214, 1.7822889708580845],
					[58.822269588686467, 1.7822889653322778],
					[58.822291882357845, 1.7822250849237162],
					[58.822308471527364, 1.7821973025228364],
					[58.822308478870006, 1.7821972888226989],
					[58.822308489798473, 1.7821972587312547],
					[58.82230849526271, 1.782197243685528],
					[58.822314089309231, 1.7821722693697153],
					[58.822350177146348, 1.7821028911270278],
					[58.822350178037297, 1.7821028909408843],
					[58.822350179722605, 1.7821028888507668],
					[58.822350179529536, 1.7821028854150776],
					[58.822383479704726, 1.7820333855414809],
					[58.822383482280976, 1.7820333832652169],
					[58.822383485458566, 1.7820333756492484],
					[58.822383487047347, 1.7820333718412784],
					[58.822391880749237, 1.7820111877449964],
					[58.822400172083988, 1.7819973032134075],
					[58.822400175358105, 1.7819972973152962],
					[58.82240018022101, 1.7819972876091943],
					[58.82240018260417, 1.7819972818972172],
					[58.822433480472462, 1.7819222884047776],
					[58.822472373973483, 1.781850098763718],
					[58.822480770998489, 1.7818362025029242],
					[58.822480771889431, 1.7818362023167806],
					[58.822480772683804, 1.7818362004127846],
					[58.822480774369126, 1.7818361983226416],
					[58.822491873719265, 1.781816799716299],
					[58.822491876198953, 1.7818167957221425],
					[58.822491880965295, 1.7818167842981434],
					[58.822491883445025, 1.7818167803039981],
					[58.822522379619571, 1.7817472869343289],
					[58.822555770932709, 1.7816834054526356],
					[58.822569563230637, 1.7816640158965946],
					[58.822569569875363, 1.7816640058180975],
					[58.822569582080803, 1.7816639824115417],
					[58.822569586847102, 1.7816639709874964],
					[58.822577981371929, 1.7816417864974301],
					[58.822616873088727, 1.781575100922143],
					[58.822616872992192, 1.7815750992042856],
					[58.82264737343862, 1.7815222999971527],
					[58.822647375821781, 1.7815222942850975],
					[58.822647380781184, 1.7815222862967226],
					[58.822647383164337, 1.7815222805846833],
					[58.822658474383758, 1.7814973009072299],
					[58.82268076082736, 1.7814668187845348],
					[58.82268076578675, 1.7814668107961569],
					[58.822680774911149, 1.7814667967233824],
					[58.822680778979624, 1.7814667889211588],
					[58.822708470960073, 1.7814112045441395],
					[58.822739061398998, 1.7813695183664953],
					[58.822739064673065, 1.781369512468276],
					[58.822739073003078, 1.7813695002994911],
					[58.822739076277152, 1.7813694944012528],
					[58.822758475415327, 1.7813333967183964],
					[58.822783474362225, 1.7812889977178634],
					[58.822783475253154, 1.7812889975317023],
					[58.822783476047526, 1.7812889956276823],
					[58.822783476047526, 1.7812889956276823],
					[58.82280297114459, 1.7812529056057056],
					[58.822822363822965, 1.7812251135003745],
					[58.822822364713893, 1.7812251133142103],
					[58.822822366399201, 1.7812251112240149],
					[58.822822366399201, 1.7812251112240149],
					[58.822855765244668, 1.7811751122854673],
					[58.822900164040171, 1.7811112132488884],
					[58.822900170588298, 1.7811112014523161],
					[58.82290018368456, 1.7811111778591777],
					[58.822900190136139, 1.7811111643447368],
					[58.822905784596699, 1.7810944763097016],
					[58.822911274391544, 1.7810833997000415],
					[58.822952964396137, 1.7810195155038193],
					[58.823002959788369, 1.7809529205670509],
					[58.823002967227417, 1.7809529085842444],
					[58.823002980420199, 1.7809528867088487],
					[58.82300298598085, 1.7809528733805069],
					[58.82300847701822, 1.7809389964520166],
					[58.823055763369048, 1.7808723156098307],
					[58.823055763369048, 1.7808723156098307],
					[58.823089060060489, 1.7808251199307967],
					[58.82311125586822, 1.7807973255468401],
					[58.823111259238807, 1.7807973213663826],
					[58.823111264294731, 1.7807973150956806],
					[58.823111265883469, 1.7807973112875564],
					[58.823139058277761, 1.7807557220781798],
					[58.823166847913882, 1.7807251335618342],
					[58.823166854751648, 1.7807251269187543],
					[58.823166868137491, 1.780725108478924],
					[58.823166873891218, 1.7807250985862508],
					[58.823177964921562, 1.7807057148393626],
					[58.823216854077373, 1.7806584277294704],
					[58.823216854968301, 1.7806584275432917],
					[58.823216855762674, 1.7806584256392131],
					[58.823272355835599, 1.7805890246215355],
					[58.823272361685873, 1.7805890164467069],
					[58.823272373289846, 1.780588998379135],
					[58.823272379140107, 1.780588990204292],
					[58.823277971129052, 1.7805779041961007],
					[58.82332235684224, 1.7805168236694058],
					[58.823355749739612, 1.7804779324985438],
					[58.8233807485976, 1.7804501341187799],
					[58.823380750282872, 1.7804501320285162],
					[58.823380752859094, 1.7804501297520612],
					[58.823380753653474, 1.7804501278479681],
					[58.823422354025723, 1.7804001287564009],
					[58.823436248130378, 1.7803834352198185],
					[58.823444540758842, 1.7803751409514073],
					[58.823444545911308, 1.7803751363984799],
					[58.823444555325267, 1.7803751274787956],
					[58.823444558599292, 1.7803751215803376],
					[58.823489050646984, 1.7803168321932727],
					[58.823533442138135, 1.7802723414246711],
					[58.823533447194009, 1.7802723351538194],
					[58.823533458196707, 1.7802723224258947],
					[58.823533463252581, 1.780272316155032],
					[58.823547350407125, 1.7802529334106754],
					[58.823580734979849, 1.7802223462633961],
					[58.8235807401323, 1.7802223417104226],
					[58.823580750437173, 1.7802223326044644],
					[58.823580754602119, 1.7802223265197787],
					[58.823605748944189, 1.7801918346667778],
					[58.82362514185283, 1.7801723405590917],
					[58.823625142743751, 1.7801723403728955],
					[58.823625145319987, 1.7801723380964007],
					[58.823625146114352, 1.7801723361923014],
					[58.823666845723757, 1.780127936607572],
					[58.823711230107975, 1.780080754524449],
					[58.823727910704093, 1.7800695668682713],
					[58.823727922790816, 1.7800695573898271],
					[58.823727945880179, 1.7800695351833296],
					[58.823727956088497, 1.7800695243593725],
					[58.823750148596837, 1.7800418338949653],
					[58.823783442119172, 1.7800084409692016],
					[58.823783443804473, 1.780008438878887],
					[58.823783448860326, 1.7800084326079337],
					[58.823783452327454, 1.7800084301452159],
					[58.823800146639073, 1.7799890357561536],
					[58.823841838837467, 1.7799473434643194],
					[58.823891837044457, 1.7799001451529153],
					[58.823891837838822, 1.7799001432487935],
					[58.823891839620671, 1.7799001428763808],
					[58.823891841305958, 1.7799001407860406],
					[58.823902934098591, 1.7798890484357279],
					[58.823933426792614, 1.7798640550854454],
					[58.823933437000896, 1.7798640442613611],
					[58.823933455732174, 1.7798640247035371],
					[58.823933464158593, 1.7798640142518718],
					[58.823958444998816, 1.7798279435708877],
					[58.823977916318647, 1.7798140623649441],
					[58.823977923252919, 1.779814057439437],
					[58.82397793524305, 1.7798140462429113],
					[58.823977942177308, 1.7798140413173897],
					[58.824019536834598, 1.7797723463660711],
					[58.824066832006352, 1.7797307495255184],
					[58.824066838049696, 1.7797307447861992],
					[58.824066849148878, 1.7797307337758281],
					[58.824066854204752, 1.7797307275047716],
					[58.824080739783994, 1.7797140438269148],
					[58.824119523629278, 1.779683456892744],
					[58.824119529576052, 1.7796834504354611],
					[58.824119543348019, 1.7796834388664335],
					[58.824119549294778, 1.7796834324091584],
					[58.824141831851918, 1.7796584513654772],
					[58.82415841092272, 1.7796473662555652],
					[58.824158420433179, 1.779647359053417],
					[58.824158436781374, 1.779647345207769],
					[58.824158446195277, 1.7796473362876937],
					[58.824202940636226, 1.7796001420036478],
					[58.824244536662086, 1.7795612463128012],
					[58.824244538347372, 1.7795612442224358],
					[58.824244540032659, 1.779561242132075],
					[58.82424454092358, 1.7795612419458566],
					[58.824264035810955, 1.7795418474346245],
					[58.824305730645499, 1.7795057519133222],
					[58.824305733221706, 1.7795057496367386],
					[58.824305733221706, 1.7795057496367386],
					[58.824330732703061, 1.779483448991535],
					[58.824330735279268, 1.7794834467149256],
					[58.824330738649806, 1.7794834425341852],
					[58.824330741226021, 1.7794834402575885],
					[58.824386240843374, 1.7794279417127321],
					[58.824425131151493, 1.779389051460925],
					[58.824447319885728, 1.7793723596064333],
					[58.82444732682, 1.7793723546807589],
					[58.824447338810067, 1.7793723434839248],
					[58.824447344756827, 1.7793723370265258],
					[58.824494542585811, 1.779322339901575],
					[58.824511240809912, 1.7793057423131482],
					[58.824511240713342, 1.7793057405952066],
					[58.82456684092864, 1.7792501404589904],
					[58.824566842710489, 1.7792501400865364],
					[58.824566846177625, 1.7792501376236787],
					[58.824566846971962, 1.7792501357195123],
					[58.824600144304171, 1.7792140392897293],
					[58.824622340753812, 1.7791918409331886],
					[58.824622341644734, 1.7791918407469591],
					[58.824622344220934, 1.7791918384703087],
					[58.824622345015285, 1.7791918365661328],
					[58.82466954533988, 1.7791418366524414],
					[58.824669549601332, 1.7791418322853776],
					[58.824669558918629, 1.7791418216470696],
					[58.824669563083525, 1.7791418155620704],
					[58.824683453352648, 1.7791223302018384],
					[58.824739040851838, 1.7790668416209792],
					[58.824739040851838, 1.7790668416209792],
					[58.824780741420462, 1.7790251417242477],
					[58.824780748161544, 1.7790251333625329],
					[58.824780760849336, 1.7790251185433188],
					[58.824780766699497, 1.7790251103678463],
					[58.824797359937115, 1.7790001200616359],
					[58.82482794736795, 1.7789640351248668],
					[58.824855741447436, 1.7789362409943239],
					[58.824855744817974, 1.7789362368134529],
					[58.824855753340906, 1.7789362280792298],
					[58.824855755917092, 1.7789362258025634],
					[58.824866854878351, 1.7789223261332519],
					[58.824908451396148, 1.7788723305818421],
					[58.82496404811851, 1.7788112354532111],
					[58.824964050598126, 1.7788112314585458],
					[58.82496405733918, 1.778811223096761],
					[58.824964059915381, 1.7788112208200737],
					[58.824980755322976, 1.7787890264582573],
					[58.825025149857723, 1.7787390331464306],
					[58.825025154913526, 1.7787390268750749],
					[58.825025164928526, 1.778739012614371],
					[58.825025169887738, 1.7787390046250355],
					[58.825047368258055, 1.778702908670599],
					[58.825072358746006, 1.7786668216920505],
					[58.825122351995553, 1.7786084303777989],
					[58.825122358640023, 1.7786084202979533],
					[58.825122372025511, 1.7786084018562289],
					[58.82512237768249, 1.7786083902446566],
					[58.825130769421861, 1.778591807670304],
					[58.825183456687412, 1.778525123317247],
					[58.825183459263613, 1.77852512104052],
					[58.825183462634151, 1.7785251168595553],
					[58.825183465113732, 1.7785251128648611],
					[58.825225164584587, 1.7784640122371178],
					[58.825225168846018, 1.778464007869911],
					[58.825225175393889, 1.7784639960720265],
					[58.8252251786678, 1.7784639901730883],
					[58.825230769755755, 1.7784529068074275],
					[58.825250157574246, 1.7784279226190143],
					[58.825250160150432, 1.7784279203422746],
					[58.825250162533415, 1.7784279146295801],
					[58.825250165109615, 1.7784279123528388],
					[58.825286264920692, 1.7783751138563451],
					[58.825286264824108, 1.778375112138348],
					[58.825286265618416, 1.7783751102341196],
					[58.825314065593979, 1.7783334103305277],
					[58.8253362661114, 1.7783001117950015],
					[58.825336267796658, 1.7783001097045039],
					[58.825336271070583, 1.7783001038055213],
					[58.825336271768322, 1.7783001001833083],
					[58.825364070990396, 1.7782529041965924],
					[58.825383468590161, 1.7782223069068981],
					[58.825383470275419, 1.7782223048163992],
					[58.825383472755007, 1.7782223008216431],
					[58.825383475234595, 1.7782222968268981],
					[58.825397370469339, 1.7781973065417163],
					[58.82543626365522, 1.7781418151391801],
					[58.825436266134794, 1.7781418111444161],
					[58.825436268614382, 1.7781418071496617],
					[58.825436271093963, 1.778141803154893],
					[58.825475171095967, 1.7780779043465538],
					[58.8254751751642, 1.7780778965432793],
					[58.825475181518833, 1.7780778813092539],
					[58.825475185683679, 1.7780778752239783],
					[58.825480786061071, 1.7780639756480923],
					[58.825480786758803, 1.7780639720258571],
					[58.825480789141807, 1.7780639663130879],
					[58.825480789839531, 1.7780639626908592],
					[58.825486282614946, 1.7780472836558903],
					[58.825527970680646, 1.7779779035253607],
					[58.82552797157156, 1.7779779033390979],
					[58.825577971802687, 1.7778945031330664],
					[58.825577972597024, 1.7778945012288063],
					[58.825577974980028, 1.7778944955160259],
					[58.825577975774337, 1.7778944936117547],
					[58.825622375794147, 1.7778111933070952],
					[58.825622378466903, 1.7778111927482814],
					[58.825622379958958, 1.7778111872217621],
					[58.825622380753281, 1.7778111853175005],
					[58.825664080794013, 1.777722285670374],
					[58.825705780768892, 1.7776333858096798],
					[58.825747378751117, 1.7775472899001428],
					[58.825752978780869, 1.7775361904254017],
					[58.825752980466106, 1.7775361883348437],
					[58.825752981163816, 1.7775361847125561],
					[58.825752981958168, 1.7775361828082739],
					[58.825791881752046, 1.7774500824234196],
					[58.825827980717385, 1.7773722833415619],
					[58.825827983991232, 1.7773722774424123],
					[58.825827990345779, 1.7773722622081201],
					[58.825827991741214, 1.77737225496354],
					[58.825833485948479, 1.7773528776456882],
					[58.825844573969889, 1.7773333996832976],
					[58.825844577941488, 1.7773333901618475],
					[58.825844585981265, 1.7773333728369543],
					[58.825844589952851, 1.7773333633155075],
					[58.825869588185476, 1.7772583700603342],
					[58.825894583830852, 1.7771972776548985],
					[58.825911284324164, 1.7771583783563998],
					[58.825911284227551, 1.7771583766383945],
					[58.825911285912802, 1.7771583745478079],
					[58.82591128581619, 1.7771583728298075],
					[58.825930785638207, 1.7771083737358642],
					[58.825930787323443, 1.7771083716452762],
					[58.825930787226817, 1.7771083699272756],
					[58.825930788117738, 1.7771083697409826],
					[58.825950188304304, 1.7770556693873227],
					[58.825950188905402, 1.7770556640470139],
					[58.825950192780347, 1.7770556528074919],
					[58.82595019427238, 1.7770556472808869],
					[58.825958492352896, 1.7770222541376863],
					[58.825980790335024, 1.7769528640874661],
					[58.826016889457243, 1.7768500667471321],
					[58.826016889360631, 1.776850065029111],
					[58.826016890154946, 1.7768500631248032],
					[58.826016890058334, 1.7768500614067948],
					[58.826052990061001, 1.7767389619441549],
					[58.826052990855302, 1.7767389600398422],
					[58.826052992443913, 1.7767389562312059],
					[58.826052993238221, 1.7767389543268965],
					[58.82607239318267, 1.7766694537569081],
					[58.826072393880359, 1.7766694501345903],
					[58.826072396070039, 1.7766694409856181],
					[58.826072396767742, 1.776669437363275],
					[58.826080796182296, 1.7766250407577362],
					[58.826100191642368, 1.7765417569391797],
					[58.826111288379963, 1.7765111677736798],
					[58.826111290666255, 1.7765111603426953],
					[58.826111295045614, 1.7765111420447131],
					[58.8261112972353, 1.7765111328957139],
					[58.826114095652606, 1.7764944396309308],
					[58.82613909418076, 1.7763917465228998],
					[58.826139095071682, 1.7763917463365893],
					[58.826139094975055, 1.7763917446185762],
					[58.826155795374838, 1.7763194447404105],
					[58.826155796072534, 1.7763194411180561],
					[58.826155796673611, 1.7763194357777061],
					[58.826155797371285, 1.776319432155369],
					[58.826164097125819, 1.7762694347767436],
					[58.826186296232322, 1.7761444396120363],
					[58.826214095140983, 1.7760167435157308],
					[58.826214095935256, 1.7760167416113992],
					[58.826214095742031, 1.7760167381753713],
					[58.82621409653634, 1.7760167362710317],
					[58.82622239709373, 1.7759722365516128],
					[58.826222397694792, 1.77597223121125],
					[58.826222398800262, 1.7759722188124762],
					[58.826222400292238, 1.7759722132857885],
					[58.826227998572655, 1.7758861250197748],
					[58.826244596854025, 1.7757972372847972],
					[58.826244598249367, 1.7757972300400677],
					[58.826244599354844, 1.7757972176412924],
					[58.826244599859251, 1.7757972105828974],
					[58.826247399344389, 1.7757472177764702],
					[58.826258499319849, 1.7756528224554522],
					[58.82625849902999, 1.775652817301395],
					[58.826258500232058, 1.7756528066206312],
					[58.826258499845544, 1.7756527997485705],
					[58.826258499992996, 1.7756250280792178],
					[58.826264092485815, 1.7756056557171154],
					[58.826264093881157, 1.7756056484723717],
					[58.826264096671807, 1.775605633982908],
					[58.826264098067128, 1.7756056267381737],
					[58.826275198041529, 1.7755222273367286],
					[58.826283497711621, 1.7754611275064505],
					[58.826283499106943, 1.7754611202617019],
					[58.826283500115721, 1.7754611061448944],
					[58.826283499729207, 1.7754610992728226],
					[58.826283500154453, 1.7754500006000298],
					[58.82628349996822, 1.7754306054939877],
					[58.826289098680384, 1.77531672605504],
					[58.826297395727188, 1.7752778412492649],
					[58.826297397315749, 1.7752778374405522],
					[58.826297397626881, 1.7752778269461085],
					[58.826297399118829, 1.7752778214193587],
					[58.826302998894064, 1.7752250213032996],
					[58.826302998700811, 1.7752250178672653],
					[58.826303000192731, 1.7752250123405393],
					[58.82630299999947, 1.7752250089044939],
					[58.826305800067622, 1.7751639085292055],
					[58.826305799971003, 1.7751639068111906],
					[58.826305799681101, 1.7751639016571337],
					[58.826305799584475, 1.7751638999391142],
					[58.826305800061938, 1.7751028028137958],
					[58.826308500403755, 1.7750222064316836],
					[58.826308500307128, 1.7750222047136672],
					[58.826308500113853, 1.7750222012776233],
					[58.826308500017213, 1.7750221995596118],
					[58.826308499996678, 1.7750111096580781],
					[58.826316898705194, 1.7749306215346092],
					[58.826316899306207, 1.7749306161941971],
					[58.826316899907212, 1.7749306108538025],
					[58.826316899713937, 1.7749306074177587],
					[58.826319599704277, 1.7748583076298858],
					[58.826319599607622, 1.7748583059118741],
					[58.826319600305276, 1.7748583022894753],
					[58.826319600208635, 1.7748583005714604],
					[58.826319600143869, 1.7748249995625742],
					[58.826319599998833, 1.7747556094223811],
					[58.826325198802429, 1.7746972192102171],
					[58.826325198705788, 1.7746972174922022],
					[58.826325200101074, 1.7746972102474157],
					[58.82632520000444, 1.7746972085293977],
					[58.826328000138965, 1.7746333094716393],
					[58.826328000042317, 1.7746333077536309],
					[58.826327999752401, 1.7746333025995866],
					[58.82632799965576, 1.7746333008815607],
					[58.826327999606633, 1.7745833197839482],
					[58.82633629620944, 1.7745417388221747],
					[58.826336298398957, 1.7745417296730102],
					[58.826336300008649, 1.774541710215741],
					[58.826336300319724, 1.7745416997212797],
					[58.826336300269226, 1.7744417079298707],
					[58.826339099278918, 1.7744083167663283],
					[58.826339099976543, 1.7744083131439183],
					[58.826339100384253, 1.7744083043674719],
					[58.826339100094316, 1.7744082992134322],
					[58.82633910018383, 1.7743833034124799],
					[58.826344599617798, 1.7742556096664643],
					[58.826344600315423, 1.7742556060440671],
					[58.826344600122134, 1.7742556026080247],
					[58.826344599928831, 1.7742555991719999],
					[58.826344599999388, 1.7742360996597786],
					[58.826344599640777, 1.7742222008237152],
					[58.826344599826776, 1.7740611048121195],
					[58.826347399730601, 1.7740083112466625],
					[58.82634739963396, 1.7740083095286445],
					[58.826347400331557, 1.7740083059062488],
					[58.826347400234923, 1.7740083041882386],
					[58.826350200001556, 1.7738917052905756],
					[58.826350199904908, 1.7738917035725639],
					[58.82635019980826, 1.7738917018545477],
					[58.826350199711605, 1.7738917001365218],
					[58.82635019960567, 1.7738778064550924],
					[58.826358500288798, 1.7737361125340101],
					[58.826358500095488, 1.7737361090979902],
					[58.826358499708896, 1.7737361022259215],
					[58.826358499612219, 1.7737361005079131],
					[58.826358499969679, 1.7737138997006436],
					[58.826358499722346, 1.7736917042340887],
					[58.826364100072226, 1.7735722092153141],
					[58.826364099975549, 1.7735722074973026],
					[58.826364099685598, 1.7735722023432519],
					[58.826364099588943, 1.7735722006252419],
					[58.826364100269473, 1.7735417066227506],
					[58.826366898711463, 1.7735028139312863],
					[58.826366900299966, 1.7735028101224686],
					[58.826366899913353, 1.773502803250427],
					[58.826366899720028, 1.773502799814388],
					[58.826366900007081, 1.7734138994369251],
					[58.826366900006938, 1.7733666993863062],
					[58.826366899581977, 1.7733167043391211],
					[58.826369600059536, 1.7732528084673722],
					[58.826369599962874, 1.7732528067493574],
					[58.826369599672894, 1.7732528015953146],
					[58.826369599576246, 1.7732527998773047],
					[58.826369599872734, 1.7731944072506274],
					[58.826377998664732, 1.7730889155734946],
					[58.826378000253214, 1.7730889117646802],
					[58.826377999866587, 1.7730889048926211],
					[58.8263779995766, 1.7730888997385925],
					[58.826378000240176, 1.77302500375301],
					[58.826380800259216, 1.7729278101796189],
					[58.826386299872695, 1.772847213364191],
					[58.82638629967937, 1.7728472099281534],
					[58.826386300183614, 1.7728472028697138],
					[58.826386299990276, 1.7728471994336779],
					[58.826386300170014, 1.7727666998710294],
					[58.826386300416544, 1.7726750042973138],
					[58.826389100008498, 1.7726083098535097],
					[58.826394600084569, 1.7725000105349713],
					[58.826394599987886, 1.7725000088169645],
					[58.826394599697892, 1.7725000036629219],
					[58.826394600395453, 1.7725000000404927],
					[58.826394599807152, 1.7724472046396043],
					[58.826400200049605, 1.7723278082401281],
					[58.826400199952914, 1.772327806522126],
					[58.826400199662913, 1.7723278013680817],
					[58.826400199566251, 1.7723277996500701],
					[58.826400199915078, 1.7722833041278607],
					[58.826405800144251, 1.7721528090033403],
					[58.826405799950898, 1.772152805567309],
					[58.826405799757559, 1.7721528021312907],
					[58.826405799660868, 1.7721528004132885],
					[58.826405800439964, 1.7721222020138034],
					[58.826408500209396, 1.7719833044557745],
					[58.82640850011272, 1.7719833027377661],
					[58.826408500016058, 1.7719833010197545],
					[58.826408499919367, 1.7719832993017366],
					[58.826408500003041, 1.7719639022606815],
					[58.826411299872412, 1.7718028072342915],
					[58.826419600034953, 1.7716722127733036],
					[58.826419599841586, 1.7716722093372772],
					[58.826419600345766, 1.7716722022788121],
					[58.826419600249089, 1.771672200560807],
					[58.826419599550576, 1.7716389029539517],
					[58.826425199804092, 1.7714750072379057],
					[58.826425199707415, 1.7714750055198942],
					[58.826425200404962, 1.7714750018974443],
					[58.826425200308272, 1.7714750001794359],
					[58.826425200291844, 1.7714638997690824],
					[58.826425199706016, 1.7713083133079877],
					[58.826427998331098, 1.7712889285330233],
					[58.826427998835257, 1.7712889214745375],
					[58.826427999843609, 1.771288907357585],
					[58.826428000347789, 1.7712889002990979],
					[58.826428000120721, 1.7711417007434693],
					[58.826428000174765, 1.7711166995857377],
					[58.826427999863988, 1.7710832993140648],
					[58.826428000094452, 1.7709749997741964],
					[58.826428000009429, 1.7709417002684611],
					[58.82642800005663, 1.7709056004240789],
					[58.82642799994872, 1.7708083005392736],
					[58.826428000096335, 1.7707694003586398],
					[58.826428000398217, 1.7707305992240172],
					[58.826428000157897, 1.7706332993492118],
					[58.826428000252548, 1.7705943991725759],
					[58.826428000202256, 1.770558299335552],
					[58.826428000202256, 1.770558299335552],
					[58.826428000008853, 1.7705582958995512],
					[58.826427999912156, 1.7705582941815443],
					[58.826425199822431, 1.7704527952125062],
					[58.826425199338949, 1.7704527866224784],
					[58.826425197577777, 1.7704527713468945],
					[58.826425197190993, 1.77045276447488],
					[58.826419599832313, 1.7704221817670143],
					[58.826419600049036, 1.7703943993726641],
					[58.826419599855654, 1.7703943959366553],
					[58.826419600263058, 1.7703943871601675],
					[58.826419599082058, 1.7703943821926149],
					[58.826408500415369, 1.7702693910092815],
					[58.826408499639669, 1.7702499993282339],
					[58.826408499740594, 1.7702361003126201],
					[58.826408499643904, 1.7702360985946006],
					[58.826408499547206, 1.7702360968766002],
					[58.826408500341401, 1.7702360949721316],
					[58.826405800031509, 1.7701166952942655],
					[58.826405799741401, 1.7701166901402514],
					[58.826405799161208, 1.7701166798322279],
					[58.826405797883488, 1.7701166731466893],
					[58.826400198645629, 1.770074976565376],
					[58.826386298836368, 1.7699332797929344],
					[58.826386298642952, 1.7699332763569382],
					[58.826386297268535, 1.7699332679534103],
					[58.826386297075132, 1.7699332645173997],
					[58.826380798632051, 1.7699027759309494],
					[58.826377999673888, 1.7698582874293294],
					[58.826377999287068, 1.7698582805573371],
					[58.826377996634918, 1.7698582654682895],
					[58.826377996248112, 1.7698582585962765],
					[58.826364098188598, 1.7697916693056828],
					[58.826358499396939, 1.7697332803835439],
					[58.826358499203529, 1.7697332769475318],
					[58.826358497829091, 1.7697332685440277],
					[58.826358496744774, 1.7697332652945155],
					[58.826339096850148, 1.7696221658864666],
					[58.826339096656746, 1.7696221624504782],
					[58.826339096366617, 1.769622157296477],
					[58.826339095378998, 1.7696221557649714],
					[58.826325195339336, 1.7695610581434755],
					[58.82630299633616, 1.7694527593684941],
					[58.82630299633616, 1.7694527593684941],
					[58.826302996239455, 1.7694527576504999],
					[58.826302995251844, 1.769452756119001],
					[58.826289097410999, 1.7693888648078095],
					[58.826283498232506, 1.7693471736554678],
					[58.826283496954758, 1.7693471669699834],
					[58.826283494399249, 1.7693471535990093],
					[58.826283493121522, 1.7693471469135358],
					[58.826247393960742, 1.769216649322632],
					[58.82621409392798, 1.7690777526506944],
					[58.826214092843628, 1.7690777494012109],
					[58.826214090578247, 1.7690777411842622],
					[58.826214090384823, 1.7690777377482849],
					[58.826202991801651, 1.7690443438512817],
					[58.826194594058855, 1.7690110503236485],
					[58.826194593071214, 1.7690110487921717],
					[58.826194591986891, 1.7690110455426864],
					[58.826194591890165, 1.7690110438247035],
					[58.826152992171011, 1.7688721433111967],
					[58.826152990989939, 1.7688721383437556],
					[58.826152988027076, 1.7688721337493074],
					[58.826152986942738, 1.7688721304998525],
					[58.82611958997871, 1.7687832358871098],
					[58.826094592099494, 1.7686999427659973],
					[58.82609459002753, 1.7686999379850812],
					[58.826094586871207, 1.7686999299546975],
					[58.826094585786869, 1.7686999267052488],
					[58.826039093390946, 1.7685610447062248],
					[58.826033496750753, 1.768530563250678],
					[58.826033489160331, 1.768530540504561],
					[58.826033468634037, 1.7685304961314279],
					[58.826033455698173, 1.768530474504397],
					[58.826022378032512, 1.7685166037994411],
					[58.82596958857777, 1.7683610361767907],
					[58.825969588287613, 1.768361031022869],
					[58.825969585131283, 1.7683610229925473],
					[58.825969583059305, 1.7683610182116472],
					[58.825936283053693, 1.7682860204227824],
					[58.82589458407741, 1.7681888232868423],
					[58.825844585129772, 1.7680666233311562],
					[58.825825184719868, 1.7680193242313298],
					[58.82582518264789, 1.7680193194504854],
					[58.825825179588271, 1.7680193131381896],
					[58.825825177516279, 1.7680193083573514],
					[58.825747393275044, 1.7678666392875897],
					[58.825744598042235, 1.7678471724862299],
					[58.825744595389843, 1.7678471573976244],
					[58.825744585727293, 1.7678471298710401],
					[58.825744581293115, 1.767847115155512],
					[58.825683480738533, 1.7677166148667154],
					[58.825683480738533, 1.7677166148667154],
					[58.825683479654181, 1.7677166116173382],
					[58.825683478763267, 1.7677166118038683],
					[58.825664078715441, 1.7676777103175929],
					[58.825664078715441, 1.7676777103175929],
					[58.825664077824506, 1.7676777105041341],
					[58.825664077727779, 1.7676777087861797],
					[58.825577978155835, 1.7675082094377919],
					[58.825577970157987, 1.7675081954685095],
					[58.825577951683101, 1.7675081715254608],
					[58.82557794110928, 1.7675081598337514],
					[58.825566861804298, 1.7674970797791147],
					[58.82548078772335, 1.767338827353981],
					[58.825477994313736, 1.7673277506519287],
					[58.825477988988602, 1.7673277361231041],
					[58.825477975665585, 1.7673277076250939],
					[58.825477969449551, 1.7673276932828121],
					[58.82540017430032, 1.7672055017798616],
					[58.825383479435608, 1.7671721111748502],
					[58.82538347736358, 1.7671721063941139],
					[58.825383474303926, 1.7671721000819676],
					[58.825383472425386, 1.7671720987371209],
					[58.825294572428113, 1.7670220980798244],
					[58.825294566599005, 1.7670220906094218],
					[58.825294555831668, 1.7670220754820885],
					[58.825294550893467, 1.7670220678251367],
					[58.825275159724683, 1.7669998797209694],
					[58.825200169141915, 1.766885991437424],
					[58.82516406969274, 1.7668276957639386],
					[58.825164067620697, 1.7668276909832421],
					[58.825164063670115, 1.7668276848577433],
					[58.825164060803942, 1.766827681981525],
					[58.825111261295568, 1.7667554824025997],
					[58.825041861213649, 1.7666609819522141],
					[58.825041861116901, 1.7666609802342963],
					[58.825041860226001, 1.7666609804208486],
					[58.825025159900086, 1.766638780204334],
					[58.825025158021546, 1.7666387788595221],
					[58.82502515604623, 1.7666387757967992],
					[58.825025154070957, 1.7666387727340489],
					[58.82498625402738, 1.7665915730613087],
					[58.824986253039704, 1.7665915715299352],
					[58.824986249185876, 1.7665915671224102],
					[58.824986248198222, 1.7665915655910494],
					[58.824927957233704, 1.7665276744408847],
					[58.824902964241524, 1.7664915864158224],
					[58.824902960290927, 1.7664915802903858],
					[58.82490294971705, 1.7664915685992058],
					[58.824902944875554, 1.7664915626603295],
					[58.824841845431799, 1.76642766199454],
					[58.824841842662352, 1.7664276608363059],
					[58.824841837917603, 1.7664276566153569],
					[58.824841835942308, 1.7664276535526544],
					[58.824761238782813, 1.7663526566230006],
					[58.824744564742836, 1.766335982060919],
					[58.824739079077879, 1.7663249114862913],
					[58.824739067226076, 1.7663248931101485],
					[58.824739040055626, 1.7663248588220053],
					[58.824739023846099, 1.766324843096559],
					[58.824658424224573, 1.7662609435489731],
					[58.824658423236905, 1.7662609420176361],
					[58.824658422346012, 1.7662609422041964],
					[58.824597324071561, 1.7662137423430544],
					[58.824569524768357, 1.7661915446540597],
					[58.824569517157407, 1.7661915375570765],
					[58.824569501334857, 1.7661915287033649],
					[58.824569492929747, 1.7661915235108343],
					[58.824511199138605, 1.7661609252072936],
					[58.824475124789558, 1.7661387414546643],
					[58.824464040734249, 1.7661276596713993],
					[58.824464028281795, 1.7661276466356923],
					[58.824463999212604, 1.7661276266507837],
					[58.824463984377687, 1.766127619328455],
					[58.824386184288649, 1.766091518407956],
					[58.824386183397749, 1.7660915185945194],
					[58.824386180628302, 1.7660915174363301],
					[58.824386179737402, 1.7660915176228935],
					[58.824341779701946, 1.7660720178400005],
					[58.824341775944845, 1.7660720151504901],
					[58.824341767636483, 1.766072011675909],
					[58.824341762988496, 1.7660720091729587],
					[58.824300062562806, 1.7660581093060201],
					[58.824300058902445, 1.7660581083343974],
					[58.824300050690837, 1.7660581065776959],
					[58.824300047030491, 1.7660581056060698],
					[58.824230646601578, 1.7660415052026091],
					[58.824230640268517, 1.7660415047906826],
					[58.824230626614764, 1.7660415024354963],
					[58.824230620184956, 1.7660415003056917],
					[58.82420282629522, 1.7660387012094987],
					[58.82413613262905, 1.7660276036239435],
					[58.824136125308343, 1.7660276016807106],
					[58.824136108090997, 1.7660276000717872],
					[58.824136099976151, 1.766027600032986],
					[58.824119399611739, 1.7660276001347988],
					[58.824038900304146, 1.7660275993443786],
					[58.824024999776483, 1.7660276006180682],
					[58.82402499433433, 1.766027600019564],
					[58.824024981764978, 1.7660276009135767],
					[58.824024976322846, 1.7660276003150885],
					[58.823955575735233, 1.7660359005074451],
					[58.823955574941081, 1.7660359024118721],
					[58.823955573159282, 1.7660359027849972],
					[58.823955572171627, 1.766035901253697],
					[58.823936072028147, 1.7660387020156485],
					[58.823936067573669, 1.766038702948459],
					[58.823936061337349, 1.7660387042543948],
					[58.823936056882864, 1.7660387051872006],
					[58.823872156535053, 1.7660526043987477],
					[58.823872151189647, 1.7660526055181121],
					[58.823872137032005, 1.7660526102209551],
					[58.823872130001554, 1.766052613431305],
					[58.823849933289821, 1.7660609116444612],
					[58.823791636889375, 1.766080410714937],
					[58.823758236622943, 1.7660915103609205],
					[58.823758233156099, 1.7660915128250123],
					[58.823758224247101, 1.766091514690608],
					[58.823758219889328, 1.7660915173412586],
					[58.823713820277447, 1.7661109167760101],
					[58.823713819386548, 1.7661109169625655],
					[58.823713815822956, 1.7661109177088015],
					[58.823713815028796, 1.7661109196132143],
					[58.823666614740418, 1.7661331198203813],
					[58.823666606819053, 1.7661331232172519],
					[58.823666591073135, 1.7661331317288456],
					[58.823666584139424, 1.7661331366570117],
					[58.823647088808059, 1.7661470341617571],
					[58.823580508789881, 1.7661887216044572],
					[58.823566626327555, 1.7661942131734025],
					[58.823566619297097, 1.7661942163836923],
					[58.823566605332935, 1.7661942245221338],
					[58.823566598302484, 1.7661942277324172],
					[58.823505498041754, 1.7662304274695886],
					[58.823505491998944, 1.766230432211169],
					[58.823505478925682, 1.7662304401630009],
					[58.823505472979647, 1.7662304466224212],
					[58.823488776218653, 1.766244242912165],
					[58.823444282344326, 1.7662776377772735],
					[58.82339708513377, 1.7663109364032403],
					[58.82339707820006, 1.7663109413313238],
					[58.8233970653203, 1.7663109527187895],
					[58.82339705759243, 1.7663109595512809],
					[58.823380466147064, 1.7663276502286811],
					[58.823322073621213, 1.7663748443256801],
					[58.823322053303805, 1.7663748676990532],
					[58.823322019389089, 1.7663749217291378],
					[58.823322005694997, 1.7663749506680173],
					[58.823319229363882, 1.7663859623190146],
					[58.823302711124619, 1.7663942217403383],
					[58.823302697063717, 1.7663942281607858],
					[58.82330267111066, 1.7663942474999133],
					[58.823302659121786, 1.7663942587007693],
					[58.823258169795437, 1.766438746452653],
					[58.823219284253433, 1.7664665360955889],
					[58.823219274743764, 1.7664665433010815],
					[58.823219260275692, 1.7664665584971881],
					[58.823219251656923, 1.7664665655161333],
					[58.823191557821353, 1.7664970573401324],
					[58.823130465377858, 1.7665526514720291],
					[58.823130463692799, 1.7665526535629352],
					[58.823130460322702, 1.7665526577447748],
					[58.823130459431802, 1.7665526579313158],
					[58.823069258809994, 1.7666137581523633],
					[58.82306925286391, 1.7666137646116358],
					[58.823069242656835, 1.7666137754392504],
					[58.823069237601651, 1.7666137817119643],
					[58.82304704999423, 1.7666442657526069],
					[58.823013764718382, 1.7666748538814117],
					[58.823013761251509, 1.7666748563453858],
					[58.823013752729466, 1.7666748650820705],
					[58.823013749359355, 1.7666748692638812],
					[58.822960960677541, 1.7667359545105943],
					[58.822947075303794, 1.7667470446874352],
					[58.82294706668501, 1.7667470517062498],
					[58.822947051326011, 1.7667470670886463],
					[58.822947043694846, 1.7667470756387453],
					[58.822902652990052, 1.7668026637024077],
					[58.822863764242534, 1.7668387525652585],
					[58.822863756611376, 1.7668387611153227],
					[58.822863743034148, 1.7668387761245636],
					[58.822863736293904, 1.7668387844881011],
					[58.822844242791867, 1.7668665767085601],
					[58.822802650361822, 1.7669137676761351],
					[58.822783151016402, 1.7669359661877324],
					[58.822752659032695, 1.7669692573541727],
					[58.822727667346349, 1.7669915507294249],
					[58.822727659618451, 1.7669915575616175],
					[58.822727646835332, 1.7669915706664092],
					[58.822727640986002, 1.766991578843345],
					[58.822691548827137, 1.7670387684938489],
					[58.822674859003754, 1.7670554581808595],
					[58.822638758914941, 1.7670915587915978],
					[58.822638752872116, 1.7670915635328825],
					[58.822638742761718, 1.7670915760780317],
					[58.822638737706519, 1.7670915823505944],
					[58.82261655165663, 1.7671220636020577],
					[58.822597069903495, 1.7671387485401773],
					[58.822597064751548, 1.7671387530949383],
					[58.822597055435303, 1.767138763735721],
					[58.822597050283377, 1.7671387682904582],
					[58.822555450108403, 1.7671859671283536],
					[58.822513760166629, 1.7672331568310171],
					[58.822497072163671, 1.7672470459458234],
					[58.822497066120846, 1.7672470506870508],
					[58.822497055913686, 1.7672470615142952],
					[58.822497049870833, 1.7672470662555353],
					[58.822474851688149, 1.7672720662611969],
					[58.822441552956796, 1.7673081639059709],
					[58.822441547901569, 1.767308170178467],
					[58.822441537791136, 1.7673081827234636],
					[58.822441533626865, 1.7673081888094471],
					[58.822424848945495, 1.7673331659034741],
					[58.822383168999409, 1.7673692487648567],
					[58.822383163847455, 1.7673692533195398],
					[58.822383153640295, 1.7673692641467094],
					[58.822383149379242, 1.7673692685148703],
					[58.822363753630455, 1.7673915629681429],
					[58.822330459387011, 1.7674248594419957],
					[58.822330441645377, 1.767424880536705],
					[58.822330416252235, 1.7674249258278705],
					[58.822330405928042, 1.7674249505838433],
					[58.822327624229779, 1.7674359803191058],
					[58.822308184200928, 1.7674498370834217],
					[58.822308176376268, 1.7674498421976199],
					[58.822308161017141, 1.7674498575793962],
					[58.822308154180121, 1.7674498642248886],
					[58.822269255663322, 1.7674915606194634],
					[58.822247059185294, 1.7675137582100773],
					[58.822208159131137, 1.7675526582720142],
					[58.822208158240237, 1.7675526584585251],
					[58.822180458698043, 1.7675804591347688],
					[58.822149863178488, 1.7676109534084232],
					[58.822127667812303, 1.7676304497019486],
					[58.822127666127223, 1.7676304517927404],
					[58.822127660975269, 1.7676304563473597],
					[58.822127659290189, 1.767630458438161],
					[58.822085959109906, 1.7676720578567984],
					[58.822085959206646, 1.7676720595745952],
					[58.822063759225713, 1.7676942593889717],
					[58.822024870109757, 1.7677331462990129],
					[58.822005484230132, 1.7677470366094916],
					[58.82200547303529, 1.7677470459051507],
					[58.822005454209254, 1.7677470637504433],
					[58.822005444796226, 1.7677470726731013],
					[58.821980464244213, 1.767777649510595],
					[58.821963788628693, 1.7677887338319822],
					[58.821963780804005, 1.7677887389460518],
					[58.821963766239023, 1.7677887524232405],
					[58.821963759305234, 1.7677887573508182],
					[58.821944259170536, 1.7678081584572072],
					[58.821944255703649, 1.7678081609209793],
					[58.821944250648428, 1.7678081671933004],
					[58.821944248072448, 1.7678081694705776],
					[58.821910963651646, 1.7678470525094909],
					[58.821891583703561, 1.7678609362476474],
					[58.821891577757427, 1.7678609427064715],
					[58.821891568247672, 1.7678609499112785],
					[58.821891563095697, 1.7678609544658361],
					[58.821841565169294, 1.7679081521856521],
					[58.821797068066225, 1.7679470497920085],
					[58.821797056968094, 1.7679470608053121],
					[58.821797036747107, 1.767947085894445],
					[58.821797028321704, 1.767947096348254],
					[58.821788743332739, 1.7679609724547458],
					[58.821755469779639, 1.7679915486535285],
					[58.821727682236855, 1.7680137375742617],
					[58.82171098916568, 1.7680248322940735],
					[58.821710983219525, 1.7680248387528354],
					[58.821710970242862, 1.7680248484212924],
					[58.821710964199973, 1.7680248531622955],
					[58.821674863980206, 1.7680581534675348],
					[58.821674858828217, 1.768058158022032],
					[58.82167484862098, 1.7680581688487624],
					[58.821674843565738, 1.7680581751209976],
					[58.821663754848743, 1.7680720610342895],
					[58.821624869700621, 1.7681054488736097],
					[58.821624868809735, 1.7681054490600951],
					[58.821624867918821, 1.7681054492465902],
					[58.821602668830145, 1.7681248483395848],
					[58.821547071399792, 1.7681720476845229],
					[58.821547067932883, 1.7681720501482361],
					[58.821547061986735, 1.7681720566069361],
					[58.821547059410726, 1.7681720588841623],
					[58.821533159140195, 1.7681859596950402],
					[58.821522067127077, 1.7681970497519568],
					[58.821472077418349, 1.7682359422843743],
					[58.821472073951469, 1.7682359447480718],
					[58.821472067908566, 1.768235949489005],
					[58.821472065332586, 1.7682359517662121],
					[58.821441569341786, 1.7682637491519986],
					[58.821410973288302, 1.7682887449275972],
					[58.821410972397402, 1.7682887451140812],
					[58.821410969821407, 1.7682887473912852],
					[58.821410968136327, 1.7682887494820148],
					[58.821366577882507, 1.7683276410670485],
					[58.821349889136407, 1.7683387328582261],
					[58.821349881408423, 1.7683387396898158],
					[58.821349865855751, 1.7683387516352589],
					[58.821349859018667, 1.7683387582803789],
					[58.821338767086864, 1.7683498499437842],
					[58.82128597659338, 1.7683915424725094],
					[58.821260976818579, 1.7684109416976734],
					[58.821260975133484, 1.7684109437883762],
					[58.821260970775683, 1.7684109464385287],
					[58.821260969090595, 1.7684109485292299],
					[58.82121927174498, 1.7684470462658359],
					[58.82117767479928, 1.7684804446635372],
					[58.82117767390838, 1.7684804448500147],
					[58.821177671332386, 1.7684804471271853],
					[58.821177669550565, 1.7684804475001499],
					[58.821158173652236, 1.768497043473459],
					[58.821110977731166, 1.7685331413623151],
					[58.821110973470063, 1.7685331457301885],
					[58.821110963069351, 1.7685331531211121],
					[58.821110958904988, 1.7685331592067044],
					[58.821094276584574, 1.7685498405353381],
					[58.821074901291936, 1.7685609261240953],
					[58.821074895152336, 1.768560929147152],
					[58.821074884751617, 1.7685609365380757],
					[58.821074880393809, 1.7685609391881902],
					[58.821030486871024, 1.7685942344040015],
					[58.820994295179347, 1.7686165303013988],
					[58.820994287354623, 1.7686165354151169],
					[58.82099427259611, 1.7686165454561009],
					[58.820994265759012, 1.7686165521010764],
					[58.820963780601701, 1.7686442386994827],
					[58.820930499196841, 1.7686637268200305],
					[58.820930487905208, 1.7686637343973459],
					[58.820930468091383, 1.7686637507103011],
					[58.820930458581564, 1.7686637579146869],
					[58.820910988313464, 1.7686831307747877],
					[58.820897125384896, 1.7686887140547489],
					[58.820897112214716, 1.7686887202872765],
					[58.820897086067859, 1.7686887361877726],
					[58.82089707299442, 1.7686887441380381],
					[58.820866578771714, 1.7687137403731856],
					[58.82083048584785, 1.7687387352652326],
					[58.820830485053676, 1.7687387371694179],
					[58.820830482380927, 1.7687387377288331],
					[58.820830480695847, 1.7687387398194927],
					[58.820819281047307, 1.768747038213333],
					[58.820766586389738, 1.7687859355821338],
					[58.820727692018714, 1.7688109327073211],
					[58.820727683303083, 1.7688109380074215],
					[58.820727666956152, 1.7688109518566077],
					[58.820727659228154, 1.7688109586879683],
					[58.82070267036184, 1.7688359477207831],
					[58.820641585637503, 1.7688804364982065],
					[58.820616589347452, 1.7688970326499003],
					[58.820616586771457, 1.7688970349269977],
					[58.820616582413628, 1.7688970375770343],
					[58.820616579837619, 1.7688970398541302],
					[58.820572089889005, 1.768930432526771],
					[58.820552700591122, 1.7689415258518393],
					[58.820552695439119, 1.7689415304060216],
					[58.820552683256579, 1.7689415381696347],
					[58.820552677116936, 1.7689415411925817],
					[58.820502680396949, 1.7689804398325024],
					[58.820460982284978, 1.7690109382321726],
					[58.820460977927176, 1.769010940882171],
					[58.82046097109005, 1.7690109475269795],
					[58.820460967623141, 1.7690109499905162],
					[58.820438772993406, 1.7690304458989592],
					[58.820377678399652, 1.7690776419861234],
					[58.820377674932743, 1.769077644449649],
					[58.820377670574914, 1.7690776470996443],
					[58.820377668889819, 1.7690776491902707],
					[58.820313772244539, 1.7691331462421365],
					[58.820285975413121, 1.7691554446327356],
					[58.820285971849486, 1.7691554453785581],
					[58.820285966697469, 1.7691554499326909],
					[58.820285965109079, 1.7691554537409901],
					[58.820252667074726, 1.7691859507446228],
					[58.820191573074275, 1.7692387457593648],
					[58.820166576612742, 1.7692581412447008],
					[58.820166569775608, 1.7692581478893947],
					[58.820166556101334, 1.7692581611787717],
					[58.820166549360913, 1.7692581695411338],
					[58.820130462089452, 1.769299853165341],
					[58.820105477005441, 1.7693192427103661],
					[58.820105474429411, 1.769319244987408],
					[58.820105466701385, 1.769319251818503],
					[58.820105464125355, 1.7693192540955625],
					[58.820063763879531, 1.7693581532612763],
					[58.820063759618385, 1.7693581576289046],
					[58.820063753672159, 1.7693581640870926],
					[58.820063751096143, 1.7693581663641262],
					[58.820019253916421, 1.7694081634597731],
					[58.820008167028242, 1.7694192497741994],
					[58.819983176937605, 1.7694387427397402],
					[58.819983171688875, 1.7694387455761114],
					[58.819983163166604, 1.7694387543112917],
					[58.819983158905472, 1.769438758678896],
					[58.819947059217661, 1.7694748587973259],
					[58.819947054065636, 1.7694748633513475],
					[58.819947045640077, 1.769474873804199],
					[58.819947042173133, 1.769474876267666],
					[58.819927645016818, 1.7694998723001754],
					[58.819891556958218, 1.7695415589931505],
					[58.819838766675325, 1.7695887516506319],
					[58.819838756467945, 1.7695887624763065],
					[58.81983873704079, 1.7695887856588894],
					[58.819838727724317, 1.7695887962981214],
					[58.819830441041987, 1.7696026767295348],
					[58.819816558525723, 1.7696165585784482],
					[58.819816555949686, 1.7696165608554439],
					[58.819816551688575, 1.7696165652229991],
					[58.819816550003452, 1.7696165673135571],
					[58.81977485436181, 1.7696637633097223],
					[58.819752659324571, 1.769685959157564],
					[58.819752656651865, 1.7696859597168755],
					[58.819752655063446, 1.7696859635251048],
					[58.819752653281633, 1.7696859638979787],
					[58.819716559430859, 1.7697248585584147],
					[58.819655465044626, 1.7697804515371758],
					[58.819655460880199, 1.769780457622361],
					[58.819655450576107, 1.769780466730237],
					[58.819655447302566, 1.769780472628983],
					[58.8196415540692, 1.7697970624850072],
					[58.8195942625238, 1.7698415534867784],
					[58.819594261729591, 1.7698415553908748],
					[58.819594260044482, 1.7698415574814024],
					[58.819594259250273, 1.7698415593854957],
					[58.819572060613893, 1.7698637557426424],
					[58.819533166290483, 1.7698998523302383],
					[58.819483180787962, 1.7699442376761698],
					[58.819469297456727, 1.7699526293962311],
					[58.819469287055902, 1.7699526367863643],
					[58.819469268036123, 1.7699526511937507],
					[58.819469258526212, 1.7699526583974414],
					[58.819447066801374, 1.7699748496717105],
					[58.819408176340119, 1.77000544301839],
					[58.819408175449219, 1.7700054432048136],
					[58.819408174558312, 1.7700054433912433],
					[58.819394275176769, 1.7700165443185976],
					[58.819394273394956, 1.7700165446914509],
					[58.819394272600725, 1.7700165465955298],
					[58.819347088219473, 1.7700554324220736],
					[58.819305506461198, 1.7700776247247973],
					[58.819305501212433, 1.7700776275609982],
					[58.819305490714939, 1.7700776332333823],
					[58.819305486357081, 1.7700776358831722],
					[58.819277691877197, 1.7700970317552684],
					[58.819216610795891, 1.7701331197425809],
					[58.819202725009355, 1.7701387138388629],
					[58.819202723324231, 1.7701387159293491],
					[58.819202718075474, 1.7701387187655435],
					[58.819202716293667, 1.7701387191383906],
					[58.819161016086554, 1.7701581189177853],
					[58.819161008165061, 1.770158122313223],
					[58.819160993406399, 1.77015813235297],
					[58.819160984594014, 1.7701581359348215],
					[58.819133212054169, 1.7701776174288286],
					[58.819058243106248, 1.7701998075920073],
					[58.819058242312039, 1.7701998094960656],
					[58.819058238748383, 1.7701998102417482],
					[58.819058236966569, 1.7701998106145855],
					[58.819033240352162, 1.7702081084067716],
					[58.818977750709379, 1.7702248061137431],
					[58.818949965437106, 1.7702304028067875],
					[58.81889437793668, 1.7702387009510669],
					[58.818855585993134, 1.7702415018011406],
					[58.818855583223723, 1.7702415006427539],
					[58.818855578769167, 1.7702415015748392],
					[58.818855576890655, 1.7702415002300467],
					[58.818808287924504, 1.7702470000798731],
					[58.818766699935622, 1.7702470006112547],
					[58.818713915532882, 1.770246999535475],
					[58.818680632814853, 1.7702415032700207],
					[58.818680629154514, 1.7702415022980449],
					[58.81868062183387, 1.7702415003541345],
					[58.81868061827025, 1.7702415010997963],
					[58.818619435890987, 1.7702359026464423],
					[58.818588953059312, 1.7702276065274207],
					[58.818588951277491, 1.7702276069002516],
					[58.818588946726244, 1.7702276061146991],
					[58.818588944847754, 1.7702276047699113],
					[58.818516745206459, 1.7702109041353153],
					[58.818516741642824, 1.7702109048809693],
					[58.818516736103973, 1.7702109025642279],
					[58.818516733431267, 1.7702109031234745],
					[58.818500045129987, 1.7702081057511658],
					[58.818425057163942, 1.7701859079151341],
					[58.818425049843285, 1.7701859059712284],
					[58.818425036189581, 1.7701859036146392],
					[58.818425028868923, 1.7701859016707304],
					[58.818405651755533, 1.7701831056036816],
					[58.818336176725346, 1.7701554149540502],
					[58.818316789099498, 1.7701470200642417],
					[58.818291796977348, 1.7701331245962728],
					[58.818291793317016, 1.7701331236243349],
					[58.818291784912098, 1.7701331184316595],
					[58.818291781251759, 1.7701331174597235],
					[58.818266781574032, 1.7701220180263568],
					[58.81823068705792, 1.7701054198774593],
					[58.818191789897412, 1.7700859203699038],
					[58.818191786237101, 1.7700859193979708],
					[58.818191777928845, 1.7700859159229205],
					[58.818191774171822, 1.7700859132333973],
					[58.818163977610176, 1.7700748169159486],
					[58.81813343484994, 1.7700609412325468],
					[58.818127966692998, 1.7700526907188323],
					[58.818127950193855, 1.7700526698415935],
					[58.818127911345975, 1.7700526362624309],
					[58.818127888900548, 1.7700526218428965],
					[58.818072289226997, 1.7700248209880631],
					[58.818050093937828, 1.7700137240120661],
					[58.818030706703333, 1.7700026303403713],
					[58.817986213704309, 1.7699720364130718],
					[58.817958413850477, 1.7699526358990372],
					[58.817958410984353, 1.7699526330231288],
					[58.817958402676091, 1.7699526295481296],
					[58.817958398919082, 1.7699526268586334],
					[58.817919509555466, 1.7699304324058898],
					[58.81789732016238, 1.7699137402729117],
					[58.817897312648334, 1.7699137348939273],
					[58.817897297716947, 1.7699137258535507],
					[58.817897290202886, 1.769913720474571],
					[58.81786401937881, 1.7698970358101382],
					[58.817855740913288, 1.7698887582416174],
					[58.817855735181062, 1.7698887524898068],
					[58.817855722825705, 1.7698887411726203],
					[58.817855716299242, 1.7698887373248091],
					[58.817816816908369, 1.7698609371838163],
					[58.81778624659156, 1.7698387591048814],
					[58.817780766571467, 1.7698304903321465],
					[58.817780751059892, 1.7698304709863844],
					[58.817780714981367, 1.7698304385660968],
					[58.817780695208647, 1.7698304235875815],
					[58.817744515413814, 1.7698109346904993],
					[58.81772233213443, 1.7697915495681549],
					[58.817722330255926, 1.7697915482234259],
					[58.817722326402205, 1.7697915438163763],
					[58.817722323632793, 1.7697915426580721],
					[58.817683424355387, 1.7697609430944039],
					[58.817683423464487, 1.769760943280821],
					[58.817683423367782, 1.7697609415632578],
					[58.817658423235436, 1.7697415411699269],
					[58.817658422344529, 1.7697415413563533],
					[58.817658422344529, 1.7697415413563533],
					[58.817658421453629, 1.7697415415427673],
					[58.817600170035185, 1.7696970782558756],
					[58.81759739026235, 1.7696888353738209],
					[58.817597374267272, 1.7696888074403865],
					[58.81759733534323, 1.769688756500015],
					[58.817597312414293, 1.7696887334930975],
					[58.817589022875616, 1.7696831415113279],
					[58.81753623656936, 1.7696359551674892],
					[58.81750574141217, 1.7696054585319119],
					[58.817505740424565, 1.7696054570007771],
					[58.817505735776621, 1.7696054544977664],
					[58.817505733801404, 1.7696054514354933],
					[58.817475144917061, 1.7695776610926746],
					[58.817433453881371, 1.7695276719653144],
					[58.817433448052419, 1.769527664496076],
					[58.817433434806105, 1.7695276533655562],
					[58.817433428182973, 1.7695276478002997],
					[58.817416835248572, 1.7695137524323623],
					[58.817386257045712, 1.7694831743224193],
					[58.817366868885777, 1.7694526928158836],
					[58.817366865922963, 1.7694526882225043],
					[58.817366860094026, 1.7694526807532913],
					[58.817366857131191, 1.7694526761599039],
					[58.817325157384211, 1.7693998756710709],
					[58.817325156396613, 1.7693998741399457],
					[58.817325155409016, 1.7693998726088203],
					[58.81732515353049, 1.769399871264131],
					[58.817311253875609, 1.7693831724051434],
					[58.817311252888011, 1.7693831708740244],
					[58.8173112510095, 1.7693831695293112],
					[58.81731125091278, 1.7693831678117673],
					[58.817291862896234, 1.7693609825768291],
					[58.817261273009542, 1.7693082000103184],
					[58.817261272021938, 1.7693081984792058],
					[58.817261271034326, 1.7693081969480708],
					[58.817261270937635, 1.7693081952305267],
					[58.817230770989426, 1.769258195237029],
					[58.817230770098512, 1.7692581954234587],
					[58.817230769014209, 1.7692581921748021],
					[58.817230769014209, 1.7692581921748021],
					[58.81721128075506, 1.7692277110121239],
					[58.817197387979341, 1.7691888332776682],
					[58.817197384726413, 1.7691888235317028],
					[58.817197376632116, 1.7691888078477103],
					[58.817197372584971, 1.769188800005717],
					[58.817166881600301, 1.7691360144024955],
					[58.817141887599988, 1.7690666314630812],
					[58.817141885431361, 1.7690666249658027],
					[58.81714187822795, 1.7690666090954297],
					[58.81714187516841, 1.7690666027845667],
					[58.817127980035423, 1.7690416122561183],
					[58.817119589169344, 1.7690221342701933],
					[58.81709739361559, 1.768938849299565],
					[58.817075193895711, 1.7688499522868317],
					[58.817075191630344, 1.7688499440720282],
					[58.817075187196345, 1.768849929359964],
					[58.81707518502769, 1.7688499228626917],
					[58.817061293801089, 1.7688166459371517],
					[58.81704739811402, 1.7687249703476293],
					[58.817047396932978, 1.7687249653814778],
					[58.817047395655237, 1.7687249586977742],
					[58.817047395461827, 1.7687249552627133],
					[58.817039100142182, 1.7686888773004823],
					[58.817039100216057, 1.7686778007173527],
					[58.817039100422527, 1.7686556006185614],
					[58.817039099842255, 1.7686555903133607],
					[58.817039097984249, 1.7686555733244596],
					[58.817039096609783, 1.7686555649232312],
					[58.81701959965141, 1.7685499812707861],
					[58.81701960029374, 1.7685028006075072],
					[58.817019600100309, 1.7685027971724461],
					[58.817019599906899, 1.7685027937373898],
					[58.817019599713454, 1.7685027903023129],
					[58.817016900165441, 1.7684416903988136],
					[58.817016900165441, 1.7684416903988136],
					[58.81701690006873, 1.7684416886812806],
					[58.817016899972018, 1.7684416869637509],
					[58.817014100286571, 1.7683999876637664],
					[58.817014099202233, 1.768399984415159],
					[58.81701409881537, 1.7683999775450256],
					[58.817014097731047, 1.7683999742964198],
					[58.817005799039705, 1.7683332793295512],
					[58.817000200330007, 1.7682555926884105],
					[58.817000199596258, 1.7682388998019685],
					[58.817000200390453, 1.7682388978979866],
					[58.817000200197015, 1.7682388944629113],
					[58.817000200100296, 1.7682388927453863],
					[58.816997400330564, 1.7681610964865351],
					[58.816997399913618, 1.7680722008306724],
					[58.816997400035945, 1.7679832998404232],
					[58.816997399571392, 1.767908299408697],
					[58.816997399651633, 1.7678889006350762],
					[58.81699740002292, 1.7678139000210313],
					[58.816997400350466, 1.7677388994103036],
					[58.816997399871482, 1.7677111038309576],
					[58.817000200075817, 1.7676361062906372],
					[58.817000200075817, 1.7676361062906372],
					[58.817000199882365, 1.7676361028555905],
					[58.817000199688913, 1.7676360994205389],
					[58.817000200100338, 1.7675750113865596],
					[58.817005798593868, 1.7675278233372265],
					[58.817005799194597, 1.7675278179981613],
					[58.817005800299306, 1.7675278056025234],
					[58.817005800009134, 1.7675278004499311],
					[58.817005799691806, 1.7674667003872577],
					[58.817005800424596, 1.7674167114279866],
					[58.817014098574745, 1.7673389208329082],
					[58.817014099175459, 1.767338915493835],
					[58.817014100376888, 1.7673389048157158],
					[58.817014100086702, 1.7673388996631234],
					[58.817014099865872, 1.7672944005149178],
					[58.817014099914545, 1.7672528033920194],
					[58.817016900445765, 1.7671583061002951],
					[58.817016900349024, 1.7671583043827732],
					[58.817016900155572, 1.7671583009477234],
					[58.817016900058832, 1.7671582992301951],
					[58.817016899627866, 1.7671193992254015],
					[58.817016900050284, 1.7670889011833497],
					[58.817019600351706, 1.7669750051938624],
					[58.817019600254966, 1.7669750034763358],
					[58.81701960015824, 1.7669750017588093],
					[58.817019600061499, 1.7669750000412876],
					[58.817019600114449, 1.7669472008350422],
					[58.817019600022284, 1.7669250007713548],
					[58.817019600081231, 1.7667888999024897],
					[58.817019599660796, 1.7667721999645083],
					[58.817019600394389, 1.7667556005756826],
					[58.817019600399746, 1.766613900579286],
					[58.817019599894088, 1.7666000002135522],
					[58.817019600215602, 1.7665888992319232],
					[58.817019600383304, 1.7664388995565721],
					[58.817019599896746, 1.7664278004800191],
					[58.817019600203373, 1.7664166994995019],
					[58.817019600225386, 1.7662639002693692],
					[58.817019599626967, 1.7662527994764425],
					[58.81701960001525, 1.7662417002146],
					[58.81701959991851, 1.7662416984970783],
					[58.817019599821762, 1.766241696779558],
					[58.817019599821762, 1.766241696779558],
					[58.817016899894512, 1.7660916975367911],
					[58.81701690036563, 1.7660805999944087],
					[58.81701689980234, 1.7660666996341201],
					[58.81701689980234, 1.7660666996341201],
					[58.8170168997056, 1.766066697916584],
					[58.817016899608859, 1.7660666961990636],
					[58.817014100102291, 1.765924998309915],
					[58.817014099666437, 1.7659083001006113],
					[58.81701410023885, 1.7658888994364084],
					[58.817014100142096, 1.7658888977188898],
					[58.817014099755113, 1.7658888908487931],
					[58.817014100452518, 1.7658888872272369],
					[58.817005800277933, 1.7657582932054106],
					[58.817005800411366, 1.7657333004682165],
					[58.817005798843212, 1.7657332886320716],
					[58.817005796694552, 1.7657332664908199],
					[58.817005795223167, 1.7657332563722097],
					[58.817000199124642, 1.7657082744314132],
					[58.816997399699694, 1.7655971961991013],
					[58.816997399938074, 1.76556110078503],
					[58.81699739987608, 1.7655250002191358],
					[58.816997399779325, 1.7655249985016188],
					[58.816997399585809, 1.7655249950665832],
					[58.816997400379968, 1.7655249931625394],
					[58.816994600122769, 1.7654332965346258],
					[58.816994599605955, 1.7653888999142213],
					[58.816994600206598, 1.7653888945751226],
					[58.816994598735157, 1.7653888844565377],
					[58.8169945993358, 1.7653888791174424],
					[58.816989099225616, 1.7653332800964749],
					[58.816983499244301, 1.7652749806419143],
					[58.816977999610501, 1.7652138867010154],
					[58.816975200123515, 1.7651416965012914],
					[58.816975199743872, 1.7651194009070057],
					[58.816975200151013, 1.7651193921328716],
					[58.816975199376941, 1.7651193783927419],
					[58.816975198098994, 1.7651193717092053],
					[58.816964099329994, 1.7650416779054092],
					[58.816958500228871, 1.7649666922127727],
					[58.816958500235579, 1.7649388999585203],
					[58.81695850004207, 1.764938896523494],
					[58.816958499558275, 1.7649388879359074],
					[58.816958499364745, 1.7649388845008878],
					[58.816952999236278, 1.7648721846747055],
					[58.816952998945993, 1.7648721795221498],
					[58.816952997571292, 1.7648721711211122],
					[58.816952997377761, 1.7648721676860877],
					[58.816944598391089, 1.7648221736126166],
					[58.816936300045505, 1.7647305909020337],
					[58.816936300119586, 1.7647000008263591],
					[58.816936299732532, 1.7646999939562926],
					[58.816936298958424, 1.7646999802161913],
					[58.816936297777232, 1.7646999752501815],
					[58.816933498450588, 1.7646777756909935],
					[58.816933498257058, 1.7646777722559532],
					[58.816933498063555, 1.7646777688209272],
					[58.816933497075865, 1.7646777672899656],
					[58.816916898940306, 1.7645777774192519],
					[58.816914099675429, 1.7645360922002955],
					[58.816914100303237, 1.7645249998535544],
					[58.8169140998194, 1.7645249912659964],
					[58.816914097960833, 1.7645249742774165],
					[58.816914096682886, 1.7645249675939119],
					[58.81691129712214, 1.7645082681643962],
					[58.816894597647206, 1.7643999704915836],
					[58.816894596562761, 1.7643999672431208],
					[58.816894597356892, 1.7643999653390436],
					[58.816894597356892, 1.7643999653390436],
					[58.816886298130918, 1.7643527692186427],
					[58.816875197676879, 1.7642693745175391],
					[58.816875197289811, 1.7642693676474948],
					[58.816875194927391, 1.7642693577155513],
					[58.816875193649437, 1.7642693510320624],
					[58.816852997954093, 1.7641805692721444],
					[58.816850199647071, 1.7641388874201438],
					[58.816850199163206, 1.7641388788326031],
					[58.816850197401386, 1.7641388635616009],
					[58.816850195135721, 1.7641388553471822],
					[58.816825195021394, 1.7640277554304131],
					[58.816825192755744, 1.7640277472160042],
					[58.816825188321197, 1.7640277325047111],
					[58.816825186055546, 1.7640277242902989],
					[58.816819587966037, 1.764013831843297],
					[58.816814093088695, 1.7639971477807554],
					[58.816791894870278, 1.7638943586934328],
					[58.816791893592281, 1.7638943520099744],
					[58.81679189113305, 1.7638943403605853],
					[58.816791888964168, 1.7638943338636965],
					[58.816772391873407, 1.7638388443424218],
					[58.816758495126358, 1.7637805537943527],
					[58.816758494041913, 1.763780550545917],
					[58.8167584928607, 1.7637805455799722],
					[58.816758491873017, 1.7637805440490457],
					[58.816730794689626, 1.763686054258965],
					[58.816727996591403, 1.7636693668288888],
					[58.816727993938621, 1.7636693517445372],
					[58.816727984275353, 1.7636693242261683],
					[58.816727978949885, 1.7636693097015148],
					[58.816711286113907, 1.7636360256675303],
					[58.816691891031716, 1.763572142488949],
					[58.816691889850489, 1.7635721375230187],
					[58.816691887681564, 1.7635721310261885],
					[58.816691885609444, 1.7635721262468296],
					[58.816661286084155, 1.7634943276039299],
					[58.816661284012028, 1.7634943228245961],
					[58.816661280855442, 1.7634943147968412],
					[58.816661278880112, 1.7634943117349944],
					[58.816650180192163, 1.7634721144763525],
					[58.816636282906352, 1.7634416198824006],
					[58.816608484876085, 1.7633749229426989],
					[58.816608483888409, 1.7633749214117835],
					[58.816608481816274, 1.763374916632453],
					[58.816608480828599, 1.7633749151015392],
					[58.816586281233924, 1.7633277147165869],
					[58.816586280246256, 1.7633277131856522],
					[58.81658627925858, 1.7633277116547417],
					[58.81658627925858, 1.7633277116547417],
					[58.816566879239545, 1.7632888101804109],
					[58.816566877167425, 1.7632888054010851],
					[58.816566871241356, 1.7632887962156163],
					[58.816566869266005, 1.7632887931537995],
					[58.816547372102647, 1.763258197697835],
					[58.816519574961049, 1.7632082024575824],
					[58.816519572985683, 1.76320819939578],
					[58.816519571010332, 1.7632081963339505],
					[58.81651956903498, 1.763208193272142],
					[58.81648626866334, 1.7631554928391828],
					[58.816486264712623, 1.7631554867155608],
					[58.81648625592031, 1.763155474654915],
					[58.8164862510787, 1.7631554687178721],
					[58.816466864376572, 1.7631331845381091],
					[58.81645577364182, 1.7631138010039442],
					[58.816455770678765, 1.7631137964112313],
					[58.816455763765035, 1.7631137856949153],
					[58.816455760801979, 1.7631137811022102],
					[58.81641686065565, 1.7630609815939835],
					[58.816416859571191, 1.7630609783456053],
					[58.816416856704912, 1.7630609754703821],
					[58.816416854826336, 1.7630609741260683],
					[58.816380755427758, 1.7630165740646311],
					[58.816380748707566, 1.763016566783322],
					[58.816380737145764, 1.7630165535650195],
					[58.816380729631476, 1.7630165481877622],
					[58.816361254952696, 1.7629998694367215],
					[58.816352971695871, 1.7629859975140043],
					[58.816352964782126, 1.7629859867977455],
					[58.816352950160493, 1.7629859672692973],
					[58.816352940670825, 1.7629859588302583],
					[58.816300140669377, 1.7629331582786683],
					[58.816277940929069, 1.7629109595386498],
					[58.816277936978359, 1.7629109534150882],
					[58.816277928476367, 1.7629109465069885],
					[58.816277922840627, 1.762910942474077],
					[58.816239022782511, 1.7628804438856949],
					[58.816239021698046, 1.7628804406373419],
					[58.816239018037692, 1.7628804396661979],
					[58.816239016062326, 1.7628804366044319],
					[58.816200115747804, 1.7628526368470967],
					[58.816200113869208, 1.7628526355028113],
					[58.816200109124374, 1.7628526312833206],
					[58.816200106354927, 1.7628526301256113],
					[58.816177906388951, 1.7628387292535175],
					[58.81617790460718, 1.7628387296266934],
					[58.81617790371628, 1.7628387298132775],
					[58.81617790272859, 1.7628387282824018],
					[58.816150103272292, 1.762822029440031],
					[58.816150103175502, 1.7628220277225664],
					[58.81610840313445, 1.7627970283418664],
					[58.816094502550939, 1.7627887277845189],
					[58.816094497011989, 1.7627887254691244],
					[58.816094486825001, 1.7627887206517194],
					[58.816094481189268, 1.7627887166188494],
					[58.816069483380431, 1.7627776174141676],
					[58.816033383678331, 1.7627609175907388],
					[58.816033381799755, 1.7627609162464599],
					[58.816033377248509, 1.7627609154619348],
					[58.816033373588134, 1.7627609144908303],
					[58.816005674214438, 1.7627498146352083],
					[58.816005668675515, 1.7627498123198011],
					[58.816005658585304, 1.7627498092199005],
					[58.816005653937253, 1.7627498067179108],
					[58.815955653651407, 1.7627359070622979],
					[58.815955651869608, 1.762735907435474],
					[58.815955649991032, 1.7627359060911967],
					[58.815955648209247, 1.7627359064643695],
					[58.815911152901521, 1.7627248072147961],
					[58.81588335715584, 1.7627165079652449],
					[58.815883347162455, 1.7627165065827881],
					[58.815883327078829, 1.7627165021004563],
					[58.815883317085408, 1.7627165007180268],
					[58.815850028024762, 1.7627137015187548],
					[58.81582223944821, 1.7627081046842794],
					[58.815822230248891, 1.7627081013978139],
					[58.81582221035886, 1.7627081003503808],
					[58.815822200462243, 1.7627081006853982],
					[58.815800005954607, 1.7627081006689198],
					[58.81575831321166, 1.7627054002725471],
					[58.815758309551285, 1.762705399301449],
					[58.815758303315036, 1.7627054006075629],
					[58.815758299654675, 1.7627053996364679],
					[58.815736099801647, 1.7627054007381437],
					[58.815708300284456, 1.7627053999245197],
					[58.815661099758564, 1.7627053996336912],
					[58.815638899905473, 1.7627054007306471],
					[58.81563889268152, 1.762705400505906],
					[58.815638879221325, 1.7627054015872436],
					[58.815638872888279, 1.762705401175912],
					[58.815619372568108, 1.7627081020509343],
					[58.815577774418486, 1.7627137019986305],
					[58.815555578148484, 1.7627165017855131],
					[58.815527781151609, 1.7627192008222414],
					[58.815527773133574, 1.7627192025015077],
					[58.81552775878248, 1.7627192037694277],
					[58.81552775076441, 1.7627192054486971],
					[58.815494359058263, 1.7627276046740132],
					[58.815461067114455, 1.7627331036103431],
					[58.815461061769078, 1.7627331047298538],
					[58.815461051969244, 1.7627331067822785],
					[58.815461046623888, 1.7627331079017827],
					[58.815430546684183, 1.7627415079145949],
					[58.815430544902398, 1.7627415082877582],
					[58.81543054044792, 1.7627415092206833],
					[58.81543053688435, 1.7627415099670194],
					[58.815413843443864, 1.7627470079827838],
					[58.815372148668935, 1.7627581070358851],
					[58.815372147778049, 1.762758107222469],
					[58.815330547706921, 1.7627692072630401],
					[58.815330544143329, 1.7627692080093718],
					[58.815330534343488, 1.7627692100617884],
					[58.815330529985815, 1.7627692127121239],
					[58.815286031053333, 1.7627859122367397],
					[58.815255531845018, 1.7627970123713534],
					[58.815194332335075, 1.7628192112478893],
					[58.815194322728807, 1.7628192167351078],
					[58.815194305104484, 1.762819223901567],
					[58.815194296389116, 1.762819229202208],
					[58.815180509948412, 1.7628276215036109],
					[58.815105524803826, 1.7628581150557261],
					[58.815105523912926, 1.7628581152423024],
					[58.815105522131155, 1.7628581156154577],
					[58.81510552124027, 1.7628581158020353],
					[58.815027720790553, 1.762891516877382],
					[58.815027711184285, 1.7628915223645512],
					[58.815027690189957, 1.7628915337120659],
					[58.81502767969279, 1.7628915393858129],
					[58.815016597911203, 1.7628998274078436],
					[58.814961018255595, 1.7629248178313686],
					[58.814961014692031, 1.7629248185776667],
					[58.814961007661644, 1.7629248217876845],
					[58.814961005085763, 1.7629248240648361],
					[58.814924909628864, 1.7629442204177461],
					[58.814883214964709, 1.7629637189253051],
					[58.814883206249327, 1.7629637242258727],
					[58.814883188818563, 1.7629637348269716],
					[58.81488318010318, 1.7629637401275393],
					[58.814872088709457, 1.7629720335757793],
					[58.814838804635322, 1.7629915239599041],
					[58.814816610795312, 1.7630026203436897],
					[58.814816608219424, 1.7630026226208271],
					[58.814816603764953, 1.76300262355369],
					[58.814816602079929, 1.7630026256442384],
					[58.814747101575705, 1.763041525268167],
					[58.814747101575705, 1.763041525268167],
					[58.814747100684805, 1.7630415254547351],
					[58.814747099890695, 1.763041527358701],
					[58.814674921313539, 1.7630831145525425],
					[58.814655545265211, 1.7630887083453837],
					[58.814655529325876, 1.7630887134210416],
					[58.814655500410247, 1.7630887281648033],
					[58.81465548644627, 1.7630887363021093],
					[58.814627690308306, 1.7631081336624586],
					[58.814605506864844, 1.7631220218726786],
					[58.814566620704767, 1.7631387162914609],
					[58.814566616347079, 1.7631387189416852],
					[58.814566604958983, 1.7631387248018411],
					[58.814566599710396, 1.763138727638651],
					[58.814533199636216, 1.7631581272699641],
					[58.814533198745323, 1.7631581274565258],
					[58.814533196963524, 1.7631581278296653],
					[58.8145331970603, 1.7631581295470504],
					[58.814505500232343, 1.7631748265119176],
					[58.814480506643122, 1.7631887227295528],
					[58.814463814964739, 1.7631970193302409],
					[58.814388818587972, 1.7632304172787938],
					[58.81438881601207, 1.7632304195558641],
					[58.814388809775799, 1.7632304208618126],
					[58.814388808090797, 1.7632304229523323],
					[58.81431932591039, 1.7632665131613401],
					[58.814299945225045, 1.763272008702361],
					[58.814299931861598, 1.7632720115007976],
					[58.814299906412764, 1.763272023780617],
					[58.814299894133782, 1.7632720298272568],
					[58.814277717697948, 1.7632859145763529],
					[58.814238844959853, 1.7632970088653217],
					[58.814238837832697, 1.763297010357805],
					[58.814238824566033, 1.7632970148736011],
					[58.81423881931746, 1.7632970177103313],
					[58.814213832736691, 1.763308111171092],
					[58.81417215214438, 1.7633192060722509],
					[58.814122159199449, 1.7633304047511877],
					[58.814080580432886, 1.7633386997878222],
					[58.814072200029017, 1.7633386996845957],
					[58.814072190229155, 1.7633387017367452],
					[58.8140721686541, 1.7633387027794074],
					[58.81407215786659, 1.7633387033007513],
					[58.814033278881475, 1.7633469994297648],
					[58.813994400250223, 1.7633470000196161],
					[58.813975000124024, 1.7633469999261475],
					[58.813944411742526, 1.7633470003835716],
					[58.813897223135761, 1.7633415004286486],
					[58.813897219572198, 1.7633415011748768],
					[58.813897215020923, 1.7633415003902944],
					[58.813897212251462, 1.7633414992326153],
					[58.813850034458127, 1.763338702501217],
					[58.81381117269801, 1.7633276135348031],
					[58.813800090219019, 1.7633220211305412],
					[58.813800081910621, 1.76332201765749],
					[58.813800067172416, 1.763322012055633],
					[58.813800058864004, 1.7633220085825962],
					[58.81376396112686, 1.7633109090496653],
					[58.813738963372607, 1.7633026093841917],
					[58.813738961590829, 1.7633026097573026],
					[58.813738960699936, 1.7633026099438596],
					[58.813738959712261, 1.7633026084130681],
					[58.813686182597195, 1.7632859156624614],
					[58.813672302619445, 1.7632776284830676],
					[58.81367230074089, 1.7632776271388364],
					[58.813672296983754, 1.7632776244503627],
					[58.813672295201933, 1.7632776248234767],
					[58.813636195473464, 1.7632581244328245],
					[58.813636193594888, 1.7632581230885995],
					[58.813636190825434, 1.7632581219309078],
					[58.813636188946852, 1.7632581205866831],
					[58.813580703361893, 1.763230427846096],
					[58.813561216286921, 1.7632165364575429],
					[58.81356121262656, 1.7632165354864353],
					[58.813561206099948, 1.7632165316403068],
					[58.813561203233704, 1.7632165287653063],
					[58.813491808406866, 1.7631748314976088],
					[58.813419513932459, 1.7631248363864374],
					[58.813419510175322, 1.7631248336980099],
					[58.813419502661034, 1.7631248283211485],
					[58.81341949890389, 1.7631248256327243],
					[58.813400117417096, 1.763113735849434],
					[58.813377931715749, 1.7630942499142976],
					[58.813377930728066, 1.7630942483835252],
					[58.813314030942479, 1.7630387494108541],
					[58.813314026197673, 1.7630387451916654],
					[58.813314014829444, 1.7630387354090997],
					[58.813314009193732, 1.7630387313764713],
					[58.813258420724509, 1.7630026403721737],
					[58.813216831120741, 1.7629665492933033],
					[58.813216830133065, 1.7629665477625389],
					[58.813216829145375, 1.7629665462317825],
					[58.81321682825449, 1.7629665464183459],
					[58.813166827850601, 1.7629248458802764],
					[58.813166826862926, 1.7629248443495185],
					[58.813166824093464, 1.7629248431918902],
					[58.813166822214903, 1.7629248418476815],
					[58.813130721594327, 1.7628970418008472],
					[58.813130722485212, 1.7628970416142886],
					[58.813094528335611, 1.7628692455698309],
					[58.813041833110709, 1.762822050682644],
					[58.813041832123034, 1.7628220491518765],
					[58.813041831232148, 1.7628220493384399],
					[58.813019531283565, 1.7628026485106911],
					[58.81301952851409, 1.7628026473530536],
					[58.813019525647853, 1.762802644478128],
					[58.813019523769277, 1.7628026431339443],
					[58.81296682363665, 1.7627609436909892],
					[58.812966821758074, 1.7627609423468136],
					[58.812966818000923, 1.7627609396584449],
					[58.812966816025551, 1.7627609365969577],
					[58.81294732989754, 1.7627470472277895],
					[58.812936241401857, 1.7627359586919351],
					[58.812936236657009, 1.7627359544728356],
					[58.812936228155039, 1.7627359475654008],
					[58.812936224301112, 1.7627359431597265],
					[58.812858424354026, 1.7626748422759422],
					[58.812775124928486, 1.7626081446129984],
					[58.812775122953106, 1.7626081415515427],
					[58.812775119195955, 1.7626081388631996],
					[58.812775116426508, 1.7626081377056064],
					[58.812755716198176, 1.7625942374511763],
					[58.8127557152105, 1.7625942359204483],
					[58.812755710562463, 1.7625942334186924],
					[58.812755708780649, 1.7625942337918334],
					[58.81267511473559, 1.7625415359448084],
					[58.812630723065659, 1.7625081421678839],
					[58.81258902481499, 1.7624748446913774],
					[58.812589019082466, 1.7624748389416343],
					[58.812589005932423, 1.7624748295325849],
					[58.812588999405811, 1.7624748256866949],
					[58.81251121255962, 1.7624304332680607],
					[58.812497324928707, 1.7624192448201412],
					[58.812497321965651, 1.7624192402280039],
					[58.812497313657239, 1.7624192367552769],
					[58.812497309803305, 1.7624192323497063],
					[58.812489009790347, 1.7624137336189358],
					[58.812489008802665, 1.7624137320882254],
					[58.812489006924089, 1.7624137307440833],
					[58.812489005045506, 1.7624137293999251],
					[58.812408405254111, 1.7623637302090451],
					[58.812408398727499, 1.7623637263631962],
					[58.812408385674246, 1.7623637186715113],
					[58.812408379244403, 1.7623637165429586],
					[58.812350089678944, 1.7623387209133012],
					[58.812316800751205, 1.7623192277307955],
					[58.812316796994054, 1.7623192250425335],
					[58.812316788685642, 1.762319221569848],
					[58.812316784928491, 1.7623192188815859],
					[58.812227885039192, 1.7622776183489339],
					[58.812227881378831, 1.7622776173779362],
					[58.812227873961305, 1.7622776137187079],
					[58.812227871191823, 1.7622776125611579],
					[58.812205676615854, 1.7622692151248749],
					[58.812138983211455, 1.762238718551643],
					[58.81213897302446, 1.7622387137348694],
					[58.81213895373493, 1.7622387073492969],
					[58.812138943644733, 1.7622387042497858],
					[58.812113954798932, 1.7622331073968265],
					[58.812041765411699, 1.7622081109871033],
					[58.812041760860446, 1.7622081102027052],
					[58.812041752552034, 1.7622081067300659],
					[58.812041748000759, 1.7622081059456776],
					[58.812008348031092, 1.7621998051449763],
					[58.8120083462493, 1.7621998055181223],
					[58.812008342588939, 1.7621998045471385],
					[58.812008340710349, 1.7621998032030266],
					[58.811955641639351, 1.7621887051595226],
					[58.811905643046167, 1.7621776045628139],
					[58.811905635725431, 1.7621776026208913],
					[58.811905622071656, 1.7621776002677039],
					[58.811905613956817, 1.7621776002296088],
					[58.81186672077957, 1.7621748006398015],
					[58.811805626886745, 1.7621665014954695],
					[58.811805619662792, 1.7621665012708014],
					[58.811805606996714, 1.7621665004482958],
					[58.811805599772761, 1.7621665002236309],
					[58.811774999635105, 1.7621665005705818],
					[58.811711099578844, 1.7621665002180125],
					[58.811711088791341, 1.7621665007396075],
					[58.811711068203984, 1.7621665033135112],
					[58.811711056622372, 1.7621665057389433],
					[58.811686069292982, 1.7621720027007164],
					[58.811627780627823, 1.7621776017489041],
					[58.811627774294791, 1.7621776013376567],
					[58.811627761822258, 1.7621776039496506],
					[58.811627754695131, 1.7621776054422118],
					[58.811591661276971, 1.762185904284485],
					[58.811541667162253, 1.7621942021063692],
					[58.811541657362426, 1.762194204158638],
					[58.811541637859534, 1.7621942099804033],
					[58.811541628156512, 1.7621942137499045],
					[58.811505537457279, 1.7622081102219602],
					[58.811466645215553, 1.762219208694833],
					[58.811466637197505, 1.7622192103739511],
					[58.811466622149105, 1.7622192152628469],
					[58.811466615118739, 1.7622192184726262],
					[58.811419326339838, 1.7622415133983638],
					[58.811394337246142, 1.7622498097252093],
					[58.811394326652213, 1.7622498136812597],
					[58.811394308233794, 1.7622498227508514],
					[58.811394298530779, 1.7622498265203128],
					[58.811327708772154, 1.7622887218806775],
					[58.811302718805031, 1.7622998174340887],
					[58.811302711774665, 1.7622998206438298],
					[58.811302697810717, 1.7622998287805618],
					[58.811302690780359, 1.7622998319903092],
					[58.811255495882421, 1.7623304296128199],
					[58.811236000972379, 1.7623415263366355],
					[58.811235994038796, 1.7623415312635866],
					[58.811235980074848, 1.7623415394002804],
					[58.81123597323807, 1.7623415460444511],
					[58.81118877306394, 1.7623804463729951],
					[58.81118877217304, 1.7623804465595552],
					[58.811188771282154, 1.7623804467461155],
					[58.811188769597145, 1.7623804488364794],
					[58.811149870307958, 1.7624137483397355],
					[58.811149869417072, 1.7624137485263005],
					[58.811149867635287, 1.7624137488994209],
					[58.811127667764296, 1.762433149005403],
					[58.811127667861086, 1.7624331507226179],
					[58.811127666970201, 1.7624331509091749],
					[58.811127666079301, 1.7624331510957367],
					[58.811072065673663, 1.762483150936514],
					[58.811072052987768, 1.7624831657553988],
					[58.811072031179535, 1.7624831946469406],
					[58.811072021263108, 1.7624832106233803],
					[58.811066536324759, 1.7624942802327876],
					[58.811005462277656, 1.7625526557318518],
					[58.811005461386742, 1.7625526559184153],
					[58.811005459701747, 1.762552658008733],
					[58.811005458810847, 1.7625526581952964],
					[58.810969259265782, 1.7625887574680978],
					[58.810969255004856, 1.7625887618352951],
					[58.810969247373968, 1.7625887703831393],
					[58.810969244003942, 1.7625887745637938],
					[58.810947045132714, 1.7626165731978019],
					[58.810908146751459, 1.7626637717822182],
					[58.810888749160782, 1.7626859676419535],
					[58.810888739147543, 1.7626859819010481],
					[58.810888721793724, 1.7626860098595423],
					[58.810888713659047, 1.7626860254627297],
					[58.81088312360793, 1.7626999004197181],
					[58.810841539911557, 1.7627554797303939],
					[58.810841539117447, 1.762755481634142],
					[58.810841538323331, 1.7627554835378962],
					[58.810841537432445, 1.762755483724447],
					[58.810794237032624, 1.7628220835886357],
					[58.8107942362385, 1.7628220854923822],
					[58.810794233662619, 1.762822087769238],
					[58.810794232868496, 1.7628220896729891],
					[58.810783134750963, 1.7628387876269118],
					[58.810738736829556, 1.7629026841149584],
					[58.81070263985513, 1.7629526800542652],
					[58.810683142260459, 1.7629776764842058],
					[58.810683137302199, 1.7629776844722642],
					[58.810683125700685, 1.7629777025386608],
					[58.810683120742425, 1.7629777105267002],
					[58.810663723241554, 1.7630166065145758],
					[58.810638727680733, 1.7630609991504689],
					[58.810616530316281, 1.7630970949750688],
					[58.810616527740379, 1.7630970972518767],
					[58.81061652545479, 1.7630971046802881],
					[58.810616522782112, 1.7630971052399018],
					[58.810591528878234, 1.7631442947110114],
					[58.810558136807714, 1.7631915849947235],
					[58.810558129370321, 1.7631915969767127],
					[58.810558116374075, 1.7631916222848061],
					[58.81055811002112, 1.7631916375146615],
					[58.810547016974056, 1.7632249164395668],
					[58.810530428230507, 1.7632526967851914],
					[58.810497029223612, 1.7633081977885545],
					[58.810497026647667, 1.763308200065353],
					[58.810497023471193, 1.7633082076802498],
					[58.810497020992052, 1.7633082116742234],
					[58.810466524917281, 1.7633693022511976],
					[58.810452635306383, 1.7633915864411114],
					[58.810435939934344, 1.7634137804591956],
					[58.810435934879273, 1.7634137867299535],
					[58.810435924962725, 1.7634138027058097],
					[58.81043592089533, 1.7634138105072206],
					[58.810405421589749, 1.7634749084978196],
					[58.810366527801577, 1.7635470974838137],
					[58.810355437114985, 1.7635637847308572],
					[58.81034704107276, 1.7635748796280817],
					[58.810347036017674, 1.763574885898787],
					[58.810347027689353, 1.7635748980671375],
					[58.810347023621958, 1.7635749058685086],
					[58.810310923946652, 1.7636416048130839],
					[58.810310923152535, 1.763641606716789],
					[58.810310920673395, 1.7636416107107149],
					[58.810310919879271, 1.7636416126144343],
					[58.810269225426332, 1.7637277032977126],
					[58.8102192323759, 1.7638081922180979],
					[58.810169233524007, 1.7638831883360377],
					[58.810169230250722, 1.7638831942336368],
					[58.810169223607382, 1.7638832043116506],
					[58.810169221321743, 1.7638832117399124],
					[58.810160925967395, 1.7638999008027358],
					[58.810119233990449, 1.7639637875887835],
					[58.810085938990106, 1.7640109813235729],
					[58.810066542109745, 1.7640359764176661],
					[58.810066539630562, 1.7640359804115411],
					[58.81006653635729, 1.7640359863090926],
					[58.810066533781345, 1.7640359885858132],
					[58.810038739179532, 1.764077680137087],
					[58.810013746956514, 1.7641081727348944],
					[58.809997048268144, 1.7641276702988384],
					[58.809997047377244, 1.7641276704853477],
					[58.809997045692199, 1.7641276725755541],
					[58.809997045692199, 1.7641276725755541],
					[58.809958147681606, 1.7641748696570521],
					[58.809910962334264, 1.7642276534090986],
					[58.809897074627102, 1.7642387439910308],
					[58.809897067790196, 1.7642387506345798],
					[58.809897055007305, 1.7642387637351398],
					[58.80989704817042, 1.7642387703786602],
					[58.809880455286276, 1.7642581622606599],
					[58.809835962921433, 1.7642998532583598],
					[58.809835962127281, 1.7642998551620444],
					[58.80983596044225, 1.7642998572522031],
					[58.809835958757219, 1.7642998593423682],
					[58.809819259266277, 1.7643165596288508],
					[58.809819256593578, 1.7643165601883612],
					[58.809819254908547, 1.7643165622785246],
					[58.809819254114402, 1.7643165641822043],
					[58.809780461976395, 1.7643581545669278],
					[58.809730479657027, 1.7643998391827589],
					[58.809713788726825, 1.7644109326820485],
					[58.809713779313988, 1.7644109416021703],
					[58.809713760391531, 1.7644109577252431],
					[58.809713750087795, 1.7644109668318662],
					[58.809691562793518, 1.7644359530861928],
					[58.809655477810345, 1.7644637426198611],
					[58.809655476919446, 1.7644637428063497],
					[58.809655476028531, 1.7644637429928527],
					[58.809655475137646, 1.7644637431793508],
					[58.809641575417608, 1.7644748447751479],
					[58.809641572744923, 1.7644748453346362],
					[58.809641566702133, 1.7644748500744181],
					[58.809641565017088, 1.7644748521645672],
					[58.809608181271003, 1.7645054362921195],
					[58.809588801235797, 1.7645165262233597],
					[58.809588793411237, 1.7645165313361189],
					[58.809588776871195, 1.7645165417481401],
					[58.809588770034274, 1.7645165483915508],
					[58.809549870302995, 1.7645498472935597],
					[58.809549868617935, 1.7645498493836993],
					[58.809549867727036, 1.7645498495701895],
					[58.809527667583126, 1.7645692490724023],
					[58.809527666788966, 1.7645692509760407],
					[58.809527664213022, 1.7645692532526833],
					[58.809527663418876, 1.7645692551563215],
					[58.809477663020068, 1.7646165547077326],
					[58.809477662225937, 1.764616556611363],
					[58.809477659649964, 1.7646165588879912],
					[58.809477658759079, 1.7646165590744816],
					[58.80946655866564, 1.7646276582638487],
					[58.809455464613862, 1.7646387527061778],
					[58.809405471882975, 1.7646804456029774],
					[58.809405469403806, 1.7646804495967203],
					[58.80940546157921, 1.7646804547094126],
					[58.80940545910002, 1.7646804587031779],
					[58.809374861986278, 1.7647109542381163],
					[58.809344266056115, 1.7647387512977004],
					[58.809344264371056, 1.7647387533878178],
					[58.80934426011008, 1.7647387577545302],
					[58.809344259315949, 1.7647387596581683],
					[58.809283158625021, 1.7647998594344392],
					[58.809283154364032, 1.7647998638011531],
					[58.809283144157007, 1.764799874624676],
					[58.809283139992786, 1.7647998807085015],
					[58.809233141760544, 1.7648665770192085],
					[58.809197045096269, 1.7649109740513471],
					[58.809197044205369, 1.7649109742378342],
					[58.809197043411196, 1.7649109761414645],
					[58.80919704162941, 1.7649109765144275],
					[58.80917764216769, 1.7649359761482633],
					[58.809177641470306, 1.7649359797689987],
					[58.809177638003462, 1.7649359822320552],
					[58.809177637209302, 1.764935984135684],
					[58.809149837456722, 1.7649748846344735],
					[58.80914983567493, 1.7649748850074316],
					[58.809149834977532, 1.7649748886281702],
					[58.809149834086632, 1.7649748888146477],
					[58.809127634579639, 1.765008186704488],
					[58.8091081358954, 1.7650359850671216],
					[58.809108135101226, 1.7650359869707264],
					[58.809108131731151, 1.7650359911509073],
					[58.809108130936998, 1.7650359930545105],
					[58.809074830642636, 1.7650887936066937],
					[58.809074828163446, 1.7650887976003891],
					[58.809074823205037, 1.7650888055877478],
					[58.809074820725833, 1.7650888095814448],
					[58.809044220879869, 1.7651499106234363],
					[58.809044220879869, 1.7651499106234363],
					[58.809033132085396, 1.7651720898408381],
					[58.809016547635593, 1.765191569314978],
					[58.80901653682789, 1.7651915854761493],
					[58.809016518679314, 1.7651916153354494],
					[58.809016512422851, 1.765191632281359],
					[58.808991517351373, 1.7652610182566955],
					[58.80895542434169, 1.7653277042416775],
					[58.808955418685869, 1.7653277158497036],
					[58.808955410046941, 1.7653277385063386],
					[58.808955406269661, 1.765327751458537],
					[58.808947010821505, 1.7653610332731526],
					[58.808930418074041, 1.7653971166486702],
					[58.808930414800685, 1.7653971225459115],
					[58.808930410229266, 1.7653971374016815],
					[58.808930407943542, 1.7653971448295822],
					[58.808910907833365, 1.7654638445273765],
					[58.808910907135946, 1.7654638481480993],
					[58.808910904946984, 1.7654638572930972],
					[58.808910904249579, 1.7654638609137978],
					[58.80890260726482, 1.7655055465394598],
					[58.808880410971369, 1.7655693339459291],
					[58.808880410273964, 1.7655693375666233],
					[58.808880408685646, 1.7655693413738014],
					[58.808880407891486, 1.7655693432773825],
					[58.808849808170685, 1.7656749443663564],
					[58.808849805284282, 1.7656749571320376],
					[58.808849800595851, 1.7656749859111354],
					[58.808849799587982, 1.7656750000210095],
					[58.808849799760104, 1.765688874588059],
					[58.808844205746126, 1.7657110518673196],
					[58.808844205048722, 1.7657110554880076],
					[58.80884420285971, 1.7657110646329801],
					[58.808844203053212, 1.7657110680672252],
					[58.808827602866302, 1.7658138675025254],
					[58.808827602072142, 1.7658138694061176],
					[58.808827602168883, 1.7658138711232274],
					[58.808827602168883, 1.7658138711232274],
					[58.808822003422002, 1.7658527645351019],
					[58.808802604960356, 1.7659416563778436],
					[58.808802604359663, 1.765941661715654],
					[58.808802602073904, 1.7659416691434879],
					[58.808802602364118, 1.7659416742948304],
					[58.808791501584345, 1.7660277714065202],
					[58.808783102130846, 1.7660805694873203],
					[58.80878310242106, 1.7660805746386563],
					[58.808783101219682, 1.7660805853142516],
					[58.808783099728061, 1.7660805908385111],
					[58.808777599679388, 1.7661999919220457],
					[58.808777599776143, 1.766199993639157],
					[58.808777600066335, 1.7661999987905028],
					[58.808777600163076, 1.7662000005076286],
					[58.808777600108208, 1.7662249924595899],
					[58.808766501228384, 1.7663777862587997],
					[58.808766499543296, 1.7663777883487963],
					[58.808766499930229, 1.7663777952172661],
					[58.808766500220436, 1.7663778003686055],
					[58.80876650015572, 1.7664055929347273],
					[58.808758099570426, 1.7665305870823138],
					[58.808758099763907, 1.7665305905165511],
					[58.808758100150825, 1.7665305973850083],
					[58.808758100247537, 1.7665305991021405],
					[58.808758100256533, 1.766547192621809],
					[58.808747001199173, 1.7666916851160901],
					[58.80874699961084, 1.7666916889231792],
					[58.808746999997737, 1.766691695791657],
					[58.808747000191211, 1.7666916992258799],
					[58.808747000295064, 1.7667222002741507],
					[58.808746999853703, 1.7668555999711444],
					[58.808746999560384, 1.7668916953914036],
					[58.80874149991805, 1.7670193901808333],
					[58.808741500111488, 1.7670193936150691],
					[58.808741500401666, 1.7670193987664307],
					[58.8087414996075, 1.7670194006699687],
					[58.808741500287397, 1.7670638994744725],
					[58.808741499653642, 1.7671861001360161],
					[58.808741500038323, 1.7672417007090306],
					[58.808741500378645, 1.7673556000510817],
					[58.808741500425754, 1.7674110999182282],
					[58.808741499821316, 1.7675222005558],
					[58.808741499599698, 1.7675888994719968],
					[58.808741500103132, 1.7676917005043935],
					[58.808741500323734, 1.7677582968122545],
					[58.808738699869799, 1.7678582942877172],
					[58.808738699966504, 1.7678582960048352],
					[58.808738700159928, 1.7678582994390899],
					[58.808738700159928, 1.7678582994390899],
					[58.80873869974711, 1.7679305996026611],
					[58.808738699922493, 1.7680221964504104],
					[58.808735899961242, 1.7681055933326477],
					[58.808735900057947, 1.7681055950497655],
					[58.808735900251364, 1.7681055984840062],
					[58.80873590034809, 1.7681056002011399],
					[58.808735900007207, 1.7681888995229427],
					[58.808735899891168, 1.7682777895999995],
					[58.808727600881291, 1.7683527779893711],
					[58.808727601171405, 1.7683527831407326],
					[58.808727599969842, 1.7683527938162598],
					[58.808727600356661, 1.7683528006847391],
					[58.808727599687948, 1.7684527907995189],
					[58.808722000908048, 1.7685166823587155],
					[58.80872200030727, 1.7685166876964544],
					[58.808721999899866, 1.7685166964684609],
					[58.808722000093269, 1.7685166999027189],
					[58.808722000337383, 1.7686249995575116],
					[58.808722000027061, 1.7686777978094383],
					[58.808719199727399, 1.7687971943156473],
					[58.80871919982409, 1.768797196032786],
					[58.808719200017478, 1.768797199467028],
					[58.808719200017478, 1.768797199467028],
					[58.808719200109628, 1.7688388976696703],
					[58.808716501309405, 1.7689721682499284],
					[58.808708109386018, 1.768999942407415],
					[58.808708104717446, 1.7689999555455262],
					[58.808708100822471, 1.7689999824206313],
					[58.808708099814254, 1.7689999965303638],
					[58.808705400209107, 1.7691443966795788],
					[58.808705400209107, 1.7691443966795788],
					[58.808705400305804, 1.769144398396703],
					[58.808705400402495, 1.7691444001138257],
					[58.808705399577498, 1.7691582956295095],
					[58.808699800103838, 1.7692999926914628],
					[58.808699800200522, 1.7692999944085854],
					[58.808699800393917, 1.7692999978428305],
					[58.808699799599701, 1.7692999997463146],
					[58.808699800102829, 1.769319393697631],
					[58.808688701039067, 1.7694749863096004],
					[58.808688700244844, 1.7694749882130829],
					[58.808688699740699, 1.7694749952679476],
					[58.808688700030778, 1.7694750004193283],
					[58.808688700211732, 1.7694916997972137],
					[58.808688699945328, 1.7695138979834499],
					[58.808685899873581, 1.7696332802558754],
					[58.808680402841006, 1.7696666683781062],
					[58.808680402336847, 1.769666675432966],
					[58.808680399643414, 1.769666691632509],
					[58.808680400126846, 1.769666700218135],
					[58.808680399770182, 1.7697082994010804],
					[58.808680400191172, 1.769788893236909],
					[58.808677599607201, 1.7698388892660888],
					[58.808677599703891, 1.7698388909832146],
					[58.808674799568948, 1.7699027909144724],
					[58.808674799665624, 1.7699027926315998],
					[58.808674799955675, 1.769902797782982],
					[58.808674800052366, 1.7699027995001062],
					[58.808674799929953, 1.7699443881929686],
					[58.808666501198061, 1.770013875412795],
					[58.808666501391428, 1.7700138788470579],
					[58.808666500790586, 1.7700138841847681],
					[58.80866650009304, 1.7700138878053826],
					[58.808660899906727, 1.7700971871051188],
					[58.808660900100094, 1.7700971905393657],
					[58.808660899595921, 1.7700971975942177],
					[58.808660899692597, 1.7700971993113419],
					[58.808660900421543, 1.770186095791253],
					[58.808658099666935, 1.7702527894251368],
					[58.80865540066111, 1.7702971802788856],
					[58.808647002256158, 1.7703582724866338],
					[58.808647000667683, 1.7703582762935763],
					[58.808647001151094, 1.7703582848792054],
					[58.808646999562633, 1.7703582886861273],
					[58.808644199776971, 1.7704110885016449],
					[58.808638699611656, 1.7704916860031359],
					[58.808638699805016, 1.7704916894373828],
					[58.808638700191736, 1.7704916963058908],
					[58.808638700385089, 1.7704916997401392],
					[58.808638699857092, 1.7705333006102371],
					[58.808638699716774, 1.7705638935712791],
					[58.808630399660402, 1.7706888872590465],
					[58.808630399853755, 1.7706888906933078],
					[58.808630400240475, 1.7706888975618014],
					[58.808630400337137, 1.7706888992789431],
					[58.80863039963458, 1.7707027999284588],
					[58.808630400091332, 1.7707193949583677],
					[58.808624800581768, 1.7708221833911575],
					[58.808619200661326, 1.7708749797710415],
					[58.808619200854665, 1.7708749832052899],
					[58.808619200447104, 1.7708749919772484],
					[58.808619199749558, 1.7708749955978345],
					[58.808616499892274, 1.771027786371661],
					[58.808613701799302, 1.7710499754286111],
					[58.808613700908396, 1.7710499756149312],
					[58.808613701005058, 1.7710499773320554],
					[58.808613701101756, 1.7710499790491734],
					[58.80859980119989, 1.7711805790072761],
					[58.808599800598984, 1.771180584344983],
					[58.808599800288079, 1.771180594834058],
					[58.808599799687165, 1.7711806001717507],
					[58.808599800266258, 1.7712277967158701],
					[58.808597000236176, 1.7713332934753989],
					[58.808594201361352, 1.7713999829446438],
					[58.808583102031484, 1.771486073505617],
					[58.808583101333895, 1.7714860771261745],
					[58.808583100829665, 1.771486084180993],
					[58.808583100132083, 1.7714860878015504],
					[58.808577599748929, 1.7715749876979259],
					[58.808577599942254, 1.771574991132179],
					[58.808577600232248, 1.7715749962835645],
					[58.808577600425572, 1.7715749997178161],
					[58.808577599958639, 1.7716360951613952],
					[58.808572001363139, 1.7717471753257445],
					[58.808563704180195, 1.7717888605287155],
					[58.808563702881678, 1.7717888694869577],
					[58.808563700982226, 1.7717888837828704],
					[58.808563699683724, 1.7717888927411125],
					[58.808558100754034, 1.7719221795654161],
					[58.808555403008661, 1.771938867938406],
					[58.808555402311065, 1.7719388715589555],
					[58.808555400915878, 1.7719388788000654],
					[58.808555401012541, 1.7719388805171881],
					[58.808544200843563, 1.7720555811702403],
					[58.808544201133543, 1.7720555863216287],
					[58.808544199835012, 1.7720555952798567],
					[58.808544200124977, 1.7720556004312293],
					[58.808544199692292, 1.7720943935602034],
					[58.80853590132056, 1.7722388841294967],
					[58.808533101050237, 1.7722693814129606],
					[58.808522001317236, 1.7723888805398971],
					[58.808516500999936, 1.7724388775925586],
					[58.808516501289887, 1.7724388827439281],
					[58.808516499894665, 1.7724388899850143],
					[58.808516500087975, 1.7724388934192628],
					[58.808513700039811, 1.772538882339342],
					[58.808502601530556, 1.7726138704815175],
					[58.808502601723859, 1.772613873915766],
					[58.808502601316185, 1.7726138826876865],
					[58.808502601412847, 1.7726138844048076],
					[58.808497001207073, 1.7726888851492697],
					[58.8084970004128, 1.7726888870526711],
					[58.808496999811844, 1.7726888923903177],
					[58.808496999908492, 1.772688894107445],
					[58.808494200358794, 1.7727860926602823],
					[58.808491501032812, 1.7728360824836067],
					[58.80847480194403, 1.7729638732479565],
					[58.80847480054878, 1.7729638804890155],
					[58.808474800431028, 1.7729638944122976],
					[58.808474799830044, 1.7729638997499602],
					[58.808474799562198, 1.7729860925065872],
					[58.808466499895992, 1.7731110863545274],
					[58.808466500089267, 1.7731110897887949],
					[58.808466499584924, 1.7731110968435579],
					[58.808466499778213, 1.7731111002778237],
					[58.808466500182462, 1.7731332894105172],
					[58.808452601221092, 1.7732777809308233],
					[58.8084526006201, 1.7732777862684779],
					[58.808452600212391, 1.7732777950403762],
					[58.808452599611392, 1.7732778003780116],
					[58.808452599824093, 1.7733110858773287],
					[58.808435901856498, 1.7734249703717251],
					[58.808435901352134, 1.7734249774264943],
					[58.808435900440067, 1.7734249932531443],
					[58.808435899935695, 1.7734250003079199],
					[58.808435899897951, 1.7734805931663862],
					[58.808430401446643, 1.7735693753735888],
					[58.808413702684767, 1.7736582631093041],
					[58.808413702277022, 1.7736582718811944],
					[58.808413699776366, 1.7736582915145978],
					[58.808413700259543, 1.7736583001002348],
					[58.808413699771904, 1.7737110837419956],
					[58.808394203236837, 1.7738277662684003],
					[58.808394201938164, 1.7738277752265328],
					[58.808394200135119, 1.7738277912394027],
					[58.808394199727346, 1.7738278000112866],
					[58.80839420029686, 1.7738499942583581],
					[58.808388702175868, 1.7739471640132398],
					[58.80837480868211, 1.7739916409237035],
					[58.808374805891489, 1.7739916554056836],
					[58.808374801201161, 1.7739916841834122],
					[58.808374800289009, 1.7739917000100431],
					[58.808374800377308, 1.7740027858739549],
					[58.808358102081684, 1.7741249724396397],
					[58.808358102178325, 1.7741249741567575],
					[58.808358100589736, 1.7741249779634871],
					[58.808358100686362, 1.7741249796806113],
					[58.808352601921094, 1.7741777711582378],
					[58.808338702922555, 1.7742555657410355],
					[58.808338703019182, 1.7742555674581582],
					[58.80833870162386, 1.7742555746991346],
					[58.808338700829573, 1.7742555766024946],
					[58.80832760431354, 1.7743499517899624],
					[58.808313713003493, 1.7743860280253938],
					[58.808313710116195, 1.7743860407902112],
					[58.808313704148368, 1.7743860628855757],
					[58.808313702055365, 1.7743860737470236],
					[58.808302604158854, 1.7744721603344487],
					[58.808291508347814, 1.7745110451308985],
					[58.808291504666222, 1.7745110597990346],
					[58.808291500770054, 1.7745110866732856],
					[58.808291499761225, 1.7745111007827288],
					[58.808291499713107, 1.7745277756061471],
					[58.808266505679384, 1.7746305524005748],
					[58.808266504885083, 1.774630554303922],
					[58.808266504885083, 1.774630554303922],
					[58.808249806957029, 1.7747027451866129],
					[58.808235910397073, 1.7747443365289504],
					[58.808235909602786, 1.7747443384322914],
					[58.808235908808484, 1.7747443403356353],
					[58.808235908014176, 1.7747443422389764],
					[58.808210907976026, 1.7748277436425679],
					[58.808210905786368, 1.7748277527868406],
					[58.808210902394585, 1.7748277726062842],
					[58.808210901095862, 1.7748277815643372],
					[58.808208103710932, 1.7748582446536023],
					[58.808199821134508, 1.7748749115915954],
					[58.80819981786069, 1.7748749174878315],
					[58.808199812397206, 1.7748749325283486],
					[58.808199810014322, 1.774874938238362],
					[58.808174813894148, 1.7749527236255382],
					[58.808169220718923, 1.7749638092896189],
					[58.808169217638337, 1.7749638186200878],
					[58.808169211380559, 1.7749638355639141],
					[58.80816920820336, 1.7749638431772619],
					[58.808144207593486, 1.7750499441418279],
					[58.808144206799192, 1.7750499460451545],
					[58.808144206101488, 1.7750499496656054],
					[58.808144206198115, 1.7750499513827185],
					[58.808138710773726, 1.7750721301794807],
					[58.808124821283741, 1.7750999115843078],
					[58.808124818009929, 1.7750999174805251],
					[58.808124811655496, 1.7750999327071713],
					[58.808124809369204, 1.7750999401342848],
					[58.808102613390432, 1.7751721277658414],
					[58.808077619405871, 1.7752249140946843],
					[58.808077618514957, 1.7752249142808898],
					[58.808058118550647, 1.7752666148347174],
					[58.808058117058643, 1.7752666203584742],
					[58.808058112292812, 1.7752666317784165],
					[58.808058109909894, 1.7752666374883994],
					[58.808041512845072, 1.7753166280211037],
					[58.808022021253571, 1.7753610091252057],
					[58.807999825777287, 1.7753999007867889],
					[58.807999824188677, 1.7753999045934326],
					[58.807999820914823, 1.7753999104895861],
					[58.807999819326199, 1.7753999142962313],
					[58.807974818790377, 1.7754527138145848],
					[58.807974818887004, 1.7754527155317075],
					[58.807974818092688, 1.7754527174350199],
					[58.807974817298394, 1.775452719338332],
					[58.807952619150662, 1.7755027145421898],
					[58.807933120854116, 1.7755416104165878],
					[58.807933120854116, 1.7755416104165878],
					[58.80791372666458, 1.7755804983848935],
					[58.807883134699054, 1.7756248867410227],
					[58.807883132219509, 1.7756248907338434],
					[58.807883125575188, 1.7756249008089595],
					[58.807883123192255, 1.7756249065188869],
					[58.807860924273761, 1.7756666060290887],
					[58.807838724889699, 1.7757082013421785],
					[58.807810927751476, 1.7757554990837967],
					[58.807810926860547, 1.775755499269988],
					[58.807791527403275, 1.7757887991917543],
					[58.807791525718031, 1.775788801281261],
					[58.807791523431703, 1.7757888087082458],
					[58.807791520855559, 1.7757888109839295],
					[58.807777630404686, 1.7758165937268287],
					[58.807738742132749, 1.7758665775005202],
					[58.80773873965321, 1.7758665814933028],
					[58.807738734597493, 1.775866587761753],
					[58.807738733008868, 1.7758665915683298],
					[58.807697032872696, 1.7759304913444529],
					[58.807697030393143, 1.7759304953372228],
					[58.807697023748787, 1.7759305054122467],
					[58.807697021365833, 1.7759305111221058],
					[58.80768873034274, 1.7759470941653834],
					[58.807652641067278, 1.7759942776659128],
					[58.807652641163877, 1.7759942793830259],
					[58.80765264027297, 1.7759942795692139],
					[58.807635939933526, 1.7760165792459306],
					[58.807635939139217, 1.7760165811492303],
					[58.807635938344895, 1.7760165830525108],
					[58.807635936563067, 1.7760165834248838],
					[58.807594236816861, 1.7760748834626514],
					[58.807594226297489, 1.7760749047711084],
					[58.807594208822479, 1.7760749466432777],
					[58.807594202854332, 1.776074968737904],
					[58.807591513334195, 1.7760916041411301],
					[58.807572059326894, 1.7761109577127903],
					[58.80757205684732, 1.7761109617055284],
					[58.807572051598406, 1.7761109645397222],
					[58.807572050009767, 1.7761109683462879],
					[58.807530449598943, 1.7761581687547716],
					[58.807530446131857, 1.7761581712165877],
					[58.807530438596551, 1.7761581814776948],
					[58.807530436116977, 1.7761581854704231],
					[58.807505437632464, 1.7761942827604462],
					[58.80748044206485, 1.7762276761817433],
					[58.807430445371963, 1.7762887727197307],
					[58.807430443686712, 1.7762887748091691],
					[58.807430442098074, 1.7762887786156967],
					[58.807430441207153, 1.7762887788018722],
					[58.807422040903376, 1.7762998790329823],
					[58.807422040012447, 1.7762998792191609],
					[58.807422039218139, 1.7762998811224397],
					[58.807422038423809, 1.7762998830257044],
					[58.807399845257251, 1.7763304721631155],
					[58.807369252645131, 1.7763637650396653],
					[58.807369251754217, 1.7763637652258406],
					[58.807369250959894, 1.7763637671290988],
					[58.807369250068973, 1.776363767315271],
					[58.807327649649814, 1.776410968848906],
					[58.807327643703168, 1.7764109753033854],
					[58.807327632003016, 1.7764109916464859],
					[58.807327626249574, 1.7764110015351247],
					[58.807316542008756, 1.7764304733174685],
					[58.807280463673081, 1.7764637535883399],
					[58.807280455150234, 1.7764637623183772],
					[58.807280438297767, 1.7764637832126511],
					[58.807280429968131, 1.7764637953768607],
					[58.807258150931609, 1.7764998607044988],
					[58.807238783572124, 1.776513737243171],
					[58.807238772376522, 1.7765137465317153],
					[58.80723875186375, 1.7765137664535346],
					[58.807238741655674, 1.7765137772729864],
					[58.807199844847126, 1.7765637733407733],
					[58.807183147903324, 1.7765831704815436],
					[58.807183147109001, 1.7765831723847842],
					[58.807183146218087, 1.7765831725709518],
					[58.807155451720625, 1.7766165647267793],
					[58.807144259354537, 1.7766276575417885],
					[58.807144259451135, 1.7766276592588603],
					[58.807127659281832, 1.7766442582347373],
					[58.807127655020423, 1.7766442625997114],
					[58.807127648182828, 1.7766442692402817],
					[58.807127644812326, 1.7766442734190853],
					[58.807091554216768, 1.7766887611325097],
					[58.807060965724958, 1.7767165523476831],
					[58.807060958790764, 1.7767165572711578],
					[58.807060946897408, 1.7767165701798826],
					[58.807060941938225, 1.7767165781651519],
					[58.80703314778053, 1.7767526697538492],
					[58.806980455071383, 1.7768081624909478],
					[58.806980450809959, 1.7768081668559059],
					[58.806980444068941, 1.7768081752134541],
					[58.806980440698446, 1.7768081793922499],
					[58.806972044509017, 1.7768192732660451],
					[58.806916549606541, 1.7768831671922631],
					[58.806888751999089, 1.7769137660273426],
					[58.806888748531982, 1.7769137684890364],
					[58.806888744367136, 1.7769137745710251],
					[58.806888741887533, 1.7769137785636246],
					[58.806860949352689, 1.7769498677187048],
					[58.806808158742555, 1.7770026597801729],
					[58.806808141889995, 1.7770026806738792],
					[58.806808115698587, 1.777002727840306],
					[58.806808106263162, 1.7770027523959471],
					[58.806805417998753, 1.7770138035676659],
					[58.806780451551447, 1.7770415643787323],
					[58.806747053799683, 1.7770776640047932],
					[58.806705454185781, 1.7771220631383338],
					[58.806705450815258, 1.7771220673170467],
					[58.806705444074211, 1.7771220756744885],
					[58.80670543971619, 1.7771220783222954],
					[58.806438739581253, 1.777474878267018],
					[58.806438738089156, 1.7774748837904217],
					[58.806438733924296, 1.7774748898722561],
					[58.806438731348074, 1.7774748921477268],
					[58.805263731426031, 1.7793165915766755],
					[58.805263728151978, 1.779316597471988],
					[58.805263723289201, 1.7793166071734559],
					[58.805263720015176, 1.7793166130687761],
					[58.805077624606021, 1.7797027034812889],
					[58.804894241202881, 1.7799942768251991],
					[58.804744255315029, 1.7801526627611224],
					[58.804744242626917, 1.7801526775708092],
					[58.804744222692783, 1.7801527077907828],
					[58.804744214555825, 1.780152723387151],
					[58.804558114930295, 1.7806082244262835],
					[58.804558113341507, 1.7806082282323183],
					[58.804558111054867, 1.7806082356582917],
					[58.804558108575165, 1.7806082396503664],
					[58.804405408785271, 1.7810916408440565],
					[58.804405408087412, 1.7810916444639968],
					[58.804405405897278, 1.7810916536068797],
					[58.804405404308469, 1.781091657412857],
					[58.804335904434716, 1.7814166587594786],
					[58.804335902942434, 1.781416664282399],
					[58.804335901836261, 1.7814166766731274],
					[58.804335901234907, 1.781416682010025],
					[58.804294200588842, 1.7818693814296396],
					[58.804294200878417, 1.7818693865804907],
					[58.804294199675681, 1.7818693972542445],
					[58.804294199965241, 1.7818694024051163],
				],
			],
		},
		{
			fldNpdidField: "3437650",
			wlbNpdidWellbore: "1382",
			fldName: "BLANE",
			fldCurrentActivitySatus: "Producing",
			wlbName: "1/2-1",
			fldDiscoveryYear: "1989",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=3437650",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=3437650",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "BLANE UNIT",
			cmpLongName: "Repsol Norge AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=1382",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "27052812",
			fldNpdidOwner: "3437667",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2021-12-21 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[56.844216501702626, 2.4933471967681538],
					[56.844216509062967, 2.4933472513649853],
					[56.844216515952276, 2.4933472797477774],
					[56.844849816018524, 2.4948167793319973],
					[56.84484981903698, 2.4948167853803236],
					[56.844849824010964, 2.4948167943750628],
					[56.844849827029428, 2.4948168004233877],
					[56.845699826850144, 2.4962834004511185],
					[56.845699852737937, 2.4962834304573351],
					[56.845699913650051, 2.4962834757299395],
					[56.84569994875956, 2.4962834926250781],
					[56.846038849065039, 2.49637239335464],
					[56.846038862708859, 2.4963723959091131],
					[56.846038890803825, 2.4963723992338873],
					[56.846038906147555, 2.496372399848731],
					[56.846427805904504, 2.496361299999291],
					[56.846427859031571, 2.4963612825282762],
					[56.846427947771609, 2.4963612226954184],
					[56.846427984191955, 2.4963611785493534],
					[56.847030783687785, 2.4949528795193858],
					[56.84703078602459, 2.4949528725377834],
					[56.847030789976088, 2.4949528619876036],
					[56.847030792398122, 2.4949528566347796],
					[56.847355779868963, 2.4938528971256777],
					[56.847963910307193, 2.4931391953271982],
					[56.848247052907652, 2.4934279362357956],
					[56.848585925770067, 2.4938168054546646],
					[56.848816489718729, 2.4944056128596461],
					[56.848549813205992, 2.4954055248672775],
					[56.848052625261964, 2.4963138047695623],
					[56.848052620417796, 2.4963138154753692],
					[56.848052610729468, 2.4963138368869524],
					[56.848052606777863, 2.4963138474372761],
					[56.847727606639516, 2.4975138477064074],
					[56.847727605450622, 2.4975138594190578],
					[56.847727601202557, 2.4975138815263738],
					[56.84772760001367, 2.4975138932390082],
					[56.847683100034928, 2.4987055931709752],
					[56.847683100290411, 2.4987055980574606],
					[56.847683099993958, 2.4987056096146496],
					[56.847683101227133, 2.4987056159745542],
					[56.847746997612489, 2.4994971881941099],
					[56.847480406086234, 2.5007971494592209],
					[56.846991510357675, 2.5022999381420994],
					[56.846991508657645, 2.5022999400815396],
					[56.846991507935307, 2.5022999434944806],
					[56.846991507127861, 2.502299945278601],
					[56.846935907441306, 2.5024999459624517],
					[56.84693590417001, 2.5024999695422969],
					[56.846935902175318, 2.5025000175542274],
					[56.846935904259375, 2.5025000402022144],
					[56.847466503681161, 2.5050639412332849],
					[56.847466520004005, 2.505063977841361],
					[56.847466565782923, 2.5050640438422027],
					[56.847466596046509, 2.5050640714508923],
					[56.848872077246497, 2.5059168599754869],
					[56.849777661122474, 2.5067862439284774],
					[56.849777702904063, 2.5067862678932804],
					[56.849777793394637, 2.5067862932496259],
					[56.849777840403554, 2.5067862965805348],
					[56.850894439968691, 2.5065557958393909],
					[56.85089447435351, 2.5065557816401958],
					[56.850894535387248, 2.506555743080757],
					[56.850894562036117, 2.5065557187205205],
					[56.851558462186873, 2.505641817361397],
					[56.85155847213202, 2.5056418008370853],
					[56.851558488281917, 2.5056417651514828],
					[56.851558495379216, 2.5056417458349296],
					[56.851772395405483, 2.5047444452018603],
					[56.851772395957731, 2.5047444385304769],
					[56.851772397869681, 2.5047444234034169],
					[56.851772399229397, 2.5047444149477252],
					[56.851872393636661, 2.5034500883161033],
					[56.852372285880975, 2.5029390984518449],
					[56.85292769298966, 2.5029252023417645],
					[56.853497031806675, 2.503802909632812],
					[56.85349711787844, 2.5038029538357014],
					[56.853497287934225, 2.5038029505461634],
					[56.853497371833164, 2.5038029014246383],
					[56.854930757607448, 2.5013779268425105],
					[56.857536233365671, 2.4989223491366888],
					[56.858647283943768, 2.4979973902435209],
					[56.859541716631895, 2.4978723997351859],
					[56.860491515907952, 2.4978419053259682],
					[56.860619200605875, 2.4994278157280267],
					[56.860619203242585, 2.4994278317110106],
					[56.860619210130793, 2.4994278601072604],
					[56.860619213574907, 2.4994278743053902],
					[56.861252614056738, 2.5010000746145589],
					[56.861252645002466, 2.5010001152646462],
					[56.861252723001094, 2.5010001773173962],
					[56.861252770946543, 2.5010001985646482],
					[56.861813845567575, 2.5010834942039635],
					[56.862933223182829, 2.5015473857672901],
					[56.862933244137324, 2.5015473903399386],
					[56.8629332850686, 2.5015473980111964],
					[56.862933305852806, 2.5015473993248709],
					[56.863327799044662, 2.5015363004730857],
					[56.866180556063405, 2.5016557980818801],
					[56.867530524273477, 2.5022140846149288],
					[56.867530531669331, 2.5022140882605099],
					[56.867530548161028, 2.5022140936111184],
					[56.867530556364308, 2.502214095471563],
					[56.868708256312885, 2.5024779954286753],
					[56.868708287892908, 2.5024779965072983],
					[56.868708352412447, 2.5024779902049268],
					[56.868708382589141, 2.5024779816604896],
					[56.870102883278108, 2.5018418825033559],
					[56.870102896411026, 2.5018418752819755],
					[56.870102919914089, 2.501841859675805],
					[56.870102931984178, 2.5018418493502717],
					[56.871155732174884, 2.5009168502113912],
					[56.871155746667093, 2.5009168345292219],
					[56.871155772718403, 2.5009167987417644],
					[56.871155784277498, 2.5009167786364945],
					[56.871975184039641, 2.4990056797334423],
					[56.872414066186423, 2.4980001214899361],
					[56.872997225172121, 2.4974863444816178],
					[56.873980468822715, 2.4980529556123718],
					[56.874549847118452, 2.4987334272302921],
					[56.874549880061359, 2.4987334543854782],
					[56.874549953043122, 2.4987334893658284],
					[56.874549994144893, 2.4987335002954247],
					[56.876286088626664, 2.4987834994204663],
					[56.878130564687375, 2.4989307979628888],
					[56.879761047302495, 2.4993807930880427],
					[56.879761048195043, 2.4993807929324645],
					[56.879761049087584, 2.4993807927768863],
					[56.879761050065319, 2.499380794251528],
					[56.88149994998362, 2.4998279936725538],
					[56.881499997714705, 2.4998279935784087],
					[56.88150008939192, 2.4998279726629584],
					[56.881500132360351, 2.4998279503669116],
					[56.88255293189075, 2.4989029506490699],
					[56.882552960448457, 2.4989029111212315],
					[56.882552992783552, 2.498902823224193],
					[56.882552998346021, 2.4989027745437817],
					[56.882339101229263, 2.4972193986976872],
					[56.882497397139566, 2.4957222378800363],
					[56.8828722479617, 2.4944199567924854],
					[56.88344420292615, 2.4955972512825233],
					[56.88351090040252, 2.4966889074427709],
					[56.883522000381902, 2.4976833011167829],
					[56.883522001233956, 2.497683317420603],
					[56.883522006337827, 2.4976833461449046],
					[56.883522008889784, 2.4976833605070601],
					[56.883866508945921, 2.4987667610141826],
					[56.883866520041828, 2.4987667837583962],
					[56.883866546611181, 2.4987668268383398],
					[56.883866562892017, 2.4987668453880358],
					[56.884997062691873, 2.4998279458516204],
					[56.884997073020998, 2.4998279539227495],
					[56.884997093594073, 2.4998279684345532],
					[56.884997104815753, 2.4998279763500824],
					[56.88640268743265, 2.5005834664833002],
					[56.887191539295785, 2.5012557265480386],
					[56.888222008828329, 2.5033167649183365],
					[56.888572003682611, 2.5050972393170232],
					[56.888572005978432, 2.5050972487897818],
					[56.888572012355127, 2.5050972674243202],
					[56.888572014565817, 2.5050972752664604],
					[56.889377615232668, 2.5070639767239475],
					[56.889377621183677, 2.5070639872058966],
					[56.88937763317076, 2.5070640098004775],
					[56.889377640099411, 2.507064021757639],
					[56.890113739831556, 2.5080390214221309],
					[56.890113782038782, 2.5080390535644699],
					[56.890113876780433, 2.5080390913679884],
					[56.890113930292486, 2.5080390985044367],
					[56.89094987838871, 2.5079141193346555],
					[56.891638715807744, 2.509488976468377],
					[56.89318311399532, 2.5133250752518608],
					[56.893183141112637, 2.5133251116740576],
					[56.893183208225786, 2.5133251724034715],
					[56.89318324894419, 2.5133251932936584],
					[56.894194279611732, 2.5135945751946891],
					[56.894763715874035, 2.5143750874493258],
					[56.894997000279979, 2.5152639265702321],
					[56.895008099703844, 2.5164583019651983],
					[56.895008103741112, 2.5164583275944996],
					[56.895008117086178, 2.5164583762905868],
					[56.895008127286523, 2.5164583992021017],
					[56.895633126711417, 2.5175362000837698],
					[56.895633147877412, 2.5175362260260141],
					[56.895633198795416, 2.5175362698342165],
					[56.895633227569867, 2.5175362862244346],
					[56.896586027877731, 2.5179057857166103],
					[56.896586038928504, 2.5179057903778412],
					[56.896586060860173, 2.5179057964382583],
					[56.896586071741055, 2.5179057978374475],
					[56.898380571854119, 2.518158497375802],
					[56.898380606027054, 2.5181584963699151],
					[56.898380672885679, 2.5181584830957355],
					[56.898380703786209, 2.5181584711379243],
					[56.901108403867013, 2.5164890703128622],
					[56.901108427201805, 2.5164890514389571],
					[56.901108467198497, 2.5164890066221943],
					[56.901108482967793, 2.5164889808346365],
					[56.901464054386906, 2.5156834446576513],
					[56.902077811092028, 2.5153669185119809],
					[56.903255521746907, 2.5158334847131711],
					[56.904941618905141, 2.5165834828027962],
					[56.904941639944283, 2.5165834890187631],
					[56.904941682745203, 2.5165834980325879],
					[56.904941706292142, 2.5165835005198383],
					[56.90572220620065, 2.5165612994070741],
					[56.905722232170369, 2.5165612965341206],
					[56.905722281814775, 2.5165612813104041],
					[56.905722306467013, 2.5165612704357421],
					[56.907394497765274, 2.5155195742361545],
					[56.908230670724322, 2.5150973887389636],
					[56.909905650875466, 2.5146529924747463],
					[56.909905656230904, 2.514652991542389],
					[56.909905666856787, 2.5146529880460862],
					[56.909905672212197, 2.5146529871137342],
					[56.911852871738162, 2.5139029873809515],
					[56.91185289711261, 2.5139029730836895],
					[56.91185294127343, 2.5139029390504439],
					[56.911852960952366, 2.5139029191590363],
					[56.912905761058092, 2.5124779187981221],
					[56.912905766880883, 2.5124779095513303],
					[56.912905777548822, 2.5124778895815068],
					[56.912905783371599, 2.5124778803347176],
					[56.913780782973838, 2.5104639805043512],
					[56.91378079143098, 2.5104639526858046],
					[56.913780798441977, 2.5104638971276536],
					[56.913780797888393, 2.5104638692325287],
					[56.913483498450077, 2.5085777697137126],
					[56.913483491903413, 2.5085777478029581],
					[56.913483476302609, 2.508577707711551],
					[56.913483466355878, 2.5085776896864744],
					[56.912633465595228, 2.5073081883794193],
					[56.912633423217244, 2.5073081529569943],
					[56.912633323671812, 2.5073081093878917],
					[56.912633268374577, 2.5073081025617241],
					[56.91190276822897, 2.5074276025675659],
					[56.911902755733138, 2.5074276047456441],
					[56.91190273439679, 2.5074276101111468],
					[56.911902722963724, 2.5074276153969488],
					[56.911124997377811, 2.5077497848763173],
					[56.910397377727882, 2.5074692540995458],
					[56.910155801430925, 2.5060832879996138],
					[56.910252999043983, 2.5041889342245796],
					[56.910900191622844, 2.5019778564745123],
					[56.910900192430212, 2.501977854687226],
					[56.911389081006135, 2.500272292738416],
					[56.91210845706825, 2.4993557227881609],
					[56.91210847202634, 2.4993556987730612],
					[56.912108492676197, 2.4993556457740365],
					[56.912108499345742, 2.4993556182659709],
					[56.91225849930197, 2.4976583187539889],
					[56.912258499683119, 2.4976583088083588],
					[56.912258498575085, 2.4976582875971252],
					[56.912258498956227, 2.4976582776515128],
					[56.912136298699686, 2.4965666775982251],
					[56.912136297380911, 2.4965666695959521],
					[56.91213629376557, 2.4965666521155909],
					[56.912136292446803, 2.4965666441133001],
					[56.911730792060688, 2.4951832437435906],
					[56.911730784789732, 2.4951832252554396],
					[56.911730766848208, 2.4951831921658099],
					[56.91173075600711, 2.4951831743011303],
					[56.910258456795482, 2.4933331772341689],
					[56.909580758352831, 2.4924554776708137],
					[56.909580748830408, 2.4924554678095263],
					[56.909580728893026, 2.4924554482428491],
					[56.909580719370595, 2.4924554383815587],
					[56.908675119421346, 2.4917859385815992],
					[56.908675100888246, 2.4917859286485609],
					[56.908675063992639, 2.4917859120453874],
					[56.908675043845072, 2.4917859056871285],
					[56.907891825883794, 2.4916081239234682],
					[56.906866884909704, 2.4899471191938307],
					[56.906116892805571, 2.4871805488122174],
					[56.906116890679073, 2.4871805425989355],
					[56.906116887318589, 2.4871805300163485],
					[56.906116885277456, 2.4871805254344119],
					[56.905597406772856, 2.4858999782476552],
					[56.905919594809994, 2.4842000428688107],
					[56.906566892372815, 2.481591757594849],
					[56.907272388739536, 2.4795778665837664],
					[56.907272390268155, 2.4795778613772788],
					[56.907272393325414, 2.4795778509642763],
					[56.90727239485404, 2.4795778457577695],
					[56.907702994869645, 2.4777750460043824],
					[56.90770299694875, 2.4777750341160152],
					[56.907702999321948, 2.4777750106517984],
					[56.907702999530542, 2.477774997444568],
					[56.907694599742499, 2.4768777980486325],
					[56.907694599144001, 2.4768777866290637],
					[56.907694597139994, 2.4768777655775773],
					[56.907694594756478, 2.476877754470558],
					[56.907577994781406, 2.4763832542634976],
					[56.90757793801928, 2.4763831851794262],
					[56.907577793278556, 2.4763831216238303],
					[56.907577705128972, 2.4763831238895841],
					[56.906799946579568, 2.4768054008798983],
					[56.906127794015006, 2.476824799757432],
					[56.90612778687494, 2.4768248010076186],
					[56.906127774294355, 2.4768248015641268],
					[56.906127767154295, 2.4768248028143129],
					[56.905400102577829, 2.4769469797629591],
					[56.904944587093297, 2.4761638240291495],
					[56.904775205680608, 2.4754721994720366],
					[56.905094583021381, 2.474169492981821],
					[56.90586950356527, 2.4732473882098502],
					[56.907713926797975, 2.4729945981449726],
					[56.907713965725748, 2.4729945847391166],
					[56.907714033715244, 2.4729945415462002],
					[56.907714063755009, 2.4729945132341493],
					[56.908211260731399, 2.4722807187107638],
					[56.908764023734015, 2.4715695665991193],
					[56.909041472163345, 2.471461403117083],
					[56.908944200980983, 2.4725582820068839],
					[56.90894419970931, 2.4725582921081148],
					[56.908944200736002, 2.4725583116850007],
					[56.908944201249341, 2.4725583214734481],
					[56.908999801151957, 2.4730556227824811],
					[56.908999803792476, 2.4730556387838396],
					[56.908999812643486, 2.4730556701610382],
					[56.908999818853999, 2.4730556855368615],
					[56.90923041911546, 2.4735472853598948],
					[56.9092304299595, 2.4735473032169066],
					[56.909230456024531, 2.4735473365176746],
					[56.90923047035303, 2.4735473521177869],
					[56.909569269745624, 2.4738362526939071],
					[56.909569343920076, 2.4738362759200205],
					[56.90956948731899, 2.4738362623217229],
					[56.909569554758427, 2.4738362258100461],
					[56.909900155267181, 2.4734279254170621],
					[56.909900166185302, 2.4734279103325423],
					[56.909900183387954, 2.4734278776825027],
					[56.909900190564983, 2.4734278599605886],
					[56.910283490825982, 2.4722194607500936],
					[56.910283492990082, 2.4722194504920858],
					[56.910283496596954, 2.4722194333953929],
					[56.910283498846617, 2.4722194247688458],
					[56.910591897613408, 2.4696222246249877],
					[56.910908497414631, 2.4672222361521836],
					[56.911441895479577, 2.4649139455769791],
					[56.911441895393914, 2.4649139439454752],
					[56.911441896200742, 2.4649139421573967],
					[56.911441896115079, 2.4649139405259084],
					[56.911925184725611, 2.4626111894095661],
					[56.912700153460527, 2.461689029644857],
					[56.912700164291429, 2.4616890129242899],
					[56.912700182383311, 2.4616889801098187],
					[56.912700189558571, 2.4616889623844176],
					[56.913136289874537, 2.4603833632667294],
					[56.913136292123355, 2.4603833546388554],
					[56.913136295899982, 2.4603833408029208],
					[56.913136297256329, 2.4603833323317503],
					[56.913344597059329, 2.4590806328035448],
					[56.913344599050845, 2.4590806192808743],
					[56.913344599721199, 2.4590805977571812],
					[56.913344600013509, 2.4590805861795584],
					[56.913216899867223, 2.4571916875257882],
					[56.913216894925149, 2.4571916620482215],
					[56.913216878965216, 2.4571916154536702],
					[56.913216868754091, 2.4571915925483427],
					[56.912594591042783, 2.4562166267475272],
					[56.912236300915851, 2.4540360894019471],
					[56.912330797932228, 2.452138944705172],
					[56.912711284076082, 2.4512333775968216],
					[56.912711292348035, 2.4512333465036313],
					[56.912711297118271, 2.4512332830942407],
					[56.912711293616574, 2.4512332507780346],
					[56.912361293703427, 2.4498471518028531],
					[56.912361289189171, 2.4498471344843593],
					[56.912361275063482, 2.449847105683554],
					[56.912361267957699, 2.4498470904674052],
					[56.911852967587336, 2.4490665914872038],
					[56.911852900411034, 2.4490665473194206],
					[56.91185275317126, 2.4490665221732728],
					[56.911852675064459, 2.449066544143863],
					[56.911463826956741, 2.4493776015308621],
					[56.910736122930118, 2.4493997990698269],
					[56.910061256561576, 2.4492220351075442],
					[56.909716896368074, 2.4481388573938268],
					[56.909533498805217, 2.4465499770750423],
					[56.909533496590797, 2.4465499692323327],
					[56.909533493861026, 2.4465499516015323],
					[56.909533491646641, 2.4465499437588178],
					[56.909358492351636, 2.4459582429643878],
					[56.909358422100908, 2.4459581746489016],
					[56.909358257594, 2.4459581295038055],
					[56.909358164316195, 2.4459581541484949],
					[56.908916563677494, 2.4463692548043086],
					[56.908916554290016, 2.4463692646880908],
					[56.90891653729998, 2.4463692841416256],
					[56.908916528890856, 2.4463692954997107],
					[56.908363728937971, 2.4472831955837249],
					[56.907372060076092, 2.4489081453956691],
					[56.906477905272631, 2.4493330283199097],
					[56.906077997070817, 2.4483499532261566],
					[56.905828000868823, 2.4454722022973652],
					[56.906191894245737, 2.4424722574115574],
					[56.907344542779363, 2.440147361851392],
					[56.907958254880356, 2.4399280287653764],
					[56.909877666424563, 2.4416612484555991],
					[56.909877749699959, 2.4416612733087386],
					[56.909877904985066, 2.4416612459673912],
					[56.909877976188277, 2.4416611955613892],
					[56.910758475756126, 2.4400389958556077],
					[56.910758485347635, 2.4400389727615117],
					[56.910758497133152, 2.4400389229366342],
					[56.910758500305597, 2.4400388976800333],
					[56.910739099687589, 2.4379471976292968],
					[56.91073909333317, 2.4379471625252358],
					[56.910739069194676, 2.4379470976238204],
					[56.910739049625725, 2.4379470681409208],
					[56.909889062626981, 2.4369776832593479],
					[56.90954739867913, 2.4363916453079009],
					[56.909530800800724, 2.4348000572425423],
					[56.910580768389941, 2.4331723086407195],
					[56.910580783366406, 2.4331722681301886],
					[56.910580795212532, 2.4331721853618937],
					[56.910580792168275, 2.4331721447354866],
					[56.909886292247506, 2.4308027433606325],
					[56.909886277139101, 2.4308027130952956],
					[56.909886237277789, 2.43080265755856],
					[56.909886211718586, 2.4308026340758735],
					[56.909041812418302, 2.4302331334109688],
					[56.909041742237882, 2.430233117799256],
					[56.909041609900967, 2.4302331362008953],
					[56.90904154685208, 2.4302331703716549],
					[56.908263747078365, 2.4311554715406207],
					[56.908263747078365, 2.4311554715406207],
					[56.908263746185945, 2.4311554716979953],
					[56.907324861268002, 2.4322804545537897],
					[56.907047254657932, 2.4324886091273172],
					[56.906136269799006, 2.4310248934287144],
					[56.906136266863442, 2.4310248890073569],
					[56.906136260099892, 2.4310248803220191],
					[56.906136256185796, 2.4310248744268788],
					[56.905514056304916, 2.4302470750016556],
					[56.905514019692184, 2.4302470468859698],
					[56.905513938403523, 2.4302470085396033],
					[56.905513892921284, 2.4302470000974199],
					[56.904449993904372, 2.4302831005863039],
					[56.90299719369149, 2.4303275995917191],
					[56.902997164327608, 2.4303276064157262],
					[56.902997106836672, 2.4303276264304028],
					[56.902997079602045, 2.4303276394637163],
					[56.902302679739272, 2.430849840769437],
					[56.902302669374549, 2.4308498491815302],
					[56.902302649709739, 2.4308498691103213],
					[56.902302640409637, 2.4308498806270222],
					[56.901610942294681, 2.4317665787016116],
					[56.900724850677705, 2.4328887658998939],
					[56.899780468737283, 2.4338165484243235],
					[56.898280485596707, 2.4349581353459708],
					[56.896777699283462, 2.4359026271778879],
					[56.894827732188404, 2.4369609083016268],
					[56.892958278017161, 2.4373192014833056],
					[56.891894501746535, 2.4373525965082941],
					[56.891072402962159, 2.4361832402359567],
					[56.891116899897867, 2.4350889074268496],
					[56.891116899123602, 2.4350888927522703],
					[56.891116894639694, 2.4350888589831277],
					[56.891116891994535, 2.4350888429924153],
					[56.890883492265807, 2.4342999431373511],
					[56.890883447858378, 2.4342998867790402],
					[56.890883335141858, 2.4342998161225036],
					[56.890883265133937, 2.4342998037691608],
					[56.890216665341619, 2.4344192028804827],
					[56.890216644009271, 2.4344192082834843],
					[56.89021660436601, 2.4344192251397976],
					[56.890216584270242, 2.43441923690748],
					[56.889185984291899, 2.4351498366381299],
					[56.88918596623899, 2.4351498529819953],
					[56.88918593485343, 2.4351498897751758],
					[56.889185920628329, 2.435149910381659],
					[56.888472029047925, 2.4365637946686145],
					[56.887419291002089, 2.4379915114005928],
					[56.886191677581785, 2.4381304014198069],
					[56.886191675796908, 2.4381304017339582],
					[56.886191674012032, 2.4381304020481087],
					[56.886191673119598, 2.4381304022051875],
					[56.885241745357895, 2.4382580925216093],
					[56.884339048148462, 2.4376915563861874],
					[56.883714073349147, 2.4366165996871749],
					[56.883714068370942, 2.4366165906915218],
					[56.883714055909223, 2.4366165764318817],
					[56.883714050038577, 2.4366165675933278],
					[56.882866850002351, 2.4356498684386891],
					[56.882866842346772, 2.4356498599147112],
					[56.882866825250765, 2.4356498431809861],
					[56.882866815896328, 2.4356498366013923],
					[56.881119546308568, 2.434413759560397],
					[56.880097366565188, 2.4328554902472455],
					[56.880097329609164, 2.4328554556261754],
					[56.880097242622867, 2.4328554117190082],
					[56.880097194291409, 2.4328554004885348],
					[56.879647331443216, 2.4328691967037153],
					[56.879272383801833, 2.4319860224286503],
					[56.879272346243205, 2.4319859763992895],
					[56.879272250278142, 2.4319859159798338],
					[56.879272192678116, 2.4319858998025539],
					[56.878516693186761, 2.4320109004140731],
					[56.878516647844918, 2.432010911689968],
					[56.878516569075217, 2.4320109551749418],
					[56.878516534754922, 2.4320109875411942],
					[56.877688874678107, 2.4332302800013923],
					[56.877239094669115, 2.432747116193446],
					[56.877105799610582, 2.4304638892984176],
					[56.876897401169686, 2.4261972155932585],
					[56.87743079596445, 2.4236944424875926],
					[56.877430797693897, 2.4236944240868601],
					[56.877430799367929, 2.4236943876001371],
					[56.877430798420114, 2.4236943696715114],
					[56.877219597918454, 2.4223082695277451],
					[56.877219530623314, 2.4223081892735054],
					[56.877219362830211, 2.4223081333249024],
					[56.877219261353652, 2.422308156158071],
					[56.876360960669686, 2.4231331557587952],
					[56.876360942963032, 2.4231331786215873],
					[56.876360915033722, 2.4231332296074886],
					[56.876360904811065, 2.4231332577305906],
					[56.875877607401982, 2.4253360452127626],
					[56.87511921066605, 2.427547134986749],
					[56.875119208419342, 2.4275471436074727],
					[56.875119204012037, 2.4275471624787066],
					[56.875119201851454, 2.4275471727292137],
					[56.874747006970715, 2.4301443396644054],
					[56.873652633369602, 2.4323637860610319],
					[56.87243315617394, 2.4336970613400144],
					[56.871491607245282, 2.4346220102385967],
					[56.870566673184165, 2.434749801999299],
					[56.870566621218515, 2.4347498243029526],
					[56.87056653936154, 2.4347498946308317],
					[56.870566507685304, 2.4347499429691872],
					[56.870297016789756, 2.4356527141613871],
					[56.869688765696601, 2.4365665407880432],
					[56.868963854074273, 2.436888701008519],
					[56.868630593277288, 2.4368998003960427],
					[56.868630543644564, 2.4368998157062389],
					[56.868630459861905, 2.4368998666281074],
					[56.868630424905525, 2.4368999040263306],
					[56.868244224952349, 2.4376055045831695],
					[56.868244218414652, 2.4376055172456645],
					[56.868244208188507, 2.4376055453587626],
					[56.868244204414076, 2.4376055591798376],
					[56.867869204405046, 2.4394082582226542],
					[56.867869203221801, 2.4394082699430983],
					[56.867869199790974, 2.4394082902819081],
					[56.867869200392633, 2.4394083016884931],
					[56.867885900078271, 2.4410971904503622],
					[56.867783108019907, 2.4420916173236384],
					[56.867338748984395, 2.4426026695413658],
					[56.867338739682886, 2.4426026810437835],
					[56.867338723929038, 2.4426027068370373],
					[56.867338715691794, 2.4426027214416024],
					[56.866902645601613, 2.4436109513674413],
					[56.866458252290201, 2.4438220026007929],
					[56.866066692660482, 2.4438359008558943],
					[56.866066641241737, 2.4438359164692733],
					[56.866066553423003, 2.4438359763031974],
					[56.866066517743675, 2.4438360171080218],
					[56.865791518413417, 2.444441616589502],
					[56.865791510605348, 2.444441639340218],
					[56.865791502214726, 2.4444416852166633],
					[56.86579149993316, 2.4444417102854361],
					[56.865860900396775, 2.445827806595041],
					[56.865874799901491, 2.4473194015059008],
					[56.865874800330722, 2.4473194096531352],
					[56.86587480216749, 2.4473194274203465],
					[56.865874802596707, 2.4473194355675987],
					[56.866466476950521, 2.4506776866975151],
					[56.8662444133371, 2.4507858703753187],
					[56.865794507183033, 2.4504998312831185],
					[56.865794472239706, 2.4504998176827217],
					[56.865794400362098, 2.4504998039875545],
					[56.86579436164287, 2.4504998042061064],
					[56.865180561860157, 2.4506192039862218],
					[56.865180531773746, 2.4506192142007386],
					[56.865180477556301, 2.4506192450958491],
					[56.865180453339484, 2.4506192641470275],
					[56.864516552641334, 2.4513359645374466],
					[56.864516535993076, 2.4513359904817582],
					[56.864516510007881, 2.4513360443761076],
					[56.864516501649199, 2.45133607379893],
					[56.864360901841323, 2.4525332740831556],
					[56.864360900571043, 2.4525332841727674],
					[56.86436089972964, 2.4525333024093841],
					[56.8643609002443, 2.4525333121857886],
					[56.864424800338838, 2.453622211526008],
					[56.864424800424601, 2.4536222131554264],
					[56.864424799532131, 2.4536222133120034],
					[56.864555399612712, 2.4555083140447067],
					[56.864555400762391, 2.4555083187764253],
					[56.864555401997812, 2.4555083251375751],
					[56.864555402169294, 2.4555083283964048],
					[56.864855397776367, 2.457583301582777],
					[56.864544205321806, 2.4600777465460344],
					[56.864052616629849, 2.4611860191903681],
					[56.864052612045903, 2.4611860347934713],
					[56.864052603770503, 2.4611860658433002],
					[56.864052600971533, 2.4611860811336284],
					[56.86384980114785, 2.4632777807981179],
					[56.863849801404754, 2.4632777856863681],
					[56.863849800133593, 2.4632777957755385],
					[56.863849799583647, 2.4632778024495434],
					[56.863858099735332, 2.4642694024261091],
					[56.863858104932106, 2.4642694327597683],
					[56.86385812416497, 2.4642694902344311],
					[56.863858138201032, 2.4642695173754694],
					[56.864424809250899, 2.4650472763153775],
					[56.864547000242375, 2.4659389156894442],
					[56.864552599053994, 2.4665332316438806],
					[56.863997044868633, 2.4672470735396632],
					[56.863555449065579, 2.4677554683915508],
					[56.863555440654068, 2.4677554797304051],
					[56.863555427315454, 2.4677555001537499],
					[56.863555420688954, 2.4677555111801288],
					[56.863005466968076, 2.4688637192564449],
					[56.862224967660246, 2.4689887024302988],
					[56.862224925333642, 2.4689887197040004],
					[56.862224851610236, 2.468988773714921],
					[56.862224821998396, 2.4689888101397375],
					[56.861458121894579, 2.4704999099415819],
					[56.86145811858119, 2.4704999154542189],
					[56.861458113017989, 2.4704999295819858],
					[56.861458109790142, 2.4704999367239555],
					[56.861019210115685, 2.4718055362988016],
					[56.861019233439137, 2.4718056374526935],
					[56.861019355462105, 2.4718057657367885],
					[56.861019455054134, 2.4718057927108474],
					[56.862194442789274, 2.4714723961557445],
					[56.863700011252462, 2.4712280004699552],
					[56.864933255931064, 2.4712918979663154],
					[56.866283160753547, 2.4718473583306162],
					[56.866621997848867, 2.4724333497537621],
					[56.866577600714521, 2.4735277667257303],
					[56.866252617899711, 2.4746277127232306],
					[56.865535952113383, 2.475644263042907],
					[56.865035972652301, 2.4760554465396005],
					[56.865035958077911, 2.4760554605989396],
					[56.865035931141485, 2.4760554965530837],
					[56.865035918608498, 2.4760555151888486],
					[56.864655418767782, 2.4768638152965017],
					[56.864655417239142, 2.4768638204970226],
					[56.864655412396807, 2.4768638312101325],
					[56.864655410061111, 2.4768638381961843],
					[56.864327610122579, 2.4778666362559871],
					[56.863944256422592, 2.4789692041667553],
					[56.863500011658999, 2.4789831005842289],
					[56.8629389291889, 2.4788998025288329],
					[56.862938894124476, 2.4788998037232699],
					[56.862938827271165, 2.4788998170501961],
					[56.862938793697268, 2.4788998294946354],
					[56.862269339743136, 2.4793165015636864],
					[56.861316736841026, 2.4793469989879537],
					[56.860419583762841, 2.4789748605530293],
					[56.860297401661995, 2.4779861014836428],
					[56.860502992903939, 2.4763889636273815],
					[56.860783465966485, 2.4758862118288794],
					[56.861114054690226, 2.4754779262517568],
					[56.86111407278694, 2.4754778934909112],
					[56.86111409542174, 2.4754778270511144],
					[56.861114099874371, 2.4754777917428203],
					[56.861041899649749, 2.4738916916295497],
					[56.861041848451677, 2.4738916085031617],
					[56.861041698222792, 2.4738915262517285],
					[56.861041600084505, 2.473891526970605],
					[56.860316599598654, 2.4743109275921022],
					[56.860316596028618, 2.4743109282163447],
					[56.860316589145015, 2.4743109343527059],
					[56.860316584767958, 2.4743109367623011],
					[56.85942768459482, 2.4749331365669991],
					[56.85942768459482, 2.4749331365669991],
					[56.859427682895287, 2.4749331385083382],
					[56.858427692370398, 2.4756581312713455],
					[56.857313828847616, 2.4762859116586848],
					[56.856755558986073, 2.4764026047236873],
					[56.856755542113802, 2.4764026093166294],
					[56.856755510325151, 2.4764026214488513],
					[56.856755493623787, 2.4764026293000829],
					[56.856255493995448, 2.4767137296591373],
					[56.856255481034999, 2.476713740144465],
					[56.856255456092093, 2.4767137625882598],
					[56.856255445002141, 2.4767137743907477],
					[56.855758176978838, 2.4773248332924411],
					[56.854977737203882, 2.477647009033463],
					[56.854530552438042, 2.4777581064041345],
					[56.854530547082952, 2.4777581073397958],
					[56.854530539135745, 2.4777581103723474],
					[56.85453053467316, 2.4777581111520672],
					[56.853580534655251, 2.4780859103641379],
					[56.853580498830965, 2.4780859314184376],
					[56.853580439639451, 2.4780859877900845],
					[56.853580416186766, 2.4780860214784193],
					[56.852980416063623, 2.4794916213250637],
					[56.852980415256525, 2.479491623109948],
					[56.852980413642307, 2.4794916266797178],
					[56.852980412835201, 2.4794916284646011],
					[56.852108113303181, 2.4818027281583999],
					[56.852108110245375, 2.4818027385555173],
					[56.852108104851546, 2.481802755935989],
					[56.852108102600873, 2.4818027645483132],
					[56.851894212199817, 2.4829999174697055],
					[56.851119270186686, 2.4840137407815002],
					[56.850230513781739, 2.4844387197725255],
					[56.850230498057613, 2.4844387290917638],
					[56.850230469457642, 2.4844387505207215],
					[56.850230454711387, 2.4844387613130912],
					[56.849841672321801, 2.4848469393285479],
					[56.848716812416207, 2.4840859333801784],
					[56.848716796731154, 2.4840859262551334],
					[56.848716765361026, 2.4840859120050331],
					[56.848716748868767, 2.4840859066645717],
					[56.847650048592563, 2.4838192066993545],
					[56.847650004346598, 2.4838192045588818],
					[56.847649917942142, 2.4838192229250584],
					[56.847649876590822, 2.4838192416471587],
					[56.846985977366629, 2.4843359423363869],
					[56.846985974774384, 2.4843359444323636],
					[56.846985972182139, 2.4843359465283372],
					[56.846985969589909, 2.48433594862431],
					[56.846149869918712, 2.4850526480609298],
					[56.846149862227279, 2.4850526559772814],
					[56.8461498460372, 2.4850526735944087],
					[56.846149839238301, 2.48505268135504],
					[56.845099838614161, 2.4864748813443929],
					[56.845099826335272, 2.4864749048523809],
					[56.845099807303306, 2.4864749541915994],
					[56.845099800635552, 2.4864749816515208],
					[56.844947000749343, 2.4880666759076067],
					[56.844683101984934, 2.4898610712412372],
					[56.844683101984934, 2.4898610712412372],
					[56.844683102155464, 2.4898610744985672],
					[56.844683101348203, 2.4898610762828288],
					[56.844427602298396, 2.4920499722318161],
					[56.844216502931431, 2.493347168614426],
					[56.844216501702626, 2.4933471967681538],
				],
			],
		},
		{
			fldNpdidField: "21675447",
			wlbNpdidWellbore: "507",
			fldName: "MARTIN LINGE",
			fldCurrentActivitySatus: "Producing",
			wlbName: "30/7-6 R",
			fldDiscoveryYear: "1978",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=21675447",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=21675447",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "MARTIN LINGE UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=507",
			fldHcType: "OIL/GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "3812119",
			fldDateUpdated: "2022-09-13 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Tananger/Dusavik",
			fldAreaGeometryWKT: [
				[
					[60.497238699687436, 1.9688722135851344],
					[60.497333100385468, 1.9702500138271657],
					[60.497333111400039, 1.9702500516301653],
					[60.497333143835121, 1.9702501194903694],
					[60.497333167037773, 1.9702501491598128],
					[60.498852662615967, 1.9715945442102834],
					[60.500374841262811, 1.9731279236097459],
					[60.501127627748843, 1.9743834035065722],
					[60.501127630613212, 1.9743834065385608],
					[60.501127636437573, 1.9743834144091679],
					[60.501127639301941, 1.9743834174411341],
					[60.502363739071477, 1.9760751181181779],
					[60.502363742922483, 1.9760751227633899],
					[60.502363750719994, 1.97607513386058],
					[60.502363755462056, 1.9760751383120581],
					[60.503599854658994, 1.9773723384358923],
					[60.50359987442264, 1.9773723542426391],
					[60.503599916336555, 1.9773723798544915],
					[60.503599939377914, 1.9773723894658852],
					[60.505405539423258, 1.977955790027992],
					[60.505405571184326, 1.9779557940891557],
					[60.505405636106481, 1.9779557945963466],
					[60.505405668185418, 1.9779557876222633],
					[60.506641758578809, 1.9775057912090928],
					[60.508258349250497, 1.9771001946423736],
					[60.508258373914082, 1.977100183795562],
					[60.508258421172428, 1.9771001570682596],
					[60.508258442780573, 1.9771001395744654],
					[60.509113886455594, 1.9762253017164029],
					[60.510822043960808, 1.9783418256618917],
					[60.510822047811757, 1.9783418303085429],
					[60.510822056404677, 1.978341839408079],
					[60.510822060160088, 1.9783418422474897],
					[60.512152652958534, 1.9796390365435705],
					[60.513388746203994, 1.9811195278202935],
					[60.513388751041425, 1.9811195340813061],
					[60.513388762402954, 1.9811195444085417],
					[60.513388767144889, 1.9811195488621753],
					[60.515097066991174, 1.9826418482431054],
					[60.515097077366043, 1.982641856957581],
					[60.515097098020284, 1.9826418725790591],
					[60.515097109095279, 1.9826418774849097],
					[60.516997071209509, 1.983608459396317],
					[60.518330441884856, 1.985277923496765],
					[60.519752641474788, 1.9871445216166452],
					[60.519752645325447, 1.9871445262655587],
					[60.519752653026778, 1.9871445355634076],
					[60.519752657768542, 1.9871445400187566],
					[60.520797058022396, 1.9882029404984958],
					[60.520797097165939, 1.9882029649095105],
					[60.520797180736643, 1.9882029942984139],
					[60.520797226945966, 1.9882029988891821],
					[60.522413927338768, 1.9879807981933955],
					[60.522413929120951, 1.9879807978062314],
					[60.522413931794254, 1.9879807972254795],
					[60.522413932685346, 1.987980797031899],
					[60.523841671744428, 1.9877419062091302],
					[60.524885966929439, 1.988225159295457],
					[60.525741536142974, 1.9894529143302291],
					[60.525741540980079, 1.9894529205947633],
					[60.525741550749665, 1.9894529349319683],
					[60.525741557368953, 1.9894529408093831],
					[60.526974857160432, 1.9907140402304671],
					[60.526974900854562, 1.9907140654891688],
					[60.526974995118117, 1.9907140925666034],
					[60.526975045687557, 1.9907140943853303],
					[60.528113946407714, 1.9904445945624951],
					[60.528113955318751, 1.9904445926268914],
					[60.528113972950031, 1.9904445851392145],
					[60.528113980969948, 1.9904445833971707],
					[60.529163954318143, 1.9899779943852793],
					[60.530016637660346, 1.9898724086831074],
					[60.531535971147846, 1.9911557532944721],
					[60.531535982413367, 1.9911557618222264],
					[60.531536005740129, 1.9911557768757369],
					[60.531536017801379, 1.9911557834014948],
					[60.533152716964651, 1.99187798164484],
					[60.534672072952723, 1.9925918608403836],
					[60.535810944429258, 1.9940195254520676],
					[60.53581095964045, 1.9940195404424799],
					[60.535810997287037, 1.9940195706837973],
					[60.535811016858432, 1.9940195828979035],
					[60.536858216609609, 1.9944945822231233],
					[60.536858223929173, 1.9944945842924613],
					[60.536858236881379, 1.9944945906269025],
					[60.536858244200943, 1.9944945926962263],
					[60.538758243850225, 1.9950501920989576],
					[60.538758248401102, 1.9950501929402327],
					[60.538758255720666, 1.9950501950097734],
					[60.538758260271557, 1.9950501958510261],
					[60.540469360047425, 1.9953973953723656],
					[60.542177752677951, 1.9957473944313804],
					[60.544174945839053, 1.9963057938468183],
					[60.544174966715907, 1.996305796632337],
					[60.54417500917009, 1.9963057983915344],
					[60.544175029856305, 1.9963057975587373],
					[60.544936091160054, 1.9961891035086765],
					[60.54617209952012, 1.9964918803966767],
					[60.547027660922225, 1.9973168446264662],
					[60.547027674956105, 1.997316854388868],
					[60.547027703023851, 1.997316873913672],
					[60.547027717853531, 1.9973168816732563],
					[60.548072117920093, 1.9977862823373762],
					[60.548072139878656, 1.9977862885492517],
					[60.548072183605186, 1.9977862973542531],
					[60.548072206264258, 1.9977862997538662],
					[60.549594406186152, 1.9977390998559172],
					[60.54959446194507, 1.9977390804257242],
					[60.549594553129296, 1.9977390148686314],
					[60.549594589350392, 1.9977389667387253],
					[60.550164089251886, 1.9961194660450048],
					[60.550164093040117, 1.9961194524107111],
					[60.550164097847933, 1.9961194239133555],
					[60.550164099758604, 1.996119408856736],
					[60.550261300045122, 1.9937139096550665],
					[60.550358500342476, 1.9920500112305086],
					[60.550358499674772, 1.9920499985641207],
					[60.550358498339357, 1.9920499732313866],
					[60.550358495794079, 1.9920499591429017],
					[60.549975195910022, 1.990155560115856],
					[60.549975186268142, 1.9901555310988026],
					[60.549975158359601, 1.9901554804305086],
					[60.549975139997585, 1.9901554569697777],
					[60.549125142592153, 1.9893248595999125],
					[60.547222369716209, 1.9872720900314913],
					[60.546558484394623, 1.9857110219871772],
					[60.546558477297765, 1.9857110070608379],
					[60.546558460621732, 1.9857109814081211],
					[60.546558451933628, 1.9857109704879179],
					[60.545325182676329, 1.9842749063542116],
					[60.545136293721377, 1.9835082511831303],
					[60.545136283282567, 1.9835082241754654],
					[60.545136253685051, 1.9835081757173048],
					[60.545136236213033, 1.9835081520698974],
					[60.54399454803881, 1.9824526641897597],
					[60.543522357978524, 1.9818415778479406],
					[60.543522324084663, 1.9818415504568596],
					[60.543522247163637, 1.9818415104712093],
					[60.543522204232012, 1.9818414996857066],
					[60.541333304402634, 1.9817942007081868],
					[60.541333289063161, 1.9817942003866622],
					[60.541333258670797, 1.9817942051703921],
					[60.541333245304543, 1.9817942080789284],
					[60.539908266134418, 1.9822054014833288],
					[60.538769404649216, 1.9822719988543809],
					[60.537722270138296, 1.982160905083769],
					[60.537055800050382, 1.9817137928839119],
					[60.537055799942713, 1.9809611006495234],
					[60.537055794754465, 1.9809610706772556],
					[60.537055777249329, 1.9809610122838475],
					[60.53705576413688, 1.9809609858652943],
					[60.53591956396145, 1.9793331860853332],
					[60.535919542924518, 1.9793331632223461],
					[60.535919494104192, 1.9793331262822771],
					[60.535919467211876, 1.9793331120112769],
					[60.535155667139257, 1.9790609116594557],
					[60.535155656159667, 1.9790609085608608],
					[60.53515563330938, 1.9790609025575854],
					[60.535155622425314, 1.9790609012675571],
					[60.533919437646375, 1.9789248025886375],
					[60.532780652965727, 1.9786137065422189],
					[60.532780629415463, 1.9786137043504191],
					[60.532780580628319, 1.9786137021630745],
					[60.532780557364703, 1.9786137053966157],
					[60.531639024577323, 1.9788664671881102],
					[60.531261287260918, 1.9778805284289638],
					[60.531261270392527, 1.977880499175285],
					[60.531261229113809, 1.9778804514550972],
					[60.531261203621284, 1.9778804295658035],
					[60.530122340251168, 1.9771859513718466],
					[60.529552964340219, 1.976363785298568],
					[60.529552955747057, 1.9763637761946373],
					[60.529552939451818, 1.9763637577928226],
					[60.529552929967593, 1.9763637488828525],
					[60.528789029966021, 1.975710948568816],
					[60.52878900476, 1.9757109321074273],
					[60.528788951261546, 1.9757109090016503],
					[60.528788921982475, 1.9757109007429821],
					[60.527175043348578, 1.9755304033396988],
					[60.526322263031588, 1.9752470098236097],
					[60.526322262140511, 1.9752470100175743],
					[60.526322261153844, 1.975247008403449],
					[60.526322260262781, 1.9752470085974112],
					[60.52537225987524, 1.9749498089668951],
					[60.525372258093064, 1.9749498093548281],
					[60.525372255324264, 1.9749498081287036],
					[60.52537225433759, 1.9749498065146485],
					[60.52422789229184, 1.9746304184611772],
					[60.522805722548995, 1.9735165428792232],
					[60.522805713160217, 1.9735165357796995],
					[60.522805691709486, 1.9735165221625912],
					[60.522805680634185, 1.9735165172589002],
					[60.521664010714467, 1.9730081310738523],
					[60.520714047362951, 1.9721359642051659],
					[60.520050157010822, 1.9712915762359389],
					[60.520050143675128, 1.9712915626828775],
					[60.520050114330552, 1.9712915361588079],
					[60.52005009752623, 1.9712915251895304],
					[60.519194526718856, 1.9708109423802196],
					[60.51824454879543, 1.9697442662261619],
					[60.518244529030824, 1.969744250417599],
					[60.518244485141977, 1.9697442215783265],
					[60.518244461017737, 1.9697442085476138],
					[60.517297260652789, 1.9694415092813973],
					[60.517297203846162, 1.9694415088538346],
					[60.517297098987626, 1.9694415371746472],
					[60.517297051031413, 1.9694415677305621],
					[60.516055543700148, 1.9708358615637376],
					[60.51472787460888, 1.9702970154308099],
					[60.514727855322619, 1.9702970086605263],
					[60.51472781258169, 1.9702970015122399],
					[60.51472779090917, 1.9702970007462008],
					[60.51349170730478, 1.9703498008166804],
					[60.512255647400451, 1.9702081047156561],
					[60.51073337126121, 1.9696470134373931],
					[60.50931117235077, 1.9690942143769214],
					[60.509311160384314, 1.9690942096704998],
					[60.509311134860567, 1.9690942042598409],
					[60.509311122098694, 1.9690942015545019],
					[60.508550116391604, 1.969008112224454],
					[60.507316867176513, 1.9671220915684973],
					[60.507316862242803, 1.9671220835037531],
					[60.50731684871544, 1.9671220663434361],
					[60.507316842986334, 1.9671220602797133],
					[60.506458462921707, 1.966247080849348],
					[60.505700176615534, 1.9647999067415731],
					[60.50570016684356, 1.9647998924204881],
					[60.505700144626417, 1.9647998643605478],
					[60.505700133072317, 1.9647998504276445],
					[60.504939032763289, 1.964124850637831],
					[60.504939013093939, 1.9641248366455237],
					[60.504938971369171, 1.9641248146637513],
					[60.504938948327016, 1.9641248050615447],
					[60.503422248481009, 1.963749806584943],
					[60.503422242052189, 1.9637498043301294],
					[60.503422230181279, 1.9637498014331467],
					[60.503422223848197, 1.963749800985082],
					[60.501900023775264, 1.9635665006122018],
					[60.501899990519092, 1.9635665023735787],
					[60.501899926363173, 1.9635665163481948],
					[60.501899894668114, 1.9635665305621843],
					[60.500569298574064, 1.9643831280501596],
					[60.499141602219275, 1.9651887267100645],
					[60.499141583094513, 1.9651887400112438],
					[60.499141548696343, 1.965188771257051],
					[60.499141534313964, 1.9651887890076782],
					[60.497810934298734, 1.9671776882634249],
					[60.497810926853219, 1.9671777008478055],
					[60.497810916321832, 1.9671777232403129],
					[60.497810910658444, 1.967177735436771],
					[60.497238710913848, 1.9688721362249926],
					[60.49723870563291, 1.9688721556466728],
					[60.497238701308461, 1.9688721931326683],
					[60.497238699687436, 1.9688722135851344],
				],
				[
					[
						[60.474347001165079, 2.0376999935923226],
						[60.47434700522021, 2.0377000365388485],
						[60.474347008138977, 2.037700057820262],
						[60.474810907795039, 2.0392472575866947],
						[60.474810908780938, 2.0392472592003403],
						[60.474810909766802, 2.0392472608139918],
						[60.474810909861482, 2.039247262619448],
						[60.475116510013763, 2.040175063587756],
						[60.47511651611832, 2.0401750768808853],
						[60.475116531095679, 2.0401751046972953],
						[60.47511653898264, 2.0401751176068821],
						[60.475799838691721, 2.0411057186478168],
						[60.47579987355941, 2.0411057476630754],
						[60.475799954123218, 2.0411057887565622],
						[60.475799997942296, 2.0411057994127906],
						[60.476472084620156, 2.0411112982752027],
						[60.476924824270355, 2.0418861942084225],
						[60.477458112936375, 2.0429667732227905],
						[60.47791920034215, 2.0445111295504432],
						[60.477927599976987, 2.0455917013011371],
						[60.477938699960553, 2.0466721701208961],
						[60.477355409189876, 2.0485221403089429],
						[60.477355409189876, 2.0485221403089429],
						[60.477355407596555, 2.0485221443033432],
						[60.47735540679988, 2.0485221463005434],
						[60.477138706783663, 2.0492916467565396],
						[60.477138703934784, 2.0492916784098876],
						[60.477138708229567, 2.0492917432208397],
						[60.477138715373215, 2.049291776378424],
						[60.477519215392498, 2.0502194755424674],
						[60.477519252000363, 2.0502195206284042],
						[60.477519343594139, 2.0502195831133232],
						[60.477519400362475, 2.0502196001292599],
						[60.477894258109487, 2.0502195992688161],
						[60.478274810601775, 2.0513000653921019],
						[60.478274848384089, 2.0513001157058768],
						[60.478274941948897, 2.0513001814236018],
						[60.478274999702883, 2.0513002000559371],
						[60.478499952332619, 2.0513002000485319],
						[60.479099845446221, 2.0516057460179482],
						[60.479708112823417, 2.0529944724541322],
						[60.479860909649986, 2.0534556625448861],
						[60.479860917819202, 2.0534556808769717],
						[60.479860936736806, 2.0534557151610033],
						[60.479860949267696, 2.053455730730017],
						[60.480391549228507, 2.0540695309358163],
						[60.480391554953798, 2.0540695370104753],
						[60.480391567295641, 2.0540695489683536],
						[60.480391572926479, 2.0540695532371709],
						[60.480766572678476, 2.0543779536884572],
						[60.480766590462153, 2.0543779663038171],
						[60.480766624760378, 2.0543779845025645],
						[60.480766644043094, 2.0543779913175073],
						[60.481291502349777, 2.0545307518310412],
						[60.481305400235925, 2.0557611023100502],
						[60.48130539962807, 2.0557611079190856],
						[60.481305400991594, 2.0557611167570893],
						[60.481305401180521, 2.0557611203688637],
						[60.481463701101909, 2.0573000207198766],
						[60.481463703734327, 2.0573000365902274],
						[60.481463710592784, 2.0573000643363963],
						[60.48146371589889, 2.0573000796326508],
						[60.48199701632128, 2.0585306801315113],
						[60.481997022235241, 2.0585306898191083],
						[60.48199703317195, 2.05853070938563],
						[60.481997039085897, 2.0585307190732336],
						[60.482452638692813, 2.0591445187351356],
						[60.482452667129465, 2.0591445455028587],
						[60.482452730377354, 2.0591445830601121],
						[60.482452765377424, 2.0591445974615565],
						[60.483274964647649, 2.0592918972370011],
						[60.483275006022318, 2.0592918956603277],
						[60.48327508435699, 2.0592918770182167],
						[60.483275121316979, 2.0592918599527787],
						[60.484091821217532, 2.0586751591591699],
						[60.484091838381332, 2.0586751426899905],
						[60.484091867969504, 2.0586751052901944],
						[60.484091881379506, 2.0586750859742806],
						[60.484311280763045, 2.0582111857141454],
						[60.484752980054253, 2.0572889867035604],
						[60.484752987319844, 2.0572889705324626],
						[60.484752996314711, 2.0572889357264246],
						[60.484752999029659, 2.0572889187061705],
						[60.484822395923196, 2.0565194539473461],
						[60.485189025560096, 2.0557502001692916],
						[60.485460029891875, 2.0557491832664048],
						[60.48573591536578, 2.0563333758062989],
						[60.486160911908676, 2.0575278668012142],
						[60.486160915054583, 2.0575278752578345],
						[60.486160921157513, 2.0575278885587505],
						[60.486160925194667, 2.057527896823975],
						[60.486491525323828, 2.0581278969175836],
						[60.486491549304539, 2.0581279246443813],
						[60.486491604909332, 2.0581279711508902],
						[60.486491637424692, 2.0581279897392015],
						[60.487155510738837, 2.058347380914137],
						[60.487910928751795, 2.0588612247122375],
						[60.488383103098016, 2.0604472410081551],
						[60.488572000848663, 2.0620194203639777],
						[60.488710899243458, 2.0635917007678843],
						[60.488619201391408, 2.0647638844822667],
						[60.488619200080642, 2.0647638938962825],
						[60.488619201118432, 2.0647639137658427],
						[60.488619200698906, 2.0647639229886505],
						[60.488710901380195, 2.0655500230491741],
						[60.488710918187223, 2.0655500687610355],
						[60.488710968965933, 2.0655501437166897],
						[60.488711004017567, 2.0655501763819228],
						[60.489472051810232, 2.0659640465729034],
						[60.490319223651468, 2.0675528947419255],
						[60.490319223745765, 2.0675528965483494],
						[60.49031922571691, 2.0675528997789003],
						[60.490319226702461, 2.0675529013941958],
						[60.491358126710601, 2.0693390016360933],
						[60.492163726702273, 2.070725100545475],
						[60.492163727687789, 2.070725102160941],
						[60.492163730644364, 2.0707251070073531],
						[60.492163731629908, 2.0707251086228164],
						[60.493060931934103, 2.0721112091136193],
						[60.493060934796382, 2.0721112121536955],
						[60.493060940709469, 2.0721112218470137],
						[60.493060942586226, 2.0721112232715222],
						[60.494008143035302, 2.0733029237504459],
						[60.494008155469864, 2.0733029375271026],
						[60.494008181838772, 2.0733029592784114],
						[60.494008195961619, 2.0733029708663606],
						[60.4946665956451, 2.0737057712897955],
						[60.494666611267753, 2.0737057770760505],
						[60.494666642607285, 2.0737057904552438],
						[60.494666660012477, 2.0737057958594556],
						[60.495708259742074, 2.073919596213933],
						[60.495708268843167, 2.0739195979172851],
						[60.495708286951157, 2.0739195995172546],
						[60.495708295958046, 2.0739195994138533],
						[60.496416678257738, 2.0739335001841042],
						[60.497505491195675, 2.0741501813686578],
						[60.49840263757919, 2.0752362175093158],
						[60.498966531444758, 2.0761223074368762],
						[60.498966557204625, 2.0761223348053823],
						[60.498966617166076, 2.0761223785978591],
						[60.498966651273442, 2.0761223932149075],
						[60.500197151306246, 2.0764334932336777],
						[60.500197158624786, 2.0764334953198511],
						[60.50019717593571, 2.0764334989192665],
						[60.500197184942607, 2.0764334988164679],
						[60.501805568273241, 2.0765584977081786],
						[60.502655551734719, 2.0767668949111986],
						[60.502655559944529, 2.0767668968065927],
						[60.502655576270001, 2.0767668987901984],
						[60.502655582603055, 2.0767668992604129],
						[60.504119383287033, 2.0768890982084964],
						[60.504119386946314, 2.0768890992517326],
						[60.504119393279396, 2.0768890997219871],
						[60.50411939693867, 2.0768891007652197],
						[60.505302776571646, 2.0769085003461001],
						[60.506247157187921, 2.0771168943330407],
						[60.506247159955919, 2.0771168955673609],
						[60.506247166289015, 2.0771168960376465],
						[60.506247169057012, 2.0771168972719853],
						[60.507616668969696, 2.0773334988217464],
						[60.507616726199402, 2.077333490211724],
						[60.5076168273768, 2.0773334429411929],
						[60.507616871324522, 2.0773334042806626],
						[60.508372350655456, 2.0760973370404239],
						[60.509747310980572, 2.0750640661148725],
						[60.510691802109335, 2.0745057712270585],
						[60.510691803000604, 2.0745057710359442],
						[60.510691803891859, 2.0745057708448336],
						[60.511922286778749, 2.073761280651083],
						[60.512675068294882, 2.0734890886350934],
						[60.512675116791883, 2.0734890508148101],
						[60.512675182866175, 2.0734889525593401],
						[60.512675199552241, 2.0734888923153361],
						[60.512630799753637, 2.072144392518501],
						[60.512630800456357, 2.072144388711922],
						[60.512630798999488, 2.0721443780569992],
						[60.512630797825445, 2.0721443728251416],
						[60.512444607287115, 2.0707972346666361],
						[60.512866870642476, 2.0699445049244538],
						[60.51315008272492, 2.0695669225947761],
						[60.513672035064346, 2.0696779696239567],
						[60.513858106020535, 2.0704472466411099],
						[60.513858129065284, 2.0704472910515523],
						[60.513858194008115, 2.0704473612029037],
						[60.513858235109197, 2.0704473889428336],
						[60.514758235211396, 2.0707557889309691],
						[60.514758237088202, 2.0707557903562646],
						[60.514758240747504, 2.0707557913990668],
						[60.514758244406821, 2.0707557924418625],
						[60.516174935560564, 2.0711723901535888],
						[60.517216627779611, 2.0715751867228587],
						[60.517216628670901, 2.0715751865316077],
						[60.517216630547686, 2.0715751879570528],
						[60.517216632330197, 2.0715751875745649],
						[60.518349932031832, 2.0719834872233354],
						[60.518349933908596, 2.0719834886488542],
						[60.518349937567912, 2.0719834896918807],
						[60.518349941227207, 2.0719834907349077],
						[60.519011040973531, 2.072189091485269],
						[60.519011053733962, 2.0721890942319972],
						[60.519011081928632, 2.0721890991517036],
						[60.519011096377319, 2.0721890997078911],
						[60.520583296376721, 2.0722223994464115],
						[60.520583300927306, 2.0722224002982608],
						[60.520583310825423, 2.0722224000026022],
						[60.520583317064229, 2.0722223986638206],
						[60.521483316830214, 2.07214739928845],
						[60.521483326634062, 2.072147397184549],
						[60.521483347227296, 2.0721473945936539],
						[60.521483357031151, 2.0721473924897511],
						[60.522380657256697, 2.0718807914731605],
						[60.522380672313801, 2.0718807864132032],
						[60.522380702145192, 2.0718807708686007],
						[60.52238071612247, 2.0718807623834596],
						[60.523280715931683, 2.0712362622393474],
						[60.523280721184925, 2.0712362592832405],
						[60.523280728923396, 2.0712362521366892],
						[60.523280733285375, 2.0712362493719021],
						[60.524322332596704, 2.070311250186176],
						[60.524322333393648, 2.070311248186512],
						[60.526497333194222, 2.0683751482798862],
						[60.526497352749715, 2.0683751257930778],
						[60.526497384164635, 2.0683750715000535],
						[60.526497396118444, 2.0683750415022901],
						[60.526733496147735, 2.0672444411022033],
						[60.526733497878006, 2.0672444224433444],
						[60.52673349767926, 2.0672443840829255],
						[60.526733496641448, 2.0672443641899316],
						[60.526497396528079, 2.065913864373599],
						[60.526497396433705, 2.0659138625651665],
						[60.526497395165002, 2.0659138555228913],
						[60.526497394085041, 2.0659138520975016],
						[60.526214093759116, 2.0647693519996801],
						[60.526214093664755, 2.0647693501912654],
						[60.526214093664755, 2.0647693501912654],
						[60.525694594486097, 2.0627638507648896],
						[60.525694583171962, 2.062763823937928],
						[60.525694554588227, 2.0627637770500247],
						[60.525694537224233, 2.0627637551806881],
						[60.525177999854961, 2.062274914477265],
						[60.525178000329483, 2.0607555994151046],
						[60.525178000235044, 2.0607555976067644],
						[60.525178000046189, 2.0607555939900686],
						[60.525177999951758, 2.0607555921817289],
						[60.525086300189997, 2.0583832918067779],
						[60.525086300189997, 2.0583832918067779],
						[60.524994599883108, 2.0563888915474462],
						[60.524994597155732, 2.0563888738477414],
						[60.524994590013044, 2.0563888406400794],
						[60.524994584611981, 2.0563888235155634],
						[60.524472395995993, 2.055138851698],
						[60.524330802355358, 2.0537111070180925],
						[60.5245695907116, 2.0522972668839277],
						[60.525139041144882, 2.0512751588265399],
						[60.526366760283842, 2.0507584912516714],
						[60.526744274833064, 2.0506808296085577],
						[60.527308113802093, 2.0521222725080142],
						[60.527308167208808, 2.0521223286757206],
						[60.527308296328954, 2.0521223850030834],
						[60.52730837302817, 2.0521223867793119],
						[60.528400073072412, 2.0516945861905072],
						[60.528400088034012, 2.0516945793115702],
						[60.528400115985676, 2.0516945623204967],
						[60.528400128975726, 2.0516945522083478],
						[60.528825129160111, 2.0513334525716909],
						[60.528825146227959, 2.0513334342657483],
						[60.528825174732688, 2.0513333933797155],
						[60.528825186264108, 2.0513333726081173],
						[60.529297344792631, 2.0501251785722849],
						[60.530197232769382, 2.0499751965157333],
						[60.530197249702503, 2.0499751928688519],
						[60.530197284270713, 2.0499751817659879],
						[60.530197300028853, 2.0499751728853139],
						[60.530952849999153, 2.0495363022946589],
						[60.531758291855745, 2.0495696005977044],
						[60.53175834959552, 2.0495695845026773],
						[60.53175844723598, 2.0495695214033502],
						[60.531758486245458, 2.0495694745909971],
						[60.532089085647712, 2.0487361749040573],
						[60.532089088037552, 2.04873616890205],
						[60.532089091129393, 2.0487361590906836],
						[60.532089091831395, 2.0487361552813197],
						[60.532466891912982, 2.0474361557721195],
						[60.532466896030179, 2.04743613110689],
						[60.532466898120745, 2.0474360849293007],
						[60.532466895999484, 2.0474360616082654],
						[60.532183495661769, 2.0460110602118795],
						[60.532183488098369, 2.0460110362348782],
						[60.532183466922405, 2.0460109932426711],
						[60.53218345321519, 2.0460109724188],
						[60.53152515294753, 2.04522487196872],
						[60.531525133001765, 2.0452248524901737],
						[60.531525087250387, 2.0452248221121816],
						[60.531525062241315, 2.04522480921203],
						[60.529822262307881, 2.0446720105309453],
						[60.529822255880134, 2.0446720082584591],
						[60.529822244010496, 2.0446720053299212],
						[60.529822237677401, 2.0446720048659728],
						[60.528544503172824, 2.044424815815173],
						[60.527366848033758, 2.0431359649003444],
						[60.527366832031248, 2.0431359518905845],
						[60.527366799229696, 2.0431359278715862],
						[60.527366782430668, 2.0431359168623255],
						[60.525947283318551, 2.0424998180690235],
						[60.524575120672814, 2.0418637352378113],
						[60.523300147836693, 2.0404692641309889],
						[60.523300135682867, 2.0404692557799624],
						[60.523300112077045, 2.0404692352694438],
						[60.523300099923226, 2.0404692269184141],
						[60.522214022383196, 2.0398442397388736],
						[60.520889057973868, 2.0385387746130283],
						[60.520180772082462, 2.0373581971274493],
						[60.520180751946242, 2.0373581740442361],
						[60.520180708393276, 2.0373581340705709],
						[60.520180683194134, 2.0373581175645423],
						[60.519762441400317, 2.0371668121170425],
						[60.521866613191158, 2.0379723818564566],
						[60.524563798664865, 2.0395557718578279],
						[60.524563799650799, 2.0395557734738623],
						[60.524563802419102, 2.0395557747055393],
						[60.524563803310294, 2.0395557745133566],
						[60.527819297337516, 2.0413501714858384],
						[60.530066592226554, 2.0427973682905818],
						[60.530066610808028, 2.0427973789161964],
						[60.530066650265873, 2.0427973923568592],
						[60.530066671331589, 2.0427973987889776],
						[60.532602770556338, 2.043175197509171],
						[60.532602775998228, 2.0431751981649775],
						[60.532602785990804, 2.0431751996687528],
						[60.532602791432708, 2.0431752003245558],
						[60.534655591168388, 2.0432668998040633],
						[60.534655660303443, 2.0432668775803449],
						[60.534655762948105, 2.0432667895963577],
						[60.534655798429469, 2.0432667270693505],
						[60.535080793311757, 2.0401528624864524],
						[60.535741868417048, 2.0389223105056731],
						[60.536775157251974, 2.0376057226986992],
						[60.536775168079409, 2.0376057057271857],
						[60.536775186074735, 2.0376056707445613],
						[60.536775193147903, 2.0376056509245801],
						[60.5371390928088, 2.0362306517155089],
						[60.537139097018631, 2.0362306288542902],
						[60.537139098308764, 2.0362305846708293],
						[60.537139096280256, 2.0362305631562272],
						[60.53686689586219, 2.0348027626125864],
						[60.536866870738713, 2.0348027131560174],
						[60.53686679817406, 2.0348026355255682],
						[60.536866749746927, 2.0348026057352215],
						[60.536344450164677, 2.0346665059085134],
						[60.536344406818984, 2.0346665042910002],
						[60.536344323843934, 2.0346665203762533],
						[60.53634428411975, 2.0346665362701617],
						[60.535677684398301, 2.0351387368972778],
						[60.535677656316189, 2.0351387685698192],
						[60.535677614088485, 2.0351388398819878],
						[60.535677600928892, 2.0351388811380597],
						[60.535538700818407, 2.0365805797853893],
						[60.535397012981285, 2.0377970728015176],
						[60.534952850153587, 2.0380358464840218],
						[60.534164070172821, 2.0370776913439097],
						[60.533594582095169, 2.0358832163357916],
						[60.532875192381709, 2.0342388383242289],
						[60.532450200295983, 2.0321332824043856],
						[60.532477998251245, 2.0309972851237488],
						[60.533033365253061, 2.030453016793714],
						[60.533705496269029, 2.0305834842929769],
						[60.534305431636469, 2.0312473127506467],
						[60.53487482020028, 2.0324389857675431],
						[60.534874871453304, 2.0324390350642703],
						[60.534874994949099, 2.0324390870519609],
						[60.534875066205878, 2.0324390881266292],
						[60.535580597906538, 2.0321919127058341],
						[60.536702729874172, 2.0326112866325969],
						[60.536702774395486, 2.0326112934814882],
						[60.536702861409061, 2.0326112856652565],
						[60.536702903901286, 2.032611271000103],
						[60.537483386117906, 2.0321362821236701],
						[60.538188950317107, 2.0318862956084773],
						[60.539341732370694, 2.0317001972447519],
						[60.539341780816521, 2.0317001758023148],
						[60.539341860149555, 2.0317001074383625],
						[60.539341890240415, 2.0317000625184076],
						[60.539744589567761, 2.0304778628118396],
						[60.539744593966276, 2.0304778435661435],
						[60.539744599008856, 2.030477802226891],
						[60.539744598856636, 2.0304777821349171],
						[60.539661299117576, 2.0294971827833508],
						[60.539661296386548, 2.0294971650782214],
						[60.539661287359749, 2.0294971304382465],
						[60.53966128017283, 2.0294971136959901],
						[60.539019579572376, 2.0281555136692662],
						[60.539019573561141, 2.0281555021620035],
						[60.539019558865121, 2.0281554797253203],
						[60.539019551071533, 2.0281554686032632],
						[60.537927963375139, 2.0268998825622937],
						[60.536266877865707, 2.0240749079254567],
						[60.535433488748424, 2.0222082312972849],
						[60.534936296105684, 2.0204082572208897],
						[60.534583496633068, 2.018224968746154],
						[60.534583489860879, 2.0182249427727115],
						[60.534583468391148, 2.0182248943701211],
						[60.534583452802444, 2.018224872133854],
						[60.533719553098727, 2.0171915714780932],
						[60.533719546385512, 2.0171915637849254],
						[60.533719530190588, 2.0171915471685491],
						[60.533719520898941, 2.0171915418626547],
						[60.53266955122411, 2.0163915634675464],
						[60.531764070774429, 2.0149054962364681],
						[60.531764055375341, 2.0149054776207502],
						[60.531764020121415, 2.0149054413538998],
						[60.531764001347831, 2.0149054271269118],
						[60.530527916762047, 2.014185936533281],
						[60.529516830683434, 2.0133081488205784],
						[60.529516823078886, 2.013308141322113],
						[60.529516805386478, 2.0133081305208349],
						[60.529516795999626, 2.0133081234082595],
						[60.528730717772163, 2.0128804358587282],
						[60.527866835982138, 2.0120748542482718],
						[60.527866824717854, 2.0120748457140181],
						[60.527866801298131, 2.0120748288384704],
						[60.527866789142713, 2.0120748204971797],
						[60.527005703181302, 2.011647028285362],
						[60.526180744377093, 2.0110665562512935],
						[60.525502964135519, 2.0101026858714435],
						[60.52550293845772, 2.0101026603436325],
						[60.525502881434832, 2.0101026214880036],
						[60.525502849103511, 2.0101026065450185],
						[60.524158348888683, 2.0097665059584311],
						[60.524158339787043, 2.0097665042722408],
						[60.524158324352307, 2.0097665021289437],
						[60.524158316141794, 2.0097665002497589],
						[60.523450016425052, 2.0097109017453376],
						[60.523450013656529, 2.0097109005162719],
						[60.523450009996829, 2.0097108994801927],
						[60.523450008214546, 2.009710899866179],
						[60.522369538808704, 2.0096692049689704],
						[60.521989089765398, 2.008697136446969],
						[60.52168349331388, 2.0075721487564198],
						[60.521683463725331, 2.007572100309317],
						[60.52168338509874, 2.007572027742405],
						[60.521683335169563, 2.0075720038156302],
						[60.520972235032879, 2.0074442034822702],
						[60.520972225040069, 2.0074442019898853],
						[60.520972204163357, 2.0074441991981273],
						[60.520972194265696, 2.0074441995136425],
						[60.520041693647691, 2.0074720004322133],
						[60.520041636995586, 2.0074720200174903],
						[60.520041545104206, 2.0074720892905491],
						[60.520041509769761, 2.0074721371704904],
						[60.519019213237833, 2.0106027278297862],
						[60.51817763123173, 2.0124442873680906],
						[60.517324852170425, 2.0133776651178215],
						[60.517324848795958, 2.0133776695046617],
						[60.517324841951975, 2.0133776764705824],
						[60.517324838672579, 2.0133776826651411],
						[60.516699838854571, 2.0142276825283254],
						[60.516699834588948, 2.0142276871077835],
						[60.516699827138922, 2.0142276996892838],
						[60.51669982385949, 2.0142277058836315],
						[60.515263741011083, 2.01691927468335],
						[60.51374711721062, 2.0182498068829973],
						[60.512480581944843, 2.0183637016000247],
						[60.512480540250387, 2.0183637142704902],
						[60.512480465130736, 2.0183637597597208],
						[60.512480433392824, 2.0183637903856795],
						[60.512106848658327, 2.0189317637558823],
						[60.512055788050091, 2.0187888328041601],
						[60.512055787955092, 2.018788830996697],
						[60.512055785982817, 2.0187888277671133],
						[60.512055785792839, 2.0187888241522183],
						[60.511750189740987, 2.0180277389100749],
						[60.511439093678881, 2.0168138496237149],
						[60.511439087286789, 2.0168138308984633],
						[60.511439069155671, 2.016813794604194],
						[60.511439058402836, 2.0168137786498685],
						[60.511197141247401, 2.016499861213084],
						[60.511475155612573, 2.0161557267976948],
						[60.51147515809599, 2.0161557226047191],
						[60.511475162361727, 2.0161557180262899],
						[60.51147516404901, 2.0161557158334387],
						[60.512183464230269, 2.0151501152870255],
						[60.512183466618609, 2.0151501092864361],
						[60.512183473367685, 2.015150100514608],
						[60.512183475851046, 2.0151500963214599],
						[60.512233437921516, 2.0150584643592864],
						[60.512469462848664, 2.0149807894101182],
						[60.512469490890311, 2.0149807742043904],
						[60.512469542137666, 2.014980737526928],
						[60.512469564357197, 2.0149807144404965],
						[60.513039056875613, 2.014161225632098],
						[60.513697132036377, 2.0134420711579764],
						[60.514360914251348, 2.0151194747676846],
						[60.514360948007734, 2.0151195168247926],
						[60.51436103337933, 2.0151195806226645],
						[60.514361084804449, 2.0151195987483068],
						[60.515116684930355, 2.0151751985692461],
						[60.515116728786843, 2.0151751927382815],
						[60.515116810012444, 2.0151751605417765],
						[60.515116845694301, 2.0151751363693737],
						[60.515780745628867, 2.0144612352408089],
						[60.515780754160154, 2.0144612260822243],
						[60.51578076765815, 2.014461208536233],
						[60.515780774312084, 2.0144611979556011],
						[60.516300164763955, 2.013541815033796],
						[60.517058452619018, 2.0126445296469848],
						[60.517058469205942, 2.0126445022895654],
						[60.517058492291973, 2.0126444442730951],
						[60.517058498886158, 2.0126444154217533],
						[60.517155798602531, 2.0113139158497],
						[60.517155799338781, 2.0113138955791503],
						[60.517155794668376, 2.0113138581960515],
						[60.517155791044011, 2.0113138406977051],
						[60.516494590997027, 2.0091610408463425],
						[60.516494583807827, 2.0091610241204285],
						[60.516494566755981, 2.0091609912474704],
						[60.516494556988476, 2.0091609769080985],
						[60.515689057335393, 2.008141576238625],
						[60.515689038275866, 2.008141556599222],
						[60.515688995190523, 2.0081415257087176],
						[60.515688971069586, 2.0081415126500017],
						[60.514083386638049, 2.0075331194745978],
						[60.513325103389562, 2.0070887285108694],
						[60.512239004086162, 2.0064248296178664],
						[60.512238982828805, 2.0064248195975041],
						[60.512238938817298, 2.0064248053649818],
						[60.512238916063126, 2.0064248011528409],
						[60.511811115795687, 2.0063915012381117],
						[60.511811110353719, 2.0063915005886934],
						[60.511811100360916, 2.0063914990968463],
						[60.51181109501411, 2.0063915002548081],
						[60.510913895355593, 2.0064137006450276],
						[60.510913880907111, 2.0064137001181446],
						[60.510913853281906, 2.0064137061007328],
						[60.510913839023758, 2.0064137091885157],
						[60.510677766765696, 2.0064887004884575],
						[60.50968064297701, 2.0065053988816124],
						[60.508689008400744, 2.0060387304917056],
						[60.507789074964272, 2.0052915841301826],
						[60.507411292032948, 2.004008244036442],
						[60.507411281789047, 2.0040082206668162],
						[60.507411258022302, 2.0040081801210885],
						[60.507411244404246, 2.0040081611378415],
						[60.506702944352433, 2.0032748601295509],
						[60.506702920040723, 2.0032748434634771],
						[60.506702866165739, 2.0032748130966818],
						[60.506702837779173, 2.0032748046241906],
						[60.505805663864805, 2.0031026098227693],
						[60.505333402817477, 2.0028692279705722],
						[60.504719587329262, 2.0024304875645198],
						[60.504386297550674, 1.9998916734972143],
						[60.50438628880265, 1.9998916443239947],
						[60.504386263286499, 1.9998915877155825],
						[60.504386244831345, 1.9998915624735674],
						[60.503583459303591, 1.9990470767412905],
						[60.503014070314656, 1.9981276944084185],
						[60.503014056791102, 1.998127677237022],
						[60.503014026370082, 1.9981276472805725],
						[60.50301401026843, 1.9981276324954922],
						[60.501644551286063, 1.9972304601132678],
						[60.501075183933487, 1.9962110198133582],
						[60.500319591434909, 1.9939138408173551],
						[60.49989459440804, 1.9924193516684265],
						[60.499375194833995, 1.9900416564702808],
						[60.49937519365681, 1.9900416512437364],
						[60.499375190315931, 1.9900416391773506],
						[60.499375189138732, 1.9900416339507896],
						[60.498950188901993, 1.9888360345533329],
						[60.498950186833632, 1.9888360295202849],
						[60.498950184765263, 1.9888360244872176],
						[60.498950183683405, 1.9888360210673908],
						[60.498383484538415, 1.9875166227123153],
						[60.497814085774301, 1.9861027241979372],
						[60.497814083801238, 1.9861027209717543],
						[60.49781407975977, 1.9861027127128652],
						[60.497814077786749, 1.986102709486695],
						[60.49739187793574, 1.98528050861008],
						[60.497391853845187, 1.9852804791230318],
						[60.497391797134803, 1.9852804293095312],
						[60.497391763719335, 1.9852804109830662],
						[60.496586163918067, 1.9850081106934516],
						[60.496586108797757, 1.9850081080429522],
						[60.496586004634921, 1.9850081324844122],
						[60.49658595657894, 1.9850081611893762],
						[60.495638756680286, 1.9859831603620848],
						[60.495638749042072, 1.9859831693285526],
						[60.495638735547857, 1.9859831868746687],
						[60.495638728800742, 1.985983195647723],
						[60.49502204229092, 1.9869942742658682],
						[60.494310959356163, 1.9877054584649967],
						[60.494310955982534, 1.9877054628511559],
						[60.494310949935617, 1.9877054678174344],
						[60.49431094824881, 1.9877054700105112],
						[60.493697058995949, 1.9884276570446739],
						[60.49317487220857, 1.9888637451661826],
						[60.493174849388652, 1.9888637738677315],
						[60.493174813837086, 1.9888638345630891],
						[60.493174802983006, 1.9888638679765025],
						[60.493080402613167, 1.9894416672203135],
						[60.493080402198906, 1.9894416764450249],
						[60.493080400288534, 1.9894416914751916],
						[60.493080399874273, 1.9894417006999021],
						[60.493080400176353, 1.9899278001826455],
						[60.493080400245042, 1.9907110956994345],
						[60.49298870055916, 1.9924582813852592],
						[60.492747003063265, 1.9943888595806509],
						[60.492230407579271, 1.9961971447824878],
						[60.492230406783428, 1.9961971467818624],
						[60.492230406082854, 1.9961971505875107],
						[60.49223040617813, 1.9961971523937692],
						[60.491852606973097, 1.9977277453574573],
						[60.491424812202929, 1.9990582316771317],
						[60.490666518787044, 2.000947114534033],
						[60.490097025235961, 2.0019777025081851],
						[60.490097012942613, 2.0019777599731365],
						[60.490097022314089, 2.0019778693772556],
						[60.490097043182928, 2.0019779233155619],
						[60.490760928950579, 2.0028140053848107],
						[60.491330398217592, 2.0040361408153369],
						[60.491280400403042, 2.0054971894086759],
						[60.491183101408673, 2.0068582862129491],
						[60.491183099911794, 2.0068582920173177],
						[60.491183099781708, 2.006858306660062],
						[60.49118310006709, 2.0068583120787977],
						[60.49128040009883, 2.0084306121315798],
						[60.491280401750863, 2.0084306263888321],
						[60.491280408714573, 2.0084306559384726],
						[60.491280413039995, 2.0084306696173839],
						[60.491658113298655, 2.009436170661874],
						[60.491658118420048, 2.009436182342053],
						[60.491658131336251, 2.0094362051241701],
						[60.491658138239927, 2.0094362164188451],
						[60.492180437789798, 2.0101612172491961],
						[60.492180491057333, 2.0101612532272584],
						[60.492180609247981, 2.0101612897787469],
						[60.492180673980933, 2.0101612867395562],
						[60.492794474103668, 2.0099168868016934],
						[60.492794488967832, 2.0099168781059351],
						[60.492794517805059, 2.0099168609071829],
						[60.492794531683018, 2.0099168505978313],
						[60.493408381807413, 2.009377993544327],
						[60.494022153474752, 2.0093280047666728],
						[60.494591551730032, 2.0096640448812457],
						[60.495108125913511, 2.0105806987090218],
						[60.495108131830897, 2.0105807083913736],
						[60.495108145352859, 2.0105807255641168],
						[60.495108152066301, 2.0105807332472532],
						[60.49586925230409, 2.0114195330374081],
						[60.495869254276535, 2.0114195362649974],
						[60.495869259112553, 2.0114195425274093],
						[60.495869260989934, 2.0114195439484788],
						[60.49606718814028, 2.0116099565690786],
						[60.495613776129318, 2.0122220315995989],
						[60.494788852785859, 2.0125081043412263],
						[60.493819515768557, 2.0126386839253119],
						[60.49359188015648, 2.0121749127326103],
						[60.49359181059917, 2.0121748583488062],
						[60.493591657464016, 2.0121748256909098],
						[60.493591572008803, 2.0121748459957955],
						[60.492699930377164, 2.0129191983170132],
						[60.492247274757652, 2.0129081017419361],
						[60.491872336909175, 2.0125942531726375],
						[60.491269544086947, 2.0119692618379319],
						[60.491269525218307, 2.0119692458226046],
						[60.491269483310454, 2.0119692201741373],
						[60.491269460176198, 2.0119692087347514],
						[60.490741885127804, 2.0118026485741867],
						[60.490661299068719, 2.0110360832597434],
						[60.490578000138797, 2.0098110861055862],
						[60.490577989135375, 2.009811048296096],
						[60.490577952965282, 2.0098109775670787],
						[60.490577927893746, 2.0098109464537757],
						[60.489822327876013, 2.0091804454736453],
						[60.489822323230086, 2.0091804428250311],
						[60.489822312952022, 2.0091804359143528],
						[60.489822307319869, 2.0091804316522919],
						[60.489072332056331, 2.0087026470691685],
						[60.488391850717576, 2.0079192690280498],
						[60.488391843017759, 2.0079192597336588],
						[60.48839182503982, 2.0079192435292694],
						[60.488391814761691, 2.0079192366192689],
						[60.487939014898579, 2.0075998361216421],
						[60.487939009266398, 2.0075998318600803],
						[60.487938995328577, 2.0075998239153066],
						[60.487938988900368, 2.0075998216525988],
						[60.486961245400778, 2.0071165476367598],
						[60.486355776898272, 2.0060277077281006],
						[60.485591880085607, 2.0044721116345636],
						[60.485591856890387, 2.0044720819502313],
						[60.485591801969193, 2.0044720317336915],
						[60.485591769352091, 2.0044720113943066],
						[60.485141769308321, 2.0043054127587228],
						[60.485141728350811, 2.0043054051835751],
						[60.485141645795473, 2.0043054120896868],
						[60.485141605884728, 2.0043054243793716],
						[60.484322105707811, 2.0047442238752207],
						[60.48432208231322, 2.004744241722423],
						[60.484322041156318, 2.0047442816774517],
						[60.484322024380283, 2.0047443053982996],
						[60.483585942085966, 2.006108171719577],
						[60.482691575870071, 2.0068526436136982],
						[60.482097091559304, 2.0072998310771886],
						[60.481277706165592, 2.0077387244090592],
						[60.481277698240426, 2.0077387279492274],
						[60.481277681689164, 2.0077387388336332],
						[60.48127767475026, 2.0077387439867729],
						[60.480533177019687, 2.0083359424249561],
						[60.479938779327469, 2.0087859413010127],
						[60.479938774075727, 2.0087859442627178],
						[60.479938762871214, 2.0087859539900266],
						[60.479938757714542, 2.0087859587573571],
						[60.479491558035249, 2.0092359590742426],
						[60.479491543646489, 2.0092359767943093],
						[60.479491519609944, 2.0092360166879604],
						[60.479491509867088, 2.0092360370559241],
						[60.478980410151166, 2.0107638368447041],
						[60.478980405850002, 2.0107638578617704],
						[60.478980400907339, 2.0107639009310083],
						[60.478980401156988, 2.0107639227905638],
						[60.479305400619538, 2.0137000217928693],
						[60.479305405229439, 2.0137000408844057],
						[60.479305417122724, 2.0137000784898498],
						[60.479305425297262, 2.0137000968112235],
						[60.479910901314433, 2.0147889522973736],
						[60.47991919952257, 2.0155610853732604],
						[60.479849818399153, 2.0160220671735449],
						[60.479549912213237, 2.0161692201751107],
						[60.479549872549875, 2.016169254307719],
						[60.479549816547049, 2.0161693376230208],
						[60.479549800207572, 2.0161693868057213],
						[60.47941920026863, 2.0181693868299546],
						[60.479419199567381, 2.0181693906336329],
						[60.479419199947259, 2.0181693978561657],
						[60.479419200137201, 2.0181694014674187],
						[60.479427599637788, 2.0192471646749213],
						[60.479133113222758, 2.0200138286274618],
						[60.479133108123897, 2.0200138516414814],
						[60.479133101395888, 2.020013895094444],
						[60.479133100847804, 2.0200139189522774],
						[60.479380400910834, 2.0226361193461693],
						[60.479380402086711, 2.0226361245708238],
						[60.47938040265614, 2.0226361354047042],
						[60.479380403926939, 2.0226361424349988],
						[60.479613703891637, 2.0237194416600506],
						[60.479613709201523, 2.0237194569497006],
						[60.479613720712472, 2.0237194873367121],
						[60.479613726818634, 2.0237195006284132],
						[60.480069227063652, 2.024497300336499],
						[60.480069248282469, 2.0244973268037882],
						[60.480069294606672, 2.0244973679429741],
						[60.480069323371644, 2.0244973836515636],
						[60.480447052665291, 2.0246557547871791],
						[60.480746999748959, 2.0252750471659211],
						[60.480760900121155, 2.0266611013601294],
						[60.480769199863744, 2.0275861015695811],
						[60.480777600366252, 2.0285111014958166],
						[60.480777599664712, 2.0285111052994504],
						[60.480777599949199, 2.0285111107167011],
						[60.480777600138843, 2.0285111143281891],
						[60.480860889351604, 2.0297415404456189],
						[60.479519380125119, 2.0300331003039909],
						[60.478619401785188, 2.0300220002288762],
						[60.478019402425296, 2.0300164997939296],
						[60.478019345673886, 2.0300165175039324],
						[60.478019251606476, 2.0300165797774987],
						[60.478019215181639, 2.0300166241489945],
						[60.477580433132033, 2.0310887813148701],
						[60.47706101374699, 2.0315470092160721],
						[60.475941675440275, 2.0316887027165356],
						[60.475941628586412, 2.0316887201155089],
						[60.47594155215225, 2.0316887749285102],
						[60.475941519898385, 2.0316888129185777],
						[60.475719219999625, 2.0321499130541123],
						[60.475719215922766, 2.0321499212360439],
						[60.47571920955145, 2.0321499372159315],
						[60.475719207351744, 2.0321499468193496],
						[60.475502615606246, 2.0329193163516392],
						[60.474913730776805, 2.0338415914853094],
						[60.474913721124764, 2.0338416136485922],
						[60.474913706371353, 2.0338416588208434],
						[60.474913701364748, 2.0338416836352433],
						[60.474847000583694, 2.0346138763260382],
						[60.474705403111408, 2.0355388624724267],
						[60.474560904939324, 2.0361555545710117],
						[60.474560904237542, 2.0361555583737583],
						[60.474560902928758, 2.036155567784685],
						[60.474560902321713, 2.0361555733928718],
						[60.474347001905791, 2.0376999733489654],
						[60.474347001165079, 2.0376999935923226],
					],
					[
						[60.514310206796566, 2.0322648116847342],
						[60.51431672163686, 2.0322640987298324],
						[60.514316771960296, 2.0322640787304027],
						[60.514316855048968, 2.0322640132709537],
						[60.514316887908969, 2.0322639696185725],
						[60.514338812766233, 2.0322047652471462],
						[60.515688734057768, 2.0342223115083242],
						[60.515688737015687, 2.0342223163546818],
						[60.515688742836723, 2.0342223242396789],
						[60.515688746685818, 2.0342223288937609],
						[60.516253394602224, 2.0348948536862346],
						[60.516113986887824, 2.0348470184407241],
						[60.515122360675335, 2.0342165669095498],
						[60.514366885045952, 2.0323527258964611],
						[60.514366880912377, 2.0323527158198851],
						[60.514366872834827, 2.0323526992819914],
						[60.514366867904876, 2.0323526912053418],
						[60.514310206796566, 2.0322648116847342],
					],
				],
			],
		},
		{
			fldNpdidField: "5774394",
			wlbNpdidWellbore: "4173",
			fldName: "GOLIAT",
			fldCurrentActivitySatus: "Producing",
			wlbName: "7122/7-1",
			fldDiscoveryYear: "2000",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=5774394",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=5774394",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "229",
			cmpLongName: "Vår Energi ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=4173",
			fldHcType: "OIL",
			fldMainArea: "Barents sea",
			cmpNpdidCompany: "50000032",
			fldNpdidOwner: "22644",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Hammerfest",
			fldAreaGeometryWKT: [
				[
					[
						[71.241458100974071, 22.186486106244978],
						[71.241458100685051, 22.186486123122055],
						[71.241949800698961, 22.190513921082715],
						[71.242299801337026, 22.193991721115903],
						[71.242299801337026, 22.193991721115903],
						[71.242299801822398, 22.193991727143224],
						[71.242299801628619, 22.193991729855792],
						[71.242683102194846, 22.196644429964447],
						[71.242683104911137, 22.19664444322401],
						[71.242683113156133, 22.196644468837423],
						[71.242683116939077, 22.196644479986645],
						[71.243574817315732, 22.198647281969226],
						[71.243574818867629, 22.198647285886743],
						[71.243574822165243, 22.198647291009074],
						[71.24357482371714, 22.19864729492658],
						[71.244477602787356, 22.200322256884675],
						[71.244613699243189, 22.202380597255775],
						[71.244366501290074, 22.204633278712802],
						[71.244366500514374, 22.204633289563805],
						[71.244366500029756, 22.204633309155763],
						[71.24436650099976, 22.204633321212174],
						[71.244522001277048, 22.206194421371812],
						[71.244522001277048, 22.206194421371812],
						[71.244797000068644, 22.208808307524286],
						[71.244677599634016, 22.211944384906822],
						[71.244349802071014, 22.21483326997059],
						[71.243752604115102, 22.217788860268698],
						[71.243752602659939, 22.217788867802977],
						[71.243752601301154, 22.217788886790593],
						[71.243752599651913, 22.217788897037416],
						[71.243702600334231, 22.220449996625931],
						[71.243702599945919, 22.220450002050907],
						[71.243702600720738, 22.220450016820099],
						[71.2437026012052, 22.220450022848429],
						[71.243905401236759, 22.222197222677035],
						[71.243905401042582, 22.222197225389547],
						[71.243905401915356, 22.222197225993011],
						[71.243905401915356, 22.222197225993011],
						[71.244319202395943, 22.225363924935941],
						[71.244319206467239, 22.2253639448328],
						[71.244319219362126, 22.225363982219669],
						[71.244319227991539, 22.225364002422189],
						[71.244824810712004, 22.226222271655477],
						[71.245233103636622, 22.228216737861406],
						[71.245994204042432, 22.232180637096931],
						[71.245994203848113, 22.232180639809595],
						[71.245994205204966, 22.232180646442917],
						[71.245994205883363, 22.23218064975956],
						[71.246741500454917, 22.235138926919699],
						[71.246808099909899, 22.239011089255538],
						[71.246658102285167, 22.240127771993865],
						[71.246658100829123, 22.240127779527487],
						[71.246658099856916, 22.24012779309086],
						[71.246658100146334, 22.240127801833278],
						[71.246666500361385, 22.241302799834486],
						[71.246666499778016, 22.241302807972485],
						[71.246666502296591, 22.241302823953507],
						[71.246666502585967, 22.241302832695919],
						[71.246927603412004, 22.24290563099953],
						[71.246927603217515, 22.242905633712194],
						[71.246927603023053, 22.242905636424908],
						[71.246927603023053, 22.242905636424908],
						[71.24790260295174, 22.248291731940146],
						[71.248019234662578, 22.249070294819891],
						[71.247685911797703, 22.250005533660289],
						[71.247685909274011, 22.250005543301423],
						[71.247685904226586, 22.250005562583638],
						[71.247685901702894, 22.250005572224772],
						[71.247333102099745, 22.252472172528282],
						[71.247333100837821, 22.252472177348562],
						[71.24733310005935, 22.2524721881991],
						[71.247333099864733, 22.252472190911718],
						[71.247216499760555, 22.255466690587884],
						[71.247088699848362, 22.258327787511771],
						[71.246910900713786, 22.260558279043867],
						[71.24674980882213, 22.261866616689407],
						[71.246385987998337, 22.262291523566198],
						[71.246230658260018, 22.262333078023563],
						[71.245950167671012, 22.262060984898341],
						[71.24569189340157, 22.261463840184284],
						[71.245561297043878, 22.260755564921524],
						[71.245561295010134, 22.26075555496887],
						[71.245561291425872, 22.260755541093417],
						[71.245561289197397, 22.260755533853043],
						[71.245375188787889, 22.260216635656548],
						[71.245375184136165, 22.260216623888354],
						[71.245375173087467, 22.260216599141501],
						[71.245375165623102, 22.260216588269877],
						[71.245025165645785, 22.259691589826673],
						[71.245025069841773, 22.259691546163431],
						[71.24502489323487, 22.259691568894798],
						[71.245024810686644, 22.25969163407904],
						[71.244947010910181, 22.259916633543352],
						[71.244947008191289, 22.25991664589403],
						[71.24494700294818, 22.259916667883171],
						[71.244947001296623, 22.259916678126814],
						[71.244833100961586, 22.260969378768763],
						[71.244833101055249, 22.260969390222755],
						[71.244833101047917, 22.260969415842922],
						[71.244833102014283, 22.260969427902186],
						[71.24521369950881, 22.263780613821698],
						[71.245205399735681, 22.266566700033174],
						[71.245205399735681, 22.266566700033174],
						[71.24520540021868, 22.266566706063038],
						[71.24520540021868, 22.266566706063038],
						[71.245324799773883, 22.27002220253274],
						[71.245355400332542, 22.273383293718698],
						[71.245299801220256, 22.274055576822068],
						[71.245210944198988, 22.274663582870101],
						[71.244847313660159, 22.274460933585292],
						[71.244527955333453, 22.274191568726838],
						[71.244094581728916, 22.273461012727498],
						[71.243575187870789, 22.272036031007371],
						[71.2435751869982, 22.272036030401679],
						[71.24357518632047, 22.272036027084088],
						[71.24357518632047, 22.272036027084088],
						[71.243077985796063, 22.270794325722434],
						[71.243077973486422, 22.270794305790726],
						[71.243077946054413, 22.270794266822257],
						[71.243077930059457, 22.270794247179879],
						[71.242633429904217, 22.270413747915736],
						[71.242633345345325, 22.270413726243067],
						[71.242633191137898, 22.270413755879908],
						[71.242633121294432, 22.270413809901143],
						[71.242358120811275, 22.270966610107248],
						[71.242358113821709, 22.270966630879169],
						[71.242358102850147, 22.270966668816399],
						[71.242358100418514, 22.270966689904654],
						[71.242308099949241, 22.27195278981894],
						[71.242308099754354, 22.271952792530659],
						[71.242308100042266, 22.271952801271446],
						[71.242308099652476, 22.271952806694863],
						[71.242424800221286, 22.275602807178419],
						[71.242424800026342, 22.275602809890103],
						[71.242424800509056, 22.275602815919296],
						[71.242424801381631, 22.275602816525105],
						[71.242935901007456, 22.281497217763647],
						[71.242935901007456, 22.281497217763647],
						[71.242935900812412, 22.281497220475352],
						[71.242935900812412, 22.281497220475352],
						[71.243194200597529, 22.283791715385686],
						[71.24336649954769, 22.287199997731307],
						[71.243180400880618, 22.289436074535992],
						[71.243072002010038, 22.290116669046501],
						[71.243072002010038, 22.290116669046501],
						[71.243072001619737, 22.290116674469758],
						[71.243072001619737, 22.290116674469758],
						[71.242894201756101, 22.291547173794367],
						[71.242894200780228, 22.291547187352243],
						[71.242894200768717, 22.29154721296954],
						[71.24289420173308, 22.291547225028953],
						[71.243074801627728, 22.292902825419347],
						[71.243074802305088, 22.292902828737567],
						[71.243074802787234, 22.292902834767368],
						[71.243074802787234, 22.292902834767368],
						[71.243508100834958, 22.295261123962522],
						[71.243605399769066, 22.297119410920669],
						[71.243605399769066, 22.297119410920669],
						[71.243605401318845, 22.297119414845923],
						[71.243605401123574, 22.297119417557521],
						[71.243783101461432, 22.299152816228347],
						[71.243783101748065, 22.299152824970093],
						[71.243783102711916, 22.299152837030388],
						[71.243783104938885, 22.299152844274378],
						[71.244574802698665, 22.302758336196923],
						[71.245074802100021, 22.306538923654212],
						[71.245777600587857, 22.312594420947288],
						[71.246163700953403, 22.316800017880205],
						[71.246163700757862, 22.316800020591952],
						[71.246163700562306, 22.316800023303689],
						[71.246163701434767, 22.316800023911497],
						[71.246763700834677, 22.321747220883548],
						[71.247177601179203, 22.326558318177302],
						[71.247177601179203, 22.326558318177302],
						[71.247177600787822, 22.32655832360085],
						[71.247177601660212, 22.32655832420911],
						[71.247602601739842, 22.329916724241059],
						[71.247602601544102, 22.329916726952849],
						[71.247602602416521, 22.329916727561294],
						[71.247602602416521, 22.329916727561294],
						[71.24845540232154, 22.335969429134259],
						[71.24845540232154, 22.335969429134259],
						[71.24845540232154, 22.335969429134259],
						[71.249163702393119, 22.340700028919944],
						[71.249163703069598, 22.340700032240814],
						[71.249163702873702, 22.340700034952732],
						[71.249163703550153, 22.340700038273589],
						[71.25001650236355, 22.345158330222219],
						[71.25047760131352, 22.349855620172466],
						[71.250477603146393, 22.349855632848673],
						[71.25047761097774, 22.34985566396016],
						[71.250477614555251, 22.349855677855203],
						[71.251408105140101, 22.352102853155166],
						[71.251580400323888, 22.353452813890122],
						[71.251599800309336, 22.355255603522551],
						[71.251599800113198, 22.355255606234611],
						[71.251599799721006, 22.355255611658787],
						[71.251599801269506, 22.355255615590263],
						[71.251794200612807, 22.35777501515771],
						[71.251794201092835, 22.357775021191681],
						[71.25179420244514, 22.357775027835473],
						[71.251794203121321, 22.357775031157409],
						[71.252119202646455, 22.359791730776159],
						[71.252119203322565, 22.359791734098181],
						[71.252119203322565, 22.359791734098181],
						[71.252119203126412, 22.359791736810276],
						[71.252619202788793, 22.362477838176485],
						[71.25261920501319, 22.362477845430938],
						[71.252619210138093, 22.362477863262086],
						[71.25261921323478, 22.362477871126611],
						[71.252922013332849, 22.363263973348314],
						[71.252922026504407, 22.36326399395881],
						[71.252922056336558, 22.363264037620226],
						[71.252922074261946, 22.363264055856934],
						[71.253527674209025, 22.363755753979103],
						[71.253527687293143, 22.36375576313144],
						[71.253527712589118, 22.363755780825965],
						[71.253527726937975, 22.363755785163981],
						[71.254188821112919, 22.364014082812364],
						[71.254861014539983, 22.364330781534203],
						[71.254861046923011, 22.364330789940301],
						[71.254861113346223, 22.364330796512991],
						[71.254861146514202, 22.364330794069375],
						[71.255705645951849, 22.364130795834242],
						[71.255705652646469, 22.364130791968361],
						[71.255705667584053, 22.364130788169618],
						[71.255705675150921, 22.364130784913936],
						[71.256102875138069, 22.363969586107611],
						[71.256102891340376, 22.363969577493709],
						[71.256102920255898, 22.363969557824944],
						[71.256102933645124, 22.363969550092882],
						[71.256600133753423, 22.363522350244498],
						[71.2566001479276, 22.363522331661287],
						[71.256600173266634, 22.363522298089382],
						[71.256600183951662, 22.363522277065105],
						[71.257177979129708, 22.362166790426357],
						[71.258600168707758, 22.35970290552704],
						[71.259771092269006, 22.357991701845116],
						[71.260827692965577, 22.358664069116212],
						[71.260827789744198, 22.358664074102514],
						[71.260827942737819, 22.358663998689337],
						[71.260827998756639, 22.358663921003149],
						[71.261175198971443, 22.355277818778795],
						[71.261175199167567, 22.355277816065367],
						[71.261175199755897, 22.355277807925145],
						[71.261175199952021, 22.355277805211745],
						[71.261198570244261, 22.354428989274833],
						[71.261547317920673, 22.354005771457899],
						[71.262227816026567, 22.353778005212526],
						[71.263572151669223, 22.354000195506146],
						[71.264430514071677, 22.354280782240181],
						[71.265010962973335, 22.354650149366627],
						[71.266247035782925, 22.356247313629403],
						[71.267072029855413, 22.357566805194732],
						[71.267072114380426, 22.357566852931729],
						[71.267072281856798, 22.357566856057947],
						[71.267072366748906, 22.357566809953671],
						[71.267252967300522, 22.357291809609205],
						[71.26725297477968, 22.357291794881977],
						[71.267252987797235, 22.357291766921097],
						[71.267252992659479, 22.357291750362837],
						[71.267505792725359, 22.35632505072461],
						[71.267505795254507, 22.356325041088102],
						[71.267505799440528, 22.356325021204807],
						[71.267505800028943, 22.356325013061952],
						[71.267700199887344, 22.35313891244666],
						[71.267700200083453, 22.35313890973233],
						[71.267700200279535, 22.353138907017961],
						[71.26770020047563, 22.35313890430362],
						[71.267779521687416, 22.349626038613991],
						[71.268385928649323, 22.349975131244292],
						[71.268902607127742, 22.351850054341593],
						[71.268902629983984, 22.351850093142268],
						[71.268902691026085, 22.351850161504544],
						[71.268902728535664, 22.351850187741405],
						[71.270230529299766, 22.35235298682521],
						[71.270230581178595, 22.352352991746486],
						[71.270230681271102, 22.352352976210053],
						[71.270230728808514, 22.352352952427381],
						[71.272014029470625, 22.350852952336524],
						[71.272014038301833, 22.350852944253681],
						[71.272014056160302, 22.350852925373022],
						[71.272014065383615, 22.350852911860198],
						[71.27384184844415, 22.348194536070245],
						[71.275397325076398, 22.346941854627772],
						[71.275397332839106, 22.346941848647138],
						[71.275397347688212, 22.34694183336023],
						[71.275397354578601, 22.346941826769438],
						[71.277491854574208, 22.344386227836967],
						[71.277491858651501, 22.344386222130019],
						[71.277491865933783, 22.344386210106052],
						[71.277491870011076, 22.3443862043991],
						[71.278755770476934, 22.342350104065691],
						[71.278755776886754, 22.342350091430124],
						[71.278755790098231, 22.342350060726922],
						[71.278755794959281, 22.342350044155172],
						[71.279077994791294, 22.340963945215002],
						[71.279077996839234, 22.340963929528613],
						[71.279077998798556, 22.340963902367935],
						[71.279077999974135, 22.340963886071489],
						[71.278977999994638, 22.339416686130022],
						[71.278977996612383, 22.339416669499919],
						[71.278977988975512, 22.339416635629675],
						[71.278977983652624, 22.339416620495694],
						[71.278633484116313, 22.338613821912226],
						[71.27863347443153, 22.338613803729224],
						[71.2786334520531, 22.338613770965534],
						[71.278633439359453, 22.338613756384866],
						[71.278061238769155, 22.33806375635751],
						[71.27806122936903, 22.338063746933081],
						[71.278061208432248, 22.338063732296398],
						[71.278061197091475, 22.338063724368169],
						[71.27711403167541, 22.337535944890519],
						[71.275983480300155, 22.336099906342874],
						[71.275929185020956, 22.335924388725953],
						[71.276114074163729, 22.335577899817629],
						[71.27716687071424, 22.333855703510629],
						[71.277166874987046, 22.333855695085663],
						[71.277166883532672, 22.333855678235714],
						[71.277166888001332, 22.333855667094856],
						[71.277614088105196, 22.332625068191369],
						[71.277614090241585, 22.332625063978664],
						[71.277614092769582, 22.33262505433402],
						[71.277614094033609, 22.332625049511726],
						[71.277952952683293, 22.331288033457049],
						[71.278113954786619, 22.331208495694309],
						[71.279897217767498, 22.331047397725925],
						[71.279897245701278, 22.331047391567587],
						[71.27989729934329, 22.331047371989474],
						[71.279897324179132, 22.331047357960109],
						[71.28234182392076, 22.329125155545999],
						[71.282341833623562, 22.329125148058811],
						[71.282341854293037, 22.329125128260692],
						[71.282341863319189, 22.32912511744723],
						[71.284222353202495, 22.326483431247805],
						[71.285455630549777, 22.325272450786262],
						[71.286297099762308, 22.325761271878921],
						[71.286297137186367, 22.325761286613005],
						[71.286297213600207, 22.325761294343177],
						[71.286297253943388, 22.325761293992947],
						[71.287061143854288, 22.32554739411157],
						[71.287980633021149, 22.32539189692293],
						[71.287980640391851, 22.325391896365062],
						[71.287980654456575, 22.325391891922198],
						[71.287980660954872, 22.325391890754705],
						[71.289891750157182, 22.324775193505818],
						[71.291355493154427, 22.324491924382752],
						[71.292710922814891, 22.327019492033042],
						[71.294810922163251, 22.331172289041419],
						[71.294810922839829, 22.331172292369928],
						[71.294810926133565, 22.331172297528845],
						[71.294810926810158, 22.331172300857379],
						[71.29667762435821, 22.334397296223425],
						[71.298177619221192, 22.337316785073099],
						[71.299313714872966, 22.339930676522332],
						[71.300391505201347, 22.342691748713673],
						[71.300805401523533, 22.345869426555101],
						[71.300805402876165, 22.345869433215228],
						[71.300805404709067, 22.345869445924489],
						[71.300805406934003, 22.345869453195611],
						[71.301416507179212, 22.348116752712038],
						[71.301416510080358, 22.348116763313897],
						[71.301416518019366, 22.34811678030167],
						[71.301416521792831, 22.348116791514695],
						[71.302266518812061, 22.349783384577751],
						[71.303158116963019, 22.351802880971373],
						[71.303158117835324, 22.351802881582728],
						[71.303158119383838, 22.351802885524751],
						[71.303158120256157, 22.351802886136117],
						[71.304052612378712, 22.353655668847654],
						[71.304852606903353, 22.35664445177013],
						[71.304852612224664, 22.356644466929549],
						[71.304852624808106, 22.356644495752263],
						[71.304852632070208, 22.356644509415485],
						[71.305716516869182, 22.357983383532748],
						[71.306285907971457, 22.359880657064007],
						[71.306285908647524, 22.359880660395511],
						[71.306285911940421, 22.359880665562503],
						[71.30628591348875, 22.359880669505817],
						[71.30716371267664, 22.362230670838265],
						[71.307163714421193, 22.3622306720622],
						[71.307163716645491, 22.36223067933765],
						[71.307163718193763, 22.362230683281346],
						[71.308752618025224, 22.365688982184444],
						[71.308752620445674, 22.365688986740913],
						[71.308752624414353, 22.365688995241722],
						[71.308752625962569, 22.36568899918603],
						[71.309608126126221, 22.367191797906035],
						[71.309608160231136, 22.36719183327731],
						[71.309608239126945, 22.367191882942183],
						[71.309608285662392, 22.36719189846032],
						[71.310144386314235, 22.367230799192956],
						[71.310144458779305, 22.367230772896537],
						[71.310144567184111, 22.367230680374263],
						[71.310144599831148, 22.367230608979078],
						[71.310236299703803, 22.364913908795849],
						[71.31023630009642, 22.364913903355511],
						[71.310236300205702, 22.364913889142517],
						[71.310236298853795, 22.364913882477772],
						[71.310089100798933, 22.363177804122007],
						[71.310241897861602, 22.362061127105235],
						[71.310241898057868, 22.36206112438504],
						[71.310241899322705, 22.36206111955671],
						[71.310241898646666, 22.362061116224435],
						[71.310339087913547, 22.360922348168021],
						[71.311266695706408, 22.360605812525144],
						[71.3116915622535, 22.360766862133723],
						[71.31229702593474, 22.361836198139944],
						[71.312297062657564, 22.361836235343528],
						[71.312297150670233, 22.361836285674695],
						[71.312297200891592, 22.361836300910614],
						[71.312727800895516, 22.361833499539976],
						[71.312727835132435, 22.361833494983923],
						[71.312727898285388, 22.361833470704539],
						[71.312727927005184, 22.361833453701831],
						[71.313283337069493, 22.361375226701163],
						[71.314186028667507, 22.361719587126437],
						[71.314186118185049, 22.361719578490245],
						[71.314186253737262, 22.36171949068115],
						[71.314186298703405, 22.361719413616793],
						[71.314302989667055, 22.360083440374709],
						[71.314522270656781, 22.360000186748863],
						[71.314522313158349, 22.360000156545212],
						[71.31452237655067, 22.360000078113913],
						[71.314522398313727, 22.360000030498416],
						[71.314666895682194, 22.359047238388246],
						[71.31492516003992, 22.357969590090242],
						[71.315302661000501, 22.357944609860485],
						[71.315641508326962, 22.358913958356077],
						[71.315827606091318, 22.359658348397087],
						[71.315827653849041, 22.359658410500128],
						[71.315827779763055, 22.359658481712579],
						[71.315827857919331, 22.359658490821996],
						[71.316319457605346, 22.359508491461558],
						[71.316319500782953, 22.359508464587591],
						[71.3163195690164, 22.359508395262971],
						[71.316319593199964, 22.359508352200184],
						[71.316761293098622, 22.357830651673353],
						[71.316761294755793, 22.357830641400835],
						[71.316761297677658, 22.357830626298099],
						[71.316761299138619, 22.357830618746753],
						[71.316947398724196, 22.35588332000539],
						[71.316947399225199, 22.355883300344914],
						[71.316947396345697, 22.355883264018285],
						[71.316947392289052, 22.355883244018887],
						[71.316519592134341, 22.354377745528399],
						[71.316519590782065, 22.35437773886219],
						[71.316519586812888, 22.354377730360149],
						[71.316519586136735, 22.354377727027014],
						[71.315883493003838, 22.352788845170796],
						[71.315216896790687, 22.349174963578569],
						[71.31460299862583, 22.345583280029651],
						[71.314527999722856, 22.343369392178051],
						[71.3145279992425, 22.343369386124621],
						[71.314527996733176, 22.343369370074068],
						[71.314527995576498, 22.3433693606883],
						[71.314169595667607, 22.341552760345898],
						[71.314169595863575, 22.341552757624918],
						[71.314169595187195, 22.34155275429266],
						[71.314169593638482, 22.341552750349116],
						[71.313561294379909, 22.339138851705748],
						[71.313561292154716, 22.339138844430416],
						[71.31356128683197, 22.339138829268666],
						[71.313561283734444, 22.3391388213822],
						[71.312525183624487, 22.336730521645094],
						[71.312525182752154, 22.336730521034102],
						[71.31252518294805, 22.336730518313303],
						[71.310744593740807, 22.332691642740073],
						[71.310530798104352, 22.331269370014621],
						[71.310530795878833, 22.331269362741367],
						[71.310530793172589, 22.33126934941625],
						[71.31053079094707, 22.331269342142999],
						[71.309719592746774, 22.328608248611321],
						[71.308894595317483, 22.324936055085914],
						[71.308894590670221, 22.324936043262507],
						[71.30889458331626, 22.324936018116023],
						[71.30889457672842, 22.324936007792353],
						[71.307691877426748, 22.322633205882052],
						[71.307691874132786, 22.322633200720947],
						[71.30769186580001, 22.322633189178312],
						[71.3076918616336, 22.322633183407003],
						[71.305700161649312, 22.319860982721899],
						[71.305700160776865, 22.31986098211188],
						[71.305700158355279, 22.319860977561831],
						[71.305700156610428, 22.319860976341811],
						[71.30400576221399, 22.317708182742212],
						[71.302172365905477, 22.314999887672684],
						[71.302172364160597, 22.314999886453343],
						[71.302172361738826, 22.314999881904736],
						[71.302172359993932, 22.314999880685399],
						[71.299716875371985, 22.311719298860712],
						[71.29887798441824, 22.30972212231466],
						[71.29887798112388, 22.309722117158202],
						[71.298877974730672, 22.309722104126092],
						[71.298877970759349, 22.309722095641117],
						[71.297564071382666, 22.307547096532538],
						[71.295744572075094, 22.304508196385733],
						[71.295744571202604, 22.304508195776755],
						[71.295744571202604, 22.304508195776755],
						[71.295744570330143, 22.304508195167799],
						[71.293464069943965, 22.300794295999122],
						[71.293464069266818, 22.300794292671824],
						[71.291639068918968, 22.297922092221434],
						[71.291639067369275, 22.297922088286036],
						[71.291639062329551, 22.297922081916543],
						[71.291639059712011, 22.297922080090927],
						[71.290050193132586, 22.295813822452423],
						[71.289850204754373, 22.294058332144466],
						[71.290377973716446, 22.292916799275506],
						[71.291405718325734, 22.291458480246856],
						[71.292544407824622, 22.291219601736678],
						[71.293883235929869, 22.291389092831857],
						[71.295727663629307, 22.292422352657596],
						[71.297058137573742, 22.294264016905483],
						[71.297058165103863, 22.294264041817556],
						[71.297058227248158, 22.294264082306103],
						[71.29705826205759, 22.294264095163452],
						[71.297966655241453, 22.29444189559776],
						[71.299458193736854, 22.294836279435781],
						[71.300699847178691, 22.296144529461642],
						[71.301774841482612, 22.297558420150921],
						[71.301774847394867, 22.29755842713276],
						[71.301774859219279, 22.297558441096392],
						[71.301774866004067, 22.297558448687141],
						[71.303699851202779, 22.299294534818998],
						[71.305747038731013, 22.302083417450667],
						[71.305747045515545, 22.302083425044607],
						[71.305747058889224, 22.302083442952764],
						[71.305747066741674, 22.302083448435695],
						[71.307047056008983, 22.30324733985606],
						[71.3078692190917, 22.304230694304241],
						[71.308388707258189, 22.306105652928657],
						[71.308388719849574, 22.306105681724411],
						[71.308388752989543, 22.306105730588985],
						[71.308388774215118, 22.30610575398789],
						[71.309538761030467, 22.307044545381316],
						[71.311344250809256, 22.309072332192748],
						[71.311344255848539, 22.309072338572033],
						[71.311344266122646, 22.309072348609678],
						[71.311344271357427, 22.309072352268014],
						[71.312438737020642, 22.30999182323017],
						[71.314472016867242, 22.314458380735598],
						[71.316299816092624, 22.318677878123118],
						[71.316299820740284, 22.318677889949736],
						[71.316299831494931, 22.318677906048638],
						[71.316299837210622, 22.318677915763999],
						[71.317116529780776, 22.3198279064435],
						[71.318172025233565, 22.321741797731431],
						[71.318172129744752, 22.32174184800029],
						[71.318172317324112, 22.321741813465838],
						[71.318172397579318, 22.321741729552656],
						[71.319425197704348, 22.313144428709659],
						[71.319425197899932, 22.31314442598763],
						[71.319425199163547, 22.313144421153762],
						[71.319425199163547, 22.313144421153762],
						[71.320041898882778, 22.306991718817262],
						[71.320041892894878, 22.306991674607215],
						[71.320041858806746, 22.306991587881836],
						[71.320041833128485, 22.306991549918504],
						[71.316397334011654, 22.303763750965942],
						[71.314836235291281, 22.302333152929236],
						[71.314836232673798, 22.302333151100608],
						[71.314836227634288, 22.302333144721846],
						[71.314836225889309, 22.302333143502761],
						[71.313269567927819, 22.301069278359705],
						[71.312391887152089, 22.298719330413459],
						[71.312391887152089, 22.298719330413459],
						[71.312391887347431, 22.298719327692254],
						[71.311561286604075, 22.296558227604859],
						[71.311561281759666, 22.296558218507624],
						[71.311561272070833, 22.296558200313111],
						[71.311561267226438, 22.29655819121588],
						[71.30830576667735, 22.291633189332735],
						[71.303577967896103, 22.284474892505827],
						[71.299416869060636, 22.277869292513792],
						[71.29941685723459, 22.277869278563887],
						[71.29941682902458, 22.277869250344175],
						[71.299416813513218, 22.277869236682314],
						[71.297319513502472, 22.276408136973732],
						[71.297319509334557, 22.27640813121554],
						[71.297319496923322, 22.276408125426194],
						[71.297319490815227, 22.276408121171837],
						[71.294786201088257, 22.275116527787333],
						[71.293035262270067, 22.273960223215621],
						[71.293114094506549, 22.273608344767755],
						[71.29311408968006, 22.273608284317042],
						[71.293114047824218, 22.273608178105146],
						[71.293114009922206, 22.273608131736438],
						[71.293105806059728, 22.273602697184462],
						[71.29311409981149, 22.273461111875918],
						[71.293139099773114, 22.273047211242201],
						[71.293139099773114, 22.273047211242201],
						[71.293139099968073, 22.273047208523412],
						[71.293139100163046, 22.273047205804616],
						[71.29315300020518, 22.272586105205008],
						[71.293153000400125, 22.272586102486201],
						[71.293152999917453, 22.27258609644112],
						[71.293153000112397, 22.272586093722314],
						[71.293150201168444, 22.272488918339704],
						[71.29320299542897, 22.272247242032169],
						[71.293202996700856, 22.272247211517733],
						[71.29320299119631, 22.272247147740785],
						[71.293202982284726, 22.272247118701035],
						[71.2931251932485, 22.272074939928043],
						[71.293100201314161, 22.271927791058395],
						[71.29310579927774, 22.271863915967682],
						[71.293105800057532, 22.271863905092442],
						[71.29310579880439, 22.271863884238563],
						[71.293105797644003, 22.271863874867275],
						[71.293039101740973, 22.271363902743111],
						[71.2931334773495, 22.270819551844092],
						[71.293150062952009, 22.270814088916513],
						[71.293150088656645, 22.270814075408346],
						[71.29315013677045, 22.27081404324375],
						[71.293150158501916, 22.270814021261085],
						[71.293264057994037, 22.270666822267518],
						[71.293264074007126, 22.270666790595332],
						[71.293264095172148, 22.270666725400279],
						[71.293264100324066, 22.270666691877395],
						[71.293247400590602, 22.270261121772474],
						[71.293277974107653, 22.270141824749633],
						[71.293316716718508, 22.270116926068528],
						[71.293466618822251, 22.270183482883322],
						[71.293466634046453, 22.270183487770549],
						[71.293466663622226, 22.270183496937598],
						[71.293466679913962, 22.270183499713298],
						[71.293494367896514, 22.270186297435529],
						[71.293655555832629, 22.270222393788707],
						[71.293655566693801, 22.270222395639237],
						[71.293655589288718, 22.270222399947588],
						[71.293655600344792, 22.270222399079163],
						[71.293674956665654, 22.270222400853108],
						[71.293847065974234, 22.270300157954896],
						[71.293852634369287, 22.270308412745234],
						[71.293852718241652, 22.270308456850703],
						[71.293852884175706, 22.270308455308488],
						[71.29385296710997, 22.270308410268136],
						[71.293858467300339, 22.270300109388099],
						[71.293858479238168, 22.270300083441995],
						[71.293858495938025, 22.270300029409768],
						[71.293858499827437, 22.270300000716251],
						[71.293858499626182, 22.270286112905254],
						[71.293925187888149, 22.26974730869577],
						[71.294014020123981, 22.269680759054076],
						[71.294014050200644, 22.269680722895266],
						[71.294014089894134, 22.26968064204663],
						[71.294014100383578, 22.269680597964147],
						[71.294005800780454, 22.269191736450367],
						[71.294041867245824, 22.269102921554445],
						[71.29408342656491, 22.269066854772344],
						[71.294222179779837, 22.268958569724653],
						[71.294366555142076, 22.269111236486509],
						[71.294366662296397, 22.269111262535969],
						[71.294366833586082, 22.2691111990537],
						[71.294366900339298, 22.269111111344007],
						[71.294391896771145, 22.268658358119453],
						[71.294402959935624, 22.268639024009644],
						[71.294411196631614, 22.268630785733926],
						[71.294583335493428, 22.268600196310903],
						[71.294583341991512, 22.268600195124016],
						[71.294583356927859, 22.268600191245856],
						[71.294583364493491, 22.268600187947232],
						[71.294722178656443, 22.268553019152524],
						[71.294761000899086, 22.268575174227863],
						[71.294761083815189, 22.268575180550879],
						[71.294761225714907, 22.268575130864747],
						[71.294761285765986, 22.268575072743765],
						[71.294933485744522, 22.268136173034897],
						[71.294933490404361, 22.268136159149503],
						[71.294933496716425, 22.268136134994982],
						[71.294933498758454, 22.268136119287679],
						[71.294936297976122, 22.268108326677144],
						[71.29494457224969, 22.268058483321916],
						[71.294969420843302, 22.268055799442088],
						[71.294969458281585, 22.268055788385912],
						[71.294969524144065, 22.268055751435888],
						[71.294969553245977, 22.268055728868418],
						[71.2951250978427, 22.267869592789882],
						[71.29521111253608, 22.267864101060646],
						[71.295211120101712, 22.267864097761692],
						[71.295211133970525, 22.267864095994689],
						[71.29521114027375, 22.267864097526704],
						[71.295305485058933, 22.267844628022111],
						[71.295455404330511, 22.268169451144914],
						[71.2954609007367, 22.268230618633293],
						[71.29546091187693, 22.268230654935071],
						[71.295460945603267, 22.268230721230722],
						[71.295460969934609, 22.268230752439258],
						[71.295480470282953, 22.268247353499699],
						[71.295480504704756, 22.268247371747382],
						[71.295480579953406, 22.268247395571588],
						[71.295480620102595, 22.268247397821685],
						[71.295644420314176, 22.268230798729945],
						[71.295644438063263, 22.268230793954157],
						[71.295644472688821, 22.268230783795246],
						[71.295644490437894, 22.268230779019454],
						[71.295661078928504, 22.268222434805839],
						[71.295797079274237, 22.268325159482213],
						[71.295797142789084, 22.268325180850162],
						[71.295797268574148, 22.268325177041685],
						[71.295797330844366, 22.268325151865302],
						[71.295838936694523, 22.268289132842735],
						[71.295872123490895, 22.268302984224952],
						[71.295872161310115, 22.268302993418214],
						[71.295872235500127, 22.268302993667323],
						[71.295872273616169, 22.268302985937865],
						[71.296019473586355, 22.268244585400325],
						[71.296019486972384, 22.26824457758773],
						[71.29601951354951, 22.268244564681776],
						[71.296019526062892, 22.268244556261809],
						[71.296064026315761, 22.268208455728765],
						[71.296064030196021, 22.268208452719634],
						[71.29606403902406, 22.268208444589479],
						[71.296064043099207, 22.268208438861112],
						[71.296200142687596, 22.268069538772739],
						[71.296200143560213, 22.268069539380082],
						[71.29620014656787, 22.268069535763583],
						[71.296200147635389, 22.268069533651655],
						[71.296277948316359, 22.26798343481812],
						[71.296277956661555, 22.267983420641844],
						[71.296277973834762, 22.267983398335229],
						[71.296277981112468, 22.267983386270906],
						[71.296380761324528, 22.267764027946093],
						[71.296489024340175, 22.267677956563766],
						[71.296489036175842, 22.267677944816601],
						[71.296489057034393, 22.267677922219509],
						[71.296489066057305, 22.267677911369624],
						[71.296561163849375, 22.267569665804199],
						[71.296730502648828, 22.267664075546147],
						[71.296730577999455, 22.267664085165887],
						[71.296730713688873, 22.267664045421739],
						[71.29673077383282, 22.267663998777248],
						[71.296741851122647, 22.267644540402166],
						[71.296872312624501, 22.267555765016663],
						[71.296872325137869, 22.267555756595918],
						[71.296872346869037, 22.267555734605633],
						[71.296872357832086, 22.267555722250822],
						[71.296922357848516, 22.267491824241731],
						[71.296922361923649, 22.26749181851293],
						[71.296922370073915, 22.267491807055379],
						[71.296922373276459, 22.267491800719249],
						[71.297089071369925, 22.267202903292272],
						[71.297102860000393, 22.267180880384842],
						[71.2972470688876, 22.267277952935171],
						[71.297280452626637, 22.267314036325764],
						[71.297280498197679, 22.267314065188774],
						[71.297280596914121, 22.267314093925581],
						[71.297280650059506, 22.267314093799349],
						[71.29743065030263, 22.267275193200959],
						[71.297430677752331, 22.267275180900224],
						[71.297430730423656, 22.267275149037761],
						[71.297430753027427, 22.267275127653939],
						[71.297461225177628, 22.267239060382821],
						[71.297563986934975, 22.267189078960367],
						[71.297563993627961, 22.267189075053416],
						[71.297564005946427, 22.267189069351645],
						[71.297564011766809, 22.26718906483736],
						[71.297641811766184, 22.26713626457574],
						[71.297641827092292, 22.267136255257025],
						[71.297641852898565, 22.267136227536419],
						[71.297641864929062, 22.267136213068753],
						[71.297677964712818, 22.267083413740849],
						[71.297677964907706, 22.26708341102135],
						[71.297677965780309, 22.267083411628693],
						[71.297822366129623, 22.26686681134527],
						[71.297822373407257, 22.266866799279608],
						[71.297822384082266, 22.266866778157869],
						[71.297822388547161, 22.266866766989654],
						[71.297858482488166, 22.266763982909442],
						[71.29791957467549, 22.266652895054939],
						[71.297919580885633, 22.266652885101333],
						[71.297919590010252, 22.266652860045149],
						[71.297919593602543, 22.266652848269494],
						[71.297955789159516, 22.266508371327131],
						[71.298005778812723, 22.266408387745305],
						[71.298005783082701, 22.266408379296429],
						[71.298005791139843, 22.266408356352276],
						[71.298005794732063, 22.266408344576494],
						[71.298077994761812, 22.266105644546791],
						[71.298077996219064, 22.266105636995409],
						[71.298077999328413, 22.266105619173089],
						[71.298077999913033, 22.266105611014396],
						[71.298094600224189, 22.265816712077779],
						[71.298094600419063, 22.265816709358184],
						[71.298094599546431, 22.265816708750865],
						[71.298105800465279, 22.265547207516907],
						[71.298105799787521, 22.265547204190035],
						[71.298105799694355, 22.2655471927044],
						[71.298105800084073, 22.265547187265227],
						[71.298083499567568, 22.265205593066948],
						[71.298080800090744, 22.264986097752846],
						[71.298080799802705, 22.26498608898682],
						[71.298080797964232, 22.264986076286636],
						[71.298080796803561, 22.264986066913359],
						[71.298058497073043, 22.264849968057618],
						[71.298058466446349, 22.264849909626776],
						[71.298058372693987, 22.264849824397174],
						[71.29805831305886, 22.264849800027509],
						[71.298014057319904, 22.264847011299405],
						[71.297902993866373, 22.264416653077262],
						[71.297841894881756, 22.264158255280563],
						[71.297841889552771, 22.264158240151744],
						[71.297841878699842, 22.264158212613548],
						[71.297841873175955, 22.264158200204264],
						[71.297669576488275, 22.263863804325677],
						[71.297664079322416, 22.263852710047999],
						[71.297664071180634, 22.263852695817285],
						[71.297664051889484, 22.263852670973243],
						[71.297664040934961, 22.26385265764042],
						[71.297652940999413, 22.26384155910938],
						[71.297652940126753, 22.263841558502158],
						[71.297652936831085, 22.263841553353792],
						[71.297652935085821, 22.263841552139361],
						[71.297486234887188, 22.263688751601919],
						[71.297486176124508, 22.263688727843874],
						[71.29748605519363, 22.263688715074242],
						[71.297485991763153, 22.263688730894511],
						[71.297308191839889, 22.263802632446438],
						[71.297308167296364, 22.263802655339294],
						[71.297308125868227, 22.263802709309324],
						[71.297308110051048, 22.26380273827419],
						[71.297305481888017, 22.263810816592155],
						[71.297150089300928, 22.263733120508004],
						[71.297150077761842, 22.263733115333977],
						[71.297150055361598, 22.263733108312682],
						[71.297150042755064, 22.263733105250942],
						[71.297125161689493, 22.263727629639096],
						[71.29699460238767, 22.263238879839847],
						[71.297005799865559, 22.263066713904522],
						[71.297005799289266, 22.26306669637351],
						[71.297005796196515, 22.263066662816577],
						[71.297005791934865, 22.263066645576394],
						[71.296952991637284, 22.262883244532901],
						[71.296952990959454, 22.262883241206296],
						[71.296952990281653, 22.262883237879723],
						[71.296952988731221, 22.262883233945967],
						[71.296880793770882, 22.262672148113744],
						[71.296841897002466, 22.262461065382535],
						[71.296841894774218, 22.262461058122295],
						[71.296841892740758, 22.262461048142633],
						[71.296841891385114, 22.262461041489519],
						[71.296777990984921, 22.26225274032139],
						[71.296777988561814, 22.262252735780631],
						[71.296777984783105, 22.262252724586791],
						[71.296777983427475, 22.262252717933727],
						[71.296711283233648, 22.262102718598236],
						[71.296711280132726, 22.262102710730996],
						[71.296711272668617, 22.26210269982824],
						[71.296711269372892, 22.262102694680419],
						[71.296636275717646, 22.261983204503121],
						[71.296600181028623, 22.261905517008824],
						[71.296600178800304, 22.261905509748743],
						[71.296600173081458, 22.261905500060315],
						[71.296600169785719, 22.261905494912529],
						[71.296522369923011, 22.261780493984361],
						[71.29652236071361, 22.261780481867689],
						[71.296522342972651, 22.261780460960821],
						[71.296522332695801, 22.261780450956469],
						[71.296422368781393, 22.261691580426969],
						[71.296355785954489, 22.261522126064111],
						[71.296355785954489, 22.261522126064111],
						[71.296355785081857, 22.261522125457059],
						[71.296355785081857, 22.261522125457059],
						[71.296244586749737, 22.261249926836008],
						[71.296133487578103, 22.260947130890983],
						[71.296133486027614, 22.260947126957575],
						[71.296133481376117, 22.260947115157332],
						[71.296133478953024, 22.260947110616886],
						[71.296069579134169, 22.260819310504949],
						[71.296069570797357, 22.260819298996072],
						[71.296069554318493, 22.260819273258925],
						[71.296069545109034, 22.260819261142991],
						[71.295964081674967, 22.260708201843386],
						[71.295891894774215, 22.260402753609483],
						[71.295891893901569, 22.260402753002481],
						[71.295891893223725, 22.260402749676167],
						[71.295891893418514, 22.260402746956849],
						[71.295883495340632, 22.260372154146069],
						[71.295819596198314, 22.260044362215329],
						[71.295819596393116, 22.260044359496014],
						[71.295819594164726, 22.260044352236438],
						[71.295819594359514, 22.260044349517106],
						[71.295758493647099, 22.259805549155111],
						[71.295758491418738, 22.259805541895609],
						[71.295758485216666, 22.259805526162623],
						[71.295758482115659, 22.259805518296151],
						[71.295716881678871, 22.259713818080989],
						[71.295716880323127, 22.259713811428465],
						[71.295716875476828, 22.259713802348109],
						[71.295716872180989, 22.259713797200948],
						[71.29561958380657, 22.259549918340362],
						[71.29554189065, 22.259297141458905],
						[71.295541882024736, 22.259297121186187],
						[71.295541860021416, 22.259297083044661],
						[71.295541846643374, 22.25929706517584],
						[71.295477947198819, 22.259227666538766],
						[71.295477943030349, 22.259227660784823],
						[71.295477933626003, 22.259227651389342],
						[71.295477929262802, 22.259227648354713],
						[71.295386229325658, 22.259149845873328],
						[71.29538622651296, 22.259149846771837],
						[71.295386223217164, 22.259149841624897],
						[71.295386219726538, 22.259149839197192],
						[71.29536406774406, 22.259133176556588],
						[71.295283487669622, 22.258908231970583],
						[71.295283480594847, 22.258908215631376],
						[71.295283462565166, 22.258908185963875],
						[71.295283451805034, 22.258908169916321],
						[71.295186261801163, 22.258794282225729],
						[71.295155770711403, 22.258744295702979],
						[71.295155750352109, 22.258744272979751],
						[71.295155702940704, 22.258744231442805],
						[71.29515567656648, 22.258744215955186],
						[71.295008376918858, 22.258683113798689],
						[71.295008338031607, 22.258683106737074],
						[71.295008259088519, 22.258683108929205],
						[71.295008220972761, 22.258683116677492],
						[71.294988880232907, 22.258691490028191],
						[71.294827936339587, 22.258655427007106],
						[71.294822379207929, 22.258644311537388],
						[71.294822348766374, 22.258644276093769],
						[71.294822275853321, 22.258644219677532],
						[71.294822232992345, 22.258644204143319],
						[71.29465833308187, 22.25861650271678],
						[71.294658324838494, 22.258616502693265],
						[71.294658307673885, 22.258616499320212],
						[71.294658300303183, 22.258616499903614],
						[71.294650000292492, 22.258616499811254],
						[71.294647199776151, 22.258616499079643],
						[71.294647182221993, 22.258616501144925],
						[71.294647146918976, 22.25861650799472],
						[71.294647130042705, 22.258616513386048],
						[71.294469384802937, 22.258683091191219],
						[71.294444442646721, 22.258677603583447],
						[71.294444382613548, 22.258677610362483],
						[71.294444275824233, 22.258677655990496],
						[71.29444423013544, 22.258677692727151],
						[71.294288810667808, 22.258927563851934],
						[71.294189002429349, 22.258897021308925],
						[71.294111235553174, 22.258824853558579],
						[71.294111224403508, 22.258824842950258],
						[71.29411119996935, 22.258824825958115],
						[71.294111186684859, 22.258824819574297],
						[71.294041833740692, 22.258791541953869],
						[71.293930774740815, 22.25863320162123],
						[71.293919582915123, 22.25860821747824],
						[71.293919543941783, 22.258608173248899],
						[71.293919450178734, 22.258608113754484],
						[71.293919393448974, 22.258608099994834],
						[71.293752933867836, 22.258613693779406],
						[71.293730812868603, 22.258558293916295],
						[71.293752936177555, 22.258491926035173],
						[71.293916666681284, 22.258519598056704],
						[71.293916730789277, 22.258519585547354],
						[71.293916840297655, 22.25851952753786],
						[71.293916884147478, 22.258519478104915],
						[71.293933483693422, 22.25848067935102],
						[71.293933486022993, 22.258480672407469],
						[71.293933490682136, 22.258480658520387],
						[71.293933493011707, 22.258480651576864],
						[71.294058490867755, 22.258019461201972],
						[71.294116881350078, 22.257855686219848],
						[71.294236223199661, 22.257664080774003],
						[71.294297236012866, 22.257652995769941],
						[71.294297285285737, 22.25765297294172],
						[71.294297362794168, 22.257652901238316],
						[71.294297391707616, 22.25765285568907],
						[71.294347386833522, 22.257480670885613],
						[71.294480780399709, 22.25720568723132],
						[71.294480780594455, 22.257205684512151],
						[71.294480783796601, 22.25720567817504],
						[71.294480785931398, 22.25720567395032],
						[71.294589075013562, 22.256936198633248],
						[71.29466125081791, 22.256841831543767],
						[71.294711228137771, 22.256791855237246],
						[71.294841780388055, 22.256702986590486],
						[71.294933341744809, 22.256683494625896],
						[71.294933389272273, 22.256683470580974],
						[71.294933463772949, 22.256683402489553],
						[71.294933491618821, 22.25668335904988],
						[71.295016877042428, 22.256405705100487],
						[71.29502223978642, 22.256400244860782],
						[71.29503316207159, 22.256405752710943],
						[71.295147031621696, 22.256580710147034],
						[71.295147073119765, 22.256580744711858],
						[71.295147167857138, 22.256580790604342],
						[71.295147221291259, 22.256580799212774],
						[71.295199939877762, 22.256575207689828],
						[71.295266560076868, 22.256627943646386],
						[71.295377646053936, 22.256761228778451],
						[71.295377670293576, 22.25676124848853],
						[71.295377722847675, 22.256761282178068],
						[71.295377753102187, 22.256761294651838],
						[71.295491615293756, 22.256789085812656],
						[71.295558183763376, 22.256836263541032],
						[71.295558265028703, 22.256836280084663],
						[71.295558411382999, 22.256836244828609],
						[71.295558477344599, 22.256836193635735],
						[71.295700169959943, 22.256569506678307],
						[71.295739061047357, 22.256516819753578],
						[71.295739063182111, 22.256516815528517],
						[71.295739068129564, 22.256516810404513],
						[71.295739070264375, 22.256516806179473],
						[71.295752969579084, 22.256494506540037],
						[71.295752981709811, 22.256494477863455],
						[71.295752997049988, 22.256494417161456],
						[71.295753000064693, 22.256494387855419],
						[71.295750203914267, 22.256452850854412],
						[71.295919471159237, 22.256186360110707],
						[71.296027709106824, 22.256241877679773],
						[71.296027737616029, 22.256241888939588],
						[71.296027799293512, 22.256241897570185],
						[71.296027830326992, 22.25624189916611],
						[71.296099879550496, 22.256230821093158],
						[71.296213712353563, 22.256483370515838],
						[71.296274807959492, 22.256688958115632],
						[71.29627481658504, 22.256688978388006],
						[71.296274840334206, 22.25668901774181],
						[71.296274853712475, 22.256689035609519],
						[71.296360953729859, 22.256780735418353],
						[71.296361001920673, 22.256780766075607],
						[71.296361106264541, 22.256780792959173],
						[71.296361162417611, 22.256780789185477],
						[71.296455661909405, 22.256750190255971],
						[71.296455671220144, 22.256750188166141],
						[71.29645569110383, 22.256750179154437],
						[71.296455699736683, 22.256750173738308],
						[71.296580634938351, 22.256678012308118],
						[71.296636061266184, 22.256689095109738],
						[71.296636071060135, 22.256689099065646],
						[71.296636090164839, 22.256689100931705],
						[71.296636100348266, 22.256689099448675],
						[71.296758271163313, 22.25668909959434],
						[71.296813841755707, 22.256705792887356],
						[71.296813857175067, 22.256705795045487],
						[71.29681388520109, 22.256705800260708],
						[71.296813899942535, 22.256705799092501],
						[71.296936100048654, 22.256705799811968],
						[71.29693610741937, 22.256705799227859],
						[71.29693612109341, 22.256705800172291],
						[71.296936128658842, 22.256705796868669],
						[71.296994428641668, 22.256697397829399],
						[71.296994433199686, 22.256697398144226],
						[71.296994440765161, 22.256697394840597],
						[71.296994446195825, 22.256697395762284],
						[71.297041745715774, 22.256686294302106],
						[71.297041755899201, 22.256686292819087],
						[71.297041777528179, 22.256686285020816],
						[71.297041787906352, 22.256686280818254],
						[71.297172279234402, 22.256622383315481],
						[71.297194469676157, 22.256614087497319],
						[71.297194489754546, 22.256614075765658],
						[71.29719452709864, 22.256614053201229],
						[71.297194543686402, 22.256614039042052],
						[71.297255723359726, 22.256550160045364],
						[71.297352895046529, 22.25649737528617],
						[71.297352896986567, 22.256497373780348],
						[71.297352898926604, 22.256497372274517],
						[71.297352900671925, 22.256497373488248],
						[71.297486104655633, 22.256419629180655],
						[71.297533205726012, 22.256444576271324],
						[71.297533219883263, 22.256444583261732],
						[71.297533247519837, 22.256444593916036],
						[71.297533262066565, 22.256444595467226],
						[71.297677762185486, 22.256472396982996],
						[71.297677763058118, 22.25647239758986],
						[71.297677765870844, 22.256472396690867],
						[71.297677766743504, 22.25647239729776],
						[71.297711066783734, 22.256477996973871],
						[71.297711103442595, 22.256477996772514],
						[71.297711172303352, 22.256477981850072],
						[71.297711206055766, 22.256477971062324],
						[71.297755564290739, 22.256450256784312],
						[71.297883150640558, 22.256591833857485],
						[71.297883154131213, 22.256591836285079],
						[71.29788315829974, 22.256591842039171],
						[71.297883160045046, 22.256591843252966],
						[71.297888736351268, 22.256597320169323],
						[71.297891521130211, 22.256602888520483],
						[71.297891529272547, 22.256602902748352],
						[71.297891547497329, 22.256602929698282],
						[71.297891558647123, 22.256602940307577],
						[71.297897051350787, 22.256608433320039],
						[71.298069243406687, 22.256825123180612],
						[71.298069250193208, 22.256825130755576],
						[71.298069264638883, 22.256825146512416],
						[71.298069273170682, 22.256825155301179],
						[71.298163746423427, 22.256902930923452],
						[71.298247028184065, 22.257044502033782],
						[71.298247066869308, 22.257044537504566],
						[71.298247160344445, 22.257044588240323],
						[71.298247212711104, 22.257044598964804],
						[71.298288913176407, 22.257041900827566],
						[71.298288937423365, 22.257041894850104],
						[71.298288983299329, 22.257041881074368],
						[71.298289005123067, 22.257041870556396],
						[71.298427904918555, 22.256955770821151],
						[71.298427910738681, 22.256955766303545],
						[71.2984279213116, 22.256955759381185],
						[71.298427926259109, 22.256955754256666],
						[71.298530711952637, 22.256872365554127],
						[71.298605604793124, 22.256830826156584],
						[71.298677711213244, 22.256866880051867],
						[71.298677733613829, 22.256866887065932],
						[71.298677781422484, 22.256866897475348],
						[71.298677805085262, 22.256866899656856],
						[71.2987861049603, 22.256864101357859],
						[71.298786132019984, 22.256864094481088],
						[71.298786185266636, 22.256864080120621],
						[71.298786209030439, 22.256864068096338],
						[71.298930610178971, 22.256769633797546],
						[71.298966566841941, 22.256789054245544],
						[71.299063740971434, 22.25691682035227],
						[71.299063740776703, 22.256916823072086],
						[71.299063741649377, 22.256916823679045],
						[71.29906374271674, 22.256916821566168],
						[71.299144242628131, 22.257019523326935],
						[71.299144247669318, 22.257019529688481],
						[71.299144258819112, 22.257019540298753],
						[71.299144263860299, 22.257019546660313],
						[71.299294261138442, 22.257158444011438],
						[71.299322059145027, 22.257186241837083],
						[71.299322085324633, 22.257186260046183],
						[71.299322141953496, 22.257186288012939],
						[71.299322173275385, 22.257186298377526],
						[71.299486073410151, 22.257208497899093],
						[71.299486088829497, 22.257208500058116],
						[71.299486121997774, 22.257208497430501],
						[71.299486137611851, 22.257208496869655],
						[71.29950003790114, 22.257205796000576],
						[71.299500060208089, 22.257205791528989],
						[71.299500101042881, 22.257205771391263],
						[71.29950012024868, 22.257205759051995],
						[71.299522318772205, 22.257189059765448],
						[71.29968062587858, 22.257075226629055],
						[71.299813823215914, 22.257130784784508],
						[71.299813848429238, 22.257130790900476],
						[71.29981389837269, 22.257130797085527],
						[71.299813923975464, 22.257130797761626],
						[71.299861019271788, 22.257125210679746],
						[71.300002649910567, 22.257286232085711],
						[71.300002650783227, 22.257286232692721],
						[71.300002653401208, 22.257286234513735],
						[71.300002653206448, 22.257286237233668],
						[71.300038738990324, 22.257325120794448],
						[71.300063728165441, 22.257366802354273],
						[71.300063744161591, 22.257366822047199],
						[71.300063780906726, 22.257366859028163],
						[71.300063801850399, 22.257366873596268],
						[71.300216602228815, 22.25745297504546],
						[71.300216628119969, 22.257452984488932],
						[71.300216683104594, 22.257452997037102],
						[71.30021671219798, 22.257453000141734],
						[71.300263912210681, 22.257450199307222],
						[71.300263917836105, 22.257450197509343],
						[71.300263930637499, 22.257450197847664],
						[71.300263937135583, 22.257450196656809],
						[71.300397237206781, 22.257425198456286],
						[71.30039724477227, 22.257425195152472],
						[71.300397259903207, 22.257425188544826],
						[71.300397268146597, 22.257425188568],
						[71.300427815136032, 22.257414107562646],
						[71.300574959089616, 22.257444595121139],
						[71.300575001179169, 22.257444595844156],
						[71.300575080223453, 22.257444579440929],
						[71.300575117178141, 22.257444562314699],
						[71.300605717335685, 22.257422361840781],
						[71.300605719275723, 22.257422360334804],
						[71.300605723155854, 22.257422357322849],
						[71.300605725095906, 22.257422355816882],
						[71.300716825021397, 22.257333456514431],
						[71.300716837923872, 22.25733344264545],
						[71.300716860916154, 22.257333415806521],
						[71.300716871005918, 22.25733340283648],
						[71.300741783640589, 22.257291948791568],
						[71.300755518365591, 22.257297382276256],
						[71.300766610310291, 22.257302979141762],
						[71.300766632710904, 22.257302986157274],
						[71.300766682264836, 22.257302997783359],
						[71.300766706800232, 22.257303000572847],
						[71.300936083254214, 22.257297402279772],
						[71.300949817684057, 22.257300165698052],
						[71.300955400656306, 22.257347225093035],
						[71.300955402206881, 22.257347229027172],
						[71.300955404240568, 22.25734723900845],
						[71.300955404723723, 22.257347245055652],
						[71.301022004967905, 22.257636144311103],
						[71.301022014271226, 22.257636167916168],
						[71.301022038503248, 22.257636213328428],
						[71.301022051686701, 22.257636233921545],
						[71.301110951968823, 22.257733434257631],
						[71.301110994050887, 22.257733460676299],
						[71.301111085203715, 22.257733492675197],
						[71.301111134274464, 22.257733498255405],
						[71.301222233811629, 22.257714097479017],
						[71.301222258253375, 22.257714088781661],
						[71.301222303646256, 22.257714068958677],
						[71.30122232479215, 22.257714055112988],
						[71.301291585374173, 22.257658646849364],
						[71.30133040679155, 22.257805651085857],
						[71.301330436259462, 22.257805700141507],
						[71.301330517120206, 22.257805773526627],
						[71.301330567640377, 22.257805797249045],
						[71.301469327800987, 22.257827990205687],
						[71.301499820742279, 22.257847324140805],
						[71.301519201832235, 22.25794443125487],
						[71.301549800558391, 22.2582472210444],
						[71.301549801914177, 22.258247227698874],
						[71.301549804625722, 22.25824724100784],
						[71.301549805786749, 22.258247250382443],
						[71.301616501044293, 22.258508329106913],
						[71.301641499674176, 22.259052803847048],
						[71.301641500341148, 22.259069401406268],
						[71.301641553557303, 22.259069489832772],
						[71.301641712776345, 22.259069569195621],
						[71.301641816839194, 22.259069561637883],
						[71.301819452995389, 22.258941908229453],
						[71.30186378670129, 22.258947385952428],
						[71.301963717005563, 22.259091785917864],
						[71.301980407552904, 22.259150055057162],
						[71.30198041288233, 22.259150070188017],
						[71.301980425676049, 22.259150096223607],
						[71.301980432945541, 22.259150109848544],
						[71.301997024727498, 22.259175098430614],
						[71.302088712191647, 22.259372268072035],
						[71.302169206843246, 22.259663953227172],
						[71.302169207521132, 22.259663956554576],
						[71.302169209944296, 22.259663961096368],
						[71.302169209749493, 22.259663963816568],
						[71.302172005921619, 22.259672251984881],
						[71.302174804280469, 22.259686138937735],
						[71.302174804085666, 22.259686141657973],
						[71.302174804763553, 22.259686144985377],
						[71.302174804763553, 22.259686144985377],
						[71.302247004801231, 22.259994445021444],
						[71.302247007029592, 22.259994452283486],
						[71.302247010808443, 22.259994463480133],
						[71.302247013036791, 22.259994470742178],
						[71.302338712369448, 22.260233367186807],
						[71.302344209970784, 22.260250063573398],
						[71.302344214622281, 22.2602500753774],
						[71.30234422654317, 22.260250100806999],
						[71.302344234007364, 22.260250111712377],
						[71.302349832026323, 22.260258407750204],
						[71.302363729663469, 22.260280706452026],
						[71.302363731408761, 22.260280707666467],
						[71.302363732281421, 22.260280708273683],
						[71.302363733154039, 22.260280708880888],
						[71.302527619851176, 22.260530690086561],
						[71.302630411992212, 22.260811168072387],
						[71.302630412864829, 22.260811168679624],
						[71.302630414220545, 22.260811175334666],
						[71.302630415093176, 22.260811175941907],
						[71.302702611052538, 22.260988965663067],
						[71.302822008844657, 22.261383359060705],
						[71.302822011072948, 22.26138336632313],
						[71.302822017469651, 22.261383379342238],
						[71.302822019697928, 22.26138338660466],
						[71.302880414297576, 22.261502874814497],
						[71.303030401225911, 22.261955636672333],
						[71.303033100366406, 22.262027807281342],
						[71.30303311441385, 22.262027854184382],
						[71.303033160842219, 22.262027935046742],
						[71.303033194095789, 22.262027969613388],
						[71.30305546496902, 22.262041852765698],
						[71.303099843774007, 22.262097325147799],
						[71.303099862582386, 22.262097343949538],
						[71.303099906214186, 22.26209737431568],
						[71.303099929970202, 22.262097387993091],
						[71.303233163310068, 22.262147361014843],
						[71.303355427915676, 22.262355699754103],
						[71.303355431889216, 22.262355708231521],
						[71.303355440903715, 22.262355723073359],
						[71.30335544613952, 22.262355726717416],
						[71.303410934062981, 22.262422314119828],
						[71.303477624394802, 22.262544496251373],
						[71.303477630308379, 22.2625445032232],
						[71.303477640000708, 22.262544521392957],
						[71.303477645914327, 22.262544528364828],
						[71.303588745749693, 22.262677929040105],
						[71.30358874924022, 22.262677931469579],
						[71.303588752535916, 22.262677936619411],
						[71.303588755153839, 22.26267793844152],
						[71.303649855316394, 22.262741839450776],
						[71.303649863170122, 22.262741844917127],
						[71.303649877810074, 22.262741857962855],
						[71.303649885663745, 22.262741863429174],
						[71.303766580986817, 22.262822360881433],
						[71.303877674683321, 22.262911255642315],
						[71.303877693881262, 22.262911269004835],
						[71.303877732861622, 22.26291128756861],
						[71.303877752644041, 22.262911292769854],
						[71.303947048375747, 22.262927969103629],
						[71.304022012161241, 22.263138966642551],
						[71.304022015262106, 22.26313897451303],
						[71.304022022336468, 22.263138990861403],
						[71.304022026309951, 22.263138999339262],
						[71.304122015798413, 22.263316780290666],
						[71.304252610176277, 22.263711163533408],
						[71.304252611048909, 22.263711164140854],
						[71.304252611726682, 22.263711167468731],
						[71.304252611726682, 22.263711167468731],
						[71.304299811708049, 22.263838968678396],
						[71.304299815681517, 22.263838977156546],
						[71.304299823628412, 22.263838994112838],
						[71.304299827601881, 22.263839002590988],
						[71.304377628429449, 22.263969501518755],
						[71.30437764006173, 22.263969518184503],
						[71.304377668079113, 22.263969549112325],
						[71.304377682718965, 22.26396956215947],
						[71.304477683325629, 22.264041862243406],
						[71.304477684198233, 22.264041862850853],
						[71.30447768594351, 22.264041864065774],
						[71.304477686816156, 22.264041864673242],
						[71.304599866225558, 22.264125152234193],
						[71.304655449196474, 22.264189031134872],
						[71.304655508247123, 22.264189063673893],
						[71.304655632083993, 22.264189087046198],
						[71.304655698132535, 22.264189073045898],
						[71.30469991110914, 22.264164179190139],
						[71.304766519108512, 22.26430568415639],
						[71.304766522209334, 22.264305692027399],
						[71.304766528800641, 22.264305702328286],
						[71.30476653190145, 22.264305710199274],
						[71.304833132365616, 22.264408409705879],
						[71.304833150106262, 22.264408430624748],
						[71.304833191602683, 22.264408465225205],
						[71.304833215163654, 22.264408481627374],
						[71.304963796058985, 22.264469571386719],
						[71.305010957185161, 22.264505742416699],
						[71.305110941096231, 22.264636220656115],
						[71.305110956413785, 22.26463623703237],
						[71.305110988311284, 22.264636264951285],
						[71.30511100576382, 22.264636277101445],
						[71.305188737275429, 22.264677939421937],
						[71.305266507965712, 22.264897256936379],
						[71.305310905825991, 22.265072248199079],
						[71.305310907376352, 22.265072252134733],
						[71.305310908054139, 22.265072255462865],
						[71.305310908054139, 22.265072255462865],
						[71.305366505015726, 22.265261145744315],
						[71.30540540276273, 22.265463934337987],
						[71.305452601974622, 22.265747230059421],
						[71.305494202305056, 22.266030627914521],
						[71.305494202982786, 22.266030631242703],
						[71.305494202593025, 22.266030636683908],
						[71.305494204143372, 22.266030640619704],
						[71.305538703618438, 22.266250040776317],
						[71.305538703618438, 22.266250040776317],
						[71.305538705168786, 22.266250044712116],
						[71.305538705168786, 22.266250044712116],
						[71.305619199169115, 22.266597219621961],
						[71.305610901091853, 22.266833277879055],
						[71.305549803963373, 22.267152761411662],
						[71.305549802895854, 22.267152763524649],
						[71.30554980250605, 22.267152768965843],
						[71.305549801633461, 22.267152768358223],
						[71.305544203728388, 22.267188861629631],
						[71.305530404670634, 22.267247153155971],
						[71.30553040534835, 22.267247156484206],
						[71.30553040534835, 22.267247156484206],
						[71.30553040534835, 22.267247156484206],
						[71.30546922061248, 22.267522081218992],
						[71.305350043223768, 22.267608021829282],
						[71.305302960940594, 22.26754427958782],
						[71.305302906643448, 22.267544244634379],
						[71.305302788144743, 22.267544210674874],
						[71.305302722875638, 22.267544213781694],
						[71.305169322600619, 22.267599815796171],
						[71.305169284865457, 22.267599843788478],
						[71.305169225779906, 22.267599914038879],
						[71.305169205302164, 22.267599956904586],
						[71.305149805084127, 22.267686056971947],
						[71.305149801779692, 22.267686077521009],
						[71.305149802346605, 22.26768612075972],
						[71.305149803600131, 22.267686141626474],
						[71.305158103902002, 22.267725042788772],
						[71.305158139280522, 22.267725098843602],
						[71.305158237106525, 22.267725178390009],
						[71.305158299748868, 22.267725199161006],
						[71.305169258762774, 22.267725198554135],
						[71.305269211017816, 22.26800836810655],
						[71.305269219159257, 22.26800838234486],
						[71.305269236992444, 22.268008414757393],
						[71.305269246879064, 22.268008430211008],
						[71.305347017832844, 22.268100093991723],
						[71.305369202051907, 22.268183334094111],
						[71.305374798733979, 22.268258296336072],
						[71.305366500842908, 22.26832777559941],
						[71.305366500740959, 22.26832778980992],
						[71.305366502087367, 22.268327822166867],
						[71.305366502858021, 22.268327836985069],
						[71.305458101419106, 22.268822221582425],
						[71.305471999677181, 22.269241706056487],
						[71.305472000354882, 22.269241709384794],
						[71.305472000159966, 22.269241712105359],
						[71.305472000159966, 22.269241712105359],
						[71.305480399643827, 22.269383308893108],
						[71.305483100184105, 22.269486103821084],
						[71.305483101149605, 22.269486115918845],
						[71.305483104148152, 22.269486138001525],
						[71.305483106181214, 22.269486147986463],
						[71.305516503329486, 22.269622238814922],
						[71.305563699285216, 22.269947210719391],
						[71.305541500310653, 22.270405591343071],
						[71.305535899660285, 22.270505590038944],
						[71.305535900337972, 22.270505593367304],
						[71.305535900430797, 22.270505604857352],
						[71.305535900040908, 22.270505610298468],
						[71.305541499622819, 22.270608303049812],
						[71.305524799827808, 22.271063892807835],
						[71.305524801275908, 22.271063910954613],
						[71.30552480611226, 22.271063945743229],
						[71.305524810373129, 22.271063962992887],
						[71.305680409911744, 22.271530661922235],
						[71.305680417857914, 22.271530678882872],
						[71.305680434817859, 22.271530710691511],
						[71.305680444704194, 22.271530726147251],
						[71.305705408163149, 22.271561180315896],
						[71.305713701388626, 22.271636121726992],
						[71.30571370409919, 22.27163613504068],
						[71.305713711070538, 22.271636165604303],
						[71.305713715721282, 22.271636177413097],
						[71.305858115716177, 22.271975078412968],
						[71.305858166902425, 22.271975131208357],
						[71.305858291211123, 22.271975186382715],
						[71.30585836326604, 22.271975190874429],
						[71.30594146071347, 22.271947490809435],
						[71.305908101600863, 22.272208274628287],
						[71.305908100626013, 22.27220828823128],
						[71.305908101294136, 22.272208317260905],
						[71.30590810293711, 22.27220833268753],
						[71.305966500658982, 22.272563924134847],
						[71.305980400494207, 22.272772212443002],
						[71.305980400781891, 22.272772221212733],
						[71.305980403297397, 22.272772237247334],
						[71.305980404652615, 22.272772243904356],
						[71.306030404538575, 22.27300004396875],
						[71.306030405216177, 22.273000047297266],
						[71.306030406766425, 22.273000051233723],
						[71.306030406571438, 22.273000053954323],
						[71.306080406634777, 22.273177853282526],
						[71.306080412835627, 22.27317786902837],
						[71.306080426304888, 22.273177898407397],
						[71.3060804335733, 22.273177912040552],
						[71.306191518828172, 22.273344488797381],
						[71.306199810370217, 22.27336946386907],
						[71.306199811920465, 22.27336946780559],
						[71.306199814148258, 22.273369475070638],
						[71.306199815698449, 22.273369479007155],
						[71.306208108725016, 22.273388962135897],
						[71.306274805448083, 22.273694441983658],
						[71.306274805058109, 22.273694447424926],
						[71.306274807480918, 22.273694451969423],
						[71.306274808158534, 22.273694455298049],
						[71.306338707630957, 22.273913956130304],
						[71.306338710053751, 22.27391396067485],
						[71.30633871363672, 22.273913974597232],
						[71.306338716059528, 22.273913979141781],
						[71.306383116270169, 22.274016778443915],
						[71.306383122665949, 22.274016791469649],
						[71.306383139142795, 22.274016817232319],
						[71.306383148351344, 22.27401682936134],
						[71.306430441694033, 22.274072321537066],
						[71.306560935892634, 22.274258415022398],
						[71.306560966915924, 22.274258442351378],
						[71.306561037113326, 22.274258485549321],
						[71.306561077354985, 22.274258499305585],
						[71.306633145203847, 22.274266882515185],
						[71.306699807916431, 22.274497256258822],
						[71.306699814117124, 22.274497272005608],
						[71.306699827781159, 22.274497298665811],
						[71.306699835727088, 22.274497315628633],
						[71.306738735698303, 22.274552914737917],
						[71.306738785241009, 22.274552952115187],
						[71.306738895299773, 22.274552988810822],
						[71.306738956493462, 22.274552991457867],
						[71.306891755526706, 22.27450849294414],
						[71.306891771530772, 22.274508486956112],
						[71.306891801988726, 22.27450847104328],
						[71.3068918162476, 22.274508463839215],
						[71.306911163637295, 22.274494598799624],
						[71.306916695403729, 22.274494599743747],
						[71.307088890346407, 22.274502999615162],
						[71.30708889315919, 22.2745029987185],
						[71.307088897717179, 22.274502999037878],
						[71.307088900334918, 22.27450300086192],
						[71.307097168794314, 22.274503000515676],
						[71.307174938828183, 22.274527990342104],
						[71.307174942513555, 22.274527990053457],
						[71.307174947749075, 22.274527993701589],
						[71.307174951434448, 22.274527993412942],
						[71.307274918162932, 22.274552987295326],
						[71.307283189563378, 22.274558468072616],
						[71.307283261905724, 22.274558481345053],
						[71.30728339729923, 22.274558458565995],
						[71.307283459672774, 22.27455841918573],
						[71.307300143099056, 22.274536241640963],
						[71.307455664681243, 22.274419599937353],
						[71.307591695726956, 22.274422399947643],
						[71.307591705715481, 22.274422401194414],
						[71.30759172695511, 22.27442239885433],
						[71.307591737138623, 22.274422397380285],
						[71.307636053318959, 22.274414112279114],
						[71.307799884223513, 22.274530764298856],
						[71.307799979745695, 22.27453077372699],
						[71.307800136296109, 22.274530711412407],
						[71.307800196841782, 22.274530633619996],
						[71.30781409224879, 22.274452863438874],
						[71.307900178976595, 22.274280689848538],
						[71.307900187034733, 22.274280666896235],
						[71.307900196652895, 22.274280622177137],
						[71.307900200153085, 22.274280598905506],
						[71.307900200072083, 22.274016736623185],
						[71.307952986843432, 22.273875069667863],
						[71.307952988978599, 22.273875065442184],
						[71.307952991308767, 22.273875058495609],
						[71.307952991503768, 22.273875055774752],
						[71.308000187332951, 22.273711174940207],
						[71.308122376282569, 22.273472296113567],
						[71.308158463897698, 22.273408416036219],
						[71.308180748988576, 22.273383432063532],
						[71.308180755876705, 22.27338342543597],
						[71.30818076810273, 22.273383408243905],
						[71.308180774313229, 22.273383398287432],
						[71.308275173816341, 22.273216798076248],
						[71.308275180221827, 22.273216785398731],
						[71.30827519022003, 22.273216760940656],
						[71.30827519400772, 22.27321674643914],
						[71.308344583100322, 22.272927894059279],
						[71.308364003218585, 22.272905786822076],
						[71.308522230085643, 22.272880798445851],
						[71.308541679908359, 22.272878003757068],
						[71.308716633892303, 22.272939087967121],
						[71.308716698966407, 22.272939087599855],
						[71.308716815162683, 22.272939051432335],
						[71.308716866674843, 22.272939010190122],
						[71.308722366649874, 22.272930709435954],
						[71.30872236859004, 22.272930707930982],
						[71.308722372860373, 22.272930699479058],
						[71.308722374995554, 22.27293069525313],
						[71.308830774615913, 22.272733396055088],
						[71.308830783156509, 22.272733379151052],
						[71.30883079393459, 22.272733343808024],
						[71.308830797917267, 22.27273332658503],
						[71.308875195125779, 22.272402848065859],
						[71.308891887411278, 22.27235836969632],
						[71.308891888478868, 22.272358367583312],
						[71.308891889741432, 22.272358362749259],
						[71.308891890808979, 22.272358360636229],
						[71.308905791469215, 22.272313959787379],
						[71.30890579079157, 22.272313956458369],
						[71.308905791664174, 22.272313957066363],
						[71.308905791859161, 22.272313954345357],
						[71.309036286708164, 22.271852873316419],
						[71.309089071859376, 22.271750102949209],
						[71.309155626657841, 22.271653114630272],
						[71.309266557287501, 22.271766840472115],
						[71.309266661823642, 22.271766864738748],
						[71.309266833114904, 22.271766801241142],
						[71.30926689880242, 22.271766715589955],
						[71.309297398337762, 22.271400036342623],
						[71.309322392087182, 22.271313955205397],
						[71.309322394612238, 22.271313945536981],
						[71.309322398112073, 22.27131392226309],
						[71.30932239976454, 22.271313911986709],
						[71.309341898967901, 22.27097223078357],
						[71.30939459371595, 22.270758347923017],
						[71.30939459371595, 22.270758347923017],
						[71.309394594783527, 22.270758345809863],
						[71.309394594978471, 22.270758343088765],
						[71.309419595113965, 22.270647244353253],
						[71.309419595698799, 22.270647236189912],
						[71.30941959929136, 22.27064722440814],
						[71.309419599003618, 22.270647215636913],
						[71.309455798712222, 22.270202816013235],
						[71.309455799009299, 22.270202799078628],
						[71.309455796790644, 22.27020276610677],
						[71.309455794274925, 22.270202750069537],
						[71.309452995054684, 22.270191654941552],
						[71.309339099264747, 22.269636076744185],
						[71.309333499668384, 22.269477791707384],
						[71.309333499863314, 22.269477788986276],
						[71.30933349870287, 22.269477779607168],
						[71.309333498897814, 22.26947777688607],
						[71.309286298687681, 22.269094375694142],
						[71.309286297815063, 22.269094375086286],
						[71.309283497912304, 22.269072175046382],
						[71.309283498107234, 22.269072172325284],
						[71.309283496751831, 22.269072165667353],
						[71.309283496074173, 22.269072162338386],
						[71.309280796699852, 22.269058265894273],
						[71.30919459776436, 22.268508269567139],
						[71.309194596891771, 22.26850826895933],
						[71.309194597086687, 22.268508266238232],
						[71.309114097218711, 22.268022167978906],
						[71.309114097413641, 22.268022165257829],
						[71.30910019746247, 22.267944364744281],
						[71.309100195912151, 22.267944360807604],
						[71.309100195234436, 22.267944357478694],
						[71.309100193684117, 22.267944353542006],
						[71.30905299386589, 22.267749951852775],
						[71.30896409501193, 22.267374953286435],
						[71.308964092783867, 22.267374946020983],
						[71.308964089005499, 22.2673749348189],
						[71.308964086777451, 22.267374927553455],
						[71.308950198023922, 22.267338857171705],
						[71.308939100299568, 22.267163886506488],
						[71.308939098749235, 22.267163882569879],
						[71.30893909913901, 22.267163877127718],
						[71.308939098266379, 22.267163876519955],
						[71.308894598398368, 22.2668110749566],
						[71.308894595687477, 22.26681106164137],
						[71.308894589875905, 22.26681104045301],
						[71.308894586970126, 22.266811029858825],
						[71.308764087135202, 22.266469328799765],
						[71.308764086457458, 22.266469325470979],
						[71.308764083161876, 22.266469320319054],
						[71.308764081611514, 22.26646931638254],
						[71.308722382266339, 22.266377718007664],
						[71.308722380715963, 22.266377714071162],
						[71.308722379165602, 22.266377710134684],
						[71.308722378293012, 22.266377709526996],
						[71.308650177613899, 22.266236008077243],
						[71.308650176063537, 22.266236004140794],
						[71.308650171217593, 22.266235995052465],
						[71.308650167922011, 22.266235989900615],
						[71.308589068100787, 22.266141592098158],
						[71.308589059764358, 22.266141580579102],
						[71.308589042896585, 22.266141560262049],
						[71.308589034365241, 22.266141551464042],
						[71.308469546492589, 22.26603316378019],
						[71.308408468352411, 22.26595548888443],
						[71.308241884047163, 22.265649920573015],
						[71.308233490320617, 22.265624938443246],
						[71.308072390430027, 22.265130538210482],
						[71.30807238955741, 22.265130537602861],
						[71.308055800186537, 22.265080569455822],
						[71.308055799654525, 22.265077800970907],
						[71.308055798688756, 22.265077788871725],
						[71.308055797240073, 22.265077770722954],
						[71.308055796079429, 22.265077761344742],
						[71.307986296242362, 22.264738859165487],
						[71.307986296242362, 22.264738859165487],
						[71.307986294691958, 22.264738855229279],
						[71.307986294691958, 22.264738855229279],
						[71.307933497147644, 22.264511062443116],
						[71.307916897745201, 22.264402769922427],
						[71.307916896872555, 22.26440276931482],
						[71.307916897262274, 22.264402763872898],
						[71.307916897262274, 22.264402763872898],
						[71.307883496991948, 22.264224963779935],
						[71.307883496314162, 22.264224960451376],
						[71.307883494763772, 22.264224956515221],
						[71.307883494763772, 22.264224956515221],
						[71.307819596357021, 22.263944360177351],
						[71.307722396718276, 22.2634249638364],
						[71.307714098813648, 22.263377774468299],
						[71.307711298771963, 22.263338886286334],
						[71.307711299161639, 22.263338880844426],
						[71.307711297806065, 22.263338874187465],
						[71.30771129819577, 22.263338868745556],
						[71.307627999008076, 22.262813876877729],
						[71.307597398842688, 22.262444382205267],
						[71.307580801162203, 22.262252797624267],
						[71.307605799197532, 22.261902827253969],
						[71.307644597317889, 22.261697236163425],
						[71.307644598003819, 22.261697213788306],
						[71.307644595690277, 22.261697169329114],
						[71.307644592690835, 22.261697147245094],
						[71.307544594684757, 22.261336055996463],
						[71.307505796985993, 22.261127763553649],
						[71.307447396486893, 22.260808264815694],
						[71.307447395809092, 22.260808261487362],
						[71.307447395326037, 22.260808255438029],
						[71.307447393775561, 22.260808251502262],
						[71.307386293914689, 22.260561051652378],
						[71.307386293914689, 22.260561051652378],
						[71.30738629410952, 22.260561048931457],
						[71.30738629410952, 22.260561048931457],
						[71.307369593633524, 22.260497150121637],
						[71.307369592955695, 22.26049714679332],
						[71.307369590532559, 22.260497142250181],
						[71.307369590727362, 22.260497139529253],
						[71.307208493927945, 22.259991649620755],
						[71.307194595982622, 22.25992495798085],
						[71.307194593559473, 22.259924953437828],
						[71.30719459084807, 22.259924940124684],
						[71.307194589492383, 22.259924933468131],
						[71.307111300217301, 22.259686065567251],
						[71.307108500366667, 22.259424997197325],
						[71.307108497366954, 22.259424975114257],
						[71.307108485936823, 22.259424930024952],
						[71.307108478184318, 22.259424910346983],
						[71.307019584909412, 22.259249921203665],
						[71.306883494023978, 22.258849951103421],
						[71.306844596764122, 22.258627766838082],
						[71.306844596958896, 22.258627764117211],
						[71.306844595603138, 22.258627757460836],
						[71.306844594730492, 22.258627756853524],
						[71.306766896178189, 22.258283258589618],
						[71.306669596872084, 22.257802764285319],
						[71.306655801376593, 22.257716690154385],
						[71.306658499935068, 22.257677812971128],
						[71.30665849964673, 22.257677804201286],
						[71.306658499069997, 22.257677786661592],
						[71.306658498781601, 22.25767777789175],
						[71.306597399187808, 22.257155577347628],
						[71.30655849962686, 22.256777790152366],
						[71.306558499677408, 22.256594399866422],
						[71.306558500261644, 22.256594391703814],
						[71.306558497939477, 22.256594372949841],
						[71.306558496778408, 22.256594363572884],
						[71.30650299729713, 22.25628606393596],
						[71.306502995746555, 22.256286060000743],
						[71.306502993712797, 22.256286050016623],
						[71.306502993034911, 22.256286046688579],
						[71.306464092805385, 22.256147145913921],
						[71.306464091932725, 22.256147145306734],
						[71.30646409125481, 22.256147141978722],
						[71.306464090576895, 22.256147138650693],
						[71.306425191310382, 22.256024940215777],
						[71.306425189759793, 22.256024936280596],
						[71.306425188209218, 22.256024932345419],
						[71.306425188209218, 22.256024932345419],
						[71.306325187733307, 22.255752730825787],
						[71.306325186182718, 22.255752726890648],
						[71.306325182208909, 22.255752718413216],
						[71.306325180658334, 22.255752714478078],
						[71.306269581642425, 22.255636017148284],
						[71.306186282918375, 22.255449918092754],
						[71.306150184614495, 22.255366623738272],
						[71.306064092851159, 22.255152743303768],
						[71.306014096872303, 22.254880564776279],
						[71.306014097067006, 22.254880562055469],
						[71.306014096194374, 22.254880561448385],
						[71.306014096194374, 22.254880561448385],
						[71.305975196908705, 22.254691663827053],
						[71.305911297293562, 22.254313865978155],
						[71.305911297488279, 22.254313863257362],
						[71.305911295937662, 22.25431385932243],
						[71.305911295259719, 22.254313855994571],
						[71.305800194597268, 22.253819356468508],
						[71.305800193919325, 22.253819353140688],
						[71.305800191690722, 22.253819345877979],
						[71.305800191207467, 22.253819339829342],
						[71.305777991002032, 22.253747140857236],
						[71.305777991002032, 22.253747140857236],
						[71.30577799032406, 22.253747137529409],
						[71.305777988773414, 22.253747133594516],
						[71.305625188954323, 22.253299934134244],
						[71.305625186531046, 22.253299929592423],
						[71.305625181201137, 22.253299914460243],
						[71.305625177905171, 22.253299909311391],
						[71.305561281799143, 22.253174916876961],
						[71.305447385374165, 22.252899923301658],
						[71.305447381400199, 22.25289991482521],
						[71.305447372384904, 22.252899899986041],
						[71.305447368410967, 22.252899891509614],
						[71.305291874353443, 22.252661000349786],
						[71.305269593644027, 22.252616642006402],
						[71.305266899500822, 22.252594389589877],
						[71.305272399936385, 22.252041702851862],
						[71.305272400131059, 22.252041700131134],
						[71.305272400131059, 22.252041700131134],
						[71.305272399662755, 22.252036098927043],
						[71.305272398890807, 22.252036084109477],
						[71.305272393661497, 22.252036054767288],
						[71.3052723911442, 22.252036038735827],
						[71.305100191440928, 22.251486039368682],
						[71.305100189017551, 22.25148603482722],
						[71.305100185916203, 22.25148602695814],
						[71.305100183687514, 22.251486019695957],
						[71.305091887024105, 22.251466628900065],
						[71.304922390434569, 22.250961036266865],
						[71.304922388011207, 22.250961031725524],
						[71.30492238578249, 22.250961024463457],
						[71.304922384909801, 22.250961023856558],
						[71.304894593108997, 22.250894343025681],
						[71.304850198229275, 22.250655571513818],
						[71.30481960293929, 22.250330616976914],
						[71.304869560158025, 22.250147374096393],
						[71.304927745656684, 22.250136314956265],
						[71.305077674057074, 22.250258455908842],
						[71.305077742132525, 22.250258477543625],
						[71.305077876356407, 22.250258470919647],
						[71.305077940759475, 22.250258441447166],
						[71.30510833377754, 22.25022804712566],
						[71.305272102420659, 22.250319574903973],
						[71.305272199596217, 22.250319573934],
						[71.30527234925917, 22.250319492363424],
						[71.305272400195776, 22.250319407828322],
						[71.305289099661479, 22.249872208303795],
						[71.305291900128609, 22.249805608927467],
						[71.305291893059504, 22.24980556688222],
						[71.305291864468614, 22.249805492734421],
						[71.305291841201424, 22.249805459418127],
						[71.305289100419543, 22.249802716684698],
						[71.305289100153843, 22.249797201341586],
						[71.305289098509007, 22.249797185917227],
						[71.305289094346719, 22.249797154461685],
						[71.305289090761903, 22.24979714054443],
						[71.305272390706818, 22.249744339641449],
						[71.305272389834158, 22.249744339034613],
						[71.305272390028804, 22.249744336313864],
						[71.305272389156116, 22.249744335707014],
						[71.305114092904503, 22.249283247921845],
						[71.305103002067852, 22.24923058692297],
						[71.305114098711726, 22.249080618564008],
						[71.30515019929426, 22.248783325617477],
						[71.305150199005496, 22.248783316848439],
						[71.305150200367891, 22.248783297803158],
						[71.305150200079083, 22.248783289034098],
						[71.305144599748616, 22.24867499087328],
						[71.305144599265162, 22.248674984824973],
						[71.305144598298298, 22.248674972728363],
						[71.305144596747581, 22.248674968794042],
						[71.305119597252826, 22.248522168184074],
						[71.305052997002221, 22.24810826800941],
						[71.305052997002221, 22.24810826800941],
						[71.305052997196839, 22.248108265288664],
						[71.305052997391471, 22.248108262567925],
						[71.304950196853923, 22.247558265842798],
						[71.304947399792141, 22.247541683974607],
						[71.304947400165489, 22.247538899592271],
						[71.304947399876653, 22.247538890823325],
						[71.304947397748165, 22.247538869351207],
						[71.304947395519335, 22.247538862089545],
						[71.304944598201516, 22.247524973297658],
						[71.304908499300396, 22.247102784109639],
						[71.304908498816914, 22.247102778061425],
						[71.304908498333432, 22.247102772013257],
						[71.304908497655347, 22.247102768685799],
						[71.304889097793506, 22.246980569616639],
						[71.304889095759222, 22.246980559634284],
						[71.304889092174179, 22.246980545717815],
						[71.304889089945334, 22.246980538456228],
						[71.304772390246526, 22.246619338998727],
						[71.304772388695739, 22.246619335064608],
						[71.304772387144965, 22.246619331130525],
						[71.304772385594177, 22.246619327196431],
						[71.304736290668487, 22.246527741025353],
						[71.304708496330591, 22.246408259108879],
						[71.304619597079522, 22.24591386636213],
						[71.304619597274112, 22.245913863641423],
						[71.30461959552872, 22.245913862428132],
						[71.30461959572331, 22.24591385970739],
						[71.304605797242303, 22.245844366534541],
						[71.304602997954859, 22.245824970628146],
						[71.304602980023319, 22.24582492674751],
						[71.304602925827822, 22.245824851946466],
						[71.304602888691122, 22.245824820419344],
						[71.304597374081112, 22.245822063705468],
						[71.304475196171381, 22.245274960523034],
						[71.304425196911339, 22.24500556458651],
						[71.304425191775337, 22.245005546736834],
						[71.304425181114169, 22.245005516478884],
						[71.304425173843612, 22.245005502857389],
						[71.304364073817936, 22.244897101146819],
						[71.304364067225492, 22.244897090852714],
						[71.304364052295242, 22.244897069051277],
						[71.304364042890114, 22.244897059658054],
						[71.304247342822038, 22.244777660974229],
						[71.304247306168691, 22.244777635497698],
						[71.304247223934738, 22.244777606898705],
						[71.304247180294084, 22.244777602268702],
						[71.304219379615262, 22.244780400824158],
						[71.304219361866544, 22.244780405623075],
						[71.304219326369136, 22.244780415220927],
						[71.304219309298603, 22.244780423347144],
						[71.304066667205774, 22.244858092702248],
						[71.304013943200545, 22.244849805104181],
						[71.303886224054551, 22.244813726487404],
						[71.303789084421354, 22.244672114617011],
						[71.303752993134793, 22.244536048966204],
						[71.303752990905821, 22.244536041705299],
						[71.30375298557523, 22.244536026576995],
						[71.303752983346257, 22.244536019316119],
						[71.30371128786193, 22.244441629656784],
						[71.303605798198902, 22.244102761317421],
						[71.303569601828272, 22.243641712042745],
						[71.303589096065522, 22.243541739079216],
						[71.303589097132743, 22.243541736965113],
						[71.30363629702596, 22.24328893597864],
						[71.303636297998722, 22.243288922375626],
						[71.303636299071499, 22.243288894563086],
						[71.303636299171544, 22.243288880353575],
						[71.303605798663142, 22.242983280121074],
						[71.303605798179504, 22.242983274073346],
						[71.303605796145064, 22.242983264092132],
						[71.303605794788695, 22.242983257437924],
						[71.303541897324848, 22.242694365461844],
						[71.30350300066236, 22.24241939061892],
						[71.303527999560856, 22.241922210808671],
						[71.303527999949907, 22.241922205367477],
						[71.303527999660815, 22.241922196599244],
						[71.303528000049866, 22.241922191158043],
						[71.30352520038079, 22.241861093984575],
						[71.303516899750008, 22.241411098917556],
						[71.303516899737531, 22.241300003675477],
						[71.303530800191425, 22.240813906252047],
						[71.30353079951324, 22.240813902925066],
						[71.30353080009678, 22.240813894763235],
						[71.303530800291284, 22.240813892042624],
						[71.303527999882107, 22.240741691506475],
						[71.303528000076611, 22.240741688785864],
						[71.303522401045541, 22.240633317409312],
						[71.303558494553272, 22.240469443638347],
						[71.303558496882218, 22.240469436689214],
						[71.303558497854766, 22.240469423086104],
						[71.303558499116519, 22.240469418251241],
						[71.303583499254984, 22.240186118544088],
						[71.303583499449502, 22.240186115823438],
						[71.303583499643992, 22.240186113102808],
						[71.303583499643992, 22.240186113102808],
						[71.30358909888551, 22.240097222259781],
						[71.303664097468754, 22.239633331031122],
						[71.30366409863575, 22.239633314707238],
						[71.303664098351533, 22.239633280240454],
						[71.303664096027646, 22.239633261491239],
						[71.303566895923268, 22.239136061585306],
						[71.303566887684795, 22.239136035871461],
						[71.303566866066276, 22.23913599229477],
						[71.303566852008018, 22.239135971105018],
						[71.303550152199719, 22.239116570136151],
						[71.303550151327002, 22.239116569529852],
						[71.303550149776072, 22.239116565596614],
						[71.303550148708851, 22.239116567710933],
						[71.303502992197906, 22.239063816448287],
						[71.303475199274146, 22.23879717783624],
						[71.303475185510024, 22.238797139716738],
						[71.303475143918575, 22.238797067986592],
						[71.303475115896759, 22.23879703709655],
						[71.303389025805984, 22.238735944566848],
						[71.303250165868903, 22.238608180177248],
						[71.30321128297868, 22.23851931986302],
						[71.303211256218503, 22.238519284138917],
						[71.303211188051478, 22.238519225361504],
						[71.303211147322855, 22.238519205635004],
						[71.303200047107367, 22.238516506231647],
						[71.30320000220479, 22.238516506451191],
						[71.303199915790785, 22.238516523524524],
						[71.303199874957613, 22.238516543705199],
						[71.30303074492366, 22.23865242802642],
						[71.303003001155716, 22.238472189376374],
						[71.303030799562833, 22.237927811026125],
						[71.303030799757309, 22.23792780830555],
						[71.303030800146232, 22.237927802864402],
						[71.303030800340721, 22.237927800143833],
						[71.303030799598886, 22.237916699650594],
						[71.303033500346558, 22.237372201353928],
						[71.303033500057239, 22.237372192585962],
						[71.303033497538621, 22.237372176558178],
						[71.303033497249288, 22.237372167790237],
						[71.303030796928212, 22.237355568312218],
						[71.303030795571701, 22.237355561658646],
						[71.303030793536891, 22.23735555167827],
						[71.30303079218038, 22.237355545024666],
						[71.302933496166204, 22.237013857301775],
						[71.30289740243434, 22.236788898006179],
						[71.302941898344628, 22.236469428170796],
						[71.302941898733536, 22.236469422729613],
						[71.302941899122445, 22.236469417288461],
						[71.302941900384084, 22.236469412453477],
						[71.302955799788947, 22.23623331285367],
						[71.302955800177841, 22.23623330741248],
						[71.302955800277317, 22.236233293203369],
						[71.302955799793494, 22.236233287156068],
						[71.302933499905961, 22.235866687038293],
						[71.302933499422139, 22.235866680990998],
						[71.302933496708974, 22.235866667684085],
						[71.302933496225108, 22.235866661636777],
						[71.302894601642492, 22.235669390083309],
						[71.302902998870039, 22.235575018179155],
						[71.302902995094158, 22.235574981289258],
						[71.30290297367371, 22.235574909299949],
						[71.302902956901818, 22.235574874806655],
						[71.30287238885883, 22.235536017191937],
						[71.30282799949417, 22.235252777945128],
						[71.302819600356145, 22.235105589270809],
						[71.302819597159086, 22.235105569916847],
						[71.302819587757824, 22.235105534831721],
						[71.302819581748111, 22.235105516380059],
						[71.302697381868796, 22.234836017253102],
						[71.302697368682786, 22.234835996673514],
						[71.302697337558143, 22.234835957925032],
						[71.302697319619597, 22.234835939756177],
						[71.302619548570675, 22.234777661668062],
						[71.302519569265584, 22.234619294175168],
						[71.302500168716634, 22.234588793877524],
						[71.302500167165576, 22.234588789944851],
						[71.302500162996338, 22.234588784193971],
						[71.30250016144528, 22.234588780261298],
						[71.302452991175571, 22.234524923486543],
						[71.302419598179043, 22.234280572373589],
						[71.302419596822375, 22.234280565720383],
						[71.302419594109111, 22.234280552414077],
						[71.302419591879755, 22.23428054515486],
						[71.302344593576322, 22.234019351550121],
						[71.302336296687542, 22.233980565976122],
						[71.302333498173851, 22.233958274199637],
						[71.30233349633329, 22.233958261499435],
						[71.302333488966809, 22.23395823639547],
						[71.302333485186352, 22.233958225203718],
						[71.302166884865159, 22.23355552389436],
						[71.302166883314086, 22.233555519961815],
						[71.302166877788167, 22.233555507558275],
						[71.30216687536435, 22.233555503019755],
						[71.302072384257869, 22.233383218976705],
						[71.301991890333227, 22.233144337587071],
						[71.301991888782155, 22.233144333654618],
						[71.301991888103842, 22.233144330328173],
						[71.301991887231068, 22.233144329722169],
						[71.301864091337748, 22.232808240091956],
						[71.301841893153679, 22.232724947889761],
						[71.301841891602592, 22.232724943957383],
						[71.301841888694781, 22.232724933372108],
						[71.30184188801644, 22.232724930045695],
						[71.301814088357318, 22.232649930192562],
						[71.301814084382386, 22.232649921721862],
						[71.301814077305309, 22.232649905386424],
						[71.301814072263227, 22.232649899030225],
						[71.301711272253229, 22.232474898283286],
						[71.301711269829383, 22.232474893744996],
						[71.301711265660074, 22.232474887994847],
						[71.301711264108945, 22.232474884062491],
						[71.301639064329692, 22.23237208533779],
						[71.30163896463857, 22.232372044677497],
						[71.30163878657487, 22.232372075241976],
						[71.301638707329545, 22.232372145860815],
						[71.301574916982702, 22.232602349497899],
						[71.30145846104206, 22.232444279633949],
						[71.301458443103215, 22.232444261468508],
						[71.301458402278712, 22.232444230269287],
						[71.301458378520266, 22.232444216629524],
						[71.30133622662116, 22.232391537522755],
						[71.301280778468055, 22.23231930238817],
						[71.301241889352966, 22.232211033957658],
						[71.301102990267296, 22.231794337553971],
						[71.301052994959349, 22.231638852200568],
						[71.300994599316141, 22.231286076657295],
						[71.300977999681621, 22.231074987792304],
						[71.300955800893547, 22.230591723810878],
						[71.300977989529116, 22.230516764948444],
						[71.301050186638051, 22.230327869945107],
						[71.301050187705172, 22.23032786783056],
						[71.301050189839373, 22.230327863601413],
						[71.301050190906523, 22.23032786148687],
						[71.301114091362905, 22.230127860975539],
						[71.301114092818707, 22.230127853420168],
						[71.301114096797463, 22.230127836194825],
						[71.301114098253265, 22.23012782863945],
						[71.3011362984047, 22.229969427433705],
						[71.301136298892402, 22.229969407785049],
						[71.301136297055251, 22.229969369390787],
						[71.301136293857638, 22.229969350039351],
						[71.301116893635623, 22.229894348910594],
						[71.30111688985491, 22.229894337720346],
						[71.301116879675277, 22.229894313522482],
						[71.301116874149088, 22.229894301120666],
						[71.301066874043244, 22.229805500877301],
						[71.301066874043244, 22.229805500877301],
						[71.301066872297753, 22.229805499665712],
						[71.301066871619355, 22.22980549633953],
						[71.300939071546452, 22.229591597977091],
						[71.300939051377753, 22.229591572556696],
						[71.300939002213866, 22.229591529865758],
						[71.300938974091437, 22.229591513201012],
						[71.300883397005222, 22.229569223213698],
						[71.300761218012923, 22.229480438445076],
						[71.300761213649139, 22.229480435416189],
						[71.300761206667133, 22.22948043057001],
						[71.30076120317608, 22.229480428146886],
						[71.300733415211269, 22.229463736081293],
						[71.300627940170472, 22.229377655554305],
						[71.300594552450633, 22.229338769383773],
						[71.300594548959623, 22.22933876696073],
						[71.300594543917455, 22.229338760605838],
						[71.30059454217195, 22.229338759394313],
						[71.300580742304035, 22.229324858361878],
						[71.300580721357932, 22.229324843823616],
						[71.300580676653112, 22.229324815650102],
						[71.300580653378489, 22.229324808061325],
						[71.300427866841076, 22.22928311044727],
						[71.300402881439823, 22.229272015972281],
						[71.300402810255463, 22.229272012248455],
						[71.300402682621126, 22.229272055000187],
						[71.300402627238157, 22.229272099361154],
						[71.300383126951871, 22.229305498449563],
						[71.300383118026389, 22.229305520806538],
						[71.300383103666476, 22.229305567943584],
						[71.300383100171885, 22.229305591214825],
						[71.300380402141698, 22.229361050842847],
						[71.300219227666545, 22.229708196091472],
						[71.300069242118298, 22.229899876067414],
						[71.300069234842951, 22.229899888148562],
						[71.300069220292201, 22.22989991231082],
						[71.300069213889572, 22.229899924997717],
						[71.300052634004558, 22.229941577069823],
						[71.300038775240992, 22.229952643349101],
						[71.30003876040054, 22.229952658744754],
						[71.300038734405007, 22.229952689238939],
						[71.300038723055565, 22.229952707057706],
						[71.29993872359033, 22.230144303343852],
						[71.299858126311705, 22.230286001218701],
						[71.299858124177476, 22.230286005447581],
						[71.299858119909047, 22.230286013905342],
						[71.299858117774804, 22.230286018134191],
						[71.299827618037327, 22.230352717328827],
						[71.299827616970205, 22.230352719443239],
						[71.299827615903112, 22.230352721557683],
						[71.299827614835991, 22.230352723672098],
						[71.29979152015953, 22.230441612086398],
						[71.299674826629115, 22.230647097917014],
						[71.299602629263347, 22.230766597041274],
						[71.299602628196212, 22.230766599155665],
						[71.299602625189237, 22.230766602778637],
						[71.29960262412213, 22.23076660489302],
						[71.299494223624606, 22.230966605866541],
						[71.299494217027558, 22.230966621272898],
						[71.29949420664606, 22.230966651182797],
						[71.299494202667233, 22.230966668406516],
						[71.299491505761267, 22.230983245909549],
						[71.299310913553796, 22.23143602659475],
						[71.29931091335942, 22.231436029314867],
						[71.299310911225191, 22.231436033543524],
						[71.299310909963708, 22.231436038377975],
						[71.299280410440304, 22.231527735972623],
						[71.299280406655811, 22.231527750475916],
						[71.299280401899438, 22.231527778579824],
						[71.299280399860478, 22.231527794294774],
						[71.299269200865382, 22.231963877929189],
						[71.29924420667075, 22.232083247391426],
						[71.299127621489347, 22.23242210406163],
						[71.299077642466287, 22.232485976153736],
						[71.29907762665367, 22.232486005147141],
						[71.299077605890247, 22.232486064964064],
						[71.299077600066582, 22.232486095181653],
						[71.299074801238746, 22.232633238971623],
						[71.298944233089756, 22.232833190696788],
						[71.298944229015618, 22.232833196433525],
						[71.298944221740058, 22.232833208512879],
						[71.298944218344275, 22.232833217575539],
						[71.298788718280136, 22.233172118130607],
						[71.29878871614585, 22.233172122358923],
						[71.298788710615767, 22.233172135649788],
						[71.298788709159808, 22.233172143204019],
						[71.298760918314755, 22.233263812278963],
						[71.298708137580661, 22.233338783457068],
						[71.298580439335979, 22.233510982282816],
						[71.298580435261826, 22.233510988019297],
						[71.298580426046357, 22.233511001606427],
						[71.298580421777785, 22.233511010062898],
						[71.298477622086097, 22.233711009796899],
						[71.298477616361566, 22.233711025807366],
						[71.29847760491252, 22.233711057828359],
						[71.298477602000574, 22.233711072936494],
						[71.298455403727658, 22.233883260750865],
						[71.29839700802782, 22.234088844491868],
						[71.298397006766294, 22.234088849325918],
						[71.298397005310292, 22.234088856879932],
						[71.298397004243142, 22.234088858993992],
						[71.298374803663847, 22.234197159281571],
						[71.298374803275038, 22.234197164721497],
						[71.298374801819079, 22.234197172275522],
						[71.298374801624675, 22.234197174995483],
						[71.298366501966839, 22.23426387409668],
						[71.298366500899675, 22.234263876210726],
						[71.298324807128594, 22.234605534655802],
						[71.298213728013948, 22.234794299826547],
						[71.298213727141203, 22.23479429922061],
						[71.298213727141203, 22.23479429922061],
						[71.298213726074053, 22.234794301334627],
						[71.298202639232784, 22.234813779911768],
						[71.298077667593503, 22.234941549152879],
						[71.298033180000942, 22.234974840185554],
						[71.298033167294435, 22.234974851347875],
						[71.298033142365298, 22.234974879718273],
						[71.298033131209849, 22.234974894812378],
						[71.297910930875148, 22.235169294803732],
						[71.297910930002402, 22.235169294197799],
						[71.297910930002402, 22.235169294197799],
						[71.297852640181219, 22.23526377682553],
						[71.297777653701644, 22.235344262698742],
						[71.297777652634508, 22.235344264812692],
						[71.297777651761763, 22.235344264206745],
						[71.29777765156733, 22.235344266926617],
						[71.297672051694221, 22.235460965525512],
						[71.297672043740207, 22.235460974277302],
						[71.297672031323117, 22.235460994204608],
						[71.297672025114579, 22.235461004168283],
						[71.2975720251642, 22.235641603906085],
						[71.2975720251642, 22.235641603906085],
						[71.297572024097065, 22.235641606019996],
						[71.297572023029872, 22.235641608133868],
						[71.297491523028185, 22.235794307386886],
						[71.297491521088276, 22.235794308894818],
						[71.297491517692336, 22.235794317956294],
						[71.2974915166252, 22.23579432007018],
						[71.297452616864419, 22.235883220141904],
						[71.297452615797255, 22.235883222255779],
						[71.297452613662898, 22.235883226483523],
						[71.297452612595748, 22.235883228597402],
						[71.297308114054843, 22.236266627901223],
						[71.297241514922518, 22.236427724259222],
						[71.297241514922518, 22.236427724259222],
						[71.297241513855354, 22.236427726373048],
						[71.297124840875455, 22.236727658255912],
						[71.297002707000573, 22.236791523115464],
						[71.297002694682874, 22.236791528836552],
						[71.297002673343925, 22.236791545422353],
						[71.297002661704539, 22.236791554469175],
						[71.296944265897807, 22.236847050259584],
						[71.296810969762006, 22.23696094893932],
						[71.296810963069618, 22.236960952856691],
						[71.296810951041266, 22.236960967342721],
						[71.296810945027119, 22.236960974585767],
						[71.296763745314678, 22.237019273264952],
						[71.296763735031817, 22.237019288962919],
						[71.296763719413065, 22.237019315229535],
						[71.296763711942802, 22.237019330025667],
						[71.296585915662178, 22.237505520639317],
						[71.296583122270306, 22.237511008945184],
						[71.296583114216645, 22.23751103190002],
						[71.296583102667412, 22.237511078120029],
						[71.296583100239019, 22.237511099271451],
						[71.296583099922387, 22.237516574374538],
						[71.296577712204922, 22.237519219299667],
						[71.296577698820045, 22.23751922713388],
						[71.296577674668413, 22.237519244620273],
						[71.296577663028941, 22.237519253666445],
						[71.296402662854661, 22.237683154234443],
						[71.296402645684822, 22.23768317656841],
						[71.296402616581503, 22.23768322487231],
						[71.296402605715173, 22.237683248728555],
						[71.29634986562516, 22.237888618364209],
						[71.296222282841896, 22.237830419098042],
						[71.296222209039854, 22.237830413526684],
						[71.296222080336946, 22.237830458328119],
						[71.296222023885079, 22.237830504769338],
						[71.296113730426171, 22.238033194392994],
						[71.296041661012708, 22.238133013683314],
						[71.29601402521989, 22.238110943712307],
						[71.296013981678584, 22.238110924896795],
						[71.296013891489309, 22.238110905090931],
						[71.296013843579672, 22.238110908933781],
						[71.295861044229, 22.238155407103967],
						[71.295861004074254, 22.238155430604508],
						[71.295860939279279, 22.238155491232995],
						[71.29586091289363, 22.238155527149011],
						[71.29570261773425, 22.238566616249553],
						[71.295677623421525, 22.238613805428841],
						[71.295677620219934, 22.238613811769419],
						[71.295677614689509, 22.238613825056657],
						[71.295677612360663, 22.238613832003242],
						[71.295497027133237, 22.239110989543576],
						[71.295494258557625, 22.239113759911803],
						[71.295494244589221, 22.239113775903022],
						[71.295494220821539, 22.239113813635122],
						[71.295494211022273, 22.239113835376003],
						[71.295474815692373, 22.23916932171969],
						[71.295313721935784, 22.239483207979575],
						[71.295313719606895, 22.239483214925936],
						[71.295313715143607, 22.239483226099203],
						[71.295313712136462, 22.23948322972004],
						[71.29524982215564, 22.239655504600382],
						[71.295133169137458, 22.239816540299451],
						[71.295066616895653, 22.239847019110627],
						[71.295066608457731, 22.239847021814576],
						[71.295066592065638, 22.239847033267374],
						[71.295066583627701, 22.239847035971316],
						[71.294952683691321, 22.239927637660937],
						[71.294952675931654, 22.239927643690311],
						[71.294952658277836, 22.239927659975791],
						[71.294952650518141, 22.239927666005162],
						[71.294799876382925, 22.240099837221315],
						[71.294772110567607, 22.240113721656122],
						[71.294772097182616, 22.240113729487867],
						[71.294772071090861, 22.240113748476809],
						[71.294772059256843, 22.240113760240089],
						[71.294708158724148, 22.240177657792913],
						[71.294708157656927, 22.240177659906269],
						[71.29470815678421, 22.240177659300237],
						[71.29459155674077, 22.24029705840649],
						[71.294591549659287, 22.24029706776102],
						[71.294591535690799, 22.240297083750697],
						[71.294591530354722, 22.240297094317295],
						[71.294499833042423, 22.240444289808799],
						[71.294410937246312, 22.24056928404357],
						[71.294410935111898, 22.240569288270148],
						[71.29441093016483, 22.240569293397932],
						[71.294410928030388, 22.240569297624482],
						[71.294324827865836, 22.240713798175648],
						[71.294324826993105, 22.240713797569587],
						[71.294324824858677, 22.240713801796115],
						[71.294324824664187, 22.24071380451544],
						[71.294227625224394, 22.240891604897854],
						[71.294227621150029, 22.240891610631561],
						[71.294227615813938, 22.240891621197807],
						[71.294227613484992, 22.240891628143611],
						[71.294088713351513, 22.241255529764153],
						[71.294088712284278, 22.241255531877346],
						[71.294088708888125, 22.241255540936308],
						[71.294088707626386, 22.241255545768816],
						[71.294047008238394, 22.241399943770954],
						[71.294047007171187, 22.24139994588419],
						[71.294047006976669, 22.241399948603462],
						[71.294047005909448, 22.241399950716662],
						[71.293944206253755, 22.241805552583884],
						[71.293944205186534, 22.241805554697049],
						[71.293944203730277, 22.241805562248757],
						[71.293944202663027, 22.241805564361911],
						[71.293860902771868, 22.242280564818987],
						[71.293847002673971, 22.242358265292371],
						[71.293847002479453, 22.242358268011603],
						[71.293847002090402, 22.242358273450087],
						[71.293847002090402, 22.242358273450087],
						[71.293838701715202, 22.242422171756033],
						[71.293766501665573, 22.242913871294625],
						[71.293691502426313, 22.243413867910149],
						[71.293683102712052, 22.243466664488501],
						[71.293672004205916, 22.243519359051731],
						[71.293672004205916, 22.243519359051731],
						[71.293672004011384, 22.243519361770939],
						[71.293672002944135, 22.243519363883966],
						[71.293577604004312, 22.244019359773134],
						[71.293488706212159, 22.244411052682317],
						[71.293447020859134, 22.24456929404796],
						[71.29334162003795, 22.24466919887108],
						[71.293308299662357, 22.244669199132765],
						[71.293308242341979, 22.244669219256618],
						[71.293308147867734, 22.244669284929664],
						[71.293308111392065, 22.244669333804179],
						[71.293155411426454, 22.245108233100584],
						[71.293124811638222, 22.245194331755293],
						[71.29295811227847, 22.245655531861864],
						[71.29295811121122, 22.245655533974716],
						[71.29295811121122, 22.245655533974716],
						[71.292941512081541, 22.245702732015204],
						[71.292760911977567, 22.246199932213248],
						[71.292760911977567, 22.246199932213248],
						[71.292760910910303, 22.24619993432605],
						[71.292760909843011, 22.246199936438831],
						[71.292758110923842, 22.246208235858354],
						[71.292708112860709, 22.246352727477884],
						[71.292577615611648, 22.246658220563553],
						[71.292577615417059, 22.246658223282573],
						[71.292577614155221, 22.246658228114342],
						[71.292577613087929, 22.24665823022708],
						[71.292544213009634, 22.24674712832471],
						[71.29239421273904, 22.247144329384547],
						[71.292394211671734, 22.247144331497257],
						[71.292394210409881, 22.247144336328958],
						[71.292394210215278, 22.247144339047935],
						[71.29234703405929, 22.247291562800122],
						[71.292213804993906, 22.24736372403682],
						[71.292213775894282, 22.247363746632757],
						[71.292213727778105, 22.247363804537873],
						[71.292213709828829, 22.247363837734397],
						[71.292058111475626, 22.247833235695865],
						[71.292030410866587, 22.247913833845544],
						[71.291872010534888, 22.248377735160073],
						[71.291872010340285, 22.248377737878968],
						[71.291872009272993, 22.248377739991582],
						[71.291872009272993, 22.248377739991582],
						[71.291847009683764, 22.24845823829175],
						[71.291685911035842, 22.248924934711088],
						[71.29168590831739, 22.248924947092757],
						[71.291685903075106, 22.248924969137242],
						[71.291685901423946, 22.24892497940634],
						[71.291663703990878, 22.249149950455795],
						[71.291527616171564, 22.249474924493718],
						[71.291527615298861, 22.249474923887348],
						[71.291527614231583, 22.249474925999852],
						[71.291480414728483, 22.249594323907878],
						[71.29130541542348, 22.250019322868447],
						[71.291305415228848, 22.250019325587239],
						[71.291305414356145, 22.250019324980858],
						[71.291299814051172, 22.250033225762134],
						[71.291299813856526, 22.25003322848092],
						[71.291299811721899, 22.2500332327058],
						[71.291299810654607, 22.250033234818247],
						[71.291158110881511, 22.250441633977463],
						[71.291116526308343, 22.250560990691802],
						[71.291113759328695, 22.250563760037497],
						[71.291113740217497, 22.250563783860127],
						[71.291113712272548, 22.250563841500796],
						[71.291113701888122, 22.250563871387282],
						[71.291110903900318, 22.250583260942626],
						[71.290974805852116, 22.25111384931165],
						[71.290974805657484, 22.251113852030397],
						[71.290974805268178, 22.251113857467864],
						[71.290974804200872, 22.25111385958024],
						[71.290958104688713, 22.251194356748748],
						[71.290930404762534, 22.251316655191054],
						[71.290930404567888, 22.251316657909783],
						[71.290930404373228, 22.251316660628511],
						[71.290930404373228, 22.251316660628511],
						[71.290860903928092, 22.251666659273752],
						[71.290747010226454, 22.252205533490109],
						[71.290741521151389, 22.252216611000978],
						[71.290741516882051, 22.25221661944996],
						[71.290741511156028, 22.252216635448612],
						[71.290741508826699, 22.252216642391794],
						[71.290691508923047, 22.252380540700781],
						[71.290572010169001, 22.252763838508656],
						[71.290566509801778, 22.252780535933006],
						[71.290563718273148, 22.252788815953668],
						[71.290541530265415, 22.252824895827569],
						[71.290541524056039, 22.252824905782088],
						[71.290541515322616, 22.252824925398293],
						[71.290541511053235, 22.252824933847076],
						[71.290016510867474, 22.254363834436496],
						[71.290016509605394, 22.254363839267093],
						[71.290016507275993, 22.254363846209767],
						[71.290016506013927, 22.254363851040388],
						[71.289819211122548, 22.25512493012388],
						[71.289605420093963, 22.255566614443875],
						[71.289605417086548, 22.255566618061188],
						[71.289605412622365, 22.255566629227459],
						[71.289605410292907, 22.255566636169853],
						[71.289288711982394, 22.256511032234513],
						[71.28900811488009, 22.257202724868016],
						[71.289008114007416, 22.25720272426139],
						[71.289008113812685, 22.257202726979784],
						[71.288710914118937, 22.257944326264983],
						[71.288710913051517, 22.257944328376681],
						[71.288710910916734, 22.257944332600125],
						[71.288710909849343, 22.257944334711844],
						[71.288327610241964, 22.259083236980693],
						[71.288327607912379, 22.259083243922188],
						[71.288327604998543, 22.259083259018539],
						[71.288327602668957, 22.259083265960044],
						[71.288041504162521, 22.260738858093131],
						[71.287838705879977, 22.26153605219611],
						[71.287838705879977, 22.26153605219611],
						[71.287838704812557, 22.261536054307534],
						[71.287838704617755, 22.261536057025705],
						[71.287672005482733, 22.262286056372005],
						[71.287672004220497, 22.262286061201525],
						[71.28767200237381, 22.262286074185496],
						[71.287672001111545, 22.262286079015016],
						[71.287585901898851, 22.263141668894516],
						[71.287266507534468, 22.264541642225396],
						[71.287002613319686, 22.265233227973933],
						[71.287002610989859, 22.265233234914174],
						[71.287002607397767, 22.265233246683618],
						[71.287002606135431, 22.265233251512782],
						[71.286788705811219, 22.266102749934277],
						[71.286433106785822, 22.267427747978338],
						[71.286066507422632, 22.268772147180197],
						[71.286066506160253, 22.268772152008882],
						[71.286066504897832, 22.268772156837553],
						[71.286066503830298, 22.268772158948387],
						[71.285760904793918, 22.270269355529123],
						[71.285422006351808, 22.271602751981838],
						[71.28520540618743, 22.272447151174998],
						[71.284894206133018, 22.273661050395322],
						[71.284894204285607, 22.273661063376302],
						[71.284894200785686, 22.273661086620617],
						[71.284894200005823, 22.273661097491182],
						[71.284883100259037, 22.275130596931202],
						[71.284883106645097, 22.275130635615398],
						[71.284883134548608, 22.275130706394418],
						[71.284883156066059, 22.275130738489249],
						[71.284906685798816, 22.275155268662115],
						[71.284090971353933, 22.27694989378266],
						[71.283408508906263, 22.275969351133767],
						[71.283811298094847, 22.273238929530727],
						[71.283811298484764, 22.273238924095754],
						[71.283811298874681, 22.273238918660759],
						[71.283811300137216, 22.273238913832937],
						[71.283914099564981, 22.271761114412904],
						[71.283914100149801, 22.271761106260321],
						[71.283914100251906, 22.271761092065628],
						[71.283914098896531, 22.271761085416387],
						[71.283605800063256, 22.267674988035846],
						[71.283525199673647, 22.266038903539183],
						[71.283686299094626, 22.264233326451293],
						[71.284019594617305, 22.262158347514113],
						[71.284489089294865, 22.260750064404743],
						[71.284861280048375, 22.259663991080391],
						[71.285077964743309, 22.259347313167488],
						[71.285077980379981, 22.259347235587228],
						[71.285077951036044, 22.259347095356031],
						[71.285077907122826, 22.259347030593858],
						[71.284516807073587, 22.258991531740339],
						[71.284516782249881, 22.258991520192804],
						[71.284516729400309, 22.258991503431467],
						[71.284516701179641, 22.258991500935345],
						[71.283980645753473, 22.258988699183263],
						[71.28366121617853, 22.258835934958189],
						[71.282622339345963, 22.257827656462403],
						[71.282622311421065, 22.257827637057041],
						[71.282622250234283, 22.257827608801712],
						[71.282622216972413, 22.257827599951749],
						[71.282158317310959, 22.257788702415155],
						[71.282158261835988, 22.257788709510098],
						[71.282158168331833, 22.257788761497732],
						[71.282158128074116, 22.257788799136293],
						[71.281719227666613, 22.258530498737851],
						[71.28171922446441, 22.258530505070684],
						[71.281719215925264, 22.258530521958249],
						[71.28171921252833, 22.258530531008454],
						[71.280697012985797, 22.261255530589665],
						[71.280697010850943, 22.261255534811024],
						[71.280697009393975, 22.26125554235611],
						[71.280697007259135, 22.261255546577477],
						[71.279166508031622, 22.266777744032822],
						[71.277616508511414, 22.272005541963246],
						[71.276105409313573, 22.276994343137218],
						[71.276105407178392, 22.276994347355878],
						[71.276105403585589, 22.276994359116582],
						[71.276105403195587, 22.276994364549317],
						[71.27528870466449, 22.281480551304494],
						[71.274935913790657, 22.282599922472066],
						[71.274360936774755, 22.283716581234483],
						[71.271635960670537, 22.286430455053882],
						[71.269488763776323, 22.288416552579129],
						[71.269488762708647, 22.288416554687132],
						[71.269488759895822, 22.288416555580429],
						[71.269488758828146, 22.288416557688421],
						[71.26874988518, 22.289149830856573],
						[71.268425104172479, 22.289294134467919],
						[71.268311299522452, 22.289027758740982],
						[71.268305799789758, 22.288266699560339],
						[71.268302999841964, 22.287586100309646],
						[71.268302999554834, 22.287586091556875],
						[71.268302998108098, 22.287586073444142],
						[71.268302996948478, 22.287586064084167],
						[71.268216896587674, 22.287099966078777],
						[71.268216892328084, 22.287099948859609],
						[71.26821688012356, 22.287099914707497],
						[71.268216872178641, 22.287099897774564],
						[71.267936271703505, 22.286627696951744],
						[71.267936238454496, 22.286627662410439],
						[71.267936156721831, 22.286627614086285],
						[71.267936111050901, 22.286627599409858],
						[71.26748891132145, 22.286602599257908],
						[71.267488879898522, 22.286602603049246],
						[71.267488821025097, 22.286602619097952],
						[71.267488791634392, 22.286602632856006],
						[71.266272131064653, 22.287383108131039],
						[71.265814034079966, 22.287438681841984],
						[71.265408498055891, 22.286322160155532],
						[71.265366899848686, 22.285619392495313],
						[71.265283500066971, 22.281463897243068],
						[71.265283500262015, 22.281463894528265],
						[71.265283499102054, 22.281463885170073],
						[71.265283499297112, 22.281463882455267],
						[71.265241899326, 22.280999982973693],
						[71.265241897970952, 22.280999976330349],
						[71.265241897006021, 22.280999964257425],
						[71.265241895650988, 22.280999957614089],
						[71.264977999512382, 22.27974997193402],
						[71.264750199803174, 22.276124986036447],
						[71.264750198930599, 22.276124985429846],
						[71.264750199125572, 22.276124982715046],
						[71.264750199320559, 22.276124980000262],
						[71.264586299250553, 22.274430581571512],
						[71.263669598733642, 22.263838883264512],
						[71.263669598928445, 22.263838880549741],
						[71.263669599318035, 22.26383887512015],
						[71.263669597572786, 22.26383887390811],
						[71.262689098968963, 22.256547177181087],
						[71.262064099075332, 22.249533281209558],
						[71.262064099075332, 22.249533281209558],
						[71.262064097719161, 22.249533274569472],
						[71.262064097913736, 22.249533271854752],
						[71.26163349754566, 22.246508272396035],
						[71.261633497740206, 22.246508269681353],
						[71.261633497740206, 22.246508269681353],
						[71.261633497740206, 22.246508269681353],
						[71.261416898123358, 22.245127769142631],
						[71.261416897250655, 22.245127768537518],
						[71.261416896572499, 22.245127765217745],
						[71.261416896767017, 22.245127762503071],
						[71.260936296725873, 22.242488863732504],
						[71.259730796558614, 22.235438866975567],
						[71.25973079675299, 22.235438864261013],
						[71.259730796074663, 22.235438860941848],
						[71.259730796074663, 22.235438860941848],
						[71.259161297209502, 22.232613865493818],
						[71.258747398890222, 22.229872178756327],
						[71.258686299716629, 22.228655591407563],
						[71.258519600390713, 22.224380590884596],
						[71.258519600390713, 22.224380590884596],
						[71.258519599712145, 22.224380587565999],
						[71.258519599712145, 22.224380587565999],
						[71.258416899683695, 22.222805585390859],
						[71.258416899683695, 22.222805585390859],
						[71.258416898810921, 22.222805584786862],
						[71.258227999000241, 22.220155586401955],
						[71.258227999194418, 22.220155583687458],
						[71.258227998904104, 22.220155574940055],
						[71.258227998031316, 22.220155574336179],
						[71.258069599084038, 22.218988878966762],
						[71.257936298571934, 22.217197185859149],
						[71.257794599756892, 22.21510558441053],
						[71.257794598399443, 22.215105577774249],
						[71.257794595199982, 22.215105558469048],
						[71.257794593163823, 22.215105548514597],
						[71.257411292928282, 22.213663848406693],
						[71.25734739280982, 22.213422149217664],
						[71.257347327349677, 22.213422078314373],
						[71.257347165973499, 22.213422029394057],
						[71.25734707219128, 22.21342204715522],
						[71.257116571629382, 22.213613746432909],
						[71.2571165619309, 22.213613753969575],
						[71.257116543891399, 22.213613775678837],
						[71.257116535744501, 22.213613787137035],
						[71.2568220359381, 22.214035987205101],
						[71.256822029730827, 22.214035997155658],
						[71.256822019061971, 22.214036018263933],
						[71.256822013727529, 22.21403602881805],
						[71.256569213943237, 22.214686027587231],
						[71.256569210742569, 22.214686033919548],
						[71.256569208026505, 22.214686046284079],
						[71.256569205892717, 22.214686050505584],
						[71.256330406032262, 22.215655552686933],
						[71.256330403704354, 22.215655559622583],
						[71.256330401667, 22.215655575304758],
						[71.25633040089059, 22.215655586161944],
						[71.256219201057604, 22.217088882343369],
						[71.256072002132697, 22.218683267091585],
						[71.255783106821852, 22.219849945623178],
						[71.255335909274535, 22.221302740470211],
						[71.255335906752265, 22.221302750118916],
						[71.255335904035803, 22.221302762481727],
						[71.25533590170771, 22.221302769416361],
						[71.255147002459239, 22.222591670706471],
						[71.255147002070842, 22.222591676134581],
						[71.255147000615466, 22.222591683672846],
						[71.25514700042126, 22.222591686386888],
						[71.255058100706862, 22.223911080404545],
						[71.254872002889329, 22.225383262343872],
						[71.254583107970191, 22.226472142608387],
						[71.254030415983806, 22.228105521119463],
						[71.253610939249171, 22.228880477957876],
						[71.253250313907685, 22.229228213097347],
						[71.253097399731629, 22.223952793556837],
						[71.253097400120069, 22.223952788129321],
						[71.253097398957152, 22.223952778780337],
						[71.25309739827857, 22.223952775462703],
						[71.252266898846514, 22.217616679996233],
						[71.251883500196755, 22.210777790791276],
						[71.251622399496043, 22.197958305925447],
						[71.252089099413141, 22.191436113816323],
						[71.252089100286, 22.191436114418682],
						[71.252089099606891, 22.191436111102359],
						[71.252405800371335, 22.186027811204973],
						[71.25240580007943, 22.186027802460615],
						[71.252405798816341, 22.186027781655639],
						[71.252405797651505, 22.186027772309103],
						[71.251772399834039, 22.181441683653585],
						[71.251697400173072, 22.179238892716324],
						[71.251697400366652, 22.179238890002271],
						[71.251697399201461, 22.179238880656499],
						[71.251697398715663, 22.179238874626542],
						[71.251164098996227, 22.174855575836911],
						[71.251164098123255, 22.174855575235295],
						[71.25116409831675, 22.174855572521267],
						[71.251164097637329, 22.174855569205658],
						[71.250258498347904, 22.168997168323372],
						[71.250258410851842, 22.168997085278058],
						[71.250258219111004, 22.168997064246465],
						[71.250258115059609, 22.168997123546216],
						[71.249069218797629, 22.171902715044983],
						[71.24825813269554, 22.173444289469113],
						[71.247410950674976, 22.17446926666253],
						[71.246344402541254, 22.175580309071222],
						[71.244950166693883, 22.174058187182332],
						[71.243950179761541, 22.172016611797822],
						[71.243950157158807, 22.172016581999429],
						[71.243950102062911, 22.172016532668664],
						[71.243950069763201, 22.17201651042323],
						[71.243208369654681, 22.171741510970119],
						[71.243208284699122, 22.171741520771569],
						[71.243208151943449, 22.171741597270426],
						[71.243208103076981, 22.171741666079654],
						[71.242794203212142, 22.174097165417081],
						[71.242794201952208, 22.174097170241549],
						[71.24279420098479, 22.174097183805888],
						[71.242794199724855, 22.174097188630331],
						[71.242613700097962, 22.177377789571832],
						[71.242613699904439, 22.177377792284634],
						[71.242613699517378, 22.177377797710236],
						[71.242613700196785, 22.177377801024477],
						[71.242608099824864, 22.180386087942196],
						[71.242280402610092, 22.183277763907384],
						[71.24145810591385, 22.186486049883303],
						[71.241458102812402, 22.186486067667538],
						[71.241458100974071, 22.186486106244978],
					],
					[
						[71.290477279370663, 22.274601455474201],
						[71.29055294304068, 22.274439067285275],
						[71.29099165518592, 22.274308522496291],
						[71.29106412570934, 22.274368554182946],
						[71.291044311870678, 22.274380420334328],
						[71.290477279370663, 22.274601455474201],
					],
					[
						[71.291451212971836, 22.274136774325139],
						[71.291516864402624, 22.273994522431497],
						[71.292055506866078, 22.273522549126291],
						[71.292126661844378, 22.273732328391599],
						[71.291451212971836, 22.274136774325139],
					],
				],
			],
		},
		{
			fldNpdidField: "43527",
			wlbNpdidWellbore: "179",
			fldName: "ELDFISK",
			fldCurrentActivitySatus: "Producing",
			wlbName: "2/7-1",
			fldDiscoveryYear: "1970",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43527",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43527",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "018",
			cmpLongName: "ConocoPhillips Skandinavia AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=179",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "2410696",
			fldNpdidOwner: "20900",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Tananger",
			fldAreaGeometryWKT: [
				[
					[56.377202599540063, 3.3154500093498274],
					[56.377535899885018, 3.3219056093562958],
					[56.37753590338118, 3.3219056266791123],
					[56.3775359102995, 3.3219056597135648],
					[56.377535914615628, 3.3219056752917919],
					[56.379810915380432, 3.3274889744581277],
					[56.379810928185599, 3.3274889985062308],
					[56.379810960726061, 3.3274890407023392],
					[56.379810981429316, 3.3274890603283724],
					[56.383891581125724, 3.3305251602173898],
					[56.383891603320933, 3.3305251731347085],
					[56.383891651885747, 3.3305251918573107],
					[56.383891677435258, 3.3305251994072922],
					[56.389252776826112, 3.3311501987908181],
					[56.389252805582075, 3.3311501977505826],
					[56.389252861830464, 3.3311501877448477],
					[56.389252890216902, 3.3311501786461108],
					[56.393186189862469, 3.3291751797593561],
					[56.393186208192859, 3.3291751672887795],
					[56.393186241129804, 3.3291751396571065],
					[56.393186255736346, 3.3291751244960217],
					[56.396727956053958, 3.3247307248428037],
					[56.396727958661948, 3.324730722830525],
					[56.396727962835918, 3.3247307157153849],
					[56.39672796454991, 3.3247307138365358],
					[56.396808465365737, 3.3246140142511313],
					[56.396808470359694, 3.3246140053905489],
					[56.39680848034763, 3.3246139876693532],
					[56.396808485267613, 3.3246139771967496],
					[56.399375185447354, 3.3184778773594048],
					[56.399375187013092, 3.3184778722559392],
					[56.399375190070465, 3.318477860436932],
					[56.399375191636189, 3.3184778553334686],
					[56.400972391903935, 3.3129139545124118],
					[56.400972394289241, 3.3129139476626621],
					[56.400972397271921, 3.3129139342305782],
					[56.400972397869275, 3.3129139276482613],
					[56.40225299814076, 3.3036583275362816],
					[56.402252998663343, 3.3036583193414564],
					[56.402252999634236, 3.3036583013396337],
					[56.402253000231092, 3.3036582947569819],
					[56.402019600075619, 3.2960388932876965],
					[56.402019598661056, 3.2960388821368189],
					[56.40201959478923, 3.2960388567448957],
					[56.40201959248072, 3.2960388457281451],
					[56.400566892441638, 3.290947145669723],
					[56.400566878437701, 3.2909471153076839],
					[56.400566840894228, 3.2909470625085184],
					[56.400566817280236, 3.2909470384593109],
					[56.398433417214143, 3.2894026372799776],
					[56.398433397845771, 3.2894026272037213],
					[56.398433358364016, 3.2894026104094052],
					[56.398433337282235, 3.2894026022136469],
					[56.395144436937784, 3.2887804022456595],
					[56.395144409896254, 3.2887804014379891],
					[56.395144357079452, 3.2887804077475611],
					[56.395144330335782, 3.288780413387224],
					[56.391441629782733, 3.2901581136143951],
					[56.391441619204336, 3.2901581184482711],
					[56.391441597153587, 3.2901581282502459],
					[56.391441585830137, 3.2901581364417001],
					[56.388102686283332, 3.2924748356150477],
					[56.388102675853609, 3.2924748436709028],
					[56.38810265685651, 3.2924748611258594],
					[56.388102648289113, 3.2924748705249751],
					[56.384083148294572, 3.2971970697974919],
					[56.384083144047835, 3.2971970753016917],
					[56.384083134660422, 3.2971970864441271],
					[56.384083132201596, 3.2971970916803093],
					[56.381477632219358, 3.3012581924014888],
					[56.381477627972437, 3.3012581979048639],
					[56.381477621340778, 3.3012582102550945],
					[56.3814776180621, 3.3012582172358584],
					[56.378885917956048, 3.3069638175938643],
					[56.378885913931462, 3.3069638279298119],
					[56.378885906850527, 3.306963850079145],
					[56.378885903719912, 3.3069638602813551],
					[56.377202603649017, 3.3154499616916575],
					[56.377202602379754, 3.3154499732367975],
					[56.377202599915343, 3.3154499979382352],
					[56.377202599540063, 3.3154500093498274],
				],
				[
					[
						[56.356363701015738, 3.2682250194307723],
						[56.357152600967332, 3.2763778186169175],
						[56.357152604693376, 3.2763778407565618],
						[56.357152619147676, 3.2763778807406112],
						[56.357152628162687, 3.2763779004639257],
						[56.360124827897053, 3.2814418005912724],
						[56.360124842349414, 3.2814418211221188],
						[56.360124876319617, 3.2814418549369644],
						[56.36012489501806, 3.2814418699655383],
						[56.365641595413486, 3.2848334693891323],
						[56.365641629382992, 3.2848334837505031],
						[56.365641698439468, 3.2848334977096645],
						[56.365641734420365, 3.2848334971732345],
						[56.370261133980776, 3.2840251964194511],
						[56.370261148283717, 3.2840251942715124],
						[56.37026117487823, 3.2840251854118296],
						[56.370261188063736, 3.2840251785658232],
						[56.373833387652049, 3.2822640794559725],
						[56.373833404338626, 3.2822640704605015],
						[56.373833433018525, 3.2822640483083561],
						[56.373833446874251, 3.2822640364939799],
						[56.377577946616384, 3.2782084354592556],
						[56.37757796404189, 3.2782083257687176],
						[56.377577884249042, 3.2782081576944599],
						[56.37757778703071, 3.2782080993107505],
						[56.377500198368772, 3.2782129884448961],
						[56.38354456127972, 3.2700501188740048],
						[56.383544567163618, 3.2700501098750809],
						[56.383544577962795, 3.2700500904005581],
						[56.38354458295278, 3.2700500815362599],
						[56.388672375103532, 3.2584778987835357],
						[56.392375111559438, 3.2531862880204785],
						[56.398494356650389, 3.2524835052785148],
						[56.405663800138363, 3.2566418733357403],
						[56.405663810270674, 3.2566418782987312],
						[56.405663831354275, 3.256641886477488],
						[56.405663840592695, 3.2566418915755451],
						[56.412058241140222, 3.2586029908536926],
						[56.412058273769411, 3.2586029956665055],
						[56.412058337609828, 3.2586029941399506],
						[56.412058370608818, 3.2586029875304732],
						[56.418872270587819, 3.2560362868892607],
						[56.418872294348112, 3.2560362751765068],
						[56.418872336130889, 3.2560362444981892],
						[56.418872355047242, 3.256036225397462],
						[56.425050154665811, 3.2484168254570469],
						[56.425050162335566, 3.2484168161738936],
						[56.425050174099638, 3.2484167981490191],
						[56.425050179906641, 3.2484167875236052],
						[56.429769579899855, 3.238744488264806],
						[56.429769582280848, 3.2387444814053477],
						[56.429769587117981, 3.2387444692995966],
						[56.429769590392823, 3.2387444623045272],
						[56.432316889816377, 3.231136163352601],
						[56.43231689219693, 3.231136156492223],
						[56.432316895245599, 3.2311361446563249],
						[56.432316895838461, 3.2311361380675505],
						[56.43508349567928, 3.2170111386541311],
						[56.435083497165202, 3.2170111319285688],
						[56.43508349924322, 3.2170111186135673],
						[56.435083499910746, 3.2170111136374717],
						[56.435452999994567, 3.2111972132835791],
						[56.435452999164013, 3.2111971955370828],
						[56.435452995866285, 3.2111971635432943],
						[56.435452993323615, 3.2111971476826846],
						[56.433586292660422, 3.2043388474077026],
						[56.433586281543555, 3.2043388214837942],
						[56.433586253279984, 3.2043387754307031],
						[56.433586236057693, 3.2043387536882912],
						[56.428947336003837, 3.2000470524698805],
						[56.428947297178553, 3.2000470307823101],
						[56.428947214997322, 3.2000470059693678],
						[56.428947170671918, 3.2000470013675075],
						[56.422441671098881, 3.2010026017558824],
						[56.422441645405691, 3.2010026105513965],
						[56.422441595958119, 3.2010026310949633],
						[56.422441573173195, 3.2010026443193089],
						[56.417502673175157, 3.2050581443500774],
						[56.417502663721173, 3.2050581539124825],
						[56.417502645707003, 3.2050581729009866],
						[56.417502637146825, 3.205058182327067],
						[56.410685936702457, 3.2145692834598227],
						[56.410685935884082, 3.2145692852081775],
						[56.410685935141125, 3.2145692885688155],
						[56.410685934247304, 3.2145692887048956],
						[56.4051470336831, 3.2228804890124039],
						[56.405147030333758, 3.2228804943920437],
						[56.405147022891882, 3.2228805085112957],
						[56.405147018648705, 3.22288051402679],
						[56.402052632046072, 3.2293831864190223],
						[56.398983231620704, 3.2327275779917088],
						[56.396144512410402, 3.2320498208889017],
						[56.393105755397826, 3.2283192737035247],
						[56.393105732892145, 3.2283192543974852],
						[56.393105684606368, 3.2283192227743727],
						[56.39310565875104, 3.2283192088457091],
						[56.388463958553039, 3.2268859084732004],
						[56.388463916984755, 3.2268859050460899],
						[56.388463836463721, 3.2268859156456462],
						[56.38846379564805, 3.2268859283322913],
						[56.384408195694384, 3.2293470283340042],
						[56.384408178344053, 3.2293470423244481],
						[56.384408145656714, 3.2293470748677851],
						[56.384408131138379, 3.2293470916738656],
						[56.378669235753435, 3.238335985459377],
						[56.373355445282286, 3.2452276745176767],
						[56.36941925107854, 3.2498026676807581],
						[56.365305454144192, 3.2542165629401683],
						[56.358722055134344, 3.2611554615450702],
						[56.35872204157986, 3.2611554798028162],
						[56.35872201901514, 3.2611555172549114],
						[56.358722010004939, 3.2611555364492606],
						[56.35636370979568, 3.2682249370402094],
						[56.356363706222488, 3.2682249570345956],
						[56.356363700938601, 3.2682249983644152],
						[56.356363701015738, 3.2682250194307723],
					],
					[
						[56.377489927601161, 3.2782140364402066],
						[56.377499364207083, 3.2782134415766744],
						[56.377497125972205, 3.2782164646235787],
						[56.377489927601161, 3.2782140364402066],
					],
				],
			],
		},
		{
			fldNpdidField: "29396445",
			wlbNpdidWellbore: "863",
			fldName: "TRESTAKK",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6406/3-2",
			fldDiscoveryYear: "1986",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=29396445",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=29396445",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "091",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=863",
			fldHcType: "OIL",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "21532",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Kristiansund",
			fldAreaGeometryWKT: [
				[
					[64.815447003296853, 6.801933262262561],
					[64.815447004878564, 6.8019333084865901],
					[64.815447028601554, 6.8019333950446539],
					[64.815447050711668, 6.8019334332742609],
					[64.817158151151844, 6.8038390344460531],
					[64.817158160242329, 6.8038390421338946],
					[64.817158178423256, 6.8038390575095624],
					[64.817158186617135, 6.8038390652704415],
					[64.819002687290563, 6.805102965504962],
					[64.819002697246489, 6.8051029710160327],
					[64.819002718054904, 6.8051029819651951],
					[64.819002728907435, 6.8051029874032842],
					[64.824769329385262, 6.8072779875854739],
					[64.824769391375085, 6.807277990972687],
					[64.824769504005232, 6.8072779586246632],
					[64.824769554676593, 6.8072779249945734],
					[64.824980755289772, 6.8070168265804929],
					[64.824980768428063, 6.8070168044345003],
					[64.824980789356133, 6.8070167626853744],
					[64.824980796187162, 6.8070167389448928],
					[64.825183496282776, 6.8060139397632105],
					[64.825183497086087, 6.806013933374671],
					[64.825183498723902, 6.8060139227027827],
					[64.825183498630636, 6.8060139163872542],
					[64.82546408318656, 6.8024724200169642],
					[64.82621933565045, 6.8025529943710028],
					[64.828835984685739, 6.8040807646668258],
					[64.834430464302642, 6.8087112477453644],
					[64.836080444716174, 6.8104334273437095],
					[64.837997029866642, 6.8131668060527177],
					[64.84084702366448, 6.8184389945989645],
					[64.840847027312719, 6.8184389985213043],
					[64.840847031950346, 6.818439008690266],
					[64.840847033805389, 6.8184390127578434],
					[64.842177634105767, 6.8204001122764408],
					[64.842177638681434, 6.8204001182331746],
					[64.842177649656975, 6.8204001321080447],
					[64.842177655129277, 6.8204001379922303],
					[64.843622054969401, 6.8219140371224372],
					[64.843622095748927, 6.8219140633519135],
					[64.843622185068782, 6.821914094091742],
					[64.843622233578273, 6.8219140964954557],
					[64.844969356649642, 6.8216835106831653],
					[64.845416547387302, 6.8219695400845826],
					[64.845944214303898, 6.8229917744478685],
					[64.846677607740332, 6.8254528579433851],
					[64.846677612377476, 6.8254528681157476],
					[64.846677620755145, 6.8254528885328893],
					[64.846677626288894, 6.825452898632836],
					[64.84976651079684, 6.8308694725460475],
					[64.850352603272341, 6.8335028354812106],
					[64.850788694212241, 6.8365193752449569],
					[64.850630413631791, 6.83691382705564],
					[64.850630409424596, 6.8369138463807921],
					[64.850630401937508, 6.8369138870662347],
					[64.850630399554205, 6.836913908354501],
					[64.850741499056696, 6.8394249913924492],
					[64.850199801778132, 6.8436027730986924],
					[64.8501998010954, 6.843602787920827],
					[64.850199800596087, 6.8436028153861042],
					[64.850199801706623, 6.8436028300645928],
					[64.850610901616363, 6.8464528285618078],
					[64.850610911937011, 6.8464528593811389],
					[64.85061094058689, 6.8464529161598211],
					[64.850610959812741, 6.846452942047442],
					[64.856388759628032, 6.8521390440768783],
					[64.856388773229625, 6.852139053541908],
					[64.856388801329402, 6.8521390724004521],
					[64.856388815827628, 6.8521390817939416],
					[64.858480516334794, 6.8531140813329117],
					[64.858480523568645, 6.853114084976343],
					[64.858480539799174, 6.8531140900122951],
					[64.858480547929688, 6.853114093584213],
					[64.862127710274223, 6.8540918829976274],
					[64.863655451384645, 6.8552612373043811],
					[64.864591526012916, 6.8567139996461419],
					[64.867041521898514, 6.8615000919736246],
					[64.867041527399564, 6.8615000999800673],
					[64.867041536608326, 6.8615001161355025],
					[64.867041543005982, 6.8615001240706661],
					[64.871363730087751, 6.867005707817718],
					[64.874310920142747, 6.8730278872824186],
					[64.874310922923101, 6.8730278933969968],
					[64.874310928423498, 6.8730279014079496],
					[64.874310931173682, 6.8730279054134371],
					[64.875924831010295, 6.875597305066349],
					[64.875924905287334, 6.8755973519886524],
					[64.875925059930381, 6.8755973693336898],
					[64.875925142986318, 6.875597339543881],
					[64.876436243068227, 6.8750779398388335],
					[64.876436253647142, 6.8750779263328141],
					[64.876436274714578, 6.8750778929929277],
					[64.876436284366733, 6.8750778774484642],
					[64.876764083597664, 6.8743028786107834],
					[64.876764089633028, 6.8743028612401087],
					[64.876764097220644, 6.8743028268531639],
					[64.876764099669487, 6.8743028097660277],
					[64.876955799181701, 6.8702972323308913],
					[64.877200181626407, 6.8694111933892286],
					[64.877816829363709, 6.8686390584161181],
					[64.878583276988834, 6.8682474365958788],
					[64.879641577038882, 6.8690779567736824],
					[64.879641667961209, 6.8690779749100637],
					[64.879641826121357, 6.869077924359333],
					[64.879641890699517, 6.8690778579949505],
					[64.880861148393635, 6.8650783278397771],
					[64.883372023301192, 6.8699083921548869],
					[64.883372026978307, 6.8699083982001321],
					[64.88337203525937, 6.8699084123294281],
					[64.883372039863332, 6.8699084204134397],
					[64.884541539792409, 6.8714751193092161],
					[64.884541546189539, 6.8714751272518351],
					[64.884541558056981, 6.8714751410982062],
					[64.88454156535073, 6.8714751489698225],
					[64.885799864587867, 6.8726251485894734],
					[64.885799877261363, 6.8726251560359595],
					[64.885799901741905, 6.8726251731098973],
					[64.885799915312035, 6.8726251804854144],
					[64.887905515253578, 6.8736084802605086],
					[64.887905527896905, 6.8736084855978445],
					[64.887905552256754, 6.8736084942332631],
					[64.887905564869897, 6.8736084974604026],
					[64.888969364803373, 6.8737973976410531],
					[64.888969445289433, 6.873797376484414],
					[64.888969561572878, 6.8737972848906193],
					[64.888969599999925, 6.8737972100200881],
					[64.889486299642797, 6.864575010378382],
					[64.88948629844343, 6.864574989346675],
					[64.889486290634665, 6.8645749456004701],
					[64.889486284952184, 6.8645749249250159],
					[64.888833497104443, 6.8629943524036676],
					[64.888700201764124, 6.8612944202614408],
					[64.889700192059877, 6.8577750544541294],
					[64.88970019376201, 6.8577750479802555],
					[64.889700197135909, 6.8577750329222269],
					[64.889700198807688, 6.8577750243380518],
					[64.890269594781643, 6.8530611490760647],
					[64.890894585577783, 6.8514750722728328],
					[64.890894589878144, 6.8514750592520439],
					[64.89089459668557, 6.8514750333538545],
					[64.890894599192691, 6.8514750204764479],
					[64.891577998922827, 6.8446583193530337],
					[64.891577998708925, 6.844658304579994],
					[64.891577998281136, 6.8446582750339529],
					[64.891577996274023, 6.8446582604047563],
					[64.890805797678269, 6.8408943698326059],
					[64.890472399222986, 6.8378805781076526],
					[64.890472398265075, 6.8378805739591115],
					[64.890472396287919, 6.8378805614413869],
					[64.890472395299369, 6.8378805551825339],
					[64.890100194763718, 6.836224955409099],
					[64.890100190993223, 6.8362249430360684],
					[64.890100180731721, 6.8362249163962776],
					[64.890100175137349, 6.8362249020573405],
					[64.889383475077935, 6.8349332032880463],
					[64.889383461321856, 6.8349331832691629],
					[64.889383430315277, 6.8349331498511052],
					[64.889383413034139, 6.8349331343416857],
					[64.888050158812646, 6.8340248677011344],
					[64.887816896931625, 6.8335027500951186],
					[64.887761302155539, 6.8327639201351511],
					[64.888047383575966, 6.8317444841023685],
					[64.888680646391052, 6.8307752957698158],
					[64.889683172724503, 6.831597354913086],
					[64.889683216099868, 6.8315973746531418],
					[64.889683307953618, 6.8315973947067636],
					[64.889683356401235, 6.8315973929100524],
					[64.890336156093724, 6.8314084915577293],
					[64.890336190846241, 6.8314084739634682],
					[64.890336251170027, 6.8314084247263525],
					[64.890336275875455, 6.8314083952661688],
					[64.89115847608781, 6.8298778939868052],
					[64.891158483045302, 6.8298778786348517],
					[64.891158494239704, 6.8298778460377871],
					[64.891158497610775, 6.8298778309754873],
					[64.891391898295751, 6.8283694320138117],
					[64.891391898853712, 6.8283694087270002],
					[64.891391894620767, 6.8283693646985597],
					[64.891391890695701, 6.8283693417740716],
					[64.891027991154544, 6.8271749419650378],
					[64.891027983735242, 6.8271749256621366],
					[64.891027967072702, 6.8271748910909951],
					[64.891027956963612, 6.8271748750056194],
					[64.890633456597172, 6.8266748759727252],
					[64.890633374358984, 6.8266748382549247],
					[64.89063321198087, 6.8266748450498138],
					[64.890633132737548, 6.8266748894899649],
					[64.889361032267203, 6.8286108382144519],
					[64.888689047307736, 6.8282581512464153],
					[64.888155787970888, 6.8272638264300669],
					[64.887561303116399, 6.8249832852566987],
					[64.887733496271636, 6.8233889430345505],
					[64.88814183391213, 6.8222030253092472],
					[64.889241665930996, 6.8223945966800681],
					[64.889241738432403, 6.8223945823539891],
					[64.889241853106384, 6.8223945054542821],
					[64.889241895309851, 6.8223944449908673],
					[64.889350194873586, 6.8219306441987513],
					[64.889350196110982, 6.8219306060693476],
					[64.889350183312686, 6.8219305289357335],
					[64.88935016930786, 6.8219304920417638],
					[64.887327983759121, 6.8187416170012964],
					[64.887139101120795, 6.8180888764124754],
					[64.887233499669506, 6.8157083078034661],
					[64.88723349957661, 6.8157083014733058],
					[64.887233500287451, 6.8157082887401268],
					[64.887233499266969, 6.8157082803727924],
					[64.887133498817406, 6.8146471800756778],
					[64.887133496838331, 6.8146471675612457],
					[64.8871334892318, 6.8146471386039433],
					[64.887133485397555, 6.8146471220152325],
					[64.886233485360208, 6.8124749226256798],
					[64.886233477073759, 6.8124749085127076],
					[64.886233459635278, 6.8124748824696884],
					[64.886233451348815, 6.8124748683567189],
					[64.884705750811406, 6.810722069424858],
					[64.884705729879329, 6.8107220500061318],
					[64.884705686377146, 6.8107220218639339],
					[64.884705661055165, 6.8107220091398917],
					[64.882305711582305, 6.8099498252283697],
					[64.881694578069144, 6.8092748984041807],
					[64.880583497736978, 6.8056027647222477],
					[64.88054190128625, 6.8048583124191113],
					[64.880822396688131, 6.8033306351938956],
					[64.88082239823224, 6.8033306181711284],
					[64.880822398630599, 6.8033305843453755],
					[64.880822396588286, 6.8033305676156504],
					[64.880616897012061, 6.8020638675896157],
					[64.880616888568838, 6.8020638429352251],
					[64.880616866271708, 6.802063791956769],
					[64.880616850686877, 6.8020637699983375],
					[64.880280751016087, 6.8016776688492682],
					[64.880280696690775, 6.8016776373870904],
					[64.880280578707456, 6.8016776111304811],
					[64.880280516873739, 6.8016776182988981],
					[64.87994711705781, 6.801830416970633],
					[64.879947084226657, 6.8018304428872209],
					[64.879947032106713, 6.8018304999490944],
					[64.879947011055904, 6.8018305333504747],
					[64.879302624997493, 6.8036720926381875],
					[64.878863826260599, 6.8041330866277345],
					[64.87806398827928, 6.8040192092662775],
					[64.877214054860474, 6.8032637709502479],
					[64.875239133197383, 6.8000860969240389],
					[64.876994572761092, 6.7970501001925729],
					[64.876994585679071, 6.7970500632338924],
					[64.876994596273036, 6.7970499905653714],
					[64.876994593917757, 6.7970499527462946],
					[64.876441893930945, 6.7947971530310909],
					[64.876441891053602, 6.7947971405966481],
					[64.876441881712637, 6.7947971160218961],
					[64.87644187707339, 6.7947971058436938],
					[64.875939077280989, 6.7938527067821521],
					[64.875939069024042, 6.793852694789428],
					[64.875939050717022, 6.7938526709511091],
					[64.875939040666907, 6.7938526591055126],
					[64.87507514134046, 6.7929887591787423],
					[64.875075123158425, 6.7929887437780332],
					[64.87507508330188, 6.7929887195981413],
					[64.875075061627371, 6.7929887108189773],
					[64.874280661748003, 6.7927304104162491],
					[64.874280616793499, 6.7927304056600395],
					[64.874280529887059, 6.7927304170168812],
					[64.874280487935167, 6.7927304331299148],
					[64.873824887570223, 6.7930387338281353],
					[64.873824871619334, 6.7930387478064551],
					[64.873824843335186, 6.7930387775777215],
					[64.873824830136641, 6.793038795553235],
					[64.872847072188804, 6.7946220263745021],
					[64.872058361378635, 6.7948219784237427],
					[64.871205765822296, 6.7939748830264932],
					[64.869819581034079, 6.7910249147953916],
					[64.869819574601422, 6.7910249047676023],
					[64.869819561736108, 6.7910248847120265],
					[64.869819555303451, 6.791024874684239],
					[64.869252955070039, 6.7903276730487132],
					[64.869252906121929, 6.7903276411800366],
					[64.869252797039863, 6.7903276100293377],
					[64.869252736905892, 6.7903276107472959],
					[64.868772136761379, 6.7904859109322846],
					[64.868772105568738, 6.7904859261615274],
					[64.868772050575387, 6.7904859707907796],
					[64.868772027639949, 6.7904859980085526],
					[64.868199895953254, 6.7914553821109509],
					[64.867725138595375, 6.7913831191419884],
					[64.866714082914626, 6.7890666199199092],
					[64.866714079202936, 6.7890666117810587],
					[64.866714070882935, 6.7890665955770695],
					[64.866714066306002, 6.7890665896203126],
					[64.865386277182722, 6.7870832041392326],
					[64.864333484882465, 6.784563822180246],
					[64.864333480273984, 6.7845638141165852],
					[64.864333470128955, 6.7845637959548615],
					[64.864333464623812, 6.7845637879650216],
					[64.863136265064227, 6.7828165866251764],
					[64.863136259590462, 6.7828165807441767],
					[64.863136251332662, 6.7828165687604889],
					[64.863136245858897, 6.7828165628794688],
					[64.861644546429417, 6.7812331626945612],
					[64.861644524628431, 6.7812331454970742],
					[64.861644479327609, 6.7812331175739926],
					[64.861644454034646, 6.7812331069962504],
					[64.86115835405667, 6.7810970079642372],
					[64.861158320788789, 6.7810970043761483],
					[64.861158257099987, 6.7810970075179204],
					[64.861158224885955, 6.7810970143956615],
					[64.86000830638433, 6.781563681982651],
					[64.858616784903788, 6.7809109197421069],
					[64.858616702292409, 6.7809109181133369],
					[64.858616564045548, 6.7809109780520895],
					[64.8586165093066, 6.7809110395456971],
					[64.857449907157303, 6.7846052292875862],
					[64.856405739703845, 6.7835887560164032],
					[64.856405701670354, 6.7835887338266536],
					[64.856405621371749, 6.7835887066770661],
					[64.856405577313453, 6.7835887018649279],
					[64.854555650580195, 6.7837997940321451],
					[64.854100152350483, 6.7834887609180665],
					[64.853166881811347, 6.7817888155355011],
					[64.851297386027639, 6.7769721276904082],
					[64.851297378602922, 6.7769721114253105],
					[64.851297361095334, 6.7769720812244598],
					[64.851297350115814, 6.7769720673627152],
					[64.85085014988988, 6.7764637683096396],
					[64.850850134395898, 6.7764637527115044],
					[64.850850099916173, 6.7764637281328772],
					[64.8508500817955, 6.776463716971203],
					[64.849030731839804, 6.775649839371658],
					[64.846736263560274, 6.7723470863136015],
					[64.846736256229804, 6.7723470763729772],
					[64.846736238879132, 6.7723470567142776],
					[64.84673622885893, 6.7723470469962024],
					[64.843308457728469, 6.7694387711360671],
					[64.842291887095612, 6.7675832232312993],
					[64.841425193083552, 6.7644499479873197],
					[64.841425190267145, 6.7644499397850346],
					[64.841425184665908, 6.7644499254868773],
					[64.84142518274605, 6.7644499172101664],
					[64.84075578339035, 6.7629555175980753],
					[64.840755776955817, 6.7629555075877219],
					[64.840755764055103, 6.7629554854606813],
					[64.840755756723993, 6.7629554755248042],
					[64.839964057321041, 6.7619581768849262],
					[64.839964046340157, 6.7619581630350751],
					[64.839964021815518, 6.7619581439840211],
					[64.839964009969776, 6.7619581323149438],
					[64.838180731175271, 6.7607887477498387],
					[64.836958460259723, 6.7594415794985601],
					[64.834908474062544, 6.7561888013415734],
					[64.833214078394519, 6.7528638081435579],
					[64.833214072887756, 6.7528638001693571],
					[64.833214061842341, 6.7528637821151909],
					[64.833214056335578, 6.7528637741409705],
					[64.830972373914335, 6.7500776972645209],
					[64.829944584815649, 6.7475999239388456],
					[64.82994457834819, 6.7475999118306813],
					[64.829944563588228, 6.7475998856587651],
					[64.829944556256137, 6.7475998757310149],
					[64.8292140562409, 6.7466915739932611],
					[64.829214047115627, 6.7466915642159773],
					[64.829214027103845, 6.7466915469167734],
					[64.829214016185418, 6.746691537289446],
					[64.827752960846254, 6.7456526689082956],
					[64.826041884355647, 6.7416916210791307],
					[64.826041869626664, 6.7416915970193916],
					[64.826041835781908, 6.7416915555910295],
					[64.826041816634088, 6.7416915361172594],
					[64.82536401693028, 6.7412054369219199],
					[64.825363989809361, 6.7412054244406079],
					[64.825363932973914, 6.7412054060187137],
					[64.825363904155893, 6.7412054000029897],
					[64.822630693725898, 6.741152601357614],
					[64.822002951722041, 6.7404137719862778],
					[64.822002908142821, 6.7404137398117721],
					[64.822002810705825, 6.7404137079372566],
					[64.82200275678403, 6.7404137040275804],
					[64.821686150989024, 6.7404830847367228],
					[64.820052925762425, 6.7391581445452564],
					[64.820052824098653, 6.7391581298909236],
					[64.820052661757273, 6.7391581982984512],
					[64.820052601079766, 6.7391582813603241],
					[64.819330400668221, 6.7465832819670473],
					[64.819330400668221, 6.7465832819670473],
					[64.819330400732071, 6.7465832861763371],
					[64.819330399835493, 6.746583286251286],
					[64.81862480119959, 6.7576110799524995],
					[64.817527602426594, 6.7652416721179618],
					[64.817527600950058, 6.7652416933118547],
					[64.817527605201278, 6.7652417372095952],
					[64.817527609104218, 6.7652417579575657],
					[64.818716509232132, 6.7691194582828542],
					[64.818716510191948, 6.769119462417895],
					[64.818716513008098, 6.7691194706138074],
					[64.818716513967914, 6.769119474748849],
					[64.820041514411827, 6.77244447299823],
					[64.820041517196231, 6.772444479090165],
					[64.820041521868518, 6.772444491348101],
					[64.82004152465295, 6.7724444974400146],
					[64.821297024968388, 6.7747139965140635],
					[64.82129702682461, 6.7747140005756847],
					[64.82129703233025, 6.774714008550907],
					[64.821297035083035, 6.7747140125385101],
					[64.823466527881834, 6.7779001020391707],
					[64.824444213599151, 6.7798167763674524],
					[64.82717760922452, 6.7885611609848002],
					[64.827177612936197, 6.7885611691117411],
					[64.827177621287547, 6.7885611873973479],
					[64.827177625927177, 6.7885611975560165],
					[64.830099800878813, 6.7937750541845237],
					[64.830135898879462, 6.7956777716939722],
					[64.829524838275304, 6.7976331477098313],
					[64.828800047160669, 6.7979108689551691],
					[64.827541861052069, 6.7967054769642239],
					[64.825766875000085, 6.7934638034861417],
					[64.825766854868576, 6.7934637777334661],
					[64.825766804836817, 6.7934637333512375],
					[64.825766776729765, 6.7934637145748669],
					[64.824513976813407, 6.7929442160096531],
					[64.824513922861428, 6.7929442098894972],
					[64.824513819846942, 6.7929442246485037],
					[64.824513770815727, 6.7929442476327733],
					[64.822605569696222, 6.7945525650444649],
					[64.821472286185795, 6.7940137191207164],
					[64.821472244754915, 6.7940137098668423],
					[64.821472163039772, 6.7940137081246981],
					[64.821472121858932, 6.7940137157098039],
					[64.820616622212924, 6.7943748156933301],
					[64.820616588454811, 6.7943748395291896],
					[64.820616532781855, 6.7943748988760344],
					[64.820616510866969, 6.7943749343870019],
					[64.820188711263469, 6.7955943334294746],
					[64.820188707061561, 6.795594352739049],
					[64.820188701285119, 6.7955943869287063],
					[64.820188699773027, 6.7955944060183358],
					[64.820246999179844, 6.7974971736090719],
					[64.820116610815674, 6.797927403403917],
					[64.818591847896485, 6.7962387656668071],
					[64.818591843319822, 6.7962387597193317],
					[64.818591832404536, 6.7962387500755987],
					[64.818591826034719, 6.7962387442746897],
					[64.817052925919953, 6.7949915455285872],
					[64.817052834092266, 6.7949915277539255],
					[64.817052674998067, 6.7949915744792113],
					[64.817052608659353, 6.7949916410103564],
					[64.8162942085699, 6.7974443413439998],
					[64.816294206839132, 6.7974443456994331],
					[64.816294205201956, 6.7974443563682989],
					[64.816294202605775, 6.797444362901472],
					[64.815447003296853, 6.801933262262561],
				],
			],
		},
		{
			fldNpdidField: "24635035",
			wlbNpdidWellbore: "238",
			fldName: "FLYNDRE",
			fldCurrentActivitySatus: "Producing",
			wlbName: "1/5-2",
			fldDiscoveryYear: "1974",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=24635035",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=24635035",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "FLYNDRE UNIT",
			cmpLongName: "NEO Energy Production UK Limited",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=238",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "37817009",
			fldNpdidOwner: "29395339",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[56.549735899628217, 2.6337943958018228],
					[56.549735901684663, 2.6337944182814881],
					[56.549735912047403, 2.633794462187494],
					[56.549735918651145, 2.6337944855312245],
					[56.550110918669311, 2.6345917842954543],
					[56.550110935260314, 2.6345918092174148],
					[56.55011097533567, 2.634591853008287],
					[56.550110997927206, 2.6345918720276607],
					[56.550344234365994, 2.6347307335333299],
					[56.550533101732348, 2.6352944388915245],
					[56.550624799719088, 2.6366528047131887],
					[56.550580399818301, 2.6381055936742257],
					[56.550580400233606, 2.6381056017566697],
					[56.550580400898092, 2.6381056146885702],
					[56.55058040123032, 2.6381056211545313],
					[56.550719200809851, 2.6394167207051642],
					[56.550719203093806, 2.6394167301034535],
					[56.550719205792973, 2.6394167475842392],
					[56.550719208076913, 2.6394167569825324],
					[56.550999803221167, 2.6403528374999587],
					[56.551094201073617, 2.6414306174768867],
					[56.551094201571715, 2.6414306271760246],
					[56.551094206139304, 2.6414306459730597],
					[56.551094208423109, 2.6414306553715741],
					[56.551283107941941, 2.6420861552065404],
					[56.551283130718701, 2.6420861953962107],
					[56.55128319176243, 2.6420862617546081],
					[56.551283230922245, 2.6420862877730329],
					[56.55179706171895, 2.6422751613982869],
					[56.552219223144732, 2.6430250935641317],
					[56.552594221078031, 2.6437750886453211],
					[56.552594252699819, 2.6437751257182147],
					[56.552594330291974, 2.6437751811436776],
					[56.552594375286517, 2.6437751980299278],
					[56.553719374960089, 2.6439140980368916],
					[56.553719375935934, 2.6439140995032706],
					[56.553719376828774, 2.643914099353009],
					[56.553719377721642, 2.6439140992027452],
					[56.554141542307939, 2.6439612840639453],
					[56.554280406946042, 2.644477851535938],
					[56.554280430780942, 2.6444778948132508],
					[56.554280498322477, 2.6444779649787544],
					[56.554280541053295, 2.6444779904005471],
					[56.555030459347023, 2.6447112659771101],
					[56.555405421418016, 2.6454611885003581],
					[56.555405423535618, 2.6454611946667601],
					[56.555405430449333, 2.6454612065488545],
					[56.555405434269666, 2.6454612107980764],
					[56.555780433711085, 2.6460223118473318],
					[56.555780450860972, 2.6460223301608861],
					[56.555780488649212, 2.6460223645703325],
					[56.555780510097449, 2.6460223788992878],
					[56.556341610385452, 2.6463057781835184],
					[56.556341631584786, 2.64630578766254],
					[56.556341677057155, 2.6463057963191567],
					[56.556341699793322, 2.6463058006474656],
					[56.556810936406215, 2.64630579935266],
					[56.556952602003264, 2.6470083299392355],
					[56.557091499577126, 2.6483194124744527],
					[56.55713870024973, 2.6501472053808386],
					[56.557138699688508, 2.6501472119982021],
					[56.557138702220406, 2.6501472262491976],
					[56.557138702552052, 2.6501472327164084],
					[56.557280402987054, 2.6509889328547755],
					[56.557280412597905, 2.6509889622232166],
					[56.557280439689563, 2.6509890163754615],
					[56.557280458956107, 2.6509890408590175],
					[56.557608111354519, 2.6513167932913682],
					[56.557747001713352, 2.6523028285006216],
					[56.557747003996369, 2.6523028379014817],
					[56.557747008645293, 2.6523028583200365],
					[56.557747011904084, 2.6523028691876571],
					[56.557935912351269, 2.6528167694323468],
					[56.557935922274446, 2.6528167873337711],
					[56.557935944882246, 2.6528168243032586],
					[56.557935959269749, 2.6528168414543067],
					[56.558310959384229, 2.6531918410470197],
					[56.558310979128898, 2.6531918572979598],
					[56.558311021858181, 2.653191882732119],
					[56.558311044842839, 2.6531918919153155],
					[56.559622028225739, 2.6535668597593345],
					[56.55971650425456, 2.6540361387884284],
					[56.559716531179305, 2.654036189712464],
					[56.559716609034723, 2.6540362679557856],
					[56.559716659072564, 2.6540362954251364],
					[56.560605458061239, 2.6542223748030307],
					[56.560888710458322, 2.6548778645800648],
					[56.561027605573855, 2.6554417472855971],
					[56.561027618838054, 2.6554417776749659],
					[56.561027653153751, 2.6554418322524631],
					[56.561027675098124, 2.6554418562905489],
					[56.561260975404501, 2.6556279556709006],
					[56.561260986698841, 2.6556279651895545],
					[56.56126101261048, 2.6556279787757564],
					[56.561261025607728, 2.6556279863773704],
					[56.5614971257882, 2.6557223865692281],
					[56.561497127573944, 2.6557223862691739],
					[56.561497133989725, 2.6557223884529986],
					[56.561497135858303, 2.6557223897699491],
					[56.561919219328715, 2.6558640495465262],
					[56.561963702302464, 2.6561917270722062],
					[56.561963711102152, 2.6561917582126791],
					[56.561963740143163, 2.6561918153093074],
					[56.561963760384508, 2.656191841265465],
					[56.562152660093751, 2.6563779411983872],
					[56.562152691859559, 2.6563779635875453],
					[56.562152761788653, 2.6563779926123408],
					[56.562152799951932, 2.6563779992480123],
					[56.562808287070347, 2.6563779993656023],
					[56.564308274950065, 2.6565668973523544],
					[56.56430828136584, 2.6565668995364486],
					[56.564308294031704, 2.6565669006703967],
					[56.564308300281816, 2.6565668996202314],
					[56.56627500018319, 2.6565668998684626],
					[56.566275019909199, 2.6565668981849133],
					[56.56627506000558, 2.6565668898161472],
					[56.566275078590209, 2.6565668834309792],
					[56.566930679168294, 2.6562834837249967],
					[56.566930689717069, 2.6562834786899767],
					[56.566930709838914, 2.6562834671527242],
					[56.566930720304846, 2.6562834605004495],
					[56.567305720433062, 2.6560029597698045],
					[56.567305722135941, 2.6560029578524431],
					[56.567305727327494, 2.6560029537176089],
					[56.567305729030387, 2.6560029518002315],
					[56.567636062374845, 2.6557224943463811],
					[56.568197032252201, 2.6565195084989783],
					[56.568524827156075, 2.6570807017727835],
					[56.568524862263111, 2.6570807366543221],
					[56.568524943090537, 2.6570807850591764],
					[56.568524988893749, 2.6570808001997976],
					[56.569413793580551, 2.6571279943074297],
					[56.569788738016513, 2.6576445167155383],
					[56.569788749559223, 2.6576445310888865],
					[56.569788776050494, 2.6576445560007129],
					[56.569788790106188, 2.6576445666892221],
					[56.570072084852576, 2.6578307636466278],
					[56.570447035890844, 2.6581112262520703],
					[56.570680404998306, 2.6586750517968789],
					[56.570774802202905, 2.6593778261917098],
					[56.570774814011791, 2.6593778633597758],
					[56.570774850608693, 2.6593779273589275],
					[56.570774874586625, 2.659377955957424],
					[56.571008175073835, 2.6595640560950287],
					[56.571008198306536, 2.6595640701369585],
					[56.571008246226505, 2.6595640914511205],
					[56.571008270913744, 2.6595640987233589],
					[56.571663871094543, 2.6596584986282719],
					[56.571663874666058, 2.6596584980282993],
					[56.571663882784733, 2.6596584982958218],
					[56.571663887331916, 2.6596584991633159],
					[56.572413837106687, 2.6597057957838657],
					[56.572788736715644, 2.6599390341377154],
					[56.572930412796701, 2.6603139710445367],
					[56.572930435237105, 2.6603140047976832],
					[56.57293049131134, 2.6603140622665955],
					[56.572930524862379, 2.6603140843648676],
					[56.573163824520051, 2.6604084843290541],
					[56.573163842874351, 2.6604084910345205],
					[56.573163881037523, 2.6604084976754065],
					[56.57316390003632, 2.6604084993783177],
					[56.573397200298679, 2.6604085003349032],
					[56.573397237716733, 2.6604084924180196],
					[56.573397309129092, 2.6604084624764157],
					[56.573397341337625, 2.6604084407516688],
					[56.573677940684206, 2.6601279410716443],
					[56.573677949281773, 2.6601279331015433],
					[56.573677961929775, 2.6601279162937708],
					[56.573677967848724, 2.6601279087736458],
					[56.574008468198969, 2.6596112083338883],
					[56.574008474762365, 2.6596111958109465],
					[56.574008486103381, 2.6596111710650834],
					[56.574008490798228, 2.6596111572245875],
					[56.574336291048304, 2.6585333579126846],
					[56.574336291941172, 2.6585333577626504],
					[56.574336292668413, 2.6585333543774885],
					[56.574336293478474, 2.6585333526098904],
					[56.574475179456137, 2.6580195044555155],
					[56.57461684062244, 2.6578779418037097],
					[56.57461684832699, 2.6578779339829421],
					[56.574616861057478, 2.6578779187915562],
					[56.574616866976285, 2.6578779112709041],
					[56.574802967230589, 2.6575973113785683],
					[56.574802979298404, 2.6575972832463681],
					[56.574802996291062, 2.6575972281824076],
					[56.57480300024018, 2.6575971997831433],
					[56.574802999696516, 2.656941700656108],
					[56.574802997809563, 2.6569416813952027],
					[56.574802990381407, 2.6569416418561236],
					[56.574802985005824, 2.6569416248131055],
					[56.574569584838429, 2.6563777235517496],
					[56.574569583862711, 2.6563777220842701],
					[56.574569582887001, 2.6563777206167885],
					[56.574569582804159, 2.6563777189992215],
					[56.57438350012761, 2.6559555573062323],
					[56.574383499712617, 2.6555361002080979],
					[56.574383499049773, 2.6555360872676199],
					[56.574383496996916, 2.6555360647719106],
					[56.574383494631199, 2.6555360537492119],
					[56.574147394723255, 2.654549953367118],
					[56.574147391464578, 2.6545499424946679],
					[56.574147383161481, 2.6545499210500538],
					[56.57414737900995, 2.6545499103277264],
					[56.573866879207927, 2.6539888116166011],
					[56.573866857907376, 2.6539887825690029],
					[56.573866806874847, 2.6539887356803997],
					[56.57386677697712, 2.6539887146043215],
					[56.573305677231012, 2.6537554144328581],
					[56.573305665375123, 2.6537554115321762],
					[56.573305641663339, 2.6537554057307977],
					[56.573305629807443, 2.6537554028300994],
					[56.572366735036333, 2.653613702749845],
					[56.571664024062976, 2.6534720202205988],
					[56.571194575397641, 2.6527221021571497],
					[56.570680791109744, 2.6516916355008866],
					[56.570491896133042, 2.6507082623180591],
					[56.570491893766906, 2.6507082512968152],
					[56.570491888307622, 2.6507082326392535],
					[56.570491884238699, 2.6507082235357755],
					[56.570119592583403, 2.6498193439832032],
					[56.569883498757839, 2.6484138767559631],
					[56.569836298938306, 2.6477555865411979],
					[56.569836297132973, 2.6477555689007164],
					[56.569836289223922, 2.647755537605744],
					[56.569836283930123, 2.6477555221836631],
					[56.569697393394549, 2.6474277439999141],
					[56.569508498282957, 2.646305568888883],
					[56.5693695976586, 2.6453666709264505],
					[56.569369596267848, 2.6453666613728877],
					[56.569369590724797, 2.6453666410994106],
					[56.569369587548344, 2.6453666318464655],
					[56.568994600423075, 2.6443360654346697],
					[56.568994599657962, 2.6432583006612407],
					[56.568994600301771, 2.6432582956590012],
					[56.568994599969734, 2.6432582891898515],
					[56.568994598910862, 2.6432582861056231],
					[56.568900204769584, 2.6419472583850223],
					[56.569275136827116, 2.6414307510301533],
					[56.569836190383825, 2.6411029785333273],
					[56.570491779412713, 2.6408223842494416],
					[56.570491809831793, 2.6408223628109431],
					[56.570491862945921, 2.6408223098159622],
					[56.570491883855283, 2.6408222785603348],
					[56.570727921925126, 2.6402613264935253],
					[56.571711063174327, 2.6404473963904476],
					[56.571711126649205, 2.6404473873262302],
					[56.571711237237267, 2.6404473311699928],
					[56.571711281588847, 2.6404472829119006],
					[56.572227982187911, 2.6393222830760648],
					[56.572227986880435, 2.6393222692338987],
					[56.57222799537265, 2.6393222417000222],
					[56.572227998279502, 2.639322228158786],
					[56.572366891883945, 2.6383389677636737],
					[56.572647335087581, 2.6378695647802139],
					[56.573163967765026, 2.6376834875114752],
					[56.573163973122035, 2.637683486608366],
					[56.573163985455594, 2.6376834812661936],
					[56.573163989836715, 2.6376834788961498],
					[56.573444490286995, 2.6375418784015099],
					[56.573444520622331, 2.6375418553411003],
					[56.57344456983116, 2.6375417964693981],
					[56.573444589763646, 2.63754176374252],
					[56.573586063101317, 2.637120139065555],
					[56.573724804654795, 2.6377306446299285],
					[56.573724819958358, 2.6377306795740298],
					[56.57372486273286, 2.6377307408850439],
					[56.573724890203806, 2.6377307672519721],
					[56.574008190367174, 2.6379168661350958],
					[56.574008230898315, 2.6379168837746212],
					[56.574008314285699, 2.6379168941892783],
					[56.574008357391165, 2.6379168918168725],
					[56.574475057287287, 2.637777991782619],
					[56.574475080168718, 2.6377779813989641],
					[56.574475122110989, 2.6377779563812385],
					[56.57447514117181, 2.6377779417471694],
					[56.574664041310996, 2.6375890411400249],
					[56.574664057360927, 2.6375890204873076],
					[56.574664082961654, 2.6375889753830286],
					[56.57466409259554, 2.6375889525489962],
					[56.574802855864647, 2.6370755585821457],
					[56.575130420781008, 2.6377306894438068],
					[56.575130437288664, 2.6377307127653746],
					[56.575130472650109, 2.6377307524867826],
					[56.575130494265515, 2.6377307700525656],
					[56.575505427883918, 2.6379640276248706],
					[56.57564700733468, 2.6384806533414711],
					[56.575647033933699, 2.6384806978043573],
					[56.575647106026466, 2.6384807688608021],
					[56.575647152329971, 2.6384807936862891],
					[56.576208111394301, 2.6386195594545958],
					[56.576255401389972, 2.6392750131565816],
					[56.576255400663264, 2.6392750165422654],
					[56.576255400829403, 2.6392750197774344],
					[56.576255400829403, 2.6392750197774344],
					[56.576302601307766, 2.6397444199551821],
					[56.576302608159928, 2.6397444481688903],
					[56.576302629816794, 2.6397445016243393],
					[56.576302644621471, 2.6397445268660675],
					[56.57649154509042, 2.6399779252577011],
					[56.576491550862762, 2.6399779324428003],
					[56.576491564193134, 2.6399779465120323],
					[56.576491570858323, 2.6399779535466488],
					[56.576772071201269, 2.6402140529107072],
					[56.576772088912776, 2.6402140646101135],
					[56.576772125955358, 2.640214084472718],
					[56.576772146179273, 2.640214092485464],
					[56.577099848133535, 2.6403057643626777],
					[56.577238712101028, 2.6406806690231459],
					[56.577238720157318, 2.6406806856133516],
					[56.577238736913642, 2.6406807137903896],
					[56.577238745779745, 2.6406807286124776],
					[56.577474846266341, 2.64096402750652],
					[56.577474865202817, 2.6409640455265966],
					[56.577474906314954, 2.6409640744942857],
					[56.577474927680839, 2.6409640872100022],
					[56.578083169361307, 2.6411973638968465],
					[56.578458133891196, 2.641758411008861],
					[56.578458156814143, 2.6417584365164006],
					[56.578458211983111, 2.6417584761703732],
					[56.578458241550585, 2.6417584907681553],
					[56.578927742005902, 2.6419001908935957],
					[56.578927750290617, 2.6419001943926608],
					[56.578927766610903, 2.6419001965376467],
					[56.578927774812591, 2.6419001984190063],
					[56.579302749384532, 2.6419473952519859],
					[56.579535962167249, 2.6420418604956217],
					[56.579863726537226, 2.6426029003180389],
					[56.579863755149226, 2.6426029313953565],
					[56.579863818768729, 2.6426029777866908],
					[56.579863855644959, 2.6426029944175862],
					[56.58028596807624, 2.6426973753536998],
					[56.580519225218005, 2.6431194975731809],
					[56.580519240748899, 2.6431195194329886],
					[56.580519278704358, 2.6431195570957979],
					[56.580519301211972, 2.643119574516573],
					[56.580847095825739, 2.6433057706925132],
					[56.581130453372758, 2.6434918426992611],
					[56.581502628755196, 2.6441029046205724],
					[56.581502650701871, 2.6441029286640845],
					[56.58150270238184, 2.6441029705439001],
					[56.58150273122228, 2.6441029885306171],
					[56.581877731037238, 2.6442418885951811],
					[56.58187774833339, 2.6442418922086963],
					[56.581877781949821, 2.6442418979682865],
					[56.581877800055814, 2.6442418998135282],
					[56.582113875213885, 2.6442419002075126],
					[56.582488850781964, 2.644336293830472],
					[56.582488856222078, 2.6443362945458455],
					[56.58248886531657, 2.6443362962774288],
					[56.58248886897097, 2.6443362972936368],
					[56.58309706674013, 2.6444307826303834],
					[56.583472011117209, 2.6451806704172922],
					[56.583566506256055, 2.6455556496578221],
					[56.583566518547144, 2.645555678593575],
					[56.583566553677549, 2.6455557314245546],
					[56.583566574731144, 2.6455557556205509],
					[56.58379987519104, 2.6457418556444199],
					[56.583799887379037, 2.6457418650148625],
					[56.583799912398895, 2.6457418787515796],
					[56.583799925313762, 2.6457418847357799],
					[56.584033225420839, 2.6458362845712373],
					[56.584033232812608, 2.6458362882219562],
					[56.584033248323024, 2.6458362921371053],
					[56.584033255714814, 2.6458362957878214],
					[56.58445540017474, 2.64593076014877],
					[56.584455399849581, 2.6461639003751931],
					[56.584455400347515, 2.6461639100829633],
					[56.584455402236209, 2.6461639293480919],
					[56.584455403709967, 2.6461639405234187],
					[56.584644204340059, 2.6471000403908862],
					[56.584644213786312, 2.6471000665431226],
					[56.584644239821621, 2.6471001176447002],
					[56.584644258113428, 2.6471001406753425],
					[56.584830430129493, 2.6472890132885647],
					[56.585063714555019, 2.6478500769916891],
					[56.585063725289039, 2.6478500931357702],
					[56.585063745864218, 2.6478501255742799],
					[56.585063759276785, 2.6478501412673205],
					[56.585299858969762, 2.6480862413419408],
					[56.585299886106974, 2.6480862612520313],
					[56.585299946133595, 2.6480862903117868],
					[56.58529997902302, 2.6480862994614669],
					[56.585722178783037, 2.6481307988175113],
					[56.585722184223116, 2.6481307995334644],
					[56.585722195020317, 2.6481307993473702],
					[56.58572219956757, 2.6481308002136634],
					[56.58600269964775, 2.6481307992774017],
					[56.586283132209125, 2.6485057091968072],
					[56.586469225839551, 2.6488333997472937],
					[56.58646923267024, 2.6488334100213566],
					[56.586469249985932, 2.6488334315861821],
					[56.586469258602314, 2.6488334415595851],
					[56.586658159373741, 2.6490223416035397],
					[56.586658209412889, 2.6490223690831778],
					[56.586658316843646, 2.6490223917959965],
					[56.586658375045161, 2.6490223852607717],
					[56.587127874579814, 2.6488334853189515],
					[56.587127908571631, 2.6488334632750568],
					[56.587127965094368, 2.6488334064285248],
					[56.587127986732455, 2.6488333717762482],
					[56.58736128733586, 2.6482250714385356],
					[56.58736129040949, 2.6482250611285942],
					[56.587361295580955, 2.6482250390409683],
					[56.587361297761731, 2.6482250288813631],
					[56.587455795418784, 2.6475694509506611],
					[56.587547357698398, 2.6473362457145457],
					[56.587736147174901, 2.6472419004188819],
					[56.58811108236538, 2.6472418994516427],
					[56.588624920233606, 2.6473362896778059],
					[56.588952614136048, 2.6475695124240786],
					[56.589094199199003, 2.6486000049353047],
					[56.588999801192905, 2.6497249826609184],
					[56.588999799738971, 2.6497249894342172],
					[56.588999799592621, 2.6497250041480327],
					[56.588999799924402, 2.6497250106206938],
					[56.58904699966331, 2.6506139118261989],
					[56.589047001053771, 2.6506139213849091],
					[56.589047004810467, 2.6506139419701968],
					[56.589047007176717, 2.6506139529967707],
					[56.58918870726923, 2.6511306527995595],
					[56.589188713621482, 2.6511306713159284],
					[56.589188732576062, 2.6511307072966201],
					[56.589188744285494, 2.6511307249112295],
					[56.58937483838853, 2.6513640177794846],
					[56.589469191105159, 2.6515056464488209],
					[56.589422001812707, 2.6518332711652346],
					[56.589422011130694, 2.6518333299862884],
					[56.589422062304983, 2.6518334323590085],
					[56.589422103185505, 2.6518334744427396],
					[56.589844303022538, 2.6520668749090923],
					[56.589844335101823, 2.6520668858315584],
					[56.589844399655711, 2.6520668978169564],
					[56.589844432940296, 2.6520668971114194],
					[56.590125032877289, 2.6520195962594317],
					[56.590125056901613, 2.6520195905837047],
					[56.590125101774149, 2.6520195699738003],
					[56.590125123598163, 2.652019556507542],
					[56.590361223825418, 2.6518334567297464],
					[56.590361231612398, 2.651833450522501],
					[56.590361247103445, 2.6518334364898015],
					[56.590361253914665, 2.6518334288146095],
					[56.590594553686479, 2.651552928147757],
					[56.59059457102682, 2.6515528974815905],
					[56.590594593851598, 2.6515528332483114],
					[56.59059460022889, 2.6515527995309269],
					[56.590594599732555, 2.6514112995772581],
					[56.590780477302246, 2.6514113000835429],
					[56.590922009109804, 2.6516917656519401],
					[56.590969204016524, 2.6519278390250025],
					[56.590969233787384, 2.651927892773684],
					[56.590969320739752, 2.6519279728063467],
					[56.590969377028422, 2.6519279992406219],
					[56.591766664354672, 2.652019596452543],
					[56.592141650872072, 2.6521140939198609],
					[56.592141662728004, 2.6521140968210446],
					[56.592141688059748, 2.6521140990862442],
					[56.592141699832752, 2.6521141003691242],
					[56.592561099780774, 2.6521141002951873],
					[56.5925611060308, 2.6521140992431635],
					[56.592561118613759, 2.652114098757437],
					[56.592561124863785, 2.6521140977054123],
					[56.592935994402936, 2.6520669141973712],
					[56.593405428055853, 2.6528640003559505],
					[56.593405464223103, 2.652864038341253],
					[56.593405549599765, 2.6528640876319876],
					[56.593405599784944, 2.6528641004055196],
					[56.593919356591542, 2.6528641007992024],
					[56.594341533989471, 2.6530529446856188],
					[56.594577608310971, 2.6538472574556184],
					[56.594577644248282, 2.6538473085405641],
					[56.594577739796705, 2.6538473806085396],
					[56.594577800217785, 2.6538473998228813],
					[56.594905600179992, 2.6538474001382246],
					[56.594905608298639, 2.6538474004042985],
					[56.594905625263031, 2.6538473975493284],
					[56.594905633298815, 2.6538473961969711],
					[56.595186132681121, 2.6538001965003351],
					[56.595186161896883, 2.6538001866862966],
					[56.595186217235351, 2.6538001594167797],
					[56.595186240596554, 2.6538001407936602],
					[56.595466803782308, 2.6535195787419856],
					[56.5959833520402, 2.6533807924125568],
					[56.595983375898761, 2.6533807834995988],
					[56.595983422308478, 2.6533807577316058],
					[56.595983442098174, 2.6533807397089273],
					[56.596169542069511, 2.6531918393013143],
					[56.596169563064528, 2.6531918096484932],
					[56.596169592139994, 2.6531917443544173],
					[56.596169600220428, 2.6531917087131629],
					[56.596216900294131, 2.652066709707166],
					[56.596311299610015, 2.6505222122499714],
					[56.596311298863547, 2.6505221976836499],
					[56.596311297204714, 2.6505221653140603],
					[56.596311293779678, 2.6505221511987824],
					[56.59607519625343, 2.6495832583296828],
					[56.595936303924795, 2.648788907408727],
					[56.596122393617854, 2.6480389483015538],
					[56.596122395715589, 2.648038936521095],
					[56.596122398935265, 2.6480389114921041],
					[56.596122400140139, 2.6480388998620512],
					[56.596122400406912, 2.647383299830925],
					[56.596122398435291, 2.6473832789413327],
					[56.596122389134933, 2.6473832380647058],
					[56.596122381806197, 2.6473832180776404],
					[56.595889099793702, 2.6468666566557451],
					[56.595889099698979, 2.6462111205603929],
					[56.595983496406319, 2.6457417395369416],
					[56.59598349833783, 2.6457417245195036],
					[56.595983498546452, 2.6457416934680289],
					[56.595983498692277, 2.6457416787515076],
					[56.595889100258958, 2.6448527915143911],
					[56.595936300481071, 2.6430250056205331],
					[56.595936300231962, 2.6430250007652214],
					[56.595936299650695, 2.6430249894361437],
					[56.595936299401572, 2.6430249845808156],
					[56.595841899370505, 2.6418527844946267],
					[56.595841896837328, 2.6418527702298462],
					[56.595841890961204, 2.6418527434692449],
					[56.595841886642354, 2.6418527295055476],
					[56.595561295857912, 2.6411027524473201],
					[56.595466902641327, 2.6403528100178928],
					[56.595561260448093, 2.6399308003610025],
					[56.595747075725178, 2.6399308001493349],
					[56.595888706224954, 2.6402139571524081],
					[56.595983100802776, 2.6410083245954175],
					[56.595983113427351, 2.6410083600126075],
					[56.595983150117299, 2.6410084256526725],
					[56.595983174099622, 2.6410084542571126],
					[56.596216573891745, 2.6411973556996551],
					[56.596216604435305, 2.6411973717707262],
					[56.596216666809717, 2.6411973939010727],
					[56.596216700426268, 2.6411973996591978],
					[56.596497200383716, 2.6411974000711473],
					[56.596497206716649, 2.6411974006356225],
					[56.596497221002117, 2.641197398226514],
					[56.596497229120736, 2.6411973984898496],
					[56.596825028808595, 2.6411501983976722],
					[56.596825071166535, 2.6411501814593872],
					[56.596825144254673, 2.6411501315864783],
					[56.59682517490176, 2.6411500970333743],
					[56.597108474998656, 2.6406361972923631],
					[56.597108486086199, 2.6406361676699595],
					[56.597108497464156, 2.6406361086136818],
					[56.597108498564324, 2.6406360774107611],
					[56.596966898727047, 2.6393693780572209],
					[56.596966893016535, 2.639369354533204],
					[56.596966877214456, 2.6393693098567197],
					[56.596966866146936, 2.6393692872363892],
					[56.596777982770874, 2.6390888141954338],
					[56.596544594602115, 2.6382916539674608],
					[56.596405796741053, 2.6375416645852363],
					[56.596405794539834, 2.6375416567943302],
					[56.596405792006173, 2.6375416425296643],
					[56.596405789888053, 2.6375416363571991],
					[56.596216890295338, 2.6369805362445167],
					[56.596216886225292, 2.636980527136588],
					[56.596216878168349, 2.6369805105391579],
					[56.596216874098303, 2.6369805014312298],
					[56.595794573843854, 2.6362305025386137],
					[56.595794567988115, 2.6362304937322278],
					[56.595794556193482, 2.6362304745010414],
					[56.595794548552057, 2.6362304659960243],
					[56.595372349188104, 2.6357609656243519],
					[56.595372319287392, 2.6357609445521635],
					[56.595372253651782, 2.6357609115545957],
					[56.595372218000023, 2.6357609012476035],
					[56.594858385965885, 2.6357137062974516],
					[56.594155769760036, 2.635058185410704],
					[56.59359189400714, 2.6336055474923419],
					[56.593452996580083, 2.6327638674255249],
					[56.593452996164231, 2.6327638593340166],
					[56.593452991761239, 2.632763843754014],
					[56.593452989642913, 2.6327638375823024],
					[56.593172398376232, 2.6319193611337992],
					[56.593077999749958, 2.6302332938178572],
					[56.593077999856028, 2.628077799862309],
					[56.593078000332689, 2.6280777916200941],
					[56.593077997631525, 2.6280777741208334],
					[56.593077997132141, 2.6280777644112079],
					[56.592936296843853, 2.6272805644012953],
					[56.592936296843853, 2.6272805644012953],
					[56.592936295784547, 2.6272805613156556],
					[56.592936295784547, 2.6272805613156556],
					[56.592797395904, 2.6265777608948566],
					[56.592797392393059, 2.6265777451649912],
					[56.592797381073595, 2.6265777176962404],
					[56.592797374884206, 2.6265777024190484],
					[56.592561303106358, 2.6261555523473388],
					[56.592608499936468, 2.6252667117814381],
					[56.592608498937267, 2.6252666923625401],
					[56.592608495403049, 2.6252666586812992],
					[56.59260849091585, 2.6252666414843286],
					[56.592375191111827, 2.6245166406253899],
					[56.592375189969189, 2.6245166359216325],
					[56.592375185981552, 2.6245166284342387],
					[56.592375184838914, 2.6245166237305],
					[56.592141900440971, 2.6239527600833314],
					[56.592141900135815, 2.6232499998021934],
					[56.592141899719358, 2.6232499917111145],
					[56.592141897993628, 2.6232499756799208],
					[56.592141896684346, 2.62324996773981],
					[56.591952997042632, 2.6220805680590997],
					[56.591952996792713, 2.6220805632044986],
					[56.591952993614456, 2.6220805539482566],
					[56.591952993364529, 2.6220805490936385],
					[56.591766900864144, 2.6213777785584367],
					[56.591811300232948, 2.6204861105006723],
					[56.591811299399673, 2.6204860943187422],
					[56.591811295864176, 2.6204860606387528],
					[56.591811292352482, 2.6204860449099256],
					[56.591530791694076, 2.6195027446668],
					[56.591530791694076, 2.6195027446668],
					[56.591530790634579, 2.619502741581488],
					[56.591530790634579, 2.619502741581488],
					[56.591297397644048, 2.6187527625892586],
					[56.591250200919546, 2.6180972026406568],
					[56.591297399284443, 2.6175806181673202],
					[56.591297399093492, 2.6175805969801123],
					[56.591297394247583, 2.6175805553611982],
					[56.591297389676001, 2.6175805365476443],
					[56.591202990133574, 2.6172999362756646],
					[56.591202977193284, 2.6172999123494844],
					[56.591202946039253, 2.6172998670218934],
					[56.591202927825535, 2.6172998456204937],
					[56.590922391222271, 2.6170665981686723],
					[56.590828000143318, 2.6161305861983823],
					[56.590780799663769, 2.6149582924987991],
					[56.59073350024525, 2.6134582941148197],
					[56.590733499018782, 2.6134582877935078],
					[56.590733498268015, 2.6134582732303953],
					[56.59073349695813, 2.6134582652909639],
					[56.590594597375336, 2.612661066389427],
					[56.590594583766752, 2.6126610295203525],
					[56.590594543658291, 2.6126609677590067],
					[56.590594518777138, 2.6126609393280895],
					[56.59040571936179, 2.6125220395223541],
					[56.590405698969505, 2.6125220282860044],
					[56.59040565932802, 2.6125220105164475],
					[56.590405637316991, 2.6125220028187339],
					[56.589658405762862, 2.6123804164984641],
					[56.589375172711442, 2.612052694593709],
					[56.589189093869244, 2.6115832477215895],
					[56.589000199948451, 2.6104138814146101],
					[56.588953000054708, 2.6086777953625129],
					[56.58895299980427, 2.6086777905084211],
					[56.588952999386883, 2.6086777824182508],
					[56.58895299921992, 2.6086777791821758],
					[56.588861298616749, 2.6077888793588695],
					[56.588861298199298, 2.6077888712687334],
					[56.588861293709755, 2.607788854075697],
					[56.588861293292311, 2.6077888459855618],
					[56.58843909976607, 2.6062888722940003],
					[56.588439100179258, 2.6051639002415667],
					[56.588439097140821, 2.6051638762743106],
					[56.588439086767146, 2.6051638323326545],
					[56.588439079348355, 2.6051638107402697],
					[56.588016884026267, 2.6043221211018279],
					[56.587641887901789, 2.6032916326673248],
					[56.587641863410141, 2.6032915943856585],
					[56.587641798941746, 2.603291531873468],
					[56.587641759941306, 2.6032915091094795],
					[56.586891817282293, 2.6030554266986603],
					[56.586469585651933, 2.6025416119321809],
					[56.586330808379053, 2.6018389224750837],
					[56.586516829604228, 2.6014169003257726],
					[56.587033300425304, 2.6014168995616602],
					[56.58703333962373, 2.6014168912798148],
					[56.587033412720984, 2.6014168592941056],
					[56.587033446619834, 2.6014168355902378],
					[56.587641847247284, 2.600761235488942],
					[56.587641858184668, 2.6007612205762003],
					[56.587641878999491, 2.6007611876662753],
					[56.587641887091394, 2.6007611699720328],
					[56.588205786730732, 2.5992611706815176],
					[56.588205789800185, 2.5992611603676523],
					[56.588205795939132, 2.5992611397399146],
					[56.58820579811583, 2.5992611295775538],
					[56.588486298170295, 2.5973861307441735],
					[56.588486298644931, 2.5973861225027273],
					[56.588486299677832, 2.5973861076378264],
					[56.588486300152461, 2.5973860993963842],
					[56.588486299936051, 2.5949500107582901],
					[56.588625195229994, 2.5937333551384487],
					[56.588908457075185, 2.593122337783111],
					[56.589564007815184, 2.5927001687715285],
					[56.589564036997295, 2.5927001409625401],
					[56.589564079598851, 2.5927000765969148],
					[56.589564095780226, 2.5927000412032983],
					[56.589750196321575, 2.5918111413126304],
					[56.589750198413945, 2.5918111295314392],
					[56.589750199836736, 2.5918111048061538],
					[56.589750200059903, 2.5918110917103414],
					[56.589703002304553, 2.5905917396040357],
					[56.590077982871577, 2.5897500811989698],
					[56.59007798836732, 2.5897500655745702],
					[56.59007799659679, 2.5897500331629582],
					[56.590077999330518, 2.5897500163757643],
					[56.590125198919345, 2.589186120992701],
					[56.590266894226787, 2.5881083614274636],
					[56.590689077039798, 2.5873139942883498],
					[56.590689084878413, 2.5873139717357772],
					[56.590689096817144, 2.5873139240017524],
					[56.590689100108257, 2.5873139005901744],
					[56.590689099685825, 2.5865167737081625],
					[56.590969513859569, 2.5861890740532454],
					[56.591436157559194, 2.5860473911457955],
					[56.591436203701825, 2.5860473604453049],
					[56.59143627436486, 2.5860472815017905],
					[56.59143629790875, 2.5860472317925134],
					[56.591672397629949, 2.5845472305785253],
					[56.591672398187505, 2.5845472239543539],
					[56.591672400111577, 2.5845472089360744],
					[56.5916723997764, 2.5845472024637797],
					[56.591719599828494, 2.5798167024186975],
					[56.591719599660777, 2.5798166991825848],
					[56.591719600301936, 2.5798166941764094],
					[56.591719600134226, 2.5798166909402953],
					[56.591625199872951, 2.5776610908949618],
					[56.591625194875995, 2.57766106399627],
					[56.591625176847479, 2.577661011567272],
					[56.591625163732061, 2.577660984418904],
					[56.591297369244309, 2.5771915925146103],
					[56.591108527141913, 2.5768638941123707],
					[56.59134457917861, 2.5763944895112481],
					[56.59134457917861, 2.5763944895112481],
					[56.591577978630951, 2.5759278893355724],
					[56.591577988085056, 2.5759278632399911],
					[56.591577997814291, 2.5759278077155052],
					[56.59157799906604, 2.5759277797525657],
					[56.591483498746307, 2.5750360796704945],
					[56.591483497182224, 2.5750360668783108],
					[56.591483491459805, 2.5750360433683066],
					[56.5914834881942, 2.5750360324983514],
					[56.591297399729157, 2.5745221656637711],
					[56.591297399605047, 2.5738667347635578],
					[56.591483487863798, 2.5733500686537538],
					[56.591483491571928, 2.573350053330786],
					[56.591483498179443, 2.5733500244550309],
					[56.591483500102143, 2.573350009436342],
					[56.591577999662, 2.5715222096197539],
					[56.591578000302832, 2.5715222046134789],
					[56.591577999883, 2.571522196523341],
					[56.591577999631113, 2.5715221916692661],
					[56.591436299722638, 2.5684777907669458],
					[56.591436299722638, 2.5684777907669458],
					[56.591436299554637, 2.5684777875309277],
					[56.591436300447349, 2.5684777873786739],
					[56.591344599921996, 2.5670249876037396],
					[56.591344589871049, 2.5670249501422457],
					[56.591344556630531, 2.5670248823576172],
					[56.591344534333672, 2.5670248518821799],
					[56.590827972975219, 2.5665554865606492],
					[56.590594597992151, 2.5658527627407022],
					[56.590547400331971, 2.5649166907158287],
					[56.590547394355525, 2.5649166623541801],
					[56.59054737721452, 2.5649166097807918],
					[56.590547364264523, 2.5649165858737257],
					[56.590219622710421, 2.5644471703276861],
					[56.590452975330976, 2.5637918036246989],
					[56.590875148624505, 2.5633223335202291],
					[56.590875155429849, 2.5633223258293607],
					[56.590875166362466, 2.5633223109047472],
					[56.5908751713824, 2.5633223035186239],
					[56.591297371173717, 2.5626195025451337],
					[56.591297380877194, 2.5626194813005725],
					[56.591297394927935, 2.5626194397258368],
					[56.59129739919112, 2.5626194177776576],
					[56.591344599160237, 2.5620583177349268],
					[56.591344598875715, 2.5620582949306914],
					[56.591344592950449, 2.5620582502366687],
					[56.591344587309699, 2.5620582283468836],
					[56.591202990859635, 2.5616832385072263],
					[56.591064105620511, 2.5611666978153527],
					[56.591155786208247, 2.5607917812014653],
					[56.591344566268184, 2.5605112121305251],
					[56.59134457928976, 2.560511185421718],
					[56.591344596153611, 2.5605111286745785],
					[56.591344600079985, 2.5605111002542258],
					[56.591344599584595, 2.5599499995574626],
					[56.591344598743447, 2.559949983377654],
					[56.591344594383031, 2.5599499514754185],
					[56.591344589971094, 2.5599499359054279],
					[56.591202990151402, 2.5595277352612289],
					[56.591202978177215, 2.5595277128212102],
					[56.591202949849453, 2.5595276703214394],
					[56.591202932603188, 2.5595276504141782],
					[56.590780761553241, 2.5591526754344489],
					[56.590547395925995, 2.5586832453858466],
					[56.590500198779779, 2.5581221854685627],
					[56.590453000257831, 2.5573721870346175],
					[56.590452997883403, 2.5573721760141028],
					[56.59045299402726, 2.5573721538205461],
					[56.590452991652818, 2.5573721428000349],
					[56.590314091701707, 2.5569027437600491],
					[56.590314068897591, 2.5569027035840026],
					[56.590314007809937, 2.5569026373027728],
					[56.590313968633744, 2.5569026113501052],
					[56.589938969255222, 2.5567637123859179],
					[56.589938947325621, 2.5567637063389888],
					[56.5899389020177, 2.5567637010217976],
					[56.589938880424739, 2.5567637014465086],
					[56.589047246570225, 2.5568553933447653],
					[56.588439033869598, 2.5564804477453666],
					[56.588064055802533, 2.5560137748201806],
					[56.58806404904869, 2.556013766180917],
					[56.588064033923992, 2.5560137524430937],
					[56.588064026361629, 2.5560137455741843],
					[56.587830726432784, 2.5558248453920318],
					[56.587830701320094, 2.5558248300965758],
					[56.587830649730179, 2.5558248078998411],
					[56.587830622360258, 2.55582480115108],
					[56.587408408129157, 2.5557776108190486],
					[56.587127960238867, 2.5554026802604781],
					[56.587127959262013, 2.5554026787952315],
					[56.587127957308297, 2.555402675864741],
					[56.587127956331436, 2.5554026743994944],
					[56.58675295584851, 2.554935975483684],
					[56.586752911871812, 2.5549359438269397],
					[56.58675281276755, 2.5549359085333858],
					[56.586752759425366, 2.5549359045914848],
					[56.58609715928803, 2.5550748034824222],
					[56.586097136330565, 2.5550748123016862],
					[56.586097093262083, 2.555074832718045],
					[56.586097073151052, 2.5550748443151607],
					[56.585580472773678, 2.5554970454230546],
					[56.585580470179771, 2.5554970474983514],
					[56.585580461589515, 2.5554970554945036],
					[56.585580459079779, 2.5554970591875166],
					[56.585063759217526, 2.5560137590826666],
					[56.585063744715029, 2.5560137746169671],
					[56.585063722295516, 2.5560138110887669],
					[56.585063713401652, 2.5560138305610884],
					[56.584783114665406, 2.556763822937667],
					[56.584455452204111, 2.5574665449068426],
					[56.583894322945447, 2.5576998144334229],
					[56.583894282334924, 2.557699847481762],
					[56.58389422070185, 2.5576999265532305],
					[56.58389420163298, 2.5576999755067371],
					[56.583847004830957, 2.5580749525777033],
					[56.583705413200235, 2.5584499290106502],
					[56.583705413284349, 2.5584499306282993],
					[56.583705412391659, 2.5584499307807449],
					[56.583566512012986, 2.558824931021674],
					[56.583566511372652, 2.5588249360270514],
					[56.583566508306589, 2.5588249463426651],
					[56.583566505880867, 2.5588249516529178],
					[56.583472056928635, 2.5591997503026458],
					[56.58314445529826, 2.5591054086687381],
					[56.583144441571307, 2.5591054044846513],
					[56.583144414369748, 2.559105400969337],
					[56.583144399918339, 2.5591054001729101],
					[56.582675000288155, 2.5591053991940562],
					[56.582674954036044, 2.5591054103553716],
					[56.582674873421858, 2.559105453495389],
					[56.582674837190268, 2.5591054841613485],
					[56.582441537124552, 2.5594331836174025],
					[56.582441532188916, 2.5594331926198772],
					[56.582441522317652, 2.5594332106248552],
					[56.582441517297895, 2.5594332180097505],
					[56.582252653284719, 2.5598554380134035],
					[56.581972167053507, 2.5599498007191852],
					[56.581691699931689, 2.5599498005995875],
					[56.58169169538408, 2.559949799744019],
					[56.581691684671668, 2.5599498015728108],
					[56.581691680124074, 2.559949800717225],
					[56.581222180027645, 2.5599970013700903],
					[56.581222149844038, 2.5599970097866245],
					[56.581222094276725, 2.5599970323279178],
					[56.58122206808445, 2.5599970482226286],
					[56.580847068101811, 2.5603248482704748],
					[56.580472232053289, 2.5606525057337555],
					[56.580333500238886, 2.5604638342401991],
					[56.580333500050678, 2.5600888997162237],
					[56.580333498569253, 2.5600888885460416],
					[56.58033349739182, 2.5600888659008842],
					[56.580333495017697, 2.5600888548830882],
					[56.580191894934821, 2.5594805544800554],
					[56.580191877740262, 2.5594805182508069],
					[56.580191830213003, 2.5594804529308641],
					[56.580191799071692, 2.5594804256100585],
					[56.579536198606917, 2.5591054260042734],
					[56.579536174723813, 2.5591054170270637],
					[56.579536126233144, 2.5591054024599798],
					[56.57953610000839, 2.5591054004098943],
					[56.579066840073587, 2.5591054000045674],
					[56.578880800176819, 2.5585916645978122],
					[56.578880799750479, 2.5578861007794185],
					[56.578880788215471, 2.5578860521630356],
					[56.578880743332817, 2.5578859684457242],
					[56.578880709985164, 2.5578859333447834],
					[56.578316824368144, 2.5575137422063619],
					[56.577708435702512, 2.5569498533708614],
					[56.577708433748846, 2.5569498504409816],
					[56.577708429032931, 2.5569498463510434],
					[56.577708427163401, 2.5569498450385515],
					[56.577194527405368, 2.5565276448186633],
					[56.57719448767314, 2.5565276254963325],
					[56.577194403778101, 2.5565276055574175],
					[56.5771943614007, 2.55652760463592],
					[56.576255560693028, 2.5567165033890413],
					[56.576255513328888, 2.5567165277942787],
					[56.576255436015416, 2.5567165997379822],
					[56.576255407767327, 2.5567166453542161],
					[56.576160915632393, 2.5570443153262703],
					[56.575788787833041, 2.5576054065665401],
					[56.575086086815752, 2.557652599657108],
					[56.575086041624694, 2.5576526138994038],
					[56.575085963941333, 2.5576526614272179],
					[56.575085931364896, 2.5576526930954615],
					[56.574849847980317, 2.5580276663608177],
					[56.574288827380471, 2.5584969991929563],
					[56.573866699788596, 2.5584969995615738],
					[56.573866662379352, 2.5584970075791089],
					[56.573866590996012, 2.5584970377120837],
					[56.573866558723203, 2.5584970579055204],
					[56.573397059342682, 2.5589637586322183],
					[56.573397039179532, 2.55896378654738],
					[56.573397009177611, 2.5589638504048455],
					[56.573397001124235, 2.5589638860423869],
					[56.573349801807112, 2.5596221653414983],
					[56.573210913350643, 2.5601360256702934],
					[56.572835924228336, 2.5608388054679261],
					[56.572835913328099, 2.5608388383271703],
					[56.572835903385077, 2.5608389069169895],
					[56.572835904258177, 2.560838941030374],
					[56.57297480042147, 2.561494420570694],
					[56.572974799744571, 2.5623388647538543],
					[56.572741520811086, 2.5629943076195474],
					[56.572180445704419, 2.563791571542605],
					[56.57133595906113, 2.5646331572061589],
					[56.571335943916715, 2.5646331777348395],
					[56.571335919068211, 2.564633219495958],
					[56.571335909448145, 2.5646332423455518],
					[56.571149815251374, 2.5652443212823663],
					[56.570913795150261, 2.5656664771719493],
					[56.570400036062139, 2.5655720028211193],
					[56.570399991174448, 2.5655720055798854],
					[56.570399905085523, 2.565572030046058],
					[56.570399864776952, 2.5655720516012792],
					[56.569835964646202, 2.5660859514412375],
					[56.569835946907396, 2.5660859740421302],
					[56.569835917846817, 2.5660860214127794],
					[56.569835906609057, 2.5660860477996028],
					[56.569697033105633, 2.5666026491992215],
					[56.569322129367684, 2.566744213672369],
					[56.569322105684435, 2.5667442258662057],
					[56.569322064010208, 2.5667442558091369],
					[56.569322045126547, 2.5667442737103752],
					[56.568899845371227, 2.567258173327692],
					[56.5688998386495, 2.5672581826301468],
					[56.568899826991469, 2.5672582009307532],
					[56.568899821246454, 2.567258211698098],
					[56.568524820598469, 2.5680082107085531],
					[56.568524812679151, 2.5680082316342698],
					[56.568524803341539, 2.5680082772718684],
					[56.568524800053773, 2.5680083006709928],
					[56.568524800064921, 2.5687110627002321],
					[56.568244226182827, 2.569413797086201],
					[56.567822047270162, 2.5699304719323193],
					[56.56726094830411, 2.5705859691723227],
					[56.567260935752827, 2.570585987623168],
					[56.567260916899315, 2.5705860234604048],
					[56.567260908895591, 2.5705860427679075],
					[56.567119240056627, 2.5710526380217789],
					[56.566791687885974, 2.5711469943885286],
					[56.566511228380485, 2.5710998182709623],
					[56.566228000174824, 2.5705860489523169],
					[56.566228000382104, 2.5701638996997715],
					[56.566227999945283, 2.5696944003205062],
					[56.566227985569107, 2.5696943489385484],
					[56.566227935086495, 2.5696942612952385],
					[56.566227897194629, 2.5696942253380279],
					[56.565808396940724, 2.569460925436696],
					[56.565808375820382, 2.5694609176161807],
					[56.565808332770999, 2.5694609037441247],
					[56.565808310925895, 2.5694608993095209],
					[56.564963927684794, 2.5694137011332394],
					[56.564355644772711, 2.5692720047956157],
					[56.564355591766386, 2.5692720073008739],
					[56.564355495215672, 2.5692720384292791],
					[56.564355449885852, 2.5692720673565681],
					[56.563980449806778, 2.5696942669810281],
					[56.563980431594288, 2.5696942978128505],
					[56.563980407110542, 2.569694363966994],
					[56.563980399946537, 2.569694399441385],
					[56.563980399894682, 2.57016385617329],
					[56.563699818348049, 2.5707721155390364],
					[56.563699816730505, 2.5707721190767971],
					[56.563699815112955, 2.5707721226145428],
					[56.56369981438813, 2.5707721260002474],
					[56.563324819173708, 2.571711012685272],
					[56.562949826323454, 2.5723666016864417],
					[56.562949816617873, 2.5723666229121198],
					[56.562949803623638, 2.5723666675330952],
					[56.562949800334977, 2.5723666909284089],
					[56.562902600182014, 2.5734443913088563],
					[56.562902599708856, 2.5734443995448752],
					[56.562902600715802, 2.5734444189465848],
					[56.562902602112004, 2.5734444284954687],
					[56.563088698271621, 2.5747556015869839],
					[56.562949803999608, 2.5758332526689776],
					[56.56271373042614, 2.5764887821474929],
					[56.562105474749742, 2.577005443565993],
					[56.561730479977818, 2.5772859404138444],
					[56.561544417851344, 2.5774247363025857],
					[56.561261246122029, 2.5771442635917983],
					[56.560889049842736, 2.576722067548503],
					[56.560889009693987, 2.5767220401281032],
					[56.560888922452186, 2.5767220076718163],
					[56.560888872597054, 2.5767220014748364],
					[56.560183301570433, 2.5768164976507903],
					[56.559294528562219, 2.5766776179645206],
					[56.559014096157583, 2.5761610417363427],
					[56.558919600051873, 2.5750360857035268],
					[56.558825200423698, 2.5733027889940803],
					[56.558825199027517, 2.5733027794462213],
					[56.558825196319056, 2.5733027619671396],
					[56.558825194922839, 2.5733027524192726],
					[56.558544595343598, 2.5721305563401797],
					[56.558311294678866, 2.5710527579721125],
					[56.558311294594937, 2.5710527563554915],
					[56.558311294427057, 2.5710527531222866],
					[56.558311294343113, 2.5710527515056825],
					[56.558075194305403, 2.5701166511342519],
					[56.558075186964984, 2.5701166311826977],
					[56.558075167904498, 2.5701165936563251],
					[56.558075157077148, 2.5701165759294713],
					[56.557372392705666, 2.5692277217969424],
					[56.557280798658539, 2.568383277662551],
					[56.557280798238686, 2.5683832695797797],
					[56.557280793744113, 2.5683832524059276],
					[56.557280791538801, 2.5683832446272801],
					[56.5570918916656, 2.5677277450508331],
					[56.557091888315597, 2.5677277325747148],
					[56.55709187804473, 2.5677277082307546],
					[56.55709187210055, 2.5677276978274022],
					[56.556341876192128, 2.5664610041359182],
					[56.556014079321848, 2.5658055106344269],
					[56.556014038414965, 2.565805468680538],
					[56.556013940983703, 2.565805413526792],
					[56.556013882673888, 2.5658054006311657],
					[56.555452782772392, 2.5658526004264042],
					[56.555452742936254, 2.5658526137371589],
					[56.555452671970407, 2.5658526519214568],
					[56.555452640116023, 2.5658526801800341],
					[56.554888740061685, 2.5666026793031294],
					[56.554888726145407, 2.5666027061353041],
					[56.554888705622588, 2.5666027618158651],
					[56.554888699908751, 2.5666027905121478],
					[56.554844203465805, 2.5674916415706943],
					[56.554374827990515, 2.5682887983321034],
					[56.554374823862808, 2.5682888055580526],
					[56.554374817392826, 2.5682888197058436],
					[56.554374814157846, 2.568288826779745],
					[56.553999814144042, 2.5692277264005177],
					[56.553999810268159, 2.5692277384755324],
					[56.553999802684295, 2.5692277658584062],
					[56.553999800677779, 2.5692277792457974],
					[56.553858101217557, 2.5705860783239443],
					[56.5538581013015, 2.5705860799403712],
					[56.553858101385444, 2.5705860815567827],
					[56.553858100576662, 2.5705860833251828],
					[56.553810902165644, 2.571147167152688],
					[56.553530405890513, 2.5722721515403975],
					[56.553530403799748, 2.5722721633110792],
					[56.553530400594909, 2.5722721883169068],
					[56.55353040028961, 2.5722721997836917],
					[56.553530399811777, 2.5733499449788981],
					[56.553108128934142, 2.5740526974556608],
					[56.553108116801639, 2.5740527239801034],
					[56.553108102608313, 2.5740527802072433],
					[56.553108099654793, 2.5740528100618372],
					[56.553155399666359, 2.5750360968324744],
					[56.553016504106822, 2.5765832502218489],
					[56.552547014957781, 2.5777082228800787],
					[56.552547011806077, 2.5777082315692388],
					[56.552547007288126, 2.5777082486439356],
					[56.552547004945318, 2.5777082555649034],
					[56.552313705126345, 2.5787388556127309],
					[56.552313703592375, 2.5787388607654194],
					[56.552313702393732, 2.5787388723836151],
					[56.552313700859763, 2.5787388775363205],
					[56.552172005617152, 2.579955537320199],
					[56.551985925972247, 2.5802832002273144],
					[56.55198591724168, 2.5802832229093009],
					[56.551985904244226, 2.5802832675145533],
					[56.55198589997736, 2.5802832894377956],
					[56.551938700985673, 2.581269381465277],
					[56.551797010669056, 2.5823471069425215],
					[56.55109425868833, 2.5830498591891904],
					[56.551094241029759, 2.5830498833868201],
					[56.551094213105927, 2.5830499354176708],
					[56.551094202924439, 2.5830499648672154],
					[56.550813702581799, 2.5846416648996016],
					[56.550813702191583, 2.5846416747492205],
					[56.550813700350879, 2.5846416913674402],
					[56.550813699876905, 2.5846416996007298],
					[56.550813700299003, 2.5857666996126776],
					[56.550813700382747, 2.5857667012290029],
					[56.550813699741212, 2.5857667062296117],
					[56.550813699908701, 2.5857667094622854],
					[56.55086089830575, 2.5867499676515875],
					[56.550438716731662, 2.5878277191058054],
					[56.550158121365556, 2.5883916115693593],
					[56.550158115702217, 2.588391623944398],
					[56.550158107137499, 2.5883916498561161],
					[56.550158103343364, 2.5883916635443769],
					[56.550016502763604, 2.5891416634890003],
					[56.550016501982448, 2.589141683187774],
					[56.550016503349489, 2.5891417269796744],
					[56.55001650630674, 2.5891417493049476],
					[56.55015810558632, 2.5897028491754335],
					[56.550158108766723, 2.5897028584187893],
					[56.550158117080429, 2.58970287983511],
					[56.550158121153586, 2.5897028889269489],
					[56.550485917558312, 2.5903583823415306],
					[56.550719206072841, 2.5909194539948204],
					[56.550813700043612, 2.59153061445405],
					[56.550813699728103, 2.5920443998872793],
					[56.550813701708435, 2.5920444207483984],
					[56.550813711025668, 2.5920444615618581],
					[56.550813718362548, 2.5920444815141876],
					[56.551047014839462, 2.5925611760065612],
					[56.551235885840391, 2.5930749966547415],
					[56.550952614879428, 2.5937777248279317],
					[56.550952607931826, 2.593777747203498],
					[56.550952601429628, 2.593777795592322],
					[56.550952600815023, 2.5937778185242819],
					[56.550999798393981, 2.5942943829962357],
					[56.550860907328065, 2.5948110485122453],
					[56.550860905151481, 2.5948110586646322],
					[56.550860901607528, 2.5948110772016371],
					[56.550860900323734, 2.594811087202618],
					[56.550813700364223, 2.5955138871185173],
					[56.550813699722305, 2.5955138921189818],
					[56.5508137003076, 2.5955139034335435],
					[56.550813699749291, 2.5955139100503728],
					[56.55086089993636, 2.5964500092367535],
					[56.550860901247148, 2.5964500171672364],
					[56.550860903059537, 2.5964500347959358],
					[56.550860905263086, 2.5964500425750585],
					[56.551047005004946, 2.5972944436645165],
					[56.551047006148487, 2.5972944483623057],
					[56.551047009328343, 2.597294457606552],
					[56.551047009579108, 2.5972944624556997],
					[56.551374800331452, 2.5982778326511742],
					[56.55137479956705, 2.5994944002531821],
					[56.551374807821993, 2.5994944379877367],
					[56.551374836747122, 2.5994945097223585],
					[56.551374859035747, 2.5994945401870293],
					[56.551610917818209, 2.5997307013788356],
					[56.551844202049757, 2.600761131638226],
					[56.551938701143783, 2.601791718622914],
					[56.551938704991585, 2.6017917407992281],
					[56.551938717876801, 2.6017917810115505],
					[56.551938726997733, 2.6017918006639804],
					[56.552266480915115, 2.6023528214211398],
					[56.552030410186845, 2.6030555355714227],
					[56.552030407367397, 2.6030555507242381],
					[56.552030401644934, 2.6030555794134216],
					[56.552030399718241, 2.6030555944150269],
					[56.551985899548875, 2.6046971937240491],
					[56.551938700166133, 2.6061499942783728],
					[56.551938700166133, 2.6061499942783728],
					[56.551938700333096, 2.6061499975112579],
					[56.551938699523781, 2.606149999278855],
					[56.551938700350235, 2.6085389007097719],
					[56.551938699791229, 2.6085389073266585],
					[56.551938701435034, 2.6085389217236603],
					[56.551938701768812, 2.6085389281894784],
					[56.552124801940785, 2.609850028271703],
					[56.552124809692039, 2.6098500563107012],
					[56.552124835015292, 2.6098501107271144],
					[56.552124852420413, 2.6098501338715909],
					[56.552594251917085, 2.6103668337273129],
					[56.552594258583738, 2.610366840752437],
					[56.55259427280987, 2.6103668546516436],
					[56.552594280285902, 2.6103668599092256],
					[56.553155415148218, 2.6107890117414971],
					[56.553249802196682, 2.611397230351185],
					[56.553249804399314, 2.6113972381317301],
					[56.553249806935582, 2.6113972523783544],
					[56.553249809054812, 2.6113972585423677],
					[56.553435909169323, 2.6120056588327385],
					[56.553435911288545, 2.6120056649968295],
					[56.553435915610329, 2.6120056789415469],
					[56.55343591862232, 2.6120056849546174],
					[56.553766518967954, 2.612708385727168],
					[56.553766519860744, 2.6127083855761719],
					[56.553766520920327, 2.6127083886582598],
					[56.553766520920327, 2.6127083886582598],
					[56.55414150421263, 2.6134583548773338],
					[56.554185900832145, 2.6139750162148467],
					[56.554185901082278, 2.6139750210645363],
					[56.554185902391978, 2.6139750289963959],
					[56.554185902642104, 2.6139750338461041],
					[56.554374800671006, 2.6150972247327013],
					[56.554422000229842, 2.6157556064848668],
					[56.554421999573272, 2.6165028003233468],
					[56.554422001216182, 2.6165028147217013],
					[56.554422006370942, 2.6165028448331551],
					[56.554422008906649, 2.6165028590805992],
					[56.554655409406919, 2.6172528592997257],
					[56.554655412668666, 2.6172528701632345],
					[56.554655421787274, 2.6172528898209375],
					[56.554655425858492, 2.6172528989169415],
					[56.555030425943478, 2.617908398668694],
					[56.555030427895751, 2.6179084016001561],
					[56.555030429931335, 2.6179084061482452],
					[56.555030431800276, 2.6179084074630961],
					[56.555452607639026, 2.6185667708966864],
					[56.555547002312956, 2.6192694266773264],
					[56.555547001670078, 2.6192694316781062],
					[56.555547003872242, 2.6192694394595963],
					[56.555547004931668, 2.6192694425420373],
					[56.5557331046871, 2.6201111422507779],
					[56.555733105913099, 2.6201111485665556],
					[56.555733109091314, 2.6201111578139669],
					[56.555733110234023, 2.6201111625130822],
					[56.556060898040684, 2.6210972246193669],
					[56.555966505044815, 2.6224082267236],
					[56.555685940217472, 2.6227831793656446],
					[56.555685935276919, 2.6227831883535559],
					[56.555685925229241, 2.6227832030960694],
					[56.555685921098231, 2.6227832103165367],
					[56.55549982135188, 2.6231582112522114],
					[56.555499814232384, 2.6231582303922152],
					[56.555499803564679, 2.6231582680691812],
					[56.55549980009971, 2.6231582882227928],
					[56.55545260298215, 2.6239527482453986],
					[56.55493592032083, 2.625030513603654],
					[56.554935917808841, 2.6250305172890536],
					[56.554935913844162, 2.6250305277424109],
					[56.554935912224998, 2.6250305312771123],
					[56.554374826297312, 2.6265776940172882],
					[56.55385821218394, 2.627141499683662],
					[56.553436100282099, 2.6271414998533151],
					[56.553436034069293, 2.6271415257015613],
					[56.553435934972541, 2.6271416125395808],
					[56.553435902171827, 2.627141675145904],
					[56.553297006136845, 2.628263841197775],
					[56.552733137150767, 2.629388778550485],
					[56.551985963174523, 2.6300915532995175],
					[56.551985942101318, 2.6300915813117896],
					[56.551985911084643, 2.6300916436120167],
					[56.551985901058039, 2.6300916762834641],
					[56.551891517940327, 2.6308887342708696],
					[56.550813858318328, 2.6311220045637396],
					[56.550813844116213, 2.6311220085889611],
					[56.550813816771097, 2.6311220197217726],
					[56.550813804437773, 2.6311220250623748],
					[56.549783203611142, 2.6316859251279627],
					[56.54978316671599, 2.6316859606964229],
					[56.549783115268198, 2.6316860460014926],
					[56.549783099822719, 2.6316860958886208],
					[56.549735899628217, 2.6337943958018228],
				],
			],
		},
		{
			fldNpdidField: "43800",
			wlbNpdidWellbore: "295",
			fldName: "ULA",
			fldCurrentActivitySatus: "Producing",
			wlbName: "7/12-2",
			fldDiscoveryYear: "1976",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43800",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43800",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "019",
			cmpLongName: "Aker BP ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=295",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "28544099",
			fldNpdidOwner: "20916",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Tananger",
			fldAreaGeometryWKT: [
				[
					[57.066955459322941, 2.8674889832776516],
					[57.066955517335181, 2.867489082766955],
					[57.067072117052938, 2.867541881179887],
					[57.067072213104204, 2.8675418751969994],
					[57.067072283758684, 2.8675418287893448],
					[57.067297000157851, 2.8852694035673094],
					[57.067296999585906, 2.8852694102733714],
					[57.067297002175394, 2.8852694263750829],
					[57.067297003389825, 2.8852694327862709],
					[57.069110903282372, 2.8960556331193898],
					[57.069110907817262, 2.8960556522079175],
					[57.069110922166089, 2.8960556878620887],
					[57.069110930273801, 2.8960557063618775],
					[57.074472029812974, 2.9046334062160577],
					[57.074472048878327, 2.9046334278764476],
					[57.074472092128175, 2.9046334653950763],
					[57.07447211728595, 2.9046334827463687],
					[57.080144316576892, 2.9072390819137781],
					[57.080144337601261, 2.9072390883751051],
					[57.080144382169543, 2.9072390975762707],
					[57.080144403927044, 2.907239100610036],
					[57.085650004070835, 2.9071168995607541],
					[57.085650012109838, 2.9071168982376792],
					[57.085650028267892, 2.9071168972320578],
					[57.08565003638693, 2.9071168975495145],
					[57.091127835020835, 2.9061140971554251],
					[57.094533333849974, 2.9055334972257434],
					[57.09453337965676, 2.9055334764493606],
					[57.09453345667103, 2.9055334124892451],
					[57.094533487065341, 2.9055333710935125],
					[57.097455775751371, 2.8977973017347924],
					[57.105944545968512, 2.8873501377655209],
					[57.11137511664969, 2.8823084633427207],
					[57.124552893764182, 2.8752612757384091],
					[57.124552929989157, 2.8752612432481786],
					[57.124552982065062, 2.8752611667441914],
					[57.124552998728667, 2.8752611209402703],
					[57.125239098763068, 2.8688944206260909],
					[57.125239099414479, 2.8688944155514347],
					[57.125239099663013, 2.8688944022659615],
					[57.125239100233848, 2.8688943955491437],
					[57.125058501104455, 2.8602667296318223],
					[57.126233483317527, 2.8567222822419778],
					[57.127900173248634, 2.8538807002529936],
					[57.127900182835283, 2.853880675479346],
					[57.127900197381422, 2.8538806233901801],
					[57.127900199661511, 2.8538805965202649],
					[57.127794599733733, 2.8482055974444989],
					[57.127805800137374, 2.8426361181811823],
					[57.129080795848949, 2.8359194414963418],
					[57.130350194313188, 2.8306389475587697],
					[57.13035019585611, 2.830638942333755],
					[57.130350197155792, 2.8306389321819081],
					[57.130350197724546, 2.8306389254637061],
					[57.132527998065655, 2.8143333256955922],
					[57.13252799936398, 2.8143333155426604],
					[57.13252800017451, 2.8143332955357963],
					[57.132527999686722, 2.8143332856818768],
					[57.132097400064417, 2.8080889004072183],
					[57.132464098982616, 2.8025111225467794],
					[57.132972396642927, 2.7995111326412991],
					[57.132972373733082, 2.7995110520315003],
					[57.132972274256538, 2.7995109345917184],
					[57.132972196878313, 2.7995108995539058],
					[57.126316697367457, 2.79961649954013],
					[57.126316646790336, 2.7996165146481422],
					[57.126316561958539, 2.7996165653051253],
					[57.126316525999243, 2.7996166027957483],
					[57.124488726141394, 2.8028555020198556],
					[57.124488718674129, 2.8028555148609735],
					[57.124488709260675, 2.8028555429286706],
					[57.124488703742365, 2.8028555587541844],
					[57.123730420555709, 2.8063720824831981],
					[57.120644281614489, 2.8086165372481049],
					[57.120644260264541, 2.8086165606888107],
					[57.120644224060086, 2.8086166114485271],
					[57.12064420920558, 2.80861663876754],
					[57.119427652215663, 2.8124387068489427],
					[57.116311090106791, 2.8125887002032428],
					[57.116311049269989, 2.8125887120029844],
					[57.116310973767774, 2.8125887511216834],
					[57.116310940888496, 2.8125887781417593],
					[57.109827727307398, 2.8210636657588961],
					[57.107527860993294, 2.820324810075618],
					[57.107527803268198, 2.8203248081370167],
					[57.107527696748924, 2.8203248391731623],
					[57.107527647873567, 2.820324870506695],
					[57.105160948418529, 2.8230776701205902],
					[57.105160939081621, 2.8230776816079413],
					[57.105160922437427, 2.8230777092077681],
					[57.105160915048998, 2.8230777236791251],
					[57.101149815068801, 2.8327805237039807],
					[57.101149814337759, 2.8327805271348532],
					[57.101149811901571, 2.8327805325044602],
					[57.10114981027742, 2.8327805360842184],
					[57.096933117233704, 2.8453666186465276],
					[57.089072025893394, 2.8591027011391148],
					[57.089072018661859, 2.8591027188781264],
					[57.089072006797522, 2.8591027522710557],
					[57.089072002245402, 2.8591027695655211],
					[57.088616502044957, 2.8621527697250149],
					[57.088616502845092, 2.8621528043241677],
					[57.088616515001881, 2.8621528684641828],
					[57.088616526439154, 2.8621528996455377],
					[57.090435870718416, 2.8653389028307066],
					[57.087099844714523, 2.8714165655663795],
					[57.083358403920329, 2.8742246720293867],
					[57.078861289807612, 2.8604305380963586],
					[57.07886121992447, 2.8604304719709037],
					[57.078861055771156, 2.860430429743678],
					[57.078860963367923, 2.8604304549857957],
					[57.073233199157436, 2.8657387208678702],
					[57.066955545737699, 2.8674887078109261],
					[57.06695547263103, 2.8674887959475006],
					[57.066955459322941, 2.8674889832776516],
				],
			],
		},
		{
			fldNpdidField: "26465170",
			wlbNpdidWellbore: "6350",
			fldName: "MARIA",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6406/3-8",
			fldDiscoveryYear: "2010",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=26465170",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=26465170",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "475 BS",
			cmpLongName: "Wintershall Dea Norge AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=6350",
			fldHcType: "OIL/GAS",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "35318843",
			fldNpdidOwner: "5107669",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[64.919269200042308, 6.9060194118194387],
					[64.919280400327025, 6.9062306057503848],
					[64.919280400211591, 6.9064389004239954],
					[64.919280399713728, 6.9066499996128483],
					[64.919280399843146, 6.9068611008495751],
					[64.919280399872832, 6.9068611029622646],
					[64.919280399902505, 6.906861105074956],
					[64.919280399932191, 6.9068611071876473],
					[64.91928870025933, 6.9070694037778209],
					[64.919288700233551, 6.9072806002311857],
					[64.919288699606696, 6.9074888991497065],
					[64.919288699636354, 6.9074889012624006],
					[64.919288699725385, 6.9074889076004959],
					[64.919288699755043, 6.90748890971319],
					[64.919299800221523, 6.9077111058910461],
					[64.919299799830483, 6.9079194006106741],
					[64.919299800423303, 6.9081305991448474],
					[64.919299800431546, 6.9083389001426738],
					[64.919299799564541, 6.908338902325255],
					[64.91929979965353, 6.908338908663338],
					[64.919299799683188, 6.9083389107760347],
					[64.919310900126888, 6.9085500056308566],
					[64.919310900166479, 6.9087611006055969],
					[64.919310900160653, 6.9089693995220287],
					[64.919310900190311, 6.9089694016347263],
					[64.919310900249599, 6.9089694058601197],
					[64.919310900279257, 6.9089694079728341],
					[64.919319199754028, 6.9091806050184772],
					[64.919319199560661, 6.9093999993239485],
					[64.919319200278593, 6.9096111005828913],
					[64.919319200308252, 6.9096111026956075],
					[64.919319200367525, 6.9096111069210036],
					[64.919319200426813, 6.9096111111463996],
					[64.919330399794489, 6.9098194049357984],
					[64.919330400325919, 6.9100305993217441],
					[64.919330400368992, 6.9102389003927822],
					[64.91933039986921, 6.9104499996473843],
					[64.919330399898826, 6.9104500017600836],
					[64.919330399958085, 6.910450005985485],
					[64.919330399987729, 6.9104500080982021],
					[64.919338700427133, 6.9106611040318384],
					[64.919338700426493, 6.9108694008871003],
					[64.919338700124968, 6.9110889009597241],
					[64.919338700154597, 6.9110889030724252],
					[64.919338700213842, 6.9110889072978283],
					[64.919338700273073, 6.911088911523251],
					[64.919349800382577, 6.9113000054953568],
					[64.919349800114176, 6.9115111005631382],
					[64.919349799851943, 6.9117193995676969],
					[64.91934979991116, 6.9117194037931036],
					[64.919349799970377, 6.9117194080185307],
					[64.919349800000006, 6.9117194101312318],
					[64.919360899742045, 6.9119306059462442],
					[64.919360899731117, 6.9121389007517529],
					[64.919360900127174, 6.9123500000129496],
					[64.919360900224149, 6.9125610992792463],
					[64.91936090025375, 6.912561101391951],
					[64.919360900312952, 6.9125611056173808],
					[64.919360900342554, 6.9125611077300855],
					[64.919369199657083, 6.9127806031734291],
					[64.919369200281182, 6.9129889000368063],
					[64.919369200369985, 6.9131999993140605],
					[64.919369200429173, 6.9132000035394743],
					[64.919369199591685, 6.9132000078346314],
					[64.919369199650859, 6.9132000120600443],
					[64.919380399977115, 6.9134111055163583],
					[64.919380399690581, 6.9136194003689564],
					[64.919380400276879, 6.9138305990469568],
					[64.919380400389372, 6.9140389001779594],
					[64.919380401581847, 6.9140389212353677],
					[64.919380412066189, 6.9140389648356759],
					[64.919380418638525, 6.9140389854749333],
					[64.919480417572728, 6.9142500833004172],
					[64.919569217267622, 6.9144500798466639],
					[64.919660917044908, 6.9146611804554148],
					[64.919660917971115, 6.9146611824984916],
					[64.919660919823599, 6.9146611865845999],
					[64.91966092074982, 6.9146611886276581],
					[64.919760921422409, 6.9148611889229779],
					[64.919760963318993, 6.9148612321985317],
					[64.91976106463126, 6.9148612877778586],
					[64.919761124914004, 6.9148612978992032],
					[64.919849873668298, 6.9148502164669905],
					[64.919938716442275, 6.9150611787785561],
					[64.919938716442275, 6.9150611787785561],
					[64.919938718294702, 6.9150611828647577],
					[64.919938718294702, 6.9150611828647577],
					[64.920030417077669, 6.9152611801678603],
					[64.920119215666347, 6.9154694773145566],
					[64.920119216622084, 6.9154694814704456],
					[64.92011922029738, 6.9154694875301699],
					[64.920119221223615, 6.9154694895732876],
					[64.920219218884171, 6.9156694858597918],
					[64.920310917240428, 6.9158806803194706],
					[64.920310917240428, 6.9158806803194706],
					[64.920399816991591, 6.9160806805011443],
					[64.920399858946908, 6.9160807280052072],
					[64.920399962978237, 6.9160807854943256],
					[64.920400025083765, 6.9160807975921772],
					[64.920488776071721, 6.9160696160851609],
					[64.920580417251884, 6.9162806809413162],
					[64.920580418178119, 6.9162806829844969],
					[64.920580420030518, 6.9162806870708602],
					[64.920580420956696, 6.9162806891140622],
					[64.920680418320998, 6.9164806833277339],
					[64.920769215974474, 6.9166889789120942],
					[64.920769216900695, 6.9166889809553043],
					[64.920769217826873, 6.9166889829985339],
					[64.920769217826873, 6.9166889829985339],
					[64.920860917890437, 6.916888981197614],
					[64.920949817083454, 6.9170889802883915],
					[64.920949818009632, 6.9170889823316308],
					[64.920949818935824, 6.9170889843748675],
					[64.920949818965369, 6.9170889864877028],
					[64.921049817760419, 6.9173000822917352],
					[64.921138717245654, 6.9175000798552047],
					[64.921230400240859, 6.9177111428424416],
					[64.921230400452473, 6.9179194008886657],
					[64.921230402570544, 6.917919423991016],
					[64.921230413053777, 6.9179194675958513],
					[64.921230421418926, 6.9179194880983372],
					[64.921330399819851, 6.9181194480263661],
					[64.921330400392563, 6.918330600326982],
					[64.921330401554911, 6.9183306192732514],
					[64.921330410185675, 6.9183306587917937],
					[64.921330415831278, 6.9183306773902915],
					[64.9214192023246, 6.9185389467412364],
					[64.921430399783262, 6.918750005422841],
					[64.92143040043203, 6.9189610992256148],
					[64.921430401623766, 6.9189611202848837],
					[64.921430410254359, 6.9189611598037768],
					[64.921430415899877, 6.9189611784024594],
					[64.921519199578853, 6.9191694401991111],
					[64.921519199653588, 6.9193806002794478],
					[64.921519202668108, 6.9193806233126418],
					[64.921519213180432, 6.9193806690314057],
					[64.921519220648705, 6.9193806896040941],
					[64.921619199651062, 6.9195806463688179],
					[64.9216191998608, 6.9197889009289799],
					[64.92161920191964, 6.9197889198060265],
					[64.921619209653343, 6.9197889593949595],
					[64.92161921712156, 6.9197889799678398],
					[64.921710902166893, 6.9200000457110473],
					[64.921719199972458, 6.9202111034716944],
					[64.921719199863873, 6.9204193989679448],
					[64.921719201952072, 6.9204194199580176],
					[64.921719210641271, 6.9204194637036123],
					[64.921719218079943, 6.9204194821638216],
					[64.921810899837098, 6.9206194441416313],
					[64.921810899594576, 6.9208389001058066],
					[64.921810902608883, 6.9208389231394154],
					[64.921810913120751, 6.9208389688592549],
					[64.921810920588825, 6.9208389894325562],
					[64.921910900255583, 6.9210389472704463],
					[64.921910899744603, 6.9212500003276896],
					[64.92191090180323, 6.9212500192050612],
					[64.921910910433226, 6.921250058725386],
					[64.921910916078446, 6.9212500773249062],
					[64.92199980014243, 6.9214611408523981],
					[64.92199980045288, 6.921669400314916],
					[64.921999799585677, 6.9216694024972893],
					[64.921999799674111, 6.9216694088360846],
					[64.921999799703585, 6.9216694109490096],
					[64.922010900365038, 6.9218806096405263],
					[64.922010903320185, 6.9218806284485774],
					[64.922010910994445, 6.9218806638128436],
					[64.922010916610134, 6.92188068029962],
					[64.922099800213658, 6.9220806417795577],
					[64.922099799631297, 6.9222888999197867],
					[64.922099801748701, 6.9222889230232409],
					[64.922099812201211, 6.9222889645182573],
					[64.922099818772452, 6.9222889851616323],
					[64.922199800393784, 6.9225000446301719],
					[64.922199799613907, 6.9227110998554204],
					[64.922199801701794, 6.922711120846051],
					[64.922199811228055, 6.922711160297883],
					[64.922199816902591, 6.9227111810108326],
					[64.922288700240102, 6.9229111423616025],
					[64.922288700265511, 6.9231193997355032],
					[64.922288700324415, 6.9231194039614223],
					[64.922288700383319, 6.923119408187322],
					[64.922288700412764, 6.9231194103002709],
					[64.922299799781555, 6.9233389099006208],
					[64.922299802736575, 6.9233389287090334],
					[64.922299811307113, 6.92333896400471],
					[64.922299816922646, 6.9233389804919714],
					[64.92238870029253, 6.9235389424932388],
					[64.922388699839217, 6.923749999090119],
					[64.922388701956422, 6.9237500221939392],
					[64.92238871243795, 6.9237500658029552],
					[64.922388719008993, 6.9237500864469119],
					[64.922488700256508, 6.9239611450566541],
					[64.922488699657208, 6.9241693997120315],
					[64.922488701744868, 6.9241694207029862],
					[64.92248871130019, 6.9241694622687309],
					[64.922488717871175, 6.9241694829128679],
					[64.922580399759255, 6.9243694440417665],
					[64.922580400384916, 6.9245805992937095],
					[64.92258040160533, 6.9245806224671149],
					[64.922580412057201, 6.9245806639637948],
					[64.922580419554194, 6.9245806866517263],
					[64.922680417664324, 6.9247889838290817],
					[64.922769216909288, 6.9249889809891583],
					[64.922860899596699, 6.9252000414876118],
					[64.922860900359566, 6.9254110994473548],
					[64.922860903373149, 6.9254111224823891],
					[64.922860912986962, 6.9254111682751516],
					[64.922860921351088, 6.9254111887812275],
					[64.922960917567849, 6.9256111845705126],
					[64.923049815895965, 6.925819479200702],
					[64.923049816822015, 6.9258194812444023],
					[64.923138702130217, 6.9260194460821944],
					[64.923149799627254, 6.9262306109808733],
					[64.923149802552516, 6.926230627677163],
					[64.923149811122371, 6.9262306629748869],
					[64.923149815840944, 6.9262306795325967],
					[64.923238716031221, 6.9264389795901709],
					[64.923238716927855, 6.9264389795208787],
					[64.923238717853934, 6.9264389815646128],
					[64.923238717883351, 6.9264389836776417],
					[64.923330417734277, 6.9266389819187539],
					[64.923419202248681, 6.9268389465763081],
					[64.923430400399994, 6.9270611100651722],
					[64.923430403354544, 6.9270611288747954],
					[64.923430411027539, 6.9270611642424287],
					[64.923430416642674, 6.9270611807311857],
					[64.923519217206206, 6.9272611800966644],
					[64.923519217235594, 6.9272611822097163],
					[64.923519218132242, 6.9272611821404499],
					[64.9236109168327, 6.9274611810576516],
					[64.923699816070993, 6.9276694781389763],
					[64.923699816997029, 6.9276694801827894],
					[64.92369981977518, 6.9276694863142465],
					[64.923699820701245, 6.9276694883580587],
					[64.923799799736912, 6.9278694470237872],
					[64.923799800260056, 6.9280888995135017],
					[64.92379980234719, 6.9280889205058145],
					[64.923799810975382, 6.9280889600311593],
					[64.923799816649151, 6.9280889807464918],
					[64.923888717200043, 6.9282889805581478],
					[64.923888717229403, 6.9282889826712317],
					[64.923888718126094, 6.9282889826019955],
					[64.923980418093265, 6.9284889833529357],
					[64.923980418093265, 6.9284889833529357],
					[64.923980419019315, 6.9284889853967968],
					[64.923980419019315, 6.9284889853967968],
					[64.924080400389144, 6.9287000440552777],
					[64.924080399664405, 6.9289111008472029],
					[64.924080401751411, 6.928911121839799],
					[64.92408041127608, 6.9289111612965906],
					[64.924080416949735, 6.9289111820123326],
					[64.924169216703177, 6.929111181645184],
					[64.924169217629185, 6.9291111836890833],
					[64.924169219451869, 6.9291111856637748],
					[64.924169220377934, 6.9291111877076901],
					[64.92426921898668, 6.9293194839679355],
					[64.924360899874031, 6.9295194437900953],
					[64.924360900242803, 6.9297305999421974],
					[64.924360902329738, 6.929730620935076],
					[64.924360910031467, 6.9297306584178271],
					[64.924360915705023, 6.9297306791339599],
					[64.924449816140012, 6.9299389786547758],
					[64.924449817066048, 6.9299389806987231],
					[64.924449819844114, 6.9299389868305878],
					[64.924449820770121, 6.9299389888745369],
					[64.924549817740129, 6.9301389832415454],
					[64.924638700201655, 6.9303500390741881],
					[64.924638699843698, 6.930561100088898],
					[64.92463870193049, 6.9305611210820626],
					[64.924638710587374, 6.9305611627225714],
					[64.924638718054126, 6.9305611833007736],
					[64.924730418385252, 6.930761182345079],
					[64.924730419311288, 6.930761184389076],
					[64.924730419340619, 6.9307611865022283],
					[64.924730420237267, 6.9307611864330756],
					[64.924830399750078, 6.9309694458432984],
					[64.924830399781072, 6.9311805992465629],
					[64.92483040186778, 6.9311806202399264],
					[64.924830411421141, 6.9311806618118146],
					[64.924830417065195, 6.9311806804154346],
					[64.924919202399124, 6.9313806474970914],
					[64.9249303998767, 6.9315889053808046],
					[64.924930399667616, 6.9318110999852527],
					[64.924930401754239, 6.931811120978745],
					[64.924930411278112, 6.9318111604378263],
					[64.924930416951383, 6.9318111811548384],
					[64.925019200432899, 6.932011142692776],
					[64.925019200066487, 6.9322193993704593],
					[64.925019200125135, 6.9322194035968314],
					[64.925019200183783, 6.9322194078231858],
					[64.9250192002131, 6.9322194099363621],
					[64.925030399646431, 6.9324306113028964],
					[64.925030402570982, 6.9324306280010415],
					[64.925030411110072, 6.9324306611900548],
					[64.925030415827962, 6.9324306777500215],
					[64.925119199621051, 6.9326389409056715],
					[64.925119200428568, 6.9328499990690959],
					[64.925119199590569, 6.9328500033645506],
					[64.925119199649188, 6.9328500075909405],
					[64.925119199707808, 6.9328500118173126],
					[64.925130400400988, 6.9330611104257756],
					[64.925130403354785, 6.9330611292372586],
					[64.925130411026387, 6.9330611646088647],
					[64.92513041664084, 6.933061181099907],
					[64.925219200165571, 6.9332611418031256],
					[64.925219199870369, 6.9334805991417143],
					[64.92521920195675, 6.9334806201355654],
					[64.92521921064214, 6.9334806638911406],
					[64.925219218079178, 6.9334806823573922],
					[64.925310902198689, 6.933680648031153],
					[64.925319199775643, 6.9338889045684589],
					[64.925319200212641, 6.9341000001902042],
					[64.925319202269648, 6.9341000190709812],
					[64.925319210028903, 6.9341000607827619],
					[64.925319216569221, 6.9341000793182976],
					[64.925410900151434, 6.9343111413614853],
					[64.925410900167179, 6.9345194008569564],
					[64.925410900196468, 6.9345194029701664],
					[64.925410900225742, 6.9345194050833774],
					[64.925410900255045, 6.9345194071965874],
					[64.925419199742308, 6.934730607169926],
					[64.925419202695906, 6.9347306259817802],
					[64.925419212218983, 6.934730665442645],
					[64.92541921786254, 6.9347306840474285],
					[64.92551090017372, 6.934930644932952],
					[64.925510899579393, 6.9351500008744731],
					[64.925510902591512, 6.9351500239128949],
					[64.925510913069729, 6.9351500675314721],
					[64.925510921432533, 6.9351500880426178],
					[64.925610899840748, 6.9353500472183605],
					[64.925610900290494, 6.9355611005847706],
					[64.925610899987987, 6.9357693996309795],
					[64.925610902074027, 6.9357694206253218],
					[64.925610909774292, 6.9357694581115696],
					[64.925610916343686, 6.9357694787609629],
					[64.925699801935693, 6.9359806454340474],
					[64.925710899580039, 6.9361889058917328],
					[64.92571090029702, 6.9364000001472323],
					[64.925710902382974, 6.9364000211417025],
					[64.925710911009006, 6.9364000606725611],
					[64.925710916681624, 6.9364000813911577],
					[64.925799802440679, 6.9366000471901978],
					[64.925810900336302, 6.9368194107463719],
					[64.925810903260341, 6.9368194274454895],
					[64.925810910960323, 6.9368194649323218],
					[64.925810916574378, 6.9368194814245934],
					[64.925899799865746, 6.9370194410468313],
					[64.925899800288533, 6.937230600205849],
					[64.92589980032038, 6.9374388992137641],
					[64.925899802435438, 6.9374389223217197],
					[64.925899812016354, 6.9374389660107463],
					[64.925899818585521, 6.9374389866607737],
					[64.925999800180008, 6.9376500442147968],
					[64.925999800072205, 6.937861100487944],
					[64.925999800121943, 6.9380694002179899],
					[64.925999803133564, 6.9380694232571694],
					[64.925999812743527, 6.938069469059867],
					[64.925999821105933, 6.9380694895722996],
					[64.926099799884639, 6.9382694460527228],
					[64.926099800160799, 6.938488899530129],
					[64.926099802246469, 6.9384889205250699],
					[64.926099810871918, 6.9384889600571817],
					[64.926099817440942, 6.9384889807076027],
					[64.926188700218162, 6.9386889415565598],
					[64.926188700437095, 6.9388999992023841],
					[64.926188699598868, 6.9389000034978476],
					[64.926188699657331, 6.9389000077244027],
					[64.92618869971578, 6.9389000119509587],
					[64.926199800177898, 6.9391111117536708],
					[64.926199803101682, 6.9391111284532849],
					[64.926199810742645, 6.9391111617147674],
					[64.926199816356458, 6.9391111782077601],
					[64.926288699967458, 6.9393194409851553],
					[64.926288699801844, 6.9395306009393183],
					[64.926288699831062, 6.9395306030526056],
					[64.926288699889497, 6.9395306072791776],
					[64.926288699947932, 6.9395306115057709],
					[64.926299800290238, 6.939738911342948],
					[64.926299803213993, 6.9397389280427104],
					[64.926299810883989, 6.9397389634177875],
					[64.926299817423626, 6.9397389819554016],
					[64.926388700244374, 6.9399389419746251],
					[64.926388700401816, 6.9401610992235367],
					[64.926388701590611, 6.940161120287681],
					[64.926388711170702, 6.9401611639784884],
					[64.926388717710282, 6.9401611825162668],
					[64.926480402373585, 6.9403611473567208],
					[64.926488700365894, 6.9405694038625318],
					[64.926488699798142, 6.940780599164361],
					[64.9264887018835, 6.9407806201597895],
					[64.926488711434189, 6.9407806617376995],
					[64.926488717077049, 6.9407806803445045],
					[64.926580399707547, 6.9409889411273529],
					[64.926580400236432, 6.9411999995201485],
					[64.926580400265607, 6.9412000016334616],
					[64.926580400324013, 6.9412000058601038],
					[64.926580400353217, 6.9412000079734151],
					[64.926588699648789, 6.9414111086518311],
					[64.926588702601549, 6.9414111274652512],
					[64.92658871209369, 6.9414111648168966],
					[64.926588717736422, 6.9414111834239254],
					[64.926680399695186, 6.9416111435446952],
					[64.926680400416387, 6.9418305993268392],
					[64.926680403427426, 6.9418306223670339],
					[64.926680413036252, 6.9418306681722566],
					[64.926680421398132, 6.9418306886863874],
					[64.926780400326479, 6.9420306469750521],
					[64.926780400114637, 6.9422388995474735],
					[64.926780402199796, 6.9422389205432466],
					[64.926780409898427, 6.9422389580330073],
					[64.926780415570249, 6.9422389787536716],
					[64.926869200424491, 6.942450039318615],
					[64.926869199787973, 6.9426610999489808],
					[64.926869202798898, 6.9426611229894295],
					[64.926869213304116, 6.9426611687265138],
					[64.926869220769206, 6.9426611893097929],
					[64.926969200329623, 6.9428611477425042],
					[64.926969199829344, 6.9430693996175812],
					[64.926969201914417, 6.9430694206135657],
					[64.926969209641967, 6.9430694602171776],
					[64.926969217107015, 6.9430694808006646],
					[64.927060899647216, 6.9432806428218559],
					[64.927060900414673, 6.943488899507674],
					[64.927060899547158, 6.9434889016897827],
					[64.927060899605493, 6.9434889059164879],
					[64.92706089963464, 6.943488908029841],
					[64.927069200126567, 6.9437000086778378],
					[64.927069203079029, 6.9437000274918699],
					[64.927069211673953, 6.9437000649137293],
					[64.927069218213049, 6.9437000834528471],
					[64.927160899674178, 6.9439000442259635],
					[64.927160900091934, 6.944119400095988],
					[64.92716090310266, 6.9441194231368542],
					[64.927160912710747, 6.9441194689437271],
					[64.927160921072314, 6.9441194894589406],
					[64.92726090037236, 6.9443194469227718],
					[64.927260900115641, 6.9445305993027278],
					[64.927260902200487, 6.9445306202990569],
					[64.927260909927668, 6.9445306599035241],
					[64.927260915570031, 6.94453067851168],
					[64.927349800292276, 6.9447389414970848],
					[64.927349799925366, 6.9449499993470436],
					[64.927349802935922, 6.9449500223881691],
					[64.927349813440443, 6.9449500681269773],
					[64.927349820905249, 6.9449500887112796],
					[64.927449799940575, 6.9451500463891191],
					[64.927449799832289, 6.9453611007399623],
					[64.927449801887889, 6.9453611196231195],
					[64.927449809614842, 6.945361159228109],
					[64.927449816182914, 6.9453611798812789],
					[64.927538702445361, 6.9455694468869531],
					[64.927549800388448, 6.9457806041185295],
					[64.927549799962947, 6.9459889004478601],
					[64.927549802047608, 6.9459889214445312],
					[64.927549810671039, 6.945988960981218],
					[64.927549817239026, 6.9459889816346214],
					[64.927638700310069, 6.9461889414687299],
					[64.927638700033498, 6.9464111000822735],
					[64.927638703043868, 6.946411123123811],
					[64.927638712651273, 6.9464111689323289],
					[64.927638721012528, 6.946411189448626],
					[64.927738699783902, 6.9466111461474815],
					[64.92773870028077, 6.9468193993750935],
					[64.927738702365261, 6.9468194203719831],
					[64.927738710091859, 6.9468194599778226],
					[64.927738716659704, 6.9468194806315759],
					[64.927830399949812, 6.9470306420655561],
					[64.927830399920964, 6.9472389002415484],
					[64.927830402931207, 6.9472389232833462],
					[64.927830413435018, 6.9472389690238767],
					[64.927830420899497, 6.9472389896091915],
					[64.927930400306664, 6.9474389464637047],
					[64.92793039960064, 6.9476500002163508],
					[64.927930401655928, 6.9476500191000294],
					[64.927930410278961, 6.9476500586378132],
					[64.92793041592094, 6.9476500772470731],
					[64.928019201885775, 6.9478611451201813],
					[64.928030400343076, 6.9480694113145747],
					[64.928030403265907, 6.9480694280163338],
					[64.928030410933943, 6.9480694633961004],
					[64.928030416575851, 6.9480694820055175],
					[64.928119199808663, 6.9482694428322418],
					[64.928119200224373, 6.9484805994664738],
					[64.928119199828416, 6.9487000009139637],
					[64.928119202838417, 6.948700023956178],
					[64.928119213312684, 6.948700067584336],
					[64.928119220776992, 6.9487000881702787],
					[64.928219199949382, 6.9489000461586574],
					[64.92821919984236, 6.9491110998984267],
					[64.928219201926538, 6.9491111208958749],
					[64.928219210549202, 6.9491111604345388],
					[64.928219217116748, 6.9491111810892265],
					[64.92831090206532, 6.9493194468378414],
					[64.928319200382177, 6.9495306074536023],
					[64.928319203333871, 6.9495306262691674],
					[64.928319211956406, 6.9495306658081137],
					[64.928319217569083, 6.9495306823045997],
					[64.928410899870173, 6.9497306442145792],
					[64.928410899581976, 6.9499389003447174],
					[64.928410901636994, 6.9499389192289129],
					[64.92841091025943, 6.9499389587681284],
					[64.928410915901139, 6.9499389773782232],
					[64.928499801712789, 6.9501500445263034],
					[64.928510899897859, 6.9503611113675561],
					[64.92851090282042, 6.9503611280698667],
					[64.92851091138462, 6.9503611633824152],
					[64.928510917026244, 6.9503611819926521],
					[64.928599800272153, 6.9505611419032816],
					[64.928599800242182, 6.9507805999634895],
					[64.928599802326133, 6.9507806209613685],
					[64.928599810948342, 6.9507806605011346],
					[64.928599816618984, 6.9507806812250239],
					[64.928688702055453, 6.9509806472138687],
					[64.928699799909097, 6.9511889040543746],
					[64.928699799785576, 6.9513999994661226],
					[64.928699801869442, 6.9514000204641295],
					[64.928699809594846, 6.9514000600727197],
					[64.92869981613299, 6.9514000786148502],
					[64.928788701571037, 6.9516111443035955],
					[64.928799799559599, 6.9518194105806561],
					[64.928799803407742, 6.9518194293283253],
					[64.92879981107491, 6.951819464710189],
					[64.928799816687302, 6.951819481207453],
					[64.928888699700892, 6.9520194416413563],
					[64.928888700144526, 6.952230599782327],
					[64.928888702228264, 6.9522306207805462],
					[64.928888710850103, 6.9522306603212103],
					[64.928888717417223, 6.9522306809771806],
					[64.928980417138476, 6.9524389802013307],
					[64.928980418064214, 6.9524389822464014],
					[64.928980419915632, 6.952438986336567],
					[64.928980420841313, 6.9524389883816413],
					[64.929080417726396, 6.9526389840613794],
					[64.929169199754625, 6.9528500392269228],
					[64.92916920035394, 6.9530611000537297],
					[64.929169202437578, 6.9530611210522357],
					[64.929169211088222, 6.9530611627071117],
					[64.929169217655229, 6.953061183363503],
					[64.92926091797257, 6.9532611831279558],
					[64.929260918869275, 6.9532611830595359],
					[64.929260918898294, 6.953261185173095],
					[64.929260919823975, 6.9532611872182191],
					[64.929360918229236, 6.9534694837682878],
					[64.929449799774559, 6.9536694411063209],
					[64.929449799629424, 6.9538805993383743],
					[64.929449801712948, 6.9538806203371459],
					[64.929449810334347, 6.9538806598791902],
					[64.929449815975531, 6.953880678490842],
					[64.92953871632993, 6.954088978895034],
					[64.929538717255625, 6.954088980940206],
					[64.929538720032696, 6.9540889870757221],
					[64.929538720958391, 6.9540889891208932],
					[64.929638719095138, 6.9542889842728277],
					[64.929730399793371, 6.9545000417769751],
					[64.929730399776005, 6.9547111005800586],
					[64.929730402785125, 6.9547111236243415],
					[64.929730413257658, 6.9547111672574848],
					[64.929730420750104, 6.954711189959923],
					[64.929830418002894, 6.9549111846347325],
					[64.929919216214458, 6.9551194779314356],
					[64.929919217140139, 6.9551194799766725],
					[64.929919218065805, 6.9551194820219102],
					[64.929919218065805, 6.9551194820219102],
					[64.930010899711249, 6.9553194448510478],
					[64.930010899845087, 6.9555306009923017],
					[64.930010901928355, 6.9555306219916444],
					[64.930010912400647, 6.9555306656255649],
					[64.930010919864017, 6.9555306862148667],
					[64.930110917647283, 6.9557389841662198],
					[64.930199816253847, 6.9559389784820027],
					[64.930288716067807, 6.956150077915777],
					[64.930288716096797, 6.9561500800293992],
					[64.930288717919112, 6.9561500820063973],
					[64.930288717919112, 6.9561500820063973],
					[64.930380418387173, 6.9563500834285259],
					[64.930380418387173, 6.9563500834285259],
					[64.930380419312854, 6.9563500854738587],
					[64.930380419312854, 6.9563500854738587],
					[64.930480399894762, 6.9565611454056269],
					[64.930480399743374, 6.9567694000976505],
					[64.930480401826472, 6.9567694210974782],
					[64.930480411343794, 6.9567694605736561],
					[64.930480417013598, 6.9567694813002703],
					[64.930569217295002, 6.956969480709188],
					[64.930660916598285, 6.9571806799524039],
					[64.930660918420614, 6.9571806819294775],
					[64.930660920271905, 6.9571806860202257],
					[64.930660921197557, 6.9571806880655895],
					[64.930760917660891, 6.9573806838782044],
					[64.930849816384963, 6.9575889783179123],
					[64.930849817310587, 6.9575889803633064],
					[64.93093871696145, 6.9577889797038894],
					[64.931030417284205, 6.9580000808115461],
					[64.931030418209858, 6.958000082856965],
					[64.931030420061134, 6.9580000869478251],
					[64.931030420986772, 6.9580000889932458],
					[64.931130418966646, 6.9582000840640914],
					[64.931219217176277, 6.9584000803679142],
					[64.931310916556384, 6.9586111794288854],
					[64.931310916585332, 6.9586111815425928],
					[64.931399816586492, 6.9588111812277376],
					[64.931399818408792, 6.9588111832049764],
					[64.93139981933443, 6.9588111852504726],
					[64.931399820260054, 6.9588111872959502],
					[64.931499817675515, 6.9590194846567561],
					[64.931588717048186, 6.9592194807606917],
					[64.931680416583035, 6.9594306806466655],
					[64.931680416583035, 6.9594306806466655],
					[64.931769216688764, 6.9596306806208137],
					[64.931769217614402, 6.9596306826663659],
					[64.931769219436688, 6.9596306846436873],
					[64.931769220362298, 6.9596306866892217],
					[64.931869219277885, 6.9598389855368925],
					[64.931960917136109, 6.9600389815463801],
					[64.932049816181163, 6.9602500784220114],
					[64.932049817106773, 6.9602500804676062],
					[64.932049819883574, 6.9602500866043373],
					[64.932049820809198, 6.9602500886499108],
					[64.932149819189533, 6.9604500846251449],
					[64.932238717416325, 6.960650079674684],
					[64.932330417029647, 6.9608611793748638],
					[64.93233041705858, 6.960861181488653],
					[64.93241921691525, 6.9610611803347764],
					[64.932419217840817, 6.9610611823804298],
					[64.932419218766412, 6.961061184426061],
					[64.932419219692008, 6.9610611864716931],
					[64.932519219177053, 6.9612694849182475],
					[64.932610916786487, 6.9614694799529522],
					[64.932699815967723, 6.961680677508193],
					[64.932699815996628, 6.9616806796220132],
					[64.93269981689329, 6.9616806795538686],
					[64.932699816922195, 6.9616806816676888],
					[64.93278871704517, 6.9618806803926008],
					[64.932788717970766, 6.9618806824382879],
					[64.93278871889639, 6.9618806844839751],
					[64.932788719821957, 6.9618806865296836],
					[64.932888718984913, 6.9620889844867104],
					[64.932980417242845, 6.9622889807107802],
					[64.933069215977355, 6.9625000771667107],
					[64.933069216006245, 6.9625000792805825],
					[64.933069217828503, 6.9625000812581943],
					[64.933069217857408, 6.9625000833720447],
					[64.933160918259929, 6.9627000821416667],
					[64.933160919185482, 6.9627000841874134],
					[64.933160920139969, 6.9627000883470158],
					[64.933160921036631, 6.9627000882789032],
					[64.93326091773028, 6.962900084609382],
					[64.933349816037008, 6.9631111776673489],
					[64.933349857977547, 6.9631112252678147],
					[64.933349962020017, 6.9631112850803714],
					[64.933350025018683, 6.9631112972243958],
					[64.933438775568362, 6.9631002167906804],
					[64.933530416680952, 6.9633111797753298],
					[64.933530416709814, 6.9633111818892193],
					[64.933619216672682, 6.9635111805974725],
					[64.933619258613078, 6.9635112281990175],
					[64.933619362626558, 6.9635112858998323],
					[64.9336194238318, 6.963511298181067],
					[64.93371098118746, 6.963500215309244],
					[64.933810918355661, 6.9637111824651363],
					[64.933899817378119, 6.9639111812539216],
					[64.933899853880575, 6.9639112250371991],
					[64.933899946237034, 6.9639112836251105],
					[64.93390000032656, 6.9639113006798308],
					[64.933988772113096, 6.9639113010363154],
					[64.934080416791247, 6.9641111801215922],
					[64.934169215855732, 6.9643194786868543],
					[64.934169256870362, 6.9643195242447504],
					[64.934169358222348, 6.9643195842679777],
					[64.934169417663043, 6.9643195988013531],
					[64.934260974631599, 6.9643113117706799],
					[64.934349817153773, 6.9645111804547524],
					[64.934349853656059, 6.9645112242395735],
					[64.934349946041166, 6.9645112849444839],
					[64.934350000101759, 6.9645112998867145],
					[64.934438776343654, 6.9645112997498924],
					[64.934538721383873, 6.9647111890894289],
					[64.93453875785724, 6.9647112307608561],
					[64.934538846568927, 6.9647112853971489],
					[64.934538899732857, 6.9647113004079468],
					[64.934630600359753, 6.9647112990680178],
					[64.934630605768689, 6.9647113007736916],
					[64.934630619218879, 6.9647112997529357],
					[64.934630624570076, 6.9647112972306555],
					[64.934719279120486, 6.9647002160870226],
					[64.93481091798472, 6.9649000824875875],
					[64.934810955383554, 6.9649001262056389],
					[64.93481104594629, 6.964900184935007],
					[64.93481110000684, 6.9649001998782625],
					[64.934900000104648, 6.9649002000690343],
					[64.934900006381412, 6.9649001995927184],
					[64.93490001893494, 6.9649001986400876],
					[64.934900025211675, 6.9649001981637735],
					[64.934988920981525, 6.9648890992139068],
					[64.935080472568288, 6.964880812121244],
					[64.935169215652664, 6.9650889769960891],
					[64.935169256695858, 6.9650890246708617],
					[64.935169358047546, 6.9650890846991773],
					[64.935169418384874, 6.9650890991667227],
					[64.935261117905853, 6.965080799349896],
					[64.935261119699206, 6.9650807992138173],
					[64.935261123285912, 6.9650807989416572],
					[64.935261125079307, 6.9650807988055741],
					[64.935349875195641, 6.9650696161076135],
					[64.935438715868472, 6.9652806776206395],
					[64.935438757808299, 6.9652807252281441],
					[64.93543886184996, 6.96528078505367],
					[64.935438923980612, 6.9652807993857335],
					[64.935530620711717, 6.9652695989706936],
					[64.935619274832092, 6.9652613127338876],
					[64.935710916644481, 6.9654694810451421],
					[64.935710957658728, 6.9654695266073849],
					[64.935711059010259, 6.9654695866383216],
					[64.935711119318682, 6.9654695989929509],
					[64.935800009334542, 6.9654612996878438],
					[64.935888900431067, 6.9654613002501522],
					[64.935888905811126, 6.9654612998419765],
					[64.935888918364668, 6.9654612988895641],
					[64.935888923744741, 6.9654612984813911],
					[64.935980483839401, 6.9654502146507635],
					[64.936080420965212, 6.9656500897827804],
					[64.936080458306108, 6.9656501292757174],
					[64.936080547046359, 6.9656501860322857],
					[64.936080600181299, 6.9656501989316482],
					[64.936169399728556, 6.9656502007007139],
					[64.936169406005291, 6.9656502002245491],
					[64.936169417662143, 6.9656501993402387],
					[64.936169423938907, 6.9656501988640711],
					[64.936260976872518, 6.9656391160904452],
					[64.936349817332214, 6.9658389807404557],
					[64.936349853834116, 6.9658390245303092],
					[64.93634994618985, 6.9658390831301009],
					[64.936350000279134, 6.9658391001901903],
					[64.936438900311103, 6.9658390991800641],
					[64.936438904823362, 6.965839100954093],
					[64.936438913761293, 6.9658390981597886],
					[64.936438918273566, 6.9658390999338158],
					[64.936530617770259, 6.9658307979873362],
					[64.936530619592475, 6.9658307999654259],
					[64.936530623179209, 6.9658307996933475],
					[64.936530624943714, 6.965830797443183],
					[64.936619276386395, 6.9658196177667495],
					[64.936710917300346, 6.9660306802767922],
					[64.936710959239946, 6.9660307278876985],
					[64.936711063252446, 6.9660307856047519],
					[64.936711125354179, 6.9660307978250415],
					[64.936800021576474, 6.9660195980424326],
					[64.936888775348507, 6.9660113126151257],
					[64.936980416859512, 6.9662194805345328],
					[64.93698045787356, 6.9662195261000486],
					[64.936980559224779, 6.9662195861365843],
					[64.93698061863644, 6.9662195985614286],
					[64.937069409039026, 6.9662113003708503],
					[64.9371609695676, 6.9662112998257806],
					[64.937249816564574, 6.9664111809537861],
					[64.937249859400765, 6.9664112284982487],
					[64.93724996341308, 6.9664112862178884],
					[64.937250024618123, 6.9664112985072855],
					[64.937338912358044, 6.9664001998459621],
					[64.937430600358226, 6.9664002008451353],
					[64.937430605738342, 6.9664002004371159],
					[64.937430619188532, 6.9664001994170768],
					[64.93743062456862, 6.9664001990090592],
					[64.937519420728577, 6.9663890987057968],
					[64.937611108591028, 6.966380799127605],
					[64.937699999647265, 6.9663808005237291],
					[64.937700005924029, 6.9663808000476983],
					[64.937700019374233, 6.9663807990276361],
					[64.937700024754292, 6.9663807986196122],
					[64.937788921890586, 6.966369599461343],
					[64.937880608851728, 6.9663612997858309],
					[64.937969400257828, 6.96636130019417],
					[64.937969405637887, 6.9663612997861373],
					[64.937969418191429, 6.9663612988340553],
					[64.937969423571502, 6.9663612984260217],
					[64.938061124407724, 6.9663501996694359],
					[64.938061125275581, 6.966350197487178],
					[64.938150012132311, 6.9663391004254471],
					[64.9382388995665, 6.9663390998115116],
					[64.938238904946573, 6.9663390994034682],
					[64.938238913913352, 6.966339098723398],
					[64.938238918396763, 6.9663390983833597],
					[64.93833061793255, 6.9663307996009918],
					[64.938330619725903, 6.9663307994649761],
					[64.938330623312666, 6.9663307991929431],
					[64.938330625077171, 6.9663307969426578],
					[64.938419412719753, 6.9663195992749198],
					[64.938510969646984, 6.9663195992951543],
					[64.938599817267033, 6.9665194801322565],
					[64.938599858309829, 6.9665195278152536],
					[64.938599958735367, 6.9665195858101434],
					[64.938600019043704, 6.9665195981683583],
					[64.938688909064169, 6.9665112991753402],
					[64.938780599739815, 6.9665113002738224],
					[64.93878060601655, 6.9665112997978023],
					[64.938780618570092, 6.9665112988457567],
					[64.938780624846871, 6.966511298369733],
					[64.938869423718103, 6.9665001997116152],
					[64.938961111972105, 6.9664890998440496],
					[64.939050000295225, 6.9664890996146784],
					[64.93905000477865, 6.9664890992746535],
					[64.939050013745458, 6.9664890985946055],
					[64.939050019125531, 6.9664890981865772],
					[64.939138919001977, 6.9664807981804273],
					[64.939138919898667, 6.9664807981124213],
					[64.939138922617559, 6.9664808000227589],
					[64.93913892441094, 6.9664807998867468],
					[64.939230612200959, 6.9664696003838209],
					[64.939319399977265, 6.9664695992342898],
					[64.9393194053862, 6.9664696009405995],
					[64.939319414324146, 6.9664695981461895],
					[64.9393194179397, 6.966469599988514],
					[64.939411118338327, 6.9664612990742647],
					[64.93941112013168, 6.9664612989382517],
					[64.939411122821738, 6.96646129873423],
					[64.939411124615091, 6.9664612985982171],
					[64.939500012332104, 6.9664501990893948],
					[64.939588899780915, 6.9664502008838562],
					[64.939588906057679, 6.9664502004078015],
					[64.939588917714516, 6.9664501995237025],
					[64.93958892399128, 6.9664501990476477],
					[64.939680621198562, 6.966439097990496],
					[64.93976941875593, 6.9664307989986725],
					[64.939769419652634, 6.9664307989306629],
					[64.939769423239341, 6.9664307986586271],
					[64.939769424136031, 6.9664307985906166],
					[64.939861111942506, 6.9664196007321531],
					[64.93994987009188, 6.9664195995123661],
					[64.940038716553744, 6.9666194808247219],
					[64.940038753952081, 6.9666195245535798],
					[64.940038846307502, 6.9666195831639151],
					[64.940038900396686, 6.9666196002278022],
					[64.940130600024446, 6.9666196009400654],
					[64.940130605404519, 6.9666196005320424],
					[64.940130614371313, 6.966619599852006],
					[64.940130618854738, 6.9666195995119864],
					[64.940219419079654, 6.9666112986352839],
					[64.94021941997633, 6.9666112985672797],
					[64.94021942266636, 6.9666112983632678],
					[64.94021942356305, 6.9666112982952626],
					[64.940311111837858, 6.966600200286523],
					[64.940400000146795, 6.9666002003512038],
					[64.940400006423559, 6.9666001998751668],
					[64.940400018977087, 6.9666001989230937],
					[64.940400025253837, 6.9666001984470585],
					[64.940488921040298, 6.9665890983227738],
					[64.940580617874147, 6.9665807997751958],
					[64.9405806196675, 6.9665807996391838],
					[64.940580623254235, 6.9665807993671569],
					[64.940580625018754, 6.9665807971166958],
					[64.940669412654344, 6.9665695992133285],
					[64.940761099720689, 6.9665696006709821],
					[64.940761105100762, 6.9665696002629369],
					[64.940761114067584, 6.9665695995828631],
					[64.940761119447657, 6.9665695991748198],
					[64.940850019308442, 6.9665612988118202],
					[64.940850020205133, 6.9665612987438115],
					[64.940850022895177, 6.966561298539788],
					[64.940850024688544, 6.9665612984037715],
					[64.940938912424755, 6.9665502006661297],
					[64.941019400015378, 6.966550200564666],
					[64.941019406292142, 6.9665502000886033],
					[64.941019417948979, 6.96655019920449],
					[64.941019424225757, 6.9665501987284264],
					[64.941111121451854, 6.9665390994543168],
					[64.941200008752844, 6.966530799814775],
					[64.941288775883862, 6.9665308003239277],
					[64.941388721083186, 6.9667306883935858],
					[64.94138875755597, 6.966730730078261],
					[64.941388847163608, 6.96673078466684],
					[64.941388900327325, 6.9667307996852346],
					[64.941480599940419, 6.9667308007012823],
					[64.941480606217198, 6.9667308002252515],
					[64.941480618770697, 6.9667307992731944],
					[64.941480625047461, 6.9667307987971654],
					[64.941569421622461, 6.9667195981704637],
					[64.941661118451336, 6.9667112996733769],
					[64.941661120244689, 6.9667112995373666],
					[64.941661122934704, 6.966711299333352],
					[64.941661124699223, 6.9667112970828073],
					[64.941750012437922, 6.9667001994619229],
					[64.94183059966845, 6.9667002006798349],
					[64.941830605945228, 6.9667002002037908],
					[64.941830619395404, 6.9667001991836974],
					[64.941830624775477, 6.9667001987756612],
					[64.941919420934383, 6.9666890995170281],
					[64.942011108761179, 6.966680799353032],
					[64.942099999741714, 6.9666807993874427],
					[64.942100006047298, 6.9666808010259569],
					[64.942100018600854, 6.9666808000738465],
					[64.942100024848784, 6.9666807974832237],
					[64.942188921984098, 6.9666695993542236],
					[64.942280608909527, 6.9666612990928742],
					[64.942369400268831, 6.9666613002530706],
					[64.942369405648904, 6.9666612998450148],
					[64.942369418202432, 6.9666612988928858],
					[64.942369423582505, 6.96666129848483],
					[64.942461124387279, 6.9666501986831255],
					[64.942461125283998, 6.9666501986151141],
					[64.9425500121108, 6.9666391004847315],
					[64.942638772025347, 6.9666390997078009],
					[64.942730417593879, 6.9668389819734209],
					[64.942730458607699, 6.9668390275495131],
					[64.942730559033137, 6.9668390855542945],
					[64.942730619370252, 6.9668391000295973],
					[64.94281940881757, 6.966830799748041],
					[64.942910975562171, 6.9668308005166111],
					[64.943010921101759, 6.967030688948177],
					[64.943010957574486, 6.9670307306357477],
					[64.943011047182011, 6.9670307852285243],
					[64.943011100345629, 6.9670308002483932],
					[64.943100000285099, 6.9670308007081774],
					[64.943100005665201, 6.9670308003001811],
					[64.943100019115406, 6.9670307992801943],
					[64.943100025392155, 6.9670307988042],
					[64.943188779133493, 6.9670196164718527],
					[64.943280417636586, 6.9672194834447785],
					[64.943280455005961, 6.967219525065004],
					[64.943280545567731, 6.9672195838202979],
					[64.943280599656873, 6.9672196008873515],
					[64.943369399948338, 6.9672196010469154],
					[64.943369405328397, 6.9672196006389537],
					[64.943369414295219, 6.9672195999590159],
					[64.943369417881939, 6.9672195996870405],
					[64.943460974838914, 6.9672113128474118],
					[64.943549817018891, 6.9674111806920873],
					[64.943549854417014, 6.9674112244276971],
					[64.943549945875418, 6.9674112831162276],
					[64.943549999964546, 6.9674113001838291],
					[64.943638899872852, 6.9674112997609043],
					[64.943638906149616, 6.9674112992849873],
					[64.943638917806467, 6.9674112984011414],
					[64.943638924112065, 6.9674113000399149],
					[64.943730474379265, 6.9674002178563423],
					[64.943819216143751, 6.9676111778588599],
					[64.943819258082854, 6.967611225484684],
					[64.943819362123563, 6.9676112853374272],
					[64.943819424253917, 6.9676112996790547],
					[64.94391097719695, 6.9676002157746515],
					[64.943999816984629, 6.9678000820381403],
					[64.943999854353791, 6.9678001236603002],
					[64.943999945840872, 6.9678001844658377],
					[64.943999999901123, 6.9678001994197771],
					[64.944088899808179, 6.9678002002615651],
					[64.944088906084957, 6.9678001997857288],
					[64.944088917741794, 6.9678001989020339],
					[64.944088924018558, 6.9678001984261977],
					[64.944180484147495, 6.9677891144961119],
					[64.944280420877092, 6.9679889889593172],
					[64.944280458246226, 6.9679890305821708],
					[64.944280546956733, 6.9679890852486412],
					[64.944280600120265, 6.9679891002710796],
					[64.944369400379188, 6.9679891008095973],
					[64.944369404862599, 6.9679891004697412],
					[64.944369413829392, 6.9679890997900316],
					[64.944369418312789, 6.9679890994501754],
					[64.944460972588914, 6.9679808129610414],
					[64.944549816286084, 6.9681889784438704],
					[64.944549857299521, 6.9681890240250803],
					[64.944549957753239, 6.9681890841535159],
					[64.944550018986874, 6.9681890985648085],
					[64.944638918849762, 6.9681807979593824],
					[64.944638919775244, 6.9681808000061878],
					[64.944638923361993, 6.9681807997343244],
					[64.944638924258669, 6.9681807996663583],
					[64.944730474062595, 6.9681696159472306],
					[64.944819215997427, 6.9683806772637782],
					[64.944819257936288, 6.9683807248925804],
					[64.944819362005475, 6.9683807868652679],
					[64.944819424106967, 6.9683807990943718],
					[64.944911120894801, 6.9683695984826022],
					[64.94499987287044, 6.9683613137869909],
					[64.945088715960154, 6.9685694783496102],
					[64.945088757002267, 6.9685695260471361],
					[64.9450888583237, 6.9685695839953929],
					[64.945088917763954, 6.9685695985437341],
					[64.945180618170497, 6.9685612984670833],
					[64.945180619963864, 6.9685612983311733],
					[64.945180622653922, 6.9685612981273088],
					[64.945180625343966, 6.9685612979234461],
					[64.945269276384224, 6.9685502163792528],
					[64.945360916551138, 6.9687611799513558],
					[64.945360959386576, 6.9687612275137543],
					[64.945361063397996, 6.9687612852594629],
					[64.945361124602769, 6.9687612975576414],
					[64.945450012410149, 6.9687502007663253],
					[64.945538775812977, 6.9687502002029946],
					[64.945638721170269, 6.9689500896798684],
					[64.945638763051349, 6.9689501330812789],
					[64.945638865211691, 6.9689501867345092],
					[64.945638923726435, 6.9689501992370433],
					[64.945730612042667, 6.9689391003290986],
					[64.945810975633123, 6.9689390996182921],
					[64.945910920713061, 6.9691389897013645],
					[64.945910961668616, 6.9691390310565691],
					[64.945911061167664, 6.9691390870297321],
					[64.945911118785702, 6.9691390996007341],
					[64.946000008801846, 6.9691308002808716],
					[64.946088772202216, 6.9691308009474966],
					[64.946180417618379, 6.9693306832020303],
					[64.946180455015948, 6.9693307269445599],
					[64.946180545548117, 6.9693307835982967],
					[64.946180599637017, 6.969330800671341],
					[64.946269399801238, 6.9693307992050428],
					[64.946269406106822, 6.9693308008444186],
					[64.946269417763673, 6.9693307999613019],
					[64.946269424040452, 6.9693307994857765],
					[64.946360976582483, 6.9693196166012106],
					[64.946449817350484, 6.9695194810802441],
					[64.94644985385132, 6.9695195248913953],
					[64.946449946205547, 6.9695195835253712],
					[64.946450000294419, 6.9695196005989803],
					[64.946538771734893, 6.969519599801969],
					[64.946630418374411, 6.9697194823581592],
					[64.946630459387379, 6.9697195279452258],
					[64.946630558915004, 6.9697195860369128],
					[64.946630619223029, 6.9697195984057343],
					[64.946719408724917, 6.9697112998952209],
					[64.946810976205938, 6.9697112996390054],
					[64.946910921021626, 6.9699111893697898],
					[64.94691095839022, 6.9699112309993545],
					[64.946911047100031, 6.9699112856774166],
					[64.946911100263364, 6.9699113007050579],
					[64.946988900250687, 6.9699113001234432],
					[64.946988905630761, 6.9699112997159549],
					[64.946988918184303, 6.9699112987651457],
					[64.94698892356439, 6.9699112983576574],
					[64.947080483776659, 6.9699002160421148],
					[64.947180420911238, 6.9701000899827816],
					[64.947180458251026, 6.9701001294980474],
					[64.947180546960709, 6.9701001841772738],
					[64.947180600124042, 6.970100199205433],
					[64.94726940028454, 6.9701002002328094],
					[64.947269405664656, 6.9701001998253513],
					[64.94726941821817, 6.9701001988746212],
					[64.947269423598286, 6.9701001984671622],
					[64.947360976620672, 6.9700891150930602],
					[64.947449817157406, 6.970288981011862],
					[64.947449853658014, 6.9702890248256768],
					[64.947449946011943, 6.9702890834644586],
					[64.94745000010073, 6.9702891005402243],
					[64.947538899878069, 6.9702891009862649],
					[64.947538905258142, 6.9702891005788414],
					[64.947538914224978, 6.9702890998998006],
					[64.947538917811698, 6.9702890996281859],
					[64.947630472980563, 6.9702808113049883],
					[64.947719216167485, 6.9704889781633188],
					[64.947719257209002, 6.9704890258683845],
					[64.947719357661811, 6.9704890860123658],
					[64.947719417969751, 6.9704890983833829],
					[64.947810974971674, 6.9704808126562732],
					[64.947899816756788, 6.970680680961399],
					[64.947899817682242, 6.9706806830085393],
					[64.947899818607738, 6.9706806850556768],
					[64.947899820429868, 6.9706806870349247],
					[64.947999819912155, 6.9708889874690509],
					[64.94799986089599, 6.9708890309451457],
					[64.947999960394384, 6.9708890869290281],
					[64.948000018908971, 6.9708890994368335],
					[64.948088775379787, 6.9708808131567759],
					[64.948180416958181, 6.9710889820351118],
					[64.94826921656653, 6.9712889810351095],
					[64.948360917157217, 6.9715000805905039],
					[64.94836091808267, 6.9715000826377045],
					[64.948360919933577, 6.9715000867321288],
					[64.948360920859017, 6.9715000887793313],
					[64.948460918349639, 6.9717000835575735],
					[64.948549815931329, 6.9719111778950698],
					[64.948549857869082, 6.971911225535945],
					[64.948549961908043, 6.9719112854156666],
					[64.948550024905941, 6.9719112975866571],
					[64.948638779304218, 6.9719002177365246],
					[64.948730416815152, 6.9721000800140587],
					[64.948819216013234, 6.9723111788441612],
					[64.948819216938674, 6.9723111808914258],
					[64.948819219714991, 6.9723111870332417],
					[64.948819220640445, 6.9723111890805054],
					[64.948919218792597, 6.9725111859275382],
					[64.949010917119352, 6.9727194809252548],
					[64.949099816759357, 6.9729194816667857],
					[64.949099854127141, 6.9729195233039052],
					[64.949099945612147, 6.9729195841382303],
					[64.949099999671873, 6.9729195991051762],
					[64.949188771897397, 6.9729196008940661],
					[64.94928041809446, 6.9731194826935594],
					[64.949280419019885, 6.9731194847408888],
					[64.949280419974073, 6.9731194889033592],
					[64.949280420870778, 6.9731194888355432],
					[64.949380417837375, 6.9733194836677832],
					[64.94946921561116, 6.9735306784386299],
					[64.949469258445077, 6.9735307260158574],
					[64.949469361586736, 6.9735307859716675],
					[64.949469423687788, 6.9735307982146537],
					[64.949560973712181, 6.9735196177814887],
					[64.949649816023623, 6.9737306778512353],
					[64.949649816052357, 6.9737306799664092],
					[64.949649816949076, 6.973730679898611],
					[64.949649816977796, 6.9737306820137848],
					[64.94973871692838, 6.9739306801518586],
					[64.949738754324613, 6.9739307239065518],
					[64.949738845809222, 6.9739307847457468],
					[64.949738899868862, 6.9739307997150695],
					[64.949830469874897, 6.9739308008076533],
					[64.949919217136753, 6.9741306811970931],
					[64.949919218062149, 6.974130683244506],
					[64.949919219912999, 6.9741306873393292],
					[64.949919220838439, 6.9741306893867394],
					[64.95001922089223, 6.9743306881712099],
					[64.950019258259644, 6.974330729811709],
					[64.950019346967835, 6.9743307845105971],
					[64.950019400130742, 6.9743307995486523],
					[64.950110970108824, 6.9743307998766895],
					[64.950199817327615, 6.97453068031583],
					[64.95028871575802, 6.9747389790848766],
					[64.950288716654697, 6.9747389790171139],
					[64.950288717580122, 6.974738981064573],
					[64.950288717608842, 6.9747389831797975],
					[64.95038041756024, 6.9749389831240842],
					[64.950380454956147, 6.9749390268812217],
					[64.950380546382902, 6.9749390834948031],
					[64.950380599574473, 6.9749391006494896],
					[64.950469275630354, 6.9749390993848381],
					[64.950569219118776, 6.975138984538777],
					[64.950660917424628, 6.9753500791086456],
					[64.950660959361301, 6.9753501267586175],
					[64.950661063398925, 6.975350186656267],
					[64.950661124603158, 6.9753501989717321],
					[64.950749878195154, 6.9753391168924059],
					[64.950838716561449, 6.9755389805842229],
					[64.950930417027138, 6.9757500805511672],
					[64.950930458934991, 6.9757501260869423],
					[64.950930562972445, 6.9757501859867528],
					[64.950930625073354, 6.9757501982354926],
					[64.951019280842189, 6.9757391162757241],
					[64.951119219192549, 6.9759500831404928],
					[64.951210918294095, 6.9761500827607863],
					[64.95121096020182, 6.976150128297677],
					[64.951211064210369, 6.976150186084336],
					[64.951211125414588, 6.9761501984018581],
					[64.95129987365813, 6.9761391170041502],
					[64.951388716053401, 6.9763500784357859],
					[64.951388716053401, 6.9763500784357859],
					[64.951388717904194, 6.9763500825309848],
					[64.951388717904194, 6.9763500825309848],
					[64.951480418240891, 6.9765500832736338],
					[64.951480454710989, 6.9765501249871624],
					[64.95148054616584, 6.9765501837238508],
					[64.951480600253944, 6.976550200814696],
					[64.951569276255782, 6.9765502005895144],
					[64.951669219205726, 6.9767500849794146],
					[64.951760916881796, 6.976961180872622],
					[64.951760958789265, 6.9769612264117065],
					[64.951761062826236, 6.9769612863180104],
					[64.951761124927032, 6.9769612985699272],
					[64.951849877686627, 6.9769502171456717],
					[64.951938717307712, 6.9771500792772159],
					[64.952030417143646, 6.977361180567919],
					[64.952030418068986, 6.9773611826156046],
					[64.952030419919751, 6.9773611867109935],
					[64.952030420845134, 6.9773611887586755],
					[64.952130420761819, 6.9775611895681697],
					[64.952130458099589, 6.9775612291010303],
					[64.952130546835335, 6.9775612859302134],
					[64.952130599997943, 6.9775613009757764],
					[64.952219272358207, 6.9775613001003336],
					[64.952310917277018, 6.9777611818197167],
					[64.952399816259543, 6.9779694797631695],
					[64.952399857270024, 6.9779695253725471],
					[64.952399957719877, 6.9779695855548258],
					[64.952400018952588, 6.9779695999923845],
					[64.95248877730991, 6.9779613121475084],
					[64.95258041791206, 6.9781611827009682],
					[64.952580418837442, 6.9781611847487213],
					[64.95258041886612, 6.9781611868641304],
					[64.952580419762782, 6.9781611867964752],
					[64.952680419724501, 6.9783694870579591],
					[64.952680460706176, 6.978369530553004],
					[64.952680561098518, 6.9783695865065418],
					[64.952680621405847, 6.9783695988973857],
					[64.95276098092765, 6.9783613127633064],
					[64.952860917804287, 6.9785694843801371],
					[64.952949817080537, 6.9787694817940222],
					[64.952949853550038, 6.9787695235128542],
					[64.95294994592939, 6.9787695843080515],
					[64.952949999988547, 6.978769599288829],
					[64.953038771940825, 6.9787695990895271],
					[64.953130417836633, 6.9789694823681545],
					[64.953219216907968, 6.9791694800430246],
					[64.953219254302681, 6.979169523810655],
					[64.953219344888524, 6.9791695847430768],
					[64.953219399844301, 6.9791695996571805],
					[64.953310975909929, 6.9791696010557631],
					[64.953410917769659, 6.9793694846809826],
					[64.953499815878089, 6.979580678503833],
					[64.953499815878089, 6.979580678503833],
					[64.953499816803429, 6.9795806805517069],
					[64.953499816803429, 6.9795806805517069],
					[64.953588717373449, 6.9797806816411221],
					[64.953588753842666, 6.9797807233623148],
					[64.953588846221578, 6.9797807841624389],
					[64.95358890028065, 6.9797807991455949],
					[64.95368047004267, 6.9797808009695945],
					[64.95376921697455, 6.9799806800971558],
					[64.953769217899918, 6.9799806821450607],
					[64.953769218825272, 6.9799806841929657],
					[64.953769219750583, 6.9799806862408742],
					[64.953869219693232, 6.9801889874884875],
					[64.953869259777704, 6.9801890310558132],
					[64.953869360169378, 6.9801890870186067],
					[64.953869417786493, 6.9801890996168297],
					[64.953960974917351, 6.9801808130373963],
					[64.954049817151031, 6.9803806798813],
					[64.954138715821841, 6.9805889785685791],
					[64.954138756831568, 6.9805890241848916],
					[64.954138858177046, 6.9805890843132241],
					[64.954138917616135, 6.9805890988928025],
					[64.954230472947856, 6.9805808116006727],
					[64.954319216033639, 6.9807889779325523],
					[64.954319216958993, 6.9807889799805478],
					[64.954319219734955, 6.9807889861244794],
					[64.954319220660295, 6.9807889881724563],
					[64.954419221019137, 6.9809889887647012],
					[64.954419262000101, 6.9809890322665407],
					[64.954419359672755, 6.9809890863205748],
					[64.954419418186475, 6.9809890988531933],
					[64.954510972644258, 6.9809808128922839],
					[64.954599815665219, 6.9811889793857569],
					[64.954599816590516, 6.9811889814337711],
					[64.954688716624716, 6.981388981098763],
					[64.954688754018804, 6.9813890248718655],
					[64.954688846368441, 6.9813890835643777],
					[64.954688900455992, 6.9813891006669371],
					[64.95478047010684, 6.9813890994621826],
					[64.954869216561491, 6.9815889813824015],
					[64.954869218354858, 6.9815889812473042],
					[64.95486921930879, 6.9815889854109461],
					[64.95486921930879, 6.9815889854109461],
					[64.954969218811897, 6.9818000859035863],
					[64.954969260689296, 6.981800129340006],
					[64.954969363799037, 6.9818001872240343],
					[64.954969424106082, 6.9818001996235957],
					[64.955060976632296, 6.9817891166888488],
					[64.955149815584335, 6.9819889780707758],
					[64.955238715629804, 6.9822000773531494],
					[64.955238715658439, 6.9822000794687744],
					[64.955238718377103, 6.9822000813818121],
					[64.955238718405738, 6.9822000834974371],
					[64.955330418409275, 6.9824000836344826],
					[64.955330418409275, 6.9824000836344826],
					[64.955330419334587, 6.9824000856826105],
					[64.955330419334587, 6.9824000856826105],
					[64.955430417560578, 6.9826111822472843],
					[64.955519216817692, 6.982811181200363],
					[64.955610916887522, 6.983019480925531],
					[64.955610957896468, 6.9830195265480475],
					[64.955611059241008, 6.9830195866887967],
					[64.955611118651277, 6.9830195991588822],
					[64.955699872615384, 6.9830113123952193],
					[64.955788716223026, 6.9832194797577438],
					[64.955788717148337, 6.9832194818059161],
					[64.9557887199242, 6.9832194879504552],
					[64.955788720849512, 6.9832194899986275],
					[64.955888719440267, 6.9834194850680893],
					[64.955980417361062, 6.9836306806921078],
					[64.955980417361062, 6.9836306806921078],
					[64.956069216974797, 6.9838306806387722],
					[64.956160917345144, 6.9840389816382533],
					[64.956160918270427, 6.9840389836864842],
					[64.956160920120979, 6.9840389877829425],
					[64.956160921046276, 6.9840389898311708],
					[64.956260917568372, 6.984238984452114],
					[64.956349815972615, 6.9844500786512436],
					[64.956349815972615, 6.9844500786512436],
					[64.956349816897884, 6.9844500806995038],
					[64.956349816897884, 6.9844500806995038],
					[64.956438716779445, 6.9846500806101917],
					[64.95653041695634, 6.9848611807019907],
					[64.956530417881623, 6.9848611827502749],
					[64.956530419732175, 6.9848611868468478],
					[64.95653042065743, 6.9848611888951542],
					[64.956630420573205, 6.9850611890001986],
					[64.956630464243077, 6.985061232309504],
					[64.956630567322989, 6.9850612880938652],
					[64.956630626704495, 6.985061298453159],
					[64.956710981834064, 6.9850502187550019],
					[64.956810917747347, 6.9852611837439298],
					[64.956899817308155, 6.9854611791334964],
					[64.956988715626508, 6.9856694781667041],
					[64.956988716551763, 6.9856694802150541],
					[64.956988720224231, 6.9856694862927018],
					[64.9569887211495, 6.9856694883410508],
					[64.957088718955234, 6.9858694843613902],
					[64.957180416804306, 6.9860806806206979],
					[64.957180416804306, 6.9860806806206979],
					[64.957269217425122, 6.9862806803680497],
					[64.957360916874237, 6.986488981940103],
					[64.957360917799491, 6.9864889839885072],
					[64.957360919649986, 6.9864889880853172],
					[64.957360920546677, 6.9864889880179266],
					[64.95746091807726, 6.9866889834549042],
					[64.957549816411216, 6.9869000780310175],
					[64.957549816411216, 6.9869000780310175],
					[64.957549817336456, 6.9869000800794527],
					[64.957549817336456, 6.9869000800794527],
					[64.957638717357028, 6.9871000819833009],
					[64.957638753824213, 6.9871001237207047],
					[64.957638846200254, 6.9871001845550458],
					[64.957638900258559, 6.9871001995551145],
					[64.957730469703819, 6.9871002007746483],
					[64.957819216988952, 6.9873000805893053],
					[64.957819217017502, 6.9873000827051399],
					[64.957819217914192, 6.9873000826377734],
					[64.957910917846249, 6.9875000829471547],
					[64.957910917874798, 6.9875000850629965],
					[64.957910918771475, 6.9875000849956379],
					[64.957910918771475, 6.9875000849956379],
					[64.958010918316575, 6.9877111821233004],
					[64.958099817119916, 6.9879111795733149],
					[64.95818871631036, 6.9881194787904786],
					[64.958188717235586, 6.9881194808390026],
					[64.958188720011265, 6.98811948698458],
					[64.958188720936505, 6.988119489033104],
					[64.958288718854206, 6.9883194859374518],
					[64.958380416603362, 6.9885306807166678],
					[64.958380416603362, 6.9885306807166678],
					[64.958469217334837, 6.9887306803326572],
					[64.958560916731273, 6.9889389802944244],
					[64.95856091765647, 6.9889389823430079],
					[64.958560920403613, 6.9889389863728759],
					[64.958560921328797, 6.9889389884214594],
					[64.958660918074813, 6.9891389848098395],
					[64.958749816310288, 6.9893500776475275],
					[64.958749816338823, 6.9893500797634394],
					[64.958749817235486, 6.9893500796961394],
					[64.958749817264007, 6.9893500818120504],
					[64.958838699787876, 6.9895500425539305],
					[64.95883869974827, 6.9897610989849879],
					[64.958838701855314, 6.9897611221255591],
					[64.958838712317714, 6.9897611658197727],
					[64.958838719776381, 6.9897611864406821],
					[64.958938718614931, 6.9899694842130042],
					[64.959030417411711, 6.990169480649004],
					[64.959119200111246, 6.990380639213468],
					[64.959119199807773, 6.9905888998169399],
					[64.959119201914689, 6.9905889229578211],
					[64.959119212348369, 6.9905889645368742],
					[64.959119218910232, 6.9905889852254903],
					[64.95921921925472, 6.9908000839515596],
					[64.959310916892335, 6.9910000810945245],
					[64.959399800236469, 6.9912111395586711],
					[64.959399799648281, 6.9914194001789429],
					[64.959399802651788, 6.9914194232528972],
					[64.95939981311372, 6.9914194669486776],
					[64.959399820600652, 6.9914194896864679],
					[64.959499818311869, 6.991619484333369],
					[64.959588716033068, 6.9918306786431064],
					[64.959588716033068, 6.9918306786431064],
					[64.95958871788342, 6.9918306827406473],
					[64.95958871788342, 6.9918306827406473],
					[64.959680400133607, 6.9920306448591303],
					[64.959680400087365, 6.9922388990807685],
					[64.959680402194053, 6.9922389222222661],
					[64.959680411759038, 6.9922389659860338],
					[64.959680419217378, 6.9922389866082817],
					[64.959780417977001, 6.9924500831549574],
					[64.959869216752239, 6.9926500805603835],
					[64.959960899991842, 6.9928611422835978],
					[64.959960899717956, 6.9930694007532619],
					[64.959960902721178, 6.9930694238278894],
					[64.959960913182641, 6.993069467525233],
					[64.959960920640867, 6.9930694881479489],
					[64.960060917843023, 6.9932694845448387],
					[64.960149815799028, 6.9934806779782042],
					[64.960149815799028, 6.9934806779782042],
					[64.960149816724154, 6.9934806800270657],
					[64.960149816724154, 6.9934806800270657],
					[64.960238701609356, 6.9936806474019324],
					[64.960249800177891, 6.9939000105411937],
					[64.960249803095607, 6.9939000272680598],
					[64.960249810781391, 6.993900064819532],
					[64.960249817285899, 6.9939000812777827],
					[64.960338717256221, 6.9941000792773051],
					[64.960430416825119, 6.9943111800662541],
					[64.960430416825119, 6.9943111800662541],
					[64.960519201801318, 6.9945111469819299],
					[64.960530400337689, 6.9947194101169696],
					[64.960530403283755, 6.9947194289601589],
					[64.9605304099873, 6.9947194602312122],
					[64.960530415623467, 6.9947194788730025],
					[64.960619216354885, 6.9949306773608351],
					[64.96061921638335, 6.9949306794769202],
					[64.960619218205153, 6.9949306814587482],
					[64.960619218233603, 6.9949306835748137],
					[64.960710917250466, 6.9951306815037766],
					[64.960799815727341, 6.9953389798224235],
					[64.960799816652496, 6.9953389818713942],
					[64.960799820324567, 6.9953389879512118],
					[64.960799821249665, 6.995338990000187],
					[64.960899800355762, 6.9955389471836362],
					[64.960899799705999, 6.995750000340192],
					[64.960899801755261, 6.9957500192508286],
					[64.960899810365689, 6.9957500588527646],
					[64.960899816001714, 6.9957500774950478],
					[64.960988716204099, 6.9959611773261177],
					[64.960988716232535, 6.9959611794422134],
					[64.960988718054338, 6.9959611814241383],
					[64.960988718082774, 6.9959611835402544],
					[64.96108041836564, 6.9961611833230224],
					[64.961080419262316, 6.9961611832559436],
					[64.961080419290738, 6.9961611853720473],
					[64.961080420215893, 6.9961611874210892],
					[64.961180417712981, 6.9963694835922059],
					[64.96126921725137, 6.9965694807021288],
					[64.961360917160036, 6.9967806796078547],
					[64.961360917188443, 6.9967806817239815],
					[64.961449816696543, 6.996980680381891],
					[64.961449817621656, 6.9969806824309906],
					[64.961449818546768, 6.9969806844800697],
					[64.961449820368586, 6.9969806864620967],
					[64.961549817574166, 6.9971889842608661],
					[64.961638717352415, 6.9973889792670061],
					[64.961730417411729, 6.997600079995923],
					[64.961730417411729, 6.997600079995923],
					[64.961819217054924, 6.9978000809213343],
					[64.961819217980036, 6.9978000829704712],
					[64.961819218905106, 6.9978000850196294],
					[64.961819218905106, 6.9978000850196294],
					[64.961919219288319, 6.9980111845775541],
					[64.962010916626056, 6.9982111813870427],
					[64.962099815943262, 6.9984194784709608],
					[64.962099816868346, 6.9984194805201421],
					[64.962188716779991, 6.998619481462323],
					[64.962188717676682, 6.9986194813953206],
					[64.962188718630145, 6.9986194855607113],
					[64.962188718630145, 6.9986194855607113],
					[64.962288719115861, 6.9988306841983299],
					[64.962380417075366, 6.9990306800859488],
					[64.962469215557192, 6.9992389774984645],
					[64.962469217378953, 6.9992389794807206],
					[64.962469218304051, 6.9992389815299747],
					[64.96246921833243, 6.9992389836461939],
					[64.962560917543968, 6.9994389821437499],
					[64.962560917572347, 6.9994389842599949],
					[64.962560919365728, 6.999438984126046],
					[64.962560919394107, 6.9994389862422715],
					[64.962660918182152, 6.9996500828743979],
					[64.962749816291876, 6.999850079388291],
					[64.962838715737703, 7.0000611773678028],
					[64.962838715766068, 7.0000611794840699],
					[64.962838717587829, 7.0000611814664087],
					[64.962838717616222, 7.0000611835826563],
					[64.962930418371002, 7.0002611832171118],
					[64.962930419267707, 7.0002611831501627],
					[64.962930419296086, 7.0002611852664183],
					[64.962930420221113, 7.000261187315747],
					[64.963030417937333, 7.000469483059474],
					[64.96311921705049, 7.0006694796472626],
					[64.963210917125721, 7.0008806791832168],
					[64.9632109171541, 7.0008806812994937],
					[64.963299817162124, 7.0010806815000537],
					[64.963299818087151, 7.0010806835494188],
					[64.963299819012221, 7.0010806855987822],
					[64.963299819937262, 7.0010806876481473],
					[64.963399818258765, 7.0012889849529643],
					[64.963488716715375, 7.0014889795192117],
					[64.963580416944154, 7.0017000808717809],
					[64.963580416944154, 7.0017000808717809],
					[64.963669217058992, 7.0019000812087979],
					[64.963669217955669, 7.0019000811419039],
					[64.963669218909075, 7.0019000853076374],
					[64.963669218909075, 7.0019000853076374],
					[64.963769219435235, 7.0021111846340309],
					[64.963860918170468, 7.0023111833446343],
					[64.963860960070321, 7.0023112289437561],
					[64.963861064068965, 7.0023112868586255],
					[64.963861125242758, 7.0023112971249635],
					[64.96394987354536, 7.0023002160903136],
					[64.964038716354011, 7.0025111788513081],
					[64.964038758253793, 7.0025112244510224],
					[64.964038862309053, 7.0025112865997547],
					[64.964038924407831, 7.0025112989160814],
					[64.964130474093778, 7.0025002171399651],
					[64.964219216051958, 7.0027111788890855],
					[64.964219257951697, 7.0027112244894152],
					[64.964219362006872, 7.0027112866393333],
					[64.964219424105622, 7.0027112989562017],
					[64.964310980951325, 7.0027002151273257],
					[64.964410919280724, 7.002911185093132],
					[64.964410962077068, 7.0029112306272356],
					[64.964411066943867, 7.0029112863623721],
					[64.96441112814594, 7.0029112987466275],
					[64.964488925565618, 7.0029001971048332],
					[64.964580480658739, 7.0028891160450106],
					[64.964680418697526, 7.0031000867096749],
					[64.96468046236221, 7.0031001300612745],
					[64.964680563642133, 7.0031001860651312],
					[64.964680624844178, 7.0031001984499328],
					[64.964769276217766, 7.0030891162836637],
					[64.964860917418648, 7.0033000806344718],
					[64.964860959318187, 7.0033001262368035],
					[64.964861063344799, 7.003300186274048],
					[64.964861125443548, 7.0033001985925507],
					[64.964949878271753, 7.00328911791481],
					[64.965038717317057, 7.0034889802300828],
					[64.965038753808031, 7.0034890241176626],
					[64.965038846177507, 7.0034890850250404],
					[64.965038900234234, 7.0034891000621062],
					[64.965130469886205, 7.003489099681949],
					[64.96521921734589, 7.0036889814025178],
					[64.965219253808513, 7.0036890231742213],
					[64.965219345281184, 7.0036890841495305],
					[64.965219400234602, 7.0036890991202352],
					[64.96531110004922, 7.003689100304082],
					[64.965311105429379, 7.0036890999030366],
					[64.965311114396386, 7.0036890992346255],
					[64.965311118879839, 7.0036890989004235],
					[64.965399882187342, 7.003680812914423],
					[64.965499821362727, 7.0038806893249399],
					[64.965499857796942, 7.0038807289808771],
					[64.965499947419517, 7.0038807858581889],
					[64.965499999682848, 7.0038808010299585],
					[64.965580475974065, 7.003880800440748],
					[64.965680421303844, 7.0040806890530218],
					[64.965680457766311, 7.0040807308259723],
					[64.965680547360506, 7.0040807855878517],
					[64.965680599623809, 7.0040808007601072],
					[64.965769271817223, 7.004080800093635],
					[64.965860917633862, 7.004280682215275],
					[64.96586096045813, 7.0042807298705103],
					[64.965861064456007, 7.0042807877972386],
					[64.965861124732953, 7.0042807981355928],
					[64.965950013353563, 7.0042695999561566],
					[64.966038771713684, 7.0042695998346307],
					[64.966130418150399, 7.0044694824191671],
					[64.966130454641103, 7.004469526309852],
					[64.966130546056846, 7.0044695830568093],
					[64.96613060014181, 7.0044696002129436],
					[64.966219272333902, 7.0044696008093617],
					[64.966310917581552, 7.004669483369784],
					[64.966310958583961, 7.0046695290435093],
					[64.966311058966639, 7.0046695851234064],
					[64.966311119300173, 7.0046695996958492],
					[64.966399879912629, 7.0046613125624004],
					[64.966499819985955, 7.0048694859527298],
					[64.966499860960042, 7.0048695295105308],
					[64.966499959577504, 7.0048695878417417],
					[64.966500018957717, 7.0048695982484306],
					[64.966588908677153, 7.0048612999723865],
					[64.966680470197787, 7.0048613005653708],
					[64.966769217415845, 7.0050611812796619],
					[64.966769259343167, 7.005061229004542],
					[64.966769363340774, 7.0050612869362112],
					[64.966769423645985, 7.0050612993932031],
					[64.966860974294974, 7.0050502165153423],
					[64.966949816410121, 7.0052611770999986],
					[64.966949858337372, 7.0052612248255102],
					[64.96694996239151, 7.0052612869915132],
					[64.966950025358457, 7.0052612971320567],
					[64.967038775578345, 7.0052502179407723],
					[64.967130416990003, 7.0054611797015873],
					[64.967130458917197, 7.0054612274277099],
					[64.967130562914647, 7.0054612853617133],
					[64.967130625013212, 7.0054612976861677],
					[64.967219424339717, 7.0054501984667672],
					[64.967310974118945, 7.0054391167733394],
					[64.967399815636739, 7.0056500779438133],
					[64.967399857563862, 7.0056501256707255],
					[64.967399961589535, 7.0056501857226445],
					[64.967400024584762, 7.0056501979808701],
					[64.967488781051969, 7.0056391151666855],
					[64.967588719321455, 7.0058500868036653],
					[64.967588761191934, 7.0058501302979428],
					[64.967588864264272, 7.0058501861845652],
					[64.967588923672693, 7.0058501987104425],
					[64.96768047434928, 7.00583911602698],
					[64.967769215634789, 7.0060500782520974],
					[64.967769258458503, 7.0060501259134966],
					[64.967769361587287, 7.0060501860345488],
					[64.967769423685809, 7.0060501983606587],
					[64.967860977066991, 7.0060391160755779],
					[64.967949816601688, 7.0062389812878481],
					[64.967949853960263, 7.0062390230004707],
					[64.967949946328631, 7.0062390839238065],
					[64.967950000385116, 7.0062390989679439],
					[64.968038899579867, 7.0062390990169883],
					[64.968038904988319, 7.0062391007330724],
					[64.968038913927046, 7.0062390979487645],
					[64.968038918438808, 7.0062390997316131],
					[64.968130475064214, 7.0062308124002799],
					[64.968219217418522, 7.0064306811090047],
					[64.968219253908657, 7.0064307250057478],
					[64.96821934535194, 7.0064307838804032],
					[64.968219400333368, 7.0064308009750125],
					[64.968310969836011, 7.0064307998922075],
					[64.968399817094536, 7.0066306818454942],
					[64.968399853556335, 7.0066307236261087],
					[64.968399945924531, 7.0066307845517963],
					[64.96839999998096, 7.0066307995969836],
					[64.968488776185254, 7.0066307994834629],
					[64.968588721078248, 7.0068306900460327],
					[64.968588762948443, 7.0068307335434472],
					[64.968588865095441, 7.0068307873858879],
					[64.968588923607101, 7.0068307999811372],
					[64.968680611871363, 7.0068195997639675],
					[64.968769272287034, 7.0068196004045786],
					[64.968860917655221, 7.0070194821623701],
					[64.968860955041862, 7.0070195259942176],
					[64.968861045588255, 7.0070195849390169],
					[64.968861099644641, 7.007019599985215],
					[64.968949870438991, 7.007019599419376],
					[64.969038716836408, 7.0072194817224505],
					[64.969038757838106, 7.0072195274046729],
					[64.969038858248084, 7.0072195856164585],
					[64.969038917656405, 7.0072195981460004],
					[64.969130473408441, 7.0072113117507842],
					[64.969219215625188, 7.0074194779478809],
					[64.96921925665508, 7.0074195257474745],
					[64.969219357989957, 7.0074195860104265],
					[64.969219418294912, 7.0074195984737857],
					[64.969311109431118, 7.0074113005034535],
					[64.969399870332197, 7.0074112998289682],
					[64.969488717025371, 7.0076111803572907],
					[64.969488758951911, 7.0076112280909308],
					[64.969488862948523, 7.0076112860382507],
					[64.96948892415017, 7.0076112984354131],
					[64.969580481170965, 7.0076002161777344],
					[64.969680419061532, 7.007811185926843],
					[64.969680461828176, 7.0078112293608221],
					[64.969680564031336, 7.0078112874427534],
					[64.969680625204717, 7.0078112977236557],
					[64.96976927584457, 7.0078002161743402],
					[64.969860916926848, 7.0080111802910228],
					[64.969860958853246, 7.0080112280259668],
					[64.969861062849702, 7.0080112859756039],
					[64.969861124948011, 7.0080112983071503],
					[64.969950024884852, 7.008000198789671],
					[64.97003877605232, 7.0079891166182993],
					[64.970130416815977, 7.0082000792600851],
					[64.970130458742304, 7.0082001269957797],
					[64.970130562766926, 7.0082001870635739],
					[64.970130624836997, 7.0082001972788159],
					[64.970219276400542, 7.0081891167393238],
					[64.970310916857272, 7.0084000793651153],
					[64.970310958783529, 7.0084001271014413],
					[64.970311062779857, 7.0084001850535556],
					[64.970311124878137, 7.0084001973861758],
					[64.970399874281227, 7.0083891165045218],
					[64.970488715724429, 7.0086000774918782],
					[64.970488757650671, 7.0086001252288286],
					[64.970488861675136, 7.0086001852989597],
					[64.970488923801668, 7.0086001997489991],
					[64.970580484423124, 7.0085891159012315],
					[64.970680420816194, 7.0087889894804212],
					[64.970680458145836, 7.0087890290839052],
					[64.970680546869872, 7.0087890860551001],
					[64.970680600001145, 7.0087890990556421],
					[64.970769400354769, 7.0087890999399693],
					[64.970769404838279, 7.0087890996065338],
					[64.9707694138053, 7.0087890989396646],
					[64.970769418288768, 7.0087890986062327],
					[64.970860973168598, 7.0087808127769264],
					[64.97094981621008, 7.0089889793163502],
					[64.970949857211224, 7.008989025004503],
					[64.970949957648742, 7.0089890853438748],
					[64.97095001887854, 7.0089890998617301],
					[64.971038775062709, 7.00898081298803],
					[64.971130417404098, 7.0091889810681867],
					[64.971130458405199, 7.0091890267569505],
					[64.971130558814437, 7.0091890849805321],
					[64.971130619147488, 7.0091890995656128],
					[64.971219276592535, 7.009180812155587],
					[64.971310918337878, 7.009380682091356],
					[64.97131095482716, 7.0093807259971319],
					[64.971311046269292, 7.0093807848885241],
					[64.971311100325465, 7.0093807999407867],
					[64.971400000267863, 7.0093807995869657],
					[64.97140000564805, 7.0093807991869541],
					[64.971400019098553, 7.0093807981869194],
					[64.971400025375445, 7.0093807977202367],
					[64.97148878066524, 7.0093696174313607],
					[64.971588718931287, 7.0095806869368182],
					[64.971588761697419, 7.0095807303766762],
					[64.971588865665026, 7.009580786219118],
					[64.97158892776325, 7.0095807985550147],
					[64.971669285211362, 7.009569618039257],
					[64.971769221010774, 7.0097694884743271],
					[64.971769258368397, 7.0097695301977341],
					[64.971769347063812, 7.0097695850574775],
					[64.971769400223252, 7.0097696001774246],
					[64.971860969576355, 7.0097695997296734],
					[64.971949817303965, 7.0099694806342381],
					[64.971949853793092, 7.0099695245418534],
					[64.971949946131687, 7.0099695833699602],
					[64.971950000216026, 7.0099696005407504],
					[64.972038900128624, 7.0099695999902982],
					[64.97203890461212, 7.0099695996570413],
					[64.972038913579112, 7.0099695989905308],
					[64.972038918062623, 7.0099695986572739],
					[64.97213047474068, 7.0099613120325737],
					[64.972219216991732, 7.0101611800850083],
					[64.972219254377464, 7.0101612239266773],
					[64.972219344950844, 7.0101612850063324],
					[64.972219399903622, 7.0101612999940297],
					[64.972310970152066, 7.0101613007941435],
					[64.972399817278898, 7.0103611799905972],
					[64.972399853767882, 7.0103612238994764],
					[64.972399946134544, 7.0103612848469155],
					[64.972400000190603, 7.0103612999017564],
					[64.972488776216096, 7.010361299238606],
					[64.97258872097602, 7.0105611885648589],
					[64.972588762845177, 7.0105612320745179],
					[64.972588865019048, 7.010561288056059],
					[64.972588924398835, 7.0105612984775219],
					[64.972680612348654, 7.0105502003001803],
					[64.972760976169681, 7.0105502002118421],
					[64.972860920624669, 7.0107500880286437],
					[64.97286095798205, 7.0107501297551185],
					[64.972861046677082, 7.0107501846202744],
					[64.972861099836408, 7.0107501997427741],
					[64.972949869583488, 7.0107502008105946],
					[64.97303871674599, 7.0109500802907476],
					[64.97303875867145, 7.0109501280358728],
					[64.973038862666797, 7.0109501860036385],
					[64.973038923868174, 7.0109501984099492],
					[64.97313047383777, 7.0109391179633551],
					[64.973219216174442, 7.0111500777891491],
					[64.973219258099846, 7.0111501255349058],
					[64.973219362123317, 7.0111501856209113],
					[64.973219424221369, 7.0111501979611619],
					[64.973310974177252, 7.0111391159963361],
					[64.973399815881891, 7.0113500789767311],
					[64.973399857779015, 7.0113501246060235],
					[64.973399961830623, 7.0113501868102981],
					[64.973400024797172, 7.0113501969673822],
					[64.973488921270373, 7.011339098671824],
					[64.973580479756777, 7.011330811832142],
					[64.973680420385335, 7.0115389865220799],
					[64.973680461357475, 7.0115390301016243],
					[64.973680559944199, 7.0115390863555316],
					[64.973680619352123, 7.0115390988966704],
					[64.973769275041008, 7.0115308121212969],
					[64.973860917072656, 7.0117389801565215],
					[64.97386095807299, 7.0117390258538128],
					[64.973861059406119, 7.0117390861432822],
					[64.973861118814042, 7.0117390986849637],
					[64.973950019369539, 7.0117307985591086],
					[64.973950020266258, 7.0117307984925095],
					[64.973950022956373, 7.0117307982927164],
					[64.973950024749797, 7.0117307981595181],
					[64.974038912720474, 7.0117195997790942],
					[64.974130599562443, 7.0117195992670025],
					[64.974130604970838, 7.0117196009845646],
					[64.974130613909665, 7.0117195982014184],
					[64.974130619318075, 7.0117195999189805],
					[64.974219419325678, 7.0117112986208685],
					[64.974219420222383, 7.0117112985542676],
					[64.974219422912483, 7.01171129835447],
					[64.974219423809188, 7.0117112982878709],
					[64.97431097379939, 7.0117002177716277],
					[64.97439981635209, 7.0119111776156196],
					[64.974399858277238, 7.0119112253646474],
					[64.97439996230041, 7.0119112854561907],
					[64.974400025295111, 7.0119112977320919],
					[64.974488911910981, 7.0119001994458738],
					[64.974580599646217, 7.0119001994606673],
					[64.97458060592308, 7.0119001989945104],
					[64.97458061937364, 7.0119001979955939],
					[64.974580624753827, 7.01190019759603],
					[64.974669420715045, 7.0118890995629002],
					[64.974761109187853, 7.0118808002820199],
					[64.974850000035318, 7.0118808002403323],
					[64.974850006312252, 7.0118807997741603],
					[64.974850018866022, 7.0118807988418217],
					[64.974850025142942, 7.0118807983756533],
					[64.97493892208206, 7.0118695994022726],
					[64.975030608756768, 7.0118613000893539],
					[64.975119399982574, 7.0118613010090254],
					[64.975119406259495, 7.0118613005428436],
					[64.975119417916588, 7.011861299677081],
					[64.975119424193508, 7.0118612992109011],
					[64.975211123820955, 7.0118501991942859],
					[64.975211124689466, 7.0118501970104452],
					[64.975300012214916, 7.0118391001923088],
					[64.975388771996521, 7.0118390991203521],
					[64.975480417981828, 7.0120389830996857],
					[64.975480458982048, 7.0120390288001433],
					[64.975480559361969, 7.0120390849263838],
					[64.975480618798059, 7.0120390995866737],
					[64.975569408946271, 7.0120307995894224],
					[64.975661100256957, 7.0120307997252009],
					[64.975661105637158, 7.0120307993256432],
					[64.975661119087661, 7.0120307983267463],
					[64.975661125364567, 7.0120307978605938],
					[64.975750022306201, 7.0120195990022207],
					[64.975838909406363, 7.0120113001193163],
					[64.975930599816976, 7.0120113002399744],
					[64.975930606093911, 7.0120112997738113],
					[64.975930618647723, 7.012011298841486],
					[64.975930624924601, 7.0120112983753264],
					[64.976019423568388, 7.0120001998786643],
					[64.976111112435532, 7.0119891007137669],
					[64.976199999653716, 7.0119890991081686],
					[64.976200005062125, 7.0119891008259119],
					[64.976200014897614, 7.0119890979760351],
					[64.976200020306024, 7.0119890996937784],
					[64.976280619952306, 7.0119807992903835],
					[64.976280621745715, 7.0119807991571896],
					[64.976280624435816, 7.0119807989574001],
					[64.976280625332549, 7.0119807988908009],
					[64.976369412751822, 7.0119695992295936],
					[64.976461099569022, 7.0119695994444244],
					[64.976461104949223, 7.0119695990448392],
					[64.976461113916201, 7.0119695983788652],
					[64.976461119296445, 7.0119695979792773],
					[64.976549880006218, 7.0119613122968136],
					[64.976649820349692, 7.012169486789114],
					[64.976649861321647, 7.0121695303743881],
					[64.976649959908116, 7.0121695866366807],
					[64.976650019315983, 7.0121695991805035],
					[64.976738918968735, 7.0121612995358529],
					[64.97673891986544, 7.0121612994692608],
					[64.976738922555541, 7.0121612992694846],
					[64.976738924348936, 7.0121612991363014],
					[64.976830612323653, 7.0121502002513649],
					[64.976919399916866, 7.0121502001816216],
					[64.976919407090477, 7.0121501996488789],
					[64.97691941964429, 7.0121501987165775],
					[64.976919426817872, 7.0121501981838348],
					[64.977000023286323, 7.0121390984094614],
					[64.9770889085856, 7.0121307996017501],
					[64.977180599879489, 7.0121307999958065],
					[64.977180606156409, 7.0121307995296434],
					[64.977180618710193, 7.0121307985973242],
					[64.977180624987113, 7.0121307981311629],
					[64.977269422299699, 7.0121195999272912],
					[64.977361108961446, 7.0121113006187024],
					[64.97744999975346, 7.0121112990852081],
					[64.977450006058589, 7.0121113007364553],
					[64.977450018612373, 7.012111299804114],
					[64.977450024861071, 7.0121112972205246],
					[64.977538924037475, 7.0121001993548999],
					[64.977630481163047, 7.0120891159602579],
					[64.977730418968278, 7.0123000866391214],
					[64.9777304617336, 7.0123001300931458],
					[64.977730565728393, 7.0123001880760079],
					[64.977730626901447, 7.012300198369986],
					[64.977811122857958, 7.0122890997770124],
					[64.977900009025561, 7.0122807989881464],
					[64.977988899841293, 7.0122808001059331],
					[64.977988906118227, 7.0122807996397896],
					[64.97798891777532, 7.0122807987740963],
					[64.977988924052198, 7.0122807983079554],
					[64.978080620559197, 7.0122695989909394],
					[64.978169408925439, 7.0122613010629671],
					[64.978261100179793, 7.0122612997266494],
					[64.978261105559994, 7.0122612993270907],
					[64.978261119010497, 7.0122612983281911],
					[64.978261125287418, 7.0122612978620369],
					[64.978350025334734, 7.0122501979304372],
					[64.978438911957724, 7.0122391010367657],
					[64.978530599621863, 7.0122390998758428],
					[64.978530605002106, 7.0122390994762709],
					[64.978530613969085, 7.0122390988103245],
					[64.978530619349272, 7.012239098410757],
					[64.978619419365188, 7.0122307993522917],
					[64.978619420261865, 7.0122307992856987],
					[64.978619422951979, 7.0122307990859127],
					[64.97861942384867, 7.0122307990193171],
					[64.978710974265667, 7.012219616440091],
					[64.978799816117458, 7.0124306781669015],
					[64.97879985804245, 7.0124307259244816],
					[64.9787999629621, 7.0124307859610555],
					[64.978800025060011, 7.0124307983066219],
					[64.978888913029124, 7.0124195999602055],
					[64.978980599793118, 7.0124195994591325],
					[64.978980605173305, 7.0124195990595926],
					[64.978980614140283, 7.0124195983936914],
					[64.978980618623822, 7.01241959806074],
					[64.979069418645935, 7.0124112994371917],
					[64.979069420439359, 7.0124112993040093],
					[64.979069423129474, 7.0124112991042358],
					[64.979069424026179, 7.0124112990376446],
					[64.979161111991914, 7.0124001999132792],
					[64.979250000076178, 7.0124001994420642],
					[64.979250006353112, 7.0124001989759224],
					[64.979250018906896, 7.0124001980436415],
					[64.979250025183816, 7.0124001975774988],
					[64.979338920746883, 7.0123890979767927],
					[64.979430609221822, 7.0123808008191002],
					[64.979519400346362, 7.0123807989906943],
					[64.9795194057548, 7.0123808007087156],
					[64.979519418308584, 7.0123807997764143],
					[64.979519423688799, 7.0123807993768557],
					[64.97961112108122, 7.0123695996105377],
					[64.979700009053644, 7.0123613004184415],
					[64.979788899821543, 7.0123612995893119],
					[64.979788906098463, 7.0123612991231496],
					[64.979788917755556, 7.0123612982574199],
					[64.979788924060671, 7.0123612999088616],
					[64.979880476695726, 7.0123502166838305],
					[64.979969216713741, 7.0125500801406364],
					[64.979969254098776, 7.0125501239981709],
					[64.979969344642967, 7.0125501829855663],
					[64.97996939962367, 7.0125502000998488],
					[64.980061099826827, 7.0125501989873777],
					[64.980061106131942, 7.012550200638878],
					[64.980061118685754, 7.0125501997066308],
					[64.980061124934423, 7.0125501971228852],
					[64.980150022321581, 7.0125390996246821],
					[64.980238909401763, 7.0125308008658447],
					[64.980330599737854, 7.0125308004044662],
					[64.980330606014775, 7.0125307999383315],
					[64.980330618568601, 7.012530799006063],
					[64.980330624845479, 7.012530798539931],
					[64.980419422124257, 7.0125195980579909],
					[64.980511108772532, 7.0125112989679161],
					[64.980599999560837, 7.0125113007109743],
					[64.980600005837758, 7.0125113002448289],
					[64.980600019288246, 7.0125112992459488],
					[64.980600024668448, 7.0125112988463982],
					[64.980688923810746, 7.0125001987158271],
					[64.98078061176308, 7.0124890991346032],
					[64.980869400211475, 7.0124890999103675],
					[64.980869404694985, 7.0124890995773992],
					[64.98086941366202, 7.0124890989114634],
					[64.980869418145502, 7.0124890985784951],
					[64.980960972999313, 7.0124808111265553],
					[64.981049816121768, 7.0126889786206492],
					[64.981049857121775, 7.0126890243314772],
					[64.981049957557801, 7.0126890847069072],
					[64.981050018787201, 7.0126890992383162],
					[64.981138919316706, 7.0126807996512577],
					[64.981138920213382, 7.0126807995846727],
					[64.981138922903497, 7.0126807993849072],
					[64.981138923800188, 7.0126807993183204],
					[64.981230612230164, 7.0126696004778779],
					[64.981319399750333, 7.0126695997771336],
					[64.98131940513052, 7.0126695993776016],
					[64.981319414097513, 7.0126695987117147],
					[64.981319417684347, 7.0126695984453553],
					[64.981411117781605, 7.0126612985411825],
					[64.981411119575, 7.0126612984080046],
					[64.981411123161791, 7.0126612981416478],
					[64.981411124955201, 7.0126612980084682],
					[64.981500011574298, 7.012650201045985],
					[64.981588899606265, 7.0126501991615307],
					[64.981588905911337, 7.0126502008131659],
					[64.981588917568473, 7.0126501999474886],
					[64.981588923845351, 7.0126501994813593],
					[64.981680473791442, 7.0126391165989661],
					[64.981769215600053, 7.0128500789398851],
					[64.981769258393427, 7.0128501245190016],
					[64.981769362444382, 7.0128501867480395],
					[64.981769423645588, 7.0128501991624397],
					[64.98186112058049, 7.0128390996059196],
					[64.981950009424509, 7.012830798969695],
					[64.982038900171432, 7.0128307995788344],
					[64.982038905551619, 7.012830799179322],
					[64.982038918105431, 7.0128307982471219],
					[64.982038924410546, 7.0128307998988051],
					[64.982130620883737, 7.0128195984285284],
					[64.98221940923635, 7.0128113008516415],
					[64.982311100420574, 7.0128112990569749],
					[64.98231110582897, 7.0128113007752759],
					[64.982311119279473, 7.0128112997764696],
					[64.982311124631522, 7.0128112972591214],
					[64.982399877728099, 7.0128002166481274],
					[64.982488717435317, 7.013000080120249],
					[64.982488753923548, 7.0130001240490651],
					[64.982488846289158, 7.0130001850280541],
					[64.982488900344904, 7.0130002000935727],
					[64.982580470360517, 7.0130001994482978],
					[64.982669216659687, 7.0132000803225507],
					[64.982669254044552, 7.0132001241853583],
					[64.982669344588473, 7.0132001831807393],
					[64.98266939956909, 7.0132002002980158],
					[64.982761099718914, 7.0132001991449391],
					[64.982761106024029, 7.0132002007967467],
					[64.982761118577841, 7.0132001998646913],
					[64.982761124826553, 7.0132001972808222],
					[64.982849884198842, 7.0131891151646313],
					[64.982949821223244, 7.0133889882868665],
					[64.982949857683181, 7.013389030099086],
					[64.982949947273951, 7.0133890849265903],
					[64.982950000432965, 7.0133891000596904],
					[64.983038900112717, 7.0133890996858064],
					[64.983038904596199, 7.0133890993529588],
					[64.983038915356602, 7.0133890985541179],
					[64.983038920736803, 7.0133890981546987],
					[64.983119280921173, 7.0133808130505173],
					[64.983219220397174, 7.0135889862579832],
					[64.983219259575336, 7.0135890299890971],
					[64.983219359954617, 7.0135890861369194],
					[64.983219417568932, 7.0135890988198648],
					[64.983310975127054, 7.0135808119545224],
					[64.983399817336689, 7.0137806802872973],
					[64.98339985382465, 7.0137807242186572],
					[64.98339994616174, 7.0137807830844618],
					[64.983400000245595, 7.0137808002699327],
					[64.983488899895846, 7.0137807990550467],
					[64.98348890620089, 7.0137808007070399],
					[64.983488917858054, 7.0137807998417809],
					[64.983488924134917, 7.0137807993758745],
					[64.983580473695724, 7.0137696177414144],
					[64.983669215878876, 7.0139806770273241],
					[64.983669257803399, 7.0139807247959265],
					[64.983669361853842, 7.0139807870336695],
					[64.983669423951653, 7.0139807993848935],
					[64.983761121370037, 7.0139695988960513],
					[64.983849873097441, 7.0139613136227306],
					[64.98393871560107, 7.0141694785950124],
					[64.983938756600622, 7.0141695243129796],
					[64.98393885793277, 7.0141695846337679],
					[64.983938918265309, 7.0141695992365953],
					[64.98403048030768, 7.0141613116433481],
					[64.98413042039347, 7.0143694862105699],
					[64.984130461364799, 7.0143695298112156],
					[64.984130559950387, 7.0143695860968887],
					[64.984130619358055, 7.0143695986488535],
					[64.984219419359817, 7.0143612982664791],
					[64.984219421153199, 7.014361298133398],
					[64.984219424740047, 7.0143612978672305],
					[64.984219427430133, 7.0143612976676097],
					[64.984299882404144, 7.0143502180335267],
					[64.984399818950479, 7.0145611856383328],
					[64.984399861715147, 7.0145612291066382],
					[64.984399963915664, 7.0145612872453986],
					[64.984400025088519, 7.0145612975468667],
					[64.984488924298915, 7.0145501982071696],
					[64.984580474311159, 7.0145391162246584],
					[64.984669215782318, 7.0147500777615361],
					[64.984669257706571, 7.0147501255330873],
					[64.984669361728578, 7.0147501856580936],
					[64.984669423826318, 7.0147501980115283],
					[64.984760973853028, 7.014739116628224],
					[64.984849816429204, 7.0149500769648965],
					[64.9848498583534, 7.0149501247370791],
					[64.984849962403487, 7.0149501869812649],
					[64.984850025369724, 7.0149501971507329],
					[64.984938912053309, 7.0149390998229642],
					[64.985030470317867, 7.0149391001869779],
					[64.985119217313937, 7.0151389800171824],
					[64.985119258341399, 7.0151390278566721],
					[64.985119357851559, 7.0151390861987162],
					[64.985119418155818, 7.0151390986863031],
					[64.985210980218625, 7.015130811860292],
					[64.985310919702385, 7.0153389864065741],
					[64.985310960673431, 7.0153390300106047],
					[64.98531106194875, 7.0153390861027338],
					[64.985311121356332, 7.0153390986573632],
					[64.985388780734127, 7.0153308132766963],
					[64.985488719914784, 7.0155389878286414],
					[64.985488759960944, 7.0155390293817099],
					[64.985488860367624, 7.0155390876595245],
					[64.985488917953617, 7.0155390982296293],
					[64.985580472836148, 7.0155308110509615],
					[64.985669216032463, 7.0157389785205622],
					[64.98566925703156, 7.0157390242438531],
					[64.985669358363069, 7.015739084574391],
					[64.985669417798746, 7.0157390992481385],
					[64.985760975382192, 7.0157308124881625],
					[64.985849817111713, 7.0159306805546313],
					[64.985938715847851, 7.0161389797987725],
					[64.985938756846792, 7.0161390255231328],
					[64.985938858178145, 7.0161390858557793],
					[64.985938917585656, 7.016139098412439],
					[64.986030479675378, 7.0161308125898429],
					[64.986130420333083, 7.016338986428595],
					[64.98613046130383, 7.0163390300354758],
					[64.986130559888707, 7.0163390863325024],
					[64.986130619296191, 7.0163390988897092],
					[64.9862192750047, 7.0163308128986763],
					[64.986310917102898, 7.0165389807720002],
					[64.98631095810174, 7.0165390264976226],
					[64.986311059432907, 7.0165390868325845],
					[64.986311118840362, 7.0165390993903145],
					[64.986399873281371, 7.016530813038317],
					[64.98648871637333, 7.0167389784295509],
					[64.986488757372115, 7.0167390241557603],
					[64.986488857806521, 7.0167390845583562],
					[64.986488918138789, 7.0167390991682801],
					[64.986580474824322, 7.0167308113599063],
					[64.986669217295812, 7.0169306812619272],
					[64.986669253754741, 7.0169307230847409],
					[64.98666934522204, 7.0169307841530575],
					[64.986669400174094, 7.016930799162278],
					[64.986760970010835, 7.0169307990266336],
					[64.986849817357609, 7.0171306801046223],
					[64.986849853844632, 7.0171307240461527],
					[64.986849946180428, 7.0171307829309342],
					[64.986850000263871, 7.0171308001252859],
					[64.986938775908982, 7.0171307999614525],
					[64.987038720819953, 7.0173306896490439],
					[64.987038762687163, 7.0173307331924537],
					[64.987038864830325, 7.017330787111014],
					[64.987038924237737, 7.017330799670817],
					[64.987130473912984, 7.0173196179066251],
					[64.987219216026347, 7.017530678328689],
					[64.987219257921609, 7.0175307239908991],
					[64.987219361970631, 7.0175307862505019],
					[64.987219424068144, 7.0175307986114941],
					[64.987311111765806, 7.0175196008588161],
					[64.987399870315798, 7.0175195989861914],
					[64.987488716802204, 7.0177194804152485],
					[64.987488757800705, 7.0177195261446466],
					[64.987488858234727, 7.0177195865531266],
					[64.987488917642096, 7.0177195991139687],
					[64.987580608828296, 7.0177113000922979],
					[64.987669272242229, 7.0177112995289264],
					[64.987760918048295, 7.0179111839806669],
					[64.987760955403672, 7.0179112257400682],
					[64.987761045945746, 7.0179112847623673],
					[64.987761100000981, 7.0179112998406135],
					[64.987850000369306, 7.0179112993379729],
					[64.987850005777617, 7.017911301057624],
					[64.987850019200025, 7.0179112979428817],
					[64.987850024580283, 7.0179112975442797],
					[64.987938778734559, 7.0179002163579254],
					[64.988030417734137, 7.0181000827413866],
					[64.988030455117595, 7.0181001266197303],
					[64.988030545631474, 7.0181001835250063],
					[64.988030599714804, 7.0181002007220199],
					[64.98811940043538, 7.018100199732884],
					[64.98811940581561, 7.0181001993343166],
					[64.988119418369436, 7.0181001984043325],
					[64.988119423749666, 7.0181001980057642],
					[64.98821098104662, 7.0180891153377756],
					[64.988310919028862, 7.0183000868119914],
					[64.988310961792436, 7.0183001302925412],
					[64.988311063963394, 7.0183001863355763],
					[64.988311125164117, 7.0183001987652416],
					[64.988400021808502, 7.0182890996184524],
					[64.988488775356771, 7.018280812022331],
					[64.988580417160932, 7.018488980647529],
					[64.98858045815922, 7.0184890263799495],
					[64.988580558592872, 7.0184890867937249],
					[64.988580618896904, 7.0184890992903322],
					[64.988669409080231, 7.0184807989771567],
					[64.988761100020028, 7.0184807993058271],
					[64.988761106325072, 7.0184808009592299],
					[64.988761118878884, 7.0184808000293986],
					[64.988761125127695, 7.0184807974461583],
					[64.988849877968761, 7.0184696160421733],
					[64.988938717405532, 7.0186694800235276],
					[64.9889387538921, 7.0186695239705488],
					[64.988938846255408, 7.0186695849834848],
					[64.988938900310586, 7.0186696000638884],
					[64.98903060021614, 7.0186696003605338],
					[64.989030604699636, 7.0186696000284794],
					[64.989030613666671, 7.0186695993643662],
					[64.989030619046886, 7.0186695989659],
					[64.989119274787271, 7.0186613132863185],
					[64.989210916883906, 7.0188694816721817],
					[64.98921095785397, 7.018869525287891],
					[64.9892110591842, 7.0188695856380541],
					[64.98921111859147, 7.0188695982022189],
					[64.989300019198822, 7.0188612992275887],
					[64.989300020095484, 7.018861299161185],
					[64.989300022785613, 7.0188612989619674],
					[64.989300024579023, 7.0188612988291554],
					[64.989388776048415, 7.0188502160772721],
					[64.989480417241424, 7.0190611798946358],
					[64.989480459164341, 7.0190612276814299],
					[64.989480563156519, 7.0190612857153543],
					[64.98948062525389, 7.0190612980808416],
					[64.989569424094384, 7.0190501996781114],
					[64.989660981396369, 7.0190391153423635],
					[64.989760918761107, 7.019250086836931],
					[64.989760962421158, 7.0192501302549264],
					[64.989761063695028, 7.01925018637096],
					[64.989761124895679, 7.0192501988033991],
					[64.989850011709734, 7.0192391007445618],
					[64.989930600389613, 7.0192391007799406],
					[64.989930604873138, 7.0192391004479653],
					[64.989930613840144, 7.0192390997840182],
					[64.98993061922036, 7.0192390993856506],
					[64.990019280338927, 7.0192308127490071],
					[64.990119219771486, 7.0194389863958326],
					[64.990119259844647, 7.0194390300802665],
					[64.990119360221769, 7.0194390862641098],
					[64.990119417835587, 7.0194390989627111],
					[64.990211118012041, 7.0194307999700456],
					[64.990211119805451, 7.0194307998372674],
					[64.990211123392257, 7.01943079957171],
					[64.990211125157543, 7.0194307973204957],
					[64.990299875342501, 7.0194196164638454],
					[64.990388715562304, 7.0196306779261501],
					[64.990388758381727, 7.0196307256490771],
					[64.990388862401758, 7.0196307858055862],
					[64.990388923602396, 7.019630798239163],
					[64.990480621216804, 7.0196195990930743],
					[64.990569275151685, 7.0196113119298449],
					[64.990660916677115, 7.0198194820151771],
					[64.990660957646895, 7.0198195256346745],
					[64.990661058976769, 7.0198195859916437],
					[64.990661119280645, 7.019819598492151],
					[64.990750009143582, 7.019811300823906],
					[64.990838772075776, 7.0198112996822077],
					[64.990930418288144, 7.020011182833036],
					[64.990930460182696, 7.0200112285053793],
					[64.990930564174462, 7.0200112865460582],
					[64.990930625375029, 7.0200112989807417],
					[64.991019411667054, 7.0200001997430634],
					[64.991110969685607, 7.0200002001426327],
					[64.991199816919092, 7.0202000810418417],
					[64.991199854301911, 7.0202001249282571],
					[64.99119994573978, 7.0202001838993491],
					[64.99119999979483, 7.0202001989840452],
					[64.991288900061761, 7.0202001995470313],
					[64.991288906338653, 7.020200199082467],
					[64.991288917995831, 7.0202001982196975],
					[64.991288924300818, 7.0202001998736554],
					[64.991380621450745, 7.0201890998373022],
					[64.991469279867133, 7.0201808117834892],
					[64.991569219624537, 7.0203889873512804],
					[64.991569259697414, 7.020389031039473],
					[64.991569360074138, 7.0203890872298471],
					[64.991569417687884, 7.0203890999311138],
					[64.991661117852075, 7.020380799426766],
					[64.991661121438881, 7.0203807991613196],
					[64.99166112592242, 7.0203807988295095],
					[64.991661127715815, 7.0203807986967863],
					[64.991738782015446, 7.0203696186770035],
					[64.991838719415242, 7.0205806855149078],
					[64.9918387612815, 7.0205807290711091],
					[64.991838864376376, 7.020580787182273],
					[64.991838923783519, 7.0205807997513592],
					[64.991930612436633, 7.020569599599586],
					[64.992019272129454, 7.0205696000672591],
					[64.992110918102284, 7.0207694839272961],
					[64.992110954560175, 7.0207695257637432],
					[64.992111045997817, 7.0207695847387619],
					[64.992111100052782, 7.020769599825095],
					[64.992200000286743, 7.0207696001128053],
					[64.992200004770254, 7.0207695997810529],
					[64.992200013737289, 7.0207695991175489],
					[64.992200019117519, 7.0207695987194452],
					[64.992288777180008, 7.0207613112821088],
					[64.992380418220705, 7.0209611833031467],
					[64.992380454678511, 7.0209612251402538],
					[64.992380546116109, 7.020961284116507],
					[64.992380600171018, 7.0209612992033739],
					[64.992469399860667, 7.0209612990698229],
					[64.992469406165611, 7.0209613007240304],
					[64.992469417822775, 7.0209612998615487],
					[64.992469424099696, 7.0209612993971344],
					[64.992560974295259, 7.0209502165315056],
					[64.992649816143967, 7.0211611775496765],
					[64.992649858066187, 7.0211612253453755],
					[64.992649962085608, 7.0211612855127656],
					[64.992650025079499, 7.0211612978181588],
					[64.99273892361866, 7.0211501996539214],
					[64.992830473826601, 7.0211391173080075],
					[64.992919215738837, 7.0213500778382301],
					[64.992919257661015, 7.0213501256346991],
					[64.992919361680364, 7.0213501858034135],
					[64.992919423777536, 7.0213501981756989],
					[64.993010981173626, 7.0213391158985754],
					[64.993110919353398, 7.0215500859044919],
					[64.993110962116091, 7.0215501293979274],
					[64.993111064313879, 7.0215501875818447],
					[64.993111124589547, 7.021550197968641],
					[64.993200022230766, 7.0215390988279616],
					[64.993288774950855, 7.0215308139777362],
					[64.99338041704253, 7.0217389817657523],
					[64.993380458011714, 7.0217390253925673],
					[64.993380559340807, 7.021739085762789],
					[64.993380618747821, 7.021739098335118],
					[64.993469408999943, 7.0217308007036259],
					[64.993560969612787, 7.0217308003360497],
					[64.993649816952882, 7.0219306816896347],
					[64.993649858846794, 7.0219307273695728],
					[64.99364996283785, 7.0219307854234536],
					[64.993650024934951, 7.0219307977973795],
					[64.993738913211857, 7.0219196006854432],
					[64.99383047020855, 7.0219195990957504],
					[64.993919216757376, 7.0221194820314929],
					[64.993919254111589, 7.022119523806265],
					[64.99391934468008, 7.0221195849748375],
					[64.993919399631594, 7.0221195999985335],
					[64.994011100241167, 7.0221195990674099],
					[64.99401110475273, 7.0221196008546007],
					[64.994011113691712, 7.0221195980727522],
					[64.994011119100009, 7.0221195997936299],
					[64.994099876275314, 7.0221113120052419],
					[64.994188716585768, 7.0223111800239408],
					[64.99418875396799, 7.0223112239181509],
					[64.994188846329806, 7.0223112849553662],
					[64.994188900384614, 7.0223113000459021],
					[64.994280600096218, 7.0223112998233272],
					[64.994280606373138, 7.0223112993591918],
					[64.994280618926979, 7.0223112984309228],
					[64.994280625203913, 7.0223112979667874],
					[64.994369424144594, 7.0223001996252243],
					[64.994460980649038, 7.0222891157466201],
					[64.994560919108523, 7.0225000857072795],
					[64.994560961870903, 7.0225001292045608],
					[64.994561064068321, 7.0225001873951776],
					[64.994561125240622, 7.022500197718327],
					[64.994650012154864, 7.0224890997311951],
					[64.994730599804541, 7.0224891009156112],
					[64.99473060518477, 7.0224891005178076],
					[64.99473061415182, 7.0224890998548037],
					[64.994730618635344, 7.0224890995233018],
					[64.994819279766304, 7.0224808113182338],
					[64.99491922040923, 7.0226889871662221],
					[64.994919259584776, 7.0226890309295538],
					[64.994919359960647, 7.0226890871354275],
					[64.994919417574181, 7.0226890998431237],
					[64.995011117762516, 7.0226807996403098],
					[64.995011120452645, 7.0226807994414226],
					[64.99501112314276, 7.0226807992425382],
					[64.995011124908117, 7.022680796991108],
					[64.995100013203256, 7.0226695996091477],
					[64.995188772414437, 7.0226695995091246],
					[64.995280417897661, 7.0228694821358726],
					[64.995280458894612, 7.022869527886316],
					[64.995280559298465, 7.0228695862124288],
					[64.995280618705394, 7.0228695987881142],
					[64.995369408951802, 7.0228612999795299],
					[64.995460970394191, 7.0228612990728125],
					[64.995549816923685, 7.023061181284171],
					[64.995549854277655, 7.0230612230627694],
					[64.995549945742454, 7.0230612841718951],
					[64.995549999797177, 7.0230612992646924],
					[64.995638899929858, 7.0230613006281688],
					[64.995638906206793, 7.0230613001641817],
					[64.995638917863943, 7.0230612993024897],
					[64.995638924140891, 7.0230612988384999],
					[64.995730624081489, 7.0230502000059269],
					[64.995730624950156, 7.023050197820762],
					[64.995819276034496, 7.0230391164270403],
					[64.995910916857241, 7.0232500810189329],
					[64.99591095875077, 7.0232501267047471],
					[64.995911062769352, 7.0232501868874246],
					[64.995911124838301, 7.0232501971464814],
					[64.996000021634202, 7.0232390981704791],
					[64.996088908824063, 7.0232307995250061],
					[64.996180600424097, 7.0232308008274611],
					[64.996180605804341, 7.0232308004297792],
					[64.996180619254915, 7.0232307994355807],
					[64.996180624635116, 7.0232307990379024],
					[64.996269276176008, 7.0232196161911338],
					[64.996360917115922, 7.0234306803481044],
					[64.996360959009394, 7.0234307260349791],
					[64.996361063027905, 7.0234307862193246],
					[64.996361125124878, 7.0234307985979063],
					[64.996450022377459, 7.0234195981694718],
					[64.996538908676897, 7.023411300026277],
					[64.996630600245382, 7.0234112998037492],
					[64.996630605625612, 7.0234112994060975],
					[64.996630619076171, 7.0234112984119701],
					[64.99663062535312, 7.0234112979480425],
					[64.996719275559769, 7.0234002173961168],
					[64.996810916642389, 7.0236111796210823],
					[64.996810958563842, 7.0236112274279456],
					[64.996811062554229, 7.0236112854950337],
					[64.996811124651217, 7.0236112978742042],
					[64.996900025024885, 7.0236001972922972],
					[64.996988911973972, 7.0235890997448802],
					[64.997080599952127, 7.0235891003721358],
					[64.997080605332386, 7.0235890999745099],
					[64.997080614299406, 7.0235890993118044],
					[64.997080618782945, 7.0235890989804499],
					[64.997169281722108, 7.0235808113156102],
					[64.997269221154653, 7.0237806884391629],
					[64.997269257611677, 7.0237807302876858],
					[64.997269347198653, 7.023780785178614],
					[64.997269400356629, 7.0237808003400435],
					[64.99736109999057, 7.0237808007475397],
					[64.997361107164181, 7.0237808002174207],
					[64.997361120614755, 7.0237807992234433],
					[64.997361127788395, 7.0237807986933225],
					[64.99743892286584, 7.0237695985357558],
					[64.99753060871781, 7.0237613000568295],
					[64.997619276307816, 7.0237612996138781],
					[64.997719220997084, 7.0239611888300573],
					[64.99771925835077, 7.0239612306132608],
					[64.997719347040984, 7.0239612855719713],
					[64.997719400198903, 7.0239613007339825],
					[64.997811099801325, 7.02396129961648],
					[64.997811106078274, 7.0239612991526554],
					[64.997811118632114, 7.0239612982250126],
					[64.997811124909063, 7.023961297761188],
					[64.997900025299543, 7.0239501978737202],
					[64.99798891226547, 7.0239391010207299],
					[64.998080470044968, 7.0239390996862747],
					[64.998169217007586, 7.0241389803728582],
					[64.998169254389296, 7.0241390242760726],
					[64.998169344928897, 7.0241390833419146],
					[64.998169399908278, 7.024139100491098],
					[64.998261100403866, 7.0241390998920963],
					[64.998261104887405, 7.0241390995608182],
					[64.998261113854426, 7.024139098898261],
					[64.998261119234684, 7.0241390985007248],
					[64.998350018981341, 7.0241307989764596],
					[64.998350019878032, 7.0241307989102051],
					[64.998350022568147, 7.0241307987114352],
					[64.998350025258276, 7.0241307985126644],
					[64.998438912681308, 7.0241196001996737],
					[64.998530599723011, 7.0241196005128348],
					[64.998530605103241, 7.0241196001152915],
					[64.99853061407029, 7.0241195994527192],
					[64.998530618553801, 7.024119599121434],
					[64.998619277026236, 7.0241113130194615],
					[64.998710918273801, 7.0243111838157484],
					[64.998710954730711, 7.0243112256672164],
					[64.99871104616696, 7.0243112846685376],
					[64.998711100221527, 7.0243112997655457],
					[64.99880000027423, 7.0243113008769011],
					[64.998800005654459, 7.0243113004793916],
					[64.998800019105005, 7.0243112994856185],
					[64.998800025381954, 7.0243112990218561],
					[64.998888923996006, 7.0243001999666221],
					[64.99898061228572, 7.0242891004754853],
					[64.999069400106904, 7.0242890991845739],
					[64.999069404618439, 7.0242891009724557],
					[64.999069413557464, 7.0242890981907768],
					[64.999069418069027, 7.024289099978656],
					[64.999160973015549, 7.0242808123575129],
					[64.999249815549177, 7.0244889785279669],
					[64.999249856545646, 7.0244890242875933],
					[64.999249957873602, 7.0244890846811439],
					[64.999250019101822, 7.0244890992487994],
					[64.999338918831128, 7.0244807984087396],
					[64.999338919727819, 7.0244807983424931],
					[64.999338923314639, 7.0244807980775033],
					[64.999338924211358, 7.0244807980112558],
					[64.999430612089697, 7.0244695992599278],
					[64.99951939993548, 7.0244696006634033],
					[64.999519405315752, 7.0244696002659079],
					[64.999519414282773, 7.0244695996034245],
					[64.999519417869593, 7.0244695993384303],
					[64.999611118060415, 7.0244612993192801],
					[64.999611119853867, 7.0244612991867781],
					[64.999611123440658, 7.0244612989217821],
					[64.999611125234082, 7.0244612987892836],
					[64.999699874184884, 7.0244502172738512],
					[64.999788715687259, 7.0246611777145862],
					[64.999788757608385, 7.0246612255283472],
					[64.999788861626399, 7.0246612857250046],
					[64.999788923723287, 7.0246612981079029],
					[64.999880623688966, 7.0246501985700416],
					[64.999880624585685, 7.0246501985037968],
					[64.999969411911067, 7.0246390998059001],
					[65.000061099808661, 7.0246390996303116],
					[65.000061105188863, 7.0246390992328456],
					[65.00006111415594, 7.0246390985703995],
					[65.000061118639437, 7.02463909823918],
					[65.000150009429589, 7.0246308003842151],
					[65.000238772104979, 7.024630799900927],
					[65.000330418030558, 7.0248306819859501],
					[65.000330459951655, 7.0248307298009491],
					[65.00033056394156, 7.0248307878802079],
					[65.000330625113676, 7.0248307982107052],
					[65.000419412924714, 7.0248196001639229],
					[65.000511099922093, 7.0248196006486001],
					[65.000511105302309, 7.0248196002511625],
					[65.000511114269358, 7.0248195995887643],
					[65.000511118752897, 7.0248195992575644],
					[65.00060001938499, 7.0248112989908371],
					[65.000600020281723, 7.0248112989245959],
					[65.000600022971824, 7.0248112987258766],
					[65.000600024765234, 7.024811298593395],
					[65.000688911713439, 7.0248001990983306],
					[65.000780470366621, 7.0248002004970251],
					[65.000869216800993, 7.0250000807171764],
					[65.000869254182447, 7.0250001246259712],
					[65.000869344721721, 7.0250001837005485],
					[65.00086939970096, 7.0250002008531514],
					[65.00096110014546, 7.0250002009387114],
					[65.00096110642238, 7.0250002004750662],
					[65.000961118976235, 7.0250001995477769],
					[65.000961125225146, 7.025000196964835],
					[65.001049877791061, 7.0249891163408336],
					[65.00113871709442, 7.025188981706795],
					[65.001138753551103, 7.0251890235632102],
					[65.001138845911697, 7.0251890846258433],
					[65.001138899966207, 7.0251890997259157],
					[65.0012306004094, 7.0251891004443721],
					[65.001230604892939, 7.0251891001132236],
					[65.001230613859974, 7.0251890994509294],
					[65.001230619240204, 7.0251890990535504],
					[65.001319280383285, 7.0251808110785587],
					[65.001419220156635, 7.025388987678185],
					[65.001419260200066, 7.0253890292703947],
					[65.001419359706233, 7.0253890876852418],
					[65.001419418188206, 7.0253890982160661],
					[65.001510973149024, 7.0253808113830098],
					[65.00159981569935, 7.025588978073821],
					[65.001599856723516, 7.0255890259584488],
					[65.001599958022993, 7.0255890842419912],
					[65.0016000192511, 7.0255890988133611],
					[65.001688918995271, 7.0255807986548033],
					[65.001688919891961, 7.025580798588587],
					[65.00168892258209, 7.0255807983899325],
					[65.001688924375486, 7.025580798257498],
					[65.001780474280267, 7.0255696165991539],
					[65.001869215682163, 7.0257806786138612],
					[65.001869257574924, 7.0257807243137025],
					[65.001869361620507, 7.0257807866386432],
					[65.001869423717267, 7.0257807990250081],
					[65.001961120584241, 7.0257695986293944],
					[65.002049873304131, 7.0257613119176625],
					[65.002138716174471, 7.0259694797423711],
					[65.002138757170471, 7.0259695255091481],
					[65.002138857601125, 7.0259695859808726],
					[65.002138917904489, 7.0259695985002217],
					[65.002230472866174, 7.0259613112721855],
					[65.002319215787352, 7.0261694779132284],
					[65.002319256811319, 7.0261695258000412],
					[65.002319358110597, 7.02616958408729],
					[65.0023194184419, 7.0261695987265771],
					[65.00241111775594, 7.0261612993395808],
					[65.002411120446084, 7.0261612991409779],
					[65.00241112313617, 7.0261612989423776],
					[65.00241112492958, 7.0261612988099778],
					[65.002499881105336, 7.0261502168564736],
					[65.002599818575789, 7.0263611854552632],
					[65.002599862261789, 7.0263612310248371],
					[65.002599966223073, 7.02636128699542],
					[65.002600027395118, 7.0263612973302108],
					[65.002680481890906, 7.0263502177915376],
					[65.002780419054844, 7.0265611864368811],
					[65.002780461816101, 7.0265612299538303],
					[65.002780563983933, 7.0265612860579125],
					[65.002780625183931, 7.026561298512668],
					[65.002869276361949, 7.0265502169798388],
					[65.002960916821337, 7.0267611806938213],
					[65.002960958713828, 7.0267612263970198],
					[65.002961062730989, 7.0267612866088038],
					[65.002961124827635, 7.0267612989979158],
					[65.003049873845086, 7.0267502171879919],
					[65.00313871619457, 7.0269611773295866],
					[65.003138758114972, 7.0269612251528724],
					[65.003138862132033, 7.0269612853658252],
					[65.003138924256703, 7.0269612998749658],
					[65.003230473726177, 7.0269502177643615],
					[65.003319216150842, 7.0271611788570363],
					[65.003319258043192, 7.0271612245614676],
					[65.003319362088206, 7.0271612868950788],
					[65.003319424184838, 7.0271612992852912],
					[65.003411124203382, 7.0271501981798545],
					[65.003411125100072, 7.0271501981136861],
					[65.003499881318092, 7.0271391168542054],
					[65.00359981906594, 7.0273500862711291],
					[65.003599861826928, 7.0273501297906584],
					[65.003599964022456, 7.0273501880189579],
					[65.003600025194402, 7.0273501983563635],
					[65.003688776018535, 7.0273391159496379],
					[65.003780417062046, 7.0275500801951702],
					[65.003780458982277, 7.0275501280205113],
					[65.00378056297113, 7.0275501861176419],
					[65.003780625067733, 7.0275501985089539],
					[65.003869278978158, 7.027539117467879],
					[65.003960916761642, 7.0277389816782927],
					[65.004049815934039, 7.0279500773918864],
					[65.004138715763858, 7.0281611786821205],
					[65.004138757655895, 7.0281612243895033],
					[65.004138861700497, 7.0281612867288725],
					[65.004138923797044, 7.0281612991217575],
					[65.004230484058951, 7.0281502156161491],
					[65.004330418245232, 7.0283500837177053],
					[65.004419215965143, 7.0285611790629483],
					[65.004419257857052, 7.0285612247714528],
					[65.004419361873502, 7.0285612849934225],
					[65.00441942399803, 7.0285612995069284],
					[65.004510974426154, 7.0285502179662824],
					[65.004599815837025, 7.0287611784900923],
					[65.004599815837025, 7.0287611784900923],
					[65.004599816761683, 7.028761180543583],
					[65.004599816761683, 7.028761180543583],
					[65.004688716995332, 7.0289611818490734],
					[65.00468871791999, 7.0289611839025579],
					[65.00468871884469, 7.0289611859560583],
					[65.004688719769334, 7.0289611880095437],
					[65.004788720056609, 7.0291694869984243],
					[65.004788760126985, 7.0291695307210951],
					[65.004788859603721, 7.0291695870375923],
					[65.004788918113377, 7.0291695996975037],
					[65.004880473110774, 7.0291613118588359],
					[65.00496921569642, 7.0293694775569744],
					[65.004969216621092, 7.0293694796104917],
					[65.004969218442483, 7.0293694815979277],
					[65.004969217573731, 7.0293694837836487],
					[65.005060917328365, 7.0295694807797728],
					[65.005149815987053, 7.0297806780857375],
					[65.005149857906559, 7.0297807259170222],
					[65.005149962819246, 7.0297807860792574],
					[65.005150024915679, 7.0297807984763354],
					[65.00523878080287, 7.0297696169648596],
					[65.00533871854978, 7.0299806838790255],
					[65.005430417439868, 7.0301806814887158],
					[65.00551921589377, 7.0303889779290989],
					[65.005519256916358, 7.0303890258280424],
					[65.005519358214002, 7.0303890841381058],
					[65.005519417648216, 7.0303890988546023],
					[65.005610972688089, 7.0303808128045331],
					[65.005699815790862, 7.0305889785752571],
					[65.005699816715492, 7.0305889806288757],
					[65.005699820386155, 7.0305889867236813],
					[65.005699821310827, 7.0305889887772972],
					[65.00579982106315, 7.0307889885707828],
					[65.00579985841496, 7.0307890303760008],
					[65.005799947102673, 7.0307890853735611],
					[65.005800000259896, 7.030789100553501],
					[65.005880476227233, 7.0307891004907717],
					[65.005980417602188, 7.0309889835957673],
					[65.00606921581327, 7.0312000770571785],
					[65.006069215841208, 7.0312000791769069],
					[65.006069217662557, 7.0312000811645312],
					[65.006069217690495, 7.0312000832842401],
					[65.006160917573624, 7.0314000839691486],
					[65.006160960361385, 7.0314001296189934],
					[65.006161064348746, 7.0314001877361472],
					[65.006161124623731, 7.0314001981497558],
					[65.006249873821545, 7.031389117517592],
					[65.006338716343137, 7.0316000774533469],
					[65.006338717267781, 7.0316000795070455],
					[65.006338718220334, 7.0316000836804768],
					[65.006338719144992, 7.0316000857341958],
					[65.006438719206457, 7.0318111867127637],
					[65.006438761069475, 7.0318112303100193],
					[65.006438864132051, 7.0318112863756248],
					[65.006438924434946, 7.0318112989100152],
					[65.006530476763658, 7.0318002168155775],
					[65.006619217350092, 7.0320000800995404],
					[65.006710916895699, 7.0322111804497389],
					[65.006710958786556, 7.0322112261678171],
					[65.006711062801543, 7.0322112864090531],
					[65.006711124897791, 7.0322112988124248],
					[65.006799874100736, 7.0322002163750383],
					[65.006888716331801, 7.0324111790293538],
					[65.006888717256402, 7.0324111810831447],
					[65.006888720030318, 7.0324111872444552],
					[65.006888720954947, 7.0324111892982248],
					[65.006988720792364, 7.0326111897323029],
					[65.006988758115767, 7.0326112294221028],
					[65.006988846802827, 7.0326112844281203],
					[65.006988899959865, 7.032611299612304],
					[65.007080469909653, 7.0326112989995808],
					[65.007169216765433, 7.0328111812662328],
					[65.00716925411669, 7.0328112230763962],
					[65.007169344680847, 7.0328112843108475],
					[65.00716939963128, 7.032811299363563],
					[65.007260969580912, 7.0328112994259389],
					[65.00734981666001, 7.0330111805499156],
					[65.007349858578536, 7.0330112283901602],
					[65.007349962565286, 7.0330112865160812],
					[65.007350024661463, 7.0330112989216049],
					[65.007438911904813, 7.033000200198976],
					[65.007530600330284, 7.0330002006626895],
					[65.007530605710528, 7.0330002002667715],
					[65.007530619161187, 7.0330001992769695],
					[65.007530625438122, 7.0330001988150661],
					[65.007619421102362, 7.032989098222334],
					[65.007711108858686, 7.0329807999424103],
					[65.007800000429938, 7.0329808003179286],
					[65.007800005810182, 7.0329807999220009],
					[65.007800019260813, 7.0329807989321793],
					[65.007800024641043, 7.0329807985362525],
					[65.007888913254376, 7.0329695996701922],
					[65.007980599880852, 7.0329696001362674],
					[65.00798060526111, 7.0329695997403334],
					[65.007980614228217, 7.0329695990804426],
					[65.00798061871177, 7.0329695987504968],
					[65.008069418932294, 7.0329612984529923],
					[65.008069419829013, 7.0329612983870025],
					[65.008069423415847, 7.0329612981230438],
					[65.008069424312538, 7.0329612980570566],
					[65.008161112008153, 7.0329502006902285],
					[65.008249999959247, 7.0329501990841417],
					[65.008250006264106, 7.0329502007420936],
					[65.008250018818032, 7.0329501998182264],
					[65.008250025067071, 7.0329501972364099],
					[65.008338921288995, 7.0329390993491918],
					[65.008430609033027, 7.0329308006350582],
					[65.00851940005262, 7.0329307997175183],
					[65.008519406329597, 7.0329307992555741],
					[65.00851941798679, 7.0329307983976799],
					[65.008519424263767, 7.0329307979357356],
					[65.008611121399525, 7.0329195998249094],
					[65.008700008673557, 7.0329112990905642],
					[65.008788900232886, 7.0329112991824019],
					[65.008788907434464, 7.0329113007743818],
					[65.00878892088511, 7.0329112997844803],
					[65.00878892713412, 7.0329112972026024],
					[65.008869425593019, 7.0329001973521512],
					[65.008961112376468, 7.0328890994658462],
					[65.009050000345823, 7.032889099733981],
					[65.009050004829334, 7.0328890994040085],
					[65.009050013796426, 7.0328890987440591],
					[65.009050019176669, 7.0328890983480914],
					[65.00913891902519, 7.0328807985487574],
					[65.009138919921909, 7.0328807984827604],
					[65.009138922612024, 7.0328807982847747],
					[65.00913892440542, 7.0328807981527861],
					[65.009230611665188, 7.0328696003029698],
					[65.009319399985031, 7.0328695993376487],
					[65.009319405365275, 7.0328695989416703],
					[65.009319414332339, 7.0328695982817075],
					[65.009319417919187, 7.0328695980177205],
					[65.009411118228158, 7.0328612999031224],
					[65.009411120021582, 7.0328612997711284],
					[65.009411122711711, 7.0328612995731365],
					[65.009411125373902, 7.0328612972551721],
					[65.009500011710017, 7.0328501992546633],
					[65.009588899672892, 7.0328501993647849],
					[65.009588905977765, 7.0328502010227893],
					[65.009588917607061, 7.0328501980448275],
					[65.009588923911906, 7.0328501997028354],
					[65.009680621414461, 7.0328390983949705],
					[65.009769409079766, 7.0328308000899478],
					[65.009861100166432, 7.0328307996468702],
					[65.00986110554669, 7.0328307992508723],
					[65.009861118997307, 7.0328307982608829],
					[65.009861125274284, 7.0328307977988871],
					[65.009950021947347, 7.0328195989872109],
					[65.010038909226395, 7.0328112995535266],
					[65.010130600309722, 7.032811299028773],
					[65.010130605717904, 7.0328113007528197],
					[65.010130619168535, 7.0328112997628089],
					[65.010130625417574, 7.0328112971807499],
					[65.010219423779333, 7.0328001987850808],
					[65.010311112330413, 7.0327890997894],
					[65.010400000283468, 7.0327890996532521],
					[65.010400004767021, 7.0327890993232405],
					[65.010400013734085, 7.0327890986632173],
					[65.010400019114343, 7.0327890982672017],
					[65.010488918967766, 7.0327807997645033],
					[65.010488919864443, 7.0327807996985019],
					[65.010488923451277, 7.0327807994344917],
					[65.010488924347996, 7.0327807993684868],
					[65.010580611581844, 7.0327696005362412],
					[65.01066939988543, 7.03276959916702],
					[65.010669405293612, 7.0327696008910703],
					[65.010669414232751, 7.0327695981109581],
					[65.010669417847495, 7.0327695999670201],
					[65.010761118105023, 7.0327612988962755],
					[65.010761119898419, 7.0327612987642674],
					[65.010761122588576, 7.0327612985662524],
					[65.010761125278691, 7.0327612983682384],
					[65.010850011589525, 7.0327501994030479],
					[65.010938900432819, 7.0327501990428596],
					[65.010938905840987, 7.0327502007669462],
					[65.010938918394885, 7.0327501998428721],
					[65.010938923775129, 7.0327501994468404],
					[65.011030621279801, 7.0327390992814518],
					[65.011119408922085, 7.0327308001535487],
					[65.011211099991982, 7.0327307992933816],
					[65.011211106296869, 7.0327308009514518],
					[65.011211118850724, 7.0327308000273598],
					[65.011211125099777, 7.0327307974451951],
					[65.011300021775384, 7.0327195997838388],
					[65.011388909031453, 7.0327112995267695],
					[65.011480600125907, 7.0327113007050599],
					[65.011480607299575, 7.0327113001769934],
					[65.01148062075022, 7.0327112991868699],
					[65.011480627027126, 7.0327112987248146],
					[65.01156112633528, 7.0327001969589622],
					[65.011650025212802, 7.0326890986119617],
					[65.011650073411701, 7.0326890780862099],
					[65.011650151624025, 7.032689019273465],
					[65.011650184299683, 7.0326889786683084],
					[65.011739083876122, 7.0324806793966541],
					[65.011739089901752, 7.0324806598530838],
					[65.011739098366249, 7.0324806210299675],
					[65.011739099880472, 7.0324805996963189],
					[65.01173910014451, 7.0322611007145266],
					[65.011739100300048, 7.0320499993427568],
					[65.011739100272109, 7.0320499972225985],
					[65.011739100216261, 7.0320499929822793],
					[65.011739100216261, 7.0320499929822793],
					[65.011730800233209, 7.0318388959041718],
					[65.011730799955345, 7.0316306001686284],
					[65.011730799700729, 7.0314193990969898],
					[65.011730799672804, 7.0314193969768315],
					[65.011730799616927, 7.0314193927365176],
					[65.011730799588989, 7.0314193906163593],
					[65.011719599685762, 7.0311999951648243],
					[65.011719600219934, 7.0309889001994286],
					[65.011719599598294, 7.0307806002637481],
					[65.011719599935958, 7.0305693991677218],
					[65.01171959990802, 7.0305693970475653],
					[65.011719599880067, 7.0305693949273911],
					[65.011719599852114, 7.0305693928072355],
					[65.011711299675454, 7.0303610954423501],
					[65.011711299854966, 7.0301388991760501],
					[65.011711299842077, 7.0299305991918573],
					[65.011711299903197, 7.0297194002347032],
					[65.011711299847278, 7.0297193959943769],
					[65.011711299791358, 7.0297193917540683],
					[65.011711299763391, 7.0297193896339154],
					[65.011700199788891, 7.0295110952318094],
					[65.011700199730996, 7.0293000003256259],
					[65.011700200197325, 7.0290805990009613],
					[65.011700200169344, 7.029080596880811],
					[65.01170020011341, 7.0290805926404865],
					[65.011700200085443, 7.0290805905203344],
					[65.011689100438119, 7.0288693953229551],
					[65.011689099608674, 7.028661099714312],
					[65.011689100087736, 7.0284500005424464],
					[65.011689100240545, 7.0282388992551867],
					[65.011689100212578, 7.0282388971350356],
					[65.011689100184583, 7.0282388950148862],
					[65.011689100156602, 7.0282388928947359],
					[65.011680799678487, 7.0280193952610794],
					[65.011680800354483, 7.0278110995376366],
					[65.011680799632884, 7.0276000004505503],
					[65.011680799576894, 7.0275999962102542],
					[65.011680800417636, 7.0275999919037755],
					[65.011680800389641, 7.027599989783627],
					[65.011669600349379, 7.0273888960212361],
					[65.011669600146703, 7.0271806003991744],
					[65.01166959807334, 7.027180579330051],
					[65.011669590395783, 7.0271805416968958],
					[65.011669583838867, 7.027180520958745],
					[65.011580800071741, 7.0269693594480787],
					[65.011580799762314, 7.0267611005698019],
					[65.011580797660883, 7.0267610773806419],
					[65.011580788133799, 7.0267610356398702],
					[65.01158078068012, 7.0267610149681001],
					[65.011480800331171, 7.0265499543807701],
					[65.011480800186064, 7.0263388991720825],
					[65.01148079811253, 7.0263388781031786],
					[65.011480788585345, 7.0263388363627142],
					[65.011480782056324, 7.0263388177450485],
					[65.011389100447289, 7.0261388568367105],
					[65.011389100006653, 7.0259194005655914],
					[65.011389097008347, 7.0259193774429045],
					[65.011389087425016, 7.0259193314624673],
					[65.011389079074547, 7.025919310857315],
					[65.011289099708065, 7.0257193538255853],
					[65.011289100381134, 7.025511099169381],
					[65.011289098335553, 7.0255110802208049],
					[65.011289089732827, 7.0255110405348056],
					[65.01128908410044, 7.0255110218512256],
					[65.011200199581154, 7.0252999610680638],
					[65.011200200254876, 7.0250888994240857],
					[65.0112001981812, 7.0250888783554997],
					[65.011200187728917, 7.0250888345620597],
					[65.011200180274997, 7.0250888138910534],
					[65.011100200369228, 7.0248805530319069],
					[65.011100200144142, 7.0246694009563351],
					[65.011100198070423, 7.024669379887861],
					[65.011100188542756, 7.0246693381485708],
					[65.011100182910241, 7.0246693194653229],
					[65.011011297559662, 7.0244693534028686],
					[65.011000199985006, 7.0242499904439697],
					[65.011000197042549, 7.0242499715619884],
					[65.011000189392291, 7.0242499360507171],
					[65.011000182891053, 7.0242499195540082],
					[65.010911283056984, 7.0240499205908877],
					[65.010819600401618, 7.0238388579503379],
					[65.010819600173647, 7.0236306005011784],
					[65.010819597175029, 7.0236305773792429],
					[65.010819586694254, 7.0236305314669272],
					[65.010819579240177, 7.0236305107966199],
					[65.01071960025908, 7.0234305536149897],
					[65.010719600312115, 7.02321110045279],
					[65.010719598238182, 7.0232110793847315],
					[65.010719588738141, 7.0232110397666299],
					[65.010719583077403, 7.0232110189638872],
					[65.010630783221075, 7.0230110201873153],
					[65.010539098102683, 7.0227999549190123],
					[65.010530799742327, 7.0225888926544533],
					[65.010530796799657, 7.0225888737729898],
					[65.010530788196164, 7.0225888340890643],
					[65.010530781694754, 7.0225888175930304],
					[65.010439082671041, 7.0223888181825753],
					[65.010350200117458, 7.0221805582664532],
					[65.010350200409121, 7.0219693991246324],
					[65.010350198335018, 7.0219693780569648],
					[65.010350187881841, 7.0219693342661467],
					[65.010350180427523, 7.0219693135966672],
					[65.010250182035406, 7.0217610169210003],
					[65.010161283244685, 7.0215610209280266],
					[65.010069582954131, 7.0213499207561414],
					[65.01006958292605, 7.0213499186361279],
					[65.009980783124774, 7.0211499179912087],
					[65.009980745770278, 7.0211498761930606],
					[65.009980655201502, 7.0211498149919027],
					[65.009980600249932, 7.0211497999617105],
					[65.00988902363784, 7.0211498001791757],
					[65.009789081613235, 7.0209499156720616],
					[65.009700183649031, 7.0207388230269698],
					[65.009700141726668, 7.0207387752019033],
					[65.009700037707177, 7.0207387149988358],
					[65.009699974713385, 7.0207387026872379],
					[65.009611224152465, 7.0207497822515759],
					[65.00951958281324, 7.0205388205558092],
					[65.009519582785146, 7.0205388184358419],
					[65.009430782724024, 7.0203388181618864],
					[65.00943078179921, 7.0203388161083442],
					[65.009430780874425, 7.0203388140547789],
					[65.009430779949611, 7.0203388120012367],
					[65.009330780935869, 7.0201305157173532],
					[65.009239082972798, 7.0199305206522471],
					[65.009150183860385, 7.0197193211241418],
					[65.009150183860385, 7.0197193211241418],
					[65.009150182935585, 7.0197193190706413],
					[65.009150182935585, 7.0197193190706413],
					[65.009061297780292, 7.0195193517254166],
					[65.009050199908557, 7.0193110897037005],
					[65.009050196993599, 7.019311072943597],
					[65.009050190266947, 7.0193110394898195],
					[65.009050183765225, 7.0193110229954936],
					[65.008961284153514, 7.0190999224869799],
					[65.008961284125405, 7.0190999203670579],
					[65.008961283228686, 7.0190999204335167],
					[65.00886960040404, 7.0188888578400341],
					[65.008869600297601, 7.0186806003223863],
					[65.008869597298229, 7.0186805772027023],
					[65.008869586815948, 7.0186805312956873],
					[65.008869579361203, 7.018680510628271],
					[65.008769582110332, 7.0184805152553],
					[65.008680784172554, 7.0182693229801734],
					[65.008680784144431, 7.0182693208602744],
					[65.008680782322912, 7.0182693188733438],
					[65.008680782294789, 7.0182693167534245],
					[65.008589100136859, 7.0180693570483026],
					[65.008589099684158, 7.017849999473424],
					[65.008589096684631, 7.0178499763540785],
					[65.00858908712695, 7.0178499325012655],
					[65.008589078775358, 7.0178499119007958],
					[65.008489082133181, 7.0176499152414751],
					[65.008400183755654, 7.0174388223093498],
					[65.008400183727531, 7.0174388201894731],
					[65.008400182830798, 7.017438820255987],
					[65.00840018280266, 7.017438818136112],
					[65.00831129992072, 7.0172388572335356],
					[65.008311300160841, 7.0170305996629025],
					[65.00831129808607, 7.0170305785972298],
					[65.008311287631415, 7.0170305348116964],
					[65.008311281073119, 7.0170305140786482],
					[65.008211280886584, 7.0168193167204613],
					[65.00811958273745, 7.0166193198662903],
					[65.008030800330559, 7.0164110594775932],
					[65.008030799896559, 7.0162000009959389],
					[65.008030797793509, 7.0161999778107109],
					[65.008030788235317, 7.0161999339594097],
					[65.008030780780217, 7.016199913293355],
					[65.007930780698359, 7.0159888166527962],
					[65.007839083157492, 7.0157888182553982],
					[65.007750184150737, 7.0155805204587782],
					[65.007750183225866, 7.0155805184055282],
					[65.007661282858365, 7.0153805187377696],
					[65.00766128196166, 7.0153805188043474],
					[65.007661281008652, 7.0153805146312758],
					[65.007661281008652, 7.0153805146312758],
					[65.007561281035294, 7.0151693156595618],
					[65.007469583249616, 7.0149693185282018],
					[65.007380783570767, 7.0147610209949276],
					[65.007380782674062, 7.0147610210615259],
					[65.007380781749205, 7.0147610190083309],
					[65.007380781721039, 7.0147610168885217],
					[65.007289082413408, 7.014561016213924],
					[65.007289082413408, 7.014561016213924],
					[65.007289080591846, 7.0145610142273274],
					[65.007289080591846, 7.0145610142273274],
					[65.007189100106686, 7.0143499555244153],
					[65.007189100310839, 7.0141388992286],
					[65.007189098263808, 7.0141388802838396],
					[65.007189089658112, 7.0141388406075924],
					[65.00718908402429, 7.0141388219293042],
					[65.00710018436466, 7.0139305218338546],
					[65.007100183439789, 7.0139305197807111],
					[65.007011283435332, 7.0137305196640876],
					[65.007011281613728, 7.0137305176775913],
					[65.007011280688857, 7.0137305156244611],
					[65.007011280660677, 7.0137305135047008],
					[65.006911280928506, 7.0135193150390753],
					[65.006819581712719, 7.0133193177636057],
					[65.006819545224573, 7.0133192737949157],
					[65.006819453812341, 7.0133192169335361],
					[65.006819399728514, 7.0133191997347133],
					[65.006730728277702, 7.0133191995295547],
					[65.006639083281868, 7.0131193190581085],
					[65.00655018392564, 7.0129110201386435],
					[65.006550183000755, 7.0129110180855765],
					[65.006550180226057, 7.0129110119263602],
					[65.006550179329381, 7.0129110119930154],
					[65.006450182001871, 7.0127110167797149],
					[65.006361284336748, 7.0124999218561213],
					[65.006361284336748, 7.0124999218561213],
					[65.006361281590259, 7.0124999178166973],
					[65.006361281590259, 7.0124999178166973],
					[65.00626958178114, 7.0122999174781286],
					[65.00626954529271, 7.0122998735117275],
					[65.00626945388008, 7.0122998166549975],
					[65.006269399796167, 7.0122997994585763],
					[65.006180728320686, 7.0122998004850912],
					[65.006089081835611, 7.0120999170127973],
					[65.006089081835611, 7.0120999170127973],
					[65.006089080910684, 7.0120999149597774],
					[65.006089080910684, 7.0120999149597774],
					[65.005989081850615, 7.0118888166327027],
					[65.005900182589926, 7.0116888207356851],
					[65.005811283810388, 7.0114805215233096],
					[65.00581128288546, 7.0114805194703314],
					[65.005811281960561, 7.0114805174173691],
					[65.005811281960561, 7.0114805174173691],
					[65.00571958158622, 7.0112805161354563],
					[65.00571958158622, 7.0112805161354563],
					[65.005719580661278, 7.0112805140825118],
					[65.005719580661278, 7.0112805140825118],
					[65.005619580810034, 7.0110693134981767],
					[65.005619538044371, 7.011069270001526],
					[65.005619435870912, 7.0110692139509547],
					[65.005619374669749, 7.011069201530443],
					[65.005530723652669, 7.0110803824255283],
					[65.005439082742456, 7.0108693195325795],
					[65.005439082742456, 7.0108693195325795],
					[65.005350182888066, 7.0106693204669472],
					[65.005261284383565, 7.0104610203105544],
					[65.005261282561946, 7.010461018324408],
					[65.005261279787149, 7.0104610121657425],
					[65.005261278862235, 7.0104610101128593],
					[65.005161278879314, 7.0102610102961309],
					[65.005161242446746, 7.0102609705735395],
					[65.005161152854996, 7.0102609157122133],
					[65.005161099695798, 7.0102609005734617],
					[65.005069529801759, 7.0102608991328141],
					[65.004980783275172, 7.0100610206764093],
					[65.004889082874811, 7.0098499190132646],
					[65.004889040977247, 7.0098498733333994],
					[65.004888936953492, 7.0098498131832931],
					[65.004888874883761, 7.0098498029522487],
					[65.004800122033799, 7.0098608826826725],
					[65.004711282675459, 7.0096610186775825],
					[65.004711281778768, 7.0096610187443433],
					[65.004711280825575, 7.0096610145719591],
					[65.004711280825575, 7.0096610145719591],
					[65.004611281692434, 7.0094499168733266],
					[65.004519583128868, 7.0092388200163844],
					[65.004519541202868, 7.0092387722185245],
					[65.004519437207108, 7.0092387141911869],
					[65.004519375109112, 7.0092387018421114],
					[65.004430721026992, 7.009249782440838],
					[65.004339083034665, 7.0090499194194384],
					[65.004250184316135, 7.0088388211780552],
					[65.004250142418272, 7.0088387755008537],
					[65.004250038365839, 7.008838713236532],
					[65.004249975371096, 7.0088387009553079],
					[65.00416122439124, 7.008849783608003],
					[65.004069583234497, 7.0086388195535534],
					[65.004069582309526, 7.0086388175008265],
					[65.004069579562923, 7.0086388134621433],
					[65.004069578637967, 7.0086388114094156],
					[65.003969578710112, 7.0084388101319188],
					[65.003969542277076, 7.0084387704135818],
					[65.003969452684657, 7.0084387155607342],
					[65.003969400421951, 7.0084387003594664],
					[65.003880727863248, 7.008438699296879],
					[65.003789083080036, 7.0082388181199367],
					[65.003700183691834, 7.0080305210197524],
					[65.003700142690391, 7.0080304752779359],
					[65.003700042252703, 7.0080304148702721],
					[65.003699981023018, 7.0080304003380487],
					[65.003611225102006, 7.0080386874059002],
					[65.0035195831952, 7.0078305181947069],
					[65.003519542221994, 7.0078304745729785],
					[65.003519440887501, 7.0078304142332826],
					[65.003519380582787, 7.0078304017542852],
					[65.003430718013703, 7.0078386888294704],
					[65.003330780569058, 7.0076388157388232],
					[65.003239083397531, 7.0074305183725389],
					[65.003239042424198, 7.0074304747518772],
					[65.003238941089549, 7.0074304144143138],
					[65.003238880784806, 7.0074304019363218],
					[65.003150126634054, 7.0074386864549103],
					[65.003061284333555, 7.0072305199315741],
					[65.003061243331899, 7.0072304741920632],
					[65.003061142025459, 7.0072304159751102],
					[65.003061081692394, 7.0072304013782079],
					[65.002969527092802, 7.0072386873213048],
					[65.00288078413557, 7.0070305218018429],
					[65.00288074310555, 7.0070304739435016],
					[65.002880641799024, 7.007030415727689],
					[65.00288058236265, 7.0070304010644868],
					[65.002789019703613, 7.0070386882278077],
					[65.002689081636902, 7.0068305149919041],
					[65.002600183220977, 7.0066305196548386],
					[65.002600142219123, 7.0066304739170313],
					[65.002600040884175, 7.0066304135839079],
					[65.002599980579319, 7.0066304011079978],
					[65.002511224651201, 7.0066386860631251],
					[65.002419583368138, 7.0064305193859813],
					[65.002419542366255, 7.0064304736487628],
					[65.002419441031236, 7.0064304133168136],
					[65.002419380726366, 7.0064304008414329],
					[65.002330725351996, 7.0064386873835653],
					[65.00223908284093, 7.006230518619522],
					[65.00215018259405, 7.0060305181013671],
					[65.002150146132081, 7.0060304762696175],
					[65.002150053763856, 7.0060304152710122],
					[65.002149999707527, 7.00603040020916],
					[65.002061223833493, 7.0060303998897151],
					[65.001961279065199, 7.0058305101740634],
					[65.001961238119733, 7.0058304686772592],
					[65.001961140427824, 7.0058304123197539],
					[65.0019610819446, 7.0058304018315312],
					[65.001869527366168, 7.0058386879259364],
					[65.001780784107623, 7.0056305227378797],
					[65.001780743077191, 7.0056304748836329],
					[65.001780641741803, 7.0056304145561068],
					[65.001780582333595, 7.005630402015937],
					[65.0016890268693, 7.0056386887958251],
					[65.00160018363313, 7.0054305203804912],
					[65.001600142630949, 7.0054304746462117],
					[65.00160004219218, 7.005430414252948],
					[65.001599980990548, 7.0054304018470859],
					[65.001511080760551, 7.0054387011899513],
					[65.001511079863874, 7.0054387012568426],
					[65.001511077173774, 7.0054387014575221],
					[65.001511076277097, 7.0054387015244117],
					[65.001419525815237, 7.0054497824019526],
					[65.001330784115069, 7.0052388215681862],
					[65.001330742216084, 7.0052387759015584],
					[65.001330638162216, 7.0052387136578984],
					[65.001330576063864, 7.0052387013194712],
					[65.001239025616499, 7.0052497827966409],
					[65.001150183991626, 7.0050388229945844],
					[65.00115014206429, 7.0050387752092886],
					[65.001150038010309, 7.0050387129668188],
					[65.001149975043575, 7.0050387028151411],
					[65.001061087961119, 7.0050498004346231],
					[65.000969524121643, 7.005049799187856],
					[65.00086957881679, 7.0048499105888125],
					[65.000869536946055, 7.0048498670428305],
					[65.000869434798602, 7.0048498131449843],
					[65.000869375418617, 7.0048498027262074],
					[65.000780724179023, 7.0048608832445094],
					[65.000689083234462, 7.0046499208157069],
					[65.000689041307012, 7.0046498730318145],
					[65.000688937309519, 7.0046498150304197],
					[65.000688875211111, 7.004649802693633],
					[65.000600126133165, 7.0046608826070216],
					[65.000511283576444, 7.004449921303296],
					[65.000511241677273, 7.0044498756392839],
					[65.000511137623022, 7.0044498134005577],
					[65.000511076421304, 7.0044498009973868],
					[65.000419379028642, 7.0044609000028162],
					[65.000330724598143, 7.0044691880395646],
					[65.000239082687742, 7.0042610200659023],
					[65.000239041685163, 7.0042609743357174],
					[65.000238941245968, 7.0042609139498406],
					[65.000238880940927, 7.0042609014802917],
					[65.000150119673137, 7.0042691874825334],
					[65.000050180068556, 7.0040610130513592],
					[65.00005013909427, 7.0040609694409968],
					[65.000050038711635, 7.0040609132947003],
					[65.000049980199947, 7.0040609006918162],
					[64.999969380008309, 7.0040692017705801],
					[64.999969378214914, 7.0040692019044473],
					[64.99996937639321, 7.0040691999191047],
					[64.999969374628108, 7.0040692021721842],
					[64.999880719365322, 7.004080382739974],
					[64.999780781162428, 7.0038693132890231],
					[64.999780739291353, 7.0038692697463301],
					[64.99978063621856, 7.0038692138021048],
					[64.999780575913519, 7.0038692013336528],
					[64.999688887961796, 7.0038804009838502],
					[64.999600129929604, 7.0038803999724335],
					[64.999511282559595, 7.0036805181883564],
					[64.999511246096972, 7.0036804763638711],
					[64.999511153727752, 7.0036804153792716],
					[64.999511099671196, 7.003680400323594],
					[64.999419400228234, 7.0036803989537999],
					[64.999419394848061, 7.0036803993554706],
					[64.99941938588114, 7.0036804000249209],
					[64.999419381397658, 7.0036804003596469],
					[64.999330723390742, 7.0036886887678476],
					[64.999239081670822, 7.0034888167242073],
					[64.999239045208157, 7.0034887749004024],
					[64.999238953763907, 7.0034887159692518],
					[64.999238899707308, 7.0034887009141062],
					[64.999149999826258, 7.0034886990418341],
					[64.999149993549395, 7.0034886995104886],
					[64.999149980995711, 7.0034887004477957],
					[64.999149974747183, 7.0034887030355764],
					[64.999061223897243, 7.0034997819757479],
					[64.998969582630096, 7.0032888206227906],
					[64.998969540702234, 7.003288772844086],
					[64.998969436704172, 7.0032887148515952],
					[64.998969374605664, 7.0032887025187183],
					[64.998880720726447, 7.0032997832829249],
					[64.998789082109653, 7.0030999177694522],
					[64.998789044721846, 7.0030998738947421],
					[64.998788954202467, 7.0030998170180219],
					[64.998788900117518, 7.0030997998448052],
					[64.998700000235104, 7.0030997992409532],
					[64.998699993958255, 7.0030997997096911],
					[64.99869998140457, 7.0030998006471616],
					[64.998699975127721, 7.0030998011158969],
					[64.998611218952902, 7.0031108836563094],
					[64.998511280565424, 7.0028999146739741],
					[64.998511238665756, 7.0028998690157618],
					[64.998511135592594, 7.0028998130778541],
					[64.998511076184144, 7.0028998005451681],
					[64.998419378867993, 7.0029109018025277],
					[64.998330725325744, 7.0029191878241388],
					[64.998239082802641, 7.0027110193271369],
					[64.998239041799636, 7.0027109736026842],
					[64.998238941359773, 7.0027109132270535],
					[64.998238881054633, 7.0027109007618593],
					[64.998150126980221, 7.0027191864054101],
					[64.998061283773964, 7.0025110208677601],
					[64.998061242770873, 7.0025109751439372],
					[64.998061142330968, 7.0025109147694264],
					[64.998061081997434, 7.0025109001857251],
					[64.997969382237983, 7.0025192007088464],
					[64.997969380444587, 7.0025192008428059],
					[64.997969376857824, 7.002519201110724],
					[64.997969375064443, 7.0025192012446826],
					[64.997880724362702, 7.0025303835507264],
					[64.997789082908824, 7.0023193201589438],
					[64.997789040980663, 7.0023192723838221],
					[64.997788936982232, 7.0023192143976125],
					[64.997788874883668, 7.0023192020674649],
					[64.997699977577653, 7.0023304012528609],
					[64.99761122531487, 7.0023386878287202],
					[64.997519582779731, 7.0021305183506026],
					[64.997519541804863, 7.002130474747271],
					[64.997519441364815, 7.0021304143753831],
					[64.997519381059575, 7.0021304019118285],
					[64.997430591259899, 7.0021387003750135],
					[64.997339023706886, 7.0021387006844256],
					[64.997239079440945, 7.0019388113949113],
					[64.997239036672639, 7.0019387679263181],
					[64.997238935420853, 7.0019387139796994],
					[64.997238875115613, 7.0019387015166981],
					[64.997150126136745, 7.0019497824505406],
					[64.997061283847458, 7.0017388230008422],
					[64.997061241919099, 7.0017387752279348],
					[64.997061137863753, 7.0017387130075841],
					[64.997061075765131, 7.0017387006790939],
					[64.996969388335046, 7.001749800258132],
					[64.99688072806056, 7.0017498009860946],
					[64.996789082411539, 7.0015499178439979],
					[64.996789045023291, 7.0015498739746933],
					[64.99678895357826, 7.0015498150554683],
					[64.996788900418181, 7.0015497999385135],
					[64.996700129708614, 7.0015497989670292],
					[64.996611283387551, 7.0013499186089572],
					[64.99661124059071, 7.001349873023373],
					[64.996611136591909, 7.001349815043417],
					[64.99661107625829, 7.0013498004630703],
					[64.996519525935739, 7.0013608824340947],
					[64.996430784153958, 7.0011499223199838],
					[64.996430783200566, 7.0011499181491699],
					[64.996430779557059, 7.0011499141794422],
					[64.996430778603639, 7.0011499100086514],
					[64.99633077935168, 7.0009499100397958],
					[64.996330742019964, 7.0009498704098743],
					[64.996330653321536, 7.0009498155303982],
					[64.9963306001614, 7.0009498004148805],
					[64.996239029860646, 7.0009498006963042],
					[64.996150182662149, 7.0007499194082445],
					[64.996150146170365, 7.0007498754740638],
					[64.996150053828345, 7.0007498166259614],
					[64.99614999974311, 7.0007497994591095],
					[64.996061228108999, 7.0007497990562939],
					[64.995969581923163, 7.0005499168977856],
					[64.995969540022813, 7.0005498712475411],
					[64.995969436023699, 7.0005498132720501],
					[64.995969374850034, 7.0005498029986128],
					[64.99588072373956, 7.0005608827562344],
					[64.995789083284862, 7.000349920023667],
					[64.995789041356076, 7.000349872255164],
					[64.995788937356878, 7.0003498142808143],
					[64.995788875258128, 7.0003498019561095],
					[64.995700119164084, 7.0003608837795337],
					[64.995600181935202, 7.00014991785193],
					[64.995511282659891, 6.9999499205353768],
					[64.995419582902542, 6.9997388208289628],
					[64.995419540973572, 6.9997387730620719],
					[64.995419436945767, 6.9997387129720536],
					[64.995419374875368, 6.9997387027677478],
					[64.99533072379765, 6.999749782836191],
					[64.995239083023137, 6.9995388207844282],
					[64.995239082098053, 6.9995388187326713],
					[64.995239080219505, 6.9995388125103846],
					[64.995239079294464, 6.9995388104586427],
					[64.995139082283416, 6.9993388165746948],
					[64.995050183768953, 6.9991305218480031],
					[64.995050142736432, 6.9991304740156366],
					[64.995050042323541, 6.9991304157792715],
					[64.995049981093018, 6.9991304012718123],
					[64.994961225277706, 6.9991386871724481],
					[64.99486958332659, 6.9989305191393614],
					[64.994780782810537, 6.9987305195722724],
					[64.994780781885439, 6.9987305175205972],
					[64.994780780960326, 6.9987305154689041],
					[64.994780780931933, 6.9987305133501314],
					[64.994680780688071, 6.9985193150255158],
					[64.994680737890391, 6.9985192694477316],
					[64.994680633918932, 6.9985192136016217],
					[64.994680571820027, 6.9985192012816189],
					[64.99460011534714, 6.9985303823131453],
					[64.994500182314965, 6.9983305150819435],
					[64.994411283847924, 6.9981193229671605],
					[64.994411283819517, 6.9981193208483976],
					[64.994411281997742, 6.9981193188638873],
					[64.994411281969334, 6.9981193167451456],
					[64.994319582906712, 6.9979193196132385],
					[64.9942307836765, 6.997711022030991],
					[64.99423078275143, 6.9977110199793708],
					[64.994230779976107, 6.9977110138245768],
					[64.994230779051023, 6.9977110117729771],
					[64.994130781187636, 6.9975110151541768],
					[64.994039084129582, 6.9972999214989544],
					[64.99395018356401, 6.997088821330637],
					[64.99395018356401, 6.997088821330637],
					[64.993950182638926, 6.9970888192790799],
					[64.993950182638926, 6.9970888192790799],
					[64.993861282557361, 6.9968888187555267],
					[64.993861281632235, 6.9968888167039678],
					[64.993861280707122, 6.9968888146524266],
					[64.993861279782024, 6.9968888126008837],
					[64.993761280678967, 6.996680514566525],
					[64.993669599835854, 6.9964805554050518],
					[64.993669600176474, 6.996269400718087],
					[64.993669598070369, 6.9962693775469047],
					[64.993669587638138, 6.9962693359121584],
					[64.993669580151888, 6.9962693131440323],
					[64.99356958195844, 6.9960610166674062],
					[64.993480782794009, 6.9958610189844501],
					[64.993389083200242, 6.9956499191663717],
					[64.993389083200242, 6.9956499191663717],
					[64.993300182891261, 6.9954499190113326],
					[64.993300181966163, 6.9954499169598821],
					[64.993300181041008, 6.9954499149084146],
					[64.993300181041008, 6.9954499149084146],
					[64.993200200190984, 6.9952388553667033],
					[64.993200199957911, 6.9950305991881798],
					[64.993200197908479, 6.9950305802548112],
					[64.993200190194429, 6.9950305405382558],
					[64.993200183661614, 6.9950305219409445],
					[64.993111283763994, 6.994819321130997],
					[64.993111283763994, 6.994819321130997],
					[64.993111281913713, 6.9948193170281519],
					[64.993111281913713, 6.9948193170281519],
					[64.993019581752179, 6.9946193173521962],
					[64.993019580827024, 6.9946193153007981],
					[64.99301958079856, 6.9946193131821515],
					[64.993019579901869, 6.9946193132493821],
					[64.992919581951028, 6.9944110150857908],
					[64.992830799857273, 6.9942110581070125],
					[64.992830799997378, 6.9939999989403914],
					[64.99283079794786, 6.9939999800073434],
					[64.992830790233526, 6.9939999402916371],
					[64.9928307827754, 6.9939999196434934],
					[64.992739083124775, 6.9937888205455438],
					[64.992739082199634, 6.9937888184941936],
					[64.992739080320874, 6.9937888122728733],
					[64.99273907939569, 6.9937888102215267],
					[64.992639099849853, 6.993588852491448],
					[64.992639100018735, 6.9933806005166321],
					[64.992639097940639, 6.9933805794651729],
					[64.992639090254656, 6.9933805418685449],
					[64.992639083693106, 6.9933805211534343],
					[64.992550184060946, 6.9931693215875486],
					[64.992550184060946, 6.9931693215875486],
					[64.992550183135762, 6.9931693195362374],
					[64.992550183135762, 6.9931693195362374],
					[64.992461297756407, 6.9929693537447735],
					[64.992450199727045, 6.9927499899194565],
					[64.992450196780652, 6.9927499710540664],
					[64.992450189122991, 6.992749935576466],
					[64.992450182618342, 6.9927499190988369],
					[64.992361282747964, 6.9925499204456356],
					[64.992269600296936, 6.9923388582761978],
					[64.992269600410623, 6.9921306005972257],
					[64.992269597407159, 6.9921305774949047],
					[64.992269586916834, 6.9921305316268105],
					[64.992269578561746, 6.9921305110469332],
					[64.992169581815702, 6.991930515381001],
					[64.992080800010498, 6.991719359883426],
					[64.992080800104219, 6.9915110993838105],
					[64.992080798025867, 6.9915110783329597],
					[64.99208078756385, 6.9915110345839828],
					[64.992080781002073, 6.9915110138697631],
					[64.991980781232712, 6.9912999160713225],
					[64.991889100003945, 6.9910999563579814],
					[64.991889100195465, 6.9908889001186258],
					[64.991889098117014, 6.9908888790679562],
					[64.991889090430405, 6.9908888414731027],
					[64.991889083868514, 6.9908888207591868],
					[64.99180018367997, 6.9906805214235552],
					[64.991800182754801, 6.9906805193723658],
					[64.991800179979194, 6.9906805132188614],
					[64.991800179053982, 6.9906805111676942],
					[64.991700199615934, 6.9904805526780693],
					[64.991700199894169, 6.9902611000437522],
					[64.991700197815604, 6.9902610789932842],
					[64.991700189203641, 6.9902610393477325],
					[64.991700182641679, 6.9902610186341168],
					[64.991611282886069, 6.9900610208910923],
					[64.991519597591108, 6.9898499540491743],
					[64.991511300344953, 6.9896388922712784],
					[64.991511297398176, 6.9896388734068982],
					[64.991511287917888, 6.9896388359477228],
					[64.991511282281039, 6.9896388172855364],
					[64.991419582949831, 6.9894388192038894],
					[64.991330799663046, 6.9892305597032367],
					[64.991330800253778, 6.9890193995313545],
					[64.991330797249859, 6.9890193764301971],
					[64.991330786787131, 6.9890193326834282],
					[64.991330779299744, 6.9890193099193318],
					[64.991230780891811, 6.9888193157603817],
					[64.991139100174635, 6.9886110570475104],
					[64.99113910036715, 6.9884000001571405],
					[64.991139098288372, 6.9883999791072595],
					[64.991139089704433, 6.9883999415816165],
					[64.991139084067441, 6.9883999229199318],
					[64.991050183780317, 6.9881888212835079],
					[64.991050182855076, 6.988188819232465],
					[64.991050180079398, 6.9881888130793897],
					[64.991050179154158, 6.9881888110283645],
					[64.990950199801006, 6.9879888523651283],
					[64.990950200098354, 6.987780600346885],
					[64.990950198019476, 6.9877805792972216],
					[64.990950190332057, 6.9877805417045735],
					[64.990950183769769, 6.9877805209921684],
					[64.990861283934805, 6.9875693210203398],
					[64.990861283934805, 6.9875693210203398],
					[64.990861282112888, 6.9875693190368109],
					[64.990861282084325, 6.9875693169183632],
					[64.990769581631142, 6.9873693179519254],
					[64.990769580705901, 6.9873693159009509],
					[64.990769580677352, 6.9873693137824899],
					[64.990769579780647, 6.9873693138499569],
					[64.990669599560761, 6.9871610540013895],
					[64.990669600404914, 6.9869500006458134],
					[64.990669598325937, 6.9869499795964147],
					[64.990669589741628, 6.9869499420718997],
					[64.990669584075903, 6.9869499212924184],
					[64.990580784009452, 6.9867388226114411],
					[64.990580783055663, 6.9867388184420776],
					[64.990580780308463, 6.986738814407687],
					[64.99058077935463, 6.9867388102383057],
					[64.990480799787846, 6.9865388540144124],
					[64.990480799781508, 6.9863305991926792],
					[64.99048079773101, 6.9863305802618951],
					[64.990480790014686, 6.9863305405519283],
					[64.990480782555494, 6.9863305199077246],
					[64.99038908277933, 6.986119320686929],
					[64.990389082750738, 6.9861193185685213],
					[64.990300183436787, 6.985919318513953],
					[64.990300181614842, 6.9859193165305769],
					[64.990300180689601, 6.9859193144796858],
					[64.990300179764319, 6.9859193124287993],
					[64.990200200118721, 6.9857110529805544],
					[64.990200199763962, 6.9854999990569322],
					[64.990200197684757, 6.9854999780080194],
					[64.990200189071544, 6.9854999383662175],
					[64.990200183434183, 6.9854999197058225],
					[64.990111283270181, 6.9852999210062281],
					[64.990019583280386, 6.9850888193277898],
					[64.990019582355117, 6.9850888172769316],
					[64.990019579607889, 6.9850888132427942],
					[64.990019578682606, 6.9850888111919582],
					[64.98991960034499, 6.9848888523491235],
					[64.989919600319141, 6.9846693990180651],
					[64.989919598239837, 6.9846693779694382],
					[64.989919588729663, 6.9846693383958973],
					[64.989919583092203, 6.984669319735854],
					[64.989830783152186, 6.9844693182622031],
					[64.989739097792707, 6.9842610534624772],
					[64.989730799665423, 6.9840499922149721],
					[64.98973079671795, 6.9840499733524704],
					[64.989730788132931, 6.9840499358302077],
					[64.989730781598709, 6.9840499172380008],
					[64.989639083395303, 6.9838499191919823],
					[64.989550183626079, 6.9836388228080875],
					[64.989550183597473, 6.9836388206897491],
					[64.98955018270081, 6.9836388207573341],
					[64.989550182672204, 6.9836388186389957],
					[64.989461298289186, 6.983438852936966],
					[64.98945019986725, 6.9832193896224162],
					[64.989450196948297, 6.9832193728785557],
					[64.989450189259742, 6.9832193352893572],
					[64.989450182754069, 6.9832193188158751],
					[64.989361282952387, 6.9830193182103528],
					[64.989269598166089, 6.9828110533643404],
					[64.989261299737223, 6.9825999919294199],
					[64.98926129678955, 6.982599973067412],
					[64.989261288204176, 6.9825999355462773],
					[64.989261281669798, 6.9825999169547499],
					[64.989169583251368, 6.9823999209026502],
					[64.989080800301011, 6.9821888601320277],
					[64.989080800336737, 6.9819806010051506],
					[64.989080797331752, 6.9819805779067377],
					[64.989080786838343, 6.9819805320481203],
					[64.989080779378526, 6.9819805114062143],
					[64.98898078090501, 6.98178051516033],
					[64.988889100093473, 6.9815693584464338],
					[64.988889100166276, 6.9813611007581473],
					[64.988889098086503, 6.9813610797105961],
					[64.988889090368872, 6.9813610400044182],
					[64.988889083834337, 6.9813610214134743],
					[64.988800197846174, 6.9811499548933211],
					[64.988789100039938, 6.9809388882856052],
					[64.988789097120716, 6.9809388715423912],
					[64.988789088563564, 6.980938836140731],
					[64.988789082954312, 6.9809388196005582],
					[64.988700182674506, 6.9807388207330678],
					[64.988611299591838, 6.9805305585727275],
					[64.988611300239725, 6.980319400053582],
					[64.988611298159796, 6.9803193790063336],
					[64.988611287694525, 6.9803193352674366],
					[64.988611280205873, 6.9803193125080947],
					[64.988511299573162, 6.9801110548391687],
					[64.98851130013793, 6.9799000009811252],
					[64.988511298057944, 6.9798999799340082],
					[64.988511289385897, 6.9798999360600247],
					[64.988511281954516, 6.9798999175373888],
					[64.988419582387095, 6.9796999170938205],
					[64.988419582358432, 6.9796999149755763],
					[64.988419580565036, 6.9796999151110084],
					[64.988419581433064, 6.9796999129250477],
					[64.988319599707367, 6.979488855908083],
					[64.988319600449742, 6.9792806003108616],
					[64.988319598369642, 6.9792805792639268],
					[64.98831958885809, 6.9792805396946598],
					[64.988319583191313, 6.9792805189186549],
					[64.9882307995898, 6.9790805582679143],
					[64.988230799777725, 6.9788610991869939],
					[64.988230796772257, 6.9788610760896734],
					[64.988230787203207, 6.9788610322842342],
					[64.988230778846329, 6.9788610117115972],
					[64.988130781359118, 6.9786610140229461],
					[64.988039099986494, 6.9784499571368617],
					[64.98803909992175, 6.9782389005517356],
					[64.988039097841536, 6.9782388795051],
					[64.98803908922639, 6.9782388398688786],
					[64.988039082662809, 6.9782388191610565],
					[64.987950197889788, 6.9780388525289281],
					[64.98793910036737, 6.9778193905815638],
					[64.987939097419101, 6.9778193717210426],
					[64.987939088861225, 6.9778193363215113],
					[64.987939083251618, 6.977819319782502],
					[64.987850183087332, 6.9776193203700654],
					[64.987761299976981, 6.9774110600462702],
					[64.987761300157572, 6.9771999991862588],
					[64.987761297151835, 6.9771999760895351],
					[64.987761286685654, 6.977199932353277],
					[64.987761279225211, 6.9771999117137256],
					[64.987661299693414, 6.976999954030572],
					[64.987661300278006, 6.9767889002556567],
					[64.987661298197594, 6.9767888792094341],
					[64.987661288685402, 6.9767888396420528],
					[64.987661283018284, 6.976788818867071],
					[64.987569583297457, 6.9765805191552683],
					[64.987569582372075, 6.9765805171048925],
					[64.987569579624619, 6.9765805130719984],
					[64.987569578699237, 6.9765805110216421],
					[64.987469600082548, 6.9763805527151366],
					[64.987469599907442, 6.9761610996712253],
					[64.98746959782693, 6.9761610786252053],
					[64.98746958921123, 6.9761610389905018],
					[64.987469582647321, 6.9761610182836344],
					[64.987380800058588, 6.975961057005061],
					[64.98738079999184, 6.975749999044325],
					[64.987380797911271, 6.9757499779984267],
					[64.987380788341369, 6.9757499341954983],
					[64.987380780880699, 6.9757499135566468],
					[64.987280799587495, 6.9755388557453646],
					[64.98728080011, 6.9753305994439003],
					[64.987280798029374, 6.9753305783980935],
					[64.987280789384727, 6.9753305366457727],
					[64.987280781924042, 6.9753305160071335],
					[64.987189099839213, 6.9751305556314955],
					[64.987189099569676, 6.9749111005462527],
					[64.987189096563583, 6.9749110774502849],
					[64.987189086964676, 6.9749110315298335],
					[64.987189078607244, 6.9749110109592358],
					[64.987089100352009, 6.974711053070866],
					[64.987089100347305, 6.9744999994223882],
					[64.9870890982953, 6.9744999804949321],
					[64.987089089679174, 6.9744999408613131],
					[64.987089084040463, 6.9744999222054149],
					[64.987000199669424, 6.9742888600810558],
					[64.987000200162115, 6.9740805995019155],
					[64.987000200133366, 6.974080597383792],
					[64.987000200047135, 6.9740805910294199],
					[64.987000200047135, 6.9740805910294199],
					[64.986989100119146, 6.9738693905706262],
					[64.98698909717038, 6.9738693717111948],
					[64.986989088640357, 6.9738693384323165],
					[64.986989083926971, 6.9738693218268422],
					[64.986900199829321, 6.9736610591790553],
					[64.986900200052034, 6.9734500005547053],
					[64.986900197045699, 6.9734499774591532],
					[64.986900186549676, 6.9734499316076475],
					[64.986900179088749, 6.973449910969828],
					[64.986800199677361, 6.973249952173215],
					[64.986800199798367, 6.9730305997383208],
					[64.9868001977174, 6.9730305786930984],
					[64.986800189100904, 6.9730305390603826],
					[64.986800183433218, 6.9730305182868744],
					[64.986711299561449, 6.9728305585857502],
					[64.986711299923954, 6.9726193995978738],
					[64.986711299895205, 6.9726193974797743],
					[64.986711299808903, 6.9726193911254564],
					[64.986711299780126, 6.9726193890073569],
					[64.986700200355074, 6.9724110896985172],
					[64.986700197434942, 6.972411072957569],
					[64.986700189801198, 6.9724110396115693],
					[64.986700184162245, 6.9724110209563666],
					[64.986611300335369, 6.9721999601210003],
					[64.986611300235381, 6.9719888994372203],
					[64.986611298154287, 6.9719888783922279],
					[64.986611288640788, 6.9719888388281026],
					[64.986611282972987, 6.9719888180549603],
					[64.986519598309997, 6.9717805540934492],
					[64.986511299700865, 6.9715693917560548],
					[64.9865112967518, 6.9715693728972381],
					[64.986511288163669, 6.9715693353835109],
					[64.986511281627898, 6.9715693167966215],
					[64.986419599673582, 6.9713693556263179],
					[64.986419599694372, 6.9711610993610416],
					[64.986419597613164, 6.9711610783162463],
					[64.986419588041883, 6.9711610345165518],
					[64.98641958058063, 6.9711610138797431],
					[64.986319599690248, 6.9709499548817764],
					[64.986319599681096, 6.9707389006280041],
					[64.986319600013545, 6.9705306005399912],
					[64.986319597932237, 6.9705305794953443],
					[64.986319590240541, 6.9705305419142052],
					[64.986319583675851, 6.9705305212096365],
					[64.986230797995248, 6.9703193559874954],
					[64.986219599718439, 6.9701110898823666],
					[64.986219596769189, 6.9701110710239202],
					[64.986219589106199, 6.9701110355611204],
					[64.986219582599063, 6.9701110190928288],
					[64.986130799648592, 6.9699110570275105],
					[64.986130799753241, 6.9696889004740354],
					[64.986130797671834, 6.9696888794295839],
					[64.986130789025609, 6.9696888376808994],
					[64.986130781564157, 6.969688817044708],
					[64.986039097838386, 6.9694888524039733],
					[64.986030799577549, 6.9692805969457723],
					[64.98603079991031, 6.9690610999204496],
					[64.986030797828803, 6.9690610788761296],
					[64.986030789182422, 6.9690610371278243],
					[64.986030781720842, 6.9690610164918887],
					[64.985939082296198, 6.9688610177219363],
					[64.985939082267393, 6.9688610156039017],
					[64.985939081370702, 6.9688610156719735],
					[64.985939081341883, 6.9688610135539388],
					[64.985839099748162, 6.968649954050365],
					[64.985839100028258, 6.9684388991315798],
					[64.985839097946638, 6.9684388780874613],
					[64.985839089328948, 6.968438838457681],
					[64.985839082792793, 6.9684388198720049],
					[64.985750182996171, 6.9682388197927452],
					[64.985661297856524, 6.9680305532351037],
					[64.985650199617467, 6.9678193906525427],
					[64.985650196667976, 6.967819371794751],
					[64.985650189004389, 6.96781933633335],
					[64.985650183393687, 6.9678193197978819],
					[64.985561282717768, 6.9676193189556361],
					[64.985469583139206, 6.9674110194863328],
					[64.985380798141421, 6.9672110528030977],
					[64.985369600073469, 6.9669888901872925],
					[64.985369597123849, 6.9669888713297938],
					[64.985369588563415, 6.966988835937153],
					[64.985369582952615, 6.9669888194020144],
					[64.985280783454115, 6.966788819886955],
					[64.985189098375955, 6.9665805539430954],
					[64.985180800373328, 6.9663693926286649],
					[64.985180797423624, 6.9663693737713679],
					[64.985180787908632, 6.9663693342113717],
					[64.985180782297775, 6.9663693176764996],
					[64.985089082688788, 6.9661693193952008],
					[64.985000199984199, 6.9659610581045435],
					[64.985000199925537, 6.9657499990022025],
					[64.985000196946856, 6.9657499780271293],
					[64.985000187345193, 6.9657499321137513],
					[64.98500017898651, 6.9657499115477064],
					[64.98490018199395, 6.9655499157634138],
					[64.984811284019273, 6.9653388223547523],
					[64.984811283990425, 6.9653388202368101],
					[64.984811282168195, 6.9653388182552423],
					[64.984811282139333, 6.9653388161372822],
					[64.984719582899984, 6.9651388197779385],
					[64.984630784362409, 6.9649305222368616],
					[64.984630783407979, 6.9649305180692096],
					[64.984630779734687, 6.9649305119882063],
					[64.984630778837996, 6.964930512056406],
					[64.984530780673865, 6.9647305157358455],
					[64.984439083157071, 6.9645193207030003],
					[64.984439083128194, 6.9645193185850731],
					[64.984350183226766, 6.9643193204098388],
					[64.984261283825418, 6.9641110212091668],
					[64.98426128289988, 6.9641110191594944],
					[64.984261280123192, 6.9641110130104842],
					[64.984261279197611, 6.9641110109607967],
					[64.984161280758357, 6.9639110140369169],
					[64.984069583395168, 6.9636999200306846],
					[64.984069583366264, 6.9636999179127859],
					[64.983980782835658, 6.9634999196930174],
					[64.983889082988654, 6.9632888201833687],
					[64.983889082063072, 6.9632888181337558],
					[64.983889080211938, 6.9632888140345095],
					[64.983889079286385, 6.9632888119848966],
					[64.983789082390658, 6.9630888164691713],
					[64.983700183923617, 6.9628805212799536],
					[64.983700182998049, 6.9628805192303478],
					[64.983611282545567, 6.9626805191724648],
					[64.983519582797328, 6.9624693192862637],
					[64.983519540885524, 6.9624692737167537],
					[64.983519436843096, 6.9624692137978199],
					[64.983519374741391, 6.9624692015662504],
					[64.983430719253647, 6.9624803830710009],
					[64.983330781006444, 6.9622693165590288],
					[64.983239081838875, 6.9620693160087379],
					[64.983239039926943, 6.9620692704403062],
					[64.983238935913263, 6.9620692126413708],
					[64.98323887473714, 6.9620692024603663],
					[64.983150125013537, 6.9620803838561098],
					[64.983061284204226, 6.9618693216364607],
					[64.983061242263261, 6.9618692739508479],
					[64.983061138220648, 6.9618692140352429],
					[64.983061076118901, 6.961869201805257],
					[64.982969525871326, 6.9618803829634439],
					[64.982880784400848, 6.9616693215984364],
					[64.982880782578562, 6.9616693196172603],
					[64.982880779801818, 6.961669313468799],
					[64.982880778876194, 6.9616693114193193],
					[64.982780778681771, 6.9614693104706076],
					[64.982780742236429, 6.9614692708473802],
					[64.982780652598223, 6.9614692140765806],
					[64.982780600330955, 6.9614691989784525],
					[64.982689030258882, 6.9614692000233935],
					[64.982600183274201, 6.9612693203273732],
					[64.982511283833844, 6.9610610212142916],
					[64.982511242818276, 6.9610609755803621],
					[64.982511142361986, 6.9610609153958203],
					[64.982511082024615, 6.961060900913524],
					[64.982419524575093, 6.9610691871528747],
					[64.982330783100537, 6.9608693196707181],
					[64.982330782174913, 6.9608693176213086],
					[64.982330781249289, 6.960869315571899],
					[64.982330780323721, 6.9608693135224868],
					[64.982230779588932, 6.9606610137000313],
					[64.982230740395508, 6.9606609700482505],
					[64.982230639967995, 6.9606609119835552],
					[64.982230582378477, 6.9606609015327816],
					[64.982139026745045, 6.9606691889006269],
					[64.982050183837529, 6.9604610203019428],
					[64.982050182911891, 6.9604610182525688],
					[64.981961282586624, 6.9602610191904546],
					[64.981961246083131, 6.9602609753346565],
					[64.981961153725507, 6.9602609166569165],
					[64.981961099635797, 6.960260899580593],
					[64.981869530405305, 6.960260900409005],
					[64.981780783089889, 6.960061018866174],
					[64.98178078219324, 6.9600610189345238],
					[64.981780781238683, 6.9600610147674926],
					[64.981780781238683, 6.9600610147674926],
					[64.981680781389571, 6.9598499132164964],
					[64.981680737712551, 6.959849869908588],
					[64.981680633698033, 6.9598498121215355],
					[64.98168057341843, 6.9598498018777812],
					[64.981600115360905, 6.959860881396783],
					[64.981500181734489, 6.9596610165500969],
					[64.98141128386375, 6.9594499221941106],
					[64.981411283834802, 6.9594499200764472],
					[64.981411282012516, 6.9594499180955269],
					[64.981411281983554, 6.9594499159778449],
					[64.981319582385524, 6.9592499179329517],
					[64.981319544985013, 6.9592498741479538],
					[64.981319454420372, 6.9592498153383406],
					[64.98131940035951, 6.9592498003820857],
					[64.981230727643094, 6.9592497999784637],
					[64.981139082277082, 6.9590499163981923],
					[64.981139082277082, 6.9590499163981923],
					[64.981139081351458, 6.9590499143489373],
					[64.981139081351458, 6.9590499143489373],
					[64.981039080871653, 6.958838813081325],
					[64.981039038091026, 6.9588387697077021],
					[64.981038935898425, 6.95883871390678],
					[64.981038874693127, 6.9588387016162825],
					[64.980950121916692, 6.9588497826992661],
					[64.980861283068947, 6.958649919521223],
					[64.980769583074235, 6.9584388203845364],
					[64.98076954113219, 6.9584387727083401],
					[64.980769437117175, 6.9584387149287057],
					[64.980769375015143, 6.9584387027076229],
					[64.980680723679669, 6.9584497836132977],
					[64.980589082675905, 6.9582388198464207],
					[64.980589081750281, 6.9582388177972332],
					[64.980589079899005, 6.9582388136988396],
					[64.980589078973352, 6.9582388116496547],
					[64.980489081994989, 6.9580388166654528],
					[64.980400184062603, 6.9578305208959987],
					[64.980400183136936, 6.9578305188468423],
					[64.98031128262889, 6.9576305193676573],
					[64.98021958331843, 6.9574193197835621],
					[64.980219582392763, 6.9574193177344332],
					[64.98021957964481, 6.9574193137045892],
					[64.980219578719158, 6.9574193116554586],
					[64.980119582362008, 6.9572193159991178],
					[64.980030783783747, 6.9570110223940009],
					[64.980030782858066, 6.9570110203448996],
					[64.980030781932427, 6.9570110182957974],
					[64.980030781903451, 6.9570110161782468],
					[64.979939082403547, 6.9568110174469036],
					[64.9799390413867, 6.9568109718238285],
					[64.979938940957709, 6.9568109137784298],
					[64.979938880648945, 6.9568109014245803],
					[64.979850127368465, 6.9568191864499829],
					[64.979761284161356, 6.9566110204542557],
					[64.979761243144424, 6.9566109748317686],
					[64.979761141789709, 6.9566109147384481],
					[64.979761082348574, 6.9566109001991165],
					[64.979669519603107, 6.9566191883531117],
					[64.979569579746553, 6.9564110132234811],
					[64.979569538758582, 6.956410969719153],
					[64.979569440151778, 6.9564109136566143],
					[64.979569380739605, 6.9564109012353557],
					[64.979480725304796, 6.9564191863415008],
					[64.979389082745428, 6.9562110199334581],
					[64.979389041728382, 6.9562109743122305],
					[64.979388941270201, 6.9562109141527442],
					[64.979388880961352, 6.9562109018004818],
					[64.979300124126709, 6.9562191888880101],
					[64.97921128321785, 6.9560193190629045],
					[64.97921124581643, 6.9560192752858336],
					[64.979211154353834, 6.9560192165601666],
					[64.979211100263697, 6.9560191994940626],
					[64.979119529944981, 6.9560191999219221],
					[64.979030782835608, 6.9558193185599881],
					[64.979030746359797, 6.9558192768324743],
					[64.97903065486814, 6.9558192159903856],
					[64.979030599881298, 6.9558191989933009],
					[64.978939024182495, 6.955819200505946],
					[64.978839100227347, 6.9556193524369938],
					[64.978839100148377, 6.9554111008683668],
					[64.978839098064981, 6.9554110798307835],
					[64.978839090340472, 6.9554110401470668],
					[64.978839083802725, 6.9554110215694509],
					[64.978750183929677, 6.9551999216911753],
					[64.978750183929677, 6.9551999216911753],
					[64.978750183003982, 6.9551999196422347],
					[64.978750183003982, 6.9551999196422347],
					[64.978661298020185, 6.9549999524601445],
					[64.978650200008587, 6.9547888896213408],
					[64.978650197086438, 6.9547888728873524],
					[64.978650189419852, 6.9547888374389908],
					[64.978650183807716, 6.9547888209105793],
					[64.978561283678729, 6.954580520366898],
					[64.978561282782053, 6.9545805204354245],
					[64.978561281856372, 6.9545805183865177],
					[64.978561281827353, 6.9545805162690861],
					[64.978469600025491, 6.9543805564107322],
					[64.978469599652257, 6.9541693994828107],
					[64.978469597568676, 6.9541693784456164],
					[64.978469587992464, 6.9541693346650408],
					[64.978469579603171, 6.9541693119901993],
					[64.978369582389689, 6.9539610153115916],
					[64.978280799921521, 6.9537610577276645],
					[64.978280800398593, 6.9535388991669498],
					[64.978280797389218, 6.9535388760811072],
					[64.978280786916173, 6.9535388323696274],
					[64.97828077945249, 6.9535388117439902],
					[64.978180781138988, 6.9533388151157824],
					[64.978089082894314, 6.9531305185605099],
					[64.978000199591563, 6.9529305586196477],
					[64.978000199810296, 6.952719400987931],
					[64.97800019769744, 6.9527193778338194],
					[64.978000188120831, 6.9527193340545521],
					[64.978000179760372, 6.9527193134979477],
					[64.977900182192883, 6.9525110151683647],
					[64.977811283169885, 6.952311019566241],
					[64.977719600229619, 6.9520999578283966],
					[64.977719599720004, 6.9518888998968897],
					[64.977719596710372, 6.9518888768117213],
					[64.977719587133478, 6.9518888330331956],
					[64.977719578743873, 6.9518888103597032],
					[64.977619581714066, 6.9516888153454257],
					[64.977530799743434, 6.9514805597921674],
					[64.977530799628312, 6.9512693994077805],
					[64.977530797544333, 6.9512693783715553],
					[64.977530788922067, 6.9512693387596389],
					[64.977530783251424, 6.951269317997947],
					[64.97743908284103, 6.951061018709769],
					[64.977439081915321, 6.95106101666107],
					[64.977439080063832, 6.9510610125636765],
					[64.977439079138094, 6.9510610105149784],
					[64.977339081849721, 6.9508610163859244],
					[64.977250200077947, 6.9506499606720231],
					[64.977250200132005, 6.9504388999174758],
					[64.977250197122174, 6.9504388768328749],
					[64.977250186619145, 6.9504388310069771],
					[64.977250179155021, 6.9504388103830026],
					[64.977150181880333, 6.9502388156880333],
					[64.977061299937546, 6.9500305591495897],
					[64.977061300443268, 6.9498194001792939],
					[64.977061298359075, 6.9498193791435723],
					[64.977061288810717, 6.9498193374841737],
					[64.977061282243199, 6.9498193167918361],
					[64.976969582437988, 6.9496193175341459],
					[64.976969580615531, 6.9496193155542239],
					[64.976969580586427, 6.9496193134369255],
					[64.976969579689793, 6.9496193135056101],
					[64.976869582063784, 6.9494110153828137],
					[64.976780800387132, 6.9492110581535131],
					[64.976780800211117, 6.9490000009961523],
					[64.976780798126811, 6.9489999799606972],
					[64.976780790400639, 6.9489999402819178],
					[64.976780782936345, 6.9489999196587044],
					[64.976689082889436, 6.9487888190131297],
					[64.97668908196367, 6.9487888169645693],
					[64.976689080112138, 6.9487888128674467],
					[64.976689079186343, 6.9487888108188871],
					[64.976589099829923, 6.9485888524930743],
					[64.976589100026672, 6.9483693993010611],
					[64.976589097942266, 6.9483693782658085],
					[64.976589089319262, 6.9483693386562049],
					[64.976589082751531, 6.9483693179646107],
					[64.976500199930186, 6.9481693569839331],
					[64.976500199952497, 6.9479610996688583],
					[64.976500197868049, 6.947961078633706],
					[64.976500188290032, 6.9479610348585918],
					[64.976500180825582, 6.9479610142358865],
					[64.97640018178511, 6.9477499175810111],
					[64.976311299906484, 6.9475499578162276],
					[64.976311300024705, 6.9473388999586509],
					[64.976311297940171, 6.9473388789236967],
					[64.97631128836197, 6.9473388351491172],
					[64.976311279971654, 6.9473388124782485],
					[64.976211300197889, 6.9471305539497381],
					[64.976211300178974, 6.946919399359861],
					[64.976211298094384, 6.9469193783250196],
					[64.976211288574291, 6.9469193387852446],
					[64.976211282903037, 6.9469193180255031],
					[64.976119583306669, 6.9467110179722447],
					[64.976030797727987, 6.9465110520275521],
					[64.97601960036981, 6.9462999885440118],
					[64.97601959655006, 6.9462999718814187],
					[64.976019589807237, 6.9462999384874191],
					[64.976019584194177, 6.946299921962396],
					[64.975930783931076, 6.9460888214997532],
					[64.975930783931076, 6.9460888214997532],
					[64.975930782079487, 6.946088817402913],
					[64.975930782079487, 6.946088817402913],
					[64.975839083341015, 6.9458888198502224],
					[64.975750183696064, 6.9456805210560519],
					[64.975750182770284, 6.9456805190076487],
					[64.975750179992843, 6.945680512862503],
					[64.975750179067063, 6.9456805108141184],
					[64.975650182279765, 6.9454805170356622],
					[64.97556128433753, 6.9452693215216446],
					[64.97556128433753, 6.9452693215216446],
					[64.975561281589236, 6.9452693174937474],
					[64.975561281589236, 6.9452693174937474],
					[64.975469581648539, 6.9450693170364941],
					[64.975469539731492, 6.9450692715078057],
					[64.975469435711432, 6.9450692137912746],
					[64.975469375401715, 6.9450692014657873],
					[64.975380724149545, 6.945080383556113],
					[64.975289083409876, 6.9448693193676867],
					[64.975289081587405, 6.9448693173882088],
					[64.975289079735774, 6.9448693132915702],
					[64.975289078809965, 6.9448693112432505],
					[64.975189081729397, 6.9446693153907457],
					[64.975100183931019, 6.9444610211854396],
					[64.975100183005196, 6.9444610191371492],
					[64.975011283369071, 6.9442610195683017],
					[64.974919582558911, 6.9440499189943736],
					[64.974919581662235, 6.9440499190632421],
					[64.9749195797814, 6.944049912849569],
					[64.974919578855591, 6.9440499108013043],
					[64.9748195824249, 6.9438499163924758],
					[64.974730783970358, 6.943638822509584],
					[64.974730783941183, 6.9436388203924704],
					[64.974730782118698, 6.9436388184131168],
					[64.974730782089523, 6.9436388162960032],
					[64.974639082563201, 6.9434388189406731],
					[64.974550183788253, 6.9432305203263569],
					[64.974550182862401, 6.9432305182781526],
					[64.974550180084861, 6.9432305121335176],
					[64.974550179159039, 6.9432305100853098],
					[64.97445018155571, 6.9430305151399754],
					[64.974361283568754, 6.9428193221222019],
					[64.974361283539579, 6.9428193200051185],
					[64.974361281717066, 6.9428193180258475],
					[64.974361281717066, 6.9428193180258475],
					[64.97426959762447, 6.9426193525159805],
					[64.97426130022734, 6.9424110928618825],
					[64.974261297274651, 6.9424110740149265],
					[64.974261288708462, 6.9424110386448703],
					[64.974261283065729, 6.9424110200046778],
					[64.974169583107951, 6.942199920819756],
					[64.974169583078748, 6.9421999187026895],
					[64.974080782577943, 6.9419999193819937],
					[64.973989082912567, 6.9417888208414942],
					[64.97398908198673, 6.9417888187933787],
					[64.973989080105824, 6.9417888125800804],
					[64.973989079179944, 6.9417888105319685],
					[64.973889082239737, 6.9415888164842805],
					[64.973800184380451, 6.9413805211045112],
					[64.973800182557923, 6.9413805191253797],
					[64.973711299754925, 6.9411805590760576],
					[64.973711300023794, 6.9409694003663311],
					[64.973711297909148, 6.9409693772168986],
					[64.973711288358345, 6.9409693355658888],
					[64.973711279967063, 6.9409693128992229],
					[64.973611281271218, 6.9407610143731358],
					[64.97351958310621, 6.9405610200029981],
					[64.973430784182128, 6.9403499215330733],
					[64.973430784182128, 6.9403499215330733],
					[64.973430782330382, 6.9403499174370129],
					[64.973430782330382, 6.9403499174370129],
					[64.973339081710492, 6.940149917491766],
					[64.973339081681289, 6.9401499153747475],
					[64.97333908078464, 6.9401499154437376],
					[64.973339080755409, 6.9401499133267404],
					[64.973239081717708, 6.9399388169323251],
					[64.973150182911056, 6.9397388203214518],
					[64.973061299855615, 6.9395305588094374],
					[64.973061300437124, 6.9393194008238126],
					[64.973061297425588, 6.939319377744094],
					[64.973061286919162, 6.9393193319297577],
					[64.973061278556898, 6.9393193113811327],
					[64.972961280771543, 6.9391193142232916],
					[64.972869583216337, 6.9389110185537239],
					[64.972780782547005, 6.9387110183371803],
					[64.972780746066476, 6.938710976642672],
					[64.972780654567998, 6.9387109158730054],
					[64.972780599608484, 6.9387109010317767],
					[64.972689030370347, 6.9387109008708672],
					[64.972600182567177, 6.9385110185874064],
					[64.972600181670543, 6.9385110186564489],
					[64.972600180715375, 6.9385110144915982],
					[64.972600180715375, 6.9385110144915982],
					[64.972500181900855, 6.9382999171266819],
					[64.972411282569894, 6.9380999207486722],
					[64.972319583345651, 6.9378888207190679],
					[64.972319582419757, 6.9378888186712162],
					[64.97231957964199, 6.9378888125276665],
					[64.972319578716053, 6.937888810479798],
					[64.972219581610275, 6.9376888155146021],
					[64.972130783939619, 6.9374805209813353],
					[64.972130783042971, 6.9374805210504125],
					[64.972130782117048, 6.9374805190025706],
					[64.972130782087774, 6.9374805168856719],
					[64.972039082812685, 6.9372805191167544],
					[64.97195018426244, 6.9370693212479182],
					[64.971950183336503, 6.937069319200126],
					[64.971950179662073, 6.9370693131258161],
					[64.971950178736165, 6.9370693110780204],
					[64.971850182250577, 6.9368693154384751],
					[64.971761283744101, 6.9366610214641522],
					[64.9717612427212, 6.9366609758857507],
					[64.971761142255275, 6.9366609158164243],
					[64.971761081915645, 6.9366609013945597],
					[64.971669524858868, 6.936669188775995],
					[64.971580782936897, 6.9364693188542015],
					[64.971489083198449, 6.936261019556679],
					[64.971489042175421, 6.936260973979369],
					[64.971488940812705, 6.9362609139812355],
					[64.971488881398955, 6.9362609016081613],
					[64.971400120416448, 6.9362691887602717],
					[64.971300180190397, 6.9360610139624077],
					[64.9713001391966, 6.9360609705025542],
					[64.971300039656413, 6.9360609124841952],
					[64.971299981139282, 6.9360609000425049],
					[64.971211222857548, 6.9360691875810287],
					[64.971119581667779, 6.9358693176571089],
					[64.971119545157208, 6.9358692738521945],
					[64.971119453686853, 6.935869215212751],
					[64.971119399623774, 6.9358692003091091],
					[64.971030727769559, 6.9358692004205977],
					[64.970939081627705, 6.9356693180503743],
					[64.970939039678541, 6.9356692704272289],
					[64.970938935654871, 6.9356692127567463],
					[64.970938875373733, 6.9356692025711562],
					[64.970850124807299, 6.9356803831966367],
					[64.970761284063826, 6.9354693225018709],
					[64.970761284034523, 6.9354693203850823],
					[64.970761282211924, 6.9354693184065752],
					[64.970761282182636, 6.9354693162897858],
					[64.970669581777912, 6.9352693164129731],
					[64.97066954529646, 6.9352692747263873],
					[64.970669453825892, 6.9352692160899547],
					[64.970669399733424, 6.9352691990709632],
					[64.970580724264224, 6.935269199303959],
					[64.970480778724692, 6.935069311812013],
					[64.970480736804646, 6.935069266307381],
					[64.970480634632665, 6.935069212735435],
					[64.970480576115492, 6.9350692002962786],
					[64.970389025927034, 6.9350803824714289],
					[64.970300184258875, 6.934869321039538],
					[64.970300142338772, 6.9348692755355188],
					[64.970300037359564, 6.9348692137051398],
					[64.970299975255728, 6.9348692015431199],
					[64.970211223823284, 6.9348803824689371],
					[64.970119583076695, 6.9346693207321159],
					[64.970119541127218, 6.9346692731119779],
					[64.97011943707389, 6.93466921333036],
					[64.970119374970025, 6.9346692011688802],
					[64.970030724095295, 6.9346803833922115],
					[64.969939083011496, 6.9344693195232709],
					[64.969939082085531, 6.9344693174757221],
					[64.969939080233601, 6.9344693133806015],
					[64.969939079307665, 6.9344693113330482],
					[64.969839078976975, 6.9342693107193174],
					[64.96983903708599, 6.9342692673337281],
					[64.96983893491371, 6.9342692137660826],
					[64.96983887460317, 6.9342692014672878],
					[64.969750125009242, 6.9342803840242073],
					[64.969661283901843, 6.9340693216597247],
					[64.969661241952153, 6.9340692740413346],
					[64.969661137898612, 6.9340692142630438],
					[64.969661075765387, 6.9340691999864452],
					[64.969569525622319, 6.9340803830728941],
					[64.969480783853342, 6.9338693214597678],
					[64.969480741932941, 6.933869275958692],
					[64.969480637849969, 6.9338692140648863],
					[64.969480575746076, 6.9338692019055452],
					[64.969388878624343, 6.9338804011702253],
					[64.96930012662159, 6.933888686284468],
					[64.969211283735518, 6.9336805211847503],
					[64.96921124271168, 6.9336804756152279],
					[64.969211142244589, 6.9336804155626401],
					[64.969211081904675, 6.9336804011487718],
					[64.969119382326241, 6.933688700316794],
					[64.969119379636268, 6.9336887005243817],
					[64.96911937694631, 6.9336887007319667],
					[64.969119375182302, 6.9336887029870153],
					[64.969030723809354, 6.9336997820796471],
					[64.968939082621617, 6.933488820428729],
					[64.968939040671785, 6.9334887728124937],
					[64.968938936617974, 6.9334887130380478],
					[64.968938875440003, 6.933488702927237],
					[64.968849974810666, 6.9334998018348628],
					[64.968761219349943, 6.933510883103458],
					[64.968661281088174, 6.9332999140903837],
					[64.968661239196862, 6.933299870708197],
					[64.968661136068974, 6.9332998129824794],
					[64.968661075758348, 6.933299800686366],
					[64.968569379090042, 6.9333109018905894],
					[64.968480724938317, 6.9333191873987117],
					[64.968389083252902, 6.9331110197844277],
					[64.968389042228921, 6.9331109742171551],
					[64.968388940864941, 6.9331109142377132],
					[64.968388881450949, 6.9331109018729462],
					[64.968299991401068, 6.9331191998300641],
					[64.968211227848968, 6.9331191990163639],
					[64.968119582156106, 6.9329193170207395],
					[64.968119540235421, 6.9329192715234305],
					[64.968119436210728, 6.9329192138695328],
					[64.968119375003354, 6.9329192016437471],
					[64.968030587063581, 6.9329303993013323],
					[64.967938899986791, 6.9329304001833538],
					[64.967938894606817, 6.9329304005986598],
					[64.967938885640251, 6.9329304012908333],
					[64.967938881156968, 6.93293040163692],
					[64.967849981250183, 6.9329387007897623],
					[64.967849980353549, 6.9329387008589771],
					[64.967849976766928, 6.9329387011358437],
					[64.967849974973575, 6.9329387012742805],
					[64.967761087826901, 6.9329498004565275],
					[64.967669399820764, 6.9329497992092701],
					[64.967669394440804, 6.9329497996245664],
					[64.967669380990927, 6.9329498006628052],
					[64.967669374714305, 6.9329498011473181],
					[64.967580579140829, 6.9329609016140132],
					[64.967488882250024, 6.9329692003213967],
					[64.967488879560037, 6.9329692005290422],
					[64.967488876870078, 6.9329692007366841],
					[64.967488875106085, 6.9329692029916421],
					[64.967399986611113, 6.9329803994706198],
					[64.967311100090811, 6.9329804000829736],
					[64.96731109471088, 6.9329804004982556],
					[64.967311085744285, 6.9329804011903935],
					[64.967311082157664, 6.9329804014672485],
					[64.967219526904827, 6.9329886869566693],
					[64.967130783973559, 6.9327805225137125],
					[64.967130742920119, 6.9327804748325601],
					[64.967130641556025, 6.9327804148571079],
					[64.967130582112659, 6.9327804003771121],
					[64.967038881670788, 6.9327887019302983],
					[64.96703887987745, 6.9327887020687387],
					[64.967038877158174, 6.9327887001599047],
					[64.967038875394181, 6.9327887024148334],
					[64.966949974800087, 6.9327998027515445],
					[64.966861087624494, 6.9328108998548466],
					[64.966769399609333, 6.932810899031276],
					[64.966769395126036, 6.9328108993773654],
					[64.96676938615947, 6.9328109000695415],
					[64.966769380779482, 6.932810900484851],
					[64.966680581409264, 6.9328192003200311],
					[64.966680579615954, 6.9328192004584661],
					[64.966680576926009, 6.9328192006661151],
					[64.966680576029319, 6.9328192007353335],
					[64.966588887898396, 6.9328303994527269],
					[64.966499999577138, 6.9328304006559529],
					[64.966499995093841, 6.9328304010020352],
					[64.966499986127275, 6.9328304016941962],
					[64.966499980717984, 6.9328303999930467],
					[64.966411080829516, 6.9328387010679089],
					[64.966411079932882, 6.9328387011371229],
					[64.966411077242881, 6.9328387013447719],
					[64.966411076346247, 6.9328387014139849],
					[64.966319387728603, 6.9328498003946173],
					[64.966230599917793, 6.9328498001156449],
					[64.966230593641171, 6.932849800600148],
					[64.966230581984632, 6.9328498014999393],
					[64.966230575708011, 6.9328498019844433],
					[64.96613887901718, 6.9328609014648181],
					[64.966049990749525, 6.9328691992901978],
					[64.965961099731828, 6.9328692005421724],
					[64.965961094351883, 6.9328692009574509],
					[64.965961081798667, 6.9328692019264357],
					[64.965961076389405, 6.9328692002253085],
					[64.965869522684983, 6.9328803838146493],
					[64.965780783158749, 6.9326805180720221],
					[64.965780745779895, 6.9326804764656913],
					[64.965780655175664, 6.9326804156629276],
					[64.965780600215581, 6.9326804008377358],
					[64.965688899606633, 6.9326803992512973],
					[64.965688895123336, 6.9326803995973911],
					[64.965688886156769, 6.932680400289577],
					[64.965688880776838, 6.9326804007048883],
					[64.9655999808883, 6.9326887019807639],
					[64.965599979991609, 6.9326887020499841],
					[64.965599977272305, 6.9326887001412612],
					[64.965599974611678, 6.9326887024652919],
					[64.965511088325172, 6.9326997992332204],
					[64.965419400324791, 6.9326998008285345],
					[64.965419394018866, 6.9326997991966914],
					[64.965419380569003, 6.9326998002349489],
					[64.965419375218346, 6.932699802766618],
					[64.965330578711814, 6.9327109009566046],
					[64.965238890775751, 6.9327191990006733],
					[64.965149999750395, 6.9327192007050789],
					[64.965149994341118, 6.9327191990040307],
					[64.965149980891255, 6.9327192000422659],
					[64.96514997464395, 6.9327192026431179],
					[64.965061077203657, 6.9327304016515825],
					[64.964980578970184, 6.9327387001655767],
					[64.964980577176831, 6.9327387003040091],
					[64.964980576280198, 6.9327387003732222],
					[64.964888887652563, 6.9327497990208586],
					[64.964800000209607, 6.9327498003273034],
					[64.964799993932985, 6.9327498008117985],
					[64.964799981379755, 6.9327498017807896],
					[64.964799975103148, 6.9327498022652847],
					[64.964711078967582, 6.9327609013320783],
					[64.964619391020435, 6.9327691989774358],
					[64.964530599604629, 6.9327691991476259],
					[64.964530594224684, 6.9327691995628982],
					[64.964530581671454, 6.9327692005318662],
					[64.964530576291523, 6.9327692009471384],
					[64.964438879199832, 6.9327804016212449],
					[64.964349990916688, 6.9327886990920886],
					[64.964261099880588, 6.9327887005161637],
					[64.964261093604009, 6.9327887010006339],
					[64.964261081947441, 6.9327887019003729],
					[64.964261075641517, 6.9327887002685538],
					[64.964169376269822, 6.9327998013523393],
					[64.964169375373132, 6.9327998014215515],
					[64.964080587838396, 6.9328109001166744],
					[64.963988899790863, 6.9328108991329591],
					[64.963988895307565, 6.932810899479005],
					[64.963988886340999, 6.932810900171094],
					[64.963988880961011, 6.9328109005863503],
					[64.963899981043127, 6.932819200807443],
					[64.963899980146493, 6.9328192008766498],
					[64.963899976559858, 6.9328192011534826],
					[64.96389997476652, 6.9328192012919017],
					[64.963811224295355, 6.932830383793644],
					[64.963719582617586, 6.9326193197153829],
					[64.963719540667469, 6.932619272109676],
					[64.96371943664262, 6.9326192144661283],
					[64.963719375435247, 6.9326192022428632],
					[64.963630577631889, 6.932630401687188],
					[64.96353889057643, 6.932638699312915],
					[64.963450129665461, 6.9326386996183134],
					[64.963361282556335, 6.9324388185110042],
					[64.963361240635464, 6.93243877302241],
					[64.963361136581241, 6.9324387132641094],
					[64.963361076270488, 6.9324387009721704],
					[64.963269387647401, 6.932449799881951],
					[64.963180599806222, 6.9324498007585769],
					[64.963180594396945, 6.9324497990576832],
					[64.963180581843744, 6.9324498000267392],
					[64.963180575567122, 6.9324498005112698],
					[64.963089016000936, 6.9324608850981377],
					[64.962989078655042, 6.9322610118219608],
					[64.962989042172765, 6.932260970151261],
					[64.962988953449639, 6.9322609155661814],
					[64.962988900282824, 6.9322609006048328],
					[64.962900000211434, 6.932260900164029],
					[64.96289999483146, 6.9322609005793749],
					[64.962899985864894, 6.9322609012716123],
					[64.962899981381582, 6.9322609016177346],
					[64.962811080560542, 6.9322692012183396],
					[64.962811079663865, 6.9322692012875642],
					[64.962811076973878, 6.9322692014952354],
					[64.962811076077244, 6.9322692015644591],
					[64.962719525940713, 6.9322803833142306],
					[64.962630783598769, 6.9320693218352556],
					[64.962630741648439, 6.9320692742323207],
					[64.962630637594089, 6.9320692144770399],
					[64.962630576357313, 6.9320692001393249],
					[64.962538879271563, 6.9320804001885623],
					[64.962449991011141, 6.9320886992867461],
					[64.962361228270765, 6.9320886993760169],
					[64.962269582420163, 6.9318888175479829],
					[64.962269539573185, 6.9318887700151732],
					[64.962269435548038, 6.9318887123775133],
					[64.962269375266573, 6.9318887022033859],
					[64.962180723959634, 6.9318997840358287],
					[64.962089083065294, 6.9316888204901161],
					[64.962089041114865, 6.9316887728887258],
					[64.962088937060344, 6.9316887131360989],
					[64.962088874956208, 6.9316887009848847],
					[64.962000125810761, 6.9316997824769429],
					[64.961911284336409, 6.93148882300863],
					[64.961911242385909, 6.9314887754078667],
					[64.961911138301971, 6.9314887135403174],
					[64.961911076197822, 6.93148870138962],
					[64.96181937595064, 6.9314998016493643],
					[64.961819375053992, 6.9314998017186094],
					[64.961730719290912, 6.9315108849724707],
					[64.961630781008921, 6.9312999146329188],
					[64.961630739087738, 6.9312998691490018],
					[64.961630635988413, 6.9312998135617532],
					[64.961630575677546, 6.9312998012731279],
					[64.961538879029433, 6.9313109017698071],
					[64.96144999077795, 6.9313192009386499],
					[64.96136952377951, 6.9313192004369046],
					[64.961269579084714, 6.9311193107246538],
					[64.96126953719282, 6.9311192673576585],
					[64.961269435019403, 6.9311192138186106],
					[64.961269374708579, 6.9311192015305396],
					[64.961180586806648, 6.9311303997898763],
					[64.961088899580744, 6.9311304000890877],
					[64.961088895097447, 6.9311304004353689],
					[64.961088886130881, 6.9311304011279278],
					[64.961088880750935, 6.9311304015434647],
					[64.960999980849252, 6.9311387020467716],
					[64.960999979923258, 6.9311387000000133],
					[64.960999977233271, 6.9311387002077813],
					[64.960999974572672, 6.9311387025315572],
					[64.960911223637311, 6.9311497833561972],
					[64.960819583322973, 6.9309388194825967],
					[64.960819541401662, 6.9309387740006052],
					[64.960819437346856, 6.9309387142536441],
					[64.96081937524265, 6.9309387021046964],
					[64.960730587735014, 6.9309497990425024],
					[64.960638899609066, 6.9309498000030763],
					[64.960638894229106, 6.9309498004186416],
					[64.960638880779243, 6.9309498014575519],
					[64.960638875399297, 6.9309498018731155],
					[64.960550121785332, 6.9309608833615508],
					[64.960461283120793, 6.9307610200349927],
					[64.960461245712068, 6.9307609763235156],
					[64.960461154210378, 6.9307609156082925],
					[64.960461100146716, 6.9307609007205189],
					[64.960369400304359, 6.9307608989805276],
					[64.960369394924413, 6.9307608993961249],
					[64.960369385957847, 6.930760900088786],
					[64.960369380577887, 6.9307609005043833],
					[64.960280581205438, 6.930769200560392],
					[64.960280580308776, 6.9307692006296575],
					[64.960280576722141, 6.9307692009067203],
					[64.960280575825479, 6.9307692009759867],
					[64.960188887728336, 6.9307803994304544],
					[64.96010013029688, 6.9307803990804437],
					[64.960011283033964, 6.9305805191521541],
					[64.960011242009188, 6.9305804736028058],
					[64.960011142437494, 6.9305804135126348],
					[64.960011082126627, 6.9305804012263064],
					[64.959919390618879, 6.9305887006504019],
					[64.95983059995126, 6.9305886992515608],
					[64.959830593674639, 6.9305886997364539],
					[64.959830582018085, 6.9305887006369655],
					[64.959830575741478, 6.9305887011218577],
					[64.959739023374908, 6.9305997835857047],
					[64.959650183225293, 6.9303999190754304],
					[64.959650145816497, 6.9303998753657607],
					[64.95965005434401, 6.9303998167694889],
					[64.95965000025096, 6.9303997997669633],
					[64.959561100072548, 6.9303997989671196],
					[64.959561093795926, 6.9303997994520508],
					[64.959561082139388, 6.9303998003526344],
					[64.95956107586278, 6.9303998008375656],
					[64.959469516335417, 6.9304108843738765],
					[64.959369579419402, 6.9302110114332205],
					[64.959369542039909, 6.9302109698401493],
					[64.959369453316015, 6.9302109152690425],
					[64.959369400148987, 6.9302109003136669],
					[64.959288899874991, 6.9302108992509472],
					[64.959288895391694, 6.9302108995973546],
					[64.959288886425099, 6.9302109002901666],
					[64.959288881045168, 6.9302109007058528],
					[64.959200120105109, 6.9302191886306614],
					[64.95910017988939, 6.9300110134368511],
					[64.959100138893831, 6.9300109700057622],
					[64.959100040247947, 6.9300109119662894],
					[64.959099980863002, 6.9300109017281839],
					[64.959011080951839, 6.9300192009858117],
					[64.959011080055177, 6.9300192010550994],
					[64.959011077365219, 6.9300192012629571],
					[64.95901107557188, 6.9300192014015343],
					[64.958919526386907, 6.9300303826149916],
					[64.958830784020137, 6.9298193221174635],
					[64.958830742069125, 6.9298192745247125],
					[64.95883063801385, 6.9298192147864128],
					[64.958830575880171, 6.9298192005250261],
					[64.958738878849559, 6.9298304001165905],
					[64.958650126864953, 6.9298386862919426],
					[64.958561284352612, 6.9296305211001403],
					[64.958561243327566, 6.9296304755546769],
					[64.958561141962178, 6.9296304156098003],
					[64.958561081621795, 6.9296304012103898],
					[64.958469382059278, 6.9296387018596315],
					[64.958469380265939, 6.9296387019982282],
					[64.958469376649944, 6.9296387001596091],
					[64.958469374886008, 6.9296387024140147],
					[64.958380723633113, 6.9296497835570463],
					[64.958289082685241, 6.9294388190992944],
					[64.958289040763503, 6.9294387736238852],
					[64.958288936708058, 6.9294387138882376],
					[64.958288874603724, 6.9294387017437984],
					[64.958199974991388, 6.9294498026605771],
					[64.958111224104613, 6.9294608835313447],
					[64.958019582865461, 6.9292499197147075],
					[64.958019540914279, 6.9292498721242506],
					[64.958019436888165, 6.929249814505714],
					[64.958019374783831, 6.9292498023618219],
					[64.957930579255034, 6.9292608999700862],
					[64.957838891365469, 6.9292692007507775],
					[64.957750123971351, 6.9292691996904772],
					[64.957650178809203, 6.9290693103843806],
					[64.957650142355405, 6.9290692708419677],
					[64.957650052705091, 6.9290692142316219],
					[64.957650000434583, 6.9290691992101019],
					[64.957561100217589, 6.9290692005973051],
					[64.957561093911607, 6.9290691989667641],
					[64.957561082284442, 6.9290692019835838],
					[64.957561075978461, 6.9290692003530436],
					[64.95746937899554, 6.9290804019063419],
					[64.957380724838984, 6.9290886867780204],
					[64.957289083220985, 6.9288805193361442],
					[64.95728904219574, 6.9288804737939618],
					[64.957288940830054, 6.9288804138546913],
					[64.957288881415664, 6.9288804015040206],
					[64.957199980630563, 6.9288887018025695],
					[64.957199979733929, 6.9288887018718865],
					[64.957199977014568, 6.9288886999641344],
					[64.957199975250631, 6.9288887022184813],
					[64.957111088165519, 6.9288997992453565],
					[64.957019530063405, 6.9288997990761514],
					[64.956930783156636, 6.9286999191039333],
					[64.956930741205269, 6.9286998715162458],
					[64.956930637178914, 6.928699813902119],
					[64.956930575971199, 6.9286998016906294],
					[64.956838888331944, 6.9287108997071707],
					[64.956749999736545, 6.9287108992207562],
					[64.956749995253247, 6.9287108995673741],
					[64.956749986286695, 6.9287109002606071],
					[64.956749980906764, 6.9287109006765455],
					[64.956661222665318, 6.928719188202189],
					[64.956569581673989, 6.9285193170889352],
					[64.956569545161287, 6.9285192733175256],
					[64.956569453717435, 6.9285192168498853],
					[64.956569399624172, 6.928519199853028],
					[64.95648059988487, 6.9285191994708235],
					[64.956480593608262, 6.928519199956126],
					[64.956480581951737, 6.9285192008574006],
					[64.956480575675116, 6.9285192013427057],
					[64.956388878666502, 6.9285304000320274],
					[64.956300126709081, 6.92853868753821],
					[64.956211283894859, 6.9283305214005564],
					[64.956211242869401, 6.9283304758610242],
					[64.956211142400178, 6.9283304158568431],
					[64.956211082059681, 6.9283304014615545],
					[64.956119381597972, 6.9283387015326534],
					[64.956119379804647, 6.9283387016713229],
					[64.956119377114675, 6.9283387018793245],
					[64.956119375321364, 6.9283387020179923],
					[64.956030587886204, 6.9283497990114373],
					[64.955939029777241, 6.9283498006591433],
					[64.95585018325464, 6.9281499191067182],
					[64.955850141303102, 6.9281498715218035],
					[64.955850037276534, 6.9281498139120492],
					[64.955849975172114, 6.9281498017716263],
					[64.955761088132462, 6.9281609006138893],
					[64.955669399830768, 6.9281609002519318],
					[64.955669395347471, 6.9281609005986242],
					[64.955669386380876, 6.9281609012920091],
					[64.955669381000945, 6.9281609017080372],
					[64.955580719680114, 6.9281691874029363],
					[64.955480779782917, 6.9279610136657981],
					[64.955480739683438, 6.9279609701742819],
					[64.955480640140081, 6.9279609122193104],
					[64.955480581651628, 6.9279609019176736],
					[64.95538888207237, 6.927969200799466],
					[64.95538888027906, 6.9279692009381533],
					[64.955388876692453, 6.9279692012155278],
					[64.955388874899143, 6.9279692013542151],
					[64.955299977593782, 6.9279804009113475],
					[64.955211224733077, 6.9279886877947119],
					[64.955119583116897, 6.9277805195737763],
					[64.955119542091282, 6.9277804740368971],
					[64.95511944072517, 6.9277804141065289],
					[64.955119381310681, 6.927780401759196],
					[64.955030590918923, 6.9277887006135952],
					[64.954938899893179, 6.9277886995754381],
					[64.954938893616585, 6.9277887000608773],
					[64.954938881063399, 6.9277887010317549],
					[64.954938874786791, 6.9277887015171951],
					[64.954850122147164, 6.9277997822640787],
					[64.954761283092992, 6.9275999184463348],
					[64.954761245712746, 6.9275998768639644],
					[64.954761154209763, 6.9275998161721617],
					[64.954761100116428, 6.9275997991782265],
					[64.954669530285145, 6.9275997999855834],
					[64.954580783146341, 6.9273999183348991],
					[64.954580741223964, 6.9273998728688833],
					[64.954580637167723, 6.927399813149191],
					[64.954580575959881, 6.9273998009416715],
					[64.954489026368037, 6.9274108831520049],
					[64.95440018421516, 6.9271999221854355],
					[64.954400142263339, 6.92719987460453],
					[64.954400038206998, 6.9271998148860066],
					[64.954399975205831, 6.9271998028177517],
					[64.954311218993112, 6.9272108834232125],
					[64.954211280936505, 6.9269999146841279],
					[64.954211239014015, 6.9269998692193582],
					[64.954211135913027, 6.9269998136636115],
					[64.954211075601847, 6.9269998013877663],
					[64.954119523349121, 6.9270108849980563],
					[64.954030782753691, 6.9268110194045933],
					[64.954030746240491, 6.9268109756396079],
					[64.954030654766612, 6.9268109170675531],
					[64.954030599776488, 6.9268109001450497],
					[64.953938899753282, 6.9268109009728649],
					[64.953938895240626, 6.9268108992042841],
					[64.953938886303447, 6.9268109020135347],
					[64.953938880894114, 6.9268109003143339],
					[64.953850123580864, 6.9268191888040995],
					[64.953761282998883, 6.9266193187373073],
					[64.95376124558895, 6.9266192750424036],
					[64.953761154114972, 6.926619216471579],
					[64.953761100021509, 6.9266191994802258],
					[64.95366953013, 6.9266191993494886],
					[64.95358078337415, 6.9264193197701038],
					[64.95358074596416, 6.9264192760757695],
					[64.953580655357399, 6.9264192153212001],
					[64.953580600396663, 6.9264192005151681],
					[64.953489029579018, 6.92641919901134],
					[64.953400182883371, 6.9262193185607588],
					[64.953400140931237, 6.9262192709831627],
					[64.953400036903929, 6.9262192133860196],
					[64.953399974799325, 6.9262192012510893],
					[64.953311219113218, 6.926230382011326],
					[64.953211280852486, 6.9260193145520788],
					[64.953211238929697, 6.926019269090518],
					[64.953211135828397, 6.9260192135405534],
					[64.953211076413766, 6.9260192011979367],
					[64.953119522865052, 6.926030383086113],
					[64.953030782652746, 6.9258305195626555],
					[64.953030746139262, 6.9258304758006188],
					[64.953030654635583, 6.9258304151186705],
					[64.95303059967479, 6.9258304003141316],
					[64.952938899591331, 6.9258304002081772],
					[64.952938895108034, 6.925830400555216],
					[64.952938886141496, 6.9258304012492919],
					[64.952938880761565, 6.9258304016657384],
					[64.952850126998797, 6.9258386864127486],
					[64.952761283602626, 6.9256305217694383],
					[64.952761242546941, 6.9256304741244703],
					[64.952761142076625, 6.9256304141379381],
					[64.952761081765289, 6.9256304018657886],
					[64.952669524756317, 6.925638688221821],
					[64.952580782984484, 6.9254388188273888],
					[64.952580745574252, 6.9254387751360316],
					[64.952580654996481, 6.9254387165023568],
					[64.952580600006229, 6.9254386995835144],
					[64.952489023777758, 6.9254386996731849],
					[64.952389079120152, 6.9252388102768068],
					[64.952389037226567, 6.9252387669331679],
					[64.952388935051033, 6.9252387134337638],
					[64.952388874739711, 6.9252387011626482],
					[64.952300125781591, 6.925249782530023],
					[64.952211283884822, 6.9250388223393573],
					[64.952211241932304, 6.925038774765695],
					[64.952211137875139, 6.9250387150603068],
					[64.952211075741019, 6.9250387008132934],
					[64.952119526230334, 6.9250497836232903],
					[64.952030783642641, 6.924838822034717],
					[64.952030741690066, 6.9248387744616826],
					[64.952030637632788, 6.9248387147574659],
					[64.95203057639533, 6.9248387004415468],
					[64.95193902331259, 6.924849784127133],
					[64.951850182617363, 6.9246499197622118],
					[64.951850146103538, 6.9246498760036905],
					[64.951850053702742, 6.924649815397828],
					[64.951849999638483, 6.9246498005269013],
					[64.951761228430797, 6.9246497999020766],
					[64.951669582126797, 6.9244499176409162],
					[64.951669540174095, 6.9244498700691057],
					[64.951669436146133, 6.9244498124824654],
					[64.951669374967494, 6.924449802398148],
					[64.951580587657958, 6.9244608996016197],
					[64.951489023951737, 6.9244608998868156],
					[64.951389078754346, 6.9242610109077907],
					[64.951389042269838, 6.9242609692657888],
					[64.951388952647164, 6.9242609147996745],
					[64.951388900376216, 6.9242608997908386],
					[64.951300130063885, 6.9242609003588012],
					[64.95121128288902, 6.9240610198366177],
					[64.951211241832837, 6.924060972196779],
					[64.951211142287974, 6.9240609142652811],
					[64.951211081976552, 6.9240609019973132],
					[64.95111952678603, 6.9240691887750456],
					[64.95103078389829, 6.9238610227024191],
					[64.95103074284205, 6.9238609750631843],
					[64.951030642371052, 6.9238609150870776],
					[64.951030582030114, 6.9238609007044234],
					[64.950939026850506, 6.923869188098899],
					[64.950850184011927, 6.9236610212204486],
					[64.950850142985118, 6.9236609756970351],
					[64.950850041617358, 6.9236609157915279],
					[64.950849981276406, 6.9236609014094332],
					[64.950761217678988, 6.9236691869942284],
					[64.950661278669017, 6.9234693100415905],
					[64.950661242213741, 6.9234692705169714],
					[64.950661152590783, 6.9234692160549454],
					[64.95066110029029, 6.9234691989328532],
					[64.950569530277704, 6.9234692009238028],
					[64.950480783350741, 6.9232693184840119],
					[64.950480741397683, 6.923269270916153],
					[64.950480637369253, 6.9232692133365612],
					[64.95048057616107, 6.9232692011401902],
					[64.950389026280732, 6.9232803823825932],
					[64.950300184446874, 6.9230693227892832],
					[64.950300141597097, 6.9230692752915264],
					[64.950300036613029, 6.9230692135522656],
					[64.950299975404846, 6.9230692013564257],
					[64.950211078246852, 6.9230803999409591],
					[64.950119526665574, 6.9230886873068007],
					[64.950030784077697, 6.9228805214989784],
					[64.950030743050604, 6.9228804759781379],
					[64.950030641682545, 6.9228804160773656],
					[64.950030582238185, 6.9228804016279408],
					[64.949939027051769, 6.9228886877732903],
					[64.949850183616391, 6.9226805212327278],
					[64.949850142589284, 6.9226804757125127],
					[64.949850042117774, 6.9226804157433897],
					[64.949849980880089, 6.9226804014334844],
					[64.949761218201999, 6.9226886877145244],
					[64.949661278648591, 6.9224888111884111],
					[64.949661242163586, 6.9224887695514745],
					[64.949661153436949, 6.9224887150251977],
					[64.949661100269097, 6.9224887000902466],
					[64.949569530166727, 6.9224886990275403],
					[64.949480782725885, 6.9222888187272735],
					[64.949480740772529, 6.9222887711626822],
					[64.949480636743715, 6.9222887135890039],
					[64.949480576432137, 6.922288701325753],
					[64.949389026104683, 6.9222997838470191],
					[64.949300184114236, 6.9220888220632828],
					[64.949300142160794, 6.9220887744993229],
					[64.949300038102436, 6.9220887148117232],
					[64.949299975100857, 6.9220887027575619],
					[64.949211224429234, 6.9220997826639241],
					[64.949119583327246, 6.9218888197569157],
					[64.949119541373761, 6.9218887721936042],
					[64.949119437344791, 6.9218887146222157],
					[64.949119375239874, 6.9218887024990892],
					[64.949030716159442, 6.9218997843510941],
					[64.948930779092763, 6.9216999116767957],
					[64.948930741710925, 6.9216998701116061],
					[64.948930652983961, 6.9216998155893741],
					[64.948930599816066, 6.9216998006563877],
					[64.94883902968391, 6.9216998001313135],
					[64.948750183231056, 6.9214999185263526],
					[64.948750141277444, 6.9214998709642979],
					[64.948750037248345, 6.9214998133951884],
					[64.9487499751434, 6.9214998012731161],
					[64.948661088311596, 6.9215108997852717],
					[64.948569529834742, 6.9215108989858951],
					[64.948480782750764, 6.9213110186312967],
					[64.94848074626546, 6.9213109769977752],
					[64.948480654759962, 6.921310916341322],
					[64.948480599769212, 6.9213108994334247],
					[64.948389029635905, 6.9213109002107922],
					[64.948300182611973, 6.9211110190037655],
					[64.948300141584369, 6.9211109734885552],
					[64.948300041112219, 6.9211109135286941],
					[64.948299980800542, 6.921110901268591],
					[64.948211225320847, 6.9211191873926738],
					[64.948119583076192, 6.9209110198704469],
					[64.948119542048545, 6.9209109743558432],
					[64.948119440679662, 6.9209109144666838],
					[64.948119381235117, 6.9209109000225881],
					[64.948030720032946, 6.9209191878991874],
					[64.947930779734818, 6.9207110119719406],
					[64.947930739662738, 6.9207109706183303],
					[64.947930640116596, 6.9207109127061974],
					[64.9479305815982, 6.9207109003080216],
					[64.947839024676242, 6.9207191889995494],
					[64.947750183236636, 6.920519319695063],
					[64.947750145824941, 6.920519276018358],
					[64.947750054319144, 6.9205192153662223],
					[64.947750000254501, 6.9205192005057468],
					[64.947661227968766, 6.9205192007095402],
					[64.947569581845855, 6.9203193159818932],
					[64.947569539921361, 6.9203192705386947],
					[64.947569435891793, 6.9203192129766506],
					[64.947569374712884, 6.9203192029031495],
					[64.947480724246134, 6.9203303838317796],
					[64.947389082586383, 6.9201193197048019],
					[64.947389040632331, 6.9201192721472928],
					[64.947388936602707, 6.920119214586383],
					[64.947388875394282, 6.9201192023984763],
					[64.947300119019189, 6.9201303836536461],
					[64.947200181124856, 6.9199193133591654],
					[64.94720013833313, 6.9199192701017038],
					[64.947200036096703, 6.9199192124028102],
					[64.94719997491778, 6.9199192023303455],
					[64.947111221247269, 6.919930383949275],
					[64.947019581697688, 6.9197305174496586],
					[64.947019545182442, 6.9197304737056387],
					[64.947019453705806, 6.9197304151727064],
					[64.947019399641064, 6.9197304003142026],
					[64.946930727839259, 6.9197303995243908],
					[64.946839082380265, 6.9195305164317613],
					[64.946839041352192, 6.91953047092142],
					[64.946838940908975, 6.9195304130855462],
					[64.946838880597127, 6.9195304008296352],
					[64.946750126937658, 6.9195386872822962],
					[64.946661283778525, 6.9193305201357092],
					[64.946661242750409, 6.9193304746259701],
					[64.946661142277563, 6.9193304146763559],
					[64.946661081936242, 6.919330400306082],
					[64.946569519620965, 6.9193386873180591],
					[64.94646957963424, 6.9191305135616714],
					[64.94646953863554, 6.9191304701674134],
					[64.946469439985464, 6.91913041219461],
					[64.94646938059978, 6.9191304019849715],
					[64.94638072299243, 6.9191386885227368],
					[64.94628908222117, 6.9189388174619895],
					[64.946289044809049, 6.9189387737898427],
					[64.946288954228748, 6.9189387151915529],
					[64.946288900163921, 6.9189387003350289],
					[64.946200129582508, 6.9189386992065307],
					[64.946111282690424, 6.9187388185705494],
					[64.946111240735931, 6.9187387710174422],
					[64.946111136705767, 6.9187387134645588],
					[64.946111076393933, 6.9187387012107298],
					[64.946019526176329, 6.9187497836884191],
					[64.945930783738575, 6.9185388223258633],
					[64.945930741784039, 6.9185387747733866],
					[64.945930637694758, 6.918538712992043],
					[64.945930575589571, 6.9185387008779804],
					[64.945839026283267, 6.9185497838837025],
					[64.945750183890866, 6.9183388217537232],
					[64.945750141936259, 6.9183387742018567],
					[64.945750037876437, 6.9183387145364854],
					[64.945749974874559, 6.9183387024926173],
					[64.945661216257506, 6.9183497831947482],
					[64.945561279356326, 6.9181499102583937],
					[64.945561242003038, 6.9181498708180715],
					[64.945561153245251, 6.9181498141995181],
					[64.945561100077029, 6.9181497992753203],
					[64.945469529795133, 6.9181498001065842],
					[64.945380783183822, 6.9179499196386365],
					[64.945380741229101, 6.9179498720880446],
					[64.945380637198639, 6.9179498145397469],
					[64.945380575960527, 6.9179498002428668],
					[64.945289025770833, 6.9179608829544392],
					[64.945200184292617, 6.9177499227181132],
					[64.945200184263086, 6.9177499206033648],
					[64.94520018336641, 6.9177499206730211],
					[64.945200183336894, 6.9177499185582718],
					[64.945111282801818, 6.9175499199596695],
					[64.945111246285933, 6.9175498762216989],
					[64.945111153882266, 6.9175498156552173],
					[64.945111099817325, 6.9175498008021066],
					[64.945019524097489, 6.9175497998450313],
					[64.94491957864156, 6.9173499119473725],
					[64.944919536716185, 6.9173498665132529],
					[64.944919435434542, 6.9173498129886974],
					[64.944919375152068, 6.9173498028532237],
					[64.944830724265429, 6.9173608823075696],
					[64.944739083058224, 6.9171499207320117],
					[64.944739041103247, 6.9171498731837984],
					[64.944738937042928, 6.9171498135252341],
					[64.944738874937642, 6.9171498014149009],
					[64.944650126241925, 6.9171608826956339],
					[64.94456128442674, 6.9169499215921544],
					[64.94456124157503, 6.916949874114243],
					[64.944561138411331, 6.9169498143871619],
					[64.944561076276429, 6.9169498001626533],
					[64.944469523441455, 6.9169608839956611],
					[64.944380783389178, 6.9167610184913659],
					[64.944380746005947, 6.916760976940016],
					[64.944380655395278, 6.9167609162384762],
					[64.944380600404074, 6.9167608993423393],
					[64.944289023757761, 6.9167608989924751],
					[64.944189078935906, 6.9165610108553857],
					[64.944189037936482, 6.9165609674686994],
					[64.944188939344542, 6.916560913739489],
					[64.944188880825749, 6.9165609013519713],
					[64.944100127181244, 6.9165691860905563],
					[64.94401128403949, 6.9163610214411655],
					[64.944011243010436, 6.9163609759404192],
					[64.944011141639791, 6.9163609160774033],
					[64.94401108219482, 6.9163609016454419],
					[64.943919527097307, 6.9163691888066845],
					[64.943830784164717, 6.9161610226393746],
					[64.943830743106105, 6.9161609750245958],
					[64.943830641735374, 6.916160915162731],
					[64.943830582290374, 6.9161609007313025],
					[64.943739025410494, 6.91616918864844],
					[64.943650182725946, 6.9159693200321071],
					[64.943650146209606, 6.9159692762986458],
					[64.9436500538053, 6.9159692157408186],
					[64.943649999740231, 6.9159692008916558],
					[64.943561228114689, 6.9159691989431131],
					[64.94346958167246, 6.9157693174194721],
					[64.943469539746602, 6.9157692719902517],
					[64.943469435685742, 6.9157692123397965],
					[64.943469375403211, 6.9157692022083666],
					[64.943380718818389, 6.9157803840356582],
					[64.943280781384885, 6.9155693135873104],
					[64.943280738591881, 6.9155692703430329],
					[64.943280636353833, 6.9155692126689132],
					[64.943280576041687, 6.9155692004234073],
					[64.9431890264309, 6.9155803833822693],
					[64.943100184375069, 6.9153693223863755],
					[64.943100184345511, 6.9153693202717958],
					[64.943100183448877, 6.9153693203415223],
					[64.943100183419304, 6.9153693182269427],
					[64.94301128300134, 6.9151693195823887],
					[64.94301124104571, 6.9151692720403037],
					[64.94301113701421, 6.915169214507741],
					[64.943011075775829, 6.9151692002183882],
					[64.942919526166136, 6.9151803822999049],
					[64.942830784035749, 6.9149693211635492],
					[64.942830742109663, 6.9149692757366479],
					[64.942830638018918, 6.9149692139761054],
					[64.942830575913405, 6.9149692018716173],
					[64.942739019115749, 6.9149803829933232],
					[64.942639081374267, 6.9147693141432791],
					[64.942639037684387, 6.9147692709710444],
					[64.942638936342689, 6.9147692132315939],
					[64.942638875133838, 6.9147692010578821],
					[64.942550121637552, 6.91478038275703],
					[64.942461283277652, 6.9145805186227642],
					[64.94246124586428, 6.9145804749628637],
					[64.942461154385654, 6.9145804164572215],
					[64.942461100290828, 6.9145803994969555],
					[64.942369529832831, 6.9145803996509683],
					[64.942280783416138, 6.9143805197622106],
					[64.942280742356942, 6.9143804721528808],
					[64.942280641911722, 6.9143804143458771],
					[64.942280581569889, 6.9143803999889526],
					[64.94218902741892, 6.9143886889766533],
					[64.942100183614357, 6.9141805207493565],
					[64.942100142584678, 6.9141804752551499],
					[64.942100042109814, 6.9141804153347737],
					[64.942099980871305, 6.9141804010481538],
					[64.942011220095736, 6.9141886871155718],
					[64.941911280330359, 6.9139805121729454],
					[64.941911240256445, 6.913980470838557],
					[64.941911139811111, 6.9139804130338245],
					[64.941911082188781, 6.9139804005831609],
					[64.941819525340037, 6.9139886888953805],
					[64.941730782684971, 6.9137888189274115],
					[64.941639082573019, 6.9135805186101349],
					[64.941639041543141, 6.9135804731176211],
					[64.941638941068078, 6.9135804132004965],
					[64.941638880755747, 6.9135804009601554],
					[64.941550127157583, 6.9135886861719484],
					[64.941461283986428, 6.9133805211696719],
					[64.941461283060164, 6.9133805191250151],
					[64.941461280281445, 6.9133805129910355],
					[64.941461279355195, 6.9133805109463573],
					[64.941361278652522, 6.9131805100994272],
					[64.941361237681704, 6.9131804688368712],
					[64.941361139955589, 6.9131804129413492],
					[64.941361082333202, 6.9131804004926387],
					[64.941269527264481, 6.9131886869652162],
					[64.941180784026486, 6.9129805218714235],
					[64.941180742966864, 6.9129804742661687],
					[64.941180641594883, 6.9129804144220941],
					[64.941180582149585, 6.9129803999990482],
					[64.941089025298481, 6.9129886872276298],
					[64.941000183379558, 6.9127888188035618],
					[64.941000145965688, 6.9127887751485142],
					[64.941000053589704, 6.9127887167219271],
					[64.941000000391369, 6.9127886996963435],
					[64.940911227753176, 6.9127886997400116],
					[64.940819582973006, 6.9125888186678468],
					[64.940730784023202, 6.9123805214960736],
					[64.940730742993011, 6.9123804760069163],
					[64.940730641591173, 6.9123804140517011],
					[64.940730582175419, 6.9123804017445991],
					[64.94063901815548, 6.9123886893001254],
					[64.940539078772574, 6.912188811911995],
					[64.94053904228474, 6.9121887703030769],
					[64.940538952657718, 6.9121887158988082],
					[64.940538900385548, 6.9121887009192431],
					[64.940450130408607, 6.9121887005993443],
					[64.940361282653555, 6.9119888187752929],
					[64.940361240696916, 6.911988771242811],
					[64.940361136664237, 6.9119887137281362],
					[64.940361076351792, 6.9119887014918904],
					[64.940269526336508, 6.9119997831523046],
					[64.940180784334714, 6.9117888228205082],
					[64.940180742378061, 6.9117887752886524],
					[64.940180638286023, 6.911788713546426],
					[64.940180576180268, 6.9117887014503978],
					[64.940089026179649, 6.9117997837082452],
					[64.940000184223067, 6.9115888226338154],
					[64.940000183267159, 6.9115888184749812],
					[64.940000179591706, 6.911588812411364],
					[64.940000178665429, 6.9115888103668803],
					[64.939900178974199, 6.9113888119668969],
					[64.939900137047033, 6.9113887665504796],
					[64.939900034866625, 6.9113887131280416],
					[64.939899974583795, 6.9113887030076775],
					[64.939811224254285, 6.9113997821863915],
					[64.939719582876762, 6.9111888204231438],
					[64.939719540919896, 6.9111887728930546],
					[64.93971943685726, 6.9111887132684977],
					[64.939719374751448, 6.9111887011740327],
					[64.939630724112789, 6.9111997837472341],
					[64.939539083293994, 6.9109888197832401],
					[64.939539041337085, 6.9109887722537806],
					[64.939538937303993, 6.9109887147446836],
					[64.93953887519821, 6.9109887026507515],
					[64.939450122208001, 6.9109997831927847],
					[64.939361282834895, 6.9107999183062798],
					[64.939361246346706, 6.9107998767010761],
					[64.939361153940339, 6.9107998161705311],
					[64.939361099845073, 6.9107997992197499],
					[64.939269523833076, 6.910799799836453],
					[64.939169582026452, 6.910599915269203],
					[64.939080783962709, 6.9103888221348635],
					[64.939080742005586, 6.9103887746071262],
					[64.939080637942638, 6.9103887149870582],
					[64.939080575807125, 6.910388700780473],
					[64.938989023161838, 6.9103997832784581],
					[64.938900182936635, 6.9101999195751853],
					[64.938900146418632, 6.9101998758572449],
					[64.938900054012038, 6.9101998153297846],
					[64.938899999946386, 6.9101998004947305],
					[64.938811228088966, 6.9101998004629852],
					[64.938719582193812, 6.9099999161320564],
					[64.938719540266192, 6.9099998707197887],
					[64.938719436232759, 6.9099998132162552],
					[64.938719375023524, 6.9099998010550623],
					[64.938630719033569, 6.9100108832781482],
					[64.938530781320551, 6.9097999132785075],
					[64.938530737599621, 6.9097998680066812],
					[64.938530633595718, 6.9097998126185294],
					[64.938530573312775, 6.9097998025021834],
					[64.938450115321444, 6.9098108811864654],
					[64.938350182220162, 6.9096110161844022],
					[64.938261283878816, 6.9093999214140149],
					[64.938261241951054, 6.9093998760034694],
					[64.938261137858049, 6.9093998142748232],
					[64.938261075722465, 6.9093998000709336],
					[64.938169525810736, 6.9094108832332886],
					[64.938080784326957, 6.9091999222114318],
					[64.938080742369479, 6.9091998746873067],
					[64.938080638276404, 6.9091998129598418],
					[64.938080576170449, 6.9091998008706783],
					[64.937989022686764, 6.9092108849100669],
					[64.937900183403656, 6.9090110196733727],
					[64.937900145988635, 6.909010976028588],
					[64.937900053581586, 6.9090109155074275],
					[64.937900000412426, 6.9090109006053932],
					[64.937811224013288, 6.9090109005737812],
					[64.937711281370269, 6.9088110143227395],
					[64.937619582990678, 6.9085999190612748],
					[64.937619541062688, 6.9085998736530536],
					[64.937619436999043, 6.9085998140430878],
					[64.937619374893046, 6.908599801955484],
					[64.937530720741549, 6.9086108839200433],
					[64.937439082669883, 6.9084110179828251],
					[64.93735018426888, 6.9081999215140666],
					[64.937350183342545, 6.9081999194699],
					[64.937350179666979, 6.9081999134073424],
					[64.937350178740672, 6.9081999113631536],
					[64.937250182224304, 6.9079999150426419],
					[64.937161284144523, 6.9077888213526313],
					[64.937161284144523, 6.9077888213526313],
					[64.937161282291868, 6.9077888172643531],
					[64.937161282291868, 6.9077888172643531],
					[64.937069581934381, 6.9075888177455944],
					[64.93706958100806, 6.9075888157014473],
					[64.937069580978374, 6.9075888135873544],
					[64.937069580081712, 6.9075888136573234],
					[64.936969580113981, 6.907380512905978],
					[64.936969539111956, 6.9073804695448606],
					[64.936969438693765, 6.9073804138893129],
					[64.936969379277613, 6.9073804015948994],
					[64.936889016822448, 6.9073886878390294],
					[64.93678908192544, 6.9071888158287607],
					[64.936700184234766, 6.9069805219572586],
					[64.936700183308417, 6.9069805199131631],
					[64.936611283284336, 6.9067805195460314],
					[64.936611282357987, 6.9067805175019705],
					[64.936611281431681, 6.9067805154579061],
					[64.93661128140198, 6.9067805133438496],
					[64.936511280831269, 6.9065693154574141],
					[64.936419582355569, 6.9063693183229447],
					[64.936330782901564, 6.9061693183888631],
					[64.936239083028283, 6.905961019484514],
					[64.936239082101935, 6.9059610174404895],
					[64.936239080249237, 6.905961013352476],
					[64.936239079322917, 6.9059610113084693],
					[64.936139082322441, 6.9057610165926882],
					[64.936050183562273, 6.9055499219373635],
					[64.936050141603658, 6.9055498744225305],
					[64.936050038435468, 6.9055498147577721],
					[64.93604997543261, 6.9055498027479114],
					[64.935961224414058, 6.9055608825902874],
					[64.935869583138924, 6.9053499195402104],
					[64.935869583138924, 6.9053499195402104],
					[64.935780783112051, 6.9051499185485019],
					[64.935780782185716, 6.9051499165045565],
					[64.935780780332976, 6.9051499124166726],
					[64.935780779406642, 6.9051499103727272],
					[64.935680781243008, 6.9049499157456218],
					[64.935589083323649, 6.9047388195542698],
					[64.935589083323649, 6.9047388195542698],
					[64.935500182810216, 6.9045388198827471],
					[64.93541128382364, 6.9043305213302091],
					[64.935411282897263, 6.9043305192863036],
					[64.935411280118188, 6.9043305131546413],
					[64.935411279191797, 6.9043305111107545],
					[64.935311280749687, 6.9041305158769442],
					[64.935219582887001, 6.9039193194856017],
					[64.935219582887001, 6.9039193194856017],
					[64.935130782637572, 6.9037193194769806],
					[64.935130740678389, 6.9037192719666258],
					[64.935130636642526, 6.9037192144949326],
					[64.935130576299755, 6.9037192001655407],
					[64.935039026196009, 6.9037303838171908],
					[64.934950184071639, 6.9035193210492913],
					[64.934950183145247, 6.9035193190054693],
					[64.93495018036613, 6.9035193128739962],
					[64.934950179439738, 6.9035193108301538],
					[64.934850181627553, 6.9033193155659083],
					[64.934761283588301, 6.9031110216570521],
					[64.934761243422201, 6.9031109740080572],
					[64.934761142046341, 6.9031109142152784],
					[64.9347610817332, 6.9031109020013499],
					[64.934669525008346, 6.9031191888436956],
					[64.934580782899616, 6.9029193200327201],
					[64.934489082638081, 6.9027110179734477],
					[64.934489081741418, 6.9027110180435702],
					[64.93448907988865, 6.9027110139560488],
					[64.934489078962272, 6.9027110119122854],
					[64.934389079004674, 6.9025110104753544],
					[64.934389041647307, 6.9025109710730668],
					[64.934388952883708, 6.9025109145292989],
					[64.934388899713966, 6.9025108996419675],
					[64.934300130217522, 6.9025108992251427],
					[64.934211282965776, 6.902311020055218],
					[64.934211282936033, 6.902311017941356],
					[64.934211282039371, 6.9023110180114937],
					[64.934119581900006, 6.9021110162606609],
					[64.93411954540943, 6.9021109746753089],
					[64.934119453926002, 6.9021109162299874],
					[64.934119399829783, 6.9021108992998803],
					[64.934030728187651, 6.9021109010016035],
					[64.933939082382025, 6.9019110176254657],
					[64.933939082352282, 6.9019110155116268],
					[64.933939081455648, 6.9019110155817733],
					[64.933939081425905, 6.9019110134679327],
					[64.933839081154105, 6.9016999134193684],
					[64.933839038357121, 6.9016998702122532],
					[64.933838936113801, 6.9016998126107794],
					[64.93383887493367, 6.9016998025844103],
					[64.933749978000364, 6.9017109013290687],
					[64.933661091011274, 6.901719199552792],
					[64.93356939969334, 6.9017191996551217],
					[64.93356939431348, 6.9017192000760366],
					[64.93356938086383, 6.901719201128321],
					[64.933569374587293, 6.9017192016193896],
					[64.933480577797496, 6.9017304002603952],
					[64.933388882138146, 6.9017387001025989],
					[64.933388878551568, 6.9017387003832038],
					[64.933388874098085, 6.901738702847755],
					[64.933388872304803, 6.9017387029880561],
					[64.933311086228926, 6.9017497997238273],
					[64.933219400286475, 6.9017497992801866],
					[64.933219394009981, 6.9017497997712374],
					[64.933219380560288, 6.9017498008234917],
					[64.933219375180428, 6.9017498012443932],
					[64.933130578778332, 6.9017609001303359],
					[64.933038891182363, 6.9017691991082035],
					[64.93294999958583, 6.901769200040782],
					[64.932949994205984, 6.9017692004616737],
					[64.932949980756291, 6.9017692015139067],
					[64.932949975376417, 6.9017692019347994],
					[64.932861078061094, 6.9017804016320161],
					[64.932769391357127, 6.9017887003739586],
					[64.932680600270785, 6.9017886997113962],
					[64.932680593994306, 6.9017887002024247],
					[64.932680582337895, 6.9017887011143406],
					[64.932680576061401, 6.9017887016053692],
					[64.932588875819889, 6.9017998008635475],
					[64.932588874953026, 6.9017998030474237],
					[64.932499977995519, 6.9018109008727624],
					[64.932411091014345, 6.901819200421393],
					[64.932319399680836, 6.9018192001087959],
					[64.932319394300961, 6.9018192005296655],
					[64.932319380851311, 6.9018192015818407],
					[64.932319374574774, 6.9018192020728568],
					[64.932230577790477, 6.9018304019040766],
					[64.93213889107524, 6.9018387002432515],
					[64.932050000364498, 6.9018387008243192],
					[64.932049994058247, 6.9018386992016332],
					[64.932049980608554, 6.901838700253788],
					[64.932049975258451, 6.9018387027883374],
					[64.931961075600995, 6.9018498004417461],
					[64.931869378961352, 6.9018609009844951],
					[64.931780590719114, 6.9018692008757645],
					[64.931688900274366, 6.9018692002852386],
					[64.931688893997844, 6.9018692007762272],
					[64.931688881444842, 6.9018692017582044],
					[64.931688875168319, 6.9018692022491948],
					[64.931599977828611, 6.901880401017161],
					[64.931511090831833, 6.9018887000061095],
					[64.931419400383774, 6.9018886993331998],
					[64.931419394107252, 6.9018886998241777],
					[64.931419380657587, 6.9018887008762757],
					[64.931419375277699, 6.901888701297116],
					[64.931330576181082, 6.9018998012008739],
					[64.931238875942483, 6.9019109015555484],
					[64.931238825071759, 6.9019109224624922],
					[64.931238743413573, 6.9019109880968603],
					[64.931238710832773, 6.9019110329645859],
					[64.931160958922092, 6.9021304006773239],
					[64.931069400422999, 6.9021303994167384],
					[64.931069344112885, 6.9021304165166679],
					[64.931069250768402, 6.9021304809480357],
					[64.931069214571238, 6.9021305239820947],
					[64.930980459001375, 6.9023498149526885],
					[64.930888876121074, 6.9023609001911561],
					[64.93088882704356, 6.9023609209567001],
					[64.9308887471189, 6.9023609822213903],
					[64.930888715404905, 6.9023610249042315],
					[64.930799864646559, 6.902580399337741],
					[64.930711099621973, 6.9025804002508506],
					[64.930711043311774, 6.902580417349597],
					[64.930710949100202, 6.90258048396222],
					[64.930710912065734, 6.902580531292398],
					[64.930630454404451, 6.9027998164545155],
					[64.930538875994372, 6.9028109017553714],
					[64.930538826887059, 6.9028109204062567],
					[64.930538746991971, 6.9028109837823619],
					[64.930538715248062, 6.9028110243505436],
					[64.930449864796017, 6.9030304006938596],
					[64.930361099762891, 6.9030304000982641],
					[64.930361045245959, 6.9030304170556311],
					[64.930360951841621, 6.9030304772550117],
					[64.930360914777268, 6.9030305224703676],
					[64.93026925954635, 6.9032498153968529],
					[64.930180575090773, 6.9032609012847113],
					[64.930180525116512, 6.9032609221181271],
					[64.93018044435432, 6.9032609876756643],
					[64.930180411743393, 6.9032610304264184],
					[64.930099860067742, 6.9034803989883722],
					[64.930011099568802, 6.9034804007607713],
					[64.930011095055818, 6.9034803989976536],
					[64.930011086119094, 6.9034804018120068],
					[64.930011081606153, 6.9034804000488856],
					[64.929919382319682, 6.9034887013697599],
					[64.929919379629737, 6.903488701580005],
					[64.929919376939822, 6.9034887017902475],
					[64.929919375146511, 6.9034887019304119],
					[64.929830575955123, 6.9034998000619687],
					[64.929738888203474, 6.903510899601045],
					[64.929650000227767, 6.9035109006359212],
					[64.929649994847864, 6.9035109010564026],
					[64.929649985881426, 6.9035109017572021],
					[64.929649981368442, 6.9035108999941004],
					[64.929561080879978, 6.9035192002606145],
					[64.92956107998333, 6.9035192003306936],
					[64.9295610772934, 6.9035192005409307],
					[64.929561076396737, 6.9035192006110124],
					[64.929469388243973, 6.9035303994308581],
					[64.929380599911696, 6.9035304010610794],
					[64.929380595398712, 6.9035303992979911],
					[64.929380586432288, 6.9035303999987745],
					[64.929380581949033, 6.903530400349168],
					[64.929288881754673, 6.9035387013373395],
					[64.929288879961376, 6.9035387014774958],
					[64.929288877271475, 6.9035387016877268],
					[64.929288874581516, 6.903538701897963],
					[64.929199988314139, 6.9035498000246944],
					[64.929111100331951, 6.903549800900139],
					[64.929111094025714, 6.9035497992772159],
					[64.929111082369303, 6.9035498001882178],
					[64.929111076092781, 6.9035498006787579],
					[64.929019379361094, 6.9035609004080856],
					[64.928930581198202, 6.9035692007432994],
					[64.92893058030154, 6.9035692008133758],
					[64.928930576714947, 6.9035692010936787],
					[64.928930575818342, 6.9035692011637524],
					[64.928838887653157, 6.9035803995127942],
					[64.928749999666593, 6.9035804002663923],
					[64.928749995183352, 6.9035804006167663],
					[64.9287499862169, 6.9035804013175142],
					[64.928749980837011, 6.9035804017379645],
					[64.928661081229578, 6.9035887013747397],
					[64.928661080332944, 6.9035887014448125],
					[64.92866107674638, 6.9035887017251083],
					[64.928661075849703, 6.9035887017951847],
					[64.928569388075374, 6.9035998004784105],
					[64.92848059970251, 6.9035997997145682],
					[64.928480594322636, 6.9035998001350087],
					[64.928480581769577, 6.9035998011160373],
					[64.928480576389717, 6.9035998015364743],
					[64.92838887874899, 6.9036109008673714],
					[64.928299990791544, 6.9036191993600404],
					[64.928211100108555, 6.9036192001647674],
					[64.928211092935385, 6.9036192007253421],
					[64.928211078589044, 6.9036192018464915],
					[64.928211072312507, 6.9036192023369942],
					[64.928130576723603, 6.9036304013974359],
					[64.928038881886778, 6.9036387011637164],
					[64.928038880093467, 6.9036387013038594],
					[64.928038877403566, 6.9036387015140699],
					[64.928038874713593, 6.9036387017242831],
					[64.927949988422071, 6.9036497989320091],
					[64.927861100424778, 6.9036497994053105],
					[64.927861094148241, 6.9036497998957991],
					[64.927861081595225, 6.9036498008767726],
					[64.927861076215365, 6.9036498012971901],
					[64.927769515689803, 6.9036608852175281],
					[64.927669579255493, 6.9034610117610731],
					[64.927669541868624, 6.9034609702538008],
					[64.927669453135096, 6.9034609158340094],
					[64.927669399965382, 6.903460900948315],
					[64.927580599897638, 6.9034608993908133],
					[64.927580595414426, 6.9034608997411864],
					[64.927580585551311, 6.9034609005120133],
					[64.927580581964705, 6.9034609007923153],
					[64.927488881752325, 6.9034692013558594],
					[64.927488879959029, 6.9034692014960077],
					[64.92748887726907, 6.9034692017062333],
					[64.927488874579154, 6.9034692019164554],
					[64.927399987002502, 6.903480398963544],
					[64.927319400312712, 6.9034804007161581],
					[64.927319394903108, 6.9034803990232794],
					[64.927319385966371, 6.9034804018373368],
					[64.927319380556767, 6.9034804001444581],
					[64.927230580585061, 6.9034887002640453],
					[64.927230579688441, 6.9034887003341172],
					[64.927230576998497, 6.9034887005443375],
					[64.927230576101863, 6.9034887006144086],
					[64.927138888317558, 6.9034997989639164],
					[64.927050000312647, 6.9034997998881575],
					[64.927049994036125, 6.9034998003786612],
					[64.927049980586432, 6.9034998014297422],
					[64.927049975206572, 6.9034998018501748],
					[64.926961079081011, 6.9035109019369179],
					[64.926869382444082, 6.9035192018214939],
					[64.926869379754137, 6.9035192020317062],
					[64.92686937703445, 6.9035192001286374],
					[64.926869375270897, 6.9035192023820624],
					[64.926780587328864, 6.9035303996452795],
					[64.926688899587646, 6.9035303992155708],
					[64.926688895104448, 6.9035303995659172],
					[64.926688886137981, 6.9035304002666171],
					[64.926688880758093, 6.9035304006870373],
					[64.926599981158489, 6.9035387018483956],
					[64.926599980261813, 6.9035387019184666],
					[64.926599976645505, 6.9035387000854813],
					[64.926599974881952, 6.9035387023388814],
					[64.92651108768419, 6.9035497992712038],
					[64.92641939996939, 6.9035498008727076],
					[64.926419393663124, 6.9035497992499373],
					[64.926419381110065, 6.9035498002308966],
					[64.926419374863301, 6.9035498028346218],
					[64.926330720820133, 6.9035608835408846],
					[64.926239081934554, 6.9033610178565779],
					[64.92623904541459, 6.9033609741683151],
					[64.926238953931588, 6.9033609157354308],
					[64.926238899865226, 6.903360900920755],
					[64.926150000135721, 6.9033608989385682],
					[64.926149994755846, 6.9033608993590105],
					[64.926149985789408, 6.9033609000597478],
					[64.926149981306168, 6.9033609004101182],
					[64.926061080814463, 6.9033692020056927],
					[64.9260610798881, 6.9033691999625439],
					[64.926061077198156, 6.903369200172766],
					[64.926061076301536, 6.9033692002428371],
					[64.925969388146143, 6.9033804000230008],
					[64.925880599746179, 6.9033803998380554],
					[64.925880595262981, 6.9033804001884151],
					[64.925880586296486, 6.9033804008891408],
					[64.92588058181326, 6.9033804012395024],
					[64.925788881584793, 6.9033887014528084],
					[64.925788879791511, 6.9033887015929523],
					[64.925788877101553, 6.9033887018031681],
					[64.925788875308271, 6.9033887019433111],
					[64.925700126137116, 6.9033997839735939],
					[64.925611284055563, 6.90318882270837],
					[64.925611242096195, 6.9031887752154484],
					[64.925611138000605, 6.9031887135392047],
					[64.925611075894139, 6.9031887014690678],
					[64.925519375561393, 6.9031998010598548],
					[64.925519374664731, 6.9031998011299338],
					[64.925430579058983, 6.9032108999812047],
					[64.925338891389117, 6.9032191996270367],
					[64.925249999750463, 6.9032191995458438],
					[64.925249994370589, 6.9032191999662986],
					[64.925249980920853, 6.9032192010174374],
					[64.925249974644373, 6.9032192015079641],
					[64.925161078124191, 6.9032304011356533],
					[64.925069526710161, 6.9032386878512968],
					[64.924980783827522, 6.9030305230314477],
					[64.924980742764788, 6.9030304754697909],
					[64.924980642285448, 6.9030304156283808],
					[64.924980581942549, 6.9030304013055899],
					[64.924888881696162, 6.9030387002704634],
					[64.924888879902866, 6.9030387004106242],
					[64.924888877212936, 6.9030387006208649],
					[64.924888875449383, 6.9030387028741531],
					[64.924799988272454, 6.903049800657481],
					[64.924711100216044, 6.9030498008265635],
					[64.924711093909764, 6.903049799204009],
					[64.924711082253381, 6.9030498001150455],
					[64.924711075976845, 6.9030498006056034],
					[64.924619379230876, 6.90306089996567],
					[64.924530590910265, 6.9030691995740474],
					[64.924439024313955, 6.9030691995804672],
					[64.924339079359243, 6.9028693112337862],
					[64.924339037459262, 6.9028692679697512],
					[64.924338935246055, 6.9028692124966708],
					[64.924338874962601, 6.9028692024007281],
					[64.924249987397559, 6.9028803999415898],
					[64.924169399724988, 6.9028803991778958],
					[64.924169395241762, 6.902880399528315],
					[64.92416938627531, 6.9028804002291499],
					[64.924169380895435, 6.9028804006496509],
					[64.924080580924766, 6.9028887016014853],
					[64.924080580028161, 6.9028887016715661],
					[64.924080577338202, 6.9028887018818166],
					[64.924080576441568, 6.9028887019518983],
					[64.923988887770946, 6.902899800933846],
					[64.923899999677161, 6.9028997994407426],
					[64.923899994297287, 6.9028997998612347],
					[64.923899980847594, 6.9028998009124667],
					[64.923899974571071, 6.9028998014030432],
					[64.923811079353442, 6.9029109019250443],
					[64.923719381790207, 6.902919200657875],
					[64.923719379996911, 6.9029192007980358],
					[64.923719377306952, 6.90291920100828],
					[64.92371937461705, 6.9029192012185216],
					[64.92363058668667, 6.9029303989734663],
					[64.923538899811774, 6.9029304003395442],
					[64.923538895328548, 6.902930400689943],
					[64.923538886362081, 6.9029304013907433],
					[64.923538880982207, 6.9029304018112221],
					[64.923449981353869, 6.9029387016936905],
					[64.923449980457207, 6.9029387017637722],
					[64.923449976870614, 6.9029387020440884],
					[64.923449975077332, 6.9029387021842465],
					[64.923361087890854, 6.9029497996220881],
					[64.923269400115984, 6.9029498009761934],
					[64.923269393809719, 6.9029497993537419],
					[64.923269381256645, 6.9029498003348424],
					[64.923269375009895, 6.9029498029383891],
					[64.923180579397069, 6.9029609015585986],
					[64.92308889078906, 6.9029691991880355],
					[64.923000000024203, 6.9029691997373321],
					[64.922999993747666, 6.9029692002278704],
					[64.922999981194607, 6.9029692012089487],
					[64.922999974918113, 6.9029692016994852],
					[64.922911078390996, 6.9029804010885005],
					[64.922819381738194, 6.902988701295885],
					[64.922819379944897, 6.9029887014360378],
					[64.922819377254982, 6.9029887016462625],
					[64.922819374565037, 6.9029887018564908],
					[64.922730587909754, 6.9029997994357419],
					[64.922638900127026, 6.9029998005821396],
					[64.922638843816728, 6.902999817674571],
					[64.922638751338638, 6.902999879899153],
					[64.922638715170862, 6.9029999250313026],
					[64.922549859370406, 6.9032192149497185],
					[64.922461078424263, 6.903230400033129],
					[64.922369390745459, 6.9032387006334019],
					[64.922288900007132, 6.9032386995818529],
					[64.92228889373061, 6.9032387000723121],
					[64.922288881177579, 6.9032387010532306],
					[64.922288874901042, 6.9032387015436925],
					[64.922199975180973, 6.9032498026491638],
					[64.922111075429129, 6.9032609015631614],
					[64.922111028114983, 6.9032609200669759],
					[64.92211094905673, 6.9032609791255108],
					[64.922110917312665, 6.9032610196802482],
					[64.922019263338612, 6.90346921498156],
					[64.921930578424607, 6.903480399942687],
					[64.92183888175613, 6.9034887001946146],
					[64.921838879962834, 6.9034887003347265],
					[64.921838877272904, 6.9034887005448926],
					[64.921838874612689, 6.903488702867949],
					[64.921749988285768, 6.9034997997703078],
					[64.921661100220092, 6.9034998003890529],
					[64.921661044806342, 6.9034998174097248],
					[64.921660952298282, 6.9034998775173904],
					[64.92166091523373, 6.9034999227172529],
					[64.921569260027766, 6.9037192164075361],
					[64.921480586743243, 6.9037303994193229],
					[64.921388899864681, 6.9037304000395432],
					[64.921388895381426, 6.9037304003897777],
					[64.921388886414974, 6.9037304010902396],
					[64.921388881035071, 6.9037304015105194],
					[64.92129998135745, 6.9037387000700354],
					[64.921299929589722, 6.9037387210354799],
					[64.921299845240583, 6.9037387868496172],
					[64.921299811762495, 6.9037388317683552],
					[64.92121925520064, 6.9039609139550437],
					[64.921130580653383, 6.9039692004482873],
					[64.921130530649165, 6.9039692191602446],
					[64.921130448033793, 6.9039692806074919],
					[64.921130415422581, 6.903969323342765],
					[64.921038767026189, 6.9041886993250952],
					[64.920950000171203, 6.9041887008449576],
					[64.920949993864937, 6.904188699222348],
					[64.920949981311864, 6.9041887002027647],
					[64.920949975065042, 6.9041887028057927],
					[64.920861075277443, 6.9041998022490239],
					[64.920861025273254, 6.904199820960371],
					[64.920860943613818, 6.9041998865618748],
					[64.920860911928884, 6.9041999313392184],
					[64.920780459676067, 6.9044191998664237],
					[64.920688900284333, 6.9044192008265819],
					[64.920688893978081, 6.9044191992039305],
					[64.92068888142505, 6.9044192001842255],
					[64.920688875178215, 6.9044192027871736],
					[64.920599975000897, 6.9044304028986971],
					[64.920599926789919, 6.9044304214693675],
					[64.920599845967587, 6.9044304827739165],
					[64.920599815149529, 6.9044305253677347],
					[64.920510959275376, 6.9046498169129844],
					[64.920419376213502, 6.9046608999348695],
					[64.920419327135576, 6.9046609206878022],
					[64.920419247239508, 6.9046609840340016],
					[64.920419214598368, 6.9046610246545157],
					[64.920330464620861, 6.9048804008410531],
					[64.920238899839191, 6.9048804006216953],
					[64.920238895355936, 6.9048804009717184],
					[64.920238886389484, 6.9048804016717602],
					[64.920238880979852, 6.9048803999790245],
					[64.920149981301549, 6.904888700648276],
					[64.920149930430298, 6.9048887215405861],
					[64.920149845183943, 6.9048887874182405],
					[64.9201498117055, 6.9048888323335822],
					[64.920069259654781, 6.9051109010339431],
					[64.919980599599384, 6.9051108995705679],
					[64.919980595116158, 6.9051108999205457],
					[64.919980586149663, 6.9051109006205049],
					[64.919980581666465, 6.905110900970481],
					[64.91988888226463, 6.9051192014849381],
					[64.91988883136365, 6.9051192202638623],
					[64.919888747851061, 6.905119281774649],
					[64.91988871523948, 6.9051193245065488],
					[64.919799858937068, 6.9053387157583757],
					[64.919711074705276, 6.9053498018251123],
					[64.919711027390832, 6.9053498203235026],
					[64.919710946568102, 6.9053498816232137],
					[64.919710914823426, 6.9053499221718155],
					[64.919619266756328, 6.9055691991143302],
					[64.919538899973276, 6.9055692002905467],
					[64.919538843662579, 6.9055692173755103],
					[64.919538751183367, 6.9055692795838866],
					[64.919538715014752, 6.9055693247073044],
					[64.919449858988528, 6.9057887157556674],
					[64.919361074743989, 6.9057998022828686],
					[64.919361027429488, 6.9057998207802349],
					[64.919360946606588, 6.9057998820777557],
					[64.919360914861755, 6.9057999226253148],
					[64.919269214964558, 6.9060193239397085],
					[64.919269208955257, 6.9060193434436936],
					[64.919269202405602, 6.9060193883699306],
					[64.919269200042308, 6.9060194118194387],
				],
			],
		},
		{
			fldNpdidField: "1630514",
			wlbNpdidWellbore: "1024",
			fldName: "MIKKEL",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6407/6-3",
			fldDiscoveryYear: "1987",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=1630514",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=1630514",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "MIKKEL UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=1024",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "1629477",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-02-17 00:00:00",
			fldMainSupplyBase: "Kristiansund",
			fldAreaGeometryWKT: [
				[
					[64.610672000019136, 7.6672639022494957],
					[64.610694200246741, 7.6697250026909733],
					[64.610694204995411, 7.6697250317254797],
					[64.610694222547366, 7.6697250873104359],
					[64.610694236247681, 7.6697251138170497],
					[64.611358136439677, 7.6706779130081983],
					[64.611358185256137, 7.670677952434688],
					[64.611358295090469, 7.6706779909719316],
					[64.611358357024102, 7.6706779921284785],
					[64.611961072274568, 7.6705002178851149],
					[64.612433197089373, 7.6707834729496112],
					[64.612433254650625, 7.6707834868635461],
					[64.612433367640591, 7.670783477164731],
					[64.61243342400391, 7.6707834576875191],
					[64.612861160113198, 7.6704474064419434],
					[64.613341679392846, 7.6704973986297311],
					[64.613341681186952, 7.6704973985420981],
					[64.613341682981016, 7.6704973984544651],
					[64.613341684775108, 7.6704973983668321],
					[64.613952783943844, 7.6705445996581449],
					[64.614533283073357, 7.6705918988373005],
					[64.615241679952859, 7.670652999589108],
					[64.616630577334973, 7.6708112986236756],
					[64.616630585408359, 7.6708112982293635],
					[64.616630600676928, 7.6708112995745523],
					[64.616630608750313, 7.6708112991802393],
					[64.616813895858499, 7.670802999552234],
					[64.617036083029774, 7.670822399397438],
					[64.617036086617944, 7.6708223992221862],
					[64.617036095607205, 7.6708224008740897],
					[64.617036100092406, 7.670822400655025],
					[64.617041666897762, 7.6708224005880421],
					[64.617049935853714, 7.6708251896111399],
					[64.617049946655825, 7.6708251932654559],
					[64.617049970016552, 7.6708251963064118],
					[64.617049981715752, 7.6708251999169121],
					[64.617263871147088, 7.6708445976218824],
					[64.617277760843038, 7.6708473965229329],
					[64.617277766225286, 7.6708473962600561],
					[64.6172777779245, 7.6708473998705964],
					[64.617277784203779, 7.6708473995639084],
					[64.617486083442486, 7.6708640993176687],
					[64.617516681644958, 7.670866899769222],
					[64.617516683439021, 7.6708668996815979],
					[64.617516685233127, 7.6708668995939719],
					[64.617516686130188, 7.6708668995501581],
					[64.617713878601194, 7.6708807985201899],
					[64.617752771878216, 7.6708862990242483],
					[64.61775277456934, 7.6708862988928121],
					[64.617752781745708, 7.670886298542313],
					[64.617752785333877, 7.6708862983670629],
					[64.617936084576058, 7.6709001986412968],
					[64.617936086388951, 7.6709002006437776],
					[64.617936088183058, 7.6709002005561517],
					[64.617936089080089, 7.6709002005123397],
					[64.617988887183074, 7.6709029999404397],
					[64.618163883306352, 7.6709168982054372],
					[64.618224981883259, 7.6709223995811575],
					[64.618224982780276, 7.6709223995373454],
					[64.618224984574397, 7.6709223994497213],
					[64.618224986368475, 7.6709223993620963],
					[64.618386082924928, 7.6709334992821061],
					[64.618469379672575, 7.6709419000481809],
					[64.618469382363685, 7.6709418999167465],
					[64.618469385951897, 7.6709418997414982],
					[64.618469387745961, 7.670941899653875],
					[64.618605588432416, 7.6709502008245707],
					[64.61860558932942, 7.6709502007807595],
					[64.618705588201877, 7.6709557993927842],
					[64.618836084756651, 7.6709640990766541],
					[64.618933282785477, 7.6709723982627356],
					[64.618933283682523, 7.6709723982189235],
					[64.618933284579555, 7.6709723981751115],
					[64.618933286392434, 7.6709724001776678],
					[64.619055585744547, 7.670980799231085],
					[64.619177782788285, 7.6709890987945375],
					[64.619286080390665, 7.6710001997719761],
					[64.619286082184757, 7.6710001996843511],
					[64.619286084875867, 7.6710001995529185],
					[64.619286086669973, 7.6710001994652943],
					[64.619416678337117, 7.6710084990449356],
					[64.619505568897168, 7.6710223986852339],
					[64.61950557158832, 7.6710223985537995],
					[64.619505577867614, 7.6710223982471222],
					[64.619505580558737, 7.6710223981156895],
					[64.619652776727563, 7.6710362983871088],
					[64.619736072593753, 7.6710473974594979],
					[64.619897168440801, 7.6710695986323261],
					[64.619958264391556, 7.6710807971620332],
					[64.619958266185677, 7.6710807970744117],
					[64.619958269792633, 7.6710807989894496],
					[64.619958273380803, 7.6710807988142102],
					[64.620138865178518, 7.6711057977186767],
					[64.620177757577565, 7.6711140970606673],
					[64.620399946892121, 7.6711612936729532],
					[64.620408235649023, 7.6711640905294765],
					[64.620408240134211, 7.6711640903104348],
					[64.620408248226397, 7.6711640920064541],
					[64.62040825093635, 7.6711640939653405],
					[64.62047494789266, 7.6711807932322706],
					[64.62062774370159, 7.6712251917222458],
					[64.620627749102653, 7.6712251935497155],
					[64.620627758988931, 7.6712251951581507],
					[64.620627764390022, 7.6712251969856196],
					[64.620658254011914, 7.6712307952272223],
					[64.620858237277815, 7.6712890908771492],
					[64.620924925345889, 7.6713140850645152],
					[64.621077719691684, 7.6713807821628697],
					[64.621077722401637, 7.6713807841218067],
					[64.621077726008579, 7.6713807860369405],
					[64.621077727821444, 7.6713807880396816],
					[64.621199926364724, 7.671427985481591],
					[64.621308222655088, 7.6714723843398254],
					[64.621491620625989, 7.6715501849073586],
					[64.62153601893273, 7.6715695826765868],
					[64.621697113631569, 7.6716418815887115],
					[64.621758210702367, 7.6716723786404266],
					[64.62175821430931, 7.6716723805556466],
					[64.621758222420283, 7.6716723843423136],
					[64.621758225130236, 7.671672386301327],
					[64.621772121111263, 7.6716779839400484],
					[64.6219860168178, 7.6717751824214533],
					[64.622063816148895, 7.671811280980303],
					[64.622063817064699, 7.6718112830269343],
					[64.622063817961759, 7.6718112829831426],
					[64.622063818858777, 7.6718112829393545],
					[64.622208219178049, 7.6718751829705649],
					[64.62220822007508, 7.6718751829267759],
					[64.622208220990927, 7.6718751849734206],
					[64.622344320798973, 7.671933484443179],
					[64.622344322593051, 7.6719334843556055],
					[64.622344325302961, 7.6719334863146891],
					[64.622344327097039, 7.6719334862271156],
					[64.622436026796862, 7.6719695869020921],
					[64.622436028590954, 7.6719695868145195],
					[64.622436033992031, 7.671969588642253],
					[64.622436036701927, 7.6719695906013472],
					[64.622619335378047, 7.6720307885372847],
					[64.622658233155363, 7.6720445879123016],
					[64.622658235865259, 7.6720445898714189],
					[64.622658243060414, 7.6720445916116145],
					[64.622658245770324, 7.67204459357073],
					[64.622886045649125, 7.6721084934871229],
					[64.622886047443217, 7.6721084933995574],
					[64.622886048340277, 7.6721084933557755],
					[64.622886049237295, 7.6721084933119936],
					[64.623108249002101, 7.6721668931746221],
					[64.623108250796193, 7.6721668930870601],
					[64.623108256197256, 7.6721668949148771],
					[64.623108258010134, 7.6721668969178189],
					[64.623147155683753, 7.6721751941925573],
					[64.623336054072567, 7.6722195952150605],
					[64.623336058557797, 7.6722195949961636],
					[64.623336068444075, 7.6722195966051094],
					[64.623336072948064, 7.6722195984767323],
					[64.623397167820428, 7.6722279963406406],
					[64.623558263441723, 7.672258497788504],
					[64.623558265235815, 7.6722584977009465],
					[64.623558270618062, 7.6722584974382757],
					[64.623558274206246, 7.6722584972631616],
					[64.623641673126755, 7.6722695985959142],
					[64.623780572283835, 7.6722890971541746],
					[64.623780574077912, 7.6722890970666189],
					[64.623780576787851, 7.6722890990258561],
					[64.623780578581929, 7.6722890989383021],
					[64.623886075963526, 7.6723001991053685],
					[64.624008272899928, 7.6723168989833992],
					[64.624008275591052, 7.6723168988520678],
					[64.62400828187036, 7.6723168985456249],
					[64.624008284561498, 7.6723168984142918],
					[64.624122184600083, 7.6723251981227873],
					[64.624230584254718, 7.6723334986112075],
					[64.624366683313298, 7.6723445990443162],
					[64.624458282041687, 7.6723529980099086],
					[64.624458283854537, 7.6723530000129596],
					[64.624458284751597, 7.6723529999691813],
					[64.624602781997311, 7.6723640985853132],
					[64.624680579061831, 7.672372399441679],
					[64.624680580855909, 7.6723723993541268],
					[64.624680587135202, 7.6723723990476866],
					[64.624680588929294, 7.6723723989601336],
					[64.624838886936018, 7.6723807996357847],
					[64.624908283958462, 7.6723862999919534],
					[64.624908284855465, 7.6723862999481778],
					[64.624908285752539, 7.6723862999043995],
					[64.6249082866496, 7.672386299860622],
					[64.625074987376124, 7.6723974007772267],
					[64.625074988273198, 7.6723974007334474],
					[64.625074989170216, 7.6723974006896718],
					[64.62507499006729, 7.6723974006458935],
					[64.625130588972468, 7.6724002007365089],
					[64.625313885960892, 7.6724112992479654],
					[64.625349985366526, 7.6724140993124879],
					[64.6253499862636, 7.6724140992687087],
					[64.625349988057678, 7.6724140991811565],
					[64.625349988954696, 7.6724140991373808],
					[64.625549985439079, 7.6724251985849623],
					[64.625580581771388, 7.6724279995197344],
					[64.625580582668448, 7.6724279994759552],
					[64.625580583565466, 7.6724279994321805],
					[64.625786084073781, 7.6724445991629295],
					[64.62578608766195, 7.6724445989878225],
					[64.625786095754137, 7.672444600684539],
					[64.625786100239324, 7.6724446004656564],
					[64.625799991653622, 7.672444600235627],
					[64.626022185362004, 7.6724612996101111],
					[64.626022188950174, 7.6724612994350059],
					[64.626022196126542, 7.67246129908479],
					[64.626022199733484, 7.6724613010004097],
					[64.626030567424834, 7.672461300518945],
					[64.626038836342801, 7.6724640909319568],
					[64.626038848023128, 7.6724640924535885],
					[64.626038871402599, 7.6724640975875973],
					[64.626038883082941, 7.6724640991092281],
					[64.626136081790065, 7.6724723992535839],
					[64.626249980906877, 7.6724834988534578],
					[64.626249985392093, 7.6724834986345751],
					[64.626249995278357, 7.6724835002437777],
					[64.626249999763573, 7.6724835000248959],
					[64.626258287580285, 7.6724834992640778],
					[64.626480574688273, 7.6725112972820479],
					[64.626502771909387, 7.6725140983062357],
					[64.626699966724928, 7.6725445980348992],
					[64.626747164760104, 7.6725529980269966],
					[64.626747165657164, 7.6725529979832192],
					[64.626922162355527, 7.6725834958842913],
					[64.626999957646177, 7.6726001963485508],
					[64.626999959440269, 7.6726001962610031],
					[64.626999960337301, 7.6726001962172292],
					[64.626999961234318, 7.6726001961734571],
					[64.627152752643141, 7.6726307929962898],
					[64.627258241987434, 7.6726612907326288],
					[64.627372140354538, 7.6726973900663236],
					[64.627372142148644, 7.6726973899787803],
					[64.627372145774373, 7.672697393985354],
					[64.627372147568465, 7.6726973938978107],
					[64.627516645814708, 7.6727362927197387],
					[64.627602744208218, 7.6727612926704287],
					[64.627602745105264, 7.6727612926266575],
					[64.627602746899356, 7.6727612925391178],
					[64.627786044039937, 7.6728112909757593],
					[64.627822132681914, 7.6728223883303937],
					[64.628022124965739, 7.672902985557557],
					[64.628022126759831, 7.6729029854700252],
					[64.628022130366759, 7.6729029873858421],
					[64.628022132160851, 7.6729029872983086],
					[64.628052720397164, 7.6729140833835414],
					[64.628058209083221, 7.6729168779951635],
					[64.628058211793132, 7.6729168799547498],
					[64.628058217194152, 7.6729168817830393],
					[64.628058219007016, 7.6729168837863906],
					[64.628272118880815, 7.6730112834561082],
					[64.628272120674893, 7.6730112833685826],
					[64.628272124281878, 7.6730112852844279],
					[64.628272126075956, 7.6730112851969041],
					[64.628341625449664, 7.6730390857928974],
					[64.6285027190699, 7.6731057840330035],
					[64.628630514151297, 7.6731668800481998],
					[64.628630514151297, 7.6731668800481998],
					[64.628630515048357, 7.6731668800044401],
					[64.628730508411664, 7.6732140789235306],
					[64.628927702279057, 7.673325173249645],
					[64.628927703194861, 7.6733251752968421],
					[64.628952701293684, 7.6733390739467513],
					[64.629019297378022, 7.6733779707234628],
					[64.62918049498299, 7.673477970374047],
					[64.629180507597908, 7.6734779760344827],
					[64.629180534640554, 7.6734779893588074],
					[64.629180549030792, 7.6734779928407564],
					[64.629233222175188, 7.6734918850330738],
					[64.629402693909611, 7.6735918696266507],
					[64.629547090429583, 7.6736862669296828],
					[64.629630488212555, 7.6737418659472567],
					[64.629805467298397, 7.6738612512868114],
					[64.62985265064539, 7.673914033588769],
					[64.62985268495818, 7.6739140570190845],
					[64.629852758853445, 7.6739140910711532],
					[64.629852800211239, 7.6739140995144091],
					[64.629883232243245, 7.6739140989524222],
					[64.6300804780184, 7.6740668575649744],
					[64.630080487960925, 7.6740668654481023],
					[64.63008050962128, 7.6740668790358342],
					[64.630080521339153, 7.6740668847404558],
					[64.630202698862092, 7.6741195746310265],
					[64.630302679974491, 7.674194559629715],
					[64.630302680871537, 7.6741945595859873],
					[64.630302681787356, 7.6741945616333149],
					[64.630302681787356, 7.6741945616333149],
					[64.630516539748598, 7.6743501319379899],
					[64.630530418002849, 7.6743806826513934],
					[64.630530447961632, 7.6743807209390242],
					[64.630530522960143, 7.67438077795134],
					[64.630530567999855, 7.6743807966760063],
					[64.630547105831965, 7.6743834863348246],
					[64.630760961359343, 7.6745890439932509],
					[64.63076098395419, 7.6745890617202219],
					[64.630761032638318, 7.6745890865437989],
					[64.630761059643376, 7.6745890956878036],
					[64.630883212893949, 7.6746140857801048],
					[64.630980475232789, 7.6746918554268913],
					[64.630980476148565, 7.6746918574742873],
					[64.630980477045625, 7.6746918574305738],
					[64.631172044909022, 7.6748418332669859],
					[64.631210926295211, 7.6749111979701885],
					[64.631210966140088, 7.6749112378697317],
					[64.631211062611172, 7.6749112875625718],
					[64.63121111835909, 7.6749112994907014],
					[64.631241625864462, 7.6749085076527921],
					[64.631438760360467, 7.6751029414117156],
					[64.631438779348315, 7.6751029572232055],
					[64.631438819958987, 7.6751029824416106],
					[64.631438843375832, 7.6751029917611477],
					[64.631577706572642, 7.675144580940616],
					[64.631660976905906, 7.6752084584090907],
					[64.631819253264126, 7.6753334391615642],
					[64.631888735761265, 7.6754334147713594],
					[64.631888783641912, 7.6754334500970627],
					[64.631888894390556, 7.6754334907291879],
					[64.631888955427002, 7.6754334919406313],
					[64.631927754616058, 7.675422420013807],
					[64.632110975171202, 7.6755695552132357],
					[64.63211097879686, 7.6755695592209054],
					[64.632110987823523, 7.6755695650576321],
					[64.632110993243259, 7.6755695689779087],
					[64.632269285894807, 7.6756695647272624],
					[64.63233877885726, 7.6757223585084162],
					[64.632338779773065, 7.6757223605559668],
					[64.632338780670082, 7.6757223605122871],
					[64.632338780670082, 7.6757223605122871],
					[64.632477668790017, 7.675825152261794],
					[64.632569258580858, 7.6759168414294576],
					[64.632569284782463, 7.6759168610752235],
					[64.632569344268376, 7.6759168895611714],
					[64.632569374861518, 7.6759168985323809],
					[64.632613823757225, 7.6759223916557495],
					[64.632788781696803, 7.6760501609770104],
					[64.632788782612565, 7.6760501630246072],
					[64.63294988269142, 7.6761668609956342],
					[64.63301928313534, 7.6762168625066671],
					[64.633019284032386, 7.6762168624629998],
					[64.633155475087875, 7.6763140558379499],
					[64.633238766317518, 7.6763890490805027],
					[64.633238784389476, 7.6763890628462832],
					[64.633238823168384, 7.6763890839730049],
					[64.633238843875262, 7.6763890913339505],
					[64.633286008189117, 7.6764029810035979],
					[64.633469279343757, 7.6765418591171999],
					[64.633469280240817, 7.6765418590735415],
					[64.633469282053625, 7.6765418610775296],
					[64.633469282053625, 7.6765418610775296],
					[64.633622082358826, 7.6766529619302277],
					[64.633622085068666, 7.6766529638906027],
					[64.633622091385419, 7.6766529677676543],
					[64.633622093198227, 7.676652969771661],
					[64.633697090779833, 7.6767001676707478],
					[64.633849880289659, 7.6768029604498826],
					[64.633919271248388, 7.6768612530738158],
					[64.633919285694688, 7.6768612628321646],
					[64.633919316362679, 7.6768612801702112],
					[64.633919331687295, 7.6768612877935762],
					[64.633949908757984, 7.6768723807344497],
					[64.634147089006731, 7.6770029665015054],
					[64.634285985322663, 7.6770973622221863],
					[64.63436928188483, 7.6771584616156883],
					[64.634369282781861, 7.6771584615720512],
					[64.634369286407463, 7.6771584655802609],
					[64.634369288201555, 7.677158465492985],
					[64.634566584865823, 7.6772918637869498],
					[64.634597081779773, 7.6773140610333703],
					[64.634597095328999, 7.6773140708358332],
					[64.634597123268364, 7.6773140841229379],
					[64.634597136780201, 7.6773140897426204],
					[64.634605515994309, 7.6773168833898247],
					[64.63482769194087, 7.6774501681986731],
					[64.634933186945617, 7.6775223655073059],
					[64.634933188739694, 7.6775223654200522],
					[64.634933190552488, 7.6775223674242312],
					[64.634933193262341, 7.6775223693847643],
					[64.635047091816404, 7.677594568663328],
					[64.635252691085952, 7.6777279671879768],
					[64.635252693795806, 7.6777279691485729],
					[64.635252700112545, 7.677727973026105],
					[64.635252702822356, 7.6777279749866816],
					[64.63527769502484, 7.6777418706616611],
					[64.635330487274388, 7.6777779640370039],
					[64.635330488190121, 7.6777779660848307],
					[64.635330489984213, 7.6777779659975929],
					[64.635330490899975, 7.677777968045417],
					[64.635497090948832, 7.6778862676413455],
					[64.635497094555689, 7.6778862695583392],
					[64.635497103582253, 7.6778862753965731],
					[64.635497108086184, 7.6778862772699483],
					[64.635566603374684, 7.6779223751158439],
					[64.63572769884702, 7.6780168728157028],
					[64.635880498575233, 7.6781057739125309],
					[64.63594709770291, 7.6781445726225623],
					[64.63613879387627, 7.6782612695209727],
					[64.636177692320175, 7.6782862684070254],
					[64.636177707644705, 7.6782862760317947],
					[64.636177742779068, 7.6782862910633458],
					[64.63617776077605, 7.6782862964657896],
					[64.63619163135732, 7.6782890893764764],
					[64.636397102186407, 7.6784001743610597],
					[64.636497098552709, 7.678458472441176],
					[64.636497100346801, 7.6784584723539755],
					[64.636497102159581, 7.6784584743583117],
					[64.636497103056627, 7.6784584743147128],
					[64.636627703228868, 7.6785307758938011],
					[64.636627704125885, 7.6785307758502057],
					[64.636794304105521, 7.6786223746433357],
					[64.636794306815361, 7.6786223766041113],
					[64.636794314029089, 7.6786223804384734],
					[64.636794316738943, 7.6786223823992472],
					[64.636855509031605, 7.6786501784201082],
					[64.637016601346659, 7.678741874750874],
					[64.637016602243705, 7.6787418747072831],
					[64.637016603140765, 7.6787418746636904],
					[64.637016604056484, 7.678741876711678],
					[64.637077703723676, 7.6787751767953232],
					[64.637077706414829, 7.6787751766645531],
					[64.63707770912464, 7.6787751786253642],
					[64.637077710918717, 7.6787751785381833],
					[64.637099911223899, 7.6787862780741625],
					[64.637099912139618, 7.6787862801221758],
					[64.637099913036664, 7.6787862800785867],
					[64.637305507789549, 7.678886278205991],
					[64.637397104264977, 7.6789362769572866],
					[64.637397107853147, 7.6789362767829461],
					[64.637397115066889, 7.6789362806174912],
					[64.6373971177767, 7.6789362825783414],
					[64.637527716377221, 7.6789945814852789],
					[64.637688812568058, 7.6790723790472404],
					[64.637688814380866, 7.6790723810517241],
					[64.637688816174943, 7.6790723809645609],
					[64.637688817090677, 7.679072383012608],
					[64.637755515840041, 7.6791029810878833],
					[64.637969314678429, 7.6792029809031206],
					[64.637969320976453, 7.6792029826897448],
					[64.637969330899978, 7.679202988485339],
					[64.637969337197987, 7.6792029902719445],
					[64.637977722653787, 7.6792057845316872],
					[64.637999911207771, 7.6792168790705109],
					[64.637999913001849, 7.6792168789833575],
					[64.637999916627393, 7.6792168829923719],
					[64.637999919318545, 7.6792168828616383],
					[64.638205518555992, 7.6793084834512344],
					[64.638205520350112, 7.6793084833640837],
					[64.638205523956955, 7.6793084852814539],
					[64.638205524853987, 7.679308485237879],
					[64.638261024822626, 7.6793307851792969],
					[64.638261025719643, 7.6793307851357238],
					[64.638261028410781, 7.6793307850050008],
					[64.6382610293265, 7.6793307870531002],
					[64.638427728562974, 7.6793945869678328],
					[64.638427730357066, 7.6793945868806865],
					[64.638427733066848, 7.6793945888416566],
					[64.63842773486094, 7.6793945887545121],
					[64.638533234738745, 7.6794307890113256],
					[64.638533235654478, 7.6794307910594473],
					[64.638533238345602, 7.679430790928734],
					[64.638533240139694, 7.6794307908415922],
					[64.638655538048184, 7.6794695906208625],
					[64.638802735640752, 7.6795195885425578],
					[64.638802738350549, 7.6795195905035678],
					[64.638802741041673, 7.6795195903728608],
					[64.638802742854452, 7.679519592377436],
					[64.638877740392473, 7.6795418900260746],
					[64.639069336981791, 7.6796057892590479],
					[64.639069337878851, 7.679605789215481],
					[64.639069339691616, 7.6796057912200828],
					[64.639069340588676, 7.6796057911765159],
					[64.639105538076677, 7.6796168904054856],
					[64.639283235298421, 7.6796779897895968],
					[64.639283236195467, 7.6796779897460317],
					[64.639283238886577, 7.679677989615338],
					[64.639283239802324, 7.6796779916635245],
					[64.639327739323519, 7.679691891760096],
					[64.639336037756223, 7.6796945900834768],
					[64.63933604405419, 7.6796945918702839],
					[64.639336055753134, 7.6796945954874776],
					[64.639336062929488, 7.6796945951389635],
					[64.639555563391426, 7.6797362961885582],
					[64.63955556697961, 7.6797362960143056],
					[64.639555576884462, 7.6797362997186527],
					[64.639555582266738, 7.6797362994572715],
					[64.639586075113087, 7.6797390981662055],
					[64.639777769068644, 7.6797695982607745],
					[64.639830569168282, 7.6797779978290608],
					[64.640008263257386, 7.6798057964758941],
					[64.640083257400946, 7.6798223954491966],
					[64.640083259195023, 7.6798223953620735],
					[64.640083263698912, 7.6798223972360802],
					[64.64008326639005, 7.6798223971053936],
					[64.640227766345006, 7.6798473967215708],
					[64.640227769054817, 7.6798473986827123],
					[64.640227775334111, 7.6798473983777864],
					[64.640227778025249, 7.6798473982471016],
					[64.640327773091741, 7.6798584974745197],
					[64.640449967599849, 7.6798779989352237],
					[64.640449975673249, 7.6798779985431809],
					[64.640449991838722, 7.6798779998509339],
					[64.640449999912121, 7.6798779994588893],
					[64.640563890431338, 7.679878000077542],
					[64.640677781333707, 7.6798890986298964],
					[64.640677782230767, 7.6798890985863357],
					[64.640677784921877, 7.6798890984556554],
					[64.640677785837596, 7.6798891005039547],
					[64.640799986195574, 7.6798973994052586],
					[64.640799987092635, 7.6798973993616979],
					[64.640799987989666, 7.6798973993181372],
					[64.640799988886712, 7.6798973992745756],
					[64.640899987796772, 7.6799030009585803],
					[64.641036087598977, 7.6799113009949158],
					[64.641036089393069, 7.6799113009077944],
					[64.641036092084192, 7.6799113007771114],
					[64.641036093878284, 7.6799113006899917],
					[64.641127789835622, 7.6799140994797206],
					[64.641205586238897, 7.6799195990906792],
					[64.641205587135929, 7.6799195990471203],
					[64.641205589845754, 7.6799196010083373],
					[64.641205591639846, 7.6799196009212158],
					[64.641272191790677, 7.6799224003880244],
					[64.641272192687723, 7.6799224003444637],
					[64.641349992801182, 7.6799252000840097],
					[64.641424992923035, 7.6799279997717464],
					[64.641424995614173, 7.6799279996410643],
					[64.641425003687544, 7.6799279992490197],
					[64.641425007275743, 7.679927999074776],
					[64.641502806757472, 7.6799252005432042],
					[64.64157779994963, 7.6799224001415123],
					[64.641647192014517, 7.679925200100473],
					[64.641647195602715, 7.6799251999262257],
					[64.641647202779041, 7.6799251995777373],
					[64.641647207264285, 7.6799251993599302],
					[64.641730603118063, 7.6799223990017929],
					[64.641799996221295, 7.6799224007731999],
					[64.641869391872291, 7.6799252005745897],
					[64.641869394563457, 7.679925200443904],
					[64.641869402636857, 7.6799252000518488],
					[64.641869406225027, 7.6799251998776015],
					[64.641961103335888, 7.6799224002897031],
					[64.642022199662691, 7.679922401036559],
					[64.642083299558493, 7.6799223995156813],
					[64.642083301352585, 7.6799223994285581],
					[64.642083304043709, 7.6799223992978707],
					[64.642083304940741, 7.67992239925431],
					[64.642188905083287, 7.6799196004663015],
					[64.642188905980319, 7.679919600422739],
					[64.642188907774397, 7.6799196003356149],
					[64.642188908671457, 7.6799196002920516],
					[64.642250007792228, 7.679916898969358],
					[64.642411102604527, 7.6799113005053128],
					[64.642472199806008, 7.6799112990806133],
					[64.642472201600086, 7.6799112989934883],
					[64.642472205206928, 7.6799113009112308],
					[64.64247220700102, 7.679911300824104],
					[64.642638902666803, 7.6799058007148719],
					[64.642699999866352, 7.6799057992722339],
					[64.642700001660444, 7.6799057991851063],
					[64.642700005248614, 7.6799057990108519],
					[64.642700007061379, 7.6799058010157379],
					[64.642866702722159, 7.6799002003503878],
					[64.642922199958065, 7.6799002008598825],
					[64.642922201752171, 7.6799002007727513],
					[64.642922205340341, 7.6799002005984933],
					[64.642922207134418, 7.6799002005113639],
					[64.643088902789856, 7.6798945997510435],
					[64.643141699643195, 7.6798945995324841],
					[64.643141702334333, 7.6798945994017869],
					[64.643141705025457, 7.6798945992710914],
					[64.643141705922503, 7.6798945992275254],
					[64.643319403211208, 7.6798891003324767],
					[64.643372200062899, 7.6798891000983653],
					[64.643372200959931, 7.6798891000547984],
					[64.643372201856977, 7.6798891000112341],
					[64.643372202754037, 7.6798890999676663],
					[64.643547202224738, 7.6798863000965634],
					[64.643591699611179, 7.6798862989809011],
					[64.643591701423944, 7.6798863009858716],
					[64.643591704115053, 7.6798863008551734],
					[64.643591705909131, 7.6798863007680422],
					[64.643775003147695, 7.6798807997193084],
					[64.643822200016402, 7.6798807993437155],
					[64.643822200913448, 7.6798807993001486],
					[64.643822201810494, 7.6798807992565807],
					[64.643822202707526, 7.6798807992130156],
					[64.644005594063429, 7.6798779997746962],
					[64.644041685075706, 7.6798807992486244],
					[64.644041687766858, 7.679880799117921],
					[64.644041695858931, 7.6798808008179327],
					[64.644041700344118, 7.6798808006000963],
					[64.644233300377451, 7.6798808006116817],
					[64.644263900130227, 7.6798808004976813],
					[64.644263901027301, 7.6798808004541135],
					[64.644263901924319, 7.6798808004105465],
					[64.644263902821379, 7.6798808003669778],
					[64.644463901146423, 7.6798780000981868],
					[64.644494400392091, 7.6798780006735514],
					[64.644691700002056, 7.679878000091958],
					[64.644713899784378, 7.6798779997995155],
					[64.644736099566572, 7.6798779995068776],
					[64.644736101360664, 7.6798779994197401],
					[64.64473610225771, 7.6798779993761697],
					[64.644736103154756, 7.6798779993326018],
					[64.644919402055166, 7.6798752000172437],
					[64.644944400032145, 7.6798751998636385],
					[64.644958300370249, 7.6798752007954612],
					[64.645149999989229, 7.6798752000452399],
					[64.645163900326949, 7.6798752009759435],
					[64.645163908400335, 7.6798752005838162],
					[64.645163925425464, 7.6798751976637858],
					[64.645163932601804, 7.6798751973152291],
					[64.645180616923142, 7.6798724005823784],
					[64.645377799843317, 7.6798723992932345],
					[64.645386100202018, 7.679872400110785],
					[64.64539999962355, 7.6798723989900957],
					[64.645608299948165, 7.6798723998197627],
					[64.645613899926545, 7.6798723999297014],
					[64.645622199387958, 7.6798724007900434],
					[64.645836096852577, 7.6798752004122699],
					[64.645836097749651, 7.6798752003686976],
					[64.645836098646669, 7.6798752003251272],
					[64.645836100440746, 7.6798752002379862],
					[64.645844397192093, 7.679875199136907],
					[64.645949995222395, 7.6798779989635211],
					[64.646063895403259, 7.6798807995381475],
					[64.646063897197379, 7.679880799451003],
					[64.646063900785549, 7.6798807992767166],
					[64.646063902579627, 7.6798807991895748],
					[64.646286100652659, 7.6798780003765712],
					[64.646294399197572, 7.6798779991876867],
					[64.646499997201374, 7.6798808000978029],
					[64.646499998098435, 7.6798808000542289],
					[64.646499998995495, 7.6798808000106566],
					[64.646499999892541, 7.6798807999670853],
					[64.646508300249977, 7.6798808007834953],
					[64.646522196978339, 7.6798807997915084],
					[64.646713893622191, 7.6798863001230879],
					[64.646713896313344, 7.6798862999923703],
					[64.646713899004482, 7.6798862998616526],
					[64.646713899901499, 7.6798862998180804],
					[64.646736087119223, 7.6798863001243856],
					[64.646758275074262, 7.6798890969604967],
					[64.646758279578165, 7.6798890988349617],
					[64.646758291258408, 7.6798891003608469],
					[64.646758296640655, 7.6798891000994116],
					[64.646933287695006, 7.6798918994939198],
					[64.646958279264013, 7.6798945999431201],
					[64.646958281058104, 7.6798945998559747],
					[64.64695828195515, 7.6798945998124024],
					[64.646988881555771, 7.6798973983700947],
					[64.646988885143941, 7.679897398195803],
					[64.64698888964783, 7.6798974000702858],
					[64.646988893235999, 7.6798973998959958],
					[64.647147189240769, 7.6799030009922307],
					[64.647186085590391, 7.6799057984802506],
					[64.647186085609079, 7.6799058005726106],
					[64.6472249855657, 7.6799084995089588],
					[64.647224987359778, 7.6799084994218134],
					[64.647224988256809, 7.6799084993782412],
					[64.647361081649478, 7.6799168997182337],
					[64.647408277334861, 7.679922398461108],
					[64.647408280025942, 7.6799223983303904],
					[64.647408286323937, 7.6799224001177606],
					[64.64740828901509, 7.6799223999870412],
					[64.647461084799545, 7.6799251985127288],
					[64.647574980981929, 7.6799362995024252],
					[64.647574981878989, 7.6799362994588511],
					[64.647636082380458, 7.679941899486499],
					[64.647697181984654, 7.6799473991025069],
					[64.64778887939022, 7.6799557980040296],
					[64.647858275637148, 7.679964099560884],
					[64.647858279225318, 7.6799640993865959],
					[64.647858283710534, 7.6799640991687346],
					[64.647858287298703, 7.6799640989944464],
					[64.647941682694295, 7.679969597976072],
					[64.647994378741629, 7.6799751981743611],
					[64.647994379638661, 7.6799751981307907],
					[64.648077780309563, 7.6799834998946803],
					[64.648077782103655, 7.6799834998075358],
					[64.648077786588871, 7.6799834995896754],
					[64.648077789279995, 7.6799834994589595],
					[64.64817778536495, 7.6799890986133654],
					[64.648208281761939, 7.6799918982139559],
					[64.64820828265897, 7.6799918981703845],
					[64.648308283122191, 7.6800001996887222],
					[64.648308284019251, 7.6800001996451499],
					[64.64841386018098, 7.6800084962513582],
					[64.648422136117247, 7.6800112908367657],
					[64.648422147797476, 7.680011292362809],
					[64.648422172073694, 7.6800112974637393],
					[64.648422183753937, 7.6800112989897613],
					[64.64852778417108, 7.6800195986775313],
					[64.64852778506814, 7.680019598633959],
					[64.648636072387674, 7.6800279981443458],
					[64.648649960895611, 7.6800307964685306],
					[64.648649965380798, 7.6800307962506755],
					[64.648649975266935, 7.6800307978638624],
					[64.648649979770852, 7.6800307997384953],
					[64.6487582804195, 7.6800418991539177],
					[64.648758282213592, 7.6800418990667731],
					[64.648758285801748, 7.6800418988924886],
					[64.648758287614498, 7.6800419008978285],
					[64.64884998064322, 7.6800473991679752],
					[64.648894375429947, 7.6800529988675779],
					[64.648894376326965, 7.6800529988240083],
					[64.648894379018103, 7.6800529986932933],
					[64.64889438081218, 7.6800529986061514],
					[64.648980581439062, 7.6800612988317889],
					[64.648980584130186, 7.6800612987010748],
					[64.648980590428167, 7.6800613004885747],
					[64.64898059311929, 7.6800613003578615],
					[64.649063891706547, 7.6800640998360699],
					[64.649130588705191, 7.6800668997356247],
					[64.649208286293117, 7.6800723996687266],
					[64.649208287190135, 7.6800723996251561],
					[64.649208289881287, 7.6800723994944411],
					[64.649208291675365, 7.6800723994072992],
					[64.649277788660498, 7.6800751994099929],
					[64.649361085306182, 7.6800807979725851],
					[64.649430583958917, 7.6800862984694636],
					[64.649430585771682, 7.6800863004748541],
					[64.649430591153944, 7.6800863002134268],
					[64.649430592948022, 7.680086300126284],
					[64.649597191905244, 7.6800918992257907],
					[64.6496499903825, 7.6800945997355257],
					[64.649702788858875, 7.6800974007409426],
					[64.649702789755921, 7.6800974006973703],
					[64.649702790652952, 7.6800974006537999],
					[64.649833291320078, 7.6801030009980753],
					[64.649833294011231, 7.6801030008673585],
					[64.6498332975994, 7.6801030006930739],
					[64.64983330029051, 7.6801030005623598],
					[64.64988060031915, 7.6801029996321679],
					[64.649924999853667, 7.6801029993220613],
					[64.650063899852739, 7.6801029998885895],
					[64.650063904337955, 7.6801029996707291],
					[64.650063912411355, 7.6801029992785832],
					[64.65006391510245, 7.6801029991478682],
					[64.650100008147305, 7.6801001992570184],
					[64.650147199594727, 7.6801001994039311],
					[64.650147201388805, 7.6801001993167874],
					[64.650147203182868, 7.6801001992296429],
					[64.650147204079957, 7.6801001991860698],
					[64.650283302437501, 7.6800974007108751],
					[64.650330599772118, 7.6800973998930937],
					[64.650330604257348, 7.6800973996752306],
					[64.650330612330734, 7.6800973992830777],
					[64.650330615021872, 7.68009739915236],
					[64.650366712550479, 7.6800945990048683],
					[64.650513910597596, 7.6800862996489814],
					[64.650513912391673, 7.680086299561836],
					[64.650513914185751, 7.6800862994746888],
					[64.650513915082811, 7.6800862994311156],
					[64.650550015301363, 7.6800834991165976],
					[64.650588912815522, 7.6800807994853191],
					[64.650733311767382, 7.6800723993701423],
					[64.65073331356146, 7.6800723992829933],
					[64.65073331356146, 7.6800723992829933],
					[64.650772212868915, 7.6800695990373269],
					[64.650816712150259, 7.6800668994118002],
					[64.650816714841369, 7.6800668992810781],
					[64.650816721120663, 7.6800668989760563],
					[64.650816723811829, 7.6800668988453307],
					[64.650955623700483, 7.6800501988449641],
					[64.650955624597529, 7.6800501988013874],
					[64.651000024393099, 7.6800445980876226],
					[64.651047223277843, 7.6800390979556683],
					[64.65104722419359, 7.6800391000047492],
					[64.651047225968981, 7.68003909782494],
					[64.651047226865998, 7.6800390977813642],
					[64.65116942734862, 7.6800223984967246],
					[64.651169428245638, 7.6800223984531497],
					[64.651169430039715, 7.6800223983659963],
					[64.651169430936775, 7.6800223983224178],
					[64.651222225451036, 7.6800140993338042],
					[64.651283318380692, 7.6800084998775828],
					[64.651283322865908, 7.6800084996596958],
					[64.651283332714698, 7.6800084970876501],
					[64.65128333809696, 7.6800084968261864],
					[64.651383338034819, 7.6799890968076818],
					[64.651383338931893, 7.6799890967641016],
					[64.651383339828911, 7.6799890967205249],
					[64.651383340725971, 7.6799890966769446],
					[64.651450032239353, 7.679975198287023],
					[64.651519423845741, 7.6799668988490568],
					[64.651519427433897, 7.6799668986747402],
					[64.651519431900454, 7.6799668963641299],
					[64.651519434591563, 7.6799668962333945],
					[64.651597235392003, 7.6799529960654898],
					[64.651597237186095, 7.6799529959783293],
					[64.651597240774279, 7.6799529958040083],
					[64.651597242568357, 7.6799529957168486],
					[64.651672234550048, 7.6799362966756677],
					[64.651755626227171, 7.679925198152814],
					[64.651755629815341, 7.6799251979784913],
					[64.651755637870053, 7.6799251954935492],
					[64.651755640561191, 7.6799251953628058],
					[64.651808340063027, 7.6799140951493943],
					[64.651894433273199, 7.6798973966754582],
					[64.651991728081839, 7.6798834974051875],
					[64.651991729875917, 7.6798834973180226],
					[64.651991734361147, 7.6798834971001071],
					[64.651991735258207, 7.6798834970565233],
					[64.652022234733707, 7.6798779975321043],
					[64.652022237406143, 7.6798779953086029],
					[64.652022240994341, 7.6798779951342686],
					[64.652022242788419, 7.6798779950471037],
					[64.65212224195821, 7.6798557969093331],
					[64.652230640167787, 7.6798334961900094],
					[64.652230641064818, 7.6798334961464247],
					[64.652230641943191, 7.6798334940100892],
					[64.652230642840237, 7.6798334939665045],
					[64.65234444283486, 7.6798084956631136],
					[64.652344443731863, 7.6798084956195289],
					[64.652441739817235, 7.6797862963896462],
					[64.652472234801678, 7.6797807968775151],
					[64.652472241080986, 7.6797807965724045],
					[64.652472251826836, 7.6797807939565894],
					[64.652472258087414, 7.6797807915587137],
					[64.652563948188629, 7.6797529938268463],
					[64.65265003780047, 7.6797362966193505],
					[64.652650038697516, 7.6797362965757596],
					[64.652650039594533, 7.6797362965321714],
					[64.652650041388611, 7.679736296444994],
					[64.652716740937819, 7.6797223963607184],
					[64.65271674361027, 7.6797223941371637],
					[64.652716750786595, 7.679722393788448],
					[64.652716755253124, 7.6797223914777133],
					[64.652794445172191, 7.679700195907559],
					[64.652855636161902, 7.679689096727607],
					[64.652855637058934, 7.6796890966840152],
					[64.652855639750072, 7.6796890965532425],
					[64.65285564064709, 7.6796890965096516],
					[64.652961141016817, 7.6796668955305014],
					[64.652961146399093, 7.6796668952689489],
					[64.652961156247869, 7.6796668926966323],
					[64.652961159817352, 7.6796668904294592],
					[64.653013952908296, 7.6796501923217937],
					[64.653061144875878, 7.6796390948706188],
					[64.653205645428002, 7.6796057941470153],
					[64.65320564722208, 7.6796057940598272],
					[64.653205650810278, 7.6796057938854485],
					[64.653205652604356, 7.6796057937982596],
					[64.653236153094809, 7.6795973938256514],
					[64.653266752296545, 7.6795890937235303],
					[64.653266753193591, 7.6795890936799349],
					[64.653458352718744, 7.6795362927367066],
					[64.653458375967091, 7.6795362832318084],
					[64.653458419716614, 7.6795362580742346],
					[64.653458440217776, 7.6795362424215607],
					[64.653463981718247, 7.6795307994304389],
					[64.653472200399605, 7.6795307998985916],
					[64.653472214752256, 7.6795307992010322],
					[64.653472246111363, 7.6795307934894348],
					[64.653472261342415, 7.6795307906554315],
					[64.653541756761484, 7.6795084917581837],
					[64.653672245849364, 7.6794723950730317],
					[64.653686139126151, 7.6794695967198079],
					[64.653686145386757, 7.6794695943217492],
					[64.653686157029625, 7.6794695916620901],
					[64.653686163290246, 7.6794695892640297],
					[64.653702859375713, 7.679464091901183],
					[64.653877855359354, 7.6794140916144231],
					[64.653877858050478, 7.6794140914836166],
					[64.653877865208131, 7.6794140890419254],
					[64.653877867899268, 7.6794140889111189],
					[64.653908358656835, 7.6794029921795062],
					[64.653952848427664, 7.6793918933538832],
					[64.65395285291288, 7.6793918931358691],
					[64.653952862761642, 7.6793918905633562],
					[64.653952868125202, 7.6793918882088379],
					[64.654075065269083, 7.6793473890217108],
					[64.654136156997069, 7.6793279913728609],
					[64.654205654061627, 7.6793084928324076],
					[64.654205656752751, 7.6793084927015878],
					[64.654205663891702, 7.6793084881669182],
					[64.654205668376918, 7.6793084879488882],
					[64.654275065169458, 7.6792834895352229],
					[64.654358357900577, 7.6792557907361978],
					[64.654458350949412, 7.6792279938803869],
					[64.654480648770331, 7.6792223934797352],
					[64.65448065413392, 7.6792223911251547],
					[64.654480663982682, 7.6792223885525281],
					[64.65448067024326, 7.6792223861543212],
					[64.654577863657934, 7.679186289068392],
					[64.654677857693883, 7.6791557906102161],
					[64.654677861282039, 7.6791557904357681],
					[64.654677865748582, 7.6791557881247705],
					[64.654677869336723, 7.6791557879503216],
					[64.654708363658415, 7.6791445899132524],
					[64.654808357683379, 7.6791140911499767],
					[64.654808364841031, 7.6791140887081184],
					[64.654808380053368, 7.6791140837807905],
					[64.654808386295272, 7.6791140792896027],
					[64.654877885719955, 7.679080781021387],
					[64.654877888411079, 7.6790807808905406],
					[64.654877890186469, 7.6790807787103601],
					[64.654877891083515, 7.6790807786667443],
					[64.654975083259842, 7.6790307814709919],
					[64.655030672939873, 7.6790084861284322],
					[64.655066771808549, 7.6789945863583435],
					[64.655066776275063, 7.6789945840472873],
					[64.655066785226765, 7.6789945815181184],
					[64.655066788796262, 7.6789945792506789],
					[64.65525008939521, 7.6789029784416147],
					[64.655250090292228, 7.6789029783979954],
					[64.655250090292228, 7.6789029783979954],
					[64.655441790123945, 7.6788057770672138],
					[64.655441794609104, 7.6788057768490887],
					[64.655441802626413, 7.6788057701774566],
					[64.655441808008675, 7.6788057699157051],
					[64.655472296394379, 7.678786275378056],
					[64.655533383041586, 7.6787584811755414],
					[64.65553338573271, 7.6787584810446603],
					[64.655533392871632, 7.6787584765096408],
					[64.65553339556277, 7.6787584763787597],
					[64.655625094431784, 7.6787084759459745],
					[64.655700091656001, 7.6786695770723359],
					[64.655700094347111, 7.6786695769414459],
					[64.655700098813625, 7.6786695746302804],
					[64.655700102383108, 7.6786695723627432],
					[64.655808401553188, 7.6786057710040652],
					[64.655808412261607, 7.678605764201385],
					[64.65580843188431, 7.6786057506833121],
					[64.655808440779921, 7.6786057418748754],
					[64.65581402141315, 7.6786001623011186],
					[64.655922295336595, 7.6785418749765135],
					[64.655922297130672, 7.6785418748892438],
					[64.655922300700126, 7.6785418726216532],
					[64.655922303372563, 7.6785418703977149],
					[64.655991802991068, 7.6785001704205778],
					[64.655991804785145, 7.6785001703333053],
					[64.655991808354614, 7.6785001680657219],
					[64.655991810148691, 7.6785001679784513],
					[64.656105708776266, 7.6784251686607714],
					[64.65614450659011, 7.6784001680755933],
					[64.656166804724094, 7.6783862703321732],
					[64.656258404084355, 7.678330771428846],
					[64.656258404962713, 7.6783307692921445],
					[64.656258405859731, 7.6783307692485039],
					[64.656258406756791, 7.6783307692048606],
					[64.656350106708018, 7.6782723690575443],
					[64.656350115622317, 7.6782723623419047],
					[64.656350132553897, 7.6782723489542501],
					[64.656350140552448, 7.6782723401891726],
					[64.656364021824686, 7.6782584601780517],
					[64.656402898608349, 7.6782362737692349],
					[64.656402901280757, 7.6782362715452273],
					[64.656402904850225, 7.6782362692775763],
					[64.656402906644303, 7.6782362691902843],
					[64.656525104700577, 7.6781584703370624],
					[64.656594502818123, 7.678116871789407],
					[64.656594507284623, 7.6781168694780728],
					[64.656594515320592, 7.6781168648990521],
					[64.65659451887133, 7.678116860538287],
					[64.656700093911752, 7.6780390800796861],
					[64.656708363936133, 7.6780362904658057],
					[64.656708375560257, 7.6780362857121318],
					[64.656708398771073, 7.6780362720185806],
					[64.656708410395183, 7.6780362672648872],
					[64.656814010050425, 7.6779668672995722],
					[64.656814012722847, 7.6779668650754855],
					[64.656814018964724, 7.6779668605836964],
					[64.656814021637118, 7.6779668583596292],
					[64.656875121347881, 7.677919558672528],
					[64.657019520068346, 7.6778112595473091],
					[64.657019521843708, 7.6778112573668507],
					[64.657019526310208, 7.6778112550554303],
					[64.6570195281043, 7.6778112549681063],
					[64.657036219967623, 7.6777973611217654],
					[64.657044509784669, 7.6777918670402858],
					[64.657044510681729, 7.677791866996623],
					[64.657044513354165, 7.6777918647725194],
					[64.657044514251183, 7.6777918647288583],
					[64.657080713911583, 7.6777668652713782],
					[64.657080715686959, 7.677766863090933],
					[64.657080721031775, 7.6777668586427161],
					[64.657080722825867, 7.6777668585553904],
					[64.657219523251342, 7.6776584575592075],
					[64.657219525026704, 7.6776584553787428],
					[64.657219527699141, 7.6776584531546117],
					[64.657219530390265, 7.6776584530236107],
					[64.657258426206226, 7.6776251551882613],
					[64.65734182129404, 7.6775612599962004],
					[64.657386219221934, 7.6775279599511332],
					[64.657486216989255, 7.6774557611696634],
					[64.65748622055871, 7.6774557589018171],
					[64.657486227678874, 7.6774557522729543],
					[64.657486232145359, 7.6774557499614335],
					[64.657555726768933, 7.6773945554709968],
					[64.657661173387964, 7.6773140952284571],
					[64.657708311998732, 7.6773112994554884],
					[64.657708348702556, 7.6773112892919526],
					[64.657708416634293, 7.6773112587611214],
					[64.657708446927785, 7.6773112342511451],
					[64.65773904680259, 7.6772779351101912],
					[64.657739047699664, 7.6772779350665088],
					[64.657739048577966, 7.6772779329296599],
					[64.65773905037203, 7.6772779328422995],
					[64.657800144334033, 7.6772084391699016],
					[64.657891833130506, 7.6771223484218689],
					[64.657927928786762, 7.6770918532337946],
					[64.657927929665092, 7.6770918510969226],
					[64.657927930562124, 7.6770918510532367],
					[64.657927932356202, 7.677091850965863],
					[64.657997319196795, 7.677030762391686],
					[64.658066803237261, 7.6769890707052983],
					[64.658066804134265, 7.6769890706616088],
					[64.658066805928371, 7.6769890705742281],
					[64.658066806806701, 7.6769890684373419],
					[64.658150107043937, 7.6769362686063181],
					[64.658150116855211, 7.6769362618460963],
					[64.658150134664979, 7.6769362463197988],
					[64.658150143579192, 7.6769362396032728],
					[64.658233443843926, 7.6768501386873877],
					[64.658233444740958, 7.6768501386436938],
					[64.658341770420265, 7.67673631686554],
					[64.658372164303358, 7.6767418976479895],
					[64.658372224348909, 7.676741888440489],
					[64.658372328909408, 7.6767418393700124],
					[64.658372374321388, 7.6767417994633584],
					[64.658394571595323, 7.6767029022131563],
					[64.658425155872209, 7.6766529287600047],
					[64.658547333635681, 7.6765390482407323],
					[64.658591832031121, 7.6765001508305977],
					[64.658591832928181, 7.6765001507868886],
					[64.658591834703529, 7.6765001486062383],
					[64.658591835581817, 7.676500146469297],
					[64.65870007786441, 7.6764001996412237],
					[64.658713900112204, 7.6764002004282199],
					[64.658822200356056, 7.676400200438465],
					[64.658822247805546, 7.6764001876554779],
					[64.658822331752688, 7.676400141681472],
					[64.658822368250341, 7.6764001084904514],
					[64.658897367748267, 7.6762834081995788],
					[64.658897368626555, 7.6762834060626073],
					[64.658897368626555, 7.6762834060626073],
					[64.658897370420661, 7.676283405975175],
					[64.659011251111096, 7.6761001353769256],
					[64.659036222799514, 7.6760807574770098],
					[64.659036235264409, 7.6760807463985286],
					[64.659036255708983, 7.6760807244601841],
					[64.659036266361028, 7.6760807113758815],
					[64.659041860341134, 7.6760723216546873],
					[64.659058392177158, 7.6760529990679442],
					[64.659247200124639, 7.6760530001917999],
					[64.65926390043434, 7.6760530008276815],
					[64.659263947883787, 7.6760529880438737],
					[64.659264032727876, 7.6760529420243087],
					[64.659264069206714, 7.6760529067390086],
					[64.65931659804572, 7.675969935069519],
					[64.659338702663106, 7.6760861332987256],
					[64.659377600130341, 7.6763333167205099],
					[64.659377600376459, 7.6763527991650733],
					[64.659377601367197, 7.6763528095878577],
					[64.659377603348602, 7.6763528304334256],
					[64.659377604339298, 7.6763528408562118],
					[64.659430400639366, 7.6766056262722486],
					[64.659438699953611, 7.6767361125546429],
					[64.659438700906847, 7.6767361187908625],
					[64.65943870191623, 7.6767361313069866],
					[64.659438702869465, 7.6767361375431875],
					[64.659499803004167, 7.6770639368794917],
					[64.659499803919957, 7.6770639389291064],
					[64.65949980485442, 7.6770639430720289],
					[64.659499804873136, 7.6770639451653349],
					[64.659508105017352, 7.6771000452850844],
					[64.65950811512819, 7.6771000720175211],
					[64.659508141572971, 7.677100118896651],
					[64.659508158822689, 7.6771001410929598],
					[64.659530447552399, 7.6771223307397856],
					[64.659652640167366, 7.677286219718618],
					[64.659652679039624, 7.67728625133336],
					[64.659652767342706, 7.6772862910121065],
					[64.659652815876512, 7.6772862991197917],
					[64.659722216193586, 7.6772807980172706],
					[64.659722231443297, 7.6772807972746415],
					[64.659722260989483, 7.6772807895530786],
					[64.659722276201705, 7.6772807846238056],
					[64.659783375901753, 7.6772557840312485],
					[64.659783376798799, 7.6772557839875617],
					[64.659783377695845, 7.6772557839438775],
					[64.659783378592877, 7.6772557839001925],
					[64.659905678590249, 7.6772029837405196],
					[64.659905692886738, 7.6772029767614987],
					[64.659905718769878, 7.6772029608411936],
					[64.65990573213189, 7.6772029497191854],
					[64.65995013196148, 7.6771640499509122],
					[64.659950133736828, 7.6771640477701952],
					[64.65995013908163, 7.6771640433213832],
					[64.659950140856992, 7.6771640411406663],
					[64.659966792743177, 7.6771473891853965],
					[64.660172223690196, 7.6771223993882014],
					[64.660172263944673, 7.6771223848619785],
					[64.660172335352186, 7.6771223415928924],
					[64.66017236560819, 7.6771223128937365],
					[64.660177951649359, 7.6771140323246705],
					[64.660202933382223, 7.6770918497935581],
					[64.660202944931413, 7.6770918366653724],
					[64.660202967992348, 7.6770918062223208],
					[64.660202977728744, 7.677091791088178],
					[64.660308471644285, 7.6768862027071538],
					[64.660391836233828, 7.6767640551356511],
					[64.660469452945676, 7.6767251963886869],
					[64.660613906439409, 7.6767168998835391],
					[64.660691641029999, 7.6767168991066557],
					[64.660841584329006, 7.6768140628091679],
					[64.6610248732229, 7.676955754014104],
					[64.66107205608877, 7.6769973404540712],
					[64.661110941070064, 7.6770445210147944],
					[64.661291536407205, 7.6773029141936933],
					[64.661291539135732, 7.6773029182495431],
					[64.661291543677095, 7.6773029243114435],
					[64.661291547302696, 7.6773029283236047],
					[64.661499837213228, 7.677550116098625],
					[64.661522022970033, 7.6775861942655013],
					[64.661735917320215, 7.6780639825931116],
					[64.661735918235991, 7.6780639846429288],
					[64.661735920067471, 7.6780639887425828],
					[64.661735920964489, 7.6780639886989199],
					[64.661749819297029, 7.6780917855070667],
					[64.661963717179532, 7.6785750814926068],
					[64.661963719011027, 7.6785750855923087],
					[64.661963722655273, 7.6785750916982369],
					[64.661963723589707, 7.6785750958415857],
					[64.661980420761623, 7.6786056891405661],
					[64.662199815369391, 7.6790889750875522],
					[64.662208112875305, 7.6791111704124786],
					[64.662208123845446, 7.6791111929176541],
					[64.662208152008873, 7.6791112313420387],
					[64.662208168342545, 7.6791112514919275],
					[64.662230465901814, 7.679130748997486],
					[64.662435948988133, 7.6793195328104389],
					[64.662627636226304, 7.6795890154116773],
					[64.662658135035073, 7.6796334138946865],
					[64.662658208106507, 7.6796334564203121],
					[64.66265836079036, 7.6796334699418027],
					[64.662658441318499, 7.6796334429876048],
					[64.662675140608386, 7.6796168430293141],
					[64.662675140589698, 7.6796168409357595],
					[64.662705740843052, 7.6795862401045847],
					[64.662705752411142, 7.6795862290698107],
					[64.662705771024761, 7.6795862030312012],
					[64.662705778967322, 7.679586187983773],
					[64.662802958864845, 7.6793890290628974],
					[64.662889014192203, 7.6793223639527337],
					[64.662977906399931, 7.6792668706885667],
					[64.662977927760693, 7.6792668507993689],
					[64.662977966856644, 7.6792668070082835],
					[64.662977981882023, 7.6792667811437125],
					[64.663077978364953, 7.6790444926460868],
					[64.663094572277018, 7.6790168026365624],
					[64.663094579322504, 7.6790167876323814],
					[64.663094590703608, 7.6790167556613218],
					[64.663094595936272, 7.6790167386508283],
					[64.663100191933879, 7.6789889594125489],
					[64.663200183421864, 7.6787500806284887],
					[64.663308481884002, 7.6785111833300013],
					[64.66330848627571, 7.6785111726437272],
					[64.663308493246276, 7.6785111492648772],
					[64.663308495843893, 7.6785111386659048],
					[64.663314094343349, 7.6784833485062496],
					[64.663322385306657, 7.6784556786544442],
					[64.663414075443498, 7.6782889959931859],
					[64.663414076340558, 7.6782889959495257],
					[64.663414076340558, 7.6782889959495257],
					[64.66341407721886, 7.678288993812262],
					[64.663436276810813, 7.6782472942724382],
					[64.663436276792112, 7.6782472921788072],
					[64.663436277689158, 7.6782472921351479],
					[64.663436277670456, 7.6782472900415364],
					[64.663544527583838, 7.6780362880640771],
					[64.663575017992002, 7.6780334989379986],
					[64.663575023374264, 7.6780334986759975],
					[64.663575032325937, 7.6780334961456891],
					[64.663575035914093, 7.6780334959710226],
					[64.663772201936865, 7.6779974020427719],
					[64.663788858088637, 7.6780001959358719],
					[64.663955539729614, 7.6780445903828918],
					[64.66399433331361, 7.6780584886953642],
					[64.663994338714559, 7.6780584905270155],
					[64.663994352207567, 7.678058494059341],
					[64.663994359402622, 7.6780584958036595],
					[64.66404715931462, 7.6780695968334927],
					[64.66415272942173, 7.6780918884380185],
					[64.664222101865334, 7.6781307739670552],
					[64.664222107284971, 7.6781307778924086],
					[64.664222118086883, 7.6781307815557716],
					[64.664222122609473, 7.6781307855248082],
					[64.664322109050062, 7.6781723786669067],
					[64.664335953082414, 7.678180745070974],
					[64.664444227587069, 7.6783640015318326],
					[64.66444422760577, 7.6783640036255223],
					[64.664444229399862, 7.6783640035382064],
					[64.664444230315624, 7.6783640055882358],
					[64.664466509054336, 7.6784000712048899],
					[64.664474802389194, 7.6784528307412776],
					[64.664474803342316, 7.6784528369786953],
					[64.664474806126975, 7.6784528473162021],
					[64.66447480706141, 7.6784528514599479],
					[64.664513704640569, 7.678597245861055],
					[64.664544198852468, 7.678750015333379],
					[64.664535900151876, 7.6789749925738802],
					[64.664535900189264, 7.678974996761271],
					[64.664535900245355, 7.6789750030423782],
					[64.664535900282758, 7.6789750072297682],
					[64.66454420040283, 7.6791778035964242],
					[64.664544199595397, 7.6792917001024028],
					[64.664544199748903, 7.6794972000948922],
					[64.664544199748903, 7.6794972000948922],
					[64.664544199786292, 7.6794972042823035],
					[64.664544199786292, 7.6794972042823035],
					[64.66454980022921, 7.6797083031713749],
					[64.664549799847123, 7.6798055998863282],
					[64.664549799765126, 7.6800194008417995],
					[64.664549799783799, 7.6800194029355158],
					[64.664549799802472, 7.6800194050292143],
					[64.66454979982116, 7.680019407122912],
					[64.664558099870362, 7.6802472030285323],
					[64.664558099875393, 7.6803138995889526],
					[64.66455809989408, 7.6803139016826494],
					[64.664558099931426, 7.6803139058700474],
					[64.664558099950099, 7.6803139079637459],
					[64.66456649994042, 7.680544407486841],
					[64.664566500893514, 7.6805444137243715],
					[64.664566501883911, 7.6805444241493026],
					[64.664566501939916, 7.6805444304304],
					[64.664619201909815, 7.6808917290272136],
					[64.664619201928488, 7.6808917311209175],
					[64.664619202844179, 7.6808917331710473],
					[64.664619202862838, 7.6808917352647716],
					[64.664649803298261, 7.68106393441282],
					[64.66469420300659, 7.6813306328465432],
					[64.664694203043908, 7.6813306370339625],
					[64.664694204894019, 7.6813306432279891],
					[64.664694205828368, 7.6813306473718495],
					[64.664755403967945, 7.681583341246041],
					[64.664794202870297, 7.6817917371513058],
					[64.664794203767329, 7.681791737107762],
					[64.664794203786002, 7.6817917392014783],
					[64.664855403818237, 7.6821000390126617],
					[64.664855403818237, 7.6821000390126617],
					[64.664855404752586, 7.6821000431565905],
					[64.664855404752586, 7.6821000431565905],
					[64.664933103614032, 7.6824444429326357],
					[64.664969204135119, 7.6826194391928304],
					[64.664969205069426, 7.6826194433367974],
					[64.664969206938082, 7.6826194516247091],
					[64.664969206956727, 7.6826194537184405],
					[64.665024804994388, 7.6828167447575249],
					[64.665038703282505, 7.682894435482865],
					[64.66503870330115, 7.6828944375766213],
					[64.665038704235457, 7.6828944417205882],
					[64.665038705151161, 7.6828944437708167],
					[64.665091503192315, 7.6831361336844903],
					[64.665108102274274, 7.6832667240593526],
					[64.665108103245828, 7.683266732390849],
					[64.665108106086052, 7.6832667490103237],
					[64.66510810795468, 7.6832667572983233],
					[64.66516090757311, 7.6834444567593279],
					[64.665160909404506, 7.6834444608598389],
					[64.665160911254475, 7.6834444670541187],
					[64.665160913067197, 7.6834444690608841],
					[64.665205412976022, 7.6835639693022735],
					[64.665205413891712, 7.6835639713525374],
					[64.665205415741696, 7.6835639775468154],
					[64.665205416657344, 7.6835639795970998],
					[64.665244214701033, 7.6836528750356221],
					[64.665313709280397, 7.6838333613768057],
					[64.665397000032627, 7.6841556240387181],
					[64.665397000055336, 7.6841639000878583],
					[64.665397012145235, 7.6841639476794779],
					[64.665397056863839, 7.6841640313941273],
					[64.665397090389604, 7.6841640674736684],
					[64.665405481756721, 7.6841695615476917],
					[64.665566530688395, 7.6843001199409864],
					[64.665619211694889, 7.6844472664897587],
					[64.665619212647769, 7.6844472727276525],
					[64.665619217207464, 7.6844472808855109],
					[64.665619219038788, 7.6844472849861605],
					[64.665672018876649, 7.6845611835604046],
					[64.665672027995981, 7.6845611998761942],
					[64.665672048888638, 7.684561228189791],
					[64.665672059783446, 7.6845612423248628],
					[64.665785960326872, 7.684672342978967],
					[64.665785964849292, 7.684672346949303],
					[64.665785973894202, 7.6846723548899902],
					[64.665785978416622, 7.6846723588603432],
					[64.66583316118539, 7.684708444187387],
					[64.665847032857528, 7.68472511225609],
					[64.666008123075073, 7.6850306919844655],
					[64.666008124009323, 7.6850306961286554],
					[64.666008126737694, 7.6850307001859806],
					[64.666008127653313, 7.6850307022363555],
					[64.666077627940211, 7.6851473014462117],
					[64.666077649711141, 7.6851473276234241],
					[64.666077702148996, 7.6851473711682043],
					[64.666077731021872, 7.6851473886226431],
					[64.666169255729514, 7.6851807603989615],
					[64.666305412687308, 7.6854583736721409],
					[64.666374807736005, 7.6856917561844265],
					[64.666374808670255, 7.6856917603287132],
					[64.666374811417185, 7.6856917664799838],
					[64.666374812332805, 7.6856917685304085],
					[64.666533112406981, 7.6861222691596929],
					[64.666533136980433, 7.6861223077710799],
					[64.666533200331315, 7.6861223675484647],
					[64.666533238230258, 7.6861223908517431],
					[64.666755400245705, 7.6861945458997587],
					[64.6667553995849, 7.6861972000129315],
					[64.666755428941073, 7.6861972719097071],
					[64.666755529442582, 7.6861973717857452],
					[64.666755599690831, 7.6861973998084112],
					[64.666763900011048, 7.6861974003947724],
					[64.666763906290356, 7.6861974000909479],
					[64.666763918848972, 7.6861973994832997],
					[64.666763926006695, 7.6861973970422],
					[64.666977826183654, 7.6861695977698261],
					[64.666977842311852, 7.6861695948946496],
					[64.666977872736894, 7.6861695850433112],
					[64.666977887949471, 7.6861695801176495],
					[64.666983387641096, 7.6861668798567671],
					[64.666983408161485, 7.6861668662950962],
					[64.66698344380147, 7.686166837338269],
					[64.666983459799482, 7.6861668198058402],
					[64.667000160330559, 7.6861445191278817],
					[64.667000163003081, 7.6861445169037754],
					[64.667000168310935, 7.6861445082677546],
					[64.667000170086467, 7.6861445060870501],
					[64.667114069890033, 7.6859612066518661],
					[64.667114069871445, 7.6859612045579642],
					[64.667114070768463, 7.6859612045145544],
					[64.667114070749861, 7.6859612024206347],
					[64.667205770566241, 7.685808403848716],
					[64.667205779406473, 7.6858083887571746],
					[64.66720578992917, 7.6858083610153383],
					[64.66720579516263, 7.6858083440035392],
					[64.667227993867925, 7.6857111490983794],
					[64.667244592000856, 7.6856500548410009],
					[64.66731957700938, 7.6854001056942485],
					[64.66742793641815, 7.6852890475144005],
					[64.66748072890806, 7.6852445527563225],
					[64.667480744905959, 7.6852445352230072],
					[64.667480774173427, 7.68524449609885],
					[64.667480784751859, 7.6852444746382957],
					[64.667533482431239, 7.6851139828749728],
					[64.66759455778346, 7.6849945289748307],
					[64.667647324117667, 7.6849529569403838],
					[64.66764732768722, 7.6849529546726387],
					[64.667647335704757, 7.6849529479998022],
					[64.667647340152755, 7.68495294359467],
					[64.667755739686456, 7.6848473436980047],
					[64.66775576183332, 7.6848473112024669],
					[64.667755791699861, 7.6848472385307947],
					[64.667755800335257, 7.6848472004051587],
					[64.667755800413232, 7.684844483375854],
					[64.667869531654659, 7.6847334500317332],
					[64.667922323758432, 7.6846918569580618],
					[64.66792232465545, 7.6846918569146077],
					[64.667922326430926, 7.6846918547337353],
					[64.668083398152646, 7.6845612772971474],
					[64.66809176343142, 7.6845584901362738],
					[64.668091813349278, 7.6845584521048824],
					[64.668091880723907, 7.6845583587608566],
					[64.668091899937536, 7.6845582991733519],
					[64.668091900006786, 7.6845556826648806],
					[64.66809734298748, 7.6845501398626475],
					[64.66809734388454, 7.6845501398191871],
					[64.668097345641371, 7.684550135544316],
					[64.668097348332537, 7.6845501354139341],
					[64.668244547994846, 7.684389035491864],
					[64.668244548873247, 7.6843890333544138],
					[64.668244552424213, 7.6843890289925758],
					[64.66824455421829, 7.6843890289056427],
					[64.668311201719376, 7.6843084915178252],
					[64.66841941014529, 7.6842973989598073],
					[64.668433299661245, 7.6842974004528264],
					[64.668433304146447, 7.6842974002354802],
					[64.668433312219818, 7.6842973998442563],
					[64.668433316705034, 7.6842973996269084],
					[64.668533310670071, 7.6842890995229931],
					[64.668647201767342, 7.6842862996409469],
					[64.668655599899154, 7.6842862990866543],
					[64.668655602608922, 7.6842863010502604],
					[64.668655607991155, 7.6842863007894389],
					[64.668655609785233, 7.6842863007025004],
					[64.668761027613769, 7.6842808046200597],
					[64.668960966811639, 7.6844584498594797],
					[64.668960990283665, 7.6844584654817547],
					[64.668961038050341, 7.684458488306638],
					[64.668961064157656, 7.684458497516375],
					[64.668991586004836, 7.6844640827830935],
					[64.669030437910266, 7.6845168177663083],
					[64.669210919839543, 7.684769491291557],
					[64.669297010183087, 7.6850278627988269],
					[64.669297012032999, 7.6850278689941565],
					[64.669297014779971, 7.6850278751460168],
					[64.669297016629884, 7.6850278813413464],
					[64.669441515068982, 7.6853556757945807],
					[64.669510911725794, 7.6855417674221833],
					[64.669677606843877, 7.686013952894494],
					[64.669685904040108, 7.6860556399200455],
					[64.669685905889978, 7.6860556461155145],
					[64.669685911439601, 7.6860556647019589],
					[64.669685914205118, 7.6860556729481306],
					[64.669752611862378, 7.6862278704360536],
					[64.669874812053777, 7.6865722669102503],
					[64.669874814819252, 7.6865722751565349],
					[64.669874823957002, 7.6865722935696228],
					[64.669874829432175, 7.6865723037798297],
					[64.669891526924502, 7.6866000992662666],
					[64.669905422899816, 7.6866250930963318],
					[64.670013720846313, 7.6868444891359609],
					[64.670013725387292, 7.6868444952014103],
					[64.670013732693775, 7.6868445095131941],
					[64.670013738150359, 7.6868445176293827],
					[64.67003593836867, 7.6868751164879416],
					[64.670035944740874, 7.6868751266548818],
					[64.670035959242242, 7.6868751427137134],
					[64.67003596737139, 7.6868751486056244],
					[64.670135936232924, 7.686964022261761],
					[64.670158119190262, 7.6870111855599887],
					[64.670158121937163, 7.6870111917122648],
					[64.670158130140621, 7.6870112059807862],
					[64.670158133765966, 7.6870112099955259],
					[64.670202634112911, 7.6870779113876901],
					[64.670202635944165, 7.6870779154892066],
					[64.670202641382161, 7.6870779215113592],
					[64.670202644110475, 7.6870779255694943],
					[64.670302644415528, 7.6872029260142689],
					[64.67030264622818, 7.6872029280216605],
					[64.670302649853525, 7.6872029320364668],
					[64.670302651666191, 7.6872029340438592],
					[64.670355445839292, 7.6872612277720442],
					[64.670447039988801, 7.6873834200972668],
					[64.670447047239449, 7.6873834281269486],
					[64.67044706082514, 7.6873834421355065],
					[64.670447068972848, 7.6873834501218159],
					[64.670533164916833, 7.6874584474317622],
					[64.670585961675229, 7.6875084455374498],
					[64.670585974326741, 7.6875084554011686],
					[64.670585997798455, 7.6875084710269856],
					[64.670586011328382, 7.6875084787531698],
					[64.670608208043092, 7.6875195780231769],
					[64.670716604924394, 7.6875779756722906],
					[64.670716605821454, 7.6875779756289235],
					[64.670716606737045, 7.687577977679739],
					[64.670716607634091, 7.6875779776363737],
					[64.670791603362616, 7.6876168740050046],
					[64.670805497343693, 7.6876251714196808],
					[64.670805513564744, 7.6876251790158916],
					[64.670805546885376, 7.6876251920707412],
					[64.670805563984914, 7.6876251975294014],
					[64.67083606425814, 7.6876307961317627],
					[64.670836065155157, 7.6876307960883992],
					[64.670836067864869, 7.6876307980525151],
					[64.670836069658947, 7.6876307979657863],
					[64.671036069855532, 7.6876612970270379],
					[64.671036080638615, 7.6876612986008812],
					[64.671036103083267, 7.6876612996109994],
					[64.671036113847762, 7.6876612990906406],
					[64.671075014033619, 7.6876584988852077],
					[64.671075022107019, 7.6876584984949368],
					[64.671075037338156, 7.6876584956635492],
					[64.671075043617464, 7.6876584953600036],
					[64.671258343944302, 7.6876168939688059],
					[64.67125836005394, 7.6876168889997869],
					[64.671258391357568, 7.6876168770108766],
					[64.671258405654527, 7.6876168700343488],
					[64.671280706384593, 7.6876029697986663],
					[64.671280716177762, 7.6876029609447327],
					[64.671280735782673, 7.687602945331105],
					[64.671280744697384, 7.6876029386147771],
					[64.671333443847331, 7.6875473381261568],
					[64.671439042828453, 7.6874390390328209],
					[64.67143904905204, 7.6874390324464965],
					[64.671439060583552, 7.687439017223002],
					[64.671439065910064, 7.6874390106800679],
					[64.67147796377678, 7.6873807141578787],
					[64.671577962248449, 7.6872418168375933],
					[64.671577963145467, 7.6872418167942156],
					[64.671577964023953, 7.6872418146565913],
					[64.671577964902397, 7.6872418125189483],
					[64.671639065441113, 7.6871529126281661],
					[64.671639066319557, 7.6871529104905347],
					[64.671700165318256, 7.6870612132357783],
					[64.671714060420499, 7.6870418209750859],
					[64.671744557143356, 7.6870029222509073],
					[64.671744560712952, 7.6870029199830832],
					[64.671744566899335, 7.6870029092080667],
					[64.671744569553269, 7.6870029048893915],
					[64.671852964973283, 7.6868279136971536],
					[64.671919560283598, 7.6867390206091679],
					[64.671919565591523, 7.6867390119716879],
					[64.67191957620733, 7.6867389946967499],
					[64.671919579721134, 7.6867389861460618],
					[64.671980779811975, 7.6866111863610502],
					[64.671980783344367, 7.6866111799046024],
					[64.671980787718056, 7.6866111671219262],
					[64.671980790372004, 7.6866111628031746],
					[64.672027990299668, 7.6864667629707775],
					[64.672027992019395, 7.6864667545068306],
					[64.672027996355837, 7.6864667375355324],
					[64.672027998075592, 7.6864667290716024],
					[64.672058497586633, 7.6862667303215355],
					[64.672058497549457, 7.6862667261329731],
					[64.672058499306345, 7.6862667218575664],
					[64.672058499287743, 7.6862667197632852],
					[64.6720890994184, 7.6859389259957824],
					[64.672102998132218, 7.6858500310966562],
					[64.672102998113616, 7.6858500290023724],
					[64.672102998076411, 7.6858500248138011],
					[64.672102998954855, 7.6858500226760729],
					[64.672133498849433, 7.6855778244320732],
					[64.672150198355155, 7.6854472249490007],
					[64.672150199196352, 7.6854472186226994],
					[64.672150199981786, 7.6854472060134906],
					[64.672150199925966, 7.6854471997306257],
					[64.672150200315585, 7.685413909194855],
					[64.672189098699391, 7.6850167186314025],
					[64.672189098680775, 7.6850167165371124],
					[64.672189100419018, 7.6850167101673135],
					[64.672189100400416, 7.6850167080730234],
					[64.672194598703015, 7.6848861184226971],
					[64.672225198159381, 7.6846583294070125],
					[64.672233497563113, 7.6846056310895232],
					[64.672233497544497, 7.6846056289952296],
					[64.672233498422941, 7.6846056268574676],
					[64.67223349840431, 7.684605624763174],
					[64.672264097850515, 7.684361127947418],
					[64.672286297127172, 7.6842139325513035],
					[64.672347395677633, 7.6838833412141794],
					[64.672355794912775, 7.6838472442013481],
					[64.672355796613687, 7.6838472336428323],
					[64.672355799099932, 7.6838472104749869],
					[64.672355799903826, 7.6838471999599616],
					[64.672355799720876, 7.6838306331479354],
					[64.672372389121506, 7.6837833654268728],
					[64.672372390859692, 7.6837833590569726],
					[64.672372395195879, 7.6837833420850314],
					[64.672372396934065, 7.6837833357151313],
					[64.672425196964937, 7.683486136042756],
					[64.672425196946293, 7.6834861339484481],
					[64.672425196946293, 7.6834861339484481],
					[64.67245579712305, 7.6833028374334722],
					[64.672491894805916, 7.6831306424725891],
					[64.672561293740998, 7.6828278492689321],
					[64.672569590972415, 7.6827972586489448],
					[64.672577990061072, 7.6827722636096158],
					[64.672577990042427, 7.6827722615152956],
					[64.672577991799244, 7.6827722572395825],
					[64.672577991761955, 7.6827722530509446],
					[64.672661286218556, 7.6824778768323405],
					[64.672783418741645, 7.6822530003815288],
					[64.67279170021493, 7.6822530006565],
					[64.672791727985896, 7.6822529951179188],
					[64.672791780799415, 7.682252979982704],
					[64.672791805841939, 7.6822529703860702],
					[64.672814005584613, 7.6822390698280829],
					[64.67296676983689, 7.6821445918430067],
					[64.673002795783944, 7.6821391028167447],
					[64.673216634804348, 7.6821807909528594],
					[64.67323320603586, 7.6821890778781841],
					[64.673277695177688, 7.6822140708190769],
					[64.673416554709902, 7.6823084422155095],
					[64.673455434160672, 7.6823668113896639],
					[64.673455441430278, 7.682366821513269],
					[64.673455456847805, 7.6823668396225333],
					[64.673455465892815, 7.6823668475646665],
					[64.673569215433247, 7.6824695023938627],
					[64.673613702993137, 7.6827222351092379],
					[64.673613703011782, 7.6827222372036381],
					[64.673613703927458, 7.6827222392545051],
					[64.673613704843149, 7.6827222413053722],
					[64.673635901418024, 7.6828250244147958],
					[64.673644200387997, 7.6831444036317293],
					[64.673644200007644, 7.6832444001871263],
					[64.673644200044919, 7.6832444043759294],
					[64.67364420105379, 7.6832444168988436],
					[64.673644201091065, 7.6832444210876654],
					[64.673652600424802, 7.6833222127030663],
					[64.673660900323469, 7.6836972017170559],
					[64.673660900198101, 7.6837666989724163],
					[64.673660900253978, 7.6837667052556453],
					[64.67366090124419, 7.6837667156841833],
					[64.673660901281437, 7.6837667198729891],
					[64.673666501310123, 7.6838222192121961],
					[64.673666502263075, 7.6838222254519302],
					[64.673666504168921, 7.6838222379313823],
					[64.673666505121844, 7.6838222441710977],
					[64.673697004988142, 7.6839583435499366],
					[64.673697015097744, 7.6839583702988561],
					[64.673697044268721, 7.683958421267401],
					[64.67369706062037, 7.6839584435230712],
					[64.673835960925402, 7.6840918444693695],
					[64.673835982603379, 7.6840918601810424],
					[64.673836030388699, 7.6840918851037037],
					[64.673836054701908, 7.6840918944016661],
					[64.673919355083541, 7.6841112945092576],
					[64.673919368576406, 7.6841112980458099],
					[64.673919399094459, 7.6841112986616986],
					[64.673919414362771, 7.6841113000168555],
					[64.67399721105032, 7.6841057999627065],
					[64.674072206648233, 7.6841030003648196],
					[64.674072210236389, 7.6841030001908734],
					[64.674072213824587, 7.6841030000169237],
					[64.674072215618665, 7.6841029999299497],
					[64.674141715584597, 7.6840973991455757],
					[64.674141755876974, 7.6840973888108879],
					[64.674141826426592, 7.6840973497698482],
					[64.674141857599537, 7.6840973231144476],
					[64.674239052965419, 7.6839723294681406],
					[64.674294547701692, 7.6839112349501422],
					[64.674294552149675, 7.6839112305437478],
					[64.674294558354447, 7.683911221861484],
					[64.674294561905356, 7.6839112174985846],
					[64.674369561907397, 7.6838084179173558],
					[64.67436957338262, 7.6838083964072741],
					[64.67436959095086, 7.6838083536481099],
					[64.674369597922251, 7.6838083302610558],
					[64.674377997763699, 7.683752829509082],
					[64.674377998586237, 7.6837528210877286],
					[64.674378000268518, 7.6837528084339608],
					[64.674378000194025, 7.6837528000561059],
					[64.674378000171643, 7.6837333257296159],
					[64.674391894260182, 7.6836778497512492],
					[64.674391895101337, 7.6836778434243502],
					[64.674391897680692, 7.683677830727067],
					[64.674391897624787, 7.6836778244436683],
					[64.674430797722565, 7.6833639269645584],
					[64.674452996129972, 7.6832056428539381],
					[64.674508492101467, 7.6830167555144966],
					[64.674508492979896, 7.6830167533765046],
					[64.674508492979896, 7.6830167533765046],
					[64.674583493090978, 7.6827444529396356],
					[64.674583493950735, 7.682744448707135],
					[64.674583495670234, 7.6827444402421756],
					[64.674583497427037, 7.6827444359661428],
					[64.674591894415471, 7.6827000505751712],
					[64.674600190359286, 7.6826750628325549],
					[64.674600190359286, 7.6826750628325549],
					[64.674661289794955, 7.6824889627304405],
					[64.674661290654711, 7.682488958497939],
					[64.674661294168274, 7.6824889499458378],
					[64.674661295028045, 7.6824889457133354],
					[64.674683494224752, 7.6823944494216558],
					[64.674750189715141, 7.6821444644103982],
					[64.674775184686595, 7.6820833769810948],
					[64.674775185564968, 7.682083374843053],
					[64.674775186443355, 7.6820833727050095],
					[64.674775187321714, 7.6820833705669678],
					[64.674797387383592, 7.6820250715097265],
					[64.674797388261965, 7.6820250693716607],
					[64.674797389121707, 7.6820250651391246],
					[64.674797390000066, 7.6820250630010785],
					[64.67487238882849, 7.6818000669595987],
					[64.674941880323871, 7.6816111896377866],
					[64.674989066574426, 7.6815390093679712],
					[64.67498906836849, 7.6815390092808249],
					[64.675019560722347, 7.6814918187124732],
					[64.675116853978793, 7.6813751277490336],
					[64.675116858407961, 7.6813751212475996],
					[64.675116867266411, 7.6813751082447066],
					[64.67511687171428, 7.6813751038377651],
					[64.675133467865493, 7.681347308503863],
					[64.675239030779565, 7.6811945630259135],
					[64.675286175887578, 7.6811751840504288],
					[64.675286196444802, 7.6811751746698258],
					[64.675286232999483, 7.6811751477484407],
					[64.67528625080962, 7.6811751322150137],
					[64.675383443847636, 7.6810640386569071],
					[64.675439038348827, 7.6810112454388646],
					[64.675439041002605, 7.6810112411190135],
					[64.675439047244552, 7.6810112366247942],
					[64.67543904989833, 7.6810112323049236],
					[64.675461245761483, 7.6809862362199377],
					[64.675597299188382, 7.6808501836915122],
					[64.675688920777162, 7.6808308005269978],
					[64.675780600275644, 7.680830799656472],
					[64.675780612834174, 7.6808307990460962],
					[64.675780639726739, 7.6808307956435788],
					[64.675780653163642, 7.6808307928950388],
					[64.675811152779659, 7.680822393363453],
					[64.675811153676719, 7.6808223933198541],
					[64.675911049260364, 7.680794621857773],
					[64.676108167218445, 7.6809695498689097],
					[64.676108172638038, 7.6809695537965412],
					[64.676108182580165, 7.6809695616953801],
					[64.676108188896762, 7.6809695655793995],
					[64.676133174906909, 7.6809862573746193],
					[64.67617765683309, 7.6810279399294767],
					[64.676360944791057, 7.6812307264077626],
					[64.676444239717185, 7.6813418199671721],
					[64.676444244258349, 7.6813418260331279],
					[64.67644425421912, 7.6813418360268155],
					[64.676444258741654, 7.6813418399981339],
					[64.676558155736046, 7.6814557371065098],
					[64.676588746864525, 7.6814890305867669],
					[64.676619238848886, 7.6815279177623275],
					[64.676819230283655, 7.6818195054785781],
					[64.676941521313623, 7.6820389900352586],
					[64.676994218273322, 7.6821556814549172],
					[64.676994218291952, 7.6821556835495972],
					[64.67704701843347, 7.6822722823940879],
					[64.677047019349146, 7.6822722844451992],
					[64.677047019349146, 7.6822722844451992],
					[64.677177619421485, 7.6825500856128155],
					[64.67717762031856, 7.682550085569269],
					[64.67717762033719, 7.6825500876639445],
					[64.677277619969828, 7.6827583863801214],
					[64.677277619988487, 7.6827583884748014],
					[64.677277621801196, 7.6827583904824088],
					[64.6772776227169, 7.6827583925335521],
					[64.677435922660237, 7.6830611938441535],
					[64.67743592628571, 7.6830611978594288],
					[64.677435931761153, 7.6830612080717513],
					[64.677435936302274, 7.6830612141381929],
					[64.677505436309659, 7.6831612138351097],
					[64.677505439953734, 7.6831612199451094],
					[64.677505449017332, 7.6831612299833791],
					[64.677505453558453, 7.6831612360498731],
					[64.677588754102231, 7.6832501368259676],
					[64.677588764062875, 7.6832501468207752],
					[64.677588785759568, 7.6832501646286406],
					[64.677588796598599, 7.6832501724852191],
					[64.677727667870798, 7.6833334544305041],
					[64.677794226083208, 7.6834140044579815],
					[64.677855415053017, 7.6835639754516931],
					[64.677855422359798, 7.6835639897667214],
					[64.677855438748765, 7.683564016214989],
					[64.677855447849581, 7.6835640304429766],
					[64.677955447926067, 7.6836807291100282],
					[64.677955484965565, 7.6836807566525041],
					[64.677955566053228, 7.6836807925367765],
					[64.677955610998453, 7.6836808008350443],
					[64.678008309956709, 7.6836779996218105],
					[64.678130609160476, 7.6836724009900541],
					[64.678130610057494, 7.6836724009465458],
					[64.678130610954554, 7.6836724009030357],
					[64.678130611851614, 7.6836724008595256],
					[64.678177812274257, 7.6836696001557003],
					[64.678177856117429, 7.6836695854552168],
					[64.678177933805969, 7.6836695418695475],
					[64.678177967614047, 7.6836695087948801],
					[64.678208466869989, 7.6836223105351138],
					[64.678261265104908, 7.683544510972304],
					[64.678261273085113, 7.6835445001068701],
					[64.678261285420092, 7.6835444743605441],
					[64.678261289793468, 7.6835444615744093],
					[64.678322381042562, 7.6833556897442437],
					[64.678397364328774, 7.6832473142154551],
					[64.678397365225848, 7.6832473141719291],
					[64.678397365207204, 7.6832473120771621],
					[64.678458464423258, 7.6831584141902978],
					[64.678566862845202, 7.6830057163623087],
					[64.678566867274455, 7.6830057098602866],
					[64.678566874357585, 7.6830056990381044],
					[64.678566876992761, 7.6830056926231656],
					[64.678597376876922, 7.6829472935209173],
					[64.678597378633711, 7.6829472892442618],
					[64.678597383044334, 7.682947280647439],
					[64.678597383922721, 7.6829472785091237],
					[64.678619583936936, 7.682894477315152],
					[64.678619585693724, 7.6828944730385098],
					[64.678619590085702, 7.6828944623468862],
					[64.678619590945445, 7.6828944581137808],
					[64.678694582135194, 7.6826500876886819],
					[64.678816862809043, 7.68246951650426],
					[64.678833459664304, 7.682447319832157],
					[64.678833464990575, 7.6824473132864295],
					[64.678833473830423, 7.6824472981872916],
					[64.678833478241032, 7.6824472895903106],
					[64.678841877564352, 7.6824306903435797],
					[64.678841880236845, 7.6824306881181217],
					[64.678841881974975, 7.6824306817465926],
					[64.678841883750394, 7.6824306795646899],
					[64.678947383781335, 7.6821861781614524],
					[64.678947383781335, 7.6821861781614524],
					[64.678947384659736, 7.6821861760230643],
					[64.678947386435155, 7.6821861738411332],
					[64.679047382617213, 7.6819361829655346],
					[64.679055779248742, 7.6819194892498768],
					[64.679055779248742, 7.6819194892498768],
					[64.679061268845828, 7.6819084086652003],
					[64.679200154713911, 7.6817390273139079],
					[64.679200154695252, 7.6817390252190778],
					[64.679200155592312, 7.6817390251755029],
					[64.679200157367717, 7.6817390229935043],
					[64.679283457373259, 7.6816334230174741],
					[64.679283462699516, 7.6816334164714766],
					[64.67928347419307, 7.6816333970514146],
					[64.679283478622295, 7.6816333905489946],
					[64.679336262677637, 7.6815279207665554],
					[64.679480738980857, 7.6813890445129376],
					[64.679480738962198, 7.6813890424180862],
					[64.679480740756318, 7.6813890423309106],
					[64.679480741634663, 7.6813890401924736],
					[64.679497282850761, 7.6813724008748165],
					[64.679505600197686, 7.6813723991153466],
					[64.679505602888838, 7.6813723989845828],
					[64.67950560828973, 7.6813724008179278],
					[64.679505610083808, 7.6813724007307522],
					[64.679724937732743, 7.6813613032312107],
					[64.679747064959528, 7.6813779499599955],
					[64.679885938174465, 7.6815279189701675],
					[64.679955425853692, 7.6816500999688042],
					[64.679955443999759, 7.6816501221410123],
					[64.679955485636796, 7.6816501620341775],
					[64.679955509109107, 7.6816501776602468],
					[64.68011380820522, 7.6817307773755523],
					[64.680183154269642, 7.6817668497538802],
					[64.680244225203666, 7.6818778956461546],
					[64.680244225222324, 7.6818778977410656],
					[64.680244227035047, 7.6818778997488302],
					[64.680244227950737, 7.6818779018001671],
					[64.680410921644992, 7.6821611904765108],
					[64.680510916563662, 7.682388980952858],
					[64.680510919292061, 7.6823889850120519],
					[64.680510924767574, 7.682388995225387],
					[64.680510928411678, 7.6823890013359541],
					[64.680641521150733, 7.6826111896862095],
					[64.680763715799344, 7.6829000777238727],
					[64.680763716715006, 7.6829000797752851],
					[64.68076371764937, 7.6829000839216448],
					[64.680763718565018, 7.6829000859730776],
					[64.680869216695086, 7.683122281029422],
					[64.680991509666796, 7.6834139648724493],
					[64.68103040697676, 7.6835583511396397],
					[64.681030408845373, 7.6835583594324888],
					[64.681030415273753, 7.6835583758876718],
					[64.68103041803937, 7.6835583841370241],
					[64.681099811099187, 7.6837083672213886],
					[64.681135906994982, 7.6838444502126633],
					[64.681135907947905, 7.6838444564540964],
					[64.681135910713564, 7.6838444647035073],
					[64.681135911647829, 7.6838444688499594],
					[64.681166508822074, 7.6839278620325615],
					[64.681227603159954, 7.6841500357158656],
					[64.68123590054816, 7.6842361162683561],
					[64.681252599826294, 7.6844472100158034],
					[64.681258100173963, 7.6846028067853842],
					[64.681258100192579, 7.6846028088803742],
					[64.681266499723932, 7.6848222047116819],
					[64.681266499830116, 7.6849721996619822],
					[64.681266499867334, 7.684972203851963],
					[64.681266499904567, 7.6849722080419465],
					[64.681266499941799, 7.6849722122319486],
					[64.681274799677539, 7.6851083095232209],
					[64.681283099981798, 7.6853639023903915],
					[64.681283099571331, 7.6854943992993308],
					[64.681283099645754, 7.6854944076793226],
					[64.681283101588676, 7.6854944243523748],
					[64.681283102560158, 7.6854944326889107],
					[64.681344201991863, 7.6858667253332822],
					[64.681358100632863, 7.6860139182186913],
					[64.681358100670067, 7.6860139224086881],
					[64.681358101641507, 7.6860139307452657],
					[64.681358102575729, 7.6860139348918262],
					[64.681372002197264, 7.686094432006283],
					[64.681433102181856, 7.6865361268707337],
					[64.681433102200444, 7.6865361289657397],
					[64.681433103134694, 7.6865361331123285],
					[64.681433103134694, 7.6865361331123285],
					[64.681488700440397, 7.6868556194113236],
					[64.681494199844778, 7.6870556052252521],
					[64.681494200834734, 7.6870556156569192],
					[64.681494203711694, 7.6870556364768525],
					[64.681494204701664, 7.6870556469085196],
					[64.681580402959042, 7.6874194379297371],
					[64.681602600182117, 7.6875750133657847],
					[64.6816026001094, 7.6876333009431992],
					[64.6816026001094, 7.6876333009431992],
					[64.681602600127974, 7.6876333030382185],
					[64.681602600146547, 7.6876333051332386],
					[64.681616500106131, 7.6880972024352694],
					[64.681616500052044, 7.6881777906457556],
					[64.681585902202329, 7.6885055731409633],
					[64.681572004399627, 7.6885860616231154],
					[64.681563705014057, 7.6886249563540581],
					[64.681494204920881, 7.6889277560445635],
					[64.681494204079542, 7.6889277623729591],
					[64.681494202378232, 7.6889277729347238],
					[64.681494200639847, 7.6889277793064439],
					[64.681472001600341, 7.6891527726811848],
					[64.681441503152342, 7.6893221659686608],
					[64.681441503152342, 7.6893221659686608],
					[64.681441503170888, 7.6893221680636694],
					[64.681388704154116, 7.6896499586180322],
					[64.681380407251353, 7.6896805486616593],
					[64.681380403774483, 7.6896805614049697],
					[64.681380401305915, 7.6896805866750082],
					[64.681380399623109, 7.6896805993316848],
					[64.681380400188942, 7.6896971862816716],
					[64.681372002292818, 7.6897610745880689],
					[64.681327601944105, 7.6900916730874949],
					[64.681327601944105, 7.6900916730874949],
					[64.681327601065604, 7.6900916752257995],
					[64.681313700722555, 7.6902082766315703],
					[64.681313700815238, 7.6902082871065849],
					[64.681313701019178, 7.6902083101516157],
					[64.681313701130435, 7.69020832272163],
					[64.681327599799303, 7.6903278139132603],
					[64.68133590029673, 7.6906306039847774],
					[64.681335900333821, 7.690630608174799],
					[64.681335900389413, 7.6906306144598027],
					[64.681335901304976, 7.6906306165115383],
					[64.681344199446499, 7.6907306013142804],
					[64.681335900701754, 7.690849983822682],
					[64.681313701293007, 7.6910832802599494],
					[64.681297002323959, 7.6912582735744612],
					[64.681258102782721, 7.6914860659877933],
					[64.681258101978315, 7.6914860765060498],
					[64.681258100350917, 7.6914860954475666],
					[64.681258100443529, 7.6914861059225661],
					[64.681266499924462, 7.6917833051569549],
					[64.681266499942964, 7.6917833072519723],
					[64.681266499979998, 7.6917833114419665],
					[64.681266499979998, 7.6917833114419665],
					[64.681283100143474, 7.6920500122356499],
					[64.681283100161991, 7.6920500143306478],
					[64.681283101077568, 7.6920500163824075],
					[64.681283101077568, 7.6920500163824075],
					[64.68130540095278, 7.6923056175057747],
					[64.681305400971269, 7.6923056196007744],
					[64.681305401905362, 7.692305623747564],
					[64.681305401942367, 7.6923056279375643],
					[64.681327602055305, 7.6924694276709635],
					[64.681327602055305, 7.6924694276709635],
					[64.681327602073821, 7.6924694297659659],
					[64.681327602073821, 7.6924694297659659],
					[64.681372001966139, 7.6927639294701162],
					[64.681380402133883, 7.6928250269258269],
					[64.681380403104953, 7.6928250352626586],
					[64.681380405925609, 7.6928250497980866],
					[64.681380407775208, 7.6928250559966997],
					[64.681388707695518, 7.6928528565301475],
					[64.681388708611081, 7.6928528585819427],
					[64.681388708611081, 7.6928528585819427],
					[64.681388708629584, 7.6928528606769495],
					[64.68140260508369, 7.692897246891639],
					[64.681458100938272, 7.6932139228581828],
					[64.681463699715493, 7.6933444085244016],
					[64.681463699752499, 7.6933444127144277],
					[64.681463700686493, 7.6933444168612608],
					[64.681463700723484, 7.6933444210513064],
					[64.681480400192413, 7.6935083155244719],
					[64.681488699713086, 7.6936833079094518],
					[64.681494200118664, 7.69386940302401],
					[64.681494200090526, 7.6940666993838169],
					[64.681494200127517, 7.6940667035738493],
					[64.681494200182968, 7.6940667098589186],
					[64.68149420021993, 7.6940667140489509],
					[64.681502600436701, 7.6941889079423822],
					[64.681502599999234, 7.6943916998432575],
					[64.681502600300533, 7.6945999997495829],
					[64.681502600337495, 7.6946000039396374],
					[64.681502600411392, 7.6946000123197269],
					[64.681502601345414, 7.6946000164666071],
					[64.68151089956865, 7.6947056074575633],
					[64.681510900251467, 7.6949138990091352],
					[64.68151090028843, 7.6949139031991711],
					[64.681510900343852, 7.6949139094842458],
					[64.681510900362326, 7.6949139115792651],
					[64.681524800181649, 7.6951500055948534],
					[64.681524799948861, 7.6954388997328191],
					[64.681524800004269, 7.6954389060178983],
					[64.681524801012074, 7.6954389185449106],
					[64.681524801964542, 7.6954389247868429],
					[64.681563701382117, 7.6957528208667823],
					[64.681580400783332, 7.6959611151021692],
					[64.681580400820238, 7.6959611192922379],
					[64.681580401772692, 7.6959611255342004],
					[64.681580401809597, 7.6959611297242505],
					[64.681655400221715, 7.696466714793301],
					[64.681655399566907, 7.6964806002998829],
					[64.681655411652855, 7.6964806479254655],
					[64.681655457261627, 7.6964807316672328],
					[64.681655489887333, 7.6964807678265315],
					[64.681663708885239, 7.6964862122353557],
					[64.681708101192314, 7.6970000167172845],
					[64.681708101210759, 7.6970000188123189],
					[64.681708101247651, 7.6970000230023894],
					[64.681708101247651, 7.6970000230023894],
					[64.681724800963238, 7.6971389208343926],
					[64.681755399624478, 7.6975222137441195],
					[64.681769200182444, 7.6977528056333915],
					[64.681769199746029, 7.6978110997240403],
					[64.681769199782892, 7.697811103914141],
					[64.681769199838186, 7.6978111101992628],
					[64.681769199875063, 7.6978111143893635],
					[64.681785900404819, 7.6980472096254831],
					[64.681794200037459, 7.6983167022870118],
					[64.681794200166081, 7.6985693960401225],
					[64.681785899594516, 7.6988221922899287],
					[64.681785899612919, 7.6988221943849711],
					[64.681785899649768, 7.6988221985750771],
					[64.681785899668185, 7.6988222006701195],
					[64.681785899675262, 7.6988416991029274],
					[64.681785900288659, 7.6990916910690395],
					[64.681769200621076, 7.6992916782219973],
					[64.68174700143696, 7.699472176688543],
					[64.681747000613584, 7.6994721851117154],
					[64.68174699986379, 7.6994722019150732],
					[64.681746999937431, 7.6994722102952347],
					[64.681755400001975, 7.6996194112478982],
					[64.681755400954216, 7.6996194174900339],
					[64.681755401961752, 7.69961943001731],
					[64.681755402895575, 7.6996194341644246],
					[64.681799802664059, 7.6998722343817922],
					[64.681799806381093, 7.6998722488752014],
					[64.681799814693747, 7.6998722757240143],
					[64.681799821083445, 7.6998722879934389],
					[64.681916517298163, 7.7001083814453688],
					[64.681922013979616, 7.7001222741001483],
					[64.681922052078761, 7.7001223204816194],
					[64.681922148669855, 7.7001223850204443],
					[64.681922208040504, 7.7001224010397671],
					[64.682350007572907, 7.7001057992510447],
					[64.68277780712863, 7.700089100447828],
					[64.683205606104636, 7.7000751992837762],
					[64.683205644622376, 7.7000751911502938],
					[64.683205715231409, 7.7000751584229326],
					[64.683205747341049, 7.7000751359241919],
					[64.683241843809284, 7.7000362395576643],
					[64.683258435934533, 7.7000195461304664],
					[64.683297330405154, 7.6999862524296772],
					[64.683297332180842, 7.6999862502485561],
					[64.683297335750609, 7.699986247981478],
					[64.683297338423358, 7.6999862457573762],
					[64.683402934016016, 7.6998862497840976],
					[64.683480728208664, 7.699819552295887],
					[64.683572327215657, 7.6997445547987793],
					[64.683572328112717, 7.6997445547557897],
					[64.683572328991346, 7.69974455261762],
					[64.683572329888406, 7.6997445525746313],
					[64.683630726641269, 7.6996945547195192],
					[64.683708420998485, 7.6996334595196805],
					[64.683739013915527, 7.6996112651646484],
					[64.683883404739319, 7.6995168707406254],
					[64.683922298801932, 7.6994945737646869],
					[64.683922302371684, 7.6994945714974694],
					[64.683922306838525, 7.6994945691872738],
					[64.683922310408292, 7.6994945669200758],
					[64.683930710424647, 7.6994890665850839],
					[64.683930713994457, 7.6994890643178833],
					[64.68393072113399, 7.6994890597834837],
					[64.683930724685354, 7.6994890554210764],
					[64.683944515758881, 7.6994779642062632],
					[64.684097304609409, 7.6993834697042907],
					[64.684097306403487, 7.6993834696182883],
					[64.684097306403487, 7.6993834696182883],
					[64.684097307300519, 7.6993834695752881],
					[64.68415010536367, 7.69935016977447],
					[64.684233398323684, 7.6993001733437989],
					[64.684280692755081, 7.6992751777867365],
					[64.684280696324862, 7.6992751755194782],
					[64.684280700773272, 7.6992751711139791],
					[64.684280704361456, 7.6992751709419585],
					[64.684372300394756, 7.6992195733665767],
					[64.684463995878033, 7.69916957571197],
					[64.684463996775094, 7.6991695756689609],
					[64.68446400034486, 7.6991695734016545],
					[64.684464002120535, 7.699169571220386],
					[64.684525101834083, 7.6991334724246183],
					[64.68452510271274, 7.6991334702863519],
					[64.684525106282493, 7.6991334680190553],
					[64.684525108076599, 7.6991334679330343],
					[64.684594492613982, 7.6990890776414282],
					[64.684647277850615, 7.6990668847611587],
					[64.684647279626304, 7.6990668825798672],
					[64.684647284111534, 7.6990668823648027],
					[64.684647285887209, 7.699066880183512],
					[64.684822280051009, 7.6989834821802274],
					[64.684836174115219, 7.698977986352185],
					[64.684836174993876, 7.6989779842138892],
					[64.684836177684986, 7.6989779840848414],
					[64.684836178582046, 7.6989779840418242],
					[64.684913978792338, 7.6989445840453339],
					[64.684913979689398, 7.6989445840023176],
					[64.685027879997222, 7.6988945822876751],
					[64.685027882688374, 7.6988945821586157],
					[64.685027887155158, 7.6988945798482264],
					[64.68502788894925, 7.6988945797621886],
					[64.685044488320216, 7.6988862793798321],
					[64.685219477906969, 7.698802983988184],
					[64.685263968976358, 7.6987862878764624],
					[64.685341767222511, 7.6987584879408493],
					[64.685341769913663, 7.6987584878117739],
					[64.685341774380447, 7.6987584855013349],
					[64.685341777071599, 7.6987584853722613],
					[64.685408369198996, 7.6987307888457757],
					[64.685494461423318, 7.6987029890595737],
					[64.685494468599671, 7.698702988715362],
					[64.685494482897141, 7.6987029817409471],
					[64.685494489158046, 7.6987029793444126],
					[64.68560008338406, 7.6986501813836066],
					[64.685713968810845, 7.6986029888958329],
					[64.685800061024679, 7.6985751904762045],
					[64.685800063715831, 7.6985751903471087],
					[64.685800069079662, 7.6985751879935478],
					[64.685800070873753, 7.6985751879074868],
					[64.685858362591929, 7.698552990890728],
					[64.685936143667206, 7.6985307958540741],
					[64.686005632309076, 7.698519597170967],
					[64.68600563320615, 7.698519597127933],
					[64.686005634103182, 7.6985195970848981],
					[64.686102834360184, 7.6985029979796948],
					[64.686102840621075, 7.6985029955830786],
					[64.686102854058305, 7.6985029928421662],
					[64.686102861216241, 7.6985029904025151],
					[64.686163961341222, 7.6984834903015029],
					[64.686163962238268, 7.6984834902584671],
					[64.686163964910961, 7.6984834880339825],
					[64.686163965808021, 7.6984834879909458],
					[64.686211166123428, 7.69846688927292],
					[64.686211167002028, 7.6984668871344999],
					[64.686211167899074, 7.698466887091465],
					[64.686211168796106, 7.6984668870484292],
					[64.686355660872053, 7.6984140904072804],
					[64.686386153259591, 7.6984057935701786],
					[64.686386162193202, 7.6984057889489801],
					[64.686386180994276, 7.6984057838543833],
					[64.686386189927859, 7.6984057792331875],
					[64.686408389718153, 7.6983945785883394],
					[64.686408392390888, 7.6983945763638042],
					[64.686408396857658, 7.6983945740532125],
					[64.686408400445856, 7.6983945738810569],
					[64.686500096155967, 7.6983418758512361],
					[64.686591791768478, 7.6982945775376104],
					[64.686591800702104, 7.6982945729163603],
					[64.686591819429452, 7.6982945594399714],
					[64.686591828363078, 7.6982945548187018],
					[64.686608407299914, 7.6982807706322109],
					[64.686638983333964, 7.698266882898519],
					[64.686638986903716, 7.6982668806309293],
					[64.686638994940267, 7.6982668760527053],
					[64.686638999407066, 7.698266873742071],
					[64.686775096912243, 7.6981890748446693],
					[64.686836195897357, 7.6981557756108794],
					[64.68692789641274, 7.6981057757628282],
					[64.686927899085433, 7.6981057735382423],
					[64.686927905327892, 7.6981057690459993],
					[64.686927908019044, 7.6981057689168511],
					[64.686958404181468, 7.6980862706801725],
					[64.687058401064903, 7.6980279732008983],
					[64.687058402840577, 7.6980279710193447],
					[64.687058408204379, 7.6980279686655848],
					[64.687058410877128, 7.6980279664409776],
					[64.687133401388394, 7.6979779732269389],
					[64.687277888704131, 7.6979057789805614],
					[64.68727790031042, 7.6979057721344102],
					[64.6872779199348, 7.6979057586143522],
					[64.687277929746983, 7.697905751854333],
					[64.687316830015604, 7.6978723513021761],
					[64.687316830912664, 7.6978723512591181],
					[64.68731683268831, 7.6978723490775325],
					[64.687316833585342, 7.6978723490344763],
					[64.687425133953468, 7.6977751495410915],
					[64.687425136607715, 7.6977751452209553],
					[64.68742514462582, 7.6977751385469526],
					[64.687425148177113, 7.6977751341837548],
					[64.68747794763506, 7.6977168340938684],
					[64.687477950307766, 7.6977168318691787],
					[64.687477953859073, 7.6977168275059631],
					[64.687477955634705, 7.6977168253243553],
					[64.687500152963892, 7.6976890288930155],
					[64.687630748652197, 7.6975418322295655],
					[64.687722346982312, 7.6974390361542735],
					[64.687783444879884, 7.6973751372242587],
					[64.687783445758498, 7.6973751350856778],
					[64.687783449328208, 7.6973751328178546],
					[64.687783451103883, 7.6973751306361997],
					[64.687911245650568, 7.6972279354442383],
					[64.687936240589011, 7.6972029408690554],
					[64.687936252158394, 7.6972029298314002],
					[64.687936269877952, 7.6972029038235448],
					[64.687936278737752, 7.6972028908196179],
					[64.687941862014441, 7.6971918219957312],
					[64.687972334593852, 7.6971640485942467],
					[64.68797233902373, 7.6971640420922585],
					[64.687972347938882, 7.6971640353748754],
					[64.687972352387192, 7.6971640309684268],
					[64.688086248617452, 7.697030733899104],
					[64.688172346183947, 7.6969390370896438],
					[64.688172347959579, 7.696939034907909],
					[64.688172352407918, 7.6969390305013894],
					[64.688172354183578, 7.6969390283196732],
					[64.688239051978769, 7.6968584305555972],
					[64.688386244912834, 7.6966945373856772],
					[64.68839184042865, 7.6966890421200764],
					[64.688391861680969, 7.6966890096523972],
					[64.688391891571655, 7.6966889390337085],
					[64.688391900210007, 7.6966889008827399],
					[64.68839189982576, 7.6966862069743396],
					[64.688400110017156, 7.6966807663757804],
					[64.688400121604928, 7.6966807574332661],
					[64.68840014208935, 7.696680739677551],
					[64.688400151883044, 7.6966807308212326],
					[64.688544550950326, 7.6965112319560713],
					[64.688614045344849, 7.6964334368584728],
					[64.688697341201475, 7.696350141538181],
					[64.688697342977107, 7.6963501393563858],
					[64.688697347425389, 7.6963501349496655],
					[64.688697349201064, 7.6963501327678685],
					[64.688836248854159, 7.6961945321592573],
					[64.688836253320929, 7.6961945298480918],
					[64.688836258629351, 7.6961945212070368],
					[64.688836263077661, 7.6961945168002863],
					[64.688850135423607, 7.696175153650314],
					[64.688933394308009, 7.6961307755952424],
					[64.688933397877719, 7.6961307733271758],
					[64.68893340681123, 7.6961307687048075],
					[64.688933411278015, 7.6961307663936225],
					[64.689033372248588, 7.6960640919831844],
					[64.689055620804126, 7.6960612991798731],
					[64.689255617102873, 7.6960445990757984],
					[64.689255617999905, 7.6960445990326782],
					[64.689286109322921, 7.6960419005318839],
					[64.689316699790638, 7.6960418996638262],
					[64.689316703378822, 7.6960418994913384],
					[64.689316709658115, 7.6960418991894848],
					[64.689316714143359, 7.6960418989738733],
					[64.689475007151444, 7.6960308009543095],
					[64.689505582368625, 7.6960308008045262],
					[64.689536065111824, 7.6960362980081856],
					[64.689536083052715, 7.6960362971457315],
					[64.689536120747007, 7.6960362974302177],
					[64.68953613868787, 7.6960362965677644],
					[64.689688933693731, 7.6960057971541715],
					[64.689727827165768, 7.6960001976546506],
					[64.689772221859101, 7.6959945999341395],
					[64.689911120158371, 7.6959807983857615],
					[64.689911121055388, 7.695980798342636],
					[64.689911123746526, 7.6959807982132613],
					[64.689911124643558, 7.6959807981701376],
					[64.689955625204547, 7.6959751973836941],
					[64.689955635969056, 7.6959751968661871],
					[64.689955655667106, 7.6959751917260837],
					[64.689955667310201, 7.6959751890697605],
					[64.690002867373607, 7.6959584892507653],
					[64.690002869149268, 7.6959584870688369],
					[64.690002875410116, 7.6959584846712783],
					[64.690002878101211, 7.6959584845419018],
					[64.690108377581794, 7.6959140850007204],
					[64.690108380254458, 7.6959140827756523],
					[64.690108381151489, 7.6959140827325259],
					[64.690177880895433, 7.6958834837006203],
					[64.690177883568111, 7.6958834814755246],
					[64.690177890725977, 7.6958834790348059],
					[64.690177895192747, 7.6958834767234707],
					[64.690286195098082, 7.6958251757519927],
					[64.690286213825203, 7.6958251622720404],
					[64.690286250327048, 7.6958251290681545],
					[64.69028626632614, 7.6958251115261991],
					[64.690291839249994, 7.6958168513768976],
					[64.690400097619346, 7.6957557738949331],
					[64.69047509499201, 7.6957140766166257],
					[64.690627881152182, 7.6956334844798233],
					[64.690666767022989, 7.6956195882872889],
					[64.690666773283851, 7.6956195858895917],
					[64.690666785805519, 7.6956195810941974],
					[64.690666792066395, 7.6956195786965003],
					[64.690850091845491, 7.6955251771723727],
					[64.690850131019943, 7.6955251417422179],
					[64.690850184964006, 7.6955250510893078],
					[64.690850199770537, 7.6955250000580522],
					[64.690850199660062, 7.695522339437642],
					[64.690872269967429, 7.695514086538763],
					[64.690872279816432, 7.6955140839684528],
					[64.690872297683399, 7.6955140747226176],
					[64.690872308411002, 7.695514070013421],
					[64.69103340813173, 7.695411268464956],
					[64.691033412580026, 7.6954112640577153],
					[64.691033424186173, 7.6954112572095328],
					[64.691033428634469, 7.695411252802292],
					[64.691069518155388, 7.6953807614168657],
					[64.691208402187939, 7.695294571601683],
					[64.691291788590476, 7.695247379615866],
					[64.691400080227396, 7.6952001822311162],
					[64.691400082021488, 7.6952001821448093],
					[64.691400085609686, 7.6952001819721909],
					[64.691400088282307, 7.6952001797469487],
					[64.691519488182095, 7.6951418802879994],
					[64.691519489060695, 7.6951418781490508],
					[64.69158068660667, 7.69511128074687],
					[64.691741784348679, 7.6950362827044652],
					[64.691772282806937, 7.6950223818591024],
					[64.691772283703983, 7.6950223818159422],
					[64.691772287273665, 7.6950223795474892],
					[64.691772288170739, 7.6950223795043282],
					[64.691947276196188, 7.6949362850525596],
					[64.691963962907053, 7.6949307896687458],
					[64.691963970064918, 7.6949307872276078],
					[64.691963984362175, 7.6949307802494875],
					[64.691963992417101, 7.6949307777651876],
					[64.692147288240108, 7.6948362790860578],
					[64.692183368295545, 7.6948195889260109],
					[64.692352834978621, 7.6947751976826622],
					[64.692413918109182, 7.6947695993890779],
					[64.692413922594397, 7.6947695991732283],
					[64.692413930649309, 7.6947695966888352],
					[64.692413936031556, 7.6947695964298157],
					[64.692475036179715, 7.6947584974948482],
					[64.692475037076761, 7.6947584974516765],
					[64.692475039749439, 7.6947584952262984],
					[64.692475040646471, 7.6947584951831285],
					[64.692566740657227, 7.6947390964775035],
					[64.692566745123969, 7.6947390941657732],
					[64.692566753197369, 7.6947390937772298],
					[64.692566755870033, 7.6947390915518437],
					[64.692633349592668, 7.6947195947216089],
					[64.69271944338594, 7.6947001950981502],
					[64.692772240556707, 7.6946890950006619],
					[64.692772243247802, 7.6946890948711433],
					[64.692772245938954, 7.6946890947416229],
					[64.692772246835986, 7.6946890946984503],
					[64.692863947262964, 7.6946668942056027],
					[64.692863948159982, 7.6946668941624283],
					[64.692863949057028, 7.6946668941192531],
					[64.692963943925506, 7.6946418955206202],
					[64.692977839180756, 7.694639096383864],
					[64.692977840974834, 7.6946390962975135],
					[64.692977843647498, 7.6946390940720821],
					[64.692977846338621, 7.6946390939425555],
					[64.693083345988896, 7.6946140943720032],
					[64.693083346885942, 7.6946140943288261],
					[64.693083347782974, 7.69461409428565],
					[64.693083348680048, 7.6946140942424721],
					[64.693183348918126, 7.6945890949792837],
					[64.693205648971428, 7.6945834945807663],
					[64.693305647389721, 7.6945584930776612],
					[64.693388944803047, 7.6945390949429662],
					[64.693388946597139, 7.6945390948566059],
					[64.693388951063881, 7.694539092544753],
					[64.693388952857958, 7.6945390924583936],
					[64.693450046693613, 7.6945223943394083],
					[64.693527841558222, 7.6945057959417227],
					[64.693527843352356, 7.6945057958553598],
					[64.693527846043452, 7.6945057957258172],
					[64.693527846922009, 7.6945057935866696],
					[64.693597246411755, 7.6944890959842107],
					[64.693694444425986, 7.6944668957603604],
					[64.693755642797313, 7.6944529957482359],
					[64.693808341228802, 7.6944418952822149],
					[64.693808342125863, 7.6944418952390308],
					[64.693808343919926, 7.6944418951526643],
					[64.693808344817, 7.6944418951094784],
					[64.693977842757718, 7.6944029952299768],
					[64.694016741984242, 7.6943945949655559],
					[64.694016742881274, 7.69439459492237],
					[64.69420564262245, 7.6943529947676828],
					[64.694205648004726, 7.6943529945085567],
					[64.694205658750747, 7.6943529918943092],
					[64.694205664114534, 7.6943529895391851],
					[64.694222255683485, 7.6943473930587238],
					[64.694427847002672, 7.6942973935989025],
					[64.694427847899675, 7.694297393555714],
					[64.694450048318785, 7.6942918939037064],
					[64.694450049215803, 7.6942918938605178],
					[64.694450050112863, 7.6942918938173257],
					[64.694633340941621, 7.6942445965603561],
					[64.694650031645835, 7.694241897294896],
					[64.69465003613108, 7.6942418970789355],
					[64.69465004508298, 7.6942418945509647],
					[64.694650048671164, 7.6942418943781972],
					[64.694672247303018, 7.6942362940671352],
					[64.694838944331494, 7.6941973943627069],
					[64.694877840858425, 7.6941890964029165],
					[64.695052840396983, 7.6941529962606676],
					[64.695052842191089, 7.6941529961742763],
					[64.695052843966693, 7.6941529939918212],
					[64.695052845779259, 7.6941529960014927],
					[64.69510004208135, 7.6941418951253979],
					[64.695258337924685, 7.6941112959643254],
					[64.6952583424099, 7.6941112957483391],
					[64.695258348670677, 7.6941112933498772],
					[64.695258353155907, 7.6941112931338909],
					[64.695319451427622, 7.6940945947354686],
					[64.695463948421065, 7.6940584944080932],
					[64.695463949318096, 7.694058494364894],
					[64.695463950215157, 7.6940584943216939],
					[64.695550049736212, 7.6940362949013785],
					[64.695550052408862, 7.6940362926756736],
					[64.695550055997032, 7.6940362925028731],
					[64.695550057772621, 7.6940362903203701],
					[64.695641747341867, 7.6940084951522616],
					[64.695672234564441, 7.6940029964389378],
					[64.695672239946717, 7.6940029961797265],
					[64.695672249795692, 7.694002993608394],
					[64.695672255159451, 7.6940029912530727],
					[64.695769452179306, 7.6939751927214015],
					[64.69587784992693, 7.6939473934619809],
					[64.695877856187735, 7.693947391063424],
					[64.695877867830788, 7.6939473884056451],
					[64.695877874091593, 7.6939473860070695],
					[64.695891764387625, 7.6939418896648952],
					[64.695991749722481, 7.6939140947868214],
					[64.696083346823656, 7.6938918938906324],
					[64.696083347720702, 7.6938918938474243],
					[64.696083350411811, 7.6938918937178054],
					[64.696083351308872, 7.693891893674599],
					[64.696219451061268, 7.6938557930402505],
					[64.69621945375242, 7.693855792910627],
					[64.696219458219147, 7.6938557905984144],
					[64.696219460910257, 7.693855790468791],
					[64.696280657407968, 7.6938362928727537],
					[64.696388954120579, 7.6938057921688401],
					[64.696388955914657, 7.6938057920824212],
					[64.696388958605795, 7.6938057919527898],
					[64.696388960399887, 7.6938057918663709],
					[64.696441760145873, 7.6937890912540752],
					[64.696486158679804, 7.6937751901194451],
					[64.69663335810796, 7.6937307902546488],
					[64.696633360799069, 7.6937307901250112],
					[64.696633366181345, 7.6937307898657341],
					[64.696633368853938, 7.6937307876399119],
					[64.696663959266573, 7.6937195919485477],
					[64.696686148012716, 7.6937140940085289],
					[64.696686149806794, 7.6937140939221003],
					[64.696686155170539, 7.6937140915666307],
					[64.696686156964631, 7.6937140914802038],
					[64.696883328875799, 7.6936557997901005],
					[64.696891700033447, 7.6936557991459305],
					[64.696891770677837, 7.6936557705773847],
					[64.696891871211378, 7.6936556713646098],
					[64.696891900203468, 7.693655600763595],
					[64.696891900317141, 7.6936529688977435],
					[64.696908332813592, 7.6936501975830387],
					[64.69690834086849, 7.6936501950978693],
					[64.696908356099726, 7.6936501922669898],
					[64.696908364154581, 7.6936501897818408],
					[64.697088943988135, 7.6935890957672086],
					[64.69711392241544, 7.6935862985286088],
					[64.697113928694733, 7.693586298226081],
					[64.697113942131878, 7.6935862954815804],
					[64.697113948392669, 7.6935862930828298],
					[64.697136147896629, 7.6935807944199706],
					[64.697136149690706, 7.6935807943335321],
					[64.697136154157434, 7.6935807920212156],
					[64.697136155951512, 7.6935807919347772],
					[64.697297255957295, 7.6935334930108814],
					[64.697297260424008, 7.6935334906985418],
					[64.697297267581831, 7.6935334882565218],
					[64.697297272067061, 7.6935334880404174],
					[64.697333356000769, 7.6935195923836917],
					[64.697388938717722, 7.693508496388346],
					[64.69738894228739, 7.6935084941192153],
					[64.697388948566697, 7.6935084938166636],
					[64.697388952154839, 7.6935084936437788],
					[64.697502852312226, 7.6934779929673622],
					[64.697502853209244, 7.6934779929241399],
					[64.69756395319834, 7.6934612936728337],
					[64.697563954095372, 7.6934612936296123],
					[64.697563954973916, 7.6934612914901308],
					[64.697563955870947, 7.6934612914469067],
					[64.697630655283405, 7.69344189170417],
					[64.697700049683334, 7.6934223934603727],
					[64.697783345147329, 7.6934029949239351],
					[64.697783347838467, 7.6934029947942575],
					[64.697783354099258, 7.6934029923954022],
					[64.697783356790396, 7.6934029922657245],
					[64.697905653177131, 7.6933668934681538],
					[64.698005629013409, 7.6933418987157109],
					[64.698119409655831, 7.6933363002748996],
					[64.69811941862622, 7.6933362998426125],
					[64.698119438342644, 7.6933362967952625],
					[64.698119447294587, 7.6933362942666745],
					[64.698233327310589, 7.693308498373832],
					[64.698341702342049, 7.6933058006327268],
					[64.698349999903471, 7.6933057992278391],
					[64.698350011564997, 7.6933057986658477],
					[64.69835003488808, 7.6933057975418633],
					[64.698350045634101, 7.6933057949267845],
					[64.698455638403999, 7.6932807972681445],
					[64.698547228792719, 7.6932668974673533],
					[64.698586128438663, 7.6932612980610484],
					[64.698586132923879, 7.6932612978448862],
					[64.698586141875779, 7.6932612953162307],
					[64.698586147239553, 7.6932612929604813],
					[64.698677836727455, 7.6932390978631418],
					[64.698761126418646, 7.6932279971057635],
					[64.698761127315706, 7.6932279970625306],
					[64.698822226929522, 7.6932195973721251],
					[64.6988222287236, 7.6932195972856583],
					[64.698822231414738, 7.6932195971559549],
					[64.698822233208816, 7.6932195970694872],
					[64.698905632801697, 7.6932057980146977],
					[64.698905633698701, 7.6932057979714648],
					[64.698905638165442, 7.6932057956589297],
					[64.698905639062488, 7.6932057956156958],
					[64.698975031699874, 7.6931918976836293],
					[64.699127812815888, 7.6931723991512158],
					[64.699197197084629, 7.693172400081191],
					[64.699280593296322, 7.6931752008506624],
					[64.699280602266711, 7.6931752004182998],
					[64.699280620207546, 7.6931751995535738],
					[64.699280629159489, 7.6931751970248214],
					[64.699355618605509, 7.6931640994822095],
					[64.699416708403064, 7.6931612997007912],
					[64.699508306091914, 7.6931584996381046],
					[64.699508312371179, 7.6931584993354445],
					[64.699508324929766, 7.6931584987301207],
					[64.699508332087575, 7.6931584962878157],
					[64.699577829286866, 7.6931473969694393],
					[64.699638924412909, 7.6931390998086648],
					[64.699744420827514, 7.6931279996513693],
					[64.699744426191288, 7.6931279972955098],
					[64.699744436058737, 7.6931279968198831],
					[64.69974444144097, 7.6931279965604515],
					[64.699797235797064, 7.6931168967988715],
					[64.699852826126985, 7.6931084978088728],
					[64.700027822018441, 7.6930890997038492],
					[64.700027825588123, 7.6930890974344388],
					[64.700027832764434, 7.6930890970885155],
					[64.700027837249664, 7.6930890968723116],
					[64.700072236688385, 7.6930807967513744],
					[64.700072236688385, 7.6930807967513744],
					[64.700072239379509, 7.6930807966216506],
					[64.700211138546322, 7.6930529953893707],
					[64.7002111403404, 7.693052995302887],
					[64.700211141237432, 7.693052995259646],
					[64.700211142134478, 7.6930529952164024],
					[64.700250038590369, 7.6930445953113047],
					[64.700280634370458, 7.6930390976777208],
					[64.700447228919529, 7.6930112990106609],
					[64.700469425292454, 7.6930084986453595],
					[64.700491723956105, 7.6930057982129894],
					[64.700683322571308, 7.6929834990542556],
					[64.700683325262432, 7.6929834989245194],
					[64.700683329747662, 7.6929834987082915],
					[64.700683332420255, 7.6929834964820589],
					[64.700700032074209, 7.6929807974621855],
					[64.700700033868316, 7.6929807973756938],
					[64.700700038334986, 7.6929807950629483],
					[64.700700039232046, 7.6929807950197029],
					[64.700713936250892, 7.6929779962007157],
					[64.70091943219137, 7.6929445962204177],
					[64.700919492034046, 7.6929445639715972],
					[64.70091957642029, 7.6929444655197186],
					[64.700919600066769, 7.6929443993599458],
					[64.700919600435782, 7.6929418992272502],
					[64.700927800198116, 7.6929419003198118],
					[64.700927808271487, 7.6929418999305863],
					[64.700927825296745, 7.692941897012374],
					[64.700927833370116, 7.6929418966231484],
					[64.701011130637539, 7.6929279977302114],
					[64.701141730087386, 7.6929084979650826],
					[64.70114179080862, 7.6929084635761091],
					[64.701141876091839, 7.6929083650800765],
					[64.701141899756806, 7.6929083010162431],
					[64.701141899829054, 7.6929057673184822],
					[64.701347234888132, 7.6928695969670047],
					[64.701347237579242, 7.6928695968372551],
					[64.701347244737093, 7.6928695943946819],
					[64.701347248325263, 7.6928695942216798],
					[64.701369392263203, 7.6928641072407293],
					[64.701386036704392, 7.6928695907833893],
					[64.701386048384435, 7.6928695923176837],
					[64.701386069968933, 7.6928695975693282],
					[64.701386080751902, 7.6928695991468947],
					[64.701591499599331, 7.6928890824358573],
					[64.701591500267625, 7.6928917000385093],
					[64.701591528705066, 7.6928917699811858],
					[64.7015916292019, 7.6928918700089959],
					[64.701591700364204, 7.692891900137381],
					[64.701599989848432, 7.6928918989842074],
					[64.701652779039321, 7.6928973996757053],
					[64.701652779936396, 7.6928973996324546],
					[64.701652781730473, 7.6928973995459549],
					[64.701652782627491, 7.6928973995027059],
					[64.701813883211443, 7.6929112990971342],
					[64.70181388681813, 7.6929113010207191],
					[64.701813895788518, 7.6929113005882215],
					[64.70181390027372, 7.6929113003719705],
					[64.701819399672829, 7.6929112995064193],
					[64.701827799548326, 7.6929112993284292],
					[64.70182780313651, 7.6929112991554272],
					[64.701827810331338, 7.6929113009060144],
					[64.701827813022462, 7.6929113007762631],
					[64.702033306930417, 7.6928973993621215],
					[64.702041699629333, 7.6928973995251155],
					[64.702049999896062, 7.6928973999497137],
					[64.702269400003985, 7.6928973992415006],
					[64.702277798981925, 7.6928973991009491],
					[64.702491685816142, 7.6929002001185962],
					[64.702513870571607, 7.6929029983143158],
					[64.702713848150921, 7.6929362930066993],
					[64.702766619903585, 7.6929557834201354],
					[64.702941609417934, 7.6930445770126266],
					[64.702941613940126, 7.693044580989735],
					[64.702941622947549, 7.6930445847506004],
					[64.702941628348299, 7.6930445865877877],
					[64.703049920363355, 7.6930862842300183],
					[64.703169311873538, 7.6931445801855567],
					[64.703169312770598, 7.6931445801423104],
					[64.70316931366763, 7.6931445800990659],
					[64.703169314564676, 7.6931445800558214],
					[64.703347112843502, 7.6932279799760277],
					[64.703391610883401, 7.6932501796933339],
					[64.703391611780461, 7.6932501796500912],
					[64.703391612677493, 7.6932501796068493],
					[64.703552708887216, 7.693327977747634],
					[64.703613803934999, 7.693361275489333],
					[64.70361380572912, 7.6933612754028537],
					[64.703613809335764, 7.6933612773266455],
					[64.703613811148358, 7.6933612793368935],
					[64.703636010802626, 7.6933723796842415],
					[64.703841602696542, 7.6934751759401161],
					[64.703941595795712, 7.6935362707987993],
					[64.703941597608292, 7.6935362728090819],
					[64.703941601214979, 7.6935362747328977],
					[64.703941603009071, 7.6935362746464309],
					[64.704072097777612, 7.6936084712135981],
					[64.704247093285886, 7.6937195690227007],
					[64.704247095098467, 7.6937195710330188],
					[64.704247099602114, 7.6937195729136549],
					[64.704247102311754, 7.6937195748807437],
					[64.704291598913187, 7.6937445723896856],
					[64.704405496178694, 7.6938140717092667],
					[64.704405497075754, 7.693814071666039],
					[64.704405498888306, 7.6938140736763758],
					[64.704405500682398, 7.693814073589925],
					[64.704522094166549, 7.6938807690175253],
					[64.704566587176956, 7.6939112652664123],
					[64.704566588074016, 7.6939112652231909],
					[64.704566589886582, 7.6939112672335463],
					[64.704566590783642, 7.6939112671903223],
					[64.70474159099119, 7.6940251670522608],
					[64.704741591906711, 7.6940251691058554],
					[64.704741592803785, 7.6940251690626349],
					[64.70488599341121, 7.6941168696818787],
					[64.704885997914886, 7.6941168715626223],
					[64.7048860078378, 7.6941168773777369],
					[64.70488601323855, 7.6941168792152643],
					[64.704972091846855, 7.6941584710478779],
					[64.705147065707649, 7.6943001494972707],
					[64.705191559078685, 7.6943445425465153],
					[64.705191563600877, 7.6943445465241647],
					[64.705191576251863, 7.6943445564034958],
					[64.705191581671102, 7.6943445603379548],
					[64.705222082408298, 7.6943668606985254],
					[64.705222083323832, 7.6943668627521653],
					[64.705222085136398, 7.6943668647626007],
					[64.705222086033444, 7.6943668647193908],
					[64.705422085820814, 7.6945057650559523],
					[64.705422087614892, 7.694505764969545],
					[64.705422091240024, 7.6945057689904592],
					[64.705422093034102, 7.6945057689040501],
					[64.705558193031223, 7.6945918681770396],
					[64.705558195740807, 7.6945918701443299],
					[64.705558201160045, 7.6945918740788715],
					[64.705558203869643, 7.6945918760461431],
					[64.705649889861093, 7.6946418683596702],
					[64.70584157732722, 7.6947918571967788],
					[64.70584157824274, 7.6947918592504827],
					[64.705841580952338, 7.6947918612177935],
					[64.70584158184937, 7.6947918611745987],
					[64.705872069877756, 7.6948140519916501],
					[64.705885958730121, 7.6948279419043022],
					[64.705885965961869, 7.694827947849471],
					[64.705885983135005, 7.6948279617071078],
					[64.705885991263813, 7.6948279676090836],
					[64.706099879886466, 7.6949668604849206],
					[64.706230469731253, 7.6950779522915065],
					[64.706230470628313, 7.6950779522483188],
					[64.706230473337925, 7.6950779542157051],
					[64.706230473337925, 7.6950779542157051],
					[64.70632207344407, 7.6951529554397453],
					[64.706322076153668, 7.6951529574071271],
					[64.706322079760298, 7.6951529593313257],
					[64.706322081572864, 7.6951529613419121],
					[64.706505478220834, 7.6952862591098281],
					[64.706549871238124, 7.6953223533267314],
					[64.706572068396952, 7.6953418500607391],
					[64.706572074731653, 7.6953418560493745],
					[64.70657209009218, 7.6953418678970849],
					[64.70657209730544, 7.6953418717455655],
					[64.706780491907537, 7.6954668675694302],
					[64.706894287233069, 7.6955445648761618],
					[64.706894289942639, 7.6955445668436262],
					[64.706894297155927, 7.6955445706922037],
					[64.706894299865482, 7.6955445726596867],
					[64.706999896383167, 7.6956057709470524],
					[64.70706098898448, 7.6956445661406176],
					[64.707222084766371, 7.6957584621377588],
					[64.707222085681877, 7.6957584641915995],
					[64.707222088391475, 7.6957584661591065],
					[64.707222089288493, 7.6957584661159411],
					[64.707230444874256, 7.6957640367563718],
					[64.707235921341493, 7.6957750892934795],
					[64.707235932272155, 7.6957751076485925],
					[64.707235958600279, 7.6957751420459637],
					[64.707235974876284, 7.6957751559480476],
					[64.707449874833145, 7.6959473564424181],
					[64.707449875730163, 7.6959473563992598],
					[64.707449879336778, 7.6959473583236413],
					[64.707449880252312, 7.6959473603775042],
					[64.707572079960002, 7.696039059486786],
					[64.707572081772568, 7.6960390614975038],
					[64.707572085379184, 7.6960390634219067],
					[64.70757208627623, 7.6960390633787483],
					[64.707680485561056, 7.6961140647213471],
					[64.707680489167714, 7.696114066645765],
					[64.707680492774315, 7.6961140685701857],
					[64.707680496380959, 7.6961140704946054],
					[64.707894296291869, 7.6962445719073402],
					[64.707894302608096, 7.6962445757994153],
					[64.707894317913158, 7.6962445813570159],
					[64.707894325126432, 7.6962445852059371],
					[64.707908225218631, 7.6962501852291085],
					[64.707908227012737, 7.6962501851428042],
					[64.707908230619353, 7.6962501870672577],
					[64.707908233310462, 7.6962501869378057],
					[64.707947129636253, 7.6962640861819756],
					[64.708130526749031, 7.6963362854722162],
					[64.708130528561583, 7.6963362874829953],
					[64.708130530355646, 7.6963362873966972],
					[64.708174922881909, 7.6963529855404254],
					[64.7083582138781, 7.6964362810767799],
					[64.708458211103164, 7.6964862793489868],
					[64.70845822280161, 7.6964862829823115],
					[64.708458248011041, 7.6964862922597916],
					[64.708458260606534, 7.6964862958499909],
					[64.708472139602677, 7.6964890921062521],
					[64.708580517332592, 7.6965362813095908],
					[64.708655512991115, 7.6965723791952083],
					[64.708655522913929, 7.6965723850120167],
					[64.708655543619685, 7.6965723924082372],
					[64.70865555440264, 7.6965723939876485],
					[64.708738849629086, 7.6965918941482458],
					[64.70880822130421, 7.6966112856816968],
					[64.709022094196087, 7.6967362707913143],
					[64.709030400074994, 7.696741806721425],
					[64.709030399558145, 7.6967443999206324],
					[64.709030428909315, 7.6967444719405096],
					[64.709030529403478, 7.6967445720097913],
					[64.709030599649523, 7.6967446001023276],
					[64.709038858609972, 7.6967445994721695],
					[64.709213820828268, 7.6968195833301651],
					[64.709213822640791, 7.6968195853410579],
					[64.709213827125978, 7.6968195851253833],
					[64.709213829835576, 7.6968195870931568],
					[64.709258230377188, 7.6968362862891855],
					[64.709258233983789, 7.6968362882138122],
					[64.709258243888158, 7.6968362919336792],
					[64.709258249288851, 7.6968362937720363],
					[64.709311048390077, 7.6968501931945239],
					[64.709411045790105, 7.696877992955959],
					[64.709411047584197, 7.6968779928696911],
					[64.709411052087859, 7.6968779947511994],
					[64.709411053881936, 7.6968779946649333],
					[64.70948055440887, 7.6968945949559311],
					[64.709480570574073, 7.6968945962767172],
					[64.709480604698513, 7.6968945988320243],
					[64.709480621742316, 7.6968945980124932],
					[64.709555617992862, 7.6968862985013464],
					[64.709633288980044, 7.6968808013516519],
					[64.709708264232674, 7.6968945977455032],
					[64.709708303702499, 7.6968945958476267],
					[64.709708380718908, 7.6968945774577087],
					[64.709708418265507, 7.6968945609656849],
					[64.709769494867487, 7.6968501783752652],
					[64.70993058985411, 7.6967919157848126],
					[64.70998319366339, 7.6968168713984344],
					[64.710152675712649, 7.6969501579999173],
					[64.710235965982307, 7.6970168496430098],
					[64.710349858811483, 7.6971307409039786],
					[64.710349859726975, 7.6971307429581008],
					[64.710349864249025, 7.6971307469369652],
					[64.710349866061605, 7.6971307489479592],
					[64.710380465844423, 7.6971584482690218],
					[64.710380516318551, 7.6971584731182867],
					[64.710380620578263, 7.6971584911854629],
					[64.710380676157939, 7.6971584843171206],
					[64.710563945696308, 7.6970834975526543],
					[64.710602766848794, 7.6970808022902357],
					[64.710830510688396, 7.6971751808930051],
					[64.711058199104542, 7.697308472636978],
					[64.711058200917051, 7.6973084746480387],
					[64.711058203608161, 7.6973084745186728],
					[64.711058206317745, 7.6973084764866089],
					[64.711136006060315, 7.697350175118391],
					[64.711136009666916, 7.6973501770432158],
					[64.711136017795624, 7.6973501829470745],
					[64.711136022299243, 7.69735018482878],
					[64.711280522004174, 7.6974112836075861],
					[64.71128052290122, 7.6974112835644668],
					[64.711419322672015, 7.6974695830667743],
					[64.711419323587464, 7.6974695851210084],
					[64.711419328091111, 7.6974695870027521],
					[64.711419329885189, 7.6974695869165162],
					[64.711508224992642, 7.697502985607116],
					[64.711699920398132, 7.697586283131745],
					[64.711699926714303, 7.6975862870246496],
					[64.711699941103859, 7.6975862905295243],
					[64.711699948298616, 7.6975862922819625],
					[64.711730539845561, 7.697594591391745],
					[64.711883232057559, 7.6976501876808259],
					[64.71195273182019, 7.6976751886807993],
					[64.711952737220869, 7.6976751905195187],
					[64.711952746209718, 7.6976751921857733],
					[64.711952750713365, 7.6976751940675854],
					[64.711974939288794, 7.6976807917667758],
					[64.712180519924459, 7.6977612844082159],
					[64.712249913085742, 7.6977945793119558],
					[64.712249916692357, 7.6977945812369164],
					[64.712249924802606, 7.6977945850437512],
					[64.712249929306253, 7.6977945869256041],
					[64.712411020239315, 7.6978557829077161],
					[64.712533210672987, 7.6979168785664074],
					[64.712533214279588, 7.6979168804914044],
					[64.712533222389837, 7.6979168842982952],
					[64.712533225996438, 7.6979168862233127],
					[64.712630515283948, 7.6979557806236922],
					[64.712830505046639, 7.6980640759776966],
					[64.712830511362782, 7.6980640798708748],
					[64.712830524873738, 7.6980640855167044],
					[64.712830532068494, 7.6980640872693398],
					[64.712861019825226, 7.6980751830201921],
					[64.712952707617646, 7.6981223779250358],
					[64.712952709411724, 7.6981223778388363],
					[64.712952710327201, 7.698122379893209],
					[64.712952711224233, 7.6981223798501093],
					[64.713080509902156, 7.6981862783458554],
					[64.713119309829594, 7.6982057796775374],
					[64.713119313417778, 7.6982057795051499],
					[64.713119317921411, 7.6982057813871299],
					[64.713119319733934, 7.6982057833984001],
					[64.713311020031142, 7.6982890820804233],
					[64.713311067666496, 7.6982890902838141],
					[64.713311161837424, 7.6982890836614244],
					[64.713311206578894, 7.6982890689218095],
					[64.713372305746816, 7.6982501696614793],
					[64.713494476756907, 7.6981751879509384],
					[64.713530645298022, 7.6981668948685176],
					[64.71353066497764, 7.6981668876278357],
					[64.713530704299998, 7.6981668689514979],
					[64.713530723045693, 7.6981668575589222],
					[64.713555723432094, 7.6981473584636699],
					[64.713630722719429, 7.6980890582988488],
					[64.713630723598058, 7.6980890561582438],
					[64.713630726289182, 7.6980890560289383],
					[64.713630727186199, 7.6980890559858368],
					[64.713722327322685, 7.6980140540328623],
					[64.713752914345136, 7.6979890653792014],
					[64.713805700058899, 7.6979584731852011],
					[64.713805702731605, 7.6979584709583628],
					[64.713805708095407, 7.6979584686021889],
					[64.713805710768128, 7.6979584663753524],
					[64.713897310097721, 7.6978973676159193],
					[64.713975045249953, 7.6978474079473242],
					[64.714066670106092, 7.6978612986781396],
					[64.71406667190017, 7.6978612985919197],
					[64.714066677282403, 7.6978612983332599],
					[64.714066679076524, 7.6978612982470382],
					[64.714119379052377, 7.6978668991032881],
					[64.714119380846441, 7.6978668990170682],
					[64.714119385331671, 7.6978668988015171],
					[64.71411938714418, 7.6978669008128398],
					[64.714202766309029, 7.697872397591988],
					[64.714272110727762, 7.6978918835015211],
					[64.71439987396397, 7.6979862551929452],
					[64.714424867434857, 7.6980084498219368],
					[64.714424869247381, 7.6980084518332905],
					[64.714424872872399, 7.6980084558560167],
					[64.714424874666506, 7.6980084557698039],
					[64.714438775439376, 7.6980195559295632],
					[64.71443878538058, 7.698019563845687],
					[64.714438806123141, 7.6980195754396776],
					[64.714438816942945, 7.6980195812151289],
					[64.714499836927146, 7.6980473452930118],
					[64.714652605765423, 7.6985111512294981],
					[64.714660904290298, 7.6985528395727441],
					[64.71466091075385, 7.6985528602470028],
					[64.714660929063243, 7.6985529013369929],
					[64.71466093999355, 7.6985529196982281],
					[64.714669219041596, 7.6985639922785349],
					[64.714691506775154, 7.6986361522003479],
					[64.714797005209206, 7.6990833437512185],
					[64.714827604566636, 7.6992222422076395],
					[64.714827607331429, 7.699222250468849],
					[64.71482761104852, 7.6992222649797846],
					[64.714827612897849, 7.6992222711864429],
					[64.714888712894464, 7.6993833704546333],
					[64.714888713809913, 7.6993833725091747],
					[64.714888714743793, 7.6993833766613369],
					[64.714888715659228, 7.6993833787158792],
					[64.714935916164322, 7.6994944777257599],
					[64.714935916182739, 7.699494479823386],
					[64.714980413358859, 7.6995972730074174],
					[64.715041506773503, 7.699775053215733],
					[64.715102603820156, 7.7000778399449716],
					[64.715102603838574, 7.7000778420425906],
					[64.715102603838574, 7.7000778420425906],
					[64.715110899706474, 7.7001167217673467],
					[64.715110900316176, 7.7001305991620272],
					[64.715110918790742, 7.7001306591323857],
					[64.715110987830926, 7.7001307544498756],
					[64.715111038396572, 7.7001307897970452],
					[64.715119266098668, 7.7001334672750898],
					[64.71515542644228, 7.7001944988625448],
					[64.71524152496778, 7.7003500969275613],
					[64.71524159900369, 7.7003501479375727],
					[64.715241757140717, 7.7003501697311876],
					[64.71524184124182, 7.7003501405147734],
					[64.715347340716704, 7.7002445415970797],
					[64.715386239997429, 7.7002057434945321],
					[64.71550013798192, 7.7000973439651332],
					[64.715500142448747, 7.7000973416522882],
					[64.715500148654399, 7.7000973329603966],
					[64.715500153102795, 7.7000973285499041],
					[64.715544548293551, 7.700044535570445],
					[64.715569525082401, 7.7000195585861722],
					[64.715605704594836, 7.6999973702133149],
					[64.715605715304136, 7.6999973634038126],
					[64.715605735788813, 7.6999973456325552],
					[64.71560574470405, 7.6999973389091361],
					[64.715705745408755, 7.6998918373547767],
					[64.715705745408755, 7.6998918373547767],
					[64.715705746305787, 7.6998918373117311],
					[64.715789046269222, 7.6998029365046623],
					[64.715789047166268, 7.6998029364616123],
					[64.715858445996886, 7.6997279358162611],
					[64.715994543074274, 7.6995834395375669],
					[64.716011227712556, 7.6995668553026357],
					[64.716019512088295, 7.6995612657783035],
					[64.71601953434859, 7.699561245822637],
					[64.716019570740656, 7.6995612000057116],
					[64.716019585769416, 7.6995611741014152],
					[64.71602516902125, 7.6995473157188963],
					[64.716177936069897, 7.6994057465680914],
					[64.716177937845572, 7.6994057443842623],
					[64.716177943190957, 7.6994057399304801],
					[64.71617794496666, 7.6994057377466323],
					[64.716233440290722, 7.6993473433946802],
					[64.716339035301147, 7.6992501474460626],
					[64.716339038852482, 7.6992501430783591],
					[64.716339045094941, 7.6992501385814505],
					[64.716339046852198, 7.6992501342998656],
					[64.716369545868332, 7.6992168362566469],
					[64.716452942771255, 7.6991307407413094],
					[64.71649183822835, 7.6990918451740002],
					[64.716597332064296, 7.6989973509721352],
					[64.716658426947191, 7.6989445558116794],
					[64.716683340052285, 7.6989252230400291],
					[64.716872135746669, 7.6989834890449442],
					[64.716902732020714, 7.6989945885182749],
					[64.716902749101322, 7.6989945918953273],
					[64.716902783262583, 7.6989945986493922],
					[64.716902800324775, 7.698994599928688],
					[64.716911069145382, 7.698994599484557],
					[64.717133235181905, 7.6990640889118698],
					[64.717177729592919, 7.6990807878119805],
					[64.717177734993612, 7.6990807896513047],
					[64.717177744879478, 7.699080791275251],
					[64.717177751177175, 7.6990807930715013],
					[64.717352739369474, 7.6991251908285516],
					[64.71743882771527, 7.6991584870701653],
					[64.717438837619568, 7.6991584907919739],
					[64.717438858288375, 7.6991584939968769],
					[64.717438868192659, 7.6991584977186847],
					[64.717574958421849, 7.6991807969855595],
					[64.717688847561448, 7.6992112938900368],
					[64.717688850252557, 7.6992112937608201],
					[64.717688854756176, 7.6992112956432948],
					[64.7176888574473, 7.699211295514079],
					[64.717802755754278, 7.6992362956933498],
					[64.717949955660671, 7.6992695947050205],
					[64.717949956557717, 7.6992695946619483],
					[64.718024956923117, 7.6992862945897276],
					[64.718024957838594, 7.6992862966445008],
					[64.718024961426735, 7.6992862964722182],
					[64.718024963220813, 7.6992862963860782],
					[64.718199963371418, 7.699319595953976],
					[64.718199968772112, 7.699319597793413],
					[64.718199983143165, 7.6993195992021572],
					[64.718199989440862, 7.6993196009985265],
					[64.718252788563916, 7.6993224009632275],
					[64.718252793946178, 7.6993224007048084],
					[64.718252803813641, 7.6993224002310408],
					[64.718252809195889, 7.6993223999726208],
					[64.71843060934647, 7.6993141003197154],
					[64.718430621886625, 7.6993140976188332],
					[64.718430646985354, 7.6993140943149854],
					[64.718430660422584, 7.6993140915710523],
					[64.718475054892224, 7.699300192226687],
					[64.718627849054627, 7.6992612944021541],
					[64.718705648321901, 7.6992418934259215],
					[64.718705650115979, 7.6992418933397744],
					[64.718705652807117, 7.6992418932105533],
					[64.718705653685717, 7.6992418910695832],
					[64.718833353641813, 7.6992057926499822],
					[64.718833359005671, 7.6992057902936253],
					[64.718833368854703, 7.6992057877218949],
					[64.718833373321459, 7.6992057854086147],
					[64.718925073295381, 7.6991695852154995],
					[64.718925091162546, 7.699169575962296],
					[64.718925124187294, 7.6991695554872095],
					[64.718925139344876, 7.699169544265323],
					[64.719008439102524, 7.6990890446831557],
					[64.719008442653887, 7.6990890403149965],
					[64.719008449756515, 7.6990890315786631],
					[64.719008454204896, 7.6990890271674051],
					[64.719147353155165, 7.6989223280062209],
					[64.719161252899909, 7.698905728252436],
					[64.719161254675612, 7.6989057260683316],
					[64.719161258226904, 7.6989057217001262],
					[64.719161260002565, 7.6989057195160218],
					[64.719186257807465, 7.698872322912905],
					[64.719308455067846, 7.6987223267011302],
					[64.719308455946447, 7.6987223245600953],
					[64.719308458619153, 7.6987223223328751],
					[64.719308460394814, 7.6987223201487485],
					[64.719366858715702, 7.6986445212787435],
					[64.719452957719753, 7.6985334237378513],
					[64.719452963028289, 7.6985334150874234],
					[64.719452971009517, 7.6985334042097451],
					[64.719452975439438, 7.6985333977003902],
					[64.719566871889185, 7.6983279027705063],
					[64.719580769971401, 7.6983057069854475],
					[64.719580772588785, 7.6983056984642513],
					[64.719580781448627, 7.6983056854453826],
					[64.719580784084442, 7.6983056790221713],
					[64.719589080873689, 7.6982861849534947],
					[64.719702977950234, 7.6980611894615167],
					[64.719702979725881, 7.6980611872773137],
					[64.719702983277173, 7.6980611829088899],
					[64.719702984137356, 7.6980611786698283],
					[64.719811284327079, 7.6978083800051014],
					[64.719811290421973, 7.6978083587233819],
					[64.719811298163464, 7.6978083205715411],
					[64.71981129977317, 7.6978082995054358],
					[64.719811300247713, 7.6978000823990147],
					[64.719819540611923, 7.6977918406759098],
					[64.719819554817064, 7.6977918232020519],
					[64.719819578723687, 7.6977917863719556],
					[64.719819589322242, 7.6977917669725899],
					[64.719916888586937, 7.697513966393422],
					[64.719916888568491, 7.697513964295414],
					[64.719916890344123, 7.6975139621111586],
					[64.719916890344123, 7.6975139621111586],
					[64.720000187198963, 7.6972583717222127],
					[64.72001688364395, 7.6972194781137704],
					[64.720016886298168, 7.6972194737883468],
					[64.72001689067271, 7.6972194609846278],
					[64.720016893308468, 7.6972194545611892],
					[64.720030792642703, 7.6971694537836015],
					[64.720030792642703, 7.6971694537836015],
					[64.720030792624257, 7.6971694516856042],
					[64.720100191902461, 7.6969111568703186],
					[64.720161290194497, 7.6967250613800884],
					[64.720161291073097, 7.6967250592389185],
					[64.720161292793364, 7.6967250507605485],
					[64.720161293671936, 7.6967250486193803],
					[64.720191892841086, 7.6966000518099671],
					[64.720244591797496, 7.6964139561985148],
					[64.720283491916021, 7.6962833580236172],
					[64.720283491897561, 7.6962833559256012],
					[64.720283492757659, 7.6962833516863736],
					[64.720283493636259, 7.6962833495451806],
					[64.720305793940724, 7.6961944493992771],
					[64.720366891882463, 7.6959639545879783],
					[64.720458490805925, 7.6956611577229532],
					[64.720458495180324, 7.6956611449187751],
					[64.720458499406959, 7.695661115330334],
					[64.720458500174701, 7.6956611006009075],
					[64.720458500289695, 7.6956528560249637],
					[64.720466870627916, 7.6956390030596076],
					[64.720466876796394, 7.6956389901690292],
					[64.720466888254734, 7.6956389665291018],
					[64.720466892629133, 7.6956389537249148],
					[64.720550190235386, 7.6953389619239134],
					[64.720627986846793, 7.6951306692486288],
					[64.720627988622425, 7.6951306670641584],
					[64.720627991258098, 7.6951306606403698],
					[64.720627992118182, 7.695130656401072],
					[64.720650189860336, 7.6950556624555144],
					[64.720680787923555, 7.6949722688025215],
					[64.720755783642559, 7.694766778873106],
					[64.720841876558268, 7.6945944936449049],
					[64.720877974125131, 7.6945306983341126],
					[64.720877975003688, 7.6945306961927979],
					[64.720877977657821, 7.6945306918669729],
					[64.720877979433411, 7.6945306896824439],
					[64.720902978100725, 7.6944806924651523],
					[64.721000176154689, 7.6942972933688631],
					[64.721122352237941, 7.6940695379707869],
					[64.721130710146412, 7.6940640659799229],
					[64.721130743040973, 7.6940640308103347],
					[64.721130788087308, 7.6940639488773916],
					[64.721130800220536, 7.6940639000159585],
					[64.721130800264746, 7.6940556573344479],
					[64.721261269158234, 7.6938501080088502],
					[64.721344567833441, 7.693719509316705],
					[64.721397366841586, 7.6936390104310091],
					[64.72148076409384, 7.6935140142045881],
					[64.721536260701271, 7.6934390181174077],
					[64.7215362615798, 7.6934390159760069],
					[64.721536265149481, 7.6934390137048094],
					[64.721536266028011, 7.6934390115634281],
					[64.721564065655201, 7.6933973108085958],
					[64.721564067449265, 7.6933973107220508],
					[64.721564068327822, 7.6933973085806633],
					[64.72156406922484, 7.6933973085373912],
					[64.721672369373195, 7.693227907367425],
					[64.721672369373195, 7.693227907367425],
					[64.721711268552724, 7.693166806751675],
					[64.72171126944977, 7.693166806708394],
					[64.721711271225345, 7.6931668045236892],
					[64.721711271206843, 7.6931668024255631],
					[64.721786270889439, 7.6930418033652055],
					[64.721802970783557, 7.693014004037229],
					[64.721802972559132, 7.693014001852525],
					[64.721802975194748, 7.6930139954282497],
					[64.721802976970295, 7.6930139932435475],
					[64.721825172192794, 7.6929723038968367],
					[64.721939065584905, 7.69280291279411],
					[64.721939069996083, 7.6928029041850756],
					[64.721939077958368, 7.692802891206564],
					[64.721939082388005, 7.692802884695654],
					[64.722008482050839, 7.6926528834508083],
					[64.722008482050839, 7.6926528834508083],
					[64.722008482032336, 7.6926528813526414],
					[64.722008482929382, 7.6926528813093427],
					[64.722052981422735, 7.6925528863254566],
					[64.722114075241251, 7.6924333977966262],
					[64.722183471027378, 7.6923195049104622],
					[64.722183473662966, 7.6923194984860501],
					[64.722183480709646, 7.6923194834524091],
					[64.722183484242237, 7.6923194769846877],
					[64.722227983309381, 7.6922139791256932],
					[64.722297381955897, 7.6920611830305399],
					[64.722297382852915, 7.6920611829872225],
					[64.722297383712942, 7.6920611787475632],
					[64.722297383712942, 7.6920611787475632],
					[64.722366881658402, 7.6918972834644448],
					[64.722411279323509, 7.6918083885783179],
					[64.722411280202053, 7.6918083864368105],
					[64.722411282837584, 7.6918083800122972],
					[64.7224112845946, 7.6918083757292672],
					[64.722450185362646, 7.6917139756961745],
					[64.722450186259707, 7.6917139756528439],
					[64.722450187119705, 7.6917139714131331],
					[64.722450187101174, 7.691713969314951],
					[64.722519585555418, 7.6915278728814247],
					[64.722589085114535, 7.6913611766911112],
					[64.722589085096004, 7.6913611745929202],
					[64.722589086853006, 7.691361170309853],
					[64.722589086853006, 7.691361170309853],
					[64.722625185149411, 7.6912639761818964],
					[64.722672381684774, 7.6911611824581119],
					[64.722672384338793, 7.6911611781316722],
					[64.722672387852839, 7.6911611695654649],
					[64.722672388694278, 7.6911611632275214],
					[64.722733489010096, 7.6909806643533649],
					[64.722733489888626, 7.6909806622118069],
					[64.722733490748553, 7.690980657972049],
					[64.722733491627082, 7.6909806558304705],
					[64.722777990126076, 7.6908278628629452],
					[64.72283078717463, 7.6906861692359056],
					[64.72283078805313, 7.6906861670943298],
					[64.722830789810132, 7.6906861628111614],
					[64.722830790688619, 7.6906861606695873],
					[64.722886290653904, 7.6905111593065572],
					[64.722886292429436, 7.6905111571216018],
					[64.722886292429436, 7.6905111571216018],
					[64.722922389139157, 7.6903889666600618],
					[64.722961284644342, 7.6902944769553629],
					[64.722961287298375, 7.6902944726287892],
					[64.722961289896773, 7.6902944620075369],
					[64.722961290775231, 7.6902944598659406],
					[64.723022389629577, 7.6900972627950361],
					[64.723105789300433, 7.6898583658010713],
					[64.72310579017892, 7.6898583636594289],
					[64.723105791038847, 7.689858359419576],
					[64.723105791917334, 7.6898583572779522],
					[64.723122390817935, 7.6898028593308911],
					[64.723136290246131, 7.6897611635674537],
					[64.72321958888017, 7.689508364691978],
					[64.72326688901822, 7.6893722660563171],
					[64.723266888999646, 7.6893722639580746],
					[64.723266890775164, 7.6893722617730145],
					[64.723266890756605, 7.6893722596747711],
					[64.72331128609747, 7.6892278730626709],
					[64.723319578758918, 7.6892111886285095],
					[64.723319579637391, 7.6892111864868502],
					[64.723319582291381, 7.6892111821601121],
					[64.723319584066914, 7.689211179975036],
					[64.723327983713432, 7.6891917782521064],
					[64.723327986367437, 7.6891917739253657],
					[64.723327989862767, 7.6891917632604505],
					[64.723327991601153, 7.6891917568788601],
					[64.723411291024561, 7.6889111594618438],
					[64.723480790373799, 7.6886944615196509],
					[64.723480791233712, 7.6886944572797011],
					[64.723480792990642, 7.6886944529962999],
					[64.723480793850527, 7.688694448756352],
					[64.723502991943874, 7.6886056562686171],
					[64.723541889955797, 7.6884889631236666],
					[64.72360299011072, 7.6883056624919517],
					[64.72360299011072, 7.6883056624919517],
					[64.723602990989193, 7.6883056603502373],
					[64.723602990970619, 7.6883056582519504],
					[64.723647390576659, 7.6881611586908285],
					[64.723647390576659, 7.6881611586908285],
					[64.723694589554896, 7.6880056618935573],
					[64.723764089233697, 7.6878056666892416],
					[64.723764090093567, 7.6878056624492013],
					[64.723764091831896, 7.6878056560674377],
					[64.723764092710368, 7.6878056539256967],
					[64.723791890590647, 7.6877056579662622],
					[64.723816889991625, 7.6876306622866348],
					[64.723816889973037, 7.6876306601883497],
					[64.723816890851509, 7.6876306580465785],
					[64.723816891729953, 7.6876306559048277],
					[64.723883491861244, 7.687402856979638],
					[64.723975169687407, 7.6871057264444174],
					[64.723983411710094, 7.6871001670609269],
					[64.723983443687388, 7.6871001298243469],
					[64.723983487832754, 7.6871000479146705],
					[64.72398349996368, 7.6870999990449791],
					[64.723983499625675, 7.6870972260169035],
					[64.723991891582429, 7.6870667565318875],
					[64.724075190321969, 7.686797260867114],
					[64.724152989635513, 7.6865639624904309],
					[64.724152991392387, 7.6865639582067864],
					[64.724152993971913, 7.6865639454864025],
					[64.724152995728801, 7.6865639412027758],
					[64.724166892629682, 7.6864972553108677],
					[64.724197387347843, 7.6864139698017357],
					[64.724275186650516, 7.6862083706057547],
					[64.724275188425992, 7.6862083684203846],
					[64.724275190182851, 7.6862083641367178],
					[64.724275190164249, 7.6862083620383999],
					[64.724333488260086, 7.6860278678378711],
					[64.724372384927619, 7.6859306776472911],
					[64.724419582904218, 7.6858222795900772],
					[64.72441958467968, 7.6858222774046911],
					[64.724419587314983, 7.6858222709790969],
					[64.724419588193385, 7.6858222688372386],
					[64.724480785731842, 7.6856556739243596],
					[64.724547384976773, 7.685494475628758],
					[64.724547385855189, 7.6854944734868598],
					[64.724547387612034, 7.6854944692031042],
					[64.724547389387496, 7.6854944670176844],
					[64.724586287103804, 7.6853833719024589],
					[64.724633484656565, 7.6852667765555749],
					[64.724700184335063, 7.6851111786152773],
					[64.724700184335063, 7.6851111786152773],
					[64.724700185194848, 7.6851111743750256],
					[64.724700186091908, 7.6851111743314711],
					[64.724761285425302, 7.68495837716954],
					[64.724808480959126, 7.6848500851073434],
					[64.724852977726044, 7.6847611908288647],
					[64.724930778336144, 7.6846111915508857],
					[64.724930778336144, 7.6846111915508857],
					[64.724930779195944, 7.6846111873105611],
					[64.724930780092947, 7.6846111872669915],
					[64.725022378621802, 7.6844222910171487],
					[64.725052977385928, 7.6843639937208934],
					[64.725052979142731, 7.6843639894369833],
					[64.725052980880918, 7.6843639830546762],
					[64.725052982656365, 7.6843639808691435],
					[64.725075178986955, 7.6843139891708327],
					[64.72517517561208, 7.6841306952093413],
					[64.725175177387513, 7.684130693023782],
					[64.725175178265928, 7.6841306908818092],
					[64.725175179144301, 7.6841306887398391],
					[64.725297378316753, 7.6838833898943495],
					[64.725419574343562, 7.6836472992856857],
					[64.725516868249002, 7.6834918093363074],
					[64.725555766018218, 7.683433410842003],
					[64.725616866260467, 7.6833418108907257],
					[64.725616867157484, 7.6833418108471125],
					[64.725616868914301, 7.6833418065630452],
					[64.725616868914301, 7.6833418065630452],
					[64.72569186905649, 7.6832223061570852],
					[64.72569186995355, 7.6832223061134677],
					[64.725691870831938, 7.6832223039714247],
					[64.725739069560746, 7.6831445041404924],
					[64.725822366578612, 7.6830112095215046],
					[64.725961263628633, 7.6828112130497868],
					[64.725961277757335, 7.682811187170274],
					[64.725961295194438, 7.6828111296395081],
					[64.725961300315518, 7.6828110999994372],
					[64.725961300124354, 7.6828027991528884],
					[64.725961299966698, 7.682800055301513],
					[64.726089068302784, 7.6825890095876419],
					[64.726180762035597, 7.6824557165536103],
					[64.726233459123037, 7.6823862202859567],
					[64.726233460898442, 7.6823862181001985],
					[64.726233463552234, 7.6823862137723253],
					[64.726233466224713, 7.6823862115428998],
					[64.72631956299017, 7.6822584169224823],
					[64.726372357814014, 7.6821890222207863],
					[64.726402956833397, 7.6821501241048544],
					[64.72640295771177, 7.6821501219627208],
					[64.726402960384192, 7.6821501197332793],
					[64.726402961262579, 7.682150117591144],
					[64.72650296132386, 7.6820140176138576],
					[64.72650296222092, 7.6820140175701983],
					[64.72650296222092, 7.6820140175701983],
					[64.726502963099279, 7.6820140154280532],
					[64.726516862177689, 7.6819945178308409],
					[64.726622356614143, 7.6818529238220767],
					[64.726661253789985, 7.6818057272343712],
					[64.726661255565389, 7.6818057250485206],
					[64.726661258237826, 7.6818057228190213],
					[64.726661259116213, 7.6818057206768557],
					[64.726730759179418, 7.6817140213389905],
					[64.726805758126289, 7.6816168220483041],
					[64.726805759023321, 7.6816168220046324],
					[64.72680575990168, 7.681616819862449],
					[64.726805760780053, 7.6816168177202657],
					[64.72684455781652, 7.6815640219763432],
					[64.726952948841472, 7.6814334335976033],
					[64.727066839862047, 7.6813168420410438],
					[64.727111236759342, 7.6812751453413153],
					[64.727111239431764, 7.6812751431117068],
					[64.727111243879605, 7.6812751386962157],
					[64.727111247449059, 7.6812751364229426],
					[64.72721124735132, 7.6811668368758816],
					[64.727211247332647, 7.6811668347773407],
					[64.727211248229708, 7.6811668347336512],
					[64.727211248229708, 7.6811668347336512],
					[64.72726404797551, 7.6811084344032583],
					[64.7272640497509, 7.6811084322173313],
					[64.727264054198713, 7.6811084278017878],
					[64.727264055974118, 7.6811084256158617],
					[64.727286252237406, 7.6810807296746031],
					[64.727416847847991, 7.680936234103914],
					[64.727508447041458, 7.6808362353503776],
					[64.727508447919831, 7.6808362332081135],
					[64.727508448816877, 7.6808362331644124],
					[64.727508449695208, 7.6808362310221501],
					[64.727569544176333, 7.6807668396975526],
					[64.727730735740209, 7.6806168465614624],
					[64.727730759679872, 7.680616813902577],
					[64.727730792234894, 7.680616740933516],
					[64.727730799953221, 7.6806167006670458],
					[64.727730799649592, 7.6806112230995733],
					[64.727736191163274, 7.6806084787942819],
					[64.72773619740515, 7.6806084742911622],
					[64.727736208113512, 7.6806084674708996],
					[64.727736212579984, 7.680608465153778],
					[64.72790571259975, 7.6804918652430816],
					[64.72790571616919, 7.6804918629696388],
					[64.727905720617002, 7.680491858553868],
					[64.727905724186456, 7.6804918562804252],
					[64.727958415764377, 7.6804501621050676],
					[64.728050106897967, 7.6803918684534978],
					[64.728050112242784, 7.6803918639939717],
					[64.728050122054086, 7.6803918572172796],
					[64.728050127417603, 7.680391854856361],
					[64.728080718780546, 7.6803668619682686],
					[64.728180710710632, 7.6803001660981209],
					[64.728180711607649, 7.6803001660544021],
					[64.728180714280072, 7.6803001638246284],
					[64.728180715177075, 7.6803001637809096],
					[64.728255712869299, 7.6802473647329474],
					[64.728400112187714, 7.6801501658711686],
					[64.728400113084732, 7.6801501658274445],
					[64.728400115757154, 7.6801501635976388],
					[64.728400116635513, 7.6801501614552805],
					[64.728430705097935, 7.6801279710050645],
					[64.72858338988047, 7.6800501784231159],
					[64.728622276579173, 7.6800307850155276],
					[64.728630663218738, 7.6800279889742509],
					[64.728630675739822, 7.6800279841647416],
					[64.72863069986623, 7.6800279724907883],
					[64.728630712368641, 7.6800279655826298],
					[64.728639001414251, 7.6800223734765654],
					[64.728805685409881, 7.6799390807620576],
					[64.728850077763838, 7.679919584481059],
					[64.72891117241052, 7.6798945875552382],
					[64.729002865223478, 7.6798612897107015],
					[64.729072259029849, 7.6798390918576649],
					[64.729163957712046, 7.6798112901042126],
					[64.729163960403113, 7.679811289973002],
					[64.729163964888286, 7.6798112897543147],
					[64.729163966663663, 7.6798112875681284],
					[64.729202864593731, 7.6797973896212923],
					[64.729302859439883, 7.6797640914977272],
					[64.729400054842571, 7.6797362920700358],
					[64.729400056636635, 7.6797362919825547],
					[64.729400061121794, 7.6797362917638532],
					[64.72940006289717, 7.6797362895776624],
					[64.729416758924742, 7.6797307911953574],
					[64.729522255658352, 7.6797001910496228],
					[64.72952225655537, 7.6797001910058809],
					[64.729605654800196, 7.6796751929529767],
					[64.729666750645336, 7.6796584945981783],
					[64.72974444358745, 7.6796390950179516],
					[64.72981393910672, 7.67962519514841],
					[64.729813940003723, 7.6796251951046655],
					[64.729813940900783, 7.6796251950609209],
					[64.72990563543793, 7.6796057969497662],
					[64.729963928978265, 7.6795973984193093],
					[64.729963929875311, 7.6795973983755621],
					[64.729963934341797, 7.6795973960580799],
					[64.729963936135846, 7.6795973959705872],
					[64.73002503429268, 7.6795862969261917],
					[64.730194431777861, 7.6795584960138168],
					[64.730194432674892, 7.6795584959700687],
					[64.730194436281721, 7.6795584978938489],
					[64.730194437178781, 7.6795584978501008],
					[64.730238934608948, 7.6795501966395481],
					[64.73041673214577, 7.6795195961231384],
					[64.730452830442786, 7.6795140980818042],
					[64.730452834030899, 7.6795140979068055],
					[64.730452841188452, 7.6795140954580168],
					[64.730452844776607, 7.6795140952830172],
					[64.730644444665785, 7.679469594942347],
					[64.73064445182338, 7.6794695924935272],
					[64.730644467035518, 7.6794695875521395],
					[64.730644474193085, 7.6794695851033028],
					[64.730658358625703, 7.6794640911448582],
					[64.730866744093504, 7.6794168941962448],
					[64.730866800326041, 7.6794168599573558],
					[64.730866878423413, 7.679416761659831],
					[64.730866900306921, 7.6794166997000195],
					[64.730866899581798, 7.679414100258799],
					[64.730872199756661, 7.6794141000042879],
					[64.730872217678609, 7.6794140970303877],
					[64.730872252625474, 7.6794140911263398],
					[64.730872269631675, 7.6794140860973501],
					[64.731063969998573, 7.6793418878676896],
					[64.731063975362048, 7.6793418855063127],
					[64.731063985192023, 7.679341880827292],
					[64.731063988761477, 7.6793418785534255],
					[64.731086180966571, 7.6793307822967538],
					[64.731255673350262, 7.6792640862699733],
					[64.731255674247322, 7.6792640862262127],
					[64.731255675125624, 7.6792640840836013],
					[64.731255676022684, 7.6792640840398398],
					[64.731316775932783, 7.6792390851925685],
					[64.731316777708145, 7.6792390830061903],
					[64.731316781296272, 7.6792390828311481],
					[64.731316783090335, 7.679239082743627],
					[64.73144447833802, 7.6791807849341893],
					[64.731536172963686, 7.6791445857555312],
					[64.73153617475775, 7.6791445856680021],
					[64.731536178327175, 7.6791445833940726],
					[64.731536180121225, 7.6791445833065444],
					[64.731644480426553, 7.6790973833752627],
					[64.731758379913671, 7.6790473837987374],
					[64.731758380810717, 7.679047383754968],
					[64.731833381363458, 7.6790140833646454],
					[64.731833382241803, 7.6790140812219585],
					[64.731980681215134, 7.6789473828520141],
					[64.732025077898186, 7.6789279831765187],
					[64.732208375640795, 7.6788529840169044],
					[64.732208384592397, 7.6788529814802278],
					[64.732208401561195, 7.6788529722527734],
					[64.732208411372412, 7.6788529654744693],
					[64.732216794630801, 7.6788473760576403],
					[64.732283377076655, 7.6788195858829029],
					[64.732283377954985, 7.6788195837401947],
					[64.732283378852017, 7.6788195836964181],
					[64.732405672037075, 7.6787668874507959],
					[64.73243066219662, 7.6787584905191792],
					[64.732613959757103, 7.6787001914666311],
					[64.732650052081794, 7.6786890938711201],
					[64.732819444697, 7.6786501951763872],
					[64.73281944736938, 7.6786501929460647],
					[64.732819450957507, 7.6786501927709336],
					[64.732819452751571, 7.6786501926833663],
					[64.732880650783116, 7.678633493360401],
					[64.733025049091779, 7.6785973931030655],
					[64.733025049988825, 7.6785973930592784],
					[64.733100049630551, 7.6785779933861331],
					[64.73310005232166, 7.6785779932547751],
					[64.733100054994026, 7.6785779910244223],
					[64.733100056806848, 7.6785779930358444],
					[64.73322225735366, 7.6785418921701707],
					[64.733222258231962, 7.6785418900273781],
					[64.733222259128993, 7.67854188998359],
					[64.733322258923224, 7.6785112907715991],
					[64.73332226518373, 7.6785112883660656],
					[64.733322278601776, 7.6785112835112113],
					[64.733322284862311, 7.6785112811056786],
					[64.733422284724597, 7.6784640816541714],
					[64.733422289191068, 7.6784640793361989],
					[64.733422297226895, 7.6784640747440269],
					[64.733422300796335, 7.6784640724698443],
					[64.733550100818533, 7.6783890729517594],
					[64.733550101715551, 7.678389072907966],
					[64.733550102612597, 7.6783890728641735],
					[64.733605703449498, 7.6783557726276035],
					[64.733605703430769, 7.6783557705285714],
					[64.733605704327786, 7.6783557704847762],
					[64.733605705224832, 7.6783557704409819],
					[64.733772305048021, 7.678252970346362],
					[64.733772306823383, 7.67825296815972],
					[64.733772308598702, 7.6782529659730772],
					[64.733772311289812, 7.6782529658416818],
					[64.733780706128059, 7.678247369044624],
					[64.733833400061314, 7.6782168729238958],
					[64.733833400958332, 7.678216872880097],
					[64.733833403630712, 7.6782168706496465],
					[64.733833405424775, 7.6782168705620482],
					[64.733964001129294, 7.6781362722512236],
					[64.733994495613842, 7.6781195751257432],
					[64.733994498304924, 7.6781195749943354],
					[64.733994500977303, 7.6781195727638618],
					[64.733994502771367, 7.6781195726762563],
					[64.734147303053476, 7.6780279728902867],
					[64.734147317312349, 7.6780279616939886],
					[64.734147342242068, 7.6780279394766122],
					[64.734147353809874, 7.678027928411729],
					[64.734214053823521, 7.6779473281607444],
					[64.734214054701809, 7.6779473260178346],
					[64.734214058252519, 7.6779473216444334],
					[64.734214059149537, 7.6779473216006258],
					[64.734291858760585, 7.6778445214798161],
					[64.734291862311267, 7.6778445171063927],
					[64.734291866740264, 7.677844510590047],
					[64.734291869393942, 7.6778445062604339],
					[64.734405763046638, 7.6776640164879719],
					[64.734427956063186, 7.6776362243846048],
					[64.734427964042837, 7.6776362134947203],
					[64.734427978208075, 7.6776361918025895],
					[64.734427984374932, 7.6776361789012455],
					[64.734436275948525, 7.677616796009735],
					[64.734566864722183, 7.6774251123854009],
					[64.734566867394562, 7.6774251101547923],
					[64.734566869151166, 7.6774251058689211],
					[64.734566870926486, 7.6774251036821592],
					[64.734658469582371, 7.6772751043951546],
					[64.734694568694593, 7.6772168074676665],
					[64.734755766925431, 7.6771223118426537],
					[64.734833465142174, 7.6770084134577159],
					[64.734833467795809, 7.677008409127934],
					[64.734833473103052, 7.6770084004683525],
					[64.734833474859641, 7.677008396182412],
					[64.734877974814097, 7.6769278977837114],
					[64.734961275408381, 7.6767778974798206],
					[64.734961276267953, 7.6767778932376745],
					[64.73496127892156, 7.6767778889078517],
					[64.734961280678135, 7.6767778846218571],
					[64.735052981272247, 7.6765833839591942],
					[64.73505298216925, 7.6765833839153403],
					[64.735052982150506, 7.6765833818161742],
					[64.735077982413884, 7.676527881220232],
					[64.735077983310887, 7.6765278811763755],
					[64.73510018334801, 7.6764778809673713],
					[64.735191881280002, 7.6762750852004222],
					[64.735305778655757, 7.6760472886151643],
					[64.735305781309322, 7.6760472842852145],
					[64.735305784822458, 7.6760472757130414],
					[64.735305786560275, 7.6760472693278006],
					[64.735314086886561, 7.6760250707674089],
					[64.735322384555886, 7.6760028765233734],
					[64.735427982304543, 7.6757722831525017],
					[64.735480782421831, 7.6756556836635692],
					[64.735541880587377, 7.6755250858240176],
					[64.735541887576048, 7.6755250644810404],
					[64.735541897965305, 7.675525021970615],
					[64.7355418995718, 7.6755250008909703],
					[64.735541899716949, 7.6755167810787706],
					[64.735550141620166, 7.6755084415041575],
					[64.735550153150271, 7.675508426239257],
					[64.735550172641126, 7.6755083979841858],
					[64.735550180601862, 7.6755083849940373],
					[64.735664081019365, 7.6752639847630215],
					[64.735664081916383, 7.6752639847191224],
					[64.735764081645442, 7.6750472829981549],
					[64.735764083420747, 7.6750472808111407],
					[64.735764085158507, 7.6750472744257268],
					[64.735764086933813, 7.6750472722387109],
					[64.73577798485735, 7.6750111754983941],
					[64.735794583241969, 7.6749722814733516],
					[64.735894580719233, 7.6747583855941288],
					[64.735986280375712, 7.6745694870638088],
					[64.735986282132217, 7.6745694827775086],
					[64.735986287420573, 7.6745694720178799],
					[64.735986289158333, 7.6745694656323646],
					[64.73600848286496, 7.6745056812956296],
					[64.736047375090465, 7.6744361983701479],
					[64.73604737594998, 7.6744361941277779],
					[64.736047380378835, 7.6744361876104508],
					[64.736047382135339, 7.6744361833241523],
					[64.736130781835499, 7.6742528828069263],
					[64.736130782713758, 7.6742528806637651],
					[64.736130783592017, 7.6742528785206021],
					[64.736130785367294, 7.6742528763334859],
					[64.736197381805511, 7.6740917836710825],
					[64.736244578263239, 7.6740000911044497],
					[64.73624457914147, 7.6740000889612707],
					[64.736244581776234, 7.6740000825317143],
					[64.736244584429784, 7.6740000782014102],
					[64.736289082562749, 7.6738972816387596],
					[64.736358481822407, 7.6737472836047944],
					[64.736358481822407, 7.6737472836047944],
					[64.73641958023515, 7.6736139868034261],
					[64.736480777799272, 7.6734944911015006],
					[64.736480778677532, 7.6734944889582648],
					[64.736480781331053, 7.6734944846278745],
					[64.736480782209298, 7.6734944824846609],
					[64.736541882166676, 7.6733611823227443],
					[64.736541883044907, 7.6733611801795227],
					[64.736594582675067, 7.6732417806699047],
					[64.736594582675067, 7.6732417806699047],
					[64.736641882162374, 7.67313338158558],
					[64.736708480606907, 7.672988985822065],
					[64.736786280933316, 7.6728250857707554],
					[64.736786283549208, 7.6728250772416802],
					[64.736786289696866, 7.6728250622388385],
					[64.736786292312786, 7.6728250537097615],
					[64.73681689227908, 7.6727167579371054],
					[64.736861288037829, 7.6725778700022635],
					[64.736922383854292, 7.6724361799117915],
					[64.736922383835491, 7.6724361778124859],
					[64.736922384732537, 7.6724361777684891],
					[64.736983484915839, 7.6722889779480168],
					[64.736983486653486, 7.6722889715621214],
					[64.736983491044626, 7.6722889608456359],
					[64.736983492782258, 7.6722889544597574],
					[64.737022392228141, 7.6721500552603148],
					[64.737083488662932, 7.6719444660646943],
					[64.737122385422751, 7.6718500761095054],
					[64.737161284718752, 7.6717556752544942],
					[64.737161285578182, 7.6717556710118648],
					[64.737161289109849, 7.6717556645378524],
					[64.737161290866297, 7.6717556602512023],
					[64.737219590074403, 7.6715694634384191],
					[64.737297387775797, 7.6713500678721873],
					[64.737297391269877, 7.6713500571994553],
					[64.737297395604486, 7.671350040184751],
					[64.737297398220306, 7.6713500316553693],
					[64.737402997145367, 7.6706861359310832],
					[64.737555795999583, 7.6699611418411475],
					[64.737555796802482, 7.6699611313003784],
					[64.737555799342857, 7.6699611143734296],
					[64.737555800164557, 7.669961105932015],
					[64.737580799973486, 7.6690528129044537],
					[64.737708499248342, 7.6678333218849479],
					[64.737708499229484, 7.6678333197856041],
					[64.737708499210598, 7.6678333176862612],
					[64.737708499191754, 7.6678333155869192],
					[64.737830799074302, 7.666302816283463],
					[64.737830799876889, 7.6663028057424825],
					[64.737830798791009, 7.6663027847931264],
					[64.737830797799518, 7.6663027743405499],
					[64.737664097952091, 7.6650360743015993],
					[64.737664097933205, 7.6650360722022608],
					[64.737664097914291, 7.6650360701029232],
					[64.73766409789539, 7.6650360680035856],
					[64.737472398027307, 7.6638305692796198],
					[64.737472395241639, 7.6638305589158398],
					[64.737472389651316, 7.6638305360889607],
					[64.73747238598753, 7.6638305278688064],
					[64.737075185665091, 7.6628221264207568],
					[64.73707515929155, 7.6628220878193449],
					[64.737075091446513, 7.6628220281642312],
					[64.737075049956104, 7.6628220050112192],
					[64.736550049637842, 7.662685905264718],
					[64.736549993125223, 7.6626859080568384],
					[64.736549887560258, 7.6626859447753004],
					[64.736549842095997, 7.6626859785243866],
					[64.736502642156893, 7.6627470779029645],
					[64.736502641259861, 7.6627470779472819],
					[64.736410955736602, 7.6628665587519205],
					[64.736344275682626, 7.6629192424970665],
					[64.736344271235367, 7.662919246917089],
					[64.73634426234085, 7.6629192557571564],
					[64.736344257874649, 7.662919258077947],
					[64.736327711434953, 7.662935906528145],
					[64.736130593358453, 7.6629497997511278],
					[64.73610004230747, 7.6629498009480406],
					[64.735961209822435, 7.6628887295909482],
					[64.735877934418852, 7.6628137499926758],
					[64.735877909150645, 7.6628137344398617],
					[64.735877856876954, 7.662813709720437],
					[64.735877828096392, 7.6628137027416878],
					[64.735838982825697, 7.6628081095476732],
					[64.735650126184396, 7.6626554443965711],
					[64.735650124371404, 7.6626554423860282],
					[64.735650120764376, 7.6626554404641238],
					[64.735650118951369, 7.6626554384535819],
					[64.735489022635704, 7.6625359420863619],
					[64.735427927083308, 7.6624859458529126],
					[64.735427912636183, 7.662485936066262],
					[64.73542788286386, 7.6624859186364453],
					[64.735427866641658, 7.6624859110376029],
					[64.735389046281696, 7.6624720406794227],
					[64.735352980977353, 7.6623943152149012],
					[64.735352977313468, 7.662394306995564],
					[64.73535296821062, 7.6623942927447164],
					[64.735352962752714, 7.6623942846140292],
					[64.735200162593671, 7.6621804842319845],
					[64.735200135417699, 7.662180456173906],
					[64.735200071349951, 7.6621804173386128],
					[64.735200035317348, 7.6621804023187758],
					[64.735091736570652, 7.6621609045099799],
					[64.735077838877217, 7.6621581031530628],
					[64.735077836186122, 7.6621581032860639],
					[64.735077828990995, 7.6621581015415998],
					[64.735077825402882, 7.6621581017189362],
					[64.734969424609147, 7.6621442016019614],
					[64.734969423712144, 7.662144201646294],
					[64.734969420124045, 7.6621442018236303],
					[64.734969418329982, 7.6621442019122998],
					[64.734847217947376, 7.66213310078611],
					[64.734847217050358, 7.6621331008304434],
					[64.734750017308684, 7.6621248004662954],
					[64.734750011029504, 7.6621248007766365],
					[64.734749997555184, 7.6621247993425419],
					[64.734749991276004, 7.662124799652883],
					[64.734619396331937, 7.6621303993883316],
					[64.734527841838741, 7.6621304008187936],
					[64.734361205183305, 7.662058128011223],
					[64.734300141071316, 7.6620081564507085],
					[64.734230759597594, 7.6619248805832525],
					[64.73406956627872, 7.6616831880975678],
					[64.734069561736732, 7.6616831820221165],
					[64.734069552671741, 7.6616831719702922],
					[64.734069548129739, 7.6616831658948614],
					[64.733864050237415, 7.661458167357889],
					[64.733847360727225, 7.6614387802886172],
					[64.733833477427751, 7.6614166064092881],
					[64.733619582788748, 7.6609305187966594],
					[64.733619580956784, 7.6609305146873403],
					[64.733619577292842, 7.6609305064687456],
					[64.733619574563818, 7.660930502403799],
					[64.733605779719994, 7.6609055127438861],
					[64.733391882942612, 7.6604138201267427],
					[64.733391878362639, 7.6604138098536518],
					[64.733391865614607, 7.6604137894850233],
					[64.733391860137559, 7.6604137792563405],
					[64.733375175779528, 7.6603916020805762],
					[64.733291887412193, 7.6601777290836299],
					[64.733177987636708, 7.6598749308307594],
					[64.733155790222966, 7.6598138361410744],
					[64.733016891082443, 7.6593582415229511],
					[64.733016889231436, 7.6593582353148788],
					[64.733016884651377, 7.6593582250421086],
					[64.733016881903339, 7.6593582188784408],
					[64.732925183697077, 7.6591555219949283],
					[64.732802986266591, 7.6588443277344318],
					[64.732802986247592, 7.6588443256354761],
					[64.732802986247592, 7.6588443256354761],
					[64.732802985350546, 7.6588443256799161],
					[64.732697386216728, 7.658583225703623],
					[64.732597388130188, 7.6583305289796249],
					[64.732466888885142, 7.6579555331906732],
					[64.732466887969082, 7.6579555311362135],
					[64.732466885221029, 7.6579555249728104],
					[64.732466884285984, 7.6579555208194225],
					[64.732405784104444, 7.6578138203856652],
					[64.732405783207398, 7.6578138204301371],
					[64.732405783188398, 7.6578138183312143],
					[64.73223908303676, 7.6574388190971296],
					[64.73223908120471, 7.6574388149882564],
					[64.732239076643523, 7.6574388068150183],
					[64.732239073914414, 7.6574388027506508],
					[64.732161274129552, 7.6572999025496227],
					[64.732161271381429, 7.6572998963863581],
					[64.732161264110189, 7.657299886247726],
					[64.732161261362052, 7.6572998800844809],
					[64.732011260630017, 7.6570970814691037],
					[64.732011241602336, 7.6570970614145413],
					[64.732011200931979, 7.6570970298344907],
					[64.732011179270287, 7.657097016210094],
					[64.731894478879681, 7.6570470166148761],
					[64.731894470768424, 7.6570470128175803],
					[64.731894453667934, 7.6570470073663683],
					[64.731894445575691, 7.6570470056679358],
					[64.731788946322524, 7.6570220056865201],
					[64.731788922064851, 7.6570220026901987],
					[64.731788872709487, 7.6570220030386844],
					[64.73178884761181, 7.6570220063834888],
					[64.731674947854273, 7.6570526064103701],
					[64.731674895234107, 7.6570526426175727],
					[64.731674820720812, 7.6570527387055423],
					[64.731674799743715, 7.6570528006406811],
					[64.731674799770659, 7.6570554779016016],
					[64.731561008680728, 7.657113722490891],
					[64.73156099530145, 7.6571137315537499],
					[64.731560967664834, 7.6571137518228047],
					[64.731560955201545, 7.6571137629400292],
					[64.731499856609517, 7.6571776604278616],
					[64.731347073447921, 7.6573304449461501],
					[64.731338789651502, 7.6573359330520123],
					[64.731338788754456, 7.6573359330964985],
					[64.731338787857453, 7.6573359331409856],
					[64.731330488024994, 7.6573415333274237],
					[64.731330480905854, 7.6573415399798304],
					[64.73133046842355, 7.6573415489980148],
					[64.73133046130441, 7.6573415556504427],
					[64.731177671295384, 7.6574887469100359],
					[64.731116703786597, 7.6575330494770117],
					[64.730897322337611, 7.6573637413540272],
					[64.730897307909203, 7.6573637336705289],
					[64.730897279033272, 7.6573637162047499],
					[64.730897263726831, 7.6573637106645451],
					[64.730888967678069, 7.6573609115522903],
					[64.730666844629738, 7.6572748413171157],
					[64.730566878675305, 7.6570721128344541],
					[64.730566878656276, 7.6570721107356743],
					[64.730566876824199, 7.6570721066270986],
					[64.730566875908139, 7.6570721045727934],
					[64.730452987589899, 7.6568610268472952],
					[64.730447393992577, 7.65683885204826],
					[64.730447379316871, 7.6568388170811019],
					[64.730447338418202, 7.6568387603179442],
					[64.730447312157239, 7.6568387343244027],
					[64.730439042943587, 7.6568331540599379],
					[64.73040846486667, 7.6567887880413377],
					[64.730208464683614, 7.656494287288333],
					[64.730208463767568, 7.6564942852340936],
					[64.730208461038444, 7.656494281170124],
					[64.730208459206338, 7.656494277061622],
					[64.730172358597002, 7.6564470782900811],
					[64.730172345905473, 7.6564470642219797],
					[64.730172317869417, 7.6564470404168548],
					[64.730172303402867, 7.6564470285365509],
					[64.730025115397723, 7.6563581353263741],
					[64.729980759742361, 7.6563220719100755],
					[64.72990577873108, 7.6561721113711974],
					[64.729905775982942, 7.6561721052085563],
					[64.729905771421699, 7.6561720970362499],
					[64.729905768692561, 7.6561720929723371],
					[64.729866869389639, 7.6561109935423177],
					[64.729866863931363, 7.6561109854145375],
					[64.729866854846946, 7.6561109732674026],
					[64.729866850304745, 7.6561109671938423],
					[64.729758450215556, 7.6559887677325529],
					[64.729758448402464, 7.6559887657228733],
					[64.729758443879305, 7.6559887617480751],
					[64.729758442066199, 7.6559887597384142],
					[64.729714042104291, 7.6559442588319477],
					[64.729714027637726, 7.6559442469521084],
					[64.729713997845607, 7.6559442274344072],
					[64.729713983398042, 7.6559442176532837],
					[64.729530800162223, 7.6558609705303162],
					[64.729530800171233, 7.655858299777738],
					[64.729530770819295, 7.6558582298468467],
					[64.729530671194951, 7.6558581298101593],
					[64.729530600025512, 7.6558580997488956],
					[64.729522232403113, 7.6558580994171166],
					[64.729308420993732, 7.6557887282474111],
					[64.729216858862344, 7.6556692785158367],
					[64.729080759858235, 7.6554887796708053],
					[64.729080752605896, 7.6554887716324727],
					[64.729080739914281, 7.6554887575654025],
					[64.72908073176491, 7.6554887495716102],
					[64.728936232400969, 7.6553609498003272],
					[64.728936232400969, 7.6553609498003272],
					[64.728936229690859, 7.6553609478353142],
					[64.728936228793827, 7.6553609478798608],
					[64.728850145859553, 7.6552887618274532],
					[64.728783460078674, 7.6551998806287527],
					[64.728783459162642, 7.6551998785746624],
					[64.728783456433462, 7.6551998745110366],
					[64.728783453723381, 7.6551998725460484],
					[64.728730757594462, 7.6551359790635116],
					[64.728622361928672, 7.6549859836205165],
					[64.728622357367399, 7.654985975448775],
					[64.728622345591774, 7.6549859634362472],
					[64.728622340152498, 7.6549859574077148],
					[64.728491840196682, 7.6548581577320212],
					[64.72849183838359, 7.6548581557225281],
					[64.72849183386036, 7.654858151748086],
					[64.728491832047268, 7.6548581497385921],
					[64.728425131900522, 7.6547998495023473],
					[64.728425122873091, 7.6547998436521523],
					[64.728425105715374, 7.6547998319071766],
					[64.728425096687971, 7.654799826056963],
					[64.728400137072967, 7.654785947116121],
					[64.728308464236861, 7.6546554854601689],
					[64.728308462404726, 7.6546554813520977],
					[64.728308457881482, 7.654655477377716],
					[64.72830845606839, 7.6546554753682496],
					[64.728172355969846, 7.6544859745400551],
					[64.728172351427588, 7.6544859684671449],
					[64.728172342362058, 7.6544859584199036],
					[64.728172336941839, 7.654485954490144],
					[64.728036236555084, 7.6543581541870305],
					[64.728036231115794, 7.6543581481587326],
					[64.728036220275285, 7.6543581402993421],
					[64.728036213958006, 7.6543581364142055],
					[64.727944514231837, 7.654294236334608],
					[64.727944506998483, 7.6542942303955215],
					[64.72794449077584, 7.6542942228036441],
					[64.727944481767508, 7.6542942190522743],
					[64.727913982088637, 7.654280418793082],
					[64.727913972164203, 7.6542804129877284],
					[64.727913950578454, 7.6542804077619415],
					[64.727913939776087, 7.6542804040997412],
					[64.72772224039862, 7.6542415038757374],
					[64.727722234100384, 7.65424150208925],
					[64.727722224214077, 7.6542415004810849],
					[64.727722217934897, 7.6542415007931561],
					[64.72766111769684, 7.6542359000604403],
					[64.727661112314664, 7.6542359003279303],
					[64.727661098859301, 7.6542359009966523],
					[64.727661092561092, 7.6542358992101684],
					[64.72749999304618, 7.6542414996699639],
					[64.727499990355085, 7.6542414998037085],
					[64.72749998497298, 7.6542415000711914],
					[64.72749998228187, 7.6542415002049369],
					[64.727438882344231, 7.6542470015826414],
					[64.727438881447242, 7.6542470016272199],
					[64.727438879653192, 7.6542470017163824],
					[64.727272179553992, 7.6542637010730656],
					[64.727272150906359, 7.6542637087952059],
					[64.727272096378471, 7.6542637324998388],
					[64.727272069601156, 7.6542637485269118],
					[64.727233175836702, 7.6542970433337496],
					[64.727088781714642, 7.6544026396656211],
					[64.727088770129555, 7.654402648639147],
					[64.727088747894499, 7.6544026707386443],
					[64.727088738122518, 7.6544026817215309],
					[64.727066553125979, 7.6544331618439641],
					[64.727049872133847, 7.6544470451521187],
					[64.727049868583862, 7.6544470495274428],
					[64.727049862342795, 7.6544470540364671],
					[64.727049858792824, 7.6544470584117716],
					[64.727019258790222, 7.6544776584634135],
					[64.727019255240236, 7.6544776628387083],
					[64.72701924724322, 7.6544776716338934],
					[64.727019242796203, 7.6544776760537623],
					[64.726913743337363, 7.6546109759729903],
					[64.726830453046446, 7.6547165632317355],
					[64.726760965368271, 7.6547804538440749],
					[64.726760963574236, 7.6547804539331974],
					[64.726760962677176, 7.65478045397776],
					[64.726622063068078, 7.6549109533984483],
					[64.726622058640103, 7.6549109599166716],
					[64.726622049726998, 7.6549109666576616],
					[64.726622046176999, 7.6549109710328551],
					[64.7266081653637, 7.6549276497166687],
					[64.726599919135097, 7.6549331147240114],
					[64.726508252580743, 7.6549554058377955],
					[64.726508251683725, 7.6549554058823519],
					[64.72650824899263, 7.6549554060160192],
					[64.726508248095627, 7.6549554060605729],
					[64.726394354036259, 7.6549859054255887],
					[64.726380579634593, 7.6549886994832557],
					[64.726372200347953, 7.6549886999885599],
					[64.726372196759854, 7.6549887001667756],
					[64.72637219227471, 7.6549887003895467],
					[64.726372188686625, 7.6549887005677615],
					[64.726172188853184, 7.6549997993518177],
					[64.726172181696001, 7.6549998018066825],
					[64.726172168240652, 7.654999802474979],
					[64.726172161083511, 7.6549998049298429],
					[64.726158342593607, 7.6550025864502844],
					[64.726150112128664, 7.6549970329804466],
					[64.72615008059951, 7.6549970198507307],
					[64.726150014945304, 7.6549970042171438],
					[64.726149980820281, 7.6549970017132933],
					[64.725944390853414, 7.6550165004346615],
					[64.725936100409726, 7.6550165007160453],
					[64.725936089626401, 7.6550164991522491],
					[64.725936070807919, 7.6550165021862648],
					[64.725936061856743, 7.6550165047302041],
					[64.725922174392281, 7.6550192014687584],
					[64.725722185596993, 7.6550330997943572],
					[64.725722180214845, 7.6550331000616634],
					[64.725722166778525, 7.6550331028283534],
					[64.725722161396391, 7.6550331030956595],
					[64.725708261408982, 7.6550359032933377],
					[64.725708225642293, 7.6550359176658125],
					[64.725708164968395, 7.6550359563681836],
					[64.725708139183212, 7.6550359828410315],
					[64.725677643323408, 7.6550776747783438],
					[64.72556924916563, 7.6552026679724587],
					[64.725569248287655, 7.655202670115397],
					[64.725569247390638, 7.6552026701599427],
					[64.725569245615603, 7.6552026723474453],
					[64.725485946423774, 7.6553026728918558],
					[64.725485944629739, 7.6553026729809384],
					[64.725485944629739, 7.6553026729809384],
					[64.725424858587374, 7.6553776565306295],
					[64.725266576067781, 7.6555026427556836],
					[64.725266571620708, 7.6555026471750951],
					[64.725266562707603, 7.6555026539155682],
					[64.725266559157589, 7.6555026582904429],
					[64.725249858738664, 7.655519257480254],
					[64.725249849863602, 7.6555192684174527],
					[64.725249833010494, 7.6555192902473141],
					[64.725249825051506, 7.6555193032383455],
					[64.725235928094563, 7.6555442990424494],
					[64.725119231133533, 7.6557304942520235],
					[64.72504423372969, 7.6558498884221695],
					[64.724983137803392, 7.6559359815529735],
					[64.724869240807521, 7.6560887795669288],
					[64.724838762163444, 7.6561276509117491],
					[64.724822088827665, 7.6561387332887607],
					[64.724822076364461, 7.6561387444036324],
					[64.724822049663047, 7.6561387688207345],
					[64.724822039012906, 7.6561387819449171],
					[64.724769240194078, 7.6562109804421512],
					[64.724694243937449, 7.6563081739467629],
					[64.72460264716122, 7.6564165713655505],
					[64.724602641836171, 7.6564165779275219],
					[64.724602632980051, 7.6564165909625013],
					[64.724602629429995, 7.6564165953371495],
					[64.724547048172866, 7.6565081641625872],
					[64.724380478078956, 7.6566359417695606],
					[64.724380448781474, 7.656635976810783],
					[64.724380410016167, 7.656636056405854],
					[64.724380399651309, 7.6566361010041808],
					[64.724380400268984, 7.6566415149345532],
					[64.724158292569314, 7.6566580992105058],
					[64.724152800321534, 7.6566581008357568],
					[64.724152728787772, 7.6566581295747307],
					[64.724152629176913, 7.6566582289809855],
					[64.7241526002028, 7.6566582996927641],
					[64.724152599588649, 7.6566609048682759],
					[64.723930615719922, 7.6566664995986473],
					[64.723900038066731, 7.6566609041554781],
					[64.723725099135507, 7.6566248158973309],
					[64.723702940875441, 7.656602658005621],
					[64.723702917381587, 7.6566026402782166],
					[64.723702864190784, 7.6566026135279097],
					[64.723702836268885, 7.6566026023177267],
					[64.723672236148914, 7.6565970039068185],
					[64.723672231644784, 7.656597002031047],
					[64.723672223571555, 7.6565970024315044],
					[64.723672219964399, 7.6565970005112414],
					[64.723480619877918, 7.6565776008045123],
					[64.72348061539283, 7.6565776010269877],
					[64.723480604609492, 7.656577599462703],
					[64.723480600124347, 7.656577599685181],
					[64.723433300246043, 7.6565775990368046],
					[64.723433297554948, 7.6565775991702907],
					[64.723433293069846, 7.6565775993927678],
					[64.723433291275782, 7.6565775994817598],
					[64.723258315884479, 7.6565858988224544],
					[64.723205643929376, 7.656574803985003],
					[64.723100045707724, 7.6565498057945334],
					[64.723100033111322, 7.6565498022210496],
					[64.723100006181554, 7.6565498014577154],
					[64.723099991810116, 7.6565498000714447],
					[64.723030592115222, 7.656552600194674],
					[64.723030591218176, 7.6565526002391699],
					[64.722969393126903, 7.656555400754482],
					[64.722869394687152, 7.6565581001971275],
					[64.722869393790106, 7.6565581002416234],
					[64.722869391996056, 7.6565581003306136],
					[64.722869391099053, 7.6565581003751069],
					[64.722808303378429, 7.6565608995808105],
					[64.722738916392672, 7.6565554011814339],
					[64.722738910991509, 7.6565553993502276],
					[64.722738901124217, 7.6565553998396707],
					[64.722738896639086, 7.6565554000621443],
					[64.722580639809678, 7.6565580987371922],
					[64.722497293390063, 7.6565220224754782],
					[64.722358412015311, 7.6564359342394379],
					[64.722161218207219, 7.6562915390188566],
					[64.722161216394142, 7.6562915370097322],
					[64.722161210076905, 7.6562915331249917],
					[64.722161208263827, 7.6562915311158477],
					[64.722130726258001, 7.6562720421208059],
					[64.722114067002167, 7.656255484092922],
					[64.722100182095915, 7.6562249177765755],
					[64.722100173889487, 7.6562249034902292],
					[64.722100155682611, 7.6562248750065205],
					[64.722100144804116, 7.6562248629518095],
					[64.721908444577366, 7.656022062554678],
					[64.721908440035151, 7.6560220564828843],
					[64.72190842917567, 7.6560220465264512],
					[64.721908422858462, 7.6560220426418057],
					[64.721808446435389, 7.6559442589694147],
					[64.721733463442433, 7.6558387834035067],
					[64.721733461648384, 7.6558387834925385],
					[64.721733459816264, 7.6558387793853804],
					[64.721733458919218, 7.6558387794298985],
					[64.721680758701879, 7.6557692780683269],
					[64.721680755075695, 7.6557692740502192],
					[64.721680746010264, 7.655769264004932],
					[64.721680740571017, 7.6557692579777488],
					[64.721664079427086, 7.6557526964245444],
					[64.721655792744428, 7.6557221489867668],
					[64.721655789042117, 7.6557221365762933],
					[64.721655778946442, 7.6557221118889043],
					[64.721655772553049, 7.6557220996119906],
					[64.721450177369476, 7.6553638054512323],
					[64.721375180006021, 7.6552110112580047],
					[64.721375179109003, 7.6552110113025398],
					[64.721375179089975, 7.6552110092044732],
					[64.721375178192915, 7.65521100924901],
					[64.721222378086836, 7.654911009607333],
					[64.721222372609446, 7.6549109993843167],
					[64.721222360776693, 7.654910981080902],
					[64.721222355318375, 7.6549109729559399],
					[64.721161255056629, 7.6548359747685302],
					[64.721161246888158, 7.6548359646791972],
					[64.721161229692299, 7.6548359487411988],
					[64.721161220645882, 7.6548359407944622],
					[64.721000121121321, 7.6547137411695774],
					[64.721000106673714, 7.6547137313922056],
					[64.721000078713558, 7.6547137159889846],
					[64.721000064303993, 7.6547137104077079],
					[64.720991763674789, 7.6547109091234713],
					[64.720991753788454, 7.6547109075155095],
					[64.720991734893786, 7.654710902156995],
					[64.720991725026522, 7.6547109026470714],
					[64.720969425401748, 7.6547081018856034],
					[64.720969423607698, 7.6547081019747081],
					[64.720786124021885, 7.6546859017578388],
					[64.720786117723662, 7.6546858999716871],
					[64.720786106062334, 7.6546859005508727],
					[64.720786099783155, 7.6546859008627415],
					[64.720772200395189, 7.654685900644826],
					[64.720755608713489, 7.6546858998113629],
					[64.720572236135908, 7.6546692027885825],
					[64.720541753206774, 7.654660906291765],
					[64.72054173973234, 7.654660904862058],
					[64.720541712783529, 7.6546609020026253],
					[64.720541700206113, 7.6546609005283663],
					[64.720511106836597, 7.6546609003851289],
					[64.720361192829117, 7.654649806274775],
					[64.720322346407769, 7.6546081638923793],
					[64.720322331024988, 7.6546081499638889],
					[64.720322297606486, 7.6546081264365391],
					[64.720322279570723, 7.6546081168376823],
					[64.72025287992696, 7.6545776178549207],
					[64.720252879010872, 7.6545776158014975],
					[64.720252876319833, 7.6545776159351622],
					[64.720252873609681, 7.6545776139708481],
					[64.720183446678703, 7.6545498429998391],
					[64.72009188067095, 7.6543555145626323],
					[64.720091877941783, 7.6543555105003849],
					[64.720091873361383, 7.6543555002333417],
					[64.720091870632189, 7.6543554961710765],
					[64.720061284643833, 7.6543055187429223],
					[64.720039092003191, 7.6542277441131503],
					[64.720039091087074, 7.654227742059736],
					[64.720039090151928, 7.6542277379083776],
					[64.720039089216812, 7.6542277337570175],
					[64.719964094765203, 7.6540138512435272],
					[64.719947398952854, 7.6539055776246778],
					[64.719933498649382, 7.6537110870078857],
					[64.719933486587749, 7.6537110435303521],
					[64.719933446432435, 7.6537109699655508],
					[64.719933417422624, 7.6537109378248909],
					[64.719864017176235, 7.6536609386136902],
					[64.719864000037418, 7.6536609289710409],
					[64.719863965778941, 7.6536609117836827],
					[64.719863947781249, 7.6536609063815328],
					[64.719841777125524, 7.6536554135902319],
					[64.719641802970528, 7.653535927352519],
					[64.719641777758525, 7.6535359181113201],
					[64.719641727372647, 7.6535359038248032],
					[64.719641700423765, 7.6535359009665749],
					[64.719558304967975, 7.6535359010167374],
					[64.719497227366077, 7.6535331000256202],
					[64.719413944627604, 7.6535137051620685],
					[64.71941391139957, 7.6535137026160047],
					[64.71941384416084, 7.6535137101560089],
					[64.719413811047204, 7.6535137201974459],
					[64.719336010994354, 7.6535526215755381],
					[64.719336009200305, 7.6535526216647112],
					[64.719336007425326, 7.6535526238517937],
					[64.719336005631291, 7.6535526239409668],
					[64.71925278887808, 7.6535969784906834],
					[64.719191776411378, 7.6535720145140669],
					[64.719191770113142, 7.653572012728274],
					[64.719191758413672, 7.6535720091121044],
					[64.719191753012439, 7.6535720072817268],
					[64.719100052599686, 7.6535470072368543],
					[64.719099971943692, 7.6535470196412447],
					[64.719099846149547, 7.6535471014521708],
					[64.719099801908385, 7.6535471708140994],
					[64.719069213039461, 7.6537526962179738],
					[64.718994269991043, 7.6538165480578968],
					[64.718994267319019, 7.6538165502895312],
					[64.718994261097052, 7.6538165568952206],
					[64.718994259322059, 7.6538165590822569],
					[64.718972128727003, 7.6538386870503432],
					[64.718933329651932, 7.6538331013411121],
					[64.718758332249564, 7.6538054021554585],
					[64.718741733077664, 7.6538026024035064],
					[64.718741673034202, 7.6538026116838056],
					[64.71874157027662, 7.653802660865435],
					[64.718741524890532, 7.6538027029983589],
					[64.718688909809984, 7.6538967732948047],
					[64.718627979043561, 7.653774911709486],
					[64.718627976295309, 7.6537749055496418],
					[64.718627969004714, 7.6537748933191336],
					[64.718627966256491, 7.653774887159309],
					[64.718558465835372, 7.6536720872642725],
					[64.718558464022252, 7.6536720852555691],
					[64.718558461293071, 7.6536720811936174],
					[64.718558458563862, 7.6536720771316675],
					[64.718522358614024, 7.653624876993157],
					[64.718522347754401, 7.6536248670389133],
					[64.718522326016128, 7.6536248450325788],
					[64.718522314259474, 7.6536248351229181],
					[64.718414029162943, 7.6535498460399491],
					[64.718400150828515, 7.6535359681691446],
					[64.718291859551854, 7.653391579784917],
					[64.718291853196405, 7.6533915717057246],
					[64.718291837794411, 7.6533915556811314],
					[64.718291829644954, 7.6533915476911183],
					[64.718252941171556, 7.6533581584249548],
					[64.718177951091761, 7.6532720693766318],
					[64.718177944755411, 7.6532720633953373],
					[64.718177933876675, 7.653272051343543],
					[64.718177928437342, 7.6532720453176362],
					[64.718094543921609, 7.6532026604842152],
					[64.7180640565565, 7.6531637771472605],
					[64.718064039341371, 7.653163759114296],
					[64.718063997773115, 7.653163727600786],
					[64.718063976111054, 7.653163713986447],
					[64.718002877986208, 7.6531387164305764],
					[64.717919479186406, 7.6531026151257944],
					[64.717919457600615, 7.6531026099027866],
					[64.717919415345079, 7.6531026015099499],
					[64.717919392900384, 7.6531026005271361],
					[64.717841692825672, 7.6531053996378766],
					[64.717841691031637, 7.6531053997270737],
					[64.717841687443538, 7.6531053999054715],
					[64.717841684752486, 7.6531054000392675],
					[64.717766686265847, 7.6531109002065678],
					[64.717688886026949, 7.6531165018619163],
					[64.717688885129917, 7.6531165019065144],
					[64.717613884828125, 7.6531219999987927],
					[64.717613854386499, 7.6531220078084496],
					[64.717613793598574, 7.6531220339166737],
					[64.717613766840429, 7.6531220520368466],
					[64.717560972868085, 7.6531692452832223],
					[64.717391633229013, 7.6532970008625698],
					[64.717383299681671, 7.6532969995859226],
					[64.717383296990576, 7.6532969997196982],
					[64.717383289814407, 7.6532970000764324],
					[64.717383287123312, 7.6532970002102081],
					[64.717169394343898, 7.6533109005643167],
					[64.717163900279346, 7.653310900843441],
					[64.717163897588293, 7.6533109009772113],
					[64.717163893981095, 7.6533108990578373],
					[64.717163892187045, 7.6533108991470202],
					[64.716949996069815, 7.653319200162449],
					[64.716941760354871, 7.6533192003103396],
					[64.716933410325794, 7.6533137328017098],
					[64.716933404027586, 7.6533137310161194],
					[64.716933394084037, 7.6533137231157076],
					[64.71693338868279, 7.6533137212855289],
					[64.716719528006124, 7.6532081410361741],
					[64.716614056058546, 7.6530776731113264],
					[64.716614048825136, 7.6530776671749976],
					[64.716614035236248, 7.6530776531600662],
					[64.71661402798378, 7.6530776451260394],
					[64.716597330599924, 7.6530637490664919],
					[64.71649184009658, 7.6529692559737947],
					[64.71636954897896, 7.6528387659602473],
					[64.716264051381373, 7.6527165688760919],
					[64.7162640468581, 7.6527165649037965],
					[64.716264036895424, 7.6527165549061298],
					[64.716264030559032, 7.6527165489253832],
					[64.716177935601237, 7.6526415531199348],
					[64.716041846728089, 7.6525081637136765],
					[64.715897356650999, 7.6523359771134452],
					[64.715814061169567, 7.6522220812541226],
					[64.715814036739999, 7.6522220593849655],
					[64.71581398343389, 7.6522220200650404],
					[64.715813951904465, 7.6522220069434326],
					[64.715752851902735, 7.6522054079455977],
					[64.715752839306205, 7.6522054043750849],
					[64.71575281325434, 7.6522054014739229],
					[64.715752799779878, 7.6522054000456645],
					[64.715591700227506, 7.6522053998482491],
					[64.715591695742418, 7.6522054000713684],
					[64.715591686772143, 7.6522054005176123],
					[64.715591682287013, 7.6522054007407361],
					[64.715530582313619, 7.6522109018956863],
					[64.715530581416587, 7.6522109019403093],
					[64.715530579622538, 7.6522109020295588],
					[64.715363880358311, 7.6522276015186286],
					[64.715363832949578, 7.6522276185668687],
					[64.715363753572348, 7.652227672880807],
					[64.715363720706875, 7.6522277101911049],
					[64.715333121347626, 7.6522888101979794],
					[64.715333121347626, 7.6522888101979794],
					[64.715294220993528, 7.6523666118834734],
					[64.715294220115553, 7.6523666140257056],
					[64.715294218359674, 7.6523666183101309],
					[64.715294216584724, 7.6523666204969611],
					[64.715216525017709, 7.65254430070281],
					[64.71514157742196, 7.652649828023069],
					[64.715049942172456, 7.6526776093987561],
					[64.715049931427217, 7.6526776120316242],
					[64.715049908199973, 7.6526776236793168],
					[64.71504989837085, 7.6526776283651516],
					[64.714919298358126, 7.6527554283807495],
					[64.714919273413088, 7.65275544850779],
					[64.714919231672596, 7.6527554967507241],
					[64.714919214877085, 7.6527555248666213],
					[64.714888717442506, 7.6528305208532883],
					[64.714849819188302, 7.6529138142659114],
					[64.714849818329427, 7.6529138185056516],
					[64.714849815676487, 7.6529138228345692],
					[64.714849813901509, 7.6529138250213276],
					[64.714774822182235, 7.6530999074062569],
					[64.714699836151254, 7.6532137853803901],
					[64.714638740649207, 7.6532970792470598],
					[64.714516542946924, 7.6534526764071478],
					[64.714516542049921, 7.6534526764517281],
					[64.714516541171946, 7.653452678593843],
					[64.714516540293999, 7.6534526807359597],
					[64.714491548782064, 7.6534859687223271],
					[64.714477658959879, 7.6534998584644418],
					[64.714477653615873, 7.6534998629269797],
					[64.714477645618899, 7.6534998717183393],
					[64.714477642068886, 7.6534998760917219],
					[64.71443874505897, 7.6535498727205518],
					[64.714338747816484, 7.6536665709800271],
					[64.714338746919424, 7.6536665710246021],
					[64.714338746041449, 7.6536665731667171],
					[64.714338745144445, 7.6536665732112894],
					[64.714255444734775, 7.6537692731365956],
					[64.714255443856842, 7.6537692752786786],
					[64.714255442959796, 7.6537692753232491],
					[64.71419424339787, 7.6538470758499555],
					[64.714072057276724, 7.6539998596293319],
					[64.714041573325517, 7.6540248451380135],
					[64.714041559106505, 7.6540248605334984],
					[64.714041533359563, 7.6540248911907822],
					[64.7140415209727, 7.6540249106921605],
					[64.71403594161383, 7.6540359696808578],
					[64.713874904864866, 7.6541609193002049],
					[64.713813838582325, 7.6541804084117562],
					[64.713813835910315, 7.6541804106429048],
					[64.713813828753146, 7.6541804130968485],
					[64.713813826062093, 7.6541804132305131],
					[64.713730525779127, 7.6542137146567235],
					[64.713730518621944, 7.6542137171106326],
					[64.713730505223722, 7.6542137240713668],
					[64.713730498982656, 7.6542137285782141],
					[64.713683199379233, 7.6542415275314353],
					[64.713683197585141, 7.6542415276205418],
					[64.713683196688137, 7.6542415276650955],
					[64.713586011318696, 7.654299820078819],
					[64.713494330133742, 7.6543359124721109],
					[64.71344715933769, 7.6543526019207819],
					[64.713363887357559, 7.6543580993008087],
					[64.713280587604999, 7.654363700844474],
					[64.713224990297661, 7.6543664996938663],
					[64.713224989400658, 7.6543664997384147],
					[64.713224987606608, 7.6543664998275096],
					[64.713224986709562, 7.654366499872058],
					[64.713141686937561, 7.6543720006351457],
					[64.713141684246466, 7.6543720007687899],
					[64.713141677070269, 7.6543720011251706],
					[64.713141674379187, 7.6543720012588148],
					[64.713058273801138, 7.6543831012515229],
					[64.713058272904121, 7.6543831012960695],
					[64.712997234131862, 7.654391493862903],
					[64.712913989563532, 7.6543498205985046],
					[64.712913970649822, 7.6543498131443659],
					[64.712913933776463, 7.6543498044837603],
					[64.712913914900852, 7.6543498012244227],
					[64.712805614781118, 7.6543415004286057],
					[64.712805611193019, 7.6543415006067974],
					[64.712805604016793, 7.6543415009631834],
					[64.712805600409652, 7.654341499043972],
					[64.71280006236816, 7.6543414991897363],
					[64.71269181359267, 7.6542665357203514],
					[64.712691793743772, 7.6542665241160748],
					[64.712691751412095, 7.6542665073333538],
					[64.712691728929286, 7.6542665021549094],
					[64.712616760901582, 7.6542554069274003],
					[64.712516790755672, 7.6542054229193637],
					[64.712463992752092, 7.6541776236628225],
					[64.712463987331844, 7.6541776197353864],
					[64.712463973819297, 7.6541776141115294],
					[64.712463966624014, 7.6541776123705771],
					[64.712425105566169, 7.6541637253282548],
					[64.712341838981345, 7.654085955272028],
					[64.712272341423244, 7.6540165580520938],
					[64.712272333273773, 7.6540165500636483],
					[64.712272316115929, 7.6540165383260597],
					[64.712272307966501, 7.6540165303376115],
					[64.712241808406944, 7.6539970307667966],
					[64.712241806612894, 7.6539970308559138],
					[64.712180724137539, 7.6539581429986949],
					[64.712014038857404, 7.6537970551831584],
					[64.712014019886482, 7.653797041437584],
					[64.712013979291569, 7.6537970182748536],
					[64.712013956770662, 7.6537970089022398],
					[64.711930722805207, 7.6537720281694481],
					[64.711800163015582, 7.6535887842445769],
					[64.711800141277251, 7.6535887622436736],
					[64.711800090681621, 7.6535887248904233],
					[64.711800063618369, 7.6535887094489166],
					[64.71178350041582, 7.653583155777917],
					[64.711783499622641, 7.6535806001197457],
					[64.711783488419897, 7.6535805524160896],
					[64.71178344460013, 7.6535804706624271],
					[64.711783412861095, 7.6535804344705438],
					[64.711564013231595, 7.6534304352111233],
					[64.711563997905884, 7.6534304275796687],
					[64.711563968151523, 7.6534304122721997],
					[64.711563952844898, 7.6534304067380488],
					[64.711472254964363, 7.6534054074974334],
					[64.711425095262243, 7.6533915189913877],
					[64.711333426766615, 7.6533165447825828],
					[64.711333413215925, 7.6533165349648069],
					[64.711333384358539, 7.6533165196129938],
					[64.711333369032857, 7.6533165119816644],
					[64.711250068614746, 7.6532859112660496],
					[64.711250049739107, 7.6532859080076605],
					[64.711250011090726, 7.6532859015354493],
					[64.711249991337098, 7.6532859004189255],
					[64.711188894569091, 7.653288699663614],
					[64.711113899871378, 7.6532887002348478],
					[64.71102780036432, 7.6532887008468107],
					[64.711027797673239, 7.6532887009805499],
					[64.711027793169066, 7.6532886991061808],
					[64.711027791375017, 7.6532886991953397],
					[64.710966691933024, 7.6532915006361861],
					[64.710883293991969, 7.6532941995517323],
					[64.710883291300902, 7.6532941996854689],
					[64.710883286815786, 7.6532941999083617],
					[64.710883285021708, 7.6532941999975224],
					[64.710808284698004, 7.653299800915434],
					[64.710808275727715, 7.6532998013612206],
					[64.71080825690926, 7.6532998043946368],
					[64.710808247061067, 7.6532998069822469],
					[64.710747148331535, 7.6533165067699578],
					[64.710663855867139, 7.6533387050867416],
					[64.710541664263033, 7.6533609038599577],
					[64.710541661571938, 7.6533609039936854],
					[64.710541658880885, 7.6533609041274131],
					[64.710541657086807, 7.6533609042165658],
					[64.71044165717467, 7.653383105769012],
					[64.71044165538062, 7.6533831058581612],
					[64.710441650895476, 7.6533831060810371],
					[64.710441649101426, 7.6533831061701862],
					[64.710336048963271, 7.6534109067736784],
					[64.710336034648961, 7.6534109116812816],
					[64.710336007833547, 7.6534109235045724],
					[64.710335995332372, 7.6534109304202591],
					[64.710213794591084, 7.653485929041393],
					[64.710213790144067, 7.6534859334586525],
					[64.710213780333987, 7.6534859402405582],
					[64.710213774989953, 7.6534859447023882],
					[64.710158177093064, 7.6535304414914602],
					[64.710005499710888, 7.6536470255391187],
					[64.709991631331121, 7.6536526115851098],
					[64.70998323886424, 7.6536554099141867],
					[64.709891643044713, 7.6536831070038636],
					[64.709777744067154, 7.6537165088661974],
					[64.709777729752886, 7.6537165137735341],
					[64.709777702937402, 7.6537165255962742],
					[64.709777690436241, 7.6537165325116732],
					[64.709769335725696, 7.6537220040226543],
					[64.709555594954836, 7.6537331005095259],
					[64.70954170003219, 7.6537331004936933],
					[64.709541698238141, 7.6537331005828158],
					[64.70954169375301, 7.6537331008056251],
					[64.709541691958961, 7.6537331008947476],
					[64.709336095974251, 7.653741499372436],
					[64.70931943159033, 7.6537415005521199],
					[64.709244462821289, 7.653716510424343],
					[64.709244461027239, 7.6537165105134655],
					[64.709244458317073, 7.6537165085500245],
					[64.709244455626035, 7.6537165086837087],
					[64.709091756222008, 7.6536720077153966],
					[64.709091733758214, 7.6536720046352382],
					[64.709091688868824, 7.6536720026691434],
					[64.709091667340189, 7.6536720037386488],
					[64.709075004984712, 7.6536747952838589],
					[64.708869441604605, 7.6536304055066724],
					[64.708869429027175, 7.653630404033466],
					[64.708869402059221, 7.6536303990790824],
					[64.708869387706798, 7.6536303997921014],
					[64.708824990318988, 7.6536331005601292],
					[64.708694407552841, 7.6536386981998294],
					[64.708650043352705, 7.6536331037673833],
					[64.708588961100816, 7.6536137082487601],
					[64.708588944935272, 7.6536137069538288],
					[64.708588912566086, 7.6536137001697977],
					[64.708588897316659, 7.6536137009273792],
					[64.708419464443779, 7.653616499701875],
					[64.708327918194726, 7.6535498370665822],
					[64.70832790105591, 7.6535498274280238],
					[64.708327864125351, 7.6535498124787331],
					[64.708327846108574, 7.6535498049817949],
					[64.708197260977897, 7.6535192083795636],
					[64.708061176204154, 7.6534637157673799],
					[64.708061158206448, 7.6534637103676246],
					[64.708061124024141, 7.6534637015759976],
					[64.708061106064534, 7.6534637003703141],
					[64.707969406228969, 7.6534609008260004],
					[64.70796940264087, 7.6534609010042702],
					[64.70796939454857, 7.6534608993083495],
					[64.707969391857517, 7.6534608994420505],
					[64.707833306061659, 7.6534665004580473],
					[64.707747219007814, 7.6534581016291563],
					[64.707747211812546, 7.6534580998886828],
					[64.707747195666073, 7.6534581006908899],
					[64.707747187573759, 7.6534580989949834],
					[64.707602804449564, 7.6534664996672612],
					[64.707519419558693, 7.6534581009192708],
					[64.707519404309267, 7.653458101676903],
					[64.707519372016307, 7.653458103281304],
					[64.70751935586982, 7.6534581040835032],
					[64.707383274749361, 7.6534887016376416],
					[64.70729719255597, 7.6534914992543834],
					[64.707297191658952, 7.6534914992989496],
					[64.707161121977649, 7.6534969984837176],
					[64.707077852199788, 7.6534748065825884],
					[64.707077842313439, 7.653474804975847],
					[64.707077824334789, 7.6534748016732337],
					[64.707077813551379, 7.6534748001110389],
					[64.707000013698888, 7.6534692016351826],
					[64.707000009194687, 7.6534691997610356],
					[64.706999998430391, 7.6534692002958193],
					[64.7069999930482, 7.6534692005632117],
					[64.706924993240037, 7.6534719993537266],
					[64.706847230248485, 7.653474799039885],
					[64.706663965600214, 7.6534109101060865],
					[64.70666395661091, 7.6534109084548243],
					[64.70666393861319, 7.6534109030553541],
					[64.706663929623844, 7.6534109014040936],
					[64.706627872052323, 7.6534054090101034],
					[64.706597326001571, 7.6533859436954002],
					[64.706536240668356, 7.6533248593761796],
					[64.706536235228981, 7.6533248533528262],
					[64.706536221659206, 7.6533248414398392],
					[64.706536215322814, 7.6533248354610528],
					[64.706397314662937, 7.6532276368913301],
					[64.706397284011487, 7.6532276216315127],
					[64.706397221028993, 7.6532276037824545],
					[64.7063971868848, 7.6532275991854535],
					[64.706352788611781, 7.6532304010625207],
					[64.706222195935339, 7.6532358997939971],
					[64.706177800014331, 7.6532359009027431],
					[64.706177772225573, 7.6532359043813631],
					[64.706177717621401, 7.6532359196815678],
					[64.706177691702962, 7.6532359314586005],
					[64.706138791920964, 7.6532609321186786],
					[64.706138791023932, 7.6532609321632492],
					[64.706138790126914, 7.6532609322078207],
					[64.705999890255811, 7.6533526319990006],
					[64.705999883136798, 7.6533526386461821],
					[64.705999870654679, 7.653352647657643],
					[64.705999864413627, 7.6533526521633641],
					[64.705963808291401, 7.6533859129920572],
					[64.705947166565707, 7.6533887033446097],
					[64.705947137918045, 7.6533887110613303],
					[64.705947083428228, 7.6533887389423567],
					[64.705947059380108, 7.6533887590174965],
					[64.705924858649013, 7.653410959766517],
					[64.705924855079971, 7.6534109620416473],
					[64.705924848857975, 7.653410968644212],
					[64.705924845308019, 7.6534109730162294],
					[64.705810947891905, 7.6535498698133946],
					[64.705727650305974, 7.6536442678040766],
					[64.705727649427985, 7.6536442699455076],
					[64.705727645858957, 7.65364427222059],
					[64.705727644083964, 7.6536442744065578],
					[64.705658144415551, 7.6537304742746466],
					[64.705658144415551, 7.6537304742746466],
					[64.705658141743513, 7.6537304765051584],
					[64.705658141762584, 7.6537304786020091],
					[64.705527644169038, 7.6538998745074789],
					[64.705513745750636, 7.6539165729515499],
					[64.705513744853633, 7.6539165729960974],
					[64.705513741303619, 7.6539165773679825],
					[64.705513740425673, 7.6539165795093673],
					[64.705505444007883, 7.6539276755522865],
					[64.705488754768425, 7.6539470613712313],
					[64.705352662435857, 7.6540776555882548],
					[64.705352660641807, 7.6540776556773391],
					[64.705352658866815, 7.6540776578632483],
					[64.705283158867758, 7.6541470585762603],
					[64.705283151748702, 7.654147065223059],
					[64.705283139342782, 7.6541470826211935],
					[64.70528313312073, 7.6541470892234509],
					[64.705208133238671, 7.6542609898123271],
					[64.705085939897103, 7.6544470794564257],
					[64.705069247951826, 7.6544665696720147],
					[64.705069238198845, 7.6544665827426721],
					[64.70506922142205, 7.65446661294403],
					[64.705069214360094, 7.6544666258811063],
					[64.705063724302306, 7.6544805023366358],
					[64.70503313886536, 7.6545220813252088],
					[64.7050331379683, 7.6545220813697368],
					[64.705033137090297, 7.6545220835110657],
					[64.705033137109353, 7.6545220856078675],
					[64.704933138423129, 7.6546637822914105],
					[64.704841543091206, 7.6547887740665548],
					[64.704788751949806, 7.6548498640317364],
					[64.704649855890693, 7.6549942602410823],
					[64.704649854115686, 7.6549942624268938],
					[64.704649852359722, 7.6549942667094575],
					[64.704649849668655, 7.6549942668429889],
					[64.704627649908133, 7.6550192676979112],
					[64.704627644583113, 7.6550192742552969],
					[64.704627633952072, 7.6550192894668232],
					[64.704627628627037, 7.6550192960241903],
					[64.704619236331652, 7.6550331839857604],
					[64.704483147761451, 7.6551998719377483],
					[64.704399852715781, 7.6552942642166562],
					[64.704330455655722, 7.6553665616215927],
					[64.704185956772307, 7.6555137604006855],
					[64.704185948775191, 7.6555137691880812],
					[64.704185934594065, 7.6555137887706053],
					[64.704185928390999, 7.6555137974690171],
					[64.704177665717239, 7.6555276345674113],
					[64.70416933672368, 7.6555304096667278],
					[64.704169319737261, 7.6555304168022893],
					[64.704169289371578, 7.6555304329921654],
					[64.70416927511431, 7.6555304441877254],
					[64.704155480011906, 7.6555415392033153],
					[64.703994286797695, 7.6556526345183844],
					[64.703994282350607, 7.6556526389342645],
					[64.703994274334477, 7.6556526456248335],
					[64.703994269868389, 7.6556526479439908],
					[64.703955502223664, 7.6556859202040828],
					[64.703888862624893, 7.6557054021819253],
					[64.703797187272954, 7.6557136990962533],
					[64.703727791920144, 7.6557165006928551],
					[64.70372778564095, 7.6557165010042461],
					[64.703727773979594, 7.6557165015825408],
					[64.703727767719428, 7.6557165039906341],
					[64.70365827804018, 7.6557276001366672],
					[64.703574988920678, 7.6557331002070645],
					[64.703505591752219, 7.6557358997170244],
					[64.703505580090862, 7.6557359002953058],
					[64.703505555012143, 7.6557359057342058],
					[64.703505543369815, 7.6557359084091718],
					[64.703430563425414, 7.6557581025131753],
					[64.703361089343034, 7.6557636997848659],
					[64.703277792769413, 7.6557665004137894],
					[64.703277765896644, 7.6557665059415756],
					[64.703277714880258, 7.6557665210570569],
					[64.703277689858666, 7.6557665327859006],
					[64.703163803563442, 7.6558415248852079],
					[64.703055519756916, 7.6558887159951823],
					[64.703055512618732, 7.6558887205443016],
					[64.703055500117458, 7.6558887274569507],
					[64.703055492979274, 7.6558887320060691],
					[64.702980520818258, 7.6559359135218692],
					[64.702836071149761, 7.6559720010709089],
					[64.702766691909147, 7.6559747989826459],
					[64.702766691012087, 7.6559747990271214],
					[64.702766688321034, 7.6559747991605427],
					[64.702766685629925, 7.655974799293964],
					[64.702605593386181, 7.6559859009319062],
					[64.7025444002348, 7.655985899613861],
					[64.702544398440764, 7.6559858997028067],
					[64.702544394852609, 7.6559858998807],
					[64.70254439305856, 7.6559858999696457],
					[64.702386145069653, 7.655991497001823],
					[64.702294503870846, 7.6559442290515092],
					[64.702164015300497, 7.6558526370012103],
					[64.702164009880292, 7.6558526330749075],
					[64.702163999039811, 7.6558526252222849],
					[64.702163993638635, 7.6558526233925628],
					[64.701980720270029, 7.6557554377954125],
					[64.70193624731769, 7.6557109650041788],
					[64.701889061987359, 7.6556554828062895],
					[64.701705770776627, 7.6553554959043968],
					[64.70170575987899, 7.6553554817624505],
					[64.70170573720587, 7.6553554556196284],
					[64.701705725430301, 7.6553554436187357],
					[64.701500134368814, 7.655191550174286],
					[64.701477968446653, 7.655169285940004],
					[64.701469583779087, 7.6551499205853908],
					[64.701469582863027, 7.6551499185333602],
					[64.701469581946938, 7.6551499164813146],
					[64.701469581030864, 7.6551499144292876],
					[64.701255781312184, 7.6546943139755488],
					[64.701255776769912, 7.6546943079085681],
					[64.701255767647282, 7.6546942915816008],
					[64.701255764002056, 7.6546942854701028],
					[64.701216870443503, 7.654638793444323],
					[64.701027974732909, 7.6542943038591869],
					[64.701027962900099, 7.6542942855695584],
					[64.701027934787433, 7.6542942534059346],
					[64.701027920301627, 7.6542942394428897],
					[64.70097233687008, 7.6542526523395793],
					[64.700866854285565, 7.6541304716389389],
					[64.700822357806089, 7.6540748776284335],
					[64.700797359642863, 7.6540415803881174],
					[64.700797358726803, 7.6540415783361784],
					[64.700797357829742, 7.6540415783807125],
					[64.7007973578107, 7.6540415762842384],
					[64.700700157833126, 7.653916576014165],
					[64.700700156020019, 7.6539165740067743],
					[64.700700150580673, 7.6539165679845826],
					[64.700700148767552, 7.6539165659771937],
					[64.700675149386853, 7.6538887664126598],
					[64.700675147592804, 7.6538887665017361],
					[64.700675146676744, 7.6538887644498095],
					[64.700675146676744, 7.6538887644498095],
					[64.700569558772656, 7.6537748764624167],
					[64.700530791116591, 7.6537138269610843],
					[64.700516897796689, 7.6536249682985966],
					[64.700516896899686, 7.6536249683431423],
					[64.700486297611306, 7.6534388692471493],
					[64.700469598431241, 7.6533277709940188],
					[64.700469524990396, 7.6533276886486235],
					[64.700469347761342, 7.6533276408221234],
					[64.70046924397316, 7.6533276753410187],
					[64.700447043933011, 7.6533554758133286],
					[64.70044704303595, 7.653355475857885],
					[64.700447042158004, 7.6533554779988862],
					[64.700447041260972, 7.6533554780434425],
					[64.700338808144721, 7.6534969891919529],
					[64.700280618997695, 7.6534915001069281],
					[64.700280617203617, 7.653491500196032],
					[64.700280613615504, 7.6534915003742352],
					[64.700280611821455, 7.6534915004633364],
					[64.700188912439089, 7.6534858992140427],
					[64.700188875699141, 7.6534859052335031],
					[64.700188806799588, 7.6534859275317872],
					[64.700188774640083, 7.6534859438106455],
					[64.70011927511878, 7.6535415445415822],
					[64.700119273343773, 7.6535415467271033],
					[64.700119267980639, 7.6535415490908187],
					[64.700119266205661, 7.6535415512763363],
					[64.70008877198876, 7.653569245064058],
					[64.699980477899302, 7.6536526428337366],
					[64.699980467211191, 7.6536526517539389],
					[64.699980447648159, 7.6536526716016402],
					[64.699980438773181, 7.6536526825291631],
					[64.699927641419364, 7.6537248799605742],
					[64.699897126010612, 7.6537636714938708],
					[64.699858355083336, 7.6537526077389852],
					[64.699858311090878, 7.6537526057287186],
					[64.699858225071594, 7.6537526204867259],
					[64.699858184838774, 7.653752637165919],
					[64.699744284968958, 7.6538331364376919],
					[64.699744283193951, 7.6538331386231624],
					[64.699744281399902, 7.6538331387122387],
					[64.699744279624909, 7.6538331408977074],
					[64.699677686942849, 7.6538831340221725],
					[64.699569295604491, 7.6539498288560361],
					[64.699555497634137, 7.6539581271767272],
					[64.699447097921421, 7.6540220281904121],
					[64.699385997719929, 7.6540581276782271],
					[64.699263803565671, 7.6541304244231831],
					[64.699224911054671, 7.6541498213450501],
					[64.69920271075182, 7.6541609211623403],
					[64.699080510825112, 7.6542220218944852],
					[64.699019318380337, 7.6542526175386483],
					[64.699005530819633, 7.6542581130442517],
					[64.698988835611985, 7.6542637109160223],
					[64.698988832023886, 7.6542637110941127],
					[64.698988823091724, 7.654263715732009],
					[64.698988817728605, 7.6542637180955015],
					[64.698822118375176, 7.654338718709667],
					[64.698822115684081, 7.6543387188432277],
					[64.698822112993028, 7.6543387189767875],
					[64.698822111218021, 7.6543387211621479],
					[64.698783221348208, 7.6543581152326894],
					[64.698722131160622, 7.6543804125744703],
					[64.698722130263576, 7.6543804126189903],
					[64.698722127572481, 7.6543804127525448],
					[64.69872212669452, 7.6543804148933763],
					[64.698630527183184, 7.6544165140647653],
					[64.698630526286152, 7.6544165141092817],
					[64.698630525389135, 7.654416514153799],
					[64.698555531304635, 7.6544470114847218],
					[64.698463842284141, 7.6544776072852754],
					[64.69843324797084, 7.6544859079916669],
					[64.698433244382727, 7.6544859081697254],
					[64.698433236328526, 7.6544859106666641],
					[64.698433231862424, 7.6544859129855283],
					[64.698333234146133, 7.6545220107967964],
					[64.69823323702218, 7.6545554091797658],
					[64.698233235247187, 7.654555411365064],
					[64.698233232556106, 7.6545554114985999],
					[64.698233231659074, 7.6545554115431118],
					[64.698202731877146, 7.6545665113835097],
					[64.698111034187022, 7.654599810107988],
					[64.698036036782938, 7.6546248097089356],
					[64.697952738444286, 7.6546526097523175],
					[64.697883239284621, 7.6546748091319916],
					[64.697883236593569, 7.6546748092655106],
					[64.697883232127467, 7.6546748115842957],
					[64.697883230352474, 7.6546748137695584],
					[64.69783883621254, 7.6546915101388313],
					[64.697691641625809, 7.6547359081808217],
					[64.69769163983176, 7.6547359082698305],
					[64.697691633571623, 7.654735910677597],
					[64.697691631796602, 7.6547359128628605],
					[64.697661032389902, 7.6547470130194233],
					[64.697661030576811, 7.6547470110121987],
					[64.69766102969885, 7.6547470131529334],
					[64.697661028801804, 7.6547470131974391],
					[64.697638834417248, 7.6547554104097664],
					[64.697441637954796, 7.6548192103944359],
					[64.697441588025953, 7.6548192464260092],
					[64.69744151887663, 7.6548193400344982],
					[64.697441499675236, 7.6548193997076641],
					[64.697441499966232, 7.6548220001756935],
					[64.69743330012912, 7.6548220001174272],
					[64.697433281291524, 7.6548220010519543],
					[64.697433243673501, 7.6548220092096697],
					[64.697433224874018, 7.6548220143366237],
					[64.697419331417706, 7.6548276109441868],
					[64.697236036732392, 7.6548887090828854],
					[64.69721103699338, 7.654897010326648],
					[64.697211029836225, 7.6548970127788509],
					[64.697211017315908, 7.654897017594223],
					[64.697211011055728, 7.6548970200019077],
					[64.697188822330347, 7.6549081156907919],
					[64.697036034883382, 7.6549609110700763],
					[64.697036033089319, 7.6549609111590682],
					[64.697036031295283, 7.6549609112480583],
					[64.697036030417308, 7.6549609133887389],
					[64.696991630874976, 7.6549776111161485],
					[64.696930534334726, 7.6549998114843936],
					[64.696838837156548, 7.6550304102117188],
					[64.696838833568435, 7.655030410389692],
					[64.696838829102333, 7.6550304127083253],
					[64.696838826430294, 7.6550304149379942],
					[64.696769328701976, 7.6550581130545812],
					[64.696669331769385, 7.6550942127184554],
					[64.696669330872339, 7.6550942127629469],
					[64.696669329975308, 7.6550942128074375],
					[64.69666932907829, 7.6550942128519273],
					[64.696647129325825, 7.6551026139234812],
					[64.696647126634744, 7.6551026140569514],
					[64.696541627240137, 7.655144214342724],
					[64.696449927054573, 7.655180413593806],
					[64.696449925260509, 7.6551804136827819],
					[64.696449922588457, 7.6551804159124037],
					[64.696449921691439, 7.6551804159568899],
					[64.696411024275463, 7.6551970141401515],
					[64.696319326768048, 7.655233114337463],
					[64.696319324076939, 7.6552331144709198],
					[64.696319319610836, 7.6552331167894758],
					[64.696319316938812, 7.6552331190190595],
					[64.696258218256432, 7.6552609175298336],
					[64.696144319746892, 7.6553109166061883],
					[64.696144317952843, 7.6553109166951527],
					[64.696144316177808, 7.6553109188802342],
					[64.696144315280819, 7.6553109189247159],
					[64.696097116320985, 7.6553331186065492],
					[64.696066618558419, 7.6553470161389603],
					[64.695877722195405, 7.6554276152395468],
					[64.695877676770522, 7.6554276531415981],
					[64.695877616572062, 7.6554277442022212],
					[64.695877600023451, 7.6554277995458602],
					[64.695877600303433, 7.655430399864052],
					[64.695869399567215, 7.6554303996610855],
					[64.695869378057594, 7.6554304028246491],
					[64.695869335954356, 7.6554304112034179],
					[64.695869315379824, 7.6554304185146931],
					[64.69568601772616, 7.655516516154476],
					[64.695647122079734, 7.655533115383566],
					[64.695647119407667, 7.6555331176130661],
					[64.695647113147487, 7.655533120020463],
					[64.695647109559388, 7.6555331201983581],
					[64.695602713253464, 7.6555554191842754],
					[64.695494315906231, 7.6556054177607953],
					[64.695494315028256, 7.6556054199013497],
					[64.695494312337146, 7.6556054200347647],
					[64.695494311440115, 7.6556054200792367],
					[64.695427717385201, 7.6556387171549565],
					[64.695327722696177, 7.6556804159016139],
					[64.695327720005068, 7.6556804160350209],
					[64.695327713763916, 7.6556804205384061],
					[64.695327711072864, 7.6556804206718114],
					[64.695305510676036, 7.6556915217990333],
					[64.695205517715024, 7.6557415178605961],
					[64.695113827229392, 7.6557776142127292],
					[64.695113826332332, 7.6557776142571949],
					[64.695113823641279, 7.6557776143905887],
					[64.695113821866258, 7.6557776165755547],
					[64.695061021960441, 7.6557998154066489],
					[64.695061021063438, 7.6557998154511111],
					[64.695061020166392, 7.6557998154955769],
					[64.694977719788568, 7.6558359149761577],
					[64.694977719807625, 7.6558359170721815],
					[64.694977718910579, 7.6558359171166455],
					[64.694977718013561, 7.6558359171611068],
					[64.694922121089903, 7.6558609154526769],
					[64.694794324149143, 7.6559137141611817],
					[64.694794323252083, 7.6559137142056404],
					[64.694794322374094, 7.6559137163461131],
					[64.694794320580016, 7.655913716435033],
					[64.694755526300739, 7.655930414356158],
					[64.694724932169578, 7.655941511660763],
					[64.694724928600493, 7.6559415139346036],
					[64.694724924115334, 7.6559415141568996],
					[64.694724922340313, 7.6559415163418416],
					[64.694541628919382, 7.6560192125410378],
					[64.694533237301854, 7.6560220105425048],
					[64.694533187372699, 7.6560220465679443],
					[64.69453311913864, 7.656022142214808],
					[64.694533099917706, 7.6560221997846956],
					[64.694533099879735, 7.6560248649944027],
					[64.694344328069874, 7.6561026134687191],
					[64.694313831756588, 7.6561137118484703],
					[64.694266633011111, 7.6561304104669503],
					[64.694266632133107, 7.6561304126073715],
					[64.694266629442012, 7.656130412740727],
					[64.694266627647963, 7.6561304128296301],
					[64.694144327725255, 7.6561776125164496],
					[64.694144326847209, 7.656177614656861],
					[64.694144325053159, 7.6561776147457623],
					[64.694144324156127, 7.6561776147902121],
					[64.694083224150788, 7.6562026158724628],
					[64.694008232399909, 7.656233111266272],
					[64.693947138981486, 7.6562526095225145],
					[64.693947136309419, 7.6562526117518015],
					[64.693947129133193, 7.6562526121073766],
					[64.69394712556408, 7.6562526143811311],
					[64.693863826593244, 7.6562859136299206],
					[64.693755532279198, 7.656327611931637],
					[64.693747137066538, 7.65633040993173],
					[64.693747135272474, 7.6563304100206189],
					[64.693747131703347, 7.6563304122943272],
					[64.693747130806344, 7.6563304123387708],
					[64.693641630807534, 7.6563692123570393],
					[64.693558231040925, 7.6563998129994504],
					[64.693558230143921, 7.6563998130438904],
					[64.69355822655578, 7.6563998132216566],
					[64.693558225658776, 7.6563998132660966],
					[64.693488828227345, 7.6564276126795558],
					[64.693413829922051, 7.656455412633778],
					[64.693413829025019, 7.6564554126782189],
					[64.693413827249969, 7.6564554148630002],
					[64.693413826352938, 7.656455414907442],
					[64.693358227258784, 7.6564776133614494],
					[64.693222179849954, 7.6565303932688158],
					[64.693191735794258, 7.6565248035983409],
					[64.693191706173295, 7.6565248029688551],
					[64.693191646110407, 7.6565248101378716],
					[64.6931916165655, 7.6565248178919569],
					[64.693161017309308, 7.6565387175818547],
					[64.693161016431262, 7.6565387197221773],
					[64.693161011946145, 7.6565387199443551],
					[64.693161011049114, 7.6565387199887924],
					[64.692999920984349, 7.6566192161998092],
					[64.692969331292318, 7.6566304124120368],
					[64.692969284054044, 7.6566304483009526],
					[64.692969217613623, 7.6566305438518665],
					[64.692969200167511, 7.6566305992332371],
					[64.692969200216595, 7.6566331762182216],
					[64.692963817159978, 7.6566359170992353],
					[64.692780527619604, 7.6567109130229403],
					[64.692741633376926, 7.6567248121557876],
					[64.692741621753584, 7.6567248169250881],
					[64.692741601197952, 7.6567248263303682],
					[64.692741591368687, 7.6567248310108127],
					[64.692694307695675, 7.6567554209414883],
					[64.692588826650606, 7.6567970139249466],
					[64.692588824856543, 7.6567970140137982],
					[64.692588821287444, 7.6567970162873449],
					[64.692588818596349, 7.6567970164206223],
					[64.692519319064061, 7.656827615956356],
					[64.692519312822895, 7.6568276204590244],
					[64.692519300321507, 7.656827627368485],
					[64.692519293183324, 7.6568276319155766],
					[64.692413857325462, 7.6568941904809193],
					[64.692397243913675, 7.6568915032129015],
					[64.692300054717165, 7.6568637073181716],
					[64.692300003567482, 7.6568637077544883],
					[64.692299907737478, 7.6568637292743897],
					[64.692299863095172, 7.6568637545496152],
					[64.692199863356919, 7.6569581544467029],
					[64.69219986068488, 7.6569581566757723],
					[64.692199857115753, 7.6569581589492666],
					[64.692199855340689, 7.6569581611339181],
					[64.692147188282803, 7.6570136220010969],
					[64.692077932261853, 7.6569526497450848],
					[64.692077865634502, 7.6569526257866807],
					[64.692077732798168, 7.656952623977638],
					[64.692077666627128, 7.656952650318587],
					[64.691999866899138, 7.6570220512033265],
					[64.691999866002078, 7.6570220512477452],
					[64.691999863329997, 7.6570220534767932],
					[64.691999862451993, 7.6570220556170074],
					[64.691872119778367, 7.6571442001810697],
					[64.691850058203073, 7.6571442000865613],
					[64.691819507134809, 7.6571248314065583],
					[64.691819504424714, 7.657124829444018],
					[64.691819497210474, 7.6571248256077631],
					[64.691819493603347, 7.6571248236896157],
					[64.691688993991164, 7.6570554236440556],
					[64.691688985879864, 7.6570554198522531],
					[64.691688968760289, 7.6570554123130448],
					[64.691688960649003, 7.6570554085212237],
					[64.691627868795564, 7.657035912651315],
					[64.691527882775446, 7.6569942180457673],
					[64.691505689117406, 7.6569831204706675],
					[64.691505681006106, 7.6569831166789122],
					[64.691505663905488, 7.6569831112355793],
					[64.691505654897199, 7.6569831074882213],
					[64.691400054678738, 7.6569526087539597],
					[64.691400030401894, 7.6569526036660154],
					[64.691399980130214, 7.6569526019619421],
					[64.691399955032423, 7.6569526053014147],
					[64.691291676836329, 7.6569776003218388],
					[64.691283308273654, 7.6569775995588731],
					[64.691177816101828, 7.656969201009975],
					[64.691177802627379, 7.656969199580506],
					[64.691177776632529, 7.6569692029643521],
					[64.691177764971172, 7.6569692035417818],
					[64.691069382144263, 7.6569886997047805],
					[64.691055600285864, 7.6569887007025228],
					[64.691055596697751, 7.6569887008801896],
					[64.691055592193592, 7.6569886990065505],
					[64.691055590399529, 7.6569886990953835],
					[64.690947190104239, 7.6569942007469729],
					[64.690947189207236, 7.656994200791388],
					[64.690841694780488, 7.6569997996262416],
					[64.690833300203437, 7.6569998001429642],
					[64.690833286747988, 7.6569998008092046],
					[64.690833261650212, 7.6569998041485583],
					[64.690833249110781, 7.6569998068660894],
					[64.690727756832032, 7.6570276047999348],
					[64.690636067762313, 7.6570442023621661],
					[64.690597171786393, 7.657049801495357],
					[64.690597155658864, 7.6570498043904953],
					[64.690597127029946, 7.6570498141945125],
					[64.690597112715508, 7.6570498190965086],
					[64.69050551877514, 7.6570942172392904],
					[64.69043602607104, 7.6571220142744911],
					[64.690330526154156, 7.6571637143211584],
					[64.690330510961701, 7.6571637213631227],
					[64.690330482408839, 7.6571637395496079],
					[64.690330468132387, 7.6571637486428328],
					[64.690285985134494, 7.6572026352377174],
					[64.690255504430965, 7.6572192230013361],
					[64.690255499983863, 7.6572192274146955],
					[64.690255492845623, 7.6572192319612808],
					[64.690255489276524, 7.6572192342345726],
					[64.690155494734185, 7.6572859292880064],
					[64.690077701447024, 7.6573304268753217],
					[64.690077691617716, 7.6573304315550752],
					[64.690077675566215, 7.6573304428326008],
					[64.690077668446975, 7.6573304494747738],
					[64.690055471387964, 7.6573498472873389],
					[64.690024877550428, 7.657374841370908],
					[64.689902681684515, 7.6574637383154949],
					[64.689902680806512, 7.6574637404555279],
					[64.68990267723737, 7.6574637427287575],
					[64.689902674565317, 7.6574637449575871],
					[64.689833189694554, 7.6575192315638647],
					[64.689727721341612, 7.6575748151948595],
					[64.68971933684459, 7.6575776097516028],
					[64.689719330584396, 7.6575776121579846],
					[64.689719319858014, 7.657577616881964],
					[64.689719313597806, 7.6575776192883671],
					[64.689613833991515, 7.6576276093972337],
					[64.68953055493381, 7.657647004119041],
					[64.689530539722341, 7.6576470090649336],
					[64.68953051290643, 7.6576470208747454],
					[64.689530498610978, 7.6576470278718354],
					[64.689383202062004, 7.6577331260911574],
					[64.689347104634209, 7.6577526238773412],
					[64.689347103737163, 7.6577526239217288],
					[64.689347102840159, 7.6577526239661173],
					[64.689172148196789, 7.6578498006268259],
					[64.689163899890247, 7.6578497999268125],
					[64.689163881968653, 7.6578498029100821],
					[64.689163847022456, 7.657849808832256],
					[64.689163830016895, 7.6578498138667177],
					[64.689141630099741, 7.6578581138513382],
					[64.689141624717536, 7.6578581141176443],
					[64.68914161578526, 7.657858118752638],
					[64.689141612216147, 7.6578581210257521],
					[64.688972111947763, 7.6579415206998469],
					[64.688972110153713, 7.6579415207886079],
					[64.688972105687554, 7.6579415231060972],
					[64.688972103893491, 7.65794152319486],
					[64.688941604393946, 7.6579581249103663],
					[64.688941594564596, 7.657958129589673],
					[64.688941576737989, 7.6579581430506698],
					[64.688941567824656, 7.6579581497811784],
					[64.688805477613613, 7.6580776406254074],
					[64.688719288030029, 7.6581359330000218],
					[64.688719281788792, 7.6581359375017293],
					[64.688719271100453, 7.6581359464164143],
					[64.688719265756234, 7.6581359508737483],
					[64.688635971902343, 7.6582109469532735],
					[64.688499876713223, 7.6583165420971913],
					[64.688499874041099, 7.6583165443258236],
					[64.688499868696908, 7.6583165487830831],
					[64.688499866024799, 7.6583165510117341],
					[64.688469266051371, 7.6583442514853202],
					[64.688469263398275, 7.6583442558094683],
					[64.688469256259992, 7.6583442603554737],
					[64.688469252709879, 7.658344264723989],
					[64.688399852833498, 7.6584192641416857],
					[64.688399837773915, 7.6584192858510898],
					[64.688399814830916, 7.6584193289150289],
					[64.68839980606954, 7.658419352409422],
					[64.688355468699569, 7.6586024926411742],
					[64.688269499613924, 7.6585526259730585],
					[64.688269441174043, 7.6585526141878217],
					[64.688269327289177, 7.6585526240125201],
					[64.688269270966174, 7.6585526477623018],
					[64.688147176281106, 7.6586553587602753],
					[64.688050095245032, 7.6586026245873864],
					[64.688050058352886, 7.6586026138330645],
					[64.688049981991341, 7.6586026050304721],
					[64.68804994341906, 7.6586026069378397],
					[64.687911080075125, 7.6586441966565788],
					[64.687827820112204, 7.6586359007367237],
					[64.687827812935964, 7.6586359010915697],
					[64.687827798564484, 7.6586358997057893],
					[64.687827792285276, 7.6586359000162814],
					[64.687683313346739, 7.6586414990405007],
					[64.687600033144776, 7.6586276026097844],
					[64.687600016082229, 7.6586276013570833],
					[64.68759998287311, 7.6586276009027658],
					[64.687599965848491, 7.6586276038410039],
					[64.687455582801036, 7.6586526001889785],
					[64.687377802334069, 7.65865259924849],
					[64.687225022316724, 7.6586497995609095],
					[64.687155638855003, 7.658635903793968],
					[64.687155631659778, 7.658635902053379],
					[64.687155617288298, 7.6586359006676288],
					[64.687155610112058, 7.6586359010224641],
					[64.686988935568507, 7.658627602124354],
					[64.686927860965142, 7.6586081093202019],
					[64.686927843883595, 7.6586081059721334],
					[64.686927810636504, 7.6586081013270322],
					[64.686927793573915, 7.658608100074372],
					[64.686752836915048, 7.6586136987403526],
					[64.686705676172792, 7.6585942141213925],
					[64.686705651895991, 7.6585942090327928],
					[64.686705600670322, 7.6585942010840835],
					[64.686705576431493, 7.6585942001862621],
					[64.68652221944437, 7.6586164963361769],
					[64.686477860051326, 7.6586026088724024],
					[64.686477836690557, 7.6586026058348713],
					[64.686477791782067, 7.6586026017664519],
					[64.686477769356316, 7.6586026028753089],
					[64.686302812776233, 7.6586303949764742],
					[64.686255656086004, 7.6586165075541803],
					[64.686255635416344, 7.6586165043835814],
					[64.686255591404887, 7.6586165002708348],
					[64.686255568979107, 7.6586165013796696],
					[64.686080599855799, 7.6586441973385027],
					[64.686027831036327, 7.6586359015526995],
					[64.686027830139281, 7.6586359015970524],
					[64.685827830148654, 7.6586054014978275],
					[64.685827829251622, 7.6586054015421805],
					[64.685827825663509, 7.6586054017195915],
					[64.685827824766491, 7.6586054017639444],
					[64.685805631018468, 7.6586026021628797],
					[64.685586136910985, 7.6585609040112166],
					[64.685586127921695, 7.6585609023594525],
					[64.685586109046056, 7.6585608991002623],
					[64.685586100075781, 7.6585608995437982],
					[64.685569447313597, 7.658560899119113],
					[64.685355688586952, 7.6584554212413254],
					[64.685355655282905, 7.658455410310804],
					[64.685355586116586, 7.6584554032498451],
					[64.685355552048406, 7.6584554070306918],
					[64.685311112918626, 7.658466490370972],
					[64.685136170649244, 7.6583998135616529],
					[64.685136144559394, 7.6583998064669547],
					[64.685136091577604, 7.6583998027982911],
					[64.685136064647679, 7.6583998020337756],
					[64.685058313148801, 7.6584136939512959],
					[64.68490565935943, 7.6583665088768456],
					[64.684905636895664, 7.6583665057953203],
					[64.684905591987174, 7.6583665017274987],
					[64.684905569561408, 7.6583665028364729],
					[64.684813899805263, 7.6583803975315154],
					[64.684686129703763, 7.658360901350254],
					[64.684686112641188, 7.6583609000978168],
					[64.684686078554023, 7.658360901783448],
					[64.684686062426465, 7.6583609046771564],
					[64.684586092800458, 7.6583803977124418],
					[64.684455625261208, 7.6583637013836299],
					[64.6844556108897, 7.6583636999981453],
					[64.684455582184725, 7.6583637014176063],
					[64.684455566935185, 7.6583637021716946],
					[64.684355592603282, 7.6583803989904125],
					[64.684233318195652, 7.6583692003761676],
					[64.684233307431242, 7.6583692009084592],
					[64.684233285005476, 7.6583692020173961],
					[64.684233274241123, 7.6583692025496859],
					[64.684127793898284, 7.6583830985358547],
					[64.684013918827503, 7.6583748007230552],
					[64.683891723131197, 7.6583609006669091],
					[64.683891718646066, 7.6583609008886926],
					[64.683891713244876, 7.658360899059665],
					[64.683891709656749, 7.658360899237092],
					[64.683783317247517, 7.6583554012617174],
					[64.683647223643121, 7.6583387007345056],
					[64.683647221849057, 7.6583387008232187],
					[64.683647215569835, 7.6583387011337223],
					[64.683647212859739, 7.6583386991716411],
					[64.683563918934027, 7.658333100498532],
					[64.683411124772903, 7.6583137025327019],
					[64.683411121165761, 7.6583137006150004],
					[64.683411112176486, 7.6583136989634415],
					[64.68341110679431, 7.6583136992295877],
					[64.683333316164038, 7.6583108992822906],
					[64.683175024316327, 7.6582915009633092],
					[64.6831750207282, 7.6582915011407398],
					[64.683175012635928, 7.6582914994448457],
					[64.683175009047801, 7.658291499622278],
					[64.683113914258641, 7.6582886999958397],
					[64.682930618368331, 7.6582720014353365],
					[64.682930613883144, 7.658272001657128],
					[64.682930604893841, 7.6582720000056117],
					[64.682930600408724, 7.6582720002274014],
					[64.682883311968638, 7.6582719994568613],
					[64.682694422768648, 7.6582498010875177],
					[64.682694421871631, 7.6582498011318751],
					[64.682694420077567, 7.65824980122059],
					[64.682694418283518, 7.6582498013093065],
					[64.68266392055294, 7.6582470000116691],
					[64.682450043889048, 7.6582220049146548],
					[64.682441763959147, 7.6582192095757984],
					[64.682441751362759, 7.6582192060066845],
					[64.682441725291881, 7.6582192010079151],
					[64.682441711817432, 7.6582191995782383],
					[64.682213913351475, 7.6582054005048779],
					[64.682175014770635, 7.6582026018211105],
					[64.68199171458501, 7.6581887005810279],
					[64.681991710996883, 7.6581887007584628],
					[64.681991703801671, 7.6581886990183081],
					[64.681991700213501, 7.6581886991957466],
					[64.681975005264405, 7.6581886989649304],
					[64.681763910946074, 7.65817759969298],
					[64.68176390825495, 7.6581775998260575],
					[64.681763902872774, 7.6581776000922099],
					[64.681763900181679, 7.6581776002252875],
					[64.681747204335039, 7.6581776000441106],
					[64.681541712924883, 7.658169200088035],
					[64.681511118285869, 7.6581665001903394],
					[64.681511114697742, 7.6581665003677744],
					[64.681511111109629, 7.6581665005452102],
					[64.681511109315565, 7.6581665006339268],
					[64.681313911065601, 7.6581580994172267],
					[64.681275014269232, 7.6581553992991669],
					[64.681275013372215, 7.6581553993435252],
					[64.681275011578165, 7.6581553994322418],
					[64.681091711630842, 7.6581442005039415],
					[64.681091710733824, 7.6581442005482989],
					[64.681091709836778, 7.6581442005926599],
					[64.681038910901961, 7.658141500527833],
					[64.680861112213265, 7.6581304007034996],
					[64.680861110419201, 7.658130400792218],
					[64.680802811992663, 7.6581275996588776],
					[64.680641713631928, 7.6581164999344988],
					[64.68064171183785, 7.6581165000232172],
					[64.680641709146769, 7.658116500156293],
					[64.680641708249738, 7.6581165002006504],
					[64.680572210756523, 7.6581136996075019],
					[64.680419413650313, 7.6581026003286325],
					[64.680419410959203, 7.6581026004617083],
					[64.680419403782963, 7.6581026008165773],
					[64.68041940019485, 7.6581026009940105],
					[64.680336110296253, 7.6581025992918876],
					[64.680191719195136, 7.6580887015195165],
					[64.680191713812974, 7.658088701785668],
					[64.680191704823642, 7.6580887001343703],
					[64.680191700338511, 7.6580887003561635],
					[64.680100011362612, 7.6580886990536667],
					[64.679969420973592, 7.6580748001474195],
					[64.679969419179528, 7.658074800236137],
					[64.679969412900306, 7.6580748005466477],
					[64.679969411106242, 7.6580748006353643],
					[64.679863937055856, 7.6580692020224124],
					[64.679855661639721, 7.6580665094586973],
					[64.679855643661099, 7.6580665061561524],
					[64.679855607722899, 7.6580665016459033],
					[64.679855589763292, 7.6580665004382196],
					[64.679741703289395, 7.6580720001349345],
					[64.679633315019828, 7.6580637016979418],
					[64.679633313206764, 7.6580636996918168],
					[64.679633307824574, 7.6580636999579674],
					[64.679633305133493, 7.6580637000910414],
					[64.679519408023864, 7.6580609009954665],
					[64.679419410994115, 7.6580554005259156],
					[64.679419408303033, 7.6580554006589887],
					[64.679419402920843, 7.6580554009251394],
					[64.679419400210776, 7.6580553989633868],
					[64.679397203107683, 7.6580554005244528],
					[64.679297209012717, 7.6580525992876076],
					[64.679161115170842, 7.6580442019620083],
					[64.679069418240204, 7.6580359020272342],
					[64.679069417324172, 7.6580358999767748],
					[64.679069416427154, 7.6580359000211322],
					[64.679069414633062, 7.6580359001098488],
					[64.678925014774705, 7.6580248014982395],
					[64.678925013877645, 7.6580248015425996],
					[64.67884721394816, 7.6580192012176296],
					[64.67884721392916, 7.6580191991228475],
					[64.678688922688664, 7.6580081002152838],
					[64.678619431603451, 7.6579970028244126],
					[64.678619428015324, 7.6579970030018458],
					[64.678619421717102, 7.6579970012175931],
					[64.678619419026006, 7.6579970013506689],
					[64.678444426913828, 7.6579804016179809],
					[64.678397234598819, 7.6579720032768046],
					[64.678397231907724, 7.6579720034098822],
					[64.678397225609515, 7.6579720016256481],
					[64.678397222918434, 7.6579720017587238],
					[64.678208322731948, 7.6579498009545111],
					[64.678208320937884, 7.6579498010432312],
					[64.678208317349785, 7.657949801220667],
					[64.678208313761658, 7.6579498013981038],
					[64.678169418725929, 7.6579470011580106],
					[64.677963929217412, 7.6579220018666483],
					[64.677947233100298, 7.6579192020346936],
					[64.677947232203266, 7.6579192020790545],
					[64.677947228615139, 7.657919202256493],
					[64.677947227718121, 7.6579192023008522],
					[64.677788931791198, 7.6578970018013219],
					[64.677727836160926, 7.6578859027009312],
					[64.677727827171609, 7.6578859010498412],
					[64.677727809211973, 7.6578858998423565],
					[64.677727800241669, 7.6578859002859598],
					[64.677719414619744, 7.6578859000398118],
					[64.677497230253195, 7.6578526028657601],
					[64.677497226665082, 7.657852603043203],
					[64.677497221263906, 7.6578526012146924],
					[64.677497217675779, 7.6578526013921371],
					[64.677466727113824, 7.6578498027524065],
					[64.677277834798858, 7.6578165026858311],
					[64.677277831210702, 7.6578165028632803],
					[64.677277824015476, 7.6578165011235138],
					[64.677277820427364, 7.6578165013009603],
					[64.677222226573292, 7.6578109017707421],
					[64.677047233981469, 7.6577804039947184],
					[64.677047232168363, 7.6577804019888029],
					[64.676977834348804, 7.6577692026995159],
					[64.676827836420202, 7.6577415032842184],
					[64.676827834626138, 7.6577415033729448],
					[64.676827833729106, 7.6577415034173084],
					[64.676827832832075, 7.6577415034616729],
					[64.676727834676413, 7.6577248038845935],
					[64.67659723771294, 7.6576998042731947],
					[64.676597236796937, 7.6576998022229299],
					[64.676597235899891, 7.6576998022672953],
					[64.67647503737868, 7.6576776020839095],
					[64.676375038048079, 7.6576581033694113],
					[64.676375037151033, 7.6576581034137767],
					[64.676375035356969, 7.6576581035025075],
					[64.67637503356292, 7.6576581035912374],
					[64.676230634027334, 7.6576331020248123],
					[64.676230633130331, 7.6576331020691777],
					[64.676147234164759, 7.6576192033076778],
					[64.675980637863418, 7.6575887033377592],
					[64.675925038966795, 7.6575776040758425],
					[64.675925038069735, 7.6575776041202106],
					[64.675925037153732, 7.6575776020700204],
					[64.675925036256686, 7.6575776021143875],
					[64.675727835998146, 7.6575415021545963],
					[64.675697239248407, 7.6575359034265835],
					[64.675475066837436, 7.6574887095069961],
					[64.675469489122747, 7.6574859218242617],
					[64.675469478301366, 7.6574859160731314],
					[64.675469454005494, 7.6574859088929976],
					[64.675469442287081, 7.6574859031862355],
					[64.675247244681174, 7.6574387050102857],
					[64.675225048800016, 7.6574331058518457],
					[64.675225047902984, 7.6574331058962146],
					[64.675225045211889, 7.6574331060293277],
					[64.675225043398825, 7.6574331040235455],
					[64.675025044054365, 7.6573887040505531],
					[64.674963943591948, 7.6573748058088427],
					[64.674805646108325, 7.6573387056431468],
					[64.674713946788856, 7.6573165063577413],
					[64.67471394587281, 7.6573165043076541],
					[64.674713944078746, 7.6573165043964],
					[64.674713943181729, 7.6573165044407716],
					[64.674575049652915, 7.6572859051627269],
					[64.674452856826548, 7.6572498073220148],
					[64.674452855929488, 7.6572498073663917],
					[64.674452855032484, 7.6572498074107651],
					[64.674355654751423, 7.6572220074838642],
					[64.674355653854363, 7.6572220075282411],
					[64.674355651163268, 7.6572220076613693],
					[64.674355650247236, 7.6572220056113096],
					[64.674194451664022, 7.6571804078031969],
					[64.674125053806037, 7.6571609072300033],
					[64.673936153833395, 7.6571081064035669],
					[64.673936152936392, 7.6571081064479456],
					[64.673905659099631, 7.6570998088515783],
					[64.673752865602339, 7.6570470119208274],
					[64.67367506959782, 7.6570192130321537],
					[64.673661174063781, 7.6570137130253562],
					[64.673661172269689, 7.6570137131141207],
					[64.673661169578622, 7.6570137132472613],
					[64.673661168662576, 7.6570137111972443],
					[64.673455669213368, 7.6569387123949042],
					[64.67345566740029, 7.6569387103892872],
					[64.673455662915131, 7.6569387106112048],
					[64.673455661102068, 7.6569387086056055],
					[64.673386165128349, 7.6569165110717821],
					[64.673225075989336, 7.6568581152272142],
					[64.673102882817261, 7.6568026187442282],
					[64.673102881004183, 7.6568026167386662],
					[64.673102878313102, 7.6568026168718264],
					[64.673102877397042, 7.6568026148218564],
					[64.673002884578878, 7.6567609197639452],
					[64.672813993888298, 7.6566609234424989],
					[64.672813992094234, 7.6566609235312812],
					[64.672813990281171, 7.6566609215257486],
					[64.672813989384139, 7.6566609215701398],
					[64.672775088956286, 7.656641521100366],
					[64.672775085349159, 7.6566415191836015],
					[64.672775075443781, 7.656641515483285],
					[64.672775070939608, 7.656641513610932],
					[64.672752883727085, 7.6566331179962912],
					[64.672652903810189, 7.6565776283391198],
					[64.672547336875368, 7.656508151207472],
					[64.672477957823546, 7.6564192772752335],
					[64.672477950571206, 7.6564192692532469],
					[64.672477936982617, 7.6564192552591814],
					[64.672477929730306, 7.6564192472371939],
					[64.672325159249354, 7.6562887732291252],
					[64.672272376976878, 7.656188806139026],
					[64.672272376079874, 7.6561888061834305],
					[64.67227237606086, 7.6561888040891377],
					[64.6722723751638, 7.6561888041335449],
					[64.672211276609858, 7.6560777066081949],
					[64.672097378685478, 7.6558471110014006],
					[64.671958479425726, 7.6555666117734553],
					[64.671866879746219, 7.6553805113683877],
					[64.671708480031825, 7.6550527122129983],
					[64.67170847819969, 7.6550527081134252],
					[64.671708473657418, 7.6550527020529309],
					[64.671708471825283, 7.6550526979533595],
					[64.671639073135964, 7.6549359991687034],
					[64.671416874236698, 7.6545443013038899],
					[64.671416870591443, 7.6545442951991083],
					[64.671416863319948, 7.6545442850837278],
					[64.671416858777661, 7.6545442790233844],
					[64.671408471066272, 7.6545331944414503],
					[64.671394579206819, 7.6545055104134399],
					[64.671394575561578, 7.6545055043086672],
					[64.671394570103175, 7.6545054961985919],
					[64.671394568252012, 7.6545054900048797],
					[64.671180769667856, 7.6541776949193832],
					[64.671097371725381, 7.6540360000083831],
					[64.671097368977144, 7.654035993859245],
					[64.67109736260268, 7.6540359836996457],
					[64.671097358976468, 7.6540359796891826],
					[64.670958461015942, 7.6538526818435866],
					[64.670730800395148, 7.6535388340086739],
					[64.670730799745144, 7.653536099934982],
					[64.670730781270919, 7.6535360421884429],
					[64.670730713097939, 7.6535359470999067],
					[64.670730663399155, 7.6535359097579292],
					[64.670722323367499, 7.6535331304678573],
					[64.670502960935096, 7.6532359805918366],
					[64.670502960038093, 7.6532359806363344],
					[64.67050295912199, 7.6532359785867019],
					[64.670502958224972, 7.6532359786311996],
					[64.670341857871335, 7.6530276774117105],
					[64.670341856955218, 7.6530276753620958],
					[64.670341853328992, 7.6530276713518779],
					[64.670341850618811, 7.6530276693912755],
					[64.67027235535366, 7.6529470738484422],
					[64.670197358385181, 7.6528498781031216],
					[64.670197358366124, 7.6528498760089905],
					[64.670197355655944, 7.6528498740484174],
					[64.67019735475894, 7.6528498740929276],
					[64.670052954649648, 7.6526720738233953],
					[64.670052953733574, 7.6526720717738144],
					[64.670052951023393, 7.6526720698132484],
					[64.670052950107262, 7.6526720677636675],
					[64.669922350120217, 7.6525248675319251],
					[64.669922349204114, 7.6525248654823592],
					[64.66992234559693, 7.6525248635663585],
					[64.669922344680813, 7.6525248615167927],
					[64.669822344589733, 7.6524192616066928],
					[64.669822343692687, 7.6524192616512208],
					[64.669822341879552, 7.6524192596461891],
					[64.66982234098252, 7.6524192596907161],
					[64.669730742686099, 7.6523276593456142],
					[64.669594543987785, 7.6521859612058742],
					[64.66959454307171, 7.6521859591563421],
					[64.669594538567466, 7.6521859572849422],
					[64.669594537651349, 7.6521859552354137],
					[64.669425138182575, 7.6520248542376779],
					[64.669364038164588, 7.6519665547471094],
					[64.669364030931121, 7.6519665488212887],
					[64.669364014670094, 7.6519665370587067],
					[64.669364007436627, 7.6519665311328673],
					[64.669333418408456, 7.6519470371519729],
					[64.66914452719918, 7.6517915453719452],
					[64.669144526302148, 7.6517915454164891],
					[64.669144525386045, 7.6517915433670058],
					[64.669144523591982, 7.6517915434560955],
					[64.66897512391715, 7.6516581430004402],
					[64.668975123020104, 7.6516581430449904],
					[64.668975123020104, 7.6516581430449904],
					[64.668975122104001, 7.651658140995524],
					[64.668914027106567, 7.6516109463244906],
					[64.668822332021691, 7.6515304493065672],
					[64.668822331124645, 7.65153044935112],
					[64.668822330208542, 7.6515304473016501],
					[64.668822329311496, 7.6515304473462038],
					[64.6686945290528, 7.6514220462607403],
					[64.668694509203618, 7.6514220346769761],
					[64.668694466833259, 7.651422013737144],
					[64.668694444312067, 7.6514220043810797],
					[64.668633382684106, 7.6514081144122112],
					[64.668464024347386, 7.6512887422913023],
					[64.668275131652223, 7.6511220501740986],
					[64.668275126212734, 7.6511220441595897],
					[64.668275113577991, 7.6511220364076387],
					[64.668275108157587, 7.6511220324871081],
					[64.668236229566276, 7.6510970465081236],
					[64.668183447648133, 7.6510387657027206],
					[64.668183447648133, 7.6510387657027206],
					[64.668183445834956, 7.6510387636979038],
					[64.668183445834956, 7.6510387636979038],
					[64.668014045739994, 7.6508581620987979],
					[64.668014041216651, 7.6508581581337847],
					[64.668014031253847, 7.65085814815437],
					[64.668014024936468, 7.6508581442784847],
					[64.667886224957599, 7.6507554433990173],
					[64.667786246134767, 7.6506748611588549],
					[64.667686260945544, 7.6505387826056683],
					[64.667686260926473, 7.6505387805117548],
					[64.667686259113324, 7.6505387785070047],
					[64.667686258216264, 7.6505387785515886],
					[64.66755575759575, 7.6503692776556083],
					[64.66755575759575, 7.6503692776556083],
					[64.66755575667959, 7.6503692756062938],
					[64.667555755782587, 7.6503692756508803],
					[64.667397355789191, 7.65017207430372],
					[64.667397352162865, 7.6501720702942952],
					[64.667397344910185, 7.6501720622754874],
					[64.667397341283859, 7.6501720582660626],
					[64.66733625722992, 7.6501109740034945],
					[64.667289068794133, 7.6500359933021977],
					[64.667289066961885, 7.6500359892036283],
					[64.6672890624194, 7.6500359831449529],
					[64.667289059690106, 7.6500359790909833],
					[64.667105760771008, 7.6497915811120851],
					[64.666916873343638, 7.6495304984992778],
					[64.666877980979081, 7.6494471156261135],
					[64.666877969126546, 7.6494470952676119],
					[64.666877939161395, 7.6494470569567428],
					[64.666877921964939, 7.6494470410536488],
					[64.666816839718308, 7.649399854288224],
					[64.666650153826978, 7.6491998710430344],
					[64.666650152929961, 7.6491998710876592],
					[64.66665015109767, 7.6491998669892425],
					[64.66665015020061, 7.649199867033869],
					[64.66649736339491, 7.6490276815366762],
					[64.666427972817345, 7.6489082002497577],
					[64.666427959170676, 7.6489081799810767],
					[64.666427928365735, 7.6489081479975347],
					[64.666427911169208, 7.6489081320950429],
					[64.666344530609564, 7.6488526471785336],
					[64.666200148300291, 7.6486942643720743],
					[64.666200145590068, 7.6486942624121816],
					[64.666200141963714, 7.6486942584031485],
					[64.666200140150522, 7.648694256398632],
					[64.666030753708597, 7.6485276703640999],
					[64.665969564812727, 7.6484387862260759],
					[64.665969553017462, 7.6484387721500333],
					[64.665969526754935, 7.648438746225672],
					[64.665969513184734, 7.648438734332708],
					[64.665908430578824, 7.6483970479707022],
					[64.665741847349111, 7.6482192637910567],
					[64.665566863413261, 7.6480276811705403],
					[64.665519573756711, 7.6479443016194883],
					[64.665519561923119, 7.6479442833563827],
					[64.665519532912029, 7.647944251285665],
					[64.665519518425612, 7.6479442373440634],
					[64.665458437027596, 7.64789985264433],
					[64.6652918526337, 7.6477026714081298],
					[64.665291851717555, 7.6477026693590728],
					[64.665139060240534, 7.6475248788777108],
					[64.665061266051111, 7.6474081886914576],
					[64.665061262424729, 7.6474081846827637],
					[64.665061254236605, 7.6474081725226197],
					[64.665061249713162, 7.6474081685585888],
					[64.664955754623378, 7.6472887743516029],
					[64.664841859989991, 7.6471359793066478],
					[64.664841859989991, 7.6471359793066478],
					[64.6648418581768, 7.6471359773023355],
					[64.664841857260598, 7.6471359752533141],
					[64.664750157785747, 7.6470192785461801],
					[64.664611259420795, 7.646835979158606],
					[64.664611257607547, 7.6468359771543319],
					[64.664611257607547, 7.6468359771543319],
					[64.664405762281618, 7.6465720819841012],
					[64.664383465968285, 7.6465387891789502],
					[64.664383465052097, 7.6465387871299839],
					[64.664383462303519, 7.6465387809831427],
					[64.66438345959331, 7.6465387790236106],
					[64.664366860301001, 7.6465165802610136],
					[64.664366859384799, 7.6465165782120703],
					[64.664152958646198, 7.6462359792886714],
					[64.664152955000574, 7.6462359731866121],
					[64.664152945934489, 7.6462359631656023],
					[64.664152941411032, 7.6462359592018965],
					[64.663933500000596, 7.6460166162429353],
					[64.66393350026334, 7.6460138995595619],
					[64.663933470906855, 7.6460138298075302],
					[64.663933371256419, 7.6460137279524307],
					[64.663933300103736, 7.6460137000813297],
					[64.663925075080456, 7.6460136996352928],
					[64.663702931958596, 7.6458192506365545],
					[64.663702927435125, 7.645819246672982],
					[64.663702919285214, 7.645819238701149],
					[64.663702913883895, 7.6458192368759113],
					[64.66357511435028, 7.6457304352814166],
					[64.663475113724999, 7.6456609359546466],
					[64.66347511193095, 7.645660936044119],
					[64.663475109201514, 7.6456609319911362],
					[64.663475107388294, 7.6456609299870228],
					[64.663261209743112, 7.6455248335771158],
					[64.663252911912039, 7.6455192340463727],
					[64.663252905594533, 7.6455192301723951],
					[64.663252892959449, 7.6455192224244808],
					[64.66325288666107, 7.645519220644073],
					[64.663247293358992, 7.6455165232905529],
					[64.663025098063898, 7.6453915250260014],
					[64.663025096269848, 7.6453915251154889],
					[64.663025091746363, 7.6453915211520869],
					[64.663025089055282, 7.6453915212863173],
					[64.6629472938003, 7.6453526235377458],
					[64.662802897355292, 7.6452720250023471],
					[64.662802891934774, 7.6452720210837448],
					[64.66280288113208, 7.645272017433645],
					[64.662802874833702, 7.645272015653342],
					[64.662658374732118, 7.6452137147087713],
					[64.662658374732118, 7.6452137147087713],
					[64.662658373835072, 7.6452137147535195],
					[64.662575074647407, 7.6451804146624669],
					[64.662377876396235, 7.6450998154350236],
					[64.662377856584911, 7.6450998080455683],
					[64.662377818832994, 7.6450998015511313],
					[64.662377799976241, 7.6450998003974231],
					[64.662352842204797, 7.6450997993161316],
					[64.662238979585524, 7.6450498176978501],
					[64.662238977772276, 7.6450498156938478],
					[64.662238972370943, 7.6450498138688765],
					[64.66223896876366, 7.6450498119544008],
					[64.662125068906718, 7.6450081119326487],
					[64.662102870135001, 7.6449998133453683],
					[64.661902870083466, 7.6449248125575426],
					[64.661902867392357, 7.644924812691813],
					[64.66190286017779, 7.6449248088629211],
					[64.661902856589663, 7.6449248090419495],
					[64.661827858007058, 7.6449026095876889],
					[64.661675060272159, 7.6448554081370945],
					[64.661561161383631, 7.6448192096074701],
					[64.66156113800335, 7.6448192044909105],
					[64.66156109219736, 7.6448192004933198],
					[64.661561069771679, 7.6448192016123091],
					[64.661452821992597, 7.6448358937539611],
					[64.661316772265252, 7.6447831138413544],
					[64.66131673989554, 7.644783107079034],
					[64.661316675251911, 7.6447831040215046],
					[64.661316642100218, 7.6447831098644938],
					[64.661225024885425, 7.6448108849497665],
					[64.661055699854145, 7.6447137277950992],
					[64.661055653952246, 7.6447137133307619],
					[64.661055560584586, 7.6447137096124056],
					[64.661055513996629, 7.644713718220232],
					[64.661002836721167, 7.644738660087687],
					[64.660850135924264, 7.6445970535923227],
					[64.660850118765651, 7.6445970418825535],
					[64.660850082654321, 7.6445970185525507],
					[64.660850062823783, 7.6445970090704627],
					[64.660775062738509, 7.6445720091987486],
					[64.660758363057681, 7.6445665093240587],
					[64.660758357675505, 7.644566509592658],
					[64.660758346872797, 7.6445665059431036],
					[64.660758342368467, 7.6445665040735609],
					[64.66055285640229, 7.6445220069899378],
					[64.660500069007412, 7.6445026113000205],
					[64.660500042936022, 7.6445026063182082],
					[64.660499991747855, 7.6445026025899141],
					[64.660499965734033, 7.6445026038881858],
					[64.66032503456664, 7.6445330918145871],
					[64.660269495576713, 7.6445026248977044],
					[64.660269464962667, 7.6445026138597543],
					[64.660269400242328, 7.6445026024296121],
					[64.660269367033067, 7.6445026019926745],
					[64.66010284255259, 7.644530389431484],
					[64.660033407730353, 7.6444859311576874],
					[64.660033371695746, 7.6444859162017984],
					[64.660033297088916, 7.6444859031709322],
					[64.660033259394524, 7.6444859029578316],
					[64.659875073964429, 7.6445191799151013],
					[64.659766851308149, 7.6443942696239153],
					[64.659766807962995, 7.6443942403733418],
					[64.659766713429676, 7.6443942073947646],
					[64.659766662241509, 7.6443942036667432],
					[64.659652914939826, 7.6444164734352951],
					[64.659522380829571, 7.6441388143099163],
					[64.659522357199819, 7.6441387819816153],
					[64.659522299291083, 7.6441387304220436],
					[64.659522265012157, 7.6441387111907728],
					[64.659425065060034, 7.6441054104689723],
					[64.659425064163017, 7.6441054105137516],
					[64.659425064163017, 7.6441054105137516],
					[64.659400064381074, 7.644097010356024],
					[64.659400047299172, 7.6440970070202869],
					[64.659400014032329, 7.6440970003040158],
					[64.65939999696964, 7.6440969990615484],
					[64.659202841822591, 7.6440997979995693],
					[64.659172283327663, 7.6440859184053416],
					[64.659172258114893, 7.6440859091928743],
					[64.659172205991311, 7.6440859013237752],
					[64.65917217995829, 7.6440859005291166],
					[64.658975060270308, 7.6441053926871891],
					[64.65893622442978, 7.6440748419448781],
					[64.658936190169996, 7.6440748248073307],
					[64.658936117280405, 7.6440748033155739],
					[64.658936078669811, 7.6440748010545789],
					[64.658752869247024, 7.6440941913845935],
					[64.658683435199634, 7.6440304531777352],
					[64.658683409013094, 7.6440304356374007],
					[64.658683351334602, 7.6440304091982476],
					[64.658683320739655, 7.6440304002546728],
					[64.658522333210897, 7.6440137130391186],
					[64.658364081084528, 7.6436749157251009],
					[64.658364065604715, 7.6436748913682111],
					[64.658364029320481, 7.6436748492028057],
					[64.658364007619042, 7.6436748314390419],
					[64.658294512932187, 7.6436304355748623],
					[64.658264018096105, 7.6436081395095954],
					[64.658263994639, 7.6436081260219186],
					[64.658263947801572, 7.6436081074192934],
					[64.658263922607972, 7.6436081003007512],
					[64.658072312396513, 7.6435859128737844],
					[64.657950163999701, 7.6434109840547375],
					[64.657950141362846, 7.6434109621499351],
					[64.657950088970395, 7.6434109249781939],
					[64.657950060111915, 7.6434109096664757],
					[64.657844548117708, 7.6433776369303787],
					[64.657761283903142, 7.6431805224689597],
					[64.657761270217293, 7.6431804980232982],
					[64.657761236624012, 7.6431804557250427],
					[64.657761216716565, 7.6431804378724699],
					[64.657622338798632, 7.6430804544113613],
					[64.657508456034918, 7.6429387760222802],
					[64.657508421678955, 7.6429387484214129],
					[64.65750834684151, 7.6429387102784121],
					[64.657508304565937, 7.6429386998258719],
					[64.657394538317007, 7.6429359022958474],
					[64.657294585806383, 7.6426805274004943],
					[64.657294577579464, 7.6426805110589981],
					[64.657294558434515, 7.6426804785104911],
					[64.657294546638681, 7.6426804644414075],
					[64.65716685320217, 7.6425415715179179],
					[64.657014059187489, 7.6423415798449037],
					[64.657014041054779, 7.6423415598106645],
					[64.657014001278128, 7.6423415282938914],
					[64.657013978737169, 7.642341516856149],
					[64.656936243989662, 7.6423081447190162],
					[64.656866877013357, 7.6421777063504646],
					[64.656866864282037, 7.642177688140511],
					[64.656866835250455, 7.642177653993004],
					[64.65686681895022, 7.642177638055454],
					[64.65671683302098, 7.6420665502876561],
					[64.656616845396925, 7.6419609612748953],
					[64.656616821004178, 7.6419609436481819],
					[64.656616766894217, 7.6419609149429606],
					[64.656616738074021, 7.6419609038196272],
					[64.656486229138622, 7.6419359217149188],
					[64.656341873561516, 7.6416805029778407],
					[64.656341868083246, 7.6416804927817763],
					[64.65634185443561, 7.6416804725242047],
					[64.656341846266216, 7.6416804624627002],
					[64.656258462770168, 7.6415915810871873],
					[64.656036274715589, 7.6411888028641579],
					[64.65603618540986, 7.6411887528878095],
					[64.656036007740653, 7.6411887554927],
					[64.656035921190494, 7.6411888100772254],
					[64.656027631328826, 7.641205490709492],
					[64.655891615682776, 7.6413719878956048],
					[64.655808330135017, 7.6413609028621705],
					[64.655647269706321, 7.6413331094560375],
					[64.655586202178725, 7.6412970278818362],
					[64.6555861859553, 7.641297020317471],
					[64.655586154424739, 7.6412970072368704],
					[64.655586137342766, 7.6412970039033352],
					[64.655380663793551, 7.6412581087535605],
					[64.655358388878824, 7.6412470215492956],
					[64.65535837086135, 7.6412470140747724],
					[64.655358333070808, 7.6412470034013484],
					[64.655358313297725, 7.6412470002024477],
					[64.655144437809, 7.6412331021323032],
					[64.655136162017527, 7.6412304107878786],
					[64.655136160204208, 7.6412304087846712],
					[64.655136156616109, 7.6412304089641321],
					[64.655136154822046, 7.6412304090538621],
					[64.655097271768966, 7.6412192124750309],
					[64.654908435569638, 7.6411276438824869],
					[64.654791868242029, 7.6409554905821704],
					[64.654677978676261, 7.6407721093620875],
					[64.65465578568066, 7.6407166266504234],
					[64.654655783848128, 7.640716622554363],
					[64.654655780183091, 7.6407166143622804],
					[64.654655779247591, 7.6407166102213395],
					[64.654458480410455, 7.6403249121316099],
					[64.654402985266486, 7.6402055239177473],
					[64.654219599877621, 7.6396999647745147],
					[64.654219600142767, 7.6396917003964075],
					[64.654219599091746, 7.6396916836983646],
					[64.654219594317851, 7.6396916525298701],
					[64.654219589678689, 7.6396916360114844],
					[64.65420579027915, 7.639649937287488],
					[64.654205789362862, 7.6396499352395377],
					[64.654205788427333, 7.6396499310987007],
					[64.654205786614042, 7.6396499290956656],
					[64.654030787435474, 7.6391805299795577],
					[64.654030785622169, 7.639180527976567],
					[64.654030783770338, 7.6391805217878517],
					[64.654030782854093, 7.639180519739929],
					[64.653991883706823, 7.6390916215858118],
					[64.653900185332233, 7.6388693233133882],
					[64.653900179853764, 7.6388693131188017],
					[64.653900168896797, 7.638869292729634],
					[64.653900161624293, 7.6388692826249285],
					[64.653786262413234, 7.6387109835029463],
					[64.653786258786582, 7.638710979497044],
					[64.653786253327382, 7.638710971395386],
					[64.653786249700744, 7.638710967389482],
					[64.653764050226258, 7.6386859679150785],
					[64.653764048412938, 7.6386859659121207],
					[64.65376404299225, 7.6386859619961358],
					[64.653764041178945, 7.6386859599931975],
					[64.653747341326053, 7.6386692590964271],
					[64.653747337699414, 7.6386692550905551],
					[64.653747332259442, 7.6386692490817287],
					[64.65374732775507, 7.6386692472136311],
					[64.653541844038429, 7.6384970595489055],
					[64.653350157341222, 7.6382554759741979],
					[64.653350154630886, 7.6382554740162769],
					[64.65335015281758, 7.6382554720133973],
					[64.653350151004233, 7.6382554700105176],
					[64.653314050648163, 7.6382137688463825],
					[64.653314047918528, 7.6382137647956743],
					[64.653314040684506, 7.6382137588769741],
					[64.653314037057868, 7.6382137548712068],
					[64.65326684011012, 7.6381692579065614],
					[64.653083443168157, 7.6379804614093976],
					[64.653083426886766, 7.6379804475693414],
					[64.653083391652146, 7.6379804221169492],
					[64.653083372737456, 7.6379804146901522],
					[64.652983378754328, 7.6379415153907226],
					[64.652863984450462, 7.6378859199678617],
					[64.652863973628328, 7.6378859142292086],
					[64.652863952938873, 7.6378859089852584],
					[64.652863943033012, 7.637885905294417],
					[64.652711143089491, 7.6378526040943893],
					[64.652711138604346, 7.6378526043192476],
					[64.652711132305868, 7.6378526025413018],
					[64.652711127820766, 7.6378526027661593],
					[64.652633390707194, 7.6378415111499436],
					[64.652466852049415, 7.6376859697298114],
					[64.652411265264334, 7.6376054863453726],
					[64.65241123718657, 7.6376054584415085],
					[64.652411173092773, 7.6376054176877144],
					[64.652411137057456, 7.6376054027450602],
					[64.652366752134043, 7.6375970070144152],
					[64.652183367227394, 7.6375331120617496],
					[64.65210006929604, 7.6375026115091167],
					[64.652100057615414, 7.6375026100011807],
					[64.652100035112639, 7.6375026027549042],
					[64.65210002432903, 7.6375026012019891],
					[64.651961161070219, 7.6374859061838167],
					[64.651825093608849, 7.6374137233653032],
					[64.651825089104435, 7.6374137214975253],
					[64.651825078282243, 7.6374137157592976],
					[64.651825072880825, 7.6374137139365237],
					[64.651733406851122, 7.6373776271275862],
					[64.65155013542433, 7.637210951004108],
					[64.651550115574011, 7.6372109394379049],
					[64.651550075873402, 7.6372109163055395],
					[64.651550055164634, 7.6372109089696858],
					[64.65151128694113, 7.6371998456895396],
					[64.651505799046859, 7.6371388823546464],
					[64.651505786102732, 7.6371388411313514],
					[64.651505744145183, 7.6371387678652951],
					[64.651505715131776, 7.6371387358225133],
					[64.651458445304144, 7.637105456552165],
					[64.651283464961807, 7.6368498878617856],
					[64.651283437780961, 7.6368498599148449],
					[64.651283376378046, 7.636849819029492],
					[64.651283342136693, 7.6368498039984383],
					[64.651100093121897, 7.6368109156298445],
					[64.651055732373024, 7.6367720502206229],
					[64.651055707998964, 7.6367720346947277],
					[64.651055655720597, 7.6367720101008532],
					[64.651055627816319, 7.6367720010328553],
					[64.650841772631821, 7.6367415075994511],
					[64.650833410930943, 7.6367359332074747],
					[64.650833410033911, 7.6367359332524796],
					[64.650833407323518, 7.6367359312948908],
					[64.650833406407216, 7.6367359292472914],
					[64.650811212007739, 7.6367220339348165],
					[64.650658474708933, 7.6366109794389558],
					[64.650605793411316, 7.6364166482542224],
					[64.65060575429888, 7.6364165915973574],
					[64.650605650426826, 7.6364165193482529],
					[64.650605583853903, 7.6364165017534562],
					[64.650466753182016, 7.636427594940514],
					[64.650375111429497, 7.636366533955333],
					[64.650375077188102, 7.636366518925378],
					[64.650375007943381, 7.6363665035584631],
					[64.650374971126695, 7.6363665012189843],
					[64.65022220863672, 7.636388696435005],
					[64.650152906955384, 7.6363720198094116],
					[64.650033449071799, 7.6362387665497229],
					[64.650033448155455, 7.636238764502183],
					[64.650033444548058, 7.636238762589719],
					[64.650033443631713, 7.6362387605421969],
					[64.649964068929961, 7.6361665870690132],
					[64.649925183371138, 7.6360777203697952],
					[64.64992513803729, 7.6360776703076008],
					[64.649925027123984, 7.636077613069542],
					[64.649924960628212, 7.6360776038461493],
					[64.649855577450623, 7.6360915015672601],
					[64.649702792894828, 7.6360969992234242],
					[64.649702774973605, 7.6360970022164123],
					[64.649702740944477, 7.6360970102048782],
					[64.649702723939583, 7.6360970152453573],
					[64.649641629607714, 7.6361220130220131],
					[64.649483333658267, 7.6361747771301172],
					[64.64941401680224, 7.6361248370651289],
					[64.649413991569702, 7.6361248257707643],
					[64.649413941123939, 7.6361248052745268],
					[64.649413914155204, 7.6361248003477096],
					[64.649252887012253, 7.6361137062675457],
					[64.649147346016008, 7.6360109627517909],
					[64.649116859993015, 7.6359748779732355],
					[64.649025171158883, 7.6358387950928019],
					[64.649008475618217, 7.6358082044671978],
					[64.648986275915135, 7.6357666065935597],
					[64.648986275895808, 7.6357666045010788],
					[64.648986274979507, 7.6357666024536508],
					[64.648986274063162, 7.6357666004062228],
					[64.648802974371037, 7.6354443011188291],
					[64.648802970725015, 7.6354442950216628],
					[64.648802964368585, 7.6354442869671608],
					[64.648802960722563, 7.635444280869975],
					[64.648666860912229, 7.6352609810741541],
					[64.648666840068174, 7.6352609590934524],
					[64.648666793094478, 7.6352609258645403],
					[64.648666766945524, 7.6352609125238944],
					[64.64858908210455, 7.6352331532515008],
					[64.648575197734175, 7.6351305725507022],
					[64.648575195824236, 7.6351305600862185],
					[64.648575189313263, 7.6351305352924106],
					[64.648575184712257, 7.6351305229630864],
					[64.648491884799881, 7.6349305231507287],
					[64.648491879321142, 7.6349305129589577],
					[64.648491868363678, 7.634930492575414],
					[64.648491861090847, 7.6349304824737638],
					[64.648361260656074, 7.6347526824903698],
					[64.648361234391146, 7.6347526565958512],
					[64.648361173903965, 7.6347526177669307],
					[64.648361138784722, 7.6347526048775736],
					[64.648347239351835, 7.634749803024266],
					[64.648347231278606, 7.6347498034298535],
					[64.648347214196448, 7.6347498001012539],
					[64.648347205226202, 7.6347498005519068],
					[64.648125005092083, 7.6347441997754597],
					[64.648124988048565, 7.6347442006316966],
					[64.648124953103164, 7.6347442065740205],
					[64.648124936079, 7.6347442095226477],
					[64.648116666792021, 7.6347470009285932],
					[64.648108306210972, 7.6347470001817879],
					[64.647894425909882, 7.6347331000223742],
					[64.647880637834078, 7.6347304035775236],
					[64.647880629741536, 7.6347304018907343],
					[64.647880611781673, 7.6347304006996399],
					[64.647880602792114, 7.6347303990579141],
					[64.647675003383767, 7.6347276006255473],
					[64.647674979183407, 7.634727603934671],
					[64.647674932595976, 7.6347276125551113],
					[64.647674911144605, 7.6347276220061184],
					[64.647652711276905, 7.6347387203753891],
					[64.647652710399186, 7.6347387225128109],
					[64.64765270770809, 7.634738722648005],
					[64.647652706811087, 7.6347387226930694],
					[64.64755270678755, 7.6347915223353322],
					[64.647552672971031, 7.634791553340631],
					[64.647552620664683, 7.6347916229546078],
					[64.647552603091199, 7.6347916636105708],
					[64.647522041187088, 7.634960852980929],
					[64.647444450082503, 7.6349415061142949],
					[64.647391751381036, 7.6349276076046229],
					[64.647391701108887, 7.6349276059431679],
					[64.647391607094974, 7.6349276295052597],
					[64.647391564250185, 7.6349276546837679],
					[64.647308164316925, 7.635005454890619],
					[64.647308161625872, 7.6350054550257811],
					[64.647308159851107, 7.6350054572082433],
					[64.647308158973416, 7.6350054593456287],
					[64.647224924035058, 7.6350886933588553],
					[64.647116714630869, 7.6350804001986408],
					[64.64711671104277, 7.6350804003788486],
					[64.647116703866544, 7.6350804007392643],
					[64.647116700278445, 7.6350804009194713],
					[64.647111100224748, 7.6350803995801364],
					[64.647111070642197, 7.635080403159165],
					[64.647111016058787, 7.6350804205535789],
					[64.647110989263865, 7.6350804344590486],
					[64.647002704328401, 7.6351526234356326],
					[64.646933220413047, 7.6351831162099844],
					[64.646836048725334, 7.6352248041199609],
					[64.64678058010405, 7.6352304008700518],
					[64.646780546094291, 7.6352304109509914],
					[64.646780481759436, 7.6352304413941559],
					[64.646780454144732, 7.6352304637135138],
					[64.646744259398488, 7.6352692587690356],
					[64.646644264594215, 7.6353609533116451],
					[64.646644245949759, 7.6353609751803599],
					[64.646644215914293, 7.635361026926577],
					[64.646644205401032, 7.6353610546667552],
					[64.646613741023017, 7.6354941995886954],
					[64.646561105133486, 7.6354942006070976],
					[64.64650830970389, 7.6354915009782998],
					[64.646508272945113, 7.6354915049170904],
					[64.646508203170072, 7.6354915293527208],
					[64.646508171031599, 7.6354915477122312],
					[64.646416570853489, 7.6355692466459963],
					[64.646416569975798, 7.6355692487832929],
					[64.646416569078738, 7.635569248828328],
					[64.64633046861762, 7.6356442492763108],
					[64.646330464171101, 7.6356442536859834],
					[64.646330455278061, 7.6356442625053429],
					[64.646330450831542, 7.6356442669150137],
					[64.646255451121277, 7.6357276665478997],
					[64.646124962382743, 7.6358719438977483],
					[64.646111203274927, 7.6358637278806363],
					[64.646111139431596, 7.6358637143393855],
					[64.646111016635416, 7.6358637309688913],
					[64.646110958560271, 7.6358637590023655],
					[64.646094258673813, 7.635880459560334],
					[64.646094251555525, 7.6358804661972339],
					[64.646094239132239, 7.6358804814732508],
					[64.646094233808, 7.6358804880201232],
					[64.646072044699821, 7.6359137722580153],
					[64.645941558706639, 7.6360442596856268],
					[64.645888759226764, 7.636097058203009],
					[64.645888748558917, 7.6360970692043324],
					[64.645888730830677, 7.6360970931191359],
					[64.645888722873224, 7.6360971060776333],
					[64.64580542324579, 7.6362555061461626],
					[64.645805423265088, 7.6362555082383778],
					[64.645805422387369, 7.6362555103756034],
					[64.645805420593319, 7.6362555104656247],
					[64.645705426923243, 7.6364554992436453],
					[64.645683137176874, 7.6364887833897601],
					[64.645666539950753, 7.6365109790147372],
					[64.645666534645756, 7.6365109876535708],
					[64.645666526688274, 7.63651100061184],
					[64.645666523158056, 7.6365110070684841],
					[64.645560979180274, 7.6367137003449006],
					[64.64555280258223, 7.6367137002214447],
					[64.645447205243229, 7.6367109006088487],
					[64.645447177435386, 7.6367109020036859],
					[64.645447121935462, 7.636710917346508],
					[64.645447096018117, 7.6367109291123212],
					[64.645347095593024, 7.6367720290217109],
					[64.645347094695992, 7.6367720290667043],
					[64.645347092921213, 7.6367720312488716],
					[64.64534709202421, 7.6367720312938623],
					[64.645308235083704, 7.6367970048955227],
					[64.645224989658175, 7.6368025993150637],
					[64.6451332941853, 7.6368054003635608],
					[64.64513329239125, 7.6368054004535422],
					[64.645133288803137, 7.6368054006335058],
					[64.64513328790612, 7.6368054006784964],
					[64.645088939636977, 7.636808098036334],
					[64.644997325361388, 7.6367637389475833],
					[64.644919554052493, 7.6366720720393166],
					[64.644783456337194, 7.6365026756111494],
					[64.644783446354126, 7.6365026635533058],
					[64.64478342373549, 7.6365026437569146],
					[64.644783411977642, 7.6365026338812099],
					[64.644775111946402, 7.6364970346656964],
					[64.64477510020788, 7.6364970268821519],
					[64.64477507587236, 7.6364970155443208],
					[64.644775064153109, 7.6364970098528966],
					[64.644766899857359, 7.6364942567376861],
					[64.644766899621573, 7.6364917010646227],
					[64.644766889329787, 7.6364916555325459],
					[64.644766849088811, 7.6364915738269534],
					[64.644766819158889, 7.6364915397455926],
					[64.644608419382976, 7.6363748379830758],
					[64.644608387851946, 7.6363748249133288],
					[64.644608323931436, 7.6363748030030907],
					[64.644608290702848, 7.6363748004839733],
					[64.644547190817278, 7.6363776000505821],
					[64.644486090931167, 7.6363803996030528],
					[64.644486089137132, 7.6363803996930608],
					[64.644486086446022, 7.6363803998280719],
					[64.644486084671271, 7.6363804020101931],
					[64.64437778515601, 7.636388700772029],
					[64.644377781567911, 7.6363887009520415],
					[64.644377776185749, 7.6363887012220593],
					[64.644377772597608, 7.6363887014020744],
					[64.644316673185571, 7.6363970014809084],
					[64.64431665885246, 7.6363970042930536],
					[64.644316632896562, 7.6363970118744593],
					[64.644316620376742, 7.6363970166887025],
					[64.644272120155762, 7.6364165170335614],
					[64.644119320181446, 7.6364831168874607],
					[64.644119318387396, 7.636483116977459],
					[64.64411931302449, 7.6364831193395446],
					[64.644119311249739, 7.6364831215216293],
					[64.644097122875863, 7.6364942142971213],
					[64.644080535899221, 7.6364998101708546],
					[64.644080518035864, 7.6364998194391864],
					[64.644080484103185, 7.6364998378858333],
					[64.644080468053147, 7.6364998491562508],
					[64.644035970814301, 7.6365387474121293],
					[64.64391377285213, 7.6366387459485905],
					[64.64391377195507, 7.6366387459935856],
					[64.643913771058081, 7.6366387460385772],
					[64.643913770180319, 7.6366387481756428],
					[64.643874869793819, 7.6366720489175455],
					[64.643874860900723, 7.636672057735745],
					[64.643874844011521, 7.6366720753271924],
					[64.643874836015428, 7.6366720841004199],
					[64.643769242149418, 7.6368220779341502],
					[64.643752647770668, 7.6368415695331988],
					[64.643752646015216, 7.6368415738072901],
					[64.643752641568611, 7.6368415782163437],
					[64.64375263979386, 7.636841580398376],
					[64.643652644409499, 7.6369748744849675],
					[64.643622048611732, 7.6370109686795269],
					[64.643555449776301, 7.6370859667779154],
					[64.643555437391484, 7.6370859862360474],
					[64.643555415332202, 7.6370860271094019],
					[64.643555406554796, 7.637086048479663],
					[64.643508107068698, 7.6372638458446822],
					[64.643433108427971, 7.6375221445815722],
					[64.643433105794699, 7.6375221509925888],
					[64.643433103257891, 7.6375221678637759],
					[64.643433100643918, 7.6375221763668266],
					[64.643424804194481, 7.6375916550853358],
					[64.643416509597273, 7.6376166364147835],
					[64.643416507880318, 7.6376166448728409],
					[64.643416504427165, 7.6376166596969561],
					[64.643416502710238, 7.6376166681550117],
					[64.643402603175659, 7.6377027676919802],
					[64.643402600600268, 7.6377027803790689],
					[64.643402599934532, 7.6377028055284573],
					[64.64340260094724, 7.6377028180357147],
					[64.643438699953805, 7.6381028092437324],
					[64.643438699698365, 7.6381361005917698],
					[64.643438700366161, 7.6381389009987934],
					[64.643438702352924, 7.6381389218292828],
					[64.643438711708612, 7.6381389632206425],
					[64.643438718180519, 7.6381389838264342],
					[64.643555417567143, 7.6383917831159156],
					[64.643555463778014, 7.6383918310333669],
					[64.643555573774194, 7.6383918862206688],
					[64.643555637578828, 7.6383918955825445],
					[64.643669364418869, 7.6383696113624167],
					[64.643805498060061, 7.6384501716884472],
					[64.643805507985164, 7.6384501774703857],
					[64.643805526919081, 7.6384501869871144],
					[64.643805536824914, 7.6384501906769682],
					[64.644030396440186, 7.6385251438182271],
					[64.644027603050404, 7.6386331658121707],
					[64.643913928850111, 7.6386803705363775],
					[64.643891819793154, 7.6386637393322632],
					[64.643891784635684, 7.6386637222557408],
					[64.643891709970447, 7.6386637029718694],
					[64.643891671378938, 7.6386637028116668],
					[64.643683340816679, 7.6386941921814673],
					[64.643669503336753, 7.6386859270652341],
					[64.643669476329563, 7.6386859179527242],
					[64.64366942145675, 7.638685903956735],
					[64.643669391797019, 7.6386858991631286],
					[64.643455591627401, 7.6386941998811322],
					[64.643455578191251, 7.6386942026470486],
					[64.643455550402635, 7.6386942061317873],
					[64.643455536985726, 7.6386942109897404],
					[64.643447136668684, 7.6386970093505049],
					[64.643447116133331, 7.6386970208439884],
					[64.643447076856702, 7.6386970437411019],
					[64.643447058153967, 7.6386970593288064],
					[64.643416630560196, 7.6387275859465804],
					[64.643233332696354, 7.6386970030114369],
					[64.643233324603827, 7.6386970013237399],
					[64.64323330843807, 7.6386970000403638],
					[64.643233300364798, 7.6386970004446866],
					[64.643219399876457, 7.6386969996391096],
					[64.643219346189611, 7.6386970169787638],
					[64.643219255193543, 7.6386970759536599],
					[64.643219217884337, 7.6386971175889409],
					[64.643110918094962, 7.6389360174147258],
					[64.64311091111135, 7.6389360386942098],
					[64.643110901609958, 7.6389360789365623],
					[64.643110900008466, 7.6389360999465481],
					[64.643110899755854, 7.6389388019952902],
					[64.642997076573224, 7.6390276425347787],
					[64.642997063233352, 7.639027655760513],
					[64.642997038366914, 7.6390276842141764],
					[64.642997025962572, 7.6390277015790025],
					[64.64295262593734, 7.6391055016733329],
					[64.64295262593734, 7.6391055016733329],
					[64.642891526104307, 7.639213801105921],
					[64.642891517326547, 7.6392138224749768],
					[64.642891505153287, 7.6392138649436436],
					[64.642891500860685, 7.6392138860881431],
					[64.642874801157561, 7.6394443868772006],
					[64.64287480027977, 7.639444389014094],
					[64.642874800337523, 7.6394443952900746],
					[64.642874800376035, 7.6394443994740797],
					[64.642874800368432, 7.6395166610351044],
					[64.642783114650612, 7.639741625402281],
					[64.642783109422439, 7.6397416424075297],
					[64.642783101695656, 7.6397416804673721],
					[64.642783100074809, 7.6397416993850502],
					[64.642783100425305, 7.6397471996492712],
					[64.642783099558997, 7.6397527627629493],
					[64.642677614267896, 7.6400249275168548],
					[64.642677613390077, 7.6400249296537321],
					[64.642677613390077, 7.6400249296537321],
					[64.642677611615269, 7.6400249318354723],
					[64.642585920078218, 7.6402749099978147],
					[64.642569233933784, 7.6402998887917928],
					[64.642569225117427, 7.6402999059762822],
					[64.642569210156509, 7.6402999381187007],
					[64.642569204947506, 7.6402999572156984],
					[64.642563710747737, 7.6403249307829624],
					[64.642485937735941, 7.6404692796371156],
					[64.6424415599177, 7.6405137574633786],
					[64.642341563276645, 7.6406109537533053],
					[64.642280465120947, 7.6406665518298151],
					[64.642280442067502, 7.6406665822834592],
					[64.642280410389958, 7.6406666508407994],
					[64.642280399971838, 7.6406666890342478],
					[64.642272005406966, 7.6408137969484136],
					[64.642158180281527, 7.6409026394655211],
					[64.642119306333626, 7.6409304220494558],
					[64.642066645857795, 7.6409498064602754],
					[64.641952765315423, 7.6409720024485113],
					[64.641899968850154, 7.6409804016238283],
					[64.641899944688063, 7.6409804091104467],
					[64.641899900887466, 7.6409804280433296],
					[64.641899880351943, 7.6409804395344221],
					[64.641799952614505, 7.641055385594159],
					[64.641761142000277, 7.6410470058351843],
					[64.641761141084032, 7.6410470037881186],
					[64.641761136598902, 7.6410470040123402],
					[64.641761135701842, 7.6410470040571861],
					[64.641669435956516, 7.6410304021878552],
					[64.641669376791015, 7.6410304093313988],
					[64.641669274914264, 7.6410304562817961],
					[64.641669230389695, 7.6410304940864178],
					[64.641585932790733, 7.6411637898968374],
					[64.641463794559229, 7.6413415006267691],
					[64.641449999972025, 7.6413414994441897],
					[64.641449945387777, 7.6413415168222727],
					[64.641449852615793, 7.6413415779659957],
					[64.641449816222078, 7.6413416216419714],
					[64.641441519027296, 7.6413610163885686],
					[64.641433132213081, 7.641377690713349],
					[64.641297049606081, 7.6415415678133698],
					[64.641227653034036, 7.6416165645678618],
					[64.641227642385005, 7.6416165776569862],
					[64.64122762291943, 7.6416166079293211],
					[64.641227614980764, 7.6416166229758575],
					[64.641166516291619, 7.6417638216684667],
					[64.641097018256232, 7.6419193187880206],
					[64.641052621706862, 7.6420166093409181],
					[64.641005425718518, 7.6420999003469214],
					[64.641005423962881, 7.6420999046202622],
					[64.641005419554574, 7.6420999132117107],
					[64.641005417798965, 7.6420999174850479],
					[64.640930418983544, 7.6422638160166816],
					[64.640838719910093, 7.6424555133858405],
					[64.64083871815447, 7.6424555176591307],
					[64.640838713746149, 7.6424555262504681],
					[64.64083871200971, 7.6424555326155978],
					[64.640816517425122, 7.6425166185378481],
					[64.640785923103891, 7.6425749071552174],
					[64.640785921329027, 7.6425749093366564],
					[64.640785918695599, 7.6425749157465361],
					[64.640785916939947, 7.6425749200197917],
					[64.640699818073401, 7.6427693179302141],
					[64.640594218774837, 7.6429916137807368],
					[64.64059421879405, 7.6429916158725577],
					[64.640594217916188, 7.6429916180091571],
					[64.640594217916188, 7.6429916180091571],
					[64.640580417933947, 7.6430221185526896],
					[64.640580415300491, 7.6430221249624983],
					[64.640580410052678, 7.6430221398739198],
					[64.640580407419222, 7.6430221462837284],
					[64.640572013432021, 7.6430527243554316],
					[64.64045813081637, 7.6432609913021752],
					[64.640449839715899, 7.6432720803191634],
					[64.640449836185397, 7.6432720867736839],
					[64.640449829085952, 7.6432720954990909],
					[64.640449825555422, 7.6432721019535936],
					[64.640349829106214, 7.6434498940867162],
					[64.640327633976099, 7.6434831883137804],
					[64.64032763044554, 7.6434831947682493],
					[64.640327624262326, 7.6434832055405986],
					[64.640327620731767, 7.6434832119950462],
					[64.640305433941776, 7.6435276852784737],
					[64.640183155426371, 7.643660961011415],
					[64.640127677578221, 7.6437165393791959],
					[64.640008223275856, 7.643783112970624],
					[64.639899964660572, 7.6438137017573231],
					[64.63979441282288, 7.6438219975375716],
					[64.639677859007747, 7.6437970071089385],
					[64.639525080816625, 7.6437359168216368],
					[64.639450095105687, 7.6436998232434892],
					[64.639252914109719, 7.6435804352756316],
					[64.639227922777053, 7.643560941637733],
					[64.639227911038873, 7.6435609338526769],
					[64.639227885787633, 7.6435609204638135],
					[64.639227874068652, 7.6435609147704744],
					[64.639214012105796, 7.6435554288124186],
					[64.639000141103722, 7.6433415592089569],
					[64.639000070828146, 7.6433415292332247],
					[64.638999929039713, 7.6433415300306677],
					[64.638999859301663, 7.6433415586225975],
					[64.638924859172121, 7.6434165581837101],
					[64.638808179630772, 7.6435304381938174],
					[64.638777703940136, 7.6435470236774474],
					[64.63877769235549, 7.6435470326259987],
					[64.638777669167027, 7.6435470484314596],
					[64.638777659395643, 7.6435470593822012],
					[64.638763767931593, 7.6435609507632387],
					[64.638702680432573, 7.6436081406588574],
					[64.638588782252413, 7.6436915391284996],
					[64.638588773339691, 7.6436915458510057],
					[64.638588756430451, 7.6436915613429584],
					[64.638588746639897, 7.6436915702019022],
					[64.638558156424708, 7.6437276601702733],
					[64.63842766534286, 7.6438470517947543],
					[64.638427662670949, 7.6438470540206449],
					[64.638427659999053, 7.6438470562465337],
					[64.638427659121191, 7.6438470583829554],
					[64.638335960676599, 7.6439387560800771],
					[64.638266565253232, 7.6440026538362602],
					[64.63826656078723, 7.6440026561516214],
					[64.638266554565604, 7.6440026627397257],
					[64.638266551893707, 7.6440026649655879],
					[64.638122068136553, 7.644160947872976],
					[64.63811380002565, 7.6441665268044243],
					[64.638108213029369, 7.644169220271591],
					[64.638108196062532, 7.6441692294880754],
					[64.638108164877465, 7.6441692540617376],
					[64.638108149743033, 7.6441692673720167],
					[64.637960954610747, 7.6443359618969211],
					[64.637885960583205, 7.6444081556286303],
					[64.637885958808326, 7.6444081578096945],
					[64.637885956136429, 7.6444081600354847],
					[64.637885954361536, 7.6444081622165498],
					[64.637802654217751, 7.6444970623912099],
					[64.637719257959432, 7.6445859584374443],
					[64.637663762374217, 7.6446387559642135],
					[64.63766375615252, 7.6446387625520478],
					[64.637663742812123, 7.6446387757724112],
					[64.637663736609611, 7.6446387844518409],
					[64.637649846959931, 7.6446581696560818],
					[64.637597058842132, 7.6447109574902807],
					[64.637488761712845, 7.6448165551596166],
					[64.637444264720685, 7.6448581525465507],
					[64.63734426741263, 7.6449470512970183],
					[64.637344260293872, 7.6449470579294099],
					[64.637344246056429, 7.6449470711941707],
					[64.637344239853917, 7.6449470798734271],
					[64.637327650323797, 7.6449692672211969],
					[64.637222062136374, 7.6450692558538718],
					[64.637222061239328, 7.6450692558985738],
					[64.637222060361495, 7.6450692580348383],
					[64.637222058567446, 7.6450692581242423],
					[64.637169259647109, 7.645122057408007],
					[64.637024860943455, 7.6452609553817359],
					[64.637024860962612, 7.645260957473285],
					[64.637024859168548, 7.6452609575626758],
					[64.637024859187719, 7.6452609596542436],
					[64.637008173407651, 7.645277643865982],
					[64.636999890075401, 7.6452831330044893],
					[64.636999881162595, 7.6452831397260965],
					[64.636999866028077, 7.6452831530352077],
					[64.636999858909334, 7.6452831596674065],
					[64.636985959278533, 7.6452970580133739],
					[64.636847059065687, 7.6454359584746401],
					[64.636847058168669, 7.6454359585193297],
					[64.636780458230163, 7.6455026600331317],
					[64.636780457333117, 7.6455026600778186],
					[64.636780457333117, 7.6455026600778186],
					[64.636688756660845, 7.6455970605919115],
					[64.636688754885952, 7.6455970627728203],
					[64.636688753111059, 7.6455970649537113],
					[64.636688752214013, 7.6455970649983964],
					[64.636605451926428, 7.6456887656549135],
					[64.636605451048553, 7.6456887677911105],
					[64.636605449273659, 7.6456887699719864],
					[64.636605448376628, 7.6456887700166671],
					[64.636558152815937, 7.6457442640787114],
					[64.636535957851706, 7.6457665599851179],
					[64.636535956057628, 7.645766560074474],
					[64.636535951629995, 7.6457665665724157],
					[64.636535949855087, 7.6457665687532819],
					[64.636497049601729, 7.6458109686504958],
					[64.636497048704697, 7.645810968695173],
					[64.636497047826836, 7.6458109708313771],
					[64.636383147927134, 7.6459442709049137],
					[64.636330448086667, 7.6460054693734198],
					[64.63633044631176, 7.6460054715542558],
					[64.636330441864914, 7.6460054759605951],
					[64.636330440109163, 7.6460054802329465],
					[64.636238739782684, 7.646127679774855],
					[64.636238738904822, 7.6461276819110084],
					[64.636238738007776, 7.646127681955674],
					[64.636238738007776, 7.646127681955674],
					[64.636160939973266, 7.646235981019248],
					[64.636108143175193, 7.6463026758844643],
					[64.636108136094691, 7.6463026866991548],
					[64.636108121952901, 7.6463027104200103],
					[64.636108115769446, 7.6463027211900201],
					[64.636099823231703, 7.6463221040594362],
					[64.636077636381032, 7.6463554865129293],
					[64.635955437656349, 7.6465248837009758],
					[64.635955436759318, 7.6465248837456254],
					[64.635955435003567, 7.6465248880178835],
					[64.635955434106535, 7.6465248880625349],
					[64.635885934331256, 7.6466276872332655],
					[64.635885932556334, 7.6466276894140242],
					[64.635885929922708, 7.6466276958223744],
					[64.635885928147772, 7.646627698003134],
					[64.635824830126623, 7.6467304948840864],
					[64.635719232245677, 7.6468970916302448],
					[64.635688734866477, 7.646944286436355],
					[64.635666537639921, 7.6469748833978333],
					[64.635666535864985, 7.646974885578552],
					[64.635666531437309, 7.6469748920760878],
					[64.635666528784469, 7.6469748963928899],
					[64.635558128666474, 7.6471554969141149],
					[64.635558118131826, 7.6471555225469636],
					[64.635558104238697, 7.6471555734556196],
					[64.63555810000237, 7.6471556008674959],
					[64.635558100071535, 7.6471638448935497],
					[64.635444229054272, 7.6473526964364718],
					[64.635444224626511, 7.6473527029338957],
					[64.635444217584222, 7.6473527179309038],
					[64.635444214931411, 7.6473527222476303],
					[64.635430420755426, 7.6473860098715356],
					[64.635399829403553, 7.6474359953441384],
					[64.635399827628603, 7.6474359975248056],
					[64.635399826750742, 7.6474359996608507],
					[64.635399825872824, 7.6474360017969163],
					[64.635299827438317, 7.6476137996914737],
					[64.635222027730961, 7.6477442970660627],
					[64.635222026853043, 7.6477442992020865],
					[64.635222025097249, 7.6477443034741297],
					[64.635222024219331, 7.6477443056101713],
					[64.635169224049079, 7.6478416045424646],
					[64.635169224068221, 7.6478416066338717],
					[64.635169223171204, 7.6478416066784787],
					[64.635099823331856, 7.6479721056677423],
					[64.63509982335097, 7.6479721077591458],
					[64.635047022606926, 7.6480721070601785],
					[64.635047021729036, 7.6480721091961756],
					[64.6350470199732, 7.6480721134681726],
					[64.635047019095325, 7.6480721156041884],
					[64.635010922954393, 7.6481499065060987],
					[64.63491922876787, 7.6483026971702186],
					[64.634919226992892, 7.6483026993507925],
					[64.63491922435918, 7.6483027057587316],
					[64.634919222584244, 7.6483027079393215],
					[64.63481092429268, 7.6485110060239432],
					[64.634797025345435, 7.6485360034666545],
					[64.634797022692567, 7.6485360077831652],
					[64.634797018283919, 7.6485360163716187],
					[64.634797015650165, 7.6485360227795089],
					[64.634788721079872, 7.6485555087350896],
					[64.63471092768242, 7.6486859985078643],
					[64.63471092678536, 7.6486859985524429],
					[64.634710925926598, 7.6486860027797663],
					[64.634710925029538, 7.6486860028243449],
					[64.634666525105459, 7.6487666023665177],
					[64.634666524227569, 7.6487666045024625],
					[64.634666524246683, 7.6487666065938331],
					[64.634666523349637, 7.6487666066384081],
					[64.634566525204505, 7.6489555028437506],
					[64.634544226630567, 7.6489942995967892],
					[64.634513726891086, 7.6490470995760029],
					[64.634513726013154, 7.6490471017119299],
					[64.634413726160801, 7.6492221008975179],
					[64.634413726179901, 7.649222102988869],
					[64.634413724404965, 7.6492221051693399],
					[64.634413724404965, 7.6492221051693399],
					[64.634347025545623, 7.6493471002221387],
					[64.634285929725479, 7.6494470959256162],
					[64.634199830299337, 7.6495859940807467],
					[64.634199827627327, 7.6495859963057242],
					[64.634199824993573, 7.6495860027133702],
					[64.634199824134726, 7.6495860069406065],
					[64.634155424157242, 7.6496693046111899],
					[64.634124826765259, 7.6497248990167321],
					[64.634024830159362, 7.6498859940237871],
					[64.633949829935162, 7.6500081944826155],
					[64.633949829057258, 7.6500081966184643],
					[64.633902642152833, 7.6500859757766735],
					[64.633888759004577, 7.6500998587696838],
					[64.633888751885621, 7.6500998653998549],
					[64.633888740357889, 7.6500998806179599],
					[64.633888734155065, 7.6500998892949141],
					[64.633872034049872, 7.6501248881147648],
					[64.633749833870141, 7.6503054877203907],
					[64.633749832992194, 7.6503054898562146],
					[64.633749830339312, 7.650305494172402],
					[64.633749828564348, 7.6503054963527486],
					[64.633683134679927, 7.6504165865976148],
					[64.633613742246055, 7.6505054776178874],
					[64.633613741368094, 7.6505054797536953],
					[64.633613736921106, 7.6505054841588747],
					[64.633613736043188, 7.6505054862946809],
					[64.633499837694615, 7.6506692820572475],
					[64.633474840157845, 7.6507026800056712],
					[64.633474839260828, 7.6507026800501805],
					[64.633474838382867, 7.6507026821859707],
					[64.633474836607888, 7.650702684366288],
					[64.633460940016334, 7.6507220805142913],
					[64.633408143090691, 7.6507887766539655],
					[64.63340814219363, 7.650788776698473],
					[64.633408140437751, 7.6507887809700339],
					[64.633408138643674, 7.6507887810590463],
					[64.633330440994769, 7.6508942781193259],
					[64.633238743129112, 7.6510109761651499],
					[64.633185943416322, 7.651077675511714],
					[64.633080447056784, 7.6512109699755024],
					[64.633033150934381, 7.6512637670139521],
					[64.633033143834439, 7.6512637757348747],
					[64.633033131428618, 7.6512637930877228],
					[64.633033125244822, 7.6512638038554019],
					[64.633019233800425, 7.6512887873955924],
					[64.632958148095568, 7.6513637694944903],
					[64.632888750825771, 7.6514415673414851],
					[64.632888749928725, 7.6514415673859695],
					[64.632888749031721, 7.6514415674304512],
					[64.632888749050792, 7.6514415695217073],
					[64.632797050888215, 7.6515470664688987],
					[64.632735953205412, 7.6516137650425309],
					[64.632735952308394, 7.6516137650870073],
					[64.632735952308394, 7.6516137650870073],
					[64.632605451808303, 7.6517581656996816],
					[64.63260545003331, 7.6517581678798594],
					[64.632605445586293, 7.6517581722846515],
					[64.6326054438113, 7.6517581744648098],
					[64.632583150930373, 7.6517859652061668],
					[64.632569258760995, 7.6517998596440977],
					[64.632569256088942, 7.6517998618687217],
					[64.632569249866918, 7.6517998684536739],
					[64.632569247194908, 7.651799870678297],
					[64.632538749396559, 7.6518359680923567],
					[64.632430451663822, 7.6519581645875947],
					[64.632347053868273, 7.6520470638424269],
					[64.632347052971269, 7.6520470638868892],
					[64.632347052093294, 7.6520470660225461],
					[64.632347051196263, 7.6520470660670084],
					[64.632277651817148, 7.6521248667715804],
					[64.632141555649, 7.652272060774143],
					[64.632124859356139, 7.6522887569736655],
					[64.632119260117875, 7.65229425639889],
					[64.632119253895837, 7.6522942629835979],
					[64.632119244142856, 7.6522942760196742],
					[64.632119238798794, 7.6522942804687712],
					[64.632110946196576, 7.6523054721411459],
					[64.631966553303144, 7.6524609647876272],
					[64.631966553303144, 7.6524609647876272],
					[64.631905452558883, 7.6525276656622188],
					[64.631905451661837, 7.652527665706665],
					[64.631905449886844, 7.6525276678867362],
					[64.631905449008897, 7.6525276700223426],
					[64.631813749242099, 7.6526331699655366],
					[64.631813748345039, 7.6526331700099801],
					[64.631813746550975, 7.6526331700988637],
					[64.63181374657006, 7.6526331721900167],
					[64.631727647266672, 7.6527359713266208],
					[64.631727644594648, 7.6527359735510831],
					[64.631727641941652, 7.6527359778666941],
					[64.631727641063705, 7.6527359800022792],
					[64.631683140752472, 7.6527942797744517],
					[64.631683139855454, 7.6527942798188855],
					[64.631683138080447, 7.6527942819989025],
					[64.631683137202458, 7.652794284134484],
					[64.631669240365326, 7.652813780439546],
					[64.631638747144592, 7.6528526703046662],
					[64.631516553295526, 7.6529859654628494],
					[64.631516552398523, 7.6529859655072769],
					[64.631460951946806, 7.6530470652426859],
					[64.631460951068803, 7.6530470673782425],
					[64.631460948396779, 7.6530470696026507],
					[64.631460945724726, 7.6530470718270625],
					[64.631363745851218, 7.6531637716972893],
					[64.631363744973257, 7.6531637738328326],
					[64.631363744095268, 7.6531637759683955],
					[64.63136374319825, 7.6531637760128186],
					[64.631302642785172, 7.6532415763619932],
					[64.631302641888112, 7.6532415764064163],
					[64.631302641010137, 7.6532415785419516],
					[64.631302641010137, 7.6532415785419516],
					[64.631241546259048, 7.6533220728285007],
					[64.631216550919746, 7.6533498649763683],
					[64.631216545594711, 7.6533498715161983],
					[64.631216536738663, 7.6533498845070431],
					[64.631216532310688, 7.6533498910024722],
					[64.631185937812717, 7.6533970837987768],
					[64.631072044132353, 7.6535387750124579],
					[64.631072042357331, 7.6535387771923959],
					[64.631072037910258, 7.6535387815966454],
					[64.631072036154279, 7.6535387858676627],
					[64.631019240027996, 7.6536137801246298],
					[64.630927643824762, 7.6537276756601944],
					[64.63092764292773, 7.6537276757045998],
					[64.630927640274706, 7.653727680019986],
					[64.630927639396702, 7.6537276821554787],
					[64.630822039093133, 7.6538720814312624],
					[64.630822037318111, 7.6538720836111382],
					[64.630822032871009, 7.6538720880152935],
					[64.630822032012063, 7.6538720922418708],
					[64.630797031849752, 7.6539109923295738],
					[64.630797029196742, 7.6539109966449388],
					[64.63079702299369, 7.6539110053200323],
					[64.630797021256754, 7.6539110116820783],
					[64.630791531993353, 7.6539220886653361],
					[64.63077484831733, 7.6539415689548367],
					[64.630774845645291, 7.6539415711791197],
					[64.630774842992281, 7.6539415754944615],
					[64.630774842114292, 7.6539415776299338],
					[64.630644242253268, 7.6541109776561642],
					[64.630644241356265, 7.6541109777005545],
					[64.63064423958123, 7.6541109798804223],
					[64.630577642422679, 7.654199878319031],
					[64.630499845320088, 7.6542970729774371],
					[64.630363758175577, 7.6544609587635559],
					[64.630347071978278, 7.6544748466502259],
					[64.630347045371792, 7.6544748814383237],
					[64.630347009278694, 7.6544749585354985],
					[64.630346999792053, 7.654475000844597],
					[64.630346999595517, 7.6544777392670973],
					[64.630341538333923, 7.6544859820130577],
					[64.63020264669845, 7.6546581716872106],
					[64.630127648805995, 7.6547442674721653],
					[64.630049848567836, 7.6548331686334885],
					[64.630049848567836, 7.6548331686334885],
					[64.630049847689847, 7.6548331707688746],
					[64.629935956111453, 7.6549665594159135],
					[64.629905466345335, 7.65499425221984],
					[64.629905459226137, 7.6549942588477515],
					[64.629905445884773, 7.6549942720592323],
					[64.629905439681636, 7.6549942807338116],
					[64.629897044587807, 7.6550054736329392],
					[64.629874850417437, 7.6550304675354903],
					[64.629874850417437, 7.6550304675354903],
					[64.62987484774537, 7.6550304697595726],
					[64.629874846867352, 7.6550304718949604],
					[64.629744246756644, 7.6551859705101748],
					[64.629744245878655, 7.6551859726455262],
					[64.629744244981595, 7.655185972689881],
					[64.629744245000623, 7.6551859747808777],
					[64.629683145001778, 7.6552609731163015],
					[64.62968314410476, 7.6552609731606518],
					[64.629683143226728, 7.6552609752959961],
					[64.629599842556502, 7.6553665754537565],
					[64.629499865940858, 7.6554915483893762],
					[64.629463795019888, 7.6555137297876446],
					[64.629463778090354, 7.655513743176023],
					[64.629463746941383, 7.65551377191073],
					[64.629463733638005, 7.6555137893037122],
					[64.629447033565427, 7.6555387890321898],
					[64.629447028259307, 7.6555387976621354],
					[64.629447017666067, 7.6555388170130447],
					[64.629447013276021, 7.6555388276896439],
					[64.629433123310889, 7.6555749011609242],
					[64.629310939682526, 7.6557387792738991],
					[64.629241541034659, 7.6558304791034706],
					[64.629241538381578, 7.6558304834184012],
					[64.62924153305643, 7.6558304899572676],
					[64.629241530403334, 7.6558304942722],
					[64.629172030819419, 7.6559415925964274],
					[64.629058132574485, 7.6561165906653521],
					[64.629035937409668, 7.6561498835306541],
					[64.629019239556854, 7.6561720792322534],
					[64.629019236903801, 7.656172083547097],
					[64.629019230700578, 7.6561720922211238],
					[64.629019228944543, 7.6561720964916482],
					[64.62894422857859, 7.6562970975499951],
					[64.628944227681572, 7.6562970975943099],
					[64.628944225925522, 7.6562971018648129],
					[64.628944225047476, 7.6562971040000658],
					[64.628905427880369, 7.6563665966337098],
					[64.628799831840837, 7.6565304916604742],
					[64.628799830962791, 7.656530493795727],
					[64.628799830084787, 7.6565304959309586],
					[64.628799829187741, 7.6565304959752671],
					[64.628769228733788, 7.6565804968766242],
					[64.628722028820548, 7.6566581958372302],
					[64.628722027942516, 7.6566581979724537],
					[64.628722027045484, 7.6566581980167561],
					[64.628722027064512, 7.6566582001076746],
					[64.628638727221443, 7.656802700658897],
					[64.628577627236737, 7.6569081998085267],
					[64.628577626358691, 7.6569082019437325],
					[64.62850812637295, 7.6570305007887169],
					[64.628508126391949, 7.6570305028796222],
					[64.628508124597872, 7.657030502968202],
					[64.628416525243978, 7.6571943023492315],
					[64.628416522590854, 7.657194306663901],
					[64.628416519078726, 7.6571943152046265],
					[64.628416517322634, 7.6571943194750096],
					[64.628385919639527, 7.6572638105027471],
					[64.628355425389842, 7.6573193039915344],
					[64.628355422717718, 7.6573193062152685],
					[64.628355420083608, 7.6573193126208077],
					[64.628355419205562, 7.6573193147559806],
					[64.628266520016155, 7.6575082147524824],
					[64.628158120143581, 7.657730511570743],
					[64.628158118406475, 7.657730517931908],
					[64.628158112241138, 7.6577305307870596],
					[64.628158109607, 7.6577305371925117],
					[64.628149817980614, 7.6577555147616563],
					[64.628135929625685, 7.657777693519435],
					[64.628135927888579, 7.6577776998806106],
					[64.628135921685271, 7.65777770855399],
					[64.628135919929178, 7.657777712824271],
					[64.628027619603984, 7.6580027135516167],
					[64.628027617847891, 7.6580027178218852],
					[64.628027615213725, 7.6580027242272628],
					[64.628027612560572, 7.6580027285417902],
					[64.628013880741292, 7.6580383935955982],
					[64.627914081010758, 7.657827713433254],
					[64.627913993447436, 7.6578276570917518],
					[64.627913813926, 7.6578276533991634],
					[64.627913723762006, 7.657827705959547],
					[64.627808124381616, 7.6580249053560365],
					[64.62780812258751, 7.6580249054445515],
					[64.627808121709464, 7.6580249075796578],
					[64.627808121728478, 7.6580249096705275],
					[64.627747027648724, 7.6581442961527397],
					[64.62769423641555, 7.6582192844496388],
					[64.627694226700001, 7.6582193016631424],
					[64.627694211735133, 7.658219333778078],
					[64.627694205626725, 7.6582193529054434],
					[64.627685907742318, 7.6582527433493519],
					[64.627677611635676, 7.6582777310005978],
					[64.627577614106244, 7.6585332265524828],
					[64.627577613228183, 7.6585332286875554],
					[64.62757761236908, 7.6585332329134612],
					[64.627577610594003, 7.6585332350927731],
					[64.627510911333417, 7.658727735077866],
					[64.627510912875692, 7.6587278061219246],
					[64.627510965145476, 7.6587279290506096],
					[64.627511085020814, 7.6587279858926527],
					[64.627511213012554, 7.6587279482049482],
					[64.627511270062399, 7.6587279056482505],
					[64.627602969684332, 7.6585807060896283],
					[64.627602969665347, 7.6585807039987941],
					[64.627602970562378, 7.6585807039545575],
					[64.627602972356442, 7.6585807038660825],
					[64.627694271929172, 7.6584284023537146],
					[64.627730395406559, 7.6587249719904156],
					[64.627699815182496, 7.6587999244738496],
					[64.627699809074031, 7.6587999436010694],
					[64.627699802239292, 7.6587999815901702],
					[64.627699799718968, 7.6588000005404693],
					[64.6276997996505, 7.6588027616966512],
					[64.627669285241225, 7.6588775538779652],
					[64.627480662073026, 7.6588165097528478],
					[64.627480645888397, 7.6588165063673062],
					[64.627480616229207, 7.6588165015543987],
					[64.627480600063578, 7.6588165002597002],
					[64.627472200032315, 7.6588165002629474],
					[64.627472188370817, 7.6588165008379292],
					[64.62747216416976, 7.6588165041229699],
					[64.627472150733183, 7.6588165068772369],
					[64.627272150688526, 7.6588665065981001],
					[64.627272146203353, 7.6588665068192352],
					[64.627272134579798, 7.6588665115758134],
					[64.627272130113596, 7.6588665138877596],
					[64.627250020422238, 7.6588747786736553],
					[64.627236203057421, 7.6588665281584936],
					[64.627236150877422, 7.6588665139971983],
					[64.6272360476801, 7.6588665149017032],
					[64.627235996643833, 7.6588665278767172],
					[64.627083197107339, 7.6589581282433103],
					[64.627083196210322, 7.6589581282875336],
					[64.62708319443523, 7.6589581304667771],
					[64.627083192641123, 7.6589581305552263],
					[64.627030492986734, 7.6589915314720507],
					[64.627030491192656, 7.6589915315604955],
					[64.627030487642472, 7.6589915359189895],
					[64.627030485848408, 7.6589915360074361],
					[64.626930485764802, 7.6590609369489844],
					[64.626930476851328, 7.6590609436635608],
					[64.626930459043365, 7.6590609591834768],
					[64.626930451045951, 7.6590609679446136],
					[64.626908151272133, 7.6590859672395961],
					[64.626908150375115, 7.6590859672838159],
					[64.626908148600009, 7.6590859694630362],
					[64.626908147702963, 7.6590859695072551],
					[64.626808147772707, 7.6592026696960467],
					[64.626808146016572, 7.6592026739660275],
					[64.626808140672296, 7.6592026784128882],
					[64.626808138897189, 7.6592026805920943],
					[64.626760939008577, 7.6592665807564675],
					[64.626760939027562, 7.6592665828472386],
					[64.626760938130545, 7.6592665828914512],
					[64.626760937252456, 7.6592665850264359],
					[64.626685944030868, 7.659372074061447],
					[64.626616555851669, 7.6594470612466337],
					[64.626585958817174, 7.6594776583190907],
					[64.626585951716748, 7.6594776670357909],
					[64.626585940169051, 7.6594776801550344],
					[64.626585933965643, 7.6594776888275282],
					[64.626472037442724, 7.6596470849217129],
					[64.626463739845661, 7.6596581802001582],
					[64.626463737192481, 7.6596581845142548],
					[64.626463732764165, 7.6596581910075194],
					[64.626463730092041, 7.6596581932308672],
					[64.626366529862949, 7.6598137947239682],
					[64.626366528984903, 7.6598137968589235],
					[64.626366524556573, 7.6598138033521206],
					[64.626366522781467, 7.6598138055312495],
					[64.626341522810549, 7.6598610059969303],
					[64.6263415210544, 7.6598610102667966],
					[64.626341518439105, 7.6598610187623537],
					[64.626341515766981, 7.6598610209856695],
					[64.626319219320408, 7.6599138130952786],
					[64.626219224267075, 7.6600999047817995],
					[64.626219223389, 7.6600999069167131],
					[64.626219221613894, 7.6600999090958304],
					[64.626219220735791, 7.6600999112307475],
					[64.626144227437919, 7.6602498980332676],
					[64.626091534177519, 7.6603276882961611],
					[64.626091533280501, 7.6603276883403373],
					[64.626091530627292, 7.6603276926543291],
					[64.626091529749218, 7.6603276947892258],
					[64.626013734899274, 7.6604526870030494],
					[64.625952640863801, 7.6605331784064603],
					[64.62595263908868, 7.6605331805855297],
					[64.625952637332517, 7.6605331848552893],
					[64.625952634660365, 7.6605331870785083],
					[64.625922037756538, 7.6605776832302208],
					[64.625808148968346, 7.6607276681095566],
					[64.625799859087635, 7.6607359596110776],
					[64.625799853743302, 7.6607359640574506],
					[64.625799844867657, 7.6607359749525932],
					[64.625799841317388, 7.6607359793106413],
					[64.625702649111588, 7.6608637686827672],
					[64.62566375923106, 7.6609026588486566],
					[64.625663758334028, 7.6609026588928133],
					[64.625663757436982, 7.6609026589369709],
					[64.625663756558922, 7.6609026610718356],
					[64.625572061760579, 7.6609970557567877],
					[64.625502678752596, 7.661058140828338],
					[64.625472196439389, 7.6610775641490232],
					[64.625350120296503, 7.661005438692845],
					[64.625252940697223, 7.6609165583074459],
					[64.625166853060904, 7.660824869430428],
					[64.625022372127688, 7.6606359969584812],
					[64.624961282383552, 7.6605027178673826],
					[64.624794583209052, 7.6601332196963501],
					[64.624733484873815, 7.6599916230498257],
					[64.62456408715552, 7.6595527286290945],
					[64.624564085323527, 7.659552724536268],
					[64.624564081659528, 7.659552716350654],
					[64.624564079827522, 7.6595527122578275],
					[64.624527980202757, 7.6594777127015856],
					[64.624527979286725, 7.6594777106551879],
					[64.624527975641698, 7.6594777045601941],
					[64.624527974725694, 7.6594777025137954],
					[64.624336275403024, 7.6591332020319802],
					[64.624336266318835, 7.6591331899305768],
					[64.624336248150414, 7.6591331657277726],
					[64.624336238188135, 7.659133155761169],
					[64.624169540831062, 7.6589748582509696],
					[64.624108444778884, 7.6589109625623628],
					[64.624108372693229, 7.6589109305602294],
					[64.624108229988281, 7.6589109292288775],
					[64.624108157574867, 7.6589109599880807],
					[64.624091558424823, 7.6589276590415611],
					[64.624038759334923, 7.6589804598510032],
					[64.624038752196583, 7.6589804643858654],
					[64.624038740667885, 7.6589804795946419],
					[64.62403873534258, 7.6589804861316502],
					[64.623947034948898, 7.6591137878838937],
					[64.623947026989384, 7.6591138008251551],
					[64.623947013780437, 7.6591138286655998],
					[64.623947009409051, 7.6591138414300328],
					[64.62389420894938, 7.6592888411210565],
					[64.623894208071334, 7.6592888432558333],
					[64.62389420719326, 7.6592888453905914],
					[64.623894207212217, 7.6592888474811449],
					[64.623855414747737, 7.6594305186041067],
					[64.623802630785079, 7.6595137922118495],
					[64.623802622844494, 7.6595138072434725],
					[64.62380260967339, 7.6595138392646831],
					[64.623802605320989, 7.659513854119508],
					[64.623755405309282, 7.6597166542747823],
					[64.623755405328254, 7.6597166563653225],
					[64.623694205258644, 7.6599888545055848],
					[64.62368040605179, 7.6600443516791072],
					[64.62368040437147, 7.6600443643107008],
					[64.623680400991859, 7.6600443874833557],
					[64.623680400208556, 7.6600444000707695],
					[64.623680400362801, 7.6600555998994579],
					[64.623680399953997, 7.6605666993428532],
					[64.623680399559788, 7.6605721999347267],
					[64.623680399578731, 7.6605722020252625],
					[64.623680399635589, 7.6605722082968901],
					[64.623680399673489, 7.6605722124779616],
					[64.623710900064793, 7.6610889050573414],
					[64.623710899722354, 7.6611582960139497],
					[64.623688699617404, 7.6616110901309717],
					[64.623688699636332, 7.6616110922215297],
					[64.623688699693133, 7.6616110984931414],
					[64.623688699712091, 7.6616111005836789],
					[64.623688700169012, 7.6616166994330772],
					[64.62368870036768, 7.6616221707153311],
					[64.623597008574805, 7.6619249411217618],
					[64.623597007696674, 7.6619249432564311],
					[64.623597007715603, 7.6619249453469616],
					[64.623597007715603, 7.6619249453469616],
					[64.623535907804907, 7.6621416454048612],
					[64.623535906926818, 7.6621416475394968],
					[64.623535906086559, 7.6621416538551896],
					[64.623535905208456, 7.6621416559898252],
					[64.623510904889883, 7.6622499555076624],
					[64.623510904908812, 7.6622499575981866],
					[64.623510904030724, 7.6622499597328364],
					[64.623510904049638, 7.6622499618233606],
					[64.623466503844298, 7.6624749602733679],
					[64.623466503863227, 7.6624749623638895],
					[64.623466502966195, 7.6624749624079893],
					[64.623466502985139, 7.66247496449851],
					[64.623444206449733, 7.6625971495201197],
					[64.623419210998748, 7.662669334913617],
					[64.623419209261442, 7.6626693412733768],
					[64.623419204889743, 7.6626693540369741],
					[64.623419204068398, 7.6626693624431388],
					[64.623374804350533, 7.6628971619402177],
					[64.623374802556455, 7.6628971620283908],
					[64.623374802594284, 7.6628971662094187],
					[64.623374802594284, 7.6628971662094187],
					[64.623366503227444, 7.6629471664892863],
					[64.623322003261933, 7.6631971623067514],
					[64.623299804383848, 7.6633110612478168],
					[64.623299802608685, 7.6633110634264732],
					[64.623299802627599, 7.6633110655169814],
					[64.623299802646514, 7.6633110676074905],
					[64.623252602860092, 7.6636027684161006],
					[64.623252601981946, 7.6636027705506695],
					[64.62325260114163, 7.6636027768662682],
					[64.623252601179473, 7.6636027810472784],
					[64.623244200834478, 7.6636888756782682],
					[64.623238705445686, 7.6637249551784574],
					[64.62315261820217, 7.6639999106967824],
					[64.623038735916992, 7.6641637858994853],
					[64.623038733263641, 7.6641637902125987],
					[64.623038726181832, 7.6641638010174198],
					[64.623038722631435, 7.6641638053745966],
					[64.623016534266341, 7.6642054871732919],
					[64.62296929077209, 7.6642609203258782],
					[64.62282497658795, 7.664294200358837],
					[64.622811100388617, 7.664294199803634],
					[64.62281109231526, 7.6642942001999899],
					[64.622811077065592, 7.6642942009486621],
					[64.622811069889266, 7.6642942013009785],
					[64.622611072340035, 7.6643248020853285],
					[64.622588876160819, 7.6643276004182246],
					[64.622391678054001, 7.6643498002530066],
					[64.622366678290831, 7.6643526002331237],
					[64.622169388637772, 7.6643747999984493],
					[64.622138899950599, 7.6643747992757714],
					[64.622138896362443, 7.664374799451914],
					[64.622138887392055, 7.6643747998922693],
					[64.622138882906839, 7.664374800112447],
					[64.621947183043829, 7.6643915009571275],
					[64.621947182146769, 7.6643915010011643],
					[64.62191669094247, 7.6643942002937129],
					[64.621880600451718, 7.6643942005661243],
					[64.621880594172438, 7.664394200874364],
					[64.621880583407972, 7.6643942014027768],
					[64.621880577128692, 7.6643942017110174],
					[64.621733277131398, 7.6644109019126141],
					[64.62173327533732, 7.6644109020006805],
					[64.62173327085209, 7.6644109022208493],
					[64.621733269058012, 7.6644109023089158],
					[64.621697178916349, 7.6644165009972971],
					[64.621649989069084, 7.6644192001272442],
					[64.621649983686837, 7.6644192003914418],
					[64.621649975632366, 7.6644192028781237],
					[64.621649972025295, 7.6644192009638745],
					[64.621513874034505, 7.6644387012790274],
					[64.621466677193112, 7.664444200942941],
					[64.621466676296052, 7.6644442009869742],
					[64.621466675399006, 7.6644442010310074],
					[64.621422175383387, 7.6644498025250813],
					[64.621422172692263, 7.6644498026571775],
					[64.621422170001125, 7.6644498027892718],
					[64.621422169104108, 7.6644498028333041],
					[64.621299968825852, 7.6644692020346126],
					[64.621247168558853, 7.6644776011983229],
					[64.621247166783675, 7.6644776033767359],
					[64.621247164989583, 7.664477603464797],
					[64.621247164092551, 7.6644776035088267],
					[64.621186063584034, 7.6644887020862464],
					[64.621186061808842, 7.6644887042646523],
					[64.621186059117747, 7.6644887043967422],
					[64.621186057323627, 7.6644887044848042],
					[64.621086058951946, 7.6645109033672494],
					[64.621016661814792, 7.6645248035137481],
					[64.62094167002968, 7.6645387021328863],
					[64.620872175587763, 7.6645470002532008],
					[64.620872174709618, 7.6645470023875513],
					[64.620872172018508, 7.6645470025196323],
					[64.620872171121434, 7.6645470025636619],
					[64.620797171077669, 7.6645581012666684],
					[64.620797165714279, 7.6645581036211485],
					[64.62079715674389, 7.6645581040614168],
					[64.620797153174649, 7.6645581063278421],
					[64.620705576310726, 7.6645803994730564],
					[64.62065830014744, 7.6645804005578047],
					[64.62057499988623, 7.6645804004803599],
					[64.620574983739502, 7.6645804012728238],
					[64.620574952380878, 7.6645804069943253],
					[64.620574937150067, 7.6645804098330625],
					[64.620474977067104, 7.6646136978263222],
					[64.620444418222888, 7.6646109001121943],
					[64.620444414634733, 7.6646109002882907],
					[64.620444409252471, 7.6646109005524385],
					[64.620444405664301, 7.6646109007285368],
					[64.620347205692781, 7.6646081008378646],
					[64.620347180575664, 7.6646081020705443],
					[64.620347132229924, 7.6646081148992939],
					[64.620347108963557, 7.6646081223147764],
					[64.620238808713466, 7.6646637225067717],
					[64.620238804247151, 7.6646637248171583],
					[64.620238794417489, 7.6646637294819735],
					[64.62023878997006, 7.6646637338826347],
					[64.620230545218462, 7.6646691971187693],
					[64.620125009694689, 7.6646637006703289],
					[64.620124974728995, 7.6646637044774559],
					[64.620124906723916, 7.6646637266355659],
					[64.620124874581578, 7.6646637449425112],
					[64.620041574947663, 7.6647304430815426],
					[64.619958230042343, 7.6647970008290889],
					[64.619902799732685, 7.664797000135235],
					[64.619902753181009, 7.6647970128753968],
					[64.619902670993241, 7.6647970545495285],
					[64.619902635394993, 7.6647970876640139],
					[64.619866539177394, 7.66484988150685],
					[64.619797070030017, 7.6649387417086832],
					[64.619697110824845, 7.664988721116849],
					[64.619697100995168, 7.664988725781452],
					[64.619697084045796, 7.6649887370688399],
					[64.61969707513208, 7.6649887437796451],
					[64.619683201232874, 7.6649998238111845],
					[64.619652731978178, 7.6650109129185493],
					[64.619652729287083, 7.665010913050577],
					[64.619652723026661, 7.6650109154488764],
					[64.619652720354424, 7.6650109176711361],
					[64.619505526334876, 7.6650748134294142],
					[64.619452731187195, 7.6650942120865153],
					[64.61945272402977, 7.6650942145287804],
					[64.61945270973375, 7.6650942215035505],
					[64.619452702595169, 7.6650942260360333],
					[64.619377737407476, 7.6651359058989614],
					[64.619308277001039, 7.6651442003013468],
					[64.619233277666453, 7.6651526011713855],
					[64.619233258847459, 7.6651526041856721],
					[64.619233223060277, 7.6651526163968482],
					[64.619233205176101, 7.6651526235475451],
					[64.619124930794101, 7.6652109092410221],
					[64.619111060997255, 7.6652137037088979],
					[64.619011062368401, 7.6652331044327555],
					[64.619011039999052, 7.6652331118033441],
					[64.619010998867353, 7.6652331284586808],
					[64.619010979245743, 7.6652331419678124],
					[64.618919278633967, 7.6653026419564885],
					[64.618919276839875, 7.6653026420444856],
					[64.618919273270606, 7.6653026443106542],
					[64.618919270598354, 7.6653026465328251],
					[64.618849907172986, 7.6653609170375807],
					[64.618783250809884, 7.6653776069146886],
					[64.618783235597974, 7.6653776118429455],
					[64.618783206090015, 7.6653776237456466],
					[64.618783190915849, 7.6653776328542511],
					[64.618735991019989, 7.6654081315024385],
					[64.618735990141815, 7.6654081336365945],
					[64.618735985675514, 7.6654081359467456],
					[64.618735984778439, 7.6654081359907424],
					[64.618574903779503, 7.6655220223025724],
					[64.618561026942842, 7.6655276147572753],
					[64.618369331018783, 7.6655998120314255],
					[64.618338850087525, 7.6656109046680214],
					[64.618302768868659, 7.6656165026677217],
					[64.618302766177536, 7.6656165027996845],
					[64.618302759001196, 7.6656165031515799],
					[64.618302756328944, 7.6656165053736691],
					[64.618155560341677, 7.6656498047931612],
					[64.618111064891579, 7.6656581028824551],
					[64.617949974429692, 7.6656859020241992],
					[64.617888886081914, 7.6656915006485047],
					[64.617722189987532, 7.6656997995005627],
					[64.617722189090529, 7.6656997995445435],
					[64.617669395617114, 7.6657025992660577],
					[64.617491707073114, 7.6656998005510522],
					[64.617438916867826, 7.6656970004125231],
					[64.617255623582125, 7.6656748004613382],
					[64.617255613714661, 7.6656748009451512],
					[64.617255594857895, 7.66567479977875],
					[64.617255585887506, 7.6656748002185786],
					[64.617216715268569, 7.6656775989355088],
					[64.61700564394647, 7.6656304047664001],
					[64.617005625070817, 7.6656304015100112],
					[64.617005587376198, 7.6656304012673129],
					[64.617005567641286, 7.6656304022349619],
					[64.616988920232941, 7.6656330940823754],
					[64.616905677616117, 7.6656026164300375],
					[64.6167917876069, 7.6655470198730731],
					[64.616791756116044, 7.6655470109625199],
					[64.616791689640579, 7.6655470037674354],
					[64.616791656450019, 7.6655470053949317],
					[64.616766665774378, 7.6655526029612844],
					[64.61674441477517, 7.6655553960526275],
					[64.616538955362628, 7.6654970075435562],
					[64.616538929310664, 7.665497004639211],
					[64.61653887724448, 7.6654970030105032],
					[64.616538852146192, 7.6654970063321439],
					[64.616494397167472, 7.6655080956301562],
					[64.616316753206419, 7.6654692070332491],
					[64.616233363354453, 7.6654415103606901],
					[64.616233361560376, 7.6654415104486677],
					[64.616233359747412, 7.6654415084466772],
					[64.616233358850394, 7.6654415084906642],
					[64.616088962750453, 7.6653970092036348],
					[64.615958368199998, 7.6653498116713807],
					[64.61595836730298, 7.6653498117153713],
					[64.615958364611856, 7.6653498118473449],
					[64.615958362798878, 7.6653498098453818],
					[64.615866770141949, 7.6653192125914478],
					[64.615683375724345, 7.6652442146644058],
					[64.615683365819123, 7.6652442109684706],
					[64.615683346924584, 7.66524420562257],
					[64.615683337038263, 7.6652442040165791],
					[64.615638961786274, 7.665235907256827],
					[64.615463985768642, 7.6651526193304313],
					[64.615463983955678, 7.6651526173284967],
					[64.615463978554558, 7.6651526155025671],
					[64.615463975863406, 7.6651526156345584],
					[64.615416775634841, 7.6651331148632371],
					[64.615416757637334, 7.6651331094734463],
					[64.615416718989025, 7.6651331030057088],
					[64.615416700113371, 7.6651330997498217],
					[64.615411128805121, 7.6651330991068276],
					[64.615188971808237, 7.6650665136449332],
					[64.615136185611163, 7.6650415186497218],
					[64.615136176621874, 7.6650415169998345],
					[64.615136160399587, 7.6650415094322755],
					[64.615136151410269, 7.6650415077823917],
					[64.614966759668192, 7.6649970090144546],
					[64.614875068017625, 7.6649637113008016],
					[64.614875061719445, 7.6649637095189496],
					[64.614875050020132, 7.6649637059112461],
					[64.614875043721938, 7.6649637041293959],
					[64.614738952327684, 7.664933107274325],
					[64.614608360616089, 7.6648915089998892],
					[64.614608353420891, 7.6648915072620749],
					[64.61460833813338, 7.664891503830451],
					[64.614608330041094, 7.6648915021366228],
					[64.614516741903486, 7.6648776045228617],
					[64.614358353602995, 7.664833107374891],
					[64.614358344613663, 7.6648331057251182],
					[64.614358324840978, 7.6648331025135841],
					[64.614358315851689, 7.6648331008638095],
					[64.614288927294737, 7.6648276023557553],
					[64.614105639154062, 7.6647915048593021],
					[64.614105632855882, 7.6647915030775389],
					[64.614105620278423, 7.6647915016038164],
					[64.614105613999115, 7.6647915019118598],
					[64.614066716100965, 7.6647887017854197],
					[64.613869417453159, 7.6647720019703272],
					[64.613869412949072, 7.664772000100573],
					[64.613869403978654, 7.6647720005406326],
					[64.61386940039047, 7.6647720007166589],
					[64.613844404897534, 7.6647719996680621],
					[64.613638911183372, 7.6647608995943664],
					[64.613638902212969, 7.6647609000344268],
					[64.613638884272135, 7.6647609009145468],
					[64.613638875301703, 7.6647609013546081],
					[64.613616678206895, 7.6647637004624709],
					[64.613419380086611, 7.6647831008421443],
					[64.613419373807304, 7.6647831011501797],
					[64.613419362164663, 7.6647831038119918],
					[64.613419355885355, 7.6647831041200272],
					[64.613394369712637, 7.6647887014396963],
					[64.61319718268787, 7.6648054011451565],
					[64.61319717820264, 7.664805401365177],
					[64.613197169232251, 7.6648054018052125],
					[64.613197164747035, 7.6648054020252303],
					[64.613166664739381, 7.6648109038857974],
					[64.613166663842364, 7.6648109039297987],
					[64.613136065990858, 7.6648165025662935],
					[64.612983270446421, 7.6648415026970671],
					[64.612944372179186, 7.6648470024758923],
					[64.612944364105815, 7.664847002871908],
					[64.61294434799683, 7.6648470078433908],
					[64.612944339923445, 7.6648470082394073],
					[64.612899949845882, 7.664860905800075],
					[64.612777759648978, 7.6648859032310774],
					[64.61277775875196, 7.6648859032750778],
					[64.612724958614777, 7.6648970040805757],
					[64.612724952354355, 7.6648970064782711],
					[64.612724938917637, 7.664897009227964],
					[64.612724931760198, 7.6648970116696606],
					[64.612655537031912, 7.664922009500958],
					[64.61257214314702, 7.6649470081057443],
					[64.612572142249988, 7.6649470081497428],
					[64.612572139558864, 7.6649470082817359],
					[64.612572138680719, 7.6649470104154389],
					[64.612494339442677, 7.6649720104266033],
					[64.612494337648585, 7.6649720105145969],
					[64.612494336751567, 7.6649720105585919],
					[64.612402782675701, 7.6650025958632897],
					[64.612366731340131, 7.6649970030006269],
					[64.612366728630107, 7.6649970010429431],
					[64.612366725938983, 7.66499700117493],
					[64.612366724144891, 7.6649970012629227],
					[64.612275066193774, 7.6649859070832935],
					[64.61208340189286, 7.6648720279368092],
					[64.612083348816256, 7.6648720138155566],
					[64.612083243843486, 7.6648720168738009],
					[64.612083191928392, 7.6648720319636707],
					[64.612044451048561, 7.6648969305996415],
					[64.61202236177536, 7.6648665822553514],
					[64.612022346355673, 7.6648665641965001],
					[64.612022312003631, 7.6648665366133972],
					[64.612022293033547, 7.664866522909854],
					[64.611900097124064, 7.6648026247443157],
					[64.611839001600572, 7.6647665280831605],
					[64.611838977267098, 7.6647665167334207],
					[64.611838926003486, 7.6647665046141187],
					[64.611838899932593, 7.6647664996212743],
					[64.611825000389359, 7.6647664999077074],
					[64.611824984242588, 7.6647665006997485],
					[64.611824951089829, 7.6647665065070898],
					[64.611824934980845, 7.6647665114784065],
					[64.611466634719434, 7.6648887116146796],
					[64.611466605249262, 7.6648887276938487],
					[64.611466550888579, 7.6648887700801849],
					[64.611466527811032, 7.6648887983889802],
					[64.610977628315169, 7.6657109987291729],
					[64.610977621270933, 7.6657110137079023],
					[64.610977608098437, 7.6657110457109745],
					[64.610977603745354, 7.6657110605577961],
					[64.610672004127309, 7.6672638602389949],
					[64.610672002427492, 7.6672638707745628],
					[64.61067199992489, 7.6672638918017828],
					[64.610672000019136, 7.6672639022494957],
				],
			],
		},
		{
			fldNpdidField: "853376",
			wlbNpdidWellbore: "2430",
			fldName: "TUNE",
			fldCurrentActivitySatus: "Producing",
			wlbName: "30/8-1 S",
			fldDiscoveryYear: "1995",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=853376",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=853376",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "190",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=2430",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "22332",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[60.414402599646714, 2.6088943958726571],
					[60.414402600081281, 2.6088944048936789],
					[60.414402600342008, 2.6088944103063101],
					[60.41440810016865, 2.6090056099794832],
					[60.414408100255571, 2.6090056117836871],
					[60.414408101321641, 2.6090056152163594],
					[60.414408100516297, 2.6090056171963183],
					[60.414422000061691, 2.6091611091042295],
					[60.414422000255932, 2.6092139002224366],
					[60.414422000864285, 2.6092139128518994],
					[60.414422003865482, 2.6092139377593599],
					[60.414422007237491, 2.609213951665843],
					[60.414435906768951, 2.6092667518016972],
					[60.414435907835021, 2.6092667552343816],
					[60.414435911033195, 2.6092667655324484],
					[60.414435912099258, 2.6092667689651456],
					[60.414494212108906, 2.6094250672645725],
					[60.414538710591025, 2.6095500674022918],
					[60.414580407577205, 2.60966945438145],
					[60.414610903704315, 2.6098028406999436],
					[60.414630402224034, 2.6099083300793264],
					[60.414647001147316, 2.6100500232549471],
					[60.414647002387156, 2.6100500302961369],
					[60.414647004779923, 2.6100500425742865],
					[60.414647006019756, 2.6100500496154799],
					[60.414663705722475, 2.6101167494877395],
					[60.41466370678851, 2.6101167529204754],
					[60.414663707941436, 2.6101167581574534],
					[60.414663709007478, 2.6101167615901879],
					[60.414683107276204, 2.6101778522072836],
					[60.41471920496744, 2.6103361432007635],
					[60.414719207186408, 2.6103361518705039],
					[60.414719212429695, 2.6103361672300376],
					[60.414719213669514, 2.6103361742712563],
					[60.414774812366616, 2.6104750675416732],
					[60.414810908946478, 2.6105889605275356],
					[60.41481090992562, 2.6105889621560729],
					[60.414810910904762, 2.6105889637846067],
					[60.414810910991655, 2.6105889655888386],
					[60.41486371132131, 2.6107417648047977],
					[60.41486371238733, 2.6107417682375758],
					[60.414863714606277, 2.6107417769073846],
					[60.41486371656454, 2.6107417801644832],
					[60.414891513581097, 2.6108056745274815],
					[60.414919212116374, 2.6108833669114468],
					[60.414919213269286, 2.6108833721484923],
					[60.41491921727274, 2.6108833804669382],
					[60.414919219230995, 2.6108833837240386],
					[60.41497481325149, 2.6110028712605335],
					[60.414999808988917, 2.6110833583058746],
					[60.414999810141822, 2.6110833635429249],
					[60.414999813166098, 2.6110833702328637],
					[60.414999814232118, 2.6110833736656658],
					[60.415060909667595, 2.6112389634478443],
					[60.4150998083215, 2.6113750552655719],
					[60.415099809474391, 2.6113750605026489],
					[60.415099811693281, 2.6113750691725666],
					[60.415099813738415, 2.6113750742339801],
					[60.415147012770944, 2.6114944712360555],
					[60.415205411145969, 2.611650065238627],
					[60.415224808912278, 2.6117111614225972],
					[60.415224811849662, 2.6117111663083801],
					[60.415224816026786, 2.6117111782355318],
					[60.415224819051062, 2.6117111849255714],
					[60.415263716918467, 2.6117944802246016],
					[60.415310914584119, 2.6119083762816446],
					[60.415310917608366, 2.6119083829717238],
					[60.415310923483119, 2.6119083927433322],
					[60.415310924635996, 2.6119083979804767],
					[60.415352618036628, 2.6119833842841009],
					[60.415405412932358, 2.6121250696238669],
					[60.415405416043455, 2.6121250781182601],
					[60.415405424050178, 2.6121250947557164],
					[60.41540542903266, 2.6121251047030527],
					[60.41546092675717, 2.6122168006326349],
					[60.415535917554173, 2.6123500845864029],
					[60.415569213273692, 2.6124389703174353],
					[60.415569215318783, 2.6124389753789892],
					[60.415569221367249, 2.6124389887593309],
					[60.415569223412341, 2.6124389938208887],
					[60.415641518047188, 2.6125750825540832],
					[60.415674813150673, 2.6126611712442065],
					[60.415674817240856, 2.6126611813673577],
					[60.415674825160664, 2.6126611962007953],
					[60.415674830056247, 2.6126612043440103],
					[60.415760924071535, 2.612800096312847],
					[60.415794220393309, 2.6128694867154967],
					[60.415794222438393, 2.6128694917770967],
					[60.415794228313096, 2.6128695015490218],
					[60.415794231337294, 2.6128695082392883],
					[60.415858128994493, 2.612969503207399],
					[60.415894225764681, 2.6130333976002813],
					[60.415919226198547, 2.6130778982463254],
					[60.415919228156774, 2.6130779015036523],
					[60.415919234031449, 2.6130779112756541],
					[60.415919236881926, 2.6130779143573597],
					[60.416005432777212, 2.6132001096914022],
					[60.41605812900702, 2.6132862040818061],
					[60.416058131857518, 2.6132862071635219],
					[60.416058136753051, 2.6132862153069287],
					[60.416058139603528, 2.6132862183886667],
					[60.416130431663106, 2.6133834096058428],
					[60.416191526335503, 2.613491797650684],
					[60.416191527314602, 2.6134917992793745],
					[60.416191528380573, 2.6134918027123977],
					[60.416191529359686, 2.6134918043410873],
					[60.416244229244271, 2.613577904401359],
					[60.41624423022337, 2.6135779060300548],
					[60.416244233073833, 2.6135779091118208],
					[60.416244234052961, 2.6135779107405277],
					[60.416327627677617, 2.613702902267713],
					[60.416363720964348, 2.6137722906138752],
					[60.416435919983897, 2.613919487596855],
					[60.416435921942103, 2.6139194908542849],
					[60.416435924966244, 2.6139194975447824],
					[60.416435925945351, 2.6139194991734982],
					[60.416480422905629, 2.6139972937001485],
					[60.416552615193737, 2.6141472761467601],
					[60.416580411753053, 2.6142250668373372],
					[60.416580412905851, 2.6142250720747562],
					[60.416580416909063, 2.6142250803940468],
					[60.416580418954119, 2.614225085455868],
					[60.416652618638942, 2.6143778851409527],
					[60.41665261872577, 2.6143778869453014],
					[60.416652619618027, 2.6143778867696925],
					[60.416697017600427, 2.6144694829250543],
					[60.416758116379498, 2.6146111791015363],
					[60.416758117358597, 2.6146111807302894],
					[60.416758119403617, 2.6146111857921479],
					[60.416758120382724, 2.6146111874209002],
					[60.41680541976347, 2.6147083878722408],
					[60.416869219114282, 2.6148417849506953],
					[60.416916518070146, 2.6149444832787667],
					[60.416916519049224, 2.6149444849075385],
					[60.416916520028309, 2.6149444865363258],
					[60.416916520115151, 2.6149444883406878],
					[60.416977620125174, 2.6150694887167636],
					[60.416977621017438, 2.6150694885411716],
					[60.416977621996523, 2.615069490169966],
					[60.416977621996523, 2.615069490169966],
					[60.417030421792646, 2.6151722905695109],
					[60.417094221862563, 2.615297290478019],
					[60.417094222928469, 2.6152972939112034],
					[60.417094224799804, 2.6152972953644067],
					[60.417094224886633, 2.6152972971687825],
					[60.417147023949994, 2.6153917943431044],
					[60.417213723288732, 2.6155194922866052],
					[60.417213724267853, 2.6155194939154032],
					[60.417213726225988, 2.6155194971730298],
					[60.417213727291895, 2.6155195006062151],
					[60.417266526686724, 2.6156112006073751],
					[60.417266527578974, 2.6156112004317964],
					[60.417266527665809, 2.6156112022361806],
					[60.417338727624873, 2.6157334015776965],
					[60.417338729583065, 2.6157334048353222],
					[60.41733873537072, 2.6157334128038379],
					[60.417338737328869, 2.6157334160614854],
					[60.417366530047708, 2.6157723055943571],
					[60.417391522779944, 2.6158194947414359],
					[60.417391525630357, 2.6158194978235199],
					[60.417391530612569, 2.6158195077720316],
					[60.417391534355282, 2.6158195106785196],
					[60.417463733015005, 2.6159279097734682],
					[60.417510932615492, 2.6160001082827593],
					[60.417510933681399, 2.6160001117159877],
					[60.417510934660491, 2.616000113344819],
					[60.417510935639569, 2.6160001149736511],
					[60.417583135860312, 2.61610291526137],
					[60.417583136839397, 2.6161029168902021],
					[60.417583137731661, 2.6161029167146364],
					[60.417655436321539, 2.616202914838917],
					[60.417702635211199, 2.6162723128143823],
					[60.417702638148427, 2.6162723177009313],
					[60.417702645894224, 2.6162723289273426],
					[60.417702649723701, 2.6162723336383276],
					[60.417769250018203, 2.6163473333404146],
					[60.417769250910474, 2.6163473331648497],
					[60.417816546210545, 2.6164001278067861],
					[60.417855439972193, 2.6164501202685502],
					[60.417933139006706, 2.6165557186275912],
					[60.41793314372844, 2.6165557231630805],
					[60.417933154237808, 2.6165557356673923],
					[60.41793315904637, 2.6165557420073222],
					[60.418013758634224, 2.6166362416337314],
					[60.418013765227286, 2.616636247622564],
					[60.41801377832661, 2.6166362577958298],
					[60.418013784919687, 2.6166362637846614],
					[60.418041565314915, 2.6166557508181079],
					[60.418060949210421, 2.6166779318333213],
					[60.418060952953084, 2.6166779347399713],
					[60.418060959632967, 2.6166779425332694],
					[60.41806096337563, 2.6166779454399314],
					[60.418152663368481, 2.6167640468215434],
					[60.41815266613208, 2.6167640480993217],
					[60.418152671832878, 2.6167640542637742],
					[60.418152675575556, 2.616764057170438],
					[60.418233174353468, 2.616827954388119],
					[60.418266572392284, 2.6168557537358321],
					[60.418266573371362, 2.6168557553647265],
					[60.418266574263619, 2.6168557551891731],
					[60.418310961700733, 2.6168918462045787],
					[60.418372052628456, 2.6169584349344572],
					[60.418372062071917, 2.6169584440056886],
					[60.418372082743346, 2.6169584617970507],
					[60.418372093971328, 2.6169584705171824],
					[60.418438792326981, 2.6170001687800104],
					[60.418502691218016, 2.6170418660599664],
					[60.418583173116204, 2.6170945562900116],
					[60.418597058895962, 2.6171084404856519],
					[60.418597094277601, 2.6171084644908249],
					[60.418597172203228, 2.6171084928765089],
					[60.418597213941737, 2.6171084992370566],
					[60.418636111790306, 2.6171058009027703],
					[60.418697205336926, 2.617102999911479],
					[60.418727791592808, 2.6171030006134273],
					[60.418761055036562, 2.6171057971062694],
					[60.418833159786551, 2.6171334611042787],
					[60.418894225032943, 2.6172444952024518],
					[60.418894225119743, 2.6172444970069333],
					[60.418894225206536, 2.6172444988114183],
					[60.41893312270814, 2.6173139947724438],
					[60.418941479695334, 2.6173306081677907],
					[60.418922014710134, 2.6173777235588678],
					[60.418922017810694, 2.6173778067408437],
					[60.41892208738674, 2.6173779424208581],
					[60.418922151990913, 2.6173779934654751],
					[60.419858251949172, 2.6176084931578165],
					[60.419858300652187, 2.6176084945057325],
					[60.419858394079434, 2.6176084706600835],
					[60.419858437019172, 2.617608445817615],
					[60.420733436607236, 2.6167862464315932],
					[60.420733449383498, 2.6167862311659182],
					[60.420733469669258, 2.6167862034925649],
					[60.420733479768728, 2.6167861887536064],
					[60.422005779896608, 2.6141861874107222],
					[60.423199988749602, 2.6117448765538049],
					[60.425369216541426, 2.6167333797205878],
					[60.425369221436796, 2.6167333878667751],
					[60.425369230335299, 2.6167334043347465],
					[60.425369235230669, 2.6167334124809312],
					[60.426249834721354, 2.6180168122124905],
					[60.426249859395782, 2.6180168383307056],
					[60.4262499153619, 2.6180168783331483],
					[60.426249945761363, 2.618016892392951],
					[60.426808190696789, 2.6181723771853496],
					[60.427691553274137, 2.6191334355053653],
					[60.427691559953914, 2.6191334433013642],
					[60.427691575903495, 2.6191334565617175],
					[60.427691584281, 2.6191334622016123],
					[60.429699883836506, 2.6205668638514785],
					[60.429699891234932, 2.6205668678625189],
					[60.429699904247265, 2.6205668762356455],
					[60.429699911558956, 2.6205668784415717],
					[60.431622083953265, 2.6215140667040284],
					[60.433308162114542, 2.6231140442632634],
					[60.43330818546216, 2.6231140615397193],
					[60.433308231636943, 2.6231140852608066],
					[60.433308258293422, 2.6231140964194042],
					[60.436180558297572, 2.6237279961458677],
					[60.436180578187759, 2.6237279977014549],
					[60.436180616902348, 2.6237279973771859],
					[60.436180636705778, 2.6237279971272947],
					[60.43890821859376, 2.6232113190140867],
					[60.440188731912841, 2.6248140105375528],
					[60.441399820796946, 2.6270667895113169],
					[60.442497018077859, 2.6294444840585038],
					[60.442497021993617, 2.6294444905803362],
					[60.442497028932834, 2.6294445037993679],
					[60.44249703186965, 2.6294445086907481],
					[60.443202631973612, 2.6305307085312828],
					[60.443202634910385, 2.6305307134228664],
					[60.443202640697294, 2.6305307214001408],
					[60.443202643547437, 2.6305307244858236],
					[60.444466544309307, 2.6321084245469715],
					[60.444466548138315, 2.6321084292635621],
					[60.444466554904068, 2.6321084388720459],
					[60.444466558646432, 2.6321084417826714],
					[60.445255459221904, 2.6328890420374305],
					[60.445255481676149, 2.6328890595021268],
					[60.445255530699562, 2.6328890863310104],
					[60.445255557268773, 2.63288909569517],
					[60.445686056742986, 2.6329834943580659],
					[60.44568612303204, 2.632983486803528],
					[60.445686235685713, 2.6329834318559207],
					[60.445686281963738, 2.6329833826568243],
					[60.445725180114259, 2.6328972874693193],
					[60.445794573170623, 2.6327612013011916],
					[60.445847356260884, 2.6326834251341467],
					[60.445919536608137, 2.6326084460801513],
					[60.445991828960004, 2.6325473528023529],
					[60.445991838428661, 2.6325473436496818],
					[60.445991854689154, 2.6325473258702563],
					[60.445991863178932, 2.632547315086843],
					[60.446025139728214, 2.6325001479864385],
					[60.446091806187098, 2.6324584705517489],
					[60.44609180788504, 2.6324584683950594],
					[60.446091811367559, 2.6324584658877246],
					[60.446091813152144, 2.6324584655370757],
					[60.446136206705106, 2.6324279698243531],
					[60.446169497233143, 2.6324084756769031],
					[60.446244485305989, 2.6323695800300424],
					[60.446316777845183, 2.6323390840805634],
					[60.446316782306624, 2.6323390832039228],
					[60.446316789271656, 2.6323390781891893],
					[60.446316792840811, 2.6323390774878739],
					[60.446369479847256, 2.632311283403852],
					[60.446441763344197, 2.6322862892172094],
					[60.446494460079407, 2.6322695901628448],
					[60.446494461058329, 2.6322695917935941],
					[60.446563957581709, 2.6322473910850803],
					[60.446602845188366, 2.6322362949747546],
					[60.446663935717972, 2.6322251968290571],
					[60.446663938394828, 2.6322251963030601],
					[60.446663942856269, 2.632225195426388],
					[60.446663944640825, 2.6322251950757254],
					[60.446700032964308, 2.6322168986632981],
					[60.446727820187917, 2.6322140983782338],
					[60.446727822951416, 2.632214099658305],
					[60.446727828305114, 2.6322140986063025],
					[60.446727830089671, 2.6322140982556359],
					[60.446800029662064, 2.6322029975068522],
					[60.446800030554364, 2.6322029973315146],
					[60.446800032338921, 2.6322029969808467],
					[60.446800033231199, 2.6322029968055123],
					[60.44683332009852, 2.6321973984372531],
					[60.44692220330716, 2.6321945996433249],
					[60.44701389224663, 2.6321946003770691],
					[60.447047182818075, 2.6321973985181559],
					[60.44704718371036, 2.6321973983428193],
					[60.447047184602631, 2.6321973981674831],
					[60.447119385379196, 2.6322029986418363],
					[60.44711938725041, 2.632203000097264],
					[60.447119388142703, 2.6322029999219221],
					[60.447166682764262, 2.6322057991454968],
					[60.447238860687179, 2.632214096899431],
					[60.447258245046896, 2.632219591234735],
					[60.447258253250716, 2.6322195932689065],
					[60.447258269658327, 2.6322195973372766],
					[60.44725827786214, 2.6322195993714534],
					[60.447283268584634, 2.6322223986184072],
					[60.4473527607976, 2.6322362961487111],
					[60.447352764453356, 2.632236297253475],
					[60.447352769001412, 2.6322362981829128],
					[60.447352771678268, 2.6322362976568998],
					[60.447411069984639, 2.6322445973143505],
					[60.447480566139077, 2.6322557977228707],
					[60.447524960390993, 2.6322640967519559],
					[60.447588856600191, 2.6322779947256745],
					[60.447588861148255, 2.6322779956551039],
					[60.447588866588568, 2.6322779964092016],
					[60.447588870244346, 2.6322779975139721],
					[60.447644370145845, 2.6322862984120481],
					[60.447716669839437, 2.6322973983682765],
					[60.447716670731715, 2.632297398192939],
					[60.447716674300857, 2.632297397491584],
					[60.447716675193156, 2.632297397316242],
					[60.447761075171563, 2.6323029988614333],
					[60.447761076956134, 2.6323029985107564],
					[60.447761079719626, 2.6323029997908716],
					[60.447761082396475, 2.6323029992648541],
					[60.447822181765893, 2.6323084988896666],
					[60.447822183550464, 2.632308498538988],
					[60.447822188098492, 2.6323084994684502],
					[60.447822188990777, 2.6323084992931105],
					[60.447874988712385, 2.6323112998413349],
					[60.447874989604671, 2.6323112996659943],
					[60.447930589600887, 2.6323141005426778],
					[60.447930594062314, 2.6323140996659737],
					[60.447930603071789, 2.6323140997187182],
					[60.447930607619831, 2.6323141006481823],
					[60.448002808088269, 2.6323113002821525],
					[60.448002809872861, 2.6323112999314668],
					[60.448002810765132, 2.6323112997561284],
					[60.44805561117456, 2.6323084993683232],
					[60.448055613045753, 2.6323085008237928],
					[60.448055618399444, 2.6323084997717499],
					[60.448055621076328, 2.6323084992457209],
					[60.448108320797139, 2.632302998443607],
					[60.448108322668332, 2.6323029998990775],
					[60.448108327043137, 2.6323029972162151],
					[60.44810832802203, 2.632302998847031],
					[60.448166727882587, 2.6322945984640769],
					[60.44816672966715, 2.6322945981133938],
					[60.448166732344013, 2.6322945975873635],
					[60.448166733236313, 2.6322945974120189],
					[60.448233333234185, 2.6322834971394737],
					[60.448233334126471, 2.6322834969641331],
					[60.448233335018777, 2.6322834967887849],
					[60.448280635056555, 2.6322751972982115],
					[60.448280636841112, 2.6322751969475253],
					[60.448280642194824, 2.632275195895462],
					[60.448280643979409, 2.6322751955447741],
					[60.448341741619984, 2.6322612966982417],
					[60.448397238726244, 2.6322501957638833],
					[60.4483972414031, 2.6322501952378463],
					[60.448397244972242, 2.6322501945364656],
					[60.448397246756798, 2.6322501941857772],
					[60.448466740650751, 2.6322334966340981],
					[60.448513932569057, 2.6322251963572465],
					[60.448586124181858, 2.6322140981578142],
					[60.448619414715679, 2.632211299106554],
					[60.448702806568384, 2.6322057995053725],
					[60.448719400104764, 2.632205800335381],
					[60.448744385368258, 2.6322058002389475],
					[60.448819355379676, 2.6322168950949671],
					[60.448863827819963, 2.6322307871698976],
					[60.448936007340919, 2.63226407766822],
					[60.44899708666324, 2.6323001659208121],
					[60.449060964158605, 2.6323501462586525],
					[60.449122051830692, 2.6324168343790011],
					[60.449188739845937, 2.632491821017175],
					[60.44926921915242, 2.632619488037228],
					[60.449294211963412, 2.6326889688750623],
					[60.44929421392122, 2.6326889721368292],
					[60.449294217031152, 2.6326889806419436],
					[60.449294219075604, 2.6326889857099474],
					[60.449366518031027, 2.6328417828151776],
					[60.449405415166588, 2.6329306752204937],
					[60.449466511565511, 2.6330889692100521],
					[60.4495109100191, 2.6332167634219426],
					[60.449549807635343, 2.633338956562278],
					[60.449585905596756, 2.6334750463451879],
					[60.449602604920798, 2.633550043342296],
					[60.449602607051823, 2.6335500502166278],
					[60.449602612379316, 2.6335500674025059],
					[60.449602613618048, 2.6335500744521743],
					[60.449630410832611, 2.6336194650981235],
					[60.449677607625446, 2.6337833548104599],
					[60.44967760771204, 2.6337833566167088],
					[60.44967760771204, 2.6337833566167088],
					[60.449730406867467, 2.6339611534075691],
					[60.449741503568809, 2.6340056416547744],
					[60.449749802681758, 2.6340528346703693],
					[60.44974980392044, 2.6340528417200937],
					[60.44974980720351, 2.6340528538379648],
					[60.449749809247898, 2.6340528589061285],
					[60.44980260801394, 2.6342250572836678],
					[60.449847005886099, 2.6343806501515958],
					[60.449866504695542, 2.634463946528943],
					[60.449866505674414, 2.634463948159909],
					[60.449866506826503, 2.6344639534033951],
					[60.449866507891976, 2.6344639568406407],
					[60.44989420830958, 2.6345583557939021],
					[60.449927607276415, 2.6346778541724136],
					[60.449927609234173, 2.6346778574343648],
					[60.449927611365098, 2.6346778643088666],
					[60.449927611624886, 2.6346778697276605],
					[60.449966511850285, 2.634783369690803],
					[60.449966512742577, 2.6347833695155178],
					[60.449966512829164, 2.6347833713217854],
					[60.450019209933011, 2.6349222622397255],
					[60.450066507850792, 2.6350861557641476],
					[60.450066509002852, 2.635086161007687],
					[60.450066513091535, 2.6350861711442293],
					[60.45006651513588, 2.6350861762125097],
					[60.450091509426599, 2.635147262105269],
					[60.450110905685328, 2.6352250477882304],
					[60.45011090790284, 2.6352250564690816],
					[60.45011091314354, 2.6352250718492143],
					[60.45011091616675, 2.6352250785485145],
					[60.450163715991813, 2.6353472765544779],
					[60.450199814906405, 2.6354361747169102],
					[60.450255413678391, 2.6355750737897909],
					[60.450255415722715, 2.6355750788581163],
					[60.450255420703641, 2.6355750888195173],
					[60.450255422747972, 2.6355750938878577],
					[60.450310923120561, 2.6356806937774899],
					[60.4503748226889, 2.635802893689136],
					[60.450374823667772, 2.6358028953201664],
					[60.450427624478912, 2.6359000956395753],
					[60.450427625457785, 2.6359000972706124],
					[60.450427627415515, 2.6359001005327034],
					[60.45042762839438, 2.6359001021637436],
					[60.450499827635767, 2.6360223027916154],
					[60.450499831464654, 2.6360223075095104],
					[60.450499838230144, 2.6360223171205415],
					[60.450499841166732, 2.6360223220136754],
					[60.450535938557749, 2.6360695174807103],
					[60.450569236040934, 2.6361168144421225],
					[60.450633135755055, 2.6362084135407282],
					[60.450633137799365, 2.6362084186091654],
					[60.450633142607103, 2.636208424958161],
					[60.450633146262859, 2.6362084260634586],
					[60.450685945912731, 2.6362723268869166],
					[60.450685947870447, 2.6362723301490409],
					[60.450685952678185, 2.6362723364980538],
					[60.450685956420507, 2.6362723394096741],
					[60.45076095489258, 2.6363501374227232],
					[60.450833153044705, 2.636427935123308],
					[60.450869252681535, 2.6364668367226494],
					[60.45086925535842, 2.6364668361969077],
					[60.450869257316143, 2.6364668394590582],
					[60.450869259273858, 2.6364668427212137],
					[60.450905456835443, 2.6365029391213399],
					[60.45097765587083, 2.636577938486087],
					[60.451049853889806, 2.6366529365462101],
					[60.451083153087858, 2.6366890355045101],
					[60.451083158787867, 2.6366890416784154],
					[60.451083171972492, 2.6366890536757301],
					[60.4510831794571, 2.6366890594991492],
					[60.451130469624971, 2.6367251512549856],
					[60.451202658191491, 2.6367945413830176],
					[60.451263755323382, 2.6368584374151487],
					[60.451263760131091, 2.6368584437643579],
					[60.451263770465694, 2.6368584526748227],
					[60.451263775100294, 2.6368584554113386],
					[60.451319275373891, 2.6369029563506139],
					[60.45131927626619, 2.6369029561753741],
					[60.451319279116184, 2.6369029592623621],
					[60.451319280008491, 2.6369029590871209],
					[60.451374866859688, 2.636944549501389],
					[60.451427655070425, 2.6370001387733035],
					[60.451427656941569, 2.6370001402291861],
					[60.451427660683876, 2.6370001431409511],
					[60.45142766166272, 2.6370001447720717],
					[60.451480462131748, 2.6370501450275374],
					[60.451480464895184, 2.6370501463082077],
					[60.451480469702894, 2.637050152657471],
					[60.451480471574051, 2.637050154113374],
					[60.451544267373869, 2.6371029492716298],
					[60.451605461599641, 2.6371612442770127],
					[60.451605465341956, 2.6371612471888062],
					[60.451605470063086, 2.6371612517317629],
					[60.451605472913087, 2.6371612548187957],
					[60.451652673139229, 2.6372001533815701],
					[60.451652675096938, 2.6372001566438446],
					[60.451652677946946, 2.6372001597308836],
					[60.451652679731538, 2.6372001593804177],
					[60.451697069586245, 2.6372334524274414],
					[60.451769260896832, 2.6373029445380474],
					[60.451769263746812, 2.6373029476251086],
					[60.451769271231406, 2.6373029534487831],
					[60.451769273994842, 2.6373029547294715],
					[60.451858160962097, 2.6373751454784662],
					[60.451877650413941, 2.6373973323435584],
					[60.451877660835052, 2.6373973430607407],
					[60.451877684267636, 2.6373973621630484],
					[60.45187769727908, 2.6373973705481619],
					[60.451905479036704, 2.6374140615972808],
					[60.451983163763536, 2.6374862464992703],
					[60.451983164742387, 2.6374862481304304],
					[60.451983168398101, 2.6374862492359141],
					[60.451983169376973, 2.6374862508670893],
					[60.452063768812756, 2.6375557504041751],
					[60.45206377264158, 2.6375557551224733],
					[60.452063781018452, 2.63755576077103],
					[60.452063784760718, 2.6375557636829194],
					[60.452110973841229, 2.6375890567354334],
					[60.452147063572184, 2.6376223469101014],
					[60.452147067314478, 2.6376223498220153],
					[60.452147072035601, 2.637622354365087],
					[60.452147074799036, 2.6376223556458127],
					[60.452216571761767, 2.6376779536624175],
					[60.452274865895312, 2.6377279494843164],
					[60.452327662210472, 2.6377779456367714],
					[60.452327665952765, 2.637777948548703],
					[60.452327674329609, 2.637777954197364],
					[60.452327678158447, 2.6377779589157226],
					[60.452399868458485, 2.6378334516831372],
					[60.452433158153887, 2.6378668418592768],
					[60.452433164746161, 2.637866847858398],
					[60.452433177844128, 2.6378668580502627],
					[60.452433185328701, 2.6378668638741645],
					[60.452480479866509, 2.6379001607126096],
					[60.452560969698744, 2.6379640512170117],
					[60.452641563700901, 2.6380390462890659],
					[60.45264157029316, 2.638039052288276],
					[60.452641581606549, 2.6380390628306865],
					[60.452641589004543, 2.6380390668482532],
					[60.452666573003604, 2.638055755677311],
					[60.452685958716401, 2.6380751411651531],
					[60.452685964329824, 2.6380751455331621],
					[60.452685973772041, 2.6380751546196044],
					[60.452685979385464, 2.638075158987597],
					[60.452777679126342, 2.6381445585408572],
					[60.452849877971012, 2.6382001587204211],
					[60.452849878863312, 2.6382001585452084],
					[60.452849880734469, 2.6382001600012308],
					[60.452849880821006, 2.6382001618076729],
					[60.452902675562221, 2.6382390569186569],
					[60.452958157936152, 2.6382862425743148],
					[60.452991547827274, 2.6383251299889556],
					[60.452991561990601, 2.6383251436188555],
					[60.452991591036486, 2.6383251670905237],
					[60.45299160681131, 2.6383251767570868],
					[60.453049890583415, 2.6383557680739744],
					[60.453116576301568, 2.6384084565094446],
					[60.453116578172718, 2.6384084579654794],
					[60.453116581022684, 2.6384084610527778],
					[60.45311658289382, 2.6384084625088136],
					[60.453197083105223, 2.6384668611143951],
					[60.453197086934026, 2.6384668658329389],
					[60.453197096203155, 2.6384668713067088],
					[60.45319710083772, 2.6384668740435839],
					[60.453235990325048, 2.6384890677514758],
					[60.453269280116089, 2.6385140605122697],
					[60.453269284750661, 2.6385140632491546],
					[60.453269294106342, 2.6385140705294101],
					[60.453269298740892, 2.6385140732663177],
					[60.45333599927902, 2.6385529722266092],
					[60.453336000257856, 2.6385529738578697],
					[60.45333600115017, 2.6385529736826592],
					[60.453336002042462, 2.6385529735074513],
					[60.45338050213406, 2.6385779734174029],
					[60.453449902050103, 2.6386168732204061],
					[60.453449905792361, 2.6386168761325299],
					[60.453449913190369, 2.6386168801503156],
					[60.45344991595379, 2.6386168814311732],
					[60.453522110751138, 2.6386501797558877],
					[60.453574906845347, 2.6386779765512403],
					[60.45357491237219, 2.6386779791129902],
					[60.45357492074902, 2.6386779847620638],
					[60.453574926189347, 2.6386779855173264],
					[60.453658207224144, 2.6387112772091315],
					[60.453674888743286, 2.6387223671302431],
					[60.453674898904687, 2.6387223724289544],
					[60.45367491824868, 2.6387223813950715],
					[60.453674928410095, 2.6387223866937819],
					[60.453711028291714, 2.6387362866639541],
					[60.453711030076299, 2.6387362863135451],
					[60.453711034710864, 2.6387362890505139],
					[60.453711036582, 2.6387362905065928],
					[60.453786022919942, 2.6387612859002028],
					[60.453819310457341, 2.6387779792355639],
					[60.453819315984205, 2.6387779817973245],
					[60.453819327930205, 2.6387779867456622],
					[60.45381933524164, 2.6387779889570178],
					[60.453891627707691, 2.638802986472903],
					[60.453944322137559, 2.6388251837346623],
					[60.453944324987525, 2.6388251868220531],
					[60.453944332212423, 2.6388251872269217],
					[60.453944334975859, 2.6388251885078109],
					[60.454016631091299, 2.6388501873141124],
					[60.454088827178346, 2.6388779867088954],
					[60.454144318139022, 2.6389001821559281],
					[60.45421660715035, 2.638936276903364],
					[60.454241603206071, 2.6389501748027215],
					[60.454241607840629, 2.6389501775397441],
					[60.454241619786615, 2.6389501824882018],
					[60.454241626292315, 2.6389501866813547],
					[60.454269315264391, 2.6389612800970736],
					[60.454341598705383, 2.6390001734744049],
					[60.454394293320689, 2.63903346924041],
					[60.454394295191811, 2.6390334706965546],
					[60.454394297955254, 2.6390334719774726],
					[60.454394299826376, 2.6390334734335998],
					[60.45446659320681, 2.6390751697351962],
					[60.454538786010211, 2.639125164789927],
					[60.454574886061756, 2.6391501635031878],
					[60.454574888911701, 2.6391501665906683],
					[60.454574896309694, 2.6391501706087017],
					[60.454574900051952, 2.6391501735209792],
					[60.454627679276847, 2.6391807615911151],
					[60.454680461598912, 2.6392307443047875],
					[60.454680466319999, 2.6392307488484468],
					[60.454680474783352, 2.6392307563044017],
					[60.454680479417895, 2.6392307590415003],
					[60.454738779377152, 2.6392751580513334],
					[60.454808170818922, 2.6393279523868554],
					[60.454880464153945, 2.6393945467436288],
					[60.45488046789621, 2.639394549655957],
					[60.454880475380719, 2.6393945554806355],
					[60.45488047823067, 2.6393945585681662],
					[60.454916573566159, 2.6394223560699381],
					[60.454952662092118, 2.6394529450045989],
					[60.455024853082179, 2.6395307365887515],
					[60.45502485682443, 2.6395307395011098],
					[60.455024864395448, 2.6395307471324112],
					[60.455024868137713, 2.6395307500447678],
					[60.455116555751175, 2.639611238752849],
					[60.455141544974815, 2.6396418262832788],
					[60.455141549782425, 2.6396418326336311],
					[60.455141561095687, 2.6396418431773676],
					[60.45514156581676, 2.639641847721133],
					[60.45516926323819, 2.6396668468335855],
					[60.455258153083108, 2.6397529368376946],
					[60.455330446872445, 2.6398390287234048],
					[60.455330450701211, 2.6398390334424255],
					[60.455330460143337, 2.6398390425300819],
					[60.455330463972118, 2.6398390472491005],
					[60.455366564188303, 2.6398723463613218],
					[60.455366566059411, 2.639872347817537],
					[60.455366568909355, 2.6398723509051689],
					[60.455366570780477, 2.639872352361381],
					[60.455402659216027, 2.6399029421964122],
					[60.455474849229404, 2.6399862304144808],
					[60.455474852079334, 2.6399862335021163],
					[60.455474857865759, 2.6399862414839887],
					[60.455474860715697, 2.6399862445716242],
					[60.455555461375397, 2.6400640432623308],
					[60.455555462354205, 2.6400640448937476],
					[60.455555463333042, 2.6400640465251599],
					[60.455555464225334, 2.6400640463499796],
					[60.455594251355862, 2.6401001336951087],
					[60.455619239955809, 2.640133419229179],
					[60.455619247613328, 2.6401334286673563],
					[60.455619264712972, 2.640133447193362],
					[60.45561927406856, 2.6401334544745789],
					[60.455708170385627, 2.6402057518993347],
					[60.455791565745251, 2.6402807490157412],
					[60.455791571358624, 2.6402807533845207],
					[60.455791579821891, 2.6402807608409948],
					[60.455791585348742, 2.6402807634031467],
					[60.455819280013664, 2.6403001601298355],
					[60.455833174868467, 2.6403112551722594],
					[60.455833182352947, 2.6403112609973096],
					[60.455833197235357, 2.6403112708407828],
					[60.455833203827531, 2.6403112768410129],
					[60.455924902253692, 2.6403612730919956],
					[60.455977699696675, 2.640391873429575],
					[60.455977703352382, 2.6403918745355104],
					[60.455977710750339, 2.6403918785539662],
					[60.455977713600262, 2.6403918816416789],
					[60.456030513917518, 2.6404168811940654],
					[60.456030524884639, 2.6404168845118354],
					[60.456030545926616, 2.6404168913225616],
					[60.456030556893758, 2.6404168946403423],
					[60.456105556811437, 2.6404334949237991],
					[60.45610556225175, 2.6404334956793747],
					[60.45610557313239, 2.6404334971905357],
					[60.456105578659191, 2.6404334997527443],
					[60.456158279432891, 2.6404390992724811],
					[60.456158280325177, 2.6404390990973057],
					[60.456158282109783, 2.6404390987469553],
					[60.456158283002097, 2.6404390985717763],
					[60.456222182656852, 2.6404445985290943],
					[60.456222189968273, 2.6404446007409534],
					[60.456222205223852, 2.6404445995695953],
					[60.456222213341064, 2.6404445997996442],
					[60.456263912898656, 2.6404419006140274],
					[60.456263916381324, 2.6404418981066873],
					[60.456263920929324, 2.6404418990374419],
					[60.456263923606237, 2.6404418985119098],
					[60.456311124388129, 2.6404362993287331],
					[60.456311127957335, 2.6404362986280261],
					[60.456311137772616, 2.6404362967010773],
					[60.456311143126399, 2.6404362956500185],
					[60.456375043235568, 2.6404223957214845],
					[60.456375046718236, 2.6404223932141133],
					[60.456375055641217, 2.6404223914623337],
					[60.456375060102708, 2.640422390586445],
					[60.456427859684716, 2.6404057916589441],
					[60.456427860577016, 2.6404057914837633],
					[60.456488960684098, 2.640386291827951],
					[60.456488960597582, 2.640386290021298],
					[60.456488963274467, 2.6403862894957602],
					[60.456555663319762, 2.6403640891914057],
					[60.456555665104375, 2.6403640888410411],
					[60.456555667781252, 2.6403640883155015],
					[60.456555668673538, 2.6403640881403243],
					[60.456608367114569, 2.6403445873821485],
					[60.456688963539257, 2.6403168885251627],
					[60.456713964306935, 2.6403084897046143],
					[60.456713965199235, 2.6403084895294313],
					[60.456713967789604, 2.64030848719722],
					[60.456713968768419, 2.6403084888287021],
					[60.45676675856901, 2.6402890902918026],
					[60.456822249139279, 2.6402751941019957],
					[60.456822257169968, 2.6402751925253303],
					[60.456822273058272, 2.6402751857586635],
					[60.456822281002431, 2.6402751823753161],
					[60.456847265336847, 2.6402640895157394],
					[60.456927845727421, 2.6402445943772559],
					[60.456975046090868, 2.6402334946339119],
					[60.456975046983182, 2.6402334944587218],
					[60.456975048767752, 2.6402334941083523],
					[60.456975049660052, 2.6402334939331649],
					[60.457038941248626, 2.6402168958748176],
					[60.457091730944988, 2.6402084973740458],
					[60.45716392807482, 2.6401973972720536],
					[60.457205625972911, 2.6401918985902908],
					[60.457272216830063, 2.6401834984836738],
					[60.457333309094793, 2.6401807991352126],
					[60.457333311858214, 2.6401808004163518],
					[60.45733331721199, 2.6401807993652051],
					[60.457333319888882, 2.6401807988396304],
					[60.457388915079363, 2.6401751994661335],
					[60.4574444102212, 2.6401724008424399],
					[60.457444412898084, 2.6401724003168643],
					[60.457444418251868, 2.6401723992657091],
					[60.457444420928766, 2.6401723987401344],
					[60.457497209885886, 2.6401668998411636],
					[60.457558299929325, 2.6401668999270336],
					[60.457613900283185, 2.6401669000428862],
					[60.457613902960077, 2.6401668995173058],
					[60.45761390840039, 2.640166900272872],
					[60.457613911077296, 2.6401668997472885],
					[60.457663901186237, 2.640164100468966],
					[60.457722189916105, 2.6401668991749365],
					[60.45777498963804, 2.6401695993085759],
					[60.457774990616862, 2.6401696009401019],
					[60.457836084983668, 2.640172399551874],
					[60.457888879425226, 2.6401779986169385],
					[60.457888880317526, 2.6401779984417439],
					[60.457888882102111, 2.6401779980913536],
					[60.457888883973226, 2.6401779995476913],
					[60.45795557124702, 2.6401834988601576],
					[60.458008258786748, 2.6401945955862582],
					[60.458061050275823, 2.6402057941231392],
					[60.458133240563967, 2.6402279907592745],
					[60.458133243327381, 2.6402279920404284],
					[60.458133246983088, 2.6402279931463877],
					[60.45813324876768, 2.6402279927959968],
					[60.45818602885236, 2.6402418873127074],
					[60.458241610608901, 2.6402695791271986],
					[60.458241613372294, 2.6402695804083636],
					[60.458241617028023, 2.6402695815143411],
					[60.45824161979143, 2.6402695827955052],
					[60.458286002400563, 2.6402890745870793],
					[60.458313782110835, 2.6403084614568222],
					[60.458394271277911, 2.6403695526864972],
					[60.458472064287641, 2.6404418461509702],
					[60.458472067137599, 2.6404418492389112],
					[60.458472072837452, 2.6404418554148159],
					[60.45847207738548, 2.6404418563456109],
					[60.458510954410798, 2.6404723397156631],
					[60.458538737000673, 2.6405112167558475],
					[60.458538738871781, 2.6405112182122306],
					[60.45853874367937, 2.6405112245633475],
					[60.458538745637, 2.6405112278264924],
					[60.458619239518832, 2.6406084203101501],
					[60.458691532928547, 2.6407140094885473],
					[60.458744227809312, 2.6407973013758115],
					[60.458816522059713, 2.6409278914064966],
					[60.458855420349579, 2.6410083865658001],
					[60.45885542043608, 2.6410083883725854],
					[60.458855421414903, 2.6410083900041856],
					[60.458855422393697, 2.6410083916357934],
					[60.458927620339288, 2.6411500863539361],
					[60.458969217352042, 2.6412417827570809],
					[60.4590331126835, 2.6413861716246076],
					[60.459069208768163, 2.6415000605937804],
					[60.459069210725765, 2.6415000638570407],
					[60.459069212942921, 2.6415000725407047],
					[60.459069214900509, 2.641500075803966],
					[60.459124812925808, 2.641636171175973],
					[60.459177612410123, 2.6417833669638657],
					[60.459205409619621, 2.6418611626358879],
					[60.459233108219514, 2.6419556563552864],
					[60.459233108219514, 2.6419556563552864],
					[60.459233109284838, 2.6419556597937577],
					[60.459233109284838, 2.6419556597937577],
					[60.459269208693748, 2.6420694600056382],
					[60.459269209759043, 2.6420694634441051],
					[60.459269211803139, 2.6420694685142463],
					[60.459269212868456, 2.64206947195271],
					[60.459305410049133, 2.6421639629403799],
					[60.45934980719305, 2.6423194536771999],
					[60.459397007432841, 2.6424917535314791],
					[60.459397009563418, 2.6424917604084697],
					[60.459397015782166, 2.6424917774258332],
					[60.459397018805056, 2.6424917841276931],
					[60.459416515710338, 2.6425333789713625],
					[60.459433109419997, 2.6425750622172601],
					[60.4594776061763, 2.6427500491966405],
					[60.4594776061763, 2.6427500491966405],
					[60.459477607241588, 2.6427500526351526],
					[60.459477607241588, 2.6427500526351526],
					[60.459522007254321, 2.6429111507121474],
					[60.459541505817143, 2.6429889481718476],
					[60.459541506882424, 2.6429889516103731],
					[60.459541507947705, 2.6429889550489154],
					[60.459541508034192, 2.642988956855743],
					[60.45956650618308, 2.6430722498497805],
					[60.459594204344775, 2.6432083393929187],
					[60.459594205583002, 2.6432083464451264],
					[60.459594209930579, 2.6432083620061482],
					[60.45959421206112, 2.6432083688832453],
					[60.459641507930179, 2.6433361568771896],
					[60.459666505146501, 2.6434444440694227],
					[60.459666506211775, 2.6434444475079761],
					[60.459666506298248, 2.6434444493148148],
					[60.459666507363515, 2.6434444527533856],
					[60.459697006963637, 2.6435583518231582],
					[60.459724806327948, 2.6436611505392102],
					[60.459749805537996, 2.6437611485857588],
					[60.459749806689722, 2.6437611538311927],
					[60.459749809712548, 2.6437611605332503],
					[60.459749810864302, 2.6437611657786642],
					[60.459797007163722, 2.6438972547878752],
					[60.459830405316005, 2.6440472428449713],
					[60.459830404596637, 2.6440472466337877],
					[60.459830406640663, 2.6440472517041265],
					[60.45983040672715, 2.6440472535109891],
					[60.459849807381737, 2.6441167530613314],
					[60.459849808360509, 2.6441167546930808],
					[60.459849808360509, 2.6441167546930808],
					[60.459849808446997, 2.6441167564999279],
					[60.45986920809878, 2.6441833564474413],
					[60.45986920809878, 2.6441833564474413],
					[60.459869209164012, 2.6441833598860449],
					[60.459869210142806, 2.6441833615177917],
					[60.459922008774349, 2.6443472598626823],
					[60.459974807393046, 2.6445167523924042],
					[60.459988705116523, 2.6445750451580836],
					[60.459988707247014, 2.644575052035353],
					[60.45998871044273, 2.6445750623512541],
					[60.459988711594434, 2.6445750675967563],
					[60.460013710049523, 2.6446444615254951],
					[60.460055408351742, 2.6447861565107385],
					[60.460055409330508, 2.644786158142534],
					[60.460055410395753, 2.6447861615811723],
					[60.460055411374526, 2.6447861632129519],
					[60.460094211454283, 2.6449000647678829],
					[60.460094211540749, 2.6449000665747509],
					[60.460094213584746, 2.6449000716451998],
					[60.460094214649992, 2.6449000750838487],
					[60.460147011981213, 2.6450306689537224],
					[60.460194210003266, 2.6451722634618133],
					[60.460194213026028, 2.6451722701640938],
					[60.460194220050298, 2.645172285200454],
					[60.46019422315949, 2.6451722937096096],
					[60.460247020632629, 2.6452722897638767],
					[60.460310918385247, 2.645411183073541],
					[60.460310920342778, 2.6454111863371788],
					[60.460310924344284, 2.6454111946713166],
					[60.46031092638826, 2.6454111997418157],
					[60.460358125861987, 2.6454944977103452],
					[60.460424825120654, 2.6456139964054946],
					[60.460424827078171, 2.6456139996691581],
					[60.46042483001446, 2.6456140045646217],
					[60.460424830993219, 2.6456140061964635],
					[60.460483130675044, 2.6457057071470085],
					[60.460483134590078, 2.6457057136743343],
					[60.460483142247234, 2.6457057231151961],
					[60.460483146075823, 2.6457057278356348],
					[60.460552645312866, 2.6457890252931429],
					[60.46062484314286, 2.6458807232926156],
					[60.460624848928965, 2.6458807312767747],
					[60.460624863091603, 2.6458807449130064],
					[60.460624869770022, 2.6458807527221038],
					[60.460663744813608, 2.6459140315867287],
					[60.46068042920453, 2.6459418023538932],
					[60.460680437034554, 2.6459418154086731],
					[60.460680456090991, 2.6459418372042083],
					[60.46068046561922, 2.645941848101963],
					[60.460769262041666, 2.6460223456425811],
					[60.460841558888056, 2.6460945426825639],
					[60.460841560759114, 2.6460945441393711],
					[60.460841567264623, 2.6460945483347391],
					[60.460841570114425, 2.6460945514234018],
					[60.460880469814548, 2.6461279517550498],
					[60.460880470793306, 2.6461279533869089],
					[60.460880473556692, 2.6461279546686627],
					[60.460880475427736, 2.6461279561254698],
					[60.460922070400358, 2.646161252738978],
					[60.460985965227131, 2.6462195482775068],
					[60.460985967990503, 2.6462195495592722],
					[60.460985973690114, 2.6462195557366597],
					[60.460985978324558, 2.6462195584752415],
					[60.461047078211948, 2.646266857344779],
					[60.461047078298385, 2.6462668591517038],
					[60.46104707927713, 2.6462668607835771],
					[60.461047080169436, 2.6462668606085264],
					[60.461113771606001, 2.6463168545807343],
					[60.461174866259796, 2.6463723477507699],
					[60.461174869109598, 2.6463723508394859],
					[60.461174875701538, 2.6463723568418831],
					[60.46117488033596, 2.6463723595805018],
					[60.461219264613383, 2.6464057497760352],
					[60.461247051738333, 2.6464362348642849],
					[60.461247058330237, 2.6464362408667137],
					[60.461247072406422, 2.6464362526964944],
					[60.461247078106013, 2.6464362588739712],
					[60.461330475775434, 2.6465001573022513],
					[60.461419255007847, 2.6465723405540982],
					[60.461435940156804, 2.6465945200219734],
					[60.461435957255645, 2.6465945385545582],
					[60.461435994763228, 2.6465945694987116],
					[60.461436016064276, 2.646594581735223],
					[60.461472091417498, 2.646611270333012],
					[60.46154427136657, 2.6466723525943801],
					[60.46154427697973, 2.6466723569649782],
					[60.461544289098349, 2.6466723655311517],
					[60.461544294711537, 2.6466723699017676],
					[60.46161659100256, 2.646716868440365],
					[60.461677687159685, 2.6467584645856022],
					[60.461677691794087, 2.6467584673243181],
					[60.46167770114937, 2.6467584746087174],
					[60.461677705697348, 2.6467584755404694],
					[60.461760988081096, 2.6468029660876988],
					[60.461777671974062, 2.6468168545756274],
					[60.461777687748381, 2.6468168642487258],
					[60.461777722952668, 2.6468168847016975],
					[60.461777741231039, 2.6468168902357259],
					[60.461813841084378, 2.6468279913833457],
					[60.461886040702787, 2.646850191938388],
					[60.461886045164313, 2.6468501910631868],
					[60.461886051583363, 2.6468501934518449],
					[60.461886055239027, 2.6468501945586738],
					[60.461922155276724, 2.6468584941751567],
					[60.461922162588081, 2.6468584963887767],
					[60.461922179801256, 2.6468584984839425],
					[60.461922188004898, 2.6468585005225269],
					[60.462013887623847, 2.6468640992761467],
					[60.462013894848731, 2.6468640996828015],
					[60.462013909298548, 2.6468641004960975],
					[60.462013914652402, 2.6468640994458501],
					[60.462086114729573, 2.6468584995695807],
					[60.462086120975741, 2.6468584983442858],
					[60.462086132662193, 2.646858497875725],
					[60.462086138908369, 2.6468584966504287],
					[60.46212783881176, 2.6468501960948378],
					[60.462127847734862, 2.6468501943444132],
					[60.46212786460228, 2.6468501892116199],
					[60.462127872546631, 2.6468501858292517],
					[60.462191773426731, 2.646825185627129],
					[60.462191776103651, 2.6468251851019993],
					[60.462191781371061, 2.6468251822447497],
					[60.462191784047981, 2.6468251817196204],
					[60.462227884352131, 2.6468084822472715],
					[60.462227893994644, 2.6468084767078026],
					[60.462227913193232, 2.6468084638218592],
					[60.462227922835758, 2.6468084582823699],
					[60.462252920114921, 2.6467890596121442],
					[60.462344518229983, 2.6467223618238109],
					[60.462344524303241, 2.64672235698449],
					[60.46234453644982, 2.6467223473058148],
					[60.462344540738464, 2.6467223428165698],
					[60.462425141438004, 2.6466418423963085],
					[60.462425144834356, 2.6466418380821057],
					[60.462425150821176, 2.6466418314357507],
					[60.462425154217541, 2.646641827121528],
					[60.462452953899316, 2.6466084285147051],
					[60.462452956403368, 2.646608424375529],
					[60.462452962390202, 2.646608417729162],
					[60.462452964001933, 2.6466084137650361],
					[60.462477963222746, 2.6465723178329918],
					[60.462558460800423, 2.6464640195405162],
					[60.462558463304447, 2.6464640154013326],
					[60.462558468312537, 2.6464640071229453],
					[60.462558470903005, 2.6464640047907704],
					[60.462619570247249, 2.6463640058936067],
					[60.462683468908516, 2.646264008242234],
					[60.462683471412554, 2.6462640041030179],
					[60.462683476420615, 2.6462639958245688],
					[60.462683478032332, 2.6462639918604163],
					[60.462744578325513, 2.6461444917974895],
					[60.462744579131389, 2.646144489815387],
					[60.462791876683283, 2.6460500922367269],
					[60.462861275929932, 2.645922294554834],
					[60.462861276735779, 2.6459222925727417],
					[60.462861280045672, 2.6459222864513761],
					[60.462861281657382, 2.6459222824871667],
					[60.462905779346634, 2.6458250901860589],
					[60.462977974610276, 2.6456944975803069],
					[60.46297797630843, 2.6456944954231179],
					[60.462977977920154, 2.6456944914588991],
					[60.462977978726009, 2.6456944894767878],
					[60.463025178617364, 2.6456000895923677],
					[60.463091874933866, 2.6454694951428395],
					[60.463139070917094, 2.6453890033673368],
					[60.463211270401324, 2.645272305050641],
					[60.463239070349694, 2.64522790552979],
					[60.463239071961397, 2.6452279015655162],
					[60.463239076969373, 2.6452278932867661],
					[60.463239078581076, 2.6452278893224919],
					[60.463255773119222, 2.645194502612604],
					[60.463336258932316, 2.6450779211104773],
					[60.463405747727855, 2.6449945345548063],
					[60.463464036690219, 2.64493624426379],
					[60.463516825877598, 2.644889056058779],
					[60.463589017727699, 2.644836261575739],
					[60.463589020318153, 2.6448362592433519],
					[60.463589022995066, 2.6448362587180307],
					[60.463589024693185, 2.6448362565607573],
					[60.46365840129463, 2.6447807766442866],
					[60.463694471966328, 2.6447668870025307],
					[60.463694486962567, 2.6447668804115003],
					[60.463694514911047, 2.6447668621584848],
					[60.463694528036214, 2.6447668541106153],
					[60.463711200395871, 2.6447529772773164],
					[60.463783360069741, 2.6447279905038248],
					[60.463822244901159, 2.6447168955879685],
					[60.463902834143667, 2.6447029971396985],
					[60.463902835035967, 2.6447029969645839],
					[60.46390283771288, 2.6447029964392428],
					[60.4639028403898, 2.6447029959139017],
					[60.463944426602112, 2.6446945975190936],
					[60.464016713667128, 2.644689100969102],
					[60.464061105877377, 2.6446863007367347],
					[60.464133292028926, 2.6446862997704779],
					[60.464205584954136, 2.6446918996111215],
					[60.464205587717515, 2.6446919008928651],
					[60.464205595748261, 2.644691899316816],
					[60.464205600296268, 2.6446919002483433],
					[60.464252788022129, 2.6446919003509888],
					[60.464322170241871, 2.6447001983103315],
					[60.464366659386826, 2.6447084967546171],
					[60.464438854692297, 2.6447251952675357],
					[60.46447493672563, 2.6447334910669049],
					[60.464519320165969, 2.6447529828399459],
					[60.464519322037027, 2.6447529842968236],
					[60.464519327563799, 2.6447529868603619],
					[60.464519331219492, 2.6447529879670042],
					[60.464572109701379, 2.6447723808929604],
					[60.464610990833634, 2.6447973687266124],
					[60.464699869969969, 2.6448557535545429],
					[60.464772053248865, 2.6449334362872361],
					[60.464772058948562, 2.6449334424650726],
					[60.464772072046095, 2.6449334526633739],
					[60.464772077745792, 2.6449334588412103],
					[60.464808138645758, 2.6449612295197853],
					[60.464824817775032, 2.6449972838731637],
					[60.464824823647646, 2.6449972936652815],
					[60.464824836285196, 2.6449973130743976],
					[60.464824843136569, 2.6449973244985476],
					[60.464908135770983, 2.6451029144450202],
					[60.464969223804118, 2.6452028954570319],
					[60.46502201959769, 2.6453111876361839],
					[60.465022021555221, 2.6453111909002733],
					[60.465022025383846, 2.6453111956212707],
					[60.465022026449049, 2.6453111990604681],
					[60.465088720675915, 2.6454278894743335],
					[60.46514151534673, 2.6455500750822627],
					[60.46518591319203, 2.6456667709912525],
					[60.465185913278475, 2.6456667727984033],
					[60.465185914257233, 2.6456667744304587],
					[60.465185915149547, 2.6456667742553615],
					[60.465247015179642, 2.6458167758437603],
					[60.465247015266115, 2.6458167776509134],
					[60.465247016244859, 2.6458167792829785],
					[60.465247017223618, 2.6458167809150406],
					[60.465277613051946, 2.6458861701231871],
					[60.465305407659152, 2.645972258100763],
					[60.465338706673201, 2.6460917549293215],
					[60.465338708717191, 2.6460917600006457],
					[60.465338713783851, 2.6460917717753647],
					[60.465338715914278, 2.6460917786538531],
					[60.465377614410961, 2.6461833732580664],
					[60.465430410453379, 2.6463306645074831],
					[60.465477608055998, 2.6464806562508802],
					[60.465502607321156, 2.6465694534437287],
					[60.465502609451541, 2.6465694603222842],
					[60.465502613625866, 2.6465694722722128],
					[60.46550261566982, 2.6465694773435962],
					[60.465549812620587, 2.6466806722603824],
					[60.465583108645461, 2.6467750582734419],
					[60.465602606211604, 2.6468500503707677],
					[60.465602607276786, 2.6468500538100779],
					[60.465602610385901, 2.6468500623207869],
					[60.465602611451118, 2.6468500657600718],
					[60.465655410759616, 2.647002865450808],
					[60.465655411824784, 2.6470028688901119],
					[60.465655414847483, 2.6470028755936656],
					[60.46565541591265, 2.6470028790329687],
					[60.465713710549053, 2.6471389663201759],
					[60.465747006765149, 2.6472583531453213],
					[60.465747007743893, 2.6472583547774517],
					[60.465747008809061, 2.6472583582167881],
					[60.465747008895505, 2.6472583600239754],
					[60.465799808742787, 2.6474250609116874],
					[60.465799811765471, 2.6474250676153095],
					[60.465799817897214, 2.6474250828297516],
					[60.465799820919862, 2.6474250895333782],
					[60.465819221221942, 2.6474639888126532],
					[60.465819221308372, 2.6474639906198454],
					[60.465838713680441, 2.6475028755298151],
					[60.465891509400798, 2.6476694594790264],
					[60.465891509573652, 2.6476694630934197],
					[60.465891511531147, 2.6476694663577365],
					[60.465891511617563, 2.6476694681649358],
					[60.465938710740076, 2.6477972652804755],
					[60.465983107721911, 2.6479306562351401],
					[60.466024806002217, 2.6480917496400713],
					[60.466024808132538, 2.6480917565188422],
					[60.466024812220319, 2.6480917666619557],
					[60.466024814264202, 2.6480917717335024],
					[60.466044208738658, 2.6481417600579364],
					[60.466055405370668, 2.6481889456804053],
					[60.466055405543507, 2.6481889492948243],
					[60.466055407673821, 2.6481889561736072],
					[60.466055408738974, 2.6481889596129924],
					[60.46611090792598, 2.6483639556110474],
					[60.466155406409271, 2.6485278482270611],
					[60.4661720045777, 2.6485972473905619],
					[60.466172005556437, 2.6485972490227572],
					[60.466172006708, 2.6485972542694003],
					[60.466172007686723, 2.6485972559015942],
					[60.466191506360914, 2.6486639501102154],
					[60.466227603779792, 2.6488278422181359],
					[60.466263703690075, 2.6490000414636174],
					[60.466274802253203, 2.6490528306919567],
					[60.46628040066571, 2.6491083203953489],
					[60.466280400838542, 2.6491083240097955],
					[60.466280403055222, 2.6491083326959122],
					[60.466280403228026, 2.6491083363103618],
					[60.466308103096267, 2.6492611343930634],
					[60.466327601721886, 2.6493833316593109],
					[60.466347002358333, 2.6495111302596004],
					[60.466347002617532, 2.6495111356812875],
					[60.466347004834191, 2.6495111443674513],
					[60.46634700598571, 2.6495111496141588],
					[60.466385904687378, 2.6496611464103075],
					[60.466399804039987, 2.6497278379151621],
					[60.466410903262243, 2.6497889365899736],
					[60.466410904154557, 2.6497889364149825],
					[60.466410904327361, 2.6497889400294476],
					[60.46641090530607, 2.6497889416616931],
					[60.466447003978018, 2.6499556412852026],
					[60.466483103346285, 2.6501250354347117],
					[60.466491501833488, 2.6501861276784493],
					[60.466491503157776, 2.6501861365396815],
					[60.466491506612279, 2.6501861522799302],
					[60.466491508828888, 2.650186160966181],
					[60.466510906257668, 2.6502472507384653],
					[60.466544204406226, 2.6504028402604947],
					[60.466572004110098, 2.6505444386324148],
					[60.46657200526159, 2.6505444438791947],
					[60.466572007564565, 2.650544454372739],
					[60.466572008716042, 2.6505444596195047],
					[60.466602604078481, 2.6506417440534693],
					[60.466616502105445, 2.6507444260801027],
					[60.466616502191833, 2.6507444278873526],
					[60.466616502364609, 2.6507444315018653],
					[60.466616503343346, 2.6507444331341419],
					[60.466635902609475, 2.650861133569117],
					[60.466635903674558, 2.6508611370086705],
					[60.46663590499881, 2.650861145869956],
					[60.466635906063907, 2.6508611493095073],
					[60.466663705938963, 2.6509694477265833],
					[60.466694204948148, 2.6510972467478009],
					[60.466694205840469, 2.6510972465728395],
					[60.466694205926856, 2.6510972483800925],
					[60.466727605357605, 2.6512333463612041],
					[60.466747004851669, 2.6513167448391433],
					[60.466763703537779, 2.6513944414784922],
					[60.466763705408788, 2.6513944429358509],
					[60.466763704602862, 2.6513944449180582],
					[60.466763704602862, 2.6513944449180582],
					[60.466799804764037, 2.6515500454882202],
					[60.466838703891412, 2.6517194376790596],
					[60.466847002171036, 2.6517722303656024],
					[60.466847004560293, 2.6517722426666088],
					[60.466847011037061, 2.6517722651114406],
					[60.466847015124586, 2.6517722752552864],
					[60.466866505267056, 2.6518194534089456],
					[60.466891501910119, 2.6519972286085327],
					[60.466891503061539, 2.6519972338554214],
					[60.466891505450789, 2.6519972461564429],
					[60.466891506602209, 2.6519972514033134],
					[60.466938702838839, 2.6521722363418552],
					[60.466944201278558, 2.6522250217776961],
					[60.466944202689099, 2.6522250324464136],
					[60.46694420729478, 2.6522250534339968],
					[60.466944209597628, 2.6522250639277902],
					[60.466963707217801, 2.6522833522548286],
					[60.466999805216481, 2.6524472424353069],
					[60.466999805389214, 2.652447246049876],
					[60.46699980654062, 2.652447251296782],
					[60.466999807605674, 2.6524472547364257],
					[60.467044203752828, 2.6526028419693661],
					[60.467055401857969, 2.6526806275867449],
					[60.467055404160767, 2.6526806380806049],
					[60.467055408680039, 2.652680657261048],
					[60.46705541187518, 2.6526806675799888],
					[60.46708310637851, 2.6527583517348456],
					[60.467108102785545, 2.6529056333220886],
					[60.467108104023303, 2.6529056403763125],
					[60.467108108369814, 2.6529056559422499],
					[60.467108109607565, 2.6529056629964916],
					[60.467155403425167, 2.6530472424809077],
					[60.46716370093845, 2.6531333189489046],
					[60.467163702348905, 2.653133329617742],
					[60.467163705889426, 2.6531333471659324],
					[60.467163708192196, 2.6531333576598617],
					[60.467188706435103, 2.6532167480498301],
					[60.467216503912049, 2.6533611372507497],
					[60.467216505063433, 2.6533611424977392],
					[60.467216507279844, 2.6533611511844115],
					[60.467216507538907, 2.6533611566062825],
					[60.467260906571248, 2.6535111541693852],
					[60.467280405637979, 2.6535861497091684],
					[60.467280407768001, 2.6535861565885814],
					[60.467280411941765, 2.6535861685400852],
					[60.467280413985449, 2.6535861736121999],
					[60.467308108975018, 2.6536556608400064],
					[60.467347005611096, 2.6538167471441612],
					[60.467347005783786, 2.6538167507587773],
					[60.46734700782747, 2.6538167558309023],
					[60.467347008806165, 2.6538167574633054],
					[60.467399804849741, 2.653991746030925],
					[60.467408102824031, 2.6540389342899711],
					[60.467408103889049, 2.6540389377297124],
					[60.467408105126744, 2.6540389447840651],
					[60.467408106191748, 2.6540389482237923],
					[60.467419206191138, 2.6540833471950394],
					[60.467463705389498, 2.6542639466743982],
					[60.467505405122161, 2.6544472441866844],
					[60.467505407338507, 2.654447252873509],
					[60.467505412749844, 2.6544472718796039],
					[60.467505416750804, 2.6544472802166892],
					[60.467524809634085, 2.6544917653907834],
					[60.467535906033, 2.654536148942606],
					[60.467535907011644, 2.6545361505750544],
					[60.467583105023962, 2.6547139438863794],
					[60.467616503947291, 2.6548889362161541],
					[60.467616505184949, 2.6548889432705844],
					[60.467616507660267, 2.6548889573794248],
					[60.467616509703916, 2.6548889624516763],
					[60.467635903984544, 2.6549472444793039],
					[60.467644202315221, 2.655013925226398],
					[60.467644202401559, 2.6550139270337207],
					[60.467644202660559, 2.6550139324556974],
					[60.467644202746889, 2.6550139342630166],
					[60.467669203004164, 2.6551583349089007],
					[60.467669203004164, 2.6551583349089007],
					[60.467688701024805, 2.6552667234034564],
					[60.467697000462231, 2.6554000127049329],
					[60.46769699956991, 2.6554000128797779],
					[60.467697000548547, 2.6554000145122592],
					[60.467697000634871, 2.6554000163196005],
					[60.467708101031299, 2.655536115278037],
					[60.467716500879853, 2.6556444147652409],
					[60.467727600561979, 2.6557889146415374],
					[60.467727600648296, 2.6557889164488686],
					[60.467727600648296, 2.6557889164488686],
					[60.467727600734619, 2.6557889182561958],
					[60.467735901159166, 2.6558833165153537],
					[60.467747000276901, 2.6560361078298342],
					[60.467747000146588, 2.65609169989852],
					[60.467747000405531, 2.6560917053205277],
					[60.467747000923431, 2.6560917161645281],
					[60.467747001182389, 2.6560917215865159],
					[60.467755400729772, 2.6561667197486059],
					[60.467766500653603, 2.6562861164089759],
					[60.467772000857565, 2.656361115342166],
					[60.467772002267758, 2.6563611260113564],
					[60.4677720059805, 2.6563611471749153],
					[60.467772009175349, 2.656361157494465],
					[60.467819209384963, 2.6565167583629408],
					[60.467819208578952, 2.6565167603450948],
					[60.46781921062253, 2.6565167654174839],
					[60.46781921160116, 2.6565167670500038],
					[60.467863709858698, 2.6566417631947341],
					[60.467899804932316, 2.656761143544871],
					[60.4679191960061, 2.6568860877548075],
					[60.467891516274108, 2.6569832172122498],
					[60.467858131119428, 2.6570359929154623],
					[60.467858116322866, 2.6570360414202896],
					[60.467858111167708, 2.6570361409396432],
					[60.467858121701461, 2.6570361917793468],
					[60.46829981779112, 2.657902883109724],
					[60.469047015238992, 2.6597444757685165],
					[60.469047017282449, 2.659744480841304],
					[60.469047023240314, 2.6597444924448008],
					[60.469047026262388, 2.6597444991502659],
					[60.469841526260403, 2.6611472986538853],
					[60.469841530174733, 2.6611473051849406],
					[60.469841538895729, 2.6611473180723295],
					[60.469841543702387, 2.6611473244286814],
					[60.470688743963024, 2.6622112246121659],
					[60.470688750640548, 2.6622112324269875],
					[60.470688766758826, 2.6622112493401024],
					[60.470688776113342, 2.6622112566308807],
					[60.471119258636065, 2.6625529440470483],
					[60.472019246268516, 2.6636362281287345],
					[60.472019309189072, 2.6636362632499124],
					[60.472019443410311, 2.663636282591356],
					[60.472019512840092, 2.6636362653532952],
					[60.473100112724872, 2.6628973647795982],
					[60.473100125764979, 2.6628973549287749],
					[60.473100149801816, 2.6628973301535339],
					[60.473100160971022, 2.6628973188444198],
					[60.474169561061295, 2.6614334185589015],
					[60.474169561867363, 2.6614334165764704],
					[60.474169564458116, 2.661433414244581],
					[60.474169565264198, 2.6614334122621308],
					[60.475422347246912, 2.6596029386460152],
					[60.476855668882251, 2.658497398494891],
					[60.47990000236603, 2.6584640997262863],
					[60.479900023868154, 2.6584640973379003],
					[60.479900066613524, 2.6584640871370864],
					[60.479900087856755, 2.6584640793246419],
					[60.482947288345422, 2.6569779803453173],
					[60.482947290043768, 2.6569779781873102],
					[60.482947296203697, 2.6569779751547467],
					[60.482947297988346, 2.6569779748049216],
					[60.484913922436903, 2.6558669178030438],
					[60.485988838179196, 2.656216890698742],
					[60.487069283251465, 2.6565723716376315],
					[60.487969245610763, 2.6576529284041062],
					[60.487969324767356, 2.6576529639971409],
					[60.487969479628049, 2.6576529628446637],
					[60.487969557922803, 2.6576529237658857],
					[60.489402931295587, 2.6558195576379782],
					[60.490836117613007, 2.6550780157665028],
					[60.491913780682928, 2.6554279722805614],
					[60.493355444184076, 2.6572334241716349],
					[60.49335544996962, 2.6572334321655724],
					[60.493355465110014, 2.6572334474535264],
					[60.493355471701562, 2.6572334534637045],
					[60.493983171778908, 2.6577557540426335],
					[60.493983292731272, 2.6577557595374919],
					[60.493983451422466, 2.657755649914666],
					[60.493983489247618, 2.657755536605777],
					[60.493939093165373, 2.657624946648236],
					[60.49391129618602, 2.657499958668327],
					[60.493891899220216, 2.6574055732039823],
					[60.493886299637133, 2.6573277965143904],
					[60.493891900063062, 2.6571917082130012],
					[60.493891899976717, 2.6571917064042143],
					[60.49389189980409, 2.6571917027866356],
					[60.493891899631436, 2.657191699169041],
					[60.493891900018625, 2.6571167094642205],
					[60.493900197670371, 2.6570222315932939],
					[60.49391959311663, 2.6569417513647444],
					[60.493947390743088, 2.656850058306675],
					[60.493947391549078, 2.6568500563228756],
					[60.493947393247403, 2.6568500541640931],
					[60.493947393161058, 2.6568500523553062],
					[60.493980787938597, 2.6567278727378825],
					[60.49401956600822, 2.6566501159752338],
					[60.494072342705159, 2.6565945403052273],
					[60.494133410894442, 2.6565334713914361],
					[60.494213959745245, 2.6565029913034612],
					[60.494258331521792, 2.6564918971913518],
					[60.494330608212167, 2.6564862992563176],
					[60.494358300353653, 2.6564862997129945],
					[60.494358320071044, 2.6564862976716666],
					[60.494358359333162, 2.6564862899713537],
					[60.494358377985563, 2.6564862844873747],
					[60.494377852129865, 2.6564779956692304],
					[60.494450012385741, 2.6564695997132173],
					[60.49446930576147, 2.6564696005456589],
					[60.494483145654968, 2.6564862282110639],
					[60.494483163558748, 2.6564862447829576],
					[60.4944832027629, 2.6564862736090746],
					[60.494483224063302, 2.6564862858632927],
					[60.494563824146773, 2.6565195854295696],
					[60.494563881139996, 2.6565195906835148],
					[60.494563990003144, 2.6565195693325787],
					[60.494564040980741, 2.6565195429027004],
					[60.494616841407712, 2.6564668424180873],
					[60.494616847394951, 2.6564668357665417],
					[60.494616856778819, 2.6564668247972851],
					[60.49461686187373, 2.6564668183207352],
					[60.494683432983749, 2.6563751570838559],
					[60.49475568876634, 2.6563390797909534],
					[60.494755692249292, 2.6563390772820612],
					[60.494755698409215, 2.6563390742481285],
					[60.494755700107511, 2.6563390720892515],
					[60.494808384136661, 2.6563084838313435],
					[60.494880665416261, 2.6562834891765723],
					[60.49488066979152, 2.6562834864926526],
					[60.494880680413019, 2.6562834825836155],
					[60.494880684874623, 2.656283481708531],
					[60.494922263939273, 2.656264090681661],
					[60.49498614303775, 2.6562501958960825],
					[60.494986150176295, 2.6562501944959371],
					[60.494986165259405, 2.6562501897117841],
					[60.494986172311634, 2.6562501865027905],
					[60.495022271679339, 2.656236286719075],
					[60.495022292749688, 2.6562362752832303],
					[60.495022331148398, 2.6562362494938867],
					[60.495022349282749, 2.656236233156521],
					[60.495047349123467, 2.6562084343389576],
					[60.495047350735433, 2.6562084303712203],
					[60.495047355024361, 2.656208425878404],
					[60.495047356722665, 2.6562084237195149],
					[60.49512792442016, 2.6561057663809957],
					[60.495219455435283, 2.65606959344781],
					[60.495266734803039, 2.6560612965907415],
					[60.495266741941592, 2.6560612951905394],
					[60.495266756218719, 2.6560612923901337],
					[60.495266763270934, 2.656061289181054],
					[60.495333363190866, 2.6560390894982961],
					[60.495333365867829, 2.6560390889732184],
					[60.495333371135409, 2.656039086114192],
					[60.495333373812372, 2.6560390855891129],
					[60.495361173790982, 2.6560279854700743],
					[60.495361189680054, 2.6560279787018586],
					[60.495361220220516, 2.6560279581049673],
					[60.495361234152284, 2.6560279480690525],
					[60.495391834278493, 2.6560001475945554],
					[60.495391837675101, 2.6560001432767066],
					[60.495391846339274, 2.6560001360998062],
					[60.495391848929884, 2.656000133765855],
					[60.495461216803712, 2.6559223682746644],
					[60.495533336810013, 2.6558945993311878],
					[60.495597200029721, 2.6558945992416656],
					[60.495597209931582, 2.6558945991251903],
					[60.495597231433592, 2.6558945967332974],
					[60.49559724133546, 2.6558945966168381],
					[60.495650020809848, 2.6558835005736166],
					[60.495705599627065, 2.6558835004937085],
					[60.495705621129076, 2.6558834981017938],
					[60.495705662089442, 2.6558834882413747],
					[60.495705683418763, 2.6558834822316735],
					[60.495766783361944, 2.6558557822161619],
					[60.495766787737189, 2.6558557795320996],
					[60.495766798272349, 2.6558557738139106],
					[60.495766802647594, 2.6558557711298487],
					[60.495822296001037, 2.6558223750334085],
					[60.495900088757871, 2.6557834798519706],
					[60.495900111440093, 2.6557834644476057],
					[60.495900150299299, 2.6557834294374345],
					[60.49590016826091, 2.6557834094815531],
					[60.495936267833137, 2.6557279087713068],
					[60.495936273647658, 2.6557278985015254],
					[60.495936285190311, 2.6557278761530712],
					[60.495936289133844, 2.6557278644244708],
					[60.495991889115984, 2.6555639632163888],
					[60.495991890008305, 2.6555639630413452],
					[60.495991890727929, 2.6555639592485005],
					[60.495991890727929, 2.6555639592485005],
					[60.496036290889286, 2.6554194594803362],
					[60.496036293307206, 2.6554194535284665],
					[60.496036296358362, 2.6554194419748463],
					[60.496036296991633, 2.6554194363730739],
					[60.496052997446519, 2.6553278367445272],
					[60.496052997993417, 2.6553278293338503],
					[60.496052999259952, 2.6553278181303166],
					[60.496052999893216, 2.6553278125285571],
					[60.49605849825825, 2.6552444320449822],
					[60.496097393031398, 2.6550972503020955],
					[60.496097393837353, 2.6550972483181177],
					[60.496097394643314, 2.6550972463341553],
					[60.496097395449283, 2.6550972443501939],
					[60.496130792855531, 2.6549500543634923],
					[60.496158488985373, 2.6548694649741362],
					[60.496158489877708, 2.6548694647990665],
					[60.496158489791348, 2.6548694629901459],
					[60.49618628977418, 2.6547861635959209],
					[60.496186292105676, 2.6547861558350743],
					[60.496186295876413, 2.6547861404884543],
					[60.496186297229244, 2.6547861310937644],
					[60.49621129707193, 2.6546306351744406],
					[60.496247394224469, 2.654452846175507],
					[60.496258491822147, 2.6544139544055643],
					[60.496258494786858, 2.6544139410428627],
					[60.496258498931667, 2.6544139146675954],
					[60.496258500025384, 2.6544138998461366],
					[60.4962585003532, 2.6543722154195573],
					[60.496283498143818, 2.6542083293358489],
					[60.496283497971099, 2.6542083257180056],
					[60.496283499410261, 2.6542083181321758],
					[60.496283499237535, 2.6542083145143538],
					[60.496291899215038, 2.6540917210573243],
					[60.496311298045178, 2.653961128613628],
					[60.496311298592005, 2.6539611212028729],
					[60.496311299599299, 2.6539611045724487],
					[60.496311300059773, 2.6539610953527819],
					[60.496308499820309, 2.6538388975383649],
					[60.496308499677149, 2.6537166998582746],
					[60.496308498986167, 2.6537166853869514],
					[60.496308495013615, 2.6537166587785408],
					[60.496308491645685, 2.6537166448325071],
					[60.496266891909443, 2.6535749448326884],
					[60.496266887735551, 2.6535749328707143],
					[60.496266877603134, 2.6535749092969567],
					[60.496266871731002, 2.6535748994940929],
					[60.496219571735999, 2.653494299711042],
					[60.496219569692236, 2.6534942946345299],
					[60.496219563820084, 2.6534942848316971],
					[60.496219560970381, 2.653494281739178],
					[60.496147364557956, 2.6533970865742207],
					[60.496075173544092, 2.653283200663636],
					[60.496022380599321, 2.6531777143069295],
					[60.495966883372795, 2.6530527185059061],
					[60.49591128425336, 2.6529277230250905],
					[60.495869585712633, 2.6528221272812713],
					[60.49586958171146, 2.6528221189373089],
					[60.495869574687774, 2.6528221038831541],
					[60.495869569794287, 2.6528220957143129],
					[60.495802971728125, 2.6527137987070697],
					[60.495730793139643, 2.6525888338226644],
					[60.49571130025987, 2.6524416869252536],
					[60.495711300436938, 2.6523556007480824],
					[60.495711300091351, 2.6523555935125764],
					[60.495711299313832, 2.6523555772326834],
					[60.49571129807596, 2.6523555701723196],
					[60.495694597991772, 2.6522416714623098],
					[60.495694597905384, 2.6522416696534203],
					[60.495694597905384, 2.6522416696534203],
					[60.495694596926668, 2.6522416680196814],
					[60.495675201189023, 2.6521221910936608],
					[60.495686299579589, 2.6519611143629751],
					[60.495686300299099, 2.6519611105700953],
					[60.495686299953512, 2.6519611033345987],
					[60.495686299780708, 2.6519610997168592],
					[60.495686300416381, 2.651919419517319],
					[60.495694595617344, 2.651877838494284],
					[60.49569459731557, 2.6518778363351272],
					[60.495694597948692, 2.651877830733353],
					[60.495694597862283, 2.6518778289244849],
					[60.495719597446723, 2.6517056354926809],
					[60.495747395785365, 2.6515722409700815],
					[60.495747395698956, 2.6515722391612098],
					[60.495747397310801, 2.6515722351931577],
					[60.495747397224392, 2.6515722333842717],
					[60.495766895735471, 2.6514556405252407],
					[60.495800193994626, 2.6513250494896616],
					[60.49580019390821, 2.6513250476807873],
					[60.495800196325945, 2.6513250417286689],
					[60.495800196153127, 2.6513250381109206],
					[60.495819593609305, 2.6512278478363531],
					[60.495852990300534, 2.6511167625526162],
					[60.495889088570756, 2.6510139655087879],
					[60.495889089376647, 2.6510139635247429],
					[60.495889090988449, 2.6510139595566495],
					[60.495889091794353, 2.6510139575726037],
					[60.495914087220456, 2.650930672663768],
					[60.495980780398313, 2.6507917862457036],
					[60.495980780398313, 2.6507917862457036],
					[60.495980781290619, 2.650791786070529],
					[60.496033478609135, 2.6506806883092406],
					[60.496094577791396, 2.6505611904859854],
					[60.496139072870832, 2.6504751006403464],
					[60.496208456614227, 2.6503668259059663],
					[60.496247341109665, 2.650327940127851],
					[60.496247348881212, 2.6503279331244349],
					[60.496247361574497, 2.6503279160254065],
					[60.496247369259606, 2.650327907213097],
					[60.49626686211387, 2.6502973165623938],
					[60.496352953160184, 2.6501918280741332],
					[60.49643344970498, 2.6501001327548073],
					[60.496433454799558, 2.650100126276894],
					[60.496433463290543, 2.6501001154803738],
					[60.496433465794631, 2.6501001113369704],
					[60.496461251897621, 2.6500584321632012],
					[60.496505731604238, 2.6500195517465901],
					[60.496505735000625, 2.6500195474279722],
					[60.496505742772143, 2.6500195404244433],
					[60.496505747060858, 2.6500195359306042],
					[60.496577945084319, 2.6499418376535822],
					[60.496650144172733, 2.6498668392943969],
					[60.496650146676828, 2.6498668351509296],
					[60.496650150965522, 2.6498668306570674],
					[60.496650154448332, 2.6498668281473314],
					[60.496691851312924, 2.6498168316601722],
					[60.496739048940285, 2.649764033120563],
					[60.496739049832598, 2.6497640329453529],
					[60.496800150352385, 2.6496945325461394],
					[60.496800151158261, 2.6496945305620043],
					[60.496800152856444, 2.6496945284026565],
					[60.496800153748758, 2.6496945282274456],
					[60.496855753653598, 2.6496279280760811],
					[60.496855755438219, 2.6496279277256547],
					[60.496855755351781, 2.6496279259167279],
					[60.496916855126265, 2.649552925505203],
					[60.496916856018593, 2.6495529253299841],
					[60.49691685682447, 2.649552923345837],
					[60.496989048238092, 2.649461235947506],
					[60.497033436780612, 2.6494195453933678],
					[60.49703344956022, 2.6494195301024868],
					[60.497033474054234, 2.6494194960780666],
					[60.497033484070471, 2.6494194795039117],
					[60.497050172707915, 2.6493807029942342],
					[60.497130757135487, 2.6492779248205447],
					[60.497211256314124, 2.6491779254722077],
					[60.497211258818176, 2.649177921328616],
					[60.4972112656109, 2.6491779126910062],
					[60.497211268920815, 2.6491779065632413],
					[60.497250161389672, 2.6491168186889729],
					[60.497311252682863, 2.6490445294777545],
					[60.497311255186922, 2.6490445253341517],
					[60.497311258583274, 2.6490445210153029],
					[60.497311262066056, 2.6490445185054239],
					[60.497355751389044, 2.6489834321709371],
					[60.497411237662767, 2.6489307453908677],
					[60.497411242757302, 2.6489307389125623],
					[60.497411253925108, 2.6489307275897063],
					[60.497411259019607, 2.6489307211114079],
					[60.497472350837107, 2.6488501321638793],
					[60.497555741378747, 2.6487668416486905],
					[60.497555741378747, 2.6487668416486905],
					[60.497555742271061, 2.6487668414734462],
					[60.497589037881895, 2.6487334446509312],
					[60.497622325653495, 2.6487029562029281],
					[60.497697314705697, 2.6486501649444527],
					[60.497697316403872, 2.6486501627849872],
					[60.497697318188486, 2.6486501624344974],
					[60.497697318994334, 2.6486501604502819],
					[60.497750105806936, 2.6486112700390656],
					[60.497813982096453, 2.6485779837502705],
					[60.497886168355983, 2.6485501883062827],
					[60.497927847248455, 2.64853629453376],
					[60.498000022746211, 2.6485251994759205],
					[60.498036100012847, 2.6485224011557968],
					[60.498072185336007, 2.648525199676103],
					[60.498072190776284, 2.6485252004335837],
					[60.498072203355036, 2.6485251997890398],
					[60.498072208795307, 2.6485252005465174],
					[60.498133298758383, 2.6485223996632112],
					[60.498180587735646, 2.6485251998660608],
					[60.49818059130488, 2.648525199165046],
					[60.498180596745151, 2.6485251999225436],
					[60.498180600314399, 2.6485251992215302],
					[60.49825279983375, 2.6485251994472852],
					[60.498300000041013, 2.6485251995932289],
					[60.498300003696691, 2.6485252007012163],
					[60.498300010835145, 2.6485251992991827],
					[60.498300014404357, 2.6485251985981693],
					[60.498377814298586, 2.648519599620252],
					[60.498377822329353, 2.6485195980429568],
					[60.498377836606245, 2.6485195952388851],
					[60.49837784472345, 2.6485195954706167],
					[60.498450045378426, 2.6485029953232231],
					[60.498486145008698, 2.6484945958379607],
					[60.4984861511684, 2.6484945928021522],
					[60.49848616276838, 2.6484945905238209],
					[60.498486168928082, 2.6484945874880133],
					[60.498538959586909, 2.6484751912208195],
					[60.498591750871078, 2.6484612928534847],
					[60.498591757117204, 2.648461291626683],
					[60.498591768630746, 2.6484612875393125],
					[60.4985917739846, 2.6484612864877612],
					[60.498619469989734, 2.6484501861016523],
					[60.498700064924165, 2.6484223887091707],
					[60.498700069385713, 2.6484223878328708],
					[60.498700075545401, 2.6484223847970281],
					[60.498700079920496, 2.6484223821117014],
					[60.498744475315689, 2.6484029856255047],
					[60.498811161661621, 2.6483779910478296],
					[60.498872247805089, 2.6483612944874158],
					[60.498936141748445, 2.6483473952823373],
					[60.498988926424559, 2.6483362989124051],
					[60.49904170497441, 2.6483334993476002],
					[60.499097195072686, 2.6483334996774412],
					[60.499149981775879, 2.6483362989317096],
					[60.499213873662619, 2.6483445972373008],
					[60.499213874641363, 2.648344598871093],
					[60.499213878210604, 2.6483445981700262],
					[60.499213879189362, 2.6483445998038158],
					[60.499266671710529, 2.6483501972515073],
					[60.499327763946802, 2.6483612970166241],
					[60.499327773042758, 2.6483612988821053],
					[60.499327791148232, 2.6483613008039719],
					[60.499327800157722, 2.648361300860377],
					[60.499383300071194, 2.6483612992794336],
					[60.499383302834545, 2.6483613005626991],
					[60.499383308188385, 2.648361299511103],
					[60.499383310951771, 2.6483613007943658],
					[60.499436111089231, 2.6483585006079413],
					[60.499436112873838, 2.6483585002574079],
					[60.499436117335378, 2.6483584993810743],
					[60.499436120012291, 2.6483584988552744],
					[60.499491720178654, 2.6483529994069852],
					[60.499491733563261, 2.6483529967779744],
					[60.499491761138309, 2.6483529895356193],
					[60.499491773544129, 2.64835298527281],
					[60.499561173796366, 2.6483251852301426],
					[60.499561182632981, 2.6483251816683659],
					[60.499561200219745, 2.6483251727357788],
					[60.499561208969908, 2.6483251673649302],
					[60.499608408749367, 2.6482945678998449],
					[60.499608420003547, 2.648294558385019],
					[60.499608440813738, 2.648294541514999],
					[60.499608451089166, 2.6482945303663654],
					[60.499661251119825, 2.6482334311672888],
					[60.499661252817972, 2.648233429007667],
					[60.499661257106609, 2.648233424513152],
					[60.499661258804771, 2.6482334223535133],
					[60.499725158861771, 2.6481501215949645],
					[60.499725163869805, 2.6481501133069894],
					[60.49972517477817, 2.6481500965557347],
					[60.499725179786203, 2.6481500882677564],
					[60.499786275898714, 2.648025095464043],
					[60.499839071558974, 2.6479362016472305],
					[60.499839074062955, 2.6479361975031992],
					[60.499839078265133, 2.6479361911995283],
					[60.499839079876836, 2.6479361872307723],
					[60.499905779179841, 2.6477972902166216],
					[60.499955777209593, 2.6477028936132316],
					[60.499955777123134, 2.6477028918041414],
					[60.499955778821281, 2.6477028896444725],
					[60.499955779627129, 2.647702887660087],
					[60.500019579626411, 2.6475722871692544],
					[60.500019579626411, 2.6475722871692544],
					[60.500064076042456, 2.6474806963094344],
					[60.500144570475328, 2.6473501057779698],
					[60.500144572892843, 2.6473500998247474],
					[60.500144578706667, 2.6473500895521198],
					[60.500144582016496, 2.6473500834236172],
					[60.500161267291041, 2.6473140161597564],
					[60.500191840592151, 2.6472834414985043],
					[60.500191846665352, 2.6472834366531814],
					[60.500191856854222, 2.6472834236949168],
					[60.500191861948657, 2.6472834172157746],
					[60.50027236181635, 2.6471723163469139],
					[60.500272362622184, 2.6471723143624999],
					[60.500272363514469, 2.6471723141871961],
					[60.500330751713271, 2.6470890315165678],
					[60.500386231256023, 2.6470362516992734],
					[60.500439024230289, 2.6469945562611419],
					[60.500502910808272, 2.6469445672682874],
					[60.500583382054934, 2.6469001835735657],
					[60.500630653739861, 2.6468834927834002],
					[60.500708342362124, 2.6468668957626638],
					[60.500708346823636, 2.6468668948860814],
					[60.500708358337121, 2.6468668907978308],
					[60.500708363690919, 2.6468668897459322],
					[60.500725003645812, 2.6468613085073605],
					[60.500744345214635, 2.6468668927982555],
					[60.500744350654934, 2.6468668935554853],
					[60.500744363406582, 2.6468668965284698],
					[60.500744369825654, 2.6468668989195168],
					[60.500816648576226, 2.6468779951686572],
					[60.500861019100121, 2.6468945820906344],
					[60.500941597162267, 2.6469362718258491],
					[60.501013780271755, 2.6469862601400864],
					[60.501069247053685, 2.6470307346273918],
					[60.501102629411193, 2.6470862027369217],
					[60.50110263243397, 2.6470862094476004],
					[60.501102641069977, 2.6470862205338967],
					[60.501102644898587, 2.6470862252601082],
					[60.50117483724695, 2.6471751166241382],
					[60.501247030855055, 2.6472890063472865],
					[60.501247031833827, 2.6472890079811426],
					[60.501247033791351, 2.6472890112488519],
					[60.501247034770124, 2.6472890128827227],
					[60.501299827076117, 2.6473668022715455],
					[60.501372020126837, 2.6475083884927373],
					[60.501410916828654, 2.6475917809939165],
					[60.501472014707389, 2.647738977408109],
					[60.501472015599688, 2.6477389772328128],
					[60.501472015686161, 2.6477389790420056],
					[60.501472016664906, 2.6477389806758858],
					[60.501519215009559, 2.6478472752068791],
					[60.501572011259761, 2.6479861653643577],
					[60.501616509618387, 2.648125060733217],
					[60.501616510683604, 2.6481250641763179],
					[60.501616512727594, 2.6481250692532989],
					[60.501616513792797, 2.648125072696387],
					[60.501655412795429, 2.6482250714662383],
					[60.501699811249139, 2.6483444652651267],
					[60.501727605963573, 2.6484333512203366],
					[60.501744203696084, 2.6485111421873508],
					[60.501744205740032, 2.648511147264379],
					[60.5017442090221, 2.648511159402946],
					[60.501744211066061, 2.6485111644799737],
					[60.501799809410485, 2.6486722585761782],
					[60.501847003371317, 2.6488500371797752],
					[60.501852601705615, 2.6488944241660159],
					[60.501852601964941, 2.6488944295936245],
					[60.501852603203019, 2.6488944366551865],
					[60.501852604181764, 2.6488944382891204],
					[60.501860904126907, 2.6489361397354885],
					[60.501860905278548, 2.6489361449878488],
					[60.501860907581815, 2.648936155492553],
					[60.501860909625755, 2.6489361605696278],
					[60.501919205915442, 2.6491167483136264],
					[60.501952602803058, 2.6492944366533044],
					[60.501952605019881, 2.6492944453488505],
					[60.501952610259359, 2.649294460755482],
					[60.501952611583846, 2.649294469626275],
					[60.501972012013589, 2.6493472692822286],
					[60.501991507180804, 2.6494000540432943],
					[60.50202480410023, 2.6495750361449319],
					[60.502024804359536, 2.6495750415725787],
					[60.502024805597586, 2.6495750486342051],
					[60.502024806576316, 2.6495750502681683],
					[60.502072005587202, 2.6497528485189927],
					[60.502083104990739, 2.6498000453387967],
					[60.502083106055906, 2.6498000487819895],
					[60.502083107207525, 2.6498000540344155],
					[60.502083108186241, 2.6498000556683929],
					[60.502097005818214, 2.6498472489937988],
					[60.50213590395407, 2.6500278414794751],
					[60.5021359049328, 2.6500278431134583],
					[60.502135906084405, 2.6500278483659039],
					[60.502135906170828, 2.6500278501751278],
					[60.502183102858687, 2.6502111378018505],
					[60.502188702397795, 2.6502528260584826],
					[60.502188703895087, 2.6502528385478503],
					[60.502188711437633, 2.6502528644596617],
					[60.50218871471953, 2.6502528765985613],
					[60.502208109668416, 2.6503000644848012],
					[60.502255406068194, 2.6504778491865411],
					[60.502297004610419, 2.6506528451841289],
					[60.502297006654295, 2.6506528502613866],
					[60.502297008871011, 2.6506528589571259],
					[60.502297009936157, 2.6506528624003853],
					[60.502316509238682, 2.650711157259054],
					[60.502333106949159, 2.6507722517652712],
					[60.502333107035568, 2.6507722535745102],
					[60.502333108100736, 2.6507722570177745],
					[60.502333109079444, 2.650772258651795],
					[60.502380406687983, 2.6509278504493587],
					[60.502413705438762, 2.6510750442667299],
					[60.50241370561158, 2.651075047885231],
					[60.502413706676734, 2.6510750513284984],
					[60.502413707741873, 2.6510750547717876],
					[60.502441507591513, 2.6511722548567578],
					[60.502441507677936, 2.651172256665999],
					[60.502441509721812, 2.6511722617433233],
					[60.502441510786937, 2.6511722651866032],
					[60.50248041103341, 2.6512861652552218],
					[60.502480412012133, 2.6512861668892618],
					[60.502480412098549, 2.6512861686985225],
					[60.502516505795874, 2.651386152787103],
					[60.502533102828366, 2.6514750376851213],
					[60.502533104958616, 2.6514750445717321],
					[60.50253311037067, 2.6514750635975033],
					[60.502533113393255, 2.6514750703088903],
					[60.502585913233986, 2.6516139712739615],
					[60.502585914299111, 2.651613974717268],
					[60.502585917321696, 2.6516139814286972],
					[60.502585919279113, 2.6516139846968079],
					[60.502649819405647, 2.651750085747091],
					[60.502649826256679, 2.6517500971855346],
					[60.502649841829736, 2.6517501215213084],
					[60.502649851444083, 2.6517501342434255],
					[60.50272485075395, 2.6518334335516003],
					[60.502724852711374, 2.6518334368197349],
					[60.50272485734574, 2.6518334395623024],
					[60.502724858324456, 2.6518334411963713],
					[60.502758158158365, 2.6518668410248134],
					[60.502758242411133, 2.6518668701386461],
					[60.502758399084108, 2.6518668503353116],
					[60.502758472396636, 2.6518668012429538],
					[60.502786271822643, 2.6518195005265826],
					[60.502786272714957, 2.6518195003513867],
					[60.502786273520869, 2.6518194983669239],
					[60.502786274413154, 2.6518194981917342],
					[60.50285847362143, 2.6516917982580237],
					[60.502858480155062, 2.6516917841915322],
					[60.502858490631851, 2.6516917583933894],
					[60.502858495294454, 2.6516917428680231],
					[60.502875195080335, 2.6516167457643522],
					[60.502891894443295, 2.6515472474672412],
					[60.502891895249192, 2.6515472454827678],
					[60.502891895249192, 2.6515472454827678],
					[60.502930794610947, 2.6513806460780338],
					[60.502972393834405, 2.6512083478255257],
					[60.502972394640295, 2.6512083458410216],
					[60.5029723945539, 2.6512083440317467],
					[60.502972395359791, 2.6512083420472625],
					[60.502983494978395, 2.6511583422959282],
					[60.502983495870701, 2.6511583421207128],
					[60.502983495697869, 2.6511583385021464],
					[60.502983497396087, 2.6511583363424425],
					[60.502991896718612, 2.6511139364501997],
					[60.502991896632203, 2.651113934640926],
					[60.5029918974381, 2.6511139326564348],
					[60.502991898243991, 2.651113930671944],
					[60.50301689841266, 2.6509472306457029],
					[60.503016898326237, 2.6509472288364293],
					[60.503036297628874, 2.6508167288908018],
					[60.503036297628874, 2.6508167288908018],
					[60.503036298348341, 2.6508167250970276],
					[60.503036298348341, 2.6508167250970276],
					[60.503052996948426, 2.6506833329375672],
					[60.503089096366267, 2.6505111414995763],
					[60.503097396115308, 2.650472241403298],
					[60.503097397381389, 2.650472230197146],
					[60.503097399107673, 2.6504722097693465],
					[60.503097400460177, 2.6504722003724717],
					[60.503097400363998, 2.6504333176342687],
					[60.503125196611627, 2.6502694330826806],
					[60.503125198136971, 2.6502694273043441],
					[60.503125199316628, 2.6502694142888918],
					[60.503125199949643, 2.6502694086858001],
					[60.503130799337576, 2.6501472246269229],
					[60.503158496054517, 2.6500083387081856],
					[60.503158496860422, 2.6500083367236389],
					[60.503158498212883, 2.6500083273267472],
					[60.50315849812646, 2.6500083255174465],
					[60.503177998315245, 2.6498528249231112],
					[60.503177999207544, 2.6498528247478625],
					[60.503186296645964, 2.6497833367822503],
					[60.503205794395249, 2.6497028463704311],
					[60.503205795201112, 2.6497028443858945],
					[60.503205795747697, 2.649702836973495],
					[60.503205797445858, 2.6497028348137022],
					[60.503227996836905, 2.6495750339599362],
					[60.503227997642782, 2.6495750319753948],
					[60.503227998189381, 2.6495750245629837],
					[60.503227998908805, 2.6495750207691606],
					[60.503239097745542, 2.6494722283416512],
					[60.503266895545579, 2.6493222378209311],
					[60.503300195917355, 2.6491528395823125],
					[60.503300196723231, 2.6491528375977529],
					[60.503300196550349, 2.6491528339791834],
					[60.503300197442677, 2.6491528338039068],
					[60.503308496328756, 2.6491028386408186],
					[60.503319595131991, 2.649055646492136],
					[60.503319595045554, 2.6490556446828486],
					[60.503319595764971, 2.6490556408889869],
					[60.503319595678541, 2.6490556390796995],
					[60.503352996298844, 2.6488861401922317],
					[60.503386294890277, 2.6487333421405652],
					[60.503405795176029, 2.6486472433433828],
					[60.50342519370944, 2.6485611461599929],
					[60.503461293870934, 2.6484194486571306],
					[60.503461293870934, 2.6484194486571306],
					[60.50346129378449, 2.6484194468478388],
					[60.503461294676796, 2.6484194466725439],
					[60.503494594603161, 2.6482778460325784],
					[60.503514093240078, 2.6481944516736182],
					[60.503539090820979, 2.6481111584856509],
					[60.503586291183758, 2.6479556584484611],
					[60.503639089656069, 2.6477806618547834],
					[60.503655788972864, 2.6477333673275769],
					[60.503655788886412, 2.6477333655182771],
					[60.50365578960578, 2.6477333617243666],
					[60.503655791303942, 2.6477333595644184],
					[60.503672389247008, 2.64768066447161],
					[60.503727988438527, 2.6475278685904615],
					[60.503727989244382, 2.6475278666058357],
					[60.503727990769605, 2.6475278608272683],
					[60.503727992381286, 2.6475278568580225],
					[60.503761288583952, 2.6474167666489237],
					[60.503814084082101, 2.6472861806507244],
					[60.503869581282594, 2.6471667845496918],
					[60.503869582174893, 2.6471667843743623],
					[60.503869582088434, 2.6471667825650353],
					[60.503869582088434, 2.6471667825650353],
					[60.503922381767936, 2.6470500839125193],
					[60.503975180947144, 2.6469361846895816],
					[60.504027975188123, 2.6468222970037214],
					[60.50408623568768, 2.6467334565581715],
					[60.504138985678722, 2.6467084801988765],
					[60.504138986571007, 2.6467084800235332],
					[60.504138990946046, 2.6467084773374809],
					[60.504138991838346, 2.6467084771621368],
					[60.50420836261528, 2.6466723923272357],
					[60.504263930097352, 2.6466640971294746],
					[60.504263937235763, 2.6466640957266967],
					[60.504263950620313, 2.6466640930964798],
					[60.504263957758738, 2.6466640916936965],
					[60.504319397299696, 2.6466474095302308],
					[60.504352702159515, 2.646658477842954],
					[60.504424873675113, 2.6467168555145246],
					[60.504424893191739, 2.6467168681162989],
					[60.504424933750187, 2.6467168875411478],
					[60.504424954791993, 2.646716894364209],
					[60.504497134272313, 2.6467334895557375],
					[60.504544297149572, 2.6467557733921994],
					[60.504616569109636, 2.6468084519214745],
					[60.504649858119926, 2.646841841565351],
					[60.504649882357484, 2.6468418587186231],
					[60.504649933163599, 2.6468418852617059],
					[60.504649960710928, 2.6468418962855451],
					[60.504677761075996, 2.6468473968332362],
					[60.504677762860602, 2.6468473964825447],
					[60.50467776740858, 2.6468473974151725],
					[60.504677770171973, 2.646847398698486],
					[60.504749949822489, 2.6468584950910832],
					[60.504802731028022, 2.6468779876536743],
					[60.504802738339379, 2.646877989869636],
					[60.504802753854428, 2.6468779941262173],
					[60.504802762058098, 2.6468779961668263],
					[60.504874962186136, 2.6468918957449126],
					[60.504874965841836, 2.6468918968529063],
					[60.504874974937806, 2.6468918987181769],
					[60.504874978593485, 2.6468918998261621],
					[60.504927776455681, 2.6468973992891875],
					[60.50498887303425, 2.6469057973753207],
					[60.504988880345621, 2.6469057995913103],
					[60.504988893010818, 2.6469058007552038],
					[60.504988900149229, 2.6469057993524339],
					[60.505047199571123, 2.6469057997451224],
					[60.505047213042147, 2.6469057989242954],
					[60.5050472381131, 2.646905795823983],
					[60.505047250605337, 2.6469057933691316],
					[60.505099979681752, 2.6468919121279177],
					[60.505152713639021, 2.6469168805485208],
					[60.505152719165771, 2.6469168831152015],
					[60.505152727369449, 2.6469168851558362],
					[60.505152732003886, 2.6469168878978802],
					[60.505213831567829, 2.6469390874157286],
					[60.505213834331201, 2.6469390886990736],
					[60.505213838879179, 2.6469390896317244],
					[60.505213840750287, 2.6469390910904109],
					[60.505286028440992, 2.6469612869756043],
					[60.505333198308023, 2.6469834734406654],
					[60.505394281250211, 2.6470279602308966],
					[60.505438772847377, 2.6470612552752475],
					[60.505466566245957, 2.6470862487432631],
					[60.505466571859188, 2.6470862531193897],
					[60.505466584064401, 2.6470862635057344],
					[60.505466589677646, 2.6470862678818605],
					[60.505547090411355, 2.6471390665775738],
					[60.505547095045799, 2.6471390693196604],
					[60.505547103422415, 2.647139074979195],
					[60.505547108056852, 2.6471390777212847],
					[60.50561100770193, 2.6471723764336512],
					[60.505611007788403, 2.6471723782430572],
					[60.505611009573009, 2.6471723778923693],
					[60.505611009659496, 2.6471723797017739],
					[60.505672109789643, 2.6472029794837293],
					[60.50567211344535, 2.647202980591763],
					[60.505672117993321, 2.64720298152448],
					[60.505672120843165, 2.6472029846172704],
					[60.505755504170104, 2.64723907565081],
					[60.505772088863473, 2.6472501667730897],
					[60.505772114712656, 2.6472501799577],
					[60.505772171332502, 2.647250196227747],
					[60.505772200318546, 2.6472501996638904],
					[60.505791686626132, 2.6472502004843741],
					[60.505836069558931, 2.6472557980703111],
					[60.505883261095001, 2.647264096609208],
					[60.505908256616607, 2.6472695954253731],
					[60.505908258401227, 2.6472695950746852],
					[60.50590825938, 2.6472695967087851],
					[60.5059888587882, 2.6472862961365178],
					[60.506041659418599, 2.647297395595869],
					[60.506041675739461, 2.6472973978679906],
					[60.506041709992878, 2.6472973984426975],
					[60.50604172711958, 2.6472973987300432],
					[60.506102798612375, 2.6472891017901694],
					[60.506141647230059, 2.647294594327795],
					[60.506222122834281, 2.6473279844572151],
					[60.506222151993263, 2.6473279915123342],
					[60.506222210771213, 2.6473279964000174],
					[60.506222241368924, 2.6473279958666676],
					[60.506302835334544, 2.6473112965071688],
					[60.506338930430722, 2.6473057980210655],
					[60.506338971936636, 2.6473057807326055],
					[60.506339042570289, 2.6473057321501052],
					[60.506339074288483, 2.6473056985205812],
					[60.50638347362726, 2.6472278993520892],
					[60.506383477742887, 2.6472278912375575],
					[60.506383485887703, 2.6472278731989993],
					[60.506383489024572, 2.6472278634503441],
					[60.506436289086892, 2.6470722649165563],
					[60.506455788428674, 2.647016770026239],
					[60.506480785656777, 2.6469528723780416],
					[60.506480787354924, 2.6469528702178757],
					[60.506480788966591, 2.6469528662482538],
					[60.506480789685938, 2.6469528624539738],
					[60.5065279903625, 2.6468111631599891],
					[60.506561288695643, 2.646711166745995],
					[60.506614086777688, 2.6465667703012929],
					[60.506669586094304, 2.6464250734743406],
					[60.506714085816363, 2.6463139742690092],
					[60.506714086622203, 2.6463139722841649],
					[60.506714086535702, 2.6463139704747061],
					[60.506775187286934, 2.6461528705601753],
					[60.506775188092746, 2.6461528685753279],
					[60.506775189617883, 2.6461528627961481],
					[60.506775191316009, 2.6461528606359233],
					[60.506794586885853, 2.6460917721953869],
					[60.506839080551231, 2.6459972850161173],
					[60.506839082249357, 2.6459972828558582],
					[60.506839083860982, 2.6459972788861492],
					[60.50683908466678, 2.6459972769012947],
					[60.506880783122156, 2.645897280444399],
					[60.506908479601513, 2.6458389873977839],
					[60.506972372815447, 2.6457112014052035],
					[60.507008464662412, 2.6456584139441666],
					[60.507008467972121, 2.645658407814151],
					[60.507008472979969, 2.6456583995238465],
					[60.507008476376193, 2.6456583952033101],
					[60.507027972966057, 2.6456222998339398],
					[60.507105764969978, 2.6454973132372088],
					[60.507166857759991, 2.6454168234700113],
					[60.507233450948917, 2.6453334311168932],
					[60.507314045791048, 2.6452473371009861],
					[60.507314050885356, 2.6452473306200495],
					[60.507314060268179, 2.645247319643067],
					[60.507314063577866, 2.6452473135129342],
					[60.507339048813485, 2.6452112362893145],
					[60.507375128594333, 2.6451807534421383],
					[60.507375131990514, 2.6451807491215038],
					[60.50737513806363, 2.6451807442746302],
					[60.507375140654027, 2.645180741938884],
					[60.50745293902844, 2.6451029431017155],
					[60.50752793590496, 2.6450334463397627],
					[60.507527936710773, 2.6450334443548398],
					[60.507527940279971, 2.6450334436531784],
					[60.507527941085769, 2.6450334416682395],
					[60.507564040538142, 2.6449973416740962],
					[60.507605739658558, 2.6449557424924319],
					[60.507672336067174, 2.6448918472783469],
					[60.507744532830195, 2.6448279503460128],
					[60.507744532743686, 2.6448279485364981],
					[60.507797333442092, 2.6447807488926682],
					[60.507797338536363, 2.6447807424115455],
					[60.507797350596043, 2.6447807309079803],
					[60.5077973565826, 2.6447807242514521],
					[60.5078390468001, 2.6447279368756953],
					[60.507902935033101, 2.644669548603845],
					[60.507902938429289, 2.6446695442830803],
					[60.507902945308167, 2.6446695374510587],
					[60.507902947812035, 2.6446695333057226],
					[60.507958446342052, 2.6446084361275539],
					[60.508011236198776, 2.6445529472358511],
					[60.508072327432451, 2.6445029550320127],
					[60.508072329130549, 2.6445029528716057],
					[60.508072335203615, 2.6445029480244817],
					[60.508072337794005, 2.6445029456886453],
					[60.508125130031829, 2.6444529520282249],
					[60.508172321681137, 2.6444168598201485],
					[60.508172323379206, 2.6444168576597211],
					[60.508172325077318, 2.6444168554993048],
					[60.508172326861903, 2.6444168551484291],
					[60.508233411070876, 2.644366867445703],
					[60.50831678629735, 2.6443223802634126],
					[60.508369468314683, 2.6443001877623806],
					[60.508441745610682, 2.6442779946016643],
					[60.508475026818353, 2.6442723988524688],
					[60.508502818731472, 2.6442695993722443],
					[60.508566705309583, 2.6442640994912732],
					[60.508636089293795, 2.6442668999772723],
					[60.508674975761146, 2.6442695998099377],
					[60.508708265203893, 2.6442751974995549],
					[60.508780562038488, 2.6442890953314344],
					[60.508780563017297, 2.6442890969655775],
					[60.508780564801881, 2.6442890966146884],
					[60.508827760289364, 2.6442973960307414],
					[60.508888856261038, 2.644311295238559],
					[60.508888857153323, 2.6443112950631176],
					[60.508888859024431, 2.6443112965218063],
					[60.508888860809016, 2.6443112961709181],
					[60.508944361184334, 2.6443223958597701],
					[60.508944364840055, 2.6443223969675675],
					[60.508944372064938, 2.6443223973735908],
					[60.508944375720638, 2.6443223984813899],
					[60.509013876080104, 2.6443307984655737],
					[60.509013876972411, 2.6443307982901234],
					[60.509061077337186, 2.6443362984922567],
					[60.509061080992893, 2.6443362996000621],
					[60.509061089110077, 2.6443362998306403],
					[60.509061092765776, 2.6443363009384435],
					[60.509138892672851, 2.6443390998702947],
					[60.50913889900545, 2.6443391004517816],
					[60.509138910691838, 2.6443390999805718],
					[60.5091389169379, 2.6443390987524498],
					[60.509205614294643, 2.6443334991558474],
					[60.509250012394467, 2.6443307990679861],
					[60.509250018727052, 2.6443307996494543],
					[60.509250033003823, 2.6443307968422958],
					[60.509250039249892, 2.6443307956141675],
					[60.509319439067973, 2.6443168959946322],
					[60.509319439960258, 2.6443168958191876],
					[60.509319440852565, 2.6443168956437368],
					[60.50937224069466, 2.6443057959818934],
					[60.509372248725327, 2.6443057944028561],
					[60.509372263807876, 2.6443057896106184],
					[60.509372271752049, 2.6443057862219725],
					[60.509444456920782, 2.6442779916637491],
					[60.509483341603286, 2.6442695951142756],
					[60.509483357664635, 2.6442695919561623],
					[60.509483387656694, 2.6442695787523749],
					[60.509483402652734, 2.6442695721504905],
					[60.509511198669898, 2.6442529733738422],
					[60.50957229423571, 2.6442195758314049],
					[60.50962509135411, 2.6441918778068798],
					[60.509697289279472, 2.6441557781577041],
					[60.509697291956385, 2.644155777631338],
					[60.509697296331346, 2.6441557749444367],
					[60.509697299008231, 2.6441557744180724],
					[60.509761183635135, 2.6441195812773759],
					[60.509822267239528, 2.6440973883588899],
					[60.509894452025875, 2.6440723932072316],
					[60.509922202697361, 2.6440669035004198],
					[60.509955568213769, 2.644072396653161],
					[60.509955570977169, 2.6440723979364185],
					[60.509955579094367, 2.6440723981669194],
					[60.509955582750074, 2.6440723992747213],
					[60.510022139009436, 2.6440779966304904],
					[60.51007489534782, 2.6441084704617355],
					[60.51014706882107, 2.6441557529398279],
					[60.510219244770425, 2.6442362275111195],
					[60.510260937985194, 2.6442918164751412],
					[60.51031923501273, 2.6443751138018823],
					[60.510363734318076, 2.644441811010382],
					[60.510363734404592, 2.644441812820062],
					[60.510363735296899, 2.6444418126446081],
					[60.510399834846545, 2.6444945125090182],
					[60.510399839654056, 2.6444945188704221],
					[60.510399849269128, 2.6444945315932569],
					[60.510399853990151, 2.6444945361449941],
					[60.510491553867539, 2.6445918365522716],
					[60.510491554846347, 2.6445918381864915],
					[60.510491557609754, 2.6445918394698116],
					[60.510491558588562, 2.6445918411040314],
					[60.5105637592957, 2.6446640413222244],
					[60.510563774351034, 2.6446640548021612],
					[60.510563805900283, 2.6446640741724141],
					[60.510563822653722, 2.64466408549178],
					[60.510597123448555, 2.6446779856855485],
					[60.510597151715331, 2.64467799291443],
					[60.510597209600988, 2.6446779979728894],
					[60.510597239219841, 2.6446779958024953],
					[60.510611138917305, 2.6446751951965246],
					[60.510611148732586, 2.6446751932666017],
					[60.510611169168946, 2.6446751874216288],
					[60.510611178005405, 2.6446751838574776],
					[60.510702878012438, 2.6446362833541119],
					[60.510702899081565, 2.6446362719045853],
					[60.510702937304579, 2.6446362424686032],
					[60.510702954544968, 2.6446362262918308],
					[60.510777954731672, 2.644544527725051],
					[60.510777958127832, 2.6445445234038578],
					[60.510777964027888, 2.6445445149369489],
					[60.510777965639463, 2.6445445109666559],
					[60.510811266018749, 2.6444945113792673],
					[60.510811274422664, 2.6444944987665866],
					[60.510811285703696, 2.644494470974561],
					[60.51081129223649, 2.6444944569031041],
					[60.510819587902027, 2.6444667678177205],
					[60.510844583734468, 2.6444083797462734],
					[60.510844583647959, 2.6444083779365863],
					[60.510844585346057, 2.64440837577598],
					[60.510844586151848, 2.6444083737908359],
					[60.51090298580452, 2.6442611734004444],
					[60.510902987416081, 2.6442611694301408],
					[60.510902989746903, 2.6442611616649772],
					[60.510902992250777, 2.6442611575192108],
					[60.510936291594156, 2.6441500578814678],
					[60.510936292313417, 2.6441500540866207],
					[60.510936293032685, 2.6441500502917523],
					[60.510936293838469, 2.6441500483065954],
					[60.510972391050828, 2.6440083616258843],
					[60.511033486134437, 2.6438528735591409],
					[60.511033486940214, 2.6438528715739706],
					[60.511033487659475, 2.6438528677791013],
					[60.511033489357551, 2.6438528656184586],
					[60.511064086900973, 2.643763970375423],
					[60.511116884680533, 2.6436361767543626],
					[60.511116886378595, 2.643636174593702],
					[60.511116887097842, 2.6436361707988216],
					[60.511116887903619, 2.6436361688136403],
					[60.511141882691867, 2.6435667828406855],
					[60.511169574869733, 2.6435167976442933],
					[60.511169574783196, 2.643516795834592],
					[60.511169575675488, 2.6435167956591075],
					[60.511169577373536, 2.6435167934984403],
					[60.511239077346218, 2.6433861946285391],
					[60.511239078871235, 2.6433861888484271],
					[60.511239083792375, 2.6433861787469688],
					[60.511239086209685, 2.6433861727913666],
					[60.511258477908314, 2.6433361939536146],
					[60.511283463596492, 2.6433001141433063],
					[60.511283465381084, 2.6433001137923271],
					[60.511283466186846, 2.6433001118071267],
					[60.511283466992609, 2.643300109821928],
					[60.511364061678208, 2.6431779174811019],
					[60.511427956086692, 2.6430973249748537],
					[60.511489048757724, 2.6430223341310453],
					[60.511561241047566, 2.6429501413881957],
					[60.511561245422492, 2.6429501387009648],
					[60.511561252214733, 2.6429501300580398],
					[60.511561254718544, 2.642950125912074],
					[60.511597346626282, 2.6429057377233636],
					[60.511641836626552, 2.6428640452832486],
					[60.511641839303444, 2.6428640447567333],
					[60.511641842699554, 2.6428640404352586],
					[60.511641845289901, 2.6428640380990021],
					[60.511702935445072, 2.6428001479549512],
					[60.511769523586828, 2.6427473569600064],
					[60.511769525371427, 2.6427473566089859],
					[60.511769526177176, 2.6427473546237463],
					[60.511769527069482, 2.6427473544482369],
					[60.511830726564469, 2.6426973549194859],
					[60.511830730852864, 2.6426973504224409],
					[60.511830741214261, 2.6426973410773611],
					[60.511830744696901, 2.6426973385655828],
					[60.511883433227368, 2.6426418501807523],
					[60.51193621921734, 2.6426029605446764],
					[60.511936222699966, 2.642602958032862],
					[60.511936232255614, 2.6426029506729742],
					[60.511936235651696, 2.6426029463514196],
					[60.511975133245535, 2.6425668487292406],
					[60.512055730681077, 2.6424973518302304],
					[60.51205573327141, 2.6424973494939259],
					[60.512055736667527, 2.6424973451723353],
					[60.512055739344412, 2.642497344645784],
					[60.512133439033398, 2.642422343346468],
					[60.512133441623746, 2.6424223410101533],
					[60.51213344671789, 2.642422334527752],
					[60.512133450200515, 2.6424223320159186],
					[60.512152936725258, 2.6424001470406511],
					[60.512189022463765, 2.6423723585451615],
					[60.512189029429038, 2.6423723535214525],
					[60.51218904229421, 2.6423723400300658],
					[60.512189048280661, 2.6423723333721401],
					[60.512269548270638, 2.6422834340704791],
					[60.512269549076386, 2.6422834320851933],
					[60.512269550860978, 2.6422834317341413],
					[60.512269551666741, 2.6422834297488542],
					[60.512347345097417, 2.642191838117856],
					[60.512386235685661, 2.6421557463525356],
					[60.512386241672061, 2.6421557396945166],
					[60.512386253644934, 2.6421557263784812],
					[60.512386259631356, 2.6421557197204764],
					[60.512419556826067, 2.6421112251944621],
					[60.512494552754774, 2.6420223295058771],
					[60.512494556956575, 2.6420223231988951],
					[60.512494563748753, 2.6420223145555162],
					[60.512494567144834, 2.6420223102338349],
					[60.512547367185839, 2.6419418105397083],
					[60.512547367099302, 2.6419418087299325],
					[60.512547367991594, 2.641941808554396],
					[60.512608468397346, 2.6418473081822422],
					[60.512608468397346, 2.6418473081822422],
					[60.512608469289646, 2.6418473080067004],
					[60.51260846920308, 2.6418473061969086],
					[60.512680767065902, 2.6417334104247439],
					[60.512733465845699, 2.6416557119114414],
					[60.512733465845699, 2.6416557119114414],
					[60.512733468349467, 2.641655707765219],
					[60.51273346924178, 2.6416557075896714],
					[60.512814069218635, 2.6415279068026987],
					[60.512814070024383, 2.6415279048173574],
					[60.512814071722417, 2.6415279026564704],
					[60.512814073420444, 2.6415279004955834],
					[60.512833472907317, 2.6414945002955483],
					[60.512852972680697, 2.641461200893104],
					[60.512852974378724, 2.641461198732209],
					[60.512852975990192, 2.6414611947615256],
					[60.512852976795926, 2.6414611927761662],
					[60.512919570617541, 2.6413334045921877],
					[60.51297796431394, 2.6412501140673346],
					[60.512977965206225, 2.6412501138917777],
					[60.512977966011974, 2.6412501119064205],
					[60.512977966817729, 2.6412501099210641],
					[60.513047363041778, 2.6411445171092707],
					[60.51312515494071, 2.6410445262807083],
					[60.513172351965011, 2.6409890299372107],
					[60.513172357059069, 2.6409890234543902],
					[60.513172367247208, 2.6409890104887261],
					[60.513172371362444, 2.6409890023716462],
					[60.513205761211744, 2.6409334202083645],
					[60.513272343711833, 2.6408612390455879],
					[60.513333440573689, 2.6408001407995974],
					[60.513333443250588, 2.6408001402728725],
					[60.513333445840892, 2.6408001379363211],
					[60.51333344664662, 2.6408001359509323],
					[60.513397347389279, 2.6407307344016391],
					[60.513397348281565, 2.6407307342260631],
					[60.513397349087285, 2.6407307322406695],
					[60.513397349979584, 2.6407307320650935],
					[60.513458450280396, 2.6406612327434908],
					[60.513502946142609, 2.6406112376211643],
					[60.513550141037697, 2.6405640416202614],
					[60.5135501462183, 2.6405640369471217],
					[60.513550153010343, 2.6405640283031504],
					[60.513550157298688, 2.6405640238055716],
					[60.513611251654815, 2.640486229177005],
					[60.51369184623389, 2.6404001375494199],
					[60.513691847931909, 2.640400135388413],
					[60.513691849543356, 2.6404001314175689],
					[60.513691852133668, 2.6404001290809771],
					[60.513725151685179, 2.6403612296823535],
					[60.513725152577464, 2.6403612295067651],
					[60.513755753296415, 2.6403251282673952],
					[60.513755753296415, 2.6403251282673952],
					[60.51375575499442, 2.6403251261063798],
					[60.513755755886706, 2.6403251259307909],
					[60.513836256131341, 2.6402251257362699],
					[60.513916856289413, 2.6401251254534746],
					[60.513916859598844, 2.6401251193215551],
					[60.513916868894604, 2.6401251065309106],
					[60.513916873902069, 2.6401250982379341],
					[60.513950167377018, 2.6400668107057408],
					[60.514002959042649, 2.6399973216648216],
					[60.514058459074981, 2.639925121722452],
					[60.514058459074981, 2.639925121722452],
					[60.51410295910307, 2.6398668205048486],
					[60.514102960801083, 2.6398668183437852],
					[60.514102964283659, 2.6398668158315108],
					[60.514102965089371, 2.6398668138460528],
					[60.514175163953595, 2.6397612142235634],
					[60.514247362362198, 2.639661217312272],
					[60.514247364865902, 2.6396612131657178],
					[60.514247370765617, 2.6396612046969725],
					[60.514247373269328, 2.6396612005504161],
					[60.514289066174854, 2.6395890130269364],
					[60.514355756274057, 2.6395057250366212],
					[60.51435575967006, 2.6395057207144026],
					[60.514355766375459, 2.6395057102601203],
					[60.514355768879163, 2.6395057061135367],
					[60.514389064713981, 2.6394529138001794],
					[60.514422355277503, 2.6394084275646641],
					[60.51451404869011, 2.6393057337837638],
					[60.51451405297837, 2.6393057292858972],
					[60.514514058878049, 2.639305720817021],
					[60.514514062274024, 2.6393057164947611],
					[60.514586256998363, 2.6392057236259308],
					[60.514622350751779, 2.6391640301938342],
					[60.514622351644078, 2.6391640300182058],
					[60.514622354234348, 2.6391640276814261],
					[60.514622354234348, 2.6391640276814261],
					[60.514661254067832, 2.639116827199671],
					[60.514727951185641, 2.6390362323555032],
					[60.514794546557255, 2.6389640355215405],
					[60.514794548255225, 2.6389640333603839],
					[60.514794550845522, 2.6389640310235865],
					[60.514794552543506, 2.638964028862429],
					[60.514855752586676, 2.6388918286210683],
					[60.51485575428466, 2.6388918264599002],
					[60.514855757767215, 2.6388918239474433],
					[60.514855759465213, 2.6388918217862773],
					[60.51491685928341, 2.63881122060286],
					[60.51495295459106, 2.6387640268490453],
					[60.514989050867598, 2.6387223319078297],
					[60.514989051673297, 2.6387223299222891],
					[60.514989055069236, 2.6387223255999239],
					[60.514989055961536, 2.6387223254242778],
					[60.515066853134982, 2.6386251291860843],
					[60.515158449319848, 2.6385223331452039],
					[60.51515845021212, 2.6385223329695586],
					[60.515158450125533, 2.6385223311596473],
					[60.515177942175804, 2.6385001404586368],
					[60.515205734260952, 2.6384751484011262],
					[60.515205736764621, 2.6384751442543535],
					[60.515205744535422, 2.6384751372437454],
					[60.515205748017962, 2.6384751347312321],
					[60.515286240084663, 2.6383862437067793],
					[60.515372331088727, 2.638311251257385],
					[60.515372331894426, 2.6383112492718079],
					[60.515372332786711, 2.638311249096152],
					[60.515372333678997, 2.6383112489204974],
					[60.515400113578032, 2.6382862662412681],
					[60.515438989276916, 2.6382668784182171],
					[60.515438996328626, 2.6382668752030258],
					[60.515439009453125, 2.6382668671383813],
					[60.515439015525921, 2.6382668622889116],
					[60.515505703406475, 2.6382195712069629],
					[60.515561188573514, 2.6381918782275244],
					[60.515561192142655, 2.6381918775248785],
					[60.515561195625196, 2.6381918750123039],
					[60.515561198302066, 2.6381918744853188],
					[60.51562508574397, 2.6381557802102646],
					[60.515697272136144, 2.6381279871381991],
					[60.515697272941829, 2.6381279851525985],
					[60.51569727561867, 2.6381279846256063],
					[60.515697278295541, 2.6381279840986154],
					[60.515750067660576, 2.6381057872639042],
					[60.515822258536829, 2.6380834905029045],
					[60.515822262105971, 2.6380834898002403],
					[60.515822267459697, 2.6380834887462443],
					[60.515822270049952, 2.6380834864092835],
					[60.515866765346821, 2.6380668886947429],
					[60.515938958890388, 2.6380445912237045],
					[60.515938959782687, 2.6380445910480352],
					[60.515938962459543, 2.6380445905210315],
					[60.5159389633518, 2.6380445903453671],
					[60.515955651384772, 2.6380390942273251],
					[60.51598334012008, 2.638033496858708],
					[60.515983342710349, 2.6380334945217321],
					[60.515983349848604, 2.6380334931163856],
					[60.515983353417774, 2.6380334924137081],
					[60.516063953334374, 2.6380112936860334],
					[60.516063955032372, 2.6380112915247333],
					[60.516063957709214, 2.6380112909977282],
					[60.516063960386084, 2.6380112904707151],
					[60.516108357774009, 2.6379973901869667],
					[60.516183349195757, 2.6379751937618821],
					[60.51623614095724, 2.6379640967918974],
					[60.516236146310952, 2.6379640957378627],
					[60.51623615603949, 2.6379640919954999],
					[60.516236159608646, 2.6379640912928095],
					[60.516288956203681, 2.6379473916764322],
					[60.516350044098132, 2.6379307952762625],
					[60.516413934766931, 2.6379195976275662],
					[60.516413939228357, 2.6379195967491924],
					[60.516413947172332, 2.6379195933581423],
					[60.51641395082806, 2.6379195944654223],
					[60.516466737715497, 2.6379057979053409],
					[60.516533324726907, 2.6378973981479552],
					[60.516533325619193, 2.6378973979722811],
					[60.516533327403764, 2.6378973976209275],
					[60.516594422374553, 2.6378890998225635],
					[60.516627807622484, 2.6378862996294137],
					[60.516680599853942, 2.6378862996960226],
					[60.516680601638498, 2.6378862993446703],
					[60.51668060618654, 2.6378863002762731],
					[60.516680607971111, 2.637886299924916],
					[60.516747188276753, 2.637883501269942],
					[60.516799967028021, 2.6378918974515373],
					[60.516863849947299, 2.6379029947740622],
					[60.516919329065246, 2.637922386255672],
					[60.516980514679702, 2.6379473815137549],
					[60.517058193565909, 2.6379890701810926],
					[60.51708878270545, 2.6380112612268061],
					[60.517088782792051, 2.6380112630368351],
					[60.517088784663223, 2.6380112644954981],
					[60.517088786447808, 2.6380112641441422],
					[60.517124876028369, 2.6380362584596115],
					[60.517197062682165, 2.6381001461549838],
					[60.517269255748573, 2.6381723387572982],
					[60.517310950106491, 2.638216832065873],
					[60.517349845557703, 2.6382640270053779],
					[60.517349847428875, 2.6382640284640622],
					[60.517349849386648, 2.6382640317327781],
					[60.517349850278919, 2.6382640315571089],
					[60.517430444839263, 2.6383557266152629],
					[60.517508141171518, 2.6384584217335854],
					[60.517508141171518, 2.6384584217335854],
					[60.517508142063797, 2.6384584215579201],
					[60.517535942418604, 2.6384945220607685],
					[60.51753594339749, 2.6384945236951434],
					[60.517535946247548, 2.6384945267882243],
					[60.51753594722642, 2.6384945284226],
					[60.517566547166147, 2.6385307282706192],
					[60.517566548145034, 2.6385307299049972],
					[60.51756654912392, 2.6385307315393725],
					[60.517566550016205, 2.6385307313637063],
					[60.517647045984567, 2.6386223282941708],
					[60.517708142747871, 2.6387001241598393],
					[60.517708147469087, 2.6387001287117013],
					[60.517708154148075, 2.6387001365323339],
					[60.517708158869276, 2.6387001410841759],
					[60.51776095869279, 2.6387529405601255],
					[60.517808159425698, 2.6388001406370507],
					[60.517808162275742, 2.6388001437301836],
					[60.517808167975836, 2.6388001499164675],
					[60.517808170825887, 2.6388001530096008],
					[60.517880471347162, 2.6388612531328373],
					[60.517880473218348, 2.6388612545915779],
					[60.517880477852977, 2.6388612573334016],
					[60.517880479810707, 2.6388612606022268],
					[60.517958168944368, 2.6389195516176653],
					[60.517977659150283, 2.6389390415167284],
					[60.517977675990771, 2.638939054645526],
					[60.517977713154316, 2.6389390783903992],
					[60.517977733390786, 2.638939087196412],
					[60.518016622692741, 2.6389529858628209],
					[60.518086013148825, 2.6389862806894673],
					[60.518086021352595, 2.6389862827286983],
					[60.518086035169837, 2.6389862891442228],
					[60.518086041589051, 2.6389862915347653],
					[60.518141642130608, 2.6390029921271649],
					[60.518141649355492, 2.6390029925319816],
					[60.51814166210729, 2.6390029955030245],
					[60.518141667547603, 2.6390029962591584],
					[60.518211068083261, 2.6390140969985358],
					[60.518211074415873, 2.6390140975790106],
					[60.518211086188792, 2.6390140989156405],
					[60.518211090736798, 2.6390140998474365],
					[60.518274990840482, 2.6390168992942602],
					[60.518274998957658, 2.639016899523424],
					[60.518275015105417, 2.6390168981716826],
					[60.518275024114885, 2.6390168982251869],
					[60.518322224442421, 2.6390112988764112],
					[60.518322230688433, 2.6390112976468063],
					[60.518322245857313, 2.6390112946606124],
					[60.518322253887909, 2.6390112930796827],
					[60.518391754370242, 2.6389918920174935],
					[60.518391757047098, 2.6389918914905146],
					[60.518391763293124, 2.6389918902608978],
					[60.518391765969994, 2.6389918897339184],
					[60.518447265853574, 2.6389723889852421],
					[60.51844727558214, 2.6389723852428846],
					[60.518447294060401, 2.6389723761237365],
					[60.518447302896696, 2.6389723725570375],
					[60.518516790962067, 2.6389307775749371],
					[60.518555678631209, 2.6389140847769106],
					[60.518555701397709, 2.6389140711591654],
					[60.518555740424951, 2.6389140397229931],
					[60.518555757664508, 2.6389140235390314],
					[60.518575150124946, 2.6388890327573402],
					[60.518661241143967, 2.6388029408261411],
					[60.51866124632452, 2.638802936151929],
					[60.518661255706697, 2.6388029251690561],
					[60.518661260800634, 2.6388029186847266],
					[60.518733461311434, 2.6387057184082869],
					[60.518733461311434, 2.6387057184082869],
					[60.518733463009418, 2.6387057162468377],
					[60.518733463815103, 2.6387057142610608],
					[60.518786263553871, 2.638630714405271],
					[60.518786266144133, 2.6386307120681454],
					[60.518786270345785, 2.6386307057594487],
					[60.518786271957154, 2.6386307017878661],
					[60.518866871734083, 2.6384945015583119],
					[60.518866874324353, 2.6384944992211663],
					[60.51886687674142, 2.6384944932637726],
					[60.51886687924506, 2.6384944891165172],
					[60.518902978903014, 2.6384222887364803],
					[60.518902980600998, 2.6384222865750053],
					[60.518902983823722, 2.6384222786318103],
					[60.518902984542812, 2.6384222748358952],
					[60.518966885408723, 2.6382639746507675],
					[60.518966886214393, 2.6382639726649635],
					[60.518966887020092, 2.6382639706791582],
					[60.51896688782579, 2.6382639686933373],
					[60.51900018815391, 2.6381722675466204],
					[60.51900018815391, 2.6381722675466204],
					[60.51905578698895, 2.6380167704027597],
					[60.519108486318537, 2.6378833733174192],
					[60.519108487843283, 2.6378833675356499],
					[60.51910849187167, 2.6378833576065235],
					[60.519108493396416, 2.6378833518247387],
					[60.519141892018453, 2.6377583574431709],
					[60.519194588100881, 2.637600068388358],
					[60.519214084686936, 2.6375500762522219],
					[60.519233483326779, 2.6375056799259107],
					[60.519233484938127, 2.6375056759542326],
					[60.519233488074192, 2.6375056662007315],
					[60.519233489685533, 2.6375056622290396],
					[60.519286288541039, 2.637344463229828],
					[60.519327983978343, 2.6372250803157948],
					[60.519394577432585, 2.6371000940625553],
					[60.519394578238256, 2.637100092076698],
					[60.519394579762981, 2.6371000862948226],
					[60.519394580568637, 2.6371000843089649],
					[60.519447380715334, 2.6369861843302771],
					[60.519500176011448, 2.6368722977058354],
					[60.519561266604939, 2.6367779105052387],
					[60.519641865087365, 2.6366584121364767],
					[60.519661261203353, 2.6366307195401943],
					[60.519680755786702, 2.6366057245888292],
					[60.519766855104429, 2.6365001251261191],
					[60.519839044099761, 2.6364112394413524],
					[60.51989453017994, 2.6363640515226954],
					[60.519894537950613, 2.6363640445105156],
					[60.51989455340528, 2.636364028675966],
					[60.519894560197038, 2.6363640200293639],
					[60.519927949984059, 2.6363195322099839],
					[60.520000138871175, 2.6362501441004507],
					[60.520000140569103, 2.636250141938786],
					[60.520000141461381, 2.6362501417630404],
					[60.520072340726252, 2.6361779409875892],
					[60.520072343403093, 2.6361779404603438],
					[60.520072345014391, 2.6361779364884987],
					[60.520072346798948, 2.6361779361370012],
					[60.520108438917866, 2.6361390441614976],
					[60.520152930848248, 2.6361001512526174],
					[60.520233430686126, 2.6360307513536911],
					[60.520233433362982, 2.6360307508264245],
					[60.520233434168631, 2.6360307488404695],
					[60.520314032099307, 2.6359584495022803],
					[60.520333430416137, 2.6359418523549607],
					[60.520352922263946, 2.635925158191275],
					[60.520439011057, 2.6358668662425999],
					[60.520502901341473, 2.6358251728753821],
					[60.520572291616979, 2.6357890764329679],
					[60.520572293401536, 2.6357890760814411],
					[60.520572294293821, 2.635789075905679],
					[60.520572295186092, 2.6357890757299169],
					[60.520644486830207, 2.6357501797513794],
					[60.520683366250509, 2.6357334889095085],
					[60.520755651735506, 2.6357140931778984],
					[60.520755652627791, 2.6357140930021328],
					[60.520755656196926, 2.6357140922990636],
					[60.520755657089197, 2.6357140921232989],
					[60.520783340127608, 2.6357057975362403],
					[60.520808314838654, 2.6357029993387551],
					[60.520888890184715, 2.6357001994701239],
					[60.520949973353417, 2.6357084972941567],
					[60.520949974245717, 2.6357084971183871],
					[60.52094997611691, 2.6357084985770713],
					[60.520997136708957, 2.6357140936615373],
					[60.521022102681329, 2.6357279748880553],
					[60.521022108208278, 2.6357279774538984],
					[60.521022117391013, 2.6357279811269123],
					[60.521022122025705, 2.6357279838685224],
					[60.521113796897453, 2.6357668746769196],
					[60.521166576233078, 2.6358084573002918],
					[60.521166578996578, 2.635808458583222],
					[60.521166583717893, 2.6358084631351062],
					[60.521166585589093, 2.6358084645938069],
					[60.521238768167258, 2.6358584517091868],
					[60.521299853448653, 2.6359251356988755],
					[60.521299854427575, 2.6359251373333681],
					[60.521299857277697, 2.6359251404265587],
					[60.521299859062253, 2.6359251400750288],
					[60.521347052426528, 2.6359723351464668],
					[60.521399841712345, 2.6360362224636131],
					[60.521452638533667, 2.6361084173158833],
					[60.52145263862031, 2.6361084191261495],
					[60.521452641383803, 2.6361084204091183],
					[60.521452642362718, 2.6361084220436068],
					[60.521499837962622, 2.6361695175794968],
					[60.521572032414959, 2.6362751091331118],
					[60.521633129032246, 2.636375104505396],
					[60.521633129924545, 2.6363751043296428],
					[60.521633130990082, 2.6363751077744282],
					[60.52169153103862, 2.636466806809032],
					[60.521691532017549, 2.6364668084435436],
					[60.521691532017549, 2.6364668084435436],
					[60.521769231851415, 2.6365862096433941],
					[60.521769232743686, 2.6365862094676449],
					[60.521769233722601, 2.6365862111021836],
					[60.521797030146629, 2.6366279052001369],
					[60.521824827734697, 2.636675101255511],
					[60.521824831650328, 2.6366751077936339],
					[60.521824841352789, 2.6366751223286551],
					[60.521824846160698, 2.6366751286910128],
					[60.521908136016826, 2.6367751161864832],
					[60.52194982933257, 2.6368445024336937],
					[60.521949832269293, 2.636844507337325],
					[60.521949838142746, 2.6368445171445622],
					[60.521949840992811, 2.6368445202378963],
					[60.522022040956301, 2.6369390215424668],
					[60.522022042914109, 2.6369390248115705],
					[60.522022047635389, 2.6369390293637189],
					[60.522022051377782, 2.6369390322813389],
					[60.522113746421248, 2.6370418279672387],
					[60.522133141824803, 2.6370668235172201],
					[60.522133148417275, 2.6370668295282322],
					[60.522133160796542, 2.6370668435362896],
					[60.522133167389015, 2.637066849547284],
					[60.522158161702173, 2.6370890447845139],
					[60.522247055716605, 2.6371807398696943],
					[60.522247059458977, 2.6371807427873639],
					[60.522247065072513, 2.6371807471638422],
					[60.522247067922635, 2.6371807502572424],
					[60.522338750862076, 2.637261235686561],
					[60.522352636703907, 2.6372807161805598],
					[60.522352642404073, 2.6372807223673931],
					[60.52235265291214, 2.6372807349167693],
					[60.52235265861232, 2.637280741103599],
					[60.522372059114161, 2.6373001405596024],
					[60.522372062050884, 2.637300145463318],
					[60.522372069535606, 2.637300151298688],
					[60.522372073277985, 2.6373001542163794],
					[60.52246377006194, 2.6373751517618236],
					[60.522544265988017, 2.6374473486563472],
					[60.522544270709268, 2.6374473532086409],
					[60.522544280065205, 2.6374473605029229],
					[60.522544284699869, 2.6374473632449131],
					[60.522591553090955, 2.6374807415389578],
					[60.522616532217839, 2.6375195074209659],
					[60.52261653711232, 2.637519515593941],
					[60.522616547707003, 2.6375195299538126],
					[60.522616554386062, 2.6375195377753213],
					[60.522697050739723, 2.6376057330368745],
					[60.522788737564724, 2.6377140182025447],
					[60.522822028776041, 2.6377668027950265],
					[60.522877621698868, 2.6378639924339438],
					[60.522913713796825, 2.6379417745811491],
					[60.522922010295979, 2.6379667622705565],
					[60.522922012427003, 2.6379667691604904],
					[60.522922017408057, 2.6379667791439472],
					[60.522922018646796, 2.6379667862096001],
					[60.522949816044985, 2.6380250791262707],
					[60.523005413027477, 2.6381667715094053],
					[60.52304151020153, 2.638263962141365],
					[60.523085907353611, 2.6384222534294097],
					[60.523085908332483, 2.638422255064055],
					[60.523085908419098, 2.638422256874418],
					[60.523085909397984, 2.6384222585090615],
					[60.523138707706003, 2.6385944581120078],
					[60.523152608056535, 2.6386417554323773],
					[60.523166506830385, 2.6386917503606138],
					[60.523210905798308, 2.6388694477196499],
					[60.523210905884909, 2.6388694495300089],
					[60.523210906863788, 2.6388694511646706],
					[60.523258106000938, 2.6390472463520402],
					[60.523269204183237, 2.6390972374976229],
					[60.523277603224528, 2.6391472338360926],
					[60.523277604290016, 2.6391472372811453],
					[60.523277604636377, 2.639147244522619],
					[60.523277605788479, 2.6391472497780124],
					[60.523322005653341, 2.6393222490104922],
					[60.523322005739942, 2.6393222508208551],
					[60.523322006718814, 2.6393222524555369],
					[60.523322007697686, 2.6393222540902213],
					[60.523369204800154, 2.6394889474494589],
					[60.52338310414887, 2.6395583391750059],
					[60.523383107431854, 2.6395583513205998],
					[60.523383114890088, 2.6395583754360712],
					[60.523383119957643, 2.6395583872303048],
					[60.52342201188943, 2.6396389707490711],
					[60.523455407357837, 2.6397639520835736],
					[60.523455407617583, 2.6397639575147207],
					[60.523455411792852, 2.6397639694846657],
					[60.523455413837183, 2.639763974564445],
					[60.52349421399623, 2.6398611732573785],
					[60.52349421710602, 2.6398611817822526],
					[60.523494223065832, 2.6398611934009009],
					[60.523494226981313, 2.6398611999397277],
					[60.523566524718909, 2.6399861980888981],
					[60.523602623935425, 2.6400528956170115],
					[60.523602632658651, 2.6400529085191211],
					[60.523602651889739, 2.6400529339719654],
					[60.523602663289836, 2.6400529463470681],
					[60.523647062636947, 2.640094545681531],
					[60.52364706922927, 2.6400945516934371],
					[60.523647080456207, 2.6400945604477779],
					[60.523647086069694, 2.6400945648249543],
					[60.523710986241014, 2.6401390647134821],
					[60.523711006563992, 2.6401390753319971],
					[60.523711053369418, 2.6401390935289379],
					[60.523711076109535, 2.6401390981892634],
					[60.523780575834195, 2.6401473998060743],
					[60.523780591089675, 2.6401473986301451],
					[60.523780619902652, 2.6401473984400106],
					[60.523780635158126, 2.6401473972640819],
					[60.523827835159643, 2.6401390972160801],
					[60.523827852918849, 2.6401390918923453],
					[60.523827889156358, 2.640139077448417],
					[60.523827906742369, 2.6401390685038733],
					[60.523880706841965, 2.6401057683661229],
					[60.523880711216833, 2.640105765677379],
					[60.523880719966563, 2.6401057602998805],
					[60.523880724341431, 2.6401057576111393],
					[60.52393342437967, 2.6400640561570135],
					[60.523933431344837, 2.6400640511308473],
					[60.523933445101953, 2.6400640374576967],
					[60.523933451088226, 2.6400640307967844],
					[60.523986250200522, 2.6400029313563755],
					[60.524052948729249, 2.6399279330664753],
					[60.524052951319533, 2.6399279307290269],
					[60.524052953909788, 2.6399279283915997],
					[60.524052954715501, 2.6399279264055147],
					[60.52413905503925, 2.6398223264054725],
					[60.524139061744648, 2.6398223159479595],
					[60.524139075155382, 2.6398222950329453],
					[60.524139080968496, 2.6398222847511112],
					[60.524158472006725, 2.6397807042040577],
					[60.524186258987619, 2.6397445227444893],
					[60.524186260599002, 2.6397445187722992],
					[60.524186264800704, 2.6397445124626291],
					[60.524186267390974, 2.6397445101251718],
					[60.524266866636289, 2.639622309651994],
					[60.524266868334287, 2.6396223074902037],
					[60.52426687083797, 2.6396223033422928],
					[60.524266871643682, 2.6396223013561868],
					[60.524319572231754, 2.6395334020709842],
					[60.524383471781107, 2.639425102675534],
					[60.524383472586806, 2.6394251006894174],
					[60.524383474284789, 2.6394250985276093],
					[60.524383474284789, 2.6394250985276093],
					[60.524444574447017, 2.639316799817935],
					[60.524497373317018, 2.6392251005396168],
					[60.524569570975025, 2.6391001037256716],
					[60.524625167774275, 2.6390140077511979],
					[60.524625170364544, 2.6390140054136495],
					[60.524625175371874, 2.6390139971176585],
					[60.524625176983271, 2.6390139931453747],
					[60.524697376663511, 2.6388778946987745],
					[60.52469737908055, 2.6388778887403297],
					[60.5246973840013, 2.6388778786338523],
					[60.52469738641836, 2.6388778726753892],
					[60.524730783109099, 2.638791781724418],
					[60.524802978094435, 2.6386500910507529],
					[60.52480298131718, 2.6386500831060875],
					[60.524802987043572, 2.6386500710133824],
					[60.524802988568332, 2.6386500652306148],
					[60.524836284092451, 2.6385528805919751],
					[60.52491127657435, 2.6384111935096959],
					[60.52491127907799, 2.6384111893616087],
					[60.524911282300735, 2.6384111814169082],
					[60.52491128391209, 2.638411177444544],
					[60.52495578423293, 2.6383056779999441],
					[60.524955785125201, 2.6383056778242224],
					[60.524955785844291, 2.6383056740275856],
					[60.524955785757676, 2.6383056722171321],
					[60.525005786208752, 2.6381778736188086],
					[60.525050179050019, 2.6380694907494933],
					[60.525122371170504, 2.637950102397264],
					[60.525122372062789, 2.6379501022215273],
					[60.525122371976188, 2.6379501004110639],
					[60.525177970125448, 2.6378557054071838],
					[60.525250160664974, 2.6377473199365222],
					[60.525275155142829, 2.6377168262261774],
					[60.525275157733077, 2.6377168238884745],
					[60.525275162740336, 2.6377168155921296],
					[60.52527516613624, 2.6377168112681981],
					[60.525291850751145, 2.637691832249629],
					[60.525375118181898, 2.637619562141666],
					[60.525444481849732, 2.637577984302776],
					[60.525508339005995, 2.6375584966910881],
					[60.525569396955511, 2.6375530001467835],
					[60.525616658009341, 2.6375584962707763],
					[60.525661027767001, 2.6375723874872095],
					[60.525722109168832, 2.6376001771078936],
					[60.525805494076359, 2.6376473704813459],
					[60.525841571336542, 2.637672354697485],
					[60.525877655297982, 2.6377084364602363],
					[60.525947050998774, 2.6377862323703938],
					[60.525947053848853, 2.6377862354641728],
					[60.525947059635634, 2.6377862434622483],
					[60.525947063377998, 2.6377862463802777],
					[60.526038739272003, 2.6378723230375249],
					[60.526058123897663, 2.6379083955442502],
					[60.526058130663344, 2.637908405177162],
					[60.526058145173579, 2.6379084260777224],
					[60.526058152831524, 2.6379084355348734],
					[60.526091546884736, 2.6379445285494021],
					[60.526172041033853, 2.6380501210173448],
					[60.526172043883932, 2.6380501241111616],
					[60.526172048691791, 2.6380501304745612],
					[60.526172051628485, 2.6380501353789008],
					[60.526255447592284, 2.638141830059566],
					[60.526291545398671, 2.6381862255701467],
					[60.526291546377578, 2.6381862272049506],
					[60.526291547356465, 2.638186228839742],
					[60.526291548335365, 2.638186230474532],
					[60.526324847758815, 2.6382251297270538],
					[60.526324854351245, 2.6382251357390127],
					[60.526324864772647, 2.63822514647965],
					[60.526324871365055, 2.638225152491612],
					[60.52639707084284, 2.6382862533116187],
					[60.526397080198734, 2.6382862606069475],
					[60.526397099716192, 2.6382862732113188],
					[60.526397110770048, 2.638286278344641],
					[60.526480510933936, 2.6383279793963101],
					[60.526480513697393, 2.6383279806796436],
					[60.526480521987786, 2.6383279845296594],
					[60.526480525643528, 2.6383279856372583],
					[60.526522126423131, 2.638344585603245],
					[60.526522142025023, 2.6383445916684689],
					[60.526522175645816, 2.6383445978400704],
					[60.526522192859055, 2.6383445999327235],
					[60.526597188664539, 2.6383474000906908],
					[60.526633285319349, 2.6383501982987276],
					[60.526633311455441, 2.6383501986340248],
					[60.526633364100249, 2.6383501882656017],
					[60.52663338891103, 2.638350179723894],
					[60.526650079952567, 2.638341882934478],
					[60.526730671191054, 2.6383112871086771],
					[60.526730675565865, 2.6383112844194181],
					[60.526730687078967, 2.6383112803242739],
					[60.526730693238349, 2.6383112772835555],
					[60.526794493234142, 2.6382779773592202],
					[60.526794501091487, 2.6382779721564384],
					[60.52679451769847, 2.6382779615751644],
					[60.526794527253784, 2.6382779542103467],
					[60.526855727073766, 2.6382279555541239],
					[60.526855727879436, 2.6382279535678208],
					[60.526855729577406, 2.6382279514057765],
					[60.526855731361962, 2.6382279510542981],
					[60.526936231465015, 2.6381584515389238],
					[60.526936235753205, 2.6381584470390647],
					[60.52693624611419, 2.6381584376878831],
					[60.526936250315785, 2.6381584313774584],
					[60.526955736613125, 2.6381362476519281],
					[60.526991822031441, 2.6381084588121535],
					[60.526991828996515, 2.6381084537850565],
					[60.526991841861104, 2.6381084402854618],
					[60.526991847847277, 2.638108433623521],
					[60.527072347546586, 2.6380195332311405],
					[60.527152942470842, 2.6379307415527471],
					[60.527186234794151, 2.6379001468981071],
					[60.527186242564881, 2.6379001398846049],
					[60.527186256235133, 2.6379001243984899],
					[60.527186263026941, 2.6379001157501616],
					[60.527214056940885, 2.6378612245916013],
					[60.527294549919063, 2.6377695315915162],
					[60.527294551617004, 2.6377695294294194],
					[60.527294556797479, 2.637769524753697],
					[60.527294559387727, 2.6377695224158422],
					[60.527358458666058, 2.637686222253496],
					[60.527358460364006, 2.6376862200913909],
					[60.527358461975346, 2.6376862161186798],
					[60.527358462867646, 2.6376862159429191],
					[60.527419563050664, 2.637600116711118],
					[60.527419563856341, 2.6376001147247639],
					[60.527419564661997, 2.6376001127383977],
					[60.527419566446561, 2.6376001123868731],
					[60.527500165844501, 2.6374807117487657],
					[60.527500170046068, 2.6374807054381382],
					[60.527500176664624, 2.6374806931684289],
					[60.527500180866163, 2.6374806868578204],
					[60.527527975791486, 2.6374222954117035],
					[60.52760017002575, 2.6373057047154269],
					[60.527600171723698, 2.6373057025532858],
					[60.527600175925258, 2.6373056962426142],
					[60.527600176644292, 2.6373056924456457],
					[60.527633476846127, 2.6372417913683077],
					[60.527633485708385, 2.6372417695181634],
					[60.527633497013682, 2.637241723427088],
					[60.527633500435627, 2.6372417008209839],
					[60.527633500060745, 2.637222245376408],
					[60.527661281385591, 2.6371639858873079],
					[60.527661282104653, 2.6371639820903252],
					[60.52766128532727, 2.6371639741448019],
					[60.527661286938589, 2.6371639701720464],
					[60.527714084835374, 2.637025076524095],
					[60.527752981949341, 2.6369389827604959],
					[60.527752984452938, 2.6369389786119353],
					[60.527752988481211, 2.6369389686799773],
					[60.527752990005901, 2.636938962896576],
					[60.527805788029518, 2.6367806676183272],
					[60.527858486761289, 2.6366444715689026],
					[60.527858489178257, 2.636644465609693],
					[60.527858492227594, 2.6366444540428442],
					[60.527858493752262, 2.6366444482594167],
					[60.527886292287526, 2.6365333552554371],
					[60.52792799140348, 2.6364028603288623],
					[60.527927992122493, 2.6364028565318214],
					[60.527927994452796, 2.6364028487619544],
					[60.52792799508515, 2.6364028431543161],
					[60.52794739364807, 2.6363167497230346],
					[60.527972391794627, 2.6362306568318408],
					[60.527972392600262, 2.6362306548454204],
					[60.527972394124916, 2.6362306490619498],
					[60.527972394038279, 2.6362306472513342],
					[60.528008494063684, 2.6360806493523627],
					[60.528052991806632, 2.635916756363569],
					[60.52807239108315, 2.6358556613271564],
					[60.528072393326781, 2.6358556517466134],
					[60.528072397095045, 2.6358556363825456],
					[60.52807239844639, 2.6358556269778028],
					[60.528080795945733, 2.6357944424716222],
					[60.528133491032918, 2.6356222586449003],
					[60.528133491032918, 2.6356222586449003],
					[60.528186290664124, 2.635447257235183],
					[60.528186293081021, 2.6354472512758136],
					[60.528186296130251, 2.6354472397087387],
					[60.528186296675948, 2.6354472322903955],
					[60.5281945942357, 2.635397250839878],
					[60.528214087600681, 2.6353444691454859],
					[60.528214089211936, 2.6353444651725764],
					[60.52821409064989, 2.635344457578396],
					[60.52821409315343, 2.6353444534296551],
					[60.528258492239978, 2.6351861576691551],
					[60.528300188657902, 2.6350528652499166],
					[60.528347387066418, 2.6349306717078553],
					[60.52834738697976, 2.6349306698972259],
					[60.52840018404838, 2.6347889803214395],
					[60.528447376983443, 2.6346944916028296],
					[60.528519575719756, 2.6345611957183559],
					[60.52851957741764, 2.6345611935560105],
					[60.528519579834487, 2.6345611875965136],
					[60.528519580640122, 2.6345611856100173],
					[60.528561275984664, 2.6344722941613461],
					[60.528633469866541, 2.6343557054929598],
					[60.528633470758813, 2.6343557053171005],
					[60.528633470672148, 2.6343557035064578],
					[60.528633472370032, 2.6343557013440924],
					[60.528694567057684, 2.6342529094905651],
					[60.528758457422299, 2.6341640236028723],
					[60.528830751470743, 2.6340807316153665],
					[60.52883075316862, 2.6340807294529722],
					[60.528830757370024, 2.6340807231416563],
					[60.528830759154566, 2.6340807227899186],
					[60.52885844486272, 2.6340445397083796],
					[60.528891825339024, 2.6340168553463963],
					[60.528952901811934, 2.6339695742638831],
					[60.529011178560914, 2.6339445841169002],
					[60.529011180345471, 2.6339445837651532],
					[60.529080665755892, 2.6339140887545383],
					[60.529144441223146, 2.6338973964276113],
					[60.529197215745029, 2.6338890996163444],
					[60.529244387855044, 2.6338890993245294],
					[60.529311054573839, 2.6338973964588104],
					[60.529369324823712, 2.6339168859713524],
					[60.529422112976185, 2.6339418793429314],
					[60.529510989010639, 2.6339862677795272],
					[60.529530469823904, 2.6340029520659716],
					[60.529530476329867, 2.6340029562669183],
					[60.529530489428424, 2.6340029664795459],
					[60.529530495042103, 2.6340029708563684],
					[60.529566582576564, 2.6340251624910729],
					[60.529635970904643, 2.6340834526552857],
					[60.529635971796928, 2.6340834524794077],
					[60.5296359718836, 2.6340834542901259],
					[60.529635972775871, 2.6340834541142506],
					[60.52969987332736, 2.6341362534602117],
					[60.52969987430631, 2.6341362550950569],
					[60.529699876177517, 2.6341362565540178],
					[60.529699878048753, 2.6341362580129717],
					[60.529760969402538, 2.6341834516019405],
					[60.529819261730054, 2.6342390456211189],
					[60.529819270999504, 2.6342390511052463],
					[60.529819289798361, 2.6342390675056673],
					[60.529819299960067, 2.6342390728139407],
					[60.529872077153811, 2.6342695598781103],
					[60.529899859322754, 2.6342973407396939],
					[60.529899866894297, 2.6342973483863252],
					[60.529899884714233, 2.6342973631519717],
					[60.529899894962597, 2.6342973702709904],
					[60.529980495423104, 2.6343473705176925],
					[60.529980501036761, 2.6343473748946518],
					[60.529980514854245, 2.6343473813102034],
					[60.529980522165793, 2.6343473835247044],
					[60.530052721908916, 2.6343779834322065],
					[60.53010552220578, 2.6344001845434541],
					[60.530105533173092, 2.6344001878652072],
					[60.530105554215467, 2.6344001946845923],
					[60.530105564203858, 2.6344001963714958],
					[60.53016666364573, 2.634411297342977],
					[60.53016666721485, 2.6344112966394957],
					[60.530166671762878, 2.6344112975708831],
					[60.530166675418656, 2.6344112986781343],
					[60.530211074745765, 2.6344168976745066],
					[60.530211088303261, 2.6344168986579448],
					[60.530211115331603, 2.6344168988140471],
					[60.530211127910135, 2.6344168981626241],
					[60.53025002840814, 2.63441129804617],
					[60.530250037330916, 2.6344112962874648],
					[60.530250053391917, 2.6344112931218016],
					[60.530250061335771, 2.6344112897282299],
					[60.530319461085838, 2.6343890895020121],
					[60.530319469116336, 2.6343890879191716],
					[60.530319485004007, 2.6343890811320039],
					[60.530319492947847, 2.634389077738422],
					[60.530377893060816, 2.6343584769880697],
					[60.530377901810255, 2.6343584716078428],
					[60.530377920201431, 2.6343584606715429],
					[60.530377928864219, 2.6343584534805693],
					[60.530430729052235, 2.6343140522581336],
					[60.530430733426961, 2.634314049568014],
					[60.530430742895355, 2.6343140403903962],
					[60.530430747183409, 2.6343140358895338],
					[60.53049734409759, 2.6342418371563578],
					[60.530544542060042, 2.6341945405155598],
					[60.53054454804596, 2.6341945338521606],
					[60.530544560823458, 2.6341945185387043],
					[60.530544566722725, 2.6341945100645332],
					[60.530597366636307, 2.6341140103403102],
					[60.53059736654965, 2.6341140085295551],
					[60.530644566845758, 2.6340418085343518],
					[60.530644570241513, 2.6340418042092928],
					[60.530644575335181, 2.6340417977217174],
					[60.530644576946386, 2.6340417937484402],
					[60.530677976755662, 2.6339778924681139],
					[60.530750176182295, 2.6338417959108522],
					[60.530802975085578, 2.6337472975432514],
					[60.530802978394661, 2.6337472914073699],
					[60.530802984926162, 2.6337472773248596],
					[60.530802988148572, 2.6337472693782158],
					[60.53085848292271, 2.6335972829030982],
					[60.530891874919668, 2.6335361953104752],
					[60.530891879121022, 2.6335361889986557],
					[60.530891884846888, 2.633536176902735],
					[60.530891888069284, 2.6335361689560459],
					[60.530919588218467, 2.6334611691528469],
					[60.530919588131795, 2.6334611673420758],
					[60.530964086839127, 2.6333389705591048],
					[60.530997385665778, 2.6332556740011581],
					[60.531055783860118, 2.6331083796725681],
					[60.531108482352685, 2.6329944832446821],
					[60.531108482265999, 2.6329944814339048],
					[60.531108483877176, 2.6329944774605165],
					[60.531108484682775, 2.6329944754738213],
					[60.531161282719225, 2.6328667805045223],
					[60.531216880734391, 2.6327472849274747],
					[60.531269578039115, 2.6326361914870913],
					[60.531330770791783, 2.6325279045919583],
					[60.531402967948445, 2.632416809549782],
					[60.531402970451886, 2.6324168054003758],
					[60.53140297456649, 2.6324167972775356],
					[60.531402977069916, 2.632416793128133],
					[60.531430756867444, 2.6323640317650598],
					[60.531483424077578, 2.6323223571326095],
					[60.531483429257833, 2.632322352455378],
					[60.53148343872607, 2.6323223432768676],
					[60.531483443906353, 2.6323223385996495],
					[60.531555735543243, 2.6322473468354755],
					[60.531636212791405, 2.6321807667014525],
					[60.531661196828516, 2.6321668743848621],
					[60.531661197720787, 2.632166874208921],
					[60.531661198613058, 2.6321668740329791],
					[60.531680693417826, 2.6321557768339265],
					[60.531750082141727, 2.6321223822315827],
					[60.531802867697849, 2.6321001872462313],
					[60.531875054274956, 2.6320779923543216],
					[60.53194174859253, 2.6320612947994806],
					[60.531941750377072, 2.632061294447587],
					[60.531941752074914, 2.632061292284873],
					[60.531941752967192, 2.632061292108923],
					[60.532002853006396, 2.6320445935488954],
					[60.532002859165615, 2.6320445905064331],
					[60.532002870678475, 2.6320445864082838],
					[60.532002877729944, 2.6320445831898631],
					[60.53205567308278, 2.6320223859436216],
					[60.532108369199236, 2.6320029884700809],
					[60.53220006790167, 2.6319695879852163],
					[60.532200081112386, 2.6319695817242681],
					[60.532200103964698, 2.6319695699061691],
					[60.532200116283114, 2.6319695638211789],
					[60.532219506323642, 2.6319557706391477],
					[60.532261194145242, 2.6319334766846074],
					[60.532261196735355, 2.6319334743458915],
					[60.532261202002303, 2.6319334714793348],
					[60.532261203786852, 2.6319334711274278],
					[60.532325103982302, 2.6318945716056579],
					[60.532325108270264, 2.6318945671041991],
					[60.532325115321754, 2.6318945638857021],
					[60.532325117911874, 2.6318945615469986],
					[60.532377918161558, 2.6318557614233704],
					[60.532377919859435, 2.6318557592605876],
					[60.532377921643956, 2.6318557589086815],
					[60.532377923428513, 2.6318557585567666],
					[60.532452923219672, 2.6317973573470992],
					[60.532452927594335, 2.631797354656459],
					[60.532452937062537, 2.6317973454775037],
					[60.532452941350485, 2.6317973409759983],
					[60.532530729253061, 2.6317195544022631],
					[60.532558415108006, 2.6317001637484223],
					[60.532558426360744, 2.6317001542175027],
					[60.53255844788724, 2.6317001335207357],
					[60.532558458161013, 2.6317001223548999],
					[60.532577952388209, 2.6316751303312227],
					[60.53266404537429, 2.6315834378280734],
					[60.532755742541113, 2.6314890382893106],
					[60.53275575200928, 2.6314890291101767],
					[60.53275576809542, 2.6314890076580788],
					[60.532755775605629, 2.6314889952091516],
					[60.532772355896036, 2.6314584319309287],
					[60.53281122279089, 2.6314279573508221],
					[60.532811228863387, 2.6314279524972917],
					[60.532811241008382, 2.6314279427902072],
					[60.532811246101886, 2.6314279363017792],
					[60.532891840738557, 2.6313418418984296],
					[60.532977937090315, 2.6312612463680547],
					[60.532977937895872, 2.6312612443812018],
					[60.532977939593714, 2.6312612422183705],
					[60.532977941291534, 2.6312612400555433],
					[60.533005740606967, 2.6312334413708398],
					[60.533025135326433, 2.631214047322449],
					[60.533102910654875, 2.631150167645858],
					[60.533177891531047, 2.6311112772337872],
					[60.533177892423303, 2.6311112770578071],
					[60.533177893315582, 2.6311112768818248],
					[60.533236180961865, 2.6310807824636324],
					[60.533311165368325, 2.6310529888030922],
					[60.533355644918409, 2.6310390957555834],
					[60.533427829551854, 2.6310279972208845],
					[60.533427831423111, 2.6310279986798148],
					[60.533463919037132, 2.6310223982192724],
					[60.533541707131342, 2.6310195995254557],
					[60.533541711679398, 2.6310196004564461],
					[60.533541722386666, 2.6310195983445985],
					[60.533541727740293, 2.6310195972886765],
					[60.533561104184024, 2.6310169016297027],
					[60.533588881210548, 2.6310195997326131],
					[60.533588882102812, 2.6310195995566286],
					[60.533588883887354, 2.6310195992046514],
					[60.53358888477964, 2.631019599028662],
					[60.533661085424313, 2.6310251998032101],
					[60.533661088101148, 2.6310251992752436],
					[60.533661096218275, 2.6310251995022718],
					[60.533661099874095, 2.6310252006092321],
					[60.533705586268752, 2.6310252007956043],
					[60.533766672622285, 2.6310334982658699],
					[60.533766677170341, 2.6310334991968523],
					[60.533766684395225, 2.6310334995998628],
					[60.533766688051031, 2.6310335007068284],
					[60.533813886289551, 2.6310363003454582],
					[60.533886085062484, 2.6310418997060832],
					[60.533886086847026, 2.6310418993541038],
					[60.533886087739297, 2.6310418991781148],
					[60.533930586385971, 2.6310446006165407],
					[60.533999983920836, 2.6310501986135684],
					[60.533999987576642, 2.6310501997205398],
					[60.533999995693797, 2.6310501999475679],
					[60.534000000155146, 2.6310501990676212],
					[60.534047200369791, 2.6310501996068756],
					[60.534119400331356, 2.6310501994655286],
					[60.534119402115927, 2.6310501991135422],
					[60.534119404879448, 2.6310502003965128],
					[60.534119405771705, 2.6310502002205238],
					[60.534205602757119, 2.6310474009152434],
					[60.534244400323566, 2.6310474007239053],
					[60.534244403892657, 2.6310474000199364],
					[60.534244411117534, 2.6310474004229549],
					[60.534244414686626, 2.6310473997189883],
					[60.534316714268151, 2.6310418993776548],
					[60.534363911909388, 2.6310390997948208],
					[60.534363912801652, 2.6310390996188295],
					[60.534363916370744, 2.6310390989148584],
					[60.534363917263015, 2.6310390987388672],
					[60.534430616910079, 2.6310334981883612],
					[60.534430620565857, 2.6310334992953495],
					[60.534430630380861, 2.6310334973594229],
					[60.534430634842195, 2.6310334964794602],
					[60.534477828624041, 2.6310251988000402],
					[60.534550017602882, 2.6310168994383187],
					[60.534594413198896, 2.6310141003847254],
					[60.534594416767987, 2.6310140996807476],
					[60.534594426582963, 2.6310140977448047],
					[60.534594431936597, 2.6310140966888342],
					[60.534663916379444, 2.6310029999535152],
					[60.534694399606593, 2.6310029998477682],
					[60.534694410400554, 2.6310029995467925],
					[60.534694430117248, 2.6310029974858526],
					[60.534694439039953, 2.6310029957258942],
					[60.534722233091436, 2.630997396490045],
					[60.534783327273068, 2.6309890971561471],
					[60.534783328252068, 2.6309890987911406],
					[60.534783330036596, 2.630989098439148],
					[60.534783330928875, 2.630989098263151],
					[60.534819426651303, 2.6309834976571884],
					[60.534888921693337, 2.6309751992914623],
					[60.534941720841047, 2.6309695981534165],
					[60.534941722712297, 2.6309695996124067],
					[60.534941728065931, 2.6309695985564119],
					[60.53494172985048, 2.6309695982044152],
					[60.535013929716122, 2.6309584975687756],
					[60.5350139332852, 2.6309584968647788],
					[60.535013938638819, 2.6309584958087817],
					[60.535013942207925, 2.6309584951047826],
					[60.535052839574618, 2.6309501955968537],
					[60.535125037840459, 2.6309362963524037],
					[60.53512503873273, 2.6309362961764013],
					[60.535125039625001, 2.6309362960004004],
					[60.53512504140955, 2.6309362956483988],
					[60.535177841150151, 2.6309251953190844],
					[60.535177842042422, 2.6309251951430825],
					[60.535177843826958, 2.630925194791081],
					[60.535238943640373, 2.630911294535824],
					[60.535238949886271, 2.6309112933038068],
					[60.535238960593531, 2.6309112911917776],
					[60.535238965860415, 2.6309112883247492],
					[60.535294457719303, 2.63089189104927],
					[60.535358350920497, 2.6308751947313294],
					[60.535358355295138, 2.6308751920402993],
					[60.535358363325578, 2.6308751904562615],
					[60.535358367700205, 2.6308751877652181],
					[60.535427868266247, 2.6308501879779298],
					[60.535427869158504, 2.630850187801927],
					[60.535427872640874, 2.6308501852868931],
					[60.535427873619859, 2.6308501869218999],
					[60.535483374400521, 2.6308279851315866],
					[60.535483377077341, 2.6308279846035658],
					[60.535483384128781, 2.6308279813845008],
					[60.535483386805602, 2.6308279808564787],
					[60.535552877119052, 2.6307945844534197],
					[60.535591767115335, 2.6307807888322863],
					[60.53559177327449, 2.6307807857892089],
					[60.535591783895022, 2.6307807818660853],
					[60.535591790054205, 2.6307807788230027],
					[60.535636190453438, 2.6307584794485828],
					[60.535697288931139, 2.6307279794061551],
					[60.535697290628981, 2.6307279772431036],
					[60.535697294198059, 2.6307279765390619],
					[60.535697295090316, 2.630727976363052],
					[60.535733395409828, 2.6307084768233269],
					[60.53573339621537, 2.6307084748362866],
					[60.535733398892191, 2.6307084743082507],
					[60.535733399697719, 2.6307084723212113],
					[60.535805699248513, 2.6306668740806045],
					[60.535869497430753, 2.6306307753278517],
					[60.535930695738948, 2.6305973757612713],
					[60.535930699221325, 2.6305973732461632],
					[60.535930706272737, 2.6305973700270013],
					[60.535930708862857, 2.6305973676879093],
					[60.536002887664623, 2.6305501821256803],
					[60.536036163327488, 2.6305390895008443],
					[60.536036183589545, 2.6305390800192696],
					[60.536036222961208, 2.6305390557990043],
					[60.53603624117855, 2.6305390412363314],
					[60.536055722670248, 2.6305195600594069],
					[60.536127895011994, 2.6304779768799254],
					[60.536183389905212, 2.6304501790320347],
					[60.536183391603032, 2.6304501768689179],
					[60.536183396956638, 2.6304501758127903],
					[60.536183400438993, 2.6304501732976511],
					[60.536255699581268, 2.6304084733541195],
					[60.53625570315036, 2.630408472650025],
					[60.536255708330522, 2.6304084679717561],
					[60.536255710920614, 2.630408465632629],
					[60.53632229859258, 2.6303640731837032],
					[60.536380685852293, 2.6303362802194599],
					[60.536380693796019, 2.6303362768241558],
					[60.536380711294512, 2.6303362660593579],
					[60.536380719151474, 2.6303362608529941],
					[60.536433414576962, 2.6302973638065801],
					[60.536475110717845, 2.6302695654307331],
					[60.536475116877007, 2.6302695623874621],
					[60.536475128129602, 2.630269552854795],
					[60.536475134288771, 2.6302695498115209],
					[60.536502921189964, 2.6302445594052553],
					[60.536594506927131, 2.6301862690532354],
					[60.536594513891842, 2.630186264022826],
					[60.536594526036701, 2.6301862543140602],
					[60.536594532109142, 2.6301862494596664],
					[60.536666831881341, 2.6301223502831732],
					[60.536666832686869, 2.630122348296061],
					[60.536666833579133, 2.6301223481200275],
					[60.536666835363675, 2.6301223477679576],
					[60.536700129468798, 2.6300918536414502],
					[60.536747321534399, 2.6300557582087025],
					[60.536747327606825, 2.6300557533542603],
					[60.536747341449505, 2.6300557414822396],
					[60.536747347435202, 2.6300557348167306],
					[60.536808441093115, 2.6299890421214949],
					[60.536889032691839, 2.6299168495930494],
					[60.53688903617418, 2.6299168470777934],
					[60.5368890395698, 2.6299168427514448],
					[60.536889042159878, 2.6299168404122129],
					[60.536933442174117, 2.6298723407895879],
					[60.536933446461987, 2.6298723362871681],
					[60.536933455037769, 2.629872327282361],
					[60.536933459325638, 2.6298723227799456],
					[60.53696955205529, 2.6298251310245093],
					[60.537041843745001, 2.6297501382063424],
					[60.537041844550536, 2.6297501362191973],
					[60.537041848119628, 2.6297501355150041],
					[60.537041848925163, 2.6297501335278586],
					[60.537114048572619, 2.6296695330882125],
					[60.537114050357147, 2.6296695327361141],
					[60.537114051162682, 2.6296695307489593],
					[60.537114052054939, 2.6296695305729121],
					[60.537147344148408, 2.6296307394430745],
					[60.537186235966757, 2.629594545505336],
					[60.5371862377513, 2.6295945451532288],
					[60.537186242039169, 2.6295945406507535],
					[60.53718624373699, 2.6295945384875399],
					[60.537258438048184, 2.6295195455316209],
					[60.537327930253817, 2.6294584515894557],
					[60.537380721704622, 2.6294140591515562],
					[60.537436214656147, 2.6293751648626489],
					[60.537436216353939, 2.6293751626994086],
					[60.53743621894403, 2.6293751603601034],
					[60.537436219836287, 2.6293751601840456],
					[60.53748070625916, 2.6293418709901655],
					[60.537525089756777, 2.6293195787791648],
					[60.537597284151381, 2.629283482119352],
					[60.537650065144334, 2.6292612896649317],
					[60.537711139245083, 2.629244596374642],
					[60.537777817869255, 2.6292362985540563],
					[60.537830599063753, 2.6292335000252032],
					[60.537874985439124, 2.6292362997312413],
					[60.537936070614478, 2.6292418979450045],
					[60.53799165101114, 2.6292529940243154],
					[60.538055535164574, 2.6292723882510955],
					[60.538127720791309, 2.6293001835202001],
					[60.53817490396947, 2.6293223761404536],
					[60.538233194681659, 2.6293584695722991],
					[60.538233199316494, 2.6293584723142911],
					[60.538233205822571, 2.6293584765153377],
					[60.538233210457392, 2.6293584792573337],
					[60.538272096798885, 2.6293779725000728],
					[60.538299886175587, 2.6293973646412638],
					[60.538299887067851, 2.6293973644651993],
					[60.538299889918136, 2.6293973675593527],
					[60.538299891702664, 2.6293973672072215],
					[60.538385984197419, 2.6294529622107849],
					[60.538460963867394, 2.629511247593753],
					[60.538494252682895, 2.629547336814881],
					[60.538494258296701, 2.6295473411920423],
					[60.538494268718807, 2.6295473519336201],
					[60.538494275224899, 2.6295473561347302],
					[60.53854987077446, 2.6295918529309268],
					[60.538605465776499, 2.6296418495179226],
					[60.538605467647763, 2.6296418509769883],
					[60.538605472282576, 2.6296418537190522],
					[60.53860547326159, 2.6296418553541909],
					[60.538666568763901, 2.6296918509446474],
					[60.538730458195403, 2.629750140792082],
					[60.538783152019427, 2.6298084331475571],
					[60.538783157806712, 2.629808441147206],
					[60.538783171884603, 2.6298084529959245],
					[60.538783179369695, 2.6298084588322612],
					[60.538830479012759, 2.6298445595361106],
					[60.538830479012759, 2.6298445595361106],
					[60.538830479905016, 2.6298445593600541],
					[60.538874876907002, 2.6298779583763805],
					[60.538944275410756, 2.6299334560998417],
					[60.538944279240042, 2.6299334608292511],
					[60.538944287617369, 2.6299334664895935],
					[60.538944293144461, 2.6299334690556675],
					[60.539027692577633, 2.6299862684539708],
					[60.539027693556619, 2.6299862700891259],
					[60.539027696320169, 2.6299862713721729],
					[60.539027697212425, 2.6299862711961195],
					[60.539069297008396, 2.6300112710094412],
					[60.539069305385738, 2.6300112766698196],
					[60.539069320987963, 2.6300112827329785],
					[60.539069329278561, 2.6300112865821279],
					[60.539127712582065, 2.6300334813378186],
					[60.539160999048619, 2.6300529727989788],
					[60.539161012866316, 2.6300529792142782],
					[60.539161041307217, 2.6300529900575875],
					[60.539161055038193, 2.6300529946616646],
					[60.539197155342251, 2.630061295529071],
					[60.539197161674849, 2.6300612961079142],
					[60.53919717523236, 2.6300612970895432],
					[60.539197181651701, 2.6300612994796233],
					[60.539288882098262, 2.6300695998948158],
					[60.539288890215417, 2.6300696001215496],
					[60.53928890644972, 2.6300696005750197],
					[60.539288913674589, 2.6300696009778117],
					[60.539366713838994, 2.6300640994051241],
					[60.539366719192607, 2.6300640983487926],
					[60.539366729986568, 2.6300640980473609],
					[60.539366735340181, 2.6300640969910289],
					[60.53941393533993, 2.6300557967626381],
					[60.539413946047155, 2.6300557946499699],
					[60.539413967374884, 2.6300557886133986],
					[60.53941397799538, 2.6300557846894943],
					[60.53946677825919, 2.6300334840619493],
					[60.539466783526073, 2.6300334811943573],
					[60.539466794952098, 2.6300334752831502],
					[60.539466801111267, 2.6300334722395133],
					[60.539500099629443, 2.6300140724241694],
					[60.53953899890633, 2.629991874392851],
					[60.539539004173186, 2.6299918715252621],
					[60.539539012835696, 2.6299918643309419],
					[60.539539017210281, 2.6299918616394136],
					[60.539619517449012, 2.6299334627835398],
					[60.539619520039068, 2.6299334604441151],
					[60.539619525219209, 2.629933455765249],
					[60.539619526917029, 2.6299334536018826],
					[60.539700127152386, 2.6298668546005115],
					[60.539700129742471, 2.6298668522610562],
					[60.539700134117069, 2.6298668495694959],
					[60.539700135814883, 2.6298668474061193],
					[60.539739035905328, 2.6298307478018548],
					[60.539739048682215, 2.6298307324821062],
					[60.539739072364725, 2.6298307003835224],
					[60.539739082378084, 2.6298306837807162],
					[60.539752982021405, 2.6298000822232788],
					[60.539752990733746, 2.6298000384516622],
					[60.539752989334062, 2.6297999527945302],
					[60.539752979222023, 2.6297999109090333],
					[60.539725179105638, 2.6297443107734093],
					[60.539725176168602, 2.6297443058678507],
					[60.539725169228845, 2.6297442926103058],
					[60.539725166291831, 2.6297442877047428],
					[60.539661267315843, 2.6296498915543203],
					[60.539627969234502, 2.6295970934303323],
					[60.539627965405224, 2.6295970887008813],
					[60.53962795765991, 2.629597077430724],
					[60.539627953830625, 2.6295970727012592],
					[60.539600154146008, 2.6295637715339955],
					[60.539600146574166, 2.6295637638863396],
					[60.539600132409504, 2.6295637502261955],
					[60.539600123945434, 2.6295637427546024],
					[60.53951952722165, 2.6294998445665296],
					[60.539447329390356, 2.6294387468319966],
					[60.539447326626807, 2.6294387455489847],
					[60.539447321013, 2.6294387411717235],
					[60.539447319141729, 2.629438739712636],
					[60.539394525442809, 2.6293998433558521],
					[60.539336230167223, 2.6293498486701505],
					[60.539336229188223, 2.6293498470349812],
					[60.539283428588227, 2.629305447165065],
					[60.539230735147029, 2.6292609531533597],
					[60.539169541188663, 2.6291998588648311],
					[60.539169537359363, 2.6291998541354795],
					[60.539169527023965, 2.6291998452050214],
					[60.539169522302416, 2.6291998406517592],
					[60.539086236309906, 2.6291359519302873],
					[60.53905845071732, 2.6291054681802759],
					[60.53903345464218, 2.629074872795913],
					[60.539033448941609, 2.6290748666075427],
					[60.539033435669189, 2.629074852771756],
					[60.539033429076333, 2.6290748467594867],
					[60.538944531999739, 2.6289998508667565],
					[60.538852935012365, 2.6289165519103421],
					[60.538852926634966, 2.6289165462502857],
					[60.538852910772469, 2.6289165347541026],
					[60.538852903287363, 2.628916528917959],
					[60.538825117940803, 2.628899836508495],
					[60.538805736642097, 2.6288831548890257],
					[60.538727943847178, 2.628802661200321],
					[60.538727938233343, 2.6288026568232441],
					[60.538727926832138, 2.6288026444467381],
					[60.538727921218317, 2.6288026400696589],
					[60.538636224954473, 2.6287331435491001],
					[60.53860013629324, 2.6287026536562155],
					[60.538566842093175, 2.6286692593182663],
					[60.538566834608048, 2.6286692534822498],
					[60.538566821422322, 2.6286692414580015],
					[60.538566813937202, 2.6286692356219659],
					[60.538494517694708, 2.6286192384562357],
					[60.538447322295241, 2.6285831412186118],
					[60.538447316768149, 2.6285831386527851],
					[60.538447309282994, 2.628583132816809],
					[60.538447304648187, 2.6285831300748876],
					[60.538375105344166, 2.6285387306504657],
					[60.53837510436513, 2.628538729015391],
					[60.538375100709324, 2.6285387279085803],
					[60.538375099730324, 2.6285387262734994],
					[60.538302900058426, 2.62849702749073],
					[60.53830289542357, 2.6284970247488593],
					[60.53830288526165, 2.6284970194411774],
					[60.538302879734559, 2.6284970168753903],
					[60.538258380068214, 2.628477617766559],
					[60.538258379089186, 2.6284776161314891],
					[60.538258378196936, 2.628477616307578],
					[60.538205677714359, 2.6284554167403775],
					[60.538205674950817, 2.6284554154574913],
					[60.538205669336968, 2.6284554110805574],
					[60.538205666660154, 2.6284554116088348],
					[60.538158367185311, 2.628438711558434],
					[60.538158356217856, 2.6284387082380603],
					[60.538158336154254, 2.6284387030562648],
					[60.53815832527355, 2.6284387015470481],
					[60.538136131216092, 2.6284359019513479],
					[60.538063938139501, 2.6284220031209125],
					[60.538063933591395, 2.6284220021902209],
					[60.538063927258797, 2.6284220016117041],
					[60.538063922710705, 2.6284220006810095],
					[60.538016722877742, 2.6284165011831613],
					[60.538016716545144, 2.6284165006046463],
					[60.538016705751197, 2.6284165009065732],
					[60.538016700310841, 2.6284165001519701],
					[60.537936100236003, 2.6284165007926075],
					[60.537936093903404, 2.6284165002140911],
					[60.537936082217165, 2.6284165006921243],
					[60.537936075884566, 2.6284165001136093],
					[60.537844375821081, 2.6284276004596721],
					[60.537844369575197, 2.6284276016923007],
					[60.537844358062507, 2.6284276057925986],
					[60.537844351729866, 2.6284276052140867],
					[60.537811052329495, 2.6284359063993077],
					[60.537811046083647, 2.6284359076319284],
					[60.537811036355478, 2.6284359113800404],
					[60.537811031001866, 2.6284359124365766],
					[60.53775823722745, 2.6284554104027156],
					[60.537711043914456, 2.6284692086539008],
					[60.537711033294002, 2.6284692125780835],
					[60.537711012945387, 2.6284692202503526],
					[60.537711003303968, 2.6284692258095901],
					[60.537686005294347, 2.6284831230466743],
					[60.53760550832412, 2.6285248219350579],
					[60.537605505647313, 2.628524822463314],
					[60.537605503057257, 2.628524824802696],
					[60.537605502251751, 2.6285248267899224],
					[60.537541601806247, 2.6285609265337109],
					[60.537541598237176, 2.628560927238043],
					[60.537541591272543, 2.628560932268956],
					[60.537541587790201, 2.6285609347844336],
					[60.537472087927021, 2.6286081343729006],
					[60.537472085336979, 2.6286081367122627],
					[60.537472080962395, 2.6286081394038074],
					[60.537472078372346, 2.6286081417431726],
					[60.53738877802963, 2.6286720413685702],
					[60.537388777224095, 2.6286720433557655],
					[60.537388773741796, 2.6286720458711947],
					[60.537388771957239, 2.6286720462233584],
					[60.537372072187829, 2.628685945508328],
					[60.53737206879228, 2.628685949834884],
					[60.537372061914368, 2.6286859566768577],
					[60.537372058432055, 2.6286859591922869],
					[60.537352658212015, 2.6287054592295287],
					[60.537352657319751, 2.6287054594056083],
					[60.537352655621959, 2.6287054615688765],
					[60.537266556783514, 2.6287942594511153],
					[60.537174861909364, 2.6288859545956376],
					[60.537147065561527, 2.6289109504573323],
					[60.537147057878094, 2.6289109592863795],
					[60.537147043403507, 2.628910976768438],
					[60.537147035720075, 2.6289109855975012],
					[60.537127641687796, 2.6289387769399588],
					[60.537041548424398, 2.6290387702432274],
					[60.537041546726606, 2.6290387724064574],
					[60.537041545834327, 2.6290387725825246],
					[60.536960953869723, 2.6291359612849421],
					[60.53692206356844, 2.6291720528438671],
					[60.536922056777243, 2.6291720614967464],
					[60.53692204400042, 2.6291720768153262],
					[60.536922037122487, 2.6291720836571142],
					[60.536894243400518, 2.6292109765690417],
					[60.536816548743026, 2.6292998685182796],
					[60.536816547937491, 2.6292998705054234],
					[60.536816546239685, 2.6292998726686263],
					[60.536816545434164, 2.6292998746557825],
					[60.536755457358829, 2.6293748585034034],
					[60.536699870850427, 2.6294220484288742],
					[60.536699869065892, 2.6294220487809792],
					[60.536699864778008, 2.6294220532834109],
					[60.536699862993487, 2.6294220536355146],
					[60.536655464180967, 2.6294637543551529],
					[60.536583172273623, 2.6295304451206212],
					[60.536494289897853, 2.6295970322700759],
					[60.536474901341755, 2.6296081265473354],
					[60.536474897772692, 2.6296081272515246],
					[60.536474891700273, 2.6296081321059868],
					[60.536474889023467, 2.6296081326341292],
					[60.536458200726855, 2.6296192261307696],
					[60.536377723031109, 2.62965811578289],
					[60.536330545764102, 2.6296748078575494],
					[60.536263867659372, 2.6296887019636155],
					[60.536216692008125, 2.6296941997322691],
					[60.536144413989348, 2.629691500125698],
					[60.536063935117483, 2.6296831022305072],
					[60.5360194568457, 2.6296720074136251],
					[60.535947264810019, 2.6296470111836499],
					[60.535947263831019, 2.6296470095486542],
					[60.535947260175206, 2.6296470084417694],
					[60.535947259282928, 2.6296470086178148],
					[60.535911170006131, 2.6296359114024548],
					[60.535866780655212, 2.6296165176340387],
					[60.535811182042785, 2.6295915183281777],
					[60.535811181956049, 2.62959151651715],
					[60.535811181063785, 2.6295915166931896],
					[60.535811180171514, 2.6295915168692323],
					[60.535766784892857, 2.6295720183499167],
					[60.535694495486538, 2.6295359248019117],
					[60.53564180032874, 2.6295054260734658],
					[60.535641795780649, 2.6295054251426673],
					[60.535641787403307, 2.629505419482963],
					[60.53564178285523, 2.629505418552164],
					[60.535580682800706, 2.6294776184080892],
					[60.535508383639481, 2.6294442181801752],
					[60.535508379091382, 2.6294442172493899],
					[60.53550837080077, 2.6294442134007374],
					[60.535508366165949, 2.6294442106589346],
					[60.535452866320725, 2.6294248102271638],
					[60.53545286453619, 2.6294248105792519],
					[60.535380665068324, 2.6293998112388572],
					[60.53538065588544, 2.6293998075662754],
					[60.535380637693109, 2.6293998038431559],
					[60.53538062770469, 2.6293998021576224],
					[60.535361135789266, 2.629397002702027],
					[60.53533614284234, 2.6293915043984919],
					[60.535336141950054, 2.6293915045745404],
					[60.535336139186519, 2.6293915032916702],
					[60.535336138294262, 2.6293915034677138],
					[60.535263938153271, 2.6293776047196005],
					[60.535263936282014, 2.629377603260671],
					[60.535263931733937, 2.6293776023298969],
					[60.535263929949416, 2.629377602681982],
					[60.535208330016424, 2.6293692018688599],
					[60.535208323683797, 2.6293692012901775],
					[60.53520831288985, 2.6293692015917172],
					[60.535208307362758, 2.6293691990259958],
					[60.535136106774324, 2.6293664994384245],
					[60.53513610231299, 2.6293665003186462],
					[60.53513608964775, 2.6293664991612822],
					[60.535136084294152, 2.6293665002175413],
					[60.535102792253319, 2.6293692004416345],
					[60.535022200332627, 2.6293692002917251],
					[60.535022194892292, 2.6293691995369985],
					[60.535022185969609, 2.6293692012974308],
					[60.535022182313782, 2.6293692001906224],
					[60.534930590763622, 2.6293776003803897],
					[60.53490559995997, 2.6293776006288936],
					[60.534886099771668, 2.6293775998246312],
					[60.534886097987105, 2.6293776001767188],
					[60.534886095310327, 2.6293776007048413],
					[60.534886092546785, 2.6293775994219897],
					[60.534805604120706, 2.6293803995387117],
					[60.534766714776104, 2.6293776009772873],
					[60.534697233204604, 2.6293720011852302],
					[60.534652860353091, 2.6293609082847542],
					[60.534580681186739, 2.6293331177733399],
					[60.534519497094472, 2.6293026242895072],
					[60.534447320849054, 2.6292581395062262],
					[60.534375139081234, 2.6291915565285859],
					[60.534347345267022, 2.62916376215292],
					[60.534319547856377, 2.6291331661143444],
					[60.534241852189339, 2.6290470693515933],
					[60.534169555442816, 2.6289581747596484],
					[60.53416955259253, 2.6289581716659205],
					[60.534169549742245, 2.6289581685721775],
					[60.534169547784245, 2.6289581653024006],
					[60.534114054875133, 2.6288970726512328],
					[60.534058460935576, 2.628822081383543],
					[60.534058457106291, 2.6288220766549437],
					[60.534058449447684, 2.6288220671977665],
					[60.534058444726121, 2.6288220626452325],
					[60.534005753773826, 2.6287665725916396],
					[60.533969562315036, 2.628716583509735],
					[60.533969559378001, 2.6287165786051259],
					[60.533969552698402, 2.6287165707828675],
					[60.533969549848116, 2.6287165676891608],
					[60.533886252612142, 2.6286220704110788],
					[60.533805756072709, 2.6285220743211397],
					[60.533805747608582, 2.6285220668510725],
					[60.533805733357127, 2.6285220513827645],
					[60.533805724892972, 2.6285220439127182],
					[60.533777944755144, 2.6284998599151654],
					[60.533761259654526, 2.6284776803608785],
					[60.533761255825219, 2.6284776756323645],
					[60.533761248079841, 2.6284776643644401],
					[60.533761244337306, 2.6284776614468317],
					[60.533672344344218, 2.6283859617916088],
					[60.533672340601662, 2.6283859588740373],
					[60.533672336772348, 2.6283859541455468],
					[60.533672334901063, 2.6283859526867612],
					[60.533580735167739, 2.6283026522021653],
					[60.533580732317454, 2.6283026491085484],
					[60.533580726790341, 2.628302646543105],
					[60.533580724832326, 2.6283026432734267],
					[60.533552934327297, 2.628280450680323],
					[60.533536240957666, 2.6282637588977318],
					[60.533536235257067, 2.6282637527105153],
					[60.533536221179077, 2.6282637408642411],
					[60.533536212801678, 2.6282637352052016],
					[60.533447315843802, 2.6282026370720102],
					[60.533383418934093, 2.6281554383178483],
					[60.533383417149558, 2.6281554386699773],
					[60.533383414299252, 2.6281554355763972],
					[60.533383413406973, 2.6281554357524617],
					[60.533322312597072, 2.6281137343115613],
					[60.533322307962223, 2.6281137315701022],
					[60.533322296821275, 2.6281137246284429],
					[60.533322292186448, 2.6281137218870003],
					[60.533236209169225, 2.6280692305478843],
					[60.533222325128406, 2.6280581432097767],
					[60.533222313008444, 2.6280581346333416],
					[60.533222287157464, 2.628058121454369],
					[60.53322227423196, 2.6280581148648872],
					[60.533194473680652, 2.6280470133327625],
					[60.533194471896131, 2.628047013684895],
					[60.533194468240303, 2.6280470125782847],
					[60.533194464584469, 2.6280470114716734],
					[60.533122264661067, 2.628022011224012],
					[60.533122259133975, 2.628022008658645],
					[60.53312224727425, 2.6280220055148913],
					[60.533122240854901, 2.628022003125591],
					[60.533069446028719, 2.6280109053854059],
					[60.533005651063867, 2.6279942074972746],
					[60.533005641075441, 2.6279942058122692],
					[60.533005621904081, 2.6279942004553143],
					[60.533005612894669, 2.6279942004051131],
					[60.532963912545334, 2.6279914993894753],
					[60.532963909868528, 2.6279914999176763],
					[60.532963902643644, 2.6279914995153417],
					[60.532963899966852, 2.6279915000435414],
					[60.532891699756718, 2.6279914996116611],
					[60.53289169529539, 2.6279915004919885],
					[60.532891686285986, 2.627991500441786],
					[60.532891680932373, 2.6279915014981814],
					[60.532805580616234, 2.6279998009281162],
					[60.532805572585843, 2.6279998025127003],
					[60.532805557417333, 2.6279998055058025],
					[60.532805549386929, 2.6279998070903909],
					[60.532752748747335, 2.6280137063354729],
					[60.532752745265029, 2.6280137088505975],
					[60.532752736342353, 2.628013710611242],
					[60.53275273188104, 2.62801371149156],
					[60.532683231724029, 2.6280387112948604],
					[60.532683229133973, 2.6280387136339032],
					[60.532683222082596, 2.6280387168532551],
					[60.53268321940579, 2.628038717381445],
					[60.532658219925665, 2.6280498178722747],
					[60.532619320600482, 2.6280665163462813],
					[60.532619313549091, 2.6280665195656239],
					[60.532619300425331, 2.6280665276391089],
					[60.532619293373969, 2.6280665308584465],
					[60.532558196916042, 2.6281054281884493],
					[60.532499900878264, 2.6281387257956559],
					[60.532499895698137, 2.6281387304736934],
					[60.532499885164455, 2.6281387362080952],
					[60.532499879984378, 2.6281387408861265],
					[60.532433179815563, 2.6281887407059168],
					[60.532433178923299, 2.6281887408819742],
					[60.532352678554915, 2.6282498409344761],
					[60.532352677662658, 2.6282498411105308],
					[60.532352675964887, 2.6282498432734704],
					[60.532352675072616, 2.6282498434495243],
					[60.53232487739961, 2.6282720430305395],
					[60.532288778592395, 2.6282998398033013],
					[60.532213780962238, 2.6283554390611763],
					[60.532213780069959, 2.6283554392372301],
					[60.532213779264445, 2.6283554412241052],
					[60.532213778372167, 2.6283554414001586],
					[60.532152682239754, 2.6284026382445891],
					[60.532080485813168, 2.6284526355580602],
					[60.532080485007633, 2.6284526375449428],
					[60.532080482330834, 2.6284526380730844],
					[60.532080479740777, 2.6284526404120445],
					[60.53200267963075, 2.6285109396675668],
					[60.53200267704068, 2.6285109420065149],
					[60.532002671860575, 2.6285109466844103],
					[60.532002670162768, 2.628510948847318],
					[60.531983178722989, 2.6285276393888362],
					[60.53196659316977, 2.6285387316018021],
					[60.531883198248686, 2.6285887277642983],
					[60.531822100945519, 2.6286248265565439],
					[60.531761016006385, 2.628658118739033],
					[60.531688827778105, 2.6286859131837805],
					[60.531688826885841, 2.6286859133598188],
					[60.531688824295777, 2.6286859156987452],
					[60.531688821618985, 2.6286859162268614],
					[60.531636028424174, 2.6287081128131642],
					[60.531563845681653, 2.6287331074131011],
					[60.531524957617314, 2.6287415053252028],
					[60.53152494958691, 2.6287415069095204],
					[60.531524933612829, 2.628741511888947],
					[60.53152492558241, 2.6287415134732663],
					[60.531483234590219, 2.6287581104607809],
					[60.531436046931006, 2.6287720062511526],
					[60.531402753216092, 2.6287804062541764],
					[60.531330557427481, 2.6287970057124306],
					[60.531277758603714, 2.6288081039963695],
					[60.531211059236057, 2.6288220030991138],
					[60.531158262366603, 2.6288331045586526],
					[60.531097163569719, 2.6288442023721208],
					[60.531097162677447, 2.6288442025481511],
					[60.531097161871926, 2.6288442045349432],
					[60.53103886818711, 2.6288554032791898],
					[60.53097777310569, 2.6288637017892427],
					[60.530977772213433, 2.6288637019652707],
					[60.530977770428898, 2.628863702317326],
					[60.530977768644377, 2.62886370266938],
					[60.530924970852894, 2.6288720020140639],
					[60.530861074037432, 2.6288804016935958],
					[60.530861073145168, 2.6288804018696221],
					[60.53086107046834, 2.6288804023977073],
					[60.530861068683819, 2.6288804027497581],
					[60.530808275351419, 2.6288887011452715],
					[60.530747183092956, 2.6288942004958105],
					[60.53068058340093, 2.6288998011005638],
					[60.530680580724137, 2.6288998016286369],
					[60.530680576176032, 2.6288998006980258],
					[60.530680572606968, 2.6288998014021216],
					[60.530638881741488, 2.6289054012900421],
					[60.5305749919657, 2.6289081001696637],
					[60.530574991073436, 2.6289081003456869],
					[60.530574990181179, 2.6289081005217096],
					[60.530574989288908, 2.6289081006977337],
					[60.530522194606462, 2.6289108992209997],
					[60.530455599589637, 2.6289109001185134],
					[60.530455596912816, 2.6289109006465856],
					[60.530455589687961, 2.6289109002440387],
					[60.53045558701114, 2.6289109007721105],
					[60.530413892553021, 2.6289136992178586],
					[60.530369399774344, 2.6289136992653437],
					[60.530308299920549, 2.6289136999863381],
					[60.530261099655981, 2.6289137004153917],
					[60.530197200350727, 2.6289136995501878],
					[60.53014440046482, 2.6289137006191345],
					[60.530083299631222, 2.6289136996789542],
					[60.530036100258286, 2.6289136999115001],
					[60.529975004864646, 2.6289136997131561],
					[60.529919410140565, 2.628910899893794],
					[60.529919408356029, 2.6289109002458302],
					[60.529919402915681, 2.6289108994912476],
					[60.529919400238889, 2.6289109000193056],
					[60.529858304844893, 2.628910899812495],
					[60.529805611265452, 2.6289080997874716],
					[60.529805610373181, 2.6289080999634908],
					[60.529805608588646, 2.6289081003155257],
					[60.529805607696375, 2.628908100491544],
					[60.529738916624922, 2.6289054004815369],
					[60.529694424955004, 2.6288998017134584],
					[60.529694421299197, 2.6288998006068414],
					[60.529694413182042, 2.6288998003803186],
					[60.529694408633951, 2.6288997994497265],
					[60.529633313305141, 2.6288969990408164],
					[60.529566718812546, 2.6288915017494792],
					[60.529513926281346, 2.6288859028205844],
					[60.529461131274473, 2.6288776010969102],
					[60.529461130382188, 2.6288776012729276],
					[60.529461127705382, 2.6288776018009807],
					[60.529461126813111, 2.6288776019769977],
					[60.529400038926383, 2.6288692034633034],
					[60.529336149812146, 2.6288526061607742],
					[60.529336145264082, 2.6288526052301893],
					[60.529336135275642, 2.6288526035450448],
					[60.529336129835286, 2.6288526027904804],
					[60.529280637020783, 2.6288442027586321],
					[60.529208347134606, 2.6288276067985454],
					[60.52916394858579, 2.6288165065050006],
					[60.529163946714519, 2.6288165050463776],
					[60.529163943145427, 2.6288165057504451],
					[60.529163941274163, 2.6288165042918212],
					[60.529083365557788, 2.6287998084206126],
					[60.529066789296714, 2.6287915214385285],
					[60.529066779134801, 2.6287915161320927],
					[60.529066757199942, 2.6287915094925665],
					[60.529066745253509, 2.6287915045381607],
					[60.529030645405747, 2.6287831045370633],
					[60.529030643621226, 2.6287831048890919],
					[60.529030641836691, 2.6287831052411268],
					[60.529030640857691, 2.6287831036064935],
					[60.528977840644302, 2.6287720048530763],
					[60.528977839665302, 2.6287720032184452],
					[60.528977836009496, 2.6287720021118632],
					[60.528977835117239, 2.6287720022878811],
					[60.5289306353529, 2.6287637028616277],
					[60.528930633568365, 2.6287637032136613],
					[60.528930630804822, 2.6287637019310668],
					[60.528930629912551, 2.6287637021070842],
					[60.528858330400993, 2.6287526025798957],
					[60.528858325852902, 2.6287526016493388],
					[60.528858317735761, 2.6287526014228457],
					[60.528858313187669, 2.628752600492287],
					[60.528816712607295, 2.6287498002354006],
					[60.528816711715024, 2.6287498004114163],
					[60.528816708059217, 2.6287497993048428],
					[60.528816706274696, 2.6287497996568718],
					[60.528725006068811, 2.6287469998012378],
					[60.528725005176554, 2.6287469999772521],
					[60.528725001607484, 2.6287470006813125],
					[60.528724999736198, 2.6287469992227148],
					[60.528652799656015, 2.6287470001353066],
					[60.52865279421566, 2.6287469993807662],
					[60.528652781723906, 2.6287470018449732],
					[60.528652776283579, 2.6287470010904319],
					[60.528605575890296, 2.6287526018269709],
					[60.528605574998046, 2.6287526020029821],
					[60.52853887493302, 2.6287609015110918],
					[60.528538873148499, 2.6287609018631146],
					[60.528538867794865, 2.6287609029192009],
					[60.52853886511808, 2.6287609034472377],
					[60.528491665241894, 2.6287692035907377],
					[60.528491664349602, 2.6287692037667525],
					[60.528491660780553, 2.6287692044708009],
					[60.528491658996003, 2.6287692048228282],
					[60.528411065389953, 2.6287859035866239],
					[60.528391671376376, 2.6287887021316214],
					[60.52839165620783, 2.6287887051238172],
					[60.52839162604419, 2.6287887147294384],
					[60.528391611049102, 2.6287887213428793],
					[60.528374924713738, 2.6287970135960355],
					[60.528294341215755, 2.6288220080675391],
					[60.528294334164364, 2.6288220112862248],
					[60.528294321759333, 2.6288220155609761],
					[60.528294315600206, 2.6288220186036506],
					[60.528258229847637, 2.6288387123350794],
					[60.528180544960151, 2.6288609086597639],
					[60.528180542283344, 2.628860909187789],
					[60.528180536037461, 2.628860910419847],
					[60.528180533360654, 2.6288609109478713],
					[60.52813323684552, 2.6288776099373301],
					[60.528061043630373, 2.6288998084493795],
					[60.527999946533811, 2.6289165062783444],
					[60.52799994483599, 2.6289165084409465],
					[60.527999942159212, 2.6289165089689601],
					[60.52799994037467, 2.628916509320971],
					[60.527947147396723, 2.6289331061455559],
					[60.527874954615015, 2.6289498047542081],
					[60.527874949348146, 2.6289498076208142],
					[60.527874938640913, 2.6289498097328576],
					[60.527874933287322, 2.628949810788872],
					[60.527827743889851, 2.628966508435433],
					[60.527755555253655, 2.628983105549533],
					[60.527755554361399, 2.628983105725534],
					[60.527755550792307, 2.6289831064295441],
					[60.527755549007786, 2.628983106781543],
					[60.527702749345181, 2.6289970069984423],
					[60.52770274845291, 2.6289970071744446],
					[60.527630547556932, 2.629016507088008],
					[60.527630544880125, 2.6290165076160088],
					[60.527630540418777, 2.6290165084960093],
					[60.527630536849699, 2.6290165092000093],
					[60.527613839226298, 2.6290220088147036],
					[60.52757774483181, 2.6290331072694846],
					[60.52750274991628, 2.6290526061647053],
					[60.527502746347174, 2.6290526068687026],
					[60.527502740101319, 2.6290526081006851],
					[60.527502736618956, 2.6290526106152452],
					[60.527469337128345, 2.6290637092801221],
					[60.527469332753718, 2.6290637119706899],
					[60.527469325615563, 2.6290637133786725],
					[60.527469322133221, 2.6290637158932229],
					[60.527397122185043, 2.6290942160348059],
					[60.527397117723687, 2.629094216914786],
					[60.527397110758997, 2.6290942219438906],
					[60.52739710718992, 2.6290942226478755],
					[60.527344308619021, 2.6291220216740818],
					[60.527272111181674, 2.6291581210079245],
					[60.527272107612596, 2.6291581217118978],
					[60.527272103237983, 2.6291581244024167],
					[60.527272099755628, 2.629158126916959],
					[60.527199901263224, 2.6291998262286338],
					[60.527155501815116, 2.6292248259653896],
					[60.527155493065891, 2.6292248313463875],
					[60.527155475654162, 2.6292248439189474],
					[60.527155467797201, 2.6292248491239731],
					[60.527110970332792, 2.6292637473125744],
					[60.527058174291021, 2.6293081458207497],
					[60.527005475851858, 2.6293498437684084],
					[60.52700547236951, 2.6293498462828921],
					[60.52700546540482, 2.6293498513118569],
					[60.527005462009193, 2.6293498556368933],
					[60.526938761988973, 2.6294137556425956],
					[60.526938760291131, 2.6294137578051009],
					[60.526938756808789, 2.6294137603195695],
					[60.526938755916532, 2.6294137604955536],
					[60.526869258532479, 2.6294859574777303],
					[60.526824862678296, 2.6295276549272364],
					[60.526824859282669, 2.6295276592522274],
					[60.526824849814616, 2.6295276684301196],
					[60.526824846419004, 2.6295276727551111],
					[60.526785948004296, 2.6295748708101074],
					[60.526719251587018, 2.6296498665281218],
					[60.526652652615347, 2.6297220647124129],
					[60.526652650917541, 2.6297220668748778],
					[60.526652648414178, 2.6297220710238358],
					[60.5266526458241, 2.6297220733622901],
					[60.526599848682125, 2.6297859695427159],
					[60.526538753227037, 2.629852664422943],
					[60.526538750636945, 2.6298526667613649],
					[60.526538748046882, 2.6298526690997974],
					[60.526538747241311, 2.6298526710862835],
					[60.526494253985362, 2.6299054621230948],
					[60.526438763816671, 2.6299581536513972],
					[60.526374865112551, 2.6300165513813099],
					[60.526374864307009, 2.6300165533677782],
					[60.526374863414738, 2.6300165535437414],
					[60.526374861716917, 2.6300165557061712],
					[60.52632206609119, 2.6300665515621473],
					[60.52626097363418, 2.6301192452566808],
					[60.526188786396858, 2.6301748357655534],
					[60.52615269474768, 2.6301970307779872],
					[60.526152688588496, 2.6301970338201968],
					[60.526152676443573, 2.6301970435255648],
					[60.526152670371111, 2.6301970483782657],
					[60.526133182394702, 2.6302137375946177],
					[60.526055500152822, 2.6302609260571641],
					[60.525988804821246, 2.6302970235925436],
					[60.525988803123418, 2.6302970257549352],
					[60.525988799554334, 2.630297026458746],
					[60.525988797856492, 2.6302970286211376],
					[60.525927704728836, 2.6303331233027221],
					[60.525847113237759, 2.6303720192394393],
					[60.525847110647668, 2.630372021577764],
					[60.52584710707859, 2.6303720222815619],
					[60.525847105380777, 2.6303720244439353],
					[60.525811005265155, 2.6303915231384964],
					[60.525811000890492, 2.63039152582872],
					[60.525810993033495, 2.6303915310332071],
					[60.52581098865884, 2.6303915337234267],
					[60.525769300394664, 2.6304192271219806],
					[60.525713810147437, 2.6304470213190352],
					[60.525713809255166, 2.6304470214949829],
					[60.525713805686074, 2.6304470221987719],
					[60.525713804880525, 2.6304470241851843],
					[60.525677704837868, 2.6304665245175261],
					[60.525677703945611, 2.6304665246934702],
					[60.525677703053326, 2.6304665248694188],
					[60.52559710313804, 2.6305109245840614],
					[60.525597101440212, 2.6305109267464135],
					[60.525597095281029, 2.6305109297884859],
					[60.525597092604222, 2.6305109303163157],
					[60.525535999472126, 2.6305498275789829],
					[60.525469304934632, 2.6305859237008296],
					[60.525469299667733, 2.6305859265669285],
					[60.525469291810722, 2.6305859317713272],
					[60.525469288328331, 2.6305859342855507],
					[60.525391588382611, 2.6306387348387488],
					[60.525391583115677, 2.6306387377048552],
					[60.525391573560817, 2.6306387450715447],
					[60.525391570078455, 2.6306387475857496],
					[60.525372083014815, 2.6306554377525404],
					[60.525344296642373, 2.6306720281824076],
					[60.525344293160018, 2.630672030696604],
					[60.525344283518436, 2.6306720362528231],
					[60.525344279230502, 2.6306720407534274],
					[60.525263779325684, 2.630733141750893],
					[60.525263778433427, 2.6307331419268274],
					[60.525263774951036, 2.6307331444410189],
					[60.52526377405875, 2.630733144616956],
					[60.525191577913773, 2.6307915425445363],
					[60.525138781005403, 2.6308304385802077],
					[60.52513877760974, 2.6308304429048093],
					[60.525138769752701, 2.6308304481090761],
					[60.525138767162602, 2.630830450447311],
					[60.525085975753015, 2.6308776422717863],
					[60.52503318687296, 2.6309137348359868],
					[60.525033179908206, 2.6309137398642806],
					[60.525033165173106, 2.6309137519072587],
					[60.525033159187338, 2.6309137585700708],
					[60.524997065614642, 2.6309498506035003],
					[60.524924881283098, 2.6310081391869899],
					[60.524833188905347, 2.6310692339105426],
					[60.524833182832836, 2.63106923876287],
					[60.524833172385684, 2.6310692463052159],
					[60.52483316720545, 2.6310692509816187],
					[60.524808180123657, 2.6310915389394722],
					[60.524772099801751, 2.6311137277974406],
					[60.52469990541244, 2.6311526239892475],
					[60.524699901037778, 2.6311526266792802],
					[60.524699893094024, 2.6311526300729766],
					[60.524699888806062, 2.6311526345734069],
					[60.524666607753126, 2.6311748214599473],
					[60.524594336269537, 2.6312026103676169],
					[60.524527750663857, 2.6312220065038758],
					[60.52447496177011, 2.6312331049314364],
					[60.524413872894371, 2.6312442022596212],
					[60.52434999055351, 2.6312497994533981],
					[60.524294400406141, 2.6312497997884141],
					[60.524252799875768, 2.6312497996998556],
					[60.524208299980842, 2.6312497992228527],
					[60.524136104605077, 2.6312498002813078],
					[60.52408331484007, 2.6312470006963826],
					[60.524027819799464, 2.6312415016625352],
					[60.524027811682309, 2.6312415014353729],
					[60.524027797232542, 2.631241500629224],
					[60.524027790007658, 2.6312415002261331],
					[60.52397499522921, 2.6312442005017811],
					[60.523919399640434, 2.631244200051357],
					[60.523919396071335, 2.631244200755007],
					[60.523919387954166, 2.6312442005278465],
					[60.523919384385096, 2.6312442012314916],
					[60.523849983583425, 2.6312498007279856],
					[60.52384998090659, 2.6312498012557222],
					[60.523849974660692, 2.6312498024870994],
					[60.523849971897185, 2.6312498012044654],
					[60.523791671884965, 2.6312581024411306],
					[60.523730572723565, 2.6312665022792689],
					[60.523730569067759, 2.6312665011725498],
					[60.523730562016283, 2.6312665043901919],
					[60.523730559252748, 2.6312665031075602],
					[60.523677758748036, 2.6312776033948961],
					[60.523677754286666, 2.6312776042744428],
					[60.523677743666084, 2.6312776081957163],
					[60.523677739204722, 2.6312776090752639],
					[60.523616647048037, 2.6312970068773747],
					[60.523558253923447, 2.6313109054950843],
					[60.523558245979707, 2.6313109088886177],
					[60.52355823089777, 2.6313109136893993],
					[60.523558222867294, 2.631310915272572],
					[60.523491627125871, 2.6313387131818096],
					[60.523461032362661, 2.6313498122485575],
					[60.523461029685834, 2.6313498127762767],
					[60.523461027009027, 2.6313498133039923],
					[60.523461026116742, 2.6313498134799],
					[60.523433226259534, 2.6313609134145275],
					[60.523433223669414, 2.6313609157525875],
					[60.523433220992601, 2.6313609162803044],
					[60.523433219208066, 2.6313609166321119],
					[60.523363818690569, 2.6313915168244559],
					[60.523363815208178, 2.6313915193384299],
					[60.523363806372124, 2.631391522907812],
					[60.523363801997462, 2.6313915255976723],
					[60.523319305870565, 2.6314165233965499],
					[60.523247116025743, 2.63145261825253],
					[60.523174922050814, 2.631483116346208],
					[60.523174919373993, 2.6314831168739099],
					[60.523174913214774, 2.6314831199155435],
					[60.523174909645682, 2.6314831206191442],
					[60.523113809965672, 2.6315137212440636],
					[60.523113809073401, 2.6315137214199633],
					[60.523113806483273, 2.6315137237579904],
					[60.523113804698738, 2.6315137241097855],
					[60.523041605048242, 2.6315526236773432],
					[60.523041603350386, 2.6315526258394621],
					[60.523041598888994, 2.6315526267189537],
					[60.523041597104466, 2.6315526270707448],
					[60.523013808169921, 2.6315692213751731],
					[60.522969319621389, 2.6315887166146754],
					[60.522969316138976, 2.6315887191285827],
					[60.522969308195222, 2.6315887225219616],
					[60.522969303820538, 2.6315887252117784],
					[60.522913806915582, 2.6316192223556487],
					[60.52286931028155, 2.6316415204658523],
					[60.522869307691423, 2.6316415228038461],
					[60.522869304122345, 2.6316415235074184],
					[60.522869302424489, 2.6316415256695214],
					[60.52279990218274, 2.6316804264785052],
					[60.522799901290469, 2.6316804266543974],
					[60.52279989772137, 2.631680427357967],
					[60.522799896023521, 2.6316804295200771],
					[60.522736005629497, 2.6317192235020337],
					[60.522663816121636, 2.6317526178047772],
					[60.522663810854702, 2.631752620670428],
					[60.522663800320792, 2.6317526264017066],
					[60.522663795053838, 2.6317526292673459],
					[60.522583194745891, 2.6318026294294801],
					[60.522583193048028, 2.6318026315915564],
					[60.522583190371193, 2.6318026321192178],
					[60.522583188673337, 2.631802634281291],
					[60.522566588921016, 2.6318137331730731],
					[60.522566581150627, 2.6318137401869484],
					[60.522566566415435, 2.631813752228529],
					[60.522566558645032, 2.6318137592424229],
					[60.522549869875256, 2.6318304473788738],
					[60.522469283720341, 2.6318887370291093],
					[60.522397085920865, 2.6319387358450701],
					[60.522397082438474, 2.6319387383588859],
					[60.522397073775799, 2.6319387455485712],
					[60.522397070293387, 2.631938748062411],
					[60.522341572402816, 2.6319859464111386],
					[60.522269273919996, 2.6320442444610532],
					[60.522269267847449, 2.6320442493127851],
					[60.522269254091192, 2.6320442629885452],
					[60.522269248105331, 2.6320442696505415],
					[60.522235956318951, 2.6320831617082359],
					[60.522197064052079, 2.6321192525017181],
					[60.522197062354202, 2.6321192546637522],
					[60.522197058066212, 2.6321192591636859],
					[60.522197056368348, 2.6321192613257138],
					[60.522124857705606, 2.6321942594965355],
					[60.522044265113081, 2.632272053887863],
					[60.52199986791036, 2.63231095062108],
					[60.521999857549808, 2.632310959972596],
					[60.521999838786599, 2.6323109819444324],
					[60.521999830383969, 2.6323109945647487],
					[60.521972044468122, 2.6323554724601612],
					[60.521891563886975, 2.6324304536016805],
					[60.521891561296833, 2.6324304559395384],
					[60.521891556203251, 2.6324304624255301],
					[60.521891552720824, 2.6324304649392527],
					[60.521824853735708, 2.6325026636300994],
					[60.521785955721427, 2.6325442603331237],
					[60.52175266778012, 2.6325776483545091],
					[60.52167208742221, 2.6326387346943916],
					[60.521585994957029, 2.6326915292008333],
					[60.521585992366887, 2.6326915315386628],
					[60.52158598361747, 2.6326915369177124],
					[60.521585980135072, 2.6326915394313777],
					[60.521552699672391, 2.6327165253612512],
					[60.52149992830978, 2.6327387120776717],
					[60.521452754693726, 2.6327554044305255],
					[60.521427785847528, 2.632758099692925],
					[60.521338912214404, 2.6327608997387268],
					[60.521247242333352, 2.6327470046567027],
					[60.521227868306269, 2.6327415109431214],
					[60.52120288416576, 2.6327304184836122],
					[60.52112229462255, 2.6326915240359896],
					[60.521061209696803, 2.6326554334591998],
					[60.520989029404895, 2.6326026464192758],
					[60.520916839182846, 2.6325331564261623],
					[60.520916835440381, 2.6325331535091481],
					[60.520916827955439, 2.632533147675157],
					[60.520916825105253, 2.6325331445822879],
					[60.520889040801407, 2.6325109570769376],
					[60.520864054844203, 2.6324804721973147],
					[60.520864050122789, 2.6324804676459794],
					[60.520864039700989, 2.6324804569089211],
					[60.520864034979567, 2.6324804523575871],
					[60.520772341701196, 2.6323970582726073],
					[60.520691848247886, 2.6323081663155961],
					[60.520691844418756, 2.632308161588429],
					[60.520691834975878, 2.6323081524858405],
					[60.520691830254457, 2.6323081479345527],
					[60.52065294567732, 2.6322748623735848],
					[60.520625157833564, 2.6322387776634413],
					[60.520625154983371, 2.6322387745706419],
					[60.520625148217334, 2.6322387649405043],
					[60.520625144388177, 2.6322387602133581],
					[60.520547344748792, 2.6321581619938703],
					[60.5204723493774, 2.6320776674231405],
					[60.52043905229003, 2.6320387695344278],
					[60.520439046676323, 2.632038765159062],
					[60.520439037146758, 2.6320387542464374],
					[60.520439032425315, 2.6320387496952091],
					[60.52039453704203, 2.6319998535402576],
					[60.52032234120351, 2.631927659155525],
					[60.520322339245581, 2.6319276558869125],
					[60.520322332739582, 2.631927651687449],
					[60.52032233078166, 2.6319276484188383],
					[60.520241831713548, 2.6318581507336183],
					[60.520214034179546, 2.6318331510276747],
					[60.520214028565839, 2.631833146652371],
					[60.520214019209625, 2.6318331393602161],
					[60.520214013682612, 2.6318331367950965],
					[60.520186214973677, 2.6318137360718237],
					[60.520102915039715, 2.6317554365596867],
					[60.520102914147444, 2.6317554367355571],
					[60.520102913168479, 2.6317554351012498],
					[60.520041813247616, 2.6317137351082884],
					[60.520041805762659, 2.6317137292746136],
					[60.520041790966111, 2.6317137212275989],
					[60.520041783567834, 2.6317137172040859],
					[60.519969486899534, 2.6316804204810049],
					[60.519897289877036, 2.6316442208717667],
					[60.519897286221223, 2.6316442197651124],
					[60.519897277930681, 2.6316442159174978],
					[60.519897274274904, 2.6316442148108394],
					[60.519869474023324, 2.6316331140376508],
					[60.519869471259824, 2.6316331127551154],
					[60.519869465732789, 2.6316331101900423],
					[60.519869463055969, 2.6316331107176567],
					[60.519827863319946, 2.6316192098424498],
					[60.519827856987348, 2.6316192092634099],
					[60.519827845040979, 2.6316192043091622],
					[60.51982783870838, 2.6316192037301218],
					[60.519772239397632, 2.6316081043118746],
					[60.519772236634125, 2.6316081030293486],
					[60.519772232086041, 2.6316081020985704],
					[60.519772230301498, 2.6316081024503157],
					[60.519736129966333, 2.6316026028284263],
					[60.519736128987383, 2.6316026011941411],
					[60.519736126310541, 2.6316026017217595],
					[60.519736123547041, 2.6316026004392357],
					[60.519644424301546, 2.6315915013451683],
					[60.519644417968948, 2.6315915007661355],
					[60.519644406195994, 2.6315914994322012],
					[60.519644399950074, 2.6315915006633075],
					[60.519566700359782, 2.6315914998552778],
					[60.519566694027169, 2.6315914992762326],
					[60.519566683319887, 2.631591501386688],
					[60.51956667698726, 2.6315915008076574],
					[60.519519377298735, 2.6315970012395304],
					[60.519519372837358, 2.631597002118887],
					[60.519519364806904, 2.6315970037017227],
					[60.519519361237791, 2.6315970044052102],
					[60.519449965279428, 2.6316109036901278],
					[60.51941386864145, 2.6316165018536677],
					[60.519413862395524, 2.6316165030847536],
					[60.519413848205822, 2.6316165077088081],
					[60.519413841067632, 2.6316165091157662],
					[60.519377741346155, 2.6316276096006552],
					[60.519377738669327, 2.6316276101282647],
					[60.519377734207964, 2.6316276110076067],
					[60.519377730638872, 2.6316276117110835],
					[60.519324932278515, 2.6316470118644073],
					[60.519286033046285, 2.6316609115239689],
					[60.519286028584908, 2.6316609124033072],
					[60.519286019835555, 2.6316609177822259],
					[60.519286016266442, 2.6316609184857001],
					[60.519213816931696, 2.6316942176155398],
					[60.519152717438295, 2.6317220179584702],
					[60.519152711279062, 2.6317220209996317],
					[60.519152699047304, 2.6317220288920771],
					[60.519152692888071, 2.6317220319332399],
					[60.519091601358333, 2.6317609253111409],
					[60.519008211227856, 2.6318026201371998],
					[60.519008206853172, 2.631802622826608],
					[60.519008199888368, 2.6318026278537117],
					[60.519008197211527, 2.6318026283813025],
					[60.518966596993586, 2.6318276284799795],
					[60.518966592618909, 2.6318276311693789],
					[60.518966583869535, 2.6318276365481794],
					[60.518966580387115, 2.6318276390617381],
					[60.51892208639763, 2.6318609365883074],
					[60.518874893213365, 2.6318915300641081],
					[60.518830495831992, 2.6319192281551369],
					[60.518749898735003, 2.6319665274640429],
					[60.518749896950453, 2.6319665278157531],
					[60.518749894360298, 2.6319665301534068],
					[60.51874989177017, 2.631966532491075],
					[60.518680497239835, 2.6320109293741814],
					[60.518622100899343, 2.6320442254881766],
					[60.518622099201473, 2.6320442276499607],
					[60.518622095632352, 2.6320442283533714],
					[60.518622093934496, 2.6320442305151706],
					[60.518555493842889, 2.632085929388031],
					[60.518555487770335, 2.6320859342391345],
					[60.51855547562522, 2.6320859439413611],
					[60.518555470444937, 2.632085948616615],
					[60.51853599535724, 2.6321026262468235],
					[60.518508225557802, 2.6321137139855857],
					[60.518508222967675, 2.6321137163232033],
					[60.51850821582947, 2.6321137177300011],
					[60.518508213239329, 2.6321137200676219],
					[60.51842771811112, 2.6321526171024869],
					[60.518374928423157, 2.6321748132050575],
					[60.518302735034347, 2.6321998118489676],
					[60.518302732357505, 2.6321998123765078],
					[60.518302725219307, 2.6321998137832714],
					[60.518302721736895, 2.6321998162967151],
					[60.518249938197449, 2.6322220091832866],
					[60.518191655774423, 2.6322359051296478],
					[60.518124965421741, 2.6322498026038934],
					[60.518069371964202, 2.632258101868997],
					[60.518005577035794, 2.6322665008529467],
					[60.517952780421808, 2.6322720015034413],
					[60.517897190009471, 2.6322775995767951],
					[60.517844400163995, 2.6322775992092482],
					[60.517844395702632, 2.632277600088448],
					[60.517844389370005, 2.6322775995092926],
					[60.517844384908628, 2.6322776003884942],
					[60.517772186356773, 2.6322831003327449],
					[60.517727787245938, 2.6322858990756295],
					[60.517727784655783, 2.6322859014131783],
					[60.517655584537415, 2.6322915002752931],
					[60.517655580968317, 2.6322915009786465],
					[60.517655573830091, 2.6322915023853564],
					[60.517655570174313, 2.6322915012786843],
					[60.517619373358855, 2.6322970010463806],
					[60.517547176828238, 2.6323054019213861],
					[60.51754717325916, 2.632305402624731],
					[60.517547167013227, 2.6323054038555926],
					[60.517547163357442, 2.6323054027489206],
					[60.517502764697667, 2.6323137027382955],
					[60.517422165955715, 2.632327603520892],
					[60.517422160602081, 2.6323276045759032],
					[60.517422150787041, 2.6323276065100982],
					[60.517422144541094, 2.6323276077409528],
					[60.51740274484046, 2.6323331080032664],
					[60.517402743055911, 2.6323331083549371],
					[60.517402738594534, 2.632333109234116],
					[60.517402735917713, 2.6323331097616198],
					[60.517386041251306, 2.6323387091274113],
					[60.517308244761622, 2.6323609079890193],
					[60.517308242084781, 2.6323609085165232],
					[60.517308235838861, 2.6323609097473581],
					[60.517308233162041, 2.6323609102748584],
					[60.517261033049039, 2.6323776113369179],
					[60.517261032156767, 2.6323776115127502],
					[60.517261028587676, 2.6323776122160822],
					[60.51726102769539, 2.6323776123919158],
					[60.51718882801557, 2.6324054135446695],
					[60.517188827123306, 2.6324054137204986],
					[60.517188823554214, 2.6324054144238249],
					[60.517188821856351, 2.6324054165854887],
					[60.517136022374906, 2.6324276150462489],
					[60.517063822304557, 2.6324581164574452],
					[60.517063818735465, 2.6324581171607595],
					[60.517063812576204, 2.6324581202015604],
					[60.517063809899376, 2.6324581207290523],
					[60.517002710204572, 2.6324887202663061],
					[60.517002710291244, 2.6324887220762947],
					[60.517002707614417, 2.6324887226037812],
					[60.517002706722153, 2.6324887227796059],
					[60.516949912345964, 2.6325165199204097],
					[60.516869318277877, 2.6325526177995271],
					[60.51686931390315, 2.6325526204886431],
					[60.516869305067075, 2.6325526240568808],
					[60.516869300692363, 2.6325526267459907],
					[60.516849912977975, 2.6325637188673179],
					[60.516822127321703, 2.632574814139359],
					[60.516749928392059, 2.6326026124305382],
					[60.516749925801918, 2.6326026147679986],
					[60.516749922232819, 2.6326026154712916],
					[60.516749919642649, 2.6326026178087405],
					[60.516705534280455, 2.6326220102070557],
					[60.516633250321277, 2.6326415064596458],
					[60.516577761931458, 2.6326554038020022],
					[60.516516677710506, 2.632663700298056],
					[60.516452790796642, 2.6326692000461476],
					[60.516400010925373, 2.6326692005786367],
					[60.516327826249466, 2.6326609023466907],
					[60.516272245035296, 2.6326526047270753],
					[60.516211164829208, 2.632633111218877],
					[60.516166769944533, 2.6326165126680166],
					[60.516166767181026, 2.6326165113855304],
					[60.516166760848414, 2.6326165108063182],
					[60.516166758084907, 2.6326165095238294],
					[60.516075094665965, 2.6325887203923113],
					[60.516061224951279, 2.6325776447363438],
					[60.516061213723908, 2.6325776359865105],
					[60.516061190550225, 2.6325776222826001],
					[60.51606117940954, 2.6325776153427203],
					[60.516041783391259, 2.6325692181375113],
					[60.515961187416295, 2.6325304202950601],
					[60.515961185631731, 2.6325304206466997],
					[60.515961185545059, 2.6325304188367684],
					[60.515908391144208, 2.632505421582259],
					[60.515841795789278, 2.6324692238658174],
					[60.515841793025771, 2.6324692225833357],
					[60.51584178660648, 2.6324692201942295],
					[60.515841783842973, 2.6324692189117656],
					[60.51576948899681, 2.6324359202549572],
					[60.515716792864808, 2.6324081232225489],
					[60.515716789122365, 2.6324081203059775],
					[60.515716779939559, 2.6324081166344402],
					[60.515716775391503, 2.632408115703611],
					[60.515627893637841, 2.6323720219188247],
					[60.515611211265224, 2.6323609325305068],
					[60.515611204845925, 2.6323609301414366],
					[60.515611194684183, 2.6323609248358126],
					[60.515611189070462, 2.632360920461013],
					[60.515583389041218, 2.6323470213244655],
					[60.515583385298733, 2.6323470184079349],
					[60.515583376115949, 2.6323470147364079],
					[60.515583372460156, 2.6323470136297868],
					[60.515511174291696, 2.632319213931265],
					[60.515463975527823, 2.632299814262113],
					[60.515463972850981, 2.6322998147895849],
					[60.51546396821626, 2.6322998120488696],
					[60.515463964560446, 2.632299810942262],
					[60.515391771756512, 2.6322748129688058],
					[60.515338978056242, 2.6322526153042984],
					[60.515338972615901, 2.6322526145493255],
					[60.515338964325366, 2.6322526107020447],
					[60.51533895879836, 2.6322526081371871],
					[60.515266766812509, 2.6322304109402546],
					[60.515205676035876, 2.6322054143000262],
					[60.515205673359056, 2.632205414827494],
					[60.515205668724313, 2.6322054120868166],
					[60.515205666853078, 2.6322054106285733],
					[60.515158366815484, 2.6321887114001479],
					[60.5150722682611, 2.6321581120815316],
					[60.515041778762935, 2.6321470160247902],
					[60.515008388637476, 2.6321304205659488],
					[60.515008387745183, 2.6321304207417762],
					[60.515008384981684, 2.6321304194593678],
					[60.515008384002726, 2.6321304178253158],
					[60.514941798426634, 2.6320998249422871],
					[60.514908411363855, 2.6320776330074205],
					[60.514908405836849, 2.6320776304426223],
					[60.514908396567364, 2.6320776249613664],
					[60.514908391932607, 2.6320776222207485],
					[60.514827902914021, 2.6320359282492172],
					[60.514764014144141, 2.6319915351707768],
					[60.51476401138062, 2.6319915338883777],
					[60.514764005766914, 2.631991529513745],
					[60.514764003003407, 2.6319915282313588],
					[60.51470840577381, 2.6319581311028273],
					[60.514636227305097, 2.631910943346214],
					[60.514602941197914, 2.6318776588229236],
					[60.51460293460525, 2.6318776528143029],
					[60.514602922398865, 2.6318776424310562],
					[60.514602915806186, 2.6318776364224368],
					[60.51455572771939, 2.6318442450723065],
					[60.514483439689236, 2.6317748565530956],
					[60.514405741911084, 2.6316970599236784],
					[60.514405739953162, 2.6316970566556401],
					[60.514405736210691, 2.6316970537392801],
					[60.514405734252776, 2.6316970504712569],
					[60.514377933812462, 2.6316720502008093],
					[60.514350135325984, 2.6316470532401461],
					[60.514258444038667, 2.6315609601150181],
					[60.514180751478968, 2.6314720686054369],
					[60.514180746757525, 2.6314720640551141],
					[60.51418073820691, 2.6314720547786488],
					[60.514180734377753, 2.6314720500525057],
					[60.514152940579727, 2.6314470579581402],
					[60.514125148081142, 2.6314165648146792],
					[60.514044547605017, 2.6313276660888225],
					[60.514044545647096, 2.6313276628208593],
					[60.514044540925639, 2.6313276582705756],
					[60.514044539054403, 2.6313276568124304],
					[60.513961243159422, 2.6312470595192146],
					[60.513927947424385, 2.6312109647800579],
					[60.513927943681907, 2.631210961863796],
					[60.513927938873771, 2.6312109555037302],
					[60.51392793602357, 2.6312109524116241],
					[60.513889038776227, 2.6311748563700803],
					[60.513808444044905, 2.6310942609472456],
					[60.513736247555855, 2.6310165652584523],
					[60.513694553024379, 2.6309692703725447],
					[60.513650156276142, 2.6309137756322096],
					[60.513650153425921, 2.6309137725401599],
					[60.513650146746521, 2.6309137647220888],
					[60.513650143896299, 2.630913761630036],
					[60.513583457352802, 2.630844274143846],
					[60.513539066091724, 2.6307776895033075],
					[60.513539063154809, 2.6307776846014854],
					[60.51353905736768, 2.6307776766076207],
					[60.513539052559516, 2.630777670247689],
					[60.513466857976816, 2.6306915773258464],
					[60.513386261798601, 2.6305804828848784],
					[60.513386256990415, 2.6305804765250129],
					[60.513386247374072, 2.6305804638052939],
					[60.513386240868059, 2.6305804596069171],
					[60.513358461927361, 2.6305526799247527],
					[60.513339076406311, 2.6305166053760924],
					[60.51333907436166, 2.6305166002984497],
					[60.513339067682224, 2.6305165924805372],
					[60.513339064745303, 2.6305165875787688],
					[60.513261265790206, 2.6304026883161633],
					[60.513205770350332, 2.630319294071561],
					[60.513141872781837, 2.6302082003204181],
					[60.513141871802851, 2.6302081986865153],
					[60.513080772113149, 2.6301054981824312],
					[60.513080771134163, 2.6301054965485342],
					[60.513080770155192, 2.630105494914639],
					[60.513080770068484, 2.6301054931048724],
					[60.513016872760318, 2.6300026995077546],
					[60.512955776034154, 2.6298888042384743],
					[60.512955775055175, 2.6298888026045879],
					[60.512955773097204, 2.6298887993368054],
					[60.512955771139254, 2.6298887960690327],
					[60.512897375946338, 2.6297916031844268],
					[60.512836280754172, 2.6296666158060504],
					[60.512791881682539, 2.6295693174008208],
					[60.512791881595824, 2.6295693155910573],
					[60.512791880703539, 2.6295693157669398],
					[60.512791879724574, 2.629569314133068],
					[60.512730780393603, 2.6294416136793863],
					[60.512730778348896, 2.6294416086019097],
					[60.512730773367245, 2.6294415986228556],
					[60.512730769624724, 2.6294415957068984],
					[60.512672375592842, 2.6293471040723086],
					[60.512611279842957, 2.6292193142490228],
					[60.512611277711528, 2.6292193073618497],
					[60.512611270945328, 2.6292192977346369],
					[60.512611268008364, 2.6292192928330942],
					[60.512555772914467, 2.6291331983611537],
					[60.512486277223708, 2.6290027064270189],
					[60.512486271349729, 2.6290026966240032],
					[60.512486259775272, 2.6290026806374107],
					[60.512486253988023, 2.6290026726441189],
					[60.512458461569231, 2.6289692820064947],
					[60.51243906942225, 2.6289387933975412],
					[60.512439064613972, 2.6289387870381047],
					[60.512439055889764, 2.6289387741433417],
					[60.512439051168272, 2.6289387695936184],
					[60.512347350618398, 2.628833169153344],
					[60.512347349639398, 2.6288331675195056],
					[60.512347348747141, 2.6288331676954],
					[60.512347348660413, 2.6288331658856836],
					[60.512275148582042, 2.6287526658097553],
					[60.512275145731785, 2.6287526627180027],
					[60.512275140031264, 2.6287526565345152],
					[60.512275136288729, 2.6287526536186805],
					[60.512233435964127, 2.6287137534303398],
					[60.512233434985127, 2.6287137517965276],
					[60.512233432134863, 2.6287137487048011],
					[60.512233430263606, 2.6287137472468833],
					[60.512194530194009, 2.6286804478129384],
					[60.512194522708974, 2.6286804419812713],
					[60.512194507825555, 2.6286804321276787],
					[60.512194500340499, 2.6286804262960137],
					[60.512122300525633, 2.6286387263559887],
					[60.512061201836453, 2.6286026276204062],
					[60.512061190782312, 2.6286026224923851],
					[60.512061168673995, 2.6286026122363446],
					[60.51206115770659, 2.6286026089180359],
					[60.511997258880307, 2.6285831089126628],
					[60.511936160619939, 2.6285637090446059],
					[60.511936153308334, 2.6285637068324195],
					[60.511936137879573, 2.6285637043936378],
					[60.511936130567939, 2.6285637021814381],
					[60.511883333691294, 2.6285554022491042],
					[60.511811137658555, 2.6285415040670808],
					[60.511811132218199, 2.6285415033127961],
					[60.511811120358523, 2.6285415001704302],
					[60.511811114112625, 2.6285415014017297],
					[60.511772217344777, 2.6285387000941247],
					[60.511691721259062, 2.6285304005147463],
					[60.51169171501315, 2.6285304017460462],
					[60.511691705024738, 2.6285304000615759],
					[60.511691699584397, 2.6285303993072899],
					[60.511666705187352, 2.6285303997947471],
					[60.511611110429222, 2.6285276002576974],
					[60.511611107752415, 2.6285276007853944],
					[60.511611103204338, 2.6285275998552127],
					[60.51161109963526, 2.6285276005588063],
					[60.51156669958641, 2.6285275995045381],
					[60.511538900381964, 2.6285275994037391],
					[60.511452799828234, 2.6285276003472346],
					[60.511452798043692, 2.6285276006990328],
					[60.511452795280142, 2.6285275994170592],
					[60.511452794387885, 2.6285275995929585],
					[60.51136109688921, 2.6285304009325965],
					[60.51133330036118, 2.6285304002907055],
					[60.511333293136296, 2.6285303998882208],
					[60.511333278773243, 2.6285304008929047],
					[60.511333271635088, 2.6285304023000782],
					[60.51131387825766, 2.628533100105424],
					[60.511244383910196, 2.6285387017395845],
					[60.511244380254368, 2.6285387006335172],
					[60.51124437311622, 2.6285387020406805],
					[60.511244370439393, 2.6285387025683704],
					[60.51120827095815, 2.6285442022779542],
					[60.511127771742778, 2.6285554020693103],
					[60.511127770850493, 2.6285554022452073],
					[60.51112776728143, 2.6285554029487805],
					[60.511127764604602, 2.6285554034764687],
					[60.511080564889895, 2.6285637032978832],
					[60.511080563997616, 2.628563703473775],
					[60.511019363794155, 2.6285748033719059],
					[60.511019362901912, 2.6285748035477954],
					[60.511019360225092, 2.6285748040754773],
					[60.51101935933282, 2.6285748042513681],
					[60.510966660793969, 2.6285859032935663],
					[60.510894365707387, 2.6285998021678809],
					[60.51084166992581, 2.6286081020164014],
					[60.510783272152324, 2.6286165028795305],
					[60.510783271173324, 2.6286165012457912],
					[60.510783270281046, 2.6286165014216838],
					[60.510783269388789, 2.6286165015975715],
					[60.510730579850907, 2.6286248001479309],
					[60.510661104315162, 2.6286275991583485],
					[60.510588927791119, 2.6286220015836257],
					[60.510536150086985, 2.6286109067657839],
					[60.510472257911061, 2.628591508461835],
					[60.51047225701879, 2.6285915086377227],
					[60.510472256126519, 2.6285915088136114],
					[60.510425082679689, 2.62857761597569],
					[60.510372306756992, 2.6285442314588976],
					[60.510372305777999, 2.6285442298251631],
					[60.510372303014471, 2.6285442285432215],
					[60.510372301143185, 2.6285442270853996],
					[60.510325120434899, 2.6285165386950045],
					[60.510283438789322, 2.6284776555810119],
					[60.510211252104206, 2.6284054681351989],
					[60.510147366648681, 2.6283192888196711],
					[60.510094576284658, 2.6282305043075143],
					[60.510033480916015, 2.6281055156355704],
					[60.509986285151875, 2.6279999230077355],
					[60.509930789585106, 2.62785823680652],
					[60.509889093015545, 2.6277027481617741],
					[60.509889090797323, 2.6277027394656942],
					[60.509889084663079, 2.6277027242349433],
					[60.509889081639315, 2.6277027175243481],
					[60.509858494689659, 2.6276360458927752],
					[60.50985019863851, 2.6275666766721986],
					[60.509850198291545, 2.6275666694339042],
					[60.509850195007573, 2.6275666572945893],
					[60.509850193768344, 2.6275666502322075],
					[60.509811293620835, 2.6274110511963169],
					[60.509811293534092, 2.6274110493867449],
					[60.509811292641821, 2.6274110495626624],
					[60.509769593145627, 2.6272527494912241],
					[60.509769593058891, 2.6272527476816561],
					[60.50976959199312, 2.6272527442384228],
					[60.50976959199312, 2.6272527442384228],
					[60.509750196242344, 2.6271860587593099],
					[60.509741898044098, 2.6271221739477681],
					[60.509741897870605, 2.6271221703286365],
					[60.509741895652347, 2.6271221616326343],
					[60.509741896371125, 2.6271221578375776],
					[60.509705796418061, 2.6269527588265689],
					[60.509705794546775, 2.6269527573688443],
					[60.509705795439046, 2.6269527571929161],
					[60.509705795352303, 2.6269527553833512],
					[60.509666894805882, 2.6267805551628935],
					[60.509666893740118, 2.6267805517196865],
					[60.509666891608575, 2.6267805448332942],
					[60.509666891348338, 2.6267805394046104],
					[60.509650194370529, 2.6267277492732481],
					[60.509639096102212, 2.6266749585573517],
					[60.509639095036448, 2.6266749551141682],
					[60.509639093970684, 2.6266749516709682],
					[60.509639092904919, 2.6266749482277856],
					[60.509594595191913, 2.6265082564013533],
					[60.509566897351277, 2.6263499666666403],
					[60.509566895132956, 2.6263499579707461],
					[60.50956689176212, 2.6263499440221167],
					[60.509566889630563, 2.626349937135787],
					[60.509541891676562, 2.6262749426266305],
					[60.509522394372603, 2.6261999504063924],
					[60.509522393306824, 2.6261999469632218],
					[60.50952239126201, 2.626199941886469],
					[60.509522390196231, 2.6261999384432975],
					[60.509475190306375, 2.6260527395419206],
					[60.509475190132846, 2.6260527359228107],
					[60.509475188088039, 2.6260527308460704],
					[60.509475187109004, 2.6260527292124589],
					[60.509422387131067, 2.6259138284283727],
					[60.509422385172989, 2.6259138251611978],
					[60.509422382062382, 2.6259138166413276],
					[60.50942238010434, 2.6259138133741282],
					[60.509377979549818, 2.6258221123130099],
					[60.509377973675655, 2.6258221025114881],
					[60.509377962014085, 2.6258220847179836],
					[60.509377956053164, 2.6258220731069182],
					[60.509308455757264, 2.6257359741930402],
					[60.509308451035658, 2.6257359696442046],
					[60.509308440613395, 2.6257359589129772],
					[60.509308435805011, 2.6257359525546078],
					[60.509236235820126, 2.6256692538232103],
					[60.509236231990776, 2.6256692490984523],
					[60.509236225397842, 2.625669243092029],
					[60.509236221655271, 2.6256692401768023],
					[60.509189022414766, 2.625633141434415],
					[60.509189011273769, 2.6256331344982766],
					[60.50918898899176, 2.6256331206260048],
					[60.509188977937512, 2.625633115499399],
					[60.509116788018481, 2.6256026192376916],
					[60.509091797387256, 2.6255887240609459],
					[60.509091779021347, 2.6255887167230134],
					[60.509091739699535, 2.6255887043845503],
					[60.50909171972264, 2.625588701017588],
					[60.509063922186449, 2.6255859009257523],
					[60.508972223526058, 2.6255748004313748],
					[60.508972220849238, 2.6255748009592628],
					[60.508972216301146, 2.6255748000295309],
					[60.508972213624368, 2.6255748005574131],
					[60.508891714105893, 2.6255691999298265],
					[60.508891707773266, 2.625569199352038],
					[60.508891696087041, 2.6255691998300108],
					[60.508891688862157, 2.6255691994281656],
					[60.508838898784752, 2.6255720002431588],
					[60.508777808613608, 2.6255692007966451],
					[60.508777806742316, 2.625569199339056],
					[60.508777802280989, 2.6255692002188544],
					[60.508777799604196, 2.6255692007467335],
					[60.508747200004429, 2.6255692000056174],
					[60.50871940431621, 2.6255691997818369],
					[60.508652815637625, 2.6255665001239024],
					[60.508605634170699, 2.6255609030253524],
					[60.508533344752131, 2.6255442051101849],
					[60.50853334385986, 2.6255442052861464],
					[60.508533341988546, 2.6255442038285715],
					[60.508533341096289, 2.6255442040045307],
					[60.508480652876422, 2.6255331061695713],
					[60.50840837373508, 2.6255081135733231],
					[60.508341789924835, 2.6254776214070956],
					[60.508280707314022, 2.6254442306839776],
					[60.508208417634478, 2.6253915384449122],
					[60.508208412999608, 2.6253915357057682],
					[60.508208401858596, 2.6253915287699048],
					[60.508208397136983, 2.6253915242212806],
					[60.508183414868213, 2.6253776352094458],
					[60.508164029559865, 2.6253609473851238],
					[60.508164028667593, 2.6253609475610897],
					[60.508164025817258, 2.6253609444700285],
					[60.508164023945959, 2.6253609430124767],
					[60.508083423750776, 2.6252970436817042],
					[60.508083422858505, 2.6252970438576666],
					[60.508083421792712, 2.6252970404146776],
					[60.508083420900448, 2.6252970405906382],
					[60.508002922615965, 2.6252359435266963],
					[60.50795842809265, 2.6251998466302662],
					[60.507902934254858, 2.6251526507988938],
					[60.507850137912961, 2.6251026545426019],
					[60.507850135062618, 2.6251026514515936],
					[60.507850130427741, 2.6251026487125015],
					[60.507850126685142, 2.6251026457974569],
					[60.507786237432157, 2.6250498545014409],
					[60.507733444650292, 2.624994261287132],
					[60.507733441799942, 2.6249942581961454],
					[60.507733435207008, 2.6249942521901173],
					[60.507733432356659, 2.6249942490991112],
					[60.507666839224541, 2.6249359564867492],
					[60.507625146033782, 2.6248915632686773],
					[60.507625142291161, 2.6248915603536651],
					[60.50762513471917, 2.6248915527142049],
					[60.507625131868835, 2.624891549623237],
					[60.507580734332976, 2.624852650782854],
					[60.507508435597174, 2.6247859530283484],
					[60.507436235762171, 2.6247192524916549],
					[60.507436232019593, 2.6247192495766845],
					[60.507436225426623, 2.6247192435707691],
					[60.507436221684017, 2.6247192406558049],
					[60.507400121626077, 2.6246915409364329],
					[60.507400119754791, 2.6246915394789321],
					[60.507400117883478, 2.6246915380214522],
					[60.507400116012171, 2.6246915365639776],
					[60.507361222781583, 2.624663741183864],
					[60.507283429869226, 2.624597048362256],
					[60.507283428890162, 2.6245970467288164],
					[60.507283426126605, 2.6245970454473215],
					[60.507283424168513, 2.6245970421804339],
					[60.50720292437218, 2.6245331429522829],
					[60.507202919737324, 2.6245331402133347],
					[60.507202912252097, 2.6245331343834719],
					[60.507202907617227, 2.6245331316445277],
					[60.507164030218483, 2.6245081463416042],
					[60.50713624799544, 2.6244776649893535],
					[60.507136239531185, 2.6244776575260791],
					[60.507136224387175, 2.6244776422475762],
					[60.507136216009684, 2.6244776365937166],
					[60.507069516036694, 2.624430437665354],
					[60.507069513186352, 2.6244304345744744],
					[60.507069507659196, 2.6244304320115184],
					[60.507069505787904, 2.6244304305540664],
					[60.507011207265379, 2.6243942307818764],
					[60.506941807901555, 2.6243498312968501],
					[60.506941807009284, 2.6243498314728311],
					[60.506941804158934, 2.6243498283819719],
					[60.50694180326667, 2.6243498285579521],
					[60.506858418937412, 2.6242998385052947],
					[60.506833432965216, 2.6242776500679827],
					[60.506833420845105, 2.6242776414993574],
					[60.506833397583954, 2.6242776259955205],
					[60.506833384658364, 2.6242776194122706],
					[60.506786196032195, 2.6242554245997738],
					[60.506733407429522, 2.624222030981767],
					[60.50673340377368, 2.624222029876309],
					[60.506733397267496, 2.6242220256800177],
					[60.506733392632611, 2.6242220229411348],
					[60.506680693950052, 2.6241942227125894],
					[60.506608396247458, 2.6241554240613754],
					[60.506533404293684, 2.6241137286337932],
					[60.506491810617113, 2.6240859338973341],
					[60.506491807853557, 2.6240859326159094],
					[60.50649180313188, 2.6240859280676929],
					[60.506491800368323, 2.6240859267862691],
					[60.506419518011867, 2.6240442378666309],
					[60.506394532896252, 2.6240220497330839],
					[60.506394519883877, 2.6240220413406457],
					[60.506394492966848, 2.6240220247317612],
					[60.506394479062187, 2.6240220165153127],
					[60.506355699729312, 2.6240054258348313],
					[60.506283418179073, 2.6239526375561075],
					[60.506283416307738, 2.6239526360987058],
					[60.506283411672868, 2.6239526333599099],
					[60.506283408996076, 2.6239526338878711],
					[60.506211208988539, 2.6239054333853438],
					[60.50621120890176, 2.6239054315759853],
					[60.506155714259009, 2.6238692358169553],
					[60.506083422726576, 2.623816541427586],
					[60.506050127979798, 2.6237887452328499],
					[60.506050126195269, 2.6237887455848297],
					[60.506050122452656, 2.6237887426700861],
					[60.506050119602286, 2.6237887395793598],
					[60.506016821206025, 2.6237637404809417],
					[60.505930724840617, 2.6236970444835963],
					[60.505852926549153, 2.6236331449049262],
					[60.505852923785582, 2.6236331436235676],
					[60.505852917192598, 2.6236331376181434],
					[60.50585291442902, 2.6236331363367809],
					[60.505816833401504, 2.6236081494113117],
					[60.505791847613878, 2.6235804663193671],
					[60.505791843784451, 2.6235804615953402],
					[60.505791834341089, 2.6235804524992554],
					[60.505791828640355, 2.6235804463178609],
					[60.505719528905033, 2.6235192471109743],
					[60.50571952792599, 2.6235192454776395],
					[60.50571952418337, 2.6235192425629394],
					[60.505719523291098, 2.6235192427389329],
					[60.505644532959799, 2.6234609507102609],
					[60.505600140696309, 2.6234165591866576],
					[60.505600136866882, 2.623416554462676],
					[60.505600128315756, 2.6234165451906946],
					[60.505600122788614, 2.6234165426280063],
					[60.505536234594643, 2.6233665522286578],
					[60.505494545991503, 2.6233220623676647],
					[60.505494540290741, 2.6233220561863759],
					[60.505494527191537, 2.6233220459851165],
					[60.505494519706254, 2.6233220401558222],
					[60.505439032651253, 2.6232804493171757],
					[60.505375144160503, 2.6232137622236262],
					[60.505375142202389, 2.6232137589570081],
					[60.505375138372969, 2.6232137542330838],
					[60.505375135609384, 2.623213752951759],
					[60.505302935642462, 2.6231470526577985],
					[60.505302934750212, 2.6231470528337995],
					[60.505302932878891, 2.6231470513764994],
					[60.505302931899827, 2.6231470497431948],
					[60.505255747693042, 2.6231054636099809],
					[60.505222360210233, 2.6230609807805072],
					[60.505222356294006, 2.6230609742473021],
					[60.505222347829687, 2.623060966784815],
					[60.505222343913431, 2.6230609602516335],
					[60.505150148573705, 2.6229859658972901],
					[60.505089056371943, 2.6229137732673431],
					[60.505025159986793, 2.6228304797097119],
					[60.504944561534153, 2.62271928244708],
					[60.504944558596961, 2.6227192775472483],
					[60.504944551917148, 2.6227192697328872],
					[60.504944548174485, 2.6227192668183652],
					[60.504916856777385, 2.6226887746640353],
					[60.504897369318527, 2.622660992791189],
					[60.504825174410151, 2.6225332022829182],
					[60.504825173431072, 2.6225332006496571],
					[60.504825170580702, 2.6225331975591497],
					[60.504825169601638, 2.6225331959258691],
					[60.504772371037994, 2.6224470971400815],
					[60.504700176997304, 2.6223249052922597],
					[60.504652980701671, 2.6222249153751447],
					[60.504652979722593, 2.6222249137418854],
					[60.504652978656729, 2.6222249102993773],
					[60.504652977677665, 2.6222249086661313],
					[60.504591878083595, 2.622105509575932],
					[60.504591877996774, 2.6221055077666722],
					[60.504591877017702, 2.6221055061334209],
					[60.504536278131972, 2.6219999091579185],
					[60.504475180927457, 2.6218749143726678],
					[60.504427981957114, 2.6217721172976685],
					[60.504427980978022, 2.6217721156644522],
					[60.50442797893308, 2.6217721105887462],
					[60.504427977954002, 2.6217721089555299],
					[60.504364078164897, 2.6216471090364935],
					[60.504311279244916, 2.6215443106747078],
					[60.504250179592944, 2.6214193125429661],
					[60.504250177547974, 2.621419307467312],
					[60.504250173631668, 2.6214193009345177],
					[60.504250171673505, 2.6214192976681057],
					[60.504191878077364, 2.6213221080295943],
					[60.504139082569942, 2.6212027193132332],
					[60.5041390806118, 2.6212027160468394],
					[60.504139077587745, 2.6212027093380308],
					[60.504139076608666, 2.6212027077048456],
					[60.504086277344548, 2.6211027069478088],
					[60.504086275386371, 2.6211027036814332],
					[60.504086269598716, 2.6211026956915608],
					[60.504086267553745, 2.6211026906159667],
					[60.504014073380986, 2.6209915992712105],
					[60.503958477323046, 2.6208860071924778],
					[60.503958475278061, 2.6208860021169182],
					[60.503958471361742, 2.6208859955842283],
					[60.503958469403564, 2.6208859923178931],
					[60.503894572654524, 2.6207859998024166],
					[60.503833476318285, 2.6206721044752692],
					[60.503833475339206, 2.6206721028421125],
					[60.503833472401936, 2.6206720979426494],
					[60.503833469551516, 2.6206720948523836],
					[60.503789070133145, 2.6205998955153849],
					[60.50378906817496, 2.6205998922490688],
					[60.503789063279548, 2.6205998840832954],
					[60.503789061321399, 2.6205998808169912],
					[60.503716871094618, 2.6205026946969969],
					[60.503689079137438, 2.62044711128337],
					[60.503689071217906, 2.6204470964090185],
					[60.503689051983464, 2.620447070982967],
					[60.503689040581747, 2.6204470586220618],
					[60.503650152393533, 2.6204081696652692],
					[60.503569559694171, 2.620299881788255],
					[60.503569559607342, 2.6202998799790573],
					[60.503569557649165, 2.6202998767127781],
					[60.503569556756901, 2.6202998768888457],
					[60.503497356651039, 2.6202081766379104],
					[60.503497353800604, 2.6202081735477329],
					[60.503497348905142, 2.6202081653820954],
					[60.503497346054708, 2.6202081622919153],
					[60.503452948378929, 2.6201609645599895],
					[60.503411250136622, 2.6201137675530948],
					[60.50341124630711, 2.6201137628298103],
					[60.503411236863521, 2.6201137537353789],
					[60.503411233120829, 2.6201137508212682],
					[60.503339033194749, 2.6200498498247344],
					[60.503266833241987, 2.6199859491051396],
					[60.503266826735675, 2.6199859449101175],
					[60.503266813723073, 2.6199859365200489],
					[60.503266808109011, 2.6199859321489387],
					[60.503227910915975, 2.619960933297345],
					[60.503200113633049, 2.6199415369733789],
					[60.503200110782608, 2.6199415338832481],
					[60.50320010516856, 2.6199415295121513],
					[60.503200100620447, 2.6199415285833476],
					[60.503119501193467, 2.6198942287627567],
					[60.503058401878036, 2.6198581279968551],
					[60.503002903403726, 2.6198248285631536],
					[60.503002895112878, 2.6198248247203493],
					[60.503002879423512, 2.6198248168586225],
					[60.503002871132693, 2.6198248130158133],
					[60.502922279909136, 2.6197942172858864],
					[60.502888989332952, 2.6197776202041596],
					[60.502888984784839, 2.6197776192753826],
					[60.502888978278513, 2.6197776150804319],
					[60.502888973730414, 2.6197776141516544],
					[60.502833373858664, 2.6197554133252026],
					[60.502833371181886, 2.6197554138534356],
					[60.502833362891081, 2.6197554100106606],
					[60.502833360127447, 2.6197554087297239],
					[60.502788969129178, 2.6197415118124168],
					[60.502755676627416, 2.6197276144816293],
					[60.502755673863795, 2.6197276132007135],
					[60.502755666552083, 2.6197276109910272],
					[60.502755662896213, 2.6197276098861884],
					[60.502680663416676, 2.6197026099370855],
					[60.502680660739919, 2.6197026104653154],
					[60.502680659760792, 2.619702608832235],
					[60.502627863023584, 2.6196859092137634],
					[60.502563965958444, 2.6196637115408605],
					[60.502563964087109, 2.6196637100838589],
					[60.502563962302588, 2.6196637104360145],
					[60.502563961323503, 2.619663708802952],
					[60.502502860714145, 2.6196442083027183],
					[60.502502859821874, 2.6196442084787992],
					[60.502502858929624, 2.6196442086548757],
					[60.502502858037367, 2.6196442088309544],
					[60.502438958384303, 2.6196248096708858],
					[60.502438957405197, 2.619624808037833],
					[60.502363973608915, 2.6196026124042775],
					[60.502319488881774, 2.6195804211894673],
					[60.502319482462319, 2.619580418803757],
					[60.502319466859738, 2.6195804127514428],
					[60.502319458568927, 2.6195804089087682],
					[60.50223897123319, 2.619555413228476],
					[60.502213982272686, 2.6195442168553416],
					[60.502213978616815, 2.619544215750536],
					[60.502213974068709, 2.6195442148218069],
					[60.502213971305089, 2.6195442135409071],
					[60.502177875025005, 2.6195304133567996],
					[60.502125077994691, 2.6195081168110339],
					[60.502125075231092, 2.6195081155301523],
					[60.502125068724773, 2.6195081113353438],
					[60.502125066047988, 2.6195081118635795],
					[60.502077871977917, 2.619491512479899],
					[60.502005677876696, 2.6194609157836215],
					[60.502005676984453, 2.6194609159596975],
					[60.502005676005346, 2.6194609143266687],
					[60.501944476219343, 2.619435915136096],
					[60.501944475327107, 2.6194359153121716],
					[60.501944474348008, 2.6194359136791476],
					[60.501944472563501, 2.6194359140313055],
					[60.501880677870034, 2.6194109156647096],
					[60.501808383889667, 2.6193776197480934],
					[60.501761190329589, 2.619355421349729],
					[60.501694494550385, 2.6193192238821021],
					[60.501694486259545, 2.6193192200395621],
					[60.501694465129766, 2.6193192114257555],
					[60.501694455054434, 2.6193192079353711],
					[60.50165568762197, 2.6193081176643367],
					[60.501636216292155, 2.6192942366605712],
					[60.501636208893558, 2.6192942326419444],
					[60.501636194096413, 2.6192942246047051],
					[60.501636186697851, 2.6192942205860907],
					[60.501550087912371, 2.6192526208811668],
					[60.501494496697319, 2.6192248244769356],
					[60.501430704024806, 2.6191859289078172],
					[60.501430702153456, 2.6191859274508893],
					[60.501430697605336, 2.6191859265222273],
					[60.501430696626223, 2.6191859248892357],
					[60.501350098213301, 2.6191415254082164],
					[60.5013112192226, 2.6191192372225367],
					[60.501277934713798, 2.6190887538659764],
					[60.501277924291074, 2.6190887431394048],
					[60.501277901029376, 2.6190887276417505],
					[60.501277888995837, 2.6190887208854954],
					[60.501205714015796, 2.6190526342070894],
					[60.501172334710361, 2.6190220528410277],
					[60.501172328117171, 2.6190220468373702],
					[60.501172312254063, 2.6190220353583182],
					[60.50117230476863, 2.619022029530746],
					[60.501086209694321, 2.6189692339114674],
					[60.501008424917579, 2.6189137448011204],
					[60.500980734344779, 2.6188887523238069],
					[60.500980732386566, 2.6188887490578869],
					[60.500980728730696, 2.6188887479531893],
					[60.500980727751596, 2.6188887463202253],
					[60.500947327849836, 2.6188609451905247],
					[60.500947325086216, 2.6188609439097239],
					[60.500947319472132, 2.6188609395391156],
					[60.500947315729434, 2.6188609366253535],
					[60.500880715770393, 2.618813737548658],
					[60.500880714791286, 2.6188137359157024],
					[60.50088071291993, 2.618813734458838],
					[60.500819514681858, 2.6187720364696805],
					[60.500747319159267, 2.618719239975734],
					[60.500666825007158, 2.6186581434454483],
					[60.500647330475879, 2.6186415486195083],
					[60.500647326733166, 2.6186415457057981],
					[60.500647322011318, 2.6186415411591759],
					[60.500647319160869, 2.6186415380693715],
					[60.50056121858163, 2.6185776385451338],
					[60.500561144745703, 2.6185776202461666],
					[60.500561006098884, 2.6185776403045007],
					[60.500560920584441, 2.6185777539683426],
					[60.500560939850537, 2.6185778926071905],
					[60.500560979101699, 2.6185779597335821],
					[60.500625829235148, 2.6186272253286837],
					[60.500541810302181, 2.6185720331056337],
					[60.50054180940991, 2.6185720332817306],
					[60.500541809323067, 2.6185720314727066],
					[60.500477910998228, 2.6185304344130644],
					[60.500416813486964, 2.6184887358039033],
					[60.500416809744237, 2.61848873289025],
					[60.50041680413014, 2.6184887285197536],
					[60.500416801366519, 2.6184887272390194],
					[60.500336200516031, 2.6184415262092036],
					[60.500336196860161, 2.618441525104573],
					[60.500336187590214, 2.618441519629461],
					[60.500336183042087, 2.618441518700926],
					[60.50030568604884, 2.6184276197147294],
					[60.500277889393466, 2.618413721049742],
					[60.500211189117891, 2.6183804204727021],
					[60.500211186354264, 2.6183804191919879],
					[60.500211179934801, 2.6183804168066453],
					[60.500211176278917, 2.618380415702029],
					[60.500163976861074, 2.6183609144137185],
					[60.500091778064771, 2.6183304158173826],
					[60.500091770753009, 2.6183304136081444],
					[60.500091757021785, 2.6183304090136037],
					[60.500091750602287, 2.61833040662827],
					[60.500027852309962, 2.6183137063060222],
					[60.499966755329901, 2.6182970083156314],
					[60.499911157379422, 2.6182804084175353],
					[60.499911153723552, 2.6182804073129349],
					[60.499911146411819, 2.6182804051037336],
					[60.499911142842798, 2.618280405808119],
					[60.499847242967618, 2.61826650542279],
					[60.499847239311762, 2.6182665043181728],
					[60.499847231107765, 2.6182665022850755],
					[60.499847226559631, 2.6182665013565747],
					[60.499786128985214, 2.6182581015574091],
					[60.499733330862803, 2.6182498022622549],
					[60.499733329970553, 2.6182498024383509],
					[60.499733327206918, 2.6182498011576483],
					[60.499733326314654, 2.6182498013337443],
					[60.499669426044363, 2.6182415018471987],
					[60.499669422388507, 2.6182415007426068],
					[60.499669415163609, 2.6182415003423865],
					[60.499669412399996, 2.6182414990616985],
					[60.499622212365338, 2.6182387000307545],
					[60.499622210580831, 2.618238700382943],
					[60.499622208796325, 2.6182387007351351],
					[60.499622207904075, 2.618238700911232],
					[60.499552811604353, 2.6182358998721358],
					[60.499516714609477, 2.6182331008864654],
					[60.499516712738099, 2.6182330994296943],
					[60.499516709169072, 2.6182331001340757],
					[60.499516707384579, 2.6182331004862642],
					[60.499436109257189, 2.6182303992612712],
					[60.499388911898983, 2.6182275997171525],
					[60.499388911006733, 2.6182275998932463],
					[60.499388909222226, 2.6182276002454339],
					[60.499388908329976, 2.6182276004215286],
					[60.49931670812623, 2.6182247994615082],
					[60.499316706341709, 2.6182247998136998],
					[60.499316701880453, 2.6182248006941671],
					[60.499316700009075, 2.6182247992374039],
					[60.499283303558691, 2.6182247999199184],
					[60.499211112450929, 2.6182220008175263],
					[60.49917501536887, 2.618219200044162],
					[60.499175013584377, 2.6182192003963443],
					[60.499175012692099, 2.6182192005724412],
					[60.499091717674986, 2.6182137008262125],
					[60.499066726396499, 2.6182109013844679],
					[60.499030631208768, 2.6182054013018847],
					[60.498977830984593, 2.6181970021896919],
					[60.498977829200101, 2.6181970025418746],
					[60.498977828307837, 2.6181970027179684],
					[60.498938938225692, 2.618191502775006],
					[60.498872248773452, 2.6181748062102725],
					[60.498872246009817, 2.6181748049296227],
					[60.498872242353947, 2.6181748038250583],
					[60.498872238784948, 2.6181748045294255],
					[60.49881674912416, 2.6181637059503879],
					[60.498744464343503, 2.6181415107068573],
					[60.498691778050087, 2.618122016009047],
					[60.49862788558319, 2.6180915196130816],
					[60.498627883711819, 2.6180915181563358],
					[60.498627880055935, 2.6180915170517922],
					[60.498627878184585, 2.6180915155950633],
					[60.498555684388727, 2.6180609174091387],
					[60.498500099317845, 2.6180331258928526],
					[60.498439007346626, 2.6179942305911856],
					[60.498439003690756, 2.6179942294866554],
					[60.498438998076644, 2.6179942251164925],
					[60.498438995313016, 2.6179942238358707],
					[60.498402899300658, 2.6179748261722771],
					[60.498375107930251, 2.6179581318880216],
					[60.498294512788632, 2.6179026343384071],
					[60.498294511004119, 2.6179026346905969],
					[60.49829450726137, 2.6179026317771914],
					[60.498294505390014, 2.6179026303204838],
					[60.498222311622094, 2.6178581348432419],
					[60.498169518657427, 2.6178192383868581],
					[60.498169516872935, 2.6178192387390502],
					[60.498169514914707, 2.6178192354734517],
					[60.498169514022429, 2.6178192356495495],
					[60.498097314419184, 2.6177692361791638],
					[60.498097309784178, 2.6177692334418712],
					[60.498097303277831, 2.6177692292479002],
					[60.498097298642833, 2.6177692265106036],
					[60.498058406603683, 2.6177470303461297],
					[60.498030715220118, 2.6177276360723933],
					[60.498030713348761, 2.6177276346157075],
					[60.498030710585148, 2.6177276333351207],
					[60.498030708800641, 2.6177276336873185],
					[60.497958409388701, 2.6176804335183719],
					[60.497958406538203, 2.6176804304289005],
					[60.497958402882333, 2.6176804293244218],
					[60.497958401010955, 2.6176804278677439],
					[60.497891800796914, 2.6176415271583271],
					[60.497891795269666, 2.617641524597182],
					[60.497891783236049, 2.6176415178420998],
					[60.497891776816545, 2.6176415154570551],
					[60.497825082849879, 2.6176137173159848],
					[60.497752888763223, 2.6175776209430976],
					[60.497752883235968, 2.6175776183819752],
					[60.497752870396987, 2.6175776136119042],
					[60.497752862998368, 2.6175776095940986],
					[60.497711162819485, 2.6175637103785903],
					[60.497711161927228, 2.6175637105546925],
					[60.49771116005585, 2.6175637090980319],
					[60.497711158271343, 2.6175637094502346],
					[60.497647257739374, 2.6175442090925043],
					[60.497647254975767, 2.617544207811946],
					[60.497647247664013, 2.6175442056030351],
					[60.497647244900392, 2.6175442043224608],
					[60.497611144930872, 2.6175359049783733],
					[60.497611141274994, 2.617535903873903],
					[60.497611133157875, 2.6175359036499488],
					[60.497611128609719, 2.617535902721599],
					[60.497591728809311, 2.6175331013700482],
					[60.497519429973082, 2.6175220020834291],
					[60.497519422748198, 2.6175220016833816],
					[60.497519408211559, 2.6175219990744147],
					[60.497519400181282, 2.6175220006593176],
					[60.497474999717326, 2.6175220008311086],
					[60.49747499695372, 2.617521999550541],
					[60.497474990707936, 2.6175220007832438],
					[60.497474987052058, 2.6175219996787948],
					[60.497386086651169, 2.6175275998714342],
					[60.497386084866676, 2.617527600223629],
					[60.497386078620906, 2.6175276014563229],
					[60.497386075857278, 2.6175276001757775],
					[60.497294377198635, 2.6175387010180735],
					[60.497269378231657, 2.6175415003247826],
					[60.497269366632374, 2.6175415026140545],
					[60.497269347002806, 2.6175415064882128],
					[60.497269337274901, 2.6175415102341244],
					[60.497252741800601, 2.6175470077919605],
					[60.497180553182368, 2.6175665066353559],
					[60.497141658184276, 2.6175748038451392],
					[60.497141657378883, 2.6175748058300834],
					[60.49714165291762, 2.6175748067105644],
					[60.49714165202537, 2.6175748068866582],
					[60.497063851709306, 2.6175942061417325],
					[60.497063850817057, 2.6175942063178264],
					[60.497008253607333, 2.6176081041660302],
					[60.496947156788615, 2.6176220045555283],
					[60.496894364154414, 2.6176331028546831],
					[60.496838881096132, 2.6176415001364743],
					[60.496777795817508, 2.6176441995022417],
					[60.496711109166291, 2.617644200853591],
					[60.496650024680875, 2.6176387015208138],
					[60.496597240987491, 2.6176304045226009],
					[60.496533354328783, 2.6176137064785068],
					[60.496477867865742, 2.6175970122746683],
					[60.496400078780418, 2.6175637159288989],
					[60.496400073253163, 2.617563713367852],
					[60.496400061393281, 2.617563710230673],
					[60.496400054973776, 2.6175637078457314],
					[60.496380685097201, 2.6175581160821357],
					[60.496364011242193, 2.6175470330152604],
					[60.496364006607195, 2.6175470302781427],
					[60.49636399831634, 2.6175470264365845],
					[60.496363993681335, 2.6175470236994722],
					[60.496280694897955, 2.6175026232223475],
					[60.496219502943099, 2.6174692288221948],
					[60.496155709376488, 2.6174276310393187],
					[60.496155706699739, 2.6174276315675975],
					[60.496155702956997, 2.6174276286544185],
					[60.496155701085627, 2.6174276271978143],
					[60.496075106045296, 2.6173804295321346],
					[60.496041810660408, 2.6173581347674513],
					[60.496041808702167, 2.6173581315021037],
					[60.496041804067183, 2.6173581287650203],
					[60.496041801303562, 2.6173581274845352],
					[60.495994504264786, 2.6173304288158858],
					[60.495941806678928, 2.6172970310807386],
					[60.495941803915301, 2.617297029800246],
					[60.495941797408946, 2.6172970256066224],
					[60.495941792773934, 2.6172970228695589],
					[60.495894492953077, 2.6172720236049156],
					[60.495894491973949, 2.6172720219722341],
					[60.495894489210315, 2.6172720206917628],
					[60.495894487338951, 2.6172720192351928],
					[60.495813993665571, 2.6172331229232904],
					[60.495761200346344, 2.6172026275014675],
					[60.495761191968597, 2.6172026218513289],
					[60.495761173602332, 2.6172026145207128],
					[60.495761165311464, 2.6172026106793229],
					[60.495688968966292, 2.6171776118162366],
					[60.495616774178721, 2.6171498136551876],
					[60.495583376675334, 2.6171359149201252],
					[60.49558336936358, 2.6171359127114182],
					[60.495583352868707, 2.6171359068374356],
					[60.495583344664695, 2.61713590480481],
					[60.495500044830067, 2.617116504715912],
					[60.49550003938969, 2.6171165039637567],
					[60.495500025745287, 2.6171165011789848],
					[60.49550002030491, 2.6171165004268282],
					[60.495472243131786, 2.6171137022079671],
					[60.495455663586931, 2.6171081097221758],
					[60.495455653598434, 2.6171081080417702],
					[60.495455632642269, 2.6171081030483099],
					[60.495455622653765, 2.6171081013679065],
					[60.495383323043853, 2.6170998008612312],
					[60.495383316711212, 2.6170998002851786],
					[60.495383305917329, 2.6170998005896067],
					[60.495383299584681, 2.6170998000135519],
					[60.495347200042538, 2.6170998001095707],
					[60.495258300017518, 2.6170998007093744],
					[60.495258297253869, 2.6170997994289413],
					[60.495258291008113, 2.6170998006616091],
					[60.495258288244464, 2.617099799381176],
					[60.495166688415743, 2.6171053995777753],
					[60.495166681277723, 2.6171054009865342],
					[60.495166667893926, 2.6171054036279586],
					[60.495166660669064, 2.6171054032279946],
					[60.495138864134454, 2.6171109037532689],
					[60.495105567244089, 2.6171165038473152],
					[60.495105566264961, 2.6171165022146776],
					[60.495041668043193, 2.6171276030992559],
					[60.495005569608892, 2.6171331024026716],
					[60.495005564255386, 2.6171331034592304],
					[60.495005552656089, 2.617133105748441],
					[60.49500554819484, 2.6171331066289039],
					[60.49493325337987, 2.617152605607882],
					[60.494880558990793, 2.6171637044761891],
					[60.494880555421773, 2.6171637051805536],
					[60.494880545607003, 2.6171637071175571],
					[60.494880542124868, 2.6171637096306237],
					[60.494816646921855, 2.617183106365101],
					[60.494761050722921, 2.6171970053433449],
					[60.494761049830664, 2.6171970055194356],
					[60.494761048133022, 2.6171970076803115],
					[60.494761047240765, 2.617197007856404],
					[60.494699947998427, 2.6172137072857087],
					[60.494636052880097, 2.6172304051872133],
					[60.49457495577132, 2.6172442046303823],
					[60.494574952202321, 2.6172442053347269],
					[60.494574944258908, 2.6172442087282115],
					[60.494574939797644, 2.6172442096086455],
					[60.494522144142238, 2.6172609078859685],
					[60.494469356294566, 2.6172748055703572],
					[60.494397166271014, 2.6172887034369343],
					[60.494341670417626, 2.6172970024999334],
					[60.494341668633105, 2.6172970028521028],
					[60.494341666848598, 2.6172970032042717],
					[60.494341665956348, 2.6172970033803531],
					[60.494277770297167, 2.6173081012165866],
					[60.494233275797697, 2.617313701470664],
					[60.494163881116229, 2.6173220009322407],
					[60.494124993224581, 2.6173248003546439],
					[60.494044400436493, 2.6173248003099507],
					[60.494044394103852, 2.6173247997338609],
					[60.494044383309941, 2.6173248000381766],
					[60.494044377956442, 2.6173248010946573],
					[60.494019388889093, 2.617327600000166],
					[60.493991708274663, 2.6173275998016865],
					[60.493919418647373, 2.6173220010087217],
					[60.493872226647326, 2.6173165013584176],
					[60.49380004679751, 2.6173054053909253],
					[60.493758362932795, 2.6172915101628154],
					[60.493758359276924, 2.6172915090584903],
					[60.493758350093792, 2.6172915053933479],
					[60.493758344653429, 2.6172915046411824],
					[60.493686157053958, 2.6172748087825162],
					[60.493633368874505, 2.6172554111567714],
					[60.493633367982241, 2.6172554113328501],
					[60.493633367090005, 2.6172554115089248],
					[60.493633366197749, 2.6172554116850071],
					[60.493569469807206, 2.6172331123316259],
					[60.493500083902539, 2.6172054185390139],
					[60.493452894072512, 2.6171804231017246],
					[60.493452893180248, 2.6171804232778073],
					[60.493452890416641, 2.6171804219974217],
					[60.493452888545249, 2.6171804205409464],
					[60.493375094154167, 2.6171415237972888],
					[60.493355699356876, 2.6171304256323698],
					[60.493355696593227, 2.6171304243519824],
					[60.493355691958243, 2.6171304216151516],
					[60.493355689194622, 2.6171304203347741],
					[60.493327894152394, 2.6171165232413824],
					[60.493244502873686, 2.6170692282333041],
					[60.493183410694598, 2.6170304335806871],
					[60.493119513578776, 2.6169859357650607],
					[60.493119511707413, 2.6169859343086208],
					[60.49311950796465, 2.6169859313957331],
					[60.493119506093286, 2.6169859299392932],
					[60.493030706400035, 2.6169304299116147],
					[60.493030703636386, 2.6169304286312736],
					[60.493030700872765, 2.6169304273509097],
					[60.49303069998053, 2.6169304275269929],
					[60.493011204943713, 2.6169192307006632],
					[60.492986213877927, 2.6169026353066021],
					[60.492905717295514, 2.6168442373323439],
					[60.492905716403278, 2.6168442375084258],
					[60.49290571176828, 2.6168442347716545],
					[60.492905710789152, 2.6168442331391493],
					[60.49283901360436, 2.6167998358454243],
					[60.492777917879941, 2.616755437834692],
					[60.492777916987698, 2.61675543801078],
					[60.492777916008578, 2.6167554363782819],
					[60.49270011628326, 2.6166998370007852],
					[60.492700114411882, 2.6166998355443805],
					[60.492700108884627, 2.6166998329837532],
					[60.492700107013249, 2.6166998315273489],
					[60.49266953267572, 2.6166804476985428],
					[60.492652951743402, 2.6166609696395002],
					[60.492652943191892, 2.6166609603728235],
					[60.492652921627645, 2.6166609427199128],
					[60.492652911291628, 2.6166609338054165],
					[60.49256121239253, 2.6165998347883792],
					[60.492489015615057, 2.6165498361964001],
					[60.492436223911348, 2.6165109424216482],
					[60.492383429110184, 2.6164665466888968],
					[60.492383421711537, 2.6164665426719531],
					[60.4923834086119, 2.6164665324772822],
					[60.492383401213253, 2.6164665284603403],
					[60.492336210862852, 2.6164387324886085],
					[60.492302919823366, 2.6164137399381304],
					[60.492302917059732, 2.6164137386578563],
					[60.492302912337841, 2.6164137341126361],
					[60.492302909574221, 2.616413732832358],
					[60.492222309631408, 2.6163609332671944],
					[60.492222308739152, 2.6163609334432909],
					[60.492150109359599, 2.6163137333320647],
					[60.492150109272728, 2.616313731523515],
					[60.492102909388969, 2.6162831317395931],
					[60.492102906625334, 2.6162831304593377],
					[60.492102903861714, 2.6162831291790596],
					[60.492102901990343, 2.6162831277227041],
					[60.492041802056711, 2.6162470283619461],
					[60.492041799206184, 2.616247025273152],
					[60.492041791894437, 2.6162470230648216],
					[60.492041789130802, 2.6162470217845688],
					[60.491997302407668, 2.6162248271101012],
					[60.491969514440903, 2.6162054364065379],
					[60.491969510698155, 2.6162054334938469],
					[60.491969504191744, 2.6162054293008978],
					[60.491969499556745, 2.6162054265643033],
					[60.49189730038065, 2.6161637260444097],
					[60.491897294853395, 2.6161637234839237],
					[60.491897284691127, 2.6161637181868422],
					[60.491897280142986, 2.6161637172587988],
					[60.491833384975109, 2.6161359181860302],
					[60.49177228932016, 2.6161054215229655],
					[60.491772284685162, 2.6161054187864039],
					[60.491772276394258, 2.616105414945689],
					[60.491772271759238, 2.6161054122091327],
					[60.491700072009877, 2.616077612915193],
					[60.491700068354007, 2.6160776118110447],
					[60.491700061042231, 2.6160776096027885],
					[60.49170005827861, 2.6160776083225588],
					[60.491644457697632, 2.6160609083718245],
					[60.491644456805396, 2.6160609085479183],
					[60.491644455913139, 2.6160609087240192],
					[60.491644455020889, 2.6160609089001174],
					[60.491566777992503, 2.6160387148132007],
					[60.491547298814687, 2.6160276253772379],
					[60.491547283212007, 2.6160276193283334],
					[60.491547252006669, 2.6160276072305204],
					[60.491547234706374, 2.616027603342344],
					[60.49150004292742, 2.6160192047449131],
					[60.491447251437847, 2.6160054065311429],
					[60.491447245018328, 2.6160054041468177],
					[60.49144723413756, 2.6160054026429829],
					[60.491447227804912, 2.6160054020671506],
					[60.491408330937873, 2.6159998027427789],
					[60.491341733237874, 2.6159887020556321],
					[60.491341731453382, 2.615988702407829],
					[60.491341726012976, 2.615988701655918],
					[60.491341724141606, 2.6159887001995954],
					[60.491294423685723, 2.6159831017802819],
					[60.491294422706602, 2.6159831001478806],
					[60.491222222971984, 2.615974801181201],
					[60.491222220295228, 2.6159748017094917],
					[60.491222214854851, 2.6159748009575834],
					[60.491222212983466, 2.6159747995012843],
					[60.491177813990532, 2.6159720004316767],
					[60.491102815144409, 2.6159665013048206],
					[60.491102814252159, 2.6159665014809175],
					[60.491102814165281, 2.6159664996724112],
					[60.491102813273017, 2.6159664998485104],
					[60.491061112745903, 2.6159636994744435],
					[60.491061111853632, 2.615963699650544],
					[60.49106110917689, 2.6159637001788338],
					[60.49106110828464, 2.6159637003549303],
					[60.490988912373282, 2.6159608995076065],
					[60.490952815275399, 2.6159581013958513],
					[60.490952811619515, 2.6159581002917394],
					[60.490952804394631, 2.6159580998920284],
					[60.490952799933382, 2.6159581007725072],
					[60.490883310041994, 2.6159580994612255],
					[60.490830620914046, 2.6159526007087246],
					[60.49083061823729, 2.6159526012370118],
					[60.490830611012392, 2.6159526008373035],
					[60.490830608248771, 2.6159525995571151],
					[60.490763909438144, 2.6159498000548989],
					[60.490711115778531, 2.6159470001334837],
					[60.490658321214404, 2.6159415003122741],
					[60.490605620974996, 2.615935901560833],
					[60.490605620082746, 2.6159359017369286],
					[60.490605618211347, 2.6159359002806584],
					[60.490605616426855, 2.6159359006328473],
					[60.490538915819059, 2.615930401438034],
					[60.490538914926823, 2.6159304016141247],
					[60.490538912163188, 2.6159304003339336],
					[60.490538911270932, 2.6159304005100283],
					[60.490486113955114, 2.6159275995126983],
					[60.490422219695446, 2.6159220010571436],
					[60.49037222180781, 2.6159165006789107],
					[60.490372220915546, 2.6159165008550063],
					[60.490372218238811, 2.6159165013832846],
					[60.490372217346547, 2.6159165015593788],
					[60.490305619093377, 2.6159109018874043],
					[60.490252820872556, 2.6159054010485741],
					[60.490200020549921, 2.6158998002298031],
					[60.490200017873185, 2.6158998007580783],
					[60.49020001243278, 2.6158998000061824],
					[60.490200008863788, 2.6158998007105496],
					[60.490136109405881, 2.61589700014432],
					[60.490080609967542, 2.6158941994077645],
					[60.490080600958152, 2.6158941993602509],
					[60.49008058213402, 2.6158942012497421],
					[60.490080572232372, 2.6158942013783233],
					[60.489644424618355, 2.6159553943880094],
					[60.489588982041958, 2.6159331164493209],
					[60.489533389067368, 2.6159054208215671],
					[60.489533370701068, 2.6159054134928965],
					[60.489533333336851, 2.6159054044368468],
					[60.489533313272929, 2.6159053992687498],
					[60.489491712739465, 2.6159025993294982],
					[60.489491710062715, 2.6159025998577583],
					[60.489491702837832, 2.6159025994580576],
					[60.489491700161075, 2.6159025999863199],
					[60.489416699780207, 2.6159025999006813],
					[60.489416697103465, 2.6159026004289418],
					[60.489416691663074, 2.6159025996770651],
					[60.489416688986317, 2.6159026002053283],
					[60.489363889648828, 2.6159054003918705],
					[60.489311095347354, 2.6159080991654142],
					[60.489258300229061, 2.6159080999463917],
					[60.489191699867185, 2.6159080996587565],
					[60.489127799842606, 2.6159080995083768],
					[60.489077808625893, 2.6159081000592042],
					[60.489013917358307, 2.6159026015246183],
					[60.489013916466064, 2.6159026017007028],
					[60.489013911917944, 2.6159026007727464],
					[60.489013910938802, 2.6159025991404605],
					[60.488961111030484, 2.6158998005015213],
					[60.488961110138234, 2.6158998006776049],
					[60.488961109245984, 2.615899800853688],
					[60.488961108266864, 2.6158997992214035],
					[60.488894414892322, 2.6158970005006701],
					[60.488841721214236, 2.6158915010551178],
					[60.488841720321986, 2.6158915012312005],
					[60.488841719429736, 2.6158915014072828],
					[60.488841717645215, 2.6158915017594522],
					[60.488780628090836, 2.6158859012603566],
					[60.488736136964093, 2.6158776038207638],
					[60.488736133308194, 2.6158776027167407],
					[60.48873612697556, 2.6158776021409618],
					[60.488736123319661, 2.6158776010369373],
					[60.488663924069456, 2.6158692002803492],
					[60.488619425066517, 2.6158637011010644],
					[60.488619424174253, 2.6158637012771475],
					[60.48861942328201, 2.6158637014532298],
					[60.488547224348501, 2.6158554010812689],
					[60.488502825023652, 2.6158498019380594],
					[60.488502824131395, 2.6158498021141439],
					[60.488502823152267, 2.6158498004818833],
					[60.488430623326074, 2.6158415003306712],
					[60.488430620649332, 2.6158415008589135],
					[60.488430617080304, 2.6158415015632444],
					[60.488430615208934, 2.6158415001070487],
					[60.488394415349333, 2.6158387005444066],
					[60.48832221829791, 2.615833101595495],
					[60.488297225354572, 2.6158304014795815],
					[60.488277829191389, 2.6158276027127179],
					[60.48827782277187, 2.6158276003286192],
					[60.488277811977987, 2.6158276006332568],
					[60.488277807429853, 2.6158275997053111],
					[60.488197207030517, 2.6158248000556679],
					[60.488197201590147, 2.6158247993038231],
					[60.488197190796228, 2.615824799608462],
					[60.488197186334993, 2.61582480048886],
					[60.488158293040378, 2.615827599991452],
					[60.488077800102623, 2.6158275994741449],
					[60.488077795641367, 2.6158276003545424],
					[60.488077786631976, 2.6158276003069973],
					[60.488077782170713, 2.6158276011873962],
					[60.488016681924684, 2.6158331017826173],
					[60.488016680945556, 2.615833100150379],
					[60.488016680053306, 2.6158331003264568],
					[60.488016679161049, 2.6158331005025381],
					[60.487963879256355, 2.6158387009072284],
					[60.487963874795092, 2.615838701787621],
					[60.487963867657086, 2.6158387031962458],
					[60.487963864980344, 2.6158387037244779],
					[60.487933266366589, 2.6158442035824003],
					[60.487866667225184, 2.615855402184561],
					[60.48786666365617, 2.6158554028888692],
					[60.487866656518165, 2.615855404297486],
					[60.487866653841422, 2.6158554048257128],
					[60.487819354942808, 2.6158665058202728],
					[60.487747155237557, 2.6158831048099001],
					[60.487747150776308, 2.6158831056902767],
					[60.487747143725166, 2.6158831089071821],
					[60.487747140156166, 2.6158831096114832],
					[60.487694343825282, 2.6158998078649729],
					[60.487622148008121, 2.6159192061580625],
					[60.487622143633736, 2.6159192088467265],
					[60.48762213738798, 2.6159192100792406],
					[60.487622133818974, 2.6159192107835323],
					[60.487566633986688, 2.6159387114160801],
					[60.487566631309939, 2.6159387119442985],
					[60.487566625151047, 2.615938714985099],
					[60.487566621582047, 2.615938715689389],
					[60.487513835301471, 2.6159609099675696],
					[60.487441647818187, 2.6159804067432559],
					[60.487441644249188, 2.6159804074475383],
					[60.487441635413539, 2.6159804110165443],
					[60.487441630952276, 2.6159804118968988],
					[60.487388836394807, 2.6159998092589034],
					[60.48731664542705, 2.6160220081693186],
					[60.487263848834544, 2.6160359076075004],
					[60.487188858323989, 2.616055404043816],
					[60.487155568366077, 2.6160609033989668],
					[60.487102770230834, 2.6160692026274632],
					[60.487063874567788, 2.6160748023034661],
					[60.487038889091032, 2.6160776005274822],
					[60.486958305239227, 2.6160776007230346],
					[60.486902821135864, 2.6160748002344287],
					[60.486850039894605, 2.6160665031501216],
					[60.486816748816558, 2.6160581058963603],
					[60.486816747924323, 2.6160581060724231],
					[60.486816746052952, 2.6160581046163021],
					[60.486816745160688, 2.6160581047923666],
					[60.486733354088315, 2.6160387074100142],
					[60.486691766224105, 2.6160248101023313],
					[60.486616770633148, 2.6159970132473016],
					[60.48655288116155, 2.6159720174007961],
					[60.486491788925818, 2.6159415210775987],
					[60.48649178794669, 2.6159415194454314],
					[60.48649178518307, 2.615941518165374],
					[60.486491784290799, 2.6159415183414434],
					[60.486419487397121, 2.615908120232751],
					[60.486375089435818, 2.6158859210077297],
					[60.486319488681438, 2.6158581217076811],
					[60.486275088842248, 2.6158359211555893],
					[60.486275086078614, 2.6158359198755696],
					[60.486275079659094, 2.6158359174915766],
					[60.486275076808596, 2.6158359144033367],
					[60.486241781729881, 2.6158220166405348],
					[60.486166787229209, 2.6157859204470548],
					[60.486166783573339, 2.615785919343105],
					[60.48616677983059, 2.6157859164309265],
					[60.486166778046069, 2.6157859167830657],
					[60.486113978176398, 2.6157637165653758],
					[60.486113976305013, 2.6157637151093009],
					[60.486113974433636, 2.6157637136532239],
					[60.486113972649143, 2.6157637140053578],
					[60.486050072982465, 2.6157387136516381],
					[60.48605007021883, 2.6157387123716318],
					[60.486050063799311, 2.6157387099876934],
					[60.486050061035677, 2.6157387087076889],
					[60.485988961145509, 2.6157192088257264],
					[60.485988960253252, 2.6157192090017962],
					[60.485936160139673, 2.6157026092077147],
					[60.485936153807017, 2.6157026086319872],
					[60.485936142839371, 2.6157026053201982],
					[60.485936138291244, 2.6157026043923324],
					[60.485863937803373, 2.6156887048478046],
					[60.485863934147467, 2.615688703743881],
					[60.485863926835727, 2.6156887015360057],
					[60.485863924072063, 2.6156887002560163],
					[60.485816724410419, 2.6156831010845383],
					[60.485816720841406, 2.6156831017888078],
					[60.485816717185536, 2.6156831006848815],
					[60.485816715401029, 2.615683101037015],
					[60.485744415009911, 2.6156776014017997],
					[60.485744413138526, 2.6156775999457276],
					[60.485700013159352, 2.6156747996555256],
					[60.485700008698117, 2.6156748005358552],
					[60.485700003257712, 2.6156747997840695],
					[60.485699999688713, 2.6156748004883315],
					[60.485638899991464, 2.6156748008586166],
					[60.48559170000712, 2.6156748000684362],
					[60.485591694566743, 2.6156747993166487],
					[60.485591684751988, 2.6156748012533693],
					[60.485591680203832, 2.6156748003255212],
					[60.485563880126684, 2.6156776009845095],
					[60.485563878342177, 2.615677601336639],
					[60.485563874773156, 2.6156776020409036],
					[60.485563872988649, 2.6156776023930366],
					[60.485502773065626, 2.615685902526971],
					[60.48550277217339, 2.6156859027030306],
					[60.485502770302013, 2.6156859012469837],
					[60.485502769409756, 2.6156859014230505],
					[60.485449971382543, 2.61569420247754],
					[60.48538607411755, 2.6157026018441991],
					[60.485386069656286, 2.6157026027245172],
					[60.48538606341053, 2.6157026039569597],
					[60.485386058949267, 2.6157026048372796],
					[60.485333262944593, 2.6157137036275775],
					[60.485266668112352, 2.6157248036706822],
					[60.485213869014082, 2.6157331011370561],
					[60.485213863660569, 2.6157331021934254],
					[60.485213853845814, 2.6157331041301011],
					[60.485213848579185, 2.6157331069946279],
					[60.485161065300673, 2.6157470013360435],
					[60.485099982399021, 2.6157526000355502],
					[60.485099979722264, 2.6157526005637299],
					[60.485099976153251, 2.6157526012679724],
					[60.485099974368751, 2.6157526016200894],
					[60.485036076778584, 2.6157609004091253],
					[60.484980580179467, 2.6157665010604041],
					[60.484980578394961, 2.6157665014125202],
					[60.484980574825975, 2.6157665021167547],
					[60.484980573041469, 2.6157665024688712],
					[60.484919385601941, 2.6157747994051883],
					[60.484863900036949, 2.6157747996701919],
					[60.484813905241865, 2.6157748007192869],
					[60.484755615361841, 2.6157719994491768],
					[60.484702824447666, 2.6157665014216316],
					[60.484641729208896, 2.6157581025369399],
					[60.484588936989923, 2.6157498030069299],
					[60.484525045631003, 2.6157359051313271],
					[60.484469457061088, 2.615722008108547],
					[60.484397268342505, 2.6156970126951404],
					[60.484352871824868, 2.6156804127506077],
					[60.484283373928598, 2.6156526141160406],
					[60.484283370272713, 2.61565261301216],
					[60.484283361981824, 2.6156526091723462],
					[60.484283358325911, 2.6156526080684559],
					[60.484255696168454, 2.6156442199332459],
					[60.484239026931903, 2.6156304466199267],
					[60.484239014724466, 2.6156304362519061],
					[60.484238989764904, 2.6156304229243794],
					[60.48423897675211, 2.6156304145405165],
					[60.484158377832586, 2.6155970163765496],
					[60.484100079627645, 2.6155720166188403],
					[60.484030679826816, 2.6155415177652293],
					[60.484030678847688, 2.615541516133177],
					[60.484030677955445, 2.6155415163092335],
					[60.483958378395407, 2.6155109162433665],
					[60.48390567841156, 2.6154887163035045],
					[60.483833377950219, 2.6154581166329249],
					[60.48383337518657, 2.6154581153530034],
					[60.483833369659315, 2.615458112793192],
					[60.483833366895681, 2.6154581115132878],
					[60.4837944738046, 2.6154442135411924],
					[60.483750087094982, 2.6154248196701388],
					[60.483697292861187, 2.6153970223837701],
					[60.48369729107668, 2.615397022735892],
					[60.483697287333896, 2.6153970198239662],
					[60.483697284570276, 2.6153970185440696],
					[60.483650088899118, 2.615374820652852],
					[60.483580691967774, 2.6153387222574422],
					[60.483580689204153, 2.6153387209775554],
					[60.48358068278462, 2.6153387185938475],
					[60.483580680020985, 2.6153387173139593],
					[60.483516780779674, 2.6153109176544769],
					[60.483455689178975, 2.6152831207739453],
					[60.483383395220947, 2.6152442232627631],
					[60.483383393436455, 2.6152442236148863],
					[60.48338339067282, 2.6152442223350136],
					[60.483383388801435, 2.6152442208790805],
					[60.483350088944128, 2.6152276207695691],
					[60.483350088051893, 2.6152276209456309],
					[60.483350086180508, 2.6152276194896995],
					[60.483350085288244, 2.6152276196657622],
					[60.483302884960239, 2.6152054194935968],
					[60.483302883981111, 2.6152054178616053],
					[60.48330288121749, 2.6152054165817216],
					[60.483302880325212, 2.6152054167577869],
					[60.48323898837441, 2.6151776197747236],
					[60.483202894922826, 2.6151581232513808],
					[60.483202891266934, 2.6151581221475708],
					[60.483202882083766, 2.6151581184840618],
					[60.483202878340997, 2.6151581155722043],
					[60.483130681703962, 2.6151276171740077],
					[60.48307788588729, 2.6151026191576729],
					[60.483077882231413, 2.615102618053871],
					[60.48307787581188, 2.6151026156702395],
					[60.483077873048231, 2.6151026143903922],
					[60.483013973127775, 2.6150776137009526],
					[60.48301396768737, 2.6150776129493001],
					[60.483013957611959, 2.6150776094618959],
					[60.483013952976947, 2.6150776067261243],
					[60.48295285327675, 2.6150609075170115],
					[60.482952852384493, 2.6150609076930773],
					[60.482952850513115, 2.6150609062371539],
					[60.482900051173935, 2.6150470062151854],
					[60.482900045733544, 2.6150470054635377],
					[60.482900034765883, 2.6150470021521981],
					[60.482900029325485, 2.6150470014005536],
					[60.48282502960889, 2.6150359015132927],
					[60.482788929629557, 2.6150304020592134],
					[60.482788918748788, 2.6150304005559266],
					[60.482788896181852, 2.615030399533445],
					[60.482788885474854, 2.6150304016462025],
					[60.482752784982694, 2.61503310095816],
					[60.482752776060181, 2.6150331027187912],
					[60.482752757236042, 2.6150331046080924],
					[60.482752747421287, 2.615033106544784],
					[60.482691646764707, 2.6150498063995866],
					[60.48269164587245, 2.6150498065756507],
					[60.482691644174842, 2.615049808735789],
					[60.482691642303458, 2.615049807279898],
					[60.482636042314205, 2.6150665076289852],
					[60.482636034370849, 2.6150665110215505],
					[60.482636015807365, 2.6150665183348609],
					[60.482636007950859, 2.61506652353546],
					[60.482566608179035, 2.615102623278919],
					[60.482566602825521, 2.6151026243352797],
					[60.482566594969043, 2.6151026295358375],
					[60.482566590594679, 2.6151026322241431],
					[60.482502697101417, 2.6151442286363951],
					[60.482447103806138, 2.615174825903988],
					[60.482447095862753, 2.6151748292965049],
					[60.482447082739661, 2.6151748373613835],
					[60.482447075775411, 2.6151748423858479],
					[60.482394275966286, 2.6152165437325943],
					[60.482394273376428, 2.6152165460687784],
					[60.482394266412179, 2.6152165510932224],
					[60.482394262930072, 2.6152165536054461],
					[60.482349865399186, 2.6152581519422018],
					[60.482302667837267, 2.6152998500053362],
					[60.48230266524741, 2.615299852341503],
					[60.482302659262288, 2.6152998589978593],
					[60.482302655780181, 2.6152998615100636],
					[60.482235961961635, 2.615369255350088],
					[60.482155468613726, 2.6154387489190554],
					[60.482155464326226, 2.6154387534152717],
					[60.482155453161361, 2.6154387647438484],
					[60.482155447176254, 2.6154387714001595],
					[60.482124862614228, 2.6154748530913952],
					[60.482083181978737, 2.6155054383946852],
					[60.482083177691266, 2.6155054428908748],
					[60.482083169834745, 2.6155054480912532],
					[60.482083166352623, 2.6155054506034174],
					[60.482002669622943, 2.6155776489727685],
					[60.481930487110766, 2.6156359344693692],
					[60.481891603291643, 2.6156581250207318],
					[60.481849915968752, 2.615680417834946],
					[60.481786027205402, 2.6157054135643314],
					[60.48178602363641, 2.6157054142684784],
					[60.481786019262017, 2.6157054169566529],
					[60.481786015693011, 2.615705417660803],
					[60.481749919317956, 2.6157220171141176],
					[60.481677722323873, 2.6157526159622448],
					[60.481677719647116, 2.6157526164903535],
					[60.481677716078117, 2.6157526171944934],
					[60.481677714380503, 2.6157526193545433],
					[60.481624917930475, 2.6157776164801385],
					[60.481552721581657, 2.6158081163836635],
					[60.481552718904894, 2.6158081169117642],
					[60.481552712746016, 2.6158081199519665],
					[60.481552710069259, 2.6158081204800658],
					[60.481491616087929, 2.6158387185270384],
					[60.481438821974677, 2.6158609151939762],
					[60.481438821082421, 2.6158609153700065],
					[60.481438819384806, 2.6158609175300147],
					[60.481438817600299, 2.6158609178820766],
					[60.481352718001105, 2.615899818090806],
					[60.481352712734463, 2.6158998209549265],
					[60.481352702201185, 2.6158998266831652],
					[60.481352696847679, 2.6158998277393399],
					[60.48133881088971, 2.6159081191608657],
					[60.481311037216621, 2.6159192106471494],
					[60.481238849831513, 2.6159387065335764],
					[60.48119435307698, 2.6159498065253293],
					[60.481122158230484, 2.6159665040641156],
					[60.48106666288885, 2.6159776037764519],
					[60.481005566876426, 2.6159887023581709],
					[60.480952776016224, 2.61599700170922],
					[60.480888882921349, 2.6160026001053986],
					[60.480888882029085, 2.6160026002814241],
					[60.480888880244578, 2.61600260063347],
					[60.480888879352328, 2.6160026008094923],
					[60.480836084606075, 2.6160081012234482],
					[60.480780594788527, 2.6160108992955329],
					[60.480708304693131, 2.6160108995754987],
					[60.480655613687745, 2.6160080992190968],
					[60.480591727851952, 2.6160026016292881],
					[60.4805500393485, 2.6159942033183761],
					[60.480550036671737, 2.6159942038464385],
					[60.480550032123602, 2.6159942029186416],
					[60.480550029359968, 2.6159942016388045],
					[60.480475029777843, 2.6159831018264907],
					[60.480422237657564, 2.615974804379515],
					[60.480350044916456, 2.6159581047045934],
					[60.480350044024206, 2.6159581048806131],
					[60.480350043131949, 2.6159581050566341],
					[60.480350042152807, 2.6159581034247683],
					[60.480311148858696, 2.6159498053205703],
					[60.480244455897882, 2.6159304074445235],
					[60.480244455005625, 2.6159304076205454],
					[60.480244454113375, 2.6159304077965637],
					[60.480244453134247, 2.6159304061646997],
					[60.480213972386075, 2.6159220125602882],
					[60.480197289087869, 2.6159137203653411],
					[60.480197279904736, 2.6159137167018858],
					[60.480197261538464, 2.6159137093749898],
					[60.480197253334445, 2.6159137073434144],
					[60.48011675293148, 2.6158915073262849],
					[60.48011675203923, 2.6158915075023033],
					[60.480116750167859, 2.6158915060464687],
					[60.480116749275624, 2.6158915062224852],
					[60.480061148785552, 2.6158776054062196],
					[60.480061147001038, 2.6158776057582616],
					[60.480061145129667, 2.615877604302427],
					[60.480061144237418, 2.6158776044784484],
					[60.480000047707954, 2.6158637057120511],
					[60.479947251206518, 2.6158498066220943],
					[60.479947243894763, 2.6158498044145198],
					[60.479947230250353, 2.6158498016312093],
					[60.479947223025476, 2.6158498012315041],
					[60.479875023109585, 2.6158415002716366],
					[60.479875018648336, 2.6158415011517353],
					[60.479875013207945, 2.6158415003999784],
					[60.479875008659818, 2.6158414994722139],
					[60.47981390861969, 2.6158386993525444],
					[60.479813904158426, 2.6158387002326435],
					[60.479813895149036, 2.6158387001849808],
					[60.479813889708652, 2.6158386994332234],
					[60.479755595185466, 2.6158414997479889],
					[60.479722206764485, 2.6158415000418862],
					[60.479647220158547, 2.6158359005346443],
					[60.479602830159699, 2.6158304025529375],
					[60.479541739961242, 2.6158192037471175],
					[60.479469456713367, 2.6158026083778205],
					[60.479416782396335, 2.6157831174484318],
					[60.479344494956948, 2.6157442236733539],
					[60.47934449219332, 2.6157442223935758],
					[60.479344485773808, 2.6157442200100287],
					[60.47934448390243, 2.6157442185542314],
					[60.479308394756529, 2.6157276225179436],
					[60.479272305057307, 2.6157054294415194],
					[60.479272304165058, 2.6157054296175386],
					[60.47927230318593, 2.6157054279857288],
					[60.47927230229368, 2.6157054281617502],
					[60.47921120512266, 2.6156692308218257],
					[60.47916401325498, 2.615638734523106],
					[60.479091818785875, 2.6155859386961686],
					[60.479002919926032, 2.6155192401364116],
					[60.479002917162404, 2.6155192388566482],
					[60.479002909676872, 2.6155192330335786],
					[60.479002906913244, 2.6155192317538156],
					[60.478972306953281, 2.6154998304564425],
					[60.478972230700968, 2.6154998181251607],
					[60.478972092575717, 2.6154998490243759],
					[60.478972029897378, 2.6154998942386976],
					[60.478958139726252, 2.6155220793569591],
					[60.478874891079428, 2.6156137232074679],
					[60.478824990003197, 2.615627595092584],
					[60.47876113883909, 2.6156165035269576],
					[60.478700044078167, 2.6156026053068913],
					[60.478700043185931, 2.6156026054829069],
					[60.478700038637797, 2.6156026045552005],
					[60.47870003685329, 2.6156026049072372],
					[60.478641737219625, 2.6155915040811708],
					[60.478641736240498, 2.6155915024493774],
					[60.478641732671491, 2.6155915031534516],
					[60.478641731779234, 2.6155915033294694],
					[60.478572236334657, 2.6155804030116641],
					[60.478516740373401, 2.6155692035707987],
					[60.478516734932995, 2.6155692028191231],
					[60.478516724944477, 2.6155692011397464],
					[60.478516720396364, 2.6155692002120303],
					[60.478461124593125, 2.6155637014901312],
					[60.478408331392735, 2.6155554015990323],
					[60.478408328715979, 2.6155554021270846],
					[60.478408325146965, 2.6155554028311574],
					[60.478408323275588, 2.6155554013754103],
					[60.478336123033984, 2.615547000944467],
					[60.478336121249448, 2.6155470012965059],
					[60.478336115809086, 2.6155470005448112],
					[60.478336113045451, 2.6155469992650895],
					[60.478294413395574, 2.6155441993310768],
					[60.478294408042046, 2.6155442003871792],
					[60.478294397248163, 2.6155442006916094],
					[60.478294390915536, 2.6155442001159512],
					[60.478230601166551, 2.6155470007168686],
					[60.478175009828497, 2.6155442002096518],
					[60.478175008044019, 2.6155442005616805],
					[60.478175002603599, 2.6155441998100146],
					[60.478174999926857, 2.6155442003380607],
					[60.478111099871988, 2.6155441995461604],
					[60.47811109630296, 2.6155442002502269],
					[60.478111087293584, 2.6155442002026184],
					[60.478111082832321, 2.6155442010826953],
					[60.478077791965909, 2.615546999982723],
					[60.478041699551518, 2.6155469991947404],
					[60.478041697767011, 2.6155469995467713],
					[60.478041693305762, 2.6155470004268428],
					[60.478041690628984, 2.615547000954896],
					[60.477977790894492, 2.6155497994702364],
					[60.477977788217729, 2.6155497999982837],
					[60.477977781971965, 2.6155498012303831],
					[60.477977779295202, 2.61554980175843],
					[60.477924979438853, 2.6155554016730438],
					[60.477924965968207, 2.6155554025054815],
					[60.477924941072025, 2.6155554092416482],
					[60.477924927775128, 2.615555413689612],
					[60.477852727613886, 2.6155831132796106],
					[60.477852705655081, 2.6155831249109429],
					[60.477852665567127, 2.6155831528928086],
					[60.477852648330206, 2.6155831690673286],
					[60.477772057541522, 2.6156776575270224],
					[60.477735998114547, 2.6157081256175054],
					[60.477683246542959, 2.6157276066704829],
					[60.477638880690527, 2.6157359003153662],
					[60.477611100074647, 2.6157359002531857],
					[60.47761109739789, 2.6157359007812047],
					[60.477611091065242, 2.615735900205518],
					[60.477611088388485, 2.6157359007335401],
					[60.477519387733729, 2.6157415002470272],
					[60.477519385949229, 2.615741500599039],
					[60.477519383272465, 2.6157415011270579],
					[60.477519380595723, 2.6157415016550747],
					[60.477433280608707, 2.6157498011814413],
					[60.477433276147444, 2.6157498020614671],
					[60.477433265353532, 2.6157498023658041],
					[60.477433260000033, 2.6157498034218341],
					[60.477405560294628, 2.6157554043096414],
					[60.477405553156608, 2.6157554057176831],
					[60.477405539772825, 2.6157554083577552],
					[60.477405532721669, 2.6157554115735207],
					[60.477366695971412, 2.6157691894018398],
					[60.477305661103067, 2.6157498085880051],
					[60.477305658426317, 2.6157498091160156],
					[60.477305652899055, 2.6157498065566034],
					[60.47730565102767, 2.6157498051008776],
					[60.477241760178401, 2.6157331082012103],
					[60.477188969692655, 2.6157137125844092],
					[60.477152872264845, 2.6156998126509023],
					[60.477152868608947, 2.6156998115472128],
					[60.477152862189456, 2.6156998091638188],
					[60.477152858620428, 2.615699809867841],
					[60.477080663590492, 2.6156776105739312],
					[60.47702786903951, 2.615658111912762],
					[60.477027865383604, 2.6156581108090822],
					[60.477027856200465, 2.6156581071460185],
					[60.477027851652331, 2.6156581062183215],
					[60.476955651581825, 2.615638707383062],
					[60.476955644270063, 2.6156387051757006],
					[60.476955628841168, 2.6156387027446635],
					[60.476955620637142, 2.6156387007133102],
					[60.476902821196056, 2.6156331010968374],
					[60.476902813078915, 2.6156331008731843],
					[60.476902797736884, 2.6156331002498487],
					[60.476902789619736, 2.6156331000261961],
					[60.476847195431908, 2.6156359003183711],
					[60.476783299917422, 2.6156359001094791],
					[60.476783289123532, 2.615635900413833],
					[60.476783266730351, 2.6156359030062322],
					[60.476783256023324, 2.6156359051182796],
					[60.476722155954356, 2.615649804278509],
					[60.47672213998068, 2.6156498092542653],
					[60.476722109991591, 2.6156498224691105],
					[60.476722095083908, 2.6156498308842377],
					[60.476649894936372, 2.6156942298294723],
					[60.476649894130986, 2.6156942318131557],
					[60.476649889669716, 2.6156942326931611],
					[60.476649888864337, 2.6156942346768624],
					[60.476616589348481, 2.6157165331103043],
					[60.476616587650838, 2.61571653526999],
					[60.476616584974096, 2.6157165357979855],
					[60.476616584168703, 2.6157165377816733],
					[60.476577686738842, 2.615744234167785],
					[60.476505489947122, 2.6157915334709587],
					[60.476505480392966, 2.6157915408299717],
					[60.476505461371488, 2.6157915573556609],
					[60.476505451904217, 2.6157915665223501],
					[60.476452651818583, 2.6158498662119793],
					[60.476397051680415, 2.6159109643397214],
					[60.476397050875036, 2.6159109663233817],
					[60.476397050069657, 2.6159109683070452],
					[60.4763970491774, 2.6159109684830395],
					[60.476344257112743, 2.6159720599570204],
					[60.476283164597838, 2.6160276516186238],
					[60.476283161202559, 2.6160276559379101],
					[60.476283152627488, 2.616027664928477],
					[60.476283149232238, 2.616027669247758],
					[60.476230451923307, 2.6160887653295664],
					[60.476177654867747, 2.6161442623341715],
					[60.476177653975491, 2.616144262510157],
					[60.476177653170112, 2.6161442644937991],
					[60.476177652364726, 2.6161442664774426],
					[60.476124860104839, 2.6162026564655054],
					[60.47606658283523, 2.6162526373533668],
					[60.476024904408824, 2.6162776256485616],
					[60.475952713063833, 2.6163137194392774],
					[60.475880520599546, 2.6163470158942399],
					[60.47582493962139, 2.6163692095016557],
					[60.475752760964269, 2.6163859048492153],
					[60.475719367773998, 2.6163915036503305],
					[60.475647169688159, 2.6164026025091029],
					[60.475647165226881, 2.6164026033889778],
					[60.475647156304333, 2.6164026051487248],
					[60.475647151843084, 2.6164026060285943],
					[60.475613883526826, 2.6164108972299678],
					[60.47558331777892, 2.6164081009868507],
					[60.475583315102135, 2.6164081015147751],
					[60.475583309661772, 2.6164081007629907],
					[60.475583306898152, 2.6164080994832823],
					[60.475502806844425, 2.6164053999679187],
					[60.475502799619541, 2.6164053995680843],
					[60.47550278793338, 2.6164054000481158],
					[60.475502780795338, 2.6164054014559048],
					[60.475413881451921, 2.6164137011980504],
					[60.475413878775157, 2.6164137017259677],
					[60.475413874313908, 2.6164137026058274],
					[60.475413871550273, 2.6164137013261328],
					[60.475394393931325, 2.6164164989913439],
					[60.475361124187067, 2.6164137013855617],
					[60.475288930318982, 2.6164026011802402],
					[60.475288928534475, 2.6164026015321844],
					[60.475288924965447, 2.6164026022360747],
					[60.475288923986348, 2.616402600604435],
					[60.475241741550896, 2.6163970033341437],
					[60.475180661112773, 2.616377609459839],
					[60.475180654780132, 2.6163776088840396],
					[60.47518064470475, 2.6163776053969192],
					[60.47518063926438, 2.6163776046451268],
					[60.475125039128606, 2.6163665036630572],
					[60.4751250373441, 2.6163665040150006],
					[60.475125032795972, 2.6163665030872592],
					[60.475125031903723, 2.6163665032632291],
					[60.475055639261804, 2.6163554036424315],
					[60.474997245800459, 2.616341505256119],
					[60.474997244015952, 2.6163415056080614],
					[60.474997237596433, 2.6163415032246604],
					[60.474997235811941, 2.6163415035766024],
					[60.474936143288978, 2.6163304056783154],
					[60.474883351303447, 2.6163165066008522],
					[60.474883344883956, 2.6163165042174765],
					[60.474883332218667, 2.6163165030659044],
					[60.47488332588604, 2.6163165024900961],
					[60.474819431139224, 2.6163081013652061],
					[60.474758335851121, 2.616297002290314],
					[60.474758334958871, 2.6162970024662857],
					[60.474711136128356, 2.6162887025792649],
					[60.474638938384473, 2.6162748044472446],
					[60.474638933836346, 2.6162748035195262],
					[60.474638924740077, 2.6162748016640891],
					[60.474638921084221, 2.6162748005603937],
					[60.474586126288003, 2.6162692028052179],
					[60.474513938746199, 2.6162581036557873],
					[60.474480647844352, 2.6162498052892893],
					[60.474480637855848, 2.6162498036098163],
					[60.474480617878811, 2.6162498002509089],
					[60.474480607977156, 2.6162498003790002],
					[60.474408314868647, 2.6162470007526819],
					[60.474361122670807, 2.6162415005899069],
					[60.474361119101779, 2.6162415012937883],
					[60.474361111876895, 2.6162415008939872],
					[60.474361108221018, 2.6162414997903083],
					[60.474288914215663, 2.6162387007026604],
					[60.474261119815523, 2.6162359018526464],
					[60.474261107150248, 2.6162359007011116],
					[60.474261080122034, 2.6162359005575149],
					[60.474261066738251, 2.6162359031970492],
					[60.474244375900284, 2.6162387004518872],
					[60.474166686441244, 2.6162441999545649],
					[60.47416668197998, 2.6162442008344033],
					[60.474166674841939, 2.616244202242151],
					[60.474166672165182, 2.6162442027700563],
					[60.474127773838141, 2.616249801364972],
					[60.474055577237593, 2.61625810042842],
					[60.474055573755415, 2.6162581029398337],
					[60.47405556563826, 2.6162581027159977],
					[60.474055562961503, 2.6162581032438998],
					[60.474011062650817, 2.6162665035394879],
					[60.47401105462054, 2.6162665051231828],
					[60.474011040431336, 2.6162665097461884],
					[60.474011032401044, 2.6162665113298869],
					[60.473941638771777, 2.6162915097576209],
					[60.473883245330178, 2.6163081074558865],
					[60.473883243545671, 2.6163081078078148],
					[60.473816643626805, 2.616327608419792],
					[60.473816642734548, 2.6163276085957561],
					[60.473816640950055, 2.6163276089476803],
					[60.473816640057791, 2.6163276091236445],
					[60.473763841841311, 2.6163442071535568],
					[60.47370554878826, 2.6163609070983722],
					[60.473636052689251, 2.616377606669452],
					[60.473636051797001, 2.6163776068454103],
					[60.473636050817873, 2.6163776052138386],
					[60.473591674562385, 2.6163886994593373],
					[60.473555608347048, 2.6163886995485779],
					[60.473483314936601, 2.6163831006209159],
					[60.473483313065238, 2.6163830991653247],
					[60.473483312172981, 2.6163830993412831],
					[60.473438920698229, 2.6163804004161819],
					[60.473366772887921, 2.6163692094841968],
					[60.473311209135176, 2.6163331316758502],
					[60.473311206371555, 2.6163331303962298],
					[60.473311200844307, 2.6163331278369704],
					[60.473311198080701, 2.6163331265573486],
					[60.473247301754135, 2.6162970280605222],
					[60.473166830826479, 2.6162470468925481],
					[60.473147349961941, 2.6162248677516704],
					[60.473147341410488, 2.6162248584903676],
					[60.473147320825433, 2.6162248424790917],
					[60.47314731138173, 2.6162248333937486],
					[60.473114026692578, 2.6162026450804952],
					[60.473033441387187, 2.6161220588639535],
					[60.473033440408074, 2.6161220572324155],
					[60.473033435773075, 2.6161220544972679],
					[60.473033434793962, 2.6161220528657463],
					[60.472941834745079, 2.6160387511605276],
					[60.472941831981444, 2.6160387498809463],
					[60.472941826367347, 2.6160387455142837],
					[60.472941822624584, 2.6160387426031924],
					[60.472916838017035, 2.616019254806027],
					[60.472897350438842, 2.6159970681605089],
					[60.472897344824744, 2.6159970637938579],
					[60.472897334315029, 2.6159970512696638],
					[60.47289732870091, 2.615997046903018],
					[60.472808429787236, 2.6159220478941578],
					[60.472727936289253, 2.6158526534822562],
					[60.472691841261899, 2.6158165582904211],
					[60.472691830925896, 2.6158165493812895],
					[60.472691807577164, 2.6158165320909319],
					[60.472691796435797, 2.6158165251652323],
					[60.47263620504836, 2.6157859300282964],
					[60.472591813356033, 2.6157554342923834],
					[60.47259180506515, 2.6157554304537269],
					[60.472591787417386, 2.6157554193374879],
					[60.472591778234268, 2.6157554156748017],
					[60.472538984215575, 2.6157331182862897],
					[60.47246678861471, 2.6156970218597047],
					[60.472466783892862, 2.6156970173172067],
					[60.472466771946102, 2.6156970123750121],
					[60.472466765613447, 2.6156970117993619],
					[60.472411166351975, 2.6156776105964057],
					[60.472411163675218, 2.615677611124319],
					[60.472411161803841, 2.6156776096688135],
					[60.472411159932456, 2.6156776082133106],
					[60.472358360193837, 2.6156609081704838],
					[60.472358358409345, 2.6156609085224245],
					[60.47235835386121, 2.6156609075948345],
					[60.47235835198984, 2.6156609061393317],
					[60.472286151855641, 2.6156415076344257],
					[60.472286149091993, 2.615641506354903],
					[60.47228614365163, 2.6156415056032878],
					[60.472286140887995, 2.6156415043237615],
					[60.472233340964394, 2.6156304040413705],
					[60.472233330975875, 2.615630402362179],
					[60.472233310193445, 2.6156304009871927],
					[60.472233300204927, 2.6156303993080008],
					[60.472172199655382, 2.6156304001565225],
					[60.472125004182921, 2.6156303991316388],
					[60.472061109231724, 2.6156275993567295],
					[60.472061106554953, 2.6156275998846388],
					[60.47206110209369, 2.6156276007644901],
					[60.472061100222327, 2.6156275993090006],
					[60.472016699731661, 2.6156275998945002],
					[60.472016688937757, 2.6156276001987124],
					[60.472016665652319, 2.6156276029664993],
					[60.472016654945286, 2.6156276050781373],
					[60.471980562415595, 2.6156359037145052],
					[60.471908269827878, 2.6156470028534233],
					[60.471908267151129, 2.6156470033813277],
					[60.471908260905359, 2.6156470046131073],
					[60.471908259033981, 2.6156470031576244],
					[60.471855558805125, 2.6156581045070468],
					[60.47185555612834, 2.6156581050349548],
					[60.471855551667105, 2.6156581059147896],
					[60.471855548990341, 2.6156581064426927],
					[60.471802751751198, 2.6156720056695479],
					[60.47174165715429, 2.6156859045973078],
					[60.47167495929358, 2.6156998046634929],
					[60.47167495394006, 2.6156998057192857],
					[60.471674945017504, 2.615699807478943],
					[60.471674939663998, 2.6156998085347305],
					[60.471622148972578, 2.6157165051467546],
					[60.47156936836776, 2.6157276020357427],
					[60.471497177150226, 2.6157359001928442],
					[60.471497176257976, 2.615735900368807],
					[60.471449977110474, 2.615741501154631],
					[60.471377776968097, 2.6157498009932327],
					[60.471352801446912, 2.6157525990354649],
					[60.471308333701138, 2.6157470022515903],
					[60.471244451301622, 2.6157331066789524],
					[60.471191760090235, 2.615716509693955],
					[60.471191754649844, 2.6157165089423255],
					[60.471191742789962, 2.615716505807685],
					[60.471191738154957, 2.6157165030727181],
					[60.471119449305753, 2.6157026051286372],
					[60.471066763302687, 2.6156859105182759],
					[60.471011165919478, 2.6156665114776776],
					[60.471011165027235, 2.6156665116536404],
					[60.470938966783955, 2.615641512169343],
					[60.470886169328551, 2.6156220117420492],
					[60.470886168436309, 2.6156220119180094],
					[60.470886165672681, 2.6156220106385319],
					[60.470886164780424, 2.615622010814497],
					[60.470805665209227, 2.6155942114547992],
					[60.470805659681972, 2.6155942088958555],
					[60.470805648714332, 2.6155942055853107],
					[60.470805643273948, 2.6155942048337266],
					[60.470780672755261, 2.6155887114466694],
					[60.47076119889536, 2.6155776257515937],
					[60.470761188733107, 2.615577620457739],
					[60.470761168582335, 2.6155776134847604],
					[60.470761159399196, 2.6155776098223171],
					[60.470688958778581, 2.6155554099226346],
					[60.470688956014953, 2.6155554086431727],
					[60.470688951466826, 2.6155554077156293],
					[60.470688948703192, 2.6155554064361519],
					[60.470633348936261, 2.6155415054396154],
					[60.470633347151754, 2.6155415057915397],
					[60.470633344388133, 2.6155415045120773],
					[60.470633342603627, 2.6155415048640025],
					[60.470569442803566, 2.6155276042104165],
					[60.470569441911323, 2.6155276043863775],
					[60.470569441911323, 2.6155276043863775],
					[60.470569441019059, 2.6155276045623408],
					[60.470516741001212, 2.6155165034206562],
					[60.470516740108962, 2.6155165035966181],
					[60.470516737345328, 2.6155165023171643],
					[60.470516735560821, 2.6155165026690903],
					[60.470455639257352, 2.6155054044250541],
					[60.470402845345767, 2.6154942050975722],
					[60.470336147888169, 2.6154776051372153],
					[60.470336145211412, 2.6154776056651006],
					[60.470336138791907, 2.6154776032821703],
					[60.470336136115151, 2.6154776038100573],
					[60.470275036067108, 2.6154665027692756],
					[60.470275035174858, 2.6154665029452384],
					[60.470275033390351, 2.6154665032971618],
					[60.470275030626702, 2.6154665020177035],
					[60.470222245316769, 2.6154581052953487],
					[60.470158357867696, 2.615438709058771],
					[60.470158356888582, 2.6154387074273946],
					[60.470158354124948, 2.6154387061479576],
					[60.470158353232698, 2.6154387063239204],
					[60.47009725612547, 2.615422007395948],
					[60.47002506734821, 2.6153998113411383],
					[60.469997276689902, 2.6153887150268571],
					[60.469938987318486, 2.6153637196015982],
					[60.469897293860598, 2.6153415230937869],
					[60.469897291096977, 2.6153415218143667],
					[60.46989728369833, 2.6153415178001316],
					[60.46989728004246, 2.6153415166966729],
					[60.469852888111525, 2.6153220198465679],
					[60.469780694634657, 2.615283124196333],
					[60.469713999376772, 2.6152470267772223],
					[60.469652902823654, 2.6152109273107818],
					[60.46957510409672, 2.6151637289999412],
					[60.46957510320447, 2.6151637291759053],
					[60.469547309936445, 2.6151470327314645],
					[60.469527916206715, 2.6151331368583524],
					[60.469527914335352, 2.6151331354029943],
					[60.469527913443109, 2.6151331355789615],
					[60.469447314348194, 2.6150776359992958],
					[60.469383418158777, 2.6150331386784993],
					[60.469322325943466, 2.6149859448486632],
					[60.469250136253606, 2.6149248522105495],
					[60.469216840721089, 2.6148915591505819],
					[60.469216838762826, 2.6148915558879726],
					[60.4692168331487, 2.6148915515219895],
					[60.46921683029818, 2.614891548435351],
					[60.46917793181305, 2.6148581508811364],
					[60.46909733599356, 2.6147859531643953],
					[60.469016846967534, 2.6147081648563324],
					[60.468991854595011, 2.6146776733914576],
					[60.46899185272364, 2.614677671936152],
					[60.468991849786235, 2.614677667042316],
					[60.468991847914857, 2.6146776655870108],
					[60.468964048203972, 2.6146470655926866],
					[60.468964043395196, 2.6146470592435369],
					[60.468964032166902, 2.6146470505117074],
					[60.468964027358119, 2.6146470441625582],
					[60.468872327894267, 2.6145720458243313],
					[60.468780741071534, 2.6144942580893211],
					[60.468764051999116, 2.6144748708000014],
					[60.468764045232085, 2.6144748611883699],
					[60.468764030260992, 2.6144748495460846],
					[60.468764022688568, 2.6144748419176933],
					[60.468739026817666, 2.614455445582708],
					[60.468655731043718, 2.6143831492109926],
					[60.468655724450414, 2.6143831432139186],
					[60.468655707694843, 2.6143831319236486],
					[60.468655700209297, 2.6143831261025361],
					[60.468583399814193, 2.6143415274418462],
					[60.468583398835065, 2.6143415258105795],
					[60.468583396963666, 2.6143415243553134],
					[60.468527896678445, 2.6143109244852361],
					[60.468527895786195, 2.6143109246612153],
					[60.468527894893953, 2.6143109248371981],
					[60.468527893914796, 2.6143109232059567],
					[60.468450094295541, 2.614269223645199],
					[60.468450088768243, 2.6142692210866496],
					[60.468450078605954, 2.6142692157935405],
					[60.46845007405782, 2.6142692148662241],
					[60.468422282259624, 2.6142581165433998],
					[60.468388989433528, 2.614241520940308],
					[60.468388987562157, 2.6142415194850508],
					[60.468388984798509, 2.6142415182057808],
					[60.468388983014002, 2.6142415185577437],
					[60.468322286008387, 2.6142109199251133],
					[60.46827788911758, 2.6141887208842851],
					[60.468277887246181, 2.6141887194290248],
					[60.468277880826662, 2.614188717046475],
					[60.468277878063006, 2.6141887157672179],
					[60.468205694106501, 2.6141581213604903],
					[60.468136209341182, 2.6141137335459557],
					[60.468077914140174, 2.6140748358153045],
					[60.468016819039057, 2.6140304391329541],
					[60.46797234385572, 2.6139970581924206],
					[60.467936261661748, 2.6139470824521789],
					[60.467936258811221, 2.6139470793657558],
					[60.467936253915525, 2.6139470712097115],
					[60.467936251065005, 2.6139470681232839],
					[60.467864055227132, 2.6138637738195638],
					[60.467811262231024, 2.613794281256562],
					[60.467747364459662, 2.6137026865818638],
					[60.467747363393627, 2.6137026831434973],
					[60.467747360629993, 2.6137026818642743],
					[60.467747360629993, 2.6137026818642743],
					[60.467666861747979, 2.6135942836113353],
					[60.46762796490637, 2.6135387871399485],
					[60.467583465625673, 2.6134720890580287],
					[60.46758346553878, 2.6134720872508566],
					[60.467530770372427, 2.6133942942039439],
					[60.467494573854168, 2.6133305012733921],
					[60.467494571895877, 2.6133304980110621],
					[60.467494567000159, 2.6133304898552172],
					[60.467494565041868, 2.6133304865928881],
					[60.467414067635076, 2.6132137909796378],
					[60.467358470900756, 2.6131220952491807],
					[60.467297371349176, 2.6130220956533154],
					[60.467297370370019, 2.6130220940221545],
					[60.467297367606363, 2.6130220927430234],
					[60.467297368411714, 2.6130220907598627],
					[60.467225167848298, 2.6129109913809292],
					[60.467169567992926, 2.6128248923782049],
					[60.467169567906019, 2.6128248905710598],
					[60.467169567013784, 2.6128248907470693],
					[60.467169566034634, 2.6128248891159189],
					[60.467086281098283, 2.6126999106988547],
					[60.46707239031111, 2.6126582368624853],
					[60.467072384175474, 2.6126582216543053],
					[60.467072369401265, 2.6126581953802579],
					[60.467072360588865, 2.612658180700111],
					[60.467033467673048, 2.6126054912542549],
					[60.466972374048922, 2.6124971025098591],
					[60.466972373069765, 2.6124971008787261],
					[60.466972371111446, 2.6124970976165001],
					[60.466972370045404, 2.6124970941782508],
					[60.466927970397592, 2.6124248953181728],
					[60.466927970310671, 2.6124248935110437],
					[60.466927969418435, 2.6124248936870638],
					[60.46685297341633, 2.6123054987224266],
					[60.466800176312354, 2.6122082040934966],
					[60.466736278784786, 2.6120888114171588],
					[60.466689082384462, 2.6119860160151127],
					[60.466689079620799, 2.6119860147360732],
					[60.466689078380902, 2.6119860076836403],
					[60.466689076422576, 2.6119860044214582],
					[60.466627975657502, 2.6118721045554194],
					[60.466627974765252, 2.611872104731455],
					[60.466627972720019, 2.6118720996621687],
					[60.466627971740849, 2.6118720980310912],
					[60.466564078265527, 2.6117638082392047],
					[60.466511281849684, 2.6116471178211418],
					[60.46651128176277, 2.611647116014038],
					[60.466511279891357, 2.6116471145590139],
					[60.46651127980445, 2.6116471127519048],
					[60.466458479653696, 2.6115388113539706],
					[60.466458478761446, 2.6115388115300133],
					[60.46640017918682, 2.6114221112515894],
					[60.4664001772285, 2.6114221079894628],
					[60.466400175096318, 2.6114221011131602],
					[60.466400173224905, 2.6114220996581494],
					[60.466339073365667, 2.6113166004358517],
					[60.466339073278753, 2.6113165986287568],
					[60.466339072299576, 2.6113165969976944],
					[60.466339071320405, 2.6113165953666471],
					[60.466275175628915, 2.6112110042148946],
					[60.466222381426419, 2.611099913809281],
					[60.466222378575829, 2.6110999107232491],
					[60.466222374659125, 2.6110999041991141],
					[60.466222373593041, 2.6110999007609736],
					[60.466166874321779, 2.6110027015332178],
					[60.466166873342601, 2.6110026999021905],
					[60.466166873255688, 2.6110026980950876],
					[60.466166872363438, 2.6110026982711441],
					[60.466094577608288, 2.6108805067778942],
					[60.466050182304492, 2.6107832169049408],
					[60.466050180346144, 2.6107832136429074],
					[60.466050176342492, 2.610783205311753],
					[60.466050174384144, 2.6107832020497015],
					[60.465977980637852, 2.6106555133544314],
					[60.465941886362053, 2.6105638264353566],
					[60.465941885382875, 2.6105638248043506],
					[60.465941883337585, 2.6105638197352548],
					[60.465941882271487, 2.6105638162971796],
					[60.465877984298686, 2.6104249215784785],
					[60.465830787296255, 2.6103027287873637],
					[60.465830786230143, 2.610302725349309],
					[60.465830784271787, 2.6103027220873285],
					[60.465830784184845, 2.61030272028025],
					[60.465777984284372, 2.6101805207242363],
					[60.46577798330518, 2.6101805190932548],
					[60.465777982326003, 2.6101805174622688],
					[60.465777982326003, 2.6101805174622688],
					[60.465716885977599, 2.6100471258208846],
					[60.465680789135838, 2.60994163510717],
					[60.465680788156661, 2.6099416334761969],
					[60.465680786024414, 2.6099416266001456],
					[60.46568078495828, 2.6099416231621051],
					[60.465619584626189, 2.6097916233512288],
					[60.465619583647012, 2.6097916217202624],
					[60.465619581688642, 2.6097916184583316],
					[60.465619580709451, 2.6097916168273696],
					[60.46558078257938, 2.60970822010548],
					[60.465527984673422, 2.6095777254870698],
					[60.46552798458648, 2.6095777236800242],
					[60.465527982541154, 2.6095777186110731],
					[60.465527981561976, 2.6095777169801204],
					[60.465500183995495, 2.6095166213706125],
					[60.465480785663281, 2.6094666284936521],
					[60.465480785402427, 2.6094666230725156],
					[60.465480781572616, 2.6094666183557469],
					[60.465480781398732, 2.6094666147416645],
					[60.465408481307257, 2.6093138145117369],
					[60.465408480415029, 2.6093138146878316],
					[60.465408479348881, 2.6093138112498573],
					[60.465408479348881, 2.6093138112498573],
					[60.465364082228398, 2.6092249152202198],
					[60.465302984138276, 2.6090805218991426],
					[60.465302983159084, 2.6090805202682139],
					[60.465302981113716, 2.6090805151993361],
					[60.465302980134538, 2.6090805135683874],
					[60.465255779798433, 2.6089832127928556],
					[60.465255779798433, 2.6089832127928556],
					[60.465191880359832, 2.6088527117445808],
					[60.465191877422235, 2.608852706851819],
					[60.465191870654806, 2.6088526972424226],
					[60.46519186771723, 2.6088526923496742],
					[60.465136272293499, 2.6087665988242055],
					[60.465064078644353, 2.6086332117978395],
					[60.465039082071534, 2.608577717882806],
					[60.465039077001613, 2.6085777061142696],
					[60.465039065251212, 2.6085776865434305],
					[60.465039057591532, 2.6085776771102482],
					[60.465000160916418, 2.6085276814493845],
					[60.464939064903753, 2.6084387873712638],
					[60.464939062945341, 2.6084387841094858],
					[60.464939059115501, 2.6084387793929165],
					[60.464939057157089, 2.6084387761311358],
					[60.464886257390013, 2.608372075762138],
					[60.464886255518557, 2.6083720743073706],
					[60.464886256410807, 2.6083720741312524],
					[60.464886254626315, 2.6083720744834933],
					[60.464814055073234, 2.6082831745960449],
					[60.46481405213563, 2.608283169703391],
					[60.4648140445629, 2.6082831620773543],
					[60.464814040733039, 2.6082831573608227],
					[60.4647334418678, 2.608202659744963],
					[60.464705741717786, 2.608174858477275],
					[60.464705738867146, 2.6081748553916384],
					[60.464705732273593, 2.6081748493965087],
					[60.464705728530703, 2.6081748464870103],
					[60.464669537189749, 2.608144254003848],
					[60.464594544174012, 2.6080665614803142],
					[60.464594539364938, 2.6080665551329778],
					[60.464594529028496, 2.6080665462284145],
					[60.46459452439337, 2.6080665434950587],
					[60.464516824299494, 2.6080054436879134],
					[60.464516823320288, 2.6080054420570433],
					[60.464516822428045, 2.6080054422331673],
					[60.464516822341075, 2.608005440426187],
					[60.464469529361025, 2.6079692469632536],
					[60.464425137130014, 2.6079276543071921],
					[60.464425132407897, 2.6079276497668844],
					[60.464425123050653, 2.607927642493256],
					[60.464425118328563, 2.6079276379529603],
					[60.464364027796719, 2.6078831468474624],
					[60.464316837340377, 2.6078387540331076],
					[60.464316832705251, 2.6078387512997905],
					[60.464316826111698, 2.6078387453048131],
					[60.464316822281837, 2.6078387405883974],
					[60.464244522739953, 2.6077831426108831],
					[60.464164024330564, 2.6077192437168613],
					[60.464164023351344, 2.6077192420860351],
					[60.464139038296153, 2.6076998530690623],
					[60.464119549945671, 2.6076776679778781],
					[60.464119542372913, 2.6076776603521208],
					[60.464119526335118, 2.6076776452767398],
					[60.464119516977874, 2.6076776380032496],
					[60.464030721197311, 2.6076137412953115],
					[60.463958425888073, 2.6075554455505241],
					[60.46391404230836, 2.6075192572130081],
					[60.463877955275429, 2.6074748734693327],
					[60.463877951532517, 2.6074748705599893],
					[60.463877946723429, 2.6074748642128736],
					[60.463877943872774, 2.6074748611273884],
					[60.463794549377852, 2.6073887668024303],
					[60.46371405598979, 2.6072915750885635],
					[60.463694558289589, 2.6072665777599049],
					[60.463694556331156, 2.6072665744983192],
					[60.463694550629818, 2.6072665683274083],
					[60.463694548758369, 2.6072665668727537],
					[60.463669554381717, 2.6072387711726854],
					[60.463589059109928, 2.6071331787390921],
					[60.463589057151488, 2.6071331754775078],
					[60.46358905242937, 2.6071331709374426],
					[60.463589049578687, 2.6071331678519996],
					[60.46350845324568, 2.6070415702720489],
					[60.463464055870553, 2.6069859756291716],
					[60.463464053912119, 2.6069859723676072],
					[60.463464048210767, 2.6069859661967891],
					[60.463464045360091, 2.6069859631113883],
					[60.463416844948647, 2.6069359637811824],
					[60.463416843969419, 2.6069359621503962],
					[60.463416842097956, 2.606935960695774],
					[60.463416841118743, 2.6069359590650043],
					[60.463352943338244, 2.6068720606310882],
					[60.463291847814446, 2.6068081655504773],
					[60.463239051054032, 2.6067470684773015],
					[60.463239049095613, 2.6067470652157856],
					[60.463239044373474, 2.6067470606758061],
					[60.463239040630555, 2.6067470577665981],
					[60.463172346914412, 2.6066804651580857],
					[60.463127952656649, 2.6066276708801897],
					[60.463127948826752, 2.606627666164087],
					[60.463127942146151, 2.6066276583626471],
					[60.463127938403233, 2.6066276554534595],
					[60.463075143728453, 2.6065776616744394],
					[60.463014049598932, 2.6065081674797832],
					[60.463014048619712, 2.6065081658490459],
					[60.463014044876786, 2.6065081629398672],
					[60.463014043897559, 2.6065081613091299],
					[60.462939053345103, 2.6064304702320347],
					[60.462905760199021, 2.6063859805641245],
					[60.462905756282119, 2.6063859740411957],
					[60.462905745858585, 2.6063859633307191],
					[60.462905741136439, 2.6063859587908325],
					[60.462866842615306, 2.6063470594511662],
					[60.462794544103552, 2.6062720606685157],
					[60.4627945366177, 2.6062720548502658],
					[60.462794521559019, 2.6062720414068798],
					[60.462794514073153, 2.6062720355886335],
					[60.462722315757951, 2.60622203788152],
					[60.462669525729481, 2.6061831455831443],
					[60.46261683318658, 2.6061359511579902],
					[60.462616826679941, 2.6061359469705252],
					[60.462616815364164, 2.6061359364363796],
					[60.46261680796529, 2.6061359324250581],
					[60.462569508294528, 2.606105432274429],
					[60.462569499024191, 2.6061054268085817],
					[60.46256947967828, 2.6061054178599337],
					[60.462569470407942, 2.6061054123940881],
					[60.46252507716855, 2.606088715581377],
					[60.462463983345863, 2.6060609172162494],
					[60.462463976033987, 2.6060609150118421],
					[60.462463963194686, 2.6060609102506795],
					[60.462463955882818, 2.6060609080462691],
					[60.462397255857589, 2.6060415073413772],
					[60.462397252201626, 2.6060415062391806],
					[60.462397244976742, 2.6060415058416351],
					[60.462397241320801, 2.6060415047394194],
					[60.462344440878752, 2.6060304042744349],
					[60.462344438202038, 2.6060304048029264],
					[60.462344430890163, 2.606030402598531],
					[60.462344427234228, 2.6060304014963167],
					[60.462283326970145, 2.6060220013681659],
					[60.462283326077909, 2.6060220015443289],
					[60.462283322421975, 2.6060220004421315],
					[60.462283320637489, 2.6060220007944608],
					[60.462230623236948, 2.6060165016916823],
					[60.462166726216921, 2.6060081011183267],
					[60.462166723540193, 2.6060081016468186],
					[60.462166720776494, 2.6060081003684634],
					[60.462166719884259, 2.6060081005446278],
					[60.46211112037922, 2.6060026017208555],
					[60.462111116723285, 2.6060026006186652],
					[60.462111112175101, 2.6060025996926406],
					[60.46211111039063, 2.6060026000449654],
					[60.462052809841651, 2.6059997996333109],
					[60.462052807164945, 2.6059998001617992],
					[60.462052801724518, 2.6059997994119226],
					[60.462052799940039, 2.605999799764251],
					[60.461994400070537, 2.6059998005849678],
					[60.46193332137225, 2.6059997995158524],
					[60.461880644644154, 2.6059887058124227],
					[60.461813949426762, 2.6059720059278502],
					[60.461813946750063, 2.6059720064563363],
					[60.461813943094128, 2.6059720053541566],
					[60.461813941222637, 2.6059720038996619],
					[60.461761148089664, 2.6059609058927151],
					[60.461691754060098, 2.6059415068394487],
					[60.461691752275634, 2.6059415071917718],
					[60.461691746835228, 2.6059415064419218],
					[60.461691744963737, 2.6059415049874159],
					[60.461655658681423, 2.6059331076032319],
					[60.46161947163592, 2.6059192125552424],
					[60.461619469851456, 2.6059192129075668],
					[60.461619467087743, 2.60591921162924],
					[60.461619466195508, 2.6059192118053995],
					[60.461564001338957, 2.6058998224470145],
					[60.461527938424631, 2.6058692554360028],
					[60.461436244934369, 2.6057720635711985],
					[60.461436231747093, 2.6057720515830667],
					[60.461436203675071, 2.6057720297659217],
					[60.461436188877322, 2.6057720217437206],
					[60.461358388878665, 2.6057331211467818],
					[60.461358382459018, 2.6057331187663384],
					[60.461358366856018, 2.6057331127271395],
					[60.461358358564901, 2.6057331088922142],
					[60.461322346994635, 2.6057220358710675],
					[60.461302983433818, 2.6056777207251112],
					[60.461302980409137, 2.6056777140264136],
					[60.461302974359747, 2.6056777006290397],
					[60.461302969550609, 2.6056776942826727],
					[60.461230770359663, 2.605560995728383],
					[60.461230768314188, 2.605560990660353],
					[60.461230762612786, 2.6055609844901872],
					[60.461230759762095, 2.6055609814051137],
					[60.461158470307367, 2.6054637920757364],
					[60.461105777275172, 2.6053638063118192],
					[60.461105759017691, 2.6053637825573275],
					[60.461105718302548, 2.6053637413495725],
					[60.461105694952657, 2.6053637240724825],
					[60.461033394614162, 2.6053248241264408],
					[60.461033391850449, 2.6053248228481656],
					[60.461033388107509, 2.6053248199393049],
					[60.461033385343782, 2.6053248186610514],
					[60.460986236832468, 2.6053026448966174],
					[60.460941878093337, 2.6052332070440891],
					[60.460889084398879, 2.6051082211858398],
					[60.460889082353397, 2.6051082161178907],
					[60.46088907558574, 2.6051082065105069],
					[60.460889074432494, 2.6051082012663609],
					[60.460833474413462, 2.6050110014923513],
					[60.460833473434221, 2.6050109998617681],
					[60.460833473347208, 2.605010998054988],
					[60.460833472454979, 2.6050109982311693],
					[60.460761279316372, 2.6048888096225173],
					[60.460716883675794, 2.604783222219397],
					[60.460716882609546, 2.6047832187820656],
					[60.460716879671821, 2.6047832138903311],
					[60.460716878605581, 2.6047832104530002],
					[60.460655778908276, 2.6046610117383295],
					[60.460655777929034, 2.6046610101077627],
					[60.460655776949793, 2.6046610084771795],
					[60.460655776862779, 2.6046610066704257],
					[60.460602977137306, 2.6045610082273303],
					[60.460544578215256, 2.6044471084712471],
					[60.460544577236014, 2.6044471068406927],
					[60.460544574298275, 2.6044471019490159],
					[60.460544573319034, 2.6044471003184597],
					[60.460483473076927, 2.6043415991946661],
					[60.460483472097678, 2.6043415975641206],
					[60.460483469159939, 2.6043415926724633],
					[60.460483467201456, 2.6043415894113648],
					[60.460419572761595, 2.604244297314307],
					[60.460358476875648, 2.6041277066288644],
					[60.460358473937909, 2.6041277017372595],
					[60.460358468954652, 2.6041276917778147],
					[60.460358465211662, 2.6041276888691534],
					[60.460311264538731, 2.6040581876529623],
					[60.460311254920263, 2.6040581749611098],
					[60.4603112321144, 2.6040581502822024],
					[60.460311219906238, 2.604058139925697],
					[60.46026682015345, 2.6040248395437051],
					[60.460266804376353, 2.6040248298920243],
					[60.460266770319507, 2.6040248147307667],
					[60.460266752844952, 2.6040248072382428],
					[60.460205661662407, 2.6040081105686306],
					[60.4601333826703, 2.6039804182767954],
					[60.460086239675952, 2.6039554480123468],
					[60.460041869342767, 2.6038859923481077],
					[60.460041867384255, 2.6038859890870696],
					[60.460041862662024, 2.6038859845479467],
					[60.460041859811305, 2.6038859814631117],
					[60.459969560251494, 2.6037887810544165],
					[60.459969477165828, 2.6037887390982384],
					[60.45996931177654, 2.6037887462265674],
					[60.459969231083321, 2.6037887913452007],
					[60.458683213868184, 2.60580256276547],
					[60.458630665789258, 2.6057859112862616],
					[60.458558372260697, 2.6057581132853884],
					[60.458558368604749, 2.6057581121833238],
					[60.458558362185109, 2.6057581098030473],
					[60.458558359421382, 2.6057581085248422],
					[60.458522270107522, 2.6057470114630474],
					[60.458477886575089, 2.6057276193628147],
					[60.458425092812526, 2.6056998238590308],
					[60.4584250890696, 2.6056998209503401],
					[60.458425079886254, 2.6056998172918848],
					[60.458425076143307, 2.6056998143831827],
					[60.458377885774311, 2.6056804184357798],
					[60.45830569473484, 2.6056415246479339],
					[60.458305692863362, 2.6056415231935994],
					[60.458305690991907, 2.6056415217392601],
					[60.458305689120422, 2.6056415202849283],
					[60.458233402323792, 2.6056054268602669],
					[60.458180713093775, 2.6055692343957184],
					[60.458180709350835, 2.6055692314870686],
					[60.458180700167496, 2.6055692278286537],
					[60.458180696337557, 2.6055692231133807],
					[60.45808901263463, 2.6055192338989528],
					[60.458072326731973, 2.6055054464291518],
					[60.458072321030571, 2.6055054402595808],
					[60.458072307038037, 2.6055054302554659],
					[60.458072299639177, 2.6055054262448007],
					[60.458052900165079, 2.6054942262746619],
					[60.457961204047784, 2.605441529748775],
					[60.457900107748067, 2.6054026314467809],
					[60.457836208647436, 2.605360932696537],
					[60.4578362077552, 2.6053609328726872],
					[60.457836205883723, 2.6053609314183874],
					[60.457836205796731, 2.6053609296117886],
					[60.457755707637901, 2.6053109326025083],
					[60.4577307110928, 2.6052942339703753],
					[60.457730709221337, 2.6052942325160644],
					[60.457730704586155, 2.6052942297836323],
					[60.457730702714684, 2.605294228329337],
					[60.457702903316537, 2.6052776271367275],
					[60.457702901532066, 2.605277627489031],
					[60.457702900639831, 2.6052776276651812],
					[60.457702899747602, 2.6052776278413314],
					[60.457630703119243, 2.605235928155313],
					[60.457577907247654, 2.6052026303760245],
					[60.457577905463175, 2.6052026307283316],
					[60.457577901720228, 2.6052026278197706],
					[60.457577900741001, 2.605202626189318],
					[60.457511200884987, 2.605163726201305],
					[60.457444501019786, 2.6051248263654077],
					[60.45744449557936, 2.6051248256157518],
					[60.457444487201229, 2.6051248199748067],
					[60.457444481673811, 2.605124817418575],
					[60.457388996199654, 2.6050998238391414],
					[60.457333408645404, 2.6050637329045752],
					[60.457333401159502, 2.6050637270875092],
					[60.45733338270577, 2.6050637179645819],
					[60.457333375306895, 2.6050637139540846],
					[60.457291792211812, 2.6050470204173743],
					[60.457261206747532, 2.6050276304089612],
					[60.457261205855282, 2.6050276305851212],
					[60.457261203983819, 2.6050276291308605],
					[60.457261203091583, 2.6050276293070169],
					[60.457191803068163, 2.6049859293084285],
					[60.457191794690026, 2.6049859236675603],
					[60.457191779979262, 2.6049859174532144],
					[60.457191771688123, 2.6049859136189246],
					[60.457119484278024, 2.6049581172112113],
					[60.457052895126672, 2.6049220231437915],
					[60.457052894234437, 2.6049220233199448],
					[60.457052890578503, 2.6049220222179938],
					[60.457052888707025, 2.6049220207637536],
					[60.456975089182663, 2.6048831212246122],
					[60.456975088203421, 2.6048831195942195],
					[60.456975084547466, 2.6048831184922956],
					[60.456975083655244, 2.6048831186684529],
					[60.456938995186661, 2.6048665242947111],
					[60.456894505826092, 2.6048387294742756],
					[60.456894503062379, 2.6048387281962015],
					[60.456894496642711, 2.604838725816212],
					[60.456894493792007, 2.6048387227315928],
					[60.456847293649332, 2.6048137227790931],
					[60.456847292757089, 2.604813722955253],
					[60.456847290885598, 2.6048137215010279],
					[60.456847289993362, 2.6048137216771874],
					[60.456813990462997, 2.6047970217919278],
					[60.456813986807035, 2.6047970206900226],
					[60.456813982171845, 2.6047970179577304],
					[60.456813980300382, 2.6047970165034862],
					[60.456730688013998, 2.6047609199176827],
					[60.456669496429001, 2.6047276235883201],
					[60.456669492773067, 2.6047276224864193],
					[60.456669487245634, 2.6047276199302902],
					[60.456669483589693, 2.6047276188283934],
					[60.456602884139407, 2.6046970170741996],
					[60.456525084367286, 2.6046609198029964],
					[60.456525084280287, 2.6046609179964713],
					[60.456488990918082, 2.6046442229051037],
					[60.456444498387071, 2.6046192250933498],
					[60.456444494731123, 2.6046192239914694],
					[60.45644448920369, 2.6046192214353936],
					[60.456444486352979, 2.6046192183508148],
					[60.456391787310544, 2.6045942196077099],
					[60.456352892265855, 2.6045748225077894],
					[60.456280695093803, 2.604535923565289],
					[60.456219496065508, 2.6045026240625306],
					[60.456219494281036, 2.6045026244148541],
					[60.456219490538061, 2.6045026215064904],
					[60.456219489645825, 2.6045026216826521],
					[60.456158393657944, 2.6044720228930207],
					[60.456075098915065, 2.6044248256702316],
					[60.456075096151352, 2.6044248243922037],
					[60.456075092408398, 2.6044248214838581],
					[60.456075088752456, 2.6044248203820093],
					[60.456047295171082, 2.6044109240518005],
					[60.456014006279517, 2.6043915292628319],
					[60.455947311337816, 2.6043470347675912],
					[60.455947307594862, 2.6043470318592474],
					[60.455947302872652, 2.6043470273205926],
					[60.455947300195952, 2.6043470278490837],
					[60.455894504673381, 2.6043165304953351],
					[60.455822311838574, 2.6042692337611233],
					[60.455741819402725, 2.604213739091827],
					[60.455714025080752, 2.604191543727699],
					[60.455714024101511, 2.6041915420973889],
					[60.455714021337783, 2.604191540819397],
					[60.455714020358542, 2.6041915391890842],
					[60.455680719955097, 2.6041665393192432],
					[60.455680719062876, 2.6041665394954094],
					[60.455680715319914, 2.6041665365871238],
					[60.455680714427679, 2.6041665367632931],
					[60.455608420279454, 2.6041165397490151],
					[60.455550125523366, 2.6040692442931972],
					[60.455550124544139, 2.6040692426628942],
					[60.455550120888169, 2.6040692415610889],
					[60.455550119908928, 2.6040692399307868],
					[60.455483422719894, 2.6040192415117271],
					[60.455411225912691, 2.6039609447051912],
					[60.455411221190467, 2.6039609401666457],
					[60.455411212812315, 2.6039609345263224],
					[60.455411208177104, 2.6039609317942576],
					[60.455372338558369, 2.6039359505905457],
					[60.45535576026397, 2.6039137805170727],
					[60.455355749840294, 2.6039137698097456],
					[60.455355728992934, 2.6039137483950672],
					[60.455355717677023, 2.6039137378639072],
					[60.455264020893566, 2.6038470406041583],
					[60.455186224361441, 2.6037859421736154],
					[60.455186222576977, 2.6037859425259651],
					[60.455147324018156, 2.6037554436227746],
					[60.455105724979795, 2.6037220441181859],
					[60.455047325624044, 2.6036748436317607],
					[60.455047322860331, 2.6036748423538492],
					[60.455047316266608, 2.6036748363613089],
					[60.455047312610674, 2.6036748352595716],
					[60.454994512679555, 2.6036387347954855],
					[60.45492231363712, 2.6035887357088092],
					[60.454922312744884, 2.6035887358849874],
					[60.454922310786387, 2.6035887326244582],
					[60.454922309894151, 2.6035887328006373],
					[60.454841811823414, 2.6035359339572302],
					[60.454814014292822, 2.6035165359605386],
					[60.454814010636873, 2.6035165348588252],
					[60.454814004043165, 2.603516528866356],
					[60.454814001279431, 2.6035165275884702],
					[60.454780700813572, 2.6034970272314735],
					[60.454780699029101, 2.6034970275838321],
					[60.454780696178361, 2.6034970244995219],
					[60.454780695286125, 2.6034970246757014],
					[60.454708402323661, 2.603458128622933],
					[60.454661209062401, 2.6034276326166803],
					[60.454661203447955, 2.6034276282544893],
					[60.454661189629341, 2.6034276218651109],
					[60.454661184014881, 2.6034276175029198],
					[60.454588990187894, 2.6033942203666545],
					[60.454527896275749, 2.6033609238303477],
					[60.454527891727551, 2.6033609229048271],
					[60.454527885220848, 2.6033609187188658],
					[60.454527881564907, 2.6033609176171795],
					[60.454472281842463, 2.6033359186945404],
					[60.454472278991723, 2.6033359156102729],
					[60.45447227257204, 2.6033359132307123],
					[60.454472269895334, 2.6033359137592593],
					[60.454383375961164, 2.6033026142242495],
					[60.454358385114027, 2.6032915196218527],
					[60.454330690366469, 2.6032776217911753],
					[60.454330684839036, 2.6032776192354499],
					[60.454330675655626, 2.6032776155780768],
					[60.454330671912651, 2.6032776126699892],
					[60.45425839225927, 2.6032498205588817],
					[60.454225110971734, 2.6032276344980967],
					[60.454225101614291, 2.6032276272279424],
					[60.454225082268223, 2.603227618282999],
					[60.454225072192592, 2.6032276148018303],
					[60.454138983762874, 2.6031942176833498],
					[60.454077897123796, 2.6031609241390528],
					[60.454014003990807, 2.6031248295290621],
					[60.453933410324844, 2.6030720337977069],
					[60.453933402925898, 2.6030720297880587],
					[60.453933388128021, 2.6030720217687433],
					[60.453933380729083, 2.6030720177590778],
					[60.453908408146916, 2.603060928975943],
					[60.453889029836986, 2.6030442477505278],
					[60.453808431624424, 2.6029748498174667],
					[60.453727934431058, 2.6029026513480344],
					[60.453727932559559, 2.6029026498940295],
					[60.453727930688082, 2.6029026484400379],
					[60.453727929795846, 2.6029026486162272],
					[60.453689036432905, 2.6028692533667672],
					[60.453647347880825, 2.6028276644258121],
					[60.45357515323515, 2.6027415710891013],
					[60.453575150384388, 2.6027415680049657],
					[60.453575143790644, 2.60274156201287],
					[60.453575140939897, 2.6027415589287162],
					[60.453483460082388, 2.6026498771744535],
					[60.453464072878148, 2.6026165984853993],
					[60.453464067089605, 2.6026165905108072],
					[60.453464054620277, 2.6026165747378296],
					[60.453464048831741, 2.6026165667632353],
					[60.453439053047951, 2.6025887703371278],
					[60.453358457836607, 2.6024859776474925],
					[60.453286259054153, 2.6023915785958831],
					[60.4532862552241, 2.6023915738816705],
					[60.453286249435557, 2.6023915659071606],
					[60.453286244713283, 2.6023915613691244],
					[60.453230753626954, 2.6023331729544195],
					[60.453186261611151, 2.6022720819470604],
					[60.453186259739645, 2.602272080493131],
					[60.453186257781113, 2.6022720772328714],
					[60.45318625680185, 2.6022720756027242],
					[60.45313345675693, 2.6022054754496038],
					[60.453133452926892, 2.6022054707354108],
					[60.453133445353863, 2.6022054631133753],
					[60.453133441523839, 2.6022054583992],
					[60.45308074825644, 2.6021526650947862],
					[60.453014053963024, 2.6020720712593026],
					[60.453014052091518, 2.6020720698053941],
					[60.453014046389967, 2.6020720636373285],
					[60.453014043539198, 2.602072060553303],
					[60.452933444060214, 2.6019887608875578],
					[60.452933442188701, 2.6019887594336581],
					[60.452933438358663, 2.6019887547195371],
					[60.452933435594936, 2.6019887534418422],
					[60.452897344548006, 2.6019554612265661],
					[60.452869554395619, 2.6019220724051446],
					[60.452869550652615, 2.6019220694973368],
					[60.452869544864029, 2.6019220615230094],
					[60.452869541121025, 2.601922058615219],
					[60.452789041324884, 2.6018415591060071],
					[60.452789040345628, 2.6018415574758893],
					[60.452789038387074, 2.601841554215699],
					[60.452789035710381, 2.6018415547443272],
					[60.452708438838087, 2.6017665562195704],
					[60.452675142122153, 2.6017331593460815],
					[60.452675139271371, 2.6017331562621022],
					[60.452675132677598, 2.6017331502703662],
					[60.452675129826815, 2.601733147186402],
					[60.452636237424137, 2.6016998544140679],
					[60.452564044467621, 2.6016248616419468],
					[60.452564041616839, 2.6016248585580022],
					[60.452564037873842, 2.6016248556502517],
					[60.452564036002329, 2.6016248541963876],
					[60.452483452210529, 2.6015498686862895],
					[60.452458463989743, 2.6015137864498366],
					[60.45245845526329, 2.6015137735854501],
					[60.452458432631147, 2.6015137525265866],
					[60.4524584222073, 2.6015137418209182],
					[60.452411238109768, 2.6014776542601208],
					[60.452339051317807, 2.6013942696014314],
					[60.45233905033853, 2.6013942679713704],
					[60.452339049359246, 2.6013942663413081],
					[60.452339048379969, 2.6013942647112485],
					[60.452252947753394, 2.6012998649135985],
					[60.452252945881874, 2.6012998634597646],
					[60.452252943031105, 2.6012998603758719],
					[60.452252941159607, 2.6012998589220229],
					[60.452225156931604, 2.6012720736262334],
					[60.452205769022228, 2.6012415924817951],
					[60.452205764125829, 2.6012415843315266],
					[60.452205752722698, 2.60124157199599],
					[60.452205747021125, 2.6012415658282189],
					[60.452114054292814, 2.6011415718796287],
					[60.452041860871027, 2.6010442806217502],
					[60.45204185802023, 2.6010442775378868],
					[60.452041851339374, 2.6010442697401568],
					[60.452041847596355, 2.6010442668325195],
					[60.451989060116752, 2.6009859796778789],
					[60.451941869229522, 2.6009109931033358],
					[60.451941865312385, 2.6009109865832101],
					[60.451941855780738, 2.6009109757016704],
					[60.451941850971394, 2.6009109693577863],
					[60.451889059818363, 2.600849878839155],
					[60.45185576569444, 2.6007998897624534],
					[60.451855762756601, 2.6007998848723726],
					[60.451855756967966, 2.6007998768984866],
					[60.451855753050836, 2.6007998703783892],
					[60.451769557130525, 2.6006970752341059],
					[60.451697360802015, 2.600599881305909],
					[60.451697359822738, 2.6005998796759018],
					[60.451697357864155, 2.6005998764158744],
					[60.45169735697192, 2.600599876592113],
					[60.451655756758889, 2.6005470758668956],
					[60.451655755779598, 2.6005470742368955],
					[60.451611258613511, 2.6004915782673454],
					[60.451558462127146, 2.6004192814015044],
					[60.45155845838412, 2.6004192784939963],
					[60.451558451703207, 2.6004192706964928],
					[60.451558447873126, 2.6004192659827359],
					[60.451502950316865, 2.6003581681344405],
					[60.451439051856788, 2.6002831706191611],
					[60.451439049898227, 2.600283167359188],
					[60.451439044196604, 2.6002831611917236],
					[60.451439041345807, 2.6002831581079988],
					[60.451366840624189, 2.6002109587244906],
					[60.451366837773392, 2.6002109556407609],
					[60.451366831179541, 2.6002109496495827],
					[60.451366827436516, 2.6002109467420982],
					[60.451319543056577, 2.6001720593056974],
					[60.451286255602234, 2.600130474558493],
					[60.451286248921328, 2.6001304667611089],
					[60.451286233775043, 2.6001304515188379],
					[60.451286226288971, 2.6001304457039174],
					[60.451214025829827, 2.600072044702618],
					[60.4512140239583, 2.6000720432489],
					[60.451214021107518, 2.6000720401652098],
					[60.451214019235962, 2.6000720387114966],
					[60.451150121163408, 2.600024840212289],
					[60.451089021610521, 2.5999776415669769],
					[60.45108901875971, 2.5999776384833098],
					[60.451089013145143, 2.5999776341221699],
					[60.451089010381395, 2.5999776328447095],
					[60.451008410312809, 2.5999248321358808],
					[60.45100840015003, 2.5999248268498252],
					[60.451008378932258, 2.5999248164539308],
					[60.451008367877265, 2.599924811344108],
					[60.45097787857496, 2.599913716075541],
					[60.450961189159166, 2.5999054215299382],
					[60.450961188179875, 2.599905419899974],
					[60.450961185416134, 2.5999054186225226],
					[60.450961183631655, 2.599905418975029],
					[60.45088898383807, 2.5998720176521606],
					[60.450888982053613, 2.5998720180046613],
					[60.450888979289864, 2.5998720167272191],
					[60.450888978310573, 2.5998720150972758],
					[60.450836177835193, 2.5998498156451015],
					[60.450836169631003, 2.5998498136189609],
					[60.450836153135569, 2.5998498077605192],
					[60.450836144931401, 2.5998498057343737],
					[60.45076394766869, 2.5998331064023685],
					[60.450711150929294, 2.5998192064078864],
					[60.450711146381103, 2.5998192054829534],
					[60.450711137284678, 2.5998192036330945],
					[60.450711132736473, 2.5998192027081655],
					[60.450644433232192, 2.5998081038104304],
					[60.450644430468451, 2.5998081025329984],
					[60.450644424048718, 2.5998081001543922],
					[60.45064442137204, 2.5998081006831435],
					[60.450591720577599, 2.5998026003645558],
					[60.450591719685377, 2.5998026005408055],
					[60.450591718793149, 2.5998026007170574],
					[60.45059171790092, 2.5998026008933062],
					[60.450530619393398, 2.5997970017320458],
					[60.450477821280359, 2.5997915006062891],
					[60.450477817711459, 2.5997915013112878],
					[60.450477812271018, 2.5997915005626169],
					[60.450477810399491, 2.5997914991089459],
					[60.450419410328557, 2.5997887000507047],
					[60.450419409436307, 2.5997887002269584],
					[60.450358317072201, 2.5997859005804194],
					[60.450313924635751, 2.5997804019501012],
					[60.450313920087559, 2.5997804010251824],
					[60.450313911970426, 2.5997804008052614],
					[60.45031390831447, 2.5997803997040951],
					[60.450244414016339, 2.5997776005111768],
					[60.450188924261646, 2.599772000254728],
					[60.450127830730729, 2.5997637016248349],
					[60.450080636292832, 2.5997554033989956],
					[60.450008343388994, 2.5997415040585046],
					[60.449963951328328, 2.5997304077730274],
					[60.44988335313117, 2.5997081061608553],
					[60.449883350454492, 2.5997081066895968],
					[60.449883343142524, 2.599708104487306],
					[60.449883338594333, 2.5997081035624046],
					[60.449855660104397, 2.5997026073456544],
					[60.449836181002702, 2.5996942169400916],
					[60.449769483024177, 2.5996637179027053],
					[60.449769479368207, 2.5996637168015648],
					[60.449769471076962, 2.5996637129693867],
					[60.449769465549473, 2.5996637104146094],
					[60.449713980623827, 2.5996442156430009],
					[60.449641797418529, 2.599605424797272],
					[60.44957789880656, 2.5995692258709138],
					[60.449577897914338, 2.5995692260471635],
					[60.449577896042825, 2.5995692245935387],
					[60.449577896042825, 2.5995692245935387],
					[60.449516805282784, 2.5995359281550643],
					[60.44944452002936, 2.5994859408696969],
					[60.449416824595659, 2.5994637448493445],
					[60.449416821744848, 2.5994637417658724],
					[60.449416815150975, 2.5994637357751662],
					[60.44941681060277, 2.5994637348503105],
					[60.449391818538089, 2.5994470381840542],
					[60.449305727275437, 2.5993776453883255],
					[60.449225127822757, 2.5993109459545307],
					[60.449225124079717, 2.5993109430473438],
					[60.449225116593595, 2.5993109372329593],
					[60.449225112850549, 2.5993109343257701],
					[60.449180739176413, 2.5992804534927347],
					[60.449152958868865, 2.5992442789205836],
					[60.449152953167236, 2.59924427275371],
					[60.449152941676857, 2.5992442586138744],
					[60.449152935975206, 2.599244252447019],
					[60.449075137465712, 2.5991720555294937],
					[60.449000145746254, 2.5990998623913586],
					[60.44896685163522, 2.5990609703799548],
					[60.4489668506559, 2.5990609687501314],
					[60.448966847805082, 2.599060965666717],
					[60.448966845933555, 2.5990609642131504],
					[60.448927946281579, 2.5990192632334987],
					[60.448927945302273, 2.5990192616036754],
					[60.44892794343076, 2.599019260150091],
					[60.448927940666984, 2.5990192588727936],
					[60.448841849250655, 2.5989331668543989],
					[60.448758454893593, 2.5988304738952617],
					[60.44875845391428, 2.5988304722654556],
					[60.44875845204276, 2.5988304708118881],
					[60.448758451063455, 2.5988304691820807],
					[60.448739050635737, 2.5988081687918112],
					[60.448739046805592, 2.5988081640786338],
					[60.448739038340179, 2.5988081566346355],
					[60.44873903353075, 2.5988081502916658],
					[60.448711245660135, 2.5987831620514834],
					[60.448630755824048, 2.5986831742864065],
					[60.448630753952528, 2.598683172832875],
					[60.448630751993917, 2.5986831695732753],
					[60.448630750122376, 2.5986831681197278],
					[60.448550158919907, 2.5985915787720453],
					[60.448516866383045, 2.5985415892436654],
					[60.448516858722741, 2.598541579817458],
					[60.448516843402167, 2.5985415609650397],
					[60.44851683395742, 2.5985415518913721],
					[60.448458449244143, 2.5984887654226325],
					[60.4484057622156, 2.5984165825833299],
					[60.448405757406142, 2.5984165762404849],
					[60.448405748853617, 2.5984165669906103],
					[60.448405744131236, 2.5984165624538029],
					[60.44834454560376, 2.59835266344477],
					[60.448280746868512, 2.5982831650165186],
					[60.448280746781414, 2.598283163210477],
					[60.448280745889193, 2.5982831633867507],
					[60.448208448833306, 2.5982054658690217],
					[60.448172351216904, 2.59816376929099],
					[60.448136250737882, 2.598122069720822],
					[60.448136249758576, 2.5981220680910653],
					[60.448136247799951, 2.5981220648315504],
					[60.448136246820638, 2.5981220632017776],
					[60.448066846831352, 2.5980470648681617],
					[60.448066844959811, 2.5980470634146928],
					[60.448066843001186, 2.5980470601551713],
					[60.448066841129645, 2.5980470587016997],
					[60.447994541442803, 2.5979748579354225],
					[60.447994539571276, 2.5979748564819567],
					[60.447994537699714, 2.5979748550284989],
					[60.447994535828187, 2.5979748535750149],
					[60.447955735648151, 2.5979387545947721],
					[60.447955734668845, 2.5979387529650264],
					[60.44795573271022, 2.597938749705524],
					[60.447955731817999, 2.5979387498818056],
					[60.447911231872816, 2.5978998498844845],
					[60.447911230893503, 2.5978998482547437],
					[60.447911229021962, 2.5978998468012908],
					[60.447841828839465, 2.597841546592301],
					[60.447783429564574, 2.5977915474949982],
					[60.447783428672352, 2.5977915476712821],
					[60.447783427693018, 2.5977915460415573],
					[60.447783426713727, 2.5977915444118209],
					[60.447722327014588, 2.5977415448339087],
					[60.447722325143047, 2.5977415433804691],
					[60.447722319615508, 2.5977415408261657],
					[60.447722317743967, 2.5977415393727097],
					[60.447650119324116, 2.5976887390836585],
					[60.447616820033971, 2.59766374029615],
					[60.447616817183111, 2.5976637372129847],
					[60.447616810763357, 2.5976637348349874],
					[60.44761680791251, 2.5976637317518372],
					[60.447577913427267, 2.5976387354831449],
					[60.447497318129429, 2.597580438620708],
					[60.447425117871319, 2.5975276393815325],
					[60.447425116891999, 2.597527637751825],
					[60.447425115020458, 2.5975276362984108],
					[60.44742511314891, 2.5975276348449983],
					[60.447380713399028, 2.5974970354722839],
					[60.447327912965292, 2.5974609345047526],
					[60.447327912073057, 2.5974609346810471],
					[60.447327910201516, 2.5974609332276217],
					[60.447327909222182, 2.5974609315979253],
					[60.447272323350028, 2.5974248414417067],
					[60.447236236407214, 2.5973915533144911],
					[60.447236228921028, 2.5973915475008797],
					[60.447236215820219, 2.5973915373270744],
					[60.44723621020561, 2.5973915329668742],
					[60.447155715178525, 2.5973387362568645],
					[60.447075142282117, 2.5972776578553205],
					[60.447055758131953, 2.5972526761584289],
					[60.447055744944024, 2.5972526641787441],
					[60.447055717588896, 2.5972526385897003],
					[60.447055702703622, 2.5972526287685951],
					[60.447014013192032, 2.5972276332228894],
					[60.44694182311715, 2.5971720421728355],
					[60.446877929237225, 2.5971220464470863],
					[60.446822333702187, 2.5970720502340572],
					[60.446822329959112, 2.5970720473273139],
					[60.44682232158069, 2.5970720416901441],
					[60.446822317837587, 2.5970720387834216],
					[60.446750122849338, 2.5970192424407923],
					[60.44671682837815, 2.5969915457226387],
					[60.446716824722138, 2.5969915446218876],
					[60.446716819020395, 2.5969915384558528],
					[60.446716816256618, 2.5969915371788006],
					[60.446677922605474, 2.5969637417818778],
					[60.446605729028185, 2.5969026465808009],
					[60.446605725285067, 2.5969026436741007],
					[60.446605717798896, 2.5969026378607025],
					[60.446605714055785, 2.5969026349540174],
					[60.446525114193612, 2.5968470351868458],
					[60.446525112322057, 2.5968470337335079],
					[60.446525107773837, 2.5968470328090789],
					[60.446525105815176, 2.5968470295497816],
					[60.44648071605198, 2.5968192375485701],
					[60.446439025236835, 2.5967859442165584],
					[60.446439018642842, 2.5967859382269349],
					[60.446439005629117, 2.5967859298595228],
					[60.446438999122236, 2.5967859256758379],
					[60.446380701330263, 2.5967526268408028],
					[60.446339003449509, 2.596727628578051],
					[60.446339001665081, 2.5967276289306582],
					[60.446339000685747, 2.596727627301016],
					[60.446338999793525, 2.5967276274773203],
					[60.44626680027595, 2.5966859273247906],
					[60.446266794748404, 2.5966859247707657],
					[60.44626678271397, 2.596685918033077],
					[60.446266778078659, 2.5966859153027464],
					[60.446194485702406, 2.5966554186299113],
					[60.446141792824768, 2.5966276233922985],
					[60.446141787297201, 2.5966276208382806],
					[60.446141776242115, 2.5966276157302661],
					[60.446141769735235, 2.5966276115466287],
					[60.446066771044478, 2.5965998121130842],
					[60.446030672388069, 2.5965859136868503],
					[60.44603066783985, 2.596585912762464],
					[60.446030661420082, 2.5965859103847633],
					[60.446030657676971, 2.5965859074781612],
					[60.44597505828812, 2.5965692080763514],
					[60.44593066525583, 2.5965554118200429],
					[60.445908370708594, 2.5965470135029864],
					[60.445908364288798, 2.5965470111253208],
					[60.445908352341476, 2.5965470061936569],
					[60.445908345116578, 2.5965470057982012],
					[60.445825046957971, 2.596527606843237],
					[60.445769449222681, 2.5965137057794023],
					[60.445769441997783, 2.5965137053839547],
					[60.445769429245352, 2.5965137024345428],
					[60.44576942291269, 2.5965137018627695],
					[60.445697222852829, 2.596505400510976],
					[60.445697211166838, 2.5965054009970561],
					[60.445697188687042, 2.5965054017929186],
					[60.445697176913917, 2.5965054004731067],
					[60.445624976852564, 2.5965137003600982],
					[60.445624969714792, 2.5965137017705389],
					[60.445624956331436, 2.5965137044151234],
					[60.44562494928077, 2.5965137076314515],
					[60.445583248628914, 2.5965248063882975],
					[60.44558321953398, 2.5965248194299066],
					[60.445583167132966, 2.5965248534847958],
					[60.44558314382688, 2.5965248744980447],
					[60.445538743723539, 2.5965804743865886],
					[60.445538728795867, 2.5965805010366227],
					[60.445538707057672, 2.5965805545558327],
					[60.445538701226454, 2.5965805830545952],
					[60.445530400594897, 2.5966832832318349],
					[60.445530399963992, 2.5966832888258122],
					[60.4455304003124, 2.596683296049378],
					[60.445530399594382, 2.5966832998374514],
					[60.445530399693745, 2.5968000001690537],
					[60.445530399867934, 2.5968000037808285],
					[60.445530400216349, 2.5968000110043947],
					[60.445530401282774, 2.5968000144398746],
					[60.445538700184386, 2.5969167066815073],
					[60.445538699781068, 2.5970305995390861],
					[60.445538700033538, 2.5971528001246091],
					[60.44553870029484, 2.597152805542291],
					[60.445538700730324, 2.597152814571754],
					[60.445538700991612, 2.5971528199894216],
					[60.445549800066267, 2.5972611106057841],
					[60.44554980041945, 2.5973861005294148],
					[60.445549800082908, 2.5974944000852749],
					[60.445549800257083, 2.597494403697056],
					[60.445549799800318, 2.5974944129028081],
					[60.445549800866736, 2.5974944163383089],
					[60.445560899791815, 2.5976222088151046],
					[60.445560900265775, 2.5977250006337771],
					[60.445560900087607, 2.5978583003400488],
					[60.445560900261782, 2.5978583039518313],
					[60.445560899804995, 2.5978583131575825],
					[60.445560900871406, 2.5978583165931144],
					[60.445569200082836, 2.5979556094108074],
					[60.445569199657172, 2.5980944003487618],
					[60.44556919991841, 2.5980944057664463],
					[60.445569201420234, 2.5980944182314585],
					[60.445569200789258, 2.5980944238254189],
					[60.445580400041408, 2.598188912327251],
					[60.445580400341754, 2.5983306000445761],
					[60.445580400209685, 2.5984193996720273],
					[60.445580400296755, 2.5984194014779227],
					[60.445580399578688, 2.5984194052659837],
					[60.445580399665772, 2.5984194070718805],
					[60.445585899825396, 2.5985667045735159],
					[60.445585899858344, 2.5986500002210513],
					[60.445585900404815, 2.5988027996451022],
					[60.445585900310135, 2.5988611005921882],
					[60.445585899679145, 2.5988611061861215],
					[60.44558590118087, 2.5988611186511812],
					[60.445585901355024, 2.5988611222629938],
					[60.445597000158926, 2.5989556126154163],
					[60.445596999614821, 2.5990528003996407],
					[60.445597000121957, 2.5991138996924024],
					[60.445596999777436, 2.5992971997396395],
					[60.445596999970583, 2.5994832999280044],
					[60.445597000073548, 2.5995499998362952],
					[60.445597000160603, 2.5995500016421951],
					[60.445597000421792, 2.5995500070599138],
					[60.44559699961664, 2.5995500090420371],
					[60.445602599126133, 2.5996693881245854],
					[60.445591502036741, 2.599741670710324],
					[60.445591501405708, 2.5997416763042418],
					[60.44559150031774, 2.5997416911039193],
					[60.445591499860832, 2.5997417003096546],
					[60.445591499745625, 2.5997916951765649],
					[60.445583099626532, 2.5999499856932551],
					[60.44557480142268, 2.600044381931053],
					[60.445574800617514, 2.6000443839131626],
					[60.445574800617514, 2.6000443839131626],
					[60.445563700855637, 2.6001860848621243],
					[60.445563700224589, 2.6001860904560297],
					[60.445563699941737, 2.6001861032735776],
					[60.445563700289952, 2.6001861104971851],
					[60.445569198871183, 2.6002916921716408],
					[60.445549805996308, 2.6004332458862125],
					[60.445488723685678, 2.6005721044108459],
					[60.445433160952263, 2.6006554477751482],
					[60.445377704288525, 2.6006859241235727],
					[60.445341610860226, 2.6007054211175542],
					[60.445269331871756, 2.600738710514169],
					[60.445216649164436, 2.6007526066509579],
					[60.445216639436936, 2.6007526103949075],
					[60.445216619981942, 2.6007526178827733],
					[60.445216611146705, 2.6007526214505146],
					[60.445144310860037, 2.6007887205071718],
					[60.445144305593686, 2.6007887233701625],
					[60.445144296845484, 2.6007887287437668],
					[60.445144293363619, 2.6007887312543847],
					[60.445083219654684, 2.6008276139732236],
					[60.445038936894903, 2.6008386844254066],
					[60.444997317295375, 2.6008109373428181],
					[60.444922327079738, 2.6007526440933333],
					[60.444839039781797, 2.6006804570620852],
					[60.444814048983069, 2.6006526662627114],
					[60.444814041409963, 2.6006526586428782],
					[60.444814024479335, 2.6006526437555806],
					[60.444814015121793, 2.6006526364881326],
					[60.444786238893265, 2.6006331528480922],
					[60.444714056836787, 2.6005415766444409],
					[60.444714052027429, 2.6005415703019441],
					[60.444714041603532, 2.6005415595990136],
					[60.444714036794174, 2.6005415532565181],
					[60.444625136868467, 2.6004581535667497],
					[60.444625134996947, 2.600458152113279],
					[60.444625131253922, 2.6004581492063394],
					[60.444625129382402, 2.6004581477528688],
					[60.444589028980843, 2.6004276471469323],
					[60.44458897468526, 2.6004276232310017],
					[60.444588863374044, 2.6004276123971222],
					[60.444588805379084, 2.6004276238495065],
					[60.4445527046067, 2.6004470236119928],
					[60.444552703801527, 2.6004470255940282],
					[60.444552701124834, 2.6004470261225978],
					[60.444552700232599, 2.6004470262987898],
					[60.444499965236439, 2.6004775881175162],
					[60.444472271083185, 2.6004720092815976],
					[60.444427897764371, 2.6004470255085339],
					[60.444427895000636, 2.6004470242312565],
					[60.444427889473147, 2.6004470216767137],
					[60.444427885730121, 2.6004470187697732],
					[60.444375086225705, 2.60042201815132],
					[60.444375045291892, 2.6004220098272715],
					[60.444374964120662, 2.6004220076259048],
					[60.444374923078087, 2.6004220157306031],
					[60.444308223408001, 2.600449814874763],
					[60.444308217249457, 2.6004498179139071],
					[60.444308204932355, 2.6004498239922],
					[60.444308199666018, 2.6004498268551637],
					[60.444255517350051, 2.6004804162939541],
					[60.444213896040495, 2.6004941917133881],
					[60.444155654827618, 2.6004776088336077],
					[60.444155618529059, 2.6004776032402641],
					[60.444155550371349, 2.6004776094070934],
					[60.444155515748477, 2.6004776198900164],
					[60.44411381602022, 2.6004970183396816],
					[60.444113802005674, 2.6004970265761131],
					[60.444113775848116, 2.6004970445024398],
					[60.444113762812854, 2.6004970543685224],
					[60.444030466270284, 2.6005748523044048],
					[60.443941568055607, 2.600652649361809],
					[60.443941568055607, 2.600652649361809],
					[60.443941567163357, 2.6006526495379902],
					[60.443916569462928, 2.6006748494141756],
					[60.443897073707696, 2.6006915438942024],
					[60.443816579447208, 2.6007526411467077],
					[60.443816577662737, 2.6007526414990609],
					[60.443816577662737, 2.6007526414990609],
					[60.443755478841155, 2.6007998403431527],
					[60.443691580571425, 2.6008470393895671],
					[60.443691576197324, 2.6008470420762286],
					[60.443691566730962, 2.6008470512373432],
					[60.443691562443888, 2.600847055729806],
					[60.44363046733293, 2.6009054502435247],
					[60.443583173144361, 2.600944244211743],
					[60.443583169749523, 2.6009442485280077],
					[60.443583165375436, 2.6009442512146612],
					[60.443583161980577, 2.6009442555309299],
					[60.443530465690166, 2.600994251776835],
					[60.443458170868098, 2.601055447648867],
					[60.443458169975877, 2.6010554478250283],
					[60.443458168278468, 2.6010554499831473],
					[60.443458165601754, 2.6010554505116468],
					[60.443377665975198, 2.6011276507186372],
					[60.443377665170011, 2.6011276527005829],
					[60.443377660795896, 2.6011276553871809],
					[60.443377659098481, 2.6011276575453031],
					[60.443358167272599, 2.6011470504898035],
					[60.443333176819863, 2.6011665428268551],
					[60.44333317503542, 2.6011665431791773],
					[60.443333171553512, 2.6011665456896034],
					[60.443333170748325, 2.6011665476715478],
					[60.443261006666546, 2.6012276166708408],
					[60.443191652530082, 2.6012442045369326],
					[60.443191644587039, 2.6012442079281435],
					[60.44319162861391, 2.601244212904763],
					[60.443191620670873, 2.6012442162959593],
					[60.443133222155438, 2.6012692160420565],
					[60.443066623234785, 2.6012970141713256],
					[60.443066618860669, 2.6012970168578868],
					[60.443066610917604, 2.6012970202490586],
					[60.443066607435696, 2.6012970227594505],
					[60.443019306715563, 2.6013220224297777],
					[60.443019297967325, 2.6013220278028539],
					[60.443019281363057, 2.6013220383728672],
					[60.443019272701875, 2.6013220455517225],
					[60.442958172777814, 2.6013720459460443],
					[60.442958171885579, 2.6013720461221985],
					[60.44295816929592, 2.601372048456414],
					[60.442958167598512, 2.6013720506144824],
					[60.442913781747734, 2.6014109378475312],
					[60.442866601915398, 2.6014387261497878],
					[60.442794304964465, 2.6014776237149495],
					[60.442794303180023, 2.6014776240672437],
					[60.442794299698114, 2.6014776265776112],
					[60.442794298000685, 2.6014776287356627],
					[60.442733198183568, 2.60151372855568],
					[60.442733197291332, 2.6015137287318266],
					[60.442733193722418, 2.6015137294364146],
					[60.442733192917217, 2.6015137314183128],
					[60.442680497771356, 2.601547028849919],
					[60.442599902992214, 2.601591525296846],
					[60.442599897725849, 2.6015915281594557],
					[60.442599888085368, 2.6015915337085422],
					[60.442599883711232, 2.6015915363950075],
					[60.44258055608077, 2.6016053854347403],
					[60.442555647921168, 2.6015998063816475],
					[60.442463952849373, 2.6015748075881864],
					[60.442463951957151, 2.6015748077643286],
					[60.442391751702708, 2.6015554067867432],
					[60.442391720931639, 2.6015554037469664],
					[60.442391659824672, 2.6015554066961015],
					[60.44239163038101, 2.6015554125088491],
					[60.442347129563849, 2.6015720126425084],
					[60.44234711903114, 2.6015720183676958],
					[60.442347096094196, 2.6015720283646013],
					[60.442347085648507, 2.6015720358955181],
					[60.44228318591103, 2.6016165353567122],
					[60.442283166978193, 2.601616553677649],
					[60.442283133660695, 2.6016165912445257],
					[60.442283120255311, 2.6016166121200652],
					[60.442241523863942, 2.601702703966303],
					[60.442169233236783, 2.6018192891214533],
					[60.442122044601781, 2.6018859730552402],
					[60.442077686172304, 2.6019331308476814],
					[60.44200552810296, 2.6019609138016828],
					[60.442005524534032, 2.6019609145062019],
					[60.442005518375431, 2.6019609175448397],
					[60.442005513914289, 2.6019609184254868],
					[60.441952716045186, 2.6019859185442789],
					[60.441897173361937, 2.6020108934871509],
					[60.441863942371619, 2.6020054040466674],
					[60.441791752005628, 2.601985907473126],
					[60.441791748349658, 2.6019859063719233],
					[60.441791740145518, 2.6019859043456748],
					[60.441791735597299, 2.6019859034206183],
					[60.441730636057621, 2.6019748030300969],
					[60.44173060992177, 2.6019748027207172],
					[60.441730558716372, 2.6019748055371963],
					[60.441730533733832, 2.6019748104687719],
					[60.441666633743509, 2.6019970108504831],
					[60.441666625800444, 2.6019970142413298],
					[60.441666608129829, 2.6019970213752455],
					[60.441666600273798, 2.6019970265717669],
					[60.441594303126465, 2.6020387248676098],
					[60.441558204908468, 2.6020581239357998],
					[60.441558192678279, 2.6020581318185938],
					[60.441558169197144, 2.60205814921378],
					[60.441558157946226, 2.6020581587261375],
					[60.441524858432182, 2.6020915590534215],
					[60.441524854145072, 2.6020915635454034],
					[60.441524844678611, 2.6020915727055112],
					[60.441524841370772, 2.6020915788270518],
					[60.441452653287172, 2.602185963212154],
					[60.441372071406285, 2.6022581464491847],
					[60.441344275174707, 2.6022804434896694],
					[60.44134427436947, 2.602280445471461],
					[60.441344271692792, 2.6022804459998103],
					[60.441344270800542, 2.6022804461759295],
					[60.441308189849309, 2.6023109316311563],
					[60.4412333343274, 2.6023469615195651],
					[60.441180741838494, 2.6022998590475943],
					[60.441119549570473, 2.6022304674255827],
					[60.441119538254505, 2.6022304568993131],
					[60.441119513838089, 2.602230436199036],
					[60.441119499845414, 2.6022304262011309],
					[60.441066800181055, 2.6021998273375435],
					[60.441066790018382, 2.6021998220521785],
					[60.441066768887829, 2.602199813463236],
					[60.441066757832935, 2.6021998083539835],
					[60.441002870749955, 2.6021804135531594],
					[60.44093068423107, 2.6021470171375021],
					[60.440930678790643, 2.6021470163885576],
					[60.440930669607205, 2.6021470127327615],
					[60.440930665951264, 2.6021470116315779],
					[60.440875071632021, 2.6021276133505582],
					[60.44079727893012, 2.6020942154904447],
					[60.440777894776048, 2.6020859235900393],
					[60.440761211198982, 2.6020748328636372],
					[60.44076120103631, 2.6020748275783547],
					[60.440761179818729, 2.602074817183905],
					[60.440761168763821, 2.6020748120747537],
					[60.440677868732642, 2.6020442113372546],
					[60.440677866948178, 2.6020442116894906],
					[60.440677864184465, 2.6020442104122061],
					[60.440677863292215, 2.6020442105883261],
					[60.440636163031812, 2.6020304094314262],
					[60.440636149387217, 2.6020304066562985],
					[60.440636121205841, 2.6020304012821422],
					[60.440636106756045, 2.6020304004887507],
					[60.440552807212917, 2.602027599555115],
					[60.440552796419084, 2.6020275998629008],
					[60.440552773133987, 2.6020276026363165],
					[60.440552762427188, 2.6020276047497308],
					[60.440480562162463, 2.6020415045078202],
					[60.440480560290972, 2.6020415030544286],
					[60.44048055404533, 2.6020415042872482],
					[60.440480552347893, 2.6020415064451066],
					[60.440436052090767, 2.6020526052448201],
					[60.44043603798908, 2.6020526116739267],
					[60.440436009698679, 2.602052622726533],
					[60.440435997381471, 2.602052628803408],
					[60.440380496628826, 2.6020859296879473],
					[60.440380491362447, 2.6020859325502572],
					[60.440380480916716, 2.6020859400805132],
					[60.440380474845142, 2.6020859449245575],
					[60.4403387749011, 2.6021192448731325],
					[60.440338773116643, 2.6021192452253601],
					[60.440338768829513, 2.6021192497171666],
					[60.440338767132076, 2.6021192518750103],
					[60.440285966753109, 2.6021665508490623],
					[60.440285958178862, 2.6021665598326433],
					[60.440285942814867, 2.6021665774476141],
					[60.440285935132856, 2.6021665862550853],
					[60.440230439940215, 2.6022470795891599],
					[60.440166545023956, 2.6023248722624963],
					[60.440166540018616, 2.6023248805415968],
					[60.440166529833959, 2.6023248934885475],
					[60.440166525720883, 2.6023249015915368],
					[60.440105426068165, 2.6024332024852495],
					[60.440105424370714, 2.6024332046430625],
					[60.440105421062832, 2.6024332107642936],
					[60.440105419452415, 2.6024332147277316],
					[60.440052618722177, 2.6025443138873419],
					[60.439999820807849, 2.6026555105406679],
					[60.439938726888215, 2.6027692992624343],
					[60.439872031710912, 2.602874891385381],
					[60.439799837775531, 2.6029859829964663],
					[60.439766546659307, 2.6030276723304038],
					[60.439747074860385, 2.6030498393761152],
					[60.439666644237512, 2.6030915044766654],
					[60.439594424396311, 2.6030969986580468],
					[60.439533394460298, 2.6030776204054891],
					[60.439461221856803, 2.603022041390592],
					[60.439461217221606, 2.6030220386598448],
					[60.439461207864184, 2.603022031392793],
					[60.43946120322898, 2.6030220286620591],
					[60.439433430174439, 2.6030054453312603],
					[60.439414053215479, 2.6029831713168949],
					[60.439336253748877, 2.6028887723876344],
					[60.439336250898144, 2.6028887693047458],
					[60.439336245109665, 2.6028887613334142],
					[60.439336241366696, 2.6028887584266274],
					[60.439244541407803, 2.6027970595228087],
					[60.439208440948796, 2.602760959824113],
					[60.439172341286358, 2.6027248582222033],
					[60.439172341286358, 2.6027248582222033],
					[60.439172340307103, 2.6027248565927201],
					[60.439172339414867, 2.6027248567688099],
					[60.439091838549807, 2.602647055947747],
					[60.439091834719804, 2.6026470512354556],
					[60.439091825362389, 2.6026470439685894],
					[60.439091820727171, 2.6026470412379217],
					[60.439000131905992, 2.6025776497473192],
					[60.438983440607444, 2.6025609596253778],
					[60.438983431162995, 2.6025609505529967],
					[60.438983409684418, 2.6025609347420855],
					[60.438983399347713, 2.6025609258458151],
					[60.438963998816419, 2.6025498266635014],
					[60.438963995965686, 2.6025498235806719],
					[60.438963987674519, 2.6025498197488699],
					[60.438963984018542, 2.6025498186476832],
					[60.43888618372398, 2.6025137198805877],
					[60.438886179981012, 2.6025137169738763],
					[60.438886170797609, 2.6025137133181864],
					[60.438886166162398, 2.6025137105875547],
					[60.438830666042094, 2.6024942102521562],
					[60.438830663365387, 2.6024942107804345],
					[60.438830655161247, 2.602494208754178],
					[60.43883065239752, 2.6024942074769228],
					[60.438758352355769, 2.6024748076008128],
					[60.438758348699785, 2.6024748064996572],
					[60.438758344151616, 2.6024748055745675],
					[60.43875834138791, 2.6024748042973123],
					[60.43870564570166, 2.6024637055580815],
					[60.438652851085394, 2.6024498066527806],
					[60.438652848321659, 2.6024498053755343],
					[60.438652842881247, 2.6024498046265636],
					[60.43865284100977, 2.6024498031732204],
					[60.438586151015436, 2.6024359053777477],
					[60.438525078028469, 2.6024165154675836],
					[60.438452895109776, 2.6023776246158858],
					[60.438452892346071, 2.6023776233386489],
					[60.438452886818595, 2.6023776207841665],
					[60.438452883967862, 2.6023776177014164],
					[60.438416821146362, 2.6023609344388996],
					[60.438389047944391, 2.6023304651094317],
					[60.438389046072899, 2.6023304636561089],
					[60.438389043222159, 2.6023304605733713],
					[60.438389041350682, 2.6023304591200449],
					[60.438308447868323, 2.6022498650652541],
					[60.438216868069603, 2.6021387882524443],
					[60.438183477043438, 2.602074906556707],
					[60.438183477043438, 2.602074906556707],
					[60.438183475977169, 2.6020749031217929],
					[60.438183475084934, 2.602074903297892],
					[60.438111278222486, 2.6019443084973961],
					[60.438083487604253, 2.6018860292466681],
					[60.438072391770575, 2.6018471453778931],
					[60.438072389638016, 2.6018471385081194],
					[60.438072385372855, 2.6018471247685913],
					[60.438072383240275, 2.6018471178988198],
					[60.438002985151819, 2.6016916243932786],
					[60.437966886984121, 2.6015971303393233],
					[60.437911287649598, 2.6014471306973368],
					[60.437911286670342, 2.6014471290679673],
					[60.437911285604031, 2.6014471256331224],
					[60.437911284711809, 2.6014471258092353],
					[60.437858489142357, 2.6013166334170092],
					[60.437819590962611, 2.601191640228425],
					[60.437819589983349, 2.6011916385990683],
					[60.437819588830003, 2.6011916333587517],
					[60.437819587850733, 2.6011916317293955],
					[60.437758488364246, 2.6010249318155698],
					[60.437758487384961, 2.6010249301862234],
					[60.43775848631865, 2.6010249267513896],
					[60.43775848631865, 2.6010249267513896],
					[60.437741887170851, 2.6009832293990569],
					[60.43772798828126, 2.6009443326875741],
					[60.43772798828126, 2.6009443326875741],
					[60.437727988194219, 2.600944330882105],
					[60.437727987214949, 2.6009443292527616],
					[60.437666888772128, 2.6007805353242697],
					[60.43763079058494, 2.6006638414768397],
					[60.437630789431594, 2.6006638362365848],
					[60.437630786232646, 2.6006638259321955],
					[60.437630784274099, 2.600663822673523],
					[60.437575185017224, 2.6005332225977549],
					[60.437522384588718, 2.6004027247430206],
					[60.437522384588718, 2.6004027247430206],
					[60.437522383522406, 2.6004027213082317],
					[60.437522384414628, 2.6004027211320926],
					[60.437477983619516, 2.6002999208816759],
					[60.437477980594629, 2.6002999141882954],
					[60.437477974718952, 2.6002999044124211],
					[60.437477971694079, 2.6002998977190175],
					[60.437405772284656, 2.6001776976711786],
					[60.437350172017155, 2.6000831985404345],
					[60.437350170950815, 2.6000831951056758],
					[60.437350168992268, 2.6000831918470806],
					[60.437350167120741, 2.6000831903939359],
					[60.43729736747283, 2.6000026905426674],
					[60.437297361684195, 2.6000026825723395],
					[60.437297350193973, 2.6000026684371282],
					[60.437297344405323, 2.6000026604668047],
					[60.437225144390808, 2.5999276605791306],
					[60.437225141627081, 2.5999276593021445],
					[60.437225138776277, 2.5999276562197249],
					[60.437225136904765, 2.5999276547665739],
					[60.437180737263617, 2.5998859538421337],
					[60.437180731649086, 2.5998859494827258],
					[60.437180721312245, 2.5998859405877726],
					[60.437180714805464, 2.5998859364045344],
					[60.437133415454568, 2.599852636241951],
					[60.43713341171155, 2.5998526333356802],
					[60.437133405204769, 2.599852629152454],
					[60.437133402441034, 2.5998526278754812],
					[60.437072302309744, 2.5998165281420613],
					[60.437072298566711, 2.5998165252358012],
					[60.437072293039215, 2.5998165226818672],
					[60.437072290275488, 2.5998165214048954],
					[60.437033389901465, 2.5997970208347505],
					[60.437033384373983, 2.599797018280801],
					[60.437033372426761, 2.5997970133490798],
					[60.437033365114829, 2.5997970111474547],
					[60.436961164579046, 2.5997720102938016],
					[60.436961158246383, 2.5997720097214376],
					[60.436961142730254, 2.5997720054943674],
					[60.436961136310522, 2.599772003116585],
					[60.436900038268419, 2.5997609040235701],
					[60.436847241278713, 2.5997498046044347],
					[60.436847231290066, 2.5997498029312935],
					[60.436847209615394, 2.5997498017427341],
					[60.436847199626747, 2.5997498000695778],
					[60.436794400136499, 2.5997498008293096],
					[60.436730600073076, 2.5997498008869475],
					[60.436730594632635, 2.5997498001384556],
					[60.436730584731052, 2.599749800270732],
					[60.436730580269916, 2.599749801151491],
					[60.436674986465988, 2.5997554003491219],
					[60.436611092293234, 2.5997580999951104],
					[60.436611083370927, 2.599758101756632],
					[60.43661106543928, 2.5997581034742634],
					[60.436611057409202, 2.5997581050596281],
					[60.436561062873388, 2.5997692038232296],
					[60.436505569834516, 2.5997776025881123],
					[60.436505566265602, 2.5997776032927118],
					[60.436505558235531, 2.5997776048780681],
					[60.436505553774396, 2.5997776057588182],
					[60.436436053569238, 2.5997942048163298],
					[60.436377754372117, 2.5998081058032456],
					[60.436377750803182, 2.5998081065078433],
					[60.436377746342032, 2.5998081073885868],
					[60.436377743665354, 2.5998081079170321],
					[60.436311044066905, 2.5998276076996203],
					[60.436311041390212, 2.5998276082280634],
					[60.43631103523164, 2.5998276112664853],
					[60.436311032554961, 2.5998276117949297],
					[60.43627213310311, 2.5998415119181422],
					[60.436272127749724, 2.5998415129750247],
					[60.436272118914474, 2.5998415165418782],
					[60.436272113648151, 2.5998415194041629],
					[60.436219315253034, 2.5998665181195353],
					[60.436172115026267, 2.5998887183855675],
					[60.436172107975487, 2.5998887216001254],
					[60.436172094853198, 2.5998887296584368],
					[60.436172088781682, 2.5998887345022266],
					[60.436138789353038, 2.5999109333634118],
					[60.436138786763408, 2.5999109356972214],
					[60.436138782389307, 2.599910938383335],
					[60.436138778907448, 2.5999109408932881],
					[60.436058179317833, 2.599972041419051],
					[60.436058174051482, 2.5999720442812801],
					[60.436058163692927, 2.5999720536164888],
					[60.43605815762141, 2.5999720584602475],
					[60.435977658741592, 2.6000526583668861],
					[60.435949858954956, 2.6000804581610417],
					[60.435949851186031, 2.6000804651624057],
					[60.435949837606714, 2.6000804824235764],
					[60.435949831796364, 2.600080492683396],
					[60.435924834641384, 2.6001192868538374],
					[60.435844238216866, 2.6002304821895552],
					[60.435844234822056, 2.6002304865048069],
					[60.435844229011664, 2.6002304967645702],
					[60.435844225616826, 2.6002305010798468],
					[60.435785925840058, 2.6003332009546378],
					[60.435785925034885, 2.6003332029361261],
					[60.43578592431674, 2.6003332067229921],
					[60.435785923424504, 2.6003332068991218],
					[60.4357248227398, 2.6004499078232319],
					[60.435724820324239, 2.6004499137676804],
					[60.435724815319013, 2.6004499220458821],
					[60.435724812990507, 2.6004499297957024],
					[60.435680414935973, 2.6005666247306558],
					[60.435619216723069, 2.6007055188330428],
					[60.435619216004923, 2.6007055226198799],
					[60.435619212697127, 2.6007055287404079],
					[60.435619211978967, 2.6007055325272312],
					[60.435585912219878, 2.6007971322520707],
					[60.435585910522441, 2.6007971344096545],
					[60.435585908999094, 2.6007971401779524],
					[60.435585908193907, 2.600797142159418],
					[60.435549810347815, 2.6009193365883778],
					[60.435519211594105, 2.6010027317200031],
					[60.435519211594105, 2.6010027317200031],
					[60.435519210788904, 2.6010027337014612],
					[60.435519210875938, 2.6010027355068095],
					[60.435494210804656, 2.601074935899784],
					[60.435494209912434, 2.6010749360758929],
					[60.435494209194268, 2.6010749398627069],
					[60.435494209281302, 2.6010749416680534],
					[60.435444208692417, 2.6012388413783105],
					[60.435397008683168, 2.6013943418503711],
					[60.435397007877967, 2.6013943438318106],
					[60.435397007246841, 2.6013943494239307],
					[60.43539700554939, 2.6013943515814897],
					[60.435377607198149, 2.6014721453626377],
					[60.435352609692508, 2.6015527372286114],
					[60.435305410739012, 2.6016888340322577],
					[60.435305409302643, 2.6016888416057884],
					[60.435305404645462, 2.6016888571050583],
					[60.435305403209099, 2.6016888646785872],
					[60.435283105963094, 2.6018110506870578],
					[60.43524420981354, 2.6019277369100866],
					[60.435244209095366, 2.6019277406968535],
					[60.435244206766768, 2.6019277484464562],
					[60.435244206048573, 2.6019277522332094],
					[60.435210908401288, 2.6020610444666095],
					[60.435183109793982, 2.6021471399390061],
					[60.435183108183566, 2.6021471439018224],
					[60.435183105049717, 2.6021471536328042],
					[60.43518310352632, 2.6021471594009689],
					[60.43516650728548, 2.6022277453028475],
					[60.435110910737222, 2.6023860333447821],
					[60.435110910824243, 2.6023860351501127],
					[60.43511090921379, 2.6023860391129099],
					[60.435110909300811, 2.602386040918256],
					[60.435058108867494, 2.6025582418144118],
					[60.435058107344069, 2.6025582475825337],
					[60.435058104471253, 2.6025582627294201],
					[60.435058102055585, 2.6025582686736097],
					[60.435049804999963, 2.6026110507370577],
					[60.435030410985625, 2.6026666348440424],
					[60.43503041018041, 2.6026666368254463],
					[60.435030407764749, 2.602666642769603],
					[60.435030407046547, 2.6026666465563117],
					[60.43498870763311, 2.6028166450522665],
					[60.434952611341686, 2.6029388325521525],
					[60.43489701630336, 2.6030693212684812],
					[60.434897014605916, 2.6030693234258959],
					[60.434897013082455, 2.603069329193973],
					[60.434897012277226, 2.6030693311753397],
					[60.434844216630644, 2.6032138196389982],
					[60.434791522549631, 2.603313806200366],
					[60.434791520939179, 2.6033138101630766],
					[60.434791517631247, 2.6033138162831841],
					[60.434791516826017, 2.6033138182645552],
					[60.434730418794736, 2.6034527140802823],
					[60.434685925862567, 2.6035388001416799],
					[60.434610930924265, 2.6036581934809373],
					[60.434610930119021, 2.6036581954622751],
					[60.434610929313791, 2.6036581974436119],
					[60.434610928421563, 2.6036581976196391],
					[60.434558139424333, 2.6037470806814458],
					[60.434497053214372, 2.6038137643156491],
					[60.434497052322136, 2.6038137644916746],
					[60.434497052409128, 2.6038137662969754],
					[60.434444263398014, 2.6038720532545092],
					[60.434380480156761, 2.6039220407570651],
					[60.434311000619637, 2.6039720255347176],
					[60.43427212103726, 2.6039887167982019],
					[60.434272119252775, 2.6039887171502416],
					[60.434247124949799, 2.6039998151566661],
					[60.434172139126446, 2.60402760949259],
					[60.434130559716493, 2.6040387043319564],
					[60.434052782297137, 2.604049801587625],
					[60.433977803402499, 2.6040525995241182],
					[60.433933318007085, 2.6040498010387059],
					[60.433861122596866, 2.6040415016150464],
					[60.433813934319737, 2.6040359031305074],
					[60.433752844302035, 2.604022005025604],
					[60.433752840646093, 2.6040220039244053],
					[60.433752834313431, 2.6040220033512496],
					[60.433752830657497, 2.6040220022500442],
					[60.433700032274835, 2.6040137034860886],
					[60.433616733127899, 2.6039998038009613],
					[60.433616730364172, 2.6039998025237541],
					[60.433616723052303, 2.6039998003213425],
					[60.433616720375575, 2.6039998008493876],
					[60.433588926236489, 2.6039970028141632],
					[60.433536132314224, 2.6039887032360598],
					[60.433502833007829, 2.6039831022060724],
					[60.433502829351859, 2.6039831011048875],
					[60.43350281945024, 2.6039831012357935],
					[60.433502814902077, 2.6039831003106149],
					[60.433466714574749, 2.6039804009374783],
					[60.433466710026551, 2.6039804000123041],
					[60.433466699232717, 2.6039804003192071],
					[60.43346669379229, 2.6039803995700432],
					[60.43338329387899, 2.6039831001374325],
					[60.43338328933082, 2.6039830992122561],
					[60.433383281300685, 2.6039831007963739],
					[60.4333832768395, 2.6039831016764365],
					[60.433311077236937, 2.603991500114752],
					[60.433311074647214, 2.6039915024480247],
					[60.433311070099023, 2.6039915015228536],
					[60.433311066530088, 2.6039915022268998],
					[60.433277767201368, 2.603997003268181],
					[60.433277760955697, 2.6039970045002621],
					[60.433277747572127, 2.6039970071404395],
					[60.433277742218721, 2.6039970081965085],
					[60.43324994187293, 2.6040054081993564],
					[60.433249940088444, 2.6040054085513771],
					[60.433249936519495, 2.6040054092554223],
					[60.433249934822022, 2.6040054114126781],
					[60.43316933479359, 2.6040331112808279],
					[60.433169330332404, 2.6040331121608777],
					[60.433169318820298, 2.6040331162542381],
					[60.433169314446104, 2.6040331189395127],
					[60.433116613844568, 2.604058119683383],
					[60.433116612952347, 2.6040581198593915],
					[60.433116610362624, 2.6040581221926424],
					[60.433116607685896, 2.6040581227206734],
					[60.433036007630697, 2.604099821877619],
					[60.433035997990068, 2.6040998274241525],
					[60.433035977990549, 2.6040998423036563],
					[60.433035969329147, 2.6040998494793883],
					[60.432972078737798, 2.60415544094203],
					[60.432924891216743, 2.6041859325744405],
					[60.432924883360577, 2.6041859377689214],
					[60.432924866037766, 2.6041859521203037],
					[60.432924859160849, 2.6041859589439755],
					[60.432883161949299, 2.6042276557369601],
					[60.432819264768249, 2.6042859523571877],
					[60.432819263070769, 2.6042859545143959],
					[60.432819257891303, 2.6042859591808196],
					[60.432819255301581, 2.6042859615140301],
					[60.432758158955025, 2.6043498577267505],
					[60.432705462140717, 2.6043998542139013],
					[60.432705458745744, 2.6043998585283097],
					[60.432705450976563, 2.6043998655278866],
					[60.43270544758159, 2.6043998698422972],
					[60.432658153639096, 2.6044554629914916],
					[60.432585961358974, 2.6045248565904973],
					[60.432585955287259, 2.6045248614328553],
					[60.432585944210082, 2.6045248745519491],
					[60.432585939204593, 2.6045248828287093],
					[60.432533152984142, 2.6045970634926863],
					[60.432480470597262, 2.6046415473802296],
					[60.432480462828089, 2.6046415543797123],
					[60.43248044826889, 2.6046415700078742],
					[60.432480441478916, 2.60464157863656],
					[60.432444254256836, 2.6046887606271505],
					[60.432372075714525, 2.6047498424198987],
					[60.432308181218701, 2.6047970384055086],
					[60.432308176039236, 2.6047970430717862],
					[60.432308167377791, 2.6047970502471611],
					[60.432308162198311, 2.6047970549134414],
					[60.432255473941019, 2.6048470445760308],
					[60.432174885567122, 2.6049026353030205],
					[60.432174885567122, 2.6049026353030205],
					[60.432147094358236, 2.6049220301463647],
					[60.432122105071009, 2.604935924699995],
					[60.432041608333968, 2.6049776216868543],
					[60.432041608420938, 2.6049776234920228],
					[60.432041606636453, 2.604977623843975],
					[60.431988809241354, 2.6050054219944263],
					[60.431927721802552, 2.6050359156579748],
					[60.431855534727305, 2.6050609118014485],
					[60.431855532050591, 2.6050609123293662],
					[60.431855527589391, 2.6050609132092268],
					[60.431855525804913, 2.605060913561172],
					[60.431799926750756, 2.6050831136456365],
					[60.431727729696632, 2.605110912421623],
					[60.43168883525599, 2.6051248101053317],
					[60.431630538770506, 2.6051442107672367],
					[60.431594344241034, 2.6051554073462442],
					[60.431563846797829, 2.6051637072222324],
					[60.431563845905593, 2.6051637073982024],
					[60.431494357825848, 2.6051831046494227],
					[60.431438871034054, 2.6051915019244984],
					[60.431358289726262, 2.6052025995327157],
					[60.431288916612665, 2.6051998003332395],
					[60.431244436003183, 2.6051942021290979],
					[60.43116395813427, 2.6051748071305729],
					[60.431133379343606, 2.6051637156713903],
					[60.431100108991025, 2.6051470303932893],
					[60.431027929117214, 2.6050887464318158],
					[60.430952952457737, 2.6050220687993733],
					[60.430919565758337, 2.6049720884379899],
					[60.430919564779138, 2.6049720868088317],
					[60.430919560862257, 2.6049720802922809],
					[60.430919558990809, 2.604972078839094],
					[60.430875162016072, 2.6049137829351037],
					[60.430802967763654, 2.6048081914422032],
					[60.430736275715049, 2.6046999043406038],
					[60.4306834890589, 2.6045860323845775],
					[60.430655799400306, 2.6044749717549296],
					[60.430650200960926, 2.6043583094893172],
					[60.430664095330037, 2.6042611509486595],
					[60.430691884720574, 2.6041889753393175],
					[60.430755779968088, 2.6040417879204525],
					[60.430808476086185, 2.6039444956405875],
					[60.430808476891421, 2.6039444936594953],
					[60.430808480199403, 2.6039444875402094],
					[60.430808481004654, 2.6039444855591158],
					[60.430869574389149, 2.6038139986507924],
					[60.430916864126644, 2.6037445138241639],
					[60.430950152410205, 2.6036973308993248],
					[60.431014038244193, 2.6036362453789312],
					[60.43101404512111, 2.603636238555568],
					[60.431014057003459, 2.6036362234557298],
					[60.431014062988133, 2.603636216808356],
					[60.431069562782852, 2.6035584167893648],
					[60.431069562695853, 2.6035584149842577],
					[60.431069564480325, 2.6035584146322401],
					[60.431069565285561, 2.6035584126511235],
					[60.431141858408353, 2.6034529229369512],
					[60.431227946250914, 2.6033557362077469],
					[60.431255741308043, 2.6033279409192969],
					[60.431255754082599, 2.6033279256432138],
					[60.431255775888722, 2.6033278921848608],
					[60.431255785007338, 2.6033278758077194],
					[60.431275181047475, 2.6032806863425066],
					[60.431344576061875, 2.6031528954546577],
					[60.431344576780106, 2.6031528916683726],
					[60.431344580980294, 2.6031528853728974],
					[60.431344581698518, 2.6031528815866154],
					[60.431380781763757, 2.6030722834004614],
					[60.431450180825692, 2.6029222861260863],
					[60.431489078807459, 2.6028417892765852],
					[60.431569577389055, 2.602688992406506],
					[60.43160297081824, 2.6026251052205849],
					[60.431683458680929, 2.6025140220236094],
					[60.431755754779438, 2.6024251266547123],
					[60.43175575897957, 2.6024251203590469],
					[60.431755766574632, 2.6024251097489159],
					[60.431755770774771, 2.6024251034532648],
					[60.431808471319023, 2.60233900436481],
					[60.431808472124239, 2.6023390023836037],
					[60.431808475432142, 2.6023389962639754],
					[60.431808477129593, 2.6023389941067374],
					[60.431880777354763, 2.6022028935476591],
					[60.431880779770417, 2.6022028876040411],
					[60.431880786212162, 2.6022028717544221],
					[60.431880788627815, 2.6022028658108018],
					[60.431916888264894, 2.6020972680595063],
					[60.431969587024653, 2.6019611727606375],
					[60.431969586850613, 2.6019611691503144],
					[60.43202238732075, 2.6018194696923103],
					[60.432022388125951, 2.6018194677110942],
					[60.432022388931166, 2.6018194657298768],
					[60.432022389736368, 2.6018194637486589],
					[60.43205578373562, 2.601719479627469],
					[60.432127975969117, 2.6015861951223194],
					[60.432127975882096, 2.6015861933171598],
					[60.432127976774318, 2.6015861931410917],
					[60.432175171416944, 2.601497305640649],
					[60.432255756000202, 2.6013834247624312],
					[60.432336248230087, 2.6012945331714357],
					[60.432336249122308, 2.6012945329953578],
					[60.432336250819745, 2.6012945308380284],
					[60.43233625171198, 2.6012945306619502],
					[60.432352931208008, 2.6012751549553319],
					[60.432380703137582, 2.6012584704163162],
					[60.432380716259978, 2.601258462359576],
					[60.4323807387618, 2.6012584433400767],
					[60.432380749925677, 2.6012584320251468],
					[60.43246125022354, 2.6011668319571641],
					[60.432461253618399, 2.6011668276424742],
					[60.432461259515911, 2.601166819189161],
					[60.432461262997805, 2.6011668166796493],
					[60.432541862632398, 2.6010529163393996],
					[60.432541866832459, 2.6010529100434017],
					[60.432541875232587, 2.6010528974514227],
					[60.432541877648205, 2.6010528915076123],
					[60.432594578373802, 2.6009500913527277],
					[60.432594579179018, 2.600950089371433],
					[60.432594581594607, 2.6009500834276085],
					[60.43259458329203, 2.600950081270244],
					[60.432647380916407, 2.6008306853828116],
					[60.43270017814649, 2.6007278909633693],
					[60.432700178864643, 2.600727887176876],
					[60.432700183151738, 2.6007278826860083],
					[60.432700183869883, 2.6007278788995358],
					[60.432755783697743, 2.6005972791924288],
					[60.432808481883754, 2.6004778816560279],
					[60.432861281222557, 2.6003639837365671],
					[60.432861282114786, 2.6003639835604599],
					[60.432861282114786, 2.6003639835604599],
					[60.432861282027744, 2.6003639817552613],
					[60.432914074782182, 2.6002472964978671],
					[60.432986266070706, 2.6001390118514456],
					[60.432986267768143, 2.6001390096940091],
					[60.432986270270746, 2.6001390055552536],
					[60.432986271075933, 2.6001390035739185],
					[60.433041870866262, 2.6000473041903276],
					[60.433041870779213, 2.6000473023851209],
					[60.433041871671463, 2.6000473022090005],
					[60.433114071887317, 2.5999251027176378],
					[60.433114076087342, 2.5999250964213956],
					[60.433114081723602, 2.5999250825520575],
					[60.433114085031391, 2.5999250764319211],
					[60.433147380628654, 2.5998444837003691],
					[60.433219575246483, 2.5997056969195045],
					[60.433272371858386, 2.599616801922795],
					[60.433272375166148, 2.5996167958026],
					[60.433272380976504, 2.5996167855435739],
					[60.433272384284251, 2.5996167794233962],
					[60.433333478762094, 2.5994750910283719],
					[60.433380772364877, 2.5993945002554999],
					[60.433380773257099, 2.5993945000793599],
					[60.433380774062279, 2.5993944980979986],
					[60.433380774062279, 2.5993944980979986],
					[60.433452974188256, 2.5992667975305235],
					[60.43345297588565, 2.5992667953730133],
					[60.433452979998584, 2.5992667872713771],
					[60.433452980716702, 2.599266783484782],
					[60.433472376001454, 2.5992250955985194],
					[60.433494570477151, 2.5991890057019518],
					[60.433494571282331, 2.5991890037205776],
					[60.433577971104988, 2.5990529039646688],
					[60.433577971823091, 2.5990529001780556],
					[60.433577976110108, 2.5990528956868437],
					[60.433577977720439, 2.5990528917240665],
					[60.433619578094799, 2.5989722919504699],
					[60.433686272787789, 2.5988445009003915],
					[60.433747367860541, 2.598750108399976],
					[60.433747371255322, 2.5987501040848593],
					[60.433747376173365, 2.5987500940016854],
					[60.433747378675925, 2.5987500898627101],
					[60.433808479092285, 2.5986278891873003],
					[60.433808479810374, 2.5986278854006493],
					[60.433808484010299, 2.5986278791040935],
					[60.433808484728388, 2.5986278753174434],
					[60.433852983919181, 2.598519478407944],
					[60.433914084358598, 2.5983778787625407],
					[60.433914084358598, 2.5983778787625407],
					[60.43391408516375, 2.5983778767811021],
					[60.433914085968901, 2.598377874799688],
					[60.433952983309133, 2.5982806814351225],
					[60.434025179933592, 2.5981306862011202],
					[60.434025180825813, 2.5981306860249429],
					[60.434025180738743, 2.5981306842196918],
					[60.434025181630965, 2.5981306840435141],
					[60.434058472788074, 2.5980584037180483],
					[60.434139055863334, 2.5979501258894002],
					[60.43421955185962, 2.5978557299820157],
					[60.434219556059524, 2.5978557236852904],
					[60.434219564546403, 2.5978557128970881],
					[60.434219567941149, 2.5978557085817968],
					[60.434264064241667, 2.597786215655868],
					[60.434325158933802, 2.5977057211635932],
					[60.434325159738954, 2.597705719182132],
					[60.434325160631175, 2.5977057190059432],
					[60.434364058896463, 2.5976529205196566],
					[60.434405756738592, 2.5976001248998868],
					[60.434405761743619, 2.597600116621626],
					[60.434405771035621, 2.5976001038518244],
					[60.434405776040641, 2.5976000955735459],
					[60.434472376314432, 2.5974778955582689],
					[60.434472376314432, 2.5974778955582689],
					[60.434525173827538, 2.5973806988717016],
					[60.434594572211779, 2.5972640014264177],
					[60.434594573909138, 2.5972639992687263],
					[60.434594577216785, 2.5972639931480384],
					[60.434594578827074, 2.5972639891850582],
					[60.434647376374564, 2.5971583937209681],
					[60.43470295661119, 2.5970612277323308],
					[60.43477233128899, 2.5970001506483875],
					[60.434811229633503, 2.5969668512129238],
					[60.434811232310167, 2.5969668506842791],
					[60.434811234899762, 2.5969668483503212],
					[60.434811235704899, 2.5969668463688134],
					[60.434852935373343, 2.5969279470807205],
					[60.434925132859249, 2.5968640501539038],
					[60.434925132772157, 2.5968640483485874],
					[60.434977923022849, 2.5968168583837818],
					[60.435044511065207, 2.5967723668596703],
					[60.435044511957429, 2.5967723666834517],
					[60.43513071189134, 2.5967140668706059],
					[60.435130722249667, 2.5967140575346503],
					[60.435130742161171, 2.5967140408442968],
					[60.435130750735048, 2.5967140318607949],
					[60.435150146306739, 2.5966918373355963],
					[60.435177941151849, 2.596664041708701],
					[60.435177943741444, 2.5966640393746894],
					[60.435177949725706, 2.5966640327251711],
					[60.435177952315314, 2.5966640303911754],
					[60.435258450711615, 2.596569530900104],
					[60.435336249916382, 2.5964807327502979],
					[60.435336251613727, 2.5964807305925146],
					[60.435336254116201, 2.5964807264531773],
					[60.435336255008423, 2.596480726276944],
					[60.43537234201554, 2.5964362419649594],
					[60.435416826321969, 2.5964001556142944],
					[60.435416827214176, 2.5964001554380607],
					[60.435416828019306, 2.5964001534564995],
					[60.435416828911542, 2.5964001532802623],
					[60.43548902792886, 2.5963390548843335],
					[60.435577925851362, 2.5962668553912729],
					[60.435577930225371, 2.5962668527047392],
					[60.435577937994083, 2.5962668457025644],
					[60.435577942281, 2.5962668412106975],
					[60.435597315957878, 2.5962473678193523],
					[60.435633384328369, 2.596230782406507],
					[60.435633387810171, 2.5962307798962021],
					[60.435633398342667, 2.596230774170631],
					[60.435633402716668, 2.596230771484072],
					[60.435702903110212, 2.5961890705267456],
					[60.435702904894661, 2.5961890701742618],
					[60.435761204725203, 2.5961529696442969],
					[60.435761209186325, 2.5961529687630724],
					[60.435761216149899, 2.5961529637424325],
					[60.435761219544602, 2.5961529594267665],
					[60.435827920052802, 2.5961029608644339],
					[60.435827921750132, 2.5961029587065809],
					[60.435827925231926, 2.5961029561962476],
					[60.435827927016369, 2.5961029558437567],
					[60.435919527021284, 2.5960279556598018],
					[60.43591953041598, 2.596027951344102],
					[60.43591953818467, 2.5960279443417895],
					[60.435919540687145, 2.5960279402023381],
					[60.435939035034757, 2.5960084480751542],
					[60.435955722823202, 2.5959945588325555],
					[60.436036216549581, 2.5959362614487507],
					[60.436036218334038, 2.5959362610962451],
					[60.436036218334038, 2.5959362610962451],
					[60.436036219226274, 2.5959362609199923],
					[60.436100112233916, 2.5958890651001147],
					[60.436172305174232, 2.5958445708320639],
					[60.436172310353356, 2.5958445661637808],
					[60.436172319993602, 2.5958445606142346],
					[60.436172325172734, 2.5958445559459533],
					[60.436241816708481, 2.5957890636657526],
					[60.436280708366716, 2.5957640679359044],
					[60.436280725688526, 2.5957640535784878],
					[60.436280758373485, 2.5957640216054285],
					[60.436280771865079, 2.5957640025369204],
					[60.436305771769, 2.595722302290957],
					[60.436305771769, 2.595722302290957],
					[60.43636686439703, 2.5956195140492007],
					[60.436430754320348, 2.5955418275562012],
					[60.436430757715002, 2.5955418232403829],
					[60.436430765309453, 2.5955418126270784],
					[60.436430767811906, 2.5955418084875244],
					[60.436491861656791, 2.5954473175054962],
					[60.436580755264558, 2.5953390264694369],
					[60.436580757767018, 2.5953390223298465],
					[60.436580763751223, 2.5953390156797655],
					[60.436580767145891, 2.5953390113639005],
					[60.436608463024811, 2.5952973154008738],
					[60.436644552704571, 2.5952501298963697],
					[60.436714005062427, 2.5951751813723951],
					[60.436786145355214, 2.5951584952421589],
					[60.436786155082544, 2.5951584914976911],
					[60.436786174624316, 2.595158485814153],
					[60.436786185156748, 2.5951584800880081],
					[60.436833384914962, 2.5951362815430996],
					[60.436833400713617, 2.5951362729538601],
					[60.436833431157332, 2.5951362505354334],
					[60.436833444997283, 2.5951362386879424],
					[60.436886245467498, 2.5950807388018311],
					[60.436886249667253, 2.5950807325042167],
					[60.4368862598512, 2.5950807195563907],
					[60.436886264050955, 2.5950807132587759],
					[60.436950154694806, 2.5949890282993944],
					[60.437030743713265, 2.594905739430954],
					[60.437030752287001, 2.5949057304464214],
					[60.437030765778466, 2.5949057113771188],
					[60.437030773372854, 2.5949057007634666],
					[60.437050172062776, 2.5948723012583002],
					[60.437058471721514, 2.5948584023854671],
					[60.437058480664724, 2.5948583823921481],
					[60.437058494090088, 2.5948583432869681],
					[60.437058499377287, 2.5948583221933976],
					[60.437064097943249, 2.5948083276613096],
					[60.437091896237639, 2.5946417383489884],
					[60.437125195142791, 2.5944889436037442],
					[60.437125195860759, 2.594488939816602],
					[60.437125197383807, 2.594488934047765],
					[60.437125197296687, 2.5944889322423506],
					[60.4371390973863, 2.5944056325597469],
					[60.437139097930007, 2.5944056251617909],
					[60.437139099822524, 2.5944056083841307],
					[60.437139100279111, 2.5944055991807455],
					[60.437139100198493, 2.5943222046366015],
					[60.437144600169695, 2.5941833085319899],
					[60.437144599385526, 2.5941832922832422],
					[60.437144595140531, 2.5941832603146837],
					[60.437144591679704, 2.5941832445948751],
					[60.437108491844256, 2.5940582446552813],
					[60.437108488644803, 2.5940582343517344],
					[60.437108480548602, 2.5940582159026797],
					[60.437108477349156, 2.5940582055991337],
					[60.437061281072374, 2.5939693142091742],
					[60.437052984337875, 2.5939499206119301],
					[60.437052966883776, 2.5939498948990249],
					[60.437052924032763, 2.5939498468654456],
					[60.437052898722968, 2.5939498263501957],
					[60.43703339908776, 2.5939387268909919],
					[60.437033395344628, 2.5939387239854343],
					[60.437033387945462, 2.593938719979735],
					[60.437033384289457, 2.5939387188795666],
					[60.436955684428462, 2.5939026188722734],
					[60.436955681664671, 2.5939026175958135],
					[60.436955678008665, 2.5939026164956713],
					[60.436955676137082, 2.5939026150428983],
					[60.436894480154535, 2.5938776160392942],
					[60.436827884240657, 2.5938470170952632],
					[60.436827880671778, 2.5938470178005248],
					[60.436827875144189, 2.5938470152476296],
					[60.436827872293271, 2.5938470121657859],
					[60.436755679411021, 2.5938192162331264],
					[60.436702886399949, 2.5937942191827794],
					[60.436702884615499, 2.5937942195354116],
					[60.436702883723271, 2.5937942197117314],
					[60.436702883636144, 2.5937942179063418],
					[60.436630683577143, 2.5937609175077485],
					[60.436630681792685, 2.5937609178603838],
					[60.436630679028895, 2.5937609165839501],
					[60.436630677157318, 2.5937609151311838],
					[60.436597282899179, 2.5937470173457799],
					[60.436558398402354, 2.5937276267763014],
					[60.436505707133243, 2.5936942314150664],
					[60.436505700626292, 2.5936942272331618],
					[60.436505687699551, 2.5936942206747067],
					[60.436505682171948, 2.5936942181218625],
					[60.436450090614834, 2.5936692222330597],
					[60.436377908310263, 2.5936276315029967],
					[60.43631121572345, 2.5935804378836735],
					[60.436311211893155, 2.5935804331728378],
					[60.436311205473338, 2.5935804307963441],
					[60.436311200837984, 2.5935804280672063],
					[60.436264004836559, 2.5935526306195991],
					[60.436200115974628, 2.5935109378462951],
					[60.436152922932969, 2.5934748417971076],
					[60.436111228915038, 2.5934415466794976],
					[60.436039032691795, 2.5933776494755993],
					[60.436039030907359, 2.5933776498282408],
					[60.436039028056427, 2.5933776467465117],
					[60.436039027077072, 2.5933776451174859],
					[60.43594733489234, 2.5933026523518139],
					[60.435927957706724, 2.5932831747687688],
					[60.435914069689524, 2.5932609939722187],
					[60.435914060962425, 2.5932609811163769],
					[60.435914042877435, 2.5932609609970818],
					[60.435914033345249, 2.5932609501229225],
					[60.435814037204935, 2.5931720544132624],
					[60.435722340567594, 2.5930804582746751],
					[60.435722338696017, 2.5930804568220021],
					[60.435722332994139, 2.5930804506586327],
					[60.435722330143193, 2.5930804475769613],
					[60.43570294065325, 2.5930637575441002],
					[60.435683449672581, 2.5930415688968669],
					[60.435683449585433, 2.5930415670915394],
					[60.435683447713842, 2.593041565638857],
					[60.435683447713842, 2.593041565638857],
					[60.435597348414639, 2.5929470651341595],
					[60.435597344671471, 2.5929470622288395],
					[60.435597338077379, 2.5929470562418495],
					[60.435597335226419, 2.5929470531601821],
					[60.435497348393618, 2.592855463642548],
					[60.435477958331276, 2.5928304777502817],
					[60.435477956372544, 2.5928304744923167],
					[60.435477950670638, 2.5928304683290397],
					[60.435477948799061, 2.5928304668763911],
					[60.435452949182917, 2.5928026675529909],
					[60.435372349669727, 2.5927109687987127],
					[60.435372348690343, 2.5927109671697406],
					[60.435372346818767, 2.5927109657171035],
					[60.435372345752263, 2.5927109622828035],
					[60.435291846235451, 2.5926248630666828],
					[60.435291845256067, 2.5926248614377183],
					[60.435291843384505, 2.5926248599850856],
					[60.435291840620678, 2.592624858708799],
					[60.435252940967672, 2.5925859593185816],
					[60.435252940880524, 2.5925859575132812],
					[60.435208451029361, 2.5925415673419461],
					[60.435147358841185, 2.5924609791035547],
					[60.435147355903062, 2.5924609742166784],
					[60.43514735020117, 2.5924609680535213],
					[60.43514734726304, 2.5924609631666611],
					[60.435080749521148, 2.5923887662079914],
					[60.435019555401439, 2.5923165731611157],
					[60.434947365247176, 2.5922248858706793],
					[60.43492237225567, 2.5921831967556836],
					[60.434922364595003, 2.5921831873347507],
					[60.434922350165877, 2.5921831683165375],
					[60.434922340720775, 2.592183159248298],
					[60.434883455925274, 2.5921442724312938],
					[60.434811267828117, 2.5920331903614433],
					[60.434811265956526, 2.5920331889088537],
					[60.43481126114682, 2.5920331825695033],
					[60.434811259100904, 2.5920331775063605],
					[60.434739068079473, 2.5919387906061235],
					[60.434686274733451, 2.5918443024548838],
					[60.434686272774684, 2.5918442991970418],
					[60.434686269836575, 2.5918442943102984],
					[60.434686267964985, 2.5918442928577385],
					[60.434611267638786, 2.5917276917448238],
					[60.434611267638786, 2.5917276917448238],
					[60.434611266659402, 2.5917276901159179],
					[60.434558471213592, 2.5916470977459709],
					[60.434486274857782, 2.5915166026386629],
					[60.434486273965547, 2.5915166028150285],
					[60.434486272006794, 2.5915165995572402],
					[60.434486271919639, 2.5915165977519821],
					[60.434452973332121, 2.5914609983820944],
					[60.434405776850411, 2.5913777053740059],
					[60.434361278651373, 2.5912888108953926],
					[60.434361275713236, 2.5912888060087482],
					[60.434361270729141, 2.5912887960590858],
					[60.434361267791012, 2.5912887911724241],
					[60.434325171743588, 2.5912331984759125],
					[60.434252975841325, 2.5910999057321882],
					[60.434252973882558, 2.5910999024744501],
					[60.434252970857216, 2.5910998957825853],
					[60.434252968898456, 2.5910998925248467],
					[60.434200170259501, 2.5910165944191261],
					[60.434127971044425, 2.5908970975534928],
					[60.434127970957256, 2.5908970957482609],
					[60.434127969977844, 2.5908970941194043],
					[60.43406957130496, 2.5908026968071449],
					[60.434008471791032, 2.5906998982102318],
					[60.434008470811634, 2.5906998965813872],
					[60.43400846983225, 2.5906998949525408],
					[60.434008469745081, 2.5906998931473129],
					[60.433944569761564, 2.590597094523738],
					[60.433944568782195, 2.5905970928948965],
					[60.433944567802804, 2.5905970912660594],
					[60.433944566823392, 2.5905970896372046],
					[60.433891866892836, 2.5905165892533972],
					[60.433891865913438, 2.590516587624565],
					[60.433891862083058, 2.5905165829144314],
					[60.433891861103668, 2.5905165812855966],
					[60.433819565317989, 2.59041928673799],
					[60.433791870419611, 2.5903748941812665],
					[60.433791866676401, 2.5903748912763769],
					[60.43379186177944, 2.590374883132236],
					[60.433791859820637, 2.5903748798745903],
					[60.433766859661702, 2.5903415800530336],
					[60.433766857790097, 2.5903415786005808],
					[60.433766854851918, 2.5903415737141171],
					[60.433766852893129, 2.590341570456455],
					[60.433677952577099, 2.5902359704427975],
					[60.43367795070548, 2.5902359689903758],
					[60.433677947854484, 2.590235965909137],
					[60.433677945895695, 2.5902359626515055],
					[60.433594548574213, 2.5901470658960815],
					[60.433558453379348, 2.5901054718343395],
					[60.433522354819956, 2.5900609735617599],
					[60.433522350010158, 2.5900609672229327],
					[60.433522338693315, 2.5900609567032791],
					[60.433522333883523, 2.5900609503644487],
					[60.433441836202199, 2.5899887523592429],
					[60.433369539606147, 2.5899192578926633],
					[60.43333344133125, 2.5898831595741045],
					[60.433333440264676, 2.5898831561401296],
					[60.433333436521437, 2.5898831532353368],
					[60.433333435629244, 2.5898831534117335],
					[60.433297339302221, 2.5898498558514742],
					[60.433225140839042, 2.5897776581475775],
					[60.43322513807523, 2.5897776568715996],
					[60.433225132373195, 2.5897776507092729],
					[60.433225128629971, 2.5897776478044978],
					[60.433136229368017, 2.5897026472857414],
					[60.433136225624807, 2.5897026443809841],
					[60.433136220009963, 2.5897026400238761],
					[60.433136216266739, 2.5897026371191192],
					[60.433116815977321, 2.5896887370740713],
					[60.433116813213481, 2.5896887357981067],
					[60.433116805814237, 2.5896887317938035],
					[60.433116802963205, 2.5896887287126535],
					[60.433089017090936, 2.5896720362968981],
					[60.433000131945846, 2.5895970499626317],
					[60.432925135823368, 2.5895276535956011],
					[60.432925132972343, 2.589527650514476],
					[60.432925128249714, 2.5895276459810184],
					[60.43292512459368, 2.5895276448814775],
					[60.432883425999286, 2.589494243868371],
					[60.432839033921312, 2.5894581516637474],
					[60.432772340889741, 2.5893915572426041],
					[60.432772339018094, 2.5893915557902538],
					[60.432772335187693, 2.589391551080424],
					[60.432772333316073, 2.5893915496280884],
					[60.432700140201163, 2.5893276557710401],
					[60.432658448108853, 2.5892831646956247],
					[60.432614049955305, 2.5892331675678362],
					[60.4326140480837, 2.5892331661155139],
					[60.432614046124883, 2.5892331628580507],
					[60.43261404425327, 2.5892331614057116],
					[60.432547347193513, 2.5891637640147325],
					[60.432480749809599, 2.589088767059756],
					[60.432480745086949, 2.5890887625264223],
					[60.432480736533861, 2.5890887532833209],
					[60.432480731898423, 2.5890887505551046],
					[60.432433441811519, 2.5890470599310609],
					[60.43239735085983, 2.5890054694438147],
					[60.432397349880425, 2.5890054678150998],
					[60.432397347921608, 2.5890054645576717],
					[60.432397346049981, 2.5890054631053725],
					[60.432322346132338, 2.5889248642603389],
					[60.432322345152933, 2.5889248626316301],
					[60.432322343194116, 2.5889248593742109],
					[60.432322341409673, 2.5889248597270513],
					[60.432236256751096, 2.588838773602582],
					[60.432216869363522, 2.5888081935733078],
					[60.432216859743804, 2.58880818089653],
					[60.432216838807179, 2.5888081577009197],
					[60.432216828295267, 2.5888081452005629],
					[60.43218342770804, 2.5887804454421177],
					[60.432097334542753, 2.5887081515097239],
					[60.432030741086969, 2.5886415585470286],
					[60.432030737256525, 2.5886415538373915],
					[60.432030726918995, 2.588641544947377],
					[60.432030721304123, 2.5886415405905749],
					[60.431983420975861, 2.5886054404987173],
					[60.43198341919144, 2.5886054408515649],
					[60.431983419104242, 2.5886054390464408],
					[60.431930718752234, 2.5885665398228337],
					[60.431930715008974, 2.5885665369183264],
					[60.431930709394123, 2.5885665325615448],
					[60.431930704758642, 2.5885665298334661],
					[60.431872316740659, 2.5885304374338576],
					[60.43183902788531, 2.5885026468836485],
					[60.431839021291012, 2.5885026408982221],
					[60.431839007297441, 2.5885026309089056],
					[60.431838999898133, 2.5885026269050191],
					[60.43176680021913, 2.5884609268075844],
					[60.43176679567086, 2.5884609258846263],
					[60.431766788184369, 2.5884609200756428],
					[60.431766783548902, 2.5884609173475708],
					[60.431694485356942, 2.588427619654897],
					[60.431641786259803, 2.5884026197257346],
					[60.431641781711555, 2.5884026188027831],
					[60.431641775204476, 2.5884026146225119],
					[60.431641770656206, 2.5884026136995657],
					[60.431561170959441, 2.5883720129331791],
					[60.431561153571423, 2.5883720072598728],
					[60.431561118164765, 2.5883720015049581],
					[60.431561100058921, 2.5883719996182655],
					[60.431533299859275, 2.5883720007426043],
					[60.431533292634398, 2.5883720003489223],
					[60.431533279164029, 2.5883720011902556],
					[60.431533271134128, 2.5883720027781014],
					[60.431513939230719, 2.5883747922438842],
					[60.431469497834655, 2.5883498252298192],
					[60.431469484994899, 2.5883498204794759],
					[60.43146945922814, 2.5883498091737396],
					[60.431469446388384, 2.5883498044233977],
					[60.431422245806665, 2.5883387042548036],
					[60.431422209682218, 2.5883387022865141],
					[60.431422139915391, 2.5883387124377162],
					[60.431422106272997, 2.5883387245571883],
					[60.431380505578979, 2.5883609242979984],
					[60.431380488975783, 2.5883609348703991],
					[60.431380456923236, 2.5883609612540153],
					[60.431380443258277, 2.5883609767123867],
					[60.431308143347394, 2.5884526761776985],
					[60.431308141562965, 2.58845267653055],
					[60.431308139952939, 2.5884526804935515],
					[60.431308139060746, 2.5884526806699735],
					[60.431227639116507, 2.5885609805256258],
					[60.431227636614274, 2.5885609846650333],
					[60.431227630717601, 2.5885609931202342],
					[60.431227628215375, 2.58856099725964],
					[60.43120263625827, 2.5886026840443805],
					[60.431155445192942, 2.5886609732976358],
					[60.431155441798516, 2.5886609776134226],
					[60.431155435814631, 2.5886609842635391],
					[60.431155434204612, 2.5886609882264944],
					[60.431094233948976, 2.5887526893249926],
					[60.431049833890185, 2.5888192881404484],
					[60.431049833085176, 2.5888192901219171],
					[60.431049832280166, 2.5888192921033846],
					[60.431049831387966, 2.5888192922797946],
					[60.430977630896251, 2.5889331929348551],
					[60.430977630983449, 2.588933194739909],
					[60.430977630091213, 2.5889331949163221],
					[60.430977629199006, 2.5889331950927286],
					[60.430916528870711, 2.5890331959546846],
					[60.430916528065701, 2.5890331979361374],
					[60.430916524671225, 2.5890332022518585],
					[60.430916523866209, 2.5890332042333113],
					[60.430860926849611, 2.5891359999223242],
					[60.430788731156255, 2.5892498927272922],
					[60.430788727761794, 2.5892498970429703],
					[60.430788723736697, 2.5892499069501875],
					[60.430788721147245, 2.5892499092844292],
					[60.430744228366912, 2.5893387969897179],
					[60.430666540979722, 2.5894498785781455],
					[60.430635947123086, 2.5894859708767455],
					[60.430635941139165, 2.5894859775265999],
					[60.430635930237891, 2.5894859942599768],
					[60.430635925233382, 2.5894860025384783],
					[60.430622042315051, 2.5895109734749751],
					[60.430530481327082, 2.5895915386966806],
					[60.430458215399867, 2.5896359182263069],
					[60.4304166897575, 2.5896497911754186],
					[60.430369472285605, 2.5896387109513497],
					[60.430297328658632, 2.5895998416925408],
					[60.430225159999601, 2.5895137793882412],
					[60.430180773901249, 2.5894471004228552],
					[60.43010848283221, 2.5892999174727422],
					[60.430072387860612, 2.5892110303398486],
					[60.430019590365276, 2.5890555352950715],
					[60.42997238974737, 2.588913836424851],
					[60.429972388767972, 2.5889138347962377],
					[60.429972387701369, 2.5889138313626483],
					[60.42997238672195, 2.5889138297340559],
					[60.429936291183786, 2.5888166395376713],
					[60.429908493879822, 2.5887138527612961],
					[60.429883495997174, 2.5885971574858773],
					[60.429883495017755, 2.5885971558572964],
					[60.429883493951145, 2.5885971524237097],
					[60.42988349386394, 2.5885971506187202],
					[60.429855794025315, 2.5884888526900478],
					[60.429827994602597, 2.5883666564155017],
					[60.429827994428202, 2.5883666528055254],
					[60.42982799318716, 2.5883666457619809],
					[60.429827992120543, 2.5883666423284044],
					[60.429791891960285, 2.5882443455153399],
					[60.429764093060527, 2.5881416474041239],
					[60.429736295763625, 2.5880360572651235],
					[60.429716896693378, 2.5879166687692901],
					[60.429716896344559, 2.5879166615493707],
					[60.429716893057446, 2.5879166494437684],
					[60.429716891903617, 2.5879166442052588],
					[60.429683495310961, 2.5878027552913352],
					[60.429664096931248, 2.5876888664356241],
					[60.429664095690192, 2.5876888593921401],
					[60.429664092315882, 2.5876888454816203],
					[60.429664090182591, 2.5876888386145711],
					[60.429627989931163, 2.5875777388328993],
					[60.429627987885112, 2.5875777337708343],
					[60.429627984685183, 2.5875777234702908],
					[60.429627982639133, 2.5875777184082263],
					[60.429580783412042, 2.5874721174146513],
					[60.429580773705013, 2.5874721029342722],
					[60.429580753311548, 2.5874720723449958],
					[60.429580740927896, 2.5874720583939297],
					[60.429519540827471, 2.5874109588656324],
					[60.429519536996992, 2.5874109541564754],
					[60.429519529423239, 2.5874109465431445],
					[60.429519523808352, 2.5874109421868812],
					[60.429466823672932, 2.5873692431396447],
					[60.429466821801292, 2.5873692416875627],
					[60.429466821801292, 2.5873692416875627],
					[60.429394523115675, 2.5873137412679004],
					[60.42931402362543, 2.5872498425568757],
					[60.429314019882149, 2.5872498396527424],
					[60.429314010611172, 2.5872498341973325],
					[60.429314006867912, 2.5872498312931751],
					[60.429283425907741, 2.5872304428062809],
					[60.429266841525802, 2.5872137584684696],
					[60.429266834931461, 2.5872137524837515],
					[60.4292668208506, 2.5872137406907632],
					[60.429266814343464, 2.5872137365109973],
					[60.429175117792553, 2.5871498386454745],
					[60.429102922431113, 2.5870942421968883],
					[60.429102919667265, 2.5870942409212647],
					[60.429102914944558, 2.587094236388666],
					[60.429102913072931, 2.5870942349366146],
					[60.429050126236092, 2.5870581445129504],
					[60.429005736881777, 2.587016554133847],
					[60.429005729307995, 2.5870165465207227],
					[60.429005711571051, 2.5870165336287618],
					[60.429005703279536, 2.5870165298019518],
					[60.428950120384783, 2.586983139095389],
					[60.428916835027962, 2.5869526530669056],
					[60.428916828433628, 2.586952647082295],
					[60.428916813460518, 2.5869526354659924],
					[60.428916805081755, 2.5869526298342813],
					[60.428836209736815, 2.5869026331821257],
					[60.428764013679796, 2.5868526356644654],
					[60.428764011808155, 2.5868526342124434],
					[60.428764008064888, 2.5868526313083775],
					[60.428764006193241, 2.5868526298563532],
					[60.428719505862034, 2.5868248303765058],
					[60.428719503990408, 2.5868248289244828],
					[60.428719499354912, 2.5868248261968931],
					[60.428719496591064, 2.5868248249213233],
					[60.428664004610773, 2.5867942302513618],
					[60.428619512666536, 2.5867637342837155],
					[60.42861950990271, 2.5867637330081492],
					[60.428619503395588, 2.5867637288285441],
					[60.428619498760099, 2.5867637261009722],
					[60.428580698674672, 2.5867415269531517],
					[60.428580696803031, 2.5867415255011403],
					[60.428500098582951, 2.5866970260346358],
					[60.428439001923628, 2.5866609275931931],
					[60.428438999159766, 2.5866609263176348],
					[60.428438995416492, 2.5866609234136329],
					[60.428438991760444, 2.5866609223145365],
					[60.428375092171414, 2.5866276232315477],
					[60.42837509208416, 2.5866276214266346],
					[60.428294495939546, 2.5865859245437028],
					[60.428275099095309, 2.586574826356868],
					[60.428275095352006, 2.5865748234528976],
					[60.428275086168256, 2.5865748198027316],
					[60.428275080640567, 2.5865748172516603],
					[60.428250081141215, 2.5865637174366487],
					[60.428169491784239, 2.5865276225960421],
					[60.428122301077451, 2.5864998276751354],
					[60.428122297334149, 2.5864998247711841],
					[60.428122288150412, 2.5864998211210506],
					[60.428122284407102, 2.5864998182170993],
					[60.428055683310255, 2.5864692187160911],
					[60.428055682330807, 2.586469217087664],
					[60.428055677782567, 2.5864692161650527],
					[60.428055676803119, 2.5864692145366281],
					[60.427988977171864, 2.5864415154302853],
					[60.427988976279657, 2.5864415156067402],
					[60.427988974408031, 2.5864415141547701],
					[60.427988972623609, 2.5864415145076793],
					[60.427925076848965, 2.5864165152984127],
					[60.427855680786941, 2.5863859167948258],
					[60.427855678023072, 2.5863859155193247],
					[60.427855674367017, 2.5863859144202759],
					[60.427855671603176, 2.5863859131447704],
					[60.427819478580666, 2.5863720165486179],
					[60.427772285387306, 2.5863498183410338],
					[60.427772278967382, 2.5863498159664808],
					[60.427772267106988, 2.5863498128458113],
					[60.427772259707659, 2.586349808842844],
					[60.427719468634194, 2.5863331127610825],
					[60.427686176798346, 2.5863192146348073],
					[60.427686175013932, 2.5863192149877201],
					[60.427686171270636, 2.5863192120838252],
					[60.427686168594022, 2.5863192126131929],
					[60.427611168565939, 2.5862915121467656],
					[60.427611166781517, 2.5862915124996784],
					[60.427611163038222, 2.5862915095957915],
					[60.427611160274374, 2.5862915083203046],
					[60.427558360012064, 2.5862748087501348],
					[60.427558357248202, 2.5862748074746378],
					[60.427558349043871, 2.5862748054530376],
					[60.427558345387823, 2.5862748043540078],
					[60.42748615325668, 2.5862581063535313],
					[60.427433359748122, 2.5862415094129352],
					[60.42738066028118, 2.5862248082830521],
					[60.427380654840704, 2.5862248075369498],
					[60.42738064298031, 2.5862248044163407],
					[60.427380637539848, 2.5862248036702344],
					[60.427308338338946, 2.5862109038943646],
					[60.427252838960598, 2.5861998038106728],
					[60.427252838068391, 2.5861998039871303],
					[60.42725283530455, 2.5861998027116617],
					[60.427252834412343, 2.5861998028881175],
					[60.427188933860386, 2.5861887026330574],
					[60.427188932075971, 2.5861887029859703],
					[60.427188926635502, 2.5861887022398742],
					[60.427188924851073, 2.586188702592787],
					[60.427144425320897, 2.5861831017045431],
					[60.427144424341463, 2.5861831000761701],
					[60.427075024134801, 2.58617480032476],
					[60.427027824295102, 2.5861692014697719],
					[60.427027819746847, 2.5861692005472268],
					[60.427027811629749, 2.5861692003305015],
					[60.427027807973687, 2.5861691992314997],
					[60.426963908144614, 2.586166500529989],
					[60.42696390627296, 2.5861664990780793],
					[60.426963901811924, 2.5861664999603553],
					[60.426963900027516, 2.5861665003132637],
					[60.426911104261961, 2.5861664996120681],
					[60.426841707769526, 2.5861637000131261],
					[60.426841705985112, 2.5861637003660367],
					[60.426841702329035, 2.5861636992670261],
					[60.426841699652421, 2.5861636997963879],
					[60.426822199673488, 2.5861637008651259],
					[60.426822196017419, 2.5861636997661339],
					[60.426822188792521, 2.5861636993729551],
					[60.426822186203118, 2.5861637017071324],
					[60.426783288982968, 2.5861665004357666],
					[60.426716692047187, 2.5861692000438588],
					[60.426716691154972, 2.5861692002203096],
					[60.426716689370558, 2.5861692005732158],
					[60.426716688478344, 2.5861692007496697],
					[60.426669390084321, 2.5861719992471244],
					[60.426605595746992, 2.5861748004878797],
					[60.42655829987558, 2.5861747997864506],
					[60.426558298091173, 2.5861748001393554],
					[60.426558293542904, 2.5861747992168156],
					[60.426558291758482, 2.5861747995697217],
					[60.426491691588843, 2.5861775999649037],
					[60.426491690696643, 2.5861776001413523],
					[60.426491688912236, 2.5861776004942545],
					[60.426491688020029, 2.5861776006707062],
					[60.426444390604168, 2.5861804007442362],
					[60.426363904282837, 2.5861830994941188],
					[60.426286113936591, 2.5861775997329364],
					[60.426286112152162, 2.5861776000858394],
					[60.426238911573222, 2.5861748000811131],
					[60.426238910681015, 2.586174800257564],
					[60.426238909788815, 2.5861748004340113],
					[60.426238908896607, 2.5861748006104608],
					[60.426175015866662, 2.5861720003089861],
					[60.426127825372703, 2.5861665017161282],
					[60.426047246014633, 2.5861554042433483],
					[60.426030664220193, 2.5861498103180645],
					[60.426030658779737, 2.5861498095719857],
					[60.426030649595972, 2.5861498059221537],
					[60.426030645047689, 2.586149804999633],
					[60.425994445007376, 2.5861415043091833],
					[60.425922253762096, 2.5861248070149832],
					[60.425888964028815, 2.5861137115973691],
					[60.425816769717379, 2.5860887139309678],
					[60.425761174258682, 2.5860665136959002],
					[60.425761171582046, 2.5860665142252497],
					[60.425761172387034, 2.5860665122440469],
					[60.42568897515045, 2.586038714921727],
					[60.425616785871142, 2.5860081193697644],
					[60.425563995366389, 2.5859804234023085],
					[60.425483406958797, 2.5859359311725463],
					[60.425464016253329, 2.5859220362551634],
					[60.425464011617848, 2.5859220335279449],
					[60.425464003326269, 2.585922029701782],
					[60.425463998603568, 2.5859220251698081],
					[60.42544450359874, 2.5859109294299025],
					[60.425366807917008, 2.585860931483817],
					[60.425366807917008, 2.585860931483817],
					[60.425366807024815, 2.5858609316602679],
					[60.425305706933216, 2.5858220308911544],
					[60.425305705953782, 2.5858220292628791],
					[60.425305703277168, 2.5858220297922343],
					[60.425305702297734, 2.5858220281639608],
					[60.425230706587001, 2.5857776312416108],
					[60.425152911600833, 2.5857248349527984],
					[60.425152860328744, 2.5857248177634315],
					[60.425152753827042, 2.5857248133189339],
					[60.425152701274058, 2.5857248255344523],
					[60.425113800578536, 2.5857470255609765],
					[60.425113777186816, 2.5857470447629645],
					[60.425113736910532, 2.585747087345927],
					[60.425113720113195, 2.5857471125316378],
					[60.425041520249607, 2.5858971135803417],
					[60.425041516942478, 2.5858971197002911],
					[60.425041512199876, 2.5858971333919825],
					[60.425041508892718, 2.5858971395119217],
					[60.425013709077142, 2.5859860396742351],
					[60.425013705401184, 2.5859860567988973],
					[60.425013700725884, 2.5859860905188778],
					[60.425013699813775, 2.5859861089189149],
					[60.425019199329398, 2.5861277868977015],
					[60.425005403208701, 2.5862082657254568],
					[60.425005401773205, 2.5862082732972005],
					[60.425005400599375, 2.5862082862830778],
					[60.425005400056058, 2.5862082936783857],
					[60.424999799808035, 2.5863582884278808],
					[60.424991500755567, 2.5864527823026244],
					[60.424991500037805, 2.5864527860884792],
					[60.424991499581701, 2.5864527952884928],
					[60.424991499843387, 2.5864528007026273],
					[60.424991500217814, 2.5865943926773061],
					[60.424983100963608, 2.586705584502488],
					[60.424972000977995, 2.5868360801407961],
					[60.424958100671724, 2.5869638771873769],
					[60.424958100846169, 2.5869638807967981],
					[60.424958101020593, 2.586963884406241],
					[60.424958101107819, 2.5869638862109516],
					[60.424949801008161, 2.5870805841817681],
					[60.424938701047786, 2.5872249737608746],
					[60.424922003837494, 2.5873110613293235],
					[60.424922003032499, 2.5873110633104437],
					[60.424922003119704, 2.5873110651151556],
					[60.424922003294142, 2.5873110687245937],
					[60.424902603392191, 2.5874305677752094],
					[60.424902601956617, 2.5874305753468767],
					[60.424902600064883, 2.5874305921185021],
					[60.424902600413738, 2.5874305993373601],
					[60.424902600236955, 2.5875138773577646],
					[60.424888704646335, 2.5875777567993326],
					[60.42488870401575, 2.5875777623898841],
					[60.42488870177516, 2.5875777719426565],
					[60.42488870194957, 2.5875777755520772],
					[60.4248692019898, 2.5877332721967417],
					[60.424849801887291, 2.5878610697807627],
					[60.424849802061715, 2.5878610733901954],
					[60.424849800713311, 2.5878610827665454],
					[60.424849799995499, 2.5878610865523588],
					[60.424841500999371, 2.5879888774427262],
					[60.424816501673959, 2.5881499682480009],
					[60.424816501761157, 2.5881499700527071],
					[60.424816501848355, 2.5881499718574199],
					[60.424808101953801, 2.5882082714257173],
					[60.424808102040998, 2.5882082732304252],
					[60.424808102128203, 2.5882082750351483],
					[60.424808101235982, 2.588208275211533],
					[60.424799801999015, 2.5882777744099874],
					[60.424780402406945, 2.5884138701920256],
					[60.424763702968917, 2.5885166679530092],
					[60.424763702163908, 2.5885166699340916],
					[60.424763702425501, 2.5885166753482265],
					[60.424763700728263, 2.5885166775056843],
					[60.424747002227669, 2.5886610699805899],
					[60.424719203327605, 2.5888110635747754],
					[60.424719203414796, 2.5888110653794802],
					[60.424719201891946, 2.5888110711463459],
					[60.424719202066342, 2.5888110747557564],
					[60.424708101638068, 2.5888971749524265],
					[60.424708101638068, 2.5888971749524265],
					[60.424708100833058, 2.5888971769334965],
					[60.424694203314964, 2.5890138664921487],
					[60.424674804871294, 2.5891027563982267],
					[60.424674802717782, 2.58910276775554],
					[60.424674801000208, 2.5891027881363891],
					[60.424674799738909, 2.5891027993173434],
					[60.424674799643583, 2.5891805816219438],
					[60.424647003115169, 2.5893332648871046],
					[60.424647003115169, 2.5893332648871046],
					[60.424622002952837, 2.5894721642525886],
					[60.424622003040028, 2.5894721660573077],
					[60.42462200232216, 2.5894721698430612],
					[60.424622002409343, 2.5894721716477638],
					[60.424605401822816, 2.5895888719400504],
					[60.424605401910014, 2.5895888737447486],
					[60.424605401997184, 2.5895888755494525],
					[60.424605401104976, 2.5895888757257954],
					[60.424585902014996, 2.589749975390649],
					[60.424580402756057, 2.5897916622597656],
					[60.424569206266774, 2.5898360510879592],
					[60.424569204831059, 2.5898360586594333],
					[60.424569201959606, 2.5898360738024246],
					[60.424569201328943, 2.5898360793928612],
					[60.42455260211689, 2.59000276916538],
					[60.424524803755091, 2.5901360587510922],
					[60.42452480303723, 2.5901360625368173],
					[60.424524801688662, 2.5901360719129891],
					[60.424524801863022, 2.5901360755223841],
					[60.424508101743903, 2.590266670524433],
					[60.42448040308178, 2.5904332648307085],
					[60.42447200307771, 2.5904777638099734],
					[60.42447200307771, 2.5904777638099734],
					[60.424472003164873, 2.5904777656146716],
					[60.424472003252049, 2.5904777674193684],
					[60.424463703012158, 2.5905277634504391],
					[60.42443040358561, 2.5906916598621454],
					[60.424430402867713, 2.5906916636478532],
					[60.424430402237007, 2.5906916692382511],
					[60.42443040232417, 2.5906916710429657],
					[60.424410902316403, 2.5908305672190277],
					[60.424391504709298, 2.5909388565587408],
					[60.424358106564775, 2.5910638481352515],
					[60.424358105846878, 2.5910638519209401],
					[60.424358105216143, 2.5910638575113221],
					[60.424358104411098, 2.5910638594923308],
					[60.424338704212879, 2.5911582605199297],
					[60.424338704300041, 2.5911582623246225],
					[60.424338702777092, 2.5911582680912981],
					[60.424338702059188, 2.5911582718769992],
					[60.424324804100699, 2.5912527607071021],
					[60.424288707036901, 2.5913888483990943],
					[60.424252606658278, 2.5915221470733862],
					[60.424224806959145, 2.5916221460331763],
					[60.424224807046301, 2.591622147837862],
					[60.424224806328375, 2.5916221516235436],
					[60.424224806415538, 2.5916221534282315],
					[60.424197007364484, 2.5917360441317601],
					[60.424163710926159, 2.5918388340335583],
					[60.42413041272156, 2.5919277302144113],
					[60.424130411916472, 2.5919277321953738],
					[60.424130411198576, 2.5919277359810144],
					[60.424130410393495, 2.5919277379619756],
					[60.424083110741563, 2.5920721351458282],
					[60.424030412120821, 2.5922221313966611],
					[60.423991513947321, 2.5923249264517838],
					[60.423930415086339, 2.5924777254608418],
					[60.423897017829056, 2.5925582172394757],
					[60.423824824002089, 2.5927055041344045],
					[60.423777629124942, 2.5927831952301763],
					[60.423777626622545, 2.5927831993682657],
					[60.423777624207311, 2.5927832053110165],
					[60.423777623402231, 2.5927832072919537],
					[60.423710927369299, 2.5929109988042964],
					[60.423655431609262, 2.5929970918185994],
					[60.423583132189847, 2.5931081913365444],
					[60.423583129687437, 2.5931081954745996],
					[60.423583123790436, 2.59310820392693],
					[60.423583122267402, 2.5931082096933831],
					[60.423530425097489, 2.5932110022962385],
					[60.423469227798307, 2.5933137974061871],
					[60.42346922699322, 2.5933137993870741],
					[60.423469225383016, 2.5933138033488499],
					[60.423469223685707, 2.5933138055059706],
					[60.423405424205868, 2.5934332066908481],
					[60.423405421703443, 2.593433210828842],
					[60.423405418395944, 2.5934332169477257],
					[60.423405415893548, 2.5934332210857143],
					[60.423363720956935, 2.5935305108954796],
					[60.42328872814192, 2.5936581997418249],
					[60.423288726357491, 2.5936582000942718],
					[60.423288723855094, 2.5936582042322236],
					[60.423288723049993, 2.5936582062130906],
					[60.423235923113289, 2.5937582060794524],
					[60.423235920610878, 2.5937582102174104],
					[60.423235917390492, 2.5937582181408554],
					[60.423235914975187, 2.5937582240834547],
					[60.423183119060866, 2.5938860151026812],
					[60.423130426443876, 2.5939860021509684],
					[60.423058130627183, 2.5941054936243186],
					[60.423019233943201, 2.5941637898361933],
					[60.422985944608342, 2.5942137713159577],
					[60.422905471121865, 2.5942915462449898],
					[60.422824883006257, 2.5943498371066416],
					[60.422824878632255, 2.5943498397922697],
					[60.422824872648029, 2.5943498464395582],
					[60.422824870058484, 2.5943498487727781],
					[60.422805502183898, 2.5943665211671836],
					[60.422777757872375, 2.5943748040407129],
					[60.422691692978987, 2.5943858996192124],
					[60.422600030677536, 2.5943804015911076],
					[60.422566757916933, 2.594372008026808],
					[60.422527867429181, 2.5943581125348447],
					[60.42252786466539, 2.5943581112588383],
					[60.422527861901607, 2.5943581099828172],
					[60.422527860030051, 2.5943581085306109],
					[60.422475066093206, 2.5943415115641799],
					[60.422438972175563, 2.5943276122277523],
					[60.422438971283341, 2.5943276124039496],
					[60.42243897039112, 2.59432761258015],
					[60.42243896860667, 2.5943276129325472],
					[60.422355669187652, 2.5942970115022645],
					[60.422302868761626, 2.5942776122400142],
					[60.422302867869405, 2.5942776124162132],
					[60.422302865997857, 2.5942776109640202],
					[60.422302865105628, 2.5942776111402206],
					[60.42223066465889, 2.5942526117036455],
					[60.422230662787335, 2.59425261025144],
					[60.422230660023551, 2.5942526089754523],
					[60.422230658151996, 2.5942526075232659],
					[60.422175057945665, 2.5942359073764365],
					[60.422175053397439, 2.5942359064528553],
					[60.422175045193192, 2.5942359044294689],
					[60.422175040644973, 2.5942359035058846],
					[60.422122240969891, 2.5942248043473892],
					[60.422122234637214, 2.5942248037762079],
					[60.422122223756311, 2.5942248022814267],
					[60.42212221831587, 2.5942248015340441],
					[60.422061118119885, 2.5942192001649107],
					[60.422061117227663, 2.5942192003411102],
					[60.422061113571651, 2.5942191992413317],
					[60.422061111787208, 2.5942191995937316],
					[60.422016711555578, 2.594216499791854],
					[60.422016706202243, 2.5942165008490496],
					[60.422016693536897, 2.5942164997066723],
					[60.422016689075782, 2.5942165005876694],
					[60.421969388808236, 2.5942191993902264],
					[60.421969387023793, 2.5942191997426236],
					[60.421969382562672, 2.5942192006236215],
					[60.421969381670451, 2.5942192007998188],
					[60.421908281820805, 2.5942248014785139],
					[60.421908273703671, 2.5942248012597324],
					[60.421908259428108, 2.5942248040789053],
					[60.421908251398094, 2.5942248056646897],
					[60.421852751236457, 2.5942387062890679],
					[60.421852750344236, 2.5942387064652661],
					[60.421852747667586, 2.5942387069938566],
					[60.421852745883129, 2.5942387073462543],
					[60.421783246354707, 2.5942581069938049],
					[60.421783235735163, 2.5942581109127394],
					[60.421783216280502, 2.5942581183981854],
					[60.421783206640292, 2.5942581239454672],
					[60.421730514630838, 2.5942859183136786],
					[60.421663822733549, 2.5943137154602756],
					[60.421663815682884, 2.5943137186743823],
					[60.421663803366009, 2.5943137247502088],
					[60.421663796402449, 2.594313729768889],
					[60.421586009703162, 2.594360920603092],
					[60.421558226362933, 2.5943720144924383],
					[60.42155821136938, 2.5943720210967967],
					[60.421558183428068, 2.5943720393668097],
					[60.421558170306078, 2.5943720474233323],
					[60.421538773126862, 2.5943887467519398],
					[60.421458176465755, 2.5944526437237392],
					[60.421458174681298, 2.59445264407612],
					[60.421458172091754, 2.5944526464092279],
					[60.421458171199518, 2.5944526465854172],
					[60.421385972889709, 2.5945137443944475],
					[60.421333175595571, 2.594555442247676],
					[60.421333173093124, 2.5945554463853076],
					[60.421333166129564, 2.5945554514038784],
					[60.421333161842661, 2.5945554558938801],
					[60.421280461689399, 2.5946054543059489],
					[60.421280459186953, 2.5946054584435676],
					[60.421280452310491, 2.5946054652666581],
					[60.42128044891583, 2.5946054695804572],
					[60.421227648721818, 2.594666569198504],
					[60.421166549874663, 2.594735967521975],
					[60.421166548982441, 2.5947359676981523],
					[60.421166548177297, 2.5947359696788812],
					[60.421119248062432, 2.5947915705831517],
					[60.421119242970427, 2.5947915770537939],
					[60.421119232786403, 2.594791589995094],
					[60.421119228586583, 2.5947915962895811],
					[60.421085933179675, 2.5948470896054094],
					[60.421013737837448, 2.5949470826170851],
					[60.421013737032332, 2.5949470845977798],
					[60.421013736227216, 2.5949470865784874],
					[60.421013735335009, 2.5949470867546585],
					[60.420941534997581, 2.5950526875391686],
					[60.420941534105332, 2.5950526877153401],
					[60.420941533300208, 2.5950526896960433],
					[60.420941531602885, 2.5950526918528953],
					[60.420885931583953, 2.5951387921094571],
					[60.420885930778816, 2.5951387940901585],
					[60.420885928276356, 2.5951387982276848],
					[60.420885926579004, 2.5951388003845297],
					[60.420813726600073, 2.5952637996263239],
					[60.420813725794957, 2.5952638016070138],
					[60.420813725794957, 2.5952638016070138],
					[60.420813724989834, 2.5952638035876863],
					[60.420772025779087, 2.595338800554905],
					[60.420699828237531, 2.595460998427527],
					[60.420699827345295, 2.5954609986036861],
					[60.420699825735056, 2.5954610025650071],
					[60.420699824842821, 2.5954610027411635],
					[60.420672026810429, 2.5955110007030591],
					[60.42064702832019, 2.5955526963389453],
					[60.420647028407295, 2.5955526981434489],
					[60.420566527743858, 2.5956887979460563],
					[60.420566526133605, 2.5956888019073503],
					[60.420566521933765, 2.595688808201611],
					[60.420566520323511, 2.5956888121629045],
					[60.420527621078662, 2.5957693104928166],
					[60.420460926309602, 2.5958971001845641],
					[60.420405432597207, 2.5959859911082099],
					[60.420333135831491, 2.5960915863929079],
					[60.420260936117131, 2.5961942855944744],
					[60.420260936117131, 2.5961942855944744],
					[60.420216539750811, 2.5962581791737116],
					[60.420160943897805, 2.5963276753395101],
					[60.420160942200425, 2.5963276774962485],
					[60.420160937108342, 2.5963276839664697],
					[60.420160934518762, 2.5963276862993321],
					[60.420119246962905, 2.5963887694932155],
					[60.420066561840265, 2.596438754397222],
					[60.420002667468495, 2.5964998506284465],
					[60.419941581428766, 2.5965498393796698],
					[60.419874894705252, 2.5965942297673057],
					[60.419797106119198, 2.5966387237490269],
					[60.419769317290999, 2.5966526177304088],
					[60.419736029602433, 2.5966665132199629],
					[60.419669337339585, 2.5966887092250852],
					[60.419636046154487, 2.5966998072740939],
					[60.419563855335113, 2.5967165050628194],
					[60.41956385444287, 2.5967165052389354],
					[60.419516660587306, 2.5967276040156482],
					[60.419449968691048, 2.5967387022570891],
					[60.419391671583767, 2.5967470012883251],
					[60.419391668101923, 2.5967470037972111],
					[60.419391659984797, 2.5967470035777751],
					[60.419391655523668, 2.5967470044583285],
					[60.419330556327374, 2.59676090426272],
					[60.419330553650681, 2.5967609047910529],
					[60.419330550974017, 2.5967609053193788],
					[60.419330549276644, 2.5967609074760527],
					[60.419277750235345, 2.5967748065584506],
					[60.419211051207888, 2.5967915056535293],
					[60.419211045854517, 2.5967915067101854],
					[60.41921103701933, 2.596791510275696],
					[60.419211031665959, 2.5967915113323503],
					[60.419149932240536, 2.5968137121001784],
					[60.419149931348315, 2.5968137122762847],
					[60.419097131177253, 2.5968331126684596],
					[60.419097124126502, 2.596833115881755],
					[60.419097110025042, 2.5968331223083001],
					[60.419097102974284, 2.5968331255215786],
					[60.419016604314862, 2.5968776243709364],
					[60.418986013916928, 2.5968942184030177],
					[60.418952722710785, 2.5969081157697285],
					[60.418952712983362, 2.5969081195112991],
					[60.4189526945949, 2.5969081304271295],
					[60.418952685846783, 2.5969081357970119],
					[60.418880486187085, 2.5969581350855884],
					[60.418880476633824, 2.5969581424359558],
					[60.418880457614343, 2.5969581589410828],
					[60.418880449932608, 2.5969581677436566],
					[60.418819249699695, 2.5970276673621306],
					[60.418819248089378, 2.5970276713231648],
					[60.418819242910175, 2.5970276759885715],
					[60.418819241212802, 2.5970276781451775],
					[60.418774842139044, 2.5970859776209925],
					[60.418730444171253, 2.5971415752914551],
					[60.418730443366087, 2.5971415772719544],
					[60.418730440776493, 2.5971415796046591],
					[60.418730439971341, 2.5971415815851562],
					[60.41865814001202, 2.5972387806897643],
					[60.418658134919873, 2.5972387871595148],
					[60.418658127412264, 2.5972387995707558],
					[60.418658124104589, 2.5972388056883378],
					[60.418594223697056, 2.597358205709138],
					[60.41859422289189, 2.5973582076896227],
					[60.418594220389359, 2.5973582118266973],
					[60.418594218691972, 2.59735821398327],
					[60.418541518997976, 2.597469313460429],
					[60.418541518192811, 2.5974693154409079],
					[60.418541516582515, 2.5974693194018807],
					[60.418541515777349, 2.5974693213823574],
					[60.418488715782729, 2.5975943225116995],
					[60.41843311612142, 2.5977249214732696],
					[60.41843311370593, 2.597724927414669],
					[60.418433109680102, 2.5977249373170173],
					[60.418433108156854, 2.5977249430823499],
					[60.418399810464258, 2.5978388375793311],
					[60.418338714587648, 2.5980027256457205],
					[60.418319216689014, 2.5980471195346855],
					[60.418319215883827, 2.5980471215151404],
					[60.418319215078647, 2.5980471234955926],
					[60.418319215078647, 2.5980471234955926],
					[60.418299814722403, 2.5980943233278104],
					[60.418299814004278, 2.598094327112642],
					[60.418299812393954, 2.5980943310735554],
					[60.418299810783616, 2.5980943350344541],
					[60.418247012838776, 2.5982471307406048],
					[60.418205414951593, 2.598349925457669],
					[60.418205414059337, 2.598349925633733],
					[60.418205413254171, 2.5983499276141693],
					[60.418205413341241, 2.5983499294185597],
					[60.418152616285255, 2.5984888219202604],
					[60.418097019312277, 2.5986082165761948],
					[60.418097018420042, 2.5986082167522455],
					[60.418097018420042, 2.5986082167522455],
					[60.418041518312101, 2.5987305172738457],
					[60.418041516614672, 2.5987305194303145],
					[60.418041514199139, 2.5987305253715833],
					[60.418041513393959, 2.5987305273520005],
					[60.417991514027776, 2.5988610265209915],
					[60.417947016786862, 2.5989693207033242],
					[60.417885917772693, 2.5991027176053039],
					[60.417838717911593, 2.5992055168123067],
					[60.417838717998634, 2.5992055186166865],
					[60.417774817786047, 2.5993471176716527],
					[60.417733118012727, 2.5994388162644402],
					[60.417733118099783, 2.5994388180687924],
					[60.41766651816755, 2.5995860180701129],
					[60.417666515577892, 2.5995860204025321],
					[60.417666514054545, 2.5995860261676471],
					[60.4176665133364, 2.5995860299523725],
					[60.417633116660667, 2.5996749168150624],
					[60.417552624062687, 2.5998249056624831],
					[60.417552620667827, 2.5998249099752502],
					[60.417552616728898, 2.5998249216814151],
					[60.41755261422626, 2.5998249258181527],
					[60.417524816452037, 2.5998943211893613],
					[60.417472019178142, 2.6000082168212071],
					[60.417472018285913, 2.6000082169972165],
					[60.417472016675518, 2.600008220957934],
					[60.417472015783268, 2.6000082211339444],
					[60.417438718758987, 2.600086014340369],
					[60.417410922606763, 2.6001388076428515],
					[60.417410921801554, 2.6001388096232128],
					[60.417410920191166, 2.6001388135838979],
					[60.417410919385937, 2.6001388155642489],
					[60.417338719409322, 2.6002943157610243],
					[60.417299819001492, 2.6003777145516742],
					[60.417299819088527, 2.6003777163560082],
					[60.417299818196291, 2.6003777165320052],
					[60.417233120735794, 2.6005249116374642],
					[60.417185924778963, 2.6006110044059372],
					[60.417185922276325, 2.600611008542578],
					[60.417185918163256, 2.6006110166398622],
					[60.417185915747638, 2.6006110225808179],
					[60.417124818980056, 2.6007555145025276],
					[60.417080422383691, 2.6008416082104446],
					[60.417080420773274, 2.60084161217106],
					[60.417080416573178, 2.6008416184639613],
					[60.417080415854983, 2.6008416222485935],
					[60.417019218589068, 2.6009860146430794],
					[60.416972023119854, 2.601074905637514],
					[60.4168998242356, 2.6012110060876696],
					[60.41685812405246, 2.6012888054027865],
					[60.416858123247259, 2.6012888073830651],
					[60.416858122442029, 2.6012888093633486],
					[60.416858120744571, 2.6012888115196025],
					[60.416785930138154, 2.6014331933999308],
					[60.41675814199187, 2.6014692780726465],
					[60.416758134396815, 2.6014692886778761],
					[60.416758121883419, 2.6014693093604402],
					[60.416758116159848, 2.6014693214180529],
					[60.416741518852128, 2.6015082135406193],
					[60.416666524062222, 2.6016499051637343],
					[60.416622024961981, 2.6017305029517064],
					[60.41662202424375, 2.6017305067362857],
					[60.416622020848834, 2.6017305110487077],
					[60.416622020043611, 2.6017305130289663],
					[60.416552622004261, 2.6018749103712562],
					[60.41650812411298, 2.6019582066998272],
					[60.416508119825814, 2.6019582111881601],
					[60.416508114276212, 2.6019582268542014],
					[60.416508111860516, 2.6019582327949347],
					[60.416455417893459, 2.6021055153493942],
					[60.416427627982138, 2.6021526993142707],
					[60.4164276245872, 2.6021527036266243],
					[60.416427621366253, 2.6021527115475811],
					[60.416427618863565, 2.6021527156839919],
					[60.416408119150717, 2.6021943140896391],
					[60.416408119237722, 2.6021943158939318],
					[60.416408118345487, 2.6021943160698715],
					[60.41633872130145, 2.6023471116859094],
					[60.416294224311187, 2.6024305067588931],
					[60.416294222613708, 2.602430508915047],
					[60.416294220916235, 2.6024305110712165],
					[60.416294220111006, 2.602430513051436],
					[60.416222020067586, 2.6025805124516328],
					[60.41622201756487, 2.6025805165880014],
					[60.416222015149138, 2.60258052252864],
					[60.416222013538672, 2.6025805264890609],
					[60.416185914036248, 2.6026721264093897],
					[60.416185913231011, 2.6026721283895977],
					[60.416185911620516, 2.6026721323500164],
					[60.416185910815287, 2.6026721343302186],
					[60.416133111138706, 2.6028249356937296],
					[60.416133110333476, 2.602824937673947],
					[60.416133108004736, 2.602824945418825],
					[60.416133106394255, 2.6028249493792446],
					[60.416099808406877, 2.6029527437881148],
					[60.41606090993794, 2.6030721378844146],
					[60.416060909219681, 2.6030721416688793],
					[60.416060906803949, 2.6030721476094669],
					[60.416060905998698, 2.6030721495896558],
					[60.416027607852072, 2.6031999452756294],
					[60.41599980917637, 2.6032888399952441],
					[60.415999809263347, 2.6032888417995337],
					[60.415999809263347, 2.6032888417995337],
					[60.41599980845811, 2.6032888437797146],
					[60.415974807818309, 2.6033721431786225],
					[60.415974807905293, 2.6033721449828966],
					[60.415974806381769, 2.6033721507475422],
					[60.415974805576518, 2.6033721527277196],
					[60.415938707003569, 2.6035221474208998],
					[60.415894208161433, 2.6036721421163365],
					[60.415894205919621, 2.6036721516653856],
					[60.415894203220518, 2.603672170411691],
					[60.415894200891714, 2.6036721781564571],
					[60.415885904225568, 2.6037499545500311],
					[60.415858112401139, 2.603824929550496],
					[60.415858110159306, 2.6038249390995269],
					[60.415858104783453, 2.6038249583734752],
					[60.415858103433877, 2.6038249677465939],
					[60.415833105307435, 2.6039749533158725],
					[60.415785908823572, 2.6041249394578481],
					[60.415785906668731, 2.6041249508110966],
					[60.415785903077285, 2.6041249697331903],
					[60.415785900835445, 2.6041249792821817],
					[60.415777603267038, 2.604202760904379],
					[60.415752608407786, 2.6042888445571952],
					[60.415752606797255, 2.6042888485174931],
					[60.415752605360666, 2.6042888560863195],
					[60.415752603837113, 2.6042888618508666],
					[60.415724805729006, 2.6044305500130291],
					[60.415680409776407, 2.6045693385996027],
					[60.415680406729251, 2.6045693501286609],
					[60.415680402332505, 2.6045693710307756],
					[60.415680401069842, 2.6045693822080942],
					[60.415672002993368, 2.6046610605252258],
					[60.415644208950454, 2.604752742341228],
					[60.415644207339923, 2.6047527463014735],
					[60.415644204292761, 2.6047527578305054],
					[60.415644202769187, 2.6047527635950272],
					[60.41561920562544, 2.6048888498154001],
					[60.415572010865723, 2.6050277353521203],
					[60.41557201006043, 2.605027737332235],
					[60.415572009342107, 2.6050277411166194],
					[60.415572007644592, 2.6050277432725877],
					[60.415547007748437, 2.6051138448670867],
					[60.415547007030142, 2.6051138486514471],
					[60.415547004701246, 2.6051138563960512],
					[60.415547003982937, 2.6051138601804156],
					[60.415530404130848, 2.6051971585889309],
					[60.41549700635462, 2.6053499497461781],
					[60.415444211204331, 2.6055221344670407],
					[60.415424815816252, 2.6055721218411527],
					[60.415397019121798, 2.605630513089507],
					[60.415397016792895, 2.6056305208340347],
					[60.415397011155882, 2.6056305346946638],
					[60.415397008740022, 2.6056305406349427],
					[60.415352610653855, 2.6057749344410719],
					[60.415310913575567, 2.6058805264756058],
					[60.4152554136272, 2.6060193263350544],
					[60.4152554136272, 2.6060193263350544],
					[60.415255412821914, 2.6060193283151243],
					[60.41525541290887, 2.6060193301193664],
					[60.415210914345323, 2.6061388260583955],
					[60.415158115821853, 2.6062638221467207],
					[60.415158115821853, 2.6062638221467207],
					[60.415158115016553, 2.6062638241267808],
					[60.415102615168792, 2.6063999233137762],
					[60.415102614450447, 2.6063999270980602],
					[60.415102611947603, 2.6063999312339945],
					[60.415102611142288, 2.6063999332140479],
					[60.41506651208163, 2.6065027320684502],
					[60.415010915123055, 2.6066499234497407],
					[60.414985917750833, 2.6067055174465441],
					[60.414985916227167, 2.6067055232108447],
					[60.414985912113693, 2.6067055313068144],
					[60.414985909697805, 2.606705537246937],
					[60.414969211937738, 2.6067555325394527],
					[60.414905412878603, 2.6069221282781623],
					[60.41490541135493, 2.6069221340424389],
					[60.414905408307575, 2.6069221455710108],
					[60.414905405891652, 2.6069221515111045],
					[60.414872006594102, 2.6070555459563751],
					[60.414835908931693, 2.6071721415643769],
					[60.414835908126378, 2.6071721435443922],
					[60.414835906602704, 2.6071721493086608],
					[60.414835905797382, 2.6071721512886779],
					[60.414802607933446, 2.6073055433808472],
					[60.414774809784646, 2.6073888373093013],
					[60.414774808979338, 2.607388839289309],
					[60.414774806737256, 2.6073888488377839],
					[60.414774806018869, 2.607388852622011],
					[60.41475540690918, 2.607466648632093],
					[60.414710907825295, 2.6076221449884249],
					[60.414710907106908, 2.6076221487726388],
					[60.414710904777891, 2.6076221565168622],
					[60.414710904059504, 2.6076221603010761],
					[60.414677606295626, 2.6077832503230707],
					[60.414658109063879, 2.6078471428167198],
					[60.41465810825855, 2.607847144796728],
					[60.414658106647892, 2.6078471487567048],
					[60.414658105929512, 2.6078471525409079],
					[60.414641506433767, 2.6079138526912602],
					[60.414641506433767, 2.6079138526912602],
					[60.414641504823138, 2.6079138566512281],
					[60.414641504823138, 2.6079138566512281],
					[60.414605406471246, 2.6080777524908298],
					[60.414560906905798, 2.6082416472763179],
					[60.41456090618739, 2.6082416510605038],
					[60.414560903945251, 2.6082416606088579],
					[60.414560903226857, 2.6082416643930597],
					[60.414549807211294, 2.6083027430584669],
					[60.414524815013039, 2.6083638233544448],
					[60.414524811791729, 2.6083638312743305],
					[60.414524807938911, 2.608363844782605],
					[60.414524806415166, 2.6083638505467688],
					[60.414483106833977, 2.6085305496549696],
					[60.414435909012909, 2.6086999395376296],
					[60.414408111671563, 2.6087777324444996],
					[60.414408107905629, 2.6087777477568812],
					[60.41440810207132, 2.6087777762259687],
					[60.414408100089886, 2.6087777911868577],
					[60.41440260027823, 2.6088943902842825],
					[60.414402599646714, 2.6088943958726571],
				],
			],
		},
		{
			fldNpdidField: "43576",
			wlbNpdidWellbore: "353",
			fldName: "ØST FRIGG",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "25/2-1",
			fldDiscoveryYear: "1973",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43576",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43576",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "873",
			cmpLongName: "Aker BP ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=353",
			fldHcType: "GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "28544099",
			fldNpdidOwner: "28973585",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[59.903205400251231, 2.3202167036922399],
					[59.903205399811249, 2.3202167127490521],
					[59.903499799798091, 2.3246778137575945],
					[59.903499800960823, 2.3246778189040556],
					[59.903499802394435, 2.3246778293767729],
					[59.903499802665358, 2.3246778347030275],
					[59.90439700291553, 2.3299028338819823],
					[59.904397004168288, 2.3299028408042584],
					[59.904397007655859, 2.3299028562446815],
					[59.904397009800441, 2.3299028629873284],
					[59.905863710138604, 2.334333363313855],
					[59.90586371112061, 2.3343333649098947],
					[59.905863713174789, 2.3343333698775508],
					[59.905863714246962, 2.3343333732491782],
					[59.907647007589802, 2.3388889576873946],
					[59.908310901121858, 2.3421333246352085],
					[59.908546999097418, 2.346594391681883],
					[59.907802602242405, 2.3518193713189275],
					[59.907802602332353, 2.3518193730946684],
					[59.907802600728509, 2.3518193770043245],
					[59.907802600818457, 2.3518193787800632],
					[59.906908103358184, 2.3600332623676392],
					[59.905991510241208, 2.3634888340865232],
					[59.904872015703482, 2.3661082215867526],
					[59.904872013386814, 2.3661082292253179],
					[59.90487200786157, 2.3661082446811617],
					[59.904872006436797, 2.3661082521410699],
					[59.903999806154076, 2.3696666517310727],
					[59.903999802591613, 2.3696666703799854],
					[59.903999800817971, 2.3696667066063903],
					[59.903999801714953, 2.3696667243624443],
					[59.904316501981405, 2.3721694258880666],
					[59.904316513675248, 2.3721694612127266],
					[59.904316551243589, 2.372169527230275],
					[59.904316576136544, 2.3721695563260607],
					[59.905733176348726, 2.3732918576720454],
					[59.90573318937323, 2.3732918658274706],
					[59.905733217206006, 2.3732918817813484],
					[59.905733231924671, 2.3732918878040845],
					[59.907469293071529, 2.3739195735406589],
					[59.908280437604468, 2.3746918217496509],
					[59.909222022363224, 2.3765111910498877],
					[59.909222030304939, 2.3765112056056399],
					[59.909222047793001, 2.3765112308084886],
					[59.909222058231229, 2.3765112412771643],
					[59.909880458155833, 2.3771723414386892],
					[59.909880509002697, 2.3771723671448464],
					[59.909880615595746, 2.3771723906690494],
					[59.909880673036113, 2.3771723863543452],
					[59.911011173227209, 2.3767279859407493],
					[59.911011202924712, 2.3767279674419659],
					[59.911011254646823, 2.376727921215819],
					[59.911011276671431, 2.3767278934884781],
					[59.91190847726061, 2.3750250933570496],
					[59.911908482876491, 2.3750250796753267],
					[59.911908492414085, 2.375025054444877],
					[59.91190849624617, 2.375025041120125],
					[59.91246129313722, 2.3723722551660447],
					[59.913641880665104, 2.3692417879106933],
					[59.914639040540401, 2.3675473535338174],
					[59.915777773381585, 2.3670002342020449],
					[59.916885908404922, 2.3678945012473425],
					[59.917019198910943, 2.3694555917635882],
					[59.915891502693917, 2.3761027670398223],
					[59.915891502070885, 2.3761027725471031],
					[59.915891500824806, 2.3761027835616551],
					[59.915891500201774, 2.376102789068919],
					[59.915735900080847, 2.3792971898327293],
					[59.915735900887057, 2.3792972058191335],
					[59.915735904193703, 2.3792972356588655],
					[59.915735905802237, 2.3792972496905933],
					[59.916469205616416, 2.382136150642904],
					[59.916469208650319, 2.3821361572131883],
					[59.916469213023852, 2.3821361724867232],
					[59.916469216057756, 2.3821361790569879],
					[59.917649800634408, 2.3849000413334398],
					[59.917727599789735, 2.3900749842941997],
					[59.917219203024558, 2.3931443665756857],
					[59.917219202400801, 2.3931443720829009],
					[59.917219201153273, 2.3931443830973671],
					[59.9172191996376, 2.3931443887826198],
					[59.917002599705278, 2.3968527875516679],
					[59.917002600241332, 2.3968527982100527],
					[59.917002601313456, 2.3968528195268153],
					[59.917002601760174, 2.3968528284088024],
					[59.917280401601865, 2.398733329613842],
					[59.917280402761762, 2.3987333347652187],
					[59.917280405081556, 2.3987333450679538],
					[59.917280406241446, 2.3987333502193131],
					[59.917766501650327, 2.4006250346901541],
					[59.917935900845549, 2.4026000166989188],
					[59.917935901202625, 2.4026000238047915],
					[59.917935902540904, 2.4026000325093442],
					[59.917935903789918, 2.4026000394374134],
					[59.918413703642941, 2.4050111391684226],
					[59.918413705962259, 2.4050111494719766],
					[59.918413713276777, 2.4050111695458956],
					[59.918413717379984, 2.405011179493997],
					[59.919202616932125, 2.4068167797121882],
					[59.919202633612002, 2.4068168068929112],
					[59.919202678388764, 2.4068168553913045],
					[59.919202702828642, 2.4068168756431945],
					[59.920680502808679, 2.407633474173366],
					[59.920680538576306, 2.4076334867870348],
					[59.920680611984629, 2.4076334954916483],
					[59.920680648822575, 2.4076334935369128],
					[59.922950049465435, 2.4070584944403706],
					[59.922950060971701, 2.4070584903534429],
					[59.922950083092232, 2.4070584823573018],
					[59.922950093617281, 2.4070584766713217],
					[59.92433897243172, 2.4063195878725159],
					[59.926652847832322, 2.4057445935297288],
					[59.926652848724281, 2.4057445933519612],
					[59.926652849705469, 2.4057445949511513],
					[59.928919448107152, 2.4051640931278593],
					[59.930616746860117, 2.4047557931702714],
					[59.930616768177735, 2.4047557871253673],
					[59.930616806798916, 2.404755766861117],
					[59.930616826064878, 2.40475575584041],
					[59.931447325828572, 2.404083455583415],
					[59.931447342953547, 2.4040834378089313],
					[59.93144737417056, 2.4040833956847054],
					[59.93144738567603, 2.4040833736457108],
					[59.932247386239659, 2.4020667732214696],
					[59.932247387755702, 2.4020667675339715],
					[59.932247391679731, 2.402066755981056],
					[59.932247394087703, 2.4020667501156359],
					[59.932952978012402, 2.3993195116540931],
					[59.934205709200143, 2.3982584693964797],
					[59.935180684725992, 2.3978029806623558],
					[59.935180717726219, 2.3978029561230643],
					[59.935180771416718, 2.3978028951399946],
					[59.935180791125688, 2.3978028570969245],
					[59.935614091205515, 2.396383357437597],
					[59.935614094147681, 2.3963833442835409],
					[59.935614099050753, 2.3963833163760979],
					[59.935614100209058, 2.3963833035782018],
					[59.935697399773147, 2.3909056023769337],
					[59.935697399773147, 2.3909056023769337],
					[59.935697399683697, 2.3909056005995648],
					[59.935697399594275, 2.3909055988221883],
					[59.935675200223571, 2.386041703377443],
					[59.935911300208524, 2.380572208465098],
					[59.935911300208524, 2.380572208465098],
					[59.936000200035892, 2.378408308406458],
					[59.936000199587845, 2.3784082995197244],
					[59.936000198781372, 2.3784082835235987],
					[59.936000198333318, 2.3784082746368638],
					[59.935719598384857, 2.376216674947671],
					[59.935719588744263, 2.3762166445634758],
					[59.935719563399061, 2.3762165886000814],
					[59.935719544929093, 2.3762165617794571],
					[59.934564049677519, 2.3750054675430721],
					[59.933155764943507, 2.3732609862678213],
					[59.931950192122841, 2.3712193312618837],
					[59.931441897926462, 2.3678777710454901],
					[59.930930799332828, 2.364327776675573],
					[59.930625199284464, 2.3607943822748116],
					[59.930625197943378, 2.3607943735688344],
					[59.930625195351055, 2.3607943579338881],
					[59.930625194009984, 2.3607943492279135],
					[59.929858496333203, 2.3578499601661735],
					[59.929252998232727, 2.3536749703919275],
					[59.929252992784136, 2.353674951741985],
					[59.929252978139623, 2.3536749116050455],
					[59.929252969123581, 2.3536748936718386],
					[59.927200189778596, 2.3504416269268145],
					[59.926914098698781, 2.3488721779309274],
					[59.926761300207652, 2.3453471900832952],
					[59.926761298955633, 2.3453471831557478],
					[59.92676129832531, 2.34534717071862],
					[59.926761297073277, 2.3453471637910712],
					[59.92605019704375, 2.3414749646644188],
					[59.92605018962962, 2.3414749428239667],
					[59.926050174089688, 2.3414749028759476],
					[59.926050163198298, 2.3414748835301453],
					[59.924747386194241, 2.3396388167188258],
					[59.924022400311991, 2.3364888789029106],
					[59.924058500339356, 2.3344222145158868],
					[59.9243501984201, 2.3321667266426189],
					[59.924350199211304, 2.3321667067410461],
					[59.924350196244383, 2.3321666660599778],
					[59.924350192666715, 2.3321666488335855],
					[59.924011301832799, 2.3309055808724843],
					[59.92427240019358, 2.3271972147772129],
					[59.924272400013002, 2.3271972112241541],
					[59.924272399742136, 2.327197205894544],
					[59.924272400453354, 2.3271972021616238],
					[59.924283500324066, 2.3259556023514865],
					[59.924283498619502, 2.3259555865426198],
					[59.924283495300706, 2.3259555567013841],
					[59.924283491902834, 2.3259555430288072],
					[59.923652993099118, 2.323849948333327],
					[59.923166898456749, 2.3218555687999909],
					[59.922955799821608, 2.3191527908813798],
					[59.922894599747849, 2.316149996394814],
					[59.922894600277907, 2.3161499891089981],
					[59.922894598843492, 2.3161499786306226],
					[59.922894597680404, 2.316149973481497],
					[59.922530798362622, 2.3134388720956296],
					[59.922530791835754, 2.3134388500838381],
					[59.922530774142125, 2.3134388034085385],
					[59.92253076226455, 2.3134387824780323],
					[59.921119561869268, 2.3114915827273057],
					[59.921119553209735, 2.3114915719154583],
					[59.92111953419758, 2.3114915524285711],
					[59.921119523844958, 2.3114915437535424],
					[59.918991824958461, 2.3098109447661277],
					[59.917325148915623, 2.3084692627682766],
					[59.916375170281675, 2.3070637938608525],
					[59.915822373113265, 2.3061026994660958],
					[59.915822368201574, 2.3061026914878764],
					[59.91582235668519, 2.3061026776681701],
					[59.915822351773478, 2.306102669689956],
					[59.914411277365133, 2.3044637993108967],
					[59.913627989817904, 2.3021416358568652],
					[59.913627988926144, 2.3021416360373181],
					[59.913627988835522, 2.3021416342614489],
					[59.912741889155932, 2.2996082342837805],
					[59.912741880042255, 2.2996082145985848],
					[59.912741859411575, 2.2996081810972324],
					[59.91274184682149, 2.2996081639099275],
					[59.912236247031515, 2.2990609643090991],
					[59.91223616878392, 2.2990609317074844],
					[59.912236016005593, 2.2990609393116204],
					[59.912235941474883, 2.2990609795173675],
					[59.911033141394469, 2.3006415782341345],
					[59.911033137207539, 2.3006415844636887],
					[59.911033128833687, 2.3006415969228158],
					[59.911033126430269, 2.3006416027914405],
					[59.910133142960426, 2.3022415701635941],
					[59.908891652765867, 2.3033025775355798],
					[59.90802794857926, 2.3030442356738172],
					[59.906891885607862, 2.3002860268888696],
					[59.905952987668194, 2.297852732574647],
					[59.905361289834573, 2.2960582366555986],
					[59.905361260542477, 2.296058192360789],
					[59.905361184138243, 2.2960581253169656],
					[59.905361137026112, 2.2960581025679487],
					[59.904541775854895, 2.2959054114965607],
					[59.904191809341938, 2.2956776318122567],
					[59.904191718805698, 2.2956776232378582],
					[59.904191567675447, 2.2956776807435104],
					[59.904191507172122, 2.2956777485988913],
					[59.903538706553704, 2.2981193479374546],
					[59.9035387035306, 2.2981193593113347],
					[59.903538701051481, 2.2981193813371377],
					[59.903538699811932, 2.2981193923500371],
					[59.903347000322675, 2.3039999931930968],
					[59.903346999612062, 2.303999996924087],
					[59.903346999793229, 2.3040000004747454],
					[59.903346999974382, 2.304000004025422],
					[59.903466499810712, 2.3097944036538824],
					[59.903466500172719, 2.3097944107553281],
					[59.903466500806253, 2.3097944231828396],
					[59.903466501969547, 2.3097944283287544],
					[59.9040247986308, 2.3136528094964977],
					[59.903938701089885, 2.3158166712825521],
					[59.903508106403223, 2.3175471520662962],
					[59.903508104890832, 2.317547157752391],
					[59.90350810195644, 2.3175471708999997],
					[59.903508101335838, 2.3175471764061295],
					[59.903205400679397, 2.3202166767017043],
					[59.903205401131224, 2.3202166855786244],
					[59.903205400251231, 2.3202167036922399],
				],
				[
					[59.875172001367012, 2.3307860916099141],
					[59.875172002188904, 2.3307861254939537],
					[59.8751720048745, 2.3307861428744001],
					[59.875935905293019, 2.33424174365231],
					[59.875935912707781, 2.3342417654588092],
					[59.875935933870302, 2.3342418095905551],
					[59.87593594654593, 2.3342418285471513],
					[59.877399846774857, 2.3359834287182624],
					[59.877399907979317, 2.3359834630111598],
					[59.877400037855125, 2.3359834834991622],
					[59.877400107418289, 2.3359834695149733],
					[59.878741807070035, 2.3351390691665053],
					[59.87874183113999, 2.3351390464042971],
					[59.878741871784626, 2.3351389952178838],
					[59.878741889251181, 2.335138966614374],
					[59.879814083584272, 2.332105680708175],
					[59.880977975357169, 2.3299056960983582],
					[59.881663962114779, 2.3287085948439135],
					[59.882574841996984, 2.3293834320711175],
					[59.883816506285136, 2.3319389577502001],
					[59.884230399678167, 2.3352722122459437],
					[59.884235900116188, 2.3382666920324477],
					[59.883852601321756, 2.3427860830943796],
					[59.883852599718146, 2.3427860870015533],
					[59.88385260016836, 2.3427860958737186],
					[59.883852600438487, 2.3427861011970084],
					[59.883860899648234, 2.3460917011907281],
					[59.88386090108007, 2.3460917116583571],
					[59.883860903141908, 2.3460917345470831],
					[59.883860904663742, 2.346091746789138],
					[59.884530405332562, 2.3489250459749038],
					[59.884530413726843, 2.3489250693858841],
					[59.884530437650341, 2.348925114775906],
					[59.884530453179501, 2.3489251367549682],
					[59.885741521348905, 2.3502390012648013],
					[59.886371998443444, 2.3524472199508737],
					[59.885938701960605, 2.3575860654186691],
					[59.883874806904181, 2.3654082488871286],
					[59.883874806191777, 2.3654082526146976],
					[59.883874803785325, 2.3654082584738787],
					[59.883874803964801, 2.3654082620229047],
					[59.883033104157043, 2.3697999611268004],
					[59.883033101929726, 2.3697999705344963],
					[59.88303310104272, 2.3697999886362067],
					[59.883033099707319, 2.3697999978654822],
					[59.882958100464705, 2.3748555975975028],
					[59.882958099752024, 2.3748556013247835],
					[59.882958100110457, 2.3748556084227919],
					[59.882958100379291, 2.3748556137463046],
					[59.883327599921394, 2.3806611132336184],
					[59.883327603402918, 2.3806611286700963],
					[59.883327611347418, 2.3806611611394879],
					[59.883327615810401, 2.3806611781723901],
					[59.885260913264446, 2.3852278715224653],
					[59.886635903323487, 2.3891361447743762],
					[59.887566500583873, 2.3983917194421989],
					[59.887566505223205, 2.398391740029449],
					[59.887566518069711, 2.3983917804931703],
					[59.887566526187527, 2.3983917985948375],
					[59.88921372600629, 2.4012833992966907],
					[59.889213734747933, 2.4012834118975608],
					[59.889213754907075, 2.4012834365664215],
					[59.889213766324566, 2.4012834486344179],
					[59.891433165759715, 2.4032862477873524],
					[59.891433189129415, 2.4032862646484658],
					[59.891433237117305, 2.4032862873651952],
					[59.891433262805933, 2.4032862965933526],
					[59.893122163204097, 2.403600196091614],
					[59.893122226800649, 2.4036001888084839],
					[59.893122334815828, 2.4036001332376649],
					[59.893122379412901, 2.4036000885002808],
					[59.893861278918607, 2.402097287465895],
					[59.893861287034873, 2.402097269712951],
					[59.893861296755944, 2.402097230124955],
					[59.893861300233944, 2.4020972097098117],
					[59.894016900160757, 2.398905609581786],
					[59.894016900071456, 2.3989056078066087],
					[59.894016899714266, 2.3989056007058975],
					[59.894016899624987, 2.3989055989307206],
					[59.893972400310126, 2.3948722007339582],
					[59.894028000407303, 2.391569417477549],
					[59.894933495281471, 2.3856333450553713],
					[59.896180791310194, 2.3815806598266902],
					[59.89618079434068, 2.3815806484625002],
					[59.896180797013045, 2.3815806299974147],
					[59.896180799241137, 2.3815806205866679],
					[59.89636129903046, 2.3798333206078977],
					[59.896361299474592, 2.3798333115534835],
					[59.896361300273334, 2.3798332916694158],
					[59.896361298844091, 2.3798332811962588],
					[59.895830800282837, 2.373911088486035],
					[59.895700200034263, 2.3692527950995768],
					[59.895664099644442, 2.3651139164864032],
					[59.896230796876125, 2.3617417334002253],
					[59.896230798474214, 2.3617417115620913],
					[59.896230796229247, 2.3617416671830416],
					[59.896230792386234, 2.3617416446421347],
					[59.895208494954147, 2.3581638565487864],
					[59.89449739745492, 2.353986066452995],
					[59.894497397365029, 2.3539860646779545],
					[59.894497397275117, 2.3539860629029179],
					[59.894497396293346, 2.3539860613068053],
					[59.894072403232741, 2.3517916947693926],
					[59.894764097800042, 2.3465611311670069],
					[59.895825196409547, 2.3410500374703891],
					[59.896489096293607, 2.3375778377209224],
					[59.896489097716788, 2.337577830261806],
					[59.896489098689344, 2.3375778139272518],
					[59.896489100112525, 2.3375778064681296],
					[59.896611300377053, 2.3331417051821437],
					[59.896611299926136, 2.3331416963067646],
					[59.896611298042295, 2.3331416769603965],
					[59.896611296609365, 2.3331416664894089],
					[59.896000197175177, 2.3294888677657442],
					[59.896000196904488, 2.3294888624406376],
					[59.8960001947599, 2.3294888556997031],
					[59.896000193597395, 2.3294888505541893],
					[59.895127993974256, 2.3260249504133661],
					[59.895127992992158, 2.326024948818076],
					[59.8951279919198, 2.326024945447795],
					[59.895127991829511, 2.3260249436728251],
					[59.894105793388789, 2.322549946640168],
					[59.892986297137931, 2.3181443624380087],
					[59.89238909811003, 2.3136638745990257],
					[59.892389096856469, 2.3136638676798791],
					[59.892389095331573, 2.3136638554363977],
					[59.892389094168458, 2.3136638502920484],
					[59.891280794028276, 2.3093610503849047],
					[59.891280791882721, 2.3093610436462617],
					[59.891280786699802, 2.3093610303490766],
					[59.891280785446021, 2.3093610234303541],
					[59.890089084939802, 2.3064999222743414],
					[59.890089074043793, 2.3064999029605584],
					[59.890089046991626, 2.3064998671886192],
					[59.89008903172725, 2.3064998505502987],
					[59.888825131854105, 2.3053887507883144],
					[59.888825107581283, 2.3053887341784134],
					[59.888825056813026, 2.3053887103719899],
					[59.888825030227046, 2.3053887014008874],
					[59.887702848537074, 2.3052165046939828],
					[59.886630724787622, 2.304841531896042],
					[59.885527987743316, 2.3033277173540392],
					[59.885002996226298, 2.3006082611136591],
					[59.885002989788362, 2.3006082409032818],
					[59.88500297236299, 2.3006081996096293],
					[59.885002959682652, 2.3006081806612455],
					[59.884197359518268, 2.2995304792958704],
					[59.884197324710343, 2.2995304504814618],
					[59.884197245208917, 2.2995304109849743],
					[59.884197199623657, 2.2995304004832282],
					[59.882552799980296, 2.2995303992185727],
					[59.882552734533164, 2.2995304232058733],
					[59.882552636134015, 2.2995305076313119],
					[59.882552603181985, 2.2995305680694429],
					[59.882388704088974, 2.3005555616582032],
					[59.881572014334033, 2.3040166176701531],
					[59.880885958190319, 2.3050081534598856],
					[59.880008192062711, 2.3055748317051425],
					[59.88000815873194, 2.3055748671152294],
					[59.880008113305628, 2.3055749479854457],
					[59.880008100408844, 2.3055749953997959],
					[59.879888699849552, 2.3102138785153525],
					[59.87944420785638, 2.3126666386559291],
					[59.878385919576282, 2.3148749142922358],
					[59.878385918774896, 2.314874916246132],
					[59.878385917172132, 2.3148749201539376],
					[59.878385916370746, 2.3148749221078329],
					[59.877322015731863, 2.317391622257797],
					[59.877322013417967, 2.3173916298931188],
					[59.877322007898371, 2.317391645343533],
					[59.877322005584482, 2.3173916529788561],
					[59.877049806067923, 2.3185082525165019],
					[59.87704980535684, 2.3185082562442281],
					[59.877049803223592, 2.3185082674273749],
					[59.877049801620707, 2.3185082713348368],
					[59.876416503021751, 2.3230138636967053],
					[59.875916504902825, 2.325255555748817],
					[59.875916504281818, 2.325255561250227],
					[59.875916501967467, 2.3252555688846996],
					[59.875916502148009, 2.3252555724325883],
					[59.875172002248696, 2.3307860735118275],
					[59.875172001367012, 2.3307860916099141],
				],
			],
		},
		{
			fldNpdidField: "3505505",
			wlbNpdidWellbore: "4805",
			fldName: "RINGHORNE ØST",
			fldCurrentActivitySatus: "Producing",
			wlbName: "25/8-14 S",
			fldDiscoveryYear: "2003",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=3505505",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=3505505",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "RINGHORNE ØST UNIT",
			cmpLongName: "Vår Energi ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=4805",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "50000032",
			fldNpdidOwner: "3505593",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-02-17 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[59.271044199712101, 2.5101166974453566],
					[59.271044200584576, 2.5101167148758154],
					[59.271044206790663, 2.5101167488845424],
					[59.271044211232081, 2.5101167656332408],
					[59.271283111248586, 2.5108028659060468],
					[59.271719208348678, 2.5120556577010293],
					[59.272055402445851, 2.5133611320229381],
					[59.272119200148396, 2.5142472146394899],
					[59.272144199914472, 2.5146083142822815],
					[59.272144204616879, 2.5146083362615004],
					[59.272144217590629, 2.5146083795385716],
					[59.272144226754229, 2.5146084006661189],
					[59.272472027249613, 2.5151751005625149],
					[59.272472052104312, 2.515175129253775],
					[59.27247211110523, 2.5151751743039061],
					[59.272472145338646, 2.5151751924059389],
					[59.273305544636571, 2.5154140912430965],
					[59.273305562025044, 2.5154140967228855],
					[59.273305596366065, 2.5154140989665956],
					[59.273305612600765, 2.5154140993871885],
					[59.27425828903791, 2.5153530016695913],
					[59.275119283630687, 2.515502983644867],
					[59.275535892647333, 2.5160972535712616],
					[59.275358104107866, 2.517791648359061],
					[59.274994216067334, 2.5186471224160889],
					[59.274763715824257, 2.5191888220762388],
					[59.274763711080659, 2.519188835300509],
					[59.274763704270256, 2.5191888612383635],
					[59.274763702290535, 2.519188875695201],
					[59.274685902012713, 2.5198138756734765],
					[59.274624801805523, 2.5202888738808475],
					[59.274624801892642, 2.5202888756241095],
					[59.274624801000414, 2.5202888757943103],
					[59.274555401405848, 2.5208555767214791],
					[59.274555401384653, 2.5208555943243058],
					[59.274555403213888, 2.5208556309328292],
					[59.274555405956555, 2.5208556497683579],
					[59.274710905993111, 2.5214611500837965],
					[59.274858105616183, 2.5220306507186718],
					[59.27499980577246, 2.52258065079157],
					[59.274999809951055, 2.5225806623140317],
					[59.274999819200502, 2.5225806851888275],
					[59.274999825163626, 2.5225806963709978],
					[59.27549702355055, 2.5234889957673929],
					[59.275827621969746, 2.5241000911929712],
					[59.27681371842904, 2.5261194817925809],
					[59.277527615019629, 2.5278444768355977],
					[59.277527615998906, 2.5278444784090115],
					[59.277527617065196, 2.5278444817258761],
					[59.277527618044473, 2.5278444832992886],
					[59.278272017582097, 2.5294806825478449],
					[59.278272018648359, 2.5294806858648187],
					[59.278272021586147, 2.5294806905853142],
					[59.278272022652395, 2.5294806939022885],
					[59.278722022817043, 2.5303333928631924],
					[59.279208123399222, 2.5312556927506402],
					[59.279497022812805, 2.5318028925842979],
					[59.279997021221973, 2.5327528886672579],
					[59.280422019151473, 2.533658384709343],
					[59.280888719049514, 2.5346556845764172],
					[59.280888789360205, 2.5346557398380338],
					[59.280888946766019, 2.5346557714779414],
					[59.280889033055765, 2.5346557497697524],
					[59.281691813062501, 2.5339362673625754],
					[59.281913987549579, 2.5338279795944767],
					[59.281914011924627, 2.5338279626315594],
					[59.281914055865606, 2.533827922580469],
					[59.281914073560046, 2.5338278980884099],
					[59.282202969088857, 2.5333168070858121],
					[59.282494563357226, 2.5329029144135951],
					[59.282494566752391, 2.5329029102464604],
					[59.282494574261122, 2.5329028982548643],
					[59.282494576677074, 2.5329028925139641],
					[59.28289457438256, 2.5321389990250944],
					[59.283469567291064, 2.5312140113576502],
					[59.283761242842345, 2.5308029444622262],
					[59.283986214185141, 2.5306473633892255],
					[59.283986234011387, 2.5306473455291205],
					[59.283986269659906, 2.53064730176997],
					[59.283986283784621, 2.5306472779547065],
					[59.284341883835374, 2.5298083782615413],
					[59.284341883748382, 2.529808376517749],
					[59.284341884640639, 2.5298083763477321],
					[59.284341884553648, 2.5298083746039399],
					[59.28460847929734, 2.5291528895167357],
					[59.285144562203662, 2.5282612191654477],
					[59.285619549946531, 2.5277251332898363],
					[59.285619554952, 2.5277251252939252],
					[59.28561956513699, 2.5277251127898208],
					[59.285619569337229, 2.5277251067078583],
					[59.285975169089902, 2.5271612062908604],
					[59.285975170787403, 2.5271612042067773],
					[59.285975174987591, 2.5271611981246838],
					[59.285975175705786, 2.5271611944668542],
					[59.286452976117417, 2.5262694944317556],
					[59.286452981035737, 2.5262694846915461],
					[59.286452988195599, 2.5262694657215063],
					[59.286452992221683, 2.5262694561514198],
					[59.286689076642396, 2.5254668087482237],
					[59.287194468812956, 2.5249891100403694],
					[59.287938819902394, 2.5250807923408765],
					[59.288772073735558, 2.5257334562163698],
					[59.289455472385342, 2.5263001539701384],
					[59.289455493777751, 2.526300167497241],
					[59.289455538978153, 2.5263001888088783],
					[59.289455562786195, 2.526300196593402],
					[59.290408200384341, 2.5264779856397457],
					[59.291122056174125, 2.5272195381693656],
					[59.291122059024957, 2.5272195411472298],
					[59.291122063834429, 2.5272195472730998],
					[59.291122066598227, 2.5272195485068329],
					[59.291955466568666, 2.5279640492127795],
					[59.291955470311748, 2.5279640520206583],
					[59.291955475926393, 2.5279640562324861],
					[59.291955479756517, 2.5279640607845368],
					[59.292430480163041, 2.5283223591817072],
					[59.2924305170718, 2.5283223767960621],
					[59.292430594567399, 2.5283223954763621],
					[59.292430636046511, 2.5283223963722037],
					[59.293411135837665, 2.5281418974147485],
					[59.293411162344391, 2.5281418870782839],
					[59.293411211614774, 2.5281418635973139],
					[59.293411234291391, 2.5281418487085769],
					[59.294097333754948, 2.5275195480605221],
					[59.294097349119355, 2.5275195310436307],
					[59.294097375212779, 2.5275194943720001],
					[59.294097385049533, 2.5275194748874004],
					[59.294602984638104, 2.5262694742008902],
					[59.294602990274548, 2.526269460799476],
					[59.294602996824977, 2.5262694296146218],
					[59.294602999610539, 2.5262694132351222],
					[59.294661299728062, 2.5254361136502155],
					[59.294661300185005, 2.525436104758473],
					[59.294661299314377, 2.5254360873153936],
					[59.294661298879063, 2.5254360785938523],
					[59.294511299226613, 2.5240971787987889],
					[59.294511298073125, 2.5240971737361479],
					[59.294511296658378, 2.5240971634405986],
					[59.29451129559196, 2.5240971601222495],
					[59.294214095828288, 2.5226388594452636],
					[59.294214095828288, 2.5226388594452636],
					[59.293977995582786, 2.521511059972958],
					[59.293708497401532, 2.5201416665500531],
					[59.293561300893458, 2.5190416915967893],
					[59.293680797179945, 2.5165722627938192],
					[59.294305769205089, 2.5155918072385144],
					[59.294305778952804, 2.515591786006206],
					[59.294305793899866, 2.515591742649621],
					[59.294305799099185, 2.5155917205253542],
					[59.294452999435514, 2.5141028197794997],
					[59.294452999173885, 2.5141028145467343],
					[59.294453000435048, 2.5141028037401947],
					[59.294453000086193, 2.5141027967631584],
					[59.294422400054458, 2.512080596902285],
					[59.294422399967196, 2.5120805951580425],
					[59.294422399879963, 2.5120805934137804],
					[59.294422399792722, 2.5120805916695339],
					[59.294333500399276, 2.5100888915521287],
					[59.294305802331571, 2.5092861491553893],
					[59.294541839848456, 2.5088695585349847],
					[59.295136029681522, 2.5086308426232451],
					[59.295463706765545, 2.509077875571192],
					[59.295641500613435, 2.5106833216408222],
					[59.295822000788618, 2.5125583190067218],
					[59.295822000963092, 2.5125583224953609],
					[59.295822003270963, 2.5125583326202188],
					[59.295822003445437, 2.5125583361088561],
					[59.296177602610307, 2.5145194359639387],
					[59.296177603589747, 2.5145194375378006],
					[59.296177603764171, 2.5145194410264891],
					[59.296177604743619, 2.5145194426003363],
					[59.296713704175346, 2.5169889423033203],
					[59.297041503632315, 2.5185667411344785],
					[59.297041505765435, 2.5185667477713078],
					[59.297041509052256, 2.5185667594709513],
					[59.29704151118537, 2.5185667661077775],
					[59.297663711114083, 2.5203500655302253],
					[59.297663730875954, 2.5203501005008575],
					[59.297663783260568, 2.5203501574201117],
					[59.297663815078209, 2.5203501812835323],
					[59.298736014906801, 2.5208557809549776],
					[59.29873601865004, 2.5208557837625869],
					[59.298736028726147, 2.5208557871222235],
					[59.298736033361614, 2.5208557897594845],
					[59.300669333185368, 2.5215390883571804],
					[59.300669345938196, 2.5215390912061384],
					[59.300669373315479, 2.5215390983079877],
					[59.300669385981173, 2.5215390994123466],
					[59.30242498623042, 2.5216585005893815],
					[59.302425013084985, 2.521658497223433],
					[59.302425063943105, 2.5216584875132297],
					[59.302425088751797, 2.5216584792539249],
					[59.303316789248072, 2.5212140786421333],
					[59.303316804154861, 2.5212140705115971],
					[59.303316831030386, 2.5212140495274404],
					[59.303316843978465, 2.5212140382481754],
					[59.30403072775929, 2.5204695548288925],
					[59.305130709405205, 2.5197557681313301],
					[59.305130718066202, 2.5197557611926249],
					[59.305130736193291, 2.5197557453999879],
					[59.305130744854281, 2.5197557384612983],
					[59.305697291625741, 2.5191612947866888],
					[59.306527792899914, 2.5191002002723444],
					[59.307124918185131, 2.5191890896444322],
					[59.307808114155321, 2.5197251078970719],
					[59.308016499976659, 2.5211528153421812],
					[59.308016500466366, 2.5223417003339521],
					[59.308016500009686, 2.5223417092292344],
					[59.308016501773103, 2.5223417265086709],
					[59.30801650310093, 2.5223417350632005],
					[59.308252603288864, 2.5236806347821834],
					[59.308252609861754, 2.523680658190111],
					[59.308252631037782, 2.5236807034728761],
					[59.308252643856399, 2.5236807256884024],
					[59.308997044305094, 2.5246029252791313],
					[59.308997051965619, 2.5246029343867877],
					[59.308997067112522, 2.524602949112031],
					[59.308997074685976, 2.5246029564746508],
					[59.309888775164048, 2.5253168552390495],
					[59.30988878273746, 2.5253168626020153],
					[59.309888800386915, 2.5253168733268416],
					[59.309888807786173, 2.5253168771996202],
					[59.310988808176972, 2.5258834780809147],
					[59.310988822888397, 2.5258834840817985],
					[59.310988852137086, 2.5258834925933167],
					[59.310988866761456, 2.5258834968490698],
					[59.312566667307465, 2.5261501964407413],
					[59.312566691746589, 2.5261501988233901],
					[59.312566741974067, 2.52615019452197],
					[59.312566766870098, 2.526150188008196],
					[59.313400066628454, 2.5258529879180216],
					[59.313400078140667, 2.525852983958615],
					[59.313400099206454, 2.5258529728898806],
					[59.313400109739376, 2.5258529673555192],
					[59.314172309957314, 2.525347367593298],
					[59.314172316028916, 2.5253473629103298],
					[59.31417232817212, 2.5253473535443773],
					[59.314172334243736, 2.5253473488613927],
					[59.31506403426107, 2.5245445492047627],
					[59.315064041050718, 2.5245445408604423],
					[59.315064054717077, 2.5245445259171722],
					[59.315064062398967, 2.5245445174024894],
					[59.315927960084707, 2.5233529197057103],
					[59.31678905878104, 2.5222251218795768],
					[59.316789061283536, 2.5222251178774124],
					[59.31678906637562, 2.5222251116184951],
					[59.31678906887813, 2.5222251076163276],
					[59.317772368592784, 2.520677906337097],
					[59.317772373597656, 2.5206778983322891],
					[59.317772381822927, 2.5206778826636538],
					[59.317772385848386, 2.5206778730838635],
					[59.318277986303698, 2.5193972734089307],
					[59.318277988631763, 2.5193972659153943],
					[59.318277993287893, 2.519397250928288],
					[59.318277994723722, 2.5193972436052574],
					[59.318722394650344, 2.5174056444667667],
					[59.318722396803928, 2.5174056334819643],
					[59.318722399413758, 2.5174056135990255],
					[59.318722399870033, 2.5174056047008881],
					[59.318753000437098, 2.5158889088418528],
					[59.318902998666097, 2.513716721213465],
					[59.319169598026178, 2.511902828716694],
					[59.319169597938931, 2.5119028269711787],
					[59.319169598656629, 2.5119028233094274],
					[59.319169599461588, 2.5119028213931927],
					[59.319319596747363, 2.5104750400245819],
					[59.319825183801846, 2.5088083812602577],
					[59.320358453631094, 2.5079168325559777],
					[59.32121120785601, 2.5072168704057969],
					[59.321994473431523, 2.5068473872131314],
					[59.322947259608689, 2.5065501905273146],
					[59.32294729557826, 2.5065501713010172],
					[59.322947356829545, 2.506550117271297],
					[59.322947382198628, 2.50655008421356],
					[59.323452982378825, 2.5054500847972343],
					[59.323452984618697, 2.5054500755559004],
					[59.323452990970218, 2.5054500584771193],
					[59.323452994189658, 2.5054500508105759],
					[59.323719590923581, 2.5044083615209565],
					[59.324197356655787, 2.5032168471693059],
					[59.325058377441167, 2.5028029856933243],
					[59.326247266369307, 2.5023862892792885],
					[59.327377864958315, 2.5019973886529505],
					[59.327377877361833, 2.5019973845125119],
					[59.327377901014458, 2.5019973711649488],
					[59.327377912438372, 2.5019973654496321],
					[59.328122311911208, 2.5014918653845735],
					[59.328122332624908, 2.5014918473116987],
					[59.328122368349391, 2.5014918052085218],
					[59.328122383360181, 2.5014917811782271],
					[59.328480783129052, 2.5006889812764164],
					[59.328480787695476, 2.5006889645363515],
					[59.328480796740898, 2.5006889293102406],
					[59.328480800327689, 2.5006889109953057],
					[59.328569599953056, 2.4991444108552545],
					[59.328569599690709, 2.4991444056174035],
					[59.328569599970805, 2.4991443932246082],
					[59.328569599795912, 2.4991443897326908],
					[59.328450199971222, 2.4970610958528803],
					[59.328480799912725, 2.4951861026552535],
					[59.328480799300237, 2.4951860904337111],
					[59.328480797183076, 2.4951860661618466],
					[59.328480794873684, 2.4951860560286878],
					[59.328122394754786, 2.4936110568941667],
					[59.328122392357784, 2.4936110450153133],
					[59.328122384082477, 2.4936110236885627],
					[59.32812237990106, 2.4936110121522383],
					[59.327586279765086, 2.4925110117683684],
					[59.327586276825897, 2.4925110070446057],
					[59.327586270947556, 2.4925109975970368],
					[59.327586268008368, 2.4925109928732736],
					[59.327052967567084, 2.4916776913404957],
					[59.327052965607606, 2.4916776881914169],
					[59.327052959816754, 2.491677680490004],
					[59.32705295687753, 2.4916776757663706],
					[59.326130756806712, 2.4905165763711241],
					[59.326130751015803, 2.4905165686701123],
					[59.326130737737103, 2.4905165553565736],
					[59.326130732033747, 2.4905165494013586],
					[59.325416840967335, 2.4898915579225216],
					[59.323900162849803, 2.4881970833837341],
					[59.323005774011655, 2.4866221015282872],
					[59.323005772944249, 2.4866220982084384],
					[59.323005770092472, 2.4866220952314344],
					[59.323005769025066, 2.4866220919115851],
					[59.321847368766292, 2.4848054916710378],
					[59.321847365914493, 2.484805488694263],
					[59.321847359143447, 2.4848054794210719],
					[59.321847356204017, 2.4848054746987587],
					[59.320508456003516, 2.4831415756685407],
					[59.32050845315166, 2.4831415726920216],
					[59.320508448340185, 2.4831415665675056],
					[59.320508446380529, 2.4831415634195113],
					[59.319491846368088, 2.4820581628704237],
					[59.319491841731875, 2.4820581602370955],
					[59.319491835048318, 2.4820581527105201],
					[59.319491832196455, 2.4820581497341738],
					[59.315780732289213, 2.4788054496855643],
					[59.315780726673061, 2.4788054454792823],
					[59.315780719097184, 2.478805438125681],
					[59.315780713568742, 2.4788054356646398],
					[59.312811222610385, 2.476747041830297],
					[59.310183435351064, 2.4744637514343504],
					[59.308927939958799, 2.4732637569752995],
					[59.307269579307977, 2.4716081964806165],
					[59.306755800147435, 2.4697249734210884],
					[59.306755799906604, 2.4680694065980271],
					[59.306927993758315, 2.4650973009882704],
					[59.30801399397356, 2.4642418805765596],
					[59.30898335671391, 2.4639557913040755],
					[59.308983361174654, 2.4639557904445226],
					[59.308983370008171, 2.4639557869806006],
					[59.308983373576766, 2.4639557862929582],
					[59.309838974389116, 2.4636140854333668],
					[59.309839014372862, 2.463614056586386],
					[59.309839074638418, 2.4636139833092567],
					[59.309839095900251, 2.4636139404520736],
					[59.310022395836882, 2.462719440146877],
					[59.310022395686993, 2.462719401415701],
					[59.310022382985167, 2.4627193281055124],
					[59.31002236766885, 2.462719292297483],
					[59.309333467801821, 2.4616442913903995],
					[59.309333466909685, 2.4616442915623713],
					[59.309333465841682, 2.4616442882446776],
					[59.309333464861588, 2.4616442866718193],
					[59.308844565133001, 2.4609359868013079],
					[59.308844564152928, 2.4609359852284878],
					[59.308369564142417, 2.4602498866745837],
					[59.308369553625312, 2.4602498746083623],
					[59.308369530806857, 2.4602498508198982],
					[59.308369519397623, 2.4602498389256615],
					[59.307136219207898, 2.4593359382455939],
					[59.307136206190229, 2.4593359301855555],
					[59.307136177566463, 2.4593359163262423],
					[59.307136162852473, 2.4593359103549535],
					[59.306552863104478, 2.4591415095503741],
					[59.305205663150531, 2.4586942101750489],
					[59.30520565770977, 2.458694209462565],
					[59.305205647632363, 2.4586942061209709],
					[59.305205643083724, 2.4586942052364522],
					[59.304044442733158, 2.4584387044318183],
					[59.303583344174264, 2.4583359048355233],
					[59.303583343282135, 2.4583359050075435],
					[59.302522242941372, 2.4581026056955451],
					[59.302522239284841, 2.4581026046391501],
					[59.302522231079699, 2.4581026026983595],
					[59.302522227423168, 2.4581026016419663],
					[59.301047227218284, 2.4578998020117542],
					[59.299402826943357, 2.4576776013219863],
					[59.298488927017893, 2.457552602858859],
					[59.298488910695539, 2.4575526007221593],
					[59.29848888009905, 2.4575526013375697],
					[59.29848886395267, 2.4575526026893928],
					[59.297505580789277, 2.4577331006563479],
					[59.296719398997851, 2.4577358994340059],
					[59.295600001635918, 2.4577387008017775],
					[59.29508330404834, 2.4577276004388291],
					[59.295083303156211, 2.4577276006108089],
					[59.294572296214653, 2.4577192016138447],
					[59.294227955246292, 2.4572970732994182],
					[59.294227955246292, 2.4572970732994182],
					[59.293850155226295, 2.4568359736395382],
					[59.293850150413704, 2.4568359675234031],
					[59.293850139984372, 2.456835957207121],
					[59.293850134279644, 2.4568359512629816],
					[59.293458433893569, 2.4564804524619772],
					[59.292939040699061, 2.4560109577585543],
					[59.292677947351343, 2.455727664801425],
					[59.292175148446447, 2.4551776649379446],
					[59.292175126871413, 2.4551776479672887],
					[59.292175080328825, 2.4551776182019456],
					[59.292175054557134, 2.4551776073232161],
					[59.291547270193398, 2.4549970126301575],
					[59.29062788903245, 2.454572021428481],
					[59.290180703192853, 2.4543331279858545],
					[59.289405712211789, 2.4538137344916171],
					[59.289036211976672, 2.4535637346717132],
					[59.289036210104392, 2.4535637332720235],
					[59.289036207251982, 2.453563730300623],
					[59.289036206359853, 2.4535637304726685],
					[59.288486206167974, 2.4532220292869535],
					[59.288486205275845, 2.4532220294590061],
					[59.288036205932194, 2.4529442293043822],
					[59.287880706437278, 2.4528470302758532],
					[59.287880696271763, 2.4528470251936363],
					[59.287880674156476, 2.4528470153733388],
					[59.287880663098846, 2.4528470104631785],
					[59.286913976442676, 2.4525248139885254],
					[59.286388988094735, 2.4522692193825866],
					[59.285672324188198, 2.4519192387933848],
					[59.285275149700837, 2.45146926732287],
					[59.285275149700837, 2.45146926732287],
					[59.284669549949889, 2.4507859663532079],
					[59.284183449909321, 2.4502387668854548],
					[59.284183445184532, 2.4502387625154296],
					[59.28418343662706, 2.4502387536032666],
					[59.284183431902257, 2.4502387492332267],
					[59.283519555584057, 2.4496526702813042],
					[59.283202979400549, 2.4491110107178868],
					[59.28276128361415, 2.4480805209850822],
					[59.282414084117455, 2.4472749202446713],
					[59.282414071374653, 2.4472748998184821],
					[59.282414040624417, 2.4472748617425371],
					[59.282414024401191, 2.4472748437484375],
					[59.281961223756419, 2.4469165432890643],
					[59.281227924906311, 2.4463331445248144],
					[59.280586237917106, 2.4458192545393818],
					[59.280186250294449, 2.4453665681420911],
					[59.279714050557679, 2.4448220687207218],
					[59.279714051449787, 2.4448220685485116],
					[59.279714049665571, 2.4448220688929316],
					[59.279255760394705, 2.4442998782506606],
					[59.278494572207741, 2.443138797401839],
					[59.277930776744796, 2.4420693071833024],
					[59.277930776656632, 2.4420693054401461],
					[59.277475177197395, 2.4412082055591107],
					[59.277475175236823, 2.4412082024174091],
					[59.277475170335414, 2.4412081945631945],
					[59.277475168374842, 2.4412081914214911],
					[59.277097367661433, 2.4406248906722614],
					[59.276400169019638, 2.4395442926049147],
					[59.275830771203012, 2.4386192965494304],
					[59.275544571126801, 2.4381498965715243],
					[59.275544484005195, 2.4381498482810873],
					[59.275544311221822, 2.4381498517421574],
					[59.27554422636392, 2.4381499015782859],
					[59.274710925799226, 2.4396332016666453],
					[59.274710916240494, 2.4396332263932452],
					[59.27471090336774, 2.4396332746402765],
					[59.274710900141912, 2.4396332999036794],
					[59.274710900023621, 2.4413471817107131],
					[59.274330406717425, 2.4434416443721387],
					[59.273663713621481, 2.4451082253098493],
					[59.273663713797724, 2.4451082287957675],
					[59.273663712817488, 2.445108227224976],
					[59.272949812724704, 2.4469638282624318],
					[59.272949811920682, 2.4469638301774603],
					[59.272949811292854, 2.4469638355783339],
					[59.272949809684796, 2.4469638394084128],
					[59.272427612090212, 2.4485805299421144],
					[59.271760916378362, 2.4501527220810773],
					[59.271760911553919, 2.4501527335704312],
					[59.271760904669442, 2.4501527577759559],
					[59.2717609017173, 2.4501527706641633],
					[59.271474802189019, 2.4520082705602739],
					[59.27147480119698, 2.4520082865900226],
					[59.271474801977327, 2.4520083198764553],
					[59.27147480285759, 2.4520083373051285],
					[59.27171370322754, 2.4532917376445216],
					[59.271713707412118, 2.4532917491569632],
					[59.27171371497716, 2.4532917740966824],
					[59.271713719161752, 2.4532917856091236],
					[59.272663719068412, 2.4552917862838766],
					[59.272663728781609, 2.4552918002514295],
					[59.272663750168192, 2.4552918313286649],
					[59.272663760685525, 2.4552918433813935],
					[59.273949857765544, 2.4565279402506484],
					[59.27551923903534, 2.4581945217692462],
					[59.27718592119119, 2.4610500904648585],
					[59.278185916979972, 2.4633333797670449],
					[59.278185917067852, 2.4633333815102922],
					[59.278185919027891, 2.4633333846533709],
					[59.278185919027891, 2.4633333846533709],
					[59.278994212787737, 2.4650472715877321],
					[59.27961090924537, 2.4670944580042673],
					[59.279610913604458, 2.4670944730077804],
					[59.279610928567706, 2.4670945018134014],
					[59.279610937387581, 2.4670945159587769],
					[59.280272006261086, 2.4680222726968388],
					[59.28038309904202, 2.4692056003994725],
					[59.280269203120689, 2.470466661624743],
					[59.279897014340335, 2.4717277245797078],
					[59.279152628462093, 2.4731304983741338],
					[59.278435937965611, 2.474219282715902],
					[59.277833152895177, 2.4749637633638639],
					[59.27729426340872, 2.4754692536920273],
					[59.277294261712107, 2.4754692557780555],
					[59.277294259123316, 2.4754692580354765],
					[59.277294258231144, 2.47546925820687],
					[59.276780458132443, 2.4759859587939514],
					[59.276780443754923, 2.4759859773963435],
					[59.276780419548473, 2.475986015487444],
					[59.276780409807209, 2.4759860367193984],
					[59.276602610435553, 2.4765221359752183],
					[59.276405414117022, 2.4771055262826054],
					[59.275827619090279, 2.4784027144263208],
					[59.275024821423102, 2.4800360118723619],
					[59.275024818834233, 2.4800360141292903],
					[59.275024817225173, 2.4800360179580947],
					[59.275024815616135, 2.4800360217868942],
					[59.274566516416641, 2.4810943205270415],
					[59.274566515612101, 2.4810943224413911],
					[59.27456651400302, 2.4810943262700911],
					[59.274566513198479, 2.4810943281844415],
					[59.27393871280767, 2.4827277283620561],
					[59.273938711373773, 2.4827277356768205],
					[59.273938706633984, 2.4827277489056008],
					[59.273938705112485, 2.4827277544772639],
					[59.273594206738053, 2.4841888452813241],
					[59.27307761001429, 2.4857360364354655],
					[59.273077607775612, 2.4857360456640829],
					[59.273077603385808, 2.4857360658643866],
					[59.273077601147108, 2.4857360750930124],
					[59.272819200723298, 2.487855576304371],
					[59.272819201248581, 2.4878555867627354],
					[59.272819200602306, 2.4878556097645927],
					[59.272819201215135, 2.4878556219660122],
					[59.273022001035123, 2.4896917227010307],
					[59.273022004324353, 2.4896917343895928],
					[59.273022010185691, 2.4896917614238738],
					[59.273022014454646, 2.4896917746845157],
					[59.273649813594488, 2.4912667738966485],
					[59.273649813681985, 2.4912667756397613],
					[59.273649814574192, 2.4912667754688069],
					[59.273649814574192, 2.4912667754688069],
					[59.274224800245634, 2.4926694394812419],
					[59.274224799836652, 2.4937305935791665],
					[59.274138700763778, 2.4949610756757843],
					[59.273880404078874, 2.4964221569520899],
					[59.273535905972231, 2.4977416481784411],
					[59.273535906059656, 2.4977416499215694],
					[59.27353590614706, 2.4977416516646995],
					[59.273285909231312, 2.498763837527715],
					[59.272733118117259, 2.5000888172395856],
					[59.272247020089296, 2.5010916129775724],
					[59.272247016782543, 2.5010916188895642],
					[59.272247012040808, 2.5010916321152354],
					[59.272247009626284, 2.5010916378565207],
					[59.271816509704429, 2.5024110380826161],
					[59.271816509791776, 2.5024110398256738],
					[59.271216509734195, 2.5042721398686139],
					[59.271216506689257, 2.5042721510092218],
					[59.271216501578948, 2.5042721748628822],
					[59.271216500405764, 2.5042721874053258],
					[59.27110260031813, 2.5061055872127818],
					[59.271102599600503, 2.5061055908693985],
					[59.271102599775105, 2.5061055943554789],
					[59.271102599949707, 2.5061055978415459],
					[59.271058100042488, 2.5091388973752409],
					[59.271044199712101, 2.5101166974453566],
				],
			],
		},
		{
			fldNpdidField: "22492497",
			wlbNpdidWellbore: "6222",
			fldName: "BØYLA",
			fldCurrentActivitySatus: "Producing",
			wlbName: "24/9-9 S",
			fldDiscoveryYear: "2009",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=22492497",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=22492497",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "340",
			cmpLongName: "Aker BP ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=6222",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "28544099",
			fldNpdidOwner: "2986630",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[59.29236920104406, 1.8905805846389014],
					[59.292369207092811, 1.8905806291808323],
					[59.292369240385121, 1.8905807102979009],
					[59.292369264955425, 1.89058074743341],
					[59.295866564844289, 1.89377234788908],
					[59.295866632981401, 1.8937723741384593],
					[59.295866769063394, 1.8937723738146217],
					[59.295866836021659, 1.893772345686048],
					[59.301747264371329, 1.8882974145240465],
					[59.305152765595452, 1.8888807974415698],
					[59.305152824597492, 1.8888807885890804],
					[59.305152928310306, 1.8888807403929735],
					[59.305152972130017, 1.888880701236294],
					[59.306683285109514, 1.8862782193737637],
					[59.308010921167707, 1.8889611887067441],
					[59.308010933994368, 1.8889612089312402],
					[59.308010965789592, 1.8889612463289009],
					[59.308010983867106, 1.8889612636890085],
					[59.310991584372914, 1.8910890631212487],
					[59.310991678669566, 1.8910890733100849],
					[59.310991834861142, 1.8910890123400523],
					[59.310991895769369, 1.891088939625277],
					[59.312427995716909, 1.8838972386121065],
					[59.312427998228856, 1.8838972186918543],
					[59.31242799781068, 1.8838971782311706],
					[59.31242799586731, 1.8838971592465035],
					[59.311536322281384, 1.8795778856575129],
					[59.316297317014971, 1.8761334613619443],
					[59.316297323156533, 1.876133458307446],
					[59.316297334261272, 1.8761334471566193],
					[59.316297340307045, 1.8761334423590492],
					[59.324541814080071, 1.8681057681650408],
					[59.329219481192162, 1.8660390834758591],
					[59.329219516573175, 1.8660390548556045],
					[59.329219574093493, 1.8660389862935702],
					[59.329219595341762, 1.8660389465394962],
					[59.332900194952686, 1.8503639468448243],
					[59.332900196664305, 1.8503639288422853],
					[59.332900199292759, 1.8503638947691707],
					[59.332900199318544, 1.8503638788867314],
					[59.332458508181674, 1.8462528608062951],
					[59.334721688284652, 1.8434623597331454],
					[59.336260984295194, 1.8445612625940748],
					[59.336260992795111, 1.8445612696189548],
					[59.33626101316645, 1.8445612794275079],
					[59.336261024243115, 1.8445612841434604],
					[59.339213824277465, 1.8457807857388688],
					[59.33921382516845, 1.8457807855505506],
					[59.339213827937606, 1.8457807867297189],
					[59.339213829719561, 1.8457807863530959],
					[59.341858229603886, 1.8467695877877666],
					[59.34185825245568, 1.8467695935455939],
					[59.341858296883522, 1.8467695982725223],
					[59.341858320337764, 1.8467695986092771],
					[59.343877819892683, 1.8465695986346624],
					[59.343877836917621, 1.846569596800772],
					[59.343877869691696, 1.8465695863439042],
					[59.343877885537069, 1.8465695794652557],
					[59.345674952996866, 1.8457113446200375],
					[59.347130452787518, 1.8472890363149548],
					[59.347130543688465, 1.8472890665166657],
					[59.347130709700558, 1.847289036720086],
					[59.347130783920711, 1.8472889769101648],
					[59.347733483902147, 1.8458500767569752],
					[59.347733490067945, 1.8458500578044308],
					[59.347733497848395, 1.8458500190966884],
					[59.347733500354025, 1.8458499991531061],
					[59.347730799687298, 1.8440694006679301],
					[59.347730798821225, 1.8440693849670076],
					[59.347730793525116, 1.8440693543188764],
					[59.347730790973294, 1.844069340739348],
					[59.347236291438129, 1.8424860401324514],
					[59.347236286213224, 1.8424860271186443],
					[59.347236275064922, 1.8424860047685001],
					[59.34723626904529, 1.8424859936876694],
					[59.3462169833657, 1.840863974483552],
					[59.347077795087877, 1.8403946309522747],
					[59.350952663688084, 1.8427418528026192],
					[59.352483021545886, 1.8447332656097777],
					[59.351777675874906, 1.8452942427105661],
					[59.351777648902988, 1.8452942766561777],
					[59.351777610586957, 1.8452943500680377],
					[59.351777600133822, 1.8452943893458422],
					[59.351724800072432, 1.8463999895035719],
					[59.351724802695088, 1.8464000207208553],
					[59.351724818439841, 1.8464000774052003],
					[59.351724829876147, 1.8464001049938061],
					[59.352527629893615, 1.8477029050717368],
					[59.352527640656348, 1.8477029204477842],
					[59.352527664662418, 1.8477029471452091],
					[59.352527678796733, 1.847702958278193],
					[59.354249857386542, 1.8490112418965308],
					[59.355366539832687, 1.8505057202370414],
					[59.355366545659429, 1.8505057278319865],
					[59.355366558998689, 1.8505057409002725],
					[59.355366565716402, 1.8505057483069083],
					[59.357060965694757, 1.8520390486814735],
					[59.357060990905218, 1.8520390645384628],
					[59.357061044601735, 1.8520390902641535],
					[59.357061073882647, 1.8520390981995118],
					[59.358944373773816, 1.8522834981030301],
					[59.358944428316768, 1.8522834901075353],
					[59.358944523294483, 1.85228344708505],
					[59.358944564716381, 1.852283413614894],
					[59.360025010404264, 1.8507142372997294],
					[59.360955442071081, 1.8519112225297381],
					[59.360955467666145, 1.8519112453692685],
					[59.360955525599692, 1.8519112825607706],
					[59.360955557842011, 1.8519112951674939],
					[59.361744357826915, 1.8520807959538039],
					[59.361744431875557, 1.8520807820687077],
					[59.361744551249878, 1.8520807074008594],
					[59.361744594697413, 1.8520806452494551],
					[59.362030794912641, 1.850858345653843],
					[59.362030791931829, 1.8508582915493574],
					[59.362030761214754, 1.8508581921048857],
					[59.362030730901665, 1.8508581490752274],
					[59.361333458258095, 1.8502554713654407],
					[59.360505776418215, 1.8487360046758334],
					[59.360505747572581, 1.8487359719328968],
					[59.360505681740889, 1.848735922292742],
					[59.360505643863853, 1.8487359055839252],
					[59.359808385595464, 1.8485776156742177],
					[59.35881405319774, 1.847824864526167],
					[59.357347372578523, 1.8452831993303664],
					[59.357347354206496, 1.8452831767355009],
					[59.357347309636083, 1.8452831367320337],
					[59.357347285219625, 1.8452831189465126],
					[59.356489025572962, 1.8448776382866909],
					[59.354944563005141, 1.8429804833436618],
					[59.353689070274932, 1.8409415940865108],
					[59.35368903795996, 1.8409415638555564],
					[59.353688964107, 1.8409415159367504],
					[59.353688920883322, 1.8409415003708143],
					[59.352161198767853, 1.8407804086130104],
					[59.351044573990521, 1.8394998944963756],
					[59.349936316795002, 1.8366500067561227],
					[59.350716865103877, 1.8349751193726276],
					[59.351949994853314, 1.8338335773596166],
					[59.353458159143919, 1.8353390426658642],
					[59.353458160925847, 1.835339042288477],
					[59.353458164778743, 1.8353390467681103],
					[59.353458166753384, 1.8353390498803466],
					[59.356247066767352, 1.8378390484231499],
					[59.356247068741929, 1.8378390515357632],
					[59.356247074376704, 1.8378390556387469],
					[59.356247077145937, 1.8378390568177516],
					[59.361949847856991, 1.8423140347730409],
					[59.3629026018844, 1.8438750595567717],
					[59.363021999980582, 1.8474139074976055],
					[59.363022016231426, 1.8474139570311721],
					[59.363022071417703, 1.8474140424718763],
					[59.363022110353135, 1.8474140783790234],
					[59.365399910150451, 1.8486112798235868],
					[59.365399988724363, 1.8486112826298045],
					[59.365400124878256, 1.848611234410436],
					[59.36540018325303, 1.8486111814509572],
					[59.366333483337129, 1.8464972813129759],
					[59.366333491334821, 1.8464972301767095],
					[59.36633347951733, 1.8464971302566056],
					[59.366333462471289, 1.8464970826527969],
					[59.365683499922412, 1.8456027353457731],
					[59.365697400014881, 1.8436000375346322],
					[59.366669587276377, 1.8410111701505032],
					[59.366669594260252, 1.8410111333539225],
					[59.366669592985218, 1.8410110612222375],
					[59.366669585617267, 1.8410110256984711],
					[59.366244594141932, 1.8399416468153758],
					[59.365858508139262, 1.8376722292056216],
					[59.367719578643523, 1.8338889874982063],
					[59.367719582014594, 1.8338889832517824],
					[59.36771958678198, 1.8338889716455535],
					[59.367719589165731, 1.8338889658424216],
					[59.36861408928403, 1.8313389655896468],
					[59.368614094363643, 1.8313389433204255],
					[59.368614098887846, 1.8313388946787539],
					[59.368614097537915, 1.8313388702407967],
					[59.368355797893635, 1.8295471716891487],
					[59.368355758108471, 1.8295471041888696],
					[59.368355641747534, 1.8295470211412044],
					[59.368355565966276, 1.8295470036593109],
					[59.367055566050027, 1.829769202725243],
					[59.367055529015914, 1.8297692176427505],
					[59.367055463737749, 1.829769259741183],
					[59.367055434602776, 1.8297692871110247],
					[59.366163787164425, 1.8310637113713257],
					[59.361327778436902, 1.8315887008119054],
					[59.361327724763598, 1.8315887245482594],
					[59.361327638560553, 1.831588795789324],
					[59.36132760800556, 1.8315888464067513],
					[59.36043595006393, 1.834738696620499],
					[59.359411190717061, 1.8347192024462184],
					[59.354258611156816, 1.8289528485301818],
					[59.356422263815432, 1.8276751932931403],
					[59.358413921163674, 1.827469599068591],
					[59.358413986827095, 1.8274695639578784],
					[59.35841407555322, 1.8274694568678453],
					[59.35841409871243, 1.8274693866335823],
					[59.358275198983456, 1.8255471867514836],
					[59.358275172055826, 1.8255471236095282],
					[59.358275084106722, 1.825547032805368],
					[59.358275022892244, 1.8255470016531246],
					[59.356864063463391, 1.82538311825447],
					[59.356489104323686, 1.8237832955999007],
					[59.356780794912389, 1.822272245157657],
					[59.357319593380751, 1.8202833518873311],
					[59.357319593284174, 1.8202833501423914],
					[59.357319594078561, 1.8202833482083232],
					[59.357319594078561, 1.8202833482083232],
					[59.357605793908704, 1.8191250485530488],
					[59.357605750910011, 1.8191249393960878],
					[59.357605590233703, 1.8191248375682871],
					[59.357605472556109, 1.8191248448974382],
					[59.356080472637323, 1.8203720444011982],
					[59.356080455687682, 1.8203720638872687],
					[59.356080424654309, 1.820372105781888],
					[59.356080413339946, 1.8203721293679767],
					[59.35536096296422, 1.8222747965282862],
					[59.353830831528832, 1.8222470034779072],
					[59.35403909827938, 1.8208778297354455],
					[59.354039098784135, 1.8208778225672386],
					[59.354039099697083, 1.8208778064860631],
					[59.354039100105261, 1.8208777975730834],
					[59.354033499798163, 1.8202388988065723],
					[59.354033477419428, 1.8202388364759858],
					[59.354033398086727, 1.8202387385711609],
					[59.354033341229332, 1.820238704741695],
					[59.353286211381828, 1.8200831190220066],
					[59.35242799904389, 1.8190221295386824],
					[59.35241410047778, 1.8170639595246696],
					[59.354550154974994, 1.8138251282597375],
					[59.356263953260971, 1.8121308294065392],
					[59.357641531043903, 1.8125890543093839],
					[59.3581109055499, 1.8145417465171059],
					[59.358110916992864, 1.814541774099498],
					[59.35811094592195, 1.8145418244494358],
					[59.358110964299016, 1.8145418470276911],
					[59.358783163917103, 1.8151612474963865],
					[59.358783189131564, 1.8151612633263061],
					[59.358783242834278, 1.8151612889940854],
					[59.358783272213465, 1.8151612986426668],
					[59.359891671646587, 1.8153195984322252],
					[59.359891735793013, 1.8153195848049652],
					[59.359891843250637, 1.8153195231337429],
					[59.359891886465213, 1.8153194733447291],
					[59.360372385759483, 1.8141028735002038],
					[59.360372392832424, 1.814102838450647],
					[59.360372394408785, 1.8141027692567193],
					[59.360372388912189, 1.8141027351123342],
					[59.359925188911937, 1.8128082350933485],
					[59.359925187827699, 1.812808231792564],
					[59.359925184671646, 1.8128082236353034],
					[59.359925182599824, 1.8128082187788221],
					[59.358572441898453, 1.8097417525370856],
					[59.360014032194684, 1.8084751497949869],
					[59.360014034770678, 1.808475147481609],
					[59.360014039922703, 1.8084751428548289],
					[59.360014043389612, 1.8084751403520061],
					[59.361714042869707, 1.8067362395635684],
					[59.36171404455478, 1.8067362374394398],
					[59.361714048815792, 1.8067362330016772],
					[59.361714051391779, 1.8067362306880224],
					[59.364033450881969, 1.8040501312116228],
					[59.364033459307045, 1.8040501205895323],
					[59.364033471799502, 1.8040501020385558],
					[59.364033477551864, 1.8040500919852518],
					[59.365727971169619, 1.8007640037291084],
					[59.367358463366124, 1.7984557143854161],
					[59.367358474869953, 1.7984556942749119],
					[59.367358493035702, 1.7984556480212217],
					[59.367358498903606, 1.7984556238131724],
					[59.36757519871378, 1.7966778239038206],
					[59.367575182365954, 1.796677756745906],
					[59.367575108767603, 1.7966776488051652],
					[59.367575052311068, 1.7966776060871543],
					[59.366114047717836, 1.7962831332280584],
					[59.365730814970618, 1.7953360982129669],
					[59.366350186136621, 1.7937694736691456],
					[59.366350186930575, 1.7937694717339403],
					[59.366350189215524, 1.7937694641830746],
					[59.366350190009484, 1.7937694622478724],
					[59.367383490261055, 1.7905722613018717],
					[59.367383495333563, 1.7905722390278764],
					[59.367383498157679, 1.7905721925089548],
					[59.367383497787969, 1.7905721696294519],
					[59.367150197609398, 1.7890971690044055],
					[59.367150148604523, 1.7890970982196344],
					[59.367150015390934, 1.7890970206710926],
					[59.36714992930348, 1.7890970125420145],
					[59.366666629081728, 1.78928041247818],
					[59.366666621160896, 1.7892804159334919],
					[59.366666606210053, 1.7892804226541359],
					[59.366666599277053, 1.7892804276647563],
					[59.365358199350766, 1.7900442263762106],
					[59.365358182909056, 1.7900442387118791],
					[59.365358152892199, 1.7900442663037968],
					[59.365358140110963, 1.7900442796248233],
					[59.363424839854147, 1.7926331807377798],
					[59.363424825969375, 1.7926332066528869],
					[59.363424806411636, 1.7926332602644057],
					[59.363424799847785, 1.7926332881507154],
					[59.363316499854122, 1.7944388881844131],
					[59.363316500047944, 1.7944388916747174],
					[59.363316500241766, 1.794438895165023],
					[59.363316500435587, 1.7944388986553415],
					[59.363288700297453, 1.7976777516846312],
					[59.362322022382536, 1.7996332081286157],
					[59.360455426068683, 1.8030582013128966],
					[59.358647032158885, 1.8061220908558604],
					[59.357436037125694, 1.8078441424982905],
					[59.356144492754218, 1.8071692225429041],
					[59.356144453381155, 1.8071692114961779],
					[59.356144372832894, 1.8071692056746178],
					[59.35614433175445, 1.8071692126446142],
					[59.354494331623656, 1.807763711850918],
					[59.354494293390935, 1.8077637376331528],
					[59.354494231256346, 1.8077638038037771],
					[59.354494208245427, 1.8077638440027457],
					[59.354033110054814, 1.8093499370917978],
					[59.353274826316124, 1.8113942946079613],
					[59.352319454837215, 1.812463538923589],
					[59.351833506256156, 1.8113999717856479],
					[59.352077997568053, 1.8097250285099729],
					[59.352077999059787, 1.8097250228973449],
					[59.352078000164738, 1.8097250103061762],
					[59.352077999777954, 1.8097250033276211],
					[59.35210849995854, 1.8082722035387409],
					[59.35210850036588, 1.8082721946261777],
					[59.352108498217667, 1.8082721721353434],
					[59.352108495952287, 1.8082721637909609],
					[59.351639096204565, 1.8058082622914731],
					[59.351639019707811, 1.8058081832394479],
					[59.351638840833544, 1.8058081436101581],
					[59.351638738359306, 1.8058081812882938],
					[59.350547083115536, 1.8073054193472569],
					[59.349530599133601, 1.807521996398157],
					[59.348841885978359, 1.8073831345127491],
					[59.34871129913963, 1.8058832835288356],
					[59.348711281982816, 1.8058832342262385],
					[59.348711225182882, 1.8058831527573562],
					[59.348711186430641, 1.8058831204016703],
					[59.34746958843516, 1.805288767749361],
					[59.347328009415008, 1.8038389842080129],
					[59.348022329800891, 1.8032445523170817],
					[59.348022340007809, 1.8032445413211249],
					[59.348022357845707, 1.803244521642106],
					[59.348022366367594, 1.8032445127695431],
					[59.349044561694249, 1.8017251185319569],
					[59.349772358449719, 1.8007945234692844],
					[59.34977236083197, 1.8007945176671256],
					[59.349772369353666, 1.8007945087934625],
					[59.349772371639098, 1.800794501246803],
					[59.350500172426457, 1.7995695019288254],
					[59.350500184937822, 1.7995694674937581],
					[59.350500196209779, 1.7995693944897122],
					[59.350500195958148, 1.7995693574756222],
					[59.350372396992221, 1.7989749664950367],
					[59.350241908306337, 1.7979000506410749],
					[59.350905746791099, 1.796916839785951],
					[59.351758419233974, 1.7962862615253121],
					[59.35175844142826, 1.796286239147292],
					[59.351758475629602, 1.7962861894997304],
					[59.351758490309287, 1.7962861616610826],
					[59.352044590443654, 1.7954000616102548],
					[59.352044594160759, 1.7954000149230438],
					[59.352044580213779, 1.7953999261021676],
					[59.352044561561904, 1.7953998824136361],
					[59.351544603794984, 1.7947110393563335],
					[59.351655799769766, 1.7927250127720362],
					[59.35186409937382, 1.7899750265452716],
					[59.352616895709559, 1.785963937855414],
					[59.352694596035313, 1.7855611380245135],
					[59.352694560912596, 1.7855610413642953],
					[59.352694424866485, 1.785560929157195],
					[59.352694324040087, 1.7855609153548997],
					[59.352113823708542, 1.7857998154413004],
					[59.352113785577636, 1.7857998429905366],
					[59.352113726321562, 1.7857999121148085],
					[59.352113705293398, 1.7857999554343862],
					[59.351772006951961, 1.7872943458851989],
					[59.351019240359761, 1.7894748481570317],
					[59.349736211664521, 1.7899997373707495],
					[59.349133501173476, 1.788461064501901],
					[59.349161299231611, 1.7873806287839762],
					[59.349911293060138, 1.7845333505046257],
					[59.349911295344604, 1.7845333429569086],
					[59.349911297937759, 1.7845333247526143],
					[59.349911299331367, 1.7845333173948907],
					[59.350205798863819, 1.781066716566271],
					[59.3502057476055, 1.7810666215950974],
					[59.350205587679874, 1.7810665339251528],
					[59.350205479012558, 1.7810665412263817],
					[59.349438778904279, 1.7816470414682539],
					[59.349438755918882, 1.781647065788502],
					[59.349438720135787, 1.7816471193157966],
					[59.349438705653448, 1.781647150647377],
					[59.349005405690164, 1.7833332504258015],
					[59.349005404993392, 1.7833332541045726],
					[59.349005404393665, 1.7833332595277396],
					[59.349005402806036, 1.7833332633965377],
					[59.348474816012491, 1.7862054962034839],
					[59.347897065610226, 1.7867276519206501],
					[59.34789704459952, 1.7867276793451496],
					[59.347897012765657, 1.7867277390823086],
					[59.347897001942492, 1.7867277713949568],
					[59.347483104827411, 1.7896221505998797],
					[59.346988778692257, 1.7909386537309662],
					[59.345683411050622, 1.7905387255711112],
					[59.345019547823249, 1.7898081649275797],
					[59.345019476608137, 1.7898081324506416],
					[59.345019333910898, 1.7898081275588309],
					[59.345019260647014, 1.7898081555235892],
					[59.343866649894508, 1.7909164702102007],
					[59.343047357313331, 1.7906276428179595],
					[59.342294594148512, 1.7884721495677465],
					[59.342052996647645, 1.7870360673496708],
					[59.342052922716299, 1.7870359860479919],
					[59.342052745426123, 1.7870359426578772],
					[59.342052640982494, 1.7870359772712718],
					[59.341308140752204, 1.7880054787111177],
					[59.341308131534475, 1.7880054912633978],
					[59.341308117050396, 1.7880055225846003],
					[59.341308109711392, 1.7880055365011904],
					[59.340530409932782, 1.7903666373897436],
					[59.340530405556976, 1.7903666559681028],
					[59.340530401356645, 1.7903666939199014],
					[59.340530399750399, 1.7903667136729033],
					[59.340624800186895, 1.791758313437422],
					[59.340624804022632, 1.7917583337959855],
					[59.340624815257613, 1.7917583737541305],
					[59.340624822656878, 1.7917583933537167],
					[59.341560914078066, 1.7935306765100283],
					[59.34225539898334, 1.7957833264455147],
					[59.342124799950973, 1.7987582901476729],
					[59.342124801112973, 1.7987583110766878],
					[59.342124806806879, 1.7987583486882943],
					[59.342124812423307, 1.7987583686694755],
					[59.342474799585759, 1.7997333354114826],
					[59.342477599923562, 1.8027443799641256],
					[59.341913720824699, 1.8055109773008517],
					[59.340547089778823, 1.8064081336910367],
					[59.340547059060356, 1.8064081649242871],
					[59.340547013736114, 1.806408241612101],
					[59.340547000718729, 1.8064082831998747],
					[59.340546784648012, 1.8064109371913535],
					[59.339950189900719, 1.8045666383315524],
					[59.339950148108727, 1.8045665836918683],
					[59.339950037950913, 1.804566515353176],
					[59.339949971366885, 1.8045665012753938],
					[59.335822170756366, 1.8051776019317836],
					[59.335822150362269, 1.8051776080306658],
					[59.335822109670808, 1.8051776219722298],
					[59.335822091252027, 1.8051776311799688],
					[59.332191696837036, 1.8075275648742248],
					[59.330236204747457, 1.8063248293228369],
					[59.330236140836313, 1.8063248146763666],
					[59.330236018201489, 1.8063248301466974],
					[59.330235959381078, 1.8063248585200045],
					[59.328144381274932, 1.8084053358836039],
					[59.32659459038446, 1.8072470924463955],
					[59.326144599178363, 1.8029471797158776],
					[59.326144587152932, 1.8029471417031062],
					[59.326144550028822, 1.802947073747464],
					[59.326144524930122, 1.8029470438046089],
					[59.323464025073861, 1.8008026432322501],
					[59.323463928702857, 1.8008026284431258],
					[59.323463768611589, 1.8008026836373823],
					[59.323463705084905, 1.8008027571070504],
					[59.321585908727634, 1.8093277388793858],
					[59.317688770656929, 1.8181886963772576],
					[59.310630622284798, 1.8179581015466946],
					[59.30778893705228, 1.8174220035187796],
					[59.307788894792182, 1.8174220054237775],
					[59.307788813813772, 1.8174220243489112],
					[59.307788776082916, 1.8174220429226047],
					[59.301260975828022, 1.8225665426861355],
					[59.301260951341256, 1.8225665725458045],
					[59.301260913541441, 1.8225666387119299],
					[59.301260901215826, 1.8225666765718058],
					[59.300733101395714, 1.8268666750753155],
					[59.300733100793991, 1.8268666804900509],
					[59.300733100288703, 1.8268666876468698],
					[59.30073309959058, 1.8268666913195359],
					[59.300341501144821, 1.836874978235528],
					[59.29956370262294, 1.8411805644941597],
					[59.299563703945111, 1.8411806047498736],
					[59.299563719665471, 1.8411806772130344],
					[59.29956373613431, 1.8411807142704022],
					[59.301288736315598, 1.8436612143545021],
					[59.301288783365891, 1.84366125024651],
					[59.30128889018318, 1.8436612911464736],
					[59.301288948962984, 1.8436612946003683],
					[59.307035956157755, 1.8421974437185507],
					[59.309222002316794, 1.8506889336730454],
					[59.309841499193546, 1.8579249922916283],
					[59.308463708269294, 1.8660388378857322],
					[59.305885919768144, 1.871338812353212],
					[59.30588591668311, 1.8713388218153384],
					[59.305885910321429, 1.871338837254557],
					[59.305885907236394, 1.8713388467166818],
					[59.304213729029776, 1.8772831699479571],
					[59.296169296155725, 1.8822109291446083],
					[59.296169278812862, 1.882210941595676],
					[59.296169248869411, 1.8822109707887542],
					[59.296169235377818, 1.8822109877178004],
					[59.292599834726666, 1.887422087172232],
					[59.292599823398838, 1.8874221106911799],
					[59.292599806280784, 1.8874221600916077],
					[59.292599801381662, 1.8874221857861855],
					[59.29236920104406, 1.8905805846389014],
				],
			],
		},
		{
			fldNpdidField: "43665",
			wlbNpdidWellbore: "0",
			fldName: "MURCHISON",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "?",
			fldDiscoveryYear: "1975",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43665",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43665",
			fldOwnerKind: "",
			fldOwnerName: "",
			cmpLongName: "",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=0",
			fldHcType: "OIL",
			fldMainArea: "North Sea",
			cmpNpdidCompany: "",
			fldNpdidOwner: "",
			fldDateUpdated: "2019-11-28 00:00:00",
			fldDateUpdatedMax: "2020-02-26 00:00:00",
			fldMainSupplyBase: "Peterhead",
			fldAreaGeometryWKT: [
				[
					[61.371844200623691, 1.7257278232093651],
					[61.372785901205432, 1.733733323234284],
					[61.372785902594238, 1.7337333323053596],
					[61.372785907152824, 1.7337333500321366],
					[61.372785908641312, 1.733733360958964],
					[61.376366508069481, 1.7450528567661443],
					[61.378760906847234, 1.7536056538616784],
					[61.37876090783714, 1.7536056555106927],
					[61.378760908827097, 1.7536056571597118],
					[61.378760908926481, 1.753605659015901],
					[61.382438708198912, 1.7655278577512212],
					[61.384827608255598, 1.7739167545920678],
					[61.384827609542647, 1.7739167618119602],
					[61.384827615778157, 1.7739167772817328],
					[61.384827618945522, 1.7739167859449372],
					[61.387683113975328, 1.7800083759019818],
					[61.391449811455885, 1.7909278657362162],
					[61.391449813731583, 1.7909278746092465],
					[61.391449821746505, 1.7909278896732854],
					[61.391449825803392, 1.7909278981338648],
					[61.393902625592872, 1.7952611991688623],
					[61.393902649637255, 1.7952612274464266],
					[61.39390270801794, 1.7952612740990395],
					[61.393902741364784, 1.7952612908229431],
					[61.397963840890235, 1.796511290385685],
					[61.397963918966312, 1.7965112835979806],
					[61.397964045039949, 1.7965112130510736],
					[61.397964093928124, 1.7965111490857457],
					[61.399552988639982, 1.7904139679982913],
					[61.401633482278832, 1.7859056836539382],
					[61.401633485445281, 1.7859056753973868],
					[61.401633490788612, 1.7859056572330736],
					[61.401633493955046, 1.785905648976527],
					[61.403030785838432, 1.7804167801856212],
					[61.405125169033766, 1.7771140065845381],
					[61.40512517793816, 1.7771139875897584],
					[61.405125192876987, 1.7771139465047396],
					[61.405125198119975, 1.7771139264790576],
					[61.405575191783839, 1.7736750756104918],
					[61.407672347793437, 1.7707723366041788],
					[61.408916829238173, 1.7697168530018776],
					[61.408916842891806, 1.7697168385419264],
					[61.40891686653773, 1.7697168085918626],
					[61.408916877420531, 1.7697167928947997],
					[61.414416863860602, 1.7592334184134026],
					[61.416808381241736, 1.7567196046138673],
					[61.421055588980288, 1.7569530003139369],
					[61.42105559432347, 1.7569529990692523],
					[61.421055603427561, 1.7569530007121901],
					[61.421055607880177, 1.7569529996749571],
					[61.423855607608289, 1.7568419000564175],
					[61.423855634423575, 1.7568418956910474],
					[61.423855684094356, 1.7568418803546673],
					[61.423855707741069, 1.7568418673173176],
					[61.425869402625075, 1.7555530358918698],
					[61.428391556084762, 1.7570640475017911],
					[61.431227631154528, 1.7615862064750747],
					[61.431227650355993, 1.7615862283577648],
					[61.431227693903431, 1.7615862671600373],
					[61.431227719140004, 1.7615862838722094],
					[61.434135987091416, 1.7628807680433578],
					[61.437930461653508, 1.766502945798633],
					[61.437930488769922, 1.7665029639631815],
					[61.437930548839454, 1.7665029914020081],
					[61.437930580802771, 1.7665029990238337],
					[61.439086080604, 1.7666112995340477],
					[61.439086162829128, 1.766611269093584],
					[61.439086271262518, 1.7666111553510104],
					[61.439086298460637, 1.7666110737014074],
					[61.438164097639543, 1.759899973839778],
					[61.438164098231965, 1.7598999680530212],
					[61.438164096152811, 1.7598999628888166],
					[61.43816409595405, 1.7598999591693183],
					[61.437305812999455, 1.7557556427274927],
					[61.440589064901239, 1.7509362136307491],
					[61.440589080030193, 1.7509361762054101],
					[61.440589095940368, 1.7509361028195369],
					[61.440589096622077, 1.7509360649991144],
					[61.440083497115779, 1.7481360648256694],
					[61.4400834856282, 1.7481360354953781],
					[61.440083457409465, 1.7481359799417842],
					[61.440083438006845, 1.7481359543420625],
					[61.437844537762736, 1.7460081543578496],
					[61.437844494116973, 1.7460081306553146],
					[61.437844400796564, 1.7460081053698602],
					[61.437844352111924, 1.7460081054386971],
					[61.435916653688437, 1.7464887059085752],
					[61.433700087180107, 1.7469803751350761],
					[61.432311387416, 1.7450028609444719],
					[61.432800101554776, 1.7447112720435844],
					[61.432800151300981, 1.7447111907732433],
					[61.432800158568028, 1.7447110234070922],
					[61.432800117969457, 1.7447109387548434],
					[61.431355745125018, 1.7436498580397395],
					[61.429989092858726, 1.7416916283065649],
					[61.429561298750613, 1.7376777794806726],
					[61.429561294982832, 1.737677759654332],
					[61.429561282004777, 1.7376777193909174],
					[61.42956127378455, 1.7376777006049267],
					[61.427994574016623, 1.7349221008651758],
					[61.42799457104605, 1.7349220959124829],
					[61.427994565104981, 1.7349220860070715],
					[61.427994561243949, 1.7349220812624933],
					[61.425552963105481, 1.7315970839117378],
					[61.424477965418085, 1.7300276866038184],
					[61.424477958685962, 1.73002767876749],
					[61.424477947102403, 1.7300276645371508],
					[61.424477941360514, 1.7300276583513998],
					[61.422722340931244, 1.72828595823952],
					[61.422722333308563, 1.728285950612267],
					[61.422722317272459, 1.7282859374247441],
					[61.422722308859065, 1.7282859318644714],
					[61.421264057254433, 1.7273387639173956],
					[61.419889080761457, 1.7243749154465036],
					[61.419889080761457, 1.7243749154465036],
					[61.419889079671321, 1.7243749119378524],
					[61.419889078680995, 1.7243749102877171],
					[61.417819578851557, 1.7202305115009235],
					[61.417819567957764, 1.7202304933519676],
					[61.417819542808125, 1.7202304616046522],
					[61.417819529442696, 1.7202304477978547],
					[61.41509733477713, 1.717924851690138],
					[61.413344554733108, 1.7161859711454412],
					[61.41216956572574, 1.7144220883008201],
					[61.412169555131136, 1.7144220757311297],
					[61.41216953117064, 1.7144220493595002],
					[61.41216951701422, 1.7144220376241615],
					[61.410325150763605, 1.7130859619391339],
					[61.409050187589962, 1.71092492552451],
					[61.408344594801932, 1.7079332546011852],
					[61.408344593611375, 1.7079332492366357],
					[61.408344592320738, 1.7079332420143356],
					[61.408344590339766, 1.7079332387162991],
					[61.407452990063156, 1.7051527391109578],
					[61.407452976886248, 1.7051527121023442],
					[61.407452943609108, 1.7051526634709571],
					[61.407452923609043, 1.7051526437058722],
					[61.405505724139935, 1.7036220420610977],
					[61.405505675245593, 1.703622021550502],
					[61.405505572725531, 1.7036220098547357],
					[61.40550551989012, 1.7036220166031284],
					[61.402430606827025, 1.7049580797109212],
					[61.399522290736336, 1.7034665219188856],
					[61.399522251346887, 1.7034665104672857],
					[61.399522170007344, 1.7034665069713648],
					[61.399522129737996, 1.7034665126522426],
					[61.397116666638922, 1.7043719993752053],
					[61.394608307915412, 1.7042748006003732],
					[61.394608279221515, 1.7042748035655375],
					[61.394608223224644, 1.7042748185717793],
					[61.394608195821519, 1.7042748287559537],
					[61.391060996109132, 1.7064359295648159],
					[61.391060983052789, 1.7064359382643932],
					[61.391060958921095, 1.7064359589597615],
					[61.391060946955278, 1.7064359711641732],
					[61.386288746762752, 1.7120637707331443],
					[61.386288745972273, 1.7120637727980319],
					[61.386288741719973, 1.71206377755309],
					[61.386288740039063, 1.7120637798264218],
					[61.382094261922099, 1.7176665514385507],
					[61.377010992600937, 1.7208915316048512],
					[61.377010984686592, 1.7208915353336538],
					[61.377010970047927, 1.7208915481510252],
					[61.377010963123873, 1.7208915535277021],
					[61.371844262789949, 1.7257276546196718],
					[61.371844237771846, 1.7257276924033687],
					[61.371844207133918, 1.7257277765942716],
					[61.371844200623691, 1.7257278232093651],
				],
			],
		},
		{
			fldNpdidField: "4380167",
			wlbNpdidWellbore: "2244",
			fldName: "VOLUND",
			fldCurrentActivitySatus: "Producing",
			wlbName: "24/9-5",
			fldDiscoveryYear: "1994",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=4380167",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=4380167",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "150",
			cmpLongName: "Aker BP ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=2244",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "28544099",
			fldNpdidOwner: "22012",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[59.448799801720064, 2.0030694264203892],
					[59.449299802336604, 2.0068806256073275],
					[59.44929982692846, 2.0068806806829533],
					[59.449299906271349, 2.0068807651082894],
					[59.449299958442701, 2.0068807967630926],
					[59.45229995839987, 2.0075195961217949],
					[59.452300003434679, 2.0075195956291187],
					[59.452300087026863, 2.0075195747466377],
					[59.452300127460944, 2.007519555737562],
					[59.455397271879143, 2.0049890996579731],
					[59.457900000725211, 2.0049808008786854],
					[59.457900059969774, 2.0049807797284398],
					[59.457900155372968, 2.0049807103534367],
					[59.457900191343235, 2.0049806586270025],
					[59.458800191173275, 2.0020500585296142],
					[59.458800195339236, 2.0020500187148813],
					[59.458800185563057, 2.002049937534879],
					[59.458800172700649, 2.0020498994862104],
					[59.45770022193711, 2.0001693832560541],
					[59.45850005520176, 1.9981615023165031],
					[59.460602640739182, 2.0009390215095513],
					[59.460602714867825, 2.0009390592267211],
					[59.460602867659659, 2.0009390682095276],
					[59.460602946134337, 2.0009390359732366],
					[59.462502871878186, 1.9989002165220848],
					[59.464802766280542, 1.9993001977008862],
					[59.464802829654552, 1.9993001863016675],
					[59.464802939177247, 1.9993001281262659],
					[59.464802982557828, 1.9993000801545959],
					[59.466597382573688, 1.995211180338559],
					[59.466597384261874, 1.9952111782166471],
					[59.466597385058819, 1.9952111762800671],
					[59.46810284719173, 1.9916115063421125],
					[59.469599838250218, 1.9936807160956709],
					[59.46959992880079, 1.9936807574737474],
					[59.469600100959127, 1.9936807411422122],
					[59.469600181675659, 1.9936806836179894],
					[59.471297312183012, 1.9899891348905869],
					[59.473597150157296, 1.9905807938870779],
					[59.473597228775759, 1.9905807810652403],
					[59.473597353392208, 1.9905807019895247],
					[59.47359739662204, 1.9905806345405839],
					[59.47440019300128, 1.9860694541459376],
					[59.476002894963806, 1.9820614023971339],
					[59.478199818025004, 1.9835112202958987],
					[59.479302599254773, 1.9896806111117447],
					[59.479099800024883, 1.9935193896973171],
					[59.479099799699775, 1.993519400394099],
					[59.479099801723322, 1.9935194212312095],
					[59.479099803275091, 1.993519433308911],
					[59.479599791835199, 1.9964805712377705],
					[59.47769982035117, 2.0004305136982858],
					[59.477699813178162, 2.0004305311323565],
					[59.47769980427401, 2.0004305666406159],
					[59.477699800760341, 2.0004305850853896],
					[59.477299805093544, 2.0059305252030439],
					[59.475102642232549, 2.0087692772599972],
					[59.475102625725036, 2.0087693054851163],
					[59.475102604767407, 2.00876936828847],
					[59.475102600128999, 2.008769399363227],
					[59.475102600262368, 2.0116110998732237],
					[59.475102634989369, 2.011611175916217],
					[59.475102752096696, 2.0116112756021467],
					[59.475102832600349, 2.0116112978632592],
					[59.477500032826903, 2.011211296729269],
					[59.477500084659752, 2.0112112717995192],
					[59.477500165334611, 2.0112111965982402],
					[59.477500194176621, 2.0112111463267031],
					[59.479600145087147, 2.002561347100718],
					[59.481097145713541, 2.0029807926239491],
					[59.481097234934673, 2.0029807758519453],
					[59.481097361899174, 2.0029806732847182],
					[59.48109739992524, 2.0029805927455628],
					[59.480800207958147, 1.9959307744075634],
					[59.484000026232266, 1.9955112986480932],
					[59.484000063381941, 1.9955112856023984],
					[59.484000129796556, 1.9955112469775595],
					[59.484000159061488, 1.9955112213983945],
					[59.48779990486431, 1.9904893568053521],
					[59.488899799030712, 1.9950306206188217],
					[59.488602600205688, 2.0044499936887386],
					[59.488602668624772, 2.0044500928753624],
					[59.488602851966846, 2.0044501504544354],
					[59.488602965295733, 2.0044501127222216],
					[59.490999932641131, 2.0009198551361802],
					[59.492297009203341, 2.0050611592824832],
					[59.492297097693665, 2.0050612294977515],
					[59.492297284824865, 2.0050612402203316],
					[59.492297381777476, 2.0050611828507745],
					[59.494900182341006, 1.9993611822143791],
					[59.494900189325101, 1.9993611612654896],
					[59.494900197662844, 1.9993611152221611],
					[59.494900200096339, 1.9993610934477426],
					[59.494800200363898, 1.9965610937875686],
					[59.494800191158561, 1.9965610567120946],
					[59.494800161350199, 1.9965609884781952],
					[59.494800139855947, 1.9965609575052978],
					[59.49089738787017, 1.9927499032662699],
					[59.490503032426851, 1.9900196097959062],
					[59.494202792315534, 1.9901695995636521],
					[59.494202857889817, 1.9901695788131202],
					[59.494202960643094, 1.9901694953750204],
					[59.494202997119672, 1.9901694363784115],
					[59.494700196686004, 1.987480636534362],
					[59.494700198320736, 1.9874806166977688],
					[59.494700198025214, 1.9874805777675784],
					[59.494700196094996, 1.9874805586739794],
					[59.493697420864841, 1.9828000785625359],
					[59.495299881226948, 1.981511452340954],
					[59.49679980562837, 1.9876111486747545],
					[59.496799837170492, 1.9876111988192684],
					[59.496799922293498, 1.9876112732451552],
					[59.496799975968806, 1.9876112992793256],
					[59.499399975891265, 1.9879195993484402],
					[59.499400050471849, 1.987919578484818],
					[59.499400161149971, 1.9879194916010732],
					[59.499400198138716, 1.9879194253951875],
					[59.499900197947952, 1.9839194245019789],
					[59.499900190656604, 1.983919372843673],
					[59.499900151402976, 1.9839192799904992],
					[59.499900117563747, 1.9839192374144818],
					[59.49690017720026, 1.9817304802888307],
					[59.49560301124145, 1.9764694203415265],
					[59.497200181407052, 1.9730194846702036],
					[59.497200186888335, 1.9730194693452257],
					[59.497200195177228, 1.973019439253701],
					[59.497200198876072, 1.9730194243010206],
					[59.49760017914641, 1.9696807842921349],
					[59.499600018994037, 1.9694890993348142],
					[59.499600078743484, 1.9694890708934798],
					[59.49960016872113, 1.9694889847277555],
					[59.499600198759921, 1.9694889234976671],
					[59.501300198577923, 1.9555111239730321],
					[59.501300199089528, 1.9555111167748278],
					[59.501300199923001, 1.9555110988726487],
					[59.50130020033972, 1.955511089921562],
					[59.500900199550273, 1.9471610895562577],
					[59.500900188358692, 1.9471610493567395],
					[59.500900149138467, 1.9471609742608698],
					[59.500900121109829, 1.9471609393645044],
					[59.498900214563704, 1.9456499114677974],
					[59.49929739838386, 1.9429611293059526],
					[59.499297393048856, 1.9429610807901621],
					[59.499297362167539, 1.9429609933167875],
					[59.499297334743872, 1.9429609529803831],
					[59.498402986811811, 1.9421388010573286],
					[59.497897408907811, 1.9387500433053633],
					[59.499500094022089, 1.9361613271129261],
					[59.501202754433777, 1.9365612947940847],
					[59.501202789663871, 1.9365612962597065],
					[59.501202861240401, 1.9365612865469692],
					[59.501202895804546, 1.9365612757429],
					[59.503502896249664, 1.9353112757167241],
					[59.503502939604061, 1.9353112276056552],
					[59.503502988443984, 1.9353111145173059],
					[59.503502992943211, 1.9353110479743092],
					[59.501597449594996, 1.9281807614911408],
					[59.503502833072034, 1.9278612962265114],
					[59.503502870213353, 1.9278612830975912],
					[59.503502934727422, 1.927861242937551],
					[59.503502963882418, 1.9278612155316248],
					[59.505000164371602, 1.9257195147134154],
					[59.505000174660708, 1.9257194221253116],
					[59.505000116691839, 1.9257192676562529],
					[59.50500004665161, 1.9257192061501966],
					[59.503800373925458, 1.9254304839594261],
					[59.505200147670713, 1.9238807344644435],
					[59.50520017297886, 1.9238806528990393],
					[59.505200152115385, 1.9238805012625875],
					[59.505200104957375, 1.923880429626075],
					[59.502700323408355, 1.9223305652030835],
					[59.507100138580846, 1.9180695432104902],
					[59.507100161209678, 1.9180695118476567],
					[59.507100191637292, 1.9180694416064139],
					[59.507100200327208, 1.91806940254035],
					[59.507400200024534, 1.89831110342304],
					[59.507400192382299, 1.8983110624804846],
					[59.507400160358898, 1.8983109876770494],
					[59.507400135977754, 1.8983109538161629],
					[59.50610018544748, 1.8971109984471333],
					[59.504800207986712, 1.8900306250547632],
					[59.506400182903157, 1.8864500817383938],
					[59.506400187281379, 1.8864500134320341],
					[59.506400150690574, 1.8864498881856815],
					[59.506400109817378, 1.8864498329985613],
					[59.502603002675521, 1.8839609945234679],
					[59.502800200289236, 1.8768889055028004],
					[59.502800183661797, 1.8768888487429587],
					[59.502800121674781, 1.8768887537198355],
					[59.502800077206203, 1.8768887152678464],
					[59.500900207635041, 1.8760998692846844],
					[59.501400200124891, 1.8677889125045866],
					[59.501400199836709, 1.8677889072468943],
					[59.501400200343461, 1.8677889000476946],
					[59.50140020015133, 1.8677888965425793],
					[59.501300216363418, 1.8606705695193642],
					[59.50219979917771, 1.8631194324239306],
					[59.502102600272629, 1.8663888938882636],
					[59.502102601233474, 1.8663889114141845],
					[59.502102605924243, 1.8663889476516267],
					[59.502102611340092, 1.8663889642325628],
					[59.502997011371548, 1.869038963414686],
					[59.502997076186247, 1.8690390276837705],
					[59.502997230197749, 1.8690390748122183],
					[59.502997320381631, 1.8690390592353014],
					[59.503900119604786, 1.8683612598000034],
					[59.503900144291272, 1.8683612332879063],
					[59.503900180901923, 1.8683611776510978],
					[59.503900195307025, 1.8683611444540773],
					[59.505500194833864, 1.8613694449106672],
					[59.50550019642354, 1.8613694410268562],
					[59.505500196929923, 1.8613694338266973],
					[59.505500197532449, 1.8613694283793096],
					[59.506200186018262, 1.8565807135086378],
					[59.5083001174198, 1.8550695614964989],
					[59.508300148653703, 1.8550695229392278],
					[59.508300189762792, 1.8550694344037],
					[59.508300198746994, 1.8550693846147841],
					[59.50809739891708, 1.8525305844167976],
					[59.508097380785962, 1.8525305332983741],
					[59.508097319359386, 1.8525304488242627],
					[59.508097276954921, 1.8525304152791593],
					[59.506200142587673, 1.851738742383193],
					[59.504800176622382, 1.8491110057790094],
					[59.504800159043839, 1.8491109811468562],
					[59.504800118035277, 1.8491109402195136],
					[59.504800094605265, 1.8491109239243249],
					[59.504002895359726, 1.8486804238702437],
					[59.504002794608098, 1.848680427565448],
					[59.504002648104141, 1.8486805172341318],
					[59.504002599775262, 1.848680605528674],
					[59.504099799129158, 1.852269347069083],
					[59.503502628184961, 1.8532692975513665],
					[59.503502616456743, 1.8532693301860903],
					[59.503502603089835, 1.8532693986305067],
					[59.503502603136845, 1.853269432308869],
					[59.504299794356179, 1.8580805805405425],
					[59.502997194201789, 1.861518851883821],
					[59.501800185021381, 1.8585610241719783],
					[59.501800138092577, 1.8585609738562179],
					[59.501800022102103, 1.8585609133840042],
					[59.501799953232798, 1.8585609067326596],
					[59.499297153063765, 1.8591609051996749],
					[59.499297094015368, 1.8591609461016176],
					[59.499297018923784, 1.8591610560046907],
					[59.499297001989632, 1.8591611251949831],
					[59.49999979830946, 1.8646305995942016],
					[59.499399810428493, 1.8688999172460659],
					[59.497799844791935, 1.8708498736113872],
					[59.497799830796978, 1.8708498978503976],
					[59.497799809140162, 1.8708499467588906],
					[59.497799801670347, 1.8708499749331249],
					[59.497299802077137, 1.8746610745694361],
					[59.497299801734627, 1.874661101232538],
					[59.497299810151723, 1.8746611561760713],
					[59.497299817924372, 1.8746611828928683],
					[59.498499785846015, 1.8773000122282957],
					[59.496599809780278, 1.8830499371632248],
					[59.496599806379486, 1.8830499573818766],
					[59.496599800564852, 1.8830499993830254],
					[59.496599800824093, 1.8830500205999809],
					[59.496899801398321, 1.8859111211695143],
					[59.496899802864263, 1.8859111314954506],
					[59.496899806591379, 1.8859111502064909],
					[59.496899808948378, 1.8859111603439809],
					[59.497999809330231, 1.8894111598049457],
					[59.497999830244986, 1.8894111961561579],
					[59.497999885948154, 1.889411258834981],
					[59.497999919749773, 1.8894112835984982],
					[59.499099832102551, 1.8898890443207188],
					[59.499999806844571, 1.8932611518788778],
					[59.499999809105297, 1.8932611602652603],
					[59.499999815313132, 1.8932611749089061],
					[59.49999981757383, 1.8932611832952917],
					[59.50219981836409, 1.8980889832928536],
					[59.502199835042731, 1.8980890081376014],
					[59.502199876323935, 1.898089054380927],
					[59.502199899844115, 1.8980890724622261],
					[59.505099786636102, 1.8997612080977275],
					[59.50449980108705, 1.9053999793188829],
					[59.504499801056035, 1.905399995282649],
					[59.504499802871749, 1.9054000285870871],
					[59.50449980462291, 1.9054000441748742],
					[59.505199794929467, 1.9085194020766574],
					[59.504902636231805, 1.9099692026663837],
					[59.501499998332434, 1.9100108998773435],
					[59.501499921062717, 1.9100109374401324],
					[59.501499823935617, 1.9100110571946365],
					[59.501499804173697, 1.9100111411390892],
					[59.502299783646848, 1.9138388425554322],
					[59.501299990714486, 1.914980305933264],
					[59.499802978244873, 1.9134609959033178],
					[59.498300201792119, 1.9082193785381445],
					[59.498797399056514, 1.9013389139303887],
					[59.498797400169366, 1.901338901286417],
					[59.498797398035329, 1.9013388786912588],
					[59.49879739727043, 1.9013388646708347],
					[59.497700197335547, 1.895099965502322],
					[59.497700126325348, 1.8950998865485069],
					[59.497699954212905, 1.8950998378125519],
					[59.497699852028191, 1.8950998647136776],
					[59.495799851688616, 1.8971887648883885],
					[59.495799831929396, 1.897188799194182],
					[59.495799806159575, 1.8971888719932439],
					[59.49579980014898, 1.8971889104865161],
					[59.496199799885709, 1.904500011798349],
					[59.496199801159051, 1.9045000186201189],
					[59.496199803005808, 1.9045000359564837],
					[59.49619980526581, 1.9045000443427491],
					[59.496899804976394, 1.9076111447188731],
					[59.496899810387077, 1.9076111613043207],
					[59.496899823786158, 1.9076111921591083],
					[59.496899831870095, 1.9076112081809362],
					[59.498899806231577, 1.9106806672136647],
					[59.49929699373309, 1.9148499404139454],
					[59.496199841110986, 1.9189387797937747],
					[59.496199826215573, 1.9189388041998263],
					[59.496199805812672, 1.918938859898655],
					[59.496199800209816, 1.918938889438971],
					[59.495997002391775, 1.9226693543896047],
					[59.494202629967901, 1.9269109880332125],
					[59.489899854668458, 1.9314192625539368],
					[59.489899845437229, 1.9314192751262766],
					[59.489899826178842, 1.9314193022103712],
					[59.489899817933946, 1.9314193163477236],
					[59.487702617966697, 1.9362693162673221],
					[59.487702614782684, 1.9362693240239579],
					[59.487702610101771, 1.9362693374110191],
					[59.487702607808899, 1.9362693449806048],
					[59.486399808190619, 1.9408110454971741],
					[59.486399804269787, 1.9408110728996111],
					[59.486399803747474, 1.9408111297132502],
					[59.486399808928304, 1.9408111587506212],
					[59.488097009041361, 1.9463806578210154],
					[59.488097017310608, 1.9463806773527939],
					[59.488097038304943, 1.9463807154824466],
					[59.488097050934989, 1.9463807323281415],
					[59.491797004827234, 1.9505111818679004],
					[59.492099797895733, 1.9548110826456393],
					[59.490897006374873, 1.9598193535837991],
					[59.490897003056801, 1.9598193755444406],
					[59.4908970019574, 1.9598194218517726],
					[59.490897004972453, 1.9598194442596295],
					[59.491699768189818, 1.963388778572404],
					[59.490503023919125, 1.9638802914814733],
					[59.490700199174888, 1.9612611153770019],
					[59.490700176288676, 1.9612610421877117],
					[59.490700086772101, 1.9612609368585647],
					[59.490700019952151, 1.9612609012139954],
					[59.489200019578867, 1.9611109005957188],
					[59.489199959072906, 1.9611109150234192],
					[59.489199856889599, 1.9611109753828377],
					[59.489199816103451, 1.9611110211281459],
					[59.488499815881049, 1.9627693223352662],
					[59.488499811804211, 1.9627693302760538],
					[59.488499807404857, 1.9627693489167186],
					[59.488499804313982, 1.9627693584234054],
					[59.488097118347369, 1.9646800199763801],
					[59.487202977057756, 1.9629999058463417],
					[59.487202975085808, 1.9629999027146523],
					[59.487202972127918, 1.962999898017098],
					[59.487202970155998, 1.9629998948854077],
					[59.486100169863072, 1.9612109945451321],
					[59.486100076345522, 1.9612109485407121],
					[59.486099897766678, 1.9612109628522636],
					[59.486099813786183, 1.9612110264860798],
					[59.485100026882684, 1.9637187926279907],
					[59.483897406261207, 1.9597110907956599],
					[59.484700088867243, 1.9556311818425571],
					[59.485999823772588, 1.9580111967950422],
					[59.485999857240138, 1.9580112323194334],
					[59.48599993570339, 1.9580112832367162],
					[59.485999981590275, 1.9580112984431493],
					[59.487099982367994, 1.9581112996964327],
					[59.487100060222986, 1.9581112727743819],
					[59.487100168945602, 1.9581111667414364],
					[59.487100199908113, 1.9581110893827076],
					[59.486800199778571, 1.9524305897597278],
					[59.486800190561034, 1.9524305527058643],
					[59.486800159934326, 1.952430486467106],
					[59.486800139321403, 1.9524304553434892],
					[59.485297398188351, 1.9509805137484693],
					[59.485200200010802, 1.9455610951268494],
					[59.485200199915802, 1.9455610933748304],
					[59.485200199725774, 1.9455610898707911],
					[59.485200200426931, 1.9455610861799753],
					[59.484902999628829, 1.9412499864428356],
					[59.484902950223045, 1.9412499064363016],
					[59.484902806010695, 1.941249823277875],
					[59.484902712986411, 1.9412498197522055],
					[59.483897335086894, 1.9417386126554808],
					[59.483200202131435, 1.9388888824992645],
					[59.483502982085255, 1.9342391892853728],
					[59.485599924985557, 1.9350890850862237],
					[59.485600020017067, 1.9350890757696371],
					[59.485600157106127, 1.935088977885892],
					[59.485600200245109, 1.9350888926356729],
					[59.48550020595485, 1.9322807614099151],
					[59.487300041005284, 1.9319001954993877],
					[59.48730008409958, 1.931900175816168],
					[59.487300155683712, 1.9319001164801248],
					[59.48730018426874, 1.9319000785793923],
					[59.488200184144986, 1.9297806776739113],
					[59.488200178397577, 1.9297805885033801],
					[59.488200096054555, 1.9297804498568081],
					[59.48820002035005, 1.9297804001935543],
					[59.484300298098077, 1.9293804287782639],
					[59.486600182344993, 1.9243389831363471],
					[59.486600187725195, 1.9243389660567876],
					[59.486600196703343, 1.9243389322723878],
					[59.486600199410162, 1.9243389157548929],
					[59.486800192203219, 1.9216612093085397],
					[59.490300114840814, 1.9191890625667385],
					[59.490300151155751, 1.9191890177077342],
					[59.490300191357107, 1.9191889117744338],
					[59.490300193556706, 1.91918885282735],
					[59.489102999820801, 1.9142610776628393],
					[59.489200200322344, 1.9083806021564205],
					[59.48920018183108, 1.9083805440259631],
					[59.489200114424605, 1.9083804483544824],
					[59.489200064618316, 1.9083804110012357],
					[59.487800065051353, 1.9078998112675938],
					[59.487800052194117, 1.9078998068886943],
					[59.487800025779556, 1.9078998018226909],
					[59.487800013017839, 1.9078997991957787],
					[59.485802812944868, 1.9077692003681015],
					[59.485802764730749, 1.9077692087564979],
					[59.485802679441662, 1.9077692479943285],
					[59.485802641380076, 1.9077692772796515],
					[59.483897227312895, 1.9102496364512673],
					[59.481900201727576, 1.9066610527181787],
					[59.482200200138806, 1.8998611083697556],
					[59.482200149148483, 1.899861016358011],
					[59.482199992151315, 1.8998609307609047],
					[59.482199886939902, 1.899860935235876],
					[59.479999886603814, 1.9013609359175476],
					[59.479999860127656, 1.9013609627616763],
					[59.479999817741906, 1.9013610283955853],
					[59.479999802532184, 1.9013610634943054],
					[59.47919701317781, 1.9057610112955787],
					[59.477299848166325, 1.9079804705979364],
					[59.477299830790535, 1.9079804990604436],
					[59.477299807209505, 1.9079805624902322],
					[59.47729980001769, 1.9079805958937659],
					[59.477199799886954, 1.9126193959088582],
					[59.477199800650475, 1.9126194099206957],
					[59.477199804055161, 1.9126194393206355],
					[59.477199806696319, 1.9126194547087483],
					[59.477902539092547, 1.9151498081502369],
					[59.476397353998046, 1.9151997943249714],
					[59.475900194233432, 1.9132499517010182],
					[59.475900166324344, 1.9132499026590166],
					[59.475900091251589, 1.913249831657388],
					[59.475900042910474, 1.9132498046310986],
					[59.473597243244278, 1.9127387045021664],
					[59.473597237802267, 1.9127387038762484],
					[59.473597226918237, 1.9127387026244005],
					[59.473597222271891, 1.9127387000596092],
					[59.471500165153159, 1.9125109171672829],
					[59.471000195274648, 1.9102110582493852],
					[59.471000193015144, 1.910211049868858],
					[59.471000188591546, 1.9102110348589532],
					[59.47100018642751, 1.9102110282295703],
					[59.46930018604489, 1.9058305285623875],
					[59.469300177865456, 1.9058305108025964],
					[59.469300158133329, 1.9058304795359453],
					[59.46930014737616, 1.905830464090329],
					[59.468102955321939, 1.9045304733018],
					[59.466202962339928, 1.9019109834136556],
					[59.466202869982794, 1.901910942663217],
					[59.466202696158192, 1.901910961585966],
					[59.466202615677382, 1.9019110228222174],
					[59.465302615709156, 1.9040499224680676],
					[59.465302610427031, 1.9040499412904519],
					[59.465302601644943, 1.9040499785597902],
					[59.465302599927128, 1.9040499966312796],
					[59.465196999674497, 1.91013889647714],
					[59.465197000151804, 1.9101389052313831],
					[59.465197002092985, 1.9101389243031395],
					[59.465197002665754, 1.9101389348082205],
					[59.466499803412823, 1.9174889344210297],
					[59.466499803698902, 1.9174889396738677],
					[59.466499805862597, 1.9174889463028768],
					[59.466499806053328, 1.9174889498047534],
					[59.467602596350602, 1.9217389102117886],
					[59.466597002568825, 1.9279805673800499],
					[59.466597001133351, 1.9279805907042109],
					[59.466597004309861, 1.9279806325408066],
					[59.466597008221171, 1.9279806547423355],
					[59.467602587662128, 1.93151938662219],
					[59.465302615710286, 1.9370110226666799],
					[59.465302608831202, 1.9370110453598828],
					[59.46530260159637, 1.9370110946911359],
					[59.46530260114551, 1.93701111957822],
					[59.465899800842863, 1.9432000195724559],
					[59.465899832174479, 1.9432000820740878],
					[59.465899927276361, 1.943200173717498],
					[59.465899991747754, 1.9432001991705776],
					[59.468599991688755, 1.9433112994511259],
					[59.468600050315011, 1.9433112836253863],
					[59.468600146952859, 1.9433112208719769],
					[59.468600185855571, 1.9433111737576199],
					[59.470300010178924, 1.9390311170510888],
					[59.472399771267931, 1.9437888767314535],
					[59.470199837165566, 1.9468998843820076],
					[59.470199824842815, 1.9468999064452619],
					[59.47019980672043, 1.9468999545190364],
					[59.470199800920767, 1.9468999805295786],
					[59.469897000644956, 1.9498805801273198],
					[59.469897004610779, 1.9498806200327781],
					[59.469897028583453, 1.9498806964856563],
					[59.469897049386546, 1.9498807310953017],
					[59.471296991652572, 1.9514806668852964],
					[59.470902601273309, 1.9553305803815013],
					[59.470902601235892, 1.9553305963297367],
					[59.470902603133133, 1.9553306313560261],
					[59.470902605958976, 1.9553306502476424],
					[59.472099740085923, 1.9599803915416261],
					[59.469002907849593, 1.9598804033391093],
					[59.467097364649028, 1.9570887865426576],
					[59.467097265973237, 1.9570887451922518],
					[59.467097087357317, 1.9570887754567659],
					[59.467097006620847, 1.9570888490091707],
					[59.465197006753471, 1.9642193483887906],
					[59.46519700301684, 1.9642193792735398],
					[59.465197006332644, 1.9642194405603892],
					[59.465197013290371, 1.9642194692114228],
					[59.465899787732589, 1.9661111028294209],
					[59.464599810002589, 1.9699805361332774],
					[59.464599807589785, 1.9699805915315547],
					[59.464599834051135, 1.9699806975695771],
					[59.464599861844754, 1.9699807448932458],
					[59.466499861549991, 1.9717807445721496],
					[59.466499961949161, 1.9717807678959427],
					[59.466500129347054, 1.9717807134880283],
					[59.466500195549223, 1.9717806376934168],
					[59.467400172836378, 1.9671890584971963],
					[59.469002642841232, 1.9667391524476743],
					[59.469799803275784, 1.971080635243142],
					[59.469799819901361, 1.9710806760501183],
					[59.469799869612295, 1.971080745373524],
					[59.469799902792289, 1.97108077564129],
					[59.471499802246015, 1.9720195190108578],
					[59.471599799630681, 1.9769694041464916],
					[59.471599809030202, 1.9769694446940123],
					[59.471599841710479, 1.9769695158096476],
					[59.471599866962784, 1.9769695495089239],
					[59.473099762158192, 1.9783111557107917],
					[59.470099874768195, 1.9857303765726615],
					[59.467800034330928, 1.9853387029705487],
					[59.467799970864206, 1.9853387126460806],
					[59.467799863927972, 1.985338768564159],
					[59.467799818581547, 1.9853388134266319],
					[59.466399947272578, 1.9882885440901032],
					[59.464700147677732, 1.9864192645000505],
					[59.464700123183327, 1.9864192448089342],
					[59.464700067442429, 1.9864192139155992],
					[59.464700036195879, 1.9864192027133698],
					[59.461600036384155, 1.9858498036803032],
					[59.461599992335657, 1.9858498057683271],
					[59.461599908842551, 1.9858498284626016],
					[59.461599870194696, 1.9858498471323878],
					[59.459597069626845, 1.9875692484578762],
					[59.459597045993924, 1.9875692781634196],
					[59.459597010975187, 1.9875693474191605],
					[59.459597001277409, 1.9875693848475049],
					[59.459399805309772, 1.9902805242370958],
					[59.457802677612555, 1.9923109312837053],
					[59.455897129906866, 1.9930192122609529],
					[59.455897080709271, 1.9930192525855477],
					[59.455897015719941, 1.9930193510728889],
					[59.455897000116941, 1.9930194127369749],
					[59.456099797733096, 1.9963888710062414],
					[59.45479989311567, 2.0000497055533479],
					[59.453097300998571, 1.9990498287985596],
					[59.453097200842763, 1.9990498265915122],
					[59.453097049575902, 1.9990499128875425],
					[59.453097000153136, 1.9990499992697712],
					[59.453096999922415, 2.003210984544419],
					[59.45190013135273, 2.0038996940399141],
					[59.450900193908339, 1.9998388520789767],
					[59.450900114141085, 1.9998387766041095],
					[59.450899933197562, 1.9998387469220418],
					[59.45089983192706, 1.9998387909643871],
					[59.448799832276862, 2.0030692908747754],
					[59.44879981872662, 2.0030693237757391],
					[59.44879980340113, 2.003069390673379],
					[59.448799801720064, 2.0030694264203892],
				],
			],
		},
		{
			fldNpdidField: "29393934",
			wlbNpdidWellbore: "6367",
			fldName: "DVALIN",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6507/7-14 S",
			fldDiscoveryYear: "2010",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=29393934",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=29393934",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "435",
			cmpLongName: "Wintershall Dea Norge AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=6367",
			fldHcType: "GAS",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "35318843",
			fldNpdidOwner: "4238696",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Kristiansund",
			fldAreaGeometryWKT: [
				[
					[65.400274801820458, 7.0860555893586108],
					[65.400274804185969, 7.0860556344103767],
					[65.400274808969129, 7.0860556577505385],
					[65.401024809331716, 7.0885333572313636],
					[65.401024822239094, 7.0885333842882527],
					[65.401024855200205, 7.0885334357281158],
					[65.401024876123373, 7.088533457894175],
					[65.401999876424782, 7.089302957296562],
					[65.401999890907845, 7.0893029670107559],
					[65.401999924330198, 7.0893029839610993],
					[65.401999940579429, 7.0893029913930699],
					[65.403449863080056, 7.0897529667145784],
					[65.404188724872441, 7.0911028966285583],
					[65.404188764870327, 7.091102936796708],
					[65.404188859756616, 7.0911029880500491],
					[65.404188914644976, 7.0911029991352246],
					[65.405272159844031, 7.0910196045350355],
					[65.407210959930282, 7.0919973541663044],
					[65.408560930993801, 7.0941445050341958],
					[65.408560931917648, 7.0941445071213067],
					[65.408560933765287, 7.0941445112955508],
					[65.40856093468912, 7.0941445133826626],
					[65.410033123251409, 7.0962861956094025],
					[65.411269215266927, 7.0992889758125877],
					[65.411269225374113, 7.099288994470272],
					[65.411269250044867, 7.099289029308431],
					[65.411269263711759, 7.0992890455538591],
					[65.413222064259813, 7.1011112464585482],
					[65.413222085100116, 7.1011112621869392],
					[65.413222132976387, 7.1011112867315322],
					[65.413222158219028, 7.101111295677546],
					[65.414191629414802, 7.1013195884616458],
					[65.415519245033494, 7.1020612434762738],
					[65.416633118358106, 7.1045111826772809],
					[65.416633158406455, 7.1045112271905202],
					[65.41663325698299, 7.1045112868685285],
					[65.416633314587457, 7.1045112999451616],
					[65.41735818226033, 7.1044558108040059],
					[65.418463721933179, 7.1066250909057525],
					[65.418463723780505, 7.1066250950824461],
					[65.418463730192173, 7.1066251053946976],
					[65.418463732936203, 7.1066251095066448],
					[65.420058124115641, 7.1090389966725267],
					[65.421416518422177, 7.1120361828586214],
					[65.421416520269347, 7.1120361870361437],
					[65.421416523963643, 7.1120361953912132],
					[65.421416525810798, 7.1120361995687364],
					[65.422652625875457, 7.1141972984138482],
					[65.422652633183347, 7.1141973086651102],
					[65.422652645978758, 7.1141973271431862],
					[65.422652653259803, 7.114197335241033],
					[65.424116552584664, 7.1157779368189171],
					[65.424116577037026, 7.1157779544612128],
					[65.424116628578261, 7.1157779852454199],
					[65.424116658330377, 7.1157779960405119],
					[65.426047157797015, 7.1161945942086957],
					[65.426047165024301, 7.1161945980007228],
					[65.426047180295043, 7.116194599059277],
					[65.426047188392189, 7.1161946006331886],
					[65.428099902781511, 7.1163195934303554],
					[65.429688748315982, 7.1181723309862761],
					[65.429688749212701, 7.1181723309219125],
					[65.429688751956363, 7.1181723350368173],
					[65.429688752853096, 7.1181723349724528],
					[65.4309081369708, 7.1194890173674041],
					[65.432258119917648, 7.1219250862555832],
					[65.433747015772667, 7.125477876071967],
					[65.433747033183778, 7.1254779050143684],
					[65.433747076866226, 7.1254779536405826],
					[65.433747103164293, 7.125477975478713],
					[65.435080486825669, 7.1262223661415627],
					[65.436660972851215, 7.1275140560393684],
					[65.436660990918639, 7.1275140655310034],
					[65.436661027950223, 7.1275140844502074],
					[65.436661046914381, 7.1275140938777763],
					[65.439080534739134, 7.1281779889798536],
					[65.441383202633986, 7.129130775873227],
					[65.443205485059494, 7.1304057647511927],
					[65.443205486852918, 7.1304057646232222],
					[65.443205488672945, 7.1304057666503757],
					[65.443205491389705, 7.1304057686135414],
					[65.44514428206358, 7.1316695627112114],
					[65.446724849633867, 7.1329612357082874],
					[65.448691533053804, 7.1359168112687987],
					[65.448691546716788, 7.1359168275562963],
					[65.44869157939651, 7.1359168575927923],
					[65.448691595723076, 7.1359168715332721],
					[65.450752638399564, 7.1371723370357802],
					[65.451499813695122, 7.1390917734011792],
					[65.451499817387969, 7.1390917817696131],
					[65.451499825643864, 7.1390917962869329],
					[65.451499830233388, 7.1390918045916569],
					[65.45333872987004, 7.1420584059749261],
					[65.453338731689911, 7.1420584080036846],
					[65.453338735356141, 7.1420584142171322],
					[65.45333873717604, 7.1420584162458693],
					[65.454680433402629, 7.1439334099735969],
					[65.455177629216536, 7.1447445053983296],
					[65.455177669147488, 7.1447445414133446],
					[65.455177762169455, 7.1447445887730323],
					[65.455177815234023, 7.1447445979615631],
					[65.456497214909973, 7.144644599575118],
					[65.457466713675657, 7.1445723999121018],
					[65.457588913980302, 7.1445641010241125],
					[65.457588979096798, 7.1445640683532234],
					[65.45758907216819, 7.1445639732718265],
					[65.457589100070265, 7.1445639065486306],
					[65.45768080010582, 7.142016707467584],
					[65.457680795198883, 7.1420166732844299],
					[65.457680774677428, 7.1420166099943554],
					[65.457680759036435, 7.1420165787310905],
					[65.456452966950678, 7.1404137887019825],
					[65.455222375229823, 7.1382499032961837],
					[65.454483476216041, 7.1368943052218405],
					[65.453622390939188, 7.1352638346914929],
					[65.453111296639861, 7.1324832640541249],
					[65.453111293790187, 7.1324832513102923],
					[65.453111287220693, 7.1324832280425126],
					[65.45311128170745, 7.1324832176464517],
					[65.452369581769474, 7.1308443179018193],
					[65.45236957899948, 7.1308443116261797],
					[65.452369574382843, 7.1308443011667606],
					[65.452369570716172, 7.1308442949550965],
					[65.451875171061786, 7.1300359954501769],
					[65.45187516467837, 7.130035987274975],
					[65.451875152834788, 7.1300359730164091],
					[65.451875147348005, 7.1300359647771883],
					[65.451141856805435, 7.129241576044496],
					[65.450408487938006, 7.1281693201528986],
					[65.449897396366552, 7.1256693609742205],
					[65.449897393569657, 7.1256693525440324],
					[65.449897389822695, 7.1256693398666764],
					[65.449897387922519, 7.1256693313723405],
					[65.448905796276918, 7.1229221539124596],
					[65.448508498317238, 7.1198499747828485],
					[65.448508489872111, 7.1198499451844004],
					[65.44850846406834, 7.1198498909422412],
					[65.448508446682965, 7.1198498641429779],
					[65.446800153971878, 7.1180026714150975],
					[65.445816868663513, 7.1166665931106952],
					[65.445083479257093, 7.1153138104922586],
					[65.444091888889815, 7.1131305335738775],
					[65.443458493609427, 7.1106416502530418],
					[65.4434584898612, 7.1106416375809545],
					[65.44345847970159, 7.1106416145858846],
					[65.443458475056659, 7.1106416019784602],
					[65.441489074568253, 7.1071249025155661],
					[65.441489069080276, 7.1071248942843912],
					[65.441489058104295, 7.1071248778220655],
					[65.441489051719628, 7.1071248696556664],
					[65.439902958677294, 7.1052720780994862],
					[65.439172365086421, 7.1041998879590187],
					[65.439172352316774, 7.1041998716290804],
					[65.439172322347943, 7.1041998436031095],
					[65.439172305121758, 7.1041998297522788],
					[65.437958415211355, 7.1034470359443738],
					[65.436011265738458, 7.1019026771924008],
					[65.434394585554273, 7.097797126561499],
					[65.434394562676516, 7.097797091564205],
					[65.434394503606143, 7.0977970333175344],
					[65.434394468337246, 7.0977970121574474],
					[65.432464016823161, 7.0970998300983501],
					[65.430625150649902, 7.0949859689145223],
					[65.430625149753197, 7.0949859689796826],
					[65.430625147932716, 7.0949859669559538],
					[65.430625147008911, 7.0949859648670675],
					[65.42892514657558, 7.0931415638771131],
					[65.42892514296166, 7.0931415619840799],
					[65.428925135679549, 7.0931415538901819],
					[65.428925132038501, 7.0931415498432431],
					[65.426850131867766, 7.0913276491309833],
					[65.426850127329971, 7.0913276451498861],
					[65.426850119178241, 7.0913276392761286],
					[65.426850113743754, 7.0913276353603028],
					[65.42430011686362, 7.0895526389473469],
					[65.422600134141405, 7.0882748501815867],
					[65.420402947317086, 7.0859081628997567],
					[65.420402923757933, 7.08590814522237],
					[65.420402875824777, 7.0859081163926332],
					[65.420402849657378, 7.0859081053711606],
					[65.418708397175081, 7.0854748190144496],
					[65.417252933062173, 7.0841804513742206],
					[65.417252931241507, 7.0841804493522647],
					[65.417252925806849, 7.0841804454393245],
					[65.417252923986197, 7.0841804434173667],
					[65.415305723893951, 7.0826387434086016],
					[65.415305722969947, 7.0826387413213654],
					[65.41530572025259, 7.0826387393651986],
					[65.415305719355928, 7.082638739430732],
					[65.413239019303035, 7.0811109390798315],
					[65.413239018379016, 7.0811109369928129],
					[65.413239015688958, 7.0811109371895551],
					[65.413239013868278, 7.0811109351680965],
					[65.411780713911298, 7.0800942367809405],
					[65.411780670597111, 7.0800942184054358],
					[65.411780581579336, 7.0800942055285967],
					[65.411780535875764, 7.0800942110272427],
					[65.410697184788575, 7.0804608947744212],
					[65.409613978155463, 7.0802637100425754],
					[65.408516813692685, 7.0795026362175832],
					[65.408516759618195, 7.0795026186327155],
					[65.408516651852025, 7.0795026135939985],
					[65.408516597290927, 7.0795026283579858],
					[65.40743879717273, 7.080149828323596],
					[65.407438788260549, 7.0801498332838033],
					[65.40743877138749, 7.0801498474428941],
					[65.407438763399369, 7.0801498544896475],
					[65.406127682320857, 7.0813804367223305],
					[65.404336014446642, 7.0823637192472173],
					[65.402655552551366, 7.0830554026612944],
					[65.401208283600354, 7.0831692007794302],
					[65.401208241592499, 7.0831692146147978],
					[65.40120816676189, 7.0831692588438155],
					[65.401208133911894, 7.083169287085803],
					[65.400610933898264, 7.0840581875226674],
					[65.400610924280159, 7.0840582076066729],
					[65.400610907706749, 7.0840582454268066],
					[65.400610902599325, 7.0840582673351919],
					[65.400274803341389, 7.0860555677123838],
					[65.400274801820458, 7.0860555893586108],
				],
				[
					[65.392355401255486, 7.128330617335747],
					[65.392577601346289, 7.1310000167893222],
					[65.392577604275871, 7.1310000359566201],
					[65.392577614618588, 7.1310000739721806],
					[65.39257762200512, 7.131000090669434],
					[65.394347019662419, 7.1344500862666322],
					[65.395722017666529, 7.1374833838311016],
					[65.395722024076065, 7.137483394142075],
					[65.395722037818473, 7.1374834168516443],
					[65.39572204602149, 7.137483427035388],
					[65.397474826358049, 7.1395918031045733],
					[65.398666513578462, 7.1426389731063686],
					[65.398666517244635, 7.1426389793070939],
					[65.398666522783586, 7.1426389918353914],
					[65.398666525553068, 7.14263899809953],
					[65.400619222425377, 7.1460806904632745],
					[65.401802614650137, 7.1486833759183925],
					[65.402594207919762, 7.1508639575816995],
					[65.403405397939423, 7.1543917140358788],
					[65.403074801433633, 7.1588971840128526],
					[65.403074799823656, 7.1588971992017214],
					[65.403074801957828, 7.1588972270501978],
					[65.403074803934771, 7.1588972419874723],
					[65.404091504137625, 7.1637528404741504],
					[65.404699803945533, 7.1668444385950121],
					[65.404699806766232, 7.1668444491671801],
					[65.404699814227101, 7.1668444723382718],
					[65.404699817944547, 7.1668444828478117],
					[65.406083113961088, 7.1698861746177878],
					[65.407477611360093, 7.1738222662049989],
					[65.407477612282833, 7.1738222682948445],
					[65.407477614128297, 7.1738222724745357],
					[65.407477615076999, 7.1738222767166437],
					[65.409255415142582, 7.1781778762527182],
					[65.409255416039301, 7.1781778761904667],
					[65.409255416987918, 7.1781778804330179],
					[65.4092554179106, 7.1781778825231575],
					[65.411430418282634, 7.1829611831392297],
					[65.41143042381826, 7.1829611956821404],
					[65.411430439321464, 7.1829612161523206],
					[65.411430446650598, 7.1829612285710347],
					[65.415327629024787, 7.1876223075950358],
					[65.416710917596532, 7.190666783421622],
					[65.416710934046989, 7.1906668081446563],
					[65.41671097407027, 7.1906668527898647],
					[65.416710997643122, 7.1906668727120389],
					[65.419419297781246, 7.1922751728208727],
					[65.419419325734651, 7.1922751838245373],
					[65.419419384228689, 7.1922751970334771],
					[65.419419413872617, 7.1922751993005685],
					[65.420760948105894, 7.1921835113990085],
					[65.421569206866394, 7.1952694500447905],
					[65.421569224341297, 7.1952694854806731],
					[65.421569271768476, 7.1952695490283007],
					[65.421569301694987, 7.1952695749866074],
					[65.423697102275611, 7.1964695742353921],
					[65.423697129331956, 7.1964695853069331],
					[65.423697185109731, 7.1964695965586527],
					[65.423697213882505, 7.1964696010460552],
					[65.425230527859014, 7.1963641045628233],
					[65.426597064825287, 7.1976195479199108],
					[65.426597065722049, 7.1976195478582712],
					[65.426597069360326, 7.197619551919396],
					[65.426597070257102, 7.1976195518577573],
					[65.427566569892363, 7.1984501517489443],
					[65.427566603328358, 7.1984501710085187],
					[65.427566675401266, 7.1984501940805075],
					[65.427566713167067, 7.1984502001084776],
					[65.429297142666286, 7.1983335040845304],
					[65.430458184654313, 7.1991529632392224],
					[65.430458262927331, 7.1991529794219264],
					[65.430458406149782, 7.1991529480248229],
					[65.430458471124808, 7.1991529025991952],
					[65.431586171072979, 7.1972780703214925],
					[65.433716554035428, 7.1984807476702866],
					[65.436469227311918, 7.2032362007348611],
					[65.436469233717062, 7.203236211077825],
					[65.436469251036655, 7.2032362336110785],
					[65.436469260132014, 7.2032362437696413],
					[65.438613751191355, 7.2053390350070474],
					[65.439980444149427, 7.2070473244826241],
					[65.439980451425498, 7.2070473326116131],
					[65.439980466874459, 7.2070473488082163],
					[65.439980475047307, 7.2070473568758517],
					[65.441535969274483, 7.2082890509558917],
					[65.442894263565108, 7.209547346662819],
					[65.442894275324804, 7.2095473544870137],
					[65.442894297050671, 7.2095473702579369],
					[65.442894308810367, 7.2095473780821333],
					[65.445222108904048, 7.2107418785208495],
					[65.44522211341328, 7.2107418803700218],
					[65.445222122431673, 7.2107418840683728],
					[65.445222126044129, 7.2107418859787886],
					[65.446183225758304, 7.2111251870815627],
					[65.446183305594261, 7.2111251837873969],
					[65.446183437675927, 7.2111251208383962],
					[65.446183489921665, 7.2111250611835356],
					[65.446922349364883, 7.2088279893731499],
					[65.447691712755585, 7.2087751990532594],
					[65.449233258061582, 7.2086724034614482],
					[65.451552708232015, 7.2098668776404837],
					[65.45155271996623, 7.2098668833114461],
					[65.451552744305786, 7.2098668924361613],
					[65.451552756014465, 7.2098668959512011],
					[65.453094303779764, 7.2102140837284505],
					[65.454460965009346, 7.2114723479018998],
					[65.454460975897632, 7.2114723579477324],
					[65.454461002107053, 7.2114723734208654],
					[65.454461014763382, 7.211472381188063],
					[65.456977715142514, 7.2126557800990883],
					[65.45697771875497, 7.2126557820106019],
					[65.456977726876602, 7.2126557857724221],
					[65.456977731385791, 7.2126557876227482],
					[65.460066618743312, 7.2138001821343289],
					[65.460847108764341, 7.2142001777100759],
					[65.463166526095264, 7.2153973359493175],
					[65.463783104759699, 7.218058345802743],
					[65.463783150358523, 7.2180584074582796],
					[65.463783272383921, 7.2180584811804822],
					[65.463783347913804, 7.2180584933081882],
					[65.466269367611957, 7.2174419148486564],
					[65.46898593058927, 7.2190668318808777],
					[65.470199802981242, 7.2230417384127961],
					[65.470641500708041, 7.2275222204401244],
					[65.470641500708041, 7.2275222204401244],
					[65.470641500733279, 7.2275222225976217],
					[65.470641500758532, 7.2275222247551403],
					[65.471297000871189, 7.2328889235360769],
					[65.471297002790536, 7.2328889342027711],
					[65.471297009344696, 7.2328889575120474],
					[65.471297012160804, 7.2328889681181652],
					[65.472891512216407, 7.2372944690262475],
					[65.472891535984004, 7.2372945062941492],
					[65.472891600406925, 7.2372945667359199],
					[65.472891638371976, 7.237294590091043],
					[65.475211037948071, 7.2380473890197381],
					[65.475211090932007, 7.2380473919303228],
					[65.475211191193054, 7.238047370061258],
					[65.47521123757339, 7.238047345342002],
					[65.476739037583513, 7.2365973458081241],
					[65.476739061419167, 7.2365973118058751],
					[65.4767390919516, 7.2365972363177997],
					[65.476739099570281, 7.2365971969295355],
					[65.476700200263252, 7.2343416959472924],
					[65.476700200212974, 7.2343416916312622],
					[65.476700199215628, 7.2343416830597631],
					[65.476700199190489, 7.2343416809017373],
					[65.476478003366921, 7.2321028237564668],
					[65.477402966240462, 7.229336234293207],
					[65.479119357434243, 7.2283224571409779],
					[65.482055448403884, 7.2317418294787705],
					[65.482055543938216, 7.2317418640628599],
					[65.482055714940913, 7.231741828740252],
					[65.482055790434416, 7.2317417609920014],
					[65.482794578874547, 7.2294361964681739],
					[65.484305596853986, 7.2275336251410591],
					[65.486266540800514, 7.2301140210756616],
					[65.486266638179131, 7.2301140598553397],
					[65.48626681369025, 7.230114026371341],
					[65.48626689184799, 7.2301139562664689],
					[65.487372391650709, 7.2264306580290256],
					[65.487372395010325, 7.2264306383558052],
					[65.487372399039316, 7.2264305991918523],
					[65.487372398811928, 7.2264305797619475],
					[65.487144598947367, 7.2241888794109679],
					[65.487144593288605, 7.224188856028924],
					[65.487144576590623, 7.2241888096302338],
					[65.487144564654628, 7.224188786674488],
					[65.485375165065946, 7.2215942877689425],
					[65.485375126075169, 7.2215942536931879],
					[65.48537503671453, 7.2215942100802479],
					[65.485374987241386, 7.2215942004821034],
					[65.48384173195987, 7.2216969967299001],
					[65.481902873609798, 7.2209220145347564],
					[65.481902872713079, 7.2209220145957511],
					[65.481902869100708, 7.2209220126813234],
					[65.48190286820396, 7.2209220127423199],
					[65.47977519827478, 7.2201581588685766],
					[65.479722399711221, 7.2161027978930967],
					[65.479722398636625, 7.2161027828466002],
					[65.479722393797132, 7.2161027529370605],
					[65.479722390032293, 7.2161027380740341],
					[65.478714089774044, 7.2130110372100269],
					[65.478714056905844, 7.2130109897778922],
					[65.478713969030792, 7.2130109201847965],
					[65.478713914049422, 7.2130109001819589],
					[65.475441851026957, 7.2127776102933954],
					[65.47423629070876, 7.2087999420017717],
					[65.474236208212218, 7.2087998720661943],
					[65.474236026662055, 7.2087998499427215],
					[65.474235929401885, 7.2087998976320655],
					[65.472541582392608, 7.2116165076581096],
					[65.471580750314516, 7.2116803889505237],
					[65.470977992831777, 7.2094666461600712],
					[65.470977990961785, 7.2094666398102767],
					[65.470977985402811, 7.2094666250758594],
					[65.470977981713844, 7.2094666166912758],
					[65.470183482398497, 7.2077166176948078],
					[65.470183473226882, 7.2077166010494578],
					[65.470183452218876, 7.207716570100426],
					[65.47018344040805, 7.2077165579542006],
					[65.468033499139835, 7.205608214529275],
					[65.467980800132651, 7.20155559604577],
					[65.467980799082241, 7.2015555831638807],
					[65.467980795162262, 7.2015555553661068],
					[65.46798079142161, 7.2015555426690838],
					[65.467177991351591, 7.198905542509797],
					[65.467177986739657, 7.1989055320324313],
					[65.467177978412494, 7.1989055110160134],
					[65.467177972878147, 7.1989054984431791],
					[65.46540847312545, 7.1958720994814982],
					[65.465408433180798, 7.19587206121746],
					[65.465408340148798, 7.1958720115008807],
					[65.465408286164688, 7.1958720001101693],
					[65.462713886143163, 7.1960554008688833],
					[65.46271383431224, 7.1960554195506408],
					[65.462713747996688, 7.1960554816218085],
					[65.462713714408807, 7.1960555249494043],
					[65.461599814362543, 7.1988332255054566],
					[65.460852788818002, 7.2006855924998403],
					[65.459861299387143, 7.1984999553323163],
					[65.459822400431975, 7.1953527986521779],
					[65.45982239838149, 7.195352777210072],
					[65.459822388925787, 7.1953527368532946],
					[65.4598223824173, 7.1953527178768297],
					[65.458633481575774, 7.1927305174137555],
					[65.458633479730821, 7.1927305132246122],
					[65.458633476040916, 7.1927305048463097],
					[65.458633474195977, 7.1927305006571691],
					[65.457650173968332, 7.190997100579585],
					[65.457650156646935, 7.1909970780364301],
					[65.457650117623587, 7.1909970418854208],
					[65.457650095870051, 7.1909970239648189],
					[65.454741830744567, 7.189397044173349],
					[65.453377956281003, 7.1876887750509617],
					[65.453377943571837, 7.1876887629835302],
					[65.45337791725683, 7.1876887389107198],
					[65.453377901857479, 7.1876887270294487],
					[65.452019502333428, 7.1868831278658227],
					[65.452019489701712, 7.1868831222671643],
					[65.452019464438237, 7.1868831110698324],
					[65.452019450935552, 7.1868831076891517],
					[65.450669451247208, 7.1865248074719137],
					[65.450669435054337, 7.1865248042778402],
					[65.450669401797683, 7.1865248001076241],
					[65.450669385630633, 7.1865247990693746],
					[65.449136225177824, 7.1866330911425349],
					[65.447358482229092, 7.1827055175718382],
					[65.447358475796719, 7.1827055050741038],
					[65.447358462983644, 7.182705484389694],
					[65.447358455680401, 7.1827054741096967],
					[65.4459918555591, 7.18100267541148],
					[65.445991841004172, 7.1810026591643448],
					[65.445991809281807, 7.1810026333230823],
					[65.445991791191759, 7.1810026216358205],
					[65.445214008429062, 7.1806054309741878],
					[65.443077925563216, 7.1789554451407067],
					[65.441139046410456, 7.1772915612800521],
					[65.439177968046977, 7.1747331907644396],
					[65.438002975879513, 7.1725666042346212],
					[65.438002974060041, 7.1725666022049293],
					[65.43800297129178, 7.1725665959282532],
					[65.438002968575589, 7.172566593961089],
					[65.435844569049237, 7.1691248933345344],
					[65.43584456264179, 7.1691248830001628],
					[65.435844548085583, 7.1691248667658503],
					[65.435844539884684, 7.169124856556758],
					[65.433708439680998, 7.1670276578165035],
					[65.433708434222339, 7.1670276517295708],
					[65.433708420640954, 7.1670276418981871],
					[65.433708415182267, 7.1670276358112348],
					[65.431961214788188, 7.1658054368713096],
					[65.431961212071883, 7.1658054349052929],
					[65.431961204819743, 7.1658054289444788],
					[65.431961202103437, 7.1658054269784612],
					[65.430611250177819, 7.1650026573665695],
					[65.426675176116419, 7.1576471055922566],
					[65.426675159686852, 7.157647083034532],
					[65.426675119706431, 7.1576470427307344],
					[65.42667509797522, 7.1576470270124313],
					[65.424544506860613, 7.1564526298777604],
					[65.422225172336042, 7.1548248775248124],
					[65.420822392098529, 7.1499888442728894],
					[65.420822352854785, 7.1499887910065176],
					[65.420822249654179, 7.1499887185444502],
					[65.420822184826974, 7.1499887015653103],
					[65.417947185314304, 7.1501998012348711],
					[65.415069572935124, 7.1504080864779294],
					[65.41465019820744, 7.1472971739236488],
					[65.414650170593717, 7.147297117697704],
					[65.414650086961373, 7.1472970309530179],
					[65.414650031865861, 7.1472970025237279],
					[65.412727886652846, 7.1469887120070323],
					[65.410780728693226, 7.1453387474450691],
					[65.410780696147313, 7.1453387282019003],
					[65.410780624066575, 7.1453387052899844],
					[65.410780586325188, 7.1453387014944587],
					[65.409058330629932, 7.1454636967870737],
					[65.407127962827573, 7.1447081495918914],
					[65.406333490987834, 7.1420777414977499],
					[65.406333487268739, 7.1420777309910886],
					[65.406333478010623, 7.142077707952617],
					[65.406333473421213, 7.142077699661546],
					[65.404572373419896, 7.139069299881041],
					[65.404572332564342, 7.1390692618545906],
					[65.404572238617106, 7.1390692125153068],
					[65.404572186422129, 7.1390692011389207],
					[65.403611188132103, 7.1391386923258056],
					[65.401855754384471, 7.1370304720696147],
					[65.401855748027899, 7.1370304660598647],
					[65.401855736211488, 7.1370304539767018],
					[65.401855729854915, 7.1370304479669295],
					[65.400886244088426, 7.1362026595317882],
					[65.399527956800796, 7.1345137778835079],
					[65.397583473291718, 7.1319692977545559],
					[65.395416887235868, 7.1272055280617463],
					[65.394614091144845, 7.1245777413574976],
					[65.394614051892219, 7.1245776881818337],
					[65.394613948706279, 7.1245776180417204],
					[65.394613884772909, 7.1245776010772941],
					[65.393661085278595, 7.1246498001522669],
					[65.393661031689604, 7.1246498212028397],
					[65.39366094545619, 7.1246498876438],
					[65.393660911915077, 7.1246499330982198],
					[65.392355411873723, 7.128330532617853],
					[65.392355406759833, 7.1283305545108115],
					[65.392355400989075, 7.1283305958262462],
					[65.392355401255486, 7.128330617335747],
				],
			],
		},
		{
			fldNpdidField: "43520",
			wlbNpdidWellbore: "178",
			fldName: "TOR",
			fldCurrentActivitySatus: "Producing",
			wlbName: "2/5-1",
			fldDiscoveryYear: "1970",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43520",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43520",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "TOR UNIT",
			cmpLongName: "ConocoPhillips Skandinavia AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=178",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "2410696",
			fldNpdidOwner: "45891",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Tananger",
			fldAreaGeometryWKT: [
				[
					[
						[56.625716500438649, 3.3023083032966949],
						[56.625763700307417, 3.3055750023497632],
						[56.62576370038186, 3.3055750039714549],
						[56.625763700456297, 3.3055750055931483],
						[56.62576369956237, 3.3055750057281905],
						[56.625824800349321, 3.3077028057830669],
						[56.625824799753048, 3.3077028124048788],
						[56.625824801167859, 3.3077028236218151],
						[56.625824802285024, 3.3077028283519279],
						[56.62616650216696, 3.3100333287859227],
						[56.626166503358419, 3.3100333351378777],
						[56.626166505815725, 3.3100333494635366],
						[56.626166507975512, 3.3100333573022902],
						[56.626524798434765, 3.3112389238779261],
						[56.626433099896346, 3.3129166882525074],
						[56.626341500334718, 3.3146110886002149],
						[56.626341499812384, 3.3146110968437785],
						[56.626341501375229, 3.3146111113046537],
						[56.626341500852895, 3.3146111195482169],
						[56.626483100033262, 3.3160306042206806],
						[56.626377600440016, 3.3183249871357487],
						[56.626127601298641, 3.3211916832879438],
						[56.626127599881919, 3.3211916916660718],
						[56.626127600772755, 3.3211917111271338],
						[56.626127601143928, 3.3211917192359133],
						[56.626380400876727, 3.3236583200873269],
						[56.626380401993302, 3.3236583248180347],
						[56.626380403332497, 3.3236583344140551],
						[56.626380404449066, 3.3236583391447638],
						[56.626697004197567, 3.3252639391512249],
						[56.62669700523989, 3.3252639422602219],
						[56.626697006430582, 3.3252639486128106],
						[56.626697006653139, 3.3252639534781911],
						[56.627144207436892, 3.3269000532920696],
						[56.627144207585218, 3.3269000565357087],
						[56.627144210712096, 3.3269000658629655],
						[56.627144212722506, 3.3269000704593115],
						[56.627819213045449, 3.3287083693056179],
						[56.627819214087694, 3.3287083724148223],
						[56.627819216172206, 3.3287083786331935],
						[56.627819217214444, 3.3287083817423979],
						[56.628485916928149, 3.3302083813385535],
						[56.628485922884948, 3.3302083935067737],
						[56.628485936438182, 3.3302084143304866],
						[56.628485944108782, 3.3302084246078674],
						[56.629119244405906, 3.3310001244095839],
						[56.629119247310136, 3.3310001288719362],
						[56.6291192540126, 3.3310001376621945],
						[56.629119257736697, 3.3310001403681961],
						[56.629597021114144, 3.3314807041119892],
						[56.62987480082063, 3.3324861310962839],
						[56.629952599733926, 3.3346611075735657],
						[56.629952599882053, 3.3346611108174917],
						[56.629952600998223, 3.3346611155489794],
						[56.62995260114635, 3.3346611187929049],
						[56.630158100673782, 3.3368667194489769],
						[56.630158102011968, 3.3368667290464851],
						[56.630158105582339, 3.3368667481071923],
						[56.630158108708486, 3.3368667574360527],
						[56.630822007656533, 3.3390444567934283],
						[56.631613703994482, 3.3419333433199951],
						[56.632224801828592, 3.3456389286122463],
						[56.632702601768415, 3.3492778258695806],
						[56.632702601842283, 3.3492778274916954],
						[56.632702602957906, 3.34927783222399],
						[56.632702603105656, 3.349277835468234],
						[56.633274803122255, 3.3524889348111366],
						[56.633274803196073, 3.3524889364332862],
						[56.633274804163911, 3.352488937921462],
						[56.633274804237743, 3.3524889395436088],
						[56.633913702438448, 3.35571672688125],
						[56.634224799546047, 3.3597278112702416],
						[56.634372000291499, 3.363288893288733],
						[56.634013700555094, 3.3668166756363118],
						[56.633502601922643, 3.3704805710525116],
						[56.632849801698967, 3.3748221703069494],
						[56.632849801772515, 3.3748221719291411],
						[56.632849801919591, 3.3748221751735255],
						[56.632849801025543, 3.374822175306968],
						[56.632419201374987, 3.3783777765644336],
						[56.632419200701435, 3.3783777815643532],
						[56.632419200395361, 3.3783777946751856],
						[56.632419199721788, 3.3783777996751065],
						[56.632419200205696, 3.384622200688951],
						[56.632419200279116, 3.3846222023111507],
						[56.632419199605309, 3.3846222073109749],
						[56.632419199752142, 3.3846222105553743],
						[56.632641500061119, 3.3886611104931998],
						[56.632641501248621, 3.3886611168489855],
						[56.632641501835529, 3.3886611298267852],
						[56.632641502949674, 3.3886611345603361],
						[56.633247002616699, 3.3920583348205771],
						[56.633247004844748, 3.3920583442880594],
						[56.63324701108894, 3.3920583629569583],
						[56.633247014211044, 3.3920583722914048],
						[56.633963713949584, 3.3939000737151557],
						[56.633963795104904, 3.3939001367701276],
						[56.633963970605961, 3.3939001563932787],
						[56.633964064951698, 3.3939001129614605],
						[56.635700165345803, 3.3913584129072283],
						[56.635700167881332, 3.3913584092633009],
						[56.635700172879041, 3.3913584003531012],
						[56.635700176308639, 3.3913583965761234],
						[56.636722376344082, 3.389486195162188],
						[56.636722377985485, 3.3894861916511485],
						[56.636722382089005, 3.3894861828735721],
						[56.636722383730408, 3.3894861793625481],
						[56.63808898201615, 3.3863086145201673],
						[56.639022078278494, 3.3870251587798892],
						[56.639022083862983, 3.3870251628482606],
						[56.639022094958598, 3.3870251693625244],
						[56.639022099649026, 3.3870251735640933],
						[56.641013800075235, 3.3881695725930627],
						[56.641013816681856, 3.3881695815541373],
						[56.641013849601642, 3.3881695929859612],
						[56.641013867776259, 3.388169596812963],
						[56.642810832775844, 3.3884640589365773],
						[56.642249811264286, 3.3900777339503083],
						[56.642249818654847, 3.3900778177950914],
						[56.642249896634517, 3.3900779499372149],
						[56.642249967150306, 3.3900779966119132],
						[56.643713867383177, 3.3903251975123463],
						[56.643713880046697, 3.3903251988939402],
						[56.643713907088518, 3.3903251997681818],
						[56.643713919678696, 3.3903251995270742],
						[56.646825020226544, 3.3900140995361006],
						[56.646825031849318, 3.390014097805119],
						[56.646825056736219, 3.3900140908311744],
						[56.646825069179677, 3.3900140873442091],
						[56.647850069376297, 3.3896390873205955],
						[56.647850108655163, 3.3896390602306692],
						[56.647850172027645, 3.3896389887069418],
						[56.647850195300535, 3.3896389460291854],
						[56.648319571637408, 3.3876390444384805],
						[56.649661179321456, 3.3870640841210982],
						[56.649661209805949, 3.3870640616053711],
						[56.649661263255503, 3.387064009525063],
						[56.649661285179732, 3.3870639768478199],
						[56.651005785430293, 3.3838389771805448],
						[56.651005787745234, 3.3838389686655894],
						[56.65100579408977, 3.383838949746047],
						[56.651005796331269, 3.3838389396080975],
						[56.651627995953667, 3.3807444384366936],
						[56.651627996700753, 3.3807444350572533],
						[56.651627998121427, 3.3807444266753768],
						[56.651627998795021, 3.3807444216729383],
						[56.652027998921348, 3.3770472239115277],
						[56.652511297911843, 3.3734722276199345],
						[56.652511299332041, 3.3734722192376112],
						[56.652511299711023, 3.373472207742787],
						[56.652511300310756, 3.3734722011170866],
						[56.652522399903731, 3.3708833009791528],
						[56.652522399682894, 3.3708832961100792],
						[56.65252239916763, 3.3708832847488872],
						[56.652522398946786, 3.3708832798797994],
						[56.652247400335298, 3.3681360875877027],
						[56.652183499594805, 3.3652667084689898],
						[56.652441898684494, 3.3627389205592717],
						[56.652441899136193, 3.3627389106874412],
						[56.652441900113338, 3.3627388925667718],
						[56.652441898703309, 3.3627388813395762],
						[56.652200198800706, 3.3600527817036778],
						[56.652200165159257, 3.3600527148446035],
						[56.652200059342135, 3.3600526244800539],
						[56.652199987092708, 3.3600525993516022],
						[56.649852955576122, 3.360202589645287],
						[56.649625194543745, 3.3592443536400536],
						[56.649625194396229, 3.3592443503943166],
						[56.649625192313167, 3.359244344170619],
						[56.649625191197856, 3.3592443394358966],
						[56.649075190818927, 3.3574832394753673],
						[56.649075190818927, 3.3574832394753673],
						[56.649075189777335, 3.357483236363624],
						[56.64907518888333, 3.3574832364975564],
						[56.648583490109104, 3.3560277377353862],
						[56.648177990267953, 3.3548055371243066],
						[56.648177989300137, 3.3548055356355033],
						[56.64817798825851, 3.3548055325238919],
						[56.648177987216869, 3.3548055294122983],
						[56.647730795308775, 3.3536138500957318],
						[56.647564102406449, 3.3524722019243614],
						[56.647830792032742, 3.3508556672557583],
						[56.648705763834897, 3.3492751156721101],
						[56.65053899993093, 3.3471918889568855],
						[56.651316726329583, 3.3470918979183],
						[56.651316744135499, 3.3470918936115805],
						[56.651316779599462, 3.3470918817523301],
						[56.651316797257529, 3.3470918741997817],
						[56.652461197379111, 3.3464529742250151],
						[56.652461225173575, 3.3464529520777213],
						[56.652461269591058, 3.3464528996559304],
						[56.652461287928112, 3.3464528674900449],
						[56.653369588133025, 3.3439472679475815],
						[56.653369592757322, 3.3439472509120369],
						[56.653369598429975, 3.3439472173780644],
						[56.653369600372301, 3.3439472007453492],
						[56.653369600139797, 3.342575018769558],
						[56.65361409399619, 3.3412194532415809],
						[56.654483486805908, 3.3389417711584026],
						[56.654483490011685, 3.338941762505689],
						[56.654483494635258, 3.3389417454690649],
						[56.654483496947059, 3.3389417369507512],
						[56.655425196693578, 3.3338917370051044],
						[56.655425196619483, 3.3338917353820587],
						[56.655425196619483, 3.3338917353820587],
						[56.656036297168605, 3.3305028350758792],
						[56.656036297840146, 3.3305028300720836],
						[56.656036299109097, 3.330502818441412],
						[56.656036299780645, 3.3305028134376018],
						[56.656355800402196, 3.3259417143367971],
						[56.656355800179604, 3.3259417094676067],
						[56.656355799660204, 3.3259416981061292],
						[56.656355800257359, 3.32594169147916],
						[56.656086300388736, 3.3188888918935202],
						[56.656086300240148, 3.3188888886474563],
						[56.656086298974749, 3.3188888806671617],
						[56.656086298826168, 3.318888877421097],
						[56.655280798958415, 3.312049977346069],
						[56.655280798884036, 3.3120499757230903],
						[56.655280797915722, 3.3120499742351504],
						[56.655280797841343, 3.3120499726121739],
						[56.654483498931441, 3.3061388771364513],
						[56.654178009648319, 3.3031139911330833],
						[56.655044521235268, 3.302455758882632],
						[56.655044555054999, 3.302455711275456],
						[56.655044590662186, 3.3024556045659792],
						[56.655044592449613, 3.3024555454636664],
						[56.654483492838963, 3.300508247330749],
						[56.653672397027286, 3.2974499604126271],
						[56.653633505951788, 3.2971556317363864],
						[56.654194581603541, 3.2959278838152755],
						[56.65419458488082, 3.2959278767820548],
						[56.654194589572995, 3.2959278613635519],
						[56.654194591956376, 3.2959278544657593],
						[56.654658483098927, 3.2943111865487262],
						[56.655030755365381, 3.2937723277821656],
						[56.655750142572366, 3.2930390401831238],
						[56.655750170650094, 3.2930389656541488],
						[56.655750163933561, 3.2930388195888822],
						[56.655750129064693, 3.293038746429628],
						[56.655211229471497, 3.2925859476421468],
						[56.655211222990268, 3.2925859437218707],
						[56.655211211740941, 3.2925859339874206],
						[56.655211206153609, 3.292585929931644],
						[56.655189005915901, 3.2925720293954206],
						[56.655188878981228, 3.2925720486364898],
						[56.655188747066482, 3.2925721944685589],
						[56.655188743129578, 3.2925723241699121],
						[56.655586478317844, 3.2930758131599114],
						[56.655169566280406, 3.2926942817409586],
						[56.65458628472166, 3.2913777241875524],
						[56.653419587304313, 3.2883027285806734],
						[56.653419586261045, 3.288302725470587],
						[56.653419583280574, 3.2883027193860279],
						[56.653419581268714, 3.2883027147887174],
						[56.65229458070921, 3.2859027158296672],
						[56.652294579665885, 3.2859027127197575],
						[56.65159191666406, 3.2844499896677926],
						[56.651905788434995, 3.2836028689249126],
						[56.651905793343701, 3.2836028191566728],
						[56.651905777238234, 3.2836027235553815],
						[56.651905757117937, 3.2836026775866367],
						[56.650994556749197, 3.2824415763291594],
						[56.650994552949335, 3.2824415720038882],
						[56.650994546243538, 3.2824415632176378],
						[56.650994540655894, 3.2824415591638219],
						[56.649302941059496, 3.2807498589016282],
						[56.649302932565817, 3.2807498503875618],
						[56.649302913865441, 3.2807498352535682],
						[56.649302904627412, 3.28074983012052],
						[56.647194504660362, 3.2794554301797558],
						[56.647194500935228, 3.2794554274777408],
						[56.647194492665854, 3.2794554238320508],
						[56.647194488940698, 3.2794554211300393],
						[56.64634448917684, 3.2790331211619916],
						[56.646344480013568, 3.2790331176522249],
						[56.64634446258092, 3.2790331104969348],
						[56.646344454311524, 3.2790331068513949],
						[56.644486153661575, 3.2785109067239899],
						[56.644486151873807, 3.2785109069955465],
						[56.64273068207391, 3.2780387143075655],
						[56.641564007761652, 3.2772915326673591],
						[56.641563985635031, 3.277291521325715],
						[56.641563941755912, 3.2772915067539108],
						[56.641563918065998, 3.2772915005507368],
						[56.639380617886175, 3.2770915014418738],
						[56.639380616992277, 3.2770915015776616],
						[56.639380616098393, 3.2770915017134459],
						[56.637725016178102, 3.2769581013936162],
						[56.63772500619573, 3.2769580996429442],
						[56.637724987349344, 3.2769581008721826],
						[56.637724978335669, 3.2769581006078576],
						[56.63617217804142, 3.2771331006600555],
						[56.636172154056183, 3.2771331075699019],
						[56.636172108023075, 3.2771331243621971],
						[56.636172085975247, 3.2771331342446395],
						[56.63489428567182, 3.2780165343455909],
						[56.634894277032174, 3.2780165421910383],
						[56.634894258039935, 3.2780165597753985],
						[56.63489424940029, 3.2780165676208477],
						[56.633860960419774, 3.2791998568957887],
						[56.63281657230511, 3.2800720459991357],
						[56.632816567985223, 3.2800720499213862],
						[56.63281656023932, 3.2800720576302247],
						[56.632816556813324, 3.2800720614167949],
						[56.631344257008969, 3.2815859616941938],
						[56.631344250082101, 3.2815859676448875],
						[56.631344237346525, 3.2815859842762625],
						[56.631344231463061, 3.281585993335066],
						[56.630055431022292, 3.2836109925784123],
						[56.630055425213399, 3.2836110032583985],
						[56.630055414564239, 3.2836110261046043],
						[56.630055409649231, 3.2836110366490097],
						[56.628952610909437, 3.2869499365611827],
						[56.628313711418095, 3.288794334790643],
						[56.628313705834721, 3.2887943699311735],
						[56.628313708225875, 3.288794441423966],
						[56.628313715231812, 3.2887944762899277],
						[56.628844188041192, 3.2900778094938801],
						[56.627013709146979, 3.2959832410916539],
						[56.627013707582826, 3.2959832462273821],
						[56.627013705348475, 3.2959832563635407],
						[56.627013703784336, 3.2959832614992473],
						[56.626622003889658, 3.2979693592383739],
						[56.625716503791196, 3.3023082587004939],
						[56.625716503418651, 3.3023082701873987],
						[56.625716499917282, 3.3023082919449056],
						[56.625716500438649, 3.3023083032966949],
					],
					[
						[56.655589863737831, 3.2930794542961848],
						[56.655627600287247, 3.2931139878179443],
						[56.655627600409751, 3.2931272236198184],
						[56.655589863737831, 3.2930794542961848],
					],
				],
			],
		},
		{
			fldNpdidField: "43568",
			wlbNpdidWellbore: "350",
			fldName: "NORDØST FRIGG",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "25/1-4",
			fldDiscoveryYear: "1974",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43568",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43568",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "903",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=350",
			fldHcType: "GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "28974935",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[59.96710539996046, 2.2214667017861616],
					[59.967105399711095, 2.2214667144182489],
					[59.967280399622403, 2.223988914675298],
					[59.967280400880895, 2.2239889216061912],
					[59.967280403397858, 2.2239889354679701],
					[59.967280404656343, 2.2239889423988655],
					[59.968277604668245, 2.2285361428604182],
					[59.96827760690978, 2.2285361513874098],
					[59.968277612192821, 2.2285361664800356],
					[59.968277615325988, 2.2285361748241961],
					[59.969955409394395, 2.232688962311852],
					[59.971552606327762, 2.2390417485846177],
					[59.971552606419273, 2.2390417503633606],
					[59.972452606223946, 2.2425361500419654],
					[59.972452610339367, 2.2425361599848612],
					[59.972452616786953, 2.2425361802356134],
					[59.972452621885495, 2.2425361917748279],
					[59.974033112459146, 2.2456389727018684],
					[59.97498870667976, 2.2492417510698623],
					[59.974988708920002, 2.2492417596000491],
					[59.974988715092444, 2.2492417745167965],
					[59.974988718132977, 2.2492417810856962],
					[59.97676091759142, 2.253191781589396],
					[59.976760919648754, 2.2531917865621711],
					[59.976760923672096, 2.2531917947286342],
					[59.976760925638096, 2.2531917979223235],
					[59.978394225652153, 2.2560861976963436],
					[59.978394225743436, 2.2560861994755141],
					[59.979927625623887, 2.2587694986235363],
					[59.979927627589745, 2.2587695018178731],
					[59.97992763053859, 2.2587695066093527],
					[59.979927633396144, 2.2587695096215756],
					[59.981558129573074, 2.2612445052900845],
					[59.983341526701409, 2.2642529011201451],
					[59.985183122657361, 2.267472293886275],
					[59.987472018896106, 2.2722806853103514],
					[59.987472019878886, 2.272280686908279],
					[59.987472020861674, 2.2722806885062008],
					[59.989505401693684, 2.2763500524934162],
					[59.989638700393584, 2.2789778092120758],
					[59.989638703086996, 2.278977826647425],
					[59.989638710166226, 2.2789778593750065],
					[59.989638714643043, 2.2789778764470974],
					[59.991310908228989, 2.2830306582350004],
					[59.992299803816074, 2.2878972395728412],
					[59.992697003541736, 2.2899083386191865],
					[59.9926970068529, 2.2899083505355633],
					[59.99269701534957, 2.2899083757856782],
					[59.99269701955248, 2.2899083875206956],
					[59.994166520004299, 2.2929056884944266],
					[59.994166539292124, 2.2929057133511579],
					[59.994166583036602, 2.2929057584165853],
					[59.994166609276732, 2.2929057782626954],
					[59.99550820881062, 2.2935890773914527],
					[59.995508252613106, 2.2935890882725971],
					[59.995508341894428, 2.2935890899085303],
					[59.99550838558978, 2.293589081025881],
					[59.997541745196813, 2.2926196003142612],
					[60.000647148561171, 2.2926390993374302],
					[60.002558157804501, 2.2936584510195823],
					[60.00342761397706, 2.2950583795790269],
					[60.004874805867857, 2.3007833493186443],
					[60.004874805867857, 2.3007833493186443],
					[60.004874806850331, 2.3007833509182469],
					[60.005627606828185, 2.3036500505028674],
					[60.00562761219377, 2.3036500674055396],
					[60.005627626310613, 2.3036500969249789],
					[60.005627634260797, 2.3036501115035977],
					[60.006497033864321, 2.3049445123649832],
					[60.006497084467298, 2.3049445506713604],
					[60.006497197327107, 2.3049445907319526],
					[60.006497260385039, 2.3049445905242698],
					[60.008575059924546, 2.3042918906559993],
					[60.008575073210267, 2.3042918861587434],
					[60.008575096925107, 2.3042918741455378],
					[60.008575109137723, 2.3042918662674361],
					[60.010241751085019, 2.3032030055928239],
					[60.012469334405502, 2.3033973955782963],
					[60.014063793389447, 2.3044057691587505],
					[60.014063797137858, 2.3044057719967985],
					[60.014063806327471, 2.3044057755293603],
					[60.014063810075854, 2.3044057783673941],
					[60.015872110380187, 2.3053195780945743],
					[60.015872123318168, 2.3053195844658494],
					[60.015872150796298, 2.3053195932834316],
					[60.015872166318893, 2.3053195973300085],
					[60.017427754162867, 2.3055918954164203],
					[60.019441602051721, 2.3061973798308766],
					[60.021749859995445, 2.3081807427588008],
					[60.024369248130242, 2.3111223291600682],
					[60.026413744938829, 2.3136223273268919],
					[60.026413751634045, 2.3136223349699629],
					[60.026413766808076, 2.3136223498941559],
					[60.026413774304515, 2.3136223555743007],
					[60.028883168716902, 2.3156140527730109],
					[60.03103875725045, 2.3175918393534043],
					[60.033558151149961, 2.3204251337167285],
					[60.033558151149961, 2.3204251337167285],
					[60.033558153024011, 2.3204251351374356],
					[60.033558154006279, 2.3204251367389706],
					[60.036122054407279, 2.3231557366613611],
					[60.037963754312806, 2.3251223368747613],
					[60.037963804458848, 2.3251223663326877],
					[60.03796391114961, 2.3251223915345745],
					[60.03796396769431, 2.3251223872785416],
					[60.039783368391959, 2.3244612877277064],
					[60.039783387740108, 2.3244612784026053],
					[60.039783425273328, 2.324461254584981],
					[60.039783442566602, 2.3244612402732394],
					[60.040677942595138, 2.323550140816971],
					[60.040677961129234, 2.3235501154461291],
					[60.040677988116393, 2.3235500613449411],
					[60.040677997461259, 2.3235500324337943],
					[60.041175197068171, 2.3205250322856541],
					[60.041175198399316, 2.3205250230099606],
					[60.04117520007938, 2.32052500285667],
					[60.041175199626963, 2.3205249939427466],
					[60.041008500040597, 2.3159971931032999],
					[60.041008499768978, 2.3159971877550292],
					[60.041008499406786, 2.3159971806239912],
					[60.041008499135152, 2.3159971752757222],
					[60.04038909894205, 2.3109277761406788],
					[60.03986129903663, 2.3062805778191309],
					[60.039861296709077, 2.3062805674856812],
					[60.039861291963284, 2.3062805450361306],
					[60.03986128874395, 2.3062805348839288],
					[60.038902995805785, 2.3035110550232401],
					[60.038180799362507, 2.2973860763482463],
					[60.037416898754429, 2.2908388802608508],
					[60.036952998749754, 2.2848305853093436],
					[60.036952999368587, 2.2848305797802402],
					[60.036952997931117, 2.2848305692674988],
					[60.036952996766502, 2.2848305641020508],
					[60.035964096600431, 2.2794332637667623],
					[60.035964096418361, 2.2794332602020635],
					[60.035964094270824, 2.2794332534365531],
					[60.035964093106003, 2.2794332482714443],
					[60.035119592965799, 2.2762471484801274],
					[60.035119588761475, 2.2762471367321968],
					[60.035119578387231, 2.2762471100358117],
					[60.035119572399466, 2.276247098651957],
					[60.034041871783437, 2.2744165990781893],
					[60.034041848742838, 2.2744165713678721],
					[60.034041794200078, 2.2744165266791483],
					[60.03404176269796, 2.2744165097007558],
					[60.032602863259129, 2.273941509772889],
					[60.032602858709524, 2.2739415089011157],
					[60.032602851393705, 2.2739415067934527],
					[60.032602846844107, 2.2739415059216967],
					[60.031047246868937, 2.2735637068580616],
					[60.031047235003527, 2.2735637038788648],
					[60.03104721145489, 2.2735637014846133],
					[60.031047198788848, 2.2735637004695763],
					[60.028561099378535, 2.2735719994171242],
					[60.028561092153815, 2.2735719990916667],
					[60.028561076903806, 2.2735720004047462],
					[60.028561069770177, 2.2735720018612238],
					[60.024719383886541, 2.2741581009162171],
					[60.022330599243276, 2.2741691991377015],
					[60.022330593893052, 2.274169200229688],
					[60.022330583101528, 2.2741692006320458],
					[60.022330577751305, 2.274169201724034],
					[60.020258277977945, 2.2744026010146121],
					[60.020258272627729, 2.2744026021064654],
					[60.020258262818984, 2.2744026041082051],
					[60.020258258269372, 2.2744026032365992],
					[60.019011155071368, 2.2746691840081605],
					[60.016955730355718, 2.2729081479502948],
					[60.016955726697816, 2.272908146896957],
					[60.016955721874886, 2.2729081406816927],
					[60.016955719108694, 2.2729081394463613],
					[60.015152930771322, 2.2715776482855814],
					[60.013408442231302, 2.2698304595208887],
					[60.013408440356748, 2.2698304581038893],
					[60.013408438391075, 2.2698304549057675],
					[60.013408437408238, 2.2698304533067177],
					[60.012127947292562, 2.2686248631381072],
					[60.010130766268645, 2.2661304873395838],
					[60.008389081014272, 2.2630193131807559],
					[60.007230787012844, 2.2601388287307058],
					[60.006319587214406, 2.2576888297854603],
					[60.006319587214406, 2.2576888297854603],
					[60.00631958614013, 2.2576888264064325],
					[60.00631958614013, 2.2576888264064325],
					[60.005300185763346, 2.2551305261149066],
					[60.005300181740019, 2.2551305179413732],
					[60.005300173693335, 2.2551305015943313],
					[60.005300169669979, 2.2551304934207979],
					[60.003414070145602, 2.2521165936296574],
					[60.003414068179538, 2.2521165904335652],
					[60.003414065230423, 2.2521165856394099],
					[60.003414063355727, 2.2521165842238164],
					[60.001877962964265, 2.2499581847583667],
					[60.001877960015086, 2.2499581799646395],
					[60.001877952333409, 2.249958170742151],
					[60.001877949384237, 2.2499581659484247],
					[59.998850161201773, 2.246588778863531],
					[59.996911269864484, 2.2434692948609034],
					[59.99691126307404, 2.2434692854586298],
					[59.996911247709889, 2.2434692670193823],
					[59.996911240919431, 2.2434692576171251],
					[59.99470574191799, 2.2412887601409945],
					[59.992086243879783, 2.238563761198674],
					[59.992086242004959, 2.2385637597843004],
					[59.992086237272112, 2.2385637553584328],
					[59.992086235305756, 2.2385637521642199],
					[59.987764052294906, 2.2346192687264823],
					[59.985616865388394, 2.2314970862246128],
					[59.985616860655334, 2.2314970818005815],
					[59.985616854755776, 2.2314970722210212],
					[59.985616851006007, 2.23149706939359],
					[59.983355759651779, 2.2288915783666239],
					[59.981516867066873, 2.2260942904538989],
					[59.981516866975163, 2.2260942886746728],
					[59.981516865100225, 2.2260942872614282],
					[59.981516865100225, 2.2260942872614282],
					[59.980186264740141, 2.2241581864726894],
					[59.980186258040177, 2.2241581788581719],
					[59.980186244548555, 2.2241581618499784],
					[59.980186236956982, 2.2241581544184932],
					[59.978444546357451, 2.2225165631237518],
					[59.977105759293664, 2.220894277736758],
					[59.97526686501331, 2.2183081858473468],
					[59.97317516584198, 2.2151887895445768],
					[59.973175163875112, 2.2151887863535253],
					[59.973175159941349, 2.2151887799714594],
					[59.973175158066311, 2.2151887785591491],
					[59.971836257599492, 2.2134581784927896],
					[59.971836242231561, 2.2134581600809824],
					[59.971836206605438, 2.2134581332502492],
					[59.971836187946714, 2.2134581209074646],
					[59.970908387761177, 2.21300542012701],
					[59.97090835662005, 2.2130054103505064],
					[59.970908294980902, 2.2130054032478044],
					[59.97090826340753, 2.2130054025476316],
					[59.969508285333106, 2.2132691991383724],
					[59.969194407126167, 2.2132580995017594],
					[59.969194346773094, 2.2132581172980483],
					[59.969194247611284, 2.2132581862081517],
					[59.969194211477323, 2.2132582367722331],
					[59.967860910866776, 2.2171943368143969],
					[59.967860907575776, 2.2171943428823053],
					[59.967860904743837, 2.2171943578425499],
					[59.967860903327868, 2.217194365322658],
					[59.967105402583506, 2.2214666653023061],
					[59.967105402242382, 2.2214666761559561],
					[59.96710539996046, 2.2214667017861616],
				],
			],
		},
		{
			fldNpdidField: "21613906",
			wlbNpdidWellbore: "6185",
			fldName: "JETTE",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "25/8-17",
			fldDiscoveryYear: "2009",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=21613906",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=21613906",
			fldOwnerKind: "",
			fldOwnerName: "",
			cmpLongName: "",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=6185",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "",
			fldNpdidOwner: "",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2020-05-15 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[59.367199799591383, 2.3331472034139114],
					[59.367313700327401, 2.3388444033737037],
					[59.367313700417029, 2.3388444051210975],
					[59.367313699525113, 2.3388444052966895],
					[59.367521999622944, 2.3457972068224171],
					[59.367522000873002, 2.345797213636668],
					[59.367522001678807, 2.3457972293634093],
					[59.367522002928879, 2.3457972361776434],
					[59.368552603021499, 2.3512861370057649],
					[59.368552604181815, 2.3512861420729605],
					[59.368552607304927, 2.3512861502845728],
					[59.36855260846523, 2.3512861553517523],
					[59.369399808250392, 2.3542306558193058],
					[59.369399814585684, 2.3542306739910392],
					[59.369399834481179, 2.3542307106804219],
					[59.369399845186742, 2.3542307262285984],
					[59.37091374485771, 2.3560807266238002],
					[59.370913779558144, 2.3560807533475638],
					[59.370913857965775, 2.3560807891396753],
					[59.370913900870427, 2.3560808001307874],
					[59.37627496039638, 2.3560474003217515],
					[59.377410976061938, 2.3565279658128473],
					[59.378477645325006, 2.357836225279986],
					[59.378477674584175, 2.3578362513140285],
					[59.378477742198776, 2.3578362874710743],
					[59.378477777967802, 2.3578362998676026],
					[59.38014707407558, 2.3580168875736764],
					[59.380705424236972, 2.3590500947014665],
					[59.380705427180906, 2.3590500994204264],
					[59.380705433068798, 2.359050108858332],
					[59.380705435120802, 2.3590501137524296],
					[59.382305416877109, 2.361363986813406],
					[59.383519207250444, 2.3656194536933151],
					[59.383941501960322, 2.3672500340437179],
					[59.384235900612452, 2.3704917162603785],
					[59.384480401304224, 2.3738889134943646],
					[59.384480400768936, 2.3738889206627105],
					[59.384480403177086, 2.3738889325518313],
					[59.384480404336585, 2.3738889376221897],
					[59.385544201214124, 2.379458324353684],
					[59.385719199630863, 2.3827194113984937],
					[59.385719200968147, 2.3827194199663357],
					[59.385719203642658, 2.3827194371020259],
					[59.385719204979942, 2.3827194456698675],
					[59.386024800195912, 2.3840194236847871],
					[59.386024799939982, 2.3858527928621505],
					[59.385924801196772, 2.3870471823587818],
					[59.385924799857783, 2.3870471914502343],
					[59.385924799766769, 2.3870472073612516],
					[59.385924801014752, 2.3870472141808299],
					[59.38600260072122, 2.3880611147567148],
					[59.386002600810215, 2.3880611165052286],
					[59.386002600810215, 2.3880611165052286],
					[59.386002600899197, 2.3880611182537401],
					[59.386244201074646, 2.3907639173554083],
					[59.386244202322438, 2.3907639241752046],
					[59.386244202945093, 2.39076393641496],
					[59.386244205084907, 2.3907639430603878],
					[59.386799804570501, 2.3933167425292932],
					[59.386799825614439, 2.3933167843319807],
					[59.386799885186775, 2.3933168574571209],
					[59.386799924429369, 2.3933168851081121],
					[59.388644323798104, 2.3940751857191276],
					[59.388644377226726, 2.3940751911740317],
					[59.388644479182346, 2.3940751765508814],
					[59.388644527620436, 2.394075154724177],
					[59.390938979198857, 2.3921612953591818],
					[59.393036077381382, 2.3920113011104478],
					[59.395283220608277, 2.3927029854888864],
					[59.397202699587417, 2.393769573334239],
					[59.398844239302676, 2.3947473363986997],
					[59.399835910401272, 2.3972972627671751],
					[59.400455406937695, 2.3995778501597425],
					[59.401549806144203, 2.4037722495837581],
					[59.401549807213812, 2.403772252908372],
					[59.40154980828342, 2.403772256232998],
					[59.401549809353014, 2.4037722595576132],
					[59.403399808683659, 2.4097889596326283],
					[59.403399812784045, 2.409788969433639],
					[59.403399820984838, 2.4097889890356563],
					[59.40339982597726, 2.4097889986626875],
					[59.405894225706717, 2.4141444981938367],
					[59.40589424969216, 2.414144527089793],
					[59.405894304178851, 2.4141445712431757],
					[59.405894335749409, 2.4141445898260181],
					[59.410183235560815, 2.4156057888216314],
					[59.410183278195944, 2.41560579464841],
					[59.410183360618532, 2.4156057856514197],
					[59.410183402278669, 2.4156057722297484],
					[59.410550101873476, 2.4153890723769362],
					[59.410550115791608, 2.4153890625953034],
					[59.410550144253996, 2.4153890376085352],
					[59.410550156210746, 2.415389024674921],
					[59.410839041753043, 2.4150279411842863],
					[59.411286219659452, 2.4146695594822476],
					[59.41163898927298, 2.4144223812525234],
					[59.412086147400068, 2.4142834945807055],
					[59.412502833716474, 2.4142112964781863],
					[59.412502855036784, 2.414211290553955],
					[59.412502894735312, 2.4142112739773753],
					[59.412502914986284, 2.4142112647271441],
					[59.412891815359515, 2.4139390641407457],
					[59.412891829188851, 2.4139390526077937],
					[59.412891853994061, 2.4139390265637162],
					[59.412891865861994, 2.4139390118786577],
					[59.413158465723747, 2.4135418113646292],
					[59.413461265088969, 2.4130973137039557],
					[59.413461271693237, 2.4130973018121393],
					[59.413461283117677, 2.4130972783764357],
					[59.413461288026539, 2.4130972685825602],
					[59.413700185161794, 2.4124361766269549],
					[59.414033481296229, 2.4117278852855453],
					[59.414333475973073, 2.4110861961990482],
					[59.414708466019512, 2.4104890102604264],
					[59.415089062814928, 2.4099557161567295],
					[59.415089067900979, 2.4099557098621176],
					[59.415089076111563, 2.4099556941208067],
					[59.415089080216845, 2.4099556862501532],
					[59.415427980424418, 2.4092472859116074],
					[59.415427983637628, 2.4092472782148655],
					[59.415427989886616, 2.4092472593211856],
					[59.415427993099819, 2.4092472516244432],
					[59.415650193241319, 2.4084111509863191],
					[59.415650193955877, 2.4084111473119747],
					[59.41565019609952, 2.4084111362889815],
					[59.415650196814077, 2.4084111326146553],
					[59.415836296856092, 2.4072889323866962],
					[59.415836298462644, 2.4072889285381782],
					[59.415836298822164, 2.4072889178633177],
					[59.415836300339933, 2.4072889122647041],
					[59.415916900117104, 2.4060583131478048],
					[59.415916900028314, 2.4060583113976901],
					[59.415916900028314, 2.4060583113976901],
					[59.415916899939553, 2.4060583096475709],
					[59.415969599830824, 2.405027810507955],
					[59.415969599742041, 2.405027808757835],
					[59.416019600213033, 2.4037333070843414],
					[59.416019600124237, 2.403733305334212],
					[59.41601959994663, 2.4037333018339795],
					[59.416019599857833, 2.4037333000838652],
					[59.416016900333318, 2.4024389004855382],
					[59.416016900244493, 2.4024388987354302],
					[59.416016900244493, 2.4024388987354302],
					[59.416016900155675, 2.4024388969853185],
					[59.415997400362897, 2.4011027966115663],
					[59.415980800142755, 2.3999193988110084],
					[59.415975200138448, 2.3988221993309145],
					[59.415969599702635, 2.3974833028156368],
					[59.416008500325944, 2.3962111088840166],
					[59.416069598829473, 2.3952222245984607],
					[59.416222397142164, 2.3943861368057497],
					[59.416222396875391, 2.3943861315554549],
					[59.416222398303688, 2.3943861242061368],
					[59.416222399017826, 2.3943861205314629],
					[59.416297398930261, 2.3936611213504375],
					[59.416297398841323, 2.3936611196003428],
					[59.416297398752384, 2.3936611178502498],
					[59.416297398663453, 2.3936611161001564],
					[59.416364098949238, 2.3928472162004901],
					[59.416364099663333, 2.3928472125257727],
					[59.416364100110599, 2.3928472036007662],
					[59.4163640999327, 2.3928472001005785],
					[59.416344600249687, 2.3840221996238897],
					[59.416344598734241, 2.3840221875481844],
					[59.416344595703329, 2.3840221633967649],
					[59.416344594187883, 2.3840221513210591],
					[59.416266893944304, 2.3837110521519196],
					[59.415394594077611, 2.3802027513124187],
					[59.415394586853481, 2.3802027332862807],
					[59.415394570621238, 2.380202697583683],
					[59.415394559829188, 2.3802026802569105],
					[59.414089067966472, 2.3784470913504276],
					[59.412666876386474, 2.375916604067938],
					[59.411461289975641, 2.3735943326358657],
					[59.410794601275597, 2.3703055845668346],
					[59.410941897686854, 2.367427843318711],
					[59.411841881262475, 2.3652444849292533],
					[59.412761276913947, 2.3635139937348484],
					[59.412761284850724, 2.3635139727343502],
					[59.412761296264527, 2.3635139316096629],
					[59.41276129965221, 2.3635139097356528],
					[59.412889099603753, 2.360944409729909],
					[59.412889100316953, 2.3609444060549736],
					[59.41288910004883, 2.3609444008055522],
					[59.412889099780706, 2.3609443955561487],
					[59.412828000290297, 2.3575527961241192],
					[59.412827998861836, 2.3575527858008045],
					[59.412827995826127, 2.3575527616545933],
					[59.412827993416307, 2.3575527497568949],
					[59.412014093289685, 2.3544527484387263],
					[59.412014078121487, 2.3544527160764117],
					[59.412014036368937, 2.3544526571327058],
					[59.412014009874021, 2.3544526323010158],
					[59.410655711731941, 2.3535609346931174],
					[59.409247313635511, 2.3525804356325541],
					[59.409247267786263, 2.3525804199145717],
					[59.409247174396285, 2.3525804082506205],
					[59.40924712605311, 2.3525804142297626],
					[59.407569406643212, 2.3532525818908314],
					[59.40676956135443, 2.3528720556435982],
					[59.405883488765788, 2.3503666328655761],
					[59.405883472883822, 2.3503666041852052],
					[59.405883434342513, 2.3503665552265969],
					[59.405883409809803, 2.3503665335500448],
					[59.404605709793778, 2.3495248327531306],
					[59.404605694985861, 2.3495248250662546],
					[59.404605663675717, 2.3495248117930072],
					[59.404605648065377, 2.3495248060310732],
					[59.402655659147854, 2.3490387081600903],
					[59.402305717943442, 2.3489109302649496],
					[59.401875175837844, 2.3484109978061127],
					[59.401791887750356, 2.3481805328313015],
					[59.40179188364548, 2.3481805230385286],
					[59.401791875614755, 2.3481805069513575],
					[59.401791871599386, 2.348180498907773],
					[59.401480780371095, 2.3476582103409362],
					[59.401458498164779, 2.3476027558029062],
					[59.401419601371565, 2.346936118078613],
					[59.401569592958687, 2.346311152225911],
					[59.401644591420997, 2.3460639580818237],
					[59.40164459284658, 2.3460639507338619],
					[59.401644596589691, 2.3460639358622624],
					[59.401644598015274, 2.3460639285142988],
					[59.401752997677853, 2.3453111287996653],
					[59.401752998834688, 2.3453111162041185],
					[59.401753000345998, 2.3453110929378425],
					[59.401752998827064, 2.3453110808692892],
					[59.401675198585565, 2.3444777803751196],
					[59.40167519608508, 2.3444777667331316],
					[59.401675188139571, 2.3444777347287444],
					[59.401675182873753, 2.3444777198646434],
					[59.401297382611041, 2.3436221199280638],
					[59.401297377613922, 2.3436221103117107],
					[59.401297366727718, 2.343622091254717],
					[59.401297360838669, 2.3436220818140723],
					[59.400955789212958, 2.3431555182228858],
					[59.400836298683835, 2.342483277193133],
					[59.400775200073625, 2.3411027903868691],
					[59.400775200073625, 2.3411027903868691],
					[59.400775199983983, 2.3411027886377638],
					[59.400775199894355, 2.3411027868886571],
					[59.400680800298836, 2.3396110871469027],
					[59.400680799137987, 2.3396110820754017],
					[59.400680797708198, 2.3396110717566145],
					[59.400680796636991, 2.3396110684342273],
					[59.400605796598093, 2.3391555685796828],
					[59.40060579740036, 2.3391555666547696],
					[59.40060579722104, 2.3391555631565919],
					[59.400605796239482, 2.3391555615832988],
					[59.400516896089229, 2.3386971625013153],
					[59.400516894928359, 2.338697157429888],
					[59.400516893677796, 2.3386971506093621],
					[59.400516893408827, 2.3386971453620902],
					[59.40024459294024, 2.337727746821832],
					[59.400244591869004, 2.3377277434995487],
					[59.400244589726498, 2.3377277368549683],
					[59.400244587763353, 2.3377277337085332],
					[59.39960578760541, 2.3359305324461301],
					[59.39960578537314, 2.3359305240527606],
					[59.399605776269681, 2.3359305046474459],
					[59.399605771361678, 2.3359304967817622],
					[59.398900183057009, 2.3347721159979122],
					[59.398369597116542, 2.333108259602338],
					[59.398091898553098, 2.3302582837472801],
					[59.398083499621357, 2.3301332867471234],
					[59.39808349854993, 2.3301332834252859],
					[59.398083499262277, 2.3301332797513932],
					[59.398083499082738, 2.3301332762535312],
					[59.397530798912982, 2.3255471759072845],
					[59.397530797841441, 2.3255471725856682],
					[59.39753079659021, 2.325547165766273],
					[59.39753079641055, 2.3255471622685056],
					[59.397430796354087, 2.3250277619805342],
					[59.397430793049551, 2.325027750266877],
					[59.397430785638477, 2.3250277287645877],
					[59.397430782333942, 2.3250277170509293],
					[59.396900181738125, 2.3238693175939464],
					[59.396900180756397, 2.3238693160212951],
					[59.396900180666535, 2.3238693142724496],
					[59.396900179684813, 2.3238693126997934],
					[59.3961251799889, 2.32226381310443],
					[59.396125176953774, 2.3222638066379013],
					[59.39612516909974, 2.3222637940573061],
					[59.396125165172748, 2.3222637877670231],
					[59.395397394949775, 2.3211916318385146],
					[59.395216899249597, 2.3189527839261226],
					[59.395216897728297, 2.3189527718612242],
					[59.395216892991868, 2.3189527498327807],
					[59.395216889776734, 2.3189527398692502],
					[59.394627990005652, 2.3171249390681634],
					[59.394627988754031, 2.3171249322496807],
					[59.394627984646903, 2.3171249224628556],
					[59.394627981701468, 2.3171249177458035],
					[59.393791881591639, 2.3152638177076983],
					[59.393791878466182, 2.3152638094936004],
					[59.393791867666053, 2.3152637921987349],
					[59.393791862756906, 2.315263784337426],
					[59.392864063399927, 2.3139665839195267],
					[59.392864022980241, 2.3139665512878662],
					[59.392863931626451, 2.3139665093013226],
					[59.392863880782336, 2.3139665016950404],
					[59.391761086954681, 2.3140692003596643],
					[59.390238892624588, 2.3141247996356995],
					[59.390238861409387, 2.3141248058097683],
					[59.390238802194482, 2.3141248281195201],
					[59.390238774194735, 2.3141248442552071],
					[59.389227744956088, 2.3149441867942668],
					[59.388455707390911, 2.3148081165755485],
					[59.387836254387942, 2.3140554721292119],
					[59.38783623181353, 2.3140554536339804],
					[59.387836182295352, 2.3140554192738314],
					[59.387836154729648, 2.314055408830316],
					[59.385911154575503, 2.3135054078721971],
					[59.385911130045294, 2.3135054038934433],
					[59.38591107866926, 2.3135054034580769],
					[59.385911054408993, 2.3135054047240509],
					[59.385005554286323, 2.3137192054026512],
					[59.385005548043274, 2.3137192066372845],
					[59.385005534755322, 2.3137192110311333],
					[59.385005528512274, 2.3137192122657666],
					[59.384311051753116, 2.3139831033708385],
					[59.383319465120678, 2.314105390672919],
					[59.382561231857352, 2.3134387505782188],
					[59.38256120294988, 2.3134387315725595],
					[59.382561142197353, 2.3134387065031876],
					[59.382561109460411, 2.3134387006158654],
					[59.380355608689882, 2.3133441992229042],
					[59.380355602446862, 2.3133442004573737],
					[59.380355588888932, 2.3133441996067465],
					[59.380355581754046, 2.3133442010175695],
					[59.377413881661163, 2.3136109017659359],
					[59.377413869085096, 2.3136109024866869],
					[59.377413843311039, 2.3136109093479327],
					[59.377413830023066, 2.3136109137406224],
					[59.376274930246467, 2.3140359133916188],
					[59.376274907417866, 2.3140359249665954],
					[59.376274865687989, 2.3140359544023172],
					[59.376274846786714, 2.3140359722630626],
					[59.375113746946724, 2.3154248726085194],
					[59.375113735982083, 2.3154248871337946],
					[59.375113717800183, 2.3154249189747049],
					[59.375113710582909, 2.3154249362903374],
					[59.374083111338493, 2.3184721353381295],
					[59.374083108220518, 2.3184721447811043],
					[59.374083103678458, 2.3184721615670467],
					[59.374083102254332, 2.3184721689100045],
					[59.373674801703139, 2.3210943686203676],
					[59.373674801972733, 2.321094373863291],
					[59.373674801350475, 2.3210943792823278],
					[59.373674800638341, 2.3210943829537123],
					[59.373535905164339, 2.3227666261715454],
					[59.372516558947964, 2.324160953762894],
					[59.370699888307662, 2.3253887333765784],
					[59.370699871098608, 2.3253887491285172],
					[59.370699840337878, 2.3253887816759797],
					[59.370699826876013, 2.3253888002190437],
					[59.369519226995095, 2.3274305000169422],
					[59.369519223696884, 2.3274305059629343],
					[59.369519217992348, 2.3274305176789989],
					[59.369519214783928, 2.3274305253724243],
					[59.367199815355946, 2.3331471244114339],
					[59.367199809117771, 2.333147143290883],
					[59.367199802172237, 2.3331471834900652],
					[59.367199799591383, 2.3331472034139114],
				],
			],
		},
		{
			fldNpdidField: "43451",
			wlbNpdidWellbore: "438",
			fldName: "VARG",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "15/12-4",
			fldDiscoveryYear: "1984",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43451",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43451",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "1034",
			cmpLongName: "Chrysaor Norge AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=438",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32035996",
			fldNpdidOwner: "36464054",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Tananger",
			fldAreaGeometryWKT: [
				[
					[58.054049809680926, 1.8712333364160429],
					[58.054049852597586, 1.8712334264293744],
					[58.054049887234981, 1.8712334653975367],
					[58.054808153302119, 1.8717529420215644],
					[58.055574831082339, 1.8729584069088392],
					[58.055574834039675, 1.8729584114173907],
					[58.055574838874001, 1.8729584172503984],
					[58.055574840845551, 1.8729584202560863],
					[58.056491540835758, 1.8741584210795035],
					[58.05649155336706, 1.8741584355736147],
					[58.056491579928455, 1.8741584591612872],
					[58.056491594944298, 1.8741584697577631],
					[58.057327681391186, 1.8746751614734749],
					[58.05854426900688, 1.8757279514662228],
					[58.058544272855329, 1.8757279557970195],
					[58.058544282145654, 1.8757279607392094],
					[58.058544286885329, 1.8757279648916876],
					[58.05930267086373, 1.8762501546400701],
					[58.060219257824222, 1.8771751408760931],
					[58.060219267303502, 1.8771751491817816],
					[58.060219286167587, 1.8771751641116956],
					[58.060219295552358, 1.8771751707359252],
					[58.061283196040598, 1.877825170682935],
					[58.061283197026313, 1.8778251721861439],
					[58.061283199794516, 1.8778251733327727],
					[58.061283200780231, 1.8778251748359824],
					[58.062194300816927, 1.8783418744016338],
					[58.062194303585116, 1.878341875548305],
					[58.062194310107174, 1.8783418793449411],
					[58.062194312875349, 1.8783418804916185],
					[58.063255513113297, 1.8788529798732705],
					[58.063255530613539, 1.8788529865754713],
					[58.063255568098754, 1.8788529960818001],
					[58.063255587286989, 1.8788530007458211],
					[58.064613887249173, 1.8789445999015695],
					[58.064613891799809, 1.8789446006917718],
					[58.064613901697811, 1.8789446004121995],
					[58.06461390704515, 1.8789445993424398],
					[58.065974954391521, 1.8789002024864547],
					[58.066958206983358, 1.8794140772913248],
					[58.066958209751533, 1.879414078438209],
					[58.066958215287862, 1.8794140807320019],
					[58.066958218056037, 1.8794140818788836],
					[58.06809708024123, 1.8799251664845817],
					[58.068705401095087, 1.8805889783983039],
					[58.068716500415142, 1.881544402508148],
					[58.068716501265293, 1.8815444176450478],
					[58.068716506436004, 1.8815444455239232],
					[58.068716508974155, 1.8815444586224104],
					[58.069258108736619, 1.8833028586892331],
					[58.069258109911168, 1.8833028635567293],
					[58.069258112962622, 1.8833028697497145],
					[58.069258114137178, 1.88330287461723],
					[58.0699581016083, 1.885055644630937],
					[58.070044200055129, 1.8864194125688658],
					[58.070044201323967, 1.8864194191185535],
					[58.070044202970372, 1.8864194323960541],
					[58.0700442041448, 1.886419437263789],
					[58.070202604195025, 1.8872333373270791],
					[58.07020262860518, 1.8872333868749989],
					[58.070202700545231, 1.8872334643417528],
					[58.070202748075083, 1.8872334922605878],
					[58.070655548356676, 1.8873557933740264],
					[58.070655605071302, 1.8873557922439856],
					[58.070655708591637, 1.8873557579472433],
					[58.070655756288595, 1.8873557246024424],
					[58.071105755634761, 1.8867945251038469],
					[58.07177780577473, 1.8859531129313416],
					[58.072235900589334, 1.8864861743681853],
					[58.072241499596615, 1.8873028010607635],
					[58.072241509180643, 1.8873028433693051],
					[58.07224154391907, 1.8873029163695829],
					[58.07224156926226, 1.8873029504254302],
					[58.07269986892036, 1.8877001511541691],
					[58.072699925268225, 1.8877001756119069],
					[58.072700041235002, 1.8877001864527267],
					[58.072700099962674, 1.8877001730139193],
					[58.074202899566714, 1.8868307728814744],
					[58.074202916825151, 1.8868307592252691],
					[58.074202948668344, 1.886830732447345],
					[58.074202963064288, 1.8868307159613105],
					[58.074500163023622, 1.886411215569882],
					[58.074500168884711, 1.8864112075939692],
					[58.074500178635581, 1.8864111886341817],
					[58.074500184402261, 1.8864111789761102],
					[58.074797384393747, 1.8857194786701883],
					[58.074797388891483, 1.8857194624614075],
					[58.074797396901303, 1.8857194285398766],
					[58.074797399616578, 1.8857194126874817],
					[58.074927999086668, 1.8835278236379596],
					[58.07514459693617, 1.8822917354020636],
					[58.075144597638484, 1.8822917318594241],
					[58.075144599043135, 1.8822917247741264],
					[58.075144598759771, 1.8822917197275864],
					[58.075352998678959, 1.8802361203503193],
					[58.075352998908798, 1.8802361083966999],
					[58.075352999368491, 1.8802360844894421],
					[58.075352997910372, 1.8802360745746758],
					[58.075191897712529, 1.8790110740114834],
					[58.075191891838884, 1.8790110496707342],
					[58.075191874042076, 1.8790110056021414],
					[58.075191862821093, 1.8790109823315964],
					[58.074425187978811, 1.877941618431094],
					[58.074269619358226, 1.8771278795250195],
					[58.074791734199991, 1.8767002315518677],
					[58.075097049881414, 1.876827954861165],
					[58.075399818031109, 1.8775000820772454],
					[58.075399822163121, 1.8775000914566773],
					[58.075399832115089, 1.8775001081765623],
					[58.075399838029519, 1.8775001171991916],
					[58.075785937827121, 1.878036216563439],
					[58.075785982077463, 1.8780362502338181],
					[58.075786082623345, 1.8780362913478905],
					[58.075786138729868, 1.8780362954272072],
					[58.076613939019843, 1.8778723967573356],
					[58.076613975978653, 1.8778723808532001],
					[58.076614041915839, 1.8778723353320168],
					[58.076614070002996, 1.8778723058933848],
					[58.077136269777725, 1.8770362068748478],
					[58.077136276340354, 1.8770361953538826],
					[58.077136285806155, 1.877036171343418],
					[58.07713629058631, 1.8770361601793093],
					[58.077355791317437, 1.8763444598304404],
					[58.077355794639104, 1.8763444387510433],
					[58.077355799310922, 1.8763443935845951],
					[58.077355798081939, 1.8763443717151689],
					[58.077114097722159, 1.8747138711470215],
					[58.077114092928035, 1.8747138499917482],
					[58.077114078087043, 1.8747138104344001],
					[58.077114069025953, 1.8747137935360669],
					[58.076347399439904, 1.873508241022809],
					[58.076339100392175, 1.8726888979795464],
					[58.076339097285576, 1.8726888747857766],
					[58.076339085819647, 1.8726888311516214],
					[58.076339078351566, 1.8726888105326984],
					[58.075725178043882, 1.8714777095630513],
					[58.075725134115572, 1.8714776656294387],
					[58.075725025681493, 1.8714776125051968],
					[58.075724963849346, 1.8714776027788977],
					[58.074822164265683, 1.8716442026591802],
					[58.074822129886797, 1.8716442163510301],
					[58.074822069717548, 1.8716442522205616],
					[58.074822043927156, 1.8716442743982427],
					[58.074149843694855, 1.8724859758331824],
					[58.074149841210385, 1.8724859797329376],
					[58.074149835350241, 1.872485987710987],
					[58.074149831974552, 1.8724859917892629],
					[58.0737027091211, 1.8731830718878477],
					[58.07271944813214, 1.8729415058518302],
					[58.072719400330321, 1.8729415052201346],
					[58.072719311398018, 1.8729415264342022],
					[58.072719269376329, 1.8729415484584628],
					[58.071741572568733, 1.8737942449753315],
					[58.070994305221419, 1.8743665205694788],
					[58.070091721010797, 1.8746691825168089],
					[58.068950131144, 1.8740220442948685],
					[58.067958476552043, 1.8728248993763412],
					[58.067725189166552, 1.8721499352533704],
					[58.067725171138484, 1.8721499031483928],
					[58.067725125751892, 1.8721498493107516],
					[58.067725099190014, 1.8721498257178104],
					[58.067044499330692, 1.8717637254173278],
					[58.067044455322858, 1.8717637121219444],
					[58.067044366754359, 1.8717637077510672],
					[58.067044322193695, 1.8717637166755747],
					[58.065991702762588, 1.8722080813964654],
					[58.065158408702395, 1.8718248295417881],
					[58.064244542004047, 1.8710359573478612],
					[58.063327952197817, 1.8699720689411845],
					[58.063327864694664, 1.8699720354521134],
					[58.063327700751742, 1.8699720529850135],
					[58.063327624406632, 1.869972105688577],
					[58.063030575319722, 1.8705274215932639],
					[58.062266862664025, 1.8694609832885589],
					[58.06226680278828, 1.8694609442675882],
					[58.062266674100172, 1.8694609156291861],
					[58.062266603410805, 1.8694609246872207],
					[58.061213802784842, 1.8700415252926288],
					[58.061213765179843, 1.8700415617314916],
					[58.061213714276576, 1.8700416501462005],
					[58.06121370008708, 1.8700417023005307],
					[58.061219199185494, 1.8704499316318011],
					[58.060547227158622, 1.871291344987732],
					[58.05962517432453, 1.8696832017403366],
					[58.059625163575141, 1.8696831868896717],
					[58.059625143156808, 1.8696831603726676],
					[58.059625130814233, 1.8696831492418038],
					[58.059169531342725, 1.8692887493147821],
					[58.059169485970457, 1.8692887277962456],
					[58.059169391108632, 1.869288707688141],
					[58.059169340727856, 1.8692887092770676],
					[58.058188841048548, 1.8695942095709754],
					[58.058188824304779, 1.869594216324834],
					[58.058188793680053, 1.869594232659759],
					[58.058188778813317, 1.869594240737968],
					[58.057441591793655, 1.8701637304741328],
					[58.056613858776501, 1.870602595848216],
					[58.055552819090849, 1.8704998013938157],
					[58.055552792070714, 1.8704998017034116],
					[58.055552737706769, 1.8704998125883694],
					[58.05555271195621, 1.8704998194444009],
					[58.054652712296225, 1.8709387199372556],
					[58.054049911988315, 1.8712331204253403],
					[58.054049873302397, 1.8712331536711901],
					[58.05404981667224, 1.8712332364115836],
					[58.054049809680926, 1.8712333364160429],
				],
				[
					[58.048630400190419, 1.9400750026875617],
					[58.048635899879599, 1.9407583012154828],
					[58.048635904145819, 1.9407583292650903],
					[58.048635919715409, 1.9407583822699905],
					[58.048635931018786, 1.9407584072252888],
					[58.049399830539024, 1.9419640064667505],
					[58.049399887016669, 1.9419640496676163],
					[58.049400011705224, 1.9419640878512989],
					[58.049400081698877, 1.9419640824808551],
					[58.050380682329653, 1.9415251830782541],
					[58.050380698797078, 1.9415251713159642],
					[58.050380731825534, 1.9415251494726311],
					[58.05038074810566, 1.9415251343478761],
					[58.05128074808983, 1.9405418347602914],
					[58.051280753156909, 1.940541828656458],
					[58.051280763995152, 1.940541812909593],
					[58.0512807699536, 1.9405418066290876],
					[58.051802922661203, 1.939705781803722],
					[58.052708185226685, 1.9395419232673974],
					[58.053477619066975, 1.9411583858039716],
					[58.053477633842064, 1.9411584083746294],
					[58.053477666676685, 1.9411584477651296],
					[58.053477686612581, 1.9411584659130048],
					[58.054619252076854, 1.9419418415410279],
					[58.055619229875717, 1.9435501052581643],
					[58.055619255721169, 1.9435501324375919],
					[58.055619315951155, 1.9435501783042748],
					[58.055619351039745, 1.9435501934519399],
					[58.057055550627062, 1.9439168939767364],
					[58.057055564278244, 1.9439168963720503],
					[58.057055592378354, 1.9439168993044684],
					[58.057055605935915, 1.9439169000182326],
					[58.058494405750196, 1.9438724003891901],
					[58.058494421794563, 1.9438723972097596],
					[58.058494453976905, 1.9438723925325234],
					[58.058494469927666, 1.9438723876714741],
					[58.059700069972948, 1.9434251881606217],
					[58.059700079590556, 1.9434251828540761],
					[58.059700100608488, 1.9434251718876476],
					[58.059700112008812, 1.9434251662277862],
					[58.060977895628163, 1.942566876305168],
					[58.061652844383367, 1.9422751982515558],
					[58.063244405699422, 1.9422251992583377],
					[58.063244411141184, 1.9422251998799229],
					[58.063244421039684, 1.9422251996179869],
					[58.063244426387783, 1.9422251985577339],
					[58.064602825957181, 1.942050197492845],
					[58.06460285438672, 1.9420501901558294],
					[58.064602907118449, 1.9420501660972447],
					[58.064602932311978, 1.942050149198975],
					[58.065727931841074, 1.9410584503995025],
					[58.065727941271213, 1.9410584417273831],
					[58.065727957363777, 1.9410584232314572],
					[58.065727964026202, 1.941058413407647],
					[58.066699934299365, 1.9396643436049286],
					[58.067008110312678, 1.9406111626486768],
					[58.067008178798815, 1.940611227293469],
					[58.06700833989126, 1.9406112718716788],
					[58.067008432403945, 1.9406112501230961],
					[58.068133431866372, 1.9396195503308116],
					[58.068133436135696, 1.9396195460826624],
					[58.068133442985385, 1.9396195396220113],
					[58.068133447254709, 1.9396195353738612],
					[58.069558447056878, 1.9380723356087322],
					[58.069558447854497, 1.9380723337497576],
					[58.069558449543464, 1.938072331713929],
					[58.069558451326159, 1.9380723313602117],
					[58.070902939963197, 1.936527944807507],
					[58.07244450592065, 1.9352529721961202],
					[58.074100080980259, 1.9345195836916833],
					[58.074100094960308, 1.9345195758131193],
					[58.074100121043941, 1.9345195587275905],
					[58.074100134038908, 1.9345195493436773],
					[58.075675133654336, 1.9331029498523202],
					[58.07567514209871, 1.9331029396701449],
					[58.075675158189924, 1.9331029211652175],
					[58.075675165836763, 1.9331029128424548],
					[58.076050138019703, 1.9325473521515013],
					[58.077411180739588, 1.9318223833247394],
					[58.078238968322601, 1.9315223872480014],
					[58.078238978924709, 1.9315223834405564],
					[58.078238999941334, 1.9315223724606445],
					[58.07823900955831, 1.9315223671477595],
					[58.07996676337342, 1.9303779976926521],
					[58.080713907438692, 1.9303530003867739],
					[58.080713965703161, 1.9303529786008435],
					[58.08071405886993, 1.930352903944959],
					[58.08071409288091, 1.9303528512521266],
					[58.081008493316169, 1.9292500523144294],
					[58.08100849411364, 1.9292500504546499],
					[58.081008494723449, 1.9292500452295998],
					[58.0810084946296, 1.9292500435469759],
					[58.08144459533279, 1.9273250442356402],
					[58.081444595847785, 1.9273250050032416],
					[58.081444583414118, 1.9273249275138697],
					[58.081444567885391, 1.9273248914711618],
					[58.080752967802404, 1.9262526918694625],
					[58.080752967802404, 1.9262526918694625],
					[58.080752966817194, 1.9262526903640853],
					[58.079605825805253, 1.9245110782405634],
					[58.080125181234123, 1.9232667854222012],
					[58.08071955714712, 1.9221529314100931],
					[58.082141756569641, 1.9210169105586803],
					[58.083655570815445, 1.9212418974211829],
					[58.083655582590332, 1.9212418984807911],
					[58.083655607828767, 1.9212418985625614],
					[58.083655621386278, 1.9212418992674234],
					[58.085469421287044, 1.9210501984886281],
					[58.085469437330566, 1.9210501952956238],
					[58.085469468432322, 1.921050187404197],
					[58.085469484381861, 1.9210501825283914],
					[58.087058383679555, 1.9203168817573122],
					[58.08705839765851, 1.9203168738699818],
					[58.08705842542853, 1.9203168547295335],
					[58.087058438422247, 1.9203168453367456],
					[58.088250138444749, 1.9191862454638788],
					[58.088250151953723, 1.9191862291611395],
					[58.088250176109796, 1.9191861937221406],
					[58.088250185865597, 1.9191861747633598],
					[58.089133469933728, 1.9169723135338288],
					[58.09040844124155, 1.9157029423883762],
					[58.090408453764709, 1.9157029245778354],
					[58.090408477028419, 1.9157028893118435],
					[58.090408486783652, 1.9157028703509407],
					[58.090777986834652, 1.9147333708156362],
					[58.090777992133262, 1.9147333527421893],
					[58.090777998274021, 1.914733317483212],
					[58.090777999913435, 1.9147332984370613],
					[58.090775200124376, 1.9143222652479575],
					[58.091819559643284, 1.9129223205459711],
					[58.09181957507267, 1.9129222902416398],
					[58.091819595141935, 1.9129222300815203],
					[58.091819599687739, 1.9129221985426599],
					[58.091800201266793, 1.9110111941266068],
					[58.092669429041642, 1.9103002345263658],
					[58.094038803423445, 1.9109390764781038],
					[58.094647091882905, 1.9113307686497667],
					[58.094647118671112, 1.9113307803286816],
					[58.094647176327612, 1.9113307960652044],
					[58.094647206304607, 1.9113308003005089],
					[58.095024993083712, 1.9113195991918897],
					[58.096083280668893, 1.9114223991344119],
					[58.096083325421347, 1.9114223936158354],
					[58.096083406666921, 1.9114223638010266],
					[58.096083444848489, 1.9114223374661092],
					[58.097430744927088, 1.910014037265648],
					[58.097430751774986, 1.9100140307936901],
					[58.09743076607981, 1.9100140126219916],
					[58.097430771754098, 1.9100140012777769],
					[58.098841872009913, 1.9076445013746153],
					[58.098841872807036, 1.9076444995134128],
					[58.098841875292585, 1.9076444956131773],
					[58.098841876089708, 1.9076444937519763],
					[58.099883475682546, 1.905697293224776],
					[58.099883480559328, 1.9056972837403834],
					[58.099883489327439, 1.90569726326605],
					[58.099883493312966, 1.9056972539595283],
					[58.100689093093465, 1.9028000544509394],
					[58.100689095107796, 1.9028000421328817],
					[58.100689099419085, 1.9028000225471311],
					[58.100689099650864, 1.9028000105849687],
					[58.100819600078232, 1.9004694114480136],
					[58.100819600404051, 1.9004694011692607],
					[58.10081959936737, 1.9004693826512389],
					[58.100819598004918, 1.9004693744119547],
					[58.100569598273594, 1.8985638730984258],
					[58.100569595831189, 1.8985638616704583],
					[58.100569590149426, 1.8985638406760375],
					[58.100569586815773, 1.8985638294261371],
					[58.09948628706212, 1.8957249298100713],
					[58.099486229720384, 1.8957248714786603],
					[58.099486093288071, 1.8957248170379517],
					[58.099486013211951, 1.8957248194233949],
					[58.098583309759775, 1.8961636731799489],
					[58.097294539308528, 1.8953859493697949],
					[58.096144562813699, 1.8937831845602713],
					[58.095303006095889, 1.8925777452586918],
					[58.095508495643621, 1.8905194484566898],
					[58.096027968227943, 1.8892723163838283],
					[58.096775099675668, 1.8885668804778659],
					[58.097377850594384, 1.8884084936756118],
					[58.097377870012892, 1.8884084863868396],
					[58.097377907675479, 1.8884084669378161],
					[58.097377925122721, 1.8884084566391037],
					[58.097905724552568, 1.8879834557616519],
					[58.097905736557898, 1.8879834448490909],
					[58.097905757800454, 1.8879834218755882],
					[58.097905767928921, 1.8879834096363486],
					[58.098725167998175, 1.8867251089676549],
					[58.098725180777137, 1.886725031513705],
					[58.098725148593488, 1.8867248915643207],
					[58.098725102739635, 1.8867248292471992],
					[58.097430711278058, 1.8859470335337272],
					[58.09689731863304, 1.8855526398812679],
					[58.096897285226461, 1.8855526227369317],
					[58.096897213915817, 1.8855526046673585],
					[58.096897175822875, 1.8855526003756611],
					[58.09531412267058, 1.8857414726234629],
					[58.095589098902487, 1.8828611192312399],
					[58.095589028121012, 1.882861014256632],
					[58.095588841019719, 1.8828609563963565],
					[58.095588723903099, 1.8828610053722374],
					[58.095141568614309, 1.8836970203743273],
					[58.094239004437021, 1.883860878559249],
					[58.094086274259546, 1.8835916015026091],
					[58.094086183140455, 1.8835915516609916],
					[58.094086005070693, 1.8835915583684393],
					[58.094085919011242, 1.8835916147391107],
					[58.09356674474418, 1.8847022319286242],
					[58.093411291930281, 1.8841610444794754],
					[58.093411268976212, 1.8841610048225839],
					[58.093411208483836, 1.8841609386392708],
					[58.093411169163026, 1.8841609124695953],
					[58.092050102999323, 1.8836581257569178],
					[58.090980762215864, 1.8827359751288395],
					[58.090133479596595, 1.8809860128299194],
					[58.090133465796576, 1.8809859917674427],
					[58.090133432943631, 1.880985952395908],
					[58.090133412999506, 1.8809859342652675],
					[58.087475113007436, 1.8791581345798087],
					[58.087475111130466, 1.8791581332539404],
					[58.087475107376548, 1.8791581306022342],
					[58.087475103717154, 1.8791581296332773],
					[58.085991804139333, 1.878249828735596],
					[58.085991734894051, 1.8782498153757039],
					[58.085991604680977, 1.8782498397481575],
					[58.085991543618675, 1.8782498757978094],
					[58.085430541018262, 1.8789525546064765],
					[58.084294490190196, 1.878441521804834],
					[58.083155734276168, 1.8777970458736157],
					[58.082541859217443, 1.8769970777312439],
					[58.082541832750735, 1.8769970558081537],
					[58.082541775644778, 1.8769970179018489],
					[58.082541744303377, 1.876997005462119],
					[58.080955643695006, 1.8766359054010131],
					[58.080955610247145, 1.8766359035920519],
					[58.080955541339549, 1.8766359122865499],
					[58.080955507662246, 1.8766359224331044],
					[58.079824907567975, 1.8772220221606402],
					[58.079824870757861, 1.8772220567518065],
					[58.079824816704097, 1.8772221373283],
					[58.079824801337374, 1.877222184639125],
					[58.079691515237364, 1.8788637065157312],
					[58.077805610241001, 1.8789248004558741],
					[58.076969426335147, 1.878816502234077],
					[58.076969374455061, 1.8788165092157825],
					[58.076969281915943, 1.8788165464508113],
					[58.076969241351428, 1.8788165783863839],
					[58.076224841245079, 1.8797970789527563],
					[58.076224824837865, 1.8797971077531863],
					[58.076224804878628, 1.8797971695859697],
					[58.076224800340853, 1.8797972011144743],
					[58.076235899706859, 1.8810277939413385],
					[58.076102601908772, 1.8830805656317966],
					[58.075516508063679, 1.8851499451927234],
					[58.075516505767624, 1.885149952456215],
					[58.0755165028636, 1.8851499649445358],
					[58.075516502350041, 1.8851499718515921],
					[58.075302605196832, 1.8866582529483491],
					[58.074452623857489, 1.8891470987819292],
					[58.073222147022136, 1.8905525573443247],
					[58.072086217243978, 1.8900415322479456],
					[58.071055742738572, 1.8889831596314397],
					[58.071055697180228, 1.8889831347175228],
					[58.071055598283515, 1.8889831068547267],
					[58.071055544242562, 1.8889831074479766],
					[58.070530659872269, 1.8891358736091561],
					[58.069758443852081, 1.8883415622319286],
					[58.069002955562731, 1.8875442739449009],
					[58.068008466018547, 1.8860748886145333],
					[58.068008458227816, 1.8860748782666272],
					[58.068008439972644, 1.8860748581052582],
					[58.068008431385103, 1.8860748496173603],
					[58.066791850360154, 1.8850220651341985],
					[58.066333465121545, 1.8843526876174663],
					[58.066333428097209, 1.8843526542035582],
					[58.066333341812978, 1.8843526102296078],
					[58.066333294335585, 1.8843525993132331],
					[58.065455594006465, 1.8843804006190421],
					[58.065455544380413, 1.8843804156414627],
					[58.065455459021891, 1.8843804684180245],
					[58.065455423289471, 1.884380506172143],
					[58.065085954248495, 1.8850748489770552],
					[58.064183212816104, 1.8855137195343248],
					[58.064183204092515, 1.8855137246789515],
					[58.064183186645309, 1.8855137349682265],
					[58.064183178907356, 1.8855137416164167],
					[58.063430478909225, 1.8860859416214786],
					[58.063430471968111, 1.8860859464096231],
					[58.063430459165843, 1.8860859591711161],
					[58.063430452319089, 1.8860859656409288],
					[58.062533269937511, 1.8870719359382193],
					[58.061394512559218, 1.8862887345064845],
					[58.061394484783662, 1.8862887213521136],
					[58.061394425248295, 1.8862887043415482],
					[58.061394392597251, 1.8862887006634577],
					[58.060036093095412, 1.8863331007988555],
					[58.060036061010678, 1.8863331072096856],
					[58.060036000081283, 1.8863331295860288],
					[58.060035971331004, 1.8863331472330249],
					[58.05936098175146, 1.8868998385377769],
					[58.05868056528503, 1.8873303853774182],
					[58.057697288161314, 1.8870887167421619],
					[58.057014021141846, 1.8865665403263994],
					[58.057013967750855, 1.8865665203891941],
					[58.057013858305957, 1.8865665133506178],
					[58.05701380225203, 1.8865665262492262],
					[58.055736002285038, 1.887288726118262],
					[58.055735988402617, 1.8872887356918016],
					[58.055735961623334, 1.8872887563421816],
					[58.05573594961777, 1.8872887672409726],
					[58.055363749794047, 1.8877109675411408],
					[58.055363734789644, 1.8877109892393491],
					[58.055363711396993, 1.8877110381147715],
					[58.055363703008751, 1.8877110652919671],
					[58.055144238680526, 1.888946964228275],
					[58.053936165790937, 1.8887137092699979],
					[58.053177891328787, 1.888327621731013],
					[58.05317786740153, 1.8883276129091666],
					[58.053177819033166, 1.8883276021681241],
					[58.053177793700847, 1.8883276004269334],
					[58.052044393813588, 1.8883637005997802],
					[58.052044332883654, 1.8883637229666175],
					[58.052044238945676, 1.8883637995236786],
					[58.052044205140746, 1.8883638555729947],
					[58.051535905238978, 1.890563855714698],
					[58.051535903550793, 1.8905638577516317],
					[58.051535903036843, 1.8905638646539571],
					[58.051535903225471, 1.8905638680161565],
					[58.050960903402114, 1.8941332678211842],
					[58.05060260563755, 1.8963277462688064],
					[58.049641512725763, 1.8988166274191598],
					[58.049641512117134, 1.8988166326399767],
					[58.049641509023118, 1.8988166417559824],
					[58.049641506537739, 1.8988166456511704],
					[58.048758106810645, 1.9019860467386003],
					[58.048758104701839, 1.9019860573575165],
					[58.048758100578404, 1.9019860802763717],
					[58.048758100346312, 1.9019860922210736],
					[58.048635899754736, 1.905263892628924],
					[58.048635899848854, 1.9052638943099451],
					[58.048635900131231, 1.9052638993529951],
					[58.048635900319496, 1.9052639027150402],
					[58.048660900130848, 1.9078583011587928],
					[58.048660900883583, 1.9078583146070305],
					[58.048660904077536, 1.9078583394675697],
					[58.048660906612817, 1.9078583525608988],
					[58.049360906634035, 1.9104306529645134],
					[58.04936096157617, 1.9104307168196437],
					[58.049361102131499, 1.910430780632034],
					[58.04936118685341, 1.9104307807666818],
					[58.04966374436772, 1.9102835977117099],
					[58.049980401694448, 1.9123222311366688],
					[58.049980414364697, 1.9123222643116162],
					[58.049980447444746, 1.9123223240219123],
					[58.04998046973116, 1.9123223518836436],
					[58.050438769730697, 1.9127168527744633],
					[58.050438774375223, 1.9127168552499465],
					[58.050438781975728, 1.9127168622367623],
					[58.050438786620255, 1.9127168647122603],
					[58.051199832246986, 1.9132390272977891],
					[58.051660911019674, 1.9145889644153542],
					[58.051660912193007, 1.9145889692816125],
					[58.05166091533691, 1.9145889771556441],
					[58.051660917307515, 1.9145889801634166],
					[58.052435916826219, 1.916341781396713],
					[58.052435959942265, 1.9163418272188093],
					[58.052436066953149, 1.9163418875311882],
					[58.052436129768743, 1.9163418988362892],
					[58.053566705818952, 1.9161696004518385],
					[58.054702601261276, 1.9162695831647851],
					[58.054713700019178, 1.917636101692294],
					[58.054713705369799, 1.9176361329288993],
					[58.054713725875402, 1.9176361934522403],
					[58.054713740936378, 1.9176362210576112],
					[58.055324801236196, 1.9184361685597326],
					[58.055335899643723, 1.9198000024118036],
					[58.055335899831604, 1.9198000057745748],
					[58.05533590128654, 1.9198000156856871],
					[58.055335900583131, 1.9198000192256828],
					[58.055588701401895, 1.9223861192612037],
					[58.055588704217499, 1.9223861374024023],
					[58.05558871421136, 1.9223861711176455],
					[58.055588720592205, 1.9223861885502442],
					[58.056124820941996, 1.9234639886631666],
					[58.056124833749692, 1.9234640082192087],
					[58.056124863727796, 1.9234640447642297],
					[58.056124880898196, 1.9234640617531999],
					[58.056655384094974, 1.9238556882437139],
					[58.056441502162421, 1.9252277685362542],
					[58.056441501552712, 1.9252277737577106],
					[58.056441501130749, 1.9252277823420945],
					[58.056441499629706, 1.9252277877406565],
					[58.056308099874769, 1.9272805859839983],
					[58.056172003039777, 1.9290582476114526],
					[58.055652711235837, 1.9301664195110551],
					[58.055200099009511, 1.9299081261029998],
					[58.055200012222585, 1.9299081212327285],
					[58.05519986585697, 1.9299081791922366],
					[58.055199808342351, 1.9299082467123303],
					[58.054538716877474, 1.9322499114893406],
					[58.053116540649484, 1.9342054799138055],
					[58.052219244963446, 1.9353248735716051],
					[58.050944247698716, 1.9368665708990827],
					[58.05019709123787, 1.9377081209663229],
					[58.049594350742829, 1.9378609068898403],
					[58.049594311288253, 1.937860926610685],
					[58.049594244199568, 1.9378609841059156],
					[58.049594217363115, 1.93786102002238],
					[58.048630417180433, 1.9400749194337774],
					[58.048630410188622, 1.9400749395163468],
					[58.048630401646783, 1.940074980302662],
					[58.048630400190419, 1.9400750026875617],
				],
			],
		},
		{
			fldNpdidField: "43778",
			wlbNpdidWellbore: "1782",
			fldName: "NORNE",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6608/10-2",
			fldDiscoveryYear: "1992",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43778",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43778",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "NORNE INSIDE",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=1782",
			fldHcType: "OIL/GAS",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "40672",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-02-17 00:00:00",
			fldMainSupplyBase: "Sandnessjøen",
			fldAreaGeometryWKT: [
				[
					[66.023369202320197, 8.1318639258920342],
					[66.023658100587397, 8.1339806185895167],
					[66.023741499941053, 8.136066707394626],
					[66.023741501821618, 8.136066722768728],
					[66.023741505557965, 8.1360667491069858],
					[66.023741510104841, 8.1360667599799861],
					[66.024435909093697, 8.138216758736732],
					[66.024819206918139, 8.1395611522209972],
					[66.025383105934679, 8.1417833487034326],
					[66.025383107765535, 8.1417833552584362],
					[66.025383112324292, 8.1417833683382792],
					[66.025383114155133, 8.1417833748933095],
					[66.02620811436519, 8.1438667746061988],
					[66.026208118899248, 8.1438667832764509],
					[66.026208128876647, 8.1438668027920134],
					[66.026208135216976, 8.1438668136072394],
					[66.027602635316683, 8.145914013812801],
					[66.027602635316683, 8.145914013812801],
					[66.027602637122939, 8.1459140159580343],
					[66.027602638019957, 8.1459140159279926],
					[66.028283138081107, 8.1468557180884993],
					[66.029685930211627, 8.1488112061091904],
					[66.030699824225792, 8.1506917943474537],
					[66.031974824199423, 8.1530389956854226],
					[66.03287481596351, 8.1547111790985074],
					[66.033660910117021, 8.1570639628999331],
					[66.03447760966138, 8.159516763378214],
					[66.034477610570448, 8.1595167655545673],
					[66.034477612388528, 8.1595167699072597],
					[66.034477613297568, 8.1595167720836077],
					[66.035233113445216, 8.1615139705854354],
					[66.036299813039065, 8.164319470572412],
					[66.036299815766, 8.1643194771024579],
					[66.036299823025828, 8.164319492309799],
					[66.036299826661732, 8.1643195010165162],
					[66.037894227167513, 8.1670972992018651],
					[66.037894243433044, 8.1670973207365325],
					[66.037894281310443, 8.1670973570113254],
					[66.037894302037174, 8.1670973739869837],
					[66.038763801850507, 8.1675862741885741],
					[66.038763807244564, 8.1675862762191063],
					[66.038763817147498, 8.1675862825157832],
					[66.038763821644466, 8.1675862845756129],
					[66.039908214960022, 8.1680695800698864],
					[66.041069308282871, 8.168666878139037],
					[66.042174880903531, 8.1692390640273107],
					[66.043197058645447, 8.1702612412717812],
					[66.044227659385101, 8.1712918407019597],
					[66.044227662996903, 8.1712918449988425],
					[66.044227672911546, 8.1712918535050658],
					[66.044227678329207, 8.171291859950351],
					[66.04492207800125, 8.1718251593757874],
					[66.044922078898239, 8.1718251593466356],
					[66.04593597907369, 8.1726001582015417],
					[66.045935979085542, 8.1726001604084608],
					[66.045935979982573, 8.1726001603793339],
					[66.047583166063632, 8.1738390489754575],
					[66.048277653893791, 8.1745807363907144],
					[66.048277773327328, 8.1745807568040725],
					[66.048277944209232, 8.1745806673827097],
					[66.048277995669423, 8.1745805597551371],
					[66.048072397419929, 8.1735916659122712],
					[66.047622398113347, 8.1704555718859755],
					[66.047622397180703, 8.1704555652939899],
					[66.047622394430292, 8.1704555543462689],
					[66.047622392600644, 8.1704555477834599],
					[66.047102992649144, 8.1685360476133422],
					[66.047102991728309, 8.1685360432285794],
					[66.04710298898965, 8.1685360344882998],
					[66.04710298717184, 8.1685360301328096],
					[66.046586286874174, 8.1671610299812318],
					[66.046586286874174, 8.1671610299812318],
					[66.045822386654137, 8.1651443290761758],
					[66.045822384836214, 8.1651443247211386],
					[66.045822381200367, 8.1651443160110571],
					[66.045822379370506, 8.1651443094491025],
					[66.044433480243256, 8.1623860127749932],
					[66.043533482388483, 8.1604138175433008],
					[66.04256408477643, 8.1582860231115948],
					[66.042100186923108, 8.1570471303234697],
					[66.042100186911057, 8.157047128116897],
					[66.041100186842812, 8.1543999283479458],
					[66.041100185933686, 8.1543999261712123],
					[66.041100184127558, 8.1543999240242435],
					[66.041100183206353, 8.1543999196410297],
					[66.039933484230033, 8.1517138215157114],
					[66.039122386230943, 8.149724924895315],
					[66.03823079012902, 8.1474388381833016],
					[66.037566893054532, 8.1449638483410496],
					[66.037077994195528, 8.1431638515449851],
					[66.036269594721546, 8.1396527544123884],
					[66.035841895200178, 8.1377805556911476],
					[66.035330794798256, 8.1355693549493235],
					[66.035330792967144, 8.1355693483921936],
					[66.035330789292544, 8.1355693330719738],
					[66.035330785667341, 8.1355693265756823],
					[66.034630786377789, 8.1337860265031594],
					[66.033944586197293, 8.1320443262257225],
					[66.033455786093526, 8.1308027264021359],
					[66.033455781558729, 8.1308027177317541],
					[66.033455771579682, 8.1308026982157813],
					[66.033455767044885, 8.1308026895454031],
					[66.031852967276805, 8.1283776902470297],
					[66.031852967264342, 8.1283776880413541],
					[66.031852966367339, 8.1283776880720175],
					[66.031852966367339, 8.1283776880720175],
					[66.030494566138415, 8.1263609883264518],
					[66.029441866714876, 8.1247665903996715],
					[66.029441854966095, 8.1247665753616296],
					[66.029441828802533, 8.1247665497888431],
					[66.02944181527225, 8.1247665370178321],
					[66.028641815427378, 8.1242026371808116],
					[66.028641811826816, 8.1242026350986158],
					[66.028641804625707, 8.1242026309342723],
					[66.028641801012597, 8.1242026266467047],
					[66.027855701081691, 8.1237415271594084],
					[66.027855692086504, 8.1237415230569372],
					[66.027855672302152, 8.1237415149136822],
					[66.027855662409962, 8.1237415108420361],
					[66.027000062333968, 8.1234609092693795],
					[66.027000044368734, 8.1234609054754543],
					[66.027000006656664, 8.1234609001545088],
					[66.026999988716454, 8.1234609007710894],
					[66.026366688646903, 8.1234970003988654],
					[66.02636664028357, 8.123497015294733],
					[66.026366557162575, 8.1234970710864935],
					[66.026366522379803, 8.1234971075719695],
					[66.025672075387192, 8.1248387069177852],
					[66.025099988430512, 8.1248719994964613],
					[66.02509993381301, 8.1248720190145871],
					[66.02509984537204, 8.1248720860091961],
					[66.025099810651511, 8.1248721335164173],
					[66.024658111103236, 8.1261443344748265],
					[66.024658106705672, 8.1261443500638695],
					[66.024658102383128, 8.1261443788832537],
					[66.024658099779629, 8.1261443944108276],
					[66.024588701355299, 8.1284027809080417],
					[66.024216503344107, 8.1305916658672537],
					[66.024216501599824, 8.1305916747484659],
					[66.024216499905307, 8.1305916924497836],
					[66.02421649995506, 8.1305917012698679],
					[66.024221999063087, 8.1324994894530622],
					[66.023727946267897, 8.1319692642404586],
					[66.023727926434276, 8.1319692472721989],
					[66.023727881409798, 8.1319692179287628],
					[66.023727856231346, 8.1319692077585799],
					[66.02336945589191, 8.1318637077519682],
					[66.023369362701942, 8.1318637285665005],
					[66.023369235922289, 8.1318638387390223],
					[66.023369202320197, 8.1318639258920342],
				],
				[
					[65.990233101193752, 8.0352583199733125],
					[65.990683100892994, 8.0399139200855849],
					[65.990683110068872, 8.0399139527795356],
					[65.990683140054145, 8.0399140133250508],
					[65.990683159980236, 8.0399140434123932],
					[65.991585917442578, 8.0408000996799682],
					[65.992041504164774, 8.0429861419796911],
					[65.992041506910496, 8.042986150687641],
					[65.992041514223317, 8.0429861724406955],
					[65.992041517866042, 8.0429861811150172],
					[65.992369209976914, 8.043711164722362],
					[65.992713698932889, 8.0451444192069577],
					[65.992638699588952, 8.0473610935961268],
					[65.992638705175295, 8.0473611264290668],
					[65.992638725317946, 8.0473611917604089],
					[65.992638740757599, 8.0473612220231416],
					[65.994238736227189, 8.0494473148647749],
					[65.994941530850554, 8.0505640080581085],
					[65.995597031426982, 8.0515973060912671],
					[65.995597037773791, 8.0515973168706196],
					[65.995597052234245, 8.0515973339576963],
					[65.995597060361462, 8.0515973424679377],
					[65.996360946372448, 8.0523473280174649],
					[65.997527624106112, 8.0540583951933904],
					[65.999383116342841, 8.0584361776800062],
					[65.999383116356299, 8.0584361798828326],
					[66.000769214722297, 8.0616528766067539],
					[66.001883114103919, 8.0645111725352017],
					[66.001883115938043, 8.0645111790786359],
					[66.001883119579546, 8.0645111877593898],
					[66.001883123194304, 8.0645111920340309],
					[66.002030422983381, 8.0647944917285503],
					[66.002030422996754, 8.0647944939316112],
					[66.006674821857573, 8.0736500918782816],
					[66.007994215839332, 8.0763333800964876],
					[66.008766513181456, 8.0784000692993612],
					[66.008766537637797, 8.0784001080906922],
					[66.008766602604169, 8.0784001696648531],
					[66.008766643114242, 8.0784001924476634],
					[66.009047054397826, 8.0784834646863484],
					[66.011772017404255, 8.0847500804873977],
					[66.013694209384326, 8.0891639638865112],
					[66.015152604355961, 8.0954583377907543],
					[66.015694202175098, 8.0989111304583119],
					[66.016358102068295, 8.1031500314692124],
					[66.016777602060245, 8.1058250303638406],
					[66.016777604827979, 8.1058250434957309],
					[66.016777612131975, 8.1058250652878598],
					[66.016777615796755, 8.1058250783882926],
					[66.018188715975668, 8.1091667771426152],
					[66.018188805148995, 8.1091668379721238],
					[66.018188987318041, 8.1091668448412868],
					[66.018189080300999, 8.1091667886764522],
					[66.021119579658844, 8.1031639874484789],
					[66.021119585848083, 8.103163971794574],
					[66.021119596406862, 8.1031639361403904],
					[66.021119598995242, 8.1031639184079101],
					[66.021233498790849, 8.1019139184882984],
					[66.021400198812529, 8.1000806172924609],
					[66.0214001977739, 8.1000805930717661],
					[66.021400191224487, 8.1000805469933663],
					[66.021400184803881, 8.1000805229625286],
					[66.019364098441386, 8.0951971543352084],
					[66.019005819803056, 8.0890531326352093],
					[66.023360972879885, 8.0912140592537405],
					[66.023819226899391, 8.0917695043693918],
					[66.024558116038108, 8.0935278776765482],
					[66.024558125111867, 8.0935278949979725],
					[66.024558147744457, 8.0935279294814499],
					[66.024558162187262, 8.0935279444065813],
					[66.025763762175131, 8.0946834433885311],
					[66.02717485328391, 8.096039036681594],
					[66.028616536839991, 8.0977973159955408],
					[66.029974830606648, 8.0999529062305609],
					[66.03133313113247, 8.1021112063412666],
					[66.032691531237717, 8.1042723061640149],
					[66.032691622129946, 8.1042723537132186],
					[66.032691797007431, 8.1042723409510593],
					[66.032691881018309, 8.1042722850512074],
					[66.032875036049461, 8.1038781964781403],
					[66.035652637273046, 8.1077973155273835],
					[66.037810936884114, 8.1108418155420594],
					[66.037810947724537, 8.1108418284036912],
					[66.037810971186616, 8.1108418518582006],
					[66.037810982911282, 8.1108418624823653],
					[66.041038783089078, 8.1131584622155675],
					[66.041038862139814, 8.1131584793270655],
					[66.041039007302544, 8.1131584477931291],
					[66.041039073427186, 8.1131584013541538],
					[66.043983348946156, 8.108125313922967],
					[66.04824154662326, 8.1119445304142683],
					[66.049841532209939, 8.1144223075657465],
					[66.049841532222601, 8.1144223097729711],
					[66.051574832062769, 8.1170973088182468],
					[66.051574833869424, 8.1170973109634446],
					[66.051574836585715, 8.1170973152849495],
					[66.051574839301978, 8.1170973196064349],
					[66.052688739197166, 8.1186112191662261],
					[66.052688781558203, 8.1186112530271064],
					[66.052688877777499, 8.1186112916481701],
					[66.0526889316357, 8.1186112964083748],
					[66.055977832105469, 8.1180723964284489],
					[66.055977898269319, 8.1180723565979918],
					[66.055977980137556, 8.1180722389367919],
					[66.055977996764227, 8.1180721654905046],
					[66.055122397325832, 8.113297165048122],
					[66.055122396416138, 8.1132971628716692],
					[66.055122394584046, 8.113297156311063],
					[66.055122394571384, 8.1132971541033818],
					[66.054175195384502, 8.109252756868873],
					[66.052833496057744, 8.1029471598985445],
					[66.052191895561009, 8.0998805588118916],
					[66.05016409587752, 8.0900888586373476],
					[66.050164094044462, 8.0900888520797114],
					[66.05016409128838, 8.0900888411396181],
					[66.050164088558304, 8.0900888346140007],
					[66.048111289260888, 8.0841721337169989],
					[66.048111281096155, 8.0841721185577757],
					[66.048111262959651, 8.0841720860967392],
					[66.04811125300094, 8.0841720710020013],
					[66.04400015428979, 8.0792859726415749],
					[66.038527980285437, 8.0725860036269665],
					[66.037491892382434, 8.0691416428726672],
					[66.03749189056181, 8.0691416385259043],
					[66.037491888741187, 8.0691416341791626],
					[66.037491887830853, 8.0691416320057918],
					[66.036858488030319, 8.0674305311924659],
					[66.036858482581636, 8.0674305203589789],
					[66.036858474401953, 8.0674305030057045],
					[66.03685846895327, 8.0674304921722193],
					[66.034736286610666, 8.0640666209864555],
					[66.034605794928169, 8.0635166530141742],
					[66.034605790349403, 8.0635166377377843],
					[66.034605776706911, 8.0635166073497295],
					[66.034605769450536, 8.0635165943780613],
					[66.033250168821169, 8.0613609943298652],
					[66.033250158860241, 8.0613609792528109],
					[66.033250135377273, 8.061360953642307],
					[66.033250121841789, 8.0613609409030982],
					[66.032814050052963, 8.0610248627732304],
					[66.032089069423449, 8.0598776936083301],
					[66.032089063987669, 8.0598776849841567],
					[66.032089052232578, 8.0598776699744938],
					[66.032089045913267, 8.0598776635890452],
					[66.030839046248573, 8.0585415634276298],
					[66.028480745580254, 8.0560081631802305],
					[66.028480743772761, 8.0560081610412997],
					[66.028480739260772, 8.05600815679664],
					[66.028480735659301, 8.0560081547240951],
					[66.024886250648066, 8.0526554672607453],
					[66.021958462938997, 8.0485470846327658],
					[66.021958460220844, 8.0485470803235906],
					[66.021958454798025, 8.0485470739100258],
					[66.021958452990432, 8.0485470717721359],
					[66.019241860022987, 8.0453387783940489],
					[66.016272379763635, 8.0408943095177321],
					[66.013947404751491, 8.0345082775647789],
					[66.014136298016894, 8.0331444279356603],
					[66.01413629876177, 8.033144403656701],
					[66.014136293075552, 8.0331443553706627],
					[66.014136288438465, 8.0331443312956132],
					[66.013336288347332, 8.0309360319835683],
					[66.013336279252712, 8.0309360124880111],
					[66.01333625570922, 8.0309359781093157],
					[66.01333624304047, 8.0309359609540394],
					[66.012833456566852, 8.0304220750210291],
					[66.011405772560963, 8.028224899386208],
					[66.009758477850411, 8.0250805090492676],
					[66.008636279851146, 8.02278321136904],
					[66.008636269886438, 8.0227831963215408],
					[66.008636247266068, 8.0227831663295408],
					[66.008636233713418, 8.0227831514194037],
					[66.0066390338947, 8.0209748521280915],
					[66.00663902036986, 8.0209748416269324],
					[66.006638988849303, 8.020974823000067],
					[66.006638972647536, 8.0209748148055393],
					[66.005363973211686, 8.0204748143181188],
					[66.005363916659491, 8.0204748098763776],
					[66.005363810016192, 8.0204748293951482],
					[66.00536375995307, 8.0204748577623217],
					[65.990233160284959, 8.0352581569558268],
					[65.990233136286932, 8.0352581931028357],
					[65.990233107196715, 8.0352582756956643],
					[65.990233101193752, 8.0352583199733125],
				],
			],
		},
		{
			fldNpdidField: "28975098",
			wlbNpdidWellbore: "321",
			fldName: "UTGARD",
			fldCurrentActivitySatus: "Producing",
			wlbName: "15/8-1",
			fldDiscoveryYear: "1982",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=28975098",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=28975098",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "UTGARD UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=321",
			fldHcType: "GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "28975129",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-01-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[58.344366503043545, 1.5424111181485487],
					[58.344366504631154, 1.5424111452473168],
					[58.344585905234268, 1.5433639440950515],
					[58.344585918894516, 1.5433639772094585],
					[58.344585955613873, 1.5433640345878721],
					[58.344585978672981, 1.54336405885188],
					[58.345024865132984, 1.543697349562289],
					[58.345535943190143, 1.5442445256194588],
					[58.345819202214301, 1.5446694648018666],
					[58.345852599376677, 1.5456305734612876],
					[58.345719209182541, 1.546072141161795],
					[58.345719208391188, 1.5460721430441855],
					[58.345719207599842, 1.5460721449265666],
					[58.345719207699013, 1.5460721466203187],
					[58.345519208091744, 1.5467805460274091],
					[58.345519203940611, 1.5467805829163808],
					[58.34551921186619, 1.5467806566867162],
					[58.345519222062634, 1.5467806922515832],
					[58.345872021903411, 1.547463991987863],
					[58.345872032789977, 1.5474640085446698],
					[58.345872058026075, 1.5474640392100991],
					[58.345872072375606, 1.5474640533187327],
					[58.346222064639271, 1.5477557487508664],
					[58.346733132194863, 1.5482612147769317],
					[58.347297000156757, 1.5495472416024445],
					[58.347288700194909, 1.5506860997144174],
					[58.347288699700854, 1.5506861066783326],
					[58.34728870138435, 1.5506861200405537],
					[58.347288701780826, 1.5506861268159382],
					[58.347413701795439, 1.5515972272133878],
					[58.34758870143299, 1.5529028233160485],
					[58.347747001199409, 1.5545583186069496],
					[58.347747003872087, 1.554558333475025],
					[58.34774701109766, 1.5545583645281591],
					[58.347747015551455, 1.5545583790193578],
					[58.34800541579564, 1.5551583792514043],
					[58.348005429749165, 1.5551584020219369],
					[58.348005463790962, 1.555158444550121],
					[58.348005482889633, 1.5551584628023192],
					[58.34835268304893, 1.5554084615817028],
					[58.348352708183278, 1.555408475127426],
					[58.348352758847199, 1.5554084935608905],
					[58.348352786256925, 1.5554084997656898],
					[58.348744385639485, 1.5554362998977422],
					[58.348744423240475, 1.5554362953718766],
					[58.348744493988697, 1.5554362718284582],
					[58.3487445281256, 1.5554362543163958],
					[58.349244528123087, 1.5550223532927077],
					[58.349244539204825, 1.5550223423732574],
					[58.349244560378722, 1.5550223190288386],
					[58.349244568689763, 1.5550223069807416],
					[58.349697354310656, 1.5543029298068918],
					[58.350311213733889, 1.5537529666136183],
					[58.350975089705031, 1.5534195796321701],
					[58.350975094949199, 1.5534195768072061],
					[58.350975103656424, 1.5534195715342505],
					[58.350975108900556, 1.5534195687092944],
					[58.351475108879612, 1.5530945678172428],
					[58.351475121841339, 1.5530945582135036],
					[58.351475145785507, 1.5530945359949375],
					[58.351475156767961, 1.5530945233800815],
					[58.351975095353325, 1.5524613026540557],
					[58.352663876658596, 1.5524779989319626],
					[58.353355556102294, 1.5526223947453868],
					[58.35381657513382, 1.5527362747169884],
					[58.354308132188365, 1.55350290869204],
					[58.354308145844989, 1.5535029263831852],
					[58.35430818018348, 1.5535029585632059],
					[58.354308198985187, 1.5535029717348814],
					[58.354794288115031, 1.5537890652400086],
					[58.355280478269634, 1.5541612587322395],
					[58.355280479160179, 1.5541612585437374],
					[58.355280481139438, 1.5541612615552076],
					[58.355280482029976, 1.5541612613667104],
					[58.355791546123633, 1.5545362354577423],
					[58.356424808315076, 1.555688965445583],
					[58.356546996554485, 1.556472194853014],
					[58.356416517315537, 1.5570888008036703],
					[58.356144312683028, 1.5573637030550864],
					[58.355777791904508, 1.5573776006929636],
					[58.355777768651251, 1.5573776038977318],
					[58.355777723431423, 1.5573776168959463],
					[58.355777699683777, 1.5573776270662549],
					[58.355366600318121, 1.5576137264254597],
					[58.355366572810972, 1.5576137493973625],
					[58.355366528978386, 1.5576138015514478],
					[58.355366511861469, 1.5576138326163247],
					[58.355255411860838, 1.5579249338437673],
					[58.355255406716353, 1.5579249537994277],
					[58.355255400979139, 1.557924994462953],
					[58.355255401177949, 1.5579250132881568],
					[58.355280401370294, 1.5582722147012693],
					[58.355280434126051, 1.5582722815255836],
					[58.355280536940207, 1.5582723746937721],
					[58.355280607889135, 1.5582724008492497],
					[58.35548890793217, 1.558264099677416],
					[58.355488937320281, 1.5582640934602092],
					[58.35548899302875, 1.5582640748139083],
					[58.355489019250086, 1.5582640606905598],
					[58.355922309914597, 1.5579418663099878],
					[58.356491733981194, 1.5576113111891341],
					[58.35702211670894, 1.557722386418732],
					[58.357416520384184, 1.558011215334149],
					[58.357610903848155, 1.5589222416422088],
					[58.357610905926315, 1.5589222463485282],
					[58.357610909390147, 1.5589222593382934],
					[58.357610910577755, 1.5589222642330176],
					[58.357919211148094, 1.559827863902628],
					[58.357919214413819, 1.5598278735038242],
					[58.357919221736793, 1.55982789082346],
					[58.357919225794035, 1.5598278985418965],
					[58.358505419374801, 1.5608528866744493],
					[58.359380414407674, 1.5630917725724001],
					[58.359877602212158, 1.5643833430758545],
					[58.359935899263228, 1.5653860922922169],
					[58.359808104761974, 1.5663971472633031],
					[58.359560916428215, 1.5669777217912473],
					[58.359560913261632, 1.5669777293220772],
					[58.359560907819002, 1.5669777441955197],
					[58.35956090554297, 1.5669777515381356],
					[58.359341505750216, 1.56786105216149],
					[58.359341505156351, 1.5678610574331575],
					[58.359341503078092, 1.5678610681646481],
					[58.359341501593676, 1.56786107362449],
					[58.359152603053879, 1.5693110665741612],
					[58.358999816690087, 1.5700609937745384],
					[58.358636053101023, 1.5703803746005593],
					[58.3580361617392, 1.5701859105769909],
					[58.358036140662797, 1.5701859047371187],
					[58.358036099004323, 1.5701859015295436],
					[58.358036078224522, 1.5701859007729662],
					[58.357369414484808, 1.5702580975829163],
					[58.356769449209118, 1.5701081057757598],
					[58.356769445547954, 1.5701081048338337],
					[58.356769441985669, 1.5701081055862798],
					[58.356769440105658, 1.5701081042681306],
					[58.355872239866848, 1.5699248045780854],
					[58.355872220769321, 1.5699248017510667],
					[58.355872183860342, 1.5699248026862278],
					[58.35587216496058, 1.5699248032478741],
					[58.355252765313182, 1.5700359023200421],
					[58.355252734439766, 1.5700359139865154],
					[58.355252676848409, 1.5700359467328988],
					[58.355252651021068, 1.570035967624668],
					[58.354844251119744, 1.5704915666060861],
					[58.354844232911219, 1.5704915944639795],
					[58.354844207675427, 1.5704916563941467],
					[58.354844199658686, 1.5704916889602309],
					[58.354785900081389, 1.5715416890011578],
					[58.354785900971066, 1.5715417042497197],
					[58.354785903739824, 1.5715417362530668],
					[58.354785907400057, 1.5715417526317355],
					[58.355049806940649, 1.5724944531495944],
					[58.355049809117155, 1.5724944595507102],
					[58.355049813569032, 1.5724944740472699],
					[58.355049816636111, 1.5724944802603626],
					[58.355427617089113, 1.5733528813985271],
					[58.355427617089113, 1.5733528813985271],
					[58.355427618177359, 1.5733528845991511],
					[58.355427619067939, 1.5733528844111246],
					[58.3558498106824, 1.5742556677085116],
					[58.356235906291936, 1.5757694493986036],
					[58.356235906291936, 1.5757694493986036],
					[58.356235907281317, 1.5757694509050097],
					[58.356499801228601, 1.5767639315819406],
					[58.356558098836814, 1.5778555957301719],
					[58.356474803082563, 1.5787332542668964],
					[58.356116513809177, 1.5796666253789666],
					[58.355783114641476, 1.5804666222491754],
					[58.355783111572791, 1.5804666314723865],
					[58.355783107216574, 1.5804666495431166],
					[58.355783105038469, 1.5804666585784841],
					[58.355497004723453, 1.5817916586059855],
					[58.355497004030283, 1.5817916621825079],
					[58.355497001852086, 1.5817916712177291],
					[58.355497001257646, 1.5817916764885969],
					[58.355372003145469, 1.5828888585001903],
					[58.354949809198516, 1.5842610409806734],
					[58.354949807614702, 1.5842610447448329],
					[58.354949806921454, 1.5842610483212538],
					[58.354949806228234, 1.5842610518976825],
					[58.354577607303334, 1.585763847541747],
					[58.354199812173448, 1.5870471312624308],
					[58.353683130833822, 1.588247088343181],
					[58.353116578965228, 1.5888387377044424],
					[58.352474929029903, 1.5891692102572601],
					[58.351833251925179, 1.589327606013679],
					[58.351833230747722, 1.5893276139042962],
					[58.351833191360441, 1.5893276342054126],
					[58.351833172161435, 1.5893276451092557],
					[58.351060972367819, 1.5899692457777157],
					[58.351060970685204, 1.5899692478470449],
					[58.351060969794595, 1.5899692480346181],
					[58.35106096890398, 1.5899692482221897],
					[58.350263781710638, 1.5906581387881134],
					[58.349349928080265, 1.5912192092317268],
					[58.348408265470908, 1.5913859036514568],
					[58.34840826190846, 1.591385904401508],
					[58.348408257455368, 1.5913859053390831],
					[58.348408254783507, 1.5913859059016342],
					[58.347263854880552, 1.591652604619608],
					[58.347263841619871, 1.5916526091261056],
					[58.347263817770354, 1.5916526175765899],
					[58.347263806389478, 1.5916526234020987],
					[58.346555505918239, 1.5920304237082104],
					[58.346555486817465, 1.5920304363027251],
					[58.3465554533648, 1.5920304656362334],
					[58.346555438122223, 1.5920304825627367],
					[58.34603313799056, 1.5927498824067803],
					[58.346033124328429, 1.592749911002866],
					[58.346033105315463, 1.5927499715897069],
					[58.346033099866069, 1.5927500018865146],
					[58.346047000329328, 1.5940167015413071],
					[58.346047010016484, 1.5940167440846325],
					[58.346047046016977, 1.5940168205282432],
					[58.346047072330329, 1.5940168544285209],
					[58.346397072017972, 1.5943084532209619],
					[58.346397099127095, 1.5943084698072318],
					[58.346397158196361, 1.5943084933853309],
					[58.346397189265886, 1.5943085005645934],
					[58.346855507844523, 1.5943334944338519],
					[58.347366515298482, 1.5948833948479106],
					[58.347585904128508, 1.5959667392966055],
					[58.347585906402365, 1.5959667473919916],
					[58.347585911048604, 1.595966765276793],
					[58.347585914213077, 1.5959667731847922],
					[58.348083113748977, 1.5972139737667808],
					[58.348083117804016, 1.5972139814876283],
					[58.348083125023457, 1.5972139971167054],
					[58.348083129078496, 1.5972140048375558],
					[58.349019228844249, 1.5987529040144519],
					[58.349019267030144, 1.5987529405680596],
					[58.349019357262364, 1.5987529884671146],
					[58.34901940841808, 1.5987529999998886],
					[58.34950280793268, 1.5987335002073617],
					[58.349502829406319, 1.5987334974053917],
					[58.349502870178426, 1.59873348539958],
					[58.349502888783192, 1.5987334797713564],
					[58.349980633833866, 1.5984946061044383],
					[58.350741576320551, 1.5985973851094344],
					[58.351163713354943, 1.5993222775058333],
					[58.351522003137461, 1.600708338829415],
					[58.351872000245713, 1.6032306168229529],
					[58.351941500295119, 1.6052833064967675],
					[58.351941500688746, 1.6052833132738824],
					[58.351941502366586, 1.6052833266409221],
					[58.351941502661809, 1.6052833317237618],
					[58.352210903423654, 1.6069333317702605],
					[58.352210905002963, 1.6069333434432032],
					[58.352210911822567, 1.6069333677347499],
					[58.352210915975419, 1.6069333771519509],
					[58.352610915723645, 1.6078806777335799],
					[58.352610942132024, 1.6078807133447575],
					[58.35261100771833, 1.6078807715942389],
					[58.352611046797882, 1.6078807925382186],
					[58.353441646558096, 1.6081084931102794],
					[58.353441652000342, 1.6081084936818599],
					[58.353441660212923, 1.6081084953864211],
					[58.35344166486292, 1.608108497839478],
					[58.354455565064953, 1.6082890971873773],
					[58.354455575949487, 1.6082890983306055],
					[58.354455596827911, 1.6082891008041722],
					[58.354455607614085, 1.6082891002529915],
					[58.355283308085582, 1.6082558005720189],
					[58.355283313429489, 1.6082557994491717],
					[58.355283324117295, 1.6082557972034901],
					[58.355283328668911, 1.6082557979622496],
					[58.356500028891517, 1.6080751982778267],
					[58.356500045813867, 1.6080751947219125],
					[58.356500080254079, 1.6080751823393913],
					[58.356500096089043, 1.6080751755816065],
					[58.356841796343588, 1.6078890755252453],
					[58.356841803272061, 1.6078890706389224],
					[58.356841816336654, 1.6078890627479281],
					[58.356841823265114, 1.607889057861599],
					[58.357433422998618, 1.6074279574581936],
					[58.357433444974916, 1.6074279322570082],
					[58.357433480616542, 1.6074278784555593],
					[58.357433494183503, 1.6074278481607522],
					[58.357786293591829, 1.6060139474538022],
					[58.358050193891224, 1.6049528476888697],
					[58.358050195377281, 1.6049528422306609],
					[58.358050196568094, 1.6049528316887538],
					[58.358050198054137, 1.604952826230553],
					[58.358264097573631, 1.60337222921567],
					[58.358500197654848, 1.6018750310966812],
					[58.358500198151681, 1.6018750241310327],
					[58.358500199342238, 1.6018750135889013],
					[58.358500199937509, 1.6018750083178459],
					[58.358572399620151, 1.5999917082388111],
					[58.358572400412314, 1.5999917063568643],
					[58.35857240011687, 1.599991701273126],
					[58.358572400018382, 1.5999916995785535],
					[58.358575199564662, 1.598286099923246],
					[58.358575200356775, 1.5982860980412752],
					[58.358550200266855, 1.5959250092017414],
					[58.358716898629844, 1.59434442363544],
					[58.358886294344018, 1.5929806591120401],
					[58.359561278798203, 1.5916417907402398],
					[58.3595612819663, 1.5916417832115137],
					[58.359561288302515, 1.591641768154054],
					[58.359561290579983, 1.5916417608129345],
					[58.360027990637278, 1.5901806617829986],
					[58.360027992717477, 1.5901806510524559],
					[58.360027996877804, 1.590180629591377],
					[58.360027999056598, 1.5901806205554403],
					[58.36019189893937, 1.5885139208490662],
					[58.360191900030159, 1.588513908611443],
					[58.360191898747921, 1.5885138865815347],
					[58.360191898057515, 1.588513874719268],
					[58.359964098473554, 1.5866860757320873],
					[58.359964095903081, 1.5866860625508017],
					[58.359964088980931, 1.5866860365636501],
					[58.359964084629262, 1.5866860237577767],
					[58.35968078507635, 1.5859971231790961],
					[58.359680778052798, 1.5859971109366275],
					[58.359680763016421, 1.5859970849448517],
					[58.359680754211652, 1.5859970730778379],
					[58.359191861903923, 1.5854026822951419],
					[58.358677985828194, 1.584594319519895],
					[58.358316896553532, 1.5831193640393952],
					[58.358164099999982, 1.5818582895291462],
					[58.358186300177977, 1.5797139017518074],
					[58.358186299881737, 1.5797138966683617],
					[58.358186298993012, 1.5797138814180616],
					[58.358186298696779, 1.5797138763346177],
					[58.358086298789424, 1.5788888766452795],
					[58.358086297207528, 1.5788888649718884],
					[58.358086291371976, 1.5788888421887817],
					[58.358086288008892, 1.5788888308911844],
					[58.357850202021865, 1.5782416712156258],
					[58.357889095007032, 1.5776722787657342],
					[58.358227891584114, 1.577264101886974],
					[58.358894340889215, 1.5772807989771376],
					[58.359566575922457, 1.5776918573255103],
					[58.360285917604266, 1.5784057014276873],
					[58.360744205357115, 1.5803556456798558],
					[58.360744206642686, 1.5803556522703914],
					[58.360744211984311, 1.580355666582431],
					[58.360744214259185, 1.5803556746796859],
					[58.361052613674048, 1.581130673738496],
					[58.361052629700751, 1.5811307012358369],
					[58.361052670363826, 1.5811307492680267],
					[58.36105269401093, 1.5811307682960829],
					[58.36144709406674, 1.581377968320081],
					[58.361447132853932, 1.5813779841548621],
					[58.361447212805942, 1.5813779947372688],
					[58.361447254168233, 1.5813779928741842],
					[58.361813954418636, 1.581275192630476],
					[58.361813983413349, 1.58127517965221],
					[58.361814033685626, 1.581275145030681],
					[58.361814056744386, 1.5812751230116677],
					[58.362380744883161, 1.5805529387885493],
					[58.363086206916094, 1.5799557712955694],
					[58.363633374989227, 1.5797140857930583],
					[58.364297253413746, 1.5794695934228147],
					[58.365216735916228, 1.5793001963850579],
					[58.365216738587996, 1.5793001958212332],
					[58.365216743931533, 1.5793001946935832],
					[58.365216746603316, 1.5793001941297584],
					[58.366294446923895, 1.5790390937495062],
					[58.366294461965154, 1.5790390888593875],
					[58.366294491058326, 1.5790390775722318],
					[58.36629450501146, 1.5790390694803187],
					[58.367502896756797, 1.5782918747063763],
					[58.368188988206548, 1.5779557792790151],
					[58.368188989097128, 1.5779557790910173],
					[58.368188990878302, 1.577955778715028],
					[58.368188991768911, 1.5779557785270237],
					[58.368919491909033, 1.5775779775114924],
					[58.368919492799613, 1.5775779773234884],
					[58.368919496361954, 1.5775779765714335],
					[58.36891949715374, 1.5775779746884384],
					[58.369513996623709, 1.5772473756480574],
					[58.369514001868446, 1.5772473728248937],
					[58.369514012259096, 1.5772473654835422],
					[58.369514016613223, 1.5772473628484145],
					[58.369833416947102, 1.5770168627586192],
					[58.36983343406721, 1.577016847132775],
					[58.369833463558194, 1.5770168117361341],
					[58.369833476918394, 1.5770167934723553],
					[58.370327977319789, 1.5760778932120572],
					[58.370327979695119, 1.5760778875627464],
					[58.370327985336353, 1.5760778760760332],
					[58.370327986821096, 1.5760778706147647],
					[58.37095578124903, 1.5744333873182876],
					[58.371427964253726, 1.5736279148538399],
					[58.371880713301493, 1.5730834765267292],
					[58.372638952418285, 1.5728779935954893],
					[58.372638956871178, 1.5728779926546972],
					[58.372638967459245, 1.5728779887016542],
					[58.372638970922679, 1.5728779862538833],
					[58.373277871426311, 1.5726334877478185],
					[58.373277894977569, 1.5726334741913754],
					[58.373277938221229, 1.5726334427456414],
					[58.373277956924184, 1.5726334233493326],
					[58.373844556968905, 1.5719112225974885],
					[58.373844568547128, 1.5719112047060224],
					[58.373844587943573, 1.5719111662854244],
					[58.373844594772351, 1.571911144249301],
					[58.374152995320365, 1.5705861447031575],
					[58.3741529958154, 1.570586137734109],
					[58.374152999180524, 1.570586118145666],
					[58.374152999675552, 1.5705861111766],
					[58.374227998001679, 1.5691833393093853],
					[58.374608482603037, 1.5681167830739888],
					[58.375036264755359, 1.5673557144217396],
					[58.375852903389116, 1.5664029866220035],
					[58.376655579745822, 1.5662835046250896],
					[58.377647132743405, 1.5666362887794076],
					[58.377647140956348, 1.5666362904749533],
					[58.37764715560111, 1.5666362942427672],
					[58.377647163912989, 1.5666362976336869],
					[58.378822164154109, 1.5668529977747423],
					[58.378822184933881, 1.5668529985288073],
					[58.378822227186113, 1.5668529964577749],
					[58.378822247767985, 1.5668529938210107],
					[58.379669447740305, 1.5666418933847432],
					[58.379669461989316, 1.5666418903708654],
					[58.379669488409384, 1.5666418796334671],
					[58.379669500679363, 1.566641873605416],
					[58.380630701205916, 1.5660779737521593],
					[58.380630713277981, 1.5660779643327034],
					[58.380630734849355, 1.5660779477544724],
					[58.38063074514028, 1.5660779387118022],
					[58.381925144884825, 1.5647140373107444],
					[58.381925145775377, 1.5647140371223158],
					[58.381925146566964, 1.5647140352383269],
					[58.381925146566964, 1.5647140352383269],
					[58.382925141741886, 1.5636223405082132],
					[58.383719506333662, 1.5628890740111148],
					[58.384658339058795, 1.5625445996967677],
					[58.385438877652497, 1.5625141003243377],
					[58.386155519925737, 1.5627029855290004],
					[58.38668599531357, 1.5630307702006163],
					[58.386686031233182, 1.5630307831981136],
					[58.38668610505102, 1.5630307967571031],
					[58.386686142850294, 1.5630307956228202],
					[58.387169442626451, 1.5629251943566016],
					[58.387169469145078, 1.5629251853095218],
					[58.38716951891665, 1.5629251576060605],
					[58.387169542268616, 1.5629251406455094],
					[58.387669541915002, 1.5624223416919987],
					[58.387669551216057, 1.5624223311390104],
					[58.387669567047482, 1.5624223089028002],
					[58.387669573676867, 1.5624222989154148],
					[58.388300174298685, 1.5613028978802224],
					[58.388300175881739, 1.5613028941113796],
					[58.388300179938405, 1.5613028863851555],
					[58.388300182412003, 1.5613028824277519],
					[58.388905779779748, 1.5599639878696339],
					[58.389894561336014, 1.5580862236658686],
					[58.390964019764937, 1.5570807627796923],
					[58.391877899047145, 1.5565612746761537],
					[58.391877912008901, 1.5565612650614578],
					[58.391877936151317, 1.5565612462095151],
					[58.391877947232921, 1.5565612352762328],
					[58.392877946880219, 1.5554695346057223],
					[58.392877951134707, 1.5554695302698947],
					[58.392877956972058, 1.5554695221644721],
					[58.392877961325638, 1.5554695195246944],
					[58.394689061246893, 1.5530307186009247],
					[58.394689063720207, 1.5530307146422171],
					[58.394689070447932, 1.5530307063471334],
					[58.394689072822104, 1.5530307006923059],
					[58.395816872860451, 1.551102901280409],
					[58.39581687523453, 1.5511028956252475],
					[58.395816880972404, 1.5511028858222404],
					[58.395816883346498, 1.551102880167087],
					[58.396622382611689, 1.5492750806138806],
					[58.396622388051853, 1.5492750657217365],
					[58.396622396260582, 1.5492750365042196],
					[58.396622398929971, 1.5492750204826493],
					[58.396747399233078, 1.5480861210156505],
					[58.396747399329954, 1.5480861072570273],
					[58.396747397643445, 1.5480860784215174],
					[58.396747396849783, 1.5480860648518733],
					[58.396478004226239, 1.546566704685413],
					[58.39660847685235, 1.5459918248875266],
					[58.397041808927604, 1.5457112678886433],
					[58.397041818425592, 1.5457112607207544],
					[58.397041834749992, 1.5457112469520506],
					[58.397041844247987, 1.5457112397841617],
					[58.397836218699403, 1.5448890645313642],
					[58.398613959208603, 1.5445084944689191],
					[58.399530627323692, 1.544383498025419],
					[58.399530640681398, 1.5443834951893025],
					[58.399530668188092, 1.5443834876316715],
					[58.399530682337058, 1.5443834829101351],
					[58.400722270122294, 1.5438501880255797],
					[58.401888957880431, 1.5434973917441661],
					[58.401888966686279, 1.5434973881565894],
					[58.401888985188506, 1.5434973807923189],
					[58.401888993994355, 1.5434973772047438],
					[58.402780693684946, 1.5430223778063505],
					[58.402780698829467, 1.5430223732786035],
					[58.402780710207544, 1.5430223674269108],
					[58.402780715352058, 1.5430223628991671],
					[58.403441814564729, 1.5425557623812749],
					[58.403441832570437, 1.5425557465335917],
					[58.403441864721977, 1.5425557105052909],
					[58.403441878867788, 1.5425556903246826],
					[58.404205779228874, 1.5410361902456633],
					[58.404205781602478, 1.5410361845884775],
					[58.404205787140889, 1.5410361713883629],
					[58.404205789415165, 1.5410361640346311],
					[58.404627988521959, 1.5397917632969735],
					[58.404627993269052, 1.539791751982287],
					[58.404627998211367, 1.5397917286024891],
					[58.404627999197771, 1.5397917146516158],
					[58.404727999417368, 1.5384750156981875],
					[58.404727999612476, 1.5384750036330634],
					[58.404727999211552, 1.5384749813886218],
					[58.404727997625663, 1.5384749697019979],
					[58.404605798161391, 1.53769166861377],
					[58.40460578232009, 1.5376916290393701],
					[58.404605735797034, 1.5376915581980168],
					[58.404605705313998, 1.5376915303241192],
					[58.404072321547233, 1.5373637392933244],
					[58.403444553926306, 1.5367776701157225],
					[58.402719567050845, 1.5356665911617231],
					[58.40271956408121, 1.5356665866403516],
					[58.40271955715211, 1.5356665760904564],
					[58.402719553291981, 1.5356665717583884],
					[58.402114069332917, 1.5349498898221248],
					[58.401619582714609, 1.5339193172587315],
					[58.401100185207873, 1.5326693231449953],
					[58.401100176099888, 1.532669306189514],
					[58.401100156301787, 1.5326692760500322],
					[58.40110014373127, 1.5326692615484347],
					[58.400494543827548, 1.5320387610216926],
					[58.400494529575404, 1.5320387485956566],
					[58.400494498598512, 1.5320387277044361],
					[58.400494481774317, 1.5320387175429233],
					[58.399869481988247, 1.531758117231814],
					[58.399869480207265, 1.5317581176106085],
					[58.399869476446462, 1.5317581149755906],
					[58.399869474566081, 1.5317581136580887],
					[58.399177874828609, 1.5314804144596088],
					[58.399177863844457, 1.5314804116435885],
					[58.399177842766655, 1.5314804058221765],
					[58.399177831782509, 1.5314804030061611],
					[58.398325076154052, 1.5313415104746648],
					[58.39753901723715, 1.5308054360949752],
					[58.396819533285395, 1.5302637496705851],
					[58.395889044001251, 1.5292970614198811],
					[58.395889041130864, 1.5292970585959906],
					[58.395889035290722, 1.5292970512520898],
					[58.395889031629302, 1.5292970503137213],
					[58.394983434045145, 1.5285026517321678],
					[58.393914058236149, 1.5274970731047501],
					[58.393305775015676, 1.526472104425481],
					[58.393022388063265, 1.5259138294473731],
					[58.392877997349416, 1.5253499622120836],
					[58.392752998195924, 1.5244360737092371],
					[58.392752986111851, 1.5244360367878094],
					[58.392752947994687, 1.5244359710651503],
					[58.392752922852047, 1.5244359420744118],
					[58.39238072320417, 1.5241470421160981],
					[58.392380691634315, 1.5241470265134209],
					[58.392380626320744, 1.5241470043562158],
					[58.392380591786093, 1.5241469996870947],
					[58.392174992438584, 1.5241554001875126],
					[58.39217497185814, 1.5241554028508169],
					[58.392174932776797, 1.5241554128860426],
					[58.392174914176351, 1.524155418562062],
					[58.391877713965648, 1.5242970189139275],
					[58.391877687157042, 1.5242970383562258],
					[58.391877641061733, 1.5242970825081628],
					[58.391877621083566, 1.5242971107990679],
					[58.391719221416793, 1.5246110106397268],
					[58.391719213606656, 1.5246110311894991],
					[58.391719202637816, 1.5246110747332169],
					[58.391719200270018, 1.5246110958417696],
					[58.391691499835161, 1.5260138869601294],
					[58.391544201776846, 1.5272888664361566],
					[58.391283110020694, 1.5284805336091187],
					[58.39085543128656, 1.5293304892850637],
					[58.390447054345913, 1.5298304618648928],
					[58.38994706465224, 1.5302887538142946],
					[58.389947051100485, 1.5302887687147257],
					[58.38994702607625, 1.5302888032242927],
					[58.389947016384738, 1.5302888224547024],
					[58.389722015696407, 1.53082212162974],
					[58.389722011840547, 1.530822132751273],
					[58.389722005909753, 1.5308221546156557],
					[58.389722002944353, 1.5308221655478425],
					[58.389572003164467, 1.5316610640400805],
					[58.38926921790793, 1.5332498868354618],
					[58.388902679030743, 1.5335276411026093],
					[58.388902674677659, 1.5335276437447281],
					[58.38890266785188, 1.5335276503462156],
					[58.388902664389299, 1.5335276527990707],
					[58.388108218004902, 1.5342609037487718],
					[58.387441692235221, 1.5342887007345161],
					[58.387441673435418, 1.5342887030128476],
					[58.387441637815598, 1.5342887105824388],
					[58.387441620204463, 1.5342887177586544],
					[58.386663820298885, 1.5346276176325999],
					[58.38666378992648, 1.5346276378208621],
					[58.386663738682913, 1.5346276864791393],
					[58.386663717911105, 1.5346277166448208],
					[58.386238717852912, 1.5355638171886112],
					[58.386238714688339, 1.5355638247280126],
					[58.386238710140248, 1.535563839428441],
					[58.38623870687632, 1.5355638452721903],
					[58.385819210347627, 1.5370693373977493],
					[58.38543872581738, 1.5380470965773789],
					[58.384599903774777, 1.5390442038992822],
					[58.383702886641409, 1.5390830958437391],
					[58.382586249564589, 1.537816567492402],
					[58.382586248674102, 1.5378165676815099],
					[58.382586246694409, 1.5378165646687787],
					[58.38258624570458, 1.5378165631624123],
					[58.381677945996636, 1.5368470623371149],
					[58.381677918681994, 1.5368470423903633],
					[58.381677859206413, 1.5368470121088602],
					[58.381677826154977, 1.5368470019632343],
					[58.380919426104519, 1.5367470023926146],
					[58.380919406215469, 1.5367470014672673],
					[58.380919364855053, 1.5367470033856139],
					[58.380919344373517, 1.5367470077355949],
					[58.380391644404256, 1.536899808370348],
					[58.380391609380183, 1.5368998261074935],
					[58.380391549131112, 1.536899874948974],
					[58.380391523806772, 1.5368999043579188],
					[58.380030424200903, 1.5375721052212488],
					[58.380030416980993, 1.5375721204860759],
					[58.380030406301849, 1.5375721536500457],
					[58.380030401753444, 1.5375721683475734],
					[58.379816504053139, 1.5389360563385299],
					[58.379397018086571, 1.5403555088657146],
					[58.379058196537528, 1.540805403623938],
					[58.37852779185782, 1.5408276007446726],
					[58.378527772167324, 1.5408276032075716],
					[58.378527734865173, 1.5408276128412604],
					[58.378527716263761, 1.5408276185057519],
					[58.378002716163152, 1.5410692183028993],
					[58.378002702212832, 1.5410692264127144],
					[58.378002675301943, 1.5410692441386469],
					[58.378002662440679, 1.5410692554500351],
					[58.37756926940844, 1.5414804492099319],
					[58.376933209640505, 1.5419859167812362],
					[58.37626948006249, 1.5421469756608004],
					[58.375916890795537, 1.5416805212905393],
					[58.37576960130491, 1.5406360965141626],
					[58.375872394147635, 1.5395806686377256],
					[58.376255767376534, 1.5389973093180327],
					[58.376255772322374, 1.5389973014030442],
					[58.376255779641795, 1.5389972878353848],
					[58.376255783597806, 1.5389972784142463],
					[58.376927983425823, 1.5374361817519302],
					[58.377489080621743, 1.5362333853910017],
					[58.37780298109292, 1.5355639852805556],
					[58.377802982675206, 1.5355639815117763],
					[58.37780298663094, 1.5355639720898651],
					[58.377802988113892, 1.5355639666258423],
					[58.378358483915711, 1.5340083790496037],
					[58.378919564619615, 1.5328918165925918],
					[58.379489043687826, 1.5323001399115124],
					[58.379489060404303, 1.5323001174784081],
					[58.379489084634159, 1.5323000694185491],
					[58.379489094819021, 1.5323000432240941],
					[58.379730795230529, 1.5312389438818841],
					[58.379730797404939, 1.5312389348374822],
					[58.37973079908231, 1.5312389173164553],
					[58.379730800366239, 1.5312389084613076],
					[58.379783499720254, 1.5298806088001267],
					[58.379783500411868, 1.5298806052201754],
					[58.379783500113597, 1.5298806001342158],
					[58.379783499914723, 1.5298805967435807],
					[58.379769600331535, 1.5288306021979716],
					[58.379825199635178, 1.5275139160802966],
					[58.379991898615799, 1.5260639226906929],
					[58.379991899108354, 1.5260639157200269],
					[58.37999189999401, 1.5260639000833975],
					[58.379991899596085, 1.5260638933021307],
					[58.379922399934756, 1.5241416948383033],
					[58.379883499846692, 1.5226555958001327],
					[58.379883500438574, 1.5226555905247756],
					[58.379883499050493, 1.5226555822377499],
					[58.379883498751916, 1.5226555771518473],
					[58.37970579934457, 1.5210860769270707],
					[58.379705798155463, 1.5210860720307322],
					[58.379705795777234, 1.5210860622380589],
					[58.379705794588141, 1.5210860573417269],
					[58.379194595059694, 1.5187888571819239],
					[58.379194589312867, 1.5187888360915227],
					[58.37919457178517, 1.5187887986281778],
					[58.379194560894724, 1.5187887820656842],
					[58.378633460967279, 1.5180248815410344],
					[58.378633437012766, 1.5180248574618946],
					[58.378633380796558, 1.5180248213707856],
					[58.378633351007117, 1.5180248053996128],
					[58.37819725096201, 1.5179109063552443],
					[58.378197206731784, 1.5179109054736772],
					[58.378197122038138, 1.5179109217889575],
					[58.378197082365617, 1.5179109371010446],
					[58.377877682233468, 1.5181442381980357],
					[58.377877671055749, 1.5181442474432498],
					[58.377877649789973, 1.5181442691344975],
					[58.377877639602332, 1.5181442798853402],
					[58.377605439808747, 1.5185054797747282],
					[58.377605437336484, 1.5185054837337681],
					[58.37760543071068, 1.5185054937261486],
					[58.377605428238411, 1.5185054976851886],
					[58.377041532446391, 1.5194470924671841],
					[58.376385936072381, 1.520394284220731],
					[58.375844239311384, 1.5211609813157729],
					[58.375072054009237, 1.5221554617854922],
					[58.374594343601366, 1.5225691834386645],
					[58.374250133928619, 1.5224970238986879],
					[58.373850186574224, 1.5217249266819768],
					[58.373491895657246, 1.5204277597991953],
					[58.373314098350349, 1.5191221741634475],
					[58.3731862992191, 1.5179916777163596],
					[58.3731862992191, 1.5179916777163596],
					[58.373186299119524, 1.5179916760213974],
					[58.373086298596817, 1.5171638755006287],
					[58.373086296019181, 1.5171638623201555],
					[58.373086289082941, 1.5171638363383317],
					[58.373086284823962, 1.5171638252319175],
					[58.372894585292464, 1.5166916245046027],
					[58.372894532532392, 1.5166915705235369],
					[58.372894404868056, 1.5166915153278251],
					[58.3728943289737, 1.5166915126078402],
					[58.372505529161721, 1.5168387121240234],
					[58.372505514322626, 1.5168387204314748],
					[58.372505484744067, 1.5168387387412745],
					[58.37250547079546, 1.5168387468591458],
					[58.372233171190459, 1.5170692466691984],
					[58.372233166937335, 1.5170692510068358],
					[58.372233158431072, 1.517069259682077],
					[58.372233154078351, 1.5170692623248014],
					[58.371891603361874, 1.5174331110508414],
					[58.371363875029232, 1.517499802275867],
					[58.371363866025014, 1.5174998024764377],
					[58.371363847325277, 1.5174998064568557],
					[58.371363837629765, 1.5174998102367048],
					[58.370747138092938, 1.5176998104545081],
					[58.370747129188302, 1.51769981234985],
					[58.370747110787292, 1.5176998214145898],
					[58.370747102081822, 1.5176998266996204],
					[58.369994301682759, 1.518124825477196],
					[58.369994291295882, 1.5181248328358277],
					[58.369994269631711, 1.5181248477426341],
					[58.369994261125342, 1.5181248564170293],
					[58.369288761173443, 1.5188109568075987],
					[58.369288757711153, 1.5188109592603503],
					[58.36928875177658, 1.5188109656711557],
					[58.369288750095215, 1.5188109677449189],
					[58.368652668812693, 1.5195359457524988],
					[58.367899900034693, 1.5200054268618493],
					[58.367099919411032, 1.5204331164458544],
					[58.366413832460807, 1.5206804125135613],
					[58.366413805353908, 1.5206804268590302],
					[58.36641375708011, 1.5206804645812786],
					[58.366413734923249, 1.5206804864528416],
					[58.365824839634804, 1.5215359807454223],
					[58.36519154876666, 1.5223054678700587],
					[58.363966582583998, 1.5236276324930245],
					[58.363141624106085, 1.5239665146451498],
					[58.363141620544155, 1.5239665154024302],
					[58.363141614410317, 1.5239665184221649],
					[58.363141610947871, 1.5239665208739481],
					[58.361497122976211, 1.5247804148997472],
					[58.360488850795406, 1.5251276046167055],
					[58.359755568880722, 1.5252442024411224],
					[58.359755543156226, 1.5252442096240935],
					[58.359755494876055, 1.5252442318939652],
					[58.359755473210832, 1.5252442467916101],
					[58.358524873385214, 1.5262581452249],
					[58.358524867450193, 1.5262581516323739],
					[58.358524856271728, 1.5262581608694854],
					[58.35852485122718, 1.5262581670877353],
					[58.357549873939391, 1.5273498403499621],
					[58.356680513778883, 1.5278220193594667],
					[58.3555138230859, 1.5283081149327216],
					[58.355513814379869, 1.5283081202125886],
					[58.355513795087411, 1.5283081294564351],
					[58.355513785590318, 1.5283081366196039],
					[58.354305486430086, 1.5291470366805473],
					[58.354305470007837, 1.5291470487444054],
					[58.354305442013434, 1.5291470787028976],
					[58.354305430341881, 1.5291470949034154],
					[58.352930433126538, 1.5313887907475794],
					[58.351980435867198, 1.5327415848732091],
					[58.351980432603312, 1.5327415907112667],
					[58.35198042765775, 1.5327415986213793],
					[58.35198042528436, 1.5327416042704196],
					[58.351169238861402, 1.5342165784332542],
					[58.350010967611702, 1.5353581490398971],
					[58.348938798179084, 1.536188726683178],
					[58.34733883092499, 1.5368665126542913],
					[58.346374940394078, 1.5371692084001554],
					[58.346374900324406, 1.5371692323316632],
					[58.34637483473427, 1.5371692976866789],
					[58.34637481000501, 1.5371693372275954],
					[58.346108109583597, 1.5379666367856026],
					[58.346108106322497, 1.5379666580546858],
					[58.346108100889367, 1.537966703791501],
					[58.346108102180104, 1.5379667258101091],
					[58.346185897972227, 1.5385777945607249],
					[58.346055408776358, 1.5393249332339161],
					[58.345560924578919, 1.5402193034235239],
					[58.345063731747089, 1.5411137907292893],
					[58.344588739923779, 1.5417470799476016],
					[58.344588730822736, 1.5417470938794791],
					[58.344588717172485, 1.541747122493204],
					[58.344588709951708, 1.5417471377412872],
					[58.344366510055877, 1.5424110377862355],
					[58.34436650521144, 1.5424110628186845],
					[58.344366503043545, 1.5424111181485487],
				],
			],
		},
		{
			fldNpdidField: "4444332",
			wlbNpdidWellbore: "1533",
			fldName: "ALVE",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6507/3-1",
			fldDiscoveryYear: "1990",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=4444332",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=4444332",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "159 B",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=1533",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "2819945",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Sandnessjøen",
			fldAreaGeometryWKT: [
				[
					[65.98764699964093, 7.9932860997689996],
					[65.987647000102356, 7.993708300164748],
					[65.987647000102356, 7.993708300164748],
					[65.987647000131133, 7.9937083045682957],
					[65.987647000131133, 7.9937083045682957],
					[65.987652599647319, 7.9939333046064434],
					[65.987652599661715, 7.9939333068082385],
					[65.987652599690492, 7.9939333112117863],
					[65.987652600601862, 7.9939333133782435],
					[65.987680400804464, 7.994322214719535],
					[65.987680400833241, 7.9943222191230898],
					[65.987680401802152, 7.9943222300966887],
					[65.987680402727875, 7.994322234464958],
					[65.987727603191857, 7.9945917326785567],
					[65.987747002189309, 7.9947139310668414],
					[65.987747003115061, 7.9947139354351364],
					[65.987747005877878, 7.9947139463381749],
					[65.987747006818012, 7.9947139529082527],
					[65.987835907385758, 7.9950472509781854],
					[65.987835908311467, 7.9950472553465088],
					[65.987835911074299, 7.9950472662496406],
					[65.987835912896998, 7.9950472705826616],
					[65.987944212890653, 7.9953333712192238],
					[65.987944215624722, 7.9953333777188131],
					[65.987944221989821, 7.9953333906827408],
					[65.987944224723847, 7.9953333971823524],
					[65.988041525341615, 7.9955083976158861],
					[65.988041526238618, 7.9955083975806218],
					[65.988041527149932, 7.9955083997471661],
					[65.988091527079249, 7.9955945006485525],
					[65.988091528901961, 7.9955945049816544],
					[65.988091533415599, 7.9955945092089928],
					[65.988091535238283, 7.9955945135420965],
					[65.98821373527862, 7.9957723121992696],
					[65.988213737998294, 7.9957723164971704],
					[65.988213744334615, 7.9957723250576764],
					[65.988213747054274, 7.9957723293555789],
					[65.988363746996498, 7.9959501296155722],
					[65.988363752421478, 7.9959501360096068],
					[65.988363763242717, 7.9959501443939596],
					[65.988363768667654, 7.9959501507879942],
					[65.988733169284075, 7.9962723511995843],
					[65.988733170181064, 7.9962723511643459],
					[65.988733171092392, 7.9962723533309772],
					[65.988733171989381, 7.9962723532957369],
					[65.988927671811311, 7.9964334530701864],
					[65.988927676324892, 7.9964334572977922],
					[65.988927682632479, 7.9964334614549282],
					[65.98892768623476, 7.9964334635159009],
					[65.98919988613919, 7.9966223642031427],
					[65.989199887036193, 7.9966223641679148],
					[65.989199887947521, 7.9966223663345977],
					[65.98919988884451, 7.9966223662993698],
					[65.989416583853398, 7.9967668632673368],
					[65.9896609762704, 7.9969501561552203],
					[65.989830462006736, 7.9970918445496455],
					[65.989983148876817, 7.9972584309729111],
					[65.990058139178132, 7.9973501179895097],
					[65.990352630153168, 7.9977918061900528],
					[65.990510927406518, 7.9980640012885686],
					[65.990510929214835, 7.9980640034202546],
					[65.990510932845737, 7.9980640098856099],
					[65.99051093465404, 7.9980640120172746],
					[65.990694233217866, 7.9983307104481867],
					[65.990735930095966, 7.9983973057490791],
					[65.990735943665314, 7.9983973228379481],
					[65.990735972569283, 7.9983973525412306],
					[65.990735988800921, 7.9983973651205122],
					[65.990777667364924, 7.9984251513329081],
					[65.990960949603391, 7.9986334310459872],
					[65.990960971259852, 7.9986334500207406],
					[65.990961018103476, 7.9986334790213736],
					[65.990961043304907, 7.9986334912492989],
					[65.991063838384747, 7.9986640895475185],
					[65.991111001136645, 7.9986807761394934],
					[65.991185973628973, 7.9987418552307927],
					[65.991185985347016, 7.9987418635820902],
					[65.991186008768807, 7.9987418780826438],
					[65.99118602226649, 7.9987418841615474],
					[65.991402698547006, 7.9988334742164993],
					[65.991638780182598, 7.9990112607944983],
					[65.991638793694605, 7.9990112690757913],
					[65.991638823395249, 7.999011283330792],
					[65.991638838686896, 7.9990112893396681],
					[65.991716610804531, 7.9990362821703132],
					[65.991863787851699, 7.9991362649243891],
					[65.991863808568198, 7.9991362773289536],
					[65.991863855325789, 7.9991362931186387],
					[65.991863877793307, 7.9991362988465076],
					[65.991991653789214, 7.9991501963051634],
					[65.992174931668245, 7.9992168885592454],
					[65.992174934359184, 7.9992168884538204],
					[65.992311017806699, 7.9992640836942384],
					[65.992535993706284, 7.999389070301465],
					[65.99276378242368, 7.9995473629341376],
					[65.993060973970003, 7.99977515524475],
					[65.993213770248886, 7.9999057515098775],
					[65.99321377205716, 7.9999057536418983],
					[65.993213776570684, 7.9999057578708399],
					[65.993213780172908, 7.9999057599326218],
					[65.99343878012094, 8.0000751604508373],
					[65.993438796338154, 8.0000751708302076],
					[65.993438829626598, 8.0000751849469811],
					[65.993438846726491, 8.0000751930889589],
					[65.993561034214849, 8.0001084884182632],
					[65.993663821372067, 8.0001529850177047],
					[65.993663836649361, 8.0001529888254375],
					[65.993663870791906, 8.0001529963004128],
					[65.993663888760153, 8.0001530000028112],
					[65.993808269710982, 8.0001612992258337],
					[65.994008247861004, 8.0002112928342406],
					[65.994113821154656, 8.0002418848065417],
					[65.994327682985769, 8.0003640633405553],
					[65.994427660995342, 8.0004529444122738],
					[65.994541555341442, 8.0005723386202714],
					[65.994541558046649, 8.000572340717353],
					[65.994541565265408, 8.0005723470437093],
					[65.994541567970643, 8.000572349140791],
					[65.994702662938906, 8.0007140464602511],
					[65.994908151105491, 8.000919534034983],
					[65.995135945399923, 8.0012001269476531],
					[65.995135945399923, 8.0012001269476531],
					[65.995135946296884, 8.0012001269125772],
					[65.995260945892724, 8.0013501271223895],
					[65.995260951317448, 8.0013501335192689],
					[65.995260961255696, 8.0013501441456789],
					[65.995260965769177, 8.001350148375213],
					[65.995372053564452, 8.0014501371034434],
					[65.995466545319843, 8.0015668266315991],
					[65.995466556155037, 8.0015668372231143],
					[65.995466581427664, 8.0015668604683423],
					[65.995466594056793, 8.0015668709897305],
					[65.995510987231427, 8.0015945657330061],
					[65.995691579562134, 8.0017307608022978],
					[65.995691593073971, 8.0017307690863273],
					[65.995691621877356, 8.0017307833817775],
					[65.995691637168861, 8.0017307893932177],
					[65.996141620833299, 8.0018807847305418],
					[65.99630269320437, 8.0019668697998867],
					[65.996366566282035, 8.0020140491489613],
					[65.996441553586536, 8.0020945349173509],
					[65.9965942539162, 8.0022584350641868],
					[65.99659425934091, 8.0022584414615885],
					[65.996594269279072, 8.0022584520889044],
					[65.9965942746895, 8.0022584562837764],
					[65.996819275192152, 8.0024390562693064],
					[65.996819298613616, 8.0024390707764095],
					[65.996819350795562, 8.0024390929726525],
					[65.996819378644815, 8.0024390984942588],
					[65.997283279069322, 8.0024890982693808],
					[65.99728328265725, 8.0024890981292423],
					[65.997283290744377, 8.0024891000165397],
					[65.99728329612627, 8.0024890998063309],
					[65.998441695419345, 8.0025140992371639],
					[65.999030595276807, 8.0025279999388026],
					[65.999030657951437, 8.0025279798641602],
					[65.999030757045688, 8.0025279032839087],
					[65.999030794376552, 8.002527848946043],
					[65.999061294365873, 8.0024083478942494],
					[65.999061296914121, 8.0024083257614738],
					[65.999061298408435, 8.0024082794332774],
					[65.99906129467783, 8.0024082575457616],
					[65.99894189795036, 8.0018610707810645],
					[65.998914099326029, 8.0015138841554094],
					[65.998914099297465, 8.0015138797498899],
					[65.998914098329095, 8.0015138687711893],
					[65.998914097403528, 8.00151386440076],
					[65.998877998789013, 8.0013138757456321],
					[65.998841900049584, 8.0007971865995611],
					[65.998841899124002, 8.0007971822291708],
					[65.998841899095424, 8.0007971778236833],
					[65.998841898169871, 8.0007971734532735],
					[65.998830799136172, 8.0007110795174281],
					[65.9987974001792, 8.0002166895986342],
					[65.99879460009835, 8.0001471924857412],
					[65.998794600084054, 8.0001471902829842],
					[65.998794599158444, 8.0001471859126241],
					[65.998794599144162, 8.0001471837098848],
					[65.9987640993035, 7.9997971850509835],
					[65.998741898716361, 7.9995138841702298],
					[65.99874189870205, 7.9995138819674967],
					[65.99874189868774, 7.9995138797647627],
					[65.998741898673416, 7.9995138775620278],
					[65.998711298992262, 7.9992471776462919],
					[65.998711298977966, 7.9992471754435588],
					[65.998711298066638, 7.9992471732759602],
					[65.998711298052328, 7.9992471710732289],
					[65.998650197585562, 7.998808272786432],
					[65.998650197571251, 7.9988082705837051],
					[65.998650197571251, 7.9988082705837051],
					[65.99861409809715, 7.9985693710684096],
					[65.998614097185865, 7.9985693689008617],
					[65.99861409715723, 7.9985693644953963],
					[65.998614096245888, 7.9985693623278493],
					[65.998589095856659, 7.998441662005864],
					[65.998589095842334, 7.9984416598031434],
					[65.998589094931049, 7.9984416576356026],
					[65.998589094916724, 7.9984416554328819],
					[65.998494595259046, 7.9980249564301422],
					[65.998494594333394, 7.9980249520599118],
					[65.998494593407742, 7.9980249476896814],
					[65.998494591585114, 7.9980249433546255],
					[65.998358491857687, 7.9975582445181344],
					[65.998358490932006, 7.9975582401479457],
					[65.99835848909504, 7.9975582336102455],
					[65.998358488183726, 7.9975582314427545],
					[65.998275188338496, 7.9973305313912793],
					[65.998275187427168, 7.9973305292237837],
					[65.998275187427168, 7.9973305292237837],
					[65.998275185618866, 7.9973305270915249],
					[65.99812238590718, 7.996936029067137],
					[65.998122385892842, 7.996936026864458],
					[65.9981223849815, 7.9969360246970096],
					[65.998122384070172, 7.996936022529562],
					[65.998008484693756, 7.9966638225761262],
					[65.997897387411626, 7.9963943306379237],
					[65.997808489331902, 7.9961332351468393],
					[65.997808489331902, 7.9961332351468393],
					[65.997697389845598, 7.9958082378038284],
					[65.997608490110821, 7.9955360400826505],
					[65.99753629225259, 7.9953082447236019],
					[65.997464092883959, 7.9950332495540399],
					[65.997464092869606, 7.9950332473514187],
					[65.997464091958264, 7.9950332451840946],
					[65.997464091958264, 7.9950332451840946],
					[65.997386293247416, 7.9947638477178318],
					[65.997291893948002, 7.9943860526820538],
					[65.997291893933621, 7.9943860504794477],
					[65.997291893022236, 7.9943860483121618],
					[65.997175192671861, 7.9939471484157814],
					[65.997175192657465, 7.9939471462131868],
					[65.997175191746109, 7.9939471440459258],
					[65.997175191746109, 7.9939471440459258],
					[65.997052992110568, 7.9935332437304751],
					[65.997052992096187, 7.9935332415278904],
					[65.997052991199226, 7.9935332415632372],
					[65.997052991184816, 7.9935332393606542],
					[65.996955790773612, 7.9932249390281527],
					[65.996955789876623, 7.9932249390635128],
					[65.996955789862199, 7.9932249368609369],
					[65.996955788950842, 7.9932249346937203],
					[65.996922391845246, 7.9931277442393665],
					[65.996852993825598, 7.9928388529803982],
					[65.996852993811203, 7.9928388507778303],
					[65.996852992885394, 7.9928388464080715],
					[65.996852991974038, 7.9928388442408762],
					[65.996752991675706, 7.9924888439572959],
					[65.996752991675706, 7.9924888439572959],
					[65.996752991675706, 7.9924888439572959],
					[65.996633491708835, 7.9920888449963794],
					[65.996525191862673, 7.9917138446768252],
					[65.99652519093685, 7.9917138403071615],
					[65.996525188188215, 7.9917138316032261],
					[65.996525186351008, 7.9917138250664372],
					[65.996422386304161, 7.9914555249021646],
					[65.996422385407158, 7.991455524937586],
					[65.996241884781099, 7.9910166245858187],
					[65.996241882958273, 7.9910166202516661],
					[65.996241879312635, 7.9910166115833379],
					[65.996241876592833, 7.9910166072846192],
					[65.996114076560858, 7.9907749072238738],
					[65.99611407564943, 7.9907749050568171],
					[65.996114074752413, 7.9907749050922634],
					[65.996114074738003, 7.9907749028897399],
					[65.995914080725683, 7.9904138131184403],
					[65.995691891342418, 7.9898721400500232],
					[65.995614095168776, 7.9895249566294275],
					[65.995614094242896, 7.9895249522599796],
					[65.995614092391122, 7.9895249435211033],
					[65.995614089656797, 7.9895249370201933],
					[65.995550189838411, 7.9893277386001795],
					[65.99555018800109, 7.9893277320638116],
					[65.995550183429486, 7.9893277190265479],
					[65.995550179798187, 7.9893277125611499],
					[65.995386279697172, 7.9889916119065871],
					[65.995386274257456, 7.9889916033098487],
					[65.995386263363571, 7.9889915839139354],
					[65.99538625702688, 7.988991575352725],
					[65.995225157009926, 7.9887887751712192],
					[65.995225151599101, 7.988788770979447],
					[65.995225142557004, 7.988788760322433],
					[65.995225138043182, 7.9887887560951478],
					[65.995158464819639, 7.988724880753173],
					[65.994947387768619, 7.9882777308045005],
					[65.994800193847951, 7.9877499527681364],
					[65.994750196200854, 7.9875110604355939],
					[65.994700196013071, 7.9872610618260156],
					[65.994700189603876, 7.9872610422535564],
					[65.994700175902935, 7.9872610053465403],
					[65.994700165920264, 7.9872609881187184],
					[65.994633466166974, 7.9871609885229198],
					[65.994633456213279, 7.9871609756999256],
					[65.994633432718004, 7.9871609501962118],
					[65.994633420087851, 7.987160939682294],
					[65.994511231181775, 7.987069249913735],
					[65.994441849966606, 7.9869998663202404],
					[65.994364057855961, 7.9868998765946921],
					[65.994364041608918, 7.9868998618186291],
					[65.994364007320854, 7.9868998323376319],
					[65.994363988397382, 7.9868998198706462],
					[65.994250154083915, 7.9868442525645573],
					[65.994155783343942, 7.9866277203712421],
					[65.994155778801058, 7.9866277117398337],
					[65.994155767906889, 7.986627692345861],
					[65.99415576336402, 7.9866276837144543],
					[65.994072387336331, 7.9865110171235916],
					[65.99402239600694, 7.9862721579316815],
					[65.994022386877646, 7.9862721340621299],
					[65.994022363237235, 7.9862720865366166],
					[65.994022347843611, 7.9862720651185732],
					[65.993969547944616, 7.9862137660987615],
					[65.993969516376367, 7.986213740916984],
					[65.993969445283071, 7.9862137084923646],
					[65.99396940664046, 7.9862136990116079],
					[65.993797207173785, 7.9862081004467358],
					[65.993797190131204, 7.9862081011231476],
					[65.993797156075004, 7.9862081068805679],
					[65.993797139943908, 7.9862081097236777],
					[65.993744340338338, 7.9862248081559963],
					[65.993744312619, 7.9862248224734005],
					[65.993744264385157, 7.986224855227956],
					[65.993744242091168, 7.9862248759386434],
					[65.993574881165102, 7.9864415278371856],
					[65.993416640328107, 7.9864915090226392],
					[65.993416628696366, 7.9864915138898418],
					[65.993416607226862, 7.9864915235530871],
					[65.99341659559515, 7.9864915284202889],
					[65.993324913960322, 7.986547018701577],
					[65.993127734164048, 7.9866165119597072],
					[65.993127721635332, 7.9866165168624024],
					[65.993127701062775, 7.9866165264898266],
					[65.993127690342519, 7.9866165335235912],
					[65.993038789673349, 7.9866748333272239],
					[65.993038786996934, 7.9866748356362258],
					[65.993038778953107, 7.9866748403609398],
					[65.993038775394169, 7.9866748449077383],
					[65.992902708688675, 7.9867831178046629],
					[65.99282495076875, 7.9868026062918069],
					[65.992824941813438, 7.9868026088498025],
					[65.992824923020365, 7.986802616203609],
					[65.992824913182574, 7.9868026209993994],
					[65.99268054698679, 7.9868720031366696],
					[65.992530585361223, 7.9868831008616397],
					[65.992530581773323, 7.9868831010039374],
					[65.99253057370052, 7.9868831013241115],
					[65.992530570112606, 7.986883101466411],
					[65.992233269913683, 7.9869276009770687],
					[65.9922332609584, 7.9869276035349586],
					[65.992233243047792, 7.986927608650765],
					[65.992233234092481, 7.9869276112086576],
					[65.991933234436345, 7.9870331108102057],
					[65.991933230848431, 7.9870331109524804],
					[65.991933226378009, 7.9870331133324619],
					[65.991933222804604, 7.9870331156768746],
					[65.991647123279705, 7.9871526148291485],
					[65.991647118809297, 7.987152617209083],
					[65.99164711164795, 7.9871526196957072],
					[65.991647108074531, 7.9871526220400959],
					[65.991561018242237, 7.9871970185100443],
					[65.991113845318068, 7.987372007169518],
					[65.991083263826042, 7.9873776026454459],
					[65.991083248606358, 7.9873776076539977],
					[65.991083219063995, 7.9873776176355964],
					[65.991083204755768, 7.9873776248106934],
					[65.990799905234269, 7.9875304232028519],
					[65.990799901660822, 7.9875304255470914],
					[65.99079989541093, 7.9875304302000369],
					[65.990799890940551, 7.987530432579824],
					[65.990666600954313, 7.9876165265747732],
					[65.990516612575163, 7.9876915194822296],
					[65.99034162181448, 7.9877720152309983],
					[65.99002493372997, 7.9878942102540789],
					[65.989738840572784, 7.9879831083038928],
					[65.989738835205387, 7.9879831107190311],
					[65.989738825367567, 7.9879831155137992],
					[65.989738820000156, 7.9879831179289376],
					[65.989547119641287, 7.9880665175923253],
					[65.989547110685962, 7.9880665201495411],
					[65.989547092818754, 7.9880665318697952],
					[65.989547083877909, 7.9880665366289438],
					[65.989324884182409, 7.9882248361156369],
					[65.989324879726453, 7.9882248406970779],
					[65.989324871697065, 7.9882248476225417],
					[65.989324868123603, 7.9882248499665467],
					[65.98913876765566, 7.9883887498099764],
					[65.98913876677311, 7.9883887520474142],
					[65.989138764979174, 7.9883887521184453],
					[65.989138764979174, 7.9883887521184453],
					[65.989069265027908, 7.9884526533456581],
					[65.989069256998491, 7.988452660270962],
					[65.989069242748087, 7.9884526762524741],
					[65.989069235630083, 7.988452685344158],
					[65.988877635838691, 7.9887248849215622],
					[65.988877633191166, 7.9887248916337033],
					[65.988877626087628, 7.988724902927137],
					[65.988877621631687, 7.9887249075084306],
					[65.988691521872099, 7.9890860069226459],
					[65.988691521004043, 7.9890860113618567],
					[65.988691518342009, 7.9890860158720729],
					[65.988691516562511, 7.9890860181449144],
					[65.98846091737866, 7.9896082193536087],
					[65.988460915599163, 7.9896082216263951],
					[65.988460913834061, 7.9896082261010219],
					[65.988460912951552, 7.9896082283383558],
					[65.988274812948859, 7.9900943279767107],
					[65.988274812066308, 7.9900943302139922],
					[65.988274811198224, 7.9900943346531168],
					[65.988274810315673, 7.9900943368903983],
					[65.9880942103824, 7.9906332366938182],
					[65.988094210396852, 7.9906332388956294],
					[65.988094207734804, 7.9906332434055765],
					[65.988094207749228, 7.9906332456073867],
					[65.988022007740312, 7.9908888449904083],
					[65.988022006857747, 7.9908888472276391],
					[65.988022005975196, 7.9908888494648904],
					[65.988022005989635, 7.9908888516666945],
					[65.987938706421716, 7.9912166498997994],
					[65.987938706436154, 7.9912166521016186],
					[65.987938704671038, 7.991216656576043],
					[65.987938704671038, 7.991216656576043],
					[65.987888704608423, 7.9914443578089456],
					[65.987888703711434, 7.9914443578443528],
					[65.987888703725858, 7.9914443600461462],
					[65.987888703725858, 7.9914443600461462],
					[65.987810904193239, 7.9918360615731459],
					[65.98781090331066, 7.9918360638103465],
					[65.987810902442504, 7.991836068249313],
					[65.987810901574392, 7.9918360726882796],
					[65.987785902188179, 7.9920138709388553],
					[65.987738701554065, 7.9923249698091974],
					[65.987738701568475, 7.9923249720109775],
					[65.987738701568475, 7.9923249720109775],
					[65.987738701582884, 7.9923249742127576],
					[65.987697001893906, 7.9926416736362667],
					[65.987697001011313, 7.9926416758734291],
					[65.987697001040118, 7.9926416802769831],
					[65.987697001054528, 7.9926416824787605],
					[65.987666501332242, 7.9929916837628125],
					[65.987666501332242, 7.9929916837628125],
					[65.987666501346666, 7.9929916859645882],
					[65.987666499552688, 7.9929916860352925],
					[65.987646999554528, 7.9932860865583404],
					[65.987646999583319, 7.9932860909618864],
					[65.98764699962652, 7.9932860975672257],
					[65.98764699964093, 7.9932860997689996],
				],
				[
					[65.93798870019279, 7.7863417112241535],
					[65.938010900420139, 7.7867278109615539],
					[65.938010901369111, 7.7867278175111361],
					[65.938010903284365, 7.7867278328076353],
					[65.938010904233295, 7.7867278393572201],
					[65.938102600610904, 7.7871861273428946],
					[65.938116500221909, 7.7873889134140999],
					[65.938116501118827, 7.7873889133716299],
					[65.938147000345651, 7.787819408896155],
					[65.938155400418353, 7.7881611044938968],
					[65.938155399556095, 7.7881611089310905],
					[65.938155401419252, 7.7881611176356884],
					[65.938155400556951, 7.7881611220728626],
					[65.938216500766728, 7.7887056228831719],
					[65.938238701059603, 7.7889139202037185],
					[65.938238701991182, 7.7889139245560468],
					[65.938238702957321, 7.7889139333031467],
					[65.938238702991967, 7.7889139376979131],
					[65.938308103028106, 7.7892806349748787],
					[65.938399802753736, 7.7897861369138859],
					[65.938399804599513, 7.7897861434212761],
					[65.938399809222517, 7.7897861607884842],
					[65.938399811965226, 7.7897861672534869],
					[65.938477604575681, 7.790002851058345],
					[65.938574799962566, 7.7906278167440357],
					[65.93858309985103, 7.791097202929798],
					[65.938583102731926, 7.7910972269742667],
					[65.938583114754891, 7.7910972725694077],
					[65.938583122982791, 7.7910972919649959],
					[65.938688706765674, 7.7912972620914349],
					[65.938774801854876, 7.7918806276256865],
					[65.93880540164119, 7.7921194255845529],
					[65.938805401675722, 7.7921194299794019],
					[65.938805404435598, 7.7921194386422004],
					[65.938805405367077, 7.7921194429947436],
					[65.938819204861218, 7.792183341338216],
					[65.938819205809949, 7.7921833478882094],
					[65.938819207655612, 7.7921833543958785],
					[65.938819208604357, 7.7921833609458711],
					[65.938927608250353, 7.792530654571018],
					[65.938988706925841, 7.7927611517228383],
					[65.938988726968901, 7.7927611903466065],
					[65.938988781319509, 7.7927612559303325],
					[65.938988815627056, 7.7927612828903321],
					[65.939024916084179, 7.7927779826389081],
					[65.939024916981111, 7.7927779825966237],
					[65.939024917878058, 7.7927779825543402],
					[65.939024918774962, 7.7927779825120576],
					[65.939230421804794, 7.7928695383899216],
					[65.939299803142575, 7.7932611346786258],
					[65.939299807765224, 7.7932611520470543],
					[65.939299820598237, 7.7932611866148607],
					[65.939299828808601, 7.7932612038142208],
					[65.93968871760319, 7.7939028871138962],
					[65.939841511556835, 7.7943333670909487],
					[65.939841511556835, 7.7943333670909487],
					[65.939841513385147, 7.7943333714015184],
					[65.939841514282094, 7.7943333713592864],
					[65.940147013255299, 7.7951194703317404],
					[65.940255411326945, 7.7954139652424557],
					[65.940574808639823, 7.7964222586328349],
					[65.940597008096674, 7.7964972576332086],
					[65.940597009010816, 7.7964972597886337],
					[65.940597011753212, 7.7964972662549092],
					[65.940597012684563, 7.7964972706079374],
					[65.940605412554447, 7.7965194709103747],
					[65.941002613312435, 7.7975778695817572],
					[65.941002614243729, 7.7975778739348742],
					[65.941002617900182, 7.7975778825568876],
					[65.941002619728422, 7.7975778868678844],
					[65.941063717604194, 7.7977056816400863],
					[65.941466515874907, 7.798655679071965],
					[65.941466516789021, 7.7986556812275394],
					[65.941466520428264, 7.7986556876522046],
					[65.941466522256462, 7.798655691963357],
					[65.941519222174989, 7.7987583920727799],
					[65.941519223071936, 7.7987583920307006],
					[65.941519224900148, 7.7987583963418681],
					[65.941519225814233, 7.7987583984974522],
					[65.941710925193007, 7.7991000968378348],
					[65.941977619969336, 7.799580687968259],
					[65.942038715008977, 7.7997278767530807],
					[65.942038716837146, 7.7997278810644257],
					[65.942038722287379, 7.7997278896029814],
					[65.942038724115562, 7.7997278939143264],
					[65.942427623557151, 7.8004500947477151],
					[65.94242762536814, 7.8004500968614137],
					[65.942427628093242, 7.8004501011308323],
					[65.942427629921397, 7.8004501054422954],
					[65.942641527800021, 7.8007973029966289],
					[65.942885927237171, 7.8012222998220562],
					[65.942885927254324, 7.8012223020198386],
					[65.942885928151242, 7.8012223019778437],
					[65.943266527887985, 7.8018668028194957],
					[65.943266528784903, 7.801866802777524],
					[65.943266529698974, 7.8018668049333639],
					[65.943266530613002, 7.8018668070892279],
					[65.943344227927824, 7.8019890029112782],
					[65.943519225688505, 7.8023000989034834],
					[65.943519225688505, 7.8023000989034834],
					[65.943519228396411, 7.8023001009754429],
					[65.943519229310439, 7.8023001031313228],
					[65.943794227638108, 7.8027557014660314],
					[65.943899824599114, 7.8029389962875042],
					[65.944252624248762, 7.8036000949334472],
					[65.944472022027696, 7.8040111899682838],
					[65.944702618946152, 7.804486185548587],
					[65.944977616682721, 7.8050861814701822],
					[65.945160916219294, 7.8055056780860941],
					[65.945441514602905, 7.8061639753008203],
					[65.945616514048695, 7.8066028740302329],
					[65.945616514945641, 7.8066028739884201],
					[65.945877614373472, 7.8072444729023855],
					[65.946074813818896, 7.8077528719642437],
					[65.946074813818896, 7.8077528719642437],
					[65.946074813835963, 7.8077528741623041],
					[65.946074814732896, 7.8077528741205313],
					[65.946305413603838, 7.8083222731576738],
					[65.946533113392988, 7.8089111719438309],
					[65.946533114289892, 7.8089111719020972],
					[65.946533114306916, 7.8089111741001993],
					[65.94673041382498, 7.8094028735695415],
					[65.946730414738894, 7.8094028757259455],
					[65.94673041565288, 7.8094028778823468],
					[65.946730415669904, 7.8094028800804853],
					[65.946991515867253, 7.8100056796911748],
					[65.947197016254265, 7.8104806791302108],
					[65.947197017151225, 7.8104806790885295],
					[65.947197018065182, 7.8104806812450089],
					[65.947197018082178, 7.8104806834431688],
					[65.947449818209819, 7.811036183575359],
					[65.947449819106737, 7.8110361835336981],
					[65.94744982003769, 7.8110361878884014],
					[65.947449820951633, 7.8110361900449208],
					[65.947708120836197, 7.8115556900248171],
					[65.947905420841863, 7.8119556869758346],
					[65.947905420858831, 7.8119556891740576],
					[65.947905421772745, 7.8119556913306507],
					[65.947905422686674, 7.8119556934872412],
					[65.948258123338846, 7.8126306939397523],
					[65.948258123338846, 7.8126306939397523],
					[65.948355422829053, 7.8128139948853246],
					[65.948355423725957, 7.8128139948437259],
					[65.948355424639871, 7.8128139970003874],
					[65.948355426433736, 7.8128139969171873],
					[65.948691524671617, 7.8134083981007629],
					[65.948813724268703, 7.8136333957986084],
					[65.948813725165621, 7.8136333957570363],
					[65.948813725182575, 7.8136333979553374],
					[65.948813725182575, 7.8136333979553374],
					[65.948852625262063, 7.8137028983870911],
					[65.948852626158981, 7.8137028983455208],
					[65.948852626158981, 7.8137028983455208],
					[65.948852627072895, 7.8137029005022747],
					[65.949272025892199, 7.8144333986950523],
					[65.949463724710455, 7.8147722966774928],
					[65.949722025432322, 7.8152417950840114],
					[65.950049824323131, 7.8158444944755558],
					[65.950180423237768, 7.8160861928695207],
					[65.95059982055767, 7.8169194896822853],
					[65.95059982236846, 7.816919491797842],
					[65.950599824196146, 7.8169194961118356],
					[65.950599825110018, 7.8169194982688301],
					[65.950638721781118, 7.8169889910282571],
					[65.950897018164696, 7.8175472846514458],
					[65.950897019061657, 7.8175472846100069],
					[65.950897019975471, 7.8175472867670512],
					[65.950897019975471, 7.8175472867670512],
					[65.951088718342007, 7.8179444838788532],
					[65.951110916665883, 7.8179944820631837],
					[65.951110917562787, 7.8179944820217617],
					[65.951110919373562, 7.8179944841374365],
					[65.951110920287391, 7.8179944862945137],
					[65.951544219650529, 7.8188944864178946],
					[65.951630420349488, 7.8190722872930412],
					[65.951630420349488, 7.8190722872930412],
					[65.951630421246449, 7.819072287251652],
					[65.951630421263317, 7.8190722894502001],
					[65.952002621153497, 7.8198222895576039],
					[65.952163721305951, 7.8201472894488733],
					[65.952163722219737, 7.8201472916061174],
					[65.952460921990507, 7.8207250901155172],
					[65.952713721235156, 7.8212222904614261],
					[65.952910920556278, 7.8216222873574131],
					[65.95291092238385, 7.8216222916721296],
					[65.952910924211409, 7.8216222959868471],
					[65.952910925108355, 7.8216222959455459],
					[65.95328592488994, 7.8222944965062293],
					[65.953285924906751, 7.8222944987049416],
					[65.953285925803712, 7.8222944986636644],
					[65.953369225804778, 7.8224417978459639],
					[65.953369225821618, 7.8224418000446638],
					[65.953369227632322, 7.8224418021608191],
					[65.953369227632322, 7.8224418021608191],
					[65.953574827892353, 7.8227862027719848],
					[65.953574828789314, 7.8227862027307218],
					[65.953574828806111, 7.8227862049294403],
					[65.953819229012609, 7.8231890032152691],
					[65.953819229926367, 7.8231890053727602],
					[65.953819231737057, 7.823189007489006],
					[65.953819231753897, 7.8231890096877441],
					[65.953933131754724, 7.8233640075017474],
					[65.954277631159755, 7.8239057059526651],
					[65.954277631176566, 7.8239057081514432],
					[65.954277632073513, 7.8239057081102201],
					[65.954277632073513, 7.8239057081102201],
					[65.954619230206049, 7.8244334065910666],
					[65.954727629183282, 7.8246140020232797],
					[65.954985928924273, 7.8250445020465129],
					[65.954985928941085, 7.825044504245354],
					[65.954985929838003, 7.8250445042041692],
					[65.9549859298548, 7.8250445064030112],
					[65.955185928735816, 7.8253640030879499],
					[65.955269227897787, 7.8255029028530618],
					[65.955269228794734, 7.825502902811893],
					[65.955269228811531, 7.8255029050107572],
					[65.955641528933128, 7.8261168030483956],
					[65.955641529846858, 7.8261168052061443],
					[65.955641529846858, 7.8261168052061443],
					[65.955924830246474, 7.8265723059846852],
					[65.955924831143435, 7.826572305943551],
					[65.955924831160218, 7.826572308142473],
					[65.956091531161846, 7.8268334084806783],
					[65.956091532058764, 7.8268334084395574],
					[65.956091532972508, 7.8268334105973887],
					[65.95640543333694, 7.8273084090313407],
					[65.95640543335368, 7.8273084112303257],
					[65.956405434250613, 7.8273084111892182],
					[65.956549830660379, 7.8275251073323178],
					[65.956619229011082, 7.8276390047952802],
					[65.956619230821701, 7.82763900691209],
					[65.956619234442954, 7.8276390111456688],
					[65.956619236253601, 7.8276390132624591],
					[65.956999835373864, 7.8281890115837944],
					[65.957344234137295, 7.8287057106265499],
					[65.95734423415405, 7.8287057128255952],
					[65.957344235050968, 7.8287057127845356],
					[65.957344235964669, 7.8287057149425197],
					[65.957458132854967, 7.8288695090812848],
					[65.95766373063762, 7.829197305651399],
					[65.95766373065436, 7.8291973078504729],
					[65.957908129633807, 7.829583404064314],
					[65.958022027894955, 7.8297751021994744],
					[65.958366526797604, 7.8303529010459201],
					[65.958641523863918, 7.8308472954785788],
					[65.958816522592841, 7.831177891353641],
					[65.959197021829979, 7.8319222906328667],
					[65.959274820295349, 7.8320778873587988],
					[65.959702618418888, 7.8330000835892273],
					[65.959733116745028, 7.8330667807737635],
					[65.960152613700757, 7.8340778721282609],
					[65.960188711846513, 7.8341750689607883],
					[65.960547011793025, 7.8351611690833129],
					[65.960647011212473, 7.8354389651702432],
					[65.960922009813245, 7.8362444619978389],
					[65.961105409771903, 7.8368056612190262],
					[65.961272008889438, 7.837327859874474],
					[65.961563709421895, 7.8382667580818541],
					[65.961608108216112, 7.838411156979058],
					[65.961722008273071, 7.8387972574544227],
					[65.961722008273071, 7.8387972574544227],
					[65.96172200918663, 7.8387972596131368],
					[65.961722009203243, 7.8387972618125668],
					[65.961944209223262, 7.8394972588158334],
					[65.962022007796548, 7.839758357717983],
					[65.962022008693452, 7.8397583576773018],
					[65.962272009115622, 7.8405833580778888],
					[65.962272009115622, 7.8405833580778888],
					[65.962272009132207, 7.8405833602773676],
					[65.962485907858735, 7.8412694575024648],
					[65.962599807721077, 7.8416667543933167],
					[65.962599807737632, 7.8416667565928435],
					[65.962599808651163, 7.8416667587517344],
					[65.962599808667719, 7.8416667609512416],
					[65.962922007308919, 7.8426972548980851],
					[65.962935905777101, 7.8427528500819923],
					[65.962935907620619, 7.8427528565994669],
					[65.962935913118088, 7.8427528717527748],
					[65.962935915858552, 7.8427528782296703],
					[65.9629442123194, 7.8427722702939748],
					[65.963280409144431, 7.8438361605241305],
					[65.963280410041378, 7.8438361604835887],
					[65.963402609982992, 7.8442167617925209],
					[65.96363040960486, 7.8449222616662153],
					[65.96385810923725, 7.84563616188324],
					[65.963858110134211, 7.8456361618427612],
					[65.963858110134211, 7.8456361618427612],
					[65.963858110150724, 7.8456361640423786],
					[65.963980409753148, 7.8460056615558615],
					[65.964324808948092, 7.8470639593445233],
					[65.964333107731278, 7.8470917570881982],
					[65.964333107731278, 7.8470917570881982],
					[65.964333108644695, 7.847091759247431],
					[65.964355408783106, 7.8471639572956677],
					[65.96466090802096, 7.8481750580886684],
					[65.964783108092618, 7.8485861565821393],
					[65.9649804075693, 7.8492611563082777],
					[65.9649804075693, 7.8492611563082777],
					[65.965241508243309, 7.8501444559442497],
					[65.965241509156684, 7.8501444581036663],
					[65.965302608067773, 7.8503472567652723],
					[65.965447008116442, 7.8508389551783431],
					[65.965622007595528, 7.8514333560228193],
					[65.965622009405848, 7.8514333581420344],
					[65.965622009422276, 7.8514333603418089],
					[65.965622010335622, 7.8514333625013055],
					[65.965705408706242, 7.8516889599379107],
					[65.965963709240427, 7.8525194585328792],
					[65.96596370925684, 7.8525194607326849],
					[65.965963710170158, 7.8525194628922703],
					[65.966163710321339, 7.8531278617265547],
					[65.966163710337739, 7.8531278639263977],
					[65.966163710337739, 7.8531278639263977],
					[65.966163711234671, 7.8531278638861783],
					[65.966324810926579, 7.8536028650716103],
					[65.966324810926579, 7.8536028650716103],
					[65.966324810926579, 7.8536028650716103],
					[65.966622011122297, 7.8544583667621231],
					[65.966622012035586, 7.8544583689218124],
					[65.966622012035586, 7.8544583689218124],
					[65.966705411848679, 7.8546861680595663],
					[65.96670541276201, 7.8546861702192681],
					[65.966705413675314, 7.8546861723789725],
					[65.966705413691685, 7.854686174578843],
					[65.967077613888932, 7.8556250722769212],
					[65.96713311428033, 7.8557667728036327],
					[65.967133114296672, 7.8557667750035414],
					[65.967133115209975, 7.8557667771633399],
					[65.967133116106922, 7.8557667771232094],
					[65.967535915989686, 7.856713976954552],
					[65.967535916903017, 7.8567139791143967],
					[65.967535917816306, 7.8567139812742415],
					[65.967535917832663, 7.8567139834741848],
					[65.967597017255841, 7.8568472828506826],
					[65.967985916989562, 7.8577361802213401],
					[65.967985917902809, 7.8577361823812613],
					[65.96798591883244, 7.8577361867411843],
					[65.967985919729372, 7.8577361867011222],
					[65.968077618654817, 7.8579250855608649],
					[65.968444219079004, 7.8587083842499608],
					[65.968444219095332, 7.8587083864499823],
					[65.96844421999225, 7.8587083864099556],
					[65.968444220008564, 7.858708388609978],
					[65.968588720216232, 7.8590028880399609],
					[65.96890261874978, 7.8596639870783065],
					[65.969013712372956, 7.8598987358414423],
					[65.969719214412819, 7.8616778745870146],
					[65.969719215326066, 7.8616778767472226],
					[65.969719217152502, 7.8616778810676573],
					[65.969719217152502, 7.8616778810676573],
					[65.971930417271594, 7.8666361811712777],
					[65.971930418184726, 7.866636183331849],
					[65.971930419097916, 7.8666361854924185],
					[65.97193041999482, 7.8666361854526619],
					[65.974083120432311, 7.8711306860348795],
					[65.974083122258477, 7.8711306903567069],
					[65.974083126823899, 7.8711307011612943],
					[65.974083130444001, 7.8711307054039104],
					[65.976180429891514, 7.8745168053144869],
					[65.976180430804561, 7.8745168074756977],
					[65.976180432614555, 7.8745168095974378],
					[65.976180434424563, 7.874516811719162],
					[65.977622033728892, 7.8766445109342405],
					[65.97762203643579, 7.8766445130168137],
					[65.977622038261813, 7.8766445173396331],
					[65.977622039174832, 7.8766445195010615],
					[65.97881922830399, 7.87825570302416],
					[65.97972202035578, 7.8801472859803825],
					[65.979722023094681, 7.8801472924655158],
					[65.979722032176255, 7.8801473074796204],
					[65.979722035812145, 7.8801473139254741],
					[65.981116535608493, 7.8821473146502612],
					[65.981116541038134, 7.8821473210182695],
					[65.981116551000497, 7.8821473337935384],
					[65.981116556414165, 7.8821473379604354],
					[65.982308155934902, 7.8833890374501596],
					[65.982308165881207, 7.8833890480252684],
					[65.982308189345616, 7.8833890668174744],
					[65.982308201053868, 7.8833890729117337],
					[65.983794284085363, 7.8842334634766784],
					[65.985083135876721, 7.8853418219832552],
					[65.986533118201578, 7.888497284941451],
					[65.986533119098524, 7.8884972849024333],
					[65.986533121837084, 7.8884972913902089],
					[65.986533122749933, 7.8884972935527919],
					[65.988188723243354, 7.8916472928634178],
					[65.988188725068966, 7.8916472971891141],
					[65.988188731411043, 7.8916473057237297],
					[65.988188733236626, 7.891647310049426],
					[65.990677632059814, 7.895411208358972],
					[65.99346923131823, 7.8998057074706933],
					[65.993469232230922, 7.899805709634288],
					[65.993469234953281, 7.8998057139228042],
					[65.993469235865945, 7.8998057160863802],
					[65.996099836335588, 7.9035668157837673],
					[65.996099952340657, 7.903566852662844],
					[65.99610013311775, 7.9035667876210214],
					[65.996100198786692, 7.9035666856616347],
					[65.9959946039626, 7.9021556499801342],
					[65.996491868975937, 7.9013695080631292],
					[65.996491877819778, 7.9013694900574967],
					[65.996491891903929, 7.9013694519980815],
					[65.996491898041157, 7.9013694319057128],
					[65.996672397669386, 7.9002083317002576],
					[65.996672399337442, 7.9002083140030805],
					[65.996672398173104, 7.9002082765993906],
					[65.996672396253359, 7.9002082590567353],
					[65.996222395676483, 7.8980388598466558],
					[65.996222395660737, 7.8980388576442104],
					[65.996222394748003, 7.8980388554804462],
					[65.996222394732243, 7.8980388532780008],
					[65.99561409461171, 7.8954916537643278],
					[65.995614091857675, 7.8954916450711101],
					[65.995614086349548, 7.8954916276847005],
					[65.995614082698566, 7.8954916190302731],
					[65.995164098295049, 7.8944832549732347],
					[65.995047400264397, 7.8914027928452377],
					[65.995047399319873, 7.8914027862771334],
					[65.995047398327799, 7.8914027731020111],
					[65.995047397399119, 7.8914027687362642],
					[65.994330797244089, 7.8869360673024618],
					[65.994330797228159, 7.8869360651001674],
					[65.994330796283435, 7.8869360585324353],
					[65.994330795354671, 7.8869360541669531],
					[65.993514095628996, 7.8833693590164122],
					[65.992752995979785, 7.8795471615067063],
					[65.992752995034778, 7.8795471549396536],
					[65.992752992263817, 7.8795471440470024],
					[65.992752991334811, 7.879547139682062],
					[65.991744591702655, 7.8763721440904595],
					[65.991286296128777, 7.8745916604474226],
					[65.990775198380845, 7.8710166715804588],
					[65.99077519468004, 7.8710166563253097],
					[65.990775187310732, 7.8710166302189206],
					[65.990775181816005, 7.8710166150430316],
					[65.990075194128906, 7.8695027425675175],
					[65.989761297710672, 7.8672027738063912],
					[65.989761297694457, 7.8672027716045267],
					[65.989402998334498, 7.8646499730217849],
					[65.989402998318255, 7.8646499708199515],
					[65.989402997372636, 7.8646499642542951],
					[65.9894029955625, 7.8646499621321837],
					[65.988891897072648, 7.8619721635963282],
					[65.988633496634378, 7.8605721642432185],
					[65.988633494775343, 7.8605721555161496],
					[65.988633491089757, 7.8605721424655624],
					[65.988633489246979, 7.8605721359402798],
					[65.987280788957207, 7.85664433577334],
					[65.987280758117834, 7.856644290903426],
					[65.987280675956569, 7.8566442219015977],
					[65.987280625564338, 7.8566442021328298],
					[65.986830758333454, 7.8565859187260267],
					[65.986327993032859, 7.8547527464074109],
					[65.986327958556018, 7.8547526950975799],
					[65.986327869185629, 7.8547526220228638],
					[65.986327811601271, 7.854752600378605],
					[65.984991832176092, 7.8546748080524624],
					[65.980386282163124, 7.8445138161185222],
					[65.980386256784485, 7.8445137820372262],
					[65.980386193585701, 7.8445137298496403],
					[65.980386155732532, 7.8445137073412701],
					[65.97884455516089, 7.8440609378568746],
					[65.978339094797903, 7.842613850576865],
					[65.97788349808944, 7.8397360724693534],
					[65.977669599626722, 7.8378221906122114],
					[65.977700199960665, 7.8353833403900399],
					[65.978286285060491, 7.8339500756980085],
					[65.978286287701252, 7.8339500689727055],
					[65.978286292949491, 7.8339500511203539],
					[65.978286294693334, 7.8339500444359667],
					[65.978577990606354, 7.832655662093341],
					[65.978919560932752, 7.8318723309414331],
					[65.97970562827922, 7.8313280239363685],
					[65.980644331404079, 7.8316751883377327],
					[65.980644350289808, 7.8316751940798834],
					[65.980644388011157, 7.8316751989609887],
					[65.980644407760408, 7.8316752002600332],
					[65.981338907706899, 7.8316473998329847],
					[65.981338953366986, 7.831647386736222],
					[65.981339031996427, 7.8316473435078295],
					[65.981339065846001, 7.8316473111340636],
					[65.982222366218906, 7.8303307107586297],
					[65.982222370653389, 7.83033070394979],
					[65.982222381282739, 7.8303306858476551],
					[65.982222384803578, 7.8303306768786802],
					[65.982702811243726, 7.8291561026412815],
					[65.983108117809252, 7.8300389836352204],
					[65.983108182823457, 7.8300390379116003],
					[65.983108331185548, 7.8300390795640098],
					[65.98310841361976, 7.8300390647798386],
					[65.983697313907385, 7.8296307637983711],
					[65.983697336180072, 7.8296307429595382],
					[65.983697372602833, 7.8296306950476353],
					[65.983697387649855, 7.8296306679334906],
					[65.983939040569908, 7.8289779950029263],
					[65.984388907277918, 7.8289612991276636],
					[65.984388952057557, 7.8289612882669664],
					[65.984389030686188, 7.8289612450250718],
					[65.984389065432126, 7.8289612126027484],
					[65.985022307601881, 7.8280390949685428],
					[65.985419408140487, 7.8280223992088978],
					[65.985419470758131, 7.8280223743145454],
					[65.985419565229563, 7.8280222907011217],
					[65.985419597980282, 7.8280222319409321],
					[65.985564072326923, 7.8271168931307677],
					[65.986008307848408, 7.8271001992139206],
					[65.986008356198866, 7.8271001859830518],
					[65.986008440141461, 7.8271001336795516],
					[65.986008474853406, 7.8271000968496063],
					[65.986152780222923, 7.8268393496715181],
					[65.986655417358449, 7.8279722816607009],
					[65.986655465281387, 7.8279723301162782],
					[65.986655580558022, 7.8279723864939861],
					[65.986655649705611, 7.8279723943338482],
					[65.987249939801345, 7.8278196211561335],
					[65.987747059406146, 7.8283140422623001],
					[65.987747120632136, 7.8283140702885845],
					[65.987747249027493, 7.828314082019892],
					[65.987747315283173, 7.8283140635644086],
					[65.988136108130377, 7.828041938738866],
					[65.988935996894895, 7.8285251720354019],
					[65.988936020298894, 7.8285251819749195],
					[65.98893606795356, 7.8285251952075381],
					[65.988936093117928, 7.8285252006612511],
					[65.99066109326337, 7.828586299998042],
					[65.990661148772631, 7.8285862842365495],
					[65.990661243445345, 7.8285862270308906],
					[65.990661281695068, 7.8285861834259212],
					[65.991202948439536, 7.8274084590486446],
					[65.993080520840778, 7.8266919439669866],
					[65.993574833260581, 7.8274418095960536],
					[65.993574916147182, 7.8274418542556434],
					[65.993575081266371, 7.8274418576903226],
					[65.993575165259273, 7.8274418119787361],
					[65.994208464868009, 7.826519513828794],
					[65.994208477240448, 7.8265194890274508],
					[65.994208493912879, 7.8265194397956384],
					[65.994208499093091, 7.8265194131217219],
					[65.994300199279749, 7.8252306131365748],
					[65.99430020017671, 7.8252306130953198],
					[65.994300200159884, 7.825230610893076],
					[65.994300200159884, 7.825230610893076],
					[65.994522400441568, 7.8207278103933815],
					[65.99452238656599, 7.8207277559585],
					[65.994522333818821, 7.8207276636639582],
					[65.994522294033501, 7.8207276236434717],
					[65.993630693958849, 7.8202498229696165],
					[65.993630669657392, 7.820249813077325],
					[65.993630618431212, 7.8202498022256925],
					[65.993630592386552, 7.8202497990227622],
					[65.991897191962565, 7.8203192004878419],
					[65.991179152837958, 7.8203484013134732],
					[65.99114189504833, 7.8202860355900521],
					[65.991072399770729, 7.8195610858252156],
					[65.991066900204345, 7.8194499900301722],
					[65.991066873703758, 7.8194499273758575],
					[65.991066787752601, 7.8194498344281067],
					[65.991066728285148, 7.8194498019327314],
					[65.991027836360402, 7.8194442025354443],
					[65.990577843213586, 7.8193442051838531],
					[65.990577833330491, 7.819344203437919],
					[65.990577810873461, 7.8193442000704545],
					[65.990577800110344, 7.819344200567893],
					[65.990525015969453, 7.8193441995128925],
					[65.990127832358397, 7.819280403735414],
					[65.990127820681451, 7.8192804020724642],
					[65.990127798241318, 7.8192804009069707],
					[65.990127786564344, 7.8192803992440023],
					[65.990044407208742, 7.8192858982589328],
					[65.989686128133869, 7.8192359030116734],
					[65.989686117353813, 7.8192359013073176],
					[65.989686095810626, 7.8192359001004226],
					[65.989686085944427, 7.8192359005564311],
					[65.989569405198836, 7.8192441986399226],
					[65.989236123133963, 7.8192054014818622],
					[65.989236112370818, 7.8192054019793273],
					[65.989236090827603, 7.8192054007724785],
					[65.989236080064472, 7.8192054012699446],
					[65.989097199637655, 7.8192191987581019],
					[65.988786119878554, 7.819188701912454],
					[65.988625021174926, 7.8191720015288011],
					[65.988625020277979, 7.8191720015702577],
					[65.988625017587182, 7.8191720016946258],
					[65.988625016690264, 7.8191720017360788],
					[65.9883333847649, 7.8191470075305469],
					[65.988036236384488, 7.8188720535653937],
					[65.988036209324605, 7.8188720349941887],
					[65.988036151701536, 7.8188720090260748],
					[65.988036122035311, 7.8188720015876791],
					[65.98788332176818, 7.8188554001420387],
					[65.987883317283561, 7.8188554003493627],
					[65.987883308314252, 7.8188554007640167],
					[65.987883303829605, 7.8188554009713425],
					[65.987739092048116, 7.8188526039858006],
					[65.987725200133241, 7.8185055915516291],
					[65.987725193618161, 7.8185055610187808],
					[65.987725171635645, 7.8185055025695309],
					[65.987725156168167, 7.8185054746531293],
					[65.987433456394086, 7.8181415760218105],
					[65.987433420280283, 7.8181415468585733],
					[65.98743333833859, 7.8181415088031221],
					[65.987433293407648, 7.8181414998694354],
					[65.98727502529205, 7.8181469994628676],
					[65.986983386144345, 7.8180609180214509],
					[65.986702912445679, 7.8178720338704677],
					[65.986702885436358, 7.8178720219059912],
					[65.986702828777709, 7.8178720047062145],
					[65.986702800025256, 7.8178719994294212],
					[65.986533423014649, 7.8178719998760027],
					[65.986327978256639, 7.8174666103506505],
					[65.986327976428967, 7.8174666060306093],
					[65.986327970065901, 7.8174665953135127],
					[65.9863279682382, 7.8174665909934733],
					[65.986075167625131, 7.8170776910363298],
					[65.986075130580346, 7.8170776575146199],
					[65.98607504499995, 7.8170776130261324],
					[65.986074997344332, 7.8170775998163267],
					[65.985908343413044, 7.817080399648467],
					[65.985625127805918, 7.816947038957121],
					[65.98521410015266, 7.8164193302617573],
					[65.985214099788394, 7.8163277993410665],
					[65.985214067820323, 7.816327725946401],
					[65.985213961187142, 7.8163276273815754],
					[65.985213885608133, 7.8163276000515527],
					[65.985174992587133, 7.816330399306322],
					[65.98513898064688, 7.8163304004116112],
					[65.984719543916654, 7.8159248619175257],
					[65.984330748029294, 7.8154970642171948],
					[65.984330739906127, 7.815497057987308],
					[65.984330721832023, 7.8154970412079345],
					[65.984330711914993, 7.8154970350612007],
					[65.984269539649901, 7.8154554534478429],
					[65.98420015875746, 7.8153637796290036],
					[65.984200156929717, 7.8153637753094731],
					[65.984200152394266, 7.8153637689133175],
					[65.984200149686529, 7.8153637668367129],
					[65.983816850250079, 7.8149304661142649],
					[65.983816849353147, 7.8149304661558601],
					[65.983816848439261, 7.8149304639961423],
					[65.983816846645411, 7.8149304640793318],
					[65.983475149221135, 7.8145609671523326],
					[65.983366853133901, 7.8144359700037844],
					[65.983261256742892, 7.8143081753520685],
					[65.98291126001628, 7.8138415808004229],
					[65.982911257291562, 7.8138415765228508],
					[65.982911251859107, 7.8138415701689183],
					[65.982911250048289, 7.8138415680509477],
					[65.982536252309458, 7.8134192708756869],
					[65.982461256635474, 7.8133276756594947],
					[65.982400160670466, 7.8132470817762503],
					[65.982002961579383, 7.8126970829333962],
					[65.98200295976855, 7.8126970808155809],
					[65.98200295704379, 7.8126970765382815],
					[65.982002955232957, 7.812697074420468],
					[65.981583474182159, 7.8121831980230709],
					[65.981552984856393, 7.8121082242838451],
					[65.98155295584894, 7.8121081859977801],
					[65.981552880894327, 7.8121081234244629],
					[65.98155283498113, 7.8121081035394413],
					[65.981522314868499, 7.8121026169502361],
					[65.981102962018156, 7.8115192826663575],
					[65.981102961104213, 7.8115192805069702],
					[65.980805775999229, 7.8111193009555082],
					[65.980644585753566, 7.8107166266249362],
					[65.980644566663003, 7.8107165944858084],
					[65.980644518717668, 7.8107165438728927],
					[65.980644489828919, 7.8107165209970315],
					[65.980430734064385, 7.8106081443894269],
					[65.980194562969899, 7.8102776831627221],
					[65.980033472941628, 7.8100526981182714],
					[65.979736280710014, 7.8094166143900621],
					[65.979736271621533, 7.8094165994011515],
					[65.979736248942984, 7.8094165674312537],
					[65.979736237180788, 7.8094165547686423],
					[65.979286300166194, 7.8089943124918939],
					[65.979286299559604, 7.8089889007051738],
					[65.979286300317639, 7.8089861000886165],
					[65.97928629562864, 7.8089860738865378],
					[65.979286280869033, 7.8089860217331344],
					[65.979286269901507, 7.8089859958236216],
					[65.978827969960278, 7.8082387946374521],
					[65.978827966321418, 7.8082387882021012],
					[65.978827958163862, 7.8082387775740756],
					[65.978827953628112, 7.8082387711805215],
					[65.978561273154682, 7.807919295091633],
					[65.978377984801398, 7.8074777228666434],
					[65.978377980231485, 7.8074777120716838],
					[65.978377968417973, 7.8074776928080958],
					[65.978377962054196, 7.8074776820968088],
					[65.977927961598439, 7.806858182824266],
					[65.977927957062619, 7.8068581764311826],
					[65.977927946197113, 7.8068581637287711],
					[65.977927940764374, 7.806858157377575],
					[65.977919548198855, 7.8068498660221515],
					[65.977469553695002, 7.8063081717718603],
					[65.977469530204189, 7.8063081508532646],
					[65.977469476115502, 7.8063081181541678],
					[65.97746944639745, 7.8063081041310243],
					[65.977211212077222, 7.8062470214743662],
					[65.977097551034873, 7.806108695179784],
					[65.974066867779328, 7.8014137915978132],
					[65.969116869394369, 7.7936720909903228],
					[65.969116865789417, 7.793672088959541],
					[65.969116861235804, 7.7936720803709596],
					[65.969116857613614, 7.7936720761401483],
					[65.964675158132508, 7.7879637765444567],
					[65.964675157235561, 7.7879637765869614],
					[65.964675155424388, 7.7879637744723045],
					[65.964675153613214, 7.7879637723576671],
					[65.959777954188894, 7.7820220720018565],
					[65.959777950583799, 7.7820220699734453],
					[65.959777945132643, 7.7820220614327651],
					[65.959777942424452, 7.7820220593616529],
					[65.95684184170328, 7.7790720584079756],
					[65.95684182635091, 7.7790720459417644],
					[65.956841791179045, 7.7790720234223052],
					[65.956841772256425, 7.7790720133262559],
					[65.953561186867049, 7.7778054199640598],
					[65.951522312442137, 7.776610933857282],
					[65.949477922295969, 7.7750359424410647],
					[65.949477905149422, 7.7750359300666769],
					[65.949477869115043, 7.7750359119987298],
					[65.949477850227154, 7.7750359063051944],
					[65.94818894963332, 7.7747054065108721],
					[65.948188886848953, 7.7747054095163151],
					[65.94818877684321, 7.7747054543652716],
					[65.948188728707393, 7.7747054940534852],
					[65.94791149708351, 7.7751578107502146],
					[65.947616818161833, 7.7749415386849137],
					[65.947616813659721, 7.7749415367013732],
					[65.947616804637988, 7.7749415305361067],
					[65.947616800118354, 7.7749415263544011],
					[65.947425104137196, 7.7748304289202315],
					[65.947158409036248, 7.7746581326321351],
					[65.947158402722764, 7.774658128536422],
					[65.947158391907195, 7.7746581224572324],
					[65.947158387405082, 7.7746581204737781],
					[65.946830686666388, 7.7744998200469508],
					[65.946830685769456, 7.7744998200898916],
					[65.946830683958098, 7.774499817977671],
					[65.946830683061151, 7.7744998180206117],
					[65.946708382755915, 7.7744442173005899],
					[65.946708379150664, 7.7744442152742685],
					[65.946708371957826, 7.7744442134197058],
					[65.946708368352631, 7.7744442113933809],
					[65.946280668321577, 7.7742887123318409],
					[65.946280661128696, 7.7742887104773528],
					[65.946280646742949, 7.7742887067683792],
					[65.946280638653135, 7.7742887049568408],
					[65.946266739280844, 7.7742859050203528],
					[65.946266684568755, 7.7742859076400403],
					[65.946266586170452, 7.7742859453348903],
					[65.946266541552305, 7.7742859760568708],
					[65.946227643684054, 7.7743359744352087],
					[65.945974846413506, 7.7746415719811486],
					[65.945816545993665, 7.7748331735843754],
					[65.945816539767762, 7.7748331804789128],
					[65.945816529144878, 7.774833198578138],
					[65.945816522918989, 7.7748332054726736],
					[65.945694224284452, 7.7750638067428692],
					[65.945524824034152, 7.7753777057829145],
					[65.945524823137205, 7.7753777058258216],
					[65.945524822257823, 7.7753777080667144],
					[65.945524821378399, 7.775377710307608],
					[65.945441523110091, 7.775544306698368],
					[65.945372025242705, 7.7756693022610808],
					[65.945372024363337, 7.7756693045019496],
					[65.945372024363337, 7.7756693045019496],
					[65.945183123931926, 7.7760193042616086],
					[65.945183123949434, 7.7760193064595882],
					[65.944969228140891, 7.7764192976520938],
					[65.94493043319379, 7.7764776890175922],
					[65.944930420846788, 7.7764777159930114],
					[65.944930404242626, 7.7764777717559914],
					[65.944930399985438, 7.7764778005435717],
					[65.944930400021889, 7.776488853795267],
					[65.944922022933312, 7.7765055075115814],
					[65.944672025765854, 7.7769582021767931],
					[65.94448872663537, 7.7772749011151721],
					[65.944488725738466, 7.7772749011580089],
					[65.944405425559722, 7.7774221005950332],
					[65.944283126934664, 7.7776332003020174],
					[65.94428312603776, 7.7776332003448401],
					[65.944144226835036, 7.7778776995426098],
					[65.94404702836303, 7.7780415978947364],
					[65.943877629400745, 7.7783248949023855],
					[65.943610935091201, 7.778749887794346],
					[65.943602640098973, 7.7787609799640878],
					[65.943602625078285, 7.7787610092631825],
					[65.943602604903063, 7.7787610673907679],
					[65.943602599783418, 7.7787611006149122],
					[65.943602599586626, 7.7787638995210333],
					[65.943602599832701, 7.7787666412408294],
					[65.943327630792254, 7.7791998927793724],
					[65.943327630809705, 7.7791998949771743],
					[65.943327629912773, 7.7791998950199428],
					[65.943327629015869, 7.7791998950627104],
					[65.943160930371434, 7.7794720940478532],
					[65.943060931008532, 7.7796331914967816],
					[65.942885934052271, 7.7798998879811787],
					[65.942780435270194, 7.7800554859281341],
					[65.942719237005832, 7.780144283045213],
					[65.942519245402337, 7.7804192731757444],
					[65.942488752209513, 7.7804526642255807],
					[65.94248874777719, 7.7804526710323856],
					[65.942488740654156, 7.7804526779673751],
					[65.942488737118779, 7.7804526847314577],
					[65.942274836611617, 7.7807526833964751],
					[65.942274835732121, 7.7807526856368998],
					[65.942274833058761, 7.7807526879627469],
					[65.942274832179294, 7.780752690203192],
					[65.942208134899587, 7.7808554887956021],
					[65.942077638202008, 7.7810387830226011],
					[65.941916538233841, 7.7812609838183073],
					[65.941916537336922, 7.7812609838610003],
					[65.94191653556048, 7.7812609861440691],
					[65.941916534663576, 7.7812609861867621],
					[65.941833137590095, 7.7813831827977706],
					[65.941627643406846, 7.7816498764739181],
					[65.941558143844048, 7.781735974020437],
					[65.941558142964524, 7.7817359762607659],
					[65.941558140308587, 7.7817359807840969],
					[65.941558139429105, 7.7817359830244257],
					[65.941391544006436, 7.7819637741638665],
					[65.941330450263706, 7.7820331685936406],
					[65.941330447590374, 7.7820331709192674],
					[65.941330444917, 7.7820331732448933],
					[65.941330443140572, 7.7820331755278769],
					[65.941216543055532, 7.7821776765301847],
					[65.94121654039958, 7.78217768105341],
					[65.941216535070268, 7.7821776879022559],
					[65.941216533293854, 7.782177690185196],
					[65.941047033102777, 7.7824359893475243],
					[65.941047033120185, 7.7824359915451335],
					[65.941047032223281, 7.7824359915877821],
					[65.941047032223281, 7.7824359915877821],
					[65.940947055152776, 7.7825915558318144],
					[65.940727691634663, 7.7827331316838197],
					[65.940727678267834, 7.7827331433113294],
					[65.940727652431107, 7.7827331665237143],
					[65.940727640858128, 7.7827331780659472],
					[65.940719245116085, 7.7827442724657701],
					[65.940497050973732, 7.7829970650739426],
					[65.940405452669197, 7.783097065889045],
					[65.940405451772293, 7.7830970659316687],
					[65.940405451772293, 7.7830970659316687],
					[65.940199852389071, 7.783324866425839],
					[65.940199844386342, 7.7833248755995621],
					[65.940199831071652, 7.7833248938191328],
					[65.940199824862759, 7.7833249029076201],
					[65.940116526912931, 7.7834749000258672],
					[65.940055465179341, 7.783574838554336],
					[65.939941627404863, 7.7836192142007903],
					[65.939941606845153, 7.7836192239707946],
					[65.939941569365658, 7.7836192499329595],
					[65.939941550652023, 7.7836192662103354],
					[65.939788750624771, 7.7837887658374285],
					[65.939788750642137, 7.7837887680349329],
					[65.939788748848258, 7.7837887681201332],
					[65.939613755411287, 7.7839915623522558],
					[65.939474862355567, 7.7841248550563904],
					[65.939474860561731, 7.7841248551415649],
					[65.939474859682164, 7.7841248573816486],
					[65.939474858785232, 7.7841248574242368],
					[65.93919425930217, 7.7844026579406425],
					[65.93919425930217, 7.7844026579406425],
					[65.939163758578857, 7.784433158255549],
					[65.93916375414635, 7.784433165060797],
					[65.939163744349727, 7.7844331743189308],
					[65.939163739899882, 7.7844331789267276],
					[65.939155448091981, 7.7844442693466904],
					[65.939124859242384, 7.7844748594808646],
					[65.939124849445747, 7.7844748687389886],
					[65.939124832577932, 7.7844748915223851],
					[65.93912482459254, 7.7844749028928044],
					[65.938888725906139, 7.7848999024893502],
					[65.938719254446596, 7.7851998524714432],
					[65.938591605758049, 7.7852692234129286],
					[65.93836100764743, 7.7853887224617333],
					[65.938360988898879, 7.7853887343420194],
					[65.938360955041489, 7.7853887645245718],
					[65.938360939915327, 7.7853887806294573],
					[65.938277652878227, 7.7854998624426903],
					[65.938238770212067, 7.7855331472872882],
					[65.938238768435596, 7.7855331495697309],
					[65.938238763088776, 7.7855331542196859],
					[65.938238761312263, 7.7855331565021295],
					[65.938147061178114, 7.7856220564460079],
					[65.938147043413224, 7.7856220792703272],
					[65.938147015093605, 7.7856221289734293],
					[65.938147005435781, 7.7856221558096808],
					[65.938033104655887, 7.7861277550384713],
					[65.938033104673238, 7.7861277572358452],
					[65.938033103793657, 7.7861277594757139],
					[65.937988704261002, 7.7863416582751395],
					[65.937988701674342, 7.7863416715867801],
					[65.937988700088681, 7.786341698040034],
					[65.93798870019279, 7.7863417112241535],
				],
			],
		},
		{
			fldNpdidField: "1630100",
			wlbNpdidWellbore: "91",
			fldName: "SIGYN",
			fldCurrentActivitySatus: "Producing",
			wlbName: "16/7-4",
			fldDiscoveryYear: "1982",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=1630100",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=1630100",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "072",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=91",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "21372",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Dusavik",
			fldAreaGeometryWKT: [
				[
					[58.27603040015061, 2.0162028037729129],
					[58.276030400429278, 2.016202808849453],
					[58.276116500402061, 2.0180861032243307],
					[58.276096999772655, 2.0196693975689661],
					[58.276097000051195, 2.0196694026455666],
					[58.276096999623917, 2.0196694112827984],
					[58.276097000793918, 2.0196694161831554],
					[58.276194200655638, 2.0209028157906799],
					[58.276194203274024, 2.0209028306681533],
					[58.276194209216555, 2.0209028568624627],
					[58.276194212726402, 2.020902871563734],
					[58.276691505688788, 2.0222111547252202],
					[58.276949802581875, 2.0236639346398144],
					[58.277202603373482, 2.0251000326062258],
					[58.27744980022662, 2.0267250138025221],
					[58.27743039996286, 2.0283277987356412],
					[58.277430400148312, 2.0283278021202382],
					[58.277430400797414, 2.028327813966293],
					[58.277430401168317, 2.0283278207354747],
					[58.277583101208883, 2.0298472152518037],
					[58.277660900408783, 2.0313417105144493],
					[58.277660901949098, 2.0313417221847225],
					[58.277660905921216, 2.0313417453492852],
					[58.277660908352992, 2.0313417568435987],
					[58.278122002873637, 2.0328861380277514],
					[58.278227599594224, 2.0340139145384404],
					[58.27828310026667, 2.0351194101515415],
					[58.27828310026667, 2.0351194101515415],
					[58.278283100359303, 2.0351194118438962],
					[58.278366499588657, 2.0364639067110208],
					[58.278360900409105, 2.0379360996897828],
					[58.278360899610178, 2.0379361015579485],
					[58.278360899980569, 2.0379361083274481],
					[58.278360900165779, 2.037936111712185],
					[58.278416500390975, 2.0388472111436631],
					[58.278416534868676, 2.0388472813246645],
					[58.278416644579302, 2.0388473760175576],
					[58.278416719719651, 2.0388473988370555],
					[58.278988903064004, 2.0387891003450647],
					[58.280158278755508, 2.0388723990366358],
					[58.281313870870207, 2.0390390982862581],
					[58.281313881753441, 2.0390390995619136],
					[58.281313905210354, 2.0390391000691563],
					[58.281313916000997, 2.0390390996522898],
					[58.282477816327422, 2.038947398748002],
					[58.28247781900194, 2.0389473982206292],
					[58.282477825335043, 2.0389473986826592],
					[58.282477828009576, 2.0389473981552833],
					[58.283894427589331, 2.0387501973196218],
					[58.283894432938354, 2.0387501962647878],
					[58.283894443728983, 2.0387501958477507],
					[58.283894449969523, 2.0387501946171005],
					[58.28501395016594, 2.038461293385978],
					[58.285013953731955, 2.0384612926826944],
					[58.285013962646985, 2.0384612909244781],
					[58.285013967011913, 2.0384612883526785],
					[58.285952827765676, 2.0381280016339276],
					[58.287127786195164, 2.0382084996970935],
					[58.287127815799934, 2.0382084972798831],
					[58.287127873562383, 2.0382084824645634],
					[58.287127901812632, 2.0382084717592273],
					[58.288088994878962, 2.0376362767888372],
					[58.289141718718376, 2.0371308130338948],
					[58.289708245751449, 2.0372890938706818],
					[58.289708321806806, 2.037289083999366],
					[58.289708445007555, 2.0372890134912374],
					[58.289708492245545, 2.0372889545473356],
					[58.289989092348264, 2.0362972535120103],
					[58.289989095879392, 2.0362972357032394],
					[58.289989099190429, 2.0362971974034911],
					[58.28998909906295, 2.03629717860544],
					[58.289791900061672, 2.0344527902137459],
					[58.289794600297633, 2.0328500151187456],
					[58.290077993309296, 2.0309028652254515],
					[58.290897372289059, 2.0294390013630976],
					[58.291766869076248, 2.0280640070102645],
					[58.291766872363937, 2.028064001226825],
					[58.291766878140599, 2.0280639915290339],
					[58.291766879738049, 2.0280639877908286],
					[58.292450168597711, 2.0266445094742398],
					[58.292964052080094, 2.0260445294892637],
					[58.292964058840838, 2.0260445213076448],
					[58.292964071378037, 2.0260445034275967],
					[58.292964076263047, 2.0260444939053284],
					[58.293297376227272, 2.0254194947561652],
					[58.293297376933189, 2.0254194911938685],
					[58.29329737862335, 2.0254194891484185],
					[58.293297380313525, 2.0254194871029707],
					[58.293714079588298, 2.0245583874804152],
					[58.293714081278452, 2.0245583854349003],
					[58.294430780883431, 2.0230278856397526],
					[58.294430784078045, 2.0230278781623068],
					[58.294430789575841, 2.0230278633837093],
					[58.294430791878995, 2.0230278560825385],
					[58.294544591055327, 2.0226389594577023],
					[58.294875175849754, 2.0216473062269622],
					[58.295200117999393, 2.0213057663697995],
					[58.295850082922556, 2.0210112819796833],
					[58.295850109016683, 2.0210112648376355],
					[58.295850155299121, 2.0210112214526559],
					[58.295850174688788, 2.0210111970791926],
					[58.29617237529132, 2.020427897743033],
					[58.296172380083014, 2.0204278865259648],
					[58.296172388774949, 2.0204278642681897],
					[58.296172392675174, 2.0204278532274738],
					[58.296391892600603, 2.0196306538465816],
					[58.296391894104943, 2.0196306484143429],
					[58.296391896407918, 2.0196306411125446],
					[58.296391897113658, 2.0196306375498465],
					[58.296544590195708, 2.0188083712021205],
					[58.296800172410279, 2.0183779020856591],
					[58.296800174806052, 2.0183778964768813],
					[58.296800181380462, 2.0183778849065361],
					[58.296800183776234, 2.0183778792977725],
					[58.296974498115297, 2.0179704405198824],
					[58.297072359340845, 2.0178418206154993],
					[58.297072377633313, 2.0178417433976263],
					[58.297072354397365, 2.0178415990889622],
					[58.297072311178951, 2.017841534044218],
					[58.297039010873881, 2.0178192345811365],
					[58.297038927187401, 2.0178192203345828],
					[58.29703878004068, 2.0178192648594915],
					[58.297038716394653, 2.017819320244564],
					[58.296974149939835, 2.0179702372832811],
					[58.296879186691726, 2.0180950463724114],
					[58.297283476186841, 2.0173444941604188],
					[58.297283482575367, 2.0173444792033055],
					[58.297283494275227, 2.0173444460791292],
					[58.29728349789648, 2.0173444299581433],
					[58.297458498258472, 2.0161778296007],
					[58.29745849807265, 2.0161778262142938],
					[58.297458498592476, 2.016177819264998],
					[58.297458499298116, 2.0161778157021271],
					[58.297591899112064, 2.0144667165311803],
					[58.297591900430312, 2.0144667077121263],
					[58.297591899593925, 2.0144666924732673],
					[58.297591899222198, 2.0144666857004521],
					[58.29749189983756, 2.013122189245522],
					[58.297461300283565, 2.0121027984051638],
					[58.297478000316175, 2.0111500097162511],
					[58.297550199128082, 2.0101528146027867],
					[58.297550199740598, 2.0101528093465912],
					[58.297550199981124, 2.0101527973176561],
					[58.297550199702151, 2.0101527922380997],
					[58.297500199823133, 2.0088749922372244],
					[58.297500198094639, 2.0088749771752528],
					[58.297500193040804, 2.0088749507909465],
					[58.29750018962239, 2.0088749377754431],
					[58.297072390195652, 2.0075666371119776],
					[58.29707238607174, 2.0075666276597652],
					[58.297072377916955, 2.007566610448495],
					[58.297072373793043, 2.0075666009962831],
					[58.296386284818652, 2.0063666197009922],
					[58.295883491141204, 2.0047138420454407],
					[58.295883486924062, 2.0047138309007964],
					[58.295883477691369, 2.0047138104813347],
					[58.295883472582744, 2.0047137995134428],
					[58.29529457282365, 2.0036887995031587],
					[58.295294570854637, 2.0036887964705681],
					[58.29529456485453, 2.0036887856797634],
					[58.295294561009555, 2.0036887813076514],
					[58.294589061337611, 2.0027248812095952],
					[58.294589057399548, 2.0027248751446649],
					[58.294589046849111, 2.0027248635451342],
					[58.294589041221265, 2.0027248595267952],
					[58.293930741220308, 2.0020665584675035],
					[58.293930737375298, 2.0020665540957268],
					[58.29393072888697, 2.002066547221967],
					[58.293930724150528, 2.0020665430270053],
					[58.29323904474488, 2.0015165593919111],
					[58.292702961967805, 2.000794283348585],
					[58.292177976322357, 2.0000499013846982],
					[58.29193628424278, 1.9994860211682774],
					[58.291936259815245, 1.9994859866532066],
					[58.291936200914897, 1.9994859315954221],
					[58.291936166442099, 1.9994859110527037],
					[58.290961165982367, 1.9991470114435266],
					[58.290961150455587, 1.9991470076786413],
					[58.290961117712321, 1.9991470021954167],
					[58.290961102278679, 1.9991470001233631],
					[58.290061101720156, 1.9991358999575704],
					[58.290061090038485, 1.999135900563757],
					[58.29006106587682, 1.9991359036457648],
					[58.290061053396833, 1.9991359061215879],
					[58.289202753019396, 1.9993415053451973],
					[58.289202750345112, 1.9993415058756954],
					[58.2892027432137, 1.9993415072903618],
					[58.289202739741107, 1.9993415096904448],
					[58.287411048141124, 1.9999054076759071],
					[58.285683255865287, 2.0002942058231263],
					[58.285683239912665, 2.0002942106978594],
					[58.285683208193589, 2.0002942238324577],
					[58.285683192427186, 2.00029423209235],
					[58.284685991584816, 2.0009331324269621],
					[58.284685983748133, 2.0009331374028978],
					[58.284685967369512, 2.0009331509166248],
					[58.284685958734485, 2.0009331577618799],
					[58.28401658167936, 2.0015942343759021],
					[58.282958228290184, 2.0021248129727431],
					[58.281844346255426, 2.0024359074938789],
					[58.281844333868413, 2.0024359116601573],
					[58.281844310970349, 2.0024359213317049],
					[58.281844298676397, 2.0024359271904069],
					[58.280869299709927, 2.0030054269575799],
					[58.280077701886547, 2.0034526264750445],
					[58.280077700995115, 2.0034526266517116],
					[58.279041610659256, 2.0040442216847585],
					[58.278155540192273, 2.0044248072654023],
					[58.277174958940655, 2.0046276032546926],
					[58.277174904880958, 2.0046276362035242],
					[58.277174825977831, 2.0046277253934051],
					[58.277174801134443, 2.004627781634428],
					[58.27705810087344, 2.0059277823175927],
					[58.27705810087344, 2.0059277823175927],
					[58.277058100168055, 2.0059277858785727],
					[58.277058100261065, 2.0059277875707684],
					[58.276944200637736, 2.0077666791509685],
					[58.276783102831416, 2.0088249654298997],
					[58.276408103605803, 2.01072776164981],
					[58.276408102807302, 2.0107277635184664],
					[58.27640810308619, 2.0107277685950007],
					[58.276408102287689, 2.0107277704636406],
					[58.276177601848737, 2.0122832708352236],
					[58.276177601329039, 2.0122832777803512],
					[58.276177600289657, 2.0122832916705904],
					[58.276177599769952, 2.0122832986157202],
					[58.276169199554289, 2.0141249922884654],
					[58.276030400113207, 2.0162027866747527],
					[58.276030400391875, 2.0162027917512932],
					[58.27603040015061, 2.0162028037729129],
				],
				[
					[
						[58.265624801392839, 2.1079278092014868],
						[58.265624802217793, 2.1079278244296304],
						[58.265883102082896, 2.1099472253519762],
						[58.265883138030688, 2.1099472901572982],
						[58.265883247325753, 2.1099473782758476],
						[58.265883318706443, 2.1099473985527899],
						[58.267216719460066, 2.1098195994099544],
						[58.267216752267458, 2.1098195895911998],
						[58.267216812874267, 2.1098195606706018],
						[58.267216840673647, 2.1098195415687662],
						[58.268927931602462, 2.1081168505488685],
						[58.270564005738223, 2.106847370942952],
						[58.272541770300663, 2.1058834886884332],
						[58.27464724673068, 2.1053362950725338],
						[58.276858342739679, 2.1048529952608681],
						[58.276858356914026, 2.104852990782776],
						[58.276858385171046, 2.1048529801340434],
						[58.276858398362052, 2.1048529741374922],
						[58.278866798426911, 2.1037195741492587],
						[58.27886679931855, 2.1037195739751215],
						[58.278866801101813, 2.1037195736268406],
						[58.278866801901707, 2.1037195717600721],
						[58.281502902006707, 2.1021612720039791],
						[58.281502912430909, 2.1021612648353689],
						[58.281502933187618, 2.1021612488053645],
						[58.281502941736832, 2.1021612402923986],
						[58.283755741747079, 2.0998834409078277],
						[58.283755742638704, 2.0998834407335569],
						[58.283755745221775, 2.0998834385179022],
						[58.28375574602159, 2.0998834366507682],
						[58.285269538217008, 2.0982640453557999],
						[58.28711952830281, 2.0967140531382813],
						[58.287119530086045, 2.096714052789538],
						[58.287119531777442, 2.0967140507477962],
						[58.287119534360443, 2.0967140485316818],
						[58.288658406656396, 2.0953307725386656],
						[58.290086172943383, 2.0947695867072653],
						[58.290086178201193, 2.094769583967484],
						[58.290086187025402, 2.0947695805299476],
						[58.290086192283191, 2.0947695777901534],
						[58.29178899178352, 2.0938890773365548],
						[58.291789020379682, 2.0938890563399619],
						[58.291789067121215, 2.0938890044135032],
						[58.291789085358467, 2.0938889751768373],
						[58.29286958500888, 2.0912194751796851],
						[58.292869587407949, 2.0912194695762638],
						[58.292869591314513, 2.0912194585439718],
						[58.29286959282198, 2.0912194531150972],
						[58.29377799293902, 2.0878972532833058],
						[58.293777993646692, 2.0878972497220816],
						[58.293777995861696, 2.0878972407317207],
						[58.293777997369048, 2.0878972353025813],
						[58.294280794615418, 2.0851750425888702],
						[58.294902994096113, 2.0827389497824873],
						[58.294902994619605, 2.0827389428344114],
						[58.294902998341335, 2.0827389284139346],
						[58.294902998772784, 2.0827389197725354],
						[58.295311298851175, 2.0787583206637432],
						[58.295311298759088, 2.0787583189704186],
						[58.295311298574944, 2.0787583155837464],
						[58.295311300265993, 2.0787583135406691],
						[58.295483499858904, 2.0762889135559335],
						[58.295483499766803, 2.0762889118626062],
						[58.295483499582573, 2.0762889084759397],
						[58.295483499582573, 2.0762889084759397],
						[58.295589099954704, 2.0735389139142613],
						[58.2957834991889, 2.0715472191856494],
						[58.295783499004543, 2.07154721579899],
						[58.295783499619588, 2.0715472105439532],
						[58.295783500326799, 2.0715472069822285],
						[58.295836300009178, 2.0699667116877221],
						[58.295997398601578, 2.0681694175678231],
						[58.295997398601578, 2.0681694175678231],
						[58.295997399400896, 2.0681694156993506],
						[58.295997399400896, 2.0681694156993506],
						[58.29602917865131, 2.067741488533442],
						[58.296589068638873, 2.0662723170235107],
						[58.297530631034583, 2.0654419388229286],
						[58.299355502815921, 2.0663612754202179],
						[58.300422096159288, 2.0670084711277954],
						[58.30042210267689, 2.067008474981936],
						[58.300422115619845, 2.0670084809966647],
						[58.300422122045227, 2.0670084831572559],
						[58.301269322373471, 2.067366883532856],
						[58.301269376881386, 2.0673668916498062],
						[58.30126948226939, 2.0673668743626608],
						[58.301269533241751, 2.0673668506521472],
						[58.302039033290249, 2.0666862500799272],
						[58.302039054379932, 2.0666862236823347],
						[58.302039087090272, 2.0666861624777813],
						[58.302039097911667, 2.0666861295396575],
						[58.302464098087235, 2.0639194293063601],
						[58.302464098609661, 2.0639194223565873],
						[58.302464099562222, 2.063919406763437],
						[58.302464100084634, 2.0639193998136647],
						[58.302447400286653, 2.0606444102927339],
						[58.302772398704199, 2.0576167205692277],
						[58.302772399226285, 2.0576167136193204],
						[58.302772400085708, 2.0576166963323002],
						[58.302772399623876, 2.0576166878642548],
						[58.302686299720662, 2.0562916914281546],
						[58.30263079965917, 2.0527999978340574],
						[58.302630797658487, 2.0527999776866199],
						[58.302630789199497, 2.0527999382696489],
						[58.302630782648762, 2.0527999173065141],
						[58.302036283054399, 2.051469317783678],
						[58.302036244712461, 2.0514692756951054],
						[58.302036153182925, 2.0514692166951107],
						[58.302036097320759, 2.0514692003105344],
						[58.300547196841869, 2.0514887002995414],
						[58.300547188818129, 2.0514887018799417],
						[58.300547170802716, 2.0514887020049755],
						[58.300547162779004, 2.0514887035853757],
						[58.299061100664304, 2.0517691955286623],
						[58.297722306576937, 2.0515081166276898],
						[58.296861251689201, 2.0504970703303687],
						[58.296861250705213, 2.0504970688126738],
						[58.296861247753277, 2.0504970642595755],
						[58.296861245970248, 2.0504970646107732],
						[58.295630754411818, 2.0491776726309827],
						[58.294522361445097, 2.0476637825559432],
						[58.294522352774003, 2.0476637722845825],
						[58.294522335524285, 2.0476637534350357],
						[58.294522327038173, 2.0476637465500325],
						[58.293480738846156, 2.0468026546324878],
						[58.292411249181868, 2.045616565729],
						[58.29241124829035, 2.0456165659046932],
						[58.292411248197844, 2.0456165642116217],
						[58.291183448714698, 2.0442748658885028],
						[58.289875150683919, 2.0428026697322745],
						[58.288800153174556, 2.041527670795646],
						[58.287772353228341, 2.0402998722692969],
						[58.287772324909291, 2.0402998487649979],
						[58.287772260803159, 2.0402998134955195],
						[58.287772225907538, 2.040299801554553],
						[58.286469426054197, 2.0401276027888189],
						[58.286469414279466, 2.0401276016885346],
						[58.286469389931064, 2.0401276013565508],
						[58.286469378156319, 2.0401276002562692],
						[58.285283278328478, 2.040260900199133],
						[58.285283273963529, 2.0402609027707315],
						[58.28528326763039, 2.0402609023084706],
						[58.285283264064368, 2.0402609030115788],
						[58.283519366609518, 2.0405804036959427],
						[58.281872175036725, 2.0408359018236752],
						[58.280669381741987, 2.0409470006820998],
						[58.280669380850483, 2.0409470008578254],
						[58.279524981295047, 2.0410581009733257],
						[58.279524945819844, 2.0410581113869384],
						[58.279524881757887, 2.0410581428312224],
						[58.279524853171147, 2.0410581638618979],
						[58.278538753059749, 2.0421276638253247],
						[58.278538741318961, 2.0421276798240835],
						[58.278538720511939, 2.0421277112945546],
						[58.278538713321247, 2.0421277281072943],
						[58.277958113876217, 2.0436555267070693],
						[58.277035913876368, 2.0459582260139815],
						[58.277035911571772, 2.0459582333100617],
						[58.277035907055129, 2.0459582495945843],
						[58.277035903951507, 2.0459582587585965],
						[58.276538707344919, 2.0482888443109952],
						[58.27579981962424, 2.050313811852051],
						[58.274585931242228, 2.0522248930919766],
						[58.274585928752451, 2.052224897002672],
						[58.274585923772911, 2.0522249048240804],
						[58.274585922174666, 2.0522249085593676],
						[58.273227621541984, 2.0548666085992191],
						[58.273227617546155, 2.0548666179367951],
						[58.273227609646902, 2.0548666383041647],
						[58.273227606635004, 2.0548666491586252],
						[58.272688706803009, 2.0568943470996515],
						[58.272122008842892, 2.0588777405158627],
						[58.271458109626217, 2.0608582359037855],
						[58.271458104769764, 2.0608582625118319],
						[58.271458102096915, 2.0608583126345144],
						[58.271458104465118, 2.0608583395333775],
						[58.271994203706825, 2.0635750389707317],
						[58.272338703996347, 2.0653361382960447],
						[58.272338707593349, 2.0653361546927176],
						[58.272338719060649, 2.0653361832263872],
						[58.272338726223857, 2.0653361989228265],
						[58.273394226386309, 2.0671917984051484],
						[58.273394235240232, 2.0671918120601513],
						[58.27339425642208, 2.0671918369778215],
						[58.273394266966932, 2.0671918485905234],
						[58.274483152308193, 2.0681640363689886],
						[58.275622005403676, 2.0697000724148977],
						[58.275760899319408, 2.0713693931259902],
						[58.275485903377167, 2.0732499663359678],
						[58.275099806146514, 2.0752999511330135],
						[58.274497012301389, 2.0771971317282283],
						[58.273697014898637, 2.0791332243333476],
						[58.273697014898637, 2.0791332243333476],
						[58.273697014099106, 2.0791332262003692],
						[58.273697014191164, 2.0791332278926609],
						[58.27276921529581, 2.0815221244254123],
						[58.271916518709737, 2.0834999143438049],
						[58.270772022220193, 2.0857110082277557],
						[58.270772022312151, 2.0857110099199359],
						[58.270772019821308, 2.0857110138279049],
						[58.270772019021678, 2.0857110156946357],
						[58.269316518837911, 2.0888055150562654],
						[58.269316515639204, 2.0888055225225619],
						[58.269316510225259, 2.0888055389728413],
						[58.269316507026538, 2.0888055464391213],
						[58.268530407174424, 2.0916943466194846],
						[58.267660907881556, 2.0947332454060645],
						[58.267660906282018, 2.0947332491387676],
						[58.267660904158213, 2.0947332598140558],
						[58.267660902558681, 2.0947332635467575],
						[58.26703310324433, 2.0981860643708901],
						[58.267033103336132, 2.0981860660629366],
						[58.267033101736487, 2.0981860697954238],
						[58.267033101828282, 2.0981860714874712],
						[58.266497001647927, 2.1019888723108839],
						[58.266497001831404, 2.1019888756949556],
						[58.266497001123263, 2.1019888792531392],
						[58.266497001306739, 2.1019888826372073],
						[58.266247002086821, 2.1048638701809854],
						[58.265624804176113, 2.1079277607813385],
						[58.265624802326151, 2.1079277765313167],
						[58.265624801392839, 2.1079278092014868],
					],
					[
						[58.29599740467502, 2.0677805770114808],
						[58.29605896796987, 2.0673349730728394],
						[58.296028784645159, 2.0677414016437279],
						[58.296005601194608, 2.067802237155929],
						[58.29599740467502, 2.0677805770114808],
					],
					[
						[58.29606030472091, 2.0673223743354319],
						[58.296069585915092, 2.0671974002950781],
						[58.296077569584654, 2.0671973993330264],
						[58.29606030472091, 2.0673223743354319],
					],
				],
			],
		},
		{
			fldNpdidField: "2138816",
			wlbNpdidWellbore: "1488",
			fldName: "SKIRNE",
			fldCurrentActivitySatus: "Producing",
			wlbName: "25/5-3",
			fldDiscoveryYear: "1990",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=2138816",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=2138816",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "102",
			cmpLongName: "TotalEnergies EP Norge AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=1488",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "35000016",
			fldNpdidOwner: "21620",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[59.599480399996061, 2.4663305920261083],
					[59.599480400789211, 2.466330607864903],
					[59.599480405139943, 2.4663306407807872],
					[59.599480407717301, 2.4663306562718872],
					[59.60034980782342, 2.4693389560505472],
					[59.600349810940187, 2.4693389643288355],
					[59.600349817085657, 2.4693389791254901],
					[59.600349820202432, 2.469338987403777],
					[59.601166520394671, 2.4710139878426349],
					[59.601166521286792, 2.4710139876688997],
					[59.601166521374857, 2.4710139894288674],
					[59.601166522355022, 2.4710139910150999],
					[59.601780421764154, 2.4722222904943343],
					[59.601780422832363, 2.4722222938406482],
					[59.601780424792707, 2.4722222970132406],
					[59.601780426753045, 2.4722223001858312],
					[59.602305427066433, 2.473136200044876],
					[59.605391526711379, 2.4785111991689166],
					[59.605391526799345, 2.478511200929133],
					[59.605391527779439, 2.4785112025157989],
					[59.607524827286454, 2.4821223008726916],
					[59.608702611913252, 2.4841500757632167],
					[59.609160897566696, 2.4861000142414986],
					[59.608988700790668, 2.4878805810199291],
					[59.608988700601287, 2.4878805952767662],
					[59.608988702094656, 2.4878806252042307],
					[59.608988703777428, 2.4878806408748186],
					[59.609494203701757, 2.4902722412621321],
					[59.609494205749499, 2.4902722461969931],
					[59.609494209040648, 2.4902722580004517],
					[59.609494211176191, 2.4902722646957853],
					[59.610541511152306, 2.4933694633060615],
					[59.610541514355418, 2.4933694733496679],
					[59.610541523438101, 2.4933694929172576],
					[59.610541528425514, 2.4933695026144482],
					[59.613358126647334, 2.498127899556843],
					[59.614808126324569, 2.5006861990749334],
					[59.614808127304407, 2.5006862006626758],
					[59.614808129176417, 2.5006862020773739],
					[59.614808129264098, 2.5006862038381734],
					[59.617394229349713, 2.5049140035418485],
					[59.619249818793776, 2.5080000871534649],
					[59.621119210006036, 2.5130417622473304],
					[59.621941506635487, 2.5159639537490248],
					[59.62194151072913, 2.5159639636255617],
					[59.621941517936762, 2.5159639817901041],
					[59.621941522030419, 2.5159639916666388],
					[59.623938722188988, 2.5198500914384732],
					[59.623938724235678, 2.5198500963772839],
					[59.623938729133805, 2.5198501043209585],
					[59.623938732072716, 2.5198501090871477],
					[59.626372021035586, 2.5236139915283751],
					[59.627694209339325, 2.5269778618974756],
					[59.628333100655922, 2.5294583277249236],
					[59.628435899694864, 2.5328361061390812],
					[59.628435899782126, 2.5328361079007071],
					[59.628435899956635, 2.5328361114239284],
					[59.628435900043897, 2.5328361131855379],
					[59.62857759973955, 2.5348806135273021],
					[59.628577604529696, 2.5348806375011534],
					[59.628577620355564, 2.5348806842432601],
					[59.628577631304054, 2.5348807052498854],
					[59.629574823013904, 2.5364694925335662],
					[59.63030261597887, 2.5381500791709346],
					[59.63030262096315, 2.5381500888805295],
					[59.630302631823973, 2.538150108127557],
					[59.630302637700488, 2.5381501176649905],
					[59.631388738405469, 2.5396473173241958],
					[59.631388779668676, 2.5396473502719346],
					[59.631388876229863, 2.5396473903380117],
					[59.631388928851159, 2.5396473979727241],
					[59.6320639288164, 2.5395473972867229],
					[59.632063983437511, 2.5395473725199653],
					[59.63206406684602, 2.5395472923974434],
					[59.632064095807742, 2.539547240565363],
					[59.632466895821885, 2.5375861407978553],
					[59.632466898344433, 2.5375861189670212],
					[59.632466897143907, 2.537586076510661],
					[59.632466895118078, 2.5375860537789081],
					[59.632008496177576, 2.5356332583851522],
					[59.631727996197704, 2.534213860507593],
					[59.631727989797433, 2.5342138403997234],
					[59.631727973428013, 2.5342138008730903],
					[59.631727961674407, 2.534213781798857],
					[59.630961281655317, 2.533158209869077],
					[59.630641905096773, 2.5320943882457434],
					[59.630950196612496, 2.5303111340245561],
					[59.630950198067026, 2.5303111088431387],
					[59.630950192771529, 2.5303110565081712],
					[59.63095018789322, 2.5303110307716064],
					[59.630358493346506, 2.5287166448099625],
					[59.629947401041214, 2.5266777850465005],
					[59.62998909370932, 2.5258779145576757],
					[59.631155705899637, 2.5251501692952232],
					[59.631155732597087, 2.5251501445684834],
					[59.631155775653539, 2.5251500864422103],
					[59.631155792992089, 2.5251500546318968],
					[59.631602993272054, 2.5235417534889653],
					[59.631602994830992, 2.5235417122785835],
					[59.631602983673574, 2.5235416326186564],
					[59.631602969085421, 2.5235415927524869],
					[59.631102969382674, 2.5227470935377045],
					[59.631102956910048, 2.52274707816396],
					[59.631102930267765, 2.5227470495232991],
					[59.631102916098143, 2.5227470362563942],
					[59.630608542092816, 2.5223971255271649],
					[59.631052904397357, 2.5212363873704668],
					[59.63181930177236, 2.5216695743770412],
					[59.63181930266456, 2.5216695742044366],
					[59.631819306320786, 2.521669575275769],
					[59.631819307300397, 2.5216695768649133],
					[59.632813807132258, 2.5221890777391995],
					[59.632813878665175, 2.5221890852456781],
					[59.632814009382002, 2.5221890510648581],
					[59.632814067673721, 2.5221890095501585],
					[59.633397336792562, 2.5212918568276774],
					[59.634925086722824, 2.5205557803952496],
					[59.634925090291624, 2.520555779704631],
					[59.634925096362153, 2.5205557749722303],
					[59.634925099843528, 2.5205557725197032],
					[59.635872299742637, 2.5200112734054878],
					[59.635872339209918, 2.5200112337471476],
					[59.6358723875652, 2.5200111372233196],
					[59.635872399217263, 2.5200110816017713],
					[59.63573079869554, 2.5184999810914932],
					[59.63573077805296, 2.518499928163549],
					[59.635730710789055, 2.5184998433466284],
					[59.635730664167703, 2.5184998114576551],
					[59.633969469811362, 2.5179026127987858],
					[59.631847274955447, 2.5170442145865559],
					[59.631847272191393, 2.517044213343004],
					[59.631847265771071, 2.5170442110286273],
					[59.631847263007032, 2.5170442097850567],
					[59.629275063405771, 2.5161887099407192],
					[59.629275061621392, 2.5161887102861824],
					[59.629275060641717, 2.5161887086973165],
					[59.629275059749517, 2.5161887088700494],
					[59.626022388368881, 2.5151665481039815],
					[59.625694601235431, 2.5113472075357306],
					[59.626094597039256, 2.5088528324931265],
					[59.626094597843853, 2.5088528305588182],
					[59.626094597756278, 2.5088528287973966],
					[59.626094597756278, 2.5088528287973966],
					[59.626397398414973, 2.5067111279080589],
					[59.626397399323253, 2.5067111099479797],
					[59.626397397571097, 2.5067110747196883],
					[59.626397394910668, 2.5067110574514615],
					[59.625616895290683, 2.5031666569794293],
					[59.625616893068084, 2.5031666485187367],
					[59.625616887818339, 2.5031666335317349],
					[59.625616885683371, 2.5031666268323884],
					[59.624805786668141, 2.5011332284460113],
					[59.624486314380334, 2.5002500026737069],
					[59.625111285875555, 2.4986389723499993],
					[59.625111293218289, 2.4986389389145685],
					[59.625111295238092, 2.4986388709454119],
					[59.625111289915139, 2.4986388364116929],
					[59.623602992093268, 2.4941249432661463],
					[59.622780793670586, 2.4908471513015655],
					[59.622780793582777, 2.4908471495403965],
					[59.622002999997072, 2.4878332765165632],
					[59.622039098290784, 2.4857000927498132],
					[59.623702880668006, 2.4842501947049644],
					[59.625233314298825, 2.4841390981484879],
					[59.625233375152973, 2.4841390720830616],
					[59.625233467358932, 2.4841389883455931],
					[59.625233497818584, 2.4841389308471213],
					[59.625452998284295, 2.4827111305470435],
					[59.625452996349843, 2.4827110917988526],
					[59.625452978206802, 2.482711017079962],
					[59.62545296110607, 2.4827109812828585],
					[59.624547361354367, 2.4814804803434596],
					[59.624547335947625, 2.481480458612745],
					[59.624547279957213, 2.4814804197155094],
					[59.624547247765214, 2.4814804064186733],
					[59.622516784937211, 2.4809776158071948],
					[59.622300118446745, 2.4808220389651243],
					[59.62008342016162, 2.4791665394206226],
					[59.620083399743045, 2.4791665273920311],
					[59.62008335836564, 2.4791665105524707],
					[59.620083336338737, 2.4791665023931504],
					[59.61918333574063, 2.4790026026441279],
					[59.619183312281365, 2.4790026018761573],
					[59.619183266430923, 2.479002603688456],
					[59.619183243235582, 2.4790026082033143],
					[59.618552818647849, 2.4791914856598924],
					[59.617694598966878, 2.4787581778705121],
					[59.617686300956926, 2.4775140063707393],
					[59.618500112445332, 2.4769668663323423],
					[59.618500129671801, 2.4769668505320994],
					[59.618500162164551, 2.4769668157572311],
					[59.618500176450659, 2.4769667951954135],
					[59.619033476153234, 2.4759833954528743],
					[59.619033484910403, 2.4759833724107998],
					[59.619033496984031, 2.4759833256081252],
					[59.619033500212446, 2.4759833000865767],
					[59.619033500013778, 2.4753583007505653],
					[59.619033499837741, 2.4753582972287154],
					[59.619033499573668, 2.4753582919459651],
					[59.619033500289746, 2.4753582882503724],
					[59.618983511032333, 2.4744695965655268],
					[59.619433190576203, 2.4744641008213577],
					[59.619658131282151, 2.4748168084143258],
					[59.619658168099583, 2.4748168421394383],
					[59.619658254147886, 2.474816887612771],
					[59.619658303378763, 2.4748168993610076],
					[59.620472203200691, 2.4748029998166259],
					[59.620472257267934, 2.4748029821727635],
					[59.620472347131646, 2.4748029237717586],
					[59.620472382840084, 2.4748028812536131],
					[59.621144583201925, 2.4732806806792089],
					[59.621144587850189, 2.4732806655481556],
					[59.621144596254652, 2.4732806354598402],
					[59.621144599118722, 2.4732806206764066],
					[59.621272398870218, 2.4720333193769291],
					[59.62127239941006, 2.4720333121589775],
					[59.621272400313593, 2.4720332942010277],
					[59.621272398981127, 2.4720332855697662],
					[59.62113080133463, 2.4700777992890721],
					[59.621216891996568, 2.4689223171826891],
					[59.622111204215443, 2.4683751717275078],
					[59.622111220636825, 2.4683751578561099],
					[59.622111249911086, 2.4683751308091111],
					[59.622111264460116, 2.4683751155245628],
					[59.623055764140567, 2.4670251143537967],
					[59.623055770802225, 2.4670250134702485],
					[59.623055692580373, 2.4670248544538116],
					[59.623055606981062, 2.4670248000171218],
					[59.621250061954925, 2.4669637016970474],
					[59.618814013613346, 2.4654026364476707],
					[59.617183419884221, 2.4641831403733763],
					[59.617183400356531, 2.4641831281808932],
					[59.617183357105162, 2.4641831099484399],
					[59.617183335165677, 2.4641831035603978],
					[59.615333334861695, 2.4638554022424937],
					[59.615333313186738, 2.463855401136986],
					[59.615333270101317, 2.4638554042080525],
					[59.615333247710602, 2.4638554067979328],
					[59.6135777476185, 2.4643276062543804],
					[59.613577716835707, 2.4643276211473086],
					[59.613577661955709, 2.4643276585181462],
					[59.613577637946655, 2.4643276827566494],
					[59.612144237809879, 2.4663081825227677],
					[59.612144235397977, 2.4663081883261646],
					[59.612144227809722, 2.4663081986942257],
					[59.612144225309649, 2.4663082027370837],
					[59.610760939643846, 2.4688192754947611],
					[59.609997173679183, 2.4695414490876928],
					[59.607600121205451, 2.4680692392309562],
					[59.605022335357297, 2.4657109520940512],
					[59.605022331612574, 2.4657109492693481],
					[59.605022325015277, 2.465710943446064],
					[59.605022322250818, 2.4657109422076062],
					[59.603214022287261, 2.4643165414261898],
					[59.603214005612294, 2.4643165322365004],
					[59.603213973242596, 2.46431651544325],
					[59.603213954871585, 2.4643165083614655],
					[59.602038968605001, 2.4639804111931425],
					[59.600322281720565, 2.4632081178539842],
					[59.600322214735094, 2.4632081113663715],
					[59.600322092153924, 2.4632081459289319],
					[59.600322035577946, 2.4632081853931442],
					[59.599563735987488, 2.4642859850594148],
					[59.599563723751906, 2.4642860105461559],
					[59.59956370480969, 2.4642860639959707],
					[59.599563699887277, 2.4642860916112097],
					[59.599480399996061, 2.4663305920261083],
				],
				[
					[59.574763702346978, 2.6427443693773482],
					[59.574763704318897, 2.6427444098386363],
					[59.574763724154863, 2.6427444842007408],
					[59.574763741297957, 2.6427445217889267],
					[59.575294240633042, 2.6434418213237496],
					[59.575294240633042, 2.6434418213237496],
					[59.575294241611189, 2.6434418229139731],
					[59.575294243396044, 2.6434418225759906],
					[59.575960942730312, 2.6442918223686176],
					[59.575960943794172, 2.6442918257181494],
					[59.575960945750452, 2.6442918288987225],
					[59.57596094664288, 2.6442918287297483],
					[59.576644247021207, 2.6451001287541662],
					[59.576644248891753, 2.6451001301755439],
					[59.576644251740454, 2.6451001331872717],
					[59.5766442528043, 2.6451001365368936],
					[59.577358153414153, 2.6458695363139695],
					[59.577358155284699, 2.6458695377354191],
					[59.577358157155246, 2.6458695391568661],
					[59.577358159111505, 2.6458695423376608],
					[59.578085959398081, 2.6465945413288456],
					[59.578085960376185, 2.6465945429192907],
					[59.57808596224676, 2.6465945443408012],
					[59.578085963224858, 2.6465945459312503],
					[59.578822063010385, 2.6472862451119341],
					[59.578822063988483, 2.6472862467024321],
					[59.578822064966587, 2.64728624829293],
					[59.578822065859029, 2.6472862481240149],
					[59.579563765621202, 2.6479584476342661],
					[59.579563766599293, 2.64795844922482],
					[59.58030816695419, 2.6486223492808256],
					[59.58030816695419, 2.6486223492808256],
					[59.580308167932287, 2.6486223508714297],
					[59.5803081688247, 2.6486223507025453],
					[59.581099869172036, 2.6493112502922975],
					[59.581099870150119, 2.6493112518829771],
					[59.581099872020644, 2.6493112533047629],
					[59.581099872913093, 2.6493112531358842],
					[59.581902672783187, 2.6499751544438759],
					[59.581902673675621, 2.6499751542750123],
					[59.582702674021917, 2.650622354351027],
					[59.582702674107551, 2.650622356110655],
					[59.582702675, 2.6506223559418003],
					[59.583508174118037, 2.6512695554691015],
					[59.584308173308713, 2.65192795474684],
					[59.584308173308713, 2.65192795474684],
					[59.585080473364911, 2.6525612546451196],
					[59.585080474257339, 2.6525612544763062],
					[59.585852672775843, 2.6531862540918016],
					[59.586619267775781, 2.65382795039692],
					[59.587374862266728, 2.654516844143016],
					[59.588199855249144, 2.6553473379630947],
					[59.589010951356052, 2.6562334331865762],
					[59.589813747726488, 2.6571557299220769],
					[59.590605446420014, 2.6581057285568477],
					[59.59139704564484, 2.6590640272337462],
					[59.59139704564484, 2.6590640272337462],
					[59.591397046622809, 2.6590640288251688],
					[59.592205447118658, 2.6600307272951103],
					[59.593016544186227, 2.6610029257555525],
					[59.593808138079275, 2.6620223173964814],
					[59.594558130800046, 2.6631334053743387],
					[59.595160924901464, 2.664166797797662],
					[59.595741521983243, 2.665266790765644],
					[59.596302620047943, 2.6664028864283043],
					[59.596849817588875, 2.6675583832422736],
					[59.59744151706844, 2.6688722813119719],
					[59.598019215686541, 2.6702167782386459],
					[59.598019216749776, 2.6702167815912126],
					[59.598605416886748, 2.671538981455873],
					[59.598605417779225, 2.6715389812874673],
					[59.598605418842425, 2.6715389846401205],
					[59.598605418927789, 2.6715389864006496],
					[59.599152618719636, 2.6726917866505162],
					[59.599152619612092, 2.6726917864821402],
					[59.599152619612092, 2.6726917864821402],
					[59.599152619697442, 2.6726917882427013],
					[59.599708119545383, 2.6738250874246305],
					[59.599708121415659, 2.6738250888485364],
					[59.59970812060854, 2.6738250907774814],
					[59.59970812239348, 2.6738250904407819],
					[59.600277622198739, 2.6749417915155806],
					[59.600277623091216, 2.6749417913472544],
					[59.600277623176545, 2.6749417931078825],
					[59.60085262337212, 2.6760361934527799],
					[59.60085262337212, 2.6760361934527799],
					[59.600852624349898, 2.6760361950451403],
					[59.600852624435198, 2.6760361968058022],
					[59.601463724358418, 2.6771583955150362],
					[59.601463725250895, 2.6771583953467619],
					[59.601463725336181, 2.6771583971074588],
					[59.602080425124697, 2.678266796825163],
					[59.602080426102461, 2.6782667984176438],
					[59.60208042708021, 2.6782668000101428],
					[59.60208042716549, 2.6782668017708779],
					[59.602924826981443, 2.6797084012032935],
					[59.602924917599466, 2.6797084498670949],
					[59.602925093804998, 2.6797084433084168],
					[59.602925180199712, 2.6797083861569564],
					[59.603683479904703, 2.6781306876708806],
					[59.603683482411576, 2.6781306836444583],
					[59.603683483855392, 2.6781306762646531],
					[59.603683486447544, 2.6781306739990463],
					[59.604002986177434, 2.6773333738556895],
					[59.604002986984618, 2.6773333719265713],
					[59.604002987621215, 2.6773333664757955],
					[59.604002989320861, 2.6773333643783794],
					[59.604225189083031, 2.6766833654222126],
					[59.6042251898049, 2.6766833617322314],
					[59.60422519141926, 2.6766833578739382],
					[59.604225192226437, 2.6766833559447947],
					[59.604422392163393, 2.6760028563884273],
					[59.604422392885226, 2.6760028526983914],
					[59.60442239522142, 2.6760028451500273],
					[59.604422395943267, 2.6760028414599941],
					[59.604572396370237, 2.6752917401573724],
					[59.604572396199593, 2.6752917366356659],
					[59.604572397643246, 2.6752917292555511],
					[59.604572398365065, 2.6752917255655029],
					[59.604666898183879, 2.6745722251051771],
					[59.604666898991006, 2.6745722231759435],
					[59.604666898735005, 2.6745722178933784],
					[59.604666898564339, 2.6745722143716537],
					[59.604722398649692, 2.6738222146608139],
					[59.604722400178602, 2.6738222090414658],
					[59.604722399666528, 2.6738221984763175],
					[59.604722400302975, 2.6738221930253667],
					[59.604697399736594, 2.6730916929985877],
					[59.604697400372999, 2.6730916875476161],
					[59.604697398968405, 2.6730916771508957],
					[59.604697397905234, 2.6730916737975949],
					[59.604630797737826, 2.6726082726119103],
					[59.604630796589262, 2.6726082674977722],
					[59.604630796247825, 2.6726082604543731],
					[59.60463079509929, 2.6726082553402475],
					[59.604522394868468, 2.6721332561871014],
					[59.604522394783089, 2.6721332544262486],
					[59.604522393634539, 2.6721332493121568],
					[59.604522392656676, 2.6721332477197515],
					[59.604400192592614, 2.6716777475690598],
					[59.6044001915294, 2.6716777442158368],
					[59.604400190295436, 2.6716777373409384],
					[59.60440018923223, 2.6716777339877154],
					[59.60414458884884, 2.6709416338071472],
					[59.604144587785619, 2.6709416304539748],
					[59.60414458378883, 2.6709416223237117],
					[59.604144582725596, 2.6709416189705393],
					[59.603844582985445, 2.6702638191873938],
					[59.60355018466214, 2.6695721246528898],
					[59.603189087933387, 2.6686305318354964],
					[59.602844590664304, 2.6676638418973897],
					[59.602572396183703, 2.6666416574753358],
					[59.602391898123308, 2.6656027711291967],
					[59.602269600173287, 2.6645110863164763],
					[59.602241899993082, 2.6634250064681999],
					[59.602305798675296, 2.6626389234640744],
					[59.60241959771885, 2.6618556297521745],
					[59.60253909803739, 2.6610722296151521],
					[59.60253909803739, 2.6610722296151521],
					[59.602539097951869, 2.6610722278544534],
					[59.602539097866362, 2.6610722260937552],
					[59.602627998399257, 2.6603889267193566],
					[59.602627998313736, 2.6603889249586401],
					[59.602627999206192, 2.660388924789912],
					[59.602711298696235, 2.6597028245527121],
					[59.602794598352688, 2.6590167257358055],
					[59.602889097993064, 2.6582917268060435],
					[59.6028890979075, 2.6582917250453377],
					[59.602889098799942, 2.6582917248765554],
					[59.602977998684928, 2.6575639246509146],
					[59.602977998599371, 2.6575639228901906],
					[59.602977999149573, 2.6575639156785544],
					[59.602977999956451, 2.657563913749041],
					[59.603027999725398, 2.6568278133918191],
					[59.603028000446713, 2.6568278097015758],
					[59.60302800001886, 2.6568278008980175],
					[59.603027999847725, 2.6568277973765819],
					[59.603016900429239, 2.6560166974629156],
					[59.603016898852132, 2.656016683546087],
					[59.603016893741909, 2.6560166525287254],
					[59.603016890379919, 2.6560166389496049],
					[59.602855790147615, 2.6555138391599091],
					[59.602855787042337, 2.6555138308629962],
					[59.602855778875764, 2.6555138110854779],
					[59.602855773900032, 2.655513801365577],
					[59.602700174272002, 2.655236002748639],
					[59.602700166362162, 2.655235988253358],
					[59.60270014633705, 2.6552359653891555],
					[59.60270013583547, 2.6552359531611351],
					[59.602477935672731, 2.6550304533581452],
					[59.602477887639559, 2.6550304286864121],
					[59.60247778580321, 2.6550304088646985],
					[59.602477732806889, 2.6550304117851731],
					[59.602036041255758, 2.6551887092472053],
					[59.601741655943712, 2.6552637045378336],
					[59.601444366775681, 2.6553220037423442],
					[59.60114997942128, 2.6553637010894562],
					[59.600749992649646, 2.6553915004097357],
					[59.600350000054618, 2.655391500684865],
					[59.60034999818415, 2.6553914992619907],
					[59.600349993721942, 2.6553915001062083],
					[59.600349992829507, 2.6553915002750506],
					[59.599827793099159, 2.6554109000443846],
					[59.599827785959626, 2.6554109013951033],
					[59.599827771594981, 2.6554109023359995],
					[59.599827765347875, 2.6554109035178795],
					[59.599669374926684, 2.6554387014066658],
					[59.599372215789444, 2.655460897164216],
					[59.599127906988336, 2.6554026197251774],
					[59.598939061075143, 2.6551915805588768],
					[59.598758480181097, 2.6548943125109035],
					[59.59862799399879, 2.6545499484418249],
					[59.598564099196246, 2.6541638743589462],
					[59.598519599707991, 2.6536527852650043],
					[59.59849190017372, 2.6531027945528916],
					[59.598491900176214, 2.6525250018304503],
					[59.598494600256437, 2.652150012292724],
					[59.598544598260965, 2.6517139297076584],
					[59.598658495130302, 2.6510917451911165],
					[59.598822390996027, 2.6504972598166767],
					[59.59901408673641, 2.649930671768054],
					[59.599291881393306, 2.6492750855085658],
					[59.599925018322011, 2.6480448005410451],
					[59.600855431320902, 2.6495057073830242],
					[59.600855433277097, 2.6495057105661575],
					[59.600855436125727, 2.6495057135802886],
					[59.60085543710381, 2.6495057151718751],
					[59.601527636837417, 2.6504445154214857],
					[59.601527638793584, 2.6504445186047381],
					[59.601527640749751, 2.6504445217880073],
					[59.601527641727834, 2.6504445233796301],
					[59.602219241770285, 2.6513307233188685],
					[59.602219242748355, 2.6513307249105522],
					[59.602219243640796, 2.6513307247415887],
					[59.602219244618865, 2.6513307263332733],
					[59.603344245196311, 2.6527168259233127],
					[59.603344323536518, 2.652716862624561],
					[59.603344481082786, 2.652716861232403],
					[59.603344559310806, 2.6527168215472243],
					[59.604164059358304, 2.6516445213766491],
					[59.604164061864317, 2.6516445173482435],
					[59.604164066876365, 2.6516445092914136],
					[59.604164069382399, 2.6516445052630049],
					[59.60431956875901, 2.6513973058627309],
					[59.604319569651452, 2.6513973056937514],
					[59.604319572157458, 2.6513973016652943],
					[59.604319572964265, 2.6513972997355562],
					[59.604469573034081, 2.6511390006513373],
					[59.604469573840873, 2.6511389987215885],
					[59.604469576432514, 2.6511389964538736],
					[59.604469576346872, 2.6511389946931092],
					[59.604683475980863, 2.65073899311647],
					[59.604683476787628, 2.6507389911866999],
					[59.604683479293627, 2.6507389871581473],
					[59.604683481078474, 2.6507389868201554],
					[59.604886280610827, 2.6503139861913461],
					[59.604886282224385, 2.6503139823317579],
					[59.604886283837914, 2.650313978472175],
					[59.604886284559036, 2.6503139747815814],
					[59.60506958490646, 2.6498611747828353],
					[59.60506958741243, 2.6498611707541837],
					[59.60506958974706, 2.6498611632039348],
					[59.605069591360596, 2.6498611593443075],
					[59.605236291470078, 2.6493278607368991],
					[59.605236292276828, 2.649327858807065],
					[59.605236292912224, 2.6493278533556173],
					[59.605236292826568, 2.64932785159482],
					[59.605386292545518, 2.648763952510258],
					[59.605541892189109, 2.6482139551899486],
					[59.605808491985194, 2.6472833578633446],
					[59.606094583624539, 2.6463778804302485],
					[59.606472361466423, 2.6457084213839592],
					[59.606658431853958, 2.6455195505213398],
					[59.606861197179086, 2.6453668762655456],
					[59.607066720187802, 2.645289105973482],
					[59.607280562325798, 2.6453251957004542],
					[59.607436030040603, 2.6453584905757848],
					[59.60774160300349, 2.6455223756666748],
					[59.608094300731182, 2.6457223741445119],
					[59.608094307149578, 2.6457223764823365],
					[59.608094318201545, 2.6457223814962703],
					[59.608094323813276, 2.6457223857641581],
					[59.60847771131786, 2.6458807800846391],
					[59.608758197200963, 2.6460445703848428],
					[59.609485986815976, 2.6464973647252714],
					[59.610144278974992, 2.6469945598566738],
					[59.610144281737973, 2.6469945611103247],
					[59.610144287349655, 2.6469945653786877],
					[59.610144290112643, 2.6469945666323369],
					[59.610741589242153, 2.6473890677199239],
					[59.610999888744814, 2.6475612669009712],
					[59.610999904430251, 2.6475612745924595],
					[59.610999935715448, 2.6475612882143378],
					[59.610999953100055, 2.6475612938064836],
					[59.611227752568965, 2.6476168942176388],
					[59.611227788694507, 2.6476168962582558],
					[59.611227860259994, 2.6476168862506295],
					[59.61122789489319, 2.6476168761325996],
					[59.611480694928943, 2.647480776689572],
					[59.611480724328651, 2.6474807515668677],
					[59.611480771990486, 2.6474806945453895],
					[59.611480790252592, 2.647480662646613],
					[59.611600190148451, 2.6471139621559261],
					[59.611600195281234, 2.6471139380773088],
					[59.611600199471205, 2.6471138946262851],
					[59.611600198356996, 2.6471138717316469],
					[59.61151959825699, 2.6465693712371214],
					[59.611519593915858, 2.6465693560636421],
					[59.611519585233559, 2.6465693257167056],
					[59.611519580085726, 2.6465693124734924],
					[59.611255780356359, 2.6460305116933429],
					[59.611255778400078, 2.646030508509452],
					[59.611255772616943, 2.6460305007189322],
					[59.611255770574907, 2.6460304957739433],
					[59.611052971359435, 2.645697095950875],
					[59.61105296940314, 2.6456970927670236],
					[59.61105296557627, 2.6456970881604445],
					[59.611052963619976, 2.6456970849765931],
					[59.610839063684857, 2.6453915843052096],
					[59.610839062792436, 2.6453915844743912],
					[59.610839061814289, 2.6453915828824934],
					[59.610839060836128, 2.6453915812905922],
					[59.610625160955351, 2.6450998816922002],
					[59.610625158999042, 2.6450998785084394],
					[59.610625153215842, 2.6450998707182083],
					[59.610625150367092, 2.6450998677036206],
					[59.610122349966055, 2.6445276686018433],
					[59.610122348009732, 2.6445276654181487],
					[59.610122342312231, 2.6445276593891678],
					[59.610122339463494, 2.6445276563746836],
					[59.60960294425881, 2.6440248607972321],
					[59.609100153995357, 2.6434637711752687],
					[59.608619562102902, 2.642841581549249],
					[59.608158472146215, 2.642166597652269],
					[59.607758481115226, 2.641386014426133],
					[59.607566885181967, 2.6409499247689912],
					[59.60739459200655, 2.6404860426467045],
					[59.607252999908297, 2.6398610763708454],
					[59.607236299592572, 2.6386638973475689],
					[59.607236290307817, 2.6386638546804986],
					[59.60723625227692, 2.6386637765934395],
					[59.607236223616589, 2.6386637429343209],
					[59.606694554895356, 2.6382359665003898],
					[59.606091873642796, 2.6371666012897541],
					[59.605641875158931, 2.6363582032323656],
					[59.605641872224169, 2.6363581984581881],
					[59.605641867332885, 2.6363581905012179],
					[59.605641864398109, 2.6363581857270288],
					[59.604889064016014, 2.6352831850381122],
					[59.604889063037753, 2.6352831834467856],
					[59.604889062145368, 2.6352831836161905],
					[59.604889062059492, 2.6352831818554607],
					[59.604125163763229, 2.6342359853068764],
					[59.603389071192119, 2.6331248953703232],
					[59.602780777394742, 2.6320249065929318],
					[59.602200179993346, 2.6308443138169824],
					[59.601639080748939, 2.6296332161914493],
					[59.601639080663013, 2.6296332144309047],
					[59.601069581341449, 2.6284277156388129],
					[59.601069580277162, 2.6284277122873441],
					[59.601069579298809, 2.6284277106963989],
					[59.601069578320455, 2.628427709105452],
					[59.600477977609785, 2.627274908539742],
					[59.600477976631424, 2.6272749069488568],
					[59.600477974674696, 2.6272749037670744],
					[59.600477973610381, 2.6272749004157152],
					[59.599869574378062, 2.6262027015763123],
					[59.599869573399708, 2.6262026999854862],
					[59.599869573313718, 2.6262026982250508],
					[59.599869572421333, 2.6262026983946627],
					[59.599247372885515, 2.6251526990284546],
					[59.598633473290896, 2.6240888000366542],
					[59.598341874138804, 2.6235777003709111],
					[59.59805297593963, 2.6230638054612827],
					[59.597777980675751, 2.6225305158064636],
					[59.59726408666382, 2.6213055282932132],
					[59.596814090984211, 2.6199749384120277],
					[59.596397391705828, 2.6185888437234683],
					[59.595986292004397, 2.6172027425964446],
					[59.595555792030851, 2.6157499445855632],
					[59.59513909288134, 2.6142777460765543],
					[59.59473349269188, 2.6127916471721124],
					[59.594733492605727, 2.6127916454119737],
					[59.594380792848959, 2.6115305478480799],
					[59.594036295437945, 2.6102582536989143],
					[59.593758497209095, 2.6089416643938681],
					[59.593636297732395, 2.6081666715205651],
					[59.593533499324131, 2.6073749770058883],
					[59.593455799050425, 2.6065777851303999],
					[59.593344600436716, 2.6044443897250211],
					[59.59334456166669, 2.6044443153995456],
					[59.59334444273054, 2.6044442226064586],
					[59.59334436256443, 2.6044442041388525],
					[59.591674963154233, 2.6047609024279237],
					[59.59078606303607, 2.6049276030023338],
					[59.59078606312233, 2.604927604762223],
					[59.590786062143721, 2.6049276031724498],
					[59.589899962360079, 2.6050970047953639],
					[59.589011062666486, 2.6052665032969822],
					[59.589011061774144, 2.6052665034670759],
					[59.58812776240179, 2.6054359044306667],
					[59.588127760617063, 2.6054359047708369],
					[59.588127758746118, 2.6054359033512511],
					[59.588127757939986, 2.6054359052810931],
					[59.587241657862023, 2.605627605121418],
					[59.587241656969667, 2.6056276052914913],
					[59.58724165518494, 2.6056276056316396],
					[59.587241654292605, 2.6056276058017085],
					[59.586352756917918, 2.6058359042929848],
					[59.58546936793438, 2.6060165014229524],
					[59.584580582140028, 2.6061248005410622],
					[59.583561095681773, 2.6061830992487054],
					[59.582538918153716, 2.6061636999191991],
					[59.581527850344301, 2.6059970059630428],
					[59.58066947820933, 2.6056887154321067],
					[59.579819492263447, 2.6052720225161425],
					[59.578972301765994, 2.6048054286646827],
					[59.578372309902164, 2.6044276327695863],
					[59.577777912982327, 2.6040220350026719],
					[59.577777908348054, 2.604022032334512],
					[59.577777898993205, 2.6040220252389741],
					[59.577777894358917, 2.6040220225708173],
					[59.576922294055663, 2.6035665230992753],
					[59.576922203957636, 2.6035665225134195],
					[59.576922058703317, 2.6035665910337737],
					[59.576922003633271, 2.6035666618991433],
					[59.576477604058091, 2.6058943612460439],
					[59.576160903602315, 2.6074582595586859],
					[59.576160903688539, 2.6074582613178214],
					[59.576160903774742, 2.6074582630769565],
					[59.576160902882393, 2.6074582632469063],
					[59.575869203041428, 2.6090305633417845],
					[59.575869203127645, 2.6090305651009058],
					[59.575869201601463, 2.6090305707181018],
					[59.575869201687645, 2.6090305724772436],
					[59.575649802212524, 2.6106360729980032],
					[59.575649802384859, 2.6106360765162604],
					[59.575649800772467, 2.610636080374221],
					[59.575649800944802, 2.6106360838924632],
					[59.575508100885486, 2.6123555845637929],
					[59.575508100885486, 2.6123555845637929],
					[59.575508100165408, 2.6123555882518472],
					[59.575508100337679, 2.6123555917701027],
					[59.575427599667023, 2.6140999915907668],
					[59.575427599667023, 2.6140999915907668],
					[59.575427599753141, 2.6140999933498881],
					[59.575427599839259, 2.6140999951090218],
					[59.575383099902581, 2.6158471952981714],
					[59.575383099902581, 2.6158471952981714],
					[59.57538309998867, 2.6158471970572976],
					[59.575383100074774, 2.6158471988164367],
					[59.575369200276491, 2.6174943971761011],
					[59.575369200362573, 2.6174943989352477],
					[59.575369199642353, 2.6174944026231812],
					[59.575369199642353, 2.6174944026231812],
					[59.575394200244951, 2.6191444025558401],
					[59.575394200331004, 2.6191444043149748],
					[59.575394200331004, 2.6191444043149748],
					[59.575435900165402, 2.6207944051056198],
					[59.575477599655379, 2.6224444056807661],
					[59.575477599655379, 2.6224444056807661],
					[59.575477599741383, 2.6224444074399331],
					[59.575541500202789, 2.6242972064922512],
					[59.575541500288772, 2.6242972082514133],
					[59.575541500288772, 2.6242972082514133],
					[59.575541500374754, 2.6242972100105892],
					[59.575630400216049, 2.6261528081637242],
					[59.575713700217833, 2.6280028070917569],
					[59.575772000443415, 2.6298556031884002],
					[59.575772000203578, 2.6317027965286623],
					[59.57571369970421, 2.6334582928626911],
					[59.575624799638526, 2.6352110882647035],
					[59.575499800825654, 2.6369582841450625],
					[59.57533870121312, 2.6386888781064117],
					[59.575113701657635, 2.6404221716715925],
					[59.574763702346978, 2.6427443693773482],
				],
			],
		},
		{
			fldNpdidField: "3437659",
			wlbNpdidWellbore: "0",
			fldName: "ENOCH",
			fldCurrentActivitySatus: "Producing",
			wlbName: "?",
			fldDiscoveryYear: "1985",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=3437659",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=3437659",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "ENOCH UNIT",
			cmpLongName: "Repsol Sinopec North Sea Limited",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=0",
			fldHcType: "OIL",
			fldMainArea: "North Sea",
			cmpNpdidCompany: "28222425",
			fldNpdidOwner: "3437805",
			fldDateUpdated: "2022-01-27 00:00:00",
			fldDateUpdatedMax: "2021-12-22 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[58.630935900681813, 1.5204749854247925],
					[58.630935900789559, 1.5204750026905482],
					[58.630935903676331, 1.5204750366478723],
					[58.630935907345801, 1.5204750531480189],
					[58.63412760028622, 1.5321944279077513],
					[58.634210899854025, 1.5391139023348726],
					[58.63421090075019, 1.5391139177038868],
					[58.634210907094449, 1.539113949194818],
					[58.634210910662013, 1.5391139639909761],
					[58.636847011189133, 1.5469028641863269],
					[58.636847014357969, 1.5469028721531393],
					[58.636847019705648, 1.546902886569703],
					[58.636847023764943, 1.5469028943457426],
					[58.637888691765717, 1.5488333357850548],
					[58.636944203932373, 1.5548138609423099],
					[58.635485905754344, 1.5608166538937607],
					[58.635485906951921, 1.5608167055090234],
					[58.635485936953025, 1.5608168028368608],
					[58.635485964866056, 1.5608168487398393],
					[58.638572065167928, 1.5636390479127469],
					[58.638572151358147, 1.5636390727181695],
					[58.638572308288232, 1.5636390426304705],
					[58.6385723799186, 1.5636389875469878],
					[58.640561280051116, 1.559588988592874],
					[58.640561283907829, 1.5595889773921308],
					[58.640561290830028, 1.5595889568891717],
					[58.640561293895537, 1.5595889475869638],
					[58.641044593997513, 1.5575861474344483],
					[58.642014085655688, 1.5535833796668612],
					[58.64401126798267, 1.5505223083840061],
					[58.644011271345761, 1.5505223042046872],
					[58.644011277082058, 1.5505222943285735],
					[58.644011280345744, 1.5505222884410488],
					[58.645502808232905, 1.5474837374234778],
					[58.648105393330361, 1.5523056371955348],
					[58.647172001763799, 1.5592749730024489],
					[58.647172001369896, 1.5592749817348845],
					[58.647172000284115, 1.5592749940746289],
					[58.647171999890183, 1.559275002807069],
					[58.647227600093714, 1.5642167012427366],
					[58.647227602175278, 1.5642167215531433],
					[58.647227608712193, 1.5642167564775293],
					[58.647227615147116, 1.5642167741274799],
					[58.650374815452686, 1.5719833755245927],
					[58.650374853356531, 1.5719834227800893],
					[58.650374950632454, 1.5719834832989539],
					[58.650375010202872, 1.5719834999794955],
					[58.65291670929993, 1.5718612992390295],
					[58.655461109242736, 1.571741900532923],
					[58.655461176822243, 1.5717418687929225],
					[58.655461272792017, 1.5717417669759566],
					[58.655461300291691, 1.5717416970892739],
					[58.655364100194703, 1.5638305982224971],
					[58.655316902024971, 1.5598779904207301],
					[58.658366708573276, 1.5597334992791374],
					[58.65836675992486, 1.5597334830970175],
					[58.658366845108993, 1.5597334285298485],
					[58.658366879732689, 1.5597333882452309],
					[58.658864080067957, 1.5587194882016626],
					[58.65886408965779, 1.558719451544377],
					[58.658864095181791, 1.5587193759625422],
					[58.658864090225478, 1.5587193372286292],
					[58.657280797600365, 1.5538471607388746],
					[58.656678007891564, 1.5459639986470419],
					[58.658191814988292, 1.5449029638230756],
					[58.658191818450696, 1.5449029613502754],
					[58.658191825375475, 1.5449029564046577],
					[58.658191827947391, 1.5449029541228465],
					[58.660708427840696, 1.5428029548424322],
					[58.660708436347257, 1.5428029460959369],
					[58.660708450788441, 1.5428029308851219],
					[58.660708458404507, 1.5428029223296937],
					[58.664719558176508, 1.537666823745566],
					[58.664719577355896, 1.5376667815549405],
					[58.664719594735885, 1.5376666930259613],
					[58.664719592936507, 1.5376666466875999],
					[58.663655792811042, 1.5337610470049317],
					[58.663655788651155, 1.5337610375156672],
					[58.663655781022527, 1.5337610149276191],
					[58.663655775972188, 1.5337610056296618],
					[58.662614124875596, 1.5318332967865544],
					[58.665097380324511, 1.5267667875321134],
					[58.665097389413503, 1.5267667578880493],
					[58.665097396606889, 1.5267666957705472],
					[58.665097396591904, 1.5267666646232132],
					[58.663464099858473, 1.517941679451603],
					[58.663355800396545, 1.5090443977245764],
					[58.663355754037724, 1.5090443142690892],
					[58.66335561436707, 1.5090442232396091],
					[58.663355520264844, 1.5090442175665124],
					[58.66082791944401, 1.5101525296747629],
					[58.658191896675788, 1.5023638593143294],
					[58.657600199424309, 1.495469382814848],
					[58.657600198133217, 1.4954693761725251],
					[58.657600196341257, 1.495469360987016],
					[58.657600195050165, 1.4954693543446931],
					[58.655991897136644, 1.4886193637236804],
					[58.655433498478622, 1.484688872638364],
					[58.655433485386723, 1.4846888339445594],
					[58.655433443577763, 1.4846887668558648],
					[58.655433416641415, 1.4846887380759843],
					[58.652869516942474, 1.4828331372895414],
					[58.652869467157593, 1.4828331186438639],
					[58.652869364741555, 1.4828331096488767],
					[58.652869313101064, 1.4828331208154002],
					[58.648316649771999, 1.4850276022531588],
					[58.644249990988598, 1.4852192000990769],
					[58.644249990098231, 1.4852192002914719],
					[58.638655590142228, 1.4854859007659105],
					[58.638655572234711, 1.4854859029052627],
					[58.638655536620298, 1.4854859105993188],
					[58.63865551990402, 1.4854859176693467],
					[58.636127720413313, 1.4865942172630369],
					[58.636127682440581, 1.4865942462150918],
					[58.636127623624674, 1.4865943194408655],
					[58.63612760357158, 1.4865943618147053],
					[58.634210904007453, 1.4965777607384501],
					[58.631808104186909, 1.5085638615904844],
					[58.631808102706003, 1.5085638670962138],
					[58.631808100734609, 1.5085638796233707],
					[58.631808101034544, 1.5085638847456699],
					[58.630935900681813, 1.5204749854247925],
				],
			],
		},
		{
			fldNpdidField: "34833026",
			wlbNpdidWellbore: "7988",
			fldName: "DUVA",
			fldCurrentActivitySatus: "Producing",
			wlbName: "36/7-4",
			fldDiscoveryYear: "2016",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=34833026",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=34833026",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "636",
			cmpLongName: "Neptune Energy Norge AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=7988",
			fldHcType: "OIL/GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "31123361",
			fldNpdidOwner: "21561151",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Florø",
			fldAreaGeometryWKT: [
				[
					[61.379555399676093, 4.0626639015324635],
					[61.379558100130346, 4.0628806028900293],
					[61.379558100130346, 4.0628806028900293],
					[61.379560899787549, 4.063094403000088],
					[61.379560901021016, 4.0630944121799519],
					[61.379560902661332, 4.0630944325452862],
					[61.379560904856959, 4.0630944434480396],
					[61.379602605338832, 4.0632833420999095],
					[61.379644204851338, 4.063472242182157],
					[61.379685904886074, 4.0636611437249996],
					[61.379727603633377, 4.0638500421943187],
					[61.379766503667781, 4.0640389390263678],
					[61.379802603038435, 4.0642250351408666],
					[61.379835903101828, 4.0644139326968993],
					[61.379866502615243, 4.0646028317043852],
					[61.379866502683029, 4.0646028335686504],
					[61.379866502683029, 4.0646028335686504],
					[61.379866502750829, 4.0646028354329129],
					[61.379899803191449, 4.0647917336576151],
					[61.379930402867721, 4.0649778321122625],
					[61.379960901723287, 4.0651667311808763],
					[61.379988700583276, 4.0653556221401539],
					[61.380002600796864, 4.0655417159612774],
					[61.380016499925908, 4.065730607569833],
					[61.380016500344986, 4.0659166998408001],
					[61.380016499941199, 4.0661055971914593],
					[61.380010899692827, 4.0662916946697827],
					[61.380005400036332, 4.0664777949147339],
					[61.380005400036332, 4.0664777949147339],
					[61.380005400104103, 4.0664777967790293],
					[61.380005400104103, 4.0664777967790293],
					[61.380002599770243, 4.0666666974479933],
					[61.37999979989231, 4.0668527967569643],
					[61.379996999826787, 4.0670388979087866],
					[61.379996999826787, 4.0670388979087866],
					[61.379996999894558, 4.0670388997730624],
					[61.379996999894558, 4.0670388997730624],
					[61.379996999852992, 4.0672278005248854],
					[61.379996999852992, 4.0672278005248854],
					[61.379996999920749, 4.0672278023891817],
					[61.379996999920749, 4.0672278023891817],
					[61.379999800430483, 4.0674111027864983],
					[61.380002599798829, 4.0675944033694549],
					[61.380002599866572, 4.0675944052337361],
					[61.380002600069837, 4.0675944108265938],
					[61.380002600205344, 4.0675944145551544],
					[61.380013699912304, 4.0677528134602898],
					[61.380024800384632, 4.0679111148374005],
					[61.38002480058789, 4.0679111204302645],
					[61.380024802850883, 4.0679111331978142],
					[61.380024803948494, 4.0679111386494435],
					[61.380047004268015, 4.0680222397746411],
					[61.380047004268015, 4.0680222397746411],
					[61.380069204362272, 4.0681306392287055],
					[61.380069206625244, 4.0681306519962916],
					[61.380069213698853, 4.0681306733792431],
					[61.380069218644955, 4.0681306857231849],
					[61.3801081188778, 4.068213984323485],
					[61.380147018167271, 4.0682972832724431],
					[61.380191517242437, 4.0683944807374761],
					[61.380233105540142, 4.068494453646502],
					[61.380252602095247, 4.0686278283086361],
					[61.380252602162983, 4.0686278301729342],
					[61.380252602162983, 4.0686278301729342],
					[61.380252603125086, 4.0686278318960492],
					[61.380274799278773, 4.0687639136342248],
					[61.380269199709161, 4.0689360935694525],
					[61.380269199709161, 4.0689360935694525],
					[61.380263700212481, 4.0691082857457808],
					[61.380244200964249, 4.0692943783787658],
					[61.380222001377803, 4.0694805763750246],
					[61.380222000551186, 4.0694805783804959],
					[61.380222000754358, 4.0694805839734105],
					[61.38022200082208, 4.0694805858377103],
					[61.380208100730677, 4.06966668475948],
					[61.380191500912787, 4.0698527821081054],
					[61.380191500221578, 4.0698527878421658],
					[61.38019149959807, 4.0698527954405428],
					[61.380191499733527, 4.0698527991691371],
					[61.380191500110904, 4.0700361000026524],
					[61.380191500240876, 4.0702194008477335],
					[61.380191500308584, 4.0702194027120351],
					[61.38019149968509, 4.0702194103103988],
					[61.380191499752826, 4.070219412174696],
					[61.380202600295355, 4.0703917136725165],
					[61.380202600295355, 4.0703917136725165],
					[61.380202601257444, 4.070391715395667],
					[61.380202601257444, 4.070391715395667],
					[61.380216500692754, 4.0705611152964689],
					[61.380216500828169, 4.0705611190250703],
					[61.380216501993409, 4.0705611263411443],
					[61.380216502061131, 4.0705611282054459],
					[61.380238701735117, 4.0707139296920296],
					[61.380238701735117, 4.0707139296920296],
					[61.380238701802838, 4.0707139315563499],
					[61.380238702697191, 4.0707139314152103],
					[61.38026370322514, 4.070869431721734],
					[61.38026370432263, 4.0708694371735312],
					[61.380263705623278, 4.0708694482182386],
					[61.380263707682879, 4.0708694553931961],
					[61.380302607844925, 4.0710056550999241],
					[61.380302607844925, 4.0710056550999241],
					[61.38030260798034, 4.0710056588285388],
					[61.3803026088747, 4.071005658687409],
					[61.380344209190348, 4.0711417588886398],
					[61.380344213174091, 4.0711417695099978],
					[61.380344223960151, 4.0711417940579553],
					[61.380344229732671, 4.0711418043970511],
					[61.380410930295923, 4.071250105525694],
					[61.380477629815324, 4.0713584053906891],
					[61.380477638338675, 4.071358417170809],
					[61.380477657106418, 4.0713584385844799],
					[61.380477667350803, 4.0713584482180503],
					[61.380552666127336, 4.0714251488034225],
					[61.380624863728677, 4.0714918473107629],
					[61.380624871154538, 4.0714918536391105],
					[61.380624885044142, 4.0714918645725886],
					[61.380624893296662, 4.0714918688954693],
					[61.380685989789704, 4.0715307671555871],
					[61.380747078847619, 4.0715723592642394],
					[61.38081377202473, 4.071627953102988],
					[61.380880461457764, 4.071683444634302],
					[61.380952651409771, 4.0717640320906909],
					[61.380952650583055, 4.0717640340961587],
					[61.381027645276937, 4.0718473256947618],
					[61.381088739036677, 4.0719307183800941],
					[61.381149839269526, 4.0720140184821716],
					[61.381149839269526, 4.0720140184821716],
					[61.381149841125968, 4.0720140200643122],
					[61.381149842088071, 4.0720140217875596],
					[61.381205438911664, 4.0720862183111537],
					[61.381258135697195, 4.0721612145622847],
					[61.38125814319072, 4.0721612227552804],
					[61.381258157283362, 4.0721612392823694],
					[61.381258164776852, 4.0721612474753721],
					[61.38132486322116, 4.0722223458591706],
					[61.381388762418574, 4.0722834454858772],
					[61.381388767025889, 4.0722834485091033],
					[61.381388778164585, 4.0722834580021265],
					[61.381388783733954, 4.0722834627486435],
					[61.381466583890663, 4.0723390637813974],
					[61.381466584785059, 4.0723390636402934],
					[61.381466586641494, 4.0723390652224865],
					[61.381466588430257, 4.0723390649402802],
					[61.381544287791733, 4.0723918657892257],
					[61.381544289648176, 4.0723918673714072],
					[61.381544294187783, 4.072391868530298],
					[61.381544296044233, 4.0723918701124795],
					[61.381630495679545, 4.0724445714748212],
					[61.381716593564718, 4.0724973697955837],
					[61.381799893104258, 4.0725501692213335],
					[61.381883188980446, 4.0726029676190718],
					[61.381947086034096, 4.0726473641955119],
					[61.381947086996135, 4.0726473659188285],
					[61.381947087890531, 4.072647365777728],
					[61.381947088784891, 4.0726473656366338],
					[61.382013770408896, 4.0726918537495491],
					[61.382066555390303, 4.0727473379288774],
					[61.382066557246738, 4.0727473395111069],
					[61.382066560065269, 4.0727473428166716],
					[61.382066561921725, 4.0727473443988984],
					[61.382122047271174, 4.072800130137967],
					[61.382177634047117, 4.0728807116119787],
					[61.382230426221156, 4.0729611998092299],
					[61.382274819649915, 4.073052887493561],
					[61.382274819649915, 4.073052887493561],
					[61.382274820611997, 4.0730528892168998],
					[61.382319211941748, 4.0731417704393724],
					[61.382349807418755, 4.0732556520038337],
					[61.382349806524395, 4.0732556521449119],
					[61.382380403992038, 4.0733667415586146],
					[61.382402602244035, 4.0735250270310237],
					[61.382402602311707, 4.0735250288954648],
					[61.382402602379393, 4.0735250307599031],
					[61.382402603341447, 4.0735250324832677],
					[61.382427601906834, 4.0736806263259764],
					[61.382447000654942, 4.0738639215845431],
					[61.382466500832393, 4.0740472179218363],
					[61.382480401339635, 4.0742361153996765],
					[61.382494200941338, 4.0744250158038815],
					[61.382494200941338, 4.0744250158038815],
					[61.382494201076675, 4.0744250195327929],
					[61.382494201144318, 4.0744250213972437],
					[61.382513700656794, 4.0746111216897498],
					[61.382533100871854, 4.0748000205274231],
					[61.382533100939511, 4.0748000223918748],
					[61.382533101901551, 4.0748000241153033],
					[61.382533102036874, 4.0748000278442049],
					[61.382558102286254, 4.0749861255759123],
					[61.382580401027354, 4.0751750234656559],
					[61.38258040205703, 4.0751750270535387],
					[61.382580403154385, 4.0751750325058893],
					[61.382580403289687, 4.0751750362348007],
					[61.382613702946415, 4.0753528353945212],
					[61.382644203082123, 4.0755306337798203],
					[61.382644204111827, 4.0755306373677183],
					[61.382644205276769, 4.0755306446845587],
					[61.382644206306473, 4.0755306482724558],
					[61.382683106331953, 4.0756833478188357],
					[61.382719205115954, 4.0758361462244173],
					[61.382719207107655, 4.0758361515358086],
					[61.38271921019669, 4.0758361622995789],
					[61.382719212188377, 4.0758361676109542],
					[61.382769211153025, 4.0759750665372936],
					[61.382816510653527, 4.0761111662317475],
					[61.382816511615566, 4.0761111679552311],
					[61.382816513607239, 4.0761111732666402],
					[61.382816514636943, 4.0761111768545755],
					[61.382883114826136, 4.0762722769432624],
					[61.382883114826136, 4.0762722769432624],
					[61.38288311578814, 4.0762722786667593],
					[61.382952615731014, 4.0764333779462882],
					[61.383035915208595, 4.0766361754618279],
					[61.383119214326705, 4.0768389721975806],
					[61.383199812347009, 4.0770556687594084],
					[61.383277610732748, 4.0772722657467177],
					[61.383338709709179, 4.0774583615973388],
					[61.383397009211684, 4.0776444586976854],
					[61.383452608284706, 4.0778278561398213],
					[61.38350540788408, 4.0780111553413994],
					[61.38350540788408, 4.0780111553413994],
					[61.383563707953805, 4.0782111568647288],
					[61.383622007601574, 4.0784111554137805],
					[61.383672007207991, 4.0785917530312954],
					[61.383672007207991, 4.0785917530312954],
					[61.383672008170009, 4.0785917547548829],
					[61.38367200823761, 4.0785917566194128],
					[61.383724807793932, 4.0787722567454816],
					[61.383724807793932, 4.0787722567454816],
					[61.383774807627447, 4.0789389565205836],
					[61.383824808156227, 4.0791056566939421],
					[61.383824809118231, 4.0791056584175562],
					[61.383824809185825, 4.079105660282095],
					[61.383874808987059, 4.0792639601953313],
					[61.383874808987059, 4.0792639601953313],
					[61.38387480994902, 4.0792639619189597],
					[61.383927609545736, 4.079425061540574],
					[61.383927609613316, 4.0794250634051217],
					[61.383927610642893, 4.0794250669933039],
					[61.383927611604882, 4.0794250687169331],
					[61.383972011673592, 4.0795472672333135],
					[61.3839720117412, 4.0795472690978638],
					[61.383972012703204, 4.0795472708214984],
					[61.383972012770784, 4.0795472726860487],
					[61.384019212855748, 4.0796694710738226],
					[61.384058113378202, 4.0797722713190367],
					[61.384058113378202, 4.0797722713190367],
					[61.384058114340164, 4.0797722730427051],
					[61.384058115302153, 4.0797722747663547],
					[61.384099811062327, 4.0798750661906444],
					[61.384138708828466, 4.0800028583169823],
					[61.384138708828466, 4.0800028583169823],
					[61.38413870889606, 4.0800028601815619],
					[61.38413870889606, 4.0800028601815619],
					[61.384180408425777, 4.0801333559737705],
					[61.384224806780807, 4.0803028515053859],
					[61.384224806780807, 4.0803028515053859],
					[61.384224806848387, 4.080302853369953],
					[61.384272006803457, 4.0804750509083894],
					[61.384319206399887, 4.080663947844883],
					[61.384319206467453, 4.0806639497094768],
					[61.384366505692874, 4.0808528483693136],
					[61.384366506722394, 4.080852851957613],
					[61.384366507684383, 4.0808528536813098],
					[61.384366507751949, 4.0808528555458858],
					[61.384422008350192, 4.0810417550155647],
					[61.384474806530442, 4.0812306532014722],
					[61.384474807559947, 4.0812306567897769],
					[61.384474809619029, 4.081230663966398],
					[61.384474810648562, 4.0812306675547001],
					[61.384541511228278, 4.0814194676850777],
					[61.384608110709458, 4.0816083651439445],
					[61.384672009697418, 4.0817972648504606],
					[61.384735906931063, 4.0819889540603791],
					[61.384777604668336, 4.0821750429801016],
					[61.384819203092178, 4.0823639346248264],
					[61.384844202355083, 4.0825472268449277],
					[61.384866502448659, 4.0827278257828956],
					[61.384866501554278, 4.0827278259237216],
					[61.384866501689373, 4.0827278296529448],
					[61.384866502651327, 4.0827278313767321],
					[61.384891501963615, 4.0828833291090563],
					[61.384913702160318, 4.083036128600706],
					[61.384913704354375, 4.0830361395067811],
					[61.384913709636919, 4.0830361611781321],
					[61.384913713687297, 4.0830361736671925],
					[61.384955414050445, 4.0831417728521284],
					[61.384955414117989, 4.0831417747167489],
					[61.384955414117989, 4.0831417747167489],
					[61.384955415012371, 4.0831417745759344],
					[61.384997014806856, 4.0832444757133004],
					[61.384997022569898, 4.08324449136843],
					[61.384997041606042, 4.0832445202508003],
					[61.38499705294668, 4.0832445353426783],
					[61.385063752910334, 4.0833168363847108],
					[61.385063753804708, 4.0833168362439025],
					[61.385063754766662, 4.0833168379677156],
					[61.385063755728574, 4.083316839691534],
					[61.385130455747664, 4.0833862381110073],
					[61.385130466885684, 4.0833862476090914],
					[61.385130490056028, 4.0833862664644629],
					[61.385130502020907, 4.0833862739571085],
					[61.385219301591945, 4.0834362742826604],
					[61.385219301591945, 4.0834362742826604],
					[61.385219303448309, 4.0834362758656733],
					[61.385219304342698, 4.0834362757248677],
					[61.385305503638207, 4.0834834752161315],
					[61.385305514641132, 4.0834834809850218],
					[61.38530553926261, 4.083483490235726],
					[61.38530555109238, 4.083483493999152],
					[61.385394351088486, 4.0835057931246732],
					[61.385394352944843, 4.0835057947076994],
					[61.385394355628002, 4.0835057942852817],
					[61.385394356522426, 4.0835057941444717],
					[61.385483256629996, 4.0835251953075096],
					[61.385483269286645, 4.0835251970655086],
					[61.385483293705455, 4.0835252007223461],
					[61.385483306294567, 4.0835252006156946],
					[61.385575005625284, 4.0835224002922272],
					[61.385669406157035, 4.083519600371428],
					[61.385669410629021, 4.083519599667385],
					[61.385669423218076, 4.0835195995607343],
					[61.385669427690075, 4.0835195988566886],
					[61.385766728195051, 4.0835057975134124],
					[61.385766729089475, 4.0835057973726014],
					[61.385766731772627, 4.0835057969501793],
					[61.385766732667044, 4.0835057968093675],
					[61.385866731020784, 4.0834890970923192],
					[61.385963928121022, 4.0834751974228745],
					[61.386063903878274, 4.0834613008856255],
					[61.386149981257667, 4.0834695983896863],
					[61.386236040615429, 4.0834779944075663],
					[61.386308203247268, 4.0835168753076223],
					[61.38638047088908, 4.0835584572151076],
					[61.386438747094125, 4.0836251287721215],
					[61.386494231227523, 4.0836945074314519],
					[61.38654422138557, 4.0837944886893185],
					[61.386591517243538, 4.0838917813276137],
					[61.386644213972552, 4.084025072889979],
					[61.386697014066208, 4.084161172903122],
					[61.386697014133752, 4.0841611747678481],
					[61.386697016057596, 4.084161178215707],
					[61.386697017087052, 4.0841611818043813],
					[61.386766517162769, 4.0843167826962041],
					[61.386766518057179, 4.084316782555411],
					[61.386766518124695, 4.0843167844201425],
					[61.386766519019119, 4.0843167842793466],
					[61.386838719095373, 4.0844722833907294],
					[61.386838720124821, 4.0844722869794294],
					[61.386838723078114, 4.0844722940160043],
					[61.386838725001979, 4.0844722974639138],
					[61.386922025206523, 4.084622297415299],
					[61.387005425071663, 4.0847722974550846],
					[61.387005426995493, 4.0847723009030155],
					[61.387005430775652, 4.0847723059341492],
					[61.387005431805115, 4.0847723095228554],
					[61.387094232457777, 4.0849084094792349],
					[61.387094233352201, 4.0849084093384551],
					[61.387183132799983, 4.0850445094958747],
					[61.387183132799983, 4.0850445094958747],
					[61.387183133761894, 4.0850445112198583],
					[61.387183134723806, 4.0850445129438411],
					[61.387272034569712, 4.0851751132620944],
					[61.387272036426026, 4.0851751148453159],
					[61.387360936351662, 4.085302914025978],
					[61.387360937313552, 4.0853029157499821],
					[61.387360939169888, 4.0853029173332107],
					[61.387360940131778, 4.0853029190572334],
					[61.387449839853808, 4.085422319045497],
					[61.387538740372428, 4.085541818961949],
					[61.387538741401819, 4.0855418225507698],
					[61.387538745181963, 4.0855418275820599],
					[61.387538747038299, 4.0855418291653125],
					[61.387627647032097, 4.0856473298654539],
					[61.387627647926507, 4.0856473297246918],
					[61.387627648888412, 4.0856473314487189],
					[61.387716549013341, 4.0857501302312054],
					[61.387716552793492, 4.0857501352625638],
					[61.387716561180639, 4.0857501433197054],
					[61.387716565855129, 4.0857501482103142],
					[61.387805466397026, 4.0858307481083127],
					[61.387894266114685, 4.0859112480387738],
					[61.387894275396242, 4.0859112559552644],
					[61.387894295680574, 4.0859112696419482],
					[61.38789430585652, 4.0859112774176882],
					[61.387983204223353, 4.0859584751928013],
					[61.38807210229654, 4.0860084732997777],
					[61.388072116050054, 4.0860084805125902],
					[61.388072144316482, 4.0860084910678127],
					[61.388072158002494, 4.0860084964158112],
					[61.388149958036166, 4.0860251967375465],
					[61.388227758000603, 4.0860418952686937],
					[61.388227767974023, 4.0860418974500705],
					[61.388227788747713, 4.0860418998072756],
					[61.388227800442408, 4.0860418998423311],
					[61.388286100251086, 4.0860418999148935],
					[61.388286102039906, 4.086041899633388],
					[61.388286106579393, 4.0860419007944575],
					[61.388286110156976, 4.0860419002314536],
					[61.388344410245182, 4.0860391002001748],
					[61.388344419189146, 4.0860390987926589],
					[61.38834443707713, 4.0860390959776192],
					[61.388344446021094, 4.0860390945701042],
					[61.388402846218646, 4.0860251948073225],
					[61.388402848901855, 4.0860251943850612],
					[61.38840285509513, 4.0860251915349615],
					[61.38840285777831, 4.0860251911127063],
					[61.388458358306714, 4.0860084918172497],
					[61.388458359201117, 4.0860084916764965],
					[61.388458360095541, 4.0860084915357398],
					[61.388458360922407, 4.0860084895301547],
					[61.388536160802381, 4.0859834909072044],
					[61.388613955241901, 4.0859584911306559],
					[61.388702848367686, 4.0859362944579862],
					[61.388794421225661, 4.0859141016516993],
					[61.388883293625497, 4.0859169007126805],
					[61.388972153367675, 4.08591959861762],
					[61.389061014815212, 4.085961280369899],
					[61.389061016671512, 4.0859612819532529],
					[61.38906102031666, 4.0859612832550845],
					[61.389061022172953, 4.0859612848384401],
					[61.389152688622048, 4.0860001697394708],
					[61.389241563474393, 4.0860834467443112],
					[61.389241564368788, 4.0860834466035536],
					[61.389241565330693, 4.0860834483276989],
					[61.389241566225088, 4.0860834481869421],
					[61.389330458086533, 4.0861640409864259],
					[61.389419249757317, 4.0862640334899778],
					[61.389419250651734, 4.0862640333492246],
					[61.389508151354086, 4.0863640336257427],
					[61.389508154104796, 4.0863640350684083],
					[61.389508159808699, 4.0863640435484232],
					[61.389508162626875, 4.0863640468559774],
					[61.389597063195083, 4.0864473461093063],
					[61.389685962822078, 4.0865307453713262],
					[61.389685974921775, 4.0865307565960203],
					[61.389686001601866, 4.0865307730284348],
					[61.389686015355366, 4.0865307802418158],
					[61.389774915013035, 4.0865723816626858],
					[61.389774915907402, 4.0865723815219441],
					[61.389774918658112, 4.0865723829646408],
					[61.389774919552501, 4.0865723828238973],
					[61.389863820383624, 4.0866112834980299],
					[61.389863841292325, 4.086611289585524],
					[61.389863883869111, 4.0866112978899336],
					[61.389863906431586, 4.0866112999660853],
					[61.389952806377273, 4.086608499985493],
					[61.389952808166079, 4.0866084997040026],
					[61.389952810849302, 4.0866084992817644],
					[61.389952812705587, 4.0866085008652195],
					[61.390038912812294, 4.0866029998919267],
					[61.390038928911494, 4.0866029973585025],
					[61.390038960147976, 4.0866029905674708],
					[61.390038976112166, 4.0866029843041858],
					[61.390113974753945, 4.0865723853588474],
					[61.390188975175462, 4.0865418853820339],
					[61.390188988456465, 4.0865418795409267],
					[61.390189013094655, 4.0865418644103153],
					[61.390189025413719, 4.0865418568450043],
					[61.390244525362462, 4.0864973559053324],
					[61.390300125180509, 4.086452955370107],
					[61.390300131306297, 4.0864529506549552],
					[61.390300141701559, 4.0864529396411902],
					[61.390300147827361, 4.0864529349260197],
					[61.390355745898049, 4.0863918362510576],
					[61.390411244730785, 4.0863334387974275],
					[61.390411247346499, 4.0863334365101878],
					[61.39041125072157, 4.0863334303523109],
					[61.390411253337284, 4.0863334280650863],
					[61.390469553399896, 4.0862640281414437],
					[61.390527946356904, 4.0861945364306003],
					[61.390583437584922, 4.0861418450206486],
					[61.390639005202097, 4.0860890776237451],
					[61.390708360782185, 4.0860668898054797],
					[61.390777799384075, 4.0860446100391528],
					[61.390861036699228, 4.0860723903135732],
					[61.390861039382408, 4.086072389891279],
					[61.390861042133118, 4.0860723913339587],
					[61.390861043989418, 4.0860723929174076],
					[61.390947095391297, 4.0860973784154409],
					[61.391027656676464, 4.0861779398353191],
					[61.391105438277243, 4.0862584214557183],
					[61.391166525622438, 4.0863667973308306],
					[61.391166525689933, 4.0863667991958303],
					[61.391166526651844, 4.0863668009200653],
					[61.391227619972106, 4.08647228779708],
					[61.391283115019299, 4.0866083754612923],
					[61.391283115086786, 4.0866083773262982],
					[61.391338714094694, 4.0867417744405659],
					[61.391405413749077, 4.0869139727163644],
					[61.391405413749077, 4.0869139727163644],
					[61.391405413816578, 4.086913974581373],
					[61.391474814098117, 4.0870889728278641],
					[61.391547012645219, 4.0872778708214899],
					[61.391547012712707, 4.0872778726865135],
					[61.391547013607081, 4.0872778725457808],
					[61.391547013674575, 4.0872778744108045],
					[61.391622013884771, 4.0874667731893384],
					[61.391697012570511, 4.0876583721451851],
					[61.391769212006672, 4.0878472687992868],
					[61.391835910817662, 4.088036167018803],
					[61.391902609171815, 4.0882250604552199],
					[61.391955407339658, 4.0884139525032817],
					[61.392005405122362, 4.0886028449086576],
					[61.392038703156153, 4.0887806368063755],
					[61.392072001588467, 4.0889611318411454],
					[61.392094202217777, 4.0891250268767045],
					[61.39211650177824, 4.0892889271944517],
					[61.39211650177824, 4.0892889271944517],
					[61.392116501845699, 4.0892889290595162],
					[61.392138702295128, 4.0894389295210232],
					[61.392138702295128, 4.0894389295210232],
					[61.392160902036046, 4.0895861298991676],
					[61.39216090313284, 4.0895861353537075],
					[61.39216090525894, 4.0895861443977095],
					[61.392160906355763, 4.0895861498522281],
					[61.392197005969386, 4.089725049573441],
					[61.392197006931276, 4.0897250512978376],
					[61.392197006998728, 4.0897250531629066],
					[61.392197006998728, 4.0897250531629066],
					[61.392235906603908, 4.0898639536109354],
					[61.392235912442544, 4.0898639658224729],
					[61.392235922330954, 4.0898639905268546],
					[61.392235928169562, 4.0898640027383957],
					[61.392299826770348, 4.089972301559456],
					[61.392360927304154, 4.0900778990694793],
					[61.392360953629073, 4.090077930567575],
					[61.392361017366547, 4.0900779768144986],
					[61.392361053057755, 4.0900779937097616],
					[61.392441652888152, 4.0900973942656726],
					[61.39244165474441, 4.0900973958494378],
					[61.392441658321999, 4.0900973952868016],
					[61.392441659216423, 4.0900973951461372],
					[61.392522158688834, 4.0901140955612494],
					[61.39252221344983, 4.0901140925762798],
					[61.392522310265292, 4.0901140585934295],
					[61.392522354108578, 4.090114027314228],
					[61.392591853953377, 4.0900307288464095],
					[61.392591853885911, 4.0900307269813192],
					[61.392591854780292, 4.0900307268406584],
					[61.392591855607265, 4.0900307248349002],
					[61.392661255869271, 4.0899445249173203],
					[61.392661265302991, 4.0899445121794002],
					[61.392661281352247, 4.0899444833953522],
					[61.392661287967847, 4.0899444673492331],
					[61.392708486762523, 4.0898139694081452],
					[61.392758487348402, 4.089683371763555],
					[61.39275848817536, 4.0896833697577781],
					[61.392758488867386, 4.0896833640218002],
					[61.392758489694344, 4.0896833620160224],
					[61.392797389845882, 4.0895639635008063],
					[61.392836286305275, 4.0894472756702527],
					[61.392880780798961, 4.0893528853059236],
					[61.39292516210908, 4.0892584261820728],
					[61.392989026767403, 4.0892057544927605],
					[61.393052860015615, 4.0891530106151146],
					[61.393133269938886, 4.0891640976056225],
					[61.393216603820527, 4.0891779866184956],
					[61.393291558686307, 4.0892529419229664],
					[61.393366531182416, 4.0893279135589857],
					[61.393424816031221, 4.089463979610561],
					[61.393483110435021, 4.0896000652809121],
					[61.393533106967723, 4.0897889518567174],
					[61.393533106967723, 4.0897889518567174],
					[61.393533107035189, 4.0897889537218655],
					[61.393533107997079, 4.089788955446334],
					[61.393585905923537, 4.0899750497886496],
					[61.393627604755643, 4.0901611439708612],
					[61.393669203384654, 4.0903472356207287],
					[61.393691502219163, 4.0905083275802099],
					[61.393713700541646, 4.0906722173227843],
					[61.393721999692737, 4.0908556081831788],
					[61.393730400099912, 4.0910417068118639],
					[61.393733099601235, 4.0912556023525406],
					[61.393733099668658, 4.0912556042177215],
					[61.393738700266738, 4.0914667045289663],
					[61.393738699574634, 4.0914667102650908],
					[61.393738700941107, 4.091466723180635],
					[61.393738702105239, 4.0914667305006871],
					[61.393763701979758, 4.0916333299892482],
					[61.393788701648987, 4.0918000291695966],
					[61.393788705833494, 4.0918000453936605],
					[61.393788715029466, 4.0918000758360176],
					[61.39378872093539, 4.0918000899136837],
					[61.393835920805394, 4.091894488135563],
					[61.39388312021164, 4.0919917866601105],
					[61.393883123097169, 4.0919917918338093],
					[61.393883127973787, 4.0919918023218047],
					[61.393883130859251, 4.0919918074955106],
					[61.393935930736028, 4.0920751066194052],
					[61.393985919421823, 4.0921556872660565],
					[61.39402481172641, 4.0922611691987356],
					[61.394024812688215, 4.0922611709233179],
					[61.394024813717493, 4.0922611745130935],
					[61.394024814611861, 4.0922611743724895],
					[61.394066507437287, 4.0923639578523625],
					[61.394091502935289, 4.0924944363826077],
					[61.394116500848639, 4.0926278222055563],
					[61.394121999537568, 4.0928000053166516],
					[61.394121999604984, 4.092800007181844],
					[61.3941219996724, 4.0928000090470356],
					[61.394121999739831, 4.0928000109122262],
					[61.394130400086098, 4.0929721975314974],
					[61.394116499731119, 4.0931693860039999],
					[61.394102601141647, 4.0933666809113189],
					[61.394077601612196, 4.0935527736064472],
					[61.394052602392463, 4.0937360691274076],
					[61.394022002830845, 4.0939138667745105],
					[61.393991503426427, 4.0940888665205755],
					[61.393991503426427, 4.0940888665205755],
					[61.39399150170501, 4.0940888686668711],
					[61.393991501772426, 4.0940888705320573],
					[61.393960901641918, 4.0942888692571229],
					[61.393960901709306, 4.0942888711223118],
					[61.393960901709306, 4.0942888711223118],
					[61.393960901776708, 4.0942888729874953],
					[61.393933101927566, 4.0944916726088136],
					[61.393933101994946, 4.0944916744739999],
					[61.393933101302707, 4.0944916802101181],
					[61.393933101370109, 4.0944916820753026],
					[61.393913700883679, 4.0947082802847108],
					[61.393891501395068, 4.0949221797952458],
					[61.39389150056806, 4.0949221818009578],
					[61.393891499943201, 4.0949221894022436],
					[61.393891500010589, 4.0949221912674281],
					[61.393883100309473, 4.0951249917350365],
					[61.393874800123108, 4.0953277914314796],
					[61.393874800190467, 4.0953277932966659],
					[61.393874800257862, 4.0953277951618494],
					[61.393874800325236, 4.095327797027033],
					[61.393872000014277, 4.0955166977976196],
					[61.393869199800221, 4.0957082977269081],
					[61.393866499975616, 4.0958805970071346],
					[61.393866500042996, 4.0958805988723386],
					[61.393866500042996, 4.0958805988723386],
					[61.393866500110356, 4.0958806007375239],
					[61.3938665001887, 4.0960527966084248],
					[61.393860899991395, 4.0961943910672103],
					[61.393860900058776, 4.0961943929323947],
					[61.393860900126143, 4.0961943947976005],
					[61.39386090019353, 4.0961943966627832],
					[61.393858099842255, 4.0963360877387327],
					[61.393844200837606, 4.0964666786461859],
					[61.393830402626733, 4.0965999640779769],
					[61.393791506442014, 4.0967499502763332],
					[61.393791505547611, 4.0967499504168075],
					[61.393791505614978, 4.096749952281991],
					[61.393791505614978, 4.096749952281991],
					[61.393755407742972, 4.0968999431491202],
					[61.393699812301598, 4.0970555336677643],
					[61.393644213308015, 4.0972110303773928],
					[61.393583113801711, 4.0973666268782551],
					[61.393583112974667, 4.0973666288838846],
					[61.393583112974667, 4.0973666288838846],
					[61.393524813180292, 4.0975193297419983],
					[61.393524813180292, 4.0975193297419983],
					[61.393524810631774, 4.0975193338937128],
					[61.393524810699112, 4.0975193357588848],
					[61.393466511080739, 4.0976888351709038],
					[61.393466511080739, 4.0976888351709038],
					[61.393466510253702, 4.0976888371765146],
					[61.393466510253702, 4.0976888371765146],
					[61.393410909849429, 4.0978582384662792],
					[61.39341090908971, 4.0978582423370504],
					[61.393410905781437, 4.0978582503594989],
					[61.393410905021724, 4.0978582542302675],
					[61.393366506265238, 4.0980471519073447],
					[61.393319206088449, 4.0982332501663468],
					[61.393319205328702, 4.0982332540371171],
					[61.393319203049543, 4.098233265649383],
					[61.393319202289796, 4.0982332695201364],
					[61.393288702383899, 4.0984332678115196],
					[61.393255403002769, 4.098633266266555],
					[61.393255402377676, 4.0986332738676055],
					[61.39325540092554, 4.0986332834742454],
					[61.393255400233144, 4.0986332892101505],
					[61.39324419982858, 4.0988388889019181],
					[61.39323040004772, 4.0990416852231171],
					[61.393230400182375, 4.09904168895343],
					[61.393230400384361, 4.0990416945488981],
					[61.393230399557304, 4.0990416965544778],
					[61.393227599895241, 4.0992332972141208],
					[61.393224799892678, 4.0994277864844619],
					[61.393208101029963, 4.0995777761071421],
					[61.39318870769074, 4.099730521882444],
					[61.393138740333235, 4.0997970805621984],
					[61.393088848408262, 4.0998636365603076],
					[61.393005703117623, 4.0998137283617053],
					[61.393005702223192, 4.0998137285020935],
					[61.393005700367041, 4.0998137269177199],
					[61.393005699405322, 4.0998137251929574],
					[61.392922338755206, 4.099766548320936],
					[61.392822365311055, 4.0996192881977231],
					[61.392822365243738, 4.0996192863325875],
					[61.392822364281976, 4.0996192846078294],
					[61.392822363387559, 4.0996192847482211],
					[61.392719567173643, 4.0994748901432123],
					[61.392622370870825, 4.0993137978473424],
					[61.392525171366891, 4.0991526976441923],
					[61.392525169376057, 4.0991526923296533],
					[61.392525163740267, 4.0991526857115863],
					[61.392525161816756, 4.0991526822621633],
					[61.392452961691433, 4.0990526828989706],
					[61.392380762391355, 4.0989526838532244],
					[61.392380747340056, 4.0989526655835373],
					[61.392380713929079, 4.098952637066211],
					[61.392380696396536, 4.0989526248130721],
					[61.392319495907358, 4.0989192247776289],
					[61.392258396115182, 4.0988859234749535],
					[61.392258368811362, 4.0988859146314844],
					[61.392258314405787, 4.0988859025398323],
					[61.392258284688083, 4.0988859015779902],
					[61.392183285416202, 4.0988915004311153],
					[61.392108285325243, 4.0988970018430191],
					[61.392108264686343, 4.0988970032072798],
					[61.392108224639628, 4.0988970151208557],
					[61.392108205164462, 4.0988970238050673],
					[61.39203050468101, 4.0989387235014387],
					[61.392030503853917, 4.0989387255069287],
					[61.392030502959507, 4.0989387256473346],
					[61.391949902863082, 4.0989831254553613],
					[61.391949886205822, 4.0989831374484229],
					[61.391949854814854, 4.0989831648839372],
					[61.391949840908183, 4.0989831783208821],
					[61.391880440660024, 4.0990748792348057],
					[61.391810941353427, 4.0991665783950779],
					[61.391810932745905, 4.0991665891244269],
					[61.39181092103204, 4.0991666134708966],
					[61.391810915107762, 4.0991666237790376],
					[61.39175541525578, 4.0993027234634356],
					[61.391755414428708, 4.0993027254688919],
					[61.391755412707177, 4.0993027276147531],
					[61.391755412774486, 4.0993027294798221],
					[61.391702613375756, 4.099441629013441],
					[61.391702610134679, 4.0994416389003483],
					[61.391702605306776, 4.0994416546632264],
					[61.391702602960166, 4.0994416644097402],
					[61.391674802628927, 4.0995943644386621],
					[61.391674802628927, 4.0995943644386621],
					[61.391647003410405, 4.0997499656047482],
					[61.391647001756198, 4.0997499696156368],
					[61.391647001131055, 4.0997499772162902],
					[61.391647001333006, 4.099749982811483],
					[61.391635900636892, 4.0998805828769811],
					[61.391635900704216, 4.0998805847420616],
					[61.391635899877095, 4.0998805867475037],
					[61.391635899877095, 4.0998805867475037],
					[61.39162760079121, 4.1000110827529808],
					[61.391610901341352, 4.1001582771779645],
					[61.391610901341352, 4.1001582771779645],
					[61.391610901408647, 4.1001582790430273],
					[61.391610900581576, 4.1001582810484543],
					[61.391597001860603, 4.1003055722020987],
					[61.39155810437115, 4.1005082627708296],
					[61.391519204841906, 4.1007110544021543],
					[61.391463707261821, 4.1009166487433069],
					[61.391408107832831, 4.1011221457575351],
					[61.391360907912414, 4.1012805422872685],
					[61.391313709436247, 4.1014360421450196],
					[61.391313707647399, 4.1014360424256768],
					[61.391313707647399, 4.1014360424256768],
					[61.391313707714708, 4.1014360442907236],
					[61.391272008772368, 4.1015777410216696],
					[61.391227609195518, 4.1017166387541417],
					[61.39122760926282, 4.1017166406191841],
					[61.39122760926282, 4.1017166406191841],
					[61.391180409154636, 4.101869340946438],
					[61.391133109866537, 4.1020221388743785],
					[61.391085910098724, 4.102163837811303],
					[61.391085910098724, 4.102163837811303],
					[61.391085909271602, 4.1021638398166616],
					[61.391085909338905, 4.1021638416816986],
					[61.391041508892684, 4.1023082407977469],
					[61.391041508959972, 4.1023082426627813],
					[61.391041508132822, 4.102308244668138],
					[61.391041508132822, 4.102308244668138],
					[61.391005407949166, 4.1024332456829127],
					[61.391005407949166, 4.1024332456829127],
					[61.391005407122016, 4.1024332476882437],
					[61.391005406294909, 4.1024332496935747],
					[61.390972005564343, 4.1025610492392266],
					[61.390972005698913, 4.1025610529693051],
					[61.39097200493903, 4.1025610568396669],
					[61.390972004111923, 4.1025610588449899],
					[61.390944203683055, 4.1026916577101851],
					[61.390916503918696, 4.1028221580129225],
					[61.39091650398597, 4.1028221598779515],
					[61.390916503226094, 4.1028221637482973],
					[61.390916503293383, 4.1028221656133255],
					[61.390891502539645, 4.1029610632139022],
					[61.390866504126606, 4.1030971618414398],
					[61.390838703999471, 4.1032277578029337],
					[61.390838704066759, 4.1032277596679751],
					[61.390810905670904, 4.1033610503144811],
					[61.390772008309156, 4.103491643688856],
					[61.390772008309156, 4.103491643688856],
					[61.39077200837643, 4.1034916455538761],
					[61.390772006654863, 4.1034916476994345],
					[61.390735909252918, 4.1036221411202938],
					[61.390688710844032, 4.1037582349569721],
					[61.390641510707177, 4.1038943342610752],
					[61.390641510707177, 4.1038943342610752],
					[61.390597011144017, 4.1040249343941904],
					[61.390597011211284, 4.1040249362592034],
					[61.390552611337448, 4.1041555353759005],
					[61.390552609615867, 4.1041555375214047],
					[61.390552608028806, 4.1041555433969483],
					[61.390552607268894, 4.1041555472672187],
					[61.390513706732612, 4.1042971466168803],
					[61.390513706799872, 4.1042971484818898],
					[61.390513706799872, 4.1042971484818898],
					[61.390513705972701, 4.1042971504871444],
					[61.390477606073254, 4.1044360503026525],
					[61.390477606073254, 4.1044360503026525],
					[61.390477605313357, 4.1044360541729024],
					[61.390477605313357, 4.1044360541729024],
					[61.390441504899364, 4.1045916555298634],
					[61.390441504899364, 4.1045916555298634],
					[61.390441504966617, 4.1045916573948693],
					[61.390441504072193, 4.1045916575351029],
					[61.390408103680684, 4.1047471577904995],
					[61.390408103747944, 4.1047471596555019],
					[61.390374804102571, 4.1049055583643872],
					[61.390374804169802, 4.1049055602293905],
					[61.390374804169802, 4.1049055602293905],
					[61.390374804237055, 4.1049055620943937],
					[61.390344203781318, 4.1050638617572606],
					[61.390310903927002, 4.1052249587005543],
					[61.390277604554591, 4.1053832550174283],
					[61.390241505737862, 4.1055277525809251],
					[61.390205408297732, 4.1056721426696674],
					[61.390166510955183, 4.1057832341610103],
					[61.390127612758782, 4.1058943292479118],
					[61.390083114363605, 4.1060055253472774],
					[61.39003871405292, 4.1061166250954475],
					[61.389988715398232, 4.1062416255849561],
					[61.389938714570732, 4.1063638239708471],
					[61.389938714637985, 4.1063638258358264],
					[61.389938713743561, 4.106363825976004],
					[61.389891513648116, 4.1064832256160049],
					[61.389891513715355, 4.1064832274809833],
					[61.389891512888127, 4.1064832294861402],
					[61.389891512888127, 4.1064832294861402],
					[61.38984701299659, 4.1066027307220301],
					[61.389847012102138, 4.106602730862206],
					[61.389847012169362, 4.1066027327271817],
					[61.389808113223168, 4.1067110292913931],
					[61.389766513791564, 4.1068166260468564],
					[61.389724815246694, 4.1069193253794118],
					[61.389683117298759, 4.1070221181078734],
					[61.389638721018862, 4.1071138103021934],
					[61.389591528037222, 4.1072054978441752],
					[61.389538734969918, 4.1072831880510474],
					[61.389538734075508, 4.1072831881911975],
					[61.389538732353877, 4.1072831903364584],
					[61.389538732421101, 4.1072831922014164],
					[61.389488741852986, 4.1073609756473681],
					[61.389422055602779, 4.1074304614007904],
					[61.389422054708369, 4.1074304615409352],
					[61.389422053881141, 4.1074304635460361],
					[61.389422053053906, 4.1074304655511398],
					[61.389358158117858, 4.1074998587248315],
					[61.389283165390218, 4.1075692518784646],
					[61.38920546724826, 4.1076387516179436],
					[61.389205464632177, 4.1076387539033048],
					[61.389205461121684, 4.1076387563288037],
					[61.389205459400053, 4.1076387584740228],
					[61.389138759810962, 4.1077054562247657],
					[61.389072061889919, 4.1077692558239427],
					[61.389072059273836, 4.1077692581092826],
					[61.389072052320053, 4.107769264825178],
					[61.389072048809574, 4.1077692672506512],
					[61.389013748632308, 4.1078359687781658],
					[61.389013747805123, 4.1078359707832233],
					[61.389013745189025, 4.1078359730685552],
					[61.38901374436179, 4.1078359750736206],
					[61.388958143613578, 4.1079054754594999],
					[61.388958141064734, 4.1079054796097578],
					[61.388958135005375, 4.1079054861854516],
					[61.388958131562077, 4.1079054904758436],
					[61.388897031944865, 4.1079998916851856],
					[61.388897031944865, 4.1079998916851856],
					[61.388897031050419, 4.1079998918253153],
					[61.388897031117629, 4.1079998936902387],
					[61.388838731268102, 4.1080915922246737],
					[61.388838728719257, 4.1080915963748845],
					[61.388838723688714, 4.1080916065402473],
					[61.388838721139827, 4.1080916106904795],
					[61.388772022455733, 4.1082249092418115],
					[61.388702623439258, 4.1083582082467256],
					[61.388702619995946, 4.1083582125370315],
					[61.388702615792617, 4.1083582206972782],
					[61.388702615032607, 4.1083582245672172],
					[61.388633115465701, 4.1085305234454674],
					[61.388560916171969, 4.1086999211481734],
					[61.388560915411936, 4.1086999250180885],
					[61.388560912035828, 4.1086999311732244],
					[61.388560911208579, 4.1086999331782534],
					[61.388499810770448, 4.1088749338847084],
					[61.388438711747661, 4.1090471336427745],
					[61.388438710093176, 4.1090471376527624],
					[61.388438706851424, 4.1090471475376518],
					[61.388438706091364, 4.1090471514075633],
					[61.388399806183216, 4.1092055511994605],
					[61.388360906102832, 4.1093638512033843],
					[61.388360903688266, 4.1093638590832384],
					[61.388360902436908, 4.1093638742825842],
					[61.388360900849612, 4.1093638801574572],
					[61.388344201177034, 4.1095360785944663],
					[61.388324801087755, 4.1097082777621141],
					[61.388324801222119, 4.109708281491919],
					[61.388324799836361, 4.1097082929614475],
					[61.388324799970718, 4.1097082966912355],
					[61.388321999541851, 4.1098805966640519],
					[61.388319199686208, 4.1100499970971764],
					[61.38831919982055, 4.1100500008269822],
					[61.3883191999549, 4.1100500045567712],
					[61.388319200089256, 4.1100500082865601],
					[61.388324799648124, 4.1101861062671272],
					[61.388327599814943, 4.1103222019120791],
					[61.388327599896385, 4.1104583009196354],
					[61.388327599572847, 4.1105943924739012],
					[61.388313700879365, 4.1107666839518204],
					[61.388299801022136, 4.1109388741616781],
					[61.388269203241386, 4.1111138660140156],
					[61.388269203308546, 4.1111138678789105],
					[61.388269202414136, 4.1111138680189416],
					[61.388241504402053, 4.111291659359444],
					[61.38820540580361, 4.1114360516741097],
					[61.388169207189357, 4.111577745580794],
					[61.388135910015357, 4.1116860397262087],
					[61.388099814120643, 4.111794323520189],
					[61.388052620272049, 4.111891612205036],
					[61.388052619444757, 4.1118916142099335],
					[61.388052618550297, 4.1118916143499495],
					[61.388008123939294, 4.1119860046872105],
					[61.387955431063617, 4.1120692929284548],
					[61.387955431130777, 4.1120692947933319],
					[61.387955430236318, 4.1120692949333417],
					[61.387955429341908, 4.1120692950733426],
					[61.38790262900266, 4.1121554954448616],
					[61.387902628175361, 4.1121554974497423],
					[61.387902627348083, 4.1121554994546194],
					[61.38785542864678, 4.1122359973652562],
					[61.387805429857622, 4.1123165957282231],
					[61.387805423932321, 4.1123166060325751],
					[61.387805413110421, 4.1123166302310237],
					[61.387805408146669, 4.1123166422602493],
					[61.387766509267536, 4.1124471416117059],
					[61.387724809260035, 4.1125777395895691],
					[61.38772480684527, 4.1125777474690226],
					[61.38772480380468, 4.1125777629479412],
					[61.387724802284403, 4.1125777706873832],
					[61.387697002352084, 4.1127693704816215],
					[61.387697002419245, 4.1127693723464835],
					[61.387669202252582, 4.1129638711326342],
					[61.38763870191751, 4.1131721709982747],
					[61.387638701984656, 4.1131721728631536],
					[61.387638701984656, 4.1131721728631536],
					[61.387638702051781, 4.1131721747280174],
					[61.387610903789586, 4.1133832581956069],
					[61.387563708118229, 4.1135443430442127],
					[61.387563708185354, 4.1135443449090721],
					[61.387563707358026, 4.113544346913895],
					[61.387563707425173, 4.1135443487787526],
					[61.38751921338887, 4.1137082266263469],
					[61.387463723186343, 4.1138138073971513],
					[61.387463722291912, 4.1138138075371051],
					[61.387463722359023, 4.1138138094019592],
					[61.387410922929831, 4.113916605816522],
					[61.387360924319189, 4.1140082047412578],
					[61.387360924319189, 4.1140082047412578],
					[61.3873609243863, 4.1140082066061092],
					[61.387360922597445, 4.1140082068860009],
					[61.387313723058952, 4.1140971058890319],
					[61.387313718095058, 4.1140971179177903],
					[61.387313709889014, 4.1140971398305686],
					[61.387313705819558, 4.1140971517193821],
					[61.387283105574291, 4.1142193512776064],
					[61.387283105574291, 4.1142193512776064],
					[61.387283104746977, 4.1142193532823903],
					[61.387283104814102, 4.1142193551472337],
					[61.387255405274061, 4.1143388558395664],
					[61.387255402993439, 4.1143388674484527],
					[61.387255401249803, 4.1143388939760914],
					[61.387255399930716, 4.1143389073098886],
					[61.387260900265943, 4.114497207456715],
					[61.387260900265943, 4.114497207456715],
					[61.387260900333061, 4.1144972093215584],
					[61.387269199657105, 4.1146556098475111],
					[61.387269201915771, 4.1146556226215827],
					[61.387269206366, 4.1146556463048833],
					[61.387269208557598, 4.1146556572141044],
					[61.387324809377276, 4.1148389572184589],
					[61.387380409061933, 4.1150222580218436],
					[61.387380412080759, 4.1150222669263528],
					[61.3873804180514, 4.1150222828705436],
					[61.387380421964693, 4.1150222916351487],
					[61.387477621982782, 4.1152111911199736],
					[61.387574821749837, 4.1154000917780102],
					[61.387574823605803, 4.1154000933630588],
					[61.387574825595991, 4.1154000986778545],
					[61.387574828413527, 4.1154001019878566],
					[61.387672027772759, 4.1155640014132793],
					[61.387672027839841, 4.1155640032781493],
					[61.387672028734293, 4.1155640031382443],
					[61.387772028888953, 4.1157279043108401],
					[61.387772030812037, 4.1157279077608093],
					[61.387772033629538, 4.1157279110708673],
					[61.387772036447046, 4.1157279143809387],
					[61.387847036340595, 4.1158362134659923],
					[61.387922036152766, 4.1159445130665455],
					[61.38792204096044, 4.11594452169152],
					[61.387922053124932, 4.1159445347919963],
					[61.387922058759933, 4.1159445414121949],
					[61.387997059367549, 4.1160195401148076],
					[61.388069257215115, 4.1160918399884086],
					[61.3881442559072, 4.1161695383698396],
					[61.388144256868735, 4.1161695400948499],
					[61.388144257830263, 4.1161695418198621],
					[61.388144258724701, 4.1161695416799704],
					[61.38822203785471, 4.1162473213035948],
					[61.388274823939078, 4.116344495316115],
					[61.388274824833481, 4.116344495176234],
					[61.388274825862119, 4.1163444987661819],
					[61.388274825862119, 4.1163444987661819],
					[61.388330410024459, 4.1164417699901099],
					[61.388355402718304, 4.1165750362814508],
					[61.388355403679846, 4.1165750380064834],
					[61.388355403746935, 4.1165750398713996],
					[61.388355403814032, 4.1165750417363283],
					[61.388383100759071, 4.1167056235794997],
					[61.388388699950163, 4.1168778052623356],
					[61.388394200309534, 4.1170527967998858],
					[61.388383099658142, 4.1172471897742815],
					[61.388383099725239, 4.1172471916391968],
					[61.388383099725239, 4.1172471916391968],
					[61.388374799813356, 4.1174443872277608],
					[61.388358101352871, 4.1176332824815569],
					[61.388358101352871, 4.1176332824815569],
					[61.388358101419946, 4.117633284346474],
					[61.388344201130479, 4.1178221844773253],
					[61.388327601244129, 4.1180082826474163],
					[61.388327601244129, 4.1180082826474163],
					[61.388327601311197, 4.1180082845123325],
					[61.388327601311197, 4.1180082845123325],
					[61.388313700544678, 4.1181943843291897],
					[61.38831369971733, 4.1181943863339354],
					[61.388313699784412, 4.1181943881988508],
					[61.388313699851459, 4.1181943900637688],
					[61.388302600060136, 4.1184138897159146],
					[61.388291499936081, 4.1186332898283275],
					[61.388291500003142, 4.1186332916932438],
					[61.388291500204318, 4.1186332972880111],
					[61.388291500271372, 4.1186332991529264],
					[61.388291500241849, 4.1188500000118431],
					[61.388291499932798, 4.1190639009015859],
					[61.388291500066906, 4.1190639046314228],
					[61.388291501296642, 4.1190639138162153],
					[61.388291500603337, 4.1190639195507979],
					[61.388308101443634, 4.1192389178431128],
					[61.388322000960621, 4.1194139165375274],
					[61.388322001161761, 4.1194139221322903],
					[61.388322003419987, 4.1194139349071719],
					[61.388322003621141, 4.1194139405019321],
					[61.388355403908022, 4.1195722404638735],
					[61.388385904090121, 4.1197278376809985],
					[61.388385905252754, 4.1197278450009085],
					[61.388385909299842, 4.1197278574962608],
					[61.388385911356913, 4.1197278646763813],
					[61.388433110580863, 4.1198667643466464],
					[61.388480411275587, 4.1200056635638411],
					[61.388480417178563, 4.1200056776446363],
					[61.388480430773463, 4.120005705526637],
					[61.388480439292749, 4.1200057173231359],
					[61.388541538614284, 4.1200890187425028],
					[61.388541538614284, 4.1200890187425028],
					[61.388605438719367, 4.1201751197612566],
					[61.38860547302049, 4.120175148158431],
					[61.388605548175001, 4.1201751870500756],
					[61.388605590884303, 4.1201751991299318],
					[61.388666690876086, 4.1201780004499122],
					[61.388666692664977, 4.1201780001703536],
					[61.388666698031621, 4.1201779993316885],
					[61.388666699887551, 4.1201780009170736],
					[61.388730599926355, 4.1201779997544437],
					[61.388730653391882, 4.1201779857728678],
					[61.388730743083322, 4.120177928621592],
					[61.388730780270713, 4.1201778871770625],
					[61.388777980293142, 4.1200806873212281],
					[61.388825180267283, 4.1199833877699206],
					[61.38882518440429, 4.1199833777462205],
					[61.388825191783845, 4.119983357838608],
					[61.388825194959409, 4.1199833460897342],
					[61.388861295242606, 4.1198306458223177],
					[61.38889739371983, 4.1196778498156146],
					[61.388936292723677, 4.1195306508867517],
					[61.388936293618137, 4.1195306507469516],
					[61.388936294445514, 4.1195306487422005],
					[61.388972390936978, 4.1193833604640124],
					[61.389027985868623, 4.1192417730456938],
					[61.389027986763089, 4.1192417729058848],
					[61.389083479329663, 4.1190972921410181],
					[61.389164067316962, 4.1189751106136354],
					[61.389244543381722, 4.1188529459289995],
					[61.389333405965736, 4.1187973707759884],
					[61.389333405898668, 4.1187973689109931],
					[61.389333406793092, 4.1187973687711743],
					[61.389425072986832, 4.1187390913199975],
					[61.389511127634272, 4.1187251980340971],
					[61.389600025178446, 4.1187140973183958],
					[61.389600027928815, 4.1187140987639346],
					[61.389600035084371, 4.1187140976453245],
					[61.389600037700625, 4.1187140953608399],
					[61.389672234013787, 4.1187001965393666],
					[61.389744430267434, 4.1186890977610151],
					[61.389744448983677, 4.118689092959622],
					[61.38974448538756, 4.1186890797667006],
					[61.389744503075235, 4.1186890713751287],
					[61.389800098332444, 4.1186557737460543],
					[61.389858392857832, 4.1186251767134001],
					[61.389858412200248, 4.1186251643120837],
					[61.389858445384291, 4.1186251366183946],
					[61.389858460947785, 4.1186251191813641],
					[61.389914060576132, 4.1185501188785452],
					[61.389969561234793, 4.1184751187678303],
					[61.389969566400254, 4.1184751123337762],
					[61.389969574875245, 4.1184750978802818],
					[61.389969579079207, 4.1184750897210458],
					[61.390025177548452, 4.1183639892875004],
					[61.390083478096905, 4.1182500915899354],
					[61.390083479751667, 4.1182500875802068],
					[61.390083483061154, 4.1182500795607373],
					[61.390083484715895, 4.1182500755510114],
					[61.390141884558943, 4.1181083761412074],
					[61.390141885386335, 4.1181083741363347],
					[61.390141886213684, 4.1181083721314673],
					[61.390141887108143, 4.1181083719916183],
					[61.3901973861459, 4.1179639747151926],
					[61.390269585062278, 4.117786175252161],
					[61.390341883690439, 4.1176083780963113],
					[61.390427982887722, 4.1174139804339411],
					[61.390514080989881, 4.1172194843000458],
					[61.390605779741186, 4.1170306868336795],
					[61.390697377592808, 4.1168417914630622],
					[61.390789075775224, 4.1166694947964988],
					[61.390880772934572, 4.1165001006092981],
					[61.390969568992631, 4.1163584073953476],
					[61.391061259681393, 4.1162168213734649],
					[61.391152950830893, 4.1161112305171237],
					[61.391241830711628, 4.1160084551356375],
					[61.3913334040001, 4.1159529709404188],
					[61.391333404894525, 4.1159529708005067],
					[61.391333405788984, 4.1159529706605902],
					[61.391422248102039, 4.1158974069193963],
					[61.391505580252044, 4.1159057981158016],
					[61.391588801803053, 4.1159140903601052],
					[61.391652648361344, 4.1159862282063413],
					[61.391713721047232, 4.116061195400829],
					[61.391755408794332, 4.1161944601291758],
					[61.391755408794332, 4.1161944601291758],
					[61.391797004539981, 4.1163250462018768],
					[61.39182480192153, 4.116491730177362],
					[61.391849801146286, 4.1166611234550752],
					[61.391863700622515, 4.1168361167850902],
					[61.391877599740852, 4.1170111065506809],
					[61.391874800117897, 4.1171749971989238],
					[61.391871999563747, 4.1173360913927182],
					[61.391863699817776, 4.1174749866466733],
					[61.391852600900968, 4.1176110840962279],
					[61.391852600900968, 4.1176110840962279],
					[61.391852600968036, 4.1176110859613555],
					[61.391841500827873, 4.1177638858611036],
					[61.391841499933413, 4.1177638860009722],
					[61.391841500000481, 4.1177638878660989],
					[61.391841500067571, 4.117763889731223],
					[61.391833099957537, 4.1179166893204044],
					[61.391833100091667, 4.117916693050673],
					[61.391833100427021, 4.1179167023763013],
					[61.39183309973378, 4.1179167081115589],
					[61.391841499608262, 4.1181250079489677],
					[61.39184149967533, 4.1181250098141113],
					[61.39184149967533, 4.1181250098141113],
					[61.391841499742412, 4.1181250116792372],
					[61.39185260039666, 4.1183333113296259],
					[61.391852600664919, 4.1183333187901541],
					[61.391852602990348, 4.1183333334315098],
					[61.39185260415303, 4.1183333407521756],
					[61.391899804179495, 4.1185639394446163],
					[61.391899804246556, 4.1185639413097483],
					[61.391899804246556, 4.1185639413097483],
					[61.391899805141016, 4.1185639411699002],
					[61.391949804897408, 4.1187944422921685],
					[61.391949805993029, 4.1187944477477521],
					[61.391949808117225, 4.1187944567937675],
					[61.391949810107285, 4.1187944621095003],
					[61.392013709794561, 4.1189917624342884],
					[61.392077610054741, 4.1191889621562101],
					[61.392077611083266, 4.1191889657466909],
					[61.392077613073305, 4.1191889710624716],
					[61.392077614101886, 4.1191889746529453],
					[61.392133113741899, 4.1193278732504934],
					[61.392185912973332, 4.1194667705555634],
					[61.392185916886355, 4.1194667793220576],
					[61.392185924712408, 4.1194667968550487],
					[61.392185928558405, 4.1194668037563726],
					[61.392247029258371, 4.1195668048078771],
					[61.392308128927013, 4.1196668045230105],
					[61.392308143014162, 4.1196668210774199],
					[61.39230817297728, 4.1196668539066135],
					[61.392308191402535, 4.1196668660316185],
					[61.392388790293019, 4.1197195660343446],
					[61.392469290215871, 4.1197723661715981],
					[61.39246932315335, 4.1197723816552516],
					[61.392469390280802, 4.1197723974215634],
					[61.392469425365263, 4.1197723975644136],
					[61.392558323884863, 4.119761298244228],
					[61.392650024282268, 4.1197501997823416],
					[61.39265003763186, 4.11975019581996],
					[61.39265006611997, 4.1197501876155611],
					[61.392650080364035, 4.1197501835133616],
					[61.392738979719191, 4.1197112827575202],
					[61.392827866739118, 4.1196723893221456],
					[61.392919450788206, 4.1196473948650185],
					[61.393008347835341, 4.1196251948969014],
					[61.393008348729765, 4.1196251947570861],
					[61.393008349624239, 4.119625194617262],
					[61.393094450219465, 4.1196029946195001],
					[61.393180649734205, 4.1195807940299245],
					[61.393180666594503, 4.1195807876428781],
					[61.393180700248095, 4.1195807730035892],
					[61.393180715185444, 4.1195807631657813],
					[61.393255714641079, 4.119527964963229],
					[61.393330714900372, 4.1194751644947951],
					[61.393330729703578, 4.1194751509264318],
					[61.393330758281458, 4.119475120199076],
					[61.393330770334323, 4.1194751051849661],
					[61.393394570150996, 4.1193723064959542],
					[61.393458469704626, 4.1192695067851259],
					[61.393458472186772, 4.1192695007699536],
					[61.393458477285236, 4.1192694924700364],
					[61.393458479834415, 4.1192694883200938],
					[61.393530779792314, 4.1191222877830826],
					[61.393530779792314, 4.1191222877830826],
					[61.393530780619678, 4.119122285778019],
					[61.393530780619678, 4.119122285778019],
					[61.393600181462048, 4.1189750857458804],
					[61.393683480651461, 4.1187972853948605],
					[61.393683482373284, 4.1187972832499282],
					[61.393764080371007, 4.1186222883188313],
					[61.393858477025304, 4.1184417922800627],
					[61.393858477025304, 4.1184417922800627],
					[61.393858477919736, 4.1184417921402057],
					[61.39395016913361, 4.1182640083013213],
					[61.394064058316765, 4.1181168213944677],
					[61.394177942252391, 4.1179695422843103],
					[61.394291820252398, 4.1178834598025462],
					[61.394402900683033, 4.1178001738927721],
					[61.394486180097651, 4.1177640835511538],
					[61.394486180097651, 4.1177640835511538],
					[61.394486180992111, 4.117764083411271],
					[61.394486181886521, 4.1177640832713926],
					[61.394566782284841, 4.1177279821462163],
					[61.394566791989547, 4.1177279768769317],
					[61.394566811398938, 4.1177279663383457],
					[61.394566820142117, 4.1177279593436555],
					[61.394622315676429, 4.1176862627564681],
					[61.394680711006437, 4.1176473651190788],
					[61.394680729387261, 4.1176473509896931],
					[61.394680761475378, 4.1176473178344626],
					[61.39468077511566, 4.1176472969433142],
					[61.394733475024474, 4.1175528979801372],
					[61.394786274706036, 4.117458398671241],
					[61.394786278842844, 4.1174583886452636],
					[61.394786287183521, 4.1174583704586309],
					[61.394786291320315, 4.1174583604326562],
					[61.394827991162835, 4.117327861442007],
					[61.394869591011947, 4.1171972614264112],
					[61.394869591772199, 4.1171972575558877],
					[61.394869594120109, 4.117197247809667],
					[61.394869594880362, 4.1171972439391427],
					[61.394894594696396, 4.1170861466477646],
					[61.394922392869667, 4.116972253569573],
					[61.394952990767308, 4.116875059699403],
					[61.394952990700197, 4.1168750578340969],
					[61.394952992421999, 4.1168750556889693],
					[61.394952993249362, 4.1168750536837315],
					[61.394980786304345, 4.1167750775983727],
					[61.395027974313997, 4.1166917990607006],
					[61.395027975141325, 4.1166917970554584],
					[61.395027976796015, 4.1166917930450051],
					[61.395027977690475, 4.1166917929050868],
					[61.395072349713757, 4.1166057469719854],
					[61.395138993887969, 4.1165695761680681],
					[61.395202839789746, 4.1165363058993156],
					[61.39528608015155, 4.116544599277689],
					[61.395369373799412, 4.1165529987798646],
					[61.395458269282678, 4.1165668979643009],
					[61.395547169303953, 4.1165807983819356],
					[61.395547180104273, 4.116580798568231],
					[61.395547201772118, 4.1165808008061493],
					[61.395547213466905, 4.1165808008525193],
					[61.39563610918281, 4.1165752007040002],
					[61.395727805804583, 4.1165724000709298],
					[61.395727809382358, 4.1165723995112362],
					[61.395727814748987, 4.1165723986717033],
					[61.39572781928829, 4.1165723998374437],
					[61.395816718632133, 4.1165640996812289],
					[61.39581672042101, 4.1165640994013808],
					[61.395816723104325, 4.1165640989816126],
					[61.395816724893201, 4.1165640987017671],
					[61.395905593831507, 4.1165530025788701],
					[61.395997164148369, 4.1165695974281427],
					[61.395997165937267, 4.1165695971482918],
					[61.395997167726165, 4.1165695968684419],
					[61.395997168687671, 4.1165695985939088],
					[61.396086054194406, 4.1165834958307439],
					[61.396174937625034, 4.1166112899751583],
					[61.39626383466026, 4.1166418884609621],
					[61.396263848345207, 4.1166418938236706],
					[61.396263872763441, 4.1166418975072769],
					[61.396263886314195, 4.1166418991392169],
					[61.396344381753003, 4.1166473995790573],
					[61.396422178681689, 4.1166557983672023],
					[61.396422205649039, 4.1166557979002736],
					[61.396422260209775, 4.1166557893649127],
					[61.396422285947232, 4.1166557797109293],
					[61.396486185686257, 4.116625180365773],
					[61.396550086311102, 4.1165945807497648],
					[61.39655009863197, 4.1165945731945861],
					[61.396550123206588, 4.1165945562187893],
					[61.39655013463301, 4.1165945488035387],
					[61.396614032899329, 4.1165362497958267],
					[61.396677930238468, 4.1164807514190187],
					[61.396750128849952, 4.116419553199326],
					[61.396750129677329, 4.1164195511939754],
					[61.396750132360623, 4.1164195507741805],
					[61.396750132360623, 4.1164195507741805],
					[61.396822317818838, 4.1163557617982685],
					[61.396897302158642, 4.1163112712142977],
					[61.396897303053038, 4.116311271074367],
					[61.396897303947512, 4.1163112709344247],
					[61.396897304841936, 4.1163112707944896],
					[61.396969488228628, 4.1162668805394205],
					[61.397044469607565, 4.1162390876495945],
					[61.39704447139642, 4.1162390873697179],
					[61.39704447401266, 4.1162390850844668],
					[61.397044475801529, 4.1162390848045867],
					[61.397119468732264, 4.1162084873149345],
					[61.397194463379449, 4.1161834890857643],
					[61.397194464273909, 4.1161834889458202],
					[61.397194466062764, 4.1161834886659374],
					[61.397194466957224, 4.1161834885259942],
					[61.397272253677926, 4.1161557928066488],
					[61.397352841225384, 4.116139096393689],
					[61.397433302116752, 4.1161224046936651],
					[61.39752216316802, 4.1161390963667692],
					[61.39752216406248, 4.1161390962268216],
					[61.397613824304059, 4.1161557889934084],
					[61.397705488955388, 4.1162168666579788],
					[61.397705489849855, 4.1162168665180321],
					[61.397705490811376, 4.1162168682435798],
					[61.397799872207969, 4.1162779564195171],
					[61.397888755425747, 4.1163695382075893],
					[61.397974847892229, 4.1164612307017574],
					[61.398049839718205, 4.11655841988678],
					[61.398122031318046, 4.1166584076189139],
					[61.398183124094075, 4.1167694952802494],
					[61.398241519390112, 4.1168778855676802],
					[61.398294215263867, 4.1170028756617914],
					[61.39834421273256, 4.1171306708183897],
					[61.398394210726003, 4.117272266230092],
					[61.398394211687538, 4.1172722679556877],
					[61.398394211754606, 4.1172722698212043],
					[61.398394212649045, 4.1172722696812887],
					[61.398447013382132, 4.1174139692725094],
					[61.398447012554783, 4.1174139712779398],
					[61.398447015439352, 4.1174139764547704],
					[61.398447016400887, 4.1174139781803731],
					[61.39850541526846, 4.1175528758869344],
					[61.398560914019527, 4.1176944733219569],
					[61.398560918894169, 4.1176944838155984],
					[61.398560928576465, 4.1176945029373471],
					[61.39856093524002, 4.1176945131511715],
					[61.398627634149271, 4.117791810954623],
					[61.398691532994732, 4.1178890105815915],
					[61.398691550794005, 4.1178890303099003],
					[61.398691594375464, 4.1178890666418635],
					[61.398691618301662, 4.1178890816597926],
					[61.398777717411797, 4.1179279822281867],
					[61.398861014568503, 4.1179668803874527],
					[61.398861040082338, 4.1179668895290646],
					[61.398861090774695, 4.1179668984845579],
					[61.398861116847606, 4.1179668981585298],
					[61.398958317443601, 4.1179584992351108],
					[61.398958319232491, 4.1179584989553089],
					[61.398958321021361, 4.1179584986755051],
					[61.39895832281023, 4.117958498395704],
					[61.399055623300924, 4.1179473991522242],
					[61.399055640228177, 4.117947394628529],
					[61.399055674977177, 4.1179473854412265],
					[61.399055691770336, 4.1179473771863915],
					[61.39914179193412, 4.1179029775198259],
					[61.39914179193412, 4.1179029775198259],
					[61.399141793723004, 4.1179029772400177],
					[61.399141793655915, 4.1179029753744585],
					[61.399225093803878, 4.1178584768674078],
					[61.399225102614146, 4.117858471737212],
					[61.399225120167564, 4.1178584596112486],
					[61.399225128083366, 4.1178584546209471],
					[61.399308427852688, 4.11778905489665],
					[61.399308427785606, 4.117789053031081],
					[61.399308428680051, 4.1177890528911707],
					[61.399394529419084, 4.1177168526720909],
					[61.399394530313543, 4.1177168525321761],
					[61.399394532035352, 4.1177168503867785],
					[61.399394533757132, 4.1177168482413844],
					[61.399477934345178, 4.1176418487580317],
					[61.399477934345178, 4.1176418487580317],
					[61.399477936066965, 4.1176418466126252],
					[61.399477936961389, 4.117641846472714],
					[61.399561236813689, 4.1175640463324417],
					[61.399561239429921, 4.1175640440471071],
					[61.399561243700894, 4.1175640377507667],
					[61.399561247211565, 4.1175640353255005],
					[61.399627946352226, 4.1174918371248017],
					[61.399697343817856, 4.1174195373047207],
					[61.399697349050321, 4.117419532733992],
					[61.399697359448204, 4.1174195217269531],
					[61.399697363719106, 4.1174195154305746],
					[61.399755761830981, 4.1173362179454118],
					[61.399816859176426, 4.117255721003434],
					[61.399816865169122, 4.1172557125615388],
					[61.399816875365715, 4.1172556959576037],
					[61.399816880464002, 4.1172556876556294],
					[61.399861279244362, 4.1171639890408116],
					[61.399908478046648, 4.1170722911081512],
					[61.3999084839052, 4.1170722789349767],
					[61.399908492737687, 4.1170722494116054],
					[61.399908496740238, 4.1170722356526985],
					[61.399927995649563, 4.1169667385322786],
					[61.399950195591956, 4.1168611413729002],
					[61.399950196978295, 4.1168611298993651],
					[61.399950199750933, 4.1168611069522854],
					[61.399950200242799, 4.1168610956186953],
					[61.39994739984472, 4.1167166964791306],
					[61.399944600071741, 4.1165749950916846],
					[61.399944599937548, 4.1165749913604586],
					[61.399944598640531, 4.1165749803067575],
					[61.399944598439241, 4.116574974709942],
					[61.399922398366925, 4.1163971751730593],
					[61.399922398299829, 4.1163971733074565],
					[61.399922398299829, 4.1163971733074565],
					[61.399922398232739, 4.1163971714418341],
					[61.399897397911495, 4.1162193731668753],
					[61.399872398317157, 4.1160305735697671],
					[61.399847399849016, 4.1158416877686266],
					[61.399847400008596, 4.1156555990872974],
					[61.39984740022981, 4.115466718728177],
					[61.399880796585052, 4.1152806357898761],
					[61.399914095364352, 4.1150944476240774],
					[61.399969591020351, 4.1149111579870219],
					[61.399969591914775, 4.1149111578470281],
					[61.400025191371959, 4.114725059620798],
					[61.400086290021839, 4.114538962925125],
					[61.400147389278423, 4.1143556645454327],
					[61.400214087594208, 4.1141694683365024],
					[61.400280784983146, 4.1139861775674937],
					[61.400369580388301, 4.1138000868189248],
					[61.400458474576432, 4.1136167974699109],
					[61.400566869150673, 4.1134445062437015],
					[61.400566869150673, 4.1134445062437015],
					[61.400675165442955, 4.1132723135933897],
					[61.400775160588793, 4.1131362185486458],
					[61.400875159993191, 4.1130001200580466],
					[61.400955758841185, 4.1128945201268099],
					[61.40095576063004, 4.1128945198466962],
					[61.401033461257803, 4.1127890185921236],
					[61.401033462085117, 4.1127890165863983],
					[61.401033464634111, 4.1127890124348765],
					[61.401033466355827, 4.112789010289096],
					[61.401105765598707, 4.1126807107810297],
					[61.40110576738757, 4.1126807105009018],
					[61.401105767320409, 4.1126807086352386],
					[61.401105768214869, 4.1126807084951702],
					[61.401177968379486, 4.112569508389166],
					[61.401177970034034, 4.1125695043776789],
					[61.401177975132057, 4.112569496074574],
					[61.401177976853752, 4.1125694939287714],
					[61.401255775644486, 4.1124222942151958],
					[61.40133627459717, 4.1122750967625423],
					[61.401336276318879, 4.1122750946166926],
					[61.401336277973428, 4.112275090605185],
					[61.40133627880072, 4.1122750885994286],
					[61.4014195790378, 4.112105689567672],
					[61.401505777344106, 4.1119389933503223],
					[61.40158347577885, 4.1117944947332692],
					[61.40158347577885, 4.1117944947332692],
					[61.401583476673288, 4.1117944945931759],
					[61.401583476606142, 4.1117944927274674],
					[61.40166127312763, 4.1116473006387881],
					[61.401725169137521, 4.1115473088207395],
					[61.401725169070374, 4.1115473069550408],
					[61.40172516996477, 4.111547306814944],
					[61.401789064003722, 4.1114445136774656],
					[61.401847357661005, 4.1113695231303797],
					[61.401847359382678, 4.1113695209844652],
					[61.401847361104373, 4.1113695188385471],
					[61.401847362826075, 4.1113695166926307],
					[61.401902956186539, 4.1112918267959628],
					[61.401961248214675, 4.1112279357366335],
					[61.401961248147501, 4.1112279338709232],
					[61.401961249869203, 4.1112279317249891],
					[61.401961250763634, 4.1112279315848754],
					[61.402019545332287, 4.1111612386548062],
					[61.402080738175457, 4.1111029454330259],
					[61.40214183112213, 4.1110445512961231],
					[61.402205721711645, 4.1109945595431796],
					[61.402272302837062, 4.1109445738646748],
					[61.402338982260261, 4.1109140819060617],
					[61.402408341582976, 4.1108835001919539],
					[61.402472199834229, 4.1108835002247961],
					[61.402536040956576, 4.1108834991824859],
					[61.402605492167979, 4.110927968804388],
					[61.40267486467409, 4.110972351502876],
					[61.402755443842103, 4.111072325488955],
					[61.402755444803724, 4.1110723272146066],
					[61.402755446659761, 4.1110723288001241],
					[61.402755447621345, 4.1110723305257633],
					[61.402838739998039, 4.1111695195988718],
					[61.402933132869123, 4.1113140090654126],
					[61.402933132936298, 4.1113140109311983],
					[61.40302763299055, 4.1114584094003002],
					[61.403027633952121, 4.1114584111259669],
					[61.403027636836946, 4.1114584163029724],
					[61.403027637798516, 4.1114584180286391],
					[61.403124837727084, 4.1115918176905408],
					[61.403124838621501, 4.1115918175504351],
					[61.403124839583114, 4.1115918192761054],
					[61.403124839583114, 4.1115918192761054],
					[61.403224840381945, 4.111725120622884],
					[61.403224852546849, 4.1117251337278446],
					[61.403224881348848, 4.1117251592372543],
					[61.403224896129913, 4.1117251700561104],
					[61.403316595895802, 4.1117807701736595],
					[61.403408195840527, 4.1118362717833401],
					[61.403408249417936, 4.111836285906918],
					[61.403408352814843, 4.1118362847215559],
					[61.403408405384809, 4.1118362708581229],
					[61.403489004566296, 4.1117862694588316],
					[61.403489006355166, 4.1117862691786238],
					[61.403489007249568, 4.1117862690385234],
					[61.403489008144028, 4.111786268898415],
					[61.403566808184543, 4.1117362685085546],
					[61.403566828219176, 4.1117362503596144],
					[61.403566862854625, 4.1117362130365853],
					[61.403566878282732, 4.1117361918565658],
					[61.403625178268989, 4.11162229063717],
					[61.403625179163384, 4.1116222904970652],
					[61.403625179923509, 4.1116222866253134],
					[61.403625180817912, 4.1116222864852068],
					[61.403680781349024, 4.1115056854680638],
					[61.403680783830822, 4.1115056794502625],
					[61.403680788660061, 4.1115056636830554],
					[61.403680792036312, 4.1115056575251376],
					[61.403716891791703, 4.1113833578360195],
					[61.403752992310707, 4.1112611571761795],
					[61.403752993003607, 4.1112611514385913],
					[61.403752996178298, 4.1112611396831866],
					[61.403752996871226, 4.1112611339456109],
					[61.403777997116343, 4.1111111326902519],
					[61.403802997308951, 4.110963932569498],
					[61.403802998136214, 4.110963930563547],
					[61.403802997934712, 4.1109639249660681],
					[61.403802998761947, 4.1109639229601225],
					[61.403825199363418, 4.1107694231454905],
					[61.403847398828432, 4.1105778221617086],
					[61.403847398761272, 4.1105778202958687],
					[61.403847399454172, 4.110577814558261],
					[61.403847400214211, 4.1105778106864816],
					[61.403858499825844, 4.1103833102736171],
					[61.403869600234039, 4.1101917122834699],
					[61.403869600166828, 4.1101917104176504],
					[61.40386960009964, 4.11019170855183],
					[61.403869600032472, 4.110191706686007],
					[61.403875200340302, 4.1100167073699394],
					[61.403883499921832, 4.1098417127829547],
					[61.403894599680875, 4.1096806146065283],
					[61.403905799324427, 4.1095167255108898],
					[61.403930796644474, 4.1093778341489129],
					[61.403930796644474, 4.1093778341489129],
					[61.403930796577278, 4.1093778322830898],
					[61.403930796577278, 4.1093778322830898],
					[61.403952994905111, 4.109238949669483],
					[61.403991887636977, 4.1091306675450481],
					[61.403991889358636, 4.1091306653988546],
					[61.403991890185871, 4.1091306633928451],
					[61.403991890118675, 4.1091306615270193],
					[61.404027984399335, 4.1090194796797865],
					[61.404077976215405, 4.1089278964161453],
					[61.404127972051228, 4.1088362015123634],
					[61.404191868091679, 4.1087362086875965],
					[61.404258465899638, 4.108636210376992],
					[61.404258467621297, 4.1086362082307541],
					[61.404258470997377, 4.1086362020724208],
					[61.404258472786225, 4.1086362017920228],
					[61.4043362720681, 4.1085029014509793],
					[61.404414072291253, 4.1083723022720013],
					[61.404414073945674, 4.1083722982598898],
					[61.404414077321732, 4.1083722921014996],
					[61.404414078976167, 4.1083722880893871],
					[61.404489078220216, 4.1082222900989649],
					[61.404566876649071, 4.1080750930781536],
					[61.404566876581875, 4.1080750912122745],
					[61.404566878303491, 4.108075089065987],
					[61.404566879197944, 4.1080750889257684],
					[61.404630778667212, 4.1079472898517144],
					[61.404694574102152, 4.1078222986358881],
					[61.404750168794656, 4.1077334074989329],
					[61.404805754174994, 4.1076473302361443],
					[61.40486683388341, 4.107591847466133],
					[61.404866834777849, 4.1075918473258994],
					[61.404866837393904, 4.1075918450393267],
					[61.404866838288314, 4.1075918448990985],
					[61.404927898793751, 4.1075334827332428],
					[61.405008346641061, 4.1075140937559027],
					[61.405008349324355, 4.1075140933351939],
					[61.405008352902044, 4.1075140927742559],
					[61.405008354690892, 4.1075140924937852],
					[61.40508610944508, 4.1074919057011181],
					[61.405172162045417, 4.1075084965344066],
					[61.405172163834266, 4.107508496253935],
					[61.405172166584784, 4.107508497699115],
					[61.405172168373618, 4.1075084974186442],
					[61.405258230809658, 4.1075223903459825],
					[61.405338796812693, 4.1075695719624488],
					[61.405419277680934, 4.1076195593160598],
					[61.40550266238575, 4.1076973454074874],
					[61.405583152655375, 4.1077751353489536],
					[61.405666545713437, 4.1078751288761861],
					[61.405666546607819, 4.1078751287359632],
					[61.405666547569481, 4.107875130461653],
					[61.405749841388023, 4.107972321607785],
					[61.405835934625259, 4.1080973134289804],
					[61.405835935586879, 4.1080973151546916],
					[61.405835938337383, 4.1080973165999533],
					[61.405835938404607, 4.1080973184658856],
					[61.405924837071467, 4.1082195161619115],
					[61.406019237349533, 4.1083529159727039],
					[61.406019237416757, 4.1083529178386486],
					[61.406019239272815, 4.1083529194241599],
					[61.406019240167211, 4.1083529192839467],
					[61.406116540131919, 4.1084834202418268],
					[61.406116550441077, 4.1084834317611483],
					[61.406116572848276, 4.108483454519356],
					[61.406116585773503, 4.1084834637521004],
					[61.406208185637972, 4.1085473633824785],
					[61.406208186599585, 4.1085473651082278],
					[61.406208188455636, 4.1085473666937604],
					[61.406302688189356, 4.1086112662101266],
					[61.406302733717212, 4.108611281591136],
					[61.406302829467357, 4.1086112928522533],
					[61.406302876804737, 4.108611283555093],
					[61.406388975202084, 4.1085751859837361],
					[61.406475071831913, 4.1085418863566723],
					[61.406475090413238, 4.1085418778142371],
					[61.406475122902116, 4.1085418558325388],
					[61.406475138665776, 4.1085418439787995],
					[61.406552936872188, 4.1084668446952719],
					[61.406630736113968, 4.1083945470519501],
					[61.406630739557237, 4.1083945427591102],
					[61.406630748299889, 4.1083945357589542],
					[61.406630750848727, 4.1083945316063355],
					[61.406700151191053, 4.1083140306964889],
					[61.406700152085499, 4.1083140305562633],
					[61.406700152912727, 4.1083140285500548],
					[61.406700153807122, 4.1083140284098345],
					[61.406766847863189, 4.1082334353651611],
					[61.406847339913206, 4.1081529436985438],
					[61.406930738774896, 4.1080723449001031],
					[61.406930743112575, 4.1080723404669417],
					[61.406930750066337, 4.1080723337471019],
					[61.40693075261516, 4.1080723295943988],
					[61.407033453290474, 4.1079501298161771],
					[61.407033453223264, 4.107950127950172],
					[61.407033454117695, 4.1079501278099348],
					[61.40713625437624, 4.1078251267808712],
					[61.407136262089963, 4.107825116188657],
					[61.407136274901369, 4.1078250972909718],
					[61.407136279864595, 4.1078250852534639],
					[61.407252979711295, 4.1075806865314206],
					[61.407252980605712, 4.1075806863911755],
					[61.407369581082293, 4.1073361868818559],
					[61.40736958432386, 4.1073361769907093],
					[61.407369590807001, 4.1073361572084153],
					[61.407369594115792, 4.1073361491832934],
					[61.407439094229758, 4.107061149707957],
					[61.407439094162505, 4.1070611478419332],
					[61.407508493749454, 4.1067833479723799],
					[61.407508496722038, 4.1067833306169996],
					[61.407508498955039, 4.1067832927352859],
					[61.407508498215471, 4.1067832722089301],
					[61.407483498382469, 4.1065971746356471],
					[61.40746129919961, 4.1064110753207137],
					[61.407461293987872, 4.106411055495804],
					[61.407461279919431, 4.1064110145410906],
					[61.407461271129982, 4.1064109952773071],
					[61.407383470956354, 4.1062831959158848],
					[61.407383469994677, 4.1062831941901514],
					[61.407383469994677, 4.1062831941901514],
					[61.407383469033029, 4.106283192464411],
					[61.407302969370491, 4.1061554924790071],
					[61.40730296655272, 4.1061554891678407],
					[61.407302961811659, 4.1061554824051933],
					[61.407302958993888, 4.1061554790940304],
					[61.40722515903704, 4.1060526786033238],
					[61.407147361892278, 4.1059498837966517],
					[61.407091865836797, 4.1058692889545947],
					[61.407039084268519, 4.1057888165561032],
					[61.40700849287763, 4.1056777475668094],
					[61.40697799790032, 4.1055638657296196],
					[61.406964099405926, 4.1053971830585159],
					[61.406950199849312, 4.1052332967321963],
					[61.406958500289306, 4.1050444094523586],
					[61.40696690019287, 4.1048583137523211],
					[61.406983498814455, 4.1046750187282877],
					[61.407000198659915, 4.1044917188587471],
					[61.407000198592641, 4.1044917169927553],
					[61.407014099021985, 4.104333318851614],
					[61.407030798131608, 4.1041778310556856],
					[61.407052996209593, 4.1040694411560361],
					[61.407075188996671, 4.1039639755941515],
					[61.407122371109246, 4.103886204801519],
					[61.407169554001747, 4.1038084317662502],
					[61.407244529800145, 4.1037445523262033],
					[61.407244531521691, 4.1037445501794876],
					[61.407244532416144, 4.1037445500391287],
					[61.407316819341069, 4.1036807619595645],
					[61.407386203395141, 4.1036390716449365],
					[61.407386204289537, 4.1036390715045812],
					[61.407386205116687, 4.1036390694982066],
					[61.407386206011132, 4.1036390693578424],
					[61.407452905694555, 4.1035973703713609],
					[61.407511204594613, 4.1035612700317285],
					[61.407511208172281, 4.103561269470279],
					[61.407511211615393, 4.1035612651767881],
					[61.407511215193075, 4.1035612646153359],
					[61.407566815412238, 4.1035223636742844],
					[61.407566823260154, 4.1035223568129195],
					[61.407566839850467, 4.1035223429498391],
					[61.407566847631145, 4.1035223342224665],
					[61.407622346410385, 4.1034612358795739],
					[61.407680745117368, 4.1034001382144485],
					[61.407680752830736, 4.1034001276209668],
					[61.407680768257492, 4.1034001064339991],
					[61.407680775076429, 4.103400095980887],
					[61.4077279750001, 4.1033139953748403],
					[61.407727975827207, 4.1033139933684399],
					[61.407727978443184, 4.1033139910812926],
					[61.407727979270312, 4.1033139890748913],
					[61.407772378853224, 4.1032250886880544],
					[61.407772383883298, 4.1032250785156217],
					[61.407772392087409, 4.1032250565854582],
					[61.407772395261375, 4.1032250448277381],
					[61.407800194968267, 4.1031028445034554],
					[61.407800194900979, 4.1031028426374192],
					[61.4078279951732, 4.1029778421036758],
					[61.407827995933062, 4.1029778382312179],
					[61.407827998347152, 4.1029778303459112],
					[61.407827998212575, 4.1029778266138344],
					[61.40785019757606, 4.1028111266966523],
					[61.407872397593763, 4.1026417316116595],
					[61.407908495729501, 4.1024556372496503],
					[61.40794459464567, 4.1022694465969218],
					[61.407997392408646, 4.1020861550521026],
					[61.408050191399838, 4.1019000608735716],
					[61.408116888044532, 4.1017139666780489],
					[61.408116888938942, 4.1017139665376314],
					[61.408180788872905, 4.1015306657632014],
					[61.408180788872905, 4.1015306657632014],
					[61.408180789700026, 4.1015306637567015],
					[61.408180789632709, 4.1015306618906466],
					[61.40824188989528, 4.1013444613786056],
					[61.408302989951871, 4.1011583614732947],
					[61.40830299071164, 4.1011583576007391],
					[61.408302993125666, 4.101158349715166],
					[61.408302994779874, 4.1011583457021734],
					[61.408347394804011, 4.1009722456218434],
					[61.408347394804011, 4.1009722456218434],
					[61.408347394736694, 4.1009722437557787],
					[61.408389095474107, 4.1007889446757417],
					[61.408389096233861, 4.1007889408031515],
					[61.408389097686104, 4.1007889311919143],
					[61.408389098378592, 4.1007889254532728],
					[61.408414097669016, 4.1006000269663758],
					[61.408439097858334, 4.1004139279555423],
					[61.40843909779101, 4.100413926089475],
					[61.408439097723694, 4.1004139242234068],
					[61.408461297797501, 4.1002389258434366],
					[61.40848349680796, 4.1000611367837667],
					[61.408519592763568, 4.0999194503280956],
					[61.408558489372361, 4.0997750660438443],
					[61.408602983064696, 4.0996722803178764],
					[61.408650180831025, 4.0995667856735549],
					[61.408697378727894, 4.0994722897764939],
					[61.408697378660555, 4.0994722879104133],
					[61.408697380382058, 4.0994722857633406],
					[61.408697381276454, 4.0994722856228574],
					[61.408741881395194, 4.0993778847653086],
					[61.408741883876459, 4.099377878745571],
					[61.408741889598616, 4.0993778628334594],
					[61.408741892906967, 4.099377854807166],
					[61.408775191752788, 4.0992583543226484],
					[61.4088084917407, 4.0991417547163884],
					[61.408808494981685, 4.0991417448239531],
					[61.408808497885929, 4.0991417256010809],
					[61.408808499405403, 4.099141717855745],
					[61.408822399162418, 4.0989750164010426],
					[61.408822399162418, 4.0989750164010426],
					[61.408822399095065, 4.0989750145349406],
					[61.408822399922123, 4.0989750125283528],
					[61.408833500367855, 4.0988083133917543],
					[61.408833500233165, 4.0988083096595656],
					[61.408833499963784, 4.098808302195204],
					[61.408833499896446, 4.098808300329118],
					[61.408833499791406, 4.0985944014145561],
					[61.408836300437379, 4.0983806048018865],
					[61.408844600174326, 4.0981667079331672],
					[61.408852998767031, 4.0979528179009037],
					[61.408875197841212, 4.0977861251427088],
					[61.408897396642011, 4.0976167391393723],
					[61.408936292617568, 4.0974722524511238],
					[61.40893629255018, 4.0974722505850387],
					[61.408936294271648, 4.0974722484378585],
					[61.408936294271648, 4.0974722484378585],
					[61.408972389749742, 4.0973250636382392],
					[61.409039083682124, 4.0971694799193044],
					[61.409039083614772, 4.0971694780531918],
					[61.409039083547398, 4.0971694761870996],
					[61.40910297756276, 4.0970167921284757],
					[61.409189068784869, 4.0968779070359398],
					[61.409277954824091, 4.09673902856174],
					[61.409366836719634, 4.0966557453942309],
					[61.409458401111614, 4.0965695797614137],
					[61.409547254128391, 4.0965445927732436],
					[61.409547255022829, 4.0965445926326653],
					[61.409547256811635, 4.0965445923515142],
					[61.409547257638692, 4.0965445903448146],
					[61.409638923838408, 4.0965169014756597],
					[61.40973328772683, 4.0965224005884373],
					[61.409733290410017, 4.0965224001667115],
					[61.409733293093275, 4.0965223997449769],
					[61.409733293987649, 4.096522399604404],
					[61.4098305853963, 4.0965251996675383],
					[61.409919374968318, 4.0965362986968534],
					[61.409919375862728, 4.0965362985562734],
					[61.410011076184439, 4.0965473980680667],
					[61.410011084301516, 4.0965473986690126],
					[61.410011098746836, 4.0965474001520628],
					[61.410011106863905, 4.0965474007530105],
					[61.410088906570991, 4.0965445995319909],
					[61.410166707232392, 4.0965418994825002],
					[61.410166728765638, 4.0965418979747588],
					[61.410166772389566, 4.0965418854878948],
					[61.410166792758893, 4.0965418766560964],
					[61.410230690757381, 4.0965084772252869],
					[61.410297289052437, 4.0964751787156697],
					[61.410297305776723, 4.0964751685799303],
					[61.410297337301678, 4.0964751448572914],
					[61.410297352035037, 4.0964751294042134],
					[61.410364049122826, 4.0963973321508247],
					[61.410433446831654, 4.0963223362233991],
					[61.410433451995971, 4.0963223297813567],
					[61.410433462324605, 4.0963223168972487],
					[61.410433465700116, 4.09632231073636],
					[61.410500166073632, 4.0962223100553761],
					[61.410500166968063, 4.0962223099147819],
					[61.410500166968063, 4.0962223099147819],
					[61.410500167862452, 4.0962223097741965],
					[61.410566867796732, 4.0961195085683055],
					[61.410566870345221, 4.0961195044141583],
					[61.41056687372069, 4.0961194982532243],
					[61.410566876269144, 4.0961194940990833],
					[61.410627976384305, 4.0960056945567027],
					[61.410627976384305, 4.0960056945567027],
					[61.410627977211348, 4.0960056925499169],
					[61.410686276578552, 4.095894493000273],
					[61.41068627829997, 4.0958944908528689],
					[61.410686280781057, 4.0958944848325007],
					[61.410686281608051, 4.0958944828257149],
					[61.410741881900961, 4.0957722831401453],
					[61.410797380842382, 4.0956528840936199],
					[61.41079738173682, 4.0956528839530089],
					[61.410797381669425, 4.0956528820868146],
					[61.410797382563828, 4.0956528819462097],
					[61.410844582765883, 4.095547282818103],
					[61.410891881800538, 4.0954417823043769],
					[61.41089188262756, 4.0954417802975618],
					[61.41089188262756, 4.0954417802975618],
					[61.410891884348977, 4.0954417781501329],
					[61.410936282078097, 4.0953389818510137],
					[61.410983480235871, 4.0952389861962288],
					[61.41103907807021, 4.0951278914154772],
					[61.411097373827218, 4.0950194987946249],
					[61.411164071648173, 4.0949057025897124],
					[61.411233469550282, 4.0947918041366531],
					[61.411316869727123, 4.0946584065902663],
					[61.411316869659714, 4.0946584047240426],
					[61.411316871448534, 4.0946584044427645],
					[61.411316871381132, 4.0946584025765409],
					[61.411397371465569, 4.0945251037279622],
					[61.411397372292591, 4.0945251017210884],
					[61.411397373186972, 4.0945251015804471],
					[61.41139737311957, 4.09452509971422],
					[61.411472373094512, 4.094394500605099],
					[61.411550172661137, 4.0942612008739339],
					[61.41155017686355, 4.0942611927057113],
					[61.411550185268361, 4.0942611763692858],
					[61.411550187614516, 4.0942611666161399],
					[61.411591886821519, 4.0941444703925898],
					[61.411636285673744, 4.0940306731728739],
					[61.41163628974131, 4.0940306612721225],
					[61.411636296087622, 4.0940306377519349],
					[61.411636299193354, 4.0940306241256028],
					[61.411652999450951, 4.0938944245225102],
					[61.411669598785672, 4.0937583232117705],
					[61.411669598583423, 4.0937583176130472],
					[61.411669599765496, 4.0937583005355078],
					[61.411669600457635, 4.0937582947961211],
					[61.411664099902801, 4.0935832937267742],
					[61.411658500199373, 4.0934082932740861],
					[61.411658500131956, 4.0934082914078456],
					[61.411658499997124, 4.0934082876753664],
					[61.411658498967846, 4.0934082840835515],
					[61.4116445987746, 4.0932221851879582],
					[61.411644598707198, 4.0932221833217177],
					[61.411644598707198, 4.0932221833217177],
					[61.411644598639761, 4.0932221814554817],
					[61.411628000411291, 4.0930332883019283],
					[61.411622400341223, 4.0928471940961551],
					[61.411622400273806, 4.092847192229919],
					[61.411622400273806, 4.092847192229919],
					[61.411622400206355, 4.0928471903636865],
					[61.411614100101588, 4.0926582965179001],
					[61.411616899902477, 4.0924722041497521],
					[61.411622399554965, 4.0922861073880528],
					[61.411630799716981, 4.0921111099490917],
					[61.411630799716981, 4.0921111099490917],
					[61.411630799582099, 4.092111106216624],
					[61.411630799582099, 4.092111106216624],
					[61.411636299951361, 4.0919333066841705],
					[61.411636299883916, 4.0919333048179345],
					[61.411639100075796, 4.0917861041271708],
					[61.411641900107966, 4.0916389034173779],
					[61.411641900107966, 4.0916389034173779],
					[61.411641900040522, 4.0916389015511436],
					[61.41164189997307, 4.0916388996848942],
					[61.41164190013599, 4.0915388992912014],
					[61.411641900427711, 4.0914389044996202],
					[61.411644599923385, 4.09136670715323],
					[61.41164459985594, 4.0913667052869949],
					[61.41164459972105, 4.0913667015545121],
					[61.411644599653584, 4.0913666996882805],
					[61.411644600037064, 4.091294410907353],
					[61.411655798534333, 4.0911917211749014],
					[61.411666898429964, 4.0910917307725825],
					[61.411694595965201, 4.0909556397384543],
					[61.411722391865673, 4.0908194616460136],
					[61.411766882692412, 4.0907194806143874],
					[61.411766882692412, 4.0907194806143874],
					[61.411766884413744, 4.0907194784666414],
					[61.411811260877293, 4.0906168331032333],
					[61.411872308064503, 4.0905751688346816],
					[61.411936155637257, 4.090536299390636],
					[61.412016696886482, 4.0905363001532713],
					[61.412097172848711, 4.0905390983443084],
					[61.412188848809002, 4.0905612938455844],
					[61.412277737706141, 4.0905862892205409],
					[61.412366627134944, 4.090619586142842],
					[61.412455525214199, 4.0906557854436008],
					[61.412455531609915, 4.090655788190837],
					[61.41245554612275, 4.0906557915375172],
					[61.412455553412862, 4.0906557941439905],
					[61.412547149418316, 4.0906779933910009],
					[61.412636045655155, 4.0907029938115258],
					[61.412636063745552, 4.0907029965951889],
					[61.412636099791477, 4.090702998429947],
					[61.412636118708818, 4.0907029992065604],
					[61.412725019287123, 4.0906945993694013],
					[61.412725020181533, 4.0906945992286392],
					[61.412725022864748, 4.0906945988063521],
					[61.41272502375913, 4.0906945986655945],
					[61.412816723984051, 4.0906834983444638],
					[61.412816743593432, 4.0906834933813796],
					[61.412816782744734, 4.0906834815889477],
					[61.412816802219204, 4.0906834728932617],
					[61.412905700808665, 4.0906307721536743],
					[61.412997299683184, 4.0905779726988278],
					[61.412997309386661, 4.0905779674177785],
					[61.412997326042991, 4.0905779554116499],
					[61.412997333890232, 4.0905779485458202],
					[61.413086233210393, 4.0904973482618647],
					[61.413177930051951, 4.0904168515062853],
					[61.413266829403128, 4.0903418529468869],
					[61.413266829335662, 4.090341851080562],
					[61.413358403199901, 4.090264075536024],
					[61.413447270319566, 4.0902307871841002],
					[61.41344727121394, 4.0902307870433257],
					[61.413447273002745, 4.0902307867617669],
					[61.413538920278285, 4.090194607412994],
					[61.413627762649135, 4.0902112968739903],
					[61.413716625859294, 4.0902279906458325],
					[61.413805494154339, 4.0902834696334773],
					[61.413894285643785, 4.0903390642465265],
					[61.413985977545892, 4.0904084583930924],
					[61.414074876713983, 4.0904779583914737],
					[61.414074883109706, 4.0904779611387969],
					[61.414074896036077, 4.0904779703662175],
					[61.414074901537447, 4.0904779732543295],
					[61.414163801923891, 4.0905279732674238],
					[61.414252702294299, 4.0905779735544128],
					[61.414252726982831, 4.0905779846846348],
					[61.41425277870578, 4.0905779971908318],
					[61.414252806702073, 4.0905780002924157],
					[61.414338902716402, 4.0905752007774794],
					[61.414425000035472, 4.0905751996639825],
					[61.414425034022734, 4.0905751943145479],
					[61.414425098774352, 4.090575169106816],
					[61.414425128779222, 4.0905751531220851],
					[61.414497328824524, 4.0905140536910807],
					[61.414569528771345, 4.0904529521045525],
					[61.414569542677015, 4.0904529386536232],
					[61.414569565881386, 4.0904529087228889],
					[61.414569577036353, 4.090452893827913],
					[61.414619575696548, 4.0903583953416369],
					[61.414669575665307, 4.0902667963509689],
					[61.414669579799991, 4.0902667863149738],
					[61.41466958806933, 4.0902667662429915],
					[61.414669592271494, 4.0902667580734233],
					[61.414702992392463, 4.0901556584094374],
					[61.414702992325005, 4.0901556565430051],
					[61.414702992257531, 4.0901556546765967],
					[61.414702993151955, 4.0901556545358035],
					[61.414733492766587, 4.0900472542626716],
					[61.414733492699114, 4.0900472523962597],
					[61.414733494352973, 4.0900472483818335],
					[61.414733495179881, 4.0900472463746311],
					[61.414766895330303, 4.0899056462078827],
					[61.414766895262851, 4.0899056443414654],
					[61.41476689519537, 4.0899056424750535],
					[61.414766896089795, 4.0899056423342524],
					[61.414797396148536, 4.089763942785229],
					[61.414797396081084, 4.08976394091881],
					[61.414797396013604, 4.0897639390523954],
					[61.414797395946138, 4.0897639371859595],
					[61.414825196078986, 4.0896194395039629],
					[61.414855795676125, 4.089472240853782],
					[61.414855795676125, 4.089472240853782],
					[61.414855795608652, 4.0894722389873639],
					[61.414855795608652, 4.0894722389873639],
					[61.414877995920627, 4.0893583378939162],
					[61.414877995920627, 4.0893583378939162],
					[61.414900195762549, 4.0892417393823068],
					[61.41492519525324, 4.0891222447198272],
					[61.414952991962977, 4.0890056580932592],
					[61.41499738844427, 4.0888833679250478],
					[61.415041872427707, 4.0887612119028729],
					[61.415108435443535, 4.0886973473790524],
					[61.415177855178925, 4.0886363176110434],
					[61.415261053596964, 4.0886557953714364],
					[61.415261055385756, 4.0886557950897693],
					[61.415344316325559, 4.0886751861384898],
					[61.415433183064408, 4.0887390617551542],
					[61.415524870699826, 4.0888057521662535],
					[61.415613760758056, 4.0888918439597237],
					[61.415613762614349, 4.0888918455445271],
					[61.415613762614349, 4.0888918455445271],
					[61.415702652042192, 4.0889751354044774],
					[61.415791542168492, 4.0890890223801826],
					[61.415880435985628, 4.0892029150333693],
					[61.415969230974333, 4.0893445056542523],
					[61.415969231041814, 4.0893445075207353],
					[61.415969231936181, 4.0893445073799208],
					[61.416058131870699, 4.0894834073639421],
					[61.416058132900055, 4.0894834109561069],
					[61.416058136747573, 4.0894834178588271],
					[61.416058140392629, 4.0894834191620593],
					[61.416147040469049, 4.0896029198232444],
					[61.416235940380972, 4.0897223198037187],
					[61.416235956260486, 4.0897223360750168],
					[61.416235990635286, 4.0897223663286599],
					[61.416236009957487, 4.0897223783037111],
					[61.41632490968945, 4.0897668794729576],
					[61.416324910583874, 4.0897668793321458],
					[61.416413811131704, 4.0898112792478747],
					[61.416413841186689, 4.0898112895333325],
					[61.416413905296366, 4.0898112963346165],
					[61.41641393680279, 4.089811297005892],
					[61.416502835967165, 4.0897945963411519],
					[61.416594436393538, 4.0897779977744131],
					[61.416594437220496, 4.0897779957670757],
					[61.416594440798072, 4.08977799520384],
					[61.416594442586884, 4.0897779949222191],
					[61.416683340419041, 4.0897584954127675],
					[61.416772205650297, 4.0897419011252785],
					[61.416863876275151, 4.0897529981393212],
					[61.416863877237049, 4.089752999865043],
					[61.416863879920271, 4.0897529994426041],
					[61.416863880814653, 4.0897529993017958],
					[61.416952751711506, 4.0897612952771603],
					[61.417041625256914, 4.0897973843470181],
					[61.417041626218762, 4.0897973860727603],
					[61.417041628075026, 4.0897973876576881],
					[61.417041629863832, 4.0897973873760627],
					[61.417130511155015, 4.0898307797862881],
					[61.417210993882101, 4.0898807692817707],
					[61.417210994776468, 4.0898807691409633],
					[61.417291570909775, 4.0899307554406805],
					[61.417349851634334, 4.089994535306805],
					[61.417408128549468, 4.0900584098627073],
					[61.417455415892483, 4.0901694786637366],
					[61.417455415892483, 4.0901694786637366],
					[61.417455416854331, 4.0901694803895126],
					[61.417505411838796, 4.0902833689491738],
					[61.417555407710239, 4.0904528568232053],
					[61.417555407777691, 4.0904528586897886],
					[61.417555408672129, 4.0904528585489892],
					[61.417555408739581, 4.0904528604155725],
					[61.417608108080074, 4.0906194565876559],
					[61.417660907080119, 4.0908083539090114],
					[61.417660907974529, 4.0908083537682236],
					[61.417660908042002, 4.0908083556348123],
					[61.417716507872562, 4.0909972557260739],
					[61.417716507940014, 4.0909972575926705],
					[61.417716508834438, 4.0909972574518845],
					[61.417774809117653, 4.0911889582004566],
					[61.417774809117653, 4.0911889582004566],
					[61.417833108672504, 4.0913778589310148],
					[61.417833109701839, 4.0913778625234603],
					[61.417833111692993, 4.091377867841727],
					[61.417833112722306, 4.091377871434176],
					[61.417905413406672, 4.0915667712400206],
					[61.417977612556115, 4.0917583704266942],
					[61.417977614412379, 4.0917583720117978],
					[61.417977614547254, 4.0917583757450267],
					[61.417977616403519, 4.0917583773301152],
					[61.418058116287213, 4.0919472781328512],
					[61.418138715669052, 4.0921361773554983],
					[61.41813871573649, 4.0921361792221207],
					[61.418138716698351, 4.0921361809480077],
					[61.418138717660177, 4.0921361826738805],
					[61.418224818428179, 4.0923278814962076],
					[61.41831091804552, 4.0925194820237643],
					[61.418310918112965, 4.0925194838903982],
					[61.418310919074791, 4.0925194856162923],
					[61.418310920036625, 4.0925194873421837],
					[61.418402619136963, 4.0927083868584289],
					[61.418491519053902, 4.0929000838483809],
					[61.418491519121325, 4.0929000857150282],
					[61.418491520015735, 4.0929000855742954],
					[61.41849152008318, 4.0929000874409569],
					[61.418583118723454, 4.093088986122571],
					[61.418672016067077, 4.0932806781211113],
					[61.41874701301397, 4.0934694725901561],
					[61.418819208831948, 4.093661160342057],
					[61.418866506200025, 4.0938500490293306],
					[61.418866506267456, 4.0938500508960187],
					[61.418866507161837, 4.0938500507553144],
					[61.418916504649602, 4.0940389432399638],
					[61.418949802859188, 4.0942278350166221],
					[61.418983102868957, 4.094416734392226],
					[61.419013701911062, 4.094602831932753],
					[61.419041500768415, 4.0947917243703706],
					[61.419060900973832, 4.0949806202280588],
					[61.419060900973832, 4.0949806202280588],
					[61.419080399713827, 4.0951694125613285],
					[61.419085900042042, 4.0953556045444142],
					[61.419088700063142, 4.0955443932566036],
					[61.419074800605848, 4.0957305836281268],
					[61.419058100903563, 4.0959166772487707],
					[61.41903310162806, 4.0960971710661349],
					[61.41900540325485, 4.0962805688700055],
					[61.418977603375502, 4.0964443665497665],
					[61.418949803356533, 4.0966082653093139],
					[61.418919203613534, 4.0967693637565326],
					[61.418919202719124, 4.0967693638971561],
					[61.418919202786491, 4.0967693657638407],
					[61.418919202786491, 4.0967693657638407],
					[61.418891502858173, 4.0969305655152048],
					[61.418860903165665, 4.0971027654956407],
					[61.418860903165665, 4.0971027654956407],
					[61.418860903233032, 4.0971027673623217],
					[61.418860903233032, 4.0971027673623217],
					[61.418833102554558, 4.0972749681099634],
					[61.418833101727522, 4.0972749701172493],
					[61.418833101862276, 4.097274973850622],
					[61.418833101929664, 4.0972749757172986],
					[61.418813701834388, 4.097427775536131],
					[61.418813700939978, 4.0974277756767368],
					[61.418813701007323, 4.097427777543416],
					[61.418813701007323, 4.097427777543416],
					[61.418797000711805, 4.0975777780017255],
					[61.418797000981272, 4.0975777854684514],
					[61.418796999731406, 4.0975778006831023],
					[61.418796999933512, 4.0975778062831321],
					[61.418802600077228, 4.0977333063451509],
					[61.418802600144602, 4.0977333082118435],
					[61.418802600211968, 4.0977333100785218],
					[61.418802600211968, 4.0977333100785218],
					[61.418810899588948, 4.0978889115244854],
					[61.418810900685422, 4.0978889169839521],
					[61.41881090198406, 4.0978889280434467],
					[61.418810903080534, 4.0978889335028956],
					[61.418847002825522, 4.0980972343398463],
					[61.418847002825522, 4.0980972343398463],
					[61.418847002892868, 4.0980972362065282],
					[61.418847002892868, 4.0980972362065282],
					[61.418885903274237, 4.098305636075473],
					[61.418885904303387, 4.0983056396682782],
					[61.418885905332488, 4.0983056432610692],
					[61.418885906361638, 4.0983056468538743],
					[61.418935905085583, 4.0985139455077322],
					[61.418985904706794, 4.0987250455517366],
					[61.418985905668535, 4.0987250472778651],
					[61.418985905735887, 4.0987250491445737],
					[61.418985905803233, 4.0987250510112654],
					[61.419033106401116, 4.0989056502052614],
					[61.419033107295554, 4.0989056500646948],
					[61.419080405697102, 4.0990861499281293],
					[61.419127604925244, 4.0992778451426393],
					[61.419172004794447, 4.099469443890313],
					[61.419216504372486, 4.0996722424286611],
					[61.419258104305491, 4.0998722400169152],
					[61.419297004083489, 4.1000556409064055],
					[61.419297004150813, 4.1000556427731194],
					[61.419335903627861, 4.1002389427640162],
					[61.41933590458958, 4.1002389444902088],
					[61.419335905686012, 4.1002389499498468],
					[61.419335906715062, 4.1002389535427577],
					[61.419385907007928, 4.1004194511093779],
					[61.419433106374278, 4.1006028504103824],
					[61.419433107268702, 4.1006028502698637],
					[61.419433107403357, 4.100602854003311],
					[61.419433108365077, 4.1006028557295204],
					[61.419488707769958, 4.1007972548038021],
					[61.419488707837296, 4.1007972566705275],
					[61.419488707837296, 4.1007972566705275],
					[61.419547007353174, 4.1009944536987266],
					[61.419597007261274, 4.1011833508555524],
					[61.419597007328598, 4.1011833527222867],
					[61.419597007328598, 4.1011833527222867],
					[61.419597007395929, 4.1011833545890211],
					[61.419649805056281, 4.101372244626643],
					[61.419685902905641, 4.101575035454684],
					[61.419685902905641, 4.101575035454684],
					[61.419722002127905, 4.1017750292951964],
					[61.419749800992058, 4.1020028237514055],
					[61.419774801065564, 4.1022306150859844],
					[61.419785899960033, 4.1024417096557455],
					[61.419794199912829, 4.1026527952086278],
					[61.419780401412581, 4.1028166821453738],
					[61.419780400585445, 4.1028166841525815],
					[61.419780400652762, 4.1028166860193318],
					[61.419780399758359, 4.1028166861597839],
					[61.419769200527988, 4.1029805721430455],
					[61.419741504378685, 4.1031138592976921],
					[61.419741504378685, 4.1031138592976921],
					[61.419741503551549, 4.1031138613048901],
					[61.419716505641738, 4.1032443515962402],
					[61.419680408843512, 4.1033610402143985],
					[61.4196804089108, 4.1033610420811453],
					[61.419680408016404, 4.1033610422215814],
					[61.419680408083693, 4.1033610440883281],
					[61.419647011348928, 4.1034749355529749],
					[61.419605414317992, 4.1035805268670522],
					[61.419605414385266, 4.1035805287338132],
					[61.419605412663721, 4.1035805308814215],
					[61.419605411769275, 4.1035805310218567],
					[61.419566511606362, 4.1036860308999765],
					[61.419566510846501, 4.1036860347738884],
					[61.419566509192236, 4.1036860387882257],
					[61.419566509326813, 4.1036860425217236],
					[61.419522008849782, 4.1038332414995988],
					[61.419477608837262, 4.1039777415998362],
					[61.419477607317539, 4.1039777493476377],
					[61.419477603316452, 4.1039777631169017],
					[61.419477601863981, 4.1039777727314446],
					[61.419444201737441, 4.1042166707428622],
					[61.419408101766152, 4.1044527705762492],
					[61.419408101140831, 4.1044527781836058],
					[61.419408099890177, 4.1044527933983161],
					[61.419408100226548, 4.1044528027319975],
					[61.419410899847506, 4.104708302607718],
					[61.419410899847506, 4.104708302607718],
					[61.419410899914773, 4.1047083044744523],
					[61.419416500008019, 4.1049639035527736],
					[61.419416501306038, 4.1049639146128056],
					[61.419416502873126, 4.104963933139798],
					[61.419416505065556, 4.1049639440594401],
					[61.419455405232959, 4.1051361439355185],
					[61.419494205413315, 4.1053111420679649],
					[61.419494206644039, 4.1053111512613043],
					[61.419494211721513, 4.1053111673600693],
					[61.419494213779394, 4.1053111745462836],
					[61.41953871401514, 4.1054222724294664],
					[61.419580409216998, 4.1055333603054756],
					[61.41961090601032, 4.1056472497683076],
					[61.419638702561976, 4.1057639380136468],
					[61.419660901587982, 4.1059167288724101],
					[61.419683101419572, 4.1060694221825704],
					[61.419697001013162, 4.1062500140807172],
					[61.419710899846528, 4.1064333133162414],
					[61.419722000323539, 4.1066222106404977],
					[61.419730400204571, 4.1068083084666025],
					[61.41973040027181, 4.1068083103333803],
					[61.41973040027181, 4.1068083103333803],
					[61.419730400339063, 4.1068083122001378],
					[61.419741500219502, 4.1069944107383529],
					[61.419749799848866, 4.1071833085090024],
					[61.419758099885264, 4.1073639085624416],
					[61.419758099952489, 4.1073639104292043],
					[61.419758099952489, 4.1073639104292043],
					[61.419758100019735, 4.1073639122959644],
					[61.419769199970986, 4.1075472120271472],
					[61.419769200038239, 4.1075472138939082],
					[61.419780399913748, 4.1077139138593539],
					[61.419791499774739, 4.1078833122939038],
					[61.419791500870822, 4.1078833177539114],
					[61.419791502034151, 4.1078833250806772],
					[61.419791502235853, 4.1078833306809717],
					[61.419813701592659, 4.1080306285757322],
					[61.419833101628313, 4.1081778269834048],
					[61.419833102791628, 4.108177834310192],
					[61.419833106974338, 4.108177850549934],
					[61.419833109099294, 4.1081778596031908],
					[61.419872008962443, 4.1083028583110721],
					[61.419872009029667, 4.1083028601778606],
					[61.41987200999133, 4.1083028619043311],
					[61.419872010058555, 4.1083028637711028],
					[61.419913709883211, 4.1084278628786448],
					[61.419913713864233, 4.1084278735181403],
					[61.419913723615103, 4.1084278945165087],
					[61.419913729384994, 4.1084279048753913],
					[61.419977629437184, 4.1085334048101751],
					[61.419977630331623, 4.1085334046698803],
					[61.419977630398847, 4.1085334065366581],
					[61.420041530246294, 4.1086362054454959],
					[61.420041534092853, 4.1086362123514792],
					[61.420041544402039, 4.1086362238757781],
					[61.420041548248612, 4.1086362307817614],
					[61.42011094770794, 4.1087168297376282],
					[61.420110948669567, 4.108716831464128],
					[61.420110949631223, 4.1087168331906225],
					[61.420110951420057, 4.1087168329100479],
					[61.420180448771553, 4.1087945313922098],
					[61.420235946282489, 4.1088612282974424],
					[61.420291536023157, 4.1089279147212059],
					[61.420338725797826, 4.1090083994242939],
					[61.420383121006097, 4.1090889884880042],
					[61.420424816316526, 4.1091833792139862],
					[61.42046371130796, 4.1092750666740647],
					[61.420497006638939, 4.1093944524361969],
					[61.420527606377654, 4.1095111466421388],
					[61.420563704612242, 4.1096750429113511],
					[61.420599804576426, 4.1098389430200433],
					[61.420641504882084, 4.1100278428602337],
					[61.420641504949302, 4.1100278447270542],
					[61.420683104069894, 4.1102139411056706],
					[61.420722003722702, 4.110402839844463],
					[61.420758102768886, 4.1105889321652809],
					[61.420783102110093, 4.1107722275626877],
					[61.4208081011232, 4.1109556209007261],
					[61.420819200122715, 4.1111167146255463],
					[61.420830399713189, 4.1112778095705638],
					[61.420833099722543, 4.111408302950446],
					[61.420833100375653, 4.1115360993029917],
					[61.42083309961555, 4.1115361031768822],
					[61.420833099817095, 4.1115361087774129],
					[61.420833099951444, 4.1115361125110885],
					[61.420841499884993, 4.1116778101758893],
					[61.420846999773858, 4.1118222078277498],
					[61.420846999841046, 4.1118222096945889],
					[61.42084700093698, 4.1118222151549233],
					[61.420847001071323, 4.1118222188886016],
					[61.420863701216263, 4.1120083183829044],
					[61.420863701216263, 4.1120083183829044],
					[61.420863701283437, 4.112008320249763],
					[61.420863701283437, 4.112008320249763],
					[61.420883101433319, 4.1121944210002264],
					[61.420883100606048, 4.1121944230072645],
					[61.420883100673223, 4.1121944248741062],
					[61.420905401275853, 4.1123806231731761],
					[61.420927600998041, 4.1125667248782536],
					[61.420927601892465, 4.1125667247380733],
					[61.420927601959626, 4.1125667266049204],
					[61.420927601959626, 4.1125667266049204],
					[61.420947002342018, 4.1127083266231033],
					[61.420947002409164, 4.1127083284899513],
					[61.420947001581915, 4.112708330496976],
					[61.420947003437952, 4.1127083320834652],
					[61.420969201851563, 4.1128472296349283],
					[61.420985901800755, 4.1129611281735032],
					[61.42098590186793, 4.112961130040353],
					[61.420985902896696, 4.1129611336338954],
					[61.420985902963849, 4.1129611355007487],
					[61.421005402942029, 4.1130722354822353],
					[61.421005402942029, 4.1130722354822353],
					[61.42100540300919, 4.1130722373490869],
					[61.421030403001183, 4.1132056368023324],
					[61.421030403895649, 4.1132056366621628],
					[61.421030403962781, 4.1132056385290374],
					[61.421055403828696, 4.1133361376449598],
					[61.421055403895856, 4.1133361395118149],
					[61.421055404991741, 4.113336144972239],
					[61.421055405953332, 4.1133361466989316],
					[61.421094205129435, 4.1134972458767072],
					[61.421130405410189, 4.1136611425800913],
					[61.421130405544474, 4.1136611463138317],
					[61.421130407669096, 4.1136611553678497],
					[61.421130408697827, 4.1136611589614169],
					[61.421177607693359, 4.1138139569495094],
					[61.421222008299701, 4.1139667564145013],
					[61.421222009328396, 4.1139667600080951],
					[61.421222012280289, 4.1139667670551452],
					[61.421222013376131, 4.1139667725156244],
					[61.421269213291509, 4.1140889697037615],
					[61.421313712014168, 4.1142111680519378],
					[61.421313714004462, 4.114211173372297],
					[61.42131371791789, 4.114211182146132],
					[61.42131371990817, 4.114211187466478],
					[61.421360920010763, 4.1143083858756473],
					[61.421408119146633, 4.1144083858035572],
					[61.421408121964234, 4.114408389116913],
					[61.421408125944794, 4.1144083997576768],
					[61.421408128829512, 4.114408404937933],
					[61.421455428964954, 4.1144862040423584],
					[61.42150262923905, 4.1145640040006173],
					[61.421502633979763, 4.1145640107675145],
					[61.421502644355627, 4.1145640241612114],
					[61.421502649163465, 4.1145640327950126],
					[61.421560949018094, 4.1146307314008528],
					[61.421560949912532, 4.1146307312607231],
					[61.421619249823486, 4.1146973324731331],
					[61.421619259103501, 4.1146973404063516],
					[61.421619277730635, 4.1146973581396855],
					[61.421619287905067, 4.1146973659327815],
					[61.421697086685981, 4.1147501644033548],
					[61.42177768640834, 4.1148057647565182],
					[61.421777702016541, 4.1148057735758368],
					[61.421777734887428, 4.1148057872004209],
					[61.421777752217352, 4.1148057938725913],
					[61.421866651976735, 4.1148279939256831],
					[61.421955551733063, 4.1148501940937239],
					[61.421955570717564, 4.1148501967518785],
					[61.421955610341158, 4.1148501980540964],
					[61.421955630152944, 4.1148501987051977],
					[61.422047229867886, 4.1148362971397532],
					[61.422047230829428, 4.1148362988665674],
					[61.422136131243207, 4.1148223976659111],
					[61.422136146381447, 4.1148223934168451],
					[61.422136174869017, 4.1148223851989743],
					[61.422136189045688, 4.1148223792230914],
					[61.422225089181396, 4.1147779787889602],
					[61.422225090970251, 4.1147779785087053],
					[61.422225093586412, 4.1147779762213936],
					[61.422225096269727, 4.1147779758010072],
					[61.422311196387533, 4.1147307751629052],
					[61.42231121132432, 4.1147307653129701],
					[61.422311238380395, 4.1147307422995736],
					[61.422311250566757, 4.114730731003073],
					[61.42238345092121, 4.114647330606342],
					[61.422383451815605, 4.1146473304662159],
					[61.422383455259101, 4.1146473261718048],
					[61.422383456153511, 4.1146473260316743],
					[61.422452956315524, 4.1145612260631976],
					[61.422452967338948, 4.114561207438916],
					[61.422452985807993, 4.1145611707509193],
					[61.422452994080935, 4.1145611506801076],
					[61.422489094117488, 4.1144222508691781],
					[61.422525194008621, 4.1142833507415046],
					[61.422525196289058, 4.1142833391193809],
					[61.422525199195398, 4.1142833198893056],
					[61.422525199686959, 4.1142833085474697],
					[61.42253350016253, 4.1141000097917919],
					[61.422533500095376, 4.1141000079248462],
					[61.42253350002823, 4.1141000060578996],
					[61.42253350002823, 4.1141000060578996],
					[61.42253910012564, 4.1139139085761212],
					[61.422547399983621, 4.113744410976091],
					[61.422558497386156, 4.1135750493731109],
					[61.422597381896701, 4.1134889829500745],
					[61.422636198043548, 4.1134030678282434],
					[61.422688813810439, 4.1134279804025988],
					[61.422741536639322, 4.1134529438678982],
					[61.422780409400076, 4.1135722605712584],
					[61.422816503096101, 4.1136917389394476],
					[61.422833100836399, 4.1138667183878468],
					[61.422849800696703, 4.1140389164538593],
					[61.422860899934101, 4.1142222114122378],
					[61.422872000002293, 4.1144056114830203],
					[61.422872001165281, 4.1144056188107676],
					[61.422872002596826, 4.114405633606399],
					[61.422872003759814, 4.1144056409341268],
					[61.422905403746476, 4.1145639410477637],
					[61.42293870366607, 4.1147222402591304],
					[61.42293870771374, 4.1147222527674394],
					[61.422938715741985, 4.1147222759170443],
					[61.422938719789649, 4.1147222884253365],
					[61.422999820153677, 4.1148472871344435],
					[61.422999821115248, 4.1148472888612879],
					[61.422999821115248, 4.1148472888612879],
					[61.423060920557603, 4.1149694888332302],
					[61.423060930106139, 4.1149695042348142],
					[61.423060949963322, 4.1149695311638901],
					[61.423060961233602, 4.1149695444182219],
					[61.423138760259917, 4.1150445435395167],
					[61.423216559095373, 4.1151223405414257],
					[61.42321658501222, 4.1151223608888854],
					[61.423216642704091, 4.1151223894009616],
					[61.423216673584754, 4.115122397705723],
					[61.423302774373632, 4.1151334987743509],
					[61.423302775268077, 4.1151334986342247],
					[61.423391675173207, 4.115144598699529],
					[61.423391704823764, 4.115144597809441],
					[61.423391762894759, 4.1151445868343925],
					[61.423391792276803, 4.1151445784763014],
					[61.423472288733336, 4.1151029786947335],
					[61.423552862076406, 4.1150640928155227],
					[61.423600028054345, 4.1150557985331471],
					[61.423649819228515, 4.1150502209157533],
					[61.423658101058045, 4.1151417150374439],
					[61.423663699272694, 4.1152332972950338],
					[61.423649801420936, 4.1153999823478218],
					[61.423635901216919, 4.1155666826177733],
					[61.423635899629424, 4.1155666884990492],
					[61.423635899897924, 4.1155666959671313],
					[61.423635900032174, 4.115566699701164],
					[61.423635900348323, 4.1157556002592282],
					[61.423635899542788, 4.1159416996833968],
					[61.423635899878398, 4.1159417090184993],
					[61.423635902338447, 4.1159417274085053],
					[61.423635902674043, 4.1159417367436086],
					[61.423669202853439, 4.1161222352345295],
					[61.423702602620168, 4.1163056361782369],
					[61.423702604744641, 4.116305645233175],
					[61.42370260988794, 4.1163056632029758],
					[61.423702612839698, 4.116305670250803],
					[61.423760913349611, 4.116458369680065],
					[61.423816511623144, 4.116611168059908],
					[61.423816515603555, 4.1166111787017643],
					[61.42381652521901, 4.1166111959712559],
					[61.42381653002672, 4.116611204606003],
					[61.423883130246693, 4.1167195042906757],
					[61.423949830250031, 4.1168279052031922],
					[61.423949839731264, 4.1168279187387444],
					[61.423949862204317, 4.1168279433824617],
					[61.423949874301719, 4.1168279546307414],
					[61.424024874394966, 4.1168890541295475],
					[61.424099873636017, 4.1169501559221464],
					[61.424099896600879, 4.1169501692237009],
					[61.424099947762926, 4.1169501912522488],
					[61.424099974104202, 4.1169501983923373],
					[61.424186073630537, 4.1169612973848118],
					[61.424272174117483, 4.1169723981545987],
					[61.424272201084783, 4.1169723976864114],
					[61.424272253029123, 4.1169723914290257],
					[61.424272277939124, 4.116972383772759],
					[61.424363977687406, 4.1169334846690493],
					[61.424363980303589, 4.1169334823817545],
					[61.424363982986904, 4.1169334819615173],
					[61.424363984775766, 4.116933481681361],
					[61.424452885424039, 4.1168918813929425],
					[61.424452896850383, 4.1168918739706939],
					[61.42445292059751, 4.1168918589860839],
					[61.424452931956736, 4.1168918496967688],
					[61.424544532426509, 4.1168112494164948],
					[61.42454453332094, 4.1168112492764113],
					[61.4245445342154, 4.1168112491363242],
					[61.424544534148303, 4.1168112472692506],
					[61.424633434416982, 4.1167307483321194],
					[61.424633441371142, 4.116730741610179],
					[61.424633452596133, 4.1167307285865746],
					[61.424633458655855, 4.1167307220047187],
					[61.424725157573498, 4.1166112221415423],
					[61.424816857294019, 4.1164945246880214],
					[61.424816863286573, 4.1164945162390154],
					[61.424816871761173, 4.1164945017684138],
					[61.424816876859325, 4.1164944934594949],
					[61.424902976976007, 4.1163306938146969],
					[61.424989076905597, 4.1161667937472748],
					[61.424989080281989, 4.116166787585545],
					[61.424989086073232, 4.1161667735351077],
					[61.424989087660734, 4.1161667676535858],
					[61.425058487873919, 4.115972267491105],
					[61.425058488768364, 4.1159722673509931],
					[61.425058488701254, 4.1159722654838715],
					[61.425127988589978, 4.1157750656774681],
					[61.425127991071932, 4.1157750596557987],
					[61.42512799493997, 4.1157750421512347],
					[61.42512799742191, 4.115775036129568],
					[61.425164097099746, 4.1155750372671509],
					[61.425202995662353, 4.1153750387802317],
					[61.425202998010022, 4.1153750290242828],
					[61.42520300002203, 4.1153750099327731],
					[61.425202999686384, 4.1153750005972114],
					[61.425202999545498, 4.1152027994752478],
					[61.425203000148031, 4.1150306000903445],
					[61.42520299981237, 4.1150305907547855],
					[61.425202998246604, 4.1150305722238043],
					[61.425202997016505, 4.1150305630283821],
					[61.425177996608383, 4.1148971634374956],
					[61.425177995646834, 4.114897161710509],
					[61.425177995579688, 4.1148971598434034],
					[61.425177996474112, 4.114897159703264],
					[61.425150195839883, 4.114763859216759],
					[61.425150195772758, 4.1147638573496348],
					[61.425150194878341, 4.1147638574897769],
					[61.425122394881889, 4.1146360566907756],
					[61.425094597988078, 4.1145082706402736],
					[61.425083499180609, 4.1143582846351059],
					[61.425072400254855, 4.1142055987148156],
					[61.425080799960547, 4.1140444102978142],
					[61.425080799893401, 4.1140444084307166],
					[61.425080799826254, 4.1140444065636181],
					[61.4250863003194, 4.11388331751444],
					[61.425108498390692, 4.1137250285065292],
					[61.42513079834135, 4.1135694270687413],
					[61.42513079834135, 4.1135694270687413],
					[61.425155797817951, 4.1133833264246267],
					[61.425180797953885, 4.1132000267751474],
					[61.425180797886753, 4.1132000249080427],
					[61.425180798646878, 4.1132000210336308],
					[61.42518079857971, 4.1132000191665279],
					[61.425200198572028, 4.11299171944209],
					[61.425219599119195, 4.1127861193733315],
					[61.425219598917693, 4.1127861137720236],
					[61.425219600303585, 4.1127861022889824],
					[61.425219600102082, 4.1127860966876568],
					[61.42521690020866, 4.1125943966133462],
					[61.42521410018059, 4.1124055962862052],
					[61.425214099979087, 4.1124055906849009],
					[61.425214098614475, 4.1124055777553679],
					[61.425214098345805, 4.1124055702869597],
					[61.425189098111616, 4.1122388707422237],
					[61.425164097608651, 4.1120721696060452],
					[61.425164097407141, 4.1120721640047497],
					[61.425164094186485, 4.112072149488597],
					[61.42516409219612, 4.1120721441677448],
					[61.4251223918778, 4.1119305441752951],
					[61.425080792162021, 4.1117860447348598],
					[61.42508079010446, 4.1117860375469526],
					[61.425080784200496, 4.1117860234515566],
					[61.425080781248482, 4.1117860164038804],
					[61.425025181045775, 4.1116666156671133],
					[61.425025181045775, 4.1116666156671133],
					[61.425025180017009, 4.1116666120731491],
					[61.425025180017009, 4.1116666120731491],
					[61.42496688036934, 4.1115471111319613],
					[61.424966879407719, 4.1115471094050982],
					[61.424966876590105, 4.1115471060916331],
					[61.424966874734039, 4.1115471045050249],
					[61.424902974878115, 4.1114305042552965],
					[61.424902974810955, 4.1114305023882149],
					[61.424836278587094, 4.1113110082928523],
					[61.424775183356438, 4.1111777185070855],
					[61.424716891043609, 4.1110443370753948],
					[61.424680794745058, 4.1108832564100641],
					[61.424644599261612, 4.1107193752444413],
					[61.424639099814897, 4.1105388950260053],
					[61.42463630003455, 4.1103556069152196],
					[61.424650198638794, 4.1101694164730818],
					[61.424666899420721, 4.1099806177175591],
					[61.424666899420721, 4.1099806177175591],
					[61.424666899353511, 4.1099806158504979],
					[61.424666899353511, 4.1099806158504979],
					[61.424680798893327, 4.1097944154659842],
					[61.42469739944363, 4.1096083188432759],
					[61.42469739937642, 4.1096083169762139],
					[61.424697399309203, 4.1096083151091518],
					[61.424697399309203, 4.1096083151091518],
					[61.424711299381599, 4.1094250170186477],
					[61.424727999397248, 4.109244420776025],
					[61.424744598834266, 4.1090889212208834],
					[61.424761298372104, 4.1089361320702293],
					[61.42478629615988, 4.1088194411336385],
					[61.424811292310402, 4.1087028572786712],
					[61.424850187325461, 4.108600071259672],
					[61.424850187258222, 4.1086000693925886],
					[61.424850188152661, 4.1086000692522662],
					[61.424850188085443, 4.1086000673851961],
					[61.424886282240109, 4.1085000844394051],
					[61.424941871677753, 4.1084057003678476],
					[61.424941872572155, 4.108405700227526],
					[61.424941872572155, 4.108405700227526],
					[61.424994563082329, 4.1083140181484579],
					[61.425072347246918, 4.1082279351418842],
					[61.425150140101628, 4.1081445428584029],
					[61.425239033346983, 4.1080640481187753],
					[61.425330731924333, 4.1079834507068007],
					[61.425419530541134, 4.1079057506593415],
					[61.425505730611583, 4.1078307505164711],
					[61.425505734122041, 4.1078307480879843],
					[61.425505741075725, 4.1078307413638857],
					[61.425505743691758, 4.1078307390757409],
					[61.425591844368327, 4.1077418387281313],
					[61.425677944153691, 4.1076529398890544],
					[61.425677951867335, 4.1076529292902899],
					[61.425677968121761, 4.1076529060853026],
					[61.42567797583542, 4.1076528954865363],
					[61.425744575687389, 4.1075306968291239],
					[61.425744575620143, 4.107530694962005],
					[61.425744577341767, 4.1075306928141559],
					[61.425808476840125, 4.1074083935025323],
					[61.425808481803216, 4.1074083814576205],
					[61.425808490835024, 4.1074083575081524],
					[61.425808494836481, 4.1074083437364735],
					[61.425841894835457, 4.1072611441146964],
					[61.425875194852836, 4.1071139448273124],
					[61.425875197132633, 4.1071139332034345],
					[61.42587519900902, 4.1071139103767749],
					[61.425875200461654, 4.107113900760389],
					[61.425875199721375, 4.1069611025871504],
					[61.425877999861854, 4.1068111038079191],
					[61.425878000420042, 4.1068110943318965],
					[61.425877998025975, 4.1068110778084836],
					[61.425877996795343, 4.1068110686132115],
					[61.425850196626975, 4.1066416673665413],
					[61.425822397225758, 4.1064721686379615],
					[61.425822397091245, 4.1064721649037184],
					[61.425822396062351, 4.106472161309858],
					[61.425822395995098, 4.1064721594427178],
					[61.425783496068632, 4.1062860584469165],
					[61.425744596026775, 4.1060999616589289],
					[61.425708497313437, 4.1059082638409432],
					[61.425675197821093, 4.1057193716289095],
					[61.425652998999823, 4.1055221784246694],
					[61.425630800029971, 4.1053249892477552],
					[61.425630800468063, 4.1051389007657733],
					[61.425630800400789, 4.1051388988986686],
					[61.425630800333529, 4.1051388970315603],
					[61.425630800333529, 4.1051388970315603],
					[61.425627999881733, 4.1049556141410912],
					[61.425650197680426, 4.1048111308066302],
					[61.42567239531126, 4.1046667467915858],
					[61.425708489725672, 4.1045556629325644],
					[61.425744585712714, 4.1044444752848639],
					[61.425791879333019, 4.1043500899266441],
					[61.425791879265759, 4.1043500880595296],
					[61.425839073758482, 4.1042556991931622],
					[61.425894568055419, 4.1041695077922746],
					[61.425950168160199, 4.1040834083641595],
					[61.426000166788, 4.1040057103104806],
					[61.426052965447418, 4.1039279121419785],
					[61.426052967168978, 4.1039279099939288],
					[61.426052970544767, 4.1039279038306873],
					[61.426052973160751, 4.1039279015421766],
					[61.426100172316175, 4.1038473021751081],
					[61.426147370744602, 4.103769504203493],
					[61.426147373293304, 4.1037695000478278],
					[61.426147375842, 4.1037694958921618],
					[61.426147378390645, 4.1037694917364851],
					[61.42620017721962, 4.1036667924714498],
					[61.426255776112534, 4.1035639948271241],
					[61.426255777834079, 4.1035639926790406],
					[61.426255781142594, 4.1035639846485781],
					[61.426255782796851, 4.1035639806333561],
					[61.426319582320893, 4.1034194824577366],
					[61.426386280960166, 4.1032778856811163],
					[61.426386282614402, 4.1032778816658446],
					[61.426386286750038, 4.1032778716276876],
					[61.426386288404267, 4.1032778676124346],
					[61.42644738836924, 4.1031056695602688],
					[61.426511286565166, 4.1029361708271352],
					[61.426511289113797, 4.1029361666713573],
					[61.426511290633428, 4.1029361589217466],
					[61.42651129228765, 4.1029361549064607],
					[61.426555792086077, 4.1027806553835298],
					[61.426600191826438, 4.1026250546919583],
					[61.426600192586271, 4.1026250508171387],
					[61.4266001958947, 4.1026250427864923],
					[61.426600195692814, 4.1026250371850184],
					[61.4266251961145, 4.1024944379760493],
					[61.426625196047198, 4.1024944361088904],
					[61.426625197008946, 4.1024944378355412],
					[61.426650197045035, 4.1023611364000736],
					[61.426650196977725, 4.1023611345329138],
					[61.426650197737494, 4.1023611306580747],
					[61.426650197670206, 4.1023611287909132],
					[61.426675197658255, 4.1021889297890795],
					[61.42670299666343, 4.1020167320095915],
					[61.426702998452249, 4.1020167317285559],
					[61.42670299838494, 4.1020167298613934],
					[61.42670299838494, 4.1020167298613934],
					[61.426733498351346, 4.1018083307719566],
					[61.42676689773883, 4.1015972317734786],
					[61.426794596991996, 4.1014250316460688],
					[61.426822397007179, 4.101255631976743],
					[61.426822397901603, 4.1012556318362003],
					[61.426822397766962, 4.1012556281018666],
					[61.426822397699631, 4.1012556262346997],
					[61.426844597794322, 4.1010917273408891],
					[61.426866897717396, 4.1009306272531294],
					[61.426894597934705, 4.1007250261283144],
					[61.426922397857609, 4.1005194295881191],
					[61.426955798033056, 4.100308331327799],
					[61.426989095735955, 4.1001000371564151],
					[61.427030794709836, 4.0999167444935276],
					[61.427030794642469, 4.0999167426263536],
					[61.427030796431332, 4.0999167423451865],
					[61.427069595312354, 4.0997361451811152],
					[61.427114093609838, 4.0995611487321941],
					[61.427158494406619, 4.0993861483042018],
					[61.427202993964102, 4.0992111487240841],
					[61.427202993896749, 4.0992111468569004],
					[61.427202994791173, 4.0992111467162946],
					[61.427244595064188, 4.0990361460949103],
					[61.42724459499685, 4.099036144227723],
					[61.427244594929483, 4.0990361423605401],
					[61.427244594929483, 4.0990361423605401],
					[61.427280795250972, 4.0988694412137088],
					[61.427280796145411, 4.0988694410730924],
					[61.427280796078058, 4.098869439205906],
					[61.427280796078058, 4.098869439205906],
					[61.427314096302794, 4.0987028386929421],
					[61.427314096235413, 4.0987028368257548],
					[61.427314097062457, 4.0987028348179511],
					[61.427314096927745, 4.0987028310835729],
					[61.427339096736219, 4.0985472320842193],
					[61.427364097250894, 4.0983917322260117],
					[61.427364098077945, 4.0983917302181947],
					[61.427364098010578, 4.098391728351003],
					[61.427364097943212, 4.098391726483813],
					[61.427386298312378, 4.0982278278878184],
					[61.427386298245032, 4.0982278260206071],
					[61.42738629907209, 4.0982278240127812],
					[61.42740579893082, 4.0980639235280005],
					[61.427405798863454, 4.0980639216608079],
					[61.42740579879608, 4.0980639197936153],
					[61.42740579879608, 4.0980639197936153],
					[61.427422398925067, 4.0978861193447669],
					[61.427439098705229, 4.0977083199100974],
					[61.427439098570488, 4.0977083161757113],
					[61.427439100157216, 4.0977083102928153],
					[61.427439100089863, 4.0977083084256192],
					[61.427447400326002, 4.0975222097291235],
					[61.427455800057444, 4.0973361084027493],
					[61.42745579992269, 4.0973361046683436],
					[61.427455799653181, 4.0973360971995518],
					[61.427455800412815, 4.0973360933245111],
					[61.427450200292178, 4.0971666920446408],
					[61.427444600034939, 4.0969971931785008],
					[61.427444599832796, 4.0969971875769051],
					[61.427444597639685, 4.0969971766550701],
					[61.427444598264593, 4.0969971690456148],
					[61.427425197990956, 4.0968693719360054],
					[61.427408497834364, 4.0967416741965339],
					[61.427408496670417, 4.0967416668684518],
					[61.427408494342536, 4.0967416522122519],
					[61.427408492284179, 4.0967416450248253],
					[61.427377992727465, 4.0966360468294276],
					[61.427350192738864, 4.0965332483816539],
					[61.427350190680514, 4.0965332411942539],
					[61.427350187660387, 4.0965332322803443],
					[61.427350185669411, 4.0965332269601475],
					[61.427308486401934, 4.0964277270430056],
					[61.427266885985297, 4.0963221273477712],
					[61.427266884061723, 4.0963221238947627],
					[61.427266881041518, 4.0963221149809232],
					[61.427266879050542, 4.0963221096607363],
					[61.427214080099198, 4.0962166136554483],
					[61.427164081652421, 4.0961082157087345],
					[61.42716408075804, 4.0961082158494202],
					[61.427164079728811, 4.0961082122557526],
					[61.427164078767035, 4.0961082105292679],
					[61.427108479733633, 4.0959971129950175],
					[61.427055784024304, 4.095883221305284],
					[61.427008486143443, 4.0957638258649833],
					[61.426961289976148, 4.0956443359051935],
					[61.426930793046942, 4.0955360451811424],
					[61.426900194997671, 4.0954249558011782],
					[61.426883497774057, 4.0953305680916605],
					[61.426869599328363, 4.0952332802871778],
					[61.426864100263039, 4.0951138936707192],
					[61.426861300241896, 4.0949944016516664],
					[61.426866900054144, 4.0948361079198365],
					[61.426872399661001, 4.0946778092220022],
					[61.426883499611819, 4.0945139141733433],
					[61.426883499544417, 4.0945139123061907],
					[61.426894598883564, 4.0943472155919309],
					[61.426908498772548, 4.0941861169294302],
					[61.426922398821198, 4.0940278178249185],
					[61.426936298800243, 4.0938639171915838],
					[61.426950198580101, 4.0937000164210939],
					[61.426950198580101, 4.0937000164210939],
					[61.426950200301498, 4.0937000142724225],
					[61.426950200234067, 4.0937000124052698],
					[61.426961299198801, 4.0935250133759906],
					[61.426975199389219, 4.0933528162255746],
					[61.426975199321781, 4.0933528143584219],
					[61.426975200148739, 4.0933528123505027],
					[61.426975200148739, 4.0933528123505027],
					[61.426986300321431, 4.0931639116805512],
					[61.426986300321431, 4.0931639116805512],
					[61.426986300254001, 4.0931639098133976],
					[61.426986300254001, 4.0931639098133976],
					[61.426994600038832, 4.092975008485161],
					[61.427002999581312, 4.0927806082294493],
					[61.427002999581312, 4.0927806082294493],
					[61.427003000408256, 4.0927806062215151],
					[61.427008500114866, 4.0925889062403273],
					[61.427008500047414, 4.0925889043731756],
					[61.427008499912532, 4.0925889006388498],
					[61.427008499912532, 4.0925889006388498],
					[61.427008499758237, 4.092402800208979],
					[61.427008500243588, 4.0922166996501392],
					[61.427008500176136, 4.0922166977829884],
					[61.427008500108698, 4.092216695915833],
					[61.427008500041254, 4.0922166940486813],
					[61.427003000093826, 4.0920443935025492],
					[61.427003000093826, 4.0920443935025492],
					[61.426997400107815, 4.0918721932424464],
					[61.426997400040349, 4.0918721913752965],
					[61.426997398943591, 4.0918721859146547],
					[61.426997398808687, 4.0918721821803361],
					[61.42698349936515, 4.0917138847353636],
					[61.426972399793996, 4.0915555859811557],
					[61.426972398764718, 4.0915555823876746],
					[61.426972397533021, 4.0915555731927364],
					[61.426972398359979, 4.0915555711847498],
					[61.426952997718658, 4.091424971610393],
					[61.426952997651206, 4.0914249697432439],
					[61.426933498370694, 4.0912971705816696],
					[61.426933497273929, 4.091297165121059],
					[61.426933496177142, 4.0912971596604324],
					[61.426933495080348, 4.0912971541998262],
					[61.426908494621962, 4.0911888544908752],
					[61.426908494621962, 4.0911888544908752],
					[61.42690849359267, 4.0911888508973959],
					[61.426908492630794, 4.0911888491710844],
					[61.426880793093062, 4.0910832486007145],
					[61.426880793093062, 4.0910832486007145],
					[61.426880792063734, 4.0910832450072494],
					[61.426880791996282, 4.0910832431401065],
					[61.426836292148785, 4.0909332426607037],
					[61.426836292148785, 4.0909332426607037],
					[61.42683629118693, 4.090933240934401],
					[61.426836291119457, 4.0909332390672652],
					[61.426789091903828, 4.0907832434447995],
					[61.426730793806733, 4.0905638496222663],
					[61.426675196698469, 4.09034436170184],
					[61.426647398405116, 4.0901332746108148],
					[61.426647398337636, 4.0901332727436897],
					[61.426647398337636, 4.0901332727436897],
					[61.426616901039829, 4.0899193926056396],
					[61.426628000329615, 4.0897556130981005],
					[61.426628000329615, 4.0897556130981005],
					[61.426628000262134, 4.0897556112309781],
					[61.426628000194654, 4.0897556093638538],
					[61.426636300280038, 4.0895917110408284],
					[61.426647398566331, 4.0894278150075527],
					[61.426661299390048, 4.0892639167810989],
					[61.42666130014949, 4.0892639129059472],
					[61.42666129974458, 4.0892639017031884],
					[61.426661299609599, 4.0892638979689417],
					[61.426658500077686, 4.0890666975031129],
					[61.426655800078748, 4.0888693968624228],
					[61.426655800078748, 4.0888693968624228],
					[61.426655799943759, 4.0888693931281779],
					[61.426655799876258, 4.0888693912610394],
					[61.42664740000243, 4.08866389270682],
					[61.4266391002676, 4.0884555993722413],
					[61.426647399715847, 4.0882639097558107],
					[61.426647399648353, 4.0882639078886891],
					[61.426647399648353, 4.0882639078886891],
					[61.426647399580865, 4.0882639060215693],
					[61.426653000380156, 4.0880722189658485],
					[61.426680797872812, 4.0878972316360533],
					[61.426680797805297, 4.0878972297689335],
					[61.426680797737802, 4.0878972279018102],
					[61.426705797430913, 4.0877222360468215],
					[61.426744595036027, 4.0875472422717332],
					[61.426744595862885, 4.0875472402636808],
					[61.42678079461156, 4.0873722461986226],
					[61.426830793747541, 4.0871806519263991],
					[61.426830793680033, 4.0871806500592704],
					[61.426830793612531, 4.0871806481921249],
					[61.426877990766343, 4.0869889596769315],
					[61.426941885556978, 4.0868250721591286],
					[61.42694188734577, 4.086825071877219],
					[61.426941887278247, 4.0868250700100859],
					[61.426941888172628, 4.0868250698691329],
					[61.427002981444502, 4.0866583877632587],
					[61.42706407062763, 4.0865584045628349],
					[61.42712516909836, 4.086458406348072],
					[61.427180767901547, 4.0863723083164718],
					[61.427236267655566, 4.0862862086785112],
					[61.427236271098074, 4.0862862043803103],
					[61.427236276126784, 4.0862861941987125],
					[61.42723627867494, 4.0862861900414922],
					[61.42729187803905, 4.0861750902587701],
					[61.427350177985097, 4.0860611908406277],
					[61.427350179638822, 4.0860611868243399],
					[61.42735018384068, 4.0860611786508194],
					[61.427350186388814, 4.0860611744935502],
					[61.427400184588471, 4.0859361765645357],
					[61.427452983680169, 4.0858111779865469],
					[61.427452986228289, 4.085811173829244],
					[61.427452990362589, 4.0858111637885033],
					[61.427452991948769, 4.0858111579050274],
					[61.427494591409982, 4.0856722596653201],
					[61.427539090360533, 4.085536161960067],
					[61.427539091119883, 4.0855361580847189],
					[61.427539092773586, 4.0855361540683983],
					[61.427539092638511, 4.0855361503340681],
					[61.427577992605549, 4.0853889506558669],
					[61.427577994394333, 4.0853889503738676],
					[61.427577994326789, 4.0853889485067016],
					[61.427577994259252, 4.0853889466395339],
					[61.42761409437469, 4.0852389487398142],
					[61.427652994437146, 4.0850889503240939],
					[61.427652994369609, 4.0850889484569048],
					[61.427652994302065, 4.0850889465897335],
					[61.427652995196432, 4.0850889464487281],
					[61.427689092847046, 4.084936153983227],
					[61.427736289981731, 4.084791762637618],
					[61.427736289914172, 4.0847917607704423],
					[61.427783488890029, 4.0846444655485348],
					[61.427833486774261, 4.0845139726890078],
					[61.42783348670671, 4.0845139708218277],
					[61.42783348842795, 4.084513968672594],
					[61.427833488360392, 4.0845139668054147],
					[61.427880787745885, 4.0843806672574692],
					[61.427880790293969, 4.0843806631000099],
					[61.427880791744961, 4.08438065348202],
					[61.427880794293046, 4.0843806493245607],
					[61.427916893761527, 4.0842417505527617],
					[61.427916893693968, 4.084241748685578],
					[61.427952994248407, 4.084100049539078],
					[61.4279529956994, 4.0841000399210508],
					[61.427952999428193, 4.0841000186767751],
					[61.427952999984804, 4.0841000091997861],
					[61.42796129990699, 4.0839194094964677],
					[61.427969599591542, 4.0837389092333529],
					[61.427969600215661, 4.083738901623545],
					[61.427969598780805, 4.0837388868270805],
					[61.427969599404911, 4.0837388792172735],
					[61.427947399011288, 4.0835332787132694],
					[61.427925199275386, 4.0833277797668295],
					[61.427925199140248, 4.0833277760324478],
					[61.427925198043177, 4.0833277705719571],
					[61.427925197081194, 4.0833277688458356],
					[61.427894597016042, 4.0831416674294987],
					[61.427864098712035, 4.0829555765881773],
					[61.427850199338877, 4.0827860856523319],
					[61.427839100728093, 4.0826194017126296],
					[61.427855799066386, 4.0824278176379121],
					[61.427872397934429, 4.0822361269592955],
					[61.427911295952079, 4.0820278371036789],
					[61.427952995406976, 4.0818194427302288],
					[61.427994593606499, 4.0816472461340538],
					[61.428036294016231, 4.0814750460695048],
					[61.428036294910626, 4.0814750459283893],
					[61.428069595179572, 4.0813333451231895],
					[61.428102994428826, 4.0811917485618299],
					[61.428144593672805, 4.0810278501728217],
					[61.428186294050761, 4.0808667502776084],
					[61.428186293983167, 4.0808667484104166],
					[61.428186293915552, 4.0808667465432267],
					[61.428186294809962, 4.0808667464020916],
					[61.428230794003817, 4.0806806475486193],
					[61.428277993925072, 4.0804972504555037],
					[61.428277993789855, 4.0804972467210954],
					[61.428277996270168, 4.0804972406960793],
					[61.42827799620256, 4.0804972388288849],
					[61.428314096265474, 4.0803111385879909],
					[61.428314096197866, 4.0803111367207938],
					[61.428314097092226, 4.0803111365796489],
					[61.428314097024625, 4.0803111347124332],
					[61.428347396716738, 4.0801250346738369],
					[61.428347398235061, 4.0801250269227154],
					[61.428347400377341, 4.0801250115616394],
					[61.42834740003925, 4.080125002225631],
					[61.428350199674661, 4.0799361045465368],
					[61.428355799805693, 4.0797500059964626],
					[61.428355799332365, 4.0797499929260406],
					[61.428355796596932, 4.0797499670675634],
					[61.428355795229258, 4.0797499541383022],
					[61.428311295151637, 4.0795610557528095],
					[61.428269594927812, 4.0793721572251593],
					[61.428269592733308, 4.0793721463043422],
					[61.428269585796379, 4.0793721286206219],
					[61.428269582707486, 4.0793721178409825],
					[61.428186282911618, 4.0791860193984952],
					[61.42810578387575, 4.0789999210870898],
					[61.428105783808107, 4.0789999192198927],
					[61.428105782913718, 4.0789999193610793],
					[61.428030782820208, 4.0788277194698956],
					[61.428030782820208, 4.0788277194698956],
					[61.427952991357522, 4.0786527386510079],
					[61.427919596023806, 4.0784916595458069],
					[61.427886302447476, 4.0783305891098474],
					[61.427900198614942, 4.0781861181235186],
					[61.427900198614942, 4.0781861181235186],
					[61.427900198547292, 4.0781861162563366],
					[61.427900199441694, 4.078186116115126],
					[61.427911296921337, 4.0780444454401286],
					[61.42796688609377, 4.0779028724389681],
					[61.427966886920537, 4.0779028704305773],
					[61.428019583307389, 4.0777639826823373],
					[61.428091875837652, 4.0776278947738946],
					[61.428164075909557, 4.0774944967691891],
					[61.428225175286805, 4.0773833968251045],
					[61.428286274439898, 4.0772750982519854],
					[61.428286276920105, 4.077275092226694],
					[61.428286282842492, 4.0772750819020942],
					[61.428286285322685, 4.0772750758768233],
					[61.428341885042023, 4.0771389776568796],
					[61.428400184015992, 4.0770028789318955],
					[61.428400184842694, 4.0770028769234612],
					[61.428400187322893, 4.0770028708981272],
					[61.42840018814961, 4.0770028688896911],
					[61.428472388414981, 4.0768056687059477],
					[61.428472388414981, 4.0768056687059477],
					[61.428544586086026, 4.0766056736774976],
					[61.428622384260741, 4.076425078131245],
					[61.428622384260741, 4.076425078131245],
					[61.428697372809566, 4.0762473047287271],
					[61.428769555182029, 4.076158426743353],
					[61.428769556076411, 4.0761584266020803],
					[61.428769556903113, 4.076158424593598],
					[61.428841810018859, 4.0760668834002196],
					[61.42891943465218, 4.0760529975766229],
					[61.428919437335331, 4.0760529971527975],
					[61.42891944001849, 4.0760529967289711],
					[61.428919441807246, 4.0760529964464203],
					[61.428997170070453, 4.0760363111002267],
					[61.429074906311904, 4.0760779752215512],
					[61.429074907273957, 4.0760779769475244],
					[61.429074909130385, 4.0760779785322061],
					[61.429074910919141, 4.0760779782496552],
					[61.429152663961041, 4.0761168549482409],
					[61.429216533294202, 4.0762112092819525],
					[61.429277624323326, 4.0763056967264362],
					[61.429330416648142, 4.0764222803238477],
					[61.429380415579899, 4.0765389787443178],
					[61.429380417436349, 4.0765389803290359],
					[61.429380418398381, 4.0765389820550233],
					[61.429380417571679, 4.0765389840635349],
					[61.429433117841398, 4.0766528843452532],
					[61.429433118735787, 4.0766528842039929],
					[61.429433118735787, 4.0766528842039929],
					[61.429433118803445, 4.0766528860712468],
					[61.429485918983602, 4.076763985949075],
					[61.429485928536359, 4.0767640013418278],
					[61.429485952181402, 4.0767640332883408],
					[61.429485964349631, 4.0767640463900641],
					[61.429558164373411, 4.0768307471188363],
					[61.429630464176491, 4.0768973478786634],
					[61.429630490857782, 4.0768973643222344],
					[61.429630549383681, 4.0768973907600499],
					[61.429630581160616, 4.0768973988870094],
					[61.429716680666388, 4.0769057990662549],
					[61.429716681560784, 4.0769057989249964],
					[61.429716686032663, 4.0769057982187258],
					[61.429716686994716, 4.0769057999447558],
					[61.429802786678586, 4.0769113005524256],
					[61.429802787572932, 4.0769113004111741],
					[61.429894387662642, 4.0769168994753899],
					[61.429894388557038, 4.0769168993341296],
					[61.429894392202208, 4.0769169006363901],
					[61.429894393990978, 4.0769169003538783],
					[61.429983264251007, 4.0769195990506226],
					[61.430072134973202, 4.076950189461626],
					[61.430161034783403, 4.076980788586404],
					[61.430161036639817, 4.0769807901711932],
					[61.430161041179403, 4.0769807913322103],
					[61.430161043035817, 4.0769807929170137],
					[61.43024434293433, 4.0770057919268945],
					[61.43024434382874, 4.0770057917856359],
					[61.430330543716472, 4.0770307911362309],
					[61.430330558229535, 4.077030794478075],
					[61.430330586293614, 4.077030799435728],
					[61.430330599776994, 4.0770307991842074],
					[61.430397199596321, 4.077030799318849],
					[61.430466700302638, 4.0770307995223707],
					[61.430466708419694, 4.0770308001183881],
					[61.430466721903095, 4.0770307998668791],
					[61.430466729952521, 4.0770307985955743],
					[61.430522229588519, 4.0770223974587498],
					[61.43052223227167, 4.0770223970349786],
					[61.4305222349548, 4.0770223966112118],
					[61.430522236743556, 4.0770223963286991],
					[61.430580591090056, 4.0770113056982469],
					[61.430638844557933, 4.0770279917821286],
					[61.43063884820311, 4.0770279930844291],
					[61.430638852742689, 4.0770279942454879],
					[61.430638856387858, 4.0770279955477875],
					[61.430699902169003, 4.0770418837615283],
					[61.430758161769262, 4.0770973445243195],
					[61.430819243753653, 4.0771557289116904],
					[61.4308776288673, 4.0772473035547536],
					[61.4309331223333, 4.0773417916749652],
					[61.430994216207438, 4.0774806788769187],
					[61.431052613359519, 4.0776194703790383],
					[61.431110909547762, 4.0777944625796687],
					[61.431169209022627, 4.0779722588092975],
					[61.431224808022165, 4.0781611569271936],
					[61.43128040766053, 4.0783500555848544],
					[61.431280408690228, 4.0783500591783648],
					[61.431280409719861, 4.0783500627718832],
					[61.4312804106819, 4.0783500644980446],
					[61.431338711069365, 4.0785194638309914],
					[61.431394210410303, 4.0786861621559058],
					[61.431394213364023, 4.0786861692017853],
					[61.431394218309393, 4.0786861815673658],
					[61.431394220368688, 4.0786861887544648],
					[61.431449820324453, 4.0788000888325566],
					[61.431505420121269, 4.0789139874491092],
					[61.431505427749734, 4.0789139993911538],
					[61.431505443901088, 4.0789140231340451],
					[61.431505453385988, 4.0789140366610495],
					[61.431572053074085, 4.0789862355775579],
					[61.431638752611988, 4.0790584359303432],
					[61.431638759143198, 4.0790584424114797],
					[61.431638772137966, 4.079058453506395],
					[61.431638778669182, 4.0790584599875297],
					[61.43171927918727, 4.0791195590256519],
					[61.431719280149295, 4.0791195607518436],
					[61.431719281043655, 4.0791195606106436],
					[61.431719281938051, 4.0791195604694384],
					[61.431802675075055, 4.0791807552901034],
					[61.431872068391982, 4.0792418506011021],
					[61.431872069286342, 4.079241850459904],
					[61.431872070248382, 4.079241852186124],
					[61.431872071142763, 4.0792418520449232],
					[61.431944247836732, 4.0793029326447661],
					[61.431994232237706, 4.0793807087886638],
					[61.431994232237706, 4.0793807087886638],
					[61.431994233132052, 4.07938070864747],
					[61.431994233199696, 4.0793807105148998],
					[61.432047019280688, 4.0794611885348644],
					[61.432085910612315, 4.0795722647086521],
					[61.432122007969326, 4.0796806570511013],
					[61.432158105970842, 4.0798194503857337],
					[61.432194205899421, 4.0799583507722179],
					[61.432194207823414, 4.0799583542247095],
					[61.432194209882695, 4.0799583614120687],
					[61.432194210979901, 4.0799583668731749],
					[61.432241510634618, 4.0800944667289532],
					[61.432288711172419, 4.0802306652617064],
					[61.432288714126045, 4.080230672307934],
					[61.432288720100871, 4.0802306882678314],
					[61.432288723948858, 4.0802306951728706],
					[61.432347024165473, 4.0803389950078346],
					[61.432347024233081, 4.0803389968752741],
					[61.432347025127491, 4.0803389967340973],
					[61.432347026089452, 4.0803389984603671],
					[61.432408126334415, 4.0804472980879547],
					[61.432408128258402, 4.0804473015405014],
					[61.432408132106389, 4.0804473084456099],
					[61.432408134992386, 4.0804473136244432],
					[61.432491534986035, 4.0805695135230806],
					[61.432491535880395, 4.080569513381918],
					[61.43257483340242, 4.080689010558177],
					[61.43267203024233, 4.0808473048663041],
					[61.432672030309945, 4.0808473067337614],
					[61.432672031204348, 4.0808473065926014],
					[61.432672032166323, 4.0808473083189183],
					[61.432772030246198, 4.0810029058332491],
					[61.432855428348667, 4.0811445006723384],
					[61.432938727595861, 4.0812862019950584],
					[61.432938730414214, 4.0812862053065819],
					[61.432938734194558, 4.0812862103444152],
					[61.432938736186131, 4.0812862156645657],
					[61.433016536153644, 4.0813973133724959],
					[61.433091536275633, 4.081505713181957],
					[61.43309153916158, 4.0815057183609884],
					[61.433091546722267, 4.0815057284367722],
					[61.433091551396977, 4.0815057333335334],
					[61.433180450336074, 4.0816057311106864],
					[61.433269249101699, 4.081708431025592],
					[61.433269250063674, 4.0817084327519702],
					[61.433269252814434, 4.0817084341960523],
					[61.433269253844045, 4.0817084377899029],
					[61.433358154180638, 4.0818029371668567],
					[61.433358155142628, 4.0818029388932207],
					[61.433358156037023, 4.0818029387520838],
					[61.433358156931384, 4.0818029386109513],
					[61.433449857314237, 4.0818973384898438],
					[61.433449860132562, 4.0818973418014597],
					[61.433449865769269, 4.0818973484247039],
					[61.433449868587594, 4.0818973517363402],
					[61.433535968990554, 4.0819723514389601],
					[61.43353596988495, 4.081972351297825],
					[61.433535972703275, 4.0819723546094586],
					[61.433535973597678, 4.0819723544683253],
					[61.433624874431551, 4.082044555546732],
					[61.433624887358484, 4.0820445647760364],
					[61.433624914866044, 4.0820445792173334],
					[61.433624930408563, 4.0820445861557237],
					[61.433713830125185, 4.0820779862099972],
					[61.433713831087154, 4.082077987936394],
					[61.433713833837913, 4.0820779893805259],
					[61.433713834732337, 4.0820779892393899],
					[61.433802734727678, 4.0821084894888013],
					[61.433802770014339, 4.0821084951899209],
					[61.433802839220121, 4.0821084936605656],
					[61.433802873139236, 4.0821084864300747],
					[61.433894472771001, 4.0820723860817392],
					[61.433894474559786, 4.0820723857994761],
					[61.433894477242951, 4.0820723853760779],
					[61.433894478069746, 4.0820723833674126],
					[61.433986177862778, 4.0820334834805765],
					[61.433986203394454, 4.0820334681824475],
					[61.433986247926775, 4.0820334311039339],
					[61.433986267821808, 4.0820334091824169],
					[61.434064067636896, 4.0819140084417818],
					[61.434141868297274, 4.0817945092828749],
					[61.43414187497919, 4.0817944950808851],
					[61.434141886621902, 4.0817944688267094],
					[61.434141893303824, 4.0817944546247151],
					[61.434189093068518, 4.0816250545007309],
					[61.434189093000917, 4.0816250526331803],
					[61.434236292892891, 4.0814528533921575],
					[61.43423629523808, 4.0814528436309097],
					[61.434236298139638, 4.0814528243907269],
					[61.434236298696042, 4.0814528149117848],
					[61.434250198682172, 4.0812667146416324],
					[61.434264098748102, 4.0810778156041687],
					[61.434264100401698, 4.0810778115867281],
					[61.434264100131273, 4.0810778041164921],
					[61.434264099996078, 4.0810778003813821],
					[61.434264099557353, 4.0808916999590883],
					[61.43426410045172, 4.0808916998179221],
					[61.434264100384091, 4.0808916979503724],
					[61.434264100316497, 4.0808916960827979],
					[61.43426129984131, 4.0807027976557633],
					[61.434258499673817, 4.0805166977455922],
					[61.434258499606194, 4.0805166958780399],
					[61.434258499606194, 4.0805166958780399],
					[61.434258500432975, 4.0805166938693072],
					[61.434253000174934, 4.0803277990266826],
					[61.434255799917565, 4.0801417035334984],
					[61.434258499765455, 4.0799528064043811],
					[61.434266900107453, 4.0797667093672727],
					[61.434275200308477, 4.0795806111074535],
					[61.434289099052314, 4.0793917148695407],
					[61.434302998955403, 4.0792056149398945],
					[61.434302998955403, 4.0792056149398945],
					[61.434302999782169, 4.0792056129311245],
					[61.434302999782169, 4.0792056129311245],
					[61.434314100139936, 4.0790194120404344],
					[61.434325199651084, 4.0788306116444684],
					[61.434325200410171, 4.0788306077681433],
					[61.434325200139646, 4.0788306002979136],
					[61.434325200004366, 4.078830596562792],
					[61.434322399948478, 4.0786443965899579],
					[61.434319599934973, 4.0784555971375633],
					[61.4343195997997, 4.0784555934024596],
					[61.434319598567136, 4.0784555842059245],
					[61.434319599326201, 4.0784555803295888],
					[61.434300198789899, 4.0782666809160082],
					[61.434300198722262, 4.0782666790484585],
					[61.434280798545828, 4.0780805783519103],
					[61.434280798410555, 4.0780805746168136],
					[61.434280796148279, 4.0780805618264591],
					[61.434280795050938, 4.07808055636505],
					[61.434239096329776, 4.0778916573231934],
					[61.434200195737759, 4.0777027600810349],
					[61.434200193678414, 4.0777027528933836],
					[61.43420019141606, 4.0777027401030796],
					[61.434200189424345, 4.0777027347829717],
					[61.434133488782912, 4.0775110352747497],
					[61.434069588689567, 4.0773221360116638],
					[61.43406958765987, 4.0773221324178683],
					[61.43406958470613, 4.0773221253715217],
					[61.434069583676425, 4.0773221217777262],
					[61.433989083907669, 4.077133221419607],
					[61.433989083907669, 4.077133221419607],
					[61.433989082945637, 4.0771332196933532],
					[61.433905783009791, 4.0769416203443454],
					[61.433905782942126, 4.0769416184768081],
					[61.433905782047738, 4.076941618618088],
					[61.43390578198008, 4.0769416167505641],
					[61.43381958153649, 4.0767527172763547],
					[61.433733483653093, 4.0765610209870422],
					[61.433658484706413, 4.076377723977437],
					[61.433583489293099, 4.0761943333468738],
					[61.433533491188477, 4.0760305411258848],
					[61.433483493844776, 4.0758666516190987],
					[61.433450196366792, 4.0757027610164354],
					[61.433419598020919, 4.0755388687351415],
					[61.433397398018421, 4.0753610752859961],
					[61.433397397950749, 4.0753610734185086],
					[61.43337239839466, 4.0751805728563806],
					[61.43337239839466, 4.0751805728563806],
					[61.433372398326973, 4.075180570988894],
					[61.43337239825928, 4.0751805691213949],
					[61.43334739772444, 4.0750138717368998],
					[61.433325198196236, 4.0748443748463075],
					[61.433325198128529, 4.0748443729788084],
					[61.433325198060864, 4.0748443711113271],
					[61.433325198060864, 4.0748443711113271],
					[61.433300198093853, 4.0746749714112092],
					[61.433277999256134, 4.0745055800393759],
					[61.433266899810071, 4.0743221871090141],
					[61.433255801370038, 4.0741417071767039],
					[61.433277997682666, 4.073980629212774],
					[61.433302993842815, 4.0738222525307322],
					[61.433361286230358, 4.0736722745328988],
					[61.433422382615447, 4.0735222828281969],
					[61.43350017928347, 4.073363987858464],
					[61.43357797837362, 4.0732056898069642],
					[61.433647376681463, 4.0730722941923352],
					[61.433719571591695, 4.0729390024035483],
					[61.433794566578158, 4.0728251106107374],
					[61.433869562289459, 4.0727112162573569],
					[61.433964056723063, 4.0725918231334619],
					[61.43405575173179, 4.0724751313796457],
					[61.434144545714489, 4.072380737710291],
					[61.434233433373208, 4.0722862497857149],
					[61.434319516934778, 4.0722223617857027],
					[61.434405703704094, 4.0721612707177783],
					[61.434502889973849, 4.0721112779091939],
					[61.434602877285002, 4.0720612851286928],
					[61.434686162559743, 4.0720334895701615],
					[61.434686165242866, 4.0720334891458894],
					[61.434686167031622, 4.0720334888630418],
					[61.43468616882037, 4.0720334885801952],
					[61.434769448882101, 4.0720029946084759],
					[61.434847227620175, 4.0719918977088092],
					[61.434847230303255, 4.0719918972845397],
					[61.434847233880753, 4.071991896718834],
					[61.434847235669494, 4.071991896435982],
					[61.4349222340606, 4.0719779972114676],
					[61.435011130660264, 4.0719640977782134],
					[61.435100030834391, 4.0719501976882713],
					[61.435100036200637, 4.0719501968396994],
					[61.435100045971005, 4.0719501934164084],
					[61.43510005223164, 4.0719501924264048],
					[61.435183350661184, 4.0719279931870007],
					[61.435269450148894, 4.0719057941549233],
					[61.43526945813052, 4.0719057910144558],
					[61.435269474161522, 4.0719057866011106],
					[61.435269481248774, 4.0719057836020554],
					[61.435344480319195, 4.0718723832374941],
					[61.435422278733959, 4.0718390847328259],
					[61.435422288436627, 4.0718390794418395],
					[61.435422306053134, 4.0718390691427766],
					[61.435422314861427, 4.0718390639932238],
					[61.43550571534464, 4.0717807636918444],
					[61.435505716238985, 4.0717807635504117],
					[61.435505717065638, 4.0717807615413539],
					[61.435505717959984, 4.0717807613999231],
					[61.435589017764904, 4.0717195615379413],
					[61.435589023890017, 4.0717195568126474],
					[61.435589034351587, 4.071719547644955],
					[61.435589038688008, 4.071719543202529],
					[61.435680739124408, 4.0716307441874955],
					[61.435680739951032, 4.071630742178443],
					[61.43568074084542, 4.0716307420369997],
					[61.435680740777684, 4.0716307401693861],
					[61.435772341390731, 4.0715390412250345],
					[61.435772345727138, 4.0715390367825766],
					[61.435772355226568, 4.0715390258885789],
					[61.435772358668586, 4.0715390215875482],
					[61.435861257985927, 4.0714223222217729],
					[61.435952957444186, 4.0713057240223023],
					[61.435952959165199, 4.0713057218717701],
					[61.435952960818462, 4.0713057178536092],
					[61.43595296343387, 4.0713057155616204],
					[61.436036263160425, 4.071189015746552],
					[61.436036263987091, 4.0711890137374418],
					[61.436036264881423, 4.0711890135959905],
					[61.436116865111664, 4.0710723137179077],
					[61.436116865938317, 4.0710723117088063],
					[61.436116869312585, 4.0710723055400377],
					[61.436116871033569, 4.0710723033894842],
					[61.436180770693248, 4.0709668041095526],
					[61.436180771587622, 4.0709668039680906],
					[61.436180773308607, 4.0709668018175265],
					[61.436180773240864, 4.0709667999498853],
					[61.436241872632671, 4.0708612003044173],
					[61.436241877660173, 4.0708611901173812],
					[61.43624188606195, 4.0708611737615215],
					[61.43624188930071, 4.0708611638574164],
					[61.436283488830519, 4.0707389648316097],
					[61.436283489724907, 4.0707389646901397],
					[61.436283489657157, 4.0707389628224915],
					[61.436325189904068, 4.0706139637813541],
					[61.436325192383961, 4.0706139577539835],
					[61.436325194592854, 4.0706139442559834],
					[61.436325197004948, 4.0706139363609699],
					[61.436352995969955, 4.0704667378152841],
					[61.436380796435259, 4.0703222379715598],
					[61.436380797261876, 4.0703222359624283],
					[61.436380797952985, 4.0703222302179904],
					[61.436380797885235, 4.0703222283503226],
					[61.436400198417566, 4.0701889298083156],
					[61.436419596975504, 4.0700583325008308],
					[61.436447397376263, 4.0699028340447994],
					[61.436447397376263, 4.0699028340447994],
					[61.436447397308498, 4.0699028321771458],
					[61.436447397308498, 4.0699028321771458],
					[61.436472395661269, 4.0697500383067409],
					[61.436514094701018, 4.0695667435677159],
					[61.436514094633246, 4.0695667417000578],
					[61.436514096421988, 4.0695667414170487],
					[61.436552995028627, 4.069383346398908],
					[61.436591892817688, 4.0692333524963082],
					[61.436633488441956, 4.0690833689737023],
					[61.436683481011841, 4.0689750865247341],
					[61.43673626684145, 4.0688668143980253],
					[61.43680574483394, 4.0687918361072288],
					[61.436877911082966, 4.0687168734853598],
					[61.436963966811511, 4.068686287666675],
					[61.436963967705857, 4.0686862875251473],
					[61.436963971283333, 4.0686862869590135],
					[61.436963972177715, 4.0686862868174787],
					[61.437050034249992, 4.0686530005310377],
					[61.437141693735171, 4.0686557993248256],
					[61.437141694629517, 4.0686557991832943],
					[61.4371416982748, 4.0686558004848417],
					[61.437141700063542, 4.0686558002017694],
					[61.437230599910052, 4.0686557991882886],
					[61.437311096643597, 4.0686557999236701],
					[61.437388893003586, 4.0686585008981249],
					[61.437388921623374, 4.0686584963689683],
					[61.43738897687092, 4.0686584819905791],
					[61.437389004325262, 4.0686584701321085],
					[61.437439003850244, 4.0686279712105806],
					[61.437489004178985, 4.0685973706234861],
					[61.437489034600794, 4.068597341389335],
					[61.437489081554439, 4.0685972719700896],
					[61.437489097259707, 4.0685972337942413],
					[61.437505797156582, 4.0684972331235905],
					[61.437505797088782, 4.0684972312558791],
					[61.437505797915399, 4.0684972292466046],
					[61.437505797847628, 4.0684972273788906],
					[61.437519597687285, 4.0683972272207134],
					[61.437519599204904, 4.0683972194667506],
					[61.437519600451417, 4.068397204241931],
					[61.437519600180281, 4.0683971967710599],
					[61.437516900293957, 4.0682332977779749],
					[61.437516900226179, 4.0682332959102645],
					[61.437516900158393, 4.068233294042531],
					[61.437516900090621, 4.0682332921748188],
					[61.437511300037549, 4.0680666926617812],
					[61.437511299969742, 4.0680666907940726],
					[61.437511299834199, 4.0680666870586446],
					[61.437511298872039, 4.0680666853324663],
					[61.437497399070246, 4.0678777856097961],
					[61.437483498950229, 4.0676916849566016],
					[61.437469598631104, 4.0675027855931845],
					[61.437469599457685, 4.0675027835839046],
					[61.437469599389907, 4.0675027817161755],
					[61.437452999425162, 4.0673138834299438],
					[61.43743909860676, 4.0671277851958276],
					[61.43743909853896, 4.0671277833281216],
					[61.43743909943332, 4.0671277831865451],
					[61.437439099365513, 4.0671277813188231],
					[61.437422399066882, 4.0669388842148315],
					[61.437408498604007, 4.0667499854629714],
					[61.437394599702507, 4.0665638873560574],
					[61.437386300011369, 4.0663749906338049],
					[61.437386300011369, 4.0663749906338049],
					[61.437386299943562, 4.066374988766106],
					[61.437386299943562, 4.066374988766106],
					[61.437375199936689, 4.0661888948147187],
					[61.437375200466988, 4.0660000007944346],
					[61.437375200399174, 4.0659999989267179],
					[61.437375200331367, 4.0659999970590173],
					[61.437375200331367, 4.0659999970590173],
					[61.437372400349496, 4.0658139001390863],
					[61.437377999790222, 4.0656250061112926],
					[61.437383499898147, 4.0654389078079971],
					[61.437391899737683, 4.0652500096639006],
					[61.437400200319608, 4.0650639090803447],
					[61.437400200251787, 4.0650639072126467],
					[61.437400200251787, 4.0650639072126467],
					[61.437400200183966, 4.0650639053449309],
					[61.43740580023281, 4.0648750070437814],
					[61.437414100367441, 4.0646889081689324],
					[61.437414100299591, 4.0646889063012388],
					[61.43741410023177, 4.0646889044335399],
					[61.437414100163949, 4.0646889025658401],
					[61.437416900430954, 4.0645028030721546],
					[61.43741959965201, 4.0643139037334679],
					[61.437419600410713, 4.0643138998564119],
					[61.437419600139378, 4.0643138923856084],
					[61.437419600071543, 4.064313890517913],
					[61.437411300151133, 4.064144390745029],
					[61.4374029999519, 4.0639721898807277],
					[61.43740299885399, 4.0639721844193],
					[61.437402997620453, 4.0639721752224949],
					[61.437402998311271, 4.0639721694777284],
					[61.437383498135524, 4.0638416710438756],
					[61.437364098437307, 4.0637082715553454],
					[61.437364096309373, 4.0637082625002234],
					[61.437364093015681, 4.0637082461160015],
					[61.437364089993352, 4.0637082372025635],
					[61.437330789860383, 4.0636055377342659],
					[61.437297389748856, 4.0635027378505839],
					[61.437297387756594, 4.063502732530873],
					[61.437297384734251, 4.0635027236174741],
					[61.437297382742003, 4.0635027182977792],
					[61.437252983433432, 4.0634027186915764],
					[61.437252983433432, 4.0634027186915764],
					[61.43725298157689, 4.0634027171072606],
					[61.43720848241307, 4.0633055174324868],
					[61.437208480556464, 4.0633055158481888],
					[61.437208479526412, 4.0633055122544972],
					[61.437208480420772, 4.0633055121128114],
					[61.437152979722903, 4.0631916113404065],
					[61.437152978828557, 4.0631916114820958],
					[61.437152977798476, 4.0631916078884345],
					[61.437152976836295, 4.0631916061624436],
					[61.437094579955513, 4.0630805126584466],
					[61.437033481902461, 4.0629443178643836],
					[61.437033481902461, 4.0629443178643836],
					[61.436972383711741, 4.0628110201289269],
					[61.436919585345009, 4.0626805253412659],
					[61.436919585277138, 4.0626805234736061],
					[61.436866884710511, 4.0625527241652373],
					[61.436825184888555, 4.0624499251465078],
					[61.436783484923765, 4.0623471245360854],
					[61.43678348293146, 4.0623471192165352],
					[61.436783477090273, 4.0623471069932258],
					[61.436783474203587, 4.0623471018153916],
					[61.436736274288265, 4.0622638011199665],
					[61.436736273326034, 4.0622637993940325],
					[61.436736271401564, 4.0622637959421652],
					[61.436736269544987, 4.0622637943579294],
					[61.436686270419379, 4.0621831953012304],
					[61.436686262789429, 4.0621831833614142],
					[61.436686246838796, 4.0621831652264628],
					[61.436686238382364, 4.062183155296025],
					[61.436619540382203, 4.0621192567614948],
					[61.436597340739027, 4.0620970592104655],
					[61.436597258753579, 4.0620970308770445],
					[61.436597103757812, 4.0620970479239311],
					[61.436597029853132, 4.062097093445975],
					[61.436519229929608, 4.0622220931787902],
					[61.436519224970638, 4.0622221052349179],
					[61.436519214916956, 4.0622221256118873],
					[61.436519209957979, 4.0622221376680363],
					[61.436499821563103, 4.0622805003130411],
					[61.436472045631689, 4.0623137723325007],
					[61.436472043084315, 4.0623137764929078],
					[61.436472035374386, 4.0623137871065094],
					[61.436472031106184, 4.0623137934179905],
					[61.43639433136704, 4.0624358365234974],
					[61.436291795055617, 4.0623804247236555],
					[61.436291764998828, 4.0623804144590467],
					[61.436291701647065, 4.0623804038348306],
					[61.436291670073025, 4.062380401324166],
					[61.436200042431622, 4.0623941906737313],
					[61.436114004113094, 4.062341529870519],
					[61.436113961263821, 4.0623415141194812],
					[61.43611387108129, 4.0623415077466678],
					[61.436113826363261, 4.0623415148321484],
					[61.436016657584112, 4.0623804017776282],
					[61.435933293684727, 4.0623830991315284],
					[61.435933260661209, 4.0623831062423088],
					[61.435933200387588, 4.0623831308192448],
					[61.435933172175226, 4.0623831465595073],
					[61.435866582866346, 4.0624387364678238],
					[61.435752696029382, 4.0625081293489336],
					[61.435752695135022, 4.0625081294906371],
					[61.435752694240662, 4.062508129632338],
					[61.435730517648665, 4.0625220155158255],
					[61.435580546102635, 4.0625637074170404],
					[61.435580544313915, 4.0625637077004377],
					[61.435580538947761, 4.0625637085506376],
					[61.435580537226905, 4.0625637107016184],
					[61.435572191903248, 4.062566492378477],
					[61.435561149913077, 4.0625637056604926],
					[61.435391751143904, 4.0625192076609435],
					[61.435391744747641, 4.0625192049176899],
					[61.435391732985252, 4.0625192030246513],
					[61.43539172665686, 4.0625192021489829],
					[61.435372248713399, 4.0625165048249281],
					[61.435213969385458, 4.0624581116528606],
					[61.435213947649366, 4.0624581075834278],
					[61.435213906033745, 4.0624581010287404],
					[61.435213884433374, 4.0624581006944274],
					[61.43507219216928, 4.062469199455756],
					[61.435033300108309, 4.0624692005535596],
					[61.435033276787046, 4.0624692023701883],
					[61.435033233099098, 4.0624692130485807],
					[61.435033211770168, 4.0624692201844663],
					[61.434886017315982, 4.0625415175882242],
					[61.434852723236027, 4.0625554157164006],
					[61.434852702042811, 4.0625554265872594],
					[61.434852664399557, 4.0625554550907026],
					[61.43485264705523, 4.0625554728649416],
					[61.43483876371608, 4.0625720507595258],
					[61.434672084938285, 4.0626887355756764],
					[61.434672084111796, 4.0626887375848932],
					[61.434672083217428, 4.062688737726587],
					[61.434672082390904, 4.0626887397358251],
					[61.434558208908605, 4.0627720184197775],
					[61.43449437686894, 4.0627914978457875],
					[61.434369464613447, 4.0627831033708111],
					[61.434314033810779, 4.0627470488954875],
					[61.434244574735516, 4.0626637981797309],
					[61.434189086745015, 4.0625166281997949],
					[61.434189083858385, 4.0625166230223568],
					[61.434189077949334, 4.0625166089325173],
					[61.434189075062697, 4.0625166037550802],
					[61.434161276888211, 4.0624666061561978],
					[61.434136278924555, 4.0624166101439929],
					[61.434069580323971, 4.062280512687158],
					[61.434069579293876, 4.0622805090938767],
					[61.434069577437285, 4.0622805075097874],
					[61.434069576475054, 4.0622805057839981],
					[61.433991876305775, 4.0621360057234011],
					[61.433991876237918, 4.0621360038559162],
					[61.433991875343558, 4.062136003997618],
					[61.433975174913066, 4.0621055047228047],
					[61.433975170101945, 4.0621054960939018],
					[61.433975159653194, 4.0621054808452524],
					[61.433975153947728, 4.0621054723580343],
					[61.433961254107778, 4.062088772069842],
					[61.433961244757008, 4.062088762281963],
					[61.433961225228913, 4.0620887447154006],
					[61.433961214089415, 4.0620887352109314],
					[61.433841813784674, 4.0620054360023783],
					[61.433841799962089, 4.0620054269230765],
					[61.43384176887519, 4.062005413066295],
					[61.43384175333172, 4.0620054061378994],
					[61.43378065264082, 4.0619887074260737],
					[61.433780614738339, 4.0619887040403491],
					[61.433780543053778, 4.0619887116418862],
					[61.433780506588604, 4.0619887230542471],
					[61.433627707007162, 4.0620692233135713],
					[61.43362770432411, 4.062069223738674],
					[61.433627699025777, 4.0620692264563587],
					[61.433627697304921, 4.0620692286072284],
					[61.433599896739366, 4.0620859281447927],
					[61.43359988538414, 4.0620859374567857],
					[61.433599862605767, 4.0620859542132983],
					[61.433599853039269, 4.0620859632418842],
					[61.433566556151469, 4.0621220602946639],
					[61.433419260049227, 4.0622665568318173],
					[61.433419256539651, 4.0622665592660523],
					[61.433419252271435, 4.0622665655769037],
					[61.433419248761844, 4.0622665680111414],
					[61.433247048788232, 4.0624637684058396],
					[61.433247043625627, 4.0624637748583003],
					[61.433247033368289, 4.0624637896307023],
					[61.433247028205713, 4.0624637960831596],
					[61.433238740690207, 4.0624776741575195],
					[61.433233159641546, 4.0624831561531884],
					[61.433233156199833, 4.0624831604548222],
					[61.433233147527616, 4.0624831693414949],
					[61.433233144085897, 4.0624831736431295],
					[61.433058144284082, 4.0626998730479409],
					[61.433058144351932, 4.0626998749153733],
					[61.432938743640399, 4.0628498752869326],
					[61.432938741919529, 4.06284987743771],
					[61.432938740198658, 4.062849879588506],
					[61.432938739372119, 4.0628498815976117],
					[61.432874840688676, 4.0629359777536482],
					[61.432758145237941, 4.0630831737558673],
					[61.432694266046234, 4.0631581489773172],
					[61.432610988718977, 4.0632137337252621],
					[61.432610984315048, 4.063213736300983],
					[61.432610974680607, 4.0632137434615068],
					[61.432610970344484, 4.0632137479046566],
					[61.432513825737395, 4.0632970003102526],
					[61.432472227945084, 4.0632970005753108],
					[61.432336195677529, 4.0632581197931277],
					[61.432269527952634, 4.0632026461421669],
					[61.432269524239466, 4.0632026429740504],
					[61.432269515024466, 4.0632026369211109],
					[61.432269510349116, 4.0632026320272674],
					[61.432155710418115, 4.0631276335678805],
					[61.432155690267223, 4.0631276236128162],
					[61.432155649206763, 4.0631276075791094],
					[61.432155628297181, 4.0631276015004705],
					[61.432016728188266, 4.0631081018580559],
					[61.432016723716444, 4.0631081025663756],
					[61.432016717388052, 4.0631081016906334],
					[61.432016713742783, 4.0631081003899148],
					[61.431977814358625, 4.0631053990583075],
					[61.431977796403515, 4.0631054000242095],
					[61.431977759734693, 4.0631054058324061],
					[61.431977742741793, 4.0631054085240139],
					[61.431811042616509, 4.0631554076717169],
					[61.431811038212537, 4.0631554102473819],
					[61.431811030231117, 4.0631554133896906],
					[61.43181102575933, 4.0631554140979906],
					[61.431797125771311, 4.0631609137181774],
					[61.431797119578654, 4.0631609165771732],
					[61.431797107193269, 4.0631609222951415],
					[61.431797101000576, 4.0631609251541239],
					[61.431758200583651, 4.0631831257147244],
					[61.431758196179693, 4.0631831282903788],
					[61.431758184756525, 4.0631831357340387],
					[61.431758180420395, 4.06318314017705],
					[61.431616610647211, 4.0632887162894527],
					[61.431555546694398, 4.0633054064807324],
					[61.431555542290425, 4.0633054090563538],
					[61.431555531625897, 4.0633054126235812],
					[61.43155552715411, 4.063305413331852],
					[61.431436065068588, 4.0633525996455253],
					[61.431350024296322, 4.063349801443163],
					[61.431255735354505, 4.063330424720462],
					[61.431216882224767, 4.0632527187686689],
					[61.431180785205548, 4.0631638245179316],
					[61.431180785205548, 4.0631638245179316],
					[61.431180784243317, 4.0631638227922728],
					[61.431133485695149, 4.0630527256838862],
					[61.431080793355669, 4.0629138465302672],
					[61.431064097029207, 4.0628221635647881],
					[61.431064096067026, 4.0628221618391365],
					[61.431064094969088, 4.0628221563788687],
					[61.431064094901238, 4.0628221545115384],
					[61.431055795952005, 4.062786059925581],
					[61.431039097324842, 4.0626916674130547],
					[61.430994597843728, 4.0624082687323542],
					[61.430994596949397, 4.0624082688740266],
					[61.430947398217114, 4.0621166745130095],
					[61.430939098995267, 4.0620305807628219],
					[61.430939098859561, 4.0620305770281968],
					[61.430939097693731, 4.0620305697006591],
					[61.430939096663614, 4.0620305661077447],
					[61.430908496587023, 4.0618555656839606],
					[61.430875196758294, 4.0616555663439842],
					[61.430875194630246, 4.0616555572908748],
					[61.43087519030626, 4.0616555373173568],
					[61.430875186321636, 4.0616555266803447],
					[61.430733488247846, 4.061291632592587],
					[61.430727989837131, 4.0612749365592666],
					[61.430727986882601, 4.0612749295152355],
					[61.430727979048946, 4.0612749119759952],
					[61.430727975132172, 4.0612749032063684],
					[61.430558479222775, 4.0609666105735034],
					[61.430525187250844, 4.0608916280999638],
					[61.430450191402365, 4.060658239235055],
					[61.430405797896626, 4.060513863743771],
					[61.430383499602669, 4.0601916875296569],
					[61.430380800388043, 4.0601388957953715],
					[61.430380799716502, 4.060130613806356],
					[61.430383497855324, 4.0601111317459857],
					[61.430444596658532, 4.0597667348250157],
					[61.430444596590618, 4.0597667329577511],
					[61.430444598107805, 4.0597667252051588],
					[61.430444598866345, 4.0597667213288808],
					[61.430461298772819, 4.0596139221073599],
					[61.430489098132931, 4.0593917241322455],
					[61.430489098755686, 4.0593917165213949],
					[61.430489099933304, 4.0593916994324424],
					[61.430489099661699, 4.0593916919633513],
					[61.430480799684823, 4.0592055910842548],
					[61.430472400349807, 4.0590166908537135],
					[61.430472399183813, 4.059016683526405],
					[61.430472396783969, 4.0590166670045154],
					[61.430472395617976, 4.0590166596772255],
					[61.430394600171354, 4.0586416781811927],
					[61.430394599902193, 4.0586389006185044],
					[61.430394598260833, 4.0586388802203857],
					[61.430394589611993, 4.0586388402748375],
					[61.430394582604507, 4.0586388207274098],
					[61.43037518262777, 4.0585943201083294],
					[61.430375182559885, 4.0585943182410666],
					[61.430375181665497, 4.0585943183828546],
					[61.43037518159759, 4.0585943165155962],
					[61.430222381898254, 4.0582582174513684],
					[61.430222373169912, 4.0582582000549676],
					[61.430222351377253, 4.0582581697056455],
					[61.430222339207319, 4.0582581566109104],
					[61.430219600346788, 4.0582555146361994],
					[61.430219599628046, 4.0582527991341566],
					[61.430219596956448, 4.0582527751434512],
					[61.430219585352766, 4.0582527281546179],
					[61.430219576420683, 4.0582527051564572],
					[61.430125183701946, 4.0580777185074695],
					[61.430055789301875, 4.0578777356573035],
					[61.43005019066522, 4.0578610414045846],
					[61.430041893656117, 4.0578305528280945],
					[61.429969597267153, 4.0574999632806179],
					[61.429944599298992, 4.0573388767783696],
					[61.429925199021675, 4.0571249817089337],
					[61.429925199021675, 4.0571249817089337],
					[61.429925198885797, 4.0571249779744782],
					[61.429925198817877, 4.0571249761072306],
					[61.42991409889806, 4.0570277824370136],
					[61.429897399594374, 4.0567471884391386],
					[61.429894600182777, 4.0566999874764722],
					[61.429875199672011, 4.0563721941643296],
					[61.429875200362829, 4.0563556000334442],
					[61.42987520015901, 4.0563555944317589],
					[61.42987519885699, 4.0563555833702507],
					[61.429875198653164, 4.0563555777685796],
					[61.429841899341405, 4.0560666816366089],
					[61.429836298646762, 4.0559971844066869],
					[61.429836299405238, 4.0559971805303743],
					[61.429836298239096, 4.0559971732033508],
					[61.429836298103204, 4.0559971694688963],
					[61.429802998146265, 4.0557832738286805],
					[61.429783498828783, 4.0556193752718181],
					[61.429783497798553, 4.0556193716792306],
					[61.429783495670108, 4.0556193626268628],
					[61.429783496428549, 4.0556193587505707],
					[61.429758497026214, 4.0555027625351405],
					[61.429716897685012, 4.0552416683364179],
					[61.429716894594222, 4.0552416575587635],
					[61.429716889307024, 4.0552416358615764],
					[61.429716886216227, 4.0552416250839229],
					[61.429711288416435, 4.055227731403094],
					[61.429702990157061, 4.0552027371172992],
					[61.429702980398069, 4.0552027161295134],
					[61.429702955581952, 4.0552026768724057],
					[61.429702941419173, 4.0552026584612078],
					[61.42966954307424, 4.0551692606262222],
					[61.429525143830766, 4.0550192616886171],
					[61.429525138192787, 4.0550192550710937],
					[61.429525126158481, 4.0550192457123293],
					[61.429525119626184, 4.0550192392367004],
					[61.429369519800858, 4.0549026398640891],
					[61.429369517049814, 4.0549026384225542],
					[61.429369509691142, 4.054902633956047],
					[61.429369505977796, 4.0549026307891873],
					[61.429347312908703, 4.054888735256462],
					[61.429336239806361, 4.0548804541761303],
					[61.429314055944637, 4.0548526752577905],
					[61.429314048382082, 4.0548526651897294],
					[61.429314028853135, 4.0548526476302218],
					[61.429314018675456, 4.0548526398550031],
					[61.429205719368319, 4.0547720390770285],
					[61.429205712903972, 4.0547720344686784],
					[61.429205700937615, 4.054772026977278],
					[61.429205693646885, 4.0547720243780052],
					[61.429169501760633, 4.054752627692551],
					[61.429066808778074, 4.0546859327020854],
					[61.429066777690522, 4.054685918854517],
					[61.42906671324009, 4.0546859027881039],
					[61.429066678982934, 4.0546859007111182],
					[61.428988937097003, 4.0546941948215363],
					[61.428900088702434, 4.0546498205250296],
					[61.428900062290509, 4.054649811569627],
					[61.428900004372409, 4.0546498019787816],
					[61.428899974722881, 4.0546498029267468],
					[61.428811106167707, 4.0546608989047588],
					[61.428719464035034, 4.0546442075929248],
					[61.428630688896348, 4.0545998202850662],
					[61.428630688001988, 4.0545998204269553],
					[61.428630684288663, 4.0545998172601978],
					[61.428630681605625, 4.0545998176858564],
					[61.428494482813001, 4.0545387178721635],
					[61.428452887379734, 4.0545192195123443],
					[61.428425096675923, 4.0545054258435123],
					[61.428325104316038, 4.0544442294847869],
					[61.428275120152136, 4.0544137393971926],
					[61.428158445296042, 4.0543081620512815],
					[61.428094571411037, 4.0542304929357975],
					[61.428055789175986, 4.0541471323545606],
					[61.428033493946394, 4.0540610490797757],
					[61.428033493946394, 4.0540610490797757],
					[61.428033493052048, 4.0540610492216738],
					[61.428033492984085, 4.0540610473545478],
					[61.427991894972706, 4.053905557137182],
					[61.427952997654984, 4.0536832722911278],
					[61.427947399101122, 4.0536277807569689],
					[61.427922398672663, 4.0533832805576164],
					[61.4279168998861, 4.0533193903938507],
					[61.427916899957907, 4.0533082995751428],
					[61.427916900082025, 4.0532917009020508],
					[61.42791690001404, 4.0532916990349364],
					[61.427916899946048, 4.0532916971678388],
					[61.427916899946048, 4.0532916971678388],
					[61.427911300037579, 4.0529332969729044],
					[61.427911299901602, 4.0529332932387128],
					[61.427911299697655, 4.0529332876374244],
					[61.427911298735332, 4.0529332859122391],
					[61.427908500398388, 4.0528971956845758],
					[61.42791690023865, 4.0525583045771469],
					[61.42791690023865, 4.0525583045771469],
					[61.427916900102659, 4.0525583008429402],
					[61.427916900102659, 4.0525583008429402],
					[61.427916900226627, 4.0525333061164206],
					[61.427930799246425, 4.0523417149609537],
					[61.427941897878164, 4.0522028354402053],
					[61.427947392359883, 4.0521833534440752],
					[61.427947393944592, 4.0521833475588647],
					[61.427947397114004, 4.0521833357884631],
					[61.427947397736375, 4.052183328178109],
					[61.427986296412563, 4.0519194394112859],
					[61.428014093759295, 4.0518111494161149],
					[61.428014093691282, 4.0518111475490155],
					[61.428014095343975, 4.0518111435308732],
					[61.428014096170294, 4.0518111415218154],
					[61.42804459602911, 4.0516639404388446],
					[61.428044595961111, 4.0516639385717266],
					[61.428044596651432, 4.0516639328284603],
					[61.428044598304126, 4.0516639288103233],
					[61.428077997276212, 4.051438937130774],
					[61.428119588907357, 4.0512528708806164],
					[61.428166876623472, 4.0511639941269104],
					[61.428166880823149, 4.0511639859485733],
					[61.428166888328207, 4.0511639697338415],
					[61.428166890671207, 4.0511639599723415],
					[61.428197390600339, 4.0510667605079274],
					[61.428302988304161, 4.0507306695047127],
					[61.428311279346879, 4.0507111918042273],
					[61.428319571575628, 4.0506973025840418],
					[61.428319576601602, 4.0506972923964755],
					[61.428319585759233, 4.0506972721633305],
					[61.428319589890911, 4.0506972621177537],
					[61.428352989766701, 4.0505944632500075],
					[61.428444589062252, 4.0503250644831477],
					[61.428444595196687, 4.0503250353402196],
					[61.428444596801441, 4.050324980625601],
					[61.428444593992431, 4.0503249529027583],
					[61.428397393757187, 4.0501360526219106],
					[61.428397392658802, 4.0501360471625691],
					[61.428397390666035, 4.0501360418452279],
					[61.428397389635627, 4.0501360382530089],
					[61.428336289727369, 4.0499471372957681],
					[61.428336284847489, 4.0499471268031666],
					[61.428336273094921, 4.0499471005006447],
					[61.428336266358357, 4.0499470884249611],
					[61.428319566296302, 4.0499220898712327],
					[61.428319559695744, 4.0499220815297914],
					[61.42831954835134, 4.0499220664299989],
					[61.428319540856457, 4.0499220582305737],
					[61.428308457957542, 4.0499109750859903],
					[61.428216870174282, 4.0497637931771928],
					[61.428216864604124, 4.0497637884279953],
					[61.428216856078812, 4.0497637766364498],
					[61.428216852229312, 4.0497637697361046],
					[61.428130752368233, 4.0496637690269814],
					[61.428100153234936, 4.0496276711821357],
					[61.428100150415823, 4.0496276678740211],
					[61.42810014388332, 4.0496276613997919],
					[61.428100141064206, 4.0496276580916772],
					[61.428036240645568, 4.0495637582555766],
					[61.428036237826447, 4.0495637549474575],
					[61.428036232188269, 4.0495637483312281],
					[61.428036227580485, 4.0495637453071804],
					[61.427952928274067, 4.0494942467889237],
					[61.427952912594009, 4.0494942361337776],
					[61.427952882264258, 4.0494942184156262],
					[61.427952865825873, 4.049494211636695],
					[61.427825077920431, 4.0494498158186625],
					[61.427775089329494, 4.0494248200799055],
					[61.42777507385351, 4.0494248150260885],
					[61.427775041112831, 4.0494248052025377],
					[61.427775023848184, 4.0494248004327797],
					[61.427661158322216, 4.0494109067506354],
					[61.427594514862264, 4.0493776344235197],
					[61.427455734765125, 4.0492498521135269],
					[61.427455731051687, 4.0492498489475395],
					[61.427455720873716, 4.049249841174599],
					[61.427455716197883, 4.0492498362835745],
					[61.427416844063991, 4.0492220578848777],
					[61.4273779638592, 4.0491665842472946],
					[61.427377962002467, 4.0491665826642933],
					[61.42737795822098, 4.049166577631274],
					[61.427377956296198, 4.0491665741812248],
					[61.427239055830078, 4.0489942738102824],
					[61.427239049297548, 4.048994267336357],
					[61.427239035270006, 4.0489942526635154],
					[61.427239026948754, 4.0489942464736925],
					[61.427111236800918, 4.0488887544374279],
					[61.427061245258308, 4.048835962858738],
					[61.427061239688143, 4.0488359581098745],
					[61.427061231230795, 4.0488359481860421],
					[61.427061225660623, 4.0488359434371946],
					[61.426989025674324, 4.0487776431130751],
					[61.426989024780006, 4.0487776432551152],
					[61.42698902388566, 4.0487776433971616],
					[61.426880744367004, 4.0486915581492084],
					[61.426786259938488, 4.0485665784003908],
					[61.426786259044164, 4.0485665785424381],
					[61.426786256225036, 4.0485665752345525],
					[61.426786255262627, 4.0485665735095884],
					[61.426702965419913, 4.0484637868158702],
					[61.426661276650968, 4.0483916075313582],
					[61.42659458064869, 4.0482499159320131],
					[61.426594578655859, 4.048249910615116],
					[61.426594575700648, 4.0482499035732475],
					[61.426594573775844, 4.0482499001233592],
					[61.426527974616661, 4.0481332043083462],
					[61.426497377170861, 4.0480749062040458],
					[61.426497374283663, 4.0480749010292385],
					[61.426497367615006, 4.0480748908216571],
					[61.426497363833441, 4.0480748857888962],
					[61.42645296884821, 4.0480109922093908],
					[61.426394572923122, 4.0479109987098818],
					[61.426394567216796, 4.0479109902273001],
					[61.426394555872228, 4.0479109751291249],
					[61.426394550165881, 4.0479109666465485],
					[61.426350149691089, 4.0478609663481055],
					[61.426350145977629, 4.0478609631823765],
					[61.426350140339345, 4.047860956566808],
					[61.426350135731532, 4.04786095354316],
					[61.426272336353641, 4.0477887528698275],
					[61.426272331745821, 4.0477887498462017],
					[61.42627232431888, 4.0477887435147917],
					[61.426272319711046, 4.0477887404911685],
					[61.426172319714226, 4.0477137405392094],
					[61.426172309604233, 4.0477137346340486],
					[61.426172290278629, 4.0477137226816735],
					[61.42617228016865, 4.0477137167765145],
					[61.426000080077316, 4.0476387171633199],
					[61.426000076431912, 4.0476387158646148],
					[61.426000068178652, 4.047638711542354],
					[61.426000063638874, 4.0476387103857441],
					[61.425991764250988, 4.0476359108129785],
					[61.425991759711202, 4.0476359096563677],
					[61.425991752420366, 4.0476359070589814],
					[61.425991747880595, 4.0476359059023714],
					[61.425913948141435, 4.0476165059354816],
					[61.425813949190051, 4.0475915065562935],
					[61.425802848690658, 4.0475887069510179],
					[61.425802842294146, 4.0475887042116074],
					[61.425802828742924, 4.0475887026087616],
					[61.425802823308814, 4.0475887015942318],
					[61.425633325423696, 4.0475692013791154],
					[61.425613927766342, 4.0475665011748667],
					[61.425613922332211, 4.0475665001603494],
					[61.425613912426385, 4.0475664998561838],
					[61.425613907060331, 4.0475665007086077],
					[61.42545280738554, 4.0475609007632336],
					[61.425452796585368, 4.0475609006011384],
					[61.425452775947456, 4.04756090200181],
					[61.425452766109686, 4.0475609035645803],
					[61.425274965920131, 4.0475915023509508],
					[61.425274952573083, 4.0475915063489092],
					[61.425274925878945, 4.0475915143447923],
					[61.42527491259991, 4.0475915202096608],
					[61.425269312549524, 4.0475942199271273],
					[61.425269308145857, 4.0475942225043928],
					[61.425269297549889, 4.0475942279430681],
					[61.425269293146229, 4.0475942305203301],
					[61.425094292639777, 4.0477054305966789],
					[61.425094280391185, 4.0477054400532424],
					[61.425094255135797, 4.0477054628422549],
					[61.425094243917691, 4.0477054758905773],
					[61.424947043683332, 4.0478887739075331],
					[61.424910945233037, 4.0479331736968609],
					[61.42491094007115, 4.0479331801499203],
					[61.424910929815375, 4.0479331949229351],
					[61.424910924721523, 4.0479332032428985],
					[61.424897032130126, 4.0479581903509851],
					[61.424730445378223, 4.0481776727802838],
					[61.424602651412762, 4.0483220670655893],
					[61.424549861211482, 4.0483804562339438],
					[61.424497070800321, 4.0484248470554345],
					[61.424497069079678, 4.0484248492063948],
					[61.424369278126768, 4.0485359401593231],
					[61.424205489280915, 4.0486470332675877],
					[61.424188788561118, 4.0486581331611236],
					[61.424188787666772, 4.0486581333031575],
					[61.424188786840467, 4.0486581353120688],
					[61.424188785946136, 4.0486581354540974],
					[61.424152690751185, 4.0486831326150812],
					[61.4240081953727, 4.0487720306200998],
					[61.424008190969019, 4.0487720331970847],
					[61.424008182161664, 4.0487720383510535],
					[61.424008176931665, 4.0487720429369194],
					[61.423933176898046, 4.0488304421802663],
					[61.423933171668075, 4.0488304467661056],
					[61.423933162928776, 4.0488304537868913],
					[61.42393315859313, 4.0488304582307251],
					[61.423827658859508, 4.0489359589059672],
					[61.423827655350188, 4.0489359613408906],
					[61.423827649361918, 4.0489359698024181],
					[61.423827644958259, 4.0489359723793612],
					[61.423738745266938, 4.0490442720858022],
					[61.42373874533498, 4.0490442739526387],
					[61.423738743546288, 4.0490442742366755],
					[61.423647044435988, 4.0491581756946671],
					[61.423647041821013, 4.0491581779875352],
					[61.423647036659027, 4.0491581844401123],
					[61.423647034112086, 4.0491581885998267],
					[61.423477634171014, 4.0494081871670602],
					[61.423477633344682, 4.0494081891759066],
					[61.42347763079772, 4.0494081933355632],
					[61.423477629903374, 4.0494081934775714],
					[61.423463730064711, 4.0494304948635618],
					[61.423463729238406, 4.0494304968723869],
					[61.423463728412081, 4.0494304988812129],
					[61.423463726623389, 4.0494304991652212],
					[61.423388727247641, 4.0495610000061939],
					[61.423388726353267, 4.0495610001481976],
					[61.423283128118378, 4.0497470973245893],
					[61.423263731282354, 4.0497776933639607],
					[61.423263730387994, 4.0497776935059537],
					[61.42326372873535, 4.0497776975235613],
					[61.42326372784099, 4.049777697665558],
					[61.423099827680332, 4.0500554986821493],
					[61.423099826853985, 4.0500555006909371],
					[61.423099824306988, 4.050055504850512],
					[61.423099823480676, 4.0500555068592954],
					[61.423052623927681, 4.0501443058649267],
					[61.422919223600921, 4.0503916058267118],
					[61.422919222774574, 4.05039160783548],
					[61.422919221121916, 4.0503916118530254],
					[61.422919219401251, 4.0503916140037628],
					[61.42286092111587, 4.0505138092671888],
					[61.422735922804513, 4.0507499056522951],
					[61.422735922872512, 4.0507499075190765],
					[61.42273592197818, 4.0507499076610358],
					[61.422735922046193, 4.0507499095278172],
					[61.42266922316599, 4.0508805077166459],
					[61.422552625547944, 4.0510943009056151],
					[61.422460929878127, 4.0512498936283716],
					[61.422372032843612, 4.0513859896624709],
					[61.422219233919456, 4.0516165884042712],
					[61.422188734800997, 4.0516609866735429],
					[61.422188733974643, 4.0516609886822286],
					[61.422188731427603, 4.0516609928415281],
					[61.422188729706868, 4.0516609949921696],
					[61.422080430062316, 4.0518359948751357],
					[61.422080429235969, 4.0518359968838045],
					[61.422008136368049, 4.0519554857124529],
					[61.421985943668062, 4.0519831750622606],
					[61.421985941053009, 4.0519831773547699],
					[61.421985935958894, 4.0519831856732651],
					[61.4219859343062, 4.0519831896906053],
					[61.421827636336445, 4.052219286880443],
					[61.421733138325607, 4.0523498823651245],
					[61.421733136604892, 4.0523498845156727],
					[61.421733134884185, 4.0523498866662209],
					[61.421733134057853, 4.0523498886748506],
					[61.421644246068503, 4.0524831717321419],
					[61.42151927545158, 4.0526026436336151],
					[61.421463790687298, 4.0526387328479894],
					[61.421463789792966, 4.0526387329898874],
					[61.421463788004267, 4.052638733273688],
					[61.421463787177899, 4.0526387352823017],
					[61.421358186706421, 4.0527109344826533],
					[61.421358184985714, 4.0527109366331526],
					[61.421283185641869, 4.0527637352958292],
					[61.421169286835308, 4.0528415346103568],
					[61.421169283325895, 4.0528415370446407],
					[61.421169276375025, 4.0528415437798735],
					[61.421169271971252, 4.052841546356035],
					[61.421102671721293, 4.0528970465122747],
					[61.42110266910624, 4.0528970488046365],
					[61.421102664770444, 4.0528970532474746],
					[61.42110266215537, 4.05289705553984],
					[61.421044261909962, 4.0529526551052069],
					[61.421044259294874, 4.0529526573975643],
					[61.421044253238357, 4.0529526639908671],
					[61.421044249796935, 4.0529526682917965],
					[61.420938749879049, 4.053072067734881],
					[61.420938748158342, 4.0530720698853298],
					[61.420938744648907, 4.0530720723195532],
					[61.420938743822532, 4.0530720743281208],
					[61.420927643575212, 4.0530859750467956],
					[61.420927640960173, 4.0530859773391246],
					[61.420927636760311, 4.0530859855152892],
					[61.420927634145237, 4.0530859878076262],
					[61.420922033800153, 4.0530942885193024],
					[61.420922033800153, 4.0530942885193024],
					[61.420883134405102, 4.0531526870407939],
					[61.420741535806648, 4.0533609868358056],
					[61.420685937069202, 4.0534387843693374],
					[61.420558138083678, 4.0536192822685706],
					[61.420422039457577, 4.0538026815554087],
					[61.420422038563252, 4.0538026816972668],
					[61.420422037668885, 4.0538026818391319],
					[61.42037763777477, 4.0538637814476797],
					[61.420377636948373, 4.0538637834561904],
					[61.420377636122005, 4.0538637854646975],
					[61.420377635295601, 4.0538637874732082],
					[61.420288739019512, 4.0539942827855313],
					[61.420197046163992, 4.054110971256919],
					[61.420177651106322, 4.0541331652420718],
					[61.420144253984205, 4.0541692631905084],
					[61.420060963258848, 4.0542581539617206],
					[61.420013772998608, 4.0542970456420377],
					[61.420013772104248, 4.0542970457838843],
					[61.420013770383491, 4.0542970479342042],
					[61.420013768594814, 4.0542970482178911],
					[61.419933183244879, 4.0543665361258583],
					[61.419833198701447, 4.0544248264170593],
					[61.419833191682564, 4.0544248312850328],
					[61.419833177712725, 4.0544248428875864],
					[61.419833170693828, 4.0544248477555458],
					[61.419797090944648, 4.0544554303316325],
					[61.41965551532855, 4.0545220188482887],
					[61.419655508241718, 4.0545220218496034],
					[61.419655495030298, 4.0545220295769768],
					[61.419655488905761, 4.0545220343030453],
					[61.419647088895992, 4.0545276337743932],
					[61.419474889997403, 4.0546415330977075],
					[61.419474885593587, 4.0546415356734666],
					[61.419474877680322, 4.0546415406831482],
					[61.419474875133197, 4.0546415448418553],
					[61.419374875069039, 4.0547220431943636],
					[61.419374874242656, 4.054722045202781],
					[61.419374873348282, 4.0547220453446133],
					[61.41937487155959, 4.0547220456282691],
					[61.419294271800318, 4.0547887453061087],
					[61.419294269253157, 4.0547887494647643],
					[61.419294262234253, 4.0547887543325514],
					[61.419294258792739, 4.0547887586330518],
					[61.419197058852106, 4.0548859597808597],
					[61.41919705616904, 4.0548859602063327],
					[61.41919705183318, 4.0548859646486131],
					[61.419197050112423, 4.0548859667988584],
					[61.419110950080714, 4.0549831662542886],
					[61.419110948427921, 4.0549831702710817],
					[61.419110944092033, 4.054983174713354],
					[61.41911094237129, 4.0549831768635709],
					[61.418983145401505, 4.0551470720905973],
					[61.418930448662387, 4.0552081682418475],
					[61.418930447835983, 4.0552081702502285],
					[61.418930446115212, 4.0552081724004196],
					[61.418930445288836, 4.0552081744087944],
					[61.418894245958185, 4.0552526739188473],
					[61.418749852370361, 4.0554248641146868],
					[61.418574858711757, 4.0556026589722025],
					[61.418569282254012, 4.0556081350438449],
					[61.418558210131877, 4.0556137202533451],
					[61.418558203112937, 4.0556137251208435],
					[61.418558189901425, 4.0556137328474682],
					[61.418558182882485, 4.0556137377149639],
					[61.418388794802098, 4.0557359292953192],
					[61.418302707967172, 4.0557804219148705],
					[61.418302708035121, 4.0557804237814148],
					[61.418302707072812, 4.0557804220566629],
					[61.418302706178494, 4.0557804221984508],
					[61.418208206087627, 4.0558304235088691],
					[61.418208205193253, 4.0558304236506606],
					[61.418208201683775, 4.0558304260843476],
					[61.418208200789458, 4.0558304262261338],
					[61.418052701825829, 4.0559192258264192],
					[61.418027702899394, 4.0559331255329409],
					[61.418027697601218, 4.0559331282501718],
					[61.41802768796714, 4.0559331354093846],
					[61.418027682668956, 4.0559331381266182],
					[61.41797768287104, 4.0559692372545717],
					[61.417880484154921, 4.0560387365108213],
					[61.417880483328496, 4.0560387385191117],
					[61.417880480645422, 4.0560387389444621],
					[61.417880479819026, 4.0560387409527472],
					[61.41784708040786, 4.0560637396806829],
					[61.417847076898347, 4.0560637421143166],
					[61.417847069052975, 4.0560637489898657],
					[61.417847065543477, 4.0560637514234985],
					[61.417760966668773, 4.0561415507435035],
					[61.41766656841039, 4.0562248496908619],
					[61.417666564900905, 4.0562248521244602],
					[61.417666561459349, 4.056224856424568],
					[61.417666558844211, 4.0562248587163925],
					[61.417560959191569, 4.056330458738266],
					[61.417510963121202, 4.0563804533776198],
					[61.417485977930731, 4.0564026402258806],
					[61.417430490768908, 4.0564387329479947],
					[61.417430486365049, 4.0564387355233373],
					[61.417430476730949, 4.0564387426822313],
					[61.417430473221458, 4.0564387451157851],
					[61.417305489094353, 4.0565415329222123],
					[61.417161008189829, 4.0566165222185031],
					[61.417161007295469, 4.056616522360267],
					[61.417161006401138, 4.0566165225020256],
					[61.417161004680352, 4.0566165246520134],
					[61.417124917580594, 4.0566359166027732],
					[61.416988830713279, 4.0566859120876231],
					[61.416988829818926, 4.0566859122293817],
					[61.416944334053035, 4.0567026101419552],
					[61.416797138960888, 4.0567498096717598],
					[61.416797123892707, 4.0567498158144968],
					[61.416797093892164, 4.056749831832879],
					[61.416797079786242, 4.0567498397003261],
					[61.416763789810318, 4.0567748325224295],
					[61.416661000932343, 4.0568331269150573],
					[61.41666098421129, 4.0568331370741193],
					[61.416660952897494, 4.0568331664409349],
					[61.416660939063306, 4.0568331817740395],
					[61.416583141999894, 4.0569387772551035],
					[61.416497046778034, 4.057044271955319],
					[61.416402651101237, 4.0571526677759451],
					[61.416283156993558, 4.0572831590879748],
					[61.416222070150781, 4.0573415472331069],
					[61.416138779396555, 4.0574054399639241],
					[61.416041581249068, 4.05747763894641],
					[61.416041571682811, 4.0574776479710666],
					[61.416041555165442, 4.0574776637287675],
					[61.416041547319992, 4.0574776706035429],
					[61.415952654162396, 4.0575831622381786],
					[61.415858161313821, 4.0576748556203315],
					[61.415858156151437, 4.0576748620698426],
					[61.415858145758733, 4.0576748731024948],
					[61.41585814142276, 4.0576748775439144],
					[61.415785944225981, 4.0577692751216157],
					[61.415677646135698, 4.057899871881431],
					[61.415677640973307, 4.0578998783308649],
					[61.415677630716374, 4.057899893096157],
					[61.415677627342681, 4.0578998992621624],
					[61.41554153588995, 4.0581359845667135],
					[61.41549706075989, 4.0581887547252116],
					[61.415385977187604, 4.0582748422947237],
					[61.415385976293244, 4.058274842436429],
					[61.415385972851631, 4.0582748467359702],
					[61.415385971062904, 4.058274847019379],
					[61.415316612031766, 4.0583331135648733],
					[61.415241664357268, 4.0583470023838712],
					[61.415241658164696, 4.0583470052421298],
					[61.415241645643675, 4.0583470072259482],
					[61.415241640277536, 4.0583470080761543],
					[61.415136161946464, 4.0583803717366429],
					[61.415022349101342, 4.0582554661936783],
					[61.414958460321778, 4.0581831784377611],
					[61.414914075426495, 4.0581138017651179],
					[61.41478077987481, 4.0578416113787998],
					[61.41472798123619, 4.0577332151453929],
					[61.414602982582942, 4.0574499185355846],
					[61.414602981620646, 4.0574499168109952],
					[61.414558483393606, 4.0573527190644372],
					[61.414427986660662, 4.0570443274527923],
					[61.414402989292256, 4.0569721343968137],
					[61.414402987231881, 4.0569721272150634],
					[61.414402982352556, 4.0569721167259205],
					[61.414402979465741, 4.0569721115522208],
					[61.414250179168377, 4.0566666101795263],
					[61.414250172432389, 4.0566665981076788],
					[61.41425015627739, 4.0566665743891912],
					[61.414250146926264, 4.0566665646088662],
					[61.414180752601474, 4.0565915709998643],
					[61.414072358307642, 4.056452677332592],
					[61.414072315808724, 4.0564526465273589],
					[61.414072222682456, 4.0564526087297894],
					[61.4140721720551, 4.0564526017374529],
					[61.414013962048898, 4.0564608890345291],
					[61.413894529572559, 4.0563581483836293],
					[61.41389451114199, 4.0563581362884653],
					[61.413894470771417, 4.0563581145313892],
					[61.413894448899313, 4.0563581067357619],
					[61.413775088816443, 4.0563276154416856],
					[61.413716821240804, 4.0562831412342666],
					[61.413716807417906, 4.0562831321629691],
					[61.41371677805126, 4.0562831161701416],
					[61.413716763401958, 4.0562831091068494],
					[61.413550083710263, 4.0562276176153258],
					[61.413536202899742, 4.0562192294161372],
					[61.413536188182476, 4.0562192204866712],
					[61.413536155374317, 4.0562192087934816],
					[61.413536138109805, 4.0562192040217697],
					[61.413377837661329, 4.0561887044595117],
					[61.413377834910328, 4.0561887030185124],
					[61.413377831264981, 4.0561887017192833],
					[61.413377827687555, 4.0561887022862892],
					[61.413358327746039, 4.0561859029058338],
					[61.413358321349676, 4.0561859001656089],
					[61.41335830697205, 4.0561859005674039],
					[61.413358299749291, 4.0561858998351816],
					[61.41335562458405, 4.0561858996353086],
					[61.413177847595563, 4.0561415061503299],
					[61.413177843055841, 4.0561415049928646],
					[61.413177833082081, 4.0561415028196679],
					[61.413177827648013, 4.0561415018039568],
					[61.413158341256853, 4.0561387040712882],
					[61.412997256431737, 4.056094208742115],
					[61.412952858670238, 4.0560804092551095],
					[61.412952856813611, 4.0560804076724022],
					[61.412952855919265, 4.0560804078141528],
					[61.4128194558605, 4.0560415086873842],
					[61.412819454966105, 4.0560415088291428],
					[61.412819454003845, 4.0560415071046867],
					[61.412750053616421, 4.0560220079667149],
					[61.412750036351952, 4.0560220031952054],
					[61.412749999411652, 4.0560220015422814],
					[61.41274997959998, 4.0560220009284302],
					[61.412638885104712, 4.0560330997452043],
					[61.41257779122045, 4.0560359000727049],
					[61.412577765284162, 4.0560359041835001],
					[61.412577716434285, 4.0560359213108059],
					[61.41257769166409, 4.0560359327446225],
					[61.412461006301179, 4.0561109230847929],
					[61.412441621359143, 4.0561192152559347],
					[61.412441608147631, 4.0561192229807199],
					[61.412441580898125, 4.0561192404382114],
					[61.412441568648859, 4.0561192498874332],
					[61.412399868834505, 4.0561554490732332],
					[61.412399861057033, 4.0561554578136914],
					[61.412399844539799, 4.0561554735701577],
					[61.412399837656658, 4.0561554821688723],
					[61.41232763786369, 4.0562554831287558],
					[61.412327631874909, 4.0562554915856817],
					[61.412327621686075, 4.0562555082160259],
					[61.412327618448309, 4.0562555181138915],
					[61.41227762534745, 4.0563666017985138],
					[61.412169236228749, 4.0565220852494432],
					[61.412169230307882, 4.0565220955724426],
					[61.412169219360514, 4.0565221160766809],
					[61.412169214401949, 4.0565221281241044],
					[61.41209701585597, 4.0567082220040565],
					[61.412013720300898, 4.056891612018716],
					[61.41191372296457, 4.0570805060926638],
					[61.411913719590892, 4.0570805122580111],
					[61.411913713805831, 4.0570805263131211],
					[61.411913711326491, 4.0570805323367534],
					[61.411849814933916, 4.057261024678632],
					[61.411730417895669, 4.0575193163874346],
					[61.411730417069208, 4.0575193183952845],
					[61.411730415416308, 4.0575193224109798],
					[61.411730413763429, 4.0575193264266733],
					[61.411685917008676, 4.0576305184586223],
					[61.411547030979918, 4.0579137907673175],
					[61.411477646098966, 4.057997071719611],
					[61.411477645272498, 4.0579970737274333],
					[61.411477644446073, 4.0579970757352468],
					[61.411477642725252, 4.0579970778847549],
					[61.411366628059668, 4.0581386672986284],
					[61.411305660719883, 4.0581192096975398],
					[61.411305638089296, 4.0581192057751805],
					[61.41130559117525, 4.0581192019460444],
					[61.411305567854008, 4.0581192037637095],
					[61.411186151293187, 4.0581386885131341],
					[61.411133418590879, 4.0580998390928054],
					[61.411133414945517, 4.058099837793435],
					[61.411133409307752, 4.058099831179085],
					[61.411133405662419, 4.0580998298797146],
					[61.411008431177393, 4.0580220464257728],
					[61.410972350815904, 4.0579804692107952],
					[61.41097233960825, 4.0579804578483092],
					[61.410972314577798, 4.0579804374145283],
					[61.410972300755006, 4.0579804283432157],
					[61.410830718587967, 4.0578970374134853],
					[61.410766835181725, 4.0578387526135273],
					[61.410766827755239, 4.0578387462826786],
					[61.410766812970152, 4.0578387354870884],
					[61.410766804649292, 4.0578387292979334],
					[61.410650105425397, 4.0577665303264645],
					[61.410650096210169, 4.0577665242790397],
					[61.410650077915598, 4.0577665159163603],
					[61.41065006876827, 4.0577665117350179],
					[61.410536168660457, 4.0577248113041344],
					[61.410536157792357, 4.0577248092722824],
					[61.410536136882577, 4.0577248032008049],
					[61.410536126014485, 4.0577248011689386],
					[61.410472225645599, 4.0577165017888062],
					[61.41047219331292, 4.0577165031575477],
					[61.410472130775752, 4.0577165149420047],
					[61.410472099744887, 4.0577165273654687],
					[61.410380499567701, 4.0577692270862373],
					[61.41038049695252, 4.0577692293773691],
					[61.410380489865567, 4.0577692323769439],
					[61.4103804872504, 4.0577692346680747],
					[61.410291587286359, 4.0578304343160045],
					[61.410291575036986, 4.0578304437638684],
					[61.410291554183566, 4.0578304639589078],
					[61.410291543790784, 4.0578304749894611],
					[61.410191543838238, 4.057955474542843],
					[61.410191541290942, 4.057955478700018],
					[61.410191535302097, 4.0579554871559997],
					[61.410191531860448, 4.0579554914548579],
					[61.410110947812825, 4.0580804661215897],
					[61.410002671334183, 4.0581720468432501],
					[61.410002665277361, 4.0581720534331218],
					[61.410002653922326, 4.0581720627390654],
					[61.410002648759892, 4.0581720691872594],
					[61.409930466405491, 4.0582554505738955],
					[61.409841593422932, 4.0583165309849161],
					[61.409749908228591, 4.0583692224619128],
					[61.409713815602046, 4.058385918784313],
					[61.409713809409467, 4.0583859216420066],
					[61.409713795303418, 4.058385929506783],
					[61.409713788284364, 4.0583859343721684],
					[61.409569287615206, 4.0584831342357823],
					[61.409569279701778, 4.0584831392427896],
					[61.409569264905038, 4.0584831528472156],
					[61.409569257059502, 4.0584831597202395],
					[61.409397068030252, 4.0586581482791049],
					[61.409385979259639, 4.0586665408685976],
					[61.409385962742078, 4.0586665566221543],
					[61.409385933420175, 4.0586665912946662],
					[61.409385920615847, 4.0586666102136233],
					[61.409380425990733, 4.0586776994818576],
					[61.409183132975208, 4.0589748896545217],
					[61.409183123680393, 4.0589749061406204],
					[61.409183109698297, 4.0589749421366124],
					[61.409183104048815, 4.0589749599221161],
					[61.409160904455142, 4.0590832604015521],
					[61.40916090356081, 4.0590832605431935],
					[61.409160902734335, 4.0590832625508328],
					[61.409160902802206, 4.059083264416846],
					[61.409133104966322, 4.0592360556786842],
					[61.409097006826258, 4.0593721481281957],
					[61.409097005931912, 4.0593721482698291],
					[61.409097005999797, 4.05937215013582],
					[61.409060911177129, 4.0595110324435311],
					[61.409013718115517, 4.0596138161738997],
					[61.40896653089375, 4.0597165892725497],
					[61.408894251111761, 4.0597970668313934],
					[61.408894251111761, 4.0597970668313934],
					[61.408822058242414, 4.0598776597355863],
					[61.408727668371597, 4.0599637507134752],
					[61.408630470221659, 4.0600470484803255],
					[61.408630466712076, 4.0600470509127486],
					[61.408630460587332, 4.0600470556359873],
					[61.408630458934361, 4.0600470596511444],
					[61.408541559201232, 4.0601359586060557],
					[61.408541558306872, 4.0601359587476678],
					[61.40854155658603, 4.0601359608968473],
					[61.408541555691684, 4.0601359610384558],
					[61.408455456127271, 4.0602248608856488],
					[61.408455451791198, 4.0602248653255995],
					[61.408455444907773, 4.0602248739223006],
					[61.408455440571736, 4.0602248783622441],
					[61.408391541400427, 4.0603081776874861],
					[61.408391541468283, 4.0603081795534379],
					[61.408391538853067, 4.0603081818442055],
					[61.408391538853067, 4.0603081818442055],
					[61.408330449242456, 4.0603915670903907],
					[61.408272061670047, 4.0604470550374137],
					[61.408213813069587, 4.0605026052174304],
					[61.408147183686076, 4.0605081002547943],
					[61.40814718100296, 4.0605081006795922],
					[61.408147175636806, 4.0605081015291766],
					[61.408147173848086, 4.0605081018123705],
					[61.408083350076666, 4.0605164912467036],
					[61.408014013128842, 4.0604692354624898],
					[61.40801401127225, 4.0604692338797559],
					[61.408014006664729, 4.0604692308558663],
					[61.408014004808102, 4.0604692292731377],
					[61.407941834616182, 4.0604248487996992],
					[61.407861257368687, 4.0603220764571102],
					[61.407861256406434, 4.0603220747327935],
					[61.407861255444224, 4.0603220730084724],
					[61.407861253587647, 4.0603220714257517],
					[61.407777962922786, 4.0602220825467379],
					[61.407683471344242, 4.0600692962913865],
					[61.407591873690293, 4.0599138007274496],
					[61.407497376350264, 4.059741605119787],
					[61.407405776711329, 4.0595666077466079],
					[61.407405775681198, 4.0595666041564318],
					[61.407405773756707, 4.0595666007078917],
					[61.40740577279449, 4.0595665989836176],
					[61.407319572883829, 4.0594192987039532],
					[61.407233472726972, 4.0592720997645015],
					[61.407233469840236, 4.0592720945917291],
					[61.407233464134649, 4.0592720861120979],
					[61.407233462210158, 4.0592720826635746],
					[61.407158461717046, 4.0591692824350876],
					[61.407083462801765, 4.0590637838488552],
					[61.407083459020654, 4.059063778817757],
					[61.407083450564095, 4.0590637688972144],
					[61.407083446782984, 4.059063763866118],
					[61.407011247424414, 4.0589859642092874],
					[61.406939047062444, 4.0589081631822923],
					[61.406939037779409, 4.0589081552693305],
					[61.406939019213318, 4.058908139443413],
					[61.406939009103795, 4.0589081335379689],
					[61.40685841205282, 4.0588554336563334],
					[61.406777913721974, 4.0587998359340451],
					[61.406777897148245, 4.0587998254225468],
					[61.406777862415758, 4.0587998102804148],
					[61.406777844257007, 4.0587998056497829],
					[61.406691743947647, 4.0587804055099923],
					[61.406691743053315, 4.0587804056516275],
					[61.406602843383062, 4.0587609047259043],
					[61.406602828043169, 4.0587609034020797],
					[61.406602797363348, 4.0587609007544154],
					[61.40660278119698, 4.0587609014380686],
					[61.406513883872961, 4.0587692019241173],
					[61.406424994385517, 4.0587748007041622],
					[61.406333299729567, 4.0587747992224115],
					[61.406244454834287, 4.0587748005539996],
					[61.406161202652804, 4.0587248288893694],
					[61.40616120169053, 4.0587248271651939],
					[61.406161199901803, 4.0587248274484722],
					[61.406161198939564, 4.0587248257242905],
					[61.406077941405528, 4.0586776493657011],
					[61.406014068424973, 4.0585804912014263],
					[61.405950182768017, 4.0584805149642289],
					[61.405911291366756, 4.0583527427502926],
					[61.405872395660111, 4.0582249546323919],
					[61.405850197987796, 4.0580805699388067],
					[61.405827997878809, 4.0579360732259104],
					[61.405808498677779, 4.0577693771667969],
					[61.405791898637361, 4.0576055792092491],
					[61.405791898637361, 4.0576055792092491],
					[61.405791898569476, 4.0576055773434589],
					[61.405791899395922, 4.0576055753359848],
					[61.405769598858072, 4.0574221773640549],
					[61.405750199302361, 4.0572416790938659],
					[61.405750198272202, 4.0572416755039535],
					[61.405750197038273, 4.0572416663166857],
					[61.405750196008107, 4.0572416627267902],
					[61.405714095772971, 4.0570527612402616],
					[61.40567799561812, 4.0568666618256906],
					[61.405677995414372, 4.056866656228352],
					[61.405677993286055, 4.0568666471828099],
					[61.405677992255853, 4.0568666435929215],
					[61.405622391841334, 4.0566777435322905],
					[61.405566891604458, 4.0564860434593584],
					[61.405566891468624, 4.0564860397278117],
					[61.405566886589284, 4.0564860292416514],
					[61.405566884664715, 4.0564860257934852],
					[61.40549458623115, 4.0563082264430159],
					[61.405425187357594, 4.0561277278853458],
					[61.405425185433025, 4.0561277244372373],
					[61.405425180621613, 4.0561277158169391],
					[61.405425179591376, 4.0561277122271227],
					[61.405352980074056, 4.0559805123978174],
					[61.405352979179689, 4.0559805125395298],
					[61.405352979111747, 4.0559805106737725],
					[61.405280778815744, 4.0558360119370143],
					[61.405280775860916, 4.0558360048991311],
					[61.405280770087209, 4.0558359945548776],
					[61.405280767200367, 4.0558359893827562],
					[61.405216867049418, 4.0557387891079495],
					[61.405216867049418, 4.0557387891079495],
					[61.405216866155044, 4.055738789249669],
					[61.405150166308232, 4.0556387885071734],
					[61.405150161632648, 4.0556387836185168],
					[61.405150152145644, 4.0556387701097307],
					[61.405150146575679, 4.0556387653627999],
					[61.405089047032888, 4.0555720657199572],
					[61.405089046964939, 4.0555720638542168],
					[61.405089045108312, 4.0555720622719171],
					[61.405089044213923, 4.0555720624136429],
					[61.405025143540236, 4.0555054616219577],
					[61.405025140721278, 4.0555054583156362],
					[61.405025133226765, 4.0555054501207106],
					[61.405025128619116, 4.0555054470978469],
					[61.404955730048655, 4.0554470474078075],
					[61.404886232323953, 4.0553859502649363],
					[61.404886232256004, 4.055385948399211],
					[61.404886231361665, 4.0553859485409331],
					[61.404886230467298, 4.0553859486826607],
					[61.404808430349711, 4.055319248832447],
					[61.404730740288194, 4.0552526575865517],
					[61.404661248936193, 4.0551748670710657],
					[61.404594557115516, 4.0550998757967083],
					[61.404527963863146, 4.0550054852094286],
					[61.404461264625347, 4.0549081863870144],
					[61.404461262768706, 4.054908184804785],
					[61.404461260844108, 4.0549081813568355],
					[61.404461258987453, 4.0549081797746043],
					[61.404380760173026, 4.0548026802022292],
					[61.404300160165256, 4.0546942811694384],
					[61.404300150746067, 4.0546942695269799],
					[61.404300131081335, 4.0546942482495201],
					[61.404300120009339, 4.0546942406219406],
					[61.404211220362242, 4.0546276402239885],
					[61.404122319767673, 4.054560940888428],
					[61.404122292189577, 4.0545609246182357],
					[61.404122232075032, 4.0545609041223996],
					[61.40412220043288, 4.0545608997550255],
					[61.404033300277277, 4.0545608990390116],
					[61.403947200349698, 4.0545608992296351],
					[61.403947174413418, 4.0545609033401835],
					[61.403947122744725, 4.0545609171582893],
					[61.403947097974573, 4.0545609285897948],
					[61.403872098689355, 4.0546054274140229],
					[61.403794300922748, 4.0546498253590366],
					[61.40379428688486, 4.0546498350895348],
					[61.403794260665755, 4.0546498561327065],
					[61.403794249446833, 4.0546498691693076],
					[61.403738751169406, 4.0547137678892335],
					[61.403683151768469, 4.0547748658840908],
					[61.403683145711838, 4.0547748724731987],
					[61.403683135455182, 4.0547748872336458],
					[61.403683130360854, 4.0547748955466751],
					[61.403633130024978, 4.0548554952117541],
					[61.403633129130604, 4.0548554953534888],
					[61.403633128304222, 4.0548554973608706],
					[61.403633126583443, 4.0548554995099915],
					[61.403585927228896, 4.0549359984151403],
					[61.403585925576067, 4.0549360024299199],
					[61.403585923096813, 4.0549360084520618],
					[61.403585921376042, 4.054936010601172],
					[61.403541521398459, 4.0550249110691601],
					[61.40349702058051, 4.0551138103664535],
					[61.403497018927702, 4.0551138143812082],
					[61.403497015622037, 4.0551138224106857],
					[61.403497013969208, 4.0551138264254432],
					[61.403455414077442, 4.0552193261055063],
					[61.403455414145363, 4.0552193279711499],
					[61.40345541331893, 4.0552193299785335],
					[61.403455411530231, 4.0552193302619735],
					[61.403416511989285, 4.0553249309029189],
					[61.403416511230823, 4.0553249347759328],
					[61.403416508751555, 4.0553249407980116],
					[61.403416507993107, 4.055324944671022],
					[61.403380407950046, 4.0554499444445131],
					[61.40334420760918, 4.0555721433229515],
					[61.403344206850733, 4.0555721471959298],
					[61.403344205469608, 4.0555721586731943],
					[61.403344203816765, 4.0555721626878887],
					[61.403322003679676, 4.0556888592895444],
					[61.403297004240599, 4.0558055582860488],
					[61.403297004376462, 4.0558055620173183],
					[61.403297002033028, 4.0558055717706232],
					[61.403297002168905, 4.0558055755019087],
					[61.40328040195844, 4.0559360748296118],
					[61.403263701826866, 4.0560666742727181],
					[61.403263701000405, 4.0560666762800537],
					[61.403244201335482, 4.0562388778718814],
					[61.40322480161224, 4.0564082713133338],
					[61.403194202711589, 4.0565943672435818],
					[61.403194201885164, 4.0565943692508917],
					[61.403194201953092, 4.056594371116522],
					[61.403166502931732, 4.0567805683233855],
					[61.403133103282144, 4.0569666624250598],
					[61.403097004124838, 4.0571527615057601],
					[61.403097004192752, 4.0571527633713886],
					[61.403097003298363, 4.0571527635130566],
					[61.403097003366277, 4.0571527653786825],
					[61.403063703286691, 4.0573388653519995],
					[61.403030402947863, 4.0575249649387057],
					[61.403030403015777, 4.0575249668043298],
					[61.403030403083683, 4.0575249686699522],
					[61.40299980283271, 4.0577110680071371],
					[61.402999802006235, 4.0577110700144088],
					[61.402972001728649, 4.0578971702190971],
					[61.402972001728649, 4.0578971702190971],
					[61.402972001864462, 4.0578971739503382],
					[61.402947002387194, 4.0580832730656766],
					[61.402922001825395, 4.0582693739013012],
					[61.402922001825395, 4.0582693739013012],
					[61.40292200099892, 4.0582693759085524],
					[61.402922001066806, 4.058269377774173],
					[61.402899800578766, 4.0584582753751581],
					[61.40287760062435, 4.058644376799001],
					[61.40287760062435, 4.058644376799001],
					[61.402877600692264, 4.0586443786646171],
					[61.402877600692264, 4.0586443786646171],
					[61.402858100848889, 4.0588305795138755],
					[61.402838701438839, 4.0590166797624141],
					[61.402838701438839, 4.0590166797624141],
					[61.402838700612342, 4.059016681769644],
					[61.402838700612342, 4.059016681769644],
					[61.402822001293416, 4.059202782065146],
					[61.402805400923654, 4.0593916828918637],
					[61.402805400923654, 4.0593916828918637],
					[61.402805400991539, 4.0593916847574807],
					[61.402791501040213, 4.0595638822414379],
					[61.402774800702808, 4.0597360800602633],
					[61.402760901350391, 4.0598749772707317],
					[61.40274420293489, 4.0600110657067683],
					[61.40271920600464, 4.0601193521500161],
					[61.402691509748884, 4.0602305368639513],
					[61.40264701632406, 4.0603360210601904],
					[61.402602626850907, 4.0604415954409516],
					[61.402533145651006, 4.060524872266627],
					[61.402533145651006, 4.060524872266627],
					[61.402466574669972, 4.0606054383368431],
					[61.402388819056213, 4.0606415169573085],
					[61.402311055396105, 4.0606748027993902],
					[61.402241692342841, 4.060677600670739],
					[61.402172214199709, 4.0606803998515755],
					[61.402111135683477, 4.0606692024917432],
					[61.402111134789131, 4.0606692026333002],
					[61.402111133894756, 4.0606692027748599],
					[61.402047233561461, 4.0606581030882847],
					[61.402047232667115, 4.0606581032298399],
					[61.402047230810538, 4.0606581016473609],
					[61.402047229021804, 4.0606581019304784],
					[61.401972228730244, 4.0606470029262569],
					[61.40189722926435, 4.0606359019598388],
					[61.401897223830304, 4.060635900943617],
					[61.40189721206788, 4.0606358990527305],
					[61.401897206701683, 4.060635899902076],
					[61.401811106998878, 4.0606331004430674],
					[61.401811106104532, 4.0606331005846217],
					[61.40172501178661, 4.0606303997214122],
					[61.401627817113003, 4.0606220012663758],
					[61.40153333360827, 4.0606137028505316],
					[61.401425050208957, 4.0605859063477521],
					[61.401425049314611, 4.0605859064893037],
					[61.401425046631516, 4.0605859069139729],
					[61.401425045669313, 4.0605859051899893],
					[61.401319459160163, 4.0605609073253408],
					[61.401216770871606, 4.0605220130575246],
					[61.401113975398026, 4.0604831153547565],
					[61.401030680634655, 4.0604470171829208],
					[61.400950088001927, 4.0604109192893825],
					[61.400877894910728, 4.0603720248743649],
					[61.400877893948518, 4.060372023150399],
					[61.40087789119756, 4.0603720217095747],
					[61.400877889340954, 4.0603720201271898],
					[61.400805696434304, 4.0603359252103539],
					[61.400736207057044, 4.0602942316180721],
					[61.400669511195332, 4.0602498333000874],
					[61.400669510301, 4.0602498334416444],
					[61.400669509338755, 4.0602498317177176],
					[61.400669508444395, 4.0602498318592772],
					[61.400600107680127, 4.0602054322048167],
					[61.400600106717903, 4.0602054304808739],
					[61.400600103966951, 4.0602054290400744],
					[61.400600103072591, 4.0602054291816341],
					[61.400530704262103, 4.0601637292987238],
					[61.400458405189895, 4.0601192296582802],
					[61.400458404295506, 4.0601192297998479],
					[61.400458403333289, 4.0601192280759308],
					[61.400458403333289, 4.0601192280759308],
					[61.400389002730449, 4.0600776282798927],
					[61.400389000873858, 4.0600776266975291],
					[61.400388997228525, 4.0600776253983168],
					[61.40038899537192, 4.0600776238159728],
					[61.400316794770944, 4.0600387244844773],
					[61.400316792914339, 4.0600387229021395],
					[61.400316791057762, 4.0600387213197973],
					[61.400316789269034, 4.060038721602929],
					[61.400244500872041, 4.0600026262651721],
					[61.40017511180136, 4.0599554359419283],
					[61.400175110839086, 4.0599554342180397],
					[61.400175108982509, 4.0599554326357099],
					[61.400175108088135, 4.0599554327772784],
					[61.400105731910038, 4.0599109460986176],
					[61.4000473499352, 4.0598442687650849],
					[61.399989061099234, 4.0597776801200265],
					[61.399939071058242, 4.0596970968753858],
					[61.399889071161468, 4.0596137968405994],
					[61.399889069236998, 4.0596137933928453],
					[61.399889062637087, 4.0596137850566389],
					[61.399889060712617, 4.0596137816089035],
					[61.399833462005347, 4.0595387828104981],
					[61.399780764205353, 4.0594637847185355],
					[61.399780752930006, 4.0594637714939692],
					[61.399780728658442, 4.0594637471934236],
					[61.399780715662224, 4.0594637361174577],
					[61.39971401556376, 4.0594165365914199],
					[61.399714014669392, 4.0594165367330008],
					[61.399714012812787, 4.0594165351507288],
					[61.399644513339389, 4.0593692348772183],
					[61.399644504192139, 4.0593692306967393],
					[61.399644487618474, 4.0593692201871603],
					[61.399644480259965, 4.0593692157235175],
					[61.399561179601662, 4.0593331154388945],
					[61.399561177812934, 4.0593331157220574],
					[61.399561175956343, 4.059333114139795],
					[61.399561174167609, 4.059333114422957],
					[61.399477883757058, 4.0592998175654778],
					[61.399388993672538, 4.0592526231842418],
					[61.399300111826712, 4.059205433382524],
					[61.399236228849873, 4.059152646575412],
					[61.399172352168414, 4.0590970672714892],
					[61.399130768429714, 4.0590331916964271],
					[61.399130766640994, 4.0590331919796006],
					[61.399089068623134, 4.0589692915094719],
					[61.399033471133606, 4.0588804964762106],
					[61.398980771627521, 4.0587915975369073],
					[61.398980765989783, 4.0587915909249084],
					[61.39898075361635, 4.0587915722463341],
					[61.398980747978634, 4.0587915656343299],
					[61.398894548131906, 4.0586970647979141],
					[61.398894547237575, 4.0586970649395049],
					[61.398894546275294, 4.0586970632157247],
					[61.398894546275294, 4.0586970632157247],
					[61.398808445607052, 4.058605462681971],
					[61.398808431716425, 4.058605451748285],
					[61.398808401184176, 4.0586054284403144],
					[61.398808384678361, 4.0586054197967973],
					[61.398713985683536, 4.0585609203173609],
					[61.398622287045839, 4.0585165207495102],
					[61.398622242611175, 4.0585165108995547],
					[61.398622155382974, 4.0585165115770465],
					[61.398622110732852, 4.0585165205223603],
					[61.398549910916763, 4.0585526204399374],
					[61.398549910022425, 4.0585526205815317],
					[61.398549908301597, 4.0585526227300841],
					[61.398549908301597, 4.0585526227300841],
					[61.398480507813346, 4.0585887239173539],
					[61.398480483178602, 4.0585887390741853],
					[61.398480440644811, 4.0585887814541897],
					[61.398480422677885, 4.0585888068120068],
					[61.398433123388003, 4.058677705040628],
					[61.398433122561528, 4.0586777070475915],
					[61.398433122629413, 4.0586777089129447],
					[61.398433121667182, 4.0586777071891822],
					[61.398385921578111, 4.0587693079037406],
					[61.3983859191666, 4.0587693157899096],
					[61.398385913381269, 4.0587693298385297],
					[61.398385910901837, 4.0587693358593526],
					[61.398347010705898, 4.0588832348978343],
					[61.398305412001967, 4.0589999303393514],
					[61.39825261270235, 4.0591388291818911],
					[61.398199815734884, 4.0592777215365645],
					[61.398119219656053, 4.0594443128560851],
					[61.398119219723938, 4.0594443147214205],
					[61.398119218829557, 4.0594443148629944],
					[61.398119218897442, 4.0594443167283476],
					[61.398041521782396, 4.0596110073634719],
					[61.397949827410947, 4.0597692996603811],
					[61.397858132796124, 4.0599276885871225],
					[61.397780439922755, 4.0600304790369508],
					[61.397780439990626, 4.0600304809022827],
					[61.397780439096245, 4.0600304810438379],
					[61.397780438201899, 4.0600304811853869],
					[61.397705467879128, 4.0601331422637177],
					[61.397630512613141, 4.0601692193133454],
					[61.397555575106153, 4.0602053890478587],
					[61.397463941652397, 4.060185904741636],
					[61.397463940758016, 4.0601859048831823],
					[61.397372274581379, 4.0601665102789672],
					[61.397272303953535, 4.0601054295309478],
					[61.39727230305914, 4.0601054296724968],
					[61.39727230209693, 4.0601054279487343],
					[61.397272301134684, 4.0601054262249949],
					[61.397172301225673, 4.0600470275738445],
					[61.39717229572377, 4.0600470246925404],
					[61.39717228657652, 4.0600470205121626],
					[61.397172282863338, 4.0600470173477667],
					[61.397080683036286, 4.0600054175899567],
					[61.397080682141933, 4.0600054177315021],
					[61.397080681247552, 4.0600054178730502],
					[61.397080680285306, 4.0600054161493251],
					[61.396991780082224, 4.0599665166790064],
					[61.396991741976038, 4.0599665077018088],
					[61.396991667268814, 4.0599665063933559],
					[61.396991627916869, 4.0599665126214539],
					[61.396919328201122, 4.0599942127270525],
					[61.396847128382454, 4.0600220131979814],
					[61.396847100237949, 4.0600220307842774],
					[61.396847051307383, 4.0600220704203256],
					[61.396847029694889, 4.0600220944768708],
					[61.396797029636026, 4.0601026952913104],
					[61.39674703033338, 4.0601831944144653],
					[61.396747026065192, 4.0601832007179324],
					[61.396747019385337, 4.060183214907088],
					[61.396747016011496, 4.0601832210690159],
					[61.396699815790505, 4.0602943228352251],
					[61.396699814896124, 4.0602943229767652],
					[61.396699814137506, 4.0602943268488243],
					[61.396699814137506, 4.0602943268488243],
					[61.396655414179008, 4.0604082268415098],
					[61.396605413683218, 4.0605332256556803],
					[61.396605413751075, 4.0605332275209296],
					[61.396605412924586, 4.0605332295277039],
					[61.396605412098069, 4.0605332315344871],
					[61.396558116150167, 4.0606610192794816],
					[61.39650542058034, 4.060766610813741],
					[61.396505420648211, 4.0607666126789832],
					[61.396505419753865, 4.060766612820502],
					[61.39650541892734, 4.0607666148272878],
					[61.396455432695696, 4.0608720849433393],
					[61.396394256332606, 4.0609359620951961],
					[61.396394255438231, 4.060935962236714],
					[61.396394252822994, 4.0609359645265037],
					[61.396394252822994, 4.0609359645265037],
					[61.396333179794098, 4.0610026334015261],
					[61.396252717805822, 4.061038716835534],
					[61.396172142708359, 4.0610748067134734],
					[61.396080569606575, 4.0610887011809123],
					[61.396080568712243, 4.0610887013224177],
					[61.395991684400506, 4.0611025998593462],
					[61.395902799995632, 4.0611025994204519],
					[61.395902798206926, 4.0611025997034655],
					[61.395902794629464, 4.0611026002695008],
					[61.39590279373509, 4.0611026004110116],
					[61.395811110295931, 4.0611054008892875],
					[61.395722225059629, 4.0610942012045159],
					[61.395722223270894, 4.0610942014875322],
					[61.395722219625561, 4.0610942001883528],
					[61.395722218731215, 4.0610942003298582],
					[61.395633335610889, 4.0610859024287249],
					[61.395541752765538, 4.0610609063844691],
					[61.395452862151345, 4.0610359103378064],
					[61.39536397267981, 4.0610026133129136],
					[61.395275081169032, 4.0609665160532202],
					[61.395194486586789, 4.0609276204985703],
					[61.395194485624579, 4.0609276187749126],
					[61.395194482873634, 4.0609276173342579],
					[61.395194481979232, 4.0609276174757722],
					[61.39511399742355, 4.0608915241813612],
					[61.395055710891754, 4.0608526334937514],
					[61.395055709035162, 4.0608526319116161],
					[61.395055704427669, 4.0608526288888491],
					[61.395055701676696, 4.0608526274482291],
					[61.394994531832914, 4.0608165454857872],
					[61.394939054763896, 4.0607498731876959],
					[61.394883468266215, 4.0606804908930432],
					[61.394811276635679, 4.0605416085809329],
					[61.3948112765678, 4.0605416067157742],
					[61.394811275673469, 4.0605416068572868],
					[61.394811275605598, 4.0605416049921468],
					[61.394736279618066, 4.0604027112155157],
					[61.394655783024362, 4.0602193204849915],
					[61.394655782956505, 4.0602193186198416],
					[61.394575183236803, 4.0600360182770565],
					[61.394575182274558, 4.0600360165534637],
					[61.39457517938785, 4.0600360113826657],
					[61.39457517842564, 4.0600360096590649],
					[61.394505778696967, 4.0598999110169931],
					[61.394439080015701, 4.0597638113212167],
					[61.39443906963475, 4.0597637979571619],
					[61.394439048737098, 4.0597637674988327],
					[61.394439038356147, 4.0597637541347948],
					[61.394386238134118, 4.059713755863271],
					[61.394386237171872, 4.0597137541396968],
					[61.394386235315274, 4.0597137525576619],
					[61.394386234353064, 4.059713750834085],
					[61.394330733695156, 4.0596637505222635],
					[61.394330683351406, 4.0596637266000579],
					[61.394330576594939, 4.0596637097298043],
					[61.39433052107659, 4.0596637166401992],
					[61.39427212140324, 4.059688716037976],
					[61.394213820714683, 4.0597137165540946],
					[61.394213798694963, 4.0597137294181014],
					[61.394213757610096, 4.0597137621819304],
					[61.394213739371473, 4.0597137800750911],
					[61.394141539262264, 4.0598109810233352],
					[61.394069240087418, 4.0599081803003996],
					[61.394069234166203, 4.0599081906166097],
					[61.394069223082447, 4.0599082073773323],
					[61.394069218055613, 4.0599082175520218],
					[61.393999819001387, 4.0600610167681035],
					[61.393927618704261, 4.0602138158458612],
					[61.393927617051261, 4.0602138198591016],
					[61.393927613745277, 4.060213827885562],
					[61.393927612092284, 4.0602138318987864],
					[61.393872012995395, 4.0603666295406704],
					[61.393813714079165, 4.0605166273843203],
					[61.393813713184819, 4.0605166275258284],
					[61.39377201323996, 4.0606249288580347],
					[61.39377201323996, 4.0606249288580347],
					[61.39377201330781, 4.060624930723117],
					[61.393730419813984, 4.0607360117553331],
					[61.393680430573554, 4.0608165938731426],
					[61.393627647258576, 4.0608998668844301],
					[61.393552673437924, 4.0609637449115583],
					[61.393474913279093, 4.0610248145547549],
					[61.393386056993172, 4.0610442052896332],
					[61.393297197143809, 4.0610636958824768],
					[61.393208336882019, 4.0610470025040044],
					[61.393208335093306, 4.0610470027869923],
					[61.39320833241019, 4.0610470032114785],
					[61.39320833055362, 4.0610470016294009],
					[61.393119430703315, 4.0610331020072845],
					[61.393119428020249, 4.0610331024317645],
					[61.39311942169185, 4.0610331015571797],
					[61.39311941894087, 4.0610331001166253],
					[61.393030619387382, 4.0610248007657166],
					[61.393030617598662, 4.0610248010487053],
					[61.392938917989284, 4.0610165009757875],
					[61.392938905400321, 4.0610165010916717],
					[61.392938878433682, 4.0610165016064048],
					[61.392938865018188, 4.0610165037288164],
					[61.392861066333403, 4.0610304025639827],
					[61.392780566447883, 4.0610442034486365],
					[61.392780545050975, 4.0610442087094718],
					[61.392780503422962, 4.0610442265497557],
					[61.392780484018338, 4.0610442371226929],
					[61.392722083667721, 4.0610859362494773],
					[61.392663784081769, 4.0611276361542741],
					[61.392663768458185, 4.0611276517562018],
					[61.392663740720565, 4.0611276805291139],
					[61.392663728674435, 4.0611276955650872],
					[61.392616529108331, 4.0612054956877248],
					[61.392616529176195, 4.0612054975527361],
					[61.39261652828182, 4.0612054976942256],
					[61.392572027892548, 4.0612804977561616],
					[61.392572026239492, 4.0612805017691551],
					[61.392572021144701, 4.061280510078122],
					[61.392572018597328, 4.0612805142325925],
					[61.392527618612213, 4.0613749158984911],
					[61.392483118605412, 4.0614693143501919],
					[61.39248311867324, 4.0614693162152005],
					[61.39248311695237, 4.0614693183631605],
					[61.39248311695237, 4.0614693183631605],
					[61.392441517845398, 4.0615638166262187],
					[61.392397021143104, 4.0616582102532153],
					[61.392352622842445, 4.0617443066719403],
					[61.392308138025719, 4.0618276798300483],
					[61.392249859376982, 4.0618859596011276],
					[61.392194300743739, 4.0619415167837918],
					[61.392113859912783, 4.0619581041623345],
					[61.392113859018423, 4.0619581043037973],
					[61.392033335309691, 4.0619747872269221],
					[61.391944501806286, 4.0619220289151796],
					[61.391855733098701, 4.0618692475109706],
					[61.391766856161446, 4.0617581755555587],
					[61.391766855199215, 4.0617581738320645],
					[61.391766855199215, 4.0617581738320645],
					[61.391677955785269, 4.0616498745659504],
					[61.391589057690737, 4.0615359767583454],
					[61.391589056796356, 4.0615359768998198],
					[61.391589055834125, 4.0615359751763425],
					[61.391500155956578, 4.0614248749204593],
					[61.391500149356773, 4.0614248665860231],
					[61.39150013443632, 4.0614248520650564],
					[61.391500126115631, 4.0614248458785163],
					[61.391411226329545, 4.0613526440212766],
					[61.391411225435199, 4.0613526441627483],
					[61.391411223578622, 4.0613526425807587],
					[61.391411222684241, 4.0613526427222366],
					[61.391322323078363, 4.0612831421999811],
					[61.391322300108612, 4.061283128952434],
					[61.39132225079517, 4.0612831086180972],
					[61.39132222534586, 4.0612831013897939],
					[61.391233324844933, 4.0612720021581472],
					[61.391233322988356, 4.0612720005761513],
					[61.391233320305275, 4.0612720010005789],
					[61.391233319410894, 4.061272001142056],
					[61.391144419074784, 4.0612637004227334],
					[61.39114438412669, 4.0612637040753672],
					[61.391144318147226, 4.0612637201394062],
					[61.391144286289304, 4.0612637345571994],
					[61.391063787729088, 4.0613192332595442],
					[61.390983190074607, 4.0613720332698602],
					[61.390983176930533, 4.061372042851624],
					[61.390983153461129, 4.0613720653205752],
					[61.390983142241431, 4.0613720783492351],
					[61.39091654337583, 4.0614581753893857],
					[61.390849843665976, 4.06154157581968],
					[61.390791546193469, 4.0616137735112625],
					[61.390730489877399, 4.0616859214999712],
					[61.39064995299973, 4.0617054066215168],
					[61.390649952105363, 4.0617054067629788],
					[61.390572217097294, 4.0617247899726836],
					[61.390469479791591, 4.0616804177038608],
					[61.390369505075675, 4.0616359276076635],
					[61.390269527057917, 4.0615554453738723],
					[61.390169533897833, 4.0614720513440785],
					[61.390080739375414, 4.0613859561846422],
					[61.390080736624448, 4.0613859547441811],
					[61.390080736624448, 4.0613859547441811],
					[61.389991836828784, 4.0613026544609498],
					[61.38999182940249, 4.0613026481332488],
					[61.389991813655477, 4.0613026356193398],
					[61.38999180622919, 4.0613026292916379],
					[61.389902905842895, 4.061247030104119],
					[61.389814006349404, 4.061191530483657],
					[61.389813985304095, 4.061191520683515],
					[61.389813940666016, 4.0611915052373799],
					[61.389813918035479, 4.0611915013147444],
					[61.389719418155138, 4.0611831001911707],
					[61.389625018245447, 4.0611748014525988],
					[61.389625002011208, 4.0611748002693639],
					[61.389624969746201, 4.0611748034974475],
					[61.389624953579769, 4.0611748041790712],
					[61.38951935374179, 4.0611998052990801],
					[61.389519351953048, 4.0611998055820191],
					[61.389519349337789, 4.0611998078712555],
					[61.389519348375572, 4.0611998061478971],
					[61.389416651434352, 4.0612276057561729],
					[61.389311054196085, 4.0612526042093746],
					[61.389311053369575, 4.0612526062156542],
					[61.389208269642715, 4.0612776002136393],
					[61.389113891423193, 4.0612830999173912],
					[61.389022214237876, 4.0612858992005529],
					[61.388938932823201, 4.0612720024029558],
					[61.388938931928813, 4.0612720025444231],
					[61.388852834933793, 4.0612581029043273],
					[61.388769439040324, 4.0612415048396215],
					[61.388769438078093, 4.0612415031163023],
					[61.388769433606278, 4.0612415038236147],
					[61.388769432711911, 4.0612415039650802],
					[61.388686133134485, 4.0612276027370369],
					[61.388686127700417, 4.0612276017210407],
					[61.388686117794556, 4.0612276014123356],
					[61.388686113254899, 4.0612276002548668],
					[61.388597213117016, 4.0612220006981357],
					[61.388597211260439, 4.0612219991162872],
					[61.388597207682956, 4.0612219996821404],
					[61.388597205894236, 4.0612219999650643],
					[61.388505605633689, 4.0612192000191687],
					[61.388505596622188, 4.0612191995690052],
					[61.388505579561411, 4.0612192003920127],
					[61.388505570617767, 4.0612192018066331],
					[61.388411074355261, 4.0612331017316494],
					[61.388316676781344, 4.0612442008442793],
					[61.388316669626434, 4.0612442019759589],
					[61.388316657173135, 4.061244205821164],
					[61.38831664995039, 4.0612442050880864],
					[61.388230549590432, 4.0612665068927027],
					[61.388144350147783, 4.0612887060787726],
					[61.388144345675954, 4.0612887067860637],
					[61.388144337694513, 4.0612887099239501],
					[61.388144335011425, 4.0612887103483226],
					[61.388072134929672, 4.0613137102230619],
					[61.388072132314413, 4.0613137125121943],
					[61.388072127842605, 4.0613137132194748],
					[61.388072126053864, 4.0613137135023898],
					[61.388002726366388, 4.0613415130259636],
					[61.388002721135848, 4.0613415176041796],
					[61.388002711365679, 4.0613415210249304],
					[61.388002706067326, 4.0613415237384025],
					[61.387924906893879, 4.0613831234686399],
					[61.387844308445771, 4.0614248225025937],
					[61.387844299637791, 4.0614248276465963],
					[61.387844282916227, 4.0614248377931075],
					[61.387844275896967, 4.0614248426542057],
					[61.387735975954335, 4.0615109430188214],
					[61.387627675949624, 4.061597042771643],
					[61.387627668103846, 4.0615970496388112],
					[61.387627655095379, 4.0615970629488283],
					[61.387627648143983, 4.0615970696745629],
					[61.387524847643768, 4.0617165709166079],
					[61.387422047837561, 4.0618359699600504],
					[61.387422042674899, 4.0618359764028078],
					[61.387422034138275, 4.0618359890054414],
					[61.387422029869946, 4.0618359953067493],
					[61.387355430057006, 4.0619442957885692],
					[61.38728873028488, 4.0620526941176189],
					[61.387288726843089, 4.0620526984127405],
					[61.387288721816056, 4.0620527085848357],
					[61.387288720162992, 4.0620527125970947],
					[61.387241520085261, 4.0621499138355963],
					[61.387241520085261, 4.0621499138355963],
					[61.387241519258723, 4.0621499158417214],
					[61.38724151843217, 4.0621499178478482],
					[61.387197017674644, 4.0622471176622694],
					[61.387197016780256, 4.0622471178036967],
					[61.387197016848091, 4.0622471196684069],
					[61.387152616937044, 4.0623471182537934],
					[61.387108119679169, 4.0624471131818449],
					[61.387060923185082, 4.062538806434012],
					[61.387010929000262, 4.0626304954077019],
					[61.38696373526713, 4.0626998871622506],
					[61.38691655347467, 4.0627692618083335],
					[61.386858179336421, 4.0628137413705838],
					[61.386799904087425, 4.0628581207316685],
					[61.386719334635671, 4.0628859111411701],
					[61.386719332846944, 4.0628859114239742],
					[61.386719329337275, 4.0628859138542603],
					[61.386719326654188, 4.0628859142784703],
					[61.38664164224425, 4.0629165078237168],
					[61.386549958930154, 4.0629359035968058],
					[61.386549958035793, 4.0629359037382065],
					[61.3865499581036, 4.0629359056028651],
					[61.386549957209219, 4.0629359057442684],
					[61.386461068714787, 4.0629554015106439],
					[61.386369382085356, 4.0629637009799087],
					[61.38636938119101, 4.0629637011213058],
					[61.38628059097335, 4.0629720001978971],
					[61.386191703056113, 4.0629719993096529],
					[61.386102812329241, 4.062969200822649],
					[61.386011120990119, 4.0629609002491431],
					[61.385922227909205, 4.0629498018805048],
					[61.385833332776791, 4.0629359021297846],
					[61.385741736198121, 4.062919202087321],
					[61.38574173172627, 4.0629192027943093],
					[61.385741722646962, 4.0629192004790626],
					[61.3857417190695, 4.0629192010446502],
					[61.385652818889007, 4.0629109000919597],
					[61.385563918775617, 4.0629026010393021],
					[61.385563906118819, 4.0629025992896599],
					[61.385563881903082, 4.0629026012427705],
					[61.385563870208436, 4.0629026012163187],
					[61.385472173229452, 4.0629165018141578],
					[61.385383275333098, 4.0629276025291841],
					[61.385383263706323, 4.0629276043673164],
					[61.38538324230931, 4.0629276096253832],
					[61.385383230682535, 4.0629276114635156],
					[61.385299930872655, 4.0629581122831953],
					[61.385299929978267, 4.0629581124245906],
					[61.385299929083921, 4.0629581125659833],
					[61.385299928257361, 4.06295811457196],
					[61.385213827579285, 4.0629915146577948],
					[61.385213824001795, 4.0629915152233647],
					[61.385213816914657, 4.0629915182190821],
					[61.385213814299391, 4.0629915205078335],
					[61.385144314040282, 4.0630248201869827],
					[61.385144311357152, 4.0630248206111581],
					[61.385144306953137, 4.0630248231827011],
					[61.385144305164395, 4.0630248234654847],
					[61.385077713126577, 4.0630609206174206],
					[61.385019322874413, 4.0630859151176972],
					[61.38496385292094, 4.0631081037509658],
					[61.384897186801787, 4.0631136994738828],
					[61.384830633928857, 4.0631164980998165],
					[61.38474727365054, 4.063083114892029],
					[61.384664007697815, 4.0630498275805307],
					[61.384577934348933, 4.0629720510411547],
					[61.384577933454601, 4.0629720511825385],
					[61.384577931598045, 4.0629720496007771],
					[61.384577930703649, 4.0629720497421706],
					[61.384491842655251, 4.0628970606995143],
					[61.384408454991501, 4.0627970736889081],
					[61.384327961748724, 4.0626970834102076],
					[61.384255768224527, 4.0625859908037967],
					[61.384255767330131, 4.0625859909451982],
					[61.384255767262317, 4.0625859890806773],
					[61.384255766367971, 4.0625859892220717],
					[61.384183470323642, 4.0624776943032073],
					[61.384122374252435, 4.0623721015451917],
					[61.38406407470184, 4.062269303690015],
					[61.384011276709252, 4.0621693061803326],
					[61.384011276709252, 4.0621693061803326],
					[61.384011275747064, 4.0621693044572371],
					[61.383958476037691, 4.0620721053858233],
					[61.383958474113278, 4.0620721019396289],
					[61.383958472121051, 4.0620720966289481],
					[61.383958470264488, 4.062072095047272],
					[61.383900170171557, 4.0619776955964708],
					[61.383841869930883, 4.0618831952473871],
					[61.383841868006485, 4.0618831918012335],
					[61.383841863195471, 4.0618831831858326],
					[61.383841860376727, 4.0618831798810904],
					[61.383766861189585, 4.0617831818807417],
					[61.383691861665795, 4.0616804822157588],
					[61.383691858847001, 4.0616804789110583],
					[61.383691853209463, 4.0616804723016307],
					[61.383691851285064, 4.0616804688555046],
					[61.383594551097097, 4.061569268413133],
					[61.383497350660129, 4.0614581672535497],
					[61.383497345090433, 4.0614581625086892],
					[61.383497333883177, 4.0614581511544827],
					[61.383497328313425, 4.0614581464096133],
					[61.383394527706685, 4.0613720465027985],
					[61.383394527706685, 4.0613720465027985],
					[61.383394526744453, 4.0613720447797643],
					[61.383394525850107, 4.0613720449211907],
					[61.383289025732822, 4.0612859449004741],
					[61.38328901005368, 4.0612859342535588],
					[61.383288975185835, 4.0612859153898704],
					[61.383288955997074, 4.0612859071731284],
					[61.383194456324546, 4.0612581077803904],
					[61.383194455430171, 4.0612581079218222],
					[61.383097255415734, 4.0612304075440608],
					[61.383097232785211, 4.0612304036220079],
					[61.383097187727692, 4.061230401371259],
					[61.383097166195064, 4.0612304029011321],
					[61.383016667261231, 4.061244203235475],
					[61.382933267390356, 4.0612581021885488],
					[61.382933264707241, 4.0612581026128387],
					[61.38293326023539, 4.0612581033199806],
					[61.382933257552317, 4.0612581037442634],
					[61.382855558202806, 4.0612748046283409],
					[61.38285555730846, 4.0612748047697647],
					[61.382855553731012, 4.061274805335473],
					[61.382855552836624, 4.061274805476903],
					[61.382774983010648, 4.0612941972417689],
					[61.382688915562909, 4.0612887011242114],
					[61.382600054871553, 4.0612804045888584],
					[61.382513986588648, 4.0612387202675295],
					[61.382513985626439, 4.0612387185445424],
					[61.382513983837711, 4.0612387188273953],
					[61.382513981981141, 4.0612387172458346],
					[61.382427893762774, 4.0611998227489838],
					[61.382347307028809, 4.0611498315200754],
					[61.382269507591097, 4.061099832928285],
					[61.382269500164803, 4.0610998266021259],
					[61.382269481870424, 4.0610998182443074],
					[61.382269472723209, 4.0610998140653907],
					[61.382191772865475, 4.0610692131214341],
					[61.382191771971087, 4.0610692132628659],
					[61.382191769220164, 4.0610692118227574],
					[61.382191767431394, 4.0610692121056235],
					[61.382113967103912, 4.0610415111251159],
					[61.382113939933724, 4.0610415060460499],
					[61.382113884970288, 4.0610415034869236],
					[61.382113857109239, 4.0610415041424526],
					[61.382038857156502, 4.0610581044403791],
					[61.381963857218608, 4.0610748040282649],
					[61.381963827081528, 4.0610748162943446],
					[61.381963773339336, 4.0610748472940168],
					[61.381963748975537, 4.0610748698978139],
					[61.381908149246243, 4.0611387694814907],
					[61.381849849354332, 4.0612054678035472],
					[61.381849840817736, 4.0612054804040296],
					[61.38184982457107, 4.0612055035991999],
					[61.381849817891016, 4.0612055177812119],
					[61.381805418048579, 4.0613027177792977],
					[61.381805418048579, 4.0613027177792977],
					[61.381805417222068, 4.0613027197850844],
					[61.381805416395522, 4.0613027217908968],
					[61.381763715874044, 4.0613999203317626],
					[61.381763715115376, 4.0613999242019299],
					[61.381763710982717, 4.0613999342308578],
					[61.381763710224014, 4.0613999381010295],
					[61.381727610298007, 4.0615110378244896],
					[61.381727610365857, 4.0615110396888534],
					[61.381727609471454, 4.0615110398302727],
					[61.381727608577108, 4.0615110399716841],
					[61.381691509668741, 4.0616249387787233],
					[61.381655409552863, 4.0617360379904133],
					[61.381655409620699, 4.0617360398547744],
					[61.381655408658496, 4.0617360381318202],
					[61.381619212976439, 4.061849927314328],
					[61.381574819259768, 4.0619443157640198],
					[61.381530432104285, 4.0620387872762631],
					[61.381472051677214, 4.0621026643900109],
					[61.381413782390105, 4.0621665311860822],
					[61.381347122918484, 4.0621942152196624],
					[61.381280568640591, 4.0622219968765929],
					[61.381219423189066, 4.0622165016065841],
					[61.38115835841532, 4.0622081060057971],
					[61.381088986875938, 4.0621748197438974],
					[61.381088985981606, 4.0621748198852838],
					[61.381088984125, 4.0621748183037427],
					[61.381088984125, 4.0621748183037427],
					[61.381016788608235, 4.0621415211155529],
					[61.380916795741776, 4.0620887248999535],
					[61.380819499225531, 4.0620331251794566],
					[61.380819495648076, 4.0620331257450282],
					[61.380819490146216, 4.0620331228647633],
					[61.380819488221775, 4.0620331194189143],
					[61.380711189129393, 4.0619804201223424],
					[61.380602890686397, 4.0619248222771667],
					[61.380602889792016, 4.0619248224185656],
					[61.380602887935446, 4.0619248208370555],
					[61.380602886973257, 4.0619248191141502],
					[61.380516786832487, 4.0618831198567689],
					[61.38051678497591, 4.0618831182752686],
					[61.380516782292808, 4.0618831186994564],
					[61.380516780436253, 4.0618831171179535],
					[61.380427880321676, 4.0618442176418172],
					[61.380427877570739, 4.0618442162017017],
					[61.380427873925441, 4.0618442149029974],
					[61.380427872068879, 4.0618442133215042],
					[61.380355672298592, 4.0618165132315864],
					[61.380355671404203, 4.0618165133729862],
					[61.380355669615476, 4.0618165136557804],
					[61.38035566954764, 4.0618165117914939],
					[61.380280669580777, 4.061788712649113],
					[61.380280638765299, 4.0617887062709288],
					[61.380280579330055, 4.0617887044174692],
					[61.380280547959366, 4.0617887075021049],
					[61.38016664792034, 4.0618192079120616],
					[61.380052747888527, 4.0618498074525471],
					[61.380052723117579, 4.0618498188686756],
					[61.380052674605686, 4.0618498452880782],
					[61.380052653683514, 4.0618498635957021],
					[61.379899854271244, 4.0620137646782473],
					[61.379747053747344, 4.0621776642741985],
					[61.379747041633181, 4.0621776774390774],
					[61.379747022974499, 4.0621777085133086],
					[61.379747013679086, 4.0621777249825382],
					[61.379649814227541, 4.0624193241725548],
					[61.379649814295362, 4.0624193260367987],
					[61.379649814295362, 4.0624193260367987],
					[61.379649812574456, 4.0624193281837959],
					[61.379555413197515, 4.062663828146607],
					[61.379555407547329, 4.0626638459143596],
					[61.37955540174881, 4.0626638843301652],
					[61.379555399676093, 4.0626639015324635],
				],
			],
		},
		{
			fldNpdidField: "43534",
			wlbNpdidWellbore: "1062",
			fldName: "EMBLA",
			fldCurrentActivitySatus: "Producing",
			wlbName: "2/7-20",
			fldDiscoveryYear: "1988",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43534",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43534",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "018",
			cmpLongName: "ConocoPhillips Skandinavia AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=1062",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "2410696",
			fldNpdidOwner: "20900",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Tananger",
			fldAreaGeometryWKT: [
				[
					[56.294849813768749, 3.2736250502970599],
					[56.294849826956828, 3.2736251017374745],
					[56.295313727391282, 3.274416800791812],
					[56.295313739907144, 3.2744168183396858],
					[56.295313768290882, 3.2744168480763176],
					[56.295313783339381, 3.2744168620067495],
					[56.296077682969383, 3.2749668622150971],
					[56.296077703306885, 3.2749668737338826],
					[56.296077746439991, 3.2749668915463643],
					[56.296077768267104, 3.2749668963665091],
					[56.297541668071233, 3.275202997186661],
					[56.297541685279597, 3.2752029994621261],
					[56.297541717684766, 3.2752029994581529],
					[56.297541733775525, 3.2752029970446439],
					[56.300172233575424, 3.2747445968565825],
					[56.300172235363277, 3.2747445965883673],
					[56.300172237151131, 3.2747445963201511],
					[56.300172238045057, 3.2747445961860429],
					[56.302658325781493, 3.2742695996128539],
					[56.304530555840039, 3.2741335037637556],
					[56.306386004713794, 3.2751390757272594],
					[56.306386017601618, 3.2751390818897166],
					[56.306386045090584, 3.275139092338299],
					[56.30638605879777, 3.2751390967585743],
					[56.307991658602305, 3.2754751967311972],
					[56.307991677598515, 3.2754751987388349],
					[56.307991717155105, 3.2754751976614744],
					[56.307991736002201, 3.2754751964528555],
					[56.309758336305748, 3.2751501963469623],
					[56.309758359398757, 3.2751501896427544],
					[56.309758402604764, 3.275150170203966],
					[56.309758423611662, 3.275150157335259],
					[56.31118902394779, 3.2740251570211019],
					[56.311189032588736, 3.2740251492462762],
					[56.311189050615383, 3.2740251303459424],
					[56.311189060001084, 3.2740251192204353],
					[56.31332515513342, 3.271186227750948],
					[56.315600126684039, 3.2686362583709356],
					[56.316719465843946, 3.2680112919604176],
					[56.317555592103197, 3.2678808047693151],
					[56.318333217640991, 3.2680695858366118],
					[56.319349850892216, 3.2687612405859183],
					[56.320072023879696, 3.2699722960153705],
					[56.32078592054846, 3.2714139884977018],
					[56.320785921591636, 3.2714139915807192],
					[56.32078592546582, 3.2714139974781498],
					[56.320785926434375, 3.2714139989525197],
					[56.321419219738743, 3.2725222886113245],
					[56.322613715887236, 3.2753528772560712],
					[56.322613742856149, 3.2753529153254597],
					[56.322613810351093, 3.2753529732295248],
					[56.322613849983171, 3.275352993198418],
					[56.322905549895452, 3.2754279947547444],
					[56.322905573435861, 3.2754279977003531],
					[56.322905619324466, 3.2754279972907971],
					[56.322905641747226, 3.2754279955443688],
					[56.32357784190733, 3.2752834956870123],
					[56.323577861424582, 3.2752834895167413],
					[56.323577896734193, 3.2752834744955188],
					[56.323577914239856, 3.2752834637673502],
					[56.324152914193604, 3.2748834642672096],
					[56.324152919408, 3.2748834602442396],
					[56.324152928942866, 3.2748834523325421],
					[56.324152933263342, 3.2748834484438132],
					[56.325625130752812, 3.273566851610104],
					[56.328022324854444, 3.271566855813822],
					[56.331089021011501, 3.2692279585780439],
					[56.331089021905413, 3.269227958443651],
					[56.331089022799326, 3.2692279583092585],
					[56.331089023618574, 3.2692279565657776],
					[56.334441823649641, 3.2665862562748589],
					[56.334441834897021, 3.2665862464806601],
					[56.334441854635308, 3.2665862256864791],
					[56.334441864839356, 3.2665862128083423],
					[56.337125165172338, 3.2626862126714271],
					[56.337125166885414, 3.2626862107929591],
					[56.337125171875066, 3.2626862019389353],
					[56.337125174407277, 3.2626861983165845],
					[56.337955764249614, 3.2612195177296024],
					[56.338752943914898, 3.2603251385932221],
					[56.341039038073419, 3.2581557442269378],
					[56.341039042393284, 3.2581557403344981],
					[56.341039051033, 3.2581557325496076],
					[56.34103905527806, 3.2581557270477046],
					[56.344233455151191, 3.2542612271154931],
					[56.344233455970226, 3.2542612253710947],
					[56.344233460215079, 3.2542612198682721],
					[56.344233461853115, 3.2542612163794828],
					[56.346122361917494, 3.2516584173130063],
					[56.346122362736502, 3.251658415568464],
					[56.346752963003489, 3.2507695155243654],
					[56.346752963822453, 3.2507695137797636],
					[56.346752967248158, 3.2507695100207821],
					[56.346752968067129, 3.2507695082761963],
					[56.347575167854806, 3.2494918075116352],
					[56.347575175450167, 3.2494917966388939],
					[56.347575186840551, 3.2494917706040165],
					[56.347575191529444, 3.2494917553069427],
					[56.34769459178505, 3.2490861565767091],
					[56.34769459416696, 3.2490861497329959],
					[56.34769459714299, 3.2490861363154364],
					[56.347694597737117, 3.2490861297416092],
					[56.34785849759124, 3.2479750294699774],
					[56.347858499079194, 3.2479750227611297],
					[56.347858500342284, 3.2479750112231232],
					[56.347858500117432, 3.2479750063939603],
					[56.347886299751053, 3.2469972056592802],
					[56.347886300420043, 3.2469972006951098],
					[56.347886299895293, 3.2469971894270628],
					[56.347886298776501, 3.2469971847328924],
					[56.347783498576874, 3.2456638854153557],
					[56.347783499395767, 3.2456638836706162],
					[56.347783499170816, 3.2456638788414685],
					[56.34778349909584, 3.2456638772317583],
					[56.347675198594928, 3.2447305774382467],
					[56.347675197551055, 3.2447305743538744],
					[56.347675198294951, 3.2447305709994181],
					[56.347675197251085, 3.2447305679150622],
					[56.347378033044357, 3.2428723924810967],
					[56.348933266184893, 3.2428252007444707],
					[56.350222097351995, 3.2433195750863164],
					[56.350997070949518, 3.2439751532527152],
					[56.350997083019919, 3.2439751611556682],
					[56.350997108054607, 3.2439751768264879],
					[56.350997121018878, 3.2439751845943574],
					[56.35168322055997, 3.2442723841652987],
					[56.351683250582859, 3.2442723909767257],
					[56.351683313010227, 3.2442723977548042],
					[56.351683345264703, 3.244272394501746],
					[56.352416744858417, 3.2441029952800733],
					[56.35241677576898, 3.2441029825024175],
					[56.352416831701824, 3.24410294648832],
					[56.352416856724091, 3.2441029232518632],
					[56.353141856969657, 3.2431890239226631],
					[56.353141861213963, 3.2431890184172851],
					[56.353141869627557, 3.2431890057966224],
					[56.353141873796858, 3.2431889986813145],
					[56.354477961715936, 3.2408362206111141],
					[56.354869521673592, 3.2404195616381704],
					[56.355308282771318, 3.2401946345515471],
					[56.356244253451663, 3.2408668410409032],
					[56.356724827669069, 3.2415973029199954],
					[56.357158123718442, 3.2423917967474174],
					[56.357158128412884, 3.2423918009019532],
					[56.357158134301343, 3.2423918113617165],
					[56.357158138176956, 3.2423918172615029],
					[56.357563738351203, 3.2429529164767561],
					[56.357563746027353, 3.2429529266664492],
					[56.357563764955152, 3.2429529465052336],
					[56.357563775312926, 3.2429529562894821],
					[56.358302674924268, 3.2435473567937105],
					[56.358302691614121, 3.2435473672425204],
					[56.358302729388178, 3.2435473858543453],
					[56.358302747715726, 3.2435473928125962],
					[56.359019348113264, 3.2437390922120999],
					[56.359019367184658, 3.2437390958150618],
					[56.359019405102387, 3.2437390981904359],
					[56.359019424023749, 3.243739098573025],
					[56.360188923738683, 3.2435973990942184],
					[56.360188931708521, 3.2435973962677207],
					[56.36018894690433, 3.2435973939703255],
					[56.360188953980298, 3.2435973912789673],
					[56.361447253825972, 3.2432418927108007],
					[56.361447254719849, 3.2432418925756439],
					[56.361447257401458, 3.2432418921701771],
					[56.361884095656791, 3.2431108024208553],
					[56.361880410152182, 3.2431221387743134],
					[56.361880406283049, 3.2431221523325116],
					[56.361880400557595, 3.2431221840095077],
					[56.361880400414002, 3.2431222002476705],
					[56.361880399582816, 3.2431444006909285],
					[56.361880478800934, 3.2431444973627328],
					[56.361880670633923, 3.2431445380868213],
					[56.361880783173774, 3.2431444805287946],
					[56.361897328617388, 3.2431068328037442],
					[56.361919456794368, 3.2431001916391673],
					[56.361919497162162, 3.2431001693187183],
					[56.361919561801614, 3.2431001076519497],
					[56.361919587936043, 3.2431000696456045],
					[56.362022387957815, 3.242822268631619],
					[56.362022385400252, 3.242822194422418],
					[56.362022329040869, 3.2428220667262058],
					[56.362022199703716, 3.2428220149315439],
					[56.362022070699233, 3.2428220668722592],
					[56.362022017380113, 3.2428221203412524],
					[56.361897036867596, 3.2431065023187782],
					[56.361884713003839, 3.2431101998760701],
					[56.362069590217075, 3.2425417622304158],
					[56.362069591035919, 3.2425417604849378],
					[56.362069591704675, 3.2425417555188338],
					[56.362069593417374, 3.2425417536381818],
					[56.362464091064396, 3.2411083595086185],
					[56.363308489283483, 3.2386194644991502],
					[56.363308490027165, 3.238619461143172],
					[56.363308492408407, 3.2386194542959745],
					[56.363308493152097, 3.2386194509399968],
					[56.363786292620688, 3.2368194503187895],
					[56.363786294258183, 3.2368194468274356],
					[56.363786295670351, 3.2368194385049849],
					[56.363786297232735, 3.2368194334032561],
					[56.364119596722439, 3.2348972348434746],
					[56.364119598209605, 3.2348972281312598],
					[56.364119598577517, 3.2348972167232972],
					[56.364119600139823, 3.2348972116214663],
					[56.364241900385672, 3.2326639117325153],
					[56.364241899784354, 3.2326638988495509],
					[56.364241897763023, 3.2326638748294285],
					[56.364241896192681, 3.232663860471511],
					[56.363797396166348, 3.2304027604031522],
					[56.363797394002674, 3.2304027526223651],
					[56.363797388706296, 3.2304027355859106],
					[56.363797385573577, 3.2304027263302491],
					[56.362819585806399, 3.2279471252541923],
					[56.362819584837325, 3.2279471237794177],
					[56.36281958282396, 3.2279471192195781],
					[56.362819581854879, 3.2279471177447898],
					[56.362083481972732, 3.2263110178554353],
					[56.36208347794588, 3.2263110087360913],
					[56.362083467210645, 3.2263109909040715],
					[56.362083462365199, 3.2263109835305381],
					[56.361569561925634, 3.2256026827345914],
					[56.361569547690188, 3.2256026670555182],
					[56.361569517657351, 3.2256026407992149],
					[56.361569500815612, 3.2256026271370866],
					[56.360847300662051, 3.2251804263945947],
					[56.360847264155723, 3.2251804157155508],
					[56.360847188913127, 3.2251804044254326],
					[56.360847151145933, 3.2251804052889721],
					[56.359997151256032, 3.2253970061429422],
					[56.359997134423516, 3.2253970119390458],
					[56.35999710180284, 3.225397026616005],
					[56.35999708601468, 3.2253970354968762],
					[56.359224886248718, 3.2259304353715588],
					[56.359224870686234, 3.2259304490824396],
					[56.359224843211933, 3.2259304775720983],
					[56.359224831300104, 3.2259304923509151],
					[56.358324835148942, 3.2273581866422436],
					[56.357116550016002, 3.2290081661865937],
					[56.356369273941539, 3.22969154327363],
					[56.355566597528835, 3.2302665263238977],
					[56.353408228229931, 3.2313081121885121],
					[56.352833244280177, 3.2314748073920811],
					[56.352833243386321, 3.2314748075274675],
					[56.352833241673792, 3.231474809408124],
					[56.351902742394799, 3.2317554085093567],
					[56.351902740607095, 3.2317554087801077],
					[56.351902739713246, 3.2317554089154816],
					[56.351902738894552, 3.2317554106607198],
					[56.351099938561148, 3.2320137100630633],
					[56.351099934091856, 3.2320137107398939],
					[56.351099926122323, 3.2320137135679952],
					[56.351099920834365, 3.2320137159899986],
					[56.350405520781493, 3.2323109155068046],
					[56.350405518175087, 3.2323109175226499],
					[56.35040551020554, 3.2323109203506295],
					[56.350405506705265, 3.2323109225018345],
					[56.349761008961103, 3.2326498222362474],
					[56.349163825368315, 3.2329442135303457],
					[56.348433269914963, 3.233177599374125],
					[56.347419417649505, 3.2331637003908043],
					[56.346461162269755, 3.2330026086594974],
					[56.345475105869539, 3.2325220288008683],
					[56.344250130137681, 3.2315804465267246],
					[56.34339453669935, 3.2307776535197501],
					[56.343394533942615, 3.2307776523163323],
					[56.343394529172642, 3.2307776465551608],
					[56.343394526340752, 3.2307776437422566],
					[56.342205726183451, 3.229816543347265],
					[56.34220572528961, 3.2298165434826367],
					[56.341052924889304, 3.2288942441995507],
					[56.341052919225412, 3.2288942385743411],
					[56.341052906260323, 3.2288942308134696],
					[56.341052898808726, 3.2288942254590465],
					[56.340069499091761, 3.2283304260934726],
					[56.340069486201848, 3.2283304199423069],
					[56.340069457815659, 3.2283304096555034],
					[56.340069443213231, 3.2283304053844826],
					[56.339138943054841, 3.2281248052829352],
					[56.339138941191919, 3.228124803944433],
					[56.339138935753624, 3.2281248031475047],
					[56.339138933072086, 3.2281248035536882],
					[56.338283332665632, 3.2279804038119893],
					[56.338283330802717, 3.2279804024735177],
					[56.337477863133962, 3.2278554072659773],
					[56.336780724714451, 3.2274942391467953],
					[56.336208452669759, 3.2268526718160411],
					[56.335644556413925, 3.2261470757255126],
					[56.335644553581929, 3.2261470729134958],
					[56.335644550674715, 3.226147068492319],
					[56.335644548736553, 3.2261470655448785],
					[56.335050149025861, 3.2254859664797362],
					[56.335050143361862, 3.2254859608558659],
					[56.33505013113998, 3.2254859497435819],
					[56.335050124582111, 3.2254859442551509],
					[56.334239025033696, 3.2248331437528677],
					[56.334239015719042, 3.2248331370619163],
					[56.334238996271083, 3.2248331254245475],
					[56.334238985168724, 3.2248331190044919],
					[56.333388984782211, 3.2244331189365569],
					[56.33338896742292, 3.224433113464912],
					[56.333388930022778, 3.2244331029279798],
					[56.33338891095103, 3.2244330993362831],
					[56.332608310595774, 3.2243914993965865],
					[56.33260828459899, 3.2243915017155862],
					[56.332608233800265, 3.2243915126542286],
					[56.332608209967418, 3.2243915227474158],
					[56.331894310291212, 3.2247526224041509],
					[56.331894295396786, 3.2247526311425254],
					[56.3318942657584, 3.2247526518372243],
					[56.331894252802137, 3.2247526635226911],
					[56.330383153152496, 3.2263915642088148],
					[56.330383152258655, 3.226391564344206],
					[56.33038315062138, 3.2263915678328341],
					[56.330383149727531, 3.2263915679682271],
					[56.329555453183936, 3.2273276638462653],
					[56.329133179807634, 3.2277665365447019],
					[56.328163838940142, 3.2282387065224474],
					[56.32752218512163, 3.2283442001223648],
					[56.327002801872318, 3.2283387003210584],
					[56.327002800978462, 3.2283387004563853],
					[56.327002800084614, 3.2283387005917139],
					[56.32630559976328, 3.228338699564667],
					[56.326305594400161, 3.2283387003766157],
					[56.326305581811049, 3.2283387006624009],
					[56.326305576447915, 3.2283387014743514],
					[56.325611075698056, 3.2284220005603661],
					[56.325611062365446, 3.2284220041988809],
					[56.325611035700213, 3.2284220114759323],
					[56.325611022442793, 3.2284220167231772],
					[56.32474162226459, 3.2287887163827418],
					[56.32474161340123, 3.2287887193444966],
					[56.324741595900058, 3.2287887300941001],
					[56.324741587111873, 3.2287887346645503],
					[56.324188786964427, 3.2291665348591936],
					[56.32418878614574, 3.2291665366031603],
					[56.324188781676483, 3.2291665372796299],
					[56.32418878085781, 3.2291665390235944],
					[56.323444280956259, 3.2297192397275967],
					[56.323444278349868, 3.2297192417420852],
					[56.323444273137063, 3.2297192457710353],
					[56.323444271424528, 3.2297192476502303],
					[56.32269159073951, 3.2303554312753695],
					[56.321502717102824, 3.2309220174191355],
					[56.320411019621879, 3.2313998164810256],
					[56.32041100904587, 3.2313998213207373],
					[56.320410988044124, 3.2313998342172163],
					[56.32041097761838, 3.2313998422739845],
					[56.319355477857826, 3.2322165425229517],
					[56.319355470857197, 3.232216546821495],
					[56.319355457900102, 3.2322165585003448],
					[56.319355451943601, 3.232216565880655],
					[56.318641552008138, 3.2330026655514597],
					[56.318641542626395, 3.233002676689106],
					[56.318641526694734, 3.233002701775785],
					[56.31864151917582, 3.2330027142515307],
					[56.316722020346134, 3.2370499146169256],
					[56.316524841665554, 3.2374581666127469],
					[56.315258215173429, 3.2383665143688289],
					[56.313361052717163, 3.2388248060595171],
					[56.313361047353929, 3.2388248068696011],
					[56.31336103580864, 3.2388248102330204],
					[56.313361031414317, 3.2388248125163486],
					[56.312727731171535, 3.2390581124102806],
					[56.312727727596048, 3.2390581129502998],
					[56.312727723201718, 3.2390581152335431],
					[56.312727721413971, 3.2390581155035538],
					[56.311938821340483, 3.2393970157223602],
					[56.311938811657953, 3.239397020423652],
					[56.311938794155665, 3.2393970311644527],
					[56.311938785442031, 3.2393970373389558],
					[56.311119284881492, 3.2399748371637371],
					[56.311119277880543, 3.2399748414598459],
					[56.311119265741439, 3.2399748513902771],
					[56.311119259709386, 3.2399748571595772],
					[56.310452659721854, 3.2406304570032196],
					[56.310452654583642, 3.2406304626373554],
					[56.310452646094987, 3.240630473635691],
					[56.31045264095679, 3.2406304792698082],
					[56.309691540622126, 3.241627678523221],
					[56.309691535558862, 3.2416276857652035],
					[56.30969152722011, 3.2416276999793028],
					[56.309691523050745, 3.2416277070863373],
					[56.309308122739957, 3.2423610077586824],
					[56.309308118645539, 3.2423610164736489],
					[56.30930811224443, 3.2423610336337916],
					[56.309308109043883, 3.2423610422138598],
					[56.30873870935104, 3.2442249412017552],
					[56.308738707638241, 3.2442249430795611],
					[56.308738706894296, 3.2442249464305553],
					[56.308738707044235, 3.2442249496466831],
					[56.307974806898457, 3.247111048803442],
					[56.3079748060795, 3.2471110505462764],
					[56.307974805335476, 3.2471110538971466],
					[56.307974805410403, 3.2471110555052038],
					[56.307335910157768, 3.2498860353872967],
					[56.306688738399238, 3.2513081745395427],
					[56.306222136364944, 3.251680395578203],
					[56.305380613621089, 3.2516219994011242],
					[56.305380609151641, 3.2516220000744931],
					[56.305380600137845, 3.2516219998132923],
					[56.305380594774498, 3.2516220006213352],
					[56.304602794741513, 3.2516415002217047],
					[56.304602792059839, 3.2516415006257158],
					[56.304602784908688, 3.2516415017030753],
					[56.304602781258239, 3.2516415006338426],
					[56.30358888126986, 3.2517359006740119],
					[56.303588869649246, 3.2517359024246226],
					[56.303588848270678, 3.2517359072644045],
					[56.30358883672492, 3.2517359106228865],
					[56.303024936692118, 3.2519220106318687],
					[56.303024918144942, 3.2519220182831634],
					[56.303024883137809, 3.2519220397478645],
					[56.303024865634264, 3.2519220504802098],
					[56.302610965559602, 3.2522942510646171],
					[56.302610957813982, 3.2522942587077499],
					[56.302610944185382, 3.2522942753325568],
					[56.302610937408524, 3.2522942844488743],
					[56.302180437078199, 3.2529026841811826],
					[56.30218043365231, 3.2529026879353218],
					[56.302180428663192, 3.2529026967821713],
					[56.3021804270251, 3.2529027002670534],
					[56.301930445938815, 3.2533331666387979],
					[56.301624896345345, 3.2535748267177307],
					[56.301149938947134, 3.2537804082924549],
					[56.300574958261919, 3.2539026036401149],
					[56.300574952079465, 3.2539026061900538],
					[56.300574937851962, 3.2539026099513433],
					[56.300574931669516, 3.2539026125012689],
					[56.29974993164199, 3.2542026112745619],
					[56.299749909668705, 3.2542026226777856],
					[56.299749870416107, 3.2542026496344456],
					[56.299749851348984, 3.2542026654570733],
					[56.298772053755627, 3.2552915630984995],
					[56.298347059961081, 3.2557276571162772],
					[56.297097063673135, 3.2568887532016575],
					[56.297097056821102, 3.2568887607081876],
					[56.297097043116985, 3.2568887757212401],
					[56.297097037233634, 3.2568887847008736],
					[56.296408136922466, 3.2578637853879302],
					[56.296408127687762, 3.2578637997279349],
					[56.296408112868733, 3.257863829477631],
					[56.296408108253118, 3.2578638463603906],
					[56.296341507648386, 3.2580999460307729],
					[56.296341506159685, 3.2580999527300576],
					[56.29634150310752, 3.2580999645210738],
					[56.296341501618812, 3.2580999712203589],
					[56.29623040194393, 3.2588666706918827],
					[56.296230402168234, 3.2588666755146383],
					[56.29623040075424, 3.258866683821473],
					[56.296230400084632, 3.258866688778673],
					[56.296146999961607, 3.2603527882343366],
					[56.296147000111105, 3.2603527914494985],
					[56.296147000260596, 3.2603527946646764],
					[56.296147000335345, 3.2603527962722567],
					[56.296130400157018, 3.2612666962572621],
					[56.296130400904374, 3.2612667123331214],
					[56.29613040500606, 3.2612667424740343],
					[56.296130409329066, 3.2612667580122818],
					[56.296558109227085, 3.26267505781877],
					[56.296558113400479, 3.2626750701421487],
					[56.29655812435427, 3.2626750927781836],
					[56.296558130315468, 3.2626751048328084],
					[56.297122030101363, 3.2635918043310967],
					[56.297122030176077, 3.2635918059387263],
					[56.297122031144688, 3.2635918074120061],
					[56.2971220321133, 3.2635918088853013],
					[56.29766092797496, 3.2644251036910221],
					[56.297997015964505, 3.2650306806134446],
					[56.298202602602856, 3.2656611402622868],
					[56.298266496593996, 3.2663777758446804],
					[56.297322016090199, 3.2692055197261207],
					[56.295860925716518, 3.2719193024825386],
					[56.294849828287688, 3.2736248975747881],
					[56.294849813540253, 3.2736249483498159],
					[56.294849813768749, 3.2736250502970599],
				],
			],
		},
		{
			fldNpdidField: "1272071",
			wlbNpdidWellbore: "2635",
			fldName: "GLITNE",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "15/5-5",
			fldDiscoveryYear: "1995",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=1272071",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=1272071",
			fldOwnerKind: "",
			fldOwnerName: "",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=2635",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Dusavik",
			fldAreaGeometryWKT: [
				[
					[58.677813700983528, 1.6168500076170349],
					[58.6778137008816, 1.6168500214871573],
					[58.677819201454085, 1.6169028215067518],
					[58.67781920066205, 1.6169028234061664],
					[58.677819201947017, 1.6169028300572643],
					[58.677819203034794, 1.6169028332881894],
					[58.67784980298967, 1.6170861329801687],
					[58.677849803186838, 1.6170861364003877],
					[58.677849804373217, 1.6170861413414033],
					[58.677849805461015, 1.6170861445723173],
					[58.677947005409308, 1.6175194445727314],
					[58.677947006694225, 1.6175194512238804],
					[58.677947013220916, 1.6175194706095217],
					[58.67794701638568, 1.6175194785922258],
					[58.678024815629939, 1.6177028786400434],
					[58.678024819685312, 1.6177028864335106],
					[58.678024829774451, 1.6177029050621197],
					[58.678024835709671, 1.6177029141871342],
					[58.67818313604613, 1.6179307137528192],
					[58.678183150588367, 1.6179307314352036],
					[58.678183184819439, 1.6179307622440935],
					[58.678183203617678, 1.6179307755598704],
					[58.678305504127245, 1.6179973763702391],
					[58.678305528072073, 1.6179973851301945],
					[58.67830557833787, 1.6179973969518884],
					[58.678305602877579, 1.6179974003921862],
					[58.678466703429493, 1.6179946001707988],
					[58.678466704320108, 1.6179945999815271],
					[58.678591704199796, 1.6179918999672218],
					[58.678591721220243, 1.6179918980811321],
					[58.678591755162557, 1.6179918925988259],
					[58.678591771095221, 1.6179918874817365],
					[58.67875286334656, 1.6179307905810927],
					[58.678872254223698, 1.6178973935542058],
					[58.678872255015747, 1.6178973916547579],
					[58.678872256797, 1.6178973912761956],
					[58.678872257687644, 1.6178973910869128],
					[58.679119452073088, 1.6178223927901658],
					[58.679202723724543, 1.617803022860431],
					[58.679438745132479, 1.6180918275500764],
					[58.679438748892139, 1.6180918302133409],
					[58.679438756707128, 1.6180918406704068],
					[58.679438761357396, 1.6180918431443823],
					[58.679658128180883, 1.6183029119216614],
					[58.679730412631301, 1.6184972701186515],
					[58.679730412729867, 1.6184972718288675],
					[58.679730413719064, 1.6184972733497929],
					[58.679730414708239, 1.6184972748707325],
					[58.679947015117655, 1.6190306750428083],
					[58.679947015216214, 1.6190306767530402],
					[58.680077610766951, 1.6193472658796995],
					[58.680091507267257, 1.6193972537499088],
					[58.680119206832501, 1.6195000522142065],
					[58.680119209997152, 1.6195000601975942],
					[58.680119216425005, 1.6195000778745827],
					[58.680119218699026, 1.6195000860472122],
					[58.680449816823497, 1.6201944813508624],
					[58.680574816036177, 1.6204917776085366],
					[58.680574819200778, 1.620491785592109],
					[58.680574826322058, 1.6204917996597945],
					[58.680574830278744, 1.6204918057438855],
					[58.680916529671343, 1.6210390069161462],
					[58.680916532638825, 1.6210390114793118],
					[58.680916540355092, 1.6210390202272114],
					[58.680916543322589, 1.6210390247903816],
					[58.681033136936136, 1.621186217130246],
					[58.681483120767673, 1.6218778902433804],
					[58.681491510234444, 1.6219000631069371],
					[58.681724794852734, 1.6227306105827062],
					[58.681672003242994, 1.6230527663794365],
					[58.681610903210334, 1.6233971644550942],
					[58.68161090330883, 1.6233971661654025],
					[58.68161090162608, 1.6233971682540331],
					[58.681610901724582, 1.623397169964343],
					[58.681580401732148, 1.6235999695207135],
					[58.681580401929153, 1.6235999729413333],
					[58.681580401334031, 1.6235999782614137],
					[58.681580401432527, 1.6235999799717427],
					[58.681569200949944, 1.6237110766206428],
					[58.681513701742716, 1.6241527758483709],
					[58.681483102277035, 1.624397170516483],
					[58.681472002912685, 1.6244638668003415],
					[58.681472000930192, 1.624463879339912],
					[58.681472000330807, 1.6244639002419052],
					[58.681472000129581, 1.6244639124032108],
					[58.681477600166176, 1.62455001332434],
					[58.681477623305781, 1.6245500707346863],
					[58.681477697002009, 1.6245501606898483],
					[58.681477748449261, 1.6245501930455357],
					[58.681519299178383, 1.6245612804760949],
					[58.681630460038214, 1.6246668423770954],
					[58.681780458878769, 1.6248168410929369],
					[58.681780510030521, 1.6248168683182798],
					[58.68178062035831, 1.6248168899027118],
					[58.681780677753089, 1.6248168846400515],
					[58.6819722424124, 1.624736299092524],
					[58.68212220370858, 1.6247334997595833],
					[58.68212220459921, 1.6247334995704563],
					[58.682122207369616, 1.6247335007134061],
					[58.682122208260246, 1.624733500524276],
					[58.682266707636693, 1.6247280002570654],
					[58.682266728219844, 1.6247279976174116],
					[58.682266767309422, 1.6247279875853284],
					[58.682266784925197, 1.6247279803820049],
					[58.682419443141463, 1.6246558005028788],
					[58.682541696205035, 1.624658500369845],
					[58.682541697095679, 1.6246585001807132],
					[58.682738874730894, 1.6246613000202021],
					[58.682869297260403, 1.624691880496826],
					[58.683063716672045, 1.6248944977154045],
					[58.683147005315881, 1.625261143878697],
					[58.683147005611325, 1.6252611490098969],
					[58.683147008874101, 1.6252611587048957],
					[58.683147010060196, 1.6252611636469718],
					[58.683216501988596, 1.625472237746491],
					[58.683246999814919, 1.6259583130853605],
					[58.683246999913386, 1.6259583147957628],
					[58.683258100234717, 1.6261194145035109],
					[58.683258101125347, 1.6261194143144049],
					[58.683258101322288, 1.6261194177352074],
					[58.683258101322288, 1.6261194177352074],
					[58.683269199943446, 1.6262444113712988],
					[58.683280400272764, 1.6268639015155062],
					[58.683280400113937, 1.6269832996944487],
					[58.683280400310842, 1.6269833031152641],
					[58.683280399715564, 1.6269833084355814],
					[58.683280399814024, 1.626983310145985],
					[58.683285900439557, 1.6270917101451583],
					[58.683285899548927, 1.6270917103342311],
					[58.68328589974584, 1.6270917137550576],
					[58.68328590073493, 1.6270917152763917],
					[58.683341500574336, 1.6278278148594649],
					[58.683341500968126, 1.6278278217011275],
					[58.683341503340095, 1.6278278315854979],
					[58.683341503733878, 1.6278278384271636],
					[58.683344200370499, 1.6278417190033383],
					[58.683344200405848, 1.6278555971984638],
					[58.683319199780762, 1.6286249904160783],
					[58.683313700991512, 1.6287055796308092],
					[58.683297001857454, 1.6288305683463582],
					[58.683216503191936, 1.6292832652832843],
					[58.683163704467404, 1.6295749587399562],
					[58.683085905535769, 1.6298916517606237],
					[58.683085905634194, 1.6298916534710339],
					[58.682974805946785, 1.6303527528702142],
					[58.682974803865299, 1.6303527636996338],
					[58.682974801385242, 1.6303527832700782],
					[58.682974800194422, 1.6303527939105047],
					[58.682972001099884, 1.630441668047558],
					[58.682955408134049, 1.6304971432016548],
					[58.682955408232452, 1.6304971449120618],
					[58.682955407341801, 1.6304971451010519],
					[58.682930406937309, 1.6305860451743457],
					[58.68293040703572, 1.6305860468847477],
					[58.682930406341896, 1.6305860504945533],
					[58.682930406440306, 1.6305860522049569],
					[58.682810905579174, 1.6310693516251178],
					[58.682749805804669, 1.6313138528828044],
					[58.68274980352632, 1.6313138602912991],
					[58.682749801144482, 1.631313881571961],
					[58.682749799855195, 1.6313138905018882],
					[58.68273040043632, 1.6317249911400244],
					[58.682730399644065, 1.6317249930393913],
					[58.682730399939246, 1.631724998170585],
					[58.682730400037642, 1.6317249998809809],
					[58.682730399907683, 1.6317721996561683],
					[58.682730400104475, 1.631772203076963],
					[58.682730399607387, 1.6317722101075234],
					[58.682730399804178, 1.6317722135283357],
					[58.682758099877269, 1.6321778144073311],
					[58.68275810382827, 1.632177836075736],
					[58.682758117074201, 1.6321778782788945],
					[58.682758126369116, 1.6321778988136497],
					[58.682952625890842, 1.6325194987268519],
					[58.682952630836006, 1.6325195063342457],
					[58.682952641617028, 1.6325195213600847],
					[58.682952648441912, 1.6325195303000035],
					[58.683116510871471, 1.6327111862193866],
					[58.683166502805989, 1.6330222317935885],
					[58.683166508636532, 1.6330222547949464],
					[58.683166526335476, 1.6330222960542977],
					[58.683166537411566, 1.6330223162116593],
					[58.683410929227861, 1.6333668042975504],
					[58.683669220950925, 1.6338667903425108],
					[58.683869219983222, 1.6342778864177325],
					[58.683869224037657, 1.634277894214468],
					[58.683869233037186, 1.6342779096190378],
					[58.68386923699326, 1.6342779157053107],
					[58.684177628627339, 1.6347084047047316],
					[58.684324823296301, 1.6349917927562261],
					[58.684324830219389, 1.6349918034074726],
					[58.684324845054533, 1.6349918262315848],
					[58.684324853758923, 1.6349918365050753],
					[58.684691553998086, 1.6353862365063383],
					[58.684691555976087, 1.6353862395496028],
					[58.684691562604094, 1.6353862450695127],
					[58.684691566363405, 1.6353862477350243],
					[58.684780450266516, 1.6354668346917856],
					[58.684835919374528, 1.6355444902703362],
					[58.68491650911907, 1.6358028606865185],
					[58.684916510108067, 1.6358028622081864],
					[58.684985910142672, 1.6360139626284955],
					[58.684985911230001, 1.636013965860696],
					[58.684985914393685, 1.636013973846743],
					[58.684985916371701, 1.6360139768900646],
					[58.685147015640986, 1.6363972777162952],
					[58.685147016728315, 1.636397280948521],
					[58.685147019793618, 1.6363972872240942],
					[58.685147020782615, 1.6363972887457718],
					[58.685241521008344, 1.6365861884601678],
					[58.685241522095673, 1.6365861916923892],
					[58.68524152407366, 1.6365861947357823],
					[58.685241525062658, 1.636586196257471],
					[58.685602625342213, 1.6372389964475176],
					[58.685602629298167, 1.6372390025344297],
					[58.685602638199036, 1.6372390162299506],
					[58.685602642154983, 1.6372390223168645],
					[58.685699838935065, 1.6373640197553154],
					[58.685994235016132, 1.6377862120776354],
					[58.686152632917228, 1.6380251118615747],
					[58.686152638752795, 1.6380251192816164],
					[58.68615265220523, 1.6380251337440581],
					[58.686152658931427, 1.6380251409752686],
					[58.68620545903682, 1.638077941289761],
					[58.686205460025789, 1.6380779428115342],
					[58.686205463785065, 1.6380779454774417],
					[58.686205465763052, 1.638077948520994],
					[58.686474852092445, 1.6383223360476429],
					[58.686608141236867, 1.6384973213641703],
					[58.686608147963092, 1.6384973285955684],
					[58.686608162404426, 1.6384973445801823],
					[58.686608170021294, 1.6384973516227572],
					[58.686760921705933, 1.6386279108830486],
					[58.686835906201864, 1.6389167499328496],
					[58.686835909463689, 1.6389167596302305],
					[58.686835914900044, 1.6389167757924989],
					[58.686835918954223, 1.6389167835904319],
					[58.686941505683222, 1.6391444553713437],
					[58.687008102051365, 1.6396556249073704],
					[58.687022001231874, 1.6397722233530148],
					[58.687022002712283, 1.6397722334280833],
					[58.687022007651031, 1.6397722566219302],
					[58.687022010912791, 1.639772266319443],
					[58.687041505832887, 1.6398278493981677],
					[58.687072002735782, 1.6400111328374616],
					[58.687072002834064, 1.6400111345481039],
					[58.687072002834064, 1.6400111345481039],
					[58.68718040231159, 1.6406278275796822],
					[58.687205400930559, 1.6408639207197433],
					[58.687205402017774, 1.6408639239523],
					[58.687205402410896, 1.6408639307949151],
					[58.687205402607439, 1.6408639342162235],
					[58.687313703063403, 1.641486134581144],
					[58.687313704150618, 1.6414861378137373],
					[58.687313704543683, 1.6414861446563731],
					[58.687313705630899, 1.6414861478889728],
					[58.687508105788751, 1.642266748092974],
					[58.687508105887005, 1.6422667498036434],
					[58.687508105887005, 1.6422667498036434],
					[58.687527605916053, 1.6423417473479824],
					[58.687544204739375, 1.6424167432738055],
					[58.687544205826562, 1.6424167465064561],
					[58.687544208197451, 1.6424167563930752],
					[58.687544209284638, 1.6424167596257098],
					[58.687788705211702, 1.6431917461904011],
					[58.687888702923125, 1.6438167327651398],
					[58.687888703021365, 1.6438167344758361],
					[58.687888703021365, 1.6438167344758361],
					[58.687930401915573, 1.6440500268560339],
					[58.687999798584933, 1.644863902788664],
					[58.687997001262865, 1.6449110851649102],
					[58.687991501058889, 1.644969381493752],
					[58.687991501150442, 1.6449693987895067],
					[58.687991504103806, 1.644969434525742],
					[58.687991506965616, 1.6449694529662322],
					[58.688027605731953, 1.645100049272209],
					[58.68813310499938, 1.6455667437556039],
					[58.68813310628294, 1.6455667504098364],
					[58.688133109642479, 1.645566761818936],
					[58.688133110827799, 1.6455667667624578],
					[58.688202609013501, 1.645763959756003],
					[58.688299806983693, 1.6461139538530625],
					[58.688299816276512, 1.6461139743948578],
					[58.688299836545347, 1.6461140133904826],
					[58.688299850193374, 1.6461140312784055],
					[58.688488735965215, 1.6463279167607039],
					[58.68864702480878, 1.64661119794607],
					[58.688647028764322, 1.6466112040345613],
					[58.688647037664261, 1.6466112177337209],
					[58.688647043401168, 1.6466112234449952],
					[58.68894153418011, 1.6469862119921905],
					[58.689205425366531, 1.6474500967642656],
					[58.689399824853396, 1.6478056961093632],
					[58.689399830884824, 1.6478057069533294],
					[58.689399847401049, 1.6478057276982554],
					[58.689399855213829, 1.6478057381650124],
					[58.689566554672595, 1.6479807385910623],
					[58.6895665584317, 1.6479807412582994],
					[58.689566565059231, 1.6479807467813563],
					[58.689566567927642, 1.6479807496371763],
					[58.689797066957951, 1.6481834481914104],
					[58.689852665619348, 1.6482334491880217],
					[58.689852688075753, 1.6482334634808764],
					[58.689852735366095, 1.6482334863683832],
					[58.689852761188867, 1.6482334964852459],
					[58.689880561366294, 1.6482390959854794],
					[58.689880572250864, 1.6482390971440759],
					[58.68988059491069, 1.6482390992726368],
					[58.689880606685954, 1.6482391002426406],
					[58.690277802532719, 1.6482251995897712],
					[58.690302800447917, 1.6482252001813384],
					[58.690302805890227, 1.6482252007606193],
					[58.690302816578416, 1.6482251984975385],
					[58.690302822020691, 1.6482251990768304],
					[58.690327822387879, 1.6482223991101497],
					[58.690327831294717, 1.6482223972242382],
					[58.690327848217706, 1.6482223936410094],
					[58.69032785623385, 1.6482223919436969],
					[58.690677856182134, 1.648119591433727],
					[58.690677857072799, 1.6481195912451296],
					[58.6906778579635, 1.6481195910565338],
					[58.69067785885418, 1.6481195908679349],
					[58.690750039986227, 1.6480973976983955],
					[58.690833302116118, 1.6480891009523149],
					[58.691136082584158, 1.6481140994452173],
					[58.691199966105465, 1.6481195981069092],
					[58.691252669240704, 1.648133471370677],
					[58.691310922695592, 1.6482333938370546],
					[58.691474802379332, 1.6485722496518016],
					[58.69148310031516, 1.6487556094847351],
					[58.691496999129598, 1.6490860865050028],
					[58.691424803260631, 1.6494971642290506],
					[58.691424803456961, 1.6494971676508361],
					[58.691424801773756, 1.6494971697388718],
					[58.691424801871925, 1.6494971714497637],
					[58.691413702183439, 1.6495749705223357],
					[58.691352603364294, 1.6499555675302746],
					[58.691319203242941, 1.6501471667994607],
					[58.691319203242941, 1.6501471667994607],
					[58.691227604238655, 1.6507027607479716],
					[58.691208105660223, 1.6507832510001554],
					[58.691197006753995, 1.6508249489025533],
					[58.691197006059603, 1.6508249525128582],
					[58.69119700378014, 1.6508249599220113],
					[58.691197003085762, 1.6508249635323049],
					[58.691180403086804, 1.6509166638950501],
					[58.691180401894314, 1.6509166745374342],
					[58.691180400400022, 1.6509166956336749],
					[58.691180400000093, 1.6509167043766413],
					[58.691196999737059, 1.6516083028192365],
					[58.691197000091066, 1.6516860734301775],
					[58.691149807183493, 1.6518582470102798],
					[58.691149812890302, 1.6518583150688813],
					[58.691149867358938, 1.6518584316840474],
					[58.691149915230106, 1.6518584804291014],
					[58.691238815370212, 1.6519001804321618],
					[58.691238898393266, 1.6519001819096488],
					[58.69123903725788, 1.651900119619045],
					[58.691239092306887, 1.6519000577503293],
					[58.691280791656048, 1.6517611584754275],
					[58.691280792350469, 1.6517611548651259],
					[58.691280793044896, 1.6517611512548351],
					[58.69128079373931, 1.651761147644524],
					[58.691300177031216, 1.6516834148246586],
					[58.691577922924722, 1.6514668573414502],
					[58.691686119041975, 1.6513835369016705],
					[58.691799904975589, 1.6514445763671661],
					[58.691799963353745, 1.6514445882548041],
					[58.691800075376975, 1.6514445766662973],
					[58.691800129022084, 1.651444553190154],
					[58.691955728040867, 1.6513140538374582],
					[58.692130700807297, 1.6511695765330388],
					[58.692300067233234, 1.6511084890635268],
					[58.692427866078923, 1.6510640893837705],
					[58.692427872215603, 1.6510640863530883],
					[58.692427883696425, 1.6510640821911768],
					[58.692427888942419, 1.6510640793490219],
					[58.692577831127878, 1.6509891079142094],
					[58.692708266158583, 1.6510112975162414],
					[58.692708289610962, 1.6510112977471496],
					[58.692708338100758, 1.6510112944099828],
					[58.692708361258653, 1.6510112895080167],
					[58.693027831224185, 1.650908499647797],
					[58.693108300398045, 1.650908499343914],
					[58.693108301288717, 1.6509084991553709],
					[58.693108302277572, 1.6509085006778181],
					[58.693388829979583, 1.6509058017793372],
					[58.693477674327774, 1.6509779546300698],
					[58.693477683725412, 1.6509779612996374],
					[58.693477705290896, 1.6509779757841379],
					[58.693477714590394, 1.6509779807427005],
					[58.693649912958961, 1.6510584799961021],
					[58.693733156591243, 1.651100151769693],
					[58.693930426797657, 1.6514390009988091],
					[58.693930429764158, 1.6514390055662702],
					[58.693930436587841, 1.6514390145126918],
					[58.69393043965249, 1.6514390207911671],
					[58.693972030971139, 1.6514945083919241],
					[58.694016521273603, 1.6515778897063038],
					[58.694191513348571, 1.6519444718860088],
					[58.69426370798076, 1.652191756725127],
					[58.694333104240869, 1.6524278411105777],
					[58.694344202204874, 1.6525167244344836],
					[58.694344202303007, 1.6525167261455282],
					[58.694344201608565, 1.6525167297561352],
					[58.694344201608565, 1.6525167297561352],
					[58.694394201543325, 1.6528472295032282],
					[58.694394202728397, 1.652847234447876],
					[58.694394204109713, 1.6528472428146384],
					[58.694394205196659, 1.6528472460482495],
					[58.694497003143645, 1.6532861348157244],
					[58.694521999914826, 1.653513915692373],
					[58.694549800151982, 1.6541472081187278],
					[58.694549800642513, 1.6541472166740638],
					[58.694549803306877, 1.654147231696703],
					[58.694549803699324, 1.6541472385409703],
					[58.694630403408716, 1.6545611354513139],
					[58.694633102320516, 1.6545778307272205],
					[58.694694200234885, 1.6550028140563189],
					[58.694694200097189, 1.6553389001241203],
					[58.694694200391481, 1.6553389052573544],
					[58.694694201078129, 1.6553389172348971],
					[58.694694201372414, 1.655338922368131],
					[58.694722000924763, 1.6555806235852302],
					[58.694722001913533, 1.6555806251078795],
					[58.694758099204932, 1.6558639051629052],
					[58.694741500108805, 1.656127787248195],
					[58.694741500991555, 1.6561278026479287],
					[58.69474150265895, 1.6561278317363342],
					[58.694741505323087, 1.6561278467592389],
					[58.694880400095926, 1.656719423459011],
					[58.694880399655787, 1.6567221996875392],
					[58.694880405082003, 1.6567222314446495],
					[58.694880423754611, 1.6567222898410312],
					[58.694880437989738, 1.6567223180029711],
					[58.695338737842164, 1.6573529173129296],
					[58.695338756040343, 1.6573529359779544],
					[58.695338794814639, 1.6573529676094436],
					[58.695338816379497, 1.6573529820986435],
					[58.695766615724295, 1.6575501817216509],
					[58.695766620275869, 1.6575501824908139],
					[58.695766626804961, 1.6575501863054711],
					[58.695766630465862, 1.6575501872630454],
					[58.695788830472239, 1.6575584877522302],
					[58.695788846897116, 1.6575584912057211],
					[58.695788882419016, 1.6575584975474911],
					[58.695788899734609, 1.6575585008125866],
					[58.695794348348706, 1.6575585000722297],
					[58.696238781157803, 1.657805762666162],
					[58.696535962249989, 1.6580834444076888],
					[58.696694253858496, 1.6582390378462624],
					[58.696808122659924, 1.6583723005922864],
					[58.696997010275958, 1.6589361645519594],
					[58.69699701116668, 1.6589361643635894],
					[58.69699701116668, 1.6589361643635894],
					[58.697096986364222, 1.659224994417885],
					[58.696924814814494, 1.6596499253618084],
					[58.696924813923779, 1.6596499255501578],
					[58.696924813131105, 1.6596499274497092],
					[58.696924813229131, 1.6596499291609064],
					[58.696877614305563, 1.6597749274075124],
					[58.696747025148454, 1.6600998987430178],
					[58.696727649906968, 1.6601248669493871],
					[58.696716558651836, 1.6601359583399453],
					[58.696716550234946, 1.6601359687792605],
					[58.696716535084491, 1.6601359875700428],
					[58.69671652755828, 1.6601359978210277],
					[58.696574828025213, 1.6603748974580796],
					[58.696574827232517, 1.6603748993576086],
					[58.696485927667986, 1.6605276972396521],
					[58.696274828145171, 1.6608804970977236],
					[58.696274827450495, 1.66088050070838],
					[58.696274823985675, 1.6608805031728073],
					[58.69627482329102, 1.660880506783478],
					[58.696247022629436, 1.6609332062135811],
					[58.696247021934752, 1.6609332098242406],
					[58.696247018666007, 1.6609332157110099],
					[58.69624701708063, 1.6609332195099791],
					[58.696224816911773, 1.660983219414186],
					[58.696224813642992, 1.6609832253009411],
					[58.696224809082885, 1.6609832401201996],
					[58.696224806802839, 1.6609832475298234],
					[58.696169209273876, 1.6611888404233981],
					[58.696074810739489, 1.6614638349512281],
					[58.696074810837501, 1.6614638366624108],
					[58.695941510770666, 1.6618582363226104],
					[58.695941506905172, 1.6618582475310075],
					[58.695941502042281, 1.6618582728052838],
					[58.69594150084891, 1.6618582834488334],
					[58.695927600902607, 1.6620332822983062],
					[58.695872001015729, 1.6626638814378329],
					[58.695866500584131, 1.6627221803448102],
					[58.695866500878118, 1.6627221854783001],
					[58.695866499586685, 1.6627221944106334],
					[58.695866499880665, 1.6627221995441346],
					[58.695866499837656, 1.6627667001036732],
					[58.69586649993564, 1.6627667018148482],
					[58.695866500033638, 1.6627667035260105],
					[58.695866500131629, 1.662766705237168],
					[58.695888699809394, 1.6635111067504351],
					[58.695888701386004, 1.6635111185403555],
					[58.695888703550551, 1.6635111405973018],
					[58.695888706908583, 1.6635111520107462],
					[58.695908103825531, 1.6635833418545136],
					[58.695930401955422, 1.6637278306828713],
					[58.695999802050991, 1.6641694315044695],
					[58.69599980777825, 1.6641694528086137],
					[58.695999824577086, 1.6641694942875678],
					[58.695999835648664, 1.6641695144623558],
					[58.696185909331085, 1.6644361763264952],
					[58.696213702370514, 1.6646250283182809],
					[58.696213770109026, 1.6646251092584883],
					[58.696213939193214, 1.6646251653196988],
					[58.696214040538898, 1.66462514044071],
					[58.696311207882545, 1.6645279751888113],
					[58.696352863239397, 1.664514089769269],
					[58.69635286413012, 1.6645140895810422],
					[58.69635286413012, 1.6645140895810422],
					[58.696352865020842, 1.6645140893928216],
					[58.696636155259711, 1.6644168928061349],
					[58.696747243771071, 1.664391895332777],
					[58.696747244661765, 1.6643918951445518],
					[58.696747248224646, 1.6643918943916507],
					[58.696747249115354, 1.6643918942034255],
					[58.696758300381056, 1.6643891050878401],
					[58.696769351019583, 1.6643918946108451],
					[58.696769361904117, 1.6643918957745736],
					[58.696769383771169, 1.6643918998132212],
					[58.696769394557712, 1.6643918992657236],
					[58.697077793348697, 1.6644001998054809],
					[58.697208291219958, 1.6644057996014998],
					[58.697208293990073, 1.6644058007480469],
					[58.69720829755294, 1.6644057999951269],
					[58.6972083002251, 1.66440579943044],
					[58.697211067836434, 1.6644057999653044],
					[58.697658226574831, 1.6645529866757189],
					[58.697780516053356, 1.6646084807121979],
					[58.698108214417807, 1.6647640814373919],
					[58.698108227279711, 1.6647640856473298],
					[58.698108253894254, 1.6647640938790189],
					[58.69810826665816, 1.6647640963776882],
					[58.698344366862848, 1.6648029974382064],
					[58.698344383189657, 1.6648029991840017],
					[58.698344412975167, 1.6648029998176843],
					[58.698344428215307, 1.6648029983291115],
					[58.698558327590398, 1.6647723973380706],
					[58.698558351639704, 1.6647723922558735],
					[58.698558397369098, 1.6647723722001082],
					[58.698558418452357, 1.6647723625486928],
					[58.698722317210134, 1.6646529618006192],
					[58.698911190602331, 1.6645168816739799],
					[58.699005649319012, 1.6644862932411604],
					[58.699080635923742, 1.6644723978362632],
					[58.699080636814436, 1.6644723976480269],
					[58.699080637607196, 1.6644723957484406],
					[58.69908063938864, 1.6644723953719569],
					[58.699405575625931, 1.664408507483222],
					[58.699455455011076, 1.6644307536322913],
					[58.699833122438442, 1.6651583926314106],
					[58.699833124415804, 1.6651583956776983],
					[58.699833127381851, 1.6651584002471074],
					[58.699833129359227, 1.6651584032933948],
					[58.699874818028597, 1.6652278848857307],
					[58.699913711368509, 1.665341764956235],
					[58.699913714628458, 1.6653417746597892],
					[58.699913722929786, 1.6653417936904473],
					[58.699913727873181, 1.6653418013061854],
					[58.700305427702972, 1.6660029017259423],
					[58.700305439371157, 1.6660029165813464],
					[58.700305463598248, 1.6660029461039307],
					[58.700305477840615, 1.666002958683322],
					[58.700352678179868, 1.6660390588267684],
					[58.700352681047953, 1.6660390616849261],
					[58.700352685697467, 1.6660390641666685],
					[58.700352689358276, 1.6660390651252304],
					[58.700369289249764, 1.6660501660256184],
					[58.700369320709662, 1.6660501801632284],
					[58.700369386604571, 1.6660501974172446],
					[58.70036942183237, 1.666050198634023],
					[58.700394422145678, 1.6660473981135044],
					[58.700394424817837, 1.6660473975488732],
					[58.700394431150819, 1.6660473979427928],
					[58.700394433822972, 1.6660473973781618],
					[58.700747233725018, 1.6659862972676862],
					[58.700747237287906, 1.665986296514822],
					[58.700747241741496, 1.665986295573745],
					[58.700747244413634, 1.6659862950091091],
					[58.70080832201139, 1.6659723998328175],
					[58.700816690560401, 1.6659723999858738],
					[58.700911082296507, 1.6659807986690456],
					[58.700911084077937, 1.665980798292614],
					[58.700911087738781, 1.6659807992511713],
					[58.700911089618174, 1.6659808005861534],
					[58.701252789817957, 1.6659974007231093],
					[58.701252793380831, 1.6659973999702338],
					[58.701252797932383, 1.6659974007405816],
					[58.701252799713821, 1.6659974003641422],
					[58.701266636695387, 1.66599740031388],
					[58.701294285250668, 1.6660168644608284],
					[58.701294287130061, 1.6660168657958312],
					[58.701294291779568, 1.666016868277618],
					[58.701294294549669, 1.6660168694244244],
					[58.701716556227936, 1.6662779454799532],
					[58.702049826073761, 1.6668000992153953],
					[58.702174821329656, 1.6670500895244529],
					[58.702174824295639, 1.6670500940943247],
					[58.702174831314245, 1.6670501064688594],
					[58.702174835268892, 1.6670501125620134],
					[58.702580434732539, 1.6676418122639509],
					[58.702580436709852, 1.6676418153105963],
					[58.702580440664477, 1.667641821403903],
					[58.702580442641789, 1.66764182445055],
					[58.702633143251255, 1.6677084246354499],
					[58.702633162240915, 1.6677084414099259],
					[58.702633202794473, 1.6677084726828044],
					[58.702633226139795, 1.6677084868048491],
					[58.702674926276337, 1.6677251851989556],
					[58.702674927264994, 1.6677251867222838],
					[58.702674929046445, 1.6677251863459162],
					[58.702674929937153, 1.6677251861577291],
					[58.702972120173932, 1.6678362831863383],
					[58.703083209666772, 1.667891878874151],
					[58.703083242712083, 1.6678918892149248],
					[58.703083309007908, 1.6678918977275157],
					[58.703083343149117, 1.6678918957111519],
					[58.703208343163546, 1.6678640957706199],
					[58.703208344054261, 1.6678640955824378],
					[58.703208345835705, 1.667864095206065],
					[58.703208346628486, 1.6678640933063373],
					[58.703424998370174, 1.6678113065349249],
					[58.703530548952898, 1.6678390924463549],
					[58.70353056992915, 1.6678390966758279],
					[58.703530613271383, 1.6678390979121571],
					[58.703530633953847, 1.6678390970069616],
					[58.703644431962395, 1.6678195978344539],
					[58.703880560241586, 1.6677835080845607],
					[58.703980498992856, 1.6678418730487643],
					[58.703980515711407, 1.6678418816423815],
					[58.703980550636096, 1.6678418933185077],
					[58.703980569830939, 1.667841897924393],
					[58.70412496963732, 1.6678640988523024],
					[58.704269281975137, 1.6678862837534443],
					[58.704433133044866, 1.6681362100702697],
					[58.70443315796642, 1.6681362359862943],
					[58.704433218106459, 1.6681362787136607],
					[58.704433252336287, 1.6681362940015836],
					[58.704566562854339, 1.6681695717227014],
					[58.704697000785693, 1.6684250499296571],
					[58.704705399892696, 1.6687750049731707],
					[58.704705403935819, 1.6687750283713172],
					[58.704705416671523, 1.6687750776500268],
					[58.704705426949687, 1.6687750997309736],
					[58.704891519140261, 1.6690972874758239],
					[58.704933114246657, 1.6692028733160453],
					[58.704933114246657, 1.6692028733160453],
					[58.704933115235299, 1.6692028748395262],
					[58.704933115333226, 1.6692028765511544],
					[58.704963676112065, 1.6692777807651475],
					[58.704924831652768, 1.6693387927260235],
					[58.704894239937595, 1.6693859783145331],
					[58.704644251279831, 1.6696665665604211],
					[58.704644229883421, 1.6696666022614763],
					[58.704644205296731, 1.6696666767470916],
					[58.704644201313656, 1.6696667174314064],
					[58.704685897053594, 1.6701499692974864],
					[58.704583114732927, 1.6703999246623082],
					[58.704583108087057, 1.6703999503181666],
					[58.704583102214798, 1.6704000052597059],
					[58.704583102890517, 1.6704000328337489],
					[58.704685900732933, 1.6710139235827239],
					[58.704719200050953, 1.671494413218549],
					[58.704719201235356, 1.6714944181653386],
					[58.704719201822691, 1.6714944284351303],
					[58.70471920300708, 1.6714944333819122],
					[58.704783101760043, 1.6718722273639792],
					[58.704860900824663, 1.6726028204636432],
					[58.70486090092254, 1.6726028221753033],
					[58.704860902106887, 1.6726028271221456],
					[58.704860902302627, 1.6726028305454275],
					[58.704880401851881, 1.672733329262639],
					[58.704880402938358, 1.6727333324978451],
					[58.704880404220582, 1.6727333391563524],
					[58.704880405307058, 1.6727333423915585],
					[58.704894204215947, 1.6727972388135735],
					[58.704933103289427, 1.6730278333288147],
					[58.704933103289427, 1.6730278333288147],
					[58.704933103387312, 1.6730278350404748],
					[58.705033103380714, 1.673588935306688],
					[58.705033104760759, 1.6735889436769076],
					[58.705033109204422, 1.6735889583296006],
					[58.705033111475188, 1.6735889665117731],
					[58.705208110702365, 1.6740889660848333],
					[58.705208110800214, 1.674088967796495],
					[58.705333111251086, 1.6744417668678546],
					[58.705397011140676, 1.6746222676285125],
					[58.705397015192837, 1.6746222754347881],
					[58.705397024383622, 1.674622294282639],
					[58.705397029424368, 1.6746223036125607],
					[58.705799818271366, 1.6752861854432577],
					[58.705855410385567, 1.6754417635538921],
					[58.70607480837149, 1.6761389549536918],
					[58.706269203679263, 1.6769000423792064],
					[58.706285901910647, 1.6769944269289103],
					[58.706291501074446, 1.6770500203521157],
					[58.706291511145238, 1.6770500546066898],
					[58.706291542768632, 1.6770501189604412],
					[58.706291565114128, 1.6770501471599164],
					[58.706322031827462, 1.677077916528052],
					[58.7066331148269, 1.6778444744178667],
					[58.706633121051624, 1.6778444886958792],
					[58.706633135977611, 1.6778445132645445],
					[58.706633143886009, 1.6778445254548855],
					[58.706780444054516, 1.6780279243196861],
					[58.706780448899444, 1.6780279302269605],
					[58.706780457600757, 1.6780279405176881],
					[58.706780463238594, 1.6780279445252617],
					[58.706988755720694, 1.6782251387787612],
					[58.707233150373135, 1.6785029324201071],
					[58.70723316273515, 1.6785029436710919],
					[58.707233190131468, 1.6785029656092414],
					[58.7072332051657, 1.6785029762964203],
					[58.707305478936263, 1.6785418614355605],
					[58.707438758747472, 1.6786751413790506],
					[58.707438759736014, 1.6786751429028988],
					[58.707438762603843, 1.6786751457626548],
					[58.707438763592386, 1.6786751472865031],
					[58.707685955399079, 1.6789029376478952],
					[58.707885914561828, 1.6791417895503937],
					[58.707966504223087, 1.6795194424277136],
					[58.707966505211616, 1.6795194439516095],
					[58.707966506297929, 1.6795194471873389],
					[58.707966506395714, 1.6795194488991547],
					[58.708149806368517, 1.6802472471060865],
					[58.708180404658528, 1.6803750476064434],
					[58.708180406440007, 1.6803750472306433],
					[58.708205406379435, 1.6804778461956047],
					[58.708205405684225, 1.6804778498071722],
					[58.708205406770524, 1.6804778530429572],
					[58.708205407759017, 1.6804778545668961],
					[58.708419208341105, 1.6812278542641961],
					[58.708419208536625, 1.6812278576879072],
					[58.708419210806944, 1.6812278658713933],
					[58.708419211795444, 1.6812278673953609],
					[58.70861371236429, 1.6817667687506979],
					[58.708613712559803, 1.6817667721744138],
					[58.708613715623059, 1.6817667784582564],
					[58.708613717697837, 1.6817667832181076],
					[58.708755417684181, 1.6820778830456116],
					[58.708755418672673, 1.6820778845696316],
					[58.708755418672673, 1.6820778845696316],
					[58.709072018910312, 1.6827556851211691],
					[58.709072021875812, 1.682755689693296],
					[58.709072027013768, 1.6827557007372695],
					[58.709072029979239, 1.6827557053093949],
					[58.709177626978942, 1.6829251015516615],
					[58.709530423889518, 1.6835778952419711],
					[58.709530436642105, 1.6835779133431519],
					[58.709530466600981, 1.6835779486063289],
					[58.70953048361185, 1.6835779623445233],
					[58.709797051822214, 1.6837668392460856],
					[58.709983130753663, 1.6840612065367024],
					[58.709983175355397, 1.684061245643343],
					[58.709983276161083, 1.6840612902244385],
					[58.709983333353513, 1.6840612972229962],
					[58.7101333332281, 1.684036297272735],
					[58.710133364208993, 1.6840362872750223],
					[58.710133420142157, 1.6840362564229359],
					[58.710133446082921, 1.6840362370926887],
					[58.710408421584148, 1.6837418624638976],
					[58.710425089952778, 1.6837334790918654],
					[58.710425110744431, 1.6837334643121045],
					[58.710425149362294, 1.6837334301802556],
					[58.710425166395474, 1.6837334127279173],
					[58.710430765811772, 1.6837251128586272],
					[58.710430772351387, 1.6837251010842091],
					[58.710430782953857, 1.6837250815227911],
					[58.71043078682122, 1.6837250703118751],
					[58.71061408740259, 1.6832333693110604],
					[58.710614091172161, 1.6832333563880693],
					[58.710614097625111, 1.6832333273059981],
					[58.71061409961321, 1.683233314758694],
					[58.71063909831215, 1.6828722352743462],
					[58.710725190667056, 1.6825889586194704],
					[58.7107251923508, 1.6825889565317733],
					[58.7107251923508, 1.6825889565317733],
					[58.710725192253044, 1.682588954819797],
					[58.710861290845052, 1.6821111577993211],
					[58.710883489445258, 1.6820417667621463],
					[58.710900183929262, 1.6819972783223467],
					[58.711097380113202, 1.6815917873579029],
					[58.711302970719608, 1.6811751056594018],
					[58.711319560216666, 1.6811529198001669],
					[58.711319561009638, 1.6811529179002738],
					[58.711319562693348, 1.6811529158124559],
					[58.711319564377085, 1.6811529137246555],
					[58.711344564228284, 1.6811168131949927],
					[58.711344569181669, 1.68111680521958],
					[58.711344578295531, 1.6811167911686529],
					[58.71134458235818, 1.6811167833811489],
					[58.711533482356046, 1.6807000821816236],
					[58.711727981903458, 1.6802722823418872],
					[58.711727982696424, 1.6802722804419414],
					[58.711727984380133, 1.680272278354068],
					[58.711727985173091, 1.6802722764541367],
					[58.711741885360041, 1.6802389775295823],
					[58.711741889628939, 1.6802389575698906],
					[58.711741898264506, 1.6802389193625233],
					[58.711741899861181, 1.6802388999666198],
					[58.711741899845649, 1.6802333122083914],
					[58.711830797994054, 1.6795583257651379],
					[58.711855798080791, 1.6793722264827646],
					[58.711855798775943, 1.6793722228707886],
					[58.711855799177748, 1.6793722141227729],
					[58.711855799872922, 1.6793722105108015],
					[58.711883499120454, 1.6788111246680253],
					[58.711941895765442, 1.6785056401702514],
					[58.712008487741038, 1.678197279668777],
					[58.712169567190251, 1.6779529095690455],
					[58.712169569666848, 1.6779529055810132],
					[58.712169577096645, 1.6779528936169466],
					[58.712169578584685, 1.6779528881049004],
					[58.712250168408055, 1.6777890113611829],
					[58.712394546955551, 1.6776251366338066],
					[58.712536244296793, 1.6774779386162848],
					[58.712611204807217, 1.6774001782586443],
					[58.712738949600883, 1.6773668937934911],
					[58.712738957519726, 1.6773668903893149],
					[58.712738972564459, 1.6773668854810573],
					[58.712738979592572, 1.6773668822649075],
					[58.71289727986742, 1.6772973835858749],
					[58.712897280758185, 1.6772973833978564],
					[58.712897281648921, 1.677297383209841],
					[58.713058307833109, 1.6772252153296436],
					[58.713377731186057, 1.677341887129475],
					[58.713377747708464, 1.6773418923055352],
					[58.713377783229824, 1.6773418986694513],
					[58.71337780044734, 1.6773419002333476],
					[58.713508223142362, 1.6773419000079115],
					[58.713766562004757, 1.6775751449455591],
					[58.713963758096845, 1.6777751398572653],
					[58.713963761062494, 1.6777751444295672],
					[58.713963767688924, 1.6777751499619082],
					[58.713963772338275, 1.6777751524460804],
					[58.714169272135642, 1.6779473533968627],
					[58.714169277773536, 1.6779473574051567],
					[58.71416929003783, 1.6779473669458824],
					[58.714169295675703, 1.6779473709541988],
					[58.714416595995978, 1.6780973706105733],
					[58.714416619144771, 1.6780973813196058],
					[58.714416665941869, 1.6780973957011183],
					[58.714416691469488, 1.6780974007097285],
					[58.714713891450351, 1.6781113000131953],
					[58.714713927177762, 1.6781112942050047],
					[58.714713993592035, 1.6781112732557883],
					[58.714714025864751, 1.6781112543144465],
					[58.714864026005721, 1.677989054870952],
					[58.714864032838229, 1.6779890482303974],
					[58.71486404571025, 1.6779890368494392],
					[58.714864051652, 1.6779890303968779],
					[58.71501125117809, 1.6778168316674285],
					[58.71527234293994, 1.6775168409280667],
					[58.715297333140242, 1.6774945493827338],
					[58.715297349183899, 1.6774945304006443],
					[58.715297375828975, 1.677494491852433],
					[58.715297387223288, 1.6774944703861283],
					[58.715305770842157, 1.6774723140051857],
					[58.715594520995815, 1.6772029604548992],
					[58.715752823369293, 1.6771085181753607],
					[58.716055496312094, 1.6772112753799922],
					[58.716205459014937, 1.6773473413363953],
					[58.716324851541309, 1.6774779344439188],
					[58.716324863903502, 1.6774779456972395],
					[58.716324890311483, 1.6774779661155721],
					[58.716324904357307, 1.6774779752806028],
					[58.716655503688848, 1.6776584761777908],
					[58.716655537624376, 1.6776584863429167],
					[58.716655605799275, 1.6776584962117247],
					[58.716655640929389, 1.6776584957273486],
					[58.716747199143079, 1.6776391054219038],
					[58.717105558427654, 1.6777168954365484],
					[58.717105583857425, 1.6777168987328595],
					[58.717105635020836, 1.6777168948638388],
					[58.717105660950075, 1.6777168911230707],
					[58.717227860155852, 1.677677990285565],
					[58.717463932990213, 1.6776057993119087],
					[58.717552780024498, 1.6776030015881773],
					[58.717658254184983, 1.6776279957977807],
					[58.7176583054462, 1.6776279936408112],
					[58.717658400363973, 1.6776279666711227],
					[58.717658443031979, 1.677627940334147],
					[58.717864031097697, 1.6774168513927252],
					[58.7179640168982, 1.6773445615632994],
					[58.71796401778893, 1.6773445613752476],
					[58.71796401858186, 1.6773445594748915],
					[58.71796402036334, 1.6773445590987845],
					[58.717997279335172, 1.6773195909706489],
					[58.71838062691883, 1.6772668980906087],
					[58.718380671955167, 1.6772668816504217],
					[58.718380747297203, 1.6772668276183333],
					[58.718380777700723, 1.6772667917387807],
					[58.718450173909375, 1.6771306983284753],
					[58.718619568872349, 1.6768640077586738],
					[58.718619572043977, 1.6768640001570543],
					[58.718619578680723, 1.6768639900908324],
					[58.718619581950172, 1.6768639842015616],
					[58.718777982366177, 1.676519483700504],
					[58.718777983854153, 1.6765194781873194],
					[58.718777987818655, 1.6765194686852389],
					[58.718777989306616, 1.6765194631720528],
					[58.71882238900686, 1.6763889635244686],
					[58.718822389799755, 1.6763889616240459],
					[58.718822392971369, 1.6763889540223516],
					[58.718822393666422, 1.6763889504095786],
					[58.718886292722864, 1.6761444517920117],
					[58.718972387121468, 1.6758222760526627],
					[58.719069574896459, 1.6756472977197396],
					[58.719069578860896, 1.675647288217462],
					[58.71906958678975, 1.6756472692129083],
					[58.719069590754181, 1.6756472597106338],
					[58.719166875923406, 1.6753362055615308],
					[58.719325143125467, 1.6751751402821897],
					[58.719325144711227, 1.6751751364812233],
					[58.71932515075067, 1.6751751317396779],
					[58.719325153325016, 1.67517512946297],
					[58.719455748218586, 1.675019534402945],
					[58.719714035937457, 1.6747584476538633],
					[58.719752910897178, 1.6747251683174531],
					[58.719769433579117, 1.6747169074616246],
					[58.719788868284077, 1.6747195979381255],
					[58.71995825603296, 1.6747501956969515],
					[58.720219259103352, 1.6748195706828561],
					[58.720413717719595, 1.6752278835499084],
					[58.720572017384129, 1.6755889796845345],
					[58.720572022620594, 1.6755889924433629],
					[58.720572035570044, 1.6755890139717982],
					[58.720572043380862, 1.6755890244538392],
					[58.720677643329324, 1.6757223232749041],
					[58.720677649162944, 1.6757223307083835],
					[58.720677662611742, 1.6757223451990861],
					[58.720677670226841, 1.6757223522563394],
					[58.720816561701639, 1.6758418447053323],
					[58.721130454764641, 1.676172337333808],
					[58.721130470885576, 1.676172351260568],
					[58.721130506592544, 1.6761723766492169],
					[58.721130526080728, 1.676172386398638],
					[58.721541625668387, 1.6763362867146738],
					[58.721541631110647, 1.6763362872985188],
					[58.721541640311528, 1.6763362905549137],
					[58.721541644863059, 1.6763362913268662],
					[58.721580545255094, 1.6763473922337599],
					[58.721580549904452, 1.6763473947181915],
					[58.721580559007471, 1.6763473962621169],
					[58.721580563558994, 1.676347397034071],
					[58.721886046049036, 1.6764029944223484],
					[58.722033193411754, 1.6764584739002359],
					[58.722099864957293, 1.6765195477549051],
					[58.722099966177133, 1.6765195679754945],
					[58.722100132265737, 1.6765195086369862],
					[58.72210019812308, 1.6765194306022679],
					[58.722119588557312, 1.6763917887486339],
					[58.722347348591583, 1.6761362334372212],
					[58.722347369793795, 1.6761361942959347],
					[58.722347394980723, 1.6761361144500115],
					[58.722347397878998, 1.6761360705084747],
					[58.722255801959889, 1.675525000286237],
					[58.722305794989211, 1.6752111508638849],
					[58.722466884218889, 1.6747583810450382],
					[58.72249184342995, 1.674711257562614],
					[58.722644477321381, 1.674647383421795],
					[58.72264447920071, 1.6746473847580192],
					[58.722644481775035, 1.6746473824810189],
					[58.7226444835565, 1.6746473821047068],
					[58.722861139194279, 1.6745474014127288],
					[58.722911022976831, 1.6745501957160425],
					[58.723010971342156, 1.6746334530691827],
					[58.723366561324966, 1.6749390433545206],
					[58.723777653204976, 1.6753862341795533],
					[58.723777654291418, 1.6753862374165709],
					[58.723777657159324, 1.6753862402773112],
					[58.723777658147917, 1.67538624180174],
					[58.723819258081122, 1.6754279410614268],
					[58.723819264805527, 1.6754279483073427],
					[58.723819279840122, 1.6754279589976975],
					[58.723819287357415, 1.6754279643428835],
					[58.723872072429579, 1.6754640547500224],
					[58.724274859067876, 1.6758668408437336],
					[58.724274933859341, 1.6758668718451089],
					[58.724275081211829, 1.6758668632546694],
					[58.724275153175697, 1.6758668289888059],
					[58.724408451702217, 1.675708430205366],
					[58.724647341985531, 1.6754334402630007],
					[58.724694531880438, 1.6753918500233058],
					[58.724716831979286, 1.675372351019135],
					[58.724716848120345, 1.6753723337432176],
					[58.724716874666676, 1.675372293469781],
					[58.724716886060506, 1.6753722719967481],
					[58.724894586460337, 1.6749139733826099],
					[58.725036280070142, 1.674555690123426],
					[58.725102969642826, 1.6744473052489974],
					[58.725155769653469, 1.674361205146923],
					[58.725155772129924, 1.674361201157097],
					[58.725155773715635, 1.674361197355434],
					[58.725155775399216, 1.6743611952664281],
					[58.725333474865899, 1.6740361974654316],
					[58.725536275229977, 1.6736722969725184],
					[58.725566873894046, 1.6736167972195726],
					[58.725597372770437, 1.6735639999336203],
					[58.725597376039708, 1.673563994042802],
					[58.725597381687507, 1.6735639824493893],
					[58.725597384956799, 1.673563976558579],
					[58.725769584901791, 1.673144475188334],
					[58.725914085176449, 1.6727944754503667],
					[58.725914089042732, 1.6727944642331563],
					[58.725914094993826, 1.6727944421752272],
					[58.725914097871502, 1.6727944294335833],
					[58.725941884209064, 1.6726112223237279],
					[58.726033404098885, 1.6725557706590006],
					[58.726033404989622, 1.6725557704707705],
					[58.726033407563911, 1.6725557681933838],
					[58.726033409345348, 1.6725557678169189],
					[58.726216808712131, 1.6724362679911495],
					[58.726216818216429, 1.6724362607824461],
					[58.726216833760056, 1.672436248830701],
					[58.726216841384975, 1.6724362402857988],
					[58.726272338768972, 1.6723807423185375],
					[58.726477930874069, 1.6721862527095424],
					[58.726580722873564, 1.6721057576677645],
					[58.726580723764286, 1.6721057574795168],
					[58.726844515912468, 1.6718973636969579],
					[58.726905693738892, 1.6718584774652892],
					[58.726925079401134, 1.6718501836335775],
					[58.726925087319778, 1.6718501802265355],
					[58.726925103059124, 1.6718501716997054],
					[58.726925110879847, 1.6718501665799379],
					[58.726958366906423, 1.6718279963767879],
					[58.727333312731702, 1.671803000059005],
					[58.72737501322738, 1.6718001995773208],
					[58.7273750302491, 1.6718001977130656],
					[58.727375063998778, 1.6718001888463219],
					[58.727375079933928, 1.6718001837448457],
					[58.727419435795994, 1.6717808015576792],
					[58.727794387633566, 1.6718029996366115],
					[58.727794391294367, 1.6718030005963056],
					[58.727794396638735, 1.6718029994667061],
					[58.727794400299537, 1.6718030004264051],
					[58.72782215884849, 1.6718029993317345],
					[58.727944309284055, 1.671855778275805],
					[58.728274898620555, 1.6720501728607722],
					[58.728274971347233, 1.672050183491552],
					[58.728275103967533, 1.6720501537275236],
					[58.728275164751878, 1.6720501131444427],
					[58.728336229949122, 1.6719612641169714],
					[58.728680672614729, 1.6718251863620004],
					[58.728680674396209, 1.6718251859854512],
					[58.72868067617766, 1.6718251856089037],
					[58.728680677068368, 1.6718251854206341],
					[58.728713976790708, 1.6718112856564289],
					[58.728713986392847, 1.6718112801598166],
					[58.728714002132193, 1.671811271632468],
					[58.728714009952895, 1.6718112665124001],
					[58.728722310244009, 1.6718057677061773],
					[58.728722334205251, 1.6718057453064243],
					[58.7287223727309, 1.6718056938256052],
					[58.728722389174685, 1.6718056660807872],
					[58.728897389030742, 1.6713056663291292],
					[58.729022389096578, 1.6709500661177075],
					[58.729022392668909, 1.6709500497608207],
					[58.729022398922851, 1.6709500172353522],
					[58.729022399725103, 1.6709499997305286],
					[58.729022399943261, 1.6706916996002428],
					[58.729022399845348, 1.6706916978874251],
					[58.72902239964948, 1.6706916944617738],
					[58.729022399551553, 1.6706916927489543],
					[58.729000200407476, 1.6700860922751803],
					[58.729000199125025, 1.6700860856122348],
					[58.729000198439515, 1.6700860736224961],
					[58.729000197059115, 1.6700860652467313],
					[58.728927997828798, 1.6696638743450634],
					[58.728889099307409, 1.6692277824256192],
					[58.728889099209461, 1.6692277807128124],
					[58.72888909911152, 1.6692277790000074],
					[58.72882799881679, 1.6686360791290251],
					[58.728827998718842, 1.6686360774162248],
					[58.728827998718842, 1.6686360774162248],
					[58.728797398696713, 1.6683666834568784],
					[58.728752999574226, 1.6676499883812552],
					[58.728752999378294, 1.6676499849556514],
					[58.728752999084413, 1.6676499798172681],
					[58.728752998888474, 1.6676499763916692],
					[58.728736299147556, 1.667505577074939],
					[58.728736298158871, 1.6675055755505308],
					[58.728736297962939, 1.6675055721249479],
					[58.728736297864963, 1.667505570412138],
					[58.728672398324221, 1.6670888706401046],
					[58.728608498097856, 1.6666471734223662],
					[58.728591899648677, 1.6665055896318699],
					[58.728600200357562, 1.6657833038561198],
					[58.728602998184456, 1.6656778796503913],
					[58.72865574839016, 1.6656195341862192],
					[58.728655755123903, 1.66561952582765],
					[58.728655765919243, 1.6656195096757755],
					[58.728655771762263, 1.6656195015056239],
					[58.728850128768848, 1.6652918750905608],
					[58.729102782851164, 1.6652308108371991],
					[58.729327723177178, 1.66532518464933],
					[58.729327733268988, 1.6653251877149107],
					[58.729327755332065, 1.6653251951819881],
					[58.72932776621662, 1.6653251963462889],
					[58.729552717151741, 1.6653640881928564],
					[58.729988777715477, 1.6657001578616335],
					[58.729988840243806, 1.6657001793048123],
					[58.729988962752692, 1.6657001776570957],
					[58.729989024612678, 1.6657001559021638],
					[58.730002925227176, 1.6656890551350616],
					[58.730002943148229, 1.6656890374750541],
					[58.730002974144796, 1.6656889962458084],
					[58.73000298632958, 1.6656889728650341],
					[58.730186286351184, 1.6652194727276637],
					[58.730186292398919, 1.6652194523786197],
					[58.730186298954095, 1.6652194093855084],
					[58.730186300450242, 1.6652193882658715],
					[58.730164101253656, 1.6648611058698917],
					[58.730216898795021, 1.6644278236501471],
					[58.730216899293708, 1.6644278166102406],
					[58.73021690009503, 1.6644277991047396],
					[58.73021689960499, 1.6644277905404568],
					[58.730197399621197, 1.6639971903426327],
					[58.730197400413886, 1.6639971884412876],
					[58.730197399229141, 1.6639971834912171],
					[58.730197399131143, 1.663997181778361],
					[58.730147399778488, 1.6634582897400392],
					[58.730144600403584, 1.6631360976015293],
					[58.730144598728678, 1.6631360840872633],
					[58.730144594390119, 1.6631360555343744],
					[58.730144590835749, 1.6631360406842612],
					[58.729972390792042, 1.6625832402775229],
					[58.729972386641023, 1.6625832307545685],
					[58.729972378142904, 1.662583208283013],
					[58.729972373101162, 1.662583198948578],
					[58.72979737291034, 1.662283198922865],
					[58.729797370932872, 1.6622831958742477],
					[58.729797366977877, 1.6622831897770496],
					[58.729797365098435, 1.662283188441277],
					[58.729514065058893, 1.6618665885517627],
					[58.729514063972069, 1.6618665853146832],
					[58.729514061103885, 1.6618665824546666],
					[58.729514060017102, 1.6618665792175646],
					[58.729197384183593, 1.6614443125484306],
					[58.729152997382549, 1.6612666619761782],
					[58.729064098122031, 1.6606082734771441],
					[58.729061303735335, 1.6605861226938774],
					[58.729080788037152, 1.6605306676114326],
					[58.729244586934342, 1.6600917696262698],
					[58.72924458782505, 1.6600917694376898],
					[58.729386282232788, 1.6597083839016695],
					[58.72944187540628, 1.6596083973385563],
					[58.72949184779641, 1.6595195442941097],
					[58.729630694246907, 1.6594362764248918],
					[58.729769480794168, 1.6593723828869575],
					[58.729938942162377, 1.65930019928757],
					[58.730136050799132, 1.6592974005900942],
					[58.730213804325942, 1.6593390763386024],
					[58.730388803105697, 1.6594362751600884],
					[58.730388834370267, 1.6594362858760801],
					[58.730388897201671, 1.6594362968424228],
					[58.730388928866574, 1.6594362988056011],
					[58.730519301040822, 1.6594169173221085],
					[58.730674822195056, 1.6596611928980305],
					[58.730847012720631, 1.6600750704216942],
					[58.730938696696548, 1.6603389241778519],
					[58.730922000702272, 1.6605138811238491],
					[58.730922000792177, 1.6605138984411854],
					[58.730922002655333, 1.6605139309858135],
					[58.730922006308063, 1.6605139475488351],
					[58.730983105116572, 1.660763944259551],
					[58.731108104014524, 1.6613694392046738],
					[58.731108108361752, 1.6613694521535565],
					[58.731108116067425, 1.661369476526958],
					[58.731108121305354, 1.661369489287283],
					[58.731310920803871, 1.6617778883551928],
					[58.731310926834418, 1.6617778992141901],
					[58.731310941665676, 1.6617779220794602],
					[58.731310950368339, 1.6617779323728166],
					[58.731455436474093, 1.6619418167438476],
					[58.73160812573677, 1.6622139984424917],
					[58.73160813938312, 1.6622140163579249],
					[58.731608171129366, 1.6622140512460892],
					[58.731608189131222, 1.6622140665059182],
					[58.731766589398852, 1.6623195657686056],
					[58.731766616307752, 1.6623195791431704],
					[58.731766671318731, 1.6623195952376821],
					[58.731766700409572, 1.662319599482005],
					[58.731827800280207, 1.6623195995471842],
					[58.73182780117093, 1.6623195993586488],
					[58.731827803941066, 1.662319600505956],
					[58.731827804831788, 1.6623196003174143],
					[58.732155575737899, 1.6623113008932762],
					[58.732216639158089, 1.6623279902530428],
					[58.732308232134194, 1.6623612876715614],
					[58.732308267656045, 1.6623612940220827],
					[58.732308340587565, 1.6623612924538738],
					[58.732308377106513, 1.6623612847237041],
					[58.73266117680194, 1.66221408490561],
					[58.732661189966464, 1.662214078651453],
					[58.732661216099388, 1.6622140627172366],
					[58.732661228177122, 1.6622140532256997],
					[58.732677847136728, 1.6622002213634],
					[58.733111045665318, 1.6623223919162815],
					[58.733111084651945, 1.6623223957996576],
					[58.733111160754106, 1.6623223866250598],
					[58.733111197967681, 1.6623223752800833],
					[58.733180698348349, 1.6622834756597249],
					[58.733180698250315, 1.6622834739467354],
					[58.733427885371462, 1.6621445827715677],
					[58.733538969864533, 1.6621029873562534],
					[58.733538970755227, 1.6621029871676973],
					[58.733561170792647, 1.6620945877041369],
					[58.733561180394339, 1.6620945822039941],
					[58.73356119791432, 1.6620945732938133],
					[58.733561206723337, 1.6620945696952318],
					[58.733591762129151, 1.662075196861498],
					[58.733975003998225, 1.6620584999874404],
					[58.734008299958191, 1.6620585008635129],
					[58.734008322323866, 1.662058497862515],
					[58.734008364088979, 1.6620584872871103],
					[58.734008385367879, 1.6620584810486112],
					[58.734055632091938, 1.6620363060153229],
					[58.734449972978943, 1.6620890988479564],
					[58.734449980202619, 1.6620890990525043],
					[58.734449992868583, 1.6620890998387168],
					[58.734450000092274, 1.6620891000432583],
					[58.734458273120161, 1.6620890997800848],
					[58.734508208283287, 1.6621029821217759],
					[58.73479155415216, 1.6623251391267981],
					[58.734913738326057, 1.6624945164819354],
					[58.734913756622035, 1.6624945368824759],
					[58.734913801034232, 1.662494572560375],
					[58.734913826161723, 1.6624945863132174],
					[58.73510815108402, 1.662572355679784],
					[58.735274756761662, 1.6629388472975566],
					[58.735027668093537, 1.663158149553132],
					[58.734919268974082, 1.663252648394079],
					[58.734919263033227, 1.6632526548530906],
					[58.734919252834878, 1.663252665680947],
					[58.734919247686697, 1.6632526702383352],
					[58.734735947689245, 1.6634665698826339],
					[58.734735946896564, 1.6634665717842398],
					[58.73473594600587, 1.6634665719727688],
					[58.734541547584037, 1.6636998685839439],
					[58.734474850675589, 1.6637748662776559],
					[58.734474838989819, 1.6637748826214547],
					[58.734474820071867, 1.6637749143664389],
					[58.734474812046976, 1.663774931669197],
					[58.734463716018311, 1.6638055204438309],
					[58.734449820807448, 1.6638332097570998],
					[58.734449811589258, 1.66383323771526],
					[58.734449802255995, 1.6638332951725003],
					[58.734449802140922, 1.6638333246715804],
					[58.734455400332735, 1.6638778152485538],
					[58.734474800421864, 1.6646750025619206],
					[58.734474800225726, 1.664697200540282],
					[58.734474803673322, 1.664697229285407],
					[58.73447482125701, 1.6646972845137002],
					[58.734474833611678, 1.6646973113738646],
					[58.734480433752552, 1.6647057107294929],
					[58.734480438696146, 1.6647057183523426],
					[58.734480452244206, 1.6647057345571112],
					[58.734480458969223, 1.6647057418029698],
					[58.734485958986085, 1.6647112408835363],
					[58.734485990936399, 1.6647112635967742],
					[58.734486061971353, 1.6647112919091909],
					[58.734486100263283, 1.6647112994099249],
					[58.73449440031952, 1.6647112992555582],
					[58.734494403980349, 1.6647113002146339],
					[58.734494412094769, 1.6647113002312426],
					[58.734494415657629, 1.6647112994772493],
					[58.734836116233154, 1.6646834986298789],
					[58.734836119893998, 1.6646834995889783],
					[58.734836127019669, 1.6646834980809819],
					[58.734836129691793, 1.6646834975154809],
					[58.734927829653074, 1.664669597726544],
					[58.734927837669453, 1.6646695960300359],
					[58.73492785370226, 1.6646695926370108],
					[58.734927861620662, 1.6646695892274084],
					[58.734936162276924, 1.664666889353577],
					[58.73493619890268, 1.6646668677318133],
					[58.734936261376276, 1.6646668094309101],
					[58.734936286431427, 1.6646667746533577],
					[58.735127984905091, 1.6641889756173565],
					[58.735286285393492, 1.6638000742601555],
					[58.735286289356921, 1.6638000647520146],
					[58.735286295208304, 1.663800040973477],
					[58.735286298084979, 1.6638000282276411],
					[58.735305797686443, 1.6636667290755776],
					[58.735369578228827, 1.6632362647957344],
					[58.735511145804104, 1.6632029952292251],
					[58.735511146596771, 1.6632029933275714],
					[58.735694447269964, 1.6631584931578878],
					[58.735694484189523, 1.6631584766733749],
					[58.735694548141254, 1.6631584284586118],
					[58.735694575173426, 1.6631583967283472],
					[58.735814074752831, 1.6629417969480951],
					[58.735814078021548, 1.6629417910545239],
					[58.735814082777594, 1.662941779644459],
					[58.735814086046318, 1.6629417737508689],
					[58.735819576378148, 1.6629278987188527],
					[58.735827959176447, 1.662916820184386],
					[58.73582796581195, 1.6629168101103338],
					[58.735827978984879, 1.6629167882491043],
					[58.735827983740904, 1.6629167768390394],
					[58.73588628430182, 1.6627778774807083],
					[58.735886285094487, 1.6627778755790261],
					[58.735886286679829, 1.6627778717756647],
					[58.735886288265164, 1.6627778679722827],
					[58.7360112880298, 1.662433369494384],
					[58.736155788085952, 1.6620389684435681],
					[58.736155791853179, 1.6620389555087103],
					[58.736155798398826, 1.662038928114429],
					[58.736155800286561, 1.6620389138435983],
					[58.736166899846424, 1.6618778134354775],
					[58.736166899650328, 1.66187781000919],
					[58.736166900148831, 1.6618778029680683],
					[58.736166899952735, 1.6618777995417808],
					[58.736164100102812, 1.6611749984643878],
					[58.736164098231512, 1.6611749815216286],
					[58.736164091718784, 1.6611749464887839],
					[58.736164087077334, 1.6611749283986843],
					[58.735955787190882, 1.6606332276002576],
					[58.735955786300181, 1.6606332277888725],
					[58.735955785213349, 1.6606332245512443],
					[58.735955784126517, 1.6606332213135955],
					[58.735825186249542, 1.6603249276659315],
					[58.735786288460169, 1.6602166323935152],
					[58.735786287373323, 1.6602166291559206],
					[58.735786284210882, 1.6602166211562102],
					[58.735786283222112, 1.6602166196317225],
					[58.735458483815712, 1.6594749215760005],
					[58.735400184436763, 1.6593360236386412],
					[58.73540017534156, 1.6593360064924092],
					[58.735400153686463, 1.6593359746676009],
					[58.735400141028464, 1.6593359582759286],
					[58.735327941314885, 1.6592637588156125],
					[58.735327907582274, 1.6592637364842622],
					[58.735327834666862, 1.6592637068467548],
					[58.735327794593339, 1.6592636997292205],
					[58.735213895277973, 1.6592664995680493],
					[58.735213894387265, 1.6592664997566882],
					[58.73521389082449, 1.6592665005112508],
					[58.735213889933789, 1.6592665006998943],
					[58.734988889837993, 1.6592776002637608],
					[58.734988860444901, 1.6592776064888632],
					[58.734988803930541, 1.6592776271271421],
					[58.734988776809246, 1.6592776415403219],
					[58.734877713298715, 1.6593637132858683],
					[58.734813858438777, 1.6593776043010975],
					[58.734466658709245, 1.6594498047964661],
					[58.734466643665414, 1.6594498097162578],
					[58.734466613577759, 1.6594498195558032],
					[58.734466599620809, 1.659449827713029],
					[58.734433254908765, 1.6594691942853401],
					[58.734405625707986, 1.6594665012968943],
					[58.733983340742675, 1.6593970041610335],
					[58.733897250333797, 1.6593748057930684],
					[58.733897247661702, 1.6593748063589511],
					[58.733897243110114, 1.6593748055890851],
					[58.733897240339935, 1.6593748044419576],
					[58.733533354157245, 1.6592998072957159],
					[58.733363968259148, 1.6592387112659255],
					[58.73336395816721, 1.6592387082018809],
					[58.733363938081411, 1.6592387037867493],
					[58.733363927196855, 1.6592387026243012],
					[58.733080661939397, 1.6591998064115874],
					[58.732811192725322, 1.6590581224717758],
					[58.732811170661968, 1.6590581150081383],
					[58.732811125840676, 1.6590581036954077],
					[58.732811103082774, 1.659058099846318],
					[58.732630655743876, 1.6590554016140233],
					[58.732219500944822, 1.6588137275156325],
					[58.732219471461605, 1.6588137164224876],
					[58.732219406652597, 1.6588137024083562],
					[58.732219374195076, 1.6588137023473499],
					[58.732177847224584, 1.6588191924114462],
					[58.732058412160107, 1.6587415338867062],
					[58.731727953702993, 1.6585109629667198],
					[58.731402976146903, 1.6579027058065006],
					[58.731402976146903, 1.6579027058065006],
					[58.731402975158112, 1.6579027042822625],
					[58.731402975060007, 1.6579027025693733],
					[58.731269583770334, 1.6576610185335907],
					[58.73106129300816, 1.6570527471512411],
					[58.730930796247918, 1.6564360592454239],
					[58.730930795062882, 1.6564360542955188],
					[58.730930792790886, 1.6564360461085801],
					[58.730930790715163, 1.6564360413473742],
					[58.730858495620623, 1.6561971574725358],
					[58.730847399143471, 1.6561166782886203],
					[58.730797399048569, 1.6554388858118398],
					[58.730797398950443, 1.6554388840989744],
					[58.730789100248835, 1.6553360917090085],
					[58.730789100182342, 1.6553278135943164],
					[58.730797397525414, 1.6552639254605772],
					[58.730797399208676, 1.6552639233702928],
					[58.730797398816158, 1.6552639165189056],
					[58.730797398718018, 1.6552639148060577],
					[58.730847399359199, 1.6545861164252615],
					[58.730858498684363, 1.6544722184327245],
					[58.730858498684363, 1.6544722184327245],
					[58.730858499476909, 1.6544722165311234],
					[58.730858499280629, 1.6544722131054306],
					[58.730902997914505, 1.6538472352701119],
					[58.730972392457353, 1.6536028555092277],
					[58.730972393747962, 1.6536028465674608],
					[58.730972398110559, 1.6536028283064126],
					[58.730972399401182, 1.653602819364649],
					[58.731014093101095, 1.653197274967475],
					[58.731219560874578, 1.6529195181914964],
					[58.731219565033534, 1.6529195121090481],
					[58.731219574242118, 1.6529194997553762],
					[58.731219578204737, 1.6529194902472018],
					[58.731269540280152, 1.6528223651251854],
					[58.731602862087342, 1.6527140904645128],
					[58.731602865650103, 1.6527140897093839],
					[58.731602873568129, 1.6527140862974485],
					[58.731602877923393, 1.6527140836406693],
					[58.731655617304156, 1.6526919104010787],
					[58.731666653173235, 1.6526945938185469],
					[58.731666664155966, 1.6526945966917883],
					[58.731666686815814, 1.6526945988237365],
					[58.73166669859107, 1.6526945997953195],
					[58.732058299336394, 1.6526973992431691],
					[58.732058300325257, 1.652697400767299],
					[58.732116692609132, 1.6526973994102183],
					[58.73256662809856, 1.6527307958457287],
					[58.732608181822236, 1.6527612607374158],
					[58.732608209720844, 1.6527612756288999],
					[58.732608269382489, 1.6527612941903784],
					[58.732608300353014, 1.6527612997620678],
					[58.733013815047926, 1.6527613008372972],
					[58.733135954869624, 1.6528890386909587],
					[58.733135983062724, 1.6528890587216183],
					[58.733136045192886, 1.6528890888966139],
					[58.733136078239262, 1.6528890992297589],
					[58.73346375310431, 1.6529251851468436],
					[58.733666511214778, 1.6535000676977385],
					[58.733666515268318, 1.6535000755075477],
					[58.733666523375405, 1.6535000911271691],
					[58.733666526636398, 1.6535001008387336],
					[58.733799815084218, 1.6537306802322294],
					[58.733927608352765, 1.6541611574745041],
					[58.733963707739868, 1.6542833565300505],
					[58.73396371495619, 1.6542833723387431],
					[58.73396373126829, 1.6542834052915987],
					[58.733963740265978, 1.6542834207227755],
					[58.73401373767522, 1.6543501179464226],
					[58.73422203666906, 1.6546445168307866],
					[58.73422204626295, 1.6546445269343657],
					[58.734222068220973, 1.6546445482882917],
					[58.734222078705571, 1.6546445582031191],
					[58.734383178810234, 1.65476685915054],
					[58.734383196518486, 1.6547668692684681],
					[58.734383232727545, 1.6547668876025625],
					[58.734383252911599, 1.6547668937281867],
					[58.734577753061245, 1.6548140944819896],
					[58.734577770376937, 1.6548140977478674],
					[58.734577802932499, 1.6548140995180003],
					[58.734577820051918, 1.6548140993578149],
					[58.734633320320576, 1.6548084983203348],
					[58.734633321211263, 1.6548084981315914],
					[58.734633322200111, 1.654808499655869],
					[58.734833321962093, 1.6547862982797241],
					[58.73483334066659, 1.6547862943159053],
					[58.734833377879319, 1.6547862829621887],
					[58.734833396387543, 1.6547862755722684],
					[58.735022295854286, 1.6546834752241617],
					[58.735022295854286, 1.6546834752241617],
					[58.735108386600572, 1.6546362804047301],
					[58.735277878298582, 1.6545640830935022],
					[58.735277899282558, 1.6545640717109538],
					[58.735277935611947, 1.6545640449392167],
					[58.735277952640615, 1.654564027459432],
					[58.735364052592672, 1.6544612282227797],
					[58.735364054275898, 1.6544612261321821],
					[58.735364056057286, 1.6544612257546552],
					[58.735364056849832, 1.6544612238528169],
					[58.735497352927837, 1.6542918295005378],
					[58.735641846550912, 1.6541334357119251],
					[58.735722345604223, 1.6540473378743532],
					[58.735722349763236, 1.6540473317912068],
					[58.735722359070046, 1.6540473211492124],
					[58.73572236332717, 1.6540473167791541],
					[58.735911263350765, 1.6537834160600104],
					[58.736122362992511, 1.6534862163004846],
					[58.73616405331758, 1.6534279281497155],
					[58.736172341670716, 1.6534195407959309],
					[58.736191841255341, 1.6534001421100331],
					[58.736191844621764, 1.6534001379286358],
					[58.736191852245298, 1.6534001293770149],
					[58.736191854721042, 1.6534001253844073],
					[58.736450155162437, 1.6530834251924333],
					[58.736450156053117, 1.6530834250036197],
					[58.736605741903368, 1.6528890419256783],
					[58.736744525098509, 1.6527779561864655],
					[58.73674454133436, 1.6527779406069876],
					[58.736744569850643, 1.6527779033507906],
					[58.736744582229242, 1.6527778833871898],
					[58.736866878287323, 1.6525111916394764],
					[58.736964073149871, 1.6523445004689596],
					[58.736964081074973, 1.6523444814492378],
					[58.736964094351272, 1.6523444456894156],
					[58.736964097724758, 1.6523444259007103],
					[58.737044597700894, 1.6517028304431982],
					[58.73705299669632, 1.651655635475074],
					[58.737052998288334, 1.6516556160639564],
					[58.737052998007812, 1.6516555797102561],
					[58.737052996037093, 1.651655561054536],
					[58.73705020114302, 1.6516416837489289],
					[58.737080799661193, 1.6508583078637891],
					[58.737080799562989, 1.6508583061506499],
					[58.737080800257282, 1.6508583025355044],
					[58.737080800159092, 1.650858300822367],
					[58.737080800053242, 1.6507750047046788],
					[58.737086300288482, 1.650675011083552],
					[58.737086300092088, 1.6506750076572629],
					[58.737086299699293, 1.6506750008046958],
					[58.737086300393585, 1.6506749971895422],
					[58.737075199766601, 1.6500055978437427],
					[58.737075200227885, 1.6499111088011862],
					[58.7370862993168, 1.6497944189648475],
					[58.737086299323579, 1.6497944033577161],
					[58.737086297359369, 1.6497943690949841],
					[58.73708629469413, 1.6497943540545401],
					[58.737022396343512, 1.6495221608094108],
					[58.736964096920609, 1.6491582682242834],
					[58.736964090103257, 1.649158243660823],
					[58.736964069343131, 1.6491581960452304],
					[58.736964054607874, 1.6491581748951125],
					[58.736880756603604, 1.6490554762608394],
					[58.736694558110479, 1.6488165768665941],
					[58.736694522202029, 1.6488165480701351],
					[58.736694440679202, 1.6488165098753962],
					[58.73669439595551, 1.6488165002881963],
					[58.736563895719613, 1.6488191993394503],
					[58.736563876917138, 1.6488192015936303],
					[58.736563840399334, 1.6488192093392875],
					[58.736563821891515, 1.6488192167327842],
					[58.736458222686025, 1.6488637156476484],
					[58.736172122774448, 1.648983115513301],
					[58.736172119211744, 1.6489831162689419],
					[58.736172111392086, 1.648983121395317],
					[58.736172106938668, 1.6489831223398812],
					[58.736119307388265, 1.6490109232781167],
					[58.736119291748906, 1.6490109335308543],
					[58.736119264032929, 1.6490109532806692],
					[58.736119251262068, 1.6490109663928383],
					[58.736094250626621, 1.6490387665226436],
					[58.736094250626621, 1.6490387665226436],
					[58.736094249735956, 1.6490387667115509],
					[58.736049850339, 1.6490887676409414],
					[58.736049848655867, 1.6490887697318348],
					[58.736049846082018, 1.6490887720116469],
					[58.736049845289564, 1.6490887739136488],
					[58.735816545417642, 1.6493748738316982],
					[58.735674852032616, 1.6495498643371749],
					[58.73553045981506, 1.6496915576456841],
					[58.735230470683405, 1.6499831458680987],
					[58.734902683984664, 1.6502192377971063],
					[58.734783185563472, 1.6503026354521326],
					[58.734783174377412, 1.6503026447595217],
					[58.73478315299419, 1.6503026648984409],
					[58.734783142797014, 1.6503026757299757],
					[58.734608143132249, 1.6505248764807192],
					[58.734372055837568, 1.6508248586577556],
					[58.734341577237657, 1.6508498417391928],
					[58.734330482819317, 1.650858137623628],
					[58.734294285867776, 1.6508831350150883],
					[58.7342942841846, 1.6508831371057926],
					[58.734294279829363, 1.6508831397630395],
					[58.734294278146187, 1.6508831418537342],
					[58.734005478835734, 1.6511054418785647],
					[58.733894307572527, 1.651188719085726],
					[58.733722270065584, 1.6512414684906858],
					[58.733458454684914, 1.6509165749208601],
					[58.733458446872099, 1.6509165644408577],
					[58.733458429661518, 1.6509165472844576],
					[58.733458421154424, 1.6509165404192097],
					[58.733447369781075, 1.6509081774226757],
					[58.733444590433429, 1.6508999365518193],
					[58.733444588357507, 1.6508999317906261],
					[58.733444585292688, 1.6508999255052998],
					[58.733444584205593, 1.6508999222682235],
					[58.733247392983507, 1.6504360447339235],
					[58.733186304592103, 1.6500639116425238],
					[58.733275192002182, 1.6497583564478637],
					[58.73342518442368, 1.6492528842900023],
					[58.733444568960856, 1.6492223072490551],
					[58.733444573021416, 1.6492222994528125],
					[58.733444581240697, 1.6492222855732663],
					[58.73344458530125, 1.6492222777770256],
					[58.733458479973265, 1.6491889864559419],
					[58.733672374843181, 1.6488000964503968],
					[58.733866875117222, 1.6484500967699276],
					[58.733866879079478, 1.6484500872605192],
					[58.733866887102252, 1.648450069954668],
					[58.73386689017385, 1.6484500606341781],
					[58.733894589720549, 1.6483639641462127],
					[58.733914088972753, 1.6483083654849817],
					[58.73391409402879, 1.6483083436068908],
					[58.733914098698541, 1.6483082992712084],
					[58.733914099301181, 1.6483082783376786],
					[58.733861299394412, 1.6478388779792241],
					[58.733861296925447, 1.6478388663662795],
					[58.733861291096801, 1.6478388433292899],
					[58.733861287737149, 1.6478388319052717],
					[58.733719588089912, 1.6474499324131275],
					[58.733719566137331, 1.6474498954585377],
					[58.733719509657945, 1.6474498380870342],
					[58.733719474934631, 1.6474498142442127],
					[58.733466798392911, 1.6473470244222439],
					[58.733405717643997, 1.6473026378236701],
					[58.733405714873733, 1.647302636677527],
					[58.733405709234965, 1.6473026326722815],
					[58.733405706366447, 1.6473026298131968],
					[58.733280706076066, 1.6472248307044746],
					[58.733280705087168, 1.6472248291804641],
					[58.733280703305802, 1.6472248295583347],
					[58.733280702316897, 1.647224828034332],
					[58.733130762568145, 1.6471359633580485],
					[58.732947393415415, 1.6466416451856134],
					[58.732944600392365, 1.6466277798417464],
					[58.732944599524807, 1.646616699542762],
					[58.732944600022492, 1.6466166925021257],
					[58.732944598149345, 1.6466166755618536],
					[58.732944596767432, 1.6466166671861986],
					[58.732941897236493, 1.6465999681134824],
					[58.732941897040014, 1.6465999646876512],
					[58.732941895756341, 1.6465999580249076],
					[58.732941894669153, 1.646599954788019],
					[58.73280849515254, 1.6460193568248256],
					[58.732752995783947, 1.6457610573567105],
					[58.732752992424103, 1.6457610459332601],
					[58.732752983923099, 1.6457610234642779],
					[58.732752978683649, 1.6457610107058627],
					[58.732486278701465, 1.6452221108374543],
					[58.732486273658516, 1.6452221015049928],
					[58.73248626179128, 1.6452220832180167],
					[58.732486254966958, 1.6452220742635228],
					[58.732389055424754, 1.6451026740622852],
					[58.732239056206467, 1.6449165741590821],
					[58.732239055315794, 1.6449165743480689],
					[58.732239054326861, 1.6449165728241635],
					[58.732239054228593, 1.6449165711112852],
					[58.732114061853707, 1.6447665818100161],
					[58.732033468751133, 1.644638793762113],
					[58.732033461926797, 1.6446387848078416],
					[58.732033447289176, 1.6446387653754191],
					[58.732033438683494, 1.644638756799133],
					[58.731833459212005, 1.6444442750507688],
					[58.731616877423541, 1.6440777065863805],
					[58.731597394145815, 1.6440360435599957],
					[58.731575198198712, 1.6438749737947318],
					[58.731575198002155, 1.6438749703690485],
					[58.731575197903872, 1.6438749686561926],
					[58.731575196914925, 1.6438749671323587],
					[58.731491898337211, 1.6433693726622116],
					[58.731475199179073, 1.643219382225362],
					[58.731464099628866, 1.6430249893955378],
					[58.731464100421242, 1.6430249874936631],
					[58.731464100322938, 1.6430249857808343],
					[58.731464099333984, 1.6430249842570241],
					[58.731433499700664, 1.6426055946545526],
					[58.731439100204938, 1.6423583044218069],
					[58.731439099811737, 1.6423582975704898],
					[58.731439098927005, 1.6423582821550122],
					[58.731439098533798, 1.6423582753036809],
					[58.73140299888108, 1.6420638776931529],
					[58.73137799923505, 1.6418388785396933],
					[58.731377999136733, 1.6418388768268581],
					[58.731377998147771, 1.6418388753030861],
					[58.731377998049453, 1.6418388735902667],
					[58.731333498021115, 1.6414971740413864],
					[58.731333496835518, 1.6414971690919933],
					[58.731333495256649, 1.6414971572913053],
					[58.731333494071045, 1.6414971523419026],
					[58.731258493793035, 1.6411916524003507],
					[58.73125849241076, 1.6411916440253396],
					[58.731258485985279, 1.6411916263187942],
					[58.731258481931029, 1.6411916185110031],
					[58.731094581694734, 1.640827718890977],
					[58.731094576553161, 1.6408277078467379],
					[58.731094565576001, 1.6408276893729441],
					[58.731094559642067, 1.6408276802305906],
					[58.731036265230294, 1.6407498871182065],
					[58.730972369724, 1.6406470944484772],
					[58.730972362899408, 1.6406470854952684],
					[58.730972348261233, 1.6406470660651453],
					[58.730972340545968, 1.6406470573010266],
					[58.730755741204497, 1.6404331572377473],
					[58.730755735565538, 1.6404331532339187],
					[58.730755726959607, 1.640433144658989],
					[58.730755722211306, 1.6404331404660621],
					[58.730636235616117, 1.6403415515234669],
					[58.730227947156592, 1.6398970653451714],
					[58.73022794705826, 1.6398970636324157],
					[58.730227946167609, 1.6398970638215156],
					[58.730183456953299, 1.6398498750014465],
					[58.730161266213251, 1.6398165885724361],
					[58.730161265125922, 1.6398165853360211],
					[58.730161261268265, 1.6398165809541456],
					[58.730161259388609, 1.6398165796195991],
					[58.729727971511942, 1.6392498952462133],
					[58.729600185261219, 1.6389749237626328],
					[58.729327987648162, 1.6382360303712327],
					[58.729327986757518, 1.6382360305603658],
					[58.729327985670153, 1.6382360273240864],
					[58.729327985571793, 1.6382360256113815],
					[58.729283490718096, 1.6381249373942637],
					[58.729277996178936, 1.6381027596072544],
					[58.729266896535336, 1.6380388656497447],
					[58.729266897425994, 1.6380388654606097],
					[58.729266897327634, 1.6380388637478842],
					[58.729266896338622, 1.6380388622243189],
					[58.729119599628348, 1.6372666811124521],
					[58.72911960017526, 1.6370056007651064],
					[58.729122400185318, 1.636650001583362],
					[58.729122400185318, 1.636650001583362],
					[58.729122400086936, 1.6366499998706698],
					[58.729122400156129, 1.636402822829427],
					[58.72916129471789, 1.6362306432652065],
					[58.72916129471789, 1.6362306432652065],
					[58.729161296302436, 1.6362306394614348],
					[58.729161296302436, 1.6362306394614348],
					[58.729244596264842, 1.6358083395396936],
					[58.729244596166453, 1.6358083378269999],
					[58.729244596860319, 1.6358083342123997],
					[58.729244596761937, 1.6358083324997059],
					[58.72927799630606, 1.6356083382584943],
					[58.729294593088504, 1.6355333535754024],
					[58.729411289149169, 1.6351917658085013],
					[58.729411289050766, 1.6351917640958062],
					[58.729411289843028, 1.635191762193887],
					[58.729411289843028, 1.635191762193887],
					[58.729450190248187, 1.6350722627291534],
					[58.729450191040449, 1.6350722608272279],
					[58.729450190942039, 1.6350722591145292],
					[58.72945019084365, 1.6350722574018308],
					[58.729575188116307, 1.6346583687562843],
					[58.729622383820804, 1.6345472784607202],
					[58.729622384613059, 1.6345472765587756],
					[58.729622386989796, 1.6345472708529416],
					[58.729622387782037, 1.6345472689510032],
					[58.729675185851832, 1.6344028743997965],
					[58.729819583186448, 1.6340694800481395],
					[58.729947383088273, 1.6337806811343478],
					[58.729947384672734, 1.6337806773304029],
					[58.729947387743245, 1.6337806680097666],
					[58.72994739011996, 1.6337806623038356],
					[58.730014088786824, 1.6335806640825048],
					[58.730114085023978, 1.6332917767396335],
					[58.730208480906107, 1.6330917849113242],
					[58.73029738087719, 1.6329028850896479],
					[58.730297383352273, 1.6329028810963437],
					[58.730297385630514, 1.6329028736775537],
					[58.730297387313371, 1.6329028715862595],
					[58.730408487312282, 1.6326083709073793],
					[58.73040848721385, 1.6326083691946427],
					[58.730552981057322, 1.6322194874589229],
					[58.730605772464529, 1.6321307026344483],
					[58.730605773256734, 1.6321307007324033],
					[58.730605773256734, 1.6321307007324033],
					[58.7306668730062, 1.6320250996744778],
					[58.730666877164118, 1.6320250935896903],
					[58.730666882709578, 1.6320250802752951],
					[58.730666885878392, 1.6320250726670698],
					[58.730808470936793, 1.6316612120833189],
					[58.730994541301229, 1.6314751413682076],
					[58.730994553081125, 1.6314751267283418],
					[58.730994573870547, 1.631475096303838],
					[58.730994582880051, 1.6314750805191762],
					[58.731072382666333, 1.6312972795847813],
					[58.731072385141367, 1.6312972755912751],
					[58.731072389102344, 1.6312972660807965],
					[58.731072389697637, 1.631297260753148],
					[58.731122389698044, 1.6311417607037593],
					[58.731122392866801, 1.631141753095362],
					[58.73112239643396, 1.6311417367337571],
					[58.731122397722984, 1.631141727791241],
					[58.731189097566926, 1.6306694280176577],
					[58.731189098359103, 1.6306694261155459],
					[58.731189098359103, 1.6306694261155459],
					[58.731189098260643, 1.6306694244027775],
					[58.731239097971311, 1.6302750266216259],
					[58.731280797877673, 1.6299917303779332],
					[58.731350198123934, 1.6295472306249841],
					[58.731350197828533, 1.6295472254866596],
					[58.73135019911745, 1.6295472165440292],
					[58.731350199712679, 1.6295472112163203],
					[58.731358499793302, 1.6294056118715456],
					[58.731358499793302, 1.6294056118715456],
					[58.731364100405322, 1.6293000114516853],
					[58.731364100306855, 1.6293000097389074],
					[58.731400199600998, 1.628566710298414],
					[58.731400199703735, 1.628566696406804],
					[58.731400197237313, 1.6285666691918057],
					[58.731400194668147, 1.6285666558683889],
					[58.731394594755962, 1.6285416554206478],
					[58.731394592679216, 1.628541650661113],
					[58.73139459011005, 1.6285416373377202],
					[58.73139458793483, 1.6285416308654059],
					[58.731358488003636, 1.6284443310967514],
					[58.731216887899222, 1.6280499320696571],
					[58.731216886811602, 1.6280499288335255],
					[58.731216883745724, 1.628049922550707],
					[58.731216881668963, 1.6280499177912313],
					[58.731055781707084, 1.6276916176552163],
					[58.731055777652038, 1.6276916098491125],
					[58.731055767662184, 1.6276915929029852],
					[58.731055762618041, 1.6276915835735342],
					[58.730952963275115, 1.6275470842664939],
					[58.73095296228599, 1.6275470827431544],
					[58.73095296129685, 1.6275470812198263],
					[58.730952960307718, 1.627547079696501],
					[58.730658459981946, 1.6271554811768902],
					[58.730494560998935, 1.6269331807608229],
					[58.730494559119165, 1.6269331794269666],
					[58.730494556151747, 1.6269331748570772],
					[58.730494554271949, 1.6269331735232211],
					[58.730427953987586, 1.6268526734615261],
					[58.73042795388907, 1.6268526717488101],
					[58.730427952009308, 1.6268526704149571],
					[58.730041851674564, 1.6264026702344336],
					[58.730041841089474, 1.6264026586166864],
					[58.730041817345892, 1.626402637662254],
					[58.730041804187444, 1.6264026283255464],
					[58.729672303869265, 1.6261776293959915],
					[58.729672300109698, 1.6261776267284238],
					[58.729672290809283, 1.6261776217721464],
					[58.729672287148219, 1.6261776208172596],
					[58.729591786668699, 1.6261387198685202],
					[58.729591753719234, 1.6261387112744818],
					[58.72959168742225, 1.626138702839258],
					[58.729591653381128, 1.6261387066128494],
					[58.729522160981922, 1.6261554039255357],
					[58.729227768228029, 1.6262026025890048],
					[58.729227767337399, 1.6262026027784497],
					[58.729227763774873, 1.6262026035362154],
					[58.729227761993585, 1.6262026039151005],
					[58.729141662260908, 1.6262192031211968],
					[58.729141661370285, 1.626219203310634],
					[58.729141658698381, 1.6262192038789594],
					[58.729141657906254, 1.6262192057810567],
					[58.72903885793091, 1.6262415049983645],
					[58.729038850805843, 1.6262415065138764],
					[58.729038838336947, 1.6262415091660329],
					[58.729038831310405, 1.6262415123941958],
					[58.728833233814996, 1.6263165118809664],
					[58.728694407866122, 1.6263636859089992],
					[58.728564011632578, 1.6263081305647666],
					[58.72831684946761, 1.6260720665079458],
					[58.72827235872743, 1.6260137784489372],
					[58.72827235872743, 1.6260137784489372],
					[58.728272357738277, 1.626013776925749],
					[58.728272356847647, 1.6260137771151859],
					[58.728241881326348, 1.6259749075111183],
					[58.728016894170246, 1.625219350104453],
					[58.727950195600009, 1.6248943602069139],
					[58.727950194216724, 1.6248943518334136],
					[58.727950189865943, 1.6248943388905501],
					[58.727950187592022, 1.6248943307065218],
					[58.727775187922184, 1.6244221322445349],
					[58.727755788733198, 1.6243666340677243],
					[58.727755787645485, 1.6243666308320506],
					[58.727755785568625, 1.6243666260732743],
					[58.727755785371542, 1.6243666226481048],
					[58.727694584732319, 1.6242193231947206],
					[58.7275668851319, 1.6239055255806178],
					[58.727566876032242, 1.6239055084477523],
					[58.727566855259596, 1.6239054764630512],
					[58.727566843586587, 1.623905461611211],
					[58.727475144379206, 1.6238109604019815],
					[58.727316843817803, 1.6236470618922811],
					[58.727316841839446, 1.6236470588461573],
					[58.727316838079844, 1.6236470561790111],
					[58.727316837090662, 1.6236470546559467],
					[58.727272337139901, 1.6236054547903747],
					[58.727272332391131, 1.6236054506001769],
					[58.727272322002918, 1.6236054424092881],
					[58.727272317254119, 1.6236054382190996],
					[58.727161217055723, 1.6235248382139569],
					[58.727161216066548, 1.6235248366909039],
					[58.727161214186737, 1.6235248353573386],
					[58.727161213296107, 1.6235248355468281],
					[58.726947312795211, 1.6233776355304008],
					[58.726947298745912, 1.6233776263851023],
					[58.726947268271097, 1.6233776138006033],
					[58.726947251648511, 1.6233776069363253],
					[58.726863960839509, 1.6233554094246212],
					[58.726744470357119, 1.6233109125152398],
					[58.72657507876032, 1.6232470161815551],
					[58.726413987277745, 1.623169220048172],
					[58.726413982627506, 1.6231692175706343],
					[58.726413975305363, 1.6231692156616113],
					[58.726413970655138, 1.6231692131840816],
					[58.726208410970479, 1.6230915271461235],
					[58.72611684243028, 1.6229998586657086],
					[58.725964043320232, 1.6228442603280044],
					[58.725964035702432, 1.6228442532815985],
					[58.725964020565371, 1.6228442409012687],
					[58.725964013046116, 1.622844235567364],
					[58.725911274299484, 1.6228081770752896],
					[58.725886292974216, 1.6227138484089747],
					[58.725886290700153, 1.6227138402255761],
					[58.72588628526146, 1.622713824048281],
					[58.725886281107591, 1.6227138145314157],
					[58.725708493054277, 1.6223332404912101],
					[58.725689097866393, 1.6222082693043101],
					[58.725689096877211, 1.6222082677813459],
					[58.725630797316846, 1.6218610674842995],
					[58.725630797218287, 1.6218610657718333],
					[58.725630797218287, 1.6218610657718333],
					[58.725597399948384, 1.6216721795678417],
					[58.725586299872504, 1.6211749954037424],
					[58.725583499723392, 1.6209971977295783],
					[58.725583500219692, 1.6209971906901839],
					[58.725583498639004, 1.6209971788925126],
					[58.725583498146094, 1.6209971703302082],
					[58.725564098143778, 1.6208638702740321],
					[58.725564098143778, 1.6208638702740321],
					[58.725486297736389, 1.6203527702610028],
					[58.725486296747185, 1.620352768738115],
					[58.725486296549995, 1.6203527653132015],
					[58.72548629734203, 1.6203527634111883],
					[58.725447397106905, 1.6201388634289844],
					[58.725447394832706, 1.6201388552458673],
					[58.725447389196468, 1.6201388356443156],
					[58.725447386031639, 1.6201388276507627],
					[58.725350186000995, 1.6198916269237111],
					[58.7253501859024, 1.6198916252112527],
					[58.7253501859024, 1.6198916252112527],
					[58.725350185011777, 1.6198916254008264],
					[58.725105784721777, 1.6192888252392192],
					[58.725105783535348, 1.6192888202915214],
					[58.725105780370498, 1.6192888122981308],
					[58.725105778293425, 1.6192888075400083],
					[58.725025178054075, 1.6191332092541424],
					[58.724675182817656, 1.6184443194109359],
					[58.724564086774329, 1.6181527287220632],
					[58.724564084697235, 1.6181527239640887],
					[58.724564079553815, 1.618152712925355],
					[58.72456407836733, 1.6181527079777716],
					[58.724277979730303, 1.6175971130479116],
					[58.72410578269178, 1.6172110183218049],
					[58.724105773689935, 1.6172110029047642],
					[58.724105753905, 1.6172109724499641],
					[58.724105742329947, 1.6172109593142174],
					[58.723791854034289, 1.616894271732211],
					[58.723541873970099, 1.6165359997060513],
					[58.723397385123747, 1.6162305231614007],
					[58.723136288873761, 1.6155110352459248],
					[58.722783490126631, 1.6144332386584914],
					[58.722783471924998, 1.6144332044024448],
					[58.722783426316994, 1.6144331482507841],
					[58.72278339802002, 1.6144331265448564],
					[58.721844497831334, 1.6139054263550141],
					[58.721844468345083, 1.6139054153029524],
					[58.721844406303504, 1.6139054025188782],
					[58.721844373846835, 1.6139054024991302],
					[58.720752793860385, 1.6140469998512443],
					[58.719813913196504, 1.6139859005211339],
					[58.719813909535382, 1.6139858995677117],
					[58.719813904191732, 1.6139859007058079],
					[58.719813901421212, 1.6139858995627199],
					[58.718341701166068, 1.6139776006282489],
					[58.718341681375293, 1.6139776013768909],
					[58.718341643079015, 1.6139776095328366],
					[58.718341623584237, 1.6139776154177168],
					[58.717269323863214, 1.6144192157474313],
					[58.717269301992538, 1.6144192273368954],
					[58.717269262306964, 1.6144192583173125],
					[58.717269244294727, 1.6144192742842218],
					[58.716635944117364, 1.6152109742000504],
					[58.71663593659509, 1.615210984466706],
					[58.716635924321011, 1.6152110061431244],
					[58.716635917689345, 1.6152110162201521],
					[58.716247026268434, 1.6160609986138201],
					[58.715799838025681, 1.6166776833379726],
					[58.715799834660473, 1.6166776875202318],
					[58.715799828028672, 1.6166776975967379],
					[58.715799824762101, 1.616677703490953],
					[58.715344225408451, 1.6174971023467197],
					[58.715344219072428, 1.617497117558772],
					[58.715344208083039, 1.6174971458917875],
					[58.715344204418862, 1.6174971605351394],
					[58.71493040407838, 1.6195777606847233],
					[58.714652603846076, 1.6209582605717505],
					[58.714652601765636, 1.6209582714116917],
					[58.71465260037516, 1.620958294235096],
					[58.714652600174517, 1.6209583064080264],
					[58.714680400413734, 1.6219222061691672],
					[58.714680402289929, 1.6219222230990165],
					[58.714680409801957, 1.6219222596247926],
					[58.714680415339224, 1.6219222775087891],
					[58.715088714046082, 1.6229083746820747],
					[58.715447003183094, 1.6238583447567432],
					[58.71549980114564, 1.6245583146125375],
					[58.715499801244164, 1.6245583163245156],
					[58.715499800649098, 1.6245583216498438],
					[58.715499800747615, 1.6245583233618204],
					[58.71553310012122, 1.6248500102447965],
					[58.715533099625084, 1.6254666732976748],
					[58.715466510882365, 1.625713831245156],
					[58.715347049748971, 1.6259692479622747],
					[58.715216618397193, 1.6260276164955902],
					[58.715216606223798, 1.6260276242822831],
					[58.715216582767681, 1.6260276396663398],
					[58.71521657237556, 1.6260276470743671],
					[58.715010972274762, 1.6261998469036776],
					[58.715010971384132, 1.6261998470930097],
					[58.715010969602858, 1.626199847471677],
					[58.714772070126003, 1.6264054481717864],
					[58.714772064979201, 1.6264054527316554],
					[58.714772057258955, 1.6264054595714796],
					[58.714772053002775, 1.6264054639420213],
					[58.714705453230849, 1.6264776645169501],
					[58.714705453230849, 1.6264776645169501],
					[58.71458045322364, 1.6266137646008958],
					[58.714416555118966, 1.6267887622159221],
					[58.71432767399336, 1.6268804423169181],
					[58.714141601258838, 1.6269942257491083],
					[58.714083206995888, 1.6270248222751642],
					[58.714083206105258, 1.6270248224644646],
					[58.714083204422494, 1.6270248245550127],
					[58.714083202641191, 1.6270248249336372],
					[58.713883207244891, 1.6271359237695759],
					[58.713730512436598, 1.6272109197787692],
					[58.713547166207825, 1.627299794275731],
					[58.713433324256897, 1.6272859011434533],
					[58.713433296351653, 1.6272859018760018],
					[58.713433240935103, 1.6272859101886072],
					[58.713433215303553, 1.6272859191020022],
					[58.713333215011104, 1.6273331185811641],
					[58.713333214218942, 1.6273331204823391],
					[58.713333212339187, 1.6273331191490474],
					[58.713333210656387, 1.6273331212395179],
					[58.71301385285647, 1.6274915003389425],
					[58.712988900299763, 1.6274914994317449],
					[58.712988869919549, 1.6274915041556812],
					[58.71298881064066, 1.6274915236854559],
					[58.712988784315428, 1.6274915362115792],
					[58.712969284248864, 1.6275054362179038],
					[58.71296927563791, 1.6275054432463938],
					[58.712969260197291, 1.6275054569247667],
					[58.712969253367625, 1.627505463574672],
					[58.712935966922018, 1.6275415501269006],
					[58.712647083155638, 1.6277498386304949],
					[58.712647082166519, 1.6277498371079253],
					[58.712544305069891, 1.6278248217278943],
					[58.712277752273309, 1.6279220045123237],
					[58.712094408352954, 1.6279469976498147],
					[58.711800042326686, 1.6278831045044515],
					[58.711800037775021, 1.6278831037389936],
					[58.711800028671675, 1.6278831022080511],
					[58.711800025010639, 1.6278831012533201],
					[58.711644430354148, 1.6278637010827377],
					[58.711297235007372, 1.6278026027008843],
					[58.711297223232101, 1.6278026017377774],
					[58.71129719968156, 1.6278025998115921],
					[58.711297188895394, 1.6278026003710246],
					[58.711197188697305, 1.6278081007824623],
					[58.711197168114104, 1.6278081034238223],
					[58.711197129024441, 1.6278081134633353],
					[58.711197108736677, 1.6278081212400459],
					[58.710919309308274, 1.6279498216577293],
					[58.710919293670656, 1.6279498319115053],
					[58.710919265165792, 1.6279498535630199],
					[58.710919252298552, 1.6279498649607747],
					[58.710749870401592, 1.6281359452708162],
					[58.710608191246166, 1.6282276320590352],
					[58.710608178280445, 1.6282276417448085],
					[58.710608154327176, 1.6282276641613913],
					[58.710608143241203, 1.6282276751804277],
					[58.710391543302968, 1.6285026752777936],
					[58.710391527065596, 1.6285027064505389],
					[58.710391504584813, 1.6285027701378927],
					[58.710391500122711, 1.6285028022739756],
					[58.710394199695344, 1.6286750031557873],
					[58.710394201176754, 1.6286750132370182],
					[58.710394203150493, 1.6286750318769674],
					[58.710394203741259, 1.628675042147435],
					[58.710541503872975, 1.6293611418174148],
					[58.71054150713563, 1.6293611515202677],
					[58.710541511682742, 1.6293611678809328],
					[58.710541514846931, 1.629361175872035],
					[58.710638713307596, 1.6295972716683793],
					[58.710769210992588, 1.6299778643217444],
					[58.710849810833913, 1.6302111650045112],
					[58.71084981597626, 1.6302111760408931],
					[58.710849826260905, 1.6302111981136944],
					[58.710849832195422, 1.6302112072491188],
					[58.711227631910212, 1.6308001087456221],
					[58.711227635866543, 1.6308001148360216],
					[58.711227645560491, 1.6308001266384764],
					[58.71122765129811, 1.6308001323504904],
					[58.711452643263698, 1.6310529236578717],
					[58.711680423282949, 1.6313806952063235],
					[58.711891498972179, 1.6318972377750871],
					[58.71188589993163, 1.6322721932912871],
					[58.711863701649875, 1.6327610574131326],
					[58.711791521425219, 1.6329416086584392],
					[58.71169985415969, 1.6330859577714307],
					[58.711485990334772, 1.6332276337093006],
					[58.711485987662826, 1.633227634276694],
					[58.711485984198632, 1.6332276367450462],
					[58.711485981526685, 1.6332276373124333],
					[58.711252743875612, 1.6333969934918813],
					[58.711113927654822, 1.6333831025843299],
					[58.710802836996365, 1.6333248021869273],
					[58.710802800976708, 1.6333248029047323],
					[58.710802732298177, 1.6333248157554026],
					[58.710802697857986, 1.6333248282665613],
					[58.710666643308507, 1.6334054003810572],
					[58.710358294820004, 1.6334136999293913],
					[58.710358259193953, 1.6334137074940878],
					[58.710358193182316, 1.6334137353719707],
					[58.710358162796716, 1.6334137556851827],
					[58.710260963466204, 1.6335054557201574],
					[58.710260961586521, 1.6335054543866288],
					[58.710260960794258, 1.6335054562874951],
					[58.710260960794258, 1.6335054562874951],
					[58.710066570733289, 1.6336942466029918],
					[58.709949884109903, 1.6337776370550128],
					[58.709910983935075, 1.633805437032918],
					[58.709910975323744, 1.6338054440591832],
					[58.709910959882357, 1.6338054577335039],
					[58.709910952161685, 1.6338054645706661],
					[58.709797063993747, 1.6339304512362076],
					[58.709641578745924, 1.6340498410599331],
					[58.709463779677826, 1.634183139543945],
					[58.709463767700633, 1.6341831507498232],
					[58.709463745625946, 1.6341831744951145],
					[58.709463734637801, 1.6341831872236117],
					[58.709347036906834, 1.6343526836199833],
					[58.709172039198727, 1.6345915821253987],
					[58.709172031674754, 1.6345915923856487],
					[58.709172020287809, 1.63459161386153],
					[58.709172013752891, 1.6345916256443989],
					[58.70910541429938, 1.6347582251168906],
					[58.709105414397747, 1.6347582268285936],
					[58.70902481585231, 1.6349638195436835],
					[58.708899820017116, 1.6352249135076151],
					[58.708899819126458, 1.6352249136966752],
					[58.708899819224825, 1.6352249154083687],
					[58.708783127770751, 1.6354720958720379],
					[58.7086637411699, 1.6356276775847365],
					[58.708663741268275, 1.6356276792964202],
					[58.708583141060153, 1.6357331790843415],
					[58.708583140169495, 1.6357331792733809],
					[58.708583137595888, 1.6357331815521989],
					[58.70858313680359, 1.6357331834529238],
					[58.708399837213214, 1.6359887830090647],
					[58.708399837311596, 1.6359887847207468],
					[58.708155496987551, 1.6363303993373561],
					[58.708141700139826, 1.6363303994875069],
					[58.708141634821338, 1.6363304237454623],
					[58.708141536133382, 1.6363305105267298],
					[58.708141501774918, 1.6363305715274021],
					[58.708138706593758, 1.6363499385426405],
					[58.708133121402355, 1.636361009761286],
					[58.708133115063966, 1.6363610249668055],
					[58.708133105157529, 1.6363610565224085],
					[58.708133101589446, 1.6363610728725158],
					[58.708130404031927, 1.6363805542990935],
					[58.707966510066186, 1.6368832382014724],
					[58.7078526113633, 1.63723323428697],
					[58.707791512348038, 1.6374027310892412],
					[58.707705425390088, 1.6376331969361668],
					[58.707552646816936, 1.6378137702936533],
					[58.707552646915268, 1.637813772005291],
					[58.707297046686001, 1.6381192728779359],
					[58.707297039954049, 1.6381192812362348],
					[58.70729702668681, 1.6381193013761257],
					[58.707297020943848, 1.6381193112570913],
					[58.707280420758231, 1.6381527102000939],
					[58.70726375828481, 1.6381859352158377],
					[58.706905537177825, 1.6383054096784009],
					[58.706905485311459, 1.6383054484021868],
					[58.70690541729212, 1.638305551189607],
					[58.706905400150177, 1.638305613730622],
					[58.706949798594607, 1.6389971982448066],
					[58.706933101248438, 1.6392027832371807],
					[58.706933100253686, 1.6392027973079832],
					[58.70693310172846, 1.639202822982222],
					[58.706933103405696, 1.6392028364862083],
					[58.707052600166136, 1.6398556165988796],
					[58.707049800166338, 1.6402666984716812],
					[58.707049799570576, 1.6402667037954812],
					[58.707049801149381, 1.6402667155879316],
					[58.707049800553641, 1.6402667209117316],
					[58.707096997841681, 1.6407166951682439],
					[58.707041502133698, 1.6410999706078928],
					[58.707041502231988, 1.6410999723195194],
					[58.707041502428588, 1.6410999757427838],
					[58.707041500745525, 1.641099977832208],
					[58.707024802187952, 1.6412416754609536],
					[58.706980403781486, 1.6415832581658092],
					[58.706933108907393, 1.6417416409609826],
					[58.706855411677836, 1.6419888317373874],
					[58.706749815323874, 1.6422499256670151],
					[58.706749812848436, 1.6422499296568411],
					[58.706749809777151, 1.6422499389703975],
					[58.706749808290652, 1.642249944482981],
					[58.706688711011914, 1.64245823432206],
					[58.706566519312261, 1.6427582155480931],
					[58.706416537133045, 1.6430359813488273],
					[58.706316571715263, 1.6431415440620254],
					[58.705988794406096, 1.6433470313269507],
					[58.705988789160322, 1.6433470341715213],
					[58.705988779559476, 1.6433470396718468],
					[58.705988775302643, 1.6433470440391651],
					[58.705974892080228, 1.6433581315145971],
					[58.705969359573309, 1.6433608965504163],
					[58.705852814448384, 1.6433526009627062],
					[58.705852812568779, 1.6433525996287894],
					[58.705852810787455, 1.6433526000064438],
					[58.705536121567462, 1.6433359009055368],
					[58.705519451737864, 1.643333106580702],
					[58.705497269859634, 1.6433248128656486],
					[58.705497267980022, 1.6433248115317463],
					[58.705497263428391, 1.6433248107643355],
					[58.705497259767448, 1.6433248098080875],
					[58.705155723593343, 1.6432165283066476],
					[58.70506956247997, 1.6430970835355196],
					[58.705069519052444, 1.6430970494332624],
					[58.705069422983279, 1.6430970091678219],
					[58.705069367669672, 1.6430970035711174],
					[58.70493056873773, 1.6431192027204606],
					[58.704750171897125, 1.64314697074433],
					[58.70468909879434, 1.6426777768095182],
					[58.704675201369156, 1.6425360977868857],
					[58.704719600405426, 1.6418833146805794],
					[58.704719599520885, 1.6418832992769177],
					[58.704719598046665, 1.6418832736041593],
					[58.704719596369735, 1.6418832601008904],
					[58.704677999177875, 1.6416721787425008],
					[58.704658500431371, 1.6409277956909176],
					[58.704655799902689, 1.6408110948365044],
					[58.704655798520562, 1.6408110864678387],
					[58.704655797635887, 1.6408110710642596],
					[58.704655797144412, 1.64081106250671],
					[58.70459459739655, 1.6404832631825379],
					[58.704594596210974, 1.6404832582369215],
					[58.704594593938154, 1.6404832500571822],
					[58.704594591861913, 1.6404832453004417],
					[58.704444591575005, 1.6399555442664164],
					[58.70444458920381, 1.6399555343752428],
					[58.704444580898802, 1.6399555153485414],
					[58.704444575855632, 1.6399555060240965],
					[58.704133476134395, 1.6393749059095075],
					[58.704133462288681, 1.6393748845936829],
					[58.704133428657585, 1.6393748484188746],
					[58.704133409762846, 1.6393748333709832],
					[58.703747320666878, 1.6391220394084542],
					[58.703677932044585, 1.6390609500857474],
					[58.703677924427666, 1.6390609430398599],
					[58.7036779066202, 1.639060931226324],
					[58.703677898210962, 1.6390609260808116],
					[58.703633398328371, 1.6390359251758735],
					[58.703633395656382, 1.6390359257426452],
					[58.7036333918971, 1.6390359230754192],
					[58.703633390017451, 1.6390359217418176],
					[58.703352939799757, 1.6388942465216318],
					[58.703225169995513, 1.6386887942055381],
					[58.703225085208935, 1.6386887463637492],
					[58.703224914016531, 1.6386887480317958],
					[58.703224830184396, 1.6386887952633875],
					[58.703097030261638, 1.6388970941172762],
					[58.703097028578625, 1.6388970962065508],
					[58.703097028676943, 1.638897097917974],
					[58.703097027687967, 1.6388970963954743],
					[58.702944227724089, 1.6391526970382706],
					[58.702944222969997, 1.6391527084402824],
					[58.702944213363516, 1.639152729532886],
					[58.702944208609452, 1.6391527409348901],
					[58.702883108817829, 1.6393471402717077],
					[58.702785909380957, 1.6396582397108741],
					[58.702785907796255, 1.6396582435115001],
					[58.702785906408145, 1.6396582507349595],
					[58.70278590482345, 1.6396582545355856],
					[58.70272760635217, 1.6399027524649761],
					[58.702697006177353, 1.6400249502307773],
					[58.702697002799475, 1.6400249855923466],
					[58.702697009207021, 1.6400250500592877],
					[58.702697018298444, 1.6400250827763752],
					[58.702794210596778, 1.6402417661241286],
					[58.70295810644739, 1.6408778507523043],
					[58.702958107632938, 1.6408778556977059],
					[58.702958110993002, 1.640877867111074],
					[58.702958113069215, 1.640877871867622],
					[58.703258110802061, 1.6416556653849139],
					[58.703280408674168, 1.6417278595488207],
					[58.703280411935879, 1.6417278692509389],
					[58.703280420240624, 1.6417278882774784],
					[58.703280425283651, 1.6417278976018945],
					[58.703716525471357, 1.6425167977081794],
					[58.703716539119995, 1.6425168156021246],
					[58.703716571859609, 1.6425168519684803],
					[58.70371658986334, 1.6425168672067754],
					[58.703797066768395, 1.6425695528112492],
					[58.70416923627478, 1.64300011549167],
					[58.704402564360016, 1.64341109018517],
					[58.704272035409325, 1.6435998857115475],
					[58.704272034616899, 1.6435998876118636],
					[58.704272034616899, 1.6435998876118636],
					[58.704177635727135, 1.6437387851284722],
					[58.704013738432081, 1.6439665831884223],
					[58.703772039219245, 1.6442970820488434],
					[58.703772038328559, 1.6442970822376299],
					[58.703772038328559, 1.6442970822376299],
					[58.703749838177657, 1.6443276823455772],
					[58.703749835603887, 1.6443276846234205],
					[58.703749832335944, 1.6443276905130237],
					[58.703749829860421, 1.6443276945023411],
					[58.703735962245659, 1.6443498434311838],
					[58.703699919561203, 1.6443665170549047],
					[58.703383223682309, 1.6444970157793091],
					[58.703288896789424, 1.6445358854144301],
					[58.703194507552887, 1.6444998263113058],
					[58.703011252270549, 1.6443276671008844],
					[58.70283627786808, 1.644080505833305],
					[58.702702990738452, 1.6437221396147799],
					[58.702636293564716, 1.6434777530999209],
					[58.70251129737715, 1.6428860652428254],
					[58.702472398004211, 1.6426221711202922],
					[58.702472397905929, 1.6426221694088865],
					[58.702472396720459, 1.642622164463474],
					[58.702472395633244, 1.6426221612294842],
					[58.702366895886421, 1.6420749638416479],
					[58.702308496695537, 1.6417638624371023],
					[58.702308493237304, 1.6417638493124529],
					[58.702308484736001, 1.6417638268636319],
					[58.702308480288799, 1.6417638122164318],
					[58.701905780143015, 1.6409249121424594],
					[58.701905777176144, 1.6409249075749253],
					[58.70190577005684, 1.6409248934945857],
					[58.701905766101007, 1.6409248874045328],
					[58.701900166191798, 1.6409165892218751],
					[58.701650165813305, 1.6405415898938509],
					[58.701650157901597, 1.6405415777139343],
					[58.701650138613815, 1.6405415558209215],
					[58.701650128226689, 1.6405415476303318],
					[58.701447353002912, 1.6403720671521687],
					[58.701264074778742, 1.6400805024080345],
					[58.701133485943309, 1.6398166246097172],
					[58.700989090740642, 1.6393527403430752],
					[58.700989065917931, 1.6393527005710451],
					[58.700988999841421, 1.6393526331726345],
					[58.700988958784265, 1.639352608968909],
					[58.700630658650319, 1.6392415094617843],
					[58.700630655880012, 1.6392415083171694],
					[58.700630650339413, 1.6392415060279191],
					[58.700630646776744, 1.6392415067835076],
					[58.70053894717541, 1.6392192064839746],
					[58.700538880774673, 1.6392192119057916],
					[58.700538768440971, 1.6392192651766602],
					[58.700538720628337, 1.6392193116921945],
					[58.700530421454879, 1.6392360116321238],
					[58.700530416602469, 1.6392360213220032],
					[58.700530408678958, 1.6392360403239776],
					[58.700530405706154, 1.6392360513473745],
					[58.700527608246716, 1.6392471449494757],
					[58.700363708747894, 1.6397666402823499],
					[58.700363707955532, 1.639766642182523],
					[58.700363705676764, 1.6397666495943461],
					[58.700363704982713, 1.6397666532058102],
					[58.700280407214713, 1.6401193498125859],
					[58.700213711417653, 1.640344331916709],
					[58.700102615602127, 1.640605522043959],
					[58.700102614908054, 1.6406055256553866],
					[58.700102611640276, 1.640605531544675],
					[58.700102610946189, 1.6406055351561042],
					[58.700024816696349, 1.6408332164264268],
					[58.699933126189336, 1.6409943001309724],
					[58.699933126287611, 1.6409943018422459],
					[58.699933124604591, 1.6409943039312151],
					[58.699933124604591, 1.6409943039312151],
					[58.699791561749556, 1.6412526356403792],
					[58.699661091772725, 1.6412969916970566],
					[58.699311175954996, 1.6412081132548566],
					[58.69921122189232, 1.6411498406212792],
					[58.699086259224323, 1.6410276758537947],
					[58.698914073143307, 1.6407359989152046],
					[58.69875297736457, 1.6404555075256382],
					[58.698683486421928, 1.6403082252620071],
					[58.698641893824416, 1.6401832483362431],
					[58.69857799590357, 1.6398582606091383],
					[58.698547396994535, 1.6397027641451047],
					[58.6984834991135, 1.6393249799454952],
					[58.698464100405651, 1.6387999926043439],
					[58.698464100405651, 1.6387999926043439],
					[58.698464100307334, 1.6387999908931499],
					[58.698464100209016, 1.6387999891819558],
					[58.698447399963328, 1.6384639038867208],
					[58.698489097881826, 1.6380028318795476],
					[58.698580794779836, 1.6375972447392062],
					[58.698580796960144, 1.6375972356164403],
					[58.698580798747145, 1.637597219648868],
					[58.698580800036794, 1.6375972107150414],
					[58.698594595176303, 1.6373500937617216],
					[58.698719534756378, 1.637236248250409],
					[58.698719542378967, 1.6372362397051967],
					[58.69871955683184, 1.6372362245148804],
					[58.69871956257478, 1.637236214636324],
					[58.698872324761012, 1.6370195705623378],
					[58.699166729302434, 1.6369252009900186],
					[58.699283295141697, 1.6369280007649134],
					[58.699283296032355, 1.636928000575963],
					[58.699283297813679, 1.6369280001980766],
					[58.699616697716479, 1.6369307996828684],
					[58.699616707612087, 1.6369307993156796],
					[58.699616728293925, 1.6369307983923709],
					[58.699616738091194, 1.6369307963139468],
					[58.699730638160958, 1.6369084967890475],
					[58.699730639051602, 1.6369084966001004],
					[58.699969396852211, 1.6368613038886672],
					[58.700066655938649, 1.6368834956902738],
					[58.700066702744657, 1.6368834944211021],
					[58.700066790723277, 1.6368834722922736],
					[58.700066832688201, 1.6368834495323834],
					[58.700139029670481, 1.636819551742267],
					[58.700325127818488, 1.6366640530821495],
					[58.700325129599804, 1.6366640527042262],
					[58.700325132965759, 1.6366640485258155],
					[58.700325134747075, 1.6366640481478956],
					[58.700450134909751, 1.6365501481344915],
					[58.700450145105947, 1.6365501373104818],
					[58.700450164410924, 1.636550112428877],
					[58.700450172727422, 1.6365501002715106],
					[58.700508473212452, 1.6364501013385777],
					[58.700508475589359, 1.6364500956378183],
					[58.700508482026187, 1.6364500821470471],
					[58.700508484403109, 1.6364500764462828],
					[58.700661283881928, 1.6360861767654222],
					[58.700661286357203, 1.6360861727759062],
					[58.70066129031872, 1.6360861632745352],
					[58.700661290914297, 1.6360861579516668],
					[58.700752990692145, 1.6357861574305157],
					[58.700752993167391, 1.6357861534409488],
					[58.700752995347585, 1.6357861443174926],
					[58.70075299604153, 1.6357861407059171],
					[58.700808492839606, 1.6355167563027126],
					[58.700941887140687, 1.6351639717273956],
					[58.700941887736228, 1.6351639664044701],
					[58.700941891003758, 1.635163960514552],
					[58.700941891697681, 1.6351639569029224],
					[58.700989091808076, 1.6350028562911794],
					[58.700989091709708, 1.6350028545798787],
					[58.700989094184941, 1.6350028505902308],
					[58.700989093988206, 1.6350028471676199],
					[58.701011293636981, 1.63491394853992],
					[58.701011295521994, 1.6349139342824333],
					[58.701011298598068, 1.6349139093790575],
					[58.701011299592416, 1.6349138953105831],
					[58.701008500032955, 1.6347832955301955],
					[58.701008499934574, 1.63478329381889],
					[58.701008499836206, 1.6347832921075751],
					[58.701008499737824, 1.6347832903962665],
					[58.700978000114638, 1.6341360907115183],
					[58.700978000114638, 1.6341360907115183],
					[58.700977999917889, 1.6341360872889203],
					[58.700977999917889, 1.6341360872889203],
					[58.700972400113891, 1.6340499875757053],
					[58.700972397643923, 1.6340499759746481],
					[58.700972393692993, 1.6340499542948075],
					[58.700972392212044, 1.6340499442160097],
					[58.700930793339623, 1.6339082482479541],
					[58.700752993845384, 1.6331971504575928],
					[58.70075299285633, 1.6331971489353621],
					[58.700752991768887, 1.6331971457018239],
					[58.700752991572102, 1.6331971422792482],
					[58.700650195283103, 1.6328555510802871],
					[58.700602996547602, 1.6326055647102384],
					[58.70055849812011, 1.6323416697705839],
					[58.7005390980569, 1.6321999727159651],
					[58.700539097860073, 1.6321999692934275],
					[58.700539095586755, 1.6321999611151972],
					[58.700539095389942, 1.632199957692658],
					[58.700458494749277, 1.6318332576546335],
					[58.700458494257219, 1.631833249098283],
					[58.700458489907341, 1.6318332361644572],
					[58.700458486841747, 1.6318332298866434],
					[58.700383487267246, 1.6316332299974918],
					[58.700383474114176, 1.631633205075411],
					[58.700383441868773, 1.6316331616886488],
					[58.70038342178735, 1.6316331417018095],
					[58.700200122339808, 1.6314915425257648],
					[58.700200121350733, 1.6314915410036022],
					[58.700200117591301, 1.6314915383374609],
					[58.700200116700657, 1.631491538526556],
					[58.700061216848702, 1.6313915380116009],
					[58.700061187166938, 1.6313915235275886],
					[58.700061123842261, 1.6313915040612534],
					[58.70006109029778, 1.6313915007901914],
					[58.700005590011031, 1.6313942006265907],
					[58.700005544784766, 1.6313942136927753],
					[58.700005466299473, 1.6313942598022355],
					[58.700005432051348, 1.6313942913233597],
					[58.699974831965918, 1.6314415902233455],
					[58.699974831173662, 1.6314415921236982],
					[58.699974828796968, 1.6314415978246946],
					[58.699974827906317, 1.6314415980137833],
					[58.699938728021102, 1.631502698664705],
					[58.699938727130444, 1.6315026988537953],
					[58.699938726338218, 1.6315027007541227],
					[58.699938726436649, 1.6315027024653757],
					[58.699741526303363, 1.6318527018220865],
					[58.69956652605395, 1.6321638027672694],
					[58.699566524371065, 1.632163804856644],
					[58.699566521994313, 1.6321638105575291],
					[58.699566520311429, 1.6321638126468927],
					[58.699510920050272, 1.632277711555062],
					[58.6994581197184, 1.6323860116541673],
					[58.699458119024541, 1.6323860152656713],
					[58.699458115855549, 1.6323860228668152],
					[58.699458114172657, 1.6323860249561581],
					[58.699310914190264, 1.6327527246013538],
					[58.699310914288667, 1.6327527263125687],
					[58.699127631581618, 1.6332137794100099],
					[58.699108169667973, 1.6332304487711435],
					[58.699108165313092, 1.6332304514275413],
					[58.699108156898511, 1.6332304618739761],
					[58.699108152543651, 1.6332304645303721],
					[58.699077653011969, 1.6332637645805184],
					[58.699077652219721, 1.6332637664807583],
					[58.699077651329063, 1.6332637666697993],
					[58.699077649646156, 1.633263768759077],
					[58.698824850138109, 1.6335498687643819],
					[58.698824849247458, 1.6335498689534149],
					[58.698683266409944, 1.6337136335160809],
					[58.698319514146377, 1.6334609363627153],
					[58.698319462597247, 1.6334609178583135],
					[58.698319358597956, 1.6334609122176633],
					[58.698319306049427, 1.6334609233702497],
					[58.698230680542991, 1.6335080308474181],
					[58.69811687512717, 1.6332999032203841],
					[58.698116871171017, 1.6332998971318546],
					[58.698116863160294, 1.6332998832436414],
					[58.698116858313476, 1.6332998773441465],
					[58.697777957622293, 1.6328609775073268],
					[58.697777922601865, 1.6328609485677523],
					[58.697777843747865, 1.6328609098794598],
					[58.697777799914213, 1.6328609001307319],
					[58.697566721174276, 1.6328609003133372],
					[58.697527843796813, 1.6328526041818303],
					[58.697327846080533, 1.6328054056588159],
					[58.697327798285627, 1.6328054054110206],
					[58.697327707734331, 1.6328054298260715],
					[58.697327664879552, 1.6328054527777969],
					[58.697138764959298, 1.6329776516343428],
					[58.69713876505768, 1.6329776533454718],
					[58.697005465359908, 1.6330998528292384],
					[58.697005462687962, 1.6330998533963155],
					[58.697005460212793, 1.6330998573856073],
					[58.697005459420517, 1.6330998592857422],
					[58.696880537062576, 1.6332247795436845],
					[58.696808345392483, 1.6332081041214663],
					[58.696808343611188, 1.6332081044995119],
					[58.696808342720516, 1.6332081046885323],
					[58.696541757165761, 1.6331498088073295],
					[58.696430757859403, 1.6331081455574248],
					[58.69622519217809, 1.6325110413619328],
					[58.696036293220409, 1.6317916486849273],
					[58.696036293122006, 1.6317916469738765],
					[58.696036292034549, 1.6317916437408315],
					[58.696036290947092, 1.6317916405077801],
					[58.695994596173115, 1.63165825704255],
					[58.695989097964372, 1.6316166746913146],
					[58.695989096581684, 1.631616666325123],
					[58.695989094013079, 1.6316166530148533],
					[58.695989092728809, 1.6316166463597079],
					[58.695964092984006, 1.6315277460018389],
					[58.695964089918419, 1.631527739724828],
					[58.69596408537172, 1.6315277233705845],
					[58.695964082306148, 1.6315277170935611],
					[58.695786281664546, 1.6311388170695884],
					[58.695786270686426, 1.6311387986168735],
					[58.695786246255061, 1.6311387657007328],
					[58.695786230823707, 1.6311387481933408],
					[58.695591832391244, 1.6309692506648634],
					[58.695511232970397, 1.6308970508563911],
					[58.69551115223129, 1.6308970264301128],
					[58.695511000230589, 1.6308970483061158],
					[58.695510929859616, 1.6308970944193131],
					[58.695288730405281, 1.6312581956409375],
					[58.695288728722396, 1.6312581977300689],
					[58.695288727039504, 1.6312581998191973],
					[58.695288726247249, 1.6312582017192876],
					[58.695263726985566, 1.6313026996082749],
					[58.695069228335612, 1.6316331997822826],
					[58.695069224871403, 1.6316332022494866],
					[58.695069220910213, 1.6316332117497525],
					[58.695069218435059, 1.6316332157389108],
					[58.695052617895378, 1.6316693162498863],
					[58.695052616409306, 1.6316693217610019],
					[58.69505261244808, 1.6316693312612505],
					[58.695052609972947, 1.6316693352504101],
					[58.695044209561438, 1.6316943360445886],
					[58.695044208272193, 1.6316943449776979],
					[58.69504420381395, 1.6316943615110211],
					[58.695044201634033, 1.6316943706331859],
					[58.694949803443428, 1.6323249646444533],
					[58.694897004999881, 1.6325638576234671],
					[58.694897004306021, 1.6325638612345004],
					[58.694897002126062, 1.6325638703565681],
					[58.694897000639941, 1.6325638758676027],
					[58.69484700057734, 1.6329749758692067],
					[58.694847000774111, 1.6329749792912163],
					[58.694847001069292, 1.632974984424199],
					[58.694847000375425, 1.6329749880352211],
					[58.694819200213907, 1.6334305887045102],
					[58.694819200312295, 1.6334305904155071],
					[58.694819199815178, 1.6334305974485135],
					[58.69481919991356, 1.6334305991595104],
					[58.694819200349066, 1.6337721995676928],
					[58.694819199844552, 1.6339499940594018],
					[58.69479980001406, 1.634291689224608],
					[58.694785899650768, 1.6345527881705937],
					[58.694758099595745, 1.6349527860033741],
					[58.69475809979248, 1.6349527894253886],
					[58.694758100087576, 1.6349527945583839],
					[58.694758100185943, 1.6349527962693793],
					[58.694755400137034, 1.6351582753810006],
					[58.694724806083073, 1.6352860522787027],
					[58.694660905711828, 1.6355416507747838],
					[58.694660905017884, 1.635541654385728],
					[58.694660903728348, 1.6355416633186211],
					[58.694660902936057, 1.6355416652185619],
					[58.694599803366913, 1.6358999636830951],
					[58.694574805317671, 1.6360277575457856],
					[58.69448870582405, 1.6363777515170954],
					[58.694488705922389, 1.6363777532280859],
					[58.694488705031745, 1.6363777534170092],
					[58.694460904929791, 1.6364943538127137],
					[58.694460905028137, 1.6364943555237041],
					[58.694460905126476, 1.6364943572346915],
					[58.694460904235832, 1.6364943574236164],
					[58.694374805560031, 1.6368971527668037],
					[58.694324808025463, 1.6370832451741948],
					[58.694227608149376, 1.6374110424017025],
					[58.694160908183925, 1.6376332425672222],
					[58.694119207825651, 1.6377721418001217],
					[58.6941192056453, 1.6377721509216805],
					[58.694119202869267, 1.6377721653650676],
					[58.694119201579561, 1.6377721742977509],
					[58.69408040173235, 1.6380749733912721],
					[58.694052601909178, 1.6382805729420071],
					[58.694052602007496, 1.6382805746529849],
					[58.694052601215141, 1.6382805765528559],
					[58.694052601215141, 1.6382805765528559],
					[58.694010901029898, 1.6386360774947339],
					[58.694010901423141, 1.6386360843386421],
					[58.694010900428331, 1.6386360984042077],
					[58.69401089993093, 1.6386361054369771],
					[58.694021999799013, 1.6390667015523541],
					[58.694016499899, 1.6394999877236356],
					[58.693980403114068, 1.6398360657478412],
					[58.693852611758892, 1.6403693272349582],
					[58.693833127247174, 1.640405498162953],
					[58.693813756170975, 1.6404359542608247],
					[58.693497122077346, 1.640633113271051],
					[58.693366740676943, 1.6406636856698802],
					[58.692989018283356, 1.6404026377276955],
					[58.692916826256862, 1.6403470445915256],
					[58.692855731215147, 1.6402942495020643],
					[58.692855728346579, 1.6402942466466466],
					[58.692855720828057, 1.6402942413134256],
					[58.692855717959475, 1.6402942384580064],
					[58.692464041416891, 1.6400081554482977],
					[58.692127970428018, 1.6395748930240981],
					[58.692005778011413, 1.6393332103456824],
					[58.692005748833843, 1.6393331732384833],
					[58.692005677309467, 1.6393331208650987],
					[58.69200563387534, 1.6393331023659719],
					[58.691861135967443, 1.6393081023354796],
					[58.69171952114035, 1.6392804201006146],
					[58.69155576668291, 1.6390304904338431],
					[58.691555766584621, 1.6390304887229796],
					[58.691555765693948, 1.6390304889118157],
					[58.691466881620045, 1.638897113836868],
					[58.69141689140308, 1.638738839338769],
					[58.691416889326831, 1.6387388345839],
					[58.691416885174306, 1.6387388250741466],
					[58.691416883098071, 1.638738820319257],
					[58.691272386906938, 1.6384082295838687],
					[58.691125191159017, 1.6379499394053265],
					[58.691105792842656, 1.6378888479010043],
					[58.691094595225799, 1.6378388562244424],
					[58.691094595127481, 1.637838854513598],
					[58.691094594040173, 1.6378388512807995],
					[58.691094593941841, 1.6378388495699649],
					[58.690952995587921, 1.6372860585679279],
					[58.690911298255386, 1.6370332691826701],
					[58.690852999949421, 1.6366055867903113],
					[58.690853000261491, 1.6366028004631816],
					[58.690852999868149, 1.6366027936199081],
					[58.690852999179775, 1.6366027816441957],
					[58.690852997994099, 1.6366027767006261],
					[58.690800198917472, 1.636174978602668],
					[58.69073909969508, 1.6355471854110364],
					[58.690728000028791, 1.6353138891384171],
					[58.690727999930438, 1.6353138874275952],
					[58.690727999930438, 1.6353138874275952],
					[58.690680800095251, 1.634597198370515],
					[58.690689099973063, 1.6344528140169934],
					[58.690705798633388, 1.6342611170264909],
					[58.690705800119588, 1.6342611115161878],
					[58.690705800321652, 1.6342610993516349],
					[58.690705799928182, 1.6342610925084313],
					[58.690689100295828, 1.633761093657556],
					[58.690689100099078, 1.6337610902359676],
					[58.690689100099078, 1.6337610902359676],
					[58.690680799532487, 1.6335917035065191],
					[58.690697398681927, 1.63339721658542],
					[58.69069740006973, 1.6333972093642792],
					[58.690697400074974, 1.6333971937781475],
					[58.690697398692407, 1.6333971854131313],
					[58.690658499979932, 1.6328638868425709],
					[58.69065019996507, 1.6327306003121729],
					[58.69065850008036, 1.6325917116260626],
					[58.690658496626384, 1.6325916829205898],
					[58.690658483287145, 1.6325916234110058],
					[58.690658470926657, 1.6325915965954472],
					[58.690586284174117, 1.6324721178579689],
					[58.690472392359368, 1.6320888428155138],
					[58.690472390282856, 1.632088838061192],
					[58.690472386031487, 1.6320888268417408],
					[58.690472383955004, 1.6320888220873953],
					[58.690383483799415, 1.6318777215545612],
					[58.690383477766702, 1.6318777107131905],
					[58.690383463723208, 1.6318776859868873],
					[58.690383455810789, 1.6318776738127418],
					[58.690230776209468, 1.6316887989090878],
					[58.690125188888054, 1.6314082342428764],
					[58.690005790736699, 1.6310305394636155],
					[58.690005790736699, 1.6310305394636155],
					[58.690005789846083, 1.6310305396526374],
					[58.689877996665942, 1.6306332607324139],
					[58.689872399011463, 1.6305693843832978],
					[58.689847400474015, 1.6301749876949003],
					[58.68984739938653, 1.6301749844624709],
					[58.689847399288112, 1.6301749827517125],
					[58.689847399091249, 1.630174979330234],
					[58.689816899676416, 1.6298777888119054],
					[58.689811299996791, 1.6296055959108626],
					[58.689811299898373, 1.6296055942001217],
					[58.689811299898373, 1.6296055942001217],
					[58.689802999874274, 1.6293138997231231],
					[58.689811299674496, 1.6290028056637313],
					[58.689811300466701, 1.6290028037639241],
					[58.689811300269824, 1.6290028003424504],
					[58.689811300171392, 1.6290027986317048],
					[58.689808499858927, 1.6287555979896837],
					[58.689808499760495, 1.6287555962789493],
					[58.68980849966205, 1.6287555945682162],
					[58.68980019987, 1.6284499949827727],
					[58.689800199377778, 1.6284499864291018],
					[58.689800197699576, 1.6284499729323256],
					[58.689800197305793, 1.6284499660893894],
					[58.689761298787623, 1.6282249788195429],
					[58.689741899849366, 1.627791692328479],
					[58.689736300227672, 1.6275888932067184],
					[58.689736300129212, 1.6275888914959902],
					[58.68973629884475, 1.6275888848421904],
					[58.689736298647823, 1.6275888814207278],
					[58.689666898793739, 1.6268027815989152],
					[58.689666899388989, 1.6268027762776152],
					[58.689666897016899, 1.6268027663915543],
					[58.689666895830875, 1.6268027614485316],
					[58.689653001348809, 1.6267305852361638],
					[58.689689100218203, 1.6260028094519008],
					[58.689689100021234, 1.6260028060304668],
					[58.68968909962733, 1.6260027991875845],
					[58.689689100321011, 1.626002795577006],
					[58.689686299774102, 1.6258666952067675],
					[58.689686299675621, 1.6258666934960522],
					[58.68968630036931, 1.6258666898854659],
					[58.689686300270829, 1.6258666881747346],
					[58.689636302953865, 1.6250583331873873],
					[58.689658482130291, 1.6250056836439599],
					[58.689852940336344, 1.6246140673713374],
					[58.69005277930156, 1.624555814050344],
					[58.690369303861438, 1.6247195754495236],
					[58.690502639102363, 1.6247973362094648],
					[58.690569213202984, 1.6249694712213356],
					[58.690722012314872, 1.6253750684109256],
					[58.690880411774017, 1.625819467735329],
					[58.690880411774017, 1.625819467735329],
					[58.690880412763129, 1.6258194692569492],
					[58.690880412861617, 1.6258194709677236],
					[58.690966513014835, 1.6260444716564129],
					[58.690966514003946, 1.6260444731780523],
					[58.690966516080657, 1.6260444779320655],
					[58.690966517069775, 1.6260444794536795],
					[58.691238717069673, 1.6266694802088353],
					[58.691238717069673, 1.6266694802088353],
					[58.691424816580245, 1.6270917809597931],
					[58.691424840023387, 1.6270918123474534],
					[58.691424897301999, 1.6270918677206845],
					[58.691424930940514, 1.6270918882846404],
					[58.691710993807327, 1.6271973748097344],
					[58.691880465150987, 1.6273529471896038],
					[58.691880498789459, 1.6273529677540541],
					[58.69188057418517, 1.6273529933049449],
					[58.691880614259539, 1.6273530003805021],
					[58.692241713574141, 1.6273279997127668],
					[58.692241723371204, 1.6273279976322403],
					[58.692241742173131, 1.6273279953712048],
					[58.692241751970194, 1.6273279932906781],
					[58.692324980939809, 1.6273058125424835],
					[58.692688806234919, 1.6274751766629703],
					[58.692780490303612, 1.6275307672406782],
					[58.692827684682442, 1.6275640622166803],
					[58.692827692201412, 1.6275640675471086],
					[58.6928277071409, 1.6275640764970718],
					[58.692827714659884, 1.6275640818274939],
					[58.693230515158653, 1.6277529816010785],
					[58.693230581357021, 1.6277529883250303],
					[58.693230701396807, 1.6277529593700542],
					[58.693230757019506, 1.6277529233128372],
					[58.693325144898559, 1.6276334385898394],
					[58.693550131015712, 1.6274390504435157],
					[58.693550142992386, 1.6274390392409357],
					[58.693550164770599, 1.6274390103703587],
					[58.693550174769065, 1.6274389961242295],
					[58.693614049988106, 1.6273223421873237],
					[58.693672305109786, 1.6272862702234407],
					[58.693672329161551, 1.6272862495289693],
					[58.693672369647665, 1.6272862010986329],
					[58.693672386081978, 1.6272861733627821],
					[58.693833486059198, 1.6268778736931953],
					[58.693961285204082, 1.6265611745461861],
					[58.693961291644243, 1.626561145468757],
					[58.693961297300987, 1.6265610871163512],
					[58.693961294637823, 1.626561056508776],
					[58.693852995097195, 1.6260666576352556],
					[58.693852994998714, 1.6260666559243351],
					[58.693772394985992, 1.6257055567071679],
					[58.693772391624677, 1.6257055452983258],
					[58.693772383022299, 1.6257055211480702],
					[58.693772377879711, 1.6257055101176126],
					[58.693652978824396, 1.6254693108346303],
					[58.693358486038136, 1.6248610261752507],
					[58.693297397592829, 1.6246666622470967],
					[58.693261299618712, 1.6241360864842644],
					[58.693253000301233, 1.62399999035111],
					[58.693250199941232, 1.6238888953597257],
					[58.693250199842737, 1.6238888936488416],
					[58.693250199842737, 1.6238888936488416],
					[58.69322520044188, 1.6232221935406084],
					[58.693222399673097, 1.6231388926054611],
					[58.693222400268191, 1.623138887283555],
					[58.693222397797285, 1.6231388756859024],
					[58.693222398392351, 1.6231388703639964],
					[58.693175198315586, 1.622827769813538],
					[58.693175197030854, 1.6228277631592769],
					[58.693175193669276, 1.6228277517509231],
					[58.693175192384572, 1.6228277450966668],
					[58.69301959161929, 1.6222860444079992],
					[58.693019589345376, 1.6222860362322085],
					[58.693019582027119, 1.6222860187375692],
					[58.693019577873422, 1.6222860092294504],
					[58.692714080255705, 1.621686013277031],
					[58.692600187922707, 1.6214388303415457],
					[58.692464091699577, 1.620969343794304],
					[58.692464089622696, 1.6209693390404036],
					[58.692464087151599, 1.6209693274431487],
					[58.692464085074697, 1.620969322689261],
					[58.692305791209954, 1.6205888381843223],
					[58.692252994905722, 1.6203610551694534],
					[58.692252994807163, 1.6203610534586377],
					[58.692252993916533, 1.620361053647954],
					[58.692252993817981, 1.6203610519371303],
					[58.692097395331238, 1.6197332556062101],
					[58.692008496310777, 1.6192888609253149],
					[58.692008496212203, 1.6192888592145136],
					[58.692008495124455, 1.6192888559822241],
					[58.692008495025881, 1.6192888542714259],
					[58.691911294723987, 1.6188777532009648],
					[58.691911292449866, 1.6188777450256622],
					[58.691911285923226, 1.6188777256321696],
					[58.691911281867867, 1.6188777178355693],
					[58.691852982232376, 1.6187499166616284],
					[58.691852976198611, 1.618749905822185],
					[58.691852964229639, 1.6187498858540712],
					[58.691852957305223, 1.6187498752039806],
					[58.691789057080648, 1.6186692761051531],
					[58.691789041746318, 1.6186692603161115],
					[58.691789011471954, 1.618669235581224],
					[58.691788993662925, 1.6186692237818257],
					[58.691627893865892, 1.6185831240359179],
					[58.691627893767325, 1.6185831223251388],
					[58.691627891986073, 1.6185831227038523],
					[58.691627890996884, 1.6185831211824195],
					[58.691525105305914, 1.618530428339614],
					[58.691333416963332, 1.6183915371550177],
					[58.691333377286405, 1.6183915213467004],
					[58.691333291596173, 1.6183915049310831],
					[58.691333248451919, 1.6183915071772512],
					[58.691127765227094, 1.6184470024291333],
					[58.691094384005162, 1.6184498015157434],
					[58.690886083725054, 1.6184665002467837],
					[58.690886038697556, 1.6184665167468431],
					[58.690885960315818, 1.618466564582193],
					[58.69088592715876, 1.6184665993389828],
					[58.690760926830379, 1.6186832000648743],
					[58.69076092593977, 1.618683200254224],
					[58.690760926038344, 1.6186832019649648],
					[58.690760925147728, 1.6186832021543127],
					[58.690624825008314, 1.6189277034562251],
					[58.690624821642942, 1.618927707635073],
					[58.690624816890633, 1.6189277190355407],
					[58.690624815405116, 1.6189277245464211],
					[58.690544215023742, 1.6191249240514123],
					[58.690444215174246, 1.6193666241815778],
					[58.690444214382183, 1.6193666260816333],
					[58.690444211213944, 1.6193666336818724],
					[58.690444209629824, 1.61936663748198],
					[58.690358109878801, 1.6196249354912222],
					[58.690297016742534, 1.6198055153270088],
					[58.6901387281113, 1.6200720986198782],
					[58.6901387281113, 1.6200720986198782],
					[58.69013872731923, 1.6200721005199004],
					[58.690138726428572, 1.6200721007092196],
					[58.690005425626971, 1.6203055010238558],
					[58.690005423349305, 1.620305508434615],
					[58.690005416023531, 1.6203055221132998],
					[58.690005412855228, 1.6203055297133608],
					[58.689924813028114, 1.6205193285379402],
					[58.68986091419891, 1.6206832273851342],
					[58.689860911129145, 1.620683236695823],
					[58.689860904989587, 1.620683255317217],
					[58.689860902810452, 1.6206832644386249],
					[58.68978590519724, 1.6211055542096209],
					[58.689652608397644, 1.6215555422144403],
					[58.689652604929833, 1.6215555602677727],
					[58.689652600666143, 1.6215555958066277],
					[58.689652599870257, 1.6215556132921658],
					[58.689663698160963, 1.6217249729128276],
					[58.689574811209432, 1.6219805346213394],
					[58.689574811209432, 1.6219805346213394],
					[58.689574810417326, 1.6219805365213009],
					[58.689485932555961, 1.6222498665538201],
					[58.689174896643408, 1.6224359282455214],
					[58.689174877640632, 1.62243594267288],
					[58.689174845274508, 1.622435975524209],
					[58.689174830129836, 1.6224359943266591],
					[58.689152697679035, 1.6224719837801649],
					[58.689133393966017, 1.6224692108784826],
					[58.689102940757287, 1.6224387583518411],
					[58.689102936899211, 1.6224387539767544],
					[58.689102927401727, 1.6224387456050988],
					[58.689102921762363, 1.6224387416085124],
					[58.688805742760401, 1.6222109577601584],
					[58.688677970335299, 1.6220415940351212],
					[58.688564087040952, 1.6218166261645941],
					[58.688500191719697, 1.621599942789246],
					[58.6885001895443, 1.621599936325179],
					[58.688500185292028, 1.621599925107706],
					[58.688500183116645, 1.6215999186436341],
					[58.688372394315714, 1.6213110433794473],
					[58.688319597898513, 1.6209443728207644],
					[58.688294597712023, 1.6207471747870024],
					[58.68829459721929, 1.6207471662338515],
					[58.688294592572788, 1.6207471481740163],
					[58.68829459020025, 1.6207471382887904],
					[58.688264093762527, 1.6206527518721008],
					[58.688208497375676, 1.6203471637484681],
					[58.688208496386487, 1.6203471622271326],
					[58.688208495101669, 1.6203471555739322],
					[58.688208495003124, 1.6203471538632914],
					[58.688100195988319, 1.6198916597281603],
					[58.688044597153478, 1.6195916640146639],
					[58.688044595967156, 1.6195916590721202],
					[58.688044593693114, 1.619591650897678],
					[58.688044591517617, 1.6195916444338241],
					[58.687886294319902, 1.6190360509596753],
					[58.687844599072498, 1.6188499756138417],
					[58.687830799982457, 1.6183499963634145],
					[58.687828000372619, 1.6181777962367188],
					[58.687828000274038, 1.6181777945261226],
					[58.687827999879723, 1.61817778768373],
					[58.687827998890533, 1.6181777861624707],
					[58.687816898760587, 1.6180249865728622],
					[58.687816899355461, 1.6180249812517291],
					[58.687816898070515, 1.618024974598675],
					[58.68781689787334, 1.6180249711774923],
					[58.687733498264222, 1.6174332718551048],
					[58.687733489364639, 1.6174332425793456],
					[58.687733462856436, 1.6174331893425742],
					[58.687733446039836, 1.6174331634816177],
					[58.687694546164742, 1.6173915637043617],
					[58.687694541317278, 1.6173915578088107],
					[58.687694529049068, 1.617391548296333],
					[58.687694523310981, 1.6173915425901397],
					[58.687605722824699, 1.6173220428867379],
					[58.687605718966452, 1.6173220385124145],
					[58.687605708676664, 1.6173220320424444],
					[58.687605704026375, 1.6173220295680841],
					[58.687364004282664, 1.6171748284438521],
					[58.687363946694639, 1.6171748147150387],
					[58.687363836063625, 1.6171748191901634],
					[58.687363781338057, 1.6171748394833951],
					[58.687277681222241, 1.6172387386290863],
					[58.687277665782609, 1.6172387523009597],
					[58.687277640345627, 1.6172387802190864],
					[58.687277627775032, 1.6172387967439938],
					[58.687252633171596, 1.617280490335568],
					[58.68721093691309, 1.6173387835946498],
					[58.687210932755761, 1.6173387896731288],
					[58.687210925430293, 1.6173388033512728],
					[58.687210922163558, 1.6173388092403973],
					[58.687022021986031, 1.6177110082867416],
					[58.687022022084619, 1.6177110099972933],
					[58.687022021194004, 1.6177110101866381],
					[58.687022021194004, 1.6177110101866381],
					[58.686838737069849, 1.6180776788248272],
					[58.686791564797552, 1.6181220530605867],
					[58.686680466170337, 1.6182220505903018],
					[58.686680460231706, 1.6182220570472348],
					[58.686680449245053, 1.6182220697717706],
					[58.686680444098471, 1.6182220743288154],
					[58.686505443709933, 1.6184415754837602],
					[58.686505442819296, 1.6184415756730828],
					[58.686505442027268, 1.6184415775729297],
					[58.686505441136617, 1.6184415777622554],
					[58.686397056858702, 1.6185831579442391],
					[58.686202677943534, 1.6187331417825797],
					[58.68620267705289, 1.6187331419718958],
					[58.686202676162253, 1.6187331421612119],
					[58.686108181576856, 1.6188081380343968],
					[58.685950000026516, 1.6189136595387796],
					[58.685783411263195, 1.6188026344519191],
					[58.685783385241436, 1.6188026209370345],
					[58.685783332008043, 1.618802604548899],
					[58.685783303807249, 1.6188026001544236],
					[58.685500055347916, 1.6187970007218262],
					[58.685241798646302, 1.6186498260709092],
					[58.685047305712594, 1.6185387309251997],
					[58.684691817052148, 1.6182998371417201],
					[58.684597320768248, 1.6182276418368449],
					[58.684597306718871, 1.6182276327034735],
					[58.684597276046802, 1.6182276167151113],
					[58.684597260314725, 1.6182276096708124],
					[58.684544459596182, 1.6182109092544312],
					[58.684544414374955, 1.6182109067470376],
					[58.684544325907424, 1.6182109203577826],
					[58.68454428275971, 1.6182109381863341],
					[58.684405491648732, 1.6183109314101267],
					[58.684202702065448, 1.6184248264489827],
					[58.6842026686125, 1.6184248560673702],
					[58.684202618625072, 1.6184249272904487],
					[58.684202602090537, 1.6184249688951271],
					[58.684149801665846, 1.6187610695029757],
					[58.684149801961539, 1.6187610746342236],
					[58.684149800771706, 1.6187610852753305],
					[58.684149800176804, 1.6187610905958896],
					[58.684130400091462, 1.6191416895896507],
					[58.68413039969365, 1.6191416983310283],
					[58.68413040067928, 1.6191417154352277],
					[58.684130402161273, 1.6191417255084626],
					[58.684135899672043, 1.6191861094055002],
					[58.684108099967119, 1.6199388891719244],
					[58.684099802203114, 1.6200499639680144],
					[58.68400540840576, 1.6203693422991987],
					[58.68400540771222, 1.6203693459092872],
					[58.684005406128065, 1.6203693497086427],
					[58.684005406226596, 1.620369351419056],
					[58.683966506001511, 1.6205277521771975],
					[58.683966505209426, 1.6205277540768648],
					[58.683966505307971, 1.6205277557872797],
					[58.683966505406524, 1.6205277574976931],
					[58.68388041331901, 1.6209221182679987],
					[58.683791534650432, 1.6210526870358808],
					[58.683791533858347, 1.621052688935537],
					[58.683791532175618, 1.6210526910244156],
					[58.683791531284996, 1.6210526912136507],
					[58.683727650828793, 1.6211526616404646],
					[58.683547080855035, 1.6212859389396796],
					[58.683547074817682, 1.6212859436850686],
					[58.683547063732163, 1.62128595469703],
					[58.683547057694824, 1.6212859594424303],
					[58.683491558361055, 1.6213415589739324],
					[58.683491558459586, 1.6213415606843313],
					[58.683285980187847, 1.6215498365763219],
					[58.68317770885767, 1.6216054226442482],
					[58.682863822275237, 1.6217665157027925],
					[58.68283886820894, 1.6217748000972114],
					[58.682811100184239, 1.6217748007335226],
					[58.682811094741915, 1.6217748001584196],
					[58.682811083955812, 1.6217748007185693],
					[58.682811078612033, 1.6217748018538287],
					[58.682400154252051, 1.6218191826415116],
					[58.682389095168439, 1.6217721543085535],
					[58.682389077663061, 1.6217721164767762],
					[58.682389029884014, 1.6217720539134257],
					[58.682389000402438, 1.6217720272822724],
					[58.682336199640417, 1.6217415275490592],
					[58.682336198651264, 1.6217415260279207],
					[58.68233619677148, 1.6217415246960014],
					[58.68233619588085, 1.6217415248852032],
					[58.68207242222698, 1.6215970930293993],
					[58.682116896623484, 1.6213444369002641],
					[58.682186295989332, 1.6209889379938343],
					[58.682186297376433, 1.620988930774053],
					[58.682186299260024, 1.6209889165237275],
					[58.682186299657985, 1.6209889077828412],
					[58.682200199763386, 1.6206528117724717],
					[58.682214098805701, 1.620436114350932],
					[58.682239098651799, 1.6201222154404706],
					[58.68223910023594, 1.6201222116413219],
					[58.682239099841759, 1.6201222047999917],
					[58.682239099546116, 1.6201221996690065],
					[58.682239100089191, 1.6198749999200073],
					[58.682239099593474, 1.6195028007203902],
					[58.682239093961293, 1.6195027655605494],
					[58.682239069337506, 1.6195026980797924],
					[58.682239049553843, 1.6195026676584818],
					[58.682033474811206, 1.6192692957891603],
					[58.681983487569077, 1.619130531611084],
					[58.681983432860925, 1.6191304739869441],
					[58.68198329701081, 1.6191304162940501],
					[58.681983215967378, 1.6191304179356123],
					[58.681911016271961, 1.6191637179814791],
					[58.681911009245518, 1.6191637212059509],
					[58.681910996181806, 1.6191637291759327],
					[58.6819109892539, 1.6191637341107143],
					[58.681869293182295, 1.6191915315195138],
					[58.681719298100646, 1.6192804274411627],
					[58.681719296417953, 1.6192804295299887],
					[58.681719292063391, 1.6192804321866154],
					[58.681719290282132, 1.6192804325651475],
					[58.681524889561075, 1.6194081327325185],
					[58.681524888670445, 1.6194081329217789],
					[58.681524887878375, 1.6194081348213287],
					[58.681524886987745, 1.619408135010586],
					[58.681463787748484, 1.6194498347118118],
					[58.681344548728205, 1.6195302572196566],
					[58.681308498255312, 1.6192971683338713],
					[58.681308497167571, 1.619297165102574],
					[58.681308494992088, 1.619297158639962],
					[58.68130849469641, 1.619297153509123],
					[58.681186294855863, 1.6187805533040871],
					[58.681186292680351, 1.6187805468415293],
					[58.681186288230776, 1.6187805322061624],
					[58.681186285066076, 1.6187805242226108],
					[58.681050200627155, 1.6184443641919819],
					[58.681053000007125, 1.6183528111958694],
					[58.681105798810997, 1.6177889191226345],
					[58.681125196777643, 1.6175806385453355],
					[58.681239091271983, 1.6171917574162604],
					[58.681316891020586, 1.6169472617802281],
					[58.681316890823418, 1.6169472583596893],
					[58.681316891615452, 1.6169472564600968],
					[58.681316891615452, 1.6169472564600968],
					[58.681386286655659, 1.6167083723531177],
					[58.681411278908875, 1.6166583884009906],
					[58.681411278908875, 1.6166583884009906],
					[58.681411279700896, 1.6166583865013837],
					[58.681411281383554, 1.6166583844124367],
					[58.681433422152672, 1.6166113108395328],
					[58.68186104987015, 1.6166473960354997],
					[58.681885997478588, 1.6166584737062899],
					[58.681922066120585, 1.6166862484126527],
					[58.682338755795563, 1.6171195393950351],
					[58.682338812391052, 1.6171195671836671],
					[58.682338929844612, 1.6171195872291844],
					[58.682338993275977, 1.6171195772077449],
					[58.682525057843272, 1.6170223945783275],
					[58.682786119001165, 1.6169973996974492],
					[58.682786149183713, 1.616997391549835],
					[58.682786206285435, 1.6169973655605177],
					[58.682786233303176, 1.6169973494291625],
					[58.682911233395309, 1.6168862503070232],
					[58.683194531876623, 1.616636249905145],
					[58.683194535340512, 1.6166362474374099],
					[58.683194538705813, 1.6166362432592949],
					[58.683194541279079, 1.6166362409808996],
					[58.683219541379025, 1.6166112425657364],
					[58.683230724257562, 1.6166001582580223],
					[58.683258392771528, 1.6165834776639409],
					[58.683583382187585, 1.6164362833449843],
					[58.683677838583023, 1.6163946009181398],
					[58.684024994196108, 1.6164058002537229],
					[58.684025031700791, 1.6164057940111911],
					[58.684025102260243, 1.6164057668898919],
					[58.684025135413627, 1.6164057477215446],
					[58.684127935178132, 1.6163112463399147],
					[58.684127951409714, 1.6163112307692389],
					[58.684127976156333, 1.6163111908802823],
					[58.684127986748386, 1.6163111713145228],
					[58.684252986867193, 1.615980671449929],
					[58.684350184795605, 1.6157278778687134],
					[58.684452979051279, 1.6155000887079027],
					[58.684564075614361, 1.6152944951716144],
					[58.684564078088982, 1.6152944911825913],
					[58.684564081355617, 1.6152944852937656],
					[58.684564082049015, 1.6152944816835149],
					[58.684669581648848, 1.6150611816032849],
					[58.684669587390069, 1.6150611717253662],
					[58.684669594123612, 1.615061147785223],
					[58.684669597094384, 1.6150611367650558],
					[58.684708496780544, 1.6148500356815423],
					[58.684708496681914, 1.6148500339711163],
					[58.684708496583291, 1.6148500322606871],
					[58.684780797411882, 1.6144222333063918],
					[58.684780797809367, 1.6144222245648185],
					[58.684780800286951, 1.6144222049923973],
					[58.684780799793813, 1.6144221964402246],
					[58.684772399814335, 1.6139860957151095],
					[58.684772399715705, 1.6139860940046866],
					[58.684772400409038, 1.6139860903943914],
					[58.684772400310401, 1.613986088683959],
					[58.684750199925489, 1.6135916982255543],
					[58.684752999630717, 1.6135278127982191],
					[58.684783499135115, 1.6131222144647854],
					[58.684783499828448, 1.6131222108544678],
					[58.684783500127217, 1.6131222004024446],
					[58.684783499831283, 1.6131221952711667],
					[58.684775199836032, 1.6127249985629877],
					[58.684775199823079, 1.6126582993844778],
					[58.684775199724449, 1.6126582976740487],
					[58.684775200319123, 1.6126582923533179],
					[58.684775200121813, 1.6126582889324563],
					[58.684753000316505, 1.6122610885377362],
					[58.684752999129927, 1.6122610835959272],
					[58.684752997746031, 1.6122610752332678],
					[58.684752998439343, 1.612261071622958],
					[58.684702998475046, 1.6119221715592353],
					[58.684702996101848, 1.6119221616756776],
					[58.68470299244342, 1.6119221451398973],
					[58.684702990070242, 1.6119221352563238],
					[58.684539089850233, 1.6114360359311213],
					[58.684539088860951, 1.611436034410207],
					[58.684539086685071, 1.611436027947533],
					[58.684539085695803, 1.6114360264266077],
					[58.684527986039875, 1.6114082252369033],
					[58.684527984061326, 1.6114082221950636],
					[58.684527979906889, 1.6114082126905518],
					[58.68452797792834, 1.6114082096486979],
					[58.684525177784323, 1.6114027091437821],
					[58.684525160076035, 1.6114026834775821],
					[58.684525115158692, 1.6114026393607792],
					[58.68452508884026, 1.6114026207206964],
					[58.68451951982685, 1.6113998354850583],
					[58.684516843608932, 1.6113970615435715],
					[58.684516830252385, 1.6113970488027243],
					[58.684516801064802, 1.6113970273102804],
					[58.684516785233768, 1.6113970185586803],
					[58.684069485066736, 1.6111859197423091],
					[58.684069446674421, 1.6111859105970026],
					[58.684069369788546, 1.6111859061789338],
					[58.684069331393644, 1.6111859126165897],
					[58.683963831323965, 1.6112248123330293],
					[58.683963829542726, 1.6112248127120068],
					[58.683963827761502, 1.611224813090989],
					[58.683963827860161, 1.6112248148013639],
					[58.68367778816328, 1.6113358904808672],
					[58.683625051316568, 1.6113220075832804],
					[58.683625043994347, 1.6113220056784048],
					[58.683625031131136, 1.6113220014897098],
					[58.683625023907574, 1.6113220012951919],
					[58.683555624089443, 1.6113137013756713],
					[58.68326955708536, 1.6112776184611226],
					[58.683169592644276, 1.6109193465391689],
					[58.683169591753661, 1.6109193467286536],
					[58.683169591556315, 1.6109193433079607],
					[58.683108500806782, 1.6107110752127518],
					[58.683114100003138, 1.6106028096156739],
					[58.683114099608432, 1.6106028027743167],
					[58.683114099709648, 1.6106027889021304],
					[58.683114099314956, 1.6106027820607731],
					[58.683102999220438, 1.6104777818630558],
					[58.683102999121765, 1.6104777801527221],
					[58.683102999023092, 1.6104777784423916],
					[58.683102998924419, 1.6104777767320531],
					[58.683041899836603, 1.6099693854462327],
					[58.683036300054894, 1.6097416951846983],
					[58.683036298670856, 1.609741686822586],
					[58.683036297782685, 1.6097416714296078],
					[58.68303629738795, 1.6097416645882776],
					[58.682972396797958, 1.6093943671959241],
					[58.682894597629414, 1.6088860706123504],
					[58.682894595058606, 1.6088860573088806],
					[58.6828945892238, 1.6088860343120899],
					[58.682894585069207, 1.6088860248083221],
					[58.682694584819892, 1.6083999246486789],
					[58.682694569782861, 1.6083998984166821],
					[58.682694533968089, 1.6083998558311015],
					[58.682694512894216, 1.6083998343465755],
					[58.682552926746105, 1.608302644499209],
					[58.682286237637797, 1.6080470564175762],
					[58.682286235659184, 1.6080470533761198],
					[58.682286229920713, 1.6080470476723054],
					[58.682286227051485, 1.6080470448204054],
					[58.682255750135539, 1.6080220635079276],
					[58.682239078143418, 1.6079971062283394],
					[58.681919586424264, 1.6071999260143619],
					[58.681919586325556, 1.6071999243040949],
					[58.681919584544339, 1.607199924683244],
					[58.681919585434947, 1.6071999244936703],
					[58.681777985099124, 1.606852724406876],
					[58.681572385292583, 1.6063499236094532],
					[58.681572384303259, 1.6063499220887887],
					[58.681572383215183, 1.6063499188579002],
					[58.681572382225866, 1.6063499173372546],
					[58.68145848206769, 1.6060971184169786],
					[58.68145847494489, 1.6060971043520205],
					[58.681458458126222, 1.6060970785011557],
					[58.681458448331625, 1.6060970650050126],
					[58.681319548331416, 1.6059442652328484],
					[58.681319521913593, 1.6059442448882442],
					[58.681319465118619, 1.6059442136982001],
					[58.681319433850888, 1.6059442030423781],
					[58.681111133658462, 1.6059081027899738],
					[58.681111111097259, 1.6059081023993476],
					[58.681111065182989, 1.6059081035179503],
					[58.68111104202741, 1.6059081084475906],
					[58.681083243615063, 1.6059165073742161],
					[58.680869345241554, 1.6059776079863637],
					[58.680869315356091, 1.6059776212735268],
					[58.680869261619897, 1.6059776586817656],
					[58.680869238659767, 1.6059776826132162],
					[58.680747038943856, 1.6061442813020257],
					[58.680563739158949, 1.6063915811034988],
					[58.6805637308453, 1.6063915932605297],
					[58.680563717879153, 1.6063916185264568],
					[58.680563712434768, 1.6063916335351127],
					[58.680508112259403, 1.6065471303043954],
					[58.680430413340808, 1.6067527296878092],
					[58.680430410965165, 1.6067527353871167],
					[58.680430407104495, 1.6067527465961373],
					[58.680430405718177, 1.6067527538160584],
					[58.680347006351376, 1.607099952119996],
					[58.680347003975733, 1.6070999578192684],
					[58.680347002786853, 1.6070999684595262],
					[58.680347002192377, 1.6070999737796638],
					[58.680324801618525, 1.6072638725869561],
					[58.680324801418926, 1.6072638847478324],
					[58.680324799832988, 1.6072639041285837],
					[58.680324801315905, 1.6072639142001599],
					[58.680338700816641, 1.607452814223407],
					[58.68033870091535, 1.6074528159335912],
					[58.680338701014072, 1.6074528176437755],
					[58.680338701112788, 1.6074528193539614],
					[58.680394200809964, 1.6080278183664749],
					[58.680394200908673, 1.6080278200766656],
					[58.680394201007374, 1.6080278217868562],
					[58.680394201106083, 1.6080278234970611],
					[58.680405401412791, 1.6081222243624571],
					[58.680405402796922, 1.608122232723892],
					[58.680405406554492, 1.6081222509674187],
					[58.680405408927925, 1.6081222608495085],
					[58.680447008872221, 1.6082556604636091],
					[58.680447010949514, 1.6082556652151208],
					[58.680447015104185, 1.6082556747181553],
					[58.680447017181493, 1.6082556794696798],
					[58.680758112672784, 1.6089694707046072],
					[58.680908109549449, 1.6094250623819371],
					[58.680908110637418, 1.6094250656128766],
					[58.680908112714725, 1.6094250703645265],
					[58.680908113802694, 1.6094250735954692],
					[58.681063702887933, 1.6098222445518515],
					[58.681085899590826, 1.6101500134033964],
					[58.681085900678781, 1.6101500166343867],
					[58.681085901964103, 1.6101500232858219],
					[58.681085902260136, 1.6101500284165384],
					[58.681160898285263, 1.6106778006520797],
					[58.681110902018204, 1.6110388735018728],
					[58.681110902116885, 1.6110388752121096],
					[58.681085901844327, 1.6112388758666381],
					[58.681085901151029, 1.6112388794765962],
					[58.681085900655091, 1.6112388865070071],
					[58.681085899863128, 1.6112388884067301],
					[58.681069201842405, 1.611544365278663],
					[58.681019208388527, 1.6117166430939707],
					[58.681019207596592, 1.6117166449936731],
					[58.681019207695257, 1.6117166467039066],
					[58.680935908399285, 1.6120110466111928],
					[58.680935906716719, 1.6120110487003148],
					[58.680935905231451, 1.6120110542099038],
					[58.680935905428775, 1.6120110576303868],
					[58.680874805064619, 1.6122916574393897],
					[58.680847007084751, 1.6124166484188132],
					[58.680713710199967, 1.6128388367698878],
					[58.680685912252052, 1.6129166322075692],
					[58.680685909777488, 1.6129166361962997],
					[58.680685907598857, 1.6129166453156973],
					[58.680685906014929, 1.612916649114978],
					[58.680588706220917, 1.6132888493846178],
					[58.680588706319554, 1.6132888510948387],
					[58.680588704834221, 1.6132888566043417],
					[58.680588704042236, 1.6132888585039726],
					[58.680563703897221, 1.6134082597066715],
					[58.6805026048334, 1.6136971543783167],
					[58.680424806719948, 1.6139943476680838],
					[58.680377609304031, 1.614161041293052],
					[58.680255409929913, 1.6145332386905826],
					[58.680255409929913, 1.6145332386905826],
					[58.680255409039283, 1.6145332388799656],
					[58.680141508700245, 1.6148916389688441],
					[58.680141508798862, 1.6148916406790665],
					[58.68014150810545, 1.6148916442888497],
					[58.680141507313458, 1.6148916461884153],
					[58.680102614878706, 1.6150332177190199],
					[58.680083131394603, 1.6150637935370598],
					[58.680083127335948, 1.6150638013247005],
					[58.680083118328028, 1.6150638170893041],
					[58.680083114368024, 1.6150638265871418],
					[58.680072020901925, 1.6150916092428891],
					[58.679897029286813, 1.6153776957469888],
					[58.679844231207781, 1.6154637936162517],
					[58.679630467260374, 1.6157970368539323],
					[58.679580527187049, 1.6158165136988047],
					[58.679580526296441, 1.6158165138881415],
					[58.679580524515174, 1.6158165142668264],
					[58.679580523624558, 1.6158165144561647],
					[58.679311023831396, 1.6159276142068977],
					[58.679311017695653, 1.6159276172423984],
					[58.679311004533545, 1.6159276235027271],
					[58.679310999288418, 1.6159276263488958],
					[58.679210998611097, 1.6159859276327779],
					[58.679210993365956, 1.6159859304789512],
					[58.679210981193087, 1.6159859382600685],
					[58.679210975254534, 1.6159859447158949],
					[58.679183330306238, 1.6160080197013751],
					[58.679122355316927, 1.6159331740999145],
					[58.679122346709761, 1.6159331655428992],
					[58.679122331375225, 1.6159331497604146],
					[58.679122321877401, 1.6159331413927585],
					[58.678730721613142, 1.6156304419596932],
					[58.678730666302506, 1.6156304208271512],
					[58.678730550626895, 1.6156304159897708],
					[58.678730492933809, 1.6156304317169028],
					[58.67860275237522, 1.6157108935568976],
					[58.678280621451471, 1.6156776012503014],
					[58.678280569598293, 1.615677608811283],
					[58.678280478255665, 1.6156776507333426],
					[58.678280437678374, 1.6156776818634844],
					[58.678191539182116, 1.6157998805030014],
					[58.678058139741694, 1.6159776799474783],
					[58.678058130635058, 1.6159776940007924],
					[58.678058116181447, 1.6159777247703611],
					[58.678058108954666, 1.6159777401551509],
					[58.677974809403949, 1.61624433998329],
					[58.677974808611921, 1.6162443418827126],
					[58.677974808611921, 1.6162443418827126],
					[58.677841509026315, 1.6166805415970928],
					[58.677841506650239, 1.6166805472952952],
					[58.677841503876451, 1.6166805617333437],
					[58.677841502390962, 1.6166805672422457],
					[58.677822003315882, 1.6168027664003952],
					[58.677813703070548, 1.6168499656281059],
					[58.677813702077998, 1.6168499796875044],
					[58.677813700983528, 1.6168500076170349],
				],
			],
		},
		{
			fldNpdidField: "38542241",
			wlbNpdidWellbore: "6951",
			fldName: "ÆRFUGL NORD",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6507/3-9 S",
			fldDiscoveryYear: "2012",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=38542241",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=38542241",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "212 E",
			cmpLongName: "Aker BP ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=6951",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "28544099",
			fldNpdidOwner: "4966902",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Sandnessjøen",
			fldAreaGeometryWKT: [
				[
					[65.908852602136662, 7.7084860731312537],
					[65.908852604244274, 7.7084861103523705],
					[65.908852620997607, 7.708486181967416],
					[65.908852635624939, 7.7084862141665553],
					[65.910980424315838, 7.711552897407544],
					[65.912316516283184, 7.714666778684113],
					[65.912316520859576, 7.7146667894347685],
					[65.912316531787738, 7.7146668086510468],
					[65.912316538139535, 7.7146668171166874],
					[65.913641537883336, 7.7165029169911046],
					[65.913641540610769, 7.7165029212468088],
					[65.913641546047202, 7.7165029275630372],
					[65.913641548774621, 7.7165029318187424],
					[65.915230429389808, 7.7183279072925544],
					[65.916572015879183, 7.721441778713209],
					[65.916572018624692, 7.721441785165406],
					[65.91657202501267, 7.7214417980250438],
					[65.91657202863685, 7.7214418022370257],
					[65.918433118242334, 7.7245250853744221],
					[65.919502610907912, 7.7276528654401924],
					[65.919502613671369, 7.7276528740895083],
					[65.919502621889023, 7.7276528912545484],
					[65.919502627343206, 7.7276528997702929],
					[65.920569220548032, 7.7295083894762424],
					[65.921372015829832, 7.731375078229803],
					[65.92217201308442, 7.7332444727679661],
					[65.92324701080058, 7.7363750646322824],
					[65.923247013563568, 7.7363750732838028],
					[65.923247021780256, 7.7363750904541453],
					[65.923247026337066, 7.7363750990172129],
					[65.924308120750865, 7.7382278891735439],
					[65.924841515778908, 7.739475079437133],
					[65.92484152306227, 7.7394750922612898],
					[65.924841540337823, 7.7394751199734424],
					[65.924841549397001, 7.7394751305131742],
					[65.926963748964454, 7.7419084300584089],
					[65.926963785989585, 7.741908459001972],
					[65.926963867017264, 7.7419084923766741],
					[65.926963911037831, 7.7419084990041993],
					[65.929588876894854, 7.741758501928242],
					[65.931433152695291, 7.742291866258487],
					[65.93250261642045, 7.7447861785667413],
					[65.932502620061967, 7.7447861849814723],
					[65.932502628259783, 7.7447861999638707],
					[65.932502631919178, 7.744786208575448],
					[65.934097032157879, 7.7472501080639207],
					[65.93409703942271, 7.7472501186979184],
					[65.934097053019642, 7.7472501356157926],
					[65.934097060266581, 7.7472501440528054],
					[65.935949837073537, 7.7490612200208941],
					[65.937544222341671, 7.7521611915485966],
					[65.937544235059008, 7.7521612107123481],
					[65.937544264045627, 7.7521612444704537],
					[65.937544280314938, 7.752161259064807],
					[65.939133179580949, 7.7533501596354464],
					[65.941513750961903, 7.755133438225398],
					[65.942305428788927, 7.7563640033181409],
					[65.94337482661166, 7.758222301070778],
					[65.943374851956065, 7.758222328424023],
					[65.943374909749139, 7.7582223739912992],
					[65.943374943991685, 7.7582223921183218],
					[65.94521383230952, 7.7587584880768627],
					[65.946533221155164, 7.7593223850714406],
					[65.946533265174892, 7.759322391733634],
					[65.946533353072496, 7.7593223874735289],
					[65.946533396053425, 7.7593223765947021],
					[65.947838996401643, 7.7586112762715906],
					[65.947839024907637, 7.758611250699718],
					[65.947839072897295, 7.7586111933964066],
					[65.947839090569346, 7.7586111595538183],
					[65.948866890814529, 7.7553611607845312],
					[65.948866895138906, 7.7553611407820995],
					[65.948866899285406, 7.7553610987970627],
					[65.948866899125221, 7.755361079012701],
					[65.948586298870609, 7.7528221798949479],
					[65.948294599158487, 7.7496443817573626],
					[65.94829459819006, 7.7496443730083389],
					[65.948294595338425, 7.7496443533559205],
					[65.948294591697177, 7.7496443469365408],
					[65.94776129169243, 7.7477610450229042],
					[65.947761280714687, 7.7477610191715183],
					[65.947761251637559, 7.7477609744142404],
					[65.947761235296269, 7.7477609510242793],
					[65.946441851997662, 7.7465581680172821],
					[65.944314106340215, 7.7434832449410065],
					[65.944558499203239, 7.7409167188408095],
					[65.944558499041221, 7.7409166990598859],
					[65.944558494196656, 7.7409166553228586],
					[65.944558488653215, 7.7409166358065997],
					[65.943480789344719, 7.7377832350216602],
					[65.943480785684912, 7.7377832264073918],
					[65.943480778365313, 7.7377832091788727],
					[65.943480773808574, 7.7377832006088223],
					[65.942422389439443, 7.7359277282511352],
					[65.941880795770075, 7.7334055580603751],
					[65.941880794873185, 7.7334055581047441],
					[65.941064100443569, 7.729622177797979],
					[65.941039101795326, 7.7270695896206316],
					[65.941825010964109, 7.7270251991872172],
					[65.941825011861042, 7.7270251991426244],
					[65.942877747316345, 7.7269641021700997],
					[65.944724855669563, 7.7281362442874322],
					[65.945524818108197, 7.7293694851949182],
					[65.946327609903449, 7.7318778613789068],
					[65.94632761083659, 7.7318778657305369],
					[65.946327613599848, 7.7318778743893217],
					[65.94632761632684, 7.7318778786520888],
					[65.947127616220698, 7.7337472800557556],
					[65.947127618947633, 7.7337472843188335],
					[65.947127624419579, 7.7337472950431003],
					[65.947127627146514, 7.7337472993061773],
					[65.948197020903834, 7.7356028890662136],
					[65.949263715907364, 7.7380972795949043],
					[65.949263740394485, 7.7380973113751796],
					[65.949263799180542, 7.7380973678544995],
					[65.949263834340314, 7.7380973881127506],
					[65.95084423562237, 7.7386473540316656],
					[65.951385907947781, 7.7405306541177676],
					[65.951385919841684, 7.7405306821240094],
					[65.951385950732657, 7.7405307289895369],
					[65.951385969765809, 7.7405307522457436],
					[65.953499870356552, 7.7423279520259163],
					[65.953499902807067, 7.7423279702268744],
					[65.953499973896541, 7.7423279953269661],
					[65.953500010723687, 7.7423280001156138],
					[65.955077810570558, 7.7422391006553113],
					[65.955077837441806, 7.7422390949351536],
					[65.955077886645824, 7.74223907711891],
					[65.955077910772417, 7.7422390649346484],
					[65.956122311208219, 7.7415418661741828],
					[65.956122332572036, 7.7415418453255977],
					[65.956122370779141, 7.7415417994512792],
					[65.956122385810644, 7.7415417723148776],
					[65.957152985951296, 7.7389306719624411],
					[65.95715299118848, 7.738930654105511],
					[65.957152998057225, 7.7389306163695419],
					[65.957152999706793, 7.7389305986894579],
					[65.957130800141428, 7.7363749973213389],
					[65.957114100378092, 7.7338222129216154],
					[65.957611296175102, 7.7299611410181654],
					[65.958380784834333, 7.7273639798768015],
					[65.96071687162474, 7.7233973007938728],
					[65.960716876054505, 7.7233972939723303],
					[65.960716883120156, 7.7233972804187463],
					[65.960716885756113, 7.723397273686702],
					[65.961486286411102, 7.7214361719429228],
					[65.961486293275499, 7.7214361341958977],
					[65.961486291756998, 7.7214360594639437],
					[65.961486284252743, 7.7214360202348482],
					[65.961219583955156, 7.7208138201994778],
					[65.961219571215665, 7.7208137988344401],
					[65.961219538579769, 7.7208137586624792],
					[65.961219519580283, 7.7208137398106844],
					[65.960425119848111, 7.7202192397516729],
					[65.960425089207178, 7.7202192236834239],
					[65.960425022653624, 7.7202192050115155],
					[65.960424987619689, 7.7202192001637613],
					[65.959380829218048, 7.7202803863331244],
					[65.959625196358061, 7.7183500425544436],
					[65.960655790638128, 7.7150972606899488],
					[65.960655794828696, 7.7150972252768515],
					[65.960655791550082, 7.7150971550363021],
					[65.960655784080956, 7.7150971202088465],
					[65.960122383925423, 7.7138499199356536],
					[65.960122382113227, 7.7138499178266278],
					[65.96012237755518, 7.7138499092553285],
					[65.960122375724609, 7.7138499049471125],
					[65.958794576065955, 7.7113721054536288],
					[65.958794569713973, 7.7113720969735482],
					[65.958794556991577, 7.7113720778143326],
					[65.958794549724303, 7.7113720671803527],
					[65.958258464262727, 7.7107609838638602],
					[65.957200173738499, 7.708908201291651],
					[65.957200134839738, 7.7089081636568917],
					[65.957200042876394, 7.7089081111009534],
					[65.957199988951942, 7.7089081006228746],
					[65.956672285997143, 7.7089358948922362],
					[65.956139091266664, 7.7083305145510561],
					[65.955580798385256, 7.7038888750624706],
					[65.955580788222917, 7.703888840381504],
					[65.955580756312727, 7.7038887804053626],
					[65.955580735443249, 7.7038887528659474],
					[65.95426129891581, 7.7026888120582608],
					[65.954239099883623, 7.7001360979007263],
					[65.954239098875206, 7.7001360847542326],
					[65.954239095064622, 7.7001360585523644],
					[65.954239092262426, 7.7001360454969898],
					[65.953705792110114, 7.6982499451547017],
					[65.953705783870575, 7.6982499257776835],
					[65.953705762888418, 7.6982498850531105],
					[65.953705750183062, 7.6982498681028417],
					[65.953169550586978, 7.6976415681012034],
					[65.952644550964777, 7.6970331696211085],
					[65.952644547339958, 7.6970331654067046],
					[65.9526445391934, 7.6970331570235455],
					[65.952644534671691, 7.6970331528548082],
					[65.951325135441564, 7.6958331520914154],
					[65.951325101173239, 7.6958331318439734],
					[65.951325028282596, 7.6958331069665897],
					[65.951324988744801, 7.6958331001839086],
					[65.950272189412487, 7.6958915001898527],
					[65.950272187618665, 7.695891500281256],
					[65.950272186721804, 7.6958915003269555],
					[65.949744527839371, 7.6959247926205379],
					[65.948944600130176, 7.6940527586613099],
					[65.948928000142814, 7.6921388979157364],
					[65.94892799915246, 7.6921388869705174],
					[65.948927996237401, 7.6921388607294316],
					[65.948927993434538, 7.6921388476776427],
					[65.947577995172665, 7.6871082569821434],
					[65.947028002270457, 7.6839471970403546],
					[65.947280777007251, 7.6820168910060636],
					[65.948066710706442, 7.6819724001972158],
					[65.948066756353427, 7.6819723868512053],
					[65.948066834024601, 7.6819723410687812],
					[65.948066867842485, 7.6819723085399776],
					[65.949364068372816, 7.6799807087908025],
					[65.949364080664978, 7.6799806773678521],
					[65.949364097158522, 7.6799806127400405],
					[65.949364098650335, 7.6799805774756917],
					[65.949080799366044, 7.6774416807022332],
					[65.948791914860664, 7.6736279887449594],
					[65.949577811011537, 7.6735835007798912],
					[65.949577887783235, 7.6735834550157671],
					[65.949577978184436, 7.6735833249743584],
					[65.949577990001188, 7.6735832385917879],
					[65.948775189910634, 7.6710749386289487],
					[65.948775162624131, 7.6710748960623398],
					[65.948775092059279, 7.6710748293527464],
					[65.948775046987151, 7.6710748053029141],
					[65.946669467976179, 7.6705609102568362],
					[65.944297291267304, 7.6694220227864109],
					[65.943236196231055, 7.6688442234427843],
					[65.943236136922621, 7.6688442133343102],
					[65.943236023075585, 7.668844225849802],
					[65.943235967659163, 7.668844250718081],
					[65.940894373212984, 7.6708969562410037],
					[65.937727895586434, 7.6691665234170285],
					[65.937727813893915, 7.6691665188695657],
					[65.937727672547808, 7.6691665679812786],
					[65.937727613829125, 7.6691666259882796],
					[65.936949921127976, 7.6711274583791331],
					[65.934302934108288, 7.6687276518616452],
					[65.934302899836368, 7.6687276316637538],
					[65.934302826941376, 7.6687276068795676],
					[65.934302788299263, 7.6687276000963811],
					[65.932472188010863, 7.6688358999462727],
					[65.932472162039289, 7.6688359056915054],
					[65.932472112824826, 7.6688359214357158],
					[65.932472088704131, 7.6688359336780776],
					[65.93141928931604, 7.6695359325261645],
					[65.931419283972772, 7.6695359371990355],
					[65.931419273286224, 7.6695359465447979],
					[65.931419267923957, 7.6695359490209807],
					[65.930641567709984, 7.6702220490847557],
					[65.930641547290719, 7.6702220743184419],
					[65.930641514581211, 7.67022213095668],
					[65.930641504084718, 7.6702221622681197],
					[65.930388703901571, 7.6715110624588849],
					[65.930388702278734, 7.6715110823213521],
					[65.930388701723672, 7.6715111219067325],
					[65.930388704585255, 7.6715111415366426],
					[65.931205398433875, 7.6752917142764572],
					[65.930960904894775, 7.6784999253414865],
					[65.930441553921582, 7.6791665623329513],
					[65.92965816893404, 7.6798498482467155],
					[65.929658165384211, 7.6798498528246766],
					[65.929658159143742, 7.6798498575413143],
					[65.929658155593941, 7.6798498621192754],
					[65.928352656090581, 7.6812026608246349],
					[65.928352635707242, 7.6812026904401822],
					[65.928352609328428, 7.681202753325481],
					[65.928352599764281, 7.6812027889763392],
					[65.928121999588555, 7.6850471888568368],
					[65.928122000635838, 7.6850472063821895],
					[65.928122006336736, 7.6850472434451609],
					[65.92812201009346, 7.6850472630288351],
					[65.928933109731844, 7.6875528629940844],
					[65.928933113394464, 7.6875528715962576],
					[65.928933121635325, 7.6875528909511859],
					[65.928933126194835, 7.6875528995074314],
					[65.92999152132073, 7.6894028888062813],
					[65.930794199564872, 7.6912722428367939],
					[65.930824800430258, 7.6957388802922955],
					[65.930319206350688, 7.6983193513780046],
					[65.929549822110104, 7.7009165959743511],
					[65.928244256396681, 7.7022692604638054],
					[65.928244246623578, 7.7022692719457764],
					[65.928244229768083, 7.7022692947734255],
					[65.928244222704222, 7.7022693083155476],
					[65.927216616792563, 7.7042441269929069],
					[65.924050095899418, 7.7025109253592099],
					[65.92405006979665, 7.7025109156957408],
					[65.924050015852984, 7.7025109030478989],
					[65.924049988012101, 7.702510900063527],
					[65.922997246183769, 7.7025719961282579],
					[65.920891826801721, 7.7014165415066387],
					[65.918769565446397, 7.6989859859228646],
					[65.917433475873111, 7.6965110051763173],
					[65.917433474957562, 7.6965110030264139],
					[65.917433472229632, 7.6965109987721947],
					[65.917433471314098, 7.6965109966222904],
					[65.915572370685695, 7.6934276966969728],
					[65.915572331783068, 7.6934276591462449],
					[65.91557224074846, 7.6934276110747231],
					[65.915572187719576, 7.6934276005996427],
					[65.91478614500798, 7.6934747975018558],
					[65.913727917084827, 7.6928970351830639],
					[65.91240573373382, 7.691699852259962],
					[65.91240565813284, 7.6916998253731954],
					[65.912405511995104, 7.6916998394183187],
					[65.912405442317819, 7.6916998759143071],
					[65.911888742056931, 7.6923665768355063],
					[65.911888729668618, 7.6923665972312376],
					[65.911888711188951, 7.6923666398975419],
					[65.911888704200706, 7.6923666622138356],
					[65.911383103838261, 7.6949471614263016],
					[65.911383102119075, 7.6949471702975956],
					[65.911383099596307, 7.6949471901895228],
					[65.911383099689559, 7.6949472011645081],
					[65.911391499968119, 7.6968610919788345],
					[65.911155402928813, 7.6994249621525919],
					[65.909874807707666, 7.7039638437361209],
					[65.909102608341755, 7.7065582421562544],
					[65.909102605725025, 7.7065582510712041],
					[65.909102603163717, 7.70655826657062],
					[65.909102602322292, 7.7065582732002813],
					[65.908852602136662, 7.7084860731312537],
				],
			],
		},
		{
			fldNpdidField: "43485",
			wlbNpdidWellbore: "285",
			fldName: "HOD",
			fldCurrentActivitySatus: "Producing",
			wlbName: "2/11-2",
			fldDiscoveryYear: "1974",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43485",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43485",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "033",
			cmpLongName: "Aker BP ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=285",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "28544099",
			fldNpdidOwner: "21052",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Tananger",
			fldAreaGeometryWKT: [
				[
					[56.17631919974874, 3.4303722047150149],
					[56.176319199966052, 3.4303722095241551],
					[56.17638590011024, 3.4317250102573551],
					[56.176385901656225, 3.4317250245549809],
					[56.176385909364001, 3.4317250557071555],
					[56.176385912698386, 3.4317250697450943],
					[56.177105413302058, 3.4336361700485352],
					[56.177105427051522, 3.4336361954838757],
					[56.177105461559229, 3.4336362421100413],
					[56.177105483211633, 3.4336362631710577],
					[56.178360982805259, 3.4345362632184737],
					[56.178360993897535, 3.4345362696768276],
					[56.178361015043109, 3.4345362795170233],
					[56.178361025241195, 3.4345362861051552],
					[56.17919712494502, 3.4348723865972817],
					[56.179197133137542, 3.4348723886356467],
					[56.179197150416783, 3.4348723925826228],
					[56.179197157715102, 3.4348723947507671],
					[56.180738858335111, 3.4352057955037698],
					[56.180738863772653, 3.4352057963283595],
					[56.180738877402732, 3.4352057991914609],
					[56.180738882767891, 3.4352057984128108],
					[56.181997183370825, 3.435314098924152],
					[56.181997197750334, 3.4353140984509793],
					[56.181997229191914, 3.4353140971153029],
					[56.181997244393216, 3.435314094909061],
					[56.183488944166868, 3.434975194785709],
					[56.183488951320413, 3.4349751937473583],
					[56.18348896458857, 3.4349751885937243],
					[56.183488971742108, 3.4349751875553758],
					[56.187602872267632, 3.4333918864475792],
					[56.187602902018426, 3.4333918676009376],
					[56.187602954826247, 3.4333918211954861],
					[56.187602976989055, 3.4333917937665173],
					[56.188016876779322, 3.4326000930441642],
					[56.188016881709942, 3.43260008264371],
					[56.188016889782794, 3.432600062102543],
					[56.188016892852602, 3.4326000503582992],
					[56.18825579171439, 3.4316972574800726],
					[56.188671673210266, 3.4304604936574941],
					[56.188488702436409, 3.4316971707550374],
					[56.188488720075355, 3.4316972424402459],
					[56.188488799942768, 3.4316973567362852],
					[56.188488936099155, 3.4316973821516159],
					[56.188489052346192, 3.4316973055440751],
					[56.188489094592903, 3.4316972445284701],
					[56.188961295181613, 3.4296694454657461],
					[56.188961295713497, 3.4296694373179553],
					[56.188961298565665, 3.4296694207624938],
					[56.188961299991732, 3.4296694124847598],
					[56.189141899277018, 3.4268500144009311],
					[56.189611298765819, 3.4214417171218692],
					[56.189611300263891, 3.4214417104472927],
					[56.189611299610796, 3.4214416960151381],
					[56.1896113002147, 3.4214416894707007],
					[56.189491900353381, 3.4193027892614589],
					[56.189425200337723, 3.4176110923139524],
					[56.18942519922571, 3.417611087633504],
					[56.189425197823233, 3.4176110765388468],
					[56.189425198426925, 3.4176110699943942],
					[56.189189098285716, 3.4160360738474216],
					[56.189005798780805, 3.4144582775297292],
					[56.189005797668671, 3.4144582728494339],
					[56.18900579626591, 3.4144582617550276],
					[56.189005795153776, 3.4144582570747302],
					[56.188466895131491, 3.4119832564520736],
					[56.188466895131491, 3.4119832564520736],
					[56.188466894091945, 3.4119832533754333],
					[56.188466894019264, 3.411983251771936],
					[56.187755793643539, 3.4091666512445058],
					[56.187755787333188, 3.4091666311819862],
					[56.187755769565747, 3.4091665966498175],
					[56.187755759824242, 3.4091665803158753],
					[56.186914059785011, 3.408041579506401],
					[56.186914047433639, 3.4080415651678337],
					[56.186914017438731, 3.4080415388767431],
					[56.186914000689356, 3.4080415267937973],
					[56.185955701164943, 3.4074831270303592],
					[56.18595567279155, 3.4074831166437241],
					[56.185955617302596, 3.4074831037569009],
					[56.185955588326017, 3.4074830999142356],
					[56.184049988352783, 3.4075998006723891],
					[56.184049961600955, 3.4075998061887018],
					[56.184049911892131, 3.4075998215094718],
					[56.184049888186472, 3.4075998346510064],
					[56.182205488352636, 3.4088470337314392],
					[56.182205481417576, 3.4088470395843],
					[56.182205466653315, 3.408847051420397],
					[56.182205459718233, 3.4088470572732601],
					[56.180591560095934, 3.4104276566828573],
					[56.180591559274475, 3.410427658416388],
					[56.180591558453031, 3.4104276601499031],
					[56.180591556664709, 3.4104276604106061],
					[56.179049856940338, 3.4120081602731793],
					[56.179049849256295, 3.4120081694614779],
					[56.17904983478239, 3.4120081877077606],
					[56.179049827992515, 3.4120081967657505],
					[56.178038728001162, 3.413699897274868],
					[56.178038724642498, 3.4136999026051167],
					[56.178038719713456, 3.4136999130051242],
					[56.178038717248938, 3.4136999182051126],
					[56.177385916702875, 3.4151666184081808],
					[56.177385912740526, 3.415166630280642],
					[56.177385904888446, 3.4151666556285822],
					[56.177385902714434, 3.4151666672405931],
					[56.176908103031145, 3.4180943677668552],
					[56.176908102209573, 3.4180943695000421],
					[56.176908102354773, 3.4180943727061046],
					[56.176908102354773, 3.4180943727061046],
					[56.176558101744313, 3.4206860726491053],
					[56.176558101067833, 3.4206860775882717],
					[56.176558101430665, 3.42068608560341],
					[56.176558099714896, 3.4206860874666187],
					[56.176433099743733, 3.4228277882441529],
					[56.176433100033876, 3.4228277946562544],
					[56.176433099574929, 3.4228278044044789],
					[56.176433099792547, 3.4228278092135556],
					[56.176560900033856, 3.4255306007952422],
					[56.176435900263741, 3.4280082894014661],
					[56.176435900408677, 3.4280082926075623],
					[56.176435899804389, 3.4280082991496412],
					[56.17643589987685, 3.4280083007526971],
					[56.176441499890124, 3.4287971934582591],
					[56.176319200667827, 3.4303721852187579],
					[56.176319200063404, 3.4303721917608025],
					[56.17631919974874, 3.4303722047150149],
				],
				[
					[56.171247000051203, 3.4936722068695643],
					[56.171247001446787, 3.4936722179622235],
					[56.17154980118778, 3.496941717753161],
					[56.171788700892243, 3.5002056155211729],
					[56.171788701035311, 3.500205618727255],
					[56.171788702215736, 3.5002056250112177],
					[56.171788702358796, 3.5002056282173175],
					[56.172208101669582, 3.5031306284341586],
					[56.172208106033217, 3.5031306455553084],
					[56.172208115583267, 3.5031306780663916],
					[56.172208123452585, 3.5031306930718422],
					[56.173277622939082, 3.5051583930330725],
					[56.173277647691748, 3.5051584233676651],
					[56.173277706534137, 3.5051584714057542],
					[56.173277740695312, 3.5051584907123843],
					[56.174005541333848, 3.5053834910949764],
					[56.174005555857093, 3.5053834938546546],
					[56.174005584903547, 3.5053834993740041],
					[56.174005600249608, 3.5053835004024099],
					[56.175369399694553, 3.505383499376864],
					[56.1753694442667, 3.5053834897645504],
					[56.175369524433236, 3.5053834508513027],
					[56.175369559133301, 3.5053834216784701],
					[56.176925158968992, 3.5033557214654394],
					[56.176925169879915, 3.5033557037665415],
					[56.176925187981617, 3.5033556656748659],
					[56.176925195172373, 3.5033556452820949],
					[56.177819592646266, 3.4995250526270354],
					[56.178702989356303, 3.4967111641931066],
					[56.179539084025222, 3.4944556777547167],
					[56.180669556779172, 3.4920918347768888],
					[56.182994511896716, 3.4905112649962393],
					[56.182994519730229, 3.4905112590292728],
					[56.182994536219844, 3.4905112453633702],
					[56.182994543159069, 3.4905112395249214],
					[56.186514043120013, 3.4869029406851961],
					[56.186514053420986, 3.4869029295202458],
					[56.186514069408148, 3.486902904626096],
					[56.186514076954587, 3.4869028922433287],
					[56.187400168039098, 3.4852140100379772],
					[56.188302955766254, 3.4840834249340724],
					[56.188302960022334, 3.4840834194796177],
					[56.188302967640219, 3.4840834086994028],
					[56.188302971002038, 3.4840834033736376],
					[56.189189021203624, 3.4826195857839122],
					[56.190024865599035, 3.4825085196073577],
					[56.191341506205433, 3.4862250523872094],
					[56.192360903337828, 3.491630636688194],
					[56.192360906665598, 3.491630650737537],
					[56.192360915932319, 3.4916306768468193],
					[56.1923609219429, 3.491630690510608],
					[56.193785922066724, 3.4944472899726575],
					[56.193785933729224, 3.4944473092824189],
					[56.193785961310766, 3.4944473424478262],
					[56.193785977301424, 3.494447357907398],
					[56.19523047737011, 3.4955751580889749],
					[56.195230502303481, 3.4955751722651525],
					[56.195230553600766, 3.4955751923405702],
					[56.195230581824852, 3.495575199586936],
					[56.196477781994673, 3.4956890992205247],
					[56.196477799951971, 3.4956890982556938],
					[56.196477836760849, 3.495689096197558],
					[56.196477856363487, 3.495689091767701],
					[56.19843895561899, 3.4951195920850133],
					[56.198438970678559, 3.4951195866927645],
					[56.19843900065446, 3.4951195727000015],
					[56.198439014676531, 3.4951195642279367],
					[56.201177913962887, 3.4932029644641003],
					[56.204158412734358, 3.4911723656664315],
					[56.20415841534551, 3.4911723636762839],
					[56.204158417956656, 3.4911723616861541],
					[56.204158419673533, 3.4911723598246129],
					[56.205655719886437, 3.4900445603433576],
					[56.205655743028188, 3.4900445344085615],
					[56.205655780225541, 3.4900444806163322],
					[56.205655794137762, 3.4900444495500418],
					[56.206486293808652, 3.4867750491971363],
					[56.206486293736916, 3.4867750475926815],
					[56.206486295453715, 3.4867750457308286],
					[56.206486295381978, 3.486775044126377],
					[56.207252982973593, 3.4833917987740541],
					[56.208877944074018, 3.4817029392830667],
					[56.208877945718925, 3.4817029358163287],
					[56.208877950869102, 3.4817029302297091],
					[56.208877952585816, 3.4817029283675085],
					[56.210302931230373, 3.4800084559045934],
					[56.212447283732139, 3.4787668829436491],
					[56.214713919164886, 3.4779752053291184],
					[56.216205570115569, 3.4782001984565536],
					[56.216205599769687, 3.4782001974109549],
					[56.216205657002007, 3.4782001891582852],
					[56.216205686368696, 3.4782001816933166],
					[56.218825086072265, 3.476958480997324],
					[56.218825099198575, 3.4769584726427136],
					[56.218825123662668, 3.4769584561914408],
					[56.218825134928601, 3.4769584464898502],
					[56.220789035344964, 3.4751501469727417],
					[56.220789037061579, 3.4751501451096396],
					[56.220789041389047, 3.4751501412543777],
					[56.22078904310564, 3.4751501393912765],
					[56.222105743339796, 3.4737945386747295],
					[56.222105745950614, 3.4737945366824086],
					[56.22210575192269, 3.4737945293585724],
					[56.222105755355848, 3.4737945256320986],
					[56.223116854668817, 3.4725529252886647],
					[56.223116863179683, 3.4725529143668474],
					[56.223116876624431, 3.4725528930396976],
					[56.223116883274855, 3.4725528807710155],
					[56.223165853058987, 3.472442240707442],
					[56.222999812121486, 3.4728916301820441],
					[56.222999814607839, 3.4728917057512114],
					[56.22299987258792, 3.4728918346973252],
					[56.223000002663717, 3.4728918853841102],
					[56.223000131569293, 3.4728918296173381],
					[56.223000184998568, 3.4728917766698308],
					[56.223416885220324, 3.4718750752161665],
					[56.223416885416086, 3.4718749992588744],
					[56.22341683190615, 3.4718748696673529],
					[56.223416702580359, 3.4718748156442203],
					[56.223416571599095, 3.4718748652528455],
					[56.223416517276043, 3.471874918331491],
					[56.223170643752034, 3.4724304278994085],
					[56.223416888038727, 3.4717639696511968],
					[56.223416891112116, 3.4717639578988111],
					[56.223416897258858, 3.4717639343940316],
					[56.223416899366065, 3.4717639211656572],
					[56.223527999317504, 3.4707472220838831],
					[56.223527998669908, 3.4707472076377592],
					[56.223527998196985, 3.4707471770112504],
					[56.223527995760904, 3.470747162823439],
					[56.223114095971539, 3.4686055612524611],
					[56.223114089742303, 3.4686055427664817],
					[56.223114073562911, 3.4686055031011436],
					[56.223114062790494, 3.4686054836560749],
					[56.221916863021036, 3.4669165843535694],
					[56.221916857295597, 3.4669165771037651],
					[56.221916843984239, 3.4669165612575936],
					[56.221916837436552, 3.4669165557420745],
					[56.220483437320183, 3.4655637541578854],
					[56.220483435387671, 3.4655637512064499],
					[56.220483428911969, 3.4655637472963372],
					[56.220483426085224, 3.4655637444741649],
					[56.217861226354373, 3.4634276442658991],
					[56.217861221739128, 3.4634276417026686],
					[56.217861213330806, 3.4634276348420276],
					[56.21786120871554, 3.4634276322788002],
					[56.216130745875013, 3.4623026577982698],
					[56.215291893876469, 3.4609499328710647],
					[56.215169600900808, 3.4598222016042182],
					[56.215355798358516, 3.4582444274092881],
					[56.215758493328138, 3.4557639552058683],
					[56.216775182535187, 3.4533944794008824],
					[56.217969583174373, 3.4506861807020623],
					[56.21796958481837, 3.4506861772331838],
					[56.217969588034158, 3.4506861686906092],
					[56.217969589605964, 3.4506861636169099],
					[56.218805789982547, 3.4482056640492131],
					[56.218805794264149, 3.4482056391982714],
					[56.218805798356286, 3.4482055901446991],
					[56.218805797200375, 3.4482055644668486],
					[56.218505797028875, 3.4465138642423896],
					[56.218505778981964, 3.4465138232460273],
					[56.218505728120242, 3.4465137530872356],
					[56.218505694411235, 3.4465137240545176],
					[56.21724179364795, 3.4458387248235156],
					[56.217241766099065, 3.4458387126667653],
					[56.217241706214203, 3.445838701969866],
					[56.21724167559438, 3.4458387015654965],
					[56.215336075610232, 3.4460692017494896],
					[56.215336073821831, 3.4460692020088715],
					[56.215336070245009, 3.4460692025276369],
					[56.215336069350784, 3.446069202657331],
					[56.212477768880376, 3.4465248025502944],
					[56.212477767986179, 3.4465248026799586],
					[56.212477767091968, 3.4465248028096265],
					[56.212477766197757, 3.4465248029392948],
					[56.210508269937897, 3.4468637028141793],
					[56.207883286868146, 3.4472081002827086],
					[56.205800009693938, 3.4472136996793359],
					[56.202458320275156, 3.4468804012634249],
					[56.202458310294354, 3.4468803994807651],
					[56.202458291443712, 3.4468804005983964],
					[56.202458280640954, 3.4468804005495373],
					[56.20007778485666, 3.4471108997512978],
					[56.197686089725501, 3.4472276003845375],
					[56.197686068264453, 3.4472276034944209],
					[56.19768602384287, 3.4472276163892603],
					[56.197686002598537, 3.4472276243110933],
					[56.195849902675576, 3.4482470256677171],
					[56.195849893950118, 3.4482470317749043],
					[56.195849874710802, 3.4482470442483546],
					[56.195849866879556, 3.4482470502259956],
					[56.193705466986181, 3.4501637515086223],
					[56.193705456616563, 3.4501637610816318],
					[56.19370543870442, 3.4501637830468077],
					[56.19370543019545, 3.4501637939646508],
					[56.192085929589517, 3.4527581935401579],
					[56.192085926373451, 3.4527582020766614],
					[56.192085918080707, 3.4527582178047567],
					[56.192085913970431, 3.452758226470666],
					[56.191016514308266, 3.4554666265496099],
					[56.191016512591929, 3.455466628412057],
					[56.191016511091995, 3.4554666350856564],
					[56.191016510342017, 3.4554666384224686],
					[56.189952614021443, 3.4587332254503362],
					[56.188813725079612, 3.4611027027050629],
					[56.187322031283145, 3.4634720928038512],
					[56.18732202956668, 3.4634720946657431],
					[56.187322027850236, 3.4634720965276466],
					[56.187322027922256, 3.4634720981312372],
					[56.185060928117622, 3.4673054974071009],
					[56.185060923934287, 3.4673055044663923],
					[56.185060918250322, 3.467305518197807],
					[56.185060914066995, 3.467305525257097],
					[56.18351921497672, 3.4711360232490009],
					[56.182149819892537, 3.4744055122120012],
					[56.180774829619459, 3.4768859953265427],
					[56.178697035294832, 3.4799276865952753],
					[56.178697033578111, 3.4799276884560162],
					[56.178697032755643, 3.4799276901880165],
					[56.177738733633063, 3.4813915884604123],
					[56.176663742129158, 3.4829692752928958],
					[56.174641550962505, 3.4852248661980729],
					[56.174641545883787, 3.4852248733820668],
					[56.174641534760362, 3.4852248862755002],
					[56.174641530575897, 3.4852248933309133],
					[56.173510930572199, 3.4870276934018056],
					[56.173510928104463, 3.4870276985966493],
					[56.17351092391992, 3.4870277056516525],
					[56.173510921380505, 3.4870277092434154],
					[56.172208121327827, 3.4896166103999433],
					[56.172208117965695, 3.4896166157229147],
					[56.17220811317339, 3.4896166293180042],
					[56.172208110705562, 3.4896166345125028],
					[56.171552611329915, 3.4915360344041866],
					[56.171552608182651, 3.4915360445359389],
					[56.171552603533378, 3.4915360613365842],
					[56.171552602174685, 3.4915360712114927],
					[56.17124700212441, 3.4936721726929862],
					[56.17124700076554, 3.493672182567753],
					[56.171247000051203, 3.4936722068695643],
				],
			],
		},
		{
			fldNpdidField: "43541",
			wlbNpdidWellbore: "263",
			fldName: "EDDA",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "2/7-4",
			fldDiscoveryYear: "1972",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43541",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43541",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "018",
			cmpLongName: "ConocoPhillips Skandinavia AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=263",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "2410696",
			fldNpdidOwner: "20900",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[56.455572017241693, 3.1199750421977028],
					[56.455572089942763, 3.119975151236964],
					[56.455572146834108, 3.1199751928176562],
					[56.45558047056312, 3.1199774857663174],
					[56.45606650015791, 3.1368750056141588],
					[56.456066500693325, 3.1368750169119988],
					[56.456066504445268, 3.1368750390932769],
					[56.456066505797892, 3.1368750486390033],
					[56.458427603680043, 3.1461278411087976],
					[56.460772003235753, 3.1605972320344633],
					[56.460772004738963, 3.1605972448108175],
					[56.460772013107722, 3.1605972695379094],
					[56.46077201721593, 3.1605972802872015],
					[56.46288591700646, 3.1653500807941346],
					[56.462885976280532, 3.1653501350909519],
					[56.462886113537799, 3.1653501822679071],
					[56.462886194278298, 3.1653501763498837],
					[56.464072294254755, 3.1647195765135363],
					[56.464072327150681, 3.1647195486860062],
					[56.464072377881507, 3.1647194790873532],
					[56.464072395792527, 3.1647194389306295],
					[56.464569595835982, 3.1622444393650158],
					[56.464569597984003, 3.1622444276513804],
					[56.464569600340248, 3.1622444012704478],
					[56.464569599730908, 3.1622443883551479],
					[56.464305800886677, 3.1576583106187046],
					[56.46564739814746, 3.1489028297632187],
					[56.46564739814746, 3.1489028297632187],
					[56.465647397994786, 3.1489028265343841],
					[56.465647398812152, 3.1489028247820636],
					[56.467025197364848, 3.137466735191814],
					[56.470950154302926, 3.121775207147528],
					[56.472116542962503, 3.1218251932942143],
					[56.472752605546532, 3.1243472486644497],
					[56.472752607717183, 3.1243472564606587],
					[56.472752611905186, 3.1243472688237848],
					[56.472752614892848, 3.1243472748668117],
					[56.47463591461954, 3.1289861744698451],
					[56.474635961167436, 3.1289862258179921],
					[56.474636073872787, 3.1289862864383537],
					[56.474636141817591, 3.1289862954337435],
					[56.476638942254809, 3.1285557953707803],
					[56.476638987959625, 3.1285557720235895],
					[56.476639062210516, 3.1285557052135808],
					[56.476639089862942, 3.1285556618892052],
					[56.477755789655554, 3.125150062073069],
					[56.477755794327607, 3.1251500467081299],
					[56.477755799126719, 3.125150015056021],
					[56.477755800147442, 3.1251499986303415],
					[56.477725200422782, 3.1199916984510438],
					[56.477725200269326, 3.1199916952213376],
					[56.477725199962414, 3.1199916887619579],
					[56.477725199808958, 3.1199916855322534],
					[56.477053001755955, 3.1102500202450392],
					[56.477816875813573, 3.107600110718189],
					[56.480075102290854, 3.1055973765806115],
					[56.485519463003278, 3.1037751899920214],
					[56.485519491060863, 3.1037751742358699],
					[56.485519540689765, 3.1037751388516552],
					[56.485519563000828, 3.1037751158542632],
					[56.488730762924121, 3.0992557165880363],
					[56.488730772060919, 3.0992557005196133],
					[56.488730788393255, 3.0992556654306678],
					[56.488730793878538, 3.0992556483037697],
					[56.490469593972939, 3.0922778476421167],
					[56.490469596699306, 3.0922778293162616],
					[56.490469599394075, 3.0922777914673425],
					[56.490469598391776, 3.0922777704683386],
					[56.48917519806956, 3.0833582705320777],
					[56.489175196867102, 3.0833582642107364],
					[56.489175193414162, 3.083358248477166],
					[56.489175191240889, 3.0833582406801696],
					[56.486969590890681, 3.0762110410598105],
					[56.486969588871595, 3.0762110364939175],
					[56.486969585649689, 3.0762110256073099],
					[56.486969583553297, 3.0762110194263075],
					[56.484600183775179, 3.0707416195261068],
					[56.484600180784945, 3.0707416134856418],
					[56.484600173910906, 3.0707416015445554],
					[56.484600170998043, 3.0707415971191026],
					[56.479366870581792, 3.0620748975589231],
					[56.479366863706986, 3.0620748856210649],
					[56.479366847431628, 3.0620748653948771],
					[56.479366837953599, 3.0620748554918151],
					[56.474580738043571, 3.0574831557100919],
					[56.474580712909514, 3.0574831385038803],
					[56.474580657590195, 3.0574831113901726],
					[56.474580629192076, 3.0574831012024726],
					[56.47134722873551, 3.0570137018319445],
					[56.471347178618743, 3.0570137080627999],
					[56.471347088406361, 3.0570137417250671],
					[56.471347046601174, 3.0570137710510688],
					[56.469477646735797, 3.0592415708245686],
					[56.46947763507854, 3.0592415905425341],
					[56.469477615260779, 3.0592416278040018],
					[56.469477607255293, 3.0592416485761702],
					[56.468099806796019, 3.064405547481805],
					[56.468099804073603, 3.0644055657984643],
					[56.468099801154587, 3.0644055987835221],
					[56.468099801035436, 3.0644056150661827],
					[56.469108099153772, 3.0774027904839159],
					[56.467763709101668, 3.0851832334626788],
					[56.465055425923616, 3.0901582018476268],
					[56.461116529166162, 3.0969554951983072],
					[56.458383131116285, 3.1012442924179195],
					[56.458383125399543, 3.1012443046886311],
					[56.458383113149409, 3.1012443309830173],
					[56.458383108403247, 3.1012443447287246],
					[56.456516507569901, 3.1076471436945079],
					[56.456516506166977, 3.1076471520417188],
					[56.456516503361115, 3.1076471687361424],
					[56.456516501064542, 3.1076471772221836],
					[56.455810901292224, 3.113938878273208],
					[56.455810900552159, 3.1139388816396747],
					[56.455810900119225, 3.113938891461753],
					[56.455810900272802, 3.1139388946895448],
					[56.455652607305851, 3.1199969383736121],
					[56.455580862915205, 3.1199771794497422],
					[56.455580799573269, 3.1199749944399362],
					[56.455580770128492, 3.1199749242175998],
					[56.455580670107452, 3.1199748275423338],
					[56.455580600348142, 3.1199747993369824],
					[56.455572200147081, 3.1199747992389821],
					[56.455572133889532, 3.1199748257679469],
					[56.455572035310581, 3.1199749109586219],
					[56.455572017241693, 3.1199750421977028],
				],
			],
		},
		{
			fldNpdidField: "43679",
			wlbNpdidWellbore: "413",
			fldName: "STATFJORD NORD",
			fldCurrentActivitySatus: "Producing",
			wlbName: "33/9-8",
			fldDiscoveryYear: "1977",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43679",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43679",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "037",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=413",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "21084",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[61.425491500165421, 1.9139194033873947],
					[61.42558309975368, 1.920841703211654],
					[61.425583101227446, 1.9208417141667606],
					[61.425583104175033, 1.9208417360769625],
					[61.425583105746, 1.9208417488917267],
					[61.426685906154979, 1.9252500495050033],
					[61.426685908325126, 1.9252500565385471],
					[61.426685912665306, 1.9252500706056364],
					[61.426685914835424, 1.9252500776391865],
					[61.433272010818257, 1.9410333666308521],
					[61.436191507228536, 1.9515000510853182],
					[61.438458106224815, 1.9602139507077443],
					[61.438458113330967, 1.9602139660403366],
					[61.438458127736489, 1.9602140004266824],
					[61.438458136624483, 1.9602140153556542],
					[61.443438735824685, 1.9672640139950421],
					[61.449441533881135, 1.9761279123537843],
					[61.449441542574661, 1.9761279235698983],
					[61.449441562634576, 1.9761279453978209],
					[61.449441573904565, 1.9761279541483148],
					[61.452522027963226, 1.9786362184961961],
					[61.45421091037813, 1.9837361633608257],
					[61.45421091136545, 1.9837361650211651],
					[61.454210912545399, 1.9837361704047245],
					[61.454210913628984, 1.9837361739266983],
					[61.458027613524067, 1.9933222734858853],
					[61.45802761540223, 1.9933222749457153],
					[61.462535992230919, 2.0043842890421102],
					[61.462533131109112, 2.0043887936177542],
					[61.462533115594212, 2.0043888423251577],
					[61.462533111870854, 2.0043889448908381],
					[61.462533124361286, 2.0043889948239912],
					[61.462552624115112, 2.0044250944932194],
					[61.462552680745006, 2.0044251439010403],
					[61.462552811808514, 2.0044251859582722],
					[61.462552935903361, 2.0044251278586573],
					[61.462552986081597, 2.0044249997560839],
					[61.462552984912669, 2.0044249246661905],
					[61.462536441372805, 2.004384329138075],
					[61.46573906871992, 1.9993445079928571],
					[61.465739075271138, 1.9993444952113886],
					[61.465739085700591, 1.9993444702511873],
					[61.465739091456967, 1.9993444595329846],
					[61.468752991213805, 1.9895333595565876],
					[61.468752994827014, 1.9895333248242475],
					[61.46875299225271, 1.9895332575729654],
					[61.468752985077941, 1.9895332233927376],
					[61.466769585470836, 1.9847749236159791],
					[61.466769584387208, 1.9847749200925988],
					[61.466769583399937, 1.9847749184315517],
					[61.466769582412631, 1.9847749167705333],
					[61.465986281935841, 1.9830610179116568],
					[61.465986258625847, 1.983060985498577],
					[61.465986202686977, 1.9830609321991208],
					[61.465986170154444, 1.9830609131750878],
					[61.463402945938789, 1.9820915418092941],
					[61.461797390175043, 1.9787416322446496],
					[61.45533079472203, 1.9513999564980335],
					[61.454258497586558, 1.9462693706210927],
					[61.452853003144973, 1.9292500242559425],
					[61.454580788905027, 1.924227865025762],
					[61.454580792776468, 1.9242278528425789],
					[61.454580797652518, 1.9242278253623233],
					[61.454580799645086, 1.924227811723682],
					[61.454914099928523, 1.9187639116624271],
					[61.454914099150827, 1.9187638967712999],
					[61.45491409670457, 1.9187638671921547],
					[61.454914094145202, 1.9187638527072544],
					[61.454255794248468, 1.9160860510323732],
					[61.454255759957242, 1.9160859985812146],
					[61.45425566492591, 1.9160859223139863],
					[61.45425560517387, 1.9160859001560835],
					[61.449675122698743, 1.9159720029952632],
					[61.44675017839883, 1.9103221083359843],
					[61.446750173555415, 1.9103221019089682],
					[61.446750166638424, 1.9103220903059333],
					[61.446750162685809, 1.9103220836756305],
					[61.443044580641981, 1.9051499094933733],
					[61.441000191325777, 1.8986860407917254],
					[61.441000186189434, 1.8986860287862337],
					[61.44100017492849, 1.898686003118363],
					[61.441000168998826, 1.8986859931769438],
					[61.436952968793804, 1.8923248922859235],
					[61.436952960093777, 1.8923248810979543],
					[61.436952940912143, 1.8923248591294608],
					[61.436952930430536, 1.8923248483489215],
					[61.434266829839814, 1.8900220477305496],
					[61.434266824202339, 1.8900220433730115],
					[61.43426681104819, 1.890022033205458],
					[61.434266804617529, 1.8900220309117264],
					[61.431111205224219, 1.8880776295166402],
					[61.431111152192159, 1.8880776152989796],
					[61.431111045810646, 1.8880776151689593],
					[61.431110992558771, 1.8880776311164871],
					[61.429708192996465, 1.8889609312651667],
					[61.429708163880903, 1.8889609605102311],
					[61.429708117815515, 1.8889610275101094],
					[61.429708102744875, 1.8889610667171888],
					[61.428455405159376, 1.8962360522507431],
					[61.426808110302268, 1.9013332374380163],
					[61.426808107323573, 1.9013332494094284],
					[61.426808102952961, 1.9013332692262011],
					[61.426808100767637, 1.9013332791345721],
					[61.425491500682803, 1.9139193788063062],
					[61.425491500974566, 1.9139193843851716],
					[61.425491499873658, 1.9139193978085316],
					[61.425491500165421, 1.9139194033873947],
				],
			],
		},
		{
			fldNpdidField: "43672",
			wlbNpdidWellbore: "412",
			fldName: "STATFJORD ØST",
			fldCurrentActivitySatus: "Producing",
			wlbName: "33/9-7",
			fldDiscoveryYear: "1976",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43672",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43672",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "STATFJORD ØST UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=412",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "41233",
			fldDateUpdated: "2022-09-13 00:00:00",
			fldDateUpdatedMax: "2022-06-02 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[61.305949802412051, 1.985922229597217],
					[61.306688702105177, 1.9909750295588042],
					[61.306688709879666, 1.9909750578062304],
					[61.306688735037419, 1.9909751083971836],
					[61.306688750734779, 1.9909751329932575],
					[61.312194250909357, 1.9971473335817922],
					[61.312194328346585, 1.9971473668444202],
					[61.312194479572561, 1.9971473629465868],
					[61.312194555143329, 1.9971473253867233],
					[61.312922172618912, 1.9962559498712726],
					[61.313032949679005, 1.9964441991862711],
					[61.313022200115199, 1.9964441996609283],
					[61.313022111056746, 1.9964442552455042],
					[61.313022031035615, 1.9964444156684507],
					[61.313022040072987, 1.996444520506794],
					[61.319585918719085, 2.0052028911882709],
					[61.322694208877842, 2.0153222587551269],
					[61.322694216072911, 2.0153222758995444],
					[61.322694233040352, 2.0153223077366409],
					[61.322694242812773, 2.0153223224292933],
					[61.325249842916016, 2.0185779238799673],
					[61.325249869301416, 2.0185779461137963],
					[61.325249928022316, 2.0185779836247755],
					[61.325249961153176, 2.0185779968486228],
					[61.331319360729502, 2.0197807963163874],
					[61.331319367062584, 2.0197807967763315],
					[61.331319378933408, 2.0197807997499093],
					[61.331319385170744, 2.0197807983554052],
					[61.336044384760754, 2.0201251987138464],
					[61.336044395644791, 2.020125200032401],
					[61.336044417221451, 2.0201251989599944],
					[61.336044428009806, 2.0201251984237856],
					[61.34104994744839, 2.0194224092019457],
					[61.342772080643812, 2.0206890601631704],
					[61.342772116734885, 2.0206890783630302],
					[61.342772191524041, 2.0206890954142036],
					[61.342772232195664, 2.0206890975772374],
					[61.349013706834043, 2.0196808333203808],
					[61.349233099972004, 2.0264278059465748],
					[61.349233103984211, 2.0264278313266866],
					[61.349233118246133, 2.0264278806926521],
					[61.349233128400172, 2.0264279028229724],
					[61.350205428041001, 2.02805010224264],
					[61.350205433865526, 2.0280501103258621],
					[61.350205445418929, 2.0280501246367053],
					[61.350205450352391, 2.0280501329190597],
					[61.35726374963734, 2.0360112336608873],
					[61.357263850330632, 2.036011263743958],
					[61.357264022022221, 2.0360112197748554],
					[61.357264094707169, 2.0360111434686661],
					[61.358377957207452, 2.0311224122769471],
					[61.36506108440107, 2.0316640990804906],
					[61.365061137069745, 2.031664089186755],
					[61.365061229293758, 2.0316640422842958],
					[61.365061268944686, 2.0316640071320551],
					[61.365933468622138, 2.030289007877248],
					[61.365933483766412, 2.0302889519098279],
					[61.365933481689737, 2.0302888415766431],
					[61.365933465359866, 2.0302887870116688],
					[61.364336311111103, 2.0279638552478061],
					[61.364872381830324, 2.025005719952699],
					[61.371383411698297, 2.0206029657769711],
					[61.37138343995391, 2.0206029369107021],
					[61.371383483416011, 2.0206028708304924],
					[61.371383496840373, 2.0206028340156021],
					[61.371780796655074, 2.0182694348851098],
					[61.371780797699969, 2.0182694027199224],
					[61.371780789193032, 2.0182693426415099],
					[61.371780779545418, 2.0182693128714391],
					[61.366339079904606, 2.0069638129434613],
					[61.366339033270741, 2.0069637651861858],
					[61.366338918755019, 2.0069637119978605],
					[61.366338851764191, 2.0069637063669248],
					[61.362708362772125, 2.0078608775576603],
					[61.355708468201819, 2.0007165851364963],
					[61.352522383226493, 1.9935971181861385],
					[61.352522373355526, 1.9935971016314007],
					[61.352522351927696, 1.993597070777801],
					[61.352522339479833, 1.9935970566790915],
					[61.347566857303612, 1.9887887731066702],
					[61.343066869955479, 1.9814637959079664],
					[61.34306684881394, 1.9814637706367817],
					[61.343066800775247, 1.9814637307722349],
					[61.343066772890907, 1.9814637145242679],
					[61.341952873035822, 1.9810276136532297],
					[61.341952851965786, 1.9810276071329265],
					[61.341952809319885, 1.9810276017124757],
					[61.341952786660443, 1.9810275993028557],
					[61.339833286608588, 1.9811691991970657],
					[61.331802846267287, 1.9816636973896884],
					[61.325013996103912, 1.9779359246636186],
					[61.325013995212927, 1.9779359248639767],
					[61.325013992443722, 1.9779359236111149],
					[61.325013990565502, 1.9779359221578987],
					[61.320272290980533, 1.9755081225660878],
					[61.320272269814033, 1.9755081141994424],
					[61.320272226879091, 1.9755081032274329],
					[61.320272205110598, 1.9755081006220789],
					[61.317866705329763, 1.9754497992232207],
					[61.317866661672355, 1.9754498090410046],
					[61.317866583242989, 1.9754498435547143],
					[61.317866547676438, 1.9754498703044785],
					[61.313088772417473, 1.9810192418006036],
					[61.309672118807335, 1.9829109154357458],
					[61.307388836996658, 1.9836748107443742],
					[61.307388807184132, 1.9836748268103364],
					[61.307388754276928, 1.9836748668064841],
					[61.307388732169393, 1.9836748923895287],
					[61.305949832211418, 1.9859220917007272],
					[61.305949817712175, 1.9859221249453549],
					[61.305949803257995, 1.9859221937936065],
					[61.305949802412051, 1.985922229597217],
				],
			],
		},
		{
			fldNpdidField: "25307278",
			wlbNpdidWellbore: "2955",
			fldName: "HANZ",
			fldCurrentActivitySatus: "Approved for production",
			wlbName: "25/10-8",
			fldDiscoveryYear: "1997",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=25307278",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=25307278",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "028 B",
			cmpLongName: "Aker BP ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=2955",
			fldHcType: "OIL/GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "28544099",
			fldNpdidOwner: "917484",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Tananger",
			fldAreaGeometryWKT: [
				[
					[59.0000776001406, 2.1921139010436526],
					[59.000085899785333, 2.1934278011734429],
					[59.000094200357154, 2.1947389007191656],
					[59.000094200448373, 2.1947389024473853],
					[59.00009419964789, 2.1947389043523886],
					[59.00009419964789, 2.1947389043523886],
					[59.000191499669477, 2.1990194049400351],
					[59.000191500034106, 2.1990194118529982],
					[59.000191501563904, 2.1990194237740472],
					[59.000191501928519, 2.1990194306870117],
					[59.000488702263539, 2.200947229480211],
					[59.000488703610905, 2.2009472379449213],
					[59.000488708089051, 2.2009472545210818],
					[59.000488710236993, 2.2009472610809007],
					[59.000780409992757, 2.2018389622048695],
					[59.000780411066728, 2.2018389654848232],
					[59.000780415180266, 2.2018389751481098],
					[59.000780416254216, 2.2018389784280687],
					[59.001249814405284, 2.2029333733858936],
					[59.001722007603199, 2.2042333573867992],
					[59.00216090535686, 2.2060889466686122],
					[59.00216090633964, 2.20608894822049],
					[59.002160907413483, 2.2060889515007163],
					[59.002160906612836, 2.2060889534055854],
					[59.00259700730237, 2.2076694530459724],
					[59.002597008558283, 2.2076694597830926],
					[59.00259701374523, 2.2076694727279196],
					[59.002597015892846, 2.2076694792885787],
					[59.003210916349282, 2.2091056787332248],
					[59.003210923319514, 2.2091056913256426],
					[59.003210938151767, 2.2091057163340553],
					[59.00321094592271, 2.2091057270216106],
					[59.003713745610312, 2.2097140267789168],
					[59.003713776766517, 2.2097140537977489],
					[59.003713847977743, 2.2097140886101969],
					[59.003713887323045, 2.2097141000371532],
					[59.00449998664196, 2.2097669005287019],
					[59.004499988425401, 2.2097669001758562],
					[59.005319326184875, 2.2098168963241709],
					[59.005749855741016, 2.2101529411204233],
					[59.006252639221678, 2.2108307197070154],
					[59.006252644936026, 2.2108307255629129],
					[59.00625265556404, 2.2108307391797259],
					[59.006252663061879, 2.2108307446828124],
					[59.006610963162764, 2.2111695450094349],
					[59.006610969859842, 2.2111695524176622],
					[59.006610985747201, 2.2111695632476911],
					[59.006610995119452, 2.2111695701267045],
					[59.006933194746928, 2.2113695701888649],
					[59.006933234183151, 2.2113695833471558],
					[59.0069333158945, 2.2113695951291423],
					[59.006933358078641, 2.211369592024194],
					[59.00768065830853, 2.2111445920640747],
					[59.007680658217524, 2.211144590335413],
					[59.008177774606217, 2.2109946164088981],
					[59.008641600292876, 2.2112612725075387],
					[59.008641611448581, 2.2112612790341171],
					[59.008641632686292, 2.2112612888062571],
					[59.008641644551744, 2.2112612916989991],
					[59.009749945070581, 2.2115807927375917],
					[59.009749954169855, 2.2115807944309731],
					[59.009749974151831, 2.211580797464932],
					[59.009749984142807, 2.2115807989819039],
					[59.01110556991091, 2.2116890972981396],
					[59.012497147137857, 2.2120029938173302],
					[59.014102695984349, 2.2125168771415962],
					[59.014891541232871, 2.2132612248269674],
					[59.015469218263874, 2.2142833842958072],
					[59.015938712414361, 2.2155861674099171],
					[59.015938714652599, 2.2155861757026734],
					[59.015938721713262, 2.2155861900300278],
					[59.015938724752267, 2.2155861964173416],
					[59.016697024723072, 2.2169500974434388],
					[59.01669703829856, 2.2169501157245364],
					[59.016697067942879, 2.2169501482994858],
					[59.016697084994377, 2.2169501641461493],
					[59.017269284620482, 2.2173529643750345],
					[59.017269297559395, 2.2173529705524246],
					[59.017269326203447, 2.2173529841074049],
					[59.017269341016778, 2.2173529916613224],
					[59.018305534647588, 2.2176751898776472],
					[59.019160991588727, 2.2180001736345982],
					[59.01994984783299, 2.2187418306967817],
					[59.020994229520582, 2.2202390053591072],
					[59.022399814556437, 2.2227667780092708],
					[59.023344207318182, 2.2259250538604873],
					[59.024035902980025, 2.2285361376116102],
					[59.024299800934742, 2.2306028211670648],
					[59.02444980036114, 2.2321889101406711],
					[59.024460899943321, 2.2337111002686605],
					[59.024460900124765, 2.2337111037278419],
					[59.024460899595852, 2.2337111108221199],
					[59.024460899686559, 2.2337111125517217],
					[59.024577600168527, 2.235505613839821],
					[59.024577602677596, 2.2355056273248044],
					[59.024577608768901, 2.2355056575780905],
					[59.024577613152204, 2.2355056724408726],
					[59.024866513150585, 2.2362583726959193],
					[59.024866514133059, 2.2362583742496609],
					[59.024866516097944, 2.2362583773571272],
					[59.024866516188624, 2.2362583790867405],
					[59.025191515583543, 2.2370139785343],
					[59.025191525589342, 2.2370139975312693],
					[59.025191549787742, 2.2370140294570025],
					[59.025191563269956, 2.2370140460208834],
					[59.025766557913734, 2.2375529409072419],
					[59.02626655319068, 2.2380945362286386],
					[59.026266564526559, 2.2380945462263608],
					[59.026266590856132, 2.2380945672481314],
					[59.026266603884892, 2.2380945751644776],
					[59.027124904182159, 2.238561276251624],
					[59.027124912389446, 2.2385612781285746],
					[59.027124927201733, 2.2385612856935984],
					[59.027124933716117, 2.2385612896519382],
					[59.028055515894707, 2.2388862828511504],
					[59.029199883722491, 2.2395501638658004],
					[59.029985958144358, 2.2402251414000598],
					[59.030741542763685, 2.241108425702202],
					[59.031747035325189, 2.2424668136473329],
					[59.032969232099568, 2.2443779073968204],
					[59.032969232190148, 2.2443779091268863],
					[59.03296923308195, 2.2443779089511566],
					[59.034083133234624, 2.2460807094080475],
					[59.034083133234624, 2.2460807094080475],
					[59.034083134307537, 2.2460807126925766],
					[59.034083135199317, 2.2460807125168891],
					[59.035055430791303, 2.2475112068484422],
					[59.035847027004529, 2.2488779002621166],
					[59.035847053785183, 2.2488779299487072],
					[59.03584711661307, 2.2488779770086516],
					[59.03584715266031, 2.2488779943820245],
					[59.037166598936885, 2.2491945813115266],
					[59.037919258916531, 2.2499390422666812],
					[59.037919259898842, 2.2499390438213793],
					[59.037919261772942, 2.2499390452004615],
					[59.037919262755231, 2.2499390467551632],
					[59.038422062737467, 2.2504112466279964],
					[59.038422087902006, 2.2504112626507302],
					[59.038422143310292, 2.250411288451466],
					[59.038422171770485, 2.2504112985807168],
					[59.03970557241167, 2.250591897938111],
					[59.039705593194462, 2.2505918990902511],
					[59.039705635380322, 2.2505918960276743],
					[59.039705655891588, 2.2505918919885719],
					[59.040986155045118, 2.2502168913908673],
					[59.04233894895097, 2.249841893448389],
					[59.043655644169178, 2.2495362941737507],
					[59.045044443128859, 2.2492307955971209],
					[59.045044457306986, 2.2492307910554805],
					[59.045044483789148, 2.24923078059288],
					[59.04504449698495, 2.2492307744962567],
					[59.046075097421657, 2.2486557745702456],
					[59.046075112219945, 2.2486557646603091],
					[59.04607513896007, 2.2486557419061199],
					[59.046075151884224, 2.2486557306168793],
					[59.047102942315831, 2.2474557404137463],
					[59.048486214180215, 2.24625016639648],
					[59.050511186925853, 2.2451668795673791],
					[59.052358378789229, 2.2443668842835978],
					[59.052358384941108, 2.2443668813215352],
					[59.052358397244845, 2.2443668753974184],
					[59.05235840339671, 2.2443668724353429],
					[59.05427787744096, 2.2432168872621694],
					[59.056163911927982, 2.2427613035421321],
					[59.05762495013267, 2.2429362947745748],
					[59.058447077581995, 2.2432640673146609],
					[59.058949837407319, 2.2438751163317701],
					[59.059885930284004, 2.2453779060649492],
					[59.059885942873713, 2.2453779228238901],
					[59.059885972330825, 2.2453779519995174],
					[59.059885987414695, 2.2453779647679659],
					[59.060277687208917, 2.2456445651874457],
					[59.060277712192949, 2.2456445777528482],
					[59.060277762690426, 2.2456445957820801],
					[59.060277789005077, 2.245644599338585],
					[59.061205588817664, 2.245694600684617],
					[59.061205627164313, 2.2456945931213714],
					[59.061205698053968, 2.2456945703928399],
					[59.061205731307581, 2.2456945515887128],
					[59.062022331215786, 2.2449834512436739],
					[59.062022349037193, 2.244983430234357],
					[59.06202237914809, 2.244983385808152],
					[59.062022389654061, 2.2449833627430897],
					[59.062652989677531, 2.2430333613186355],
					[59.062652992700862, 2.2430333502255313],
					[59.062652997765163, 2.2430333264837454],
					[59.062652999004939, 2.2430333157425633],
					[59.062850198886778, 2.2403306146206279],
					[59.062850200216999, 2.240330605610851],
					[59.062850199310375, 2.2403305882954525],
					[59.062850198857056, 2.2403305796377544],
					[59.062661298586065, 2.2385332783071679],
					[59.062661297059485, 2.2385332663625932],
					[59.062661290257871, 2.2385332397147271],
					[59.062661285965291, 2.2385332265668714],
					[59.062152985731529, 2.2372305267421306],
					[59.062152984749055, 2.2372305251867557],
					[59.062152983675873, 2.237230521899892],
					[59.06215298260269, 2.2372305186130119],
					[59.061755783140164, 2.2363388189242017],
					[59.061755777154552, 2.2363388078607351],
					[59.061755765274057, 2.2363387874652592],
					[59.061755757595641, 2.2363387784855266],
					[59.061075158405494, 2.2354554780658988],
					[59.061075156440523, 2.2354554749553457],
					[59.06107515170951, 2.2354554706418122],
					[59.061075149744539, 2.235455467531259],
					[59.05981956318989, 2.2340304824750157],
					[59.058989087091845, 2.2325943231489633],
					[59.058625196666476, 2.2311499629768545],
					[59.058475199326608, 2.2299082772539833],
					[59.058250198689457, 2.2277693785455086],
					[59.058250194032539, 2.2277693584756899],
					[59.058250181060863, 2.2277693173101283],
					[59.058250171854368, 2.2277692963907021],
					[59.05763627610655, 2.2267443043912221],
					[59.05695298682388, 2.2253749268844847],
					[59.056477991960733, 2.2237943420461472],
					[59.05647798579237, 2.2237943275242511],
					[59.056477972654783, 2.2237943003880551],
					[59.05647796470295, 2.2237942862190199],
					[59.055689064860026, 2.2226359863813112],
					[59.055689042003976, 2.222635962918226],
					[59.055688987647109, 2.2226359229498973],
					[59.05568895712895, 2.2226359079993183],
					[59.0548306571689, 2.2223804077827416],
					[59.054830648069697, 2.2223804060850982],
					[59.054830630763057, 2.2223804025133416],
					[59.054830621663868, 2.2223804008156973],
					[59.054294422109066, 2.2223220007694722],
					[59.054294394283325, 2.2223220027772443],
					[59.05429434068801, 2.2223220116330276],
					[59.054294314026684, 2.2223220186574797],
					[59.053372114167196, 2.222758118947032],
					[59.053372094111587, 2.2227581316599023],
					[59.05337205694827, 2.2227581617493524],
					[59.053372039931432, 2.2227581808569417],
					[59.052735949076123, 2.2236026685633234],
					[59.05188595879892, 2.2244526590992555],
					[59.051885950335809, 2.2244526695176585],
					[59.051885934301325, 2.2244526901780932],
					[59.051885925838199, 2.224452700596498],
					[59.051285969734984, 2.2255026246644527],
					[59.050608311106096, 2.2256580925342653],
					[59.04985839258574, 2.2253970216260264],
					[59.048927917442839, 2.2247248380836191],
					[59.048927917351975, 2.2247248363528205],
					[59.048927914676725, 2.2247248368818684],
					[59.048927914676725, 2.2247248368818684],
					[59.047783443806281, 2.2239220578233327],
					[59.046236273890408, 2.2216749003547855],
					[59.045333493889558, 2.2197582438924708],
					[59.045147397638274, 2.2183777730431506],
					[59.045147392889113, 2.2183777512516882],
					[59.045147376165986, 2.2183777073500597],
					[59.045147366157352, 2.2183776883480744],
					[59.044177966050263, 2.216947088572216],
					[59.044177965067554, 2.21694708701822],
					[59.044177963193142, 2.2169470856407347],
					[59.044177963102179, 2.2169470839102092],
					[59.043205770034746, 2.2155859936725735],
					[59.042052975947101, 2.2134693044662854],
					[59.042052969067988, 2.2134692935896494],
					[59.042052953617365, 2.2134692739199329],
					[59.042052945846528, 2.2134692632198871],
					[59.040652946419918, 2.2119804631837634],
					[59.04065294463647, 2.2119804635370022],
					[59.040652944545457, 2.2119804618066841],
					[59.039433445014602, 2.2106942616735688],
					[59.039433433676685, 2.210694251681522],
					[59.039433410200168, 2.2106942336043054],
					[59.039433398061583, 2.2106942255191533],
					[59.038361198001539, 2.2100942250639686],
					[59.038361166772624, 2.2100942137681758],
					[59.038361104952095, 2.2100942032879516],
					[59.038361073377736, 2.2100942025500032],
					[59.037755572667109, 2.2101776019867851],
					[59.037755562858173, 2.2101776039298957],
					[59.037755543240337, 2.2101776078161137],
					[59.037755534414181, 2.2101776113127394],
					[59.037327734308839, 2.2103276117116577],
					[59.037327705337255, 2.2103276261915012],
					[59.037327655165136, 2.2103276658491069],
					[59.037327632981849, 2.2103276894733557],
					[59.036727633280179, 2.2112387895472723],
					[59.036727629986373, 2.2112387954440864],
					[59.036727624199457, 2.2112388053309782],
					[59.036727620814617, 2.2112388094976616],
					[59.036130435704919, 2.2124276819251572],
					[59.03556375866517, 2.2129942596486361],
					[59.035563752605078, 2.2129942643446947],
					[59.035563741558668, 2.2129942770203623],
					[59.03556373737306, 2.2129942830933507],
					[59.034713764435836, 2.2141887455655702],
					[59.033897147676903, 2.2146220012517843],
					[59.033469447312655, 2.2146330984191076],
					[59.032719547888689, 2.2142359526963431],
					[59.031997378255006, 2.2128693071633205],
					[59.031277990334608, 2.2114332331115194],
					[59.030836297396498, 2.2094388648117498],
					[59.030575199394484, 2.2074388778241572],
					[59.030383499957182, 2.2052305967116794],
					[59.030447398170907, 2.2039167375688407],
					[59.030902974189722, 2.2025918096076738],
					[59.031469540609628, 2.2020251407828586],
					[59.031469544977028, 2.202025138168954],
					[59.031469550945516, 2.2020251317417983],
					[59.031469552546646, 2.2020251279285095],
					[59.032175108320104, 2.2011807820818832],
					[59.033350038614955, 2.2009445964876093],
					[59.033350043965193, 2.2009445954264786],
					[59.033350053682767, 2.2009445917511701],
					[59.033350059032983, 2.2009445906900433],
					[59.034311159034083, 2.2006473914432818],
					[59.034311166076535, 2.2006473882983566],
					[59.034311180252622, 2.2006473837384921],
					[59.034311187295067, 2.2006473805935833],
					[59.03491678683352, 2.2003557801278735],
					[59.034916821407549, 2.2003557522927384],
					[59.034916874758501, 2.2003556875195329],
					[59.034916894335936, 2.2003556486745661],
					[59.035158494034079, 2.1993833481279177],
					[59.035158496871425, 2.1993833335802919],
					[59.035158498979264, 2.1993833051926637],
					[59.035158500033198, 2.1993832909988575],
					[59.035116900162016, 2.1984138904239461],
					[59.035116897649033, 2.1984138769378481],
					[59.035116893423577, 2.1984138480587454],
					[59.035116889036019, 2.1984138331965335],
					[59.034827988982634, 2.1975916333669261],
					[59.034827986925606, 2.1975916285309109],
					[59.034827981828677, 2.1975916173058159],
					[59.034827979771642, 2.1975916124697998],
					[59.034394580622823, 2.1967027157465928],
					[59.03385298271418, 2.1954721196735449],
					[59.033852980657073, 2.1954721148377954],
					[59.03385297663413, 2.1954721068961898],
					[59.033852974577037, 2.195472102060422],
					[59.03320297480208, 2.1943110027302097],
					[59.033202944169204, 2.1943109686077027],
					[59.033202871241031, 2.1943109184099798],
					[59.033202828945718, 2.1943109023347822],
					[59.032383329095481, 2.1941915021568468],
					[59.03238331999605, 2.1941915004673973],
					[59.032383303763112, 2.1941915001941248],
					[59.032383294754943, 2.1941915002345143],
					[59.031350019162474, 2.1942164984410137],
					[59.030205662924949, 2.1939665088924061],
					[59.029169480754653, 2.1935054166910373],
					[59.029169479862958, 2.1935054168680561],
					[59.029169476113708, 2.1935054141167729],
					[59.029169474330317, 2.1935054144708115],
					[59.027383376846544, 2.1927887147096357],
					[59.026311178958984, 2.1923276151440061],
					[59.026311176283905, 2.1923276156750919],
					[59.026311170751242, 2.1923276132782075],
					[59.026311167984915, 2.1923276120797635],
					[59.025238968341107, 2.1919387114138784],
					[59.025238961916749, 2.1919387091941531],
					[59.025238948267614, 2.1919387066611842],
					[59.025238941843249, 2.1919387044414562],
					[59.024061168804202, 2.1916887096059172],
					[59.022952910147488, 2.1910942315928277],
					[59.022094522661924, 2.1904220430525876],
					[59.022094521678959, 2.1904220415003262],
					[59.02209451891261, 2.1904220403021459],
					[59.022094517929645, 2.1904220387498827],
					[59.021269522658741, 2.1898192421601497],
					[59.020375144093641, 2.189080460742606],
					[59.019369558196281, 2.1877915767090554],
					[59.019369555338578, 2.1877915737820253],
					[59.019369548640199, 2.1877915663758736],
					[59.019369545782503, 2.1877915634488421],
					[59.01829184561722, 2.1866415625852955],
					[59.018291840884807, 2.1866415582835792],
					[59.018291828653602, 2.1866415484823882],
					[59.018291823829856, 2.1866415424515502],
					[59.017611224215209, 2.1861054434227425],
					[59.017611214841693, 2.1861054365486741],
					[59.017611197077713, 2.1861054243524678],
					[59.017611187795545, 2.1861054192074842],
					[59.016933388069909, 2.1857748205569596],
					[59.016933362372136, 2.1857748116843863],
					[59.01693330965, 2.1857748029386674],
					[59.016933283425949, 2.1857748011593578],
					[59.015827782721651, 2.1858692004280749],
					[59.015827761321376, 2.1858692046790047],
					[59.015827717903207, 2.1858692185449402],
					[59.015827696776959, 2.1858692279828094],
					[59.015224897220598, 2.1862304275300231],
					[59.015224886794485, 2.1862304348421717],
					[59.015224867816926, 2.1862304508412205],
					[59.015224859174154, 2.1862304577991711],
					[59.014727659040759, 2.1867248582335623],
					[59.014727649688957, 2.1867248688262202],
					[59.014727633660364, 2.1867248894802498],
					[59.014727626183252, 2.1867249014476697],
					[59.01413044342128, 2.1877776710886563],
					[59.013383183012714, 2.1884165373454469],
					[59.012533197692903, 2.1889192279030905],
					[59.012533190742033, 2.1889192327769016],
					[59.012533177731989, 2.1889192423475117],
					[59.012533171581495, 2.188919245315478],
					[59.011680532911804, 2.1896303947230962],
					[59.010897236730557, 2.1895804015071474],
					[59.010075107295464, 2.1893220243317],
					[59.009608442173835, 2.1888498585865879],
					[59.009608423335827, 2.1888498431111443],
					[59.00960837969194, 2.1888498185853154],
					[59.009608356578127, 2.1888498074522338],
					[59.009180656820604, 2.1887220088469106],
					[59.00918061783905, 2.1887220043573938],
					[59.009180540788996, 2.1887220126649223],
					[59.009180502720476, 2.1887220254619866],
					[59.008788918863722, 2.188938660878553],
					[59.008361223991379, 2.1886026424417895],
					[59.008361213726339, 2.1886026357457169],
					[59.008361194179237, 2.188602623905147],
					[59.008361183022522, 2.1886026173860551],
					[59.007788982906888, 2.1883415186506179],
					[59.007788979157525, 2.188341515901461],
					[59.007788971841471, 2.1883415138603057],
					[59.007788968092107, 2.1883415111111355],
					[59.007252868325118, 2.1881470114358903],
					[59.007252862883711, 2.1881470107693368],
					[59.007252851909591, 2.1881470077077076],
					[59.007252847268553, 2.1881470051356122],
					[59.006466772991352, 2.1879581112940403],
					[59.005858441111627, 2.18762484864479],
					[59.005211273020173, 2.1866054995381212],
					[59.004669577139161, 2.1855805077333481],
					[59.004669566325951, 2.1855804906682921],
					[59.004669543098856, 2.1855804603491391],
					[59.004669529793297, 2.1855804472720735],
					[59.003880730089321, 2.184908147332898],
					[59.003880702151456, 2.1849081301758742],
					[59.003880642365424, 2.1849081071174483],
					[59.003880609534249, 2.184908099664697],
					[59.002955609868636, 2.1848608994670706],
					[59.002955600860517, 2.1848608995093253],
					[59.002955582043924, 2.1848609014992033],
					[59.002955573035791, 2.1848609015414571],
					[59.002349972565845, 2.1849442021653656],
					[59.002349942431472, 2.184944211641727],
					[59.002349885385861, 2.1849442404331172],
					[59.002349859366291, 2.1849442595710928],
					[59.001641559262367, 2.1856526588117915],
					[59.001641544834506, 2.1856526756474204],
					[59.001641520711168, 2.1856527136183659],
					[59.001641511015706, 2.1856527347536643],
					[59.001010913778806, 2.1874638253156427],
					[59.00044701830177, 2.1887193172757637],
					[59.000447011898622, 2.1887193325170577],
					[59.000447004442478, 2.1887193619379719],
					[59.000447000714395, 2.1887193766484461],
					[59.000280401049352, 2.190174976946659],
					[59.000280401140621, 2.1901749786748739],
					[59.000280401140621, 2.1901749786748739],
					[59.000077600737768, 2.1921138782231777],
					[59.000077601102753, 2.1921138851360209],
					[59.000077599866856, 2.1921138958590034],
					[59.0000776001406, 2.1921139010436526],
				],
			],
		},
		{
			fldNpdidField: "43597",
			wlbNpdidWellbore: "884",
			fldName: "FRØY",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "25/5-1",
			fldDiscoveryYear: "1987",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43597",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43597",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "364",
			cmpLongName: "Aker BP ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=884",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "28544099",
			fldNpdidOwner: "3810636",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-06 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[59.693638704837824, 2.5524166564730457],
					[59.693638731039691, 2.5524167494206638],
					[59.693638850340136, 2.5524168671649816],
					[59.69363894182829, 2.5524168958363402],
					[59.694527842254885, 2.552227995332554],
					[59.695511115092991, 2.5520169008663434],
					[59.696258238065283, 2.5520612969407956],
					[59.697247095278485, 2.552672369166777],
					[59.69724710000088, 2.5526723736015104],
					[59.697247110163808, 2.5526723787681465],
					[59.697247114799175, 2.5526723814375694],
					[59.698563814608796, 2.5532918818756078],
					[59.698563828340703, 2.5532918863537168],
					[59.698563854825245, 2.5532918937167723],
					[59.698563869449409, 2.5532918980226564],
					[59.699733268576558, 2.5534751973528063],
					[59.699733273124842, 2.5534751982570945],
					[59.699733280349889, 2.5534751986447],
					[59.699733284005916, 2.5534751997212175],
					[59.700485988016666, 2.5535362912751141],
					[59.701241531197425, 2.5546473067035738],
					[59.701949827612374, 2.5558529011377318],
					[59.701949838210282, 2.555852915133745],
					[59.701949863062133, 2.5558529442025892],
					[59.701949876336791, 2.5558529576820672],
					[59.703266566281833, 2.5568945492863633],
					[59.704349857367326, 2.5579973399327365],
					[59.704349866811803, 2.5579973488054728],
					[59.704349887398315, 2.5579973644409786],
					[59.704349897648058, 2.5579973713758899],
					[59.704630498287372, 2.5581640714151259],
					[59.704630537698229, 2.5581640852016925],
					[59.704630620111395, 2.5581640942571222],
					[59.704630662221419, 2.5581640896981144],
					[59.705944288342323, 2.5577391467727502],
					[59.706463716262256, 2.5589583783782555],
					[59.706463717328482, 2.5589583817377397],
					[59.706463720266171, 2.5589583865187571],
					[59.706463721245413, 2.5589583881124258],
					[59.707266520585101, 2.5605806883839923],
					[59.707266522630476, 2.5605806933373567],
					[59.70726652761352, 2.5605807030720191],
					[59.70726653144343, 2.5605807076811993],
					[59.708119222611835, 2.5619250942026213],
					[59.708544216888406, 2.5628972804098438],
					[59.7085442287256, 2.5628973013024177],
					[59.708544260343444, 2.5628973397733259],
					[59.708544276555038, 2.5628973580397942],
					[59.709766561114812, 2.5638501462042811],
					[59.710522038788334, 2.564727919390771],
					[59.711088730375558, 2.5656334060254808],
					[59.711088736163617, 2.5656334138239352],
					[59.711088746760588, 2.5656334278267399],
					[59.711088752548655, 2.565633435625196],
					[59.711841552892679, 2.5664501365506647],
					[59.711841571693768, 2.5664501525385801],
					[59.711841614388923, 2.5664501781842928],
					[59.711841635693062, 2.5664501901240766],
					[59.712874936022871, 2.5667973903185177],
					[59.712874968665879, 2.5667973947248348],
					[59.712875034409755, 2.5667973945346749],
					[59.712875065639267, 2.5667973885159507],
					[59.714374960872661, 2.5662696267329324],
					[59.715124874131419, 2.5668807555432931],
					[59.715124911930808, 2.566880773220241],
					[59.715124992275044, 2.5668807951783035],
					[59.715125033840756, 2.5668807978650889],
					[59.716111133781638, 2.5667084965414437],
					[59.716111140919793, 2.5667084951655394],
					[59.716111155196124, 2.5667084924137278],
					[59.71611116135513, 2.5667084894434815],
					[59.717138941304654, 2.5663779975877055],
					[59.717841631668122, 2.5663085079299974],
					[59.718783166013374, 2.5671529486957021],
					[59.718783168863929, 2.5671529517126896],
					[59.718783175370461, 2.5671529558081549],
					[59.718783178221017, 2.5671529588251278],
					[59.720149878330524, 2.5682084581579172],
					[59.720149882160186, 2.5682084627696748],
					[59.720149892322631, 2.5682084679441646],
					[59.720149896957707, 2.5682084706173938],
					[59.721416589794494, 2.5689695671216297],
					[59.722827681031447, 2.5699751606023269],
					[59.724474850812236, 2.5712279372365665],
					[59.725372032359545, 2.57262790874629],
					[59.725372037168157, 2.5726279149542104],
					[59.725372047851323, 2.5726279307318385],
					[59.725372053552242, 2.5726279367678595],
					[59.726313753762618, 2.5736362362028888],
					[59.726313774434352, 2.5736362536244521],
					[59.726313818999749, 2.5736362807125039],
					[59.726313842980232, 2.573636292145884],
					[59.727958226296785, 2.5741279872713116],
					[59.729461010240591, 2.5748834781421821],
					[59.729461052240197, 2.574883489674284],
					[59.729461135198029, 2.5748834915336576],
					[59.72946117713532, 2.5748834834561327],
					[59.730350077383733, 2.5745112847085267],
					[59.730350081758345, 2.5745112820820255],
					[59.730350093184448, 2.5745112763133635],
					[59.730350097559068, 2.5745112736868605],
					[59.731658379000599, 2.5737779856038392],
					[59.732644385029154, 2.5734752136125891],
					[59.734474909026886, 2.5743223773607156],
					[59.736308186043154, 2.5753584646806553],
					[59.737888770640112, 2.5766862525775771],
					[59.737888774382768, 2.5766862554251251],
					[59.737888778125466, 2.5766862582726828],
					[59.737888780889065, 2.576686259524589],
					[59.739016541911099, 2.5775251318109427],
					[59.739444220629444, 2.5783917885587262],
					[59.739444258663028, 2.5783918294106618],
					[59.739444350642707, 2.5783918866383009],
					[59.739444405307623, 2.578391899306975],
					[59.74047496806417, 2.5783669018505297],
					[59.741413833214096, 2.578700187490214],
					[59.741413899850599, 2.5787001871478328],
					[59.741414019442011, 2.5787001462719221],
					[59.74141407141795, 2.5787001041425102],
					[59.742297369132665, 2.577255706249145],
					[59.743183461198939, 2.575889019320317],
					[59.743880684988234, 2.575055810108327],
					[59.745147126227238, 2.5751862928260238],
					[59.746466583084732, 2.5761362623033244],
					[59.746466609915515, 2.5761362767645783],
					[59.746466666625501, 2.576136294391461],
					[59.746466696591504, 2.5761362993250922],
					[59.748249996955536, 2.5761669001531806],
					[59.7482500175648, 2.5761668979662224],
					[59.748250061286654, 2.5761668895403096],
					[59.748250081722368, 2.5761668838172285],
					[59.749369481916609, 2.5756668825956353],
					[59.749369497630525, 2.5756668724275249],
					[59.749369529058349, 2.5756668520913233],
					[59.749369542814115, 2.5756668387309127],
					[59.750583433990705, 2.574416848970885],
					[59.752500076800835, 2.5729279924685975],
					[59.754466714615042, 2.5727362993927407],
					[59.756338910120377, 2.5726445992936515],
					[59.758075010230314, 2.5725585003063904],
					[59.758075028075815, 2.5725584968639632],
					[59.758075063679954, 2.5725584882105279],
					[59.758075082330876, 2.5725584828274104],
					[59.759041782413625, 2.5721251826517144],
					[59.759041798214021, 2.5721251742473283],
					[59.75904182687745, 2.5721251526491207],
					[59.759041840806454, 2.5721251428204113],
					[59.760486239541805, 2.5706834423426859],
					[59.762583438001847, 2.5686890445718946],
					[59.76258344487929, 2.5686890378874709],
					[59.762583459352683, 2.5686890208087365],
					[59.76258346525097, 2.5686890125277544],
					[59.764727965211556, 2.5655584130727891],
					[59.765983463787713, 2.563755714822507],
					[59.765983469598709, 2.5637557047708275],
					[59.765983481220687, 2.5637556846674947],
					[59.765983486139426, 2.5637556747882275],
					[59.766727985758379, 2.5618806731937256],
					[59.766727989697706, 2.5618806617174426],
					[59.76672799570489, 2.5618806373408152],
					[59.766727997859711, 2.561880626209466],
					[59.766997397936748, 2.5598389258965413],
					[59.766997398415988, 2.559838899016496],
					[59.766997391344219, 2.5598388468091198],
					[59.766997382813933, 2.5598388198853006],
					[59.765908483081191, 2.557352719756758],
					[59.765908482014815, 2.5573527163914656],
					[59.765908480056218, 2.5573527131987683],
					[59.765908479076941, 2.5573527116024177],
					[59.765294579326458, 2.5561249112385998],
					[59.765294543157431, 2.5561248718064373],
					[59.765294455716749, 2.556124815506946],
					[59.765294403639885, 2.5561248005811348],
					[59.763275009379896, 2.5560804000332382],
					[59.761775072069781, 2.555974805787101],
					[59.760319525416961, 2.554891544662433],
					[59.758952944209717, 2.5537165591109394],
					[59.758105764754269, 2.5526748857702337],
					[59.756783473297325, 2.550374900227645],
					[59.756783471251467, 2.5503748952677805],
					[59.756783466354605, 2.5503748872891645],
					[59.756783462611388, 2.5503748844431158],
					[59.755080763393892, 2.547977683563539],
					[59.755080755820202, 2.5479776761039448],
					[59.755080742370133, 2.547977659070912],
					[59.755080733904208, 2.5479776517840764],
					[59.753150141778413, 2.5462331588873175],
					[59.750419549373738, 2.5431942654368256],
					[59.750419543758724, 2.5431942611697274],
					[59.750419534313124, 2.5431942522898487],
					[59.75041952861087, 2.543194246254691],
					[59.749619529290705, 2.5425192468431619],
					[59.749619508876691, 2.5425192347339847],
					[59.749619467243654, 2.5425192124565035],
					[59.749619445219672, 2.5425192042290714],
					[59.744219450320486, 2.5412720065140713],
					[59.740138953965925, 2.540119207697213],
					[59.740138952094242, 2.5401192062754259],
					[59.740138946653651, 2.5401192055450914],
					[59.74013894388974, 2.5401192042961682],
					[59.738402843763922, 2.5397248042640141],
					[59.738402822001596, 2.5397248013430054],
					[59.738402777041557, 2.5397248029163557],
					[59.73840275464881, 2.5397248054704531],
					[59.736124971215226, 2.5402498018571933],
					[59.733502786001324, 2.5404276005817708],
					[59.733502786001324, 2.5404276005817708],
					[59.730877786447913, 2.5406165010352688],
					[59.730877772977358, 2.5406165018600171],
					[59.730877748887359, 2.5406165065252022],
					[59.730877737375693, 2.5406165105384306],
					[59.729144336892226, 2.5411887099559003],
					[59.729144330733909, 2.5411887129321529],
					[59.729144319222236, 2.5411887169449314],
					[59.729144313063891, 2.5411887199211698],
					[59.728133212763261, 2.541674820116214],
					[59.728133202231021, 2.5416748257228252],
					[59.728133180448765, 2.5416748406425707],
					[59.728133170003744, 2.5416748480160787],
					[59.726435992818537, 2.5431331272021196],
					[59.723627731087277, 2.5443331117688088],
					[59.721897152675673, 2.5448776046760817],
					[59.720352853039209, 2.5451414881541288],
					[59.7191723216295, 2.5442276418969345],
					[59.719172311291864, 2.5442276331970963],
					[59.71917228730922, 2.5442276217871065],
					[59.719172275274296, 2.5442276151989054],
					[59.718188974824677, 2.5438304155962554],
					[59.718188971081396, 2.5438304127539708],
					[59.718188963769158, 2.5438304106021379],
					[59.718188961005282, 2.5438304093535908],
					[59.71692226103147, 2.5434276091509429],
					[59.716922252827004, 2.5434276071718753],
					[59.716922237397547, 2.5434276048073929],
					[59.716922230085324, 2.5434276026557088],
					[59.715344430010113, 2.5431915020035616],
					[59.715344417344511, 2.5431915008876458],
					[59.715344392992755, 2.5431915002494443],
					[59.715344381306586, 2.5431915007271493],
					[59.713752781401858, 2.5433470001052254],
					[59.71375277783293, 2.5433470007956029],
					[59.713752771587338, 2.5433470020037543],
					[59.713752768910624, 2.5433470025215397],
					[59.712016707909029, 2.543616495630733],
					[59.710469445773128, 2.5432498050001549],
					[59.710469443096429, 2.5432498055178785],
					[59.71046943765586, 2.5432498047873464],
					[59.710469434891998, 2.5432498035391076],
					[59.708919434899137, 2.5429720029013274],
					[59.70891943311468, 2.5429720032464647],
					[59.708919426781875, 2.5429720026885851],
					[59.708919424018013, 2.5429720014404227],
					[59.707561130468449, 2.5428054026097699],
					[59.706244451319726, 2.5425609053242724],
					[59.705166799691746, 2.5421942228823431],
					[59.703941833264373, 2.5411609506238015],
					[59.703000154036609, 2.5402831703752509],
					[59.702339068356608, 2.5392692905051635],
					[59.702339063633765, 2.5392692860717254],
					[59.702339057757001, 2.5392692765143692],
					[59.702339053926394, 2.5392692719083243],
					[59.701680753789425, 2.5384748721604056],
					[59.701680752809956, 2.5384748705675695],
					[59.701161276567774, 2.537858200198198],
					[59.70068629008211, 2.5364638375656785],
					[59.699925191301823, 2.5340582403840468],
					[59.699925191214511, 2.534058238618659],
					[59.699925190322318, 2.534058238791403],
					[59.699452990115475, 2.5326193378794764],
					[59.69945298201722, 2.532619319842965],
					[59.699452961359619, 2.5326192846338493],
					[59.6994529488003, 2.5326192674612411],
					[59.698697353140005, 2.5317720703083118],
					[59.698222355947443, 2.5311804746875759],
					[59.698222255184064, 2.5311804407385528],
					[59.698222078963177, 2.5311804837813048],
					[59.698222004397884, 2.5311805606002671],
					[59.697627603984301, 2.5340582601646986],
					[59.697627603984301, 2.5340582601646986],
					[59.697627603354, 2.534058265633186],
					[59.697627603354, 2.534058265633186],
					[59.697169203052901, 2.5367332636861453],
					[59.696530405283717, 2.5398555553011022],
					[59.695933105604048, 2.5423305532492559],
					[59.69593310479901, 2.5423305551869397],
					[59.695933104973392, 2.5423305587173295],
					[59.695933104081149, 2.5423305588898315],
					[59.695427605315977, 2.5447638575340052],
					[59.694785905005148, 2.547613855584439],
					[59.694233106199839, 2.5499527516683256],
					[59.693913707279577, 2.5511610491196728],
					[59.693913705582169, 2.5511610512292791],
					[59.693913704864059, 2.5511610549317694],
					[59.693913704951129, 2.5511610566968783],
					[59.693638704837824, 2.5524166564730457],
				],
			],
		},
		{
			fldNpdidField: "28975067",
			wlbNpdidWellbore: "5063",
			fldName: "BYRDING",
			fldCurrentActivitySatus: "Producing",
			wlbName: "35/11-13",
			fldDiscoveryYear: "2005",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=28975067",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=28975067",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "090 B",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=5063",
			fldHcType: "OIL/GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "3426781",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[61.133591500104465, 3.5281972014173042],
					[61.133602600055944, 3.5323250005685609],
					[61.133602600655237, 3.5323250153539498],
					[61.133602606471904, 3.5323250478464172],
					[61.133602609752145, 3.5323250621670086],
					[61.134427609755953, 3.5348417616241443],
					[61.134427611767897, 3.5348417668591159],
					[61.134427615791758, 3.5348417773290453],
					[61.134427617803723, 3.534841782564011],
					[61.135452617776302, 3.5371194820123968],
					[61.135452700230609, 3.5371195403185056],
					[61.135452873683413, 3.5371195568038383],
					[61.13545296639446, 3.53711951282517],
					[61.136414066425125, 3.5356973124615596],
					[61.136414067243926, 3.535697310458366],
					[61.136414068062706, 3.5356973084551742],
					[61.136414068881486, 3.5356973064519828],
					[61.138169381007081, 3.5329282046005597],
					[61.138922022364717, 3.5344139906369954],
					[61.138922082029573, 3.5344140417234988],
					[61.138922221379076, 3.5344140827207906],
					[61.138922299351265, 3.5344140747898729],
					[61.140633399121249, 3.5334362740452496],
					[61.140633402620942, 3.5334362715768903],
					[61.140633411407649, 3.5334362663303236],
					[61.140633414907327, 3.5334362638619843],
					[61.142102905383759, 3.5324001700568619],
					[61.143916553993137, 3.5314364044104942],
					[61.144616501246915, 3.5351222214842823],
					[61.144694200160203, 3.5374361075243916],
					[61.144694201652662, 3.5374361221602197],
					[61.144694208287021, 3.5374361526613276],
					[61.144694211641635, 3.5374361688363032],
					[61.145480410995134, 3.5396000651196053],
					[61.146033110230924, 3.5412944614366948],
					[61.146033118202965, 3.5412944805368771],
					[61.146033136678433, 3.5412945145751156],
					[61.146033149043987, 3.5412945310526105],
					[61.147547043450317, 3.5430501245251538],
					[61.148416538109352, 3.5442501176692418],
					[61.148416549431403, 3.544250130605648],
					[61.148416573788026, 3.54425015432001],
					[61.148416586822648, 3.5442501650979561],
					[61.149494242749341, 3.5449834342280697],
					[61.149980414017151, 3.5460028755775168],
					[61.151127605676237, 3.5494389501881241],
					[61.151474792013218, 3.551252775310199],
					[61.150316517801237, 3.5538277176168385],
					[61.150316512886953, 3.5538277296386993],
					[61.150316505814033, 3.5538277550683266],
					[61.150316502687112, 3.5538277667813145],
					[61.150149803072466, 3.5548305660583055],
					[61.150149802255115, 3.5548305904068287],
					[61.150149805088823, 3.5548306383318717],
					[61.150149808739847, 3.5548306619084031],
					[61.151435906936833, 3.5588611547994127],
					[61.151755405338342, 3.5602028454180692],
					[61.151755409434898, 3.5602028577463054],
					[61.151755415617004, 3.5602028771632925],
					[61.15175542060723, 3.5602028893372615],
					[61.15243042121044, 3.56156678985649],
					[61.152430471705408, 3.5615668370095355],
					[61.15243059429163, 3.5615668866199188],
					[61.152430663701857, 3.5615668895399106],
					[61.153286021865426, 3.5612780374510185],
					[61.154258136162618, 3.5626612148918273],
					[61.154258145621128, 3.5626612262961284],
					[61.154258167219126, 3.5626612486421658],
					[61.154258178390499, 3.5626612578885992],
					[61.1551387325432, 3.5633390233341324],
					[61.155952613354508, 3.5654667705446688],
					[61.155952614397144, 3.565466774089713],
					[61.155952616407944, 3.5654667793301873],
					[61.15595261834433, 3.5654667827210895],
					[61.156983113103081, 3.5677611717765276],
					[61.157466509044362, 3.569288960126519],
					[61.157466510012497, 3.5692889618221635],
					[61.157466511055027, 3.5692889653675079],
					[61.157466512023163, 3.569288967063148],
					[61.158733112212538, 3.5728556669400318],
					[61.158733171342185, 3.5728557275329287],
					[61.158733314777564, 3.5728557810544896],
					[61.158733399083296, 3.5728557739831439],
					[61.159591752385701, 3.5723641009585112],
					[61.160813896349261, 3.5723862991276012],
					[61.160813919734309, 3.5723862988242305],
					[61.160813966951835, 3.5723862869642149],
					[61.16081398914568, 3.5723862794152699],
					[61.162041785122213, 3.5717751803172813],
					[61.16369159219839, 3.5710446665756996],
					[61.164360908040514, 3.5727028589098953],
					[61.164780403676183, 3.5747361403568942],
					[61.164780486040044, 3.5747362193177659],
					[61.164780669916169, 3.5747362491250341],
					[61.164780772396448, 3.5747362016676454],
					[61.165889061989802, 3.5728584172852238],
					[61.167547350142904, 3.570989033581923],
					[61.167547356101196, 3.5709890251024632],
					[61.167547368017729, 3.5709890081435649],
					[61.167547373976014, 3.5709889996641069],
					[61.168408414878357, 3.569486301784512],
					[61.168897135164322, 3.5694945987367044],
					[61.169263783916286, 3.5697557629404955],
					[61.169263849453408, 3.569755781451633],
					[61.169263975092171, 3.5697557728309377],
					[61.169264036981225, 3.569755745390915],
					[61.17006123729027, 3.5690084454099402],
					[61.170061252781352, 3.5690084278324146],
					[61.170061278922809, 3.5690083841958673],
					[61.170061289722078, 3.5690083618376893],
					[61.170555789834324, 3.5675000627140712],
					[61.170555794675508, 3.5675000264764969],
					[61.170555791920599, 3.5674999580101132],
					[61.170555785143733, 3.5674999237767158],
					[61.169708494064338, 3.5654582464735576],
					[61.169114098265624, 3.5614638707080761],
					[61.169114096105567, 3.5614638617650494],
					[61.169114091785431, 3.5614638438789643],
					[61.169114088657167, 3.5614638332399116],
					[61.168125214083076, 3.5586222088197448],
					[61.168950162733928, 3.5567112276370927],
					[61.169441706254432, 3.5563391451836228],
					[61.170783145419684, 3.5572501365394071],
					[61.171691518310602, 3.5590389834122309],
					[61.172177615948058, 3.5601972774275987],
					[61.172177643579737, 3.5601973155123243],
					[61.172177714332904, 3.5601973740987232],
					[61.172177755667065, 3.5601973949091534],
					[61.173338840923677, 3.5604584912084327],
					[61.1740082181636, 3.5607251827029693],
					[61.175047110281398, 3.5612501791983715],
					[61.175047131283421, 3.5612501867519408],
					[61.175047173957545, 3.5612501961525997],
					[61.175047196597866, 3.5612501996960337],
					[61.176086096617432, 3.561266899650835],
					[61.176086144801857, 3.5612668894636434],
					[61.176086229328355, 3.5612668431805812],
					[61.176086265894035, 3.5612668126369624],
					[61.176880765733223, 3.5600862122595083],
					[61.176880774073567, 3.5600861959090193],
					[61.176880788966869, 3.5600861635168588],
					[61.176880793732458, 3.5600861477840402],
					[61.177644594206079, 3.5569917474832176],
					[61.177644595695, 3.5569917397709077],
					[61.177644597779164, 3.5569917245007696],
					[61.177644599268099, 3.5569917167884424],
					[61.177841894946368, 3.5545889752877691],
					[61.179177958617181, 3.5528390206186509],
					[61.179177962117208, 3.5528390181492062],
					[61.1791779671806, 3.5528390098177645],
					[61.179177969712271, 3.5528390056520585],
					[61.180900170005422, 3.5500834068465466],
					[61.180900171643387, 3.5500834028350887],
					[61.180900176631866, 3.5500833926517563],
					[61.180900179163437, 3.5500833884856],
					[61.181702975413891, 3.5484500963082657],
					[61.182625169975609, 3.5469473052220195],
					[61.182625170794495, 3.546947303216113],
					[61.182625170794495, 3.546947303216113],
					[61.18428627069941, 3.5441890022373275],
					[61.184286276655875, 3.5441889937482354],
					[61.184286285663489, 3.5441889716812436],
					[61.184286290576708, 3.5441889596446901],
					[61.184844590659196, 3.542425059161685],
					[61.184844593710167, 3.5424250455833945],
					[61.184844598843604, 3.5424250167305713],
					[61.184844600032434, 3.5424250016110217],
					[61.184853000362125, 3.5401472008105888],
					[61.184853000212449, 3.5401471971081846],
					[61.184852999913069, 3.5401471897034127],
					[61.184852998869722, 3.5401471861560436],
					[61.184680801287122, 3.5377361070313493],
					[61.185055790445084, 3.5353361772983121],
					[61.186716864212812, 3.5329584156883964],
					[61.186716865031528, 3.5329584136818486],
					[61.186716866668924, 3.532958409668792],
					[61.186716868456209, 3.5329584093582964],
					[61.189483467642539, 3.5287001085377971],
					[61.18948346942981, 3.5287001082270244],
					[61.189483469354812, 3.5287001063755956],
					[61.191391869051351, 3.5256918069057943],
					[61.191391869869953, 3.5256918048987775],
					[61.194097369630875, 3.5213029048891475],
					[61.194097384882284, 3.5213028593436229],
					[61.194097392000735, 3.5213027685954548],
					[61.194097383792652, 3.5213027215411352],
					[61.193552989867797, 3.5200249368645755],
					[61.193419593888152, 3.519483251343793],
					[61.1934195371906, 3.5194831847670995],
					[61.193419392392386, 3.51948312048486],
					[61.193419304366785, 3.519483124630959],
					[61.192616604933853, 3.519924824470321],
					[61.191694307020882, 3.5204081225608501],
					[61.191694286024642, 3.5204081374053131],
					[61.191694247013494, 3.5204081740334856],
					[61.191694229817088, 3.520408193810026],
					[61.190916531819674, 3.5216526901788252],
					[61.189555442996578, 3.5236692758214665],
					[61.188383153828546, 3.5249248648060276],
					[61.188383150329088, 3.5249248672793225],
					[61.188383142586638, 3.5249248760841265],
					[61.188383139162219, 3.5249248804087761],
					[61.18612204233343, 3.5279720773854448],
					[61.184874845436518, 3.5294942730840035],
					[61.184874842011951, 3.5294942774076792],
					[61.184874836875103, 3.5294942838931682],
					[61.184874834344157, 3.5294942880615108],
					[61.182577633988558, 3.5328859886826085],
					[61.182577630563877, 3.5328859930055754],
					[61.18257762468307, 3.5328860033473464],
					[61.182577622226987, 3.5328860093661421],
					[61.181227854603073, 3.5355550480288893],
					[61.180689105027184, 3.5336082901669066],
					[61.181064096854207, 3.5314750356019609],
					[61.181064098266702, 3.5314750260367145],
					[61.181064100273019, 3.5314750089124356],
					[61.181064099973199, 3.5314750015086407],
					[61.181075200201974, 3.527950010382511],
					[61.181327999330641, 3.5255639209355198],
					[61.181327999255608, 3.5255639190845782],
					[61.181327999105534, 3.5255639153826936],
					[61.181327999105534, 3.5255639153826936],
					[61.181586297804145, 3.5220417311961549],
					[61.182264093931863, 3.5192833472285687],
					[61.182264094750344, 3.5192833452219894],
					[61.182264094675219, 3.5192833433710131],
					[61.182264096387335, 3.5192833412088231],
					[61.182508496251643, 3.5181222403493573],
					[61.182508498105975, 3.5181222195215343],
					[61.182508497196217, 3.5181221749421212],
					[61.182508493613703, 3.5181221531971545],
					[61.18187801096586, 3.5155000222057695],
					[61.182808368989846, 3.5135475211720708],
					[61.183555425677092, 3.5141807149679627],
					[61.184130408603743, 3.5160806573108063],
					[61.184130453234658, 3.5160807147802551],
					[61.184130569438423, 3.5160807840127388],
					[61.184130640117743, 3.5160807959314551],
					[61.185438940218447, 3.5158112964002184],
					[61.185438959727641, 3.5158112892719648],
					[61.185438998670875, 3.5158112731642794],
					[61.18543901713609, 3.5158112624894291],
					[61.18629181704884, 3.5151945627961441],
					[61.186291853669871, 3.5151945116701016],
					[61.186291889521947, 3.5151943972916206],
					[61.186291888828144, 3.515194335890409],
					[61.185030788802472, 3.5115221344931404],
					[61.185030779932411, 3.5115221155334373],
					[61.185030757724306, 3.5115220783932437],
					[61.185030745355064, 3.5115220619080465],
					[61.183939045066069, 3.5103720622965824],
					[61.183938969984276, 3.5103720306526682],
					[61.183938823423212, 3.5103720338467674],
					[61.183938749187881, 3.5103720673013532],
					[61.1819859491847, 3.5126054675169258],
					[61.18198593980626, 3.5126054803363118],
					[61.181985922987053, 3.512605509365395],
					[61.181985915471046, 3.5126055237241331],
					[61.181310915179914, 3.5142305238560141],
					[61.181310911981434, 3.5142305337335129],
					[61.181310906402871, 3.5142305514819001],
					[61.181310904098012, 3.5142305612036711],
					[61.180938703709188, 3.5161138611133644],
					[61.180938703116261, 3.5161138686726243],
					[61.180938700886394, 3.5161138802450433],
					[61.180938700293467, 3.516113887804301],
					[61.180810902924378, 3.5181666483470728],
					[61.179360923613345, 3.5211082052781935],
					[61.178552637208973, 3.5224859816691696],
					[61.177791790168804, 3.5233467967886827],
					[61.177277999309986, 3.5215999698256928],
					[61.177222400233624, 3.5188221967786779],
					[61.177222400158506, 3.5188221949280014],
					[61.17722240008338, 3.5188221930773196],
					[61.177166901421622, 3.5173111216793789],
					[61.177908494091696, 3.5143028477105087],
					[61.177908494091696, 3.5143028477105087],
					[61.177908494910128, 3.5143028457040821],
					[61.177908494910128, 3.5143028457040821],
					[61.17889739495623, 3.5100389453360852],
					[61.178897394880977, 3.510038943485335],
					[61.178897395624105, 3.5100389396279841],
					[61.178897395548866, 3.5100389377772157],
					[61.179330796324464, 3.5077778383760707],
					[61.179330797735268, 3.5077778288103816],
					[61.179330799663298, 3.5077778098349319],
					[61.179330800180495, 3.5077778004251567],
					[61.179339099548883, 3.5052611537697933],
					[61.180502973162561, 3.5032640000839455],
					[61.180502985953247, 3.5032639605706279],
					[61.180502995299292, 3.5032638806512177],
					[61.180502989992029, 3.5032638387063928],
					[61.179925189730106, 3.5014888384716594],
					[61.179925189654739, 3.5014888366208741],
					[61.179925187716826, 3.5014888332314951],
					[61.179925187641459, 3.5014888313807089],
					[61.179300187988758, 3.4997749302325607],
					[61.179300100749352, 3.4997748653276211],
					[61.179299917616078, 3.4997748544567804],
					[61.179299822540443, 3.4997749064840242],
					[61.17819982274694, 3.5018832068043158],
					[61.178199822004053, 3.5018832106617777],
					[61.178199819473996, 3.5018832148313592],
					[61.178199817686767, 3.50188321514351],
					[61.177410927156053, 3.5035942982612958],
					[61.175944239025441, 3.5055831804299622],
					[61.175944221615303, 3.5055832170151917],
					[61.175944203999443, 3.5055832927742361],
					[61.17594420290007, 3.5055833321040097],
					[61.176252593388938, 3.5074610748072685],
					[61.175555428860058, 3.5091026915605421],
					[61.17426942189536, 3.5105357754696471],
					[61.173791883199897, 3.5098305126247755],
					[61.173227992389492, 3.5079416438148785],
					[61.173227907608805, 3.5079415728798922],
					[61.173227724917531, 3.5079415507034573],
					[61.173227627006916, 3.5079415994620136],
					[61.17294423856746, 3.5084304792817265],
					[61.171930548812099, 3.5094914642361026],
					[61.171025074671284, 3.5091248151902303],
					[61.171025012410297, 3.5091248111396007],
					[61.171024896617553, 3.5091248406478703],
					[61.171024844054635, 3.5091248759012394],
					[61.170202671522809, 3.5101498402736877],
					[61.169716796244508, 3.5103914289931382],
					[61.168722382271177, 3.5082305151390174],
					[61.168722363194796, 3.5082304886532243],
					[61.168722318118974, 3.5082304424787605],
					[61.16872229211954, 3.5082304227900689],
					[61.167630692266457, 3.5076665215916729],
					[61.167630680348502, 3.5076665162172724],
					[61.167630656663071, 3.5076665091686126],
					[61.16763064482037, 3.5076665056442971],
					[61.166966745013291, 3.5075137056678529],
					[61.166966681341435, 3.5075137111824266],
					[61.166966570845091, 3.5075137602633277],
					[61.166966524020545, 3.5075138038296587],
					[61.166102655129436, 3.5090998481135327],
					[61.164713811347447, 3.5097859216237532],
					[61.164713798169032, 3.5097859295099645],
					[61.164713771887435, 3.5097859471322868],
					[61.164713759677859, 3.5097859567126566],
					[61.164035960163048, 3.5104498563685311],
					[61.164035955920681, 3.5104498626968903],
					[61.164035947285512, 3.5104498716538073],
					[61.164035942967928, 3.5104498761322858],
					[61.163269242932969, 3.5114220761782855],
					[61.163269237796968, 3.5114220826620426],
					[61.163269229312164, 3.5114220953181645],
					[61.163269225144951, 3.5114221034960758],
					[61.162227626277016, 3.5132916021380209],
					[61.160883126003299, 3.515661001641218],
					[61.160883116181772, 3.5156610257046719],
					[61.160883102869207, 3.5156610745923027],
					[61.160883100271796, 3.5156610992608948],
					[61.16087760003289, 3.5167916937896142],
					[61.160683099987352, 3.5199360861000177],
					[61.160299801341168, 3.5248360830999652],
					[61.160299801272899, 3.5248361036020861],
					[61.160299804560829, 3.5248361402856871],
					[61.160299808885689, 3.524836158161563],
					[61.161199808669366, 3.5277444586508677],
					[61.161199812693901, 3.5277444691285682],
					[61.16119982260517, 3.5277444916232841],
					[61.161199829385595, 3.5277445034850774],
					[61.162346967097648, 3.529650001777962],
					[61.161613728722877, 3.530897096002207],
					[61.160758130744796, 3.5322665934956001],
					[61.15941093438051, 3.534383187831398],
					[61.157088760403006, 3.5376137523169673],
					[61.156113829327786, 3.538227609728521],
					[61.155383341237616, 3.5383386921766733],
					[61.154291836072417, 3.5376915482825799],
					[61.153627959901073, 3.5367998811918979],
					[61.153627944779167, 3.5367998633278037],
					[61.153627912153773, 3.536799835461848],
					[61.15362789368185, 3.5367998237655272],
					[61.152172294371304, 3.5360220241473383],
					[61.152172288859667, 3.5360220213784257],
					[61.152172278730006, 3.5360220156857034],
					[61.152172274186881, 3.5360220146111194],
					[61.151505736985968, 3.5357581384295642],
					[61.14999459483888, 3.5332166357359682],
					[61.14968629962712, 3.5300221853687535],
					[61.149647402045026, 3.5290611440905764],
					[61.151055770199989, 3.5263168074534446],
					[61.152711191870253, 3.5241724063011461],
					[61.154083181147548, 3.5242751922232372],
					[61.154585922371609, 3.525119493215036],
					[61.155280418322391, 3.5266500828866643],
					[61.155280445887911, 3.5266501190724373],
					[61.155280516579431, 3.5266501757039523],
					[61.155280558811768, 3.5266501963049453],
					[61.156522159017328, 3.5269112963847364],
					[61.15652221457276, 3.5269112904597426],
					[61.156522315622333, 3.526911252419568],
					[61.156522361041404, 3.5269112184548908],
					[61.157805760912005, 3.5251723181712533],
					[61.157805779146457, 3.5251722796132698],
					[61.157805794611598, 3.5251721949714439],
					[61.157805793704604, 3.5251721504265916],
					[61.157386296282844, 3.523530560022317],
					[61.15706960113009, 3.521458291957317],
					[61.157219598941531, 3.519500015160538],
					[61.157219596771135, 3.5194999838742484],
					[61.15721958073815, 3.5194999214728662],
					[61.157219567769168, 3.5194998902023187],
					[61.155875168150594, 3.5174276902934123],
					[61.155875099768629, 3.5174276463167691],
					[61.155874953942032, 3.5174276232654389],
					[61.155874876422267, 3.5174276423413269],
					[61.154733176382798, 3.5183276438573667],
					[61.154733161641879, 3.5183276575963234],
					[61.154733135809707, 3.5183276863017574],
					[61.154733124793538, 3.5183277031176243],
					[61.154119230770085, 3.5194498913981302],
					[61.153205464458935, 3.520691547812151],
					[61.151924902414997, 3.5214248262309602],
					[61.150827756978018, 3.5220358956419209],
					[61.149719419177941, 3.5219276012057925],
					[61.149719382389002, 3.5219276038758709],
					[61.14971931127392, 3.5219276255471166],
					[61.149719277766394, 3.5219276425438761],
					[61.148483299024548, 3.5228803476502208],
					[61.147925121103739, 3.5224581406342685],
					[61.147925088032387, 3.5224581240371879],
					[61.14792501973411, 3.522458104251756],
					[61.147924983613578, 3.5224581012187319],
					[61.147197300566106, 3.5225164909337749],
					[61.146575175898228, 3.5215888023796311],
					[61.14579188259686, 3.519822119915399],
					[61.145791881553038, 3.5198221163730739],
					[61.145791878646868, 3.5198221112926023],
					[61.145791877678171, 3.5198221095991102],
					[61.144939078116458, 3.5181471088171938],
					[61.144939014871234, 3.5181470583742236],
					[61.144938871124673, 3.5181470200683833],
					[61.144938789729686, 3.5181470323609156],
					[61.143788822753038, 3.5189026107251791],
					[61.141963862683923, 3.5192498034487816],
					[61.141963839524735, 3.5192498093364679],
					[61.141963798049936, 3.5192498295782224],
					[61.14196377779691, 3.5192498405457129],
					[61.140197078349942, 3.5206026409767963],
					[61.140197076637754, 3.520602643135915],
					[61.140197073138282, 3.5206026456056363],
					[61.140197072319758, 3.5206026476094494],
					[61.13842767168623, 3.5220831478035866],
					[61.138427637223892, 3.5220832077729405],
					[61.138427616337239, 3.5220833361194011],
					[61.138427629019283, 3.5220834046517568],
					[61.138972018109726, 3.5229722853841081],
					[61.139633099856894, 3.5248694348228953],
					[61.139624800211095, 3.5272582790612867],
					[61.139252610787494, 3.5290138262554591],
					[61.138580611287495, 3.5301328933717007],
					[61.137975185210159, 3.528991622748197],
					[61.137397390264539, 3.5271888388830743],
					[61.137397344820393, 3.5271887834827864],
					[61.137397228697566, 3.5271887161510098],
					[61.137397158018878, 3.527188704219538],
					[61.135819358015013, 3.5275248039148819],
					[61.135819345579051, 3.5275248079342227],
					[61.135819323387977, 3.5275248155077055],
					[61.135819311027007, 3.5275248213753292],
					[61.134813840105132, 3.5280248052482563],
					[61.133591672010674, 3.5281970022263214],
					[61.133591610355388, 3.5281970352572034],
					[61.133591524439765, 3.5281971357767805],
					[61.133591500104465, 3.5281972014173042],
				],
			],
		},
		{
			fldNpdidField: "18161341",
			wlbNpdidWellbore: "450",
			fldName: "GAUPE",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "6/3-1",
			fldDiscoveryYear: "1985",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=18161341",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=18161341",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "292",
			cmpLongName: "A/S Norske Shell",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=450",
			fldHcType: "OIL/GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "562",
			fldNpdidOwner: "2407572",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[57.945541502432548, 1.9554110723896845],
					[57.945541525819806, 1.9554111525214808],
					[57.945541627682843, 1.9554112663442353],
					[57.945541706158643, 1.9554113000351672],
					[57.946800005777199, 1.9553752001690921],
					[57.946800034301738, 1.9553751945503337],
					[57.946800089194561, 1.9553751769582437],
					[57.94680011457811, 1.9553751634840575],
					[57.947627228266334, 1.9547932986839807],
					[57.94752201005327, 1.9551138378291082],
					[57.947522006343242, 1.9551138521188474],
					[57.947522000986076, 1.9551138853765535],
					[57.947522000043591, 1.9551139008159615],
					[57.947535900352847, 1.9576056007968337],
					[57.947535900446184, 1.9576056024733064],
					[57.947552599688542, 1.9595472023641274],
					[57.947552601699648, 1.959547222306478],
					[57.94755261214825, 1.9595472643157532],
					[57.947552618616356, 1.9595472833806657],
					[57.948447018752063, 1.9614667842533291],
					[57.948447046934859, 1.9614668210787667],
					[57.948447117614712, 1.9614668766582608],
					[57.948447160111819, 1.9614668954122974],
					[57.949058229785976, 1.9615918894639672],
					[57.949536003181031, 1.9618584756060795],
					[57.949536071248957, 1.9618584842434139],
					[57.949536197828316, 1.9618584593303598],
					[57.949536257137851, 1.9618584239279355],
					[57.950538997701017, 1.9605918976069563],
					[57.951488904358207, 1.9605723994030777],
					[57.951488913365509, 1.9605723993248543],
					[57.951488928612633, 1.9605723980182141],
					[57.951488938418038, 1.9605723960878312],
					[57.952300037847827, 1.9604168966127871],
					[57.952300058163459, 1.9604168892228822],
					[57.952300096027173, 1.9604168732928895],
					[57.952300115358085, 1.9604168644017952],
					[57.953716815136723, 1.9594251641166098],
					[57.953716819407084, 1.9594251598854042],
					[57.953716828932514, 1.9594251529242142],
					[57.953716833202883, 1.959425148693011],
					[57.954186233426299, 1.9590057488339851],
					[57.954186236007168, 1.9590057466305406],
					[57.954186241967001, 1.959005740371337],
					[57.954186245439253, 1.9590057379923633],
					[57.954722344691788, 1.9584445380456523],
					[57.954722355626636, 1.9584445240255861],
					[57.954722374728831, 1.9584444948353641],
					[57.954722383787562, 1.9584444794896123],
					[57.955258484177044, 1.9572000786325243],
					[57.955258484975069, 1.9572000767800961],
					[57.955258487369214, 1.9572000712227924],
					[57.95525848727587, 1.9572000695459435],
					[57.955722386736987, 1.9559556708706967],
					[57.955722389929093, 1.9559556634607582],
					[57.955722393545777, 1.9559556474908981],
					[57.955722395846507, 1.9559556402565739],
					[57.955983493901314, 1.9547167507881951],
					[57.956447389653292, 1.9532028625582007],
					[57.956844588187785, 1.9521000683925003],
					[57.956844589596898, 1.952100061333446],
					[57.95684459269534, 1.9521000522460359],
					[57.956844595089258, 1.9521000466881591],
					[57.957236295106284, 1.9504528463121453],
					[57.957236295717358, 1.9504528411056425],
					[57.957236297830875, 1.9504528305168738],
					[57.957236297550565, 1.9504528254861595],
					[57.957430794766609, 1.9489417472090815],
					[57.958094585786192, 1.9471583733093554],
					[57.95862517137293, 1.9459251096543653],
					[57.959233449785849, 1.9452390332194014],
					[57.959233458138513, 1.9452390213982991],
					[57.959233473154598, 1.9452389997849153],
					[57.959233479630981, 1.9452389866386666],
					[57.960297374859636, 1.9430500982362937],
					[57.961166859632726, 1.9416862212177886],
					[57.961772338822293, 1.9410057450535771],
					[57.962444525707852, 1.940461255085085],
					[57.962444546868987, 1.9404612305581661],
					[57.962444581779607, 1.9404611778814878],
					[57.962444593559248, 1.9404611467296284],
					[57.962772388549155, 1.9391028676157254],
					[57.963305779676745, 1.938016787323072],
					[57.963305779583131, 1.9380167856459323],
					[57.963305782069995, 1.9380167817632581],
					[57.963305782867742, 1.9380167799099688],
					[57.963841881344756, 1.9367944836230728],
					[57.964305779785136, 1.9358472875145556],
					[57.964305781474188, 1.935847285484976],
					[57.964305783773689, 1.9358472782476923],
					[57.96430578546277, 1.9358472762181111],
					[57.964639082023631, 1.935033381476218],
					[57.964905770820927, 1.9344945054847384],
					[57.965508413303695, 1.9336862847548002],
					[57.966250015731802, 1.9335584998830306],
					[57.967269375359898, 1.9335668992168435],
					[57.968422151129779, 1.9338501940697155],
					[57.96896379163941, 1.9339890782844771],
					[57.970191547854959, 1.9353529298223346],
					[57.971285944164954, 1.9367084258573133],
					[57.97128595570387, 1.9367084388403328],
					[57.971285981174887, 1.9367084592451798],
					[57.971285994402898, 1.9367084701982371],
					[57.972374894124229, 1.9373890702129897],
					[57.972374908056267, 1.9373890776352822],
					[57.972374938407178, 1.9373890885961162],
					[57.972374953136963, 1.9373890941646321],
					[57.974072084987334, 1.9378001780129366],
					[57.975435942399187, 1.9395529232849236],
					[57.975435966180726, 1.939552945724039],
					[57.975436017638984, 1.9395529796550719],
					[57.975436048083374, 1.9395529922962111],
					[57.976933248023819, 1.9399584922216966],
					[57.976933259985849, 1.9399584966425705],
					[57.976933286209622, 1.9399584982446756],
					[57.976933298969421, 1.9399585008116051],
					[57.978286099308235, 1.9399641005396013],
					[57.978286109206756, 1.9399641002794368],
					[57.978286128018873, 1.9399640982574333],
					[57.978286136932439, 1.939964096495604],
					[57.98038063671553, 1.9395695971817086],
					[57.980380642861427, 1.9395695942703099],
					[57.980380656231766, 1.9395695916272],
					[57.980380662471269, 1.9395695903937498],
					[57.98119446183567, 1.9393057895024763],
					[57.981194483838756, 1.93930578006304],
					[57.98119452559412, 1.9393057531466809],
					[57.981194546331402, 1.9393057371715092],
					[57.982066837852216, 1.9383779443413383],
					[57.983008428844506, 1.9375834530745866],
					[57.983008429735882, 1.9375834528983082],
					[57.983008433114023, 1.9375834488370738],
					[57.983008434005377, 1.9375834486608003],
					[57.983883410422635, 1.9367918697945921],
					[57.985366741435818, 1.9361391004360275],
					[57.986449997619026, 1.9361474009024],
					[57.986449999401714, 1.9361474005497346],
					[57.987666686863825, 1.9361558003593815],
					[57.98881937641076, 1.9362973990869434],
					[57.988819400664518, 1.9362973976822475],
					[57.988819451658721, 1.9362973909871648],
					[57.988819476522821, 1.9362973843711402],
					[57.990372276671124, 1.9356501852907189],
					[57.990372303553563, 1.9356501663959629],
					[57.990372351689373, 1.9356501246294593],
					[57.990372372849073, 1.9356501000793376],
					[57.990905752647095, 1.934730733835438],
					[57.992252921720464, 1.9336918580513414],
					[57.992252922611819, 1.9336918578749034],
					[57.992252926083495, 1.9336918554906912],
					[57.992252926881157, 1.9336918536357759],
					[57.993194514355167, 1.9329140650930425],
					[57.994338998212015, 1.9322723737400773],
					[57.994339000792323, 1.932272371532036],
					[57.994339007829353, 1.9322723684415903],
					[57.994339012192349, 1.9322723658806022],
					[57.995483394166946, 1.9315001774297047],
					[57.996763920924728, 1.9309919062822345],
					[57.998324895248203, 1.9312751838265827],
					[57.999008149437167, 1.932066830822222],
					[57.999008152298636, 1.9320668336502382],
					[57.999008158115281, 1.9320668409850634],
					[57.99900816097675, 1.932066843813083],
					[57.999827660704547, 1.9328584433636191],
					[57.999827678670862, 1.9328584584771451],
					[57.999827716902658, 1.9328584814593568],
					[57.999827737168147, 1.9328584893280252],
					[58.000644336952028, 1.9331279898270839],
					[58.000644340611103, 1.9331279907999448],
					[58.00064434980564, 1.9331279940714912],
					[58.000644354356048, 1.9331279948678313],
					[58.001797154302217, 1.9334001947307884],
					[58.001797180525955, 1.9334001963277792],
					[58.001797235460046, 1.9334001956344038],
					[58.001797262200292, 1.9334001903391906],
					[58.002944415174319, 1.9330252045729626],
					[58.003758168906273, 1.9331640815386564],
					[58.004444200029937, 1.9344667495980137],
					[58.004463699952943, 1.9364111012492919],
					[58.004463701874663, 1.9364111195425266],
					[58.004463709283492, 1.9364111554232044],
					[58.004463712894264, 1.9364111716844716],
					[58.005016513086815, 1.9378389712319626],
					[58.005016519277795, 1.9378389852852966],
					[58.005016533255137, 1.9378390096809119],
					[58.00501654015013, 1.9378390201996536],
					[58.005699835545705, 1.9387473143609082],
					[58.006041510526998, 1.9392667755178254],
					[58.006327600224367, 1.9408194181845049],
					[58.006335899948063, 1.9417250013182923],
					[58.006335908621232, 1.9417250437423816],
					[58.006335945296591, 1.9417251196737635],
					[58.006335970624747, 1.9417251537100448],
					[58.006947070635327, 1.9422418535607107],
					[58.006947076264275, 1.9422418575403835],
					[58.006947088413511, 1.9422418653233862],
					[58.006947093151076, 1.9422418694793662],
					[58.008377692754017, 1.943144569813591],
					[58.008377756646553, 1.9431445843402235],
					[58.0083778794179, 1.943144571940657],
					[58.008377938203147, 1.9431445433351839],
					[58.008511148916398, 1.9430169297315187],
					[58.009597132646583, 1.943400189805784],
					[58.00959714995674, 1.9434001931731804],
					[58.00959718270078, 1.9434001985812477],
					[58.00959719991738, 1.943400200269308],
					[58.011763887918292, 1.9434001992102914],
					[58.012783276425893, 1.9435251987840609],
					[58.012783281867641, 1.9435251994055509],
					[58.012783293642485, 1.9435252004722054],
					[58.012783299882024, 1.9435251992378864],
					[58.013727799815889, 1.9435252006412027],
					[58.014477750028405, 1.9435252004336843],
					[58.01542770619745, 1.9440334759427287],
					[58.015427733780463, 1.9440334857690913],
					[58.01542779213765, 1.9440334979980274],
					[58.015427821035495, 1.9440334990736943],
					[58.016647220729084, 1.9439029991679133],
					[58.016647277825207, 1.9439029725883694],
					[58.016647366172684, 1.9439028922771253],
					[58.016647396439055, 1.9439028370421179],
					[58.01684189643322, 1.9428806370151861],
					[58.016841897654366, 1.9428806265844407],
					[58.016841900003016, 1.9428806040432764],
					[58.016841900239193, 1.9428805921092518],
					[58.016750199585978, 1.9403277954458271],
					[58.016739100150687, 1.9389250047029967],
					[58.016914100014475, 1.9357361115152545],
					[58.016914099827076, 1.9357361081559774],
					[58.016914100343655, 1.9357361012608512],
					[58.016914100156264, 1.9357360979015652],
					[58.01690580031616, 1.9349693974430875],
					[58.016905798394085, 1.9349693791436198],
					[58.016905790984602, 1.9349693432509623],
					[58.016905784605811, 1.934969325834353],
					[58.016077990266183, 1.9329166362577368],
					[58.015727993429529, 1.931636047228886],
					[58.015727991365601, 1.9316360425434551],
					[58.015727988035394, 1.9316360313163874],
					[58.015727985877731, 1.9316360249513849],
					[58.015105790819817, 1.9300916392917427],
					[58.014552147466574, 1.9278116573295503],
					[58.014555800046175, 1.9275778024132399],
					[58.014555799764786, 1.9275777973747403],
					[58.014555799108209, 1.9275777856182064],
					[58.014555798733028, 1.9275777789002062],
					[58.014264098878627, 1.924866679059589],
					[58.014264088886243, 1.9248666453817578],
					[58.01426406026971, 1.9248665848325881],
					[58.014264039769088, 1.9248665566353664],
					[58.013080767017499, 1.9237137825564647],
					[58.012252981554788, 1.9218693177176931],
					[58.012252980381859, 1.9218693128564324],
					[58.012252975362053, 1.9218693036645107],
					[58.012252973297798, 1.9218692989801309],
					[58.010789081258672, 1.9193638141692466],
					[58.009961286909466, 1.9171805297768587],
					[58.009961265046321, 1.9171804933681222],
					[58.009961207810598, 1.9171804368160796],
					[58.009961174126666, 1.9171804146396096],
					[58.008965772844583, 1.9167821699409087],
					[58.007466841891535, 1.9152637588768802],
					[58.007466829646887, 1.9152637494239615],
					[58.007466804266286, 1.9152637306950999],
					[58.007466791130341, 1.9152637214191743],
					[58.0055611910444, 1.9142942219772487],
					[58.005561172654552, 1.9142942154432039],
					[58.0055611377514, 1.914294203700126],
					[58.005561118658157, 1.9142942007011501],
					[58.003533319367619, 1.9140970005215934],
					[58.003533315802422, 1.9140970012295839],
					[58.003533310360616, 1.9140970006126454],
					[58.003533306701442, 1.9140969996417023],
					[58.002247221151968, 1.9140526013730366],
					[58.001297247802277, 1.913888705038213],
					[57.999938960747215, 1.9134526094430464],
					[57.999938938886089, 1.9134526052969691],
					[57.999938892677861, 1.9134526008933297],
					[57.99993887020733, 1.913452601960552],
					[57.997844370445812, 1.9137720021402491],
					[57.997844358858856, 1.9137720044408277],
					[57.997844338452829, 1.9137720101897535],
					[57.9978443269598, 1.9137720141690051],
					[57.997233266149799, 1.9140108988582372],
					[57.996016707077445, 1.9139692001430222],
					[57.994730607243461, 1.9139247996313944],
					[57.994730589323403, 1.9139248014917263],
					[57.994730554468532, 1.9139248067139665],
					[57.994730536642443, 1.913924810252813],
					[57.993652736573573, 1.9142831104163731],
					[57.993652705050252, 1.914283126856579],
					[57.993652650777037, 1.9142831715724553],
					[57.993652627041833, 1.9142831983465753],
					[57.993049856590233, 1.9153137482060822],
					[57.992308314676585, 1.9156830690725914],
					[57.991286211801039, 1.9149915335719385],
					[57.991286198853025, 1.914991527656587],
					[57.991286172863084, 1.9149915141475302],
					[57.991286159023751, 1.9149915084090923],
					[57.990336159168976, 1.9146970084048562],
					[57.989863960308433, 1.9145498083453603],
					[57.989863916868089, 1.9145498050881637],
					[57.989863834586018, 1.9145498163274679],
					[57.989863793867734, 1.9145498294994792],
					[57.989055493676219, 1.9150526293956307],
					[57.989055470550262, 1.9150526509538994],
					[57.989055430819334, 1.9150526978671343],
					[57.989055415199594, 1.9150527247234548],
					[57.98852481694464, 1.9163555199321607],
					[57.987858136096008, 1.9177887768376538],
					[57.986983167663674, 1.9185554508751415],
					[57.986983155654713, 1.9185554617408793],
					[57.986983134592379, 1.9185554879765703],
					[57.98698312633644, 1.9185555014915701],
					[57.986252675597946, 1.919858111286459],
					[57.985236078904855, 1.9199637011966249],
					[57.985236069100331, 1.9199637031406307],
					[57.985236048600015, 1.9199637072053621],
					[57.985236038889376, 1.9199637108274603],
					[57.984088839318773, 1.9203331107354382],
					[57.984088824260212, 1.9203331154175662],
					[57.984088795315991, 1.9203331296392629],
					[57.984088781430337, 1.9203331391788367],
					[57.983216581036068, 1.9209748396892168],
					[57.983216569824322, 1.9209748486982239],
					[57.983216549371157, 1.9209748697188771],
					[57.983216539144578, 1.9209748802292022],
					[57.982544333958167, 1.9218886537129212],
					[57.981255699545386, 1.9213248247718042],
					[57.980300135936496, 1.920633152596948],
					[57.979480752202363, 1.9196776695663613],
					[57.979480747370339, 1.9196776637384974],
					[57.979480736017436, 1.9196776541140133],
					[57.979480731185383, 1.9196776482861511],
					[57.979002931164814, 1.9192637491151288],
					[57.9790029207971, 1.9192637409919961],
					[57.979002900155535, 1.919263726423541],
					[57.979002889975547, 1.9192637216560278],
					[57.977030689913128, 1.9182748201942021],
					[57.977030664205223, 1.9182748117204595],
					[57.977030612273623, 1.918274801660552],
					[57.977030585158623, 1.9182748002510834],
					[57.975747194731554, 1.9183720001920548],
					[57.97527586005284, 1.91835914326424],
					[57.975344563280032, 1.9182251202113727],
					[57.976597340226043, 1.9170001427746732],
					[57.976597355424616, 1.9170001244941322],
					[57.97659738098956, 1.9170000821059863],
					[57.976597391449857, 1.9170000596760721],
					[57.976861290879548, 1.9161444587182603],
					[57.976861293597942, 1.9161444106821355],
					[57.97686127487539, 1.9161443177048438],
					[57.976861254325762, 1.9161442725868929],
					[57.975494553728872, 1.9144887722925983],
					[57.975494521639654, 1.9144887464287506],
					[57.975494451176225, 1.9144887112140889],
					[57.975494411816705, 1.9144887003624387],
					[57.973597241314579, 1.9143748024345069],
					[57.971877891752371, 1.9137498203284371],
					[57.969975112866862, 1.9124498342960794],
					[57.969975107237197, 1.9124498303248325],
					[57.969975096165697, 1.9124498257371154],
					[57.969975089644706, 1.9124498219426826],
					[57.968708389530079, 1.9118081214727314],
					[57.968708383009044, 1.9118081176785435],
					[57.968708369263645, 1.9118081136215783],
					[57.968708361851334, 1.9118081100042188],
					[57.967800309138489, 1.9115107467384287],
					[57.966883461457705, 1.9102692815641271],
					[57.966883456625297, 1.910269275739489],
					[57.966883448837038, 1.9102692654137572],
					[57.966883444098606, 1.9102692612663306],
					[57.966614044052832, 1.9099887615738902],
					[57.966613999202792, 1.9099887331642369],
					[57.966613901296064, 1.9099887068026029],
					[57.966613848145371, 1.9099887071734245],
					[57.964855547773006, 1.9104637070228476],
					[57.964855517938588, 1.9104637214204314],
					[57.964855464087414, 1.9104637575402441],
					[57.964855440961976, 1.9104637790856269],
					[57.963852664189773, 1.9117859479372172],
					[57.962772097577258, 1.9124276276650118],
					[57.962772088946046, 1.9124276344637003],
					[57.962772071589633, 1.9124276463840446],
					[57.962772063849712, 1.9124276530059572],
					[57.961497063625444, 1.913610953216337],
					[57.961497061936733, 1.9136109552467522],
					[57.961497056776743, 1.9136109596610338],
					[57.961497054290668, 1.9136109635451382],
					[57.960624860201669, 1.9145359564040978],
					[57.959749865728227, 1.9153276521405258],
					[57.959749851327132, 1.9153276685590532],
					[57.959749824589302, 1.9153277060734364],
					[57.959749814035177, 1.9153277268159707],
					[57.959483124022803, 1.9160027016516024],
					[57.958680440382963, 1.917072079522312],
					[57.958680436207999, 1.9170720854359673],
					[57.958680428749446, 1.9170720970866293],
					[57.958680424574489, 1.9170721030002831],
					[57.958080435755413, 1.9181498838393511],
					[57.957141549607002, 1.9192192688999186],
					[57.957141534595571, 1.9192192905225514],
					[57.957141511984815, 1.919219337385744],
					[57.957141502696714, 1.9192193646562103],
					[57.956813702768059, 1.9209916638126958],
					[57.956813702345862, 1.9209916723731009],
					[57.956813699812649, 1.9209916915237015],
					[57.956813700375619, 1.9209917015843756],
					[57.956833099933846, 1.9231833017080524],
					[57.95684419989577, 1.9252389014509648],
					[57.95684419989577, 1.9252389014509648],
					[57.956855399979659, 1.9266111027088988],
					[57.956872000176567, 1.9283917025314512],
					[57.956883099876372, 1.9298971793238322],
					[57.956555405285791, 1.9313999572450811],
					[57.956555403690473, 1.9313999609511803],
					[57.956555402376218, 1.9313999696877036],
					[57.956555401672247, 1.9313999732175675],
					[57.956514396835288, 1.9317017306269841],
					[57.956169224371912, 1.9323721036478558],
					[57.955280436780775, 1.9339248815436034],
					[57.954655450742479, 1.9346220653712156],
					[57.954655436619603, 1.9346220868098567],
					[57.954655413815587, 1.9346221303069284],
					[57.954655405321766, 1.9346221557188057],
					[57.954219226004945, 1.9365026624908461],
					[57.95242210994553, 1.9374026202670525],
					[57.952422079777733, 1.937402644872477],
					[57.952422029808645, 1.9374027022063691],
					[57.95242201010096, 1.9374027366114628],
					[57.95145261536144, 1.9403138211987803],
					[57.950213722125497, 1.9427249088466958],
					[57.950213718934165, 1.9427249162565898],
					[57.950213711660126, 1.9427249312523081],
					[57.950213709453699, 1.9427249401628157],
					[57.949244209110383, 1.9458055392968825],
					[57.949244209203883, 1.9458055409733934],
					[57.949244209297376, 1.9458055426499044],
					[57.949244208405993, 1.9458055428257508],
					[57.948630419223299, 1.9478610058676549],
					[57.947383143769812, 1.9494220748720377],
					[57.94738312903317, 1.949422101505516],
					[57.947383106877759, 1.9494221567194394],
					[57.947383099552447, 1.9494221869763362],
					[57.947224806343549, 1.9517999063366955],
					[57.945880464702221, 1.9530276517930529],
					[57.945880443725024, 1.9530276796533936],
					[57.945880412747464, 1.9530277382955965],
					[57.945880402042491, 1.9530277726058596],
					[57.945541502432548, 1.9554110723896845],
				],
			],
		},
		{
			fldNpdidField: "43464",
			wlbNpdidWellbore: "74",
			fldName: "GUNGNE",
			fldCurrentActivitySatus: "Producing",
			wlbName: "15/9-15",
			fldDiscoveryYear: "1982",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43464",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43464",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "046",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=74",
			fldHcType: "GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "21156",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Dusavik",
			fldAreaGeometryWKT: [
				[
					[58.351921999610532, 1.8884527952007677],
					[58.351922001030346, 1.8884528206309366],
					[58.351922002584125, 1.8884528323182823],
					[58.352305403224676, 1.8907667323001391],
					[58.352305403413922, 1.8907667356908744],
					[58.352305404778242, 1.8907667439877252],
					[58.352305405858701, 1.8907667471984568],
					[58.352813705851275, 1.8928472465741726],
					[58.352813715291148, 1.8928472703856203],
					[58.352813737546548, 1.8928473138978856],
					[58.352813752144506, 1.8928473332387932],
					[58.353427596304456, 1.8935250724101473],
					[58.353321999751202, 1.8953888898701583],
					[58.353322008637512, 1.8953889360831209],
					[58.353322046624449, 1.8953890192851695],
					[58.353322076710867, 1.8953890577897889],
					[58.354183154941992, 1.8960612395467842],
					[58.3546748219714, 1.896741794821287],
					[58.35492759986154, 1.897433336690064],
					[58.354938700163828, 1.89906110060052],
					[58.354938703364013, 1.8990611256738177],
					[58.354938714922717, 1.8990611713504599],
					[58.354938724361496, 1.8990611951650571],
					[58.355433100954485, 1.8999750513127021],
					[58.355449799983433, 1.9016056017765524],
					[58.355449803088646, 1.9016056251549198],
					[58.355449814740965, 1.9016056725286727],
					[58.355449824084786, 1.9016056946487665],
					[58.35581922436424, 1.9022917944768565],
					[58.355819253274099, 1.9022918280830998],
					[58.355819323706491, 1.9022918790348191],
					[58.355819365229038, 1.9022918963803011],
					[58.356922018125587, 1.9024890715573901],
					[58.357055400903008, 1.9036472237424229],
					[58.357055404615743, 1.9036472418553916],
					[58.357055415606148, 1.9036472773624791],
					[58.357055423775059, 1.9036472945768836],
					[58.357424823934458, 1.9043361950685171],
					[58.357424862133989, 1.9043362336635805],
					[58.357424954333126, 1.9043362870914389],
					[58.357425007347075, 1.9043363004082583],
					[58.358036107027104, 1.9043140998401016],
					[58.358036149711971, 1.904314089518633],
					[58.35803622583267, 1.9043140484490297],
					[58.358036258566223, 1.9043140212719605],
					[58.358397359126627, 1.9038390206016054],
					[58.358397364190679, 1.9038390144361723],
					[58.358397374129851, 1.9038389987138598],
					[58.35839737820821, 1.903838991032432],
					[58.358639077644447, 1.9033639907043325],
					[58.358639077644447, 1.9033639907043325],
					[58.358877967623478, 1.9028918118957729],
					[58.359486175661182, 1.9021724207068305],
					[58.360460951295643, 1.9023751729777609],
					[58.360713691136738, 1.9030639103339406],
					[58.36047760623017, 1.9040027512383146],
					[58.360477606201236, 1.904002799260224],
					[58.360477629315461, 1.9040028906311139],
					[58.360477651567386, 1.904002934159817],
					[58.361091552388451, 1.9046807338304514],
					[58.361091587158683, 1.9046807594019983],
					[58.361091663775767, 1.9046807919692434],
					[58.361091705717079, 1.9046808006607745],
					[58.361583305807095, 1.904666900247167],
					[58.361583356229715, 1.9046668832197002],
					[58.361583443369838, 1.9046668296345792],
					[58.361583479007216, 1.904666789864887],
					[58.362061263949478, 1.9037195194671626],
					[58.362550130608192, 1.9032362523888513],
					[58.363516820091128, 1.9025084601623339],
					[58.363516849583604, 1.9025084233426515],
					[58.36351689017701, 1.9025083431233136],
					[58.363516900292289, 1.9025082982074957],
					[58.363511299892565, 1.9018082987250124],
					[58.363500199919251, 1.9001805985134599],
					[58.363500193383913, 1.9001805621019505],
					[58.363500165162357, 1.9001804923363144],
					[58.363500145447638, 1.9001804620143714],
					[58.362636244867176, 1.8992748615711086],
					[58.362636210190466, 1.8992748376998672],
					[58.36263613366674, 1.8992748068392107],
					[58.362636092710943, 1.8992747996699368],
					[58.36214723939694, 1.8992914984998104],
					[58.361655822759843, 1.8990748800949424],
					[58.361775196735579, 1.8983722329272079],
					[58.361891870209561, 1.8976723944320311],
					[58.362377807185531, 1.8976558007609363],
					[58.362866706958791, 1.897639100241183],
					[58.362866771261615, 1.8976390735408797],
					[58.362866867296745, 1.8976389855553588],
					[58.362866898137781, 1.8976389244500811],
					[58.362983498278417, 1.8967028249477602],
					[58.362983498791415, 1.8967028179842076],
					[58.36298349981741, 1.8967028040571168],
					[58.362983500330429, 1.8967027970935564],
					[58.362980800182783, 1.8964721986457835],
					[58.362980798629671, 1.8964721869543761],
					[58.362980796603743, 1.896472166783467],
					[58.362980795145184, 1.8964721567879799],
					[58.362725203118259, 1.8953138904508369],
					[58.362841893985561, 1.8943806597483825],
					[58.363564077613475, 1.8929583909306775],
					[58.363564077613475, 1.8929583909306775],
					[58.363922348730689, 1.8922501485654892],
					[58.364411191013076, 1.8920001782927884],
					[58.36441122374363, 1.8920001510991062],
					[58.364411275174284, 1.8920000875409075],
					[58.364411293779753, 1.8920000494804559],
					[58.364647394073458, 1.8910611485321456],
					[58.364647396084663, 1.8910611361201477],
					[58.364647399215869, 1.8910611114762312],
					[58.364647400241225, 1.8910610975483588],
					[58.364628000343323, 1.8887332987410144],
					[58.364627986808991, 1.8887332500240768],
					[58.364627937933548, 1.8887331655735555],
					[58.364627903483637, 1.8887331296598213],
					[58.364258402981314, 1.8885109297838061],
					[58.364258393690619, 1.8885109248015253],
					[58.364258375109223, 1.8885109148369397],
					[58.364258365021996, 1.8885109117307328],
					[58.363647264712895, 1.8882998116567224],
					[58.363647204244941, 1.8882998101592721],
					[58.363647091630092, 1.8882998432132772],
					[58.363647041265637, 1.8882998774044364],
					[58.3632804410651, 1.8887776779452001],
					[58.3632804287383, 1.888777699301964],
					[58.363280408730041, 1.8887777445066065],
					[58.363280402831002, 1.8887777679942221],
					[58.363166543729839, 1.889480312034765],
					[58.362186243149807, 1.8890470452682353],
					[58.361814092573525, 1.8883610355934497],
					[58.361683498310832, 1.8874332713643951],
					[58.361683493799262, 1.8874332551269415],
					[58.361683482899018, 1.8874332213165081],
					[58.361683475713839, 1.8874332056195],
					[58.361314099587759, 1.8867471492646437],
					[58.361305799868198, 1.8858166972659343],
					[58.361305768870814, 1.8858166263614144],
					[58.361305666087198, 1.8858165288130573],
					[58.361305594206229, 1.8858165004734619],
					[58.360327793941337, 1.8858470002213852],
					[58.360327793050118, 1.8858470004015788],
					[58.360327792158913, 1.8858470005817602],
					[58.359961091689904, 1.8858609001912927],
					[58.359961056041953, 1.8858609073985559],
					[58.359960990054269, 1.8858609361736536],
					[58.359960958823351, 1.8858609579216838],
					[58.359474859266463, 1.8863415575277647],
					[58.359474859361171, 1.8863415592234567],
					[58.359233190690667, 1.886583125621734],
					[58.357772193318482, 1.8870969905911235],
					[58.357036167574236, 1.8868887109520096],
					[58.35605573111075, 1.8864526393497547],
					[58.355564087867222, 1.8857721192051311],
					[58.355433497036692, 1.8850777631968856],
					[58.355433476561075, 1.8850777193262831],
					[58.355433419339995, 1.8850776468767867],
					[58.355433380717407, 1.8850776169627379],
					[58.354947280687767, 1.8848609171489814],
					[58.354947259622001, 1.8848609111200549],
					[58.35494721589744, 1.8848609028135512],
					[58.354947193238687, 1.8848609005359338],
					[58.353722193219895, 1.8849025996433739],
					[58.352255594011631, 1.884949800227965],
					[58.352255528725834, 1.8849498254265271],
					[58.352255432699621, 1.8849499134172658],
					[58.352255401067978, 1.8849499763895958],
					[58.352141500945464, 1.8858860764000795],
					[58.352141501040158, 1.8858860780954376],
					[58.352141501229532, 1.8858860814861214],
					[58.352141501324219, 1.8858860831814643],
					[58.351922000730383, 1.8884527829732429],
					[58.351921999610532, 1.8884527952007677],
				],
				[
					[58.2984804017755, 1.9000916848499556],
					[58.29848040207083, 1.9000917224510219],
					[58.298480404095422, 1.9000917425855948],
					[58.299647004275748, 1.9055750417288648],
					[58.2996470077045, 1.9055750547343342],
					[58.299647018221307, 1.9055750817211252],
					[58.299647024323789, 1.9055750941887939],
					[58.301402591195163, 1.9088611330711229],
					[58.300819208816968, 1.9121582333328964],
					[58.299063722562224, 1.9154582052437608],
					[58.29906371487472, 1.9154582290417714],
					[58.299063703064803, 1.9154582759217242],
					[58.299063699927871, 1.915458300517596],
					[58.29906369993175, 1.9286333006123404],
					[58.299063700213971, 1.928633305691221],
					[58.299063700872431, 1.9286333175419599],
					[58.299063701248706, 1.9286333243138196],
					[58.300819200887389, 1.9429028235965038],
					[58.300819202060339, 1.9429028284974832],
					[58.300819204406253, 1.9429028382994145],
					[58.300819204687912, 1.942902843378719],
					[58.302569205236566, 1.9505833436036772],
					[58.302569207581918, 1.9505833534066412],
					[58.302569214055303, 1.9505833726562662],
					[58.302569217198212, 1.9505833805878676],
					[58.305491510820318, 1.9571667674706865],
					[58.307244207282146, 1.9637528515583027],
					[58.307244208360721, 1.9637528547674092],
					[58.307244209720132, 1.9637528630569756],
					[58.307244210798707, 1.9637528662660972],
					[58.309577611261318, 1.9703389666982698],
					[58.309577682416283, 1.9703390312769566],
					[58.309577845008157, 1.9703390707777528],
					[58.309577937336464, 1.970339045522129],
					[58.311916836926812, 1.9681390453233578],
					[58.311916844574874, 1.9681390369484923],
					[58.311916859870955, 1.9681390201987587],
					[58.311916866534084, 1.9681390103079468],
					[58.314833466610331, 1.9637390104073862],
					[58.314833474868543, 1.9637389967714163],
					[58.314833487632328, 1.9637389668233944],
					[58.314833493029226, 1.9637389503334572],
					[58.316589092581374, 1.9571417507616922],
					[58.316589083016417, 1.9571416756044551],
					[58.316589012234367, 1.9571415527359741],
					[58.316588952002299, 1.95714150654057],
					[58.312500175114266, 1.9560498391321237],
					[58.311333501797563, 1.9494610944694515],
					[58.312500198919395, 1.9395694230997724],
					[58.312500198731513, 1.9395694197124722],
					[58.312500199341024, 1.939569414453048],
					[58.312500200044461, 1.9395694108872472],
					[58.313083497797685, 1.9285806344833929],
					[58.316005785592921, 1.9186806756701553],
					[58.319508477140133, 1.9120694939378975],
					[58.31950848262445, 1.9120694791322015],
					[58.319508492701836, 1.9120694497000275],
					[58.319508497294912, 1.912069435073551],
					[58.320677991102215, 1.9054694657410614],
					[58.323014077040483, 1.9010611944309728],
					[58.323014086412869, 1.9010611685603958],
					[58.323014097838879, 1.9010611148675631],
					[58.323014099892504, 1.9010610870452951],
					[58.322430801781856, 1.8922694190626794],
					[58.324180791760384, 1.8856611569017079],
					[58.325936290688333, 1.8801528616041741],
					[58.325936282670902, 1.8801527827032476],
					[58.325936213315394, 1.8801526545214393],
					[58.325936151180869, 1.8801526071147121],
					[58.321847250941588, 1.8790804068820732],
					[58.321847238180666, 1.8790804043216576],
					[58.321847211862384, 1.8790804010747588],
					[58.321847199196206, 1.8790804002081953],
					[58.320091699173808, 1.8790915005889728],
					[58.32009164767419, 1.879091514420713],
					[58.320091559653491, 1.8790915681774909],
					[58.320091523037675, 1.8790916064087266],
					[58.317755443763993, 1.8835026668100694],
					[58.314833232397532, 1.8857191992577615],
					[58.311333299440548, 1.8857386998287673],
					[58.311333270827326, 1.885738703890361],
					[58.311333217638932, 1.8857387197611253],
					[58.311333193063739, 1.8857387315703149],
					[58.309577791440724, 1.8868497696518052],
					[58.305491793989518, 1.8846776233524929],
					[58.305491750967008, 1.8846776114871537],
					[58.305491665166699, 1.8846776082536087],
					[58.305491622294205, 1.8846776151922964],
					[58.300236022110063, 1.8869054155833167],
					[58.300235962634453, 1.8869055285831495],
					[58.300236003115181, 1.8869057207172033],
					[58.300236101289045, 1.8869058002108676],
					[58.30081916109075, 1.8869030015766746],
					[58.299647003036391, 1.8934971652299084],
					[58.298480403410302, 1.9000916656906417],
					[58.2984804017755, 1.9000916848499556],
				],
			],
		},
		{
			fldNpdidField: "21123063",
			wlbNpdidWellbore: "1868",
			fldName: "BRYNHILD",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "7/7-2",
			fldDiscoveryYear: "1992",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=21123063",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=21123063",
			fldOwnerKind: "",
			fldOwnerName: "",
			cmpLongName: "",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=1868",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "",
			fldNpdidOwner: "",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[57.472599799577701, 2.3028528122996619],
					[57.472847000135971, 2.3066417123439802],
					[57.47284700814626, 2.3066417443150358],
					[57.47284703291146, 2.3066418033068818],
					[57.472847049754648, 2.3066418319835602],
					[57.474705450318559, 2.308750132094779],
					[57.474705464582875, 2.3087501461975717],
					[57.474705499268033, 2.3087501716005145],
					[57.474705516924331, 2.3087501817361051],
					[57.478408195063381, 2.3104418716255051],
					[57.480030422683569, 2.3117112157853015],
					[57.481205405881454, 2.3163472498004474],
					[57.481205405881454, 2.3163472498004474],
					[57.48120540686174, 2.316347251293092],
					[57.481205407038033, 2.3163472546057089],
					[57.48261370673341, 2.3214083544742623],
					[57.482613709762191, 2.3214083606091882],
					[57.48261371403548, 2.3214083732061139],
					[57.482613717152347, 2.3214083809974264],
					[57.484477616739568, 2.3256250816636252],
					[57.484477663360103, 2.3256251283183742],
					[57.48447777455587, 2.3256251865584985],
					[57.484477840827367, 2.3256251961604555],
					[57.492333340582903, 2.3239640962249619],
					[57.492333348612227, 2.3239640947530322],
					[57.492333365386891, 2.3239640883319574],
					[57.492333371543857, 2.3239640855302865],
					[57.502025071650635, 2.3201973859209244],
					[57.502025097886062, 2.3201973710663184],
					[57.50202514465159, 2.320197335710239],
					[57.502025165093563, 2.3201973135515046],
					[57.504322364806733, 2.3168279131780634],
					[57.504322373914839, 2.3168278981174351],
					[57.504322388386171, 2.3168278653366308],
					[57.504322393837576, 2.3168278492738144],
					[57.506825193756349, 2.3071278508659456],
					[57.506825195814613, 2.307127838771708],
					[57.506825199127292, 2.3071278164047557],
					[57.506825200381698, 2.3071278061320242],
					[57.507227999616326, 2.2936278057041908],
					[57.507228000331452, 2.2936278022249916],
					[57.507228000066029, 2.2936277972527468],
					[57.507227999977559, 2.2936277955953384],
					[57.506955800383487, 2.283499994879497],
					[57.506955800206249, 2.2834999915647605],
					[57.506955798871132, 2.2834999834425762],
					[57.506955798605311, 2.2834999784704748],
					[57.506230798847554, 2.2767499798236397],
					[57.506230798670167, 2.2767499765090102],
					[57.506230797600715, 2.2767499733591818],
					[57.506230798404083, 2.2767499715370882],
					[57.505039098050851, 2.2683110723589537],
					[57.505039091188891, 2.2683110451760156],
					[57.505039068722091, 2.2683109957744456],
					[57.505039054009345, 2.2683109733908271],
					[57.502255753645635, 2.2649359736779955],
					[57.502255739287733, 2.264935957925768],
					[57.50225570539741, 2.2649359307257853],
					[57.502255686845871, 2.2649359207700805],
					[57.498783386766966, 2.2632526203888093],
					[57.498783365628114, 2.263252612586903],
					[57.498783321832903, 2.2632526022840249],
					[57.498783300068595, 2.2632525996179886],
					[57.496702800025794, 2.2632525993529917],
					[57.4967027529257, 2.2632526114146119],
					[57.496702669693065, 2.2632526552610304],
					[57.496702633649441, 2.2632526887026678],
					[57.491883134058945, 2.2704248879271454],
					[57.491883126563103, 2.27042489935062],
					[57.491883115317158, 2.2704249248514521],
					[57.491883109694164, 2.2704249376018648],
					[57.488002610187685, 2.2822249383382118],
					[57.488002609384239, 2.2822249401592614],
					[57.488002607062434, 2.2822249472788774],
					[57.488002606436197, 2.2822249524129266],
					[57.486860923700355, 2.2868609792523515],
					[57.480183186005895, 2.2914887365242738],
					[57.480183179849739, 2.2914887393302927],
					[57.480183166999282, 2.2914887517313214],
					[57.48018316093161, 2.2914887561935138],
					[57.475808161130146, 2.295697055193092],
					[57.475808149348126, 2.2956970707389219],
					[57.475808126676185, 2.295697101666438],
					[57.475808117658893, 2.2956971183758492],
					[57.472599818003175, 2.3028527186200169],
					[57.472599810230257, 2.3028527417857627],
					[57.472599801909624, 2.3028527884616516],
					[57.472599799577701, 2.3028528122996619],
				],
			],
		},
		{
			fldNpdidField: "1028599",
			wlbNpdidWellbore: "87",
			fldName: "TAMBAR",
			fldCurrentActivitySatus: "Producing",
			wlbName: "1/3-3",
			fldDiscoveryYear: "1983",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=1028599",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=1028599",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "065",
			cmpLongName: "Aker BP ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=87",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "28544099",
			fldNpdidOwner: "21316",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Tananger",
			fldAreaGeometryWKT: [
				[
					[56.942810904969853, 3.0129443574986783],
					[56.942810923269633, 3.0129444385632129],
					[56.942811015555826, 3.0129445604398821],
					[56.942811091250526, 3.0129445993300923],
					[56.943774990799582, 3.0129863000481678],
					[56.94377501321356, 3.0129862980907016],
					[56.943775055204171, 3.0129862913375924],
					[56.943775075595717, 3.0129862847636306],
					[56.945141760040414, 3.012427991401688],
					[56.94646944437374, 3.0121251956787316],
					[56.946469471755464, 3.0121251846845136],
					[56.946469523445899, 3.0121251549535817],
					[56.946469546861223, 3.012125136360567],
					[56.947541794020509, 3.0109668937320735],
					[56.94868059669075, 3.0108696012887264],
					[56.950777775902552, 3.0111223993592375],
					[56.95077780196867, 3.0111223984598516],
					[56.950777852893225, 3.0111223902651831],
					[56.950777877830262, 3.0111223846048127],
					[56.95253337794982, 3.0103807836053407],
					[56.952533392008704, 3.0103807763996526],
					[56.952533419154449, 3.0103807604971],
					[56.952533432162774, 3.0103807501652233],
					[56.954464032303648, 3.0086918502456639],
					[56.954464045154609, 3.0086918366424822],
					[56.954464069727308, 3.0086918046747422],
					[56.954464079662252, 3.0086917865978666],
					[56.955436279867648, 3.0066750870056551],
					[56.955436284020166, 3.0066750797457376],
					[56.955436290381172, 3.0066750622434464],
					[56.955436291774859, 3.0066750537800977],
					[56.956364086026255, 3.003391776729079],
					[56.957794574835411, 3.0008278974341889],
					[56.957794582167644, 3.0008278814209612],
					[56.957794593258527, 3.0008278499707268],
					[56.957794596938498, 3.0008278328985045],
					[56.958227991103705, 2.9982583717371361],
					[56.958911270650404, 2.9971307035922652],
					[56.958911277325015, 2.997130692627699],
					[56.958911286943156, 2.9971306680046883],
					[56.958911291752216, 2.997130655693184],
					[56.959408492147325, 2.9954361553469995],
					[56.959408495090685, 2.9954361416882307],
					[56.959408499190602, 2.9954361146591064],
					[56.959408500268403, 2.9954360996534684],
					[56.95938910054209, 2.9913195313966394],
					[56.961802878068504, 2.9902973837386289],
					[56.961802885136692, 2.9902973809486908],
					[56.961802900087577, 2.9902973735891414],
					[56.961802907076908, 2.9902973691638679],
					[56.964219507254654, 2.9887612686136009],
					[56.96421952026094, 2.9887612582707708],
					[56.964219542778885, 2.9887612397980625],
					[56.964219553919577, 2.9887612281085403],
					[56.965789054010038, 2.9868668277069959],
					[56.965789063284888, 2.9868668146697357],
					[56.965789079968935, 2.9868667872486028],
					[56.965789087299235, 2.9868667712292014],
					[56.96655298373912, 2.9848861780713758],
					[56.967439080200208, 2.9829500885148601],
					[56.968944577116822, 2.9800722921866676],
					[56.968944580453297, 2.9800722867013603],
					[56.968944586074954, 2.9800722726041284],
					[56.968944589332452, 2.9800722654832001],
					[56.969983477255859, 2.9770833990749885],
					[56.971519555295465, 2.9751945263831394],
					[56.971519561153684, 2.9751945171913738],
					[56.971519572055804, 2.9751945005882678],
					[56.971519577099698, 2.9751944931769465],
					[56.97256956012707, 2.9731834253812042],
					[56.974219533301032, 2.9717223498755372],
					[56.974219546068426, 2.9717223346163379],
					[56.974219569737436, 2.971722302751802],
					[56.9742195797457, 2.9717222862912882],
					[56.975222380427851, 2.9696222855477066],
					[56.975222382870491, 2.9696222802054368],
					[56.975222387676666, 2.9696222678850024],
					[56.975222390119313, 2.9696222625427331],
					[56.976319579608187, 2.9662500933183371],
					[56.978264060372176, 2.9636640197227933],
					[56.978264060372176, 2.9636640197227933],
					[56.978264061186302, 2.9636640179417637],
					[56.978264062079653, 2.9636640177967051],
					[56.979827961909024, 2.9615029165549891],
					[56.979827963616458, 2.9615029146287206],
					[56.979827968659471, 2.9615029072138652],
					[56.979827971180981, 2.9615029035064455],
					[56.982486267154904, 2.9571362101728038],
					[56.984480718173835, 2.9543029784449346],
					[56.985685927658409, 2.9540446413730095],
					[56.985935896950373, 2.9561305854115956],
					[56.985291506789189, 2.95855554943383],
					[56.985291504008693, 2.9585555663774272],
					[56.985291501206952, 2.9585556014652519],
					[56.985291501185706, 2.9585556196094775],
					[56.985408101027303, 2.9597833192809526],
					[56.985408127265764, 2.9597833776848637],
					[56.985408209899312, 2.9597834665032265],
					[56.985408266294399, 2.9597834969176819],
					[56.986472094411205, 2.9599668852782339],
					[56.987641546865397, 2.9613612290206737],
					[56.987641619657047, 2.9613612633718027],
					[56.987641767668237, 2.9613612706568389],
					[56.987641843543365, 2.9613612385364392],
					[56.988422343964714, 2.9605557391922979],
					[56.988422354129824, 2.9605557259951714],
					[56.98842237259413, 2.9605556982548671],
					[56.98842238097258, 2.9605556853481159],
					[56.989436277260651, 2.9583889939498227],
					[56.990441783250937, 2.9567113511656617],
					[56.992102723234929, 2.95740298432146],
					[56.992102798908135, 2.9574029852105217],
					[56.99210292790535, 2.9574029345414226],
					[56.99210298293665, 2.9574028810561677],
					[56.99355298293802, 2.9541444807076607],
					[56.99355298619389, 2.9541444735797047],
					[56.99355299173299, 2.9541444578325309],
					[56.993552993936859, 2.9541444475766965],
					[56.994408489004975, 2.95068896819491],
					[56.996422379604027, 2.9465778884509435],
					[56.996422390441865, 2.9465778520418309],
					[56.996422394985942, 2.9465777787158252],
					[56.99642239129264, 2.9465777397255195],
					[56.99584459112436, 2.9447360394592437],
					[56.995844561048024, 2.9447359948721106],
					[56.995844482037548, 2.944735925268096],
					[56.995844432369005, 2.9447359036702063],
					[56.994225031944225, 2.9444720025700648],
					[56.994225023825102, 2.9444720022438511],
					[56.994225008400605, 2.9444719998092159],
					[56.994225000281453, 2.9444719994830089],
					[56.992375184072856, 2.9444691996711043],
					[56.992180805874696, 2.9421944615450095],
					[56.99312235584474, 2.9410112248776046],
					[56.993122368448965, 2.9410112063267881],
					[56.993122387324846, 2.941011168608342],
					[56.993122394410285, 2.9410111476584735],
					[56.993683493028797, 2.9387111542169251],
					[56.99433079027807, 2.9366778640014175],
					[56.994697388860871, 2.9356417665403924],
					[56.994697393760866, 2.9356417376971966],
					[56.994697397307853, 2.9356416810315178],
					[56.994697394009123, 2.9356416502274163],
					[56.994300193655192, 2.9340527510096996],
					[56.99430012357206, 2.9340526799729609],
					[56.994299958305959, 2.9340526327252863],
					[56.994299861336451, 2.9340526568060503],
					[56.992849890969069, 2.9354637273882651],
					[56.991194331189455, 2.9360748119174316],
					[56.991194316242357, 2.9360748193051855],
					[56.991194289028016, 2.9360748336433291],
					[56.99119427594708, 2.9360748423759713],
					[56.989644338590971, 2.9372997926596431],
					[56.98709171978328, 2.9370442016998268],
					[56.987091688359136, 2.9370442035281394],
					[56.987091625988214, 2.937044217002478],
					[56.987091595934736, 2.9370442285027738],
					[56.985385996224771, 2.9380776296075028],
					[56.985385972821923, 2.9380776482674915],
					[56.98538593274651, 2.938077694384817],
					[56.985385915994421, 2.9380777202059507],
					[56.984383126419019, 2.9403942988791671],
					[56.982313739957753, 2.9431359806208888],
					[56.982313733288244, 2.9431359916020039],
					[56.982313719135412, 2.9431360153458934],
					[56.9823137134387, 2.9431360278175465],
					[56.981133112947553, 2.9462110283318399],
					[56.981133109850973, 2.9462110387301119],
					[56.981133103657797, 2.9462110595266511],
					[56.981133102347819, 2.9462110696339634],
					[56.980933111035604, 2.9475360121429905],
					[56.980641641668413, 2.9478691626806728],
					[56.980219511302792, 2.947705429168765],
					[56.97998078107063, 2.947469298371316],
					[56.979722400294882, 2.9464138782577765],
					[56.979755800116621, 2.9446583164288014],
					[56.980183495406131, 2.9419278482539313],
					[56.981019589908982, 2.9394278625889498],
					[56.981019589829458, 2.9394278609529261],
					[56.981019592350272, 2.9394278572439156],
					[56.981019592270748, 2.9394278556078879],
					[56.981450188958988, 2.9379139672514265],
					[56.982066883904707, 2.9364806795897445],
					[56.982066886345869, 2.9364806742443794],
					[56.982066888627912, 2.9364806656268749],
					[56.982066891069067, 2.9364806602814979],
					[56.982650190912601, 2.9346528617895098],
					[56.98265019392862, 2.9346528497538258],
					[56.982650198094461, 2.9346528243379093],
					[56.982650200217179, 2.934652812447998],
					[56.98274740008285, 2.9330889125582189],
					[56.982747399923632, 2.933088909286039],
					[56.982880799772673, 2.9303222117811591],
					[56.983064098919868, 2.9278750154455175],
					[56.983064099653774, 2.9278750120274224],
					[56.983064100387701, 2.9278750086093241],
					[56.98306410022834, 2.9278750053371496],
					[56.983158500360467, 2.9241722060867956],
					[56.983300199794648, 2.9196056066420351],
					[56.983300199635075, 2.9196056033699036],
					[56.983300200288966, 2.919605598315556],
					[56.983300200129399, 2.9196055950434245],
					[56.98315579982026, 2.9140083008675255],
					[56.983319599877547, 2.9089278061509072],
					[56.983319600211303, 2.9089277945522634],
					[56.983319597225915, 2.9089277703044951],
					[56.98331959480003, 2.9089277575089918],
					[56.982789101371985, 2.9064749854736198],
					[56.982952999216373, 2.9040639260544561],
					[56.983333496157421, 2.9020583375046627],
					[56.983333498036963, 2.9020583207048274],
					[56.983333499036284, 2.9020582859088164],
					[56.983333498236128, 2.9020582695486663],
					[56.983172397840164, 2.9010138702069304],
					[56.983172391200313, 2.9010138449103162],
					[56.983172369961608, 2.9010137972723329],
					[56.98317235722925, 2.9010137762737886],
					[56.982430757169347, 2.9000720764445704],
					[56.982430707827362, 2.9000720433150393],
					[56.98243059986541, 2.9000720082626454],
					[56.982430541405542, 2.9000720096117174],
					[56.981138841231584, 2.9004720081433812],
					[56.981138813128553, 2.9004720226494949],
					[56.981138762601979, 2.9004720573258993],
					[56.981138741151703, 2.9004720789855183],
					[56.980560940704677, 2.9012304782758407],
					[56.980560928346577, 2.9012305017408431],
					[56.980560908989759, 2.9012305477914979],
					[56.980560902071062, 2.9012305720130258],
					[56.980094202267239, 2.9044943697700951],
					[56.979777602827596, 2.9062999642055272],
					[56.97977760241411, 2.9062999741672146],
					[56.979777599720691, 2.9062999927475635],
					[56.979777600120485, 2.9063000009269273],
					[56.979797000410819, 2.9105722005383887],
					[56.979824800258072, 2.9150527847140268],
					[56.979083103559837, 2.9198360607798044],
					[56.978210910666824, 2.9233832320480109],
					[56.977438719101002, 2.9250055134824837],
					[56.977438712751969, 2.9250055310085425],
					[56.977438703706689, 2.9250055671126134],
					[56.977438701010435, 2.9250055856906143],
					[56.977285901353625, 2.9270832808512846],
					[56.976608101985846, 2.9326582715549701],
					[56.975597002558004, 2.9384110651359792],
					[56.975597002717073, 2.9384110684075502],
					[56.975597002062379, 2.9384110734605646],
					[56.975597002141924, 2.9384110750963472],
					[56.974966503673933, 2.9434943527920208],
					[56.973227617280877, 2.9482916195045292],
					[56.9713581391105, 2.9518776775289304],
					[56.968880463925089, 2.9541831544070356],
					[56.968880457016517, 2.9541831604754756],
					[56.968880444251347, 2.9541831757382342],
					[56.968880437422094, 2.9541831834422125],
					[56.966519237301398, 2.9574887842873827],
					[56.966519230551192, 2.9574887936257115],
					[56.966519219810046, 2.9574888135024011],
					[56.966519215005057, 2.957488825821343],
					[56.965385925730729, 2.9602804967557312],
					[56.963824854369179, 2.9621942624580622],
					[56.961727663935186, 2.9641470532036305],
					[56.961727656291096, 2.964147062684475],
					[56.961727641737902, 2.9641470782306811],
					[56.961727634987149, 2.9641470875665745],
					[56.959880456028252, 2.9668554566294665],
					[56.959141586744465, 2.967360935194769],
					[56.959141573740055, 2.9673609455434273],
					[56.959141548782888, 2.9673609693661858],
					[56.95914153772344, 2.967360982695431],
					[56.957777637769418, 2.9692581824092388],
					[56.957777628575563, 2.9692581970828988],
					[56.957777614654567, 2.969258225706207],
					[56.957777609113201, 2.9692582414357584],
					[56.956616524833947, 2.9730609889185398],
					[56.954910966352649, 2.9745998524114206],
					[56.954910954319956, 2.9745998642466387],
					[56.95491093146412, 2.9745998943123801],
					[56.954910922348624, 2.9745999106185743],
					[56.953480431560187, 2.9774248899344808],
					[56.951335946333565, 2.9800276724763761],
					[56.951335925736807, 2.980027712055918],
					[56.951335904592135, 2.9800277962099671],
					[56.951335904044207, 2.9800278407844774],
					[56.951496993134789, 2.9808166861878318],
					[56.950580410662205, 2.9834055333931251],
					[56.950580406984592, 2.9834055504635493],
					[56.95058040133722, 2.9834055826807067],
					[56.950580400339717, 2.9834055993178294],
					[56.950577600067568, 2.9846943261288059],
					[56.949744246990171, 2.9856776708093249],
					[56.949744241866625, 2.9856776765799378],
					[56.949744231777323, 2.9856776913907441],
					[56.949744227626034, 2.9856776986517866],
					[56.949324827686453, 2.9863859988294958],
					[56.949324813893362, 2.9863860488364784],
					[56.949324814396199, 2.9863861525494748],
					[56.949324829427717, 2.9863862028415968],
					[56.949597028591455, 2.9868390035749708],
					[56.94959703912847, 2.9868390167003893],
					[56.949597061095879, 2.9868390428068654],
					[56.949597073419667, 2.9868390556436002],
					[56.950294220941927, 2.9874112108241673],
					[56.950699799274268, 2.9891583195969922],
					[56.95056650401304, 2.9924555024079993],
					[56.950327674005159, 2.9926498448900078],
					[56.950327663678586, 2.9926498547946978],
					[56.950327644812177, 2.992649874315652],
					[56.950327636351162, 2.9926498855667742],
					[56.950099835935234, 2.9929776862735418],
					[56.950099830890139, 2.9929776936780637],
					[56.950099821693307, 2.9929777083428992],
					[56.950099817620369, 2.9929777172380656],
					[56.949391524933645, 2.9945277015200178],
					[56.948733133731672, 2.9954998886597797],
					[56.948733127057146, 2.9954998996215081],
					[56.9487331148378, 2.9954999263051874],
					[56.948733110028805, 2.9954999386134404],
					[56.948277610311735, 2.9969138395060186],
					[56.948277606395997, 2.996913851669849],
					[56.948277600587552, 2.9969138806136342],
					[56.94827760032414, 2.9969138938358761],
					[56.948141502040819, 3.0018221393667166],
					[56.947002631246448, 3.0036165928638479],
					[56.947002625464563, 3.0036166036797161],
					[56.947002614794229, 3.0036166251675178],
					[56.947002610799153, 3.0036166356955358],
					[56.945749819817173, 3.0073027082180039],
					[56.94439423563405, 3.0092304849871034],
					[56.944394234819221, 3.0092304867655106],
					[56.944394232296133, 3.0092304904661069],
					[56.944394230587903, 3.0092304923883062],
					[56.943222030703183, 3.0110804925054024],
					[56.943222023369472, 3.0110805085102377],
					[56.943222009595424, 3.0110805403761729],
					[56.943222005020502, 3.0110805575844175],
					[56.942810904969853, 3.0129443574986783],
				],
			],
		},
		{
			fldNpdidField: "2834734",
			wlbNpdidWellbore: "3260",
			fldName: "URD",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6608/10-6",
			fldDiscoveryYear: "2000",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=2834734",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=2834734",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "128",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=3260",
			fldHcType: "OIL",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "21836",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Sandnessjøen",
			fldAreaGeometryWKT: [
				[
					[66.082724801263154, 8.2802249915795567],
					[66.082724801376472, 8.2802250158914266],
					[66.082724802325018, 8.2802250269168951],
					[66.082883102353989, 8.2813778263938218],
					[66.082883103281887, 8.28137783299902],
					[66.082883106034686, 8.2813778461840997],
					[66.082883106972872, 8.2813778549994996],
					[66.083074807344687, 8.2820667546440294],
					[66.083074817314866, 8.2820667764675502],
					[66.083074841740398, 8.2820668199879801],
					[66.083074857082522, 8.2820668394493957],
					[66.091016557198131, 8.2902390399881956],
					[66.091016603976215, 8.2902390674278017],
					[66.091016706360548, 8.2902390911041],
					[66.091016761079914, 8.2902390895767244],
					[66.091827861165214, 8.289977989661141],
					[66.091827871909373, 8.289977984938627],
					[66.091827891613733, 8.2899779777546598],
					[66.091827902357849, 8.2899779730321264],
					[66.092491622151115, 8.2895863777823955],
					[66.093991523956205, 8.2923889948212661],
					[66.093991527574744, 8.2923890013548558],
					[66.093991536605913, 8.292389014372116],
					[66.093991541131643, 8.2923890230919088],
					[66.095922041292184, 8.294908421770181],
					[66.095922050312993, 8.2949084325780031],
					[66.095922070138684, 8.2949084519325496],
					[66.095922080046506, 8.2949084605041712],
					[66.097530474001488, 8.2961112555693468],
					[66.098283168445604, 8.2967751509239278],
					[66.098283190044995, 8.2967751658090538],
					[66.098283235904731, 8.2967751888702264],
					[66.098283261062079, 8.2967751970214607],
					[66.099097161192731, 8.2969362963966073],
					[66.099097181834694, 8.2969362980375259],
					[66.099097223995528, 8.2969362968713547],
					[66.099097246411432, 8.2969362940394547],
					[66.101413945971657, 8.2963945948262925],
					[66.101413988928812, 8.2963945715162293],
					[66.101414061317001, 8.2963945097860012],
					[66.101414088036776, 8.2963944670167376],
					[66.101652987785116, 8.2957333672314508],
					[66.101652992219911, 8.2957333560480429],
					[66.101652996594197, 8.2957333315937003],
					[66.101652999234915, 8.295733320460009],
					[66.101719598866069, 8.295069424034736],
					[66.101875190625591, 8.2939306789381426],
					[66.102652924579317, 8.2929112649720995],
					[66.103452758883961, 8.2925974300076568],
					[66.103627667186501, 8.2927529488945346],
					[66.103627668093651, 8.2927529510815585],
					[66.103627669887743, 8.2927529510316287],
					[66.103627670794907, 8.2927529532186544],
					[66.104333171424656, 8.2933473521751377],
					[66.104333199313743, 8.2933473690982762],
					[66.104333257742582, 8.2933473940215183],
					[66.104333290066293, 8.2933473997596732],
					[66.106111089869486, 8.2934391001071077],
					[66.106111099736879, 8.2934390998327263],
					[66.106111122162829, 8.2934390992091309],
					[66.106111132020132, 8.2934390967225244],
					[66.107422231793123, 8.2932251971606235],
					[66.107422257776832, 8.2932251898000082],
					[66.107422307909744, 8.2932251662794023],
					[66.107422331161885, 8.2932251501443606],
					[66.108919458876898, 8.2919335138173782],
					[66.110074906700632, 8.2921307879235719],
					[66.111005463277891, 8.2930057460046083],
					[66.111005471381603, 8.2930057524178231],
					[66.111005489383075, 8.2930057651943407],
					[66.11100549838379, 8.2930057715826102],
					[66.111916548897909, 8.2935445440961466],
					[66.112780422364295, 8.29521119274645],
					[66.112780429591041, 8.2952112036112986],
					[66.112780445848685, 8.2952112275040069],
					[66.112780454869508, 8.2952112383190766],
					[66.113619255189704, 8.2960890372561416],
					[66.113619384443695, 8.2960890513793508],
					[66.113619547220736, 8.2960889406374836],
					[66.113619581640862, 8.2960888157475043],
					[66.112027982062685, 8.2926249150048612],
					[66.112027973031545, 8.2926249019783107],
					[66.112027953154964, 8.2926248715497195],
					[66.112027942329775, 8.2926248585731237],
					[66.111011245774705, 8.2916054626460323],
					[66.109933459164651, 8.2903942780838626],
					[66.108844567240681, 8.2887442904968527],
					[66.108844559116477, 8.2887442796606354],
					[66.108844541073935, 8.2887442580384274],
					[66.108844531165786, 8.2887442494648802],
					[66.107902967550586, 8.2879304803381828],
					[66.107369592311727, 8.2865582413607441],
					[66.107014094672479, 8.2850110546322107],
					[66.10701409193021, 8.2850110436465112],
					[66.107014083754521, 8.2850110217508632],
					[66.107014079218175, 8.2850110108156496],
					[66.106491879087002, 8.2839610103141759],
					[66.106491873663799, 8.2839610016168876],
					[66.106491862817393, 8.2839609842223147],
					[66.106491857394161, 8.2839609755250461],
					[66.106005788277258, 8.2833471146580369],
					[66.105555796676967, 8.2808332647706884],
					[66.105555793924083, 8.2808332515739203],
					[66.105555785737508, 8.2808332274686993],
					[66.105555781200863, 8.2808332165348606],
					[66.104755780984959, 8.2791110149634175],
					[66.104755770137956, 8.2791109975721202],
					[66.104755744866182, 8.2791109651033779],
					[66.104755731338429, 8.279110950000506],
					[66.10384183086326, 8.278322048226828],
					[66.10384180027107, 8.2783220291849151],
					[66.103841732879829, 8.278322006763462],
					[66.103841697854151, 8.2783219989089485],
					[66.1030695746486, 8.2783303977737948],
					[66.102814097607762, 8.2763999737777318],
					[66.102814093926412, 8.2763999539726747],
					[66.102814080294806, 8.2763999167532418],
					[66.102814072128268, 8.2763998970758728],
					[66.102300172442014, 8.2755415975657147],
					[66.102300160707983, 8.2755415824150571],
					[66.102300137260642, 8.275541556537469],
					[66.102300123742864, 8.2755415436498154],
					[66.101116823557518, 8.2746026426286967],
					[66.101116820856063, 8.2746026404937432],
					[66.10111681455605, 8.2746026362494369],
					[66.101116811854538, 8.2746026341144834],
					[66.100080762795386, 8.2738998699254971],
					[66.099533485937499, 8.2725166271033519],
					[66.099533468758466, 8.2725165988400171],
					[66.099533429049444, 8.2725165491022423],
					[66.099533403838763, 8.2725165299164889],
					[66.09804450407789, 8.27160812902431],
					[66.098044450182826, 8.2716081150860941],
					[66.098044343425315, 8.2716081159334607],
					[66.098044289676253, 8.2716081329562208],
					[66.096966629250076, 8.2723192079311314],
					[66.095230627780325, 8.2725414946867986],
					[66.094214052405917, 8.2721165469434705],
					[66.093425183818923, 8.2702860203697988],
					[66.093425109980089, 8.2702859627813794],
					[66.093424945655642, 8.2702859321153284],
					[66.093424856077547, 8.2702859612230419],
					[66.092810955611384, 8.2709192619781415],
					[66.092163795982884, 8.2715887194762026],
					[66.091988850802323, 8.2716331051381502],
					[66.091988830212259, 8.2716331145730582],
					[66.091988791723125, 8.2716331333657678],
					[66.09198877293754, 8.2716331449602496],
					[66.091361014776311, 8.2721470121011293],
					[66.090975080414864, 8.272208085043669],
					[66.09025575225067, 8.2713637695964053],
					[66.090255673134294, 8.2713637342744111],
					[66.090255516163566, 8.2713637409834089],
					[66.090255439195829, 8.2713637807778841],
					[66.089963851239361, 8.2717580292275237],
					[66.089194509036659, 8.2712581328959622],
					[66.089194472185198, 8.27125811847481],
					[66.089194396750813, 8.2712581029483303],
					[66.089194357281258, 8.2712581040794095],
					[66.088811056657647, 8.2713415048130905],
					[66.088811039634848, 8.2713415097228289],
					[66.088811005610069, 8.2713415239637271],
					[66.088810990402209, 8.2713415332434366],
					[66.088366590023711, 8.2716359332782279],
					[66.088366574826225, 8.2716359447682759],
					[66.088366548040241, 8.271635972066905],
					[66.088366534647278, 8.2716359857162391],
					[66.087955484766454, 8.2722304150933184],
					[66.08738887183938, 8.2723109016978782],
					[66.087388849444721, 8.2723109089712796],
					[66.087388804665864, 8.2723109257286236],
					[66.087388784086116, 8.2723109373718238],
					[66.086938783591762, 8.2726331369495956],
					[66.086938764826883, 8.2726331529620758],
					[66.08693873360761, 8.2726331914390556],
					[66.086938720245783, 8.2726332117186807],
					[66.086513719948243, 8.2735027120127462],
					[66.086513718174984, 8.2735027164849768],
					[66.086513714618007, 8.2735027232189715],
					[66.086513712844734, 8.2735027276912039],
					[66.086058127431755, 8.2746915922470041],
					[66.085227653137835, 8.2755859644842111],
					[66.085227648673396, 8.2755859690327167],
					[66.085227638857816, 8.275585980365685],
					[66.085227635300797, 8.2755859870990438],
					[66.084549849394463, 8.2765776680638563],
					[66.08370266676144, 8.2773359515181859],
					[66.083702662296915, 8.2773359560661124],
					[66.083702654254623, 8.2773359629262533],
					[66.083702648893095, 8.2773359674996634],
					[66.083102648806303, 8.278022068403585],
					[66.083102637227483, 8.2780220864161933],
					[66.083102616760968, 8.2780221223650141],
					[66.083102607883603, 8.2780221425114213],
					[66.082885908123714, 8.2787471420265355],
					[66.082885906370947, 8.2787471509181412],
					[66.082885902865357, 8.2787471687013365],
					[66.08288590111259, 8.278747177592944],
					[66.082724801201337, 8.2802249783185395],
					[66.082724801263154, 8.2802249915795567],
				],
				[
					[66.049202601436988, 8.183244409923681],
					[66.049202600610087, 8.1832444231957044],
					[66.049897000703936, 8.189161122608434],
					[66.049897005281863, 8.1891611401238986],
					[66.049897015323154, 8.1891611729189879],
					[66.049897020786489, 8.1891611881986126],
					[66.051483120636149, 8.1923472893987928],
					[66.051483141417933, 8.1923473174410866],
					[66.051483190111412, 8.1923473644684783],
					[66.051483218920168, 8.1923473834251368],
					[66.052611019413959, 8.1928473829515767],
					[66.052611070590459, 8.1928473901615106],
					[66.05261117190787, 8.1928473781196427],
					[66.05261122115175, 8.1928473588962802],
					[66.053005650224222, 8.1925492253480261],
					[66.052519214606605, 8.193747124253175],
					[66.052519207557339, 8.1937471487628599],
					[66.052519202429011, 8.1937471974983094],
					[66.052519200761878, 8.1937472218376435],
					[66.052758101348886, 8.1959028209076106],
					[66.052758104120514, 8.1959028362753603],
					[66.052758111446252, 8.1959028647466763],
					[66.05275811601193, 8.195902880057778],
					[66.054122012245514, 8.1990972709960328],
					[66.054808099438475, 8.2012361290588025],
					[66.054619200153454, 8.2099082952451177],
					[66.0546192011183, 8.2099083084634632],
					[66.054619203945052, 8.2099083348723472],
					[66.054619205806944, 8.2099083480628696],
					[66.056227604399226, 8.216341740038045],
					[66.057616501784167, 8.2254972297707898],
					[66.057616503622555, 8.2254972385480905],
					[66.057616509093407, 8.2254972560481256],
					[66.057616510942893, 8.2254972670333864],
					[66.059669211257258, 8.2313750660398206],
					[66.059669232064977, 8.2313751007473925],
					[66.059669286183791, 8.2313751587427628],
					[66.059669318597855, 8.2313751820576346],
					[66.059810740786631, 8.2314377268241934],
					[66.059799807189151, 8.2314777477537877],
					[66.059799803666991, 8.2314777611110088],
					[66.0597998011079, 8.2314777876901086],
					[66.059799800276821, 8.2314778009661147],
					[66.059799800405798, 8.2336193997973854],
					[66.059799800416755, 8.2336194020055409],
					[66.059799800438682, 8.2336194064218677],
					[66.059799799552607, 8.2336194086570185],
					[66.060035899858164, 8.2389722090967172],
					[66.060035902636415, 8.2389722266817422],
					[66.06003591088394, 8.2389722617713517],
					[66.060035916342358, 8.2389722770677558],
					[66.061394216033818, 8.2421556784558678],
					[66.061394220562335, 8.2421556871555808],
					[66.061394231424345, 8.2421557067098892],
					[66.061394236849921, 8.2421557153829053],
					[66.062513649270599, 8.2437388902974611],
					[66.061622045067068, 8.2448304734157514],
					[66.061622028163939, 8.2448305026294051],
					[66.061622006051493, 8.2448305673358],
					[66.061621999934346, 8.244830600646841],
					[66.061630399805907, 8.2507221991428459],
					[66.061630399859538, 8.2507222101844722],
					[66.061630401750136, 8.2507222300066019],
					[66.061630403587088, 8.2507222387871124],
					[66.06276650384973, 8.256591738299301],
					[66.062766505686341, 8.2565917470806287],
					[66.062766510246007, 8.2565917624086467],
					[66.06276651296902, 8.2565917689553601],
					[66.064566512592819, 8.2613750700973085],
					[66.064566521658307, 8.2613750897143099],
					[66.064566544253339, 8.2613751244010398],
					[66.064566556885865, 8.261375139496776],
					[66.068169255986362, 8.2650501394938498],
					[66.072210956280827, 8.269255738514687],
					[66.072210965293038, 8.2692557470941335],
					[66.072210983306945, 8.2692557620437626],
					[66.072210993205744, 8.2692557683881844],
					[66.077155493379706, 8.2723723689588784],
					[66.077155520353301, 8.2723723814473953],
					[66.077155575145426, 8.2723723953503647],
					[66.077155603871446, 8.2723723989488605],
					[66.080741670922265, 8.2722974014770845],
					[66.083886038890824, 8.2733057889029009],
					[66.083886104384902, 8.2733057892423414],
					[66.083886219915343, 8.2733057461515589],
					[66.083886269962164, 8.2733057049315946],
					[66.085900170442585, 8.2700473041208138],
					[66.085900171339631, 8.2700473040950691],
					[66.08590017399942, 8.2700472973865029],
					[66.085900174885964, 8.2700472951503201],
					[66.086791875015791, 8.2684222966075325],
					[66.086791888251412, 8.2684222497996096],
					[66.086791888698173, 8.2684221547215468],
					[66.086791877713864, 8.2684221086103147],
					[66.084319620322745, 8.2636443896445524],
					[66.085433481699852, 8.2609389823716963],
					[66.086777978820308, 8.2582306890049573],
					[66.08677798840101, 8.2582306290333598],
					[66.086777971712934, 8.2582305167682204],
					[66.086777946330557, 8.2582304622379894],
					[66.084305745683309, 8.2555998624426277],
					[66.084305712375908, 8.2555998391007286],
					[66.084305636865352, 8.2555998081515138],
					[66.084305595559229, 8.255599800517972],
					[66.082511095759259, 8.2556387002346305],
					[66.082511068858892, 8.255638703232238],
					[66.08251101868899, 8.2556387179630395],
					[66.082510995419412, 8.2556387296961908],
					[66.080719598243903, 8.2567468422552981],
					[66.080711300092887, 8.2551388987124419],
					[66.080711299121234, 8.2551388832688328],
					[66.080711293600444, 8.2551388546966535],
					[66.080711290834699, 8.2551388393055518],
					[66.080039090725549, 8.2530082405806251],
					[66.080039088899397, 8.2530082340035449],
					[66.080039086165542, 8.253008225242862],
					[66.080039084350091, 8.2530082208756994],
					[66.079583484064131, 8.2519471214117175],
					[66.079583446163923, 8.2519470761119429],
					[66.079583351675751, 8.2519470170046549],
					[66.079583295974075, 8.2519470009608842],
					[66.078688967283512, 8.2519636996794166],
					[66.07801954598709, 8.2514415598953832],
					[66.076889165717887, 8.2498583278003093],
					[66.078683434890351, 8.2482112459906283],
					[66.078683455414478, 8.2482112232829543],
					[66.078683484758301, 8.2482111693729845],
					[66.078683496279865, 8.2482111403009721],
					[66.079125196111534, 8.2460583399330343],
					[66.079125197819266, 8.246058322201133],
					[66.079125199429882, 8.2460582845806876],
					[66.07912519665247, 8.24605826698172],
					[66.078675196854377, 8.2433860679887836],
					[66.078675184122673, 8.2433860330054536],
					[66.078675147030395, 8.2433859700149892],
					[66.078675121772719, 8.2433859420345144],
					[66.077994522153119, 8.2428637413557286],
					[66.077994462840735, 8.2428637210201128],
					[66.077994344400153, 8.2428637179144904],
					[66.077994284385795, 8.2428637373808904],
					[66.076430494410218, 8.2439720310632101],
					[66.074408202889288, 8.2450887248768012],
					[66.074408181436169, 8.2450887409814708],
					[66.074408142161232, 8.2450887819220853],
					[66.074408125225659, 8.2450888045220001],
					[66.073516703431423, 8.2467134779611264],
					[66.072394576953812, 8.2445943065209253],
					[66.072394575138134, 8.2445943021556509],
					[66.072394569723514, 8.2445942956876515],
					[66.072394567907835, 8.2445942913223789],
					[66.071036268745615, 8.2424804924030965],
					[66.070366882836566, 8.2414221146217042],
					[66.069686289897419, 8.2392943387252302],
					[66.069686282634095, 8.2392943212675736],
					[66.069686262725227, 8.2392942865130951],
					[66.069686251873733, 8.2392942691626558],
					[66.069236271797095, 8.2387692944710178],
					[66.068566888753892, 8.2371749328107526],
					[66.068108492276508, 8.2355777450045196],
					[66.068108491368548, 8.2355777428225796],
					[66.068108488644611, 8.2355777362767491],
					[66.068108488633683, 8.235577734067876],
					[66.066977988634704, 8.2323888339631495],
					[66.066977986818685, 8.2323888295996763],
					[66.066977985002637, 8.2323888252362227],
					[66.066977984094635, 8.2323888230544977],
					[66.066077984090299, 8.2302638219406035],
					[66.066077966914534, 8.2302637959515454],
					[66.066077923603757, 8.2302637464533532],
					[66.066077899273793, 8.2302637250986681],
					[66.065222061842547, 8.2297750422546478],
					[66.066414080270107, 8.2273194878386189],
					[66.066414084699915, 8.2273194766588471],
					[66.066414092651556, 8.2273194521178041],
					[66.066414096184374, 8.2273194409652586],
					[66.066636295643633, 8.2262278405525642],
					[66.066636298279363, 8.2262278294270335],
					[66.066636299973752, 8.2262278094938033],
					[66.0666362999184, 8.2262277984500685],
					[66.066611300309148, 8.2224360985309755],
					[66.066611299367636, 8.2224360897234039],
					[66.066611298370432, 8.2224360698721384],
					[66.066611295634843, 8.2224360611193639],
					[66.065002995943814, 8.2143666609844352],
					[66.065002995943814, 8.2143666609844352],
					[66.065002995932574, 8.214366658775841],
					[66.065002995932574, 8.214366658775841],
					[66.064080799351714, 8.2100693726127325],
					[66.063600199684657, 8.2030416869128207],
					[66.062880799757252, 8.1927749917007038],
					[66.062858500294411, 8.1884416992428566],
					[66.06285845319637, 8.1884416146021231],
					[66.062858310070681, 8.1884415241871356],
					[66.062858213157597, 8.1884415206498744],
					[66.060386024105327, 8.1896276144841913],
					[66.058594335094625, 8.1902442106208522],
					[66.058594310047553, 8.1902442246676621],
					[66.058594263564657, 8.1902442570632736],
					[66.058594243922869, 8.1902442753550009],
					[66.057694301033649, 8.1913665050125388],
					[66.054388844155369, 8.1915037258262426],
					[66.054864019199528, 8.1911445607833056],
					[66.058022316643275, 8.1888695625171373],
					[66.058022319322703, 8.1888695602234485],
					[66.058022322002188, 8.1888695579297437],
					[66.058022323796223, 8.1888695578725983],
					[66.061630723825857, 8.1860362580202537],
					[66.061630747008579, 8.1860362307752208],
					[66.061630782586562, 8.1860361722126633],
					[66.061630795878798, 8.186036140866479],
					[66.062072395703581, 8.18385834027759],
					[66.062072398324574, 8.1838583269413743],
					[66.062072399058067, 8.1838582959960462],
					[66.062072398987979, 8.1838582827460833],
					[66.061844599393211, 8.1811638819666719],
					[66.061844599393211, 8.1811638819666719],
					[66.061844599381502, 8.1811638797583708],
					[66.061844599381502, 8.1811638797583708],
					[66.06115019957214, 8.1747027855949774],
					[66.060914099574816, 8.1666027945968231],
					[66.060914091286989, 8.1666027551131481],
					[66.060914057703798, 8.166602683328243],
					[66.060914035099529, 8.1666026509389482],
					[66.059775135335926, 8.1655637511480208],
					[66.059775059831338, 8.1655637249115731],
					[66.05977491455053, 8.1655637362977256],
					[66.059774843877321, 8.1655637739497209],
					[66.05797484406915, 8.167794274321901],
					[66.057974836952567, 8.1677942855962034],
					[66.057974823604482, 8.1677943059075684],
					[66.057974818281949, 8.1677943171232634],
					[66.055733118336221, 8.1727388162201393],
					[66.055733113025141, 8.1727388296415757],
					[66.055733105967349, 8.1727388519523867],
					[66.055733103335513, 8.1727388630786511],
					[66.054838705328706, 8.1776360546506712],
					[66.053719297961393, 8.1814607358798543],
					[66.051452934784123, 8.1793804536095269],
					[66.051452929366789, 8.1793804471606997],
					[66.051452914955718, 8.1793804365860101],
					[66.05145290865309, 8.1793804323734811],
					[66.049872309191855, 8.1783581309354894],
					[66.049872213163638, 8.1783581252018891],
					[66.049872059228903, 8.1783581963957808],
					[66.049872002219345, 8.1783582732943714],
					[66.049202602135324, 8.1832443723723323],
					[66.049202601308451, 8.1832443856443522],
					[66.049202601436988, 8.183244409923681],
				],
				[
					[66.017958100780689, 8.032791689046503],
					[66.017958100932887, 8.0327917132951061],
					[66.017958101899055, 8.0327917242831965],
					[66.018005401789196, 8.0331722249168109],
					[66.018005401803009, 8.0331722271212289],
					[66.018005401830678, 8.0331722315300862],
					[66.01800540274148, 8.0331722337005171],
					[66.018058102300756, 8.0334861290744417],
					[66.018149800988965, 8.0343083221412908],
					[66.018149800988965, 8.0343083221412908],
					[66.018149801002792, 8.034308324345723],
					[66.018227598570661, 8.0349583030903471],
					[66.018197000926136, 8.0353055822135033],
					[66.018197000939935, 8.0353055844179408],
					[66.018197000084371, 8.0353055910651872],
					[66.018197000084371, 8.0353055910651872],
					[66.018180399817794, 8.0357249915333728],
					[66.018180399845377, 8.0357249959422461],
					[66.018180399900544, 8.0357250047600086],
					[66.018180399928141, 8.0357250091688801],
					[66.018219199732272, 8.0365111096362742],
					[66.018219208922716, 8.036511144568637],
					[66.018219238053618, 8.0365112118224324],
					[66.018219258877352, 8.0365112419055649],
					[66.018380458594507, 8.0366723402807558],
					[66.018380489229955, 8.0366723611738315],
					[66.018380556710852, 8.036672391700618],
					[66.018380592645542, 8.0366723991637237],
					[66.018616649388392, 8.0366807976893302],
					[66.018722110924173, 8.0367334800144636],
					[66.018722128008278, 8.0367334859844597],
					[66.018722163059792, 8.0367334956861143],
					[66.018722181027101, 8.0367334994177604],
					[66.018988843641139, 8.0367584957568265],
					[66.019066549017595, 8.0367973474451642],
					[66.01917201888682, 8.0370194864370514],
					[66.019172020694583, 8.0370194885738595],
					[66.019172023413134, 8.0370194928813543],
					[66.019172024323851, 8.0370194950520197],
					[66.019280423973029, 8.0372194946005635],
					[66.019280451986319, 8.0372195266192552],
					[66.019280517797057, 8.0372195770570549],
					[66.019280555594577, 8.0372195954761878],
					[66.019516582703062, 8.0372723797691084],
					[66.019583135278054, 8.0373640143111054],
					[66.019730417180256, 8.0375889863710253],
					[66.019822006668917, 8.0378889510902543],
					[66.019897005342344, 8.0382028468407292],
					[66.019897006253103, 8.0382028490114976],
					[66.01989700717759, 8.0382028533868546],
					[66.019897008088364, 8.038202855557623],
					[66.020027607825142, 8.0386472558889093],
					[66.020027607838898, 8.0386472580935067],
					[66.020027608735916, 8.0386472580597061],
					[66.020185904440197, 8.0391667408979952],
					[66.020224801005099, 8.0394667202537917],
					[66.02027200064984, 8.040016717668605],
					[66.02027200342323, 8.0400167307950809],
					[66.020272008059237, 8.0400167548771684],
					[66.020272011729588, 8.0400167679698917],
					[66.020369212244788, 8.0402889677798424],
					[66.020369214066207, 8.040288972121612],
					[66.020369217722816, 8.0402889830097966],
					[66.020369220441253, 8.0402889873178207],
					[66.020538719911031, 8.0406361888817646],
					[66.020538720821747, 8.0406361910526769],
					[66.020538724437145, 8.0406361953270356],
					[66.02053872534789, 8.0406361974979443],
					[66.020652625228763, 8.0408417959023275],
					[66.020652639717824, 8.0408418174092784],
					[66.0206526749337, 8.0408418535731698],
					[66.02065269477724, 8.0408418704684905],
					[66.020827694734507, 8.0409501700646207],
					[66.020827728902418, 8.0409501820112421],
					[66.020827798977436, 8.0409501970183701],
					[66.020827834870772, 8.0409501978741886],
					[66.021094346070825, 8.0409030119825626],
					[66.021216563297372, 8.0410001461222045],
					[66.021369253260175, 8.0411668355561314],
					[66.021369316255232, 8.0411668662669022],
					[66.021369448236243, 8.0411668811533286],
					[66.021369517194756, 8.0411668609195743],
					[66.02143901724385, 8.0411168624655467],
					[66.021439034190635, 8.0411168463918692],
					[66.021439065365811, 8.0411168099362005],
					[66.021439077813938, 8.0411167918263846],
					[66.021544492719059, 8.0409113578562614],
					[66.02166658601989, 8.0409640690199424],
					[66.021888751822246, 8.0411834360098897],
					[66.022010916356166, 8.041330691669863],
					[66.022094203286059, 8.0416861362841043],
					[66.022155401805549, 8.0421528255274861],
					[66.022155410967287, 8.0421528560577045],
					[66.022155440040962, 8.0421529145091473],
					[66.022155459042182, 8.0421529402593066],
					[66.022347056100585, 8.0423445399323175],
					[66.022452636420979, 8.0424584179050989],
					[66.022574822879605, 8.0426889930905165],
					[66.02257482922704, 8.0426890038789978],
					[66.022574840997464, 8.0426890210799815],
					[66.022574847331185, 8.042689029663622],
					[66.022797046625527, 8.0429529288145272],
					[66.02279704933018, 8.0429529309184264],
					[66.022797052034861, 8.0429529330223044],
					[66.022797052945563, 8.0429529351934885],
					[66.022902634596093, 8.0430668154105067],
					[66.023124823304684, 8.0434944912821749],
					[66.023124825139718, 8.0434944978295295],
					[66.023124832384042, 8.0434945085848071],
					[66.023124835102394, 8.0434945128936359],
					[66.023269234603717, 8.0437057122887499],
					[66.023269253577311, 8.043705733631386],
					[66.02326929776244, 8.0437057694666354],
					[66.023269322076956, 8.0437057839928912],
					[66.023466621612755, 8.043789084829827],
					[66.023466629699342, 8.0437890867320956],
					[66.023466644989213, 8.0437890927751745],
					[66.023466652178854, 8.043789094711066],
					[66.023613851713748, 8.0438251929989502],
					[66.023613861608013, 8.0438251970389025],
					[66.023613881355487, 8.0438251985040718],
					[66.023613891236067, 8.0438252003391124],
					[66.023924873990183, 8.043839095084925],
					[66.024038716112202, 8.0440667784110484],
					[66.024116505005424, 8.0442833470904986],
					[66.024155402342117, 8.0445583281259836],
					[66.024155403280091, 8.0445583347072809],
					[66.024155406053097, 8.0445583478362472],
					[66.024155406977414, 8.0445583522125652],
					[66.024230406638793, 8.0448306522249702],
					[66.024230413013399, 8.0448306674246108],
					[66.024230425762582, 8.0448306978239135],
					[66.024230433020477, 8.0448307107849999],
					[66.024383127178794, 8.0450612010266038],
					[66.024497011641728, 8.045286170652922],
					[66.024610903732551, 8.045730641211172],
					[66.02467200185373, 8.0460722286298925],
					[66.024719201434735, 8.0464944219697667],
					[66.024719206056119, 8.0464944438522235],
					[66.024719218859531, 8.0464944830729728],
					[66.02471922795219, 8.0464945025827586],
					[66.024863726830091, 8.0467390009426953],
					[66.025069219549977, 8.0471056867245778],
					[66.025252610787405, 8.0475500655859644],
					[66.025299808260925, 8.0477111558597283],
					[66.02529981555945, 8.0477111754373745],
					[66.025299834614273, 8.0477112100150734],
					[66.025299846384144, 8.0477112272201872],
					[66.025405429446991, 8.0478390067615013],
					[66.025519210402493, 8.0480917654825266],
					[66.02563590607042, 8.0485750462449062],
					[66.025635947713127, 8.0485751064482898],
					[66.025636061224091, 8.0485751816155471],
					[66.025636133092277, 8.0485751965794385],
					[66.025719432888337, 8.0485612971787077],
					[66.025719490118846, 8.0485612663708981],
					[66.025719572994589, 8.0485611750550259],
					[66.025719598639853, 8.0485611145469633],
					[66.025772398708668, 8.0478750148268841],
					[66.025772400448176, 8.0478750059395132],
					[66.025772399442189, 8.0478749883321594],
					[66.025772399387705, 8.0478749795117608],
					[66.025733498985957, 8.0474971794875376],
					[66.025733496199592, 8.0474971641523396],
					[66.025733487949509, 8.047497135787518],
					[66.025733483369166, 8.0474971205193189],
					[66.025514082788291, 8.0469916189864215],
					[66.025514043931352, 8.0469915741209235],
					[66.02551394757154, 8.046991515964903],
					[66.025513890055038, 8.0469915004693053],
					[66.025405741623274, 8.0469969927048517],
					[66.025291903432844, 8.046649978177193],
					[66.025314091372238, 8.0464444915150217],
					[66.025405571421416, 8.046353112839391],
					[66.0255137215524, 8.0465111932038429],
					[66.025619212943056, 8.0467861717862874],
					[66.025619248212067, 8.0467862167857511],
					[66.025619337436922, 8.0467862818246783],
					[66.025619391379138, 8.0467862996590753],
					[66.025741593907924, 8.0467918959630751],
					[66.025872002989757, 8.0469750689452777],
					[66.025885900064111, 8.0472500031919196],
					[66.025877599756498, 8.0475916949057353],
					[66.025877600776155, 8.0475917147182585],
					[66.025877607273088, 8.0475917497655889],
					[66.025877611867074, 8.047591767239],
					[66.025969211661916, 8.0478472676857677],
					[66.025969214393768, 8.0478472742006772],
					[66.025969219857444, 8.0478472872304767],
					[66.025969222589268, 8.047847293745388],
					[66.026077616992566, 8.0480528820013433],
					[66.026199812075362, 8.0483917670908998],
					[66.026199816641977, 8.0483917801544038],
					[66.026199830246625, 8.0483918039089311],
					[66.02619983840124, 8.04839181683856],
					[66.026358137812664, 8.0486112166248631],
					[66.026358172088891, 8.0486112462255157],
					[66.026358248605362, 8.0486112874844657],
					[66.026358291742639, 8.0486112991092824],
					[66.026649984601235, 8.0486223998029196],
					[66.026869302774159, 8.0486473900784059],
					[66.027122051813222, 8.0489251343733965],
					[66.027122067143708, 8.0489251470360763],
					[66.027122098688096, 8.0489251701227289],
					[66.027122115812574, 8.048925182718488],
					[66.027266554333551, 8.048991853401219],
					[66.027397022582363, 8.0492417922959305],
					[66.027397061411435, 8.0492418327576516],
					[66.027397154155224, 8.049241886649698],
					[66.027397208069928, 8.0492419000800037],
					[66.027663820011497, 8.0492308047910957],
					[66.027877657623137, 8.0494473404756466],
					[66.027877678349157, 8.0494473551436752],
					[66.027877721581532, 8.0494473822075818],
					[66.027877745868295, 8.0494473923313095],
					[66.028113762924562, 8.0495140693047862],
					[66.028222022947247, 8.0497194926936011],
					[66.028222026575989, 8.0497194991758612],
					[66.028222035641065, 8.0497195142788254],
					[66.028222041063827, 8.0497195206942376],
					[66.028419240819233, 8.0499779209738627],
					[66.028419305660876, 8.0499779604692776],
					[66.028419443947101, 8.0499779795819233],
					[66.028419517405254, 8.0499779614044815],
					[66.028725011903504, 8.0497585387393329],
					[66.028960999592755, 8.0498945727180367],
					[66.028961008603417, 8.0498945789999947],
					[66.028961028391606, 8.0498945870862926],
					[66.028961038272129, 8.0498945889240527],
					[66.029122092053427, 8.0499473757016418],
					[66.029227659101835, 8.050052941886344],
					[66.029227684339915, 8.0500529607993609],
					[66.029227738336076, 8.0500529874647579],
					[66.029227767107741, 8.0500529974225188],
					[66.029427675807, 8.050086282883246],
					[66.029677622159411, 8.0505028922855555],
					[66.029922011260354, 8.0510556661299866],
					[66.030030407324205, 8.0514500519453378],
					[66.030030409158911, 8.0514500584950142],
					[66.030030411904164, 8.0514500672168179],
					[66.030030412828324, 8.05145007159439],
					[66.030158112598102, 8.0517806715103202],
					[66.030158137944468, 8.0517807080696251],
					[66.030158202024325, 8.0517807696604997],
					[66.030158240730628, 8.0517807902810876],
					[66.03037214109159, 8.05184739047343],
					[66.030372204778018, 8.0518473881053616],
					[66.030372320259346, 8.0518473463092572],
					[66.030372370260281, 8.0518473069479217],
					[66.030586218254186, 8.0515029891807046],
					[66.03080829322046, 8.0514808017841446],
					[66.030936065930121, 8.051502998013726],
					[66.030936090148884, 8.0515029971128413],
					[66.030936136792448, 8.0515029953778079],
					[66.030936160984112, 8.0515029900658277],
					[66.031144461048171, 8.0514362909668691],
					[66.03114450483794, 8.0514362628649607],
					[66.03114457166447, 8.0514361875784868],
					[66.031144594714831, 8.051436142599508],
					[66.031197395365695, 8.0511917412368224],
					[66.031197396262698, 8.0511917412034446],
					[66.031197396249127, 8.0511917389978755],
					[66.031197396249127, 8.0511917389978755],
					[66.03128909244225, 8.0507167624169984],
					[66.031533480663597, 8.0501889856080258],
					[66.031830780412307, 8.0495667850352532],
					[66.031830783945878, 8.0495667760790113],
					[66.031830791013007, 8.0495667581664883],
					[66.03183079363599, 8.049566747038039],
					[66.031914093630405, 8.0492333471539066],
					[66.031914093630405, 8.0492333471539066],
					[66.03191409539717, 8.0492333426757483],
					[66.031914096280573, 8.0492333404366683],
					[66.032027996019622, 8.0486861414544091],
					[66.032027997718316, 8.0486861259479685],
					[66.032027999321727, 8.0486860950020169],
					[66.032027999212872, 8.0486860773568445],
					[66.031983498664658, 8.048280577435996],
					[66.031983496829824, 8.0482805708860248],
					[66.031983494029944, 8.0482805533413515],
					[66.03198349218151, 8.0482805445857437],
					[66.031730792225048, 8.0474082449924325],
					[66.03173079131443, 8.0474082428203086],
					[66.031730790376557, 8.0474082362369774],
					[66.031730788568964, 8.0474082340983824],
					[66.031508489728637, 8.0467666371345636],
					[66.031089093394129, 8.0454027460549611],
					[66.030716894654844, 8.0439027533867637],
					[66.03031129529225, 8.0421166553378516],
					[66.03031129529225, 8.0421166553378516],
					[66.030311294367877, 8.0421166509605495],
					[66.030311294367877, 8.0421166509605495],
					[66.029961293689155, 8.0407221512630151],
					[66.029961293675427, 8.040722149057558],
					[66.029961292764739, 8.0407221468858232],
					[66.029961292764739, 8.0407221468858232],
					[66.029433492617173, 8.0388471457017427],
					[66.029433490795697, 8.0388471413585396],
					[66.029433488960478, 8.0388471348099042],
					[66.029433488035963, 8.0388471304328917],
					[66.028939088176003, 8.0374943319758447],
					[66.028939085443739, 8.03749432546131],
					[66.028939079992938, 8.0374943146375895],
					[66.028939078157649, 8.0374943080891796],
					[66.028375178085867, 8.0364027087478451],
					[66.028375175353531, 8.0364027022335733],
					[66.028375169019455, 8.0364026936495367],
					[66.028375166287177, 8.0364026871352614],
					[66.027833466276704, 8.0355970868972708],
					[66.027833462674948, 8.0355970848276836],
					[66.027833456340844, 8.0355970762440254],
					[66.027833452725318, 8.0355970719691676],
					[66.0272362533169, 8.0348859717447052],
					[66.027236247893555, 8.0348859653327196],
					[66.027236237957666, 8.03488595468],
					[66.027236233445109, 8.034885950439266],
					[66.026750133001968, 8.0344554498258844],
					[66.026750126695404, 8.0344554456532489],
					[66.026750115876325, 8.0344554372400321],
					[66.026750109569761, 8.0344554330673734],
					[66.025994509857412, 8.0339554331600294],
					[66.025994505344855, 8.0339554289196844],
					[66.025994495450362, 8.0339554248831941],
					[66.025994490054586, 8.0339554228819168],
					[66.025375093078807, 8.0336415238219185],
					[66.024758395630158, 8.0333081244821791],
					[66.024077897442353, 8.0329331255068634],
					[66.024077883035218, 8.0329331172312024],
					[66.02407785426243, 8.0329331072947276],
					[66.02407783898596, 8.0329331034629838],
					[66.023438968040551, 8.0328054091567633],
					[66.022627896716301, 8.032369224026672],
					[66.02191121608459, 8.0319609361214983],
					[66.021422346011832, 8.0315387630405368],
					[66.021155763103494, 8.0311915848687363],
					[66.020783466742387, 8.0306248900390731],
					[66.020783465845412, 8.0306248900731543],
					[66.020783464934553, 8.030624887902583],
					[66.020783464023708, 8.0306248857320099],
					[66.020477964331349, 8.0301859861710838],
					[66.020477963420475, 8.0301859840005534],
					[66.020477958907776, 8.0301859797617663],
					[66.020477957996945, 8.0301859775912341],
					[66.020080758430623, 8.0296776766568527],
					[66.019591858128734, 8.0290526773450388],
					[66.019225166705198, 8.0285776883606275],
					[66.018989073361269, 8.0281665989320672],
					[66.018989067937667, 8.028166592523549],
					[66.018989057062612, 8.0281665752975346],
					[66.01898904984499, 8.0281665689573529],
					[66.018539049691412, 8.027652667632287],
					[66.018538949903288, 8.0276526361553842],
					[66.018538777035204, 8.0276526824334713],
					[66.018538704838292, 8.0276527579498147],
					[66.01838040527366, 8.028374957842642],
					[66.018380402652184, 8.0283749689673822],
					[66.018380400141936, 8.0283749977277292],
					[66.01838040022534, 8.0283750109544396],
					[66.018394200066979, 8.0286528093411089],
					[66.018394200991764, 8.0286528137158584],
					[66.018394201047357, 8.0286528225336777],
					[66.018394201972157, 8.0286528269084272],
					[66.018455401175231, 8.0290889256740225],
					[66.018494199674635, 8.0294528117823756],
					[66.018499799915887, 8.0298861000048252],
					[66.018494200326614, 8.0302499952298092],
					[66.018463701178518, 8.0310888799703548],
					[66.018385905880848, 8.0315416459547126],
					[66.01824152244177, 8.0319027062081769],
					[66.018019233393701, 8.0322387893725367],
					[66.018019222768288, 8.0322388118250494],
					[66.01801920688554, 8.0322388543214984],
					[66.0180192007589, 8.0322388788083181],
					[66.01795810071151, 8.0327916780244095],
					[66.017958100780689, 8.032791689046503],
				],
			],
		},
		{
			fldNpdidField: "43785",
			wlbNpdidWellbore: "149",
			fldName: "COD",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "7/11-1",
			fldDiscoveryYear: "1968",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43785",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43785",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "018",
			cmpLongName: "ConocoPhillips Skandinavia AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=149",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "2410696",
			fldNpdidOwner: "20900",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[57.050860900178108, 2.4360610976650361],
					[57.050860901125979, 2.4360611156775924],
					[57.05086090739762, 2.4360611492746185],
					[57.050860911022745, 2.4360611668128227],
					[57.053663711358027, 2.4440139660045306],
					[57.053663719530284, 2.4440139843928388],
					[57.053663742207824, 2.444014021701542],
					[57.05366375475613, 2.444014037662495],
					[57.05816925461162, 2.4487779378738521],
					[57.058169275277308, 2.4487779540554646],
					[57.058169318135548, 2.4487779811893811],
					[57.058169341134501, 2.4487779903459659],
					[57.063177740574361, 2.4503168905195944],
					[57.063177764207687, 2.4503168946060163],
					[57.063177809431494, 2.4503168981802652],
					[57.063177831914395, 2.4503168975102598],
					[57.067966731606539, 2.4495390967131052],
					[57.06796676615344, 2.4495390856402626],
					[57.067966828140136, 2.4495390482168449],
					[57.067966855751919, 2.4495390251428582],
					[57.070866855581137, 2.4459362250208065],
					[57.070866859064786, 2.4459362227503938],
					[57.070866864075114, 2.4459362152487185],
					[57.070866865687847, 2.4459362116558729],
					[57.073283466264868, 2.4423195115679879],
					[57.073283471995154, 2.4423195006302034],
					[57.073283482563298, 2.4423194789127223],
					[57.07328348820748, 2.4423194663364267],
					[57.074264088192201, 2.4395833662136752],
					[57.074264089890882, 2.439583364258787],
					[57.074264092137398, 2.4395833555915303],
					[57.074264092857511, 2.4395833521562564],
					[57.075250183279842, 2.4359250876904737],
					[57.078872361038044, 2.4309723176673295],
					[57.078872371776598, 2.4309722992208145],
					[57.078872388705442, 2.4309722614810991],
					[57.078872394895747, 2.4309722421878992],
					[57.080355794876738, 2.424108341719085],
					[57.080355795769137, 2.4241083415605025],
					[57.080355795596425, 2.4241083382829629],
					[57.08035579729485, 2.424108336327039],
					[57.081108496860558, 2.4199861353524939],
					[57.081108498040464, 2.4199861235636364],
					[57.08110850022743, 2.4199860967083473],
					[57.081108498643765, 2.4199860837567515],
					[57.080669599830053, 2.4144416924557222],
					[57.080711298149623, 2.4093752068198917],
					[57.081663893161746, 2.4094085004684884],
					[57.08166397493958, 2.409408462744056],
					[57.081664075418288, 2.4094083356709],
					[57.081664093313314, 2.4094082481198913],
					[57.080272393241266, 2.4042888472832509],
					[57.080272392175658, 2.4042888441649266],
					[57.080272389065541, 2.404288836448615],
					[57.080272387913354, 2.4042888316915767],
					[57.078400188246, 2.3991471315548165],
					[57.0784001762377, 2.3991471088859075],
					[57.078400147040398, 2.399147067780258],
					[57.078400129764731, 2.3991470477049499],
					[57.073894529741686, 2.3952887477588001],
					[57.073894501844151, 2.3952887312368167],
					[57.073894440236401, 2.3952887074993869],
					[57.073894408310821, 2.3952886999654965],
					[57.065777807923041, 2.3949775992273454],
					[57.065777773121923, 2.3949776054358192],
					[57.065777706630271, 2.3949776255653137],
					[57.065777676005538, 2.3949776426032177],
					[57.06241097576833, 2.3976220434258879],
					[57.062410967997302, 2.3976220497715262],
					[57.062410951736268, 2.3976220658976604],
					[57.062410944051919, 2.3976220738811818],
					[57.061685943554025, 2.3985192734444896],
					[57.061685924391242, 2.3985193198467387],
					[57.061685907022934, 2.3985194171816704],
					[57.061685912386771, 2.3985194674780033],
					[57.062855384408998, 2.401797191228547],
					[57.061397046069189, 2.4049776553466833],
					[57.058997104832933, 2.4062776244787725],
					[57.058997061400277, 2.4062776718879726],
					[57.058997010548929, 2.4062777834387861],
					[57.058997005893829, 2.4062778487415253],
					[57.059691499547867, 2.4090750233184615],
					[57.059655405244001, 2.4136965701316417],
					[57.058002964432077, 2.4113248866430865],
					[57.058002880199112, 2.411324843768734],
					[57.05800271364064, 2.4113248452963725],
					[57.058002632294006, 2.4113248911773426],
					[57.056549832322624, 2.4135859921636622],
					[57.056549823284762, 2.4135860086483811],
					[57.056549808864986, 2.4135860426207381],
					[57.056549804375472, 2.4135860599496697],
					[57.055802727593559, 2.4172576513867354],
					[57.053691881612735, 2.4125582176251759],
					[57.053691780366293, 2.4125581595957013],
					[57.053691591384322, 2.4125581799802771],
					[57.053691503648828, 2.4125582583943044],
					[57.052444203740237, 2.4185305585841692],
					[57.052444203999443, 2.4185305634967089],
					[57.052444201667903, 2.4185305705224192],
					[57.052444201840714, 2.4185305737974461],
					[57.050913701873291, 2.4300471735549274],
					[57.050913701325889, 2.4300471802631418],
					[57.050913700144811, 2.4300471920420734],
					[57.050913699597409, 2.430047198750287],
					[57.050860900178108, 2.4360610976650361],
				],
			],
		},
		{
			fldNpdidField: "43583",
			wlbNpdidWellbore: "356",
			fldName: "LILLE-FRIGG",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "25/2-4",
			fldDiscoveryYear: "1975",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43583",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43583",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "873",
			cmpLongName: "Aker BP ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=356",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "28544099",
			fldNpdidOwner: "28973585",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[59.929791499588553, 2.3778389020856054],
					[59.929791499857402, 2.3778389074166517],
					[59.92990539993172, 2.3807833079838852],
					[59.929905401361047, 2.3807833184676017],
					[59.929905405290775, 2.3807833428106342],
					[59.929905406720103, 2.3807833532943503],
					[59.930785906913222, 2.3839472546080906],
					[59.930785911018042, 2.3839472645571043],
					[59.930785919227731, 2.3839472844550946],
					[59.93078592422448, 2.3839472942257092],
					[59.931994223694218, 2.3862000943873793],
					[59.931994249655538, 2.3862001268928332],
					[59.93199431522239, 2.386200176610318],
					[59.931994352152145, 2.3862001943573632],
					[59.934624904751729, 2.3868529825990197],
					[59.937472036140228, 2.3893223230604703],
					[59.939230408410133, 2.3931694616150203],
					[59.940230403581786, 2.3981639390013316],
					[59.940230410005576, 2.398163959264648],
					[59.940230428204799, 2.3981639987228904],
					[59.940230438196359, 2.3981640182739352],
					[59.941880438215449, 2.4004279170784044],
					[59.941880476839437, 2.4004279506676482],
					[59.941880566216099, 2.4004279902874952],
					[59.941880616166166, 2.4004279982738543],
					[59.945052816426731, 2.4001807995175088],
					[59.945052860666571, 2.4001807835034406],
					[59.945052940225523, 2.4001807352986693],
					[59.945052972690142, 2.4001807000862798],
					[59.946902941061524, 2.3969779551003771],
					[59.949525042518815, 2.3958168992749043],
					[59.952586101858671, 2.3957918991777563],
					[59.952586128706059, 2.3957918956094426],
					[59.952586181953919, 2.3957918795813247],
					[59.95258620665988, 2.3957918692562772],
					[59.954769458636711, 2.3944030006518879],
					[59.958272202149146, 2.3943752005984398],
					[59.958272204824958, 2.3943752000635383],
					[59.958272212049806, 2.394375200415741],
					[59.958272215617534, 2.3943751997025422],
					[59.961444416306705, 2.3941195988612027],
					[59.961444430667008, 2.394119597786704],
					[59.961444460011265, 2.3941195901230907],
					[59.961444475084626, 2.3941195853127413],
					[59.963191774698018, 2.393416886717838],
					[59.963191793070784, 2.3934168758568415],
					[59.963191827051105, 2.3934168528910762],
					[59.963191842658645, 2.3934168407863097],
					[59.965694503067397, 2.3908723795901432],
					[59.969519425859268, 2.3899169005137693],
					[59.972583301673147, 2.3898862992226699],
					[59.972583305330318, 2.3898863002879236],
					[59.972583311663243, 2.3898863008176283],
					[59.972583316033351, 2.3898862981456555],
					[59.975972153102482, 2.3896224030961757],
					[59.978274897714059, 2.3909807725857117],
					[59.978274898605981, 2.3909807724071777],
					[59.978274898605981, 2.3909807724071777],
					[59.97827490038982, 2.3909807720501104],
					[59.981019279205789, 2.3925695608111659],
					[59.984097060656538, 2.3955418440898639],
					[59.984097071004278, 2.3955418528067924],
					[59.98409708973707, 2.3955418670375934],
					[59.984097100887311, 2.3955418737960894],
					[59.988485965276332, 2.3980501522203057],
					[59.989699840154003, 2.3996584205827678],
					[59.989699857281266, 2.3996584387354973],
					[59.989699894835475, 2.3996584689865288],
					[59.989699916154322, 2.3996584809064343],
					[59.991672116155556, 2.4005723816627782],
					[59.991672142736604, 2.400572390733279],
					[59.991672198038344, 2.400572397656672],
					[59.991672225956471, 2.4005723974683786],
					[59.993533326080374, 2.4003279975736973],
					[59.993533390921911, 2.400327961226064],
					[59.993533476628592, 2.400327855963476],
					[59.993533499188267, 2.4003277849112346],
					[59.993058499324768, 2.393849984162316],
					[59.993058492541998, 2.3938499567463145],
					[59.993058472733004, 2.3938499031642508],
					[59.993058457922892, 2.3938498773553221],
					[59.989864058175172, 2.3897165779016647],
					[59.989864057193742, 2.389716576300029],
					[59.98986405523091, 2.3897165730967864],
					[59.989864054249495, 2.3897165714951663],
					[59.986450153653735, 2.3855915724037264],
					[59.986450151690811, 2.3855915692010519],
					[59.986450146962582, 2.3855915647545061],
					[59.986450144107728, 2.385591561730573],
					[59.983150144052559, 2.3821692607900204],
					[59.983150140216132, 2.3821692561655783],
					[59.983150130849097, 2.3821692490541975],
					[59.983150126120755, 2.3821692446085811],
					[59.980080733409807, 2.3796720503671547],
					[59.976125156421055, 2.3758109731688628],
					[59.97392236794213, 2.372391591708177],
					[59.973922335733747, 2.3723915604277885],
					[59.973922263205566, 2.3723915156712243],
					[59.973922221012288, 2.3723915007736953],
					[59.970097232127046, 2.3719942013286461],
					[59.967138943303404, 2.3713498044462349],
					[59.96713892885375, 2.3713498037519494],
					[59.967138899864679, 2.3713498005844191],
					[59.967138886306891, 2.3713497997111781],
					[59.962883302173928, 2.3716469992242817],
					[59.958727826051387, 2.3712554018170713],
					[59.955008367237468, 2.3706276081931343],
					[59.951836198309778, 2.368852625531892],
					[59.951836178060049, 2.3688526170214281],
					[59.951836137829851, 2.3688526053349701],
					[59.951836116867746, 2.3688526005597557],
					[59.949650066896545, 2.3686637057851119],
					[59.946583427678242, 2.3666637441679668],
					[59.944394542819779, 2.3644220608607327],
					[59.944394528633374, 2.3644220475460966],
					[59.944394498746192, 2.3644220266080409],
					[59.944394482955644, 2.3644220172068962],
					[59.941986218817405, 2.3633276342183431],
					[59.939902946178982, 2.3610942633077063],
					[59.939902929226825, 2.361094248755871],
					[59.939902892826559, 2.3610942237443906],
					[59.939902874270352, 2.3610942131056567],
					[59.937716773891509, 2.3602276142179899],
					[59.937716743830777, 2.3602276076856472],
					[59.937716684158573, 2.3602276035078185],
					[59.937716653565374, 2.3602276042640402],
					[59.935641654319184, 2.3607220044893653],
					[59.935641608750423, 2.3607220297948452],
					[59.935641537240713, 2.3607220944185823],
					[59.935641509695692, 2.3607221376494567],
					[59.933691515699181, 2.3666610181968579],
					[59.931630426326699, 2.3703277025893148],
					[59.931630422938554, 2.37032770685849],
					[59.931630418837962, 2.3703277148605983],
					[59.93163041723362, 2.3703277187722662],
					[59.930108117159136, 2.3737527194859931],
					[59.930108111633174, 2.3737527349526766],
					[59.930108103346647, 2.3737527671270824],
					[59.930108100675703, 2.3737527856118388],
					[59.929791501457778, 2.3778388855568853],
					[59.929791499853202, 2.3778388894679825],
					[59.929791499588553, 2.3778389020856054],
				],
			],
		},
		{
			fldNpdidField: "20460988",
			wlbNpdidWellbore: "5811",
			fldName: "KNARR",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "34/3-1 S",
			fldDiscoveryYear: "2008",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=20460988",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=20460988",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "373 S",
			cmpLongName: "A/S Norske Shell",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=5811",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "562",
			fldNpdidOwner: "3811116",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Florø",
			fldAreaGeometryWKT: [
				[
					[61.784499824237145, 2.7084000930264254],
					[61.787497011821117, 2.7140000732915555],
					[61.788399804800001, 2.7175000473892879],
					[61.78959980491198, 2.722900043194338],
					[61.78959980491198, 2.722900043194338],
					[61.789599804998467, 2.7229000450790304],
					[61.789599805977204, 2.7229000467810565],
					[61.790797006458135, 2.7278000472256148],
					[61.79079700876121, 2.7278000581692239],
					[61.790797016936438, 2.727800079326244],
					[61.790797021024034, 2.7278000899047465],
					[61.791499821294948, 2.7292000908638587],
					[61.791499823165907, 2.7292000923836692],
					[61.791499827080621, 2.7292000991929188],
					[61.791499829037974, 2.7292001025975616],
					[61.792399828933149, 2.7307001027045303],
					[61.792399892285779, 2.7307001487275468],
					[61.792400034245105, 2.7307001805770934],
					[61.792400111067195, 2.7307001667686301],
					[61.795400065610799, 2.7287001953457253],
					[61.798697120066748, 2.7285002043523727],
					[61.801899853252543, 2.7318001359800199],
					[61.804199849729621, 2.7344001328298075],
					[61.804199851600536, 2.7344001343505053],
					[61.804199856320949, 2.7344001390950869],
					[61.80419985908415, 2.7344001404333209],
					[61.806499833344624, 2.7367001149742607],
					[61.809099816574154, 2.7425000823752637],
					[61.809099820402025, 2.7425000873040699],
					[61.809099825294673, 2.7425000958225398],
					[61.809099827251735, 2.7425000992299382],
					[61.813399821419914, 2.7499000913307676],
					[61.815899809719681, 2.7556000657300692],
					[61.817299806157401, 2.7610000506915111],
					[61.817299904064846, 2.7610001240433135],
					[61.817300099418915, 2.7610001223250369],
					[61.817300194994964, 2.761000045732021],
					[61.818000195154653, 2.75800004433956],
					[61.818000195788869, 2.7580000384980021],
					[61.818000197863633, 2.758000024746416],
					[61.818000199304151, 2.7580000168363923],
					[61.818600199231163, 2.751000016728554],
					[61.818600199145031, 2.7510000148419858],
					[61.818600199778942, 2.7510000090001836],
					[61.818600199606685, 2.7510000052270267],
					[61.818803000178754, 2.7443000060993166],
					[61.818803000092551, 2.7443000042127563],
					[61.818803000006319, 2.7443000023261801],
					[61.818802999920109, 2.7443000004396203],
					[61.818803000263891, 2.7402000006171146],
					[61.818802998336288, 2.7401999781609629],
					[61.818802988321394, 2.7401999364120444],
					[61.818802981040108, 2.7401999150503258],
					[61.817402981116352, 2.7371999143094481],
					[61.817402975158423, 2.7371999021992064],
					[61.817402958867532, 2.7371998807775757],
					[61.817402951125075, 2.7371998690322754],
					[61.814800151049319, 2.7341998688761189],
					[61.814800141608401, 2.7341998593839523],
					[61.814800123791585, 2.7341998439896096],
					[61.814800115329298, 2.734199836201169],
					[61.813800136356981, 2.7334998510867572],
					[61.812100166157066, 2.731499886355365],
					[61.80960018806568, 2.7268999277581849],
					[61.808400196344323, 2.7220999584756425],
					[61.807700197279985, 2.7178999676858604],
					[61.807700193910669, 2.7178999531484922],
					[61.807700185387496, 2.7178999244396813],
					[61.807700179341424, 2.7178999104512003],
					[61.806600178598707, 2.7156999107118085],
					[61.806600066280396, 2.7156998576124267],
					[61.806599877383562, 2.7156999020707828],
					[61.806599799912817, 2.7156999998115627],
					[61.806599799947129, 2.7158000007205354],
					[61.806599799821853, 2.7158999997480398],
					[61.806599802732123, 2.7159000238962356],
					[61.806599813819538, 2.7159000692088284],
					[61.80659982199672, 2.7159000903731974],
					[61.806702600405799, 2.7161000479985971],
					[61.806702599685593, 2.7161999998650006],
					[61.806702600165032, 2.7162999990616181],
					[61.806702599765828, 2.7164000003312321],
					[61.806702600099278, 2.7164999995363663],
					[61.806702600446371, 2.716600000631487],
					[61.806702599741634, 2.7167000000281298],
					[61.806702599856159, 2.7167999992460481],
					[61.806702599984312, 2.716900000353919],
					[61.806702599952885, 2.7169999995803464],
					[61.806702599757678, 2.7171999999316685],
					[61.806702600162765, 2.7174000001170375],
					[61.806702599766453, 2.7174999993647164],
					[61.806702600275969, 2.7176000003194196],
					[61.806702599733654, 2.7176999995756161],
					[61.806702600097204, 2.7178000005388179],
					[61.80670260013386, 2.7178997997898957],
					[61.806599999712873, 2.7178997994962062],
					[61.806599929025296, 2.7178998292188057],
					[61.806599829160007, 2.7178999296424373],
					[61.806599799982358, 2.7179000003434317],
					[61.806599799941765, 2.7179997992643163],
					[61.806500082537859, 2.7179997994266087],
					[61.806400140686961, 2.7178998588891985],
					[61.806300163680042, 2.7177998805010284],
					[61.806197378180428, 2.7175999082104352],
					[61.806197370436429, 2.7175998964743204],
					[61.806197353077266, 2.717599871482332],
					[61.806197342656496, 2.7175998602951199],
					[61.806100199631771, 2.7174999190456202],
					[61.806100200034315, 2.717399999806561],
					[61.806100191970422, 2.7173999614879651],
					[61.806100162632099, 2.7173998913667141],
					[61.806100141357625, 2.7173998595640527],
					[61.806000199741767, 2.7172999163935851],
					[61.806000200089322, 2.7171999993744058],
					[61.806000200210008, 2.7171000005434052],
					[61.806000170784912, 2.7170999285369892],
					[61.80600007075175, 2.7170998291350852],
					[61.806000000057089, 2.7170997998539073],
					[61.80590019973495, 2.7170998008052432],
					[61.80590019976119, 2.7168999998619512],
					[61.805900199580648, 2.716799999483988],
					[61.805900200305921, 2.7167000008129834],
					[61.805900196590116, 2.7166999787339097],
					[61.805900187287492, 2.7166999330560508],
					[61.805900180089274, 2.7166999135946068],
					[61.805802999964307, 2.7164999549497222],
					[61.805802999789591, 2.716400000527551],
					[61.805803000140493, 2.7163000003047331],
					[61.805802999731078, 2.716100000054841],
					[61.805802999922015, 2.7158999996389799],
					[61.805802999907996, 2.7157999994374267],
					[61.805802999821005, 2.7156999992401283],
					[61.805802991756664, 2.7156999609223722],
					[61.805802961352043, 2.7156998872151665],
					[61.805802938292679, 2.7156998557800414],
					[61.805697400394308, 2.7155999133498057],
					[61.805697399601677, 2.7154999992748023],
					[61.805697400192471, 2.7153999992514737],
					[61.805697400262886, 2.7151999994006184],
					[61.805697400041353, 2.714999999566778],
					[61.8056974004202, 2.7147999995669334],
					[61.805697399607872, 2.7146999997564376],
					[61.805697399614807, 2.7145999997671582],
					[61.805697399548762, 2.7144999997821313],
					[61.805697400302009, 2.7143999996183252],
					[61.805697393129563, 2.7143999611180054],
					[61.805697363876327, 2.7143998928862652],
					[61.805697342601178, 2.7143998610861262],
					[61.805600200442491, 2.7142999195961397],
					[61.805600200230643, 2.7141999998699982],
					[61.805600200342262, 2.714000000388892],
					[61.805600196539416, 2.7139999764247289],
					[61.805600187322511, 2.7139999326338393],
					[61.805600179145074, 2.7139999114706606],
					[61.805500199984905, 2.7137999526888446],
					[61.805500199724534, 2.7137000008877368],
					[61.805500199815668, 2.7135999995151305],
					[61.80550019175076, 2.7135999611983306],
					[61.805500163302767, 2.7135998908990961],
					[61.805500141135219, 2.7135998592828088],
					[61.805400199894024, 2.7134999165623985],
					[61.805400200049242, 2.7133000000760066],
					[61.80540019820404, 2.7132999795171897],
					[61.805400190139039, 2.7132999412006162],
					[61.80540018383261, 2.7132999215572187],
					[61.80510018427411, 2.7125999213823544],
					[61.80510018133748, 2.7125999162748498],
					[61.805100176356397, 2.7125999058767101],
					[61.805100174311988, 2.7125999005861043],
					[61.804700174420134, 2.7118999017489287],
					[61.80470014217758, 2.7118998664922649],
					[61.80470006606761, 2.7118998173984248],
					[61.804700022113522, 2.7118998016756715],
					[61.803800022011586, 2.7117998017857441],
					[61.803799988738668, 2.7117998029044692],
					[61.803799926368413, 2.7117998176081315],
					[61.803799895399898, 2.7117998296737857],
					[61.802502694960111, 2.7125998298935605],
					[61.80250266646911, 2.7125998566752307],
					[61.802502620713867, 2.7125999193542611],
					[61.802502605234132, 2.7125999548854658],
					[61.802199804636054, 2.713899954780516],
					[61.802199803371295, 2.7138999664592016],
					[61.802199800841777, 2.7138999898165799],
					[61.802199800382617, 2.7138999994267947],
					[61.802199800365116, 2.7139999994824442],
					[61.802199800274607, 2.7140999995423534],
					[61.802199800111126, 2.7141999996064987],
					[61.802199799719126, 2.7142999178690754],
					[61.802099859140874, 2.7143998586172819],
					[61.802099837241698, 2.7143998916574525],
					[61.802099808065705, 2.7143999623510626],
					[61.802099799896681, 2.7144000001875215],
					[61.802099799942503, 2.7145999995107939],
					[61.802099800302074, 2.7146999990873062],
					[61.802099808366599, 2.7147000374002257],
					[61.802099836900588, 2.7147001095783265],
					[61.802099859067859, 2.7147001411920764],
					[61.802199799623736, 2.7148000834061401],
					[61.802199799599769, 2.7148999996257657],
					[61.802199799744599, 2.714999999540975],
					[61.802199799816492, 2.7150999994604317],
					[61.802199799815334, 2.7151999993841582],
					[61.802199799741238, 2.7152999993121338],
					[61.802199807805636, 2.715300037625334],
					[61.80219983723152, 2.7153001096212508],
					[61.802199859398677, 2.715300141235538],
					[61.80229980035174, 2.715400083667741],
					[61.80229979960562, 2.7156000005052232],
					[61.802299799701231, 2.7158000008683421],
					[61.802299799999112, 2.7158999990793289],
					[61.802299800310585, 2.7159999991800388],
					[61.802299799656808, 2.7160999994679922],
					[61.802299799822308, 2.7161999995772153],
					[61.802299799914813, 2.7162999996906976],
					[61.802299802824969, 2.7163000238355748],
					[61.802299812933342, 2.7163000674395685],
					[61.802299821110424, 2.7163000886011681],
					[61.802399799671363, 2.7165000462572659],
					[61.802399799866073, 2.7166999994805621],
					[61.8023997995979, 2.7167999999412475],
					[61.802399800148976, 2.7169000002232324],
					[61.802399799734815, 2.7170000006924284],
					[61.802399799759996, 2.7170999534805746],
					[61.802299820589461, 2.7172999105428981],
					[61.802299812532709, 2.7172999312268771],
					[61.802299802924686, 2.7172999769705606],
					[61.802299800394543, 2.7173000003277719],
					[61.802299799684185, 2.7174000004880705],
					[61.802299799793097, 2.7175000004696761],
					[61.802299799828987, 2.7176000004555427],
					[61.802299799624627, 2.7176998006436683],
					[61.802200000233739, 2.7176998006439685],
					[61.802199928567283, 2.7176998286596938],
					[61.802199828701958, 2.7176999290687038],
					[61.802199800416496, 2.7176999995765918],
					[61.802199800302191, 2.7177999992450319],
					[61.8021997999615, 2.7178999171117626],
					[61.802099858994666, 2.7179998591192147],
					[61.802099837008015, 2.7179998902714284],
					[61.80209980791669, 2.7179999628471596],
					[61.802099799746657, 2.7180000006827201],
					[61.802099800134684, 2.7180998000543948],
					[61.801997199763292, 2.7180998000799823],
					[61.801899999719986, 2.7180998006233992],
					[61.801899928945687, 2.7180998284549567],
					[61.801899829080021, 2.7180999288616459],
					[61.801899799902088, 2.7180999995513924],
					[61.801899800295352, 2.7181998001622256],
					[61.801800000075112, 2.71819980052872],
					[61.80169999986974, 2.7181998000041152],
					[61.801699929181972, 2.7181998297206333],
					[61.801699829229683, 2.7181999282409093],
					[61.801699800138245, 2.7182000008154898],
					[61.80169979972429, 2.7183000007610887],
					[61.801699799976511, 2.718399918723363],
					[61.801602657058623, 2.7184998603938619],
					[61.801602636050639, 2.7184998932477087],
					[61.801602606785934, 2.7184999620509527],
					[61.801602600400258, 2.7184999995199717],
					[61.80160259959726, 2.7185997993640179],
					[61.801497200109054, 2.7185998004563028],
					[61.80149712933467, 2.718599828286266],
					[61.801497028576343, 2.7185999288728775],
					[61.801497000290396, 2.7185999993782901],
					[61.801497000388856, 2.7186998005856413],
					[61.801400000302408, 2.7186998000114952],
					[61.80139992872229, 2.7186998299094447],
					[61.801399828769597, 2.7186999284270761],
					[61.80139979967786, 2.7187000010004918],
					[61.801399799612803, 2.7187998000105358],
					[61.801300000342948, 2.7187998000331648],
					[61.8012000001956, 2.718799799343524],
					[61.801199960936408, 2.7187998073908339],
					[61.801199889356248, 2.7187998372883357],
					[61.801199857121823, 2.7187998610238946],
					[61.801102714763729, 2.7188997995416728],
					[61.801000000082908, 2.7188997991855901],
					[61.800999961802539, 2.7188998089352068],
					[61.800999891028034, 2.7188998367639723],
					[61.800999858707044, 2.7188998586138813],
					[61.800899999883434, 2.7189997172671738],
					[61.800800141337191, 2.7188998593544498],
					[61.800800108810002, 2.7188998374750146],
					[61.800800038115725, 2.7188998081939268],
					[61.800799999948609, 2.7188998007922653],
					[61.800700080875899, 2.7188998001901834],
					[61.800597338918486, 2.718799856729234],
					[61.800597307370069, 2.7187998365525194],
					[61.800597237568034, 2.7187998070889261],
					[61.800597200293154, 2.7187997995045508],
					[61.800500000299301, 2.7187997994573103],
					[61.800400000233743, 2.7187998005010252],
					[61.800300000080973, 2.7187997996403932],
					[61.800202800085408, 2.7187997995382744],
					[61.800097199789072, 2.7187998008547378],
					[61.800097129014581, 2.7187998286828119],
					[61.800097029148304, 2.7187999290810101],
					[61.800096999969938, 2.7187999997658405],
					[61.800097000036388, 2.7188998001989475],
					[61.800000000020113, 2.718899800619877],
					[61.799999961653157, 2.7188998084837763],
					[61.799999890878638, 2.71889983631152],
					[61.799999858557641, 2.7188998581606585],
					[61.799899917231457, 2.7189998006827265],
					[61.799799999933597, 2.7189997992183881],
					[61.799799929245602, 2.7189998289309774],
					[61.799799829379161, 2.7189999293275213],
					[61.799799800200667, 2.7190000000114694],
					[61.799799800108801, 2.7190997994864756],
					[61.799702800082528, 2.7190997992204182],
					[61.799702729394532, 2.7190998289326815],
					[61.799702628635742, 2.7190999295114282],
					[61.799702600349455, 2.7191000000121774],
					[61.799702600180595, 2.719199799175315],
					[61.799600000006123, 2.7191998007114719],
					[61.799599929231533, 2.7191998285381085],
					[61.799599829364929, 2.7191999289333015],
					[61.799599800186314, 2.7191999996166096],
					[61.799599800026819, 2.7192998003349271],
					[61.79949999991922, 2.7192998004582707],
					[61.799499929144623, 2.7192998282845493],
					[61.79949982927792, 2.7192999286790971],
					[61.799499800099291, 2.7192999993620526],
					[61.799499799862659, 2.7193997997594117],
					[61.799399999750243, 2.7193997995385453],
					[61.799399962275508, 2.7193998072187586],
					[61.799399890695163, 2.7193998371128676],
					[61.799399859266323, 2.7193998587781589],
					[61.799299916719278, 2.7194997996931156],
					[61.799197200303183, 2.7194997992278553],
					[61.799197161043928, 2.7194998072735994],
					[61.799197088571297, 2.7194998373501087],
					[61.799197057228994, 2.7194998609004055],
					[61.799099914667231, 2.7195997997660055],
					[61.799000000188961, 2.7195997994181673],
					[61.798999928608559, 2.7195998293113521],
					[61.798999828741586, 2.719599929703207],
					[61.798999800455043, 2.7196000002018557],
					[61.798999800065154, 2.7196998008710933],
					[61.798899999937014, 2.7196997995797578],
					[61.798802800069161, 2.7196998001388453],
					[61.798697199810078, 2.7196997999625192],
					[61.798697129121877, 2.7196998296722898],
					[61.798697029168295, 2.7196999281775334],
					[61.798697000075933, 2.7197000007434706],
					[61.798696999600402, 2.7197998004321802],
					[61.798599999625402, 2.7197997996354828],
					[61.798499999678938, 2.71979980082989],
					[61.798499947208903, 2.7197998153881349],
					[61.798499857842963, 2.7197998698575367],
					[61.798499821033644, 2.71979991165394],
					[61.798399876392821, 2.7199997993752305],
					[61.798302799960254, 2.7199998002361836],
					[61.798302729185444, 2.7199998280596365],
					[61.798302629318151, 2.7199999284477689],
					[61.798302600139081, 2.7199999991272654],
					[61.798302600407062, 2.7200997992484561],
					[61.798200000271201, 2.7200997993878411],
					[61.798199928690671, 2.720099829278992],
					[61.798199828823265, 2.7200999296664552],
					[61.798199799644166, 2.7201000003456279],
					[61.79819979983489, 2.7201998001367391],
					[61.798099999853775, 2.7201998008381336],
					[61.798099929078951, 2.7201998286609088],
					[61.798099829211495, 2.720199929047697],
					[61.798099800032325, 2.720199999726546],
					[61.798099800395633, 2.7203000008567875],
					[61.798099800436404, 2.7203998003312062],
					[61.798000000446208, 2.7203998003632206],
					[61.797999928779063, 2.7203998283682407],
					[61.797999828911436, 2.7203999287540612],
					[61.797999799732153, 2.7203999994324706],
					[61.797999799782353, 2.7204998004711292],
					[61.797899999787347, 2.7204998001589726],
					[61.797899929098946, 2.7204998298660286],
					[61.797899829144725, 2.7204999283659901],
					[61.797899800051901, 2.7205000009292664],
					[61.79789979986306, 2.7205999158555345],
					[61.797797061136855, 2.7206998571767405],
					[61.797797038257229, 2.7206998885053224],
					[61.797797008272063, 2.7206999612509746],
					[61.797797000101191, 2.7206999990805039],
					[61.797797000021639, 2.7207999193361441],
					[61.797699856909276, 2.720899860635051],
					[61.797699835900652, 2.7208998934829522],
					[61.797699806634604, 2.7208999622750105],
					[61.797699800248189, 2.7208999997387551],
					[61.797699799694492, 2.7210999176620869],
					[61.797599859034953, 2.7211998599013438],
					[61.797499858803903, 2.7212998593317876],
					[61.797499836816414, 2.72129989047682],
					[61.79749980763664, 2.7212999611532225],
					[61.797499800444392, 2.7213000006845887],
					[61.797499799817707, 2.7213998001354569],
					[61.797402800029289, 2.7213997997164516],
					[61.797297199944545, 2.7213997990650611],
					[61.797297129255917, 2.7213998287694143],
					[61.797297029387479, 2.721399929149571],
					[61.797297000207649, 2.7213999998254041],
					[61.797296999586031, 2.7214998005061748],
					[61.797199999792291, 2.721499799733738],
					[61.797100000024678, 2.7214998007781732],
					[61.797000000169973, 2.7214997999184836],
					[61.796999928589074, 2.7214998298050364],
					[61.796999828633993, 2.7214999282987322],
					[61.796999800432886, 2.7215000006761265],
					[61.796999799725796, 2.7215998003946424],
					[61.796902799926208, 2.7215997992514054],
					[61.79690276155906, 2.7215998071109149],
					[61.796902692654903, 2.721599836448795],
					[61.79690266113915, 2.7215998562248016],
					[61.796799860663043, 2.7216998565599924],
					[61.7967998597708, 2.7216998567427653],
					[61.796799858965038, 2.7216998588106915],
					[61.796799858965038, 2.7216998588106915],
					[61.796699917079415, 2.7217997999732439],
					[61.796600000162513, 2.7217998000962988],
					[61.796599961795359, 2.7217998079554566],
					[61.796599891106624, 2.7217998376581201],
					[61.796599858785079, 2.7217998595016004],
					[61.7964999168125, 2.7218997996535275],
					[61.79639719981467, 2.7218998002176682],
					[61.79630000018728, 2.7218998008872721],
					[61.796200000397263, 2.721899800457416],
					[61.796099999714379, 2.7218998001913164],
					[61.796002800085297, 2.7218998008060571],
					[61.795897284706747, 2.7218997998245693],
					[61.795800143434434, 2.7217998615256622],
					[61.79580011082119, 2.7217998377613157],
					[61.795800039235253, 2.7217998086603719],
					[61.795800000089471, 2.7217997995536396],
					[61.795700000300734, 2.721799799354637],
					[61.795599999619071, 2.7217997993194358],
					[61.795502799991205, 2.7217998001585504],
					[61.795400200023494, 2.7217997997243275],
					[61.795400199792411, 2.7216999997264115],
					[61.795400171348774, 2.7216999294420141],
					[61.795400071232621, 2.7216998281721825],
					[61.795399999733128, 2.7216998009568516],
					[61.795299999859722, 2.7216997991226624],
					[61.795299929170994, 2.7216998288241254],
					[61.795299829302223, 2.7216999291964079],
					[61.795299800122173, 2.7216999998672722],
					[61.795299799950946, 2.7217995161091344],
					[61.795200140653925, 2.7216998597882931],
					[61.79520010901944, 2.7216998377267672],
					[61.79520003832571, 2.7216998084437702],
					[61.795200000072214, 2.7216997991545475],
					[61.795100000284108, 2.7216997991674159],
					[61.794997285070878, 2.721699799762261],
					[61.794900142628691, 2.721599861282106],
					[61.794900110907705, 2.7215998373358468],
					[61.794900039321703, 2.7215998082360939],
					[61.794900000175943, 2.721599799129824],
					[61.794800083256774, 2.7215997995623962],
					[61.794700142375667, 2.7214998605902201],
					[61.794602942897228, 2.7213998611152976],
					[61.79460294191847, 2.7213998594130713],
					[61.794602939068653, 2.7213998561913928],
					[61.794602938089859, 2.72139985448917],
					[61.7944973381477, 2.7212998544116385],
					[61.794497305707431, 2.721299834418724],
					[61.794497236884666, 2.7212998066566945],
					[61.794497199696401, 2.72129980095534],
					[61.794400199894646, 2.7212998002992324],
					[61.794400200061013, 2.7211999996214136],
					[61.794400191998371, 2.7211999613161826],
					[61.794400162662235, 2.7211998912172759],
					[61.794400141388735, 2.7211998594235793],
					[61.794300140574514, 2.721099859112738],
					[61.794300108939943, 2.7210998370524098],
					[61.794300038246107, 2.72109980777156],
					[61.794300000079097, 2.7210998003682665],
					[61.794200200122987, 2.7210997992887895],
					[61.794200200152154, 2.7209999992697789],
					[61.794200170815913, 2.7209999291714948],
					[61.794200070785841, 2.7209998297926923],
					[61.794200000091998, 2.7209998005121538],
					[61.794102881397983, 2.7209998003711964],
					[61.794000138730553, 2.7208998571570406],
					[61.794000107095954, 2.7208998350971187],
					[61.794000038273118, 2.7208998073363779],
					[61.794000000106088, 2.7208997999333842],
					[61.793900000258347, 2.7208998004398017],
					[61.793800200308354, 2.7208997999348097],
					[61.793800200122256, 2.7207999993399414],
					[61.793800170785936, 2.720799929242764],
					[61.793800070755736, 2.7207998298658391],
					[61.79380000006185, 2.7207998005860792],
					[61.793700000130613, 2.7207997994953859],
					[61.793597200124111, 2.7207997992756923],
					[61.793500200337739, 2.7207998000339093],
					[61.793500200091536, 2.7207000004187485],
					[61.793500170668686, 2.7206999284373907],
					[61.793500070638437, 2.7206998290617128],
					[61.793499999944537, 2.7206997997824258],
					[61.793400200000598, 2.7206997998519271],
					[61.793400199685799, 2.7206000005661393],
					[61.793400199941559, 2.7204999994725991],
					[61.793400170605096, 2.7204999293766106],
					[61.793400071466984, 2.7204998298191083],
					[61.793399999880783, 2.7204998007231507],
					[61.793300199858272, 2.7204997995390414],
					[61.793300200294269, 2.7204000004041253],
					[61.793300170871234, 2.7203999284235301],
					[61.793300070840779, 2.7203998290494513],
					[61.793300000146836, 2.720399799771041],
					[61.793203000288749, 2.7203997998536535],
					[61.793202999677213, 2.7202999993368899],
					[61.793202991527821, 2.720299959148484],
					[61.793202961212465, 2.7202998873510196],
					[61.793202938067758, 2.7202998540398236],
					[61.79309740014596, 2.7201999139134188],
					[61.793097399555052, 2.720099999557839],
					[61.793097400350966, 2.7199999992892891],
					[61.793097400181594, 2.7198999992077955],
					[61.793097393097419, 2.7198999626066263],
					[61.793097363760666, 2.719899892511902],
					[61.793097343379138, 2.7198998605376419],
					[61.793000165050792, 2.7197998837001784],
					[61.792900199966361, 2.7195999537881055],
					[61.792900199805693, 2.7194999999689689],
					[61.792900196982721, 2.7194999777159778],
					[61.792900186788977, 2.7194999322391475],
					[61.792900178612484, 2.7194999110830111],
					[61.792800200040404, 2.7192999531393518],
					[61.792800199665095, 2.7191999996586169],
					[61.792800193386441, 2.7191999609902839],
					[61.792800164135897, 2.719199892781782],
					[61.792800142861978, 2.7191998609911097],
					[61.792702999785703, 2.7190999199478827],
					[61.792702999934122, 2.719000000902668],
					[61.792702999857227, 2.718900000080978],
					[61.792702971412268, 2.7188999298052905],
					[61.79270287129426, 2.7188998285531003],
					[61.792702799707776, 2.718899799461481],
					[61.79260020039564, 2.7188998004528178],
					[61.792600200196311, 2.7186999992309868],
					[61.792600199991305, 2.7186000006418474],
					[61.79260019192796, 2.718599962339562],
					[61.792600163396315, 2.7185998901795303],
					[61.792600141229997, 2.7185998585722979],
					[61.792500200179695, 2.7184999168434913],
					[61.792500200371556, 2.718300000050542],
					[61.79250017094747, 2.7182999280738716],
					[61.792500070915601, 2.7182998287091449],
					[61.792500000221288, 2.7182997994362523],
					[61.792400200027245, 2.7182997995798837],
					[61.792400199617752, 2.718199999512088],
					[61.792400191554322, 2.718199961210177],
					[61.792400163109015, 2.7181998909358152],
					[61.792400140942611, 2.7181998593290935],
					[61.792300200430482, 2.7180999174651697],
					[61.792300199866574, 2.7179999997737001],
					[61.792300191803115, 2.7179999614719428],
					[61.792300162378879, 2.7179998894960318],
					[61.792300139233639, 2.7179998561875429],
					[61.792197339015075, 2.7178998563328052],
					[61.792197306574359, 2.7178998363448899],
					[61.79219723766446, 2.7178998067074716],
					[61.792197200389595, 2.7178997991256511],
					[61.792100200282171, 2.7178997993658496],
					[61.79210019959401, 2.7178000002904628],
					[61.792100171061954, 2.7177999281323295],
					[61.792100071029772, 2.7177998287704903],
					[61.792100000335338, 2.7177997994990664],
					[61.792000200073602, 2.717799799307663],
					[61.792000200209117, 2.7177000003787755],
					[61.792000170784725, 2.7176999284038272],
					[61.792000070752472, 2.717699829042624],
					[61.792000000058046, 2.71769979977153],
					[61.791900085444993, 2.7176998004909523],
					[61.791802999632267, 2.7175999197695657],
					[61.791803000336174, 2.717499999582476],
					[61.791802992186014, 2.7174999593966653],
					[61.791802960977051, 2.7174998877881014],
					[61.791802937831683, 2.7174998544805167],
					[61.791697400114472, 2.7173999146353811],
					[61.79169740004442, 2.7173000006378039],
					[61.791697392873004, 2.7172999621541258],
					[61.791697363621573, 2.7172998939498818],
					[61.791697343152912, 2.7172998600939735],
					[61.791600200161213, 2.7171999187417493],
					[61.791600199689739, 2.7170999994146778],
					[61.791600191712568, 2.7170999629988368],
					[61.791600163180163, 2.7170998908425257],
					[61.791600141013554, 2.7170998592373965],
					[61.791500200407476, 2.716999917617152],
					[61.791500199933481, 2.7167999995597829],
					[61.791500197110096, 2.7167999773080731],
					[61.791500186915485, 2.7167999318342395],
					[61.791500178738602, 2.7167999106797915],
					[61.791400200133118, 2.7165999527364058],
					[61.791400200350822, 2.7164999996111692],
					[61.791400193179193, 2.7164999611280609],
					[61.791400163927378, 2.7164998929252429],
					[61.791400142652861, 2.7164998611378728],
					[61.79130299984061, 2.7163999193116943],
					[61.79130299969043, 2.7163000008032134],
					[61.791303000205616, 2.7162000003391933],
					[61.791302970780485, 2.7161999283673421],
					[61.791302870747245, 2.7161998290132123],
					[61.791302800052534, 2.7161997997461467],
					[61.791200200416988, 2.7161998000649894],
					[61.791200200139833, 2.7160999998526223],
					[61.79120019207587, 2.7160999615527524],
					[61.791200162737276, 2.7160998914660013],
					[61.791200141462674, 2.7160998596790922],
					[61.791100199924436, 2.7159999169291846],
					[61.791100200469941, 2.7159000007997061],
					[61.791100199722912, 2.7157999993115345],
					[61.791100191745421, 2.7157999628966363],
					[61.791100163212363, 2.7157998907427361],
					[61.791100141045447, 2.7157998591390462],
					[61.791000200196997, 2.7156999175023135],
					[61.791000200441324, 2.7155999998263409],
					[61.791000192377204, 2.7155999615268627],
					[61.791000163038355, 2.7155998914410486],
					[61.791000140871432, 2.7155998598375981],
					[61.790900199802643, 2.7154999172823491],
					[61.790900199905337, 2.7153999999403693],
					[61.790900191841153, 2.7153999616410878],
					[61.790900162415632, 2.7153998896709313],
					[61.790900139269795, 2.7153998563659165],
					[61.790797341346348, 2.7152998592778763],
					[61.790700200233921, 2.7151999188332767],
					[61.790700200039694, 2.7151000002703229],
					[61.790700199617888, 2.7149999999364116],
					[61.790700191553576, 2.7149999616374716],
					[61.79070016310672, 2.7149998913699891],
					[61.790700140939634, 2.7149998597672838],
					[61.790600200188656, 2.7148999161583349],
					[61.790600199866432, 2.7147999998183745],
					[61.790600200122093, 2.7146999996400818],
					[61.790600192057745, 2.7146999613413452],
					[61.790600162718476, 2.7146998912573084],
					[61.790600141443569, 2.7146998594719873],
					[61.790500142053027, 2.7145998606510324],
					[61.790403000296557, 2.7144999195946142],
					[61.790403000409974, 2.714399999961191],
					[61.790402996607376, 2.7143999760087389],
					[61.79040298543314, 2.7143999288355842],
					[61.790402977255908, 2.7143999076825334],
					[61.790297399775419, 2.714199949587325],
					[61.790297399830784, 2.7140000006271441],
					[61.790297392658474, 2.713999962146028],
					[61.790297364297729, 2.7139998937651058],
					[61.790297342936071, 2.7139998600958779],
					[61.79020019979523, 2.7138999198847231],
					[61.790200200371842, 2.713800000753996],
					[61.790200199827879, 2.7137000000366265],
					[61.790200191763233, 2.7136999617386666],
					[61.790200163315717, 2.713699891473548],
					[61.790200141148361, 2.7136998598723059],
					[61.790100200154924, 2.7135999162468174],
					[61.790100199931423, 2.7134000008449322],
					[61.790100171397164, 2.7133999286956172],
					[61.790100071362041, 2.7133998293543939],
					[61.790099999774611, 2.7133998002777577],
					[61.790000199974131, 2.7133998006356701],
					[61.790000200211374, 2.7133000001254617],
					[61.790000170784829, 2.7132999281594365],
					[61.790000070749656, 2.7132998288188479],
					[61.790000000054427, 2.7132997995595818],
					[61.789903000410526, 2.7132998005539668],
					[61.789902999686809, 2.7132000005469563],
					[61.789902971152451, 2.7131999283983039],
					[61.789902871117214, 2.7131998290583228],
					[61.789902800421956, 2.7131997997993698],
					[61.789799999599438, 2.7131997997874864],
					[61.789699999821423, 2.7131997998948343],
					[61.78960008290899, 2.7131998001121351],
					[61.789500140615047, 2.7130998582737531],
					[61.789500108979382, 2.7130998362248961],
					[61.78950003837074, 2.7130998088511542],
					[61.78950000011681, 2.7130997995739894],
					[61.789397200188453, 2.7130997996352324],
					[61.789300199570448, 2.7130997994486683],
					[61.789300199532278, 2.7129999993422329],
					[61.789300192446362, 2.7129999627472365],
					[61.789300163019654, 2.7129998907831165],
					[61.789300140852156, 2.7129998591832738],
					[61.789200140713099, 2.7128998584690169],
					[61.789200109077356, 2.7128998364205765],
					[61.78920003838207, 2.7128998071628878],
					[61.789200000214741, 2.7128997997706592],
					[61.789100000445892, 2.7128998007411194],
					[61.789002879953067, 2.7128997990821087],
					[61.788897337888883, 2.7127998555314679],
					[61.788897306253155, 2.7127998334833259],
					[61.78889723662332, 2.7127998078123716],
					[61.788897200240498, 2.7127998000543885],
					[61.788800199632028, 2.7127998007234706],
					[61.788800200202161, 2.7127000001876285],
					[61.788800170775339, 2.712699928224942],
					[61.788800070739796, 2.7126998288899884],
					[61.788800000044461, 2.7126997996330964],
					[61.788700083061343, 2.7126997994220856],
					[61.788600141175849, 2.7125998580393831],
					[61.788600108647849, 2.7125998361746402],
					[61.788600038039128, 2.7125998088027341],
					[61.78859999978517, 2.7125997995263873],
					[61.788503000075487, 2.7125998005895822],
					[61.788502999533662, 2.7124999993267807],
					[61.788502992360975, 2.7124999608482616],
					[61.788502962041804, 2.7124998890694516],
					[61.788502938981921, 2.7124998576537367],
					[61.788400140074152, 2.7123998585277982],
					[61.788300141166829, 2.7122998584187941],
					[61.78820019982679, 2.7121999175134848],
					[61.788200199880819, 2.7120999991278074],
					[61.788200171432578, 2.7120999288686871],
					[61.788200071396638, 2.7120998295375243],
					[61.788199999808946, 2.7120998004655035],
					[61.788099999894982, 2.7120998000824161],
					[61.787997399812838, 2.7120998002865213],
					[61.787997399820462, 2.7120000005056051],
					[61.787997400473031, 2.7119000008375855],
					[61.787997371045805, 2.7118999288775334],
					[61.78799727100975, 2.7118998295476633],
					[61.78799720031428, 2.7118998002932795],
					[61.78790008342542, 2.711899800862084],
					[61.787800140759671, 2.7117998589162986],
					[61.787700142267511, 2.7116998605598539],
					[61.787602942882607, 2.7115998613260754],
					[61.787602941903742, 2.711599859624541],
					[61.787602939053713, 2.7115998564044799],
					[61.787602938074826, 2.7115998547029512],
					[61.787497341021158, 2.7114998587719024],
					[61.78740020003093, 2.7113999191839389],
					[61.787400200254346, 2.7112999994839195],
					[61.7874001709135, 2.7112999294103517],
					[61.787400070790405, 2.7112998281997536],
					[61.787400000181478, 2.7112998008317595],
					[61.787300200107737, 2.7112997999654693],
					[61.787300200367675, 2.7112000004174455],
					[61.7873001709401, 2.7111999284596973],
					[61.787300070903576, 2.7111998291342463],
					[61.787300000208006, 2.7111997998820518],
					[61.78720020013791, 2.7111997993218475],
					[61.787200200329238, 2.7111000001031389],
					[61.78720017090162, 2.7110999281457016],
					[61.78720007086504, 2.7110998288209034],
					[61.787200000169442, 2.7110997995690198],
					[61.787102881351338, 2.7110997991181391],
					[61.787000199935235, 2.7109999159966907],
					[61.787000199970514, 2.7108999995000085],
					[61.787000170629462, 2.7108999294277316],
					[61.787000071398346, 2.7108998280366809],
					[61.786999999897105, 2.7108998008529301],
					[61.786900199751358, 2.7108997993264619],
					[61.786900199650191, 2.71079999921121],
					[61.786900171201339, 2.7107999289562357],
					[61.78690007116456, 2.710799829633324],
					[61.786899999576647, 2.7107998005654022],
					[61.786800200326823, 2.7107997991619901],
					[61.786800200157039, 2.710699999376033],
					[61.786800170815894, 2.7106999293043987],
					[61.786800070779037, 2.7106998299821412],
					[61.786800000083375, 2.7106998007314926],
					[61.786700081042483, 2.7106997994773061],
					[61.786597400172482, 2.7105999168748967],
					[61.786597399846464, 2.7104999998216508],
					[61.786597371397434, 2.7104999295676473],
					[61.786597271273834, 2.7104998283621771],
					[61.786597199685872, 2.7104997992952025],
					[61.786500199791583, 2.7104998009700347],
					[61.786500200221539, 2.7104000001045323],
					[61.786500192156034, 2.7103999618120493],
					[61.786500162814747, 2.7103998917413796],
					[61.786500140560044, 2.7103998582617415],
					[61.786400200137393, 2.7102999172834439],
					[61.786400199600941, 2.7102000008847544],
					[61.786400171065118, 2.7101999287470089],
					[61.786400071027956, 2.7101998294276162],
					[61.7864000003322, 2.7101998001784535],
					[61.786300200212672, 2.7101998008134505],
					[61.786300200402536, 2.7100000007152993],
					[61.786300193229188, 2.7099999622401385],
					[61.786300163887695, 2.7099998921703099],
					[61.786300142611793, 2.7099998603925872],
					[61.786203000200018, 2.7098999198679148],
					[61.786203000099015, 2.7098000001761093],
					[61.786202991946695, 2.7097999599997236],
					[61.786202960733988, 2.7097998884119425],
					[61.786202937586879, 2.7097998551161062],
					[61.786097399946641, 2.7096999143662583],
					[61.786097400383397, 2.7094999993987763],
					[61.786097393296586, 2.7094999628084171],
					[61.786097363954852, 2.7094998927395317],
					[61.78609734267885, 2.7094998609623659],
					[61.786000199757048, 2.7093999188947633],
					[61.786000200192163, 2.7092999997012037],
					[61.786000192126373, 2.7092999614096462],
					[61.786000162784539, 2.7092998913411734],
					[61.786000140616274, 2.7092998597472904],
					[61.785900200001706, 2.7091999162081635],
					[61.78590020038186, 2.7090999992329721],
					[61.785900199638782, 2.7090000006828907],
					[61.785900171102334, 2.70899992854745],
					[61.785900071064376, 2.70899982923354],
					[61.785900000368407, 2.7089997999875806],
					[61.785800200123688, 2.7089998006593161],
					[61.785800200295945, 2.7089000000655639],
					[61.785800193122292, 2.7088999615912988],
					[61.785800163866938, 2.7088998934080744],
					[61.785800142590801, 2.708899861631628],
					[61.785702943331117, 2.7087998615938269],
					[61.78570291071587, 2.7087998378506186],
					[61.785702839127623, 2.7087998087883474],
					[61.785702799981216, 2.7087997997004791],
					[61.785600200401134, 2.7087997989745909],
					[61.785600199543943, 2.7086999992224898],
					[61.785600192456918, 2.708699962632918],
					[61.785600163028128, 2.7086998906815301],
					[61.785600140859721, 2.7086998590884863],
					[61.785500199760875, 2.7085999168694919],
					[61.785500199633688, 2.7084999993371723],
					[61.785500171183692, 2.7084999290875342],
					[61.785500071145421, 2.7084998297764691],
					[61.785500000449325, 2.7084998005320022],
					[61.78540008301897, 2.7084998001708014],
					[61.78530020031608, 2.708399917596148],
					[61.785300200051509, 2.7083000007231979],
					[61.785300200360965, 2.7082000000264852],
					[61.785300139382223, 2.7081999079196604],
					[61.785299968571515, 2.7081998364471738],
					[61.785299861416235, 2.7081998565322776],
					[61.785197060669944, 2.7082998574634884],
					[61.785197037793104, 2.7082998887877383],
					[61.785197007814254, 2.7082999615145429],
					[61.785196999646665, 2.7082999993314507],
					[61.785196999972584, 2.7083997998234102],
					[61.785099999810448, 2.7083998003364691],
					[61.785099929038132, 2.7083998281727624],
					[61.785099829179202, 2.7083999285524718],
					[61.785099800005831, 2.7083999992115162],
					[61.785099800275617, 2.7084999173209727],
					[61.784999916532563, 2.7085997992668895],
					[61.784900000073861, 2.7085998001864757],
					[61.784802799917699, 2.708599799197323],
					[61.784697284945914, 2.7085997998545475],
					[61.784600199808892, 2.7084999195729114],
					[61.784600200279257, 2.7083999991375096],
					[61.784600170937033, 2.7083999290730172],
					[61.784600070898705, 2.7083998297650447],
					[61.78460000020263, 2.7083998005215424],
					[61.784499999905044, 2.7083998001978049],
					[61.784499913852571, 2.7083998482876623],
					[61.784499826061982, 2.7083999956441209],
					[61.784499824237145, 2.7084000930264254],
				],
				[
					[61.782199800262624, 2.7665000006530871],
					[61.782199800315396, 2.7666999996492385],
					[61.78219980016199, 2.7669000005466966],
					[61.782199799787421, 2.7672000008917368],
					[61.782199799710249, 2.7673999997657339],
					[61.782199799562179, 2.7674999992090736],
					[61.782199807611832, 2.767500037512173],
					[61.782199837010346, 2.7675001095139056],
					[61.782199859165708, 2.7675001411468116],
					[61.782299799821892, 2.767600082363189],
					[61.782299799938365, 2.7677999999860114],
					[61.78229980039476, 2.7678999995905702],
					[61.782299799885777, 2.7679999993806681],
					[61.782299800282004, 2.7681000008780949],
					[61.782299799791303, 2.7683000002981171],
					[61.782299799882615, 2.7683999999238167],
					[61.78229980269758, 2.7684000221741347],
					[61.78229981278924, 2.7684000657682124],
					[61.782299820065901, 2.7684000871119729],
					[61.782397000388549, 2.7686000464372023],
					[61.782396999989047, 2.7687000008034532],
					[61.782396999792347, 2.7688000007625688],
					[61.782397000415024, 2.7689000005446185],
					[61.782397003315765, 2.7689000246794704],
					[61.782397014299605, 2.7689000680925782],
					[61.782397021662057, 2.7689000913209418],
					[61.782499800142297, 2.7691000489076156],
					[61.782499800102876, 2.769199999679425],
					[61.782499800437783, 2.7692999998131316],
					[61.782499799996224, 2.7695000002745225],
					[61.782499802811024, 2.7695000225251096],
					[61.782499812988192, 2.7695000680042687],
					[61.782499821157067, 2.7695000891671526],
					[61.782599799639407, 2.7697000475375146],
					[61.782599800144475, 2.7698000003633001],
					[61.782599799787697, 2.769999999452514],
					[61.782599800117012, 2.7702000002618159],
					[61.782599799725951, 2.7703000007634229],
					[61.782599800423689, 2.7704999995323738],
					[61.782599800022815, 2.7707000003839282],
					[61.782599800126562, 2.7709999995366457],
					[61.782599799887855, 2.7712000002492041],
					[61.782599800249407, 2.7714000007974384],
					[61.782599808212183, 2.7714000372176724],
					[61.782599836716365, 2.7714001094053984],
					[61.782599858870874, 2.7714001410415654],
					[61.782699799828663, 2.7715000824251739],
					[61.782699799554024, 2.7717000000094658],
					[61.782699800000401, 2.7718999991855613],
					[61.782699802900609, 2.7719000233209665],
					[61.782699813077009, 2.7719000688012576],
					[61.78269982115971, 2.7719000880804878],
					[61.782799800373404, 2.772100047287863],
					[61.782799800027639, 2.7722000006847591],
					[61.782799799752269, 2.7724000005543785],
					[61.782799800077193, 2.7726000002596956],
					[61.782799802977294, 2.7726000243952491],
					[61.782799814045781, 2.7726000696947644],
					[61.782799822214194, 2.7726000908586985],
					[61.782902600151886, 2.7728000475230035],
					[61.782902600106141, 2.7728999992183923],
					[61.782902599709573, 2.7730999996354675],
					[61.782902603416126, 2.7731000217055146],
					[61.782902612614002, 2.7731000654832587],
					[61.782902619889938, 2.7731000868285891],
					[61.782999799956933, 2.7733000468290161],
					[61.782999799636166, 2.7734000007463524],
					[61.782999800216537, 2.7735999995918927],
					[61.782999808264485, 2.7736000378976806],
					[61.782999836681803, 2.7736001082039539],
					[61.782999858921578, 2.7736001417266123],
					[61.783099799822722, 2.7737000836891643],
					[61.783099800175599, 2.7737999997157758],
					[61.783099800180281, 2.7739999992463931],
					[61.78309979971268, 2.7741000009930854],
					[61.783099803419077, 2.774100023063419],
					[61.783099812702417, 2.7741000687262161],
					[61.783099820870589, 2.7741000898907706],
					[61.783199800051023, 2.774300046944246],
					[61.783199799815449, 2.7743999994891091],
					[61.783199799866566, 2.7744999995127269],
					[61.783199799749823, 2.7746999995725963],
					[61.783199799581894, 2.7747999996088644],
					[61.783199803288205, 2.7748000216793391],
					[61.783199813377884, 2.7748000652769109],
					[61.783199819761244, 2.774800086803991],
					[61.783296999773142, 2.7750000461272042],
					[61.783297000359589, 2.7751999990254506],
					[61.783297000121159, 2.7753999995963667],
					[61.783296999590647, 2.7756000001841521],
					[61.783297007723817, 2.7756000403753545],
					[61.783297038902937, 2.7756001120253893],
					[61.783297062034599, 2.7756001453687063],
					[61.783402600189014, 2.7757000863979622],
					[61.783402599816384, 2.7757999995440548],
					[61.783402599746196, 2.7759000001060703],
					[61.783402603452302, 2.7759000221768408],
					[61.783402612649297, 2.7759000659561597],
					[61.783402619924857, 2.7759000873025297],
					[61.783499799919191, 2.7761000454673885],
					[61.783499800253047, 2.7762000005869849],
					[61.783499799809142, 2.7762999995978905],
					[61.783499807856366, 2.7763000379050422],
					[61.783499837164669, 2.7763001080339258],
					[61.783499859403854, 2.7763001415590938],
					[61.783599799736763, 2.7764000822866426],
					[61.783599799925604, 2.7764999996918354],
					[61.783599800244957, 2.7766000007444789],
					[61.783599803058635, 2.7766000229965555],
					[61.783599813233486, 2.7766000684798313],
					[61.783599821401317, 2.7766000896454033],
					[61.783699800131217, 2.7768000461794577],
					[61.783699799876899, 2.7769000000950714],
					[61.78369980703161, 2.7769000385837126],
					[61.783699836253874, 2.7769001068291082],
					[61.783699856622476, 2.7769001388325565],
					[61.783797056862362, 2.7770001397723321],
					[61.783797057754725, 2.7770001395912565],
					[61.783797059710921, 2.7770001429981903],
					[61.783797060603256, 2.7770001428171192],
					[61.783899800265132, 2.7771000835213808],
					[61.783899799870198, 2.7772000000476877],
					[61.78389980791718, 2.7772000383555833],
					[61.78389983722505, 2.7772001084862441],
					[61.783899859378273, 2.7772001401279445],
					[61.783999799913403, 2.7773000829835408],
					[61.783999800269171, 2.7773999996625895],
					[61.783999803168435, 2.777400023799601],
					[61.783999813257317, 2.7774000673991783],
					[61.783999821424985, 2.7774000885652481],
					[61.784099820655683, 2.7776000896623079],
					[61.784199821309542, 2.7778000898133799],
					[61.784199858254766, 2.7778001298663475],
					[61.784199947178799, 2.7778001840985738],
					[61.784200000135748, 2.7778001999813484],
					[61.784302674993917, 2.7778002000416273],
					[61.784399819777896, 2.7780000872949566],
					[61.784399828580653, 2.7780001026266938],
					[61.784399847799456, 2.7780001291588907],
					[61.784399859107857, 2.7780001401783352],
					[61.784499835627415, 2.7781001178354554],
					[61.784599820589392, 2.7783000890956577],
					[61.784599829306416, 2.7783001025429761],
					[61.784599846654729, 2.7783001275530443],
					[61.784599857070724, 2.7783001387536959],
					[61.784697000214642, 2.778400080363399],
					[61.784696999797291, 2.7784999993574711],
					[61.78469700269639, 2.778500023495122],
					[61.784697014741077, 2.7785000705031875],
					[61.784697022994301, 2.7785000935546207],
					[61.784802622827812, 2.778700092221654],
					[61.784802630652422, 2.7787001058502274],
					[61.784802646936839, 2.7787001272725074],
					[61.78480265735282, 2.7787001384733809],
					[61.784899800142895, 2.778800080103764],
					[61.784899800334621, 2.7788999995935613],
					[61.784899808381184, 2.7789000379031545],
					[61.784899836795852, 2.778900108218747],
					[61.784899859034404, 2.7789001417473149],
					[61.78499979979285, 2.7790000834890791],
					[61.784999799671461, 2.7790999995434871],
					[61.784999807717952, 2.7791000378532633],
					[61.784999837110625, 2.7791001098728554],
					[61.784999859263408, 2.7791001415170649],
					[61.785099858299866, 2.7792001408480576],
					[61.785197035573539, 2.7793001178738752],
					[61.785299800399812, 2.7795000487786421],
					[61.785299799684331, 2.7796000001157686],
					[61.785299807730688, 2.7796000384260529],
					[61.785299837037414, 2.7796001085621929],
					[61.785299859190104, 2.7796001402070627],
					[61.785399836083499, 2.7797001180015624],
					[61.785499821010113, 2.7799000884476213],
					[61.785599821395614, 2.7801000893372421],
					[61.785599830198166, 2.7801001046701566],
					[61.785599850308955, 2.780100131023703],
					[61.785599860724822, 2.7801001422253173],
					[61.78570265881217, 2.7802001418422875],
					[61.785799800173187, 2.780300081622928],
					[61.785799800029451, 2.7804000001543456],
					[61.785799808075616, 2.7804000384654657],
					[61.785799837381937, 2.7804001086035099],
					[61.785799858642108, 2.7804001404304324],
					[61.78589985923302, 2.7805001403078573],
					[61.785999858866418, 2.7806001410030219],
					[61.785999891384471, 2.7806001629387334],
					[61.785999962067265, 2.780600192350509],
					[61.786000000232001, 2.7806001998265666],
					[61.786097079455317, 2.7806002006742303],
					[61.786202623131075, 2.78080009324546],
					[61.786202630869795, 2.7808001049904694],
					[61.786202647239612, 2.7808001282995067],
					[61.786202656763074, 2.7808001396825706],
					[61.786299857032603, 2.7809001393703006],
					[61.786299857032603, 2.7809001393703006],
					[61.786299858010636, 2.7809001410740244],
					[61.786299858903021, 2.7809001408930389],
					[61.786399858996596, 2.7810001401848838],
					[61.786499858132608, 2.7811001402944693],
					[61.786597057142444, 2.7812001382696789],
					[61.786597058120478, 2.781200139973425],
					[61.786597059990889, 2.7812001414961975],
					[61.78659706096893, 2.7812001431999405],
					[61.786699800389357, 2.7813000833663315],
					[61.786699800189375, 2.7813999990335074],
					[61.786699808235277, 2.781400037346017],
					[61.786699836734456, 2.7814001095527567],
					[61.78669985888677, 2.781400141200316],
					[61.786799859457474, 2.7815001413034732],
					[61.786899858984931, 2.7816001403396462],
					[61.786899890610513, 2.7816001624579925],
					[61.786899962185473, 2.7816001916918611],
					[61.786900000350172, 2.7816001991693047],
					[61.786999919020118, 2.7816001997422695],
					[61.787102599683763, 2.7817000839813448],
					[61.787102600101292, 2.7818000007952186],
					[61.787102607169061, 2.7818000374045231],
					[61.787102636474714, 2.7818001075468666],
					[61.787102656842222, 2.7818001395570362],
					[61.787199857090513, 2.7819001393840948],
					[61.787199857090513, 2.7819001393840948],
					[61.787199858068554, 2.7819001410878905],
					[61.787199858960903, 2.7819001409069353],
					[61.78729979973108, 2.7820000839731041],
					[61.787299799852399, 2.7820999995565745],
					[61.787299829243587, 2.7821000715844],
					[61.787299929231636, 2.7821001711437949],
					[61.787299999914161, 2.7821002005601105],
					[61.787399875255204, 2.7821002000621409],
					[61.787497019864524, 2.7823000873910599],
					[61.787497028666834, 2.7823001027255367],
					[61.787497050733307, 2.7823001324897652],
					[61.787497062127017, 2.7823001453966421],
					[61.787602658869339, 2.7824001421309181],
					[61.787699856594848, 2.7825001382425567],
					[61.78769985668049, 2.7825001401273477],
					[61.787699857658552, 2.7825001418311905],
					[61.787699859443286, 2.7825001414693022],
					[61.787799858950578, 2.782600140731248],
					[61.787899859285034, 2.7827001404490797],
					[61.787899890910438, 2.7827001625692178],
					[61.787899961592842, 2.7827001919874381],
					[61.787899999757514, 2.7827001994664218],
					[61.787997000391655, 2.7827001997311336],
					[61.787997000074633, 2.7827999996690118],
					[61.787997029465437, 2.7828000716991328],
					[61.787997129453032, 2.7828001712629669],
					[61.787997200135443, 2.782800200681478],
					[61.788099999653006, 2.7828002010024742],
					[61.788199917216716, 2.7828001997816396],
					[61.788299858846109, 2.7829001409817664],
					[61.788299891363849, 2.7829001629214387],
					[61.788299962046246, 2.7829001923404468],
					[61.788300000210889, 2.7829001998197516],
					[61.788399999753835, 2.7829001993849904],
					[61.788502800165503, 2.7829001997819969],
					[61.788599999815865, 2.7829002001629646],
					[61.788700000249328, 2.7829001994908298],
					[61.788700037728653, 2.7829001918913665],
					[61.788700109324921, 2.7829001621564164],
					[61.788700140764782, 2.7829001405637301],
					[61.788800083114765, 2.7828002007952777],
					[61.788897199890648, 2.782800200488365],
					[61.789002800186005, 2.7828001995892788],
					[61.789099999829268, 2.7828001995622347],
					[61.789100070618808, 2.7828001718924171],
					[61.789100171427535, 2.7828000715568484],
					[61.789100199662009, 2.7827999992529504],
					[61.789100200103988, 2.7827001994986973],
					[61.789199999815224, 2.7827002007349813],
					[61.789300000236537, 2.7827001992985463],
					[61.789397199959595, 2.7827002007854542],
					[61.789397270663414, 2.7827001712302111],
					[61.789397370579728, 2.7827000710742156],
					[61.789397399792151, 2.7827000004734757],
					[61.789397400148772, 2.7826001997573164],
					[61.78949999974477, 2.7826001997267826],
					[61.789600000245684, 2.7826001997930567],
					[61.789699999853624, 2.7826002000214793],
					[61.789799919269477, 2.7826001993719505],
					[61.789902660984815, 2.7827001439413594],
					[61.789902693416906, 2.7827001639969611],
					[61.789902762314611, 2.7827001937787235],
					[61.789902800393627, 2.7827001993731248],
					[61.790000000113061, 2.782700200749848],
					[61.790099799733078, 2.7827001999323118],
					[61.790099800139885, 2.782800000870187],
					[61.790099829445133, 2.7828000710201781],
					[61.790099928540414, 2.7828001707713748],
					[61.790100000115224, 2.7828002000106831],
					[61.790199915075206, 2.782800200841657],
					[61.790297057349548, 2.782900139433746],
					[61.790297058327567, 2.7829001411377638],
					[61.790297061175998, 2.7829001443648065],
					[61.790297062068333, 2.7829001441838606],
					[61.790402636347125, 2.7830001199188517],
					[61.790499820392569, 2.7832000875476921],
					[61.790499857336336, 2.7832001276150362],
					[61.790499947322232, 2.7832001854620843],
					[61.790500000193013, 2.7832001994718838],
					[61.790599799831043, 2.7832002001858962],
					[61.790599799808923, 2.7833000008852782],
					[61.790599807768679, 2.7833000373181545],
					[61.790599837159341, 2.7833001093547236],
					[61.790599859311271, 2.7833001410075577],
					[61.790699800340633, 2.7834000836781096],
					[61.790699800047996, 2.7835000007804247],
					[61.790699799796222, 2.7835999996648382],
					[61.790699806949192, 2.7836000381638106],
					[61.790699836168386, 2.7836001064309692],
					[61.790699856535525, 2.7836001384459705],
					[61.790797000061787, 2.7837000807773302],
					[61.790796999640044, 2.783800000093624],
					[61.790797007685349, 2.7838000384118313],
					[61.790797037968119, 2.7838001102684391],
					[61.790797061097976, 2.783800143625867],
					[61.790899799726752, 2.7839000843672324],
					[61.790899799884521, 2.7840000000756171],
					[61.790899800465162, 2.7842000007169041],
					[61.790899803277661, 2.7842000229750239],
					[61.790899813450167, 2.7842000684716188],
					[61.790899820724519, 2.7842000898251227],
					[61.790999800443394, 2.7844000471686114],
					[61.790999800006361, 2.784599999123238],
					[61.79099980290448, 2.7846000232664614],
					[61.790999813076823, 2.7846000687633512],
					[61.79099982115784, 2.7846000880511168],
					[61.791099800317447, 2.7848000473635834],
					[61.791099799669603, 2.7848999999377755],
					[61.791099799759756, 2.7850999998920067],
					[61.791099807804699, 2.7851000382109703],
					[61.791099837108817, 2.7851001083654756],
					[61.791099859260363, 2.7851001400201056],
					[61.791199799619974, 2.7852000824393204],
					[61.791199799820134, 2.7853000008829745],
					[61.791199800235411, 2.7855999996921028],
					[61.791199803133367, 2.7856000238355669],
					[61.791199814197768, 2.7856000691522365],
					[61.791199822364248, 2.7856000903254166],
					[61.791302599759263, 2.7858000479853211],
					[61.791302599895673, 2.7859999996024545],
					[61.791302599628949, 2.7861000001939886],
					[61.791302600181588, 2.7862000006088699],
					[61.791302607248213, 2.7862000372242335],
					[61.791302635659441, 2.7862001075611134],
					[61.791302656918383, 2.7862001393976135],
					[61.791399799946788, 2.7863000802464302],
					[61.791399800245351, 2.7863999992065183],
					[61.791399799690716, 2.7865000001315288],
					[61.791399800061555, 2.7866999997474524],
					[61.791399808106057, 2.7867000380672118],
					[61.791399837409379, 2.7867001082239589],
					[61.791399858753827, 2.7867001419459401],
					[61.791499800358871, 2.7868000824790449],
					[61.791499800102663, 2.7869999991065302],
					[61.791499828599072, 2.7870000713296816],
					[61.791499928583903, 2.787000170918053],
					[61.79150000015791, 2.7870002001683596],
					[61.791599800242501, 2.7870001996723537],
					[61.791599799834849, 2.7872000008949356],
					[61.791599806901225, 2.7872000375108934],
					[61.791599836204298, 2.7872001076685611],
					[61.791599857463034, 2.7872001395060804],
					[61.791697037391714, 2.7873001187125723],
					[61.791802622557675, 2.787500092334918],
					[61.791802631273868, 2.7875001057879554],
					[61.791802646664692, 2.7875001274004769],
					[61.791802657079998, 2.7875001386067968],
					[61.791899799952311, 2.7876000812097077],
					[61.791899800130473, 2.7876999997860525],
					[61.791899808174698, 2.7877000381067263],
					[61.791899836585152, 2.7877001084463839],
					[61.791899858736123, 2.7877001401037353],
					[61.791999858972694, 2.7878001419393619],
					[61.792099799586261, 2.7879000822854136],
					[61.792099800360759, 2.7879999994594806],
					[61.792099828856671, 2.7880000716849667],
					[61.792099928840848, 2.788000171278473],
					[61.792100000414685, 2.7880002005315938],
					[61.792196999577271, 2.7880001999925192],
					[61.792197000330162, 2.7880999995267897],
					[61.792197008459887, 2.7881000397330271],
					[61.79219703784814, 2.7881001117780144],
					[61.792197060891411, 2.7881001432551189],
					[61.79229986058926, 2.7882001431627543],
					[61.79229989302069, 2.7882001632256226],
					[61.792299961917344, 2.7882001930218219],
					[61.792300000081802, 2.788200200508419],
					[61.792399917076395, 2.7882001991088639],
					[61.792499859214033, 2.7883001416399429],
					[61.792499890838613, 2.7883001637689442],
					[61.792499962326836, 2.7883001911379859],
					[61.792499999684487, 2.7883002006906787],
					[61.792599919359198, 2.788300199036613],
					[61.792702658569645, 2.7884001407944732],
					[61.792799857135975, 2.7885001385248724],
					[61.792799857221553, 2.7885001404100151],
					[61.792799858113952, 2.7885001402292007],
					[61.792799859006301, 2.7885001400483964],
					[61.792899859360489, 2.7886001414504755],
					[61.792899890985041, 2.7886001635800706],
					[61.792899961666414, 2.7886001930160571],
					[61.792899999830844, 2.7886002005032564],
					[61.792999799717258, 2.7886001992911025],
					[61.792999799981175, 2.7886999995755075],
					[61.792999829369144, 2.7887000716229293],
					[61.792999929352874, 2.7887001712214925],
					[61.793000000034226, 2.7887002006577934],
					[61.793097119825241, 2.788700200560509],
					[61.793202659483242, 2.7888001427907674],
					[61.793299856728424, 2.7889001397526121],
					[61.793299856728424, 2.7889001397526121],
					[61.793299857706394, 2.7889001414569639],
					[61.793299858598751, 2.7889001412761676],
					[61.793399859416944, 2.7890001415820169],
					[61.793499834706502, 2.7891001172435961],
					[61.79359699960505, 2.7893000465777948],
					[61.793596999983862, 2.7894000009131079],
					[61.793597008027653, 2.7894000392363485],
					[61.793597038307631, 2.7894001111050248],
					[61.793597061350631, 2.7894001425845167],
					[61.793699859777888, 2.7895001417650831],
					[61.793799799748214, 2.7896000833265377],
					[61.793799799989891, 2.7897000002694692],
					[61.793799808033597, 2.7897000385930411],
					[61.793799837335506, 2.7897001087580757],
					[61.79379985859368, 2.7897001405995399],
					[61.793899800214817, 2.7898000827658587],
					[61.793899800314826, 2.7899000000427008],
					[61.79389980312645, 2.7899000223035504],
					[61.793899813297138, 2.7899000678065367],
					[61.793899820570651, 2.7899000891634889],
					[61.793999820534111, 2.790100088353153],
					[61.793999820619696, 2.7901000902383539],
					[61.793999821597652, 2.790100091942783],
					[61.794102600219837, 2.7903000470061903],
					[61.794102599719125, 2.7903999992595865],
					[61.794102606870247, 2.7904000377644946],
					[61.794102636086237, 2.7904001060456842],
					[61.794102657429846, 2.7904001397731526],
					[61.794199856917835, 2.7905001382577179],
					[61.794199857003406, 2.790500140142933],
					[61.794199857981326, 2.7905001418473914],
					[61.794199858873725, 2.7905001416666271],
					[61.794299799788106, 2.7906000839282292],
					[61.794299799698273, 2.7908000008793423],
					[61.794299828999634, 2.7908000710465761],
					[61.794299928981985, 2.790800170656059],
					[61.794299999662954, 2.7908002000982961],
					[61.794399800268806, 2.7908001999400955],
					[61.794399799945097, 2.7909999995903765],
					[61.79439980709607, 2.7910000380958011],
					[61.794399836311776, 2.7910001063782244],
					[61.794399856677288, 2.7910001384019543],
					[61.794497036802774, 2.7911001188325142],
					[61.794602621599822, 2.7913000902177951],
					[61.794699820111703, 2.7915000871431048],
					[61.794699828913075, 2.7915001024837722],
					[61.794699848129461, 2.7915001290331247],
					[61.794699858544483, 2.7915001402418027],
					[61.794799800402949, 2.7916000832036363],
					[61.794799799949224, 2.791699999534631],
					[61.794799807992391, 2.791700037859993],
					[61.794799837293326, 2.7917001080292247],
					[61.79479985863663, 2.7917001417583753],
					[61.794899857790504, 2.7918001406465769],
					[61.79499705689274, 2.791900138353292],
					[61.794997057870638, 2.7919001400578405],
					[61.794997059740943, 2.7919001415816593],
					[61.79499706071887, 2.7919001432862021],
					[61.795099861427964, 2.7920001430202497],
					[61.795099892966512, 2.792000163269551],
					[61.795099961862476, 2.792000193076682],
					[61.795100000026792, 2.7920002005684963],
					[61.79519979961465, 2.7920002005722666],
					[61.795199799828914, 2.792100000069357],
					[61.79519982912965, 2.7921000702398788],
					[61.795199929196656, 2.7921001717416924],
					[61.795199999791876, 2.7921001993023928],
					[61.795299800275686, 2.7921001994318266],
					[61.795299799528912, 2.7921999994389894],
					[61.795299828915141, 2.7922000714951225],
					[61.795299928896561, 2.7922001711122832],
					[61.795299999577281, 2.7922002005585891],
					[61.795399799979116, 2.7922001991091396],
					[61.795399800056089, 2.7922999992649307],
					[61.795399829442246, 2.7923000713214132],
					[61.795399929423596, 2.7923001709391944],
					[61.795400000104301, 2.7923002003858128],
					[61.795502714855296, 2.7923002007675692],
					[61.795599800048123, 2.7924000803351778],
					[61.79559979993693, 2.7924999991233315],
					[61.795599807979855, 2.7925000374499107],
					[61.795599837365955, 2.7925001095070443],
					[61.795599858623532, 2.7925001413522788],
					[61.79569985886949, 2.7926001412046721],
					[61.795799859050248, 2.792700141694112],
					[61.795799890674211, 2.7927001638298883],
					[61.795799962161709, 2.7927001912117273],
					[61.795800000411518, 2.7927002005898767],
					[61.795897000037229, 2.7927002008517108],
					[61.795896999685311, 2.7928000007598355],
					[61.795897007813728, 2.7928000409721871],
					[61.795897038984421, 2.7928001126688859],
					[61.795897062026704, 2.7928001441532153],
					[61.796002659032474, 2.7929001427179925],
					[61.796099800366868, 2.7930000808891968],
					[61.796099799753534, 2.7930999994449692],
					[61.796099807796338, 2.7931000377723056],
					[61.796099837182119, 2.7931001098311814],
					[61.796099859331953, 2.793100141496641],
					[61.79619983631197, 2.7932001171500889],
					[61.796299799578925, 2.7934000476019918],
					[61.796299800221753, 2.7935000007621174],
					[61.796299807286523, 2.7935000373851695],
					[61.796299835694199, 2.7935001077402135],
					[61.79629985695157, 2.7935001395868522],
					[61.796396999668268, 2.7936000802344094],
					[61.796397000414615, 2.7936999994215452],
					[61.796397007650363, 2.7937000398154872],
					[61.796397037928244, 2.7937001116949332],
					[61.796397060970314, 2.7937001431804611],
					[61.796499799703412, 2.7938000838029429],
					[61.796499800137177, 2.7938999995624019],
					[61.796499803033683, 2.7939000237108842],
					[61.796499813117578, 2.7939000673337255],
					[61.796499821282879, 2.7939000885127578],
					[61.796599820826813, 2.7941000898653088],
					[61.796699820816343, 2.794300088567736],
					[61.796799821422482, 2.7945000883908442],
					[61.796799830223534, 2.7945001037333919],
					[61.796799850331752, 2.7945001301057322],
					[61.796799860832074, 2.7945001432015761],
					[61.796902659050403, 2.7946001403107759],
					[61.796999799882393, 2.7947000808636049],
					[61.796999799766262, 2.7948000001740492],
					[61.796999807808604, 2.7948000385029892],
					[61.796999837108039, 2.794800108680183],
					[61.796999859257511, 2.7948001403478004],
					[61.79709979994481, 2.7949000839221103],
					[61.797099800408382, 2.7949999996150496],
					[61.797099803304718, 2.7950000237641284],
					[61.797099813388272, 2.7950000673881767],
					[61.797099820661032, 2.795000088748568],
					[61.797199800149627, 2.7952000461494499],
					[61.797199800323895, 2.795300000250255],
					[61.797199800095591, 2.7954999998678836],
					[61.797199802906327, 2.7955000221316619],
					[61.797199812989739, 2.7955000657560389],
					[61.797199820262421, 2.7955000871166193],
					[61.797297000037489, 2.795700046372648],
					[61.797296999858716, 2.795900000865152],
					[61.79729699979341, 2.7960000009234931],
					[61.797297000336194, 2.7962000008721364],
					[61.797297003146838, 2.7962000231360515],
					[61.797297015185769, 2.7962000701704244],
					[61.797297023436208, 2.7962000932360556],
					[61.797402599816294, 2.7964000491958898],
					[61.797402600238549, 2.7966000003031377],
					[61.797402599666746, 2.7967000007349632],
					[61.797402603369676, 2.7967000228184014],
					[61.797402613452732, 2.7967000664434596],
					[61.797402619832823, 2.7967000879851018],
					[61.797499800342024, 2.7969000462278486],
					[61.7974998001282, 2.7970999998958321],
					[61.797499800087913, 2.7972000004847852],
					[61.797499802898393, 2.7972000227489646],
					[61.79749981306675, 2.7972000682597766],
					[61.79749982123154, 2.7972000894403943],
					[61.797599820674016, 2.7974000882135139],
					[61.797599829474763, 2.7974001035573162],
					[61.797599847712213, 2.7974001284079693],
					[61.797599859104672, 2.7974001413245415],
					[61.797699800190223, 2.7975000834615065],
					[61.797699799589751, 2.7975999991509415],
					[61.797699828973293, 2.7976000712179281],
					[61.797699928951054, 2.7976001708602367],
					[61.797699999630801, 2.797600200321043],
					[61.797797077974977, 2.7976001998324196],
					[61.797899800460094, 2.7978000483778458],
					[61.797899800380733, 2.7979000007355426],
					[61.79789982878625, 2.7979000710983906],
					[61.797899928763847, 2.7979001707422952],
					[61.797900000335908, 2.7979002000233759],
					[61.797999800191413, 2.7979001996528416],
					[61.797999800345174, 2.7980000000575145],
					[61.79799982875064, 2.7980000704206613],
					[61.797999928813631, 2.7980001719506977],
					[61.798000000300249, 2.7980001993466099],
					[61.798099800159342, 2.7980001992825283],
					[61.798099800244401, 2.7981000000165936],
					[61.798099808285862, 2.7981000383478061],
					[61.798099836691279, 2.7981001087112753],
					[61.798099858840025, 2.7981001403823882],
					[61.798199858673968, 2.7982001409792296],
					[61.798199858759446, 2.7982001428647147],
					[61.798199859651845, 2.7982001426841436],
					[61.79819986054423, 2.7982001425035765],
					[61.798302659157478, 2.7983001406468628],
					[61.798399856997499, 2.798400138608315],
					[61.798399857082963, 2.7984001404938139],
					[61.798399857975326, 2.7984001403132583],
					[61.798399858867725, 2.7984001401326921],
					[61.798499799930461, 2.7985000821862709],
					[61.798499800419044, 2.798600000161108],
					[61.798499808460392, 2.7986000384929501],
					[61.798499836865552, 2.7986001088577965],
					[61.798499859014214, 2.7986001405296697],
					[61.798599858347643, 2.7987001402340397],
					[61.798697056729679, 2.7988001383272567],
					[61.798697089330624, 2.7988001621762577],
					[61.798697160902513, 2.7988001914601219],
					[61.798697200044423, 2.7988002006659989],
					[61.798802675222959, 2.7988002003049979],
					[61.798899820118308, 2.7990000876274963],
					[61.798899828833434, 2.7990001010868437],
					[61.798899848133935, 2.7990001295299893],
					[61.798899858548388, 2.7990001407427014],
					[61.798999799938031, 2.7991000823583407],
					[61.798999799924601, 2.7992000001007478],
					[61.798999829221913, 2.7992000702867301],
					[61.798999929284093, 2.7992001718238519],
					[61.798999999878099, 2.7992001994038818],
					[61.79909979961041, 2.7992001992838071],
					[61.799099799584816, 2.7992999993685563],
					[61.79909980673353, 2.7993000378818702],
					[61.799099835945334, 2.7993001061826286],
					[61.79909985720144, 2.7993001380361782],
					[61.799197056972844, 2.7994001392319166],
					[61.799197057950664, 2.7994001409369349],
					[61.799197059820891, 2.7994001424614097],
					[61.799197060713304, 2.7994001422808648],
					[61.799299860066483, 2.7995001415334686],
					[61.799399835939511, 2.7996001189432222],
					[61.79949982089628, 2.7998000886592367],
					[61.799499857921077, 2.7998001306435158],
					[61.799499946837479, 2.7998001849644658],
					[61.799499999792403, 2.799800200891748],
					[61.799599919174824, 2.7998001993599515],
					[61.79970260039611, 2.7999000842370769],
					[61.799702599657834, 2.8000000005301318],
					[61.799702628954755, 2.8000000707184922],
					[61.799702728930974, 2.8000001703748438],
					[61.799702799610301, 2.8000001998428461],
					[61.799799800269199, 2.800000199479733],
					[61.799799799604756, 2.800099999988896],
					[61.79979980764567, 2.800100038322749],
					[61.799799836942547, 2.8001001085114257],
					[61.799799859090889, 2.8001001401856893],
					[61.799899858882064, 2.8002001409297685],
					[61.799999857630212, 2.800300140605914],
					[61.800096999698575, 2.8004000813005874],
					[61.800096999590089, 2.8005000006038605],
					[61.800097028886761, 2.8005000707935768],
					[61.800097128862667, 2.8005001704527981],
					[61.800097200434294, 2.8005001997417707],
					[61.800202599778146, 2.8005001993682517],
					[61.800202599573353, 2.8005999991421811],
					[61.800202628955411, 2.8006000712178531],
					[61.800202728931247, 2.8006001708777273],
					[61.800202799610446, 2.8006002003475379],
					[61.800299800205352, 2.8006001999043781],
					[61.8002997999318, 2.8006999999986082],
					[61.800299829228386, 2.8007000701889737],
					[61.800299929289551, 2.8007001717351114],
					[61.800299999883315, 2.8007001993196035],
					[61.800399800391986, 2.8007001998825829],
					[61.800399800049803, 2.8008000003062223],
					[61.800399829346333, 2.8008000704969094],
					[61.800399929322005, 2.8008001701580549],
					[61.800400000001169, 2.8008001996284912],
					[61.800499914872631, 2.8008002002009209],
					[61.800597057250712, 2.8009001381995819],
					[61.80059705822854, 2.8009001399047175],
					[61.80059706009876, 2.8009001414293468],
					[61.800597061076566, 2.800900143134506],
					[61.80069985991485, 2.8010001421584194],
					[61.800799858777488, 2.8011001410945693],
					[61.800899859359667, 2.8012001403068858],
					[61.800899890982436, 2.8012001624548719],
					[61.80089996166155, 2.8012001919266445],
					[61.800899999825496, 2.8012001994309483],
					[61.800999919172568, 2.8012002003095824],
					[61.801102659244656, 2.8013001411215344],
					[61.801199857026582, 2.8014001398123951],
					[61.801199857026582, 2.8014001398123951],
					[61.801199858004424, 2.8014001415175751],
					[61.801199858896787, 2.8014001413370839],
					[61.801299859201549, 2.8015001408824598],
					[61.801299890824254, 2.8015001630310117],
					[61.801299962395717, 2.8015001923233118],
					[61.801299999667236, 2.8015002000085594],
					[61.801400000023328, 2.8015002006300422],
					[61.801496999584494, 2.8015001992230881],
					[61.801497000319493, 2.801599999116303],
					[61.801497028808676, 2.8016000713764253],
					[61.801497128783879, 2.8016001710435621],
					[61.801497200355314, 2.801600200336261],
					[61.801602800365742, 2.8016001990423907],
					[61.801699800015172, 2.8016001998003683],
					[61.801699799793425, 2.8017000005378336],
					[61.80169982908955, 2.8017000707323256],
					[61.801699929064689, 2.8017001704003945],
					[61.801699999743697, 2.8017001998739905],
					[61.801800000020279, 2.8017001991865182],
					[61.801899800476178, 2.8017002008353913],
					[61.801899800104586, 2.801800000341792],
					[61.801899829400647, 2.8018000705368307],
					[61.801899929375708, 2.801800170205845],
					[61.801900000054708, 2.8018001996798367],
					[61.801997199615997, 2.8018002005472269],
					[61.802099916937074, 2.8018002005627762],
					[61.802199859231557, 2.8019001416587916],
					[61.802199890854233, 2.8019001638083481],
					[61.802199962340175, 2.8019001912165864],
					[61.802199999697152, 2.8019002007882192],
					[61.802299999979006, 2.801900200658765],
					[61.80240000026027, 2.8019002005105369],
					[61.80250280036681, 2.8019001992640433],
					[61.802599999928539, 2.80190020033832],
					[61.802700000207928, 2.8019002001338471],
					[61.802799999594285, 2.8019002000910986],
					[61.802897200046623, 2.8019002009307359],
					[61.803002800061186, 2.8019002003920046],
					[61.803099999534496, 2.8019001994893586],
					[61.803199999810786, 2.801900199191091],
					[61.803300000171859, 2.8019002007599068],
					[61.803397199643364, 2.8019001998030797],
					[61.803499999828915, 2.8019002002501288],
					[61.803600000102726, 2.8019001998768669],
					[61.803700000375898, 2.8019001994848303],
					[61.803799999756052, 2.8019001992545638],
					[61.80390279993906, 2.8019001996239554],
					[61.804000000384718, 2.8019002002625863],
					[61.804099799857539, 2.8019002004449822],
					[61.804099799983184, 2.8019999993915823],
					[61.804099829364631, 2.8020000714775795],
					[61.804099929339664, 2.802000171153999],
					[61.804100000018629, 2.8020002006302787],
					[61.804200000292902, 2.8020002004704088],
					[61.804297199763049, 2.8020001996662969],
					[61.804402799772838, 2.802000199194461],
					[61.804499917350469, 2.802000199692221],
					[61.804599859251631, 2.8021001412721782],
					[61.804599890874293, 2.8021001634235532],
					[61.804599962445643, 2.8021001927199736],
					[61.804599999717176, 2.802100200406541],
					[61.804699915338617, 2.8021002004730522],
					[61.804797057096145, 2.8022001384034989],
					[61.804797088804158, 2.802200162441022],
					[61.804797161267906, 2.8022001915573358],
					[61.804797200409645, 2.8022002007689446],
					[61.804899916752284, 2.8022001998788593],
					[61.804999859337272, 2.8023001412802619],
					[61.804999890959905, 2.8023001634321059],
					[61.804999961638828, 2.8023001929098252],
					[61.80499999980271, 2.8023002004162167],
					[61.805099917214868, 2.8023002006980908],
					[61.805199858760481, 2.8024001413380022],
					[61.805199858760481, 2.8024001413380022],
					[61.805199859738259, 2.8024001430434411],
					[61.805199860630673, 2.8024001428629397],
					[61.805302661403722, 2.8025001433944068],
					[61.805302692940877, 2.8025001636607128],
					[61.805302761835001, 2.802500193500133],
					[61.805302799913505, 2.80250019912088],
					[61.805399800384251, 2.8025001999847876],
					[61.805399799956561, 2.8025999994132782],
					[61.805399807996899, 2.8026000377547331],
					[61.805399837378076, 2.8026001098443212],
					[61.805399858633521, 2.8026001417064683],
					[61.805499859279237, 2.8027001407802379],
					[61.805499890901785, 2.802700162932839],
					[61.805499961580644, 2.8027001924118959],
					[61.805499999744555, 2.8027001999188657],
					[61.805599800048554, 2.8027002003563499],
					[61.805599800376001, 2.8028000002632898],
					[61.805599806631477, 2.8028000389660486],
					[61.80559983584174, 2.8028001072843574],
					[61.80559985709715, 2.8028001391468464],
					[61.805697056808107, 2.8029001387949615],
					[61.805697057785927, 2.8029001405004337],
					[61.805697060633932, 2.8029001437308967],
					[61.805697061611724, 2.8029001454363942],
					[61.805802661714935, 2.8030001444204151],
					[61.805802694229897, 2.8030001663930282],
					[61.805802762953057, 2.8030001924620702],
					[61.805802800224583, 2.8030002001499295],
					[61.805899799734881, 2.803000200798254],
					[61.805899799770984, 2.8030999998076584],
					[61.805899807811173, 2.8031000381498741],
					[61.805899837106715, 2.803100108355125],
					[61.805899859254431, 2.8031001400376434],
					[61.805999858596323, 2.8032001405925797],
					[61.80609985778765, 2.803300139898858],
					[61.806197057001299, 2.8034001389186485],
					[61.806197057979119, 2.8034001406241655],
					[61.80619705984931, 2.8034001421492087],
					[61.80619706082711, 2.8034001438547262],
					[61.806299859629597, 2.8035001415875214],
					[61.806399859432304, 2.803600140739352],
					[61.806499799789187, 2.8037000821154225],
					[61.806499800087558, 2.8037999990555456],
					[61.806499808127569, 2.8038000373987129],
					[61.806499836615785, 2.8038001096724567],
					[61.80649985876336, 2.8038001413561027],
					[61.806599859173865, 2.8039001400462764],
					[61.806599859259244, 2.8039001419323033],
					[61.806599860237064, 2.8039001436378563],
					[61.806599861129442, 2.8039001434573936],
					[61.80670265853982, 2.8040001405302299],
					[61.806799800418837, 2.8041000809242842],
					[61.80679979963115, 2.8042000009247108],
					[61.806799828926145, 2.8042000711330566],
					[61.806799928899757, 2.8042001708249895],
					[61.806799999578338, 2.8042002003085988],
					[61.806899916802827, 2.8042002007831592],
					[61.80699979989113, 2.8043000829458569],
					[61.806999799687624, 2.8043999996535196],
					[61.806999829067927, 2.8044000717485362],
					[61.806999929041375, 2.804400171441769],
					[61.806999999719963, 2.8044002009259992],
					[61.807097118822909, 2.8044002006431712],
					[61.807199835699215, 2.8045001200866735],
					[61.807299821229492, 2.8047000894495442],
					[61.807299829051757, 2.804700103094532],
					[61.807299848265821, 2.8047001296627467],
					[61.807299858679869, 2.804700140880326],
					[61.807399859221626, 2.8048001410470254],
					[61.80749983521509, 2.8049001182513544],
					[61.807597020202273, 2.8051000878929604],
					[61.807597028916867, 2.805100101357747],
					[61.807597049108615, 2.8051001296321156],
					[61.807597061392805, 2.8051001423751676],
					[61.80769986052119, 2.8052001422641157],
					[61.807699893035796, 2.8052001642403348],
					[61.807699961844044, 2.8052001922017813],
					[61.807700000007848, 2.8052001997122344],
					[61.80779991709786, 2.8052001995030023],
					[61.807899800252997, 2.8053000837725737],
					[61.807899800079745, 2.8053999995007568],
					[61.807899829459537, 2.8054000715988519],
					[61.807899929432345, 2.8054001712981149],
					[61.807900000110735, 2.8054002007854142],
					[61.80799980008598, 2.8054002001268898],
					[61.807999799990817, 2.8055000000491188],
					[61.807999808030374, 2.8055000383946136],
					[61.807999838302521, 2.8055001103125878],
					[61.807999861427547, 2.8055001437046507],
					[61.808102659147096, 2.8056001407082181],
					[61.808199857142831, 2.8057001398920582],
					[61.808199857142831, 2.8057001398920582],
					[61.808199858120624, 2.8057001415977574],
					[61.808199859013001, 2.8057001414173355],
					[61.808299800155616, 2.8058000833915608],
					[61.808299799634575, 2.8059000004434975],
					[61.80829980767404, 2.8059000387894724],
					[61.808299836968231, 2.8059001090028226],
					[61.808299859115365, 2.8059001406897801],
					[61.808399857633631, 2.8060001405267179],
					[61.808497057063931, 2.8061001396623602],
					[61.808497088686032, 2.8061001618204737],
					[61.808497161234449, 2.8061001928352258],
					[61.808497200290603, 2.8061002001665254],
					[61.808599800035879, 2.8061001990589878],
					[61.808599799649208, 2.8063000006543413],
					[61.808599828943215, 2.806300070868756],
					[61.808599928915399, 2.8063001705731572],
					[61.808599999593632, 2.8063002000631192],
					[61.808699916553451, 2.8063001994958197],
					[61.808799858915542, 2.8064001408524395],
					[61.80889980032012, 2.8065000830261453],
					[61.808899800120876, 2.8065999999951043],
					[61.80889982852235, 2.8066000703908847],
					[61.808899929472112, 2.8066001718029638],
					[61.808900000064916, 2.806600199407697],
					[61.808997000083188, 2.8066001993786034],
					[61.808996999962879, 2.8067000005286791],
					[61.808997029256716, 2.8067000707443701],
					[61.808997129228658, 2.8067001704512862],
					[61.80899719990682, 2.8067001999425223],
					[61.80909991671134, 2.8067001999273868],
					[61.809199858727396, 2.806800141946062],
					[61.809299859079978, 2.806900141319983],
					[61.809299890701958, 2.8069001634794648],
					[61.809299962272455, 2.806900192791002],
					[61.809300000436181, 2.806900200303871],
					[61.809399800294834, 2.8069002004910777],
					[61.809399799802243, 2.8069999991917456],
					[61.809399829181267, 2.8070000712948597],
					[61.80939992915301, 2.807000171004006],
					[61.809399999831101, 2.807000200496252],
					[61.809502600416423, 2.8070002001586767],
					[61.8095025998553, 2.8070999991979733],
					[61.809502629234274, 2.807100071301408],
					[61.809502729205988, 2.8071001710112062],
					[61.809502799884072, 2.8071002005037693],
					[61.809599999739532, 2.807100199622774],
					[61.809699916641293, 2.807100199587754],
					[61.809799859125555, 2.8072001408160054],
					[61.80979989074747, 2.8072001629761378],
					[61.809799962317953, 2.8072001922887826],
					[61.809799999589259, 2.8072001999824909],
					[61.80989711934032, 2.807200200329464],
					[61.809999860786803, 2.807300143933642],
					[61.809999893215775, 2.8073001640273527],
					[61.809999962109003, 2.8073001938815967],
					[61.810000000187344, 2.8073001995088287],
					[61.810099999964272, 2.8073002004304399],
					[61.810199799749334, 2.8073001998831808],
					[61.810199799806, 2.8073999991366807],
					[61.810199829184825, 2.807400071242014],
					[61.810199929156347, 2.8074001709549412],
					[61.810199999834367, 2.8074002004487815],
					[61.810300000421307, 2.8074001995922702],
					[61.810397119285078, 2.8074002006618661],
					[61.810499861333774, 2.807500142524217],
					[61.810499892955661, 2.8075001646851199],
					[61.81049996176354, 2.8075001926539427],
					[61.810499999927245, 2.8075002001677478],
					[61.810599999624102, 2.8075001997614195],
					[61.810699917428799, 2.8075002006617518],
					[61.81079985941777, 2.807600140622045],
					[61.810799891039636, 2.8076001627832547],
					[61.810799961717613, 2.8076001922780565],
					[61.810799999881347, 2.8076001997920366],
					[61.810902800389421, 2.8076002007577632],
					[61.810999917387541, 2.8076002008254224],
					[61.811099859233146, 2.8077001401506894],
					[61.811099890854976, 2.8077001623121896],
					[61.811099962425381, 2.8077001916270747],
					[61.811099999696673, 2.8077001993216446],
					[61.811199914744094, 2.8077001994347053],
					[61.81129705661143, 2.8078001384053333],
					[61.811297089211045, 2.8078001622730131],
					[61.8112971607814, 2.8078001915882993],
					[61.811297199922862, 2.8078002008085714],
					[61.811399916589366, 2.8078002002616729],
					[61.811499859204162, 2.807900141294974],
					[61.811499890825985, 2.8079001634569605],
					[61.81149996239634, 2.8079001927726459],
					[61.811499999667632, 2.8079002004675138],
					[61.811600000182096, 2.8079001991111481],
					[61.811699915234769, 2.8079001997821011],
					[61.811797056897007, 2.8080001390462694],
					[61.811797088518809, 2.8080001612085592],
					[61.811797161066877, 2.8080001922306979],
					[61.81179720012301, 2.8080001995649617],
					[61.811899916794921, 2.8080001995916049],
					[61.811999859204747, 2.8081001409341311],
					[61.811999890826584, 2.8081001630966314],
					[61.811999962396868, 2.8081001924132232],
					[61.811999999668146, 2.8081002001084245],
					[61.81210000018789, 2.8081001993104127],
					[61.812199999899931, 2.8081002005604332],
					[61.812302600343983, 2.8081001995912911],
					[61.812302599650025, 2.8082000000610079],
					[61.8123026289432, 2.8082000702855798],
					[61.812302728999583, 2.8082001718939646],
					[61.812302799592146, 2.8082001995050092],
					[61.812400000282558, 2.8082001995330792],
					[61.812499999911587, 2.8082001991665195],
					[61.81259979963405, 2.8082001992124295],
					[61.812599799772137, 2.8083000004729919],
					[61.812599829065221, 2.808300070698317],
					[61.81259992903621, 2.8083001704215698],
					[61.812599999714088, 2.8083001999194819],
					[61.812697200406824, 2.8083002002103119],
					[61.812799999869824, 2.8083001995643899],
					[61.812800067180603, 2.8083001745365763],
					[61.812800166649815, 2.8083000839946002],
					[61.812800198893569, 2.8083000203668811],
					[61.8130001982016, 2.806300025578043],
					[61.813300196131529, 2.8045000426062328],
					[61.814097393113713, 2.8016000527542473],
					[61.814097393028291, 2.8016000508677688],
					[61.814097393749847, 2.8016000469142073],
					[61.814097394642218, 2.801600046733622],
					[61.814800195153119, 2.7986000459100926],
					[61.814800195789104, 2.7986000400698674],
					[61.814800197953446, 2.7986000282087571],
					[61.814800198589424, 2.7986000223685301],
					[61.815102998239141, 2.7958000311020266],
					[61.815700190693569, 2.7931000629918739],
					[61.817700183072027, 2.7886000817087964],
					[61.817700190076067, 2.7886000574399903],
					[61.817700197837546, 2.7886000101693709],
					[61.817700200379711, 2.7885999868055555],
					[61.817600200030157, 2.7870999932899361],
					[61.817600200110576, 2.7865000116186254],
					[61.81770019883426, 2.7856000223885262],
					[61.817700198698844, 2.7855999995677299],
					[61.817700192988127, 2.7855999531259723],
					[61.81770018839088, 2.7855999312105815],
					[61.816100188045148, 2.7811999319945753],
					[61.816100150757613, 2.7811998843519068],
					[61.816100056772449, 2.781199817758973],
					[61.816100000160603, 2.7811998006952208],
					[61.816002881314084, 2.7811998000854903],
					[61.815900140256446, 2.7810998581290067],
					[61.815800140606513, 2.7809998589751701],
					[61.815800108980639, 2.7809998368383266],
					[61.815800038297674, 2.7809998074010167],
					[61.815800000132924, 2.7809997999193374],
					[61.81570000032476, 2.780999800388503],
					[61.815599999623615, 2.780999801020049],
					[61.815497399747599, 2.7809998001253038],
					[61.815497399839678, 2.7808999998081885],
					[61.815497371425124, 2.7808999294221652],
					[61.81549727143527, 2.7808998297805214],
					[61.815497199859927, 2.7808998005248982],
					[61.815400124198113, 2.7808997996034113],
					[61.815300178786572, 2.7806999119695517],
					[61.815200200051763, 2.7804999523847198],
					[61.815200200073669, 2.7804000006883927],
					[61.815200197260317, 2.7803999784131719],
					[61.81520018717184, 2.7803999347687776],
					[61.815200179896699, 2.7803999133996213],
					[61.815102999735835, 2.780199954391716],
					[61.815103000263996, 2.7800999990706599],
					[61.815102999639905, 2.7800000001839349],
					[61.815102996740769, 2.7799999760223457],
					[61.815102985674088, 2.7799999306730045],
					[61.815102978398905, 2.779999909304014],
					[61.815000199611241, 2.7797999525179078],
					[61.815000199937714, 2.7796999994351927],
					[61.815000197124277, 2.7796999771601847],
					[61.815000186949831, 2.779699931629863],
					[61.815000178782242, 2.7796999104422446],
					[61.814900178577886, 2.7794999106783322],
					[61.814900141632691, 2.7794998705853571],
					[61.81490005262301, 2.7794998144125742],
					[61.814899999751852, 2.7794998004004619],
					[61.814800199647536, 2.7794997993128812],
					[61.814800200375636, 2.7793999990787435],
					[61.814800192414481, 2.7793999626185566],
					[61.814800163021154, 2.7793998905303363],
					[61.81480014086803, 2.7793998588564151],
					[61.814700141444945, 2.7792998589024842],
					[61.814700108926495, 2.7792998369493751],
					[61.814700038243224, 2.7792998075169173],
					[61.81470000007841, 2.7792998000375446],
					[61.81460299994955, 2.7792998001294009],
					[61.814602999647995, 2.7792000007266235],
					[61.814602971146904, 2.7791999284577993],
					[61.814602871155884, 2.7791998288245137],
					[61.814602799580236, 2.7791997995735924],
					[61.814500199604247, 2.7791998007353351],
					[61.81450020004079, 2.7790999996036114],
					[61.81450017073302, 2.7790999294028036],
					[61.814500070741936, 2.7790998297701623],
					[61.814500000058629, 2.7790998003383169],
					[61.814400199968674, 2.7790998004772742],
					[61.814400200336593, 2.7789999996751886],
					[61.814400192289632, 2.7789999613291596],
					[61.814400162981826, 2.7789998911286733],
					[61.814400140828617, 2.7789998594554413],
					[61.814300200364187, 2.7788999166572044],
					[61.81430019982237, 2.7788000002116604],
					[61.814300197008762, 2.7787999779372319],
					[61.814300186834068, 2.7787999324082659],
					[61.814300178666322, 2.7787999112213755],
					[61.814200200372582, 2.778599952278892],
					[61.814200199771861, 2.7784999999675857],
					[61.814200196872484, 2.7784999758068767],
					[61.814200185805333, 2.7784999304594034],
					[61.814200177637566, 2.7784999092726568],
					[61.814097399851725, 2.7782999513078592],
					[61.814097399928876, 2.7781999991629429],
					[61.814097399574116, 2.7780999994998381],
					[61.814097396760417, 2.7780999772256258],
					[61.814097386671257, 2.7780999335835905],
					[61.814097380288125, 2.778099912034484],
					[61.814000199921281, 2.7778999535669913],
					[61.814000199625099, 2.777799999869325],
					[61.814000196811364, 2.7777999775952109],
					[61.814000186636321, 2.7777999320670377],
					[61.814000179360789, 2.7777999106993416],
					[61.8139002003945, 2.7775999530464572],
					[61.813900199918827, 2.7774000005154211],
					[61.813900200307323, 2.7770999990708871],
					[61.813900199720983, 2.7769000007649782],
					[61.813900199649346, 2.7767000004082325],
					[61.813900199907337, 2.7765999992023427],
					[61.813900200376061, 2.7764000005759475],
					[61.813900199574768, 2.7762000002613454],
					[61.813900200266318, 2.7759999996009466],
					[61.813900192218512, 2.775999961256395],
					[61.813900162909214, 2.7759998910599628],
					[61.813900140755379, 2.7759998593894455],
					[61.813800200410029, 2.7758999161666522],
					[61.81380019998479, 2.7757000000905472],
					[61.813800199955111, 2.7755000001244037],
					[61.81380019963359, 2.7753000001751347],
					[61.813800199766682, 2.7751999991729521],
					[61.813800199912578, 2.7751000000613524],
					[61.813800192756879, 2.7750999615358016],
					[61.813800164425245, 2.7750998930455064],
					[61.813800143163554, 2.7750998611943967],
					[61.813702942688764, 2.7749998611715343],
					[61.813702941710616, 2.7749998594665408],
					[61.813702939840134, 2.7749998579428992],
					[61.813702938861972, 2.7749998562379043],
					[61.813600199658083, 2.7748999163889261],
					[61.813600199713221, 2.7747999997807034],
					[61.813600200382218, 2.7746999992703572],
					[61.813600192419877, 2.7746999628128823],
					[61.813600163024148, 2.7746998907320513],
					[61.813600140869994, 2.774699859062796],
					[61.813500200362995, 2.7745999170980356],
					[61.813500200203634, 2.7745000008282279],
					[61.813500200146997, 2.7743000006705079],
					[61.813500200455422, 2.7742000005072769],
					[61.81350019996097, 2.7740000003748708],
					[61.813500200050512, 2.7739000002242831],
					[61.813500200067061, 2.7738000000779128],
					[61.813500197252665, 2.7737999778045848],
					[61.813500187076315, 2.7737999322785312],
					[61.813500178907873, 2.7737999110935498],
					[61.813400178988786, 2.7735999102737021],
					[61.813300199592938, 2.7733999522185591],
					[61.813300199613714, 2.773300000266429],
					[61.813300196799254, 2.7732999779932914],
					[61.813300186708574, 2.7732999343540312],
					[61.813300180324717, 2.7732999128065168],
					[61.813202979588141, 2.773099913046785],
					[61.813202970784658, 2.773099897702572],
					[61.813202950586536, 2.7730998694446956],
					[61.813202939191818, 2.7730998565310476],
					[61.813100200144078, 2.7729999167512229],
					[61.813100199641624, 2.772899999784229],
					[61.813100199837749, 2.7728000008028126],
					[61.813100191789054, 2.7727999624601267],
					[61.813100163284716, 2.7727998902009205],
					[61.813100141130157, 2.7727998585335576],
					[61.813000199884733, 2.7726999166486528],
					[61.813000200060159, 2.7725999998386524],
					[61.813000192011422, 2.7725999614961432],
					[61.813000162700448, 2.7725998913050893],
					[61.813000141438209, 2.7725998594565446],
					[61.812900199973136, 2.7724999166525093],
					[61.812900200006979, 2.7724000001767091],
					[61.812900191958192, 2.772399961834378],
					[61.812900162647118, 2.7723998916437456],
					[61.81290014138483, 2.7723998597954429],
					[61.812800199785954, 2.7722999179585743],
					[61.812800199592353, 2.7721999999306854],
					[61.812800200096838, 2.7720999998891989],
					[61.812800170785636, 2.7720999296990634],
					[61.812800070704, 2.7720998282084501],
					[61.812800000105248, 2.7720998006805324],
					[61.812697400202069, 2.7720998005418211],
					[61.812697399834434, 2.7720000008956966],
					[61.81269740011151, 2.7718999993115245],
					[61.812697400401454, 2.7717999996178557],
					[61.812697393244832, 2.7717999610944997],
					[61.812697364019293, 2.7717998927911949],
					[61.812697342756863, 2.7717998609435042],
					[61.812600199734582, 2.7716999198877139],
					[61.812600199918428, 2.7716000004689518],
					[61.812600199909909, 2.7713999998586587],
					[61.812600199609513, 2.7711999992652547],
					[61.812600200285125, 2.7710999997365588],
					[61.812600199995423, 2.7710000003935389],
					[61.812600197094746, 2.7709999762348478],
					[61.812600187003355, 2.7709999325973871],
					[61.812600178834472, 2.7709999114137842],
					[61.812500199678219, 2.7707999530070282],
					[61.812500200341852, 2.770699999454691],
					[61.81250019992072, 2.7704999993909345],
					[61.812500200089772, 2.7704000002177951],
					[61.81250020010004, 2.770299999162595],
					[61.812500192136525, 2.7702999627076133],
					[61.812500162738544, 2.7702998906336789],
					[61.812500140583481, 2.7702998589687216],
					[61.81240019988978, 2.7701999172018632],
					[61.812400199966646, 2.770100000202655],
					[61.812400199762187, 2.7699999994861648],
					[61.812400192690951, 2.7699999628498828],
					[61.812400164271054, 2.7699998924812355],
					[61.812400143008226, 2.7699998606351102],
					[61.812302942675316, 2.7698998615283386],
					[61.812302910961968, 2.7698998375191075],
					[61.812302839384657, 2.7698998082919037],
					[61.812302800241277, 2.7698997991199161],
					[61.812200000184198, 2.769899799380084],
					[61.812100000084122, 2.7698997993414949],
					[61.811999999983449, 2.7698997992840573],
					[61.811999929283132, 2.7698998288934384],
					[61.811999829377839, 2.7698999291686155],
					[61.811999800172863, 2.7698999998344482],
					[61.811999800127118, 2.7699995176674683],
					[61.81190014125211, 2.7698998580245253],
					[61.811900108818165, 2.769899837969529],
					[61.811900038133174, 2.7698998085611652],
					[61.811899999882144, 2.7698997992077907],
					[61.81179719982255, 2.7698997993898855],
					[61.811699999763547, 2.7698998008848967],
					[61.811600199562989, 2.7698998000716504],
					[61.811600200144788, 2.7697999997713687],
					[61.811600170832442, 2.7697999295861999],
					[61.811600070749236, 2.7697998281069189],
					[61.811600000150108, 2.769799800585238],
					[61.811500000050593, 2.7697998007595825],
					[61.811400080947926, 2.769799799670428],
					[61.811297340713345, 2.7696998592701818],
					[61.811200142719102, 2.7695998602032432],
					[61.81120011109158, 2.7695998380813238],
					[61.811200038536001, 2.7695998071510211],
					[61.811200000370825, 2.7695997996843702],
					[61.811100200179844, 2.7695997997532031],
					[61.811100199586036, 2.7694999993877105],
					[61.811100199805111, 2.7694000008672242],
					[61.811100171299032, 2.7693999286158739],
					[61.811100071301468, 2.7693998290256641],
					[61.811099999724036, 2.7693997998006199],
					[61.81100020043322, 2.7693998003198805],
					[61.811000199697936, 2.7693000002881911],
					[61.811000199970053, 2.7691000476653054],
					[61.811100179147729, 2.7689000886760686],
					[61.811100187212354, 2.7689000679991294],
					[61.811100196837664, 2.768900022257335],
					[61.811100200354751, 2.7689000006017936],
					[61.811100200050127, 2.7688000002204269],
					[61.811100197149081, 2.7687999760631596],
					[61.811100187057015, 2.7687999324285522],
					[61.811100178887813, 2.7687999112465698],
					[61.811000199614227, 2.7685999538771142],
					[61.811000199671362, 2.7683999992456103],
					[61.811000200138459, 2.768200000704478],
					[61.811000200219588, 2.7681000004971517],
					[61.811000193061979, 2.7680999619768443],
					[61.811000163834692, 2.7680998936808581],
					[61.811000143463822, 2.7680998616559309],
					[61.810902999774704, 2.7679999182875124],
					[61.810902999837786, 2.7679000002366774],
					[61.810902971330982, 2.7678999279872323],
					[61.810902871332367, 2.7678998284025655],
					[61.810902799754672, 2.7678997991812011],
					[61.810800200312677, 2.7678997993399155],
					[61.810800200278145, 2.7677999996591165],
					[61.810800192228136, 2.7677999613206592],
					[61.810800162914823, 2.7677998911392465],
					[61.810800140759206, 2.7677998594778153],
					[61.81070019959261, 2.7676999176148418],
					[61.810700200337827, 2.7676000000557348],
					[61.810700200383245, 2.767399999584367],
					[61.810700196675533, 2.7673999774952556],
					[61.810700187389472, 2.767399931794015],
					[61.810700179220063, 2.7673999106126845],
					[61.810600200327571, 2.7671999523676543],
					[61.810600199972718, 2.7670999991371397],
					[61.810600200189256, 2.7668999991784458],
					[61.810600200113889, 2.7666999992366561],
					[61.810600200009702, 2.7666000002151527],
					[61.810600199746638, 2.7664999993117343],
					[61.810600200388855, 2.7664000001170908],
					[61.810600199583767, 2.7662000002175211],
					[61.81060020027148, 2.7659999999716582],
					[61.810600199656484, 2.7659000009797134],
					[61.810600199774967, 2.7657999999242548],
					[61.810600196959314, 2.7657999776538276],
					[61.81060018678042, 2.7657999321348261],
					[61.810600178610727, 2.7657999109540152],
					[61.810500199878312, 2.7655999524891017],
					[61.810500200145412, 2.7654999992887812],
					[61.810500200062108, 2.765400000462364],
					[61.810500191925499, 2.7653999602388217],
					[61.81050016163276, 2.7653998883559274],
					[61.810500138584274, 2.765399856878175],
					[61.81039739994501, 2.7652999160943814],
					[61.810397399845506, 2.7651999994412639],
					[61.810397392773034, 2.7651999628085298],
					[61.810397364350749, 2.7651998924489671],
					[61.810397343086869, 2.7651998606082331],
					[61.810300199671097, 2.7650999184248466],
					[61.810300199710625, 2.7648999999896682],
					[61.810300191659842, 2.7648999616526297],
					[61.8103001632374, 2.7648998912935552],
					[61.81030014108115, 2.7648998596347694],
					[61.810200200101555, 2.7647999170163704],
					[61.810200199559858, 2.7646999991405989],
					[61.810200199711872, 2.7645999992582726],
					[61.810200171289331, 2.7645999288996994],
					[61.810200071288477, 2.764599829328096],
					[61.810199999710214, 2.7645998001150445],
					[61.810100199842935, 2.7645997991071498],
					[61.810100199973455, 2.7643000003204516],
					[61.810100191922466, 2.7642999619838364],
					[61.810100162607434, 2.7642998918074353],
					[61.810100141257422, 2.7642998580815394],
					[61.810000199786856, 2.7641999161712274],
					[61.810000199794402, 2.7641000006767107],
					[61.810000196892538, 2.7640999765207979],
					[61.810000185820748, 2.7640999311849432],
					[61.810000177650842, 2.7640999100049792],
					[61.809897377965598, 2.763899908524805],
					[61.809897341016203, 2.7638998684570639],
					[61.809897252086742, 2.7638998142243287],
					[61.809897200106654, 2.7638998000593387],
					[61.809800000229963, 2.7638997992903023],
					[61.809799928638768, 2.7638998290926691],
					[61.809799828738029, 2.7638999293795234],
					[61.809799800428472, 2.7638999998640519],
					[61.809799799963933, 2.7640000004792307],
					[61.809799800232817, 2.7640999990308899],
					[61.809799799831239, 2.7643000001007048],
					[61.80979979981835, 2.76449991729163],
					[61.809699858652735, 2.7645998597644081],
					[61.809699836655142, 2.7645998908918523],
					[61.809699808345229, 2.7645999613754979],
					[61.809699800162285, 2.7645999992088939],
					[61.809699800075059, 2.7646999993459747],
					[61.809699799637222, 2.7647999538581818],
					[61.809599820876663, 2.7649999119036206],
					[61.809599812812621, 2.7649999325805137],
					[61.809599803102692, 2.764999976435123],
					[61.809599799672135, 2.7649999999760739],
					[61.809599799903616, 2.7650999539934427],
					[61.809502634602971, 2.7652998802384481],
					[61.809399861211475, 2.7653998566860305],
					[61.809399838321369, 2.7653998879942128],
					[61.809399808312271, 2.765399960725706],
					[61.809399800215026, 2.7654000004445831],
					[61.809399800298507, 2.7654999176287673],
					[61.809299859406231, 2.7655998590975046],
					[61.809299837408417, 2.7655998902238168],
					[61.809299808291549, 2.7655999627732974],
					[61.809299800108313, 2.7656000006059247],
					[61.80929980004175, 2.7656999174728139],
					[61.809199859076237, 2.7657998598397668],
					[61.809099859412065, 2.7658998591538695],
					[61.809099837414195, 2.765899890279774],
					[61.809099808297127, 2.7658999628284806],
					[61.80909980011382, 2.7659000006608014],
					[61.809099799581801, 2.7659999512008699],
					[61.808997022032202, 2.7661999090117195],
					[61.80899701405388, 2.766199931573929],
					[61.808997002558868, 2.7661999757904652],
					[61.808997000020447, 2.7661999991492627],
					[61.808996999800122, 2.7663000006532519],
					[61.808996999584046, 2.7664999540921373],
					[61.808899835321498, 2.7666998827529619],
					[61.808799859332119, 2.7667998598505545],
					[61.808799848161271, 2.7667998716409694],
					[61.808799828582487, 2.7667998965631511],
					[61.808799821152775, 2.7667999113993851],
					[61.808699821017818, 2.7669999102713092],
					[61.808699813039318, 2.7669999328331021],
					[61.808699803328714, 2.7669999766858129],
					[61.808699799897838, 2.767000000225857],
					[61.808699799973525, 2.7671000006132056],
					[61.808699799890306, 2.7671999991187413],
					[61.808699799820047, 2.7672999995145129],
					[61.808699800359221, 2.7673999160216964],
					[61.808599859289153, 2.7674998582163695],
					[61.808599837376782, 2.7674998912266364],
					[61.808599808172957, 2.7674999618866472],
					[61.808599799989175, 2.7674999997179439],
					[61.808599800221721, 2.7676999997055165],
					[61.808599800162384, 2.767899999709988],
					[61.80859979957706, 2.7679999998093288],
					[61.80859979960163, 2.7680999160203026],
					[61.808497060793322, 2.7681998573757043],
					[61.808497037902541, 2.7681998886808725],
					[61.808497007891908, 2.7681999614075181],
					[61.808496999707941, 2.7681999992385089],
					[61.808496999877605, 2.7683000007198624],
					[61.808496999888384, 2.76840000031943],
					[61.808496999826232, 2.7684999999232272],
					[61.808496999691052, 2.7685999995312351],
					[61.808497000180068, 2.7688000004644286],
					[61.808496999825984, 2.7689000000851158],
					[61.808497000291254, 2.768999999528504],
					[61.808496999754098, 2.7690999192188497],
					[61.808399856685419, 2.7691998607640969],
					[61.808399835664943, 2.7691998935914675],
					[61.808399807266703, 2.7691999621819399],
					[61.808399799974794, 2.7691999998310513],
					[61.808399800124498, 2.7692997991239676],
					[61.808299999929041, 2.7692997996420465],
					[61.808199999830393, 2.7692998008243119],
					[61.808199961459756, 2.7692998086280398],
					[61.808199888974883, 2.7692998385975613],
					[61.808199856645217, 2.7692998604004262],
					[61.808102715192923, 2.7693997999355222],
					[61.807999999783789, 2.769399800972427],
					[61.807999928997681, 2.769399828692551],
					[61.807999829092552, 2.7693999289555284],
					[61.807999799887732, 2.7693999996123666],
					[61.807999799960797, 2.7694997994983654],
					[61.807899999754447, 2.7694997992905481],
					[61.807799999644921, 2.769499799745434],
					[61.807700000427083, 2.769499800000002],
					[61.807597285011134, 2.7694998006240823],
					[61.80750014321535, 2.769399861159243],
					[61.807500143129488, 2.7693998592732885],
					[61.807500142237139, 2.7693998594547646],
					[61.807500141344811, 2.7693998596362328],
					[61.807400140852224, 2.7692998587988562],
					[61.807400109224709, 2.7692998366797572],
					[61.807400037647291, 2.7692998074579553],
					[61.807400000374464, 2.7692997998108537],
					[61.807300000270281, 2.7692998008234304],
					[61.807200199982219, 2.7692997992486492],
					[61.807200199711296, 2.7692000000863919],
					[61.807200171291107, 2.7691999297301466],
					[61.807200071207689, 2.7691998282665806],
					[61.807199999716111, 2.7691998009311365],
					[61.80709720046378, 2.7691998004808296],
					[61.807000200222205, 2.7691998007634067],
					[61.807000199801038, 2.7691000003700448],
					[61.807000171294924, 2.7690999281284205],
					[61.807000071297288, 2.7690998285517394],
					[61.806999999719849, 2.7690997993307485],
					[61.806900083296185, 2.7690997991297599],
					[61.806800141121123, 2.7689998596531358],
					[61.806800108601223, 2.7689998377162102],
					[61.806800037916105, 2.7689998083141409],
					[61.806799999750915, 2.7689998008489751],
					[61.8067028004197, 2.7689998003265068],
					[61.806600200183105, 2.7689997999472387],
					[61.806600199633102, 2.7689000008635558],
					[61.80660017112691, 2.7688999286230613],
					[61.806600071129175, 2.7688998290482369],
					[61.806599999551679, 2.7688997998280502],
					[61.806500200168529, 2.7688997992413769],
					[61.806500200442244, 2.7688000003057307],
					[61.806500196648884, 2.768799976333546],
					[61.806500187449274, 2.7687999325239034],
					[61.806500179280121, 2.7687999113450248],
					[61.806400179084754, 2.7685999112853867],
					[61.806400142050833, 2.7685998693303255],
					[61.806400053123085, 2.7685998150894608],
					[61.806400000165219, 2.7685997992133644],
					[61.806300121580684, 2.7685998004488437],
					[61.806197446338466, 2.7684000399106536],
					[61.806300080703366, 2.7683002007786803],
					[61.806399999859849, 2.7683001998298682],
					[61.806500000099106, 2.7683001995004424],
					[61.80660000033771, 2.7683001991521667],
					[61.806600071123626, 2.7683001714309627],
					[61.806600171027931, 2.7683000711692958],
					[61.806600200232253, 2.7683000005147367],
					[61.806600199979016, 2.7682001993931555],
					[61.806702800352738, 2.768200200870047],
					[61.806799999737024, 2.7682002007450248],
					[61.806800071329349, 2.7682001709559585],
					[61.806800171233576, 2.7682000706932972],
					[61.80680020043782, 2.7682000000381572],
					[61.80680020018923, 2.7681002001560775],
					[61.806899999622736, 2.7681001999710335],
					[61.806900037993245, 2.7681001921662403],
					[61.80690010869322, 2.7681001625583543],
					[61.806900141022666, 2.7681001407552448],
					[61.807000140912187, 2.7680001411999218],
					[61.807000162824721, 2.7680001081918073],
					[61.807000192028831, 2.7680000375360372],
					[61.807000200212769, 2.7679999997068672],
					[61.807000199839294, 2.7679000811662586],
					[61.807097342780537, 2.7678001383086781],
					[61.807097363886527, 2.7678001073677647],
					[61.807097393176463, 2.7678000385974983],
					[61.807097400382133, 2.7677999990637434],
					[61.807097399820769, 2.7677000002799228],
					[61.807097399992912, 2.7675999994328713],
					[61.8070973996482, 2.7675000481687415],
					[61.807200177658231, 2.7673000905622609],
					[61.807200185722685, 2.7673000698875976],
					[61.807200197132182, 2.7673000237881538],
					[61.807200199670817, 2.7673000004308306],
					[61.807200199546699, 2.76719999926557],
					[61.807200200327856, 2.7670999998089165],
					[61.807200200143676, 2.7670000005380269],
					[61.807200199800619, 2.7668999993854482],
					[61.807200199959759, 2.766700000683227],
					[61.807200199741025, 2.7665000001119799],
					[61.807200200011351, 2.7664000006848877],
					[61.807200200122786, 2.76629999937607],
					[61.807200200298517, 2.7661000005429619],
					[61.807200200191012, 2.7659999992468101],
					[61.807200192140549, 2.7659999609133017],
					[61.807200161848165, 2.7659998890373494],
					[61.807200138799843, 2.7659998575623805],
					[61.807097400261135, 2.7658999154256239],
					[61.807097400308677, 2.7658000000657976],
					[61.807097399986603, 2.7656999991174178],
					[61.807097392914322, 2.7656999624884726],
					[61.80709736360005, 2.7656998923173814],
					[61.807097343228676, 2.7656998602980227],
					[61.807000199933007, 2.7655999191128515],
					[61.807000199594086, 2.7655000003104342],
					[61.807000200317852, 2.7653000005996939],
					[61.80700019741623, 2.7652999764459829],
					[61.807000187323148, 2.7652999328183605],
					[61.80700017915342, 2.7652999116401129],
					[61.806900178968711, 2.7650999114865762],
					[61.806800200201877, 2.7648999539013563],
					[61.806800200405405, 2.7647999994029933],
					[61.806800196697274, 2.7647999773169918],
					[61.806800186604036, 2.7647999336898272],
					[61.806800180218943, 2.7647999121486406],
					[61.806703000008831, 2.7645999537610955],
					[61.806702999635839, 2.7643999998026376],
					[61.806702996734032, 2.7643999756492756],
					[61.806702985662426, 2.7643999303180831],
					[61.806702978384912, 2.7643999089586737],
					[61.806600200302597, 2.7641999515969826],
					[61.806600200089903, 2.7640999996656683],
					[61.806600200332824, 2.7640000000466061],
					[61.806600199610479, 2.7639000006133871],
					[61.80660019964553, 2.7636999995104952],
					[61.806600198957995, 2.7636999844234396],
					[61.806600193841732, 2.7636999512040719],
					[61.806600189584877, 2.7636999368435191],
					[61.806500199690703, 2.7633999674600802],
					[61.806500199995774, 2.7633000004380817],
					[61.806500199573179, 2.7631999992928997],
					[61.806500200465628, 2.7630000004236548],
					[61.806500199824072, 2.762899999291156],
					[61.806500171400749, 2.7628999289425171],
					[61.806500071398887, 2.7628998293878166],
					[61.806499999820339, 2.7628998001817746],
					[61.806400199830293, 2.7628998002359602],
					[61.806400200309071, 2.7626999999908253],
					[61.80640019660062, 2.7626999779053985],
					[61.806400187313116, 2.7626999322119756],
					[61.806400179142997, 2.762699911034852],
					[61.806300185221964, 2.7624999232619882],
					[61.806197388957763, 2.7621999359120601],
					[61.806197386829261, 2.7621999287319881],
					[61.806197381851874, 2.762199918325229],
					[61.806197379809291, 2.7621999130310129],
					[61.806100179995525, 2.7619999130673487],
					[61.806100179909556, 2.7619999111815057],
					[61.806100179017207, 2.7619999113631879],
					[61.806000199694374, 2.7617999532107338],
					[61.806000200261444, 2.7616999990189179],
					[61.80600019229577, 2.761699962573926],
					[61.806000162893604, 2.7616998905233978],
					[61.806000140736664, 2.7616998588712125],
					[61.805900200251493, 2.7615999166533158],
					[61.805900199633321, 2.761499999171638],
					[61.805900196902996, 2.761499978790861],
					[61.805900186722774, 2.7614999332802697],
					[61.805900180423137, 2.7614999136262557],
					[61.805802980360092, 2.7612999138691467],
					[61.805802970577098, 2.7612998968278415],
					[61.805802949484359, 2.7612998687661112],
					[61.805802938088647, 2.7612998558598458],
					[61.805697399943952, 2.7611999133283498],
					[61.805697400106965, 2.7611000001140047],
					[61.805697400421217, 2.7609000004531818],
					[61.80569739326171, 2.7608999619412389],
					[61.805697364031062, 2.7608998936638294],
					[61.805697342766294, 2.7608998618308402],
					[61.805600472834342, 2.7608001999737173],
					[61.805697200182642, 2.7608002003423411],
					[61.805697270880813, 2.7608001707190302],
					[61.805697370779363, 2.7608000704363964],
					[61.805697399979728, 2.7607999997770558],
					[61.805697399856108, 2.7606000001415736],
					[61.80569740033291, 2.7604000003412952],
					[61.805697400418822, 2.7602999995045896],
					[61.805697399565432, 2.7600999999113767],
					[61.80569740020475, 2.7598999999716316],
					[61.805697400127215, 2.7595999992416571],
					[61.805697397396614, 2.7595999788611971],
					[61.805697387215773, 2.7595999333515544],
					[61.805697380023531, 2.7595999138798195],
					[61.805600200331106, 2.7593999530562785],
					[61.805600200056688, 2.7593000000873356],
					[61.805600200236263, 2.7591999994322118],
					[61.805600200428877, 2.7591000006671162],
					[61.805600199530801, 2.7588999995597492],
					[61.805600200396768, 2.758800000625576],
					[61.805600200211451, 2.7586999999915864],
					[61.805600199707897, 2.7585000006220839],
					[61.805600198933789, 2.7584999836498625],
					[61.805600193902301, 2.7584999523183074],
					[61.805600189644913, 2.7584999379589736],
					[61.805500200082435, 2.7581999687118954],
					[61.805500200233794, 2.7580000002093357],
					[61.805500200361166, 2.75789999975335],
					[61.805500200483024, 2.7577000007398302],
					[61.805500200226923, 2.7574999998574508],
					[61.805500199764943, 2.7573000008777622],
					[61.805500200273777, 2.7572000002695676],
					[61.805500200180198, 2.7569999992476548],
					[61.805500199880754, 2.7568000001284623],
					[61.805500200024689, 2.7566999995414045],
					[61.805500200181683, 2.7566000008443456],
					[61.805500199407348, 2.7565999838722419],
					[61.805500194375426, 2.7565999525411251],
					[61.805500190031808, 2.756599936296324],
					[61.80540019021992, 2.7562999376351915],
					[61.805400187198785, 2.7562999306375704],
					[61.805400183027224, 2.7562999181644443],
					[61.805400180092128, 2.7562999130526027],
					[61.805302999774462, 2.7560999545056784],
					[61.805302999696316, 2.7560000002133198],
					[61.805302996793095, 2.7559999760619083],
					[61.805302985718733, 2.7559999307359764],
					[61.805302978439983, 2.755999909379562],
					[61.805200177650732, 2.7557999082035463],
					[61.805200169823785, 2.7557998945722129],
					[61.805200151579037, 2.7557998697408341],
					[61.805200141161194, 2.7557998585408146],
					[61.805100140766591, 2.7556998582778829],
					[61.805000163905554, 2.755599882392155],
					[61.804900185017686, 2.7553999221309859],
					[61.804797389092755, 2.7550999354907142],
					[61.804797380029207, 2.755099914498627],
					[61.80479735672035, 2.7550998773771362],
					[61.804797343367348, 2.7550998610658644],
					[61.804700143253513, 2.7549998606838479],
					[61.804700110731581, 2.754999838763263],
					[61.804700039065537, 2.7549998076914886],
					[61.804700000007472, 2.7549998004260137],
					[61.804600082994249, 2.7549998002709528],
					[61.804500164898137, 2.7548998827734938],
					[61.804402979848376, 2.7546999122152847],
					[61.804402978870016, 2.7546999105114289],
					[61.804402977891627, 2.7546999088075945],
					[61.804402976913259, 2.7546999071037539],
					[61.804297376659534, 2.7544999077544974],
					[61.804297368832479, 2.7544998941238839],
					[61.804297353436588, 2.7544998725197609],
					[61.804297343018654, 2.7544998613205007],
					[61.804200199953392, 2.7543999193072484],
					[61.804200200144905, 2.7543000007365253],
					[61.804200192091294, 2.7542999624099891],
					[61.804200162685333, 2.7542998903708957],
					[61.804200141419102, 2.7542998585440652],
					[61.80410016417644, 2.7541998823175979],
					[61.804000178773279, 2.7539999105318951],
					[61.804000141821227, 2.7539998704835362],
					[61.804000052802216, 2.7539998144034801],
					[61.803999999929076, 2.7539998004393507],
					[61.803902922414032, 2.7539997993760017],
					[61.803800199936283, 2.7537999515676028],
					[61.803800199942451, 2.7537000000189344],
					[61.803800191888634, 2.7536999616930462],
					[61.803800163460799, 2.7536998913592483],
					[61.803800141302119, 2.7536998597151259],
					[61.803700200183329, 2.7535999173161771],
					[61.803700199812617, 2.7535000004075858],
					[61.803700196908977, 2.7534999762576899],
					[61.803700186812243, 2.7534999326387974],
					[61.803700178640796, 2.7534999114660197],
					[61.803600199846016, 2.7532999525250172],
					[61.803600200302128, 2.7531999995814664],
					[61.803600196592207, 2.7531999774992499],
					[61.803600187301598, 2.7531999318130307],
					[61.803600179130122, 2.7531999106404026],
					[61.803500179113428, 2.7529999118997539],
					[61.803500179027345, 2.7529999100141009],
					[61.803500178048957, 2.752999908310366],
					[61.803397400321131, 2.7527999518503998],
					[61.803397400421076, 2.7526999995886543],
					[61.803397399666878, 2.752500000067756],
					[61.803397396849121, 2.7524999778038675],
					[61.803397386752124, 2.7524999341857415],
					[61.803397380365098, 2.7524999126495686],
					[61.803300180116658, 2.7522999136272599],
					[61.803300180030568, 2.7522999117416242],
					[61.803300179052144, 2.7522999100379266],
					[61.803200200200756, 2.7520999535048465],
					[61.803200199651549, 2.7517999996037741],
					[61.803200196833721, 2.7517999773400925],
					[61.803200186650358, 2.7517999318368651],
					[61.803200179370961, 2.7517999104829243],
					[61.803100199646728, 2.7515999524829726],
					[61.803100199690086, 2.7514999991725793],
					[61.803100200270841, 2.7514000000655527],
					[61.803100200235029, 2.7512000001605434],
					[61.803100199618456, 2.7510999993625349],
					[61.803100199907291, 2.7510000002724522],
					[61.803100192745077, 2.7509999617662033],
					[61.803100164402025, 2.7509998933221742],
					[61.803100143135055, 2.7509998614987543],
					[61.803002999692495, 2.7508999187639582],
					[61.803002999887056, 2.7507999999229313],
					[61.803002999875986, 2.7506000005203175],
					[61.803003000379164, 2.7503999990670431],
					[61.80300297105736, 2.7503999289201806],
					[61.803002871047056, 2.7503998294174385],
					[61.803002800358556, 2.7503998000615812],
					[61.802897399916233, 2.7503998005832693],
					[61.802897400256079, 2.7501999996440643],
					[61.802897396545653, 2.7501999775627639],
					[61.802897387340231, 2.7501999337641245],
					[61.802897380060585, 2.7501999124108067],
					[61.802800199554589, 2.7499999536096458],
					[61.802800200141924, 2.7498999990946991],
					[61.802800199726597, 2.7497000009082271],
					[61.802800196822304, 2.7496999767594477],
					[61.802800186638258, 2.7496999312575241],
					[61.802800179444709, 2.7496999117899836],
					[61.802700199914682, 2.7494999531858686],
					[61.802700200227605, 2.7494000009034343],
					[61.80270019732324, 2.7493999767547499],
					[61.802700187139116, 2.7493999312530941],
					[61.802700178967051, 2.749399910082122],
					[61.802600199912092, 2.7491999529317193],
					[61.802600199924271, 2.7490999991021394],
					[61.802600192847663, 2.7490999624825867],
					[61.802600163525256, 2.7490998923378998],
					[61.802600143150151, 2.7490998603343022],
					[61.802502999944274, 2.7489999192113954],
					[61.802502999580611, 2.7489000000114099],
					[61.802502999987567, 2.7488000007111921],
					[61.802502997083081, 2.7487999765627436],
					[61.802502986006473, 2.7487999312436009],
					[61.802502977748162, 2.7487999081873555],
					[61.802400200429211, 2.7485999521446338],
					[61.802400200098297, 2.7485000008775615],
					[61.80240020013153, 2.7484000000437256],
					[61.802400192076277, 2.748399961721061],
					[61.802400162753486, 2.7483998915774963],
					[61.80240014059369, 2.748399859938667],
					[61.802300200290524, 2.7482999174894749],
					[61.802300200221865, 2.7481000001878608],
					[61.802300192166527, 2.7480999618653876],
					[61.802300162843608, 2.7480998917223545],
					[61.802300140597588, 2.7480998581982665],
					[61.802200200091917, 2.7479999170457425],
					[61.802200199990246, 2.7479000005776566],
					[61.802200191934844, 2.7478999622553628],
					[61.802200162525679, 2.7478998902271976],
					[61.802200141258048, 2.7478998584068628],
					[61.80210016383716, 2.747799881953696],
					[61.801997399552356, 2.7475999517876488],
					[61.801997400207433, 2.7474999996253575],
					[61.801997399667613, 2.7473000004901462],
					[61.801997393396668, 2.7472999618041212],
					[61.801997364159519, 2.7472998935480937],
					[61.801997342891767, 2.7472998617283864],
					[61.801900199822782, 2.747199918441241],
					[61.801900199956542, 2.7471000009162965],
					[61.801900199795263, 2.746999999703732],
					[61.801900191739627, 2.7469999613820564],
					[61.801900163308481, 2.7469998910589188],
					[61.801900141148337, 2.7469998594216056],
					[61.801800199642884, 2.7468999174691171],
					[61.801800200454387, 2.7468000001004405],
					[61.801800196657275, 2.7467999761348185],
					[61.801800187450787, 2.7467999323388326],
					[61.801800179278352, 2.7467999111691817],
					[61.801700178683255, 2.7465999111645059],
					[61.801700141643025, 2.746599869242504],
					[61.801700052707517, 2.7465998150734232],
					[61.801699999747576, 2.7465997992373943],
					[61.801602799994839, 2.7465998004937271],
					[61.801497200015682, 2.7465997993513418],
					[61.80149712932095, 2.7465998290025575],
					[61.801497029433158, 2.7465999293163232],
					[61.801497000240182, 2.7465999999788417],
					[61.801496999659072, 2.7466998002431562],
					[61.801399999801617, 2.7466998003179031],
					[61.801399929020654, 2.7466998280832864],
					[61.801399829132826, 2.7466999283963869],
					[61.801399800025919, 2.7467000009440934],
					[61.801399800260171, 2.7467998007144261],
					[61.801300000293779, 2.7467997990137758],
					[61.801299928706669, 2.7467998288464197],
					[61.801299828818756, 2.7467999291588527],
					[61.801299799625625, 2.7467999998207295],
					[61.801299799907433, 2.7468999990681082],
					[61.801299800202401, 2.7470000002052473],
					[61.801299800202337, 2.7470999176563606],
					[61.801199859075496, 2.7471998589726638],
					[61.801199837081896, 2.7471998901033756],
					[61.801199807974747, 2.7471999626501495],
					[61.801199799796535, 2.747200000477275],
					[61.801199799782133, 2.747299999415715],
					[61.801199799657034, 2.7473998004464226],
					[61.801102799855272, 2.7473998001392932],
					[61.801102729160277, 2.7473998297882507],
					[61.801102629185728, 2.7473999282125914],
					[61.801102600078437, 2.7474000007589332],
					[61.801102599913989, 2.7474999993890932],
					[61.801102599762658, 2.7475999999089633],
					[61.801102600430703, 2.7477000002510232],
					[61.801102600133397, 2.7478000007793679],
					[61.80110260034774, 2.7478999155546813],
					[61.800999861423534, 2.7479998571774953],
					[61.800999837645172, 2.7479998886715125],
					[61.800999807645262, 2.7479999613991164],
					[61.800999800359136, 2.7479999990437434],
					[61.800999799838515, 2.7480999992497921],
					[61.800999800362987, 2.7482999993104742],
					[61.800999799703135, 2.7484999995701496],
					[61.800999799709906, 2.7485999996153301],
					[61.800999802614413, 2.7486000237625792],
					[61.800999813691064, 2.7486000690794397],
					[61.800999821863222, 2.7486000902490662],
					[61.801102599589015, 2.7488000487518613],
					[61.801102599818392, 2.7488999995758818],
					[61.801102600429871, 2.7489999997910011],
					[61.801102600076042, 2.7491000001923585],
					[61.801102607238754, 2.7491000386956519],
					[61.801102635582701, 2.7491001071335228],
					[61.801102656850041, 2.7491001389536094],
					[61.801199800194318, 2.7492000817508049],
					[61.80119980045631, 2.7493000004908486],
					[61.801199800052146, 2.7494999998892533],
					[61.801199800229817, 2.7496000004465433],
					[61.801199800248327, 2.7496999991225977],
					[61.801199800238734, 2.7499000002583736],
					[61.801199808293539, 2.7499000385799928],
					[61.801199836723221, 2.7499001089043387],
					[61.801199858882718, 2.7499001405430721],
					[61.801299799744911, 2.7500000826464905],
					[61.801299799977812, 2.7502000005871237],
					[61.801299799562649, 2.7502999996144504],
					[61.801299807617369, 2.7503000379363058],
					[61.801299836939151, 2.7503001080792919],
					[61.801299859184695, 2.7503001416039279],
					[61.80139979968952, 2.7504000834483882],
					[61.801399799890987, 2.7505000008229623],
					[61.801399800342878, 2.750699999195096],
					[61.801399799653119, 2.7508000004549982],
					[61.801399806815397, 2.7508000389590785],
					[61.801399836050798, 2.7508001072172537],
					[61.801399857317783, 2.7508001390388346],
					[61.801497000050574, 2.7509000804792936],
					[61.801496999977644, 2.7510000000770662],
					[61.801496999879276, 2.7510999995989653],
					[61.801497002783329, 2.7511000237468703],
					[61.801497014837608, 2.7511000707689841],
					[61.801497023095507, 2.7511000938251375],
					[61.80160260031019, 2.7513000500951845],
					[61.801602600435452, 2.7514000004042227],
					[61.801602603253336, 2.751400022666715],
					[61.801602613350688, 2.751400066281934],
					[61.801602619737821, 2.7514000878166374],
					[61.801699800034434, 2.7516000451080771],
					[61.801699800408755, 2.7517999995480134],
					[61.801699799953326, 2.7520999998468549],
					[61.801699808007513, 2.7521000381697114],
					[61.801699837328435, 2.752100108315342],
					[61.801699858595121, 2.7521001401381429],
					[61.801799800296216, 2.7522000829462705],
					[61.801799800008148, 2.752299999632033],
					[61.801799799651008, 2.7525000003998703],
					[61.801799800255225, 2.752600000608219],
					[61.801799803072946, 2.7526000228709693],
					[61.801799813255997, 2.7526000683724483],
					[61.801799821427572, 2.7526000895436971],
					[61.80189979980458, 2.7528000464297544],
					[61.801899800143083, 2.7530000000251635],
					[61.801899800386622, 2.7531000005805994],
					[61.80189980320425, 2.7531000228434732],
					[61.801899813301077, 2.7531000664597021],
					[61.801899819687961, 2.753100087994957],
					[61.801997000015525, 2.7533000464941697],
					[61.801996999676824, 2.7533999998761796],
					[61.801996999632564, 2.7535000007653023],
					[61.80199700768636, 2.7535000390889146],
					[61.801997037984975, 2.7535001109402564],
					[61.801997061035983, 2.7535001424005578],
					[61.802099799682892, 2.7536000837335681],
					[61.802099800085095, 2.7536999992060389],
					[61.802099800300546, 2.7538999996165296],
					[61.802099799895664, 2.7540000008618675],
					[61.802099807863293, 2.7540000373001519],
					[61.802099837269346, 2.7540001093341351],
					[61.802099859427933, 2.7540001409767805],
					[61.802199800385914, 2.7541000835570153],
					[61.80219979953538, 2.7541999995581414],
					[61.802199799808108, 2.7543000009601846],
					[61.802199799921766, 2.7544000004808837],
					[61.802199802739167, 2.7544000227441274],
					[61.802199812921664, 2.7544000682468037],
					[61.802199821092991, 2.7544000894188105],
					[61.802299800175589, 2.7546000465835054],
					[61.802299799793644, 2.7547000008249785],
					[61.802299800264862, 2.7549000003744375],
					[61.802299803082185, 2.7549000226377851],
					[61.802299813264518, 2.7549000681408007],
					[61.802299821435739, 2.7549000893130184],
					[61.802399799731589, 2.7551000468963918],
					[61.802399799795374, 2.7551999994173357],
					[61.802399799545206, 2.7553999996609031],
					[61.802399807684566, 2.7554000398711302],
					[61.802399837982193, 2.7554001117253475],
					[61.802399861032782, 2.7554001431874822],
					[61.802502599831271, 2.755500084290385],
					[61.802502599757261, 2.7556000009729842],
					[61.802502599738183, 2.7557999998530422],
					[61.802502599662169, 2.7559000002422125],
					[61.802502600183558, 2.7561000008514092],
					[61.802502607258312, 2.7561000374725872],
					[61.80250263568491, 2.7561001078057759],
					[61.802502656950715, 2.7561001396322062],
					[61.802599799676287, 2.756200081700626],
					[61.80259980007429, 2.7563000004331313],
					[61.802599799551416, 2.756399999274628],
					[61.802599803346887, 2.7564000232421333],
					[61.802599812636387, 2.7564000689279342],
					[61.802599820721355, 2.7564000882151563],
					[61.802699800226243, 2.7566000463272773],
					[61.802699800100747, 2.7566999997070964],
					[61.802699808153726, 2.7567000380324607],
					[61.802699836580018, 2.7567001083666658],
					[61.802699858824042, 2.7567001418975274],
					[61.802799800109071, 2.7568000833682058],
					[61.802799799991909, 2.7569000008917381],
					[61.802799799798969, 2.7570999995697267],
					[61.802799802616001, 2.7571000218336468],
					[61.802799812711534, 2.7571000654525539],
					[61.802799819990121, 2.7571000868075082],
					[61.802897000187059, 2.7573000457704859],
					[61.802896999559252, 2.757399999822292],
					[61.802897000030804, 2.7574999993153142],
					[61.802896999948594, 2.7577000003814716],
					[61.802897008087299, 2.7577000405929923],
					[61.802897039276111, 2.7577001122689047],
					[61.802897062412228, 2.7577001456189145],
					[61.803002600057859, 2.757800085098526],
					[61.803002600051379, 2.7579999992300039],
					[61.803002600089499, 2.7580999990925688],
					[61.803002600140587, 2.7582000008450138],
					[61.803002607214829, 2.7582000374672888],
					[61.803002635640389, 2.7582001078036091],
					[61.803002656905733, 2.7582001396320184],
					[61.80309979955846, 2.7583000801830115],
					[61.803099799981041, 2.7585000004652427],
					[61.803099799658298, 2.7586000006657474],
					[61.803099800154953, 2.7587000006887199],
					[61.803099802971687, 2.7587000229530259],
					[61.803099813152784, 2.758700068458547],
					[61.803099821323435, 2.7587000896323519],
					[61.803199799769047, 2.758900046738356],
					[61.803199799685451, 2.7589999995950176],
					[61.803199800030278, 2.7592000003308779],
					[61.803199808082574, 2.7592000386575548],
					[61.803199837399973, 2.7592001088135287],
					[61.803199858665081, 2.7592001406428452],
					[61.803299799690897, 2.7593000839904573],
					[61.80329980036268, 2.759400000816941],
					[61.803299800197919, 2.7594999996399228],
					[61.803299799735363, 2.7596999991842397],
					[61.803299806895183, 2.7597000376928849],
					[61.803299836212339, 2.7597001078495942],
					[61.803299857477342, 2.7597001396793468],
					[61.803396999742702, 2.7598000801176208],
					[61.803397000139988, 2.7598999991674491],
					[61.803397008278061, 2.7599000393802378],
					[61.803397037681123, 2.7599001114230108],
					[61.803397060730752, 2.7599001428895762],
					[61.803499860456071, 2.7600001425659566],
					[61.803599799706689, 2.7601000821128028],
					[61.803599799807806, 2.7601999999500357],
					[61.803599807859811, 2.7602000382774881],
					[61.803599837176726, 2.7602001084353396],
					[61.803599859333936, 2.7602001400840543],
					[61.803699800321404, 2.7603000821199357],
					[61.803699800280867, 2.760400000291285],
					[61.803699800295476, 2.7605000002785074],
					[61.803699808347403, 2.7605000386061462],
					[61.803699836771834, 2.7605001089462426],
					[61.803699858929001, 2.760500140595251],
					[61.803799799713879, 2.7606000839278924],
					[61.803799800265196, 2.7607000003701576],
					[61.803799808317052, 2.760700038697999],
					[61.803799837719701, 2.760700110742488],
					[61.803799860769139, 2.7607001422100654],
					[61.803902600390174, 2.7608000837395736],
					[61.803902599907602, 2.7609000007067537],
					[61.80390259995751, 2.7610999999822532],
					[61.803902602773867, 2.7611000222473812],
					[61.8039026128682, 2.7611000658692193],
					[61.803902620146211, 2.7611000872259037],
					[61.803999800142954, 2.7613000460521224],
					[61.80399980023082, 2.7615000001928203],
					[61.80399980026165, 2.7615999991369082],
					[61.803999800276316, 2.7618000008092158],
					[61.803999799913072, 2.7620000006127166],
					[61.803999799578989, 2.7620999995779298],
					[61.803999799670194, 2.7622999992251187],
					[61.803999800447748, 2.7625000005932607],
					[61.803999799748752, 2.7625999995796149],
					[61.803999799955079, 2.7627000002742581],
					[61.803999800002444, 2.7627999990874175],
					[61.803999800022176, 2.7628999198636826],
					[61.803902656793923, 2.762999860555222],
					[61.803902635774925, 2.7629998933817594],
					[61.803902607379705, 2.7629999619674974],
					[61.803902600089465, 2.7629999996124002],
					[61.803902599787364, 2.7630999163091285],
					[61.803799860959757, 2.7631998568261182],
					[61.803799849789165, 2.7631998686158079],
					[61.80379983029696, 2.7631998954217063],
					[61.803799821082968, 2.7631999106195519],
					[61.803699820686305, 2.7633999112844752],
					[61.803699812622455, 2.7633999319577103],
					[61.803699802998871, 2.7633999776899558],
					[61.803699800374901, 2.763399999159188],
					[61.803699799607429, 2.7636000005504417],
					[61.803699799963553, 2.7637000001279444],
					[61.803699799564569, 2.7638999994772302],
					[61.803699799851927, 2.7641000005475096],
					[61.803699799843159, 2.7642000001461371],
					[61.803699799761397, 2.7642999997489728],
					[61.803699799606655, 2.7643999993560473],
					[61.803699800357201, 2.7645000006714646],
					[61.803699808322101, 2.7645000371145323],
					[61.803699836830454, 2.7645001093441386],
					[61.803699858986747, 2.7645001409961036],
					[61.803799799990571, 2.7646000836004538],
					[61.803799800301938, 2.764699999666751],
					[61.803799828810213, 2.7647000718967889],
					[61.803799928810776, 2.7647001714491588],
					[61.803800000388968, 2.7647002006572192],
					[61.803902800068052, 2.7647001998705725],
					[61.803902839330668, 2.7647001918809302],
					[61.803902910922176, 2.7647001620869625],
					[61.803902943165141, 2.7647001383968997],
					[61.804000143298936, 2.7646001384125123],
					[61.804000164404229, 2.7646001074726678],
					[61.804000192800288, 2.7646000388881338],
					[61.804000200005042, 2.7645999993577415],
					[61.804000199612261, 2.7645000826430657],
					[61.804100141009336, 2.76440014045173],
					[61.804100163006936, 2.7644001093300479],
					[61.804100192209262, 2.7644000386777927],
					[61.804100200392234, 2.7644000008513383],
					[61.804100199737178, 2.764300199938718],
					[61.804199799640294, 2.7643001999295875],
					[61.804199800213624, 2.7643999992804695],
					[61.804199803029448, 2.7644000215461455],
					[61.804199813122757, 2.7644000651695273],
					[61.804199820400221, 2.7644000865272549],
					[61.804296999685945, 2.7646000457444866],
					[61.804296999866295, 2.7646999996222954],
					[61.804296999756829, 2.7649000007000231],
					[61.804297007893489, 2.7649000409153857],
					[61.804297039078662, 2.7649001126020245],
					[61.804297062127176, 2.7649001440732857],
					[61.804402599935102, 2.765000085232951],
					[61.804402599808427, 2.7650999995076955],
					[61.804402599930484, 2.7652999992402534],
					[61.804402599882025, 2.7653999991128817],
					[61.8044025996521, 2.765600000756534],
					[61.804402628966301, 2.7656000709215216],
					[61.804402728966252, 2.7656001704787059],
					[61.804402799651967, 2.7656001998709274],
					[61.804499800276879, 2.765600199997519],
					[61.804499799579681, 2.765800000585362],
					[61.804499800472819, 2.7660000006475629],
					[61.804499807545007, 2.7660000372735265],
					[61.804499836944935, 2.7660001093248661],
					[61.8044998591009, 2.7660001409787305],
					[61.804599799651456, 2.7661000832630722],
					[61.804599799904615, 2.7663000003024365],
					[61.804599829218517, 2.7663000704685303],
					[61.804599929217993, 2.7663001700286425],
					[61.804599999903587, 2.7663001994226479],
					[61.804699799532365, 2.7663001994538443],
					[61.804699800454678, 2.7664000001523474],
					[61.804699806720329, 2.7664000388459602],
					[61.804699835948284, 2.7664001071266004],
					[61.804699857211816, 2.7664001389624975],
					[61.804797056763547, 2.7665001382965189],
					[61.804797057741808, 2.7665001400007578],
					[61.804797059612355, 2.766500141523486],
					[61.804797060590566, 2.7665001432277321],
					[61.804899860965151, 2.7666001438022922],
					[61.80489989339948, 2.7666001638496067],
					[61.804899962300361, 2.7666001936076214],
					[61.804900000379739, 2.7666001991836833],
					[61.804999917053763, 2.7666001991641433],
					[61.805099858822885, 2.7667001408285072],
					[61.805199836411347, 2.7668001196047878],
					[61.805302622183831, 2.7670000918350266],
					[61.805302629923808, 2.7670001035835128],
					[61.805302647274274, 2.7670001286032782],
					[61.805302656798901, 2.7670001399887165],
					[61.805399856679806, 2.7671001391501049],
					[61.805399856679806, 2.7671001391501049],
					[61.805399857658017, 2.7671001408544202],
					[61.805399858550366, 2.7671001406728934],
					[61.805499799904474, 2.7672000833285795],
					[61.805499800004533, 2.7673000009062503],
					[61.805499829317966, 2.7673000710753519],
					[61.805499929316795, 2.7673001706415041],
					[61.805500000002205, 2.7673002000385654],
					[61.805599800388187, 2.7673001992001418],
					[61.805599799724199, 2.767500000892956],
					[61.805599803345999, 2.7675000210926055],
					[61.805599812631911, 2.7675000667863934],
					[61.805599819823058, 2.7675000862599846],
					[61.805697020203155, 2.7677000878131603],
					[61.805697021181395, 2.7677000895175041],
					[61.805697022159606, 2.7677000912218386],
					[61.805697023137839, 2.767700092926165],
					[61.805802622046819, 2.7679000908715059],
					[61.805899819747225, 2.7681000878302791],
					[61.805899829357621, 2.7681001011021333],
					[61.805899847772032, 2.7681001297131993],
					[61.805899859081229, 2.7681001407362027],
					[61.805999800400897, 2.7682000823872297],
					[61.805999799706981, 2.7682999997496394],
					[61.805999807756784, 2.7683000380823093],
					[61.80599983706972, 2.7683001082534981],
					[61.805999859311044, 2.7683001417964004],
					[61.806099717419634, 2.7684000004345313],
					[61.805999858626528, 2.7684998583402725],
					[61.805999836713866, 2.7684998913469343],
					[61.805999808401786, 2.7684999618183976],
					[61.805999800217691, 2.7684999996461954],
					[61.805999800197434, 2.7685999168397553],
					[61.805899858672262, 2.7686998590723579],
					[61.80589983667366, 2.7686998901928965],
					[61.805899808447357, 2.7686999625498183],
					[61.805899800263198, 2.7687000003774251],
					[61.805899800181642, 2.768899999655849],
					[61.805899799771126, 2.7690999190191281],
					[61.805802657006801, 2.7691998610573019],
					[61.805802635986282, 2.7691998938817886],
					[61.8058026066956, 2.7691999626477837],
					[61.805802600295983, 2.7692000001122108],
					[61.805802599965659, 2.7693000003964992],
					[61.805802600160199, 2.7693999149142541],
					[61.805697119558978, 2.7694998000858035],
					[61.805599999585247, 2.7694998006674374],
					[61.80559992879904, 2.7694998283848515],
					[61.805599828893754, 2.7694999286392057],
					[61.805599799688771, 2.7694999992902982],
					[61.805599799900698, 2.7695999169276777],
					[61.805499858648737, 2.7696998581566881],
					[61.805399858782103, 2.7697998582040997],
					[61.805399847696798, 2.769799871877892],
					[61.805399829095592, 2.7697998984996848],
					[61.805399819880854, 2.76979991369643],
					[61.805302620006906, 2.769999913222652],
					[61.805302612834375, 2.7699999337138772],
					[61.805302603122712, 2.7699999775607838],
					[61.805302599605426, 2.7699999992120672],
					[61.805302599648329, 2.7700999996047799],
					[61.805302599618265, 2.7702000000016933],
					[61.805302600407536, 2.7703000002214138],
					[61.805302600023531, 2.7703999169246258],
					[61.805199860943347, 2.7704998560863823],
					[61.805199860051026, 2.7704998562678029],
					[61.805199859244524, 2.7704998583350462],
					[61.805199859244524, 2.7704998583350462],
					[61.805099858917487, 2.770599858545935],
					[61.805099837004278, 2.7705998915501229],
					[61.805099807798712, 2.7705999621990185],
					[61.80509979961402, 2.7706000000251638],
					[61.805099800012599, 2.7706997997758229],
					[61.804999999995559, 2.7706998007764319],
					[61.804999961624823, 2.7706998085773686],
					[61.80499989083836, 2.7706998362914002],
					[61.804999858594265, 2.7706998599761192],
					[61.804899859887882, 2.7707998584911655],
					[61.804797061139375, 2.7708998564886795],
					[61.804797038333746, 2.7708998896737294],
					[61.804797008235674, 2.7708999605030433],
					[61.804797000136723, 2.7709000002145348],
					[61.804797000304056, 2.7709997989928641],
					[61.804700000318533, 2.7709998007071297],
					[61.804699928639643, 2.7709998286015867],
					[61.804699828733156, 2.7709999288479907],
					[61.804699799527356, 2.7709999994955785],
					[61.804699799703606, 2.7710997998477507],
					[61.804599999667268, 2.7710997994719833],
					[61.804500199716131, 2.7710998009632117],
					[61.804500200354823, 2.7709999991931338],
					[61.804500171043273, 2.7709999290226177],
					[61.804500071047016, 2.7709998294473075],
					[61.804500000362296, 2.7709998000424987],
					[61.804402879599287, 2.7709997996097089],
					[61.80429734140494, 2.7708998575046553],
					[61.804200142911661, 2.7707998612141145],
					[61.804200111198519, 2.7707998372098044],
					[61.804200038729078, 2.770799808168523],
					[61.804199999671589, 2.7707998008829713],
					[61.804099999656259, 2.7707998001748311],
					[61.804099962177865, 2.7707998077939382],
					[61.804099890584851, 2.7707998375739686],
					[61.804099859147229, 2.7707998591906926],
					[61.803999917326266, 2.7708998008219683],
					[61.803902800133791, 2.7708997998885909],
					[61.803902729433077, 2.7708998294867739],
					[61.803902628634276, 2.7708999299121686],
					[61.80390260032086, 2.7709000003765585],
					[61.803902599819551, 2.7710000000157216],
					[61.803902599930133, 2.7710999157793479],
					[61.803799860670395, 2.7711998572240666],
					[61.803799837778833, 2.7711998885220415],
					[61.80379980776641, 2.7711999612344482],
					[61.803799799667367, 2.771200000944555],
					[61.803799799835097, 2.7713000000799353],
					[61.803799799808402, 2.7713999174071771],
					[61.803699859466285, 2.7714998594839613],
					[61.803699836574687, 2.7714998907816013],
					[61.803699808260959, 2.7714999612449804],
					[61.803699800075989, 2.7714999990691527],
					[61.803699800106394, 2.771599999777004],
					[61.803699800063839, 2.7717000004890684],
					[61.803699799585559, 2.7718998002241988],
					[61.80359999968109, 2.7718998008296651],
					[61.803599928894307, 2.7718998285395116],
					[61.803599828987068, 2.7718999287792245],
					[61.803599799780741, 2.7718999994233879],
					[61.803599800407362, 2.7719999996408173],
					[61.803599800068675, 2.7721000000438352],
					[61.803599799656972, 2.7722000004510714],
					[61.803599800064632, 2.7723000006811831],
					[61.8035998004544, 2.7724999174564391],
					[61.803499859425571, 2.7725998590789303],
					[61.803499836533668, 2.7725998903755604],
					[61.803499808305162, 2.7725999627232101],
					[61.803499800119894, 2.7726000005468592],
					[61.8034998002314, 2.7727000004679119],
					[61.803499800182919, 2.7727998005784946],
					[61.803397200362561, 2.7727998000728582],
					[61.803299999671417, 2.7727998004516374],
					[61.803299928884421, 2.772799828159088],
					[61.803299828976463, 2.772799928394889],
					[61.803299799855495, 2.7728000009232256],
					[61.803299799725743, 2.7728998003874876],
					[61.8031999998625, 2.7728997995508204],
					[61.80319996238395, 2.7728998071670836],
					[61.803199890790403, 2.7728998369412436],
					[61.803199859352425, 2.7728998585551285],
					[61.803099917189186, 2.772999799580822],
					[61.803002880439735, 2.7729998002111249],
					[61.802897338225215, 2.7728998546265773],
					[61.80289730579171, 2.7728998345737215],
					[61.802897236977849, 2.7728998066888471],
					[61.802897199790998, 2.7728998009238497],
					[61.802799999985091, 2.7728998007129371],
					[61.802799929198095, 2.7728998284196336],
					[61.802799829290024, 2.7728999286533904],
					[61.802799800083179, 2.772899999294741],
					[61.802799799757267, 2.7729999532136831],
					[61.802699821224849, 2.7731999105083869],
					[61.80269981315945, 2.7731999311784516],
					[61.80269980264017, 2.7731999770876348],
					[61.802699800100484, 2.7732000004410811],
					[61.80269980045702, 2.7734000005991977],
					[61.802699799629281, 2.7736000009555095],
					[61.802699800208472, 2.7737999990803717],
					[61.802699799582051, 2.7739000002161163],
					[61.802699799689215, 2.7739999992891073],
					[61.802699799809183, 2.7741000002519773],
					[61.802699799650611, 2.7741999175238643],
					[61.80259985858801, 2.7742998598270132],
					[61.802599836588037, 2.7742998909401453],
					[61.802599808272788, 2.7742999613984418],
					[61.802599800087023, 2.7742999992205224],
					[61.802599799899191, 2.7743998000611936],
					[61.802502881274066, 2.7743998007085375],
					[61.802400139365822, 2.7742998577909126],
					[61.802400068681735, 2.7742998283801947],
					[61.80239992908075, 2.7742998281956317],
					[61.802399859357344, 2.7742998594887509],
					[61.802299917310805, 2.7743998006184496],
					[61.802199999622701, 2.7743997996073362],
					[61.802199962144037, 2.7743998072214513],
					[61.802199890550106, 2.7743998369910257],
					[61.802199859111866, 2.7743998586026422],
					[61.802099917064332, 2.7744998006074342],
					[61.801997200384122, 2.7744998007430866],
					[61.801899999786535, 2.7744997992515645],
					[61.801800000201659, 2.774499799251259],
					[61.801699999723837, 2.7744997994133933],
					[61.801699929022206, 2.7744998290009044],
					[61.80169982911287, 2.7744999292257155],
					[61.801699799905137, 2.7744999998630013],
					[61.80169979961952, 2.7745997996704741],
					[61.801602799983122, 2.7745998007519126],
					[61.801602729195679, 2.7745998284534754],
					[61.801602629286251, 2.7745999286776284],
					[61.801602600078454, 2.7745999993145949],
					[61.801602599885804, 2.7747000004982687],
					[61.80160260042674, 2.7747999996192569],
					[61.80160260008811, 2.7749000008113671],
					[61.80160260281643, 2.774900021190621],
					[61.801602612992191, 2.7749000666996335],
					[61.801602620182265, 2.7749000861725399],
					[61.801699799832569, 2.7751000466160796],
					[61.80169979981158, 2.7752000004532151],
					[61.801699799991688, 2.7752999999120811],
					[61.801699808039494, 2.7753000382413933],
					[61.801699837348799, 2.7753001084098803],
					[61.801699858610313, 2.7753001402490529],
					[61.801799799755486, 2.775400082249571],
					[61.8017997996402, 2.7756000000329859],
					[61.801799800424902, 2.7756999996533724],
					[61.801799800244325, 2.775799999459216],
					[61.801799799990718, 2.7758999992692655],
					[61.801799799749915, 2.7760000009691743],
					[61.801799799688183, 2.7761998006252226],
					[61.801700000191637, 2.7761998000873076],
					[61.801699928597209, 2.7761998298521786],
					[61.801699828600789, 2.7761999281858816],
					[61.801699800370287, 2.7762000005260048],
					[61.801699799820533, 2.7763000000270237],
					[61.801699800090155, 2.7763999993510451],
					[61.801699800372553, 2.7765000005648912],
					[61.801699808334227, 2.7765000370089177],
					[61.801699836836377, 2.7765001092454487],
					[61.801699858989956, 2.7765001409042487],
					[61.801799859422587, 2.7766001412628216],
					[61.801799891049008, 2.7766001633858224],
					[61.801799961732662, 2.7766001928013719],
					[61.801799999897575, 2.7766002002751593],
					[61.801899800216972, 2.7766002000296712],
					[61.801899800194711, 2.7767000002135362],
					[61.801899803008517, 2.7767000224787988],
					[61.801899813097926, 2.7767000661032282],
					[61.801899820373521, 2.7767000874623893],
					[61.801996999742137, 2.7769000456688437],
					[61.801997000205276, 2.7770000001875035],
					[61.801996999674799, 2.7772000008358981],
					[61.801997003466667, 2.777200024805734],
					[61.801997013555884, 2.7772000684304774],
					[61.801997021809527, 2.7772000914942674],
					[61.802099800155695, 2.7774000472019873],
					[61.802099800172662, 2.7775000001909378],
					[61.802099800475666, 2.7776000006879276],
					[61.802099807544742, 2.7776000373139151],
					[61.802099836938666, 2.7776001093712126],
					[61.802099859092031, 2.7776001410312285],
					[61.802199859417684, 2.777700140411183],
					[61.802299800287265, 2.7778000820735373],
					[61.802299800176428, 2.7778999995407263],
					[61.802299803075812, 2.7779000236920663],
					[61.802299813164851, 2.7779000673174701],
					[61.802299821332589, 2.7779000884960356],
					[61.802399821324144, 2.7781000890903935],
					[61.802399830127001, 2.7781001044308384],
					[61.802399850238324, 2.7781001307968456],
					[61.802399860740174, 2.7781001438892643],
					[61.802502600247259, 2.7782000844242702],
					[61.802502600310248, 2.7784000004595941],
					[61.802502599887639, 2.7785000004215994],
					[61.802502607042207, 2.77850003893397],
					[61.802502636264229, 2.7785001072217121],
					[61.802502656632669, 2.7785001392451214],
					[61.802599835380796, 2.7786001160824161],
					[61.802699800376885, 2.7788000459908471],
					[61.802699800290171, 2.7789000008121247],
					[61.80269982870535, 2.778900071167552],
					[61.80269992869615, 2.7789001707634324],
					[61.802700000271756, 2.7789002000039065],
					[61.802799914697459, 2.7789001991482269],
					[61.802897057213805, 2.7790001398106199],
					[61.802897088839842, 2.7790001619368474],
					[61.802897161307811, 2.7790001909965363],
					[61.802897200450708, 2.7790002001780532],
					[61.803002714819293, 2.7790002008103523],
					[61.803099857102644, 2.7791001386456005],
					[61.803099857188379, 2.7791001405313382],
					[61.803099858080714, 2.7791001403501827],
					[61.803099858973091, 2.779100140169021],
					[61.803199858938356, 2.7792001417333911],
					[61.803299800254784, 2.7793000835085677],
					[61.803299799813082, 2.7794000003427102],
					[61.803299802626483, 2.7794000226092543],
					[61.803299812715075, 2.7794000662365819],
					[61.803299819990265, 2.7794000875973812],
					[61.803397020125757, 2.7796000870266502],
					[61.803397021103855, 2.7796000887312395],
					[61.80339702208191, 2.7796000904358387],
					[61.80339702208191, 2.7796000904358387],
					[61.803499821797168, 2.7798000905858404],
					[61.803499858742072, 2.7798001306650892],
					[61.803499947665763, 2.7798001849340293],
					[61.803499999644494, 2.7798001991237138],
					[61.80359987619557, 2.7798001992275272],
					[61.803699800224265, 2.7800000465940022],
					[61.803699800025228, 2.7801000007708621],
					[61.803699807986028, 2.7801000372181996],
					[61.803699837378716, 2.7801001092816522],
					[61.803699858639177, 2.7801001411262134],
					[61.803799859465904, 2.7802001418149862],
					[61.803799858573555, 2.7802001419961173],
					[61.803799860443959, 2.780200143519624],
					[61.803799861336337, 2.7802001433384871],
					[61.803902599830764, 2.7803000837873966],
					[61.803902600384241, 2.7804000003786107],
					[61.803902606560243, 2.78040003718854],
					[61.803902635867068, 2.7804001073669662],
					[61.803902657127473, 2.7804001392119382],
					[61.803999856805611, 2.7805001390779216],
					[61.803999889323848, 2.7805001610253059],
					[61.803999960984846, 2.7805001921563637],
					[61.804000000041981, 2.7805001994542633],
					[61.804099799997523, 2.7805002001663253],
					[61.804099799561293, 2.7805999995835098],
					[61.804099807607663, 2.7806000379172624],
					[61.804099836914389, 2.7806001080963259],
					[61.804099859152814, 2.7806001416463171],
					[61.804199800088924, 2.7807000830787403],
					[61.804199800363214, 2.7808000006546454],
					[61.804199828777485, 2.7808000710152458],
					[61.804199928767027, 2.7808001706220029],
					[61.80420000034227, 2.7808001998681511],
					[61.804297000337399, 2.7808002007613206],
					[61.804296999690955, 2.7809000008325553],
					[61.80429700773724, 2.780900039166617],
					[61.804297038999955, 2.7809001127558024],
					[61.804297062044952, 2.7809001442393102],
					[61.80440265918152, 2.7810001420673629],
					[61.804499799710058, 2.7811000811938809],
					[61.804499799705312, 2.7811999997636057],
					[61.804499807751526, 2.7812000380980195],
					[61.804499837057946, 2.7812001082785698],
					[61.804499859296236, 2.7812001418294265],
					[61.804599799662959, 2.7813000826976406],
					[61.804599800219442, 2.781500000257406],
					[61.8045998082656, 2.7815000385920214],
					[61.8045998366795, 2.7815001089541909],
					[61.804599858832042, 2.7815001406195505],
					[61.804699799888525, 2.7816000826034442],
					[61.804699800334262, 2.7816999997786271],
					[61.804699803147294, 2.7817000220464099],
					[61.804699813235175, 2.7817000656765023],
					[61.80469981961766, 2.7817000872199067],
					[61.804797015400048, 2.7819000765977093],
					[61.804899800420088, 2.7822000327442291],
					[61.804899799987467, 2.7823000001712415],
					[61.804899800046208, 2.7823999998429003],
					[61.804899802859126, 2.7824000221109007],
					[61.804899813032478, 2.782400067627377],
					[61.804899821199577, 2.7824000888089166],
					[61.804999820782932, 2.7826000892861886],
					[61.80509982089729, 2.7828000889983184],
					[61.805099828721609, 2.7828001026366778],
					[61.805099847939715, 2.7828001291891029],
					[61.805099859247846, 2.7828001402173101],
					[61.805199800064152, 2.78290008218669],
					[61.80519980038963, 2.782999998978009],
					[61.805199807628668, 2.7830000393807111],
					[61.805199837912291, 2.7830001112693896],
					[61.805199860956833, 2.7830001427553901],
					[61.8053026346455, 2.7831001199197249],
					[61.805399799990219, 2.7833000454183865],
					[61.805399799961165, 2.7834000009575788],
					[61.805399802773948, 2.7834000232260543],
					[61.805399812946973, 2.783400068743596],
					[61.805399821113951, 2.7834000899257432],
					[61.805499799965212, 2.7836000475470648],
					[61.805499799550184, 2.7836999996529115],
					[61.805499800311544, 2.7837999990912121],
					[61.805499808357055, 2.7838000374275809],
					[61.805499836855525, 2.7838001096798464],
					[61.805499859007561, 2.7838001413477698],
					[61.805599799871956, 2.7839000838917718],
					[61.805599800291816, 2.7839999999609035],
					[61.805599799946066, 2.7840999999188072],
					[61.805599799927975, 2.7842999996662288],
					[61.805599800255663, 2.7843999994557485],
					[61.80559980740864, 2.7844000379734362],
					[61.805599835735471, 2.7844001064547217],
					[61.805599856994988, 2.784400138304215],
					[61.80569700015181, 2.7845000815043517],
					[61.805697000336771, 2.7846999999201509],
					[61.805697000376611, 2.7848000000433393],
					[61.805697000237387, 2.7850000003023063],
					[61.805696999806244, 2.7852000005781266],
					[61.805697000373591, 2.7853000005413575],
					[61.805697000283821, 2.7854999188434904],
					[61.805599857179381, 2.7855998616435254],
					[61.805599836069135, 2.7855998925708647],
					[61.805599806770033, 2.7855999613212066],
					[61.805599800451517, 2.7856000006681372],
					[61.805599799762078, 2.7856998006828837],
					[61.805500000368284, 2.7856998003116495],
					[61.805400047008817, 2.7856998006958706],
					[61.80520008861852, 2.785599821088101],
					[61.80520006847258, 2.7855998137545268],
					[61.805200022997816, 2.7855998039450927],
					[61.80520000026042, 2.7855997990403689],
					[61.805100000071938, 2.7855997996759094],
					[61.80499999988286, 2.7855998002926343],
					[61.804999962403407, 2.7855998078939983],
					[61.804999890806748, 2.785599837640691],
					[61.80499985936666, 2.7855998592430415],
					[61.804899860311266, 2.7856998570416733],
					[61.80479711910094, 2.7857997993487897],
					[61.8046999998774, 2.7857997992669192],
					[61.804699929173033, 2.785799828831824],
					[61.804699829255107, 2.7857999290306772],
					[61.804699800041497, 2.7857999996646132],
					[61.804699800232228, 2.7858998003509501],
					[61.804600082800711, 2.7858997996643069],
					[61.804500140926436, 2.7857998597914451],
					[61.804500109301394, 2.7857998376570818],
					[61.804500037727017, 2.7857998083989748],
					[61.804500000454851, 2.7857998007322955],
					[61.804402800145667, 2.7857997999113984],
					[61.804297200074132, 2.7857997992041654],
					[61.804297129369779, 2.7857998287686265],
					[61.804297028559425, 2.7857999291470636],
					[61.804297000238186, 2.7857999995990745],
					[61.804296999519615, 2.7858997991561716],
					[61.804200000087228, 2.7858997991130305],
					[61.804099999965587, 2.7858998004685622],
					[61.804099929175599, 2.7858998281467637],
					[61.804099829257517, 2.7858999283432317],
					[61.804099800129507, 2.7859000008614609],
					[61.804099800222176, 2.7859997996003565],
					[61.80399999999981, 2.7859998000386375],
					[61.803902799765098, 2.7859998003785966],
					[61.803799999659411, 2.7859998001180455],
					[61.803700000337813, 2.7859997990057104],
					[61.803699962051631, 2.785999808672913],
					[61.803699891261566, 2.7859998363504794],
					[61.803699858928987, 2.7859998581324206],
					[61.803599859037917, 2.7860998597728055],
					[61.803599837035179, 2.7860998908786825],
					[61.803599807821378, 2.786099961509751],
					[61.80359979963233, 2.7860999993301339],
					[61.803599799797141, 2.7862000000391673],
					[61.803599799754871, 2.7863997997675898],
					[61.803499999598252, 2.786399800696759],
					[61.803499928808137, 2.7863998283732125],
					[61.803499828889635, 2.7863999285659813],
					[61.803499799675706, 2.7863999991965298],
					[61.803499799617327, 2.7864999995922983],
					[61.803499800311414, 2.7865997999954324],
					[61.803397200252626, 2.7865998008499524],
					[61.803299999989711, 2.7865997991790254],
					[61.803199999918682, 2.786599799969697],
					[61.803099999847056, 2.786599800741544],
					[61.803002799667993, 2.7865998009020672],
					[61.802897199723049, 2.7865998009351092],
					[61.802800000349492, 2.7865997989919129],
					[61.802799962063233, 2.7865998086580221],
					[61.802799891273033, 2.7865998363332793],
					[61.802799858940361, 2.7865998581138984],
					[61.802699858713936, 2.7866998584931699],
					[61.802599917031756, 2.7867998006453738],
					[61.802502799710147, 2.7867998004067296],
					[61.802400083407598, 2.7867997992259768],
					[61.802300163782839, 2.7866998816030453],
					[61.802200178645045, 2.786499911346473],
					[61.802100199991983, 2.786299952438656],
					[61.802100200148011, 2.7862000003453375],
					[61.802100197250105, 2.7861999761932852],
					[61.802100186185704, 2.7861999308605174],
					[61.802100178019174, 2.7861999096798282],
					[61.801997379153512, 2.7859999114122593],
					[61.801900199700071, 2.7857999547994186],
					[61.801900200220921, 2.7856999994263121],
					[61.80190019740855, 2.7856999771601401],
					[61.801900187236335, 2.7856999316469007],
					[61.801900179069733, 2.785699910466485],
					[61.801800200027365, 2.7854999521788906],
					[61.801800199526809, 2.785399999210791],
					[61.801800192459766, 2.7853999625832633],
					[61.801800163069892, 2.785399890518919],
					[61.801800140918267, 2.7853998588534821],
					[61.801700200071814, 2.785299917547376],
					[61.80170020007872, 2.7851999990362923],
					[61.801700197351934, 2.7851999786559714],
					[61.801700187179549, 2.7851999331431987],
					[61.801700179990895, 2.7851999136677383],
					[61.80160298007295, 2.7849999121784639],
					[61.801602971270825, 2.7849998968363825],
					[61.801602949204785, 2.7849998670570772],
					[61.801602937811211, 2.7849998541435568],
					[61.8014974003978, 2.784899913632195],
					[61.801497400292831, 2.7847999995695911],
					[61.801497399830289, 2.7846999999331792],
					[61.801497400187081, 2.7846000001199993],
					[61.801497393034211, 2.7845999616073835],
					[61.80149736381523, 2.7845998933158262],
					[61.80149734344814, 2.7845998612892875],
					[61.801400199997694, 2.7844999193667364],
					[61.801400200382503, 2.7843000003157869],
					[61.801400196591878, 2.7842999763454621],
					[61.801400187397249, 2.7842999325380977],
					[61.801400179230477, 2.7842999113583975],
					[61.801300178889583, 2.7840999115557037],
					[61.801200179079679, 2.7838999109879725],
					[61.801200171255473, 2.7838998973510214],
					[61.801200153015643, 2.7838998725057409],
					[61.80120014260001, 2.7838998612974315],
					[61.801102964411633, 2.7837998819098457],
					[61.801000178079903, 2.783599910199118],
					[61.800900185237531, 2.7833999230672841],
					[61.800800190347182, 2.7830999361687341],
					[61.800800180395484, 2.7830999153518254],
					[61.800800155223591, 2.7830998766897461],
					[61.800800140003382, 2.7830998588445817],
					[61.800597365301478, 2.7828998822396378],
					[61.800500180104002, 2.7826999125174305],
					[61.800500180104002, 2.7826999125174305],
					[61.800500179125955, 2.7826999108128923],
					[61.800400199631881, 2.7824999535364521],
					[61.800400200399856, 2.7824000007595928],
					[61.800400192354033, 2.7823999624299063],
					[61.800400162962632, 2.7823998903717602],
					[61.800400140810368, 2.782399858709895],
					[61.800300141532979, 2.7822998593139068],
					[61.800202999683314, 2.7821999182975983],
					[61.800202999998305, 2.7821000004778282],
					[61.800202991866712, 2.7820999602629173],
					[61.800202960690413, 2.7820998885676076],
					[61.800202937560059, 2.7820998552016669],
					[61.8000974002018, 2.7819999134400959],
					[61.800097399654369, 2.7818999999247351],
					[61.800097396841409, 2.7818999776602418],
					[61.80009738675367, 2.7818999340365211],
					[61.800097380371263, 2.7818999124962511],
					[61.800000200298008, 2.7816999535811053],
					[61.800000200413514, 2.7816000002552475],
					[61.800000192367477, 2.7815999619262795],
					[61.800000163061348, 2.7815998917553855],
					[61.800000140823244, 2.7815998582089576],
					[61.799900200228066, 2.7814999172685031],
					[61.799900200045307, 2.7814000004641053],
					[61.799900191999207, 2.7813999621353336],
					[61.799900162693, 2.7813998919648393],
					[61.79990014134718, 2.7813998582376227],
					[61.799800164737462, 2.781299881777263],
					[61.79970300037764, 2.781099953785243],
					[61.79970299998223, 2.7809999995671602],
					[61.799702991936016, 2.7809999612387282],
					[61.799702961651576, 2.7809998893646242],
					[61.799702938606636, 2.7809998578855732],
					[61.799600200228376, 2.7808999166167814],
					[61.799600199620699, 2.7808000008144482],
					[61.799600191574434, 2.780799962486201],
					[61.799600163074587, 2.7807998902503663],
					[61.79960014092201, 2.780799858590481],
					[61.799500199619949, 2.7806999177455203],
					[61.799500200438153, 2.7805000006975744],
					[61.799500192391818, 2.7804999623695461],
					[61.7995001629995, 2.7804998903153177],
					[61.799500140846796, 2.780499858655761],
					[61.79940020014881, 2.7803999170407132],
					[61.799400199991538, 2.7802999998440194],
					[61.799400199645355, 2.7802000008505852],
					[61.799400191598934, 2.7801999625227483],
					[61.799400163098795, 2.7801998902879683],
					[61.799400140946027, 2.7801998586287144],
					[61.799300199959731, 2.7800999164246774],
					[61.79930019958789, 2.7799999995662277],
					[61.799300200092034, 2.7798000000128691],
					[61.799300192045493, 2.7797999616852658],
					[61.799300161760428, 2.7797998898133214],
					[61.799300138629519, 2.7797998564500759],
					[61.799197400163273, 2.7796999165310714],
					[61.799197400396416, 2.7795999998430454],
					[61.799197400354473, 2.7794999994733685],
					[61.79919739328593, 2.7794999628503452],
					[61.799197363978813, 2.7794998926833014],
					[61.799197342718266, 2.7794998608436416],
					[61.799100200165398, 2.7793999186378908],
					[61.799100200098124, 2.7793000003935089],
					[61.799100200403899, 2.7791000001359949],
					[61.799100200417719, 2.7788999998953385],
					[61.799100192370929, 2.7788999615682362],
					[61.799100163063478, 2.7788998914020069],
					[61.799100140910483, 2.7788998597439947],
					[61.799000199676613, 2.7787999169114155],
					[61.799000200154033, 2.7787000007222242],
					[61.799000199562478, 2.7783999994197104],
					[61.799000171147163, 2.7783999290730295],
					[61.799000071156172, 2.7783998294901067],
					[61.798999999580495, 2.7783998002538501],
					[61.798900200317171, 2.7783997995772767],
					[61.798900200296828, 2.7782000003031566],
					[61.798900196590907, 2.77819997822106],
					[61.798900187394402, 2.7781999344191473],
					[61.79890018011907, 2.7781999130616604],
					[61.798803000280422, 2.7779999536343669],
					[61.798803000252683, 2.777900000049466],
					[61.798802999878895, 2.7778000008554966],
					[61.79880300023877, 2.7776999995991165],
					[61.798802992105927, 2.7776999593872591],
					[61.798802960927432, 2.7776998876997574],
					[61.798802937796054, 2.7776998543386395],
					[61.798697400271813, 2.7775999144816601],
					[61.79869739988689, 2.7774999993288669],
					[61.798697397073219, 2.7774999770658302],
					[61.798697386984131, 2.777499933445617],
					[61.798697379794433, 2.7774999139738883],
					[61.798600199699571, 2.7772999541544774],
					[61.798600199976264, 2.7771999991929963],
					[61.798600197162557, 2.7771999769300604],
					[61.798600186987628, 2.7771999314246298],
					[61.79860017881979, 2.7771999102487732],
					[61.798500200209077, 2.7769999531024352],
					[61.798500200356926, 2.7769000003647228],
					[61.798500192309596, 2.7768999620389136],
					[61.798500163001172, 2.7768998918759942],
					[61.798500140761931, 2.7768998583346245],
					[61.79840019970252, 2.7767999179219482],
					[61.798400200359836, 2.7766999996372603],
					[61.798400193204834, 2.7766999611304359],
					[61.79840016398208, 2.7766998928533564],
					[61.798400142720922, 2.7766998610164855],
					[61.798302999539331, 2.7765999185673125],
					[61.798303000054887, 2.7765000006075136],
					[61.798302991921723, 2.776499960396638],
					[61.798302961634981, 2.7764998885303136],
					[61.798302938589082, 2.776499857056077],
					[61.798200199885422, 2.7763999157512775],
					[61.798200200345214, 2.7763000000200093],
					[61.798200171036534, 2.7762999298583404],
					[61.798200070958359, 2.776299828399369],
					[61.798200000360424, 2.776299800872899],
					[61.798100082910203, 2.7762998004460218],
					[61.798000141281207, 2.776199859469457],
					[61.797900200393713, 2.7760999170625995],
					[61.797900199669272, 2.776000000616484],
					[61.797900191535945, 2.7759999604062746],
					[61.797900162141318, 2.7759998883601877],
					[61.797900139095297, 2.7759998568867306],
					[61.797797340585653, 2.7758998582822261],
					[61.797700142837563, 2.7757998608218877],
					[61.797700142837563, 2.7757998608218877],
					[61.79770014185943, 2.7757998591177264],
					[61.797700140967095, 2.7757998592989384],
					[61.797600141230419, 2.7756998587231547],
					[61.797500142406648, 2.7755998604886112],
					[61.797402943398758, 2.775499861315494],
					[61.797402942420646, 2.7754998596113527],
					[61.797402938679667, 2.7754998565655025],
					[61.79740293770157, 2.7754998548613581],
					[61.797297340684501, 2.7753998588128859],
					[61.797200143330876, 2.7752998605428445],
					[61.797200143330876, 2.7752998605428445],
					[61.797200142352729, 2.7752998588387094],
					[61.79720014056803, 2.7752998592011568],
					[61.797100141312079, 2.7751998597440908],
					[61.797100108793167, 2.7751998378068756],
					[61.797100038109285, 2.7751998083984484],
					[61.797099999944308, 2.7751998009272181],
					[61.797000200375216, 2.7751998008726941],
					[61.797000199631398, 2.7750999995355476],
					[61.797000171214442, 2.775099929196521],
					[61.797000071221291, 2.775099829630598],
					[61.796999999645017, 2.7750998004036989],
					[61.796903000045944, 2.7750998009314585],
					[61.796903000125695, 2.7749999997333754],
					[61.796902970816369, 2.7749999295758885],
					[61.796902870737334, 2.7749998281252539],
					[61.796902800139186, 2.7749998006027807],
					[61.79680008259794, 2.7749998001261784],
					[61.796700199977707, 2.7748999161575796],
					[61.796700200041805, 2.7747999995356598],
					[61.796700191993928, 2.7747999612126564],
					[61.796700162684495, 2.7747998910558191],
					[61.796700141422946, 2.7747998592219854],
					[61.79660014077723, 2.7746998593606707],
					[61.796500164468419, 2.7745998818184407],
					[61.796397378956691, 2.7743999109748745],
					[61.796300180041705, 2.7741999121679588],
					[61.796300143095301, 2.7741998721044387],
					[61.796300053105831, 2.7741998142740503],
					[61.796300000234325, 2.7741998002777564],
					[61.796200082603647, 2.7741998006741362],
					[61.796100200176184, 2.7740999172307323],
					[61.796100199630473, 2.7739000003361123],
					[61.79610019336706, 2.7738999616516042],
					[61.796100164142992, 2.7738998933822465],
					[61.796100142881237, 2.7738998615496477],
					[61.796002943130503, 2.7737998606443122],
					[61.796002942152384, 2.7737998589402997],
					[61.796002939303754, 2.7737998557135257],
					[61.796002938325614, 2.773799854009515],
					[61.79589739987771, 2.7736999131217148],
					[61.795897400078587, 2.7736000008678765],
					[61.795897392922754, 2.7735999623649308],
					[61.795897363612745, 2.7735998922110654],
					[61.795897343243304, 2.7735998601976091],
					[61.795800200008706, 2.7734999197571626],
					[61.795800199724653, 2.7734000002871335],
					[61.795800196824551, 2.7733999761413437],
					[61.795800186734226, 2.7733999325266154],
					[61.795800179458183, 2.7733999111724219],
					[61.795700178863598, 2.7731999117490544],
					[61.795600199598447, 2.7729999529994385],
					[61.795600200090682, 2.7728999997234007],
					[61.795600192934664, 2.772899961220999],
					[61.795600163710127, 2.7728998929537219],
					[61.795600143340515, 2.7728998609410422],
					[61.795503000355488, 2.7727999197072317],
					[61.795503000382638, 2.7726000006126825],
					[61.795502992248366, 2.7725999604065352],
					[61.795502961959741, 2.7725998885505678],
					[61.795502938912996, 2.7725998570820636],
					[61.795400200259493, 2.7724999161094646],
					[61.795400200241083, 2.7723999996877513],
					[61.795400199943373, 2.7722999995950413],
					[61.795400199572605, 2.7721999995065492],
					[61.795400196758131, 2.7721999772464185],
					[61.795400186581602, 2.7721999317474308],
					[61.795400179305403, 2.7721999103938075],
					[61.795300179460497, 2.7719999113130216],
					[61.79520020005301, 2.7717999530859161],
					[61.795200199692815, 2.7715999996850598],
					[61.795200196878255, 2.7715999774251325],
					[61.795200186701535, 2.7715999319266484],
					[61.795200179425265, 2.7715999105732978],
					[61.795100200265153, 2.7713999522490149],
					[61.795100199611113, 2.7713000006687554],
					[61.795100199953239, 2.7711000001061379],
					[61.795100197052754, 2.7710999759611181],
					[61.795100185983543, 2.7710999306442958],
					[61.795100177814795, 2.7710999094724831],
					[61.794997399690942, 2.7708999513010721],
					[61.794997400081961, 2.7705999998634745],
					[61.794997397267231, 2.7705999776037955],
					[61.794997387176018, 2.7705999339911513],
					[61.794997379899556, 2.7705999126381977],
					[61.794900180112784, 2.77039991322706],
					[61.794900180026936, 2.7703999113418725],
					[61.794900179134586, 2.7703999115232234],
					[61.794800199824273, 2.7701999530887185],
					[61.794800199753702, 2.7699999999995764],
					[61.794800200320552, 2.7698999995338283],
					[61.794800192271367, 2.7698999612145303],
					[61.794800162959532, 2.7698998910667574],
					[61.79480014080459, 2.76989985941962],
					[61.794700199683348, 2.7697999163586586],
					[61.794700200316406, 2.7696999999003613],
					[61.794700199776145, 2.7695999999544516],
					[61.794700196961251, 2.7695999776950906],
					[61.794700186869733, 2.7695999340832276],
					[61.794700179593157, 2.7695999127306954],
					[61.794602999845225, 2.7693999540511909],
					[61.794603000322461, 2.769200000066347],
					[61.794602971010292, 2.7691999299197021],
					[61.794602870927335, 2.7691998284953865],
					[61.794602800242352, 2.7691997991029123],
					[61.794497400132904, 2.7691997997353623],
					[61.794497400220116, 2.7691000000456749],
					[61.794497400142788, 2.7690000006044673],
					[61.794497392985704, 2.7689999621044046],
					[61.794497363759319, 2.7689998938433455],
					[61.794497343388834, 2.7689998618343998],
					[61.794400199663002, 2.7688999189867514],
					[61.794400200080709, 2.7687000004794222],
					[61.794400170682437, 2.7686999284485703],
					[61.794400070684958, 2.7686998289116995],
					[61.794399999999889, 2.7686997995205496],
					[61.794300199744107, 2.7686997993560731],
					[61.794300199847839, 2.7684999994844746],
					[61.794300191798293, 2.7684999611661989],
					[61.794300163378111, 2.7684998908395007],
					[61.794300141222863, 2.7684998591939047],
					[61.794200199792158, 2.7683999164475073],
					[61.794200200231657, 2.7682999996093618],
					[61.794200193074381, 2.7682999611098444],
					[61.794200163847634, 2.7682998928501199],
					[61.794200142584664, 2.7682998610233507],
					[61.794103000086444, 2.7681999190860029],
					[61.794103000298335, 2.7681000006875669],
					[61.794102992162784, 2.7680999604845007],
					[61.794102961871893, 2.7680998886363088],
					[61.794102938824167, 2.7680998571726225],
					[61.794000163960199, 2.7679998796984435],
					[61.793900200110002, 2.7677999520856913],
					[61.793900200277619, 2.7677000000693139],
					[61.793900192227852, 2.7676999617517364],
					[61.793900162914959, 2.7676998916081326],
					[61.793900140759519, 2.7676998599635043],
					[61.793800200406302, 2.767599916949651],
					[61.79380020019331, 2.7674999995622311],
					[61.793800197378069, 2.7674999773037308],
					[61.793800187200041, 2.7674999318087266],
					[61.793800179030789, 2.7674999106387768],
					[61.793700178670633, 2.7672999114333225],
					[61.793700178584757, 2.7672999095482029],
					[61.793700177692394, 2.767299909729636],
					[61.79359737781963, 2.7670999083102008],
					[61.793597370079723, 2.7670998965659943],
					[61.793597352729364, 2.7670998715553368],
					[61.793597343204809, 2.7670998601739951],
					[61.793500199644598, 2.7669999182820413],
					[61.79350019978483, 2.7667999995974104],
					[61.793500191734836, 2.766799961280574],
					[61.79350016331383, 2.7667998909572895],
					[61.793500141158191, 2.7667998593137404],
					[61.793400200029033, 2.7666999167250319],
					[61.793400200068845, 2.766600000496231],
					[61.793400192018758, 2.7665999621795798],
					[61.793400162619413, 2.7665998901530964],
					[61.793400141356081, 2.7665998583283238],
					[61.793300200093064, 2.7664999167059952],
					[61.793300199991201, 2.7664000008111498],
					[61.793300197175796, 2.7663999785531201],
					[61.793300186997421, 2.7663999330592364],
					[61.793300179806216, 2.7663999135935486],
					[61.793203000123945, 2.7661999542396392],
					[61.793202999874104, 2.7661000006095229],
					[61.793202996972767, 2.7660999764665095],
					[61.793202984923717, 2.7660999294507422],
					[61.793202976668333, 2.7660999063964971],
					[61.793097377769534, 2.765899910354336],
					[61.793000186117816, 2.7656999238597924],
					[61.792900190360527, 2.7653999377651082],
					[61.792900152896351, 2.7653998864062403],
					[61.792900057926424, 2.7653998182047772],
					[61.792900000334825, 2.76539979947714],
					[61.792800199779599, 2.7653998003343272],
					[61.79280020012142, 2.7651999999909922],
					[61.792800197305823, 2.7651999777334466],
					[61.792800187212968, 2.7651999341257549],
					[61.792800179935696, 2.7651999127756275],
					[61.792702980208695, 2.7649999128584661],
					[61.792702971404459, 2.7649998975265491],
					[61.79270295120493, 2.7649998692921796],
					[61.792702938917294, 2.7649998565712499],
					[61.792600199936267, 2.7648999166152044],
					[61.792600199592997, 2.7646999996550856],
					[61.792600191542405, 2.7646999613399483],
					[61.792600163120397, 2.7646998910206984],
					[61.792600140964282, 2.7646998593795846],
					[61.792500199779354, 2.7645999166893334],
					[61.792500199938253, 2.7644999993457038],
					[61.792500191973573, 2.7644999629157776],
					[61.792500162573184, 2.7644998908934095],
					[61.792500141309382, 2.7644998590710359],
					[61.792400199990588, 2.7643999173471228],
					[61.792400199740918, 2.7641999996652924],
					[61.792400171318668, 2.7641999293469661],
					[61.79240007131817, 2.7641998298310542],
					[61.792399999739956, 2.7641998006334823],
					[61.792300122438895, 2.7641997999911254],
					[61.79219737824895, 2.7639999086026608],
					[61.792197369530562, 2.7639998951562732],
					[61.792197353157924, 2.763999871851984],
					[61.792197342740778, 2.7639998606536298],
					[61.792100200064972, 2.7638999190835878],
					[61.792100199558334, 2.7637999993015354],
					[61.792100171135893, 2.763799928984263],
					[61.792100071135145, 2.7637998294706021],
					[61.792100000449189, 2.7637998000926749],
					[61.792000199698869, 2.7637998003461157],
					[61.792000200075378, 2.7637000005557049],
					[61.792000197173593, 2.7636999764138883],
					[61.792000187080305, 2.7636999328078478],
					[61.792000178910477, 2.7636999116401588],
					[61.7919001793259, 2.7634999105664071],
					[61.791900160104341, 2.7634998840372207],
					[61.791900114866273, 2.7634998399709993],
					[61.791900087871539, 2.763499820730499],
					[61.791697399728378, 2.7633998760477936],
					[61.791697399872241, 2.7632999992488547],
					[61.791697399797329, 2.7632000007269273],
					[61.791697371288656, 2.7631999285261637],
					[61.791697271287525, 2.7631998290156949],
					[61.791697199709134, 2.7631997998210385],
					[61.791600199827215, 2.763199800986154],
					[61.791600199697513, 2.7631000006365194],
					[61.791600191646488, 2.763099962323079],
					[61.791600163137772, 2.7630998901226174],
					[61.791600140981316, 2.7630998584836672],
					[61.791500200173715, 2.7629999162344139],
					[61.79150020001422, 2.76290000014682],
					[61.791500199569704, 2.7628000003992397],
					[61.79150017106079, 2.7627999281993052],
					[61.791500071059417, 2.7627998286907682],
					[61.791500000373297, 2.7627997993156663],
					[61.791400200467876, 2.7627998006407046],
					[61.791400199969267, 2.7626999990732144],
					[61.791400200275291, 2.7625999994783279],
					[61.791400196566983, 2.7625999774035961],
					[61.791400187365646, 2.7625999336172242],
					[61.791400180087976, 2.7625999122686946],
					[61.791302979663698, 2.7623999138627817],
					[61.791302978685408, 2.7623999121593825],
					[61.791302977707161, 2.7623999104559926],
					[61.791302977621207, 2.7623999085710569],
					[61.791200200409314, 2.7621999521682694],
					[61.79120019983057, 2.7621000009140562],
					[61.791200196928543, 2.7620999767730243],
					[61.791200186748782, 2.7620999312837373],
					[61.791200178664695, 2.7620999120019554],
					[61.791100199923477, 2.7618999527927994],
					[61.791100199936444, 2.761799999810354],
					[61.791100197120294, 2.7617999775543827],
					[61.79110018694044, 2.761799932065343],
					[61.79110017877035, 2.7617999108987652],
					[61.79100017880986, 2.7615999118995287],
					[61.790900179294098, 2.7613999102499114],
					[61.790900169683098, 2.7613998969864153],
					[61.790900150375194, 2.7613998685744852],
					[61.790900139065549, 2.7613998575590326],
					[61.790797400389195, 2.76129991574774],
					[61.790797399825728, 2.7611999999529915],
					[61.790797397009527, 2.7611999776972711],
					[61.790797386915436, 2.761199934093796],
					[61.790797379637581, 2.7611999127460178],
					[61.790700200323386, 2.7609999541629415],
					[61.790700199610562, 2.7609000006360422],
					[61.790700196708329, 2.7608999764955464],
					[61.790700186614167, 2.7608999328923067],
					[61.790700179336255, 2.7608999115446702],
					[61.790600200460048, 2.7606999534897598],
					[61.790600199952024, 2.7604999992680881],
					[61.790600199264247, 2.760499984188963],
					[61.790600194233399, 2.7604999528722218],
					[61.790600189890341, 2.7604999366346052],
					[61.790500200242334, 2.7601999686574463],
					[61.790500200109712, 2.7599999996871367],
					[61.790500197293305, 2.7599999774317685],
					[61.790500187198866, 2.759999933829123],
					[61.790500179920812, 2.7599999124818284],
					[61.790402999561998, 2.7597999538440332],
					[61.790403000433813, 2.7596000004381085],
					[61.790403000131626, 2.7595000000897207],
					[61.790402991993645, 2.7594999598938701],
					[61.790402960806105, 2.7594998882445263],
					[61.790402937670585, 2.7594998549061445],
					[61.790297400092598, 2.7593999140929886],
					[61.79029739994899, 2.7592999996426935],
					[61.790297400324562, 2.7591999994693568],
					[61.790297399734811, 2.7590999994818874],
					[61.790297392661031, 2.7590999628743891],
					[61.790297364236245, 2.7590998925656303],
					[61.790297342971257, 2.7590998607492647],
					[61.790200199614489, 2.7589999192690948],
					[61.790200199817562, 2.7588999993158438],
					[61.790200199905215, 2.7587999994760528],
					[61.790200191853032, 2.7587999611655203],
					[61.790200163428132, 2.7587998908572766],
					[61.790200141270745, 2.7587998592228531],
					[61.790100200353798, 2.7586999177575611],
					[61.790100200428249, 2.7586000000273261],
					[61.790100200301126, 2.7585000005259674],
					[61.790100197398495, 2.7584999763861888],
					[61.79010018730358, 2.7584999327846087],
					[61.790100179132999, 2.758499911619626],
					[61.790000179066432, 2.7582999100368366],
					[61.790000171239825, 2.7582998964113532],
					[61.790000152995631, 2.758299871590256],
					[61.790000142578059, 2.7582998603946041],
					[61.789903000293904, 2.7581999182597712],
					[61.789902999925978, 2.7580999993076527],
					[61.789902991873603, 2.7580999609976793],
					[61.789902961577702, 2.7580998891692681],
					[61.789902939420159, 2.7580998575356563],
					[61.789800199698171, 2.7579999164035289],
					[61.789800200167036, 2.7578999994976314],
					[61.789800192114619, 2.7578999611878388],
					[61.789800162796929, 2.757899891063027],
					[61.789800140639379, 2.7578998594296746],
					[61.789700163952141, 2.7577998817603393],
					[61.789600179384166, 2.7575999102312396],
					[61.789500178801156, 2.7573999108678873],
					[61.789500178801156, 2.7573999108678873],
					[61.789500177822809, 2.7573999091647496],
					[61.789397384672355, 2.757199922274816],
					[61.789300189877679, 2.7568999372237109],
					[61.789300179922201, 2.7568999164231101],
					[61.789300155635701, 2.7568998776151816],
					[61.789300141304672, 2.7568998596078589],
					[61.789200199576612, 2.756799917562514],
					[61.78920020000178, 2.7567000005936149],
					[61.789200199227565, 2.7566999836304968],
					[61.789200194195878, 2.7566999523158424],
					[61.789200189852401, 2.7566999360795172],
					[61.789100189632052, 2.7563999378329873],
					[61.789100153057007, 2.7563998863096617],
					[61.789100058083086, 2.7563998181440716],
					[61.789099999598143, 2.7563997996170109],
					[61.789002999926502, 2.7563998008771611],
					[61.789002999814777, 2.7562000006657081],
					[61.789002991675851, 2.756199960472602],
					[61.789002961379033, 2.7561998886481738],
					[61.789002938242739, 2.7561998553137843],
					[61.788897400443474, 2.7560999146396639],
					[61.788897400456861, 2.7560000005706149],
					[61.788897396747494, 2.755999978498529],
					[61.788897386651826, 2.7559999348995188],
					[61.788897380265503, 2.7559999133725945],
					[61.788800199572144, 2.755799953991211],
					[61.788800200241006, 2.7557000001259686],
					[61.788800192187992, 2.7556999618180202],
					[61.788800162869229, 2.7556998916976081],
					[61.78880014062517, 2.7556998581820782],
					[61.788700141195839, 2.7555998590255237],
					[61.788600141701338, 2.7554998605057408],
					[61.78850294297844, 2.7553998609644874],
					[61.788502911349021, 2.7553998388723082],
					[61.788502838790855, 2.755399807995536],
					[61.788502799732818, 2.7553998007322091],
					[61.788399999878102, 2.7553997993361912],
					[61.788300000139301, 2.755399800214255],
					[61.788200000313822, 2.7553997991887096],
					[61.788099999681478, 2.7553998002107196],
					[61.788099928984309, 2.7553998298270748],
					[61.788099829003052, 2.7553999281815349],
					[61.788099799891008, 2.7554000006891122],
					[61.788099799666824, 2.7554998000299236],
					[61.787997199792841, 2.7554997993407895],
					[61.787997129095665, 2.7554998289567996],
					[61.787997029200355, 2.7554999291953193],
					[61.787997000002242, 2.7554999998178222],
					[61.787996999767316, 2.7555999186560824],
					[61.78789991504965, 2.7556997990604679],
					[61.78780000003345, 2.7556998006321476],
					[61.787799929250163, 2.7556998283627712],
					[61.78779982935469, 2.7556999285999626],
					[61.787799800156435, 2.7556999992218243],
					[61.787799799787038, 2.7557997994848553],
					[61.787700000015256, 2.755799800068707],
					[61.787699929318009, 2.755799829683701],
					[61.787699829336397, 2.755799928035521],
					[61.787699800224154, 2.7558000005417451],
					[61.787699799777648, 2.7558998004839448],
					[61.7876027999462, 2.7558997992977869],
					[61.787602763361129, 2.7558998067480589],
					[61.787602694362434, 2.7558998341145902],
					[61.787602662034878, 2.7558998559155485],
					[61.787497119682627, 2.7559997994547896],
					[61.787400000135015, 2.7559997994667906],
					[61.78730000045072, 2.7559998000863706],
					[61.787299962080994, 2.755999807899848],
					[61.787299891383661, 2.7559998375139494],
					[61.787299859056084, 2.7559998593145543],
					[61.787199858953983, 2.7560998598323989],
					[61.787199857975622, 2.7560998581294434],
					[61.787199857169348, 2.7560998601958109],
					[61.787199857169348, 2.7560998601958109],
					[61.787102657016803, 2.7561998605361842],
					[61.787102646739093, 2.7561998721399155],
					[61.787102629839012, 2.7561998965052275],
					[61.787102622324305, 2.7561999094485108],
					[61.786999822359896, 2.7563999091269022],
					[61.786999814383002, 2.7563999316750651],
					[61.786999802890776, 2.7563999758629305],
					[61.786999800353811, 2.7563999992055819],
					[61.78699979972199, 2.7565000003729856],
					[61.786999799693731, 2.7565999177112421],
					[61.786899858705183, 2.7566998593828709],
					[61.786899848427467, 2.7566998709863508],
					[61.786899828936328, 2.7566998977809334],
					[61.786899821421578, 2.756699910724008],
					[61.786799820891446, 2.7568999117335546],
					[61.786799812828406, 2.7568999323968097],
					[61.786799803120644, 2.7568999762208506],
					[61.786799799691288, 2.7568999997449843],
					[61.786799800361941, 2.7569998002141531],
					[61.786699999813074, 2.7569998006599081],
					[61.786597400123313, 2.7569998003409499],
					[61.786597400353529, 2.7569000003479944],
					[61.786597370949416, 2.7568999283466757],
					[61.786597270944192, 2.7568998288725508],
					[61.786597200256999, 2.7568997995151427],
					[61.786500082623725, 2.7568997999211438],
					[61.786400140652979, 2.756799858923324],
					[61.786400109023781, 2.7567998368310955],
					[61.786400038336588, 2.7567998074740601],
					[61.786400000170936, 2.7567998000276108],
					[61.786299999640391, 2.7567997999190634],
					[61.786202800034062, 2.7567998001579892],
					[61.78609720041559, 2.7567998006179457],
					[61.786097161153464, 2.7567998086117096],
					[61.786097088671269, 2.7567998385860131],
					[61.786097057235899, 2.7567998602031727],
					[61.785999915275667, 2.7568998007481595],
					[61.785900000283128, 2.75689979994082],
					[61.785799999745294, 2.75689979941215],
					[61.785702800131915, 2.7568997992427131],
					[61.785599999646159, 2.7568998000010407],
					[61.785499999998805, 2.7568997992340467],
					[61.785400000436908, 2.7568998003327194],
					[61.785299999895997, 2.7568997997096081],
					[61.785197200300075, 2.7568998002080418],
					[61.785197128710166, 2.7568998299994401],
					[61.785197028727637, 2.7568999283392333],
					[61.785196999614683, 2.7569000008384195],
					[61.785197000389608, 2.7569997998671578],
					[61.785099999863959, 2.7569998005828511],
					[61.785099929080374, 2.7569998283077024],
					[61.785099829183729, 2.7569999285313882],
					[61.785099799984735, 2.7569999991457035],
					[61.785099799855388, 2.7570999178605478],
					[61.784999858732952, 2.7571998589212137],
					[61.784899859000895, 2.7572998587823574],
					[61.784899837090698, 2.7572998917732856],
					[61.784899807891541, 2.7572999623868539],
					[61.784899799710224, 2.7573000001911177],
					[61.784899800245874, 2.7573999180862074],
					[61.784802714766442, 2.7574997991935368],
					[61.784697199932069, 2.7574998006219333],
					[61.78460000037348, 2.7574998002352902],
					[61.78459996200359, 2.7574998080460191],
					[61.784599891305888, 2.7574998376536977],
					[61.784599858978027, 2.7574998594506708],
					[61.784499916716278, 2.7575997995711674],
					[61.784400000072409, 2.7575998003353654],
					[61.784399929288625, 2.7575998280581233],
					[61.784399829391525, 2.757599928277457],
					[61.784399800278166, 2.7576000007740791],
					[61.784399799703031, 2.7576997993075625],
					[61.784302800122241, 2.7576997993688046],
					[61.784302729424475, 2.7576998289757051],
					[61.784302628634954, 2.7576999293760394],
					[61.784302600327877, 2.7576999998061833],
					[61.784302599654119, 2.7577999159565723],
					[61.784199860853498, 2.7578998564731432],
					[61.784199838050803, 2.7578998896445013],
					[61.784199807958977, 2.757899960437503],
					[61.784199799863487, 2.757900000125229],
					[61.784199800017781, 2.7579999176524419],
					[61.784099859307275, 2.7580998581947171],
					[61.78409983739688, 2.7580998911841905],
					[61.78409980819724, 2.7580999617951436],
					[61.784099800015696, 2.7580999995982114],
					[61.78409980023455, 2.7582000004523226],
					[61.784099800367208, 2.7584000002887752],
					[61.784099800207763, 2.7586000001421671],
					[61.784099799975543, 2.7586999991329653],
					[61.784099802878089, 2.7587000232680787],
					[61.78409981305883, 2.7587000687458061],
					[61.784099821143329, 2.758700088022318],
					[61.784199800031978, 2.7589000463129825],
					[61.784199799678071, 2.7590999992028586],
					[61.784199800289542, 2.759299999403634],
					[61.784199828800034, 2.7593000715834433],
					[61.784199928803581, 2.7593001710581442],
					[61.784200000382597, 2.7593002002375524],
					[61.784302599757325, 2.7593001992848132],
					[61.784302599980307, 2.7595000004834902],
					[61.784302599991847, 2.7595999999912246],
					[61.784302599930356, 2.7596999995031934],
					[61.784302600323407, 2.7597999513620701],
					[61.784199836147138, 2.7599998816443931],
					[61.784099858940735, 2.7600998590116901],
					[61.784099847770349, 2.7600998707945066],
					[61.784099829170835, 2.7600998974028546],
					[61.784099820763394, 2.760099910525462],
					[61.783999820707741, 2.7602999112281057],
					[61.783999812644147, 2.7602999318885724],
					[61.783999803021224, 2.7602999775920285],
					[61.783999800397588, 2.7602999990476191],
					[61.783999800217998, 2.7604999997502775],
					[61.78399979974639, 2.760700000469853],
					[61.783999799847223, 2.7608000007452138],
					[61.783999799743846, 2.760999999424151],
					[61.783999800113833, 2.7611999161792831],
					[61.783899859309599, 2.7612998585816491],
					[61.783899837398415, 2.7612998915686759],
					[61.783899808197106, 2.7612999621761891],
					[61.783899800014659, 2.7612999999782049],
					[61.783899800150927, 2.7614999997511283],
					[61.783899800343107, 2.7615999159097324],
					[61.783797061057143, 2.7616998571967537],
					[61.783797038167549, 2.7616998884804147],
					[61.783797008159624, 2.761699961153333],
					[61.783797000063011, 2.7617000008395962],
					[61.783797000216019, 2.7617998006298121],
					[61.783700000086888, 2.7617997991177954],
					[61.783600000102091, 2.7617997991767811],
					[61.783599961817842, 2.7617998088662916],
					[61.783599891033113, 2.7617998365785783],
					[61.783599858704456, 2.7617998583702854],
					[61.783499916611348, 2.761899799534322],
					[61.78340280042034, 2.7618998003375941],
					[61.783297200437381, 2.7618997998425359],
					[61.783297128846208, 2.7618998296202406],
					[61.783297028945704, 2.7618999298219844],
					[61.78329699974406, 2.7619000004275263],
					[61.783296999790814, 2.761999918410579],
					[61.78319985676147, 2.76209986100227],
					[61.783199835742359, 2.7620998938064658],
					[61.783199807346989, 2.7620999623456521],
					[61.783199800056664, 2.762099999965089],
					[61.783199799953394, 2.7621999176403467],
					[61.783099858596273, 2.7622998597292008],
					[61.783099836598865, 2.7622998908301848],
					[61.783099808289322, 2.7622999612534209],
					[61.783099800106569, 2.7622999990541901],
					[61.78309979970139, 2.7623999525798442],
					[61.782999820945236, 2.7625999114089899],
					[61.782999812881314, 2.7625999320681562],
					[61.782999803257596, 2.7625999777694505],
					[61.782999799741241, 2.7625999994056474],
					[61.782999800091162, 2.7626999543214383],
					[61.78290262002313, 2.7628999128323475],
					[61.782902611000836, 2.7628999717243876],
					[61.782902629713703, 2.7629000858368671],
					[61.782902657362996, 2.7629001391728334],
					[61.782999800069192, 2.7630000816868843],
					[61.782999800279079, 2.7631000004523725],
					[61.782999808329969, 2.7631000387552285],
					[61.782999836752509, 2.7631001090514973],
					[61.782999858908831, 2.7631001406819498],
					[61.78309979965087, 2.7632000837621402],
					[61.783099799633135, 2.7633000009770243],
					[61.783099799668008, 2.763399999953049],
					[61.78309980771882, 2.7634000382561101],
					[61.783099837033561, 2.7634001083714081],
					[61.783099859275779, 2.7634001418866152],
					[61.783199858061273, 2.763500139083209],
					[61.783297000193258, 2.7636000806645358],
					[61.783296999977949, 2.763700000422423],
					[61.783297008114594, 2.7637000406102645],
					[61.783297039299782, 2.7637001122478559],
					[61.783297062434244, 2.7637001455820576],
					[61.783402599836307, 2.7638000869603401],
					[61.783402599637803, 2.7639999993384263],
					[61.783402606796123, 2.7640000378234744],
					[61.783402636024618, 2.7640001060555832],
					[61.783402657374346, 2.7640001397530183],
					[61.783499799975786, 2.7641000814941385],
					[61.783499800210677, 2.7641999998671265],
					[61.78349979960533, 2.7643000001837961],
					[61.783499803313326, 2.764300022253118],
					[61.783499812599857, 2.7643000679133611],
					[61.783499820769542, 2.7643000890754994],
					[61.783599799993446, 2.7645000472724486],
					[61.783599799702451, 2.7646000002712086],
					[61.783599799541847, 2.7647999993465215],
					[61.783599803335662, 2.764800023300475],
					[61.783599812622043, 2.764800068961021],
					[61.783599820705732, 2.7648000882388768],
					[61.783699776299514, 2.7650000001454353],
					[61.783599821420665, 2.7651999110728473],
					[61.783599813356361, 2.7651999317317864],
					[61.783599802839412, 2.7651999776146177],
					[61.783599800215008, 2.7651999990695519],
					[61.783599799776049, 2.7652999995727079],
					[61.783599800156431, 2.7653999998986953],
					[61.783599800100987, 2.7654999527540536],
					[61.783499820645908, 2.7656999119146586],
					[61.78349981258151, 2.7656999325733862],
					[61.783499802870843, 2.7656999763900321],
					[61.78349980033223, 2.7656999997293519],
					[61.783499800378081, 2.765799919873019],
					[61.78340265664356, 2.7658998603608826],
					[61.783402635623553, 2.765899893162743],
					[61.783402607226236, 2.7658999616989917],
					[61.783402599934803, 2.7658999993178472],
					[61.783402599572966, 2.7659999135002811],
					[61.783297119948763, 2.7660997995683938],
					[61.78319999963783, 2.7660997992654726],
					[61.783100083023449, 2.7660998005757431],
					[61.783000141135147, 2.7659998580828491],
					[61.783000108614964, 2.7659998361646756],
					[61.783000038015395, 2.7659998086736053],
					[61.782999999764208, 2.7659997993318295],
					[61.782902881326166, 2.7659998008124198],
					[61.782800139332458, 2.7658998578581042],
					[61.78280010681226, 2.7658998359401656],
					[61.78280003791145, 2.765899806202277],
					[61.782799999832029, 2.7658998006295166],
					[61.782700000269735, 2.7658998003584538],
					[61.782599999814472, 2.7658998002499051],
					[61.782500200153528, 2.7658997992462901],
					[61.782500200194185, 2.7658000002729017],
					[61.782500170794769, 2.7657999282721506],
					[61.782500070795763, 2.7657998287811862],
					[61.782500000110261, 2.7657997994065324],
					[61.782397199700107, 2.7657998000816204],
					[61.78239712900033, 2.7657998296678947],
					[61.782397029096778, 2.7657999298539893],
					[61.782396999893017, 2.7658000004538419],
					[61.782396999848004, 2.7658997990918479],
					[61.782300000229711, 2.7658997994483636],
					[61.782299928637549, 2.7658998292156718],
					[61.782299828733919, 2.7658999294011259],
					[61.782299800422457, 2.7658999998192533],
					[61.78229979958919, 2.7659999998875469],
					[61.782299799575242, 2.7660999997786826],
					[61.782299800380613, 2.7661999994926578],
					[61.78229979994402, 2.766299953803796],
					[61.782199821383301, 2.7664999107769059],
					[61.78219981331879, 2.76649993143453],
					[61.782199802801365, 2.7664999773148429],
					[61.782199800262624, 2.7665000006530871],
				],
			],
		},
		{
			fldNpdidField: "43513",
			wlbNpdidWellbore: "181",
			fldName: "VEST EKOFISK",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "2/4-6",
			fldDiscoveryYear: "1970",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43513",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43513",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "018",
			cmpLongName: "ConocoPhillips Skandinavia AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=181",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "2410696",
			fldNpdidOwner: "20900",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[56.54924420194051, 3.0859805854545637],
					[56.549244202977746, 3.0859806260391784],
					[56.549244205768957, 3.0859806467908357],
					[56.549447006345488, 3.086825046793646],
					[56.549447009798492, 3.0868250625522453],
					[56.54944702288244, 3.0868250914730315],
					[56.549447029832606, 3.0868251050546052],
					[56.54974982420179, 3.0873139952878756],
					[56.550074814866335, 3.0880111772136338],
					[56.550297013024235, 3.0886028693291694],
					[56.550297015120229, 3.0886028755210142],
					[56.550297020205811, 3.0886028877649347],
					[56.550297023118205, 3.0886028921991535],
					[56.550574823174657, 3.089133393257383],
					[56.550574840571699, 3.0891334182453249],
					[56.550574884886601, 3.0891334602124529],
					[56.550574910017275, 3.0891334774711336],
					[56.551030509920743, 3.08936407808446],
					[56.551030509997929, 3.0893640797023374],
					[56.551030511785129, 3.0893640794228272],
					[56.551030512678729, 3.0893640792830697],
					[56.551435932893199, 3.0895612407889073],
					[56.551630403827019, 3.0902278402216892],
					[56.551749797268215, 3.0911721880836152],
					[56.551588705701242, 3.09183325305459],
					[56.551588704884793, 3.0918332548121978],
					[56.551444204781191, 3.0924638544073488],
					[56.551444204273345, 3.0924638626365595],
					[56.551444201393267, 3.0924638777564311],
					[56.55144420088542, 3.0924638859856408],
					[56.551399800619578, 3.0930832864114253],
					[56.55139980065168, 3.0930833059659468],
					[56.55139980704827, 3.0930833457152263],
					[56.55139981073193, 3.0930833663289747],
					[56.55159701073422, 3.0936444660830591],
					[56.551597012752822, 3.093644470657483],
					[56.551597016867206, 3.0936444814242523],
					[56.551597019779429, 3.0936444858590333],
					[56.551977620298786, 3.0944389852207506],
					[56.551977621346644, 3.0944389883169729],
					[56.551977624335962, 3.0944389943697868],
					[56.551977625383834, 3.0944389974660069],
					[56.552180384449457, 3.0948028244281618],
					[56.551997015968077, 3.0954221152328185],
					[56.551708130782984, 3.0958776935438514],
					[56.551708125729753, 3.0958777008531895],
					[56.551708118458357, 3.0958777182888948],
					[56.551708114375828, 3.0958777270765534],
					[56.551472014259531, 3.0964777276275952],
					[56.551472010993486, 3.0964777346576309],
					[56.55147200714223, 3.0964777482989123],
					[56.551472004846893, 3.0964777568072712],
					[56.551322004605822, 3.0971610570894725],
					[56.551322002110616, 3.0971610802985423],
					[56.55132200255899, 3.097161127497186],
					[56.551322007289777, 3.0971611512075818],
					[56.551513707082513, 3.0978861507550186],
					[56.551513716281228, 3.0978861737678525],
					[56.55151373899254, 3.0978862158599094],
					[56.551513753398758, 3.097886234799577],
					[56.551830440384165, 3.0982307222074827],
					[56.552119216624412, 3.0986917851298514],
					[56.552277597903995, 3.0991806265633532],
					[56.552246999934113, 3.0997527894380199],
					[56.55224700078174, 3.099752807235586],
					[56.552247006205562, 3.0997528455085761],
					[56.552247010704697, 3.0997528643660321],
					[56.5524942108215, 3.100480663637283],
					[56.552494211946261, 3.1004806683516928],
					[56.552494216060076, 3.1004806791194959],
					[56.552494218078444, 3.1004806836944208],
					[56.552852611320901, 3.1012556664896294],
					[56.553060903013417, 3.1020833372667114],
					[56.553177598250734, 3.1030360964605848],
					[56.553038702808983, 3.1039610624651219],
					[56.55281090505963, 3.1049777562816727],
					[56.552810904396985, 3.1049777612750762],
					[56.552810902178045, 3.1049777714012619],
					[56.552810902332027, 3.1049777746372542],
					[56.552702601955808, 3.1058527758103489],
					[56.552702601370093, 3.1058527824217048],
					[56.552702600198643, 3.1058527956444468],
					[56.552702599612942, 3.1058528022558143],
					[56.552719199639824, 3.1070639024022584],
					[56.552719199716783, 3.1070639040202725],
					[56.552719199793749, 3.1070639056382707],
					[56.552744200365574, 3.108058304342042],
					[56.552758099717124, 3.1087972034667981],
					[56.552758104753494, 3.1087972336517451],
					[56.552758124579221, 3.1087972908712453],
					[56.552758139368564, 3.1087973179058053],
					[56.553088738785874, 3.1092473173900945],
					[56.553088764298685, 3.1092473427534979],
					[56.553088821934843, 3.1092473810397725],
					[56.553088854135098, 3.1092473955806512],
					[56.55358054230588, 3.1093640913749816],
					[56.554080520897465, 3.1095473845395123],
					[56.554458212205674, 3.109733478535349],
					[56.554458256839794, 3.1097334895089119],
					[56.554458346818315, 3.1097334885242698],
					[56.554458392162694, 3.1097334765660798],
					[56.554858392166167, 3.1095251761746012],
					[56.554858399238249, 3.1095251734421079],
					[56.554858412335008, 3.1095251648802127],
					[56.554858417542917, 3.109525160808202],
					[56.555130598407217, 3.1093252482027101],
					[56.555324804117618, 3.1094723022290847],
					[56.555346998980731, 3.1100638810097467],
					[56.55520260481935, 3.11069165583607],
					[56.555202604002638, 3.1106916575934598],
					[56.555202603416681, 3.1106916642052105],
					[56.555202602599962, 3.1106916659626016],
					[56.555099804628881, 3.1112943524499048],
					[56.554927610122377, 3.1118166367844435],
					[56.554927605760298, 3.1118166586553451],
					[56.554927601350499, 3.1118166984647035],
					[56.554927600562955, 3.1118167197786355],
					[56.554988700855255, 3.1124167193525469],
					[56.554988702133357, 3.1124167273038883],
					[56.554988703565243, 3.1124167384914503],
					[56.554988704766465, 3.1124167448246789],
					[56.555191504642657, 3.1132917455202098],
					[56.555191505766928, 3.1132917502353639],
					[56.555191508909139, 3.1132917595264757],
					[56.555191511003933, 3.1132917657205565],
					[56.555416507187601, 3.1139500536976992],
					[56.555549801842595, 3.1145611305961141],
					[56.555594200947304, 3.1150833174980583],
					[56.555594203349457, 3.1150833301648904],
					[56.555594209124273, 3.1150833569775385],
					[56.555594213313711, 3.1150833693660362],
					[56.555783113423459, 3.115591769620802],
					[56.555783132093403, 3.1155918025761862],
					[56.555783183347245, 3.115591858169577],
					[56.555783214143879, 3.1155918810859067],
					[56.556133214152737, 3.1157584808649323],
					[56.556133222426062, 3.1157584844670176],
					[56.556133238895853, 3.1157584900530035],
					[56.556133247169157, 3.1157584936550773],
					[56.556663846917878, 3.1159029932997253],
					[56.556663859582528, 3.1159029945878838],
					[56.556663884095073, 3.1159029989215621],
					[56.556663896759744, 3.1159030002097183],
					[56.557141663416864, 3.1159112995990967],
					[56.557705532221576, 3.1161140874994357],
					[56.557705631338848, 3.1161140704344552],
					[56.557705762791862, 3.1161139570424177],
					[56.557705796098134, 3.1161138621944469],
					[56.557566895942287, 3.1153971618846836],
					[56.557566895865428, 3.1153971602664532],
					[56.557566895788568, 3.1153971586482085],
					[56.557566895788568, 3.1153971586482085],
					[56.557383499285031, 3.114541673973716],
					[56.55731960024012, 3.1132805925954683],
					[56.557277999709058, 3.1117055951530936],
					[56.557277999632149, 3.1117055935348881],
					[56.55727800037198, 3.111705590159211],
					[56.557278000295071, 3.1117055885410045],
					[56.557219599770917, 3.1106638999044769],
					[56.557277999943764, 3.1095389099349706],
					[56.557277999636035, 3.1095389034621665],
					[56.557277999991129, 3.1095388919954177],
					[56.5572779996834, 3.1095388855226145],
					[56.557216900164988, 3.108644385978609],
					[56.557216899271353, 3.1086443861179442],
					[56.557216899194415, 3.1086443844997462],
					[56.557216899194415, 3.1086443844997462],
					[56.557153002400959, 3.1078389147410657],
					[56.557286227237668, 3.1072281556265295],
					[56.557594239996867, 3.1074723277205543],
					[56.557863699279885, 3.108030644614745],
					[56.5578469997333, 3.108916689381549],
					[56.557788701129375, 3.1096749854930374],
					[56.557788701235864, 3.1096750066692791],
					[56.557788707858116, 3.1096750512830389],
					[56.557788714373878, 3.1096750747205575],
					[56.558005414264777, 3.1102167741180287],
					[56.558005419271431, 3.1102167847491917],
					[56.558005431071955, 3.1102168057328927],
					[56.558005436972209, 3.1102168162247419],
					[56.558341509303062, 3.1106861774654435],
					[56.558441501595098, 3.1113861275863934],
					[56.558441502873301, 3.111386135538408],
					[56.558441507140017, 3.1113861495456447],
					[56.558441508418206, 3.1113861574976638],
					[56.558594208330547, 3.1118972572896406],
					[56.558594229913091, 3.1118972946824908],
					[56.558594286021247, 3.1118973576696543],
					[56.558594320546867, 3.1118973832639649],
					[56.558905521255809, 3.1120307836725938],
					[56.558905523119975, 3.1120307850123474],
					[56.55890552774197, 3.1120307875526025],
					[56.558905530422869, 3.1120307871348167],
					[56.559144251853617, 3.112119557879752],
					[56.559402611586663, 3.1126889701487772],
					[56.559619207720957, 3.1134167573143339],
					[56.559619209815772, 3.1134167635091159],
					[56.559619214899037, 3.1134167757594748],
					[56.559619216916964, 3.1134167803359438],
					[56.55986091608419, 3.1139667781101537],
					[56.560105398831446, 3.1145750353979769],
					[56.560080399555694, 3.115247193040664],
					[56.560080399632554, 3.1152471946590019],
					[56.560080399786287, 3.115247197895691],
					[56.560080399863146, 3.1152471995140281],
					[56.560077600060517, 3.1161721997067384],
					[56.560077603000536, 3.1161722237035363],
					[56.560077614242424, 3.1161722708621062],
					[56.560077623361074, 3.1161722922663535],
					[56.560310922936907, 3.116613993516693],
					[56.560310997425113, 3.1166140454995395],
					[56.560311157904906, 3.1166140694185058],
					[56.560311243819655, 3.1166140397362696],
					[56.560647344351921, 3.1162668393418125],
					[56.560647352827232, 3.1162668282398944],
					[56.560647368884204, 3.1162668061752155],
					[56.560647376389028, 3.116266793594106],
					[56.560936276090295, 3.1157250944709545],
					[56.56093628418126, 3.1157250752769383],
					[56.560936295078243, 3.1157250393423981],
					[56.56093629870103, 3.1157250208443119],
					[56.561011298728495, 3.1149944194379326],
					[56.561011299391524, 3.1149944144435846],
					[56.561011299747108, 3.114994402975733],
					[56.561011300333277, 3.1149943963630107],
					[56.561000200232861, 3.1143444084424425],
					[56.561077993799216, 3.1135972640267884],
					[56.561327925823001, 3.1131723796153388],
					[56.561705620599376, 3.1131002005144217],
					[56.562222022122796, 3.1130891039916833],
					[56.562302600981155, 3.1137861164994609],
					[56.562363700269728, 3.1149889069656655],
					[56.562385899663639, 3.1161111041914058],
					[56.562385899740491, 3.1161111058098445],
					[56.56241089999034, 3.1171444012423382],
					[56.562399800071809, 3.1182860907170351],
					[56.562313709763899, 3.119310967741721],
					[56.561866615991839, 3.1195192193636347],
					[56.561866600213477, 3.1195192283411757],
					[56.561866569703952, 3.1195192493940036],
					[56.561866556760101, 3.1195192611911127],
					[56.561577656979466, 3.1198165607766035],
					[56.561577647533369, 3.1198165703987142],
					[56.561577632369385, 3.1198165923234034],
					[56.561577624787382, 3.1198166032857415],
					[56.561272025159973, 3.1203693037080877],
					[56.561272018625175, 3.120369317767949],
					[56.561272008236479, 3.1203693454704617],
					[56.56127200438263, 3.1203693591131043],
					[56.561130406827751, 3.1210499467229531],
					[56.560863725781793, 3.1218081905835904],
					[56.560577657604235, 3.1220970590182318],
					[56.560577639835635, 3.1220970829769072],
					[56.560577612648373, 3.1220971361164498],
					[56.560577603152943, 3.1220971636789541],
					[56.56049420281893, 3.1225499639149512],
					[56.560494202029574, 3.1225499852319341],
					[56.560494203131867, 3.1225500274488089],
					[56.560494205993919, 3.1225500498280536],
					[56.560627606323521, 3.1230694503134342],
					[56.560627609465044, 3.1230694596067159],
					[56.560627615671322, 3.1230694765748925],
					[56.56062761970648, 3.1230694857291632],
					[56.560994220369345, 3.1238361856869155],
					[56.560994231197277, 3.1238362051972133],
					[56.560994257984817, 3.1238362385286358],
					[56.560994274097915, 3.1238362555865602],
					[56.561522074119047, 3.1242668546810304],
					[56.561522080758351, 3.1242668618002543],
					[56.561522098351716, 3.1242668721069005],
					[56.561522105807924, 3.1242668774687146],
					[56.562038806217963, 3.1245418770984363],
					[56.562038808975664, 3.124541878299933],
					[56.562038816278374, 3.1245418804249705],
					[56.562038819112814, 3.1245418832449148],
					[56.562435995665197, 3.1247168727889694],
					[56.562694243258967, 3.1249223297174997],
					[56.562874823106981, 3.1252639932317177],
					[56.562874832810863, 3.1252640080268725],
					[56.562874854822859, 3.1252640355818171],
					[56.562874867130965, 3.1252640483415743],
					[56.563227666973802, 3.125577949669704],
					[56.563227700296331, 3.125577968944504],
					[56.56322776990217, 3.1255779939901913],
					[56.563227807972801, 3.1255779994831507],
					[56.563522207749742, 3.1255669003328315],
					[56.563522268721364, 3.1255668761774067],
					[56.563522362983825, 3.1255667946707479],
					[56.563522396274664, 3.125566737319498],
					[56.563616896275434, 3.1250833373402394],
					[56.563616898342495, 3.1250833239751366],
					[56.563616898748542, 3.1250832945638343],
					[56.563616898951572, 3.125083279858174],
					[56.563572399089949, 3.1246416794287524],
					[56.56357238443001, 3.1246416360561544],
					[56.563572335833491, 3.1246415604613009],
					[56.56357230279054, 3.1246415281000717],
					[56.563275130644193, 3.1244637456329478],
					[56.563019601872455, 3.1241610283316601],
					[56.563030799449223, 3.1236694290524882],
					[56.5632251932279, 3.1229583535410752],
					[56.563225193814503, 3.122958346928058],
					[56.563225196928535, 3.1229583366609663],
					[56.56322519759189, 3.122958331666458],
					[56.563308497170908, 3.1224361330317323],
					[56.563450195717664, 3.1216194378827415],
					[56.5635807778106, 3.1210168217243734],
					[56.56392787133214, 3.120789092084375],
					[56.564249984730132, 3.1207474038547938],
					[56.564597104876185, 3.1208445811550263],
					[56.564960972186014, 3.1211473541727575],
					[56.564960988885979, 3.1211473646178454],
					[56.564961024890096, 3.1211473834722283],
					[56.564961044194213, 3.1211473918815154],
					[56.565227743875383, 3.1212251920775245],
					[56.565227811206121, 3.121225188120893],
					[56.565227926815005, 3.1212251391485872],
					[56.565227976063596, 3.1212250956123873],
					[56.565433476044753, 3.1208417945876823],
					[56.565433484136491, 3.1208417753923605],
					[56.56543349503491, 3.1208417394548285],
					[56.565433498581612, 3.12084171933637],
					[56.565516898110886, 3.1200139297389122],
					[56.565658491041908, 3.1192639646590639],
					[56.565852978743202, 3.118869487555227],
					[56.565852986094619, 3.1188694717356231],
					[56.565852996175614, 3.1188694375548884],
					[56.565852998905164, 3.1188694191937745],
					[56.565916894875556, 3.1182000639037977],
					[56.56621127200232, 3.1177057024163615],
					[56.566211275346369, 3.1177056970038617],
					[56.566211281140873, 3.1177056863180366],
					[56.566211282620813, 3.1177056795652636],
					[56.566505782702386, 3.1170333807076918],
					[56.566505791658663, 3.1170333418092349],
					[56.566505792592039, 3.1170332666568288],
					[56.566505786202747, 3.1170332268872607],
					[56.566280788306344, 3.1164555313168862],
					[56.566075193938651, 3.115844349200398],
					[56.565900198993823, 3.1149582739448047],
					[56.565861301231735, 3.1143583046832384],
					[56.565966895148925, 3.1134528557218446],
					[56.566169578341992, 3.1130278920375147],
					[56.566425173588208, 3.1125750978129685],
					[56.566425180275843, 3.1125750869870408],
					[56.566425189106099, 3.1125750644130257],
					[56.566425193035961, 3.1125750523863616],
					[56.566525192554337, 3.1122000516583319],
					[56.56652519683945, 3.1122000281620612],
					[56.56652519818369, 3.1121999806652805],
					[56.566525195242818, 3.1121999566647967],
					[56.566411294905038, 3.1116888556153786],
					[56.566411293703737, 3.1116888492802888],
					[56.56641128959081, 3.1116888385073334],
					[56.566411288312608, 3.1116888305536432],
					[56.566241903080666, 3.1112249721186505],
					[56.566272399240987, 3.1108417165608855],
					[56.566272399826914, 3.1108417099472043],
					[56.566272400182065, 3.1108416984777234],
					[56.566272399951302, 3.1108416936219578],
					[56.566250200137212, 3.1101138997491682],
					[56.566272399142562, 3.1091861240421563],
					[56.566372395264047, 3.1087333427878949],
					[56.56637239592682, 3.1087333377927413],
					[56.566372398145987, 3.1087333276630691],
					[56.56637239880876, 3.1087333226679279],
					[56.566441897698354, 3.1081139317416224],
					[56.566597395801793, 3.1073333387767139],
					[56.566597397866907, 3.1073333254097197],
					[56.566597399162333, 3.1073332958567712],
					[56.566597399363218, 3.1073332811499976],
					[56.566519599665277, 3.1064971901600047],
					[56.566514099788371, 3.1058777987515556],
					[56.566514100374036, 3.1058777921377549],
					[56.566514099018477, 3.1058777825656834],
					[56.566514098787515, 3.1058777777099289],
					[56.56643349872332, 3.1051499787964363],
					[56.566433498646319, 3.105149977177859],
					[56.566433497598702, 3.1051499740801489],
					[56.566433498415329, 3.1051499723221054],
					[56.566344597926587, 3.1045193719181254],
					[56.566344597772577, 3.1045193686809811],
					[56.566344596647951, 3.1045193639647364],
					[56.566344595600306, 3.1045193608670556],
					[56.566258507825644, 3.1040861198164049],
					[56.566394551727171, 3.1037418609694432],
					[56.56685004456088, 3.1035918958210447],
					[56.567250010355522, 3.1035390997523233],
					[56.567847194006134, 3.1035558001364163],
					[56.567847195793377, 3.103555799857399],
					[56.567847199367847, 3.103555799299361],
					[56.567847201232112, 3.1035558006389747],
					[56.568369400851772, 3.1035529993893394],
					[56.568369433915677, 3.103552994227385],
					[56.568369495036229, 3.1035529732704275],
					[56.568369523986497, 3.1035529573359111],
					[56.568794500361939, 3.1032168759657379],
					[56.569161172216965, 3.1030751874448494],
					[56.569161202800352, 3.1030751679931434],
					[56.569161254568762, 3.1030751207728802],
					[56.569161276647399, 3.1030750928647768],
					[56.56956407381837, 3.1023056979981578],
					[56.569958470690516, 3.1016529037612708],
					[56.570355770271874, 3.1010112056740282],
					[56.570355772721548, 3.101011200399062],
					[56.570355777697962, 3.1010111914678795],
					[56.570355781041251, 3.1010111860533454],
					[56.570708481195489, 3.1002694858268969],
					[56.570708481118423, 3.1002694842081504],
					[56.57070848275152, 3.1002694806914519],
					[56.570708483568069, 3.1002694789330882],
					[56.570994582870441, 3.099600080302904],
					[56.571181801616724, 3.099193119941237],
					[56.570980414944231, 3.0996777233974542],
					[56.570980414915176, 3.0996777984188704],
					[56.570980468011349, 3.099677930372116],
					[56.57098059844968, 3.0996779850093876],
					[56.570980729239913, 3.0996779335895779],
					[56.570980784273033, 3.0996778793287878],
					[56.571258483988785, 3.0990361798144677],
					[56.571258484728226, 3.0990361764372989],
					[56.57125848791727, 3.0990361677849116],
					[56.57125848865671, 3.0990361644077447],
					[56.57153628859961, 3.0982167655721069],
					[56.571830789110741, 3.0973583644631271],
					[56.571830789110741, 3.0973583644631271],
					[56.571830790004356, 3.0973583643234499],
					[56.57183078992724, 3.0973583627046635],
					[56.571955790244949, 3.0969833632531927],
					[56.571955795420678, 3.096983339612287],
					[56.571955797575413, 3.0969832903500425],
					[56.571955797235262, 3.0969832643096575],
					[56.571858497457818, 3.0964332676293536],
					[56.571769602391925, 3.0958610994412128],
					[56.57183079838827, 3.0954278271680726],
					[56.571830798819093, 3.0954278173156511],
					[56.571830799680768, 3.0954277976107996],
					[56.571830800111584, 3.095427787758378],
					[56.571808499586886, 3.0950638881301966],
					[56.571808499124089, 3.0950638784175073],
					[56.571808494624051, 3.0950638595510522],
					[56.57180849319051, 3.0950638483593149],
					[56.571705794090732, 3.0946721500958989],
					[56.571628009647505, 3.094366713388677],
					[56.571766884430701, 3.0940111782711353],
					[56.571950182261247, 3.093613984619398],
					[56.57195018618917, 3.0936139725890786],
					[56.571950193967893, 3.0936139469096799],
					[56.571950196925087, 3.0936139334003392],
					[56.572022391250314, 3.0932000653151546],
					[56.572105736035276, 3.0930445699028626],
					[56.572594422652671, 3.0929113005094262],
					[56.573036091541582, 3.0929279996701307],
					[56.573036105022751, 3.0929279991921472],
					[56.573036129227141, 3.0929279970367083],
					[56.573036140843968, 3.0929279952194721],
					[56.573458341430317, 3.092839096380978],
					[56.573458396569698, 3.0928390632914065],
					[56.573458475816345, 3.0928389693484815],
					[56.573458499923596, 3.0928389084951204],
					[56.573483499649079, 3.0922583097220184],
					[56.5734834995719, 3.0922583081031765],
					[56.573483500388335, 3.0922583063445273],
					[56.573483500311156, 3.0922583047256866],
					[56.573502999759988, 3.091419418804191],
					[56.573600193247366, 3.0908000586355153],
					[56.573905780939505, 3.0901472849535376],
					[56.573905780862297, 3.0901472833346855],
					[56.573905781755897, 3.0901472831948253],
					[56.574214068555882, 3.0894668112965218],
					[56.574516847065965, 3.0891390354325559],
					[56.574516849592314, 3.089139031775149],
					[56.574516857325811, 3.0891390240406627],
					[56.57451685985216, 3.0891390203832549],
					[56.5748945599126, 3.0886334202149519],
					[56.574894569124368, 3.0886334057248988],
					[56.574894583079924, 3.0886333774443075],
					[56.574894589610906, 3.0886333633739675],
					[56.575139087122579, 3.0879028712458423],
					[56.575394582827421, 3.0873028807664644],
					[56.575730781578592, 3.0865611829954616],
					[56.575730784843962, 3.0865611759599538],
					[56.575730790403867, 3.0865611604099783],
					[56.575730792775637, 3.0865611535144231],
					[56.575869592451532, 3.0860667557429688],
					[56.57599739219522, 3.0856333571928163],
					[56.575997394345343, 3.0856333079249558],
					[56.57599737370235, 3.0856332149274532],
					[56.575997352696412, 3.0856331709178768],
					[56.57577515304758, 3.0853692707692142],
					[56.575775152076716, 3.0853692692902746],
					[56.575775150212273, 3.0853692679513007],
					[56.575775149241423, 3.0853692664723606],
					[56.575547383861533, 3.0851138058560013],
					[56.575394594887719, 3.0844166565160531],
					[56.575394593839583, 3.084416653418236],
					[56.575394591666033, 3.0844166456037305],
					[56.575394591434204, 3.0844166407470128],
					[56.575252990534509, 3.0839638394868856],
					[56.57525298940908, 3.0839638347701976],
					[56.575252985371044, 3.0839638256168627],
					[56.575252983274744, 3.0839638194213115],
					[56.575011290847705, 3.0834138386842298],
					[56.57489740042945, 3.0828721816942637],
					[56.574908500301731, 3.082133302332287],
					[56.574916899908914, 3.0816111037379716],
					[56.574916896962065, 3.0816110797349854],
					[56.574916885706863, 3.0816110325693664],
					[56.574916878292086, 3.0816110092667013],
					[56.574730778168977, 3.0812471085164499],
					[56.574730773314499, 3.0812471011224742],
					[56.574730762634601, 3.0812470848557392],
					[56.574730757780095, 3.0812470774617693],
					[56.574603010605472, 3.081083246648499],
					[56.574677997321714, 3.0806167311974892],
					[56.574805796044423, 3.0798389396993846],
					[56.57494459399431, 3.0792667467755996],
					[56.57494459628834, 3.0792667382608911],
					[56.574944599011879, 3.0792667198928494],
					[56.574944600412316, 3.0792667115182635],
					[56.574978000033639, 3.0786361116020857],
					[56.574977999956289, 3.0786361099832185],
					[56.574977999878939, 3.0786361083643543],
					[56.57501130042413, 3.077897209381919],
					[56.575011300192045, 3.0778972045253234],
					[56.575011299650527, 3.0778971931932495],
					[56.575011300312013, 3.0778971881964852],
					[56.574966900273338, 3.077169388046451],
					[56.574966900195967, 3.0771693864275891],
					[56.574966900195967, 3.0771693864275891],
					[56.574933500264834, 3.0766777856491609],
					[56.574933499139121, 3.076677780932751],
					[56.574933497703917, 3.0766777697409058],
					[56.574933496578218, 3.0766777650244954],
					[56.574872397144183, 3.076333265404823],
					[56.574872394970107, 3.076333257590913],
					[56.57487239054462, 3.0763332403442321],
					[56.574872389264144, 3.0763332323901307],
					[56.574694601195105, 3.0758305683095974],
					[56.574711300308806, 3.0752306073531854],
					[56.574733499714007, 3.0746778086841804],
					[56.57473350045278, 3.07467780530625],
					[56.574733500065769, 3.0746777972120287],
					[56.574733499988362, 3.0746777955931686],
					[56.574708500013216, 3.0737610982427337],
					[56.574722399586058, 3.073091704501834],
					[56.574744600011364, 3.072294405568933],
					[56.57474459690787, 3.0722943783292114],
					[56.574744582658752, 3.0722943251123191],
					[56.574744573377707, 3.0722943004734202],
					[56.57450019204181, 3.0718721326197991],
					[56.574422397778939, 3.0713416708582142],
					[56.574422396730476, 3.0713416677608594],
					[56.574422396265803, 3.0713416580479187],
					[56.574422395139905, 3.0713416533317588],
					[56.574255795437139, 3.070633254061351],
					[56.574255794388669, 3.0706332509640313],
					[56.574255792369158, 3.0706332463882364],
					[56.574255792214245, 3.0706332431505996],
					[56.574036296371872, 3.0698971583929966],
					[56.57394740026249, 3.069186090219258],
					[56.573975200160639, 3.0684694083514543],
					[56.573975200005684, 3.0684694051138641],
					[56.57397519977323, 3.0684694002574706],
					[56.573975199618268, 3.0684693970198813],
					[56.57396959995755, 3.0680249962918587],
					[56.573969598366602, 3.0680249818630791],
					[56.573969593242602, 3.0680249500486951],
					[56.573969588893476, 3.0680249344222785],
					[56.573772389226249, 3.0674555353298061],
					[56.573772387129154, 3.0674555291354464],
					[56.573772383012447, 3.0674555183655259],
					[56.573772380021779, 3.0674555123115606],
					[56.573502979577313, 3.0668943127279884],
					[56.573502972702386, 3.0668943007606346],
					[56.573502957165388, 3.0668942771067527],
					[56.573502947609775, 3.0668942655606268],
					[56.573139047643764, 3.0664942655617651],
					[56.57313904286594, 3.0664942597887994],
					[56.573139031600689, 3.0664942501424566],
					[56.573139025929315, 3.0664942445098995],
					[56.572789031354617, 3.0662109493208796],
					[56.572475177400655, 3.0659165911649811],
					[56.572297392211084, 3.0652971444782668],
					[56.572297391162486, 3.0652971413812589],
					[56.572297388094213, 3.0652971337089858],
					[56.572297386968074, 3.0652971289932838],
					[56.572041886600324, 3.0646193295866815],
					[56.572041882560917, 3.0646193204362686],
					[56.572041874559638, 3.0646193037541378],
					[56.572041870597765, 3.0646192962224221],
					[56.571730770802333, 3.0641109961879902],
					[56.571730762062508, 3.0641109828839346],
					[56.571730742057262, 3.0641109599345926],
					[56.571730731685399, 3.0641109501488444],
					[56.571350164079583, 3.0637776775143255],
					[56.571133493285302, 3.0632971407119935],
					[56.571005796625023, 3.0625666663572693],
					[56.571005794139914, 3.0625666520703758],
					[56.571005784779473, 3.0625666258177251],
					[56.571005779691262, 3.062566613570977],
					[56.570747379825569, 3.062027714095283],
					[56.570747372872646, 3.0620277005110488],
					[56.570747358305915, 3.0620276783390099],
					[56.570747349643462, 3.0620276666544335],
					[56.570297350102301, 3.0615192677274141],
					[56.570297348160061, 3.0615192647712077],
					[56.570297344353165, 3.0615192604774122],
					[56.570297340701373, 3.0615192594208365],
					[56.56987514118493, 3.0610970584276265],
					[56.569875137377998, 3.0610970541339078],
					[56.569875128948169, 3.0610970473055987],
					[56.569875125218829, 3.0610970446304906],
					[56.569544530731335, 3.06083314926201],
					[56.56925296137787, 3.060560976250867],
					[56.56911410087249, 3.0602971519898516],
					[56.569119600429005, 3.0598639248799495],
					[56.569289090580405, 3.0591528617054617],
					[56.569541883012292, 3.0585500799353835],
					[56.569808481806966, 3.0579556816494211],
					[56.569808482700509, 3.0579556815088278],
					[56.570175182607159, 3.0571361824270493],
					[56.570175191156139, 3.0571361354188382],
					[56.570175185837869, 3.0571360432992187],
					[56.570175172048252, 3.0571359998064129],
					[56.570008472040413, 3.0568526989022176],
					[56.57000847196278, 3.0568526972836279],
					[56.570008471069244, 3.0568526974242465],
					[56.57000847099161, 3.0568526958056581],
					[56.569572371012839, 3.0561359959696732],
					[56.569314085077828, 3.0557138208007526],
					[56.569164093030814, 3.0551638470343798],
					[56.569164089884296, 3.0551638377450407],
					[56.569164082697704, 3.055163819307023],
					[56.569164078657622, 3.0551638101583434],
					[56.56889737934069, 3.0546360100287133],
					[56.568897369628715, 3.0546359952500044],
					[56.568897351253618, 3.054635968789011],
					[56.568897339909874, 3.0546359575287116],
					[56.568516840021324, 3.0542637572513844],
					[56.568516821296249, 3.0542637422611505],
					[56.568516778717864, 3.0542637179802874],
					[56.568516756651626, 3.0542637084083242],
					[56.568163957293997, 3.0541581085148959],
					[56.56816392912831, 3.0541581031647898],
					[56.568163872447037, 3.0541581039348418],
					[56.568163843853789, 3.0541581084364644],
					[56.567791657408513, 3.0542665039379129],
					[56.567438888627777, 3.0543191995079493],
					[56.567050008942573, 3.054302599710089],
					[56.567049984739363, 3.0543026018896078],
					[56.567049939246509, 3.0543026106820639],
					[56.567049917063343, 3.0543026174356345],
					[56.566677716616191, 3.0544720179434042],
					[56.566677702630194, 3.0544720266677476],
					[56.566677675551723, 3.0544720439757773],
					[56.566677662614573, 3.0544720557963423],
					[56.566383174563533, 3.0547498425923472],
					[56.566119312987766, 3.0549276186288972],
					[56.565661040712776, 3.0550692093859446],
					[56.565660980183807, 3.055069261309256],
					[56.565660917204895, 3.0550693935187301],
					[56.565660913783738, 3.0550694723271521],
					[56.565874709393583, 3.0556192045495432],
					[56.565658302524469, 3.055621999323693],
					[56.565136109896464, 3.0555969992857759],
					[56.565136081225482, 3.0555970021674179],
					[56.565136026058823, 3.0555970157413399],
					[56.565135998669604, 3.0555970265742642],
					[56.564810999208596, 3.0557859263732112],
					[56.564810980987687, 3.0557859406547867],
					[56.56481094827533, 3.0557859718922087],
					[56.564810932890317, 3.0557859889886752],
					[56.564602632786318, 3.0560998891847357],
					[56.564602627074947, 3.0560999014975434],
					[56.564602615574621, 3.0560999245047653],
					[56.564602610756801, 3.0560999366769477],
					[56.564427614058395, 3.0566138276066939],
					[56.564247017272741, 3.0570193193503945],
					[56.564247008996212, 3.057019353263807],
					[56.564247004214479, 3.0570194224996663],
					[56.56424700770927, 3.0570194578221326],
					[56.56436090273602, 3.0574028394719255],
					[56.564410895827656, 3.0578360783188616],
					[56.564249821945261, 3.0583193031204829],
					[56.563980509689713, 3.0586553928574913],
					[56.563633315812353, 3.0586276007239395],
					[56.563633294212025, 3.0586276008603859],
					[56.563633250428218, 3.0586276077471486],
					[56.563633228167156, 3.0586276128791416],
					[56.563266627973547, 3.0587692137636298],
					[56.563266626186461, 3.0587692140447107],
					[56.563266621796316, 3.0587692163657385],
					[56.563266620009216, 3.0587692166468217],
					[56.562905520279237, 3.0589276175100863],
					[56.562905512314906, 3.058927620393197],
					[56.562905498250942, 3.0589276274966535],
					[56.562905491257752, 3.0589276318575198],
					[56.562519333747211, 3.0591776038645384],
					[56.562061118099535, 3.0592108983342934],
					[56.561722273069215, 3.0591248123094439],
					[56.561541917207606, 3.0590276906652027],
					[56.56161129831677, 3.0585250275789888],
					[56.56161129121061, 3.0585249732615467],
					[56.561611247899215, 3.0585248773555991],
					[56.56161121258755, 3.0585248356265682],
					[56.561352949730157, 3.0583470601865241],
					[56.561169572277898, 3.0580359977585978],
					[56.561169559808121, 3.0580359817853013],
					[56.561169532187918, 3.0580359502603502],
					[56.561169516221554, 3.058035936467459],
					[56.56096401640459, 3.0578887376223536],
					[56.560963926624162, 3.0578887240271815],
					[56.560963774098369, 3.057888772474822],
					[56.560963709643509, 3.0578888364169403],
					[56.560824810121119, 3.0583027362021213],
					[56.560824810198717, 3.0583027378203247],
					[56.560824808566835, 3.0583027413378212],
					[56.56082480775089, 3.0583027430965619],
					[56.560660908067426, 3.0588555427186894],
					[56.560660907406671, 3.0588555477138186],
					[56.560660905114013, 3.0588555562263777],
					[56.560660903559707, 3.0588555613620332],
					[56.560466522987269, 3.0598609594272368],
					[56.560058229239786, 3.0600415130182639],
					[56.55960824613075, 3.0601831071754226],
					[56.559161054974354, 3.0602915051693254],
					[56.558647157152869, 3.0604054051459535],
					[56.558647143827201, 3.0604054088712482],
					[56.558647118146943, 3.0604054177994509],
					[56.558647105869966, 3.0604054246204977],
					[56.558313806415534, 3.0605831245878798],
					[56.55831380194779, 3.0605831252902354],
					[56.558313794954515, 3.0605831296502135],
					[56.558313790564341, 3.0605831319706733],
					[56.557833191016364, 3.0608942315142222],
					[56.557833191093927, 3.060894233132307],
					[56.557833189306827, 3.060894233413229],
					[56.557833189306827, 3.060894233413229],
					[56.557388788980759, 3.0611915338359674],
					[56.557388776781288, 3.0611915422745737],
					[56.557388756111678, 3.0611915618261007],
					[56.557388745776855, 3.0611915716018672],
					[56.556960953909119, 3.0617054626874403],
					[56.556622062661205, 3.0620220535568614],
					[56.556622055006969, 3.0620220629109069],
					[56.556622037988987, 3.0620220835178795],
					[56.556622031305857, 3.0620220943495311],
					[56.556244235964698, 3.0626248853586504],
					[56.555785941833243, 3.0632192774841727],
					[56.555785935072471, 3.0632192866973647],
					[56.555785924231593, 3.0632193047025629],
					[56.555785919335463, 3.0632193152529661],
					[56.555516526612585, 3.0637915972066945],
					[56.555183138702162, 3.0642470823395791],
					[56.555183133650893, 3.0642470896537231],
					[56.555183125335489, 3.0642471040012564],
					[56.555183121177798, 3.0642471111750282],
					[56.554847021334972, 3.0649277113200819],
					[56.554847019702883, 3.0649277148367169],
					[56.554847018070795, 3.0649277183533639],
					[56.554847017254758, 3.0649277201116809],
					[56.554588728958223, 3.0655220923204705],
					[56.554141549380574, 3.066035968407939],
					[56.554141538384265, 3.0660359831756474],
					[56.554141519227329, 3.066036015526004],
					[56.554141511066689, 3.0660360331086269],
					[56.553813711216087, 3.0669638330175566],
					[56.553813711293571, 3.0669638346354851],
					[56.553813709738883, 3.0669638397698837],
					[56.553813708922817, 3.0669638415281066],
					[56.55361651343464, 3.0676027278767335],
					[56.553288718860202, 3.0683055147997043],
					[56.553288714118537, 3.0683055285845957],
					[56.553288706344894, 3.0683055542559194],
					[56.553288703312909, 3.0683055661423566],
					[56.553122005303109, 3.0693110560527939],
					[56.552935921144261, 3.0699609987018595],
					[56.552660966914864, 3.0702581484034486],
					[56.552372083055381, 3.0704665381671359],
					[56.552372053990517, 3.0704665704381435],
					[56.552372012564497, 3.0704666453994718],
					[56.552372000203363, 3.0704666880897777],
					[56.552338699549431, 3.0710388877155133],
					[56.552338701372157, 3.0710389069898771],
					[56.552338705833755, 3.0710389437805414],
					[56.552338710259768, 3.0710389610164475],
					[56.552594209605203, 3.0718333603540473],
					[56.552594223586148, 3.0718333891315646],
					[56.552594260174104, 3.0718334388132407],
					[56.552594281964922, 3.0718334614754856],
					[56.552972072933684, 3.072108454889964],
					[56.553313748311894, 3.0724195324908381],
					[56.553555418124894, 3.0727722884524753],
					[56.553810906152918, 3.0735889500557443],
					[56.553933098151042, 3.0741778113642231],
					[56.55385540210235, 3.0749749652123595],
					[56.553710954976488, 3.0755664507778953],
					[56.553211206808406, 3.0754220225327762],
					[56.552891859676528, 3.0750887771665223],
					[56.552564070550865, 3.0745498954357622],
					[56.552563980933542, 3.074549847546427],
					[56.552563804941734, 3.074549857211494],
					[56.552563720277028, 3.07454991286777],
					[56.552291519872611, 3.0751138124533934],
					[56.552291515052808, 3.0751138246192182],
					[56.552291507200323, 3.0751138486706475],
					[56.552291504167663, 3.0751138605562627],
					[56.552169204161096, 3.0757055601942347],
					[56.552169202021965, 3.0757055719396993],
					[56.552169199608187, 3.0757055967683193],
					[56.552169200227155, 3.0757056097114188],
					[56.552202599102465, 3.0764332847712499],
					[56.55206920730518, 3.0771249425863827],
					[56.551827615309307, 3.0777166246846699],
					[56.551827613676842, 3.077716628200486],
					[56.551827611228113, 3.0777166334742314],
					[56.551827609595641, 3.0777166369900475],
					[56.551547011703633, 3.0785638304473233],
					[56.551258114619827, 3.0792832235445133],
					[56.551016516079748, 3.0798582222549307],
					[56.551016511998419, 3.0798582310440747],
					[56.551016506593776, 3.0798582498202749],
					[56.551016504299604, 3.0798582583294603],
					[56.550849807146378, 3.0806360484705637],
					[56.550594216121411, 3.0814249196320276],
					[56.550291524988602, 3.081966602345791],
					[56.550291524249587, 3.0819666057213606],
					[56.550291521723409, 3.0819666093767939],
					[56.550291520013516, 3.0819666112744679],
					[56.549980420104319, 3.0825999117040856],
					[56.549980416177313, 3.0825999237283215],
					[56.549980408168778, 3.0825999445411676],
					[56.549980405135372, 3.0825999564254927],
					[56.549841504859415, 3.083230557080229],
					[56.549841504120373, 3.0832305604557342],
					[56.549841503458588, 3.0832305654490617],
					[56.549841502642266, 3.0832305672067633],
					[56.549702604946752, 3.0840666552953584],
					[56.54939700842052, 3.0851416448194251],
					[56.549397005971464, 3.0851416500923436],
					[56.549397003676901, 3.0851416586008633],
					[56.549397003015045, 3.0851416635941034],
					[56.549244202723671, 3.0859805641436053],
					[56.54924420194051, 3.0859805854545637],
				],
			],
		},
		{
			fldNpdidField: "4005142",
			wlbNpdidWellbore: "4902",
			fldName: "GIMLE",
			fldCurrentActivitySatus: "Producing",
			wlbName: "34/10-48 S",
			fldDiscoveryYear: "2004",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=4005142",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=4005142",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "GIMLE UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=4902",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "4007192",
			fldDateUpdated: "2022-09-13 00:00:00",
			fldDateUpdatedMax: "2022-09-06 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[61.249630402148831, 2.3457693745826327],
					[61.24963040504695, 2.3457694151132173],
					[61.249630427784837, 2.3457694925773955],
					[61.249630447624646, 2.3457695295110015],
					[61.250441517169854, 2.3467194948139092],
					[61.250652606164806, 2.3475583493076484],
					[61.250652634667446, 2.3475583974969223],
					[61.250652714927249, 2.3475584721064315],
					[61.25065276570156, 2.3475584968649592],
					[61.251070721250699, 2.3476297806673427],
					[61.250699832572025, 2.3487804657357021],
					[61.249860998709551, 2.3492720272259184],
					[61.249860956131208, 2.3492720774072202],
					[61.249860911393171, 2.3492721935063883],
					[61.249860909142356, 2.349272257573324],
					[61.250591506091808, 2.3516639495913139],
					[61.250944204372111, 2.3533750404366227],
					[61.250944210815391, 2.3533750615140834],
					[61.25094422896094, 2.353375100683345],
					[61.250944239862633, 2.3533751208152793],
					[61.252005418271942, 2.3547833894130425],
					[61.252508107432824, 2.3565694548235978],
					[61.252508108597659, 2.3565694601879033],
					[61.252508113693509, 2.3565694722005386],
					[61.252508115750068, 2.356569477375825],
					[61.252885916092964, 2.3574583776662039],
					[61.252885918149509, 2.357458382841604],
					[61.252885922171487, 2.3574583913412699],
					[61.252885925119671, 2.3574583963276576],
					[61.254002625241306, 2.3594889964384733],
					[61.254002629172142, 2.3594890030874911],
					[61.254002637925524, 2.3594890161965489],
					[61.254002642839097, 2.3594890245078113],
					[61.255002642659825, 2.3607501241872662],
					[61.255002651140103, 2.360750131743262],
					[61.255002666317225, 2.3607501472331247],
					[61.255002673905814, 2.3607501549780507],
					[61.255619273431712, 2.3612501538381219],
					[61.256363771236011, 2.3618779524846749],
					[61.256363773201379, 2.3618779558095833],
					[61.256363776950174, 2.3618779587566543],
					[61.256363778733558, 2.3618779583788347],
					[61.256858179068118, 2.3622529588430754],
					[61.256858189331687, 2.3622529660220777],
					[61.256858208876174, 2.362252978717629],
					[61.256858219940483, 2.3622529838563526],
					[61.257416619886904, 2.3624973826573554],
					[61.257416629968525, 2.3624973861337266],
					[61.257416652006121, 2.3624973945600733],
					[61.257416662979452, 2.3624973978475232],
					[61.258652697310907, 2.3627279844858626],
					[61.25933875196452, 2.3634834340810098],
					[61.26001921422457, 2.3642389922283318],
					[61.260208102994731, 2.3652611344552854],
					[61.260335903115532, 2.3660278325992854],
					[61.260335904643789, 2.3660278453717218],
					[61.260335912067866, 2.3660278681208915],
					[61.260335917162898, 2.3660278801380432],
					[61.260835916631038, 2.3671722797333135],
					[61.260835922617716, 2.367172291562039],
					[61.260835936283534, 2.3671723129902853],
					[61.260835943161915, 2.3671723246302161],
					[61.261333137140156, 2.3678029168142154],
					[61.261830433330822, 2.368564008978634],
					[61.261830437261295, 2.3685640156304042],
					[61.26183044690557, 2.368564028556424],
					[61.261830451636818, 2.3685640331677327],
					[61.262516552054997, 2.3693195339103239],
					[61.262516554020202, 2.3693195372363247],
					[61.262516558751393, 2.3693195418478536],
					[61.262516560625727, 2.3693195433220979],
					[61.263691561145826, 2.3704501430793279],
					[61.263691564985308, 2.3704501478798927],
					[61.263691570608223, 2.3704501523030181],
					[61.263691574356812, 2.3704501552517621],
					[61.264622057890683, 2.3712001430882474],
					[61.26524424475955, 2.3719584275740822],
					[61.26524427385641, 2.3719584513553027],
					[61.265244338638659, 2.3719584862966738],
					[61.265244375215801, 2.3719584972681234],
					[61.266111075256866, 2.3720695985623257],
					[61.266111076148597, 2.3720695983736242],
					[61.266111078022881, 2.3720695998482002],
					[61.266111078914605, 2.372069599659496],
					[61.267161079411501, 2.3721779994931587],
					[61.26716116081321, 2.372177969168269],
					[61.267161270682351, 2.3721778579686865],
					[61.267161298167245, 2.3721777754306808],
					[61.267147503410783, 2.372067509574717],
					[61.267288733642395, 2.3722779117342987],
					[61.267288778716036, 2.3722779489777093],
					[61.267288881677089, 2.3722779908126665],
					[61.267288939564494, 2.3722779954041791],
					[61.268458339741379, 2.3720418969366301],
					[61.268458340633117, 2.3720418967479096],
					[61.268458342416537, 2.3720418963704764],
					[61.268836141760183, 2.3719612964058063],
					[61.268836148893868, 2.3719612948960336],
					[61.268836162178701, 2.3719612902130769],
					[61.268836169312394, 2.3719612887033041],
					[61.269452836983028, 2.3717362986538979],
					[61.26986670389438, 2.3717279992721121],
					[61.270522204379056, 2.3717141004762348],
					[61.27052221062106, 2.3717140991550396],
					[61.270522224979345, 2.3717140979873896],
					[61.270522232113031, 2.3717140964774548],
					[61.27103606097279, 2.3716308090430283],
					[61.271627679219371, 2.3719779602414337],
					[61.272285961583059, 2.3726084432531236],
					[61.272285969171037, 2.3726084510049734],
					[61.272285983182712, 2.3726084611403961],
					[61.272285990770726, 2.372608468892238],
					[61.273355489797481, 2.3733029662340797],
					[61.274288760145524, 2.3739279465637928],
					[61.274808138591091, 2.3746334196879948],
					[61.274808144213914, 2.3746334241133193],
					[61.274808155731947, 2.3746334385214505],
					[61.274808162337301, 2.3746334446105788],
					[61.275466561949273, 2.3752640449111664],
					[61.275466564806074, 2.3752640480502061],
					[61.275466573285605, 2.3752640556147697],
					[61.275466577034159, 2.3752640585651079],
					[61.275916576814886, 2.3756140584456227],
					[61.275916596267507, 2.3756140693039964],
					[61.275916634991155, 2.375614087315574],
					[61.275916655153857, 2.3756140942801314],
					[61.276499955391884, 2.375747395601246],
					[61.276500016392092, 2.3757473901817741],
					[61.276500122308583, 2.37574734530966],
					[61.276500169099172, 2.3757473073322877],
					[61.276777968801404, 2.3753112067960434],
					[61.276777980195611, 2.375311181923029],
					[61.276777996378669, 2.3753111260874138],
					[61.27677799956578, 2.3753110992073987],
					[61.27677519997502, 2.3745221980726847],
					[61.276775199339191, 2.3745221851044231],
					[61.276775195210739, 2.3745221560287995],
					[61.276775191899759, 2.3745221436266553],
					[61.276669591604126, 2.374166642703325],
					[61.276669588383939, 2.3741666321538784],
					[61.27666958025101, 2.3741666132850185],
					[61.276669575247396, 2.374166603113014],
					[61.275944651008714, 2.3728417428784421],
					[61.276339034324003, 2.3724834475397825],
					[61.276339062024206, 2.3724834061115727],
					[61.276339092712362, 2.3724833153837772],
					[61.27633909668284, 2.3724832677480094],
					[61.275866896852307, 2.3696582678737546],
					[61.27586689121209, 2.3696582447349654],
					[61.275866872888891, 2.3696582018206889],
					[61.275866861097633, 2.3696581818563236],
					[61.274841864176032, 2.3682637866975966],
					[61.273236267198378, 2.3658165908051019],
					[61.273236262285131, 2.36581658248791],
					[61.273236249874493, 2.3658165682726815],
					[61.273236244069508, 2.3658165601444292],
					[61.271580756029479, 2.3640942745103204],
					[61.270636284065873, 2.3627027156935601],
					[61.269336292637135, 2.3579860466024574],
					[61.269336290580604, 2.3579860414243656],
					[61.269336288250919, 2.3579860306899532],
					[61.269336286194388, 2.3579860255118814],
					[61.268300186112533, 2.3554110248576121],
					[61.26830018512976, 2.355411023194768],
					[61.268300184147002, 2.3554110215319133],
					[61.268300184147002, 2.3554110215319133],
					[61.266977983997705, 2.3523527213197979],
					[61.266977974078543, 2.3523527028409852],
					[61.266977950855711, 2.3523526703443181],
					[61.266977938352618, 2.3523526542852466],
					[61.265988370858253, 2.3514137859824382],
					[61.266014099973184, 2.3486028232810461],
					[61.266491894671738, 2.3465556458579648],
					[61.266491897143929, 2.3465556228774913],
					[61.266491896647125, 2.3465555762011672],
					[61.266491893678129, 2.3465555525053383],
					[61.265641893691011, 2.3430416529457068],
					[61.265641893417261, 2.3430416473902103],
					[61.265641890286027, 2.3430416386995772],
					[61.26564188822897, 2.343041633523093],
					[61.265386288232556, 2.3423221326976305],
					[61.265386284118357, 2.3423221223448634],
					[61.265386275089512, 2.3423221036806865],
					[61.265386270083653, 2.3423220935174576],
					[61.264736269777501, 2.3412776944905471],
					[61.264736257273199, 2.3412776784370757],
					[61.264736227806267, 2.3412776472779084],
					[61.264736210934934, 2.3412776340239954],
					[61.263919565020593, 2.3407304702191891],
					[61.263127988149769, 2.3385638309166992],
					[61.26312797822883, 2.3385638124445558],
					[61.26312795571193, 2.3385637760691571],
					[61.263127943207323, 2.3385637600175544],
					[61.262447343284926, 2.3378720607203234],
					[61.262447321772363, 2.3378720447140977],
					[61.262447277146649, 2.3378720167841616],
					[61.262447253233127, 2.3378720069017369],
					[61.26173341073585, 2.3376776222586204],
					[61.26118625018443, 2.3370554682116169],
					[61.261186226705853, 2.3370554488827522],
					[61.261186177439072, 2.3370554182001624],
					[61.26118614977625, 2.3370554053741892],
					[61.260675110014056, 2.3369248227423829],
					[61.260133477671573, 2.3363027002494636],
					[61.259339091043898, 2.3337832401708214],
					[61.259339077990496, 2.3337832130144411],
					[61.25933904404171, 2.3337831641120768],
					[61.259339024037956, 2.3337831421763902],
					[61.258555723848154, 2.3331637438474653],
					[61.258555699660228, 2.3331637284155349],
					[61.258555649957941, 2.3331637071880724],
					[61.258555622660325, 2.3331637017720177],
					[61.258047222685107, 2.3331054008087651],
					[61.258047163836601, 2.3331054133315128],
					[61.258047062851013, 2.3331054666244198],
					[61.258047020805286, 2.3331055092459301],
					[61.257708125803404, 2.333780500120139],
					[61.256527654088472, 2.3356026571200417],
					[61.255683345842009, 2.3361830265093286],
					[61.255069561208387, 2.3353526814112775],
					[61.255069505334404, 2.3353526446595776],
					[61.25506938302167, 2.3353526126861923],
					[61.255069316582919, 2.3353526174644887],
					[61.254427778534435, 2.335646989334105],
					[61.253883345652, 2.3355192057021572],
					[61.25388329962982, 2.3355192042669857],
					[61.253883214122823, 2.3355192243233316],
					[61.253883172763381, 2.3355192443429855],
					[61.253002672830597, 2.3362415449338014],
					[61.253002656528587, 2.336241561493853],
					[61.253002630348767, 2.3362415969888315],
					[61.253002617796021, 2.3362416164925852],
					[61.25272484275375, 2.3368498609414314],
					[61.251958196127056, 2.3373192298642187],
					[61.251958152753097, 2.3373192821055664],
					[61.251958111866294, 2.3373194030238169],
					[61.251958113370463, 2.3373194700392665],
					[61.252407375294823, 2.3384970101856628],
					[61.251247308046253, 2.3377526322632094],
					[61.251247195585115, 2.3377526355967655],
					[61.251247044981504, 2.3377527424283175],
					[61.25124700613005, 2.3377528498177713],
					[61.251544200779122, 2.3389306274432298],
					[61.251606219071448, 2.3409333203987175],
					[61.250158201899787, 2.3417498253272018],
					[61.250158168311359, 2.3417498567770099],
					[61.250158117914417, 2.3417499310741965],
					[61.250158101997584, 2.3417499737321528],
					[61.249630402148831, 2.3457693745826327],
				],
				[
					[
						[61.229999802117661, 2.3376277691173102],
						[61.229999838255594, 2.3376278623735072],
						[61.229999970643718, 2.3376279706989447],
						[61.230000068768526, 2.3376279872391348],
						[61.230250069233719, 2.3375362883334878],
						[61.230250099893901, 2.3375362706062455],
						[61.230250152550255, 2.3375362239081863],
						[61.230250174637732, 2.3375361967870996],
						[61.230544575435516, 2.3370000963442235],
						[61.230544580420222, 2.3370000878087462],
						[61.230544588423733, 2.3370000674171161],
						[61.230544592425488, 2.3370000572213017],
						[61.231047387045741, 2.3353139703097061],
						[61.231422380737797, 2.3345111852210545],
						[61.23172515015532, 2.3338890485578947],
						[61.23210280585463, 2.3337030209006633],
						[61.232691621163028, 2.3339557844975163],
						[61.232691674683785, 2.3339557918147129],
						[61.232691778389146, 2.3339557753804767],
						[61.232691826881791, 2.333955753857917],
						[61.23291122668639, 2.3337751544633787],
						[61.232911237020801, 2.333775144789592],
						[61.232911255814905, 2.3337751239712055],
						[61.232911265257641, 2.3337751144869614],
						[61.233158463968437, 2.3334168140137956],
						[61.233533421015714, 2.332883476925959],
						[61.233908299433523, 2.332789107035564],
						[61.234213849359719, 2.33286959305569],
						[61.234213906881948, 2.3328695901736758],
						[61.234214012348403, 2.3328695546665745],
						[61.2342140593096, 2.3328695203810828],
						[61.2345557012002, 2.3324223985242973],
						[61.235102741150371, 2.3324085022563081],
						[61.235613787813712, 2.3327529661021322],
						[61.235613826359291, 2.3327529803404441],
						[61.235613904594125, 2.3327529954877142],
						[61.235613945975345, 2.3327529941674952],
						[61.236033345646611, 2.3326529944225718],
						[61.236033375688486, 2.3326529824267328],
						[61.236033428982488, 2.3326529486617598],
						[61.236033453217622, 2.3326529285531015],
						[61.236330752919997, 2.332297327172137],
						[61.236330758087114, 2.3322973223344383],
						[61.236330765655055, 2.3322973113777734],
						[61.236330769930547, 2.3322973067296591],
						[61.236666869792664, 2.3317584061905996],
						[61.236666877269187, 2.3317583933835819],
						[61.236666887581201, 2.331758365017409],
						[61.236666893183049, 2.3317583507395252],
						[61.237041892620354, 2.33034175155922],
						[61.237427991642903, 2.3289222572039798],
						[61.237836290230412, 2.3276778624061056],
						[61.237836290138972, 2.3276778605559461],
						[61.237836291030597, 2.3276778603662156],
						[61.23783629093915, 2.32767785851608],
						[61.238547391352199, 2.3253694591132623],
						[61.238547392060866, 2.3253694552231021],
						[61.238547392769583, 2.325369451332949],
						[61.23854739436991, 2.3253694472529913],
						[61.239052909637671, 2.3233309866091614],
						[61.23935810098051, 2.3236278852431402],
						[61.239360899797923, 2.3238250024746003],
						[61.239360901147023, 2.3238250115358321],
						[61.239360903045053, 2.3238250316983735],
						[61.239360904394154, 2.3238250407596035],
						[61.239433104279783, 2.3241722418382156],
						[61.239433109561318, 2.3241722575410004],
						[61.239433122982255, 2.3241722920774759],
						[61.239433131921814, 2.3241723088710953],
						[61.239558119904345, 2.3243667895409437],
						[61.23963041289371, 2.3245611698069211],
						[61.239630418083742, 2.3245611836596187],
						[61.239630431847338, 2.3245612069052233],
						[61.239630438729129, 2.324561218528018],
						[61.239702638894748, 2.3246584183989678],
						[61.239702645593596, 2.324658426321331],
						[61.23970266086598, 2.3246584436366344],
						[61.239702668364941, 2.3246584495189366],
						[61.23981376762989, 2.3247557500109335],
						[61.239813864155401, 2.3247557705941762],
						[61.239814027392285, 2.3247557190128578],
						[61.239814094195154, 2.3247556486985146],
						[61.239864093730809, 2.3245583486970274],
						[61.239864095239611, 2.324558342766613],
						[61.239864098074314, 2.3245583272052444],
						[61.239864098599995, 2.3245583196144075],
						[61.239933498129488, 2.3238639314800351],
						[61.240075195318333, 2.3232194422974102],
						[61.240075195935447, 2.3232194365567538],
						[61.240075198061334, 2.3232194248855804],
						[61.240075198678483, 2.3232194191449369],
						[61.240108498872715, 2.3228722228835399],
						[61.240177997683979, 2.3223278244509835],
						[61.24017799919271, 2.3223278185204026],
						[61.240178000335355, 2.3223278051887677],
						[61.240178000060823, 2.322327799637995],
						[61.240177999992056, 2.3219306009215184],
						[61.240177999900546, 2.3219305990712602],
						[61.240177999809013, 2.3219305972210083],
						[61.24017240030885, 2.3214361261793885],
						[61.240227953432615, 2.3212390951036936],
						[61.240313906817583, 2.321236300446226],
						[61.240313966898967, 2.3212362764290133],
						[61.240314062048803, 2.3212361963276553],
						[61.240314096225688, 2.321236140433435],
						[61.240366896329888, 2.3209861407194481],
						[61.240366896855392, 2.3209861331284358],
						[61.240366898706476, 2.3209861159062091],
						[61.240366900032058, 2.320986106274995],
						[61.240383500428479, 2.320541707030332],
						[61.240383500336947, 2.3205417051800716],
						[61.240383500153861, 2.3205417014795469],
						[61.240383500062336, 2.3205416996292674],
						[61.240380800165021, 2.3198499990809927],
						[61.24038080041457, 2.3195556002602276],
						[61.240380800174002, 2.3193556004842888],
						[61.240380799990895, 2.3193555967837587],
						[61.240380799716235, 2.3193555912329837],
						[61.240380799624688, 2.3193555893827265],
						[61.240355802379554, 2.3189111319454878],
						[61.240375180680161, 2.3188611875158465],
						[61.240402972340469, 2.3188140030216204],
						[61.24046405807475, 2.3187140242052431],
						[61.240516840912512, 2.318661240667526],
						[61.240516853645865, 2.3186612248655023],
						[61.240516877237816, 2.3186611917912177],
						[61.240516886130003, 2.3186611711984653],
						[61.240536285933558, 2.318611171270311],
						[61.240536290025396, 2.3186111629191988],
						[61.24053629535112, 2.3186111430864429],
						[61.240536296676517, 2.3186111334550872],
						[61.240561296619518, 2.3184639323386236],
						[61.240561298311192, 2.3184639301083267],
						[61.240561298036496, 2.3184639245575194],
						[61.24056129883656, 2.3184639225172341],
						[61.240605798953929, 2.3180667222317712],
						[61.240605798679219, 2.3180667166809559],
						[61.240605799913034, 2.3180667051992878],
						[61.24060579963831, 2.3180666996484947],
						[61.240605799553414, 2.3178194859842272],
						[61.240658273647746, 2.3177697001347153],
						[61.240730418808909, 2.3178667913858026],
						[61.240774808215178, 2.3180139557727872],
						[61.240802607136729, 2.3181139531197443],
						[61.240802610360987, 2.3181139636513488],
						[61.240802616626461, 2.318113981013981],
						[61.24080262065079, 2.3181139895052749],
						[61.240874813923476, 2.3182583757791635],
						[61.240966506381781, 2.3185528491996625],
						[61.240985904406671, 2.3186500381661013],
						[61.240985913987849, 2.3186500679109572],
						[61.240985941791692, 2.3186501199496679],
						[61.240985960997556, 2.3186501439037874],
						[61.241085961396934, 2.3187473432265135],
						[61.241085963363304, 2.3187473465471027],
						[61.241085968004526, 2.3187473492976336],
						[61.241085969970896, 2.318747352618201],
						[61.24125546953676, 2.3188918516542096],
						[61.241255533781434, 2.3188918753597405],
						[61.241255661764953, 2.3188918761330686],
						[61.241255727378679, 2.3188918546711181],
						[61.241316688997181, 2.3188419679692678],
						[61.24141651604284, 2.3189390001054395],
						[61.241435903780051, 2.3190389377562504],
						[61.241435904946357, 2.3190389431171963],
						[61.241435907187395, 2.3190389519887913],
						[61.241435908262112, 2.3190389554994413],
						[61.241463706780905, 2.3191333537808254],
						[61.241488706063635, 2.3192333486672108],
						[61.241488721269093, 2.3192333828236973],
						[61.241488761921659, 2.3192334396050027],
						[61.241488787551901, 2.3192334659304858],
						[61.241633187581229, 2.3193307658248488],
						[61.241633200521719, 2.3193307724165164],
						[61.241633226402655, 2.3193307855998646],
						[61.241633239251605, 2.3193307903412008],
						[61.241772109104375, 2.3193751803108311],
						[61.241841583155221, 2.3194251625035891],
						[61.241841589671232, 2.3194251667246424],
						[61.241841605469688, 2.3194251764470826],
						[61.241841612877302, 2.3194251804781447],
						[61.241938772784906, 2.3194723615822781],
						[61.241977645643239, 2.3195195269889961],
						[61.241977717753194, 2.3195195639791475],
						[61.241977865901788, 2.3195195716770414],
						[61.241977940957192, 2.3195195407244258],
						[61.242077940617087, 2.3194195417846895],
						[61.242077955933837, 2.3194195235617987],
						[61.242077980142874, 2.3194194847452749],
						[61.242077989835245, 2.3194194621112931],
						[61.24214450830523, 2.3192197096920717],
						[61.242216546081828, 2.3192668383248964],
						[61.242291521903319, 2.3194139912646197],
						[61.242291524852824, 2.3194139962457414],
						[61.242291531826623, 2.319414009718697],
						[61.242291535667768, 2.3194140145098125],
						[61.242427636215957, 2.3196084149752818],
						[61.242427672911823, 2.3196084464233619],
						[61.242427756979062, 2.3196084883457364],
						[61.242427804442045, 2.3196085006703964],
						[61.242552803948385, 2.3196058006031022],
						[61.242552843088198, 2.3196057903927407],
						[61.242552913593975, 2.3196057585392822],
						[61.242552946743189, 2.3196057365161806],
						[61.24269448730189, 2.319453000622115],
						[61.242766555585398, 2.3194530003902281],
						[61.242833100184825, 2.3196500321699913],
						[61.242833099903159, 2.3198471455566367],
						[61.242716526850622, 2.3200470989536925],
						[61.242716524450394, 2.3200471050748663],
						[61.242716517866697, 2.3200471176971695],
						[61.242716514574838, 2.320047124008334],
						[61.242530414969117, 2.3204971248103359],
						[61.242530414169053, 2.3204971268506935],
						[61.242530411768804, 2.3204971329717976],
						[61.242530410077109, 2.3204971352021313],
						[61.242397017205334, 2.3208943168108727],
						[61.242130427582786, 2.3213470996029959],
						[61.24213042170755, 2.3213471083341037],
						[61.242130412814959, 2.3213471289272305],
						[61.242130408814475, 2.3213471391288203],
						[61.242022011429221, 2.3216916346113736],
						[61.241827614217883, 2.3221916278695187],
						[61.241827613326237, 2.3221916280594423],
						[61.241827611726023, 2.322191632139988],
						[61.241827610925895, 2.3221916341802653],
						[61.241688710817904, 2.3225916339894521],
						[61.241688710017804, 2.3225916360297192],
						[61.241688709309187, 2.3225916399203239],
						[61.241688707617456, 2.3225916421504826],
						[61.241555408069743, 2.3230360431214589],
						[61.241555406561005, 2.3230360490522854],
						[61.241555403635047, 2.3230360627642601],
						[61.241555402217806, 2.3230360705454269],
						[61.241458102273761, 2.3236832703489529],
						[61.241458102273761, 2.3236832703489529],
						[61.241458102365257, 2.3236832721992897],
						[61.241458101565144, 2.3236832742395146],
						[61.241347002321326, 2.3245249742605152],
						[61.241347000812532, 2.3245249801912302],
						[61.241346999669616, 2.3245249935232932],
						[61.24134700003556, 2.3245250009246443],
						[61.241352600253322, 2.325661101194421],
						[61.241352599727549, 2.3256611087856114],
						[61.241352601259486, 2.3256611215481731],
						[61.241352602425501, 2.3256611269093899],
						[61.241427601843462, 2.3262028279591553],
						[61.241427601934923, 2.326202829809501],
						[61.241427603100924, 2.3262028351707498],
						[61.241427604175485, 2.326202838681636],
						[61.241474803834812, 2.3264500372846362],
						[61.241474807149928, 2.3264500496677014],
						[61.241474814763251, 2.326450076094396],
						[61.241474819861622, 2.3264500880978671],
						[61.24154701992984, 2.3265972873417926],
						[61.241547022879097, 2.3265972923234952],
						[61.24154702877766, 2.3265973022868662],
						[61.241547031726917, 2.3265973072685466],
						[61.241672031700517, 2.3267918071488034],
						[61.241672042331594, 2.326791821714409],
						[61.241672065194081, 2.3267918467653077],
						[61.24167207831713, 2.3267918570608135],
						[61.241733178135398, 2.3268390584261454],
						[61.241733225963657, 2.3268390781630575],
						[61.24173332568968, 2.3268390905929826],
						[61.241733376787224, 2.3268390853261716],
						[61.241852876889588, 2.3267890850214279],
						[61.241852886514657, 2.3267890792329742],
						[61.241852906747894, 2.3267890693166779],
						[61.24185291637297, 2.3267890635282398],
						[61.241997138219006, 2.3266863904918029],
						[61.242083126733121, 2.3268334007286651],
						[61.242083151561786, 2.3268334291010921],
						[61.242083208786568, 2.3268334748858819],
						[61.242083241982883, 2.3268334902580805],
						[61.242230541765167, 2.3268779910250359],
						[61.242230586987084, 2.3268779944882696],
						[61.242230673658604, 2.3268779797793497],
						[61.242230716091257, 2.3268779632677927],
						[61.242444515612, 2.3267251624662246],
						[61.242444530403993, 2.3267251518380259],
						[61.24244455605556, 2.3267251239392075],
						[61.24244456709809, 2.3267251103693916],
						[61.242675139645129, 2.3263751526206922],
						[61.242863806600006, 2.3262724785666968],
						[61.242908117048628, 2.3263694825422152],
						[61.243080416845913, 2.3267611802739445],
						[61.243080439091166, 2.3267612110670228],
						[61.243080493023832, 2.3267612631635703],
						[61.243080524711253, 2.3267612844670449],
						[61.243197125208262, 2.3268084857065374],
						[61.243197172396385, 2.3268084924909993],
						[61.243197263000248, 2.3268084844238706],
						[61.243197308199221, 2.32680846919268],
						[61.243358407568572, 2.3267057683798549],
						[61.243358424052339, 2.3267057555212491],
						[61.243358453270439, 2.3267057268623157],
						[61.243358466004771, 2.326705711062008],
						[61.243458466036657, 2.3265557102679648],
						[61.24345847511298, 2.3265556933763172],
						[61.243458489607605, 2.3265556585017602],
						[61.243458495917544, 2.326555640329079],
						[61.243519594553149, 2.3262583464307229],
						[61.24364187674869, 2.3258112104172435],
						[61.243872331940231, 2.3256084509062971],
						[61.243872343074095, 2.3256084391859306],
						[61.243872361775281, 2.3256084165045787],
						[61.243872371034421, 2.3256084033134039],
						[61.243964063381362, 2.3254584166983485],
						[61.24413345278969, 2.3252584297266714],
						[61.244133465340873, 2.3252584102243583],
						[61.24413348589362, 2.3252583703185095],
						[61.244133493895156, 2.3252583499149573],
						[61.24415849399599, 2.3251583495115642],
						[61.244158494704678, 2.3251583456207032],
						[61.244158496304998, 2.3251583415399728],
						[61.24415849612204, 2.3251583378389706],
						[61.24417796472261, 2.3250584997009263],
						[61.244233131319248, 2.325058500150667],
						[61.244249803076599, 2.3251556340860655],
						[61.244249803259557, 2.3251556377870783],
						[61.244249805317246, 2.3251556429588884],
						[61.244249804608593, 2.3251556468497689],
						[61.244296999801129, 2.3253528243153907],
						[61.244296999787259, 2.325747199651647],
						[61.244296999878721, 2.3257472015021761],
						[61.244296999878721, 2.3257472015021761],
						[61.244299800046555, 2.3260944009479907],
						[61.24429979973354, 2.3264388997257974],
						[61.244299800099377, 2.3264389071278551],
						[61.244299800831072, 2.326438921932005],
						[61.244299801997101, 2.3264389272937245],
						[61.24432760198799, 2.3266361272764766],
						[61.244327602079451, 2.3266361291269955],
						[61.244327603336927, 2.3266361363392454],
						[61.244327604320013, 2.3266361379999663],
						[61.244347004289722, 2.3267361385060581],
						[61.244347039589556, 2.3267361964410838],
						[61.244347141807836, 2.3267362775303018],
						[61.244347207834636, 2.326736300874308],
						[61.244419408286369, 2.3267334990052593],
						[61.24441945892665, 2.3267334844848917],
						[61.244419545277871, 2.3267334268324409],
						[61.244419580280116, 2.3267333875912057],
						[61.24449187967376, 2.3265833863165915],
						[61.244491881365569, 2.3265833840864318],
						[61.244491883766102, 2.3265833779653988],
						[61.244491885457904, 2.3265833757352246],
						[61.244613988358353, 2.3262836143276577],
						[61.244677681338693, 2.3263307604081453],
						[61.2446777100769, 2.3263307767300172],
						[61.244677771371215, 2.3263307954708687],
						[61.244677803127153, 2.3263307999302074],
						[61.244891589351418, 2.3263280003401108],
						[61.245010916345038, 2.326522283217662],
						[61.245102603027433, 2.326816738999594],
						[61.245158100749826, 2.3274083184663872],
						[61.24515810093272, 2.3274083221675257],
						[61.245158102190132, 2.3274083293800212],
						[61.245158103173232, 2.32740833104079],
						[61.245230399771579, 2.3278528184374454],
						[61.2452581001507, 2.3292861006261738],
						[61.245247000254565, 2.3309666980351507],
						[61.245247000437367, 2.3309667017363225],
						[61.245246999728536, 2.3309667056272119],
						[61.24524699991133, 2.3309667093283877],
						[61.24526919973021, 2.3314611083926966],
						[61.24526920098743, 2.3314611156053693],
						[61.245269201718578, 2.3314611304101285],
						[61.245269202975777, 2.331461137622806],
						[61.245297002861847, 2.3316083366379603],
						[61.245297005010684, 2.3316083436609909],
						[61.245297008325352, 2.3316083560461092],
						[61.245297009582551, 2.3316083632588191],
						[61.245394205686267, 2.3319028504204651],
						[61.24546089992004, 2.3322472200345876],
						[61.245469200180743, 2.3337305973851352],
						[61.245430399965315, 2.3351166921551858],
						[61.24536369981945, 2.3365499912629613],
						[61.245363699910783, 2.3365499931135743],
						[61.245363700276059, 2.3365500005160538],
						[61.245363699567079, 2.3365500044068233],
						[61.245388700372992, 2.3376389052409317],
						[61.245388700920856, 2.3376389163446718],
						[61.245388704874145, 2.3376389416848777],
						[61.245388708188258, 2.3376389540707097],
						[61.245485907894057, 2.3379806559110237],
						[61.245485943807012, 2.3379807081196398],
						[61.245486042887634, 2.3379807805639556],
						[61.245486105072338, 2.3379807991385357],
						[61.245605605000222, 2.3379779994790111],
						[61.245605646907968, 2.3379779905723694],
						[61.245605721603006, 2.3379779522569466],
						[61.245605756264858, 2.3379779243198042],
						[61.245766856169134, 2.3377779241387699],
						[61.245766866321155, 2.3377779107607708],
						[61.245766882693331, 2.3377778773602205],
						[61.245766890079075, 2.3377778627001078],
						[61.245864090400573, 2.3374778619820495],
						[61.24586409200127, 2.3374778579016966],
						[61.245864094128365, 2.3374778462292336],
						[61.245864095729061, 2.3374778421488966],
						[61.246002996181673, 2.3368333414250384],
						[61.246002996181673, 2.3368333414250384],
						[61.246169595758879, 2.3360389403278283],
						[61.246169597176845, 2.3360389325460456],
						[61.246169598938508, 2.3360389134707158],
						[61.246169600265155, 2.3360389038382698],
						[61.246186299652194, 2.3352472092831755],
						[61.246227998215815, 2.3346528304324794],
						[61.246411291667044, 2.3339083576810498],
						[61.246483473662749, 2.3337084051625099],
						[61.246669520126289, 2.3335084657366512],
						[61.246783253160594, 2.3334558413463422],
						[61.246874855251036, 2.3335529369924317],
						[61.246874857125697, 2.3335529384638485],
						[61.246874860966379, 2.3335529432573918],
						[61.246874862841025, 2.3335529447288086],
						[61.246974863469859, 2.3336473458209475],
						[61.246974865344527, 2.3336473472923696],
						[61.246974868202166, 2.3336473504248638],
						[61.246974870076855, 2.3336473518962846],
						[61.247147070116149, 2.3337945518646546],
						[61.247147126242496, 2.3337945754605545],
						[61.247147241217561, 2.3337945865406122],
						[61.247147299974891, 2.3337945721740834],
						[61.247238999742429, 2.3337418725727304],
						[61.247239004200679, 2.3337418716245506],
						[61.247239012843046, 2.3337418641760501],
						[61.247239017209907, 2.3337418613771388],
						[61.247380716605647, 2.3336390614184808],
						[61.247380744842246, 2.3336390311009358],
						[61.247380784717599, 2.3336389627749936],
						[61.247380799031312, 2.3336389241976905],
						[61.247422397281589, 2.333294433513081],
						[61.247519595345658, 2.3328472419151383],
						[61.247519596672049, 2.3328472322821581],
						[61.247519599142159, 2.3328472093147257],
						[61.247519600468564, 2.3328471996817455],
						[61.247516900242601, 2.3323527985302177],
						[61.247516899870384, 2.3320583162011901],
						[61.247558491937745, 2.3318083673104981],
						[61.247586259946154, 2.3317584227991008],
						[61.247744482080712, 2.3316057988773662],
						[61.24790830261275, 2.3316030003626222],
						[61.248097171196143, 2.3316002008306285],
						[61.248241567751414, 2.331644568362655],
						[61.248358127929592, 2.3318390021028734],
						[61.248358137668554, 2.3318390168628791],
						[61.248358159638578, 2.331839042112243],
						[61.248358171961065, 2.3318390544523666],
						[61.248474871729194, 2.3319362548458717],
						[61.248474891184799, 2.3319362656685239],
						[61.248474931879329, 2.3319362869344116],
						[61.248474953918503, 2.3319362953371723],
						[61.248663854106255, 2.3319807947560589],
						[61.248663881221105, 2.3319807964683386],
						[61.248663935176673, 2.3319807943404962],
						[61.248663962017346, 2.3319807905003862],
						[61.248825062091768, 2.3319279896260436],
						[61.248825086783597, 2.3319279787620522],
						[61.248825132944035, 2.33192794649832],
						[61.248825153703741, 2.3319279289898858],
						[61.248950153918109, 2.3317779277862289],
						[61.248950164869726, 2.3317779123643945],
						[61.248950183023595, 2.3317778785779089],
						[61.248950191117522, 2.3317778600235486],
						[61.248997390826148, 2.3316278608017682],
						[61.248997392335269, 2.3316278548699136],
						[61.248997394644618, 2.3316278468975273],
						[61.248997396153719, 2.3316278409656808],
						[61.249039095998363, 2.3314306408917447],
						[61.249039097324655, 2.3314306312582396],
						[61.249039098994224, 2.3314306103301261],
						[61.249039100229147, 2.3314305988458051],
						[61.249036299939739, 2.3309361026892415],
						[61.249061299784678, 2.3302417083803619],
						[61.249083499605717, 2.329700013508913],
						[61.249119596666304, 2.329302839800139],
						[61.249241890772069, 2.3289028577800117],
						[61.249241893172673, 2.3289028516581904],
						[61.249241896007753, 2.3289028360925084],
						[61.249241898408386, 2.32890282997068],
						[61.249383496791744, 2.3279611358243031],
						[61.249511295699989, 2.3273667427257192],
						[61.249511297026025, 2.3273667330919219],
						[61.249511298969296, 2.3273667177157975],
						[61.24951130029531, 2.3273667080819873],
						[61.249552999821702, 2.3264250095870374],
						[61.249552999638773, 2.3264250058853926],
						[61.249553000164553, 2.3264249982922198],
						[61.249552999981638, 2.3264249945905724],
						[61.249530799555259, 2.3256860935275774],
						[61.249530800446905, 2.3256860933376942],
						[61.249530800355444, 2.3256860914868716],
						[61.249530800355444, 2.3256860914868716],
						[61.249500200379146, 2.3249916900600018],
						[61.249500199121563, 2.3249916828466457],
						[61.249500197315115, 2.3249916645283619],
						[61.24950019507444, 2.3249916556540744],
						[61.249455795320792, 2.3247971557864782],
						[61.249455760111516, 2.3247970996954326],
						[61.249455661641491, 2.3247970215424547],
						[61.249455600163991, 2.3247969991007311],
						[61.249366700436184, 2.3247970006215803],
						[61.249366661112951, 2.3247970071266351],
						[61.249366588731156, 2.3247970375053995],
						[61.249366556564226, 2.3247970611891891],
						[61.249266556879761, 2.3248998613372445],
						[61.249266544054223, 2.3248998752905954],
						[61.249266523227256, 2.3248999096510063],
						[61.249266514151209, 2.3248999265463497],
						[61.249169214170081, 2.3251499276507195],
						[61.249169210969505, 2.3251499358134766],
						[61.249169204751247, 2.3251499558406286],
						[61.249169203333921, 2.3251499636236175],
						[61.249133132226738, 2.3253470041853768],
						[61.249036195578185, 2.3253497966993946],
						[61.248961289043322, 2.3252527176306046],
						[61.248936296617053, 2.3251027686935632],
						[61.248889099822662, 2.3248082830761136],
						[61.248886300209648, 2.3246110968162599],
						[61.248886293121579, 2.3246110627908889],
						[61.248886267262726, 2.3246109953581464],
						[61.248886247783304, 2.324610965842238],
						[61.248752947604153, 2.3244637660701284],
						[61.248752913950142, 2.3244637414437825],
						[61.248752836582959, 2.3244637074254237],
						[61.248752795636179, 2.3244636993144487],
						[61.248627956382549, 2.3244664956849377],
						[61.248580804795537, 2.3242721951738021],
						[61.24862799004881, 2.3240250698155487],
						[61.248714016668636, 2.3238723993969224],
						[61.248786009885329, 2.3238724001407047],
						[61.248913749823622, 2.3240168320046641],
						[61.24891381274184, 2.3240168653592992],
						[61.248913945024121, 2.3240168801982608],
						[61.248914013588063, 2.3240168637233016],
						[61.24898621361524, 2.323966863836092],
						[61.248986224040273, 2.3239668560046387],
						[61.248986243907275, 2.3239668386808292],
						[61.248986253257698, 2.3239668273377356],
						[61.249072353186918, 2.3238640271496531],
						[61.249072363337447, 2.3238640137657516],
						[61.24907238078066, 2.3238639838661896],
						[61.24907238807333, 2.3238639673505035],
						[61.249161288360916, 2.3236139677643401],
						[61.249161289869654, 2.3236139618320801],
						[61.249161292978634, 2.3236139518183854],
						[61.249161293687251, 2.3236139479268525],
						[61.249255793953481, 2.3232194465275948],
						[61.249255796970921, 2.3232194346630175],
						[61.249255798547679, 2.3232194118835992],
						[61.24925579978192, 2.3232194003989144],
						[61.249255799921258, 2.3226750013814219],
						[61.249275200400824, 2.3212417043664244],
						[61.249291900017511, 2.3206472062036454],
						[61.249291898027366, 2.3206471841842049],
						[61.249291890663656, 2.3206471446069807],
						[61.249291885107006, 2.3206471233476322],
						[61.249272404681449, 2.3205999727477233],
						[61.249447309681074, 2.3193117753826296],
						[61.249663711217387, 2.3197500671484823],
						[61.249827602353825, 2.3204500296642614],
						[61.249924801093186, 2.321772214623341],
						[61.249924801276237, 2.3217722183250151],
						[61.24992480173389, 2.3217722275791681],
						[61.249924802900111, 2.3217722329416528],
						[61.250099803092652, 2.3228278325247067],
						[61.25009980435032, 2.3228278397381259],
						[61.250099806774188, 2.3228278523141013],
						[61.250099808923544, 2.3228278593375395],
						[61.250530409356521, 2.324227858473789],
						[61.250530411597289, 2.3242278673482981],
						[61.250530417770527, 2.3242278828665981],
						[61.25053042081138, 2.3242278897002975],
						[61.250838714905704, 2.3248417763553788],
						[61.251044206879854, 2.3254528540104138],
						[61.251185902944499, 2.3260694377219986],
						[61.251349802393321, 2.3271250305746771],
						[61.251349803742258, 2.3271250396394931],
						[61.251349809206474, 2.3271250590504917],
						[61.251349812338646, 2.3271250677356012],
						[61.251572011695892, 2.3277361689515383],
						[61.251572017685845, 2.3277361807691603],
						[61.251572030557419, 2.3277362042145242],
						[61.25157203842209, 2.3277362175034169],
						[61.251949837641952, 2.3282584163015279],
						[61.25194986228756, 2.3282584409815867],
						[61.251949918254702, 2.3282584795681998],
						[61.251949948684569, 2.328258493664586],
						[61.252255548574936, 2.3283390932429175],
						[61.252255562223802, 2.32833909594848],
						[61.252255590321738, 2.3283390993187667],
						[61.25225560298751, 2.3283391003631562],
						[61.252425003060026, 2.3283362995274],
						[61.252425052808775, 2.3283362851949434],
						[61.252425140326466, 2.3283362328939221],
						[61.25242517631213, 2.3283361953050368],
						[61.253058465862892, 2.3271751133054108],
						[61.253433352744203, 2.3267280481875408],
						[61.253777713379577, 2.3268945805552068],
						[61.253777735327553, 2.326894587104519],
						[61.253777780915335, 2.3268945979722986],
						[61.253777804463653, 2.3268946004397022],
						[61.254161103640421, 2.3268863009027037],
						[61.254161131281052, 2.3268862950162892],
						[61.254161181029588, 2.3268862806805783],
						[61.254161204829309, 2.3268862700003954],
						[61.254749988933106, 2.3265224426515849],
						[61.255091568133587, 2.3267807515343102],
						[61.255435958973564, 2.3271251417921461],
						[61.255436008059384, 2.3271251687516057],
						[61.255436112792324, 2.3271251913427222],
						[61.255436169422552, 2.3271251886356823],
						[61.255694468587748, 2.3270307885327934],
						[61.255694494879428, 2.327030773580081],
						[61.2556945426388, 2.3270307372193373],
						[61.255694564106484, 2.3270307158113166],
						[61.256447331928285, 2.3259584592803106],
						[61.25687506509864, 2.3257695897665118],
						[61.257252847960856, 2.3256751939949094],
						[61.257252891010374, 2.3256751717287796],
						[61.257252960053791, 2.325675110251431],
						[61.257252986939292, 2.325675070850294],
						[61.257552972962628, 2.3248723079726834],
						[61.257891739740302, 2.3245141479242619],
						[61.258052666799344, 2.3246001558497396],
						[61.258441539921002, 2.3251195199011883],
						[61.258441550460837, 2.3251195326223031],
						[61.258441573049325, 2.3251195521305377],
						[61.258441585280991, 2.3251195626203494],
						[61.2590470851598, 2.3255473628605974],
						[61.259047113806687, 2.3255473773370134],
						[61.259047175101202, 2.3255473960831257],
						[61.259047206857183, 2.3255474005427859],
						[61.259208305418873, 2.3255418994460557],
						[61.259461101534029, 2.3255390993234766],
						[61.259461134615833, 2.3255390941462086],
						[61.259461195680913, 2.3255390717816495],
						[61.259461224647282, 2.3255390562558218],
						[61.259800124821645, 2.3252668563296965],
						[61.259800127405036, 2.3252668539083379],
						[61.259800131680208, 2.3252668492556205],
						[61.259800134355103, 2.325266848685696],
						[61.260400134430071, 2.3247251484018241],
						[61.260400139596833, 2.3247251435589327],
						[61.260400150730518, 2.3247251318317148],
						[61.260400155805826, 2.3247251251373746],
						[61.261119538674251, 2.3238251460149146],
						[61.261497178870513, 2.3235530610935848],
						[61.261669258803451, 2.3237251414510767],
						[61.261669292366228, 2.323725164234351],
						[61.261669365001055, 2.3237251936584364],
						[61.261669404964721, 2.323725200109211],
						[61.262133304758187, 2.3237141005451085],
						[61.262133332398626, 2.3237140946539618],
						[61.26213338482156, 2.3237140797386888],
						[61.262133411204339, 2.3237140666313501],
						[61.262680711168478, 2.3233501673023182],
						[61.262680719810191, 2.3233501598470521],
						[61.262680737093604, 2.3233501449365055],
						[61.262680744843664, 2.3233501376712749],
						[61.263108445144674, 2.3229001371691447],
						[61.263108455386529, 2.3229001256302864],
						[61.263108473012366, 2.3229000994195461],
						[61.263108481379447, 2.3229000864092137],
						[61.263572381254519, 2.3219194852912599],
						[61.263572381254519, 2.3219194852912599],
						[61.263864071039002, 2.3212945052452927],
						[61.264500115418528, 2.3204862773940049],
						[61.265269300992429, 2.3202919313203805],
						[61.265613722420021, 2.32090289171312],
						[61.265822018747564, 2.321341786802368],
						[61.265822056792658, 2.3213418273429252],
						[61.265822149708363, 2.3213418842519307],
						[61.265822205470521, 2.3213419004302835],
						[61.265944405072908, 2.3213391002844252],
						[61.265944443321125, 2.3213390902568856],
						[61.265944513026852, 2.3213390604221336],
						[61.265944545284455, 2.3213390385729959],
						[61.266116845375549, 2.3211584377937782],
						[61.266116865401187, 2.3211584054536916],
						[61.266116892603577, 2.3211583360283705],
						[61.266116899780293, 2.3211582989431427],
						[61.26611409985145, 2.3208055974921171],
						[61.266114099759896, 2.3208055956403393],
						[61.2661141002853, 2.3208055880430658],
						[61.266114099210554, 2.3208055845296669],
						[61.266075201050626, 2.3202750028206993],
						[61.266152999182367, 2.3194806190161947],
						[61.266152998999218, 2.3194806153126506],
						[61.266153000324579, 2.3194806056733888],
						[61.266153000049854, 2.3194806001180521],
						[61.26615299977513, 2.3190388998172313],
						[61.266152999591959, 2.3190388961136907],
						[61.266153000117242, 2.319038888516388],
						[61.266152999134057, 2.319038886854814],
						[61.266102999640253, 2.3183332925902782],
						[61.266097400374981, 2.3172750037912966],
						[61.266136300049524, 2.3161250068797061],
						[61.266136299316564, 2.3161249920655935],
						[61.266136297142261, 2.3161249663312113],
						[61.266136294717683, 2.316124953749445],
						[61.266052995011407, 2.3157749548106281],
						[61.266052989728713, 2.3157749390962792],
						[61.266052978363383, 2.3157749097096358],
						[61.266052971389072, 2.3157748962276439],
						[61.265839071139567, 2.3154248951068679],
						[61.265839066314989, 2.3154248886514153],
						[61.265839056757486, 2.3154248775922097],
						[61.265839051932915, 2.3154248711367567],
						[61.26532516467153, 2.3148192858819008],
						[61.264930774320639, 2.314122101784533],
						[61.264930769404351, 2.314122093477768],
						[61.264930758863485, 2.3141220807579375],
						[61.264930753947226, 2.3141220724511644],
						[61.264211256182513, 2.3132526743378636],
						[61.263272361992136, 2.3120387818628036],
						[61.262672364624855, 2.3111692860041697],
						[61.262672351225547, 2.3111692701543189],
						[61.262672321043929, 2.311169242919286],
						[61.26267230416989, 2.3111692296825583],
						[61.26211684339593, 2.3108304537045745],
						[61.261944566589321, 2.3105665911467912],
						[61.261944567389243, 2.310566589104873],
						[61.261772368323435, 2.3103054912484926],
						[61.261391869755712, 2.3096970931299516],
						[61.261391862964288, 2.3096970833541923],
						[61.2613918476899, 2.3096970660349974],
						[61.26139184000688, 2.3096970564496497],
						[61.261127939731757, 2.3094387574220847],
						[61.261127935890244, 2.3094387526294966],
						[61.261127928390628, 2.3094387467471833],
						[61.261127922765866, 2.3094387423354568],
						[61.260577935561031, 2.3090081531805238],
						[61.260180747070443, 2.3085748641345822],
						[61.260180719929984, 2.3085748437382705],
						[61.260180659774804, 2.308574811684343],
						[61.260180626851763, 2.3085748018780996],
						[61.259633327155868, 2.3084998012582956],
						[61.259633307357269, 2.308499801745457],
						[61.259633265976817, 2.3084998031006685],
						[61.259633244578453, 2.3084998076714243],
						[61.259300002877637, 2.3085941913915624],
						[61.258780682749233, 2.3084304152266291],
						[61.258358442355082, 2.3081748522559478],
						[61.258186266914713, 2.3079137905133402],
						[61.258186235657455, 2.3079137597730197],
						[61.258186164069166, 2.3079137151974898],
						[61.258186122846553, 2.3079137015527289],
						[61.257550022629864, 2.307838701991201],
						[61.257550016296932, 2.3078387014731203],
						[61.257550001847918, 2.30783870081788],
						[61.257549994623396, 2.307838700490263],
						[61.257338959010937, 2.3078441979126691],
						[61.256955712287578, 2.307585934333853],
						[61.256955683730773, 2.3075859217259862],
						[61.256955626092434, 2.3075859041054878],
						[61.256955596119319, 2.3075858992832994],
						[61.256819395709634, 2.3075887008377358],
						[61.256819357370937, 2.3075887090271641],
						[61.256819285977066, 2.307588741114396],
						[61.256819253813489, 2.3075887648217646],
						[61.256230457224412, 2.3082220595638225],
						[61.255855462916962, 2.308583154495162],
						[61.25546927972308, 2.3089415377186064],
						[61.254833301433884, 2.309310868215964],
						[61.254116804047364, 2.308885928130413],
						[61.253302905653975, 2.3083748303889595],
						[61.253302894587904, 2.3083748252698673],
						[61.253302873347415, 2.3083748148412888],
						[61.253302861389713, 2.3083748099126025],
						[61.253044499613317, 2.3082915228834575],
						[61.252450130787615, 2.3077748494291748],
						[61.252450110255211, 2.3077748351089999],
						[61.252450063132663, 2.307774811503343],
						[61.252450038417422, 2.3077748036879981],
						[61.25164173784863, 2.3076165041118633],
						[61.251641727857589, 2.3076165025046276],
						[61.251641706092329, 2.3076164996709596],
						[61.251641695301444, 2.3076165001050017],
						[61.251297233679949, 2.3076247990051879],
						[61.250625070008482, 2.3073748131320531],
						[61.250625045385, 2.3073748071681943],
						[61.250624994629938, 2.3073748011737671],
						[61.250624970373323, 2.3073748026131748],
						[61.24994176468865, 2.30747758825752],
						[61.249472362906623, 2.307047077900211],
						[61.2491279794002, 2.3063499102569862],
						[61.249127976633666, 2.3063499089775439],
						[61.249127974391769, 2.3063499001047711],
						[61.249127972425107, 2.3063498967841607],
						[61.248866876704199, 2.3059138065368612],
						[61.248658480826094, 2.3054749144865818],
						[61.248658479842732, 2.3054749128263414],
						[61.248658476892714, 2.3054749078455874],
						[61.248658475817599, 2.3054749043346576],
						[61.248097376314654, 2.3044305045541398],
						[61.248097369614676, 2.3044304966341609],
						[61.248097356922798, 2.3044304769024029],
						[61.248097350131047, 2.3044304671317715],
						[61.247027950413646, 2.3032165685062385],
						[61.247027949430297, 2.3032165668461304],
						[61.247027948446906, 2.3032165651860459],
						[61.247027946571933, 2.3032165637164579],
						[61.24647234686833, 2.3026137647841312],
						[61.246472329901813, 2.3026137497078034],
						[61.246472292677836, 2.3026137258688011],
						[61.246472274111738, 2.3026137148745955],
						[61.24562508963939, 2.3022776203546012],
						[61.244941827775129, 2.3018526435126345],
						[61.244208446337801, 2.3010748632097444],
						[61.244208443571232, 2.301074861930946],
						[61.244208441604435, 2.3010748586111935],
						[61.244208440712839, 2.301074858801734],
						[61.243947351856534, 2.3008165694816274],
						[61.243569562032917, 2.3002942823045203],
						[61.24356955336593, 2.3002942710668837],
						[61.243569533448976, 2.3002942510136308],
						[61.24356952219906, 2.3002942421980208],
						[61.243350135137653, 2.3001248514208736],
						[61.243186246351307, 2.2999498636696787],
						[61.243186235101341, 2.2999498548543036],
						[61.243186213401209, 2.2999498351826317],
						[61.243186201351449, 2.2999498284081703],
						[61.242755748790962, 2.299697056367267],
						[61.242375183699394, 2.2989999199858002],
						[61.24202799160112, 2.2979471438175385],
						[61.241719595350048, 2.2967193538075295],
						[61.241425195245633, 2.295402756015942],
						[61.241425116650611, 2.2954026793304756],
						[61.241424938317984, 2.2954026445438944],
						[61.241424836705356, 2.2954026849739169],
						[61.240416537442115, 2.296833185269572],
						[61.240416528185897, 2.2968331984675325],
						[61.2404165143151, 2.2968332276106809],
						[61.240416507917367, 2.2968332439371064],
						[61.239913709380779, 2.2985221402533185],
						[61.238658110414882, 2.302341638605975],
						[61.238658108015457, 2.3023416447276253],
						[61.238658104999807, 2.3023416565900208],
						[61.23865810438356, 2.3023416623307749],
						[61.238166503642084, 2.3049110625570681],
						[61.238166502134149, 2.3049110684880647],
						[61.238166501084962, 2.3049110836694218],
						[61.238166499577019, 2.3049110896004197],
						[61.2380054017585, 2.3082638467655459],
						[61.237544221591996, 2.309158208734619],
						[61.237544216792536, 2.3091582209764798],
						[61.23754420799348, 2.3091582434199021],
						[61.237544205068922, 2.3091582571313234],
						[61.237130410734586, 2.3110193308429219],
						[61.236038723011617, 2.3130721067866866],
						[61.236038718120106, 2.3130721171774011],
						[61.236038708612064, 2.3130721435087489],
						[61.236038704703823, 2.3130721555593055],
						[61.235335906159719, 2.316172151908082],
						[61.234699807319039, 2.3185666482860929],
						[61.234699805627365, 2.3185666505159457],
						[61.234699805718911, 2.3185666523658668],
						[61.234699805718911, 2.3185666523658668],
						[61.232905407040548, 2.3260138468337748],
						[61.23152481036913, 2.3303666376128609],
						[61.230449811440913, 2.3334749351195754],
						[61.230449808422435, 2.3334749469760037],
						[61.230449802385465, 2.3334749706888691],
						[61.230449801241633, 2.3334749840160183],
						[61.230244201258991, 2.3360360775185747],
						[61.229999802117661, 2.3376277691173102],
					],
					[
						[61.239320014647141, 2.323237499144013],
						[61.239338701394388, 2.3233333262092],
						[61.239357292080868, 2.3236155361124458],
						[61.239320014647141, 2.323237499144013],
					],
				],
			],
		},
		{
			fldNpdidField: "20461008",
			wlbNpdidWellbore: "5943",
			fldName: "VISUND SØR",
			fldCurrentActivitySatus: "Producing",
			wlbName: "34/8-14 S",
			fldDiscoveryYear: "2008",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=20461008",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=20461008",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "VISUND INSIDE",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=5943",
			fldHcType: "OIL/GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "40726",
			fldDateUpdated: "2022-09-13 00:00:00",
			fldDateUpdatedMax: "2021-02-06 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[61.271988703672207, 2.3355499849001062],
					[61.271988713750872, 2.3355500613632527],
					[61.271988726369216, 2.3355500979817045],
					[61.275610926280471, 2.3420000982491209],
					[61.275611000807771, 2.3420001479138115],
					[61.27561116127827, 2.3420001680704488],
					[61.275611246238505, 2.3420001368995731],
					[61.278288848300477, 2.3391475487831821],
					[61.280555419691758, 2.3437639877288374],
					[61.285994219965573, 2.3548444877083883],
					[61.285994281113609, 2.3548445402589397],
					[61.285994421002776, 2.3548445817066961],
					[61.285994499835063, 2.3548445724569658],
					[61.289663858063456, 2.3527363558839052],
					[61.295444205080287, 2.3593139802044805],
					[61.295924799643132, 2.3667722125276009],
					[61.295924804210422, 2.3667722321620825],
					[61.295924814145771, 2.3667722693882349],
					[61.295924819604785, 2.3667722888336327],
					[61.29955261955493, 2.3741639873158813],
					[61.299552635094344, 2.3741640102488142],
					[61.299552669467445, 2.374164049797205],
					[61.299552689192844, 2.3741640662237851],
					[61.303602689314566, 2.3768834658074263],
					[61.303602777264359, 2.3768834771558094],
					[61.303602927948134, 2.3768834265086447],
					[61.30360298969957, 2.376883362847753],
					[61.304641886278837, 2.3737083761369395],
					[61.307575178966928, 2.3677500888453409],
					[61.307575185463861, 2.3677500743513917],
					[61.307575193908299, 2.3677500444547923],
					[61.307575197730152, 2.3677500305282679],
					[61.309177995348627, 2.35731674441345],
					[61.310444589289766, 2.3531833643435629],
					[61.313269586604221, 2.3458056715311777],
					[61.313269588914181, 2.345805663543119],
					[61.313269592733668, 2.3458056496114899],
					[61.313269595134898, 2.3458056434780969],
					[61.315527995178954, 2.3355639423851655],
					[61.315527997191751, 2.3355639100939598],
					[61.315527989443233, 2.3355638442735982],
					[61.315527979773329, 2.3355638125992049],
					[61.31235578044371, 2.3290943121820811],
					[61.312355763022168, 2.3290942877890419],
					[61.31235572497841, 2.3290942471822524],
					[61.312355703373107, 2.3290942293042418],
					[61.307852903195275, 2.3263887291919589],
					[61.307852834950623, 2.3263887156520928],
					[61.30785270628202, 2.3263887374954972],
					[61.307852645858034, 2.3263887728787549],
					[61.305388806596767, 2.3293553986064093],
					[61.302033301928596, 2.3293137004791822],
					[61.30203328846266, 2.3293137014781307],
					[61.30203325894734, 2.3293137059005384],
					[61.302033244681233, 2.3293137089436073],
					[61.295424984341409, 2.331210897709437],
					[61.290777903979638, 2.3306359101660501],
					[61.287655755400927, 2.3268220725829218],
					[61.287655718980545, 2.326822046643473],
					[61.28765563964717, 2.3268220092572678],
					[61.287655595934034, 2.3268219998537298],
					[61.282719395910703, 2.3269276008191917],
					[61.282719380570001, 2.3269276003454555],
					[61.28271935292949, 2.3269276062387845],
					[61.282719338663419, 2.3269276092805065],
					[61.277338839156208, 2.3286470097913021],
					[61.277338830331345, 2.3286470135439328],
					[61.277338811698492, 2.328647019386747],
					[61.277338803856701, 2.3286470248018474],
					[61.273311004135671, 2.330860925434588],
					[61.273310973842577, 2.330860950604273],
					[61.273310924596039, 2.3308610116302479],
					[61.27331090822608, 2.3308610450645455],
					[61.271988708086525, 2.3355499465280505],
					[61.271988703672207, 2.3355499849001062],
				],
			],
		},
		{
			fldNpdidField: "3392471",
			wlbNpdidWellbore: "4278",
			fldName: "VILJE",
			fldCurrentActivitySatus: "Producing",
			wlbName: "25/4-9 S",
			fldDiscoveryYear: "2003",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=3392471",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=3392471",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "036 D",
			cmpLongName: "Aker BP ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=4278",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "28544099",
			fldNpdidOwner: "5081703",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[59.643291500710149, 2.2766750015962245],
					[59.643291501889422, 2.2766750244954634],
					[59.643488701746548, 2.2782389250143256],
					[59.643488703001083, 2.2782389318811513],
					[59.643488706401897, 2.2782389454357022],
					[59.643488706764657, 2.2782389524816931],
					[59.643852607409912, 2.2795611523498187],
					[59.643852608664382, 2.2795611592167746],
					[59.643852613848601, 2.2795611724133868],
					[59.643852615994838, 2.2795611791012322],
					[59.64461091188948, 2.2813194693464838],
					[59.645016504541417, 2.2826278456402633],
					[59.645377601148937, 2.2848500255715511],
					[59.64562200127331, 2.2876889163981429],
					[59.645622005126029, 2.2876889387627752],
					[59.645622019073912, 2.2876889822396516],
					[59.645622028277288, 2.2876890035308364],
					[59.646299815857695, 2.2888222814163743],
					[59.64648039129127, 2.2894221991617179],
					[59.646308125935981, 2.2899720855630226],
					[59.645363770542652, 2.2907720466654267],
					[59.645363752629912, 2.2907720662755038],
					[59.645363723318468, 2.2907721095288305],
					[59.645363711027997, 2.2907721333508886],
					[59.64506091078767, 2.2916305329726789],
					[59.6450609077636, 2.2916305442576586],
					[59.64506090260727, 2.2916305666487857],
					[59.645060901366769, 2.2916305775761496],
					[59.644924800950058, 2.2928443785889883],
					[59.644924801234744, 2.2928444016688658],
					[59.644924807154837, 2.2928444467560394],
					[59.644924811807954, 2.2928444671804633],
					[59.645302612149706, 2.2938889684301129],
					[59.645302633759748, 2.2938890032540469],
					[59.645302689012453, 2.2938890615912837],
					[59.645302722655131, 2.2938890851045888],
					[59.645783222718514, 2.2940890846038431],
					[59.645783260005771, 2.2940890913688823],
					[59.645783333946966, 2.294089092567261],
					[59.645783372384436, 2.2940890866431052],
					[59.646644471597433, 2.2937584878383244],
					[59.646644495223349, 2.2937584742033446],
					[59.646644537744741, 2.2937584425420017],
					[59.646644556640211, 2.2937584245156533],
					[59.647316857255234, 2.292911224356128],
					[59.647316863937057, 2.2929112141171153],
					[59.647316876408894, 2.2929111938178757],
					[59.647316882198929, 2.2929111837576461],
					[59.647877893314551, 2.2916891750967876],
					[59.648741606396754, 2.2921473768015743],
					[59.648741666431313, 2.2921473879036256],
					[59.648741781032591, 2.29214737382468],
					[59.648741836400568, 2.2921473467030853],
					[59.649777924415048, 2.2911918565755656],
					[59.652011185337855, 2.289686283045862],
					[59.654474987962963, 2.2890002105888025],
					[59.655602725308221, 2.289452985962352],
					[59.655602784980744, 2.2894529900123053],
					[59.655602896272271, 2.2894529641212724],
					[59.655602947891261, 2.2894529341803125],
					[59.656597320650008, 2.2883612635550938],
					[59.657391683813131, 2.2879919277973335],
					[59.659130435549024, 2.2891168315858947],
					[59.659410905117412, 2.2898750477126426],
					[59.659702602334711, 2.2920889268018771],
					[59.659702608060947, 2.2920889505809696],
					[59.659702626647665, 2.2920889967080083],
					[59.659702640399964, 2.2920890188770682],
					[59.661238732892286, 2.2941529099196645],
					[59.661919226976416, 2.2953362007477205],
					[59.661919238582726, 2.2953362162270801],
					[59.66191926625423, 2.2953362462917375],
					[59.661919281427686, 2.2953362610558257],
					[59.662822081066629, 2.2960057610488689],
					[59.662822128887363, 2.2960057799518876],
					[59.662822227630699, 2.2960057904252476],
					[59.66282227864378, 2.2960057837581607],
					[59.663933340628915, 2.2955280006528493],
					[59.665286035039536, 2.2955307994901415],
					[59.66686658313418, 2.2966668613788097],
					[59.666866598036151, 2.2966668708571674],
					[59.666866630424948, 2.2966668875146561],
					[59.666866646929535, 2.2966668931097844],
					[59.66849434676589, 2.2971168933026656],
					[59.668494382980377, 2.297116896727041],
					[59.668494453793883, 2.2971168896516039],
					[59.66849448928469, 2.2971168789729544],
					[59.669783367864234, 2.2964779907893633],
					[59.671447244830055, 2.2960918956310201],
					[59.671447264268508, 2.2960918881698196],
					[59.671447298595957, 2.2960918723787325],
					[59.671447315268551, 2.2960918636911054],
					[59.673469515326005, 2.2946640624815773],
					[59.673469530925694, 2.29466405044532],
					[59.673469559087714, 2.2946640198570574],
					[59.673469570848759, 2.2946640032471155],
					[59.674411270726196, 2.2931084036032567],
					[59.674411277317361, 2.2931083915924551],
					[59.67441128773379, 2.2931083663445988],
					[59.674411293433181, 2.2931083545127655],
					[59.67478629305927, 2.2917694537094988],
					[59.674786293588895, 2.2917694464777356],
					[59.6747862973235, 2.2917694314771988],
					[59.674786298654375, 2.2917694223032403],
					[59.675439095839423, 2.2861806454823239],
					[59.675991889099137, 2.2846139677948694],
					[59.675991892031753, 2.2846139547354563],
					[59.675991897005233, 2.2846139287958604],
					[59.675991899136726, 2.2846139176788891],
					[59.676286297317411, 2.2810861396743736],
					[59.676730784720661, 2.2797444765080344],
					[59.677625178925268, 2.2779500899019105],
					[59.677625182129411, 2.2779500821311691],
					[59.677625187736602, 2.2779500685323981],
					[59.677625190139679, 2.2779500627043592],
					[59.678466890011222, 2.275352862849954],
					[59.678466893743874, 2.2753528478460168],
					[59.678466899334943, 2.2753528164337826],
					[59.678466900392337, 2.2753528019682796],
					[59.678478000301808, 2.2739361015967452],
					[59.678478000029465, 2.2739360963067861],
					[59.678477999393976, 2.2739360839635809],
					[59.678477999121618, 2.2739360786736382],
					[59.678178000123445, 2.2712777916020532],
					[59.678208498797751, 2.2704639214508608],
					[59.678530793792596, 2.2686083535776311],
					[59.68028348611071, 2.2641306726179105],
					[59.680283489932947, 2.2641306593753545],
					[59.680283496776639, 2.2641306348334274],
					[59.680283498815413, 2.2641306219504553],
					[59.680427998609325, 2.2627972225167023],
					[59.680427998864531, 2.2627972099932179],
					[59.68042799866506, 2.2627971886528604],
					[59.680427998920251, 2.2627971761293937],
					[59.680325198953263, 2.2619110775044731],
					[59.680325196351319, 2.2619110619938181],
					[59.680325186688755, 2.2619110318717444],
					[59.680325182303328, 2.2619110167207794],
					[59.679458482364346, 2.2600138166477342],
					[59.67945847834261, 2.2600138085506902],
					[59.679458468606626, 2.2600137944796925],
					[59.679458464584904, 2.260013786382661],
					[59.678208464922911, 2.2581998873881712],
					[59.678208445814597, 2.258199866301259],
					[59.678208405286775, 2.2581998317201584],
					[59.678208381101122, 2.2581998170025237],
					[59.677202911859219, 2.2577526306423219],
					[59.676300137217886, 2.256902653092649],
					[59.676300125971203, 2.2569026446731648],
					[59.676300101694437, 2.2569026281940836],
					[59.676300088755326, 2.2569026218976278],
					[59.673302912763695, 2.2554081326091686],
					[59.67273346464841, 2.2549026791029076],
					[59.672377992198776, 2.2540971394576883],
					[59.672205800999855, 2.253211088876176],
					[59.672266898404267, 2.2523917343790552],
					[59.672527993215454, 2.2514306522936129],
					[59.672527996053653, 2.251430637469984],
					[59.672527999054957, 2.2514306083629032],
					[59.672528000018652, 2.251430592136471],
					[59.672508499672524, 2.2508721923848078],
					[59.672508494739397, 2.2508721666613352],
					[59.672508480414614, 2.2508721161147953],
					[59.672508468347814, 2.2508720918319534],
					[59.671314068376972, 2.2490081916442515],
					[59.671314036145688, 2.2490081607484855],
					[59.671313961711576, 2.2490081152212031],
					[59.671313920400443, 2.2490081004095597],
					[59.670866720380019, 2.2489637007988814],
					[59.670866666786637, 2.248963709843133],
					[59.67086657274092, 2.2489637555581474],
					[59.670866532197472, 2.2489637904660684],
					[59.670533131572704, 2.2494776910289436],
					[59.670533118144654, 2.2494777275840709],
					[59.670533104045774, 2.2494778034613416],
					[59.670533105158349, 2.2494778424232726],
					[59.670871998828581, 2.2510222152055581],
					[59.670805400837324, 2.2522027767343],
					[59.670666512394654, 2.2530110151383895],
					[59.670219243000716, 2.253574876679401],
					[59.670219231336162, 2.2535748950645456],
					[59.67021921104638, 2.2535749383461101],
					[59.67021920411355, 2.2535749611197304],
					[59.670108103752327, 2.254147161984644],
					[59.670108102169664, 2.2541471836782252],
					[59.670108102662233, 2.2541472281082937],
					[59.670108106429865, 2.2541472487220426],
					[59.670227605725351, 2.2546167482706903],
					[59.670227676172907, 2.2546168213312576],
					[59.67022784256158, 2.2546168679045357],
					[59.670227940377103, 2.2546168428201554],
					[59.670672187078544, 2.2541808928367892],
					[59.670897008866611, 2.254444484777713],
					[59.671024796176361, 2.2554555763273427],
					[59.670860933793001, 2.2559054712166269],
					[59.670594363614327, 2.2560858821991938],
					[59.669783352646441, 2.255860906482837],
					[59.669783308406174, 2.2558609047224985],
					[59.66978322181857, 2.2558609204126241],
					[59.669783181345693, 2.255860939266026],
					[59.66866666396367, 2.2566914784505165],
					[59.668155700002835, 2.2565248214820541],
					[59.666502954695417, 2.2551137682155686],
					[59.665958480862081, 2.2542027133084708],
					[59.665275188107252, 2.252324931990803],
					[59.665275178006354, 2.2523249108775958],
					[59.665275152636418, 2.2523248732562267],
					[59.6652751391508, 2.25232485638807],
					[59.664597338778769, 2.2516720555863126],
					[59.664597303436672, 2.2516720342262393],
					[59.664597227148001, 2.2516720051036345],
					[59.66459718460014, 2.2516720012261318],
					[59.664408285081009, 2.2516859016292092],
					[59.664408276964551, 2.2516859014867054],
					[59.664408259039277, 2.251685903324196],
					[59.664408251104938, 2.251685906706685],
					[59.663205551200079, 2.2519915056890074],
					[59.663205503958295, 2.2519915330324118],
					[59.663205432059641, 2.2519916063112837],
					[59.663205405619323, 2.2519916526067223],
					[59.662810916313482, 2.2536110103839526],
					[59.661366542960131, 2.2554526771905801],
					[59.66136653209589, 2.2554526936278059],
					[59.661366514116359, 2.2554527293074225],
					[59.661366507001063, 2.255452748549827],
					[59.660477610290386, 2.2587971366590942],
					[59.659930430381543, 2.2601442862830821],
					[59.658591593123788, 2.261485924318503],
					[59.656627740775512, 2.2620970097368489],
					[59.656627703067542, 2.2620970297974194],
					[59.656627641135969, 2.2620970867865871],
					[59.656627615838794, 2.2620971203706195],
					[59.655902616264548, 2.2637777215231747],
					[59.655902610749607, 2.263777736877242],
					[59.655902603468427, 2.2637777703911284],
					[59.655902599827826, 2.2637777871480562],
					[59.655788701942264, 2.2655527685516597],
					[59.655602616148087, 2.2662555146533276],
					[59.654158175341877, 2.2682915305679257],
					[59.653769384690015, 2.2684191947254373],
					[59.651894434302847, 2.2680970028429845],
					[59.651894409953272, 2.2680970024030658],
					[59.651894361435851, 2.2680970050470357],
					[59.651894337358797, 2.2680970098928253],
					[59.650950029249458, 2.268408080684102],
					[59.649872310189593, 2.2676998336852519],
					[59.649872280654634, 2.2676998200485583],
					[59.649872221237977, 2.2676998035253995],
					[59.649872190373706, 2.2676997990566146],
					[59.649324989667214, 2.2677275998017499],
					[59.649324951413142, 2.2677276092805063],
					[59.649324879380373, 2.2677276451377995],
					[59.649324848276876, 2.2677276709779468],
					[59.649044247784943, 2.2680581704181586],
					[59.649044236027208, 2.2680581870245358],
					[59.649044217061309, 2.268058221101739],
					[59.649044209052214, 2.2680582405137777],
					[59.648399815632814, 2.2701499186273359],
					[59.647352645018849, 2.2719470681799789],
					[59.646083221132862, 2.2730109058345755],
					[59.644736085799536, 2.2731081009206773],
					[59.644736065198458, 2.2731081032827913],
					[59.644736025341899, 2.2731081166354468],
					[59.644736005904853, 2.2731081241028943],
					[59.643433205552434, 2.2738054239711225],
					[59.643433160376766, 2.2738054739859752],
					[59.643433112316636, 2.2738055904303085],
					[59.643433108631164, 2.2738056588005406],
					[59.643755397189722, 2.2748639189065374],
					[59.643680403969221, 2.2755749557223908],
					[59.643291510730137, 2.2766749337336827],
					[59.643291505576393, 2.2766749561257313],
					[59.643291500710149, 2.2766750015962245],
				],
			],
		},
		{
			fldNpdidField: "21350124",
			wlbNpdidWellbore: "5949",
			fldName: "SKULD",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6608/10-12",
			fldDiscoveryYear: "2008",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=21350124",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=21350124",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "128",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=5949",
			fldHcType: "OIL",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "21836",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Sandnessjøen",
			fldAreaGeometryWKT: [
				[
					[66.224341500432843, 8.3107416997824402],
					[66.224341499764463, 8.3108417006358248],
					[66.224341499856763, 8.3108500006936676],
					[66.224341499821634, 8.3108610993023593],
					[66.224341500108054, 8.3109166990290504],
					[66.224341500137669, 8.3109167056967319],
					[66.224341501074207, 8.3109167145625324],
					[66.22434150109396, 8.3109167190076523],
					[66.224419201388287, 8.3117750170145843],
					[66.224419201398163, 8.3117750192371513],
					[66.224419201417874, 8.3117750236822872],
					[66.224419201417874, 8.3117750236822872],
					[66.224441500954825, 8.3119583231374019],
					[66.224441501871567, 8.3119583275581359],
					[66.224441502798157, 8.3119583342014192],
					[66.224441503714914, 8.3119583386221532],
					[66.224555403116327, 8.3125528330786889],
					[66.224624798763685, 8.3130667036412458],
					[66.224563702438118, 8.3136638756378378],
					[66.224485902235884, 8.3141805708962266],
					[66.224485901368325, 8.3141805775883189],
					[66.224485899643057, 8.3141805931950543],
					[66.22448589967253, 8.3141805998627998],
					[66.224485900193969, 8.3147083010507483],
					[66.224485900233248, 8.3147083099410679],
					[66.224485902105855, 8.3147083276730509],
					[66.224485903948988, 8.3147083387372689],
					[66.224563704151407, 8.3151139375038507],
					[66.224563704161213, 8.315113939726432],
					[66.224563704171018, 8.315113941949015],
					[66.224563705068078, 8.315113941924702],
					[66.225769204445498, 8.3206889382368487],
					[66.226922003381063, 8.3275944319564044],
					[66.22692200431662, 8.3275944408237326],
					[66.226922007972163, 8.3275944562878248],
					[66.226922010701813, 8.3275944651074045],
					[66.228263708188749, 8.3315306553169943],
					[66.230141505892192, 8.3392556469560546],
					[66.230141557307036, 8.3392557123112816],
					[66.230141691277183, 8.3392557821771334],
					[66.2301417738325, 8.3392557866877599],
					[66.235947195634495, 8.3369474163418342],
					[66.236633220398943, 8.3372473827827474],
					[66.236633304719803, 8.3372473805698135],
					[66.236633442577585, 8.3372473102346181],
					[66.236633495226982, 8.3372472443595722],
					[66.23907519254989, 8.3263722553898436],
					[66.240997386901441, 8.3209472723614919],
					[66.243469569763846, 8.3150779109705173],
					[66.246436245050006, 8.3119501375187461],
					[66.246436251299585, 8.3119501306740471],
					[66.246436262901753, 8.3119501170091166],
					[66.246436268244452, 8.3119501079643623],
					[66.24679456780008, 8.3113945082727394],
					[66.24679458274359, 8.3113944388959293],
					[66.246794565127786, 8.3113943103374943],
					[66.246794531661536, 8.3113942489557999],
					[66.246772372703859, 8.3113748858214045],
					[66.246764090045616, 8.3113499380878739],
					[66.246764089128845, 8.3113499336632763],
					[66.246764086408149, 8.3113499270630715],
					[66.246764084604251, 8.3113499248874838],
					[66.246322384692178, 8.3102638250616696],
					[66.24632238197141, 8.3102638184617188],
					[66.246322374726049, 8.3102638030863023],
					[66.246322372005324, 8.3102637964863497],
					[66.246314071865413, 8.3102498960107418],
					[66.246314070071378, 8.3102498960597639],
					[66.246314068257561, 8.3102498916597796],
					[66.246314065556589, 8.3102498895088193],
					[66.245872366363272, 8.3095915879931912],
					[66.245872363662329, 8.3095915858423375],
					[66.245872360951466, 8.3095915814670107],
					[66.245872359147512, 8.3095915792916255],
					[66.245544558727545, 8.3091609796586638],
					[66.245544557820637, 8.3091609774587845],
					[66.2455445569137, 8.3091609752589104],
					[66.245544556016696, 8.3091609752834596],
					[66.245414062425866, 8.3089970808890232],
					[66.245239065940254, 8.3087359893373964],
					[66.245239065033317, 8.3087359871375615],
					[66.245239064126409, 8.3087359849377052],
					[66.245239063229349, 8.3087359849622704],
					[66.244964068357618, 8.3083498903372526],
					[66.244797372009742, 8.3080692972418344],
					[66.244797370205802, 8.3080692950666499],
					[66.244797365690928, 8.3080692885165099],
					[66.244797363886974, 8.3080692863413024],
					[66.244514066664223, 8.3076609892691629],
					[66.244080769087574, 8.3069748930526259],
					[66.244080767283592, 8.30697489087758],
					[66.244080761861753, 8.3069748821281273],
					[66.244080760057741, 8.3069748799530867],
					[66.244064064890296, 8.3069526876968283],
					[66.244041870127433, 8.3069165960785725],
					[66.244041869220496, 8.306916593878908],
					[66.24404186740658, 8.3069165894795773],
					[66.244041864705579, 8.3069165873291659],
					[66.243614065338733, 8.3062887883851921],
					[66.243339065731647, 8.3058831895227954],
					[66.243155769114665, 8.3056026934905702],
					[66.242814070632107, 8.3050442960291644],
					[66.242814068828096, 8.3050442938543654],
					[66.242814067014152, 8.3050442894553616],
					[66.242814066107144, 8.3050442872558712],
					[66.242705771151009, 8.3048831949722519],
					[66.242652974571612, 8.3047888016638911],
					[66.242652973674581, 8.3047888016885949],
					[66.242652970963576, 8.3047887973143606],
					[66.242652970056596, 8.3047887951148933],
					[66.24225577203562, 8.3041415980074991],
					[66.241997374811106, 8.3036943038267985],
					[66.241805777182734, 8.3033360075483547],
					[66.241425177739529, 8.3025999076713983],
					[66.241425175935504, 8.3025999054968924],
					[66.241425174121431, 8.3025999010983309],
					[66.241425173214438, 8.3025998988990466],
					[66.241347373043013, 8.3024665993845481],
					[66.241172374092031, 8.3021610016975238],
					[66.241172373185009, 8.3021609994982821],
					[66.241172371370951, 8.3021609950997934],
					[66.241172369566911, 8.3021609929253426],
					[66.24089737040569, 8.3017192930384756],
					[66.240897367714638, 8.3017192931129014],
					[66.240897364993543, 8.3017192865152882],
					[66.24089736318949, 8.3017192843408907],
					[66.240744562811116, 8.3015054840861602],
					[66.240744561914113, 8.3015054841109777],
					[66.240744561007077, 8.3015054819117733],
					[66.24044736059156, 8.3011054820190306],
					[66.240075160972509, 8.3006026810423723],
					[66.240075159168455, 8.3006026788681062],
					[66.240075154653255, 8.3006026723205224],
					[66.240075152849187, 8.3006026701462758],
					[66.239997359907804, 8.3005109786966162],
					[66.239975166358406, 8.3004776898550237],
					[66.239975165451355, 8.3004776876559418],
					[66.239975162740251, 8.3004776832826348],
					[66.239975161833186, 8.3004776810835565],
					[66.239930764430326, 8.3004165843120337],
					[66.239691875155387, 8.3000610030318853],
					[66.239547382251885, 8.2997471159649994],
					[66.239547381344863, 8.299747113765962],
					[66.239547378633688, 8.2997471093928077],
					[66.239547377736656, 8.2997471094176856],
					[66.239447379577086, 8.2995527125039192],
					[66.239341884294575, 8.2993221224739848],
					[66.239089088176343, 8.298677730393365],
					[66.238930788788082, 8.2982193340916215],
					[66.238930787880989, 8.2982193318927155],
					[66.238930786056784, 8.2982193252710399],
					[66.238930785149719, 8.2982193230721322],
					[66.238639085067916, 8.2975221247577338],
					[66.238480785636469, 8.2971221259447017],
					[66.238183485632447, 8.2963805274074929],
					[66.238044586767927, 8.2960221276175385],
					[66.237730786649578, 8.2951999297372137],
					[66.237730782113957, 8.2951999187437178],
					[66.237730771258825, 8.2951998990305551],
					[66.23773076492914, 8.2951998880871329],
					[66.237541866975207, 8.2949220895664553],
					[66.237280769132099, 8.2945081939827148],
					[66.237280733089435, 8.2945081594060159],
					[66.237280647649953, 8.2945081128654881],
					[66.2372806000471, 8.2945081008515356],
					[66.237105600141703, 8.2945080994067801],
					[66.237105592068488, 8.2945080996323224],
					[66.237105576819147, 8.2945081000583496],
					[66.237105569653068, 8.2945081024825118],
					[66.236830569824733, 8.2945498025301045],
					[66.236830534891411, 8.2945498146256718],
					[66.236830471364556, 8.2945498519833301],
					[66.236830442760919, 8.2945498750217652],
					[66.236724859945127, 8.2946831556205947],
					[66.23638048125477, 8.2949387393368941],
					[66.23638045091765, 8.294938775766262],
					[66.236380410905255, 8.2949388547198115],
					[66.236380400343037, 8.2949388994926743],
					[66.236380400239625, 8.2949414436351034],
					[66.235930669751383, 8.2948276109890191],
					[66.235864016941704, 8.2947942351963526],
					[66.235480739032852, 8.2944248560214735],
					[66.235480736331724, 8.2944248538731227],
					[66.235480730022218, 8.2944248473779343],
					[66.235480727321018, 8.294424845229587],
					[66.23514453612971, 8.2941498529049387],
					[66.235030744754468, 8.2940304629585881],
					[66.235030738444948, 8.2940304564636111],
					[66.235030724928905, 8.2940304434987571],
					[66.235030717732528, 8.2940304392523707],
					[66.234833434855389, 8.2938859500815116],
					[66.234580748955977, 8.2936026677821921],
					[66.234580739935154, 8.2936026569158017],
					[66.234580720129813, 8.2936026419035809],
					[66.234580710222076, 8.2936026332857331],
					[66.234419520889531, 8.2934970405863186],
					[66.234130732381132, 8.293244250032302],
					[66.234130714379916, 8.2932442371938375],
					[66.234130675706695, 8.2932442160390387],
					[66.234130655931693, 8.2932442076976045],
					[66.233825066124922, 8.2931554110727852],
					[66.233680675046529, 8.2930970138880333],
					[66.233680655291792, 8.2930970099936072],
					[66.233680614885259, 8.2930970022298887],
					[66.233680595140655, 8.2930970005588573],
					[66.233566699311993, 8.2930997992064537],
					[66.233238903021785, 8.2930941998536198],
					[66.233238897639666, 8.2930942000042567],
					[66.23323888597838, 8.2930942003306356],
					[66.233238879699215, 8.2930942005063795],
					[66.233124981765954, 8.2931053999723385],
					[66.232788884346817, 8.2931331020605903],
					[66.232788883449771, 8.2931331020856955],
					[66.232788881645604, 8.2931330999126072],
					[66.232788880748572, 8.2931330999377124],
					[66.232466687788261, 8.2931637007896768],
					[66.232338896193042, 8.2931665009374971],
					[66.232338892604972, 8.2931665010379092],
					[66.232338888109709, 8.2931664989401632],
					[66.232338886315659, 8.2931664989903684],
					[66.232024993059383, 8.2931886996203428],
					[66.231888900048617, 8.2931886990173282],
					[66.231888897357535, 8.2931886990926298],
					[66.231888891078398, 8.2931886992683381],
					[66.231888888387303, 8.2931886993436414],
					[66.231749990161248, 8.293196999703758],
					[66.231438892927741, 8.2932080994516912],
					[66.231438885761705, 8.2932081018756669],
					[66.23143887230637, 8.2932081022521658],
					[66.231438865130201, 8.2932081024529634],
					[66.231299976570554, 8.2932331003948665],
					[66.230994389025085, 8.2932497995349657],
					[66.230994373785833, 8.293249802184766],
					[66.230994345998383, 8.2932498074090972],
					[66.230994330769221, 8.2932498122820402],
					[66.230819338261398, 8.2933137109930097],
					[66.230544345923306, 8.2933915075168976],
					[66.230544337860223, 8.2933915099658275],
					[66.230544322641165, 8.293391517061691],
					[66.230544316372175, 8.2933915194604335],
					[66.23029991570759, 8.293505419830753],
					[66.230299914810573, 8.2935054198558404],
					[66.23009431636018, 8.2936026184152674],
					[66.22975821679313, 8.2937554185928501],
					[66.229758214999066, 8.2937554186430074],
					[66.229758208730047, 8.2937554210415865],
					[66.229758206946101, 8.2937554233147708],
					[66.229652706827679, 8.293810922886685],
					[66.22921100737662, 8.2940415219107457],
					[66.229211002901636, 8.2940415242590646],
					[66.229210993961729, 8.2940415311787064],
					[66.229210990383763, 8.2940415335019573],
					[66.229202689971245, 8.2940470323790745],
					[66.229202656943215, 8.2940470688743151],
					[66.229202611569292, 8.2940471524031683],
					[66.229202600120416, 8.2940471994116916],
					[66.229202599730755, 8.2940498740123108],
					[66.228752713595071, 8.2942637190587938],
					[66.228752710017091, 8.2942637213819719],
					[66.228752701964098, 8.2942637260533889],
					[66.228752698386117, 8.2942637283765865],
					[66.228644301751388, 8.2943276260025094],
					[66.228308205422366, 8.2945081240329603],
					[66.228308200947339, 8.2945081263811122],
					[66.228308191100297, 8.2945081311024857],
					[66.228308185738385, 8.2945081356985888],
					[66.228066591912111, 8.2946748324763959],
					[66.227858197123027, 8.2947998290869247],
					[66.227858190864097, 8.2947998337079429],
					[66.227858178336064, 8.2947998407270784],
					[66.227858172077106, 8.2947998453480984],
					[66.227438793291441, 8.2951470300925703],
					[66.227408217351154, 8.2951609172235941],
					[66.227408200358212, 8.2951609288132193],
					[66.227408169053263, 8.2951609496945711],
					[66.227408153864459, 8.2951609634569667],
					[66.227363753660441, 8.295208162624494],
					[66.227363749205608, 8.295208169418073],
					[66.227363740285895, 8.2952081807824261],
					[66.227363735820944, 8.2952081853531858],
					[66.227110938922991, 8.2955720799699524],
					[66.226966542987739, 8.295755476571216],
					[66.226966540306748, 8.2957554788690135],
					[66.226966535851929, 8.2957554856624061],
					[66.226966534067969, 8.2957554879351978],
					[66.226822035611363, 8.2959720866363629],
					[66.22655543838107, 8.2963415831113316],
					[66.226555427687344, 8.2963415989703861],
					[66.226555410805233, 8.2963416350090959],
					[66.226555404616775, 8.296341655188753],
					[66.226524804968633, 8.2964749555535491],
					[66.226294204942192, 8.2974582538456438],
					[66.22629420408542, 8.2974582627614915],
					[66.226294200557732, 8.2974582761976396],
					[66.226294200597977, 8.2974582850885632],
					[66.226241500917851, 8.2981721857231125],
					[66.226241500020848, 8.2981721857480295],
					[66.226241500030909, 8.2981721879707511],
					[66.226241500030909, 8.2981721879707511],
					[66.226219200422221, 8.2985721898468761],
					[66.226219200422221, 8.2985721898468761],
					[66.226219200432254, 8.2985721920695958],
					[66.226219200442316, 8.2985721942923352],
					[66.226210899749333, 8.2988832893137623],
					[66.226141500806435, 8.299686082606982],
					[66.226141499929454, 8.2996860870772746],
					[66.226141499969586, 8.2996860959681502],
					[66.226141499989666, 8.2996861004135738],
					[66.226141499683195, 8.2998193912263005],
					[66.226088700842979, 8.3004416823515328],
					[66.226088700852998, 8.3004416845742437],
					[66.226088700852998, 8.3004416845742437],
					[66.226066500809452, 8.3007388792627754],
					[66.226058101768515, 8.3007999726742199],
					[66.226058101778506, 8.3007999748969272],
					[66.226058101778506, 8.3007999748969272],
					[66.22596090185705, 8.301572171199183],
					[66.225905402677, 8.3019166680119909],
					[66.225905401779968, 8.3019166680367764],
					[66.225905401789973, 8.3019166702594891],
					[66.225838701597866, 8.3023777716546583],
					[66.225838701607856, 8.3023777738773443],
					[66.225838701617846, 8.3023777761000339],
					[66.225838700720843, 8.3023777761248017],
					[66.225760900840513, 8.3030305760340948],
					[66.225760900840513, 8.3030305760340948],
					[66.225760900850503, 8.3030305782567773],
					[66.225760900850503, 8.3030305782567773],
					[66.225738701242136, 8.3032416781640759],
					[66.225738701262117, 8.3032416826094568],
					[66.225738700385065, 8.3032416870795522],
					[66.225738700395056, 8.3032416893022329],
					[66.225691499785626, 8.3041443901136311],
					[66.225660899678445, 8.3047166892631843],
					[66.225660899678445, 8.3047166892631843],
					[66.225660899688393, 8.3047166914858774],
					[66.225660899688393, 8.3047166914858774],
					[66.225638699805558, 8.3052582860434168],
					[66.225630401025143, 8.3053499774552968],
					[66.225485904970895, 8.3063721480186956],
					[66.22522761433008, 8.3070360271232335],
					[66.225044218341154, 8.3074971174214749],
					[66.224891522287578, 8.3077916086700281],
					[66.224891522287578, 8.3077916086700281],
					[66.224891519606402, 8.3077916109663814],
					[66.224891518719332, 8.3077916132135634],
					[66.224777619300042, 8.3080305136453827],
					[66.224777619309975, 8.3080305158679799],
					[66.224777618422848, 8.3080305181151441],
					[66.224777616628799, 8.3080305181642746],
					[66.224655416933388, 8.3083055184307071],
					[66.22465541605618, 8.3083055229004596],
					[66.224655411610684, 8.3083055319135859],
					[66.224655410723585, 8.3083055341607484],
					[66.224547010904132, 8.3086221349248337],
					[66.224547008252685, 8.3086221438887975],
					[66.224547003836889, 8.3086221595695839],
					[66.224547002979477, 8.3086221684844599],
					[66.224485903017396, 8.308999968224235],
					[66.224485902130297, 8.3089999704713371],
					[66.224485902140202, 8.3089999726939094],
					[66.224485902150093, 8.3089999749164818],
					[66.224388701915274, 8.30973887483065],
					[66.224388701028133, 8.3097388770777414],
					[66.224388701067681, 8.309738885967997],
					[66.22438870019046, 8.3097388904376501],
					[66.224341500393351, 8.3107416908921756],
					[66.224341500403213, 8.3107416931147373],
					[66.224341500422966, 8.3107416975598802],
					[66.224341500432843, 8.3107416997824402],
				],
				[
					[66.139708099737007, 8.2314443990048289],
					[66.139708099751616, 8.2316110999244643],
					[66.139708099751616, 8.2316110999244643],
					[66.139708099762629, 8.2316111021395901],
					[66.139716500191668, 8.232552802842001],
					[66.139716500224623, 8.2325528094873182],
					[66.13971650210658, 8.232552827153933],
					[66.139716503047538, 8.2325528359872457],
					[66.139769202930182, 8.2328500323992593],
					[66.139885901844949, 8.2336556281266571],
					[66.139885902752951, 8.2336556303146828],
					[66.139885902785863, 8.2336556369600657],
					[66.13988590369388, 8.233655639148088],
					[66.140105403986311, 8.2347333396967848],
					[66.140105405813202, 8.2347333462880865],
					[66.140105410375043, 8.2347333616588205],
					[66.140105413109922, 8.2347333704382049],
					[66.14011369992815, 8.2347556363424985],
					[66.140113699713353, 8.2347749996102504],
					[66.140113700665168, 8.2347750106589217],
					[66.140113702557812, 8.2347750305411243],
					[66.14011370440663, 8.2347750415627399],
					[66.140341487915805, 8.2358555647184044],
					[66.140205428086063, 8.236083196534862],
					[66.14020541833915, 8.2360832211986885],
					[66.140205404238358, 8.2360832725794229],
					[66.140205399884508, 8.2360832992963324],
					[66.140205400147977, 8.2369666679534372],
					[66.140166511118707, 8.2370805346903015],
					[66.140166507585221, 8.2370805458739671],
					[66.140166503198444, 8.2370805659451989],
					[66.140166500561989, 8.2370805771018905],
					[66.140122001118044, 8.2374860779868193],
					[66.140122001128972, 8.2374860802019629],
					[66.1401220011399, 8.2374860824171066],
					[66.1401220011508, 8.2374860846322502],
					[66.140074800808122, 8.2380805829018211],
					[66.140074799954746, 8.2380805917893216],
					[66.140074800031101, 8.2380806072953465],
					[66.140074800960861, 8.2380806139138247],
					[66.140083100064146, 8.2381889095079828],
					[66.140105399669181, 8.2391861026559372],
					[66.140105400034798, 8.2392388996023769],
					[66.140105400078383, 8.239238908462946],
					[66.140105401970459, 8.2392389283454808],
					[66.140105403808079, 8.2392389371522743],
					[66.140127603564721, 8.2393556362473479],
					[66.140127603575621, 8.2393556384625164],
					[66.140127603586507, 8.2393556406776618],
					[66.140127603597406, 8.2393556428928036],
					[66.140327604347036, 8.2402889420151251],
					[66.140327607081602, 8.2402889507952075],
					[66.140327611653731, 8.240288968382238],
					[66.140327616182375, 8.240288977108575],
					[66.140458111358384, 8.240600067295949],
					[66.140585908061979, 8.2410389570010771],
					[66.140585908061979, 8.2410389570010771],
					[66.14058590896984, 8.2410389591894262],
					[66.14058590896984, 8.2410389591894262],
					[66.140694204569314, 8.2413861442781258],
					[66.140755402123347, 8.241788929753584],
					[66.140755403042093, 8.2417889341571744],
					[66.140755403971653, 8.2417889407759901],
					[66.140755404879556, 8.2417889429643818],
					[66.140913702294256, 8.2424861312407405],
					[66.140938699906428, 8.2427528099743732],
					[66.140952599797359, 8.2435916950131194],
					[66.140938699860342, 8.2438582894960337],
					[66.140938699871171, 8.243858291711252],
					[66.140938699882, 8.243858293926472],
					[66.140938699882, 8.243858293926472],
					[66.140913699742981, 8.244699994872434],
					[66.140913699742981, 8.244699994872434],
					[66.140913699753796, 8.2446999970876504],
					[66.140913699753796, 8.2446999970876504],
					[66.140908100033215, 8.2450582973176818],
					[66.140908100033215, 8.2450582973176818],
					[66.140908100044015, 8.2450582995328965],
					[66.14090810005483, 8.2450583017481147],
					[66.140913699562148, 8.2458083000071181],
					[66.140913699572948, 8.2458083022223363],
					[66.140913699583763, 8.2458083044375527],
					[66.140913699583763, 8.2458083044375527],
					[66.140922000416822, 8.2461583039558146],
					[66.140922000427594, 8.2461583061710524],
					[66.140922000427594, 8.2461583061710524],
					[66.14092200043838, 8.2461583083862706],
					[66.140952599712392, 8.2469167085553412],
					[66.140952599723178, 8.2469167107705648],
					[66.140952599733964, 8.2469167129857848],
					[66.140952599733964, 8.2469167129857848],
					[66.141021999914486, 8.2479028150825489],
					[66.141030399882865, 8.2480222080437606],
					[66.141030399918634, 8.2481277990161566],
					[66.141030399929392, 8.248127801231405],
					[66.141030399950921, 8.248127805661861],
					[66.141030399950921, 8.248127805661861],
					[66.141060900037445, 8.2491278025859547],
					[66.141060900198539, 8.2491722000656207],
					[66.141060900198539, 8.2491722000656207],
					[66.141060900220054, 8.2491722044961087],
					[66.141060900220054, 8.2491722044961087],
					[66.141083099994276, 8.2500250038442058],
					[66.141083099567254, 8.2502249998361297],
					[66.141083100314958, 8.2502332992847904],
					[66.141083100347174, 8.2502333059305126],
					[66.141083101287151, 8.2502333147649587],
					[66.141083101308624, 8.2502333191954271],
					[66.141166500811252, 8.2511278180816241],
					[66.141166501740429, 8.251127824700891],
					[66.141166502691064, 8.2511278357506388],
					[66.141166503609568, 8.2511278401546413],
					[66.141210903555574, 8.2513389379732303],
					[66.141310902641791, 8.2518917370798857],
					[66.141310905375704, 8.2518917458615437],
					[66.141310910843572, 8.2518917634248972],
					[66.141310913577456, 8.2518917722065765],
					[66.141524813066212, 8.25243897019085],
					[66.141533113134244, 8.2524611695560885],
					[66.141533118580611, 8.2524611826891512],
					[66.141533133061486, 8.2524612088495388],
					[66.141533141198948, 8.2524612219032978],
					[66.141541541039913, 8.2524723204288541],
					[66.141541541050614, 8.2524723226441292],
					[66.141822040812372, 8.2528390213542249],
					[66.14182204172009, 8.2528390235431051],
					[66.141822045329576, 8.2528390278680188],
					[66.14182204712364, 8.2528390278151775],
					[66.141991542182083, 8.2530418219596378],
					[66.142119215998534, 8.2532194860155954],
					[66.142197005111257, 8.2535250451937134],
					[66.14228040135653, 8.2539278239260021],
					[66.142302600275315, 8.2546306033651611],
					[66.142302599879855, 8.2549833010657743],
					[66.142302599890527, 8.2549833032811186],
					[66.14230259992253, 8.2549833099271481],
					[66.142302599933188, 8.2549833121424907],
					[66.142349799682052, 8.2557361114127019],
					[66.142363700422266, 8.2559611120550951],
					[66.14236370136193, 8.255961120890202],
					[66.142363704127561, 8.2559611363187315],
					[66.142363705067211, 8.2559611451538348],
					[66.142455404920653, 8.256352843021002],
					[66.142524804001255, 8.2566750420912243],
					[66.142524806734897, 8.2566750508738398],
					[66.142524813099243, 8.2566750684127594],
					[66.142524815832914, 8.256675077195375],
					[66.142594211074538, 8.2568389652024017],
					[66.14272200676271, 8.2573000519635098],
					[66.142722007670343, 8.2573000541526298],
					[66.142722008588649, 8.2573000585571243],
					[66.142722008599293, 8.2573000607725042],
					[66.142913699777878, 8.2579139303253442],
					[66.142913699916392, 8.2579278006831256],
					[66.142913700251455, 8.2579361007332732],
					[66.142913700262071, 8.2579361029486691],
					[66.142913700304575, 8.2579361118102828],
					[66.142913701222867, 8.2579361162148377],
					[66.142988699584237, 8.2588611075263714],
					[66.142988699716852, 8.2590416996960538],
					[66.142974799716498, 8.2600249980405884],
					[66.142974799716498, 8.2600249980405884],
					[66.142974799716498, 8.2600249980405884],
					[66.142974799727099, 8.260025000255995],
					[66.14297480002061, 8.2601499999423247],
					[66.142974800031212, 8.2601500021577507],
					[66.142974800062987, 8.260150008803965],
					[66.142974800073588, 8.260150011019368],
					[66.143019200065908, 8.2610167091839859],
					[66.143019201923437, 8.2610167224241948],
					[66.143019204720332, 8.2610167444999227],
					[66.143019208361338, 8.2610167554724523],
					[66.143088706802104, 8.2612556511395763],
					[66.143197005762431, 8.2617056460736773],
					[66.143197006691167, 8.2617056526938626],
					[66.143197011239749, 8.2617056658558834],
					[66.143197013065517, 8.2617056724499385],
					[66.143380413285684, 8.2621833726120464],
					[66.143380414193288, 8.2621833748013938],
					[66.143380416008483, 8.2621833791800885],
					[66.143380416916045, 8.262183381369459],
					[66.143416514126486, 8.2622639730802234],
					[66.14344700866711, 8.2623528620029774],
					[66.143608104796229, 8.2628944467675325],
					[66.14371370317474, 8.2634528369400773],
					[66.143752601937408, 8.2636611277770875],
					[66.143835901166696, 8.2644917207588993],
					[66.143835901177226, 8.2644917229743804],
					[66.143835902105835, 8.2644917295948144],
					[66.143835903023941, 8.264491733999785],
					[66.143844199730381, 8.2645417165504398],
					[66.143844200235407, 8.2645556006872987],
					[66.143844200266983, 8.2645556073337492],
					[66.143844201227225, 8.2645556206006763],
					[66.143844202155833, 8.2645556272211138],
					[66.143958101586165, 8.2653778259775237],
					[66.143958103422293, 8.2653778347875342],
					[66.143958106176484, 8.2653778480025721],
					[66.143958107094548, 8.2653778524075978],
					[66.144035907330803, 8.2656583539224577],
					[66.144035908227835, 8.2656583538964856],
					[66.14403590914587, 8.2656583583015362],
					[66.144035909156415, 8.2656583605170351],
					[66.144149809271568, 8.2660167619139049],
					[66.14414981380925, 8.2660167728616845],
					[66.144149823781675, 8.2660167947312839],
					[66.144149829205872, 8.266016803437596],
					[66.144294229430642, 8.2662529049736868],
					[66.14429423755638, 8.2662529158177698],
					[66.144294257406585, 8.2662529396176598],
					[66.1442942673159, 8.2662529481942997],
					[66.14444706677628, 8.2663890499948725],
					[66.144447082975276, 8.266389060605535],
					[66.144447117167388, 8.2663890817750101],
					[66.144447135139458, 8.2663890879027289],
					[66.144744335403345, 8.2664918900296449],
					[66.144744365923316, 8.2664918935787313],
					[66.144744428725744, 8.2664918939783369],
					[66.144744460111212, 8.2664918908547946],
					[66.144858359886058, 8.2664557907300882],
					[66.144858366154764, 8.2664557883328893],
					[66.14485837601157, 8.2664557858319352],
					[66.144858382280262, 8.2664557834347576],
					[66.145194482074203, 8.2663057823307824],
					[66.145194491023489, 8.2663057776400866],
					[66.145194508024986, 8.2663057682846208],
					[66.145194516963755, 8.2663057613783035],
					[66.145430700923015, 8.2661362730339878],
					[66.145644475934148, 8.2660390849939436],
					[66.145966669050338, 8.265919624551648],
					[66.146088781793637, 8.2660084612644216],
					[66.146088787196817, 8.266008465540013],
					[66.14608879619864, 8.2660084719274618],
					[66.146088800694301, 8.2660084740133346],
					[66.146316558843992, 8.2661390510048101],
					[66.146538732749192, 8.2664779100279269],
					[66.146538743576585, 8.2664779230109211],
					[66.146538769726888, 8.2664779510629085],
					[66.146538784142351, 8.2664779639421244],
					[66.146605484040194, 8.2665251619820381],
					[66.14660553616234, 8.2665251804188689],
					[66.146605641167085, 8.2665251884621398],
					[66.146605694936213, 8.2665251758268941],
					[66.146988982371056, 8.2663195840824155],
					[66.147155669427036, 8.2662584888405775],
					[66.147155689109098, 8.2662584771907177],
					[66.147155727565732, 8.2662584517011748],
					[66.147155745443229, 8.2662584378874477],
					[66.147439011943888, 8.2659584741084213],
					[66.147766763243936, 8.2658445895814729],
					[66.147880549083169, 8.2658085253547018],
					[66.148055463165846, 8.2659723459331236],
					[66.148055483871062, 8.265972360846936],
					[66.148055528838029, 8.2659723839231063],
					[66.148055553110339, 8.2659723943013166],
					[66.148330486246735, 8.2660390772518753],
					[66.148766542713432, 8.2665918231573787],
					[66.148766547219566, 8.2665918274594876],
					[66.148766555334831, 8.2665918360896686],
					[66.148766558943962, 8.2665918404177212],
					[66.148780458885639, 8.2666057419058987],
					[66.148780484086444, 8.2666057589068487],
					[66.148780538055092, 8.2666057883731128],
					[66.148780567698992, 8.2666057963806683],
					[66.148797167906892, 8.2666084965626503],
					[66.148797173299556, 8.266608498622908],
					[66.148797184063881, 8.2666084983115891],
					[66.148797188559513, 8.2666085003977905],
					[66.149055588842401, 8.2666224005542936],
					[66.149055589739419, 8.26662240052835],
					[66.149055593327546, 8.2666224004245805],
					[66.149055594224549, 8.2666224003986368],
					[66.149230593756101, 8.2666279994206899],
					[66.149230666299786, 8.266627972943775],
					[66.149230769892853, 8.26662787243224],
					[66.149230800055747, 8.2666278006395153],
					[66.149230799738888, 8.266625083511709],
					[66.149239041367451, 8.2666168400142883],
					[66.149239043161501, 8.2666168399624009],
					[66.149239047625642, 8.2666168354007716],
					[66.14923905030625, 8.2666168331069869],
					[66.149680749749166, 8.2661195327172212],
					[66.149680749749166, 8.2661195327172212],
					[66.149680752429759, 8.2661195304233228],
					[66.149680753316261, 8.2661195281813686],
					[66.150047353190175, 8.2656834285273817],
					[66.150047356757241, 8.2656834239914225],
					[66.150047363891417, 8.2656834149194811],
					[66.150047366561466, 8.2656834104094976],
					[66.15012236343803, 8.2655695172701282],
					[66.150191856926241, 8.2654807234226642],
					[66.150191857823259, 8.2654807233966796],
					[66.150191857812743, 8.2654807211806194],
					[66.150575153737947, 8.2649834283468238],
					[66.150908437862029, 8.2646140460929978],
					[66.151016819152304, 8.2645251605712087],
					[66.151291810879854, 8.2643418655598282],
					[66.151291815354455, 8.2643418632135432],
					[66.151291821612588, 8.264341858599094],
					[66.151291826076644, 8.264341854036692],
					[66.151466817169222, 8.2642001609836875],
					[66.15153613277252, 8.2641558149862604],
					[66.151916648401297, 8.264258493436186],
					[66.151916672642088, 8.264258497165752],
					[66.151916722886071, 8.2642584979242564],
					[66.151916747992317, 8.2642584949792273],
					[66.151938868987045, 8.2642530144504889],
					[66.152052691016308, 8.264319567007627],
					[66.15229158414121, 8.2644890621610916],
					[66.152291617415457, 8.2644890789280527],
					[66.15229168568429, 8.2644890968963818],
					[66.15229172157585, 8.2644890980717101],
					[66.152366721559588, 8.2644807980574306],
					[66.152366749346356, 8.2644807928182686],
					[66.152366798588019, 8.264480771440935],
					[66.15236682274454, 8.2644807574409338],
					[66.152594518570837, 8.2643029606077469],
					[66.152808415436212, 8.2641529645952794],
					[66.152808420797328, 8.2641529600065393],
					[66.152808433302965, 8.2641529485607013],
					[66.152808438664081, 8.2641529439719612],
					[66.152900138915868, 8.2640640441031827],
					[66.152900144276941, 8.2640640395144036],
					[66.152900153194508, 8.2640640281726334],
					[66.15290015765855, 8.2640640236098957],
					[66.153258448323015, 8.2636029367310773],
					[66.153677928672508, 8.2632168519862148],
					[66.153702903214125, 8.2631973732922734],
					[66.153786179821608, 8.2631612841946644],
					[66.15378618429618, 8.2631612818479585],
					[66.153786192348321, 8.2631612771806182],
					[66.153786195936419, 8.2631612770763319],
					[66.154152858170235, 8.2629612969006043],
					[66.154227779028403, 8.2629558025826384],
					[66.154602727410733, 8.2630640864885265],
					[66.154624888646012, 8.2630751688098449],
					[66.15469427092367, 8.2631334539566179],
					[66.15469427271772, 8.263133453904473],
					[66.154694278120971, 8.2631334581808922],
					[66.154694279925565, 8.2631334603451965],
					[66.154960980358013, 8.2633334589277982],
					[66.154960983967243, 8.263333463256437],
					[66.154960992061547, 8.2633334674547463],
					[66.154960995670805, 8.2633334717834064],
					[66.155052696016554, 8.2633890704842194],
					[66.155052711308187, 8.2633890789069877],
					[66.15505274006577, 8.2633890891552255],
					[66.155052756243904, 8.2633890953354676],
					[66.155358255654235, 8.2634584957734027],
					[66.155358278090389, 8.2634584973383287],
					[66.155358322055221, 8.2634584982777515],
					[66.155358343573297, 8.2634584954357599],
					[66.155494444095979, 8.2634279947847915],
					[66.15549446022132, 8.2634279898826222],
					[66.155494490667465, 8.2634279779139295],
					[66.155494505885258, 8.2634279708213452],
					[66.155922305574862, 8.2631612702820263],
					[66.155922311832938, 8.2631612656664153],
					[66.155922325246109, 8.263161256409159],
					[66.155922332401204, 8.2631612517674728],
					[66.155944532426275, 8.2631418519301238],
					[66.155944532415731, 8.263141849713584],
					[66.155944533312763, 8.2631418496875089],
					[66.155997329697058, 8.2630945524851267],
					[66.156394525589661, 8.2627723554502595],
					[66.156394529167187, 8.2627723531293267],
					[66.156394533631229, 8.262772348565731],
					[66.156394537208755, 8.2627723462447804],
					[66.156586191590776, 8.2625918872515136],
					[66.156836121831546, 8.2625529987979363],
					[66.157049876061322, 8.2625391073106034],
					[66.157288700288291, 8.2630528449940055],
					[66.157288699951053, 8.2630556006944111],
					[66.157288714556742, 8.2630556534769912],
					[66.157288770605035, 8.2630557427431803],
					[66.157288812047611, 8.263055779226768],
					[66.157294249827572, 8.2630584499854578],
					[66.157538719679295, 8.2635583878708303],
					[66.157538789013941, 8.2635584412811376],
					[66.157538942584594, 8.263558474508212],
					[66.157539027717675, 8.2635584542988738],
					[66.157739027839327, 8.2633918529183816],
					[66.157739027839327, 8.2633918529183816],
					[66.15773902873633, 8.2633918528923171],
					[66.157739029633376, 8.2633918528662491],
					[66.15815841884239, 8.2630334604105187],
					[66.158188958045926, 8.2630141000513451],
					[66.158194400151686, 8.2630140992277799],
					[66.158194436011584, 8.2630140937510141],
					[66.158194504069414, 8.2630140673846615],
					[66.158194535370356, 8.2630140465211515],
					[66.15863070065501, 8.2626140795663723],
					[66.158822256300837, 8.2625584911378684],
					[66.158822267951621, 8.2625584885817922],
					[66.158822292118487, 8.2625584767931546],
					[66.15882230286168, 8.2625584720463667],
					[66.159080654441496, 8.2624029999364552],
					[66.159324998402766, 8.2624057998032097],
					[66.159325005578964, 8.2624057995943705],
					[66.159325024416475, 8.2624057990461726],
					[66.159325031582156, 8.2624057966204987],
					[66.159530588863376, 8.2623724037632176],
					[66.15975271897814, 8.2624334855322559],
					[66.159980493933674, 8.2625751701601526],
					[66.159980531682649, 8.2625751845822588],
					[66.15998060889018, 8.2625751956390729],
					[66.159980649256326, 8.2625751944645955],
					[66.160377848711718, 8.2624751940938168],
					[66.160377875569665, 8.2624751822260549],
					[66.16037792566577, 8.2624751519441677],
					[66.160377948006882, 8.2624751335561228],
					[66.160719547711452, 8.2620973347715729],
					[66.160719563752252, 8.2620973121318553],
					[66.160719587739436, 8.26209726265356],
					[66.160719596593438, 8.2620972380058255],
					[66.160866896875774, 8.2613139364188868],
					[66.160866897730486, 8.2613139275248653],
					[66.160866900336927, 8.2613139097106369],
					[66.160866900294621, 8.2613139008427403],
					[66.160866899578366, 8.2604277998971742],
					[66.160866900443622, 8.260427793220094],
					[66.160866898586022, 8.2604277799706125],
					[66.160866897667816, 8.2604277755628335],
					[66.160789097997892, 8.2598055748513151],
					[66.160789097987291, 8.2598055726343524],
					[66.160789097069085, 8.2598055682266285],
					[66.160789097058483, 8.2598055660096446],
					[66.160719596549654, 8.2594110639266187],
					[66.160719594723801, 8.2594110573281778],
					[66.160719591061451, 8.259411041914321],
					[66.160719589235597, 8.2594110353158605],
					[66.160461292321727, 8.2586582449359387],
					[66.16043079490538, 8.2585305546056151],
					[66.160430790335312, 8.2585305370013575],
					[66.160430776741904, 8.2585305085748804],
					[66.160430770388402, 8.2585304932400554],
					[66.160416869892899, 8.2585081931287991],
					[66.160416867191216, 8.2585081909905789],
					[66.160416861766578, 8.2585081822803303],
					[66.160416859054294, 8.2585081779251848],
					[66.160408458802721, 8.2584970780620264],
					[66.160408436270956, 8.2584970565488174],
					[66.160408382268969, 8.2584970204356516],
					[66.160408351706451, 8.2584970080263744],
					[66.160019474590385, 8.2583915140247246],
					[66.159958419732661, 8.2583581379165594],
					[66.159836239936325, 8.2582387565282058],
					[66.159836226427828, 8.2582387458375894],
					[66.159836196730438, 8.2582387267519959],
					[66.159836182335539, 8.2582387183045132],
					[66.159508435300538, 8.2580915412282074],
					[66.159094569159379, 8.2574609925127334],
					[66.159058478006813, 8.2574027062992332],
					[66.158608499751395, 8.2563666586679485],
					[66.158608499560714, 8.2563527996740529],
					[66.158608498589132, 8.2563527841829476],
					[66.158608493954901, 8.2563527532797316],
					[66.158608490292266, 8.2563527378675996],
					[66.158250191928786, 8.2552693421560583],
					[66.158150193256745, 8.2548943471558456],
					[66.158150193256745, 8.2548943471558456],
					[66.157950193344931, 8.2541693475115014],
					[66.157691893080127, 8.2532332459622069],
					[66.15769189126469, 8.2532332415816665],
					[66.157691886715412, 8.2532332284136594],
					[66.157691883992285, 8.2532332218428781],
					[66.15762239122482, 8.2530693378021684],
					[66.157530795000142, 8.2526777537210361],
					[66.157530794092423, 8.2526777515308094],
					[66.15753079317399, 8.2526777471238919],
					[66.157530792266286, 8.2526777449336635],
					[66.157325194851026, 8.2519721532750374],
					[66.15723349710116, 8.2514638640367508],
					[66.157233495274937, 8.2514638574397701],
					[66.157233491622534, 8.2514638442458299],
					[66.15723348979634, 8.2514638376488474],
					[66.15703628989192, 8.2508721376662724],
					[66.157036288065697, 8.2508721310693858],
					[66.157036282619188, 8.2508721179286493],
					[66.157036279906663, 8.250872113574923],
					[66.156783481406706, 8.2503499139908243],
					[66.156516886436535, 8.249777725081648],
					[66.156325190743246, 8.2492277399214728],
					[66.156166895054199, 8.2486805550798188],
					[66.156036297157613, 8.2479749631444257],
					[66.156036296249795, 8.2479749609544974],
					[66.156036294412687, 8.2479749521415631],
					[66.15603629260788, 8.2479749499782233],
					[66.155930798691216, 8.2475777689589904],
					[66.155891899906933, 8.2465388924440362],
					[66.155891899885361, 8.2465388880109636],
					[66.1558918989452, 8.2465388791715171],
					[66.155891898026596, 8.2465388747651041],
					[66.155883498964101, 8.2464721777482648],
					[66.155861299170624, 8.2462527799783327],
					[66.155861299170624, 8.2462527799783327],
					[66.155861299159838, 8.2462527777618106],
					[66.155861299149052, 8.2462527755452637],
					[66.155752998819935, 8.245366675276534],
					[66.155752997912117, 8.2453666730867248],
					[66.155752996971842, 8.2453666642473511],
					[66.155752996064038, 8.2453666620575401],
					[66.155655795665936, 8.2448721608245901],
					[66.155655795655136, 8.2448721586080858],
					[66.155655794747304, 8.2448721564183014],
					[66.155655794736489, 8.2448721542017953],
					[66.155511294807098, 8.2442666539532006],
					[66.15551129298062, 8.2442666473571862],
					[66.155511288419731, 8.2442666319754316],
					[66.155511285707007, 8.2442666276226735],
					[66.155402985980245, 8.2439916267353475],
					[66.155402985969431, 8.2439916245188645],
					[66.155402984164553, 8.2439916223558871],
					[66.15540298415371, 8.2439916201393828],
					[66.155052984123401, 8.2431721203359967],
					[66.155052983226383, 8.243172120362777],
					[66.155052982307666, 8.243172115956634],
					[66.155052982307666, 8.243172115956634],
					[66.154952984137921, 8.2429555213842445],
					[66.154616891183309, 8.2420749401496334],
					[66.154572396405598, 8.2418916605405972],
					[66.154494598305831, 8.2413777703547826],
					[66.154494598294974, 8.2413777681383813],
					[66.154494597397971, 8.2413777681652238],
					[66.154494597387099, 8.2413777659488225],
					[66.154427998855041, 8.2409749782673725],
					[66.154419599682527, 8.2407916916317951],
					[66.154419599671655, 8.240791689415401],
					[66.154419598752881, 8.2407916850094551],
					[66.154419598741995, 8.240791682793061],
					[66.154336299061839, 8.239869382990916],
					[66.154305800315669, 8.2394332854682215],
					[66.154305799407766, 8.239433283278748],
					[66.15430579939688, 8.2394332810623645],
					[66.154305799385995, 8.2394332788459774],
					[66.154236299417349, 8.2387638829206811],
					[66.154197400245948, 8.2380749882921798],
					[66.154197400235049, 8.2380749860758034],
					[66.154197399316203, 8.2380749816700174],
					[66.154197399305289, 8.2380749794536197],
					[66.154152999202694, 8.2376555838472925],
					[66.154136299733509, 8.237374988434782],
					[66.154097400449018, 8.2365499999354057],
					[66.154105800139064, 8.2363417076399763],
					[66.154105800117179, 8.236341703207243],
					[66.15410580008438, 8.2363416965581511],
					[66.154105800051582, 8.2363416899090343],
					[66.154066899609163, 8.2354443908593105],
					[66.154066899609163, 8.2354443908593105],
					[66.154066899598206, 8.2354443886429287],
					[66.154061299616629, 8.2353388899062363],
					[66.154061298697741, 8.2353388855005498],
					[66.154061297745955, 8.2353388744457821],
					[66.154061297724056, 8.2353388700130612],
					[66.154014097689, 8.2350193700496472],
					[66.154014097689, 8.2350193700496472],
					[66.154014096770098, 8.2350193656439803],
					[66.154014096770098, 8.2350193656439803],
					[66.153891898351802, 8.234338870140542],
					[66.153839097708996, 8.2339277751766673],
					[66.153839097698039, 8.2339277729603282],
					[66.153839096768124, 8.2339277663383879],
					[66.153839096746182, 8.2339277619057096],
					[66.153708498989914, 8.2332360745459017],
					[66.15364739996528, 8.2323638869870734],
					[66.153647399046264, 8.2323638825815859],
					[66.153647399013295, 8.232363875932597],
					[66.153647398105264, 8.2323638737434344],
					[66.153616897554514, 8.2321305742040831],
					[66.153616898440532, 8.2321305719605959],
					[66.153616898440532, 8.2321305719605959],
					[66.153550198252887, 8.2316832703079292],
					[66.153550197344856, 8.231683268118779],
					[66.153550197322858, 8.2316832636861488],
					[66.153550196414841, 8.2316832614970199],
					[66.153419596088526, 8.2310277601061248],
					[66.15341959518048, 8.2310277579170315],
					[66.15341959334242, 8.2310277491062145],
					[66.153419592423347, 8.2310277447008158],
					[66.153122391559137, 8.229997144735119],
					[66.153122392445155, 8.2299971424915981],
					[66.153122389732019, 8.2299971381407779],
					[66.153122389721005, 8.2299971359245028],
					[66.153100193858478, 8.229930549150005],
					[66.153091895880522, 8.2298888606791163],
					[66.152886297793799, 8.2288305709597758],
					[66.152816898590132, 8.2281749802206257],
					[66.15277239863839, 8.2277249828787102],
					[66.152747398703838, 8.2274305820378331],
					[66.152747398692767, 8.2274305798215916],
					[66.152747397762582, 8.2274305732001807],
					[66.152747397740455, 8.2274305687676996],
					[66.152625200402341, 8.2266388843313809],
					[66.152625199735638, 8.2266277992741408],
					[66.152625196003584, 8.2266277705725486],
					[66.152625178683351, 8.2266277156865666],
					[66.152625165992205, 8.2266276894748245],
					[66.152619576977088, 8.2266193039926296],
					[66.152397384705807, 8.2260805238247539],
					[66.152397381073413, 8.2260805150694143],
					[66.152397373830752, 8.2260805019911807],
					[66.152397370209414, 8.2260804954520772],
					[66.152175175172815, 8.2257221017942044],
					[66.152077980794445, 8.2255277150339978],
					[66.151894582823658, 8.2251082195153238],
					[66.151894582823658, 8.2251082195153238],
					[66.151894581915528, 8.225108217326575],
					[66.15189458100744, 8.225108215137821],
					[66.151719584103688, 8.2247305200767897],
					[66.151602990963568, 8.2244360390221907],
					[66.1514751942929, 8.2239332514069332],
					[66.151475193373642, 8.2239332470021314],
					[66.151475191568494, 8.2239332448409179],
					[66.151475190649265, 8.223933240436093],
					[66.151291892382915, 8.223336043300522],
					[66.151261292316505, 8.2232277448971534],
					[66.151261292316505, 8.2232277448971534],
					[66.15126129140836, 8.2232277427085236],
					[66.151261291397262, 8.2232277404923924],
					[66.150955791693306, 8.2222388449297021],
					[66.150811293316906, 8.2217110472626995],
					[66.15081129239762, 8.2217110428580931],
					[66.150811288776083, 8.2217110363200199],
					[66.150811287856811, 8.2217110319153939],
					[66.150605794222017, 8.2211416479331643],
					[66.150436296657105, 8.2202443631632942],
					[66.150436295748946, 8.2202443609748244],
					[66.150436295737777, 8.220244358758789],
					[66.150436294840759, 8.2202443587863723],
					[66.150391896088109, 8.2200388605986756],
					[66.150352996941947, 8.2198193659405323],
					[66.150352996011407, 8.2198193593200273],
					[66.150352993264519, 8.2198193483226838],
					[66.150352992345134, 8.219819343918207],
					[66.150094591753984, 8.2189388448043044],
					[66.150094589018195, 8.2189388360231543],
					[66.150094583557802, 8.218938820676879],
					[66.150094580833183, 8.2189388141117572],
					[66.14998628078196, 8.2187110141826505],
					[66.149986279873787, 8.2187110119942712],
					[66.149986278068567, 8.2187110098335552],
					[66.149986278057369, 8.2187110076175589],
					[66.149894578158467, 8.2185332082988687],
					[66.149894570018162, 8.2185331952517302],
					[66.149894552851762, 8.2185331714010754],
					[66.149894542928621, 8.2185331606252152],
					[66.149697343279442, 8.2183304607749719],
					[66.149697330665219, 8.2183304500822292],
					[66.149697302756948, 8.2183304309956622],
					[66.149697289245722, 8.2183304203305703],
					[66.149444520898456, 8.2182054360794545],
					[66.149344544829262, 8.2180998626841255],
					[66.149344539413576, 8.2180998562022438],
					[66.149344526799354, 8.2180998455097587],
					[66.149344521394866, 8.2180998412438413],
					[66.149033420626523, 8.2178637414396398],
					[66.149033411622739, 8.2178637350685797],
					[66.149033392718195, 8.2178637223541049],
					[66.149033382828591, 8.2178637182266048],
					[66.149002882804538, 8.2178498189000262],
					[66.149002863025373, 8.217849810645065],
					[66.149002820820769, 8.2178498030818066],
					[66.149002800166969, 8.2178497992863502],
					[66.148994450329013, 8.2178497997469382],
					[66.148636193984615, 8.2176581249090255],
					[66.148636186786092, 8.2176581206986672],
					[66.148636170594955, 8.2176581123332717],
					[66.14863616340763, 8.2176581103387889],
					[66.148544465994249, 8.2176276118363933],
					[66.148422268008972, 8.217583112931143],
					[66.148094480015246, 8.2174637151076606],
					[66.147872290925079, 8.2173498217477849],
					[66.147872286428779, 8.217349819670396],
					[66.147872273836896, 8.2173498134105252],
					[66.147872269340624, 8.217349811333138],
					[66.147644488662195, 8.217266520153709],
					[66.147294507146924, 8.2170442319438521],
					[66.147294491852762, 8.2170442235515964],
					[66.147294460378674, 8.2170442090105613],
					[66.147294443312944, 8.2170442051052284],
					[66.147194490873332, 8.2170220141545709],
					[66.146950127822791, 8.2168192460616716],
					[66.146950124223508, 8.2168192439567456],
					[66.146950117013731, 8.2168192375311513],
					[66.146950113414462, 8.2168192354262306],
					[66.1467445479189, 8.2166776593397444],
					[66.146552970388612, 8.2163692949052027],
					[66.146552964064597, 8.216369286236425],
					[66.146552953221871, 8.2163692710591487],
					[66.146552946012037, 8.21636926463378],
					[66.146294562133122, 8.2160915809606436],
					[66.146094578934637, 8.2157388116266947],
					[66.145844583626229, 8.2151555217405861],
					[66.145844582717999, 8.2151555195527131],
					[66.145844581809726, 8.2151555173648418],
					[66.145844580901468, 8.2151555151769458],
					[66.145608490925454, 8.2146471355416981],
					[66.14538629459534, 8.21363605785732],
					[66.145386289111883, 8.213636038083834],
					[66.145386273682433, 8.2136360031071209],
					[66.145386264633473, 8.2136359878760867],
					[66.145325164902815, 8.213547086415721],
					[66.145325164902815, 8.213547086415721],
					[66.145325164005811, 8.2135470864435369],
					[66.145325163994528, 8.2135470842279528],
					[66.144936263617723, 8.2129915858464777],
					[66.144936217678335, 8.2129915496016039],
					[66.144936113398998, 8.2129915085192966],
					[66.144936054173357, 8.2129915059252738],
					[66.144722154140936, 8.2130415058931678],
					[66.144722105825991, 8.2130415317670238],
					[66.144722031745474, 8.2130416071898349],
					[66.144722005071628, 8.2130416545510787],
					[66.14459980465405, 8.2135693544642177],
					[66.144599803779542, 8.2135693589230758],
					[66.144599802916332, 8.2135693655974791],
					[66.144599802041824, 8.213569370056339],
					[66.144569201721055, 8.2137693685067887],
					[66.144494203690314, 8.2142221589861606],
					[66.144424811007099, 8.2145138286510679],
					[66.144333126980811, 8.2146859989972913],
					[66.144158140877749, 8.2149665773032758],
					[66.14404160959711, 8.2150887041689558],
					[66.143761088394953, 8.2151053996668342],
					[66.143761067786059, 8.2151054047361729],
					[66.143761028362263, 8.215105414819357],
					[66.143761007764567, 8.2151054221041644],
					[66.143599950748722, 8.2151887008426616],
					[66.143341700213099, 8.2151886991616703],
					[66.143341655418396, 8.2151887116264959],
					[66.143341573980891, 8.2151887518142708],
					[66.143341539143293, 8.2151887816971225],
					[66.143149870999125, 8.2154498377581469],
					[66.143019322060027, 8.2155054159431948],
					[66.143019280954491, 8.2155054482348184],
					[66.143019220361708, 8.2155055298753741],
					[66.143019200863222, 8.2155055770088978],
					[66.142983101646934, 8.2158332748768856],
					[66.142905402203951, 8.2163332693175271],
					[66.142905401340585, 8.2163332759913903],
					[66.142905399625107, 8.21633329155447],
					[66.142905399669999, 8.2163333004159949],
					[66.142905400323173, 8.2169443799902062],
					[66.142716504173549, 8.2179249618651795],
					[66.142716503276517, 8.2179249618928356],
					[66.14269150396548, 8.2180582585566988],
					[66.142660906071484, 8.2181888515287813],
					[66.142402606763937, 8.2191777481666595],
					[66.142272007183294, 8.2196693464544026],
					[66.142088709190929, 8.22029434019659],
					[66.141830413130378, 8.2210721294126419],
					[66.141685916185807, 8.2214138212034182],
					[66.14168591174527, 8.2214138302021933],
					[66.141685907349384, 8.2214138480620509],
					[66.141685903817049, 8.2214138592485657],
					[66.14151650373762, 8.2222138597707168],
					[66.141516502851758, 8.2222138620134775],
					[66.141516502010418, 8.2222138731172905],
					[66.141516501135683, 8.2222138775753102],
					[66.141480400732377, 8.2225305767213399],
					[66.141480400788041, 8.2225305877976353],
					[66.141480400899368, 8.2225306099502227],
					[66.141480400955018, 8.2225306210265163],
					[66.141555401044116, 8.2232444217725291],
					[66.141555401044116, 8.2232444217725291],
					[66.141555401055228, 8.22324442398779],
					[66.141555401055228, 8.22324442398779],
					[66.141602601264438, 8.2236333248602396],
					[66.141602603091826, 8.2236333314511487],
					[66.141602604952567, 8.2236333446878582],
					[66.141602606779955, 8.2236333512787514],
					[66.14169980135182, 8.2240028308877413],
					[66.141730399332957, 8.2247360740929913],
					[66.141410910205508, 8.2257305385651946],
					[66.141410909330659, 8.2257305430230723],
					[66.141410906672846, 8.2257305497509865],
					[66.141410905786927, 8.2257305519936121],
					[66.141380409765304, 8.2258555336916661],
					[66.140960918664106, 8.226749915027332],
					[66.140852618868294, 8.2269805154391271],
					[66.140852617982318, 8.2269805176816639],
					[66.140852616210424, 8.2269805221667589],
					[66.140852614427459, 8.2269805244366303],
					[66.140519214389116, 8.2278082240824588],
					[66.140519214400172, 8.2278082262976344],
					[66.140519212617193, 8.227808228567417],
					[66.140519212628263, 8.2278082307825908],
					[66.140410912915996, 8.2280999306511902],
					[66.140410911133017, 8.2280999329209443],
					[66.140410909372136, 8.2280999396210461],
					[66.140410907600213, 8.2280999441059635],
					[66.14008312177944, 8.2292081954974243],
					[66.140074857579179, 8.2292165596626106],
					[66.140074839749147, 8.2292165823590953],
					[66.140074813092355, 8.2292166341249331],
					[66.140074804254624, 8.2292166609791657],
					[66.13993040385698, 8.2299555625650029],
					[66.139930402970975, 8.2299555648073532],
					[66.139930402096027, 8.2299555692648276],
					[66.139930402107041, 8.2299555714799517],
					[66.139877602668577, 8.230333261288953],
					[66.139785905660659, 8.2306971503841808],
					[66.13978590479671, 8.2306971570567367],
					[66.139785902182737, 8.23069717264414],
					[66.139785901318746, 8.2306971793166745],
					[66.139708101431978, 8.2314443790144942],
					[66.139708100557002, 8.231444383471878],
					[66.139708099714994, 8.2314443945745985],
					[66.139708099737007, 8.2314443990048289],
				],
			],
		},
		{
			fldNpdidField: "29446221",
			wlbNpdidWellbore: "7265",
			fldName: "BAUGE",
			fldCurrentActivitySatus: "Approved for production",
			wlbName: "6407/8-6",
			fldDiscoveryYear: "2013",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=29446221",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=29446221",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "348",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=7265",
			fldHcType: "OIL",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "2987047",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Kristiansund",
			fldAreaGeometryWKT: [
				[
					[64.333413702361909, 7.4468416894858303],
					[64.333413704681988, 7.4468417390240962],
					[64.333413710305067, 7.4468417614721814],
					[64.333708106566206, 7.4477444549145533],
					[64.33430540643883, 7.4502083477923549],
					[64.334305426610115, 7.4502083880501955],
					[64.334305484783187, 7.4502084572152354],
					[64.334305521866071, 7.4502084841044658],
					[64.335677618612323, 7.4507890402112222],
					[64.335985901560022, 7.4528750292827146],
					[64.335985903419513, 7.452875035387506],
					[64.335985906263332, 7.452875049715967],
					[64.335985908144664, 7.4528750578892806],
					[64.336635905755145, 7.4550722471892836],
					[64.336997004199461, 7.4568917390868581],
					[64.336997006080566, 7.4568917472607472],
					[64.336997009842804, 7.4568917636085228],
					[64.336997012620898, 7.4568917717321641],
					[64.337874800089708, 7.4592083354508887],
					[64.337846999838277, 7.4618388972751228],
					[64.337847001849369, 7.461838917861523],
					[64.337847009416123, 7.4618389546967459],
					[64.337847014096496, 7.4618389730642685],
					[64.338608110368071, 7.4637667621104642],
					[64.339199806650015, 7.4659694521150071],
					[64.339199807590319, 7.4659694562026004],
					[64.339199811264933, 7.4659694642778867],
					[64.339199812205223, 7.4659694683654632],
					[64.339905411970179, 7.4679028696578635],
					[64.339905412888783, 7.4679028716768157],
					[64.339905414726033, 7.4679028757146844],
					[64.339905415644651, 7.4679028777336178],
					[64.340663716202911, 7.4697000772837683],
					[64.340663738995062, 7.469700111209109],
					[64.340663797926425, 7.4697001679681252],
					[64.340663833146991, 7.4697001887827543],
					[64.342894332688715, 7.4704973877790968],
					[64.342894333607319, 7.470497389798334],
					[64.342894337195304, 7.4704973895991396],
					[64.342894338092322, 7.4704973895493403],
					[64.345297013832166, 7.4712834501962702],
					[64.345677600935247, 7.4750750201216363],
					[64.345677603776906, 7.475075034457463],
					[64.345677611275804, 7.4750750650990145],
					[64.345677615911455, 7.4750750793355287],
					[64.346672015660317, 7.4773861781012823],
					[64.346672061047698, 7.4773862273554057],
					[64.346672171169672, 7.4773862875248955],
					[64.346672235882707, 7.4773862963709172],
					[64.34871920187706, 7.4770113391022521],
					[64.348766499579099, 7.4816083026725479],
					[64.348766499600529, 7.4816083047420356],
					[64.348766499643389, 7.4816083088810332],
					[64.348766499664833, 7.4816083109505218],
					[64.348910899553928, 7.4843639102427728],
					[64.348910900557925, 7.4843639205409449],
					[64.348910904338496, 7.4843639389690608],
					[64.34891090621808, 7.4843639471483501],
					[64.349622005672828, 7.4872167471717326],
					[64.34962204396632, 7.4872168051197168],
					[64.34962214616074, 7.4872168802652084],
					[64.349622210980002, 7.487216899483002],
					[64.350874971090946, 7.4871474029847107],
					[64.351849831565573, 7.487489052550754],
					[64.352622006222106, 7.4906028483463425],
					[64.352622006222106, 7.4906028483463425],
					[64.352622007140411, 7.4906028503669653],
					[64.353035905870925, 7.4921583488036694],
					[64.353513705092539, 7.4942361458636455],
					[64.353513706010801, 7.4942361478844539],
					[64.353513706950309, 7.4942361519751213],
					[64.353513706950309, 7.4942361519751213],
					[64.354047006794787, 7.496180652988083],
					[64.354047007713035, 7.4961806550089953],
					[64.354047008652486, 7.4961806590998279],
					[64.354047009570735, 7.4961806611207393],
					[64.354635910296267, 7.4979889611245421],
					[64.354635918581394, 7.4979889813836706],
					[64.354635942327704, 7.4979890215104925],
					[64.354635955973691, 7.497989039406078],
					[64.355960955939949, 7.4993640395558732],
					[64.355961013666303, 7.4993640674778028],
					[64.355961136810805, 7.4993640856207984],
					[64.355961201310748, 7.499364073820705],
					[64.357205701271795, 7.4986362727348936],
					[64.357205703044613, 7.4986362705669265],
					[64.357205708405445, 7.4986362682033034],
					[64.357205710178292, 7.4986362660353354],
					[64.358283409726369, 7.497922366531391],
					[64.358283415963029, 7.4979223620483548],
					[64.358283425766501, 7.4979223552993597],
					[64.358283431127333, 7.4979223529355012],
					[64.359750130851239, 7.4966584512415855],
					[64.359750137963616, 7.4966584446387285],
					[64.359750153042995, 7.4966584272433288],
					[64.359750160155372, 7.4966584206404718],
					[64.360989059902835, 7.4950112210090971],
					[64.360989068724194, 7.4950112060257297],
					[64.360989086324395, 7.4950111719181374],
					[64.360989092433456, 7.4950111550114746],
					[64.36148349181974, 7.4932750567060298],
					[64.362033491853012, 7.4914028554686682],
					[64.362033492707425, 7.4914028512785169],
					[64.362033495313227, 7.4914028428490367],
					[64.362033496167655, 7.4914028386588836],
					[64.362466895806492, 7.4891417374260856],
					[64.362466898412194, 7.4891417289962767],
					[64.36246689999291, 7.48914170819251],
					[64.362466899886257, 7.4891416978398606],
					[64.36245019987129, 7.4874332988121015],
					[64.362450198846133, 7.487433286438236],
					[64.362450196838594, 7.4874332658315499],
					[64.362450194937836, 7.4874332555775149],
					[64.361791895760931, 7.4845749607037693],
					[64.361247397172747, 7.4815777640079739],
					[64.361247393456125, 7.4815777517832878],
					[64.361247385104448, 7.4815777253130076],
					[64.361247379593834, 7.4815777131872965],
					[64.36025848193492, 7.4795277178502486],
					[64.359614089610446, 7.4779860370060636],
					[64.359080794308383, 7.4759110509023614],
					[64.359080790634323, 7.47591104281996],
					[64.359080785058694, 7.4759110244855442],
					[64.359080782281623, 7.4759110163534652],
					[64.358380918927296, 7.4743724192769614],
					[64.359522128090944, 7.4744390960717597],
					[64.360497078846478, 7.4751751600521503],
					[64.36049712480866, 7.4751751782218738],
					[64.360497219208838, 7.4751751937085746],
					[64.360497269419227, 7.4751751888558227],
					[64.361350029175455, 7.474864101757495],
					[64.362433211216938, 7.474936294222208],
					[64.36329705835405, 7.4758084409028731],
					[64.363297135840241, 7.4758084697604241],
					[64.363297288308516, 7.4758084592457621],
					[64.363297362372066, 7.4758084178526234],
					[64.364197361778565, 7.4745751175344397],
					[64.364197374099618, 7.4745750940613549],
					[64.364197393338259, 7.474575045342819],
					[64.364197399337286, 7.4745750180764388],
					[64.364402999146606, 7.4724583199884842],
					[64.364402999914205, 7.4724583075147493],
					[64.364402998758408, 7.4724582827166275],
					[64.364402997732043, 7.4724582703424574],
					[64.364155798114652, 7.4707610701165397],
					[64.364155798114652, 7.4707610701165397],
					[64.364155797174504, 7.4707610660251058],
					[64.364155797174504, 7.4707610660251058],
					[64.363611296976927, 7.4676332655698063],
					[64.363611296036666, 7.4676332614785617],
					[64.3636112941345, 7.4676332512254628],
					[64.363611292275621, 7.4676332451135643],
					[64.362366892760861, 7.463230548810766],
					[64.361833495076368, 7.4610249555814701],
					[64.361233495196899, 7.4582943574470333],
					[64.361233493315851, 7.4582943492658735],
					[64.36123348775979, 7.4582943330040488],
					[64.361233484981753, 7.4582943248731297],
					[64.360122385044122, 7.4555943239228304],
					[64.360122383206544, 7.4555943198828407],
					[64.360122380428351, 7.4555943117525203],
					[64.360122377693784, 7.455594307762853],
					[64.358608478446797, 7.4526555089558411],
					[64.35860847571216, 7.4526555049666827],
					[64.358608472058691, 7.4526554989577214],
					[64.358608470221014, 7.4526554949181492],
					[64.357972396305499, 7.4516388360339425],
					[64.357766900438989, 7.448494387292949],
					[64.3577668976166, 7.4484943750237766],
					[64.357766893787726, 7.4484943524544471],
					[64.357766890965337, 7.4484943401852748],
					[64.357119590852093, 7.4464249401735625],
					[64.35711959083018, 7.4464249381034602],
					[64.356175190601235, 7.4434527397919599],
					[64.356175185065524, 7.443452725606134],
					[64.356175173141025, 7.4434527014252101],
					[64.356175165833278, 7.4434526894108126],
					[64.353975166424448, 7.4401581884939061],
					[64.35397515279351, 7.4401581726973189],
					[64.353975123759696, 7.4401581432756467],
					[64.353975108378833, 7.4401581317203815],
					[64.352655739236795, 7.4393137514293564],
					[64.350977960643576, 7.4370415806060839],
					[64.350977947034337, 7.437041566882356],
					[64.35097791530896, 7.4370415376198551],
					[64.350977898133905, 7.4370415261693603],
					[64.34948622879665, 7.4362054425596416],
					[64.347872352168935, 7.4343248699140663],
					[64.347872343985571, 7.4343248600260559],
					[64.347872326788206, 7.4343248465091483],
					[64.347872317729951, 7.4343248387415217],
					[64.346033430696849, 7.4329831477771933],
					[64.344419558368841, 7.4313637755540842],
					[64.343725171343763, 7.4302220959671015],
					[64.342736277396909, 7.428569306275012],
					[64.341452983637041, 7.4257499212340585],
					[64.340575185684386, 7.4235666249980801],
					[64.340575180168997, 7.4235666128930848],
					[64.34057516557256, 7.4235665909571935],
					[64.340575159160224, 7.4235665789035155],
					[64.339419559142186, 7.4220554786502504],
					[64.339419462697734, 7.4220554407031942],
					[64.33941928800958, 7.4220554714071953],
					[64.33941920974361, 7.4220555379895048],
					[64.33820541302434, 7.4258055291117762],
					[64.337313716839077, 7.4278277197938181],
					[64.33653591721874, 7.4295805187694413],
					[64.336535916343948, 7.429580520889095],
					[64.336535913697318, 7.4295805251794853],
					[64.336535913719473, 7.4295805272480049],
					[64.33564701441567, 7.4318638262402414],
					[64.335647010938402, 7.4318638367866967],
					[64.335647005733591, 7.4318638536406185],
					[64.335647004028147, 7.4318638620165389],
					[64.335108106876675, 7.4346555450759908],
					[64.334330414153598, 7.4366693269664852],
					[64.333830415170411, 7.4378805248600965],
					[64.333830413420543, 7.4378805290984253],
					[64.333830409942834, 7.4378805396434311],
					[64.333830408192952, 7.4378805438817599],
					[64.333555407945539, 7.4388166446000499],
					[64.333555405408802, 7.4388166592306346],
					[64.333555401232402, 7.4388166884409923],
					[64.333555399614696, 7.4388167050890903],
					[64.333558100005149, 7.4389278053193157],
					[64.333558102916413, 7.4389278258499996],
					[64.333558113245957, 7.4389278687256404],
					[64.333558120642223, 7.4389278890022936],
					[64.334138705338916, 7.4400972556640825],
					[64.334333098128511, 7.4420582870665219],
					[64.334005405093606, 7.4435221553847981],
					[64.334005404218601, 7.4435221575037964],
					[64.334005404284511, 7.4435221637088489],
					[64.334005403409478, 7.443522165827849],
					[64.333413702995813, 7.4468416646156133],
					[64.333413702361909, 7.4468416894858303],
				],
			],
		},
		{
			fldNpdidField: "40867462",
			wlbNpdidWellbore: "243",
			fldName: "TOMMELITEN A",
			fldCurrentActivitySatus: "Approved for production",
			wlbName: "1/9-1",
			fldDiscoveryYear: "1977",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=40867462",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=40867462",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "TOMMELITEN A UNIT",
			cmpLongName: "ConocoPhillips Skandinavia AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=243",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "2410696",
			fldNpdidOwner: "40915772",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-07 00:00:00",
			fldMainSupplyBase: "Tananger",
			fldAreaGeometryWKT: [
				[
					[56.394119200215783, 2.9088082864455318],
					[56.394119201009758, 2.9088083025523757],
					[56.394119203570419, 2.9088083362335966],
					[56.39411920704439, 2.9088083519109729],
					[56.39617200701322, 2.9165028516405211],
					[56.39617201324554, 2.9165028685013374],
					[56.396172030256167, 2.9165029031188316],
					[56.396172039168498, 2.9165029195506871],
					[56.39932763939737, 2.920747319540749],
					[56.399327655535195, 2.9207473364426573],
					[56.399327693091642, 2.9207473677781151],
					[56.399327712565103, 2.920747379275594],
					[56.402591612873024, 2.9223307803582612],
					[56.402591634867946, 2.9223307882066067],
					[56.402591681300144, 2.9223307986413993],
					[56.402591704764831, 2.9223307997596537],
					[56.403038905071902, 2.9223196001843394],
					[56.403038972094691, 2.922319571602785],
					[56.403039069319, 2.9223194797347536],
					[56.403039078009023, 2.9223193451953424],
					[56.40303899353151, 2.9223192385479022],
					[56.40303893235221, 2.9223192028676244],
					[56.403022864359308, 2.9223166044094495],
					[56.410522285967517, 2.9187362796191882],
					[56.410522302623832, 2.9187362704563338],
					[56.410522333911928, 2.9187362475824377],
					[56.410522347650385, 2.918736234014403],
					[56.415547347786699, 2.9131779342404749],
					[56.41554735876511, 2.9131779194864422],
					[56.415547377148584, 2.9131778905510766],
					[56.415547385367631, 2.9131778746149726],
					[56.418580785148237, 2.9057278752162565],
					[56.418580789872465, 2.9057278614624162],
					[56.418580795827104, 2.9057278361399019],
					[56.418580798764687, 2.9057278226728012],
					[56.419577999419786, 2.8969722226573102],
					[56.419577998862756, 2.8969722113752443],
					[56.419577998562438, 2.8969721870558049],
					[56.419577998005423, 2.8969721757737377],
					[56.418547398263676, 2.8886721755341132],
					[56.418547395839646, 2.8886721629283798],
					[56.418547390177999, 2.8886721394723338],
					[56.418547385967386, 2.8886721271541345],
					[56.415989085746574, 2.8821277264933842],
					[56.415989081694867, 2.8821277173998183],
					[56.415989070752126, 2.8821276964213096],
					[56.415989064913887, 2.8821276876155371],
					[56.410375165056202, 2.8739026872705327],
					[56.410375145992944, 2.8739026659827629],
					[56.410375101853127, 2.873902629249466],
					[56.410375076856468, 2.8739026154151941],
					[56.407022277136029, 2.872494216456186],
					[56.407022228994315, 2.872494207981009],
					[56.407022134882212, 2.872494216664518],
					[56.407022088911837, 2.8724942338231858],
					[56.401735989072975, 2.8760165346383393],
					[56.401735968352973, 2.8760165525916643],
					[56.401735930885216, 2.8760165959769779],
					[56.401735915924007, 2.8760166211210469],
					[56.399119215976199, 2.8820638214105467],
					[56.399119214428893, 2.8820638265306155],
					[56.399119210440986, 2.8820638369145501],
					[56.39911920889368, 2.8820638420346025],
					[56.396566509284263, 2.8904805421922162],
					[56.396566506843293, 2.8904805474552164],
					[56.396566504561584, 2.890480555939678],
					[56.396566503907209, 2.8904805609154631],
					[56.394694203932708, 2.8999582617442257],
					[56.39469420335746, 2.8999582683303209],
					[56.394694201313627, 2.89995828164587],
					[56.394694199765532, 2.8999582867646563],
					[56.394119200215783, 2.9088082864455318],
				],
			],
		},
		{
			fldNpdidField: "22507971",
			wlbNpdidWellbore: "1920",
			fldName: "SVALIN",
			fldCurrentActivitySatus: "Producing",
			wlbName: "25/11-16",
			fldDiscoveryYear: "1992",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=22507971",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=22507971",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "169",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=1920",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "22164",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Dusavik",
			fldAreaGeometryWKT: [
				[
					[59.136210914409887, 2.3966528442056427],
					[59.136210928128321, 2.396652901152867],
					[59.137122028023974, 2.3981973028097436],
					[59.137122056557949, 2.3981973323530954],
					[59.137122125671482, 2.3981973803462582],
					[59.13712216616242, 2.3981973970601826],
					[59.138680483913859, 2.3984640837993982],
					[59.139083117866619, 2.3990417882467345],
					[59.139488701567814, 2.4004028355938631],
					[59.139594201300064, 2.4018639133154034],
					[59.139594200850894, 2.4018639221677085],
					[59.139594204324247, 2.4018639372744612],
					[59.139594204767121, 2.4018639459543771],
					[59.140008104839964, 2.4036139457928831],
					[59.140008150308908, 2.4036140071337937],
					[59.140008270343586, 2.4036140821197236],
					[59.14000834580137, 2.4036140955923968],
					[59.141811001254062, 2.4031919297178703],
					[59.142313726649846, 2.404061200629787],
					[59.142313778369648, 2.4040612432352559],
					[59.142313896898834, 2.404061288713037],
					[59.142313963619657, 2.4040612898492282],
					[59.143219464141168, 2.4037529883544839],
					[59.143219479217649, 2.4037529836883573],
					[59.14321950812429, 2.4037529675837517],
					[59.143219522131567, 2.4037529596176173],
					[59.143866652672536, 2.4032558879431241],
					[59.145230417624312, 2.4052806883120796],
					[59.14578870006811, 2.4070278320084051],
					[59.145797000207835, 2.4082972012796495],
					[59.145796999581243, 2.4082972066608739],
					[59.145797001004297, 2.4082972169065568],
					[59.145797001269784, 2.4082972221155132],
					[59.146058100612223, 2.4106361197220276],
					[59.146163700950773, 2.4119028178162818],
					[59.146163701039214, 2.4119028195526315],
					[59.146163701304552, 2.4119028247616852],
					[59.146163702285087, 2.4119028263258921],
					[59.146319202148405, 2.4130722258911521],
					[59.146319210876811, 2.413072255768693],
					[59.146319238057998, 2.413072311894001],
					[59.146319254726649, 2.4130723384860358],
					[59.146872055236678, 2.4136501372723838],
					[59.146872075923461, 2.4136501543225415],
					[59.146872120600079, 2.4136501825252124],
					[59.146872146374044, 2.4136501933334702],
					[59.147524946459768, 2.4138334933215781],
					[59.147524967331229, 2.4138334963086039],
					[59.147525008897304, 2.4138334988097898],
					[59.147525029591911, 2.4138334983239655],
					[59.148227830129819, 2.4137251974614693],
					[59.148227848863414, 2.41372519384685],
					[59.148227885976951, 2.4137251796717627],
					[59.148227902661105, 2.4137251711919929],
					[59.149230641802454, 2.4131196071772152],
					[59.14983048780951, 2.4132084858011185],
					[59.150538721196661, 2.4141722946473756],
					[59.151047004308637, 2.4156278446510417],
					[59.151202601480385, 2.4170917217741126],
					[59.151202601922307, 2.4170917304572468],
					[59.151202606374468, 2.4170917471352875],
					[59.151202607708463, 2.4170917556463669],
					[59.151508107954534, 2.4181611557520553],
					[59.151508114190733, 2.4181611720863248],
					[59.151508131211934, 2.4181612056313559],
					[59.151508140124378, 2.4181612214495325],
					[59.152163700617308, 2.4190278675006978],
					[59.152169200020339, 2.419613901522867],
					[59.152169207228226, 2.4196139369612562],
					[59.152169232172334, 2.4196140023006718],
					[59.152169250888996, 2.4196140337664018],
					[59.153027650688948, 2.4205751337335126],
					[59.153027709902723, 2.4205751644083904],
					[59.153027836199804, 2.4205751856583011],
					[59.153027902125984, 2.4205751711951131],
					[59.153872301813294, 2.4200723722010973],
					[59.15387231394913, 2.4200723628460339],
					[59.153872337328714, 2.420072344307914],
					[59.153872347680377, 2.4200723352968438],
					[59.154322348415945, 2.4195779346108841],
					[59.154322356806695, 2.4195779224699692],
					[59.15432237439191, 2.4195778962793648],
					[59.154322381802217, 2.4195778825736882],
					[59.154819552851663, 2.4184945467233114],
					[59.155022097938904, 2.4183919764547128],
					[59.155627614536641, 2.4198472762888423],
					[59.155627618458396, 2.4198472825482238],
					[59.155627623448936, 2.4198472921093068],
					[59.155627624694418, 2.4198472988847222],
					[59.156491525210058, 2.4213944984087798],
					[59.156491546249313, 2.4213945224157283],
					[59.156491593592023, 2.421394567660867],
					[59.15649162230676, 2.4213945831723573],
					[59.158047099741836, 2.4220529759181653],
					[59.15895545333322, 2.4227223403973883],
					[59.159660933134745, 2.4237834110353993],
					[59.15966095783029, 2.4237834360952775],
					[59.159661014897011, 2.4237834777192395],
					[59.159661047179895, 2.4237834925462498],
					[59.160663847290515, 2.4240612930694478],
					[59.160663912766523, 2.4240612874663237],
					[59.160664027414178, 2.424061239057099],
					[59.160664075605439, 2.4240611946858142],
					[59.161511275780668, 2.422483395184261],
					[59.16151128292627, 2.4224833762646911],
					[59.161511294717862, 2.4224833424158612],
					[59.161511299098883, 2.4224833222750877],
					[59.161800198871767, 2.4200361221729181],
					[59.161800198783425, 2.4200361204357459],
					[59.161800200125818, 2.4200361114057687],
					[59.161800200037462, 2.4200361096685983],
					[59.161891898900492, 2.4178861156208153],
					[59.162180796731583, 2.4154389366600264],
					[59.162772393608812, 2.4130833482721452],
					[59.162772395842808, 2.4130833390694248],
					[59.162772399241803, 2.4130833173618371],
					[59.162772399691633, 2.4130833085035994],
					[59.162819599819294, 2.4119111075704045],
					[59.162819599730831, 2.411911105833211],
					[59.162819600357501, 2.4119111004493399],
					[59.162819600269046, 2.4119110987121446],
					[59.162808500152472, 2.410055598139865],
					[59.162808499975505, 2.41005559466549],
					[59.162808499621548, 2.4100555877167262],
					[59.16280849944458, 2.4100555842423481],
					[59.162705816747227, 2.4087862977120755],
					[59.162952720601041, 2.4087835005046569],
					[59.163355413067904, 2.409166798220689],
					[59.16351370294624, 2.4101417316760223],
					[59.163513724334301, 2.4101417801740546],
					[59.163513787723659, 2.4101418573993225],
					[59.163513829813454, 2.4101418878638019],
					[59.164013829911951, 2.4103279867807923],
					[59.164013906357155, 2.4103279842942853],
					[59.164014034917891, 2.4103279261276573],
					[59.164014087033429, 2.4103278704475208],
					[59.164608481912786, 2.4087556824076954],
					[59.165355775653623, 2.407375094589336],
					[59.165355782258963, 2.4073750827862219],
					[59.165355791724359, 2.4073750563949883],
					[59.16535579636853, 2.4073750414620751],
					[59.165797396237629, 2.4053139419692773],
					[59.165797374772247, 2.4053138566424868],
					[59.165797274913757, 2.4053137355926917],
					[59.165797195628606, 2.4053137000421305],
					[59.165194490140486, 2.4053247971044742],
					[59.164439071386319, 2.4044581909429907],
					[59.163883482846707, 2.4031999193929634],
					[59.163883456723852, 2.4031998840945357],
					[59.163883392609407, 2.4031998280732934],
					[59.163883354617873, 2.4031998073504783],
					[59.16322785827316, 2.403013708151629],
					[59.162372430195894, 2.4027359634695578],
					[59.163108491791519, 2.3989139613229944],
					[59.163952982240346, 2.3970444816654575],
					[59.163952989382132, 2.3970444627394292],
					[59.163952998047399, 2.3970444207117803],
					[59.163952999659571, 2.397044399347354],
					[59.163947400010635, 2.3955778231698339],
					[59.164391888897455, 2.3937139696108587],
					[59.165133479174528, 2.3922361899872016],
					[59.165133483191077, 2.3922361804369716],
					[59.165133490332117, 2.3922361615093166],
					[59.165133493367868, 2.3922361503946465],
					[59.165577992904048, 2.3905667503616215],
					[59.165577995766682, 2.3905667182266748],
					[59.165577991590858, 2.390566654120811],
					[59.165577983660363, 2.3905666223227406],
					[59.165375184099602, 2.3900832235169478],
					[59.165375172152615, 2.3900832012697206],
					[59.165375142191955, 2.3900831614597968],
					[59.165375124089493, 2.3900831421598236],
					[59.164772324069091, 2.3896054421935311],
					[59.164772298115814, 2.3896054279243684],
					[59.164772243888279, 2.389605406853494],
					[59.164772214721928, 2.3896054002246729],
					[59.163666733279875, 2.3895248012704617],
					[59.162613967877618, 2.3892526102267078],
					[59.162011185229481, 2.3889692184018907],
					[59.162011117350758, 2.3889692122587967],
					[59.162010992018367, 2.3889692453178681],
					[59.16201093634875, 2.388969284174328],
					[59.160763736226357, 2.3907498851485558],
					[59.160763724625312, 2.3907499049380649],
					[59.160763707845, 2.3907499467814537],
					[59.160763701773675, 2.3907499690081431],
					[59.160324806306541, 2.3935916477525083],
					[59.159430450747415, 2.3958526326242002],
					[59.158280665758582, 2.3961664754257388],
					[59.157677988632685, 2.3954943107748341],
					[59.157261297603121, 2.3927666693891343],
					[59.157261297248269, 2.3927666624417365],
					[59.157261293951159, 2.3927666508019976],
					[59.157261291812233, 2.3927666442000604],
					[59.156700191592826, 2.3908221444474065],
					[59.156700180360616, 2.3908221185589595],
					[59.156700149956627, 2.390822070073896],
					[59.156700130873595, 2.3908220492140764],
					[59.156147331065043, 2.3903442481836867],
					[59.156147305023147, 2.3903442321803325],
					[59.156147250707036, 2.3903442093758827],
					[59.156147222344032, 2.3903442008380278],
					[59.155394479311234, 2.3902609082481376],
					[59.154789057629145, 2.3897831692934037],
					[59.154183476701803, 2.3886221080018952],
					[59.154183444153581, 2.3886220704607757],
					[59.154183363085011, 2.3886220177655604],
					[59.154183316259967, 2.3886220005291534],
					[59.15217511676579, 2.3884609096312368],
					[59.15121409105825, 2.386916628576488],
					[59.15080299716697, 2.3843860681179834],
					[59.150802997078152, 2.3843860663814938],
					[59.150802996989341, 2.3843860646449877],
					[59.150802996900502, 2.3843860629085012],
					[59.150494596602407, 2.3827305637841776],
					[59.150494586083028, 2.3827305342571843],
					[59.150494557193795, 2.3827304802326337],
					[59.150494537843073, 2.382730454171575],
					[59.150191838070882, 2.38244155522856],
					[59.150191828617587, 2.3824415465393605],
					[59.150191808996716, 2.3824415328068116],
					[59.150191797848251, 2.3824415261999654],
					[59.149688998301507, 2.3821581264323624],
					[59.149688925696047, 2.3821581159588572],
					[59.14968879483574, 2.38215814659269],
					[59.149688734707986, 2.3821581863094958],
					[59.148341534972012, 2.3841359866736722],
					[59.148341528368768, 2.3841359984750272],
					[59.148341516143084, 2.3841360236412146],
					[59.148341509628651, 2.3841360371789571],
					[59.147749815972844, 2.385905519662765],
					[59.147002640397204, 2.3872831753398871],
					[59.146452720654572, 2.3877831005335435],
					[59.145752796907551, 2.3877942001697301],
					[59.145752785222392, 2.3877942006796573],
					[59.145752761048819, 2.3877942036085389],
					[59.145752748649144, 2.3877942077637395],
					[59.144949949308206, 2.3880054060848566],
					[59.144949911572247, 2.3880054256674983],
					[59.144949848406881, 2.3880054764763972],
					[59.144949822174212, 2.388005509611641],
					[59.144352631879542, 2.3891859887078217],
					[59.14385549057144, 2.389780418400572],
					[59.143352805301134, 2.3898886938271691],
					[59.143002992270056, 2.3897970439718383],
					[59.142944600044586, 2.3886250049151436],
					[59.143139098962571, 2.3866694207920718],
					[59.143139034140233, 2.3866693158769854],
					[59.143138852369177, 2.3866692493956876],
					[59.143138736223719, 2.3866692859205845],
					[59.140397035639985, 2.3906248858029922],
					[59.140397033944652, 2.3906248878843357],
					[59.140397032338036, 2.3906248917016462],
					[59.140397030642674, 2.390624893782979],
					[59.138355436249149, 2.3938804853289484],
					[59.136210941936625, 2.3966526776129191],
					[59.13621092131406, 2.3966527324356766],
					[59.136210914409887, 2.3966528442056427],
				],
				[
					[59.108833100308885, 2.3799472113957467],
					[59.108916499944712, 2.3814139094761968],
					[59.108994200149205, 2.3831750092744715],
					[59.108994203979663, 2.3831750313033764],
					[59.108994215119885, 2.3831750729361749],
					[59.108994223410484, 2.3831750941018073],
					[59.109158087831453, 2.3834833276139316],
					[59.108988705164215, 2.384197154419966],
					[59.108988702839078, 2.3841971793917431],
					[59.108988703718566, 2.3841972317682676],
					[59.108988707726432, 2.3841972572660395],
					[59.109255404074986, 2.3850972445104461],
					[59.109383102001459, 2.385938929714738],
					[59.109383106368426, 2.3859389446340229],
					[59.109383116083187, 2.3859389760344327],
					[59.109383122234227, 2.3859389906085511],
					[59.109641522336609, 2.3864500904395189],
					[59.109641551138978, 2.3864501251540005],
					[59.109641622306164, 2.3864501779464793],
					[59.109641663778966, 2.3864501961970461],
					[59.110152655440928, 2.3865445771730225],
					[59.11047759910997, 2.3873528354119808],
					[59.110455400721818, 2.3882277615922671],
					[59.110316512349641, 2.3886055311218888],
					[59.110316515272878, 2.3886056058761334],
					[59.11031657134432, 2.3886057334098059],
					[59.110316625295859, 2.3886057842822748],
					[59.111244325416678, 2.3889834845640463],
					[59.111244329162261, 2.3889834873429932],
					[59.111244335583969, 2.3889834896043873],
					[59.111244340132892, 2.3889834904763285],
					[59.112366552235741, 2.3893334632269903],
					[59.113105415908606, 2.3910583795550471],
					[59.113105446671334, 2.3910584174010743],
					[59.113105522740561, 2.3910584780187754],
					[59.113105567869702, 2.3910584973212554],
					[59.113816667820394, 2.391172396908873],
					[59.113816727764494, 2.3911723888222367],
					[59.113816832320815, 2.3911723370739377],
					[59.11381687612969, 2.3911722953193979],
					[59.114466823020187, 2.3899668942333485],
					[59.115483283265966, 2.3899141004791304],
					[59.116213856074658, 2.3900779951774123],
					[59.116213864280439, 2.3900779970942372],
					[59.116213880603254, 2.3900779991931471],
					[59.116213888720317, 2.3900779993752157],
					[59.117366689135821, 2.3901390997988803],
					[59.117366699929001, 2.390139099463366],
					[59.117366720623316, 2.3901390989648656],
					[59.117366732219807, 2.3901390967220077],
					[59.118136074678652, 2.3900141072767194],
					[59.118722122031876, 2.390264083906406],
					[59.118722135767314, 2.3902640882579615],
					[59.118722165914292, 2.3902640964434752],
					[59.1187221822371, 2.390264098542533],
					[59.119113882030916, 2.3903001994389488],
					[59.11911395205837, 2.3903001771341166],
					[59.119114060369533, 2.3903000931104654],
					[59.119114097761184, 2.3903000315642013],
					[59.119233458921236, 2.3895335765945203],
					[59.119552615339934, 2.3896584411505417],
					[59.119633101233248, 2.3903500227254866],
					[59.11963311602829, 2.3903500654182932],
					[59.119633161142829, 2.3903501372886136],
					[59.119633192443047, 2.3903501680285126],
					[59.120258163763388, 2.390750150642075],
					[59.12075814288459, 2.3913807243775183],
					[59.120758168388875, 2.3913807474794604],
					[59.120758224217468, 2.3913807822382407],
					[59.120758253738451, 2.3913807958026059],
					[59.121447154049349, 2.3915418948598837],
					[59.121447155833422, 2.3915418945148477],
					[59.121447160382324, 2.3915418953872818],
					[59.121447162255087, 2.391541896777289],
					[59.122402681358253, 2.3917279817737453],
					[59.123183129714882, 2.3928723056072938],
					[59.123772025735803, 2.3939000992312147],
					[59.123772086644749, 2.3939001452806257],
					[59.123772223732111, 2.3939001836104188],
					[59.123772299733226, 2.3939001724204929],
					[59.124644323672854, 2.3933974745450053],
					[59.125160925010853, 2.394322297788539],
					[59.125160930806445, 2.3943223054298532],
					[59.125160941594253, 2.3943223226201789],
					[59.125160948281874, 2.3943223300890231],
					[59.12561654803816, 2.3948529291946081],
					[59.125616576306705, 2.3948529535200822],
					[59.125616637398146, 2.3948529855189724],
					[59.125616671379078, 2.394852998225697],
					[59.126474888383783, 2.3949807843188089],
					[59.127277612533057, 2.396080682335938],
					[59.127541504385896, 2.397322241137271],
					[59.127541505455191, 2.3973222444355891],
					[59.127541505632443, 2.3973222479063039],
					[59.127541506701732, 2.3973222512046091],
					[59.127985907186435, 2.3990194505901652],
					[59.1279859204666, 2.3990194813217549],
					[59.127985956573681, 2.3990195356826831],
					[59.127985980292685, 2.3990195591396635],
					[59.128880464595944, 2.3996890486180438],
					[59.129860953213345, 2.4007529351040042],
					[59.12986095419398, 2.4007529366671552],
					[59.129860956155262, 2.4007529397934544],
					[59.129860957047313, 2.4007529396211171],
					[59.130649850789951, 2.4015584335400719],
					[59.131508136978383, 2.402614017594173],
					[59.131835931080275, 2.4031334068125161],
					[59.13183596335849, 2.4031334391378474],
					[59.131836038714674, 2.4031334859283833],
					[59.131836081704101, 2.4031334986580095],
					[59.132602747542492, 2.4032029953363527],
					[59.133402656454443, 2.4035723540989467],
					[59.134127623842765, 2.4049056955372077],
					[59.13412763356019, 2.4049057094361084],
					[59.134127653798529, 2.4049057353259209],
					[59.134127666192107, 2.4049057487080359],
					[59.135569242953913, 2.4062112277169567],
					[59.135994205242866, 2.4069333646418909],
					[59.136108100708014, 2.4080639199664908],
					[59.136108133774371, 2.4080639854533006],
					[59.136108234712076, 2.408064074650544],
					[59.13610837056472, 2.4080640712157546],
					[59.136108468543874, 2.4080639786812608],
					[59.136108500325861, 2.4080639129476213],
					[59.136197399285024, 2.4067083290958924],
					[59.136394594590485, 2.4058500444293078],
					[59.136394595142171, 2.4058500022524654],
					[59.136394579296386, 2.4058499211714865],
					[59.136394562006785, 2.4058498824396226],
					[59.136014125886838, 2.4053249716051393],
					[59.136305642167045, 2.4045726453816716],
					[59.136724829680446, 2.4052473066917019],
					[59.136724842162543, 2.4052473218107426],
					[59.136724867841771, 2.4052473484048873],
					[59.13672488291153, 2.405247361271277],
					[59.136874883056585, 2.4053557613640923],
					[59.136874978316712, 2.4053557745217371],
					[59.136875133814833, 2.4053557146934623],
					[59.136875195836986, 2.4053556413630104],
					[59.13721409587113, 2.403733340824227],
					[59.13721409587113, 2.403733340824227],
					[59.137214095694034, 2.4037333373524876],
					[59.137214097389588, 2.4037333352720016],
					[59.137477997248148, 2.4022944367612959],
					[59.137477997260881, 2.4022944016989918],
					[59.137477984708973, 2.4022943322514085],
					[59.137477973124959, 2.4022942994296397],
					[59.137122395843548, 2.4016777405981262],
					[59.137019599771079, 2.400205586357484],
					[59.137019592824011, 2.4002055561465983],
					[59.137019570097969, 2.4002054991846538],
					[59.137019555211012, 2.4002054722612138],
					[59.136544554815863, 2.3996248727629639],
					[59.136544546255784, 2.3996248638997297],
					[59.136544528243526, 2.3996248463456791],
					[59.136544518879958, 2.3996248393906812],
					[59.136014059740241, 2.3992331698717519],
					[59.135411283942993, 2.397911022870137],
					[59.135108485746322, 2.3971499266866791],
					[59.13510847772357, 2.3971499107089911],
					[59.135108459982639, 2.3971498808342755],
					[59.135108449372368, 2.3971498671097389],
					[59.134633461854783, 2.3966192808657261],
					[59.133950170805562, 2.3954831978386819],
					[59.133950170716901, 2.3954831961030085],
					[59.133950169736195, 2.3954831945398367],
					[59.133950169736195, 2.3954831945398367],
					[59.133339084161825, 2.3944999172346026],
					[59.132930797813053, 2.393147164448802],
					[59.13289740028474, 2.3925110900889535],
					[59.132897396721653, 2.3925110732505277],
					[59.132897389595506, 2.3925110395736753],
					[59.132897385140389, 2.392511022907823],
					[59.132600193396129, 2.391799942429278],
					[59.132469596735646, 2.3911055627922386],
					[59.132469583276148, 2.3911055285887746],
					[59.132469543065341, 2.3911054645065279],
					[59.132469517383456, 2.3911054379262944],
					[59.132008499931771, 2.3907720986317083],
					[59.132011299647431, 2.3899417036965596],
					[59.132022400014229, 2.3894583042142341],
					[59.13202239574089, 2.3894582734921119],
					[59.132022377293076, 2.3894582122114012],
					[59.132022363207348, 2.3894581833883781],
					[59.131572363124782, 2.3888276843818805],
					[59.131572360182453, 2.3888276796932635],
					[59.131572354386527, 2.3888276720515447],
					[59.131572350640916, 2.3888276692711004],
					[59.130927950930406, 2.3880887688476027],
					[59.130927949057593, 2.3880887674574356],
					[59.130927946115222, 2.3880887627689726],
					[59.130927944242409, 2.3880887613788233],
					[59.13030581000703, 2.3874443301255353],
					[59.130372398095389, 2.3869139252027654],
					[59.130372391952989, 2.3869138755650425],
					[59.1303723577225, 2.3869137875489557],
					[59.130372328653614, 2.3869137476078262],
					[59.129983461664978, 2.3865859744221463],
					[59.129544594158439, 2.3856693426646784],
					[59.129500197618185, 2.3853249742139475],
					[59.129500182910078, 2.3853249332468169],
					[59.129500136900504, 2.385324861536219],
					[59.129500105599028, 2.3853248307927357],
					[59.128947344624734, 2.3849804548826863],
					[59.128211269851612, 2.3837915954060005],
					[59.128211264947439, 2.3837915875931017],
					[59.128211255227917, 2.3837915737026627],
					[59.128211248539714, 2.3837915662352951],
					[59.127544548598792, 2.3830470667398016],
					[59.127544541910545, 2.3830470592727195],
					[59.127544528711667, 2.3830470478091872],
					[59.127544522023413, 2.3830470403421029],
					[59.126747321531546, 2.382435940626797],
					[59.12674731412887, 2.3824359368033394],
					[59.126747299234736, 2.3824359274211386],
					[59.126747291743243, 2.3824359218624029],
					[59.126289019433386, 2.382199836215285],
					[59.125691841304075, 2.3816081583828104],
					[59.12569183568543, 2.3816081542140224],
					[59.125691826232199, 2.381608145530806],
					[59.125691820613575, 2.3816081413620172],
					[59.12532796616491, 2.3813304746420689],
					[59.124775186974325, 2.37991382834515],
					[59.124408486691159, 2.3789555291548603],
					[59.124408463238751, 2.378955493395507],
					[59.124408403398071, 2.378955433145475],
					[59.124408366206595, 2.3789554105627886],
					[59.123650066050033, 2.3786887104601879],
					[59.123650057844131, 2.3786887085458144],
					[59.123650041432342, 2.3786887047170429],
					[59.123650032334439, 2.3786887029755359],
					[59.12298898561346, 2.378580411631638],
					[59.122530744655869, 2.3781970613562975],
					[59.121841858220066, 2.3773054774399225],
					[59.121841823437769, 2.3773054491368923],
					[59.121841743968979, 2.3773054102205258],
					[59.121841701066515, 2.3773053992614175],
					[59.12078062658528, 2.3773025997925532],
					[59.119980652817624, 2.377083106716634],
					[59.119980630161706, 2.3770831040991225],
					[59.119980584135646, 2.3770831025068024],
					[59.119980559873454, 2.3770831037048845],
					[59.118988859692195, 2.3772831043094178],
					[59.11898882793519, 2.3772831174722668],
					[59.118988770217733, 2.3772831514350035],
					[59.118988744435036, 2.3772831757046129],
					[59.118369278965531, 2.3780526302956635],
					[59.118050077426247, 2.3781691596417533],
					[59.117694565095398, 2.3776498866410982],
					[59.117694518183733, 2.3776498501791932],
					[59.117694411599096, 2.3776498095125471],
					[59.117694352907037, 2.3776498068697416],
					[59.116824973158693, 2.377860900454261],
					[59.115616706320601, 2.3778969993514436],
					[59.114852818585263, 2.377824800397859],
					[59.114852781834827, 2.3778248040145953],
					[59.114852711721042, 2.3778248246067957],
					[59.114852679249687, 2.3778248414094167],
					[59.113997129149709, 2.3784776035413562],
					[59.113633381047983, 2.378491497504208],
					[59.113269543744984, 2.3781165603799121],
					[59.113269476405975, 2.3781165296309204],
					[59.113269338327683, 2.3781165248488727],
					[59.11326926848043, 2.3781165506430075],
					[59.11281940521409, 2.378513629445461],
					[59.11236123736132, 2.3780804549842838],
					[59.112361210248629, 2.378080435712322],
					[59.112361150312402, 2.3780804087852006],
					[59.112361118380825, 2.3780804009572445],
					[59.111697218080579, 2.3780192010693404],
					[59.111697199170472, 2.3780192012292409],
					[59.111697160635991, 2.378019205190824],
					[59.111697142795649, 2.3780192086468994],
					[59.111325034254889, 2.3781303806467502],
					[59.110911211624277, 2.3778498334069633],
					[59.11091116123027, 2.3778498168943463],
					[59.110911055620093, 2.3778498128299597],
					[59.110911003079934, 2.3778498247597746],
					[59.11030820295597, 2.3781831242980513],
					[59.110308184756803, 2.3781831383331697],
					[59.110308152015385, 2.37818316744668],
					[59.11030813836517, 2.378183182352267],
					[59.109980494320368, 2.3786387045319644],
					[59.109633290228757, 2.3786553993158122],
					[59.109633289336763, 2.3786553994885802],
					[59.109633285768687, 2.3786554001796723],
					[59.109633284876658, 2.3786554003524474],
					[59.109044385068159, 2.3786998007716438],
					[59.109044327708943, 2.3786998241420716],
					[59.109044237960155, 2.3786999010781753],
					[59.10904420467859, 2.3786999548166015],
					[59.10887480462835, 2.3794138534181517],
					[59.10887480301848, 2.3794138747486362],
					[59.108874802385934, 2.3794139151570284],
					[59.108874802648941, 2.379413937876369],
					[59.10900529111273, 2.3801077361168028],
					[59.108833437244364, 2.3799470533178662],
					[59.108833325571275, 2.379947036408379],
					[59.108833158397125, 2.3799471143210464],
					[59.108833100308885, 2.3799472113957467],
				],
			],
		},
		{
			fldNpdidField: "31164879",
			wlbNpdidWellbore: "7322",
			fldName: "FENJA",
			fldCurrentActivitySatus: "Approved for production",
			wlbName: "6406/12-3 S",
			fldDiscoveryYear: "2014",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=31164879",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=31164879",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "586",
			cmpLongName: "Neptune Energy Norge AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=7322",
			fldHcType: "OIL/GAS",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "31123361",
			fldNpdidOwner: "20109200",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Kristiansund",
			fldAreaGeometryWKT: [
				[
					[64.01848589999355, 6.7389500027862894],
					[64.018502600097435, 6.7399861029304518],
					[64.018502600192861, 6.7399861090628557],
					[64.018502601248585, 6.7399861192110286],
					[64.018502602240673, 6.7399861252709332],
					[64.018630402335447, 6.7410111244506803],
					[64.018758100208231, 6.7420389141258772],
					[64.018771999786608, 6.7430722034609287],
					[64.018788699934831, 6.7441083025783266],
					[64.018788700030086, 6.7441083087108114],
					[64.018788701085498, 6.7441083188592508],
					[64.018788702077444, 6.744108324919365],
					[64.018916502365229, 6.745133325008327],
					[64.019044202304144, 6.7461611256848988],
					[64.019172002201245, 6.7471861231862293],
					[64.019297000891953, 6.7482111240594893],
					[64.01929700182032, 6.7482111260314355],
					[64.019424801708908, 6.749238924880288],
					[64.019552602021847, 6.7502639244584746],
					[64.019680401284674, 6.7512889248553787],
					[64.019805401309924, 6.7523167239793764],
					[64.019805402238248, 6.7523167259514931],
					[64.019933102382595, 6.7533417254620058],
					[64.020060901578745, 6.7543694256726843],
					[64.020060901641955, 6.7543694297612413],
					[64.020060903593347, 6.754369439838479],
					[64.020060904584852, 6.7543694458992212],
					[64.02029700464972, 6.7553861442464411],
					[64.020297004681311, 6.7553861462907472],
					[64.020535904797271, 6.7564028452917277],
					[64.020774805052937, 6.7574194465689832],
					[64.021013702910068, 6.7584361345431612],
					[64.02113870060181, 6.7594639236505394],
					[64.021138702426683, 6.7594639255510884],
					[64.021266501985352, 6.76048892587613],
					[64.021266503008135, 6.7604889339817387],
					[64.02126650783822, 6.76048895611045],
					[64.021266510685862, 6.7604889661166849],
					[64.021616511147215, 6.7614972643682449],
					[64.021963711071507, 6.7625056642651984],
					[64.021963714815669, 6.7625056742000833],
					[64.021963724128867, 6.7625056959706544],
					[64.021963729666396, 6.7625057057619227],
					[64.022419230278643, 6.7632473043275496],
					[64.02287762264298, 6.7639889941310809],
					[64.023222018288863, 6.7647361847443364],
					[64.023566513028797, 6.7654861715870389],
					[64.023799809414811, 6.7662444595365177],
					[64.02403590892213, 6.7670056596865704],
					[64.0240359108098, 6.7670056656769804],
					[64.024035916378438, 6.7670056775144429],
					[64.02403591826608, 6.7670056835048529],
					[64.024380417994138, 6.7677528836221992],
					[64.024724810051822, 6.7685028659070836],
					[64.02496370465937, 6.7695222459391138],
					[64.025199805343661, 6.7705389445849784],
					[64.025199805375038, 6.7705389466296468],
					[64.025438705188378, 6.7715556454690988],
					[64.025677604974064, 6.7725750451196989],
					[64.025677606861478, 6.7725750511108194],
					[64.025677608780256, 6.772575059146666],
					[64.025677610667671, 6.7725750651378078],
					[64.026024811241086, 6.7735833648860817],
					[64.026024811272435, 6.7735833669308132],
					[64.026374810698343, 6.774591765118763],
					[64.026374812554351, 6.7745917690654114],
					[64.026374816297704, 6.7745917790034893],
					[64.026374818153712, 6.7745917829501581],
					[64.026719218367745, 6.7753417831402309],
					[64.027063717724019, 6.7760917833792522],
					[64.027408117805479, 6.7768417830882743],
					[64.027408117805479, 6.7768417830882743],
					[64.027408118702198, 6.7768417830169074],
					[64.027755414378262, 6.7775917752602419],
					[64.028102610600783, 6.7786000647514832],
					[64.028102610632061, 6.7786000667963933],
					[64.028452610607488, 6.7796083647587855],
					[64.028799810960876, 6.7806167645681068],
					[64.028799810992126, 6.7806167666130692],
					[64.029149801286053, 6.7816250349210128],
					[64.029166500341773, 6.7826611034610123],
					[64.029180400074907, 6.7836971819796545],
					[64.028974804017338, 6.7847499610811983],
					[64.028769204418367, 6.785802761327262],
					[64.02876920188416, 6.7858027717652734],
					[64.028769200402493, 6.7858027923569697],
					[64.02876919966161, 6.7858028026528219],
					[64.028785900060043, 6.786838902710751],
					[64.028799800081075, 6.7878750021750749],
					[64.028799802123558, 6.7878750183927004],
					[64.028799807105216, 6.7878750507569556],
					[64.028799810941109, 6.7878750668325658],
					[64.02914981129895, 6.7888833649245148],
					[64.029497011129877, 6.7898917640110197],
					[64.02949702040786, 6.7898917837516137],
					[64.029497040695063, 6.7898918190008546],
					[64.029497054425406, 6.7898918363416723],
					[64.029949854238097, 6.7903751376372252],
					[64.030402654247439, 6.7908557372674938],
					[64.030402666987698, 6.7908557485450185],
					[64.030402695158287, 6.7908557708872923],
					[64.030402709660848, 6.7908557799778908],
					[64.030849909770495, 6.7910807787103131],
					[64.030849910667158, 6.7910807786393947],
					[64.031299911399628, 6.7913057799835475],
					[64.031747111137832, 6.7915279794109251],
					[64.032194309895999, 6.7917529788973505],
					[64.03219431079269, 6.7917529788264464],
					[64.032641611079725, 6.7919751781253312],
					[64.033091610888633, 6.792200179090794],
					[64.033538810648082, 6.792422379847662],
					[64.033985977716, 6.7926473628710333],
					[64.034438754345487, 6.7931307372906922],
					[64.034891540051717, 6.7936112225588445],
					[64.035347029633684, 6.7943529054549519],
					[64.03580262967408, 6.7950945051576106],
					[64.036258130171433, 6.7958362041340932],
					[64.036713713271581, 6.7965778774158858],
					[64.036952605364547, 6.7975972458327742],
					[64.037188700399, 6.7986139251154842],
					[64.037205399649054, 6.7996500038863186],
					[64.037222000240277, 6.8006861035116186],
					[64.037222001353669, 6.800686117760149],
					[64.037222006239674, 6.8006861439996875],
					[64.03722200914649, 6.8006861581069344],
					[64.037455409235946, 6.8014444587742888],
					[64.037688709024607, 6.8022056593189415],
					[64.037688728412135, 6.8022056946579958],
					[64.037688778689599, 6.80220575419039],
					[64.037688811372917, 6.802205778242544],
					[64.038136011264271, 6.8024279789579332],
					[64.038586010972026, 6.8026529790822234],
					[64.038586062300283, 6.8026529893794763],
					[64.038586161864529, 6.8026529835916714],
					[64.038586210966301, 6.8026529653903491],
					[64.039025110918942, 6.8023584655701042],
					[64.039466811097398, 6.8020640656307041],
					[64.039466836761207, 6.8020640410801407],
					[64.039466878070471, 6.8020639825267546],
					[64.0394668928502, 6.8020639506402949],
					[64.039675193101658, 6.801269451035183],
					[64.039886292779727, 6.8004778525174654],
					[64.039886296829707, 6.800477823523206],
					[64.039886295993654, 6.8004777682869522],
					[64.039886291076655, 6.8004777399991729],
					[64.039652990648278, 6.7997166412990522],
					[64.039419591402392, 6.7989582418714019],
					[64.039186290761847, 6.7981971414313325],
					[64.038950196238005, 6.7974388586251111],
					[64.038825198921117, 6.7964110767777148],
					[64.038825197993418, 6.7964110748027684],
					[64.038697397666652, 6.7953860750327291],
					[64.038569598683821, 6.7943582761008505],
					[64.03844460160559, 6.7933306067156085],
					[64.038650195837832, 6.7922778373558854],
					[64.038855793977845, 6.7912250475957512],
					[64.039175192435877, 6.7901639573538821],
					[64.039175192435877, 6.7901639573538821],
					[64.039491892248662, 6.7891000574276124],
					[64.039491893051988, 6.7891000512193838],
					[64.039491894720868, 6.789100042894388],
					[64.039491896451992, 6.7891000386608704],
					[64.039697395725241, 6.7880472389954685],
					[64.039902990405864, 6.7869944731280265],
					[64.040227972003024, 6.786450101983533],
					[64.040555748389252, 6.7859057400612359],
					[64.040994511135906, 6.785611265500326],
					[64.041436211120029, 6.7853168659868315],
					[64.041436229732156, 6.7853168501717906],
					[64.041436262410713, 6.7853168148056211],
					[64.041436277342569, 6.7853167931374818],
					[64.04186687664442, 6.7845056932438883],
					[64.042300177086943, 6.7836917949930413],
					[64.042730776807502, 6.7828806932529844],
					[64.043164075781419, 6.7820694950066471],
					[64.043164081005415, 6.7820694843491527],
					[64.043164089628775, 6.7820694611306598],
					[64.043164093059374, 6.7820694506156602],
					[64.043375192660321, 6.7812750507492314],
					[64.043583493278859, 6.7804806501360568],
					[64.043583495781434, 6.780480637645975],
					[64.043583498961937, 6.7804806107623898],
					[64.043583499639908, 6.7804805963688821],
					[64.043569600210304, 6.7794443973564258],
					[64.043552999777532, 6.778408297132458],
					[64.043552998724451, 6.7784082869736979],
					[64.043552996587053, 6.7784082646101513],
					[64.043552994637309, 6.7784082545227546],
					[64.043535112610968, 6.7783319530437458],
					[64.043650193256866, 6.7778917517344643],
					[64.043650195727949, 6.7778917371980967],
					[64.043650198939261, 6.7778917123601898],
					[64.043650199648212, 6.7778917000126189],
					[64.043647399557869, 6.776755599331973],
					[64.043647400391961, 6.7767555951684804],
					[64.043647400266778, 6.7767555869843745],
					[64.043647399307488, 6.7767555829637223],
					[64.04356959927307, 6.7758555830291831],
					[64.043569599210443, 6.7758555789371249],
					[64.043569598188512, 6.7758555708244961],
					[64.043569597229222, 6.7758555668038838],
					[64.043397400288484, 6.7748138850023993],
					[64.043425200209953, 6.7735389098429826],
					[64.043508498797792, 6.77229441707345],
					[64.04370299674332, 6.7704389381436414],
					[64.044114086929369, 6.7690083730743469],
					[64.044686279384862, 6.7678750900027902],
					[64.044686283679695, 6.7678750773676901],
					[64.044686292300753, 6.7678750541435839],
					[64.044686294802233, 6.767875041651898],
					[64.044955794705686, 6.7666333413151438],
					[64.044955795570928, 6.7666333391972664],
					[64.044955797301398, 6.7666333349615533],
					[64.044955798135177, 6.7666333307975881],
					[64.04512798979458, 6.7655194816324178],
					[64.045605636655282, 6.76491137800814],
					[64.046219239352283, 6.7653668291707234],
					[64.046735917901017, 6.7665083824900751],
					[64.046735932625154, 6.7665084058968423],
					[64.046735968287209, 6.766508448115597],
					[64.046735989225169, 6.7665084669275801],
					[64.047413789436334, 6.7669612661587895],
					[64.047413851652649, 6.766961283717543],
					[64.047413973600328, 6.7669612739601082],
					[64.047414034259759, 6.7669612486184834],
					[64.04802793423363, 6.7664057470435157],
					[64.048027959734, 6.7664057122209282],
					[64.048027991715983, 6.7664056318048074],
					[64.048027999990879, 6.7664055860677479],
					[64.04796410023495, 6.7654666869317186],
					[64.047964097261783, 6.7654666687301246],
					[64.04796408868836, 6.7654666366349963],
					[64.047964083025192, 6.7654666186488051],
					[64.047444602661528, 6.7642860649822705],
					[64.047519599815175, 6.76306391116327],
					[64.047519593836796, 6.7630638727149082],
					[64.047519569389578, 6.7630638009169486],
					[64.047519549127401, 6.7630637677111105],
					[64.046800148938686, 6.7622581663832504],
					[64.046800107219255, 6.7622581389967991],
					[64.046800014263539, 6.7622581075234089],
					[64.046799963923931, 6.762258103364557],
					[64.045766663850571, 6.762447002247332],
					[64.045766637076468, 6.7624470125885621],
					[64.045766586281204, 6.7624470371476946],
					[64.045766562323081, 6.762447055457919],
					[64.045008162271756, 6.763169255769558],
					[64.045008156089523, 6.7631692624108988],
					[64.045008143725084, 6.7631692756936186],
					[64.045008137542851, 6.7631692823349603],
					[64.044447160081589, 6.7639469136367705],
					[64.043994561371548, 6.7635887721176173],
					[64.043530789790381, 6.7625999336731422],
					[64.043233499571627, 6.7613749757279029],
					[64.043219600417629, 6.7604082966920753],
					[64.043219599363326, 6.7604082865341368],
					[64.043219597254662, 6.7604082662182634],
					[64.043219595303668, 6.7604082561322674],
					[64.043033500307516, 6.7595666776053687],
					[64.043016899999486, 6.7585443970425185],
					[64.043016898945055, 6.7585443868847257],
					[64.04301689686767, 6.7585443686151017],
					[64.043016895813253, 6.7585443584573071],
					[64.042747395641769, 6.7572610580988561],
					[64.042747386264637, 6.7572610322221083],
					[64.042747361265228, 6.7572609830188251],
					[64.042747344777865, 6.7572609618102843],
					[64.042089044583989, 6.7565720610342215],
					[64.042089008306689, 6.7565720373201872],
					[64.042088927934699, 6.7565720069077546],
					[64.042088883840023, 6.7565720002093768],
					[64.041641683758016, 6.7566081014778856],
					[64.04143911426722, 6.7566243519673446],
					[64.041425199920809, 6.7557610978590708],
					[64.041411299718433, 6.7547249969516701],
					[64.041394600022457, 6.7536888965703987],
					[64.041394599927585, 6.7536888904328922],
					[64.04139459887277, 6.7536888802758819],
					[64.041394597881265, 6.7536888742105434],
					[64.041266898164963, 6.7526638757486968],
					[64.041139098382274, 6.7516360757252381],
					[64.041139098287317, 6.7516360695877946],
					[64.041139096335726, 6.7516360595031735],
					[64.041139095375769, 6.7516360554837753],
					[64.04090299475115, 6.750616654995234],
					[64.04090299475115, 6.750616654995234],
					[64.040664095178414, 6.749599954894216],
					[64.040664088616623, 6.7495999369882211],
					[64.040664072803054, 6.7495999013930952],
					[64.04066406358298, 6.7495998857497312],
					[64.040322364015651, 6.7491081857124007],
					[64.039980763833427, 6.7486165857833189],
					[64.039980724706382, 6.7486165520697048],
					[64.039980635176008, 6.7486165101311828],
					[64.039980583876016, 6.7486165019785851],
					[64.039536083803057, 6.7486526018712603],
					[64.039094438568782, 6.7486886955689549],
					[64.03864448864195, 6.7484665205761551],
					[64.038197339598327, 6.7482442454803611],
					[64.037741870194068, 6.7475026956488406],
					[64.037283470261798, 6.7467609944695486],
					[64.037283463858273, 6.7467609867939897],
					[64.0372834519479, 6.746760971370529],
					[64.037283446441052, 6.7467609636226076],
					[64.03683344561081, 6.7462804637868805],
					[64.036380745929876, 6.7457970636221347],
					[64.036380684385634, 6.7457970317264664],
					[64.036380555782102, 6.7457970175327997],
					[64.036380488691094, 6.7457970331893309],
					[64.035941589058254, 6.7460915341098744],
					[64.035499888954305, 6.7463859341903598],
					[64.035499870346442, 6.7463859500278271],
					[64.035499836780872, 6.7463859855040784],
					[64.035499822719771, 6.7463860050704785],
					[64.035069223229215, 6.7471999068796489],
					[64.034635931787236, 6.7480109898807479],
					[64.034199843352184, 6.7485665756917435],
					[64.033763843664659, 6.7491191489609026],
					[64.033316788867126, 6.7488970201766536],
					[64.032866820968593, 6.7486720369833995],
					[64.032416845840245, 6.7481915629023064],
					[64.031964067727415, 6.7477081869179116],
					[64.031619582410258, 6.7469582160973367],
					[64.031619582410258, 6.7469582160973367],
					[64.031619580616933, 6.7469582162420121],
					[64.031272389945059, 6.7462110353339586],
					[64.031036294983849, 6.7451916556219107],
					[64.031036294952102, 6.7451916535768524],
					[64.030797395438881, 6.744174953802168],
					[64.030797392685358, 6.744174949929362],
					[64.030797390733269, 6.7441749398489899],
					[64.03079738884469, 6.7441749338586989],
					[64.030447389254206, 6.7431666347729458],
					[64.030097388875816, 6.7421582356063405],
					[64.029750189211271, 6.741149935676237],
					[64.029750189179467, 6.741149933631279],
					[64.029400195800477, 6.7401416549754813],
					[64.029272397787324, 6.7391138755085152],
					[64.029144598853676, 6.7380888753192112],
					[64.029019598545759, 6.7370610754318667],
					[64.029019597649082, 6.7370610755045144],
					[64.02889189801553, 6.7360360747830068],
					[64.028891897951794, 6.7360360706932285],
					[64.028891895967277, 6.7360360585692325],
					[64.028891895006851, 6.7360360545521347],
					[64.028652995013132, 6.7350193533253284],
					[64.028414094982537, 6.7339999541060749],
					[64.028177994902677, 6.7329832539843526],
					[64.028177994902677, 6.7329832539843526],
					[64.027939097093281, 6.731966664661277],
					[64.027811298077964, 6.7309416753112572],
					[64.027683498075689, 6.7299138757025574],
					[64.02755849875291, 6.7288888751663221],
					[64.027558497856262, 6.7288888752392264],
					[64.027430798009789, 6.7278610750192396],
					[64.027430797945797, 6.7278610709297002],
					[64.027430795960598, 6.7278610588069299],
					[64.027430794999972, 6.7278610547903073],
					[64.027191894644261, 6.7268443541675698],
					[64.026952994820533, 6.7258277543531175],
					[64.026714095082113, 6.7248110547426316],
					[64.026477995292581, 6.7237916543574654],
					[64.026477995292581, 6.7237916543574654],
					[64.026239095222437, 6.7227749537094645],
					[64.026000195011861, 6.7217582536037161],
					[64.025761295336054, 6.720741654300606],
					[64.025525195083517, 6.7197249559905803],
					[64.025525193193928, 6.7197249500031049],
					[64.025525191240149, 6.7197249399263708],
					[64.025525189350574, 6.7197249339388758],
					[64.025175188969968, 6.7187166346035969],
					[64.024825188644897, 6.7177082330832718],
					[64.0244751888971, 6.7166999340555629],
					[64.024125191835992, 6.715691643175278],
					[64.023889094614091, 6.7146749542996522],
					[64.023889094614091, 6.7146749542996522],
					[64.023650195026534, 6.7136582535324898],
					[64.023411295081516, 6.7126416536306479],
					[64.023172395210906, 6.7116249539424526],
					[64.023172392392112, 6.7116249459850215],
					[64.023172384896881, 6.7116249261281729],
					[64.0231723820459, 6.711624916126306],
					[64.022827981625667, 6.7108749178725873],
					[64.022827981593437, 6.7108749158281773],
					[64.022483482017478, 6.7101277155446866],
					[64.022483418317847, 6.7101276614017467],
					[64.022483272223838, 6.7101276201530107],
					[64.022483188932824, 6.7101276331207238],
					[64.022041589168381, 6.7104220345572321],
					[64.021602689242641, 6.7107165348567532],
					[64.021602663562234, 6.7107165574305609],
					[64.021602624018925, 6.7107166118447115],
					[64.021602608362713, 6.7107166438319723],
					[64.021285907600713, 6.7117805429811241],
					[64.020969208224685, 6.7128416437686838],
					[64.020969207392454, 6.7128416479306328],
					[64.020969204831289, 6.7128416563279529],
					[64.020969204031204, 6.7128416625341814],
					[64.020763703742304, 6.7138943630141519],
					[64.020558104183138, 6.7149471620586461],
					[64.02055810165399, 6.714947172499901],
					[64.020558100150183, 6.7149471910448426],
					[64.020558100343152, 6.7149472033103592],
					[64.02057200012112, 6.7159833025195512],
					[64.020588699925739, 6.7170194027674484],
					[64.020605400191542, 6.7180528039154099],
					[64.020621999549618, 6.7190888811686467],
					[64.020416504426834, 6.7201416626034183],
					[64.02021090614808, 6.7211943525391717],
					[64.019894207555581, 6.7222555427298838],
					[64.019577607934536, 6.7233193426714077],
					[64.019577607134011, 6.7233193488770553],
					[64.019577604572262, 6.7233193572729837],
					[64.019577603739691, 6.7233193614344398],
					[64.01937200363119, 6.7243721607663627],
					[64.019163703600825, 6.7254249612464356],
					[64.019163701967599, 6.7254249716132444],
					[64.019163699597826, 6.7254249922738705],
					[64.019163699757897, 6.7254250024947027],
					[64.019180400099714, 6.7264611031165602],
					[64.019196999822697, 6.7274944024822982],
					[64.019213700397799, 6.7285306028860266],
					[64.019230400148601, 6.7295638936253175],
					[64.019135900989411, 6.7306082814128532],
					[64.019041501214332, 6.7316527825425938],
					[64.018944200633001, 6.7326971819869375],
					[64.018944200633001, 6.7326971819869375],
					[64.018849801249274, 6.7337416822462863],
					[64.01884980041639, 6.7337416864073107],
					[64.018849799711148, 6.7337416987449563],
					[64.018849799774941, 6.7337417028332789],
					[64.018866500430562, 6.7347750033800775],
					[64.018883099830347, 6.7358110923883734],
					[64.018788701384935, 6.7368555824726073],
					[64.018694201833313, 6.7378971709553905],
					[64.018485903840372, 6.7389499615408273],
					[64.01848590220618, 6.7389499719066119],
					[64.018485899834431, 6.7389499925656295],
					[64.01848589999355, 6.7389500027862894],
				],
			],
		},
		{
			fldNpdidField: "43792",
			wlbNpdidWellbore: "42",
			fldName: "MIME",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "7/11-5",
			fldDiscoveryYear: "1982",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43792",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43792",
			fldOwnerKind: "",
			fldOwnerName: "",
			cmpLongName: "",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=42",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "",
			fldNpdidOwner: "",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[57.11448589978307, 2.4853555974110728],
					[57.114485900467756, 2.4853556105348642],
					[57.114485900724524, 2.485355615456271],
					[57.115035900557608, 2.4922306155589151],
					[57.115035902134089, 2.4922306285259253],
					[57.115035907879069, 2.4922306523479523],
					[57.115035911240561, 2.4922306650006392],
					[57.116516509378307, 2.496530658494803],
					[57.118113707232368, 2.5023167527765544],
					[57.118113710336765, 2.5023167605092111],
					[57.118113715738431, 2.5023167777721342],
					[57.118113718842828, 2.5023167855047896],
					[57.120333112212926, 2.5070028713611254],
					[57.122252608114998, 2.5135750553644773],
					[57.122252611303978, 2.5135750647398862],
					[57.122252617681923, 2.5135750834906783],
					[57.1222526216782, 2.5135750910684909],
					[57.124669222341552, 2.518275090601092],
					[57.124669278067898, 2.5182751404396662],
					[57.124669408301621, 2.5182751854868677],
					[57.1246694820016, 2.5182751824930936],
					[57.12483068190592, 2.5182029822774976],
					[57.124830736388851, 2.5182029213837578],
					[57.12483078117301, 2.518202777736632],
					[57.124830770666819, 2.5182026967808793],
					[57.124822530667693, 2.5181890614004176],
					[57.12955548676743, 2.5176030164617194],
					[57.131591522983001, 2.52149729250262],
					[57.131591573990178, 2.5214973382134374],
					[57.131591696275258, 2.5214973863290324],
					[57.131591764875488, 2.5214973892034562],
					[57.134686164651498, 2.5204279888555678],
					[57.134686197079859, 2.5204279715712699],
					[57.1346862523299, 2.5204279254367474],
					[57.134686275151559, 2.5204278965865301],
					[57.137833474980752, 2.5147028967953524],
					[57.137833482246599, 2.514702880610189],
					[57.137833493293336, 2.5147028505084785],
					[57.137833496903802, 2.5147028333087165],
					[57.139144596995472, 2.5072028338839791],
					[57.139144598097936, 2.5072028204370169],
					[57.139144599324993, 2.5072027920584183],
					[57.139144598557074, 2.5072027772837169],
					[57.138772398841766, 2.5038110784418319],
					[57.138772390972122, 2.5038110483504252],
					[57.138772364607945, 2.5038109916934115],
					[57.138772347983817, 2.50381096645539],
					[57.137308489709753, 2.502194311325225],
					[57.135966898339447, 2.492238873073505],
					[57.135966898253933, 2.4922388714320651],
					[57.135966898168412, 2.492238869790607],
					[57.1346002054526, 2.4831500216830786],
					[57.13518628805744, 2.4815556697194294],
					[57.135186293569831, 2.4815556372730501],
					[57.135186294691657, 2.4815555724717475],
					[57.135186291193556, 2.4815555399592948],
					[57.133539090876383, 2.4763443407151438],
					[57.133539070969583, 2.4763443044772164],
					[57.133539019261406, 2.4763442456974434],
					[57.133538987288588, 2.4763442198730043],
					[57.13072235265431, 2.4749776524340614],
					[57.129316882926432, 2.4717860182156404],
					[57.129316861576626, 2.4717859888640561],
					[57.129316812080397, 2.4717859379870477],
					[57.129316782234824, 2.4717859184181816],
					[57.125327882275585, 2.4699970186942632],
					[57.125327869352098, 2.4699970126982609],
					[57.12532784376257, 2.4699970056289526],
					[57.125327830924888, 2.469997001273847],
					[57.123288931301587, 2.4696776012815174],
					[57.123288883829474, 2.4696776063603947],
					[57.123288796247884, 2.4696776367444921],
					[57.123288756052609, 2.4696776604088884],
					[57.120130456379201, 2.4729581603257262],
					[57.12013045468003, 2.4729581622816976],
					[57.120130452174131, 2.4729581660360123],
					[57.120130450389183, 2.4729581663513094],
					[57.116499849646537, 2.4770526671602906],
					[57.116499842128448, 2.4770526784212383],
					[57.116499827898998, 2.4770526991450801],
					[57.116499821273365, 2.4770527102485023],
					[57.11471092110105, 2.4806110101602972],
					[57.114710914010836, 2.4806110296223132],
					[57.114710902593529, 2.4806110697145107],
					[57.11471090013702, 2.4806110916703026],
					[57.114485900333229, 2.4853555906918761],
					[57.11448589978307, 2.4853555974110728],
				],
			],
		},
		{
			fldNpdidField: "1578893",
			wlbNpdidWellbore: "1703",
			fldName: "VALE",
			fldCurrentActivitySatus: "Producing",
			wlbName: "25/4-6 S",
			fldDiscoveryYear: "1991",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=1578893",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=1578893",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "036",
			cmpLongName: "Sval Energi AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=1703",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "36536246",
			fldNpdidOwner: "21076",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[59.683508101993539, 2.2936388877285565],
					[59.683508105419932, 2.2936389369317967],
					[59.683508109362592, 2.293638961085871],
					[59.684138709310886, 2.2956167616197236],
					[59.684138710293155, 2.2956167632044435],
					[59.684138712348208, 2.2956167681375819],
					[59.684138713421028, 2.2956167714859799],
					[59.684960912650531, 2.2977444714616215],
					[59.6849609136328, 2.2977444730464378],
					[59.684960914705513, 2.2977444763949983],
					[59.684960914705513, 2.2977444763949983],
					[59.685785914996437, 2.2997194757412815],
					[59.686885914410134, 2.3025306735102964],
					[59.686885916555362, 2.3025306802081102],
					[59.686885924593824, 2.3025306964162526],
					[59.686885928613052, 2.3025307045203283],
					[59.688410924121762, 2.3050278959499666],
					[59.689466520208221, 2.307227886363675],
					[59.689466524227164, 2.3072278944689222],
					[59.689466532084396, 2.307227907151419],
					[59.689466536103346, 2.3072279152566808],
					[59.69079423583716, 2.3091168150289318],
					[59.690794244405396, 2.3091168240055131],
					[59.690794259758228, 2.3091168423160022],
					[59.690794268326464, 2.3091168512925835],
					[59.692933168313921, 2.310986251041415],
					[59.692933183215032, 2.3109862605328342],
					[59.692933214800917, 2.3109862791584241],
					[59.692933232377491, 2.3109862881139565],
					[59.693944193851195, 2.3113501387699729],
					[59.693805399847712, 2.3148777921010746],
					[59.69380539958761, 2.3148778046295093],
					[59.69380540263468, 2.3148778289722265],
					[59.693805404158233, 2.3148778411436002],
					[59.693966503845949, 2.3156389409392792],
					[59.693966512243826, 2.3156389642111215],
					[59.693966532426415, 2.3156390065122077],
					[59.693966545102974, 2.3156390253629264],
					[59.695135925991217, 2.3170723044766617],
					[59.6954498150955, 2.3178333762546655],
					[59.695449827952167, 2.3178333986357837],
					[59.695449859818083, 2.317833440384387],
					[59.695449877845263, 2.3178334581659796],
					[59.696422077818426, 2.3185779592578095],
					[59.696422087989333, 2.3185779643525359],
					[59.696422107529514, 2.3185779764848555],
					[59.696422117700422, 2.3185779815795651],
					[59.697236005431748, 2.3189445756977354],
					[59.698402673017462, 2.3196890569322748],
					[59.699960956595035, 2.3212668406291983],
					[59.700972056214795, 2.3223195389107474],
					[59.700972057106618, 2.322319538732367],
					[59.701555457339445, 2.3229195391476809],
					[59.701555475185778, 2.3229195534056726],
					[59.701555514355604, 2.3229195794434592],
					[59.701555534697164, 2.3229195896369266],
					[59.702566634998739, 2.32326688893898],
					[59.702566642313727, 2.323266891041547],
					[59.702566656051815, 2.3232668954250433],
					[59.702566663366795, 2.3232668975275934],
					[59.703880550676672, 2.3235140938150494],
					[59.704699933240995, 2.3237751888570308],
					[59.705824919735925, 2.324214082512674],
					[59.707183166250154, 2.3248751563187637],
					[59.707610937430687, 2.3254807151824517],
					[59.707610966789929, 2.3254807431893303],
					[59.707611038255173, 2.3254807841726497],
					[59.70761107688395, 2.325480799627496],
					[59.709316656524557, 2.3256751953947097],
					[59.710402680058962, 2.3260362705652979],
					[59.71141653534238, 2.3273195145340226],
					[59.71196372537878, 2.3282306960961234],
					[59.712824816623247, 2.3299000813590478],
					[59.713727612879822, 2.3322583718894001],
					[59.7137276138617, 2.3322583734765674],
					[59.713727616897401, 2.3322583800034602],
					[59.713727617969298, 2.3322583833560264],
					[59.71494151139791, 2.3349167668444366],
					[59.715535905894292, 2.3372056498002998],
					[59.715535906966075, 2.3372056531531928],
					[59.715535910091504, 2.3372056614463852],
					[59.715535911163286, 2.3372056647992765],
					[59.715927611386753, 2.3383472644988981],
					[59.715927612638446, 2.3383472713828946],
					[59.715927618709209, 2.338347284438675],
					[59.715927621834531, 2.3383472927320801],
					[59.716280421650339, 2.3390306913365011],
					[59.716280441908467, 2.3390307176116747],
					[59.716280487416157, 2.3390307620315238],
					[59.716280513647504, 2.3390307817637264],
					[59.717055514282009, 2.3394001813972354],
					[59.717055572350596, 2.3394001894237793],
					[59.7170556841034, 2.3394001724649187],
					[59.717055735913917, 2.3394001460700471],
					[59.717558436155912, 2.338930746438479],
					[59.717558444714776, 2.3389307375954389],
					[59.71755846004875, 2.3389307202654912],
					[59.717558466733934, 2.3389307100129653],
					[59.718019566861102, 2.3382307093594115],
					[59.718019572654356, 2.3382306992846877],
					[59.71801958415093, 2.3382306773696064],
					[59.718019589052332, 2.3382306674729794],
					[59.718591888529666, 2.3366083663481225],
					[59.718591891647236, 2.3366083568073308],
					[59.718591896008625, 2.3366083363163654],
					[59.71859189823433, 2.3366083269537046],
					[59.718777997661363, 2.3352250278228159],
					[59.719002993668219, 2.3336083535041161],
					[59.719461276812623, 2.3325278932303788],
					[59.719961267757881, 2.3317501071633076],
					[59.71996127533432, 2.3317500967303468],
					[59.719961286559602, 2.3317500695146771],
					[59.719961292262298, 2.3317500576725432],
					[59.720191817894637, 2.3309781024462768],
					[59.720694298083771, 2.331275172850539],
					[59.720694338315646, 2.3312751844291091],
					[59.720694419932755, 2.3312751948694661],
					[59.720694462029719, 2.3312751900214823],
					[59.72119726207216, 2.3311112902668008],
					[59.721197311556715, 2.3311112536174226],
					[59.721197380545838, 2.3311111577717827],
					[59.721197400050421, 2.3311110985755032],
					[59.721194600060649, 2.3301917086856294],
					[59.721302968671267, 2.3288864828205589],
					[59.721691578071976, 2.3291862581779013],
					[59.721691602160696, 2.3291862711987199],
					[59.72169165185165, 2.3291862915862662],
					[59.721691678345707, 2.3291862987746508],
					[59.722272178388685, 2.3292501996671522],
					[59.722272241619578, 2.3292501852383363],
					[59.722272346388941, 2.3292501196905713],
					[59.722272387125692, 2.3292500705157924],
					[59.722502986584175, 2.3286306704817421],
					[59.722502993881044, 2.3286306369130729],
					[59.722502995007034, 2.3286305706854953],
					[59.722502989728014, 2.3286305378481806],
					[59.722108494916014, 2.3274138544047327],
					[59.721827998224462, 2.3254999701242749],
					[59.721827997062228, 2.325499965005386],
					[59.721827993755824, 2.3254999531802301],
					[59.721827993395308, 2.3254999461171053],
					[59.721433493282689, 2.3240527472440951],
					[59.721433491138434, 2.3240527405380251],
					[59.721433486940064, 2.3240527288916635],
					[59.721433484885956, 2.3240527239513469],
					[59.720961286003487, 2.3229110254341467],
					[59.720727998929021, 2.3223027596217594],
					[59.720630799755369, 2.3189306063815311],
					[59.720814099456774, 2.3169333185107965],
					[59.720814099186043, 2.3169333132136876],
					[59.720814099626651, 2.3169333042065365],
					[59.72081410024775, 2.3169332987307616],
					[59.720811300182483, 2.3163944466022848],
					[59.72184737945534, 2.3143028894495408],
					[59.721847380256875, 2.314302887505054],
					[59.721847381058424, 2.3143028855605636],
					[59.721847380968136, 2.3143028837948254],
					[59.72303627636083, 2.3117472957813781],
					[59.724072369332468, 2.3101168061851767],
					[59.724072370224278, 2.310116806006302],
					[59.724072370935403, 2.310116802295743],
					[59.724072371827219, 2.310116802116867],
					[59.724764044910344, 2.3089529475494501],
					[59.725727895096149, 2.308394575773427],
					[59.727002872890246, 2.307755786345667],
					[59.728975050373435, 2.3071779931317682],
					[59.730522236117928, 2.3068390968578067],
					[59.732419416072332, 2.3065695993251327],
					[59.73381390427496, 2.3065418994822648],
					[59.733813926660382, 2.3065418967726301],
					[59.733813971069694, 2.3065418842879808],
					[59.733813993183922, 2.3065418762792995],
					[59.734355692816493, 2.3062584776422725],
					[59.73435573880414, 2.3062584255805563],
					[59.734355786617115, 2.306258303555905],
					[59.734355788442386, 2.3062582335929642],
					[59.733725187548259, 2.3044860323676204],
					[59.73372518334812, 2.3044860207196534],
					[59.733725169325751, 2.3044859931992647],
					[59.733725160576228, 2.3044859806804099],
					[59.732939061298552, 2.3034192801778799],
					[59.732939035863545, 2.3034192585196602],
					[59.732938980715375, 2.303419219631397],
					[59.732938950291334, 2.3034192061130367],
					[59.73171944972561, 2.3031054065858525],
					[59.731719436077356, 2.3031054039739245],
					[59.731719408871299, 2.3031054005162712],
					[59.731719396205285, 2.3031053994914448],
					[59.730366696318072, 2.3031330992120722],
					[59.730366681958891, 2.3031331003116078],
					[59.730366654313222, 2.3031331058638718],
					[59.730366640044501, 2.3031331087295515],
					[59.729127739906744, 2.3035220082576782],
					[59.729127724125711, 2.3035220167794828],
					[59.729127694256761, 2.3035220316988561],
					[59.729127680259317, 2.303522039862504],
					[59.727974880338685, 2.304388739344807],
					[59.727974872583765, 2.3043887462543999],
					[59.727974858767084, 2.3043887579494338],
					[59.727974852795732, 2.3043887645009042],
					[59.726074861305804, 2.306455454252927],
					[59.725310978908709, 2.3070970394471533],
					[59.724277731025992, 2.3077942058771823],
					[59.722902782282446, 2.3079192006859506],
					[59.722902766229936, 2.3079192039065899],
					[59.722902734215324, 2.3079192121136383],
					[59.722902719144976, 2.3079192169211455],
					[59.722502719203469, 2.3080970166207249],
					[59.722502712249735, 2.3080970215835688],
					[59.72250269816157, 2.3080970279777415],
					[59.722502691117491, 2.3080970311748454],
					[59.721774911995738, 2.3085692182186297],
					[59.720874965210506, 2.3088776015592316],
					[59.720486106093816, 2.3088858992233923],
					[59.719741785199531, 2.308827605666032],
					[59.719386274812329, 2.3084970891706904],
					[59.718966892911496, 2.3071805462748669],
					[59.718625195068327, 2.3057055556610164],
					[59.71862518979578, 2.305705540665882],
					[59.718625178358913, 2.3057055108545779],
					[59.718625171302762, 2.3057054962173504],
					[59.718077971422801, 2.3047942967400168],
					[59.718077959817158, 2.3047942812325735],
					[59.718077934020876, 2.3047942525201162],
					[59.718077919830243, 2.3047942393151146],
					[59.716983420083594, 2.3039720397520638],
					[59.716983395992699, 2.303972026751226],
					[59.71698334629852, 2.3039720064038605],
					[59.716983319893842, 2.3039720010017617],
					[59.715988920309606, 2.3038720002855624],
					[59.715988869386656, 2.3038720087221507],
					[59.715988777814914, 2.3038720502865204],
					[59.715988738057966, 2.3038720832352784],
					[59.714922081278232, 2.3053470238012039],
					[59.714186124855843, 2.3055219887216571],
					[59.713233421422707, 2.3050359365422119],
					[59.712241851775275, 2.3040109695459594],
					[59.711133458972462, 2.3025637783355513],
					[59.711133457990222, 2.3025637767494023],
					[59.711133456116208, 2.3025637753422319],
					[59.711133455133982, 2.3025637737560789],
					[59.709964054807088, 2.3011304736938762],
					[59.709964047130065, 2.3011304645355466],
					[59.709964030974646, 2.3011304481629793],
					[59.709964021604463, 2.3011304411277451],
					[59.709186221583849, 2.3005331406468597],
					[59.709186196600896, 2.3005331278301857],
					[59.709186143248708, 2.300533106442999],
					[59.709186115060383, 2.3005331014025336],
					[59.707597245192943, 2.3004137024192866],
					[59.706472299084496, 2.2999776235628637],
					[59.706083422267497, 2.2996776421428193],
					[59.706083403617484, 2.2996776298395374],
					[59.706083364895733, 2.2996776126504419],
					[59.706083343751281, 2.2996776044139464],
					[59.705152906679672, 2.2994665197213204],
					[59.703944549847904, 2.2981137676866363],
					[59.702697351018344, 2.2966831681063407],
					[59.702697316393227, 2.29668314296815],
					[59.702697238949099, 2.2966831085999151],
					[59.702697196130089, 2.2966830993698797],
					[59.702386095875269, 2.2966886997910767],
					[59.702386039253334, 2.2966887200752337],
					[59.702385946365972, 2.2966887886467466],
					[59.702385910191055, 2.2966888386987589],
					[59.702158110018821, 2.2973832380187189],
					[59.702158109308016, 2.2973832417270637],
					[59.702158106994595, 2.2973832493228246],
					[59.702158106193295, 2.2973832512665244],
					[59.701930420415167, 2.2983081925875273],
					[59.70158321362058, 2.2986220073993153],
					[59.700694379320275, 2.2987165013680104],
					[59.700694335002083, 2.2987165156122327],
					[59.700694260026928, 2.2987165645340162],
					[59.700694228387661, 2.2987165976259942],
					[59.70023313152462, 2.2994942902729565],
					[59.699733164144881, 2.3001942455551925],
					[59.698844362905881, 2.3006719926617549],
					[59.697913977021656, 2.3005387084526432],
					[59.697602956135981, 2.3003165659522531],
					[59.697369577600071, 2.2998610086517384],
					[59.697369535738041, 2.2998609653580782],
					[59.697369435715778, 2.2998609123447675],
					[59.697369378356868, 2.2998609006817285],
					[59.696516722431042, 2.2999553959870913],
					[59.695430703101941, 2.2995942236313396],
					[59.694030735118076, 2.2983220521263643],
					[59.69403070165653, 2.2983220321060496],
					[59.694030632781818, 2.2983220067161185],
					[59.694030596386398, 2.2983219997612587],
					[59.693294396282326, 2.2983359002405654],
					[59.692130656228073, 2.2983608996294849],
					[59.691316853589441, 2.2978415609940708],
					[59.690141881917739, 2.2954916163286598],
					[59.68935578983087, 2.2935916348877021],
					[59.688922398930018, 2.2919916701267393],
					[59.688827999604626, 2.2888527936137004],
					[59.688777999647776, 2.2870916947257953],
					[59.688777999557153, 2.2870916929618796],
					[59.688778000358298, 2.2870916910187429],
					[59.688778000267703, 2.2870916892548374],
					[59.688691899598304, 2.2854082900570134],
					[59.688691899054483, 2.2854082794736446],
					[59.68869189511026, 2.2854082553169572],
					[59.688691891891132, 2.2854082452714213],
					[59.68821689166289, 2.2837332450158492],
					[59.68821688531505, 2.28373322668926],
					[59.688216866376983, 2.2837331912913146],
					[59.688216854678551, 2.283733174040639],
					[59.687789054597836, 2.2832054738149883],
					[59.687789009707799, 2.2832054418545176],
					[59.687788908893658, 2.2832054086656131],
					[59.687788852878903, 2.2832054056733591],
					[59.687441653125312, 2.283288706342864],
					[59.687441605785963, 2.2832887319008108],
					[59.687441531284975, 2.2832888074720303],
					[59.68744150492445, 2.2832888555421595],
					[59.687213711041878, 2.2842888289273189],
					[59.686791532981864, 2.285066588046027],
					[59.686408144595454, 2.285533173672047],
					[59.686408135420429, 2.28553318799031],
					[59.686408117251631, 2.2855332201543952],
					[59.686408110041427, 2.2855332376416997],
					[59.686255409903659, 2.2859971365281857],
					[59.686255407061587, 2.2859971513553083],
					[59.686255402087966, 2.2859971773027952],
					[59.686255400047038, 2.2859971901869085],
					[59.686185899896373, 2.287605591297134],
					[59.686185900077575, 2.2876055948246745],
					[59.686185900258792, 2.2876055983522243],
					[59.686185900349386, 2.287605600115993],
					[59.686188700006163, 2.2883721539926993],
					[59.685538729397472, 2.289688794709722],
					[59.684460941938362, 2.2910887781951566],
					[59.684460936058137, 2.2910887865036083],
					[59.684460926081229, 2.2910888027623209],
					[59.684460921092764, 2.2910888108916634],
					[59.683694221425547, 2.2926388118025227],
					[59.683694214834489, 2.292638823816874],
					[59.683694207184161, 2.2926388502985802],
					[59.683694203358996, 2.2926388635394344],
					[59.683508103401579, 2.2936388625003516],
					[59.683508101993539, 2.2936388877285565],
				],
			],
		},
		{
			fldNpdidField: "29401178",
			wlbNpdidWellbore: "8102",
			fldName: "SINDRE",
			fldCurrentActivitySatus: "Producing",
			wlbName: "34/10-55 S",
			fldDiscoveryYear: "2017",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=29401178",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=29401178",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "SINDRE UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=8102",
			fldHcType: "OIL/GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "29400473",
			fldDateUpdated: "2022-09-13 00:00:00",
			fldDateUpdatedMax: "2021-06-03 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[
						[61.230719200038465, 2.3471139007505237],
						[61.230730400377745, 2.349350000861806],
						[61.230730399850586, 2.349350008450108],
						[61.230730401380228, 2.3493500212096845],
						[61.230730401744786, 2.3493500286089168],
						[61.231033102394377, 2.3514417287653044],
						[61.231033109019982, 2.3514417535289036],
						[61.231033130296325, 2.3514418013550427],
						[61.231033143163693, 2.3514418247956046],
						[61.23193594289981, 2.3525890234187163],
						[61.231935961826593, 2.3525890418377409],
						[61.231936004848073, 2.3525890738421196],
						[61.231936028942755, 2.3525890874274795],
						[61.23367212924353, 2.3532529864468219],
						[61.233672144857572, 2.3532529924841872],
						[61.233672177595807, 2.353252998630956],
						[61.233672194811071, 2.3532530005903589],
						[61.234705594581797, 2.3532779995190301],
						[61.234705600023013, 2.3532780002351674],
						[61.234705609922628, 2.3532780000064037],
						[61.234705615272716, 2.3532779988724997],
						[61.235533220832224, 2.3532141074786597],
						[61.236413727128102, 2.3541279098749404],
						[61.237299813393008, 2.3564861698142452],
						[61.237299815449518, 2.356486174987066],
						[61.237299817688154, 2.3564861838603282],
						[61.237299820636338, 2.356486188844257],
						[61.237708121229403, 2.3573167872959249],
						[61.237708211455818, 2.3573168448333308],
						[61.237708390631951, 2.3573168423981614],
						[61.237708480655478, 2.3573167859371504],
						[61.238264080843223, 2.3561417853692377],
						[61.238264081643869, 2.3561417833300147],
						[61.238264084136866, 2.3561417790626726],
						[61.238264084846421, 2.3561417751732039],
						[61.238454170824362, 2.3556819958665045],
						[61.238366506298753, 2.3562499494673403],
						[61.237872012929195, 2.3575555282205669],
						[61.237872006815628, 2.3575555687759664],
						[61.23787200983827, 2.3575556485259144],
						[61.237872020757841, 2.3575556873426926],
						[61.238124820086185, 2.3580694879731223],
						[61.238563719893143, 2.3589667877662834],
						[61.238563722841228, 2.3589667927506461],
						[61.238563727845779, 2.3589668029081912],
						[61.238563730702893, 2.3589668060422291],
						[61.239447031155045, 2.3603751055447417],
						[61.239447032137747, 2.3603751072062753],
						[61.239447034194114, 2.3603751123797219],
						[61.239447035176816, 2.3603751140412563],
						[61.24032763511569, 2.3616501135088761],
						[61.24032763708103, 2.3616501168321209],
						[61.240327639938101, 2.3616501199665718],
						[61.240327641812463, 2.3616501214394199],
						[61.241019242204956, 2.3625445226983222],
						[61.24101924702731, 2.3625445291562968],
						[61.241019259347141, 2.3625445415059589],
						[61.241019265061198, 2.3625445477751814],
						[61.241708164623759, 2.3631779475293095],
						[61.241708164623759, 2.3631779475293095],
						[61.241708167389824, 2.3631779488135307],
						[61.241708167480787, 2.3631779506640314],
						[61.242708166612722, 2.3640640507839894],
						[61.242708169287823, 2.3640640502177694],
						[61.242708173036526, 2.3640640531639421],
						[61.242708175001816, 2.3640640564875874],
						[61.243335974520441, 2.3645695551673391],
						[61.243335998705064, 2.3645695706169483],
						[61.243336049294712, 2.3645695916969451],
						[61.243336075790701, 2.3645695991779503],
						[61.244274962912129, 2.364680797550204],
						[61.245213790825055, 2.3649223778732638],
						[61.245777650408016, 2.3655584334740793],
						[61.245777658978923, 2.3655584428800767],
						[61.245777677722309, 2.365558457613127],
						[61.245777688076629, 2.3655584666416809],
						[61.246152688072215, 2.3658112669127371],
						[61.246152701902432, 2.365811273335821],
						[61.246152732328895, 2.3658112874666091],
						[61.246152747050765, 2.3658112937009834],
						[61.246591627738752, 2.3659307871922599],
						[61.247091585028471, 2.3661807659027181],
						[61.247780464838236, 2.3668140476518942],
						[61.24778047233557, 2.3668140535457618],
						[61.247780489204516, 2.3668140668069726],
						[61.247780496610901, 2.3668140708499603],
						[61.248405477858135, 2.3671890591781852],
						[61.249602662006552, 2.3683334444930426],
						[61.24960266298914, 2.3683334461553551],
						[61.250413763250272, 2.3690945468269327],
						[61.250413773604393, 2.3690945558579029],
						[61.250413797788617, 2.3690945713141671],
						[61.250413810817832, 2.3690945797791656],
						[61.250916580736856, 2.36934456348461],
						[61.251291557703794, 2.3697251408954085],
						[61.251291591259452, 2.36972516372153],
						[61.251291663885688, 2.3697251932444994],
						[61.251291703848018, 2.3697251997527085],
						[61.251605510065332, 2.3697196024087326],
						[61.252044246803251, 2.3702279288737449],
						[61.25235814463349, 2.3706112264732822],
						[61.252358156952681, 2.3706112388303247],
						[61.252358181409313, 2.3706112598421201],
						[61.252358195421067, 2.370611269970746],
						[61.253169268992259, 2.3711140535506718],
						[61.253610930936148, 2.3716223109809551],
						[61.253797017385367, 2.3720083814138593],
						[61.254052614170412, 2.3726500744556969],
						[61.254052620156607, 2.3726500862830173],
						[61.254052632038096, 2.3726501080864146],
						[61.254052638915994, 2.372650119725169],
						[61.254430439215078, 2.3731612192972968],
						[61.254430446893799, 2.3731612288964437],
						[61.254430464926436, 2.3731612475289703],
						[61.25443047528033, 2.3731612565623723],
						[61.255058152112362, 2.3736668377244956],
						[61.255685936024292, 2.3745640156303898],
						[61.255685947451433, 2.3745640281787983],
						[61.255685971106644, 2.3745640512356685],
						[61.255685984317239, 2.374564063406984],
						[61.256749884005878, 2.3753195637139806],
						[61.256749884897616, 2.3753195635254452],
						[61.256749886771864, 2.3753195649998102],
						[61.25674988775436, 2.3753195666627116],
						[61.25731098816739, 2.3756973667458752],
						[61.257311001997216, 2.3756973731753228],
						[61.257311032422841, 2.3756973873201077],
						[61.257311047144377, 2.3756973935610342],
						[61.258188835979794, 2.3759390900900601],
						[61.258813826393641, 2.3761862855717704],
						[61.258813831033841, 2.3761862883322782],
						[61.258813839240936, 2.3761862903386715],
						[61.258813842898611, 2.3761862914361336],
						[61.259627703338019, 2.3764279794613028],
						[61.260380471236672, 2.3770640524967508],
						[61.26038048060785, 2.3770640598699027],
						[61.260380501042839, 2.3770640723875425],
						[61.260380511305698, 2.3770640795721736],
						[61.26087767588929, 2.3773112623122685],
						[61.261447040790969, 2.3779501214494365],
						[61.262197031614512, 2.3791001091800172],
						[61.262197057234033, 2.3791001355714432],
						[61.262197112387142, 2.3791001763013546],
						[61.262197143794978, 2.3791001921146795],
						[61.263452743674662, 2.3794668916520791],
						[61.263452759197058, 2.3794668958555163],
						[61.263452789077846, 2.3794668988953145],
						[61.263452804418705, 2.379466899395057],
						[61.264511069597511, 2.3794446014828141],
						[61.265261038326074, 2.3796890896371337],
						[61.26526109433923, 2.37968909276728],
						[61.265261198399138, 2.3796890651598552],
						[61.265261245644908, 2.379689036462695],
						[61.265758365606935, 2.3791613203306108],
						[61.266261053745872, 2.3792807949059354],
						[61.266261071852654, 2.3792807966920679],
						[61.266261108867283, 2.379280798223836],
						[61.26626112688335, 2.3792807981579736],
						[61.267383327387378, 2.3791279980763629],
						[61.267383342546729, 2.3791279948717361],
						[61.267383373575626, 2.3791279845698186],
						[61.267383390427689, 2.3791279791361006],
						[61.268191776791411, 2.3787223847351995],
						[61.26943896299202, 2.3783084905147125],
						[61.269438975294634, 2.3783084841706801],
						[61.269438999990598, 2.3783084733347879],
						[61.269439011310702, 2.3783084653271152],
						[61.2704361350429, 2.3776419169860241],
						[61.271308247063715, 2.3778834921895808],
						[61.271308252595617, 2.3778834947627381],
						[61.271308267135559, 2.3778834973024665],
						[61.271308274360116, 2.3778834976461818],
						[61.272627712653538, 2.3780557905946238],
						[61.273499853120292, 2.3788279364831015],
						[61.274099841230573, 2.3796057210894492],
						[61.274808134530197, 2.3805473122975451],
						[61.275599829662681, 2.381827905869955],
						[61.275599834393525, 2.3818279104851912],
						[61.275599843144953, 2.3818279236092712],
						[61.275599847966546, 2.3818279300770699],
						[61.276308147951816, 2.3826612307524777],
						[61.276308156430972, 2.3826612383192591],
						[61.276308171605912, 2.3826612538297547],
						[61.276308180976841, 2.38266126120803],
						[61.27704708076795, 2.3832084607545987],
						[61.277047104860131, 2.3832084743810413],
						[61.277047157140323, 2.3832084932809474],
						[61.277047184436604, 2.3832084987428783],
						[61.277616683944039, 2.3832529992179134],
						[61.277616692952094, 2.3832529991858964],
						[61.277616710968182, 2.3832529991218681],
						[61.277616719976187, 2.3832529990898772],
						[61.278266720118026, 2.3831862994511379],
						[61.278266736169208, 2.383186296058549],
						[61.278266766397415, 2.3831862877975918],
						[61.278266779682681, 2.3831862831177069],
						[61.278805680342224, 2.3829501821343011],
						[61.278805703164366, 2.3829501698225606],
						[61.278805743004668, 2.3829501370662238],
						[61.278805761005287, 2.3829501182858785],
						[61.279183461198883, 2.3824334185361691],
						[61.279183463692604, 2.3824334142651007],
						[61.279183468770839, 2.3824334075757072],
						[61.27918347126456, 2.3824334033046379],
						[61.279564054011466, 2.3818057313574621],
						[61.279966829630361, 2.3814612528591366],
						[61.279966846648286, 2.3814612324129265],
						[61.279966875333734, 2.3814611926517655],
						[61.279966886910543, 2.3814611714839731],
						[61.280289086648132, 2.3806083715142425],
						[61.280289093221654, 2.3806083401717122],
						[61.280289096650428, 2.3806082814137386],
						[61.280289094215917, 2.3806082501040309],
						[61.28009189390508, 2.3798249513054386],
						[61.280091890776113, 2.3798249426070668],
						[61.280091886392313, 2.3798249266859886],
						[61.280091883263353, 2.3798249179876358],
						[61.279766883238239, 2.3790971171139974],
						[61.279766872612292, 2.379097102513422],
						[61.27976685216138, 2.3790970712708788],
						[61.279766839752014, 2.3790970570475074],
						[61.279030740059667, 2.3783804563172],
						[61.279030729706022, 2.3783804472754762],
						[61.279030710072021, 2.3783804327089104],
						[61.27903069891741, 2.3783804257085612],
						[61.278366822882639, 2.3780026396119567],
						[61.278116842162262, 2.3777498593867268],
						[61.278116839305504, 2.3777498562472075],
						[61.278116833682816, 2.3777498518208908],
						[61.278116830826058, 2.3777498486813724],
						[61.277675139918017, 2.3773692565191551],
						[61.276986249085162, 2.3766026651368808],
						[61.276986242570686, 2.3766026608995836],
						[61.276986232126127, 2.3766026500063555],
						[61.276986225611672, 2.3766026457690526],
						[61.276675159853212, 2.3763498723773178],
						[61.276611278809881, 2.3762221119595264],
						[61.27661126416276, 2.376222088852955],
						[61.276611230773149, 2.3762220509935732],
						[61.276611211048099, 2.3762220345768079],
						[61.276233427817651, 2.3759692457730295],
						[61.275605741625398, 2.3753331605603774],
						[61.275605742426315, 2.3753331585191537],
						[61.27497794180784, 2.3746970600619997],
						[61.274977941717019, 2.3746970582094806],
						[61.274225141860491, 2.3739359593683114],
						[61.274225139003683, 2.3739359562295221],
						[61.274225132398307, 2.373935950140647],
						[61.274225127758044, 2.3739359473792638],
						[61.273164038818841, 2.373047056939777],
						[61.272594548969117, 2.37241096633494],
						[61.272594532992272, 2.3724109528722916],
						[61.272594498363432, 2.3724109265132212],
						[61.272594478910577, 2.3724109156578974],
						[61.271158378970171, 2.3717915159868754],
						[61.271158361573271, 2.3717915103116898],
						[61.27115832785298, 2.3717915024770915],
						[61.271158309746134, 2.3717915006951924],
						[61.26934440963845, 2.3716969993207075],
						[61.269344377445947, 2.3716970042629484],
						[61.269344314406908, 2.3716970232194989],
						[61.269344282668683, 2.3716970374225546],
						[61.269158208323894, 2.3718304197886724],
						[61.268391661998734, 2.3720887022627068],
						[61.267058331174276, 2.3721580980021408],
						[61.266663970334569, 2.3720109137417746],
						[61.266663952045981, 2.3720109082558234],
						[61.266663914758865, 2.3720109011766404],
						[61.266663895760288, 2.3720108995833953],
						[61.265975040784426, 2.3720247985384075],
						[61.265413981145649, 2.3717748173988453],
						[61.265413979271372, 2.3717748159243173],
						[61.265413974721916, 2.3717748150158986],
						[61.265413972847647, 2.3717748135413688],
						[61.265100100611662, 2.3716526259865569],
						[61.264472338648531, 2.3711470554071186],
						[61.263908450080855, 2.3705081682456135],
						[61.263908448206564, 2.3705081667712276],
						[61.263908444367061, 2.3705081619706294],
						[61.26390844160106, 2.3705081606849525],
						[61.263280742100896, 2.3698720599862577],
						[61.263280738261408, 2.3698720551858052],
						[61.263280729781592, 2.3698720476254573],
						[61.263280725141271, 2.369872044865557],
						[61.262339049998246, 2.3691137640119999],
						[61.26158626732564, 2.3679637902979156],
						[61.261586260629144, 2.3679637823609103],
						[61.261586249019558, 2.367963766109348],
						[61.261586242413955, 2.3679637600240362],
						[61.26095846481283, 2.3673276833828196],
						[61.260519590023883, 2.3664277326965641],
						[61.260005795202765, 2.3642332548068019],
						[61.260005785904028, 2.3642332305844733],
						[61.260005764012682, 2.3642331884500729],
						[61.260005750346387, 2.3642331670236905],
						[61.259441849600272, 2.3635970663896551],
						[61.259441845851541, 2.3635970634421071],
						[61.259441838263136, 2.363597055695446],
						[61.259441834423392, 2.3635970508963444],
						[61.258877952958102, 2.3630887688100746],
						[61.258625167110388, 2.3627054894862058],
						[61.258625146219359, 2.3627054677211414],
						[61.258625100160721, 2.362705428838566],
						[61.258625073300728, 2.362705413950331],
						[61.258313972825043, 2.3625831141483022],
						[61.258313968184588, 2.3625831113898843],
						[61.258313958193945, 2.3625831097648979],
						[61.258313952661815, 2.3625831071953627],
						[61.257438953595511, 2.3623415063247748],
						[61.257002872335818, 2.3622192117278171],
						[61.256502913141567, 2.3619720324062707],
						[61.255933438019582, 2.3614637549047375],
						[61.255433443214137, 2.3609554596821183],
						[61.255433437590952, 2.3609554552617991],
						[61.255433430002405, 2.3609554475167185],
						[61.255433426253624, 2.3609554445698167],
						[61.254808442749045, 2.360449858728257],
						[61.253991861971471, 2.3594304823100232],
						[61.253236274376455, 2.3582777012850946],
						[61.252733486084537, 2.357252724120563],
						[61.25247799175002, 2.3564805433091043],
						[61.251969592626857, 2.3545471490848282],
						[61.251969581360989, 2.3545471215475562],
						[61.25196954911182, 2.3545470704040046],
						[61.251969530002953, 2.3545470482706454],
						[61.251464056002881, 2.3541165704211511],
						[61.250902987383604, 2.3531416248048336],
						[61.250839093587253, 2.3528832531461901],
						[61.250202994648177, 2.3501749536930165],
						[61.250202991608575, 2.3501749468567774],
						[61.250202987130436, 2.3501749291040284],
						[61.250202984090869, 2.3501749222677804],
						[61.249822384187219, 2.3492749228749044],
						[61.24982238007356, 2.3492749125262065],
						[61.249822371136943, 2.349274895719867],
						[61.249822366222823, 2.349274887411299],
						[61.249130768643354, 2.3482526923431228],
						[61.248436271343913, 2.3470998963348584],
						[61.248436264555117, 2.3470998865546964],
						[61.248436250085874, 2.3470998671836396],
						[61.248436241604914, 2.3470998596328485],
						[61.247683441797662, 2.3463387596632042],
						[61.247683440814797, 2.346338758001699],
						[61.247683438940264, 2.3463387565294749],
						[61.247683438940264, 2.3463387565294749],
						[61.24677884814998, 2.3454700775485935],
						[61.24782205767206, 2.3461501464303898],
						[61.248835931834222, 2.3476084095434664],
						[61.249847028928443, 2.3492723040796655],
						[61.249847090604753, 2.349272348970191],
						[61.249847228346901, 2.3492723833301095],
						[61.249847304321548, 2.3492723709485848],
						[61.250694503883956, 2.3487584719317538],
						[61.250694529722544, 2.3487584477448489],
						[61.25069457301003, 2.3487583936702694],
						[61.250694590367779, 2.3487583619316026],
						[61.251711290355971, 2.3456361624239146],
						[61.25171129408465, 2.3456361466690483],
						[61.251711298775838, 2.3456361138762745],
						[61.25171129982953, 2.3456360986893938],
						[61.251672399727788, 2.3422610981199674],
						[61.251672399636519, 2.3422610962689618],
						[61.251672400436924, 2.3422610942285269],
						[61.251672400345669, 2.3422610923775204],
						[61.251552999866519, 2.3389471936806063],
						[61.251552999318726, 2.3389471825746657],
						[61.251552995548195, 2.3389471609313253],
						[61.251552994200068, 2.3389471518659053],
						[61.250872399111067, 2.336269369647467],
						[61.25055019955699, 2.3299332896465299],
						[61.250550147729165, 2.3299332071594216],
						[61.250549998598665, 2.329933124807559],
						[61.250549901387416, 2.3299331267937031],
						[61.249836000975797, 2.3303387267610325],
						[61.249835981816773, 2.3303387401898781],
						[61.249835947430924, 2.3303387736920014],
						[61.249835931221071, 2.3303387921041505],
						[61.248633214255236, 2.3322303636080353],
						[61.247730703957714, 2.3319470238089814],
						[61.246530738205088, 2.3307998548900986],
						[61.246530677437271, 2.3307998285472293],
						[61.246530549887737, 2.3307998182835421],
						[61.246530485072093, 2.3307998376846442],
						[61.245908449121806, 2.3312385514936245],
						[61.245641898469863, 2.3295082706496673],
						[61.245641895154989, 2.3295082582646502],
						[61.245641889691214, 2.3295082388566679],
						[61.245641887359383, 2.329508228132517],
						[61.244616887057759, 2.3268277284538881],
						[61.244616824643103, 2.326827668810453],
						[61.244616678140375, 2.3268276214576513],
						[61.244616592977806, 2.3268276302370334],
						[61.243472092575168, 2.3275498304133384],
						[61.243472083933142, 2.3275498378625641],
						[61.243472065757466, 2.3275498529508125],
						[61.243472058007079, 2.3275498602102727],
						[61.24164161544352, 2.3294081026820734],
						[61.240497248861189, 2.3294303991576597],
						[61.239844541240615, 2.3290720483027223],
						[61.238869567830342, 2.32755549235269],
						[61.238869557199379, 2.3275554777880596],
						[61.238869532462331, 2.3275554512679935],
						[61.238869518264792, 2.3275554374623497],
						[61.237130717650963, 2.3262887389025759],
						[61.237130631299451, 2.3262887236287653],
						[61.23713048017882, 2.3262887651397484],
						[61.237130414701006, 2.3262888258144883],
						[61.23602201491655, 2.3290138243623875],
						[61.236022011715662, 2.3290138325212655],
						[61.236022007005737, 2.3290138466096604],
						[61.236022004696508, 2.329013854578851],
						[61.23503310791174, 2.3332305424185282],
						[61.233994225395833, 2.336141594009852],
						[61.232916552324809, 2.3373276658023063],
						[61.232916537988352, 2.3373276856715055],
						[61.232916514047695, 2.337327730012444],
						[61.232916505243821, 2.3373277524448755],
						[61.232366511021198, 2.3396249310697459],
						[61.231722020208792, 2.3409416120729745],
						[61.231722013096366, 2.3409416322751064],
						[61.231722003421055, 2.3409416735826487],
						[61.231721999966496, 2.3409416948773751],
						[61.231666500601946, 2.3432749666724018],
						[61.23102480964215, 2.3452443386051289],
						[61.231024808932837, 2.3452443424939435],
						[61.23102480742309, 2.3452443484217262],
						[61.231024805822138, 2.3452443524997193],
						[61.230827605775744, 2.346041651133338],
						[61.230827604357152, 2.3460416589108561],
						[61.230827602320403, 2.346041672426932],
						[61.230827600901769, 2.3460416802044586],
						[61.230719200819003, 2.3471138800244868],
						[61.230719201092505, 2.3471138855738878],
						[61.230719199764962, 2.3471138952011397],
						[61.230719200038465, 2.3471139007505237],
					],
					[
						[61.238665994050315, 2.3540576390717725],
						[61.238808653953278, 2.3524894460795807],
						[61.238788699729319, 2.3530027848359905],
						[61.238665994050315, 2.3540576390717725],
					],
					[
						[61.238819600959779, 2.3492777814243202],
						[61.238889634227668, 2.3481276715505097],
						[61.238894200029662, 2.3489832992494635],
						[61.23888354856868, 2.3495353851737488],
						[61.238819600959779, 2.3492777814243202],
					],
					[
						[61.23893383237538, 2.3474018403035819],
						[61.238944587397881, 2.3472252195925192],
						[61.239210962912104, 2.3472524462360997],
						[61.23893383237538, 2.3474018403035819],
					],
					[
						[61.240679397314175, 2.3461531974666903],
						[61.241736230706792, 2.3444168688092937],
						[61.242877858623032, 2.3440640905518486],
						[61.242877886882717, 2.3440640789424321],
						[61.242877935538935, 2.3440640424341113],
						[61.24287795799254, 2.3440640227080447],
						[61.243864004323754, 2.3427862921707137],
						[61.244808218384591, 2.3427196053100112],
						[61.245866532952057, 2.3438640144598515],
						[61.246512078428282, 2.3452138995907208],
						[61.246494564000777, 2.3451970807549847],
						[61.246114079234623, 2.3444277109822917],
						[61.24611407816051, 2.344427707470238],
						[61.246114074320197, 2.3444277026754525],
						[61.246114072354437, 2.3444276993527247],
						[61.245736271827333, 2.3437859984259926],
						[61.245736259232203, 2.3437859805300656],
						[61.245736227891527, 2.3437859479142724],
						[61.245736210037599, 2.3437859330050537],
						[61.245358436916646, 2.3435359515871603],
						[61.244858458136733, 2.3428970772515565],
						[61.244858416987555, 2.342897046720458],
						[61.244858324992812, 2.3428970082872644],
						[61.244858274238524, 2.3428970022357873],
						[61.243672173689362, 2.3430526026468286],
						[61.243672134729835, 2.3430526165296133],
						[61.2436720647652, 2.3430526594353087],
						[61.24367203456049, 2.343052686418341],
						[61.242866593245544, 2.3442359015415133],
						[61.242369395724943, 2.3442470000315998],
						[61.242369351232838, 2.3442470113470799],
						[61.242369272168652, 2.3442470524402412],
						[61.242369237596563, 2.3442470822179078],
						[61.24218048059722, 2.3445081233534775],
						[61.241619351106131, 2.3446498054472777],
						[61.24161931589547, 2.3446498222707444],
						[61.241619255120533, 2.3446498688282666],
						[61.241619227681682, 2.3446498970904832],
						[61.240752647119997, 2.3460942667662739],
						[61.240679397314175, 2.3461531974666903],
					],
				],
			],
		},
		{
			fldNpdidField: "43444",
			wlbNpdidWellbore: "247",
			fldName: "TOMMELITEN GAMMA",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "1/9-4",
			fldDiscoveryYear: "1978",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=43444",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=43444",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "TOMMELITEN UNIT",
			cmpLongName: "ConocoPhillips Skandinavia AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=247",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "2410696",
			fldNpdidOwner: "41411",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-02-17 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[56.476794200895085, 2.9295194015887307],
					[56.476794200714572, 2.9295194162600673],
					[56.477572000830563, 2.9388139169402749],
					[56.477572003249954, 2.9388139295689308],
					[56.477572009061191, 2.938813956297627],
					[56.477572013346382, 2.9388139702547824],
					[56.480224813040302, 2.9458333704247521],
					[56.480224823079965, 2.9458333915993808],
					[56.48022484925491, 2.9458334297205133],
					[56.480224863603439, 2.945833446952506],
					[56.484152663966526, 2.9494807476835949],
					[56.484152701937248, 2.9494807692818781],
					[56.484152783263887, 2.9494807937188279],
					[56.484152826698768, 2.9494807981721087],
					[56.488113927063843, 2.9489501973330303],
					[56.488113953786232, 2.9489501914365812],
					[56.488114004891507, 2.9489501686256214],
					[56.488114029353369, 2.9489501533259124],
					[56.492900128776071, 2.9449057536417644],
					[56.492900139915804, 2.9449057420949458],
					[56.492900161222842, 2.9449057175291711],
					[56.492900169603423, 2.9449057047959109],
					[56.496680770017605, 2.9387529049742098],
					[56.496680776610781, 2.9387528925243216],
					[56.496680788745564, 2.9387528645373049],
					[56.496680794366277, 2.9387528506153053],
					[56.499539093759964, 2.9278056504074961],
					[56.49953909572676, 2.9278056354408033],
					[56.499539098846299, 2.9278056072658645],
					[56.499539099919765, 2.92780559244245],
					[56.499311300095357, 2.9209527934329689],
					[56.499311299460672, 2.9209527805115623],
					[56.49931129364532, 2.9209527537707687],
					[56.499311291223982, 2.9209527411362277],
					[56.497330791131958, 2.9144999405518388],
					[56.497330750753513, 2.914499888439654],
					[56.497330647306512, 2.9144998171632892],
					[56.497330581716824, 2.9144998016600132],
					[56.497239017204379, 2.9145080886910897],
					[56.495900177934331, 2.9118916090763407],
					[56.495900174936573, 2.9118916030472239],
					[56.495900167075014, 2.9118915896612774],
					[56.495900163183933, 2.9118915837757986],
					[56.493183462704096, 2.908069283793389],
					[56.49318345029608, 2.9080692695128638],
					[56.493183421013569, 2.9080692416703293],
					[56.493183405111822, 2.9080692295795041],
					[56.489516804714057, 2.9058109298994683],
					[56.489516768920829, 2.9058109161288903],
					[56.489516696263486, 2.9058109034076458],
					[56.489516657612739, 2.9058109047444804],
					[56.486238858394586, 2.9065220057089718],
					[56.486238827446854, 2.9065220171971005],
					[56.486238772361112, 2.9065220504694418],
					[56.486238747250283, 2.9065220707827826],
					[56.482844246690838, 2.9105526715222712],
					[56.482844238313874, 2.9105526842593443],
					[56.482844221559937, 2.9105527097334987],
					[56.482844215049056, 2.9105527237978399],
					[56.479005415444831, 2.9198166229354077],
					[56.479005412268172, 2.9198166315803902],
					[56.479005406728831, 2.9198166471126972],
					[56.479005405338825, 2.9198166554709659],
					[56.476794204930833, 2.9295193553880199],
					[56.476794202963625, 2.9295193703455937],
					[56.476794200895085, 2.9295194015887307],
				],
			],
		},
		{
			fldNpdidField: "3420717",
			wlbNpdidWellbore: "2105",
			fldName: "VOLVE",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "15/9-19 SR",
			fldDiscoveryYear: "1993",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=3420717",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=3420717",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "1092",
			cmpLongName: "ABP Norway AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=2105",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "50000045",
			fldNpdidOwner: "37817871",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Dusavik",
			fldAreaGeometryWKT: [
				[
					[58.430899801329758, 1.8751471784153413],
					[58.430899801862232, 1.8751472040826402],
					[58.430899808179269, 1.87514725263242],
					[58.43089981485501, 1.8751472753339637],
					[58.431505395540881, 1.8766417295602322],
					[58.43119420103897, 1.8798443807814493],
					[58.431194201133813, 1.8798443824805675],
					[58.43119419954116, 1.8798443862404723],
					[58.431194199730861, 1.8798443896387225],
					[58.430983099921171, 1.8836221886913365],
					[58.430983100110751, 1.8836221920895801],
					[58.43098310039516, 1.8836221971869589],
					[58.43098309969357, 1.883622200765944],
					[58.430977599953387, 1.8871278008530445],
					[58.430977601886831, 1.8871278193629066],
					[58.430977609413247, 1.8871278573591701],
					[58.430977615006199, 1.8871278768455828],
					[58.432491493427108, 1.8908111237079057],
					[58.431483103309418, 1.8966388653224933],
					[58.431483103404048, 1.8966388670217069],
					[58.431483102607459, 1.8966388689013114],
					[58.431483101716253, 1.8966388690817275],
					[58.431227602021515, 1.898261068410001],
					[58.431227601090534, 1.8982610840634619],
					[58.431227600916337, 1.8982611133104488],
					[58.431227601862389, 1.8982611303023587],
					[58.43160810223651, 1.9007972301385871],
					[58.431608105855858, 1.9007972465898353],
					[58.431608115673647, 1.9007972772522106],
					[58.431608121872088, 1.9007972914633271],
					[58.432977615795508, 1.9034417792556255],
					[58.433869212032626, 1.9059361668600434],
					[58.433869212221644, 1.9059361702587476],
					[58.433869214193102, 1.9059361732970426],
					[58.433869215273333, 1.9059361765155349],
					[58.435035914940599, 1.9087750761374609],
					[58.435035915926292, 1.908775077656738],
					[58.435035917006473, 1.9087750808754449],
					[58.435035917992167, 1.9087750823947212],
					[58.435858112406841, 1.9105778700629834],
					[58.436349808104268, 1.912227857777733],
					[58.436349815287187, 1.9122278735122504],
					[58.43634982965299, 1.9122279049812745],
					[58.436349840400872, 1.9122279199955798],
					[58.437149839693049, 1.9133001199910209],
					[58.437149870897805, 1.9133001463410753],
					[58.437149941653274, 1.9133001870436488],
					[58.43714998110957, 1.9133001996966403],
					[58.438605581045515, 1.9134362997741725],
					[58.438605612427786, 1.9134362968720062],
					[58.438605672140966, 1.9134362848093922],
					[58.438605701268713, 1.9134362737692918],
					[58.439614000874528, 1.9128473736621765],
					[58.439614012974211, 1.9128473643427204],
					[58.439614037173598, 1.9128473457038326],
					[58.439614048193228, 1.9128473331651439],
					[58.440497319226402, 1.9118751665950007],
					[58.441597269363591, 1.9114001873194115],
					[58.442905658280587, 1.911002990765623],
					[58.442905690689663, 1.9110029739019785],
					[58.442905749404815, 1.911002927657065],
					[58.442905773131628, 1.9110029005160036],
					[58.443975172592936, 1.9091390006419044],
					[58.443975176765683, 1.9091389946414332],
					[58.443975182343017, 1.9091389814812212],
					[58.44397518473329, 1.9091389758411228],
					[58.445700184839723, 1.9049583761215469],
					[58.445700185636426, 1.9049583742413352],
					[58.445700188026528, 1.904958368600689],
					[58.445700188823217, 1.9049583667204626],
					[58.446955788885276, 1.9014000665893531],
					[58.446955791180606, 1.9014000592483038],
					[58.446955794785509, 1.9014000430466544],
					[58.446955797080854, 1.9014000357056058],
					[58.447708496828632, 1.8971889355731306],
					[58.448505797026868, 1.8927861357120721],
					[58.448505797539312, 1.8927861287314209],
					[58.448505798658836, 1.8927861164701434],
					[58.448505800062478, 1.8927861093088589],
					[58.448650199867473, 1.8895444091805833],
					[58.448650200284995, 1.8895444004998772],
					[58.448650198541173, 1.8895443853805978],
					[58.448650199053418, 1.8895443783998869],
					[58.448227999377593, 1.8856582779628577],
					[58.448227998107271, 1.8856582713438061],
					[58.448227994580591, 1.8856582565865747],
					[58.448227993215475, 1.8856582482675628],
					[58.447755792704001, 1.8838527491831434],
					[58.447755791338778, 1.8838527408643237],
					[58.447755785138398, 1.8838527266500131],
					[58.447755781990772, 1.8838527186928975],
					[58.446977981547541, 1.8821277176953817],
					[58.446977978399836, 1.8821277097386127],
					[58.446977967458878, 1.8821276913296949],
					[58.446977962433962, 1.8821276820348094],
					[58.446114062247261, 1.8809415813208639],
					[58.446114035681909, 1.8809415574890822],
					[58.446113978569478, 1.8809415192293066],
					[58.446113947226131, 1.8809415066820772],
					[58.444905646619461, 1.8806470057069735],
					[58.444905613076791, 1.8806470022020239],
					[58.444905545953965, 1.8806470106711015],
					[58.444905513170113, 1.8806470207644144],
					[58.44401101308457, 1.8810804194305377],
					[58.444010991374192, 1.8810804341513694],
					[58.444010952693823, 1.8810804677878032],
					[58.444010935723853, 1.8810804867033835],
					[58.443472139055991, 1.8818580370037892],
					[58.443064067607388, 1.8816304632285863],
					[58.442639101755113, 1.8802499763385148],
					[58.442711298960901, 1.8792861151181877],
					[58.442711299757214, 1.8792861132375724],
					[58.442711299662342, 1.8792861115379031],
					[58.44271129956747, 1.8792861098382305],
					[58.442750199595189, 1.8784750096568101],
					[58.442750198646415, 1.878474992660077],
					[58.442750194871671, 1.8784749573288479],
					[58.442750191249374, 1.878474940874971],
					[58.442504516835562, 1.8776832794617939],
					[58.442630410786542, 1.8780528640459824],
					[58.442630460922821, 1.8780529226225871],
					[58.442630587852427, 1.8780529845137679],
					[58.442630722266941, 1.8780529383112543],
					[58.442630783733804, 1.8780528123802347],
					[58.442630790253062, 1.8780527354234025],
					[58.442114089848978, 1.8765166355931793],
					[58.442114087876845, 1.8765166325559062],
					[58.442114085809813, 1.8765166278189844],
					[58.442114084728836, 1.8765166246007203],
					[58.441389094315213, 1.8747527455841335],
					[58.4409390979704, 1.8717916693480372],
					[58.440939096794352, 1.8717916644304582],
					[58.440939094252329, 1.8717916511961916],
					[58.440939093076274, 1.8717916462786122],
					[58.440383493298334, 1.8697943460082462],
					[58.440383484043075, 1.8697943255447753],
					[58.440383463845201, 1.8697942866797139],
					[58.440383451120297, 1.8697942686404052],
					[58.439458450921151, 1.8687248688288993],
					[58.439458435712659, 1.8687248547329132],
					[58.439458403703362, 1.8687248303022679],
					[58.439458385916389, 1.8687248184492844],
					[58.438827932061962, 1.868424840665347],
					[58.438327961838489, 1.8677387821121707],
					[58.438327945548721, 1.8677387647993993],
					[58.438327909594584, 1.8677387342974585],
					[58.438327890916412, 1.8677387226265076],
					[58.437658390781053, 1.8673970218321778],
					[58.43765835962671, 1.8673970126983588],
					[58.437658296010795, 1.8673970032900578],
					[58.437658262658083, 1.8673970031967562],
					[58.436547162577135, 1.8676054024026221],
					[58.436547134345268, 1.8676054132984679],
					[58.436547080935064, 1.867605441343958],
					[58.436547055851776, 1.8676054601929333],
					[58.435149858039885, 1.869047058293547],
					[58.433672059971272, 1.8704915572056677],
					[58.433672059971272, 1.8704915572056677],
					[58.43367205917508, 1.8704915590859608],
					[58.433672058283925, 1.8704915592670481],
					[58.431969258403647, 1.8722081590342354],
					[58.43196924996677, 1.8722081693402282],
					[58.431969233984162, 1.8722081897711811],
					[58.431969227329589, 1.872208199715093],
					[58.431041526857669, 1.8737970988293058],
					[58.431041518895277, 1.8737971176300947],
					[58.431041505833861, 1.8737971580868855],
					[58.431041500734821, 1.8737971797428912],
					[58.430899801329758, 1.8751471784153413],
				],
			],
		},
		{
			fldNpdidField: "20474183",
			wlbNpdidWellbore: "6110",
			fldName: "HYME",
			fldCurrentActivitySatus: "Producing",
			wlbName: "6407/8-5 S",
			fldDiscoveryYear: "2009",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=20474183",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=20474183",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "348",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=6110",
			fldHcType: "OIL",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "2987047",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Kristiansund",
			fldAreaGeometryWKT: [
				[
					[64.333344199925804, 7.5075278018571128],
					[64.333380400099713, 7.5114389026648265],
					[64.333380401101792, 7.5114389129582086],
					[64.33338040310592, 7.5114389335449596],
					[64.333380405005002, 7.5114389437898978],
					[64.334427599583094, 7.5160750222028687],
					[64.334510899976522, 7.5246750002547396],
					[64.334519199728902, 7.5262389000664633],
					[64.33451920175176, 7.5262389227236444],
					[64.334519211117197, 7.5262389615448058],
					[64.334519216707349, 7.5262389819416597],
					[64.335883103955851, 7.5292972516930474],
					[64.336258101412071, 7.5331889198392119],
					[64.336258102371175, 7.5331889259972655],
					[64.336258104289456, 7.5331889383133861],
					[64.336258105248604, 7.5331889444714548],
					[64.336952605065932, 7.5362833444782638],
					[64.336952609695672, 7.5362833587206346],
					[64.336952620749258, 7.5362833871100827],
					[64.336952628090728, 7.5362834032781914],
					[64.339669203737088, 7.5408333621653441],
					[64.340058100621775, 7.546291713230513],
					[64.340058106270732, 7.5462917398422587],
					[64.340058122012707, 7.5462917886912972],
					[64.340058133899802, 7.5462918108339565],
					[64.342085934235428, 7.5493168108852462],
					[64.342085945143126, 7.5493168248018465],
					[64.34208596873215, 7.5493168504715271],
					[64.342085983207497, 7.5493168621301514],
					[64.344108123935229, 7.5507779200048244],
					[64.345488705660117, 7.556186148362702],
					[64.345488743906174, 7.5561862043289407],
					[64.34548884606582, 7.5561862797195811],
					[64.345488909979423, 7.5561862991440014],
					[64.347161054748483, 7.5561002023766788],
					[64.348511006648209, 7.5568140775414809],
					[64.348511013844828, 7.556814079235016],
					[64.348511028278864, 7.5568140867611815],
					[64.348511036372557, 7.5568140884077151],
					[64.350516620895732, 7.557494584665843],
					[64.353211005715153, 7.5589223776486341],
					[64.355897105926147, 7.5603501773022064],
					[64.355897123051065, 7.5603501846914982],
					[64.355897157239994, 7.5603501932597661],
					[64.355897174344619, 7.5603501985789592],
					[64.360244373669218, 7.5609112970391905],
					[64.360244438174377, 7.5609112853812501],
					[64.360244542578769, 7.5609112239933083],
					[64.36024458518942, 7.5609111761930565],
					[64.361550181449829, 7.5577111863036235],
					[64.362869575906529, 7.5552917954611978],
					[64.362869587955089, 7.555291743036527],
					[64.362869584284951, 7.5552916437879585],
					[64.362869569422557, 7.5552915927757054],
					[64.359480780392317, 7.5499832095456521],
					[64.357422386902883, 7.5446027291556153],
					[64.357422375933112, 7.544602709022616],
					[64.357422352240562, 7.5446026729918154],
					[64.357422336806181, 7.544602655166103],
					[64.355064061871389, 7.5423776786577301],
					[64.352683495927408, 7.5378027434848178],
					[64.352302998930355, 7.5331221831572126],
					[64.35230298320468, 7.533122136363926],
					[64.352302930349055, 7.5331220563429202],
					[64.352302894116093, 7.5331220230674019],
					[64.350961267929918, 7.5324109620154367],
					[64.350272391727998, 7.5300971420370386],
					[64.350272386221391, 7.5300971299063795],
					[64.350272373372576, 7.5300971016014939],
					[64.350272366051144, 7.5300970874969364],
					[64.347222375958182, 7.5255610028379269],
					[64.345855783123213, 7.5225027170720695],
					[64.3458557431955, 7.5225026736641345],
					[64.345855646567813, 7.5225026146634502],
					[64.345855588970835, 7.5225025991188348],
					[64.344855777198276, 7.5225553901943183],
					[64.344489098670692, 7.5194471770993303],
					[64.344489097710976, 7.5194471709399284],
					[64.344489094873637, 7.5194471566001102],
					[64.344489093913907, 7.5194471504406906],
					[64.343102994221368, 7.5140416502338878],
					[64.343102982287476, 7.514041623964566],
					[64.343102951243566, 7.5140415718130154],
					[64.343102932154494, 7.5140415479998435],
					[64.339730769669927, 7.5110942834733656],
					[64.338019587261243, 7.5064916292524355],
					[64.338019549080684, 7.506491581645296],
					[64.33801944879535, 7.5064915167056165],
					[64.338019389381572, 7.5064914992272094],
					[64.337013984420309, 7.5065469960118021],
					[64.335664047865677, 7.5050554652296739],
					[64.33566396229061, 7.5050554346871685],
					[64.335663800036528, 7.5050554538369383],
					[64.335663724275648, 7.505055505549084],
					[64.33434987768473, 7.5074748062602996],
					[64.333344389928058, 7.5075275991051624],
					[64.333344322049797, 7.5075276317536996],
					[64.333344227038097, 7.5075277320955083],
					[64.333344199925804, 7.5075278018571128],
				],
			],
		},
		{
			fldNpdidField: "23410947",
			wlbNpdidWellbore: "5517",
			fldName: "FRAM H-NORD",
			fldCurrentActivitySatus: "Producing",
			wlbName: "35/11-15 S",
			fldDiscoveryYear: "2007",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=23410947",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=23410947",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "FRAM H-NORD UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=5517",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "23410876",
			fldDateUpdated: "2022-09-13 00:00:00",
			fldDateUpdatedMax: "2022-03-02 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[61.104702600155996, 3.5006278061683913],
					[61.104702600607922, 3.5006278172467162],
					[61.104877601277401, 3.5025417177436475],
					[61.104877601352705, 3.5025417195900661],
					[61.1048776015786, 3.502541725129265],
					[61.104877601653897, 3.5025417269756685],
					[61.105141502435821, 3.504458327068515],
					[61.105141501617467, 3.5044583290704714],
					[61.105141502661631, 3.5044583326077841],
					[61.105141502661631, 3.5044583326077841],
					[61.105438703141864, 3.5062250327390032],
					[61.105438703217118, 3.5062250345854471],
					[61.105438703217118, 3.5062250345854471],
					[61.10570260313218, 3.5076972338559558],
					[61.106055403018885, 3.5099028320743764],
					[61.106055403018885, 3.5099028320743764],
					[61.106055403094089, 3.5099028339208687],
					[61.106383102910804, 3.5118167338993391],
					[61.10638310298598, 3.511816735745855],
					[61.10656089617207, 3.5127749971595352],
					[61.106255403554698, 3.5142499584288744],
					[61.106255402886511, 3.5142499641237022],
					[61.106255402293414, 3.5142499716650368],
					[61.106255400656458, 3.5142499756686099],
					[61.106133101023147, 3.5152832752645926],
					[61.106133101031048, 3.5152832975780131],
					[61.106133105364641, 3.5152833377356894],
					[61.106133107978259, 3.5152833577369074],
					[61.106460907892895, 3.516386156313049],
					[61.106460909905486, 3.5163861615422674],
					[61.106460911993167, 3.5163861686180091],
					[61.106460914005744, 3.5163861738472306],
					[61.107297010869893, 3.5185167646476532],
					[61.107716508415471, 3.5199167574238261],
					[61.107716509384183, 3.5199167591153318],
					[61.107716510578058, 3.5199167663467215],
					[61.107716511621831, 3.5199167698848481],
					[61.108313708938219, 3.5215333605372066],
					[61.10866920736553, 3.522858352686101],
					[61.108669208409225, 3.5228583562244338],
					[61.108669209602972, 3.5228583634561534],
					[61.108669210646639, 3.5228583669944933],
					[61.109058111359573, 3.5239611645684841],
					[61.109299788995955, 3.5246971987040654],
					[61.108902611351382, 3.5258777358371982],
					[61.108719212031104, 3.5263943335746295],
					[61.108719206306461, 3.5263943699014502],
					[61.108719209305463, 3.5263944437695161],
					[61.108719217135423, 3.5263944814657116],
					[61.109077616853952, 3.5272028806150519],
					[61.10907762274055, 3.5272028926126584],
					[61.109077635257471, 3.5272029127594755],
					[61.109077641962763, 3.5272029227554262],
					[61.110160924899489, 3.5285973003824069],
					[61.111058115313632, 3.5308028740362993],
					[61.111058115388552, 3.5308028758831447],
					[61.111630415057952, 3.5322000759585537],
					[61.1116304160265, 3.5322000776506535],
					[61.111630418038501, 3.5322000828817308],
					[61.111630419081926, 3.5322000864207341],
					[61.11225811466992, 3.5335222773499444],
					[61.11300541103428, 3.5355806652076631],
					[61.113724809848428, 3.5377139627114857],
					[61.114410908953801, 3.5399222590400501],
					[61.11441091096551, 3.539922264272088],
					[61.114410915063694, 3.5399222765832583],
					[61.114410917969082, 3.5399222816607239],
					[61.115038718374777, 3.5413194811431876],
					[61.115038741318202, 3.5413195143762182],
					[61.115038800236157, 3.5413195692884281],
					[61.11503883524221, 3.5413195892750169],
					[61.11588603294657, 3.5416084882358141],
					[61.116280531367167, 3.5417529877445859],
					[61.116280541421787, 3.5417529915861059],
					[61.116280562275151, 3.5417529954202385],
					[61.116280572255, 3.5417529974145867],
					[61.116791522875985, 3.5418251765987896],
					[61.117010901063871, 3.5433750234437391],
					[61.117130401171138, 3.5447750170451244],
					[61.117130403705758, 3.5447750352086813],
					[61.117130415180171, 3.5447750741489981],
					[61.117130422183152, 3.5447750915402074],
					[61.117397022196428, 3.5452889912116423],
					[61.117397031805638, 3.5452890062924602],
					[61.117397052736649, 3.54528903429794],
					[61.117397064058444, 3.5452890472226049],
					[61.118030433321756, 3.5458751179099379],
					[61.118572016105198, 3.5471278781714726],
					[61.118572017148281, 3.5471278817117513],
					[61.118572019085022, 3.5471278850976011],
					[61.118572021021762, 3.547127888483451],
					[61.119294216651532, 3.5485972813844548],
					[61.119769212957337, 3.5497750702252189],
					[61.120280408680493, 3.5512472585393891],
					[61.120727607016967, 3.5529389506405082],
					[61.120727608134523, 3.5529389560286893],
					[61.120727611263263, 3.5529389666508115],
					[61.12072761319989, 3.5529389700372627],
					[61.121177613286562, 3.554116771310357],
					[61.121177630715735, 3.5541168017894407],
					[61.121177677787166, 3.5541168531983285],
					[61.121177704823012, 3.5541168764383362],
					[61.121988780162432, 3.5545529628457779],
					[61.122805459340448, 3.5553612420292104],
					[61.122805463064346, 3.555361245107608],
					[61.122805469618505, 3.5553612514186157],
					[61.12280547423611, 3.5553612543428001],
					[61.12343877376712, 3.5558751543867744],
					[61.123438776597318, 3.5558751576194854],
					[61.123438783076857, 3.5558751620830766],
					[61.123438785832512, 3.5558751634681336],
					[61.12406928609434, 3.5563140648383804],
					[61.124069294361234, 3.5563140689937498],
					[61.124069310001317, 3.5563140774586968],
					[61.124069318342798, 3.5563140834617704],
					[61.124552718280782, 3.556530782022048],
					[61.124552830810508, 3.5565307607470702],
					[61.124552962176132, 3.5565306264478944],
					[61.124552980043816, 3.5565305117301613],
					[61.123980781668678, 3.555355515992356],
					[61.123561284411188, 3.5543971221153567],
					[61.123172390644299, 3.5534416394930699],
					[61.122905800749905, 3.5523360818772662],
					[61.122969600368585, 3.5509361093189766],
					[61.122969600219292, 3.5509361056237454],
					[61.122969599846044, 3.5509360963857013],
					[61.122969599696738, 3.5509360926904896],
					[61.122883500380574, 3.548724991944407],
					[61.122883496878075, 3.5487249720840244],
					[61.122883487192048, 3.548724932826441],
					[61.122883481008543, 3.5487249134292376],
					[61.122464084220006, 3.5478416224590208],
					[61.122105798432649, 3.5468860576222454],
					[61.122016900163267, 3.5452638896771109],
					[61.122016900088532, 3.5452638878295768],
					[61.122016898970713, 3.5452638824414451],
					[61.122016898895978, 3.5452638805939132],
					[61.12175299859166, 3.5426832804072412],
					[61.121752998367384, 3.5426832748646957],
					[61.121752997099918, 3.5426832657816889],
					[61.121752996056706, 3.5426832622412308],
					[61.121277996199609, 3.5402527628249221],
					[61.121277994038294, 3.5402527538968021],
					[61.121277988822072, 3.5402527361951401],
					[61.121277985767101, 3.540252727421624],
					[61.120586300457802, 3.5384888627332565],
					[61.120589099974225, 3.5373806009951423],
					[61.120589099600053, 3.5373805917579992],
					[61.12058909795806, 3.5373805734384169],
					[61.120589096765094, 3.537380566203395],
					[61.120383497390613, 3.5362027652336017],
					[61.120383496347273, 3.5362027616935121],
					[61.120383495154215, 3.5362027544585941],
					[61.12038349403602, 3.5362027490710952],
					[61.120175194082911, 3.5353943507226968],
					[61.120175193039536, 3.5353943471826459],
					[61.120175190952786, 3.5353943401025743],
					[61.120175189984259, 3.5353943384099176],
					[61.119933490379161, 3.5346582384259455],
					[61.119816895643638, 3.5342888573624505],
					[61.119672399370991, 3.5330388761153682],
					[61.119672397284106, 3.5330388690355568],
					[61.11967239489767, 3.5330388545663074],
					[61.119672392810806, 3.533038847486492],
					[61.11948909274598, 3.5323749467263985],
					[61.119489063839282, 3.5323748996456756],
					[61.119488984433616, 3.5323748278285625],
					[61.11948893393469, 3.5323748030921873],
					[61.119069433783444, 3.532302602801014],
					[61.119069383589292, 3.5323026077770461],
					[61.119069293036226, 3.5323026383486975],
					[61.11906925185864, 3.5323026659464429],
					[61.118733354616126, 3.5326746401454203],
					[61.118194597719004, 3.5314999512407002],
					[61.118166901760574, 3.5309083394085929],
					[61.118408480744598, 3.5303917844570423],
					[61.118408481563286, 3.5303917824548696],
					[61.118408484094395, 3.5303917782956638],
					[61.118408484913132, 3.5303917762935022],
					[61.118714085390174, 3.5296528750419212],
					[61.118714089408797, 3.5296528631836197],
					[61.118714095583762, 3.5296528379295391],
					[61.118714097740146, 3.5296528245337448],
					[61.118836290668796, 3.5286917824626989],
					[61.119202953967253, 3.5282473271615364],
					[61.119202964166249, 3.5282473123709197],
					[61.119202981808428, 3.5282472814071943],
					[61.119202989251527, 3.5282472652341133],
					[61.119380789065978, 3.5277306658577472],
					[61.119380789884637, 3.5277306638554609],
					[61.119625189893448, 3.5269917629310195],
					[61.119625193761877, 3.5269917473773518],
					[61.119625198817779, 3.5269917167350022],
					[61.119625200005267, 3.5269917016463173],
					[61.119628000312517, 3.5259611007623284],
					[61.119627998818991, 3.5259610861386355],
					[61.119627995981872, 3.525961060585963],
					[61.119627992776024, 3.5259610481196448],
					[61.119450194097496, 3.5252971516809368],
					[61.119239099740611, 3.5244138767975515],
					[61.119244600344508, 3.5234556007121318],
					[61.119244598775694, 3.5234555842414483],
					[61.119244593925778, 3.5234555534575733],
					[61.119244589676036, 3.5234555374521626],
					[61.118886290845595, 3.5223527413022007],
					[61.118347392012659, 3.5205110446959802],
					[61.118347391937597, 3.5205110428487369],
					[61.118019599287699, 3.5194082673888318],
					[61.117961299560179, 3.5180082917736621],
					[61.117961300378724, 3.5180082897712159],
					[61.117961300228544, 3.5180082860767752],
					[61.117961299259804, 3.5180082843847948],
					[61.117816900338447, 3.5160916877468202],
					[61.117758499681379, 3.5148388922243048],
					[61.117733499852115, 3.5140277939555395],
					[61.117733497163066, 3.5140277720999538],
					[61.117733486573528, 3.5140277330152014],
					[61.117733480385148, 3.5140277136281486],
					[61.117133480265295, 3.512777712685013],
					[61.117133476314969, 3.5127777040707664],
					[61.117133464839888, 3.5127776874637267],
					[61.117133460071102, 3.5127776808519857],
					[61.116802994750408, 3.5123388269591329],
					[61.116689099633575, 3.5107916898418927],
					[61.116658500572051, 3.5099806204320685],
					[61.116841893617988, 3.5092417481068461],
					[61.116841897183491, 3.5092417251640247],
					[61.116841898059199, 3.5092416803666593],
					[61.116841896262997, 3.5092416583566668],
					[61.116397395530925, 3.507180557308069],
					[61.116397377790562, 3.5071805194715369],
					[61.11639732920662, 3.5071804535197586],
					[61.116397297469462, 3.5071804255600201],
					[61.116005730861794, 3.5069637436060774],
					[61.115825242455664, 3.5067416811596028],
					[61.11625017304106, 3.5060028998561088],
					[61.116250174753013, 3.506002897697921],
					[61.116250178920041, 3.5060028895318101],
					[61.116250179738422, 3.5060028875291755],
					[61.116464080226351, 3.505558386984565],
					[61.116464086622564, 3.5055583672691144],
					[61.116464096809473, 3.5055583301519762],
					[61.116464099555984, 3.5055583092117097],
					[61.116558500311228, 3.5037139106465256],
					[61.116558499784183, 3.5037138977169118],
					[61.116558497911832, 3.5037138738603635],
					[61.116558495672884, 3.5037138630890814],
					[61.116291896282185, 3.5023166627124418],
					[61.116291895087365, 3.5023166554798211],
					[61.116291890910482, 3.5023166413259088],
					[61.116291888822005, 3.5023166342489751],
					[61.115989100865455, 3.5014332697930732],
					[61.116028000249074, 3.4995889133022233],
					[61.116241898358176, 3.4975972270340305],
					[61.116397395610981, 3.4967111383266296],
					[61.116764095150913, 3.4950889458464687],
					[61.117039094588954, 3.4939056457065374],
					[61.117039095407108, 3.4939056437035534],
					[61.117039096074443, 3.4939056380063804],
					[61.117039096892597, 3.4939056360033933],
					[61.117252996887423, 3.4927250366372147],
					[61.117252998448286, 3.4927250307840598],
					[61.117252998813768, 3.4927250176984628],
					[61.11725300037466, 3.4927250118452853],
					[61.117347400035797, 3.4910278109310258],
					[61.117347399733937, 3.4910278035426403],
					[61.117347399054765, 3.4910277869187913],
					[61.117347398752912, 3.491027779530405],
					[61.117230798968585, 3.4899221797687554],
					[61.117230795684009, 3.4899221654601331],
					[61.117230790083894, 3.4899221385339452],
					[61.117230785012119, 3.4899221245373657],
					[61.1166612846053, 3.4885277235803702],
					[61.11666128259165, 3.4885277183513268],
					[61.11666127670167, 3.4885277063583211],
					[61.116661273794413, 3.4885277012853488],
					[61.116000173836753, 3.4873527017064574],
					[61.116000171898563, 3.4873526983246181],
					[61.116000166083985, 3.4873526881790862],
					[61.116000164145802, 3.4873526847972447],
					[61.115433463802781, 3.4865470849220861],
					[61.115433459032772, 3.4865470783147128],
					[61.115433450537395, 3.4865470686378148],
					[61.115433445842896, 3.4865470638774103],
					[61.114680746298703, 3.4857415627214516],
					[61.114680715753067, 3.485741542016481],
					[61.114680651465186, 3.4857415104656435],
					[61.114680615042147, 3.4857415000881473],
					[61.113777814952471, 3.4856748012219541],
					[61.113777756051704, 3.4856748133724835],
					[61.113777657708006, 3.4856748733355229],
					[61.113777618114007, 3.4856749174542614],
					[61.113444236153484, 3.4864137785285343],
					[61.112930663819967, 3.4868607793201614],
					[61.112419591126596, 3.4858332341643559],
					[61.112214095661635, 3.484802760440501],
					[61.112214095435, 3.4848027549001817],
					[61.112214092225429, 3.4848027424411265],
					[61.112214090211637, 3.4848027372130685],
					[61.111825202945731, 3.4836249783969304],
					[61.111950198820189, 3.4824472205866099],
					[61.111950198669035, 3.482447216893108],
					[61.111950200153871, 3.4824472091936967],
					[61.111950200002724, 3.4824472055001947],
					[61.111983499951002, 3.4810444057191976],
					[61.111983454991218, 3.4810443187216999],
					[61.111983312618314, 3.4810442264365777],
					[61.111983216023219, 3.4810442191459834],
					[61.111499916439222, 3.4812692191634333],
					[61.11149988926757, 3.4812692369335152],
					[61.111499841557276, 3.4812692806136774],
					[61.111499822805754, 3.4812693062113298],
					[61.111074841276533, 3.4820831726235397],
					[61.11071379191339, 3.4823831287014206],
					[61.110230581306276, 3.4826053891962943],
					[61.109116748784572, 3.4823220061405986],
					[61.109116737016926, 3.4823220044777603],
					[61.109116710800912, 3.4823220016206631],
					[61.109116699033279, 3.4823219999578243],
					[61.108727798863555, 3.4823247995472246],
					[61.108727744657742, 3.4823248164603569],
					[61.108727655251244, 3.4823248748615336],
					[61.108727618263451, 3.4823249166619439],
					[61.108422017726056, 3.4829888158163551],
					[61.1084220153476, 3.4829888236710289],
					[61.108422008803501, 3.4829888396926827],
					[61.108422007318616, 3.4829888473912027],
					[61.108024807061476, 3.4844666483358133],
					[61.108024806318966, 3.4844666521849863],
					[61.108024804015983, 3.4844666618859796],
					[61.108024803273473, 3.4844666657351522],
					[61.107841508560647, 3.4854999342209445],
					[61.107413723352039, 3.4863138076534641],
					[61.10741371836815, 3.486313817822317],
					[61.107413710187522, 3.4863138378478897],
					[61.107413707066328, 3.4863138495511246],
					[61.106960907439543, 3.4880138487462844],
					[61.106960905060781, 3.4880138566002299],
					[61.106960902014919, 3.4880138701496137],
					[61.106960901423307, 3.488013877691547],
					[61.106802600777279, 3.4894138787709199],
					[61.10680260085276, 3.4894138806173927],
					[61.106802600185617, 3.4894138863127711],
					[61.106802600336579, 3.489413890005733],
					[61.106738701114274, 3.4906693793298564],
					[61.106460908107962, 3.4923638376296471],
					[61.106052621360774, 3.4931777105807131],
					[61.106052611618196, 3.4931777364547285],
					[61.106052602037998, 3.4931777883348114],
					[61.106052601382217, 3.4931778163431741],
					[61.106138697049126, 3.4942832758669402],
					[61.105744236803737, 3.4954637587419568],
					[61.105441610853134, 3.4956137203751978],
					[61.105441596781787, 3.4956137284069451],
					[61.105441570577035, 3.4956137478517415],
					[61.105441559261841, 3.4956137572625563],
					[61.105141595749139, 3.4959109215482878],
					[61.104869345359305, 3.4959887085884649],
					[61.104869297116124, 3.4959887393123052],
					[61.104869224918716, 3.4959888225593545],
					[61.104869201858044, 3.4959888749268049],
					[61.104774802274093, 3.4967249748169098],
					[61.104774800713024, 3.4967249806675516],
					[61.104774800347059, 3.4967249937479532],
					[61.10477479967961, 3.4967249994428435],
					[61.104769199842245, 3.4981999987960291],
					[61.104769199917598, 3.4982000006424103],
					[61.104769200068304, 3.4982000043351733],
					[61.104769200143672, 3.4982000061815528],
					[61.104796999681909, 3.4990111065506739],
					[61.104824799003431, 3.4998193887685098],
					[61.104702602299241, 3.5006277704644893],
					[61.104702600963996, 3.5006277818540843],
					[61.104702600155996, 3.5006278061683913],
				],
			],
		},
		{
			fldNpdidField: "4467554",
			wlbNpdidWellbore: "3391",
			fldName: "REV",
			fldCurrentActivitySatus: "Producing",
			wlbName: "15/12-12",
			fldDiscoveryYear: "2001",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=4467554",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=4467554",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "038 C",
			cmpLongName: "Repsol Norge AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=3391",
			fldHcType: "OIL/GAS",
			fldMainArea: "North sea",
			cmpNpdidCompany: "27052812",
			fldNpdidOwner: "4005057",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[58.020499800349086, 1.9239000002780928],
					[58.02049979997421, 1.9251999996465561],
					[58.020499808465516, 1.9252000387232397],
					[58.020499836753793, 1.9252001095383644],
					[58.020499858333423, 1.9252001409231374],
					[58.021197054035987, 1.9259001363327166],
					[58.02199983319305, 1.9268001127277192],
					[58.022399809150599, 1.9276000646093023],
					[58.022699786779803, 1.9291999462525846],
					[58.02229984367667, 1.9296998746306266],
					[58.022299828148014, 1.9296999031867845],
					[58.022299805581575, 1.9296999671078081],
					[58.022299800232709, 1.9297000004393277],
					[58.022299800293574, 1.9307999993412985],
					[58.02229979968353, 1.9308000045576348],
					[58.022299801137422, 1.930800014460186],
					[58.022299801418711, 1.9308000194998267],
					[58.022399794162823, 1.9317999485489827],
					[58.021999833684433, 1.9323998891663439],
					[58.021999823832061, 1.9323999064052064],
					[58.021999808771476, 1.9323999433593324],
					[58.021999802671935, 1.9323999632512618],
					[58.021702603028565, 1.9339999629696358],
					[58.021702601527025, 1.9339999683624722],
					[58.021702601291729, 1.9339999802981238],
					[58.021702599883923, 1.9339999873708316],
					[58.021599806297722, 1.9355998954053071],
					[58.021197080518888, 1.9358998393881184],
					[58.021197051712313, 1.9358998722692755],
					[58.021197011690511, 1.9358999464355233],
					[58.021196999583999, 1.9358999878971879],
					[58.021099799740533, 1.937499988098061],
					[58.021099800302565, 1.9374999981771355],
					[58.021099800722652, 1.9375000218715226],
					[58.021099803067358, 1.9375000315975222],
					[58.021599802836541, 1.9406000312839913],
					[58.021599803023804, 1.9406000346437489],
					[58.021599804102415, 1.9406000378270571],
					[58.021599804196043, 1.9406000395069329],
					[58.021999799635971, 1.9426000196438302],
					[58.021999799892399, 1.9432999996562632],
					[58.021999800266784, 1.9433000063759054],
					[58.02199980110916, 1.9433000214950951],
					[58.021999802374935, 1.9433000280383144],
					[58.022299801854253, 1.9454000278506924],
					[58.022299805370459, 1.9454000424410243],
					[58.022299813200696, 1.9454000697654024],
					[58.022299817608271, 1.9454000841793884],
					[58.022899817597448, 1.9467000833715939],
					[58.022899819473736, 1.9467000846989126],
					[58.022899818582374, 1.9467000848752338],
					[58.023602608731011, 1.9482000642502424],
					[58.023799805292782, 1.9491000422026004],
					[58.023799809887016, 1.9491000599776869],
					[58.023799824330155, 1.9491000927902513],
					[58.023799832396321, 1.9491001081802619],
					[58.024897031842279, 1.9508001075745403],
					[58.024897077246145, 1.9508001461574005],
					[58.024897180632877, 1.9508001902620935],
					[58.024897238615672, 1.9508001957839411],
					[58.025397152376016, 1.9507002133874047],
					[58.02579987227098, 1.9510001542012942],
					[58.027199864233637, 1.952300147551409],
					[58.027199923338266, 1.9523001732379845],
					[58.027200045636967, 1.9523001847334134],
					[58.027200108737588, 1.9523001688620534],
					[58.028900108919217, 1.9512001673383197],
					[58.028900116754578, 1.951200162391552],
					[58.028900134021036, 1.9512001487849904],
					[58.028900140777992, 1.951200140653943],
					[58.030300112793412, 1.9498001687607514],
					[58.031502854088863, 1.9493001945438873],
					[58.032799963178824, 1.9491002084440185],
					[58.033699902836716, 1.9496001757637456],
					[58.033699938628871, 1.9496001873724473],
					[58.033700013921823, 1.9496001962644083],
					[58.033700051639855, 1.949600193900298],
					[58.03480285168019, 1.9493001938307306],
					[58.034802884099605, 1.949300177223054],
					[58.034802938759299, 1.9493001392252807],
					[58.034802964471474, 1.9493001154493144],
					[58.036000163927724, 1.9476001142496555],
					[58.03600016810389, 1.9476001083259782],
					[58.036000174673489, 1.9476000968313829],
					[58.036000178756076, 1.9476000892271155],
					[58.037497378798861, 1.944600089750075],
					[58.037497380487963, 1.9446000877164935],
					[58.037497381989901, 1.9446000823216192],
					[58.03749738278767, 1.9446000804645076],
					[58.038600180268475, 1.942100087416945],
					[58.040200176334217, 1.9391000944984706],
					[58.040200180322593, 1.9391000852115896],
					[58.040200188393023, 1.939100068318576],
					[58.040200190598739, 1.9391000593849506],
					[58.040797387073773, 1.9372000703269061],
					[58.041697381558713, 1.9352000818589052],
					[58.041697389347419, 1.9352000599219064],
					[58.041697397700396, 1.935200015780983],
					[58.041697399953598, 1.9351999915428042],
					[58.041600199655633, 1.9326999914200254],
					[58.041600199280609, 1.9326999846968584],
					[58.041600197545463, 1.9326999697465426],
					[58.041600196279099, 1.9326999632001907],
					[58.041200196468345, 1.9305999636089899],
					[58.04120018577315, 1.9305999334429411],
					[58.041200154765654, 1.9305998784177192],
					[58.041200136236043, 1.9305998532048341],
					[58.039902935739669, 1.9293998528259708],
					[58.039902930110287, 1.929399848845075],
					[58.039902918757754, 1.9293998392026064],
					[58.03990291401972, 1.9293998350448398],
					[58.038600119842712, 1.9284998405804612],
					[58.037602937856327, 1.9276998547786408],
					[58.036800148905918, 1.9267998678001605],
					[58.036800115926823, 1.9267998420617198],
					[58.036800041102339, 1.9267998093363208],
					[58.036800000054455, 1.9267998004918885],
					[58.035599999808106, 1.9267997993152661],
					[58.035599989112207, 1.9267998014381034],
					[58.035599969409255, 1.9267998036494636],
					[58.035599957728223, 1.9267998042687191],
					[58.034199958238396, 1.9270998040524279],
					[58.03419995378178, 1.9270998049368557],
					[58.034199945759845, 1.9270998065288298],
					[58.034199943179694, 1.9270998087399229],
					[58.033200063253915, 1.9273997712989761],
					[58.032200169261728, 1.9262998877774518],
					[58.031600202357453, 1.9249999610131212],
					[58.031700199550066, 1.9229000136323851],
					[58.031800190127576, 1.9218001185059437],
					[58.033100105060207, 1.9210001707358468],
					[58.033100117162981, 1.9210001615360248],
					[58.033100138694586, 1.9210001436674922],
					[58.033100149718258, 1.9210001312840119],
					[58.034600149730053, 1.9193001312466351],
					[58.034600175386231, 1.9193000581864079],
					[58.034600161866734, 1.9192999130530095],
					[58.034600124661587, 1.9192998439865014],
					[58.034100124766077, 1.9188998435594895],
					[58.034100074944625, 1.918899822875316],
					[58.034099972348045, 1.9188998092786553],
					[58.034099918681648, 1.9188998165432187],
					[58.032299919375355, 1.9196998162938452],
					[58.032299916795353, 1.9196998185053127],
					[58.032299913230091, 1.9196998192135466],
					[58.032299910650075, 1.9196998214250331],
					[58.030900101347711, 1.9203997265984651],
					[58.030603003087485, 1.9196999652044631],
					[58.030700199760233, 1.9182000133368504],
					[58.030700190796878, 1.9181999658498936],
					[58.030700150775388, 1.9181998786635077],
					[58.030700119811158, 1.9181998406443022],
					[58.030300119808814, 1.917899839253453],
					[58.030300052958452, 1.917899820256957],
					[58.03029992188813, 1.9178998293097556],
					[58.030299858653407, 1.9178998588621314],
					[58.02929998992434, 1.9188997279643512],
					[58.028600193860662, 1.9182999021919134],
					[58.028500203560768, 1.9167000647849566],
					[58.029300106753823, 1.9157001865946646],
					[58.030700028373843, 1.9155001985207432],
					[58.030700073266452, 1.9155001794047801],
					[58.030700147757145, 1.9155001255248816],
					[58.030700179043841, 1.9155000887264355],
					[58.031100179338999, 1.914700088639766],
					[58.031100181636972, 1.9147000813873158],
					[58.031100188015515, 1.914700066528084],
					[58.031100191204771, 1.914700059098464],
					[58.031502971194747, 1.9134001229161732],
					[58.03220011695182, 1.9129001627031412],
					[58.032200118640404, 1.9129001606684228],
					[58.03220012380018, 1.9129001562445034],
					[58.032200127365364, 1.9129001555356022],
					[58.03329732694592, 1.9120001542845482],
					[58.034400125944096, 1.9111001543518642],
					[58.034400128618003, 1.9111001538200072],
					[58.034400132886397, 1.9111001495728399],
					[58.034400135466235, 1.9111001473606155],
					[58.035600134758688, 1.9100001462394238],
					[58.035600154317898, 1.9100001253567869],
					[58.0356001830678, 1.9100000754594968],
					[58.035600194135135, 1.9100000477706174],
					[58.036097393730309, 1.9080000485996398],
					[58.036097386213889, 1.9079999787306077],
					[58.036097328354707, 1.9079998628078036],
					[58.036097277026542, 1.9079998152509947],
					[58.034900077006775, 1.9074998141681132],
					[58.034900019117678, 1.9074998103968717],
					[58.03489991028659, 1.9074998303566468],
					[58.034899858735564, 1.9074998593061907],
					[58.033899859327953, 1.9084998584917372],
					[58.033899856748164, 1.9084998607041086],
					[58.033899853371139, 1.9084998647741731],
					[58.033899851682634, 1.9084998668092041],
					[58.032902651872519, 1.9095998665903899],
					[58.032902644227114, 1.9095998749073773],
					[58.032902630015684, 1.9095998947246686],
					[58.032902624246908, 1.9095999043673471],
					[58.032199851961003, 1.9108998537790414],
					[58.031299954757159, 1.9113997963713081],
					[58.030000014745475, 1.9112998009233306],
					[58.030000006629791, 1.9112998008384185],
					[58.029999988615842, 1.9112998010230917],
					[58.029999979608867, 1.9112998011154105],
					[58.027999997980963, 1.9114997987789939],
					[58.026700043742707, 1.9113998025765107],
					[58.025902870235896, 1.9110998130053303],
					[58.025902869250579, 1.9110998115025857],
					[58.025902864700065, 1.9110998107087478],
					[58.02590286282345, 1.9110998093832285],
					[58.025300063055852, 1.9108998093920526],
					[58.02529999733315, 1.9108998105700576],
					[58.025299879027962, 1.9108998544779228],
					[58.025299828322126, 1.9108998985332766],
					[58.025002627728149, 1.9113998985606282],
					[58.025002618254881, 1.9113999225263374],
					[58.025002604046875, 1.9113999746114618],
					[58.025002600015405, 1.911399999193818],
					[58.025002599632501, 1.9132999923426759],
					[58.024897002086142, 1.9148999564227025],
					[58.024699887622248, 1.9153997392786006],
					[58.024200074359456, 1.9151998131841546],
					[58.024200029839868, 1.9151998067435037],
					[58.024199943710933, 1.9151998136669128],
					[58.024199901116305, 1.9151998255281624],
					[58.023497101400167, 1.9155998258782057],
					[58.023497073818078, 1.9155998483441419],
					[58.023497029022039, 1.9155999014072003],
					[58.023497011808075, 1.9155999320043247],
					[58.023102622986002, 1.9166999017781507],
					[58.022699886050106, 1.9171998227913989],
					[58.022299950622603, 1.9172998064500026],
					[58.022299914642637, 1.9172998237879393],
					[58.022299853332548, 1.9172998716347274],
					[58.022299826125881, 1.9172999008178646],
					[58.021899849706195, 1.9179998605861417],
					[58.021599888682715, 1.9181998344813525],
					[58.021599852422831, 1.9181998790488239],
					[58.021599810349485, 1.9181999808198613],
					[58.021599803738624, 1.9182000398802546],
					[58.021799791108897, 1.9191999767429506],
					[58.021399845693615, 1.9200998532018732],
					[58.020802709744906, 1.920399820828548],
					[58.0208026622727, 1.9203998744123498],
					[58.020802613302408, 1.920399997928472],
					[58.020802612789574, 1.9204000693635848],
					[58.021099804864598, 1.9212000484535203],
					[58.021196996197894, 1.9218999861656128],
					[58.02099980961831, 1.9225999378131102],
					[58.020499813436238, 1.9238999280470337],
					[58.020499808135526, 1.9238999460828174],
					[58.02049980199066, 1.923899981270031],
					[58.020499800349086, 1.9239000002780928],
				],
			],
		},
		{
			fldNpdidField: "4999528",
			wlbNpdidWellbore: "5485",
			fldName: "TAMBAR ØST",
			fldCurrentActivitySatus: "Producing",
			wlbName: "1/3-K-5",
			fldDiscoveryYear: "2007",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=4999528",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=4999528",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "TAMBAR ØST UNIT",
			cmpLongName: "Aker BP ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=5485",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "28544099",
			fldNpdidOwner: "4999504",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2021-12-22 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[56.954783099645383, 3.028161113875012],
					[56.954974800255016, 3.0308972147352624],
					[56.954974802824978, 3.0308972308002442],
					[56.95497481064524, 3.0308972625002872],
					[56.954974815895532, 3.0308972781353352],
					[56.955794216028181, 3.0328389787305752],
					[56.955794216999912, 3.0328389802225026],
					[56.955794219099936, 3.0328389864767771],
					[56.955794221043398, 3.032838989460632],
					[56.956710921325922, 3.0346944893588654],
					[56.95671097409825, 3.0346945385714776],
					[56.956711099709693, 3.0346945859935572],
					[56.956711172627045, 3.034694585838289],
					[56.957386029385397, 3.0344308414421444],
					[56.958349837798515, 3.035755718454848],
					[56.958349845337374, 3.0357557254857115],
					[56.958349859678115, 3.0357557429613493],
					[56.958349868188677, 3.0357557514843312],
					[56.959360967559178, 3.0366390503740597],
					[56.959360970317775, 3.0366390515798218],
					[56.959360974048053, 3.0366390542777646],
					[56.959360975991437, 3.0366390572620965],
					[56.960472076307248, 3.0375223573501398],
					[56.960472102184461, 3.0375223713305419],
					[56.960472158171527, 3.0375223936691258],
					[56.960472187309662, 3.0375224005350812],
					[56.961919371025161, 3.0376140988503755],
					[56.963461055239087, 3.037966894349573],
					[56.963461095835697, 3.037966896083653],
					[56.963461174303333, 3.0379668802115383],
					[56.963461212330813, 3.0379668658764492],
					[56.964764011554287, 3.0370862658197066],
					[56.965680711599042, 3.0364695656190177],
					[56.965680717696728, 3.0364695613450383],
					[56.965680731600749, 3.0364695508749366],
					[56.96568073762019, 3.0364695449653114],
					[56.966791837728756, 3.0354112451039206],
					[56.966791846271967, 3.0354112354927274],
					[56.966791862464937, 3.0354112164136238],
					[56.966791869142995, 3.0354112054532836],
					[56.967900169004992, 3.0336445053211958],
					[56.967900179307748, 3.0336444196054684],
					[56.967900126305054, 3.0336442715198388],
					[56.967900063077899, 3.0336442107856576],
					[56.96683900682811, 3.0332915257990587],
					[56.966019593853595, 3.0324943093903616],
					[56.965972401446272, 3.0317889009252932],
					[56.966019599175503, 3.0311694154737414],
					[56.966019599207499, 3.0311693973382514],
					[56.966019594804266, 3.0311693617841251],
					[56.966019592077615, 3.0311693424430901],
					[56.965730793191973, 3.0301999463265372],
					[56.965394595063572, 3.0288749532569459],
					[56.965200199723284, 3.02799167880034],
					[56.965200199693115, 3.0264889138952928],
					[56.965394592127566, 3.025077868608725],
					[56.965827970067977, 3.0243723058123702],
					[56.965827982215046, 3.0243722774895003],
					[56.965827996681391, 3.0243722224226106],
					[56.965827999894138, 3.0243721955350562],
					[56.965780800273379, 3.0225167076219512],
					[56.965975198703674, 3.0203972185682399],
					[56.965975198625216, 3.0203972169326287],
					[56.965975199361736, 3.0203972135177675],
					[56.965975200255173, 3.0203972133741366],
					[56.966119596001931, 3.0181889656016985],
					[56.966989067148084, 3.0168668106612215],
					[56.966989067884505, 3.016866807246215],
					[56.966989069592842, 3.0168668053231373],
					[56.966989071301178, 3.0168668034000579],
					[56.968436268669784, 3.0144807063854677],
					[56.969883450286034, 3.0122751359779207],
					[56.970750125684468, 3.0115695543524854],
					[56.970750146340606, 3.0115695345417648],
					[56.970750177432478, 3.0115694883238762],
					[56.97075018965505, 3.0115694616289583],
					[56.971330790280263, 3.0098028611797076],
					[56.971330791831285, 3.0098028559844323],
					[56.971330795669559, 3.0098028421783583],
					[56.971330797063381, 3.0098028337114568],
					[56.971716893350951, 3.0075944598875495],
					[56.972680777649401, 3.0054750924011215],
					[56.973936240950422, 3.0033557545970817],
					[56.974850086690651, 3.0029140793839546],
					[56.974850112863031, 3.0029140619735251],
					[56.974850159296565, 3.002914016565561],
					[56.974850177849618, 3.0029139904921944],
					[56.975622375964853, 3.0014111946370532],
					[56.976925168637258, 2.9991168075185266],
					[56.977794540144956, 2.9978779486689806],
					[56.978372304321077, 2.9975251703751238],
					[56.978372320008219, 2.9975251595983088],
					[56.97837234928069, 2.9975251317888101],
					[56.978372362130159, 2.9975251181725531],
					[56.979144562321864, 2.9964640177281412],
					[56.979144564029873, 2.9964640158034777],
					[56.979144568181674, 2.9964640085376368],
					[56.979144569810884, 2.9964640049768616],
					[56.980397347929092, 2.9944334408772986],
					[56.981700112357146, 2.9935501658634611],
					[56.981700134718032, 2.99355014411175],
					[56.981700172949672, 2.9935500967101696],
					[56.981700187848219, 2.993550069568423],
					[56.982280778707754, 2.9919583914705257],
					[56.983244564884998, 2.9905445128216983],
					[56.9832445691153, 2.9905445071906631],
					[56.983244574895764, 2.990544496361923],
					[56.983244578153837, 2.9905444892390469],
					[56.984497377856755, 2.9880722895419343],
					[56.984497380457967, 2.9880722874720993],
					[56.984497382822511, 2.9880722804932738],
					[56.984497383636977, 2.9880722787124463],
					[56.98531688134328, 2.9861278848806494],
					[56.9861362702799, 2.9845362066255379],
					[56.987005758603907, 2.9833890210470799],
					[56.987005765434986, 2.9833890133443459],
					[56.987005775365773, 2.9833889952445558],
					[56.987005780331174, 2.9833889861946581],
					[56.987391880244118, 2.9825917871358527],
					[56.987391886601337, 2.982591769614277],
					[56.987391895663364, 2.9825917335132623],
					[56.987391899261532, 2.9825917147891881],
					[56.987489096163991, 2.9812667573317988],
					[56.988308472695117, 2.9797639993341183],
					[56.989225170142213, 2.9782612052112407],
					[56.989900168847399, 2.9772001070707717],
					[56.989900177804834, 2.9772000874761892],
					[56.989900193039659, 2.9772000487214068],
					[56.989900198423683, 2.9772000297059993],
					[56.990091894436851, 2.9758750576320416],
					[56.991344582062681, 2.9731361829539411],
					[56.99134458198359, 2.9731361813173587],
					[56.991344582797858, 2.9731361795358784],
					[56.991344583612111, 2.9731361777543972],
					[56.992550181725576, 2.9703056838476751],
					[56.994430777904164, 2.9665056911548677],
					[56.995589077296351, 2.9642944935923232],
					[56.995589082181056, 2.964294482901062],
					[56.99558909024298, 2.9642944634455541],
					[56.99558909326182, 2.9642944514078202],
					[56.996069593234566, 2.9625278513302895],
					[56.996069592644098, 2.9625278019371644],
					[56.996069569129986, 2.9625277067804006],
					[56.996069545313027, 2.9625276611619409],
					[56.9957307453505, 2.9621720622874008],
					[56.995730676290712, 2.96217203062101],
					[56.99573053558467, 2.9621720254453394],
					[56.995730464910942, 2.962172053427619],
					[56.995344265450115, 2.9625276530781335],
					[56.99534425171155, 2.9625276668580289],
					[56.995344229752817, 2.9625276968201946],
					[56.995344221453429, 2.9625277113657691],
					[56.994863737815571, 2.9634998768245127],
					[56.993272067403801, 2.9649165504497237],
					[56.99327206561712, 2.9649165507399391],
					[56.993272064802994, 2.9649165525217009],
					[56.993272064802994, 2.9649165525217009],
					[56.991730464736044, 2.9663304536199773],
					[56.991730457012778, 2.9663304614718746],
					[56.991730441645409, 2.9663304788122318],
					[56.99173043481548, 2.9663304865190625],
					[56.990330434587108, 2.9683637872458712],
					[56.990330431251223, 2.9683637927354649],
					[56.990330422951097, 2.9683638072777425],
					[56.990330418801015, 2.9683638145488689],
					[56.989124827652574, 2.9709276954471142],
					[56.987485942393363, 2.9730498778970302],
					[56.987485938163914, 2.9730498835306594],
					[56.987485929705016, 2.9730498947979349],
					[56.987485926368912, 2.9730499002867008],
					[56.986233126044262, 2.975258201259412],
					[56.986233124415669, 2.9752582048217797],
					[56.986233121158527, 2.9752582119465227],
					[56.986233118636605, 2.9752582156537222],
					[56.984738727751704, 2.9784415952439396],
					[56.982952653676008, 2.9807387623399491],
					[56.981119269443795, 2.9823304494443788],
					[56.981119261640437, 2.9823304556544552],
					[56.981119249686124, 2.9823304691323118],
					[56.981119242776124, 2.9823304751977671],
					[56.979433161427515, 2.9844498534465975],
					[56.977938814065986, 2.9855109156048978],
					[56.976924948904774, 2.9857776070823734],
					[56.976924924206116, 2.9857776176727926],
					[56.976924876017861, 2.9857776452530587],
					[56.976924855208374, 2.9857776618093683],
					[56.975669255076198, 2.9871026627759738],
					[56.973644254808903, 2.989222062335366],
					[56.973644243825973, 2.9892220772996119],
					[56.973644223725756, 2.9892221085751336],
					[56.973644214608463, 2.9892221248864121],
					[56.972777615144487, 2.991341623844904],
					[56.972777614408791, 2.9913416272609057],
					[56.972777611965192, 2.9913416326014648],
					[56.972777611150647, 2.9913416343816452],
					[56.972197014944669, 2.9930221227932159],
					[56.970897033236881, 2.9956720853324099],
					[56.969013753121779, 2.9977026631729347],
					[56.969013752385905, 2.9977026665884718],
					[56.969013748076442, 2.9977026705807615],
					[56.969013746368425, 2.9977026725048268],
					[56.967616589089666, 2.9993804213371793],
					[56.966505553810698, 2.9996442055608257],
					[56.966505532526746, 2.9996442122910314],
					[56.966505492139234, 2.9996442336410349],
					[56.966505472878232, 2.9996442449896996],
					[56.965638772990324, 3.0003526454757492],
					[56.965638772096938, 3.0003526456198539],
					[56.965638770388864, 3.0003526475436013],
					[56.965638768680797, 3.0003526494673447],
					[56.964527669074286, 3.0013220489311441],
					[56.96452765622427, 3.0013220625407282],
					[56.964527634097813, 3.0013220891835921],
					[56.964527624006678, 3.0013221039964502],
					[56.963758124078474, 3.0027360036621715],
					[56.963758119190189, 3.0027360143391744],
					[56.96375811038569, 3.0027360371846199],
					[56.963758105576083, 3.0027360494970852],
					[56.962888705543328, 3.0060916496997354],
					[56.962888704149719, 3.006091658164816],
					[56.962888700626351, 3.0060916785097986],
					[56.962888700204786, 3.0060916884663587],
					[56.962694199637419, 3.0095360892759437],
					[56.962694199794598, 3.0095360925468126],
					[56.962694200187578, 3.0095361007239938],
					[56.962694200266164, 3.0095361023594296],
					[56.962744200473871, 3.0127139036882737],
					[56.962744199580456, 3.0127139038320676],
					[56.962791497797546, 3.0153638093910335],
					[56.961247070243651, 3.0166859483451609],
					[56.961247053975129, 3.0166859657938221],
					[56.961247026955618, 3.0166860030998164],
					[56.961247015311216, 3.0166860231008235],
					[56.960474815434125, 3.0185388227641545],
					[56.960474812331285, 3.0185388331509184],
					[56.960474805389083, 3.0185388573388172],
					[56.960474803179665, 3.0185388675819391],
					[56.960088702607948, 3.0209249682901413],
					[56.960088701871406, 3.0209249717044471],
					[56.960088701291745, 3.0209249783894458],
					[56.960088700555204, 3.0209249818037511],
					[56.959847002074007, 3.0235721698607514],
					[56.959219207542851, 3.0264860425723596],
					[56.958447034983116, 3.0285165697074765],
					[56.957288838051426, 3.029308099383913],
					[56.956083375679086, 3.0293080996840382],
					[56.954783432117033, 3.0281609501224014],
					[56.954783321550401, 3.0281609349118965],
					[56.954783156247196, 3.0281610174623941],
					[56.954783099645383, 3.028161113875012],
				],
			],
		},
		{
			fldNpdidField: "29398828",
			wlbNpdidWellbore: "5102",
			fldName: "TROLL BRENT B",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "31/2-N-11 H",
			fldDiscoveryYear: "2005",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=29398828",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=29398828",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "054",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=5102",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "21228",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "Mongstad",
			fldAreaGeometryWKT: [
				[
					[60.911719201789218, 3.534402819248077],
					[60.911719204548, 3.5344028428003127],
					[60.912280404877407, 3.5369389426613318],
					[60.912280405995155, 3.5369389480140105],
					[60.912280409124364, 3.5369389585659907],
					[60.912280410167433, 3.5369389620833269],
					[60.913002603953863, 3.5391417421205067],
					[60.913238699645561, 3.5411639086338527],
					[60.913044199813264, 3.5463777933987255],
					[60.913044199813264, 3.5463777933987255],
					[60.913044199962393, 3.5463777970695851],
					[60.913044200036971, 3.5463777989050116],
					[60.913021999605057, 3.5502499988545688],
					[60.913022001169338, 3.5502500152205432],
					[60.913022007053556, 3.5502500493288527],
					[60.913022011298978, 3.5502500652357338],
					[60.913666511035068, 3.5521139656755132],
					[60.913666538814709, 3.5521140071263488],
					[60.913666611652168, 3.5521140722845788],
					[60.913666655741864, 3.5521140943094731],
					[60.914486056072938, 3.5522973948175491],
					[60.914486073276827, 3.5522973974175969],
					[60.914486104854497, 3.5522973994055516],
					[60.914486121909405, 3.552297398334523],
					[60.915794421829446, 3.552155798178807],
					[60.915794473217503, 3.5521557782917776],
					[60.915794556853207, 3.5521557103721908],
					[60.915794589175363, 3.552155664175217],
					[60.916711259333226, 3.5494807536935862],
					[60.917941724768021, 3.5490002045066813],
					[60.919413872545618, 3.5491973974560573],
					[60.919413878876071, 3.5491973982201999],
					[60.919413889749542, 3.5491974000546929],
					[60.91941389607998, 3.5491974008188363],
					[60.920399872229694, 3.5492168984466015],
					[60.921366519411571, 3.5512556852678099],
					[60.921366555309383, 3.551255727186037],
					[60.921366644010575, 3.5512557840944527],
					[60.921366695920256, 3.5512557992377056],
					[60.922102666394856, 3.5512695986717802],
					[60.923316516303778, 3.5541556770664777],
					[60.923316521293572, 3.5541556891539039],
					[60.923316534847984, 3.5541557127168537],
					[60.923316541550726, 3.5541557226622773],
					[60.924777630137505, 3.5560390076201491],
					[60.926155415071534, 3.5587611766446297],
					[60.92703870662352, 3.5614722521982629],
					[60.927835904224168, 3.5651944403349307],
					[60.928474802767738, 3.5685750372943237],
					[60.928474803810019, 3.5685750408144843],
					[60.928474805968825, 3.568575049691213],
					[60.9284748079791, 3.5685750548951662],
					[60.929194192981598, 3.5711167037191256],
					[60.928863718943177, 3.5724581984700898],
					[60.927380457919696, 3.5739442581997198],
					[60.92738044339395, 3.5739442773184571],
					[60.927380418139961, 3.5739443204551842],
					[60.927380408305496, 3.5739443443206964],
					[60.926544208230226, 3.5767888432050601],
					[60.926544204128483, 3.5767888753363946],
					[60.926544205681957, 3.576788936086738],
					[60.926544212305075, 3.5767889663896772],
					[60.927269212378491, 3.5788250677998588],
					[60.927269276937935, 3.5788251289078556],
					[60.927269430945962, 3.5788251766207981],
					[60.927269520246256, 3.5788251595530576],
					[60.927924908043217, 3.578333619521902],
					[60.928572012541743, 3.5800333705018001],
					[60.928572021326616, 3.5800333874954959],
					[60.928572039641864, 3.5800334176577353],
					[60.928572051033846, 3.5800334323581411],
					[60.929794251364591, 3.581405732629769],
					[60.92979432292934, 3.5814057666662658],
					[60.929794467934194, 3.5814057697003743],
					[60.929794542342151, 3.5814057403822095],
					[60.930449978753309, 3.5807447063904982],
					[60.931347039858807, 3.5819445201299001],
					[60.931347074335235, 3.5819445493918778],
					[60.931347152452481, 3.5819445897116893],
					[60.931347196093313, 3.5819446007695381],
					[60.931919395990093, 3.5819557998865457],
					[60.931919450286472, 3.5819557850890966],
					[60.931919540786623, 3.5819557308376195],
					[60.931919578703827, 3.581955689242454],
					[60.933164072334471, 3.5794557020428956],
					[60.93398626275075, 3.5782918151711192],
					[60.933986272062931, 3.578291800637881],
					[60.933986287038159, 3.5782917703441859],
					[60.933986292627068, 3.5782917527469666],
					[60.934822381011863, 3.5752778956842581],
					[60.935813989552805, 3.5741196014009216],
					[60.93679435328756, 3.5741390986316817],
					[60.938266613812871, 3.5748445804826599],
					[60.938266648368412, 3.5748445893836402],
					[60.938266717034352, 3.5748445961637909],
					[60.938266752932215, 3.5748445937379558],
					[60.939413953032378, 3.5745307925916401],
					[60.939413990049928, 3.5745307733269578],
					[60.939414052766594, 3.5745307219314149],
					[60.939414079359459, 3.5745306896480447],
					[60.940241879365558, 3.5728611893378126],
					[60.940241885847428, 3.5728611715836007],
					[60.940241896204228, 3.5728611383699427],
					[60.940241899036934, 3.5728611193889037],
					[60.940414080333056, 3.5711808035418473],
					[60.941563896190601, 3.5712029992475958],
					[60.941563965381917, 3.5712029744834202],
					[60.941564066084915, 3.5712028832938292],
					[60.941564099384102, 3.5712028165631913],
					[60.941739099042593, 3.5691861172233668],
					[60.941739099639243, 3.5691861097220885],
					[60.941739099938793, 3.569186094872169],
					[60.941739099567435, 3.5691860856863973],
					[60.941508499812691, 3.5658110857567684],
					[60.94150849527167, 3.5658110624851309],
					[60.941508480901582, 3.5658110186956273],
					[60.941508471966245, 3.5658109980249755],
					[60.940697379819746, 3.5644443113678226],
					[60.940211297380891, 3.5632555534088435],
					[60.93998629983632, 3.5598805867411829],
					[60.939986297676896, 3.55988057786191],
					[60.939986295145495, 3.5598805597975067],
					[60.939986292911676, 3.5598805490812082],
					[60.939586293078349, 3.5583555492632004],
					[60.939586280120551, 3.5583555181857967],
					[60.939586243778095, 3.5583554652145475],
					[60.939586222106435, 3.5583554411777754],
					[60.938689022184491, 3.5576637428253468],
					[60.938688987255908, 3.5576637247582519],
					[60.938688914419281, 3.5576637039315546],
					[60.938688874723859, 3.5576637014778862],
					[60.937541698105363, 3.5578080984479343],
					[60.935577927840079, 3.5575998117035428],
					[60.934689077192942, 3.5558971075688564],
					[60.934689058127603, 3.5558970812415858],
					[60.934689012251283, 3.5558970373109169],
					[60.934688986334038, 3.5558970195545099],
					[60.933952958201118, 3.555547054202655],
					[60.932902987007552, 3.5528305275027408],
					[60.932902983060295, 3.552830518932073],
					[60.932902974272061, 3.5528305019438289],
					[60.93290297032479, 3.5528304933731638],
					[60.930875169822841, 3.5495887941390296],
					[60.930875154629256, 3.5495887745518817],
					[60.930875120890875, 3.5495887414996554],
					[60.930875102346079, 3.5495887280345415],
					[60.92793622646181, 3.5478470427483431],
					[60.92549454495682, 3.5452748627059743],
					[60.925494507941053, 3.5452748376211995],
					[60.925494426612147, 3.5452748071922984],
					[60.925494383192685, 3.54527480169493],
					[60.923858375240457, 3.5454108923896364],
					[60.921414054208199, 3.5430054702769693],
					[60.919872369845578, 3.5407859954384575],
					[60.917358474848683, 3.5361943034955599],
					[60.917358470006761, 3.5361942950845342],
					[60.917358456673455, 3.5361942770406292],
					[60.917358449969441, 3.5361942671008508],
					[60.914347350432003, 3.5327692677334017],
					[60.914347333673078, 3.5327692539776225],
					[60.914347300229927, 3.5327692283015151],
					[60.914347281758381, 3.5327692166882287],
					[60.912388981573223, 3.5318915164096851],
					[60.912388897937994, 3.531891517840958],
					[60.912388760243928, 3.5318915840054745],
					[60.912388707078769, 3.531891648585157],
					[60.911719206992792, 3.5344027481247684],
					[60.911719202602114, 3.534402772904687],
					[60.911719201789218, 3.534402819248077],
				],
			],
		},
		{
			fldNpdidField: "4973114",
			wlbNpdidWellbore: "5562",
			fldName: "YTTERGRYTA",
			fldCurrentActivitySatus: "Shut down",
			wlbName: "6507/11-8",
			fldDiscoveryYear: "2007",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=4973114",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=4973114",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "062",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=5562",
			fldHcType: "GAS",
			fldMainArea: "Norwegian sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "21292",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[65.101027602010333, 7.5126888730245698],
					[65.101027603245242, 7.5126889070193865],
					[65.101027617353921, 7.5126889722297436],
					[65.101027629330773, 7.5126890034954137],
					[65.101341503443422, 7.5132056617803897],
					[65.101502600321254, 7.5158583115929805],
					[65.101502601302471, 7.5158583200543569],
					[65.101502604182969, 7.5158583390549856],
					[65.101502606082207, 7.5158583495942191],
					[65.102027606436252, 7.5179583485593646],
					[65.10202760921122, 7.5179583569211124],
					[65.102027613864223, 7.5179583736945848],
					[65.102027618433056, 7.5179583819564577],
					[65.102494208400628, 7.5189944595038858],
					[65.102760903061693, 7.520477835574316],
					[65.102760904939586, 7.5204778439864306],
					[65.10276091142822, 7.520477864916999],
					[65.102760914203046, 7.5204778732792592],
					[65.103424806090615, 7.5221361542304157],
					[65.103683103132425, 7.5237194325328254],
					[65.103683104092283, 7.5237194388671593],
					[65.103683106929822, 7.5237194536141505],
					[65.103683108786555, 7.5237194598987429],
					[65.104010908823653, 7.5247667595225449],
					[65.104010909762465, 7.5247667637289428],
					[65.104010911619184, 7.5247667700137031],
					[65.104010913433982, 7.5247667720423346],
					[65.104722013223935, 7.5266083712349188],
					[65.104722017834149, 7.5266083837553603],
					[65.104722028785616, 7.5266084023126005],
					[65.104722035168749, 7.5266084126056265],
					[65.105072022437284, 7.5271194942259312],
					[65.105369214085215, 7.5278861714136909],
					[65.105369219613138, 7.5278861860132142],
					[65.105369233317987, 7.5278862108070976],
					[65.105369240597966, 7.5278862210510695],
					[65.105758118386376, 7.5283944913436649],
					[65.105910908020647, 7.5289194564537327],
					[65.10591091171284, 7.5289194668964985],
					[65.105910920911938, 7.5289194898110914],
					[65.105910927294929, 7.5289195001051281],
					[65.106208112262891, 7.5294361732822983],
					[65.106574804708345, 7.5310111460378479],
					[65.10657480931819, 7.531011158559922],
					[65.106574820352577, 7.5310111856333251],
					[65.10657482585934, 7.5310111981058947],
					[65.106910914972147, 7.5316111789973572],
					[65.107369208575491, 7.5330972590633873],
					[65.107369212246539, 7.5330972673789756],
					[65.107369217773964, 7.5330972819806794],
					[65.107369221424179, 7.5330972881679177],
					[65.107627608275948, 7.5336167631856208],
					[65.107910901809362, 7.5351972261515927],
					[65.108008099007947, 7.5362555988962372],
					[65.107894201924154, 7.5374055704942133],
					[65.107688703862479, 7.5384027594629721],
					[65.107688703069343, 7.5384027701540601],
					[65.10768870056539, 7.5384027894571144],
					[65.107688699772254, 7.5384028001482015],
					[65.107688699579057, 7.5394693541578324],
					[65.10743041881463, 7.5400138149291935],
					[65.107430410135592, 7.5400138452180849],
					[65.107430402664548, 7.5400139073829848],
					[65.107430403851794, 7.5400139371306114],
					[65.107505404130563, 7.5403972384789286],
					[65.107505419751604, 7.5403972759529809],
					[65.107505465261667, 7.5403973416006043],
					[65.107505495150733, 7.5403973697741717],
					[65.107680494742453, 7.5405057696076048],
					[65.107680549641969, 7.540505785762309],
					[65.107680659088658, 7.5405057818895287],
					[65.10768071275956, 7.5405057640395592],
					[65.107894512690692, 7.5403584648815372],
					[65.107894523391721, 7.5403584579060334],
					[65.107894541185289, 7.5403584420234484],
					[65.107894550071677, 7.5403584330179578],
					[65.108558421970983, 7.5396084634544591],
					[65.109230684717588, 7.5392918815730274],
					[65.109230702552523, 7.5392918699458953],
					[65.109230736407781, 7.5392918446616122],
					[65.109230752428118, 7.5392918310044612],
					[65.109719552013715, 7.5387223303286408],
					[65.109719557333079, 7.5387223236475203],
					[65.109719567950961, 7.5387223081567782],
					[65.109719572373379, 7.5387223015249125],
					[65.111450141248525, 7.5357834537903114],
					[65.113486187331276, 7.5348057797696706],
					[65.113486198052797, 7.5348057749192696],
					[65.113486219454259, 7.5348057609608388],
					[65.113486229258072, 7.5348057540310291],
					[65.114747328950457, 7.5337390533570492],
					[65.11474734670162, 7.53373903320806],
					[65.114747376780741, 7.5337389889487971],
					[65.114747389108643, 7.5337389648385482],
					[65.115805789262637, 7.5306444648179633],
					[65.115805790993861, 7.5306444583318743],
					[65.115805793580307, 7.530644447538215],
					[65.115805796208463, 7.5306444410025648],
					[65.116608493764844, 7.526755650081614],
					[65.117758491112355, 7.5230250580685984],
					[65.117758492009301, 7.5230250580187956],
					[65.11775849286424, 7.5230250537106915],
					[65.117758492843265, 7.5230250515815378],
					[65.117814093198604, 7.5228139530096207],
					[65.118902993137198, 7.5188528523324374],
					[65.118902994867909, 7.5188528458448296],
					[65.118902997432443, 7.5188528329195146],
					[65.118902998245233, 7.51885282435261],
					[65.119461298184603, 7.5143861252886621],
					[65.119461299039315, 7.5143861209799852],
					[65.119461299830732, 7.5143861102834855],
					[65.119461299788512, 7.5143861060249053],
					[65.11955299940432, 7.5107139258290374],
					[65.12042239468704, 7.5070583458513696],
					[65.12042239468704, 7.5070583458513696],
					[65.120880794643455, 7.5050944456394166],
					[65.120880797079408, 7.5050944199355172],
					[65.120880795651615, 7.50509436675123],
					[65.120880790912238, 7.5050943414506168],
					[65.120369591002131, 7.5034193417188053],
					[65.12036957543566, 7.5034193106365992],
					[65.120369531788398, 7.5034192534373956],
					[65.120369506419593, 7.5034192292983741],
					[65.116477906384645, 7.5009970291448438],
					[65.116477872152771, 7.5009970161621995],
					[65.116477801125995, 7.5009970031227047],
					[65.116477765228012, 7.5009970030153337],
					[65.115713997216247, 7.5011330795842079],
					[65.115219568992188, 7.5003498933686918],
					[65.115219551737781, 7.5003498730400251],
					[65.115219514602089, 7.5003498389211751],
					[65.115219492905638, 7.5003498231031873],
					[65.113852910964766, 7.4996276319376731],
					[65.111511226444804, 7.4977220450927948],
					[65.11151120297562, 7.4977220315090278],
					[65.111511155183024, 7.497722008649391],
					[65.111511129087091, 7.4977220016034281],
					[65.109488993640227, 7.4974276123929915],
					[65.10875844064762, 7.4966970599752241],
					[65.108758434283786, 7.4966970518162546],
					[65.108758417092886, 7.4966970378800912],
					[65.108758408956618, 7.4966970319508652],
					[65.108130708569007, 7.4962915323212398],
					[65.108130680615091, 7.49629151899381],
					[65.108130623020244, 7.4962915030821513],
					[65.108130593379315, 7.4962915004978825],
					[65.106788948763409, 7.496338697335716],
					[65.105530698380406, 7.4956304270149676],
					[65.105530677644524, 7.4956304175402391],
					[65.105530637133711, 7.4956304049245492],
					[65.105530615565002, 7.4956304018849824],
					[65.10475842743169, 7.495569209304743],
					[65.103997378453514, 7.4940832097680801],
					[65.103997372965082, 7.4940831994324144],
					[65.103997362927913, 7.494083182966385],
					[65.103997356585339, 7.4940831769375338],
					[65.10353907963254, 7.4935027040899831],
					[65.103264091216829, 7.4926193410774982],
					[65.103264082951924, 7.4926193223829243],
					[65.103264064671066, 7.4926192893513104],
					[65.103264053736851, 7.4926192729370991],
					[65.103041906035713, 7.4923499351833778],
					[65.103089098779819, 7.4918139188444197],
					[65.103089092927092, 7.4918138723343848],
					[65.103089060678272, 7.4918137889949863],
					[65.10308903517911, 7.4918137521148038],
					[65.102919535226448, 7.4916581529660364],
					[65.102919476627065, 7.4916581264785149],
					[65.102919357144643, 7.49165811408675],
					[65.10291929538603, 7.4916581303612899],
					[65.102622094924357, 7.491841530999042],
					[65.102622060287402, 7.491841567027584],
					[65.102622012667922, 7.4918416506325318],
					[65.102621999685468, 7.4918416982089324],
					[65.102616499882274, 7.4923749838348659],
					[65.102447001906597, 7.4934471693790297],
					[65.102447002206148, 7.4934471991696761],
					[65.102447010877512, 7.4934472582940801],
					[65.102447020146201, 7.4934472875770757],
					[65.103180415268, 7.4949444750445453],
					[65.103491504989918, 7.4958750477212241],
					[65.103613701008811, 7.4966611203030187],
					[65.103660899744426, 7.4976389103432171],
					[65.103660901666288, 7.4976389230099878],
					[65.103660907325192, 7.4976389503703009],
					[65.103660911062207, 7.4976389650638238],
					[65.104019193942278, 7.4986833177920627],
					[65.103927606239353, 7.4992193452209719],
					[65.103713714193944, 7.4997638273983549],
					[65.103713709004225, 7.4997638468537549],
					[65.103713702191101, 7.499763883434353],
					[65.103713699713424, 7.4997639048661204],
					[65.103752598750745, 7.5018916669225604],
					[65.102949812314193, 7.5040666317097706],
					[65.102949807999536, 7.5040666489854226],
					[65.102949801185375, 7.5040666855638802],
					[65.102949799561515, 7.5040667026883296],
					[65.102966499711485, 7.505127791848019],
					[65.102852603127957, 7.5061999647573838],
					[65.102630410754415, 7.5071027319456647],
					[65.102280420297376, 7.5078305137835377],
					[65.102280414167311, 7.5078305290308043],
					[65.102280404597863, 7.5078305593745247],
					[65.102280402055428, 7.5078305744206792],
					[65.102080403371403, 7.5094360667147377],
					[65.101616516298151, 7.5115916044956279],
					[65.101097052851927, 7.5121526633473952],
					[65.101097036063933, 7.5121526898336235],
					[65.101097009684523, 7.5121527445328615],
					[65.10109700190803, 7.5121527747734049],
					[65.101027602010333, 7.5126888730245698],
				],
			],
		},
		{
			fldNpdidField: "21106284",
			wlbNpdidWellbore: "6423",
			fldName: "ATLA",
			fldCurrentActivitySatus: "Producing",
			wlbName: "25/5-7",
			fldDiscoveryYear: "2010",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=21106284",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=21106284",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "102 C",
			cmpLongName: "TotalEnergies EP Norge AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=6423",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "35000016",
			fldNpdidOwner: "5467035",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[59.652063701027537, 2.5655444177974958],
					[59.652430401000444, 2.5697417166358982],
					[59.652430405871328, 2.5697417423950135],
					[59.652430422664629, 2.5697417907784654],
					[59.65243043360794, 2.5697418118112818],
					[59.655352634377479, 2.5740501125374387],
					[59.655352637227814, 2.5740501155496425],
					[59.655352641057164, 2.5740501201536481],
					[59.655352643015206, 2.5740501233372277],
					[59.656563743313555, 2.5755751232702071],
					[59.656563751951168, 2.5755751340707675],
					[59.656563769858565, 2.5755751502108404],
					[59.656563779301763, 2.5755751590768234],
					[59.659480477378466, 2.5777807574937026],
					[59.664669273831628, 2.5819223541652354],
					[59.667160972038637, 2.583994554214982],
					[59.667160987900687, 2.5839945654152996],
					[59.667161022128518, 2.5839945837746865],
					[59.667161039601986, 2.5839945911049651],
					[59.669711038644714, 2.5848029903659717],
					[59.672627738017518, 2.5857501891733903],
					[59.67262774532918, 2.5857501913321497],
					[59.672627758081269, 2.5857501942279404],
					[59.672627763608304, 2.585750196729065],
					[59.675536063913647, 2.5862751968389235],
					[59.67553606569826, 2.5862751964965462],
					[59.675536068461795, 2.5862751977472453],
					[59.675536070246402, 2.586275197404869],
					[59.678938846824821, 2.5867945945056281],
					[59.680288813230284, 2.5873390809870878],
					[59.683205502050853, 2.5889862738782949],
					[59.683205504900911, 2.5889862768941607],
					[59.683205511320182, 2.5889862792253346],
					[59.683205514976017, 2.5889862803053352],
					[59.68668321458383, 2.5906251810738348],
					[59.686683216454995, 2.5906251824964031],
					[59.686683219218473, 2.5906251837478393],
					[59.686683220110787, 2.5906251835766896],
					[59.688244312144654, 2.5913084795270653],
					[59.690522094477977, 2.592541870680547],
					[59.691866583229569, 2.5935084613808232],
					[59.695708183086687, 2.5962668621163689],
					[59.695708200732582, 2.5962668729891329],
					[59.695708238441938, 2.5962668889253604],
					[59.695708256720749, 2.5962668943309613],
					[59.696911057110576, 2.5965334942027281],
					[59.696911068077853, 2.5965334974462753],
					[59.696911090645365, 2.5965334984660093],
					[59.696911102418518, 2.5965334997730478],
					[59.699111101621263, 2.5965085003978445],
					[59.699111196463662, 2.5965084323150327],
					[59.699111257044983, 2.5965082531807533],
					[59.699111223762706, 2.5965081437237374],
					[59.698758424058227, 2.5962304427107972],
					[59.698758422187119, 2.5962304412874211],
					[59.698758416660247, 2.5962304387828725],
					[59.69875841478914, 2.5962304373595138],
					[59.696411221656767, 2.5945748402652149],
					[59.695911236034014, 2.5941609535220334],
					[59.693777944643365, 2.5919415631724267],
					[59.689511245868452, 2.5873637636589359],
					[59.689511243910673, 2.5873637604714363],
					[59.689511239189379, 2.5873637560327012],
					[59.689511237318186, 2.587363754610188],
					[59.685811242244917, 2.5839026586646492],
					[59.682111246661535, 2.5798804655496332],
					[59.682111245682563, 2.579880463956433],
					[59.682111243811313, 2.5798804625346463],
					[59.682111242832335, 2.5798804609414607],
					[59.6796918432998, 2.5773887616409694],
					[59.67969183670693, 2.5773887557833106],
					[59.679691825305774, 2.5773887437251006],
					[59.679691817907297, 2.5773887398033533],
					[59.675564018278294, 2.574355438109869],
					[59.675564017386009, 2.5743554382813736],
					[59.675564013643374, 2.5743554354389251],
					[59.675564012751089, 2.5743554356104328],
					[59.67414731253929, 2.5733915345984237],
					[59.674147309775655, 2.5733915333488433],
					[59.674147304161671, 2.5733915290855278],
					[59.674147301398065, 2.5733915278359234],
					[59.668533417566223, 2.5700970372249778],
					[59.667605733014163, 2.5692692516713329],
					[59.667605730163729, 2.5692692486584945],
					[59.667605723570539, 2.569269242804435],
					[59.667605720806876, 2.5692692415553871],
					[59.664694520814407, 2.567063741043929],
					[59.66469451983528, 2.5670637394519238],
					[59.664694518943008, 2.5670637396235505],
					[59.663200118881676, 2.5659609397468564],
					[59.663200106761039, 2.5659609313969223],
					[59.663200082693429, 2.565960918224155],
					[59.663200069854163, 2.5659609135729826],
					[59.659577870393591, 2.5646054126958808],
					[59.659577868522206, 2.5646054112758372],
					[59.657375069050786, 2.5637942126363993],
					[59.657375061738882, 2.5637942104832034],
					[59.657375048899567, 2.5637942058335308],
					[59.657375041587656, 2.5637942036803367],
					[59.654894441532136, 2.5632637035100894],
					[59.654894436091631, 2.5632637027769398],
					[59.65489442431835, 2.5632637014822981],
					[59.654894417985574, 2.5632637009208077],
					[59.65354171790549, 2.5631415013742562],
					[59.653541664369143, 2.5631415116734226],
					[59.653541570285959, 2.5631415582488306],
					[59.65354152973913, 2.5631415945250695],
					[59.652063730094895, 2.5655442947502012],
					[59.65206371810018, 2.5655443255313335],
					[59.652063703033527, 2.5655443853778084],
					[59.652063701027537, 2.5655444177974958],
				],
			],
		},
		{
			fldNpdidField: "104718",
			wlbNpdidWellbore: "2830",
			fldName: "SYGNA",
			fldCurrentActivitySatus: "Producing",
			wlbName: "33/9-19 S",
			fldDiscoveryYear: "1996",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=104718",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=104718",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "SYGNA UNIT",
			cmpLongName: "Equinor Energy AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=2830",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "32011216",
			fldNpdidOwner: "96205",
			fldDateUpdated: "2022-09-13 00:00:00",
			fldDateUpdatedMax: "2022-06-02 00:00:00",
			fldMainSupplyBase: "Florø",
			fldAreaGeometryWKT: [
				[
					[
						[61.464680408985998, 2.0010721427835123],
						[61.464680412637684, 2.0010722135510988],
						[61.464680468369309, 2.001072332865423],
						[61.464680518763394, 2.0010723836761675],
						[61.466585989120418, 2.0019223686979717],
						[61.468947051563397, 2.00409733485716],
						[61.470222028779546, 2.0057695063353154],
						[61.471802613342341, 2.008952873186908],
						[61.472766508142428, 2.0122500552037619],
						[61.473491507274382, 2.0148528533063339],
						[61.473491508261333, 2.0148528549686819],
						[61.474002607569126, 2.0166472544761875],
						[61.474002609734761, 2.0166472615268898],
						[61.47400261495703, 2.0166472754278506],
						[61.474002617122657, 2.0166472824785733],
						[61.474591517137803, 2.0179611810749187],
						[61.474591522168204, 2.0179611912504223],
						[61.474591533024153, 2.0179612095380159],
						[61.474591538945553, 2.0179612195130887],
						[61.475666538799388, 2.0194140188405707],
						[61.475666552136509, 2.0194140328019516],
						[61.475666579605985, 2.0194140586612845],
						[61.475666595616211, 2.0194140720213944],
						[61.476691595712232, 2.0200390711191218],
						[61.476691599372181, 2.0200390721805679],
						[61.47669160589696, 2.0200390763669729],
						[61.476691609652789, 2.0200390792915104],
						[61.477805510386602, 2.0206001779945182],
						[61.47780551592448, 2.0206001805184228],
						[61.477805528878129, 2.0206001870285517],
						[61.477805535307063, 2.0206001893520469],
						[61.479202735179861, 2.0210807887627973],
						[61.479202751601832, 2.0210807926085277],
						[61.479202788009857, 2.0210807994983573],
						[61.479202806022201, 2.0210807992167834],
						[61.480386106028412, 2.02104460026505],
						[61.480386114047725, 2.0210445984613115],
						[61.480386131073196, 2.0210445965167394],
						[61.480386139188326, 2.0210445965763193],
						[61.48244443672359, 2.0206390959048202],
						[61.483005635949596, 2.0205362974189356],
						[61.483005640404755, 2.0205362964166782],
						[61.483005649315061, 2.0205362944121656],
						[61.483005653770242, 2.0205362934099012],
						[61.483905645349992, 2.0202862942860533],
						[61.485088935965194, 2.0200695978173848],
						[61.485088936856229, 2.0200695976169007],
						[61.485088938542432, 2.0200695953523411],
						[61.485088940420347, 2.0200695968149716],
						[61.48604723559405, 2.0198751961782588],
						[61.486725024696888, 2.0197612988115008],
						[61.486922217092328, 2.0197445991218057],
						[61.486922247387383, 2.0197445923045634],
						[61.48692230383817, 2.0197445682898678],
						[61.486922330884958, 2.0197445508918839],
						[61.48722513098226, 2.0194834512720239],
						[61.487225142086302, 2.0194834393466787],
						[61.487225161621332, 2.0194834160975379],
						[61.487225171738515, 2.0194834025089823],
						[61.487336271984809, 2.0192973029805632],
						[61.487336280128169, 2.0192972860654881],
						[61.487336291863848, 2.0192972513742138],
						[61.487336297334132, 2.0192972350606908],
						[61.48746409693765, 2.0185750346985052],
						[61.487464098144436, 2.0185750231150021],
						[61.487464099666994, 2.0185750001485396],
						[61.487464099982759, 2.0185749887655811],
						[61.48742239961301, 2.017808288822462],
						[61.487422398050747, 2.0178082759769258],
						[61.487422393144207, 2.0178082506869792],
						[61.487422389799917, 2.0178082382425502],
						[61.486764090202563, 2.0157888376990201],
						[61.486764088132773, 2.0157888325092017],
						[61.486764083993165, 2.0157888221295872],
						[61.486764081923376, 2.0157888169397888],
						[61.485880783993295, 2.0138471203909423],
						[61.484730786111434, 2.010980526740497],
						[61.48327518669312, 2.007133228944757],
						[61.48327518669312, 2.007133228944757],
						[61.483275185706077, 2.0071332272821567],
						[61.48235848758253, 2.0047805308374582],
						[61.481311290317329, 2.0017860371376313],
						[61.4800195918816, 1.997452743430429],
						[61.480019591785428, 1.9974527415672083],
						[61.480019590894472, 1.9974527417682901],
						[61.480019590894472, 1.9974527417682901],
						[61.478847392186758, 1.9936416432122115],
						[61.477914094259489, 1.9904027503327879],
						[61.477355795276594, 1.9878443564464632],
						[61.477355794193066, 1.9878443529217853],
						[61.477355791833403, 1.9878443421464096],
						[61.477355789858848, 1.9878443388230653],
						[61.476189089774117, 1.9842082385357229],
						[61.476189089774117, 1.9842082385357229],
						[61.476189089677774, 1.9842082366727862],
						[61.475502989917572, 1.9821360382106621],
						[61.475083491112237, 1.9808082395501549],
						[61.475083420940535, 1.9808081724975024],
						[61.475083258301979, 1.9808081301286942],
						[61.475083163956846, 1.9808081533527515],
						[61.474894176924998, 1.9809833962462253],
						[61.47354428359656, 1.9819387371959289],
						[61.473544276661606, 1.9819387425332422],
						[61.473544262791727, 1.9819387532078803],
						[61.473544256844086, 1.9819387602064755],
						[61.471355457055651, 1.9841915603164391],
						[61.471355450216933, 1.9841915675154784],
						[61.471355438417767, 1.9841915833734456],
						[61.471355431579049, 1.984191590572485],
						[61.469322032359415, 1.9873165910566908],
						[61.469322026604054, 1.9873166017783375],
						[61.469322016778989, 1.9873166209565287],
						[61.469322011914606, 1.9873166314768835],
						[61.467327612142611, 1.9927471318014054],
						[61.467327612238819, 1.9927471336638491],
						[61.467327609662114, 1.9927471361296163],
						[61.467327609758321, 1.9927471379920443],
						[61.465958109615784, 1.9969416387098788],
						[61.465958109615784, 1.9969416387098788],
						[61.465958108820935, 1.9969416407732254],
						[61.465958108820935, 1.9969416407732254],
						[61.46491920938341, 2.0002832402966675],
						[61.46491920858854, 2.0002832423598553],
						[61.464680408985998, 2.0010721427835123],
					],
					[
						[61.474669801275674, 1.9811920001391838],
						[61.474894430137518, 1.9809837083635622],
						[61.475051682830248, 1.9808724162687801],
						[61.474669801275674, 1.9811920001391838],
					],
				],
			],
		},
		{
			fldNpdidField: "18081500",
			wlbNpdidWellbore: "1467",
			fldName: "TRYM",
			fldCurrentActivitySatus: "Producing",
			wlbName: "3/7-4",
			fldDiscoveryYear: "1990",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=18081500",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=18081500",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "147",
			cmpLongName: "DNO Norge AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=1467",
			fldHcType: "GAS/CONDENSATE",
			fldMainArea: "North sea",
			cmpNpdidCompany: "29468270",
			fldNpdidOwner: "21988",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[56.396427599536501, 4.2406610863850895],
					[56.396427601114716, 4.2406611040309317],
					[56.396427604209094, 4.2406611377082708],
					[56.396427607577913, 4.2406611551302191],
					[56.397158099146964, 4.2431972254945327],
					[56.39705809970549, 4.2458943924187285],
					[56.397058100201619, 4.2458944053338037],
					[56.397058102922486, 4.2458944293259036],
					[56.397058104313913, 4.2458944421291358],
					[56.397358104007878, 4.2473056423452888],
					[56.39735811395294, 4.2473056670577378],
					[56.397358138257616, 4.2473057143092694],
					[56.397358154407854, 4.247305736624762],
					[56.398105429279141, 4.2481029098808616],
					[56.398613706275611, 4.2493333543974847],
					[56.399127602012953, 4.2528306285258912],
					[56.399127604175156, 4.252830637989466],
					[56.3991276083757, 4.2528306536876519],
					[56.399127609642584, 4.2528306632629018],
					[56.399624810286674, 4.2543417624330564],
					[56.399624814301298, 4.2543417732880959],
					[56.399624825078426, 4.2543417962777363],
					[56.399624829926466, 4.2543418054066118],
					[56.400499830284694, 4.2557390054536324],
					[56.400499842604638, 4.2557390217627837],
					[56.400499870702056, 4.2557390507055306],
					[56.400499885584168, 4.255739063450755],
					[56.40188318619316, 4.2567001636746964],
					[56.40188327128147, 4.2567001774041877],
					[56.401883419871808, 4.2567001345490301],
					[56.401883484269149, 4.2567000778528037],
					[56.403252979745368, 4.2534722882454279],
					[56.404452974208127, 4.2513472986912992],
					[56.404452976708214, 4.2513472935119783],
					[56.404452980813083, 4.2513472832650798],
					[56.404452984270421, 4.2513472795887512],
					[56.405583483753105, 4.2487333787912478],
					[56.405583486253079, 4.2487333736116044],
					[56.405583488567082, 4.248733363587716],
					[56.405583491067063, 4.248733358408086],
					[56.406344584903906, 4.2462667799786633],
					[56.407077974196987, 4.2449694981061583],
					[56.407077979196586, 4.2449694877459914],
					[56.407077989133782, 4.2449694654108354],
					[56.40707799317606, 4.2449694535477533],
					[56.407580789029502, 4.2431472667556331],
					[56.408297375971735, 4.2414972963685882],
					[56.408927965744169, 4.2405612123742094],
					[56.408927966577366, 4.2405612106473392],
					[56.408927968243781, 4.2405612071935934],
					[56.408927969139086, 4.2405612070815923],
					[56.409594569044479, 4.2395112073439618],
					[56.409594569877669, 4.2395112056170374],
					[56.41068077031187, 4.2377473056411601],
					[56.410680775310837, 4.2377472952790693],
					[56.410680785370914, 4.2377472761698254],
					[56.410680788579313, 4.2377472660318665],
					[56.411514089156825, 4.2353500654466689],
					[56.411514090698752, 4.2353500587625152],
					[56.411514093782607, 4.2353500453942194],
					[56.411514096282005, 4.2353500402128965],
					[56.411919594264532, 4.2333528481814096],
					[56.412252992358681, 4.2322111556684296],
					[56.412252996088647, 4.2322111357274439],
					[56.412252998885542, 4.2322110915615365],
					[56.412252998076873, 4.2322110705666161],
					[56.411891898242637, 4.2298416729126327],
					[56.411666898587484, 4.2279666759239376],
					[56.411666897629942, 4.2279666744212756],
					[56.411666898338439, 4.2279666694640952],
					[56.411666897318646, 4.2279666663464912],
					[56.411302996911537, 4.225847165537564],
					[56.411302995767102, 4.2258471591901623],
					[56.411302991563097, 4.2258471434901974],
					[56.41130299035639, 4.2258471355278555],
					[56.41036689612497, 4.2230666552224081],
					[56.409964099168519, 4.2194943778670169],
					[56.409964067067442, 4.2194943153699667],
					[56.409963969134736, 4.2194942254458345],
					[56.409963903365465, 4.219494199633635],
					[56.409068485574821, 4.2194804700362711],
					[56.409836114425033, 4.2194251989443323],
					[56.40983617986425, 4.2194251696260361],
					[56.409836272290242, 4.2194250703858476],
					[56.40983627517096, 4.2194249353398003],
					[56.409836185816687, 4.2194248346019538],
					[56.409836122421375, 4.2194248003781336],
					[56.40831117992208, 4.2192526088002884],
					[56.406627924707067, 4.2179109444022531],
					[56.406627853690694, 4.2179109224993301],
					[56.406627718255493, 4.217910933031443],
					[56.406627652046105, 4.2179109656915275],
					[56.406036000683279, 4.2185665102575358],
					[56.405019373616412, 4.2186998026933633],
					[56.405019360187332, 4.2186998043808339],
					[56.405019334411634, 4.2186998124872312],
					[56.405019321044932, 4.218699815789356],
					[56.404236021024786, 4.2190387158141469],
					[56.404235999017224, 4.2190387283140529],
					[56.404235958832693, 4.2190387593224203],
					[56.40423594065571, 4.21903877783088],
					[56.40306924140986, 4.2205581776708385],
					[56.403069233788855, 4.220558189984895],
					[56.403069220275043, 4.220558212773553],
					[56.403069213611658, 4.2205582265897377],
					[56.402497013903314, 4.2219916256365924],
					[56.402497009987911, 4.2219916407296614],
					[56.402497002927738, 4.2219916675742688],
					[56.402497000802896, 4.2219916824425177],
					[56.402335901390025, 4.2238777830382777],
					[56.402335901055508, 4.2238777976816415],
					[56.402335902052421, 4.2238778235145205],
					[56.402335904403735, 4.2238778378207904],
					[56.402702583667619, 4.2257805346667663],
					[56.401733178382308, 4.2267637386571799],
					[56.40070270407567, 4.2273248234943175],
					[56.400702679630363, 4.2273248427830161],
					[56.400702638151074, 4.2273248869201749],
					[56.400702620221786, 4.2273249118809],
					[56.399574820277778, 4.2296221114726276],
					[56.399574816945567, 4.2296221183792948],
					[56.399574811300859, 4.2296221353093406],
					[56.399574807968641, 4.2296221422160238],
					[56.398813715805105, 4.2321582167127723],
					[56.39774422996868, 4.2338887947545416],
					[56.396869229667765, 4.2352942934228848],
					[56.396869222293823, 4.235294312189402],
					[56.396869208379066, 4.2352943479960174],
					[56.39686920279572, 4.2352943665383869],
					[56.396560902960424, 4.2371471673634646],
					[56.396560902313631, 4.2371471739328666],
					[56.396560901020081, 4.2371471870716491],
					[56.396560900373302, 4.2371471936410492],
					[56.396480400393138, 4.2398805903614702],
					[56.396427599536501, 4.2406610863850895],
				],
			],
		},
		{
			fldNpdidField: "29412516",
			wlbNpdidWellbore: "6630",
			fldName: "ODA",
			fldCurrentActivitySatus: "Producing",
			wlbName: "8/10-4 S",
			fldDiscoveryYear: "2011",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=29412516",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=29412516",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "405",
			cmpLongName: "Sval Energi AS",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=6630",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "36536246",
			fldNpdidOwner: "4237284",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[57.059341500164344, 3.0429833097972763],
					[57.059524799810823, 3.047186109556236],
					[57.059524801095208, 3.0471861176117612],
					[57.059524802848706, 3.0471861355061121],
					[57.059524805026555, 3.0471861434181386],
					[57.060199805257383, 3.050172244751133],
					[57.060199808406651, 3.0501722541598997],
					[57.060199814783331, 3.0501722746172755],
					[57.060199818904202, 3.0501722855224545],
					[57.061030418396086, 3.0519472821440821],
					[57.061694211189213, 3.0534278686672311],
					[57.062030402031873, 3.0546222337727174],
					[57.062205400389374, 3.0573222121926844],
					[57.062205400779654, 3.0573222203924479],
					[57.062205404240636, 3.0573222363621544],
					[57.062205404630909, 3.057322244561921],
					[57.062544204965462, 3.0588167434041256],
					[57.062544208192143, 3.0588167544542202],
					[57.062544213595942, 3.0588167734177123],
					[57.062544217638063, 3.0588167826846031],
					[57.063877608275718, 3.0617778600644971],
					[57.064213703394472, 3.063572236226241],
					[57.064719203392023, 3.0662611366046804],
					[57.064719204675214, 3.0662611446620645],
					[57.064719208950613, 3.066261158850597],
					[57.064719211127304, 3.0662611667649133],
					[57.065555411386413, 3.0686389653084736],
					[57.06555541235781, 3.0686389668055898],
					[57.065555411542235, 3.0686389685887345],
					[57.065555413407125, 3.068638969942838],
					[57.066222012826223, 3.0704222705983395],
					[57.066222020830928, 3.0704222874963887],
					[57.066222041229935, 3.0704223189374233],
					[57.066222051837237, 3.070422333766377],
					[57.067547036600253, 3.0718807174225948],
					[57.068377626418197, 3.0733556974645868],
					[57.068377638074374, 3.0733557154328484],
					[57.06837766472718, 3.0733557458768894],
					[57.068377679801664, 3.0733557599929378],
					[57.069533179120469, 3.074216859122497],
					[57.071024878221138, 3.0753695583443901],
					[57.071024897685106, 3.075369570107382],
					[57.071024940031364, 3.0753695897810696],
					[57.071024961942342, 3.0753695961942373],
					[57.073661062421216, 3.0758807958450256],
					[57.073661073298872, 3.0758807974114193],
					[57.073661095947713, 3.0758808004013343],
					[57.073661106747544, 3.0758808003272167],
					[57.076630577076862, 3.0757780007997857],
					[57.077616648162767, 3.0760445928471256],
					[57.07761666179875, 3.0760445956256461],
					[57.077616691673384, 3.0760445991132666],
					[57.077616707018542, 3.0760445999652992],
					[57.078113907371197, 3.0760280007188734],
					[57.078113948238759, 3.0760279892230531],
					[57.078114022941143, 3.0760279508246406],
					[57.078114056775952, 3.0760279239220458],
					[57.078605756758712, 3.0754084241964614],
					[57.078605773383387, 3.0754083950862969],
					[57.078605794705886, 3.0754083321610723],
					[57.078605800297233, 3.0754082982030702],
					[57.078594599799452, 3.0732999992283108],
					[57.078583500375061, 3.0708943997389606],
					[57.078583498702656, 3.0708943834748323],
					[57.07858349267736, 3.0708943513756886],
					[57.078583489217962, 3.0708943353976248],
					[57.07808079991716, 3.069405566996513],
					[57.078066899732057, 3.0663971998218758],
					[57.078064099666442, 3.0657944207699872],
					[57.0783890960939, 3.0642778426782318],
					[57.078389095931399, 3.0642778013746734],
					[57.078389079523745, 3.0642777213449648],
					[57.07838906335656, 3.0642776842594999],
					[57.076727962603101, 3.0619276854753403],
					[57.076727955802703, 3.0619276749937581],
					[57.076727939677482, 3.061927657741518],
					[57.076727932061608, 3.0619276490437777],
					[57.075405761719281, 3.0607720760989614],
					[57.074405780271647, 3.0586999128984815],
					[57.074405774364479, 3.0586999022748209],
					[57.074405759869698, 3.0586998814574375],
					[57.074405753069058, 3.0586998709770934],
					[57.073414052950994, 3.057530471150578],
					[57.073414047277922, 3.0575304654487341],
					[57.073414036825234, 3.0575304539017374],
					[57.073414031152147, 3.0575304481998948],
					[57.072750184244576, 3.0569526947974066],
					[57.072414097228069, 3.0551582633818484],
					[57.072414095050682, 3.0551582554666172],
					[57.07241409085205, 3.0551582429169613],
					[57.072414088752751, 3.0551582366421228],
					[57.071911295920742, 3.0536721558463182],
					[57.071739098571307, 3.0521721774290911],
					[57.07173908791782, 3.0521721427756625],
					[57.071739054258558, 3.0521720787577697],
					[57.071739032146297, 3.0521720492498372],
					[57.070747331741622, 3.0513054494064118],
					[57.069755789885789, 3.0504360006423412],
					[57.069411299437071, 3.0474388777536467],
					[57.069411291853648, 3.0474388508743555],
					[57.069411273347527, 3.0474388026107184],
					[57.069411259588101, 3.0474387783764985],
					[57.068083477184857, 3.0456805028301606],
					[57.067752987618903, 3.0447888303850417],
					[57.067752969000615, 3.0447887986701025],
					[57.067752921433751, 3.0447887451642006],
					[57.067752893378611, 3.0447887232296509],
					[57.065605693332138, 3.0436637236450665],
					[57.065605688708423, 3.0436637210830266],
					[57.065605681326105, 3.0436637173117602],
					[57.065605676780628, 3.0436637163897777],
					[57.064944552853476, 3.0433859469104587],
					[57.06444458358478, 3.042199921436219],
					[57.064444570796127, 3.0421998987035002],
					[57.064444539121205, 3.0421998575235896],
					[57.064444520313188, 3.0421998407164064],
					[57.063286220334561, 3.0413359406952156],
					[57.063286141932849, 3.0413359202508232],
					[57.063285998322741, 3.0413359482997739],
					[57.063285931092665, 3.0413359921605565],
					[57.062305472156517, 3.0428776289197876],
					[57.061152768107107, 3.0432165013557242],
					[57.060166728353003, 3.0432525986648424],
					[57.059341761659908, 3.0429831107949115],
					[57.059341671421471, 3.0429831252982815],
					[57.059341540266082, 3.0429832256911613],
					[57.059341500164344, 3.0429833097972763],
				],
			],
		},
		{
			fldNpdidField: "31164600",
			wlbNpdidWellbore: "6368",
			fldName: "SKOGUL",
			fldCurrentActivitySatus: "Producing",
			wlbName: "25/1-11 R",
			fldDiscoveryYear: "2010",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=31164600",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=31164600",
			fldOwnerKind: "PRODUCTION LICENSE",
			fldOwnerName: "460",
			cmpLongName: "Aker BP ASA",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=6368",
			fldHcType: "OIL",
			fldMainArea: "North sea",
			cmpNpdidCompany: "28544099",
			fldNpdidOwner: "4910195",
			fldDateUpdated: "2022-08-30 00:00:00",
			fldDateUpdatedMax: "2022-10-05 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[59.778738699590107, 2.2235583036896034],
					[59.778738699956314, 2.2235583107632597],
					[59.77884980002964, 2.2255861113149975],
					[59.778849802819686, 2.2255861304046953],
					[59.778849813841205, 2.2255861692633863],
					[59.778849821180998, 2.2255861892139093],
					[59.779591491430807, 2.2270667288425372],
					[59.779399802931763, 2.2280888623335371],
					[59.779399803206225, 2.2280888676389585],
					[59.779399801697402, 2.2280888733072697],
					[59.779399800988728, 2.228088877025681],
					[59.779141500834335, 2.2303638765727833],
					[59.779141502206237, 2.230363903099748],
					[59.779141509225362, 2.2303639517097573],
					[59.779141514964088, 2.2303639755612767],
					[59.779647001666206, 2.2315889441466998],
					[59.779766499821761, 2.2341722085320201],
					[59.779766501627513, 2.2341722260358354],
					[59.779766509697332, 2.2341722601369645],
					[59.779766514978327, 2.2341722751470732],
					[59.780435902394125, 2.2357944454680161],
					[59.780633100179017, 2.2389917116069964],
					[59.780633114237069, 2.2389917570032027],
					[59.780633160712846, 2.2389918369166009],
					[59.780633193930932, 2.2389918694840367],
					[59.78107489365776, 2.2392668699181741],
					[59.781074969928298, 2.2392668812286058],
					[59.781075107954699, 2.2392668496095851],
					[59.781075171402556, 2.2392668045491768],
					[59.781666871393924, 2.23830010390098],
					[59.781666874686508, 2.2383000978702836],
					[59.781666881980705, 2.2383000820904488],
					[59.781666886073644, 2.2383000741099193],
					[59.782183486050471, 2.2370167752236512],
					[59.782183489160289, 2.2370167656554383],
					[59.782183493687924, 2.2370167486501416],
					[59.782183495906068, 2.2370167392631708],
					[59.782516895912046, 2.2353667407515654],
					[59.782516896620919, 2.2353667370329617],
					[59.782516898038679, 2.2353667295957371],
					[59.782516897947268, 2.2353667278270826],
					[59.782608485954256, 2.2347029135639072],
					[59.783191732873277, 2.2342863216873114],
					[59.784266636316218, 2.2346473904003052],
					[59.784266687780821, 2.2346473924463486],
					[59.784266784972047, 2.234647372682522],
					[59.784266831590315, 2.2346473506913385],
					[59.785286232315237, 2.2337557514388813],
					[59.785286237390949, 2.2337557450443648],
					[59.785286245941855, 2.2337557361556688],
					[59.785286251109, 2.2337557315299454],
					[59.786625150847136, 2.2322223326686004],
					[59.786625163399023, 2.232222314027775],
					[59.786625183953085, 2.2322222758842787],
					[59.786625191955252, 2.2322222563815939],
					[59.787036292289201, 2.2308000553257732],
					[59.787036295124118, 2.2308000404489494],
					[59.787036299102141, 2.2308000128270402],
					[59.787036300062297, 2.230799996544218],
					[59.787016899538649, 2.2294666962127323],
					[59.787016898806819, 2.2294666820617057],
					[59.787016893776538, 2.229466654485575],
					[59.787016891169927, 2.2294666389286464],
					[59.786616919249319, 2.2282111283284092],
					[59.787436268294989, 2.2269362087892208],
					[59.788016836223534, 2.2260529572354395],
					[59.789191726024278, 2.2255141074426485],
					[59.790249965195116, 2.2257029969193129],
					[59.79025001281844, 2.22570299437057],
					[59.790250102325416, 2.2257029654145959],
					[59.790250143317429, 2.2257029391889662],
					[59.791477738744533, 2.2244503489058745],
					[59.792160918007049, 2.2259556831619096],
					[59.792160943203513, 2.2259557173610904],
					[59.79216100731356, 2.2259557722388918],
					[59.792161044352355, 2.2259557915115975],
					[59.792841606593129, 2.2261529815457357],
					[59.793622055896371, 2.2267723410609261],
					[59.794169240658761, 2.2274890217398085],
					[59.794169287437441, 2.2274890551226494],
					[59.794169390230415, 2.2274890914013676],
					[59.794169447136312, 2.2274890941156569],
					[59.795150047384489, 2.2272501945382484],
					[59.795150088651312, 2.2272501736181405],
					[59.795150157695176, 2.2272501148579571],
					[59.795150185380741, 2.2272500752485964],
					[59.795958481978602, 2.2252889839409216],
					[59.79618077842651, 2.2248528906993026],
					[59.796180786427442, 2.2248528711891709],
					[59.796180796896365, 2.2248528297202195],
					[59.796180800347521, 2.2248528093490827],
					[59.796241898776643, 2.2235389254027806],
					[59.797047392837634, 2.2195556527652887],
					[59.797716888701245, 2.2176056649657583],
					[59.797716894117819, 2.2176056477680475],
					[59.797716899326261, 2.2176056091556555],
					[59.797716900193024, 2.217605591097922],
					[59.797619600285479, 2.2154999911620399],
					[59.797619598935491, 2.2154999824970436],
					[59.797619597218763, 2.2154999667544777],
					[59.797619595960434, 2.2154999598588532],
					[59.797355796282531, 2.2142305593836191],
					[59.797355794040811, 2.2142305509007092],
					[59.797355788665762, 2.2142305341168154],
					[59.797355786424056, 2.2142305256338899],
					[59.796694586192181, 2.2125638254171807],
					[59.796694575283773, 2.2125638061872448],
					[59.796694550883828, 2.2125637700426912],
					[59.796694535609035, 2.2125637534920757],
					[59.796222336202163, 2.2121248539456517],
					[59.796222318344192, 2.2121248397107816],
					[59.796222280228541, 2.2121248170949737],
					[59.796222259970875, 2.2121248087140355],
					[59.795350128707447, 2.2118526306916135],
					[59.793122420629217, 2.208416672431238],
					[59.793800188813584, 2.2064750655554799],
					[59.793800190413229, 2.2064750616528976],
					[59.793800192537276, 2.2064750504915689],
					[59.793800194936715, 2.2064750446376857],
					[59.794389095323268, 2.2039472451557556],
					[59.794389097263448, 2.2039472304557322],
					[59.794389099268756, 2.2039471996509308],
					[59.79438909933392, 2.2039471835461435],
					[59.794225198856466, 2.201988883397993],
					[59.794225195079406, 2.201988862714888],
					[59.794225183067276, 2.2019888222599979],
					[59.79422517394061, 2.201988802670475],
					[59.793144621695298, 2.2000610879684892],
					[59.7939918844259, 2.198055677557738],
					[59.793991889132101, 2.1980556640796105],
					[59.793991895869738, 2.1980556376704814],
					[59.793991898792747, 2.1980556245570888],
					[59.794411298762036, 2.194563924319064],
					[59.794411299193868, 2.1945639152908911],
					[59.794411300057476, 2.1945638972345591],
					[59.794411299597698, 2.1945638883888572],
					[59.794297399595756, 2.1926582879711303],
					[59.794297398060309, 2.1926582757697561],
					[59.794297393206264, 2.1926582517320292],
					[59.794297389795652, 2.1926582381265418],
					[59.793877989800841, 2.1913666388093551],
					[59.793877984606965, 2.191366625569279],
					[59.793877974403188, 2.1913666026273084],
					[59.793877966626553, 2.1913665917039662],
					[59.793111267152312, 2.1901915915147456],
					[59.793111237673138, 2.190191561793315],
					[59.79311116865987, 2.1901915169246657],
					[59.79311113001738, 2.1901915015948781],
					[59.792522230179969, 2.1901026013055898],
					[59.792522166785851, 2.1901026124989085],
					[59.79252206163666, 2.1901026697875667],
					[59.792522018098417, 2.1901027162480369],
					[59.791674818189499, 2.1919527163213801],
					[59.791674818281479, 2.1919527180903686],
					[59.79167481738989, 2.1919527182728777],
					[59.791674816590287, 2.1919527202243754],
					[59.790672016819109, 2.1942555195327724],
					[59.790672012820977, 2.1942555292896979],
					[59.790672005808197, 2.19425555039005],
					[59.790672003685174, 2.1942555615510253],
					[59.790088704407829, 2.1972805615175672],
					[59.79008870271656, 2.1972805636512018],
					[59.790088702808468, 2.1972805654201371],
					[59.790088702808468, 2.1972805654201371],
					[59.78982202492503, 2.1988276372704711],
					[59.788505552838402, 2.199147006149301],
					[59.788505541247645, 2.1991470085191676],
					[59.788505519141523, 2.1991470166143041],
					[59.788505507734548, 2.1991470225218537],
					[59.787924907819161, 2.1994470219592159],
					[59.787924873349773, 2.1994470522452327],
					[59.787924818703772, 2.1994471277711063],
					[59.787924802093556, 2.1994471722818409],
					[59.787674802223151, 2.2012055726414816],
					[59.787674801174838, 2.2012055871564309],
					[59.78767480193671, 2.2012056191772138],
					[59.7876748028553, 2.2012056368653017],
					[59.787924803124511, 2.2025528351840249],
					[59.787924829245114, 2.2025528870492188],
					[59.787924903657725, 2.2025529665852517],
					[59.787924952841337, 2.2025529940738715],
					[59.788658253433155, 2.2027307947392352],
					[59.788658266191113, 2.2027307974949846],
					[59.788658292414951, 2.2027307992865679],
					[59.788658305081078, 2.2027308002734518],
					[59.789341603440349, 2.2027141018059053],
					[59.789763740736412, 2.2032640222502158],
					[59.789763752078485, 2.2032640324463371],
					[59.789763773962903, 2.2032640547897073],
					[59.789763787088177, 2.2032640646214374],
					[59.790469204743481, 2.2037501089862541],
					[59.79061369863328, 2.2066805784876617],
					[59.790010906672499, 2.208886047469798],
					[59.790010905216491, 2.2088860888840758],
					[59.790010916662062, 2.2088861705688099],
					[59.790010932146707, 2.2088862085241838],
					[59.790885865640746, 2.2102333064079791],
					[59.789786024019499, 2.2121803284618204],
					[59.78895569779759, 2.2117165246394723],
					[59.788955653007605, 2.2117165123287839],
					[59.788955563636641, 2.2117165091163833],
					[59.788955518963952, 2.2117165164457391],
					[59.78803321868142, 2.2121248172469303],
					[59.78803320121586, 2.2121248279613588],
					[59.788033167359814, 2.2121248527459989],
					[59.788033150969291, 2.2121248668162097],
					[59.786533151042661, 2.2138054665976954],
					[59.786533144276426, 2.2138054751279976],
					[59.786533131635579, 2.2138054920067436],
					[59.786533126652614, 2.2138055001732733],
					[59.786194334815548, 2.2143941120871751],
					[59.7856557742072, 2.2140220761004978],
					[59.785283493136554, 2.2126277487465593],
					[59.7852834878531, 2.2126277337376119],
					[59.785283473628034, 2.2126277026786689],
					[59.785283465669714, 2.2126276882154641],
					[59.784764065966641, 2.2118554885983697],
					[59.784763978052723, 2.2118554439775613],
					[59.784763807360889, 2.211855453782765],
					[59.784763724399518, 2.2118555046713895],
					[59.783991526220177, 2.2132832006958831],
					[59.782977628816177, 2.2149526951832263],
					[59.782977620724935, 2.2149527129190831],
					[59.782977607925659, 2.214952744126216],
					[59.782977604384214, 2.2149527627214853],
					[59.782458125017968, 2.2176720513596502],
					[59.781747130335042, 2.2179359119478144],
					[59.78174709032789, 2.2179359397622953],
					[59.781747028262515, 2.2179360113921742],
					[59.781747005221028, 2.2179360536207731],
					[59.781477604807414, 2.2190805548212968],
					[59.781477602682195, 2.2190805659777149],
					[59.781477601289886, 2.2190805912825118],
					[59.781477600056306, 2.2190806022572143],
					[59.781502599158301, 2.2206943416320386],
					[59.781116580821376, 2.2212804174747123],
					[59.779799966031845, 2.2215054026047123],
					[59.779799943032401, 2.2215054108642107],
					[59.77979989979999, 2.221505428606787],
					[59.779799878858555, 2.2215054418084201],
					[59.778880478759639, 2.2222026409245843],
					[59.778880452400756, 2.2222026713124388],
					[59.778880412806963, 2.222202741924542],
					[59.778880401172174, 2.2222027782487039],
					[59.778738700983325, 2.2235582783870202],
					[59.778738701441064, 2.223558287229114],
					[59.778738699590107, 2.2235583036896034],
				],
			],
		},
		{
			fldNpdidField: "21105675",
			wlbNpdidWellbore: "0",
			fldName: "ISLAY",
			fldCurrentActivitySatus: "Producing",
			wlbName: "?",
			fldDiscoveryYear: "2008",
			fldFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=field&nav2=PageView|All&nav3=21105675",
			fldFactMapUrl: "https://factmaps.npd.no/factmaps/3_0/?run=FieldByNPDID&NPDID=21105675",
			fldOwnerKind: "BUSINESS ARRANGEMENT AREA",
			fldOwnerName: "ISLAY UNIT",
			cmpLongName: "TotalEnergies E&P UK Limited",
			wlbFactPageUrl:
				"https://factpages.npd.no/factpages/default.aspx?nav1=wellbore&nav2=PageView|Exploration|All&nav3=0",
			fldHcType: "GAS",
			fldMainArea: "North Sea",
			cmpNpdidCompany: "35000018",
			fldNpdidOwner: "27122635",
			fldDateUpdated: "2022-09-13 00:00:00",
			fldDateUpdatedMax: "2021-08-31 00:00:00",
			fldMainSupplyBase: "",
			fldAreaGeometryWKT: [
				[
					[60.543310899876765, 1.9251306133734782],
					[60.543369200419463, 1.9260556137120346],
					[60.543369207401945, 1.9260556432895124],
					[60.543369231263981, 1.9260557021033347],
					[60.543369247156249, 1.9260557297263177],
					[60.543880447003446, 1.9266584276666809],
					[60.544624833916608, 1.9275612124947623],
					[60.545269225263759, 1.9287250966712943],
					[60.545269226250966, 1.9287250982848509],
					[60.545269227238187, 1.928725099898406],
					[60.545269228225415, 1.9287251015119611],
					[60.54601652799829, 1.9299918028957557],
					[60.546016531850888, 1.9299918075413016],
					[60.546016540639521, 1.9299918202549995],
					[60.546016546370325, 1.9299918263188114],
					[60.546555442719452, 1.930644523497181],
					[60.54704153482583, 1.9312973136834966],
					[60.547969231048377, 1.9327612065544117],
					[60.547969232035541, 1.9327612081682253],
					[60.547969232035541, 1.9327612081682253],
					[60.547969233022727, 1.9327612097820339],
					[60.548766533150534, 1.9339751094100215],
					[60.54876653423387, 1.9339751128330713],
					[60.548766535124855, 1.9339751126377964],
					[60.549588722137493, 1.9351833943258632],
					[60.550055413556713, 1.9363556733927079],
					[60.550055413652863, 1.9363556752019504],
					[60.550649814276163, 1.9378306748982193],
					[60.550649815263291, 1.9378306765123166],
					[60.550649817333642, 1.9378306815498165],
					[60.550649818320764, 1.9378306831639123],
					[60.551399817912305, 1.9394583844807467],
					[60.551399819790397, 1.9394583858998038],
					[60.551399821860691, 1.9394583909375089],
					[60.551399822943893, 1.9394583943610271],
					[60.551991523424668, 1.9405722942310428],
					[60.551991528360098, 1.9405723023023109],
					[60.551991539121993, 1.9405723182497123],
					[60.551991543961357, 1.9405723245116018],
					[60.552760933622345, 1.9415279134658858],
					[60.55348312607348, 1.9427917996516428],
					[60.553483131008782, 1.9427918077236148],
					[60.553483141674363, 1.9427918218630202],
					[60.553483146609693, 1.9427918299349842],
					[60.554713738920171, 1.9442473199864763],
					[60.555510928313133, 1.9454612030809284],
					[60.556205424647644, 1.9467278968999875],
					[60.556205426717689, 1.9467279019389085],
					[60.556205432639814, 1.9467279116267899],
					[60.556205437286899, 1.946727914271142],
					[60.556952628134432, 1.9477862021315182],
					[60.557752617338984, 1.9494111793860713],
					[60.558166512995342, 1.9504806721334846],
					[60.558166519013142, 1.950480683632396],
					[60.558166531048791, 1.9504807066302292],
					[60.558166536970667, 1.9504807163193802],
					[60.558705437471716, 1.9512362166864599],
					[60.558705492728571, 1.9512362558626362],
					[60.558705615195947, 1.951236287661922],
					[60.55870568339342, 1.9512362818999662],
					[60.559438983300936, 1.9509029823620414],
					[60.559438994692307, 1.9509029762087384],
					[60.559439017379155, 1.9509029620923124],
					[60.559439027783561, 1.9509029543240899],
					[60.560147307552825, 1.9503140695544474],
					[60.560980675277335, 1.9499251852283734],
					[60.561969453845762, 1.9495834934601874],
					[60.56283064168246, 1.94940019531001],
					[60.562830648810618, 1.9494001937503009],
					[60.562830664752966, 1.9494001884309489],
					[60.562830671881137, 1.9494001868712392],
					[60.563844434208363, 1.9490085002905151],
					[60.564630594218833, 1.9490335001655139],
					[60.56463066918753, 1.9490334691106512],
					[60.564630771694517, 1.9490333624469531],
					[60.564630800123872, 1.9490332866431419],
					[60.564514099692801, 1.947180586274442],
					[60.564514097142727, 1.9471805721837276],
					[60.564514091055536, 1.9471805423872497],
					[60.564514085736405, 1.9471805270715685],
					[60.563972385905046, 1.945802727138447],
					[60.563972380777805, 1.9458027154433766],
					[60.563972367850297, 1.945802692638428],
					[60.56397236094103, 1.9458026813335012],
					[60.563227972261075, 1.9447970959519758],
					[60.562605788945653, 1.9435277309295276],
					[60.562214094277898, 1.941991650274584],
					[60.562214094277898, 1.941991650274584],
					[60.561819594210618, 1.9404582492163518],
					[60.561819593127424, 1.9404582457916992],
					[60.561819590070058, 1.9404582391376282],
					[60.561819588986857, 1.9404582357129778],
					[60.561275190703427, 1.9388749385718982],
					[60.561066892829572, 1.9381610476317455],
					[60.560855794215257, 1.9373416495254925],
					[60.560855791157728, 1.9373416428719215],
					[60.560855785741396, 1.9373416257497664],
					[60.560855782587701, 1.9373416172863309],
					[60.560286283012829, 1.9360693188202154],
					[60.560286273141379, 1.9360693026751274],
					[60.560286251616439, 1.9360692707755802],
					[60.560286239168143, 1.9360692570262585],
					[60.559569552052004, 1.9353720680046111],
					[60.558722369962993, 1.9342137941279489],
					[60.558000191661577, 1.9328443342840993],
					[60.557761297202937, 1.9314082664120047],
					[60.557761297010515, 1.931408262792695],
					[60.557761294843694, 1.93140825594489],
					[60.557761293760279, 1.9314082525209766],
					[60.557289094137644, 1.9295138525648823],
					[60.557289091874459, 1.9295138439076922],
					[60.557289086553418, 1.9295138285984079],
					[60.55728908438649, 1.9295138217508376],
					[60.556744588578255, 1.9282443343013413],
					[60.556222395216231, 1.9262999555168274],
					[60.555955796713064, 1.9248138656954756],
					[60.555955796327865, 1.9248138584573402],
					[60.555955793077118, 1.9248138481869068],
					[60.55595579199354, 1.9248138447634167],
					[60.555539093020492, 1.9233832466092111],
					[60.5552251928628, 1.9222082475925513],
					[60.555225190695523, 1.9222082407459422],
					[60.555225186360957, 1.9222082270526881],
					[60.555225184193681, 1.9222082202060615],
					[60.554939084442431, 1.9215471210127597],
					[60.554939046009764, 1.9215470763650284],
					[60.554938950892009, 1.9215470167067084],
					[60.554938895001555, 1.9215469996909864],
					[60.554633295115629, 1.9215553994164474],
					[60.554633240261069, 1.9215554187854211],
					[60.554633150707474, 1.9215554805560804],
					[60.554633115117426, 1.9215555231534551],
					[60.554460915120934, 1.9219749231190255],
					[60.554460908956578, 1.9219749427786184],
					[60.554460902070012, 1.9219749827333061],
					[60.554460900360517, 1.9219750014146366],
					[60.554466499622301, 1.9226444021245468],
					[60.554466499718636, 1.9226444039339849],
					[60.554466499814957, 1.9226444057434224],
					[60.5544664999113, 1.922644407552859],
					[60.554496999943581, 1.9234167083526954],
					[60.554496999943581, 1.9234167083526954],
					[60.554497000136209, 1.9234167119715813],
					[60.554497000232516, 1.9234167137810252],
					[60.554613700052293, 1.9251667127034748],
					[60.55461370103955, 1.9251667143173685],
					[60.554613701135843, 1.9251667161268433],
					[60.554613701328449, 1.9251667197457534],
					[60.554777600372326, 1.9269167096072424],
					[60.554794200315442, 1.9287722020659905],
					[60.55479419961695, 1.9287722058804202],
					[60.554794199905693, 1.9287722113088732],
					[60.554794201085414, 1.9287722165418471],
					[60.554935901378059, 1.930572215129043],
					[60.554935900583317, 1.930572217133955],
					[60.554935900679524, 1.9305722189434504],
					[60.554935900775753, 1.930572220752961],
					[60.555127601387106, 1.9324750181117309],
					[60.55524149978811, 1.9338639128095467],
					[60.55533589481653, 1.9362859739268612],
					[60.554730662898471, 1.9365636501853518],
					[60.55393347329499, 1.9355054965939362],
					[60.553055782211104, 1.9335749174350001],
					[60.553055781223925, 1.9335749158209234],
					[60.553055779153411, 1.9335749107833409],
					[60.55305577816624, 1.9335749091692618],
					[60.552514078789734, 1.9325082115822507],
					[60.551894580346364, 1.9312416120290707],
					[60.551894576397558, 1.9312416055732557],
					[60.551894568403789, 1.9312415908522962],
					[60.551894562673027, 1.9312415847872288],
					[60.551250176828091, 1.9303332026974345],
					[60.550628000603069, 1.9288055614378334],
					[60.550644600222526, 1.9275694052354455],
					[60.550686300192574, 1.9263806159467061],
					[60.550900198406154, 1.9246722245567891],
					[60.550900199225104, 1.9246722060731976],
					[60.550900197395421, 1.92467217169747],
					[60.550900194554181, 1.9246721521868264],
					[60.550716895295388, 1.9239055527251989],
					[60.550716893224504, 1.9239055476886426],
					[60.550716888986464, 1.9239055358062487],
					[60.550716886819295, 1.9239055289604514],
					[60.550561287392725, 1.9234971293232159],
					[60.550561271596152, 1.9234971035048589],
					[60.550561236631793, 1.9234970562689193],
					[60.550561215778352, 1.9234970370516986],
					[60.550075115809086, 1.9231526377336146],
					[60.550075111065276, 1.9231526332839541],
					[60.550075102661275, 1.9231526278074167],
					[60.550075098013785, 1.9231526251669495],
					[60.549641797721222, 1.922908125713334],
					[60.549641783875067, 1.9229081196012978],
					[60.549641756279101, 1.92290810918636],
					[60.549641740650991, 1.922908103465486],
					[60.54900569428797, 1.9227748150286754],
					[60.547905770775316, 1.9217804842891275],
					[60.547491888877417, 1.9206610355631184],
					[60.546839090717391, 1.9185666403319632],
					[60.546839085395192, 1.9185666250294073],
					[60.546839071379665, 1.9185665988250113],
					[60.546839063384596, 1.9185665841094897],
					[60.546375162970264, 1.9179109841324375],
					[60.54637512619869, 1.9179109537763117],
					[60.546375040062244, 1.9179109104713079],
					[60.546374991781079, 1.9179109009446695],
					[60.545944402512632, 1.9179275992475666],
					[60.545436113697811, 1.9178915008406412],
					[60.545436085885264, 1.9178915032892578],
					[60.545436030549332, 1.917891513613212],
					[60.545436005000624, 1.9178915247150343],
					[60.545030505081286, 1.9181109233058706],
					[60.545030480809032, 1.9181109414472663],
					[60.545030440668739, 1.9181109832044603],
					[60.545030422922395, 1.9181110054027559],
					[60.5447804228398, 1.9185832060186161],
					[60.544780418866893, 1.9185832160413725],
					[60.544780410921021, 1.91858323608689],
					[60.544780407839063, 1.9185832459139769],
					[60.544633107688746, 1.9191055460521416],
					[60.544633106292267, 1.9191055536789654],
					[60.544633101717444, 1.9191055693238872],
					[60.544633101308335, 1.9191055785639257],
					[60.544491500707267, 1.9203999780011425],
					[60.544491500899966, 1.9203999816189008],
					[60.5444915003944, 1.9203999890500052],
					[60.544491499696143, 1.9203999928633775],
					[60.544449801187433, 1.9214832740544672],
					[60.544230405894332, 1.9225221500552439],
					[60.543833119362006, 1.9238249080695078],
					[60.54355813999954, 1.9241942814683617],
					[60.543558129981967, 1.9241942964762613],
					[60.543558112908606, 1.9241943313320728],
					[60.543558106647495, 1.9241943491756464],
					[60.543310907294746, 1.9251305495276398],
					[60.543310903610163, 1.9251305649756281],
					[60.543310900792122, 1.9251305967030741],
					[60.543310899876765, 1.9251306133734782],
				],
			],
		},
	]
}
