import { ReactElement } from "react"

type PositionDataKpiProps = {
	label: string
	value: number | null
	unit?: string
	width?: number
}

export default function PositionDataKpi({
	label,
	value,
	unit,
	width,
}: PositionDataKpiProps): ReactElement {
	return (
		<div
			className={`grid h-[76px] grid-rows-[] gap-2 p-2`}
			style={{ width: width !== undefined ? width : "100%" }}
		>
			<p className={`text-center text-title4 text-black`}>
				{`${value !== null ? Math.round(10 * value) / 10 : `--`}${
					unit !== undefined ? " " : ""
				}${unit !== undefined ? unit : ""}`}
			</p>
			<p className={`text-center text-small uppercase text-gray-666`}>{label}</p>
		</div>
	)
}
