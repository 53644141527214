import { findDeviationSeverity } from "@helpers/findDeviationSeverity"
import { VesselConnectionStatus } from "@redux/features/selectedVessel/selectedVesselSlice"
import { OpModeState } from "@utilityTypes/opmodeDefs"
import "./shipMarkerStyles.css"
import { VesselInfoWithOpMode } from "@redux/features/timelineEventMarker/timelineEventMarkerSlice"

const colorSelectorVesselConnectionStatus = (
	connectionStatus: string,
	lightness: number,
	alpha: number = 1
): string => {
	return `${
		connectionStatus === "UniSea"
			? `hsla(213, 94%, ${lightness}%, ${alpha})`
			: connectionStatus === "offline"
				? `hsla(50, 100%, ${lightness}%, ${alpha})`
				: connectionStatus === "online"
					? `hsla(143, 72%, ${lightness}%, ${alpha})`
					: `hsla(0, 0%, ${lightness}%, ${alpha})`
	}`
}

const colorSelector = (vesselCondition: string, lightness: number): string => {
	return `${
		vesselCondition.split(":")[0] === OpModeState.RED
			? `hsl(347, 83%, ${lightness}%)`
			: vesselCondition.split(":")[0] === OpModeState.YELLOW
				? `hsl(50, 100%, ${lightness}%)`
				: vesselCondition.split(":")[0] === OpModeState.GREEN
					? `hsl(143, 72%, ${lightness}%)`
					: `hsl(0, 0%, ${lightness}%)`
	}`
}

export const createShipIcon = (data: VesselInfoWithOpMode) => {
	const heading: number = data.heading ?? 0

	const state =
		data.latestOperationMode !== undefined &&
		data.latestOperationMode.opModeEvents !== undefined
			? data.latestOperationMode.deviationEvents !== undefined
				? findDeviationSeverity(data.latestOperationMode.deviationEvents)
				: ""
			: ""
	const svgIcon = L.divIcon({
		html: `<svg
		viewBox="0 0 30 30"
	>
		<path
			d="M 2.5 9 L 22.5 9 L 27.5 15 L 22.5 21 L 2.5 21 L 7.5 15 L 2.5 9"
			fill="${colorSelector(state, 70)}"
			stroke="${colorSelector(state, 35)}"
			strokeDasharray="none"
			strokeOpacity="1"
			transform="rotate(${-90 + heading},15,15)"
		/>
	</svg>`,
		className: "cursor-pointer",
		iconSize: [30, 30],
		iconAnchor: [15, 15],
	})
	return svgIcon
}

const MIN_WIDTH_PX = 6
const MIN_HEIGHT_PX = 10
const ZOOM_THRESHOLD = 15

const metersToPixels = (meters: number, lat: number, zoom: number): number => {
	const metersPerPixel = (156543.03392 * Math.cos((lat * Math.PI) / 180)) / Math.pow(2, zoom)
	return meters / metersPerPixel
}

export const createShipIconNL = (
	heading?: number | null,
	connectionStatus: string | VesselConnectionStatus = "online",
	isSelected: boolean = false,
	latitude?: number,
	lengthMeters?: number,
	beamMeters?: number,
	mapZoom?: number
) => {
	let width = 10
	let height = 26

	if (
		latitude !== undefined &&
		mapZoom !== undefined &&
		beamMeters !== undefined &&
		lengthMeters !== undefined &&
		mapZoom >= ZOOM_THRESHOLD
	) {
		height = Math.max(metersToPixels(lengthMeters, latitude, mapZoom), MIN_HEIGHT_PX)
		width = Math.max(metersToPixels(beamMeters, latitude, mapZoom), MIN_WIDTH_PX)
	}

	return L.divIcon({
		className: `cursor-pointer !flex items-center flex-row justify-center border-[2px] rounded-full border-white/0 ${isSelected ? "selected-marker" : ""}`,
		html: `
      <div class="marker-container" style="width:${height}px; height:${height}px; transform: rotate(${heading ?? 0}deg);">
	  	<svg viewBox="0 0 ${width} ${height}" width="100%" height="100%" >
			<path
				d="M 0 ${height} L 0 ${height / 6} L ${width / 2} 0 L ${width} ${height / 6} L ${width} ${height} L 0 ${height}"
				fill="${colorSelectorVesselConnectionStatus(connectionStatus, 70, mapZoom === undefined ? 1 : mapZoom >= ZOOM_THRESHOLD ? 0.9 : 1)}"
				stroke="${colorSelectorVesselConnectionStatus(connectionStatus, 35, mapZoom === undefined ? 1 : mapZoom >= ZOOM_THRESHOLD ? 1 : 1)}"
				strokeDasharray="none"
				strokeOpacity="1"
			/>
		</svg>
        ${isSelected ? `<div class="marker-pulse pointer-events-none" style="width:${height}px; height:${height}px;" />` : ""}
      </div>
    `,
		iconSize: [height + Math.max(0.1 * height, 8), height + Math.max(0.1 * height, 8)],
		iconAnchor: [
			(height + Math.max(0.1 * height, 8)) / 2,
			(height + Math.max(0.1 * height, 8)) / 2,
		],
	})
}

export const createShipIconTimeline = (data: VesselInfoWithOpMode) => {
	const heading: number = data.heading ?? 0

	const svgIcon = L.divIcon({
		html: `<svg
		viewBox="0 0 30 30"
	>
		<path
			d="M 2.5 9 L 22.5 9 L 27.5 15 L 22.5 21 L 2.5 21 L 2.5 9"
			fill="hsl(0, 0%, 90%)"
			stroke="hsl(0, 0%, 35%)"
			strokeDasharray="none"
			strokeOpacity="1"
            transform="rotate(${-90 + heading},15,15)"
		/>
	</svg>`,
		className: "opacity-75",
		iconSize: [30, 30],
		iconAnchor: [15, 15],
	})
	return svgIcon
}
