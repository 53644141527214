type Point = [Date, number]

type Polygon = Point[]

function perpendicularDistance(point: Point, start: Point, end: Point): number {
	if (start[0].getTime() === end[0].getTime() && start[1] === end[1]) {
		return distance(point, start)
	}

	return (
		Math.abs(
			(start[1] - end[1]) * point[0].getTime() +
				(end[0].getTime() - start[0].getTime()) * point[1] +
				start[0].getTime() * end[1] -
				end[0].getTime() * start[1]
		) / distance(start, end)
	)
}

function distance(p1: Point, p2: Point): number {
	return Math.sqrt((p2[0].getTime() - p1[0].getTime()) ** 2 + (p2[1] - p1[1]) ** 2)
}

// Ramer–Douglas–Peucker Decimation Algorithm
export function RDP(contour: Polygon, epsilon = 1): Polygon {
	const endIndex = contour.length - 1
	let collection: Polygon = []
	let maxDistance = 0
	let index = 0
	const contour_0 = contour[0]
	const contour_last = contour[endIndex]
	if (contour.length <= 2 || contour_0 === undefined || contour_last === undefined) return contour

	for (let i = 1; i < endIndex; i += 1) {
		const contour_i = contour[i]
		if (contour_i === undefined) {
			continue
		}
		const distance = perpendicularDistance(contour_i, contour_0, contour_last)

		if (distance > maxDistance) {
			index = i
			maxDistance = distance
		}
	}

	if (maxDistance > epsilon) {
		collection = [
			...RDP(contour.slice(0, index), epsilon),
			...RDP(contour.slice(index, endIndex), epsilon),
		]
	} else {
		collection = [contour_0, contour_last]
	}

	return collection
}
