import { requestApi2 } from "@utils/http"
import { DbDataReport, DbDataReportType, endpoints } from "api"
import { ReactElement, useEffect, useState } from "react"
import Button from "./Button"
import DbDataTable from "./DbDataTable"

export default function DbDataPage(props: { argKey: DbDataReportType }): ReactElement {
	const [data, setData] = useState<DbDataReport | undefined>()

	async function loadData() {
		const res = await requestApi2(endpoints.getDbDataReport, { type: props.argKey })
		if (res === null) {
			return
		}
		setData(res)
	}

	function refresh() {
		setData(undefined)
		loadData()
	}

	useEffect(() => {
		if (data == undefined) {
			loadData()
		}
	}, [])

	return (
		<>
			<Button label="Refresh" onClick={refresh} disabled={data == undefined} />
			{data == undefined ? <p>Loading...</p> : <DbDataTable data={data} />}
		</>
	)
}
