import { ReactNode, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { HeaderBrandingSeaq } from "./HeaderBrandingSeaq"

type HeaderLayoutProps = {
	label: string
	translationFunc?: ReturnType<typeof useTranslation>["t"]
	children?: ReactNode
	customerName?: string
}

const HeaderLayout = forwardRef<HTMLDivElement, HeaderLayoutProps>((props, ref) => {
	const { label, translationFunc, children, customerName } = props
	return (
		<div
			ref={ref}
			className={`sticky top-0 z-98 flex h-[52px] w-full flex-row items-center gap-12 border-b-0 border-gray-900 bg-dipai-secondary-901 px-5 shadow-popup`}
		>
			<p
				id={"header label"}
				className={`whitespace-nowrap text-title4 font-bold text-white/90`}
			>
				{translationFunc !== undefined
					? translationFunc(label.replaceAll("-", " ").toUpperCase())
					: label.replaceAll("-", " ")}
			</p>
			<div className={`flex h-[52px] w-full flex-row justify-between gap-4`}>
				{children}
				{/* fix positioning of branding if there are no other children */}
				{children === undefined &&
				(customerName?.toLowerCase() === "ocean infinity" ||
					customerName?.toLowerCase() === "vard") ? (
					<div className={`h-[52px] w-full`} />
				) : null}
				{customerName?.toLowerCase() === "ocean infinity" ||
				customerName?.toLowerCase() === "vard" ? (
					<HeaderBrandingSeaq />
				) : null}
			</div>
		</div>
	)
})

export { HeaderLayout }
