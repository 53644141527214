import { removeIncompatibleSearchParameters } from "@helpers/removeIncompatibleSearchParameters"
import { createFileRoute } from "@tanstack/react-router"
import { z } from "zod"

export const fleetOverviewPageSearchSchema = z.object({
	vessel: z.optional(z.string()).catch(undefined), //TODO: find a way to get list of vessels
})

export const Route = createFileRoute("/clients/$clientName/_sopPageLayout/fleet-overview")({
	component: RouteComponent,
	validateSearch: fleetOverviewPageSearchSchema,
	search: {
		middlewares: [removeIncompatibleSearchParameters(fleetOverviewPageSearchSchema)],
	},
})

function RouteComponent() {
	return null
}
