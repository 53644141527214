import { Button as ButtonLib } from "@ui-lib/index"

export default function Button(args: { label: string; onClick: () => void; disabled?: boolean }) {
	return (
		<ButtonLib
			className={`px-1 ${
				args.label.toLowerCase() === "erase credentials" ? "w-[140px]" : "w-[121px]"
			}`}
			onClick={args.onClick}
			disabled={args.disabled}
		>
			<ButtonLib.Label>{args.label}</ButtonLib.Label>
		</ButtonLib>
	)
}
