import { Dispatch, FC, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { MdKeyboardDoubleArrowRight } from "react-icons/md"
import { Tooltip } from "../../Tooltip"
import { usePageLayoutContext } from "../layoutContext"

const ExpandButton = ({
	isExpanded,
	setIsExpanded,
	setLocalStorageFunc,
	translationFunc,
}: {
	isExpanded: boolean
	setIsExpanded: Dispatch<SetStateAction<boolean>>
	setLocalStorageFunc?: ((value: string) => void) | undefined
	translationFunc: ReturnType<typeof useTranslation>["t"] | undefined
}) => {
	return (
		<Tooltip
			side={"right"}
			sideOffset={20}
			customContent={
				translationFunc !== undefined
					? translationFunc(isExpanded === true ? "SHOW LESS" : "SHOW MORE")
					: isExpanded === true
					? "SHOW LESS"
					: "SHOW MORE"
			}
		>
			<button
				onMouseDown={(e) => {
					const elem = document.activeElement
					if (elem instanceof HTMLElement) {
						elem.blur()
					}
					e.preventDefault()
				}}
				onClick={() => {
					if (setLocalStorageFunc !== undefined) {
						setLocalStorageFunc(`${!isExpanded}`)
					}
					setIsExpanded((prev) => !prev)

					// Ensures that map components are properly resized and updated
					var event = new Event("resize")
					setTimeout(() => {
						window.dispatchEvent(event)
					}, 500)
				}}
				className={`focus-style h-7 w-7 cursor-pointer text-white/60  hover:text-white/100`}
			>
				<MdKeyboardDoubleArrowRight
					tabIndex={-1}
					className={`h-6 w-6 scale-y-125 transition-transform duration-300 ease-in-out ${
						isExpanded === false ? `rotate-0` : `rotate-180`
					}`}
				/>
			</button>
		</Tooltip>
	)
}
export const MainNavExpandButton: FC = () => {
	const {
		isMainNavExpanded,
		setIsMainNavExpanded,
		isMainNavPopupExpanded,
		setIsMainNavPopupExpanded,
		translationFunc,
	} = usePageLayoutContext()

	return (
		<>
			<div
				className={`hidden w-full justify-end border-dipai-secondary-901/40 px-4 py-[11px] lg:flex`}
			>
				<ExpandButton
					isExpanded={isMainNavExpanded}
					setIsExpanded={setIsMainNavExpanded}
					setLocalStorageFunc={(v: string) => {
						localStorage.setItem("isMainNavExpanded", v)
					}}
					translationFunc={translationFunc}
				/>
			</div>
			<div
				className={`flex w-full justify-end border-dipai-secondary-901/40 px-4 py-[11px] sm:flex lg:hidden`}
			>
				<ExpandButton
					isExpanded={isMainNavPopupExpanded}
					setIsExpanded={setIsMainNavPopupExpanded}
					translationFunc={translationFunc}
				/>
			</div>
		</>
	)
}
