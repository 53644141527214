import "./i18n"
import "./index.css"

import { Configuration, PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { store } from "@redux/app/store"
import { RouterProvider, createRouter } from "@tanstack/react-router"
import ErrorBoundary from "@utils/errorBoundary/ErrorBoundary"
import ErrorMessagePage from "@utils/errorBoundary/ErrorMessagePage"
import { requestApi2 } from "@utils/http"
import root from "@utils/root"
import { getUserWebSocket } from "@utils/websocket/getUserWebSocket"
import { endpoints } from "api"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { Provider } from "react-redux"

const queryClient = new QueryClient()

// TODO: try to solve problem with lazy.load flickering and clean up this file.
const clientIdGoogle = "978990834905-553if084b9h5gp6smq73inmjemeqv3n1.apps.googleusercontent.com"

// MSAL configuration
const configuration: Configuration = {
	auth: {
		clientId: "356b82d5-8dc7-4e54-b9df-fab8157f6c9f", // TODO: Move key to .env file in the backend.
		redirectUri: window.location.origin,
	},
}

export const pca = new PublicClientApplication(configuration)

getUserWebSocket().notificationEvent.addListener((msg) => alert(msg.text))

// Import the generated route tree
import { routeTree } from "./routeTree.gen"
// Create a new router instance
const router = createRouter({
	routeTree,
	notFoundMode: "root",
	defaultErrorComponent: ErrorMessagePage,
})
// Register the router instance for type safety
declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router
	}
}

root.render(
	<Provider store={store}>
		<QueryClientProvider client={queryClient}>
			{/* <StrictMode> */}
			<ErrorBoundary fallback={ErrorMessagePage}>
				{/* <Suspense fallback={<span>Loading...TTTTTTTTTTTTT</span>}> */}
				<GoogleOAuthProvider clientId={clientIdGoogle}>
					<MsalProvider instance={pca}>
						<RouterProvider router={router} />
					</MsalProvider>
				</GoogleOAuthProvider>
				{/* </Suspense> */}
			</ErrorBoundary>
			{/* </StrictMode> */}
			<ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />
		</QueryClientProvider>
	</Provider>
)

window["resetAccess"] = () => {
	requestApi2(endpoints.resetAccess).then(() =>
		console.log(`Access data has been reset for your user`)
	)
	console.log(`Resetting access data...`)
}
