import { TimeseriesDataRange } from "@utilityTypes/timeseriesDefs"
import { createContext, Dispatch, SetStateAction, useContext } from "react"

export type SensorsGroupContextProps = {
	plotZoom: {
		plotId: number
		zoomFrom?: number
		zoomTo?: number
	}[]
	setPlotZoom: Dispatch<
		SetStateAction<
			| {
					plotId: number
					zoomFrom?: number | undefined
					zoomTo?: number | undefined
			  }[]
			| undefined
		>
	>
	plotRanges: {
		plotId: number
		range: TimeseriesDataRange
		from?: Date
		to?: Date
	}[]
	exposeFetchRange?: (plotId: number, range: TimeseriesDataRange, from?: Date, to?: Date) => void
	triggerIndividualRangeRerender: number
}

export const SensorsGroupContext = createContext<SensorsGroupContextProps | undefined>(undefined)

export const useSensorsGroupContext = () => {
	const sensorsGroupContextValue = useContext(SensorsGroupContext)
	if (sensorsGroupContextValue === undefined) {
		// throw new Error("Context not defined yet...")
	}
	return sensorsGroupContextValue
}
