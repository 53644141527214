import { OpModeState } from "@utilityTypes/opmodeDefs"
import { DeviationEvent } from "api"

export function findDeviationSeverity(deviationEvents: DeviationEvent[], subsystemId?: number) {
	let statusLabel: string = ""
	for (const event of deviationEvents) {
		if (subsystemId !== undefined && event.subsystemId !== subsystemId) {
			continue
		}
		if (statusLabel === OpModeState.RED) {
			break
		}
		if (event.severity === "red") {
			statusLabel = OpModeState.RED
		} else {
			if (statusLabel === OpModeState.YELLOW) {
				continue
			}
			if (event.severity === "yellow") {
				statusLabel = OpModeState.YELLOW
			} else {
				if (statusLabel === OpModeState.GREEN) {
					continue
				}
				if (event.severity === "" || event.severity === "green") {
					statusLabel = OpModeState.GREEN
				}
			}
		}
	}
	return statusLabel
}
