import { useAppSelector } from "@redux/app/hooks"
import { getAppStructure } from "@redux/features/appStructure/appStructureSlice"
import DashboardPageLayout from "@sop/DashboardPage/DashboardPageLayout"
import { createFileRoute, notFound } from "@tanstack/react-router"
import { LoadingCircle } from "@ui-lib/index"

export const Route = createFileRoute("/_sopPageLayout")({
	component: RouteComponent,
})

function RouteComponent() {
	const appStruct = useAppSelector(getAppStructure)
	if (appStruct === undefined) {
		throw notFound()
	}
	return appStruct === null ? (
		<LoadingCircle containerHeightWithUnit="100vh" />
	) : (
		<DashboardPageLayout props={appStruct} />
	)
}
