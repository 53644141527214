import CarouselV2 from "@components/carousel/CarouselV2"
import { useAppSelector } from "@redux/app/hooks"
import { getAppStructure } from "@redux/features/appStructure/appStructureSlice"
import { ModuleNameSOP } from "@routes/clients.$clientName/_sopPageLayout"
import { Link, useLocation } from "@tanstack/react-router"
import { debounce } from "@ui-lib/src/lib"
import { FC, ReactNode, useEffect, useState } from "react"
import { MdMenu } from "react-icons/md"
import { usePageLayoutContext } from "../layoutContext"
import { MainNavExpandButton } from "./MainNavExpandButton"
import { DipaiLogoHeader, MainNavHeader } from "./MainNavHeader"
import { MainNavItem } from "./MainNavItem"
import { MainNavLanguageSelector } from "./MainNavLanguageSelector"
import { MainNavUserDropdown } from "./MainNavUserDropdown"

export interface IMainNavProps {
	children?: ReactNode | ReactNode[]
}

const MainNav: FC<IMainNavProps> = (props: IMainNavProps) => {
	const { children } = props
	const selectedModule = useLocation().pathname.split("/").at(-1) as ModuleNameSOP
	const location = useLocation()
	const appStructure = useAppSelector(getAppStructure)
	const {
		mainItems,
		isMainNavExpanded,
		isMainNavPopupExpanded,
		setIsMainNavPopupExpanded,
		isSecondaryNavExpanded,
	} = usePageLayoutContext()

	const [mainNavRef1, setMainNavRef1] = useState<HTMLDivElement>()
	const [navItemsHeight1, setNavItemsHeight1] = useState<number>()

	const handleResize1 = () => {
		if (mainNavRef1 === undefined) {
			return
		}
		const childrenDivElem = document.getElementById("childrenDiv1")
		setNavItemsHeight1(mainNavRef1?.clientHeight - (childrenDivElem?.clientHeight ?? 0) - 52)
	}
	useEffect(() => {
		handleResize1()
		window.addEventListener("resize", debounce(handleResize1, 100))
		return () => {
			window.removeEventListener("resize", handleResize1)
		}
	}, [mainNavRef1])

	const [mainNavRef2, setMainNavRef2] = useState<HTMLDivElement>()
	const [navItemsHeight2, setNavItemsHeight2] = useState<number>()

	const handleResize2 = () => {
		if (mainNavRef2 === undefined) {
			return
		}
		const childrenDivElem = document.getElementById("childrenDiv2")
		setNavItemsHeight2(mainNavRef2?.clientHeight - (childrenDivElem?.clientHeight ?? 0) - 52)
	}

	useEffect(() => {
		handleResize2()
		window.addEventListener("resize", debounce(handleResize2, 100))
		return () => {
			window.removeEventListener("resize", handleResize2)
		}
	}, [mainNavRef2])

	const [mainNavRef3, setMainNavRef3] = useState<HTMLDivElement>()
	const [navItemsHeight3, setNavItemsHeight3] = useState<number>()

	const handleResize3 = () => {
		if (mainNavRef3 === undefined) {
			return
		}
		const childrenDivElem = document.getElementById("childrenDiv3")

		setNavItemsHeight3(
			(mainNavRef3?.clientHeight ?? 0) - (childrenDivElem?.clientHeight ?? 0) - 52
		)
	}

	useEffect(() => {
		handleResize3()
	}, [isMainNavPopupExpanded])

	useEffect(() => {
		handleResize3()
		window.addEventListener("resize", debounce(handleResize3, 100))
		return () => {
			window.removeEventListener("resize", handleResize3)
		}
	}, [mainNavRef3])

	return (
		<div id="mainNav" className={`bg-dipai-secondary-901`}>
			<div
				ref={(newRef) => setMainNavRef1(newRef !== null ? newRef : undefined)}
				className={`z-401 hidden h-full flex-col justify-between bg-dipai-secondary-800 shadow-side-menu-l transition-[width] duration-300 lg:flex ${
					isMainNavExpanded === false ? `w-[60px]` : `w-[180px]`
				}`}
			>
				<div
					id={"buttonsDiv1"}
					className={`mb-1 h-full w-full overflow-hidden pb-0 shadow-[0px_-1.5px_1px_0px_hsla(0,0%,0%,0.000025)]`}
				>
					<MainNavHeader />
					<div className={`relative w-auto pb-2`} style={{ height: navItemsHeight1 }}>
						<CarouselV2
							step={2}
							showPaginationControls={false}
							paginationControlsOverlap={true}
							direction="vertical"
							itemsSpacing={0}
							paginationControlsSize={"lg"}
						>
							{mainItems.map((item, index) => (
								<MainNavItem
									key={`${item.name}_${index}`}
									name={item.name as ModuleNameSOP}
									icon={item.icon}
								/>
							))}
						</CarouselV2>
					</div>
				</div>
				<div
					className={`mx-2 h-[4px] w-[calc(100%-16px)] border-t-1 border-t-gray-900/20 shadow-[inset_0px_1.5px_1px_hsla(0,0%,100%,0.15)]`}
				/>
				<div id={"childrenDiv1"}>{children}</div>
			</div>

			<div
				ref={(newRef) => setMainNavRef2(newRef !== null ? newRef : undefined)}
				className={`z-401 hidden h-full w-[60px] flex-row overflow-visible bg-dipai-secondary-500 shadow-side-menu-l sm:flex lg:hidden`}
			>
				<div className={`flex h-[10px] w-[60px] bg-gray-500`} />
				<div
					className={`absolute z-5000000000 bg-dipai-secondary-800 transition-[width] duration-300 ${
						isMainNavPopupExpanded === false
							? `w-[60px]`
							: `w-[180px] border-r-1 border-dipai-secondary-800`
					} `}
				>
					<div className={`relative flex h-full flex-col justify-between`}>
						<div
							id={"buttonsDiv2"}
							className={`mb-1 h-full w-full overflow-hidden pb-0 shadow-[0px_-1.5px_1px_0px_hsla(0,0%,0%,0.000025)]`}
						>
							<MainNavHeader />
							<div
								className={`relative w-auto pb-2`}
								style={{ height: navItemsHeight2 }}
							>
								<CarouselV2
									step={2}
									showPaginationControls={false}
									paginationControlsOverlap={true}
									direction="vertical"
									itemsSpacing={0}
									paginationControlsSize={"lg"}
								>
									{mainItems.map((item, index) => (
										<MainNavItem
											key={`${item.name}_${index}`}
											name={item.name as ModuleNameSOP}
											icon={item.icon}
										/>
									))}
								</CarouselV2>
							</div>
						</div>
						<div
							className={`mx-2 h-[4px] w-[calc(100%-16px)] border-t-1 border-t-gray-900/20 shadow-[inset_0px_1.5px_1px_hsla(0,0%,100%,0.15)]`}
						/>
						<div id={"childrenDiv2"}>{children}</div>
					</div>
				</div>
				<div
					className={`absolute left-[60px] z-499999999 h-full ${
						isMainNavPopupExpanded === false
							? `w-0 bg-black/0 [transition:background-color_0.5s,width_0s_0.5s]`
							: `w-[calc(100vw-60px)] bg-black/70 [transition:background-color_0.5s,width_0s]`
					} `}
					onClick={() => setIsMainNavPopupExpanded(false)}
				/>
			</div>

			<div
				ref={(newRef) => setMainNavRef3(newRef !== null ? newRef : undefined)}
				className={`z-401 flex ${
					isMainNavPopupExpanded === true ? "h-full" : "h-[52px]"
				} flex-row overflow-visible bg-dipai-secondary-901 sm:hidden`}
			>
				<div className={`flex h-auto`}>
					<div className={`flex w-full sm:hidden`}>
						<div
							className={`z-100 flex h-[52px] origin-left flex-row items-center border-r-0 pl-0 shadow-side-menu-l transition-[width] duration-300 ${
								isSecondaryNavExpanded === true &&
								selectedModule !== "fleet-overview"
									? "w-[211px]"
									: "w-[52px]"
							}`}
						>
							<div
								className={`z-0 h-[52px] w-[54px] border-b-1 border-b-dipai-secondary-901/40 py-2 pl-2 shadow-[0px_1.5px_1px_hsla(0,0%,100%,0.15)]`}
							>
								<MdMenu
									onClick={() => {
										setIsMainNavPopupExpanded(true)
									}}
									className={`mx-0 mr-2 h-9 w-9 cursor-pointer rounded-sm px-1 text-white/60 hover:bg-white/10 hover:text-white/100`}
								/>
							</div>
							<div
								className={`overflow-x-clip transition-[width] duration-300 ${
									isSecondaryNavExpanded === true &&
									selectedModule !== "fleet-overview"
										? "w-[158px]"
										: "w-[0px]"
								}`}
							>
								{location.pathname.includes("/clients") === true &&
								appStructure !== null &&
								Object.keys(appStructure)[0] === "Dipai" ? (
									<Link to={"/clients"}>
										<DipaiLogoHeader
											width={158}
											selectedModule={selectedModule}
											isExpanded={isSecondaryNavExpanded}
										/>
									</Link>
								) : (
									<DipaiLogoHeader
										width={158}
										selectedModule={selectedModule}
										isExpanded={isSecondaryNavExpanded}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className={`absolute ${isMainNavPopupExpanded === true ? "block" : "hidden"}`}>
					<div
						className={`absolute z-5000000000 w-[180px] border-r-1 border-dipai-secondary-800 bg-dipai-secondary-800 transition-[width] duration-300`}
					>
						<div className={`relative flex h-full flex-col justify-between`}>
							<div
								id={"buttonsDiv3"}
								className={`mb-1 h-full w-full overflow-hidden pb-0 shadow-[0px_-1.5px_1px_0px_hsla(0,0%,0%,0.000025)]`}
							>
								<MainNavHeader />
								<div
									className={`relative w-auto pb-2`}
									style={{ height: navItemsHeight3 }}
								>
									<CarouselV2
										step={2}
										showPaginationControls={false}
										paginationControlsOverlap={true}
										direction="vertical"
										itemsSpacing={0}
										paginationControlsSize={"lg"}
									>
										{mainItems.map((item, index) => (
											<MainNavItem
												key={`${item.name}_${index}`}
												name={item.name as ModuleNameSOP}
												icon={item.icon}
											/>
										))}
									</CarouselV2>
								</div>
							</div>
							<div
								className={`mx-2 h-[4px] w-[calc(100%-16px)] border-t-1 border-t-gray-900/20 shadow-[inset_0px_1.5px_1px_hsla(0,0%,100%,0.15)]`}
							/>
							<div id={"childrenDiv3"}>{children}</div>
						</div>
					</div>
					<div
						className={`absolute left-[60px] z-499999999 h-full w-[calc(100vw-60px)] bg-black/70 [transition:background-color_0.5s,width_0s]`}
						onClick={() => setIsMainNavPopupExpanded(false)}
					/>
				</div>
			</div>
		</div>
	)
}

const MainNavNamespace = Object.assign(MainNav, {
	ExpandButton: MainNavExpandButton,
	LanguageSelector: MainNavLanguageSelector,
	UserDropdown: MainNavUserDropdown,
})
export { MainNavNamespace as MainNav }
