import { ReactElement } from "react"

export enum LocationType {
	location = "Location",
	slaughterHoldingCage = "Slaughter holding cage",
	juvenile = "Juvenile",
	onLand = "On land",
	zone = "Zone",
	valve = "Valve",
}

export const TimeLineSvgCollection = ({
	locationType,
	height = 40,
	width = 20,
}: {
	locationType?: LocationType
	height?: number
	width?: number
}): ReactElement => {
	let path: ReactElement
	const svgColor = "#333"
	const decoratorSize = 10
	const lineSections = `M${width / 2} 0 l0 ${
		(height - decoratorSize) / 2
	} m0 ${decoratorSize} l0 ${(height - 10) / 2}`

	switch (locationType) {
		case LocationType.location:
			path = (
				<g>
					<path d={lineSections} />
					<circle cx={width / 2} cy={height / 2} r={decoratorSize / 2} fill="none" />
				</g>
			)
			break
		case LocationType.slaughterHoldingCage:
			path = (
				<g>
					<path d={lineSections} />
					<circle cx={width / 2} cy={height / 2} r={decoratorSize / 2} fill={svgColor} />
				</g>
			)
			break
		case LocationType.juvenile:
			path = (
				<g>
					<path d={lineSections} />
					<rect
						x={(width - decoratorSize) / 2}
						y={(height - decoratorSize) / 2}
						width={decoratorSize}
						height={decoratorSize}
						fill="none"
					/>
				</g>
			)
			break
		case LocationType.onLand:
			path = (
				<g>
					<path d={lineSections} />
					<rect
						x={(width - decoratorSize) / 2}
						y={(height - decoratorSize) / 2}
						width={decoratorSize}
						height={decoratorSize}
						fill={svgColor}
					/>
				</g>
			)
			break
		default:
			path = <path d={`M${width / 2} 0 l0 ${height}`} />
	}
	return (
		<svg stroke={svgColor} strokeWidth={2.5} style={{ height: height - 2, width: width }}>
			{path}
		</svg>
	)
}
