import Logout from "@components/login/Logout"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { useAppSelector } from "@redux/app/hooks"
import { getUserData } from "@redux/features/userData/userDataSlice"
import { SessionValidityStatus } from "api"
import { ReactElement, ReactNode } from "react"
import { MdKeyboardArrowDown } from "react-icons/md"

export type UserDropdownProps = {
	userData?: Omit<SessionValidityStatus, "isAuthenticated"> &
		Partial<Pick<SessionValidityStatus, "isAuthenticated">>
}

interface UserDropdownCompanyColors {
	text: string
	border: string
	backgroundHover: string
}
interface GenericUserDropdownProps extends UserDropdownProps {
	companyColors: UserDropdownCompanyColors
}

const DropdownItem = ({
	companyColors,
	onSelect,
	children,
}: {
	companyColors: UserDropdownCompanyColors
	onSelect?: ((event: Event) => void) | undefined
	children: ReactNode
}) => {
	return (
		<DropdownMenu.Item
			onSelect={onSelect}
			className={`z-1000 cursor-pointer rounded-md px-[14px] py-1 text-menu text-white ${companyColors.backgroundHover} hover:outline-hidden`}
		>
			{children}
		</DropdownMenu.Item>
	)
}
export default function UserDropdownV2({
	userData,
	companyColors,
}: GenericUserDropdownProps): ReactElement {
	userData = useAppSelector(getUserData)

	const userNameSplit = userData.name.split(" ")
	const userNameSplit_0 = userNameSplit[0]
	const userNameSplot_last =
		userNameSplit[userNameSplit.length > 0 ? userNameSplit.length - 1 : 0]
	return (
		<div
			className={`z-1000 h-10 rounded-[20px] p-1 pr-2 ${companyColors.backgroundHover} focus:shadow-none focus:outline-hidden`}
		>
			<DropdownMenu.Root>
				<DropdownMenu.Trigger className={`z-1000`}>
					<div className="z-1000 cursor-pointer">
						<div
							className={`float-right ml-2 flex h-8 items-center ${companyColors.text}`}
						>
							<MdKeyboardArrowDown />
						</div>
						<p
							className={`float-right mt-1 inline-block text-menu font-bold ${companyColors.text}`}
						>
							{userNameSplit_0 !== undefined
								? `${userNameSplit_0} ${
										userNameSplit.length > 1 ? userNameSplot_last : ""
								  }`
								: "Unknonwn User"}
						</p>
						{userData.profilePicture !== undefined && userData.profilePicture !== "" ? (
							<img
								className={`float-right mr-3 h-8 w-8 rounded-full border-2 ${companyColors.border}`}
								src={userData.profilePicture}
								alt=""
							/>
						) : (
							<div
								className={`float-right mr-3 inline-block h-8 w-8 rounded-full border-2 ${companyColors.border} text-center align-middle`}
							>
								<p
									className={`pt-1 text-small font-bold uppercase ${companyColors.text}`}
								>
									{userNameSplit_0 !== undefined
										? `${userNameSplit_0?.[0]}${
												userNameSplit.length > 1
													? userNameSplot_last?.[0]
													: ""
										  }`
										: `UU`}
								</p>
							</div>
						)}
					</div>
				</DropdownMenu.Trigger>
				<DropdownMenu.Portal>
					<DropdownMenu.Content className="z-1000 mt-2 w-[200px] rounded-lg border-1 border-dipai-secondary-800 bg-dipai-secondary-901 p-[6px] shadow-popup">
						<DropdownItem companyColors={companyColors}>
							<Logout />
						</DropdownItem>
					</DropdownMenu.Content>
				</DropdownMenu.Portal>
			</DropdownMenu.Root>
		</div>
	)
}
