export interface IReportPageFooterProps {
	currentPage?: number
	totalPages?: number
}

export default function ReportPageFooterDipai(props: IReportPageFooterProps) {
	return (
		<div className={`absolute bottom-0 flex h-[40px] w-full flex-col gap-2`}>
			<div className="relative flex justify-end w-full h-2 overflow-hidden bg-dipai-secondary-800">
				<div className="absolute bottom-0 right-[303px] z-1 flex h-[120%] w-4 origin-bottom-left rotate-[5deg] flex-row ">
					<div className={`w-1`} />
					<div className={`w-1 bg-dipai-primary-501`} />
					<div className={`w-2 bg-dipai-secondary-901`} />
				</div>
				<div className="h-2 w-[308px] bg-dipai-secondary-901" />
			</div>
			<div className={`flex flex-row items-center justify-between`}>
				<p className={`h-6 text-menu text-dipai-secondary-901`}>
					{"Generated with Dipai Digital Fleet Platform"}
				</p>
				<p className={`text-small text-dipai-secondary-901`}>
					{props.currentPage !== undefined && props.totalPages !== undefined
						? `${props.currentPage}/${props.totalPages}`
						: ""}
				</p>
			</div>
		</div>
	)
}
