import { ReactElement } from "react"

export enum ZoneRangeId {
	none = "NONE",
	enter = "ENTER",
	inside = "INSIDE",
	leave = "LEAVE",
}

export function ZoneRangeSvgCollection({
	rangeId,
	height = 40,
}: {
	rangeId: ZoneRangeId
	height?: number
}): ReactElement {
	let path: ReactElement

	switch (rangeId) {
		case ZoneRangeId.inside:
			path = <path d={`M10 0 l0 ${height}`} />
			break
		case ZoneRangeId.enter:
			path = <path d={`M6 3.25 l4 0 m0 -1.25 l0 ${height}`} />
			break
		case ZoneRangeId.leave:
			path = <path d={`M10 0 l0 ${height - 3.25} m1.25 0 l -5.25 0`} />
			break
		default:
			path = <></>
	}
	return (
		<svg className="w-5" stroke="currentColor" strokeWidth={2.5} style={{ height: height - 2 }}>
			{path}
		</svg>
	)
}
