import { getFileName } from "@helpers/getFileName"
import { requestApi2 } from "@utils/http"
import { VesselConnectionStatus, endpoints } from "api"
import { useQuery } from "react-query"

export function useGetVesselsConnectionStatusQuery(args: {
	isDipaiUser: boolean
	plantIds: number[]
}) {
	const res = useQuery(
		[
			`source=${getFileName()}`,
			`queryId=GetVesselsConnectionStatus`,
			`endpoints=[${endpoints.getVesselConnectionStatus.name}]`,
			`plantIds=${args.plantIds}`,
			`isDipaiUser=${args.isDipaiUser}`,
		],
		async (): Promise<VesselConnectionStatus[] | undefined | null> => {
			if (args.isDipaiUser === false) {
				return undefined
			}
			const vesselsConnectionStatus = await requestApi2(endpoints.getVesselConnectionStatus, {
				plantIds: args.plantIds,
			})
			return vesselsConnectionStatus
		},
		{
			staleTime: 600000,
			refetchInterval: 600000,
		}
	)
	return res
}
