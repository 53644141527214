import { requestApi2 } from "@utils/http"
import { DbDevice, DbDeviceConfig, DeviceConfigPushHistory, endpoints } from "api"
import { useEffect, useState } from "react"

export default function () {
	const [devices, setDevices] = useState<DbDevice[]>()
	const [deviceId, setDeviceId] = useState<number>()
	const [historyForSelectedDevice, setHistoryForSelectedDevice] =
		useState<DeviceConfigPushHistory>()
	const [pushId, setPushId] = useState<number>()
	const [selectedConfig, setSelectedConfig] = useState<DbDeviceConfig>()

	async function loadListOfDevices() {
		const res = await requestApi2(endpoints.getAllDevices)
		if (res === null) {
			return
		}
		setDevices(res)
	}

	useEffect(() => {
		if (devices == undefined) {
			loadListOfDevices()
		}
	}, [])

	async function loadConfigHistory() {
		if (deviceId == undefined) return
		const tmp = await requestApi2(endpoints.deviceConfigPushHistoryGet, {
			id: deviceId,
		})
		if (tmp === null) {
			return
		}
		setHistoryForSelectedDevice(tmp)

		// setCurrentDeviceId(tmp.id)
	}

	useEffect(() => {
		loadConfigHistory()
	}, [deviceId])

	useEffect(() => {
		setSelectedConfig(undefined)
		if (historyForSelectedDevice !== undefined) {
			for (const configgg of historyForSelectedDevice) {
				if (configgg.id == pushId) {
					setSelectedConfig(configgg)
					break
				}
			}
		}
	}, [pushId])

	return (
		<>
			{devices == undefined ? (
				<p>Loading...</p>
			) : (
				<>
					<label htmlFor="device-selector">Plant</label>
					<br></br>
					<select
						id="device-selector"
						onChange={(evt) => setDeviceId(parseFloat(evt.target.value))}
						defaultValue={""}
					>
						<option disabled value="">
							{" "}
							-- select an option --{" "}
						</option>
						{devices.map((device, index) => {
							return (
								<option key={index} value={device.id}>
									{device.name + " @ " + device.plantName}
								</option>
							)
						})}
					</select>
				</>
			)}

			{historyForSelectedDevice === undefined ? (
				<p></p>
			) : historyForSelectedDevice.length < 1 ? (
				<p>No configs pushed for selected device</p>
			) : (
				<>
					<br></br>
					<label htmlFor="config-selector-label">Config</label>
					<br></br>

					<select
						id="config-selector"
						size={10}
						onChange={(evt) => (
							setPushId(parseFloat(evt.target.value)),
							console.log(parseFloat(evt.target.value))
						)}
						defaultValue={""}
					>
						<option disabled value="">
							{" "}
							-- select an option --{" "}
						</option>
						{historyForSelectedDevice.map((config, index) => {
							return (
								<option key={index} value={config.id}>
									{config.timestamp} {config.pushed ? "pushed" : "not pushed"}
								</option>
							)
						})}
					</select>
				</>
			)}
			{selectedConfig !== undefined ? (
				<>
					<br></br>
					Raw config:
					<br></br>
					<pre>{JSON.stringify(selectedConfig.config, null, "\t")}</pre>
				</>
			) : (
				<></>
			)}
		</>
	)
}
