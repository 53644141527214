import { useAppSelector } from "@redux/app/hooks"
import { getAppState } from "@redux/app/store"
import { requestApi2_getError } from "@utils/http"
import { ConfigurationModule, TableDbEditingWidget, endpoints } from "api"
import { useEffect, useState } from "react"
import Button from "./Button"
import { DbConfigEditingWidget } from "./DbConfigEditingWidget"

export default function DataEditPage(args: { module: ConfigurationModule; _key?: string }) {
	const [widgets, setWidgets] = useState<TableDbEditingWidget[] | undefined>()
	const appStateSelector = useAppSelector(getAppState)
	const [busy, setBusy] = useState(false)
	async function refresh() {
		if (busy) return
		setBusy(true)
		setWidgets(undefined)
		try {
			const widgets = await requestApi2_getError(endpoints.dbConfigGetWidgets, {
				module: args.module,
				key: args._key,
			})
			if (widgets === null) {
				return
			}
			setWidgets(widgets)
		} catch (err) {
			console.error(err)
		}
		setBusy(false)
	}

	useEffect(() => {
		refresh()
	}, [args._key])

	useEffect(() => {
		if (appStateSelector.isConnected) {
			// refresh()
		}
	}, [appStateSelector.isConnected])

	return (
		<>
			<Button label="Refresh" onClick={refresh} disabled={widgets == undefined} />
			{widgets == undefined ? (
				<p>Loading...</p>
			) : (
				widgets.map((widget, i) => (
					<DbConfigEditingWidget
						key={i}
						widget={widget}
						doSubmit={async (objects) => {
							await requestApi2_getError(endpoints.configurateDb, {
								module: args.module,
								objects: objects,
								table: widget.label,
								key: args._key,
							})
						}}
						doCreate={async () => {
							await requestApi2_getError(endpoints.dbConfigCreateObject, {
								module: args.module,
								table: widget.label,
								key: args._key,
							})
						}}
					/>
				))
			)}
		</>
	)
}
