import { PlantObject, Sensor } from "api"

export function getSensorLabelFromSensorId(
	plantData: PlantObject | undefined,
	sensorId: number
): string {
	if (plantData === undefined) {
		return ""
	}
	let sensorData: Sensor | undefined
	for (const asset of plantData.assets) {
		sensorData = asset.sensors.find((sensor) => sensor.id === sensorId)
	}
	if (sensorData === undefined) {
		return ""
	}
	return sensorData.display_name
}
