import { ValueLabelPair } from "@utilityTypes/ValueLabelPair"
import { Alternative } from "api"
import { KeyboardEventHandler, ReactElement, useEffect, useState } from "react"
import MultiSelect from "./MultiSelect"

import { MultiValue } from "react-select"
import { z } from "zod"

const Values = z.array(z.string())
type Values = z.infer<typeof Values>

export default function MultiSelectWrapper(args: {
	alternatives: Alternative[]
	initialValues: string[]
	onChange: (newValues: string[]) => void
	onKeyDown?: KeyboardEventHandler
	isClearable?: boolean
	required?: boolean
	error?: boolean
	warning?: boolean
	tabIndex?: number
	maxAmountItems?: number
	placeHolderText?: string
	minAmountItems?: number
	isDisabled?: boolean
}): ReactElement {
	const initialValues = args.alternatives.filter((alt) => args.initialValues.includes(alt.value))
	const [selectedOptions, setSelectedOptions] =
		useState<MultiValue<ValueLabelPair>>(initialValues)

	useEffect(() => {
		setSelectedOptions(
			args.alternatives.filter((alt) => args.initialValues.includes(alt.value))
		)
	}, [JSON.stringify(initialValues.map((v) => v.value))])

	useEffect(() => {
		args.onChange(selectedOptions.map((o) => o.value))
	}, [selectedOptions])

	async function parseClipboard() {
		const text = await navigator.clipboard.readText()
		const values = Values.parse(JSON.parse(text))
		const selected = args.alternatives.filter((alt) => values.includes(alt.value))
		setSelectedOptions(selected)
	}

	const keyDown: KeyboardEventHandler = (event) => {
		if (!event.ctrlKey) {
			return
		}
		if (event.key == "c") {
			event.preventDefault()
			const values = JSON.stringify(selectedOptions.map((so) => so.value))
			navigator.clipboard.writeText(values)
		}
		if (event.key == "v") {
			event.preventDefault()
			parseClipboard().catch(console.error)
		}
	}

	return (
		<MultiSelect
			isDisabled={args.isDisabled}
			placeHolderText={args.placeHolderText}
			warning={args.warning}
			required={args.required}
			error={args.error}
			tabIndex={args.tabIndex}
			onChange={setSelectedOptions}
			options={args.alternatives}
			value={selectedOptions}
			onKeyDown={keyDown}
			isClearable={args.isClearable ?? true}
			maxAmountItems={args.maxAmountItems}
			minAmountItems={args.minAmountItems}
		/>
	)
}
