import { RequestStatus } from "@redux/app/RequestStatus"
import { RootState } from "@redux/app/store"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { requestApi2 } from "@utils/http"
import { authEndpoints } from "api"

type UserData = {
	name: string
	email: string
	isAuthenticated: boolean | undefined
	profilePicture?: string
	portalMode?: PortalMode
}

type State = {
	userData: UserData
	status: RequestStatus
	error: string
}

export enum PortalMode {
	PROD = "prod",
	DEV = "dev",
}

const initialState: State = {
	userData: {
		name: "unknown",
		email: "unknown",
		isAuthenticated: undefined,
		profilePicture: "unknown",
	},
	status: RequestStatus.idle,
	error: "",
}

export const fetchUserData = createAsyncThunk<UserData>(
	"userData/fetchUserData",
	async (): Promise<UserData> => {
		const abortController = new AbortController()
		const data = await requestApi2(
			authEndpoints.getIsAuthenticated,
			undefined,
			abortController.signal
		)
		const defaultPortalMode = PortalMode.PROD
		const portalMode = localStorage.getItem("portalMode") as PortalMode | null

		if (data !== null) {
			return {
				...data,
				portalMode: portalMode !== null ? portalMode : defaultPortalMode,
			}
		}
		if (!abortController.signal.aborted) {
			console.log(`error: ${"Server error"}`)
		}
		return {
			name: "unknown",
			email: "unknown",
			isAuthenticated: false,
			profilePicture: "unknown",
		}
	}
)

const userDataSlice = createSlice({
	name: "userData",
	initialState,
	reducers: {
		setUserData(state, action: PayloadAction<UserData>) {
			return { ...state, userData: { ...action.payload } }
		},
		setUserPortalMode(state, action: PayloadAction<PortalMode>) {
			return { ...state, userData: { ...state.userData, portalMode: action.payload } }
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchUserData.pending, (state, action) => {
				state.status = RequestStatus.loading
			})
			.addCase(fetchUserData.fulfilled, (state, action) => {
				state.status = RequestStatus.succeeded
				state.userData = action.payload
			})
			.addCase(fetchUserData.rejected, (state, action) => {
				state.status = RequestStatus.failed
				state.error = "" //action.error.message
			})
	},
})

export const getUserData = (state: RootState) => state.userData.userData
export const getUserDataStatus = (state: RootState) => state.userData.status

export const { setUserData, setUserPortalMode } = userDataSlice.actions

export default userDataSlice.reducer
