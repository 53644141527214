import { format, getWeek } from "date-fns"
import { nb } from "date-fns/locale"

//from selected range t1 - t2, returns array of years : eg. 2022, 2023 ... 2025
export function makeLabelYears({ gte, lt }: { gte: Date; lt: Date }) {
	const yearLabels = new Set<string>()
	const t2minus2 = new Date(lt)
	t2minus2.setHours(t2minus2.getHours() - 2)

	const startYear = gte.getFullYear()
	const endYear = t2minus2.getFullYear()

	for (let year = startYear; year <= endYear; year++) {
		yearLabels.add(`${year}`) // Add each year to the Set
	}

	return Array.from(yearLabels)
}

//returns array of strings with quarter and year : eg. Q1 2022, Q2 2022 ... Q4 2022
export function makeLabelQuarter({ gte, lt }: { gte: Date; lt: Date }) {
	const quarterLabels = new Set<string>()
	const t2minus2 = new Date(lt)
	t2minus2.setHours(t2minus2.getHours() - 2)

	const startYear = gte.getFullYear()
	const endYear = t2minus2.getFullYear()
	const startQ = Math.floor((gte.getMonth() + 3) / 3)
	const endQ = Math.floor((t2minus2.getMonth() + 3) / 3)

	for (let year = startYear; year <= endYear; year++) {
		const startQuarter = year === startYear ? startQ : 1
		const endQuarter = year === endYear ? endQ : 4
		for (let quarter = startQuarter; quarter <= endQuarter; quarter++) {
			quarterLabels.add(`Q${quarter} ${year}`)
		}
	}

	return Array.from(quarterLabels)
}

export function getRangeQuarter(quarter: String) {
	const split = quarter.split(" ")

	const quarterRef = parseInt(split[0]?.split("")[1] ?? "")
	const yearRef = parseInt(split[1] ?? "")
	const monthRef1 = quarterRef * 3 - 3
	const monthRef2 = quarterRef * 3

	const t1 = new Date(Date.UTC(yearRef, monthRef1))
	const t2 = new Date(Date.UTC(yearRef, monthRef2))

	return { t1, t2 } as { t1: Date; t2: Date }
}

//returns array of strings with month number and year : eg. 0 2022, 1 2022 ... 11 2022
export function makeLabelMonth({ gte, lt }: { gte: Date; lt: Date }) {
	const monthLabels = new Set<string>()
	const t2minus2 = new Date(lt)
	t2minus2.setHours(t2minus2.getHours() - 3)

	const startYear = gte.getFullYear()
	const endYear = t2minus2.getFullYear()
	const startMonth = Math.floor(gte.getMonth())
	const endMonth = Math.floor(t2minus2.getMonth())

	for (let year = startYear; year <= endYear; year++) {
		const startM = year === startYear ? startMonth : 0
		const endM = year === endYear ? endMonth : 11
		for (let month = startM; month <= endM; month++) {
			monthLabels.add(`${month} ${year}`)
		}
	}

	return Array.from(monthLabels)
}

export function makeLabelsWeek({ gte, lt }: { gte: Date; lt: Date }) {
	const weekLabels: string[] = []
	const currentDate = new Date(gte)
	const t2minus2 = new Date(lt)
	t2minus2.setHours(t2minus2.getHours() - 3)
	while (currentDate < t2minus2) {
		const week = getWeek(currentDate, { locale: nb })
		let day = 0
		const year =
			currentDate.getMonth() === 0 && week > 6
				? currentDate.getFullYear() - 1
				: currentDate.getMonth() === 11 && week < 6
				? currentDate.getFullYear() + 1
				: currentDate.getFullYear()

		day = (currentDate.getDay() === 0 ? 6 : currentDate.getDay()) - 1
		weekLabels.push(`${week} ${year}`)

		currentDate.setDate(currentDate.getDate() + 7 - day)
	}

	return [...new Set(weekLabels)].sort((a, b) => {
		const splitA = a.split(" ").map(Number)
		const splitB = b.split(" ").map(Number)

		const weekA = splitA[0]
		const yearA = splitA[1]
		const weekB = splitB[0]
		const yearB = splitB[1]
		if (yearA === undefined || yearB === undefined) {
			console.error("Year is undefined:", { yearA, yearB })
			return 0
		}
		if (weekA === undefined || weekB === undefined) {
			console.error("Week is undefined:", { weekA, weekB })
			return 0
		}

		return yearA !== yearB ? yearA - yearB : weekA - weekB
	})
}

export function getLabelWeekNo({ date }: { date: Date }) {
	const currentDate = new Date(date)
	const week = getWeek(currentDate, { locale: nb })
	let day = 0
	const year =
		currentDate.getMonth() === 0 && week > 6
			? currentDate.getFullYear() - 1
			: currentDate.getMonth() === 11 && week < 6
			? currentDate.getFullYear() + 1
			: currentDate.getFullYear()

	day = (currentDate.getDay() === 0 ? 6 : currentDate.getDay()) - 1
	return `${week} ${year}`
}

export function makeLabelsDays({ gte, lt }: { gte: Date; lt: Date }) {
	const daysLabels: string[] = []
	const currentDate = new Date(gte)
	const t2minus2 = new Date(lt)
	t2minus2.setHours(t2minus2.getHours() - 3)
	while (currentDate < t2minus2) {
		const week = getWeek(currentDate, { locale: nb })

		const year =
			currentDate.getMonth() === 0 && week > 6
				? currentDate.getFullYear() - 1
				: currentDate.getMonth() === 11 && week < 6
				? currentDate.getFullYear() + 1
				: currentDate.getFullYear()

		daysLabels.push(`${format(currentDate, "dd M yyyy")}`)

		currentDate.setDate(currentDate.getDate() + 1)
	}

	return [...new Set(daysLabels)].sort((a, b) => {
		// const [dayA, monthA, yearA] = a.split(" ").map(Number)
		// const [dayB, monthB, yearB] = b.split(" ").map(Number)

		const splitA = a.split(" ").map(Number)
		const splitB = b.split(" ").map(Number)

		const dayA = splitA[0]
		const monthA = splitA[1]
		const yearA = splitA[2]
		const dayB = splitB[0]
		const monthB = splitB[1]
		const yearB = splitB[2]

		if (yearA === undefined || yearB === undefined) {
			console.error("Year is undefined:", { yearA, yearB })
			return 0
		}
		if (monthA === undefined || monthB === undefined) {
			console.error("Week is undefined:", { monthA, monthB })
			return 0
		}
		if (dayA === undefined || dayB === undefined) {
			console.error("Week is undefined:", { dayA, dayB })
			return 0
		}

		return yearA !== yearB ? yearA - yearB : monthA !== monthB ? monthA - monthB : dayA - dayB
	})
}

export function formatString(date: String, lang): string {
	const parts = date.split(", ")
	const parts0 = parts[0]

	const splitted = parts0?.split("/")
	const day = parseInt(splitted?.[0] ?? " ", 10)
	const month = parseInt(splitted?.[1] ?? " ", 10)
	const year = parseInt(splitted?.[2] ?? " ", 10)

	//const [day, month, year] = parts[0].split("/").map((num) => parseInt(num, 10))

	const formatedMonth = new Date(year, month - 1, day)

	const options: Intl.DateTimeFormatOptions = { day: "numeric", month: "short", weekday: "short" }

	const formatedDate = formatedMonth.toLocaleDateString(lang, options)

	const formattedString = `${formatedDate} - ${parts[1]?.substring(0, 5)}`

	return formattedString
}
