export function parseCoordFromDDToDDM(lat: number, lng: number) {
	const toDDM = (coord: number) => {
		const degrees = Math.floor(Math.abs(coord))
		const decimalMinutes = (Math.abs(coord) - degrees) * 60
		return { degrees, decimalMinutes: Math.round(100 * decimalMinutes) / 100 }
	}

	const latDirection = lat >= 0 ? "N" : "S"
	const lngDirection = lng >= 0 ? "E" : "W"

	const latDDM = toDDM(lat)
	const lngDDM = toDDM(lng)

	return {
		Latitude_Degree: latDDM.degrees,
		Latitude_Minutes: latDDM.decimalMinutes,
		Latitude_North_South: latDirection,
		Longitude_Degree: lngDDM.degrees,
		Longitude_Minutes: lngDDM.decimalMinutes,
		Longitude_East_West: lngDirection,
	}
}
