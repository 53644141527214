import { ReactElement, useState } from "react"
import Button from "../Button"
import EzTable from "./EzTable"

export default function DeviceDataSendComponent(args: {
	devices: {
		id: number
		name: string
	}[]
	onSubmit: (deviceId: number, timestampGt: Date, timestampLt: Date) => void
}): ReactElement {
	const [deviceId, setDeviceId] = useState<string>(args.devices[0]?.id.toString() ?? "")
	const [t1, setT1] = useState<string>("2024-04-01T19:38:01")
	const [t2, setT2] = useState<string>("2024-04-02T10:14:00")

	function onSubmit() {
		const t1Date = new Date(t1)
		const t2Date = new Date(t2)
		const deviceIdNumber = parseFloat(deviceId)
		const confirmation = confirm("Are you sure?")
		if (confirmation == true) {
			args.onSubmit(deviceIdNumber, t1Date, t2Date)
		}
	}

	return (
		<EzTable
			header={["plant", "timestamps gt", "timestamps lt", ""]}
			data={[
				[
					<select
						className={`h-8 py-0`}
						onChange={(event) => setDeviceId(event.target.value)}
					>
						{args.devices.map((sad, index) => (
							<option key={index} value={sad.id}>
								{sad.name}
							</option>
						))}
					</select>,
					<input
						className={`h-8 px-1`}
						style={{ border: "1px solid lightgray" }}
						placeholder="(timestamps gt)"
						onChange={(evt) => setT1(evt.target.value)}
						defaultValue={t1}
					/>,
					<input
						className={`h-8 px-1`}
						style={{ border: "1px solid lightgray" }}
						placeholder="(timestamps lt)"
						onChange={(evt) => setT2(evt.target.value)}
						defaultValue={t2}
					/>,
					<Button label="submit" onClick={onSubmit} />,
				],
			]}
		/>
	)
}
