import { LoadingCircle } from "@ui-lib/index"
import { AppStructure, CustomerData } from "api"
import { ReactElement, Suspense } from "react"
import { ClientCard } from "./ClientCard"

function ComponentBody({ companyData }: { companyData: CustomerData }): ReactElement {
	const clients = companyData.clients

	return (
		<Suspense fallback={<LoadingCircle containerHeightWithUnit="100vh" />}>
			<div className="m-auto mt-5 grid w-[70%] grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-5 pb-5">
				{Object.keys(clients)
					.sort()
					.map((client) => (
						<ClientCard
							key={`clientCards_${client}`}
							client={client}
							isAllAssets={client.split(":")[1] === "All" ? true : false}
						/>
					))}
			</div>
		</Suspense>
	)
}

export default function ClientsPage({ props }: { props: AppStructure }): ReactElement {
	const companyName = Object.keys(props)[0]

	if (companyName === undefined) {
		return <div> {`Server error: unkown company`}</div>
	}
	const companyData = props[companyName]
	if (companyData === undefined) {
		return <div> {`Server error: unkown company`}</div>
	}

	return <ComponentBody companyData={companyData} />
}
