import { getFileName } from "@helpers/getFileName"
import { requestApi2 } from "@utils/http"
import { endpoints, PlantWeatherSensorIds } from "api"
import { useQuery } from "react-query"

export function useListOfWeatherSensorsQuery(args: { plantId: number }) {
	const res = useQuery(
		[
			`source=${getFileName()}`,
			`queryId=ListOfWeatherSensors`,
			`endpoints=[${endpoints.weatherGetPlantSensorIds.name}]`,
			`plantId=${args.plantId}`,
		],
		async (): Promise<PlantWeatherSensorIds | undefined> => {
			if (args.plantId === -1) {
				return undefined
			}
			const response = await requestApi2(endpoints.weatherGetPlantSensorIds, {
				plantId: args.plantId,
			})

			return response !== null
				? response
				: {
						relativeWindDirection: null,
						relativeWindSpeed: null,
						trueWindDirection: null,
						trueWindSpeed: null,
						waveHeightM: null,
						waterTempC: null,
						waveDirectionDeg: null,
						windWavePeriodSec: null,
						windWaveHeight: null,
						currentDirectionDeg: null,
						currentSpeedMps: null,
						airTemperature: null,
						wavePeriodSec: null,
						windWaveDirectionDeg: null,
						humidityPercent: null,
						stormglassWindDirection: null,
						stormglassWindSpeed_mps: null,
				  }
		},
		{
			staleTime: 10 * 60 * 1000,
			refetchInterval: 10 * 60 * 1000,
		}
	)
	return res
}
