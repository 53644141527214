import { Fragment } from "react"
import { IconId } from "./IconId"
import { IconSize } from "./IconSvgContainer"
import BiomassIcon from "./svgIcons/BiomassIcon"
import ClearIcon from "./svgIcons/ClearIcon"
import Co2WaterIcon from "./svgIcons/Co2WaterIcon"
import EnergyIcon from "./svgIcons/EnergyIcon"
import FishIcon from "./svgIcons/FishIcon"
import FuelIcon from "./svgIcons/FuelIcon"
import O2WaterIcon from "./svgIcons/O2WaterIcon"
import PhWaterIcon from "./svgIcons/PhWaterIcon"
import TemperatureWaterIcon from "./svgIcons/TemperatureWaterIcon"

type IconProps = {
	iconId: IconId
	size?: IconSize
}

export default function Icon({ iconId, size = IconSize.medium }: IconProps) {
	switch (iconId) {
		case IconId.Clear:
			return <ClearIcon size={size} />
		case IconId.Fuel:
			return <FuelIcon size={size} />
		case IconId.Energy:
			return <EnergyIcon size={size} />
		case IconId.Fish:
			return <FishIcon size={size} />
		case IconId.Biomass:
			return <BiomassIcon size={size} />
		case IconId.CO2Water:
			return <Co2WaterIcon size={size} />
		case IconId.O2Water:
			return <O2WaterIcon size={size} />
		case IconId.PhWater:
			return <PhWaterIcon size={size} />
		case IconId.TemperatureWater:
			return <TemperatureWaterIcon size={size} />

		default:
			return <Fragment />
	}
}
