import "@components/datePicker/datepicker.css"
import { PlantPlotSignal } from "api"
import { InputHTMLAttributes, ReactNode, forwardRef } from "react"
import { CalendarContainer } from "react-datepicker"

export type SeriesDataFE = PlantPlotSignal & {
	displayName: string
	data: { x: Date; y: number | null }[]
	unit: string
	stateAliases?: { value: number; label: string }[] | undefined
}

export type PlotData = {
	plotId: number
	plotName: string
	series: SeriesDataFE[] | null
}

export type RangeType = "12h" | "24h" | "7days" | "custom"

type CustomCalendarInputProps = InputHTMLAttributes<HTMLInputElement> & {
	error?: boolean
	isCalendarOpen?: boolean
}

export const CustomCalendarContainer = ({
	className,
	children,
}: {
	className?: string
	children: ReactNode
}) => {
	return (
		<CalendarContainer className={`${className}`}>
			<div className="relative text-[12px]">{children}</div>
		</CalendarContainer>
	)
}

export const CustomCalendarInput = forwardRef<HTMLInputElement, CustomCalendarInputProps>(
	({ value, error, isCalendarOpen, ...props }, ref) => {
		return (
			<input
				{...props}
				className={`${isCalendarOpen === false ? "" : "react-datepicker-ignore-onclickoutside"} text-small h-8 w-[170px] rounded-sm border-1 py-[2px] focus:border-2 focus:px-[11px] focus:ring-0 focus:outline-hidden ${
					error === true
						? "border-red-500 text-red-500 hover:border-red-700 focus:border-red-700"
						: "border-gray-ccc hover:border-dipai-secondary-500 focus:border-dipai-secondary-500"
				}`}
				ref={ref}
				type="text"
				value={value}
			/>
		)
	}
)
