import { ConfigJsonFetchResult, ConfigJsonPushResult } from "edge-admin-api"
import { ReactElement, useEffect, useState } from "react"
import Button from "../Button"

export default function ConfigJsonController(args: {
	devices: { id: number; name: string }[]
	receivedData: ConfigJsonFetchResult | null
	receivedPushResult: ConfigJsonPushResult | null
	doFetch: (deviceId: number) => void
	doPush: (deviceId: number, data: string) => void
}): ReactElement {
	const [selectedDeviceId, setSelectedDeviceId] = useState(args.devices[0]?.id.toString())
	const [textAreaText, setTextAreaText] = useState("")

	useEffect(() => {
		setTextAreaText("")
	}, [selectedDeviceId])

	useEffect(() => {
		if (args.receivedData != null) {
			setTextAreaText(args.receivedData.data ?? args.receivedData.message ?? "")
		}
	}, [args.receivedData])

	const getSelectedDeviceId = () => {
		if (selectedDeviceId == undefined) {
			throw new Error("No device selected")
		}
		return parseFloat(selectedDeviceId)
	}

	const getSelectedDevice = () => {
		const device = args.devices.find((d) => d.id == getSelectedDeviceId())
		if (device == undefined) {
			throw new Error("Could not find device in list")
		}
		return device
	}

	const doFetch = () => {
		setTextAreaText("")
		args.doFetch(getSelectedDeviceId())
	}

	const doPush = () => {
		const device = getSelectedDevice()
		const confirmed = confirm(
			`You are about to push the config to "${device.name}"\nAre you sure? Click OK to confirm.`
		)
		if (confirmed == true) {
			args.doPush(device.id, textAreaText)
		}
	}

	return (
		<div className={`flex flex-col gap-2 border-1 border-slate-300 p-2`}>
			<div className={`flex flex-row items-center gap-2`}>
				<p className={`pr-4 text-body font-semibold`}>Config</p>
				<select
					className={`h-8 py-0`}
					onChange={(event) => setSelectedDeviceId(event.target.value)}
					value={selectedDeviceId}
				>
					{args.devices.map((d, index) => (
						<option key={index} value={d.id}>
							{d.name}
						</option>
					))}
				</select>
				<Button label="fetch" onClick={doFetch} />
				<Button label="push" onClick={doPush} />
			</div>
			<textarea
				value={textAreaText}
				cols={100}
				rows={20}
				onChange={(event) => setTextAreaText(event.target.value)}
			/>
		</div>
	)
}
