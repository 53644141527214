import { removeIncompatibleSearchParameters } from "@helpers/removeIncompatibleSearchParameters"
import { createFileRoute } from "@tanstack/react-router"
import { z } from "zod"

export const voyagePageSearchSchema = z.object({
	vessel: z.optional(z.string()).catch(undefined), //TODO: find a way to get list of vessels
})
export const Route = createFileRoute("/clients/$clientName/_sopPageLayout/voyage")({
	component: RouteComponent,
	validateSearch: voyagePageSearchSchema,
	search: {
		middlewares: [removeIncompatibleSearchParameters(voyagePageSearchSchema)],
	},
})

function RouteComponent() {
	return null
}
