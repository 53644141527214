import { diseaseZoneType } from "@redux/features/diseaseZones/diseaseZonesSlice"
import { mapLayersColors } from "../utils/mapLayers"
import { LayerOptionsWithMetadata } from "../windyMap/windyMap"
import { LayerConstructor } from "./defaultLayerConstructor"

export type DiseaseZoneProperties = {
	color: string
	zoneType: diseaseZoneType
	regulationNumber: string
}

export const addDiseaseZonesConstructor: LayerConstructor = (layerName, zIndex, map) => {
	if (map === undefined) {
		return
	}
	function onEachFeature(
		feature: GeoJSON.Feature<GeoJSON.Geometry, DiseaseZoneProperties>,
		layer: L.Layer
	) {
		if (feature?.properties?.zoneType !== undefined) {
			const options = layer.options as LayerOptionsWithMetadata
			if (options !== undefined) {
				options.metadata = {
					diseaseZone: {
						color: feature.properties.color,
						zoneType: feature.properties.zoneType,
						regulationNumber: feature.properties.regulationNumber,
					},
				}
			}
		}
	}

	const layerPaneName = `${layerName}Pane`
	map.createPane(layerPaneName)
	map.getPane(layerPaneName)!.style.zIndex = `${zIndex}`

	const diseaseZones = L.geoJSON(
		{ type: "Feature" },
		{
			onEachFeature: onEachFeature,
			pane: layerPaneName,
			style: function (feature) {
				if (feature === undefined) {
					return { color: "#ccc" }
				}
				switch (feature.properties.zoneType) {
					case diseaseZoneType.IlaProtectionZone:
						return {
							color: mapLayersColors.diseaseZones.IlaProtectionZone,
							fillColor: mapLayersColors.diseaseZones.IlaProtectionZone,
							weight: 1,
							fillOpacity: 0.3,
						}
					case diseaseZoneType.IlaFreeArea:
						return {
							color: mapLayersColors.diseaseZones.IlaFreeArea,
							fillColor: mapLayersColors.diseaseZones.IlaFreeArea,
							weight: 1,
							fillOpacity: 0.3,
						}
					case diseaseZoneType.IlaSurveillanceZone:
						return {
							color: mapLayersColors.diseaseZones.IlaSurveillanceZone,
							fillColor: mapLayersColors.diseaseZones.IlaSurveillanceZone,
							weight: 1,
							fillOpacity: 0.3,
						}
					case diseaseZoneType.PdProtectionZone:
						return {
							color: mapLayersColors.diseaseZones.PdProtectionZone,
							fillColor: mapLayersColors.diseaseZones.PdProtectionZone,
							weight: 1,
							fillOpacity: 0.3,
						}
					case diseaseZoneType.PdZone:
						return {
							color: mapLayersColors.diseaseZones.PdZone,
							fillColor: mapLayersColors.diseaseZones.PdZone,
							weight: 1,
							fillOpacity: 0.3,
						}
					case diseaseZoneType.PdSurveillanceZone:
						return {
							color: mapLayersColors.diseaseZones.PdSurveillanceZone,
							fillColor: mapLayersColors.diseaseZones.PdSurveillanceZone,
							weight: 1,
							fillOpacity: 0.3,
						}
					default:
						return { color: "#ccc" }
				}
			},
		}
	).addTo(map)

	return diseaseZones
}
