export interface UserAction {
	count: number
	lastTimestamp: string
	totalTimeSpent?: number
}

export interface User {
	id: number
	name: string
	email: string
}

export interface ChartData {
	labels: string[]
	datasets: {
		label: string
		data: number[]
		backgroundColor: string | string[]
		borderColor: string | string[]
		borderWidth: number
	}[]
}

// Helper Functions

export function timeAgo(date: Date): string {
	const seconds = Math.floor((Date.now() - date.getTime()) / 1000)
	const intervals = [
		{ label: "year", seconds: 31536000 },
		{ label: "month", seconds: 2592000 },
		{ label: "day", seconds: 86400 },
		{ label: "hour", seconds: 3600 },
		{ label: "minute", seconds: 60 },
		{ label: "second", seconds: 1 },
	] as const

	for (const interval of intervals) {
		const count = Math.floor(seconds / interval.seconds)
		if (count >= 1) {
			return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`
		}
	}
	return "Just now"
}

export const formatTimeSpent = (hours: number): string => {
	const totalSeconds = hours * 3600 // Convert hours to seconds

	if (totalSeconds < 60) {
		// Less than a minute
		const seconds = Math.floor(totalSeconds)
		return `${seconds} second${seconds !== 1 ? "s" : ""}`
	} else if (totalSeconds < 3600) {
		// Less than an hour
		const minutes = Math.floor(totalSeconds / 60)
		const seconds = Math.floor(totalSeconds % 60)
		return `${minutes} minute${minutes !== 1 ? "s" : ""} ${seconds} second${
			seconds !== 1 ? "s" : ""
		}`
	} else {
		// One hour or more
		const hrs = Math.floor(totalSeconds / 3600)
		const minutes = Math.floor((totalSeconds % 3600) / 60)
		return `${hrs} hour${hrs !== 1 ? "s" : ""} ${minutes} minute${minutes !== 1 ? "s" : ""}`
	}
}

export function extractEmailDomain(email: string): string {
	const match = email.match(/@([^\.]+)\./)
	const domain = match?.[1]?.toLowerCase() ?? "N/A"

	switch (domain) {
		case "allamericanmarine":
			return "All American Marine"
		case "dof":
		case "dofsubsea":
			return "DOF"
		case "solvtrans":
			return "Sølvtrans"
		case "fsvgroup":
			return "FSV Group"
		case "oceaninfinity":
			return "Ocean Infinity"
		case "gmail":
		case "icloud":
		case "outlook":
		case "hotmail":
			return "Email"
		default:
			return domain.charAt(0).toUpperCase() + domain.slice(1)
	}
}

// Styles

export const tableStyles: React.CSSProperties = {
	width: "100%",
	borderCollapse: "collapse",
	fontFamily: "Arial, sans-serif",
	tableLayout: "fixed",
}

export const columnWidths: Record<string, string> = {
	id: "15%",
	name: "45%",
	company: "40%",
}

export const thStyles: React.CSSProperties = {
	textAlign: "left",
	padding: "12px",
	borderBottom: "1px solid #e0e0e0",
	color: "#333",
	fontWeight: "bold",
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
}

export const tdStyles: React.CSSProperties = {
	padding: "12px",
	borderBottom: "1px solid #e0e0e0",
	color: "#666",
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
}

export const thStylesSortable: React.CSSProperties = {
	...thStyles,
	cursor: "pointer",
}

export const getSortIndicator = (
	key: string,
	sortConfig: { key: string; direction: "asc" | "desc" } | null
): string | null => {
	if (sortConfig?.key !== key) return null
	return sortConfig.direction === "asc" ? " ▲" : " ▼"
}

// CSV Generation Function

export function generateCSV(
	users: User[],
	userActions: Record<number, Record<string, UserAction>>,
	extractEmailDomainFn: (email: string) => string
): string {
	// Step 1: Collect all unique action types
	const actionSet = new Set<string>()

	users.forEach((user) => {
		const userActionData = userActions[user.id]
		// Correct null/undefined check
		if (userActionData != null) {
			// Correct usage of Object.keys
			Object.keys(userActionData).forEach((actionKey) => {
				const actionName = actionKey.split("_")[0]
				if (actionName !== undefined) {
					actionSet.add(actionName)
				}
			})
		}
	})

	const actionTypes = Array.from(actionSet).sort()

	// Step 2: Generate headers including action types with Count and Duration
	const headers = [
		"User ID",
		"User Name",
		"Email",
		"Company",
		"Total Actions",
		"Total Time Spent",
		// Dynamically add Action Count and Duration headers
		...actionTypes.flatMap((actionType) => [`${actionType} Count`, `${actionType} Duration`]),
	]

	// Step 3: Generate rows with action counts and durations
	const rows = users.map((user) => {
		const company = extractEmailDomainFn(user.email)
		const userActionData = userActions[user.id] ?? {}
		let totalActions = 0
		let totalTimeSpent = 0

		// Initialize action counts and durations
		const actionCounts: Record<string, number> = {}
		const actionDurations: Record<string, number> = {}
		actionTypes.forEach((actionType) => {
			actionCounts[actionType] = 0
			actionDurations[actionType] = 0
		})

		// Safely iterate over userActionData
		Object.entries(userActionData).forEach(([actionKey, actionData]) => {
			if (actionData === undefined) return // Skip if actionData is undefined or null

			const actionName = actionKey.split("_")[0]
			if (actionName !== undefined && actionTypes.includes(actionName)) {
				// Safely handle count
				const count = actionData.count ?? 0
				actionCounts[actionName] = (actionCounts[actionName] ?? 0) + count
				totalActions += count

				// Safely handle duration
				const duration = actionData?.totalTimeSpent ?? 0 // In hours
				actionDurations[actionName] = (actionDurations[actionName] ?? 0) + duration
				totalTimeSpent += duration
			}
		})

		// Format total time spent and action durations using the existing formatTimeSpent function
		const formattedTotalTimeSpent = formatTimeSpent(totalTimeSpent)
		const formattedActionDurations = actionTypes.map((actionType) =>
			formatTimeSpent(actionDurations[actionType] ?? 0)
		)

		return [
			user.id,
			user.name,
			user.email,
			company,
			totalActions,
			formattedTotalTimeSpent,
			...actionTypes.flatMap((actionType, index) => [
				actionCounts[actionType],
				formattedActionDurations[index],
			]),
		]
	})

	// Step 4: Generate CSV content
	const csvContent = [headers, ...rows]
		.map((row) =>
			row
				.map((value) => {
					const escapedValue = String(value).replace(/"/g, '""')
					return `"${escapedValue}"`
				})
				.join(",")
		)
		.join("\n")

	return csvContent
}

// Chart Preparation Functions

export function prepareChartData(
	sortedUserActions: [string, UserAction][],
	sortConfig: { key: string; direction: "asc" | "desc" } | null
): ChartData {
	// Sort the user actions by totalTimeSpent
	const sortedByTimeSpent = sortedUserActions.sort(([, a], [, b]) => {
		const timeA = a.totalTimeSpent ?? 0
		const timeB = b.totalTimeSpent ?? 0
		return timeB - timeA // Sorting in descending order of time spent
	})

	const labels = sortedByTimeSpent.map(([action]) => action.split("_")[0])
	const data = sortedByTimeSpent.map(([, action]) => (action.totalTimeSpent ?? 0) * 60)

	return {
		labels: labels.filter((label): label is string => label !== undefined),
		datasets: [
			{
				label: "Time Spent (minutes)",
				data,
				backgroundColor: "rgba(21, 237, 158, 0.6)",
				borderColor: "rgba(75, 192, 192, 1)",
				borderWidth: 1,
			},
		],
	}
}

export function prepareTotalTimeSpentChartData(
	userActionsMap: Record<number, Record<string, UserAction>>,
	selectedCompanies: string[],
	users: User[],
	filterActive: boolean,
	extractEmailDomainFn: (email: string) => string
): ChartData {
	const userIdToCompany: Record<number, string> = {}
	users.forEach((user) => {
		const company = extractEmailDomainFn(user.email)
		userIdToCompany[user.id] = company
	})

	const filteredUserActionsMap = Object.fromEntries(
		Object.entries(userActionsMap).filter(([userIdStr]) => {
			const userId = parseInt(userIdStr)
			const company = userIdToCompany[userId]
			return (
				selectedCompanies.length === 0 ||
				(company !== undefined && selectedCompanies.includes(company))
			)
		})
	)

	const actionTotals: Record<string, number> = {}

	Object.values(filteredUserActionsMap).forEach((userActions) => {
		Object.entries(userActions).forEach(([action, data]) => {
			const actionName = action.split("_")[0]
			if (data.totalTimeSpent != null) {
				actionTotals[actionName as string] =
					(actionTotals[actionName as string] ?? 0) + data.totalTimeSpent
			}
		})
	})

	const sortedActions = Object.entries(actionTotals)
		.sort(([, a], [, b]) => b - a)
		.slice(0, 10) // Top 10 actions

	return {
		labels: sortedActions.map(([action]) => action),
		datasets: [
			{
				label: "Total Time Spent (hours)",
				data: sortedActions.map(([, time]) => time),
				backgroundColor: "rgba(21, 237, 158, 0.6)",
				borderColor: "rgba(21, 237, 158, 1)",
				borderWidth: 1,
			},
		],
	}
}

export function prepareEntriesPerActionChartData(
	userActionsMap: Record<number, Record<string, UserAction>>,
	selectedCompanies: string[]
): ChartData {
	const actionCounts: Record<string, number> = {}

	Object.values(userActionsMap).forEach((userActions) => {
		Object.entries(userActions).forEach(([action, data]) => {
			const actionName = action.split("_")[0]
			if (data.count != null) {
				actionCounts[actionName as string] =
					(actionCounts[actionName as string] ?? 0) + data.count
			}
		})
	})

	const sortedActions = Object.entries(actionCounts)
		.sort(([, a], [, b]) => b - a)
		.slice(0, 10) // Top 10 actions

	return {
		labels: sortedActions.map(([action]) => action),
		datasets: [
			{
				label: "Number of Entries",
				data: sortedActions.map(([, count]) => count),
				backgroundColor: "rgba(153, 102, 255, 0.6)",
				borderColor: "rgba(153, 102, 255, 1)",
				borderWidth: 1,
			},
		],
	}
}

export function prepareTimeSpentPerCompanyChartData(
	userActionsMap: Record<number, Record<string, UserAction>>,
	users: User[],
	selectedCompanies: string[],
	filterActive: boolean,
	extractEmailDomainFn: (email: string) => string
): ChartData {
	const companyTotals: Record<string, number> = {}

	users.forEach((user) => {
		const company = extractEmailDomainFn(user.email)
		const companyMatch = selectedCompanies.length === 0 || selectedCompanies.includes(company)
		if (!companyMatch) return

		const userActions = userActionsMap[user.id]
		const userHasActions = userActions != null && Object.keys(userActions).length > 0
		if (filterActive && !userHasActions) return

		let totalTime = 0
		if (userActions != null) {
			Object.values(userActions).forEach((data) => {
				if (data.totalTimeSpent != null) {
					totalTime += data.totalTimeSpent
				}
			})
		}

		companyTotals[company] = (companyTotals[company] ?? 0) + totalTime
	})

	const sortedCompanies = Object.entries(companyTotals).sort(([, a], [, b]) => b - a)

	return {
		labels: sortedCompanies.map(([company]) => company),
		datasets: [
			{
				label: "Total Time Spent",
				data: sortedCompanies.map(([, time]) => time * 60),
				backgroundColor: "rgba(255, 159, 64, 0.6)",
				borderColor: "rgba(255, 159, 64, 1)",
				borderWidth: 1,
			},
		],
	}
}

export function prepareUsersPerCompanyChartData(
	filteredUsers: User[],
	extractEmailDomainFn: (email: string) => string
): ChartData {
	const companyUserCounts: Record<string, number> = {}

	filteredUsers.forEach((user) => {
		const company = extractEmailDomainFn(user.email)
		if (company != null && company !== "") {
			companyUserCounts[company] = (companyUserCounts[company] ?? 0) + 1
		}
	})

	const sortedCompanies = Object.entries(companyUserCounts).sort(([, a], [, b]) => b - a)

	const colors = sortedCompanies.map((_, index) => {
		const hue = (index * 137.508) % 360 // Using golden angle approximation
		return `hsl(${hue}, 70%, 50%)`
	})

	return {
		labels: sortedCompanies.map(([company]) => company),
		datasets: [
			{
				label: "Number of Users",
				data: sortedCompanies.map(([, count]) => count),
				backgroundColor: colors,
				borderColor: "#fff",
				borderWidth: 1,
			},
		],
	}
}

export function prepareEntriesForUserChartData(
	sortedUserActions: [string, UserAction][]
): ChartData {
	const sortedEntries = sortedUserActions
		.map(([action, data]) => ({
			action: action.split("_")[0],
			count: data.count ?? 0,
		}))
		.sort((a, b) => b.count - a.count)

	const labels = sortedEntries.map((entry) => entry.action)
	const data = sortedEntries.map((entry) => entry.count)

	return {
		labels: labels.filter((label): label is string => label !== undefined),
		datasets: [
			{
				label: "Number of Entries",
				data,
				backgroundColor: "rgba(153, 102, 255, 0.6)",
				borderColor: "rgba(153, 102, 255, 1)",
				borderWidth: 1,
			},
		],
	}
}
