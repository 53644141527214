import { removeIncompatibleSearchParameters } from "@helpers/removeIncompatibleSearchParameters"
import {
	emissionPageSearchSchema,
	validateEmissionSearchParams,
} from "@routes/clients.$clientName/_sopPageLayout/emission"
import { createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute("/_sopPageLayout/emission")({
	component: RouteComponent,
	validateSearch: validateEmissionSearchParams,
	search: {
		middlewares: [removeIncompatibleSearchParameters(emissionPageSearchSchema)],
	},
})

function RouteComponent() {
	return null
}
