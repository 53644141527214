import { removeIncompatibleSearchParameters } from "@helpers/removeIncompatibleSearchParameters"
import {
	consumptionPageSearchSchema,
	validateConsumptionSearchParams,
} from "@routes/clients.$clientName/_sopPageLayout/consumption"
import { createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute("/_sopPageLayout/consumption")({
	component: RouteComponent,
	validateSearch: validateConsumptionSearchParams,
	search: {
		middlewares: [removeIncompatibleSearchParameters(consumptionPageSearchSchema)],
	},
})

function RouteComponent() {
	return null
}
