import { useSearch } from "@tanstack/react-router"
import { LoadingCircle } from "@ui-lib/src"
import { common } from "@utils/common"
import { requestApi2 } from "@utils/http"
import {
	createIntervalOffsetV2,
	endpoints,
	GetTimeseriesDataArgs,
	SourceKey,
	TimeseriesData,
} from "api"
import { ReactElement, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { KpiWidgetData, TriggerKpiModalFunc } from "./KpiWidget"
import { formatNumber2String as formatNumber, KpiToFetchProps } from "./utils"

type KpiLine = {
	value: string
	preLabel?: string
}

type KpiCardData = {
	header?: string
	kpiLines: KpiLine[]
}

export function findValeOfKeyLike<V>(map: Map<string, V>, target: string): V | undefined {
	let key: string | undefined
	for (const candidate of map.keys()) {
		if (candidate.includes(target)) {
			key = candidate
			break
		}
	}
	if (key == undefined) {
		return
	}
	return map.get(key)
}

export const getSensorIdFromTopic = (topic: string, plantId: string): number | undefined => {
	const topicSplited = topic.split("/")
	const plant = findValeOfKeyLike(common.plantDataFromOwnerAndName, plantId.toLowerCase())
	const asset = plant?.assets.find((a) => a.display_name === topicSplited[3])
	const sensorId = asset?.sensors.find((s) => s.display_name === topicSplited[4])?.id

	return sensorId
}

function ComponentBody({
	kpiToFetch,
	kpiToFetch0,
}: {
	kpiToFetch: KpiToFetchProps[]
	kpiToFetch0: KpiToFetchProps
}): [ReactElement, TriggerKpiModalFunc?] {
	const { t } = useTranslation()
	const paramSelectedVessel = useSearch({ strict: false })?.vessel ?? ""
	const unit = kpiToFetch0.unit ?? ""
	const kpiName = t((kpiToFetch0.kpi_name ?? "unknown kpi name").toUpperCase())

	const [kpiValues, setKpiValues] = useState<KpiCardData>({ kpiLines: [] })
	const [loaded, setLoaded] = useState(false)
	useEffect(() => {
		const abortController = new AbortController()
		const apiCall = async () => {
			try {
				const resTotal: number[] = []
				const prelabels: (string | undefined)[] = []
				let tmp_header: string | undefined

				for (const kpiToFecthData of kpiToFetch) {
					if (!Array.isArray(kpiToFecthData.sensor)) {
						continue
					}

					const sensorId = getSensorIdFromTopic(
						kpiToFecthData.sensor[0] ?? "",
						paramSelectedVessel ?? ""
					)

					if (sensorId !== undefined) {
						const postData: GetTimeseriesDataArgs = {
							sensorIds: [],
							interval: createIntervalOffsetV2(-5),
						}
						const postDataMl: GetTimeseriesDataArgs = {
							sensorIds: [],
							interval: createIntervalOffsetV2(-720),
						}
						if (kpiToFecthData.source === SourceKey.SENSORDATA) {
							postData.sensorIds.push(sensorId)
						} else if (kpiToFecthData.source === SourceKey.MLDATA) {
							postDataMl.sensorIds.push(sensorId)
						}

						const resCombined: TimeseriesData = { sensors: [] }
						const res = await requestApi2(endpoints.getTimeseriesData4, postData)
						if (res !== null) {
							resCombined.sensors = [...resCombined.sensors, ...res.sensors]
						}
						const resMl = await requestApi2(endpoints.getTimeseriesData4, postDataMl)
						if (resMl !== null) {
							const resMlTransformed = resMl.sensors.map((sensorData) => {
								return {
									id: sensorData.id,
									data: sensorData.data.map((dataPoint) => {
										return [dataPoint[0], dataPoint[1]] as [Date, number]
									}),
								}
							})
							resCombined.sensors = [...resCombined.sensors, ...resMlTransformed]
						}
						if (resCombined.sensors.length === 0) {
							console.log(`failed to get kpi data with args: ${postData}`)
							continue
						}
						if (kpiToFecthData.header !== undefined) {
							tmp_header = kpiToFecthData.header
						}
						const resCombinedSensor0 = resCombined.sensors[0]?.data[0]
						if (resCombinedSensor0 !== undefined) {
							const add = resCombinedSensor0[1] // "add" variable is important to update resTotal properly
							if (add !== undefined) {
								resTotal.push(add)
								prelabels.push(kpiToFecthData.pre_label)
							}
						}
					} else {
						console.log(`failed to get sensorId for KPI: ${kpiToFecthData.sensor}`)
					}
				}

				if (Array.isArray(kpiToFetch0.sensor)) {
					const multiplier = kpiToFetch0.multiplier ?? 1

					if (paramSelectedVessel !== undefined) {
						const numberList = resTotal.map((x) => formatNumber(x * multiplier))
						// let kpiList: kpiValue[] = []
						var kpiList: KpiLine[] = numberList.map(function (e, i) {
							return { preLabel: prelabels[i], value: e }
						})
						var kpiCardData: KpiCardData = {
							kpiLines: kpiList,
							header: tmp_header,
						}
						// for (let item of numberList) {
						// 	kpiList.push({ preLabel: "", value: item })
						// }
						kpiCardData.kpiLines.sort((a, b) => {
							return b.value.length - a.value.length
						})
						setKpiValues(kpiCardData)
					} else {
						const kpiList: KpiLine[] = [
							{
								value: formatNumber(
									resTotal
										.map((x) => x * multiplier)
										.reduce((accumulator, element) => {
											return accumulator + element
										}, 0)
								),
							},
						]
						setKpiValues({ header: tmp_header, kpiLines: kpiList })
					}
				}

				setLoaded(true)
			} catch (err) {
				if (!abortController.signal.aborted) {
					throw err
				}
			}
		}

		apiCall()
		return () => {
			abortController.abort()
		}
	}, [kpiToFetch])

	const triggerKpiModal: TriggerKpiModalFunc = (setKpiData, setIsKpiPlotOpen, plantId) => {
		if (kpiToFetch0 === undefined) {
			setKpiData(undefined)
			setIsKpiPlotOpen(false)
		} else {
			let sensors: (number | undefined)[] = []
			const displayName: string[] = []
			for (const kpiToFecthData of kpiToFetch) {
				if (Array.isArray(kpiToFecthData.sensor)) {
					sensors = [
						...sensors,
						...kpiToFecthData.sensor.map((s) => getSensorIdFromTopic(s, plantId)),
					]
					for (const sensorName of kpiToFecthData.sensor) {
						const sensorNameSplit = sensorName.split("/")
						const sensorNameSplit2 =
							sensorNameSplit[sensorNameSplit.length - 1]?.split("_SE")
						const sensorNameFormated = sensorNameSplit2?.[0]?.replace("_", " ")
						displayName.push(
							`${kpiToFecthData.owner_name}/${kpiToFecthData.plant_name}/${
								sensorNameFormated ?? "unknown sensor name"
							}`
						)
					}
				} else {
					sensors = [...sensors, getSensorIdFromTopic(kpiToFecthData.sensor[0], plantId)]
					displayName.push(
						`${kpiToFecthData.owner_name}/${kpiToFecthData.plant_name}/${kpiToFecthData.component_name}`
					)
				}
			}
			const kpiData: KpiWidgetData = {
				clientId: kpiToFetch0.clientId,
				kpiName: kpiName,
				sensors: sensors,
				aggregation: kpiToFetch0.aggregationMethod,
				combineMethod: kpiToFetch0.combine_method,
				labels: displayName,
				unit: kpiToFetch0.unit,
				source: kpiToFetch0.source,
				multiplier: kpiToFetch0.multiplier,
			}
			setKpiData(kpiData)
			setIsKpiPlotOpen(true)
		}
	}

	return [
		!loaded ? (
			<LoadingCircle containerHeightWithUnit={"132.55px"} />
		) : (
			<div
				className={`${
					kpiValues.kpiLines.length > 0 && kpiValues.kpiLines[0]?.preLabel !== undefined
						? "h-[142.55px]"
						: "my-auto flex h-[132.55px] content-center"
				}`}
				style={{
					paddingTop:
						kpiValues.kpiLines.length > 0 &&
						kpiValues.kpiLines[0]?.preLabel === undefined
							? (4 + 108 - kpiValues.kpiLines.length * 36) / 2
							: undefined,
				}}
			>
				<div className="flex flex-col justify-center w-full">
					<p className="mb-4 mt-0 flex justify-center text-title3 font-bold leading-[20px]">
						{kpiValues.header !== undefined ? kpiValues.header : ""}
					</p>
					{kpiValues.kpiLines.map((kpi_val, index) => (
						<div key={index} className="flex justify-center mt-0 ">
							{kpiValues.kpiLines.length < 4 ? (
								<p className="text-title3 font-bold leading-[36px]">
									{kpi_val.preLabel !== undefined ? kpi_val.preLabel + ": " : ""}
									{kpi_val.value}{" "}
									<span className="font-normal text-button">{unit}</span>
								</p>
							) : (
								<p className="text-[14px] font-bold leading-[18px]">
									{kpi_val.preLabel !== "" ? kpi_val.preLabel + ": " : ""}
									{kpi_val.value} <span className="font-normal">{unit}</span>
								</p>
							)}
						</div>
					))}
				</div>
			</div>
		),
		triggerKpiModal,
	]
}
export function useMultiValueWidget(
	kpiToFetch: KpiToFetchProps[]
): [ReactElement, TriggerKpiModalFunc?] {
	const { t } = useTranslation()
	const kpiToFetch0 = kpiToFetch[0]
	if (kpiToFetch0 === undefined) {
		return [<p className="h-12 mt-4 mb-12 text-center">{t("ERROR FETCHING KPI")}</p>, undefined]
	}
	return ComponentBody({ kpiToFetch, kpiToFetch0 })
}
