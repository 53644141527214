import { DateTime } from "luxon"
import { DataSetter } from "../utils/mapLayers"
import { createArrowIcon, getBearing } from "../utils/utils"

export const addVesselTrackWithSegments: DataSetter = (
	dispatch,
	queryClient,
	layerGroup,
	layerData,
	navigate,
	map
) => {
	if (layerGroup === undefined) return

	const { vesselsTrackSegmentsData } = layerData
	if (vesselsTrackSegmentsData === undefined) return

	const { intervalPoints, operationModeEvents } = vesselsTrackSegmentsData
	if (intervalPoints === undefined) return

	layerGroup.clearLayers()
	console.log(map?.getZoom())
	const segments = operationModeEvents?.segments
	const segments_0 = segments?.[0]
	if (segments !== undefined && segments_0 !== undefined) {
		// THERE IS DATA SEGMENTATION
		const segmentsRefIndex: { index: number; color: string }[] = []
		segmentsRefIndex.push({
			index: 0,
			color: segments_0.color,
		})
		const trackData = intervalPoints
		for (const segment of segments) {
			const tsSegment = DateTime.fromISO(segment.timestamp).toMillis()
			let index = trackData.findIndex((td) => tsSegment <= td.timestamp.getTime())
			if (index === -1) {
				index = trackData.length
			}
			segmentsRefIndex.push({ index: index, color: segment.color })
		}
		const segmentsRefIndexSorted = segmentsRefIndex.sort((a, b) => a.index - b.index)

		for (const [idx, segmentData] of segmentsRefIndexSorted.entries()) {
			const nextSegmentData = segmentsRefIndexSorted[idx + 1]
			if (nextSegmentData === undefined) {
				continue
			}
			layerGroup.addLayer(
				L.polyline(
					intervalPoints
						.slice(
							segmentData.index !== 0 ? segmentData.index - 1 : 0,
							nextSegmentData.index
						)
						.filter(
							(pointData) =>
								isNaN(pointData.latitude) === false &&
								isNaN(pointData.longitude) === false
						)
						.map((pointData) => [pointData.latitude, pointData.longitude]),
					{
						color: nextSegmentData.color,
						pane: "vesselsTrackPane",
						weight: 2,
					}
				)
			)
		}
		const trackPoints = intervalPoints
			.filter((pointData) => !isNaN(pointData.latitude) && !isNaN(pointData.longitude))
			.map((pointData) => L.latLng(pointData.latitude, pointData.longitude))

		if (trackPoints.length < 2) return
		if (map !== undefined && map?.getZoom() >= 10) {
			const arrowDistance =
				map?.getZoom() >= 13
					? 500
					: map?.getZoom() === 12
						? 1500
						: map?.getZoom() === 11
							? 5000
							: 10000

			let accumulatedDistance = 0
			let lastArrowIndex = 0
			for (let i = 1; i < trackPoints.length; i++) {
				const start = trackPoints[lastArrowIndex]
				const end = trackPoints[i]
				if (start === undefined || end === undefined) break

				accumulatedDistance += map.distance(start, end)

				if (accumulatedDistance >= arrowDistance) {
					const refSegmentIdx = segmentsRefIndexSorted.findIndex((s) => s.index - 1 >= i)
					let color1: string | undefined
					let color2: string | undefined

					if (refSegmentIdx !== -1) {
						if (refSegmentIdx === 0) {
							color1 = segmentsRefIndexSorted[refSegmentIdx]?.color
							color2 = segmentsRefIndexSorted[refSegmentIdx]?.color
						} else if (refSegmentIdx === segmentsRefIndexSorted.length - 1) {
							color1 = segmentsRefIndexSorted[refSegmentIdx]?.color
							color2 = segmentsRefIndexSorted[refSegmentIdx]?.color
						} else if (
							segmentsRefIndexSorted[refSegmentIdx] !== undefined &&
							segmentsRefIndexSorted[refSegmentIdx]?.index - 1 > i
						) {
							color1 = segmentsRefIndexSorted[refSegmentIdx]?.color
							color2 = segmentsRefIndexSorted[refSegmentIdx]?.color
						} else {
							color1 = segmentsRefIndexSorted[refSegmentIdx]?.color
							color2 = segmentsRefIndexSorted[refSegmentIdx + 1]?.color
						}
					}
					const angle = getBearing(start, end)
					const arrowIcon = createArrowIcon(angle, color1, color2)

					const arrowMarker = L.marker(end, {
						icon: arrowIcon,
						pane: "vesselsTrackPane",
						zIndexOffset: 10000,
					})
					layerGroup.addLayer(arrowMarker)

					lastArrowIndex = i
					accumulatedDistance = 0
				}
			}
		}
	} else {
		// THERE IS NO DATA SEGMENTATION
		layerGroup.addLayer(
			L.polyline(
				intervalPoints
					.filter(
						(pointData) =>
							isNaN(pointData.latitude) === false &&
							isNaN(pointData.longitude) === false
					)
					.map((pointData) => [pointData.latitude, pointData.longitude]),
				{
					color: `hsl(0, 0%, 5%)`,
					pane: "vesselsTrackPane",
					// dashArray: "8",
					weight: 2,
				}
			)
		)

		const trackPoints = intervalPoints
			.filter((pointData) => !isNaN(pointData.latitude) && !isNaN(pointData.longitude))
			.map((pointData) => L.latLng(pointData.latitude, pointData.longitude))

		if (trackPoints.length < 2) return
		if (map !== undefined && map?.getZoom() >= 10) {
			const arrowDistance =
				map?.getZoom() >= 13
					? 500
					: map?.getZoom() === 12
						? 1500
						: map?.getZoom() === 11
							? 5000
							: 10000

			let accumulatedDistance = 0
			let lastArrowIndex = 0
			for (let i = 1; i < trackPoints.length; i++) {
				const start = trackPoints[lastArrowIndex]
				const end = trackPoints[i]
				if (start === undefined || end === undefined) break

				accumulatedDistance += map.distance(start, end)

				if (accumulatedDistance >= arrowDistance) {
					const angle = getBearing(start, end)
					const arrowIcon = createArrowIcon(angle)

					const arrowMarker = L.marker(end, {
						icon: arrowIcon,
						pane: "vesselsTrackPane",
						zIndexOffset: 10000,
					})
					layerGroup.addLayer(arrowMarker)

					lastArrowIndex = i
					accumulatedDistance = 0
				}
			}
		}
	}
}
