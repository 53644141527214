const ListOfVessels = {
	// ======================
	// ALTERA VESSELS
	// ======================
	"Altera Thule": "/assets/img/vessels/altera/Altera Thule.jpg",
	"Lambada Spirit": "/assets/img/vessels/altera/Lambada Spirit.jpg",
	"Norse Spirit": "/assets/img/vessels/altera/Norse Spirit.jpg",
	"Scott Spirit": "/assets/img/vessels/altera/Scott Spirit.jpg",
	"Sertanejo Spirit": "/assets/img/vessels/altera/Sertanejo Spirit.jpg",

	// ===========
	// DOF VESSELS
	// ===========
	Geoholm: "/assets/img/vessels/dof/Geoholm.jpg",
	"Skandi Acergy": "/assets/img/vessels/dof/Skandi Acergy.jpg",
	"Skandi Achiever": "/assets/img/vessels/dof/Skandi Achiever.jpg",
	"Skandi Acu": "/assets/img/vessels/dof/Skandi Acu.jpg",
	"Skandi Africa": "/assets/img/vessels/dof/Skandi Africa.jpg",
	"Skandi Amazonas": "/assets/img/vessels/dof/Skandi Amazonas.jpg",
	"Skandi Angra": "/assets/img/vessels/dof/Skandi Angra.jpg",
	"Skandi Atlantic": "/assets/img/vessels/dof/Skandi Atlantic.jpg",
	"Skandi Aukra": "/assets/img/vessels/dof/Skandi Aukra.jpg",
	"Skandi Barra": "/assets/img/vessels/dof/Skandi Barra.jpg",
	"Skandi Buzios": "/assets/img/vessels/dof/Skandi Buzios.jpg",
	"Skandi Caledonia": "/assets/img/vessels/dof/Skandi Caledonia.jpg",
	"Skandi Carla": "/assets/img/vessels/dof/Skandi Carla.jpg",
	"Skandi Chieftain": "/assets/img/vessels/dof/Skandi Chieftain.jpg",
	"Skandi Commander": "/assets/img/vessels/dof/Skandi Commander.jpg",
	"Skandi Darwin": "/assets/img/vessels/dof/Skandi Darwin.jpg",
	"Skandi Emerald": "/assets/img/vessels/dof/Skandi Emerald.jpg",
	"Skandi Feistein": "/assets/img/vessels/dof/Skandi Feistein.jpg",
	"Skandi Flora": "/assets/img/vessels/dof/Skandi Flora.jpg",
	"Skandi Fluminense": "/assets/img/vessels/dof/Skandi Fluminense.jpg",
	"Skandi Gamma": "/assets/img/vessels/dof/Skandi Gamma.jpg",
	"Skandi Hawk": "/assets/img/vessels/dof/Skandi Hawk.jpg",
	"Skandi Hera": "/assets/img/vessels/dof/Skandi Hera.jpg",
	"Skandi Hercules": "/assets/img/vessels/dof/Skandi Hercules.jpg",
	"Skandi Hugen": "/assets/img/vessels/dof/Skandi Hugen.jpg",
	"Skandi Iceman": "/assets/img/vessels/dof/Skandi Iceman.jpg",
	"Skandi Iguacu": "/assets/img/vessels/dof/Skandi Iguacu.jpg",
	"Skandi Ipanema": "/assets/img/vessels/dof/Skandi Ipanema.jpg",
	"Skandi Jupiter": "/assets/img/vessels/dof/Skandi Jupiter.jpg",
	"Skandi Kvitsøy": "/assets/img/vessels/dof/Skandi Kvitsøy.jpg",
	"Skandi Marøy": "/assets/img/vessels/dof/Skandi Marøy.jpg",
	"Skandi Mercury": "/assets/img/vessels/dof/Skandi Mercury.jpg",
	"Skandi Mongstad": "/assets/img/vessels/dof/Skandi Mongstad.jpg",
	"Skandi Niterói": "/assets/img/vessels/dof/Skandi Niterói.jpg",
	"Skandi Nova": "/assets/img/vessels/dof/Skandi Nova.jpg",
	"Skandi Olinda": "/assets/img/vessels/dof/Skandi Olinda.jpg",
	"Skandi Olympia": "/assets/img/vessels/dof/Skandi Olympia.jpg",
	"Skandi Paraty": "/assets/img/vessels/dof/Skandi Paraty.jpg",
	"Skandi Patagonia": "/assets/img/vessels/dof/Skandi Patagonia.jpg",
	"Skandi Recife": "/assets/img/vessels/dof/Skandi Recife.jpg",
	"Skandi Salvador": "/assets/img/vessels/dof/Skandi Salvador.jpg",
	"Skandi Seven": "/assets/img/vessels/dof/Skandi Seven.jpg",
	"Skandi Singapore": "/assets/img/vessels/dof/Skandi Singapore.jpg",
	"Skandi Skansen": "/assets/img/vessels/dof/Skandi Skansen.jpg",
	"Skandi Urca": "/assets/img/vessels/dof/Skandi Urca.jpg",
	"Skandi Vega": "/assets/img/vessels/dof/Skandi Vega.jpg",
	"Skandi Vinland": "/assets/img/vessels/dof/Skandi Vinland.jpg",

	// ======================
	// NCT OFFSHORE VESSELS
	// ======================
	Freja: "/assets/img/vessels/nctOffshore/Freja.jpg",

	// ======================
	// OCEAN INFINITY VESSELS
	// ======================
	"Armada 78 01": "/assets/img/vessels/oceanInfinity/Armada 78 01.png",
	"Armada 78 02": "/assets/img/vessels/oceanInfinity/Armada 78 02.png",
	"Armada 78 03": "/assets/img/vessels/oceanInfinity/Armada 78 03.png",

	// =============
	// REMØY VESSELS
	// =============
	"Rem Eir": "/assets/img/vessels/remoey/Rem Eir.jpg",

	// ===============
	// SOLSTAD VESSELS
	// ===============
	"Normand Drott": "/assets/img/vessels/solstad/Normand Drott.jpg",
	"Normand Frontier": "/assets/img/vessels/solstad/Normand Frontier.jpg",
	"Normand Ocean": "/assets/img/vessels/solstad/Normand Ocean.jpg",
	"Normand Vision": "/assets/img/vessels/solstad/Normand Vision.jpg",

	// ==========
	// ST VESSELS
	// ==========
	// "Aqua Homborøy": "/assets/img/vessels/soelvtrans/Aqua Homborøy.jpg",
	// "Aqua Spa": "/assets/img/vessels/soelvtrans/Aqua Spa.jpg",
	// "Ronja Aurora": "/assets/img/vessels/soelvtrans/Ronja Aurora.jpg",
	"Ronja Azul": "/assets/img/vessels/soelvtrans/Ronja Azul.jpg",
	// "Ronja Borøy": "/assets/img/vessels/soelvtrans/Ronja Borøy.jpg",
	"Ronja Carrier": "/assets/img/vessels/soelvtrans/Ronja Carrier.jpg",
	"Ronja Challenger": "/assets/img/vessels/soelvtrans/Ronja Challenger.jpg",
	"Ronja Christopher": "/assets/img/vessels/soelvtrans/Ronja Christopher.jpg",
	"Ronja Commander": "/assets/img/vessels/soelvtrans/Ronja Commander.jpg",
	"Ronja Evolution": "/assets/img/vessels/soelvtrans/Ronja Evolution.jpg",
	"Ronja Explorer": "/assets/img/vessels/soelvtrans/Ronja Explorer.jpg",
	"Ronja Fjord": "/assets/img/vessels/soelvtrans/Ronja Fjord.jpg",
	"Ronja Harvester": "/assets/img/vessels/soelvtrans/Ronja Harvester.jpg",
	"Ronja Havsøy": "/assets/img/vessels/soelvtrans/Ronja Havsøy.jpg",
	Ronjafisk: "/assets/img/vessels/soelvtrans/Ronjafisk.jpg",
	// "Ronja Herøy": "/assets/img/vessels/soelvtrans/Ronja Herøy.jpg",
	"Ronja Huon": "/assets/img/vessels/soelvtrans/Ronja Huon.jpg",
	"Ronja Islander": "/assets/img/vessels/soelvtrans/Ronja Islander.jpg",
	"Ronja Kvaløy": "/assets/img/vessels/soelvtrans/Ronja Kvaløy.jpg",
	"Ronja Maløy": "/assets/img/vessels/soelvtrans/Ronja Maløy.jpg",
	"Ronja Merdø": "/assets/img/vessels/soelvtrans/Ronja Merdø.jpg",
	// "Ronja Mistral": "/assets/img/vessels/soelvtrans/Ronja Mistral.jpg",
	"Ronja Nordic": "/assets/img/vessels/soelvtrans/Ronja Nordic.jpg",
	"Ronja Nærøysund": "/assets/img/vessels/soelvtrans/Ronja Nærøysund.jpg",
	"Ronja Ocean": "/assets/img/vessels/soelvtrans/Ronja Ocean.jpg",
	"Ronja Polaris": "/assets/img/vessels/soelvtrans/Ronja Polaris.jpg",
	"Ronja Princess": "/assets/img/vessels/soelvtrans/Ronja Princess.jpg",
	"Ronja Queen": "/assets/img/vessels/soelvtrans/Ronja Queen.jpg",
	"Ronja Silver": "/assets/img/vessels/soelvtrans/Ronja Silver.jpg",
	"Ronja Skye": "/assets/img/vessels/soelvtrans/Ronja Skye.jpg",
	"Ronja Star": "/assets/img/vessels/soelvtrans/Ronja Star.jpg",
	"Ronja Storm": "/assets/img/vessels/soelvtrans/Ronja Storm.jpg",
	"Ronja Strand": "/assets/img/vessels/soelvtrans/Ronja Strand.jpg",
	"Ronja Sund": "/assets/img/vessels/soelvtrans/Ronja Sund.jpg",
	"Ronja Superior": "/assets/img/vessels/soelvtrans/Ronja Superior.jpg",
	"Ronja Supporter": "/assets/img/vessels/soelvtrans/Ronja Supporter.jpg",
	"Ronja Tind": "/assets/img/vessels/soelvtrans/Ronja Tind.jpg",
	"Ronja Tromøy": "/assets/img/vessels/soelvtrans/Ronja Tromøy.jpg",
	"Ronja Vest": "/assets/img/vessels/soelvtrans/Ronja Vest.jpg",
	"Ronja Viking": "/assets/img/vessels/soelvtrans/Ronja Viking.jpg",
	// "Ronja Vita": "/assets/img/vessels/soelvtrans/Ronja Vita.jpg",

	// ==========
	// VARD VESSELS
	// ==========
	"REV Ocean": "/assets/img/vessels/vard/REV Ocean.jpg",
}

export type ShipImgContainerProps = {
	shipName: string
}

export default function ShipImgContainer({ shipName }: ShipImgContainerProps) {
	return (
		<img
			className="border-gray-eee w-full border-b-1 object-cover"
			src={ListOfVessels[shipName] ?? "/assets/img/vessels/placeHolderImage.png"}
		/>
	)
}
