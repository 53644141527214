import { Row } from "@tanstack/react-table"
import { TableBodyProps } from "@ui-lib/src/components/Table/Table"
import { ReactElement } from "react"
import { FuelConsumptionTimelineTableItem } from "./OperationsTimelineContent"
import OperationsTimelineExpandedTableArea from "./OperationsTimelineExpandedTableArea"

export default function TableBodyOperationsTimelineNL<T>({
	columnsLayout,
	BodyContentLayout,
	rows,
}: TableBodyProps<T>): ReactElement {
	return (
		<div>
			{rows.map((row, index) => {
				const rowUniqueId = JSON.stringify(row.getAllCells().map((cell) => cell.getValue()))
				return (
					<div
						className={`group bg-white ${
							row.getIsExpanded() === false
								? `border-b-1 border-l-4 border-b-gray-300 border-l-transparent hover:bg-gray-f4`
								: `border-l-4 border-l-dipai-secondary-300 hover:border-l-dipai-secondary-600 hover:bg-gray-f4/30`
						}`}
						key={rowUniqueId}
					>
						<div
							className={`group grid h-[50px] select-none gap-4 overflow-hidden rounded-lg pl-3 pr-4 text-small ${columnsLayout} group-hover:bg-slate-100/100`}
						>
							<BodyContentLayout row={row} />
						</div>
						{row.getIsExpanded() === true ? (
							<OperationsTimelineExpandedTableArea
								row={row as unknown as Row<FuelConsumptionTimelineTableItem>}
							/>
						) : null}
					</div>
				)
			})}
		</div>
	)
}
