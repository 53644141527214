import { ModuleNameSOP } from "@routes/clients.$clientName/_sopPageLayout"
import { getWarningCondition, WARNING_DIALOG_HEIGHT } from "@sop/DashboardPage/DashboardPage"
import { Link, useLocation, useSearch } from "@tanstack/react-router"
import { FC, ReactElement, ReactNode, Suspense } from "react"
import { MdHome } from "react-icons/md"
import BrokenLinkIcon from "../../../assets/icons/BrokenLinkIcon.svg"
import { Button } from "../../Button"
import { LoadingCircle } from "../../Loading"
import { usePageLayoutContext } from "../layoutContext"
import { HeaderLayout } from "./HeaderLayout"

export type ModulesContraint = {
	constraintName: string
	vessels: string[]
	availableModules: string[]
	placeHolderComponent?: ReactElement
}
export interface IBodyLayoutProps {
	moduleConstraints: ModulesContraint[]
	placeHolderComponent?: ReactElement
	customerName?: string
}

export const BodyLayout: FC<IBodyLayoutProps> = (props: IBodyLayoutProps) => {
	const { placeHolderComponent } = props
	const selectedModule = useLocation().pathname.split("/").at(-1) as ModuleNameSOP
	const { selectedModuleMetadata, translationFunc } = usePageLayoutContext()

	const paramSelectedVessel =
		useSearch({
			strict: false,
		}).vessel ?? ""

	const currentVesselConstraints: ModulesContraint[] = props.moduleConstraints.filter(
		(constraint) => constraint.vessels.includes(paramSelectedVessel)
	)

	const constraintsWithCurrentModule: ModulesContraint[] = currentVesselConstraints.filter(
		(constraint) => constraint.availableModules.includes(selectedModule)
	)
	const alternativeContent: ReactNode | undefined =
		constraintsWithCurrentModule.length > 0
			? selectedModuleMetadata?.alternativeContent?.find(
					(alternativeContent) =>
						alternativeContent.constraintName ===
						constraintsWithCurrentModule[0]?.constraintName
				)?.content
			: undefined

	const warningCondition = getWarningCondition()

	return (
		<div className={`bg-dipai-secondary-200/80 h-full w-[calc(100%)] overflow-auto`}>
			{currentVesselConstraints.length > 0 && constraintsWithCurrentModule.length === 0 ? (
				<>
					<HeaderLayout
						label={selectedModule}
						translationFunc={translationFunc}
						customerName={props.customerName}
					/>
					{currentVesselConstraints?.[0]?.placeHolderComponent}
				</>
			) : placeHolderComponent !== undefined &&
			  paramSelectedVessel === "" &&
			  selectedModuleMetadata !== undefined &&
			  selectedModuleMetadata.config?.hideVesselSelection !== true ? (
				<>
					<HeaderLayout
						label={selectedModule}
						translationFunc={translationFunc}
						customerName={props.customerName}
					/>
					{placeHolderComponent}
				</>
			) : (
				<Suspense
					fallback={
						<div className={`relative h-full w-full`}>
							<HeaderLayout
								label={selectedModule}
								translationFunc={translationFunc}
								customerName={props.customerName}
							/>
							<div className={`absolute top-0 left-[calc(50%-30px)]`}>
								<LoadingCircle containerHeightWithUnit="100vh" />
							</div>
						</div>
					}
				>
					{alternativeContent !== undefined
						? alternativeContent
						: (selectedModuleMetadata?.content ?? (
								<div
									style={{
										height:
											warningCondition === true
												? `calc(100vh - ${WARNING_DIALOG_HEIGHT}px)`
												: `100vh`,
									}}
									className={`bg-dipai-secondary-901 text-white`}
								>
									<div className="flex h-[52px] flex-row items-center justify-end">
										<p className="text-small pr-4">
											{translationFunc !== undefined
												? translationFunc("PROBLEMS? PLEASE CONTACT ")
												: "Problems? Please contact "}
											<span className="font-semibold underline">
												Dipai Support
											</span>
										</p>
									</div>

									<div className="flex h-[calc(100%-104px)] w-full flex-row items-center justify-center">
										<div className={`flex flex-col items-center`}>
											<img
												className="mb-8 w-[100px]"
												src={BrokenLinkIcon}
												alt=""
											/>
											<p className="text-title3 mb-5 h-[49px] text-center">
												{translationFunc !== undefined
													? translationFunc("THIS PAGE ISN'T AVAILABLE")
													: "This page isn't available"}
											</p>
											<p className="text-subheadline m-auto mb-8 text-center">
												{translationFunc !== undefined
													? translationFunc(
															"THE LINK MAY BE BROKEN, OR YOU ARE TRYING TO ACCESS A PAGE THAT DOESN'T EXIST"
														)
													: "The link may be broken, or you are trying to access a page that doesn't exist."}
											</p>
											<Link
												to={"/map"}
												// to={"/fleet overview"}
											>
												<Button>
													<Button.Icon>{MdHome}</Button.Icon>
													<Button.Label>
														{translationFunc !== undefined
															? translationFunc("GO TO HOME")
															: "Go to home"}
													</Button.Label>
												</Button>
											</Link>
										</div>
									</div>
								</div>
							))}
				</Suspense>
			)}
		</div>
	)
}
