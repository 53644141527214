import { useGetUniSeaVessels } from "@hooks/useGetUniSeaVessels"
import { useGetVesselsConnectionStatusData } from "@hooks/useGetVesselsConnectionStatusData"
import { useAppDispatch, useAppSelector } from "@redux/app/hooks"
import {
	getPanelState,
	updateSidePanelState,
} from "@redux/features/mapSidePanelControl/mapSidePanelControlSlice"
import MapPage from "@sop/MapPage/MapPageContent"
import { common } from "@utils/common"
import { AppStructure, PlantObject } from "api"
import { useEffect, useState } from "react"

export default function HomePage({ props }: { props: AppStructure }) {
	const panelState = useAppSelector(getPanelState)

	const dispatch = useAppDispatch()

	const plants: { [plantName: string]: PlantObject } | undefined = Array.from(
		common.plantDataFromName
	).reduce((acc, [key, value]) => {
		acc[key] = value
		return acc
	}, {})
	useGetVesselsConnectionStatusData(plants)
	useGetUniSeaVessels(plants ?? {})

	const [width, setWidth] = useState(document.body.clientWidth)
	function updateSize() {
		setWidth(document.body.clientWidth)
	}

	useEffect(() => {
		updateSize()

		dispatch(
			updateSidePanelState({
				...panelState,
				isOpen: false,
			})
		)
		const debouncedUpdateChartHeightExpression = updateSize //debounce(updateSize, 0)
		window.addEventListener("resize", debouncedUpdateChartHeightExpression)

		return () => {
			window.removeEventListener("resize", debouncedUpdateChartHeightExpression)
		}
	}, [])

	return (
		<div className={`-ml-[1.5rem] h-[calc(100vh-3.25rem)] w-[calc(100%+3rem)] overflow-hidden`}>
			<MapPage props={props} />
		</div>
	)
}
