/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { debounce } from "@ui-lib/src"
import { ChartConfiguration, ChartEvent, Chart as ChartJS, ChartType } from "chart.js"
declare module "chart.js" {
	interface PluginOptionsByType<TType extends ChartType> {
		crosshairV2?: CrossHairPluginProps
	}
}

type SyncEvent = CustomEvent<{
	chartId: string
	syncGroup: number | string
	original: ChartEvent
	xValue: number | undefined
}>

type DefaultOptions = {
	line?: {
		color: string
		width: number
		dashPattern: number[] // not sure about this type. check chartjs for references if needed.
	}
	sync?: {
		enabled: boolean
		group: number | string
		suppressTooltips: boolean
	}
	snap?: {
		enabled: boolean
	}
}
export type CrossHairPluginProps = DefaultOptions & {
	timestampGetter: (tRef: number) => void
	crosshair:
		| {
				timeout: ReturnType<typeof setTimeout> | undefined
				enabled: boolean
				suppressUpdate: boolean
				x: number | null
				suppressTooltips: boolean
				ignoreNextEvents: number
				syncEventHandler: ((e: SyncEvent) => void) | undefined
		  }
		| undefined
}

type MyEvent = Event & {
	x?: number | null
	y?: number | null
	native?: MyEvent | null
	stop?: boolean
	chartId?: string
}

const defaultOptions: Required<DefaultOptions> = {
	line: {
		color: "#F66",
		width: 1,
		dashPattern: [],
	},
	sync: {
		enabled: true,
		group: 1,
		suppressTooltips: false,
	},
	snap: {
		enabled: false,
	},
}

export const crosshairV2 = {
	id: "crosshairV2",
	afterInit: function (chart: ChartJS) {
		if (chart.config.options?.scales?.x === undefined) {
			return
		}

		const xScaleType = chart.config.options?.scales.x.type ?? ""

		if (["linear", "time", "category", "logarithmic"].includes(xScaleType) === false) return

		let pluginRef = chart.config.options?.plugins?.crosshairV2
		if (pluginRef === undefined) return

		if (Object.keys(pluginRef).length === 0) pluginRef = defaultOptions

		pluginRef = {
			...pluginRef,
			crosshair: {
				timeout: undefined,
				enabled: false,
				suppressUpdate: false,
				x: null,
				suppressTooltips: false,
				ignoreNextEvents: 0,
			},
		}

		chart.config.options.plugins!.crosshairV2 = pluginRef

		const syncEnabled =
			chart?.config?.options?.plugins?.crosshairV2?.sync?.enabled ??
			defaultOptions.sync.enabled

		if (syncEnabled === true) {
			chart.config.options.plugins!.crosshairV2.crosshair!.syncEventHandler = debounce(
				(e: SyncEvent) =>
					function (this: typeof crosshairV2, e: SyncEvent) {
						this.handleSyncEvent(chart, e)
					}.bind(this)(e),
				0
			)
			window.addEventListener(
				"sync-event",
				chart.config.options!.plugins!.crosshairV2!.crosshair!
					.syncEventHandler! as EventListener
			)
		}
	},
	destroy: function (chart: ChartJS) {
		const options = chart?.config?.options?.plugins?.crosshairV2
		const syncEnabled = options?.sync?.enabled ?? defaultOptions.sync.enabled
		const syncEventHandler = options?.crosshair?.syncEventHandler

		if (syncEventHandler === undefined) return

		if (syncEnabled === true)
			window.removeEventListener("sync-event", syncEventHandler as EventListener)
	},
	getXScale: function (chart: ChartJS) {
		const xAxisId = chart.getDatasetMeta(0).xAxisID
		return chart.data.datasets.length > 0 && xAxisId !== undefined
			? chart.scales[xAxisId]
			: null
	},
	getYScale: function (chart: ChartJS) {
		const yAxisId = chart.getDatasetMeta(0).yAxisID
		return yAxisId !== undefined ? chart.scales[yAxisId] : null
	},
	handleSyncEvent: function (chart: ChartJS, e: SyncEvent) {
		const options = chart?.config?.options?.plugins?.crosshairV2
		const syncGroup = options?.sync?.group ?? defaultOptions.sync.group

		// stop if the sync event was fired from this chart
		if (e.detail.chartId === chart.id) return

		// stop if the sync event was fired from a different group
		if (e.detail.syncGroup !== syncGroup) return

		// stop if sync event should not be updated
		if (options?.crosshair?.suppressUpdate === true) return

		const xScale = this.getXScale(chart)
		if (xScale === null || xScale === undefined) return

		// if e.detail.orginal is undefined, the event was sent by a custom event emitter outside a chart
		const myEvent: MyEvent = new Event(
			e.detail?.original === undefined
				? "mousemove"
				: e.detail?.original.type == "click"
					? "mousemove"
					: e.detail.original.type
		)
		myEvent.x = xScale.getPixelForValue(e.detail.xValue ?? 0)
		myEvent.y = e.detail?.original !== undefined ? e.detail.original.y : chart.canvas.height / 2
		myEvent.native = e.detail?.original !== undefined ? e.detail?.original.native : null
		myEvent.stop = true
		myEvent.chartId = e.detail.chartId

		chart.canvas.dispatchEvent(myEvent)
	},
	afterEvent: function (
		chart: ChartJS,
		args: {
			event: ChartEvent
			replay: boolean
			changed?: boolean
			cancelable: false
			inChartArea: boolean
		},
		props: CrossHairPluginProps
	) {
		const e = args.event
		if (e === null) return

		const native = e.native as MyEvent | null

		if (chart.id === native?.chartId && args.inChartArea === false) {
			// remove tooltip generated in the previous render when cursor goes outside the chart area
			const syncGroup =
				chart?.config?.options?.plugins?.crosshairV2?.sync?.group ??
				defaultOptions.sync.group

			const stopPropagation = native !== null ? native.stop : false
			if (stopPropagation === undefined || stopPropagation !== true) {
				const event = new CustomEvent("sync-event", {
					bubbles: true,
					detail: {
						chartId: native?.chartId ?? chart.id, //chart.id,
						syncGroup: syncGroup,
						original: e,
						xValue: -1,
					},
				})
				window.dispatchEvent(event)
			}
			return
		}

		const pluginRef = chart.config.options?.plugins?.crosshairV2?.crosshair
		if (pluginRef === undefined) return true

		const xValue = e.x //Number.isNaN(e.x) !== true ? e.x : native.detail.x
		if (xValue === null) return

		const xScale = chart.scales.x
		if (xScale === undefined) return

		const xScaleType = xScale.type

		if (["linear", "time", "category", "logarithmic"].includes(xScaleType) === false) return

		if (pluginRef.ignoreNextEvents !== undefined && pluginRef.ignoreNextEvents > 0) {
			pluginRef.ignoreNextEvents -= 1
			return
		}

		const syncEnabled =
			chart?.config?.options?.plugins?.crosshairV2?.sync?.enabled ??
			defaultOptions.sync.enabled
		const syncGroup =
			chart?.config?.options?.plugins?.crosshairV2?.sync?.group ?? defaultOptions.sync.group

		// fire event for all other linked charts
		const stopPropagation = native !== null ? native.stop : false
		if (stopPropagation === undefined || (stopPropagation !== true && syncEnabled === true)) {
			const event = new CustomEvent("sync-event", {
				bubbles: true,
				detail: {
					chartId: native?.chartId ?? chart.id,
					syncGroup: syncGroup,
					original: e,
					xValue: xScale.getValueForPixel(xValue),
				},
			})

			window.dispatchEvent(event)
			if (pluginRef.timeout !== undefined) {
				clearTimeout(pluginRef.timeout as number)
			}

			if (
				props.timestampGetter !== undefined &&
				xScale !== null &&
				xValue !== null &&
				xScale.getValueForPixel(xValue) !== undefined
			) {
				props.timestampGetter(xScale.getValueForPixel(xValue)!)
			}
		}

		// suppress tooltips for linked charts
		const suppressTooltips =
			chart?.config?.options?.plugins?.crosshairV2?.sync?.suppressTooltips ??
			defaultOptions.sync.suppressTooltips

		pluginRef.suppressTooltips = suppressTooltips

		pluginRef.enabled =
			e.type !== "mouseout" &&
			xValue > xScale.getPixelForValue(xScale.min) &&
			xValue < xScale.getPixelForValue(xScale.max)

		// if (!pluginRef.enabled && pluginRef.suppressUpdate === false) {
		// 	if (xValue >= xScale.getPixelForValue(xScale.max)) {
		// 		// suppress future updates to prevent endless redrawing of chart
		// 		pluginRef.suppressUpdate = true
		// 		// chart.update("none") //this line may add stack overflow bug, be aware!!
		// 	}

		// 	return false
		// }
		// pluginRef.suppressUpdate = false
		pluginRef.x = xValue
		return
	},
	beforeDatasetsDraw(chart: ChartJS) {
		const chartType = (chart.config as ChartConfiguration)?.type
		if (chartType !== null && chartType === "bar") {
			this.drawBackgroundHighlight(chart)
		}
	},
	afterDraw: function (chart: ChartJS) {
		// const pluginRef = chart.config.options?.plugins?.crosshairV2?.crosshair
		// if (pluginRef === undefined) {
		// 	return
		// }
		// if (pluginRef.enabled === undefined) {
		// 	return
		// }
		// if (pluginRef.enabled === false) {
		// 	return
		// }
		this.drawTraceLine(chart)
		// return false
	},
	beforeTooltipDraw: function (chart: ChartJS) {
		const pluginRef = chart.config.options?.plugins?.crosshairV2?.crosshair
		if (pluginRef === undefined) return true

		if (pluginRef.suppressTooltips === undefined) return false

		if (pluginRef.suppressUpdate === true) return false

		return true
	},
	drawBackgroundHighlight: function (chart: ChartJS) {
		const options = chart.config.options?.plugins?.crosshairV2

		const pluginRef = options?.crosshair
		if (pluginRef === undefined) {
			return
		}
		const yScale = this.getYScale(chart)

		if (yScale === null || yScale === undefined) {
			return
		}
		const ctx = chart.ctx
		ctx.save()

		const activeElements = chart.getActiveElements()
		if (activeElements.length === 0) {
			ctx.restore()
			return
		}

		let minX = Infinity,
			maxX = -Infinity
		const topY = yScale.getPixelForValue(yScale.max)
		const bottomY = yScale.getPixelForValue(yScale.min)

		activeElements.forEach(({ element }) => {
			if (element === undefined || element === null) return
			const { width, x } = element.getProps(["width", "x"])
			if (width === undefined) return
			minX = Math.min(minX, x - width / 2)
			maxX = Math.max(maxX, x + width / 2)
		})

		ctx.fillStyle = "#2b4861"
		ctx.globalAlpha = 0.15
		ctx.fillRect(
			minX - (maxX - minX) / 12.5,
			topY,
			maxX - minX + (2 * (maxX - minX)) / 12.5,
			bottomY - topY
		)
		ctx.globalAlpha = 1
	},
	drawTraceLine: function (chart: ChartJS) {
		const options = chart.config.options?.plugins?.crosshairV2
		const pluginRef = options?.crosshair
		if (pluginRef === undefined) {
			return
		}
		const yScale = this.getYScale(chart)

		if (yScale === null || yScale === undefined) {
			return
		}
		const color = options?.line?.color ?? defaultOptions.line.color
		const snapEnabled = options?.snap?.enabled ?? defaultOptions.snap.enabled
		const chartType = (chart.config as ChartConfiguration)?.type

		const ctx = chart.ctx
		ctx.save()

		const activeElements = chart.getActiveElements()
		if (activeElements.length === 0) {
			ctx.restore()
			return
		}

		if (chartType !== null && chartType === "bar") {
			return
		}
		const lineWidth = options?.line?.width ?? defaultOptions.line.width
		if (lineWidth === 0) return

		const dashPattern = (options?.line?.dashPattern ??
			defaultOptions.line.dashPattern) as number[]

		let lineX = pluginRef.x

		if (snapEnabled === true && chart.getActiveElements().length > 0) {
			lineX = chart.getActiveElements()[0]?.element.x
		}
		if (lineX === undefined || lineX === null) {
			return
		}
		if (
			chart.isPointInArea({
				x: lineX,
				y: (yScale.getPixelForValue(yScale.max) + yScale.getPixelForValue(yScale.min)) / 2,
			}) === false
		) {
			return
		}

		chart.ctx.beginPath()
		chart.ctx.setLineDash(dashPattern)
		chart.ctx.moveTo(lineX, yScale.getPixelForValue(yScale.max))
		chart.ctx.lineWidth = lineWidth
		chart.ctx.strokeStyle = color
		chart.ctx.lineTo(lineX, yScale.getPixelForValue(yScale.min))
		chart.ctx.stroke()
		chart.ctx.setLineDash([])

		ctx.restore()
	},
}
