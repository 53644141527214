import { AppDispatch } from "@redux/app/store"
import { setFacilities } from "@redux/features/facilities/facilitiesSlice"
import { IconButton } from "@ui-lib/index"
import { requestApi2 } from "@utils/http"
import { Facility, endpoints } from "api"
import { Circle } from "leaflet"
import { useState } from "react"
import { MdCheck } from "react-icons/md"
import { QueryClient } from "react-query"

export const FacilityRadiusPopup = (args: {
	facility: Facility
	dispatch?: AppDispatch
	queryClient: QueryClient
	facilities: Facility[]
	circle: Circle
}) => {
	const updateRadius = async (radius: number) => {
		if (args.dispatch === undefined) {
			return
		}
		const res = await requestApi2(endpoints.setFacilityRadius, {
			facilityId: args.facility.id,
			radiusM: radius,
		})
		if (res === null) {
			return
		}
		args.circle.setRadius(newRadius)
		args.dispatch(
			setFacilities(
				args.facilities.map((f) =>
					f.id === args.facility.id ? { ...f, radiusM: newRadius } : f
				)
			)
		)
		args.queryClient.invalidateQueries({
			predicate: (query) => {
				return query.queryHash.includes("GetFacilitiesRadius")
			},
		})
	}
	const [newRadius, setNewRadius] = useState<number>(args.facility.radiusM)
	return (
		<div className={`text-small -m-1 flex w-fit flex-col items-start gap-1`}>
			<div className={`h-5 font-semibold`}>
				{`Name: `}
				<span className={`font-normal`}>{`${args.facility.name}`}</span>{" "}
			</div>
			<div className={`h-5 font-semibold`}>
				{`Type: `}
				<span className={`font-normal`}>{`${
					args.facility.type === "FISH_FARM" ? "FISH FARM" : args.facility.type
				}`}</span>
			</div>
			<div className={`flex w-full flex-row gap-4`}>
				<div className={`h-5 w-fit font-semibold`}>
					{`Lat: `}
					<span className={`font-normal`}>{`${args.facility.latitude.toFixed(2)}°`}</span>
				</div>
				<div className={`h-5 w-fit font-semibold`}>
					{`Lng: `}
					<span className={`font-normal`}>{`${args.facility.longitude.toFixed(
						2
					)}°`}</span>
				</div>
			</div>
			<div className={`flex h-5 flex-row items-center gap-2`}>
				<div className={`h-full font-semibold`}>{`Radius: `}</div>
				{args.dispatch === undefined ? (
					<div className={`h-5`}>{args.facility.radiusM}</div>
				) : (
					<>
						<input
							disabled={args.dispatch === undefined}
							defaultValue={args.facility.radiusM}
							className={`${
								args.dispatch === undefined
									? ""
									: "hover:border-dipai-secondary-500 focus:border-dipai-secondary-500 hover:cursor-pointer focus:border-2 focus:py-0 focus:pr-[1px] focus:pl-2 focus:ring-0 focus:outline-hidden"
							} border-gray-ccc/90 text-small h-full w-[100px] rounded-sm border-1 py-[1px] pr-[2px] pl-[9px] transition-colors ease-in-out`}
							type={"number"}
							min={0}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									updateRadius(newRadius)
								}
							}}
							onChange={(e) => setNewRadius(Number(e.target.value))}
						/>

						<IconButton
							className={`h-5 w-5`}
							variant={"filled-secondary"}
							size={"sm"}
							onClick={() => updateRadius(newRadius)}
						>
							<IconButton.Icon>{MdCheck}</IconButton.Icon>
						</IconButton>
					</>
				)}
			</div>
		</div>
	)
}
