import {
	AssetObject,
	endpoints,
	KPI,
	KPIAddArgs,
	KPIDeleteArgs,
	KPIEditArgs,
	KPIGetForAssetArgs,
	PlantObject,
} from "api"

import SingleSelect from "@components/plot/plotConfig/SingleSelect"
import { common } from "@utils/common"
import { requestApi2 } from "@utils/http"
import { useEffect, useState } from "react"
import Button from "./Button"

type ValueLabelPairPlant = {
	value: PlantObject
	label: string
}

type ValueLabelPairAsset = {
	value: AssetObject
	label: string
}

type ValueLabelPairKpi = {
	value: KPI
	label: string
}
interface TextAreaContent extends Object {
	id?: number | string
}
export default function KPIConfiguratorPage() {
	// const [plantKPIsArray, setPlantKPIsArray] = useState<PlantKPIs[]>([])
	const [plantSelector, setPlantSelector] = useState<ValueLabelPairPlant[]>([])
	const [selectedPlant, setSelectedPlant] = useState<ValueLabelPairPlant>()
	const [assetSelector, setAssetSelector] = useState<ValueLabelPairAsset[]>([])
	const [selectedAsset, setSelectedAsset] = useState<ValueLabelPairAsset>()
	const [kpiSelector, setKpiSelector] = useState<ValueLabelPairKpi[]>([])
	const [selectedKpi, setSelectedKpi] = useState<ValueLabelPairKpi>()
	const [kpi, setKPI] = useState<KPI>()
	const [newKPI, setNewKPI] = useState<boolean>(false)
	const [editKpiTextArea, setEditKpiTextArea] = useState<boolean>(false)
	const [addKpiTextArea, setAddKpiTextArea] = useState<boolean>(false)

	useEffect(() => {
		async function getKPIs() {
			const valueLabelPair: ValueLabelPairPlant[] = []

			common.plantDataFromOwnerAndName.forEach((item) =>
				item.assets.length > 0
					? valueLabelPair.push({ label: item.display_name, value: item })
					: {}
			)

			// plantData.map((item, index) =>
			// 	valueLabelPair.push({ label: item.name, value: item.name })
			// )

			// for (let item of valueLabelPair) {
			// 	let found_kpi_bool: boolean = false
			// 	for (let kpi_item of kpiData) {
			// 		if (item.label === kpi_item.plantName) {
			// 			item.value = kpi_item.
			// 			found_kpi_bool = true
			// 		}
			// 	}
			// 	if (!found_kpi_bool) {
			// 		item.label = item.label + " NO KPI"
			// 	}
			// }

			setPlantSelector(valueLabelPair)
		}

		async function getAllPlants() {}

		getKPIs()
		getAllPlants()
		console.log("hai")
	}, [])

	useEffect(() => {
		console.log("set selected plantos")
		const valueLabelPair: ValueLabelPairAsset[] = []
		const tmp2 = selectedPlant?.value
		if (tmp2 !== undefined) {
			tmp2.assets.map((item) =>
				valueLabelPair.push({ label: item.display_name, value: item })
			)
		}
		setAssetSelector(valueLabelPair)
		setSelectedAsset(undefined)
		setSelectedKpi(undefined)
		setAddKpiTextArea(false)
		setEditKpiTextArea(false)
		// setEditKpiTextArea(false)
	}, [selectedPlant])

	useEffect(() => {
		async function getKpisForAsset() {
			const valueLabelPair: ValueLabelPairKpi[] = []
			const assetValue = selectedAsset?.value
			if (assetValue !== undefined) {
				const arg: KPIGetForAssetArgs = {
					assetId: assetValue?.id,
				}
				const kpiData = await requestApi2(endpoints.KPIsGetForAsset, arg)

				if (kpiData !== null) {
					kpiData.map((item) =>
						valueLabelPair.push({
							label: item.display_name + " - " + item.kpi_name,
							value: item,
						})
					)
				}
			}
			setSelectedKpi(undefined)
			setKpiSelector(valueLabelPair)
			setAddKpiTextArea(false)
			setEditKpiTextArea(false)
		}
		getKpisForAsset()
	}, [selectedAsset])

	useEffect(() => {
		if (selectedKpi !== undefined) {
			setEditKpiTextArea(true)
			const textArea = document.getElementById("textarea") as HTMLTextAreaElement | null
			const tmp = selectedKpi
			if (textArea !== null && tmp !== undefined) {
				textArea.value = JSON.stringify(tmp.value, null, "\t")
			}
		} else {
			setEditKpiTextArea(false)
		}
	}, [selectedKpi])

	useEffect(() => {
		if (editKpiTextArea && selectedKpi !== undefined) {
			const textArea = document.getElementById("textarea") as HTMLTextAreaElement | null
			const tmp = selectedKpi
			if (textArea !== null && tmp !== undefined) {
				textArea.value = JSON.stringify(tmp.value, null, "\t")
			}
		}
	}, [editKpiTextArea])

	// function findAndSetAsset(target: string) {
	// 	// if (plant !== undefined){
	// 	setKPI(undefined)
	// 	setAsset(undefined)
	// 	let textArea: any = document.getElementById("textarea")
	// 	if (textArea !== null) {
	// 		textArea.value = ""
	// 	}
	// 	let kpiList: any = document.getElementById("kpi list")
	// 	if (kpiList !== null) {
	// 		kpiList.value = 0
	// 	}
	// 	for (let asset of selectedPlant!.KPIsOfAssets) {
	// 		if (asset.assetName === target) {
	// 			console.log("found asset")
	// 			setAsset(asset)
	// 		}
	// 	}
	// 	// }
	// }

	// function findAndSetKpi(target: string) {
	// 	console.log(target)

	// 	let textArea: any = document.getElementById("textarea")
	// 	setKPI(undefined)
	// 	if (textArea !== null) {
	// 		textArea.value = ""
	// 	}
	// 	for (let kpi of asset!.KPIs) {
	// 		if (kpi.dbId.toString() === target) {
	// 			setKPI(kpi)
	// 			if (textArea !== null) {
	// 				textArea.value = JSON.stringify(kpi.value, null, "\t")
	// 			}
	// 		}
	// 	}
	// }

	async function setNewKPIsetting() {
		const textArea = document.getElementById("textarea") as HTMLTextAreaElement | null
		if (textArea !== null) {
			const vall = JSON.parse(textArea.value) as TextAreaContent
			console.log(vall)
			if (vall.id !== undefined) {
				delete vall.id
			}
			console.log(vall)
			const args: KPIEditArgs = { dbId: selectedKpi!.value.id, value: vall }
			console.log(args)
			const message = `Are you sure you want to submit: \r\n ${textArea.value}`
			if (confirm(message) == true) {
				await requestApi2(endpoints.KPIsEdit, args)
				console.log("set new data")
			}
		} else {
			console.log("error textarea null")
		}
	}

	async function deleteKPI() {
		if (
			selectedPlant !== undefined &&
			selectedAsset !== undefined &&
			selectedKpi !== undefined
		) {
			const args: KPIDeleteArgs = {
				kpiId: selectedKpi.value.id,
			}
			const message = `Are you sure you want to delete: \r\n ${JSON.stringify(
				selectedKpi.value
			)} \r\n For asset ${selectedAsset.value.display_name}?`
			if (confirm(message) == true) {
				await requestApi2(endpoints.KPIsDelete, args)
			}
		}
	}

	async function addNewKPIToDb() {
		const textArea = document.getElementById("textarea") as HTMLTextAreaElement | null
		if (textArea !== null && selectedAsset !== undefined && selectedPlant !== undefined) {
			const vall = JSON.parse(textArea.value)
			console.log(vall)
			const args: KPIAddArgs = {
				assetId: selectedAsset.value.id,
				value: vall,
				plantId: selectedPlant.value.id,
			}
			console.log(args)
			const message = `Are you sure you want to submit: \r\n ${textArea.value}`
			if (confirm(message) == true) {
				await requestApi2(endpoints.KPIsAdd, args)
				console.log("added new kpi ")
			}
		} else {
			console.log("error textarea null")
		}
	}

	// async function addNewPlant() {
	// 	setSelectedPlant(undefined)
	// 	setAsset(undefined)
	// 	setKPI(undefined)
	// 	setAddNewPlant(true)
	// }

	useEffect(() => {
		if (addKpiTextArea === true) {
			const textArea = document.getElementById("textarea") as HTMLTextAreaElement | null
			if (textArea !== null) {
				const obj = {
					unit: "Hours",
					source: "sensordata",
					kpi_name: "Nogva/Norled/exampleplant/exampleasset/hour_counter",
					related_to: [],
					aggregation: "snap_first",
					display_mode: "multivalue",
					display_name: "Running Hours",
					combine_method: "add",
				}
				textArea.value = JSON.stringify(obj, null, "\t")
			}
		}
	}, [addKpiTextArea])

	async function addKPI() {
		setEditKpiTextArea(false)
		setAddKpiTextArea(true)
	}

	async function addNewKPI() {
		console.log("hsdf")
		const kpiList = document.getElementById("kpi list") as HTMLSelectElement | null
		if (kpiList !== null) {
			kpiList.value = "Choose kpi.."
		}
		setKPI(undefined)
		setNewKPI(true)
		const textArea = document.getElementById("textarea") as HTMLTextAreaElement | null
		if (textArea !== null) {
			const obj = {
				unit: "Hours",
				source: "sensordata",
				kpi_name: "Nogva/Norled/Solavågen/2131-1/hour_counter",
				related_to: [],
				aggregation: "snap_first",
				display_mode: "multivalue",
				display_name: "Running Hours",
				combine_method: "add",
			}
			textArea.value = JSON.stringify(obj, null, "\t")
		}
	}

	return (
		<>
			Select plant:
			<br></br>
			{plantSelector.length > 0 ? (
				<>
					{/* <select onChange={(e) => findAndSetPlant(e.target.value)}>
						<option key={"empty plant"}> {"Choose plant.."} </option>
						{plantKPIsArray.map((plantKPIs, index) => (
							<option key={"plant" + index.toString()}>
								{" "}
								{plantKPIs.plantName}{" "}
							</option>
						))}
					</select> */}
					<SingleSelect
						options={plantSelector}
						value={selectedPlant}
						onChange={(e: ValueLabelPairPlant) => setSelectedPlant(e)}
						disabled={false}
					/>
					{/* <Button label={"Add new plant"} onClick={() => addNewPlant()}></Button> */}
				</>
			) : (
				""
			)}
			{/* {addNewPlantBool ? (
				<SingleSelect
					options={omg}
					value={{ value: plant, label: plant?.plantName }}
					onChange={(e) => setPlant(e.value)}
					disabled={false}
				/>
			) : (
				""
			)} */}
			{selectedPlant !== undefined ? (
				<>
					{" "}
					Select asset:
					<SingleSelect
						options={assetSelector}
						value={selectedAsset}
						onChange={(e: ValueLabelPairAsset) => setSelectedAsset(e)}
						disabled={false}
					/>
				</>
			) : (
				""
			)}
			{selectedAsset !== undefined ? (
				<>
					{/* <br></br>
					Select KPI or add new:
					<br></br>
					<select id="kpi list" onChange={(e) => findAndSetKpi(e.target.value)}>
						<option key={"empty kpi"}> {"Choose kpi.."} </option>
						{asset.KPIs.map((KPI, index) => (
							<option key={"kpi" + index.toString()}> {KPI.dbId} </option>
						))}
					</select>
					<Button label={"Add new KPI"} onClick={() => addNewKPI()}></Button> */}
					Select kpi:
					<SingleSelect
						options={kpiSelector}
						value={selectedKpi}
						onChange={(e: ValueLabelPairKpi) => setSelectedKpi(e)}
						disabled={false}
					/>
					or add kpi:
					<Button label={"Add kpi"} onClick={() => addKPI()}></Button>
				</>
			) : (
				""
			)}
			{/* <pre>{kpi}</pre> */}
			{editKpiTextArea ? (
				<>
					<br></br>
					<textarea id={"textarea"} rows={20} cols={70} />
					<Button label={"Apply change"} onClick={() => setNewKPIsetting()}></Button>
					<Button label={"Delete this KPI"} onClick={() => deleteKPI()}></Button>
				</>
			) : addKpiTextArea ? (
				<>
					<br></br>
					<textarea id={"textarea"} rows={20} cols={70} />
					<Button label={"Save new KPI"} onClick={() => addNewKPIToDb()}></Button>
				</>
			) : (
				""
			)}
		</>
	)
}
