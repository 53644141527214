import { normaliseTabLabel } from "@pages/DatabasePage/DatabasePage"
import { TabHeader } from "@pages/HomePage/TabHeader"
import * as Tabs from "@radix-ui/react-tabs"
import { Route } from "@routes/_adminPageLayout/devices"
import { useNavigate } from "@tanstack/react-router"
import { ReactElement, useEffect, useState } from "react"
import DeviceConfigPage from "./DeviceConfigPage"
import DevicePushHistoryPage from "./DevicePushHistoryPage"
import RebootDevice from "./RebootDevice"
import UpdateDevice from "./UpdateDevice"
import UpdateHistory from "./UpdateHistory"

type Tab = {
	label: string
	content: ReactElement
}

export const DEFAULT_DEVICE_TAB_LABEL = "Device Config"

export default function DevicesPage() {
	const { page } = Route.useSearch()
	const navigate = useNavigate()
	const [tabs, setTabs] = useState<Tab[]>([])

	const [activeTabIndex, setActiveTabIndex] = useState<string>()
	const [width, setWidth] = useState(1300)

	useEffect(() => {
		const _tabs: Tab[] = [
			{
				label: DEFAULT_DEVICE_TAB_LABEL,
				content: <DeviceConfigPage />,
			},
			{
				label: "Config Push History",
				content: <DevicePushHistoryPage />,
			},
			{
				label: "Update Device",
				content: <UpdateDevice />,
			},
			{
				label: "Update History",
				content: <UpdateHistory />,
			},
			{
				label: "Reboot Device",
				content: <RebootDevice />,
			},
		]
		_tabs.sort((a, b) => a.label.localeCompare(b.label))
		setTabs(_tabs)

		if (page === "") {
			navigate({
				to: ".",
				search: (prev) => ({ ...prev, page: normaliseTabLabel(DEFAULT_DEVICE_TAB_LABEL) }),
			})
		}
		const updateSize = () => {
			setWidth(document.body.clientWidth - 48)
		}
		updateSize()
		window.addEventListener("resize", updateSize)
		return () => {
			window.removeEventListener("resize", updateSize)
		}
	}, [])

	useEffect(() => {
		if (page === "") {
			return
		}
		const tabIndex = tabs.findIndex((t) => normaliseTabLabel(t.label) === page)
		if (tabIndex != -1) {
			setActiveTabIndex(tabIndex.toString())
		}
	}, [page, tabs])

	useEffect(() => {
		const index = parseFloat(activeTabIndex ?? "")
		const tab = tabs[index]
		if (tab != undefined) {
			navigate({
				to: ".",
				search: (prev) => ({ ...prev, page: normaliseTabLabel(tab.label) }),
			})
		}
	}, [activeTabIndex, tabs])

	return (
		<div className="db-config">
			{activeTabIndex != undefined ? (
				<>
					<Tabs.Root
						orientation="vertical"
						className=""
						onValueChange={setActiveTabIndex}
						value={activeTabIndex}
					>
						<Tabs.List className="flex flex-wrap gap-1 overflow-auto">
							{tabs.map((t, index) => (
								<TabHeader
									key={`tabHeader_${t.label}`}
									activeTab={activeTabIndex}
									tabName={t.label}
									tabIndex={index}
								/>
							))}
						</Tabs.List>
						{tabs.map((t, index) => (
							<Tabs.Content key={index} value={index.toString()}>
								{t.content}
							</Tabs.Content>
						))}
					</Tabs.Root>
				</>
			) : (
				<>Loading...</>
			)}
		</div>
	)
}
