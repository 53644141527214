import { getFileName } from "@helpers/getFileName"
import { requestApi2 } from "@utils/http"
import { AggregationConfig, GetTimeseriesDataArgs, TimeseriesData, endpoints } from "api"
import { useQuery } from "react-query"

export const LIMIT_FOR_MINUTE_AGGREGATION_MILLISECONDS = 1000 * 60 * 60 * 24 * 5 // 5 days

type GetTimeseriesQuery = {
	t1: Date
	t2: Date
	plantId: number
	sensorIds: number[]
	aggregationGroupedBy?: AggregationConfig["groupBy"]
	aggregationMethod?: AggregationConfig["method"]
}

export function useGetTimeseriesQuery(args: GetTimeseriesQuery) {
	const res = useQuery(
		getTimeseriesQueryKeys(
			args.t1,
			args.t2,
			args.plantId,
			args.sensorIds,
			args.aggregationGroupedBy ??
				(args.t2.getTime() - args.t1.getTime() > LIMIT_FOR_MINUTE_AGGREGATION_MILLISECONDS
					? "hour"
					: "minute"),
			args.aggregationMethod,
			getFileName()
		),
		async (): Promise<TimeseriesData | null | undefined> => {
			try {
				const reqArgs: GetTimeseriesDataArgs = {
					interval: {
						gte: args.t1,
						lt: args.t2,
					},
					sensorIds: args.sensorIds,
					aggregation:
						args.aggregationMethod !== undefined
							? {
									groupBy:
										args.aggregationGroupedBy ??
										(args.t2.getTime() - args.t1.getTime() >
										LIMIT_FOR_MINUTE_AGGREGATION_MILLISECONDS
											? "hour"
											: "minute"),
									method: args.aggregationMethod,
							  }
							: args.t2.getTime() - args.t1.getTime() >
							  LIMIT_FOR_MINUTE_AGGREGATION_MILLISECONDS
							? {
									groupBy: "hour",
									method: args.aggregationMethod ?? "average",
							  }
							: undefined,
				}
				const response = await requestApi2(endpoints.getTimeseriesData4, reqArgs)
				return response
			} catch (error) {
				return undefined
			}
		},
		{
			staleTime: 60000000,
			refetchInterval: 60000000,
		}
	)
	return res
}

export function getTimeseriesPartialQueryKeys(plantId: number, source?: string) {
	return getTimeseriesQueryKeys(
		new Date(),
		new Date(),
		plantId,
		[],
		"minute",
		"average",
		source
	).slice(0, 4)
}

export function getTimeseriesQueryKeys(
	t1: Date,
	t2: Date,
	plantId: number,
	sensorIds: number[],
	aggregationGroupedBy: AggregationConfig["groupBy"],
	aggregationMethod?: AggregationConfig["method"],
	source?: string
) {
	return [
		`source=${source ?? getFileName()}`,
		`queryID=GetTimeseriesQuery`,
		`endpoints=[${endpoints.getTimeseriesData4.name}]`,
		`plantId=${plantId}`,
		`t1=${t1.toISOString().split(":")[0]}:${t1.toISOString().split(":")[1]}`,
		`t2=${t2.toISOString().split(":")[0]}:${t2.toISOString().split(":")[1]}`,
		`sensorIds=[${sensorIds}]`,
		`aggregationGroupedBy=${aggregationGroupedBy}`,
		`aggregationMethod=${aggregationMethod === undefined ? "average" : aggregationMethod}`,
	]
}
