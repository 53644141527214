import { PlantObject, Sensor } from "api"

export function getSensorIdWithoutComponent(
	plantData: PlantObject | undefined,
	sensorName: string
): number {
	if (plantData === undefined) {
		return -1
	}
	let sensorData: Sensor | undefined = undefined
	for (const asset of plantData.assets) {
		const tmp = asset.sensors.find((sensor) => sensor.display_name === sensorName)
		if (tmp !== undefined) {
			sensorData = tmp
		}
	}
	if (sensorData === undefined) {
		return -1
	}
	return sensorData.id
}
