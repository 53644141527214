import { AppStructure, GetVesselInfoArgs, OpModeGetLiveArgs, PlantObject, Vessel } from "api"

type SubCompanyFilter = {
	[companyName: string]: string[]
}

export type PlantsPerCompany = {
	[companyName: string]: {
		[plantName: string]: PlantObject
	}
}
export const listPlantsPerCompany = (
	listOfCompanyObjs: AppStructure,
	subCompanyFilter?: SubCompanyFilter
) => {
	const plantsPerCompany: PlantsPerCompany = {}
	for (const clientId in listOfCompanyObjs) {
		const clientData = listOfCompanyObjs[clientId]
		if (clientData === undefined) {
			continue
		}
		if (subCompanyFilter !== undefined) {
			if (subCompanyFilter[clientId]?.includes(clientId) === true) {
				plantsPerCompany[clientId] = clientData.plants ?? {}
			} else {
				plantsPerCompany[clientId] = {}
			}
		} else {
			plantsPerCompany[clientId] = clientData.plants
		}
		for (const subClientId in clientData.clients) {
			const subClientData = clientData.clients[subClientId]
			if (subClientData === undefined) {
				continue
			}
			if (subCompanyFilter !== undefined) {
				if (subCompanyFilter[clientId]?.includes(subClientId) === true) {
					plantsPerCompany[clientId] = {
						...plantsPerCompany[clientId],
						...subClientData.plants,
					}
				}
			} else {
				plantsPerCompany[clientId] = {
					...plantsPerCompany[clientId],
					...subClientData.plants,
				}
			}
		}
	}
	return plantsPerCompany
}

export const filterPlantsPerCompanyByVessel = (
	plantsPerCompany: PlantsPerCompany,
	specificPlant?: string,
	plantId?: string
): PlantsPerCompany => {
	const plantsPerCompanyFiltered: PlantsPerCompany = {}
	for (const companyName of Object.keys(plantsPerCompany)) {
		plantsPerCompanyFiltered[companyName] = {}
		const currentCompanyPlantsFiltered = plantsPerCompanyFiltered[companyName]
		if (currentCompanyPlantsFiltered === undefined) {
			continue
		}
		const currentCompanyPlants = plantsPerCompany[companyName]
		if (currentCompanyPlants === undefined) {
			continue
		}
		if (specificPlant !== undefined) {
			const specificPlantData = currentCompanyPlants[specificPlant]
			if (specificPlantData !== undefined) {
				currentCompanyPlantsFiltered[specificPlant] = specificPlantData
			}
		} else if (plantId !== undefined) {
			const specificPlantIdData = currentCompanyPlants[plantId]
			if (specificPlantIdData !== undefined) {
				currentCompanyPlantsFiltered[plantId] = specificPlantIdData
			}
		} else {
			plantsPerCompanyFiltered[companyName] = currentCompanyPlants
		}
	}
	return plantsPerCompanyFiltered
}

export const getGetVesselInfoArgs = (
	plantsPerCompanyFiltered: PlantsPerCompany
): GetVesselInfoArgs => {
	const listOfPlants: Vessel[] = []
	for (const company in plantsPerCompanyFiltered) {
		const companyData = plantsPerCompanyFiltered[company]
		if (companyData === undefined) {
			continue
		}
		for (const plant in companyData) {
			const plantData = companyData[plant]
			if (plantData === undefined) {
				continue
			}
			const mmsi = plantData.mmsi
			const id = plantData.id
			if (mmsi === undefined || id === undefined) {
				continue
			}
			listOfPlants.push({
				plantId: id,
				mmsi: mmsi,
			})
		}
	}

	return {
		vessels: listOfPlants,
	}
}

export const getOpModeGetLiveArgs = (
	plantsPerCompanyFiltered: PlantsPerCompany
): OpModeGetLiveArgs => {
	const listOfPlantsId: number[] = []
	for (const company in plantsPerCompanyFiltered) {
		const companyData = plantsPerCompanyFiltered[company]
		if (companyData === undefined) {
			continue
		}
		for (const plant in companyData) {
			const plantData = companyData[plant]
			if (plantData === undefined) {
				continue
			}
			const id = plantData.id
			if (id === undefined) {
				continue
			}
			listOfPlantsId.push(id)
		}
	}

	return {
		plantIds: listOfPlantsId,
	}
}

export const getPlantsPerCompanyFilter = (
	clientId: string,
	params: { [paramName: string]: string },
	specificPlant?: string
) => {
	let filter: { [clientName: string]: string[] } | undefined
	if (specificPlant !== undefined) {
		filter = {}
		filter[clientId] = []
		filter[clientId]?.push(specificPlant)
	} else {
		if (params.clientId !== undefined) {
			filter = {}
			filter[params.clientId] = []
			if (params.plantId !== undefined) {
				filter[params.clientId]?.push(params.plantId)
			}
		}
	}
	return filter
}
