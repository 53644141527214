import { getFileName } from "@helpers/getFileName"
import { requestApi2 } from "@utils/http"
import { endpoints, GetOpModeEventsArgs, OpModeEvent } from "api"
import { useQuery } from "react-query"

export function getOpModeIntervalsPartialQueryKeys(plantId: number, source?: string) {
	return getOpModeIntervalsQueryKeys(new Date(), new Date(), plantId, source).slice(0, 4)
}

export function getOpModeIntervalsQueryKeys(t1: Date, t2: Date, plantId: number, source?: string) {
	return [
		`source=${source ?? getFileName()}`,
		`queryId=GetOpModeIntervals`,
		`endpoints=[${endpoints.opModeGetEvents.name}]`,
		`plantId=${plantId}`,
		`t1=${t1.toISOString().split(":")[0]}:${t1.toISOString().split(":")[1]}`,
		`t2=${t2.toISOString().split(":")[0]}:${t2.toISOString().split(":")[1]}`,
	]
}

export type GetOpModeIntervalsQueryArgs = {
	t1: Date
	t2: Date
	plantId: number
}

export function useGetOpModeIntervalsQuery(args: GetOpModeIntervalsQueryArgs) {
	const res = useQuery(
		getOpModeIntervalsQueryKeys(args.t1, args.t2, args.plantId, getFileName()),
		async (): Promise<OpModeEvent[] | null | undefined> => {
			try {
				const reqArgs: GetOpModeEventsArgs = {
					plantId: args.plantId,
					from: args.t1,
					to: args.t2,
				}

				const response = await requestApi2(endpoints.opModeGetEvents, reqArgs)
				return response
			} catch (error) {
				return undefined
			}
		},
		{
			staleTime: 60000000,
			refetchInterval: 60000000,
		}
	)
	return res
}
