import { getFileName } from "@helpers/getFileName"
import { requestApi2 } from "@utils/http"
import { OpModeGetListArgs, OpModeList, endpoints } from "api"
import { useQuery } from "react-query"

export function useGetOpModesListQuery({ plantIdsList }: { plantIdsList?: number[] }) {
	const queryKeys = [
		`source=${getFileName()}`,
		`queryId=GetOpModesList`,
		`endpoints=[${endpoints.opModeGetList.name}]`,
		`plantIdsList=[${plantIdsList}]`,
	]

	const res = useQuery(
		queryKeys,
		async (): Promise<OpModeList | null | undefined> => {
			if (plantIdsList === undefined || plantIdsList.length === 0) {
				return undefined
			}

			const data: OpModeList = []
			const seenIds = new Set<number>()

			for (const plantId of plantIdsList) {
				if (plantId === -1 || plantId === undefined) {
					continue
				}

				try {
					const args: OpModeGetListArgs = { plantId: plantId }
					const opModes = await requestApi2(endpoints.opModeGetList, args)
					if (opModes !== null && Array.isArray(opModes)) {
						for (const opMode of opModes) {
							if (!seenIds.has(opMode.id)) {
								seenIds.add(opMode.id)
								data.push(opMode)
							}
						}
					} else {
						console.error(`Invalid response for plantId ${plantId}:`, opModes)
					}
				} catch (error) {
					console.error(`Error fetching op modes for plantId ${plantId}:`, error)
					return undefined
				}
			}

			return data
		},
		{
			staleTime: 600000,
			refetchInterval: 600000,
		}
	)
	return res
}
