import { Dialog, Transition } from "@headlessui/react"
import { Dispatch, ReactElement, RefObject, SetStateAction } from "react"

export type ModalProps = {
	children: ReactElement
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>> | ((isOpen: boolean) => void)
	opacity?: number
	initialFocus?: RefObject<HTMLElement | null>
}

export default function Modal({ children, isOpen, setIsOpen }: ModalProps): ReactElement {
	return (
		<Transition
			show={isOpen}
			enter="transition duration-100 ease-out"
			enterFrom="transform scale-95 opacity-0"
			enterTo="transform scale-100 opacity-100"
			leave="transition duration-75 ease-out"
			leaveFrom="transform scale-100 opacity-100"
			leaveTo="transform scale-95 opacity-0"
		>
			<Dialog
				onClose={() => setIsOpen(false)}
				as="div"
				className="fixed left-0 z-10 overflow-y-auto right-4"
			>
				<Dialog.Overlay className="fixed inset-0 bg-dipai-secondary-901 opacity-30" />

				{children}
			</Dialog>
		</Transition>
	)
}

export function ModalNL({
	children,
	isOpen,
	setIsOpen,
	opacity = 0.4,
	initialFocus,
}: ModalProps): ReactElement {
	return (
		<Transition
			show={isOpen}
			enter="transition duration-100 ease-out"
			enterFrom="transform scale-95 opacity-0"
			enterTo="transform scale-100 opacity-100"
			leave="transition duration-75 ease-out"
			leaveFrom="transform scale-100 opacity-100"
			leaveTo="transform scale-95 opacity-0"
		>
			<Dialog
				onClose={() => setIsOpen(false)}
				as="div"
				className="fixed z-1090 overflow-y-auto"
				initialFocus={initialFocus}
			>
				<Dialog.Overlay
					className="fixed inset-0 bg-dipai-secondary-901"
					style={{ opacity: opacity }}
				/>

				{children}
			</Dialog>
		</Transition>
	)
}

export function ModalSide({ children, isOpen, setIsOpen }: ModalProps): ReactElement {
	return (
		<Transition
			show={isOpen}
			enter="transition duration-100 ease-out"
			enterFrom="transform scale-95 opacity-0"
			enterTo="transform scale-100 opacity-100"
			leave="transition duration-70 ease-out"
			leaveFrom="transform scale-100 opacity-100"
			leaveTo="transform scale-95 opacity-0"
		>
			<Dialog
				onClose={() => setIsOpen(false)}
				as="div"
				className="fixed left-0 z-10 overflow-y-auto right-4"
			>
				<Dialog.Overlay className={`fixed inset-0 bg-dipai-secondary-901 opacity-30`} />

				{children}
			</Dialog>
		</Transition>
	)
}
